import {
  CEMeasurementsResponseTreeNode,
  CEMeasurementUpdateForm,
  RevisionState,
  RevisionStateC,
} from 'unit-cost-estimate/interfaces';
import { ValidationStepStatisticDataPayload } from 'unit-projects/actions/payloads/validation';
import { MeasurementValue } from 'unit-projects/interfaces/measurements/measurement-value';
import { RevisionResourcesApi } from '../../../api/server';


function getData(): Promise<CEMeasurementsResponseTreeNode[]> {
  return RevisionResourcesApi.get('/measurements');
}

function updateMeasurement(
  rootId: number,
  activityValues: MeasurementValue[],
): Promise<void> {
  return RevisionResourcesApi.put(
    `/measurements/${rootId}`,
    activityValues,
  );
}

function bulkUpdateMeasurement(changes: CEMeasurementUpdateForm[]): Promise<void> {
  return RevisionResourcesApi.put('/measurements', changes);
}

function getStatistic(): Promise<ValidationStepStatisticDataPayload> {
  return RevisionResourcesApi.get('/measurements/statistic');
}

function approve(): Promise<RevisionState> {
  return RevisionResourcesApi.postV('/measurements/approve', null, RevisionStateC);
}

function disapprove(): Promise<RevisionState> {
  return RevisionResourcesApi.postV('/measurements/disapprove', null, RevisionStateC);
}


export const MeasurementsApi = {
  getData,
  updateMeasurement,
  bulkUpdateMeasurement,
  getStatistic,
  approve,
  disapprove,
};
