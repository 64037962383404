import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasConstants } from 'common/components/drawings/constants/drawing-canvas-constants';
import { DrawingMarkShapes } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsInstanceType } from 'common/components/drawings/enums/drawings-instance-type';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { SnappingGuideInfo } from '../../drawings-helpers/snapping/with-self-snapping';
import { DrawingsGeometryEntityCountMark } from '../count-mark/drawings-geometry-entity-count-mark';
import { DrawingsGeometryTempEntity, FinishedDrawingElement } from './drawings-geometry-temp-entity';
import { DrawingsGeometryTempEntityWithStrokeConfig } from './drawings-geometry-temp-entity-with-stroke';


export class DrawingsGeometryTempCountEntity
  extends DrawingsGeometryTempEntity<DrawingsGeometryTempEntityWithStrokeConfig> {
  private _marks: DrawingsGeometryEntityCountMark[] = [];
  private _path: paper.Path;

  constructor(config: DrawingsGeometryTempEntityWithStrokeConfig) {
    super(config);
    this._path = new paper.Path();
    DrawingsPaperUtils.updatePathStyles(this._path);
    this._path.addTo(config.layer);
    this._path.strokeColor = this._config.color.stroke;
    this._config.textRenderParametersObserver.subscribe(this.updateLabels);
    this._config.renderParametersContextObserver.subscribe(this.updateMarksRenderParams);
  }

  public destroy(): void {
    this._marks.forEach(x => x.destroy());
    this._path.remove();
    this._config.textRenderParametersObserver.unsubscribe(this.updateLabels);
    this._config.renderParametersContextObserver.unsubscribe(this.updateMarksRenderParams);
  }

  public get lastStablePoint(): paper.Point {
    return this._marks[this._marks.length - 1]?.paperPosition;
  }

  public get lastPoint(): paper.Point {
    return this._path.lastSegment.point;
  }

  public set shape(value: DrawingMarkShapes) {
    super.shape = value;
    this._marks.forEach(x => x.shape = value);
  }

  public get pointsCount(): number {
    return this._marks.length;
  }
  public get hasPoint(): boolean {
    return !!this._marks.length;
  }

  public canComplete(): boolean {
    return this.hasPoint;
  }

  public removeLastPoint(): paper.Point | null {
    if (this.hasPoint) {
      const lastPoint = this._marks.pop();
      lastPoint.destroy();
      if (this._marks.length) {
        const position = this._path.lastSegment.point;
        this._path.removeSegment(this._path.segments.length - 1);
        return position;
      } else {
        this._path.removeSegments();
        return null;
      }
    }
    return null;
  }

  public getPoint(index: number): paper.Point {
    return this._marks[index].paperPosition;
  }

  public convert(addLastPoint: boolean): FinishedDrawingElement[] {
    const result: FinishedDrawingElement = {
      type: DrawingsInstanceType.Count,
      geometry: {
        points: [],
        color: this._config.color.stroke.toCSS(true),
        shape: this._config.shape,
      },
      points: {},
    };
    for (const pointMark of this._marks) {
      const point = pointMark.position;
      const id = UuidUtil.generateUuid();
      result.geometry.points.push(id);
      result.points[id] = point;
    }
    if (addLastPoint) {
      const point = this._path.lastSegment.point;
      const id = UuidUtil.generateUuid();
      result.geometry.points.push(id);
      result.points[id] = DrawingsPaperUtils.convertPoint(point);
    }
    this._marks.forEach(x => x.destroy());
    return [result];
  }

  public getSnappingGuideInfo(point: paper.Point): SnappingGuideInfo {
    if (!this._marks || !this._marks.length) {
      return null;
    }
    return DrawingsPaperUtils.getGuidelineValue(this.statePointsIterator(), point);
  }

  public tryAddPoint(point: paper.Point): boolean {
    const mark = new DrawingsGeometryEntityCountMark({
      renderParamsContextObserver: this._config.renderParametersContextObserver,
      id: 'temp',
      geometry: point,
      layer: this._config.layer,
      color: this._config.color.stroke,
      order: this._marks.length + 1,
      rotation: this._config.textRenderParametersObserver.getContext().rotation,
      shapeCreator: this._config.shapeCreator,
      shape: this._config.shape || DrawingMarkShapes.Circle,
    });
    if (this._marks.length === 0) {
      this._path.add(point);
    } else {
      this._path.lastSegment.point = point;
    }
    this._path.add(point);

    this._marks.push(mark);
    mark.changeVisualData(this._config.renderParametersContextObserver.getContext().zoom);
    return true;
  }

  public updateColor(color: DrawingsPaperColorInfo): void {
    this._config.color = color;
    this._path.strokeColor = color.stroke;
    this._marks.forEach(x => x.changeColor(this._config.color.stroke));
  }

  public updateTempPointPosition(point: paper.Point): paper.Point {
    if (this._path && this._path.segments.length) {
      this._path.lastSegment.point = point;
    }
    return point;
  }

  @autobind
  private updateLabels(params: MeasuresViewSettings): void {
    this._marks.forEach(x => x.updateRenderParams(params));
  }

  @autobind
  private updateMarksRenderParams(renderParameters: DrawingsRenderParams): void {
    this._path.strokeWidth = DrawingsCanvasConstants.countTraceStrokeWidth / renderParameters.zoom;
    this._path.dashArray = [DrawingsCanvasConstants.dashArray * this._path.strokeWidth];
    this._marks.forEach(x => x.changeVisualData(renderParameters.zoom));
  }

  private* statePointsIterator(): IterableIterator<paper.Point> {
    for (const mark of this._marks) {
      yield mark.paperPosition;
    }
  }
}
