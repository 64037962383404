import { AssignedPia, AssignedPiaItem } from '2d/interfaces/pia-data';
import { AssignItemPatch, AssignPiaPatch } from '2d/interfaces/pia-patch';

function* resetAssignPatchesIteratorAfterChangeProperty(
  ids: string[],
  assemblyName: string,
  itemName: string,
  propertyName: string,
  assignProps: Record<string, AssignedPia>,
): Iterable<AssignPiaPatch> {
  for (const id of ids) {
    const assign = assignProps[id];
    const assembly = resetAssignAssemblyForm(id, assign, assemblyName, itemName, propertyName);
    if (assembly) {
      yield assembly;
    }
    const item = resetAssignItemForm(id, assign, itemName, propertyName);
    if (item) {
      yield item;
    }
  }
}

function resetAssignAssemblyForm(
  id: string,
  assign: AssignedPia,
  assemblyName: string,
  itemName: string,
  propertyName: string,
): AssignPiaPatch {
  if (assign && assign.assemblies && assemblyName) {
    const assignAssembly = assign.assemblies.find(a => a.name === assemblyName);
    if (assignAssembly) {
      const itemPatch = resetAssignItems(itemName, propertyName, assignAssembly.items);
      if (itemPatch === null) {
        const itemsLength = assignAssembly.items?.filter(i => i.name !== itemName).length;
        return itemsLength
          ? {
            ids: [id],
            updatedAssemblies: [
              {
                name: assemblyName,
                deletedItems: [itemName],
              },
            ],
          }
          : {
            ids: [id],
            deletedAssemblies: [assemblyName],
          };
      } else if (itemPatch) {
        return {
          ids: [id],
          updatedAssemblies: [
            {
              name: assemblyName,
              updatedItems: [itemPatch],
            },
          ],
        };
      }
    }
  }
}

function resetAssignItemForm(
  id: string,
  assign: AssignedPia,
  itemName: string,
  propertyName: string,
): AssignPiaPatch {
  if (assign && assign.items && itemName) {
    const item = resetAssignItems(itemName, propertyName, assign.items);
    if (item === null) {
      return {
        ids: [id],
        deletedItems: [itemName],
      };
    } else if (item) {
      return {
        ids: [id],
        updatedItems: [item],
      };
    }
  }
}

function resetAssignItems(
  itemName: string,
  propertyName: string,
  items: AssignedPiaItem[],
): AssignItemPatch | null {
  if (!items) {
    return null;
  }
  const item = items.find(i => i.name === itemName);
  if (item) {
    if (!item.properties) {
      return null;
    }
    const property = item.properties.find(p => p.name === propertyName);
    if (property) {
      const propertyLength = item.properties.filter(p => p.name !== property.name).length;
      return propertyLength
        ? {
          name: item.name,
          iconType: item.iconType,
          deletedProperties: [property.name],
        }
        : null;
    }
  }
}

export const resetAssignAfterChangeProperty = (
  ids: string[],
  assemblyName: string,
  itemName: string,
  propertyName: string,
  assignProps: Record<string, AssignedPia>,
): AssignPiaPatch[] => {
  const patch: AssignPiaPatch[] = [
    ...resetAssignPatchesIteratorAfterChangeProperty(
      ids,
      assemblyName,
      itemName,
      propertyName,
      assignProps,
    ),
  ];
  return patch;
};
