import { useMemo } from 'react';

import { DrawingsGeometryUtils } from 'common/components/drawings';
import { useRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { useCurrentDrawingInfo, useInstances } from 'common/components/drawings/hooks';

export function useCanOpenPolygon(instancesIds: string[]): boolean {
  const { rendererApi } = useRendererApiContext();
  const instances = useInstances();
  const currentDrawingInfo = useCurrentDrawingInfo();

  return useMemo(() => {
    return rendererApi && instancesIds.some(x => {
      if (!instances[x]) {
        return false;
      }

      const { type, drawingId, geometry } = instances[x];

      return DrawingsGeometryUtils.isClosedContour(type, geometry) && drawingId === currentDrawingInfo?.drawingId;
    });
  }, [rendererApi, instancesIds, instances, currentDrawingInfo]);
}
