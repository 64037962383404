import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';

import { HintWithDescription } from 'common/components/hint-with-description';
import { TextInput } from 'common/components/inputs';
import { LinkComponent } from 'common/components/link-component';
import { DPI_MODE_NAMES, SEARCH_AND_MEASURE_DPIs } from '../../constants/dpi-constants';
import { WizzardStatus } from '../../enums/dropper-state';
import { AiButtonWithStatus } from '../ai-button-with-status';
import { DpiSelector } from '../dpi-selector';
import { MagicSearchStyled } from '../magic-search-menu';
import { useDPI } from '../magic-search-menu/hooks/use-dpi';
import { useSearchingZone } from '../magic-search-menu/hooks/use-searching-zone';
import { NewInstanceSettings } from '../new-drawing-panel-controller';
import { SearchZoneButton } from '../search-zone-button';
import { QUERIES } from './queries';
import { QueryLine } from './query-line';
import { Styled } from './styled';
import { useRerun } from './use-rerun';
import { useRun } from './use-run';

interface Props {
  status: WizzardStatus;
  reset: () => void;
  query: string;
  setQuery: (query: string) => void;
}


const AutoMeasureAIMenuComponent: React.FC<Props> = ({
  status,
  reset,
  query,
  setQuery,
}) => {
  const description = `To use the tool, enter the type of room, building element,
    building area metrics such as GIA, NIA, GEA, or material, for example "Brick", "Concrete"`;

  const [dpi, setDpi] = useDPI();
  const run = useRun();

  const runQuery = useCallback((newQuery: string) => {
    if (query === newQuery) {
      run(newQuery, dpi);
    } else {
      setQuery(newQuery);
    }
  }, [setQuery, query, run, dpi]);

  const onRun = useCallback(() => {
    run(query, dpi);
  }, [dpi, query, run]);

  const { zone, clearZone, specifyZone } = useSearchingZone();
  useRerun(query);
  const text = useMemo(
    () => {
      if (status === WizzardStatus.Start) {
        return 'Launch';
      }
      return 'Restart';
    }, [ status],
  );
  return (
    <>
      <NewInstanceSettings/>
      <MagicSearchStyled.Container>
        <Styled.Query>
          <TextInput
            value={query}
            onBlur={setQuery}
          />
        </Styled.Query>
        <HintWithDescription text={description} />
        <QueryLine
          queries={QUERIES}
          runQuery={runQuery}
        />
        <SearchZoneButton
          hasZone={!!zone}
          clearZone={clearZone}
          specifyZone={specifyZone}
        />
        <LinkComponent
          text='Reset'
          icon={Icons.UndoClassic}
          onClick={reset}
          fontColor='mainFont'
          size='m'
        />
        <DpiSelector
          dpi={dpi}
          onChange={setDpi}
          options={SEARCH_AND_MEASURE_DPIs}
          names={DPI_MODE_NAMES}
        />
        <AiButtonWithStatus
          text={text}
          onClick={onRun}
          status={status}
          disabled={!query}
          icon={Icons.AutoMeasureSmall}
        />
      </MagicSearchStyled.Container>
    </>
  );
};

export const AutoMeasureAiMenu = React.memo(AutoMeasureAIMenuComponent);
