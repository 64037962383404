import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './activity-card.scss';

import { State } from 'common/interfaces/state';
import { KreoIconCollapseExpand4d } from 'common/UIKit';
import { ActivityData } from '../../../../interfaces/gantt-chart';
import {
  ActivityResources,
  ChartDataProvider,
  GroupType,
  ResourceDescription,
} from '../../../../utils/gantt-chart';
import { actions as fourDActions } from '../../actions';
import { datesFormatter } from '../../dates-formatter';
import { ResourcesTabMode } from '../../enums/resources-tab-mode';
import { SidePanelTab } from '../../enums/side-panel-tab';
import { ResourceIdentifier } from '../../interfaces/resource-identifier';
import {
  ResourceMouseEventHandlerDictionary,
} from '../../interfaces/resource-mouse-event-handler-dictionary';
import {
  actions as resourceSelectorActions,
} from '../resource-selector/actions';

interface CardOwnProps {
  dataProvider: ChartDataProvider;
  activityId: number;
  bimId: number;
  progress: number;
  belongsToSelectedElement: boolean;
  updateCurrentMoment: (currentMoment: number, force?: boolean) => void;
  selectElementOnEngine: (bimId: number | null) => void;
}

interface CardStateProps {
  projectStartDate: Date;
  data: ActivityData;
}

interface CardDispatchProps {
  toggleExpansion: () => void;
  previewResource: (resource: ResourceIdentifier) => void;
  resetResourcePreview: () => void;
  displayResource: (resource: ResourceIdentifier) => void;
}

interface CardState {
  startDay: number;
  endDay: number;
  color: string;
}

interface CardProps extends CardOwnProps, CardStateProps, CardDispatchProps { }

class ActivityCardComponent extends React.Component<CardProps, CardState> {
  private resourceMouseEnterHandlers: ResourceMouseEventHandlerDictionary = {};
  private resourceClickHandlers: ResourceMouseEventHandlerDictionary = {};

  constructor(props: CardProps) {
    super(props);

    const colors = props.dataProvider.getWorkGroupColors(GroupType.Activity, props.activityId);
    const dates = props.dataProvider.getWorkGroupDates(GroupType.Activity, props.activityId);
    this.state = {
      startDay: dates.startDay,
      endDay: dates.endDay,
      color: colors.main,
    };
  }

  public render(): React.ReactNode {
    const progressPercent = this.props.progress * 100;
    const progressPercentString = progressPercent.toFixed(0);
    const widthPercentString = `${progressPercent}%`;
    const resources = this.props.dataProvider.getResourcesForActivity(this.props.activityId);
    const dates = datesFormatter.formatDateSpan(
      this.state.startDay,
      this.state.endDay,
      this.props.projectStartDate);

    const expandable = !!((resources.labours && resources.labours.length) ||
      (resources.materials && resources.materials.length) ||
      (resources.plants && resources.plants.length));

    const expandIconClassName = classNames(
      'activity-card__expand-icon',
      {
        expanded: this.props.data.expanded,
      });

    const collabsiblePartClassName = classNames(
      'activity-card__collapsible-part',
      {
        expanded: this.props.data.expanded,
      });

    return (
      <div
        className={classNames(
          'activity-card',
          { selected: this.props.belongsToSelectedElement, expandable })}
      >
        <div
          className='activity-card__content'
          onClick={this.onCardClick}
        >
          <div
            className={classNames('activity-card__progress-identifier', { full: progressPercent === 100 })}
            style={{
              width: widthPercentString,
              background: this.state.color,
            }}
          />
          <div className='activity-card__name-line'>
            <div className='activity-card__name'>
              {this.props.data.shortName}
            </div>
            <div className='activity-card__progress'>
              {progressPercentString}%
            </div>
          </div>
          <div className='activity-card__dates-line'>
            <div className='activity-card__total-labour-hours'>
              Labour hours: {resources.totalLabourTime.toFixed(2)}
            </div>
            <div className='activity-card__dates'>
              <a
                className='activity-card__date-link'
                onClick={this.onStartDateClick}
              >
                {dates.start}
              </a> - <a
                className='activity-card__date-link'
                onClick={this.onEndDateClick}
              >
                {dates.end}
              </a>
            </div>
          </div>
          {
            expandable
              ? (
                <div className={collabsiblePartClassName}>
                  {this.props.data.expanded
                    ? (
                      <React.Fragment>
                        {this.renderPlantsAndLaboursSection(resources)}
                        {this.renderMaterialsSection(resources)}
                      </React.Fragment>
                    ) : null
                  }
                </div>
              ) : null
          }
        </div>
        {
          expandable
            ? (
              <div
                className='activity-card__expand-handle'
                onClick={this.props.toggleExpansion}
              >
                <KreoIconCollapseExpand4d className={expandIconClassName}/>
              </div>
            ) : null
        }
      </div>
    );
  }

  private renderPlantsAndLaboursSection(resources: ActivityResources): React.ReactNode {
    const plantsAndLabours = [...resources.plants, ...resources.labours];

    return plantsAndLabours.length > 0
      ? (
        <div className='activity-card__resources-section'>
          {
            plantsAndLabours.map((resource) => {
              return (
                <div
                  key={`resource-${resource.type}-${resource.id}`}
                  className='activity-card__resource-badge'
                  style={{ background: resource.colors.off }}
                  onMouseEnter={this.getResourceMouseEnterHandler(resource)}
                  onMouseLeave={this.onResourceMouseLeave}
                  onClick={this.getResourceClickHandler(resource)}
                  title={resource.displayName}
                >
                  {resource.displayName}
                </div>
              );
            })
          }
        </div>
      ) : null;
  }

  private renderMaterialsSection(resources: ActivityResources): React.ReactNode {
    return resources.materials !== null && resources.materials.length > 0 ?
      (
        <div className='activity-card__materials-section'>
          {
            resources.materials.map((material, index) => {
              const amount = resources.materialsAmounts[index];
              return (
                <div
                  className='activity-card__material-line'
                  key={material.id}
                >
                  <div
                    className='activity-card__color-box material'
                    style={{ background: material.colors.main }}
                  />
                  <div className='activity-card__material-name'>
                    <a
                      className='activity-card__material-link'
                      onMouseEnter={this.getResourceMouseEnterHandler(material)}
                      onClick={this.getResourceClickHandler(material)}
                      onMouseLeave={this.onResourceMouseLeave}
                      title={material.displayName}
                    >
                      {material.displayName}
                    </a>
                  </div>
                  <div className='activity-card__material-amount'>
                    {amount.toFixed(3)} {material.unit}
                  </div>
                </div>
              );
            })
          }
        </div>
      ) : null;
  }

  @autobind
  private onStartDateClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    event.stopPropagation();
    this.props.updateCurrentMoment(this.state.startDay, true);
  }

  @autobind
  private onEndDateClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    event.stopPropagation();
    this.props.updateCurrentMoment(this.state.endDay, true);
  }

  @autobind
  private onCardClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    this.props.selectElementOnEngine(this.props.belongsToSelectedElement ? null : this.props.bimId);
  }

  private getResourceMouseEnterHandler<T extends HTMLDivElement | HTMLAnchorElement>(
    resource: ResourceDescription,
  ): (event: React.MouseEvent<T>) => void {
    if (
      !this.resourceMouseEnterHandlers[resource.type] ||
      !this.resourceMouseEnterHandlers[resource.type][resource.id]
    ) {
      if (!this.resourceMouseEnterHandlers[resource.type]) {
        this.resourceMouseEnterHandlers[resource.type] = {};
      }

      this.resourceMouseEnterHandlers[resource.type][resource.id] =
        (event: React.MouseEvent<HTMLDivElement>): void => {
          event.stopPropagation();
          event.nativeEvent.stopPropagation();
          event.preventDefault();
          this.props.previewResource({ type: resource.type, id: resource.id });
        };
    }

    return this.resourceMouseEnterHandlers[resource.type][resource.id];
  }

  @autobind
  private onResourceMouseLeave<T>(event: React.MouseEvent<T>): void {
    event.stopPropagation();
    event.nativeEvent.stopPropagation();
    event.preventDefault();
    this.props.resetResourcePreview();
  }

  private getResourceClickHandler<T extends HTMLDivElement | HTMLAnchorElement>(
    resource: ResourceDescription,
  ): (event: React.MouseEvent<T>) => void {
    if (!this.resourceClickHandlers[resource.type] || !this.resourceClickHandlers[resource.type][resource.id]) {
      if (!this.resourceClickHandlers[resource.type]) {
        this.resourceClickHandlers[resource.type] = {};
      }

      this.resourceClickHandlers[resource.type][resource.id] = (event: React.MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
        event.preventDefault();
        this.props.displayResource(resource);
      };
    }

    return this.resourceClickHandlers[resource.type][resource.id];
  }
}

const mapStateToProps = (state: State, ownProps: CardOwnProps): CardStateProps => {
  return {
    projectStartDate: state.fourDVisualisation.projectStartDate,
    data: state.fourDVisualisation.activitiesData[ownProps.activityId],
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: CardOwnProps): CardDispatchProps => {
  return {
    toggleExpansion: () => { dispatch(fourDActions.toggleActivityCardExpandStatus(ownProps.activityId)); },
    previewResource: (resource: ResourceIdentifier) => {
      dispatch(resourceSelectorActions.previewResource(0, resource));
    },
    resetResourcePreview: () => { dispatch(resourceSelectorActions.resetResourcePreview()); },
    displayResource: (resource: ResourceIdentifier) => {
      dispatch(resourceSelectorActions.selectResource(0, resource));
      dispatch(fourDActions.selectResourceForTimeline(resource));
      dispatch(resourceSelectorActions.resetResourcePreview());
      dispatch(fourDActions.setSidePanelTab(SidePanelTab.Resources));
      dispatch(fourDActions.setResourcesTabMode(ResourcesTabMode.All));
    },
  };
};

export const ActivityCard = connect(mapStateToProps, mapDispatchToProps)(ActivityCardComponent);
