import { FormControlLabel } from '@material-ui/core';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { change, Field, formValueSelector, InjectedFormProps, isValid, reduxForm, SubmitHandler } from 'redux-form';

import './database-dialog.scss';

import { UploadingFile } from 'common/interfaces/common-state';
import { State as ReduxState } from 'common/interfaces/state';
import {
  Checkbox,
  MaterialInputField,
  MaterialInputProps,
  MaterialMenuItem,
  MaterialSelectField,
  MaterialSelectProps,
} from 'common/UIKit';
import { KreoFormDialog } from '../../../../components/dialog/base/kreo-form-dialog';
import { Required } from '../../../../components/dialog/validators';
import { CREATE_DATABASE } from '../../../../constants/forms';
import { DatabaseModel } from '../../interfaces/data';

const selector = formValueSelector(CREATE_DATABASE);

interface ReduxProps {
  show: boolean;
  databases: DatabaseModel[];
  selectedDatabases: number[];
  files: UploadingFile[];
  isFormValid: boolean;
}

interface ReduxActions {
  changeDatabases: (value: number[]) => void;
}

interface Props extends InjectedFormProps<any, any>, ReduxProps, ReduxActions {}

interface State {
  isNew: boolean;
}

export const CREATE_DATABASE_DIALOG_NAME = 'createDatabase';
const isValidSelector = isValid(CREATE_DATABASE);

class DatabaseDialogComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isNew: true,
    };
  }

  public componentDidUpdate(): void {
    const { files, show } = this.props;
    if (show && files && files.length > 0 && files[0].progress === 100) {
      this.onDialogClose();
    }
  }

  public render(): JSX.Element {
    const { databases, selectedDatabases } = this.props;

    const databaseOptions = databases.map(x => {
      return {
        value: x.id,
        text: (
          <div className='database-select-checked-option'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDatabases && selectedDatabases.includes(x.id)}
                />
              }
              label={x.name}
            />
          </div>
        ),
      };
    });

    const validateFields = {
      name: 'Required',
    };

    return (
      <KreoFormDialog
        name={CREATE_DATABASE_DIALOG_NAME}
        modal={true}
        title={'Create Database'}
        showCancelButton={true}
        submitButtonText={'Create'}
        handleSubmit={this.handleSubmit}
        onDialogClose={this.onDialogClose}
        formName={CREATE_DATABASE}
        validateFields={validateFields}
        bodyClassName={'database-dialog'}
      >
        <Field<MaterialInputProps>
          name='name'
          component={MaterialInputField}
          label='Name'
          validate={[Required]}
          displayBottomInformation={true}
        />
        <div className='database-dialog__options'>
          <div
            className={classNames('database-dialog__radio-btn', { 'x': this.state.isNew })}
            onClick={this.setNew}
          >
            New database
          </div>
          <div
            className={classNames('database-dialog__radio-btn', { 'x': !this.state.isNew })}
            onClick={this.setImport}
          >
            Import all data from databases
          </div>
        </div>
        {!this.state.isNew && (
          <Field<MaterialSelectProps>
            className='database-dialog__material-select'
            component={MaterialSelectField}
            name='ids'
            multiple={true}
            label='Select databases'
            selectionRenderer={this.selectionRenderer}
          >
            {databaseOptions &&
              databaseOptions.map((db, index) => {
                return (
                  <MaterialMenuItem
                    className='database-dialog__material-select-item'
                    key={index}
                    value={db.value}
                  >
                    {db.text}
                  </MaterialMenuItem>
                );
              })}
          </Field>
        )}
      </KreoFormDialog>
    );
  }

  @autobind
  private handleSubmit(submitHandler: SubmitHandler<{}, {}>): void {
    this.props.handleSubmit(submitHandler);
    if (this.props.isFormValid) {
      this.props.reset();
    }
  }

  @autobind
  private setNew(): void {
    this.changeOption(true);
  }

  @autobind
  private setImport(): void {
    this.changeOption(false);
  }

  @autobind
  private onDialogClose(): void {
    this.props.reset();
    this.changeOption(true);
  }

  @autobind
  private changeOption(value: boolean): void {
    if (this.state.isNew !== value) {
      this.props.changeDatabases([]);
      this.setState({ isNew: value });
    }
  }

  @autobind
  private selectionRenderer(value: number[]): string {
    const { databases } = this.props;
    return databases
      .filter(x => value.includes(x.id))
      .map(x => x.name)
      .join(', ');
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    show: CREATE_DATABASE_DIALOG_NAME in state.dialog,
    databases:
      (state.database &&
        state.database.databases &&
        state.database.databases.filter(x => !x.version)) ||
      [],
    selectedDatabases: selector(state, 'ids'),
    isFormValid: isValidSelector(state),
    files: state.common.files,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    changeDatabases: (value: number[]) => dispatch(change(CREATE_DATABASE, 'ids', value)),
  };
};

export interface CreateDatabaseData {
  name: string;
  ids?: number[];
}

const DatabaseDialogForm = reduxForm<CreateDatabaseData, any>({
  form: CREATE_DATABASE,
  destroyOnUnmount: true,
  initialValues: {},
})(DatabaseDialogComponent);

export const DatabaseDialog =
  connect<ReduxProps, ReduxActions, { onSubmit: (value: CreateDatabaseData) => void }>
    (mapStateToProps, mapDispatchToProps)(DatabaseDialogForm);
