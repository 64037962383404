import { ReactourStepContentArgs } from 'reactour';

import { ControlNames } from 'common/constants/control-names';

function forcedClose(stepData: ReactourStepContentArgs): void {
  console.warn('Ui Tour forced close on step', stepData.step);
  stepData.close();
}

export function getSelector(controlName: string): string {
  return `[data-control-name=${controlName}]`;
}

export function tourStepNext(
  stepData: ReactourStepContentArgs,
  goToSelector: string,
  goToTimeout: number,
  closeTourSelector?: string,
): void {
  const goToElement = document.querySelector(getSelector(goToSelector));
  const closeButton = closeTourSelector && document.querySelector(getSelector(closeTourSelector));
  function removeEvent(): void {
    goToElement.removeEventListener('click', next);
    if (closeButton) {
      closeButton.removeEventListener('click', closeTour);
    }
  }
  function closeTour(): void {
    removeEvent();
    stepData.close();
  }
  function next(): void {
    removeEvent();
    !goToTimeout ? stepData.goTo(stepData.step) : setTimeout(() => stepData.goTo(stepData.step), goToTimeout);
  }
  goToElement
    ? goToElement.addEventListener('click', next)
    : forcedClose(stepData);
  if (closeButton) {
    closeButton.addEventListener('click', closeTour);
  }
}

export function tourStepLast(stepData: ReactourStepContentArgs, closeTourSelectors: string[]): void {
  closeTourSelectors.map(selector => {
    const closeButton = document.querySelector(getSelector(selector));
    function closeTour(): void {
      closeButton.removeEventListener('click', closeTour);
      stepData.close();
    }
    closeButton.addEventListener('click', closeTour);
  });
}

export function tourStepProjectCreateDialog(stepData: ReactourStepContentArgs): void {
  const page = document.querySelector(getSelector(ControlNames.projectsPage));
  const form = document.querySelector(getSelector(ControlNames.createProjectForm));
  const cancelButton = document.querySelector(getSelector(ControlNames.dialogCancelButton));
  const closeButton = document.querySelector(getSelector(ControlNames.dialogCloseButton));
  function closeTour(): void {
    cancelButton.removeEventListener('click', closeTour);
    closeButton.removeEventListener('click', closeTour);
    stepData.close();
  }
  function submitForm(): void {
    cancelButton.removeEventListener('click', closeTour);
    closeButton.removeEventListener('click', closeTour);
    function modifiedPage(): void {
      const tileElement = page.querySelector(getSelector(ControlNames.projectTile));
      const tileParentElement = tileElement && tileElement.parentElement;
      if (tileParentElement && tileParentElement.tagName !== 'A') {
        page.removeEventListener('DOMSubtreeModified', modifiedPage);
        stepData.goTo(stepData.step);
      }
    }
    page.addEventListener('DOMSubtreeModified', modifiedPage);
  }
  form.addEventListener('submit', submitForm);
  cancelButton.addEventListener('click', closeTour);
  closeButton.addEventListener('click', closeTour);
}

export function tourStepProjectCreating(stepData: ReactourStepContentArgs): void {
  const page = document.querySelector(getSelector(ControlNames.projectsPage));
  const cancelButton = page.querySelector(getSelector(ControlNames.projectTileDeleteButton));
  function closeTour(): void {
    page.removeEventListener('DOMSubtreeModified', modifiedPage);
    cancelButton.removeEventListener('click', closeTour);
    stepData.close();
  }
  function modifiedPage(): void {
    const tileElement = page.querySelector(getSelector(ControlNames.projectTile));
    const tileParentElement = tileElement && tileElement.parentElement;
    if (tileParentElement && tileParentElement.tagName === 'A') {
      page.removeEventListener('DOMSubtreeModified', modifiedPage);
      cancelButton.removeEventListener('click', closeTour);
      stepData.goTo(stepData.step);
    }
  }
  cancelButton.addEventListener('click', closeTour);
  page.addEventListener('DOMSubtreeModified', modifiedPage);
}
