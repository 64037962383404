import * as t from 'io-ts';

import { BillingPaymentStatusC } from '../enums/billing-payment-status';
import { BillingCardModelC } from './billing-card-model';


export const BillingPaymentModelC = t.intersection(
  [
    t.type(
      {
        id: t.string,
        type: t.string,
        status: BillingPaymentStatusC,
      },
    ),
    t.partial({
      card: BillingCardModelC,
      description: t.string,
    }),
  ],
  'BillingPaymentForm');

export type BillingPaymentModel = t.TypeOf<typeof BillingPaymentModelC>;

