import { FaqCaption } from 'common/enums/faq-caption';

export const FaqUrl = {
  [FaqCaption.QuantityTakeOff]: 'https://www.kreo.net/qto-help',
  [FaqCaption.QtoGeneral]: 'https://www.kreo.net/qto-help',
  // [FaqCaption.Scenarios]: 'https://kreo.zendesk.com/hc/en-us/sections/360002162037-Scenarios',
  // [FaqCaption.Costs]: 'https://kreo.zendesk.com/hc/en-us/sections/360002184478-Costs',
  // [FaqCaption.Sequence]: 'https://kreo.zendesk.com/hc/en-us/sections/360002184438-Sequencing',
  // [FaqCaption.ActivityAssignment]: 'https://kreo.zendesk.com/hc/en-us/sections/360002184518-Activity-Assignment',
  // [FaqCaption.Classification]: 'https://kreo.zendesk.com/hc/en-us/sections/360002161997-Information-audit-',
  // [FaqCaption.Measurements]: 'https://kreo.zendesk.com/hc/en-us/sections/360002184498-Measurements',
  // [FaqCaption.FourD]: 'https://kreo.zendesk.com/hc/en-us/sections/360002162017-4D',
  // [FaqCaption.Schedule]: 'https://kreo.zendesk.com/hc/en-us/sections/360002184458-Schedule',
  // [FaqCaption.Databases]: 'https://kreo.zendesk.com/hc/en-us/sections/360002184418-Databases',
};
