import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DrawingsGeometryGroup } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';

export function useCanUngroup(instancesIds: string[]): boolean {
  const groups = useSelector<State, DrawingsGeometryGroup[]>(s => s.drawings.drawingGeometryGroups);

  return useMemo(() => {
    if (!instancesIds?.length) {
      return false;
    }
    const instancesIdsSet = new Set(instancesIds);
    return groups.some(group => group.measurements.some(id => instancesIdsSet.has(id)));
  }, [groups, instancesIds]);
}
