import React from 'react';
import { CreateProjectReportTemplate } from 'unit-2d-report-template/interfaces';

export const useHandleApplyTemplateCallback = (
  projectId: number,
  applyTemplate: (payload: CreateProjectReportTemplate) => void,
  closeDialog: () => void,
): (id: string) => void => {
  return React.useCallback((id) => {
    applyTemplate({ projectId, form: { companyTemplateId: Number(id) } });
    closeDialog();
  }, [projectId]);
};
