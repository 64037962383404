import * as React from 'react';

export const KreoIconPlus: React.FC<{}> = (_props) => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icon_add' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-4'>
          <polygon id='Rectangle-Copy-5' points='0 3.05597706e-05 14 3.05597707e-05 14 14 0 14' />
          <path
            d='M7,2 L7,12 L7,2 Z'
            id='Rectangle-5'
            stroke='#343948'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M12,7 L2,7 L12,7 Z'
            id='Rectangle-5'
            stroke='#343948'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
};
