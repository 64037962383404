type EventHandler = (...args: any) => void;

export class Action<TEventTypes> {
  private eventsMap: Record<keyof TEventTypes, EventHandler[]> = {} as Record<keyof TEventTypes, EventHandler[]>;

  public addEventLister(eventType: keyof TEventTypes, handler: EventHandler): void {
    const eventHandlers = this.eventsMap[eventType];
    if (eventHandlers) {
      eventHandlers.push(handler);
    } else {
      this.eventsMap[eventType] = [handler];
    }
  }

  public removeEventLister(eventType: keyof TEventTypes, handler: EventHandler): void {
    const eventHandlers = this.eventsMap[eventType];
    if (!eventHandlers) {
      return;
    }

    const handlerIndex = this.eventsMap[eventType].indexOf(handler);
    if (handlerIndex === -1) {
      return;
    }

    this.eventsMap[eventType].splice(handlerIndex, 1);
  }

  public dispatch(eventType: keyof TEventTypes, ...args: any): void {
    const handlers = this.eventsMap[eventType];
    if (!handlers) {
      return;
    }

    handlers.forEach(handler => {
      handler(...args);
    });
  }
}
