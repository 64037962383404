import * as Ag from 'ag-grid-community';

export enum FontStyle {
  Bold =          0b00000001,
  Italic =        0b00000010,
  StrikeThrough = 0b00000100,
  Underline =     0b00001000,
}

export enum HorizontalAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

/* eslint-disable @typescript-eslint/no-shadow */
export enum StyleFields {
  BackgroundColor = 'backColor',
  FontColor = 'fontColor',
  FontName = 'fontName',
  FontSize = 'fontSize',
  FontStyle = 'fontStyle',
  HorizontalAlign = 'horizontalAlign',
}
/* eslint-enable */

export interface StyleProperty extends Record<StyleFields, any> {
}

export interface StyleParams {
  api: Ag.GridApi;
  colDef: Ag.ColDef;
  column: Ag.Column;
  context: any;
  data: any;
  node: Ag.RowNode;
  value: any;
}

export type StyleResult = Record<string, string>;
