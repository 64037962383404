import { UpdateInitiator } from 'common/interfaces/update-initiator';
import { CompanyResourcesApi } from '../../../api/server';
import {
  CreateAssemblyPayload,
  CreateItemPayload,
  CreatePropertyPayload,
  DatabaseFetchResponsePayload,
  Group,
  Property,
  UpdateAssemblyPayload,
  UpdateItemRequestPayload,
} from '../interfaces';


function fetchDataBase(): Promise<DatabaseFetchResponsePayload> {
  return CompanyResourcesApi.get('/pia');
}

function createProperty(payload: CreatePropertyPayload): Promise<UpdateInitiator> {
  return CompanyResourcesApi.post('/pia/properties', [payload]);
}

function updateProperty(payload: Property): Promise<UpdateInitiator> {
  return CompanyResourcesApi.put('/pia/properties', [payload]);
}

function deleteProperties(propertiesIds: string[]): Promise<void> {
  return CompanyResourcesApi.delete('/pia/properties', propertiesIds);
}

function createItem(payload: CreateItemPayload): Promise<UpdateInitiator> {
  return CompanyResourcesApi.post('/pia/items', [payload]);
}

function updateItem(payload: UpdateItemRequestPayload): Promise<UpdateInitiator> {
  return CompanyResourcesApi.put('/pia/items', [payload]);
}

function deleteItems(payload: string[]): Promise<void> {
  return CompanyResourcesApi.delete('/pia/items', payload);
}

function addItemProperties(itemId: string, properties: Property[]): Promise<UpdateInitiator> {
  return CompanyResourcesApi.post(`/pia/items/${itemId}/properties`, properties);
}

function updateItemProperties(itemId: string, properties: Property[]): Promise<UpdateInitiator> {
  return CompanyResourcesApi.put(`/pia/items/${itemId}/properties`, properties);
}

function removeItemProperties(itemId: string, properties: string[]): Promise<UpdateInitiator> {
  return CompanyResourcesApi.delete(`/pia/items/${itemId}/properties`, properties);
}

function createItemGroup(payload: Group): Promise<void> {
  return CompanyResourcesApi.post('/pia/items/folders', [payload]);
}

function updateItemGroup(payload: Group): Promise<void> {
  return CompanyResourcesApi.put('/pia/items/folders', [payload]);
}

function deleteItemGroup(payload: string[]): Promise<void> {
  return CompanyResourcesApi.delete('/pia/items/folders', payload);
}

function createAssembly(payload: CreateAssemblyPayload): Promise<UpdateInitiator> {
  return CompanyResourcesApi.post('/pia/assemblies', [payload]);
}

function updateAssemblies(payload: UpdateAssemblyPayload[]): Promise<UpdateInitiator> {
  return CompanyResourcesApi.put('/pia/assemblies', payload);
}

function deleteAssembly(payload: string[]): Promise<void> {
  return CompanyResourcesApi.delete('/pia/assemblies', payload);
}

function createAssemblyGroup(group: Group): Promise<void> {
  return CompanyResourcesApi.post('/pia/assemblies/folders', [group]);
}

function updateAssemblyGroup(group: Group): Promise<void> {
  return CompanyResourcesApi.put('/pia/assemblies/folders', [group]);
}

function deleteAssemblyGroup(payload: string[]): Promise<void> {
  return CompanyResourcesApi.delete('/pia/assemblies/folders', payload);
}

function dumpDatabase(): Promise<void> {
  return CompanyResourcesApi.downloadFile('/pia/dump');
}


export const TwoDDatabaseApi = {
  fetchDataBase,

  createProperty,
  updateProperty,
  deleteProperties,

  createItem,
  updateItem,
  deleteItems,

  addItemProperties,
  updateItemProperties,
  removeItemProperties,

  deleteItemGroup,
  createItemGroup,
  updateItemGroup,

  createAssembly,
  updateAssemblies,
  deleteAssembly,

  createAssemblyGroup,
  updateAssemblyGroup,
  deleteAssemblyGroup,

  dumpDatabase,
};
