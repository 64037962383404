import { useCallback, useMemo, useState } from 'react';
import { MentionUtils } from 'unit-2d-comments/utils';

export interface UseInputTextResult {
  value: string;
  isValueValid: boolean;
  cancelEdit: () => void;
  onEdit: (text: string) => void;
}

const LIMIT = 512;

export function useInputText(defaultValue: string): UseInputTextResult {
  const [value, setValue] = useState(defaultValue);
  const onEdit = useCallback((newValue: string) => setValue(newValue), []);
  const isValid = useMemo(() => value?.trim() && MentionUtils.getTextLength(value) <= LIMIT, [value]);
  const cancelEdit = useCallback(() => setValue(defaultValue), [defaultValue]);

  return {
    onEdit,
    value,
    isValueValid: isValid,
    cancelEdit,
  };
}

