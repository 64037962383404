
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './edge-controls.scss';

import { TitleEditable } from 'common/components/title-editable';
import { State as ReduxState } from 'common/interfaces/state';
import { Direction } from '../../../../components/graph-viewer/enums';
import { EdgeControlsComponentProps, GraphEdge } from '../../../../components/graph-viewer/interfaces';
import { MacroSequenceActions } from '../../actions';
import { LimitationData } from '../../interfaces';
import { DeleteLinkPopup } from './delete-link-popup';

interface ReduxProps {
  limitation: LimitationData;
}

interface ReduxActions {
  setTimeLag: (edge: GraphEdge, timeLag: number) => void;
  deleteLimitation: (edge: GraphEdge) => void;
}

interface State {
  isEdit: boolean;
  showDelPopup: boolean;
  anchorY: number;
  anchorX: number;
}

interface Props extends ReduxProps, ReduxActions, EdgeControlsComponentProps { }

class EdgeControlsComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEdit: false,
      showDelPopup: false,
      anchorY: 0,
      anchorX: 0,
    };
  }
  public render(): JSX.Element {
    if (!this.props.limitation) {
      return null;
    }

    const style = this.getStyle();
    const className = classNames(
      'macro-sequence-edge-controls',
      { 'macro-sequence-edge-controls--reverse': this.props.direction === Direction.Back });

    return (
      <div className={className} style={style}>
        <div
          className={classNames(
            'macro-sequence-edge-controls__change-limitation',
            { 'macro-sequence-edge-controls__change-limitation--editable': this.state.isEdit },
          )}
        >
          <div className='macro-sequence-edge-controls__change-limitation-popup'>
            <TitleEditable
              sizeSmall={true}
              label=''
              text={this.props.limitation.timeLag.toString()}
              onChange={this.onChange}
              onEditStatusChanged={this.onEditStatusChanged}
              canEdit={true}
              valueType='integer'
            />
          </div>
          <div className='macro-sequence-edge-controls__icon-tooltip'>
            Time Lag
          </div>
        </div>
        <div
          className={classNames(
            'macro-sequence-edge-controls__edge-dot',
            { 'macro-sequence-edge-controls__edge-dot--popup': this.state.showDelPopup },
          )}
        >
          <DeleteLinkPopup
            isOpen={this.state.showDelPopup}
            onCancel={this.closeDelPopup}
            onDelete={this.removeEdge}
            anchorX={this.state.anchorX}
            anchorY={this.state.anchorY}
          />
          <div className='macro-sequence-edge-controls__remove-limitation-button' onClick={this.openDelPopup}>
            <div className='macro-sequence-edge-controls__icon-tooltip'>
              Delete Link
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private onEditStatusChanged(isEdit: boolean): void {
    this.props.onChangeEditStatus(isEdit);
    this.setState({ isEdit });
  }

  @autobind
  private onChange(value: string): void {
    const timeLag = parseFloat(value);
    if (!Number.isNaN(timeLag)) {
      this.props.setTimeLag(this.getEdge(), timeLag);
    }
  }

  @autobind
  private openDelPopup(e: React.MouseEvent<HTMLDivElement>): void {
    const buttonRect = e.currentTarget.getBoundingClientRect() as DOMRect;
    const popupBottom = buttonRect.y - 6;
    const buttonCenter = buttonRect.x + buttonRect.width / 2;
    this.setState({
      showDelPopup: true,
      anchorY: popupBottom,
      anchorX: buttonCenter,
    });
  }

  @autobind
  private closeDelPopup(): void {
    this.setState({ showDelPopup: false });
  }

  @autobind
  private removeEdge(): void {
    this.setState({ showDelPopup: false }, () => {
      this.props.deleteLimitation(this.getEdge());
    });
  }

  private getEdge(): GraphEdge {
    return {
      source: this.props.source,
      target: this.props.target,
      type: this.props.limitation.type,
    };
  }

  private getStyle(): React.CSSProperties {
    const { direction, from, to, vertexWidth } = this.props;
    const top = direction === Direction.Forward ? to.y : from.y;
    const left = direction === Direction.Forward ? to.x - 27 : from.x + vertexWidth - 9;
    return {
      left,
      top,
    };
  }
}

const mapStateToProps = (state: ReduxState, props: Props): ReduxProps => {
  const limitations = state.activityGrouping.macroSequence.graph.limitations;
  const limitation = limitations[props.source] && limitations[props.source][props.target];
  return {
    limitation,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setTimeLag: (edge, timeLag) => dispatch(MacroSequenceActions.setLimitationTimeLag(edge, timeLag)),
    deleteLimitation: edge => dispatch(MacroSequenceActions.removeEdge(edge)),
  };
};


const connector = connect(mapStateToProps, mapDispatchToProps);
export const EdgeControls = connector(EdgeControlsComponent);
