import { DrawingsGeometryInstance } from 'common/components/drawings';

export function hasUpdatedGeometry(
  currentGeometries: Record<string, DrawingsGeometryInstance>,
  previousGeometries: Record<string, DrawingsGeometryInstance>,
): boolean {
  if (!currentGeometries && !previousGeometries || currentGeometries === previousGeometries) {
    return false;
  } else if (!previousGeometries) {
    return true;
  }
  return Object.entries(currentGeometries).some(([key, value]) => {
    const prevInstanceData = previousGeometries[key];
    if (!prevInstanceData) {
      return true;
    }
    return value !== prevInstanceData
      && (value.name !== prevInstanceData.name || value.groupId !== prevInstanceData.groupId);
  });
}
