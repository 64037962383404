import { OpenArrowButtonStyled, Text } from '@kreo/kreo-ui-components';
import styled, { css } from 'styled-components';

import { InputSmallStyled } from 'common/components/inputs';
import { ValuesWithEditStyled } from 'common/components/values-with-edit';


const InputWithUnitStyle = css`
  > ${InputSmallStyled.InputStyle} {
    border-radius: 5px 0 0 5px;
    &:hover {
      z-index: 1;
    }
  }
  &:hover {
    ${OpenArrowButtonStyled.Container} {
      z-index: 1;
    }
  }
  > div:nth-child(2) > ${InputSmallStyled.InputStyle}:nth-child(1) {
    border-radius: 5px 0 0 5px;
    &:hover {
      ${OpenArrowButtonStyled.Container} {
        z-index: 2;
      }
      z-index: 1;
    }
  }
`;

const UnitWrap = styled.div`
  div:nth-child(1) > ${InputSmallStyled.InputStyle} {
    border-radius: 0 5px 5px 0;
  }
`;

const Container = styled.div`
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  margin: 2px 0;
  font-size: 14px;
  line-height: 1.5;

  > ${Text} {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
  }

  :hover {
    ${ValuesWithEditStyled.StartEditingButton} {
      display: block;
    }
  }
`;

const InputContainer = styled.div<{ hasUnit: boolean }>`
  position: relative;
  grid-area: span 1 / span 1 / span 1 / span 1;
  display: flex;
  ${(p) => {
    if (p.hasUnit) {
      return InputWithUnitStyle;
    }
    return '';
  }}
`;

export const Styled = {
  Container,
  InputContainer,
  InputWithUnitStyle,
  UnitWrap,
};
