import * as paper from 'paper';

import { VisibleEntity, VisibleEntityConfig } from '../../common';
import { DrawingsOffsetUtils } from '../../drawings-helpers';

interface Config extends VisibleEntityConfig<paper.Segment[]> {
  layer: paper.Layer | paper.Group;
  color: paper.Color;
  offset: number;
}

export class PolylineThickness extends VisibleEntity<paper.Segment[], Config> {
  private _destroyed: boolean = false;

  private _path: paper.Path | paper.CompoundPath;

  public destroy(): void {
    this._destroyed = true;
    if (this._path) {
      this._path.remove();
    }
  }

  public set fillColor(color: paper.Color) {
    this._config.color = color;
    if (this._path) {
      this._path.fillColor = color;
    }
  }

  protected render(geometry: paper.Segment[]): void {
    this.renderAsync(geometry);
  }


  private async renderAsync(geometry: paper.Segment[]): Promise<void> {
    const offseted = await DrawingsOffsetUtils.polylineAsyncOffset(
      geometry,
      x => x.point,
      this._config.offset,
      x => new paper.Point(x),
    );


    if (this._destroyed) {
      return;
    }

    this._path = new paper.CompoundPath({
      children: offseted.map(contour => new paper.Path(contour)),
      fillColor: this._config.color,
    });

    this._path.addTo(this._config.layer);
    this._path.sendToBack();
    if (this._destroyed) {
      this._path.remove();
    }
  }
}
