import * as Ably from 'ably';
import { END } from 'redux-saga';

import { StringDictionary } from 'common/interfaces/dictionary';
import { CommonApi } from '../../api/server';

export type ChannelNamespaces = 'user';

interface SubscriptionOnHold {
  resolve: (value?: {} | PromiseLike<{}>) => void;
  emit: (input: {} | END) => void;
  channelId: string | number;
}

type TokenCallback = (error: string, tokenRequestOrDetails: string) => void;
type AuthCallback = (data: Ably.Types.TokenParams, callback: TokenCallback) => void;

function createAuthCallback(url: string): AuthCallback {
  return (_token, callback) => {
    CommonApi.get<string>(url).then(x => callback(null, x));
  };
}

export class RealtimeConnectionProvider {
  private static client: Ably.Realtime = null;
  private static channels: StringDictionary<Ably.Types.RealtimeChannelCallbacks> = {};
  private static channelsForSubscribe: StringDictionary<SubscriptionOnHold> = {};

  public static initializeClient(token: string, authUrl: string): void {
    this.disconnectIfConnectionExists();
    this.client = new Ably.Realtime({ token, authCallback: createAuthCallback(authUrl) });
    for (
      const [channelNamespace, { resolve, emit, channelId }] of
      Object.entries(this.channelsForSubscribe) as Array<[ChannelNamespaces, SubscriptionOnHold]>
    ) {
      this.buildAndRegisterChannel(channelNamespace, channelId, resolve, emit);
    }
    this.channelsForSubscribe = {};
  }


  public static disconnectIfConnectionExists(): void {
    if (this.client) {
      this.client.close();
    }
  }

  public static subscribe(
    channelNamespace: ChannelNamespaces,
    channelId: string | number,
    resolve: (value?: {} | PromiseLike<{}>) => void,
    emit: (input: {} | END) => void,
  ): void {
    if (!this.client) {
      console.warn('realtime client isn`t initialized');
      this.channelsForSubscribe[channelNamespace] = {
        resolve,
        channelId,
        emit,
      };
    } else {
      if (channelNamespace in this.channels) {
        this.channels[channelNamespace].unsubscribe();
      }
      this.buildAndRegisterChannel(channelNamespace, channelId, resolve, emit);
    }
  }

  private static buildAndRegisterChannel(
    channelNamespace: ChannelNamespaces,
    channelId: string | number,
    resolve: (value?: {} | PromiseLike<{}>) => void,
    emit: (input: {} | END) => void,
  ): void {
    const channelKey = `${channelNamespace}:${channelId}`;
    const channel = this.client.channels.get(channelKey);
    channel.history((_error, page) => {
      resolve(page.items.map(x => x.data).reverse());
    });
    channel.subscribe(emit);
    this.channels[channelNamespace] = channel;
  }
}
