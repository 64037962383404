import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ComparableDocumentType, CompareDocument, ComparisonLine } from 'common/pdf/compare-document';
import { ModalRootWrapper } from 'common/UIKit/dialogs/modal-root-wrapper';
import { Header } from './header';
import { LinesSelectors } from './line-selector/line-selector';
import { Styled } from './styled';
import { useDrawing } from './use-drawing';
import { useSnapping } from './use-load-snapping';

interface Props {
  drawingRendered: boolean;
  currentDrawing: Core.Document | ComparableDocumentType;
  close: () => void;
  applyTransform: (transform: { sourceDrawingLine: ComparisonLine, targetDrawingLine: ComparisonLine }) => void;
}

export const ComparePagesLayout: React.FC<Props> = ({
  close,
  currentDrawing,
  drawingRendered,
  applyTransform,
}) => {
  const currentDrawingSnappingState = useSelector((state: State) => state.drawings.currentDrawingSnapping);
  const currentDrawingInfo = useSelector((state: State) => state.drawings.currentDrawingInfo);
  const {
    snapping: currentDrawingSnapping,
    loaded: currentDrawingSnappingLoaded,
  } = useSnapping(currentDrawing, currentDrawingInfo.drawingId, currentDrawingSnappingState);
  const [selectedDrawing, setSelectedDrawing] = useState<string>(currentDrawingInfo.drawingId);
  useEffect(() => {
    if (selectedDrawing !== currentDrawingInfo.drawingId && selectedDrawing !== currentDrawingInfo.pageToCompareId) {
      setSelectedDrawing(currentDrawingInfo.drawingId);
    }
  }, [currentDrawingInfo, selectedDrawing]);

  const [currentDocLine, setCurrentDocLine] = useState<ComparisonLine>(
    currentDrawingInfo.pagesCompareSettings?.sourceLine,
  );
  const [docToCompareLine, setDocToCompareLine] = useState<ComparisonLine>(
    currentDrawingInfo.pagesCompareSettings?.targetLine,
  );

  const loadedCurrentDrawing = useDrawing(currentDrawingInfo, currentDrawing);
  const documentToCompareInfo = useSelector(
    (state: State) => state.drawings.drawingsInfo[currentDrawingInfo.pageToCompareId],
  );
  const drawingToCompareWith = useDrawing(
    documentToCompareInfo,
    (loadedCurrentDrawing as CompareDocument).documentToCompareWith,
  );

  const { snapping, loaded } = useSnapping(drawingToCompareWith, currentDrawingInfo.pageToCompareId);

  const onCurrentDocLineChange = useCallback((p1, p2) => {
    setCurrentDocLine({ x1: p1.x, y1: p1.y, x2: p2.x, y2: p2.y });
  }, []);

  const onCompareDocLineChange = useCallback((p1, p2) => {
    setDocToCompareLine({ x1: p1.x, y1: p1.y, x2: p2.x, y2: p2.y });
  }, []);

  const selectedTab = selectedDrawing || currentDrawingInfo.drawingId;

  const reset = useCallback(() => {
    applyTransform(null);
  }, []);

  const apply = useCallback(() => {
    applyTransform({ sourceDrawingLine: currentDocLine, targetDrawingLine: docToCompareLine });
  }, [currentDocLine, docToCompareLine]);

  return (
    <ModalRootWrapper>
      <Styled.Container>
        <Header
          close={close}
          currentDrawingInfo={currentDrawingInfo}
          selectedDrawing={selectedDrawing}
          setSelectedDrawing={setSelectedDrawing}
          apply={apply}
          reset={reset}
          canSaveLines={!!currentDocLine && !!docToCompareLine}
        />
        <Styled.Content>
          {selectedTab === currentDrawingInfo.drawingId ? (
            <LinesSelectors
              key={currentDrawingInfo.drawingId}
              loaded={currentDrawingSnappingLoaded && drawingRendered}
              drawing={currentDrawing}
              snapping={currentDrawingSnapping}
              onLineChange={onCurrentDocLineChange}
              line={currentDocLine}
              drawingId={currentDrawingInfo.drawingId}
              currentDrawing={currentDrawingInfo}
            />
          ) : (
            <LinesSelectors
              key={currentDrawingInfo.pageToCompareId}
              drawing={drawingToCompareWith}
              loaded={loaded}
              snapping={snapping}
              onLineChange={onCompareDocLineChange}
              line={docToCompareLine}
              drawingId={currentDrawingInfo.pageToCompareId}
              currentDrawing={documentToCompareInfo}
            />
          )}
        </Styled.Content>
      </Styled.Container>
    </ModalRootWrapper>
  );
};
