import { Message } from '../interfaces';


interface CreateFirstPromptParams {
  userQuery: string;
  getPdfText: () => Promise<string>;
}

async function createFirstPrompt({
  userQuery,
  getPdfText,
}: CreateFirstPromptParams): Promise<Message> {
  const pdfText = await getPdfText();
  const query = `PDF content:\n ${pdfText} End of PDF content\n\n User query:\n ${userQuery}`;
  return { role: 'user', content: query, hideInHistory: true };
}

interface AddPromptToHistoryParams extends CreateFirstPromptParams {
  history: Message[];
}

async function addPromptToHistory({
  userQuery,
  getPdfText,
  history,
}: AddPromptToHistoryParams): Promise<Message[]> {
  const prompt = history.length > 1
    ? { role: 'user', content: userQuery } as Message
    : await createFirstPrompt({ userQuery, getPdfText });
  return history.concat(prompt);
}

function toChatMessage({ role, content }: Message): Message {
  return { role, content };
}

export const MessageUtils = {
  addPromptToHistory,
  toChatMessage,
};

