const prefix = '@quantity-take-off-report';

const GENERATE_DEFAULT_REPORT = `${prefix}/GENERATE_DEFAULT_REPORT`;
const GET_AVAILABLE_REPORT_GENERATORS = `${prefix}/GET_AVAILABLE_REPORT_GENERATORS`;
const CREATE_REPORT = `${prefix}/CREATE_REPORT`;
const PUBLISH_REPORT = `${prefix}/PUBLISH_REPORT`;
const GET_REPORTS_INFO = `${prefix}/GET_REPORTS_INFO`;
const GET_REPORT = `${prefix}/GET_REPORT`;
const UPDATE_REPORT_INFO = `${prefix}/UPDATE_REPORT_INFO`;
const DELETE_REPORT = `${prefix}/DELETE_REPORT`;
const ADD_REPORT_ROWS = `${prefix}/ADD_REPORT_ROWS`;
const UPDATE_REPORT_ROWS = `${prefix}/UPDATE_REPORT_ROWS`;
const DELETE_REPORT_ROWS = `${prefix}/DELETE_REPORT_ROWS`;
const REORDER_REPORT_ROWS = `${prefix}/REORDER_REPORT_ROWS`;
const UPDATE_REPORT_BASIC_COLUMNS = `${prefix}/UPDATE_REPORT_BASIC_COLUMNS`;
const UPDATE_REPORT_PIVOT_COLUMNS = `${prefix}/UPDATE_REPORT_PIVOT_COLUMNS`;
const EXPORT_REPORT_TO_EXCEL = `${prefix}/EXPORT_REPORT_TO_EXCEL`;
const EXPORT_DEFAULT_REPORTS_TO_EXCEL = `${prefix}/EXPORT_DEFAULT_REPORTS_TO_EXCEL`;
const SET_REPORTS_INFO  = `${prefix}/SET_REPORTS_INFO`;
const SET_SELECTED_REPORT_ID  = `${prefix}/SET_SELECTED_REPORT_ID`;
const SET_REPORT  = `${prefix}/SET_REPORT`;
const SET_AVAILABLE_REPORT_GENERATORS  = `${prefix}/SET_AVAILABLE_REPORT_GENERATORS`;
const PUT_MESSAGE_TO_UPDATE_REPORT_QUEUE  = `${prefix}/PUT_MESSAGE_TO_UPDATE_REPORT_QUEUE`;
const UPDATE_REPORT_QUEUE_MESSAGE_DONE  = `${prefix}/UPDATE_REPORT_QUEUE_MESSAGE_DONE`;
const UPDATE_REPORT_QUEUE_MESSAGE_FAILED  = `${prefix}/UPDATE_REPORT_QUEUE_MESSAGE_FAILED`;
const GET_AVAILABLE_REPORT_FOR_COPY = `${prefix}/GET_AVAILABLE_REPORT_FOR_COPY`;
const SET_REPORTS_FOR_COPY = `${prefix}/SET_REPORTS_FOR_COPY`;
const COPY_REPORT = `${prefix}/COPY_REPORT`;
const GET_INITIAL_STATE = `${prefix}/GET_INITIAL_STATE`;
const CREATE_TEMPLATE_FROM_REPORT = `${prefix}/CREATE_TEMPLATE_FROM_REPORT`;
const UPDATE_REPORT_AFTER_CREATION_VIA_TEMPLATE = `${prefix}/UPDATE_REPORT_AFTER_CREATION_VIA_TEMPLATE`;
const CREATE_REPORT_FINISH = `${prefix}/CREATE_REPORT_FINISH`;

export const QuantityTakeOffReportActionTypes = {
  GENERATE_DEFAULT_REPORT,
  GET_AVAILABLE_REPORT_GENERATORS,
  CREATE_REPORT,
  PUBLISH_REPORT,
  GET_REPORTS_INFO,
  GET_REPORT,
  UPDATE_REPORT_INFO,
  DELETE_REPORT,
  ADD_REPORT_ROWS,
  UPDATE_REPORT_ROWS,
  DELETE_REPORT_ROWS,
  REORDER_REPORT_ROWS,
  UPDATE_REPORT_BASIC_COLUMNS,
  UPDATE_REPORT_PIVOT_COLUMNS,
  EXPORT_REPORT_TO_EXCEL,
  EXPORT_DEFAULT_REPORTS_TO_EXCEL,
  GET_AVAILABLE_REPORT_FOR_COPY,
  CREATE_TEMPLATE_FROM_REPORT,
  CREATE_REPORT_FINISH,

  SET_REPORTS_INFO,
  SET_SELECTED_REPORT_ID,
  SET_REPORT,
  SET_AVAILABLE_REPORT_GENERATORS,
  SET_REPORTS_FOR_COPY,
  COPY_REPORT,
  GET_INITIAL_STATE,

  PUT_MESSAGE_TO_UPDATE_REPORT_QUEUE,
  UPDATE_REPORT_QUEUE_MESSAGE_DONE,
  UPDATE_REPORT_QUEUE_MESSAGE_FAILED,
  UPDATE_REPORT_AFTER_CREATION_VIA_TEMPLATE,
};
