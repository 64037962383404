import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Item } from './menu-item-container';
import { ContextMenuActions } from './store-slice';


interface CoordinateProps {
  x: number;
  y: number;
}

interface ExtraProps {
  menuWidth: number;
  sizeItem: string;
}


function getCoordinateMouse(e: React.MouseEvent<HTMLDivElement, MouseEvent>): CoordinateProps {
  return {
    x: e.clientX,
    y: e.clientY,
  };
}

function getCoordinateElement(e: React.MouseEvent<HTMLButtonElement>): CoordinateProps {
  const { left, top } = e.currentTarget.getBoundingClientRect();
  const offsetX = e.currentTarget.clientWidth / 2;
  const offsetY = e.currentTarget.clientHeight / 2;
  const x = left + offsetX;
  const y = top + offsetY;

  return {
    x,
    y,
  };
}

function useOpenContextMenu(
  getMenuItems: () => Item[],
  extraProps: ExtraProps,
): (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void {
  const dispatch = useDispatch();
  return useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ConstantFunctions.preventDefault(e);
    const menuItems = getMenuItems();
    const { x, y } = getCoordinateMouse(e);
    dispatch(ContextMenuActions.set({ contextMenuPositionX: x, contextMenuPositionY: y, menuItems, ...extraProps }));
  }, [getMenuItems, dispatch, extraProps]);
}

function useOpenContextMenuButton(
  getMenuItems: () => Item[],
  extraProps: ExtraProps,
): (e: React.MouseEvent<HTMLButtonElement>) => void {
  const dispatch = useDispatch();
  return useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    ConstantFunctions.preventDefault(e);
    const menuItems = getMenuItems();
    const { x, y } = getCoordinateElement(e);
    dispatch(ContextMenuActions.set({ contextMenuPositionX: x, contextMenuPositionY: y, menuItems, ...extraProps }));
  }, [getMenuItems, dispatch, extraProps]);
}

export const ContextMenuUtils = {
  useOpenContextMenu,
  useOpenContextMenuButton,
};
