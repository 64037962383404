import { Icons } from '@kreo/kreo-ui-components';
import { createContext, useContext } from 'react';

interface EditContextValue {
  editIcon: React.ComponentType;
  startEditing: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const EditContext = createContext<EditContextValue>(null);

export const useEditContext = (onDeleteClick?: (e: React.MouseEvent<HTMLButtonElement>) => void): EditContextValue => {
  const editContext = useContext(EditContext);
  const editIcon = editContext
    ? editContext.editIcon
    : Icons.CloseSmall;
  const startEditing = editContext
    ? editContext.startEditing
    : onDeleteClick;

  return {
    editIcon,
    startEditing,
  };
};
