import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import { change } from 'redux-form';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseActivityActions } from '../../actions/creators/database-activity';
import { DatabaseActivityListingActions } from '../../actions/creators/database-activity-listing';
import { ModifyDatabaseEntityMode } from '../../enums';
import { ActivityModel, ExtractorFunctionModel } from '../../interfaces/data';
import { ActivityForm } from '../../interfaces/rest-data';
import { ChangeFormHelper } from '../../utils/change-form-helper';
import { RestFormMapper } from '../../utils/rest-form-mapper';
import { DatabaseActivity } from '../database-activity';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';

interface ReduxProps {
  activity: ActivityModel;
  activityId: number;
  databaseId: number;
  functions: ExtractorFunctionModel[];
  form: ActivityForm;
}

interface ReduxActions {
  setEditActivityModel: (activity: ActivityModel) => void;
  loadActivity: (databaseId: number, activityId: number) => void;
  updateActivity: (databaseId: number, activityId: number, activity: ActivityForm) => void;
  applyFieldChanges: (field: string, value: any) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps {
}

interface State {
  initialValues: ActivityForm;
}

const formId: string = 'edit-activity';
class EditDatabaseActivityComponent extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { initialValues: null };
  }

  public componentDidMount(): void {
    this.props.loadActivity(this.props.databaseId, this.props.activityId);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.activity === null && this.props.activity !== null) {
      const { activity, functions } = this.props;
      this.setState({ initialValues: RestFormMapper.GetActivityForm(activity, functions) });
    }
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Activity'
        isApplyButtonDisabled={this.isSaveButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Edit}
        onSubmit={this.save}
        readonly={this.props.readonly}
        form={formId}
        initialValues={this.state.initialValues}
      >
      {
        this.props.activity ? (
          <KreoScrollbars>
            <DatabaseActivity
              activity={this.props.activity}
              onChange={this.onChange}
              readonly={this.props.readonly}
            />
          </KreoScrollbars>
        ) : <SvgSpinner size='large'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }


  @autobind
  private save(values: ActivityForm): void {
    this.props.updateActivity(this.props.databaseId, this.props.activityId, values);
  }

  @autobind
  private onChange(model: ActivityModel): void {
    const form = RestFormMapper.GetActivityForm(model, this.props.functions);
    ChangeFormHelper.applyChanges(this.props.form, form, this.props.applyFieldChanges);
    this.props.setEditActivityModel(model);
  }

  private isSaveButtonDisabled(): boolean {
    const { activity } = this.props;
    return !activity || !activity.name || !activity.name.trim();
  }
}


const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    activity: editModel.root,
    activityId: editModel.rootId,
    databaseId: state.database.currentDatabase.database.id,
    functions: state.database.currentDatabase.functions,
    form: state.form[formId] && state.form[formId].values,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditActivityModel: activity =>
      dispatch(DatabaseActivityListingActions.setEditActivityModel(activity)),
    loadActivity: (databaseId, activityId) => dispatch(DatabaseActivityActions.loadActivity(databaseId, activityId)),
    updateActivity: (databaseId, activityId, activity) =>
      dispatch(DatabaseActivityActions.updateActivity(databaseId, activityId, activity, formId)),
    applyFieldChanges: (field: string, value: any) => dispatch(change(formId, field, value)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const EditDatabaseActivity = connector(EditDatabaseActivityComponent);
