import { TinyText, Icons, IconButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  switchToSelectMode: () => void;
  isBaseSelected: boolean;
  caption: string;
}

export const BaseSelectedStatus = React.memo<Props>(
  ({ switchToSelectMode, isBaseSelected, caption }) => {
    return (
      <Styled.SelectedPolygons isSelected={isBaseSelected}>
        <TinyText>{caption}</TinyText>
        <IconButton
          onClick={switchToSelectMode}
          height={30}
          Icon={isBaseSelected ? Icons.ApplyXs : Icons.CloseSmall}
          iconHeight={10}
          defaultColor={isBaseSelected ? 'green' : 'red'}
        />
      </Styled.SelectedPolygons>
    );
  },
);
