import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './database-base-layout.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { DatabaseActions } from '../../actions/creators/database';
import { DatabaseCommonActions } from '../../actions/creators/database-common';
import { DatabaseModel, ExtractorFunctionModel, UnitModel } from '../../interfaces/data';

interface ReduxProps {
  databases: DatabaseModel[];
  currentDatabase: DatabaseModel;
  companyId: number;
  units: UnitModel[];
  functions: ExtractorFunctionModel[];
  categoriesLoadStatus: RequestStatus;
  databaseResourcesRequestStatus: RequestStatus;
  databasesRequestStatus: RequestStatus;
}

interface ReduxActions {
  setCurrentDatabase: (database: DatabaseModel) => void;
  loadDatabases: (companyId: number) => void;
  loadActivityCategories: () => void;
}

interface Props extends ReduxProps, ReduxActions {
  databaseId: number;
  children?: React.ReactNode;
}

class DatabaseBaseLayoutComponent extends React.Component<Props> {
  public componentDidMount(): void {
    if (this.props.databasesRequestStatus === RequestStatus.NotRequested) {
      this.props.loadDatabases(this.props.companyId);
    } else if (!this.props.currentDatabase || this.props.currentDatabase.id !== this.props.databaseId) {
      const database = this.props.databases.find(x => x.id === this.props.databaseId);
      this.props.setCurrentDatabase(database);
    }
    if (this.props.categoriesLoadStatus !== RequestStatus.Loaded) {
      this.props.loadActivityCategories();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.databasesRequestStatus === RequestStatus.NotRequested) {
      this.props.loadDatabases(this.props.companyId);
    }

    if (
      this.props.databasesRequestStatus === RequestStatus.Loaded && this.props.databaseId && !this.props.currentDatabase
      || prevProps.databaseId !== this.props.databaseId) {
      const database = this.props.databases.find(x => x.id === this.props.databaseId);
      this.props.setCurrentDatabase(database);
    }
  }

  public render(): JSX.Element {
    const { currentDatabase, databaseResourcesRequestStatus } = this.props;
    const isLoaded = currentDatabase && databaseResourcesRequestStatus === RequestStatus.Loaded;

    return (
      <div className='database-master'>
        {isLoaded ? (
          <div className='database-master__content'>
            {this.props.children}
          </div>
        ) : (
          <SvgSpinner size='large' />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    currentDatabase: state.database.currentDatabase.database,
    databases: state.database.databases.filter(x => !x.version || x.vendor),
    units: state.database.currentDatabase.units,
    functions: state.database.currentDatabase.functions,
    companyId: state.account.selectedCompany && state.account.selectedCompany.id,
    categoriesLoadStatus: state.database.statuses.categories,
    databaseResourcesRequestStatus: state.database.statuses.databaseResources,
    databasesRequestStatus: state.database.statuses.databases,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadDatabases: companyId => dispatch(DatabaseActions.loadDatabases(companyId)),
    setCurrentDatabase: database => dispatch(DatabaseActions.setCurrentDatabase(database)),
    loadActivityCategories: () => dispatch(DatabaseCommonActions.loadActivityCategories()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const DatabaseBaseLayout = connector(DatabaseBaseLayoutComponent);
