import { MultilevelSelectOptionData } from './interfaces/multi-level-select-option-data';

type Comparer<T> = (value: T) => boolean;


function *recursiveFilter<T extends MultilevelSelectOptionData<T>>(
  values: T[],
  compare: Comparer<T>,
): IterableIterator<T> {
  for (const value of values) {
    if (compare(value)) {
      yield value;
    } else if (value.children && value.children.length) {
      const newValue = { ...value };
      const children = new Array<T>();
      for (const child of recursiveFilter(value.children, compare)) {
        children.push(child);
      }
      newValue.children =  children;
      if (children.length) {
        yield newValue;
      }
    }
  }
}


export const MultiLevelSelectUtils = {
  recursiveFilter,
};
