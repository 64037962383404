import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './measurements-extractor-item.scss';

import { State } from 'common/interfaces/state';
import { MeasurementsActions } from '../../actions/creators/measurements';
import {
  MeasurementExtractorFunctionTemplate,
} from '../../interfaces/measurements/measurement-extractor-function-template';
import { MeasurementValueGroup } from '../../interfaces/measurements/measurement-value';
import {
  WithMeasurementsEngineContextAwareProps,
} from '../../interfaces/measurements/with-measurements-engine-context-aware-props';
import { MeasurementExtractorEditorRow } from '../measurements-extractor-editor-row/measurements-extractor-editor-row';
import { MeasurementsExtractorInput } from '../measurements-extractor-input';
import { MeasurementsExtractorItemInnerContainer } from '../measurements-extractor-item-inner-container';
import { withMeasurementEngineLayoutContext } from '../with-measurement-engine-layout-context';


interface OwnProps {
  isDisabled: boolean;
  itemIndex: number;
  isSelected: boolean | undefined;
  name: string;
  values: Record<string, MeasurementValueGroup>;
  engineId: number;
  isError: boolean;
}

interface StateProps {
  functionTemplates: Record<string, MeasurementExtractorFunctionTemplate>;
}

interface DispatchProps {
  selectDisabledItem: () => void;
}

interface Props extends OwnProps, StateProps, DispatchProps, WithMeasurementsEngineContextAwareProps  {

}

class MeasurementsExtractorItemComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isDisabled, isSelected, isError, itemIndex, name } = this.props;
    return (
      <MeasurementExtractorEditorRow
        isDisabled={isDisabled}
        isError={isError}
        isSelected={isSelected}
        index={itemIndex}
        title={name}
        onCheck={this.onCheck}
        onDisabledItemClick={this.onClickItem}
      >
        {this.renderInputs()}
      </MeasurementExtractorEditorRow>
    );
  }

  @autobind
  private renderInputs(): React.ReactNode {
    const { functionTemplates, values, engineId, isDisabled, itemIndex } = this.props;
    return  Object.entries(functionTemplates).map(([templateKey, template]) => {
      return (
        <MeasurementsExtractorItemInnerContainer
          key={templateKey}
          doubleInputs={template.extractors.length > 1}
        >
          {template.extractors.map(
            extractor => extractor.extractorFunctionId in values ? (
              <MeasurementsExtractorInput
                disabled={isDisabled}
                generalFunctionId={template.generalFunctionId}
                key={extractor.extractorFunctionId || 'undefined'}
                engineId={engineId}
                functionId={extractor.extractorFunctionId}
                onItemSelect={this.props.onSelectElements}
                itemIndex={itemIndex}
                isTotal={false}
              />
            ) : null)
          }
        </MeasurementsExtractorItemInnerContainer>
      );
    });
  }

  @autobind
  private onClickItem(): void {
    this.props.selectDisabledItem();
    this.props.onSelectElements([this.props.engineId]);
  }

  @autobind
  private onCheck(value: boolean): void {
    if (value) {
      this.props.onAddSelected(this.props.engineId);
    } else {
      this.props.onDeselectElement(this.props.engineId);
    }
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    functionTemplates: state.measurements.extractorFunctions,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<AnyAction>,
  { itemIndex }: OwnProps,
): DispatchProps {
  return {
    selectDisabledItem: () => {
      dispatch(MeasurementsActions.disabledItemSelectStatus(itemIndex));
    },
  };
}

const WithEngineLayoutApi = withMeasurementEngineLayoutContext(MeasurementsExtractorItemComponent);
const connector = connect(mapStateToProps, mapDispatchToProps);
export const MeasurementsExtractorItem = connector(WithEngineLayoutApi);
