import { ColDef, ColGroupDef } from 'ag-grid-community';
import {
  GraphStorageRecordsConfig,
  RecordConfig,
} from '../../interfaces/graph-storage-records-config';
import { QtoLeftPanelConstants } from './constants';

export class ColumnOrderHelper {
  private defaultElementColumnGroupOrder: Record<string, number> = {};
  private defaultLocationColumnGroupOrder: Record<string, number> = {};
  private defaultElementColumnOrder: Record<string, number> = {};
  private defaultLocationColumnOrder: Record<string, number> = {};

  public constructor(recordsConfig: GraphStorageRecordsConfig) {
    Object.entries(recordsConfig).forEach(([key, value]) => {
      if (key !== QtoLeftPanelConstants.USER_EXTRACTORS_KEY && key !== QtoLeftPanelConstants.BREAKDOWN_GROUPS_KEY) {
        if (key === QtoLeftPanelConstants.EXTRACTORS_KEY) {
          const extractorConfig = value;
          Object.keys(extractorConfig).forEach((extKey) => {
            if (extractorConfig[extKey][QtoLeftPanelConstants.QTO_COLUMNS_ELEMENT]) {
              this.defaultElementColumnOrder[extKey] =
                extractorConfig[extKey][QtoLeftPanelConstants.QTO_COLUMNS_ELEMENT] as number;
            }

            if (extractorConfig[extKey][QtoLeftPanelConstants.QTO_COLUMNS_LOCATION]) {
              this.defaultLocationColumnOrder[extKey] =
                extractorConfig[extKey][QtoLeftPanelConstants.QTO_COLUMNS_LOCATION] as number;
            }
          });
        } else {
          const record = value as RecordConfig;
          if (record.ui_groups[QtoLeftPanelConstants.QTO_COLUMNS_ELEMENT]) {
            this.defaultElementColumnOrder[key] =
              record.ui_groups[QtoLeftPanelConstants.QTO_COLUMNS_ELEMENT];
          }

          if (record.ui_groups[QtoLeftPanelConstants.QTO_TABLE_ELEMENT]) {
            this.defaultElementColumnGroupOrder[key] = record.ui_groups[QtoLeftPanelConstants.QTO_TABLE_ELEMENT];
          }

          if (record.ui_groups[QtoLeftPanelConstants.QTO_COLUMNS_LOCATION]) {
            this.defaultLocationColumnOrder[key] = record.ui_groups[QtoLeftPanelConstants.QTO_COLUMNS_LOCATION];
          }

          if (record.ui_groups[QtoLeftPanelConstants.QTO_TABLE_LOCATION]) {
            this.defaultLocationColumnGroupOrder[key] = record.ui_groups[QtoLeftPanelConstants.QTO_TABLE_LOCATION];
          }
        }
      }
    });
  }

  public sortColumnsConfig(columnDefs: Array<ColDef | ColGroupDef>, isLocation: boolean): void {
    const columnsOrder = isLocation
      ? this.defaultLocationColumnOrder
      : this.defaultElementColumnOrder;

    columnDefs.sort((colA, colB) => {
      if ((colA as ColGroupDef).children) {
        this.sortColumnsConfig((colA as ColGroupDef).children, isLocation);
      }
      if ((colB as ColGroupDef).children) {
        this.sortColumnsConfig((colB as ColGroupDef).children, isLocation);
      }
      const aValue = columnsOrder[(colA as ColDef).field];
      const bValue = columnsOrder[(colB as ColDef).field];
      return aValue - bValue;
    });
  }

  public getColumnGroupOrder(isLocation: boolean): Record<string, number> {
    return isLocation ? this.defaultLocationColumnGroupOrder : this.defaultElementColumnGroupOrder;
  }
}
