import classNames from 'classnames';
import React, { Component } from 'react';

import './top-card-panel.scss';

import { IconButton } from '../buttons';
import { KreoIconCancel, KreoIconSubnavBack } from '../icons';

interface Props {
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  content?: any;
  back?: boolean;
  exit?: boolean;
  onBackClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onExitClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  controlNameButtonBack?: string;
  controlNameButtonClose?: string;
}

class TopCardPanel extends Component<Props> {
  public render(): JSX.Element {
    const { style, contentStyle, back, exit, className, controlNameButtonBack, controlNameButtonClose } = this.props;

    const topCardPanelClassName = classNames('top-card-panel', className);

    return (
      <div className={topCardPanelClassName} style={style ? { ...style } : {}}>
        <div className='top-card-panel__content' style={contentStyle ? { ...style } : {}}>
          {back === true && (
            <div className='top-card-panel__back-button'>
              <IconButton
                size='medium'
                onClick={this.props.onBackClick}
                controlName={controlNameButtonBack}
                icon={<KreoIconSubnavBack />}
              />
            </div>
          )}
          <div className='top-card-panel__content-container'>
            {this.props.content ? this.props.content : this.props.children}
          </div>
          {exit === true && (
            <div className='top-card-panel__back-button'>
              <IconButton
                size='medium'
                onClick={this.props.onExitClick}
                controlName={controlNameButtonClose}
                icon={<KreoIconCancel />}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TopCardPanel;
