import { AnyAction } from 'redux';


import { ACTIVITY_ASSIGNMENT_INITIAL_STATE } from '../units/projects/constants/activity-assignment-initial-state';
import {
  ActivityAssignmentState,
} from '../units/projects/interfaces/activity-assignment/activity-assignment-state';
import { ActivityAssignmentReducerMethods } from '../units/projects/reducers/activity-assignment-reducer-methods';

export default function(
  state: ActivityAssignmentState = ACTIVITY_ASSIGNMENT_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): ActivityAssignmentState {
  if (action.type in ActivityAssignmentReducerMethods) {
    return ActivityAssignmentReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
