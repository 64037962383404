import autobind from 'autobind-decorator';
import * as React from 'react';

import { KreoIconSubnavForward } from 'common/UIKit';
import { FilterPanelOption } from '../../filter-panel/filter-panel-option';
import { FilterValue } from '../../filter-select/interfaces';
import { EMPTY_VALUE } from '../constants';

import './tree-filter-select-title.scss';

interface Props {
  name: string;
  value: FilterValue[];
  onDelete: (value: string) => void;
  icon?: JSX.Element;
}

export class TreeFilterSelectTitle extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { name, value, icon } = this.props;
    const isValueSelected = !!value.length;
    return (
      <>
        <div className='tree-filter-select-title__container' >
          <div className='tree-filter-select-title__name' >
            {icon}
            {name}
          </div>
          <div className='tree-filter-select-title__button' >
            <KreoIconSubnavForward />
          </div>
        </div>
        {isValueSelected && <div className='tree-filter-select-title__value' onClick={this.disableClick}>
          {value.map(x =>
            (<FilterPanelOption
              columnName={name}
              value={x.value}
              name={!x.name || x.name === EMPTY_VALUE ? x.value : x.name.replace(/_/g, ' ')}
              key={`${x.name}/${x.value}`}
              deleteFilterItem={this.onDelete}
            />))}
        </div>}
      </>);
  }

  private disableClick(e: React.SyntheticEvent): void {
    e.stopPropagation();
  }

  @autobind
  private onDelete(_: string, value: string): void {
    this.props.onDelete(value);
  }
}
