import styled from 'styled-components';

const Container = styled.div`
  button:nth-child(2) {
    text-align: center;
  }
`;

export const Styled = {
  Container,
};
