import { Utils } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as paper from 'paper';

const ColorUtils = Utils.ColorUtils;

import {
  DrawingsCanvasColors,
  DrawingsCanvasConstants,
  DrawingsTextEntitySettings,
  DrawingsTextEntitySettingsInterface,
} from '../../constants/drawing-canvas-constants';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { VisibleEntity, VisibleEntityConfig } from '../common';
import { PaperObjectEventsApplier } from '../interfaces';

export enum PinType {
  CENTER,
  LEFT,
  RIGHT,
  BOTTOM,
  TOP,
}

interface DrawingsGeometryEntityTextConfig extends VisibleEntityConfig<paper.Point> {
  text: string;
  layer: paper.Layer | paper.Group;
  color: paper.Color;
  parentPath?: paper.Path | paper.CompoundPath;
  withIcon?: boolean;
  angle?: number;
  opacity?: number;
  pinType?: PinType;
  eventApplier?: PaperObjectEventsApplier;
  onClick?: (e: PaperMouseEvent) => void;
  onDoubleClick?: (e: PaperMouseEvent) => void;
  onMouseEnter?: (e: PaperMouseEvent) => void;
  onMouseLeave?: (e: PaperMouseEvent) => void;
  onMouseDown?: (e: PaperMouseEvent) => void;
  onMouseUp?: (e: PaperMouseEvent) => void;
  getRotation?: () => number;
}

export class DrawingsGeometryEntityText extends VisibleEntity<paper.Point, DrawingsGeometryEntityTextConfig> {
  /* paper.js data */
  private _rectangle: paper.Rectangle;
  private _polygon: paper.Path;
  private _group: paper.Group;
  private _text: paper.PointText;

  /* state */
  private _rectSettings: DrawingsTextEntitySettingsInterface;

  constructor(config: DrawingsGeometryEntityTextConfig) {
    super(config);
    this._config.renderParamsContextObserver.subscribe(this.changeZoom);
  }

  public get opacity(): number {
    return this._group.opacity;
  }

  public set opacity(value: number) {
    this._group.opacity = value;
  }

  public set parentPath(parentPath: paper.Path | paper.CompoundPath) {
    this._config.parentPath = parentPath;
    this.updateVisible();
  }

  public set rotation(angle: number) {
    this.rotateTextAndPolygon(angle - this._config.angle);
    this._config.angle = angle;
  }

  public changeColor(color: paper.Color): void {
    if (this._config.color === color) {
      return;
    }
    this._polygon.strokeColor = color;
    this._polygon.fillColor = color;
    this._text.fillColor = this.getTextColor(color);
    this._config.color = color;
  }

  public changePosition(point: ShortPointDescription | paper.Point): void {
    this._config.geometry = new paper.Point(point);
    this.applyPosition();
  }

  public changePinType(pinType: PinType): void {
    this._config.pinType = pinType;
    this.applyPosition();
  }

  public destroy(): void {
    this._config.renderParamsContextObserver.unsubscribe(this.changeZoom);
    this._polygon.remove();
    this._text.remove();
    this._group.remove();
  }

  public updateText(text: string): void {
    this.rotateTextAndPolygon(-this._config.angle);
    this._text.content = text;
    this.renderPolygon();
  }

  public renderPolygon(): void {
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    this._text.fontSize = DrawingsCanvasConstants.fontSize / zoom;
    const { topLeft, size } = this._text.bounds;
    const zoomPaddingVertical = this._rectSettings.padding.vertical / zoom;
    const zoomPaddingHorizontal = this._rectSettings.padding.horizontal / zoom;
    const zoomPoint = new paper.Point(
      topLeft.x - zoomPaddingHorizontal,
      topLeft.y - zoomPaddingVertical,
    );
    this._rectangle.point.set(zoomPoint);
    this._rectangle.size.set(size.width + zoomPaddingHorizontal * 2, size.height + zoomPaddingVertical * 2);

    const rectangle = new paper.Path.Rectangle(this._rectangle, this._rectSettings.round.divide(zoom));
    if (this._polygon) {
      this._polygon.removeSegments();
      this._polygon.addSegments(rectangle.segments);
      rectangle.remove();
    } else {
      this._polygon = rectangle;
      this._polygon.addTo(this._group);
      this._polygon.sendToBack();
      this._polygon.strokeColor = this._config.color;
    }
    this._text.fillColor = this.getTextColor(this._config.color);
    this._polygon.strokeWidth = this._rectSettings.strokeWidth / zoom;
    this._polygon.fillColor = this._config.color;
    this.updateVisible();
  }

  protected render(geometry: paper.Point): void {
    if (!this._config.angle) {
      this._config.angle = 0;
    }
    this._text = new paper.PointText(geometry);
    this._text.content = this._config.text;
    this._text.fontSize = DrawingsCanvasConstants.fontSize;
    this._text.fontFamily = 'ProximaSoft';
    this._rectSettings = this._config.withIcon
      ? DrawingsTextEntitySettings.textWithIcon
      : DrawingsTextEntitySettings.textGroup;
    this._rectangle = new paper.Rectangle(geometry, this._rectSettings.size);
    this._group = new paper.Group;
    this._text.addTo(this._group);
    this._group.addTo(this._config.layer);
    this.renderPolygon();
    if (this._config.eventApplier) {
      this._config.eventApplier.setHandlers(this._group);
    }
    this._group.onMouseEnter = this._config.onMouseEnter;
    this._group.onMouseLeave = this._config.onMouseLeave;
    this._group.onMouseDown = this._config.onMouseDown;
    if (this._config.opacity !== undefined) {
      this.opacity = this._config.opacity;
    }
  }

  @autobind
  private changeZoom(): void {
    this.rotateTextAndPolygon(-this._config.angle);
    this.renderPolygon();
  }

  private rotateTextAndPolygon(rotation: number): void {
    this._text.rotate(rotation, this._group.position);
    this._polygon.rotate(rotation, this._group.position);
  }

  private updateVisible(): void {
    const visible = !this._config.parentPath || this._config.parentPath.bounds.width > this._polygon.bounds.width;
    this._polygon.visible = visible;
    this._text.visible = visible;
    this._group.position = this._config.geometry;
    if (this._config.angle) {
      this.rotateTextAndPolygon(this._config.angle);
    }
  }

  private getTextColor(rectColor: paper.Color): paper.Color {
    return ColorUtils.needReverseTextColorByBackground(rectColor, true)
      ? DrawingsCanvasColors.darkBlue
      : DrawingsCanvasColors.white;
  }

  private applyPosition(): void {
    const halfWidth = this._rectangle.width / 2;
    const halfHeight = this._rectangle.height / 2;
    const { x, y } = this._config.geometry;
    switch (this._config.pinType) {
      case PinType.BOTTOM:
        this._group.position = new paper.Point(x, y - halfHeight);
        break;
      case PinType.TOP:
        this._group.position = new paper.Point(x, y + halfHeight);
        break;
      case PinType.LEFT:
        this._group.position = new paper.Point(x + halfWidth, y);
        break;
      case PinType.RIGHT:
        this._group.position = new paper.Point(x - halfWidth, y);
        break;
      default:
        this._group.position = new paper.Point(x, y);
    }
  }
}

