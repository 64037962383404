import * as t from 'io-ts';

export const ProjectsFolderC = t.intersection(
  [
    t.type({
      id: t.number,
      name: t.string,
    }),
    t.partial({
      parentFolderId: t.number,
    }),
  ],
  'Folder');

export type ProjectsFolder = t.TypeOf<typeof ProjectsFolderC>;

export const ProjectsFoldersC = t.array(ProjectsFolderC);

export interface ProjectsFolderFormData {
  name: string;
  parentFolderId: number;
}
