import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './cost-page-layout.scss';

import { KreoButton, KreoScrollbars } from 'common/UIKit';
import PageBackButton from '../../../../components/page-back-btn';
import { ProjectLayout } from '../../../project-dashbord';
import { UserBidPricingStatus } from '../../enums/user-bid-pricing-status';
import { BidDescriptionDialog } from './bid-description-dialog';

interface Props {
  projectId: string;
  caption: string;
  status: UserBidPricingStatus;
  description: string;
  isSubcontractor: boolean;
  onButtonClick: () => void;
  navigate: () => void;
  onDescriptionClick: () => void;
  updateBidDescription?: (description: string) => void;
}

interface State {
  fixedHeader: boolean;
}

export class CostPageLayout extends React.Component<Props, State> {
  private scrollMasterContainer: HTMLDivElement;
  private tableContainer: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      fixedHeader: false,
    };
  }
  public render(): React.ReactNode {
    const { isSubcontractor, onDescriptionClick, description } = this.props;
    return (
      <ProjectLayout projectId={this.props.projectId}>
        <KreoScrollbars onSendRef={this.saveScrollRef} onScroll={this.onScroll}>
          <div className={classNames('bid-cost-page-layout', { 'fixed-header': this.state.fixedHeader })}>
            <div className='bid-cost-page-layout__header'>
              <PageBackButton navigate={this.props.navigate} />
              <span className='bid-cost-page-layout__header__name'>{this.props.caption}</span>
              <div className='bid-cost-page-layout__header__buttons'>
                <KreoButton size='medium' className='table-btn header-action' onClick={onDescriptionClick}>
                  {`${isSubcontractor ? 'Add' : 'View'} description`}
                </KreoButton>
                <BidDescriptionDialog
                  isSubcontractor={isSubcontractor}
                  description={description}
                  updateBidDescription={this.props.updateBidDescription}
                />
                {this.getStatusLabel()}
                {this.getButton()}
              </div>
            </div>
            <div className='bid-cost-page-layout__body' ref={this.saveContainerRef}>{this.props.children}</div>
          </div>
        </KreoScrollbars>
      </ProjectLayout>
    );
  }

  private getStatusLabel(): React.ReactNode {
    if (this.props.isSubcontractor) {
      return '';
    }
    let status = '';
    switch (this.props.status) {
      case UserBidPricingStatus.Estimated:
        status = 'Estimated';
        break;
      case UserBidPricingStatus.Winner:
        status = 'Selected';
        break;
      default:
        return null;
    }

    return (
      <div className='bid-cost-page-layout__header__status'>
        <div>Status</div>
        <span>{status}</span>
      </div>
    );
  }

  private getButton(): React.ReactNode {
    const isSubcontractor = this.props.isSubcontractor;
    let mode = null;
    let title = '';
    let onClick = null;
    switch (this.props.status) {
      case UserBidPricingStatus.AcceptedInvite:
        onClick = this.onClickWithCheck;
        title = isSubcontractor ? 'Submit Bid' : 'Invite Accepted';
        break;
      case UserBidPricingStatus.Estimated:
        onClick = this.onClickWithoutCheck;
        title = isSubcontractor ? 'Submit Bid' : 'Confirm bid';
        mode = isSubcontractor ? 'dark' : null;
        break;
      case UserBidPricingStatus.Winner:
        title = isSubcontractor ? 'Submitted' : 'Selected';
        mode = isSubcontractor ? 'dark' : 'success';
        break;
      default:
        return null;
    }
    return (
      <KreoButton size='medium' className='table-btn' mode={mode} onClick={onClick}>
        {title}
      </KreoButton>
    );
  }

  @autobind
  private saveScrollRef(ref: HTMLDivElement): void {
    this.scrollMasterContainer = ref;
  }

  @autobind
  private saveContainerRef(ref: HTMLDivElement): void {
    this.tableContainer = ref;
  }

  @autobind
  private onScroll(): void {
    const shouldHaveFixedHeader = this.shouldHaveFixedHeader();
    if (this.state.fixedHeader !== shouldHaveFixedHeader) {
      this.setState({ fixedHeader: shouldHaveFixedHeader });
    }
  }

  @autobind
  private shouldHaveFixedHeader(): boolean {
    if (this.scrollMasterContainer && this.tableContainer) {
      return this.scrollMasterContainer.scrollTop >= this.tableContainer.offsetTop;
    }
  }

  @autobind
  private onClickWithoutCheck(e: React.MouseEvent<HTMLButtonElement>): void {
    this.checkAction(e, false);
  }

  @autobind
  private onClickWithCheck(e: React.MouseEvent<HTMLButtonElement>): void {
    this.checkAction(e, true);
  }

  private checkAction(e: React.MouseEvent<HTMLButtonElement>, check: boolean = false): void {
    e.stopPropagation();
    if (!check || this.props.isSubcontractor) {
      this.props.onButtonClick();
    }
  }
}
