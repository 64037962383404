import * as React from 'react';

import { MotivationPopupSettings } from '../../components';
import { AdminMasterPage } from '../../master';

export class AdminSettingsPage extends React.Component {
  public render(): JSX.Element {
    return (
      <AdminMasterPage title='Settings'>
        <MotivationPopupSettings />
      </AdminMasterPage>
    );
  }
}
