import classNames from 'classnames';
import * as React from 'react';

import './classification-ontology-appropriate-uniclass.scss';

import { AlertAttention, AlertSuccess } from 'common/UIKit';
import { ClassificationOntologyUniclassInfo } from '../../interfaces/classification/ontology-data';
import { ClassificationOntologyTreeElementUniclass } from '../classification-ontology-tree-element-uniclass';


interface Props {
  isError: boolean;
  uniclass: ClassificationOntologyUniclassInfo;
  isLayer: boolean;
  isMultilayer: boolean;
}

export class ClassificationOntologyAppropriateUniclass extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isError, uniclass, isLayer, isMultilayer } = this.props;
    const className = classNames('classification-ontology-appropriate-uniclass', {
      'classification-ontology-appropriate-uniclass--error': isError,
    });
    return (
      <div className={className}>
        <ClassificationOntologyTreeElementUniclass
          isMultilayer={isMultilayer}
          isLayer={isLayer}
          isError={isError}
          uniclass={uniclass}
        />
        <div className='classification-ontology-appropriate-uniclass__status'>
          {isError ? <AlertAttention/> : <AlertSuccess/>}
        </div>
      </div>
    );
  }
}
