import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px 10px;
`;

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  .image-uploader__files-input-container {
    margin: unset;
  }
  .image-uploader__load-container {
    width: 50px;
    height: 50px;
  }
`;

export const Styled = {
  Container,
  Avatar,
};
