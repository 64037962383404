import { UpdateCellData } from 'common/components/excel-table/interfaces';
import { RowDataStore, SheetDataStore } from '../cell-data-controllers/report-cell-data-store';

export enum TableDataMoveType {
  InsertRows,
  InsertColumns,
  DeleteRows,
  DeleteColumns,
}

export type UpdatedCellsMap = Record<string, UpdateCellData>;
export type MoveCellRefMap = Record<string, string>;
export type RowData = RowDataStore | {};

export interface MoveCellTempData {
  updatedCellsMap: UpdatedCellsMap;
  moveCellRefMap: MoveCellRefMap;
}

export interface MoveDataPayload {
  type: TableDataMoveType;
  startIndex: number;
  offset: number;
  sheetsData: Record<string, SheetDataStore>;
  cellToCellMap: Record<string, string[]>;
  sheetId: string;
}

export enum RangeType {
  Horizontal,
  Vertical,
  Twice,
}


