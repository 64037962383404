import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const EntityType = styled.div`
  display: flex;
  gap: 15px;
  svg {
    fill: ${p => p.theme.color.gray};
    g {
      stroke: ${p => p.theme.color.gray};
    }
  }
`;

export const Styled = {
  Header,
  EntityType,
};
