import styled from 'styled-components';


const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.color.background};
  display: flex;
  align-items: center;
  justify-content: center;

  h6, p {
    text-align: center;
    margin-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
  height: 463px;
  padding: 40px;
  background-color: ${p => p.theme.color.backgroundRush};
  box-shadow: 0px 15px 40px #0000001F;
  border-radius: 40px;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;

  > button {
    > p {
      margin-bottom: 0;
    }
  }
`;

const Img = styled(ContentContainer)`
  margin-bottom: 20px;

  img {
    width: 180px;
    height: 180px;
  }
`;


export const Styled = {
  Container,
  Wrapper,
  ContentContainer,
  Img,
};
