import * as t from 'io-ts';


export const QtoReportSettingC = t.intersection([
  t.type({
    type: t.string,
    name: t.string,
  }),
  t.partial({
    group: t.string,
  }),
]);

export type QtoReportSetting = t.TypeOf<typeof QtoReportSettingC>;
export const QtoReportSettingArrayC = t.array(QtoReportSettingC);

