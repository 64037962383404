
export enum ProgressBarType {
  Percentage = 'Percentage',
  Count = 'Count',
  Indeterminate = 'Indeterminate',
}

export interface ProgressBarState {
  title?: string;
  progressTitle?: string;
  finieshedTitle?: string;
  total?: number;
  customProgressText?: string;
  finished?: number;
  type: ProgressBarType;
}

export interface ProgressState {
  progressBars: ProgressBarState[];
}


export type ProgressReduxState = Record<string, ProgressState>;
