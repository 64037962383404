import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const Styled = {
  Container,
};
