import autobind from 'autobind-decorator';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { ModifyHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { DrawingsEventsController, ModifyController, SetDrawModeCallback } from '../../interfaces';

interface DrawingsModifyKeyListenerHelperConfig {
  api: GlobalKeyboardEventsControllerContextProps;
  changeDrawMode: SetDrawModeCallback;
  modifyController: ModifyController;
}

export class DrawingsModifyKeyListenerHelper implements DrawingsEventsController {
  private _config: DrawingsModifyKeyListenerHelperConfig;

  constructor(config: DrawingsModifyKeyListenerHelperConfig) {
    this._config = config;
  }

  public activate(): void {
    const { api } = this._config;
    api.addKeyDownEventListener(ModifyHotkey.Escape, this.disableDrawMode);
    api.addKeyDownEventListener(ModifyHotkey.RemoveModifyPoints, this.removePoints);
  }

  public deactivate(): void {
    const { api } = this._config;
    api.removeKeyDownEventListener(ModifyHotkey.Escape, this.disableDrawMode);
    api.removeKeyDownEventListener(ModifyHotkey.RemoveModifyPoints, this.removePoints);
  }

  @autobind
  private disableDrawMode(): void {
    this._config.changeDrawMode(DrawingsDrawMode.Disabled);
  }

  @autobind
  private removePoints(): void {
    if (this._config.modifyController.isModifyEnabled) {
      this._config.modifyController.removeModifySelectedPoints();
    }
  }
}
