import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoPopupCollapseIcon: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='icon_collapse' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icon'>
          <rect id='Rectangle-7' x='0' y='0' width='16' height='16' />
          <path
            // eslint-disable-next-line max-len
            d='M4,8.5 C3.72385763,8.5 3.5,8.27614237 3.5,8 C3.5,7.72385763 3.72385763,7.5 4,7.5 L12,7.5 C12.2761424,7.5 12.5,7.72385763 12.5,8 C12.5,8.27614237 12.2761424,8.5 12,8.5 L4,8.5 Z'
            id='Rectangle-5'
            fill={KreoColors.f1}
            fillRule='nonzero'
          />
        </g>
      </g>
    </svg>
  );
};
