import * as Ag from 'ag-grid-community';
import { useCallback } from 'react';

import { PiaBreakdownNode } from '../interfaces';
import { UpdateFunction } from './use-options-state';

type ExtendContextMenuItem = (
  items: Array<Ag.MenuItemDef | string>,
  params: Ag.GetContextMenuItemsParams,
) => Array<(string | Ag.MenuItemDef)>;

const getDeleteItem = (
  handleDelete: (ids: Set<string>) => void,
  params: Ag.GetContextMenuItemsParams,
): Ag.MenuItemDef => {
  if (!params.node?.data?.id) {
    return;
  }

  const selectedNodeIds = params.api.getSelectedNodes().map(n => n.id);
  if (!selectedNodeIds.length) {
    selectedNodeIds.push(params.node.data.id);
  }

  const ids = new Set<string>();
  params.api.forEachNode((n) => {
    if (selectedNodeIds.some(id => n.data.h_path.includes(id))) {
      ids.add(n.data.id);
    }
  });
  return {
    name: 'Delete',
    action: () => handleDelete(ids),
  };
};

const sliceNodes = (root: PiaBreakdownNode[], ids: Set<string>): void => {
  for (let i = 0; i < root.length; i++) {
    if (ids.has(root[i].id)) {
      root.splice(i, 1);
      i--;
    } else {
      sliceNodes(root[i].children, ids);
    }
  }
};

export const useExtendContextMenuItemCallback = (updateOptions: UpdateFunction): ExtendContextMenuItem => {
  const handleDelete = useCallback((ids: Set<string>) => {
    updateOptions((ops) => {
      sliceNodes(ops, ids);
    });
  }, [updateOptions]);

  return useCallback((items: Array<Ag.MenuItemDef | string>, params: Ag.GetContextMenuItemsParams) => {
    const filterSet = new Set(['expandAll', 'contractAll']);
    const filtredResult = items.filter((i) => typeof i === 'string' && filterSet.has(i));
    filtredResult.push(getDeleteItem(handleDelete, params));

    return filtredResult;
  }, [updateOptions]);
};
