import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { change, Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { CustomDialog } from 'common/components/custom-dialog';
import { FileUploadWrapObsolete } from 'common/components/file-upload-wrap';
import { UploadingFile } from 'common/interfaces/common-state';
import { State } from 'common/interfaces/state';
import { KreoDialogActions, MaterialInputField } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { Common } from '../../../../actions';
import Form from '../../../../components/form';
import { TwoDActions } from '../../actions/creators';
import { Styled } from './styled';

export const IMPORT_EXCEL_DIALOG = 'IMPORT_EXCEL_DIALOG';
const IMPORT_EXCEL_FORM = 'IMPORT_EXCEL_FORM';
const FILE_EXTENSIONS = ['xlsx'];

const selector = formValueSelector(IMPORT_EXCEL_FORM);

interface StateProps {
  fileName: string;
  files: UploadingFile[];
  isButtonDisabled: boolean;
  projectId: number;
}

interface DispatchProps {
  setName: (value: string) => void;
  clearFiles: () => void;
  removeDownloadFileName: () => void;
  closeImportDialog: () => void;
  setUploadFiles: (projectId: number, files: string[]) => void;
}

interface Props extends StateProps, DispatchProps, InjectedFormProps<{}, {}>, AnalyticsProps {}

class TwoDExcelImporterComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return (
      <DialogWrapper
        name={IMPORT_EXCEL_DIALOG}
      >
        <Styled.CustomDialogWrapper>
          <CustomDialog
            mainText='Import sheets from Excel'
            width={420}
            autoHeight={true}
            onReject={this.onCloseDialog}
            submitText='Import'
            submitButtonMood={this.props.isButtonDisabled ? 'disabled' : 'positive'}
            onSubmit={this.onImport}
          >
            <Styled.Container isFilesLoaded={this.props.files.length > 0}>
              <Form handleSubmit={this.props.handleSubmit} >
                <Field<any>
                  name='name'
                  component={MaterialInputField}
                  label='File name'
                />
                <FileUploadWrapObsolete
                  onUploadStarted={this.onUploadStarted}
                  onDeleteFile={this.onDeleteFile}
                  formName={IMPORT_EXCEL_FORM}
                  extensions={FILE_EXTENSIONS}
                  extensionsToShow={FILE_EXTENSIONS}
                />
              </Form>
            </Styled.Container>
          </CustomDialog>
        </Styled.CustomDialogWrapper>
      </DialogWrapper>
    );
  }

  @autobind
  private onCloseDialog(): void {
    this.props.clearFiles();
    this.props.closeImportDialog();
  }

  private getExtensionsRegExp(extensions: string[]): RegExp {
    const regexExtensions = extensions
      .map(x => `\\.${x}`)
      .join('|');

    return new RegExp(`(${regexExtensions})$`, 'i');
  }

  @autobind
  private onDeleteFile(file: UploadingFile): void {
    const { fileName, setName, files } = this.props;
    const fileExtensionRegExp = this.getExtensionsRegExp(FILE_EXTENSIONS);
    if (file && file.file.name.replace(fileExtensionRegExp, '') === fileName) {
      if (files.length > 1) {
        setName(files[1].file.name.replace(fileExtensionRegExp, ''));
      } else {
        setName('');
      }
    }
  }

  @autobind
  private onImport(): void {
    const fileNames = [];
    this.props.files.map(item => fileNames.push(item.uploadedFileName));
    this.props.setUploadFiles(this.props.projectId, fileNames);
    this.props.clearFiles();
    this.props.closeImportDialog();
    this.props.sendEvent(MetricNames.reports.importExcl);
  }

  @autobind
  private onUploadStarted(files: File[]): void {
    const { fileName, setName } = this.props;
    if (!fileName && files && files.length) {
      const fileExtensionRegExp = this.getExtensionsRegExp(FILE_EXTENSIONS);
      setName(files[0].name.replace(fileExtensionRegExp, ''));
    }
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    fileName: selector(state, 'name'),
    isButtonDisabled: !state.common.files.length || state.common.files.some(x => x.progress < 1),
    files: state.common.files,
    projectId: state.projects.currentProject.id,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setName: (value) => dispatch(change(IMPORT_EXCEL_FORM, 'name', value)),
    clearFiles: () => dispatch(Common.commonClearFiles()),
    removeDownloadFileName: () => dispatch(Common.removeDownloadFileName()),
    closeImportDialog: () => dispatch(KreoDialogActions.closeDialog(IMPORT_EXCEL_DIALOG)),
    setUploadFiles: (projectId, files) => dispatch(TwoDActions.setUploadFiles(projectId, files)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
const TwoDExcelImporterConnector = connector(
  withAnalyticsContext(TwoDExcelImporterComponent));

export const TwoDExcelImporter = reduxForm<{}, {}>({
  form: IMPORT_EXCEL_FORM,
  destroyOnUnmount: true,
})(TwoDExcelImporterConnector);
