import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { ActivityAssignmentActivityLayoutEdit } from '../../components/activity-assignment-activity-layout-edit';
import { ActivityAssignmentEngineLayout } from '../../components/activity-assignment-engine-layout';


export class ActivityAssignmentEditPage extends React.Component<RouteComponentProps<PlanProjectRouteParams>> {
  public render(): React.ReactNode {
    return (
      <ActivityAssignmentEngineLayout projectId={this.props.match.params.projectId}>
        <ActivityAssignmentActivityLayoutEdit/>
      </ActivityAssignmentEngineLayout>
    );
  }
}
