import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-assignment-header.scss';

import { State } from 'common/interfaces/state';
import { IconButton, KreoIconPlus } from 'common/UIKit';
import { ClassificationActions } from '../../actions/creators/classification';
import { ClassificationAssignmentStage } from '../../enums/classification-assignment-stage';
import { ClassificationUtils } from '../../utils/classification-utils';


interface DispatchProps {
  changeAssignmentLevel: (level: ClassificationAssignmentStage) => void;
}

interface StateProps {
  level: ClassificationAssignmentStage;
  isMultilayer: boolean;
  caption: string;
}

interface Props extends StateProps, DispatchProps {

}

class ClassificationAssignmentHeaderComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    if (this.props.level === ClassificationAssignmentStage.PredictedInfo) {
      if (this.props.isMultilayer) {
        return (
          <div className='classification-assignment-header'>
            <span>
              UniClass
            </span>
          </div>
        );
      } else {
        return (
          <div className='classification-assignment-header'>
            <span>
              Select UniClass
            </span>
            <IconButton size='medium' onClick={this.goToCustomUniclassSelection} controlName='add-uniclass-button'>
              <KreoIconPlus />
            </IconButton>
          </div>
        );
      }
    } else if (this.props.level === ClassificationAssignmentStage.Assignment) {
      return (
        <div className='classification-assignment-header'>
          <span>
            {this.props.caption}
          </span>
        </div>
      );
    }

    return null;
  }


  @autobind
  private goToCustomUniclassSelection(): void {
    this.props.changeAssignmentLevel(ClassificationAssignmentStage.Assignment);
  }
}


function mapStateToProps({ classification }: State): StateProps {
  let caption = 'Select Uniclass';
  const isMultilayer = ClassificationUtils.classificationTargetIsMultilayer(classification.classificationTarget);
  if (isMultilayer) {
    if (classification.changeAssemblyRoot) {
      caption = 'Select System';
    } else {
      caption = `Layer #${classification.editableLayer}`;
    }
  }
  return {
    level: classification.assignmentStage,
    isMultilayer,
    caption,
  };
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    changeAssignmentLevel: (level) => {
      dispatch(ClassificationActions.changeAssignmentLevel(level));
    },
  };
}

export const ClassificationAssignmentHeader =
  connect(mapStateToProps, mapDispatchToProps)(ClassificationAssignmentHeaderComponent);
