import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CheckboxWithText } from 'common/components/checkbox-with-text';
import { DrawingsTextSearchActions } from 'common/components/drawings/actions/creators';
import { TextSearchPagesType } from 'common/components/drawings/interfaces';
import { QuickSearchInput } from 'common/components/quick-search';
import { RadioMenu } from 'common/components/selection-menus';
import { State } from 'common/interfaces/state';
import { Styled } from './styled';

const PAGES_SETTINGS = [
  {
    name: 'All pages',
    value: TextSearchPagesType.All,
  },
  {
    name: 'Current page',
    value: TextSearchPagesType.Current,
  },
];


const TextSearchQueryComponent: React.FC = () => {
  const autofocusCallback = useCallback((ref: React.RefObject<HTMLInputElement>) => {
    ref.current.focus();
  }, []);

  const dispatch = useDispatch();

  const setSearchValue = useCallback((value: string) => {
    dispatch(DrawingsTextSearchActions.setQuery(value));
  }, [dispatch]);

  const toggleCaseSensitive = useCallback(() => {
    dispatch(DrawingsTextSearchActions.toggleCaseSensitive());
  }, [dispatch]);

  const toggleRegExp = useCallback(() => {
    dispatch(DrawingsTextSearchActions.toggleRegExp());
  }, [dispatch]);

  const toggleWholeWord = useCallback(() => {
    dispatch(DrawingsTextSearchActions.toggleWholeWord());
  }, [dispatch]);

  const regExp = useSelector<State, boolean>(s => s.drawings.textSearch.settings.regExp);
  const searchQuery = useSelector<State, string>(s => s.drawings.textSearch.settings.query);
  const caseSensitiveEnabled = useSelector<State, boolean>(s => s.drawings.textSearch.settings.caseSensitive);
  const wholeWordEnabled = useSelector<State, boolean>(s => s.drawings.textSearch.settings.wholeWord);

  const pagesSetting = useSelector<State, TextSearchPagesType>(s => s.drawings.textSearch.settings.pages);
  const updatePagesSetting = useCallback((value: TextSearchPagesType) => {
    dispatch(DrawingsTextSearchActions.setPagesSetting(value));
  }, [dispatch]);


  return (
    <Styled.Container>
      <QuickSearchInput
        placeholder='Type text'
        isQuickSearchVisible={true}
        setQuickSearchValue={setSearchValue}
        passRef={autofocusCallback}
        value={searchQuery}
        tooltipText='Clear'
      />
      <Styled.Settings hasQuery={!!searchQuery}>
        <CheckboxWithText
          onToggle={toggleCaseSensitive}
          checked={caseSensitiveEnabled}
          text={'Case sensitive'}
        />
        <CheckboxWithText
          onToggle={toggleWholeWord}
          checked={wholeWordEnabled}
          text={'Whole word'}
        />
        <CheckboxWithText
          onToggle={toggleRegExp}
          checked={regExp}
          text={'Regular expression'}
        />
      </Styled.Settings>
      <Styled.Settings hasQuery={!!searchQuery}>
        <RadioMenu
          value={pagesSetting}
          onSelectionChanged={updatePagesSetting}
          options={PAGES_SETTINGS}
        />
      </Styled.Settings>
    </Styled.Container>
  );
};

export const TextSearchQuery = React.memo(TextSearchQueryComponent);
