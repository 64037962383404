import { ValuePayload } from 'common/request-payloads/value-payload';
import { ProjectResourcesApi } from '../../../api/server';


function removeCalculation(calculationId: number): Promise<void> {
  return ProjectResourcesApi.delete(
    `/calculations/${calculationId}`,
    'Error in deleting calculation',
  );
}

function changeCalculationName(calculationId: number, name: string): Promise<void> {
  return ProjectResourcesApi.put<void, ValuePayload<string>>(
    `/calculations/${calculationId}/name`,
    { value: name },
    'Error in updating calculation name',
  );
}

function getIndirectData(calculationId: number): Promise<any> {
  return ProjectResourcesApi.get(
    `/calculations/${calculationId}/indirect-costs`,
    'Error in getting calculation indirect-costs',
  );
}

export const CalculationApi = {
  removeCalculation,
  changeCalculationName,
  getIndirectData,
};
