const RESOURCES_ALLOCATION_DATA_REQUEST = 'RESOURCES_ALLOCATION_DATA_REQUEST';
const RESOURCES_HUMANS_REQUEST = 'RESOURCES_HUMANS_REQUEST';
const RESOURCES_PLANT_REQUEST = 'RESOURCES_PLANT_REQUEST';
const RESOURCES_ALLOCATION_DATA_SUCCESSED = 'RESOURCES_ALLOCATION_DATA_SUCCESSED';
const RESOURCES_HUMANS_SUCCESSED = 'RESOURCES_HUMANS_SUCCESSED';
const RESOURCES_PLANT_SUCCESSED = 'RESOURCES_PLANT_SUCCESSED';
const RESOURCES_ALLOCATION_DATA_FAILED = 'RESOURCES_ALLOCATION_DATA_FAILED';
const RESOURCES_HUMANS_FAILED = 'RESOURCES_HUMANS_FAILED';
const RESOURCES_PLANT_FAILED = 'RESOURCES_PLANT_FAILED';
const RESOURCES_HUMANS_SELECT = 'RESOURCES_HUMANS_SELECT';
const RESOURCES_PLANT_SELECT = 'RESOURCES_PLANT_SELECT';


export {
  RESOURCES_ALLOCATION_DATA_REQUEST,
  RESOURCES_ALLOCATION_DATA_SUCCESSED,
  RESOURCES_ALLOCATION_DATA_FAILED,
  RESOURCES_HUMANS_REQUEST,
  RESOURCES_HUMANS_SUCCESSED,
  RESOURCES_HUMANS_FAILED,
  RESOURCES_HUMANS_SELECT,
  RESOURCES_PLANT_REQUEST,
  RESOURCES_PLANT_SUCCESSED,
  RESOURCES_PLANT_FAILED,
  RESOURCES_PLANT_SELECT
}