import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { GraphEdge } from '../../../components/graph-viewer/interfaces';
import { MacroSequenceActionTypes } from '../actions/types/macro-sequence';
import { UngroupedCategoryId } from '../constants';
import { ActivityGroupData, ActivityGroupsReduxState, GraphChangeType  } from '../interfaces';


const findLimitation =
  (limitations: ActivityGroupData.LimitationMap, limitation: GraphEdge): ActivityGroupData.LimitationData => {
    return limitations[limitation.source] && limitations[limitation.source][limitation.target];
  };


export const MacroSequenceReducerMethods: ReducerMethods<ActivityGroupsReduxState> = {
  [MacroSequenceActionTypes.SET_EDGE_CREATOR_ROOT_VERTEX_AND_DIRECTION]:
    (state, payload: ActivityGroupData.EdgeCreator) => {
      return new MonoliteHelper(state)
        .set((_) => _.macroSequence.edgeCreator, payload)
        .get();
    },
  [MacroSequenceActionTypes.RESET_EDGE_CREATOR]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.macroSequence.edgeCreator, null)
      .get();
  },
  [MacroSequenceActionTypes.ADD_EDGE]: (state, payload: GraphEdge) => {
    const limitation = findLimitation(state.macroSequence.graph.limitations, payload);

    if (!limitation) {
      const changeModel: ActivityGroupData.GraphChange = {
        graphChangeType: GraphChangeType.Added,
        limitation: {
          ...payload,
          isUsed: true,
          timeLag: 0,
        },
      };

      return new MonoliteHelper(state)
        .set(
          (_) => _.macroSequence.graph.limitations[payload.source],
          state.macroSequence.graph.limitations[payload.source]
            ? state.macroSequence.graph.limitations[payload.source]
            : {},
        )
        .set(
          (_) => _.macroSequence.graph.limitations[payload.source][payload.target],
          { type: payload.type, timeLag: 0, isUsed: true },
        )
        .setAppend((_) => _.macroSequence.changes, changeModel)
        .get();
    }

    if (!limitation.isUsed) {
      const changeModel: ActivityGroupData.GraphChange = {
        graphChangeType: GraphChangeType.Used,
        limitation: {
          ...payload,
          ...limitation,
        },
      };

      return new MonoliteHelper(state)
        .set((_) => _.macroSequence.graph.limitations[payload.source][payload.target].isUsed, true)
        .setAppend((_) => _.macroSequence.changes, changeModel)
        .get();
    }

    return state;
  },
  [MacroSequenceActionTypes.REMOVE_EDGE]: (state, payload: GraphEdge) => {
    const limitation = findLimitation(state.macroSequence.graph.limitations, payload);
    if (limitation) {
      const changeModel: ActivityGroupData.GraphChange = {
        graphChangeType: GraphChangeType.NotUsed,
        limitation: {
          ...payload,
          ...limitation,
        },
      };

      return new MonoliteHelper(state)
        .set((_) => _.macroSequence.graph.limitations[payload.source][payload.target].isUsed, false)
        .setAppend((_) => _.macroSequence.changes, changeModel)
        .get();
    }

    return state;
  },
  [MacroSequenceActionTypes.SET_LIMITATION_TIME_LAG]: (state, payload: { edge: GraphEdge, timeLag: number }) => {
    const { edge, timeLag } = payload;
    const limitation = findLimitation(state.macroSequence.graph.limitations, payload.edge);
    if (limitation) {
      const updatedEdge = {
        ...edge,
        ...limitation,
        timeLag,
      };
      const changeModel: ActivityGroupData.GraphChange = {
        graphChangeType: GraphChangeType.TimeLagChanged,
        limitation: updatedEdge,
      };
      const limitations = { ...state.macroSequence.graph.limitations };
      limitations[edge.source][edge.target] = updatedEdge;

      return new MonoliteHelper(state)
        .set((_) => _.macroSequence.graph.limitations, limitations)
        .setAppend((_) => _.macroSequence.changes, changeModel)
        .get();
    }

    return state;
  },
  [MacroSequenceActionTypes.SET_ACTIVE_ACTIVITY_GROUP_OR_UNGROUPED_ACTIVITY_BY_GROUP_ID]: (state, id: number) => {
    const group = state.activityGroups[id];
    if (group) {
      return new MonoliteHelper(state)
        .set((_) => _.activeActivityId, null)
        .set((_) => _.activeActivityGroupId, group.id)
        .set((_) => _.activeCategoryId, group.categoryId)
        .set((_) => _.activeWorkPackageId, group.workPackageId)
        .get();
    }
    const activity = state.ungroupedActivities[state.ungroupedActivityGroupIdToId[id]];
    if (activity) {
      return new MonoliteHelper(state)
        .set((_) => _.activeActivityId, activity.id)
        .set((_) => _.activeActivityGroupId, activity.activityGroupId)
        .set((_) => _.activeCategoryId, UngroupedCategoryId)
        .set((_) => _.activeWorkPackageId, activity.workPackageId)
        .get();
    }

    return state;
  },
  [MacroSequenceActionTypes.SET_HIGHLIGHTED_GRAPH_ITEM_ID]: (state, payload: number | null) => {
    return new MonoliteHelper(state)
      .set((_) => _.macroSequence.highlightedGraphItemId, payload)
      .get();
  },
  [MacroSequenceActionTypes.SET_FILTERED_WORK_PACKAGE_IDS]: (state, payload: Record<number, undefined> | null) => {
    return new MonoliteHelper(state)
      .set((_) => _.macroSequence.filteredWorkPackageIds, payload)
      .get();
  },
  [MacroSequenceActionTypes.SET_FILTERED_ACTIVITY_GROUP_IDS]: (state, payload: Record<number, undefined> | null) => {
    return new MonoliteHelper(state)
      .set((_) => _.macroSequence.filteredActivityGroupIds, payload)
      .get();
  },
  [MacroSequenceActionTypes.setSelectedDestinationId]: (state, payload: number[] | null) => {
    return new MonoliteHelper(state)
      .set((_) => _.macroSequence.edgeCreator.destinationIds, payload)
      .get();
  },
};
