import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-selected-group-info.scss';

import { StringDictionary } from 'common/interfaces/dictionary';
import { State } from 'common/interfaces/state';
import { KreoButton } from 'common/UIKit';
import { ClassificationActions } from '../../actions/creators/classification';

interface DispatchProps {
  edit: React.MouseEventHandler<HTMLButtonElement>;
  select: React.MouseEventHandler<HTMLDivElement>;
}

interface StateProps {
  layers: StringDictionary<number[][]>;
  layersCount: number;
  elementTypesCount: number;
  selected?: boolean;
}

interface OwnProps {
  index: number;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

const REMOVE_TAG_B_REGEX = /<(b|\/b)>/gi;

const ClassificationSelectedGroupInfoComponent: React.FC<Props> = props => {
  let title = '';
  if (props.index === -1) {
    title = 'Select All';
  } else if (!props.layersCount) {
    title = 'Simple objects';
  } else {
    for (const [key, value] of Object.entries(props.layers)) {
      title = `${title}${title ? ',' : ''} <b>${value.length}x</b>${key}`;
    }
  }
  const className = classNames('classification-selected-group-info', {
    'classification-selected-group-info--selected': props.selected && props.index !== -1,
  });
  return (
    <div className={className} onClick={props.select}>
      <div
        className='classification-selected-group-info__title'
        title={title.replace(REMOVE_TAG_B_REGEX, ' ')}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {props.index !== -1 && [
        <div key={'0'} className='classification-selected-group-info__count'>
          {props.elementTypesCount}
        </div>,
        <KreoButton
          key={'1'}
          size='medium'
          mode='action'
          className='classification-selected-group-info__edit'
          onClick={props.edit}
        >
          Edit
        </KreoButton>,
      ]}
    </div>
  );
};

const cleanStateProps = {
  layers: null,
  layersCount: 0,
  elementTypesCount: 0,
};

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  if (ownProps.index === -1) {
    return cleanStateProps;
  } else {
    const group = state.classification.currentSelectedElementTypesGroups[ownProps.index];
    const pattern = group.pattern;
    const elementTypesCount = group.classificationTarget.elementTypesCount;
    return {
      layers: pattern.layersInfo,
      layersCount: pattern.layersCount,
      elementTypesCount,
      selected: ownProps.index === state.classification.selectedClassificationGroup,
    };
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
  return {
    edit: event => {
      event.stopPropagation();
      dispatch(ClassificationActions.editGroup(ownProps.index));
    },
    select: event => {
      event.stopPropagation();
      dispatch(ClassificationActions.selectGroup(ownProps.index));
    },
  };
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export const ClassificationSelectedGroupInfo = connector(ClassificationSelectedGroupInfoComponent);
