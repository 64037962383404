import React from 'react';
import { useSelector } from 'react-redux';

import { getSortingMeasureTypes } from '2d/units/get-sorting-measure-types';
import { ButtonInput } from 'common/components/inputs';
import { Property } from 'common/components/property';
import { State } from 'common/interfaces/state';
import { UnitUtil } from 'common/utils/unit-util';
import { MeasureNames, MeasureUnit } from './measure-unit';
import { Styled } from './styled';

interface Props {
  measurements: Record<string, number>;
}

export const Measurements: React.FC<Props> = ({ measurements }) => {
  const isImperial = useSelector<State, boolean>(s => s.account.settings.isImperial);
  const sortedMeasurements = getSortingMeasureTypes(Object.entries(measurements));

  return (
    <>
      {
        sortedMeasurements.map(([key, value]) => {
          if (!MeasureUnit[key]) {
            return null;
          }
          const siUnit = MeasureUnit[key];
          const stringValue = UnitUtil.measureToString2d(value, siUnit, isImperial);

          return (
            <Property title={MeasureNames[key] || key} key={key} textTransform='capitalize'>
              <Styled.ValueContainer>
                <ButtonInput value={stringValue} />
              </Styled.ValueContainer>
            </Property>
          );
        })
      }
    </>
  );
};

