import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { CompanySubscriptionModel } from '../../../../account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../account/selectors';
import { SubscriptionActions } from '../../../actions/creators';

type UseAfterApplyCallback = (
  subscription: CompanySubscriptionModel,
) => [(id: string) => void];

export const useAfterApplyCallback: UseAfterApplyCallback = (subscription) => {
  const dispatch = useDispatch();
  const afterEdit = useCallback((subscriptionId) => {
    dispatch(SubscriptionActions.renewSubscription(subscriptionId));
  }, []);
  const afterPause = useCallback((subscriptionId) => {
    dispatch(SubscriptionActions.resumeSubscription(subscriptionId));
  }, []);
  const handleAfterApply = useCallback((id: string) => {
    const isPausedSubscription = AccountSelectors.isPausedSubscription(subscription);
    if (isPausedSubscription) {
      afterPause(id);
    } else {
      afterEdit(id);
    }
  }, []);
  return [handleAfterApply];
};
