import * as React from 'react';
import { Styled } from './styled';


export const DrawingsPdfBrowserFileTreeFooter: React.FC = ({ children }) => {
  return (
    <Styled.Container >
      {children}
    </Styled.Container>
  );
};
