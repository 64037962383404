import { ActionWith } from 'common/interfaces/action-with';
import { ResourceType } from '../../enums';
import {
  ResourceVariantModel,
} from '../../interfaces/resources-data';
import { ResourceVariantForm } from '../../interfaces/resources-rest-data';
import {
  CreateResourceVariantPayload,
  DeleteResourceVariantPayload,
  EditResourceVariantPayload,
  LoadResourceVariantPayload,
} from '../payloads/database-resource-variant';
import { DatabaseResourceVariantActionTypes } from '../types/database-resource-variant';

function createResourceVariant(
  databaseId: number,
  resourceId: number,
  type: ResourceType,
  model: ResourceVariantForm,
  formId: string,
): ActionWith<CreateResourceVariantPayload> {
  return {
    type: DatabaseResourceVariantActionTypes.CREATE_RESOURCE_VARIANT_REQUEST,
    payload: { databaseId, resourceId, model, type, formId },
  };
}

function createResourceVariantResponse(
  model: ResourceVariantModel,
): ActionWith<ResourceVariantModel> {
  return {
    type: DatabaseResourceVariantActionTypes.CREATE_RESOURCE_VARIANT_SUCCEEDED,
    payload: model,
  };
}

function loadResourceVariant(
  databaseId: number,
  resourceId: number,
  resourceVariantId: number,
  type: ResourceType,
): ActionWith<LoadResourceVariantPayload> {
  return {
    type: DatabaseResourceVariantActionTypes.GET_RESOURCE_VARIANT_REQUEST,
    payload: {
      databaseId,
      resourceId,
      resourceVariantId,
      type,
    },
  };
}

function loadResourceVariantResponse(
  model: ResourceVariantModel,
): ActionWith<ResourceVariantModel> {
  return {
    type: DatabaseResourceVariantActionTypes.GET_RESOURCE_VARIANT_SUCCEEDED,
    payload: model,
  };
}

function updateResourceVariant(
  databaseId: number,
  resourceId: number,
  resourceVariantId: number,
  type: ResourceType,
  model: ResourceVariantForm,
  formId: string,
): ActionWith<EditResourceVariantPayload> {
  return {
    type: DatabaseResourceVariantActionTypes.UPDATE_RESOURCE_VARIANT_REQUEST,
    payload: { databaseId, resourceId, resourceVariantId, model, type, formId },
  };
}


function updateResourceVariantResponse(
  model: ResourceVariantModel,
): ActionWith<ResourceVariantModel> {
  return {
    type: DatabaseResourceVariantActionTypes.UPDATE_RESOURCE_VARIANT_SUCCEEDED,
    payload: model,
  };
}

function deleteResourceVariant(
  databaseId: number,
  resourceId: number,
  resourceVariantId: number,
  type: ResourceType,
): ActionWith<DeleteResourceVariantPayload> {
  return {
    type: DatabaseResourceVariantActionTypes.DELETE_RESOURCE_VARIANT_REQUEST,
    payload: { databaseId, resourceId, resourceVariantId, type },
  };
}

function deleteResourceVariantResponse(
  id: number,
): ActionWith<number> {
  return {
    type: DatabaseResourceVariantActionTypes.DELETE_RESOURCE_VARIANT_SUCCEEDED,
    payload: id,
  };
}


export const DatabaseResourceVariantActions = {
  createResourceVariant,
  createResourceVariantResponse,
  loadResourceVariant,
  loadResourceVariantResponse,
  updateResourceVariant,
  updateResourceVariantResponse,
  deleteResourceVariant,
  deleteResourceVariantResponse,
};
