import React, { useCallback, useState } from 'react';
import { ApplyButton } from './apply-button';
import { Styled } from './styled';
import { Tag } from './tag';


export interface TagsInputProps {
  name?: string;
  placeHolder?: string;
  tags: string[];
  invalidTags?: string[];
  separators?: string[];
  disableBackspaceRemove?: boolean;
  disabled?: boolean;
  isEditOnRemove?: boolean;
  onChange: (tags: string[]) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const defaultSeparators = ['Enter'];

export const TagsInput: React.FC<TagsInputProps> = ({
  name,
  placeHolder,
  tags,
  onChange,
  separators,
  disableBackspaceRemove,
  disabled,
  isEditOnRemove,
  onKeyUp,
  invalidTags,
}: TagsInputProps) => {
  const [value, setValue] = useState<string>('');

  const handleOnKeyUp = useCallback(e => {
    e.stopPropagation();

    const text = e.target.value;

    if (
      !text &&
      !disableBackspaceRemove &&
      tags.length &&
      e.key === 'Backspace'
    ) {
      e.target.value = isEditOnRemove ? `${tags[tags.length -1]} ` : '';
      onChange(tags.slice(0, -1));
    }

    if (text && (separators || defaultSeparators).includes(e.key)) {
      e.preventDefault();

      if (tags.includes(text)) {
        return;
      }
      onChange(tags.concat(text));
      e.target.value = '';
      setValue('');
    }
  }, [tags]);

  const handleOnClick = useCallback(() => {
    if (value) {
      if (tags.includes(value)) {
        return;
      }

      onChange(tags.concat(value));
      setValue('');
    }
  }, [tags, value]);

  const handleChange = useCallback((e) => {
    if (e.target.value) {
      setValue(e.target.value);
    }
  }, []);

  const onTagRemove = useCallback(text => {
    const newTags = tags.filter(tag => tag !== text);
    onChange(newTags);
  }, [tags]);

  return (
    <Styled.Container>
      {tags.map(tag => (
        <Tag
          key={tag}
          text={tag}
          remove={onTagRemove}
          disabled={disabled}
          hasProblem={invalidTags?.includes(tag)}
        />
      ))}

      <Styled.Input
        type="text"
        name={name}
        value={value}
        placeholder={placeHolder}
        disabled={disabled}
        onChange={handleChange}
        onKeyDown={handleOnKeyUp}
        onKeyUp={onKeyUp}
      />
      <ApplyButton onClick={handleOnClick} />
    </Styled.Container>
  );
};
