import styled from 'styled-components';

const Query = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const Styled = {
  Query,
};
