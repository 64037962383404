import { ModalWrapper } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { pushSearch } from '../../../actions/common';
import { DemoProjectCard } from './demo-project-card';
import { HeaderDialog } from './header-dialog';
import { useClickDemoProjectCallback, useDemoProject, useFilteredDemo, useTags } from './hooks';
import { Styled } from './styled';
import { TagList } from './tag-list';


export const DemoProjectDialog: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const status = useSelector<State, RequestStatus>(s => s.projects.status);
  const demoProjects = useDemoProject();
  const createProjectFromDemoProject = useClickDemoProjectCallback(demoProjects);
  const show = useSelector<State, boolean>(s => Boolean(s.router.location.query.demoProject));
  const tags = useTags(demoProjects);
  const [selectedTags, setSelectedTags] = useState<string[]>(tags);

  useEffect(() => {
    setSelectedTags(tags);
  }, [demoProjects]);

  const filteredDemos = useFilteredDemo(demoProjects, selectedTags);

  const handleClose = useCallback(() => {
    dispatch(pushSearch({ demoProject: undefined }));
  }, [dispatch]);

  const isShow = useAbility(Operation.Manage, Subject.DemoProject);
  if (!isShow) {
    return null;
  }


  return (
    <RenderIf condition={show}>
      <ModalWrapper onExit={handleClose}>
        <Styled.Container>
          <HeaderDialog />
          <Styled.ContentWrapper>
            <Styled.TagsContainer>
              <TagList
                selectedTags={selectedTags}
                onSelectChange={setSelectedTags}
                tags={tags}
              />
            </Styled.TagsContainer>
            <Styled.DemosContainer>
              <Styled.DemoProjectsList>
                {filteredDemos.map(project => (
                  <DemoProjectCard
                    key={project.id}
                    project={project}
                    onClick={createProjectFromDemoProject}
                  />
                ))}
              </Styled.DemoProjectsList>
            </Styled.DemosContainer>
          </Styled.ContentWrapper>
          <Spinner show={status === RequestStatus.Loading} fullScreen={true} withBackground={true} />
        </Styled.Container>
      </ModalWrapper >
    </RenderIf>
  );
});
