import { useEffect, useMemo } from 'react';
import { usePersistedStorageValue } from 'persisted-storage/hooks';
import { ContextObserver, DrawingContextObserver } from '../../drawings-contexts';

export function useShowthicknessObserver(): ContextObserver<boolean> {
  const showThickness = usePersistedStorageValue('drawingShowThickness');
  const showThicknessObserver = useMemo(() => new DrawingContextObserver(showThickness), []);
  useEffect(() => {
    showThicknessObserver.updateContext(showThickness);
  }, [showThickness]);
  return showThicknessObserver;
}
