import { Constants } from '@kreo/kreo-ui-components';
import styled  from 'styled-components';

const Container = styled.div`
  width: 210px;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.color.backgroundRush};
  box-shadow: ${p => p.theme.shadow.xl};
  animation: ${Constants.Animations.opacityAppear} ${p => p.theme.duration.s} ease-in-out;
  border-radius: 20px;
  padding: 25px 0;
  &, * {
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  display: flex;
  padding: 0 20px 16px 20px;
  svg {
    height: 20px;
    fill: ${p => p.theme.color.gray};
    margin-right: 10px;
  }
`;

const Switcher = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 20px;
`;

const Buttons = styled.div`
  border-top: 10px solid ${p => p.theme.color.pale};
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
`;

const SelectedPolygons = styled(Switcher)`
  border: none;
  button {
    border: 1px solid ${p => p.theme.color.background};
    border-radius: 5px;
    svg {
      fill: ${p => p.theme.color[p.isSelected ? 'green' : 'red']};
    }
    :hover {
      svg {
        fill: ${p => p.theme.color[p.isSelected ? 'green' : 'red']};
      }
    }
  }
`;


export const Styled = {
  Container,
  Header,
  Switcher,
  Buttons,
  SelectedPolygons,
};
