export function XLOOKUP(
  lookupValue: any,
  lookupList: any[],
  returnValue: any[],
  ifNotFound?: any,
  matchMode: number = 1,
  searchMode: number = 1,
): any {
  let result: any = ifNotFound;
  let index: number = -1;
  const lookupArray = [];
  lookupList.forEach(e => {
    lookupArray.push(e[0]);
  });
  const returnArray = [];
  returnValue.forEach(e => {
    returnArray.push(e[0]);
  });

  // Handle match mode
  if (matchMode === -1) {
    lookupArray.reverse();
    returnArray.reverse();
  }

  // Handle search mode
  if (searchMode === 2) {
    lookupArray.sort();
    const sortedIndex: number = lookupArray.indexOf(lookupValue);
    if (sortedIndex !== -1) {
      index = sortedIndex;
    }
  } else {
    index = lookupArray.indexOf(lookupValue);
  }

  // Handle return value
  if (index !== -1) {
    result = returnArray[index];
  }

  // Handle match mode reverse
  if (matchMode === -1) {
    lookupArray.reverse();
    returnArray.reverse();
  }

  return result;
}
