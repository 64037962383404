import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { State } from 'common/interfaces/state';
import { BaseMenuSettings, Menu } from 'unit-2d-info-panel/constants/menus';


export interface MenuSettings {
  badge?: JSX.Element;
  title: string;
  infoIcon?: React.ComponentType;
  menuComponent?: React.ComponentType;
  onClose?: () => void;
}

function useDisableDrawMode(): () => void {
  const { setDrawMode } = useDrawModeApi();
  return useCallback(() => {
    setDrawMode(DrawingsDrawMode.Disabled);
  }, [setDrawMode]);
}

export function useCurrentMenu(): MenuSettings {
  const menu = useSelector<State, Menu>(x => x.twoDInfoPanel.menu);
  const disableDrawMode = useDisableDrawMode();

  return useMemo(() => {
    const settings = BaseMenuSettings[menu];
    if (menu === Menu.Scale || menu === Menu.AutoMeasure2) {
      return {
        ...settings,
        onClose: disableDrawMode,
      };
    }
    return settings;
  }, [menu, disableDrawMode]);
}

