import {
  Constants,
  HotKeys,
  Icons,
  Input,
  LinkComponent,
  ModalWrapper,
  Text,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { DrawingsAnnotationActions } from 'common/components/drawings/actions/creators/annotation';
import {
  DrawingsGeometryRenameInstance,
} from 'common/components/drawings/actions/payloads/annotation';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import { DrawingsUndoRedoHelper } from 'common/components/drawings/utils/drawings-undo-redo-helper';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { UndoRedoContextApiProps, withUndoRedoApiProps } from 'common/undo-redo';
import { Fields } from './enums';
import { ModesButtons } from './modes-buttons';
import { Styled } from './styled';


interface DispatchProps {
  closeRenameToolDialog: () => void;
  renameElements: (instances: DrawingsGeometryRenameInstance[]) => void;
}

interface StateProps {
  selectedInstances: string[];
  instances: Record<string, DrawingsGeometryInstance>;
}

interface Props extends DispatchProps, StateProps,  UndoRedoContextApiProps {

}

interface ComponentState {
  prefix: string;
  name: string;
  suffix: string;
  field: Fields;
}

export class DialogComponent extends React.PureComponent<Props, ComponentState> {
  private inputRef: HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      prefix: '',
      name: '',
      suffix: '',
      field: Fields.Replace,
    };
  }


  public render(): React.ReactNode {
    const { field, prefix, name, suffix } = this.state;
    const inputName = field === Fields.Replace ? 'Name' : field;
    const inputPlaceholder = `Enter ${inputName.toLowerCase()}`;
    const inputValue = field === Fields.Replace ? name : field === Fields.Prefix ? prefix : suffix;
    const readyForSubmit = prefix.length || name.length || suffix.length;

    return (
      <DialogWrapper name={DrawingDialogs.RENAME_TOOL_DIALOG}>
        <ModalWrapper onExit={this.closeModal}>
          <HotKeys
            keyMap={Constants.KeyMaps.ENTER_ESCAPE}
            handlers={{ escape: this.props.closeRenameToolDialog, enter: this.onSubmit }}
            autofocus={true}
          >
            <Styled.RenameDialogContainer>
              <Styled.DialogHeader>
                <Styled.MeasurementsNumber>
                  <Text color={'white'} fontSize={14}>{this.props.selectedInstances.length}</Text>
                </Styled.MeasurementsNumber>
                <Text fontSize={14}>Rename tool</Text>
                <LinkComponent
                  text={'Apply'}
                  Icon={Icons.Apply}
                  mood={!readyForSubmit && 'disabled'}
                  onClick={this.onSubmit}
                />
              </Styled.DialogHeader>
              <ModesButtons
                activeField={field}
                onChange={this.onChange}
              />
              <Input
                width='100%'
                type='text'
                text={inputName}
                input={{
                  value: inputValue,
                  onChange: this.changeField,
                  ref: this.saveInputRef,
                }}
                placeholder={inputPlaceholder}
                autoFocus={true}
              />
            </Styled.RenameDialogContainer>
          </HotKeys>
        </ModalWrapper>
      </DialogWrapper>
    );
  }

  @autobind
  private dropState(): void {
    this.setState({ name: '', suffix: '', prefix: '', field: Fields.Replace });
  }

  @autobind
  private closeModal(): void {
    this.props.closeRenameToolDialog();
    this.dropState();
  }

  @autobind
  private saveInputRef(ref: HTMLInputElement): void {
    this.inputRef = ref;
  }

  @autobind
  private onChange(field: Fields): void {
    this.setState({ field });
    this.inputRef.focus();
  }


  @autobind
  private changeField(event: React.ChangeEvent<HTMLInputElement>): void {
    const { field, name, prefix, suffix } = this.state;
    this.setState({
      name: field === Fields.Replace ? event.target.value : name,
      prefix: field === Fields.Prefix ? event.target.value : prefix,
      suffix: field === Fields.Suffix ? event.target.value : suffix,
    });
  }

  @autobind
  private onSubmit(): void {
    const { name, prefix, suffix } = this.state;
    const oldInstances = this.props.selectedInstances.map(instanceId => {
      return {
        instanceId,
        name: this.props.instances[instanceId].name,
      };
    });
    const regExp = new RegExp('^(.+) (\\d+)$');
    const instances = this.props.selectedInstances.map((instanceId) => {
      const oldName = this.props.instances[instanceId].name;
      const oldNameSplit = oldName.match(regExp);
      const prefixResult = prefix ? `${prefix} ` : '';
      const suffixResult = suffix ? ` ${suffix}` : '';
      const newNameAndSuffix = name && `${name}${suffixResult}`;
      const oldNameSplitAndSuffix = oldNameSplit && oldNameSplit[2]
        && `${oldNameSplit[1]}${suffixResult} ${oldNameSplit[2]}`;
      const oldNameAndSuffix = suffix ? `${oldName} ${suffix}` : oldName;
      const newName = `${prefixResult}${newNameAndSuffix || oldNameSplitAndSuffix || oldNameAndSuffix}`;
      return {
        instanceId,
        name: newName,
      };
    });
    const { undo, redo } = DrawingsUndoRedoHelper.renameMeasurementUndoRedo(
      instances,
      oldInstances,
      this.props.renameElements,
    );
    this.props.addUndoRedo(undo, redo);
    redo();
    this.dropState();
    this.props.closeRenameToolDialog();
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    selectedInstances: state.drawings.selectedInstances,
    instances: state.drawings.aiAnnotation.geometry,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    renameElements: (instances) => dispatch(DrawingsAnnotationActions.renameInstance(instances)),
    closeRenameToolDialog: () => dispatch(KreoDialogActions.closeDialog(DrawingDialogs.RENAME_TOOL_DIALOG)),
  };
}

export const Dialog = connect(mapStateToProps, mapDispatchToProps)(withUndoRedoApiProps(DialogComponent));
