import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import { AgGridCellWithComment } from '../ag-grid-cell-with-comment';

import { ExcelTableContext } from './interfaces';
import { ExcelFormulaHelper } from './utils';

export interface DefaultCellRendererParams extends Ag.ICellRendererParams {
  onMouseDown: (e: MouseEvent, cellId: string) => void;
  context: ExcelTableContext;
}

export class DefaultCellRenderer implements Ag.ICellRendererComp {
  private eDiv: HTMLDivElement;
  private params: DefaultCellRendererParams;
  private cellWithComment: AgGridCellWithComment<{ id?: number }>;

  public init(params?: DefaultCellRendererParams): void | Ag.AgPromise<void> {
    this.params = params;
    this.cellWithComment = new AgGridCellWithComment(params);
    this.eDiv = document.createElement('div');
    this.eDiv.style.width = '100%';
    this.eDiv.style.height = '100%';
    this.eDiv.innerText = params.valueFormatted !== undefined
      ? params.valueFormatted
      : '';
    const commentContainer = this.cellWithComment.getCommentHightLight(this.eDiv);
    if (commentContainer) {
      this.eDiv.appendChild(commentContainer);
    }
    this.initEvents();
  }

  public getGui(): HTMLElement {
    return this.eDiv;
  }

  public refresh(): boolean {
    return false;
  }

  public destroy(): void {
    const parent = this.params.eGridCell;
    parent.removeEventListener('mousedown', this.onMouseDown);
    parent.removeEventListener('mouseup', this.onMouseUp);
    parent.removeEventListener('mouseenter', this.onMouseEnter);
  }

  private initEvents(): void {
    const parent = this.params.eGridCell;
    parent.addEventListener('mousedown', this.onMouseDown);
    parent.addEventListener('mouseup', this.onMouseUp);
    parent.addEventListener('mouseenter', this.onMouseEnter);
  }

  @autobind
  private onMouseEnter(): void {
    const columnId = this.params.column.getColId();
    const rowId = this.params.data.id;
    this.params.context.rangeSelection.onMouseEnter(rowId, columnId, this.params);
  }

  @autobind
  private onMouseUp(): void {
    this.params.context.rangeSelection.onMouseUp();
  }

  @autobind
  private onMouseDown(e: MouseEvent): void {
    if (!this.eDiv.parentElement) {
      return;
    }
    const columnId = this.params.column.getColId();
    const rowId = this.params.data.id;
    const sheetId = this.params.context.sheetId;
    const value = ExcelFormulaHelper.getCellLink(sheetId, columnId, rowId);

    const isUpdateSuccesses = this.params.context.referenceReader.updateTarget(value);
    if (isUpdateSuccesses) {
      e.stopPropagation();
      e.preventDefault();

      this.params.context.rangeSelection.onMouseDown(rowId, columnId, this.params);
    }
  }
}
