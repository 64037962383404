import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { WizzardToolsActions } from '../../../actions/creators';
import { WizzardErrorTypes } from '../../../enums';
import { WizzardStatus } from '../../../enums/dropper-state';

interface FinderSettingReduxProps {
  dropFinderArea: () => void;
  addWorkingArea: () => void;
  search: () => void;
  eraser: () => void;
  error: WizzardErrorTypes;
}


export function useFinderSettingsRedux(): FinderSettingReduxProps {
  const error = useSelector<State, WizzardErrorTypes>(x => x.drawings.wizzardTools.error);
  const dispatch = useDispatch();

  return {
    error,
    dropFinderArea: useCallback(() => dispatch(WizzardToolsActions.dropFinderSelectionArea()), []),
    search: useCallback(() => dispatch(WizzardToolsActions.runFinderSearch()), []),
    eraser: useCallback(() => dispatch(WizzardToolsActions.setWizzardState(WizzardStatus.Eraser)), []),
    addWorkingArea: useCallback(
      () => dispatch(WizzardToolsActions.setWizzardState(WizzardStatus.SpecifySearchArea)),
      [],
    ),
  };
}
