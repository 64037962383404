import { LabelStyled } from '@kreo/kreo-ui-components';
import styled, { css } from 'styled-components';
import { ToggleButtonStyled } from '../../../toggle-button';
import { DrawingsAnnotationLegendUserInfoStyled } from '../drawings-annotation-legend-user-info';
import { EntityLabelStyled } from '../entity-label';

const OPEN_TOGGLE_WIDTH = 20;
const COLOR_INDICATOR_WIDTH = 20;

const PinContainer = styled.div`
  z-index: 2;
  display: none;
  padding-right: 5px;

  > div {
    padding-right: 0;
  }
`;

const CreateNew = styled(PinContainer)`
  display: flex;
`;

const Count = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 5px;
  background-color: ${p => p.theme.color.pale};
  margin-right: 5px;

  > p {
    line-height: 1;
    padding-top: 2px;
    color: ${p => p.theme.color.turquoise};
  }

  & + ${ToggleButtonStyled.VisibleToggle} {
    padding-left: 0px;
  }
`;


const GroupValues = styled.div<{ hideInfo: boolean }>`
  margin-right: 5px;
  height: ${p => p.hideInfo ? '10px' : '100%'};
  display: flex;
  align-items: center;

  div {
    height: 100%;
    max-height: 100%;
    align-items: center;
  }
`;

const DndHoverStyle = css`
  box-shadow: inset 0 0 0 2px ${p => p.theme.color.turquoise};
`;

interface ContainerProps {
  isSelected?: boolean;
  hovering?: boolean;
  nestingCount: number;
  hideCount?: boolean;
  hidePin?: boolean;
  isOpenFilterPages?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  flex-shrink: 0;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: ${p => p.nestingCount * 20}px;
  color: ${p => p.theme.color.mainFont};
  background-color: ${p => p.isSelected ? p.theme.color.pale : p.theme.color.backgroundRush};
  box-shadow: 0 0 0 1px ${p => p.isSelected ? p.theme.color.turquoise : p.theme.color.background};
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  min-width: initial;
  max-width: -webkit-fill-available;
  z-index: 0;
  ${p => p.hovering ? DndHoverStyle : ''}

  &:active {
    transform: translateY(2px);
  }

  &:hover {
    background-color: ${p => p.isSelected ? p.theme.color.pale : p.theme.color.background};
    box-shadow: 0 0 0 1px ${p => p.isSelected ? p.theme.color.turquoise : p.theme.color.background};
    z-index: ${p => p.isOpenFilterPages ? 1 : 2};

    ${PinContainer} {
      display: ${p => !p.hidePin ? 'block' : 'none'};
    }

    ${Count} {
      display: flex;
    }

    ${GroupValues} {
      display: none;
    }
  }

  ${Count} {
    display: ${p => p.hideCount ? 'none' : 'flex'};
  }
`;

const highlightedStyle = css`
  ${LabelStyled.TextContainer} {
    > p {
      color: ${p => p.theme.color.turquoise};
    }
  }
  button {
    svg {
      fill: ${p => p.theme.color.turquoise} !important;
    }
  }
`;

const Wrapper = styled.div<{ isHighlighted?: boolean }>`
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  min-width: 0;
  height: 24px;
  ${p => p.isHighlighted ? highlightedStyle : ''}
`;

const Inner = styled.div<{ hideTitle: boolean }>`
  display: ${p => p.hideTitle ? 'none' : 'flex'};
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  width: 100%;
  min-width: 0;
  max-width: 320px;
  flex: 1 1 auto;
`;

interface OpenToggleProps {
  isOpen: boolean;
  hideOpenToggleIcon: boolean;
}

const OpenToggle = styled.div<OpenToggleProps>`
  display: ${p => p.hideOpenToggleIcon ? 'none' : 'flex'};
  align-items: center;
  width: ${OPEN_TOGGLE_WIDTH}px;
  padding-right: 5px;

  button {
    position: relative;
    height: 20px;
    z-index: 2;
    border: 0;
    padding-left: 2px;

    &:hover svg {
      fill: ${p => p.theme.color.turquoise};
    }
  }

  span svg {
    transform: rotate(${p => p.isOpen ? '90deg' : '0deg'});
    width: 10px;
    fill: ${p => p.theme.color.gray};
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.l};
  }
`;

const ColorIndicatorContainer = styled.div<{ hideColorIndicator: boolean, color: string }>`
  display: ${p => p.hideColorIndicator ? 'none' : 'flex'};
  min-width: ${COLOR_INDICATOR_WIDTH}px;
  padding-right: 5px;

  button {
    position: relative;
    z-index: 2;
  }
  span svg,
  &:hover span svg {
    fill: ${p => p.color};
  }
`;


const OrderLineContainer = styled.div<{ nestingCount: number, bottomLine: boolean }>`
  position: absolute;
  width: calc(100% - ${p => p.nestingCount > 1 ? 40 : 20}px);
  height: 10px;
  margin-left: ${p => p.nestingCount * 20}px;
  display: flex;
  align-items: center;
  ${p => p.bottomLine ? 'bottom' : 'top'}: -6px;
`;

const OrderLine = styled.div<{ nestingCount: number, hovering: boolean }>`
  width: calc(100% - ${p => p.nestingCount === 1 ? 40 : p.nestingCount > 1 ? p.nestingCount * 20 : 20}px);
  height: 2px;
  background-color: ${p => p.hovering && p.theme.color.turquoiseDay};
  transition: ${p => p.theme.duration.s} ${p => p.theme.function.l};
`;

const SeparatorResizeContainer = styled.div`
  height: 100%;
  flex: 1 100 auto;
  min-width: 0;
  max-width: 100%;
`;

interface ItemsContainerProps {
  hideAvatar?: boolean;
  hideVisibleToggle?: boolean;
  hideEntityLabel?: boolean;
  hideCreateNew?: boolean;
}

const ItemsContainer = styled.div<ItemsContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;


  ${DrawingsAnnotationLegendUserInfoStyled.AvatarContainer} {
    display: ${p => p.hideAvatar ? 'none' : 'inline-flex'};
  }

  ${ToggleButtonStyled.VisibleToggle} {
    display: ${p => p.hideVisibleToggle ? 'none' : 'flex'};
  }

  ${EntityLabelStyled.Container} {
    display: ${p => p.hideEntityLabel ? 'none' : 'inline-flex'};
  }

  ${CreateNew} {
    display: ${p => p.hideCreateNew ? 'none' : 'flex'};
  }
`;


export const Styled = {
  PinContainer,
  CreateNew,
  Container,
  Wrapper,
  Inner,
  Count,
  OpenToggle,
  ColorIndicatorContainer,
  OrderLineContainer,
  OrderLine,
  SeparatorResizeContainer,
  ItemsContainer,
  GroupValues,
};
