import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { ToolMenuButton } from '../tool-menu-button';

interface Props {
  hasZone: boolean;
  clearZone: () => void;
  specifyZone: () => void;
}

const SearchZoneButtonComponent: React.FC<Props> = ({
  hasZone,
  clearZone,
  specifyZone,
}) => {
  if (hasZone) {
    return (
      <ToolMenuButton
        text='Remove Zone'
        icon={Icons.Delete}
        mood='negative'
        onClick={clearZone}
      />
    );
  } else {
    return (
      <ToolMenuButton
        text='Add Search Zone'
        icon={Icons.SearchZone2D}
        onClick={specifyZone}
      />
    );
  }

};

export const SearchZoneButton = React.memo(SearchZoneButtonComponent);
