import { Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { InformationLine } from './information-line';


interface Props {
  title: string;
  value: string | number;
  isValueName?: boolean;
}

export const SimpleInformationLine: React.FC<Props> = ({ title, value, isValueName }) => {
  return (
    <InformationLine title={title}>
      <Text
        fontSize={10}
        withEllipsis={isValueName}
      >
        {value}
      </Text>
    </InformationLine>
  );
};
