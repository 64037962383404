import React from 'react';

import { RenderIf } from 'common/components/render-if';
import { Text } from 'common/components/text';
import { ColorsName } from 'common/enums/kreo-colors';
import { SimilaritySlider } from './similarity-slider';
import { Styled } from './styled';

interface Props {
  count: number;
  hideSlider?: boolean;
  minSimilarity?: number;
  maxSimilarity?: number;
  similarity?: number;
  similarityText?: string;
  onSimilarityChange?: (value: number) => void;
}

const SimilaritySliderWithCountComponent: React.FC<Props> = ({
  count,
  hideSlider,
  minSimilarity,
  maxSimilarity,
  similarity,
  similarityText,
  onSimilarityChange,
}) => {
  return (
    <>
      <Styled.ResultTitle>
        <Text fontSize={14} lineHeight={10} color={ColorsName.mainFont}>
          {count} results found
        </Text>
      </Styled.ResultTitle>
      <RenderIf condition={!hideSlider}>
        <SimilaritySlider
          minValue={minSimilarity}
          maxValue={maxSimilarity}
          similarity={similarity}
          onChange={onSimilarityChange}
          similarityText={similarityText}
        />
      </RenderIf>
    </>
  );
};

export const SimilaritySliderWithCount = React.memo(SimilaritySliderWithCountComponent);
