import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { Styled } from './styled';

const BotAvatarComponent: React.FC = () => {
  return (
    <Styled.Container>
      <Icons.Caddy/>
    </Styled.Container>
  );
};

export const BotAvatar = React.memo(BotAvatarComponent);
