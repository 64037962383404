import classNames from 'classnames';
import * as React from 'react';
import { Validator } from 'redux-form';

import './file-upload-wrap.scss';

import { UploadingFile } from 'common/interfaces/common-state';
import { KreoIconCreateNew } from 'common/UIKit';
import { LineFile } from '../../../components/dialog/fields';
import { SvgSpinner } from '../svg-spinner';


interface Props {
  isDownloadFileInProgress?: boolean;
  formName: string;
  validate?: Validator[];
  onUploadStarted?: (file: File[]) => void;
  onDeleteFile?: (file: UploadingFile) => void;
  extensions: string[];
  extensionsToShow: string[];
  children?: React.ReactNode;
}


export class FileUploadWrapObsolete extends React.PureComponent<Props> {
  private fileTitle: React.ReactNode = (
    <span className='file-upload-wrap__select-file-label'>
      <KreoIconCreateNew />
      <span>
        Select {this.props.extensionsToShow
        .filter(x => x !== 'rvt')
        .map(x => (<b key={x}>{`.${x.toUpperCase()} `}</b>))
      }files</span>
    </span>
  );

  public render(): React.ReactNode {
    const { isDownloadFileInProgress, onUploadStarted, formName, validate, onDeleteFile } = this.props;
    const projectFileExtension = this.props.extensions
      .map(x => `.${x}`)
      .join(', ');
    return (
      <div
        className={classNames(
          'file-upload-wrap',
          { 'file-upload-wrap--download': isDownloadFileInProgress },
        )}
      >
        <LineFile
          name='fileName'
          form={formName}
          title={isDownloadFileInProgress ? 'downloading project...' : this.fileTitle}
          validate={validate}
          accept={projectFileExtension}
          onUploadStarted={onUploadStarted}
          onDeleteFile={onDeleteFile}
          disabled={isDownloadFileInProgress}
          extensionsToShow={this.props.extensionsToShow}
        />
        {isDownloadFileInProgress
          ? <SvgSpinner size='small' />
          : null
        }
      </div>
    );
  }
}
