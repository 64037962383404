import React from 'react';

export const InformationRow: React.FC = ({
  children,
}) => {
  return (
    <div className='information-row'>
      {children}
    </div>
  );
};
