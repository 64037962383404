import * as t from 'io-ts';

import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { CodecUtils } from 'common/utils/codec-utils';
import { QtoTreeRowPropertyC } from './quantity-take-off-tree-row-property';

export const TreeTableRowTypeC =
  CodecUtils.createEnumCodec<TreeTableRowType>(TreeTableRowType, 'TreeTableRowType');

export const QtoReportRowC = t.intersection([
  t.type({
    id: t.string,
    type: TreeTableRowTypeC,
    properties: t.dictionary(t.string, QtoTreeRowPropertyC),
    // pivotProperties: t.dictionary(t.string, QtoReportRowPropertyC),
  }),
  t.partial({
    parentId: t.string,
    children: t.array(t.string),
  }),
]);

export type QtoReportRow = t.TypeOf<typeof QtoReportRowC>;
