import { Constants, HotKeys, Input, ModalWrapper, RectangleButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { ProjectType } from 'common/constants/project-type';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { StringUtils } from 'common/utils/string-utils';
import { Styled } from './styled';

interface Props {
  projectName: string;
  projectType: string;
  onEditProjectName: (name: string) => void;
  onClose: () => void;
}

interface State {
  newProjectName: string;
}

export const PROJECT_NAME_EDIT_DIALOG_NAME = 'project-name-edit-dialog';

export class TwoDProjectsNameEditDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newProjectName: props.projectName ? props.projectName : '',
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.projectName !== this.props.projectName) {
      this.setState({ newProjectName: this.props.projectName });
    }
  }

  public render(): React.ReactNode {
    const { projectType } = this.props;
    const inputText = projectType !== ProjectType.Database ? 'Project' : StringUtils.firstUpperLetter(projectType);

    return (
      <DialogWrapper
        name={PROJECT_NAME_EDIT_DIALOG_NAME}
      >
        <ModalWrapper onExit={this.props.onClose}>
          <HotKeys
            keyMap={Constants.KeyMaps.ENTER_ESCAPE}
            handlers={{ escape: this.props.onClose, enter: this.onEditProjectName }}
            autofocus={true}
          >
            <Styled.Container>
              <Input
                width='340px'
                type='text'
                text={`${inputText} name`}
                input={{
                  value: this.state.newProjectName,
                  onChange: this.onChangedProjectName,
                }}
                placeholder={`Enter ${projectType} name`}
              />
              <Styled.Separator />
              <RectangleButton
                size='xl'
                mood={this.canSaveName() ? 'positive' : 'disabled'}
                onClick={this.onEditProjectName}
                text='Save'
                width={340}
              />
            </Styled.Container>
          </HotKeys>
        </ModalWrapper>
      </DialogWrapper>
    );
  }

  private canSaveName(): boolean {
    return this.state.newProjectName.trim().length > 0;
  }

  @autobind
  private onEditProjectName(): void {
    if (this.canSaveName()) {
      this.props.onEditProjectName(this.state.newProjectName);
    }
  }

  @autobind
  private onChangedProjectName(event: React.ChangeEvent<HTMLInputElement>): void {
    event.preventDefault();
    this.setState({ newProjectName: event.target.value });
  }
}
