import styled from 'styled-components';


const ChatLink = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid ${props => props.theme.color.background};
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 20px;

    > div {
      > svg {
        margin-right: 20px;
      }

      p {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        color: ${props => props.theme.color.mainFont} !important;
      }

      :hover {
        transform: none;
      }

      :active {
        transform: none;
      }
    }
  }

  :hover {
    background: ${props => props.theme.color.pale};
    border-radius: 15px;

    p {
      color: ${props => props.theme.color.turquoise} !important;
    }
    svg {
      fill: ${props => props.theme.color.turquoise} !important;
    }
  }
`;

export const Styled = {
  ChatLink,
};
