import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { DrawingsRenderParams, ShortPointDescription } from 'common/components/drawings/interfaces';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { VisibleEntity, VisibleEntityConfig } from '../../common';

export interface UtilityPolygonConfig extends VisibleEntityConfig<ShortPointDescription[]> {
  geometry: ShortPointDescription[];
  layer: paper.Layer | paper.Group;
  color?: DrawingsPaperColorInfo;
  onMouseEnter?: (e: PaperMouseEvent) => void;
  onMouseDown?: (e: PaperMouseEvent) => void;
}


export const UTILITY_STYLES = {
  dashArray: [4, 8],
  strokeWidth: 2,
  strokeCap: 'round',
  shadowBlur: 4,
};

export class DrawingsGeometryUtilityPolygon<T extends UtilityPolygonConfig = UtilityPolygonConfig>
  extends VisibleEntity<ShortPointDescription[], T> {
  protected _path: paper.Path;

  constructor(config: T) {
    super(config);
    this._config.renderParamsContextObserver.subscribe(this.updateZoom);
  }

  public destroy(): void {
    this._path.remove();
    this._config.renderParamsContextObserver.unsubscribe(this.updateZoom);
  }

  protected render(geometry: ShortPointDescription[]): void {
    this._path = new paper.Path(geometry.map(x => new paper.Point(x)));
    this._path.addSegments([this._path.firstSegment]);
    this._path.addTo(this._config.layer);
    if (this._config.color) {
      this._path.fillColor = this._config.color.fill;
      this._path.strokeColor = this._config.color.stroke;
    } else {
      this._path.fillColor = DrawingsCanvasColors.utilityTransparent;
      this._path.strokeColor = DrawingsCanvasColors.utility;
    }

    this._path.strokeCap = UTILITY_STYLES.strokeCap;
    this._path.shadowColor = this._path.strokeColor;
    this._path.onMouseEnter = this._config.onMouseEnter;
    this._path.onMouseDown = this._config.onMouseDown;
    this._path.shadowOffset = new paper.Point(0, 0);
  }

  @autobind
  private updateZoom({ zoom }: DrawingsRenderParams): void {
    this._path.strokeWidth = UTILITY_STYLES.strokeWidth / zoom;
    this._path.shadowBlur = UTILITY_STYLES.shadowBlur / zoom;
    this._path.dashArray = DrawingsCanvasUtils
      .scaleDashArray(UTILITY_STYLES.dashArray, UTILITY_STYLES.strokeWidth, zoom);
  }
}
