import { UpdateCellData } from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { TwoDRegex } from '../2d-regex';
import { MoveDataPayload } from './interfaces';

export const getUpdatedCell = (
  updatedCellsMap: Record<string, UpdateCellData>,
  cellId: string,
  sheetId: string,
  payload: MoveDataPayload,
): UpdateCellData => {
  if (!updatedCellsMap[cellId]) {
    const { sheetsData } = payload;
    const { rowId, columnId } = TwoDRegex.fullCellId.exec(cellId).groups;
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const rows = sheetsData[sheetId].rows;
    const prevRowValue = rows[rowIndex][columnId] || '';
    updatedCellsMap[cellId] = {
      prevValue: prevRowValue,
      value: prevRowValue,
      rowId,
      columnId,
    };
  }

  return updatedCellsMap[cellId];
};
