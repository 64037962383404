import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './index.scss';

import { State } from 'common/interfaces/state';
import { TopCardPanel } from 'common/UIKit';

import { ViewerActions } from '../../actions';
import { ModelBrowserTreeItem } from '../../interfaces';
import { Tree as Tr } from './tree';

const Tree: any = Tr;

interface StateProps {
  tree: ModelBrowserTreeItem[];
  properties: any;
  showProperties: boolean;
  searchMode: boolean;
}


interface DispatchProps {
  onExpandElement: (id: any) => void;
  searchRun: (value: string) => void;
  searchEnd: () => void;
}

interface OwnProps {
  selectedId: any;
  isEngineEvent: boolean;
  onUpdate: () => void;
  showTree: () => void;
  onShow: (ids: any, identificator: any, hasIds: boolean) => void;
  onHide: (ids: any, identificator: any, hasIds: boolean) => void;
  onSelect: (item: any, identificator: any, ids: any, hasIds: boolean) => void;
  onGhost: (ids: any, identificator: any, hasIds: boolean) => void;
  onAddSelect: (item: any, identificator: any) => void;
  onDeselectItem: (item: any, identificator: any) => void;
  getTreeList: (ref: any) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {

}

class PropertiesTreeComponent extends React.Component<Props> {

  public shoudComponentUpdate(nextProps: any): boolean {
    if (nextProps.enginemove) {
      this.props.onUpdate();
      return false;
    }
    return true;
  }

  public render(): JSX.Element {

    return (
      <div className='viewer-property-tree'>
        <TopCardPanel
          content={'MODEL BROWSER'}
          className={'property-tree-header'}
          onExitClick={this.props.showTree}
          style={{ minHeight: '70px' }}
          exit={true}
          controlNameButtonClose='viewer-tree-close-button'
        />
        <Tree
          isEngineEvent={this.props.isEngineEvent}
          onUpdate={this.props.onUpdate}
          selectedIds={this.props.selectedId}
          search={this.onSearch}
          onShow={this.props.onShow}
          onHide={this.props.onHide}
          onExpandElement={this.props.onExpandElement}
          data={this.props.tree}
          showProps={this.props.showProperties}
          searchMode={this.props.searchMode}
          onSelect={this.props.onSelect}
          onGhost={this.props.onGhost}
          onAdd={this.props.onAddSelect}
          onDeselect={this.props.onDeselectItem}
          getTreeList={this.props.getTreeList}
        />
      </div>);
  }

  @autobind
  private onSearch(value: string): void {
    if (value?.length > 0) {
      this.props.searchRun(value);
    } else {
      this.props.searchEnd();
    }
  }
}


function mapStateToProps(state: State): StateProps {
  const props = state.viewer.get('properties').toJS();
  return {
    tree: state.viewersets.bimInfo,
    showProperties: props && props.props && props.props.length > 0,
    properties: props,
    searchMode: state.viewer.get('searchMode'),
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onExpandElement: (id: any) => {
      dispatch(ViewerActions.elementExpand(id));
    },
    searchRun: (value: any) => {
      dispatch(ViewerActions.runSearch(value));
    },
    searchEnd: () => {
      dispatch(ViewerActions.endSearch());
    },
  };
}


const connecter = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps);
export const PropertiesTree = connecter(PropertiesTreeComponent);
