import styled from 'styled-components';
import { TinyText, Text } from '../text';

const Container = styled.div`
  position: relative;
  padding: 40px;
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.color.backgroundRush};
  width: 640px;
  height: 500px;
  border-radius: 40px;
  &, * {
    box-sizing: border-box;
  }
  button {
    margin-left: auto;
  }
  > ${Text} {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
  }
`;

const IconShare = styled.div`
  display: inline-flex;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    fill: ${p => p.theme.color.gray};
  }
`;

const BtnWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  padding: 20px;
  overflow-y: auto;
`;

const Form = styled.div`
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  label {
    margin-bottom: 0;
  }
  > ${TinyText} {
    position: absolute;
    bottom: -20px;
    left: 130px;
  }
  > button {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  }
  :hover {
    > button {
      opacity: 1;
      transform: translate(-10px, -50%);
    }
  }
`;

const DropdownContainer = styled.form`
  width: 100%;
`;

export const Styled = {
  Container,
  DropdownContainer,
  Form,
  FormWrapper,
  IconShare,
  BtnWrapper,
};
