import {
  DrawingsGeometryGroup,
  DrawingsGeometryInstance,
  DrawingsInstanceMeasure,
  DrawingsLayoutApi,
  DrawingsMeasureLength,
} from 'common/components/drawings';
import { MoveToCellOptionType } from 'common/components/drawings/drawings-canvas-menus';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsFile, DrawingsFolderViewInfo } from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces/drawings-short-drawing-info';
import { ExcelFormulaHelper } from 'common/components/excel-table';
import { MeasureUtil } from 'common/utils/measure-util';
import { UnitUtil } from 'common/utils/unit-util';
import { ValueHelper } from 'common/utils/value-helper';
import { TwoDRegex } from './2d-regex';
import { getMeasurementsList } from './get-measurements-list';

const getTextValue = (value: string): string => {
  return `${ExcelFormulaHelper.extendQuotInSheetName(value)}`;
};

const getInstanceName = (geometryInstance: DrawingsGeometryInstance): string => {
  const name = geometryInstance
    ? geometryInstance.name
    : ExcelFormulaHelper.INVALID_REF;

  return getTextValue(name);
};

const getDrawingName = (
  geometryInstance: DrawingsGeometryInstance,
  drawingInfo: Record<string, DrawingsShortInfo>,
): string => {
  const drawingId = geometryInstance
    ? geometryInstance.drawingId
    : null;
  const pageName = drawingInfo && drawingId && drawingInfo[drawingId]
    ? drawingInfo[drawingId].name
    : ExcelFormulaHelper.INVALID_REF;
  return getTextValue(pageName);
};

const getCastedSegmentLength = (
  pointsArgs: string,
  getSegmentLength: (ids: string) => DrawingsInstanceMeasure | null,
  isImperial: boolean,
): string => {
  const ids = pointsArgs.replace(',', '');
  const measure = getSegmentLength(ids);
  if (!measure) {
    return ExcelFormulaHelper.INVALID_REF;
  }
  const value = (measure.measures as DrawingsMeasureLength).length;
  const unit = MeasureUtil.measureUnit[MoveToCellOptionType.segmentLength];
  const castedValue = UnitUtil.getCastedMeasure2d(value, unit, isImperial);

  return ValueHelper.isNumberValue(castedValue)
    ? castedValue.toString()
    : ExcelFormulaHelper.INVALID_REF;
};

const getMetricMeasure = (
  ids: DrawingsInstanceMeasure | string[],
  type: MoveToCellOptionType,
  getInstancesMeasures: (ids: DrawingsInstanceMeasure | string[]) => DrawingsInstanceMeasure[],
): number => {
  const instances = getInstancesMeasures(ids);
  if (!instances) {
    return null;
  }

  return instances.reduce((acc, el) => acc + (el.measures[type] || 0), 0);
};

const getCastedMeasure = (
  id: string,
  type: MoveToCellOptionType,
  getInstancesMeasures: (ids: string[]) => DrawingsInstanceMeasure[],
  isImperial: boolean,
  groups: DrawingsGeometryGroup[],
  geometry: Record<string, DrawingsGeometryInstance>,
): string => {
  const measurementList = getMeasurementsList(id, groups, geometry);
  if (!measurementList) {
    return ExcelFormulaHelper.INVALID_REF;
  }
  const value = getMetricMeasure(measurementList, type, getInstancesMeasures);
  const castedValue = UnitUtil.getCastedMeasure2d(value, MeasureUtil.measureUnit[type], isImperial);

  return ValueHelper.isNumberValue(castedValue) && castedValue.toString();
};

const getGroupList = (groups: DrawingsGeometryGroup[], id: string, list: DrawingsGeometryGroup[]): void => {
  const drawingGroup = groups.find(group => group.id === id);
  if (drawingGroup) {
    list.unshift(drawingGroup);
    getGroupList(groups, drawingGroup.parentId, list);
  }
};

const getGroupName = (
  id: string,
  drawingGeometryGroups: DrawingsGeometryGroup[],
  geometryInstance: DrawingsGeometryInstance,
  otherArgs: string,
): string => {
  if (!geometryInstance) {
    return ExcelFormulaHelper.INVALID_REF;
  }

  const drawingInstanceGroupList = [];
  const instanceParentGroup = drawingGeometryGroups.find(
    group => group.measurements.some(instanceId => instanceId === id),
  );
  if (instanceParentGroup) {
    drawingInstanceGroupList.push(instanceParentGroup);
    getGroupList(drawingGeometryGroups, instanceParentGroup.parentId, drawingInstanceGroupList);
  }

  const nestingArg = otherArgs
    ? Number(otherArgs.split(',')[1])
    : -1;

  const nesting = nestingArg >= 0 ? nestingArg : drawingInstanceGroupList.length + nestingArg;
  const drawingGroup = drawingInstanceGroupList[nesting];
  return getTextValue(drawingGroup ? drawingGroup.name : 'No group');
};

const getDynamicGroupName = (
  id: string,
  drawingGeometryGroups: DrawingsGeometryGroup[],
): string => {
  const dynamicGroup = drawingGeometryGroups.find(group => group.id === id);
  return getTextValue(dynamicGroup ? dynamicGroup.name : ExcelFormulaHelper.INVALID_REF);
};

const getDocumentName = (
  geometryInstance: DrawingsGeometryInstance,
  drawingInfo: Record<string, DrawingsShortInfo>,
  entities: Record<string, DrawingsFile | DrawingsFolderViewInfo>,
): string => {
  const drawingId = geometryInstance
    ? geometryInstance.drawingId
    : null;
  const page = drawingInfo
    ? drawingInfo[drawingId]
    : undefined;
  const document = page && entities && entities[page.pdfId]
    ? entities[page.pdfId] as DrawingsFile
    : undefined;
  const documentName = document
    ? document.properties.name
    : ExcelFormulaHelper.INVALID_REF;
  return getTextValue(documentName);
};

const getDrawingProperty = (
  drawingInstanceId: string,
  keyArgs: string,
  geometry: Record<string, DrawingsGeometryInstance>,
): string => {
  const propertiesKey = typeof keyArgs === 'string' ? keyArgs.replace(',', '') : '';
  if (!geometry[drawingInstanceId]) {
    return '';
  }
  const properties = geometry[drawingInstanceId].properties;
  const propertyValue = properties ? properties[propertiesKey] : '';

  return getTextValue(propertyValue);
};

const getCount = (
  id: string,
  getInstancesMeasures: (ids: DrawingsInstanceMeasure | string[]) => DrawingsInstanceMeasure[],
  groups: DrawingsGeometryGroup[],
  geometry: Record<string, DrawingsGeometryInstance>,
): string => {
  const measurementList = getMeasurementsList(id, groups, geometry);
  if (!measurementList) {
    return ExcelFormulaHelper.INVALID_REF;
  }
  const instances = getInstancesMeasures(measurementList);
  let count = 0;

  for (const instance of instances) {
    const value = instance.measures[MoveToCellOptionType.count];
    if (ValueHelper.isNumberValue(value)) {
      count += value;
    } else {
      count += 1;
    }
  }
  return count.toString();
};

export const replaceDrawingRef = (
  value: string,
  drawingsLayoutApi: DrawingsLayoutApi,
  isImperial: boolean,
  geometry: Record<string, DrawingsGeometryInstance>,
  drawingInfo: Record<string, DrawingsShortInfo>,
  drawingGeometryGroups: DrawingsGeometryGroup[],
  entities: Record<string, DrawingsFile | DrawingsFolderViewInfo>,
  getInstancesMeasures: (ids: string[]) => DrawingsInstanceMeasure[],
  getSegmentLength: (points: string) => DrawingsInstanceMeasure | null,
): string => {
  const matches = value.match(TwoDRegex.drawingInstance);
  const groups = matches.groups;
  const otherArgs = groups.otherArgs;
  const id = groups?.drawingInstanceId;
  const type = groups?.drawingInstanceType;
  const isDrawingValueInvalid = geometry[id]?.type === DrawingsInstanceType.Polygon
    && (type === MoveToCellOptionType.width);

  if (isDrawingValueInvalid) {
    return ExcelFormulaHelper.INVALID_VALUE;
  }


  if (matches && drawingsLayoutApi) {
    switch (type) {
      case MoveToCellOptionType.name:
        return getInstanceName(geometry[id]);
      case MoveToCellOptionType.drawingName:
        return getDrawingName(geometry[id], drawingInfo);
      case MoveToCellOptionType.documentName:
        return getDocumentName(geometry[id], drawingInfo, entities);
      case MoveToCellOptionType.groupName:
        return getGroupName(id, drawingGeometryGroups, geometry[id], otherArgs);
      case MoveToCellOptionType.segmentLength:
        return getCastedSegmentLength(otherArgs, getSegmentLength, isImperial);
      case MoveToCellOptionType.area:
      case MoveToCellOptionType.perimeter:
      case MoveToCellOptionType.length:
      case MoveToCellOptionType.width:
      case MoveToCellOptionType.height:
      case MoveToCellOptionType.pointsCount:
      case MoveToCellOptionType.segmentsCount:
      case MoveToCellOptionType.verticalArea:
      case MoveToCellOptionType.volume:
      case MoveToCellOptionType.thickness:
        return getCastedMeasure(id, type, getInstancesMeasures, isImperial, drawingGeometryGroups, geometry);
      case MoveToCellOptionType.count:
        return getCount(id, getInstancesMeasures, drawingGeometryGroups, geometry);
      case MoveToCellOptionType.property:
        return getDrawingProperty(id, otherArgs, geometry);
      case MoveToCellOptionType.dynamicTable:
        return getDynamicGroupName(id, drawingGeometryGroups);
      default: return ExcelFormulaHelper.INVALID_REF;
    }
  }

  return ExcelFormulaHelper.INVALID_REF;
};
