import { ContextObserverCallbackGroup } from './context-observer-callbacks-group';
import { ContextObserverWithPrevious } from './interfaces';

export class ContextObserverCallbackGroupWithPrev<T>
  extends ContextObserverCallbackGroup<T>
  implements ContextObserverWithPrevious<T> {
  protected _observer: ContextObserverWithPrevious<T>;

  constructor(observer: ContextObserverWithPrevious<T>) {
    super(observer);
  }


  public getPrevContext(): T {
    return this._observer.getPrevContext();
  }
}
