import styled from 'styled-components';

const Container = styled.div`
  gap: 12px;
  justify-content: flex-end;
  display: flex;
  button {
    width: fit-content;
    font-weight: 500;
    padding: 9px 15px;
    p {
      line-height: 1;
    }
  }
`;

export const Styled = {
  Container,
};
