import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './chart-line-name.scss';

import {
  KreoIconEnterIsolateMode,
  KreoIconExitIsolateMode,
} from 'common/UIKit/icons';
import {
  BaseWorkPackageName,
} from '../../../../../../../../../components/gantt-chart';
import {
  LineData,
} from '../../../../../../../../../interfaces/gantt-chart';
import {
  GroupType,
} from '../../../../../../../../../utils/gantt-chart';


interface NameProps {
  data: LineData;
  isIsolated: boolean;
  toggleIsolation: (line: LineData | null) => void;
}

export class ChartLineName extends React.PureComponent<NameProps> {
  public render(): JSX.Element {
    const lineType = this.props.data.type;
    return (
      <BaseWorkPackageName
        data={this.props.data}
        isShowExpandButton={
          this.props.isIsolated ||
          lineType === GroupType.WorkPackage
        }
      >
        <div
          className={classNames('chart-line-name__isolate-button-container', { isolated: this.props.isIsolated })}
          onClick={this.props.data.isIsolatable ? this.toggleIsolation : null}
        >
          {
            this.props.data.isIsolatable &&
            (
              this.props.isIsolated
                ? <KreoIconExitIsolateMode />
                : <KreoIconEnterIsolateMode />
            )
          }
        </div>
      </BaseWorkPackageName>
    );
  }

  @autobind
  private toggleIsolation(): void {
    this.props.isIsolated ? this.props.toggleIsolation(null) : this.props.toggleIsolation(this.props.data);
  }
}
