import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { renderIconToString } from 'common/components/string-icon-renderer';

import { Constants } from '../constants';

function renderItemIcon(iconType: string): string {
  const Icon = Constants.ICON_LIST_MAP[iconType];
  return renderIconToString(Icon);
}

function renderAssemblyIcon(): string {
  return ReactDOMServer.renderToString(React.createElement(Icons.AssembliesDataBase, {}, null));
}

export const RenderIconsUtils = {
  renderItemIcon,
  renderAssemblyIcon,
};
