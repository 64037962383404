import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { HeaderContext } from 'common/enums/header-context';
import { State } from 'common/interfaces/state';
import { RoutingContextProps, withRoutingContext } from 'common/routing/with-routing-context';
import { PageLayout } from '../../../../layouts/page-layout';
import { PlanProjectRouteParams, Qto2dProjectRouteParams } from '../../../../routes/app-routes-params';
import { NotFoundPage } from '../../../notfound/page';
import { ProjectsActions } from '../../actions/creators/common';
import { ValidationStepsNames } from '../../constants/validation-steps-names';
import { Project } from '../../interfaces/project';
import { projectInnerHeader } from '../../routes/validation';
import { ModelCheckViewPage } from '../model-check-view';
import { ModelCheckActions } from '../model-check-view/actions';
import { ModelCheckPage } from './page';

interface RoutesStateProps {
  currentProject: Project | null;
}

interface RoutesDispatchProps {
  changeProjectName: (name: string) => void;
  dropState: () => void;
}

interface RoutesProps extends RouteComponentProps<PlanProjectRouteParams | Qto2dProjectRouteParams>,
RoutesDispatchProps,
RoutesStateProps,
AbilityAwareProps,
RoutingContextProps {}


class ModelCheckRoutesComponent extends React.Component<RoutesProps> {
  public render(): JSX.Element {
    const { urls } = this.props;

    return (
      <Switch>
        <PageLayout
          exact={true}
          metaTitle='Model Check'
          path={urls.project.modelCheck.index.path}
          backgroundColor='white'
          subject={Subject.ValidationModelCheck}
          component={ModelCheckPage}
          redirectUrl={urls.project.modelCheck.index.path}
          header={{
            context: HeaderContext.Project,
            backUrl: urls.listing.url(),
            title: this.props.currentProject ? this.props.currentProject.name : '',
            onEditTitle: this.props.ability.can(Operation.Update, Subject.Project)
              ? this.props.changeProjectName
              : null,
            showLeftMenu: true,
          }}
        />
        <PageLayout
          exact={true}
          metaTitle='Model Check'
          path={urls.project.modelCheck.view.path}
          backgroundColor='white'
          component={ModelCheckViewPage}
          subject={Subject.ValidationModelCheck}
          redirectUrl={urls.project.modelCheck.view.path}
          header={{
            ...projectInnerHeader,
            backUrl: urls.project.modelCheck.index.url({ projectId: this.props.match.params.projectId }),
            title: ValidationStepsNames.modelCheck,
          }}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  public componentWillUnmount(): void {
    this.props.dropState();
  }
}


function mapStateToProps(state: State): RoutesStateProps {
  const currentProject = state.projects.currentProject;
  return {
    currentProject,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): RoutesDispatchProps {
  return {
    changeProjectName: (name: string) => dispatch(ProjectsActions.updateProjectName(name)),
    dropState: () => dispatch(ModelCheckActions.dropState()),
  };
}

export const ModelCheckRoutes = withRoutingContext(withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ModelCheckRoutesComponent),
));
