import { ExcelFormulaHelper } from 'common/components/excel-table/utils/excel-formula-helper';
import { MoveCellRefMap } from '../interfaces';

export const collectCellsShiftMap =(
  map: MoveCellRefMap,
  rowIdBeforeMove: string,
  rowIdAfterMove: string,
  columnIdBeforeMove: string,
  columnIdAfterMove: string,
): void => {
  const cellIdBeforeMove = ExcelFormulaHelper.getCellLink(null, columnIdBeforeMove, rowIdBeforeMove);
  const cellIdAfterMove = ExcelFormulaHelper.getCellLink(null, columnIdAfterMove, rowIdAfterMove);
  map[cellIdAfterMove] = cellIdBeforeMove;
};
