import { Text, InputSelectStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px;
  ${Text} {
    padding-right: 15px;
  }
  ${InputSelectStyled.DropDownContainer} {
    width: 125px;
  }
`;

export const Styled = {
  Container,
};
