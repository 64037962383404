import styled from 'styled-components';

const FileBrowser = styled.div<{ browserWidth: number }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${(props => (`${props.browserWidth}px`))};
  border-right: 1px solid ${p => p.theme.color.background};
`;


const TableContainer = styled.div`
  position: relative;
  height: 100%;
`;


const ActiveFileBorder = styled.div<{ selectedFileIndex: number, rowHeight: number }>`
  position: absolute;
  top: ${p => p.selectedFileIndex * p.rowHeight}px;
  left: 0;
  height: ${p => p.rowHeight}px;
  width: 5px;
  background-color: ${p => p.theme.color.turquoise};
  border-radius: 1px 4px 4px 1px;
  transition: all ${p => p.theme.duration.s} linear;
`;

const FileContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px 20px;
`;

const AddFolderButton = styled.div`
  display: flex;
  gap: 15px;
  margin-left: auto;
`;

const DotMarkWrapper = styled.div<{ active: boolean }>`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 5px;
    width: 5px;
    right: -5px;
    top: -5px;
    background-color: ${p => p.active ? p.theme.color.turquoise : 'transparent'};
    border: 3px solid ${p => p.active ? p.theme.color.backgroundRush : 'transparent'};
    border-radius: 50%;
  }
`;

export const Styled = {
  FileBrowser,
  TableContainer,
  ActiveFileBorder,
  FileContainer,
  AddFolderButton,
  DotMarkWrapper,
};
