import styled from 'styled-components';
import { ShapeSwitcherStyled } from '../../shape-switcher';

const Container = styled.div`
  margin: 20px auto 0;
  padding: 0 20px;
  ${ShapeSwitcherStyled.Container} {
    flex-wrap: wrap;
  }
`;

export const Styled = {
  Container,
};
