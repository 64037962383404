import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './icon-button.scss';

export type ButtonSize = 'small' | 'medium' | 'large';

interface Props {
  icon?: React.ReactNode;
  size: ButtonSize;
  rounded?: boolean;
  hoverStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  disabled?: boolean;
  className?: string;
  hoverColor?: string;
  controlName?: string;
  onMouseOut?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  tooltip?: string;
  badgeActive?: boolean;
}

interface State {
  hovered: boolean;
}

export class IconButton extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    hoverStyle: null,
    hoverColor: undefined,
    disabled: false,
    rounded: true,
    style: {},
  };

  public state: State = {
    hovered: false,
  };

  public render(): React.ReactNode {
    const { hoverStyle, hoverColor, className, disabled, size, rounded, badgeActive } = this.props;

    let style: React.CSSProperties = this.props.style;

    if ((hoverStyle || hoverColor) && this.state.hovered) {
      style = { ...hoverStyle, backgroundColor: hoverColor };
    }

    const containerClassName = classNames(
      'kreo-icon-btn',
      `kreo-icon-btn--${size}`,
      className,
      {
        disabled,
        'kreo-icon-btn--rounded': rounded,
        'kreo-icon-btn--has-badge': badgeActive,
      },
    );

    return (
      <div
        className={containerClassName}
        onClick={this.onClick}
        data-control-name={this.props.controlName}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        style={style}
        title={this.props.tooltip}
      >
        {this.props.icon || this.props.children}
      </div>
    );
  }

  @autobind
  private onMouseOver(e: React.MouseEvent<HTMLDivElement>): void {
    if (!this.props.disabled) {
      if ((this.props.hoverStyle || this.props.hoverColor)) {
        this.setState({ hovered: true });
      }
      if (this.props.onMouseOver) {
        this.props.onMouseOver(e);
      }
    }
  }

  @autobind
  private onMouseOut(e: React.MouseEvent<HTMLDivElement>): void {
    if (!this.props.disabled) {
      if (this.props.hoverStyle || this.props.hoverColor) {
        this.setState({ hovered: false });
      }
      if (this.props.onMouseOut) {
        this.props.onMouseOut(e);
      }
    }
  }
  @autobind
  private onClick(e: React.MouseEvent<HTMLDivElement>): void {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick(e);
    }
  }
}
