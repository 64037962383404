import { ElementTooltip, IconButton, Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { RenderIf } from 'common/components/render-if';

interface Props {
  onClick: () => void;
}

export const CreateGroupButton: React.FC<Props> = ({ onClick }) => {
  return (
    <RenderIf condition={!!onClick}>
      <ElementTooltip
        text={'Add an empty group'}
        speed='l'
        position='top'
      >
        <IconButton Icon={Icons.NewGroup2d} width={20} height={20} onClick={onClick} />
      </ElementTooltip>
    </RenderIf>
  );
};
