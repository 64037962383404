import autobind from 'autobind-decorator';
import * as React from 'react';
import { ConstantFunctions } from 'common/constants/functions';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { mathUtils } from 'common/utils/math-utils';
import {
  FormulaInputAutoComplete,
  FormulaInputAutocompleteItemInfo,
} from './auto-complete';
import { FORMULA_FUNCTIONS, FORMULA_FUNCTION_ARGUMENTS_COUNT } from './constants';
import { FormulaPartTypes } from './enums';
import { FormulaInputValidationStatus } from './enums/formula-input-validation-status';
import {
  CaretPosition,
  CaretPositioningHelper,
  FormulaInputParsers,
  FormulaInputValidators,
  insertToFormula,
} from './helpers';
import { FormulaInputAutocompleteUtils } from './helpers/auto-complete';
import { FormulaFormIssues, FormulaPart } from './interfaces';
import { Styled } from './styled';


const nonBrakingSymbol = '\u200b';
const globalNonBrakingSymbol = new RegExp(nonBrakingSymbol, 'g');

export interface FormulaAPI {
  insertToFormula: (textToInsert: FormulaInputAutocompleteItemInfo) => void;
}

interface Props {
  readonly?: boolean;
  properties?: string[];
  value: string;
  onChangeValue: (value: string) => void;
  sendApi?: (api: FormulaAPI) => void;
  onChangeValidationStatus: (validationStatus: FormulaInputValidationStatus, problems?: FormulaFormIssues) => void;
}

interface State {
  isFocused: boolean;
  parts: FormulaPart[];
  text: string;
  isValid: boolean;
  position: number;
  autoCompleteResult: FormulaInputAutocompleteItemInfo[];
  searchQuery: string;
  autocompletePart: FormulaPart;
  autocompleteSelectionIndex: number;
}

export class FormulaInput extends React.PureComponent<Props, State> {
  private inputRef: React.RefObject<HTMLDivElement> = React.createRef();
  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private validateDeferedExecutor: DeferredExecutor = new DeferredExecutor(300);

  constructor(props: Props) {
    super(props);
    this.state = {
      isFocused: false,
      parts: [],
      text: '',
      isValid: false,
      position: 0,
      autoCompleteResult: null,
      searchQuery: null,
      autocompletePart: null,
      autocompleteSelectionIndex: 0,
    };
  }

  public  render(): React.ReactNode {
    return (
      <Styled.Container ref={this.containerRef} onBlur={this.onBlur} onFocus={this.onFocus} tabIndex={0}>
        {
          this.state.autoCompleteResult && this.state.autoCompleteResult.length ? (
            <FormulaInputAutoComplete
              searchText={this.state.searchQuery}
              targetElement={this.state.autocompletePart.span}
              autoCompleteResults={this.state.autoCompleteResult}
              selectedIndex={this.state.autocompleteSelectionIndex}
              onItemClick={this.insertAutocompleteItem}
              parentContainerRef={this.containerRef.current}
            />
          ) : null
        }
        <Styled.InputGroup
          onInput={this.onInput}
          contentEditable={!this.props.readonly}
          ref={this.inputRef}
          onKeyDown={this.onKeyDown}
          placeholder={`Start entering a formula's name, property or takeoff values`}
          value={this.props.value}
        />
      </Styled.Container>
    );
  }

  public componentDidMount(): void {
    if (this.props.value) {
      this.updateValue(this.props.value, this.inputRef.current);

      CaretPositioningHelper.restoreSelection(
        this.inputRef.current,
        { start: this.props.value.length, end: this.props.value.length },
      );
    }
    this.inputRef.current.focus();
    if (this.props.sendApi) {
      this.props.sendApi({
        insertToFormula: this.insertToFormula,
      });
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.value !== prevProps.value) {
      this.updateValue(this.props.value, this.inputRef.current);
    }
  }

  @autobind
  private onBlur(): void {
    this.setState({ isFocused: false });
  }

  @autobind
  private onFocus(): void {
    this.inputRef.current.focus();
    this.setState({ isFocused: true });
  }

  @autobind
  private onKeyDown(e: React.KeyboardEvent): void {
    const { autoCompleteResult, autocompleteSelectionIndex } = this.state;
    if (e.key === 'Escape') {
      this.inputRef.current.blur();
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      ConstantFunctions.stopEvent(e);
      if (autoCompleteResult?.length) {
        this.insertAutocompleteItem(autoCompleteResult[autocompleteSelectionIndex]);
      } else {
        this.inputRef.current.blur();
      }
    }

    if (e.key === 'ArrowUp' && autoCompleteResult) {
      ConstantFunctions.stopEvent(e);
      this.setAutocompleteSelection(autocompleteSelectionIndex - 1);
    }

    if (e.key === 'ArrowDown' && autoCompleteResult) {
      ConstantFunctions.stopEvent(e);
      this.setAutocompleteSelection(autocompleteSelectionIndex + 1);
    }
  }

  private setAutocompleteSelection(value: number): void {
    this.setState({ autocompleteSelectionIndex: mathUtils.clamp(value, 0, this.state.autoCompleteResult.length - 1) });
  }

  @autobind
  private onInput(e: React.SyntheticEvent<HTMLDivElement>): void {
    const value = this.getCurrentSpanValue();
    this.updateValue(value, e.currentTarget as HTMLElement);
  }

  private updateValue(value: string, target: HTMLElement, caretPositionToMove?: CaretPosition): void {
    const stateUpdate: Partial<State> = {
      text: value,
    };
    const formulaParts = FormulaInputParsers.splitToVariables(value);
    stateUpdate.parts = formulaParts;
    if (this.state.isFocused) {
      const savedCaretPosition = caretPositionToMove || CaretPositioningHelper.getCurrentSelection(target);
      this.updateBarElement(formulaParts, this.inputRef.current);
      CaretPositioningHelper.restoreSelection(target, savedCaretPosition);
      const searchQuery = FormulaInputAutocompleteUtils.getCurrentWord(savedCaretPosition.start, formulaParts);

      if (searchQuery) {
        const [query, part] = searchQuery;
        stateUpdate.autoCompleteResult = FormulaInputAutocompleteUtils.filterData(
          query,
          this.props.properties || [],
          FORMULA_FUNCTIONS,
          part.type === FormulaPartTypes.Property,
        );
        stateUpdate.searchQuery = query;
        stateUpdate.autocompletePart = part;
        stateUpdate.autocompleteSelectionIndex = 0;
      } else {
        stateUpdate.autoCompleteResult = null;
        stateUpdate.searchQuery = null;
        stateUpdate.autocompletePart = null;
      }
    } else {
      this.updateBarElement(formulaParts, this.inputRef.current);
    }
    this.validateDeferedExecutor.execute(this.validate);
    this.props.onChangeValidationStatus(FormulaInputValidationStatus.InProgress);
    if (!this.props.readonly) {
      this.props.onChangeValue(value);
    }
    this.setState(stateUpdate as State);
  }

  @autobind
  private updateBarElement(parts: FormulaPart[], element: HTMLElement): void {
    element.innerHTML = '';
    for (const { span } of parts) {
      element.appendChild(span);
    }
  }

  private getCurrentSpanValue(): string {
    const span = this.inputRef.current;
    const children = span.childNodes as any;
    const result = [];
    children.forEach((c: HTMLElement) => {
      result.push(c.textContent);
    });
    return result.join('').replace(globalNonBrakingSymbol, '');
  }


  @autobind
  private validate(): void {
    const propertyNames = new Set(this.props.properties);
    const {
      errors,
      warnings,
    } = FormulaInputValidators.validateFormula(this.state.parts, FORMULA_FUNCTION_ARGUMENTS_COUNT, propertyNames);

    if (errors.length) {
      this.props.onChangeValidationStatus(FormulaInputValidationStatus.Error, { errors, warnings });
    } else if (warnings.length) {
      this.props.onChangeValidationStatus(FormulaInputValidationStatus.Warning, { errors, warnings });
    } else {
      this.props.onChangeValidationStatus(FormulaInputValidationStatus.Ok);
    }
  }

  @autobind
  private insertAutocompleteItem(autocompleteItem: FormulaInputAutocompleteItemInfo): void {
    this.inputRef.current.focus();
    const { autocompletePart } = this.state;
    const { value } = this.props;
    const partText = FormulaInputAutocompleteUtils.buildNameFromResult(autocompleteItem);
    const newValue = FormulaInputAutocompleteUtils.pasteAutocompleteResult(autocompletePart, value, partText);
    const caretPositionToMove = autocompletePart.bounds[0] + partText.length;
    this.updateValue(newValue, this.inputRef.current, { start: caretPositionToMove, end: caretPositionToMove });
  }

  @autobind
  private insertToFormula(dataToInsert: FormulaInputAutocompleteItemInfo): void {
    this.inputRef.current.focus();
    this.setState({ isFocused: true }, () => {
      const { value } = this.props;
      const curretPosition = CaretPositioningHelper.getCurrentSelection(this.inputRef.current);
      const { newValue, newCaretPosition } = insertToFormula(dataToInsert, value, curretPosition);
      this.updateValue(newValue, this.inputRef.current, newCaretPosition);
    });
  }
}

