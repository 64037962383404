import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { BaseGanttLineBody } from '../../../../../../../../components/gantt-chart';
import { LineData } from '../../../../../../../../interfaces/gantt-chart';
import { actions } from '../../../../../../actions';
import { ChartLineName } from './components/chart-line-name';

interface ChartLineOwnProps {
  className?: string;
  data: LineData;
  left: number;
  width: number;
  nestingLevel: number;
  isIsolated: boolean;
  toggleIsolation: (line: LineData | null) => void;
  saveOuterRangeRef: (lineId: string, ref: HTMLDivElement) => void;
}

interface ChartLineDispatchProps {
  toggleExpandStatus: () => void;
}

interface ChartLineProps extends ChartLineOwnProps, ChartLineDispatchProps { }

class ChartLineComponent extends React.Component<ChartLineProps> {
  public shouldComponentUpdate(nextProps: ChartLineProps): boolean {
    return this.props.className !== nextProps.className ||
      this.props.left !== nextProps.left ||
      this.props.width !== nextProps.width ||
      this.props.nestingLevel !== nextProps.nestingLevel ||
      this.props.isIsolated !== nextProps.isIsolated ||
      this.props.data !== nextProps.data;
  }

  public render(): JSX.Element {
    const lineClassName = classNames(
      'chart-line',
      this.props.className,
      `nesting-level-${this.props.nestingLevel}`,
    );

    return (
      <div
        className={lineClassName}
        onClick={this.onLineClick}
      >
        <ChartLineName
          data={this.props.data}
          isIsolated={this.props.isIsolated}
          toggleIsolation={this.props.toggleIsolation}
        />
        <BaseGanttLineBody
          className='chart-line-body'
          lineId={this.props.data.lineId}
          left={this.props.left}
          width={this.props.width}
          offColor={this.props.data.offColor}
          saveOuterRangeRef={this.props.saveOuterRangeRef}
        />
      </div>
    );
  }

  @autobind
  private onLineClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    if (this.props.data.isExpandable) {
      this.props.toggleExpandStatus();
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: ChartLineOwnProps): ChartLineDispatchProps => {
  return {
    toggleExpandStatus: () => {
      dispatch(actions.toggleChartLineExpandStatus(ownProps.data.type, ownProps.data.entityId));
    },
  };
};

export const ChartLine = connect(null, mapDispatchToProps)(ChartLineComponent);
