import React, { useCallback, useState } from 'react';
import { StickerContentStyled } from '../sticker-content';

export interface DialogPosition {
  x: number;
  y: number;
  pinBottom: boolean;
  pinRight: boolean;
}

interface OpenStickerApi {
  position: DialogPosition;
  onOpen: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClose: () => void;
  onPositionChanged: () => void;
}

const BORDER_INDENT = 20;


function open(parentRef: HTMLDivElement): DialogPosition {
  const { left, top, bottom, right } = parentRef.getBoundingClientRect();
  const rightSpace = window.innerWidth - right - BORDER_INDENT;
  const bottomSpace = window.innerHeight - bottom - BORDER_INDENT;
  const topSpace = top - BORDER_INDENT;
  const pinRight = rightSpace < StickerContentStyled.WIDTH;
  const x = pinRight ? window.innerWidth - left : right;
  const pinBottom = topSpace > bottomSpace;
  const y = pinBottom ? window.innerHeight - top : bottom;
  return { x, y, pinBottom, pinRight };
}

export function useOpenSticker(
  forceOpen: boolean,
  containerRef: React.MutableRefObject<HTMLDivElement>,
  disableOpen: boolean,
  afterClose?: () => void,
): OpenStickerApi {
  const [position, setPosition] = useState<DialogPosition>(null);

  const onPositionChanged = useCallback(() => {
    if (forceOpen) {
      setPosition((open(containerRef.current)));
    }
  }, [containerRef, !!position, forceOpen]);

  const onOpen = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (disableOpen) {
      return;
    }
    setPosition(open(e.currentTarget));
  }, [disableOpen]);

  const onClose = useCallback(() => {
    setPosition(null);
    if (afterClose) {
      afterClose();
    }
  }, [afterClose]);
  return {
    onOpen,
    onClose,
    position,
    onPositionChanged,
  };
}
