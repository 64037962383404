import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MagicSearchActions } from 'common/components/drawings/actions/creators/magic-search';
import { ShortPointDescription } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';

interface SearchZoneApi {
  zone: ShortPointDescription[];
  clearZone: () => void;
  specifyZone: () => void;
}

export function useSearchingZone(): SearchZoneApi {
  const dispatch = useDispatch();
  const zone = useSelector<State, ShortPointDescription[]>((state) => state.drawings.magicSearch.searchArea);
  const clearZone = useCallback(() => {
    dispatch(MagicSearchActions.setSearchingZone(null));
  }, [dispatch]);
  const specifyZone = useCallback(() => {
    dispatch(MagicSearchActions.setSpecifyingZoneMode(true));
  }, [dispatch]);
  return {
    zone,
    clearZone,
    specifyZone,
  };
}
