import { IconButton, Icons, LinkComponent } from '@kreo/kreo-ui-components';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImageUploader } from 'common/components/image-uploader';
import { ImageUploadZone } from 'common/components/image-uploader/image-upload-zone';
import { TinyText } from 'common/components/text';
import { KreoColors } from 'common/enums/kreo-colors';
import { Mood } from 'common/enums/mood';
import { State } from 'common/interfaces/state';
import { CommonsApi } from '../../../../api/common';
import { AccountActions } from '../../../../units/account/actions/creators';
import { Styled } from './styled';


export const CompanyLogo: React.FC = () => {
  const dispatch = useDispatch();
  const [imageZone, setImageZone] = useState<ImageUploadZone>(null);
  const companyLogoTemporaryKey = useSelector<State, string>(state => state.account.companyLogoTemporaryKey);

  const setImage = useCallback((logoTemporaryKey: string) => {
    dispatch(AccountActions.setCompanyLogoTemporaryKey(logoTemporaryKey));
  }, [dispatch]);

  const removeAvatar = useCallback(() => {
    dispatch(AccountActions.setCompanyLogoTemporaryKey(undefined));
  }, [dispatch]);

  const onAddAvatar = useCallback((e: React.MouseEvent) => {
    imageZone.openFileDialogClick(e);
  }, [imageZone]);

  return (
    <Styled.Container>
      <Styled.Avatar>
        <ImageUploader
          previewUrl={CommonsApi.tempFileLink}
          defaultImagePreviewUrl={companyLogoTemporaryKey}
          deleteImage={removeAvatar}
          onLoadEnd={setImage}
          prefixName={'companyLogo'}
          forwardImageZoneRef={setImageZone}
        />
      </Styled.Avatar>
      <Styled.ButtonContainer>
        <TinyText>Company logo</TinyText>
        <Styled.Buttons>
          <LinkComponent
            text={'Upload image'}
            fontSize={14}
            onClick={onAddAvatar}
            Icon={Icons.Export}
            mood={Mood.Default}
          />
          <IconButton
            Icon={Icons.Delete}
            onClick={removeAvatar}
            width={20}
            height={20}
            defaultColor={companyLogoTemporaryKey && KreoColors.red}
            mood={!companyLogoTemporaryKey && Mood.Disabled}
          />
        </Styled.Buttons>
      </Styled.ButtonContainer>
    </Styled.Container>
  );
};
