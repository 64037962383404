import { ExcelFormulaHelper } from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { arrayUtils } from 'common/utils/array-utils';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { RefHandler } from './ref-handler';

export class CellsRef extends RefHandler {
  public removeRefs(groups: Record<string, string>, cellId: string): void {
    if (groups.fullCellId) {
      const cleanCellId = ExcelFormulaHelper.getCellLink(undefined, groups.columnId, groups.rowId);
      const id = ExcelFormulaHelper.getCellWithSheetId(groups.sheetId, cleanCellId);
      const cellRef = this.state[id];
      if (!cellRef) {
        return;
      }
      const index = cellRef.indexOf(cellId);
      if (index !== -1) {
        this.state[id].splice(index, 1);
      }
    }

    if (groups.startId && groups.endId) {
      this.getRangeRefCells(groups.cellRange).forEach(cellIdInRange => {
        const cellRefs = this.state[cellIdInRange];
        if (cellRefs) {
          const index = cellRefs.indexOf(cellId);
          if (index !== -1) {
            this.state[cellIdInRange].splice(index, 1);
          }
        }
      });
    }
  }

  public addRefs(groups: Record<string, string>, cellId: string): void {
    if (groups.fullCellId) {
      const id = ExcelFormulaHelper.getCellLink(groups.sheetId, groups.columnId, groups.rowId);
      arrayUtils.setKeyArrayValue(this.state, id, cellId);
    }

    if (groups.startId && groups.endId) {
      this.getRangeRefCells(groups.cellRange).forEach(cellIdInRange => {
        arrayUtils.setKeyArrayValue(this.state, cellIdInRange, cellId);
      });
    }
  }

  private getRangeRefCells(cellRange: string): string[] {
    const {
      sheetId,
      firstColIndex,
      lastColIndex,
      firstRowIndex,
      lastRowIndex,
    } = ExcelFormulaHelper.getCellRangeInfo(cellRange);
    const cellsIds = [];

    for (let i = firstRowIndex; i <= lastRowIndex; i++) {
      const rowId = ExcelTableRowIdentification.getRowIdFromIndex(i);
      for (let y = firstColIndex; y <= lastColIndex; y++) {
        const columnId = ExcelColumnHelper.indexToExcelColName(y);
        cellsIds.push(ExcelFormulaHelper.getCellLink(sheetId, columnId, rowId));
      }
    }

    return cellsIds.reverse();
  }
}
