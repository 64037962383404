import React, { useCallback, useMemo, useState } from 'react';

import { Mood } from 'common/enums/mood';
import { RenderIf } from '../render-if';
import { BlockQuestionnaire } from './block-questionnaire';
import { CloseButton } from './close-button';
import { ContinueButton } from './continue-button';
import { ProgressBar } from './progress-bar';
import { Styled } from './styled';


export interface ReplyType {
  reply: string;
}

export interface QuestionnaireContent {
  question: string;
  replies: ReplyType[];
  isChecked: boolean;
  isOther: boolean;
}

interface Props {
  content: QuestionnaireContent[];
  onClickContinue: (form: Record<string, string>) => void;
  closeButtonClick?: () => void;
  continueButtonTitle?: string;
  continueButtonMood?: Mood;
  showCloseButton?: boolean;
}

const isFirstQuestion = (index: number): boolean => {
  return index === 0;
};

const isCheckedReply = (question: QuestionnaireContent): boolean => {
  return question.isChecked;
};

const isAllElementsBeforeChecked = (elements: QuestionnaireContent[], currentIndex: number): boolean => {
  return elements.filter((el, index) => index < currentIndex && isCheckedReply(el)).length === currentIndex;
};

export const Questionnaire: React.FC<Props> = ({
  onClickContinue,
  closeButtonClick,
  content,
  continueButtonTitle,
  continueButtonMood,
  showCloseButton,
}) => {
  const [openBlocks, setOpenBlocks] = useState<number[]>([]);
  const [replies, setReplies] = useState<string[]>([]);
  const [questionContent, setQuestionContent] = useState<QuestionnaireContent[]>(content);

  const completed = useMemo(() => questionContent.filter((el) => el.isChecked), [questionContent]);
  const showContinueButton = questionContent.length === completed.length;

  const handleOnContinue = useCallback(() => {
    const newFormsData = completed.reduce((obj, el, index) => {
      obj[el.question] = replies[index];
      return obj;
    }, {});

    onClickContinue(newFormsData);
  }, [completed, onClickContinue, replies]);

  const handleChangeReply = useCallback((index) => {
    const newContent = [...questionContent];
    newContent[index] = { ...questionContent[index], isChecked: false };
    setQuestionContent(newContent);
  }, [questionContent]);

  const currentContent = questionContent.filter((el, index) => {
    return (
      isCheckedReply(el) ||
      isFirstQuestion(index) ||
      isAllElementsBeforeChecked(questionContent, index)
    );
  });

  return (
    <Styled.Container>
      <ProgressBar size={questionContent.length} completed={completed.length} />
      {questionContent.length ? (
        <Styled.Wrapper>
          {currentContent.map((el, index) => {
            const isOpen = openBlocks.includes(index);

            return (
              <BlockQuestionnaire
                key={index}
                index={index}
                isOpen={isOpen}
                isChecked={el.isChecked}
                question={el.question}
                answers={el.replies}
                isOther={el.isOther}
                openBlocks={openBlocks}
                replies={replies}
                questionContent={questionContent}
                handleChangeReply={handleChangeReply}
                setOpenBlocks={setOpenBlocks}
                setReplies={setReplies}
                setQuestionContent={setQuestionContent}
              />
            );
          })}
          <Styled.ButtonsContainer>
            <RenderIf condition={showCloseButton}>
              <CloseButton
                onClick={closeButtonClick}
              />
            </RenderIf>
            <RenderIf condition={showContinueButton}>
              <ContinueButton
                onClick={handleOnContinue}
                buttonTitle={continueButtonTitle}
                buttonMood={continueButtonMood}
              />
            </RenderIf>
          </Styled.ButtonsContainer>
        </Styled.Wrapper>
      ) : null}
    </Styled.Container>
  );
};
