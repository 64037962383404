import { ElementTooltip, IconButton, Icons, MenuItem, SmallMenuList, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { ProjectsFolder } from '../../../projects/interfaces/projects-folder';
import { Styled } from './styled';

interface Props {
  folder: ProjectsFolder;
  onClick: (folder: ProjectsFolder) => void;
  editFolder: (folder: ProjectsFolder) => void;
  deleteFolder: (id: number) => void;
  moveFolder: (folder: ProjectsFolder) => void;
}

interface ComponentState {
  isOpen: boolean;
  menuPositionX: number;
  menuPositionY: number;
}

export class FolderListItem extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      menuPositionX: 0,
      menuPositionY: 0,
    };
  }

  public render(): React.ReactNode {
    return (
      <>
      <Styled.Folder onClick={this.onClick}>
        <Icons.Group_2 height={20} width={20}/>
        <ElementTooltip
          text={this.props.folder.name}
          position='top'
          speed='l'
          disabled={this.props.folder.name.length < 20}
          wordBreakAll={true}
          tooltipOverflow='hidden'
        >
          <Text fontSize={14} withEllipsis={true}>{this.props.folder.name}</Text>
        </ElementTooltip>
        <Styled.Button>
          <IconButton
            Icon={Icons.Menu}
            width={20}
            height={20}
            onClick={this.openMenu}
          />
        </Styled.Button>
      </Styled.Folder>
      {this.state.isOpen &&
        (<Styled.MenuWrapper left={this.state.menuPositionX} top={this.state.menuPositionY}>
          <SmallMenuList
            emergencePlace={'right top'}
            onExit={this.closeMenu}
          >
            <MenuItem onClick={this.renameFolder} text='Edit' Icon={Icons.Edit2D} size={'m'}/>
            <MenuItem onClick={this.moveFolder} text='Move to' Icon={Icons.MoveToGroup_1} size={'m'}/>
            <MenuItem onClick={this.deleteFolder} text='Delete' Icon={Icons.Delete} size={'m'}/>
          </SmallMenuList>
        </Styled.MenuWrapper>)
      }
      </>
    );
  }

  @autobind
  private onClick(): void {
    this.props.onClick(this.props.folder);
  }

  @autobind
  private openMenu(e: React.MouseEvent<HTMLButtonElement>): void {
    ConstantFunctions.stopEvent(e);
    const buttonElem = e.currentTarget;
    this.setState({
      isOpen: true,
      menuPositionX: buttonElem.offsetLeft,
      menuPositionY: buttonElem.offsetTop,
    });
  }

  @autobind
  private closeMenu(): void {
    this.setState({ isOpen: false });
  }

  @autobind
  private deleteFolder(): void {
    this.props.deleteFolder(this.props.folder.id);
  }

  @autobind
  private renameFolder(): void {
    this.props.editFolder(this.props.folder);
  }

  @autobind
  private moveFolder(): void {
    this.props.moveFolder(this.props.folder);
  }
}
