import { arrayUtils } from 'common/utils/array-utils';
import { Hotkey } from './hotkey';

function stringifyHotkeysList(hotkeys: Hotkey[]): string {
  return arrayUtils.uniq(hotkeys.map(x => x.shortcutToShow)).join(' or ');
}

export const HotkeysUtils = {
  stringifyHotkeysList,
};
