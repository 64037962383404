import styled from 'styled-components';

const FieldLabel = styled.div`
  margin-bottom: 2px;
`;

const ButtonWrapper = styled.div`
  margin-top: 2px;
  display: flex;
  justify-content: space-between;

  > div {
    > p {
      font-size: 14px;
      padding-top: 1px;
    }
  }
`;

const TreePanelContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  .ag-header {
    display: none;
  }
  .ag-root-wrapper-body.ag-layout-normal {
    padding-bottom: 35px;
  }

  .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper {
    margin-left: -5px;
  }

  .ag-row.ag-row-hover {
    background-color: ${p => p.theme.color.pale};
  }

  .ag-cell-inline-editing input {
    box-sizing: border-box;
    border-radius: 0 !important;
  }

  .ag-row-level-1.ag-row-group {
    .ag-cell-inline-editing {
      width: 125px !important;
    }
    > div:nth-child(2) {
      width: 120px !important;
    }
  }
  .ag-row-level-2.ag-row-group {
    .ag-cell-inline-editing {
      width: 120px !important;
    }
    > div:nth-child(2) {
      width: 115px !important;
    }
  }
  .ag-row-level-3.ag-row-group {
    .ag-cell-inline-editing {
      width: 110px !important;
    }
    > div:nth-child(2) {
      width: 105px !important;
    }
  }
  .ag-row-level-4.ag-row-group {
    .ag-cell-inline-editing {
      width: 105px !important;
    }
    > div:nth-child(2) {
      width: 100px !important;
    }
  }
  .ag-row-level-5.ag-row-group {
    .ag-cell-inline-editing {
      width: 95px !important;
    }
    > div:nth-child(2) {
      width: 90px !important;
    }
  }
  .ag-row-level-6.ag-row-group {
    .ag-cell-inline-editing {
      width: 90px !important;
    }
    > div:nth-child(2) {
      width: 85px !important;
    }
  }
  .ag-row-level-7.ag-row-group {
    .ag-cell-inline-editing {
      width: 85px !important;
    }
    > div:nth-child(2) {
      width: 80px !important;
    }
  }
  .ag-row-level-8.ag-row-group {
    .ag-cell-inline-editing {
      width: 80px !important;
    }
    > div:nth-child(2) {
      width: 75px !important;
    }
  }

  .code-cell-not-edit {
    height: 100%;
    display: flex;
    align-items: center;
    background: ${p => p.theme.color.background};
    color: ${p => p.theme.color.gray};
    padding: 0 3px;
    line-height: 22px;
    max-width: 75%;
  }

  .code-cell-edit {
    height: 100%;
    width: 100%;
    border: 0;
  }

  .input-cell-edit-value {
    height: 100%;
    width: 100%;
    color: ${p => p.theme.color.mainFont};
    background: ${p => p.theme.color.background};
    border: 0;
    padding-bottom: 1px;
    font-size: 14px;

    :focus {
      outline: none;
    }
  }

  .ag-row-focus.ag-row-inline-editing {
    .ag-cell-inline-editing {
      padding-left: 0;
      border-color: ${p => p.theme.color.background} !important;
    }

    > div:nth-child(2):has(.code-cell-edit) {
      top: -3px;
    }
    > div:nth-child(3):has(.name-textarea-cell-editor) {
      top: 2px;
    }

    > div:nth-child(3) {
      width: 200px !important;
      height: calc(100% - 4px) !important;

      .name-textarea-cell-editor {
        overflow: hidden;
        height: inherit;
        width: inherit;
        resize: none;
        background: ${p => p.theme.color.background};
        line-height: 22px;
        font-size: 14px;
        color: ${p => p.theme.color.mainFont};
        font-family: 'ProximaSoft', sans-serif;
        font-weight: 400;
        border: none;

        :focus {
          outline: none;
        }
      }
    }
  }

  .ag-theme-balham-dark .ag-cell {
    padding-left: 4px;
    padding-right: 0px;
  }

  div[role='row'] {
    display: inline-flex;
    align-items: baseline !important;

    > div:first-child {
      position: relative;
      width: auto !important;

      > div {
        align-items: flex-start;
        padding-top: 3px;
      }
    }
    > div:nth-child(2) {
      position: relative;
      left: auto !important;
      top: -4px;
      height: calc(100% - 6px) !important;
      max-height: 28px;
      display: inline-flex;
      align-items: flex-start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0 !important;
    }
    > div:nth-child(3) {
      top: 0px;
      align-items: center;
      left: 196px !important;
      height: 100% !important;
      width: 200px !important;
    }
    > div:nth-child(4) {
      width: 30px !important;
      left: 404px !important;
      top: 0;
      padding-left: 0 !important;
    }
  }

  .ag-group-expanded {
    margin-right: 0 !important;
  }
  .ag-group-contracted {
    margin-right: 0 !important;
  }

  .ag-ltr .ag-row-drag {
    margin-right: 4px !important;
  }

  .ag-group-checkbox {
    position: absolute;
    left: 25px;
    .ag-selection-checkbox {
      margin-right: 0;
    }
  }

  .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 15px !important;
  }
  .ag-ltr .ag-row-group-indent-0 {
    padding-left: 20px;
  }
  .ag-ltr .ag-row-group-indent-1 {
    padding-left: 28px;
  }
  .ag-ltr .ag-row-group-indent-2 {
    padding-left: 35px;
  }
  .ag-ltr .ag-row-group-indent-3 {
    padding-left: 42px;
  }
  .ag-ltr .ag-row-group-indent-4 {
    padding-left: 49px;
  }
  .ag-ltr .ag-row-group-indent-5 {
    padding-left: 56px;
  }
  .ag-ltr .ag-row-group-indent-6 {
    padding-left: 63px;
  }
  .ag-ltr .ag-row-group-indent-7 {
    padding-left: 70px;
  }
  .ag-ltr .ag-row-group-indent-8 {
    padding-left: 74px;
  }
  .ag-ltr .ag-row-group-indent-9 {
    padding-left: 81px;
  }
  .ag-ltr .ag-row-group-indent-10 {
    padding-left: 88px;
  }
  .ag-ltr .ag-row-group-indent-11 {
    padding-left: 95px;
  }
  .ag-ltr .ag-row-group-indent-12 {
    padding-left: 102px;
  }
  .ag-ltr .ag-row-group-indent-13 {
    padding-left: 109px;
  }
  .ag-ltr .ag-row-group-indent-14 {
    padding-left: 116px;
  }
  .ag-ltr .ag-row-group-indent-15 {
    padding-left: 123px;
  }

  .ag-cell-wrap-text.ag-cell-not-inline-editing {
    min-height: 100%;
  }

  .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value.ag-cell-wrap-text {
    div {
      > button {
        height: 20px;
        width: 20px;
      }
    }
  }
  .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value.ag-cell-wrap-text.ag-cell-range-right {
    width: 30px !important;
    left: 404px !important;
  }

  div::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  div::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }

  div::-webkit-scrollbar-track {
    background: ${p => p.theme.color.background};
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Styled = {
  FieldLabel,
  ButtonWrapper,
  TreePanelContainer,
  FormContainer,
};
