import * as t from 'io-ts';

import { QtoTemplateColumnC } from './quantity-take-off-template-column';

export const QtoTemplateColumnsModelC = t.type({
  firstLevelColumns: t.array(t.string),
  columns: t.dictionary(t.string, QtoTemplateColumnC),
});


export type QtoTemplateColumnsModel = t.TypeOf<typeof QtoTemplateColumnsModelC>;
