import { SagaIterator } from 'redux-saga';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { WizzardToolsActions } from '../actions/creators';
import { DrawingsActions } from '../actions/creators/common';
import { WizzardToolsActionTypes } from '../actions/types/wizzard-tools';
import { DrawingsApi } from '../api/drawings-api';
import { WizzardErrorTypes } from '../enums';
import { WizzardStatus } from '../enums/dropper-state';
import { DrawingsSelectAggregationGroup, ShortPointDescription, WizzardToolsState } from '../interfaces';
import { PdfGeometry, PdfGeometryStatus } from '../interfaces/api-responses/pdf-geometry-response';
import { CursorHintType } from '../layout-components/drawings-cursor-hint';
import { WizzardUtils } from '../utils';

function* setSelectionArea(): SagaIterator {
  try {
    yield put(DrawingsActions.setCursorHint(null));
    const { drawingId, pdfId } = yield selectWrapper((x) => x.drawings.currentDrawingInfo);
    const wizzardTools: WizzardToolsState = yield selectWrapper((x) => x.drawings.wizzardTools);
    const rect = WizzardUtils.getPdfRect(wizzardTools.finderSelectionArea);
    if (rect) {
      const { status, geometries } = yield call(DrawingsApi.processPdfSelect, pdfId, drawingId, rect);
      if (status === PdfGeometryStatus.Succcess) {
        yield put(WizzardToolsActions.setSelectedGeometries(geometries));
      } else {
        if (status === PdfGeometryStatus.Empty) {
          yield put(DrawingsActions.setCursorHint(CursorHintType.NoGeometriesInArea));
        } else if (status === PdfGeometryStatus.ReachedLimit) {
          yield put(DrawingsActions.setCursorHint(CursorHintType.ReachedLimitInArea));
        }
        yield delay(3000);
        yield put(DrawingsActions.setCursorHint(null));
        yield put(WizzardToolsActions.setSelectionArea(null));
      }
    }
  } catch (e) {
    console.error('wizzard setSelectionArea error', e);
    yield put(WizzardToolsActions.setSelectionArea(null));
    yield put(DrawingsActions.setCursorHint(CursorHintType.SelectionAreaCrashed));
    yield put(DrawingsActions.setCursorHint(null));
    yield put(WizzardToolsActions.setSelectionArea(null));
  }
}

function* runFinder(): SagaIterator {
  try {
    const { drawingId, pdfId } = yield selectWrapper((x) => x.drawings.currentDrawingInfo);
    const wizzardTools: WizzardToolsState = yield selectWrapper((x) => x.drawings.wizzardTools);
    const { isCount, finderSelectedGeometries } = wizzardTools;
    if (wizzardTools.finderSelectedGeometries?.length) {
      yield put(WizzardToolsActions.setWizzardState(WizzardStatus.Loading));
      const { status, geometries } = yield call(DrawingsApi.findPdfGeometries, pdfId, drawingId, isCount, {
        geometryIds: finderSelectedGeometries.map((x) => x.id),
        returnInitial: true,
        allowFlipping: wizzardTools.allowFlipping,
        allowRotation: wizzardTools.allowRotation,
        findText: wizzardTools.findText,
        workingArea: WizzardUtils.getPdfRect(wizzardTools.searchArea),
      });
      if (
        yield selectWrapper((x) => {
          const currentToolsInfo = x.drawings.wizzardTools;
          return currentToolsInfo.wizzardState.status !== WizzardStatus.Loading ||
            finderSelectedGeometries !== currentToolsInfo.finderSelectedGeometries ||
            isCount !== currentToolsInfo.isCount;
        })
      ) {
        return;
      }
      if (status === PdfGeometryStatus.Succcess) {
        (geometries as PdfGeometry[]).forEach((x, index) => x.id = index);
        yield put(WizzardToolsActions.setResult(geometries));
        yield put(WizzardToolsActions.setWizzardState(WizzardStatus.Preview));
      } else {
        yield put(WizzardToolsActions.setError(WizzardErrorTypes.Crash));
        yield delay(3000);
        yield put(WizzardToolsActions.setError(null));
        yield put(WizzardToolsActions.setWizzardState(WizzardStatus.Start));
      }
    }
  } catch (e) {
    console.error('wizzard runFinder error', e);
    yield put(WizzardToolsActions.setWizzardState(WizzardStatus.Start));
    yield put(WizzardToolsActions.setError(WizzardErrorTypes.Crash));
    yield delay(3000);
    yield put(WizzardToolsActions.setError(null));
  }
}

function* getPdfStatistic({ payload: drawingId }: ActionWith<string>): SagaIterator {
  try {
    const drawingInfo = yield selectWrapper((x) => x.drawings.drawingsInfo[drawingId]);
    const currentStatistic = yield selectWrapper((x) => x.drawings.drawingStatistic[drawingId]);
    if (!drawingInfo || currentStatistic) {
      return;
    }
    const { pdfId } = drawingInfo;
    const statistic = yield call(DrawingsApi.readPdf, pdfId, drawingId);
    yield put(WizzardToolsActions.setPdfStatistic(drawingId, statistic));
  } catch (e) {
    console.error('wizzard runFinder error', e);
  }
}

function* runDrop({ payload: position }: ActionWith<ShortPointDescription>): SagaIterator {
  try {
    const { drawingId, pdfId } = yield selectWrapper((x) => x.drawings.currentDrawingInfo);
    const wizzardTools: WizzardToolsState = yield selectWrapper((x) => x.drawings.wizzardTools);
    const { dropperInstanceType, dropperSameGeometry, allowFlipping, allowRotation } = wizzardTools;
    yield put(WizzardToolsActions.setWizzardState(WizzardStatus.Loading));
    yield put(WizzardToolsActions.setResult(null));
    yield put(DrawingsActions.setCursorHint(null));
    const { status, geometries } = yield call(DrawingsApi.processPdfClick, pdfId, drawingId, {
      position,
      sameStyle: true,
      filled: dropperInstanceType === DrawingsSelectAggregationGroup.Area,
      sameGeometry: dropperSameGeometry,
      allowRotation,
      allowFlipping,
      findText: false,
      workingArea: WizzardUtils.getPdfRect(wizzardTools.searchArea),
    });
    if (status === PdfGeometryStatus.Succcess) {
      yield put(WizzardToolsActions.setResult(geometries));
      yield put(WizzardToolsActions.setWizzardState(WizzardStatus.Preview));
    } else {
      yield put(WizzardToolsActions.setWizzardState(WizzardStatus.Start));
      if (status === PdfGeometryStatus.Empty) {
        yield put(DrawingsActions.setCursorHint(CursorHintType.NoGeometryFoundByClick));
      } else {
        yield put(WizzardToolsActions.setError(WizzardErrorTypes.Crash));
      }
      yield delay(3000);
      yield put(DrawingsActions.setCursorHint(null));
      yield put(WizzardToolsActions.setError(null));
    }
  } catch (e) {
    console.error('wizzard runDrop error', e);
    yield put(WizzardToolsActions.setWizzardState(WizzardStatus.Start));
    yield put(WizzardToolsActions.setError(WizzardErrorTypes.Crash));
    yield delay(3000);
    yield put(WizzardToolsActions.setError(null));
  }
}

export function* wizzardSaga(): SagaIterator {
  yield takeLatest(WizzardToolsActionTypes.SET_SELECTION_AREA, setSelectionArea);
  yield takeLatest(WizzardToolsActionTypes.RUN_FINDER, runFinder);
  yield takeLatest(WizzardToolsActionTypes.GET_PDF_STATISTIC, getPdfStatistic);
  yield takeLatest(WizzardToolsActionTypes.RUN_DROPPER, runDrop);
}
