import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './filter-select.scss';

import { NumberDictionary } from 'common/interfaces/dictionary';
import { SortingDirection } from 'common/interfaces/sorting-direction';
import { State as ReduxState } from 'common/interfaces/state';
import { MaterialSelect } from 'common/UIKit';
import { KreoIconFilterNormal } from 'common/UIKit/icons';
import { StringUtils } from 'common/utils/string-utils';
import { ActivityActions } from '../../actions';
import { FilterType } from '../../filter-type';
import { GroupedUserActivity, SimpleOption, Sorting } from '../../interfaces';
import { UserActivityUtil } from '../../user-activity-util';
import { FilterSelectOption } from '../filter-select-option';
import { SortingIcon } from '../sorting-icon';

interface ReduxProps {
  filter: NumberDictionary<SimpleOption[]>;
  activities: GroupedUserActivity[];
  sorting: Sorting;
}

interface ReduxActions {
  deleteFilterItem: (type: FilterType, optionId: React.ReactText) => void;
  addFilterItem: (type: FilterType, option: SimpleOption) => void;
  setSorting: (type: FilterType, direction: SortingDirection) => void;
}

interface Props extends ReduxProps, ReduxActions {
  type: FilterType;
}

interface State {
  options: SimpleOption[];
}

class FilterSelectComponent extends React.Component<Props, State> {
  private defaultOption: SimpleOption = {
    id: null,
    name: `All ${FilterType[this.props.type]}${this.props.type !== FilterType.People ? 's' : ''}`,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  public componentDidMount(): void {
    const { type, activities } = this.props;
    const options = UserActivityUtil.getFilterOptions(type, activities);
    options.unshift(this.defaultOption);
    this.setState({ options });
  }

  public render(): JSX.Element {
    const { filter, type, sorting } = this.props;
    const { options } = this.state;
    const filterValue = filter[type] ? filter[type].map(x => x.id) : [];

    return (
      <div className='activity-filter-select'>
        <div
          className={classNames('activity-filter-select__filter', {
            selected: filterValue.length > 0,
          })}
        >
          <MaterialSelect
            autoWidth={true}
            value={filterValue}
            className='activity-filter-select__select'
            dropdownClassName='activity-filter-select__select-paper'
            multiple={true}
            displayUnderline={false}
            selectionRenderer={this.selectionRenderer}
            displayEmpty={true}
            iconComponent={<KreoIconFilterNormal />}
            controlName={`select-${StringUtils.titleCaseToSnakeCase(this.defaultOption.name)}`}
          >
            {options.map(x => (
              <FilterSelectOption
                key={x.id}
                option={x}
                type={type}
                filterValue={filterValue}
                deleteFilterItem={this.props.deleteFilterItem}
                addFilterItem={this.props.addFilterItem}
                value={x.id}
              />
            ))}
          </MaterialSelect>
        </div>
        <SortingIcon type={type} sorting={sorting} setSorting={this.props.setSorting}/>
      </div>
    );
  }

  @autobind
  private selectionRenderer(value: React.ReactText[]): React.ReactNode {
    const { type } = this.props;

    if (!value || value.length === 0) {
      return this.defaultOption.name;
    }

    return `${value.length} ${FilterType[type]}${
      value.length > 1 && type !== FilterType.People ? 's' : ''
    }`;
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    filter: state.userActivities.filter,
    activities: state.userActivities.activities,
    sorting: state.userActivities.sorting,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    deleteFilterItem: (type, optionId) => {
      dispatch(ActivityActions.deleteFilterItem(type, optionId));
    },
    addFilterItem: (type, option) => {
      dispatch(ActivityActions.addFilterItem(type, option));
    },
    setSorting: (type, direction) => {
      dispatch(ActivityActions.setSorting(type, direction));
    },
  };
};

export const FilterSelect = connect(mapStateToProps, mapDispatchToProps)(FilterSelectComponent);
