import {
  DrawingsInstanceMeasure,
  DrawingsMeasureUtils,
} from 'common/components/drawings';
import { SegmentLength } from './segment-length-helper';

export function getMeasureKey(id: string, key: string): string {
  return `${key}(${id})`;
}

export function getSegmentMeasure(points: string[], drawingsInstanceId: string): string {
  return SegmentLength.getMeasureFunction(points, drawingsInstanceId);
}

export function getMeasure({ type, measures, id }: DrawingsInstanceMeasure, measureType?: string): string {
  if (Array.isArray(id)) {
    return null;
  }
  if (DrawingsMeasureUtils.isLengthMeasure(type, measures)) {
    return getMeasureKey(id, measureType || 'length');
  } else if (DrawingsMeasureUtils.isCountMeasure(type, measures)) {
    return getMeasureKey(id, measureType || 'count');
  } else if (DrawingsMeasureUtils.isPolygonMeasure(type, measures)) {
    return getMeasureKey(id, measureType || 'area');
  } else if (DrawingsMeasureUtils.isRectangleMeasure(type, measures)) {
    return getMeasureKey(id, measureType || 'area');
  } else {
    return null;
  }
}
