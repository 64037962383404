import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { WizzardToolsActions } from '../../actions/creators';

export function useVisible(previewId: number): [
  visible: boolean,
  toogle: (e: React.MouseEvent<HTMLDivElement>) => void,
] {
  const visible = useSelector<State, boolean>((x) => !x.drawings.wizzardTools.finderResultToHide[previewId]);
  const dispatch = useDispatch();

  const toggle = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    ConstantFunctions.stopEvent(e);
    dispatch(WizzardToolsActions.changeResultHideStatus(previewId, visible));
  }, [previewId, visible]);

  return [visible, toggle];
}
