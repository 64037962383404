import styled from 'styled-components';

import { KreoColors } from 'common/enums/kreo-colors';

interface ContainerProps {
  showSubvariants: boolean;
  themeStyle: boolean;
  isHighlighted: boolean;
  isSelected: boolean;
  hasSubvariants: boolean;
}

const Container = styled.div<ContainerProps>`
  box-sizing: border-box;
  min-width: 240px;
  white-space: nowrap;
  padding: 8px 40px 8px 20px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  min-height: 32px;
  color: #2D4969;
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: ${p => p.hasSubvariants ? 'default' : 'pointer'};

  :hover {
    background-color: ${p => (p.showSubvariants || p.isHighlighted) &&
      p.themeStyle ? p.theme.color.turquoise : KreoColors.blue1};
    .multi-level-select-option__icon-selected {
      opacity: ${p => p.isSelected && 0};
    }
    .database-activity-variant-select-inner__info {
      opacity: 1;
    }
  }

  background-color: ${p => p.isSelected && (p.themeStyle ? p.theme.color.turquoise : KreoColors.blue2)};

  b {
    font-weight: inherit;
    background-color: rgba(#4CA0FF, 0.2);
  }

  .multi-level-select-option__content {
    max-width: 350px;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .multi-level-select-option__icon-selected {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: ${p => p.theme.duration.m} ${p => p.theme.function.oq};
  }

  ${p => p.themeStyle && `
    padding: 0;
    background-color: transparent !important;
    &:not(:first-child) {
      border-top: 1px solid ${p.theme.color.background};
    }
    p {
      color: ${p.showSubvariants && p.theme.color.turquoise};
    }
    svg {
      fill: ${p.showSubvariants && p.theme.color.turquoise};
    }
  `}

  ${p => p.themeStyle && p.hasSubvariants && `
    &:hover::before {
      border-color: ${p.theme.color.turquoise} !important;
    }
    ::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%) rotate(-135deg);
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color: ${p.theme.color.gray};
      border-top: 0;
      border-right: 0;
      z-index: 1;
    }
  `}
`;


export const Styled = {
  Container,
};
