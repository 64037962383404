import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid ${p => p.theme.color.background};
  margin: 5px 10px;
  border-radius: 10px;
  padding: 9px;
  cursor: pointer;
  &:hover {
    border-color: ${p => p.theme.color.turquoise};
  }
`;

const Text = styled.span`
  color: ${p => p.theme.color.gray};
  font-size: 14px;
`;

const Highlighted = styled.span`
  color: ${p => p.theme.color.mainFont};
`;

export const Styled = {
  Container,
  Text,
  Highlighted,
};
