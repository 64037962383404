import React, { useCallback, useMemo, useRef } from 'react';

import { useNewInstancesSetting } from 'common/components/drawings/hooks';
import { DrawingsGeometryGroup } from 'common/components/drawings/interfaces';
import { Property } from 'common/components/property';
import { usePersistedStorageValue } from 'persisted-storage/hooks';
import { InputApiWrapper } from './input-api-wrapper';

interface Props {
  selectedGroup: DrawingsGeometryGroup;
}

const NamePropertyComponent: React.FC<Props> = ({
  selectedGroup,
}) => {
  const [ name, onChange ] = useNewInstancesSetting('name');
  const originNameRef = useRef<string>();
  const nameAsGroup = usePersistedStorageValue('drawingUseGroupNameForNewGeometry');
  const useGroupName = nameAsGroup && !!selectedGroup;

  const currentName = useMemo(() => {
    return useGroupName ? selectedGroup?.name : name;
  }, [name, selectedGroup, useGroupName]);

  const handleBlur = useCallback((value: string) => {
    if (!value) {
      onChange(originNameRef.current);
    }
  }, [onChange]);

  const handleFocus = useCallback(() => {
    originNameRef.current = currentName;
  }, [currentName]);

  return (
    <Property title='Name'>
      <InputApiWrapper
        value={currentName}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={useGroupName}
      />
    </Property>
  );
};

export const NameProperty = React.memo(NamePropertyComponent);
