import styled from 'styled-components';

import { SidePanelNavigationStyled } from 'common/components/side-panel-navigation';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: min-content min-content 1fr;
  grid-template-rows: 100%;
  background-color: ${props => props.theme.color.background};
  overflow: auto;

  p {
    font-family: 'ProximaSoft', sans-serif;
  }

  > div:nth-child(1) {
    overflow-y: auto;
    overflow-x: hidden;
  }

  ${SidePanelNavigationStyled.Logo} {
    width: 200px;
    z-index: 1;

    > svg {
      height: 40px;

      > path {
        fill: ${p => p.theme.color.mainFont};
      }
    }
  }

  @keyframes slide-top {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-240px);
    }
  }

.slide-top {
    animation: slide-top 0.5s ease;
    transition-delay: 0.1s;
    position: absolute;
    top: -500px;
    left: 290px;
  }
`;

export const Styled = {
  Container,
};
