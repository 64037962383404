import { HoverMenu, TinyText } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { MeasureValue } from '../../drawings-annotation-legend-item';
import { Styled } from '../styled';
import { MeasuresPopup } from './measures-popup';

interface Props {
  count: number;
  measureValues?: MeasureValue[];
  isOpenFilterPages?: boolean;
}

const CountComponent: React.FC<Props> = ({ count, measureValues, isOpenFilterPages }) => {
  const renderValues = useCallback(
    () => {
      return <MeasuresPopup measureValues={measureValues}/>;
    },
    [measureValues],
  );

  return (
    <HoverMenu
      position='top'
      shift={10}
      disabled={!measureValues?.length || isOpenFilterPages}
      MenuComponent={renderValues}
    >
      <Styled.Count>
        <TinyText fontWeight={700}>
          {count}
        </TinyText>
      </Styled.Count>
    </HoverMenu>
  );
};

export const Count = React.memo(CountComponent);
