import autobind from 'autobind-decorator';
import React from 'react';

import { FormNumberInputField } from 'common/components/form-fields';
import { roundNumeric } from 'unit-2d-database/helpers';
import { PropertyFormData } from '../interfaces';
import { GroupsDropdown, UnitInput, PropertyNameInput } from './fields';
import { validatePropertyNames, validatePropertyUnit, validatePropertyValue } from './helper';

interface Props {
  property: PropertyFormData;
  groups: string[];
  units: string[];
  onChange: (data: PropertyFormData, isValid: boolean) => void;
}

export class NumberForm extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return (
      <>
        <PropertyNameInput
          value={this.props.property.name}
          onChange={this.triggerChange}
        />
        <UnitInput
          selectedUnit={this.props.property.unit}
          onChange={this.triggerChange}
          extraUnits={this.props.units}
        />
        <GroupsDropdown
          groups={this.props.groups}
          value={this.props.property.groupName}
          onChange={this.triggerChange}
        />
        <FormNumberInputField
          label='Default value'
          input={{
            value: this.getValue(),
            onChange: this.changeValue,
          }}
        />
      </>
    );
  }

  private getValue(): string {
    const { value } = this.props.property;
    return `${value && typeof value === 'number' ? roundNumeric(value) : value}`;
  }

  @autobind
  private changeValue(value: number): void {
    this.triggerChange({ value });
  }

  @autobind
  private triggerChange(updates: Record<string, string | number>): void {
    const updatedProperty = { ...this.props.property, ...updates };
    const isValid = validatePropertyNames(updatedProperty)
      && validatePropertyUnit(updatedProperty)
      && validatePropertyValue(updatedProperty);

    this.props.onChange(updatedProperty, isValid);
  }
}
