import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`;

const NumberContainer = styled.div<{ isValid: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${p => p.isValid ? p.theme.color.background : p.theme.color.red};

  ${Text} {
    font-size: 18px;
  }
`;

const StatusIconContainer = styled.div<{ isValid: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.color[p.isValid ? 'green' : 'red']};
  border: 1px solid ${p => p.theme.color.background};
  z-index: 1;

  svg {
    height: 10px;
    fill: ${p => p.theme.color.white};
  }
`;

export const Styled = {
  Container,
  NumberContainer,
  StatusIconContainer,
};
