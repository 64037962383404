import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 170px;
  justify-content: space-between;
  height: 100%;
`;

const SliderContainer = styled.div`
  > div {
    width: 110px;
  }
`;

export const Styled = {
  Container,
  SliderContainer,
};
