import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './tooltip-icon.scss';

import { KreoIconInfoBigColor } from 'common/UIKit';
import { getOrCreateRoot } from 'common/UIKit/dialogs/';
import { ModelCheckPriority } from '../../../enums/mode-check-priority';
import { ModelCheckType } from '../../../enums/model-check-type';
import * as errorTypes from './error-types.json';
import { TipPositions } from './tip-positions';

interface ErrorType {
  name: string;
  description: string;
}

const errorDescriptions: Record<string, ErrorType> = errorTypes.default;

interface Props {
  type?: ModelCheckPriority;
  modelCheckType?: ModelCheckType;
  tipPosition: TipPositions;
}

export class TooltipIcon extends React.Component<Props> {

  private root: HTMLDivElement;
  private tip: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.getRoot();
  }

  public render(): any {
    const error = errorDescriptions[this.props.modelCheckType];
    return (
      <div
        className={classNames('checker-tooltip-icon', { 'warning': this.props.type !== ModelCheckPriority.Low })}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      >
        <KreoIconInfoBigColor/>
        <span className='tooltip-main' ref={this.saveTip}>
          <span className='error-title'>
            {!!error && error.name}
          </span>
          <span className='error-desciption-body'>
            {!!error && error.description}
          </span>
        </span>
      </div>
    );
  }

  private getRoot(): HTMLDivElement {
    if (!this.root) {
      this.root = getOrCreateRoot();
    }
    return this.root;
  }

  @autobind
  private saveTip(ref: HTMLDivElement): void {
    this.tip = ref;
  }

  @autobind
  private onMouseOut(): void {
    this.root.removeChild(this.tip);
  }

  @autobind
  private onMouseOver(event: React.MouseEvent<HTMLDivElement>): void {
    const targetRect = event.currentTarget.getBoundingClientRect() as DOMRect;
    this.root.appendChild(this.tip);
    const tipRect = this.tip.getBoundingClientRect() as DOMRect;
    const gap = 5;
    switch (this.props.tipPosition) {
      case TipPositions.BOTTOM: {
        this.tip.style.left = `${targetRect.x + targetRect.width + gap}px`;
        this.tip.style.top = `${targetRect.y + targetRect.height + gap}px`;
        break;
      }
      case TipPositions.TOP: {
        this.tip.style.left = `${targetRect.x - tipRect.width - gap}px`;
        this.tip.style.top = `${targetRect.y - tipRect.height - gap}px`;
        break;
      }
      case TipPositions.RIGHT_TOP: {
        this.tip.style.left = `${targetRect.x + targetRect.width + gap}px`;
        this.tip.style.top =   `${targetRect.y - tipRect.height - gap}px`;
        break;
      }
      default:
    }
  }
}
