import {
  Icons,
  SearchList,
  Text,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { PRESET_TABLE_DIALOG_NAME } from '../../../../../components/dialog/preset-table-dialog';
import { TwoDActions } from '../../../../../units/2d/actions/creators';
import { TablePreset } from '../../../../../units/2d/interfaces';
import { PresetMenuItem } from './preset-menu-item';
import { Styled } from './styled';


interface DispatchProps {
  openCreateDialog: (preset?: TablePreset) => void;
  deletePreset: (id: string) => void;
}

interface StateProps {
  tablePresetsData: TablePreset[];
}

interface Props extends StateProps, DispatchProps {
  onSelect: (data: TablePreset) => void;
}

class PresetMenuComponent extends React.PureComponent<Props> {
  private divRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Styled.SearchListItemContainer className='ignore-react-onclickoutside'>
        <SearchList
          items={this.props.tablePresetsData}
          itemComponent={this.getItemComponent}
          maxItemsCount={4}
          width={240}
          showNoResult={false}
          getKeyValue={this.getPresetKey}
          getStringToCompare={this.getPresetName}
          onClick={this.onSelectPreset}
          itemHeight={40}
        >
          <Styled.MenuItemButton onClick={this.create} ref={this.divRef}>
            <Icons.PlusBig />
            <Text fontSize={14} color={'turquoise'}>Add preset</Text>
          </Styled.MenuItemButton>
        </SearchList>
      </Styled.SearchListItemContainer>
    );
  }

  private getPresetKey(item: TablePreset): string {
    return item.id;
  }

  private getPresetName(item: TablePreset): string {
    return item.name;
  }

  @autobind
  private getItemComponent(item: TablePreset): JSX.Element {
    return (
      <PresetMenuItem
        itemData={item}
        deletePreset={this.props.deletePreset}
        openCreateDialog={this.props.openCreateDialog}
      />
    );
  }

  @autobind
  private create(): void {
    this.props.openCreateDialog();
  }

  @autobind
  private onSelectPreset(data: TablePreset): void {
    this.props.onSelect(data);
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    tablePresetsData: state.twoD.presets,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openCreateDialog: (data: TablePreset) => dispatch(KreoDialogActions.openDialog(PRESET_TABLE_DIALOG_NAME, data)),
    deletePreset: (id: string) => dispatch(TwoDActions.deleteTablePreset(id)),
  };
};

export const PresetMenu = connect(mapStateToProps, mapDispatchToProps)(PresetMenuComponent);
