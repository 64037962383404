import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './activities-tab.scss';

import { State } from 'common/interfaces/state';
import { KreoEngine } from '../../../../../../components/engine/KreoEngine';
import { ChartDataProvider } from '../../../../utils/gantt-chart';
import { actions } from '../../actions';
import { ActivitiesTabMode, ActivitiesTabModeKeys } from '../../enums/activities-tab-mode';
import { ActivitiesList } from '../activities-list';
import { ModeSwitcher } from '../mode-switcher';


interface TabOwnProps {
  currentMoment: number;
  dataProvider: ChartDataProvider;
  engine: KreoEngine;
  updateCurrentMoment: (currentMoment: number, force?: boolean) => void;
  selectElementOnEngine: (bimId: number) => void;
}

interface TabStateProps {
  activeMode: ActivitiesTabMode;
  selectedElementsIds: number[];
}

interface TabDispatchProps {
  setActiveMode: (mode: ActivitiesTabMode) => void;
  expandAllActivities: () => void;
  collapseAllActivities: () => void;
}

interface TabProps extends TabStateProps, TabDispatchProps, TabOwnProps { }

class ActivitiesTabComponent extends React.Component<TabProps> {

  public render(): React.ReactNode {
    if (!this.props.engine) {
      return null;
    }

    const currentActivities = this.props.engine.getCurrentActiveWorks();
    const selectedActivities = this.props.engine.getWorksForElements(this.props.selectedElementsIds);

    return (
      <div
        className='activities-tab'
      >
        <div className='activities-tab__controls-line'>
          <div className='activities-tab__button-group'>
            <div
              className='activities-tab__button'
              onClick={this.props.expandAllActivities}
            >
              Expand All
            </div>
            <div
              className='activities-tab__button'
              onClick={this.props.collapseAllActivities}
            >
              Collapse All
            </div>
          </div>
          <ModeSwitcher
            modes={ActivitiesTabModeKeys}
            activeMode={ActivitiesTabMode[this.props.activeMode]}
            setActiveMode={this.setActiveMode}
          />
        </div>
        {
          this.props.activeMode === ActivitiesTabMode.Current
          && (
            currentActivities && currentActivities.length
              ? (
                <ActivitiesList
                  activities={currentActivities}
                  dataProvider={this.props.dataProvider}
                  selectedElementsIds={this.props.selectedElementsIds}
                  updateCurrentMoment={this.props.updateCurrentMoment}
                  selectElementOnEngine={this.props.selectElementOnEngine}
                />
              ) : (
                <div className='activities-tab__message'>
                  No activities in progress at the current moment
                </div>
              )
          )
        }
        {
          this.props.activeMode === ActivitiesTabMode.Object
          && (
            this.props.selectedElementsIds && this.props.selectedElementsIds.length
              ? (
                <ActivitiesList
                  activities={selectedActivities}
                  dataProvider={this.props.dataProvider}
                  selectedElementsIds={this.props.selectedElementsIds}
                  updateCurrentMoment={this.props.updateCurrentMoment}
                  selectElementOnEngine={this.props.selectElementOnEngine}
                />
              ) : (
                <div className='activities-tab__message'>
                  Select an element on the 3D view to see activities associated with it
                </div>
              )
          )
        }
      </div>
    );
  }

  @autobind
  private setActiveMode(mode: string): void {
    this.props.setActiveMode(ActivitiesTabMode[mode]);
  }
}

const mapStateToProps = (state: State): TabStateProps => {
  return {
    activeMode: state.fourDVisualisation.sidePanel.activitiesTab.activeMode,
    selectedElementsIds: state.fourDVisualisation.selectedElementsIds,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): TabDispatchProps => {
  return {
    setActiveMode: (mode: ActivitiesTabMode): void => {
      dispatch(actions.setActivitiesTabMode(mode));
    },
    expandAllActivities: (): void => {
      dispatch(actions.expandAllActivityCards());
    },
    collapseAllActivities: (): void => {
      dispatch(actions.collapseAllActivityCards());
    },
  };
};

export const ActivitiesTab = connect(mapStateToProps, mapDispatchToProps)(ActivitiesTabComponent);
