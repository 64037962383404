import * as Ag from 'ag-grid-community';
import uuid from 'uuid';

import { AgGridHelper } from '../../ag-grid';
import {
  TreeTableRow,
  TreeTableRowAddModel,
  TreeTableRowUpdateModel,
} from './interfaces';


export interface RowChangesCallbacks<T> {
  onReorderRows: (parentId: string | null, position: number, rowIdsToMove: string[]) => void;
  onRowsUpdate: (rows: Array<TreeTableRowUpdateModel<T>>) => void;
  onAddNewRows: (
    targetParentId: string | null, position: number,
    rootRowIds: string[], rows: Array<TreeTableRow<T>>,
  ) => void;
  onRemoveRows: (rowIds: string[]) => void;
}

export class TreeTableRowController<T> {
  private gridApi: Ag.GridApi;
  private callbacks: RowChangesCallbacks<T>;

  constructor(gridApi: Ag.GridApi, callbacks: RowChangesCallbacks<T>) {
    this.gridApi = gridApi;
    this.callbacks = callbacks;
  }

  public addRows(rows: Array<TreeTableRowAddModel<T>>, targetId: string, position?: number): void {
    const children: Array<TreeTableRow<T>> = [];
    const rootNodes: Array<TreeTableRow<T>> = [];
    rows.forEach(row => this.fillRootAndChildrenArrays(row, targetId, children, rootNodes));
    const rootRowIds = rootNodes.map(x => x.id);
    const parent = this.getNode(targetId);

    const insertPosition = Number.isNaN(Number(position)) || parent.data.children.length < position
      ? parent.data.children.length
      : position;

    parent.data.children = [
      ...parent.data.children.slice(0, insertPosition),
      ...rootRowIds,
      ...parent.data.children.slice(insertPosition),
    ];
    parent.updateData(parent.data);

    this.gridApi.updateRowData({
      add: rootNodes,
      addIndex: insertPosition,
    });

    const reorderedParent = this.getNode(targetId);
    reorderedParent.childrenAfterGroup = reorderedParent.data.children.map(id => reorderedParent.childrenMapped[id]);
    this.gridApi.updateRowData({ add: children, update: reorderedParent.allLeafChildren.map(x => x.data) });
    this.callbacks.onAddNewRows(targetId, insertPosition, rootRowIds, rootNodes.concat(children));
  }

  public removeRows(rowIds: string[]): void {
    rowIds.forEach(rowId => {
      const row = this.gridApi.getRowNode(rowId);
      const parent = row.parent;
      const removedId = row.id;

      this.gridApi.updateRowData({
        remove: row.allLeafChildren,
      });
      parent.data.children = parent.data.children.filter(x => x !== removedId);
      parent.updateData(parent.data);
    });

    this.callbacks.onRemoveRows(rowIds);
  }

  public updateRows(rows: Array<TreeTableRowUpdateModel<T>>): void {
    rows.forEach(row => {
      const rowNode = this.gridApi.getRowNode(row.id);
      rowNode.data.properties = row.properties;
      rowNode.updateData(rowNode.data);
    });

    this.callbacks.onRowsUpdate(rows);
  }

  private getNode(id: string): Ag.RowNode {
    return id
      ? this.gridApi.getRowNode(id)
      : AgGridHelper.getRootNode(this.gridApi);
  }

  private fillRootAndChildrenArrays(
    row: TreeTableRowAddModel<T>,
    parentId: string,
    childrenArray: Array<TreeTableRow<T>>,
    rootRowsArrays?: Array<TreeTableRow<T>>,
  ): void {
    const properties = {};
    for (const propertyKey in row.properties) {
      properties[propertyKey] = row.properties[propertyKey];
    }

    const childrenIds = this.appendChildrenIdIfNotExistAndReturnChildrenIds(row.children);

    const id = row.id || uuid.v4();
    (rootRowsArrays || childrenArray).push({
      id,
      parentId,
      children: childrenIds,
      type: row.type,
      properties,
    });

    if (row.children) {
      row.children.forEach(r => this.fillRootAndChildrenArrays(r, id, childrenArray));
    }
  }

  private appendChildrenIdIfNotExistAndReturnChildrenIds(children?: Array<TreeTableRowAddModel<T>>): string[] | null {
    if (!children) {
      return null;
    }

    const result = [];
    for (const child of children) {
      child.id = child.id || uuid.v4();
      result.push(child.id);
    }

    return result;
  }
}
