import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './nav-menu.scss';

import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State } from 'common/interfaces/state';
import { HelpMenu } from '../help-menu';
import { NavMenuItem } from './nav-menu-item';

interface MenuOwnProps {
  items: NavMenuItem[];
}

interface MenuStateProps {
  appState: State;
}

interface MenuProps extends MenuOwnProps, MenuStateProps, AbilityAwareProps { }

class NavMenuComponent extends React.Component<MenuProps> {
  public render(): React.ReactNode {
    const { items, appState } = this.props;

    return (
      <div className='kreo-nav-menu'>
        {items.map(item => {
          if (item.isVisible && !item.isVisible(this.props.ability)) {
            return null;
          }
          const disabled = !item.isAvailable(appState);
          const hasBadge = item.hasBadge ? item.hasBadge(appState) : false;
          const itemClassName = classNames('kreo-nav-menu__item', {
            disabled,
            'kreo-nav-menu__item--has-badge': hasBadge,
          });
          return disabled ? (
            <div className={itemClassName}>{this.renderMenuItemContent(item)}</div>
          ) : (
              <NavLink
                className={itemClassName}
                activeClassName='active'
                key={item.title}
                to={item.path}
              >
                {this.renderMenuItemContent(item)}
              </NavLink>
          );
        })}
        <div className='kreo-nav-menu__help-icon'>
          <HelpMenu />
        </div>
      </div>
    );
  }

  private renderMenuItemContent(item: NavMenuItem): React.ReactNode {
    return (
      <React.Fragment>
        <div className='kreo-nav-menu__item-inner'>
          <div className='kreo-nav-menu__icon-wrap'>{item.icon}</div>
          <div className='kreo-nav-menu__item-title'>{item.title}</div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: State): MenuStateProps => {
  return { appState: state };
};

export const NavMenu = withAbilityContext(connect(mapStateToProps)(NavMenuComponent));
