import React from 'react';
import { DatabaseEntityModifyPanel } from '../database-entity-modify-panel';
import { CreateDatabaseResourceMaterial } from './create-database-resource-material';
import { CreateDatabaseResourceMaterialVariant } from './create-database-resource-material-variant';
import { EditDatabaseResourceMaterial } from './edit-database-resource-material';
import { EditDatabaseResourceMaterialVariant } from './edit-database-resource-material-variant';

export class DatabaseResourceMaterialModifyPanel extends React.Component {
  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyPanel
        createRootComponent={CreateDatabaseResourceMaterial}
        createVariantComponent={CreateDatabaseResourceMaterialVariant}
        editRootComponent={EditDatabaseResourceMaterial}
        editVariantComponent={EditDatabaseResourceMaterialVariant}
      />
    );
  }
}
