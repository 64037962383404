import { ActionWith } from 'common/interfaces/action-with';

import { Feed } from 'common/interfaces/feed';
import { FeedParams } from '../../interfaces/feed';
import { User } from '../../interfaces/user';
import { ChangeUserEmail, EnableUserParams, UpdateAttribute } from '../payloads/people';
import { AdminPeopleActionTypes } from '../types/people';

function loadUsers(
  data: FeedParams,
): ActionWith<FeedParams> {
  return {
    type: AdminPeopleActionTypes.LOAD_REQUEST,
    payload: data,
  };
}

function loadUsersSucceeded(
  data: Feed<User>,
): ActionWith<Feed<User>> {
  return {
    type: AdminPeopleActionTypes.LOAD_SUCCEEDED,
    payload: data,
  };
}

function deleteUser(userId: string): ActionWith<string> {
  return {
    type: AdminPeopleActionTypes.DELETE_REQUEST,
    payload: userId,
  };
}

function deleteUserSucceeded(userId: string): ActionWith<string> {
  return {
    type: AdminPeopleActionTypes.DELETE_SUCCEEDED,
    payload: userId,
  };
}

function enableUser(
  data: EnableUserParams,
): ActionWith<EnableUserParams> {
  return {
    type: AdminPeopleActionTypes.ENABLE_USER_REQUEST,
    payload: data,
  };
}

function enableUserSucceeded(
  data: EnableUserParams,
): ActionWith<EnableUserParams> {
  return {
    type: AdminPeopleActionTypes.ENABLE_USER_SUCCEEDED,
    payload: data,
  };
}

function updateUserAttribute(
  key: string,
  value: string,
  userId: string,
): ActionWith<UpdateAttribute> {
  return {
    type: AdminPeopleActionTypes.UPDATE_USER_ATTRIBUTE_REQUEST,
    payload: { key, value, userId },
  };
}

function updateUserAttributeSucceed(
  key: string,
  value: string,
  userId: string,
): ActionWith<UpdateAttribute> {
  return {
    type: AdminPeopleActionTypes.UPDATE_USER_ATTRIBUTE_SUCCEED,
    payload: { key, value, userId },
  };
}

function deletedOwnedCompany(companyId: string): ActionWith<string> {
  return {
    type: AdminPeopleActionTypes.DELETE_OWNED_COMPANY,
    payload: companyId,
  };
}

function changeUserEmail(userId: string, email: string): ActionWith<ChangeUserEmail> {
  return {
    type: AdminPeopleActionTypes.CHANGE_USER_EMAIL,
    payload: { userId, email },
  };
}

export const AdminPeopleActions = {
  updateUserAttributeSucceed,
  updateUserAttribute,
  enableUserSucceeded,
  enableUser,
  deleteUserSucceeded,
  deleteUser,
  loadUsersSucceeded,
  loadUsers,
  deletedOwnedCompany,
  changeUserEmail,
};
