import styled from 'styled-components';

import { LinkComponentStyled } from 'common/components/link-component';
import { WizzardMainContainer } from '../wizzard-tools/wizzard-main-container';

const Container = styled(WizzardMainContainer)`
  border-bottom: 1px solid ${p => p.theme.color.background};

  ${LinkComponentStyled.DivContainer} {
    > svg {
      margin-right: 15px;
    }
  }
`;

const Button = styled.div`
  > button {
    width: 100%;
  }
`;

export const Styled = {
  Container,
  Button,
};
