import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { SetPdfStatisticPayload } from '../actions/payloads/wizzard-tools';
import { WizzardToolsActionTypes } from '../actions/types/wizzard-tools';
import { WizzardErrorTypes } from '../enums';
import { WizzardStatus } from '../enums/dropper-state';
import { WizzardToolsState } from '../interfaces';
import { DrawingsState } from '../interfaces/drawings-state';

export const WizzardToolsReducerMethods: ReducerMethods<DrawingsState> = {
  [WizzardToolsActionTypes.SET_SETTINGS]: (state, payload) => {
    return new MonoliteHelper(state).set(_ => _.wizzardTools[payload.key], payload.value).get();
  },
  [WizzardToolsActionTypes.SET_WIZZARD_STATE]: (state, payload) => {
    const helper = new MonoliteHelper(state);
    if (state.wizzardTools.wizzardState.status === WizzardStatus.Eraser) {
      helper
        .set(_ => _.wizzardTools.selectedToRemove, [])
        .set(_ => _.wizzardTools.finderSelectedVisibility, { area: true, line: true });
    }
    return helper
      .set(_ => _.wizzardTools.wizzardState, payload)
      .get();
  },
  [WizzardToolsActionTypes.DROP_WIZZARD_STATE]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.wizzardTools.wizzardState, { status: WizzardStatus.Start })
      .set(_ => _.wizzardTools.searchArea, null)
      .set(_ => _.wizzardTools.finderSelectionArea, null)
      .set(_ => _.wizzardTools.result, null)
      .set(_ => _.wizzardTools.finderSelectedGeometries, null)
      .set(_ => _.wizzardTools.selectedToRemove, [])
      .set(_ => _.wizzardTools.finderSelectedVisibility, { area: true, line: true })
      .set(_ => _.wizzardTools.finderResultToHide, {})
      .get();
  },
  [WizzardToolsActionTypes.DROP_FINDER_SELECTION_AREA]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.wizzardTools.wizzardState, { status: WizzardStatus.Start })
      .set(_ => _.wizzardTools.finderSelectionArea, null)
      .set(_ => _.wizzardTools.finderSelectedGeometries, null)
      .get();
  },
  [WizzardToolsActionTypes.SET_SELECTION_AREA]: (state, payload) => {
    const helper = new MonoliteHelper(state);
    if (!payload) {
      helper
        .set(_ => _.wizzardTools.finderSelectedGeometries, null);
    }
    return helper
      .set(_ => _.wizzardTools.finderSelectionArea, payload)
      .get();
  },
  [WizzardToolsActionTypes.SET_SELECTED_GEOMETRY]: (state, payload) => {
    return new MonoliteHelper(state)
      .set(_ => _.wizzardTools.finderSelectedGeometries, payload)
      .get();
  },
  [WizzardToolsActionTypes.ADD_GEOMETRY_TO_REMOVE]: (state, payload) => {
    return new MonoliteHelper(state)
      .setConcat(_ => _.wizzardTools.selectedToRemove, payload)
      .get();
  },
  [WizzardToolsActionTypes.APPLY_REMOVE_GEOMETRIES]: (state) => {
    const geometriesToRemoveSet = new Set(state.wizzardTools.selectedToRemove);
    const newFinderSelectedGeometries =
      state.wizzardTools.finderSelectedGeometries.filter(_ => !geometriesToRemoveSet.has(_.id));
    if (!newFinderSelectedGeometries.length) {
      return new MonoliteHelper(state)
        .set(_ => _.wizzardTools.finderSelectedGeometries, null)
        .set(_ => _.wizzardTools.selectedToRemove, [])
        .set(_ => _.wizzardTools.wizzardState, { status: WizzardStatus.Start })
        .set(_ => _.wizzardTools.finderSelectionArea, null)
        .get();
    }
    return new MonoliteHelper(state)
      .set(_ => _.wizzardTools.finderSelectedGeometries, newFinderSelectedGeometries)
      .set(_ => _.wizzardTools.wizzardState.status, WizzardStatus.Start)
      .set(_ => _.wizzardTools.selectedToRemove, [])
      .get();
  },
  [WizzardToolsActionTypes.SET_RESULT]: (state, payload) => {
    if (!payload) {
      return new MonoliteHelper(state)
        .set(_ => _.wizzardTools.result, null)
        .set(_ => _.wizzardTools.finderResultToHide, {})
        .set(_ => _.wizzardTools.wizzardState, { status: WizzardStatus.Start })
        .get();
    } else {
      return new MonoliteHelper(state)
        .set(_ => _.wizzardTools.result, payload)
        .set(_ => _.wizzardTools.finderResultToHide, {})
        .set(_ => _.wizzardTools.wizzardState, { status: WizzardStatus.Preview, foundedItemsCount: payload.length })
        .get();
    }
  },
  [WizzardToolsActionTypes.DROP_REMOVE_GEOMETRIES]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.wizzardTools.selectedToRemove, [])
      .get();
  },
  [WizzardToolsActionTypes.SET_ERROR]: (state, error: WizzardErrorTypes) => {
    return new MonoliteHelper(state)
      .set(_ => _.wizzardTools.error, error)
      .get();
  },
  [WizzardToolsActionTypes.SET_FINDER_SELECTION_VISIBILITY]: (state, payload) => {
    return new MonoliteHelper(state)
      .set(_ => _.wizzardTools.finderSelectedVisibility[payload.key], payload.isVisible)
      .get();
  },
  [WizzardToolsActionTypes.SET_PDF_STATISTIC]: (state, { drawingId, statistic }: SetPdfStatisticPayload) => {
    return new MonoliteHelper(state)
      .set(_ => _.drawingStatistic[drawingId], statistic)
      .get();
  },
  [WizzardToolsActionTypes.CHANGE_RESULT_HIDE_STATUS]: (state, { id, status }) => {
    return new MonoliteHelper(state)
      .set(_ => _.wizzardTools.finderResultToHide[id], status)
      .get();
  },
  [WizzardToolsActionTypes.BATCH_UPDATE_SETTINGS]: (state, payload: Partial<WizzardToolsState>) => {
    return new MonoliteHelper(state)
      .set(_ => _.wizzardTools, _ => ({ ..._, ...payload }))
      .get();
  },
};
