import { Text, TinyText } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { PdfPagePreviewWithBubble } from 'common/components/drawings/components';
import { useDrawingInfo } from 'common/components/drawings/hooks';
import { DrawingsFile } from 'common/components/drawings/interfaces/drawings-file-info';
import { TwoDHeaderPageTabStyled } from 'common/components/two-d-header/two-d-header-page-tab';
import { ColorsName } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { Styled } from './styled';

interface Props {
  selected: boolean;
  drawingId: string;
  onSelect: (drawingId: string) => void;
}

export const TabComponent: React.FC<Props> = ({
  drawingId,
  selected,
  onSelect,
}) => {
  const handleSelect = useCallback(() => {
    onSelect(drawingId);
  }, []);
  const drawing = useDrawingInfo(drawingId);
  const pdfName = useSelector<State>(x =>
    (x.drawings.files.entities[drawing.pdfId] as DrawingsFile).properties.name,
  );
  return (
    <Styled.Tab
      selected={selected}
      onClick={handleSelect}
    >
      <TwoDHeaderPageTabStyled.PreviewImage>
        <PdfPagePreviewWithBubble
          created={drawing.screenshotCreated}
          color={drawing.color}
          pdfId={drawing.pdfId}
          pageId={drawingId}
          pageNumber={drawing.pageNumber}
        />
      </TwoDHeaderPageTabStyled.PreviewImage>
      <TwoDHeaderPageTabStyled.TextContainer minified={false}>
        <Text>
          {drawing.name}
        </Text>
        <TinyText
          color={ColorsName.turquoiseFont}
          withEllipsis={true}
        >
          {pdfName}
        </TinyText>
      </TwoDHeaderPageTabStyled.TextContainer>
    </Styled.Tab>
  );
};


export const Tab = React.memo(TabComponent);
