import styled from 'styled-components';

const PREVIEW_SIZE = 80;

const SelectedMark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: ${p => p.theme.color.turquoise};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: white;
  svg {
    width: 10px;
    height: 10px;
    fill: white;
  }
`;

const FocusButton = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: 30px;
  height: 30px;
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.turquoiseInversed};
  }
  &:hover {
    background-color: ${p => p.theme.color.turquoise};
    svg {
      fill: ${p => p.theme.color.white};
    }
  }

  &:active {
    transform: scaleX(0.9) scaleY(0.9) scaleZ(0.9);
  }
`;

const Similarity = styled.div`
  background-color: ${p => p.theme.color.turquoise};
  box-sizing: border-box;
  position: absolute;
  top: 5px;
  left: 5px;
  height: 18px;
  padding: 3px 7px 3px 5px;
  color: ${p => p.theme.color.white};
  font-size: 12px;
  line-height: 14px;
  border-radius: 10px;;
  align-items: center;
  display: flex;
`;

const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  aspect-ratio: 1;
  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-sizing: border-box;
  }

  ${FocusButton} {
    opacity: 0;
  }

  &:hover {
    ${FocusButton} {
      opacity: 1;
    }
  }
`;

const Container = styled.div<{ visible: boolean }>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  border-radius: 15px;
  aspect-ratio: 1 / 1;
  background: ${p => p.theme.color.background};
  cursor: pointer;

  ${SelectedMark} {
    opacity: ${p => p.visible ? 1 : 0};
  }
  img {
    border-radius: 10px;
    box-sizing: border-box;
    min-width: 100%;
    max-width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  ${FocusButton} {
    opacity: 0;
  }

  &:hover {
    ${FocusButton} {
      opacity: 1;
    }
  }
`;

export const Styled = {
  Container,
  ImageContainer,
  FocusButton,
  SelectedMark,
  Similarity,
  PREVIEW_SIZE,
};
