const prefix = '@database-common';

export const DatabaseCommonActionTypes = {
  GET_ACTIVITY_CATEGORIES: `${prefix}/GET_ACTIVITY_CATEGORIES`,
  GET_ACTIVITY_CATEGORIES_SUCCEEDED: `${prefix}/GET_ACTIVITY_CATEGORIES_SUCCEEDED`,
  GET_ACTIVITY_CATEGORY_COUNTS: `${prefix}/GET_ACTIVITY_CATEGORY_COUNTS`,
  GET_ACTIVITY_CATEGORY_COUNTS_SUCCEEDED: `${prefix}/GET_ACTIVITY_CATEGORY_COUNTS_SUCCEEDED`,
  LOAD_FUNCTIONS_WITH_UNITS: `${prefix}/LOAD_FUNCTIONS_WITH_UNITS`,
  LOAD_FUNCTIONS_WITH_UNITS_SUCCEEDED: `${prefix}/LOAD_FUNCTIONS_WITH_UNITS_SUCCEEDED`,

  SET_PAGE_MODE: `${prefix}/SET_PAGE_MODE`,
  DROP_STATE: `${prefix}/DROP_STATE`,
  DROP_CURRENT_DATABASE_STATE: `${prefix}/DROP_CURRENT_DATABASE_STATE`,
};
