import { Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { Item } from 'common/components/item-context-menu/menu-item-container';
import { ErrorContainer } from 'common/components/project-card';
import { ProjectRow } from 'common/components/project-row';
import { Spinner } from 'common/components/spinner';
import { ConstantFunctions } from 'common/constants/functions';
import { ProjectType } from 'common/constants/project-type';
import { ProjectCreateStatus } from 'common/enums/project-create-status';
import { RoleGroup } from 'common/enums/role-group';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { AppUrls } from 'routes/app-urls';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { ProjectAccessReason } from 'unit-projects/enums';
import { AnalyticsProps, withAnalyticsContext } from '../../../utils/posthog/analytics-wraper';
import { MetricNames } from '../../../utils/posthog/metric-names';
import { AccountApi } from '../../account/api';
import { Company } from '../../account/interfaces/company';
import { Person } from '../../people/interfaces/person';
import { ProjectsApi } from '../../projects/api/common';
import { KnownViewModel } from '../../projects/enums/known-view-model';
import { ViewModelStatus } from '../../projects/enums/view-model-status';
import { CompanyProjectHeader } from '../../projects/interfaces/company-project-header';
import { ProjectsView } from '../2d-projects-content/constants';
import { ProjectCardImageAuthWrapper } from './project-card-auth-wrapper';

interface UserInfo {
  name: string;
  email: string;
  avatar: string;
}

interface OwnProps {
  isAdminMode: boolean;
  projectHeader: CompanyProjectHeader;
  isInaccessibleProject: boolean;
  onRemoveConfirm: (id: number, createStatus: ProjectCreateStatus) => void;
  onLeaveProject: (id: Number, createStatus: ProjectCreateStatus) => void;
  onInviteToProject: (projectId: number) => void;
  onProjectNameEdit: (id: number) => void;
  onDuplicateProjectClick: (id: number) => void;
  onMoveProjectClick: (id: number) => void;
  onSaveAsTemplateClick: (id: number) => void;
}

interface DispatchProps {
  onDumpProjectClick: (id: number, quality: number) => void;
}

interface StateProps {
  currentUserId: string;
  companiesUsers: Person[];
  company: Company;
  projectsViewType: ProjectsView;
}

interface Props extends OwnProps, StateProps, AbilityAwareProps, AnalyticsProps, DispatchProps { }

const spinner = (): JSX.Element => <Spinner show={true} />;

class TwoDProjectsCardWrapperComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { projectHeader, isAdminMode, isInaccessibleProject, projectsViewType } = this.props;

    const projectIsLoading = this.projectIsLoading();
    const canOpenProject = !projectIsLoading && !isAdminMode;
    const invitedUsers = this.getInvitedUsers();
    const menuItems = isAdminMode
      ? this.getAdminModeMenu(!projectIsLoading && !this.projectIsError())
      : this.getMenu(!projectIsLoading && !this.projectIsError());
    switch (projectsViewType) {
      case ProjectsView.List:
        return (
          <ProjectRow
            image={this.getScreenShotUrl()}
            company={this.getCompanyInfo()}
            isShareWithCompany={projectHeader.isCompanyShared}
            projectName={projectHeader.name}
            created={projectHeader.created}
            users={invitedUsers}
            menuItems={menuItems}
            url={this.getUrl()}
            isBlocked={!canOpenProject}
            RouterLink={Link}
            sizeItem={'m'}
            menuWidth={210}
            isCalculating={this.isCalculating()}
            CalculatingComponent={spinner}
            isInaccessibleProject={isInaccessibleProject}
          />
        );
      case ProjectsView.Grid:
      default:
        return (
          <ProjectCardImageAuthWrapper
            image={this.getScreenShotUrl()}
            company={this.getCompanyInfo()}
            isShareWithCompany={projectHeader.isCompanyShared}
            projectName={projectHeader.name}
            users={invitedUsers}
            menuItems={menuItems}
            url={this.getUrl()}
            isBlocked={!canOpenProject}
            RouterLink={Link}
            sizeItem={'m'}
            menuWidth={210}
            isCalculating={this.isCalculating()}
            CalculatingComponent={spinner}
            isInaccessibleProject={isInaccessibleProject}
            ErrorComponent={this.renderErrorButton}
          />
        );
    }
  }

  @autobind
  private renderErrorButton(): JSX.Element {
    return (
      <ErrorContainer />
    );
  }

  private isCalculating(): boolean {
    const { projectHeader } = this.props;
    return projectHeader.viewModelStatuses
      .some(v => v.viewModelType === KnownViewModel.ProjectCreating
        && v.status === ViewModelStatus.Calculating);
  }

  private getScreenShotUrl(): string | null {
    const { projectHeader } = this.props;
    const screenshotStatus = projectHeader.viewModelStatuses
      .find(x => x.viewModelType === KnownViewModel.ProjectScreenshot)?.status;
    if (screenshotStatus === ViewModelStatus.Ready || screenshotStatus === ViewModelStatus.Empty) {
      return ProjectsApi.buildProjectScreenshotUrl(projectHeader.id);
    } else {
      return null;
    }
  }

  private getCreatingStatus(): ViewModelStatus {
    const { viewModelStatuses: vmStatuses } = this.props.projectHeader;
    const projectStatus = vmStatuses
      && vmStatuses.find((vmStatus) => vmStatus.viewModelType === KnownViewModel.ProjectCreating);
    return projectStatus?.status;
  }


  private projectIsError(): boolean {
    const projectStatus = this.getCreatingStatus();
    return projectStatus === ViewModelStatus.Failed;
  }

  private projectIsLoading(): boolean {
    const projectStatus = this.getCreatingStatus();
    const isFailedStatus = projectStatus === ViewModelStatus.Failed;
    return !isFailedStatus && projectStatus !== ViewModelStatus.Ready;
  }

  @autobind
  private getUrl(): string {
    const { id } = this.props.projectHeader;
    const projectStatus = this.getCreatingStatus();
    const isFailedStatus = projectStatus === ViewModelStatus.Failed;
    return isFailedStatus ? null : AppUrls.qto2d.project.quantityTakeOff.url({ projectId: id.toString() });
  }

  private getAdminModeMenu(projectCreated: boolean): Item[] {
    const menu = [];
    const is2dProject = this.props.projectHeader.type === ProjectType.Project2d;
    const canManageProject = this.props.ability.can(Operation.Manage, Subject.Project);
    const canShareProject = this.props.ability.can(Operation.Manage, Subject.ShareProjects);

    if (projectCreated && canShareProject) {
      menu.push({ text: 'Share', icon: Icons.Share, onClick: this.onOpenInviteToProject });
    }

    if (canManageProject && is2dProject && projectCreated) {
      menu.push({ text: 'Move to', icon: Icons.MoveToGroup_1, onClick: (e) => this.moveProject(e) });
    }

    if (this.props.ability.can(Operation.Delete, Subject.Project)) {
      menu.push({ text: 'Delete', icon: Icons.Delete, onClick: (e) => this.delete(e) });
    }

    return menu;
  }

  @autobind
  private getMenu(projectCreated: boolean): Item[] {
    const menu = [];
    const is2dProject = this.props.projectHeader.type === ProjectType.Project2d;
    const canManageProject = this.props.ability.can(Operation.Manage, Subject.Project);
    const canShareProject = this.props.ability.can(Operation.Manage, Subject.ShareProjects);
    const canManageProjectTemplates = this.props.ability.can(Operation.Manage, Subject.Project2DTemplates);
    const canDuplicateProject = this.props.ability.can(Operation.Duplicate, Subject.Project);
    const isOwn = this.props.projectHeader.accessReason === ProjectAccessReason.Owner;

    if (projectCreated && canShareProject) {
      menu.push({ text: 'Share', icon: Icons.Share, onClick: this.onOpenInviteToProject });
    }

    if ((canDuplicateProject && isOwn || canManageProjectTemplates) && is2dProject && projectCreated) {
      menu.push({ text: 'Duplicate', icon: Icons.Duplicate, onClick: this.onDuplicateProjectClick });
    }

    if (canManageProject && projectCreated) {
      menu.push({ text: 'Edit', icon: Icons.Edit2D, onClick: (e) => this.onProjectNameEdit(e) });
    }

    if (canManageProject && is2dProject && projectCreated) {
      menu.push({ text: 'Move to', icon: Icons.MoveToGroup_1, onClick: (e) => this.moveProject(e) });
    }

    if (this.props.ability.can(Operation.Delete, Subject.Project)) {
      if (isOwn) {
        menu.push({ text: 'Delete', icon: Icons.Delete, onClick: (e) => this.delete(e) });
      } else {
        if (!this.props.projectHeader.isCompanyShared) {
          menu.push({ text: 'Leave', icon: Icons.Delete, onClick: (e) => this.leave(e) });
        }
      }
    }

    if (this.props.ability.can(Operation.Admin, Subject.Application) && is2dProject && projectCreated) {
      menu.push({ text: 'Dump (Full)', icon: Icons.Download, onClick: (e) => this.onFullDumpProject(e) });
      menu.push({ text: 'Dump (Light)', icon: Icons.Download, onClick: (e) => this.onLightDumpProject(e) });
      menu.push({ text: 'Dump (Fake)', icon: Icons.Download, onClick: (e) => this.onFakeDumpProject(e) });
    }

    if (canManageProjectTemplates && is2dProject && projectCreated) {
      menu.push({ text: 'Save as template', icon: Icons.Templates, onClick: (e) => this.saveAsTemplate(e) });
    }

    return menu;
  }

  private getInvitedUsers(): UserInfo[] {
    const projectUsers = new Set<string>(this.props.projectHeader.users.map(user => user.id));
    projectUsers.delete(this.props.currentUserId);
    const filteredUsers = this.props.projectHeader.isCompanyShared
      ? this.props.companiesUsers.filter(user => user.roleGroup === RoleGroup.Guest)
      : this.props.companiesUsers;

    return arrayUtils.filterMap(
      filteredUsers,
      user => projectUsers.has(user.id),
      user => ({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        avatar: AccountApi.getAvatarLink(user),
      }),
    );
  }

  private getCompanyInfo(): { name: string, image: string } {
    const company = this.props.company;

    return {
      name: company.name,
      image: AccountApi.getCompanyLogoLink(company),
    };
  }

  @autobind
  private onOpenInviteToProject(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    const { onInviteToProject: onOpenInviteToProject, projectHeader } = this.props;
    onOpenInviteToProject(projectHeader.id);
  }

  private leave(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    const createStatus = this.projectIsLoading()
      ? ProjectCreateStatus.Loading
      : ProjectCreateStatus.Created;
    this.props.onLeaveProject(this.props.projectHeader.id, createStatus);
  }

  @autobind
  private delete(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    const createStatus = this.projectIsLoading()
      ? ProjectCreateStatus.Loading
      : ProjectCreateStatus.Created;
    this.props.onRemoveConfirm(this.props.projectHeader.id, createStatus);
  }

  @autobind
  private onFullDumpProject(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onDumpProjectClick(this.props.projectHeader.id, 0);
  }

  @autobind
  private onLightDumpProject(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onDumpProjectClick(this.props.projectHeader.id, 1);
  }

  @autobind
  private onFakeDumpProject(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onDumpProjectClick(this.props.projectHeader.id, 2);
  }

  @autobind
  private onProjectNameEdit(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onProjectNameEdit(this.props.projectHeader.id);
  }

  @autobind
  private onDuplicateProjectClick(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onDuplicateProjectClick(this.props.projectHeader.id);
  }

  @autobind
  private moveProject(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onMoveProjectClick(this.props.projectHeader.id);
  }

  @autobind
  private saveAsTemplate(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onSaveAsTemplateClick(this.props.projectHeader.id);
    this.props.sendEvent(MetricNames.projects.createTemplateFromProject, { name: this.props.projectHeader.name });
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    currentUserId: state.account.id,
    companiesUsers: state.people.companiesUsers,
    company: state.account.selectedCompany,
    projectsViewType: state.persistedStorage.projectsViewType,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onDumpProjectClick: (projectId, quality) => dispatch(ProjectsActions.dump2dProject(projectId, quality)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const TwoDProjectsCardWrapper = connector(
  withAbilityContext(withAnalyticsContext(TwoDProjectsCardWrapperComponent)),
);
