import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  top: 0;
  background-color: ${p => p.theme.color.background}85;
  flex-direction: column;
  display: flex;
  align-items: center;
  > span {
    color: var(--day-background);
    text-align: center;
    letter-spacing: 0px;
    line-height: 21px;
    opacity: 1;
    color: ${p => p.theme.color.mainFont};
    font-size: 14px;
    max-width: 240px;
  }
  > svg {
    fill: ${p => p.theme.color.red};
    width: 20px;
    height: 20px;
    margin-bottom: 15px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
`;

export const Styled = {
  Container,
  ButtonContainer,
};

