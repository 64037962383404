import autobind from 'autobind-decorator';
import * as React from 'react';

import './doughnut-chart.scss';

import { KreoColors } from '../../enums/kreo-colors';

interface Props {
  size: number;
  strokeWidth: number;
  full: number;
  value: number;
  indicatorColor: string;
  secondaryColor?: string;
  valuePostfix?: string;
  className?: string;
}


export class DoughnutChart extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    secondaryColor: KreoColors.gray2,
    indicatorColor: '#F6AC46',
  };

  private canvas: HTMLCanvasElement = null;

  constructor(props: Props) {
    super(props);
  }


  public componentDidMount(): void {
    if (this.canvas) {
      this.draw();
    }
  }

  public render(): React.ReactNode {
    const { size } = this.props;
    return (
        <div className='doughnut-chart-container'>
          <canvas width={size} height={size} ref={this.saveCanvas}/>
          <span className='doughnut-chart-container__value'>
            {this.props.value} {this.props.valuePostfix}
          </span>
        </div>
    );
  }

  @autobind
  private saveCanvas(ref: HTMLCanvasElement): void {
    this.canvas = ref;
  }


  private draw(): void {
    const { size, strokeWidth, indicatorColor, value, full, secondaryColor } = this.props;
    const canvas = this.canvas.getContext('2d');
    const halfSize = size / 2;
    const radius = halfSize - (strokeWidth / 2);
    const tau = Math.PI * 2;
    const df =  tau * value / full;
    canvas.beginPath();
    canvas.lineWidth = strokeWidth;
    canvas.strokeStyle = indicatorColor;
    canvas.arc(halfSize, halfSize, radius, -Math.PI / 2, df - Math.PI / 2);
    canvas.stroke();

    const fmv = full - value;

    canvas.beginPath();
    canvas.strokeStyle = secondaryColor;
    canvas.arc(halfSize, halfSize, radius, df - Math.PI / 2, df - Math.PI / 2 + tau * fmv / full);
    canvas.stroke();
  }
}
