import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


interface Props {
  img: string;
  description: string;
}


export const  InfoBlock: React.FC<Props> = ({ img, description }: Props) => {
  return (
    <Styled.InfoBlock>
      <Styled.Img>
        <img src={`/static/img/${img}.png`} />
      </Styled.Img>
      <Styled.InfoText>
        <Text fontSize={14}>{description}</Text>
      </Styled.InfoText>
    </Styled.InfoBlock>
  );
};
