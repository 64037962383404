import { Text, Icons, DeleteButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { DrawingsActions } from 'common/components/drawings/actions/creators/common';
import { DrawingsGeometryGroupWithNesting } from 'common/components/drawings/interfaces';
import { RenderIf } from 'common/components/render-if';
import { useUndoRedoFullApi } from 'common/undo-redo';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { Styled } from './styled';

interface DispatchProps {
  unpinGroups: (groupIds: string[]) => void;
  pinGroups: (groupIds: string[]) => void;
}

interface OwnProps {
  pinnedGroups: DrawingsGeometryGroupWithNesting[];
  canEditMeasurement: boolean;
}

interface Props extends DispatchProps, OwnProps, AnalyticsProps {

}

const PinnedFoldersHeaderComponent: React.FC<Props> = (
  { unpinGroups, canEditMeasurement, pinnedGroups, sendEvent, pinGroups },
) => {
  const { addUndoRedo } = useUndoRedoFullApi();
  const unpinGroupsCallback = useCallback(
    () => {
      sendEvent(MetricNames.measureManager.unpinAllFolders);
      const groupIds = pinnedGroups.map(x => x.id);
      const redo = (): void => unpinGroups(groupIds);
      const undo = (): void => pinGroups(groupIds);
      addUndoRedo(undo, redo);
      redo();
    },
    [pinnedGroups]);
  return (
    <Styled.Container>
      <Styled.PinIcon>
        <Icons.SmallPin />
      </Styled.PinIcon>
      <Text
        fontSize={10}
        textTransform={'uppercase'}
      >
        Pinned groups
      </Text>
      <RenderIf condition={canEditMeasurement}>
        <DeleteButton onClick={unpinGroupsCallback}/>
      </RenderIf>
    </Styled.Container>
  );
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    unpinGroups: (groupIds) => dispatch(DrawingsActions.unpinGroups(groupIds)),
    pinGroups: (groupIds) => dispatch(DrawingsActions.pinGroups(groupIds)),
  };
}

export const PinnedFoldersHeader =
  connect(null, mapDispatchToProps)(withAnalyticsContext(PinnedFoldersHeaderComponent));
