import * as Ag from 'ag-grid-community';


export class NameTextareaCellRenderer implements Ag.ICellRendererComp {
  private eDiv: HTMLDivElement;

  public init(params?: Ag.ICellRendererParams): void | Ag.AgPromise<void> {
    this.eDiv = document.createElement('div');
    this.eDiv.style.width = '100%';
    this.eDiv.style.height = '100%';
    this.eDiv.style.minHeight = '32px';
    this.eDiv.style.wordBreak = 'break-word';

    this.eDiv.innerText = params.value !== undefined
      ? params.value
      : '';
  }

  public getGui(): HTMLElement {
    return this.eDiv;
  }

  public refresh(): boolean {
    return false;
  }
}
