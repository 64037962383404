import { RequestStatus } from 'common/enums/request-status';
import { Notification } from 'common/interfaces/realtime-messages';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { NotificationActionTypes } from './actions';
import { ReadNotification } from './actions/payloads';
import { NotificationReduxState } from './interfaces';
import { Alert } from './interfaces/alert';

export const notificationReducerMethods: ReducerMethods<NotificationReduxState> = {
  [NotificationActionTypes.LOAD_NOTIFICATIONS_REQUEST]: state => {
    return new MonoliteHelper(state).set(_ => _.loadStatus, RequestStatus.Loading).get();
  },
  [NotificationActionTypes.LOAD_NOTIFICATIONS_FAILED]: state => {
    return new MonoliteHelper(state).set(_ => _.loadStatus, RequestStatus.Failed).get();
  },
  [NotificationActionTypes.LOAD_NOTIFICATIONS_SUCCEEDED]: (state, payload: Notification[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.notifications, payload)
      .set(_ => _.loadStatus, RequestStatus.Loaded)
      .get();
  },
  [NotificationActionTypes.SHOW_NOTIFICATION_PANEL]: (state, payload: boolean) => {
    return new MonoliteHelper(state).set(_ => _.showBellNotifications, payload).get();
  },
  [NotificationActionTypes.READ_NOTIFICATION]: (state, payload: ReadNotification) => {
    const helper = new MonoliteHelper(state);
    return helper
      .setMap(
        _ => _.notifications,
        x => {
          if (payload.notificationIds.includes(x.id)) {
            return new MonoliteHelper(x).set(_ => _.isRead, payload.value).get();
          }

          return x;
        },
      )
      .get();
  },
  [NotificationActionTypes.ADD_NOTIFICATION]: (state, payload: Notification) => {
    if (payload.message && payload.id) {
      return new MonoliteHelper(state).setPrepend(_ => _.notifications, payload).get();
    }
    return state;
  },
  [NotificationActionTypes.DELETE_NOTIFICATION]: (state, notificationIds: number[]) => {
    return new MonoliteHelper(state)
      .setFilter(_ => _.notifications, x => !notificationIds.includes(x.id))
      .get();
  },
  [NotificationActionTypes.ADD_ALERT]: (state, alert: Alert) => {
    const stateUpdater = new MonoliteHelper(state);
    if (alert.message) {
      const alerts = state.alerts;
      alert.id = (alerts.length ? alerts[alerts.length - 1].id : 0) + 1;
      stateUpdater.setAppend(_ => _.alerts, alert);
    }
    return stateUpdater.get();
  },
  [NotificationActionTypes.DELETE_ALERT]: (state, alert: Alert) => {
    const stateUpdater = new MonoliteHelper(state);
    return stateUpdater.setFilter(_ => _.alerts, x => x.id !== alert.id).get();
  },
};
