import * as t from 'io-ts';

import { CodecUtils } from 'common/utils/codec-utils';
import { ModelCheckPriority } from '../../enums/mode-check-priority';
import { ModelCheckType } from '../../enums/model-check-type';

export const ModelCheckTypeC = CodecUtils
  .createEnumCodec<ModelCheckType>(ModelCheckType, 'ModelCheckType');
export const ModelCheckPriorityC = CodecUtils
  .createEnumCodec<ModelCheckPriority>(ModelCheckPriority, 'ModelCheckPriority');


export const ModelCheckAvailableCheckC = t.strict(
  {
    name: t.string,
    priority: ModelCheckPriorityC,
    type: ModelCheckTypeC,
    order: t.number,
  },
  'ModelCheckAvailableCheckC',
);

export const ModelCheckAvailableChecksC = t.array(
  ModelCheckAvailableCheckC,
);


export type ModelCheckAvailableCheck = t.TypeOf<typeof ModelCheckAvailableCheckC>;
