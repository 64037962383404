import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { BadgeStyled } from '../badge';
import { Text } from '../text';

const MENU_ITEM_SIZING = {
  m: { height: 40, padding: 15 },
  mm: { height: 50, padding: 20 },
  l: { height: 60, padding: 20 },
};

function setSvgColor(color: any): FlattenSimpleInterpolation {
  return css`
    fill: ${color};
    color: ${color};
  `;
}

const Item = styled.div<{ size: string, disabled: boolean, withBorder: boolean, isBadge: boolean }>`
  position: relative;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: ${p => p.isBadge ? 'flex-start' : 'space-between'};
  gap: ${p => p.isBadge && '10px'};
  align-items: center;
  height: ${p => MENU_ITEM_SIZING[p.size].height}px;
  cursor: ${p => p.disabled ? 'auto' : 'pointer'};
  pointer-events: ${p => p.disabled ? 'none' : 'auto'};

  :not(:first-child) {
    border-width: 0;
    border-top-color: ${p => p.theme.color.background};
    border-style: solid;
    border-top-width: ${p => p.withBorder ? 1 : 0}px;
  }

  &:hover {
    background-color: ${p => p.theme.color.pale};
    border-radius: 15px;
    ${Text} {
      color: ${p => p.theme.color[p.disabled ? 'disabled' : 'turquoise']};
    }

    svg {
      ${p => setSvgColor(p.theme.color[p.disabled ? 'disabled' : 'turquoise'])};
    }

    ${BadgeStyled.BadgeContainer} {
      > svg {
        fill: ${p => p.theme.color.white};
      }

      ${Text} {
        color: ${p => p.theme.color.white};
      }
    }
  }

  svg {
    height: 20px;
    ${() => setSvgColor(p => p.disabled
    ? p.theme.color.disabled
    :  p.iconColor
      ? p.iconColor
      : p.theme.color.gray)}
    margin-right: ${p => MENU_ITEM_SIZING[p.size].padding}px;
  }

  ${Text}, svg {
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  }

  img {
    height: 30px;
    width: 30px;
    margin-right: 20px;
  }

  ${BadgeStyled.BadgeContainer} {
    width: 30px;

    > svg {
      margin-right: 0;
      fill: ${p => p.theme.color.white};
    }
  }
`;

const IconWithText = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
`;

const SubText = styled.span<{ isBadge: boolean }>`
  display: ${p => p.isBadge ? 'none' : 'block'};
  margin-left: auto;
  margin-right: 20px;
`;

const TooltipContainer = styled.div`
  z-index: 1;
  position: relative;
  height: 15px;
  display: flex;

  svg {
    height: 15px;
  }
`;

export const Styled = {
  Item,
  IconWithText,
  SubText,
  TooltipContainer,
};
