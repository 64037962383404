import * as t from 'io-ts';

export const DrawingsGroupTemplateC = t.type(
  {
    id: t.number,
    name: t.string,
    isDefault: t.boolean,
    isPublic: t.boolean,
    ownerId: t.string,
  },
  'DrawingsGroupTemplate');

export const DrawingsGroupTemplatesC = t.array(DrawingsGroupTemplateC);

export type DrawingsGroupTemplate = t.TypeOf<typeof DrawingsGroupTemplateC>;

export interface GroupTemplateShortInfo {
  name: string;
  isPublic: boolean;
  isDefault: boolean;
}
