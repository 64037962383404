import * as t from 'io-ts';

export const RevisionInfoModelC = t.strict(
  {
    id: t.number,
    name: t.string,
  },
  'RevisionInfoModel');

export const RevisionInfoModelsC = t.array(RevisionInfoModelC);

export type RevisionInfoModel = t.TypeOf<typeof RevisionInfoModelC>;
