import _ from 'lodash';
import { SagaIterator } from 'redux-saga';
import { all, call, CallEffect, put, select, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { NumberDictionary, StringDictionary } from 'common/interfaces/dictionary';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { AppUrls } from '../../../routes/app-urls';
import { NotificationActions } from '../../notifications/actions';
import { AlertType } from '../../notifications/alert-type';
import { ClassificationActions } from '../actions/creators/classification';
import {
  ClassificationElementTypeInfo,
  ClassificationNodeActionBasePayload,
  ClassificationSelectVariantFromTreePayload,
} from '../actions/payloads/classification';
import { ValidationStepStatisticDataPayload } from '../actions/payloads/validation';
import { ClassificationActionTypes } from '../actions/types/classification';
import { ClassificationApi } from '../api/classification';
import { ViewerApi } from '../api/viewer';
import { ClassificationConstants } from '../constants/classification';
import {
  ClassificationAssignAssemblyRequestBody,
  ClassificationAssignSimpleRequestBody,
} from '../interfaces/classification/classification-api-requests';
import { ClassificationReduxState } from '../interfaces/classification/classification-redux-state';
import { ClassificationComparisonPatternGroup } from '../interfaces/classification/comparison-pattern-group';
import {
  ClassificationElementTypeComparisonPattern,
} from '../interfaces/classification/element-type-comparison-pattern';
import {
  ClassificationOntologyGraphResponse,
  ClassificationOntologyNode,
  ClassificationSaveGraphPayload,
} from '../interfaces/classification/ontology-data';
import { UniClassCategories } from '../interfaces/classification/uniclass-categories';
import { ClassificationUtils } from '../utils/classification-utils';


function* getClassificationStatistic(): SagaIterator {
  try {
    const statistic: ValidationStepStatisticDataPayload = yield call(ClassificationApi.getStatistic);
    yield put(ClassificationActions.getStatisticSuccess(statistic));
  } catch (error) {
    console.error('classification: get classification statistic failed', error);
  }
}

function* loadUniclassTrees(): SagaIterator {
  try {
    const categories: UniClassCategories = yield call(ClassificationApi.getUniClassCategories);
    yield put(ClassificationActions.saveUniclassData(categories));
  } catch (error) {
    console.error('classification: load uniclass trees failed', error);
  }
}

function* loadModelData({ payload: projectIdString }: ActionWith<string>): SagaIterator {
  try {
    const [data, groups] = yield all([
      call(ViewerApi.loadFullBimInfo),
      call(ClassificationApi.getClassificationGroups),
    ]);
    yield put(
      ClassificationActions.loadBimInfoSuccess(ClassificationUtils.processRevitTree(data, groups)),
    );
    yield call(loadUniclassTrees);
  } catch (error) {
    console.error('classification: load model data failed', error, { projectIdString });
  }
}

interface ClassificationStateWithInfoForRequests {
  classificationState: ClassificationReduxState;
  projectId: number;
  companyId: number;
}

function getClassificationStateWithInfoForRequest(
  state: State,
): ClassificationStateWithInfoForRequests {
  return {
    classificationState: state.classification,
    projectId: state.projects.currentProject ? state.projects.currentProject.id : null,
    companyId: state.account.selectedCompany.id,
  };
}


function* saveUserClassification(): SagaIterator {
  try {
    const classificationState: ClassificationReduxState = yield selectWrapper(s => s.classification);
    const target = classificationState.classificationTarget;
    if (classificationState.isUniclass) {
      const elementClassification = classificationState.elementClassification;
      if (target.layers && target.layers.length > 0) {
        const targetClassification = elementClassification[0][0];
        const assemblyRequestData: ClassificationAssignAssemblyRequestBody = {
          assembly: target.rootChanged
            ? {
              uniSystemId: targetClassification.systemId,
              idList: target.ids,
              source: targetClassification.source,
            }
            : null,
          layers: target.changedLayers
            ? target.changedLayers.map(layer => {
              const idList = target.layers[layer].ids;
              const classification = elementClassification[layer + 1][0];
              return {
                idList,
                uniProductId: classification.productId,
                source: classification.source,
              };
            })
            : [],
        };
        yield call(ClassificationApi.setLayersClassification, assemblyRequestData);
        yield put(ClassificationActions.onHeaderBackClick());
      } else {
        const selectedUniItem =
          classificationState.elementClassification[0][
            classificationState.classificationSelectedIndex
          ];
        const objectRequestData: ClassificationAssignSimpleRequestBody = {
          bimHandleIds: target.ids,
          systemId: selectedUniItem.systemId,
          productId: selectedUniItem.productId,
          source: selectedUniItem.source,
        };
        yield call(ClassificationApi.setSimpleObjectClassification, objectRequestData);
      }
    } else {
      const graphs = new Array<ClassificationSaveGraphPayload>();
      for (let i = 0; i < classificationState.elementOntologyGraph.length; i++) {
        const {
          graph,
          uniclass: { systemCode, systemDescription, productCode, productDescription },
          isUndefined,
        } = classificationState.elementOntologyGraph[i];
        const uniclass = {
          code: `${systemCode};${productCode}`,
          description: `${systemDescription};${productDescription}`,
        };
        if (i === 0) {
          graphs[i] = {
            bimHandleIds: target.ids,
            graph,
            isUndefined,
            uniclass,
          };
          continue;
        } else {
          graphs[i] = {
            bimHandleIds: target.layers[i - 1].ids,
            graph,
            isUndefined,
            uniclass,
          };
        }
      }
      yield call(ClassificationApi.saveGraph, graphs);
    }
  } catch (error) {
    console.error('classification: save user classification failed', error);
  }
}

function* getCurrentClassification(bimHandleIds: number[], state: State): SagaIterator {
  try {
    const { router } = state;
    const { projectId }: ClassificationStateWithInfoForRequests
      = yield select(getClassificationStateWithInfoForRequest);
    const isView =
      AppUrls.plan.project.validation.viewClassification.url({
        projectId: projectId.toString(),
      }) === router.location.pathname;
    const loadGraphsEffect = call(ClassificationApi.getClassificationByMultipleIds, bimHandleIds);
    yield call(areGraphsUndefined, loadGraphsEffect, bimHandleIds, isView);
  } catch (error) {
    console.error('classification: get current classification failed', error);
  }
}


/**
 * mutates and saves graphs to state and returns true if set of graphs has one unclassificated graph
 * @param reloadGraphsCallEffect - must return dict with elementId as key and graph as value
 * @param bimHandleIds
 * @param isView
 */
function* areGraphsUndefined(
  reloadGraphsCallEffect: CallEffect,
  bimHandleIds: number[],
  isView: boolean,
): SagaIterator {
  try {
    const allClassification: NumberDictionary<ClassificationOntologyGraphResponse> = yield reloadGraphsCallEffect;
    const graphs = new Array<ClassificationOntologyGraphResponse>();
    let isUndefined = false;
    for (const id of bimHandleIds) {
      if (!Number.isInteger(id)) {
        continue;
      }
      const elementGraph = allClassification[id];
      if (elementGraph.isUndefined) {
        isUndefined = true;
      }
      elementGraph.isAugmentation = ClassificationUtils.prepareGraph(elementGraph.graph, isView);
      elementGraph.uniclass =
        elementGraph.uniclass && ClassificationUtils.splitUniclass(elementGraph.uniclass);
      graphs.push(elementGraph);
    }
    yield put(ClassificationActions.loadElementGraphSuccess(graphs));
    return isUndefined;
  } catch (error) {
    console.error('classification: are graphs undefined failed', error);
  }
}

function* getClassificationByBimHandleId(action: ActionWith<number>): SagaIterator {
  try {
    const state: State = yield select();
    const { classification } = state;
    let bimHandleId = 0;
    const isFiltered = ClassificationUtils.isTreeFiltered(classification.modelBrowserFilters);
    const treeElement = (isFiltered ? classification.modelBrowserFiltered : classification.modelTree)[action.payload];
    bimHandleId = classification.bimElements[treeElement.engineIds[0]].id;
    const ids = [bimHandleId];
    if (treeElement.layersId !== null) {
      const layers = classification.layerInfo[treeElement.layersId];
      for (const layer of layers) {
        ids.push(layer.ids[0]);
      }
    }
    yield call(getCurrentClassification, ids, state);
  } catch (error) {
    console.error('classification: get classification by bim handle id failed', error, action.payload);
  }
}


function* multipleEditLoadClassification(action: ActionWith<number>): SagaIterator {
  try {
    const state: State = yield select();
    const group = state.classification.currentSelectedElementTypesGroups[action.payload];
    const ids = [group.classificationTarget.ids[0]];
    for (const layer of group.classificationTarget.layers) {
      ids.push(layer.ids[0]);
    }

    yield call(getCurrentClassification, ids, state);
  } catch (error) {
    console.error('classification: multiple edit load classification failed', error, action.payload);
  }
}

function* removeNode({ payload }: ActionWith<ClassificationNodeActionBasePayload>): SagaIterator {
  try {
    const {
      classificationState,
    }: ClassificationStateWithInfoForRequests = yield selectWrapper(
      getClassificationStateWithInfoForRequest,
    );
    const graph = classificationState.elementOntologyGraph[payload.graphId].graph;
    const bimHandleGraphs: NumberDictionary<ClassificationOntologyNode> = {};
    const rootObjectBimHandleId = classificationState.classificationTarget.ids[0];
    const bimHandleIds = [rootObjectBimHandleId];
    const path = payload.nodeId.split('/');
    let currentNodes = graph.subnodes;
    for (let i = 0; i < path.length; i++) {
      if (i !== path.length - 1) {
        currentNodes = currentNodes[path[i]].subnodes;
      } else {
        currentNodes.splice(parseInt(path[i], 10), 1);
      }
    }
    const bimHandleId =
      payload.graphId > 0
        ? classificationState.classificationTarget.layers[payload.graphId - 1].ids[0]
        : rootObjectBimHandleId;
    const layerIdsSets = ClassificationUtils.createLayerIdsSets(classificationState.classificationTarget);
    bimHandleGraphs[bimHandleId] = graph;
    if (classificationState.elementOntologyGraph[0].graph.subnodes) {
      for (const rootSubnode of classificationState.elementOntologyGraph[0].graph.subnodes) {
        if (rootSubnode.canonicalRel === 'has_layer') {
          const layerId = parseInt(rootSubnode.layer, 10);
          const graphIndex = layerIdsSets.findIndex(x => x.has(layerId));
          bimHandleIds[graphIndex + 1] = layerId;
          if (graphIndex === payload.graphId - 1) {
            if (layerId !== bimHandleId) {
              delete bimHandleGraphs[bimHandleId];
              bimHandleGraphs[layerId] = graph;
            }
            bimHandleGraphs[classificationState.classificationTarget.ids[0]] =
              classificationState.elementOntologyGraph[0].graph;
          } else {
            if (graphIndex === -1) {
              console.error(`layer ${layerId} doesn't exist`);
            } else {
              bimHandleGraphs[layerId] =
                classificationState.elementOntologyGraph[graphIndex + 1].graph;
            }
          }
        }
      }
    }
    const updateEffect = call(ClassificationApi.selectVariant, bimHandleGraphs);
    if (!(yield call(areGraphsUndefined, updateEffect, bimHandleIds, false))) {
      const breadCrumb = ClassificationUtils.getLastBreadCrumb(classificationState.modelBrowserFilters);
      yield put(
        NotificationActions.addAlert({
          message: `<b>${breadCrumb.name}</b> is classified successfully. Please save result`,
          alertType: AlertType.Info,
        }),
      );
    }
  } catch (error) {
    console.error('classification: remove node failed', error, payload);
  }
}

function* toOldAssignment({ payload }: ActionWith<number>): SagaIterator {
  try {
    const classificationState: ClassificationReduxState = yield selectWrapper(s => s.classification);
    const isFiltered = ClassificationUtils.isTreeFiltered(classificationState.modelBrowserFilters);
    const item =
      (isFiltered ? classificationState.modelBrowserFiltered : classificationState.modelTree) [payload];
    const ids = [classificationState.bimElements[item.engineIds[0]].id];
    if (item.layersId !== null) {
      const layers = classificationState.layerInfo[item.layersId];
      for (const layer of layers) {
        ids.push(layer.ids[0]);
      }
    }
    const elementClassifications = yield call(ClassificationApi.getClassificationByElements, ids);
    const currentClassification = [];
    for (const id of ids) {
      currentClassification.push(
        ClassificationUtils.createCurrentElementClassification(
          elementClassifications[id],
          classificationState,
        ),
      );
    }
    yield put(ClassificationActions.saveCurrentClassifition(currentClassification));
  } catch (error) {
    console.error('classification: to old assignment failed', error, payload);
  }
}

function* copyClassification({ payload }: ActionWith<number>): SagaIterator {
  try {
    const classificationState: ClassificationReduxState = yield selectWrapper(s => s.classification);
    const isFiltered = ClassificationUtils.isTreeFiltered(classificationState.modelBrowserFilters);
    const item =
      (isFiltered ? classificationState.modelBrowserFiltered : classificationState.modelTree) [payload];
    const layers = classificationState.layerInfo[item.layersId]
      ? classificationState.layerInfo[item.layersId]
      : [];
    const layersInfo: StringDictionary<number[][]> = {};
    const layerIdMaterial: NumberDictionary<{ name: string, index: number }> = {};
    const ids = [classificationState.bimElements[item.engineIds[0]].id];
    for (const layerGroup of layers) {
      const id = layerGroup.ids[0];
      ids.push(id);
      const currentLayerKey = layerGroup.materials[0].name;
      layersInfo[currentLayerKey] = layersInfo[currentLayerKey] || [];
      layerIdMaterial[id] = {
        name: currentLayerKey,
        index: layersInfo[currentLayerKey].push(layerGroup.ids) - 1,
      };
    }
    const nodeComparisonData: ClassificationElementTypeComparisonPattern = {
      layersCount: layers.length,
      bimElementIds: classificationState.bimElements
        .slice(item.engineIds[0], item.engineIds[1])
        .map(x => x.id),
      layersInfo,
    };
    const allClassification: NumberDictionary<ClassificationOntologyGraphResponse> = yield call(
      ClassificationApi.getClassificationByMultipleIds,
      ids,
    );
    const rootObjectId = ids.shift();
    const rootObjectClassification = allClassification[rootObjectId];
    const classificationGroups: StringDictionary<ClassificationSaveGraphPayload> = {};
    classificationGroups[ClassificationConstants.rootObjectGraphKey] = {
      isUndefined: rootObjectClassification.isUndefined,
      bimHandleIds: [],
      graph: rootObjectClassification.graph,
      uniclass: rootObjectClassification.uniclass,
    };
    for (const id of ids) {
      const { name, index } = layerIdMaterial[id];
      const currentLayerClassification = allClassification[id];
      classificationGroups[`${name} ${index}`] = {
        isUndefined: currentLayerClassification.isUndefined,
        bimHandleIds: [],
        graph: currentLayerClassification.graph,
        uniclass: currentLayerClassification.uniclass,
      };
    }
    yield put(
      ClassificationActions.copyClassificationSuccess({
        pattern: nodeComparisonData,
        graphs: classificationGroups,
      }),
    );
  } catch (error) {
    console.error('classification: copy classification failed', error, payload);
  }
}

function* pasteClassification(): SagaIterator {
  try {
    const {
      classificationState,
    }: ClassificationStateWithInfoForRequests = yield selectWrapper(
      getClassificationStateWithInfoForRequest,
    );
    const isFiltered = ClassificationUtils.isTreeFiltered(classificationState.modelBrowserFilters);
    const currentTree = isFiltered ? classificationState.modelBrowserFiltered : classificationState.modelTree;
    const elementTypesForPastIterator = ClassificationUtils.getSelectedElementTypes(currentTree);
    const pattern = classificationState.elementTypeComparisonPattern;

    const comparedIndexes = new Array<ClassificationElementTypeInfo>();
    const notComaredIndexes = new Array<ClassificationElementTypeInfo>();
    const classificationGroups = _.cloneDeep(classificationState.graphsByKeys);

    for (const itemIndex of elementTypesForPastIterator) {
      const currentItem = currentTree[itemIndex];
      const itemLayers = classificationState.layerInfo[currentItem.layersId] || [];
      let compare = pattern.layersCount === itemLayers.length;
      const sourceId = classificationState.bimElements[currentItem.engineIds[0]].type;
      const elementTypeInfo: ClassificationElementTypeInfo = {
        filteredId: itemIndex,
        sourceId,
      };
      if (!compare) {
        notComaredIndexes.push(elementTypeInfo);
        continue;
      }
      const materialLayerIdsDictionary: StringDictionary<number[][]> = {};
      for (const layer of itemLayers) {
        const currentLayerKey = layer.materials[0].name;
        materialLayerIdsDictionary[currentLayerKey] =
          materialLayerIdsDictionary[currentLayerKey] || [];
        materialLayerIdsDictionary[currentLayerKey].push(layer.ids);
      }
      for (const [key, value] of Object.entries(pattern.layersInfo)) {
        if (
          !materialLayerIdsDictionary[key] ||
          materialLayerIdsDictionary[key].length !== value.length
        ) {
          compare = false;
          break;
        }
      }
      if (compare) {
        const items = classificationState.bimElements.slice(currentItem.engineIds[0], currentItem.engineIds[1]);
        classificationGroups[ClassificationConstants.rootObjectGraphKey].bimHandleIds =
          (classificationGroups[ClassificationConstants.rootObjectGraphKey].bimHandleIds || []);
        arrayUtils.extendArray(
          classificationGroups[ClassificationConstants.rootObjectGraphKey].bimHandleIds, items.map(x => x.id));
        for (const [key, value] of Object.entries(materialLayerIdsDictionary)) {
          for (let i = 0; i < value.length; i++) {
            const groupKey = `${key} ${i}`;
            classificationGroups[groupKey].bimHandleIds = classificationGroups[groupKey].bimHandleIds || [];
            arrayUtils.extendArray(classificationGroups[groupKey].bimHandleIds, value[i]);
          }
        }
        comparedIndexes.push(elementTypeInfo);
      } else {
        notComaredIndexes.push(elementTypeInfo);
      }
    }
    const classification = Object.values(classificationGroups);
    const isUndefined = !!classification.find(x => x.isUndefined);
    if (classification.length && classification[0].bimHandleIds.length) {
      yield call(ClassificationApi.saveGraph, classification);
    }
    yield put(
      ClassificationActions.pasteClassificationSuccess(
        notComaredIndexes,
        comparedIndexes,
        isUndefined,
      ),
    );
    yield put(KreoDialogActions.openDialog(ClassificationConstants.pasteResultDialogName));
  } catch (error) {
    console.error('classification: paste classification failed', error);
  }
}

function* tryStartMultipleEdit(): SagaIterator {
  try {
    const groups: ClassificationComparisonPatternGroup[] =
      yield selectWrapper(x => ClassificationUtils.groupSelectedElementTypes(x.classification));
    yield put(ClassificationActions.saveGrouping(groups));
    if (groups.length === 1) {
      const group = groups[0];
      if (group.elementTypes.length === 1) {
        yield put(ClassificationActions.toAssignment(group.elementTypes[0]));
      } else {
        yield put(ClassificationActions.editGroup(0));
      }
    }
  } catch (error) {
    console.error('classification: try start multiple edit failed', error);
  }
}

function* selectNodeVariant({ payload }: ActionWith<ClassificationSelectVariantFromTreePayload>): SagaIterator {
  try {
    const {
      classificationState,
    }: ClassificationStateWithInfoForRequests = yield selectWrapper(
      getClassificationStateWithInfoForRequest,
    );
    let graph = classificationState.elementOntologyGraph[payload.graphId].graph;
    const bimHandleGraphs: NumberDictionary<ClassificationOntologyNode> = {};
    const rootObjectBimHandleId = classificationState.classificationTarget.ids[0];
    const bimHandleId =
      payload.graphId > 0
        ? classificationState.classificationTarget.layers[payload.graphId - 1].ids[0]
        : rootObjectBimHandleId;
    const bimHandleIds = [rootObjectBimHandleId];
    if (payload.nodeId !== '-1') {
      const path = payload.nodeId.split('/');
      let currentNodes = graph.subnodes;
      for (let i = 0; i < path.length; i++) {
        const currentPath = path[i];
        if (i !== path.length - 1) {
          currentNodes = currentNodes[currentPath].subnodes;
        } else {
          currentNodes[currentPath] = _.merge(currentNodes[currentPath], payload.variant);
          currentNodes[currentPath].isSuggest = false;
        }
      }
      bimHandleGraphs[bimHandleId] = graph;
      bimHandleGraphs[rootObjectBimHandleId] = classificationState.elementOntologyGraph[0].graph;
    } else {
      graph = _.merge(graph, payload.variant);
      graph.subnodes = graph.subnodes && graph.subnodes.filter(x => x.canonicalRel === 'has_layer');
      bimHandleGraphs[bimHandleId] = graph;
    }
    const layerIdsSets = ClassificationUtils.createLayerIdsSets(classificationState.classificationTarget);
    if (classificationState.elementOntologyGraph[0].graph.subnodes) {
      for (const rootSubnode of classificationState.elementOntologyGraph[0].graph.subnodes) {
        if (rootSubnode.canonicalRel === 'has_layer') {
          const layerId = parseInt(rootSubnode.layer, 10);
          const graphIndex = layerIdsSets.findIndex(x => x.has(layerId));
          bimHandleIds[graphIndex + 1] = layerId;
          if (graphIndex === payload.graphId - 1) {
            if (layerId !== bimHandleId) {
              delete bimHandleGraphs[bimHandleId];
              bimHandleGraphs[layerId] = graph;
            }
            bimHandleGraphs[classificationState.classificationTarget.ids[0]] =
              classificationState.elementOntologyGraph[0].graph;
          } else {
            if (graphIndex === -1) {
              console.error(`layer ${layerId} doesn't exist`);
            } else {
              bimHandleGraphs[layerId] =
                classificationState.elementOntologyGraph[graphIndex + 1].graph;
            }
          }
        }
      }
    }
    const updateEffect = call(ClassificationApi.selectVariant, bimHandleGraphs);

    if (!(yield call(areGraphsUndefined, updateEffect, bimHandleIds, false))) {
      const breadCrumb = ClassificationUtils.getLastBreadCrumb(classificationState.modelBrowserFilters);
      yield put(
        NotificationActions.addAlert({
          message: `<b>${breadCrumb.name}</b> is classified successfully. Please save result`,
          alertType: AlertType.Info,
        }),
      );
    }
  } catch (error) {
    console.error('classification: select node variant failed', error, payload);
  }
}

export function* classificationSagas(): SagaIterator {
  yield takeLatest(ClassificationActionTypes.LOAD_DATA, loadModelData);
  yield takeLatest(ClassificationActionTypes.ASSIGN_CLASSIFICATION, saveUserClassification);
  yield takeLatest(ClassificationActionTypes.GET_STATISTIC_REQUEST, getClassificationStatistic);
  yield takeLatest(ClassificationActionTypes.TO_ASSIGNMENT, getClassificationByBimHandleId);
  yield takeLatest(ClassificationActionTypes.TO_OLD_ASSIGNMENT, toOldAssignment);
  yield takeLatest(ClassificationActionTypes.REMOVE_NODE, removeNode);
  yield takeLatest(ClassificationActionTypes.PASTE_CLASSIFICATION, pasteClassification);
  yield takeLatest(ClassificationActionTypes.COPY_CLASSIFICATION, copyClassification);
  yield takeLatest(ClassificationActionTypes.EDIT_GROUP, multipleEditLoadClassification);
  yield takeLatest(ClassificationActionTypes.TRY_START_MULTIPLE_EDIT, tryStartMultipleEdit);
  yield takeLatest(ClassificationActionTypes.SELECT_NODE_VARIANT, selectNodeVariant);
}
