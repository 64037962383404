import { PaneConfig } from './interfaces';
import { Utils } from './utils';

const correctOffset = (
  startOffset: number,
  panes: PaneConfig[],
  isLimitationExist: (pane: PaneConfig) => boolean,
  isSizeCorrect: (pane: PaneConfig, newSize: number) => boolean,
  getNewOffset: (pane: PaneConfig, newSize: number) => number,
): number => {
  let offset = startOffset;
  for (let i = 0; i < panes.length; i++) {
    const pane = panes[i];
    if (!isLimitationExist(pane)) {
      break;
    }

    const newPaneSize = pane.size + offset;
    if (isSizeCorrect(pane, newPaneSize)) {
      break;
    }

    offset = getNewOffset(pane, newPaneSize);

    if (i === panes.length - 1) {
      offset = Math.abs(startOffset) - Math.abs(offset);
    }
  }

  return Math.abs(offset);
};

const correctDecrease = (offset: number, panes: PaneConfig[]): number => correctOffset(
  -offset,
  panes,
  Utils.isDecreaseConditionExist,
  Utils.isDecreaseSizeCorrect,
  Utils.getNewDecreaseOffset,
);

const correctIncrease = (offset: number, panes: PaneConfig[]): number => correctOffset(
  offset,
  panes,
  Utils.isIncreaseConditionExist,
  Utils.isIncreaseSizeCorrect,
  Utils.getNewIncreaseOffset,
);

export const getCorrectedOffset = (offset: number, partIncrease: PaneConfig[], partDecrease: PaneConfig[]): number => {
  let correctedOffset = correctIncrease(offset, partIncrease);
  correctedOffset = correctDecrease(correctedOffset, partDecrease);

  return correctedOffset;
};
