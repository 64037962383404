import { useMemo } from 'react';

import { isCommentData } from 'unit-2d-notification/store-slice/helpers';
import { CompanyNotification } from 'unit-2d-notification/store-slice/interfaces';
import { AccountApi } from '../../../../account/api';
import { Person } from '../../../../people/interfaces/person';
import { getGoToAction } from '../helpers/get-go-to-action';
import { getText } from '../helpers/get-text';
import { NotificationData } from '../interface';

const getUserName = (person: Person): string => {
  if (!person) {
    return 'undefined undefined';
  }
  if (person.firstName || person.lastName) {
    return `${person.firstName} ${person.lastName}`;
  }
  return person.email;
};

const mapModelToData = (
  model: CompanyNotification,
  lastAccess: Date,
  openProject: (projectId: string) => void,
  persons: Person[],
): NotificationData => {
  const user = persons.find(p => p.id === model.initiatorId);
  const text = getText(model);
  const goToAction = getGoToAction(model, openProject);
  return {
    id: model.id,
    userName: getUserName(user),
    avatarLink: AccountApi.getAvatarLink({ id: user?.id, hasAvatar: user?.hasAvatar }),
    isHightLight: !lastAccess || model.createAt > lastAccess,
    date: model.createAt,
    projectName: model.project.name,
    isDeleted: model.project.isDeleted,
    accessReason: model.project.accessReason,
    text,
    projectId: isCommentData(model.data) ? model.data?.projectId : null,
    goTo: goToAction,
  };
};

export const useNotificationMemo = (
  notificationsModel: CompanyNotification[],
  lastAccess: Date,
  openProject: (projectId: string) => void,
  persons: Person[],
): [NotificationData[]] => {
  const notifications = useMemo(() => {
    return notificationsModel.map(n => mapModelToData(n, lastAccess, openProject, persons));
  }, [persons, notificationsModel]);

  return [notifications];
};
