import { useCallback } from 'react';

import { Assembly, Item } from 'unit-2d-database/interfaces';
import { AssemblyUpdater } from '../helpers';
import { MeasureForm, MeasurePanelType } from '../interfaces';

type OpenAssemblyPanelCallback = (
  assemblyForm: MeasureForm,
  itemsDatabase: Item[],
  openSidePanel: (type: MeasurePanelType) => void,
  setAssemblyForm: (assembly: Assembly) => void,
  closePanel: () => void,
) => (name: string) => void;

export const useOpenAssemblyPanelCallback: OpenAssemblyPanelCallback = (
  form,
  itemsDatabase,
  openSidePanel,
  setForm,
  closePanel,
) => {
  return useCallback((name) => {
    const asignAssembly = AssemblyUpdater.getData(form.assemblyGroupForm)
      .find(a => a.name === name);
    if (asignAssembly) {
      const baseItemsMap = {};
      itemsDatabase.forEach(i => baseItemsMap[i.name] = i);
      const items = asignAssembly.items.map(i => ({
        ...i,
        baseItemId: baseItemsMap[i.name]?.id || i.name,
        id: i.name,
      }));
      const assemblySidePanelPayload = { ...asignAssembly, items };
      openSidePanel(MeasurePanelType.AssemblyMeasure);
      setForm(assemblySidePanelPayload);
      closePanel();
    }
  }, [form, itemsDatabase, openSidePanel, setForm, closePanel]);
};
