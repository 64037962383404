import { ModelManagementState } from '../interfaces/model-management/state';

export const MODEL_MANAGEMENT_INITIAL_STATE: ModelManagementState = {
  models: [],
  isLoading: false,
  isApplyFailed: false,
  temporaryModels: [],
  modelsForRemove: [],
  selectedFileIds: [],
};
