import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { useCurrentProjectFullScreen } from 'persisted-storage/hooks';
import { TwoDFullScreenMode } from 'persisted-storage/interfaces/state';
import { DrawingsActions } from '../actions/creators/common';

export function useOpenEngineIfNeeded(): () => void {
  const [ fullscreenMode, setFullScreenMode ] = useCurrentProjectFullScreen();
  const isReportFullscreen = fullscreenMode === TwoDFullScreenMode.Report;
  const is3d = useSelector<State, boolean>(s => s.drawings.is3d);
  const dispatch = useDispatch();


  return useCallback(() => {
    if (isReportFullscreen) {
      setFullScreenMode(undefined);
    }
    if (is3d) {
      dispatch(DrawingsActions.toggle3d());
    }
  }, [isReportFullscreen, is3d]);
}
