import { MenuItemStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const ContextMenuItem = styled.div`
  min-width: 240px;

  &:not(:first-child) {
    border-top: 1px solid ${p => p.theme.color.background};
  }

  > div {
    pointer-events: all;
  }

  ${MenuItemStyled.TooltipContainer} {
    height: 20px;
    > svg {
      height: 20px;
    }
  }
`;

export const Styled = {
  ContextMenuItem,
};
