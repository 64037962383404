import * as t from 'io-ts';

import { CostEstimateGroupingType, CostEstimateGroupingTypeC } from '../../enums/cost-estimate-grouping-type';

// recursive types cannot be inferred by TypeScript, so we have to declare interface manually
export interface CostsTreeNodeVm {
  calculationId: number;
  groupingType: CostEstimateGroupingType;
  duration: number;
  name: string;
  offSiUnit?: string;
  isSubcontractor: boolean;
  id: number;
  familyGuid: string;
  ids?: number[];
  workpackageId: number;
  childrens?: CostsTreeNodeVm[];
  isIndirectsCosts: boolean;
  amountInOffSiUnit?: number;
  quantity?: number;
  levelOfNesting?: number;
  laborCost?: number;
  laborCostPerSiUnit?: number;
  laborCostPerOffSiUnit?: number;
  plantCost?: number;
  plantCostPerSiUnit?: number;
  plantCostPerOffSiUnit?: number;
  materialCost?: number;
  materialCostPerSiUnit?: number;
  materialCostPerOffSiUnit?: number;
  directCost?: number;
  directCostPerSiUnit?: number;
  directCostPerOffSiUnit?: number;
  extraPaymentsPercentage?: number;
  totalCost?: number;
  totalCostPerOffSiUnit?: number;
}

export const CostsTreeNodeVmC =
  t.recursion<CostsTreeNodeVm>(
    'CostsTreeNodeVm',
    (self) => t.intersection(
      [
        t.type({
          calculationId: t.number,
          groupingType: CostEstimateGroupingTypeC,
          duration: t.number,
          name: t.string,
          isSubcontractor: t.boolean,
          id: t.number,
          familyGuid: t.string,
          workpackageId: t.number,
          isIndirectsCosts: t.boolean,
        }),
        t.partial({
          ids: t.array(t.number),
          offSiUnit: t.string,
          childrens: t.array(self),
          amountInOffSiUnit: t.number,
          quantity: t.number,
          levelOfNesting: t.number,
          laborCost: t.number,
          laborCostPerSiUnit: t.number,
          laborCostPerOffSiUnit: t.number,
          plantCost: t.number,
          plantCostPerSiUnit: t.number,
          plantCostPerOffSiUnit: t.number,
          materialCost: t.number,
          materialCostPerSiUnit: t.number,
          materialCostPerOffSiUnit: t.number,
          directCost: t.number,
          directCostPerSiUnit: t.number,
          directCostPerOffSiUnit: t.number,
          extraPaymentsPercentage: t.number,
          totalCost: t.number,
          totalCostPerOffSiUnit: t.number,
        }),
      ]));
