import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import {
  FormWithProjectAndReportIdsPayload,
  ProjectAndReportIdsPayload,
} from '../actions/payloads/quantity-take-off-report';
import { QuantityTakeOffReportActionTypes } from '../actions/types/quantity-take-off-report';
import { QtoGenerateReportStatus } from '../enums/qto-generate-report-status';
import { QtoReportInfoForm } from '../interfaces/quantity-take-off';
import { QtoReport } from '../interfaces/quantity-take-off/quantity-take-off-report';
import { QtoReportSetting } from '../interfaces/quantity-take-off/quantity-take-off-report-generator';
import { QtoReportInfo } from '../interfaces/quantity-take-off/quantity-take-off-report-info';
import { QuantityTakeOffState } from '../interfaces/quantity-take-off/quantity-take-off-state';
import { customFiltersReducerMethods } from './custom-filters';
import { quantityTakeOffFilterReducerMethods } from './quantity-take-off-filter';

export const quantityTakeOffReportReducerMethods: ReducerMethods<QuantityTakeOffState> = {
  [QuantityTakeOffReportActionTypes.GET_REPORTS_INFO]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.statuses.loadReportsInfo, RequestStatus.Loading)
      .get();
  },
  [QuantityTakeOffReportActionTypes.SET_REPORTS_INFO]: (s, models: QtoReportInfo[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.reportsInfo, models)
      .set(_ => _.report.statuses.loadReportsInfo, RequestStatus.Loaded)
      .get();
  },
  [QuantityTakeOffReportActionTypes.SET_SELECTED_REPORT_ID]: (s, id: number) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.selectedReportId, id)
      .get();
  },
  [QuantityTakeOffReportActionTypes.SET_REPORTS_FOR_COPY]: (s, reports: any) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.reportsForCopy, reports)
      .get();
  },
  [QuantityTakeOffReportActionTypes.GET_REPORT]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.statuses.loadReport, RequestStatus.Loading)
      .get();
  },
  [QuantityTakeOffReportActionTypes.SET_REPORT]: (s, report: QtoReport) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.isSync, true)
      .set(_ => _.report.reportModel, report)
      .set(_ => _.report.statuses.loadReport, RequestStatus.Loaded)
      .get();
  },
  [QuantityTakeOffReportActionTypes.SET_AVAILABLE_REPORT_GENERATORS]: (s, generators: QtoReportSetting[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.availableReportGenerators, generators)
      .get();
  },
  [QuantityTakeOffReportActionTypes.DELETE_REPORT]: (s, { reportId }: ProjectAndReportIdsPayload) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.reportModel, null)
      .set(_ => _.report.selectedReportId, null)
      .set(_ => _.report.statuses.loadReport, RequestStatus.NotRequested)
      .setFilter(_ => _.report.reportsInfo, (x) => x.id !== reportId)
      .get();
  },
  [QuantityTakeOffReportActionTypes.UPDATE_REPORT_QUEUE_MESSAGE_FAILED]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.isSync, false)
      .get();
  },
  [QuantityTakeOffReportActionTypes.UPDATE_REPORT_INFO]:
    (s, payload: FormWithProjectAndReportIdsPayload<QtoReportInfoForm>) => {
      return new MonoliteHelper(s)
        .set(_ => _.report.reportModel.showDiff, payload.form.showDiff)
        .get();
    },
  [QuantityTakeOffReportActionTypes.UPDATE_REPORT_AFTER_CREATION_VIA_TEMPLATE]:
    (s, model: QtoReportInfo) => {
      return new MonoliteHelper(s)
        .set(_ => _.report.selectedReportId, model.id)
        .set(_ => _.report.reportsInfo, [...s.report.reportsInfo, model])
        .get();
    },
  [QuantityTakeOffReportActionTypes.GENERATE_DEFAULT_REPORT]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.reportCreationStatus, QtoGenerateReportStatus.Generating)
      .get();
  },
  [QuantityTakeOffReportActionTypes.COPY_REPORT]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.reportCreationStatus, QtoGenerateReportStatus.Copying)
      .get();
  },
  [QuantityTakeOffReportActionTypes.CREATE_REPORT_FINISH]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.report.reportCreationStatus, QtoGenerateReportStatus.NoActions)
      .get();
  },
  ...quantityTakeOffFilterReducerMethods,
  ...customFiltersReducerMethods,
};

