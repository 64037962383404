import React from 'react';

import { DrawingsDrawMode } from '../../enums';
import { useStartBooleanOperation } from '../../hooks';
import { DrawingsSelectAggregationGroup } from '../../interfaces';
import { DrawingsBooleanOperationPanel } from './drawings-boolean-operation-panel';
import { useCanEnclose, useCanOpenPolygon } from './hooks';

interface Props {
  canEditMeasurement: boolean;
  instancesIds: string[];
  canJoinCount: boolean;
  canJoinPolyline: boolean;
  canBoolWithPolygons: boolean;
  doActionAndClose: (action: (...args: any) => void, ...args: any) => void;
}

const DrawingsContextMenuOptionsPanelComponent: React.FC<Props> = ({
  canBoolWithPolygons,
  canEditMeasurement,
  canJoinCount,
  canJoinPolyline,
  instancesIds,
  doActionAndClose,
}) => {
  const canEnclose = useCanEnclose(instancesIds);
  const subtract = useStartBooleanOperation(
    instancesIds,
    DrawingsSelectAggregationGroup.Area,
    DrawingsDrawMode.Subtract,
  );
  const unite = useStartBooleanOperation(instancesIds, DrawingsSelectAggregationGroup.Area, DrawingsDrawMode.Union);
  const enclose = useStartBooleanOperation(instancesIds, DrawingsSelectAggregationGroup.Line, DrawingsDrawMode.Enclose);
  const joinCount = useStartBooleanOperation(instancesIds, DrawingsSelectAggregationGroup.Count, DrawingsDrawMode.Join);
  const joinLine = useStartBooleanOperation(instancesIds, DrawingsSelectAggregationGroup.Line, DrawingsDrawMode.Join);
  const splitPolyline = useStartBooleanOperation(
    instancesIds,
    DrawingsSelectAggregationGroup.Line,
    DrawingsDrawMode.SplitPolyline,
  );
  const openPolygon = useStartBooleanOperation(
    instancesIds,
    DrawingsSelectAggregationGroup.Area,
    DrawingsDrawMode.OpenPolygon,
  );
  const canOpenPolygon = useCanOpenPolygon(instancesIds);


  if (!canEditMeasurement) {
    return <></>;
  }
  return (
    <DrawingsBooleanOperationPanel
      unite={unite}
      subtract={subtract}
      booleanOperationEnabled={canBoolWithPolygons}
      encloseEnabled={canEnclose}
      enclose={enclose}
      splitPolyline={splitPolyline}
      joinCountEnabled={canJoinCount}
      joinPolylineEnabled={canJoinPolyline}
      joinCount={joinCount}
      joinPolyline={joinLine}
      openPolygon={openPolygon}
      doActionAndClose={doActionAndClose}
      openPolygonEnabled={canOpenPolygon}
    />
  );
};

export const DrawingsContextMenuOptionsPanel = React.memo(DrawingsContextMenuOptionsPanelComponent);
