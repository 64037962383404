import React, { useCallback } from 'react';

import { AutoScaleButton } from 'common/components/drawings/drawings-calibrate/auto-scale-button';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useCurrentDrawingInfo, useDrawModeApi } from 'common/components/drawings/hooks';
import { DrawingsUtils } from 'common/components/drawings/utils/drawings-utils';
import { Paper } from 'common/components/paper';
import { RenderIf } from 'common/components/render-if';
import { Mood } from 'common/enums/mood';
import { Menu } from 'unit-2d-info-panel/constants/menus';
import { useOpenMenu } from 'unit-2d-info-panel/hooks/use-open-menu';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { ScaleNoApplied } from './scale-no-applied';
import { Styled } from './styled';

const ScaleMenuItemComponent: React.FC = () => {
  const currentDrawing = useCurrentDrawingInfo();

  const openScaleMenu = useOpenMenu(Menu.Scale);
  const { setDrawMode } = useDrawModeApi();
  const startCalibrate = useCallback(() => {
    setDrawMode(DrawingsDrawMode.Calibrate);
    openScaleMenu();
  }, [setDrawMode]);

  const isCalibrated = currentDrawing && DrawingsUtils.isCalibrated(currentDrawing);

  if (!currentDrawing || isCalibrated) {
    return null;
  }

  return (
    <Styled.Container>
      <Paper mood={Mood.Negative}>
        <ScaleNoApplied startCalibrate={startCalibrate}/>
      </Paper>
      <RenderIf condition={currentDrawing?.scaleRecognitionStatus === ViewModelStatus.Ready}>
        <AutoScaleButton currentDrawing={currentDrawing} />
      </RenderIf>
    </Styled.Container>
  );
};

export const ScaleMenuItem = React.memo(ScaleMenuItemComponent);
