import * as React from 'react';

import './activities-list.scss';

// eslint-disable-next-line import/named
import { WorkInfo } from '../../../../../../components/engine/KreoEngine';
import { ChartDataProvider } from '../../../../utils/gantt-chart';
import { ActivityCard } from '../activity-card';


interface ListProps {
  activities: WorkInfo[];
  dataProvider: ChartDataProvider;
  selectedElementsIds: number[];
  updateCurrentMoment: (currentMoment: number, force?: boolean) => void;
  selectElementOnEngine: (bimId: number) => void;
}

export class ActivitiesList extends React.Component<ListProps> {
  public render(): React.ReactNode {
    return (
      <div
        className='activities-list'
      >
        {
          this.props.activities && this.props.activities.length > 0 &&
          this.props.activities.map((activity) => {
            const belongsToSelectedElement = this.props.selectedElementsIds.includes(activity.bimId);
            return (
              <ActivityCard
                key={activity.workId}
                activityId={activity.workId}
                bimId={activity.bimId}
                progress={activity.progress}
                dataProvider={this.props.dataProvider}
                belongsToSelectedElement={belongsToSelectedElement}
                updateCurrentMoment={this.props.updateCurrentMoment}
                selectElementOnEngine={this.props.selectElementOnEngine}
              />
            );
          })
        }
      </div>
    );
  }
}
