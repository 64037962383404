import autobind from 'autobind-decorator';
import * as React from 'react';

import './filter-select-option.scss';

import { ToggleArea } from 'common/components/toggle-area';
import { MaterialMenuItem, MaterialMenuItemProps } from 'common/UIKit';
import { activityTypes } from '../../constants';
import { FilterType } from '../../filter-type';
import { SimpleOption } from '../../interfaces';

interface Props extends MaterialMenuItemProps {
  option: SimpleOption;
  filterValue: React.ReactText[];
  type: FilterType;
  deleteFilterItem: (type: FilterType, optionId: React.ReactText) => void;
  addFilterItem: (type: FilterType, option: SimpleOption) => void;
}

export class FilterSelectOption extends React.Component<Props> {
  public render(): React.ReactNode {
    const { option, type } = this.props;
    const selected = this.isSelected();

    const styles =
      !selected && type === FilterType.Type && option.id !== null
        ? { backgroundColor: activityTypes[option.id].styles.backgroundColor }
        : {};

    return (
      <MaterialMenuItem
        className='activity-filter-select-option'
        value={option.id}
        onClick={this.onClickMenuItem}
        data-control-name={`filter-option-${option.id}`}
      >
        <ToggleArea
          onChange={this.onClickMenuItem}
          isSelected={selected}
          style={styles}
        >
          {option.name}
        </ToggleArea>
      </MaterialMenuItem>
    );
  }

  @autobind
  private onClickMenuItem(): void {
    const { option, type } = this.props;
    if (this.isSelected()) {
      if (this.isDefault()) {
        return;
      }
      this.props.deleteFilterItem(type, option.id);
    } else {
      this.props.addFilterItem(type, option);
    }
  }

  private isSelected(): boolean {
    const { option, filterValue } = this.props;
    return this.isDefault() ? filterValue.length === 0 : filterValue.includes(option.id);
  }

  private isDefault(): boolean {
    return this.props.option.id === null;
  }
}
