import * as React from 'react';

import { RenderIf } from 'common/components/render-if';
import { DrawingsDrawMode } from '../../enums';
import { DrawingsInstanceMeasure } from '../../interfaces';
import { DrawingsCommonUtils } from '../../utils/drawings-common-utils';
import { InstanceInformationContextWrapper } from '../instance-information';
import { Styled } from './styled';


interface Props {
  drawMode: DrawingsDrawMode;
  getInstancesMeasures: (instancesId: string[]) => DrawingsInstanceMeasure[];
}

const DrawingsTopControlGroupComponent: React.FC<Props> = ({ getInstancesMeasures, drawMode }) => {
  return (
    <Styled.Container>
      <Styled.Line>
        <RenderIf condition={DrawingsCommonUtils.isHoverEnabledDrawMode(drawMode)}>
          <InstanceInformationContextWrapper
            getInstancesMeasures={getInstancesMeasures}
          />
        </RenderIf>
      </Styled.Line>
    </Styled.Container>
  );
};


export const DrawingsTopControlGroup = React.memo(DrawingsTopControlGroupComponent);
