import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 15px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  svg {
    height: 20px;
  }
  > svg {
    fill: ${p => p.theme.color.turquoise};
  }
`;

const Title = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
`;


export const Styled = {
  Container,
  Title,
};

