import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconExitIsolateMode: React.FC = () => {
  return (
    <svg
      className='kreo-icon exit-isolate-mode'
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icon_isolate-mode-exit' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          // eslint-disable-next-line max-len
          d='M7,1 C3.6862915,1 1,3.6862915 1,7 C1,10.3137085 3.6862915,13 7,13 C10.3137085,13 13,10.3137085 13,7 C13,3.6862915 10.3137085,1 7,1 Z M7,0 C10.8659932,-7.10171439e-16 14,3.13400675 14,7 C14,10.8659932 10.8659932,14 7,14 C3.13400675,14 4.73447626e-16,10.8659932 0,7 C-4.73447626e-16,3.13400675 3.13400675,7.10171439e-16 7,0 Z'
          id='Rectangle-19'
          fill={KreoColors.f3}
          fillRule='nonzero'
        />
        <path
          // eslint-disable-next-line max-len
          d='M8.35355339,9.14644661 C8.54881554,9.34170876 8.54881554,9.65829124 8.35355339,9.85355339 C8.15829124,10.0488155 7.84170876,10.0488155 7.64644661,9.85355339 L5.14644661,7.35355339 C4.95118446,7.15829124 4.95118446,6.84170876 5.14644661,6.64644661 L7.64644661,4.14644661 C7.84170876,3.95118446 8.15829124,3.95118446 8.35355339,4.14644661 C8.54881554,4.34170876 8.54881554,4.65829124 8.35355339,4.85355339 L6.20710678,7 L8.35355339,9.14644661 Z'
          id='Rectangle'
          fill={KreoColors.f4}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
