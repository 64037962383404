import { arrayUtils } from 'common/utils/array-utils';
import { MentionConstants } from 'unit-2d-comments/constants';

function* mentionsIterator(text: string): Iterable<RegExpExecArray> {
  for (
    let match = MentionConstants.MentionRegex.exec(text);
    match;
    match = MentionConstants.MentionRegex.exec(text)
  ) {
    yield match;
  }
}

function getUserIdsSet(text: string): Set<string> {
  return new Set(arrayUtils.mapIterator(mentionsIterator(text), v => v[1]));
}


function getTextLength(text: string): number {
  const transformedText = text.replace(MentionConstants.MentionRegex, ' ');
  return transformedText.length;
}

export const MentionUtils = {
  mentionsIterator,
  getUserIdsSet,
  getTextLength,
};
