import { CompanyResourcesApi } from '../../../api/server';
import { ActivityVariantModel } from '../interfaces/data';
import { ActivityVariantForm } from '../interfaces/rest-data';


function getActivityVariant(
  databaseId: number,
  activityId: number,
  activityVariantId: number,
): Promise<ActivityVariantModel> {
  return CompanyResourcesApi.get(
    `/databases/${databaseId}/activities/${activityId}/variants/${activityVariantId}`,
    `api get database(${databaseId}) activity(${activityId}) variant(${activityVariantId}) error`,
  );
}

function updateActivityVariant(
  databaseId: number,
  activityId: number,
  activityVariantId: number,
  data: ActivityVariantForm,
): Promise<ActivityVariantModel> {
  return CompanyResourcesApi.put(
    `/databases/${databaseId}/activities/${activityId}/variants/${activityVariantId}`,
    data,
  );
}

function  createActivityVariant(
  databaseId: number,
  activityId: number,
  data: ActivityVariantForm,
): Promise<ActivityVariantModel> {
  return CompanyResourcesApi.post(
    `/databases/${databaseId}/activities/${activityId}/variants`,
    data,
  );
}

function deleteActivityVariant(databaseId: number, activityId: number, activityVariantId: number): Promise<{}> {
  return CompanyResourcesApi.delete(
    `/databases/${databaseId}/activities/${activityId}/variants/${activityVariantId}`,
    null,
  );
}


export const ActivityVariantsApi = {
  updateActivityVariant,
  createActivityVariant,
  deleteActivityVariant,
  getActivityVariant,
};
