import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { ProjectType } from 'common/constants/project-type';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { TwoDNavigation, ProjectMenuTab } from '../2d-navigation';
import { ProjectsHeader } from '../2d-projects-header';
import {
  CreateReportTemplateDialog,
  CREATE_2D_REPORT_TEMPLATE_DIAGLOG,
  InfoReportTemplateTable,
} from './components';
import { TwoDReportTemplateActions } from './store-slice';
import { Styled } from './styled';


interface PageStateProps {
  loadingStatus: RequestStatus;
  companyId: number;
}

interface DispatchProps {
  goToProjects: () => void;
  showDialog: (name: string) => void;
  createReportTemplate: () => void;
  closeDialog: () => void;
}

export interface ProjectHeaderSearchTextProps {
  searchPlaceholder: string;
  searchText: string;
}

type Props = RouteComponentProps<{}> & PageStateProps & DispatchProps & AbilityAwareProps;

class TwoDReportTemplatePageComponent extends React.PureComponent<Props> {
  public componentDidUpdate(): void {
    if (this.props.ability.cannot(Operation.Read, Subject.Takeoff2dReportTemplateShow)) {
      this.props.goToProjects();
    }
  }

  public render(): React.ReactNode {
    return (
      <TwoDNavigation
        pageTitle='Report Templats'
        isCreateButtonHide={false}
        activeTabIndex={ProjectMenuTab.ReportTemplate}
        createButtonText='Create Report Template'
        onClickCreateButton={this.openCreate2dProjectDialog}
      >
        <Styled.Container>
          <ProjectsHeader
            projectType={ProjectType.Project2dTemplate}
            showSearch={false}
            canAddPeople={true}
          />
          <InfoReportTemplateTable canEdit={true} />
        </Styled.Container>
        <RenderIf condition={this.props.loadingStatus === RequestStatus.Loading}>
          <Spinner show={true} />
        </RenderIf>
        <CreateReportTemplateDialog
          onSubmit={this.handleSubmit}
        />
      </TwoDNavigation>
    );
  }

  @autobind
  private handleSubmit(): void {
    this.props.createReportTemplate();
    this.props.closeDialog();
  }

  @autobind
  private openCreate2dProjectDialog(): void {
    this.props.showDialog(CREATE_2D_REPORT_TEMPLATE_DIAGLOG);
  }
}

const mapStateToProps = (state: State): PageStateProps => {
  const { requestStatus } = state.twoDReportTemplate;

  return {
    companyId: state.account.selectedCompany ? state.account.selectedCompany.id : null,
    loadingStatus: requestStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    goToProjects: () => dispatch(push(AppUrls.qto2d.listing.path)),
    showDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
    createReportTemplate: () => dispatch(TwoDReportTemplateActions.createTeplate()),
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(CREATE_2D_REPORT_TEMPLATE_DIAGLOG)),
  };
};

export const TwoDReportTemplatePage = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(TwoDReportTemplatePageComponent));
