
import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { OptionContainerStyled } from 'common/components/selection-menus';


const Container = styled.div`
  ${OptionContainerStyled.Container} {
    p {
      font-size: 12px;
      line-height: unset;
      color: ${Constants.Colors.GENERAL_COLORS.darkBlue};
    }
  }
  > div {
    gap: 15px;
  }
`;

export const Styled = {
  Container,
};
