import autobind from 'autobind-decorator';
import { uniq } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { CustomElementFilterViewModel } from 'common/interfaces/custom-element-filter-builder';
import { State } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { MapIdHelper } from '../../../components/engine/map-id-helper';
import { CustomFiltersActions } from '../../../units/projects/actions/creators/custom-filters-actions';
import { QtoRecord } from '../../../units/projects/components/quantity-take-off-left-panel/interfaces';
import { FilterPanelState } from '../../../units/projects/components/quantity-take-off-left-table-tab/use-save-state';
import { withPropertiesDataProviderApi } from '../../../units/projects/components/with-properties-data-provider-api';
import {
  GraphStorageRecordsConfig,
  RecordConfig,
} from '../../../units/projects/interfaces/graph-storage-records-config';
import {
  PropertiesDataProviderApi,
} from '../../../units/projects/interfaces/properties-provider-types';
import { QtoCustomFilterValueGetter } from '../../../units/projects/utils/quantity-take-off-custom-filter-value-getter';
import {
  CustomElementFilterHelper,
} from '../../../units/projects/utils/quantity-take-off-tree-table/custom-element-filter-helper';
import { FilterValue } from '../filter-select/interfaces';
import { CUSTOM_FILTER, EMPTY_VALUE, ENGINE_FILTER_KEY, REPORT_FILTER_KEY } from './constants';
import { CustomOptionConfig } from './custom-filter-map';
import { filterByCustomFilter } from './filter-by-custom-filter';
import { filterByReportData } from './filter-by-report-data';
import {
  FilterConfig,
  SelectedFilterValue,
  TreeFilterPanelState,
} from './interfaces';
import { TreeFilter } from './tree-filter';
import { customFilterConfig, engineFilterConfig, reportFilterConfig, handleFilterConfig } from './use-filter-config';
import { handleSaveState } from './use-save-state';


const ADDITIONAL_FILTERS_COUNT = 3;


interface ResetFilterConfig {
  value: boolean;
  callBack: (value: boolean) => void;
}

interface StateProps {
  callFilters: boolean;
  appliedCustomFilterModel: CustomElementFilterViewModel;
}

interface DispatchProps {
  resetAppliedCustomFilter: () => void;
}

interface Props extends StateProps, DispatchProps, PropertiesDataProviderApi {
  projectId: number;
  onFilter: (bimIds: number[], bimIdsWithoutEngineFilter: number[], value: boolean) => void;
  displayNames: GraphStorageRecordsConfig;
  resetFilter?: ResetFilterConfig;
  isLocation: boolean;
  filterName: string;
  records: QtoRecord[];
  useEngineFilter: boolean;
  disableEngineFilter: () => void;
  saveState: (filterPanelState: TreeFilterPanelState, selectedIds: number[]) => void;
  getSaveState: () => FilterPanelState;
  getReportElementIds: () => { nodeIds: Record<string, boolean>, duplicatedNodeIds: Record<string, boolean> };
  engineFilter: (id: number) => boolean;
  mapIdHelper: MapIdHelper;
}

class TreeFilterPanelComponents extends React.PureComponent<Props, TreeFilterPanelState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      filterConfigs: [],
      selectedValue: {},
      selectedDisplayName: {},
      lastSelectedFilterKey: null,
      defaultFilterOptions: [],
      customFilterFunction: this.getCustomFilterFunction(props),
    };
  }

  public async componentDidMount(): Promise<void> {
    const isSaveStateUsed = handleSaveState(this.updateState, this.props.getSaveState);
    if (!isSaveStateUsed) {
      handleFilterConfig(this.props.records, this.props.displayNames, this.updateState);
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: TreeFilterPanelState): void {
    if (prevProps.records !== this.props.records) {
      handleFilterConfig(this.props.records, this.props.displayNames, this.updateState);
    }

    if (prevProps.callFilters !== this.props.callFilters) {
      this.fetchFilteredValue();
    }
    this.onCustomFilterChange(prevProps, prevState);
    this.handleResetFilter(this.props.resetFilter);
    if (prevProps.useEngineFilter !== this.props.useEngineFilter) {
      this.onChangeSelectedValue(
        {
          key: engineFilterConfig.key,
          value: this.props.useEngineFilter ? engineFilterConfig.options : [],
        },
        true,
      );
    }
  }

  public render(): JSX.Element {
    return (
      <KreoScrollbars showShadowTop={true}>
        {
          this.state.filterConfigs.map(
            (filter) => (
              <TreeFilter
                key={filter.name}
                name={filter.name}
                filterConfig={filter}
                value={this.state.selectedDisplayName[filter.key] ? this.state.selectedDisplayName[filter.key] : []}
                onChangeSelectedValue={this.onChangeSelectedValue}
                optionContentRenderer={filter.optionsRender}
              />
            ),
          )
        }
      </KreoScrollbars>
    );
  }

  private handleResetFilter({ value, callBack }: ResetFilterConfig): void {
    if (value) {
      this.setState(
        { selectedValue: {}, lastSelectedFilterKey: null },
        () => {
          callBack(false);
          this.fetchFilteredValue();
        });
    }
  }

  @autobind
  private updateState(obj: Partial<TreeFilterPanelState>): void {
    this.setState(obj as TreeFilterPanelState);
  }

  private updateFiltersConfig(suggestions: Record<string, string[]>): FilterConfig[] {
    const selectValueLength = Object.keys(this.state.selectedValue).length;
    if (selectValueLength !== 0) {
      let oldFilterConfigs = this.state.filterConfigs;
      if (oldFilterConfigs.length === ADDITIONAL_FILTERS_COUNT
        && (
          oldFilterConfigs[0].key === reportFilterConfig.key
          || oldFilterConfigs[1].key === customFilterConfig.key
          || oldFilterConfigs[2].key === engineFilterConfig.key
        )
      ) {
        oldFilterConfigs = this.state.defaultFilterOptions;
      }
      const filterConfigs = oldFilterConfigs.map((filter) => {
        if (this.state.lastSelectedFilterKey === filter.key) {
          return filter;
        }

        if (
          filter.key === reportFilterConfig.key
          || filter.key === customFilterConfig.key
          || filter.key === engineFilterConfig.key
        ) {
          return filter;
        }

        const customConfig = CustomOptionConfig[filter.key];
        const optionsRender = customConfig && customConfig.optionsRender;
        let options = [];
        const suggest = suggestions[filter.key];
        if (suggest) {
          options = customConfig
            ? customConfig.optionsMap(
              suggestions[filter.key],
              (this.props.displayNames[filter.key] as RecordConfig).displayNames,
            )
            : suggestions[filter.key]
              .filter(value => value)
              .sort(arrayUtils.sortStringArrayWithEmptyValue)
              .map((value) => ({ name: value, value }));
        }

        return { ...filter, options, optionsRender };
      })
        .filter(filter => {
          const onlyUndefinedOption =
            filter.options.length === 1
            && filter.options[0].value.toLowerCase().includes(EMPTY_VALUE)
            && !this.state.selectedValue[filter.key];
          return filter.options.length > 0 && !onlyUndefinedOption;
        });
      return filterConfigs;
    }

    return this.state.defaultFilterOptions;
  }

  @autobind
  private onChangeSelectedValue(selectedValue: SelectedFilterValue, supressEngineFilterCheck: boolean = false): void {
    if (!supressEngineFilterCheck && selectedValue.key === engineFilterConfig.key) {
      if (selectedValue.value.length === 0) {
        this.props.disableEngineFilter();
      }
    }
    const newSelectedValue = { ...this.state.selectedValue };
    if (selectedValue.value.length === 0) {
      delete newSelectedValue[selectedValue.key];
      Object.keys(newSelectedValue).forEach(fKey => {
        if (fKey !== selectedValue.key) {
          newSelectedValue[fKey] = this.state.selectedValue[fKey];
        }
      });
    } else {
      newSelectedValue[selectedValue.key] = selectedValue.value;
    }

    this.setState(
      {
        selectedValue: { ...newSelectedValue },
        lastSelectedFilterKey: selectedValue.key,
      },
      this.fetchFilteredValue,
    );
  }

  @autobind
  private isSelectedValueEmpty(selectedValue: Record<string, FilterValue[]>): boolean {
    return !Object.keys(selectedValue).some(key => selectedValue[key].length);
  }

  @autobind
  private getSelectedValue(suggestions: Record<string, string[]>): Record<string, FilterValue[]> {
    const selectedValue = {};
    Object.keys(this.state.selectedValue).forEach(key => {
      selectedValue[key] = this.state.selectedValue[key].filter(v => {
        if (this.externalFilter(key)) {
          return true;
        }
        return suggestions[key] && suggestions[key].includes(v.value);
      });
    });
    return selectedValue;
  }

  private externalFilter(key: string): boolean {
    return key === REPORT_FILTER_KEY || key === CUSTOM_FILTER || key === ENGINE_FILTER_KEY;
  }

  private isValueInKeyAppropriate(selectValues: FilterValue[], propValues: Array<string | number>): boolean {
    if (!Array.isArray(propValues)) {
      return false;
    }

    for (const sValue of selectValues) {
      for (const pValue of propValues) {
        if (sValue.value === pValue) {
          return true;
        }
      }
    }

    return false;
  }

  private isAppropriate(props: Record<string, Array<string | number>>): boolean {
    let isValid = true;
    for (const [key, values] of Object.entries(this.state.selectedValue)) {
      if (this.externalFilter(key)) {
        continue;
      }
      const isKeyValid = this.isValueInKeyAppropriate(values, props[key]);
      isValid = isValid && isKeyValid;
    }

    return isValid;
  }

  private filterRecords(): {
    filteredIds: number[],
    filteredWithoutEngineFilters: number[],
    suggestions: Record<string, string[]>,
    } {
    const filteredIds = [];
    const filteredWithoutEngineFilters = [];
    const suggestions: Record<string, string[]> = {};
    const { nodeIds, duplicatedNodeIds } = this.props.getReportElementIds();
    for (const record of this.props.records) {
      if (
        this.isAppropriate(record.props)
        && this.filterByReportUsing(record.id as number, nodeIds, duplicatedNodeIds)
        && this.filterByCustomFilter(record)
      ) {
        if (this.filterByEngineFilter(record)) {
          filteredIds.push(record.id);
          for (const [key, values] of Object.entries(record.props)) {
            if (!suggestions[key]) {
              suggestions[key] = [];
            }
            arrayUtils.extendArray(suggestions[key], values);
          }
        }
        filteredWithoutEngineFilters.push(record.id);
      }
    }
    Object.keys(suggestions).forEach((k) => {
      suggestions[k] = uniq(suggestions[k]);
    });

    return { filteredIds, suggestions, filteredWithoutEngineFilters };
  }

  private filterByEngineFilter(
    record: QtoRecord,
  ): boolean {
    if (!this.state.selectedValue[ENGINE_FILTER_KEY]) {
      return true;
    }
    return this.props.engineFilter(Number(record.id));
  }

  private filterByReportUsing(
    recordId: number,
    elementIdUsedInReport: Record<string, boolean>,
    duplicatedNodeIds: Record<string, boolean>,
  ): boolean {
    const reportFilter = this.state.selectedValue[REPORT_FILTER_KEY];
    if (!reportFilter)
      return true;

    for (const filter of reportFilter) {
      if (filterByReportData[filter.value](recordId, elementIdUsedInReport, duplicatedNodeIds)) {
        return true;
      }
    }

    return false;
  }

  private filterByCustomFilter(record: QtoRecord): boolean {
    const customFilters = this.state.selectedValue[CUSTOM_FILTER];
    if (!customFilters)
      return true;

    for (const filter of customFilters) {
      if (filterByCustomFilter[filter.value](record, this.state.customFilterFunction)) {
        return true;
      }
    }

    return false;
  }

  @autobind
  private fetchFilteredValue(): void {
    const { filteredIds, suggestions, filteredWithoutEngineFilters } = this.filterRecords();
    const filterConfigs = this.updateFiltersConfig(suggestions);
    const selectedDisplayName = this.getSelectedValue(suggestions);
    this.setState(
      { filterConfigs, selectedDisplayName },
      () => {
        this.props.saveState(this.state, filteredWithoutEngineFilters);
        this.props.onFilter(
          filteredIds,
          filteredWithoutEngineFilters,
          !this.isSelectedValueEmpty(this.state.selectedValue));
      });
  }

  @autobind
  private updateCustomFilterFunction(): void {
    const customFilterFunction = this.getCustomFilterFunction(this.props);
    this.setState({ customFilterFunction }, this.fetchFilteredValue);
  }

  private getCustomFilterFunction(props: Props): (object: any) => boolean {
    const filter = props.appliedCustomFilterModel;
    const { getElementPropertyNameToValuesMap, mapIdHelper } = props;
    const valueGetter = QtoCustomFilterValueGetter.getQtoCustomFilterValueGetterFunction(
      getElementPropertyNameToValuesMap,
      mapIdHelper.mapBimIdsToEngineIds,
    );
    return CustomElementFilterHelper.createFilterFunction(filter, valueGetter);
  }

  private onCustomFilterChange(prevProps: Props, prevState: TreeFilterPanelState): void {
    if (customFilterConfig.key in prevState.selectedValue && !(customFilterConfig.key in this.state.selectedValue)) {
      this.props.resetAppliedCustomFilter();
    }

    if (prevProps.appliedCustomFilterModel !== this.props.appliedCustomFilterModel) {
      if (!prevProps.appliedCustomFilterModel || !this.props.appliedCustomFilterModel) {
        this.onChangeSelectedValue({
          key: customFilterConfig.key,
          value: this.props.appliedCustomFilterModel ? customFilterConfig.options : [],
        });
      }

      this.updateCustomFilterFunction();
    }
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    callFilters: state.quantityTakeOff.needToUpdateFilter,
    appliedCustomFilterModel: state.quantityTakeOff.appliedCustomFilter,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    resetAppliedCustomFilter: () => dispatch(CustomFiltersActions.setAppliedCustomFilter(null)),
  };
};

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

export const TreeFilterPanel = reduxConnector(withPropertiesDataProviderApi(TreeFilterPanelComponents));
