import autobind from 'autobind-decorator';
import * as React from 'react';

import './filter-panel.scss';

import { FilterValue } from '../filter-select/interfaces';
import { FilterPanelOption } from './filter-panel-option';


interface Props {
  filters: Record<string, FilterValue[]>;
  columnNames: string[];
  filterValueChanged: (columnName: string, value: FilterValue[]) => void;
}

export class FilterPanel extends React.Component<Props> {
  public render(): JSX.Element {
    const { filters, columnNames } = this.props;

    const filterPanelOptions = columnNames.map(columnName => {
      if (filters[columnName]) {
        return filters[columnName].map(filterValue => (
          <FilterPanelOption
            columnName={columnName}
            name={filterValue.name}
            value={filterValue.value}
            deleteFilterItem={this.deleteFilterItem}
            key={`${columnName}/${filterValue.value}`}
          />
        ));
      }

      return [];
    }).reduce((a, b) => a.concat(b), []);

    if (!filterPanelOptions.length) {
      return null;
    }

    return (
      <div className='filter-panel'>{filterPanelOptions}</div>
    );
  }

  @autobind
  private deleteFilterItem(columnName: string, filterValue: string): void {
    if (this.props.filters[columnName]) {
      const newValue = this.props.filters[columnName].filter(x => x.value !== filterValue);
      this.props.filterValueChanged(columnName, newValue);
    }
  }
}

