import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './select-item.scss';

import { Checkbox, KreoRadioButton } from 'common/UIKit';

/* eslint-disable @typescript-eslint/no-shadow */
export enum ItemType {
  Radio,
  Checkbox,
  Simple,
}
/* eslint-enable */

interface Props {
  selected: boolean;
  level: number;
  onCheck: (value: boolean) => void;
  title: string;
  value?: React.ReactText;
  type?: ItemType;
}

export class SelectItem extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    type: ItemType.Checkbox,
  };

  public render(): React.ReactNode {
    const itemClasses = classNames(
      'gantt-chart-header-select-body__item',
      `gantt-chart-header-select-body__item--level-${this.props.level}`,
    );
    return (
      <div className={itemClasses} onClick={this.onClick}>
        <div className='gantt-chart-header-select-body__item-icon'>
          {this.getIcon()}
        </div>
        <div title={this.props.title} className='gantt-chart-header-select-body__item-name'>
          {this.props.title}
        </div>
      </div>);
  }

  private getIcon(): React.ReactNode {
    switch (this.props.type) {
      case ItemType.Checkbox:
        return <Checkbox checked={this.props.selected}/>;
      case ItemType.Radio:
        return (
          <KreoRadioButton
            value={this.props.value}
            checked={this.props.selected}
            name={'selected-item'}
          />
        );
      case ItemType.Simple:
      default:
        return null;
    }
  }

  @autobind
  private onClick(): void {
    this.props.onCheck(!this.props.selected);
  }
}

