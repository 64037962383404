import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { DrawingsGeometryState, DrawingsInstanceMeasure, DrawingsShortInfo } from '../../interfaces';
import { DrawingsInstanceMeasureHelper } from '../../utils/measures/get-drawings-instance-measure-helper';

export function useGetInstancesMeasures(
  getInstanceMeasureHelper: () => DrawingsInstanceMeasureHelper,
): (instancesIds: string[]) => DrawingsInstanceMeasure[] {
  const aiAnnotation = useSelector<State, DrawingsGeometryState>((x) => x.drawings.aiAnnotation);
  const elementMeasurement = useSelector<State, Record<string, DrawingsInstanceMeasure>>(
    (x) => x.drawings.elementMeasurement,
  );
  const drawings = useSelector<State, Record<string, DrawingsShortInfo>>((x) => x.drawings.drawingsInfo);
  return useCallback((instancesIds: string[]) => {
    const helper = getInstanceMeasureHelper();
    if (helper) {
      const { measures } = helper.getInstancesMeasures(
        instancesIds,
        drawings,
        aiAnnotation,
        (id) => elementMeasurement[id],
      );
      return measures;
    }
  }, [aiAnnotation, elementMeasurement, drawings, getInstanceMeasureHelper]);
}
