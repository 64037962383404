import styled from 'styled-components';

const VisibleToggle = styled.div`
  width: 20px;
  padding-right: 5px;

  button {
    position: relative;
    z-index: 2;
    border: 0;
    width: 20px;
    height: 20px;

    &:hover svg {
      fill: ${p => p.theme.color.turquoise};
    }
  }

  svg {
    width: 20px;
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.l};
    fill: ${p => p.theme.color.icon};
  }
`;


export const Styled = {
  VisibleToggle,
};
