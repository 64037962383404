import React, { useCallback } from 'react';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { MeasurementInput } from '../../../measurement-input';
import { PropertyWithMenuEditor } from '../property-menu-editor';

interface Props {
  values: Record<string, string[]>;
  canEdit: boolean;
  name: string;
  onChange: (value: number) => void;
  isImperial: boolean;
}

const MeasurePropertyWithFilterComponent: React.FC<Props> = ({
  values,
  canEdit,
  name,
  onChange,
  isImperial,
}) => {
  const getElementLabel = useCallback((property: string) => {
    if (property === 'undefined') {
      return 'Empty value';
    }
    return UnitUtil.measureToString2d(Number(property), UnitTypes.M, isImperial);
  }, []);

  const editorRenderer = useCallback(() => {
    return (
      <MeasurementInput
        value={undefined}
        onChange={onChange}
        unit={UnitTypes.M}
      />
    );
  }, [onChange, isImperial]);

  return (
    <PropertyWithMenuEditor
      name={name}
      elements={values}
      defaultValue='Mixed'
      editorRenderer={canEdit ? editorRenderer : undefined}
      getElementLabel={getElementLabel}
    />
  );
};

export const MeasurePropertyWithFilter = React.memo(MeasurePropertyWithFilterComponent);

