const isTouch = (e: MouseEvent | TouchEvent): e is TouchEvent => !!(e as TouchEvent).touches;
const isMouse = (e: MouseEvent | TouchEvent): e is MouseEvent => !!(e as MouseEvent).button;

const isOneFingerTouch = (event: TouchEvent): boolean =>
  event.touches.length === 1
  || (event.touches.length === 0 && event.changedTouches.length === 1);

const isLeftMouseButton = ({ event }: PaperMouseEvent): boolean => isTouch(event)
  ? isOneFingerTouch(event)
  : event.button === 0;

const isWheel = (e: PaperMouseEvent): boolean => isMouse(e.event) && e.event.button === 1;
const isRightMouseButton = (e: PaperMouseEvent): boolean => isMouse(e.event) && e.event.button === 2;
const hasPressed = (e: PaperMouseEvent): boolean => isTouch(e.event) ? e.event.touches.length > 0 : e.event.buttons > 0;
const isPaperEvent = (e: PaperMouseEvent | MouseEvent): e is PaperMouseEvent => !!(e as PaperMouseEvent).event;

const getEvent = (
  e: PaperMouseEvent | MouseEvent,
): MouseEvent | TouchEvent => isPaperEvent(e) ? e.event : e;

export const PaperMouseEventUtils = {
  isLeftMouseButton,
  isWheel,
  isRightMouseButton,
  isTouch,
  isMouse,
  hasPressed,
  getEvent,
};
