import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';


interface Props {
  color?: string;
}

export const KreoIconSuccessSmall: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      color={color}
    >
      <g id='Icons/14x14/Success' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle
          id='Oval-3'
          fill='currentColor'
          cx='7'
          cy='7'
          r='6'
        />
        <path
          // eslint-disable-next-line max-len
          d='M9.14644661,5.14644661 C9.34170876,4.95118446 9.65829124,4.95118446 9.85355339,5.14644661 C10.0488155,5.34170876 10.0488155,5.65829124 9.85355339,5.85355339 L6.5,9.20710678 L4.64644661,7.35355339 C4.45118446,7.15829124 4.45118446,6.84170876 4.64644661,6.64644661 C4.84170876,6.45118446 5.15829124,6.45118446 5.35355339,6.64644661 L6.5,7.79289322 L9.14644661,5.14644661 Z'
          id='Path-4'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>);
};

KreoIconSuccessSmall.defaultProps = {
  color: KreoColors.success,
};
