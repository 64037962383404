import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { Notification } from 'common/interfaces/realtime-messages';
import { CommonNotificationProcessor } from '../../../notifications/common-notification-processor';
import { QuantityTakeOffActions } from '../../actions/creators/quantity-take-off';
import { ModelType } from '../../interfaces/quantity-take-off';

function* updateRecordsUserExtractors(notification: Notification): SagaIterator {
  yield call(CommonNotificationProcessor.addInfoAlert, notification);
  const state = yield select();
  const currentProjectId = state.projects.currentProject && state.projects.currentProject.id;
  if (currentProjectId === notification.data.projectId) {
    yield put(
      QuantityTakeOffActions.getLeftPanelRecords(notification.data.projectId, ModelType.QuantityTakeOff, false),
    );
  }
}

export const QuantityTakeOffRecordNotificationsProcessor = {
  updateRecordsUserExtractors,
};
