import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  gap: 12px;
  border-right: 1px solid ${p => p.theme.color.background};
  object-position: 50% 0%;
  flex-direction: column;
  padding: 12px;
  display: flex;
  overflow: auto;
  box-sizing: border-box;
`;

const Category = styled.div`
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
`;

const CategoryItems = styled.div`
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-wrap: wrap;
  display: flex;
  > div {
    height: 20px;
  }
`;

export const Styled = {
  Container,
  Category,
  CategoryItems,
};
