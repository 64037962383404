import styled from 'styled-components';

const IframeContainer = styled.div`
  max-height: 160px;
  max-width: 240px;
  width: 240px;
  height: 160px;
  min-height: 160px;
  min-width: 240px;
  overflow: hidden;

  canvas {
    border: none;
    overflow: hidden;
    max-height: 160px;
    max-width: 240px;
    width: 240px;
    height: 160px;
    border-radius: 10px;
  }
`;

const TutorialDescription = styled.div`
  padding-top: 10px;
`;

export const Styled = {
  IframeContainer,
  TutorialDescription,
};
