import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Field, FieldArray, GenericFieldArray, Validator } from 'redux-form';

import { UploadingFile } from 'common/interfaces/common-state';
import { State } from 'common/interfaces/state';
import { FileFormatSupportedDialog } from '../file-format-supported-dialog';
import { FieldInnerComponentProps, FilesDropzone, InnerComponentProps } from './files-dropzone';


interface Props {
  title: React.ReactNode;
  accept: string;
  validate: Validator[];
  form: string;
  name: string;
  disabled: boolean;
  extensionsToShow: string[];
  onUploadStarted?: (files: File[]) => void;
  onDeleteFile?: (file: UploadingFile) => void;
}


const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, InnerComponentProps>;

const FilesUploaderComponent: React.FC<Props> = ({
  accept,
  disabled,
  validate,
  extensionsToShow,
  form,
  name,
  title,
  onDeleteFile,
  onUploadStarted,
}) => {
  const files = useSelector<State, UploadingFile[]>(s => s.common.files);

  const renderFiled = useCallback((fieldProps: FieldInnerComponentProps) => {
    return (
      <FilesDropzone
        fieldProps={fieldProps}
        title={title}
        accept={accept}
        name={name}
        form={form}
        disabled={disabled}
        onDeleteFile={onDeleteFile}
        onUploadStarted={onUploadStarted}
      />
    );
  }, [name, form, accept, disabled, title, onDeleteFile, onUploadStarted]);

  return (
    <>
      <FieldArrayCustom
        name="files"
        component={renderFiled}
        files={files}
        validate={validate}
      />
      <FileFormatSupportedDialog
        extensions={extensionsToShow}
      />
    </>
  );
};


export const FilesUploader = React.memo(FilesUploaderComponent);
