const equal = (a: any, b: any): boolean => {
  // The standard excel equals to (=) operator is not case-sensitive
  if (typeof a === 'string' && typeof b === 'string') {
    return a.toLowerCase() === b.toLowerCase();
  }

  return a === b;
};

export const ExcelExpressionEvaluatorOverrideBinaryOps: Record<string, any> = {
  ['==']: equal,
};

export const ExcelExpressionEvaluatorAvaliableDefaultBinaryOps = [
  '+',
  '-',
  '*',
  '/',
  '%',
  '^',
  '||',
  '!=',
  '>',
  '<',
  '>=',
  '<=',
  '=',
  '[',
];
