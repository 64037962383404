import * as t from 'io-ts';

import { UserBidPricingStatusC } from '../../enums/user-bid-pricing-status';
import { BidPricingUserCostC } from './bid-pricing-user-cost';


export const BidPricingWorkPackageC = t.exact(
  t.intersection([
    t.type({
      constructionCostId: t.number,
      duration: t.number,
      id: t.number,
      indirectCost: t.number,
      laborCost: t.number,
      materialCost: t.number,
      name: t.string,
      plantCost: t.number,
      status: UserBidPricingStatusC,
      totalCost: t.number,
      workPackageInnerId: t.number,
      assignCompany: t.boolean,
    }),
    t.partial({
      usersCost: t.array(BidPricingUserCostC),
    }),
  ]),
  'BidPricingCalculationDetails');

export type BidPricingWorkPackage = t.TypeOf<typeof BidPricingWorkPackageC>;
