import React from 'react';

import { AnalyticContext } from './analytics-context';

export interface AnalyticsProps {
  sendEvent: (name: string, params?: any) => void;
  sendToggleEvent: (name: string, currentStatus: boolean, params?: any) => void;
}

export const withAnalyticsContext = <P extends AnalyticsProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof AnalyticsProps>>> => {
  return class AbilityAwareComponent extends React.Component<
    Pick<P, Exclude<keyof P, keyof AnalyticsProps>>
  > {
    public render(): React.ReactNode {
      return (
        <AnalyticContext.Consumer>
          {sendEvent => {
            return (<Component
              {...this.props as P}
              sendEvent={sendEvent.sendEvent}
              sendToggleEvent={sendEvent.sendToggleEvent}
            />);
          }}
        </AnalyticContext.Consumer>
      );
    }
  };
};
