import autobind from 'autobind-decorator';
import * as React from 'react';

import { SidePanelApi } from '../../interfaces';


interface SideProps {
  show: boolean;
  defaultSize: number;
  onResize: (resize: boolean) => void;
  onApiProccessed: (api: SidePanelApi) => void;
}


export class SidePanel extends React.Component<SideProps> {
  private size: number = null;
  private ref: HTMLDivElement = null;
  private resize: HTMLDivElement = null;

  constructor(props: SideProps) {
    super(props);
    this.size = props.defaultSize;
  }

  public setSize(size: number): boolean {
    if (size > 500 || size < 300) {
      return false;
    }
    this.size = size;
    this.ref.style.width = `${this.size}px`;
    return true;
  }

  public deactivate(): void {
    if (this.resize !== null) {
      this.ref.parentElement.classList.toggle('no-transition');
      this.resize.classList.toggle('active');
    }
  }

  public componentDidMount(): void {
    const api: SidePanelApi = {
      setSize: this.setSize.bind(this),
      deactivate: this.deactivate.bind(this),
    };
    this.props.onApiProccessed(api);
  }

  public render(): JSX.Element {
    return (
      <div
        className='side-panel'
        ref={this.setRef}
        style={{ width: this.props.show ? this.size : 0 }}
      >
        {this.props.show &&
          this.props.children
        }

        {this.props.show && <div
          ref={this.resizeRef}
          key={1}
          className={'resizer'}
          onMouseDown={this.mouseDown}
          onMouseUp={this.mouseUp}
        >
          <div
            className='line'
          />
        </div>}
      </div>
    );
  }


  @autobind
  private setRef(ref: HTMLDivElement): void {
    this.ref = ref;
  }

  @autobind
  private resizeRef(ref: HTMLDivElement): void {
    this.resize = ref;
  }

  @autobind
  private mouseDown(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    if (!this.resize !== null) {
      this.ref.parentElement.classList.toggle('no-transition');
      this.resize.classList.toggle('active');
    }
    this.props.onResize(true);
  }

  @autobind
  private mouseUp(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    if (!this.resize !== null) {
      this.ref.parentElement.classList.toggle('no-transition');
      this.resize.classList.toggle('active');
    }
    this.props.onResize(false);
  }
}
