import { useCallback } from 'react';

export function useCloseAfterAction(
  doActionAndClose: (action: (...args: any) => void, ...args: any) => void,
  action: (...args: any) => void,
  withArgs: boolean = false,
): (...args: any) => void {
  return useCallback((...args) => {
    if (action) {
      if (withArgs) {
        doActionAndClose(action, ...args);
      } else {
        doActionAndClose(action);
      }
    }
  }, [withArgs, action, doActionAndClose]);
}
