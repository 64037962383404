import { RectangleButton } from '@kreo/kreo-ui-components';

import React from 'react';

import { RequestStatus } from 'common/enums/request-status';
import { getStatusData } from './get-status-data';

interface Props {
  status: RequestStatus;
  fetchProjects: () => void;
}

const StatusButtonComponent: React.FC<Props> = ({ status, fetchProjects }) => {
  const { icon, mood, text } = getStatusData(status);
  return (
    <RectangleButton
      size='xl'
      mood={mood}
      ghost={true}
      onClick={fetchProjects}
      Icon={icon}
      text={text}
    />
  );
};

export const StatusButton = React.memo(StatusButtonComponent);
