import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { CompanyProjectHeader } from './interfaces/company-project-header';
import { Project } from './interfaces/project';
import { ProjectReduxState } from './interfaces/project-redux-state';

const findProjectHeader = (
  state: ProjectReduxState,
  projectId: number,
): CompanyProjectHeader => {
  const companyProjectHeadersStore = arrayUtils.flatArray(
    Object.values(state.projectHeadersByCompanyId).map(projectsByType => Object.values(projectsByType)),
  )
    .find(store => projectId in store.data);

  return companyProjectHeadersStore ? companyProjectHeadersStore.data[projectId] : null;
};

export const getCurrentProject = (state: State): Project | null => {
  // пришлось оставить т.к. слишком много использований (79)
  return state.projects.currentProject;
};

function projectIsCurrent({ projects }: State, projectId: number): boolean {
  return projects.currentProject && projectId === projects.currentProject.id;
}

export const ProjectSelectors = {
  findProjectHeader,
  projectIsCurrent,
};
