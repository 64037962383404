const isMacOS = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

export enum OS {
  Mac = 'Mac',
  Windows = 'Windows',
}

export const OsDetector = {
  getOs: (): OS => isMacOS ? OS.Mac : OS.Windows,
};
