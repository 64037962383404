import React, { createContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DrawingsAnnotationLegendActions } from '../drawings';

export const MixedPropertyContext = createContext(null);

interface Props {
  propertyVariants: Record<string, Set<string | number>>;
  getIdByVariant: (variant: string) => { instances: string[], groups: string[] };
}

export const MixedPropertyContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  propertyVariants,
  children,
  getIdByVariant,
}) => {
  const dispatch = useDispatch();
  const getter = useCallback((id: string) => {
    if (!propertyVariants) {
      return null;
    }
    const variants = propertyVariants[id];
    if (!variants) {
      return null;
    }
    const elements = Array.from(variants);
    return ({
      elements,
      activeElementIndex: 0,
      onClick: (i) => {
        const value = elements[i];
        const ids = getIdByVariant(`${value}_${id}`);
        dispatch(DrawingsAnnotationLegendActions.updateSelection({ instanceIds: ids.instances, groupIds: ids.groups }));
      },
      createNewElement: false,
      value: 'Mixed',
    });
  }, [propertyVariants, dispatch, getIdByVariant]);
  const value = propertyVariants !== null
    ? {
      getInput: getter,
    }
    : null;
  return (
    <MixedPropertyContext.Provider value={value}>
      {children}
    </MixedPropertyContext.Provider>
  );
};
