import autobind from 'autobind-decorator';
import * as React from 'react';

import { connect } from 'react-redux';
import { State } from 'common/interfaces/state';
import { mathUtils } from 'common/utils/math-utils';
import { KreoChart, KreoChartMode } from '../../../../../components/kreo-chart';
import { SingleChartColors } from '../../../../../components/kreo-chart/kreo-chart';
import { GanttConstants } from '../../../constants/gantt';
import { AllLaboursId, ChartDataProvider } from '../../../utils/gantt-chart';
import { ResourceIdentifier } from '../../four-d-visualisation/interfaces/resource-identifier';
import { ganttChartContants } from '../utils/constants';
import { DateRow } from './date-row';
import { OverlayTimeSelector } from './overlay-time-selector';
import { Timeframe } from './timeframe';

interface OwnProps {
  width: number;
  projectStartDate: Date;
  timelineStartDay: number;
  timelineDuration: number;
  timeframeStartDay: number;
  timeframeDuration: number;
  dataProvider: ChartDataProvider;
  updateTimeframe: (start: number, duration: number) => void;
  updateTimeframeTarget: (start: number, duration: number) => void;
}

interface StateProps {
  resourceToRender: ResourceIdentifier;
  resourceToRenderName: string;
  isPreviewingResourceChart: boolean;
}

interface Props extends StateProps, OwnProps {}

interface TimelineState {
  pixelsPerDay: number;
  isCursorOnTimeframeHandle: boolean;
}

class ProjectTimelineComponent extends React.Component<Props, TimelineState> {
  private readonly minimalFrameDuration: number = 1;
  private readonly timeframeHandleWidth: number = 5;
  private readonly defaultFrameDuration: number = 7;
  private readonly colors: SingleChartColors = { main: '#44B8A1' };

  private chartWidth: number = 0;

  constructor(props: Props) {
    super(props);
    const { pixelsPerDay } = ProjectTimelineComponent.getDerivedStateFromProps(props, null);
    this.state = {
      pixelsPerDay,
      isCursorOnTimeframeHandle: false,
    };
  }

  public static getDerivedStateFromProps(
    nextProps: Props,
    prevState: TimelineState | null,
  ): Partial<TimelineState> {
    const datesAreaWidth = nextProps.width - ganttChartContants.leftPanelWidth;
    const pixelsPerDay = datesAreaWidth / nextProps.timelineDuration;
    if (!prevState || pixelsPerDay !== prevState.pixelsPerDay) {
      return { pixelsPerDay };
    }
    return null;
  }

  public render(): React.ReactNode {
    this.chartWidth = this.props.width - ganttChartContants.leftPanelWidth;
    const chartData = this.props.dataProvider.calculateFullChart(
      this.props.resourceToRender.type,
      this.props.resourceToRender.id,
      this.chartWidth,
    );
    return (
      <OverlayTimeSelector
        isCursorOnTimeframeHandle={this.state.isCursorOnTimeframeHandle}
        className='gantt-chart__project-timeline'
        handleClick={this.handleClick}
        onCompleteSelection={this.calculateNewTimeFrame}
        clampStartPoint={this.clampStartPoint}
        onWheel={this.onWheel}
        isSelectInValidArea={this.isSelectInValidArea}
      >
        <DateRow
          startDate={this.props.projectStartDate}
          endDate={new Date(
            this.props.projectStartDate.getTime() + this.props.timelineDuration * GanttConstants.msInDay,
          )}
        />
        <Timeframe
          timeframeStartDay={this.props.timeframeStartDay}
          timeframeDuration={this.props.timeframeDuration}
          timelineDuration={this.props.timelineDuration}
          timelineStartDay={this.props.timelineStartDay}
          updateTimeframe={this.props.updateTimeframe}
          horizontalMargin={ganttChartContants.rightIndent}
          minimalFrameDuration={this.minimalFrameDuration}
          handleWidth={this.timeframeHandleWidth}
          pixelsPerDay={this.state.pixelsPerDay}
          setCursorHandleStatus={this.setCursorHandleStatus}
        />
        <KreoChart
          className='resources chart'
          id='labours-timeline'
          backgroundColor='#fff'
          colors={this.colors}
          data={chartData}
          mode={KreoChartMode.Single}
          height={30}
          width={this.chartWidth}
          isAvailable={true}
        />
      </OverlayTimeSelector>
    );
  }

  private isSelectInValidArea(offset: number): boolean {
    return offset - ganttChartContants.leftPanelWidth >= 0;
  }

  @autobind
  private setCursorHandleStatus(isCursorOnTimeframeHandle: boolean): void {
    this.setState({ isCursorOnTimeframeHandle });
  }

  @autobind
  private clampStartPoint(offset: number): number {
    return mathUtils.clamp(
      offset,
      ganttChartContants.leftPanelWidth,
      this.props.timelineDuration * this.state.pixelsPerDay + ganttChartContants.leftPanelWidth,
    );
  }

  @autobind
  private handleClick(offset: number): void {
    const dayUnderThePointer = this.props.timelineStartDay +
      (offset - ganttChartContants.leftPanelWidth) / this.state.pixelsPerDay;
    this.props.updateTimeframeTarget(dayUnderThePointer - this.defaultFrameDuration / 2, this.defaultFrameDuration);
  }

  @autobind
  private onWheel(event: React.WheelEvent<HTMLDivElement>, containerOffset: number): void {
    const directionSign = Math.sign(event.deltaY);
    const zoomSpeed = 0.05;
    const multiplier = 1 + directionSign * zoomSpeed;
    if (
      (this.props.timeframeDuration <= this.minimalFrameDuration && directionSign) ||
      (this.props.timeframeDuration >= this.props.timelineDuration && directionSign > 0)
    ) {
      return;
    }

    const newDuration = mathUtils.clamp(
      this.props.timeframeDuration * multiplier,
      this.minimalFrameDuration,
      this.props.timelineDuration,
    );
    const offset = this.clampStartPoint(event.pageX - containerOffset);
    const target = (offset - ganttChartContants.leftPanelWidth) / this.state.pixelsPerDay
      + this.props.timelineStartDay;

    const newDaysTillStart = mathUtils.clamp(
      mathUtils.lerp(this.props.timeframeStartDay, target - newDuration / 2, zoomSpeed * 2),
      this.props.timelineStartDay,
      this.props.timelineStartDay + this.props.timelineDuration - newDuration);
    this.props.updateTimeframe(newDaysTillStart, newDuration);
  }

  @autobind
  private calculateNewTimeFrame(startOffset: number, width: number): void {
    const newDuration = mathUtils.clamp(
      width / this.state.pixelsPerDay,
      this.minimalFrameDuration,
      this.props.timelineDuration,
    );
    const startDay = (startOffset - ganttChartContants.leftPanelWidth) / this.state.pixelsPerDay;
    this.props.updateTimeframe(startDay, newDuration);
  }
}

const mapStateToProps = (state: State): StateProps => {
  const resourceToRender =
    state.fourDVisualisation.timelineChart.previewedResource ||
    state.fourDVisualisation.timelineChart.selectedResource ||
    state.fourDVisualisation.timelineChart.defaultResource;

  const isPreviewingResourceChart =
    state.fourDVisualisation.timelineChart.previewedResource != null;

  const resourceToRenderName =
    resourceToRender.id === AllLaboursId
      ? 'All Labours'
      : state.fourDVisualisation.resources[resourceToRender.type].get(resourceToRender.id)
        .displayName;

  return {
    resourceToRender,
    resourceToRenderName,
    isPreviewingResourceChart,
  };
};

export const ProjectTimeline = connect(mapStateToProps)(ProjectTimelineComponent);
