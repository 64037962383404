import styled from 'styled-components';


const BUTTONS_MARGIN_LEFT = 20;
const BUTTONS_MARGIN_TOP = 15;
const BUTTONS_MARGIN_RIGHT = 15;


const Line = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  transition: ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  pointer-events: none;
  width: 100%;
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  pointer-events: none;
  top: ${BUTTONS_MARGIN_TOP}px;

  padding: 0 ${BUTTONS_MARGIN_RIGHT}px 0 ${BUTTONS_MARGIN_LEFT}px;
  box-sizing: border-box;

  ${Line} + ${Line} {
    margin-top: 10px;
  }
`;

export const Styled = {
  Container,
  Line,
};
