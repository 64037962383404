import * as React from 'react';

import {
  KreoIconEyeGhosted as KIEG,
  KreoIconEyeHidden as KIEH,
  KreoIconEyeNormal as KIEN,
} from 'common/UIKit';

const KreoIconEyeGhosted: any = KIEG;
const KreoIconEyeHidden: any = KIEH;
const KreoIconEyeNormal: any = KIEN;

interface VisibilityIconProps {
  visibility: string;
  onHide: (e: React.MouseEvent<SVGElement>) => void;
  onGhost: (e: React.MouseEvent<SVGElement>) => void;
  onNormal: (e: React.MouseEvent<SVGElement>) => void;
}

export const VisibilityIcon: React.FC<VisibilityIconProps> = (props): JSX.Element => {
  switch (props.visibility) {
    case 'none':
      return (
        <KreoIconEyeHidden
          className='visible-icon'
          onClick={props.onHide}
          size={16}
        />);
    case 'ghost':
      return (
        <KreoIconEyeGhosted
          onClick={props.onGhost}
          className='visible-icon'
        />);
    case 'standard':
    default:
      return (
        <KreoIconEyeNormal
          className='visible-icon'
          onClick={props.onNormal}
          size={16}
        />);
  }
};
