import * as t from 'io-ts';

import { CompanyEmployeeRoleC } from 'common/enums/company-employee-role';
import { KreoProductC } from 'common/enums/kreo-product';
import { PermissionStateC } from 'common/enums/permission-state';
import { RoleCodeC } from 'common/enums/role-code';
import { RoleGroupC } from 'common/enums/role-group';
import { SubscriptionActivityStatusC } from '../../subscription/enums/subscription-activity-status';
import { SubscriptionStatusC } from '../../subscription/enums/subscription-status';

export const CompanySubscriptionModelC = t.intersection(
  [
    t.type({
      status: SubscriptionStatusC,
      billingStatus: SubscriptionActivityStatusC,
      employeesMaxCount: t.number,
      products: t.array(KreoProductC),
      isCollaborationBlocked: t.boolean,
      isSelfService: t.boolean,

      userRole: CompanyEmployeeRoleC,
      userRoleId: t.number,
      userRoleCode: RoleCodeC,
      userRoleName: t.string,
      userRoleGroup: RoleGroupC,
      userRolePermissions: t.record(t.string, PermissionStateC),
    }),
    t.partial({
      planId: t.string,
      projectsLimit: t.number,
      createdAt: t.string,
      nextBillingAt: t.string,
      trialEnd: t.string,
    }),
  ],
  'CompanySubscriptionModel');

export type CompanySubscriptionModel = t.TypeOf<typeof CompanySubscriptionModelC>;
