import './description-tooltip.scss';

export const appendDescriptionTooltip = (fullName: string, id: string, innerText: string): void => {
  const tooltipId = `${id}_tooltip`;
  if (document.getElementById(tooltipId)) {
    return;
  }
  const descriptionTooltip = document.createElement('div');
  const page = document.querySelector('.quantity-take-off-page');
  const element = document.getElementById(id);
  const modalRoot = document.getElementById('modal-root');
  const pageRect = page.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  const isUnderCenterHeight = pageRect.bottom - (pageRect.height / 2) < elementRect.top;
  const top = isUnderCenterHeight
    ? elementRect.top - 325
    : elementRect.top + 25;
  descriptionTooltip.style.top = `${top}px`;
  descriptionTooltip.style.left = `${elementRect.left}px`;

  descriptionTooltip.className = 'description-tooltip';
  descriptionTooltip.id = tooltipId;

  const descriptionBox = document.createElement('div');
  descriptionBox.className = 'description-tooltip__text-box';
  descriptionBox.innerText = innerText;

  const boxImg = document.createElement('div');
  boxImg.className = 'description-tooltip__img-box';

  const img = document.createElement('img');
  img.src = `/static/extractor-functions/${fullName}.png`;
  img.alt = fullName;

  boxImg.appendChild(img);
  descriptionTooltip.appendChild(boxImg);
  descriptionTooltip.appendChild(descriptionBox);

  modalRoot.appendChild(descriptionTooltip);
};
