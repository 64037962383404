import { H5 } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import {
  Styled as ChangeBillingRadioMenuStyled,
} from '../../subscription-details-new-user/change-billing-radio-menu/styled';

const Container = styled.div<{ isFreePlan: boolean }>`
  padding: 20px 20px 0px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${p => p.theme.color.background};

  ${H5} {
    font-size: 21px;
  }

  ${ChangeBillingRadioMenuStyled.RadioMenuContainer} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  ${ChangeBillingRadioMenuStyled.Discount} {
    bottom: 15px;
    right: ${p => p.isFreePlan ? -12 : 45}px;
  }
`;

const Period = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  flex-direction: column;

  > div:nth-child(3n) {
    margin: 0 15px;
  }
`;

export const Styled = {
  Container,
  Period,
};
