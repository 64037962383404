import styled from 'styled-components';

const DialogOption = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Styled = {
  DialogOption,
};
