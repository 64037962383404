
/* eslint-disable */

import { 
	AnimationDataResponse, 
	CrewMemberType, 
	TimesCouples, 
	TimesTriples 
} from '../../interfaces/gantt-chart';


export enum GroupType {
	WorkPackage = 1,
	ActivityGroup = 2,
	Activity = 3
}

export enum SlackType{
	FreeSlack = 1,
	TotalSlack = 2,
	Disabled = 3,
}

export interface Slack{
	backSlack: number;
	forwardSlack: number;
}

export interface SlackHorizontalCoordinates{
	left: number;
	width: number;
	type: SlackType;
}

export interface GanttLineInfo {
	readonly sourceId: number;
	readonly lineType: GroupType;
	readonly startDay: number;
	readonly endDay: number;
	readonly colors: Colors;
	readonly activeDuration: number;
}

export interface GanttLineHorizontalCoords {
	readonly sourceId: number;
	readonly lineType: GroupType;
	readonly start: number;
	readonly width: number;
	/**
	 * contains local sizes of internal lines of gantt lines in order [line1Start, line1Width, line2Start, line2Width, ...etc]
	 */
	readonly internalLinesStartIndex : number;
	readonly internalLinesSizes: number[];
}


export interface GanttLineCoordinatesWithSLack {
	ganttCoordinates: GanttLineHorizontalCoords;
	slackCoordinates: SlackHorizontalCoordinates;
}

export enum ResourceType {
	Labour = 1,
	Plant = 2,
	Material = 3
}



export const resourceTypeValues: ResourceType[] = [ResourceType.Labour, ResourceType.Plant, ResourceType.Material];

export interface ResourceDescription {
	readonly type: ResourceType;
	readonly id: number;
	readonly name: string;
	readonly unit: string | null;
	readonly colors: Colors;
	readonly templateId: number | null;
	readonly skillRate: string | null;
	readonly workPackageIds: number[] | null;
	readonly displayName: string;
}

export interface ActivityResources {
	readonly activityId: number;
	readonly labours: ResourceDescription[];
	readonly totalLabourTime: number;
	
	readonly plants: ResourceDescription[];

	readonly materials: ResourceDescription[];
	/**
	 * amount of material used of material for activity
	 * indices correspond to indices in materials array
	 */
	readonly materialsAmounts: number[];
}

interface Colors {
	main: string,
	off: string
}

export interface ChartBase {
	timeFrom: number,
	timeTo: number,
	globalMax: number;
	mergeStart: number,
	mergeDuration: number;
}

export interface SingleChartData extends ChartBase {
	mergedValues: number[]; // value1, value2, ...
	timesInternals: TimesTriples | null;
}

export interface MultiChartData extends ChartBase {
	mergedValues2dCumm: number[][]; // values are cummulative, last array has max values
	mergeIds: number[];
}

const defaultWpMainColor = "#57617A"
const defaultWpOffColor = "#2B3247"

const cachedKeyStrings: DictionaryNumb<DictionaryNumb<string>> = {};
export function stringKeyFromNumbs(type: ResourceType | GroupType, id: number) {
	let ids2s = cachedKeyStrings[type];
	if (!ids2s) {
		ids2s = (cachedKeyStrings[type] = {});
	}
	let str = ids2s[id] || (ids2s[id] = type + '_' + id);
	return str;
}

function isKeyStartWith(key: string, type: ResourceType | GroupType): boolean{
	return key.startsWith(type + '_');
}

interface IdTree {
	id: number;
	children: number[];
}

const MinGanttPixels = 2;
const MinGanttEmptyPixels = 1;
const MinInternalChartWidthPixels = 1;
const MinChartWidthPixels = 10;

export const AllLaboursId = -7;

const AllWpsId = -11;

export class ChartDataProvider {

	private from: number = 0;
	private to: number = 1;
	private minGanttRange: number = 0.1;
	private minGanttEmptyRange: number = 0.1;
	private ganttPixelsRange: number = 100;
	private ganttPixelsPerDay = 100;
	version: number = 0;
	private readonly totalDuration: number = 0;
	private readonly startDate: Date;
	private readonly startMonday: Date;
	private readonly toMondayOffsetDays: number; //days

	private colorGenerator: ColorsGenerator = new ColorsGenerator();

	private readonly ids: DictionaryNumb<DictionaryNumb<IdTree>> = {
		[GroupType.WorkPackage]: {},
		[GroupType.ActivityGroup]: {}
	};

	private readonly flatTimes: DictionaryNumb<DictionaryNumb<FlatTimes>> = {
		[GroupType.WorkPackage]: {},
		[GroupType.ActivityGroup]: {},
		[GroupType.Activity]: {}
	};

	private readonly cachedCollapsedTimes: DictionaryNumb<DictionaryNumb<number[]>> = {
		[GroupType.WorkPackage]: {},
		[GroupType.ActivityGroup]: {},
		[GroupType.Activity]: {}
	};

	private readonly slacks: DictionaryNumb<DictionaryNumb<DictionaryNumb<Slack>>> = {
		[GroupType.WorkPackage]: {},
		[GroupType.ActivityGroup]: {},
		[GroupType.Activity]: {},
	}

	private acsColors: DictionaryNumb<Colors> = {};
	private actGrsColors: DictionaryNumb<Colors> = {};

	private isolated: FlatTimes | null = null;

	private readonly resourceDescriptions: DictionaryNumb<Map<number, ResourceDescription>> = {
		[ResourceType.Labour]: new Map(),
		[ResourceType.Plant]: new Map(),
		[ResourceType.Material]: new Map()
	}

	private readonly activitiesResInfo: DictionaryNumb<ActivityResBrief> = {};

	// wp.type_wp.id -> res.type_res.id
	private readonly resourcesConsumption: DictionaryStr<DictionaryStr<LazyResult<ResDemand>>> = {}

	private readonly wpNames: DictionaryNumb<string> = {};

	constructor(animData: AnimationDataResponse) {

		this.startDate = new Date(parseInt(animData.startDatetime.substr(6), 10));
		this.startMonday = new Date(this.startDate);
		this.startMonday.setDate(this.startDate.getDate() - (this.startDate.getDay() + 6) % 7);
		this.startMonday.setHours(0, 0, 0);
		this.toMondayOffsetDays = ( this.startMonday.valueOf() - this.startDate.valueOf()) / (1000 * 60 * 60 * 24);

		for (const wp of animData.workPackages) {
			this.wpNames[wp.id] = wp.name;
			const wpTree: IdTree = {
				id: wp.id,
				children: []
			}
			for (const ag of wp.activityGroups) {
				const agTree: IdTree = {
					id: ag.id,
					children: ag.activities.map(a => a.id)
				}
				for (const a of ag.activities) {
					agTree.children.push(a.id)
				}
				wpTree.children.push(agTree.id);
				this.ids[GroupType.ActivityGroup][ag.id] = agTree;
			}
			this.ids[GroupType.WorkPackage][wp.id] = wpTree;
		}

		{ // populate resource descriptions
			const resDescriptionSort = (d1, d2) => {
				if (d1.name < d2.name) {
					return -1;
				}
				if (d1.name > d2.name) {
					return 1;
				}
				return 0;
			};
			for (const r of animData.resources.slice().sort(resDescriptionSort)) {
				if (r.type === CrewMemberType.Labor || r.type === CrewMemberType.Plant) {
					const type = r.type === CrewMemberType.Labor ? ResourceType.Labour : ResourceType.Plant;

					const skillRate = r.skill_rate ? ` (${r.skill_rate})` : '';
					const workPackages = r.workpackage_ids
						? ` @ ${r.workpackage_ids.map((wpId) => this.wpNames[wpId]).join(', ')}`
						: '';
					const displayName = `${r.name}${skillRate}${workPackages}`;
					
					this.resourceDescriptions[type].set(r.id, {
						type: type,
						id: r.id,
						name: r.name,
						unit: null,
						colors: this.colorGenerator.getResourceColors(type, r.id),
						skillRate: r.skill_rate,
						templateId: r.template_id,
						workPackageIds: r.workpackage_ids,
						displayName,
					});
				} else {
					console.error('unkown resource type', r);
				}
			}
			for (const m of animData.materials.slice().sort(resDescriptionSort)) {
				this.resourceDescriptions[ResourceType.Material].set(m.id,  {
					type: ResourceType.Material,
					id: m.id,
					name: m.name,
					unit: m.unit,
					colors: this.colorGenerator.getResourceColors(ResourceType.Material, m.id),
					displayName: m.name,
					skillRate: null,
					templateId: null,
					workPackageIds: null,
				});
			}
		}

		const crewResTypeFromId: DictionaryNumb<ResourceType> = {};
		for (const r of animData.resources) {
			const type = crewTypeToResourceType(r.type);
			if (type !== null) {
				crewResTypeFromId[r.id] = type;
			}
		}

		// save resources for activities
		const globalResConsumptionsByKey: DictionaryStr<LazyResult<ResDemand>[]> = {};

		for (const wp of animData.workPackages) {
			const wpResConsumptionsByKey: DictionaryStr<LazyResult<ResDemand>[]> = {};

			for (const ag of wp.activityGroups) {
				const agResConsumptionsByKey : DictionaryStr<ResDemand[]> = {};

				for (const act of ag.activities) {
					
					const actResources: DictionaryNumb<number[]> = createResourcesNumbersDict();
					actResources[ResourceType.Material] = act.materialIds;
					for (const resId of act.resourceIds) {
						const resType = crewResTypeFromId[resId];
						if (resType) {
							actResources[resType].push(resId);
						}
					}

					let actLabTime = 0;
					let multiplierChartArea = 0;
					for (let i = 0; i < act.times.length; i += 3) {
						const dur = safeNonNegativeNumber(act.times[i + 1] - act.times[i]);
						let mult = safeNonNegativeNumber(act.times[i + 2]);
						if (mult === 0) {
							mult = 1;
						}
						const colArea = dur * mult;
						actLabTime += colArea * actResources[ResourceType.Labour].length;
						multiplierChartArea += colArea;
					}

					this.activitiesResInfo[act.id] = {
						id: act.id,
						resources: actResources,
						laboursTimeTotal: actLabTime,
						materialsTotalAmounts:  act.materialAmount,
					}

					// calc resource charts for activity
					for (let i = 0; i < act.materialIds.length; ++i) { // only add materials to ag charts
						const matId = act.materialIds[i];
						let resDemandMult = 0;
						if (multiplierChartArea > 0) {
							resDemandMult = act.materialAmount[i] / multiplierChartArea;
						} else if (act.times.length === 3) { // work with zero duration
							resDemandMult = act.materialAmount[i];
						}
						const dayStartTime = this.calcStartTimeForRange(act.times[0], 1);
						const weekStartTime = this.calcStartTimeForRange(act.times[0], 7);
						const resDemandChart = matResDemandFromTimesTriples(act.times, resDemandMult, matId, dayStartTime, weekStartTime);
						const resKey = stringKeyFromNumbs(ResourceType.Material, matId);

						const resSumFromChart = resDemandChart.perDayDemand.reduce((sum: number, curr: number) => curr + sum);
						const isValid = Math.abs(resSumFromChart - act.materialAmount[i]) < 0.001;
						console.assert(isValid, 'resource chart total is correct');
						
						safeAddToArrayByKey(agResConsumptionsByKey, resKey, resDemandChart);
					}
				}

				const agLaboursCharts: ResDemand[] = [];

				for (let i = 0; i < ag.resource_ids.length; ++i) {
					const resId = ag.resource_ids[i]; // in case of invalid data
					if (!crewResTypeFromId[resId]) {
						console.error('invalid ag resource id ', resId);
						continue;
					}
					const resType = crewResTypeFromId[resId];
					if (resType === null) { continue; }
					const resAmount = ag.resource_amount[i];
					const perDayStart = this.calcStartTimeForRange(ag.times[0], 1);
					const perWeekStart = this.calcStartTimeForRange(ag.times[0], 7);
					const resDemandChart = crewResDemandFromTimesCouples(ag.times, resAmount, resId, perDayStart, perWeekStart, resType);
					const resKey = stringKeyFromNumbs(resType, resId);

					agResConsumptionsByKey[resKey] = [resDemandChart];

					if (resType === ResourceType.Labour) {
						agLaboursCharts.push(resDemandChart);
					}
				}

				// calc resource charts for activityGroup
				for (const resKey in agResConsumptionsByKey) {
					const activitiesCharts = agResConsumptionsByKey[resKey];
					const groupKey = stringKeyFromNumbs(GroupType.ActivityGroup, ag.id);
					
					const lazyChart = new LazyResult(() => this.combineCharts(activitiesCharts));
					safeSetToNestedDict(this.resourcesConsumption, groupKey, resKey, lazyChart);

					safeAddToArrayByKey(wpResConsumptionsByKey, resKey, lazyChart);
				}
				
				if (agLaboursCharts.length > 0) {
					const groupKey = stringKeyFromNumbs(GroupType.ActivityGroup, ag.id);
					const resKey = stringKeyFromNumbs(ResourceType.Labour, AllLaboursId);
					const chartFactory = () => this.calcLaboursSummaryChart(agLaboursCharts);
					safeSetToNestedDict(this.resourcesConsumption, groupKey, resKey, new LazyResult(chartFactory));
				}
			}

			const wpLaboursCharts: LazyResult<ResDemand>[] = [];

			// calc resource charts for workpackage
			for (const resKey in wpResConsumptionsByKey) {
				const actGroupsCharts = wpResConsumptionsByKey[resKey];
				const lazyChart = new LazyResult(() => this.combineCharts(actGroupsCharts.map(l => l.result)));
				const groupKey = stringKeyFromNumbs(GroupType.WorkPackage, wp.id);
				safeSetToNestedDict(this.resourcesConsumption, groupKey, resKey, lazyChart);

				safeAddToArrayByKey(globalResConsumptionsByKey, resKey, lazyChart);

				if (isKeyStartWith(resKey, ResourceType.Labour)) {
					wpLaboursCharts.push(lazyChart);
				}
			}

			if (wpLaboursCharts.length > 0) {
				const groupKey = stringKeyFromNumbs(GroupType.WorkPackage, wp.id);
				const resKey = stringKeyFromNumbs(ResourceType.Labour, AllLaboursId);
				const chartFactory = () => this.calcLaboursSummaryChart(wpLaboursCharts.map(l => l.result));
				safeSetToNestedDict(this.resourcesConsumption, groupKey, resKey, new LazyResult(chartFactory));
			}
		}

		const glLaboursCHarts: LazyResult<ResDemand>[] = [];

		// calc global resource charts
		for (const resKey in globalResConsumptionsByKey) {
			const actGroupsCharts = globalResConsumptionsByKey[resKey];
			const lazyChart = new LazyResult(() => this.combineCharts(actGroupsCharts.map(l => l.result)));
			const groupKey = stringKeyFromNumbs(GroupType.WorkPackage, AllWpsId);
			safeSetToNestedDict(this.resourcesConsumption, groupKey, resKey, lazyChart);

			if (isKeyStartWith(resKey, ResourceType.Labour)) {
				glLaboursCHarts.push(lazyChart);
			}
		}
	
		if (glLaboursCHarts.length > 0) {
			const groupKey = stringKeyFromNumbs(GroupType.WorkPackage, AllWpsId);
			const resKey = stringKeyFromNumbs(ResourceType.Labour, AllLaboursId);
			const chartFactory = () => this.calcLaboursSummaryChart(glLaboursCHarts.map(l => l.result));
			safeSetToNestedDict(this.resourcesConsumption, groupKey, resKey, new LazyResult(chartFactory));
		}



		// calculate gantt lines
		for (const wp of animData.workPackages) {
			
			let wpInternalRanges: Range[] = [];

			for (let i = 0; i < wp.activityGroups.length; ++i) {
				const ag = wp.activityGroups[i];
				this.slacks[GroupType.ActivityGroup][ag.id] = {
					[SlackType.FreeSlack]: {
						forwardSlack: ag.free_slack, backSlack: ag.free_back_slack
					}
				}
				
				if(this.slacks[GroupType.ActivityGroup][ag.id]){
					this.slacks[GroupType.ActivityGroup][ag.id][SlackType.TotalSlack] = {
						forwardSlack: ag.total_slack, 
						backSlack: ag.total_back_slack
					}
				} else {
					this.slacks[GroupType.ActivityGroup] = {
						[ag.id]: {
							[SlackType.TotalSlack]: {
								forwardSlack: ag.free_slack, backSlack: ag.free_back_slack
							}
						}
					}
				}

				const colors = this.colorGenerator.getWorkColors(ag.name);
				this.actGrsColors[ag.id] = colors;

				let agInternalTimes: Range[] = [];
				
				for (const act of ag.activities) {

					this.acsColors[act.id] = colors;
					
					let activityInternalRanges: Range[] = [];
					for (let i = 0; i < act.times.length; i += 3){
						activityInternalRanges.push(new Range(act.times[i], act.times[i+1]));
					}

					activityInternalRanges = sortAndCollapseRanges(activityInternalRanges);
					this.flatTimes[GroupType.Activity][act.id] = new FlatTimes(act.id, GroupType.Activity, activityInternalRanges);
					extendArray(agInternalTimes, activityInternalRanges);
				}

				agInternalTimes = sortAndCollapseRanges(agInternalTimes);
				this.flatTimes[GroupType.ActivityGroup][ag.id] = new FlatTimes(ag.id, GroupType.ActivityGroup, agInternalTimes);
				extendArray(wpInternalRanges, agInternalTimes);
			}

			wpInternalRanges = sortAndCollapseRanges(wpInternalRanges);
			this.flatTimes[GroupType.WorkPackage][wp.id] = new FlatTimes(wp.id, GroupType.WorkPackage, wpInternalRanges);
			this.totalDuration = Math.max(this.totalDuration,this.flatTimes[GroupType.WorkPackage][wp.id].end);
		}
	}

	getTotalDuration() {
		return this.totalDuration;
	}

	getWorkGroupColors(lineType: GroupType, id: number) : Colors {
		if (lineType === GroupType.Activity) {
			return this.acsColors[id];
		} else if (lineType === GroupType.ActivityGroup) {
			return this.actGrsColors[id];
		}
		return {
			main: defaultWpMainColor,
			off: defaultWpOffColor
		};
	}

	getWorkGroupDates(lineType: GroupType, id: number) : {startDay:number, endDay:number} {
		const ft = this.flatTimes[lineType][id];
		return { startDay: ft.start, endDay: ft.end };
	}

	getGanttLineInfo(type: GroupType, id: number) : GanttLineInfo | null {
		const ft = this.flatTimes[type][id];
		if (!ft) {
			return null;
		}
		return {
			sourceId: ft.id,
			lineType: ft.type,
			startDay: ft.start,
			endDay: ft.end,
			activeDuration: ft.activeTime,
			colors: this.getWorkGroupColors(ft.type, ft.id)
		}
	}

	setTimeRange(fromDayTime: number, rangeInDays: number) {
		if (isNaN(fromDayTime)) {
			console.error('invalid fromDayTime', fromDayTime);
			return;
		}
		if (!(rangeInDays > 0)) {
			console.error('invalid rangeInDays', fromDayTime);
			return;
		}

		this.version++;
		this.from = fromDayTime;
		this.to = fromDayTime + rangeInDays;
		this.setGanttPixelsRange(this.ganttPixelsRange);
	}

	setGanttPixelsRange(ganttRangeInPixels: number) {
		if (!(ganttRangeInPixels > 0)) {
			console.error('invalid rangeInPixels', ganttRangeInPixels);
			return;
		}
		
		const rangeInDays = this.to - this.from;
		
		this.ganttPixelsRange = ganttRangeInPixels;
		this.ganttPixelsPerDay = this.ganttPixelsRange / rangeInDays;

		const newMinRange = calcMinTimerange(rangeInDays, ganttRangeInPixels, MinGanttPixels);
		const newMinEmptyRange = calcMinTimerange(rangeInDays, ganttRangeInPixels, MinGanttEmptyPixels);
		if (newMinRange !== this.minGanttRange || newMinEmptyRange !== this.minGanttEmptyRange) {
			this.minGanttRange = newMinRange;
			this.minGanttEmptyRange = newMinEmptyRange;
			
			for (const category in this.cachedCollapsedTimes) { // invalidate cache
				const categoryCache = this.cachedCollapsedTimes[category];
				for (const id in categoryCache) {
					categoryCache[id].length = 0;
				}
			}
		}
	}


	calculateGanttLineCoordinatesWithSlacks(lineType: GroupType ,slackType: SlackType, lineID: number): GanttLineCoordinatesWithSLack | null {
		const ganttLineCoordinates = this.calculateGanttLineCoords(lineType, lineID);
		if(!ganttLineCoordinates){
			return null;
		}
		const coordinates: GanttLineCoordinatesWithSLack = {
			ganttCoordinates: ganttLineCoordinates,
			slackCoordinates: null,
		}

		if(slackType === SlackType.Disabled){
			return coordinates;
		}
		
		const slacksForLine = this.slacks[lineType][lineID];
		if(slacksForLine && slacksForLine[slackType]){
			const {forwardSlack, backSlack} = slacksForLine[slackType];
			const backSlackLength = backSlack * this.ganttPixelsPerDay;
			const left = ganttLineCoordinates.start - backSlackLength;
			const width = forwardSlack * this.ganttPixelsPerDay + ganttLineCoordinates.width + backSlackLength;
			const slackCoordinates = {
				left,
				width,
				type: slackType,
			}
			coordinates.slackCoordinates = slackCoordinates;
		}
		return coordinates;
	}


	calculateGanttLineCoords(type: GroupType, id:number) : GanttLineHorizontalCoords | null {
		const flatTimes = this.flatTimes[type][id];
		if (flatTimes && flatTimes.start <= this.to && flatTimes.end >= this.from) {
			return this.flatTimesToGanttLines(flatTimes);
		}
		return null;
	}

	public calculateFullWorkTime(type: GroupType, id: number): number {
		const flatTimes = this.flatTimes[type][id];
		if(flatTimes) {
			return flatTimes.end - flatTimes.start;
		}
		return null;
	}  

	private flatTimesToGanttLines(flatTimes: FlatTimes): GanttLineHorizontalCoords {
		const flatInternals = this.calcFlatTimesCollapsed(flatTimes);
		const flatStart = flatTimes.start;
		const pixelsPerDay = this.ganttPixelsPerDay;
		const from = this.from;
		const to = this.to;
		const internalOffsets: number[] = [];
		let i = 0;
		//todo: some heuristic for big arrays (smth like binary search or small offsets table)
		for (; i < flatInternals.length && flatInternals[i + 1] <= from; i += 2) {
		}
		const internalsStartIndex = i;
		for (; i < flatInternals.length; i += 2) {
			const start = flatInternals[i];
			if (start >= to) {
				break;
			}
			const end = flatInternals[i + 1];
			internalOffsets.push(
				(start - flatStart) * pixelsPerDay,
				(end - start) * pixelsPerDay,
			)
		}
		const lineEnd = flatInternals.length > 0 ? flatInternals[flatInternals.length - 1] : flatTimes.end;
		return {
			sourceId: flatTimes.id,
			lineType: flatTimes.type,
			start: (flatStart - from) * pixelsPerDay,
			width: (lineEnd - flatStart) * pixelsPerDay,
			internalLinesStartIndex : internalsStartIndex,
			internalLinesSizes: internalOffsets
		}
	}

	private calcFlatTimesCollapsed(flatTimes: FlatTimes): number[] {
		let cached = this.cachedCollapsedTimes[flatTimes.type][flatTimes.id];
		if (cached === undefined) {
			cached = (this.cachedCollapsedTimes[flatTimes.type][flatTimes.id] = []);
		}
		if (cached.length === 0) {
			const minRange = this.minGanttRange;
			const minEmptyRange = this.minGanttEmptyRange;
			cached.push(flatTimes.internalTimes[0]);
			cached.push(Math.max(flatTimes.internalTimes[0] + minRange, flatTimes.internalTimes[1]));
			for (let i = 2, j = 2; i < flatTimes.internalTimes.length; i += 2) {
				const prevEnd = cached[j - 1];
				const is = flatTimes.internalTimes[i];
				const ie = flatTimes.internalTimes[i + 1];
				if (is - prevEnd < minEmptyRange) {
					if (ie > prevEnd) {
						cached[j - 1] = flatTimes.internalTimes[i + 1]; // move previous end further
					}
				} else {
					cached[j] 	 	= is;
					cached[j + 1] 	= Math.max(is + minRange, flatTimes.internalTimes[i + 1]);
					j += 2;
				}
			}
		}
		return cached;
	}

	setActiveGroup(type: GroupType, id: number) : boolean {
		const byType = this.flatTimes[type];
		const times = byType && byType[id];
		let isolated: FlatTimes | null = null;
		if (times) {
			isolated = times;
		}
		this.isolated = isolated;
		return isolated !== null;
	}

	makeAllActive() : void {
		this.isolated = null;
	}

	getActiveTimeframe(): { startDay: number, endDay: number } {
		if (this.isolated) {
			return {
				startDay: this.isolated.start,
				endDay: this.isolated.end
			}
		} else {
			return {
				startDay: 0,
				endDay: this.totalDuration
			}
		}
	}
	
	private getActiveIsolation(): { grType: GroupType, grId: number } {
		if (this.isolated) {
			return {
				grType: this.isolated.type,
				grId: this.isolated.id
			}
		} else {
			return {
				grType: GroupType.WorkPackage,
				grId: AllWpsId
			}
		}
	}

	isResourceAvailable(resType: ResourceType, resId: number) : boolean {
		const { grType, grId } = this.getActiveIsolation();
		return this._isResourceAvailable(grType, grId, resType, resId);
	}

	private _isResourceAvailable(groupType: GroupType, groupId: number, resType: ResourceType, resId: number): boolean {
		const grKey = stringKeyFromNumbs(groupType, groupId);
		const resKey = stringKeyFromNumbs(resType, resId);
		const gr = this.resourcesConsumption[grKey];
		return (gr !== undefined) && (gr[resKey] !== undefined);
	}

	getResourcesDescriptionsOfType(type: ResourceType) : ResourceDescription[] {
		return Array.from(this.resourceDescriptions[type].values());
	}

	getResourceDescription(type: ResourceType, id: number) : ResourceDescription {
		return this.resourceDescriptions[type].get(id)!;
	}

	getResourcesForActivity(id: number): ActivityResources | null {
		const res : ActivityResBrief = this.activitiesResInfo[id];
		if (!res) {
			return null;
		}
		const labours = res.resources[ResourceType.Labour].map(id => this.getResourceDescription(ResourceType.Labour, id));
		const plants = res.resources[ResourceType.Plant].map(id => this.getResourceDescription(ResourceType.Plant, id));
		const materials = res.resources[ResourceType.Material].map(id => this.getResourceDescription(ResourceType.Material, id));

		const result: ActivityResources = {
			activityId: id,
			labours: labours,
			totalLabourTime: res.laboursTimeTotal,
			plants: plants,
			materials: materials,
			materialsAmounts: res.materialsTotalAmounts
		}
		return result;
	}

	calculateResourceConsumptionForTimerange(resType: ResourceType, resId: number, startDay: number, endDay: number):
		{ amount: number, max: number }
	{
		const { grType, grId } = this.getActiveIsolation();
		let resChart = this.getResourceDemand(grType, grId, resType, resId);
		let amount = 0;
		let max = 0;
		if (resChart && endDay > startDay) {
			const intervals = resChart.intervals;
			if (resType === ResourceType.Material) {
				{
					console.assert(!resChart.intervals, 'for materials resDemand intervals should be null');
					const _dayD = (startDay > resChart.perDayStart ? startDay - resChart.perDayStart : resChart.perDayStart - startDay) % 1;
					console.assert(_dayD < TimesEpsilon || _dayD > 1 - TimesEpsilon, 'startDay should be aligned to whole days, results are gonna be incorrect', _dayD, startDay, resChart.perDayStart);
					console.assert(Math.abs((endDay - startDay) - Math.round(endDay - startDay)) < TimesEpsilon && endDay > startDay, 'endDay - startDay should be positive integer', endDay, startDay);
				}

				const daysValues = resChart.perDayDemand;
				const startIndex = Math.max(0, Math.round(startDay - resChart.perDayStart));
				const endIndex = Math.min(daysValues.length, Math.round(endDay - resChart.perDayStart));
				for (let i = startIndex; i < endIndex; ++i) {
					const val = daysValues[i];
					const start = resChart.perDayStart + i;
					const end = start + 1;
					if (start - startDay > -TimesEpsilon && endDay - end > -TimesEpsilon) {
						amount += val;
						max = Math.max(val, max);
					}
				}
			} else if (resType === ResourceType.Labour && resId === AllLaboursId) {
				const daysValues = resChart.perDayDemand;
				const startIndex = Math.max(0, Math.round(startDay - resChart.perDayStart));
				const endIndex = Math.min(daysValues.length, Math.round(endDay - resChart.perDayStart));
				for (let i = startIndex; i < endIndex; ++i){
					const val = daysValues[i];
					if (val > max) {
						max = val;
					}
				}
				amount = -1;
			} else if (intervals) {
				for (let i = 0; i < intervals.length; i += 3){
					const start = intervals[i];
					const end = intervals[i + 1];
					if (end < startDay || start > endDay) {
						continue;
					}
					const value = intervals[i + 2];
					const s = Math.max(start, startDay);
					const e = Math.min(end, endDay);
					amount += value * (e - s);
					max = Math.max(value, max);
				}
			} else {
				console.error('intervals should always exist for non material resources');
			}
			
		}
		return { amount: amount, max: max };
	}

	calculateTimeboundChart(resType: ResourceType, resId: number, rangeInPixels:number): SingleChartData | null {
		return this.calcChartForTimerangeCollapsed(resType, resId, this.from, this.to, rangeInPixels, true);
	}

	calculateFullChart(resType: ResourceType, resId: number, rangeInPixels:number): SingleChartData | null {
		const { startDay, endDay } = this.getActiveTimeframe();
		return this.calcChartForTimerangeCollapsed(resType, resId, startDay, endDay, rangeInPixels, false);
	}

	private calcChartForTimerangeCollapsed (
		resType: ResourceType, resId: number,
		startDay: number, endDay: number,
		rangeInPixels: number,
		includeInternalTimes: boolean
	): SingleChartData | null {

		const { grType, grId } = this.getActiveIsolation();
		let resChart = this.getResourceDemand(grType, grId, resType, resId);
		if (resChart === null) {
			return null;
		}

		let { mergeStart, mergeRange } = this.calcMinTimerangeAndStartForMerged(startDay, endDay, rangeInPixels, MinChartWidthPixels);
		if (resId === AllLaboursId) {
			mergeRange = 1;
			mergeStart = this.calcStartTimeForRange(startDay, mergeRange);
		}
		const mergeValues = getMergedTimesPortionFromResDemand(mergeStart, mergeRange, endDay, resChart);

		const minInternalDuration = calcMinTimerange(endDay - startDay, rangeInPixels, MinInternalChartWidthPixels);
		if (minInternalDuration > 0.5 || !resChart.intervals) { // force disable internal times if chart is too small
			includeInternalTimes = false;
		}

		let internalTimesResult: number[] | null  = null;

		if (includeInternalTimes) {
			internalTimesResult = [];

			const sourceIntervals = resChart.intervals!;
			let sourceStart = sourceIntervals.length;
			let sourceEnd = sourceIntervals.length;
	
			//find start
			for (let i = 1; i < sourceIntervals.length; i += 3) {
				const end = sourceIntervals[i];
				if (end >= mergeStart) {
					sourceStart = i - 1;
					break;
				}
			}
			
			let prevEndI = -2; // pointer to end of last interval
			for (let i = sourceStart; i < sourceEnd; i += 3){

				const start = sourceIntervals[i];
				const end = Math.max(start + minInternalDuration, sourceIntervals[i + 1]);
				const val = sourceIntervals[i + 2];

				if (start > endDay) {
					break;
				}
				
				if (prevEndI > 0 && (end - internalTimesResult[prevEndI] < minInternalDuration)) {
					internalTimesResult[prevEndI] = end;
					internalTimesResult[prevEndI + 1] = Math.max(val, internalTimesResult[prevEndI + 1]);
				} else {
					internalTimesResult.push(start);
					internalTimesResult.push(end);
					internalTimesResult.push(val);
					prevEndI += 3;
				}
			}
		}

		return {
			timeFrom: startDay,
			timeTo: endDay,
			globalMax: chooseChartMaxFromMergeRange(resChart, mergeRange),
			mergeStart: mergeStart,
			mergeDuration: mergeRange,
			mergedValues: mergeValues,
			timesInternals: internalTimesResult,
		};
	}

	calculateTimeboundLaboursChart(rangeInPixels: number): MultiChartData | null {
		return this.calcLaboursChartForTimerange(this.from, this.to, rangeInPixels);

	}

	calculateFullLaboursChart(rangeInPixels: number): MultiChartData | null {
		const { startDay, endDay } = this.getActiveTimeframe();
		return this.calcLaboursChartForTimerange(startDay, endDay, rangeInPixels);
	}

	private calcLaboursSummaryChart(resCharts: ResDemand[]): ResDemand {
		let startTime = Infinity;
		let endTime = 0;

		for (const c of resCharts) {
			const intervals = c.intervals!;
			if (intervals[0] < startTime) {
				startTime = intervals[0];
			}
			if (intervals[intervals.length - 2] > endTime) {
				endTime = intervals[intervals.length - 2];
			}
		}
		const daysMergeStart = this.calcStartTimeForRange(startTime, 1);
		const weeksMergeStart = this.calcStartTimeForRange(startTime, 7);
		let daysMerge: number[] = [];
		let weeksMerge: number[] = [];
		for (const c of resCharts) {
			const daysMergeValues = getMergedTimesPortionFromResDemand(daysMergeStart, 1, endTime, c);
			const weeksMergeValues = getMergedTimesPortionFromResDemand(weeksMergeStart, 7, endTime, c);
			
			if (daysMerge.length === 0) {
				daysMerge = daysMergeValues;
				weeksMerge = weeksMergeValues;
			} else {
				for (let i = 0; i < daysMergeValues.length; ++i) {
					daysMerge[i] += daysMergeValues[i];
				}
				for (let i = 0; i < weeksMergeValues.length; ++i) {
					weeksMerge[i] += weeksMergeValues[i];
				}
			}
		}

		return {
			dailyMax: getNonNegativeMaxFromArray(daysMerge),
			weeklyMax: getNonNegativeMaxFromArray(weeksMerge),
			intervals: null,
			perDayDemand: daysMerge,
			perDayStart: daysMergeStart,
			perWeekStart: weeksMergeStart,
			perWeekDemand: weeksMerge,
			resId: AllLaboursId,
			resType: ResourceType.Labour,
			total: 0 // shouldn't be asked
		}
	}

	private calcLaboursChartForTimerange(
		startDay: number, endDay: number,
		rangeInPixels: number,
		maxMergeRange: number = 1
	): MultiChartData | null {
		const { grType, grId } = this.getActiveIsolation();
		const allLaboursChart = this.getResourceDemand(grType, grId, ResourceType.Labour, AllLaboursId);
		if (!allLaboursChart) {
			return null;
		}
		const laboursIds: number[] = [];
		const mergeValues2d: number[][] = [];
		let { mergeStart, mergeRange } = this.calcMinTimerangeAndStartForMerged(startDay, endDay, rangeInPixels, MinChartWidthPixels);
		mergeRange = Math.min(maxMergeRange, mergeRange);

		const laboursDescr = this.resourceDescriptions[ResourceType.Labour];
		let prevValues: number[] | null = null;
		const laboursReversed = Array.from(laboursDescr.values()).reverse();
		for (const d of laboursReversed) {
			const resChart = this.getResourceDemand(grType, grId, d.type, d.id);
			if (resChart === null) {
				continue;
			}
			laboursIds.push(d.id);
			const mergeValues = calcMergedValuesFromPerDayDemand(
				mergeStart, mergeRange, endDay,
				resChart.perDayStart, resChart.perDayDemand,
				ResourceType.Labour
			);
			if (prevValues !== null) {
				for (let i = 0; i < mergeValues.length; ++i) {
					const prev = prevValues[i];
					mergeValues[i] += prev;
				}
			}
			mergeValues2d.push(mergeValues);
			prevValues = mergeValues;
		}
		const globalMax = chooseChartMaxFromMergeRange(allLaboursChart, mergeRange);

		return {
			timeFrom: startDay,
			timeTo: endDay,
			globalMax: globalMax,
			mergeStart: mergeStart,
			mergeDuration: mergeRange,
			mergedValues2dCumm: mergeValues2d,
			mergeIds: laboursIds
		}
	}

	private getResourceDemand(groupType: GroupType, groupId: number, resType: ResourceType, resId: number): ResDemand | null {
		const grKey = stringKeyFromNumbs(groupType, groupId);
		const resKey = stringKeyFromNumbs(resType, resId);
		const resDict = this.resourcesConsumption[grKey];
		const resChart = resDict && resDict[resKey];
		return (resChart && resChart.result) || null;
	}

	private calcMinTimerangeAndStartForMerged(startDay: number, endDay: number, rangeInPixels: number, minPixels: number)
		: {mergeStart:number, mergeRange:number}
	{
		const range = endDay - startDay;
		const pixelsPerDay = rangeInPixels / range;
		const days = minPixels / pixelsPerDay;

		const rangeNice = days > 4 ? 7 : 1;
		const startNice = this.calcStartTimeForRange(startDay, rangeNice);

		return {
			mergeStart: startNice,
			mergeRange: rangeNice
		};
	}

	private calcStartTimeForRange(startDay: number, rangeDays: number) {
		console.assert(Number.isInteger(rangeDays) && rangeDays > 0, 'range should be positive integer', rangeDays);
		if (this.toMondayOffsetDays > -TimesEpsilon) {
			return startDay;
		}
		const startRanges = Math.trunc(startDay / rangeDays);
		const inRangeCompens = (this.toMondayOffsetDays) % rangeDays;
		let res = (startRanges * rangeDays + inRangeCompens);
		
		if (startDay - res >= rangeDays) {
			// this can happen beacuse of bullshit precision like 265.9999999 instead of 266
			res += rangeDays;
		}
		console.assert(startDay - res < rangeDays, 'nice start should be less than rnage days before');
		return res;
	}
		
	private combineCharts(charts: ResDemand[]): ResDemand {
		if (charts.length === 1) {
			return charts[0];
		} else if (charts.length === 0) {
			throw 'zero charts to combine'
		}

		console.assert(charts.every(ch => ch.resType === charts[0].resType), 'combining charts of the same type');
		charts.sort((ch1, ch2) => ch1.perDayStart - ch2.perDayStart);
		const perDayStart = charts[0].perDayStart;
		const perWeekStart = charts[0].perWeekStart;

		let intervalsResult: number[] | null = null;
		let perDayResult: number[];
		let perWeekResult: number[];
		let total = 0;

		if (charts[0].resType === ResourceType.Material) {

			perDayResult = charts[0].perDayDemand;
			for (let i = 1; i < charts.length; ++i) {
				perDayResult = combineMaterialChartsDays(perDayStart, perDayResult, charts[i].perDayStart, charts[i].perDayDemand);
			}

			const toMondayOffset = Math.round(perDayStart - perWeekStart);
			const weeksLength = Math.ceil(((perDayStart + perDayResult.length) - perWeekStart) / 7);
			perWeekResult = new Array(weeksLength).fill(0.0);
			
			for (let i = 0; i < perDayResult.length; ++i) {
				const demand = perDayResult[i];
				const weekIndex = (i - toMondayOffset) / 7 | 0;
				perWeekResult[weekIndex] += demand;
				total += demand;
			}

		} else {

			intervalsResult = charts[0].intervals!;
			for (let i = 1; i < charts.length; ++i) {
				intervalsResult = combine2ChartsTriples(intervalsResult, charts[i].intervals!);
			}

			const intervals = intervalsResult!;
			for (let i = 0; i < intervals.length; i += 3) {
				const start = intervals[i];
				const end = intervals[i + 1];
				const demand = intervals[i + 2];
				total += (end - start) * demand;
			}
			perDayResult = calcCrewConsumptionForPeriod(intervalsResult, perDayStart, 1);
			perWeekResult = calcCrewConsumptionForPeriod(intervalsResult, perWeekStart, 7);
		}

		const resChartsSumTotal = charts.map(ch => ch.total).reduce((sum: number, curr: number) => sum + curr);
		const inaccuracyTotal = resChartsSumTotal - total;
		console.assert(Math.abs(inaccuracyTotal) < 0.001, 'combined chart total consumption is inaccuracy is too big', inaccuracyTotal);

		let maxDaily = getNonNegativeMaxFromArray(perDayResult);
		let maxWeekly = getNonNegativeMaxFromArray(perWeekResult);

		return {
			intervals: intervalsResult,
			resId: charts[0].resId,
			resType: charts[0].resType,
			total: total,
			perDayDemand: perDayResult,
			perDayStart: perDayStart,
			dailyMax: maxDaily,
			perWeekStart: perWeekStart,
			perWeekDemand: perWeekResult,
			weeklyMax: maxWeekly
		}
	}

};

function calcMinTimerange(range: number, rangeInPixels: number, minPixels: number) {
	const pixelsPerDay = rangeInPixels / range;
	// const devicePixelRatio = window.devicePixelRatio || 1;
	return minPixels / pixelsPerDay;// / devicePixelRatio;
}

function safeAddToArrayByKey<T>(dict: DictionaryStr<T[]>, key: string, obj:T) {
	const arr = dict[key] || (dict[key] = []);
	arr.push(obj);
}

function safeSetToNestedDict<T>(dict: DictionaryStr<DictionaryStr<T>>, key1: string, key2: string, obj:T) {
	const nestedDict = dict[key1] || (dict[key1] = {});
	nestedDict[key2] = obj;
}

interface ResDemand {
	readonly resId: number;
	readonly resType: ResourceType;
	readonly intervals: TimesTriples | null; // for materials should be null
	readonly total: number;
	readonly perDayStart: number;
	readonly perDayDemand: number[];
	readonly dailyMax: number;
	readonly perWeekStart: number,
	readonly perWeekDemand: number[],
	readonly weeklyMax: number;
}

function calcCrewConsumptionForPeriod(times: TimesTriples, periodStart: number, periodRange:number): number[] {
	const endTime = times[times.length - 2];
	const perDayDemand: number[] = new Array(Math.ceil((endTime - periodStart) / periodRange)).fill(0.0);
	for (let i = 0; i < times.length; i += 3) {
		const start = times[i];
		const end = times[i + 1];
		const consumption = times[i + 2];
		const startIndex = (start - periodStart) / periodRange | 0;
		const endIndex = (end - periodStart) / periodRange | 0;
		if (startIndex === endIndex) {
			perDayDemand[startIndex] = Math.max(consumption, perDayDemand[startIndex]);
		} else {
			for (let j = startIndex; j <= endIndex; ++j){
				perDayDemand[j] = Math.max(consumption, perDayDemand[j]);
			}
		}
	}
	return perDayDemand;
}


	// this function should only be called for crew types for activity groups - where resource consumption is constant (at least by payment)
function crewResDemandFromTimesCouples(times: TimesCouples, consumption: number, id: number, startDayTime:number, startWeekTime: number, resType: ResourceType): ResDemand {
	console.assert(resType === ResourceType.Labour || resType === ResourceType.Plant, 'resource is of crew type');
	const intervalsN = times.length / 2;
	const intervals: TimesTriples = new Array(intervalsN * 3);
	let total = 0;

	for (let i = 0; i < intervalsN; i++) {
		const it = i * 2;
		const start = times[it];
		const end = times[it + 1];

		const ii = i * 3;
		intervals[ii] = start;
		intervals[ii + 1] = end;
		intervals[ii + 2] = consumption;
		total += safeNonNegativeNumber(end - start) * consumption;
	}

	const endTime = times[times.length - 1];
	const perDayDemand: number[] = new Array(Math.ceil(endTime - startDayTime)).fill(0.0);
	const perWeekDemand: number[] = new Array(Math.ceil((endTime - startWeekTime) / 7)).fill(0.0);
	for (let i = 0; i < times.length; i += 2) {
		const start = times[i];
		const end = times[i + 1];
		const startDayIndex = (start - startDayTime) | 0;
		const endDayIndex = (end - startDayTime) | 0;
		if (startDayIndex === endDayIndex) {
			perDayDemand[startDayIndex] = consumption;
		} else {
			for (let j = startDayIndex; j <= endDayIndex; ++j){
				perDayDemand[j] = consumption;
			}
		}
		const startWeekIndex = (start - startWeekTime) | 0;
		const endWeekIndex = (end - startWeekTime) | 0;
		if (startWeekIndex === endDayIndex) {
			perWeekDemand[startWeekIndex] = consumption;
		} else {
			for (let j = startWeekIndex; j <= endWeekIndex; ++j){
				perWeekDemand[j] = consumption;
			}
		}
	}

	return {
		resId: id,
		intervals: intervals,
		resType: resType,
		total: total,
		perDayDemand: perDayDemand,
		perDayStart: startDayTime,
		dailyMax: consumption,
		perWeekStart: startWeekTime,
		perWeekDemand: perWeekDemand,
		weeklyMax: consumption
	}
}

function addMaterialConsumptionForPeriod(start: number, end: number, consForPeriod: number, periodStartTIme: number, periodRange: number, demandResult:number[]){
	const dur = end - start;
	const startIndex = (start - periodStartTIme) / periodRange | 0;
	const endIndex = (end - periodStartTIme) / periodRange | 0;
	if (startIndex === endIndex) {
		demandResult[startIndex] += consForPeriod;
	} else {
		for (let j = startIndex; j <= endIndex; ++j){
			const periodStart = periodStartTIme + j * periodRange;
			const periodEnd = periodStart + periodRange;
			const durForperiod = Math.min(periodEnd, end) - Math.max(periodStart, start);
			if (durForperiod > 0) {
				const consForWeek = consForPeriod * durForperiod / dur;
				demandResult[j] += consForWeek;
			}
		}
	}

}

// this function should only be called for materials demand for activities
function matResDemandFromTimesTriples(times: TimesTriples, resMultiplier: number, id:number, startDayTime:number, startWeekTime:number) : ResDemand {
	let total = 0;
	const endTime = times[times.length - 2];
	const perDayDemand: number[] = new Array(Math.ceil(endTime - startDayTime)).fill(0.0);
	const perWeekDemand: number[] = new Array(Math.ceil((endTime - startWeekTime)/7)).fill(0.0);

	for (let i = 0; i < times.length; i += 3) {
		const start = times[i];
		const end = times[i + 1];
		const dur = end - start;
		const area = (times[i + 2] || 1) * dur;
		const consForPeriod = area > 0 || times.length > 3 ? area * resMultiplier : resMultiplier; // todo: do not repeat logic of finding zero intervals here and in cdp constructor

		addMaterialConsumptionForPeriod(start, end, consForPeriod, startDayTime, 1, perDayDemand);
		addMaterialConsumptionForPeriod(start, end, consForPeriod, startWeekTime, 7, perWeekDemand);

		total += consForPeriod;
	}

	let dailyMax = getNonNegativeMaxFromArray(perDayDemand);
	let weeklyMax = getNonNegativeMaxFromArray(perWeekDemand);

	return {
		resId: id,
		intervals: null,
		resType: ResourceType.Material,
		total: total,
		perDayDemand: perDayDemand,
		perDayStart: startDayTime,
		dailyMax: dailyMax,
		perWeekStart: startWeekTime,
		perWeekDemand: perWeekDemand,
		weeklyMax: weeklyMax
	}
}

const TimesEpsilon = 1 / 24 / 60 / 60; // 1 second

function combine2ChartsTriples(int1: TimesTriples, int2: TimesTriples): TimesTriples {
	const resultTimes: TimesTriples = [];
	
	let i1 = 0, i2 = 0;
	let i1Incr = 0, i2Incr = 0;
	let currIntervalEnd = 0;
	while (i1 < int1.length || i2 < int2.length) {

		// if not intersecting, populate and move next
		if (i2 >= int2.length || int1[i1 + 1] < int2[i2]) {
			resultTimes.push(Math.max(int1[i1 + 0], currIntervalEnd));
			resultTimes.push(int1[i1 + 1]);
			resultTimes.push(int1[i1 + 2]);
			i1 += 3;

			continue;
		
		} else if (i1 >= int1.length || int2[i2 + 1] <  int1[i1]) {
			resultTimes.push(Math.max(int2[i2 + 0], currIntervalEnd));
			resultTimes.push(int2[i2 + 1]);
			resultTimes.push(int2[i2 + 2]);
			i2 += 3;

			continue;
			
		}
		
		const i1Start = Math.max(int1[i1 + 0], currIntervalEnd); // clamp to previous end
		const i1End = 			int1[i1 + 1];
		const i1Val = 			int1[i1 + 2];
		
		const i2Start = Math.max(int2[i2 + 0], currIntervalEnd); // clamp to previous end
		const i2End = 			int2[i2 + 1];
		const i2Val = 			int2[i2 + 2];

		let secondIntervalStart = 0;

		if (Math.abs(i1Start - i2Start) < TimesEpsilon) { // same start, easisest path, no first interval
			secondIntervalStart = i1Start;

		} else if (i2Start < i1Start) {
			secondIntervalStart = i1Start;
			
			resultTimes.push(i2Start);
			resultTimes.push(secondIntervalStart);
			resultTimes.push(i2Val);
		} else {
			secondIntervalStart = i2Start;

			resultTimes.push(i1Start);
			resultTimes.push(secondIntervalStart);
			resultTimes.push(i1Val);
		}

				
		let endIntervalVal = 0;

		// find interval that ends 1st (or if both)
		if (Math.abs(i1End - i2End) < TimesEpsilon) {
			i1Incr = 3;
			i2Incr = 3;
			currIntervalEnd = i1End;
			endIntervalVal = i1Val + i2Val;
		} else if (i1End < i2End) {
			i1Incr = 3;
			i2Incr = 0;
			currIntervalEnd = i1End;
			endIntervalVal = i1Val;
			if (i2End >= i1End) {
				endIntervalVal += i2Val;
			}
		} else {
			i1Incr = 0;
			i2Incr = 3;
			currIntervalEnd = i2End;
			endIntervalVal = i2Val;
			if (i1End >= i2End) {
				endIntervalVal += i1Val;
			}
		}

		resultTimes.push(secondIntervalStart);
		resultTimes.push(currIntervalEnd);
		resultTimes.push(endIntervalVal);

		// increment pointers
		i1 += i1Incr;
		i2 += i2Incr;
	}

	return resultTimes;
}

function combineMaterialChartsDays(start1: number, values1: number[], start2: number, values2: number[]): number[] {
	console.assert(start1 <= start2, 'combine material charts days: charts should be sorted');
	const end = Math.max(start1 + values1.length, start2 + values2.length);
	const totalLength = Math.round(end - start1);
	const d = Math.round(start2 - start1);
	const resultArray: number[] = new Array(totalLength).fill(0.0);
	for (let i = 0; i < values1.length; ++i){
		resultArray[i] = values1[i];
	}
	for (let i = 0; i < values2.length; ++i){
		const ind = i + d;
		resultArray[ind] += values2[i];
	}
	return resultArray;
}

function safeNonNegativeNumber(n: number): number {
	if (!(n >= 0)) {
		console.error('number is not positive');
		return 0;
	}
	return n;
}

function createResourcesNumbersDict() : DictionaryNumb<number[]> {
	return {
		[ResourceType.Labour]: [],
		[ResourceType.Plant]: [],
		[ResourceType.Material]: []
	};
}

function chooseChartMaxFromMergeRange(chart:ResDemand, mergeRange: number) {
	let chartMax = chart.dailyMax;
	if (mergeRange === 7) {
		chartMax = chart.weeklyMax;
	} else if (mergeRange !== 1) {
		console.error('invalid res chart mergeRange', mergeRange);
	}
	return chartMax;
}

function getNonNegativeMaxFromArray(values: number[]) {
	let max = 0;
	for (const v of values) {
		if (v > max) {
			max = v;
		}
	}
	return max;
}

function getMergedTimesPortionFromResDemand(mergeStart: number, mergeRange: number, endDay: number, resDemand: ResDemand): number[] {
	const resultLength = Math.ceil((endDay - mergeStart) / mergeRange);
	const result = new Array(resultLength).fill(0.0);
	let resStart: number;
	let resValues: number[]
	if (mergeRange === 1) {
		resStart = resDemand.perDayStart;
		resValues = resDemand.perDayDemand;
	} else if (mergeRange === 7) {
		resStart = resDemand.perWeekStart;
		resValues = resDemand.perWeekDemand;
	} else {
		console.error('unsupported merge range', mergeRange);
		return [];
	}
	const offsetToResArray = Math.round((mergeStart - resStart) / mergeRange);
	const startIndex = Math.max(0, -offsetToResArray);
	const upperBound = Math.min(result.length, resValues.length - offsetToResArray);
	for (let i = startIndex; i < upperBound; ++i){
		result[i] = resValues[i + offsetToResArray];
	}
	return result;
}

function calcMergedValuesFromPerDayDemand(mergeStart: number, mergeRange: number, endDay: number, perDayStart:number, perDayDemand: number[], resType: number): number[] {
	const arrLength: number = Math.ceil((endDay - mergeStart) / mergeRange);
	const mergeValues: number[] = new Array(arrLength).fill(0.0);
	const offsetToCachedDays = Math.round(mergeStart - perDayStart);
	const startI = Math.max(0, -1 * offsetToCachedDays);
	const endI = Math.min(mergeValues.length * mergeRange, perDayDemand.length - offsetToCachedDays);
	for (let i = startI; i < endI; ++i){
		const resIndex = i + offsetToCachedDays;
		const resValue = perDayDemand[resIndex];
		const niceIndex = i / mergeRange | 0;
		if (resType === ResourceType.Material) {
			mergeValues[niceIndex] += resValue;
		} else {
			mergeValues[niceIndex] = Math.max(mergeValues[niceIndex], resValue);
		}
	}
	return mergeValues;
}

function crewTypeToResourceType(crewType: CrewMemberType) : ResourceType | null {
	if (crewType === CrewMemberType.Labor) {
		return ResourceType.Labour;
	}
	if (crewType === CrewMemberType.Plant) { 
		return ResourceType.Plant;
	}
	console.error('unkown crewType', crewType);
	return null;
}

interface DictionaryNumb<Value> {
	[id:number] : Value;
}

interface DictionaryStr<Value> {
	[id:string] : Value;
}

class FlatTimes {
	readonly id: number;
	readonly type: GroupType;
	readonly start: number;
	readonly end: number;
	readonly activeTime: number; // should be equal to sum to of internal time ranges if everything is fine 
	readonly internalTimes: number[]; // local, first number should always be zero

	constructor(id: number, type:GroupType, sortedTimeRanges: Range[]) {
		this.id = id;
		this.type = type;
		this.start = sortedTimeRanges[0].start;
		this.end = sortedTimeRanges[ sortedTimeRanges.length - 1].end;
		this.internalTimes = [];
		this.activeTime = 0;
		for (const r of sortedTimeRanges) {
			this.internalTimes.push(r.start, r.end);
			this.activeTime += (r.end - r.start);
		}
	}
}

interface ActivityResBrief {
	readonly id: number;
	readonly resources: DictionaryNumb<number[]>;
	readonly laboursTimeTotal: number;
	readonly materialsTotalAmounts: number[];
}

class Range {
	start: number;
	readonly end: number;

	constructor(start: number, end: number) {
		if (end < start) {
			console.error(`invalid range, clamping end to start, start: ${start}, end: ${end}`);
			end = start;
		}
		this.start = start;
		this.end = end;
	}
}

function sortAndCollapseRanges(ranges: Range[]): Range[] {
/*
	collapsing of ranges to coninuus when possible should not happen for the last range in a bunch
	because of minRange stuff, we can introduce visual bugs to the gantt, where upper level internal range 
	is smaller in width than the projection of lower level internal ranges widths that it corresponds to
	if the last of collapsed ranges for upper level happened to be smaller than current minWidth
*/
	ranges.sort((r1, r2) => r1.end === r2.end ? r1.start - r2.start : r1.end - r2.end);
	let next = ranges[ranges.length - 1];
	const result: Range[] = [next];
	let canCollapse : boolean = false; // do not collapse first element in descendingly sorted array (would be last when reversed)
	for (let i = ranges.length - 2; i >= 0; --i){
		const curr = ranges[i];
		if (curr.start >= next.start) {
			continue; // curr range is inside next
		}
		let shouldCollapse = curr.end >= next.start;
		if (shouldCollapse) {
			if (canCollapse) {
				next.start = curr.start;
			} else {
				canCollapse = true;
				next.start = curr.end;
				result.push(curr);
				next = curr;
			}
		} else {
			canCollapse = false;
			result.push(curr);
			next = curr;
		}
	}
	return result.reverse();
}

function extendArray<T>(arrToExtend: T[], arrToExtendBy: T[]) {
	for (const t of arrToExtendBy) {
		arrToExtend.push(t);
	}
}

const worksColorsMain = ['#C75C61', '#B765A5', '#7270DD', '#5A9FBC', '#47A57E', '#B7B85E', '#C4924C'];
const worksColorsOff = ['#77373A', '#6D3D63', '#444284', '#355F70', '#29634C', '#6D6E38', '#75572D'];

const resColorsMain = ["#7BA710", "#489E63", "#389998", "#528ED8", "#7549DA", "#A43EC8", "#BB3988", "#AC563C", "#B7382D", "#DC702E", "#C29D1F"];

const laboursColors = ["#dd7300", "#8d8600", "#ca84cd", "#589800", "#8398e6", "#c24219", "#0d930a", "#a86a00", "#6495ff", "#b55f48", "#615fd2", "#87a83b", "#00864f", "#4b85ff", "#3973bb", "#f5775f", "#f67283", "#6d6ef5", "#c13f4f", "#b04a72", "#37a4ea", "#017cdb", "#ae58db"];

class ColorsGenerator {
	actsCounter: number = 0;
	actsNumbers: DictionaryStr<number> = {};

	resCounters: DictionaryNumb<number> = {
		[ResourceType.Labour]: 0,
		[ResourceType.Plant]: 2,
		[ResourceType.Material]: 4
	}

	resNumbers: DictionaryNumb<DictionaryNumb<number>> = {
		[ResourceType.Labour]: {},
		[ResourceType.Plant]: {},
		[ResourceType.Material]: {}
	}
	
	constructor() {
	}

	getWorkColors(workName: string) : Colors {
		if (this.actsNumbers[workName] === undefined) {
			this.actsNumbers[workName] = this.actsCounter++;
		}
		const n = Math.abs(this.actsNumbers[workName] % worksColorsMain.length);
		return {
			main: worksColorsMain[n],
			off: worksColorsOff[n],
		}
	}

	getResourceColors(type: ResourceType, id: number): Colors {
		const byResource = this.resNumbers[type];
		if (byResource[id] === undefined) {
			byResource[id] = this.resCounters[type];
			this.resCounters[type] = this.resCounters[type] + 1;
		}
		const colorN = byResource[id];
		let colorsPalette = resColorsMain;
		if (type === ResourceType.Labour) {
			colorsPalette = laboursColors;
		}
		const color = colorsPalette[colorN % colorsPalette.length];
		return {
			main: color,
			off: ColorsGenerator.getOffColorFromMainColor(color)
		}
	}

	static getOffColorFromMainColor(hex: string) {
		hex = hex.slice(1);
		const colRangeAdjusted = 255 / 0.7;
		const r = parseInt( hex.charAt( 0 ) + hex.charAt( 1 ), 16 ) / colRangeAdjusted;
		const g = parseInt( hex.charAt( 2 ) + hex.charAt( 3 ), 16 ) / colRangeAdjusted;
		const b = parseInt( hex.charAt( 4 ) + hex.charAt( 5 ), 16 ) / colRangeAdjusted;
		const offHex = (r * 255) << 16 ^ (g * 255) << 8 ^ (b * 255) << 0;
		return '#' + ('000000' + offHex.toString( 16 )).slice( - 6 );
	}
}

type Producer<T> = () => T;

class LazyResult<T>{

	private producer: Producer<T> | null = null; // todo: stop using lambdas with closure for this, and pass arguments explicitely
	
	private _result: T | null;

	constructor(resultProducer: Producer<T>) {
		this.producer = resultProducer;
		this._result = null;
	}
	
	get result(): T {
		if (this.producer !== null) {
			this._result = this.producer();
			this.producer = null;
		}
		return this._result!;
	}

}
