import React from 'react';
import { CompareControl } from 'common/components/drawings/layout-components/top-control-group/compare';
import { Menu } from 'unit-2d-info-panel/constants';
import { useOpenMenu } from 'unit-2d-info-panel/hooks/use-open-menu';


const CompareViewComponent: React.FC = () => {
  const onFinished = useOpenMenu(Menu.Default);

  return (
    <CompareControl
      onFinished={onFinished}
    />
  );
};

export const CompareView = React.memo(CompareViewComponent);
