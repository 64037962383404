import styled from 'styled-components';

import { TextAreaStyled } from 'common/components/text-area';

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  padding-left: 45px;
  position: relative;

  > p {
    font-size: 14px;
    line-height: 21px;
  }

  ${TextAreaStyled.Container} {
    min-height: 30px;
  }
`;


const Buttons = styled.div`
  margin-left: auto;
  display: flex;
  button {
    width: 20px;
    height: 20px;
  }
  button + button {
    margin-left: 10px;
  }
`;

export const Styled = {
  Container,
  Header,
  Buttons,
};
