import classNames from 'classnames';
import * as React from 'react';


import './information-item.scss';

interface InformationItemProps {
  title: string;
  data?: any[] | React.ReactText;
  className?: string;
  renderItem?: (item: any) => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  action?: () => void;
}


export class InformationItem extends React.Component<InformationItemProps> {
  public render(): JSX.Element {
    return (
      <div className={classNames('information-item', this.props.className)}>
        <div className='information-item__title'>
          { this.props.title }:
          { this.props.renderHeader ? this.props.renderHeader() : null }
        </div>
        <div className='information-item__items'>
          {this.getDataRenderer()}
        </div>
      </div>
    );
  }

  private getDataRenderer(): React.ReactNode {
    if (!Array.isArray(this.props.data)) {
      return (
        <span className='information-item__text-data'>
          {this.props.data}
        </span>
      );
    } else {
      return (
        <React.Fragment>
          {
            this.props.data && this.props.data.length > 0 ?
              this.props.data.map((item) => this.props.renderItem ? this.props.renderItem(item) : null) :
            <span className='information-item__no-data'>
              No data
            </span>
          }
        </React.Fragment>
      );
    }
  }
}
