import { Icons, IconButton } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Text } from 'common/components/text';
import { ElementTooltip } from 'common/components/tooltip';
import { Mood } from 'common/enums/mood';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { MagicSearchActions } from '../../actions/creators/magic-search';
import { Styled } from './styled';


const RemovedResultsComponent: React.FC = () => {
  const removedPreviewsCount = useSelector<State, number>(s => s.drawings.magicSearch.previewsToDelete.length);
  const reset = useActionDispatch(MagicSearchActions.resetDeletePreviews);
  return (
    <Styled.RemovedInstancesContainer>
      <Styled.ContentWrapper>
        <ElementTooltip
          position='top'
          speed='l'
          text={'reset'}
        >
          <IconButton
            Icon={Icons.UndoClassic}
            onClick={reset}
            width={20}
            height={20}
            mood={removedPreviewsCount > 0 ? Mood.Secondary : Mood.Disabled}
          />
        </ElementTooltip>
        <Text fontSize={14} lineHeight={16}>
          { removedPreviewsCount } removed areas
        </Text>
      </Styled.ContentWrapper>
      <ElementTooltip
        position='left'
        speed='l'
        text={'You can delete the area measurement if the AI is incorrect by clicking on it (it will turn red)'}
      >
        <Icons.Questions2D />
      </ElementTooltip>
    </Styled.RemovedInstancesContainer>
  );
};

export const RemovedResults = React.memo(RemovedResultsComponent);
