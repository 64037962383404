import React, { useEffect } from 'react';

import { useCurrentDrawingInfo } from 'common/components/drawings/hooks';
import { PdfFilterSettings } from 'common/components/drawings/layout-components/pdf-filter-settings';
import { Menu } from 'unit-2d-info-panel/constants';
import { useOpenMenu } from 'unit-2d-info-panel/hooks/use-open-menu';

export const PdfFilterView: React.FC = () => {
  const currentDrawing = useCurrentDrawingInfo();

  const closeMenu = useOpenMenu(Menu.Default);

  useEffect(() => {
    if (!currentDrawing) {
      closeMenu();
    }
  }, [currentDrawing, closeMenu]);

  return currentDrawing ? (<PdfFilterSettings/>) : null;
};

