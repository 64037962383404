import React, { useCallback } from 'react';

import { Text } from 'common/components/text';
import { ITEM_TEXT_COLOR, ITEM_TYPE_COLOR } from '../../constants/item-type-color';
import { FormulaInputAutocompleteItemType } from '../../enums';
import { FormulaInputAutocompleteItemInfo } from '../../input/auto-complete';
import { Styled } from './styled';


interface Props {
  insert: (value: FormulaInputAutocompleteItemInfo) => void;
  type: FormulaInputAutocompleteItemType;
  name?: string;
  textToInsert: string;
}


const ProeprtyButtonComponent: React.FC<Props> = ({ insert, type, name, textToInsert }) => {
  const onClick = useCallback(() => {
    insert({ type, text: textToInsert });
  }, [insert, type, textToInsert]);

  return (
    <Styled.Container onClick={onClick} backgroundColor={ITEM_TYPE_COLOR[type]}>
      <Text
        fontSize={12}
        color={ITEM_TEXT_COLOR[type]}
        lineHeight={12}
        fontWeight={500}
        letterSpacing={0.5}
        textTransform='uppercase'
        withEllipsis={true}
      >
        {name || textToInsert}
      </Text>
    </Styled.Container>
  );
};


export const PropertyButton = React.memo(ProeprtyButtonComponent);
