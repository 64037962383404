import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import {
  MultilevelSelectOptionDataWithObjectValue,
} from 'common/components/multi-level-drop-down-menu/interfaces/multi-level-select-option-data';
import { State } from 'common/interfaces/state';
import { TablePreset } from '../../../../../units/2d/interfaces';
import { DrawingsInstanceType } from '../../enums';
import {
  useEditStyleForSelectedIds,
  useInstancesStyles,
} from '../../hooks';
import { DrawingContextMenuPosition, DrawingsGeometryInstance } from '../../interfaces';
import { useCanBoolWithPolygons } from '../hooks';
import { useCanJoin } from '../hooks/use-can-join';
import { MoveToCellOptionData } from '../interfaces/options';
import { DrawingsContextMenuBody } from './drawings-context-menu-body';
import { DrawingsContextMenuOptions } from './drawings-context-menu-options';
import { DrawingsContextMenuOptionsPanel } from './drawings-context-menu-options-panel';
import { useDoActionAndClose, useHeaderType } from './hooks';
import { ActionEventHandlers } from './interfaces';

interface Props {
  position: DrawingContextMenuPosition;
  isFullScreen: boolean;
  canPasteToSamePlace: () => boolean;
  hasStrokedInstancesInSelected: boolean;
  onClose: () => void;
  canPaste: boolean;
  onDynamicMoveToCell: (preset: TablePreset) => void;
  getCurrentMeasureTypes: () => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>;
  canTraceLink: boolean;
  hasMeasures: boolean;
  canEditReport: boolean;
  canViewReport: boolean;
  canEditMeasurement: boolean;
  getFolderToCellOptions: () => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>;
  isRendererInitialized: boolean;
  actionEventHandlers: Record<string, ActionEventHandlers>;
  instancesIds: string[];
  canToggleFullScreen: boolean;
}


export const ContextMenuComponent: React.FC<Props> = (props) => {
  const { position, onClose, instancesIds, canEditMeasurement } = props;
  const { color, strokeStyle, strokeWidth, shape } = useInstancesStyles(instancesIds);
  const { headerType, styleMenuType } = useHeaderType(props);
  const canJoinCount = useCanJoin(instancesIds, DrawingsInstanceType.Count);
  const canJoinPolyline = useCanJoin(instancesIds, DrawingsInstanceType.Polyline);
  const canBoolWithPolygons = useCanBoolWithPolygons(instancesIds);
  const doActionAndClose = useDoActionAndClose(onClose);
  const instances = useSelector<State, Record<string, DrawingsGeometryInstance>>(
    (state) => state.drawings.aiAnnotation.geometry,
  );

  const [stroked, counts] = useMemo(() => {
    return instancesIds.reduce((acc, id) => {
      const instance = instances[id];
      if (!instance) {
        return acc;
      }
      const { type } = instance;
      if (type === DrawingsInstanceType.Count) {
        acc[1].push(id);
      } else {
        acc[0].push(id);
      }
      return acc;
    }, [[], []] as [string[], string[]]);
  }, [instancesIds, instances]);
  const setColor = useEditStyleForSelectedIds('color', instances, instancesIds);
  const setStrokeStyle = useEditStyleForSelectedIds('strokeStyle', instances, stroked);
  const setStrokeWidth = useEditStyleForSelectedIds('strokeWidth', instances, stroked);
  const setShape = useEditStyleForSelectedIds('shape', instances, counts);

  const TopPanelComponent = useMemo(() => {
    return (
      <DrawingsContextMenuOptionsPanel
        instancesIds={instancesIds}
        canBoolWithPolygons={canBoolWithPolygons}
        canEditMeasurement={canEditMeasurement}
        canJoinCount={canJoinCount}
        canJoinPolyline={canJoinPolyline}
        doActionAndClose={doActionAndClose}
      />
    );
  }, [instancesIds, canBoolWithPolygons, canEditMeasurement, canJoinCount, canJoinPolyline, doActionAndClose]);

  return (
    <DrawingsContextMenuBody
      x={position.x}
      y={position.y}
      headerType={headerType}
      styleMenuType={styleMenuType}
      onClose={onClose}
      selectedColor={color}
      strokeWidth={strokeWidth}
      strokeStyle={strokeStyle}
      shape={shape}
      topPanelComponent={TopPanelComponent}
      zIndex={1001}
      setShape={setShape}
      setStrokeStyle={setStrokeStyle}
      setStrokeWidth={setStrokeWidth}
      setColor={setColor}
    >
      <DrawingsContextMenuOptions
        canToggleFullScreen={props.canToggleFullScreen}
        actionEventHandlers={props.actionEventHandlers}
        canBoolWithPolygons={canBoolWithPolygons}
        canEditMeasurement={props.canEditMeasurement}
        canEditReport={props.canEditReport}
        canJoinCount={canJoinCount}
        canJoinPolyline={canJoinPolyline}
        doActionAndClose={doActionAndClose}
        isFullScreen={props.isFullScreen}
        onClose={onClose}
        position={position}
        canPaste={props.canPaste}
        canPasteToSamePlace={props.canPasteToSamePlace}
        canTraceLink={props.canTraceLink}
        hasMeasures={props.hasMeasures}
        isRendererInitialized={props.isRendererInitialized}
        instancesIds={instancesIds}
        onDynamicMoveToCell={props.onDynamicMoveToCell}
        getCurrentMeasureTypes={props.getCurrentMeasureTypes}
        canViewReport={props.canViewReport}
        getFolderToCellOptions={props.getFolderToCellOptions}
        color={color}
      />
    </DrawingsContextMenuBody>
  );
};

export const DrawingsContextMenu = React.memo(ContextMenuComponent);
