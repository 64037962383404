import * as React from 'react';

import './revit-properties-popup-base-header-new.scss';

import { IconButton, KreoIconCloseSmall } from 'common/UIKit';

interface Props {
  title: string;
  count: number;
  onClose?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const RevitPropertiesPopupBaseHeaderNew: React.FC<Props> = props => {
  return (
    <div className='revit-properties-popup-base-header-new'>
      <div
        className='revit-properties-popup-base-header-new__draggable'
        onMouseDown={props.onMouseDown}
      >
        <div className='revit-properties-popup-base-header-new__title' title={props.title}>
          {props.title}
        </div>
        <div className='revit-properties-popup-base-header-new__count' title='Elements'>{props.count}</div>
      </div>
      <IconButton size='small' onClick={props.onClose}>
        <KreoIconCloseSmall />
      </IconButton>
    </div>
  );
};
