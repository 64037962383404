import { arrayUtils } from 'common/utils/array-utils';
import { DrawingsGeometrySelectionArea } from '../../drawings-geometry-entities/utility';
import { DrawingsUserAnnotationsHelper } from '../annotations';
import { DrawingsGeometryEntityHelper } from '../drawings-geometry-entity-helper';
import { BaseSelectionAreaHelper, BaseSelectionAreaHelperConfig } from './base-selection-area-helper';

interface DrawingsDragSelectionHelperConfig extends BaseSelectionAreaHelperConfig {
  entitiesHelper: DrawingsGeometryEntityHelper;
  userAnnotationHelper: DrawingsUserAnnotationsHelper;
  onSelectionChange: (instancesIds: string[], ctrlKey: boolean) => void;
  onAnnotationsSelectionChanged: (annotationsIds: string[], ctrlKey: boolean) => void;
  stickersInRectIdsIterator: (
    rect: paper.Rectangle,
    selectionArea: DrawingsGeometrySelectionArea,
  ) => IterableIterator<string>;
}


export class DrawingsDragSelectionHelper extends BaseSelectionAreaHelper<DrawingsDragSelectionHelperConfig> {
  public destroy(): void {
    if (this._selectionArea) {
      this._selectionArea.destroy();
    }

    this._config.geometryRenderParamsObserver.unsubscribe(this.updateRenderParams);
  }

  public finishDrag(ctrlKey: boolean): void {
    const ids = new Array<string>();
    const rect = this._selectionArea.bounds;
    const iterator =
      this._config.entitiesHelper.instancesInRectIdsIterator(rect, this._selectionArea, ctrlKey);
    for (const id of iterator) {
      ids.push(id);
    }
    const idsForSelect = arrayUtils.uniq(ids);
    this._config.onSelectionChange(idsForSelect, ctrlKey);
    if (idsForSelect.length) {
      this._config.onAnnotationsSelectionChanged([], false);
    } else {
      this._config.onAnnotationsSelectionChanged(
        [
          ...this._config.stickersInRectIdsIterator(rect, this._selectionArea),
          ...this._config.userAnnotationHelper.imagesInRectIdsIterator(rect, this._selectionArea),
        ],
        ctrlKey,
      );
    }
    this.removeSelectionArea();
  }
}
