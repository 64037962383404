import React from 'react';

import { useDownloadImage } from 'common/components/auth-image/use-download-image';

interface Props {
  previewUrl: string;
  displayNotification: boolean;
}

const PreviewComponent: React.FC<Props> = ({ previewUrl, displayNotification }) => {
  const image = useDownloadImage(previewUrl ? `/api${previewUrl}` : null);

  if (!previewUrl) {
    return (<span className='image-uploader__pseudo-button-content' />);
  }

  return (
    <div className={'image-uploader__preview'} style={{ backgroundImage: `url(${image})` }}>
      {displayNotification && <div className='image-uploader__notification' />}
    </div>
  );
};


export const Preview = React.memo(PreviewComponent);
