import { createSlice } from '@reduxjs/toolkit';
import { ConstantFunctions } from 'common/constants/functions';
import { RequestStatus } from 'common/enums/request-status';
import { ActionWith } from 'common/interfaces/action-with';
import { arrayUtils } from 'common/utils/array-utils';
import {
  AddCommentPayload,
  AddSubCommentRequestPayload,
  AddSubCommentSucceedPayload,
  CommentaryThread,
  CommentaryTarget,
  EditCommentTextPayload,
  EditSubCommentTextPayload,
  RemoveSubCommentPayload,
  TwoDCommentsState,
  RealtimeConnectionInfo,
  MoveSpreadSheetCommentPayload,
} from 'unit-2d-comments/interfaces';
import { SortingUtils, TargetCountingUtils } from 'unit-2d-comments/utils';
import { Filters, MentionFilterValues } from '../enums';

function removeComment(state: TwoDCommentsState, action: ActionWith<number>): void {
  const [comments, removedComments] = arrayUtils.splitByCondition(state.comments, c => c.id !== action.payload);
  state.comments = comments;
  if (removedComments[0] && !removedComments[0].resolved) {
    TargetCountingUtils.decrease(removedComments[0], state);
  }
}

function removeSubComment(state: TwoDCommentsState, action: ActionWith<RemoveSubCommentPayload>): void {
  const comment = state.comments.find(x => x.id === action.payload.commentId);
  comment.messages = comment.messages.filter(x => x.id !== action.payload.subCommentId);
}

function setCommentResolveStatus(state: TwoDCommentsState, comment: CommentaryThread, status: boolean): void {
  if (comment.resolved === status) {
    return;
  }
  comment.resolved = status;
  if (status) {
    TargetCountingUtils.decrease(comment, state);
  } else {
    TargetCountingUtils.increase(comment, state);
  }
}

export const TWOD_COMMENTS_INITIAL_STATE: TwoDCommentsState = {
  comments: [],
  isPanelOpened: false,
  newCommentData: null,
  temporaryComments: [],
  createSubCommentStatus: {},
  resolveRequestStatuses: {},
  loadCommentsStatus: RequestStatus.NotRequested,
  commentToFocus: null,
  drawingCommentsCount: {},
  filters: {
    [Filters.Mention]: MentionFilterValues.All,
    [Filters.Status]: null,
  },
  realtimeConnection: null,
  showBadge: false,
  commentOnEdit: null,
  subCommentOnEdit: null,
  openedCommentsCount: 0,
  selectedCommentId: null,
};

const TwoDComments = createSlice({
  name: '@2dComments',
  initialState: TWOD_COMMENTS_INITIAL_STATE,
  reducers: {
    dropState: (state) => ({
      ...TWOD_COMMENTS_INITIAL_STATE,
      realtimeConnection: state.realtimeConnection,
    }),
    connectRealtime: ConstantFunctions.doNothing,
    realtimeConnected: (state, { payload: connection }: ActionWith<RealtimeConnectionInfo>) => {
      state.realtimeConnection = connection;
    },
    disconnectRealtime: ConstantFunctions.doNothing,
    realtimeDisconnected: (state) => {
      state.realtimeConnection = null;
    },
    loadCommentsRequest: (state) => {
      state.loadCommentsStatus = RequestStatus.Loading;
    },
    loadCommentsSucceed: (state, { payload }: ActionWith<CommentaryThread[]>) => {
      state.loadCommentsStatus = RequestStatus.Loaded;
      const { all, byDrawings } = TargetCountingUtils.countComments(payload);
      state.drawingCommentsCount = byDrawings;
      state.openedCommentsCount = all;
      state.comments = SortingUtils.deepSort(payload);
    },
    changeOpenPanelStatus: (state, { payload }: ActionWith<boolean>) => {
      state.isPanelOpened = payload;
      if (payload) {
        state.showBadge = false;
        state.loadCommentsStatus = RequestStatus.Loading;
        state.comments = SortingUtils.sortCommentaries(state.comments);
      } else {
        state.newCommentData = null;
        state.commentOnEdit = null;
        state.subCommentOnEdit = null;
        state.selectedCommentId = null;
      }
    },
    startAddComment: (state, { payload: targetInfo }: ActionWith<CommentaryTarget>) => {
      state.newCommentData = targetInfo;
      state.isPanelOpened = true;
      state.commentOnEdit = null;
      state.subCommentOnEdit = null;
      state.selectedCommentId = null;
    },
    cancelAddComment: (state) => {
      state.newCommentData = null;
    },
    addCommentRequest: (state, action: ActionWith<AddCommentPayload>) => {
      state.temporaryComments.push({ ...action.payload, status: RequestStatus.Loading });
      state.newCommentData = null;
    },
    addCommentFinished: (state, { payload: temporaryCommentId }: ActionWith<string>) => {
      state.temporaryComments = state.temporaryComments.filter(x => x.tempararyId !== temporaryCommentId);
    },
    addCommentIfNotExists: (state, { payload: comment }: ActionWith<CommentaryThread>) => {
      if (state.comments.find(c => c.id === comment.id)) {
        return state;
      }
      state.showBadge = !state.isPanelOpened;
      state.comments.unshift(comment);
      TargetCountingUtils.increase(comment, state);
    },
    editCommentRequest: (state, { payload: { text, commentId } }: ActionWith<EditCommentTextPayload>) => {
      const comment = state.comments.find(x => x.id === commentId);
      comment.text = text;
    },
    resolveCommentRequest: (state: TwoDCommentsState, { payload }: ActionWith<number>) => {
      state.resolveRequestStatuses[payload] = RequestStatus.Loading;
    },
    resolveCommentRequestError: (state: TwoDCommentsState, { payload }: ActionWith<number>) => {
      delete state.resolveRequestStatuses[payload];
    },
    changeResolveCommentStatus: (
      state,
      { payload: { commentId, status } }: ActionWith<{ commentId: number, status: boolean }>,
    ) => {
      const comment = state.comments.find(x => x.id === commentId);
      setCommentResolveStatus(state, comment, status);
    },
    resolveCommentSuccess: (
      state: TwoDCommentsState,
      { payload: commentId }: ActionWith<number>,
    ) => {
      delete state.resolveRequestStatuses[commentId];
    },
    editSubCommentRequest: (state, { payload }: ActionWith<EditSubCommentTextPayload>) => {
      const { text, commentId, subCommentId } = payload;
      const comment = state.comments.find(x => x.id === commentId);
      const subComment = comment.messages.find(x => x.id === subCommentId);
      subComment.text = text;
    },
    commentUpdated: (state, { payload }: ActionWith<CommentaryThread>) => {
      const commentIndex = state.comments.findIndex(x => x.id === payload.id);
      if (commentIndex >= 0) {
        state.comments[commentIndex] = { ...state.comments[commentIndex], ...payload };
      }
    },
    subCommentUpdated: (state, { payload }: ActionWith<AddSubCommentSucceedPayload>) => {
      const commentIndex = state.comments.findIndex(x => x.id === payload.commentId);
      const subCommentIndex = state.comments[commentIndex]?.messages.findIndex(x => x.id === payload.subComment.id);
      if (subCommentIndex >= 0) {
        state.comments[commentIndex].messages[subCommentIndex] = payload.subComment;
      }
    },
    addSubCommentRequest: (state, action: ActionWith<AddSubCommentRequestPayload>) => {
      state.createSubCommentStatus[action.payload.commentId] = RequestStatus.Loading;
    },
    addSubCommentOrUpdate: (state, action: ActionWith<AddSubCommentSucceedPayload>) => {
      delete state.createSubCommentStatus[action.payload.commentId];
      const comment = state.comments.find(x => x.id === action.payload.commentId);
      state.showBadge = !state.isPanelOpened;
      if (!comment.messages) {
        comment.messages = [];
      }
      const messageIndex = comment.messages.findIndex(c => c.id === action.payload.subComment.id);
      if (messageIndex === -1) {
        comment.messages.push(action.payload.subComment);
      } else {
        comment.messages[messageIndex] = action.payload.subComment;
      }
    },
    removeSubCommentRequest: removeSubComment,
    onRemoveSubComment: removeSubComment,
    removeComment,
    onCommentRemoved: removeComment,
    selectComment: (state, action: ActionWith<number>) => {
      state.isPanelOpened = true;
      state.commentToFocus = action.payload;
    },
    updateFilters: (state, { payload: { filter, value } }: ActionWith<{ filter: Filters, value: string }>) => {
      if (filter === Filters.Status && value === state.filters[filter]) {
        state.filters[filter] = null;
      } else {
        state.filters[filter] = value;
      }
    },
    setEditComment: (state, { payload }: ActionWith<number>) => {
      state.commentOnEdit = payload;
      state.subCommentOnEdit = null;
    },
    setEditSubComment: (state, { payload }: ActionWith<number>) => {
      state.commentOnEdit = null;
      state.subCommentOnEdit = payload;
    },
    moveComments: (_1, _2: ActionWith<MoveSpreadSheetCommentPayload>) => {
      ConstantFunctions.doNothing();
    },
    setSelectedCommentId: (state, { payload }: ActionWith<number>) => {
      state.selectedCommentId = payload;
    },
  },
});

export const TwoDCommentsActions = TwoDComments.actions;
export const twoDCommentsReducer = TwoDComments.reducer;
