import { DrawingsGeometryGroup, DrawingsGeometryInstance } from '../../interfaces';

export function getGroupsSelectionTree(
  selectedInstancesIds: string[],
  selectedGroups: string[],
  groups: DrawingsGeometryGroup[],
  geometries: Record<string, DrawingsGeometryInstance>,
): Record<string, boolean> {
  if (!selectedInstancesIds.length && !selectedGroups.length) {
    return {};
  }

  const result = {};
  const parentGroupsOfVisibleInstances = [];
  const selectedGroupsIdsSet = new Set(selectedGroups);

  const groupMap: Record<string, DrawingsGeometryGroup> = {};

  for (const group of groups) {
    groupMap[group.id] = group;
    if (selectedGroupsIdsSet.has(group.id) && group.parentId) {
      parentGroupsOfVisibleInstances.push(group.parentId);
    }
  }

  for (const instanceId of selectedInstancesIds) {
    const instance = geometries[instanceId];
    if (instance?.groupId) {
      parentGroupsOfVisibleInstances.push(instance.groupId);
    }
  }


  while (parentGroupsOfVisibleInstances.length) {
    const groupId = parentGroupsOfVisibleInstances.pop();
    if (result[groupId]) {
      continue;
    }
    const group = groupMap[groupId];
    result[groupId] = true;
    if (group.parentId) {
      parentGroupsOfVisibleInstances.push(group.parentId);
    }
  }
  return result;
}
