import { useCallback, useState } from 'react';

export interface SelectState {
  onBlur: (unit: string) => void;
  value: string;
  onClick: (index: number) => void;
  activeElementIndex: number;
  isChanged: boolean;
}

export const useSelectState = (): SelectState  => {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [value, changeValue] = useState<string>();
  const [activeElementIndex, setActiveElementIndex] = useState<number>();
  const handleClick = useCallback((index: number) => {
    setActiveElementIndex(index);
    setIsChanged(true);
  }, []);
  const handleBlur = useCallback((newValue: string) => {
    setIsChanged(true);
    changeValue(newValue);
  }, []);

  return {
    value,
    onClick: handleClick,
    onBlur: handleBlur,
    activeElementIndex,
    isChanged,
  };
};
