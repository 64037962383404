import { RegexpUtil } from 'common/utils/regexp-util';
import { ValueHelper } from 'common/utils/value-helper';
import { PatternHelper } from './interfaces';

const POSITIVE_NUMBER = '\\d+';
const NUMBER = `-?${POSITIVE_NUMBER}`;
const SPACES = '\\s*';
const isIteratableRegexp = new RegExp(`^${SPACES}(?:(${NUMBER})(?:\\s|$))?.*?(${NUMBER})?${SPACES}$`);

const numberAtEndPatternHelper = {
  getSequenceNumberFromPattern: (value: number) => ValueHelper.isNumberValue(value) ? value : null,
  getPatternValue: (numberValue) => ValueHelper.isNumberValue(numberValue) ? numberValue : null,
};

const getSequenceValueCommon = (value: string, patternRegexp: RegExp, matchIndex: number): number | null => {
  if (ValueHelper.isNumberValue(value) || !value) {
    return null;
  }

  const matches = (value).match(patternRegexp);
  if (!matches || !matches[matchIndex]) {
    return null;
  }

  return +matches[matchIndex];
};

const getNumberAtStartPatternHelper = (referenceValue: string): PatternHelper => {
  const postfix = referenceValue.match(new RegExp(`^${SPACES}${NUMBER}(.*)?${SPACES}$`))[1];
  const patternRegexp = new RegExp(`^(${SPACES})(${NUMBER})(${RegexpUtil.escapeRegExp(postfix)})${SPACES}$`);
  return {
    getSequenceNumberFromPattern: (value: string) => getSequenceValueCommon(value, patternRegexp, 2),
    getPatternValue: (value) => referenceValue.replace(patternRegexp, `$1${value}$3`),
  };
};

const getNumberAtEndPatternHelper = (referenceValue: string): PatternHelper => {
  const prefix = referenceValue.match(new RegExp(`^${SPACES}(.*?)${POSITIVE_NUMBER}${SPACES}$`))[1];
  const patternRegexp = new RegExp(`^(${SPACES}${RegexpUtil.escapeRegExp(prefix)})(${POSITIVE_NUMBER})${SPACES}$`);
  return {
    getSequenceNumberFromPattern: (value: string) => getSequenceValueCommon(value, patternRegexp, 2),
    getPatternValue: (value) => referenceValue.replace(patternRegexp, (_match, group1, numberString) => {
      const absoluteValue = Math.abs(value);
      const resultValue = numberString && numberString.startsWith('0')
        ? absoluteValue.toString().padStart(numberString.length, '0')
        : absoluteValue;

      return `${group1}${resultValue}`;
    }),
  };
};

const getCopyPatternHelper = (referenceValue: string): PatternHelper => {
  return {
    getSequenceNumberFromPattern: () => null,
    getPatternValue: () => referenceValue,
  };
};


const getFillPatternHelper = (initialValue: string | number): PatternHelper => {
  if (ValueHelper.isNumberValue(initialValue)) {
    return numberAtEndPatternHelper;
  }

  const stringInitialValue = initialValue as string || '';
  const matches = stringInitialValue.match(isIteratableRegexp);
  if (matches && matches[1]) {
    return getNumberAtStartPatternHelper(stringInitialValue);
  }

  if (matches && matches[2]) {
    return getNumberAtEndPatternHelper(stringInitialValue);
  }

  return getCopyPatternHelper(stringInitialValue);
};


export const CellFillPatternHelperFactory = {
  getFillPatternHelper,
};
