import { AppRoute } from 'common/routing/app-route';
import * as Admin from './common/admin/urls';
import * as CostEstimate from './cost-estimate/urls';
import * as Plan from './plan/urls';
import * as Qto2d from './quantity-take-off-2d/urls';
import * as Qto3d from './quantity-take-off-3d/urls';

const planExpired = new AppRoute('/plan-expired');
const products = new AppRoute('/products');
const emptyAccount = new AppRoute('/empty-account');
const root = new AppRoute('');

export const AppUrls = {
  qto2d: Qto2d.Urls,
  qto3d: Qto3d.Urls,
  plan: Plan.Urls,
  costEstimate: CostEstimate.Urls,
  admin: Admin.Urls,
  planExpired,
  products,
  emptyAccount,
  root,
};
