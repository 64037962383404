import * as Ag from 'ag-grid-community';

import { sortComparator } from '2d/components/2d-element-view/constants';
import { getMaxNesting } from 'unit-2d-database/components/breakdown-property/utils';
import { Constants } from 'unit-2d-database/components/side-panel/components/property-panel/forms/tree-form/constants';
import { BreakDownPropertyValue, PiaBreakdownNode, Property } from 'unit-2d-database/interfaces';
import { extendsCodeColumns } from '../../extend-code-column/extends-code-column';

export const TREE_PROPERTY_POSTFIX = '[TP]';
export const ORDER_COLUMN_ID = '[[order]]';
export const ORDER_AUTO_GROUP_COLUMN_ID = '[[order_auto_group_column]]';
export const SEPARATOR_PROPERTY_KEY = 'SEPARATOR_PROPERTY_KEY';

export const orderAutoGroupColumn: Ag.ColDef = {
  colId: ORDER_AUTO_GROUP_COLUMN_ID,
  field: ORDER_AUTO_GROUP_COLUMN_ID,
  sort: 'asc',
  sortIndex: 1,
  hide: true,
  lockVisible: true,
  lockPosition: true,
  lockPinned: true,
  pinned: 'left',
  enableRowGroup: false,
  rowGroup: false,
  headerName: ORDER_AUTO_GROUP_COLUMN_ID,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  comparator: sortComparator,
  valueGetter: (params) => {
    const node = params.node;
    const groupData: string = node.groupData ? node.groupData['ag-Grid-AutoColumn'] : undefined;
    return groupData;
  },
};

export type BreakdownPropertyExtender = (
  code: string,
  propertyName: string,
  nodeId: string,
  name: string,
) => Record<string, string>;


const setParent = (node: PiaBreakdownNode, map: Record<string, string[]>, parentIds: string[]): void => {
  if (!node) {
    return;
  }

  for (const child of node.children) {
    const ids = parentIds.concat([node.id]);
    map[child.id] = ids;
    setParent(child, map, ids);
  }
};

const setOrderMap = (nodes: PiaBreakdownNode[], map: Record<string, number>, startCount: number): number => {
  let count = startCount;
  for (const node of nodes) {
    map[node.id] = count;
    count++;
    count = setOrderMap(node.children, map, count);
  }
  return count;
};

const setNameMap = (nodes: PiaBreakdownNode[], map: Record<string, string>): void => {
  for (const node of nodes) {
    map[node.id] = node.name;
    setNameMap(node.children, map);
  }
};

const getField = (name: string, level: number): string => `${name} {${level}}`;
const getPropertyName = (name: string, level: number): string => `${name} Level ${level + 1}`;

export const getBreakdownPropertyExtender = (
  properties: Array<Property<BreakDownPropertyValue>>,
  isPivot: boolean,
): [BreakdownPropertyExtender, (hidePropertyColumn?: boolean) => Record<string, Ag.ColDef>] => {
  const childMap: Record<string, string[]> = {};
  const orderMap: Record<string, number> = {};
  const nameMap: Record<string, string> = {};

  for (const property of properties) {
    setOrderMap(property.value.root, orderMap, 0);
    setNameMap(property.value.root, nameMap);
    property.value.root.forEach((r) => setParent(r, childMap, []));
  }

  const getColumnMap = (hidePropertyColumn?: boolean): Record<string, Ag.ColDef> => {
    const columnsMap: Record<string, Ag.ColDef> = {};
    if (!hidePropertyColumn) {
      for (const property of properties) {
        const maxNesting = getMaxNesting(property.value.root);
        for (let i = 0; i < maxNesting; i++) {
          const id = getField(property.name, i);
          columnsMap[id] = {
            colId: id,
            field: getField(property.name, i),
            pivot: false,
            headerName: getPropertyName(property.name, i),
            valueGetter: (value) => {
              const data = value.data;
              return data ? data[id] : '';
            },
            filterValueGetter: (param: Ag.ValueGetterParams) => {
              const data = param.data;
              const value = data ? data[id] : '';
              return value
                ? value.split(SEPARATOR_PROPERTY_KEY)[2]
                : '';
            },
          };
        }
      }
    }

    extendsCodeColumns(columnsMap, properties);

    columnsMap[ORDER_COLUMN_ID] = {
      colId: ORDER_COLUMN_ID,
      field: ORDER_COLUMN_ID,
      sort: 'asc',
      sortIndex: 0,
      hide: true,
      lockVisible: true,
      lockPosition: true,
      lockPinned: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      pinned: 'left',
      enableRowGroup: false,
      rowGroup: false,
      headerName: `Order`,
      valueGetter: (params) => {
        const node = params.node;
        const groupData: string = node.groupData ? node.groupData['ag-Grid-AutoColumn'] : undefined;
        if (!groupData || !groupData.startsWith(TREE_PROPERTY_POSTFIX)) {
          return 9999999;
        }
        const propertyNodeId = groupData.split(SEPARATOR_PROPERTY_KEY)[1];
        return orderMap[propertyNodeId];
      },
    };

    return columnsMap;
  };

  const extender = (code: string, propertyName: string, nodeId: string, name: string): Record<string, string> => {
    const fields = {};
    const codeParts = code ? code.split(Constants.codeSeparator) : [];

    for (let i = 0; i < codeParts.length; i++) {
      const key = getField(propertyName, i);
      const parents = childMap[nodeId];
      const codePrefix = TREE_PROPERTY_POSTFIX;
      if (i === codeParts.length - 1) {
        fields[key] = isPivot ? name : [codePrefix, nodeId, name].join(SEPARATOR_PROPERTY_KEY);
      } else {
        fields[key] = isPivot
          ? nameMap[parents[i]]
          : parents
            ? [codePrefix, parents[i], nameMap[parents[i]]].join(SEPARATOR_PROPERTY_KEY)
            : undefined;
      }
    }

    return fields;
  };

  return [extender, getColumnMap];
};
