import { ExcelFormulaHelper, UpdateCellData } from 'common/components/excel-table';
import { FormulaInfoController } from 'common/components/excel-table/utils/formula-info-controller';
import { TwoDFullCellId, TwoDRegexGetter } from '../2d-regex';
import { ReportCellValueGetter, ReportCellValueGetterProps } from './report-cell-value-getter';
import { ReportCellValueStore, ValueStore } from './report-cell-value-store';
import { DynamicCellsStore, ReportDynamicCellValueStore } from './report-dynamic-cell-value-store';

export type GetDrawingsToCells = (id: string) => string[];
export type FunctionToRefresh = (force?: boolean) => void;
export { DynamicCellsStore, ValueStore };

type GetDynamicTable = (groupId: string, cell: TwoDFullCellId) => Record<string, string>;

export interface ReportCellValueControllerProps {
  cellValueGetterProps: ReportCellValueGetterProps;
  getDrawingsToCells: GetDrawingsToCells;
  functionToRefresh: FunctionToRefresh;
  getCellToCells: (id: string) => string[];
  getDynamicTable: GetDynamicTable;
}

export class ReportCellValueController {
  private getDrawingsToCells: (id: string | string[]) => string[];
  private getCellToCells: (id: string) => string[];
  private getDynamicTable: GetDynamicTable;
  private functionToRefresh: FunctionToRefresh;
  private reportCellValueGetter: ReportCellValueGetter;
  private reportCellValueStore: ReportCellValueStore;
  private reportDynamicCellValueStore: ReportDynamicCellValueStore;

  public constructor(props: ReportCellValueControllerProps) {
    this.reportCellValueStore = new ReportCellValueStore();
    this.reportDynamicCellValueStore = new ReportDynamicCellValueStore();
    this.reportCellValueGetter = new ReportCellValueGetter(props.cellValueGetterProps);
    this.getDrawingsToCells = props.getDrawingsToCells;
    this.functionToRefresh = props.functionToRefresh;
    this.getCellToCells = props.getCellToCells;
    this.getDynamicTable = props.getDynamicTable;
  }

  public updateCellValueByMeasure(instancesIds: Array<string | string[]>): void {
    const fic = new FormulaInfoController();
    const cellsIdToUpdate = new Set<string>();
    for (const id of instancesIds) {
      this.getDrawingsToCells(id).forEach(c => cellsIdToUpdate.add(c));
    }
    this.updateCells(cellsIdToUpdate, fic);
    this.updateRelatedCells(Array.from(cellsIdToUpdate), fic);
    this.functionToRefresh();
  }

  public updateDynamicCells(dynamicGroups: Record<string, string[]>): void {
    const fic = new FormulaInfoController();
    const cellsIdToUpdate = new Set<string>();
    const prevCells = this.reportDynamicCellValueStore.getCells();
    this.reportDynamicCellValueStore.clearStore();
    for (const id in dynamicGroups) {
      dynamicGroups[id].forEach((cellId) => this.setDynamicGroupData(cellId, id, cellsIdToUpdate));
    }
    prevCells.forEach((cellId) => {
      if (!this.reportDynamicCellValueStore.hasCell(cellId)) {
        this.reportCellValueStore.updateCell(cellId, null);
        cellsIdToUpdate.add(cellId);
      }
    });
    this.updateRelatedCells(Array.from(cellsIdToUpdate), fic);
    this.functionToRefresh();
  }

  public updateCellValueByData(cellId: string, data: string): void {
    const fic = new FormulaInfoController();
    this.setCellValueByDataWithRelatedCells(cellId, data, fic);
    this.functionToRefresh();
  }

  public bulkCellValueByData(
    sheetId: string,
    payload: UpdateCellData[],
  ): void {
    const fic = new FormulaInfoController();
    payload.forEach(({ columnId, rowId, value }) => {
      const fullCellId = ExcelFormulaHelper.getCellLink(sheetId, columnId, rowId);
      this.setCellValueByDataWithRelatedCells(fullCellId, value, fic);
    });
  }

  public selectCellValue(sheetId: string, columnId: string, rowId: string): string | number {
    const cellId = ExcelFormulaHelper.getCellLink(sheetId, columnId, rowId);
    const value = this.reportCellValueStore.selectCell(cellId);
    const data = this.reportCellValueGetter.getCellData(sheetId, columnId, rowId, cellId);
    if (value !== undefined) {
      return value;
    } else if (data) {
      const fic = new FormulaInfoController();
      this.setCellValueByData(cellId, data, fic);
      return this.reportCellValueStore.selectCell(cellId);
    }

    return value;
  }

  public selectCellData(sheetId: string, columnId: string, rowId: string): string {
    const cellId = ExcelFormulaHelper.getCellLink(sheetId, columnId, rowId);
    return this.reportCellValueGetter.getCellData(sheetId, columnId, rowId, cellId);
  }

  public setCellValueByData(cellId: string, data: string, fic: FormulaInfoController): void {
    const value = this.reportDynamicCellValueStore.hasCell(cellId)
      ? data
        ? ExcelFormulaHelper.INVALID_VALUE
        : this.reportCellValueStore.selectCell(cellId)
      : this.reportCellValueGetter.getValueByData(cellId, data, fic);
    this.reportCellValueStore.updateCell(cellId, value);
  }

  public setCellValueByDataWithRelatedCells(cellId: string, data: string, fic: FormulaInfoController): void {
    this.setCellValueByData(cellId, data, fic);
    this.updateRelatedCells([cellId], fic);
  }

  public setCellValue(cellId: string, value: string | number): void {
    this.reportCellValueStore.updateCell(cellId, value);
  }

  public setStore(valueStore: ValueStore, dynamicStore: DynamicCellsStore): void {
    this.reportCellValueStore.setStore(valueStore);
    this.reportDynamicCellValueStore.setStore(dynamicStore);
  }

  public getValueStore(): ValueStore {
    return this.reportCellValueStore.getStore();
  }

  public getDynamicStore(): DynamicCellsStore {
    return this.reportDynamicCellValueStore.getStore();
  }

  public isValidCell(cellId: string): boolean {
    return this.reportDynamicCellValueStore.isValid(cellId);
  }

  public getDynamicCellValue(id: string): string {
    return this.reportDynamicCellValueStore.getCellValue(id);
  }

  public deleteStore(id: string): void {
    const fic = new FormulaInfoController();
    const deletedCells = this.reportCellValueStore.deleteStore(id);
    const cellsToUpdate = new Set<string>();
    deletedCells.forEach(deletedCellId => {
      this.getCellToCells(deletedCellId).forEach(c => cellsToUpdate.add(c));
    });
    this.updateCells(cellsToUpdate, fic);
    this.updateRelatedCells(Array.from(cellsToUpdate), fic);
  }

  private updateCells(updatedSet: Set<string>, fic: FormulaInfoController): void {
    updatedSet.forEach((cellId) => {
      const value = this.reportCellValueGetter.getValue(cellId, fic);
      this.reportCellValueStore.updateCell(cellId, value);
    });
  }

  private updateRelatedCells(ids: string[], fic: FormulaInfoController): void {
    const cellsIdsToUpdate = this.getAllRelatedCells(ids);
    const notCalculatedCells = new Set(cellsIdsToUpdate.concat(ids));
    fic.setNotCalculatedCells(notCalculatedCells);
    cellsIdsToUpdate.forEach(id => {
      const value = this.reportCellValueGetter.getValue(id, fic);
      this.reportCellValueStore.updateCell(id, value);
    });
  }

  private getAllRelatedCells(ids: string[]): string[] {
    const set = new Set<string>();
    ids.forEach((id) => this.appendRef(id, set));
    return Array.from(set);
  }

  private setDynamicGroupData(cellId: string, groupId: string, cellsIdToUpdate: Set<string>): void {
    const cell = TwoDRegexGetter.getFullCellField(cellId);
    const dynamicCells = this.getDynamicTable(groupId, cell);
    const fic = new FormulaInfoController();
    for (const id in dynamicCells) {
      const formula = dynamicCells[id];
      let value: string;
      if (this.reportDynamicCellValueStore.hasCell(id)) {
        const invalidGroupCellId = this.reportDynamicCellValueStore.getGroupCell(id);
        this.reportDynamicCellValueStore.setStatus(invalidGroupCellId, false);
        value = ExcelFormulaHelper.INVALID_VALUE;
      } else {
        this.reportDynamicCellValueStore.setCell(id, formula, cellId);
        value = this.reportCellValueGetter.getValue(id, fic);
      }
      this.reportCellValueStore.updateCell(id, value);
      if (value === ExcelFormulaHelper.INVALID_VALUE) {
        this.reportDynamicCellValueStore.setStatus(cellId, false);
      }
      cellsIdToUpdate.add(id);
    }
  }

  private appendRef(id: string, set: Set<string>): void {
    const cells = this.getCellToCells(id);
    if (cells) {
      if (cells.includes(id)) {
        return;
      }
      cells.forEach(cellId => {
        if (!set.has(cellId)) {
          set.add(cellId);
          this.appendRef(cellId, set);
        }
      });
    }
  }
}
