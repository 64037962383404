import QueryString from 'query-string';

import { ProjectResourcesApi } from '../../../api/server';
import {
  ModelCheckAvailableCheck,
  ModelCheckAvailableChecksC,
} from '../interfaces/model-check/model-check-available-checks';
import { ModelCheckDataResponse } from '../pages/model-check-view/interfaces/model-check-data-response';


function getData(): Promise<ModelCheckDataResponse> {
  return ProjectResourcesApi.get(`/model-check`);
}

function runModelCheck(types: string[]): Promise<void> {
  return ProjectResourcesApi.post(`/model-check/calculate?${QueryString.stringify({ type: types })}`);
}

function getAvailableChecks(): Promise<ModelCheckAvailableCheck[]> {
  return ProjectResourcesApi.getV(`/model-check/available-checks`, ModelCheckAvailableChecksC);
}

function downloadIssuesList(): Promise<void> {
  return ProjectResourcesApi.downloadFile(`/model-check/report`);
}

export const ModelCheckApi = {
  getData,
  runModelCheck,
  downloadIssuesList,
  getAvailableChecks,
};
