import autobind from 'autobind-decorator';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import './dialog.scss';

import { ControlNames } from 'common/constants/control-names';
import { State } from 'common/interfaces/state';
import { KreoButton, KreoDialog, KreoDialogActions } from 'common/UIKit';
import { KreoDialogActionsPanel } from 'common/UIKit/dialogs/kreo-dialog-actions-panel';
import Form from '../../form';

interface StateProps {
  show: boolean;
}

interface DispatchProps {
  closeDialog: (name: string) => void;
  reloadErrorState: (formName: string, fields: object) => void;
}

interface OwnProps {
  onDialogClose?: () => void;
  handleSubmit: (data: any) => void;
  children: React.ReactNode;
  title: string;
  actions?: React.ReactNodeArray;
  modal: boolean;
  name: string;
  formName: string;
  validateFields?: object;
  showActions?: boolean;
  showSubmitButton?: boolean;
  submitButtonText?: string;
  showCancelButton?: boolean;
  cancelButtonText?: string;
  actionsComponent?: React.ReactNodeArray;
  titleClassName?: string;
  bodyClassName?: string;
  submitDisabled?: boolean;
  overlayClassName?: string;
  controlName?: string;
}

interface Props extends OwnProps, DispatchProps, StateProps {}

export class KreoFormDialogComponent extends Component<Props> {
  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        document.addEventListener('keydown', this.onKeyDown, false);
      } else {
        document.removeEventListener('keydown', this.onKeyDown, false);
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    if (nextProps.show !== this.props.show) {
      this.reloadErrorState();
    }
  }

  public render(): JSX.Element {
    const { submitDisabled } = this.props;

    let actions = this.props.actionsComponent || this.props.actions;
    if (!actions) {
      actions = [];
      if (this.props.showCancelButton !== false) {
        actions.push(
          <KreoButton
            key={2}
            caption={this.props.cancelButtonText || 'Cancel'}
            onClick={this.dialogClose}
            mode='ghost'
            size='large'
            controlName={this.props.cancelButtonText || ControlNames.dialogCancelButton}
          />,
        );
      }
      if (this.props.showSubmitButton !== false) {
        actions.push(
          <KreoButton
            htmlButtonType='submit'
            size='large'
            mode='submit'
            caption={this.props.submitButtonText || 'Submit'}
            disabled={submitDisabled}
            key={1}
            controlName={this.props.submitButtonText || 'dialog-submit-btn'}
          />,
        );
      }
    }

    return (
      <KreoDialog
        name={this.props.name}
        title={this.props.title}
        isModal={this.props.modal}
        onClose={this.props.onDialogClose}
        overlayClassName={this.props.overlayClassName}
      >
        <Form
          handleSubmit={this.props.handleSubmit}
          className={this.props.bodyClassName}
          controlName={this.props.controlName}
        >
          <div className='kreo-dialog__content'>{this.props.children}</div>
          {actions && this.props.showActions !== false ? (
            <KreoDialogActionsPanel centered={actions.length === 1}>
              {actions}
            </KreoDialogActionsPanel>
          ) : null}
        </Form>
      </KreoDialog>
    );
  }

  @autobind
  private onKeyDown(e: KeyboardEvent): void {
    if (this.props.show && (e.keyCode === 27 || e.which === 27)) {
      this.dialogClose();
    }
  }

  @autobind
  private reloadErrorState(): void {
    this.props.reloadErrorState(this.props.formName, this.props.validateFields || {});
  }

  @autobind
  private dialogClose(): void {
    this.props.closeDialog(this.props.name);

    if (this.props.onDialogClose) {
      this.props.onDialogClose();
    }
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  return {
    show: ownProps.name in state.dialog,
  };
};

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    closeDialog: name => {
      dispatch(KreoDialogActions.closeDialog(name));
    },

    reloadErrorState: (formName: string, fields: object) => {
      dispatch({
        type: '@@redux-form/UPDATE_SYNC_ERRORS',
        meta: { form: formName },
        payload: { syncErrors: fields },
      });
    },
  };
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
);
export const KreoFormDialog = connector(KreoFormDialogComponent);
