import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';

interface ReduxProps {
  geometryCount: number;
  drawingRendered: boolean;
  toggleOpenMeasuremetsPanel: () => void;
}

export function useDrawingsControlReduxState(): ReduxProps {
  const geometryCount = useSelector<State, number>((state) => state.drawings.filteredElementIds?.length);
  const drawingRendered = useSelector<State, boolean>((state) => state.drawings.drawingRenderedStatus);

  const dispatch = useDispatch();

  const toggleOpenMeasuremetsPanel = useCallback(() => {
    dispatch(PersistedStorageActions.toggleOpenDrawingsAnnotationLegendPanel());
  }, []);

  return {
    geometryCount,
    drawingRendered,
    toggleOpenMeasuremetsPanel,
  };
}
