import {
  Editors,
  TreeTableCellEditorCreator,
} from '../../quantity-take-off-tree-table-cell-edit/tree-table-cell-editor-creator';
import { QtoReportPivotTableCellEditParams } from './pivot-cell-editor';
import { PivotTableDefaultCellEditor } from './pivot-table-default-cell-editor';
import { PivotTableFormulaCellEditor } from './pivot-table-formula-cell-editor';

export class PivotTableCellEditorCreator
  extends TreeTableCellEditorCreator<PivotTableDefaultCellEditor, PivotTableFormulaCellEditor> {
  public getCellEditors(
    params: QtoReportPivotTableCellEditParams,
    eGui: HTMLDivElement,
  ): Editors<PivotTableDefaultCellEditor, PivotTableFormulaCellEditor> {
    return {
      defaultCellEditor: new PivotTableDefaultCellEditor(params, eGui),
      formulaCellEditor: new PivotTableFormulaCellEditor(params, eGui),
    };
  }
}
