import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { MainLayout } from 'common/components/main-layout';
import { AppUrls } from 'routes/app-urls';
import { getRootMenuItems } from '../menu-items';
import { UserActivities } from './components/user-activities';

export class ActivityPage extends React.Component<RouteComponentProps<{}>> {
  public render(): JSX.Element {
    return (
      <MainLayout
        getMenuItems={getRootMenuItems}
        notScrollContent={true}
        metaTitle='User Activities'
        backUrl={AppUrls.products.url()}
      >
        <UserActivities />
      </MainLayout>
    );
  }
}
