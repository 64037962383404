import { Text } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { Styled } from './styled';

interface Props {
  content: string;
  onClick: (prompt: string) => void;
}


const PromptComponent: React.FC<Props> = ({
  content,
  onClick,
}) => {
  const onClickCallback = useCallback(() => {
    onClick(content);
  }, [content, onClick]);
  return (
    <Styled.PromptContainer onClick={onClickCallback}>
      <Text>
        {content}
      </Text>
    </Styled.PromptContainer>
  );
};

export const Prompt = React.memo(PromptComponent);
