import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './reports-sent-popup.scss';

import { KreoDialog } from 'common/UIKit';
import { Common } from '../../../actions';
import { State } from '../../../common/interfaces/state';

interface StateProps {
  text: string;
}

interface DispatchProps {
  onClose: () => void;
}

interface Props extends StateProps, DispatchProps { }

export const REPORT_SENT_DIALOG_NAME = 'reportSentDialog';

const ReportsSentPopupComponent: React.FC<Props> = (props) => {
  return (
    <KreoDialog
      name={REPORT_SENT_DIALOG_NAME}
      isModal={true}
      onClose={props.onClose}
    >
      <div className='reports-sent-message'>{props.text} report(s) will be sent to your email as soon as ready.</div>
      <div className='reports-sent-message'>This might take a few minutes.</div>
    </KreoDialog>
  );
};


const mapStateToProps = (state: State): StateProps => {
  return {
    text: state.common.reportsSentDialogText,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    onClose: () => dispatch(Common.setReportsDialogText('')),
  };
};


export const ReportsSentPopup = connect(mapStateToProps, mapDispatchToProps)(ReportsSentPopupComponent);
