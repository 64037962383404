import { Action } from 'redux';
import { ActionWith } from 'common/interfaces/action-with';
import { TextSearchPagesType, TextSearchResultsInfo } from '../../interfaces';
import { PdfTextRects } from '../../interfaces/pdf-text-rectangle';
import { DrawingsTextSearchActionTypes } from '../types';

function setQuery(payload: string): ActionWith<string> {
  return {
    type: DrawingsTextSearchActionTypes.SET_QUERY,
    payload,
  };
}

function toggleCaseSensitive(): Action {
  return {
    type: DrawingsTextSearchActionTypes.TOGGLE_CASE_SENSITIVE,
  };
}

function toggleWholeWord(): Action {
  return {
    type: DrawingsTextSearchActionTypes.TOGGLE_WHOLE_WORD,
  };
}

function toggleRegExp(): Action {
  return {
    type: DrawingsTextSearchActionTypes.TOGGLE_REG_EXP,
  };
}

function setResults(results: TextSearchResultsInfo): ActionWith<TextSearchResultsInfo> {
  return {
    type: DrawingsTextSearchActionTypes.SAVE_RESULTS,
    payload: results,
  };
}

function setIsLoading(isLoading: boolean): ActionWith<boolean> {
  return {
    type: DrawingsTextSearchActionTypes.SET_IS_LOADING,
    payload: isLoading,
  };
}

function setPagesSetting(pagesSetting: TextSearchPagesType): ActionWith<TextSearchPagesType> {
  return {
    type: DrawingsTextSearchActionTypes.UPDATE_PAGES_SETTING,
    payload: pagesSetting,
  };
}

function setIsError(isError: boolean): ActionWith<boolean> {
  return {
    type: DrawingsTextSearchActionTypes.SET_IS_ERROR,
    payload: isError,
  };
}


function setPageTextRects(payload: PdfTextRects): ActionWith<PdfTextRects> {
  return {
    type: DrawingsTextSearchActionTypes.SET_PAGE_TEXT_RECTS,
    payload,
  };
}

function setIsTextLoading(isLoading: boolean): ActionWith<boolean> {
  return {
    type: DrawingsTextSearchActionTypes.SET_IS_TEXT_LOADING,
    payload: isLoading,
  };
}

function setTextSearchActive(isActive: boolean): ActionWith<boolean> {
  return {
    type: DrawingsTextSearchActionTypes.SET_TEXT_SEARCH_ACTIVE,
    payload: isActive,
  };
}


export const DrawingsTextSearchActions = {
  setQuery,
  toggleCaseSensitive,
  toggleWholeWord,
  toggleRegExp,
  setResults,
  setIsLoading,
  setPagesSetting,
  setPageTextRects,
  setIsTextLoading,
  setTextSearchActive,
  setIsError,
};
