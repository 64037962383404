import * as THREE from 'three';

export function createMultiMaterialObject(
  geometry: THREE.ExtrudeGeometry | THREE.BufferGeometry,
  materials: THREE.Material[],
  userData: any,
): THREE.Object3D {
  const group = new THREE.Object3D();
  for (let i = 0, l = materials.length; i < l; i ++) {
    const mesh = new THREE.Mesh(geometry, materials[i]);
    mesh.userData = userData;
    group.add(mesh);
  }
  return group;
}
