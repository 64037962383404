import { Popover } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';
import { PopoverOrigin } from '@material-ui/core/Popover';
import autobind from 'autobind-decorator';
import * as React from 'react';

import './delete-link-popup.scss';

import { KreoButton } from 'common/UIKit';

interface Props {
  anchorX: number;
  anchorY: number;
  onDelete: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const transformOrigin: PopoverOrigin = {
  horizontal: 'center',
  vertical: 'bottom',
};
const paperProps: PaperProps = {
  classes: {
    root: 'macro-sequence-delete-link-popup',
  },
};

export class DeleteLinkPopup extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { isOpen, anchorX, anchorY } = this.props;
    return (
      <Popover
        open={isOpen}
        anchorReference='anchorPosition'
        anchorPosition={{
          left: anchorX,
          top: anchorY,
        }}
        transformOrigin={transformOrigin}
        PaperProps={paperProps}
        onBackdropClick={this.onCancel}
      >
        <div className='macro-sequence-delete-link-popup__title'>Delete Link</div>
        <div className='macro-sequence-delete-link-popup__actions'>
          <KreoButton
            onClick={this.onCancel}
            caption='Cancel'
            size='small'
            rounded={true}
            controlName='delete-link-on-cancel'
          />
          <KreoButton
            onClick={this.onDelete}
            caption='Delete'
            size='small'
            rounded={true}
            mode='error'
            controlName='delete-link-on-delete'
          />
        </div>
      </Popover>
    );
  }

  @autobind
  private onDelete(): void {
    this.props.onDelete();
  }

  @autobind
  private onCancel(): void {
    this.props.onCancel();
  }
}
