import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { DatabaseResourceVariantActions } from '../../actions/creators/database-resource-variant';
import { ModifyDatabaseEntityMode, ResourceType } from '../../enums';
import { UnitModel } from '../../interfaces/data';
import { PlantVariantModel } from '../../interfaces/resources-data';
import { PlantVariantForm } from '../../interfaces/resources-rest-data';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';
import { DatabaseResourcePlantVariant } from './database-resource-plant-variant';

interface ReduxProps {
  variant: PlantVariantModel;
  resourceId: number;
  databaseId: number;
  units: UnitModel[];
}

interface ReduxActions {
  setEditResourceVariantModel: (variant: PlantVariantModel) => void;
  createDatabaseResourceVariant: (
    databaseId: number, resourceId: number, type: ResourceType, model: PlantVariantForm, formId: string,
  ) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps {
}

class CreateDatabaseResourcePlantVariantComponent extends React.Component<Props> {
  private readonly formId: string = 'create-plant-variant';

  public componentDidMount(): void {
    this.props.setEditResourceVariantModel(this.getDefaultVariantModel());
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Plant Variant'
        isApplyButtonDisabled={this.isCreateButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Create}
        readonly={this.props.readonly}
        form={this.formId}
        onSubmit={this.create}
        initialValues={this.getDefaultVariantModel()}
      >
      {
        this.props.variant ? (
          <KreoScrollbars>
            <DatabaseResourcePlantVariant
              variant={this.props.variant}
              onChange={this.props.setEditResourceVariantModel}
              readonly={this.props.readonly}
            />
          </KreoScrollbars>
        ) : <SvgSpinner size='middle'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }


  @autobind
  private create(values: PlantVariantForm): void {
    this.props.createDatabaseResourceVariant(
      this.props.databaseId, this.props.resourceId, ResourceType.Plant, values, this.formId,
    );
  }

  private isCreateButtonDisabled(): boolean {
    const { variant } = this.props;
    return !variant ||
      !variant.name ||
      !variant.name.trim() ||
      !(variant.cost >= 0);
  }

  private getDefaultVariantModel(): PlantVariantModel {
    return {
      id: 0,
      name: '',
      cost: null,
      isMainContractor: false,
      rentTime: 1,
      shiftHours: 8,
      resourceId: this.props.resourceId,
      resourceName: null,
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    variant: editModel.variant,
    resourceId: editModel.rootId,
    databaseId: state.database.currentDatabase.database.id,
    units: state.database.currentDatabase.units,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditResourceVariantModel: variant =>
      dispatch(DatabaseResourceListingActions.setEditResourceVariantModel(variant)),
    createDatabaseResourceVariant: (databaseId, resourceId, type, model, formId) =>
      dispatch(DatabaseResourceVariantActions.createResourceVariant(databaseId, resourceId, type, model, formId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const CreateDatabaseResourcePlantVariant = connector(CreateDatabaseResourcePlantVariantComponent);
