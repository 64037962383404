import * as React from 'react';

import './slack-range.scss';

import { SlackHorizontalCoordinates } from '../../utils/gantt-chart';

interface Props {
  slackData: SlackHorizontalCoordinates | null;
  color: string;
  sendSlackRef: (ref: HTMLDivElement) => void;
}

export class BaseSlackRange extends React.Component<Props> {
  public render(): React.ReactNode {
    if (!this.props.slackData) {
      return null;
    }

    return (
      <div
        ref={this.props.sendSlackRef}
        className='gantt-line-body-slack-range'
        style={{
          transform: `translateX(${this.props.slackData.left}px)`,
          width: this.props.slackData.width,
          background: this.props.color,
        }}
      >
        <div className='gantt-line-body-slack-range__left-border'/>
        <div className='gantt-line-body-slack-range__right-border'/>
      </div>
    );
  }
}
