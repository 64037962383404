import styled from 'styled-components';

const Container = styled.div`
  width: fit-content;
  margin-top: 40px;
`;

export const Styled = {
  Container,
};
