import styled from 'styled-components';
import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';
import { LabelGroupStyled } from 'common/components/label-group';
import { QuickSearchStyled } from 'common/components/quick-search';

const HEADER_HEIGHT = 60;
const QUICK_INPUT_HEIGHT = 40;

const SELECT_PANEL_HEIGHT_SIZE = 0.5;

const MeasurePanelContainer = styled.div`
  flex-grow: 1;

  ${LabelGroupStyled.RoundButtonContainer} {
    display: none;
  }
`;

const SelectPanelContainer = styled.div`
  position: absolute;
  z-index: 100;
  right: 20px;
  top: 0;
  z-index: 101;
  height: ${100 * SELECT_PANEL_HEIGHT_SIZE}vh;
  width: 480px;
  border-radius: 15px;
  background-color: ${p => p.theme.color.backgroundRush};
  border-left: 1px solid ${p => p.theme.color.background};
  border: 1px solid ${p => p.theme.color.background};
  box-shadow: ${p => p.theme.shadow.xl};

  ${QuickSearchStyled.Input} {
    background-color: transparent;
  }

  > div {
    :not(:first-child) {
      height: calc(100% - ${HEADER_HEIGHT}px);

      > div {
        :not(:first-child) {
          height: calc(100% - ${QUICK_INPUT_HEIGHT}px);
        }
      }
    }

    > .ag-theme-balham-dark {
      background-color: transparent;
      .ag-root-wrapper.ag-layout-normal.ag-ltr {
        background-color: transparent;
        border-color: transparent;
      }
      .ag-header.ag-focus-managed.ag-pivot-off {
        background-color: transparent;
      }
      .ag-row.ag-row-no-focus {
        .ag-row-even.ag-row-level-0.ag-row-group.ag-row-group-contracted.ag-row-position-absolute.ag-row-first {
          background-color: transparent;
        }
      }
      .ag-row.ag-row-no-focus.ag-row-level-0.ag-row-position-absolute.ag-row-odd {
        background-color: transparent;
      }
      .ag-row.ag-row-no-focus.ag-row-even.ag-row-level-0.ag-row-position-absolute {
        background-color: transparent;
      }
      .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
        background-color: transparent;
      }
      .ag-row.ag-row-even {
        .ag-row-level-0.ag-row-group.ag-row-position-absolute.ag-row-first.ag-row-focus.ag-row-group-expanded {
          background-color: transparent;
        }
      }
      .ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-focus.ag-row-odd {
        background-color: transparent;
      }
      .ag-row.ag-row-no-focus.ag-row-odd.ag-row-level-1.ag-row-position-absolute {
        background-color: transparent;
      }
      .ag-center-cols-container {
        .ag-row {
          background-color: transparent;
        }
      }
    }
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  margin-left: 10px;
  z-index: 1003;
  ${UpgradeWrapperStyled.StarsContainer} {
    right: -5px;
    top: -5px;
  }
`;

const SelectAssign = styled.div<{ isNeedOffset: boolean }>`
  padding: 0 10px;
  margin-top: ${p => !p.isNeedOffset ? '-8px' : 0 };
`;

export const Styled = {
  SelectAssign,
  MeasurePanelContainer,
  SelectPanelContainer,
  ButtonContainer,
  SELECT_PANEL_HEIGHT_SIZE,
};
