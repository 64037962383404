import React from 'react';

import { CommentaryThread } from 'unit-2d-comments/interfaces';
import { BaseCommentProps } from './interfaces';


interface Props {
  currentUserId: string;
  comments: CommentaryThread[];
  commentType: React.ComponentType<BaseCommentProps>;
  onAddSubComment: (commentId: number, subCommentText: string) => void;
  onRemoveComment: (commentId: number) => void;
  onRemoveSubComment: (commentId: number, subCommentId: number) => void;
  onEditComment: (commentId: number, text: string) => void;
  onEditSubComment: (commentId: number, subCommentId: number, text: string) => void;
  onResolve: (commentId: number) => void;
  onCommentClick: (commentId: number) => void;
}

export const CommentsList: React.FC<Props> = ({
  currentUserId,
  comments,
  commentType,
  onAddSubComment,
  onRemoveComment,
  onRemoveSubComment,
  onEditComment,
  onEditSubComment,
  onResolve,
  onCommentClick,
}) => {
  const Comment = commentType;

  return (<>
    {
      comments.map((x) => {
        return (
          <Comment
            {...x}
            currentUserId={currentUserId}
            key={x.id}
            saveButtonText={'save'}
            onEditSubComment={onEditSubComment}
            onAddSubComment={onAddSubComment}
            onRemoveComment={onRemoveComment}
            onRemoveSubComment={onRemoveSubComment}
            onEditComment={onEditComment}
            onResolve={onResolve}
            onCommentClick={onCommentClick}
          />
        );
      })
    }
  </>);
};
