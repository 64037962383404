import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';

import { ProjectType } from 'common/constants/project-type';
import { Notification } from 'common/interfaces/realtime-messages';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { RevisionsActions } from 'unit-cost-estimate/actions/creators';
import { NotificationActions } from './actions';
import { AlertType } from './alert-type';
import { Alert } from './interfaces';
import { NotificationsMessages } from './notification-messages';
import { NotificationsUrlsBuilderProvider } from './notification-urls';


const buildAlert = (notification: Notification, alertType: AlertType): Alert => {
  const messageBuilder = NotificationsMessages[notification.type];
  const urlBuilderProvider = NotificationsUrlsBuilderProvider[notification.type];

  return {
    message: notification.message
      ? notification.message
      : messageBuilder ? messageBuilder(notification.data) : undefined,
    alertType,
    urlBuilder: urlBuilderProvider && urlBuilderProvider(notification.data),
  };
};

function* addInfoAlert(notification: Notification): SagaIterator {
  try {
    if (notification.isAlert) {
      yield put(NotificationActions.addAlert(buildAlert(notification, AlertType.Info)));
    } else {
      if (yield selectWrapper(
        s => s.projects.currentProject && s.projects.currentProject.type !== ProjectType.Project2d,
      )) {
        yield put(RevisionsActions.fetchProjectRevisionsInfo());
      }
      yield put(NotificationActions.addNotification(notification));
    }
  } catch (error) {
    console.error('Error in CommonNotificationProcessor.addInfoAlert', error);
  }
}

function* addErrorAlert(notification: Notification):  SagaIterator {
  try {
    yield put(NotificationActions.addAlert(buildAlert(notification, AlertType.Error)));
  } catch (error) {
    console.error('Error in CommonNotificationProcessor.addErrorAlert', error);
  }
}

function* addNotification(notification: Notification): SagaIterator {
  try {
    yield put(NotificationActions.addNotification(notification));
  } catch (error) {
    console.error('Error in CommonNotificationProcessor.addNotification', error);
  }
}

export const CommonNotificationProcessor = {
  addInfoAlert,
  addErrorAlert,
  addNotification,
};
