import { DeferredUpdater, DeferredUpdaterFactory, OnExecFuncType } from 'common/utils/deferred-updater';
import {
  CreateQtoTreeRowsForm,
  QtoTreeColumnsForm,
  QtoTreeRowForm,
  ReorderQtoTreeRowsForm,
} from '../../interfaces/quantity-take-off';


const aggregateUpdateColumns = (_: QtoTreeColumnsForm | null, data: QtoTreeColumnsForm): QtoTreeColumnsForm => {
  return data;
};

const aggregateRemoveRowIds = (ids: string[] | null, newIds: string[]): string[] => {
  return ids
    ? ids.concat(newIds)
    : newIds;
};

const aggregateUpdateRows = (rows: QtoTreeRowForm[] | null, newRows: QtoTreeRowForm[]): QtoTreeRowForm[] => {
  if (!rows) {
    return newRows;
  }

  const idToIndex = {};
  rows.forEach((x, index) => idToIndex[x.id] = index);
  const result = rows.slice();
  newRows.forEach(x => {
    if (x.id in idToIndex) {
      result[idToIndex[x.id]] = x;
    } else {
      result.push(x);
    }
  });

  return result;
};

const aggregateCreateRows = (
  _: CreateQtoTreeRowsForm | null, newForm: CreateQtoTreeRowsForm,
): CreateQtoTreeRowsForm => {
  return newForm;
};

const aggregateReorderRows = (
  _: ReorderQtoTreeRowsForm | null, newForm: ReorderQtoTreeRowsForm,
): ReorderQtoTreeRowsForm => {
  return newForm;
};


interface CallBacks {
  onColumnsUpdate: (reportId: number, form: QtoTreeColumnsForm) => void;
  onReorderRows: (reportId: number, form: ReorderQtoTreeRowsForm) => void;
  onRowsUpdate: (reportId: number, form: QtoTreeRowForm[]) => void;
  onRowsCreate: (reportId: number, form: CreateQtoTreeRowsForm) => void;
  onRowsRemove: (reportId: number, form: string[]) => void;
}

export class QtoTreeTableUpdater {
  private columnUpdateExecuter: DeferredUpdater<QtoTreeColumnsForm>;
  private reorderRowsExecuter: DeferredUpdater<ReorderQtoTreeRowsForm>;
  private rowUpdateExecuter: DeferredUpdater<QtoTreeRowForm[]>;
  private createRowsExecuter: DeferredUpdater<CreateQtoTreeRowsForm>;
  private removeRowsExecuter: DeferredUpdater<string[]>;

  constructor(id: number, delay: number, callBacks: CallBacks) {
    const factory = new DeferredUpdaterFactory(delay, delay * 5);

    this.columnUpdateExecuter = factory.create(
      this.getDeferredUpdaterCallBack(id, callBacks.onColumnsUpdate),
      aggregateUpdateColumns,
    );
    this.reorderRowsExecuter = factory.create(
      this.getDeferredUpdaterCallBack(id, callBacks.onReorderRows),
      aggregateReorderRows,
    );
    this.rowUpdateExecuter = factory.create(
      this.getDeferredUpdaterCallBack(id, callBacks.onRowsUpdate),
      aggregateUpdateRows,
    );
    this.createRowsExecuter = factory.create(
      this.getDeferredUpdaterCallBack(id, callBacks.onRowsCreate),
      aggregateCreateRows,
    );
    this.removeRowsExecuter = factory.create(
      this.getDeferredUpdaterCallBack(id, callBacks.onRowsRemove),
      aggregateRemoveRowIds,
    );
  }

  public executeImmediatelyAll(): void {
    this.columnUpdateExecuter.executeImmediatelyIfDeferred();
    this.reorderRowsExecuter.executeImmediatelyIfDeferred();
    this.rowUpdateExecuter.executeImmediatelyIfDeferred();
    this.createRowsExecuter.executeImmediatelyIfDeferred();
    this.removeRowsExecuter.executeImmediatelyIfDeferred();
  }

  public cancelAll(): void {
    this.columnUpdateExecuter.cancel();
    this.reorderRowsExecuter.cancel();
    this.rowUpdateExecuter.cancel();
    this.createRowsExecuter.cancel();
    this.removeRowsExecuter.cancel();
  }

  public onColumnsUpdate(updateModel: QtoTreeColumnsForm): void {
    this.columnUpdateExecuter.execute(updateModel);
  }

  public onReorderRows(data: ReorderQtoTreeRowsForm): void {
    const dependExecuters = [this.createRowsExecuter, this.removeRowsExecuter];
    this.reorderRowsExecuter.executeImmediately(data, dependExecuters);
  }

  public onRowsUpdate(data: QtoTreeRowForm[]): void {
    const dependExecuters = [this.createRowsExecuter];
    this.rowUpdateExecuter.execute(data, dependExecuters);
  }

  public onRowsCreate(form: CreateQtoTreeRowsForm): void {
    const dependExecuters = [this.removeRowsExecuter];
    this.createRowsExecuter.executeImmediately(form, dependExecuters);
  }

  public onRowsRemove(ids: string[]): void {
    const dependExecuters = [this.createRowsExecuter];
    this.removeRowsExecuter.execute(ids, dependExecuters);
  }

  private getDeferredUpdaterCallBack<T>(
    reportId: number, func: (reportId: number, form: T) => void,
  ): OnExecFuncType<T> {
    return (form: T) => func(reportId, form);
  }
}
