import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  box-shadow: ${p => p.theme.shadow.xl};
  flex-direction: row;
  min-width: 90px;
  &, * {
    outline: none;
  }
`;

export const Styled = {
  Container,
};
