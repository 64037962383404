import * as Ag from 'ag-grid-community';

import { getStartAndEndRowIndexes, getStylePropertyId } from './common';
import { StyleFields, StyleParams, StyleProperty } from '.';


const rowGetterFabric = (model: Ag.IRowModel): (index: number) => Ag.RowNode => {
  const cache: Record<number, Ag.RowNode> = {};

  return (index: number) => {
    if (!(index in cache)) {
      cache[index] = model?.getRow(index);
    }

    return cache[index];
  };
};

const cellPropertyGetterFabric = (gridApi: Ag.GridApi): (index: number, column: Ag.Column) => StyleProperty => {
  const model = gridApi.getModel();
  const getRow = rowGetterFabric(model);

  return (index: number, column: Ag.Column) => {
    const row = getRow(index);
    if (!column || !row) {
      return {};
    }
    return row.data[getStylePropertyId(column.getColId())];
  };
};

const deleteNotEqualFieldFromResult = (result: StyleProperty, compareProperty: StyleProperty): void => {
  if (!result || !compareProperty) {
    return;
  }

  Object.keys(result).forEach(key => {
    switch (key) {
      case StyleFields.BackgroundColor:
      case StyleFields.FontColor:
      case StyleFields.FontSize:
      case StyleFields.FontName:
      case StyleFields.HorizontalAlign: {
        if (result[key] !== compareProperty[key]) {
          delete result[key];
        }
        break;
      }
      case StyleFields.FontStyle: {
        // eslint-disable-next-line no-bitwise
        const newValue = result[key] & compareProperty[key];
        if (!newValue) {
          delete result[key];
        } else {
          result[key] = newValue;
        }
        break;
      }
      default: delete result[key];
    }
  });
};

const getCellsStyleProperty = (gridApi: Ag.GridApi, ranges: Ag.CellRange[]): StyleProperty => {
  const getCellProperty = cellPropertyGetterFabric(gridApi);
  if (!ranges[0]) {
    return {} as any;
  }

  const result = { ...getCellProperty(ranges[0].startRow.rowIndex, ranges[0].columns[0]) };

  for (const range of ranges) {
    const [startIndex, endIndex] = getStartAndEndRowIndexes(range);
    for (let rowIndex = startIndex; rowIndex <= endIndex; rowIndex++) {
      for (const column of range.columns) {
        const cellProperty = getCellProperty(rowIndex, column);
        deleteNotEqualFieldFromResult(result, cellProperty);
        if (!result || !Object.keys(result).length) {
          return result;
        }
      }
    }
  }

  return result;
};

const getCellStyleProperty = (params: StyleParams): StyleProperty => {
  const colId = params.colDef.colId;
  return params.data[getStylePropertyId(colId)];
};


export const PropertyGetter = {
  getCellsStyleProperty,
  getCellStyleProperty,
};
