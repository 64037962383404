import styled from 'styled-components';

export const DrawingsSubmenuContainer = styled.div`
  z-index: 1001;
  box-shadow: ${p => p.theme.shadow.xl};
  box-sizing: border-box;
  min-width: 210px;
  border-radius: 15px;
  max-height: 100%;
  overflow: auto;
  border: 1px solid ${p => p.theme.color.background};
  background-color: ${p => p.theme.color.backgroundRush};

  :first-child{
    padding-top: 10px;
  }

  :last-child{
    padding-bottom: 10px;
  }

  p {
    font-weight: 400;
  }

  &::-webkit-scrollbar-track {
    margin: 15px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }
`;
