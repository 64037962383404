const prefix = '@modelcheck/';

export const MODEL_CHECK_GET_DATA: string = `${prefix}GET_DATA`;
export const MODEL_CHECK_GET_DATA_SUCCESSED: string = `${prefix}GET_DATA_SUCCESSED`;
export const MODEL_CHECK_ORDER_SELECT: string = `${prefix}ORDER_SELECT`;
export const MODEL_CHECK_SELECT_HANDLE: string = `${prefix}SELECT_HANDLE`;
export const MODEL_CHECK_DESELECT: string = `${prefix}DESELECT`;
export const SHOW_HIDE_PROPS: string = `${prefix}SHOW_HIDE_PROPS`;
export const SELECT_SECOND_HANDLE: string = `${prefix}SELECT_SECOND_HANDLE`;
export const SELECT_BY_ID: string = `${prefix}SELECT_BY_ID`;
export const DESELECT_HANDLE: string = `${prefix}DESELET_HANDLE`;
export const MODEL_CHECK_GET_DATA_FAIL: string =  `${prefix}GET_DATA_FAIL`;
export const MODEL_CHECK_RUN: string = `${prefix}RUN`;
export const MODEL_CHECK_GET_AVAILABLE_CHECKS = `${prefix}GET_AVAILABLE_CHECKS`;
export const MODEL_CHECK_GET_AVAILABLE_CHECKS_SUCCESS = `${prefix}GET_AVAILABLE_CHECKS_SUCCESS`;
export const MODEL_CHECK_TOGGLE_CHECK_SELECTED = `${prefix}MODEL_CHECK_TOGGLE_CHECK_SELECTED`;
export const MODEL_CHECK_DROP_STATE = `${prefix}MODEL_CHECK_DROP_STATE`;
