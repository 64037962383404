const prefix = '@macro-sequence';

export const MacroSequenceActionTypes = {
  UPDATE_MACRO_SEQUENCE: `${prefix}/UPDATE_MACRO_SEQUENCE`,
  SET_EDGE_CREATOR_ROOT_VERTEX_AND_DIRECTION: `${prefix}/SET_EDGE_CREATOR_ROOT_VERTEX_AND_DIRECTION`,
  SET_EDGE_CREATOR_DESTINATION_VERTEX: `${prefix}/SET_EDGE_CREATOR_DESTINATION_VERTEX`,
  RESET_EDGE_CREATOR: `${prefix}/RESET_EDGE_CREATOR`,
  ADD_EDGE: `${prefix}/ADD_EDGE`,
  REMOVE_EDGE: `${prefix}/REMOVE_EDGE`,
  SET_LIMITATION_TIME_LAG: `${prefix}/SET_LIMITATION_TIME_LAG`,
  SET_ACTIVE_ACTIVITY_GROUP_OR_UNGROUPED_ACTIVITY_BY_GROUP_ID:
    `${prefix}/SET_ACTIVE_ACTIVITY_GROUP_OR_UNGROUPED_ACTIVITY_BY_GROUP_ID`,
  SET_HIGHLIGHTED_GRAPH_ITEM_ID: `${prefix}/SET_HIGHLIGHTED_GRAPH_ITEM_ID`,
  SET_FILTERED_WORK_PACKAGE_IDS: `${prefix}/SET_FILTERED_WORK_PACKAGE_IDS`,
  SET_FILTERED_ACTIVITY_GROUP_IDS: `${prefix}/SET_FILTERED_ACTIVITY_GROUP_IDS`,
  setSelectedDestinationId: `${prefix}/setSelectedDestinationId`,
};
