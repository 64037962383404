import { DrawingsGeometryFilterBase } from './filter-base';

export class DrawingsInstanceDictFilter implements DrawingsGeometryFilterBase {
  public applyFilter: (value: string) => boolean;

  private _statuses: Record<string, boolean>;

  constructor(statuses: Record<string, boolean>, reversedIfTrue: boolean = false) {
    this._statuses = statuses;
    if (!Object.keys(statuses).length) {
      this.applyFilter = () => true;
    } else {
      this.applyFilter = reversedIfTrue ? (id) => !this._statuses[id] : id => this._statuses[id];
    }
  }
}
