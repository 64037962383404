import autobind from 'autobind-decorator';
import * as React from 'react';

import './message.scss';

import { ConstantFunctions } from 'common/constants/functions';
import { MessageApi } from '../interfaces';
import { newLineFormatter } from './utils/new-line-formatter';

interface Props {
  name?: string;
  maxViewRows?: number;
  startViewLines?: number;
  getApi?: (api: MessageApi) => void;
  onChange?: (value: string) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
  placeholder?: string;
}


interface State {
  height: number;
}

class Message extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    maxViewRows: 4,
    placeholder: 'Message',
    startViewLines: 1,
    getApi: ConstantFunctions.doNothing,
    onChange: ConstantFunctions.doNothing,
    onKeyUp: ConstantFunctions.doNothing,
    autoFocus: false,
  };


  private ref: HTMLTextAreaElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      height: 15 * props.startViewLines,
    };
  }
  public componentDidMount(): void {
    const api = { clear: this.clear };
    this.props.getApi(api);
  }


  public render(): any {
    return (
      <textarea
        autoFocus={this.props.autoFocus}
        onKeyUp={this.onKeyUp}
        ref={this.saveRef}
        placeholder={this.props.placeholder}
        rows={1}
        style={{ height: this.state.height }}
        className='kreo-text-message'
        name={this.props.name}
        onChange={this.onChange}
      />
    );
  }


  @autobind
  private onKeyUp(e: React.KeyboardEvent<HTMLTextAreaElement>): void {
    this.props.onKeyUp(e);
  }

  @autobind
  private clear(): void {
    this.ref.value = '';
  }

  @autobind
  private saveRef(ref: HTMLTextAreaElement): void {
    this.ref = ref;
  }

  @autobind
  private onChange(e: any): void {
    const maxViewRows = this.props.maxViewRows;
    const startViewLines = this.props.startViewLines;
    const value = e.target.value;
    const lines = value.split(/\r|\r\n|\n/);
    const count = lines.length;

    if (count <= maxViewRows) {
      if (count <= startViewLines) {
        this.setState({ height: startViewLines * 15 });
      } else {
        this.setState({ height: count * 15 });
      }
    }
    this.props.onChange(newLineFormatter(value));
  }
}

export default Message;
