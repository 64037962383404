import * as React from 'react';

export const KreoIconSortingArrow: React.FC = () => {
  return (
    <svg
      width='12px'
      height='6px'
      viewBox='0 1 12 6'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >

    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <polygon id='Combined-Shape' fill='currentColor' points='8.5 2.5 6 5 3.5 2.5'/>
    </g>
    </svg>
  );
};
