import * as React from 'react';

export const KreoIconNrm1: React.FC = (_props) => {
  return (
    <svg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='UI-KIT' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Database_Activities_UI-Elements' transform='translate(-618.000000, -1446.000000)'>
          <g id='ActivityEditor' transform='translate(560.000000, 1281.000000)'>
            <g id='ResType-1-Copy-3' transform='translate(40.000000, 84.000000)'>
              <g id='Group-5' transform='translate(0.000000, 60.000000)'>
                <g id='Ss' transform='translate(18.000000, 10.000000)'>
                  <g id='DB/icon-nrm1' transform='translate(0.000000, 11.000000)'>
                    <rect id='Background' x='0' y='0' width='18' height='18'/>
                    <g id='icon_'>
                      <circle id='Oval' fill='#3C7A96' cx='9' cy='9' r='9'/>
                      <polygon
                        id='1'
                        fill='#FFFFFF'
                        points='10.4238281 13 8.44335938 13 8.44335938 6.6953125 6.49804688 7.26367188
                        6.49804688 5.76367188 10.2421875 4.46875 10.4238281 4.46875'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
