import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { MetricNames, analyticEvent } from 'utils/posthog';
import { SubscriptionActions } from '../../../../subscription/store';

type Payload = Array<{ email: string, role: string, id: string }>;

type UseSaveInvitePeople = () => (payload?: Payload) => void;

export const useSaveInvitePeople: UseSaveInvitePeople = () => {
  const dispatch = useDispatch();
  return useCallback(
    (payload) => {
      dispatch(SubscriptionActions.setTempInvitePeople(payload));
      analyticEvent(
        MetricNames.invitePeople.inviteAfterCreationSubscription,
        { inviteEmails: payload && payload.map(form => form.email) },
      );
    },
    [dispatch],
  );
};
