import styled from 'styled-components';

const ContentContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 40px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.color.backgroundRush};
  display: flex;
  flex-direction: column;
  h3, h5 {
    text-align: center;
  }
  > h3 {
    margin-top: 50px;
    height: 45px;
  }
  > h5 {
    height: 41px;
  }

  h5 {
    margin-top: 40px;
  }
`;

const Img = styled(ContentContainer)`
  margin-top: 120px;
  img {
    width: 360px;
    height: 360px;
  }
`;


export const Styled = {
  Container,
  Img,
  ContentContainer,
};
