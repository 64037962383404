import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './database-selector.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RenderIf } from 'common/components/render-if';
import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { Checkbox, KreoButton, KreoIconInfoBigColor, MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { HelpTooltip } from '../../../../../../components/controls/tooltips';
import { DatabaseModel } from '../../../../../../units/databases/interfaces/data';
import { CreateDatabaseForm } from '../../../../../../units/databases/interfaces/rest-data';
import { DatabaseActions } from '../../../../../databases/actions/creators/database';
import { Creator } from './creator';

interface ReduxProps {
  databases: DatabaseModel[];
  isLoaded: boolean;
  companyId: number;
}

interface ReduxActions {
  loadDatabases: (companyId: number) => void;
  createDatabase: (name: string, importDatabasesIds: number[]) => void;
}

interface Props extends ReduxProps, ReduxActions, AbilityAwareProps {
  onCalculate: (ids: number[], usePreviousLaunch: boolean) => void;
  canBeCalculated: boolean;
  databasesCalculated?: number[];
  allowSettings: boolean;
}

interface State {
  selectedDBIds: number[] | null;
  usePreviousLaunch: boolean;
}

class DatabaseSelectorComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedDBIds: props.databasesCalculated
        ? props.databasesCalculated
        : props.databases.length && props.databases.filter(x => x.isDefault).map(x => x.id) || null,
      usePreviousLaunch: true,
    };

    if (!props.databases || props.databases.length === 0) {
      this.props.loadDatabases(props.companyId);
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (!isEqual(prevProps.databasesCalculated, this.props.databasesCalculated) && this.props.databasesCalculated) {
      this.setState({ selectedDBIds: this.props.databasesCalculated });
    } else if (!this.state.selectedDBIds && this.props.databases) {
      this.setState({ selectedDBIds: this.props.databases.filter(x => x.isDefault).map(x => x.id) });
    }
  }

  public render(): JSX.Element {
    const { databases } = this.props;
    const { selectedDBIds } = this.state;
    const bcis = databases && databases.find(x => x.version && !x.vendor);

    const isButtonDisabled =
      !this.state.selectedDBIds ||
      this.state.selectedDBIds.length === 0 ||
      !this.props.canBeCalculated;

    const databaseOptions =
      databases &&
      databases.filter(x => !x.version || x.vendor).map(x => {
        return {
          value: x.id,
          text: (
            <div className='database-selector__checked-option'>
              <Checkbox
                checked={selectedDBIds && selectedDBIds.includes(x.id)}
              />
              <span className='database-selector__label'>
                {x.name}
                {x.isDefault && <span className='database-selector__default-db'>(default)</span>}
              </span>
            </div>
          ),
          tooltip: x.name,
        };
      });

    return (
      <div className='database-selector'>
        {!this.props.isLoaded ? (
          <div className='database-selector__spinner-wrap'>
            <SvgSpinner size='middle' />
          </div>
        ) : (
          <React.Fragment>
            <div className='database-selector__content'>
              <div className='database-selector__title'>
                <span>Database</span>
                <HelpTooltip
                  icon={<KreoIconInfoBigColor />}
                  text='The selected databases will be used for subsequent calculations'
                  infoMode={true}
                  className='database-selector__input-tooltip'
                />
              </div>
              <div className='database-selector__select-wrap'>
                <MaterialSelect
                  value={this.state.selectedDBIds}
                  className='database-selector__material-select'
                  dropdownClassName='database-selector__material-paper'
                  onChange={this.selectDatabase}
                  multiple={true}
                  selectionRenderer={this.selectionRenderer}
                  bottomElement={this.getCreator()}
                  noDataText='No Databases'
                  placeholder='Select Databases'
                  displayedType={MaterialComponentType.Native}
                  autoWidth={true}
                >
                  {databaseOptions &&
                    databaseOptions.map((database, index) => {
                      return (
                        <MaterialMenuItem
                          key={index}
                          value={database.value}
                          className='database-selector__material-select-item'
                        >
                          {database.text}
                        </MaterialMenuItem>
                      );
                    })}
                </MaterialSelect>
                <KreoButton
                  className='database-selector__calculate-action'
                  caption='Calculate'
                  size='medium'
                  mode='add'
                  disabled={isButtonDisabled}
                  onClick={this.onCalculateClick}
                  controlName='activity-assignment-calculate-button'
                />
              </div>
              <RenderIf condition={this.props.allowSettings && this.props.databasesCalculated !== null}>
                <Checkbox
                  checked={this.state.usePreviousLaunch}
                  disabled={isButtonDisabled}
                  label='Save manual edits from the previous calculation'
                  onChange={this.onUsePreviousLaunchChecked}/>
              </RenderIf>
            </div>
            {this.props.ability.can(Operation.Buy, Subject.KreoProvidedDb) ? (
              <KreoButton
                mode='action'
                size='medium'
                className='database-selector__buy-action'
                caption={`Buy ${bcis ? bcis.name : ''}`}
                onClick={this.onBuyClick}
                controlName='activity-assignment-buy-bcis-button'
              />
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }

  @autobind
  private onCalculateClick(): void {
    if (this.props.onCalculate) {
      this.props.onCalculate(this.state.selectedDBIds, this.state.usePreviousLaunch);
    }
  }

  @autobind
  private onUsePreviousLaunchChecked(): void {
    this.setState({ usePreviousLaunch: !this.state.usePreviousLaunch });
  }

  @autobind
  private onBuyClick(): void {
    console.error('Buy DB page is not implemented yet');
  }

  @autobind
  private getCreator(): JSX.Element {
    return (
      this.props.ability.can(Operation.Create, Subject.Database) && (
        <Creator onCreate={this.onCreateDb} placeholder='Name' />
      )
    );
  }

  @autobind
  private selectDatabase(_event: any, value: number[]): void {
    this.setState({
      selectedDBIds: value,
    });
  }

  @autobind
  private selectionRenderer(value: number[]): string {
    if (value.length === 1) {
      const database = this.props.databases.find(db => db.id === value[0]);
      if (database) {
        return database.name;
      }
    } else if (value.length > 1) {
      return `${value.length} databases selected`;
    }

    return '';
  }

  @autobind
  private onCreateDb(name: string): void {
    this.props.createDatabase(name, this.state.selectedDBIds);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const currentProject = state.projects.currentProject;

  return {
    databases: state.database.databases,
    isLoaded: state.database.statuses.databases === RequestStatus.Loaded,
    companyId: currentProject ? currentProject.companyId : null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadDatabases: (companyId: number) => {
      dispatch(DatabaseActions.loadDatabases(companyId));
    },
    createDatabase: (name, importDatabaseIds) => {
      const form: CreateDatabaseForm = {
        name,
        version: null,
        isReadOnly: false,
        importDatabaseIds,
      };
      dispatch(DatabaseActions.createDatabase(form));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const DatabaseSelector = withAbilityContext(connector(DatabaseSelectorComponent));
