import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './sequence-engine-overlay.scss';

import { Controls3D } from 'common/components/controls-3d';
import { EngineHotkeysListItem } from 'common/components/engine-hotkeys-hint';
import { KreoToolbar } from 'common/components/kreo-toolbar';
import { KreoColors } from 'common/enums/kreo-colors';
import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { StringDictionary } from 'common/interfaces/dictionary';
import { State as ReduxState } from 'common/interfaces/state';
import { Engine } from '../../../../components/engine';
import { KreoEngine } from '../../../../components/engine/KreoEngine';
import { Unit } from '../../../../components/engine/KreoEngineConsts';
import { EngineActions } from '../../actions';
import { GroupedIdsByName, SequenceColor } from '../../interfaces';
import { EnginePopover, EnginePopoverOperationType } from '../engine-popover';
import { EngineProps } from './interfaces';

interface ReduxProps {
  availableBimElementIds: number[] | null;
  defaultEngineAvailableBimElementIds: number[] | null;
  colorToBimHandleIdsMap: StringDictionary<number[]>;
  selectedBimHandleIds: number[];
  highlightedIds: number[];
}

interface ReduxActions {
  setAvailableBimElements: (ids: number[] | null) => void;
}

interface Props extends ReduxProps, ReduxActions, EngineProps {
  projectId: number;
}

interface State {
  ghostEnabled: boolean;
  clipBoxEnabled: boolean;
  isIsometryEnabled: boolean;
  isAnyInvisible: boolean;
  isEngineSelected: boolean;
  isRulerEnabled: boolean;
  fullscreen: boolean;
  isToolbarHide: boolean;
  color: string;
  popoverGroups: GroupedIdsByName[];
  popoverOperation: EnginePopoverOperationType;
  popoverX: number;
  popoverY: number;
  engineContainerRef: HTMLDivElement;
  isImperialUnit: boolean;
}

class SequenceEngineOverlayComponent extends React.Component<Props, State> {
  private engine: KreoEngine = null;
  private specialAbilitiesHotkeyList: EngineHotkeysListItem[] = [
    { name: 'Multi Deselect', key: ['Ctrl', 'Shift', 'Click'] },
    { name: 'Rect Deselect', key: ['Ctrl', 'Shift', 'Drag'] },
  ];
  constructor(props: Props) {
    super(props);
    this.state = {
      ghostEnabled: true,
      clipBoxEnabled: false,
      isIsometryEnabled: false,
      isAnyInvisible: false,
      isEngineSelected: false,
      isRulerEnabled: false,
      isImperialUnit: false,
      color: KreoColors.blue4,
      fullscreen: false,
      isToolbarHide: false,
      popoverGroups: [],
      popoverOperation: 'select',
      popoverX: 0,
      popoverY: 0,
      engineContainerRef: null,
    };
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.state.ghostEnabled && !prevState.ghostEnabled) {
      this.engine.toggleVisibility(true);
    }

    if (!isEqual(prevProps.selectedBimHandleIds, this.props.selectedBimHandleIds)) {
      this.engine.setSelected(this.props.selectedBimHandleIds);
    }

    if (!isEqual(prevProps.colorToBimHandleIdsMap, this.props.colorToBimHandleIdsMap)) {
      this.engine.setColorTint(null);

      Object.keys(this.props.colorToBimHandleIdsMap)
        .sort((a, b) => this.getColorPriority(a as SequenceColor) - this.getColorPriority(b as SequenceColor))
        .forEach(color => {
          const ids = this.props.colorToBimHandleIdsMap[color];
          this.engine.setColorTint(color, ids);
        });
    }

    if (!isEqual(this.props.highlightedIds, prevProps.highlightedIds)) {
      this.engine.setHighlighted(this.props.highlightedIds);
    }

    // let focusedIds = [];

    if (
      !isEqual(this.props.availableBimElementIds, prevProps.availableBimElementIds) ||
      !isEqual(prevProps.colorToBimHandleIdsMap, this.props.colorToBimHandleIdsMap) ||
      !isEqual(this.props.highlightedIds, prevProps.highlightedIds) ||
      this.state.ghostEnabled !== prevState.ghostEnabled
    ) {
      // focusedIds = this.props.availableBimElementIds;
      if (!this.props.availableBimElementIds) {
        this.engine.setRenderMode('standard');
        this.engine.toggleVisibility(true);
      } else {
        const ids = Object.keys(this.props.colorToBimHandleIdsMap)
          .map(key => this.props.colorToBimHandleIdsMap[key])
          .reduce((a, b) => a.concat(b), [])
          .concat(this.props.availableBimElementIds);
        // focusedIds = ids;
        if (this.state.ghostEnabled) {
          this.engine.setRenderMode('ghost');
        } else {
          this.engine.toggleVisibility(true, ids, false);
        }
        this.engine.setRenderMode('standard', ids.concat(this.props.highlightedIds));
      }
    }

    if (this.engine && !!this.engine.getSelected().length !== this.state.isEngineSelected) {
      this.setState({ isEngineSelected: !!this.engine.getSelected().length });
    }

    // if (!isEqual(this.props.availableBimElementIds, prevProps.availableBimElementIds)) {
    //   this.engine.focusCamera(focusedIds);
    // }
  }

  public render(): JSX.Element {
    return (
      <div
        className={classNames(
          'sequence-engine',
          { 'sequence-engine--full-screen': this.state.fullscreen },
        )}
        onDoubleClick={this.onWrapDoubleClick}
      >
        <div
          className={classNames(
            'sequence-engine__engine-wrap',
            { 'sequence-engine__engine-wrap--toolbar-hide': this.state.isToolbarHide },
          )}
        >
          <Engine
            textures='/static/textures/'
            sendEngineApi={this.sendEngineApi}
            projectId={this.props.projectId}
            onHandleClick={this.onHandleClick}
            onToggleRuler={this.onEngineToggleRuler}
            onToggleClipBox={this.onEngineToggleClipBox}
            toggleCameraParallel={this.toggleCameraParallel}
            saveContainerRef={this.saveEngineContainerRef}
            onChangeVisibility={this.onChangeVisibility}
          />
          <KreoToolbar
            isToolbarHide={this.state.isToolbarHide}
            onHideToggle={this.onHideToolbarToggle}
            className='sequence-engine__engine-toolbar'
          >
            <Controls3D
              isSelectedElement={this.state.isEngineSelected}
              ghostEnabled={this.state.ghostEnabled}
              isometryEnabled={this.state.isIsometryEnabled}
              clipBoxEnabled={this.state.clipBoxEnabled}
              rulerEnabled={this.state.isRulerEnabled}
              isFullScreen={this.state.fullscreen}
              isImperialUnit={this.state.isImperialUnit}
              engineLayoutContainer={this.state.engineContainerRef}
              color={this.state.color}
              onChangeColor={this.onChangeColor}
              onAssignColor={this.onAssignColor}
              onDefaultColor={this.onDefaultColor}
              onHome={this.onHome}
              onFocus={this.onFocus}
              onIsolate={this.onIsolate}
              onHide={this.onHide}
              isAnyInvisible={this.state.isAnyInvisible}
              onShowHideElements={this.onShowHideElements}
              toggleGhost={this.toggleGhost}
              toggleClipBox={this.toggleClipBox}
              toggleIsometry={this.toggleIsometry}
              toggleRuler={this.toggleRuler}
              toggleFullScreen={this.toggleFullScreen}
              toggleMetricImperial={this.toggleUnit}
              specialAbilitiesHotkeyList={this.specialAbilitiesHotkeyList}
            />
          </KreoToolbar>
        </div>
        {
          this.state.popoverGroups.length > 0 &&
          <EnginePopover
            groups={this.state.popoverGroups}
            title={this.props.popoverTitle}
            anchorX={this.state.popoverX}
            anchorY={this.state.popoverY}
            onSelect={this.onItemSelect}
            popoverOperation={this.state.popoverOperation}
          />
        }
      </div>
    );
  }

  @autobind
  private saveEngineContainerRef(ref: HTMLDivElement): void {
    this.setState({ engineContainerRef: ref });
  }

  @autobind
  private onWrapDoubleClick(): void {
    this.engine.setRenderMode('standard', this.props.defaultEngineAvailableBimElementIds);
    this.props.setAvailableBimElements(this.props.defaultEngineAvailableBimElementIds);
  }

  @autobind
  private sendEngineApi(engine: KreoEngine): void {
    this.engine = engine;
  }

  @autobind
  private onChangeVisibility(): void {
    if (this.state.isAnyInvisible !== this.engine.isAnyInvisible()) {
      const isAnyInvisible = this.engine.isAnyInvisible();
      this.setState({ isAnyInvisible });
    }
  }

  @autobind
  private toggleFullScreen(): void {
    this.setState({ fullscreen: !this.state.fullscreen });
  }

  @autobind
  private onHome(): void {
    this.engine.cameraToHome();
  }

  @autobind
  private onHideToolbarToggle(): void {
    this.setState((s) => ({ isToolbarHide: !s.isToolbarHide }));
  }

  @autobind
  private onShowHideElements(): void {
    this.engine.toggleVisibility(true);
    this.setState({ isAnyInvisible: false });
  }

  @autobind
  private onDefaultColor(): void {
    const ids = this.engine.getSelected();
    this.engine.setColorTint(null, ids);
  }

  @autobind
  private onAssignColor(): void {
    const ids = this.engine.getSelected();
    this.engine.setColorTint(this.state.color, ids);
  }

  @autobind
  private onChangeColor(color: string): void {
    this.setState({ color });
  }

  @autobind
  private onHide(): void {
    const ids = this.engine.getSelected();
    this.engine.toggleVisibility(false, ids);
  }

  @autobind
  private onIsolate(): void {
    const ids = this.engine.getSelected();
    this.engine.toggleVisibility(true, ids, false);
  }

  @autobind
  private onFocus(): void {
    const ids = this.engine.getSelected();
    this.engine.focusCamera(ids);
  }

  @autobind
  private onEngineToggleClipBox(clipBoxEnabled: boolean): void {
    this.setState({ clipBoxEnabled });
  }

  @autobind
  private toggleClipBox(): void {
    if (this.engine) {
      this.engine.toggleClipbox(!this.state.clipBoxEnabled, this.state.ghostEnabled ? 'ghost' : 'none');
      this.setState({ clipBoxEnabled: !this.state.clipBoxEnabled });
    }
  }

  @autobind
  private toggleGhost(): void {
    if (this.engine) {
      const { ghostEnabled, clipBoxEnabled } = this.state;
      if (clipBoxEnabled) {
        this.engine.toggleClipbox(
          true,
          !ghostEnabled ? 'ghost' : 'none',
        );
      }
      this.engine.showInvisibleAsGhost(!ghostEnabled);
      this.setState((s) => ({ ghostEnabled: !s.ghostEnabled }));
    }
  }

  @autobind
  private toggleCameraParallel(isIsometryEnabled: boolean): void {
    this.setState({ isIsometryEnabled });
  }

  @autobind
  private toggleIsometry(): void {
    this.engine.toggleParallelProjection(!this.state.isIsometryEnabled);
  }

  @autobind
  private onEngineToggleRuler(enabledRuler: boolean): void {
    this.setState({ isRulerEnabled: enabledRuler });
  }

  @autobind
  private toggleRuler(): void {
    if (this.engine) {
      const { isRulerEnabled } = this.state;
      this.engine.toggleRuler(!isRulerEnabled);
      this.setState({ isRulerEnabled: !isRulerEnabled });
    }
  }

  @autobind
  private toggleUnit(): void {
    if (this.engine) {
      const { isImperialUnit } = this.state;
      this.engine.setUiUnits(
        isImperialUnit
          ? { length: Unit.Meter, area: Unit.MeterPow2 }
          : { length: Unit.FootInch, area: Unit.FootPow2 },
      );
      this.setState({ isImperialUnit: !isImperialUnit });
    }
  }

  @autobind
  private onHandleClick(bimIds: number[], event: Event | null): void {
    if (event instanceof MouseEvent) {
      const operation = event.shiftKey && HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(event) ? 'deselect' : 'select';
      const availableBimIds = this.props.getPopoverAvailableBimIds(bimIds, operation);
      this.engine.setSelected(availableBimIds);

      if (!availableBimIds.length) {
        this.setState({ popoverGroups: [] });
        return;
      }
      const groups = this.props.getItemsForPopover(availableBimIds, operation);
      if (groups.length === 1 && groups[0].items.length === 1) {
        this.onItemSelect(groups[0].items[0].ids);
        this.props.onItemSelect(groups[0].items[0].ids, operation);
      } else {
        if (!this.state.popoverGroups.length && groups) {
          this.setState({ popoverOperation: operation });
        }
        this.setState({
          popoverGroups: groups,
          popoverX: event.clientX,
          popoverY: event.clientY,
        });
      }
    }
  }

  @autobind
  private onItemSelect(ids: number[]): void {
    this.props.onItemSelect(ids, this.state.popoverOperation);
    this.setState({
      popoverGroups: [],
    });

    this.engine.setSelected([]);
    this.setState({ isEngineSelected: false });
  }

  private getColorPriority(color: SequenceColor): number {
    switch (color) {
      case SequenceColor.WhiteHighlight: return 3;
      case SequenceColor.ActiveVertex: return 2;
      case SequenceColor.NextVertex:
      case SequenceColor.PreviousVertex:
        return 1;
      default: return 0;
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const engine = state.activityGrouping.engine;

  return {
    colorToBimHandleIdsMap: engine.colorToBimIdsMap,
    selectedBimHandleIds: engine.selectedIds,
    highlightedIds: engine.highlightedIds,
    availableBimElementIds: engine.availableBimHandleIds,
    defaultEngineAvailableBimElementIds: engine.defaultEngineAvailableBimElementIds,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setAvailableBimElements: bimIds => dispatch(EngineActions.setAvailableBimElements(bimIds)),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export const SequenceEngineOverlay = connector(SequenceEngineOverlayComponent);
