import { ContextMenuGroup } from '@kreo/kreo-ui-components';
import React from 'react';

import { DrawingMarkShapes } from 'common/components/drawings/constants/drawing-styles';
import { ShapeSwitcher } from '../../shape-switcher';
import { Styled } from './styled';

interface Props {
  setShape: (value: DrawingMarkShapes) => void;
  shape: DrawingMarkShapes;
}

const ShapeMenuComponent: React.FC<Props> = ({ setShape, shape }) => {
  return (
    <ContextMenuGroup>
      <Styled.Container>
        <ShapeSwitcher
          setShape={setShape}
          shape={shape}
        />
      </Styled.Container>
    </ContextMenuGroup>
  );
};

export const ShapeMenu = React.memo(ShapeMenuComponent);
