export enum PageOptions {
  Current = 'current',
  CurrentFile = 'currentFile',
  WithMeasurements = 'withMeasurements',
  OpenedPages = 'openedPages',
  All = 'all',
}

export enum MeasurementOptions {
  Visible = 'visible',
  All = 'all',
}


export enum AnnotationOptions {
  Stickers = 'useStickers',
  Rulers = 'useRulers',
}
