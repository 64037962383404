import { ColorPicker } from '@kreo/kreo-ui-components';
import React from 'react';
import { connect } from 'react-redux';

import { ColorControlOwnProps } from 'common/components/color-control';
import { DrawingsColorControl } from 'common/components/drawings/drawings-controls';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';

interface StateProps {
  groupColor: string;
}

interface Props extends ColorControlOwnProps, StateProps {
}

const ColorControlComponent: React.FC<Props> = ({
  colorList,
  selectedColor,
  onColor,
  title,
  groupColor,
}) => {
  return groupColor ? (
    <ColorPicker
      colorList={[groupColor]}
      selectedColor={groupColor}
      title={'FROM SELECTED FOLDER'}
      onColor={ConstantFunctions.doNothing}
    />
  ) : (
    <DrawingsColorControl
      colorList={colorList}
      selectedColor={selectedColor}
      onColor={onColor}
      title={title}
    />
  );
};

function mapStateToProps(state: State): StateProps {
  const selectedGroupId = state.drawings.selectGeometryGroup[0];
  const color = selectedGroupId
    && state.drawings.drawingGeometryGroups.find(({ id }) => id === selectedGroupId)?.color;
  return {
    groupColor: color,
  };
}

export const ColorControl = connect(mapStateToProps)(ColorControlComponent);
