import * as React from 'react';

export class KreoIconDownloadFile extends React.Component {
  public render(): React.ReactNode {
    return (
      <svg
        width='16px'
        height='16px'
        viewBox='0 0 16 16'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='download-file-icon' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <path
            // eslint-disable-next-line max-len
            d='M5,3.70710678 L3.70710678,5 L5,5 L5,3.70710678 L5,3.70710678 Z M6,3 L6,5.5 C6,5.77614237 5.77614237,6 5.5,6 L3,6 L3,12.5 C3,12.7761424 3.22385763,13 3.5,13 L7.5,13 C7.77614237,13 8,13.2238576 8,13.5 C8,13.7761424 7.77614237,14 7.5,14 L3.5,14 C2.67157288,14 2,13.3284271 2,12.5 L2,5.91421356 C2,5.51638883 2.15803526,5.13485796 2.43933983,4.85355339 L4.85355339,2.43933983 C5.13485796,2.15803526 5.51638883,2 5.91421356,2 L10.5,2 C11.3284271,2 12,2.67157288 12,3.5 L12,5.5 C12,5.77614237 11.7761424,6 11.5,6 C11.2238576,6 11,5.77614237 11,5.5 L11,3.5 C11,3.22385763 10.7761424,3 10.5,3 L6,3 L6,3 Z M11,12.7928932 L11,8.5 C11,8.22385763 11.2238576,8 11.5,8 C11.7761424,8 12,8.22385763 12,8.5 L12,12.7928932 L13.1464466,11.6464466 C13.3417088,11.4511845 13.6582912,11.4511845 13.8535534,11.6464466 C14.0488155,11.8417088 14.0488155,12.1582912 13.8535534,12.3535534 L11.8535534,14.3535534 C11.6582912,14.5488155 11.3417088,14.5488155 11.1464466,14.3535534 L9.14644661,12.3535534 C8.95118446,12.1582912 8.95118446,11.8417088 9.14644661,11.6464466 C9.34170876,11.4511845 9.65829124,11.4511845 9.85355339,11.6464466 L11,12.7928932 Z M5,8 C4.72385763,8 4.5,7.77614237 4.5,7.5 C4.5,7.22385763 4.72385763,7 5,7 L8.5,7 C8.77614237,7 9,7.22385763 9,7.5 C9,7.77614237 8.77614237,8 8.5,8 L5,8 Z M5,10 C4.72385763,10 4.5,9.77614237 4.5,9.5 C4.5,9.22385763 4.72385763,9 5,9 L8.5,9 C8.77614237,9 9,9.22385763 9,9.5 C9,9.77614237 8.77614237,10 8.5,10 L5,10 Z'
            id='Shape'
            fill='#2D4969'
            fillRule='nonzero'
          />
        </g>
      </svg>
    );
  }
}
