import autobind from 'autobind-decorator';

import { DrawingsCanvasConstants } from 'common/components/drawings/constants/drawing-canvas-constants';
import { DrawingsSVGIcons } from 'common/components/drawings/constants/drawings-svg-icons';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { ConstantFunctions } from 'common/constants/functions';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DrawingsCursorTypeHelper } from '..';
import { DrawingsMouseEventHandler } from '../../drawings-geometry-entities';
import { DrawingsGeometrySelectionArea } from '../../drawings-geometry-entities/utility';
import { EditPermissions } from '../../interfaces';
import { DrawingsDragProcessingHelper } from '../drag-instances/drawings-drag-processing-helper';
import { PaperMouseEventUtils } from '../paper-mouse-event-utils';
import { BaseRotationProcessor } from '../rotation';
import { DrawingsUserAnnotationImageBounds } from './drawings-user-annotation-image-bounds';
import { DrawingsUserAnnotationImageStore } from './drawings-user-annotation-image-store';


export interface DrawingsUserAnnotationImageConfig {
  layer: paper.Layer;
  tempLayer: paper.Layer;
  imageKey: DrawingsSVGIcons;
  id: string;
  position: paper.Point;
  rotation: number;
  scale: number;
  renderParametersContext: ContextObserverWithPrevious<DrawingsRenderParams>;
  editPermissionsObserver: ContextObserver<EditPermissions>;
  dragProcessingHelper: DrawingsDragProcessingHelper;
  onSelect: DrawingsMouseEventHandler;
  imageStore: DrawingsUserAnnotationImageStore;
  cursorHelper: DrawingsCursorTypeHelper;
  onStartDrag: (id: string, point: paper.Point) => void;
  onRotationChange: (id: string, rotation: number) => void;
  onScaleChange: (id: string, scale: number) => void;
}

export class DrawingsUserAnnotationImageEntity {
  private _config: DrawingsUserAnnotationImageConfig;
  private _image: paper.Item;
  private _boundsRect: DrawingsUserAnnotationImageBounds;
  private _rotationController: BaseRotationProcessor;

  private _dragExecutor: DeferredExecutor = new DeferredExecutor(DrawingsCanvasConstants.doubleClickDelay / 2);

  constructor(config: DrawingsUserAnnotationImageConfig) {
    this._config = config;
    this._rotationController = new BaseRotationProcessor({
      dragProcessingHelper: config.dragProcessingHelper,
      changeRotation: this.onRotationChange,
      getCenterPoint: () => this._image.position,
    });

    this._image = config.imageStore.getIcon(config.imageKey);
    this._image.position = config.position;
    this._image.scale(config.scale, config.position);
    this._image.rotate(config.rotation);
    this._image.onClick = this.onClick;
    this._boundsRect = new DrawingsUserAnnotationImageBounds(
      {
        bounds: this._image.bounds,
        rotation: 0,
        layer: config.tempLayer,
        renderParametersContext: config.renderParametersContext,
        dragProcessingHelper: config.dragProcessingHelper,
        editPermissionsObserver: config.editPermissionsObserver,
        onScaleChange: this.onScaleChange,
        cursorHelper: this._config.cursorHelper,
        rotationProcessor: this._rotationController,
      });
    this._boundsRect.rotation = config.rotation;
    this._image.onMouseDown = this.onMouseDown;
    this._image.onMouseUp = this.onMouseUp;
    this._image.onMouseEnter = this.onMouseEnter;
    this._image.onMouseLeave = this.onMouseLeave;
    this._image.addTo(config.layer);
  }

  public set scale(value: number) {
    this._image.scale(value / this._config.scale);
    this._config.scale = value;
  }

  public set selected(value: boolean) {
    this._boundsRect.selected = value;
  }

  public get selected(): boolean {
    return this._boundsRect.selected;
  }

  public set rotationAngle(value: number) {
    this._image.rotate(value - this._config.rotation);
    this._config.rotation = value;
    this._boundsRect.rotation = value;
  }

  public set position(value: paper.Point) {
    this._image.position = value;
    this._boundsRect.position = value;
  }

  public set color(color: paper.Color) {
    this._image.fillColor = color;
    this._image.strokeColor = color;
  }

  public get color(): paper.Color {
    return this._image.fillColor;
  }

  public get bounds(): paper.Rectangle {
    return this._image.bounds;
  }

  public destroy(): void {
    this._image.remove();
    this._boundsRect.destroy();
  }

  public isInRect(rect: paper.Rectangle, selectionArea: DrawingsGeometrySelectionArea): boolean {
    return this._image.position.isInside(rect) || this._boundsRect.intersectsWithSelectionRect(rect, selectionArea);
  }

  @autobind
  private onMouseEnter(): void {
    if (this._boundsRect.selected) {
      this._config.cursorHelper.hoveredSelected = true;
    } else {
      this._config.cursorHelper.hovered = true;
    }
  }

  @autobind
  private onMouseLeave(): void {
    this._config.cursorHelper.hovered = false;
  }


  @autobind
  private onScaleChange(scale: number, finish: boolean): void {
    if (!this._config.editPermissionsObserver.getContext().canEditUserAnnotation) {
      return;
    }
    this.scale = this._config.scale * scale;
    if (finish) {
      this._config.onScaleChange(this._config.id, this._config.scale);
    }
  }

  @autobind
  private onRotationChange(diffRotation: number, finish: boolean): boolean {
    this._image.rotate(diffRotation);
    this._config.rotation += diffRotation;
    if (finish) {
      this._config.onRotationChange(this._config.id, this._config.rotation);
    }
    this._boundsRect.rotation = this._config.rotation;
    return true;
  }

  @autobind
  private onClick(e: PaperMouseEvent): void {
    const { canEditUserAnnotation } = this._config.editPermissionsObserver.getContext();
    if (PaperMouseEventUtils.isLeftMouseButton(e) && canEditUserAnnotation) {
      e.stopPropagation();
      this._dragExecutor.reset();
    }
  }

  @autobind
  private onMouseDown(e: PaperMouseEvent): void {
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      ConstantFunctions.stopEvent(e);
      this._config.onSelect(this._config.id, e);
      this._dragExecutor.execute(() => this.startDrag(e));
    }
  }

  @autobind
  private onMouseUp(e: PaperMouseEvent): void {
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      this._dragExecutor.reset();
    }
  }

  @autobind
  private startDrag(e: PaperMouseEvent): void {
    this._config.onStartDrag(this._config.id, e.point);
  }
}
