import { FormulaInputAutocompleteItemType } from 'common/components/formula-editor/enums';
import { FormulaInputAutocompleteItemInfo } from '../../auto-complete';
import { CaretPosition } from '../caret-position-helper';


function getInsertString(
  dataToInsert: FormulaInputAutocompleteItemInfo,
): string {
  switch (dataToInsert.type) {
    case FormulaInputAutocompleteItemType.Property:
      return `[${dataToInsert.text}]`;
    case FormulaInputAutocompleteItemType.Operator:
      return dataToInsert.text;
    default:
      return `${dataToInsert.text}()`;
  }
}

export function insertToFormula(
  dataToInsert: FormulaInputAutocompleteItemInfo,
  value: string,
  caretPosition: CaretPosition,
): { newValue: string, newCaretPosition: CaretPosition } {
  const insertData = getInsertString(dataToInsert);

  let newCarretPosition = caretPosition.start + insertData.length;
  if (dataToInsert.type === FormulaInputAutocompleteItemType.Function) {
    newCarretPosition = caretPosition.start + insertData.length - 1;
  }

  const newValue = value.substring(0, caretPosition.start) + insertData + value.substring(caretPosition.end);
  return { newValue, newCaretPosition: { start: newCarretPosition, end: newCarretPosition } };
}
