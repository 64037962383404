import { Popover } from '@material-ui/core';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { DateRange } from 'react-date-range';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './date-filter.scss';

import { SortingDirection } from 'common/interfaces/sorting-direction';
import { KreoIconFilterNormal } from 'common/UIKit/icons';
import { State as ReduxState } from '../../../../common/interfaces/state';
import { ActivityActions } from '../../actions';
import { FilterType } from '../../filter-type';
import { Sorting, TimeInterval } from '../../interfaces';
import { UserActivityUtil } from '../../user-activity-util';
import { SortingIcon } from '../sorting-icon';

interface ReduxProps {
  timeInterval: TimeInterval;
  sorting: Sorting;
}

interface ReduxActions {
  setTimeInterval: (start: Date, end: Date) => void;
  setSorting: (type: FilterType, direction: SortingDirection) => void;
}

interface Props extends ReduxProps, ReduxActions {}

interface State {
  start?: Date;
  end?: Date;
  open: boolean;
  anchorEl: HTMLDivElement;
  edited: boolean;
  startEdit: boolean;
}

const intervalKey = 'interval';

class DateFilterComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      start: new Date(),
      end: new Date(),
      open: false,
      anchorEl: null,
      edited: false,
      startEdit: false,
    };
  }

  public render(): JSX.Element {
    const { timeInterval, sorting } = this.props;
    const { start, end, open, anchorEl, startEdit } = this.state;
    const isSelected = timeInterval.start != null;
    return (
      <div className='activity-date-filter'>
        <div
          className={classNames('activity-date-filter__filter', {
            selected: isSelected,
          })}
        >
          <div
            className='activity-date-filter__filter-label'
            onClick={this.openPicker}
            data-control-name='open-filter-data'
          >
            {isSelected ? 'Period' : 'All period'}
            <KreoIconFilterNormal />
          </div>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={this.closePicker}
            classes={{ paper: 'activity-date-filter__paper' }}
          >
            <div className='activity-date-filter__picker'>
              <div className='activity-date-filter__picker-info'>{this.getPickerInfo()}</div>
              <DateRange
                onChange={this.onChange}
                className={classNames('activity-date-filter__calendar', {
                  'default': !startEdit,
                })}
                ranges={[
                  {
                    startDate: start,
                    endDate: end,
                    key: intervalKey,
                  },
                ]}
                showDateDisplay={false}
                showMonthAndYearPickers={false}
              />
            </div>
          </Popover>
        </div>
        <SortingIcon type={FilterType.Date} sorting={sorting} setSorting={this.props.setSorting} />
      </div>
    );
  }

  @autobind
  private openPicker(e: React.MouseEvent<HTMLDivElement>): void {
    const { timeInterval: { start, end } } = this.props;
    this.setState({
      anchorEl: e.currentTarget,
      open: true,
      edited: false,
      start: start || new Date(),
      end: end || new Date(),
    });
  }

  @autobind
  private closePicker(): void {
    const { edited, start, end } = this.state;
    this.setState({ anchorEl: null, open: false, startEdit: false });
    if (edited) {
      this.props.setTimeInterval(start, end);
    }
  }

  @autobind
  private onChange(ranges: Array<{ startDate: Date, endDate: Date }>): void {
    const interval = ranges[intervalKey];
    this.setState({ start: interval.startDate, end: interval.endDate, edited: true, startEdit: true });
    this.props.setTimeInterval(interval.startDate, interval.endDate);
  }

  private getPickerInfo(): React.ReactNode {
    const { start, end, edited } = this.state;
    if (!edited || start == null) {
      return (
        <div>
          Pick <b>Start</b> Date
        </div>
      );
    }
    if (start != null && end === start) {
      return (
        <div>
          Pick <b>End</b> Date
        </div>
      );
    }
    return <div>{UserActivityUtil.displayInterval({ start, end })}</div>;
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    timeInterval: state.userActivities.timeInterval,
    sorting: state.userActivities.sorting,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setTimeInterval: (start, end) => {
      dispatch(ActivityActions.setTimeInterval(start, end));
    },
    setSorting: (type, direction) => {
      dispatch(ActivityActions.setSorting(type, direction));
    },
  };
};

export const DateFilter = connect(mapStateToProps, mapDispatchToProps)(DateFilterComponent);
