import { Canceler } from 'axios';
import { Action } from 'redux';

import { DownloadFileInfo } from 'common/interfaces/common-state';
import { ActionWith } from '../common/interfaces/action-with';
import { CommonActionTypes } from './common/action-types';
import {
  UpdateFileLoadingProgressPayload,
  UploadFileAddPayload,
  UploadFileFailedPayload,
  UploadFilePayload,
  UploadSuccessPayload,
} from './common/payloads';


export const uploadFiles = (
  files: File[],
  form: string,
  field: string,
): ActionWith<UploadFilePayload> => {
  return {
    type: CommonActionTypes.UPLOAD_FILES,
    payload: { files, form, field },
  };
};


export const addFilesForUpload = (uploads: UploadFileAddPayload[]): ActionWith<UploadFileAddPayload[]> => {
  return {
    type: CommonActionTypes.UPLOAD_FILES_ADD,
    payload: uploads,
  };
};


export const removeUploadFile = (key: string): ActionWith<string> => {
  return {
    type: CommonActionTypes.UPLOAD_FILE_REMOVE,
    payload: key,
  };
};

export const downloadFile = (name: string, canceler: Canceler): ActionWith<DownloadFileInfo> => {
  return {
    type: CommonActionTypes.DOWNLOAD_FILE,
    payload: { name, canceler },
  };
};

export const removeDownloadFileName = (): Action => {
  return {
    type: CommonActionTypes.REMOVE_DOWNLOAD_FILE_NAME,
  };
};

export const commonClearFiles = (): Action => {
  return {
    type: CommonActionTypes.REMOVE_ALL_FILES,
  };
};


export const getLocationsRequest = (): Action => {
  return {
    type: CommonActionTypes.GET_LOCATIONS,
  };
};


export const fileProgress = (key: string, progress: number): ActionWith<UpdateFileLoadingProgressPayload> => {
  return {
    type: CommonActionTypes.FILE_PROGRESS,
    payload: {
      key,
      progress,
    },
  };
};


export const uploadFileSuccessed = (key: string, result: string[]): ActionWith<UploadSuccessPayload> => {
  return {
    type: CommonActionTypes.UPLOAD_FILE_SUCCESSED,
    payload: {
      key,
      result,
    },
  };
};


export const uploadFileFailed = (key: string, error: string): ActionWith<UploadFileFailedPayload> => {
  return {
    type: CommonActionTypes.UPLOAD_FILE_FAILED,
    payload: { key, error },
  };
};


export const getLocationsSuccessed = (locations: Location[]): ActionWith<Location[]> => {
  return {
    type: CommonActionTypes.GET_LOCATIONS_SUCCESSED,
    payload: locations,
  };
};

export const setReportsDialogText = (text: string = ''): ActionWith<string> => {
  return {
    type: CommonActionTypes.SET_REPORTS_TEXT,
    payload: text,
  };
};

export const pushSearch = (payload: Record<string, string>): ActionWith<Record<string, string>> => {
  return {
    type: CommonActionTypes.PUSH_SEARCH,
    payload,
  };
};
