import React, { useContext } from 'react';
import { AbilityContext } from 'common/ability/ability-context';
import { AbilityAwareProps } from 'common/ability/with-ability-context';
import { UndoRedoContextApiProps } from 'common/undo-redo';
import {
  UndoRedoContext,
} from 'common/undo-redo/undo-redo-context';
import {
  DrawingsRendererApiContext,
  DrawingsRendererApiContextProps,
} from './drawings-contexts';
import { useDrawingControlsHotkeys } from './drawings-controls/hooks';
import { DrawingsLayoutApi, DrawingsLayoutApiSetter } from './drawings-layout-api-context';
import { useCallibrationEffect } from './hooks';
import { SnappingSettings, useSnappingSettings } from './hooks/settings';
import {
  DrawingSettings,
  UpdateDrawingSettings,
  useDrawingSettingsWithUpdate,
} from './hooks/settings/use-drawing-settings';
import { DrawModeContextProps, useDrawModeApi } from './hooks/use-draw-mode-api';

export interface DrawingsLayoutContextWrapperProps
  extends UndoRedoContextApiProps,
DrawingsRendererApiContextProps,
  AbilityAwareProps,
  DrawModeContextProps,
  DrawingSettings,
  UpdateDrawingSettings,
  SnappingSettings {
    sendApi: (api: DrawingsLayoutApi) => void;
}

export function withDrawingsLayoutContextWrapper<P>(
  Component: React.ComponentType<P & DrawingsLayoutContextWrapperProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawingsLayoutContextWrapperProps>>> {
  return (props: P) => {
    const undoRedoContext = useContext(UndoRedoContext);
    const rendererApi = useContext(DrawingsRendererApiContext);
    const ability = useContext(AbilityContext);
    const drawModeContext = useDrawModeApi();
    useDrawingControlsHotkeys();
    const settings = useDrawingSettingsWithUpdate();
    const snappings = useSnappingSettings();
    useCallibrationEffect(drawModeContext.drawMode);
    const sendApi = useContext(DrawingsLayoutApiSetter);
    return (
      <Component
        {...props}
        {...undoRedoContext}
        {...rendererApi}
        {...ability}
        {...drawModeContext}
        {...settings}
        {...snappings}
        sendApi={sendApi}
      />
    );
  };
}
