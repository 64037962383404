import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import './vertex.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { KreoIconAddPlus, KreoIconGroupingSmall, KreoIconInfoLetter, KreoIconMicroplanning } from 'common/UIKit';
import { Direction, GraphItemDisplayType } from '../../../../components/graph-viewer/enums';
import { VertexComponentProps } from '../../../../components/graph-viewer/interfaces';
import { EngineActions, MacroSequenceActions, StepActions } from '../../actions';
import { ActivityGroup, ActivityGroupingStepPage, UngroupedActivity } from '../../interfaces';
import { BimElementIdsHelper } from '../../utils';

interface ReduxActions {
  setHighlightedBimElements: (bimIds: number[]) => void;
  setActiveActivityGroupOrUngroupedActivityById: (id: number) => void;
  setEdgeCreatorRootVertexAndDirection: (id: number, direction: Direction) => void;
  setHighlightedGraphItemId: (id: number | null) => void;
  jumpToActivityGrouping: () => void;
}

interface ReduxProps {
  activeActivityGroupId: number;
  activityGroups: Record<number, ActivityGroup>;
  ungroupedActivities: Record<number, UngroupedActivity>;
  ungroupedActivityGroupIdToId: Record<number, number>;
}

interface Props extends VertexComponentProps, ReduxProps, ReduxActions {
}

const getClassName = (displayType: GraphItemDisplayType): string => {
  return `macro-sequence-vertex macro-sequence-vertex--${displayType}`;
};

export class VertexComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const props = this.props;
    const name = this.getGroupName();

    return  (
      <div
        className={getClassName(props.displayType)}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onVertexClick}
      >
      <div className='macro-sequence-vertex__edge-dot macro-sequence-vertex__edge-dot-left'/>
      <div className='macro-sequence-vertex__edge-dot macro-sequence-vertex__edge-dot-right'/>
      {
        props.displayType === GraphItemDisplayType.Active && !props.readonly ? (
          <React.Fragment>
            <div
              className='macro-sequence-vertex__add-limitation-button macro-sequence-vertex__add-successor'
              onClick={this.onAddSuccessorClick}
            >
              <KreoIconAddPlus />
              <div className='macro-sequence-vertex__icon-tooltip'>
                <span className='macro-sequence-vertex__icon-tooltip-item'>Create “Finish-to-Start” Link</span>
              </div>
            </div>
            <div
              className='macro-sequence-vertex__add-limitation-button macro-sequence-vertex__add-predecessor'
              onClick={this.onAddPredecessorClick}
            >
              <KreoIconAddPlus />
              <div className='macro-sequence-vertex__icon-tooltip'>
                <span className='macro-sequence-vertex__icon-tooltip-item'>Create “Finish-to-Start” Link</span>
              </div>
            </div>
          </React.Fragment>
        ) : null
      }
        <div className='macro-sequence-vertex__name' title={name}>{name}</div>
        {props.displayType === GraphItemDisplayType.Active ?
          <div className='macro-sequence-vertex__icons-wrap'>
            <div className='macro-sequence-vertex__icon'>
              <KreoIconMicroplanning />
              <div className='macro-sequence-vertex__icon-tooltip'>
                <span className='macro-sequence-vertex__icon-tooltip-item'>Microplanning</span>
              </div>
            </div>
            <div className='macro-sequence-vertex__icon'  onClick={props.jumpToActivityGrouping}>
              <KreoIconGroupingSmall />
              <div className='macro-sequence-vertex__icon-tooltip'>
                <span className='macro-sequence-vertex__icon-tooltip-item'>Activities Grouping</span>
              </div>
            </div>
            <div className='macro-sequence-vertex__icon'>
              <KreoIconInfoLetter />
              <div className='macro-sequence-vertex__icon-tooltip'>
                <span className='macro-sequence-vertex__icon-tooltip-item'>Frame In-situ concrete</span>
                <span
                  className='macro-sequence-vertex__icon-tooltip-item macro-sequence-vertex__icon-tooltip-item--sub'
                >
                  Column
                </span>
              </div>
            </div>
          </div>
          : null}
      </div>
    );
  }

  private getGroupName(): string {
    let name;
    const group = this.getActivityGroup();
    if (group) {
      name = group.name;
    } else {
      const activity = this.getUngroupedActivity();
      name = activity ? activity.name : 'ERROR';
    }
    return name;
  }

  @autobind
  private onMouseOver(): void {
    const bimIds = BimElementIdsHelper.getActivityGroupBimIds(this.props.id);
    this.props.setHighlightedBimElements(bimIds);
    this.props.setHighlightedGraphItemId(this.props.id);
  }

  @autobind
  private onMouseLeave(): void {
    this.props.setHighlightedBimElements([]);
    this.props.setHighlightedGraphItemId(null);
  }

  @autobind
  private onVertexClick(): void {
    if (!this.props.readonly) {
      this.props.setActiveActivityGroupOrUngroupedActivityById(this.props.id);
    }
  }

  @autobind
  private onAddSuccessorClick(): void {
    this.props.setEdgeCreatorRootVertexAndDirection(this.props.id, Direction.Forward);
  }

  @autobind
  private onAddPredecessorClick(): void {
    this.props.setEdgeCreatorRootVertexAndDirection(this.props.id, Direction.Back);
  }

  private getActivityGroup(): ActivityGroup {
    return this.props.activityGroups[this.props.id];
  }

  private getUngroupedActivity(): UngroupedActivity {
    return this.props.ungroupedActivities[this.props.ungroupedActivityGroupIdToId[this.props.id]];
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    activeActivityGroupId: state.activityGrouping.activeActivityGroupId,
    activityGroups: state.activityGrouping.activityGroups,
    ungroupedActivities: state.activityGrouping.ungroupedActivities,
    ungroupedActivityGroupIdToId: state.activityGrouping.ungroupedActivityGroupIdToId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setHighlightedBimElements: ids =>
      dispatch(EngineActions.setHighlightedBimElements(ids)),
    setActiveActivityGroupOrUngroupedActivityById: id =>
      dispatch(MacroSequenceActions.setActiveActivityGroupOrUngroupedActivityById(id)),
    setEdgeCreatorRootVertexAndDirection: (id, direction) =>
      dispatch(MacroSequenceActions.setEdgeCreatorRootVertexAndDirection(id, direction)),
    setHighlightedGraphItemId: id => dispatch(MacroSequenceActions.setHighlightedGraphItemId(id)),
    jumpToActivityGrouping: () =>
      dispatch(StepActions.setActivityGroupingStepPage(ActivityGroupingStepPage.ActivityGrouping)),
  };
};


const connector = connect(mapStateToProps, mapDispatchToProps);
export const Vertex = connector(VertexComponent);
