import styled from 'styled-components';
import { PromptStyled } from 'unit-2d-copilot/prompts-list';

const Container = styled.div`
  margin-top: 10px;
`;

const Message = styled.div`
  display: flex;
  align-items: end;
`;

const MessageText = styled.div`
  padding: 10px 15px;
  background-color: ${p => p.theme.color.backgroundRush};
  margin-left: 15px;
  border-radius: 20px 20px 20px 5px;
`;

const Prompts = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 45px;
  gap: 10px;
  flex-wrap: wrap;

  ${PromptStyled.PromptContainer} {
    border-radius: 9px;
    height: 18px;
    padding: 2px 5px;
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const Styled = {
  Container,
  Message,
  MessageText,
  Prompts,
};
