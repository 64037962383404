import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { ResourceType } from '../../../../utils/gantt-chart';
import { FourDVisualisationState } from '../../interfaces/four-d-visualisation-state';
import { payloads, types } from './actions';

export const resourceSelectorReducerMethods: ReducerMethods<FourDVisualisationState> = {
  [types.OPEN_POPUP]: (
    state,
    payload: payloads.OpenPopupPayload,
  ) => {
    const stateModifier = new MonoliteHelper(state);
    const chartState = state.sidePanel.resourcesTab.charts[payload.chartId];
    const resource = chartState ? chartState.selectedResource : null;
    return stateModifier
      .set((s) => s.sidePanel.resourcesTab.resourceSelector.chartId, payload.chartId)
      .set((s) => s.sidePanel.resourcesTab.resourceSelector.clientX, payload.clientX)
      .set((s) => s.sidePanel.resourcesTab.resourceSelector.clientY, payload.clientY)
      .set((s) => s.sidePanel.resourcesTab.resourceSelector.isOpen, true)
      .set((s) => s.sidePanel.resourcesTab.resourceSelector.selectedTab, resource ? resource.type : ResourceType.Labour)
      .get();
  },
  [types.SELECT_RESOURCE]: (
    state,
    payload: payloads.ResourceActionPayload,
  ) => {
    const stateModifier = new MonoliteHelper(state);

    const chartState = state.sidePanel.resourcesTab[payload.chartId];
    if (chartState) {
      stateModifier.set((s) => s.sidePanel.resourcesTab.charts[payload.chartId].selectedResource, payload.resource);
    } else {
      stateModifier.set((s) => s.sidePanel.resourcesTab.charts[payload.chartId], {
        selectedResource: payload.resource,
        previewedResource: null,
      });
    }

    return stateModifier.get();
  },
  [types.SET_TAB]: (
    state,
    payload: ResourceType,
  ) => {
    const stateModifier = new MonoliteHelper(state);
    return stateModifier
      .set((s) => s.sidePanel.resourcesTab.resourceSelector.selectedTab, payload)
      .get();
  },
  [types.CLOSE_POPUP]: (
    state,
  ) => {
    const stateModifier = new MonoliteHelper(state);
    return stateModifier
      .set((s) => s.sidePanel.resourcesTab.resourceSelector.chartId, null)
      .set((s) => s.sidePanel.resourcesTab.resourceSelector.clientX, 0)
      .set((s) => s.sidePanel.resourcesTab.resourceSelector.clientY, 0)
      .set((s) => s.sidePanel.resourcesTab.resourceSelector.isOpen, false)
      .get();
  },
  [types.PREVIEW_RESOURCE]: (
    state,
    payload: payloads.ResourceActionPayload,
  ) => {
    const stateModifier = new MonoliteHelper(state);
    const chartState = state.sidePanel.resourcesTab.charts[payload.chartId];
    if (!chartState) {
      stateModifier.set((s) => s.sidePanel.resourcesTab.charts[payload.chartId], {
        selectedResource: null,
        previewedResource: null,
      });
    }

    return stateModifier
      .set((s) => s.sidePanel.resourcesTab.charts[payload.chartId].previewedResource, payload.resource)
      .set((s) => s.timelineChart.previewedResource, payload.resource)
      .get();
  },
  [types.RESET_RESOURCE_PREVIEW]: (state) => {
    const stateModifier = new MonoliteHelper(state);
    const chartIds = Object.keys(state.sidePanel.resourcesTab.charts).map((id) => parseInt(id, 10));

    for (const id of chartIds) {
      stateModifier.set((s) => s.sidePanel.resourcesTab.charts[id].previewedResource, null);
    }

    stateModifier.set((s) => s.timelineChart.previewedResource, null);

    return stateModifier.get();
  },
};
