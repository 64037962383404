/* Так как работа с сылками на ячейки должна быть частью самой таблицы, создал класс,
  который будет за этим следить в рамках таблицы */

export class ReferenceHelper {
  /* Функция, которая сетит значение куда напишут в функции */
  private setInTarget: (value: string) => void;
  /* Проверка на валидность действия вставки, необходима
  так как могут быть замешаны факторы со страницы, о которых самой таблице знать не надо */
  private isCanUpdate: () => boolean;

  /* В один момент времени можно сетить только в одину цель,
  каждый раз при инициализации цели или каких-то действия в результате,
  может понадобиться сетить значение нужно вызвать этот метод.
  Один раз зарегистрировать все обработчики не получиться, так как ячейка при переходе в рендер создаёт новый объект
  в который нужно передавать значение
  */
  public setConfig(setInTarget: (value: string) => void, isCanUpdate: () => boolean): void {
    this.setInTarget = setInTarget;
    this.isCanUpdate = isCanUpdate;
  }

  /* Обновление цели, этот метод используется в дефолтном рендере ячейки */
  public updateTarget(value: string): boolean {
    if (!this.setInTarget || !this.isCanUpdate || !this.isCanUpdate()) {
      return false;
    }

    this.setInTarget(value);
    return true;
  }
}
