import styled from 'styled-components';
import { InputSmallStyled } from 'common/components/inputs';

const ValueContainer = styled.div`
  ${InputSmallStyled.InputStyle} {
    background-color: ${p => p.theme.color.pale};
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;


export const Styled = {
  ValueContainer,
};
