import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import produce from 'immer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid';

import { ColorsName } from 'common/enums/kreo-colors';
import { Mood } from 'common/enums/mood';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { EMAIL } from '../../../constants/regex-templates';
import { TempInvitePeople } from '../../../units/subscription/store/interface';
import { LinkComponent } from '../link-component';
import { InviteField } from './invite-field';
import { Styled } from './styled';


const DUPLICATES_EMAILS_DIALOG = 'duplicatesEmailsDialog';
const DUPLICATES_YOUR_EMAIL_DIALOG = 'duplicatesYourEmailDialog';

interface FormsData {
  id: string;
  email: string;
  role: string;
}

interface Props {
  onSubmit: (formsData: FormsData[]) => void;
  roles?: string[];
  userLimit?: number;
}

export const InvitePeopleForm = React.memo<Props>(({ userLimit, roles, onSubmit }) => {
  const dispatch = useDispatch();
  const userEmail = useSelector<State, string>(state => state.account.email);
  const invitedPeople = useSelector<State, TempInvitePeople[]>(state => state.subscription.tempInvitePeople);
  const [formsData, setFormsData] = useState<FormsData[]>([]);
  const [duplicatesEmail, setDuplicatesEmail] = useState<string>('');
  const [dialogKey, setDialogKey] = useState<string>(DUPLICATES_EMAILS_DIALOG);
  const canAddUser = useMemo(() => !userLimit || userLimit > formsData.length, [formsData.length, userLimit]);
  const optionsDialog = useMemo(() => ({
    [DUPLICATES_EMAILS_DIALOG]: {
      name: DUPLICATES_EMAILS_DIALOG,
      title: `You have entered duplicated email`,
      text: `You've entered email "${duplicatesEmail}"  twice in the list of teammates.
        Press "Cancel" if you want to enter other teammates email.
        Press "Continue" to invite all teammates without duplication.`,
    },
    [DUPLICATES_YOUR_EMAIL_DIALOG]: {
      name: DUPLICATES_YOUR_EMAIL_DIALOG,
      title: `You have entered your email`,
      text: `You've entered your email "${userEmail}" in the list of teammates.
        Press "Cancel" if you want to enter your teammates email. Press "Continue" to invite all other teammates.`,
    },
  }), [duplicatesEmail, userEmail]);

  const cleanForm = useCallback(() => {
    return { id: uuid.v4(), email: undefined, role: roles[roles.length - 1] };
  }, [roles]);

  useEffect(() => {
    if (invitedPeople && invitedPeople.length) {
      setFormsData(invitedPeople);
    } else {
      setFormsData([
        cleanForm(),
        cleanForm(),
        cleanForm(),
      ]);
    }
  }, [cleanForm, invitedPeople]);

  const moreClick = useCallback(() => {
    setFormsData([...formsData, cleanForm()]);
  }, [cleanForm, formsData]);

  const onEmailChange = useCallback((index, value) => {
    const newFormsData = produce(formsData, (s) => {s[index].email = value;});
    setFormsData(newFormsData);
  }, [formsData]);

  const onRoleChange = useCallback((index: number, roleIndex: number) => {
    const newRole = roles[roleIndex];
    const newFormsData = produce(formsData, (s) => {s[index].role = newRole;});
    setFormsData(newFormsData);
  }, [formsData, roles]);

  const deleteForm = useCallback((index) => {
    if (formsData.length === 1 && formsData[0].email === '') {
      return;
    }
    if (index === 0 && formsData.length === 1) {
      onEmailChange(index, '');
    } else {
      setFormsData(formsData.filter((_form, i) => i !== index));
    }
  }, [formsData, onEmailChange]);

  const validateEmail = useCallback((email: string): boolean => {
    if (email) {
      return EMAIL.test(email);
    } else {
      return true;
    }
  }, []);

  const handleSubmit = useCallback(() => {
    const isInvalidEmail = formsData.find(form => !validateEmail(form.email));
    if (isInvalidEmail) {
      return;
    }

    if (onSubmit) {
      if (dialogKey === DUPLICATES_YOUR_EMAIL_DIALOG) {
        const newFormsData = formsData.filter(form => form.email !== userEmail && !!form.email);
        onSubmit(newFormsData);
      } else {
        onSubmit(formsData.filter(d => !!d.email));
      }
    }
  }, [dialogKey, formsData, onSubmit, userEmail, validateEmail]);

  const onHandleInviteUsers = useCallback(() => {
    const emails = arrayUtils.filterMap(
      formsData,
      (form) => form.email !== undefined && form.email !== '',
      (form) => form.email,
    );
    const duplicatesEmails = emails.filter((email, index) => emails.indexOf(email) !== index);
    const isDuplicatesYourEmail = emails.includes(userEmail);

    if (duplicatesEmails.length) {
      setDuplicatesEmail(duplicatesEmails[0]);
      setDialogKey(DUPLICATES_EMAILS_DIALOG);
      dispatch(KreoDialogActions.openDialog(DUPLICATES_EMAILS_DIALOG));
    } else if (isDuplicatesYourEmail) {
      setDialogKey(DUPLICATES_YOUR_EMAIL_DIALOG);
      dispatch(KreoDialogActions.openDialog(DUPLICATES_YOUR_EMAIL_DIALOG));
    } else {
      handleSubmit();
    }
  }, [dispatch, formsData, handleSubmit, userEmail]);

  const handleSkip = useCallback(() => {
    if (onSubmit) {
      if (invitedPeople && invitedPeople.length) {
        onSubmit(invitedPeople);
      } else {
        onSubmit(null);
      }
    }
  }, [invitedPeople, onSubmit]);

  return (
    <>
      <Styled.Container canAddUser={canAddUser}>
        {formsData.map((formData, index) =>
          (<InviteField
            key={formData.id}
            roles={roles}
            activeElementIndex={roles.indexOf(formData.role)}
            value={formData.email}
            index={index}
            onRoleChange={onRoleChange}
            deleteField={deleteForm}
            onEmailChange={onEmailChange}
          />),
        )}
        {canAddUser && (
          <LinkComponent
            text={'Add email address'}
            icon={Icons.PlusBig}
            onClick={moreClick}
          />
        )}
        <Styled.ContinueButtonContainer>
          <RectangleButton
            mood={Mood.Default}
            text={'Skip'}
            fontSize={14}
            height={40}
            textColor={ColorsName.mainFont}
            onClick={handleSkip}
          />
          <RectangleButton
            mood={Mood.Secondary}
            text={'Invite team members'}
            fontSize={14}
            height={40}
            onClick={onHandleInviteUsers}
          />
        </Styled.ContinueButtonContainer>
      </Styled.Container>
      <ConfirmationDialog
        name={optionsDialog[dialogKey].name}
        title={optionsDialog[dialogKey].title}
        text={optionsDialog[dialogKey].text}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Continue'}
        submitButtonMood={Mood.Positive}
        onConfirm={handleSubmit}
      />
    </>
  );
},
);
