import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { Project } from 'unit-projects/interfaces/project';
import { DrawingsActions } from '../actions/creators/common';
import { AutoMeasureActionTypes } from '../actions/types/auto-measure';
import { DrawingsApi } from '../api/drawings-api';
import { AutoMeasurePageSetting } from '../enums/auto-measure-page-setting';
import { AutoMeasureOption } from '../interfaces';
import { DrawingsState } from '../interfaces/drawings-state';
import { reloadAfterRecognition } from '../saga-utils/reload-after-recognition';

function selectAutoMeasureSettings(state: DrawingsState): AutoMeasureOption[] {
  const { availableOptions, selectedOptions } = state.autoMeasureSettings;
  return selectedOptions.map(x => availableOptions[x]);
}

function* startAutoMeasureCalculation({ payload }: ActionWith<AutoMeasurePageSetting>): SagaIterator {
  try {
    const [drawingsState, project] =
      (yield selectWrapper(s => [s.drawings, s.projects.currentProject])) as [DrawingsState, Project];
    const { currentDrawingInfo, drawingsInfo } = drawingsState;
    const selectedOptions = selectAutoMeasureSettings(drawingsState);
    let pageIds = [];
    if (payload === AutoMeasurePageSetting.All) {
      for (const drawingId of Object.keys(drawingsInfo)) {
        yield put(DrawingsActions.setStatusDrawingRecognition(drawingId, ViewModelStatus.Calculating));
      }
      yield call(DrawingsApi.startAutoMeasureCalculation, selectedOptions);
      pageIds = Object.keys(drawingsInfo);
    } else {
      yield put(DrawingsActions.setStatusDrawingRecognition(currentDrawingInfo.drawingId, ViewModelStatus.Calculating));
      yield call(DrawingsApi.startAutoMeasureCalculation,
        selectedOptions,
        currentDrawingInfo.pdfId,
        currentDrawingInfo.drawingId,
      );
      pageIds = [currentDrawingInfo.drawingId];
    }
    for (const id of pageIds) {
      const { recognitionStatus, pdfId } = drawingsInfo[id];
      if (recognitionStatus === ViewModelStatus.Ready) {
        yield call(reloadAfterRecognition, project.id, pdfId, id);
        yield put(DrawingsActions.setStatusDrawingRecognition(id, ViewModelStatus.Ready));
      } else {
        yield put(DrawingsActions.setStatusDrawingRecognition(id, ViewModelStatus.Calculating));
      }
    }
  } catch (e) {
    console.error('Error: startAutoMeasureCalculation', payload, e);
  }
}

export function* autoMeasureSaga(): SagaIterator {
  yield takeLatest(AutoMeasureActionTypes.START_CALCULATION, startAutoMeasureCalculation);
}
