import { ColDef, ColGroupDef, ColumnState } from 'ag-grid-community';
import { isAdditionalProperties } from './is-additional-properties';

const updateRowGroup = (colDefs: Array<ColGroupDef | ColDef>): void => {
  for (const colDef of colDefs) {
    if ((colDef as ColGroupDef).children) {
      updateRowGroup((colDef as ColGroupDef).children);
    }
    if (Number.isInteger((colDef as ColDef).rowGroupIndex)) {
      (colDef as ColDef).rowGroupIndex += 1;
    }
  }
};

const updateOldColDef = (col: ColGroupDef | ColDef, colStates: ColumnState[]): void => {
  if ((col as ColGroupDef).children) {
    (col as ColGroupDef).children.forEach((c) => updateOldColDef(c, colStates));
  }

  const colState = (col as ColDef).field && colStates.find(c => c.colId === (col as ColDef).field);
  if (colState) {
    (col as ColDef).rowGroup = colState.rowGroupIndex !== null;
    (col as ColDef).rowGroupIndex = colState.rowGroupIndex;
    (col as ColDef).hide = colState.hide;
  }
};

const updateNewColDef = (
  col: ColGroupDef | ColDef,
  colStates: ColumnState[],
  resultColDefs: Array<ColGroupDef | ColDef>,
): void => {
  if ((col as ColGroupDef).children) {
    (col as ColGroupDef).children.forEach((c) => updateNewColDef(c, colStates, resultColDefs));
  }

  const colState = (col as ColDef).field && colStates.find(c => c.colId === (col as ColDef).field);
  if (!colState) {
    (col as ColDef).hide = false;
    if ((col as ColDef).field && isAdditionalProperties((col as ColDef).field)) {
      updateRowGroup(resultColDefs);
      (col as ColDef).rowGroup = true;
      (col as ColDef).hide = true;
      (col as ColDef).rowGroupIndex = 0;
    }
  }
};


// Нужно обновлять colGroup, но colDef не должны меняться
const addNewColumn = (
  resultColDefs: Array<ColGroupDef | ColDef>,
  colDefs: Array<ColGroupDef | ColDef>,
): void => {
  for (let groupIndex = 0; groupIndex < colDefs.length; groupIndex++) {
    const group = resultColDefs[groupIndex] as ColGroupDef;
    if ((colDefs[groupIndex] as ColGroupDef).children) {
      (colDefs[groupIndex] as ColGroupDef).children.forEach((newColumn: ColDef) => {
        const c = group.children.find((oldColumn: ColDef) => oldColumn.field === newColumn.field);
        if (!c) {
          resultColDefs[groupIndex] = { ...group, children: [...group.children] };
          (resultColDefs[groupIndex] as ColGroupDef).children.push(newColumn);
        }
      });
    }
  }
};

export const syncColDefWithColState = (
  colDefs: Array<ColGroupDef | ColDef>, // тут новые колонки
  colStates: ColumnState[], // тут актуальное состояние для старых
  prevColumnDefs: Array<ColGroupDef | ColDef>, // тут ссылки на старые колонки иначе ag-grid всё не так сделает
): Array<ColGroupDef | ColDef> => {
  const resultColDefs: Array<ColGroupDef | ColDef> = [];

  prevColumnDefs.forEach(col => {
    updateOldColDef(col, colStates);
    resultColDefs.push(col);
  });

  if (colDefs) {
    colDefs.forEach(colDef => {
      updateNewColDef(colDef, colStates, resultColDefs);
    });
    addNewColumn(resultColDefs, colDefs);
  }

  return resultColDefs;
};
