import * as React from 'react';
import { connect } from 'react-redux';

import './measurements-extractors-header-item.scss';

import { State } from 'common/interfaces/state';
import { KreoIconInfoBigColor } from 'common/UIKit';
import { UnitUtil } from 'common/utils/unit-util';
import { HelpTooltip } from 'components/controls/tooltips';
import { CEMeasurementsPercentageExtractorType, CEMeasurementsValueId } from 'unit-cost-estimate/constants';
import { CEMeasurementExtractorFunction } from 'unit-cost-estimate/interfaces';
import {
  MeasurementsExtractorsHeaderItemLayout,
} from 'unit-projects/components/measurements-extractors-header-item-layout';
import {
  MeasurementGeneralExtractorEditor,
} from 'unit-projects/interfaces/measurements/measurement-general-extractor-editor';


interface OwnProps {
  id: string;
}

interface StateProps {
  activityNames: string[];
  generalFunctionId: string;
  generalExtractorEditors: Record<string, MeasurementGeneralExtractorEditor>;
  extractors: CEMeasurementExtractorFunction[];
}

interface Props extends OwnProps, StateProps {
}

class MeasurementsExtractorsHeaderItemComponent extends React.PureComponent<Props> {
  private readonly valuesKeysWithHiddenTotal: CEMeasurementsValueId[] = [
    CEMeasurementsValueId.ReinforcementConcentration,
  ];

  public render(): React.ReactNode {
    return (
      <MeasurementsExtractorsHeaderItemLayout>
        <div className='measurements-extractors-header-item__tooltip-icon'>
          <HelpTooltip
            id='measurements-extractors-header-item__tooltip'
            icon={<KreoIconInfoBigColor />}
            place='bottom'
            className='measurements-extractors-header-item__tooltip'
            text={this.renderTooltip()}
          />
        </div>
        <div
          className='measurements-extractors-header-item__name'
          title={this.props.generalFunctionId}
        >
          <p>{this.props.generalFunctionId}</p>
        </div>
        <div className='measurements-extractors-header-item__totals'>
          {this.renderTotals()}
        </div>
      </MeasurementsExtractorsHeaderItemLayout>
    );
  }

  private renderTotals(): React.ReactNode {
    const { generalExtractorEditors, extractors } = this.props;
    return extractors.map(extractor => {
      const totalMustBeHidden = this.isTotalShouldBeHidden(extractor.extractorFunctionId);
      const totalValue = generalExtractorEditors[extractor.extractorFunctionId].totalValue;
      const value = totalMustBeHidden
        ? ''
        : UnitUtil.round(totalValue, extractor.unitName).toString();
      const title = `${value} ${extractor.unitName}`;
      return (
        <p
          key={extractor.extractorFunctionId || 'undefined'}
          className='measurements-extractors-header-item__volume'
          title={title}
        >
          {value} <span>{extractor.unitName}</span>
        </p>
      );
    });
  }

  private renderTooltip(): JSX.Element {
    return (
      <React.Fragment>
        <p>
          <b>ACTIVITIES</b>
        </p>
        {this.props.activityNames.map((activityName, index) => {
          return <p key={index}>{activityName}</p>;
        })}
      </React.Fragment>
    );
  }

  private isTotalShouldBeHidden(extractorFunctionId: string): boolean {
    return this.valuesKeysWithHiddenTotal.includes(extractorFunctionId as CEMeasurementsValueId)
      || extractorFunctionId.endsWith(CEMeasurementsPercentageExtractorType.PrimaryPercentage)
      || extractorFunctionId.endsWith(CEMeasurementsPercentageExtractorType.SecondaryPercentage);
  }
}


function mapStateToProps({ ceMeasurements }: State, { id }: OwnProps): StateProps {
  const { activityNames, generalFunctionId, extractors } = ceMeasurements.extractorFunctions[id];
  return {
    generalExtractorEditors: ceMeasurements.generalExtractorEditors,
    activityNames,
    generalFunctionId,
    extractors,
  };
}


const connector = connect(mapStateToProps);
export const MeasurementsExtractorsHeaderItem =
  connector(MeasurementsExtractorsHeaderItemComponent);
