import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State } from 'common/interfaces/state';
import { ClassificationChart } from 'components/charts';
import { ObjectStatisticType } from 'components/charts/interfaces/object-statistic-type';
import { AppUrls } from 'routes/app-urls';
import { CEMeasurementsActions } from 'unit-cost-estimate/actions/creators';
import { CostEstimateValidationStep } from 'unit-cost-estimate/interfaces';
import { isCostEstimateValidationStepGreaterThan } from 'unit-cost-estimate/utils/validation-step';
import { ValidationStep } from 'unit-projects/enums/validation-step';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { StepWrapper, StepWrapperEngineApi } from '../step-wrapper';


interface DispatchProps {
  approveMeasurements: () => void;
  disapproveMeasurements: () => void;
  dropStatistic: () => void;
  loadStatistic: () => void;
}

interface StateProps {
  isReady: boolean;
  isApprovable: boolean;
  canDisapprove: boolean;
  isEditable: boolean;
  statistic: ObjectStatisticType[];
  loaded: boolean;
  errors: number[];
}

interface OwnProps extends AbilityAwareProps {
  projectId: number;
  revisionId: number;
}

interface Props extends StateProps, DispatchProps, OwnProps { }

class MeasurementsComponent extends React.Component<Props> {
  private stepEngineApi: StepWrapperEngineApi = null;

  public componentDidUpdate(): void {
    const { errors, loaded, statistic, isReady } = this.props;
    if (loaded) {
      const hasError = errors.length > 0;
      if (this.stepEngineApi && hasError) {
        this.stepEngineApi.paintEngine([], errors);
      }
    }

    if (this.stepEngineApi && (!statistic || statistic.length === 0)) {
      this.stepEngineApi.clearEngine();
    }

    if (isReady && !loaded) {
      this.props.loadStatistic();
    }
  }

  public componentDidMount(): void {
    const { isReady } = this.props;

    if (isReady) {
      this.props.loadStatistic();
    }
  }

  public render(): React.ReactNode {
    const { ability, isApprovable, canDisapprove, approveMeasurements, disapproveMeasurements } = this.props;
    const urlParams = {
      projectId: this.props.projectId.toString(),
      revisionId: this.props.revisionId.toString(),
    };

    const onApprove = isApprovable ? approveMeasurements : null;
    const onDisapprove = canDisapprove ? disapproveMeasurements : null;

    return (
      <StepWrapper
        projectId={this.props.projectId}
        onApprove={onApprove}
        onDisapprove={onDisapprove}
        onEngineApiReady={this.initStepEngine}
      >
       <div className='classification-step__header'>
          <h3>Measurements</h3>
        </div>
        <div className='validation-step-content__inner-wrap'>
          <ClassificationChart
            viewLink={AppUrls.costEstimate.project.revision.measurements.view.url(urlParams)}
            fixLink={AppUrls.costEstimate.project.revision.measurements.edit.url(urlParams)}
            isFixable={this.props.isApprovable && ability.can(Operation.Update, Subject.ValidationMeasurements)}
            isLoading={!this.props.loaded || !this.props.statistic || this.props.statistic.length === 0}
            data={this.props.statistic}
            onSelectIds={this.isolate}
            noDataMessage='No calculation yet'
            onDeselect={this.showAll}
            chartName={ValidationStep.Measurements}
            attentionStatus={this.hasUncertainStatistic()}
          />
        </div>
      </StepWrapper>
    );
  }

  @autobind
  private initStepEngine(api: StepWrapperEngineApi): void {
    this.stepEngineApi = api;
    this.stepEngineApi.clearEngine();
    this.stepEngineApi.paintEngine([], this.props.errors);
  }

  @autobind
  private hasUncertainStatistic(): boolean {
    const uncertainStatistic = this.props.statistic.find(s => s.name === 'Uncertain');
    return uncertainStatistic && uncertainStatistic.amount > 0;
  }

  @autobind
  private isolate(ids: number[]): void {
    if (this.stepEngineApi) {
      this.stepEngineApi.isolate(ids);
    }
  }

  @autobind
  private showAll(): void {
    if (this.stepEngineApi) {
      this.stepEngineApi.showAll();
    }
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const revisionState = state.projects.revisionStates[ownProps.revisionId];

  const isMeasurementStep = revisionState && revisionState.validationStep === CostEstimateValidationStep.Measurements;
  const canUpdate = ownProps.ability.can(Operation.Update, Subject.ValidationMeasurements);
  const canApprove = ownProps.ability.can(Operation.Approve, Subject.ValidationMeasurements);

  const isReady = revisionState && revisionState.measurementsStatus === ViewModelStatus.Ready;
  const isEditable = isReady && canUpdate;

  const isStepPassed = revisionState && isCostEstimateValidationStepGreaterThan(
    revisionState.validationStep, CostEstimateValidationStep.Measurements,
  );

  const isApprovable = canApprove && isMeasurementStep && isReady;
  const canDisapprove = canApprove && isStepPassed;

  return {
    isReady,
    canDisapprove,
    isApprovable,
    isEditable,
    statistic: state.ceMeasurements.statistic,
    loaded: state.ceMeasurements.statisticLoaded,
    errors: state.ceMeasurements.badIds,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    approveMeasurements: () => dispatch(CEMeasurementsActions.approve()),
    disapproveMeasurements: () => dispatch(CEMeasurementsActions.disapprove()),
    loadStatistic: () => dispatch(CEMeasurementsActions.getStatisticRequest(null)),
    dropStatistic: () => dispatch(CEMeasurementsActions.dropStatistic()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const Measurements = withAbilityContext(connector(MeasurementsComponent));
