import React, { useMemo } from 'react';

import { usePersistedStorageValueToggle } from 'persisted-storage/hooks';
import { DrawingsBooleanOperation } from '../drawings-geometry/drawings-helpers';
import { useApplyOperation, useBooleanKeyboardListener, useCancelBoolean } from './hooks';
import { Styled } from './styled';
import { UnionDialog } from './union-dialog';

interface Props {
  sendRef: (ref: HTMLDivElement, isBooleanMenu?: boolean) => void;
}

export const DrawingsUniteDialogWrapper: React.FC<Props> = ({
  sendRef,
}) => {
  const [ shouldKeepOld, toggleKeepOld ] = usePersistedStorageValueToggle('drawingIsKeepPolygon');
  const applySettings = useMemo(() => ({
    keepOld: shouldKeepOld,
  }), [shouldKeepOld]);
  const apply = useApplyOperation(DrawingsBooleanOperation.Unite, applySettings);
  const cancel = useCancelBoolean();
  useBooleanKeyboardListener(apply, cancel);

  return (
    <Styled.Container ref={sendRef}>
      <UnionDialog
        toggleKeepPolygons={toggleKeepOld}
        isKeepPolygons={shouldKeepOld}
        apply={apply}
        cancel={cancel}
      />
    </Styled.Container>
  );
};

export const DrawingsUniteDialog = React.memo(DrawingsUniteDialogWrapper);
