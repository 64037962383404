import { ElementTooltip, Icons, SearchListItem, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { ItemData } from './interfaces';
import { Styled } from './styled';

export interface TabInfo extends ItemData {
  isActive: boolean;
}

interface Props {
  itemData: TabInfo;
  tooltipPosition: string;
  defaultColor: string;
}

export class TabsMenuItem extends React.Component<Props> {
  public render(): JSX.Element {
    const { itemData, tooltipPosition, defaultColor } = this.props;
    return (
      <ElementTooltip
        position={tooltipPosition}
        speed={'m'}
        text={itemData.name}
        disabled={itemData.name.length < (itemData.isActive ? 16 : 20)}
        wordBreakAll={true}
      >
        <SearchListItem>
          <Styled.TabsMenuItem
            color={itemData.color || defaultColor}
          >
            <Icons.CircleFilled />
            <Styled.TabsMenuItemText
              isActive={itemData.isActive}
            >
              <Text
                fontSize={14}
                withEllipsis={true}
                color={itemData.isActive ? 'turquoise' : 'mainFont'}
              >
                {itemData.name}
              </Text>
            </Styled.TabsMenuItemText>
            {itemData.isActive && <Icons.Apply />}
          </Styled.TabsMenuItem>
        </SearchListItem>
      </ElementTooltip>
    );
  }
}
