import { IconButton, Icons } from '@kreo/kreo-ui-components';

import React from 'react';
import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { RenderIf } from 'common/components/render-if';
import { ConstantFunctions } from 'common/constants/functions';
import { Styled } from './styled';

interface Props {
  onCollapse: () => void;
  onExpand: () => void;
  isSelectPanelOpen?: boolean;
  openSelectPanel?: () => void;
}

export const VisibillityControlPanel = React.memo<Props>(
  ({
    onCollapse,
    onExpand,
    openSelectPanel,
    isSelectPanelOpen,
  }) => {
    const canManage = useAbility(Operation.Manage, Subject.Takeoff2dPiaAssignment);
    const showDatabaseButton = !!openSelectPanel;

    return (
      <Styled.ControlPanelContainer withDataBase={showDatabaseButton}>
        <RenderIf condition={showDatabaseButton}>
          <UpgradeWrapper isNeedUpdate={!canManage}>
            <IconButton
              Icon={Icons.DatabaseSmall}
              width={20}
              height={30}
              onClick={canManage ? openSelectPanel : ConstantFunctions.doNothing}
              defaultColor={isSelectPanelOpen && 'turquoise'}
            />
          </UpgradeWrapper>
        </RenderIf>
        <Styled.IconButtonsContainer>
          <IconButton
            Icon={Icons.ExpandAllSmall}
            onClick={onExpand}
          />
          <IconButton
            Icon={Icons.CollapseAllSmall}
            onClick={onCollapse}
          />
        </Styled.IconButtonsContainer>
      </Styled.ControlPanelContainer>
    );
  },
);
