import React from 'react';


import { DialogPosition } from '../hooks';
import { Content } from './content';
import { NewStickerContent } from './new-sticker-content';

interface Props {
  position: DialogPosition;
  text: string;
  creator?: string;
  createdAt?: string | Date;
  isTemp: boolean;
  canEdit: boolean;
  remove: () => void;
  onClose: () => void;
  setStickerText: (text: string) => void;
}

const StickerDialogComponent: React.FC<Props> = ({
  isTemp,
  text,
  createdAt,
  creator,
  canEdit,
  position,
  remove,
  onClose,
  setStickerText,
}) => {
  return isTemp ? (
    <NewStickerContent
      onClose={onClose}
      position={position}
      createComment={setStickerText}
      remove={remove}
    />
  ) : (
    <Content
      remove={remove}
      canEdit={canEdit}
      text={text}
      createdAt={createdAt}
      creator={creator}
      changeText={setStickerText}
      position={position}
      onClose={onClose}
    />
  );
};

export const StickerDialog = React.memo(StickerDialogComponent);
