import { IconButton, Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { CommentCountMark } from 'unit-2d-comments/comment-count-mark';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { TwoDCommentsActions } from '../store-slice';
import { Styled } from './styled';

interface StateProps {
  showBadge: boolean;
  openedCount: number;
}
interface DispatchProps {
  onOpen: () => void;
}

interface Props extends StateProps, DispatchProps {

}

const CommentsButtonComponent: React.FC<Props> = ({ onOpen, showBadge, openedCount }) => {
  const canShowComment = useAbility(Operation.Manage, Subject.Comment2d);
  const { sendEvent } = useAnalytics();
  const onHandleClick = useCallback(() => {
    if (canShowComment) {
      onOpen();
      sendEvent(MetricNames.comments.openComments, { target: 'button' });
    } else {
      sendEvent(MetricNames.comments.tryOpenCommentsWhithoutAccess);
    }
  }, [canShowComment]);

  return (
    <>
      <Styled.Container>
        <UpgradeWrapper isNeedUpdate={!canShowComment}>
          <IconButton
            Icon={Icons.Comments2D}
            width={20}
            height={20}
            onClick={onHandleClick}
            mood={canShowComment ? 'default' : 'disabled'}
          />
        </UpgradeWrapper>
        <RenderIf condition={showBadge}>
          <Styled.Badge/>
        </RenderIf>
        <CommentCountMark count={openedCount}/>
      </Styled.Container>
    </>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    showBadge: state.twoDComments.showBadge,
    openedCount: state.twoDComments.openedCommentsCount,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onOpen: () => dispatch(TwoDCommentsActions.changeOpenPanelStatus(true)),
  };
}

export const CommentsButton = connect(mapStateToProps, mapDispatchToProps)(CommentsButtonComponent);
