import { H6, ItemMenuStyled, AvatarStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const CONTENT_PADDING_TOP_PROJECT = 20;
const CONTENT_PADDING_TOP_TEMPLATE = 20;
const CONTENT_PADDING_BOTTOM = 120;
const CONTENT_PADDING_RIGHT_LEFT = 20;
const PROJECT_HEADER_HEIGHT = 40;

const Container = styled.div`
  position: relative;
  height: 100%;
  background-color: ${props => props.theme.color.backgroundRush};
  border-radius: 15px 0 0 15px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const WrapperProjects = styled.div<{ isProjectType?: boolean }>`
  overflow: scroll;
  padding: ${p => p.isProjectType ? CONTENT_PADDING_TOP_PROJECT : CONTENT_PADDING_TOP_TEMPLATE}px 
    ${CONTENT_PADDING_RIGHT_LEFT}px ${CONTENT_PADDING_BOTTOM}px ${CONTENT_PADDING_RIGHT_LEFT}px;
  height: 100%;

  ::-webkit-scrollbar-corner {
    background-color: ${p => p.theme.color.backgroundRush};
  }
`;

const ProjectsHeader = styled.div`
  display: flex;
  align-items: center;
  height: ${PROJECT_HEADER_HEIGHT}px;
  justify-content: space-between;
`;

const CountTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${H6} {
    line-height: 1px;
  }
`;

const FilterAndViewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Main = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 120px);
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainGrid = styled.div<{ isLoading?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
  grid-gap: 20px 20px;
  margin-top: 15px;

  ${ItemMenuStyled.Menu} {
    z-index: 101;
  }

  ${AvatarStyled.Avatar} {
    border: 4px solid ${p => p.theme.color.background};
  }

  > button {
    position: absolute;
    bottom: -50px;
    height: 30px;
    width: 100%;
    margin: auto;
    pointer-events: ${props => props.isLoading ? 'none' : 'all'};
  }

  div {
    > a {
      > div:hover {
        box-shadow: 0 0 0 2px ${p => p.theme.color.turquoise};
      }
    }
  }
`;

export const Styled = {
  Container,
  WrapperProjects,
  Main,
  MainGrid,
  ProjectsHeader,
  CountTitleWrapper,
  FilterAndViewWrapper,
  Placeholder,
};
