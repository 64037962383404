import {
  LaborModel,
  LaborVariantModel,
  MaterialModel,
  MaterialVariantModel,
  PlantModel,
  PlantVariantModel,
} from '../interfaces/resources-data';
import {
  LaborForm,
  LaborVariantForm,
  MaterialForm,
  MaterialVariantForm,
  PlantForm,
  PlantVariantForm,
} from '../interfaces/resources-rest-data';


export class ResourcesRestFormMapper {
  public static GetLaborForm(labor: LaborModel): LaborForm {
    return {
      name: labor.name,
      variants: labor.variants.map(ResourcesRestFormMapper.GetLaborVariantForm),
    };
  }

  public static GetLaborVariantForm(laborVariant: LaborVariantModel): LaborVariantForm {
    return {
      id: laborVariant.id,
      name: laborVariant.name,
      cost: laborVariant.cost,
    };
  }

  public static GetPlantForm(plant: PlantModel): PlantForm {
    return {
      name: plant.name,
      variants: plant.variants.map(ResourcesRestFormMapper.GetPlantVariantForm),
    };
  }

  public static GetPlantVariantForm(plantVariant: PlantVariantModel): PlantVariantForm {
    return {
      id: plantVariant.id,
      isMainContractor: plantVariant.isMainContractor,
      name: plantVariant.name,
      cost: plantVariant.cost,
      rentTime: plantVariant.rentTime,
      shiftHours: plantVariant.shiftHours,
    };
  }

  public static GetMaterialForm(material: MaterialModel): MaterialForm {
    return {
      defaultUnitId: material.defaultUnitId,
      name: material.name,
      variants: material.variants.map(ResourcesRestFormMapper.GetMaterialVariantForm),
    };
  }

  public static GetMaterialVariantForm(materialVariant: MaterialVariantModel): MaterialVariantForm {
    return {
      id: materialVariant.id,
      amount: materialVariant.amount,
      cost: materialVariant.cost,
      name: materialVariant.name,
      unitId: materialVariant.unitId,
    };
  }
}
