import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import './file-indicator.scss';

import { UploadingFile } from 'common/interfaces/common-state';
import { KreoIconCloseSmall } from 'common/UIKit';
import { Common } from '../../../../actions';

interface ReduxActions {
  removeUploadFile: (key: string) => void;
}

interface Props extends ReduxActions {
  file: UploadingFile;
  index: number;
  removeFromFields: (file: UploadingFile, index: number) => void;
}

class FileIndicatorComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { file } = this.props;
    const progress = `${Math.round(file.progress * 100)}%`;
    return (
      <div className={classNames('file-indicator', { error: !!file.error })}>
        <span className='file-indicator__name'>
          {file.file.name}
        </span>
        <b className='file-indicator__progress-wrap'>
          <span className='file-indicator__progress-percent'>{progress}</span>
          <div className='file-indicator__progress-bar'>
            <b className='file-indicator__progress-fill' style={{ width: progress }} />
          </div>
        </b>
        <u className='file-indicator__error-text'>{file.error}</u>
        <i className='file-indicator__icon-close' onClick={this.onRemove}>
          <KreoIconCloseSmall />
        </i>
      </div>
    );
  }

  @autobind
  private onRemove(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    this.props.removeUploadFile(this.props.file.key);
    this.props.removeFromFields(this.props.file, this.props.index);
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>): ReduxActions {
  return {
    removeUploadFile: key => {
      dispatch(Common.removeUploadFile(key));
    },
  };
}

export const FileIndicator = connect(null, mapDispatchToProps)(FileIndicatorComponent);
