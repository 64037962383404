import styled, { css } from 'styled-components';
import {
  DrawingsSubmenuContainer,
} from 'common/components/drawings/drawings-canvas-menus/drawings-flying-menu/submenu-containers';
import { MenuItemStyled } from 'common/components/menu-item';

const OpenedStyle = css`
  background-color: ${p => p.theme.color.turquoise};
  svg {
    transform: rotate(180deg) translateY(-5px);
    fill: ${p => p.theme.color.white};
  }
  &:hover {
    background-color: ${p => p.theme.color.turquoise};
    svg {
      transform: rotate(180deg) translateY(-5px);
    }
  }
`;

const MenuButton = styled.div<{ opened?: boolean }>`
  margin-left: 2px;
  width: 16px;
  height: 30px;
  box-sizing: border-box;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
  transition: all ${p => p.theme.duration.s} linear;
  background-color: unset;
  svg {
    height: 8px;
    width: 8px;
    transition: all ${p => p.theme.duration.s} linear;
    fill: ${p => p.theme.color.gray};
    transform: translateY(0px);
  }
  &:hover {
    background-color: ${p => p.theme.color.pale};
    svg {
      margin-bottom: 0;
      transform: translateY(5px);
    }
  }
  ${p => p.opened ? OpenedStyle : null};
`;

const active = css`
  div {
    border-radius: 15px;
    background-color: ${p => p.theme.color.turquoise};
    ${MenuItemStyled.IconWithText} {
      p {
        color: ${p => p.theme.color.white};
      }
    }
    svg {
      fill: ${p => p.theme.color.white};
      color: ${p => p.theme.color.white};
    }
    &:hover {
      background-color: ${p => p.theme.color.turquoise};
      ${MenuItemStyled.IconWithText} {
        p {
          color: ${p => p.theme.color.white}!important;
        }
      }
      ${MenuItemStyled.SubText} {
        p {
          color: ${p => p.theme.color.disabled}!important;
        }
      }
      svg {
        fill: ${p => p.theme.color.white};
        color: ${p => p.theme.color.white};
      }
    }
  }
`;

const Subvariant = styled.div<{ active: boolean }>`
  div {
    background-color: unset;
    &:hover {
      border-radius: 15px;
    }
  }
  ${p => p.active && active};
`;

const Container = styled(DrawingsSubmenuContainer)`
  border-radius: 20px;
  border: unset;
  overflow: visible;
  :first-child {
    padding-top: 0px;
  }

  :last-child {
    padding-bottom: 0px;
  }
`;

const MenuContainerStyle = css`
  overflow: visible;
`;

export const Styled = {
  MenuButton,
  Subvariant,
  Container,
  MenuContainerStyle,
};
