import * as Ag from 'ag-grid-community';
import { DataBaseTableRowData } from '../interfaces';
import { getNameValue } from './get-name-value';

export const getTableNodeData = (params: Ag.GetContextMenuItemsParams): DataBaseTableRowData => {
  const data = params.node.data;
  if (data) {
    return { id: data.id, isGroup: data.h_isDragTarget };
  } else if (params.node.group) {
    return { id: params.node.groupData['ag-Grid-AutoColumn'], isGroup: true };
  } else {
    return { id: getNameValue(params.value), isGroup: false };
  }
};
