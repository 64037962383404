import * as React from 'react';

export const KreoIconHome: React.FC = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/24x24/Home_Normal'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path
          // eslint-disable-next-line max-len
          d='M5.5,18.5 L9.5,18.5 C10.0522847,18.5 10.5,18.0522847 10.5,17.5 L10.5,16.5 C10.5,15.9477153 10.9477153,15.5 11.5,15.5 L12.5,15.5 C13.0522847,15.5 13.5,15.9477153 13.5,16.5 L13.5,17.5 C13.5,18.0522847 13.9477153,18.5 14.5,18.5 L18.5,18.5 C19.0522847,18.5 19.5,18.0522847 19.5,17.5 L19.5,9.50673835 C19.5,9.18819481 19.3482388,8.88870843 19.0913637,8.70033335 L12.5913637,3.93366669 C12.2393727,3.67554001 11.7606273,3.67554001 11.4086363,3.93366669 L4.90863634,8.70033335 C4.65176123,8.88870843 4.5,9.18819481 4.5,9.50673835 L4.5,17.5 C4.5,18.0522847 4.94771525,18.5 5.5,18.5 Z'
          stroke='currentColor'
        />
      </g>
    </svg>
  );
};
