import { cloneDeep, maxBy, pull, without } from 'lodash';

import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { WorkPackagesActionTypes } from '../actions';
import { WorkPackagesData, WorkPackagesPayload, WorkpackagesReduxState } from '../interfaces';

export const WorkpackagesReducerMethods: ReducerMethods<WorkpackagesReduxState> = {
  [WorkPackagesActionTypes.LOAD_DATA]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.packages, RequestStatus.Loading)
      .get();
  },
  [WorkPackagesActionTypes.LOAD_SUCCESSED]: (state, payload: WorkPackagesData.WorkPackage[]) => {
    return new MonoliteHelper(state)
      .set((_) => _.packages, payload)
      .set((_) => _.statuses.packages, RequestStatus.Loaded)
      .get();
  },
  [WorkPackagesActionTypes.SET_ACTIVITIES]: (state, payload) => {
    return new MonoliteHelper(state)
      .set((_) => _.activities, payload)
      .get();
  },
  [WorkPackagesActionTypes.RENAME_PACKAGE]: (state, payload) => {
    const helper = new MonoliteHelper(state);
    const index = state.groupedPackages.findIndex((pack) => pack.id === payload.id);

    if (index >= 0) {
      helper.set((_) => _.groupedPackages[index].name, payload.name);
    }

    return helper.get();
  },
  [WorkPackagesActionTypes.CREATE_NEW]: (state) => {
    const newPackage = {
      id: state.iter,
      name: 'Work Package New',
      activitiesGroups: [],
      count: 0,
    };

    return new MonoliteHelper(state)
      .set((_) => _.iter, state.iter + 1)
      .set((_) => _.groupedPackages, [...state.groupedPackages, newPackage])
      .get();
  },
  [WorkPackagesActionTypes.SET_GROUPED]: (state, payload: WorkPackagesData.GropedWorkPackage[]) => {
    const groupWithMaxId = maxBy(payload, (grouped): number => grouped.id);
    return new MonoliteHelper(state)
      .set((_) => _.groupedPackages, payload)
      .set((_) => _.iter, groupWithMaxId ? groupWithMaxId.id + 1 : 1)
      .set((_) => _.activePackage, payload && payload.length > 0 ? payload[0] : null)
      .get();
  },
  [WorkPackagesActionTypes.SET_ACTIVE]: (state, payload: WorkPackagesData.GropedWorkPackage) => {
    return new MonoliteHelper(state)
      .set((_) => _.activePackage, payload)
      .set((_) => _.activeGroup, null)
      .set((_) => _.selectedGroup, [])
      .set((_) => _.isAllGroupSelected, false)
      .get();
  },
  [WorkPackagesActionTypes.SELECT_GROUP]: (state, payload) => {
    const isExist = state.selectedGroup.includes(payload);
    const selectedGroup = isExist
      ? without(state.selectedGroup, payload)
      : [...state.selectedGroup, payload];
    const isAllSelected = state.activePackage.activitiesGroups.length === selectedGroup.length;

    return new MonoliteHelper(state)
      .set((_) => _.isAllGroupSelected, isAllSelected)
      .set((_) => _.selectedGroup, selectedGroup)
      .get();
  },
  [WorkPackagesActionTypes.SELECT_ALL_GROUP]: (state, payload) => {
    const activePackage = state.activePackage;
    const selectedGroup = payload
      ? activePackage.activitiesGroups.map((group) => group.idGroup)
      : [];

    return new MonoliteHelper(state)
      .set((_) => _.isAllGroupSelected, payload)
      .set((_) => _.selectedGroup, selectedGroup)
      .get();
  },
  [WorkPackagesActionTypes.SET_ACTIVE_GROUP]: (state, payload) => {
    return new MonoliteHelper(state)
      .set((_) => _.activeGroup, payload)
      .set((_) => _.selectedActivities, [])
      .set((_) => _.isAllActivitySelected, false)
      .set((_) => _.subgroupScrollPosition, null)
      .get();
  },
  [WorkPackagesActionTypes.SET_ACTIVE_WORKPACKAGE_BY_ID]: (state, payload) => {
    const packId = payload;
    const activePack = state.groupedPackages.find((pack) => pack.id === packId);

    return new MonoliteHelper(state)
      .set((_) => _.activePackage, activePack)
      .set((_) => _.activeGroup, null)
      .set((_) => _.subgroupScrollPosition, null)
      .set((_) => _.selectedGroup, [])
      .set((_) => _.selectedActivities, [])
      .set((_) => _.isAllGroupSelected, false)
      .set((_) => _.isAllActivitySelected, false)
      .get();
  },
  [WorkPackagesActionTypes.SELECT_GROUP_ACTIVITY]: (state, payload: number) => {
    const isExist = state.selectedActivities.includes(payload);
    const selectedActivities = isExist
      ? without(state.selectedActivities, payload)
      : [...state.selectedActivities, payload];
    const isAllSelected = state.activeGroup.activities.length === selectedActivities.length;

    return new MonoliteHelper(state)
      .set((_) => _.isAllActivitySelected, isAllSelected)
      .set((_) => _.selectedActivities, selectedActivities)
      .get();
  },
  [WorkPackagesActionTypes.SELECT_ALL_GROUP_ACTIVITY]: (state, payload) => {
    const selectedActivities = payload
      ? state.activeGroup.activities.map((activity) => activity.id)
      : [];

    return new MonoliteHelper(state)
      .set((_) => _.isAllActivitySelected, payload)
      .set((_) => _.selectedActivities, selectedActivities)
      .get();
  },
  [WorkPackagesActionTypes.REPLACE_GROUP]: (state, payload) => {
    const helper = new MonoliteHelper(state);
    const idPack = payload.idPack || payload.idPack === 0
      ? payload.idPack
      : state.iter - 1;

    const groupedPackages = state.groupedPackages;
    const activePackage =
      cloneDeep(groupedPackages.find((gp) => gp.id === state.activePackage.id));
    const newPackage =
      cloneDeep(groupedPackages.find((gp) => gp.id === idPack));

    const idGroups = !payload.idGroup || state.selectedGroup.includes(payload.idGroup)
      ? state.selectedGroup
      : [payload.idGroup, ...state.selectedGroup];

    const activities = cloneDeep(state.activities);

    if (activePackage.id !== idPack) {
      idGroups.forEach((idGroup: number) => {
        const group = activePackage.activitiesGroups.find((gr) => gr.idGroup === idGroup);
        pull(activePackage.activitiesGroups, group);
        activePackage.count -= group.count;

        const existedGroup = newPackage.activitiesGroups.find((g) => g.nameGroup === group.nameGroup);
        if (existedGroup) {
          existedGroup.activities = [...existedGroup.activities, ...group.activities];
          existedGroup.count += group.count;
        } else {
          newPackage.activitiesGroups.push(group);
        }
        newPackage.count += group.count;

        group.activities.forEach((activity): void => activity.workpackageid = idPack);
        const activityIds = group.activities.map((activity) => activity.id);
        activities.forEach((activity) => {
          if (activityIds.includes(activity.id)) {
            activity.workpackageid = idPack;
          }
        });
      });

      const indexOfActivePack = state.groupedPackages.findIndex((gp) => gp.id === activePackage.id);
      const indexOfNewPack = state.groupedPackages.findIndex((gp) => gp.id === newPackage.id);

      helper.set((_) => _.activePackage, activePackage)
        .set((_) => _.groupedPackages[indexOfActivePack], activePackage)
        .set((_) => _.groupedPackages[indexOfNewPack], newPackage)
        .set((_) => _.activities, activities)
        .set((_) => _.selectedGroup, [])
        .set((_) => _.isAllGroupSelected, false);
    }

    return helper.get();
  },
  [WorkPackagesActionTypes.REPLACE_ACTIVITY]: (state, payload: WorkPackagesPayload.ReplaceActivityPayload) => {
    const helper = new MonoliteHelper(state);

    const activityId = payload.activityId;
    const packageId = Number.isInteger(payload.packageId) ? payload.packageId : state.iter - 1;

    const groupedPackages = state.groupedPackages;
    const activePackage = cloneDeep(state.activePackage);
    const activeGroup = activePackage.activitiesGroups.find(g => g.idGroup === state.activeGroup.idGroup);
    const newPackage = cloneDeep(groupedPackages.find(gp => gp.id === packageId));
    let newGroup = newPackage.activitiesGroups.find(ag => ag.nameGroup === activeGroup.nameGroup);

    if (!newGroup) {
      let maxId = 0;
      for (const gp of groupedPackages) {
        for (const ag of gp.activitiesGroups) {
          if (maxId < ag.idGroup) {
            maxId = ag.idGroup;
          }
        }
      }

      newGroup = {
        idGroup: maxId + 1,
        nameGroup: activeGroup.nameGroup,
        count: 0,
        activities: [],
      };

      newPackage.activitiesGroups.push(newGroup);
    }

    const selectedActivities = state.selectedActivities;
    const activities = state.activities;
    const activitiesForReplace = new Set(selectedActivities);
    if (!activitiesForReplace.has(activityId)) {
      activitiesForReplace.add(activityId);
    }
    if (activePackage.id !== packageId) {
      const activeGroupActivities =  new Array<WorkPackagesData.Activity>();
      for (const activity of activeGroup.activities) {
        if (!activitiesForReplace.has(activity.id)) {
          activeGroupActivities.push(activity);
          continue;
        }
        activeGroup.count--;
        activePackage.count--;
        newGroup.activities.push(activity);
        newGroup.count++;
        newPackage.count++;

        activity.workpackageid = newPackage.id;
        const activityIndex = activities.findIndex(a => a.id === activity.id);
        helper.set(_ => _.activities[activityIndex].workpackageid, newPackage.id);
      }
      activeGroup.activities = activeGroupActivities;
      if (!activeGroup.count) {
        const activeGroupIndex =
          activePackage.activitiesGroups.findIndex(ag => ag.idGroup === activeGroup.idGroup);
        activePackage.activitiesGroups.splice(activeGroupIndex, 1);
      }

      const indexOfActivePack = state.groupedPackages.findIndex(gp => gp.id === activePackage.id);
      const indexOfNewPack = state.groupedPackages.findIndex(gp => gp.id === newPackage.id);

      helper.set((_) => _.activePackage, activePackage)
        .set((_) => _.groupedPackages[indexOfActivePack], activePackage)
        .set((_) => _.groupedPackages[indexOfNewPack], newPackage)
        .set((_) => _.activeGroup.count, activeGroup.count)
        .set((_) => _.activities, activities)
        .set(_ => _.activeGroup.activities, activeGroupActivities)
        .set((_) => _.selectedActivities, [])
        .set((_) => _.isAllActivitySelected, false);

    }

    return helper.get();
  },
  [WorkPackagesActionTypes.REMOVE_WORKPACKAGE]: (state, payload: number) => {
    const helper = new MonoliteHelper(state);
    if (state.activePackage && state.activePackage.id === payload) {
      helper
        .set((_) => _.activePackage, null)
        .set((_) => _.activeGroup, null)
        .set((_) => _.selectedGroup, [])
        .set((_) => _.isAllGroupSelected, false);
    }
    helper.setFilter((_) => _.groupedPackages, (x) => x.id !== payload);

    return helper.get();
  },
  [WorkPackagesActionTypes.SELECT_ACTIVITY]: (state, activity: WorkPackagesData.Activity) => {
    const workPackage = state.groupedPackages.find(({ id }) => id === activity.workpackageid);
    let group: WorkPackagesData.GroupOfActivity;
    let scrollPosition: number;
    for (const activityGroup of workPackage.activitiesGroups) {
      const index = activityGroup.activities.findIndex(({ id }) => id === activity.id);
      if (index !== -1) {
        group = activityGroup;
        scrollPosition = index;
        break;
      }
    }
    return new MonoliteHelper(state)
      .set((_) => _.activePackage, workPackage)
      .set((_) => _.activeGroup, group)
      .set((_) => _.selectedGroup, [])
      .set((_) => _.selectedActivities, [activity.id])
      .set((_) => _.isAllGroupSelected, false)
      .set((_) => _.isAllActivitySelected, group.activities.length === 1)
      .set((_) => _.subgroupScrollPosition, scrollPosition)
      .get();
  },
};
