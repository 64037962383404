import React from 'react';

import { Text } from '../text';
import { RatingStars } from './rating-stars';
import { Styled } from './styled';

interface Props {
  title: string;
  onSelectRating: (rating: number) => void;
}

const RatingContentComponent: React.FC<Props> = ({
  title,
  onSelectRating,
}) => {
  return (
    <Styled.ContentContainer>
      <Styled.MessageContentTitle>
        <Text
          fontSize={14}
          lineHeight={16}
        >
          {title}
        </Text>
      </Styled.MessageContentTitle>
      <RatingStars onSelectRating={onSelectRating} />
    </Styled.ContentContainer>
  );
};


export const RatingContent = React.memo(RatingContentComponent);
