import styled from 'styled-components';

const CONTENT_PADDING_TOP = 20;
const CONTENT_PADDING_RIGHT_LEFT = 20;
const NAVIGATION_HEIGHT = 40;
const TABLE_HEADER = 60;

const Container = styled.div`
  overflow: auto;
  padding: ${CONTENT_PADDING_TOP}px ${CONTENT_PADDING_RIGHT_LEFT}px 0 ${CONTENT_PADDING_RIGHT_LEFT}px;
  height: 100%;
  box-sizing: border-box;
`;

const Main = styled.div`
  position: relative;
  height: calc(100% - ${NAVIGATION_HEIGHT + TABLE_HEADER}px);
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Navigation = styled.div`
  height: ${NAVIGATION_HEIGHT}px;

  > div {
    width: 400px;
    display: flex;
    justify-content: space-between;

    div:first-child {
      width: 120px;
    }
    div:not(:first-child) {
      height: 20px;
      width: 120px;
      justify-content: center;
    }
  }
`;

export const Styled = {
  Container,
  Main,
  NavigationContainer,
  Navigation,
};
