import { objectUtils } from 'common/utils/object-utils';
import { CompanyResourcesApi, RevisionResourcesApi } from '../../../api/server';
import {
  CreateQtoTreeRowsForm,
  ModelType,
  QtoCopyReportResult,
  QtoCopyReportResultC,
  QtoProjectReport,
  QtoProjectReportArrayC,
  QtoReport,
  QtoReportC,
  QtoReportForm,
  QtoReportInfo,
  QtoReportInfoArrayC,
  QtoReportInfoC,
  QtoReportInfoForm,
  QtoReportSetting,
  QtoReportSettingArrayC,
  QtoTemplateInfo,
  QtoTemplateInfoC,
  QtoTreeColumnsForm,
  QtoTreeRowForm,
  ReorderQtoTreeRowsForm,
} from '../interfaces/quantity-take-off';


function createReport(projectId: number, modelType: ModelType, model: QtoReportForm): Promise<QtoReportInfo> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.postV(`/${modelType}/reports`, model, QtoReportInfoC);
  }
  return CompanyResourcesApi.postV(`/projects/${projectId}/${modelType}/reports`, model, QtoReportInfoC);
}

function getReports(projectId: number, modelType: ModelType): Promise<QtoReportInfo[]> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.getV(`/${modelType}/reports`, QtoReportInfoArrayC);
  }
  return CompanyResourcesApi.getV(`/projects/${projectId}/${modelType}/reports`, QtoReportInfoArrayC);
}

function getReport(projectId: number, modelType: ModelType, reportId: number): Promise<QtoReport> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.getV(`/${modelType}/reports/${reportId}`, QtoReportC);
  }
  return CompanyResourcesApi.getV(`/projects/${projectId}/${modelType}/reports/${reportId}`, QtoReportC);
}

function publishReport(_projectId: number, modelType: ModelType, reportId: number): Promise<void> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.post(`/${modelType}/reports/${reportId}/publish`, null);
  }
}

function deleteReport(projectId: number, modelType: ModelType, reportId: number): void {
  if (modelType === ModelType.CostEstimate) {
    RevisionResourcesApi.delete(`/${modelType}/reports/${reportId}`, null);
  }
  CompanyResourcesApi.delete(`/projects/${projectId}/${modelType}/reports/${reportId}`, null);
}

function updateReportInfo(
  projectId: number, modelType: ModelType, reportId: number, form: QtoReportInfoForm,
): Promise<QtoReportInfo> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.putV(`/${modelType}/reports/${reportId}`, form, QtoReportInfoC);
  }

  return CompanyResourcesApi.putV(
    `/projects/${projectId}/${modelType}/reports/${reportId}`,
    form,
    QtoReportInfoC,
  );
}

function addRows(
  projectId: number, modelType: ModelType, reportId: number, rows: CreateQtoTreeRowsForm,
): Promise<QtoReport> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.postV(`/${modelType}/reports/${reportId}/rows`, rows, QtoReportC);
  }

  return CompanyResourcesApi.postV(
    `/projects/${projectId}/${modelType}/reports/${reportId}/rows`,
    rows,
    QtoReportC,
  );
}

function updateRows(projectId: number, modelType: ModelType, reportId: number, rows: QtoTreeRowForm[]): Promise<void> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.put(`/${modelType}/reports/${reportId}/rows`, rows);
  }
  return CompanyResourcesApi.put(`/projects/${projectId}/${modelType}/reports/${reportId}/rows`, rows);
}

function deleteRows(projectId: number, modelType: ModelType, reportId: number, ids: string[]): Promise<void> {
  const idsParam = objectUtils.toQueryString({ id: ids });
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.delete(`/${modelType}/reports/${reportId}/rows?${idsParam}`, null);
  }

  return CompanyResourcesApi.delete(
    `/projects/${projectId}/${modelType}/reports/${reportId}/rows?${idsParam}`,
    null,
  );
}

function reorderRows(
  projectId: number, modelType: ModelType, reportId: number, model: ReorderQtoTreeRowsForm,
): Promise<void> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.put(`/${modelType}/reports/${reportId}/reorder`, model);
  }
  return CompanyResourcesApi.put(`/projects/${projectId}/${modelType}/reports/${reportId}/reorder`, model);
}


function updateBasicColumns(
  projectId: number, modelType: ModelType, reportId: number, columns: QtoTreeColumnsForm,
): Promise<void> {
  if (modelType === ModelType.PublishedReports) {
    return null;
  }
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.put(`/${modelType}/reports/${reportId}/columns`, columns);
  }
  return CompanyResourcesApi.put(`/projects/${projectId}/${modelType}/reports/${reportId}/columns`, columns);
}

function updatePivotColumns(
  projectId: number, modelType: ModelType, reportId: number, columns: QtoTreeColumnsForm,
): Promise<void> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.put(`/${modelType}/reports/${reportId}/pivot-columns`, columns);
  }
  return CompanyResourcesApi.put(`/projects/${projectId}/${modelType}/reports/${reportId}/pivot-columns`, columns);
}

function generateDefaultReport(projectId: number, modelType: ModelType, type: string): Promise<QtoReportInfo> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.post(`/${modelType}/reports/generate?type=${type}`, QtoReportInfoC);
  }

  return CompanyResourcesApi.post(
    `/projects/${projectId}/${modelType}/reports/generate?type=${type}`,
    QtoReportInfoC,
  );
}

function exportReportToExcel(projectId: number, modelType: ModelType, reportId: number): void {
  if (modelType === ModelType.CostEstimate) {
    RevisionResourcesApi.downloadFile(`/${modelType}/reports/${reportId}/excel?/`);
  } else {
    CompanyResourcesApi.downloadFile(`/projects/${projectId}/${modelType}/reports/${reportId}/excel?/`);
  }
}

function getAvailableReportGenerators(projectId: number, modelType: ModelType): Promise<QtoReportSetting[]> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.getV(`/${modelType}/reports/available-generators`, QtoReportSettingArrayC);
  }

  return CompanyResourcesApi.getV(
    `/projects/${projectId}/${modelType}/reports/available-generators`,
    QtoReportSettingArrayC,
  );
}

function getAvailableReportForCopy(modelType: ModelType): Promise<QtoProjectReport[]> {
  if (modelType === ModelType.QuantityTakeOff) {
    return CompanyResourcesApi.getV(`/${modelType}/reports`, QtoProjectReportArrayC);
  }

  return new Promise(() => []);
}

function copyReport(
  projectId: number, modelType: ModelType, reportId: number, currentProjectId: number,
): Promise<QtoCopyReportResult> {
  const form = { SourceProjectId: projectId, SourceReportId: reportId };
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.postV(`/${modelType}/reports/copy`, form, QtoCopyReportResultC);
  }

  return CompanyResourcesApi.postV(
    `/projects/${currentProjectId}/${modelType}/reports/copy`,
    form,
    QtoCopyReportResultC,
  );
}

function createTemplateFromReport(projectId: number, modelType: ModelType, reportId: number): Promise<QtoTemplateInfo> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.postV(`/${modelType}/reports/${reportId}/templates`, null, QtoTemplateInfoC);
  }

  return CompanyResourcesApi.postV(
    `/projects/${projectId}/${modelType}/reports/${reportId}/templates`,
    null,
    QtoTemplateInfoC,
  );
}


export const QuantityTakeOffReportApi = {
  createReport,
  getReport,
  getReports,
  updateReportInfo,
  deleteReport,
  copyReport,
  publishReport,
  createTemplateFromReport,

  addRows,
  updateRows,
  reorderRows,
  deleteRows,

  updateBasicColumns,
  updatePivotColumns,

  exportReportToExcel,
  generateDefaultReport,
  getAvailableReportGenerators,
  getAvailableReportForCopy,
};
