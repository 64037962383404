import React from 'react';

type UseHandleDeleteProjectTemplate = (
  handleDelete: (
    projectId: number,
    templateId: number,
  ) => void,
  projectId: number,
) => (id: string) => void;

export const useHandleDeleteProjectTemplate: UseHandleDeleteProjectTemplate = (handleDelete, projectId) => {
  return React.useCallback((templateId) => {
    handleDelete(projectId, Number(templateId));
  },
  [handleDelete, projectId]);
};
