import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { ActivityGroupingActionTypes } from '../actions/types/activity-grouping';
import { ActivityGroupData, ActivityGroupsReduxState } from '../interfaces';

const replaceActivity = (
  helper: MonoliteHelper<ActivityGroupsReduxState>,
  state: ActivityGroupsReduxState,
  activityId: number, activityGroupId: number,
): void => {
  if (state.activities[activityId]) {
    helper
      .set((_) => _.activities[activityId].activityGroupId, activityGroupId)
      .setFilter(
        (_) => _.activityGroups[state.activities[activityId].activityGroupId].activityIds,
        id => id !== activityId);
  } else {
    const ungroupedActivity = state.ungroupedActivities[activityId];
    const newActivity = {
      id: ungroupedActivity.id,
      name: ungroupedActivity.name,
      engineIds: ungroupedActivity.engineIds,
      activityGroupId,
    };

    helper
      .set((_) => _.activities[activityId], newActivity)
      .setFilter(
        (_) => _.workPackages[ungroupedActivity.workPackageId].ungroupedIds,
        groupId => groupId !== ungroupedActivity.activityGroupId)
      .removeKey((_) => _.ungroupedActivities, activityId)
      .removeKey((_) => _.ungroupedActivityGroupIdToId, ungroupedActivity.activityGroupId);
  }
};

export const ActivityGroupsReducerMethods: ReducerMethods<ActivityGroupsReduxState> = {
  [ActivityGroupingActionTypes.CREATE_ACTIVITY_GROUP]: (state, categoryId: number) => {
    const id = state.activityGrouping.availableIds.activityGroup;
    const workPackageId = state.categories[categoryId].workPackageId;
    const activityGroup: ActivityGroupData.ActivityGroup = {
      workPackageId,
      categoryId,
      id,
      name: 'New Activity Group',
      workSpaceProcessing: true,
      activityIds: [],
    };

    return new MonoliteHelper(state)
      .set((_) => _.activityGrouping.availableIds.activityGroup, activityGroup.id + 1)
      .set((_) => _.activityGroups[id], activityGroup)
      .setAppend((_) => _.categories[categoryId].activityGroupIds, id)
      .setAppend((_) => _.workPackages[workPackageId].activityGroupIds, id)
      .setAppend((_) => _.activityGrouping.changes.activityGroups, activityGroup)
      .set((_) => _.activeCategoryId, activityGroup.categoryId)
      .set((_) => _.activeActivityGroupId, activityGroup.id)
      .get();
  },
  [ActivityGroupingActionTypes.CREATE_CATEGORY]: (state, workPackageId: number) => {
    const id = state.activityGrouping.availableIds.category;
    const newCategory: ActivityGroupData.Category = {
      workPackageId,
      id,
      name: 'New Category',
      activityGroupIds: [],
    };

    return new MonoliteHelper(state)
      .set((_) => _.activityGrouping.availableIds.category, newCategory.id + 1)
      .set((_) => _.categories[id], newCategory)
      .setAppend((_) => _.workPackages[workPackageId].categoryIds, id)
      .setAppend((_) => _.activityGrouping.changes.categories, newCategory)
      .set((_) => _.activeCategoryId, newCategory.id)
      .set((_) => _.activeActivityGroupId, null)
      .get();
  },
  [ActivityGroupingActionTypes.RENAME_ACTIVITY_GROUP]: (state, group: { id: number, name: string }) => {
    const activityGroup = state.activityGroups[group.id];
    const renamedGroup = {
      id: group.id,
      name: group.name,
      workPackageId: activityGroup.workPackageId,
      categoryId: activityGroup.categoryId,
      workSpaceProcessing: activityGroup.workSpaceProcessing,
    };

    return new MonoliteHelper(state)
      .set((_) => _.activityGroups[group.id].name, group.name)
      .setFilter((_) => _.activityGrouping.changes.activityGroups, x => x.id !== renamedGroup.id)
      .setAppend((_) => _.activityGrouping.changes.activityGroups, renamedGroup)
      .get();
  },
  [ActivityGroupingActionTypes.RENAME_CATEGORY]: (state, category: { id: number, name: string }) => {
    const { id, name } = category;
    const renamedCategory = {
      workPackageId: state.categories[id].workPackageId,
      id,
      name,
    };

    return new MonoliteHelper(state)
      .set((_) => _.categories[id].name, name)
      .setFilter((_) => _.activityGrouping.changes.categories, x => x.id !== renamedCategory.id)
      .setAppend((_) => _.activityGrouping.changes.categories, renamedCategory)
      .get();
  },
  [ActivityGroupingActionTypes.REMOVE_CATEGORY]: (state, categoryId: number) => {
    const workPackageId = state.categories[categoryId].workPackageId;

    return new MonoliteHelper(state)
      .removeKey((_) => _.categories, categoryId)
      .setFilter((_) => _.workPackages[workPackageId].categoryIds, id => id !== categoryId)
      .get();
  },
  [ActivityGroupingActionTypes.REMOVE_ACTIVITY_GROUP]: (state, groupId: number) => {
    const { workPackageId, categoryId } = state.activityGroups[groupId];

    return new MonoliteHelper(state)
      .removeKey((_) => _.activityGroups, groupId)
      .setFilter((_) => _.workPackages[workPackageId].activityGroupIds, id => id !== groupId)
      .setFilter((_) => _.categories[categoryId].activityGroupIds, id => id !== groupId)
      .setAppend((_) => _.activityGrouping.changes.removedActivityGroupIds, groupId)
      .get();
  },
  [ActivityGroupingActionTypes.SET_DRAGGING_ACTIVITY]: (state, activityId: number | null) => {
    let draggingActivity = state.activities[activityId];
    if (!draggingActivity && Number.isInteger(activityId)) {
      const ungroupedActivity = state.ungroupedActivities[activityId];
      draggingActivity = {
        engineIds: ungroupedActivity.engineIds,
        id: ungroupedActivity.id,
        name: ungroupedActivity.name,
        activityGroupId: ungroupedActivity.activityGroupId,
      };
    }

    return new MonoliteHelper(state)
      .set((_) => _.activityGrouping.dndItems.activity, draggingActivity)
      .get();
  },
  [ActivityGroupingActionTypes.SET_DRAGGING_ACTIVITY_GROUP]: (state, groupId: number) => {
    return new MonoliteHelper(state)
      .set((_) => _.activityGrouping.dndItems.activityGroup, state.activityGroups[groupId])
      .get();
  },
  [ActivityGroupingActionTypes.REPLACE_ACTIVITY]: (state, payload: { activityId: number, activityGroupId: number }) => {
    const helper = new MonoliteHelper(state);
    const { activityId, activityGroupId } = payload;

    replaceActivity(helper, state, activityId, activityGroupId);

    const activity = state.activities[activityId] || state.ungroupedActivities[activityId];
    const replacedActivity = {
      id: activity.id,
      name: activity.name,
      activityGroupId,
    };

    helper
      .setAppend((_) => _.activityGroups[activityGroupId].activityIds, activityId)
      .setFilter((_) => _.activityGrouping.changes.activities, x => x.id !== activityId)
      .setAppend((_) => _.activityGrouping.changes.activities, replacedActivity);
    return helper.get();
  },
  [ActivityGroupingActionTypes.REPLACE_SELECTED_ACTIVITIES]: (state, groupId: number) => {
    const selectedActivityIdsMap = state.activityGrouping.selectedActivityIds;
    const helper = new MonoliteHelper(state);
    const selectedIds = Object.keys(selectedActivityIdsMap).map(x => +x);

    for (const activityId of selectedIds) {
      replaceActivity(helper, state, +activityId, groupId);
    }

    const replacedActivities = selectedIds
      .map(id => state.activities[id] || state.ungroupedActivities[id])
      .map(activity => ({
        id: activity.id,
        name: activity.name,
        activityGroupId: groupId,
      }));
    const changes = state.activityGrouping.changes.activities
      .filter(activity => !selectedActivityIdsMap[activity.id])
      .concat(replacedActivities);
    const activityIds = state.activityGroups[groupId].activityIds
      .concat(selectedIds)
      .filter((x, index, array) => array.indexOf(x) === index);

    helper
      .set((_) => _.activityGroups[groupId].activityIds, activityIds)
      .set((_) => _.activityGrouping.changes.activities, changes)
      .set((_) => _.activityGrouping.selectedActivityIds, {})
      .set((_) => _.activityGrouping.selectedActivityGroupIds, {});

    return helper.get();
  },
  [ActivityGroupingActionTypes.REPLACE_ACTIVITY_GROUP]: (
    state,
    payload: { activityGroupId: number, categoryId: number },
  ) => {
    const group = state.activityGroups[payload.activityGroupId];
    const replacedGroup = {
      id: group.id,
      name: group.name,
      workSpaceProcessing: group.workSpaceProcessing,
      categoryId: payload.categoryId,
      workPackageId: group.workPackageId,
    };

    return new MonoliteHelper(state)
      .set((_) => _.activityGroups[payload.activityGroupId].categoryId, payload.categoryId)
      .setFilter(
        (_) => _.categories[group.categoryId].activityGroupIds,
        id => id !== payload.activityGroupId)
      .setAppend((_) => _.categories[payload.categoryId].activityGroupIds, payload.activityGroupId)
      .setFilter((_) => _.activityGrouping.changes.activityGroups, x => x.id !== payload.activityGroupId)
      .setAppend((_) => _.activityGrouping.changes.activityGroups, replacedGroup)
      .get();
  },
  [ActivityGroupingActionTypes.SET_SELECTED_ACTIVITY_IDS]: (state, payload: Record<number, boolean>) => {
    const selectedActivityGroupIds = {};
    for (const activityId in payload) {
      if (state.activities[activityId]) {
        selectedActivityGroupIds[state.activities[activityId].activityGroupId] = true;
      } else {
        selectedActivityGroupIds[state.ungroupedActivities[activityId].activityGroupId] = true;
      }
      const activityGroupId = (state.activities[activityId] || state.ungroupedActivities[activityId]).activityGroupId;
      selectedActivityGroupIds[activityGroupId] = true;
    }

    return new MonoliteHelper(state)
      .set((_) => _.activityGrouping.selectedActivityIds, payload)
      .set((_) => _.activityGrouping.selectedActivityGroupIds, selectedActivityGroupIds)
      .get();
  },
  [ActivityGroupingActionTypes.MERGE_ACTIVITY_GROUPS]: (
    state, payload: { destinationGroupId: number, mergedGroupId: number },
  ) => {
    const { destinationGroupId, mergedGroupId } = payload;
    const mergedGroup = state.activityGroups[mergedGroupId];
    const mergedGroupActivities = mergedGroup.activityIds
      .map(id => ({
        id,
        name: state.activities[id].name,
        activityGroupId: destinationGroupId,
      }));
    const activityChanges = state.activityGrouping.changes.activities
      .filter(activity => !mergedGroupActivities.find(x => x.id === activity.id))
      .concat(mergedGroupActivities);

    const helper = new MonoliteHelper(state);
    helper.set(
      (_) => _.activityGroups[destinationGroupId].activityIds,
      state.activityGroups[destinationGroupId].activityIds.concat(mergedGroup.activityIds))
      .removeKey((_) => _.activityGroups, mergedGroupId)
      .setFilter((_) => _.workPackages[mergedGroup.workPackageId].activityGroupIds, id => id !== mergedGroup.id)
      .setFilter((_) => _.categories[mergedGroup.categoryId].activityGroupIds, id => id !== mergedGroup.id)
      .set((_) => _.activityGrouping.changes.activities, activityChanges)
      .setAppend((_) => _.activityGrouping.changes.removedActivityGroupIds, mergedGroupId);

    for (const activityId of mergedGroup.activityIds) {
      helper.set((_) => _.activities[activityId].activityGroupId, destinationGroupId);
    }

    if (state.activityGrouping.selectedActivityGroupIds[mergedGroupId]) {
      helper
        .removeKey((_) => _.activityGrouping.selectedActivityGroupIds, mergedGroupId)
        .set((_) => _.activityGrouping.selectedActivityGroupIds[destinationGroupId], true);
    }

    return helper.get();
  },
};
