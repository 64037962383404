import autobind from 'autobind-decorator';
import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';

import './index.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { Location } from 'common/interfaces/common-state';
import { UserInfo } from 'common/interfaces/people';
import { Avatar } from 'common/UIKit';
import { ScenarioStatus } from '../../../../constants';
import { IconPrivate } from '../../../../icons';
import { ScenariosData } from '../../interfaces';
import { CalculationItem } from '../calculation-item';
import { ScenarioItemControl } from '../scenario-item-control';
import { ScenarioItemIcon } from '../scenario-item-icon';

interface Props extends AbilityAwareProps {
  item: ScenariosData.Scenario;
  changeScenarioName: (id: number, name: string) => void;
  changeCalculationName: (id: number, name: string, scenarioId: number) => void;
  deleteScenario: (scenario: ScenariosData.Scenario) => void;
  copyScenario: (id: number) => void;
  publishScenario: (id: number, value: boolean) => void;
  scenariosToCopy: number[];
  getCalculations: (id: number) => void;
  activateScenario: (scenarioId: number, calculationId: number) => void;
  deleteCalculation: (calculationId: number, scenarioId: number) => void;
  activateCalculation: (id: number, scenarioId: number) => void;
  onEditScenario: (scenario: ScenariosData.Scenario) => void;
  calculationsStatus: number[];
  showShortLevelling: boolean;
  hasChangeScenarioStatus: boolean;
  bidPricingCalculation: number;
  openUpdateScenarioDialog: (scenario: ScenariosData.Scenario) => void;
  locations: Location[];
  owner: UserInfo;
  ownerId: string;
}

interface State {
  expanded: boolean;
}

class ScenarioItemComponent extends React.Component<Props, State> {
  public state: State = {
    expanded: false,
  };

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.item.calculations && !this.props.item.calculations) {
      this.setState({ expanded: false });
    }
  }

  public render(): JSX.Element {
    const {
      item,
      ability,
      changeScenarioName,
      deleteScenario,
      copyScenario,
      publishScenario,
      onEditScenario,
      openUpdateScenarioDialog,
      hasChangeScenarioStatus,
      locations,
    } = this.props;
    const { expanded } = this.state;
    const scenarioFailed = item.status === ScenarioStatus.Error;
    const canUpdate = ability.can(Operation.Update, Subject.Scenarios);

    const hasOneCalculation = item.calculationsQuantity <= 1;
    const itemClassName = classNames('scenario-item', {
      'scenario-item--has-error': scenarioFailed,
      'scenario-item--active': item.isActivated,
    });
    const calculationsClassName = classNames('scenario-item__calculations', {
      'scenario-item__calculations--expanded': expanded,
    });

    return (
      <div className={itemClassName}>
        {this.ownerIcon(item.isPublic, this.props.owner, this.props.ownerId)}
        <div className='scenario-item__scenario'>
          <ScenarioItemControl
            item={item}
            className='scenario-item__scenario-inner'
            hasChangeScenarioStatus={hasChangeScenarioStatus}
            hasOneCalculation={hasOneCalculation}
            locations={locations}
            expanded={expanded}
            scenarioFailed={scenarioFailed}
            canUpdate={canUpdate}
            changeScenarioName={changeScenarioName}
            deleteScenario={deleteScenario}
            copyScenario={copyScenario}
            publishScenario={publishScenario}
            onEditScenario={onEditScenario}
            openUpdateScenarioDialog={openUpdateScenarioDialog}
          />
        </div>
        {!scenarioFailed && (<>
          <div className='scenario-item__calculations-wrap'>
            <div className={calculationsClassName}>
              {this.renderCalculation(expanded, hasOneCalculation, canUpdate)}
            </div>
            {item.calculationsQuantity > 1 && (
              <div
                className='scenario-item__expand-button'
                onClick={this.toggleExpand}
              >
                Show {expanded ? 'less' : 'more'} calculations
              </div>
            )}
          </div>
        </>)}
        {this.renderOverlay()}
      </div>
    );
  }

  private ownerIcon(isPublicItem: boolean, owner?: UserInfo, ownerId?: string): React.ReactNode {
    if (isPublicItem) {
      if (!owner) {
        return null;
      }
      return (
        <ScenarioItemIcon
          tooltipId={`avatar-tooltip_${ownerId}`}
          tooltipTitleRow='Public Scenario'
          tooltipSubRow={`Owner: ${owner.firstName} ${owner.lastName}`}
          icon={
            <Avatar
              userId={ownerId}
              firstName={owner.firstName}
              lastName={owner.lastName}
              className='scenario-item-icon__avatar'
            />
          }
        />
      );
    } else {
      return (
        <ScenarioItemIcon
          tooltipId='private-tooltip'
          tooltipTitleRow='Private Scenario'
          tooltipSubRow='This scenario is only visible to you'
          icon={<img className={'scenario-item-icon__lock-img'} src={IconPrivate} />}
        />
      );
    }
  }

  @autobind
  private renderCalculation(expanded: boolean, hasOneCalculation: boolean, canUpdate: boolean): React.ReactNode {
    const { item, calculationsStatus, showShortLevelling } = this.props;
    const bestCalc = item.calculations && _.minBy(item.calculations, x => x.total_cost);

    if (!expanded || hasOneCalculation) {
      if (item.calculation) {
        return (
          <CalculationItem
            item={item.calculation}
            changeCalculationName={this.changeCalculationName}
            deleteCalculation={this.deleteCalculation}
            activateCalculation={this.activateCalculation}
            isScenarioActive={item.isActivated}
            activateScenario={this.activateScenario}
            showShortLevelling={showShortLevelling}
            isOwner={item.isOwner}
            isBidPricingCalculation={item.calculation.id === this.props.bidPricingCalculation}
            canUpdate={canUpdate}
          />
        );
      }
    } else {
      if (calculationsStatus.includes(item.id)) {
        return <SvgSpinner size='middle' />;
      } else if (item.calculations) {
        return (
          item.calculations.map(x => (
            <CalculationItem
              key={x.id}
              item={x}
              changeCalculationName={this.changeCalculationName}
              deleteCalculation={this.deleteCalculation}
              activateCalculation={this.activateCalculation}
              isScenarioActive={item.isActivated}
              activeCalculation={item.calculation}
              isBestTotal={bestCalc && x.id === bestCalc.id}
              activateScenario={this.activateScenario}
              showShortLevelling={showShortLevelling}
              isOwner={item.isOwner}
              isBidPricingCalculation={x.id === this.props.bidPricingCalculation}
              canUpdate={canUpdate}
            />
          ))
        );
      }
    }
  }

  private renderOverlay(): React.ReactNode {
    const { item, scenariosToCopy } = this.props;
    const scenarioStatusesToShow = [ScenarioStatus.Calculating];
    let msg = null;
    if (scenariosToCopy.includes(item.id)) {
      msg = 'Copying...';
    } else if (scenarioStatusesToShow.includes(item.status)) {
      msg = ScenarioStatus[item.status];
    }
    return (
      msg && (
        <div className={'scenario-item__copying-overlay'}>
          <SvgSpinner size='middle' />
          <div className={'scenario-item__copying-overlay-name'}>{msg}</div>
        </div>
      )
    );
  }

  @autobind
  private activateScenario(calculationId: number): void {
    const item = this.props.item;
    if (item.status !== ScenarioStatus.Editing) {
      this.props.activateScenario(item.id, calculationId);
    }
  }

  @autobind
  private toggleExpand(): void {
    const item = this.props.item;
    const { expanded } = this.state;
    if (!this.props.item.calculations) {
      this.props.getCalculations(item.id);
    }
    this.setState({ expanded: !expanded });
  }

  @autobind
  private changeCalculationName(id: number, name: string): void {
    this.props.changeCalculationName(id, name, this.props.item.id);
  }

  @autobind
  private deleteCalculation(calculationId: number): void {
    this.props.deleteCalculation(calculationId, this.props.item.id);
  }

  @autobind
  private activateCalculation(id: number): void {
    this.props.activateCalculation(id, this.props.item.id);
  }
}

export const ScenarioItem = withAbilityContext(ScenarioItemComponent);
