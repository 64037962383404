import autobind from 'autobind-decorator';
import * as classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import './row.scss';

import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { State } from '../../common/interfaces/state';
import { CostFieldNames } from '../../constants';
import { CostEstimateGroupingType } from '../../units/projects/enums/cost-estimate-grouping-type';
import { CostRateCell } from './cells/cost-rate-cell';
import { DurationCell } from './cells/duration-cell';
import { OverheadAndProfitCell } from './cells/overhead-and-profit-cell';
import { ReadonlyCostRateCell } from './cells/readonly-cost-rate-cell';
import { TotalCell } from './cells/total-cell';
import Name from './name';
import * as Utils from './utils';

interface RowOwnProps {
  path: string;
  isEditable: boolean;
  includeDuration: boolean;
  groupingType: CostEstimateGroupingType;
  projectName: string;
  changeField: (value: any, fieldName: string) => void;
  onCollapse: () => void;
}

interface RowStateProps {
  isExpandable: boolean;
  isExpanded: boolean;
  levelOfNesting: number;
  rowName: string;
  duration: number | undefined;
  offSiUnit: UnitTypes;
  quantity: number | undefined;
  laborRate: number | undefined;
  laborCost: number;
  plantRate: number | undefined;
  plantCost: number;
  materialRate: number | undefined;
  materialCost: number;
  directRate: number | undefined;
  directCost: number;
  totalCost: number | undefined;
  overheadRate: number | undefined;
  extraPayment: number | undefined;
  isIndirectCosts: boolean;
}

interface RowProps extends RowOwnProps, RowStateProps {}

class RowComponent extends React.Component<RowProps> {
  public componentDidMount(): void {
    if (this.props.isEditable && this.props.levelOfNesting === 1) {
      this.props.onCollapse();
    }
  }
  public render(): JSX.Element {
    const rowClassName = classnames('cost-estimate-table__row', Utils._getStyle(this.props.levelOfNesting), {
      editable: this.props.isEditable,
      expanded: this.props.isExpanded,
    });

    const name = this.props.levelOfNesting === 0 ? this.props.projectName : this.props.rowName;

    const hasUnits = !!this.props.offSiUnit;

    return (
      <div className={rowClassName}>
        <Name
          isExpandable={this.props.isExpandable}
          level_of_nesting={this.props.levelOfNesting}
          collapsed={!this.props.isExpanded}
          onCollapse={this.props.onCollapse}
          name={name}
          innerId={this.props.path}
        >
          {this.props.quantity ? (
            <React.Fragment>
              {this.getUnitCell()}
              <div className='cost-estimate-table__quantity'>
                {UnitUtil.round(this.props.quantity, this.props.offSiUnit)}
              </div>
            </React.Fragment>
          ) : null}
        </Name>
        <CostRateCell
          className={'lbord rbord'}
          rate={this.props.laborRate}
          rateFieldName={CostFieldNames.LABOR_RATE}
          cost={this.props.laborCost}
          costFieldName={CostFieldNames.LABOR_COST}
          onInputChange={this.props.changeField}
          isEditable={this.props.isEditable && this.props.levelOfNesting === 2}
          levelOfNesting={this.props.levelOfNesting}
          groupingType={this.props.groupingType}
          isIndirectCostsRow={this.props.isIndirectCosts}
          hasUnits={hasUnits}
        />
        <CostRateCell
          className={'rbord'}
          rate={this.props.plantRate}
          rateFieldName={CostFieldNames.PLANT_RATE}
          cost={this.props.plantCost}
          costFieldName={CostFieldNames.PLANT_COST}
          onInputChange={this.props.changeField}
          isEditable={this.props.isEditable && this.props.levelOfNesting === 2}
          levelOfNesting={this.props.levelOfNesting}
          groupingType={this.props.groupingType}
          isIndirectCostsRow={this.props.isIndirectCosts}
          hasUnits={hasUnits}
        />
        <CostRateCell
          className={'material rbord'}
          rate={this.props.materialRate}
          rateFieldName={CostFieldNames.MATERIAL_RATE}
          cost={this.props.materialCost}
          costFieldName={CostFieldNames.MATERIAL_COST}
          onInputChange={this.props.changeField}
          isEditable={this.props.isEditable && this.props.levelOfNesting === 2}
          levelOfNesting={this.props.levelOfNesting}
          groupingType={this.props.groupingType}
          isIndirectCostsRow={this.props.isIndirectCosts}
          hasUnits={hasUnits}
        />
        <ReadonlyCostRateCell
          className={'rbord'}
          rate={hasUnits ? this.props.directRate : null}
          cost={this.props.directCost}
        />
        <OverheadAndProfitCell
          overheadPercentage={this.props.overheadRate}
          extraPayment={this.props.extraPayment}
          isEditable={this.props.isEditable}
          levelOfNesting={this.props.levelOfNesting}
          onInputChange={this.props.changeField}
          groupingType={this.props.groupingType}
          isIndirectCosts={this.props.isIndirectCosts}
        />
        <TotalCell
          levelOfNesting={this.props.levelOfNesting}
          totalCost={this.props.totalCost}
          groupingType={this.props.groupingType}
          isIndirectCosts={this.props.isIndirectCosts}
        />
        {this.props.includeDuration ? (
          <DurationCell
            duration={this.props.duration}
            isEditable={this.props.isEditable}
            levelOfNesting={this.props.levelOfNesting}
            groupingType={this.props.groupingType}
            isIndirectCosts={this.props.isIndirectCosts}
            onInputChange={this.props.changeField}
          />
        ) : null}
      </div>
    );
  }

  @autobind
  private getUnitCell(): JSX.Element {
    const powerRegex = /([^\d*])(\d+)$/;
    const match = powerRegex.exec(this.props.offSiUnit);

    if (this.props.offSiUnit && match) {
      return (
        <div className='cost-estimate-table__unit'>
          {match[1]}
          <sup>{match[2]}</sup>
        </div>
      );
    }

    return <div className='cost-estimate-table__unit'>{this.props.offSiUnit}</div>;
  }
}

const mapStateToProps = (state: State, ownProps: RowOwnProps): RowStateProps => {
  const rowPath = ['costs'].concat(ownProps.path.split('.'));
  const row = state.cost.getIn(rowPath).toJS();

  return {
    isExpandable: row.expandable,
    isExpanded: row.expanded,
    levelOfNesting: row.levelOfNesting,
    rowName: row.name,
    offSiUnit: row.offSiUnit,
    quantity: row.quantity,
    laborRate: row.laborCostPerOffSiUnit,
    laborCost: row.laborCost,
    plantRate: row.plantCostPerOffSiUnit,
    plantCost: row.plantCost,
    materialRate: row.materialCostPerOffSiUnit,
    materialCost: row.materialCost,
    directRate: row.directCostPerOffSiUnit,
    directCost: row.directCost,
    extraPayment: row.extraPaymentsPercentage,
    overheadRate: row.overheadPercentage,
    totalCost: row.totalCost,
    isIndirectCosts: row.isIndirectsCosts,
    duration: row.duration,
  };
};

export const Row = connect<RowStateProps, {}, RowOwnProps, State>(mapStateToProps)(RowComponent);
