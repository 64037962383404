import { DrawingSnapping } from '../interfaces/drawing';

async function getSnapping(pageNumber: number, pdfDoc: Core.PDFNet.PDFDoc): Promise<DrawingSnapping | null> {
  try {
    const page = await pdfDoc.getPage(pageNumber);
    const fromPdf = await page.getDefaultMatrix(true);
    return {
      geometry: await pdfDoc.getGeometryCollectionForPage(pageNumber),
      fromPdf,
      toPdf: await fromPdf.inverse(),
    };
  } catch (e) {
    console.error(e);
    return null;
  }
}

async function getSnappingCore(
  pageNumber: number,
  coreDoc: Core.Document,
): Promise<DrawingSnapping> {
  const pdfDoc = await coreDoc.getPDFDoc();
  return getSnapping(pageNumber, pdfDoc);
}

export const SnappingGetters = {
  getSnapping,
  getSnappingCore,
};
