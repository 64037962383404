import * as t from 'io-ts';

import { RoleCodeC } from 'common/enums/role-code';

export const ShortRoleC = t.strict(
  {
    id: t.number,
    name: t.string,
    code: RoleCodeC,
  },
  'ShortRole');

export const ShortRolesC = t.array(ShortRoleC);

export type ShortRole = t.TypeOf<typeof ShortRoleC>;
