import styled from 'styled-components';

const HEADER_HEIGHT = 90;
const HEADER_MARGIN_BOTTOM = 60;
const PROJECT_HEADER_HEIGHT = 60;

const Container = styled.div`
  position: relative;
  height: 100%;
  width: calc(100% - 340px);
  background-color: ${props => props.theme.color.backgroundRush};
  border-radius: 15px 0 0 15px;
`;

const Projects = styled.div`
  height: calc(100% - ${HEADER_HEIGHT + HEADER_MARGIN_BOTTOM}px);
`;

const ProjectsHeader = styled.div`
  display: flex;
  align-items: center;
  height: ${PROJECT_HEADER_HEIGHT}px;
  padding: 0 40px;

  label {
    max-width: 50%;
    margin-left: 100px;
    margin-bottom: 0;
  }
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  background-color: ${props => props.theme.color.background};
  border-radius: 10px;
`;

const Main = styled.div`
  position: relative;
  height: calc(100% - ${PROJECT_HEADER_HEIGHT}px);
  overflow-y: auto;
`;

const MainGrid = styled.div<{ loading?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, 260px);
  grid-template-rows: repeat(auto-fill, 260px);
  grid-gap: 40px;
  justify-content: center;
  margin: 60px 40px;

  > button {
    position: ${props => props.loading ? 'absolute' : 'relative'};
    top: ${props => props.loading ? '50%' : 'unset'};
    left: ${props => props.loading ? '50%' : 'unset'};
    transform: ${props => props.loading ? 'translate(-50%, -50%)' : 'unset'};
    margin: auto;
    pointer-events: ${p => p.loading && 'none'};
  }
`;

export const Styled = {
  Container,
  Main,
  MainGrid,
  Projects,
  Count,
  ProjectsHeader,
};
