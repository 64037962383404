import { CleanInput } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { RadioButton } from 'common/components/radion-button';
import { QuestionnaireContent } from '../../questionnaire';
import { Styled } from '../styled';
import { AddArrowButton } from './add-arrow-button';


interface Props {
  questionContent: QuestionnaireContent[];
  replies: string[];
  id: number;
  onClick: (value: string[]) => void;
  setQuestionContent: (value: QuestionnaireContent[]) => void;
  placeholder?: string;
}

export const OtherInputReply: React.FC<Props> = ({
  onClick,
  setQuestionContent,
  questionContent,
  replies,
  id,
  placeholder,
}) => {
  const [check, setChecked] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<any>('');
  const [showAddButton, setShowAddButton] = useState<boolean>(false);
  const isCurrentAnswer = !questionContent[id].replies.find(el => el.reply === replies[id])
  && inputValue === replies[id];
  const [headerText, setHeaderText] = useState(isCurrentAnswer ? replies[id] : 'Other');
  const inputRef: React.MutableRefObject<any> = useRef();

  useEffect(() => {
    const isUpdateInputValue = questionContent[id].replies.find(el => el.reply === replies[id]);

    if (!inputValue && replies[id] && !isUpdateInputValue) {
      setInputValue(replies[id]);
    }
  }, [questionContent, id, inputValue, replies]);

  const handleChange = useCallback((value) => {
    setInputValue(value);
    if (value.trim()) {
      setShowAddButton(true);
    } else {
      setShowAddButton(false);
    }
  }, []);

  const handleCheckBoxOnClick = useCallback(() => {
    inputRef.current.focus();
  }, []);

  const handleOnBlur = useCallback((e) => {
    setShowAddButton(false);

    if (isCurrentAnswer && !e.target.value) {
      setHeaderText(replies[id]);
    } else if (!e.target.value.trim()) {
      setInputValue(inputValue.trim());
      setHeaderText('Other');
      setChecked(false);
    }
  }, [isCurrentAnswer, replies, id, inputValue]);

  const handleOnFocus = useCallback((e) => {
    setHeaderText('');

    if (headerText.trim() && headerText !== 'Other') {
      setShowAddButton(true);
      setInputValue(headerText);
    }

    if (e.target.value.trim()) {
      setShowAddButton(true);
    }
  }, [headerText]);

  const handleOnReply = useCallback(() => {
    if (!questionContent[id].isChecked && inputValue.trim()) {
      const newContent = [...questionContent];
      newContent[id] = { ...questionContent[id], isChecked: true };
      setQuestionContent(newContent);
      const newAnswers = [...replies];
      newAnswers[id] = inputValue;
      onClick(newAnswers);
      setChecked(true);
    }
  }, [questionContent, id, inputValue, setQuestionContent, replies, onClick]);

  const onKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleOnReply();
      e.target.blur();
    }
  }, [handleOnReply]);

  return (
    <Styled.ReplyContainer
      isCurrentAnswer={isCurrentAnswer}
    >
      <Styled.CheckBoxContainer isCurrentAnswer={isCurrentAnswer}>
        <RadioButton checked={check} onClick={handleCheckBoxOnClick}/>
      </Styled.CheckBoxContainer>
      <Styled.InputWrapper isCurrentAnswer={isCurrentAnswer}>
        <CleanInput
          inputRef={inputRef}
          value={inputValue}
          onChange={handleChange}
          onKeyPress={onKeyPress}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          autoFocus={true}
          headerText={!inputValue.trim() ? headerText : ''}
          placeholder={placeholder || 'Type your answer'}
        />
        {showAddButton && <AddArrowButton onClick={handleOnReply}/>}
      </Styled.InputWrapper>
    </Styled.ReplyContainer>
  );
};
