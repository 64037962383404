import styled from 'styled-components';

import { Styled as UpgradeWrapperStyled } from '2d/components/upgrade-plan/styled';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${props => props.theme.color.backgroundRush};
  border-radius: 20px;
  margin-bottom: 10px;
  ${UpgradeWrapperStyled.StarsContainer} {
    top: -10px;
    right: -10px;
  }
`;

export const Styled = {
  Container,
};
