import { FlowNavigation } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';
import { Text } from 'common/components/text';
import { TabNames } from '../enums';
import { Styled } from './styled';

interface Props {
  menuNames: TabNames[];
  currentTab: TabNames;
  onSelectMenuItem: (value: TabNames) => void;
}

const HeaderComponent: React.FC<Props> = ({
  menuNames,
  currentTab,
  onSelectMenuItem,
}) => {
  const menuItems = useMemo(() => {
    return menuNames.map(name => {
      return {
        name,
        current: name === currentTab,
        onClick: () => onSelectMenuItem(name),
      };
    });
  }, [menuNames, currentTab]);
  return (
    <Styled.HeaderContainer>
      <FlowNavigation
        items={menuItems}
        TextComponent={Text}
        borderRadius={5}
        borderOverhang={5}
        height={5}
        marginRight={20}
      />
    </Styled.HeaderContainer>
  );
};


export const CalibrateHeader = React.memo(HeaderComponent);
