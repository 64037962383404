import styled from 'styled-components';

import { BackdropStyled } from 'unit-2d-database/components/side-panel/components';


const SidePanelWrapper = styled.div`
  ${BackdropStyled.Container} {
    width: 320px;
  }
`;

export const Styled = {
  SidePanelWrapper,
};
