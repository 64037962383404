import { ElementTooltip, IconButton, Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { ProjectsView } from '../constants';
import { Styled } from './styled';


export const ProjectsDisplayFormatButtons: React.FC = () => {
  const dispatch = useDispatch();
  const projectsViewType = useSelector((state: State) => state.persistedStorage.projectsViewType);

  const onClickExtraLarge = useCallback(() => {
    dispatch(PersistedStorageActions.changeViewProjects(ProjectsView.Grid));
  }, []);

  const onClickList = useCallback(() => {
    dispatch(PersistedStorageActions.changeViewProjects(ProjectsView.List));
  }, []);

  return (
    <Styled.Container>
      <ElementTooltip text={ProjectsView.Grid} position="top">
        <IconButton
          Icon={Icons.FourViews}
          onClick={onClickExtraLarge}
          width={20}
          height={20}
          defaultColor={projectsViewType === ProjectsView.Grid && 'turquoise'}
        />
      </ElementTooltip>
      <ElementTooltip text={ProjectsView.List} position="top">
        <IconButton
          Icon={Icons.TwoViewsStacked}
          onClick={onClickList}
          width={20}
          height={20}
          defaultColor={projectsViewType === ProjectsView.List && 'turquoise'}
        />
      </ElementTooltip>
    </Styled.Container>
  );
};
