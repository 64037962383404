import { deleteColumns } from './delete-columns/delete-columns';
import { deleteRows } from './delete-rows/delete-rows';
import { insertColumns } from './insert-columns/insert-columns';
import { insertRows } from './insert-rows/insert-rows';
import { MoveCellTempData, MoveDataPayload, TableDataMoveType } from './interfaces';

export const moveData = (payload: MoveDataPayload): MoveCellTempData => {
  switch (payload.type) {
    case TableDataMoveType.InsertRows: {
      return insertRows(payload);
    }
    case TableDataMoveType.InsertColumns: {
      return insertColumns(payload);
    }
    case TableDataMoveType.DeleteRows: {
      return deleteRows(payload);
    }
    case TableDataMoveType.DeleteColumns: {
      return deleteColumns(payload);
    }
    default: return null;
  }
};
