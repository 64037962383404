import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { TempInvitePeople } from '../../../../../units/subscription/store/interface';
import { SubscriptionDetailsModel } from '../../../interfaces/subscription-details';
import { TeamSize } from '../../subscription-details/team-size';
import { FillStepScaleSubscription } from '../fill-step-scale-subscription';
import { Styled } from './styled';

interface Props {
  onSubscriptionChange: (subscription: SubscriptionDetailsModel) => void;
  currentSubscription: SubscriptionDetailsModel;
  currentTeamSize: number;
  minTeamSize: number;
  headerText: string;
  openDialog?: (name: string) => void;
}

export const TeamSizeBlock: React.FC<Props> = (props) => {
  const {
    currentSubscription,
    currentTeamSize,
    minTeamSize,
    headerText,
    onSubscriptionChange,
    openDialog,
  } = props;

  const invitedPeople = useSelector<State, TempInvitePeople[]>((s) => s.subscription.tempInvitePeople);
  const countInvitedPeople = invitedPeople &&
    invitedPeople.filter(p => p.email && !p.email.endsWith('@kreo.net')).length;

  const onTeamSizeChanged = useCallback((teamSize: number) => {
    onSubscriptionChange({
      ...currentSubscription,
      teamSize,
    });
  }, [onSubscriptionChange, currentSubscription]);

  return (
    <>
      <Styled.FillStepScaleContainer>
        <FillStepScaleSubscription
          numberStep={3}
          isValid={true}
        />
      </Styled.FillStepScaleContainer>
      <Styled.ContentContainer headerMargin={19}>
        <TeamSize
          currentTeamSize={currentTeamSize}
          minTeamSize={minTeamSize}
          countInvitedPeople={countInvitedPeople}
          headerText={headerText}
          onTeamSizeChange={onTeamSizeChanged}
          openDialog={openDialog}
        />
      </Styled.ContentContainer>
    </>
  );
};
