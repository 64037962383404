import styled from 'styled-components';

const HEADER_HEIGHT = 90;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.color.background};

  *::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  *::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }
`;

const Header = styled.div`
  height: ${HEADER_HEIGHT}px;
`;

const Main = styled.div`
  box-sizing: border-box;
  overflow: overlay;
  height: calc(100% - ${HEADER_HEIGHT}px);
  padding: 40px 20px;
`;

const Message = styled.div`

`;


export const Styled = {
  Container,
  Header,
  Main,
  Message,
};
