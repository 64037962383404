export enum MoveToCellOptionType {
  area = 'area',
  perimeter = 'perimeter',
  count = 'count',
  length = 'length',
  name = 'name',
  width = 'width',
  height = 'height',
  pointsCount = 'pointsCount',
  segmentsCount = 'segmentsCount',
  segmentLength = 'segmentLength',
  table = 'table',
  drawingName = 'drawingName',
  groupName = 'groupName',
  documentName = 'documentName',
  property = 'property',
  dynamicTable = 'dynamicTable',
  verticalArea = 'verticalArea',
  volume = 'volume',
  thickness = 'thickness',
}

export interface MoveToCellOptionData {
  type: MoveToCellOptionType;
  value: number;
  renderMenu?: () => React.ReactNode;
}
