import React from 'react';

import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { DrawingDialogs } from '../../constants/drawing-dialogs';

const NotEnoughGeometriesDialogComponent: React.FC = () => {
  return (
    <ConfirmationDialog
      name={DrawingDialogs.NOT_ENOUGH_GEOMETRIES_DIALOG}
      title={`Most likely we won't find geometry here...`}
      // eslint-disable-next-line max-len
      text={`In this drawing, it's unlikely that we'll find anything specific. It's more probable that the drawing itself is a picture.`}
      cancelButtonText="I'll be lucky"
    />
  );
};

export const NotEnoughGeometriesDialog = React.memo(NotEnoughGeometriesDialogComponent);
