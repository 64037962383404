import { CodecUtils } from 'common/utils/codec-utils';

export enum CostEstimateValidationStep {
  Classification = 'Classification',
  ActivityAssignment = 'ActivityAssignment',
  Measurements = 'Measurements',
  Reports = 'Reports',
}

export const CostEstimateValidationStepC = CodecUtils.createEnumCodec<CostEstimateValidationStep>(
  CostEstimateValidationStep, 'CostEstimateValidationStep',
);
