import { PaymentMethod, Text } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';

import { BillingPaymentModel } from '../../../../interfaces/billing-payment-model';
import { Styled } from './styled';

interface Props {
  cards: BillingPaymentModel[];
  primaryCardId: string;
  backupCardId: string;
  subscriptionCardId: string;
  setPrimaryCard: (cardId: string) => void;
  setBackupCard: (cardId: string) => void;
  setSubscriptionCard: (cardId: string) => void;
  removeCard: (cardId: string) => void;
}

const ExistedCardsComponent: React.FC<Props> = (
  {
    cards,
    primaryCardId,
    backupCardId,
    subscriptionCardId,
    setPrimaryCard,
    setBackupCard,
    setSubscriptionCard,
    removeCard,
  },
) => {
  const parsedCards = useMemo(() => cards?.map(x => {
    if (x.card) {
      return {
        id: x.id,
        status: x.status.toLowerCase(),
        ...x.card,
        type: x.card.brand.toLowerCase(),
      };
    } else {
      return {
        id: x.id,
        status: x.status.toLowerCase(),
        type: x.type,
        expiryMonth: 0,
        expiryYear: 0,
        last4: x.description,
      };
    }
  }), [cards]);
  if (!parsedCards?.length) {
    return null;
  }

  return (
    <>
      <Text fontSize={14} lineHeight={16}>
        Your current Cards
      </Text>
      <Styled.CardList>
        {parsedCards?.map((card, index) => {
          return (
            <PaymentMethod
              key={index}
              card={card}
              isPrimary={primaryCardId === card.id}
              isBackup={backupCardId === card.id}
              isSubscription={subscriptionCardId === card.id}
              setPrimaryCard={setPrimaryCard}
              setBackupCard={setBackupCard}
              setSubscriptionCard={setSubscriptionCard}
              removeCard={removeCard}
              isLine={true}
            />);
        })
        }
      </Styled.CardList>
    </>
  );
};


export const ExistedCards = React.memo(ExistedCardsComponent);
