import { ConstantFunctions } from 'common/constants/functions';
import { ValueHelper } from 'common/utils/value-helper';
import { FontStyle, HorizontalAlign, StyleFields, StyleParams, StyleResult } from './interfaces';
import { PropertyGetter } from './property-getter';

const concatValue = (property, value): string => property && value
  ? property.concat(` ${value}`)
  : property || value;

const backgroundColor = (styles, value): string => styles['background-color'] = value || '';
const fontColor = (styles, value): string => styles.color = value || '';
const fontName = (styles, value): string => styles['font-family'] = value || '';
const fontSize = (styles, value): string => styles['font-size'] = value ? `${value}pt` : '';

const fontStyle = (styles, value): void => {
  /* eslint-disable no-bitwise */
  styles['font-weight'] = value & FontStyle.Bold
    ? 'bold'
    : '';

  styles['font-style'] = value & FontStyle.Italic
    ? 'italic'
    : '';

  const underlineValue = value & FontStyle.Underline
    ? 'underline'
    : '';
  styles['text-decoration'] = concatValue(styles['text-decoration'], underlineValue);

  const strikeThroughValue = value & FontStyle.StrikeThrough
    ? 'line-through'
    : '';
  styles['text-decoration'] = concatValue(styles['text-decoration'], strikeThroughValue);
  /* eslint-enable */
};

const horizontalAlign = (result, value: HorizontalAlign, cellValue?: string | number): void => {
  let alignValue: string;
  switch (value) {
    case HorizontalAlign.Center:
      alignValue = 'center';
      break;
    case HorizontalAlign.Left:
      alignValue = 'left';
      break;
    case HorizontalAlign.Right:
      alignValue = 'right';
      break;
    default:
      alignValue = ValueHelper.isNumberValue(cellValue)
        ? 'right'
        : 'left';
  }

  result['text-align'] = alignValue;
};

type AppendFieldFunctionType = (result: StyleResult, value: any, cellValue?: string | number) => void;
const getAppendFieldFunction = (type: StyleFields): AppendFieldFunctionType => {
  switch (type) {
    case StyleFields.BackgroundColor: return backgroundColor;
    case StyleFields.FontColor: return fontColor;
    case StyleFields.FontSize: return fontSize;
    case StyleFields.FontName: return fontName;
    case StyleFields.FontStyle: return fontStyle;
    case StyleFields.HorizontalAlign: return horizontalAlign;
    default: return ConstantFunctions.doNothing;
  }
};

const appendField = (
  type: StyleFields,
  result: StyleResult,
  values: Record<StyleFields, any>,
  cellValue: string | number,
): StyleResult => {
  const appendFunction = getAppendFieldFunction(type);
  appendFunction(result, values[type], cellValue);
  return result;
};

const getDefaultStyles = (params: StyleParams): StyleResult => {
  return {
    ['text-align']: ValueHelper.isNumberValue(params.value)
      ? 'right'
      : 'left',
  };
};

const getCellStyle = (params: StyleParams): StyleResult => {
  const styles = PropertyGetter.getCellStyleProperty(params);
  if (!styles) {
    return getDefaultStyles(params);
  }

  const result: StyleResult = {};
  Object.values(StyleFields).forEach(key => appendField(key, result, styles, params.value));

  return result;
};

export const CellStyleGetter = {
  getCellStyle,
};
