import { DrawingsGeometryGroup, DrawingsGeometryInstance } from 'common/components/drawings';
import { DrawingsGroupUtils } from 'common/components/drawings/utils/drawings-group-utils';

export const getMeasurementsList = (
  id: string,
  groups: DrawingsGeometryGroup[],
  geometry: Record<string, DrawingsGeometryInstance>,
): string[] => {
  const drawingGroup = groups.find(group => group.id === id);
  if (drawingGroup) {
    const groupsMap = DrawingsGroupUtils.getParentToChildMap(groups);
    return DrawingsGroupUtils.getAllInnerMeasurements(drawingGroup, groupsMap, () => true);
  } else {
    return id in geometry ? [id] : null;
  }
};
