import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { SnappingSettings } from 'common/components/drawings/components/snappings-settings';
import { useMeasurementsAbilities } from 'common/components/drawings/hooks';
import { RenderIf } from 'common/components/render-if';
import { AccordionElement } from '../../../../common/components/accordion-element';
import { ThreeDSettings } from './3d-settings/three-d-settings';
import { DrawingsSettings } from './drawing-settings/drawing-settings';
import { MeasurementSettings } from './measurement-settings.tsx';
import { Styled } from './styled';
import { UnitsSwitcher } from './units-switcher';


const SettingsComponent: React.FC = () => {
  const { canEditMeasurements, canViewMeasurements } = useMeasurementsAbilities();
  const canEdit3dMeasurements = useAbility(Operation.Update, Subject.Takeoff2dMeasurement3d);
  if (!canViewMeasurements)  {
    return null;
  }

  return (
    <Styled.Container>
      <AccordionElement
        title='Unit system'
        isOpen={true}
        infoIcon={Icons.UnitSystem}
      >
        <Styled.UnitsSwitcherContainer>
          <UnitsSwitcher/>
        </Styled.UnitsSwitcherContainer>
      </AccordionElement>
      <RenderIf condition={canEditMeasurements}>
        <AccordionElement
          title='Snapping'
          isOpen={true}
          infoIcon={Icons.Snapping2D}
        >
          <SnappingSettings/>
        </AccordionElement>
      </RenderIf>
      <AccordionElement
        title='Drawing'
        isOpen={true}
        infoIcon={Icons.DrawingSmall}
      >
        <DrawingsSettings/>
      </AccordionElement>
      <RenderIf condition={canEditMeasurements}>
        <AccordionElement
          title='Measurements'
          isOpen={true}
          infoIcon={Icons.Layers}
        >
          <MeasurementSettings/>
        </AccordionElement>
      </RenderIf>
      <RenderIf condition={canEdit3dMeasurements}>
        <AccordionElement
          title='3D'
          isOpen={true}
          infoIcon={Icons.ThreeDView}
        >
          <ThreeDSettings/>
        </AccordionElement>
      </RenderIf>
    </Styled.Container>
  );
};

export const Settings = React.memo(SettingsComponent);
