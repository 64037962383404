import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { PdfSaveFilterResultPayload, PdfSetFilterStatusPayload } from '../actions/payloads/pdf-filter';
import { PdfFilterActionTypes } from '../actions/types/pdf-filter';
import { DrawingsState } from '../interfaces';

export const PdfFilterReducerMethods: ReducerMethods<DrawingsState> = {
  [PdfFilterActionTypes.SAVE_FILE_FILTER]: (s, payload: PdfSaveFilterResultPayload) => {
    if (!s.drawingsInfo[payload.pageId]) {
      return s;
    }
    const helper = new MonoliteHelper(s);
    if (s.currentDrawingInfo?.drawingId === payload.pageId) {
      helper.set(_ => _.currentDrawingInfo.filter, payload.filter);
    }
    return helper
      .set(_ => _.drawingsInfo[payload.pageId].filter, payload.filter)
      .get();
  },
  [PdfFilterActionTypes.SET_STATUS]: (s, { pageId, status }: PdfSetFilterStatusPayload) => {
    return new MonoliteHelper(s)
      .set(_ => _.pageFilterApplyStatus[pageId], status)
      .get();
  },
};

