import {
  ApiRejectInviteFromCompany,
  InvitationCompanyUsers,
} from 'common/interfaces/people';
import { ValuePayload } from 'common/request-payloads/value-payload';
import { CommonApi, CompanyResourcesApi } from '../../api/server';
import * as Payloads from './actions/payloads';
import { PeopleV, Person } from './interfaces/person';

function getCompanyUsers(): Promise<Person[]> {
  return CompanyResourcesApi.getV(`/users`, PeopleV);
}

function inviteUsersToCompany(data: InvitationCompanyUsers): Promise<Person[]> {
  return CompanyResourcesApi.postV(`/users`, data, PeopleV, 'Error while inviting users to company');
}

function inviteUsersToProject(
  projectId: number,
  data: Payloads.InviteToProjectParams,
): Promise<void> {
  return CompanyResourcesApi.post(
    `/projects/${projectId}/users`,
    data,
    'Error while inviting users to project');
}

function deleteCompanyPerson(
  userId: string,
): Promise<Person[]> {
  return CompanyResourcesApi.deleteV(
    `/users/${userId}`,
    PeopleV,
    'delete company person error',
  );
}

function rejectInvitation(
  params: ApiRejectInviteFromCompany,
): Promise<string> {
  return CommonApi.post('/invitations/reject', params);
}

function removeUsersFromProject(
  projectId: number,
  emails: string[],
): Promise<void> {
  return CompanyResourcesApi.delete<void, ValuePayload<string[]>>(
    `/projects/${projectId}/users`,
    { value: emails },
    'Error while removing peopleFromProject');
}

function resendInvitation(userId: string): Promise<void> {
  return CompanyResourcesApi.post(`/users/${userId}/resend`);
}

function updateUserRole(profileId: string, roleId: number): Promise<void> {
  return CompanyResourcesApi.put<void, ValuePayload<number>>(
    `/users/${profileId}/role`,
    { value: roleId });
}

export const PeopleApi = {
  getCompanyUsers,
  inviteUsersToCompany,
  inviteUsersToProject,
  deleteCompanyPerson,
  rejectInvitation,
  removeUsersFromProject,
  resendInvitation,
  updateUserRole,
};
