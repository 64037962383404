import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { getMaxNesting } from 'unit-2d-database/components/breakdown-property/utils';
import { PropertiesTypeGuards } from 'unit-2d-database/helpers';
import { Property } from 'unit-2d-database/interfaces';

export function usePropertyNames(additionalProperties?: string[]): string[] {
  const properties = useSelector<State, Property[]>(s => s.twoDDatabase.properties);

  return useMemo(() => {
    const propertyNames = [];
    if (properties) {
      for (const property of  properties) {
        propertyNames.push(property.name);
        if (PropertiesTypeGuards.isBreakdown(property)) {
          const maxNesting = getMaxNesting(property.value.root);
          for (let nesting = 0; nesting < maxNesting; nesting++) {
            propertyNames.push(`${property.name} {${nesting}}`);
          }
        }
      }
    }
    if (!additionalProperties?.length) {
      return propertyNames;
    }
    arrayUtils.extendArray(propertyNames, additionalProperties);
    return arrayUtils.uniq(propertyNames);
  }, [additionalProperties, properties]);
}
