import * as paper from 'paper';
import { DrawingsCanvasColors, DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';


export const DrawingsSymbolParameters = {
  arrowParams: {
    WIDTH: 10,
    HEIGHT: 6,
  },
  sliderParams: {
    WIDTH: 20,
    HEIGHT: 8,
    border: {
      RADIUS: 5,
      WIDTH: 3,
    },
  },
};

export class DrawingsGeometrySymbolsStore {
  public static arrow: paper.Path;

  private static slider: paper.Path.Rectangle;

  public static get segmentSlider(): paper.Path.Rectangle {
    const slider = this.slider.clone();
    slider.strokeWidth = DrawingsCanvasConstants.infoLinesStroke;
    slider.strokeColor = DrawingsCanvasColors.white;
    slider.fillColor = DrawingsCanvasColors.pointColor;
    return slider;
  }

  public static init(): void {
    this.initArrow();
    this.initSlider();
  }

  private static initArrow(): void {
    const path = new paper.Path([
      new paper.Point(0, 0),
      new paper.Point(DrawingsSymbolParameters.arrowParams.WIDTH, DrawingsSymbolParameters.arrowParams.HEIGHT),
      new paper.Point(DrawingsSymbolParameters.arrowParams.WIDTH, -DrawingsSymbolParameters.arrowParams.HEIGHT),
    ]);
    this.arrow = path;
  }

  private static initSlider(): void {
    const borderRadius = DrawingsSymbolParameters.sliderParams.border.RADIUS;
    const path = new paper.Rectangle(
      new paper.Point(0, 0),
      new paper.Point(DrawingsSymbolParameters.sliderParams.WIDTH, DrawingsSymbolParameters.sliderParams.HEIGHT),
    );
    const radius = new paper.Size(borderRadius, borderRadius);
    this.slider = new paper.Path.Rectangle(path, radius);
  }
}
