import React from 'react';

import { BotMessage } from './bot-message';
import { UserMessage } from './user-message';

interface Props {
  role: string;
  content: string;
  userName: string;
  userAvatar: string;
}

const MessageComponent: React.FC<Props> = ({
  role,
  content,
  userName,
  userAvatar,
}) => {
  if (role === 'system') {
    return (
      <BotMessage
        content={content}
        withButtons={true}
      />
    );
  } else {
    return (
      <UserMessage
        content={content}
        userName={userName}
        userAvatar={userAvatar}
      />
    );
  }
};

export const Message = React.memo(MessageComponent);
