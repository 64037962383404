import { useMemo } from 'react';
import { DemoProject } from 'unit-projects/interfaces/demo-project';

export const useTags = (demoProjects: DemoProject[]): string[] => {
  const tags = useMemo(() => {
    const dictionary = new Set<string>();
    for (const demo of demoProjects) {
      if (demo.tags) {
        demo.tags.forEach((tag) => dictionary.add(tag));
      }
    }
    return Array.from(dictionary);
  }, [demoProjects]);

  return tags;
};
