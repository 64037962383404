export const ColorList = [
  '#ff3f3f',
  '#ff7f3f',
  '#ffbf3f',
  '#ffff3f',
  '#bfff3f',
  '#7fff3f',
  '#3fff3f',
  '#3fff7f',
  '#3fffbf',
  '#3fffff',
  '#3fbfff',
  '#3f7fff',
  '#3f3fff',
  '#7f3fff',
  '#bf3fff',
  '#ff3fff',
  '#ff3fbf',
  '#ff3f7f',
];

export const ExtendColorList = [
  '#ffffff',
  '#6d93bf',
  '#5e7fa5',
  '#4f6b8b',
  '#405671',
  '#314256',
].concat(ColorList);
