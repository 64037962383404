import autobind from 'autobind-decorator';
import * as React from 'react';

import { MultiLevelDropDownMenu } from 'common/components/multi-level-drop-down-menu';
import {
  MultilevelSelectOptionData,
} from 'common/components/multi-level-drop-down-menu/interfaces/multi-level-select-option-data';
import { KreoIconCreateNew, WaitButton } from 'common/UIKit';
import { QtoGenerateReportStatus } from '../../enums/qto-generate-report-status';
import { QtoProjectReport } from '../../interfaces/quantity-take-off';


const CREATE_NEW_TEMPLATE_KEY = 'CREATE_NEW_TEMPLATE_KEY';
const CREATE_TEMPLATE_FROM_REPORT = 'CREATE_TEMPLATE_FROM_REPORT';

interface Props {
  onNewTemplateCreate: () => void;
  className: string;
  onCreateTemplateFromReport: (projectId: number, reportId: number) => void;
  reportsForCreateTemplate: QtoProjectReport[];
  templateCreationStatus: QtoGenerateReportStatus;
  onClickButton: () => void;
}

type SelectionEventHandler = (value: undefined | string | { projectId: number, reportId: number }) => void;

interface Option extends MultilevelSelectOptionData<Option> { }

export class QtoAddTemplateMenu extends React.Component<Props> {
  private selectionHandlers: Record<string, SelectionEventHandler> = {
    [CREATE_NEW_TEMPLATE_KEY]: this.props.onNewTemplateCreate,
    [CREATE_TEMPLATE_FROM_REPORT]: this.onCreateTemplateFromReport,
  };

  public render(): JSX.Element {
    const isWaiting = this.props.templateCreationStatus !== QtoGenerateReportStatus.NoActions;
    return (
      <MultiLevelDropDownMenu
        disabled={isWaiting}
        onSelect={this.onSelect}
        options={this.getOptions()}
        value={null}
        className={this.props.className}
      >
        <WaitButton
          isWaiting={isWaiting}
          caption='Create'
          tooltip='Create Template'
          waitCaption={this.props.templateCreationStatus}
          waitTooltip={`${this.props.templateCreationStatus} template`}
          icon={<KreoIconCreateNew/>}
          size='medium'
          rounded={true}
          mode='submit'
          onClick={this.props.onClickButton}
        />
      </MultiLevelDropDownMenu>
    );
  }

  private getOptions(): Array<MultilevelSelectOptionData<Option>> {
    const { reportsForCreateTemplate } = this.props;
    return [
      {
        name: 'Create New Template',
        value: JSON.stringify({ type: CREATE_NEW_TEMPLATE_KEY }),
        children: null,
        isSelectable: true,
      },
      {
        name: 'Create Template On The Report',
        children: reportsForCreateTemplate.map(x => ({
          name: x.name,
          children: x.reports.map(c => ({
            name: c.name,
            value: JSON.stringify({
              type: CREATE_TEMPLATE_FROM_REPORT,
              value: {
                reportId: c.id,
                projectId: x.id,
              },
            }),
            isSelectable: true,
            children: null,
          })),
          isSelectable: false,
        })),
      },
    ];
  }

  @autobind
  private onCreateTemplateFromReport(value: { projectId: number, reportId: number }): void {
    this.props.onCreateTemplateFromReport(value.projectId, value.reportId);
  }

  @autobind
  private onSelect(value: string): void {
    const selectionValue = JSON.parse(value);
    this.selectionHandlers[selectionValue.type](selectionValue.value);
  }
}
