import { Feed } from 'common/interfaces/feed';
import { objectUtils } from 'common/utils/object-utils';
import { CompanyResourcesApi } from '../../../api/server';
import { ResourceType } from '../enums';
import { RequestFilterModel } from '../interfaces/data';
import { ResourceModel } from '../interfaces/resources-data';
import { ResourceForm } from '../interfaces/resources-rest-data';
import { ResourcesBaseUrlBuilder } from './resources-base-url-builder';


function getResource(databaseId: number, resourceId: number, type: ResourceType): Promise<ResourceModel> {
  const baseUrl = ResourcesBaseUrlBuilder.getResourcesBaseUrl(databaseId, type);
  return CompanyResourcesApi.get(
    `${baseUrl}/${resourceId}`,
    `api get database(${databaseId}) resource(${resourceId}) error`,
  );
}

function getResources(
  databaseId: number, type: ResourceType, filter: RequestFilterModel,
): Promise<Feed<ResourceModel>> {
  const baseUrl = ResourcesBaseUrlBuilder.getResourcesBaseUrl(databaseId, type);
  return CompanyResourcesApi.get(
    `${baseUrl}?${objectUtils.toQueryString(filter)}`,
    `api get database(${databaseId}) ${type} resources  error`,
  );
}

function updateResource(
  databaseId: number, resourceId: number, type: ResourceType, data: ResourceForm,
): Promise<ResourceModel> {
  const baseUrl = ResourcesBaseUrlBuilder.getResourcesBaseUrl(databaseId, type);
  return CompanyResourcesApi.put(`${baseUrl}/${resourceId}`, data);
}

function createResource(databaseId: number, type: ResourceType, data: ResourceForm): Promise<ResourceModel> {
  const baseUrl = ResourcesBaseUrlBuilder.getResourcesBaseUrl(databaseId, type);
  return CompanyResourcesApi.post(baseUrl, data);
}

function deleteResource(databaseId: number, resourceId: number, type: ResourceType): Promise<{}> {
  const baseUrl = ResourcesBaseUrlBuilder.getResourcesBaseUrl(databaseId, type);
  return CompanyResourcesApi.delete(`${baseUrl}/${resourceId}`, null);
}


export const ResourcesApi = {
  updateResource,
  createResource,
  deleteResource,
  getResource,
  getResources,
};
