import React from 'react';

import { SearchZoneButton } from '../../search-zone-button';
import { useSearchingArea } from './use-searching-area';


const WizzardWorkingAreaButtonComponent: React.FC = () => {
  const { area, clearArea, specifySearchingArea } = useSearchingArea();
  return (
    <SearchZoneButton
      hasZone={!!area}
      clearZone={clearArea}
      specifyZone={specifySearchingArea}
    />
  );
};

export const WizzardWorkingAreaButton = React.memo(WizzardWorkingAreaButtonComponent);
