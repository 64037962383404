import { EmptyListNotification } from '@kreo/kreo-ui-components';
import React from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { EmptyListName, LIST_NOTIFICATION } from './constants';
import { Styled } from './styled';

export interface Props {
  type: EmptyListName;
}

export const DatabaseEmptyList: React.FC<Props> = ({ type }) => {
  const { headerText, description, linkText, linkId } = LIST_NOTIFICATION[type];

  return (
    <Styled.EmptyListNotification>
      <EmptyListNotification
        headerText={headerText}
        description={description}
        linkText={linkText}
        linkId={linkId}
        onClick={ConstantFunctions.doNothing}
      />
    </Styled.EmptyListNotification>
  );
};
