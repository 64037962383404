import { PdfHelper } from './pdf-helper';

export class PdfReaders {
  private static queue = [];
  private static inProgress: boolean = false;

  public static convertDocToCore(pdfDocument: Core.PDFNet.PDFDoc, filename: string): Promise<Core.Document>  {
    return PdfReaders.addToQueue(Core.createDocument(pdfDocument, { licenseKey: PdfHelper.key, filename }));
  }

  public static async readArrayBuffer(
    arrayBuffer: ArrayBuffer,
  ): Promise<Core.PDFNet.PDFDoc> {
    return PdfReaders.addToQueue(Core.PDFNet.PDFDoc.createFromBuffer(arrayBuffer));
  }

  public static async readArrayBufferAsCore(
    arrayBuffer: ArrayBuffer,
    filename: string,
  ): Promise<Core.Document> {
    return PdfReaders.addToQueue(PdfReaders.readArrayBufferAsCoreInternal(arrayBuffer, filename));
  }

  private static async addToQueue<T>(method: Promise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      PdfReaders.queue.push(async () => {
        try {
          const result = await method;
          resolve(result);
        } catch (e) {
          reject(e);
        }
      });
      if (!PdfReaders.inProgress) {
        PdfReaders.runNext();
      }
    });
  }

  private static async runNext(): Promise<void> {
    const method = PdfReaders.queue.shift();
    PdfReaders.inProgress = true;
    await method();
    if (PdfReaders.queue.length) {
      await PdfReaders.runNext();
    } else {
      PdfReaders.inProgress = false;
    }
  }

  private static async readArrayBufferAsCoreInternal(
    arrayBuffer: ArrayBuffer,
    filename: string,
  ): Promise<Core.Document> {
    const doc = await Core.PDFNet.PDFDoc.createFromBuffer(arrayBuffer);
    return PdfReaders.convertDocToCore(doc, filename);
  }
}
