import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { DrawingsGeometryInstance, ShortPointDescription } from '../../interfaces';

export function useInstanceAndPoints():
[instances: Record<string, DrawingsGeometryInstance>, points: Record<string, ShortPointDescription>] {
  const instances = useSelector<State, Record<string, DrawingsGeometryInstance>>(
    state => state.drawings.aiAnnotation.geometry);
  const points = useSelector<State, Record<string, ShortPointDescription>>(
    state => state.drawings.aiAnnotation.points);
  return [instances, points];
}
