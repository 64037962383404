const Colors = [
  '#FFCFCF',
  '#FFCC99',
  '#F2EF8A',
  '#CBED87',
  '#9EF0BF',
  '#8EE6E6',
  '#96E6FA',
  '#B0D3F7',
  '#B9B8FC',
  '#E5B9FA',
  '#F2D5E1',
  '#EFC8B6',
  '#C3DAE6',
];

function getColors<T extends { name: string }>(data: T[]): Array<{ color: string, name: string }> {
  return [...data].sort((x, y) => (x.name > y.name ? 1 : -1)).map((x, i) => {
    return {
      color: Colors[i % Colors.length],
      name: x.name,
    };
  });
}

export const DashboardUtil = {
  Colors,
  getColors,
};
