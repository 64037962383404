export function calcYPos(el: any): any {
  if (el === window) return 0;
  let y = 0;

  while (el) {
      y += el.offsetTop;
      el = el.offsetParent;
  }

  return y;
}