import autobind from 'autobind-decorator';
import * as paper from 'paper';
import {
  DrawingsGeometryInstance,
  DrawingsGeometryUtils,
} from 'common/components/drawings';
import { DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import {
  ContextObserver,
  ContextObserverWithPrevious,
} from 'common/components/drawings/drawings-contexts';
import { DrawingsDrawMode } from 'common/components/drawings/enums/drawings-draw-mode';
import { DrawingsInstanceType } from 'common/components/drawings/enums/drawings-instance-type';
import { DrawingsSegment } from 'common/components/drawings/interfaces';
import { ShortPointDescription } from 'common/components/drawings/interfaces/drawing-ai-annotation';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import {
  DrawingsAllowedPathType,
  DrawingsGeometryStrokedType,
  DrawingsPolygonGeometry,
  DrawingsPolylineGeometry,
} from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingAnnotationUtils } from 'common/components/drawings/utils/drawing-annotation-utils';
import { DrawingsGeometryConverters } from 'common/components/drawings/utils/drawings-geometry-converters';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { PaperIntersectionUtils, PaperPointUtils } from 'common/components/drawings/utils/paper-utils';
import { arrayUtils } from 'common/utils/array-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { EngineObjectConfig } from '../../common';
import { InstanceWithTempPoint } from '../../drawings-geometry-entities/temporary';
import {
  BatchedUpdateCallback,
  Completable,
  GetColorOfInstanceCallback,
  GetCurrentDrawingCallback,
  GetDrawingInstanceCallback,
  GetInstanceMeasureCallback,
  GetPointCoordinatesCallback,
  SavePointsToCacheCallback,
  SetDrawModeCallback,
  ToolMode,
} from '../../interfaces';
import { DrawingsOperationResultsPostProcessors } from '../common';
import { DrawingsOperationHelper } from '../common/operation-helper';
import { Intersection } from './interfaces';
import { KnifeLine } from './knife-line';
import { findContourKnifeIntersection } from './utils';

export interface DrawingsKnifeHelperConfig extends EngineObjectConfig {
  layer: paper.Layer;
  textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
  getInstance: GetDrawingInstanceCallback;
  getEntity: (instanceId: string) => DrawingsAllowedPathType;
  renderParamsObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  getPointCoordinates: GetPointCoordinatesCallback;
  getInstanceMeasures: GetInstanceMeasureCallback;
  getCurrentDrawing: GetCurrentDrawingCallback;
  addPointsToCached: SavePointsToCacheCallback;
  changeDrawMode: SetDrawModeCallback;
  onUpdate: BatchedUpdateCallback;
  getColorOfInstances: GetColorOfInstanceCallback;
}

export interface ProcessResult {
  geometries: DrawingsGeometryStrokedType[];
  pointIds: Record<string, ShortPointDescription>;
}

function isSimpleIntersections(
  intersections: Intersection[] | [Intersection, Intersection],
): intersections is [Intersection, Intersection] {
  return intersections.length === 2;
}

export class DrawingsKnifeHelper extends DrawingsOperationHelper<DrawingsKnifeHelperConfig>
  implements InstanceWithTempPoint, Completable, ToolMode {

  private _entityPath: DrawingsAllowedPathType;
  private _instance: DrawingsGeometryInstance<DrawingsGeometryStrokedType>;
  private _line: KnifeLine;


  public get hasPoints(): boolean {
    return this._line && !!this._line.path.segments.length;
  }

  public get isActive(): boolean {
    return !!this._instance;
  }

  public removeLastPoint(): void {
    this._line.removeLastPoint();
    if (this._line.path.segments.length) {
      this._line.destroy();
      this._line = null;
    }
  }

  public canComplete(addLastPoint: boolean): boolean {
    if (!this._line) {
      return false;
    }
    const path = this._line.path;
    if (addLastPoint) {
      if (this._line.isError || !this.validatePoint(path.lastSegment.point)) {
        return false;
      }
    } else if (path.segments.length < 2 || !this.validatePoint(path.segments[path.segments.length - 2].point)) {
      return false;
    }
    let lastSegmentIndex = path.segments.length - (addLastPoint ? 1 : 2);
    if (!lastSegmentIndex) {
      lastSegmentIndex = 1;
    }
    const entityPath = this._entityPath;
    const contour = DrawingsGeometryUtils.isClosedContour(this._instance.type, this._instance.geometry)
      ? entityPath.children ? (entityPath.firstChild as paper.Path).segments : entityPath.segments
      : entityPath.segments;
    for (let i = 1; i <= lastSegmentIndex; i++) {
      const currentPoint = path.segments[i].point;
      const prevPoint = path.segments[i - 1].point;
      for (const [start, end] of DrawingAnnotationUtils.iteratePoints(contour, this._instance.type)) {
        if (!end) {
          continue;
        }
        if (PaperIntersectionUtils.getLinesIntersection(currentPoint, prevPoint, start.point, end.point)) {
          return true;
        }
      }
    }
    return false;
  }

  public clearLine(): void {
    if (this._line) {
      this._line.destroy();
      this._line = null;
    }
  }

  public activate(): void {
    const [instanceId] = this.instancesForProcess;
    this._entityPath = this._config.getEntity(instanceId);
    this._instance = this._config.getInstance(instanceId) as DrawingsGeometryInstance<DrawingsGeometryStrokedType>;
  }

  public deactivate(): void {
    this.clearLine();
    if (this._entityPath) {
      this._entityPath.remove();
      this._entityPath = null;
    }
    this._instance = null;
  }

  @autobind
  public addLinePoint(point: paper.Point): void {
    if (this._line) {
      if (!this._line.isError) {
        this._line.addPoint(point);
      }
    } else if (this.validatePoint(point)) {
      this._line = new KnifeLine({
        point,
        layer: this._config.layer,
        renderParametersContextObserver: this._config.renderParamsObserver,
      });
    }
  }

  public finish(addLastPoint: boolean): void {
    const type = this._instance.type;
    if (this._line.points.length !== 2 && !addLastPoint) {
      this._line.removeLastPoint();
    }
    const [baseInstanceId] = this.instancesForProcess;
    const {
      geometries,
      pointIds,
    } = type === DrawingsInstanceType.Polyline ? this.splitPolyline() : this.splitPolygon();


    const {
      changes,
      oldMeasures,
      newMeasures,
    } = DrawingsOperationResultsPostProcessors.processStrokedGeometryResultAsEdit(
      baseInstanceId,
      this._instance,
      pointIds,
      geometries,
      [],
      this._config.getCurrentDrawing().drawingId,
      this._config.getPointCoordinates,
      this._config.getInstanceMeasures,
      this._config.textRenderParamsObserver.getContext(),
      this._config.getColorOfInstances,
    );
    this._config.addPointsToCached(changes.newPoints);
    changes.newIdsToSource = changes.addedInstances.reduce((acc, x) => {
      acc[x.id] = baseInstanceId;
      return acc;
    }, {});
    this._config.onUpdate(changes, newMeasures, oldMeasures);
    this.clearLine();
    this._config.changeDrawMode(DrawingsDrawMode.Disabled);
  }

  public updateTempPointPosition(point: paper.Point, strictAngle?: boolean): paper.Point {
    if (this._line) {
      return this._line.updateTempPointPosition(point, strictAngle);
    }
    return null;
  }

  private validatePoint(point: paper.Point): boolean {
    const { type, geometry } = this._instance;
    if (type === DrawingsInstanceType.Polyline) {
      return true;
    } else if (DrawingsGeometryUtils.isClosedContour(type, geometry)) {
      const entityPath = this._entityPath;
      const path = (geometry.children ? entityPath.firstChild : entityPath) as paper.Path;
      const pointsIterator = DrawingAnnotationUtils.iteratePathPoints(path, type);
      return !DrawingsPaperUtils.isPointInside(point, pointsIterator)
        || path.segments.some(x => PaperPointUtils.arePointsEqual(x.point, point));
    }
  }

  private splitPolyline(): ProcessResult {
    const entityPath = this._entityPath as paper.Path;
    const lines = new Array<paper.Point[]>();
    let pathPoints = new Array<paper.Point>();
    const savePolyline = (intersectionPoint: paper.Point): void => {
      pathPoints.push(intersectionPoint);
      if (!pathPoints.every(p => PaperPointUtils.arePointsEqual(pathPoints[0], p))) {
        lines.push(pathPoints);
      }
      pathPoints = [intersectionPoint];
    };
    for (let i = 0; i < entityPath.segments.length - 1; i++) {
      const start = entityPath.segments[i].point;
      const end = entityPath.segments[i + 1].point;
      pathPoints.push(entityPath.segments[i].point);
      const segmentIntersections = new Array<paper.Point>();
      for (const knife of DrawingAnnotationUtils.iteratePathPoints(this._line.path, DrawingsInstanceType.Polyline)) {
        const point = PaperIntersectionUtils.getLinesIntersection(start, end, knife[0], knife[1]);
        if (point) {
          segmentIntersections.push(point);
        }
      }
      if (segmentIntersections.length) {
        const sorted = PaperPointUtils.sortPointsByDestination(start, segmentIntersections);
        for (const intersectionPoint of sorted) {
          savePolyline(intersectionPoint);
        }
      }
    }
    savePolyline(entityPath.lastSegment.point);

    const points: Record<string, ShortPointDescription> = {};
    const geometries = [];
    const color = entityPath.strokeColor.toCSS(true);
    for (const polyline of lines) {
      const geometry: DrawingsPolylineGeometry = {
        points: this.processPoints(polyline, points),
        color,
        strokeStyle: DrawingStrokeStyles.Normal,
        strokeWidth: 4,
      };
      geometries.push(geometry);
    }

    return { pointIds: points, geometries };
  }

  private splitPolygon(): ProcessResult {
    const entityPath = this._entityPath;
    const path = entityPath.children ? entityPath.firstChild : entityPath;
    const lineSegments = [
      ...DrawingAnnotationUtils.iteratePathPoints(this._line.path, DrawingsInstanceType.Polyline),
    ];

    const sourceContour = (path as paper.Path).segments.map(x => x.point);
    if (PaperPointUtils.arePointsEqual(sourceContour[0], sourceContour[sourceContour.length - 1])) {
      sourceContour.pop();
    }
    const contours = this.splitContourByPolyline(lineSegments, [sourceContour]);
    let pointIds: Record<string, ShortPointDescription> = {};
    const geometries = new Array<DrawingsPolygonGeometry>();
    const color = entityPath.strokeColor.toCSS(true);
    if (entityPath.children) {
      for (const contour of this.contoursIterator(contours)) {
        let contourPath: DrawingsAllowedPathType = new paper.Path(contour);
        for (let j = 1; j < entityPath.children.length; j++) {
          contourPath = contourPath.subtract(entityPath.children[j] as paper.Path) as DrawingsAllowedPathType;
        }

        if (DrawingsPaperUtils.isCompoundPolygonPath(contourPath)) {
          contourPath.children.sort((a, b) => Math.abs(a.area) - Math.abs(b.area));
          if (!contourPath.firstChild.segments.length) {
            continue;
          }

          const { geometriesIterator, newPoints } = DrawingsGeometryConverters.convertPathToPolygons(
            contourPath,
            {},
            {
              color,
              strokeWidth: this._instance.geometry.strokeWidth,
              strokeStyle: this._instance.geometry.strokeStyle,
            },
          );

          pointIds = { ...pointIds, ...newPoints };
          arrayUtils.extendArray(geometries, arrayUtils.mapIterator(geometriesIterator, x => x[1]));
        } else {

          if (!contourPath.segments.length) {
            continue;
          }
          if (!contourPath.clockwise) {
            contourPath.reverse();
          }
          const geometry: DrawingsPolygonGeometry = {
            points: this.processPoints(contourPath.segments.map(x => x.point), pointIds),
            color,
            strokeStyle: this._instance.geometry.strokeStyle,
            strokeWidth: this._instance.geometry.strokeWidth,
          };
          geometries.push(geometry);
        }

      }
    } else {
      for (const contour of this.contoursIterator(contours)) {
        const geometry: DrawingsPolygonGeometry = {
          points: this.processPoints(contour, pointIds),
          color,
          strokeStyle: this._instance.geometry.strokeStyle,
          strokeWidth: this._instance.geometry.strokeWidth,
        };
        geometries.push(geometry);
      }
    }
    return { pointIds, geometries };
  }

  private *contoursIterator(contours: paper.Point[][]): IterableIterator<paper.Point[]> {
    for (const contour of contours) {
      const filtered = arrayUtils.uniqWith(contour, p => `${p.x}-${p.y}`);
      if (filtered.length < 3) {
        continue;
      }
      yield filtered;
    }
  }

  private splitContourByPolyline(
    line: Array<DrawingsSegment<paper.Point>>,
    sourceContours: paper.Point[][],
  ): paper.Point[][] {
    let contours = sourceContours.slice();
    const knifeSegments = line.reverse();
    while (knifeSegments.length) {
      const knifeSegment = knifeSegments.pop();
      const knifeSegmentId = UuidUtil.generateUuid();
      const newContours = new Array<paper.Point[]>();
      for (const contour of contours) {
        const contourSegments = [...DrawingAnnotationUtils.iteratePoints(contour, DrawingsInstanceType.Polygon)];
        const [intersections, intersectionPath] = findContourKnifeIntersection(
          contourSegments,
          knifeSegment,
          knifeSegmentId,
        );
        if (intersections.length) {
          if (intersections.length > 1) {
            const intersectionSegment = intersectionPath.splice(0, 2);
            arrayUtils.extendArray(
              newContours,
              this.splitContour(
                contourSegments,
                intersectionSegment,
                intersections.splice(0, 2) as [Intersection, Intersection],
              ),
            );
            if (intersections.length) {
              knifeSegments.push([intersections[0].point.add(intersectionSegment[1]).divide(2), knifeSegment[1]]);
            }
          } else {
            this.searchPairIntersection(knifeSegments, contourSegments, intersections, intersectionPath);
            if (intersections.length) {
              const c = this.splitContour(contourSegments, intersectionPath, intersections);
              arrayUtils.extendArray(newContours, c);
            } else {
              newContours.push(contour);
            }
          }
        } else {
          newContours.push(contour);
        }
      }
      contours = newContours;
    }
    return contours;
  }

  private searchPairIntersection(
    knifeSegments: Array<DrawingsSegment<paper.Point>>,
    contourSegments: Array<DrawingsSegment<paper.Point>>,
    intersections: Intersection[],
    intersectionPath: paper.Point[],
  ): void {
    let lastSegmentIndex = knifeSegments.length - 1;
    const resultSegmentIntersections = new Array<Intersection>();
    while (intersections.length % 2 !== 0) {
      const knifePoints = knifeSegments[lastSegmentIndex];
      if (!knifePoints) {
        break;
      }
      intersectionPath.push(knifePoints[0]);
      const sId = UuidUtil.generateUuid();
      for (let i = 0; i < contourSegments.length; i++) {
        const [s, e] = contourSegments[i];
        const intersectionPoint = PaperIntersectionUtils.getLinesIntersection(knifePoints[0], knifePoints[1], s, e);
        if (intersectionPoint && !intersections.some(x => PaperPointUtils.arePointsEqual(x.point, intersectionPoint))) {
          resultSegmentIntersections.push({ index: i, point: intersectionPoint, knifePoints, knifeSegmentId: sId });
        }
      }
      if (resultSegmentIntersections.length) {
        const sortedPath = PaperPointUtils.sortPointsByDestination(
          knifePoints[0],
          resultSegmentIntersections.map(x => x.point),
        );
        intersections.push(resultSegmentIntersections[0]);
        intersectionPath.push(sortedPath[0]);
        break;
      }
      lastSegmentIndex--;
    }
    if (lastSegmentIndex !== -1) {
      if (resultSegmentIntersections.length > 1) {
        const nextIntersection = resultSegmentIntersections[1];
        const newSegment: DrawingsSegment<paper.Point> = [nextIntersection.point, nextIntersection.knifePoints[1]];
        knifeSegments.splice(lastSegmentIndex);
        knifeSegments.push(newSegment);
      } else {
        knifeSegments.splice(lastSegmentIndex);
      }
    }
    if (intersections.length === 1) {
      intersections.pop();
    }
  }

  private splitContour(
    contourPoints: Array<[paper.Point, paper.Point]>,
    intersectionPath: paper.Point[],
    intersections: Intersection[],
  ): paper.Point[][] {
    if (isSimpleIntersections(intersections)) {
      return this.splitBySimpleIntersection(contourPoints, intersectionPath, intersections);
    } else {
      return [];
    }
  }

  private isNeedToReverseRightContour(
    intersections: [Intersection, Intersection],
    contourSegments: Array<[paper.Point, paper.Point]>,
  ): boolean {
    const [start, end] = intersections;
    const startSegment = contourSegments[start.index];
    if (start.index === end.index) {
      return startSegment[0].subtract(start.point).length > startSegment[0].subtract(end.point).length;
    }
    return start.index > end.index;
  }

  private splitBySimpleIntersection(
    contourSegments: Array<[paper.Point, paper.Point]>,
    intersectionPath: paper.Point[],
    intersections: [Intersection, Intersection],
  ): paper.Point[][] {
    const leftContour = intersectionPath.slice();
    const rightContour = intersectionPath.slice();
    let [start, end] = intersections;

    if (this.isNeedToReverseRightContour(intersections, contourSegments)) {
      [end, start] = [start, end];
      rightContour.reverse();
    } else {
      leftContour.reverse();
    }

    for (let i = start.index; i < end.index; i++) {
      leftContour.push(contourSegments[i][1]);
    }

    for (let i = end.index; i < contourSegments.length; i++) {
      rightContour.push(contourSegments[i][1]);
    }

    if (start.index === end.index) {
      if (start.index !== 0) {
        for (let i = 0; i <= start.index; i++) {
          rightContour.push(contourSegments[i][0]);
        }
      }
    } else {
      for (let i = 0; i < start.index; i++) {
        rightContour.push(contourSegments[i][1]);
      }
    }
    return [leftContour, rightContour];
  }

  private processPoints(
    contour: paper.Point[],
    pointIds: Record<string, ShortPointDescription>,
  ): string[] {
    const points = [];
    for (const { x, y } of contour) {
      const pointId = UuidUtil.generateUuid();
      pointIds[pointId] = [x, y];
      points.push(pointId);
    }
    return points;
  }
}
