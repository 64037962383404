import autobind from 'autobind-decorator';
import * as React from 'react';

import { Checkbox, MaterialMenuItem } from 'common/UIKit';


interface Props {
  name: string;
  id: string;
  onChangeReportType: (id: string) => void;
  selected: boolean;
}

const itemStyle: React.CSSProperties = { height: 30, minHeight: 30 };

export class SelectReportTypeItem extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <MaterialMenuItem
        value={this.props.id}
        style={itemStyle}
      >
        <Checkbox
          onChange={this.onCheck}
          checked={this.props.selected}
          label={this.props.name}
        />
      </MaterialMenuItem>
    );
  }

  @autobind
  private onCheck(_value: boolean, event: React.MouseEvent<HTMLLabelElement>): void {
    event.stopPropagation();
    this.props.onChangeReportType(this.props.id);
  }
}
