import autobind from 'autobind-decorator';

import { arrayUtils } from 'common/utils/array-utils';
import { BimRecordDataApi } from '../../api/bim-record-data/api';

export class MapIdHelper {
  private bimIdsToEngineId: Record<number, number> = {};
  private engineIdToBimIds: Record<number, number[]> = {};

  public async init(projectId: number): Promise<void> {
    const records = await BimRecordDataApi.getLayerDecomposition(projectId);

    records.forEach(record => {
      this.engineIdToBimIds[record.elementId] = record.layerIds;
      record.layerIds.forEach(layerId => {
        this.bimIdsToEngineId[layerId] = record.elementId;
      });
    });
  }

  @autobind
  public *mapEngineIdToBimIdIterator(engineIds: number[]): IterableIterator<number> {
    for (const engineId of engineIds) {
      if (this.engineIdToBimIds[engineId] === undefined) {
        yield engineId;
      } else {
        yield *this.engineIdToBimIds[engineId];
      }
    }
  }

  @autobind
  public mapEngineIdsToBimIds(enginId: number[]): number[] {
    const bimIds = [];
    enginId.forEach(id => this.engineIdToBimIds[id] !== undefined
      ? arrayUtils.extendArray(bimIds, this.engineIdToBimIds[id])
      : bimIds.push(id),
    );
    return bimIds;
  }

  @autobind
  public mapBimIdsToEngineIds(bimId: number[]): number[] {
    const ids = [];
    const addedIds = {};
    bimId.forEach(id => {
      const enginId = this.mapBimIdToEngineId(id);
      if (!addedIds[enginId]) {
        ids.push(enginId);
        addedIds[enginId] = true;
      }
    });
    return ids;
  }

  @autobind
  public mapBimIdToEngineId(bimId: number | string): number {
    // todo: https://kreosoftware.atlassian.net/browse/KREOP-10264
    const id = Number(bimId);
    return Number.isFinite(this.bimIdsToEngineId[id]) ? this.bimIdsToEngineId[id] : id;
  }

}
