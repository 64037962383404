import { Icons, LinkComponent  } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';


import { RowData } from 'common/components/data-base-table';
import { DataBaseSelectorTable } from 'common/components/database-selector-table';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { mapProperties } from '../../../../helpers';
import { Property, User } from '../../../../interfaces';
import { Styled } from './styled';

interface StateToProps {
  properties: Property[];
  users: User[];
}

interface Props extends StateToProps {
  onSelectProperties: (ids: string[]) => void;
  selectedIdsMap: Record<string, boolean>;
  showCollapseButton?: boolean;
  autoGroupColumnMinWidth?: number;
  hideTable?: boolean;
}

interface OwnState {
  rowData: RowData[];
}

class SelectPropertyPanelComponent extends React.PureComponent<Props, OwnState> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      rowData: [],
    };
  }

  public componentDidMount(): void {
    this.updateRowData();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.properties !== prevProps.properties || this.props.users !== prevProps.users) {
      this.updateRowData();
    }
  }

  public render(): JSX.Element {
    const { autoGroupColumnMinWidth, hideTable } = this.props;
    return (
      <Styled.Container>
        <DataBaseSelectorTable
          rowData={this.state.rowData}
          autoGroupName={'Properties'}
          onSelect={this.onSelect}
          selectedEntityMap={this.props.selectedIdsMap}
          fieldForQuickSearch={[]}
          autoGroupColumnMinWidth={autoGroupColumnMinWidth}
          hideTable={hideTable}
        />
        <RenderIf condition={this.props.showCollapseButton}>
          <Styled.CollapseButton>
            <LinkComponent
              onClick={this.collapsePropertiesPanel}
              Icon={Icons.Right}
              text={'Collapse'}
            />
          </Styled.CollapseButton>
        </RenderIf>
      </Styled.Container>
    );
  }

  private updateRowData(): void {
    const { users, properties } = this.props;
    const rowData = mapProperties(properties, users);
    this.setState({ rowData });
  }

  @autobind
  private onSelect(ids: string[]): void {
    this.props.onSelectProperties(ids);
  }

  @autobind
  private collapsePropertiesPanel(): void {
    this.props.onSelectProperties(null);
  }
}

const mapStateToProps = (state: State): StateToProps => {
  return {
    properties: state.twoDDatabase.properties,
    users: state.people.companiesUsers as User[],
  };
};

export const SelectPropertyPanel = connect(mapStateToProps)(SelectPropertyPanelComponent);
