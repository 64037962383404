import * as React from 'react';
import { connect } from 'react-redux';
import { State } from 'common/interfaces/state';

import { ConfirmationDialog } from '../../../../../components/dialog/confirmation-dialog';
import { DrawingsFile, DrawingsFolderViewInfo } from '../../interfaces/drawings-file-info';

export const DRAWING_DELETE_FILE_CONFIRMATION_DIALOG = 'DRAWING_DELETE_FILE_CONFIRMATION_DIALOG';

interface StateProps {
  data: Array<DrawingsFile | DrawingsFolderViewInfo>;
}

interface OwnProps {
  onSubmit: (data: Array<DrawingsFile | DrawingsFolderViewInfo>) => void;
}

type Props = OwnProps & StateProps;

class DrawingsDeleteFileConfirmationDialogComponent extends React.Component<Props> {

  public render(): React.ReactNode {
    const { onSubmit } = this.props;
    const dialogText =
      `The ${this.getDataNames()} will be removed immediately. This action can’t be undone.`;

    return (
      <ConfirmationDialog
        name={DRAWING_DELETE_FILE_CONFIRMATION_DIALOG}
        title='Are you sure you want to delete it?'
        text={dialogText}
        confirmButtonText='Delete'
        onConfirm={onSubmit}
        cancelButtonText='Cancel'
      />
    );
  }

  private getDataNames(): string {
    if (!this.props.data) {
      return '';
    }
    return this.props.data.map(d => `"${d.properties.name}"`).join(', ');
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    data: state.dialog[DRAWING_DELETE_FILE_CONFIRMATION_DIALOG],
  };
}

export const DrawingsDeleteFileConfirmationDialog =
  connect(mapStateToProps)(DrawingsDeleteFileConfirmationDialogComponent);
