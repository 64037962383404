import * as React from 'react';

import {
  DrawingsLayoutApiContext,
  WithDrawingsLayoutApi,
} from './drawings-layout-api-context';

export function withDrawingsLayoutApiContext<P>(
  Component: React.ComponentType<P & WithDrawingsLayoutApi>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof WithDrawingsLayoutApi>>>  {
  return class WithDrawingsLayoutApiContext extends React.PureComponent<P> {
    public render(): React.ReactNode {
      return (
        <DrawingsLayoutApiContext.Consumer>
          {
            contextProps => (
              <Component
                {...this.props}
                drawingsLayoutApi={contextProps}
              />
            )
          }
        </DrawingsLayoutApiContext.Consumer>
      );
    }
  };
}
