// todo: Юзать вместо input-select при избавлении от индексов
import React from 'react';

import { useInputHandler } from './hooks';
import { SearchItemsWithDropdownList } from './search-items-with-dropdown-list';


interface CommonProps {
  elements: string[];
  activeElement: string;
  onClick: (value: string) => void;
  getElementLabel?: (value: string) => string;
  isCollaps?: boolean;
  withSearch?: boolean;
  inputId?: string;
  isShowArrow?: boolean;
  placeholder?: string;
  placeholderAsValue?: boolean;
}

interface WithCreateNew extends CommonProps {
  createNewElement: true;
  onBlur: (element: string) => void;
  value: string | undefined;
}

interface SingleSelectWithSearch extends CommonProps {
  createNewElement: false;
  onBlur?: undefined;
  value?: undefined;
}

type Props = WithCreateNew | SingleSelectWithSearch;

export const InputSelectNoIndex: React.FC<Props> = ({
  elements,
  activeElement,
  value,
  isCollaps,
  inputId,
  createNewElement,
  isShowArrow,
  onClick,
  onBlur,
  getElementLabel,
  placeholder,
  placeholderAsValue,
}) => {
  const handleSelect = React.useCallback((element: string) => {
    const includes = elements.includes(element);
    if (!includes) {
      if (createNewElement) {
        onBlur(element);
      }
      return;
    }
    onClick(element);
  }, [onClick, elements, onBlur]);

  const {
    isDropdownOpen,
    openDropdown,
    closeDropdown,
    onClickDropDownTextItem,
  } = useInputHandler(handleSelect);

  const activeElementValue = value === undefined || value === null
    ? activeElement
    : value;


  return (
    <SearchItemsWithDropdownList
      isDropdownOpen={isDropdownOpen}
      openDropdown={openDropdown}
      closeDropdown={closeDropdown}
      activeElement={activeElementValue}
      elements={elements}
      onClickDropDownTextItem={onClickDropDownTextItem}

      createNewElement={createNewElement}
      withSearch={true}
      isCollaps={isCollaps}
      isShowArrow={isShowArrow}

      inputId={inputId}
      getElementLabel={getElementLabel}
      placeholder={placeholder}
      placeholderAsValue={placeholderAsValue}
    />
  );
};
