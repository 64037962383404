import { Text } from '@kreo/kreo-ui-components';
import React, { useCallback, useState } from 'react';

import { RadioButton } from 'common/components/radion-button';
import { QuestionnaireContent, ReplyType } from '../questionnaire';
import { Styled } from './styled';


interface Props {
  questionContent: QuestionnaireContent[];
  replies: string[];
  content: ReplyType;
  id: number;
  onClick: (value: string[]) => void;
  setQuestionContent: (value: QuestionnaireContent[]) => void;
}

export const ReplyComponent: React.FC<Props> = ({
  content,
  id,
  questionContent,
  replies,
  onClick,
  setQuestionContent,
}) => {
  const { reply } = content;
  const [check, setChecked] = useState(false);
  const [answers, setAnswers] = useState(replies);

  const onReplyClick = useCallback(() => {
    setChecked(true);
    if (!questionContent[id].isChecked) {
      const newAnswers = [...replies];
      newAnswers[id] = reply;
      setAnswers(newAnswers);
      onClick(newAnswers);
    }
    const newContent = [...questionContent];
    newContent[id] = { ...questionContent[id], isChecked: true };
    setQuestionContent(newContent);
  }, [id, onClick, questionContent, replies, reply, setQuestionContent]);

  const isCurrentAnswer = reply === answers[id];

  return !check ? (
    <Styled.ReplyContainer
      onClick={onReplyClick}
      isCurrentAnswer={isCurrentAnswer}
    >
      <Styled.CheckBoxContainer isCurrentAnswer={isCurrentAnswer}>
        <RadioButton onClick={onReplyClick} checked={check} />
      </Styled.CheckBoxContainer>
      <Styled.TextContainer>
        <Text withEllipsis={true}>{reply}</Text>
      </Styled.TextContainer>
    </Styled.ReplyContainer>
  ) : (
    <Styled.TextContainer>
      <Text withEllipsis={true}>{reply}</Text>
    </Styled.TextContainer>
  );
};

export const Reply = React.memo(ReplyComponent);
