import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import { change } from 'redux-form';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseActivityActions } from '../../actions/creators/database-activity';
import { DatabaseActivityListingActions } from '../../actions/creators/database-activity-listing';
import { DatabaseActivityVariantActions } from '../../actions/creators/database-activity-variant';
import { ModifyDatabaseEntityMode } from '../../enums';
import { ActivityModel, ActivityVariantModel, ExtractorFunctionModel, UnitModel } from '../../interfaces/data';
import { ActivityVariantForm } from '../../interfaces/rest-data';
import { ChangeFormHelper } from '../../utils/change-form-helper';
import { RestFormMapper } from '../../utils/rest-form-mapper';
import { DatabaseActivityVariant } from '../database-activity-variant';
import { DatabaseActivityVariantTotalCost } from '../database-activity-variant-total-cost';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';

interface ReduxProps {
  activity: ActivityModel;
  activityId: number;
  activityVariant: ActivityVariantModel;
  activityVariantId: number;
  databaseId: number;
  functions: ExtractorFunctionModel[];
  form: ActivityVariantForm;
  unitMap: Record<number, UnitModel>;
}

interface ReduxActions {
  setEditActivityVariantModel: (activityVariant: ActivityVariantModel) => void;
  loadActivity: (databaseId: number, activityId: number) => void;
  loadActivityVariant: (databaseId: number, activityId: number, variantId: number) => void;
  updateActivityVariant: (databaseId: number, activityId: number, variantId: number, form: ActivityVariantForm) => void;
  applyFieldChanges: (field: string, value: any) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps {
}

interface State {
  initialValues: ActivityVariantForm;
}

const formId: string = 'edit-activity-variant';
class EditDatabaseActivityVariantComponent extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { initialValues: null };
  }

  public componentDidMount(): void {
    const { databaseId, activityId, activityVariantId } = this.props;
    this.props.loadActivity(databaseId, activityId);
    this.props.loadActivityVariant(databaseId, activityId, activityVariantId);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (
      (prevProps.activityVariant === null || prevProps.activity === null) &&
      this.props.activityVariant !== null && this.props.activity !== null
    ) {
      const { activityVariant, functions } = this.props;
      this.setState({ initialValues: RestFormMapper.GetActivityVariantForm(activityVariant, functions) });
    }
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Activity Variant'
        isApplyButtonDisabled={this.isSaveButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Edit}
        onSubmit={this.save}
        className='database-entity-modify-panel--has-total-cost'
        readonly={this.props.readonly}
        form={formId}
        initialValues={this.state.initialValues}
      >
      {
        this.props.activity && this.props.activityVariant ? (
          <React.Fragment>
            <KreoScrollbars>
              <DatabaseActivityVariant
                activityVariant={this.props.activityVariant}
                onChange={this.onActivityVariantChange}
                readonly={this.props.readonly}
              />
            </KreoScrollbars>
            <DatabaseActivityVariantTotalCost
              databaseId={this.props.databaseId}
              activity={this.props.activity}
              activityVariant={this.props.activityVariant}
              unitMap={this.props.unitMap}
            />
          </React.Fragment>
        ) : <SvgSpinner size='large'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }

  @autobind
  private save(values: ActivityVariantForm): void {
    const { databaseId, activityId, activityVariantId } = this.props;
    this.props.updateActivityVariant(databaseId, activityId, activityVariantId, values);
  }

  private isSaveButtonDisabled(): boolean {
    const { activityVariant } = this.props;
    return !activityVariant ||
      !activityVariant.name ||
      !activityVariant.name.trim() ||
      !activityVariant.extractorFunction ||
      !activityVariant.unitId;
  }

  @autobind
  private onActivityVariantChange(variant: ActivityVariantModel): void {
    const form = RestFormMapper.GetActivityVariantForm(variant, this.props.functions);
    ChangeFormHelper.applyChanges(this.props.form, form, this.props.applyFieldChanges);
    this.props.setEditActivityVariantModel(variant);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    activity: editModel.root,
    activityId: editModel.rootId,
    activityVariant: editModel.variant,
    activityVariantId: editModel.variantId,
    databaseId: state.database.currentDatabase.database.id,
    functions: state.database.currentDatabase.functions,
    form: state.form[formId] && state.form[formId].values,
    unitMap: state.database.currentDatabase.unitMap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditActivityVariantModel: variant =>
      dispatch(DatabaseActivityListingActions.setEditActivityVariantModel(variant)),
    loadActivity: (databaseId, activityId) => dispatch(DatabaseActivityActions.loadActivity(databaseId, activityId)),
    loadActivityVariant: (databaseId, activityId, variantId) =>
      dispatch(DatabaseActivityVariantActions.loadActivityVariant(databaseId, activityId, variantId)),
    updateActivityVariant: (databaseId, activityId, variantId, form) =>
      dispatch(DatabaseActivityVariantActions.updateActivityVariant(databaseId, activityId, variantId, form, formId)),
    applyFieldChanges: (field: string, value: any) => dispatch(change(formId, field, value)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const EditDatabaseActivityVariant = connector(EditDatabaseActivityVariantComponent);
