import React from 'react';

import './permission-table.scss';

import { PermissionActivityDescription } from 'common/interfaces/account/permission-activity-description';
import { NumberDictionary } from 'common/interfaces/dictionary';
import { KreoScrollbars } from 'common/UIKit';
import { Permission } from '../../../units/people/components/permission';
import { ExtendedPermission } from '../../../units/people/interfaces/extended-permission';

interface Props {
  permissions: NumberDictionary<ExtendedPermission>;
  activities: PermissionActivityDescription[];
}

export const PermissionTable: React.FC<Props> = props => {
  return (
    <div className='permission-table'>
      <div className='permission-table__header'>
        <div className='permission-table__permission'>
          <div className='permission-table__key'>Feature</div>
          <div className='permission-table__value'>Permission</div>
        </div>
      </div>
      <div className='permission-table__content'>
        <KreoScrollbars>
          <div className='permission-table__permission-wrap'>
            {props.activities &&
              props.activities.map(act => {
                return (
                  <div key={act.activity} className='permission-table__permission'>
                    <div className='permission-table__key'>
                      <div className='permission-table__text'>{act.name}</div>
                    </div>
                    <div className='permission-table__value'>
                      {props.permissions && <Permission access={props.permissions[act.activity].activityAccess} />}
                    </div>
                  </div>
                );
              })}
          </div>
        </KreoScrollbars>
      </div>
    </div>
  );
};
