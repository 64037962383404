import * as React from 'react';


export interface RoutingContextValue {
  urls: any;
}

export const RoutingContext = React.createContext<RoutingContextValue>(null);


export class RoutingContextProvider extends React.Component<RoutingContextValue> {
  public render(): React.ReactNode {
    const { urls } = this.props;

    return (
      <RoutingContext.Provider value={{ urls }}>
        {this.props.children}
      </RoutingContext.Provider>
    );
  }
}

