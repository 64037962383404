import { useCallback } from 'react';
import uuid from 'uuid';

import { Constants } from '../constants';
import { PiaBreakdownNode } from '../interfaces';
import { UpdateFunction } from './use-options-state';

export type HandleAddedOption = (parentId?: string) => void;

export const useAddedOptionCallback = (updateOptions: UpdateFunction): HandleAddedOption => {
  return useCallback(
    (value?: string) => {
      const parentId = typeof value === 'string'
        ? value
        : '';

      updateOptions((ops, opsMap) => {
        const option: PiaBreakdownNode = {
          id: uuid.v4(),
          name: Constants.emptyDescription,
          children: [],
        };
        if (parentId) {
          const parent = opsMap[parentId];
          parent.children.push(option);
        } else {
          ops.push(option);
        }
      });
    },
    [updateOptions],
  );
};
