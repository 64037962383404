import { useActionDispatch } from 'common/hooks';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { usePersistedStorageValue } from 'persisted-storage/hooks';
import { DrawingsSnappingModes } from '../../enums/drawing-snapping-modes';
import { useUndoRedoForSameFunction, useUndoRedoForToggle } from './use-undo-redo-for-toggle';


export interface SnappingSettings {
  isSnappingEnabled: boolean;
  snappingModes: DrawingsSnappingModes[];
  isOrthogonalModeEnabled: boolean;
}

export interface SnappingSettingsApi {
  toggleSnapping: () => void;
  toggleOrthogonalMode: () => void;
  setSnappingModes: (modes: DrawingsSnappingModes[]) => void;
}


export function useSnappingSettings(): SnappingSettings {
  const isSnappingEnabled = usePersistedStorageValue('drawingSnappingToggle');
  const isOrthogonalModeEnabled = usePersistedStorageValue('drawingOrthogonalMode');
  const snappingModes = usePersistedStorageValue('drawingSnappingModes');
  return {
    isSnappingEnabled,
    isOrthogonalModeEnabled,
    snappingModes,
  };
}

export function useSnappingFullApi(): SnappingSettings & SnappingSettingsApi {
  const settings = useSnappingSettings();
  const toggleSnapping = useActionDispatch(PersistedStorageActions.changeDrawingSnappingToggle);
  const toggleOrthogonalMode = useActionDispatch(PersistedStorageActions.toggleOrthogonalMode);
  const setSnappingModes = useActionDispatch(PersistedStorageActions.setDrawingSnappingModes);

  return {
    ...settings,
    toggleSnapping: useUndoRedoForToggle(toggleSnapping),
    toggleOrthogonalMode: useUndoRedoForToggle(toggleOrthogonalMode),
    setSnappingModes: useUndoRedoForSameFunction(setSnappingModes, settings.snappingModes),
  };
}
