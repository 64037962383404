const prefix = `@database`;

export const DatabaseActionTypes = {
  CREATE_DATABASE_REQUEST: `${prefix}/CREATE_DATABASE_REQUEST`,
  CREATE_DATABASE_SUCCEEDED: `${prefix}/CREATE_DATABASE_SUCCEEDED`,
  GET_DATABASES_REQUEST: `${prefix}/GET_DATABASES_REQUEST`,
  GET_DATABASES_SUCCEEDED: `${prefix}/GET_DATABASES_SUCCEEDED`,
  GET_DATABASE_REQUEST: `${prefix}/GET_DATABASE_REQUEST`,
  GET_DATABASE_SUCCEEDED: `${prefix}/GET_DATABASE_SUCCEEDED`,
  UPDATE_DATABASE_REQUEST: `${prefix}/UPDATE_DATABASE_REQUEST`,
  UPDATE_DATABASE_SUCCEEDED: `${prefix}/UPDATE_DATABASE_SUCCEEDED`,
  DELETE_DATABASE_REQUEST: `${prefix}/DELETE_DATABASE_REQUEST`,
  DELETE_DATABASE_SUCCEEDED: `${prefix}/DELETE_DATABASE_SUCCEEDED`,
  SET_CURRENT_DATABASE: `${prefix}/SET_CURRENT_DATABASE`,
  SET_DEFAULT_DATABASE: `${prefix}/SET_DEFAULT_DATABASE`,
  SET_DEFAULT_DATABASE_SUCCEEDED: `${prefix}/SET_DEFAULT_DATABASE_SUCCEEDED`,
  LOAD_FUNCTIONS_WITH_UNITS: `${prefix}/LOAD_FUNCTIONS_WITH_UNITS`,
};
