import * as React from 'react';

import './validation-step-wrapper.scss';

import { ValidationStep } from '../../../../enums/validation-step';
import { ValidationStepContent } from './validation-step-content';
import { ValidationStepperComponent } from './validation-stepper';


export interface StepProps {
  stepperRef?: ValidationStepperComponent;
  title: string;
  with3dView: boolean;
  step: ValidationStep;
  className?: string;
  onRevertToPreviousStep?: () => void;
  onApprove?: () => void;
  /**
   * Used on Classification page in BoQ product
   */
  onRemoveApproval?: () => void;
}

export interface OwnProps extends Partial<StepProps> { }

export class ValidationStepWrapper extends React.PureComponent<OwnProps> {
  public render(): React.ReactNode {
    return (
      <ValidationStepContent width={'36%'} className={this.props.className}>
        {this.props.children}
      </ValidationStepContent>
    );
  }
}
