import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import { AsyncArrayUtils } from 'common/utils/async-array-util';
import {
  DrawingsGeometryAddInstances,
  ProcessFileDataResult,
} from '../actions/payloads/annotation';
import { DrawingsGeometryPayload } from '../api/drawings-api';
import { DrawingsInstanceType } from '../enums/drawings-instance-type';
import {
  DrawingsFilesData,
  DrawingsPointInfo,
  DrawingsProcessedAiAnnotation,
  ShortPointDescription,
} from '../interfaces/drawing-ai-annotation';
import { DrawingsGeometryStrokedType, DrawingsGeometryType } from '../interfaces/drawings-geometry';
import { DrawingsGeometryInstance } from '../interfaces/drawings-geometry-instance';
import { DrawingsGeometryUtils } from './drawings-geometry-utils';

const lineKeySeparator = '|';

function getLineKey(start: string | number, end: string | number): string {
  let maxKey = end;
  let minKey = start;
  if (start > end) {
    maxKey = start;
    minKey = end;
  }
  return `${minKey}${lineKeySeparator}${maxKey}`;
}

function getPointsIdsFromLineKey(key: string): [string, string] {
  return key.split(lineKeySeparator) as [string, string];
}

function createNextPointSelector<T = string>(type: DrawingsInstanceType, points: T[]): (index: number) => T {
  if (type === DrawingsInstanceType.Polygon || type === DrawingsInstanceType.Rectangle) {
    return (index) => points[index === points.length - 1 ? 0 : index + 1];
  } else {
    return (index) => points[index + 1];
  }
}

function* iteratePointsWithIndex<T = string>(
  points: T[],
  type: DrawingsInstanceType,
): IterableIterator<[T, T, number]> {
  const nextPointSelector = createNextPointSelector(type, points);
  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    const finishPoint = nextPointSelector(i);
    yield [point, finishPoint, i];
  }
}

function* iteratePoints<T = string>(points: T[], type: DrawingsInstanceType): IterableIterator<[T, T]> {
  for (const [ point, finishPoint ] of iteratePointsWithIndex(points, type)) {
    yield [point, finishPoint];
  }
}

function* iterateSegmentsWithIndex<T = string>(
  points: T[],
  type: DrawingsInstanceType,
): IterableIterator<[T, T, number]> {
  for (const segmentPoints of iteratePointsWithIndex(points, type)) {
    if (segmentPoints[1]) {
      yield segmentPoints;
    }
  }
}

function* iterateSegments<T = string>(points: T[], type: DrawingsInstanceType): IterableIterator<[T, T]> {
  for (const [start, end] of iterateSegmentsWithIndex(points, type)) {
    yield [start, end];
  }
}

function* iteratePathPoints(
  path: paper.Path,
  type: DrawingsInstanceType,
): IterableIterator<[paper.Point, paper.Point]> {
  for (const [start, end] of iteratePoints(path.segments, type)) {
    if (!end) {
      break;
    }
    yield [start.point, end.point];
  }
}

function* geometryPointsIterator(geometry: DrawingsGeometryType, type: DrawingsInstanceType): IterableIterator<string> {
  for (const point of geometry.points) {
    yield point;
  }
  if (DrawingsGeometryUtils.isPolygon(type, geometry) && geometry.children) {
    for (const child of geometry.children) {
      for (const point of child) {
        yield point;
      }
    }
  }
}

function* iterateLines(geometry: DrawingsGeometryStrokedType, type: DrawingsInstanceType): IterableIterator<string> {
  for (const [current, next] of iteratePoints(geometry.points, type)) {
    if (!next) {
      break;
    }
    yield getLineKey(current, next);
  }
  if (DrawingsGeometryUtils.isPolygon(type, geometry) && geometry.children) {
    for (const child of geometry.children) {
      for (const [current, next] of iteratePoints(child, type)) {
        yield getLineKey(current, next);
      }
    }
  }
}

function processPoints(
  contour: string[],
  instanceId: string,
  pointElement: Record<string, DrawingsPointInfo>,
): string[] {
  const result = [];
  for (const point of contour) {
    pointElement[point] = { instanceId, lines: [] };
  }
  return result;
}

function createEmptyFileData(): DrawingsProcessedAiAnnotation {
  return { categories: [], instances: [], loaded: true };
}

function* iterateFileInstances(fileData: Record<string, DrawingsProcessedAiAnnotation>): IterableIterator<string> {
  for (const { instances } of Object.values(fileData)) {
    for (const instanceId of instances) {
      yield instanceId;
    }
  }
}

function processPointsAndLines(
  points: string[],
  type: DrawingsInstanceType,
  pointInfo: Record<string, DrawingsPointInfo>,
  instanceId: string,
): void {
  for (const [start, end] of iteratePoints(points, type)) {
    pointInfo[start] = pointInfo[start] || { instanceId, lines: [] };
    if (end) {
      const key = getLineKey(start, end);
      pointInfo[end] = pointInfo[end] || { instanceId, lines: [] };
      pointInfo[start].lines.push(key);
      pointInfo[end].lines.push(key);
    }
  }
}

function remapPointsInElement(
  instance: DrawingsGeometryInstance,
  instanceId: string,
  pointInfo: Record<string, DrawingsPointInfo>,
): DrawingsGeometryType {
  const { type, geometry } = instance;
  if (DrawingsGeometryUtils.isCount(type, geometry)) {
    processPoints(geometry.points, instanceId, pointInfo);
  } else if (
    DrawingsGeometryUtils.isPolygon(type, geometry) ||
    DrawingsGeometryUtils.isPolyline(type, geometry) ||
    DrawingsGeometryUtils.isRectangle(type, geometry)
  ) {
    processPointsAndLines(geometry.points, type, pointInfo, instanceId);
    if (DrawingsGeometryUtils.isPolygon(type, geometry) && geometry.children) {
      for (const child of geometry.children) {
        processPointsAndLines(child, type, pointInfo, instanceId);
      }
    }
  }
  return geometry;
}


const STEP = 100;

async function processFileDataAsync(
  filesData: DrawingsFilesData,
  pdfId: string,
  pageId: string,
  response: DrawingsGeometryPayload,
  progress: (progress: number) => void,
  isCanceled: () => boolean = () => false,
): Promise<ProcessFileDataResult> {
  const instances = new Array<string>();
  const { geometry: geometryInstances, points } = response;
  const pointsInfo: Record<string, DrawingsPointInfo> = {};
  const hiddenInstances = [];
  const users = [];
  const usedColors = [];
  const entries = Object.entries(geometryInstances);
  const iterator = AsyncArrayUtils.indexedIteratorWithDelayEveryNStep(entries, 0.01, STEP);
  for await (const [[instanceId, instance], index] of iterator) {
    if (isCanceled()) {
      return null;
    }
    instance.geometry = remapPointsInElement(instance, instanceId, pointsInfo);
    instance.drawingId = pageId;
    if (!instance.geometry) {
      if (index && index % STEP === 0) {
        progress(STEP);
      }
      delete geometryInstances[instanceId];
      continue;
    }
    if (instance.creator) {
      users.push(instance.creator);
    }
    instances.push(instanceId);
    if (instance.geometry.color === 'undefined') {
      console.error('Undefined color in instance', instanceId, instance);
    } else {
      // todo скорее всего можно будет убрать где-то через полгода год
      instance.geometry.color = instance.geometry.color.toLowerCase();
      usedColors.push(instance.geometry.color);
    }
    if (instance.isHidden) {
      hiddenInstances.push(instanceId);
    }
    if (index && index % STEP === 0) {
      progress(STEP);
    }
  }

  progress(entries.length % STEP);

  const fileData = new MonoliteHelper(filesData[pdfId] || {})
    .set(_ => _[pageId], _ => _ || createEmptyFileData())
    .set(
      _ => _[pageId],
      _ => {
        const instancesIds = arrayUtils.uniq(_.instances.concat(instances));
        return new MonoliteHelper(_)
          .set(x => x.instances, instancesIds)
          .get();
      },
    ).get();
  return {
    geometryInstances,
    points,
    pointsInfo,
    fileData: { [pdfId]: fileData },
    usedColors,
    hiddenInstances,
    users,
    pagePoints: { [pageId]: Object.keys(points) },
  };
}

function canelableProcessFileDataAsync(
  filesData: DrawingsFilesData,
  pdfId: string,
  pageId: string,
  response: DrawingsGeometryPayload,
  progress: (progress: number) => void,
): [Promise<ProcessFileDataResult>, () => void] {
  let isCanceled = false;
  const promise = processFileDataAsync(filesData, pdfId, pageId, response, progress, () => isCanceled);
  const cancel = (): void => {
    isCanceled = true;
  };
  return [promise, cancel];
}

function createPointInfo(instanceId: string): DrawingsPointInfo {
  return { instanceId, lines: [] };
}

function getOrCreatePointInfo(
  statePointsInfo: Record<string, DrawingsPointInfo>,
  pointId: string,
  instanceId: string,
): DrawingsPointInfo {
  return statePointsInfo[pointId] ? { ...statePointsInfo[pointId] } : createPointInfo(instanceId);
}

function addLineLinks(
  currentPointsInfo: Record<string, DrawingsPointInfo>,
  statePointsInfo: Record<string, DrawingsPointInfo>,
  id: string,
  next: string,
  current: string,
): void {
  currentPointsInfo[current] = currentPointsInfo[current] || getOrCreatePointInfo(statePointsInfo, current, id);
  currentPointsInfo[next] = currentPointsInfo[next] || getOrCreatePointInfo(statePointsInfo, next, id);
  const key = getLineKey(current, next);
  currentPointsInfo[current].lines.push(key);
  currentPointsInfo[next].lines.push(key);
}

function processContourPoints(
  child: string[],
  points: Record<string, ShortPointDescription>,
  payload: DrawingsGeometryAddInstances,
  id: string,
  statePointsInfo: Record<string, DrawingsPointInfo>,
  currentPointsInfo: Record<string, DrawingsPointInfo>,
  statePoints: Record<string, ShortPointDescription>,
): void {
  for (let i = 0; i < child.length; i++) {
    const current = child[i];
    const next = child[i === child.length - 1 ? 0 : i + 1];
    if (!points[current]) {
      points[current] = payload.newPoints[current] || statePoints[current];
    }
    addLineLinks(currentPointsInfo, statePointsInfo, id, next, current);
  }
}

function getColorOfInstance(instance: DrawingsGeometryInstance): string {
  if (!instance) {
    return;
  }
  return instance.geometry.color;
}

export const DrawingAnnotationUtils = {
  getLineKey,
  iterateLines,
  iteratePoints,
  processFileDataAsync,
  canelableProcessFileDataAsync,
  getPointsIdsFromLineKey,
  geometryPointsIterator,
  iteratePathPoints,
  createPointInfo,
  getOrCreatePointInfo,
  addLineLinks,
  processContourPoints,
  getColorOfInstance,

  createEmptyFileData,
  iterateFileInstances,
  iterateSegments,
  iterateSegmentsWithIndex,
};
