import { useCallback } from 'react';
import { useRendererApiContext } from '../../drawings-contexts';
import { DrawingsDrawMode } from '../../enums';
import { useDrawModeApi } from '../../hooks';

export function useModify(instancesIds: string[]): () => void {
  const { setDrawMode } = useDrawModeApi();
  const { rendererApi } = useRendererApiContext();

  return useCallback(() => {
    setDrawMode(DrawingsDrawMode.Modify);
    rendererApi.engine.modify(instancesIds[0]);
  }, [setDrawMode, rendererApi, instancesIds]);
}
