import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import {
  DrawingsAddImages,
  DrawingsAddRulers,
  DrawingsAddStickers,
  DrawingsAnnotationRotation,
  DrawingsAnnotationsChangeColors,
  DrawingsAnnotationsPosition,
  DrawingsChangeStickerTextPayload,
  DrawingsDeleteAnnotationsPayload,
  DrawingsDeleteAnnotationsRequest,
  DrawingsGetUserAnnotationsSuccess,
  DrawingsSaveStickerSuccess,
  DrawingsUpdateRulerPoints,
} from '../actions/payloads/user-annotation';
import { DrawingsUserAnnotationActionTypes } from '../actions/types/user-annotation';
import { UserAnnotationsInitialState } from '../constants/drawings-initial-state';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import { DrawingsGroupMeasure } from '../interfaces/drawings-measures';
import { DrawingsState } from '../interfaces/drawings-state';
import { DrawingUserAnnotationsType } from '../interfaces/drawings-user-annotation';
import { DrawingsPaperUtils } from '../utils/drawings-paper-utils';

function updateCollectionPosition<T extends { position: ShortPointDescription }>(
  collection: Record<string, T>,
  payload: DrawingsAnnotationsPosition[],
): Record<string, T> {
  const helper = new MonoliteHelper(collection);
  for (const { id, position } of payload) {
    helper.set(_ => _[id].position, position);
  }
  return helper.get();
}

function addItemsToCollection<T, K extends DrawingUserAnnotationsType>(
  state: T,
  items: K[],
  collectionAccessor: (state: T) => Record<string, K>,
): T {
  const helper = new MonoliteHelper(state);
  for (const item of items) {
    helper.set(_ => collectionAccessor(_)[item.id], item);
  }
  return helper.get();
}


export const DrawingsUserAnnotationReducerMethods: ReducerMethods<DrawingsState> = {
  [DrawingsUserAnnotationActionTypes.GET_ANNOTATIONS]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations, { ...UserAnnotationsInitialState, annotationLoadingStatus: RequestStatus.Loading })
      .get();
  },
  [DrawingsUserAnnotationActionTypes.GET_ANNOTATIONS_SUCCESS]: (
    state,
    { stickers, svgs, rulers, currentDrawingId, legend }: DrawingsGetUserAnnotationsSuccess,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.stickers, arrayUtils.toDictionary(stickers, x => x.id, x => x))
      .set(_ => _.userAnnotations.images, arrayUtils.toDictionary(svgs, x => x.id, x => x))
      .set(_ => _.userAnnotations.rulers, arrayUtils.toDictionary(rulers, x => x.id, x => x))
      .set(_ => _.userAnnotations.annotationLoadingStatus, RequestStatus.Loaded)
      .set(_ => _.userAnnotations.legend, DrawingsPaperUtils.convertPoint(legend))
      .set(_ => _.userAnnotations.currentDrawingId, currentDrawingId)
      .get();
  },
  [DrawingsUserAnnotationActionTypes.ADD_STICKER]: (state, { stickers }: DrawingsAddStickers) => {
    return new MonoliteHelper(state)
      .set(
        _ => _.userAnnotations,
        _ => addItemsToCollection(_, stickers, x => x.stickers),
      )
      .get();
  },
  [DrawingsUserAnnotationActionTypes.ADD_STICKER_SUCCEED]: (
    state,
    { stickers, applied }: DrawingsSaveStickerSuccess,
  ) => {
    const helper = new MonoliteHelper(state.userAnnotations.stickers);
    for (const sticker of stickers) {
      if (state.userAnnotations.stickers[sticker.id]) {
        helper.set(
          _ => _[sticker.id],
          _ => ({ ..._, createdAt: applied.appliedAt, creatorId: applied.userId }),
        );
      }

    }
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.stickers, helper.get())
      .get();
  },
  [DrawingsUserAnnotationActionTypes.UPDATE_STICKERS_POSITIONS]: (state, payload: DrawingsAnnotationsPosition[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.stickers, _ => updateCollectionPosition(_, payload))
      .get();
  },
  [DrawingsUserAnnotationActionTypes.UPDATE_IMAGES_POSITIONS]: (state, payload: DrawingsAnnotationsPosition[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.images, _ => updateCollectionPosition(_, payload))
      .get();
  },
  [DrawingsUserAnnotationActionTypes.SELECT_ANNOTATION]: (state, payload: string[]) => {
    return new MonoliteHelper(state).set(_ => _.userAnnotations.selectedAnnotations, payload).get();
  },
  [DrawingsUserAnnotationActionTypes.REMOVE_INSTANCES]: (
    state,
    { annotationsIds }: DrawingsDeleteAnnotationsRequest,
  ) => {
    const instancesForRemoveSet = new Set(annotationsIds);
    return new MonoliteHelper(state).setFilter(
      _ => _.userAnnotations.selectedAnnotations,
      _ => !instancesForRemoveSet.has(_),
    ).get();
  },
  [DrawingsUserAnnotationActionTypes.REMOVE_INSTANCES_FROM_STATE]: (
    state,
    { deletedAnnotations: { deletedRulers, deletedSvgs, deletedStickers } }: DrawingsDeleteAnnotationsPayload,
  ) => {
    const helper = new MonoliteHelper(state);

    deletedRulers.forEach(id => helper.removeKey(_ => _.userAnnotations.rulers, id));
    deletedStickers.forEach(id => helper.removeKey(_ => _.userAnnotations.stickers, id));
    deletedSvgs.forEach(id => helper.removeKey(_ => _.userAnnotations.images, id));
    return helper.get();
  },
  [DrawingsUserAnnotationActionTypes.ADD_IMAGES]: (state, { images }: DrawingsAddImages) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations, _ => addItemsToCollection(_, images, x => x.images))
      .get();
  },
  [DrawingsUserAnnotationActionTypes.ADD_RULERS]: (state, { rulers }: DrawingsAddRulers) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations, _ => addItemsToCollection(_, rulers, x => x.rulers))
      .get();
  },
  [DrawingsUserAnnotationActionTypes.CHANGE_COLORS]: (state, payload: DrawingsAnnotationsChangeColors[]) => {
    const helper = new MonoliteHelper(state.userAnnotations);
    for (const { ids, color } of payload) {
      for (const id of ids) {
        if (id in state.userAnnotations.images) {
          helper.set(annotations => annotations.images[id].color, color);
        } else if (id in state.userAnnotations.rulers) {
          helper.set(annotations => annotations.rulers[id].color, color);
        }
      }
    }
    return new MonoliteHelper(state).set(_ => _.userAnnotations, helper.get()).get();
  },
  [DrawingsUserAnnotationActionTypes.UPDATE_IMAGE_PARAMETER]: (
    state,
    { id, value, parameter }: DrawingsAnnotationRotation,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.images[id][parameter], value)
      .get();
  },
  [DrawingsUserAnnotationActionTypes.UPDATE_RULER_POINTS]: (state, { id, points }: DrawingsUpdateRulerPoints) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.rulers[id].positions, points)
      .get();
  },
  [DrawingsUserAnnotationActionTypes.CHANGE_STICKER_TEXT]: (state, { id, text }: DrawingsChangeStickerTextPayload) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.stickers[id].text, text)
      .get();
  },
  [DrawingsUserAnnotationActionTypes.REMOVE_LEGEND]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.legend, null)
      .set(_ => _.userAnnotations.groupsMeasuresCache, {})
      .get();
  },
  [DrawingsUserAnnotationActionTypes.UPDATE_LEGEND_POSITION]: (state, point: ShortPointDescription) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.legend, point)
      .get();
  },
  [DrawingsUserAnnotationActionTypes.CACHE_GROUP_VALUES]: (
    state,
    payload: Record<string, DrawingsGroupMeasure>,
  ) => {
    const helper = new MonoliteHelper(state.userAnnotations.groupsMeasuresCache);
    for (const [key, value] of Object.entries(payload)) {
      helper.set(_ => _[key], value);
    }
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.groupsMeasuresCache, helper.get())
      .get();
  },
  [DrawingsUserAnnotationActionTypes.SET_TEMP_STICKER_POSITION]: (state, position: ShortPointDescription) => {
    return new MonoliteHelper(state)
      .set(_ => _.userAnnotations.tempSticker, position ? { position, hasText: false } : null)
      .get();
  },
  [DrawingsUserAnnotationActionTypes.SET_TEMP_STICKER_HAS_TEXT_STATUS]: (state, status: boolean) => {
    if (!state.userAnnotations.tempSticker) {
      return state;
    }
    return new MonoliteHelper(state).set(_ => _.userAnnotations.tempSticker.hasText, status).get();
  },
};
