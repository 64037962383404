import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionType } from 'common/constants/subscription';
import { State } from 'common/interfaces/state';
import { SubscriptionActions } from '../../../../subscription/actions/creators';
import { SUBSCRIPTION__DIALOG } from '../../../../subscription/components/subscription-dialog';
import { UPGRADE_PRO_PLAN_CONSTANTS } from '../constants';

export const useFetchCompanyEffect: () => void = () => {
  const dispatch = useDispatch();
  const subscriptionType = useSelector<State, SubscriptionType>(s => s.account.selectedSubscriptionType);
  const isOpen = useSelector<State, boolean>(s => UPGRADE_PRO_PLAN_CONSTANTS.UPGRADE_PLAN_DIALOG in s.dialog);
  const isSubscriptionDialoOpen = useSelector<State, boolean>(s => SUBSCRIPTION__DIALOG in s.dialog);
  React.useEffect(() => {
    if (!isSubscriptionDialoOpen) {
      if (isOpen) {
        dispatch(SubscriptionActions.fetchOwnCompanyBillingInfo(subscriptionType));
      } else if (!isSubscriptionDialoOpen) {
        dispatch(SubscriptionActions.dropOwnCompanyBillingInfoState());
      }
    }
  }, [isOpen]);
};
