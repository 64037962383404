import { ModalWrapper } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { CheckboxWithText } from 'common/components/checkbox-with-text';
import { FormulaInputParsers } from 'common/components/formula-editor/input';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { FormulaEditorWrapper } from '../formula-field-wrapper/formula-editor-wrapper';
import { FORMULA_CLOSE_DIALOG_NAME, FORMULA_DIALOG_NAME } from './constants';
import { FormulaDialogData } from './interfaces';
import { Styled } from './styled';

interface DispatchProps {
  onClose: () => void;
  openConfirmation: () => void;
  closeConfirmation: () => void;
}

interface StateProps {
  data?: FormulaDialogData;
}

interface Props extends StateProps, DispatchProps {}


const FormulaDialogComponent: React.FC<Props> = ({
  onClose,
  data,
  openConfirmation,
}) => {
  const [ value, setValue ] = useState<string>(data?.formulaValue);
  useEffect(() => {
    setValue(data?.formulaValue);
  }, [data?.formulaValue]);
  const [ isValid, setIsValid ] = useState(true);
  const onExit = useCallback(() => {
    if (value !== data?.formulaValue) {
      openConfirmation();
    } else {
      onClose();
    }
  }, [value, data?.formulaValue, onClose, openConfirmation]);

  const [ addProps, setAddProps ] = useState(false);
  const toggleAddProps = useCallback(() => {
    setAddProps(s => !s);
  }, []);

  const renderCheckbox = useCallback(() => {
    return (
      <CheckboxWithText
        onToggle={toggleAddProps}
        text="Add new functions in a formula to an entity"
        checked={addProps}
      />
    );
  }, [addProps, toggleAddProps]);

  const onSave = useCallback(() => {
    if (!isValid) {
      return;
    }
    const propertiesToAdd = addProps && data?.canAddProperties
      ? FormulaInputParsers.extractUsedPropertiesNames(value)
      : [];
    data?.onChangeValue(value, propertiesToAdd);
    onClose();
  }, [data, value, addProps, onClose, isValid]);

  return (
    <RenderIf condition={value !== undefined}>
      <DialogWrapper name={FORMULA_DIALOG_NAME}>
        <Styled.ModalWrapper>
          <ModalWrapper onExit={onExit} zIndex={99999}>
            <Styled.Container withAdditional={data?.canAddProperties}>
              <FormulaEditorWrapper
                additionalPropertyNames={data?.itemProperties}
                value={value}
                onChangeValue={setValue}
                onChangeValidation={setIsValid}
                onSaveClick={value !== data?.formulaValue ? onSave : undefined}
                renderFormulaOptions={data?.canAddProperties ? renderCheckbox : undefined}
              />
            </Styled.Container>
          </ModalWrapper>
        </Styled.ModalWrapper>
      </DialogWrapper>
      <ConfirmationDialog
        name={FORMULA_CLOSE_DIALOG_NAME}
        title='Are you sure you want to exit the "Edit formula" Mode?'
        text="This action cannot be undone"
        confirmButtonText="Exit"
        cancelButtonText="Cancel"
        onConfirm={onClose}
        zIndex={100000}
      />
    </RenderIf>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    data: state.dialog[FORMULA_DIALOG_NAME],
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onClose: () => dispatch(KreoDialogActions.closeDialog(FORMULA_DIALOG_NAME)),
    openConfirmation: () => dispatch(KreoDialogActions.openDialog(FORMULA_CLOSE_DIALOG_NAME)),
    closeConfirmation: () => dispatch(KreoDialogActions.closeDialog(FORMULA_CLOSE_DIALOG_NAME)),
  };
}

export const FormulaDialog = connect(mapStateToProps, mapDispatchToProps)(FormulaDialogComponent);
