import { flatten } from './flatten';

export function SUMIFS(values: number[], ...criteria: any[]): number {
  let sum = 0;
  const vlatenValues = flatten(values);
  for (let i = 0; i < vlatenValues.length; i++) {
    let criteriaMatch = true;
    for (let j = 0; j < criteria.length; j += 2) {
      const criterionRange = Array.isArray(criteria[j]) ? flatten(criteria[j]) : criteria[j];
      const criterion = criteria[j + 1].toString();
      let criterionValue = criterion;
      let operator = '=';
      if (criterion.includes('>=')) {
        criterionValue = criterion.replace('>=', '');
        operator = '>=';
      } else if (criterion.includes('<=')) {
        criterionValue = criterion.replace('<=', '');
        operator = '<=';
      } else if (criterion.includes('<>')) {
        criterionValue = criterion.replace('<>', '');
        operator = '!=';
      } else if (criterion.includes('>')) {
        criterionValue = criterion.replace('>', '');
        operator = '>';
      } else if (criterion.includes('<')) {
        criterionValue = criterion.replace('<', '');
        operator = '<';
      } else if (criteria.includes('=')) {
        criterionValue = criterion.replace('=', '');
        operator = '=';
      }
      const originValue = criterionValue === '' || Number.isNaN(Number(criterionValue))
        ? criterionValue
        : Number(criterionValue);
      if (typeof originValue !== typeof criterionRange[i]) {
        criteriaMatch = false;
        break;
      }
      if (operator === '=') {
        if (criterionRange[i] !== originValue) {
          criteriaMatch = false;
          break;
        }
      } else if (operator === '>=') {
        if (criterionRange[i] < originValue) {
          criteriaMatch = false;
          break;
        }
      } else if (operator === '<=') {
        if (criterionRange[i] > originValue) {
          criteriaMatch = false;
          break;
        }
      } else if (operator === '!=') {
        if (criterionRange[i] === originValue) {
          criteriaMatch = false;
          break;
        }
      } else if (operator === '>') {
        if (criterionRange[i] <= originValue) {
          criteriaMatch = false;
          break;
        }
      } else if (operator === '<') {
        if (criterionRange[i] >= originValue) {
          criteriaMatch = false;
          break;
        }
      }
    }
    if (criteriaMatch) {
      const valueToAdd = Number(vlatenValues[i]);
      sum += Number.isNaN(valueToAdd)
        ? 0
        : valueToAdd;
    }
  }
  return typeof sum === 'number' ? sum : 0;
}
