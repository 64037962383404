import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';


interface Props {
  togglePanel: () => void;
}

export const CollapseExpandButton: React.FC<Props> = ({ togglePanel }) => {
  return (
    <Styled.Container
      onClick={togglePanel}
    >
      <Icons.DownSmall />
    </Styled.Container>
  );
};
