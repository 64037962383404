import { Constants, TinyText } from '@kreo/kreo-ui-components';
import styled, { ThemedStyledProps } from 'styled-components';
import { UserTooltipContainer } from 'common/components/drawings/components';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${p => p.theme.color.background};
  border-radius: 15px;
  height: 30px;
  background-color: ${p => p.theme.color.background};
`;

function getColor({ theme, status }: ThemedStyledProps<any, any> & { status: ViewModelStatus }): string {
  switch (status) {
    case ViewModelStatus.Failed:
      return Constants.Colors.GENERAL_COLORS.red;
    case ViewModelStatus.Waiting:
    case ViewModelStatus.Calculating:
      return theme.color.turquoise;
    default:
      return theme.color.gray;
  }
}

const IconContainer = styled.div<{ status: ViewModelStatus }>`
  width: 20px;
  height: 20px;
  padding: 0 10px;

  > svg {
    fill: ${p => getColor(p)};
  }
`;

const DeleteButtonContainer = styled.div`
  left: calc(100% + 10px);
  position: absolute;
`;

const TextContainer = styled.div`
  margin-right: 15px;
  max-width: 400px;

  ${TinyText} {
    font-size: 14px;
  }
`;

const ErrorTextContainer = styled(UserTooltipContainer)`
  padding: 5px 10px;
`;

export const Styled = {
  Container,
  IconContainer,
  TextContainer,
  DeleteButtonContainer,
  ErrorTextContainer,
};
