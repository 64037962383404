import { CursorHint } from '@kreo/kreo-ui-components';
import * as React from 'react';


import { useGeometryOperationContext } from '../drawings-contexts';
import { DrawingsSubtractDialogWrapper } from './drawings-subtract-dialog-wrapper';


interface OwnProps {
  sendRef: (ref: HTMLDivElement, isBooleanMenu?: boolean) => void;
}

export const DrawingsSubtract: React.FC<OwnProps> = props => {
  const { selectedIdForSubtract } = useGeometryOperationContext();
  return selectedIdForSubtract
    ? <DrawingsSubtractDialogWrapper {...props}/>
    : (
      <CursorHint
        position={'right'}
        hintText={'Select base polygon'}
        yShift={30}
      />
    );
};
