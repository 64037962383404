import { State } from 'common/interfaces/state';
import { DrawingsState } from '../interfaces/drawings-state';

export interface ProjectAndDrawings {
  projectId: number;
  drawings: DrawingsState;
}

function getProjectRequiredInfo(state: State): ProjectAndDrawings {
  return {
    projectId: state.projects.currentProject.id,
    drawings: state.drawings,
  };
}

export const DrawingsSagaSelectors = {
  getProjectRequiredInfo,
};
