export const DataControlNameAttribute = 'data-control-name';

export const ControlNames = {
  createDemoProjectDialogButton: 'create-demo-project-dialog-button',
  create2dProjectDialogButton: 'create-2d-project-dialog-button',
  create3dProjectDialogButton: 'create-3d-project-dialog-button',
  createProjectForm: 'create-project-form',
  dialogCloseButton: 'dialog-close-button',
  dialogCancelButton: 'dialog-cancel-btn',
  projectsPage: 'projects-page',
  projectTile: 'project-tile',
  projectTileDeleteButton: 'project-tile-delete-button',
  projectTileLink: 'project-tile-link',
  projectTileContent: 'project-tile-content',
  advDialogVideo: 'adv-dialog-video',
  advDialogVideoFail: 'adv-dialog-video-fail',
  advDialogInformation: 'adv-dialog-information',
  advDialogHeader: 'adv-dialog-header',
  advDialogHeaderH5: 'adv-dialog-header-h5',
  advDialogDescription: 'adv-dialog-description',
  advDialogFeaturesList: 'adv-dialog-list-features',
  advDialogButton: 'adv-dialog-button',
  advDialogConfirmButton: 'adv-dialog-button-confirmation',
  advDialogChatButton: 'adv-dialog-chat-button',
  advDialogChatLink: 'adv-dialog-chat-link',
  advDialogAutoMeasure: 'adv-dialog-auto-measure',
  advDialogAnnotations: 'adv-dialog-annotations',
  advDialogTemplates: 'adv-dialog-templates',
  giftButton: 'gift-button',
  reconnectDialogWrapper: 'reconnect-dialog-wrapper',
  reconnectDialogDescription: 'reconnect-dialog-description',
  reconnectDialogButton: 'reconnect-dialog-button',
  reconnectDialogConfirmButton: 'reconnect-dialog-confirm-button',
  addNewButton: 'add-new-button',
  addCardButton: 'add-card-button',
  notAddCardButton: 'not-add-card-button',
  createReportTemplate: 'create-report=template',

  scale: 'scale',
  autoMeasure: 'auto-measure',
  autoMeasure2: 'auto-measure-2',
  askAi: 'ask-ai',
  comparePage: 'compare-page',
  textSearch: 'text-search',
  exportPdf: 'export',
  styleFilter: 'style-filter',
  twoDView: '2d-view',
  threeDView: '3d-view',
  createReport: 'create-report',
  spreadsheetPage: 'spreadsheet-page',
  itemReport: 'items-report',
  takeoffReport: 'takeoffs-report',
  settings: 'settings',
  openFileManager: 'open-file-manager',
  measurementManager: 'measurement-manager',
  addNewGroup: 'add-new-group',
};
