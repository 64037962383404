import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './database-header.scss';

import { State as ReduxState } from '../../../common/interfaces/state';
import { KreoButton, KreoDialogActions, MaterialMenuItem, MaterialSelect } from '../../../common/UIKit';
import { MaterialComponentType } from '../../../common/UIKit/material/interfaces';
import { AppUrls } from '../../../routes/app-urls';
import { DatabaseCommonActions } from '../../../units/databases/actions/creators/database-common';
import { DatabaseModel } from '../../../units/databases/interfaces/data';
import {
  ActivityAssignmentDatabaseActivityListingDialog,
// eslint-disable-next-line max-len
} from '../../../units/projects/components/activity-assignment-database-activity-listing/activity-assignment-database-activity-listing-dialog';
import { InternalHeaderMenuItem, InternalHeaderMenuWrapper } from '../InternalHeaderMenu';

interface ReduxProps {
  currentDatabase: DatabaseModel;
  databases: DatabaseModel[];
  elementId: number;
  projectId: number;
  selectedActivityVariantIds: number[];
  disabledActivityVariantIds: number[];
}

interface ReduxActions {
  openAssignDialog: () => void;
  selectDatabase: (databaseId: number, projectId: number, elementId: number) => void;
}

interface Props extends ReduxProps, ReduxActions { }

class DatabaseAssignmentHeaderComponent extends React.Component<Props> {

  public render(): JSX.Element {
    const { currentDatabase, databases } = this.props;
    const selectedCount = this.props.selectedActivityVariantIds.length;
    const disabledCount = this.props.disabledActivityVariantIds.length;
    const isAssignButtonDisabled = selectedCount - disabledCount < 1;

    const menu = [
      (
      <InternalHeaderMenuItem
        key={0}
        link={AppUrls.plan.project.validation.activityAssignmentDbActivities.url({
          projectId: this.props.projectId.toString(),
          dbId: this.props.currentDatabase && this.props.currentDatabase.id.toString(),
          endElementId: this.props.elementId && this.props.elementId.toString(),
        })}
        name='Activities'
      />),
      (
      <InternalHeaderMenuItem
        key={1}
        link={AppUrls.plan.project.validation.activityAssignmentDbResources.url({
          projectId: this.props.projectId.toString(),
          dbId: this.props.currentDatabase && this.props.currentDatabase.id.toString(),
          endElementId: this.props.elementId && this.props.elementId.toString(),
        })}
        name='Resources'
      />),
    ];

    return (
      <React.Fragment>
        {this.isDbActivityAssignmentDataLoaded() ? (
          <MaterialSelect
            value={currentDatabase.id}
            onChange={this.onChangeDb}
            className='database-header__select'
            displayedType={MaterialComponentType.Native}
          >
            {databases.map(x => (
              <MaterialMenuItem key={x.id} value={x.id}>
                {x.name}
              </MaterialMenuItem>
            ))}
          </MaterialSelect>
        ) : null}
        <InternalHeaderMenuWrapper>
          {menu}
        </InternalHeaderMenuWrapper>
        <div className='database-header__buttons'>
          <KreoButton
            mode='submit'
            size='medium'
            disabled={isAssignButtonDisabled}
            onClick={this.props.openAssignDialog}
          >
            {`Assign selected Activity Variant${selectedCount - disabledCount > 1 ? 's' : ''}`}
          </KreoButton>
        </div>
      </React.Fragment>
    );
  }

  private isDbActivityAssignmentDataLoaded(): boolean {
    const { currentDatabase, databases } = this.props;
    return currentDatabase && databases && databases.length !== 0;
  }

  @autobind
  private onChangeDb(event: React.SyntheticEvent<{}>, id: number): void {
    event.stopPropagation();
    const selectedDatabase = this.props.databases.find(x => x.id === id);
    if (selectedDatabase && selectedDatabase.id !== this.props.currentDatabase.id &&
      this.props.projectId && this.props.elementId
    ) {
      this.props.selectDatabase(selectedDatabase.id, this.props.projectId, this.props.elementId);
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    databases: state.database.databases.filter(x => !x.version || x.vendor),
    selectedActivityVariantIds: state.database.currentDatabase.activityListing.selectedActivityVariantIds,
    disabledActivityVariantIds: state.database.currentDatabase.activityListing.disabledActivityVariantIds,
    currentDatabase: state.database.currentDatabase.database,
    elementId: state.activityAssignment.selectedWork && state.activityAssignment.selectedWork.id,
    projectId: state.projects.currentProject.id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    openAssignDialog: () =>
      dispatch(KreoDialogActions.openDialog(ActivityAssignmentDatabaseActivityListingDialog.dialogName)),
    selectDatabase: (databaseId, projectId, elementId) => {
      dispatch(DatabaseCommonActions.dropCurrentDatabaseState());
      dispatch(push(AppUrls.plan.project.validation.activityAssignmentDbActivities.url({
        dbId: databaseId.toString(),
        endElementId: elementId.toString(),
        projectId: projectId.toString(),
      })));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const DatabaseActivityAssignmentHeader = connector(DatabaseAssignmentHeaderComponent);
