import React from 'react';
import { BillingPeriodUnit } from '../../../../enums/billing-period-unit';

type UseHandlePlanClickCallback = (
  billingPeriod: BillingPeriodUnit,
  onBillingPeriodChanged: (value: BillingPeriodUnit) => void,
  onSubscriptionPlanGroupClick: (value: string) => void,
  handleClose: () => void,
) => (name: string) => void;

export const useHandlePlanClickCallback: UseHandlePlanClickCallback = (
  billingPeriod,
  onBillingPeriodChanged,
  onSubscriptionPlanGroupClick,
  handleClose,
) => {
  return React.useCallback((groupName: string) => {
    onBillingPeriodChanged(billingPeriod);
    onSubscriptionPlanGroupClick(groupName);
    handleClose();
  }, [onSubscriptionPlanGroupClick, handleClose, billingPeriod, onBillingPeriodChanged]);
};
