import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  height: 100%;
  background-color: ${props => props.theme.color.backgroundRush};
  border-radius: 15px 0 0 15px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  > div:nth-child(3) {
    backdrop-filter: none;
  }
`;

export const Styled = {
  Container,
};
