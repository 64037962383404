import React from 'react';
import { ConfirmationDialog } from '../confirmation-dialog';

export const CONTANC_OWNER_DIALOG_NAME = `CONTANC_OWNER_DIALOG_NAME`;

export const ContactOwnerDialog: React.FC = () => {
  return (
    <ConfirmationDialog
      name={CONTANC_OWNER_DIALOG_NAME}
      title='The feature is not available.'
      text='Contact your company owner to add the feature, please'
      cancelButtonText='Okay'
    />
  );
};
