import * as t from 'io-ts';

export enum UserBidPricingStatus {
  AcceptedInvite = 'AcceptedInvite',
  Estimated = 'Estimated',
  Canceled = 'Canceled',
  Winner = 'Winner',
  Invited = 'Invited',
}

export const UserBidPricingStatusC = t.keyof({
  [UserBidPricingStatus.AcceptedInvite]: null,
  [UserBidPricingStatus.Canceled]: null,
  [UserBidPricingStatus.Estimated]: null,
  [UserBidPricingStatus.Invited]: null,
  [UserBidPricingStatus.Winner]: null,
});
