import { ModalWrapper } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Spinner } from 'common/components/spinner';
import { ConstantFunctions } from 'common/constants/functions';
import { SubscriptionType } from 'common/constants/subscription';
import { CurrentCountry } from 'common/environment/current-country-service';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { CompanySubscriptionModel } from '../../../../units/account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../units/account/selectors';
import { SubscriptionActions } from '../../actions/creators';
import { BillingPeriodUnit } from '../../enums/billing-period-unit';
import { EstimateSubscriptionError } from '../../interfaces';
import { EstimateSubscriptionForm } from '../../interfaces/estimate-subscription-form';
import { PaymentSourcesModel } from '../../interfaces/payment-sources-model';
import { SubscriptionAddon } from '../../interfaces/subscription-addon';
import { SubscriptionDetailsModel } from '../../interfaces/subscription-details';
import { SubscriptionPlanListingModel } from '../../interfaces/subscription-plan-listing-model';
import { SubscriptionPlanVariantWithName } from '../../interfaces/subscription-plan-variant-with-name';
import { TempInvitePeople } from '../../store/interface';
import { SubscriptionDetailsNewUser } from '../subscription-details-new-user';

export const SUBSCRIBE_NEW_USER_APPROVE_DIALOG = 'SUBSCRIBE_NEW_USER_APPROVE_DIALOG';
const UPDATE_ESTIMATION_DELAY = 1000;

interface DispatchProps {
  closeDialog: () => void;
  getSubscriptionEstimation: (subscriptionType: SubscriptionType, form: EstimateSubscriptionForm) => void;
}

interface StateProps {
  isOpen: boolean;
  paymentSources: PaymentSourcesModel;
  billingEstimationError: EstimateSubscriptionError;
  currentCountry: CurrentCountry;
  subscription: CompanySubscriptionModel;
  plansModel: SubscriptionPlanListingModel | null;
  showLoading: boolean;
  isHandleSubscriptionUpdate: boolean;
  invitedPeoples: TempInvitePeople[];
}

interface Props extends DispatchProps, StateProps {
  onCreateSubscriptionClick: (
    quantity: number,
    paymentCardId: string,
    billingCountry: string,
    vatNumber: string,
    coupons: string[],
  ) => void;
  onSelectedAddonGroupsChanged: (addonIds: string[]) => void;
  onBillingPeriodChanged: (billingPeriod: BillingPeriodUnit) => void;
  addons: SubscriptionAddon[];
  selectedAddonIds: string[];
  billingPeriod: BillingPeriodUnit;
  planVariant: SubscriptionPlanVariantWithName;
  subscriptionType: SubscriptionType;
}

interface OwnState {
  teamSize: number;
  paymentCardId: string;
  billingCountry: string;
  vatNumber: string;
  coupons: string[];
}

class SubscribeNewUserApproveDialogComponent extends React.Component<Props, OwnState> {
  private getEstimationDeferredExecutor: DeferredExecutor = new DeferredExecutor(UPDATE_ESTIMATION_DELAY);

  constructor(props: Props) {
    super(props);
    const teamSize = props.invitedPeoples
      ? props.invitedPeoples.filter(p => p.email && !p.email.endsWith('@kreo.net')).length + 1
      : 1;
    this.state = {
      teamSize,
      paymentCardId: null,
      billingCountry: null,
      vatNumber: null,
      coupons: [],
    };
  }

  public componentDidMount(): void {
    this.getEstimationIfNeeded();
  }

  public componentDidUpdate(prevProps: Props, prevState: OwnState): void {
    this.getEstimationIfNeeded(prevState, prevProps);
    this.updateInitialStateIfAllDataIsReady(prevProps);
  }

  public componentWillUnmount(): void {
    this.onClose();
  }

  public render(): JSX.Element {
    if (!this.props.planVariant) {
      return null;
    }

    const paymentCardId = this.getPaymentCardId();
    const canSubscribe = paymentCardId && this.state.billingCountry;

    return (
      <DialogWrapper name={SUBSCRIBE_NEW_USER_APPROVE_DIALOG}>
        <ModalWrapper onExit={ConstantFunctions.preventDefault}>
          <SubscriptionDetailsNewUser
            estimationError={this.props.billingEstimationError}
            addons={this.props.addons}
            applyButtonCaption={this.getApplyButtonCaption()}
            currentSubscription={{
              planVariant: this.props.planVariant,
              teamSize: this.state.teamSize,
              selectedAddonIds: this.props.selectedAddonIds,
              billingPeriod: this.props.billingPeriod,
              coupons: this.state.coupons,
            }}
            header={'Start your 7-day free trial'}
            currentTeamSize={this.state.teamSize}
            minTeamSize={1}
            onApplyClick={canSubscribe ? this.onApplyClick : null}
            onSubscriptionChange={this.onSubscriptionChange}
            onChangePaymentCardId={this.onChangePaymentCardId}
            onCountryIdChanged={this.onCountryIdChanged}
            onVatNumberIdChanged={this.onVatNumberIdChanged}
            paymentCardId={paymentCardId}
            countryId={this.state.billingCountry}
            vatNumber={this.state.vatNumber}
            isHandleInProgress={this.props.isHandleSubscriptionUpdate}
            closeDialog={this.onClose}
          />
          <Spinner show={this.props.showLoading} withBackground={true} />
        </ModalWrapper>
      </DialogWrapper>
    );
  }

  private updateInitialStateIfAllDataIsReady(prevProps: Props): void {
    const { currentCountry, paymentSources } = this.props;
    if (paymentSources && !isEqual(paymentSources, prevProps.paymentSources)) {
      const paymentCardId = paymentSources.paymentSources.some(x => x.id === this.state.paymentCardId)
        ? this.state.paymentCardId
        : undefined;
      this.setState({
        paymentCardId: paymentCardId || paymentSources.primaryCardId,
        billingCountry: this.state.billingCountry || paymentSources.billingCountry || currentCountry?.country,
        vatNumber: this.state.vatNumber || paymentSources.vatNumber,
      });
    }
  }

  private getPaymentCardId(): string {
    const paymentSources = this.props.paymentSources;
    const selected = paymentSources && paymentSources.paymentSources.find(x => x.id === this.state.paymentCardId);
    const primaryCardId = paymentSources && paymentSources.primaryCardId;

    return selected ? selected.id : primaryCardId;
  }

  @autobind
  private onChangePaymentCardId(paymentCardId: string): void {
    this.setState({ paymentCardId });
  }

  @autobind
  private onCountryIdChanged(billingCountry: string): void {
    this.setState({ billingCountry });
  }

  @autobind
  private onVatNumberIdChanged(vatNumber: string): void {
    this.setState({ vatNumber });
  }


  @autobind
  private onSubscriptionChange(model: SubscriptionDetailsModel): void {
    if (this.props.billingPeriod !== model.billingPeriod) {
      this.props.onBillingPeriodChanged(model.billingPeriod);
    }

    if (this.props.selectedAddonIds !== model.selectedAddonIds) {
      const selectedGroupNames = this.props.addons
        .filter(x => model.selectedAddonIds.includes(x.id))
        .map(x => x.groupName);
      this.props.onSelectedAddonGroupsChanged(selectedGroupNames);
    }

    if (this.state.teamSize !== model.teamSize) {
      this.setState({ teamSize: model.teamSize });
    }

    if (this.state.coupons !== model.coupons) {
      this.setState({ coupons: model.coupons });
    }
  }

  @autobind
  private onApplyClick(): void {
    const { teamSize, billingCountry, vatNumber } = this.state;
    const paymentCardId = this.getPaymentCardId();
    this.props.onCreateSubscriptionClick(teamSize, paymentCardId, billingCountry, vatNumber, this.state.coupons);
  }

  private getApplyButtonCaption(): string {
    const { planVariant: variant, subscription, plansModel } = this.props;
    const isFreePlan = plansModel
      && plansModel.plans.find(x => x.variants.find(v => v.id === subscription?.planId))?.isFree;
    if (isFreePlan) {
      return 'Buy now';
    }

    return variant.trialPeriod
      ? `Start ${variant.trialPeriod} ${variant.trialPeriodUnit} free trial`
      : 'Start';
  }

  private getForm(
    { planVariant, selectedAddonIds }: Props,
    { teamSize, vatNumber, billingCountry, coupons }: OwnState,
  ): EstimateSubscriptionForm {
    const planId = planVariant.id;
    const quantity = this.getQuantity(planVariant.isCollaborationBlocked, teamSize);

    return {
      addons: selectedAddonIds,
      quantity,
      planId,
      vatNumber,
      billingCountry,
      coupons,
      reactivate: false,
    };
  }

  private getEstimationIfNeeded(prevState?: OwnState, prevProps?: Props): void {
    if (!this.state.billingCountry || !this.props.isOpen) {
      return;
    }

    const currentForm = this.getForm(this.props, this.state);
    const prevForm = this.getForm(prevProps, prevState);

    if (currentForm.billingCountry === prevForm.billingCountry
      && currentForm.vatNumber === prevForm.vatNumber
      && currentForm.addons === prevForm.addons
      && currentForm.planId === prevForm.planId
      && currentForm.quantity === prevForm.quantity
      && currentForm.coupons === prevForm.coupons) {
      return;
    }

    this.getEstimationDeferredExecutor.execute(() => {
      const { subscriptionType } = this.props;
      const form = this.getForm(this.props, this.state);

      this.props.getSubscriptionEstimation(subscriptionType, form);
    });
  }

  private getQuantity(isCollaborationBlocked: boolean, teamSize: number): number {
    return isCollaborationBlocked
      ? 1
      : teamSize;
  }

  @autobind
  private onClose(): void {
    this.props.closeDialog();
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    paymentSources: state.account.payment,
    isOpen: SUBSCRIBE_NEW_USER_APPROVE_DIALOG in state.dialog,
    currentCountry: state.account.currentCountry,
    subscription: AccountSelectors.currentSubscription(state),
    plansModel: state.account.subscriptionPlans,
    showLoading: state.account.showLoadingCreateSubscription,
    billingEstimationError: state.account.billingEstimationError,
    isHandleSubscriptionUpdate: state.account.isHandleSubscriptionUpdate,
    invitedPeoples: state.subscription.tempInvitePeople,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(SUBSCRIBE_NEW_USER_APPROVE_DIALOG)),
    getSubscriptionEstimation: (subscriptionType, form) => {
      console.warn('getSubscriptionEstimation');
      dispatch(SubscriptionActions.getCreateSubscriptionEstimation(subscriptionType, form));
    },
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const SubscribeNewUserApproveDialog = connector(SubscribeNewUserApproveDialogComponent);
