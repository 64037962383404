import { Icons, IconButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Text } from 'common/components/text';
import { pushSearch } from '../../../../actions/common';
import { Styled } from './styled';


export const HeaderDialog: React.FC = () => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(pushSearch({ demoProject: undefined }));
  }, [dispatch]);

  return (
    <Styled.Container>
      <Styled.HeaderName>
        <Icons.DemoProjectNavigation />
        <Text fontSize={14}>Demo projects</Text>
      </Styled.HeaderName>
      <IconButton
        Icon={Icons.CloseSmall}
        onClick={handleClose}
        width={20}
        height={20}
        iconHeight={10}
      />
    </Styled.Container>
  );
};
