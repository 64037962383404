import { DrawingsInstanceType } from '../../enums';
import {
  DrawingsGeometryInstance,
  DrawingsInstanceMeasure,
  DrawingsMeasureCount,
  DrawingsSelectAggregationGroup,
} from '../../interfaces';
import { DrawingsGeometryUtils } from '../drawings-geometry-utils';
import { DrawingsMeasureUtils } from '../drawings-measure-utils';

interface AggregationResult {
  names: string[];
  type: Record<string, string[]>;
  strokeStyle: Record<string, string[]>;
  strokeWidth: Record<string, string[]>;
  color: Record<string, string[]>;
  shape: Record<string, string[]>;
  hasStroke: boolean;
  hasCount: boolean;
  group: Record<string, string[]>;
  measures: Record<string, number>;
  height: Record<string, string[]>;
  thickness: Record<string, string[]>;
  offsets: Record<string, string[]>;
}

function saveMeasures(
  getInstanceMeasures: (instanceId: string[]) => DrawingsInstanceMeasure[],
  instanceId: string,
  measures: Record<string, number>,
  ignoreMeasures: string[] = [],
): void {
  measures.count = measures.count + 1;
  const instanceMeasures = getInstanceMeasures([instanceId])[0];
  for (const [key, value] of Object.entries(instanceMeasures.measures)) {
    if (value === undefined || value === null || ignoreMeasures.includes(key)) {
      continue;
    }
    measures[key] = (measures[key] || 0) + value;
  }
}

function aggregateInstances(
  instancesToAggregate: string[],
  instances: Record<string, DrawingsGeometryInstance>,
  getInstanceMeasures?: (instanceId: string[]) => DrawingsInstanceMeasure[],
): AggregationResult {
  const instancesTypes: Record<DrawingsSelectAggregationGroup, string[]> = {
    [DrawingsSelectAggregationGroup.Count]: [],
    [DrawingsSelectAggregationGroup.Line]: [],
    [DrawingsSelectAggregationGroup.Area]: [],
    [DrawingsSelectAggregationGroup.Unknown]: [],
  };
  const strokeStyles: Record<string, string[]> = {};
  const strokeWidths: Record<string, string[]> = {};
  const colors: Record<string, string[]> = {};
  const shapes: Record<string, string[]> = {};
  const groupInstances: Record<string, string[]> = {};
  const height: Record<string, string[]> = {};
  const thickness: Record<string, string[]> = {};
  const measures: Record<string, number> = {
    count: 0,
  };
  const offsets: Record<string, string[]> = {};
  const names = new Array<string>();
  let hasCount = false;
  let hasStroke = false;

  const saveStroke = (
    instanceId: string,
    { strokeStyle, strokeWidth, offset }: { strokeStyle: string, strokeWidth: number, offset?: number },
  ): void => {
    strokeStyles[strokeStyle] = strokeStyles[strokeStyle] || [];
    strokeStyles[strokeStyle].push(instanceId);

    strokeWidths[strokeWidth] = strokeWidths[strokeWidth] || [];
    strokeWidths[strokeWidth].push(instanceId);

    offsets[offset] = offsets[offset] || [];
    offsets[offset].push(instanceId);
    hasStroke = true;
  };

  for (const instanceId of instancesToAggregate) {
    const instance = instances[instanceId];
    if (!instance) {
      continue;
    }
    const { type, geometry, groupId, name } = instance;
    names.push(name);
    const groupKey = groupId || 'undefined';
    groupInstances[groupKey] = groupInstances[groupKey] || [];
    groupInstances[groupKey].push(instanceId);

    colors[geometry.color] = colors[geometry.color] || [];
    colors[geometry.color].push(instanceId);

    if (DrawingsGeometryUtils.isClosedContour(type, geometry)) {
      if (getInstanceMeasures) {
        const ignoreMeasures = DrawingsMeasureUtils.getPolygonIgnoreMeasures(geometry);
        saveMeasures(getInstanceMeasures, instanceId, measures, ignoreMeasures);
      }
      instancesTypes[DrawingsSelectAggregationGroup.Area].push(instanceId);
      saveStroke(instanceId, geometry);
      height[geometry.height] = height[geometry.height] || [];
      height[geometry.height].push(instanceId);
    } else if (DrawingsGeometryUtils.isPolyline(type, geometry)) {
      instancesTypes[DrawingsSelectAggregationGroup.Line].push(instanceId);
      saveStroke(instanceId, geometry);
      if (getInstanceMeasures) {
        const ignoreMeasures = DrawingsMeasureUtils.getPolylineIngoreMeasures(geometry);
        saveMeasures(getInstanceMeasures, instanceId, measures, ignoreMeasures);
      }
      height[geometry.height] = height[geometry.height] || [];
      height[geometry.height].push(instanceId);
      thickness[geometry.thickness] = thickness[geometry.thickness] || [];
      thickness[geometry.thickness].push(instanceId);
    } else if (DrawingsGeometryUtils.isCount(type, geometry)) {
      shapes[geometry.shape] = shapes[geometry.shape] || [];
      shapes[geometry.shape].push(instanceId);
      if (getInstanceMeasures) {
        measures.count = measures.count + (getInstanceMeasures([instanceId])[0].measures as DrawingsMeasureCount).count;
      }
      instancesTypes[DrawingsSelectAggregationGroup.Count].push(instanceId);
      hasCount = true;
    }
  }
  return {
    type: Object.entries(instancesTypes).reduce((acc, [key, value]) => {
      if (value.length > 0) {
        acc[key] = value;
      }
      return acc;
    }, {}),
    strokeStyle: strokeStyles,
    strokeWidth: strokeWidths,
    color: colors,
    shape: shapes,
    group: groupInstances,
    measures,
    names,
    hasStroke,
    hasCount,
    height,
    thickness,
    offsets,
  };
}


const INSTANCE_TYPE_TO_AGGREGATION = {
  [DrawingsInstanceType.Polygon]: DrawingsSelectAggregationGroup.Area,
  [DrawingsInstanceType.Rectangle]: DrawingsSelectAggregationGroup.Area,
  [DrawingsInstanceType.Polyline]: DrawingsSelectAggregationGroup.Line,
  [DrawingsInstanceType.Count]: DrawingsSelectAggregationGroup.Count,
};

export const DrawingInstancesAggregationUtils = {
  aggregateInstances,
  INSTANCE_TYPE_TO_AGGREGATION,
};
