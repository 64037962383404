import * as t from 'io-ts';

import { UserBidPricingStatusC } from '../../enums/user-bid-pricing-status';

export const BidPricingUserInfoC = t.exact(
  t.intersection([
    t.type({
      id: t.string,
      firstName: t.string,
      lastName: t.string,
    }),
    t.partial({
      email: t.string,
      image: t.number,
    }),
  ]),
  'BidPricingCalculationDetails');

export type BidPricingUserInfo = t.TypeOf<typeof BidPricingUserInfoC>;


export const BidPricingUserCostC = t.strict(
  {
    id: t.number,
    duration: t.number,
    indirectCost: t.number,
    laborCost: t.number,
    materialCost: t.number,
    plantCost: t.number,
    totalCost: t.number,
    status: UserBidPricingStatusC,
    name: t.string,
    levelOfNesting: t.number,
    packageId: t.number,
    description: t.string,
    user: BidPricingUserInfoC,
  },
  'BidPricingUserCost');

export type BidPricingUserCost = t.TypeOf<typeof BidPricingUserCostC>;
