import * as t from 'io-ts';

export const ActivityAssignmentSubVariantMaterialC = t.exact(
  t.type({
    name: t.string,
    materialId: t.number,
  }),
  'ActivityAssignmentSubVariantMaterial');

export type ActivityAssignmentSubVariantMaterial = t.TypeOf<typeof ActivityAssignmentSubVariantMaterialC>;
