import styled from 'styled-components';

const Counter = styled.div<{ active: boolean }>`
  box-sizing: border-box;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${p => p.theme.color.lightGray};
  border-radius: 5px;
  transition: all ${p => p.theme.duration.s} linear;
  background-color: ${p => p.active ? p.theme.color.turquoise : p.theme.color.disabled};
`;

export const Styled = {
  Counter,
};
