import * as React from 'react';

export const KreoIconCellSelect: React.FC = () => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          // eslint-disable-next-line max-len
          d='M19.24,15.35l-1.59-1.59,1-1a1.25,1.25,0,0,0-.6-2.1L14.75,10V3A2.75,2.75,0,0,0,12,.25H3A2.75,2.75,0,0,0,.25,3v9A2.75,2.75,0,0,0,3,14.75h7L10.7,18a1.27,1.27,0,0,0,.87.92,1.29,1.29,0,0,0,1.24-.32l.95-1,1.59,1.59a1.75,1.75,0,0,0,2.47,0l1.42-1.42A1.75,1.75,0,0,0,19.24,15.35ZM3,13.25A1.25,1.25,0,0,1,1.75,12V3A1.25,1.25,0,0,1,3,1.75h9A1.25,1.25,0,0,1,13.25,3V9.61L10.46,9A1.22,1.22,0,0,0,9.3,9.3,1.24,1.24,0,0,0,9,10.46l.65,2.79Zm15.18,3.51-1.42,1.42a.26.26,0,0,1-.35,0l-2.12-2.12a.74.74,0,0,0-.53-.22.73.73,0,0,0-.53.22l-1.17,1.16-1.54-6.7,6.7,1.54-1.16,1.17a.74.74,0,0,0,0,1.06l2.12,2.12A.26.26,0,0,1,18.18,16.76Z'
        />
      </g>
    </svg>
  );
};
