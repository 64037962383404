import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsGeometryUtils } from 'common/components/drawings';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsSimplifiedBoundingRect } from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsGeometryCopyPasteBuffer } from '../../drawings-helpers';
import { ShapeFactory } from '../../interfaces';
import { BoundingRect } from '../utility';
import {
  DrawingsGeometryPreviewCount,
  DrawingsGeometryPreviewPolygon,
  DrawingsGeometryPreviewPolyline,
} from './preview';

interface DrawingsGeometryCopyWithPointPreviewConfig {
  shapeCreator: ShapeFactory;
  instances: DrawingsGeometryCopyPasteBuffer;
  colorCache: DrawingsColorCacheHelper;
  textRenderParametersObserver: ContextObserver<MeasuresViewSettings>;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  getCurrentPageInfo: () => Core.Document.PageInfo;
  getColorOfLabel: (label: string) => string;
}

export class DrawingsGeometryCopyWithPointPreview {
  private _config: DrawingsGeometryCopyWithPointPreviewConfig;
  private _bounds: DrawingsSimplifiedBoundingRect;
  private _startPosition: paper.Point;
  private _rendered: boolean = false;

  private _hasError: boolean;
  private _group: paper.Group;
  private _boundingRect: BoundingRect;

  private _polygons: DrawingsGeometryPreviewPolygon[] = [];
  private _counts: DrawingsGeometryPreviewCount[] = [];
  private _polylines: DrawingsGeometryPreviewPolyline[] = [];
  private _positionDiff: paper.Point;

  constructor(config: DrawingsGeometryCopyWithPointPreviewConfig) {
    this._config = config;
    this._group = new paper.Group(config.instances);
    this._group.strokeWidth = 0;
    this.renderInstances(config.instances);
    config.renderParametersContextObserver.subscribe(this.updateRenderParams);
    config.textRenderParametersObserver.subscribe(this.updateRenderRotation);
  }

  public set position(value: paper.Point) {
    this._group.position = value.subtract(this._positionDiff);
    this._boundingRect.position = this._group.position;
    const { width, height } = this._config.getCurrentPageInfo();
    const { right, left, top, bottom } = this._boundingRect.bounds;
    const hasError = right > width || left < 0 || top < 0 || bottom > height;
    if (hasError !== this._hasError) {
      this._hasError = hasError;
      this._polygons.forEach(x => x.hasError = hasError);
      this._polylines.forEach(x => x.hasError = hasError);
      this._counts.forEach(x => x.hasError = hasError);
    }
  }

  public get canPaste(): boolean {
    return !this._hasError && this._rendered;
  }

  public destroy(): void {
    this._group.remove();
    this._boundingRect.destroy();
  }

  private renderInstances(instances: DrawingsGeometryCopyPasteBuffer): void {
    const lefts = [];
    const rights = [];
    const tops = [];
    const bots = [];
    for (const { type, geometry } of instances.geometry) {
      let preview: DrawingsGeometryPreviewPolygon | DrawingsGeometryPreviewPolyline | DrawingsGeometryPreviewCount;
      if (DrawingsGeometryUtils.isClosedContour(type, geometry)) {
        const color = geometry.color;
        preview = new DrawingsGeometryPreviewPolygon({
          geometry,
          getPoint: id => new paper.Point(instances.points[id]),
          color: this._config.colorCache.getPaperColor(color),
          group: this._group,
        });
        this._polygons.push(preview);
      } else if (DrawingsGeometryUtils.isCount(type, geometry)) {
        preview = new DrawingsGeometryPreviewCount({
          geometry,
          getPoint: id => new paper.Point(instances.points[id]),
          color: this._config.colorCache.getPaperColor(geometry.color),
          group: this._group,
          textRenderParametersObserver: this._config.textRenderParametersObserver,
          renderParamsContextObserver: this._config.renderParametersContextObserver,
          shapeCreator: this._config.shapeCreator,
        });
        this._counts.push(preview);
      } else if (DrawingsGeometryUtils.isPolyline(type, geometry)) {
        preview = new DrawingsGeometryPreviewPolyline({
          geometry,
          getPoint: id => new paper.Point(instances.points[id]),
          color: this._config.colorCache.getPaperColor(geometry.color),
          group: this._group,
        });
        this._polylines.push(preview);
      }
      const { left, right, top, bottom } = preview.bounds;
      lefts.push(left);
      rights.push(right);
      tops.push(top);
      bots.push(bottom);
    }
    this._bounds = {
      left: Math.min(...lefts),
      right: Math.max(...rights),
      top: Math.min(...tops),
      bottom: Math.max(...bots),
    };
    const size = new paper.Size(this._bounds.right - this._bounds.left, this._bounds.bottom - this._bounds.top);
    const rect = new paper.Rectangle(new paper.Point(this._bounds.left, this._bounds.top), size);
    this._boundingRect = new BoundingRect(
      {
        geometry: rect,
        renderParamsContextObserver: this._config.renderParametersContextObserver,
        layer: this._group,
      },
    );
    this._startPosition = this._group.position;
    this._rendered = true;
  }

  @autobind
  private updateRenderRotation(params: MeasuresViewSettings): void {
    const leftTop = DrawingsCanvasUtils.getTopLeftPointByRotation(this._bounds, params.rotation);
    this._positionDiff = new paper.Point(leftTop).subtract(this._startPosition);
    this._counts.forEach(x => x.updateLabels(params));
  }

  @autobind
  private updateRenderParams(params: DrawingsRenderParams): void {
    this._polylines.forEach(x => x.zoom = params.zoom);
    this._polygons.forEach(x => x.zoom = params.zoom);
    this._counts.forEach(x => x.updateRenderParams(params));
  }
}
