import * as classNames from 'classnames';
import * as React from 'react';

import './editable-cost-rate-cell.scss';

import Input from '../../input';
import * as Utils from '../utils';

interface EditableCostRateCellProps {
  rate: number | undefined;
  rateFieldName: string;
  cost: number | undefined;
  costFieldName: string;
  className?: string;
  onInputChange: (value: string, fieldName: string) => void;
}

export const EditableCostRateCell: React.FC<EditableCostRateCellProps> = (props) => {
  const cellClassName = classNames('cost-estimate-table__price', props.className);

  return (
    <td className={cellClassName}>
      <div className='rate'>
        <Input
          onChange={props.onInputChange}
          value={props.rate ? Utils._formatter(props.rate.toString(), 2) : ''}
          fieldName={props.rateFieldName}
        />
      </div>
      <div className='cost'>
        <Input
          onChange={props.onInputChange}
          value={props.cost ? Utils._formatter(props.cost.toString(), 2) : ''}
          fieldName={props.costFieldName}
        />
      </div>
    </td>
  );
};
