import {
  ElementTooltip,
  IconButton,
  Icons,
  MenuItem,
  MovableContextMenu,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import {
  CREATE_TEMPLATE_DIALOG_NAME,
  CreateTemplateDialog,
} from '../../../../../components/dialog/create-template-dialog';
import { PersistedStorageActions } from '../../../../../units/persisted-storage/actions/creators';
import { DrawingsAnnotationLegendActions } from '../../actions/creators/drawings-annotation-legend';
import { DrawingsRendererApiContextProps, withRendererApiContext } from '../../drawings-contexts';
import { DrawingsGroupTemplate, GroupTemplateShortInfo } from '../../interfaces/drawings-group-template';
import { Styled } from './styled';
import { SwitcherItem } from './switcher-item';
import { TemplatesMenuItem } from './templates-menu';

interface StateProps {
  templates: DrawingsGroupTemplate[];
  isHideGroups: boolean;
  showOnlyGroups: boolean;
  pivotMeasurements: boolean;
}

interface DispatchProps {
  closeCreateTemplateDialog: () => void;
  createGroupTemplate: (templateInfo: GroupTemplateShortInfo) => void;
  updateGroupTemplate: (templateId: number, templateInfo: GroupTemplateShortInfo) => void;
  toggleHideGroups: () => void;
  toggleShowOnlyGroups: () => void;
  togglePivotMeasurements: () => void;
}

interface Props extends DispatchProps, StateProps, DrawingsRendererApiContextProps, AnalyticsProps, AbilityAwareProps {
  canEditMeasurement: boolean;
  toggleQuickSearch: () => void;
  setQuickSearchValue: (value: string) => void;
  deleteGroups: () => void;
  invertSelection: () => void;
}

interface ComponentState {
  menuPositionX: number;
  menuPositionY: number;
  isOpen: boolean;
}

class DrawingsAnnotationLegendMeasurementsMenuComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      menuPositionX: 0,
      menuPositionY: 0,
    };
  }

  public render(): JSX.Element {
    return (
      <>
        <IconButton
          Icon={Icons.Burger_1Small}
          onClick={this.onOpen}
          width={20}
          height={20}
        />
        {this.state.isOpen && <MovableContextMenu
          onClose={this.onClose}
          x={this.state.menuPositionX}
          y={this.state.menuPositionY}
        >
          <Styled.MenuContainer>
            <Styled.MenuItemWrapper withBorder={true}>
              <MenuItem fontSize={14} text='Search' Icon={Icons.Search} onClick={this.toggleSearch}/>
            </Styled.MenuItemWrapper>
            <SwitcherItem
              title='Hide empty groups'
              onChange={this.props.toggleHideGroups}
              value={this.props.isHideGroups}
            />
            <SwitcherItem
              title='Show only groups'
              onChange={this.toggleShowOnlyGroups}
              value={this.props.showOnlyGroups}
            />
            <SwitcherItem
              title='Pivot'
              onChange={this.togglePivotMeasurements}
              value={this.props.pivotMeasurements}
            />
            <Styled.MenuItemWrapper withBorder={true}>
              <MenuItem
                fontSize={14}
                text='Select All'
                Icon={Icons.SelectAll}
                onClick={this.selectAll}
              />
            </Styled.MenuItemWrapper>
            {this.props.canEditMeasurement && (
              <TemplatesMenuItem
                onCloseMenu={this.onClose}
              />
            )}
            <ElementTooltip
              text='Invert the order of the measurements you’ve selected'
              position='right'
              tooltipWidth={345}
              speed='l'
            >
              <Styled.MenuItemWrapper withBorder={true}>
                <MenuItem
                  fontSize={14}
                  text='Invert selection'
                  Icon={Icons.Invert}
                  onClick={this.invertSelection}
                />
              </Styled.MenuItemWrapper>
            </ElementTooltip>
            {this.props.canEditMeasurement &&
              <ElementTooltip
                text='All the empty groups will be removed'
                position='right'
                speed='l'
              >
                <Styled.MenuItemWrapper>
                  <MenuItem
                    fontSize={14}
                    text='Delete empty groups'
                    Icon={Icons.DeleteEmptyGroups}
                    onClick={this.deleteGroups}
                  />
                </Styled.MenuItemWrapper>
              </ElementTooltip>}
          </Styled.MenuContainer>
        </MovableContextMenu>}
        <CreateTemplateDialog
          onFormSubmit={this.onSubmit}
          onCloseDialog={this.props.closeCreateTemplateDialog}
        />
      </>
    );
  }


  @autobind
  private togglePivotMeasurements(): void {
    this.props.sendToggleEvent(MetricNames.measureManager.pivot, this.props.pivotMeasurements);
    this.props.togglePivotMeasurements();
  }

  @autobind
  private toggleShowOnlyGroups(): void {
    this.props.sendToggleEvent(MetricNames.measureManager.showOnlyGroups, this.props.showOnlyGroups);
    this.props.toggleShowOnlyGroups();
  }

  @autobind
  private onOpen(e: React.MouseEvent<HTMLButtonElement>): void {
    const buttonRect = e.currentTarget.getBoundingClientRect();
    this.setState({
      isOpen: true,
      menuPositionX: buttonRect.left + 10,
      menuPositionY: buttonRect.bottom,
    });
  }

  @autobind
  private onClose(): void {
    this.setState({ isOpen: false });
  }


  @autobind
  private onSubmit(id: number, data: GroupTemplateShortInfo): void {
    if (!id) {
      this.props.createGroupTemplate(data);
    } else {
      this.props.updateGroupTemplate(id, data);
    }
  }

  @autobind
  private toggleSearch(): void {
    this.props.toggleQuickSearch();
    this.props.setQuickSearchValue('');
    this.onClose();
  }

  @autobind
  private deleteGroups(): void {
    this.props.deleteGroups();
    this.onClose();
  }

  @autobind
  private invertSelection(): void {
    this.props.invertSelection();
    this.onClose();
  }

  @autobind
  private selectAll(): void {
    this.props.rendererApi?.selectAll();
    this.onClose();
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    templates: state.drawings.drawingGroupTemplates,
    isHideGroups: state.persistedStorage.hideUnselectedPageGroups,
    showOnlyGroups: state.drawings.showOnlyGroups,
    pivotMeasurements: state.persistedStorage.drawingMeasurementsPivot ?? false,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    closeCreateTemplateDialog: () => dispatch(KreoDialogActions.closeDialog(CREATE_TEMPLATE_DIALOG_NAME)),
    createGroupTemplate: (templateInfo) =>
      dispatch(DrawingsAnnotationLegendActions.createGroupTemplate(templateInfo)),
    updateGroupTemplate: (templateId, templateInfo) =>
      dispatch(DrawingsAnnotationLegendActions.updateGroupTemplate(templateId, templateInfo)),
    toggleHideGroups: () => dispatch(PersistedStorageActions.toggleHideGroups()),
    toggleShowOnlyGroups: () => dispatch(DrawingsAnnotationLegendActions.toggleShowOnlyGroups()),
    togglePivotMeasurements: () => dispatch(PersistedStorageActions.toggleValue('drawingMeasurementsPivot')),
  };
}

export const DrawingsAnnotationLegendMeasurementsMenu = connect(mapStateToProps, mapDispatchToProps)(
  withRendererApiContext(withAnalyticsContext(
    withAbilityContext(DrawingsAnnotationLegendMeasurementsMenuComponent))),
);
