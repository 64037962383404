import React from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { NavMenuItem } from 'common/components/nav-menu/nav-menu-item';
import {
  KreoIconTopActivity,
  KreoIconTopDatabases,
  KreoIconTopPeople,
  KreoIconTopProjects,
  KreoIconTopSettings,
} from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { AccountSelectors } from './account/selectors';

export const getRootMenuItems = (urls): NavMenuItem[] => {
  return [
    {
      icon: <KreoIconTopProjects />,
      title: 'Projects',
      path: urls.listing.url(),
      isAvailable: () => true,
    },
    {
      icon: <KreoIconTopPeople />,
      title: 'People',
      path: urls.people.index.url(),
      isAvailable: () => true,
      hasBadge: AccountSelectors.isSelectedCompanyLicensesLimitExceeded,
    },
    {
      icon: <KreoIconTopDatabases />,
      title: 'Databases',
      path: urls.database && urls.database.listing.url(),
      isAvailable: () => true,
      isVisible: ability => ability.can(Operation.Read, Subject.Database) && urls.database && urls.database.listing,
    },
    {
      icon: <KreoIconTopActivity />,
      title: 'Activity',
      path: urls.activity.index.url(),
      isAvailable: () => true,
    },
  ];
};

export const getRootAdminMenuItems = (_urls): NavMenuItem[] =>  [
  {
    icon: <KreoIconTopPeople />,
    title: 'People',
    path: AppUrls.admin.people.url(),
    isAvailable: () => true,
  },
  {
    icon: <KreoIconTopProjects />,
    title: 'Companies',
    path: AppUrls.admin.companies.url(),
    isAvailable: () => true,
  },
  {
    icon: <KreoIconTopSettings />,
    title: 'Settings',
    path: AppUrls.admin.settings.url(),
    isAvailable: () => true,
  },
  {
    icon: <KreoIconTopSettings />,
    title: 'Demo Projects',
    path: AppUrls.admin.demoProjects.url(),
    isAvailable: () => true,
  },
];
