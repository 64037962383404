import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { Action, Dispatch } from 'redux';

import './database-entity-modify-panel.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State as ReduxState } from 'common/interfaces/state';
import { DatabaseActivityListingActions } from '../../actions/creators/database-activity-listing';
import { ModifyDatabaseEntityMode } from '../../enums';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';

interface ReduxProps {
  isReadonly: boolean;
  panelMode: ModifyDatabaseEntityMode | null;
  rootId: number | null;
  variantId: number | null;
  isOpen: boolean;
}

interface ReduxActions {
  closeModifyPanel: () => void;
}

interface Props extends ReduxProps, ReduxActions, AbilityAwareProps {
  editRootComponent: React.ComponentType<DatabaseEntityModifyWrapProps>;
  createRootComponent: React.ComponentType<DatabaseEntityModifyWrapProps>;
  editVariantComponent: React.ComponentType<DatabaseEntityModifyWrapProps>;
  createVariantComponent: React.ComponentType<DatabaseEntityModifyWrapProps>;
}

class DatabaseEntityModifyPanelComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <CSSTransition
        classNames='database-entity-modify-panel'
        in={this.props.isOpen}
        mountOnEnter={this.props.isOpen}
        unmountOnExit={true}
        timeout={400}
      >
        <div className='database-entity-modify-panel'>
          <div className='database-entity-modify-panel__wrap'>
            {this.getComponent()}
          </div>
        </div>
      </CSSTransition>
    );
  }

  @autobind
  private getComponent(): React.ReactNode {
    const isReadonly = this.props.isReadonly ||
      !this.props.ability.can(Operation.Create, Subject.Database);
    const { panelMode, rootId, variantId } = this.props;
    const {
      editRootComponent: EditRootComponent,
      createRootComponent: CreateRootComponent,
      editVariantComponent: EditVariantComponent,
      createVariantComponent: CreateVariantComponent,
    } = this.props;

    window.addEventListener('keydown', this.close);


    if (panelMode === ModifyDatabaseEntityMode.Create) {
      if (rootId) {
        return (<CreateVariantComponent readonly={isReadonly}/>);
      } else {
        return (<CreateRootComponent readonly={isReadonly}/>);
      }
    } else if (panelMode === ModifyDatabaseEntityMode.Edit) {
      if (rootId && variantId) {
        return (<EditVariantComponent readonly={isReadonly}/>);
      } else if (rootId) {
        return (<EditRootComponent readonly={isReadonly}/>);
      }
    }

    return null;
  }

  @autobind
  private close(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.props.closeModifyPanel();
      window.removeEventListener('keydown', this.close);
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    panelMode: editModel.mode,
    rootId: editModel.rootId,
    variantId: editModel.variantId,
    isOpen: editModel.isOpen,
    isReadonly: state.database.currentDatabase.database.isReadOnly,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    closeModifyPanel: () => dispatch(DatabaseActivityListingActions.closeEditPanel()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const DatabaseEntityModifyPanel = withAbilityContext(connector(DatabaseEntityModifyPanelComponent));
