import { Avatar, Text, TinyText } from '@kreo/kreo-ui-components';
import moment from 'moment';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { DateFormatConstants } from 'common/constants/date-formats';
import { State } from 'common/interfaces/state';
import { AccountApi } from '../../../../../units/account/api';
import { Person } from '../../../../../units/people/interfaces/person';
import { Styled } from './drawings-sticker-user-info-styled';

interface StateProps {
  users: Person[];
}

interface Props extends StateProps {
  createdAt: string | Date;
  creatorId: string;
}


const DrawingsStickerUserInfoComponent: React.FC<Props> = ({ users, createdAt, creatorId }) => {
  const creator = useMemo(() => users.find(x => x.id === creatorId), [creatorId, users]);
  if (!creator) {
    return null;
  }
  const name = `${creator.firstName} ${creator.lastName}`;
  return (
    <Styled.Container>
      <Styled.UserInfo>
        <Avatar
          name={name}
          size={30}
          avatar={AccountApi.getAvatarLink({ id: creator.id, hasAvatar: creator.hasAvatar })}
        />
        <Styled.TextInfo>
          <TinyText>
            {name}
          </TinyText>
          <Text color={'gray'} fontSize={10}>
            {moment(createdAt).format(DateFormatConstants.FULL_DATE_TIME_FORMAT)}
          </Text>
        </Styled.TextInfo>
      </Styled.UserInfo>
    </Styled.Container>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    users: state.people.companiesUsers,
  };
}

export const DrawingsStickerUserInfo = connect(mapStateToProps)(DrawingsStickerUserInfoComponent);
