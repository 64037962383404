import { GridApi } from 'ag-grid-community';

import { AgGridHelper } from 'common/ag-grid';
import { GraphStorageRecordsConfig } from '../../interfaces/graph-storage-records-config';
import { AGG_HASH } from '../quantity-take-off-common-table/custom-cell/aggregation-cell/aggregation-cell';
import { QTOLeftTableTabMapper } from './quantity-take-off-left-table-tab-mapper';


export class QtoElementTabMapper extends QTOLeftTableTabMapper {
  constructor(columnConfig: GraphStorageRecordsConfig) {
    super(columnConfig);
  }

  protected getAggFields(api: GridApi): Record<string, boolean> {
    const result = {};
    let afterAggHashField = false;
    const rowGroupColumns = AgGridHelper.getColumnController(api).getRowGroupColumns();

    rowGroupColumns.forEach(col => {
      const columnId = col.getColId();
      if (columnId === AGG_HASH) {
        afterAggHashField = true;
      }
      if (afterAggHashField) {
        result[columnId] = true;
      }
    });

    return result;
  }
}
