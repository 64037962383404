import { Icons, Text, CheckboxButton } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { DrawingsActions } from 'common/components/drawings/actions/creators/common';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { DrawingsRendererApiContextProps, withRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { DrawingsFile } from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingsUndoRedoHelper } from 'common/components/drawings/utils/drawings-undo-redo-helper';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { useUndoRedoFullApi } from 'common/undo-redo';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { Person } from '../../../../../../../units/people/interfaces/person';
import { OpenCloseButtons } from '../open-close-buttons';
import { DrawingsPdfBrowserFileName } from './drawings-pdf-browser-file-name';
import { Styled } from './styled';
import { useSelectChangeCallbacks } from './use-select-change-callbacks';

interface OwnProps {
  file: DrawingsFile;
  canEdit: boolean;
  multiselect: boolean;
  onChangeDrawingSelection: (id: string[], select: boolean, openLast: boolean) => void;
}

interface StateProps {
  drawings: Record<string, DrawingsShortInfo>;
  users: Person[];
  currentDrawing: DrawingsShortInfo;
}

interface DispatchProps {
  onOptimize: (value: boolean) => void;
  openUnsavedChangesDialog: (accept: () => void, cancel?: () => void) => void;
  deleteFile: () => void;
}

interface Props extends
  OwnProps,
  DispatchProps,
  StateProps,
  AnalyticsProps,
  DrawingsRendererApiContextProps,
  AbilityAwareProps {

}

const STATUSES_TO_SHOW_BUTTONS = [ViewModelStatus.Ready, ViewModelStatus.Empty];

export const DrawingsPdfBrowserFileTitleComponent: React.FC<Props> = ({
  file,
  canEdit,
  multiselect,
  onOptimize,
  drawings,
  onChangeDrawingSelection,
  sendToggleEvent,
  users,
  currentDrawing,
  rendererApi,
  openUnsavedChangesDialog,
  ability,
  deleteFile,
}) => {
  const [createdAt, setCreatedAt] = useState<string>('');
  const [selectAll, deselectAll] = useSelectChangeCallbacks(file, onChangeDrawingSelection);
  const { addUndoRedo } = useUndoRedoFullApi();

  const canOptimize = ability.can(Operation.Manage, Subject.Takeoff2dFileOptimizer);

  const checkboxData = useMemo(() => {
    let checked = true;
    let indeterminate = false;
    for (const page of file.pages) {
      if (drawings[page.drawingId]?.isOptimized) {
        indeterminate = true;
      } else {
        checked = false;
      }

      if (!checked && indeterminate) {
        break;
      }
    }
    return {
      checked,
      indeterminate,
      disabled: !canOptimize,
    };
  }, [file, drawings]);

  const creator = useMemo(() => {
    for (const page of file.pages) {
      if (drawings[page.drawingId]?.creatorId) {
        return users.find(user => {
          if (user.id === drawings[page.drawingId]?.creatorId) {
            setCreatedAt(drawings[page.drawingId]?.createdAt);
          }
          return user.id === drawings[page.drawingId]?.creatorId;
        });
      } else {
        break;
      }
    }
  }, [file, drawings, users]);

  const onSelectAll = useCallback(() => {
    const { undo, redo } = DrawingsUndoRedoHelper.getSelectDeselectAllTabsUndoRedo(
      deselectAll,
      selectAll,
    );
    addUndoRedo(undo, redo);
    redo();
  }, [file]);

  const onDeselectAll = useCallback(() => {
    const { undo, redo } = DrawingsUndoRedoHelper.getSelectDeselectAllTabsUndoRedo(
      selectAll,
      deselectAll,
    );
    addUndoRedo(undo, redo);
    redo();
  }, [file]);

  const onOptimizeClick = useCallback(() => {
    sendToggleEvent(
      MetricNames.fileManager.optimizeFile,
      checkboxData.checked,
      { fileName: file.properties.name },
    );
    onOptimize(!checkboxData.checked);
  }, [checkboxData.checked, file.properties.name]);

  const optimizeClick = useCallback(() => {
    if (canOptimize) {
      if (currentDrawing?.pdfId === file.id && rendererApi?.hasNotFinishedGeometry()) {
        openUnsavedChangesDialog(onOptimizeClick);
      } else {
        onOptimizeClick();
      }
    }
  }, [onOptimizeClick, currentDrawing, rendererApi]);

  useEffect(() => {
    if (!canOptimize && checkboxData.checked) {
      onOptimizeClick();
    }
  }, [checkboxData.checked, canOptimize]);

  return (
    <Styled.Container>
      <DrawingsPdfBrowserFileName
        fileName={file.properties.name}
        creator={creator}
        createdAt={createdAt}
        conversionStatus={file.cadFileConvertingStatus}
        canEdit={canEdit}
        delete={deleteFile}
      />
      <RenderIf condition={STATUSES_TO_SHOW_BUTTONS.includes(file.cadFileConvertingStatus)}>
        <Styled.ButtonsContainer>
          <RenderIf condition={multiselect}>
            <Styled.OpenCloseButtonContainer>
              <OpenCloseButtons
                onDeselectAll={onDeselectAll}
                onSelectAll={onSelectAll}
                showText={false}
              />
            </Styled.OpenCloseButtonContainer>
          </RenderIf>
          <RenderIf condition={canEdit}>
            <UpgradeWrapper isNeedUpdate={!canOptimize}>
              <Styled.Optimize
                onClick={optimizeClick}
                isNeedUpdate={!canOptimize}
              >
                <Icons.Optimize />
                <Text fontSize={12}>
                  Optimize
                </Text>
                <CheckboxButton {...checkboxData} />
              </Styled.Optimize>
            </UpgradeWrapper>
          </RenderIf>
        </Styled.ButtonsContainer>
      </RenderIf>
    </Styled.Container>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    drawings: state.drawings.drawingsInfo,
    users: state.people.companiesUsers,
    currentDrawing: state.drawings.currentDrawingInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { file }: OwnProps): DispatchProps {
  return {
    onOptimize: (value) => dispatch(DrawingsActions.toggleFileOptimize(file.id, value)),
    openUnsavedChangesDialog: (accept, cancel) =>
      dispatch(KreoDialogActions.openDialog(DrawingDialogs.UNSAVED_CHANGES_DIALOG, { accept, cancel })),
    deleteFile: () => dispatch(DrawingsActions.deleteDrawing(file.id)),
  };
}

export const DrawingsPdfBrowserFileTitle =
  connect(mapStateToProps, mapDispatchToProps)
  (withAnalyticsContext(withRendererApiContext(
    withAbilityContext(DrawingsPdfBrowserFileTitleComponent))));

