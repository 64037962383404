import { useMemo } from 'react';
import { AssignedPia } from '2d/interfaces';
import { DrawingsAssign } from 'common/components/drawings/drawings-annotation-legend/utils';
import { DrawingsGeometryGroup, DrawingsGeometryGroupWithNesting } from 'common/components/drawings/interfaces';
import { DrawingAnnotationFiltrationUtils } from 'common/components/drawings/utils/drawing-annotation-filtration-utils';
import { arrayUtils } from 'common/utils/array-utils';

interface GroupInfo {
  group: DrawingsGeometryGroupWithNesting;
  children: string[];
  hasAssignedPia: boolean;
  instancesCount: number;
}

export interface GroupTree {
  rootIds: string[];
  groups: Record<string, GroupInfo>;
}

function addGroupToParentInTree(
  groupsTree: GroupTree,
  group: DrawingsGeometryGroupWithNesting,
  hasAssignedPia?: boolean,
): GroupInfo {
  const current = {
    children: groupsTree.groups[group.id]?.children || [],
    group,
    hasAssignedPia,
    instancesCount: (groupsTree.groups[group.id]?.instancesCount || 0) + group.drawingsCount,
  };
  groupsTree.groups[group.id] = current;
  if (group.groupId) {
    let parentGroup = groupsTree.groups[group.groupId];
    if (parentGroup) {
      parentGroup.children.push(group.id);
      parentGroup.instancesCount = parentGroup.instancesCount + current.instancesCount;
      while (parentGroup) {
        parentGroup = groupsTree.groups[parentGroup.group?.groupId];
        if (parentGroup) {
          parentGroup.instancesCount = parentGroup.instancesCount + current.instancesCount;
        }
      }
    } else {
      groupsTree.groups[group.groupId] = {
        children: [group.id],
        group: null,
        hasAssignedPia: false,
        instancesCount: current.instancesCount,
      };
    }
  } else {
    groupsTree.rootIds.push(group.id);
  }
  return current;
}

function useGroupsTree(
  groups: DrawingsGeometryGroup[],
  selectedGroupIds: string[],
  assignedPia: Record<string, AssignedPia>,
  filteredInstances: string[],
): GroupTree {
  return useMemo(() => {
    const selectedGroupIdsSet = new Set(selectedGroupIds);
    const filteredInstancesSet = new Set(filteredInstances);
    const result = {
      rootIds: [],
      groups: {},
    };

    for (const group of groups) {
      if (selectedGroupIdsSet.has(group.id)) {
        continue;
      }
      const filteredInstancesCount = group.measurements.filter(x => filteredInstancesSet.has(x)).length;
      const isRoot = !group.parentId;
      addGroupToParentInTree(
        result,
        {
          ...group,
          drawingsCount: filteredInstancesCount,
          allInnerInstances: [],
          groupId: group.parentId,
          nestingCount: isRoot ? 0 : null,
        },
        DrawingsAssign.hasAssignedPia(group.id, assignedPia),
      );
    }
    return result;
  }, [selectedGroupIds, groups, assignedPia, filteredInstances]);
}

function useFilterTree(
  groupsTree: GroupTree,
  query: string,
): GroupTree {
  return useMemo(() => {
    if (!query || !query.trim()) {
      return groupsTree;
    }
    const result = {
      rootIds: [],
      groups: {},
    };

    const addGroupToTreeById = (groupId: string): void => {
      if (result.groups[groupId]?.group) {
        return;
      }
      const { group, hasAssignedPia } = groupsTree.groups[groupId];
      addGroupToParentInTree(result, group, hasAssignedPia);
      if (group.groupId) {
        addGroupToTreeById(group.groupId);
      }
    };

    const idsToFilter = [...groupsTree.rootIds];
    while (idsToFilter.length) {
      const groupId = idsToFilter.pop();
      const { group, children } = groupsTree.groups[groupId];
      if (DrawingAnnotationFiltrationUtils.isValidName(group.name, query)) {
        addGroupToTreeById(groupId);
      }
      arrayUtils.extendArray(idsToFilter, children);
    }
    return result;
  }, [groupsTree, query]);
}

interface PIAStatuses {
  hasAssignedPia: boolean;
  hasInheritedPia: boolean;
}

interface GroupsState {
  groups: DrawingsGeometryGroupWithNesting[];
  groupsPiaStatuses: Record<string, PIAStatuses>;
}

export function useGroups(
  selectedGroupIds: string[],
  groups: DrawingsGeometryGroup[],
  collapesdGroups: Record<string, boolean>,
  query: string,
  assignedPia: Record<string, AssignedPia>,
  filteredInstances: string[],
): GroupsState {
  const groupsTree = useGroupsTree(groups, selectedGroupIds, assignedPia, filteredInstances);
  const filteredTree = useFilterTree(groupsTree, query.trim());
  return useMemo(() => {
    const getGroup = (groupId: string): DrawingsGeometryGroupWithNesting => {
      return groupsTree.groups[groupId].group;
    };

    const idsToProcess = [...filteredTree.rootIds].sort((a, b) => getGroup(b).orderIndex - getGroup(a).orderIndex);
    const result: DrawingsGeometryGroupWithNesting[] = [];
    const piaStatuses: Record<string, PIAStatuses> = {};

    const hasAssignedPia = (g: string): boolean => {
      return piaStatuses[g]
        ? piaStatuses[g].hasAssignedPia || piaStatuses[g].hasInheritedPia
        : groupsTree.groups[g].hasAssignedPia;
    };

    const saveInheritedPiaStatus = (groupId: string): void => {
      const groupData = groupsTree.groups[groupId];
      if (groupData.hasAssignedPia) {
        piaStatuses[groupId] = {
          hasAssignedPia: true,
          hasInheritedPia: false,
        };
      }
      piaStatuses[groupId] = {
        hasAssignedPia: false,
        hasInheritedPia: DrawingsAssign.hasInheritedPia(
          groupId,
          hasAssignedPia,
          g => getGroup(g).groupId,
        ),
      };
    };

    while (idsToProcess.length) {
      const id = idsToProcess.pop();
      const { group, children, instancesCount } = filteredTree.groups[id];
      if (!group) {
        continue;
      }
      group.drawingsCount = instancesCount;
      saveInheritedPiaStatus(id);
      if (group.parentId) {
        group.nestingCount = filteredTree.groups[group.parentId].group.nestingCount + 1;
      }
      result.push(group);
      if (collapesdGroups[id]) {
        continue;
      }
      if (children.length) {
        arrayUtils.extendArray(idsToProcess, children.sort((a, b) => getGroup(b).orderIndex - getGroup(a).orderIndex));
      }
    }
    return { groups: result, groupsPiaStatuses: piaStatuses };
  }, [filteredTree, collapesdGroups, assignedPia]);
}
