import { Map } from 'immutable';

import { BidPricingData, BidPricingDataC } from 'common/interfaces/bid-pricing-data';
import { ProjectResourcesApi } from '../../../api/server';
import {
  UpdateBidDescription,
  WinnerPackage,
} from '../actions/payloads/bid-pricing';
import {
  BidPricingCalculationDetails,
  BidPricingCalculationDetailsC,
} from '../interfaces/bid-pricing/bid-pricing-calculation-details';
import { BidPricingInfo, BidPricingInfoC } from '../interfaces/bid-pricing/bid-pricing-info';
import { BidPricingInviteUsers } from '../interfaces/bid-pricing/bid-pricing-invite-users';
import { BidPricingUserCost } from '../interfaces/bid-pricing/bid-pricing-user-cost';
import { BidPricingUserPackage } from '../interfaces/bid-pricing/bid-pricing-user-package';

function baseUrl(): string {
  return `/bid-pricing`;
}

function startBidPricing(data: BidPricingInfo): Promise<BidPricingInfo> {
  return ProjectResourcesApi.postV(
    `${baseUrl()}/create`,
    data,
    BidPricingInfoC);
}

function getProjectBidPricingDetails(): Promise<BidPricingData> {
  return ProjectResourcesApi.getV(
    `${baseUrl()}/details`,
    BidPricingDataC);
}

function getCalculationBidPricingDetails(calculationId: number): Promise<BidPricingCalculationDetails> {
  return ProjectResourcesApi.getV(
    `${baseUrl()}/calculations/${calculationId}/details`,
    BidPricingCalculationDetailsC);
}

function insertSubcontractorBid(data: Map<{}, {}>): Promise<void> {
  return ProjectResourcesApi.post(`${baseUrl()}/bid`, data);
}

function createSubcontractorBid(workPackageId: number): Promise<BidPricingUserCost> {
  return ProjectResourcesApi.put(
    `${baseUrl()}/bid/${workPackageId}`,
    null,
    'Error in creating new bid');
}

function setPackageWinner(model: WinnerPackage): Promise<void> {
  return ProjectResourcesApi.post(
    `${baseUrl()}/bid/win`,
    model,
    'Error in setting Bid Pricing winner');
}

function cancelSubcontractorInvitationRequest(workPackageId: number, email: string): Promise<void> {
  return ProjectResourcesApi.post(
    `${baseUrl()}/bid/cancel`,
    { workPackageId, email });
}

function removeSubcontractorBid(constructionCostId: number): Promise<void> {
  return ProjectResourcesApi.delete(
    `${baseUrl()}/bid/${constructionCostId}`,
    null,
    'Error in removing bid');
}

function setBidPricingInfo(data: BidPricingInfo): Promise<void> {
  return ProjectResourcesApi.post(
    `${baseUrl()}/config`,
    data,
    'Error in changing Bid Pricing data');
}

function cancelBidPricing(): Promise<boolean> {
  return ProjectResourcesApi.post(
    `${baseUrl()}/disable`,
    null,
    'Error in cancel Bid Pricing');
}

function removePeopleFromWorkPackage(packageId: number, emails: string[]): Promise<void> {
  return ProjectResourcesApi.post(
    `${baseUrl()}/package/${packageId}/cancel`,
    emails);
}

function updateBidDescription(model: UpdateBidDescription): Promise<boolean> {
  return ProjectResourcesApi.post(
    `${baseUrl()}/bid/description`,
    model,
    'Error in updating bid description');
}

function getSubcontractorPackagesCosts(calculationId: number): Promise<BidPricingUserPackage[]> {
  return ProjectResourcesApi.get(
    `${baseUrl()}/calculations/${calculationId}/costs`,
    'Error in getting packages');
}

function inviteUsersToPackage(invitations: BidPricingInviteUsers): Promise<boolean> {
  return ProjectResourcesApi.post(
    `${baseUrl()}/invite-users`,
    invitations,
    'Error in inviting users to package');
}

export const BidPricingApi = {
  getProjectBidPricingDetails,
  insertSubcontractorBid,
  getCalculationBidPricingDetails,
  setBidPricingInfo,
  removeSubcontractorBid,
  startBidPricing,
  setPackageWinner,
  cancelSubcontractorInvitationRequest,
  cancelBidPricing,
  removePeopleFromWorkPackage,
  createSubcontractorBid,
  updateBidDescription,
  getSubcontractorPackagesCosts,
  inviteUsersToPackage,
};
