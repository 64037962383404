import { EventChannel, SagaIterator } from 'redux-saga';
import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects';

import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { ProgressActions, ProgressBarType } from 'common/components/progress';
import { ConstantFunctions } from 'common/constants/functions';
import { ActionWith } from 'common/interfaces/action-with';
import { ProgressUtils } from 'common/utils/progress-utils';
import { createSagaEventsChannel, selectWrapper } from 'common/utils/saga-wrappers';
import { DrawingsAnnotationActions } from '../actions/creators/annotation';
import { DrawingsActions } from '../actions/creators/common';
import { DrawingsAnnotationLegendActions } from '../actions/creators/drawings-annotation-legend';
import { DrawingsUpdateActions } from '../actions/creators/update';
import {
  DrawingsAnnotationUpdatePoint,
  DrawingsGeometryAddInstances,
  DrawingsGeometryRenameInstance,
  DrawingsGeometryUpdateParametr,
  DrawingsGetAnnotationPayload,
  DrawingsUpdateAnnotationGeometry,
} from '../actions/payloads/annotation';
import { DrawingsElementChanges } from '../actions/payloads/update';
import { DrawingsAnnotationActionTypes } from '../actions/types/annotation';
import { DrawingsActionTypes } from '../actions/types/common';
import { DrawingsApi } from '../api/drawings-api';
import { fileGeometriesLoader, loadAllFilesGeometries } from '../api/file-geometries-loader';
import { DrawingsInstancesUpdateType } from '../enums';
import { DrawingChangeOperation } from '../enums/drawing-change-operation';
import { DrawingChangeSource } from '../enums/drawing-change-source';
import { DrawingsShortInfo } from '../interfaces/drawings-short-drawing-info';
import { DrawingsState } from '../interfaces/drawings-state';
import { DrawingsUpdateUtils } from '../utils/drawings-update-utils';

const LOADING_MEAUREMENTS_PROGRESS_TITLE = 'Loading measurements';

function* geometryProcessed(amount: number): SagaIterator {
  try {
    yield put(
      ProgressActions.increaseFinishedAmout({
        progressKey: ProgressUtils.DRAWING_PROGRESS_KEY,
        progressBarTitle: LOADING_MEAUREMENTS_PROGRESS_TITLE,
        amount,
      }),
    );
  } catch (e) {
    console.error('geometry progress tracking error', e);
  }
}

function* watchLoading(channel: EventChannel<any>): SagaIterator {
  while (true) {
    const data = yield take(channel);
    yield fork(geometryProcessed, data);
  }
}

function* watchTotal(channel: EventChannel<any>): SagaIterator {
  while (true) {
    const data = yield take(channel);
    const state = yield selectWrapper((s) => s.progress[ProgressUtils.DRAWING_PROGRESS_KEY]);
    const total = state.progressBars.find((x) => x.title === LOADING_MEAUREMENTS_PROGRESS_TITLE)?.total;
    yield put(
      ProgressActions.updateProgress({
        progressKey: ProgressUtils.DRAWING_PROGRESS_KEY,
        progressBar: {
          title: LOADING_MEAUREMENTS_PROGRESS_TITLE,
          total: (total || 0) + data,
        },
      }),
    );
  }
}

function* cancelProcessing(cancel: () => void): SagaIterator {
  yield take(DrawingsActionTypes.DROP_STATE);
  cancel();
}

function* loadFileData(projectId: number, pdfId: string, pageId: string): SagaIterator | Promise<any> {
  try {
    const processState = yield selectWrapper((s) => s.progress[ProgressUtils.DRAWING_PROGRESS_KEY]);
    let processHandler = (_input: any): void => ConstantFunctions.doNothing();
    let processChannel: EventChannel<any>;
    let totalHandler = (_input: any): void => ConstantFunctions.doNothing();
    let totalChannel: EventChannel<any>;

    if (processState) {
      processChannel = createSagaEventsChannel((emitter) => (processHandler = emitter));
      yield fork(watchLoading, processChannel);
      totalChannel = createSagaEventsChannel((emitter) => (totalHandler = emitter));
      yield fork(watchTotal, totalChannel);
    }

    const drawingsState: DrawingsState = yield selectWrapper((s) => s.drawings);

    const [ getPromise, cancel ] = fileGeometriesLoader({
      projectId,
      pdfId,
      pageId,
      filesData: drawingsState.aiAnnotation.fileData,
      onUpdateProgress: processHandler,
      onUpdateTotal: totalHandler,
    });

    const forkedCencelation = yield fork(cancelProcessing, cancel);
    const processed = yield call(getPromise);
    forkedCencelation.cancel();
    if (processState) {
      processChannel.close();
      totalChannel.close();
    }
    if (processed) {
      yield put(DrawingsAnnotationActions.saveAiAnnotation(pageId, processed));
      const keys = Object.keys(processed.geometryInstances);
      yield put(TwoDElementViewActions.handleCreateDrawingElement(keys));
    }
  } catch (e) {
    console.error('load file data error', e);
  }
}

function* loadAnnotationData({ payload }: ActionWith<DrawingsGetAnnotationPayload>): SagaIterator {
  try {
    yield call(loadFileData, payload.projectId, payload.pdfId, payload.pageId);
  } catch (error) {
    console.error('drawings get annotation data', error, payload);
  }
}

function* loadDataForAllFiles(): SagaIterator {
  try {
    const [projectId, drawings]: [number, Record<string, DrawingsShortInfo>] = yield selectWrapper((x) => [
      x.projects.currentProject?.id,
      x.drawings.drawingsInfo,
    ]);
    if (!projectId) {
      return;
    }
    yield put(
      ProgressActions.addOrUpdateProgress({
        progressKey: ProgressUtils.DRAWING_PROGRESS_KEY,
        progressBar: {
          title: LOADING_MEAUREMENTS_PROGRESS_TITLE,
          progressTitle: 'Processing...',
          type: ProgressBarType.Count,
        },
      }),
    );

    let processHandler = (_input: any): void => ConstantFunctions.doNothing();
    const processChannel: EventChannel<any> = createSagaEventsChannel((emitter) => (processHandler = emitter));
    yield fork(watchLoading, processChannel);
    let totalHandler = (_input: any): void => ConstantFunctions.doNothing();
    const totalChannel: EventChannel<any> = createSagaEventsChannel((emitter) => (totalHandler = emitter));
    yield fork(watchTotal, totalChannel);
    const drawingsState: DrawingsState = yield selectWrapper((s) => s.drawings);

    const [ getPromise, cancel] = loadAllFilesGeometries({
      projectId,
      drawings,
      onUpdateProgress: processHandler,
      onUpdateTotal: totalHandler,
      filesData: drawingsState.aiAnnotation.fileData,
    });
    const forkedCencelation = yield fork(cancelProcessing, cancel);
    const callEffects = [call(getPromise), call(DrawingsApi.getDrawingsGroups, projectId)];
    const [processed, groups] = yield all(callEffects);
    forkedCencelation.cancel();
    processChannel.close();
    totalChannel.close();
    if (callEffects) {
      yield put(DrawingsAnnotationActions.saveAllGeometries(processed, groups));
    } else {
      yield put(DrawingsAnnotationLegendActions.loadGroups(projectId));
    }
    const keys = Object.keys(processed.geometryInstances);
    yield put(TwoDElementViewActions.handleCreateDrawingElement(keys));

    yield put(ProgressActions.removeProgress(ProgressUtils.DRAWING_PROGRESS_KEY));
    yield put(DrawingsActions.fullDataLoaded());
  } catch (error) {
    yield put(DrawingsActions.loadDataFailed());
    console.error('error in loading all data', error);
  }
}

function* loadingFork(): SagaIterator {
  const loadFiles = yield fork(loadDataForAllFiles);
  yield take(DrawingsActionTypes.DROP_STATE);
  loadFiles.cancel();
  yield put(ProgressActions.removeProgress(ProgressUtils.DRAWING_PROGRESS_KEY));
}

function* loadAllData(): SagaIterator {
  while (yield take(DrawingsAnnotationActionTypes.LOAD_FULL_GEOMETRY)) {
    yield fork(loadingFork);
  }
}

function* updatedPoints({ payload: { pointsUpdated } }: ActionWith<DrawingsAnnotationUpdatePoint>): SagaIterator {
  try {
    const drawings: DrawingsState = yield selectWrapper((x) => x.drawings);
    const updatesByPage: Record<string, DrawingsElementChanges> = {};
    const processedElements = new Set<string>();
    for (const pointId of Object.keys(pointsUpdated)) {
      const instanceId = drawings.aiAnnotation.pointsInfo[pointId].instanceId;
      if (processedElements.has(instanceId)) {
        continue;
      }
      const drawingId = drawings.aiAnnotation.geometry[instanceId].drawingId;
      updatesByPage[drawingId] = updatesByPage[drawingId] || {
        elementIds: [],
        operation: DrawingChangeOperation.Update,
        pageId: drawingId,
        updateType: DrawingsInstancesUpdateType.Full,
      };
      updatesByPage[drawingId].elementIds.push(instanceId);
      processedElements.add(instanceId);
    }
    yield put(DrawingsUpdateActions.commitUpdates(Object.values(updatesByPage), DrawingChangeSource.Elements));
  } catch (error) {
    console.error('drawings add points prepare for commit', error);
  }
}

function* renameInstances({ payload }: ActionWith<DrawingsGeometryRenameInstance[]>): SagaIterator {
  try {
    const drawings: DrawingsState = yield selectWrapper((x) => x.drawings);
    const changesByPage = DrawingsUpdateUtils.makeElementUpdatesByPages(
      {
        targetInstances: payload,
        instances: drawings.aiAnnotation.geometry,
        operation: DrawingChangeOperation.Update,
        idGetter: (instance) => instance.instanceId,
        updateType: DrawingsInstancesUpdateType.Description,
      },
    );
    yield put(DrawingsUpdateActions.commitUpdates(changesByPage, DrawingChangeSource.Elements));
    yield put(TwoDElementViewActions.handleRenameDrawingElement(payload));
  } catch (error) {
    console.error('drawings rename instances failed', error, payload);
  }
}

function* addInstances({ payload }: ActionWith<DrawingsGeometryAddInstances>): SagaIterator {
  try {
    const drawings: DrawingsState = yield selectWrapper((x) => x.drawings);
    const changesByPage = DrawingsUpdateUtils.makeElementUpdatesByPages(
      {
        targetInstances: payload.instances,
        instances: drawings.aiAnnotation.geometry,
        operation: DrawingChangeOperation.Create,
        idGetter: (instance) => instance.id,
        pia: payload.pia,
      },
    );
    yield put(DrawingsUpdateActions.commitUpdates(changesByPage, DrawingChangeSource.Elements));
    const keys = Object.keys(drawings.aiAnnotation.geometry);
    yield put(TwoDElementViewActions.handleCreateDrawingElement(keys));
  } catch (error) {
    console.error('drawings add instances failed', error, payload);
  }
}

function* changeStylesParams({ payload }: ActionWith<DrawingsGeometryUpdateParametr>): SagaIterator {
  try {
    const drawings: DrawingsState = yield selectWrapper((x) => x.drawings);
    const updates = DrawingsUpdateUtils.makeElementUpdatesByPages(
      {
        targetInstances: payload.instancesIds,
        instances: drawings.aiAnnotation.geometry,
        operation: DrawingChangeOperation.Update,
        idGetter: (instanceId) => instanceId,
      },
    );
    yield put(DrawingsUpdateActions.commitUpdates(updates, DrawingChangeSource.Elements));
  } catch (error) {
    console.error('change style params', error, payload);
  }
}

function* removeInstances({ payload }: ActionWith<string[]>): SagaIterator {
  yield put(TwoDElementViewActions.handleRemoveDrawingElement(payload));
}

function* updateAnnotationGeometry({ payload }: ActionWith<DrawingsUpdateAnnotationGeometry>): SagaIterator {
  try {
    const pageId = yield selectWrapper((x) => x.drawings.aiAnnotation.geometry[payload.instance.id].drawingId);
    const elementChange = {
      pageId,
      operation: DrawingChangeOperation.Update,
      elementIds: [payload.instance.id],
      updateType: DrawingsInstancesUpdateType.Full,
    };
    yield put(DrawingsUpdateActions.commitUpdates([elementChange], DrawingChangeSource.Elements));
  } catch (e) {
    console.error('update annotation geometry', e, payload);
  }
}

export function* drawingsAnnotationSagas(): SagaIterator {
  yield takeLatest(DrawingsAnnotationActionTypes.RENAME_INSTANCES, renameInstances);
  yield takeLatest(DrawingsAnnotationActionTypes.GET_ANNOTATION_DATA, loadAnnotationData);
  yield takeLatest(DrawingsAnnotationActionTypes.UPDATE_POINT, updatedPoints);
  yield takeLatest(DrawingsAnnotationActionTypes.ADD_INSTANCE, addInstances);
  yield takeLatest(DrawingsAnnotationActionTypes.REMOVE_INSTANCES, removeInstances);
  yield takeLatest(DrawingsAnnotationActionTypes.CHANGE_INSTANCES_GEOMETRY_PARAM, changeStylesParams);
  yield takeLatest(DrawingsAnnotationActionTypes.UPDATE_GEOMETRY, updateAnnotationGeometry);
  yield fork(loadAllData);
}
