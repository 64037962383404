import { Action } from 'redux';
import { ActionWith } from 'common/interfaces/action-with';
import {
  CEActivityAssignmentActivity,
  CEActivityAssignmentAggregatedBimInfo,
  CEActivityAssignmentAggregatedData,
  CEActivityAssignmentTreeNodeActivitySort,
} from 'unit-cost-estimate/interfaces';
import {
  ActivityAssignmentSelectMaterialPayload,
  ActivityAssignmentSetModalityPayload,
  CalculateActivityAssignmentPayload,
  PatchActivityAssignmentPayload,
  PathAndEndElementTitlePayload,
} from '../payloads/activity-assignment';
import { CEActivityAssignmentActionTypes } from '../types/activity-assignment';

const calculateActivityAssignment = (
  revisionId: number, databaseIds: number[], usePreviousLaunch: boolean,
): ActionWith<CalculateActivityAssignmentPayload> => {
  return {
    type: CEActivityAssignmentActionTypes.CALCULATE,
    payload: { revisionId, databaseIds, usePreviousLaunch },
  };
};

const patchActivityAssignment = (
  databaseIds: number[],
): ActionWith<PatchActivityAssignmentPayload> => {
  return {
    type: CEActivityAssignmentActionTypes.PATCH,
    payload: { databaseIds },
  };
};

const loadData = (): Action => {
  return {
    type: CEActivityAssignmentActionTypes.LOAD_DATA,
  };
};

const loadDataSucceeded = (
  data: CEActivityAssignmentAggregatedData,
): ActionWith<CEActivityAssignmentAggregatedData> => {
  return {
    type: CEActivityAssignmentActionTypes.LOAD_DATA_SUCCEEDED,
    payload: data,
  };
};

const loadDataFailed = (): Action => {
  return {
    type: CEActivityAssignmentActionTypes.LOAD_DATA_FAILED,
  };
};

const dropState = (): Action => {
  return {
    type: CEActivityAssignmentActionTypes.DROP_STATE,
  };
};

const getRevitData = (): Action => {
  return {
    type: CEActivityAssignmentActionTypes.GET_REVIT_DATA,
  };
};

const saveRevitData = (
  data: CEActivityAssignmentAggregatedBimInfo,
): ActionWith<CEActivityAssignmentAggregatedBimInfo> => {
  return {
    type: CEActivityAssignmentActionTypes.SAVE_REVIT_DATA,
    payload: data,
  };
};


const selectFromEngine = (bimHandleId: number): ActionWith<number> => {
  return {
    type: CEActivityAssignmentActionTypes.SELECT_FROM_ENGINE,
    payload: bimHandleId,
  };
};

const expandItem = (path: string): ActionWith<string> => {
  return {
    type: CEActivityAssignmentActionTypes.EXPAND_ITEM,
    payload: path,
  };
};

const showWorksFromType = (path: string): ActionWith<string> => {
  return {
    type: CEActivityAssignmentActionTypes.SHOW_WORK_FROM_TYPE,
    payload: path,
  };
};

const showWorksFromLayer = (
  data: PathAndEndElementTitlePayload,
): ActionWith<PathAndEndElementTitlePayload> => {
  return {
    type: CEActivityAssignmentActionTypes.SHOW_WORK_FROM_LAYER,
    payload: data,
  };
};

const runSearch = (search: string): ActionWith<string> => {
  return {
    type: CEActivityAssignmentActionTypes.START_SEARCH,
    payload: search,
  };
};

const showHideTree = (): Action => {
  return {
    type: CEActivityAssignmentActionTypes.SHOW_HIDE_TREE,
  };
};

const selectExpandableTreeNode = (path: string): ActionWith<string> => {
  return {
    type: CEActivityAssignmentActionTypes.SELECT_TREE_NODE,
    payload: path,
  };
};

const setModality = (data: ActivityAssignmentSetModalityPayload): ActionWith<ActivityAssignmentSetModalityPayload> => {
  return {
    type: CEActivityAssignmentActionTypes.SET_MODALITY,
    payload: data,
  };
};

const assignActivity = (): Action => {
  return {
    type: CEActivityAssignmentActionTypes.ASSIGN_WORKS,
  };
};

const assignActivitySucceeded = (
  work: CEActivityAssignmentTreeNodeActivitySort,
): ActionWith<CEActivityAssignmentTreeNodeActivitySort> => {
  return {
    type: CEActivityAssignmentActionTypes.ASSIGN_WORKS_SUCCEEDED,
    payload: work,
  };
};

function selectMaterial(
  materialId: number,
  materialVariantId: number,
  activityIndex: number,
): ActionWith<ActivityAssignmentSelectMaterialPayload> {
  return {
    type: CEActivityAssignmentActionTypes.SELECT_MATERIAL,
    payload: { materialVariantId, materialId, activityIndex },
  };
}

const loadWork = (workId: number): ActionWith<number> => {
  return {
    type: CEActivityAssignmentActionTypes.LOAD_WORK,
    payload: workId,
  };
};

const loadWorkSucceeded = (work: CEActivityAssignmentActivity): ActionWith<CEActivityAssignmentActivity> => {
  return {
    type: CEActivityAssignmentActionTypes.LOAD_WORK_SUCCEEDED,
    payload: work,
  };
};

const assignModalities = (
  work: CEActivityAssignmentTreeNodeActivitySort,
): ActionWith<CEActivityAssignmentTreeNodeActivitySort> => {
  return {
    type: CEActivityAssignmentActionTypes.ASSIGN_MODALITIES,
    payload: work,
  };
};

const updateWork = (
  work: CEActivityAssignmentTreeNodeActivitySort,
): ActionWith<CEActivityAssignmentTreeNodeActivitySort> => {
  return {
    type: CEActivityAssignmentActionTypes.UPDATE_WORK,
    payload: work,
  };
};

const setSaveData = (isSave: boolean): ActionWith<boolean> => {
  return {
    type: CEActivityAssignmentActionTypes.SET_SAVE_DATA,
    payload: isSave,
  };
};

const openDataBasePage = (): Action => {
  return {
    type: CEActivityAssignmentActionTypes.OPEN_DATABASE_PAGE,
  };
};

const approve = (): Action => {
  return {
    type: CEActivityAssignmentActionTypes.APPROVE,
  };
};

const disapprove = (): Action => {
  return {
    type: CEActivityAssignmentActionTypes.DISAPPROVE,
  };
};


export const CEActivityAssignmentActions = {
  calculateActivityAssignment,
  patchActivityAssignment,
  loadData,
  loadDataSucceeded,
  loadDataFailed,
  loadWork,
  loadWorkSucceeded,
  expandItem,
  showWorksFromType,
  showWorksFromLayer,
  runSearch,
  assignActivity,
  assignActivitySucceeded,
  dropState,
  saveRevitData,
  getRevitData,
  setModality,
  selectFromEngine,
  setSaveData,
  openDataBasePage,
  assignModalities,
  updateWork,
  showHideTree,
  selectExpandableTreeNode,
  selectMaterial,
  approve,
  disapprove,
};
