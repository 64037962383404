import { NotificationProcessor } from 'common/interfaces/realtime-messages';
import { CommonNotificationProcessor } from '../../../notifications/common-notification-processor';
import {
  QuantityTakeOffRecordNotificationsProcessor,
} from '../../components/quantity-take-off-left-panel/quantity-take-off-record-notifications-processor';
import { QuantityTakeOffNotificationKeys } from '../keys/quantity-take-off';

export const QuantityTakeOffNotificationsProcessor: NotificationProcessor = {
  [QuantityTakeOffNotificationKeys.QuantityTakeOffCalculationSuccess]: CommonNotificationProcessor.addInfoAlert,
  [QuantityTakeOffNotificationKeys.QuantityTakeOffCalculationFailed]: CommonNotificationProcessor.addErrorAlert,
  [QuantityTakeOffNotificationKeys.QuantityTakeOffReportReady]: CommonNotificationProcessor.addInfoAlert,
  [QuantityTakeOffNotificationKeys.QuantityTakeOffReportFailed]: CommonNotificationProcessor.addErrorAlert,
  [QuantityTakeOffNotificationKeys.CalculationUserExtractorStart]:
    QuantityTakeOffRecordNotificationsProcessor.updateRecordsUserExtractors,
  [QuantityTakeOffNotificationKeys.CalculationUserExtractorFinish]:
    QuantityTakeOffRecordNotificationsProcessor.updateRecordsUserExtractors,
  [QuantityTakeOffNotificationKeys.UpdatedSharedParameters]: CommonNotificationProcessor.addInfoAlert,
};
