import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';

import { AgGridHelper } from '../../ag-grid';
import { TreeTableGroupRules, TreeTableRowType } from './interfaces';


export class TreeTableDefaultGroupRules implements TreeTableGroupRules {
  public EmptyGroupNode: Ag.RowNode = { data: { type: TreeTableRowType.Group } } as any;
  public EmptyElementNode: Ag.RowNode = { data: { type: TreeTableRowType.Element } } as any;

  public isGroup(node: Ag.RowNode): boolean {
    return !!node && node.data && node.data.type === TreeTableRowType.Group;
  }

  public isItem(node: Ag.RowNode): boolean {
    return !!node && node.data && node.data.type === TreeTableRowType.Element;
  }

  @autobind
  public availableInsertAfter(node: Ag.RowNode, overNode: Ag.RowNode): boolean {
    const insetGroupAfterGroup = (): boolean => this.isGroup(node) && this.isGroup(overNode);
    const insertItemAfterItem = (): boolean => this.isItem(node) && this.isItem(overNode);

    return insetGroupAfterGroup() || insertItemAfterItem();
  }

  @autobind
  public availableInsertInto(node: Ag.RowNode, overNode: Ag.RowNode): boolean {
    const insetGroupIntoGroup = (): boolean =>
      this.isGroup(node) && this.isGroup(overNode) && (
        !this.nodeHasChildren(overNode) || this.isGroup(overNode.childrenAfterGroup[0])
      );
    const insertItemIntoGroup = (): boolean =>
      this.isItem(node)
      && this.isGroup(overNode)
      && !AgGridHelper.isRootNode(overNode)
      && (!this.nodeHasChildren(overNode) || this.isItem(overNode.childrenAfterGroup[0]));
    const nodeIsChildOfOverNode = (): boolean =>
      this.nodeHasChildren(overNode)
      && overNode.data.children.includes(node.data.id);

    return !nodeIsChildOfOverNode() && (insetGroupIntoGroup() || insertItemIntoGroup());
  }

  public getClosestAvailableOverNode(draggableNode: Ag.RowNode, overNode: Ag.RowNode): Ag.RowNode {
    return this.isGroup(draggableNode) && this.isItem(overNode)
      ? overNode.parent
      : overNode;
  }

  private nodeHasChildren(node: Ag.RowNode): boolean {
    const children = node && node.data.children;
    return !!(children && children.length);
  }
}
