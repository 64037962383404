import * as React from 'react';

export const KreoIconRulerPoints: React.FC = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Icons/24x24/Ruler_Points' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        // eslint-disable-next-line max-len
        d='M19.5,11 C20.3284271,11 21,11.6715729 21,12.5 L21,17.5 C21,18.3284271 20.3284271,19 19.5,19 L4.5,19 C3.67157288,19 3,18.3284271 3,17.5 L3,12.5 C3,11.6715729 3.67157288,11 4.5,11 L19.5,11 Z M6,12 L4.5,12 C4.22385763,12 4,12.2238576 4,12.5 L4,17.5 C4,17.7761424 4.22385763,18 4.5,18 L19.5,18 C19.7761424,18 20,17.7761424 20,17.5 L20,12.5 C20,12.2238576 19.7761424,12 19.5,12 L19,12 L19,14 L18,14 L18,12 L16,12 L16,16 L15,16 L15,12 L13,12 L13,14 L12,14 L12,12 L10,12 L10,16 L9,16 L9,12 L7,12 L7,14 L6,14 L6,12 Z M19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 C18.6715729,8 18,7.32842712 18,6.5 C18,5.67157288 18.6715729,5 19.5,5 Z M4.5,5 C5.32842712,5 6,5.67157288 6,6.5 C6,7.32842712 5.32842712,8 4.5,8 C3.67157288,8 3,7.32842712 3,6.5 C3,5.67157288 3.67157288,5 4.5,5 Z M8,6 L8,7 L7,7 L7,6 L8,6 Z M10,6 L10,7 L9,7 L9,6 L10,6 Z M12,6 L12,7 L11,7 L11,6 L12,6 Z M14,6 L14,7 L13,7 L13,6 L14,6 Z M16,6 L16,7 L15,7 L15,6 L16,6 Z M18,6 L18,7 L17,7 L17,6 L18,6 Z'
        id='icon'
        fill='currentColor'
        fillRule='nonzero'
      />
      </g>
    </svg>
  );
};
