import * as paper from 'paper';

import { AssignedPia } from '2d/index';
import { DrawingsGeometryGroup } from 'common/components/drawings';
import { UndoRedoActionMethods } from 'common/undo-redo/undo-redo-context-provider';
import { arrayUtils } from 'common/utils/array-utils';
import {
  DrawingsGeometryRenameInstance,
  DrawingsBatchUpdateGeometries,
  DrawingsUpdateAnnotationGeometry,
} from '../actions/payloads/annotation';
import {
  UpdateDrawingGroup,
  UpdateDrawingGroupTree,
  UpdateDrawingMeasurement,
} from '../actions/payloads/drawings-annotation-legend';
import { DrawingMarkShapes } from '../constants/drawing-styles';
import { AddInstancesWithUndoRedoPayload, DrawingsAddInstanceFunctionType } from '../drawings-contexts';
import {
  GetDrawingInstanceCallback,
  GetInstanceMeasureCallback,
  GetPointCoordinatesCallback,
  SendMeasuresUpdateCallback,
} from '../drawings-geometry/interfaces';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import { DrawingGeometryUpdateEventHandler } from '../interfaces/drawing-update-events-handlers';
import { DrawingsGeometryInstance } from '../interfaces/drawings-geometry-instance';
import { DrawingsGeometryStyle } from '../interfaces/drawings-geometry-style';
import { DrawingsInstanceMeasure } from '../interfaces/drawings-instance-measure';
import { DrawingsUserAnnotationsState } from '../interfaces/drawings-state';
import {
  DrawingUserAnnotationImage,
  DrawingUserAnnotationRuler,
  DrawingUserAnnotationSticker,
} from '../interfaces/drawings-user-annotation';
import { DrawingAnnotationUtils } from './drawing-annotation-utils';
import { DrawingsUpdateUtils } from './drawings-update-utils';


export type DrawingsRemoveGeometryMethod = (instancesIds: string[], groupIds?: string[]) => void;
export interface GroupActionData {
  groupIds: string[];
  measurementIds: string[];
  groupId?: string;
  orderIndex?: number;
  newGroupParent?: DrawingsGeometryGroup;
}

function createBooleanEditRedo(
  { newPoints, addedInstances, removedInstances, pageId, updated, pia }: DrawingsBatchUpdateGeometries,
  measures: DrawingsInstanceMeasure[],
  addGeometry: DrawingsAddInstanceFunctionType,
  removeGeometry: DrawingsRemoveGeometryMethod,
  addMeasures: (measures: DrawingsInstanceMeasure[]) => void,
  updateGeometry: DrawingGeometryUpdateEventHandler,
): () => void {
  return () => {
    if (updated) {
      updateGeometry(updated, pageId);
    }
    if (removedInstances && removedInstances.length) {
      removeGeometry(removedInstances);
    }
    if (addedInstances && addedInstances.length) {
      addGeometry({
        instances: addedInstances,
        points: newPoints,
        ignoreSaveMeasuresOnCreate: true,
        forceSave: true,
        pia,
      });
    }
    if (measures && measures.length) {
      addMeasures(measures);
    }
  };
}

function createUndoRemove(
  removedInstances: Record<string, string[]>,
  points: Record<string, ShortPointDescription>,
  instances: Record<string, DrawingsGeometryInstance>,
  addGeometry: DrawingsAddInstanceFunctionType,
  getPia: (id: string) => AssignedPia,
): () => void {
  const {
    newPoints,
    addedInstances,
  } = DrawingsUpdateUtils.getInstancesForRestore(
    arrayUtils.flatArrayIterator(Object.values(removedInstances)),
    id => points[id],
    id => instances[id],
    getPia,
  );
  return () => {
    addGeometry({ instances: addedInstances, points: newPoints });
  };
}

function createBooleanEditUndoRedo(
  payload: DrawingsBatchUpdateGeometries,
  measures: DrawingsInstanceMeasure[],
  getPointCoordinates: GetPointCoordinatesCallback,
  getInstance: GetDrawingInstanceCallback,
  addGeometry: DrawingsAddInstanceFunctionType,
  removeGeometry: DrawingsRemoveGeometryMethod,
  addMeasures: SendMeasuresUpdateCallback,
  updateGeometry: DrawingGeometryUpdateEventHandler,
  getMeasures: GetInstanceMeasureCallback,
  getPia: (id: string) => AssignedPia,
): UndoRedoActionMethods {
  const restoreData: DrawingsBatchUpdateGeometries =
    DrawingsUpdateUtils.convertBatchedUpdate(payload, getPointCoordinates, getInstance, getPia);
  return {
    redo: createBooleanEditRedo(payload, measures, addGeometry, removeGeometry, addMeasures, updateGeometry),
    undo: createBooleanEditRedo(
      restoreData,
      measures
        ? measures.map(
          ({ id }) => getMeasures(Array.isArray(id) ? DrawingAnnotationUtils.getLineKey(id[0], id[1]) : id),
        )
        : null,
      addGeometry,
      removeGeometry,
      addMeasures,
      updateGeometry,
    ),
  };
}

function createRemoveUndoRedo(
  removedInstances: Record<string, string[]>,
  points: Record<string, ShortPointDescription>,
  instances: Record<string, DrawingsGeometryInstance>,
  addGeometry: DrawingsAddInstanceFunctionType,
  removeGeometry: (instancesByPages: Record<string, string[]>) => void,
  getPia: (id: string) => AssignedPia,
): UndoRedoActionMethods {
  return {
    undo: createUndoRemove(removedInstances, points, instances, addGeometry, getPia),
    redo: () => removeGeometry(removedInstances),
  };
}

export interface GroupPayloadForInstanceCreate {
  selectedGroups?: string[];
  useGroupNameForNewGeometry: boolean;
  groupForInstanceCreation: DrawingsGeometryGroup;
}

function createAddElementUndoRedo(
  addInstancesPayload: AddInstancesWithUndoRedoPayload,
  groups: GroupPayloadForInstanceCreate,
  addGeometry: (payload: AddInstancesWithUndoRedoPayload, groupsPayload: GroupPayloadForInstanceCreate) => void,
  removeGeometry: (instancesByPages: Record<string, string[]>) => void,
): UndoRedoActionMethods {
  const instancesForRemove = arrayUtils.groupByWithMap(addInstancesPayload.instances, x => x.drawingId, x => x.id);
  return {
    redo: () => addGeometry(addInstancesPayload, groups),
    undo: () => removeGeometry(instancesForRemove),
  };
}

function createRemovePointUndoRedo(
  pointIds: string[],
  update: DrawingsUpdateAnnotationGeometry,
  getPoint: (pointId: string) => ShortPointDescription,
  getInstance: (instanceId: string) => DrawingsGeometryInstance,
  restorePoint: (payload: DrawingsUpdateAnnotationGeometry) => void,
  removePoint: (pointIds: string[]) => void,
): UndoRedoActionMethods {
  const undoPayload = DrawingsUpdateUtils.convertUpdateForRestore(update, getPoint, getInstance);
  return {
    redo: () => removePoint(pointIds),
    undo: () => restorePoint(undoPayload),
  };
}

function splitCountUndoRedo(
  sourceInstance: DrawingsGeometryInstance,
  updatedInstance: DrawingsGeometryInstance,
  newInstance: DrawingsGeometryInstance,
  points: Record<string, ShortPointDescription>,
  undoUpdateCountGeometry: (sourceInstance: DrawingsGeometryInstance, newInstanceId: string) => void,
  updateGeometry: (instance: DrawingsGeometryInstance) => void,
  addInstances: DrawingsAddInstanceFunctionType,
): UndoRedoActionMethods {
  return {
    undo: () => undoUpdateCountGeometry(sourceInstance, newInstance.id),
    redo: () => {
      updateGeometry(updatedInstance);
      addInstances({ instances: [newInstance], points });
    },
  };
}

function createMovePointUndoRedo(
  getPoint: (pointId: string) => ShortPointDescription,
  changedPoints: Record<string, ShortPointDescription>,
  undoChangePointsPosition: (points: Record<string, ShortPointDescription>) => void,
): UndoRedoActionMethods {
  const oldPoints = arrayUtils.toDictionary(Object.keys(changedPoints), x => x, getPoint);
  return {
    redo: () => {
      undoChangePointsPosition(changedPoints);
    },
    undo: () => {
      undoChangePointsPosition(oldPoints);
    },
  };
}

function createAddPointUndoRedo(
  pointIds: string[],
  payload: DrawingsUpdateAnnotationGeometry,
  restorePoint: (payload: DrawingsUpdateAnnotationGeometry) => void,
  removePoint: (pointIds: string[]) => void,
): UndoRedoActionMethods {
  return {
    undo: () => removePoint(pointIds),
    redo: () => restorePoint(payload),
  };
}

function createSelectUndoRedo(
  selectedInstances: string[],
  oldSelectedInstances: string[],
  changeSelection: (instancesIds: string[]) => void,
): UndoRedoActionMethods {
  return {
    undo: () => changeSelection(oldSelectedInstances),
    redo: () => changeSelection(selectedInstances),
  };
}

function createVisibilityUndoRedo(
  instancesToShowIds: string[],
  instancesToHideIds: string[],
  updateVisibility: (instancesToShowIds: string[], instancesToHideIds: string[]) => void,
): UndoRedoActionMethods {
  return {
    undo: () => updateVisibility(instancesToHideIds, instancesToShowIds),
    redo: () => updateVisibility(instancesToShowIds, instancesToHideIds),
  };
}

function createColorUndo(
  elementsIds: string[],
  getElementColor: (id: string) => string,
  changeColor: (ids: string[], color: string) => void,
): () => void {
  const colorElements: Record<string, string[]> = {};
  for (const elementId of elementsIds) {
    const color = getElementColor(elementId);
    colorElements[color] = colorElements[color] || [];
    colorElements[color].push(elementId);
  }
  return () => {
    for (const [color, elements] of Object.entries(colorElements)) {
      changeColor(elements, color);
    }
  };
}

function createUndoRedoColor(
  elementsIds: string[],
  color: string,
  getElementColor: (id: string) => string,
  changeColor: (ids: string[], color: string) => void,
): UndoRedoActionMethods {
  return {
    undo: createColorUndo(elementsIds, getElementColor, changeColor),
    redo: () => changeColor(elementsIds, color),
  };
}


function createParameterUndo<T extends keyof DrawingsGeometryStyle>(
  elementIds: string[],
  field: T,
  getFieldValue: (id: string, field: T) => DrawingsGeometryStyle[T],
  changeField: (ids: string[], field: T, fieldValue: DrawingsGeometryStyle[T]) => void,
): () => void {
  const valueElements: Record<string | number, string[]> = {};
  for (const elementId of elementIds) {
    const value = getFieldValue(elementId, field);
    valueElements[value] = valueElements[value] || [];
    valueElements[value].push(elementId);
  }
  return () => {
    for (const [value, elements] of Object.entries(valueElements)) {
      changeField(elements, field, value as DrawingsGeometryStyle[T]);
    }
  };
}

function createUndoRedoStrokeStyleField<T extends keyof DrawingsGeometryStyle>(
  elementIds: string[],
  field: T,
  value: DrawingsGeometryStyle[T],
  getFieldValue: (id: string, field: T) => DrawingsGeometryStyle[T],
  changeField: (ids: string[], field: T, fieldValue: DrawingsGeometryStyle[T]) => void,
): UndoRedoActionMethods {
  return {
    undo: createParameterUndo(elementIds, field, getFieldValue, changeField),
    redo: () => changeField(elementIds, field, value),
  };
}

function createUndoRedoCountShape(
  elementIds: string[],
  value: DrawingMarkShapes,
  getValue: (id: string) => DrawingMarkShapes,
  change: (ids: string[], value: DrawingMarkShapes) => void,
): UndoRedoActionMethods {
  const valueElements: Record<string, string[]> = {};
  for (const elementId of elementIds) {
    const shape = getValue(elementId);
    valueElements[shape] = valueElements[shape] || [];
    valueElements[shape].push(elementId);
  }

  return {
    redo: () => change(elementIds, value),
    undo: () => {
      for (const [shape, elements] of Object.entries(valueElements)) {
        change(elements, shape as DrawingMarkShapes);
      }
    },
  };
}

function renameMeasurementUndoRedo(
  instances: DrawingsGeometryRenameInstance[],
  oldInstances: DrawingsGeometryRenameInstance[],
  onNameChange: (instances: DrawingsGeometryRenameInstance[]) => void,
): UndoRedoActionMethods {
  return {
    undo: () => onNameChange(oldInstances),
    redo: () => onNameChange(instances),
  };
}

function updateMeasurementGroupUndoRedo(
  propName: string,
  newValue: string,
  group: UpdateDrawingGroup,
  updateGroups: (data: UpdateDrawingGroupTree) => void,
  oldMeasurements: UpdateDrawingMeasurement[] = [],
  newMeasurements: UpdateDrawingMeasurement[] = [],
): UndoRedoActionMethods {
  return {
    undo: () => updateGroups({
      groups: group ? [group] : [],
      measurements: oldMeasurements,
    }),
    redo: () => updateGroups({
      groups: group ? [{ ...group, [propName]: newValue }] : [],
      measurements: newMeasurements,
    }),
  };
}

function updateInstancesColorUndoRedo(
  newColor: string,
  oldGroup: UpdateDrawingGroup,
  oldMeasurements: UpdateDrawingMeasurement[],
  getElementColor: (id: string) => string,
  changeColor: (ids: string[], color: string) => void,
  updateGroups: (data: UpdateDrawingGroupTree) => void,
): UndoRedoActionMethods {
  const measurementIds = [];
  const updatedMeasurements = [];
  oldMeasurements.forEach(measurement => {
    measurementIds.push(measurement.id);
    updatedMeasurements.push({
      ...measurement,
      color: newColor,
    });
  });
  const colorUndoRedo = createUndoRedoColor(
    measurementIds,
    newColor,
    getElementColor,
    changeColor,
  );
  const groupUndoRedo = updateMeasurementGroupUndoRedo(
    'color',
    newColor,
    oldGroup,
    updateGroups,
    oldMeasurements,
    updatedMeasurements,
  );
  return {
    undo: () => {
      colorUndoRedo.undo();
      groupUndoRedo.undo();
    },
    redo: () => {
      colorUndoRedo.redo();
      groupUndoRedo.redo();
    },
  };
}

function updateColorPageUndoRedo(
  drawingId: string,
  newColor: string,
  oldColor: string,
  onParameterUpdate: (drawingId: string, parameter: string, value: string | number) => void,
): UndoRedoActionMethods {
  return {
    undo: () => onParameterUpdate(drawingId, 'color', oldColor),
    redo: () => onParameterUpdate(drawingId, 'color', newColor),
  };
}

function onRenameWithUndoRedo(
  drawingId: string,
  newValue: string,
  oldValue: string,
  onParameterUpdate: (drawingId: string, parameter: string, value: string | number) => void,
): UndoRedoActionMethods {
  return {
    undo: () => onParameterUpdate(drawingId, 'name', oldValue),
    redo: () => onParameterUpdate(drawingId, 'name', newValue),
  };
}

function entityRenameUndoRedo(
  name: string,
  oldName: string,
  id: string,
  onEditEntity: (id: string, name: string) => void,
): UndoRedoActionMethods {
  return {
    undo: () => onEditEntity(id, oldName),
    redo: () => onEditEntity(id, name),
  };
}

function fileRenameUndoRedo(
  name: string,
  oldName: string,
  id: string,
  parentId: string,
  onEditEntity: (id: string, name: string, parentId: string) => void,
): UndoRedoActionMethods {
  return {
    undo: () => onEditEntity(id, oldName, parentId),
    redo: () => onEditEntity(id, name, parentId),
  };
}

function getGroupUndoRedo<T extends GroupActionData>(
  prevGroupState: DrawingsGeometryGroup[],
  pinnedGroupIds: string[],
  actionData: T,
  redo: (actionData: T) => void,
  undo: (
    prevGroupState: DrawingsGeometryGroup[],
    pinnedGroupIds: string[],
    groupIds: string[],
    measurementIds: string[],
  ) => void,
): UndoRedoActionMethods {
  return {
    undo: () => undo(prevGroupState, pinnedGroupIds, actionData.groupIds, actionData.measurementIds),
    redo: () => redo(actionData),
  };
}

function getSelectDrawingUndoRedo(
  prevDrawingId: string,
  drawingId: string,
  setDrawingId: (id: string) => void,
): UndoRedoActionMethods {
  return {
    undo: () => setDrawingId(prevDrawingId),
    redo: () => setDrawingId(drawingId),
  };
}

function getTabsUndoRedo(
  ids: string[],
  undo: (ids: string[]) => void,
  redo: (ids: string[]) => void,
): UndoRedoActionMethods {
  return {
    undo: () => undo(ids),
    redo: () => redo(ids),
  };
}

function updateParameterUndoRedo(
  id: string,
  oldParameter: string,
  newParameter: string,
  updateParameter: (parameter: string, drawingId: string) => void,
): UndoRedoActionMethods {
  return {
    undo: () => updateParameter(id, oldParameter),
    redo: () => updateParameter(id, newParameter),
  };
}

function removeAnnotationsUndo(
  ids: string[],
  pageId: string,
  { stickers, rulers, images }: DrawingsUserAnnotationsState,
  remove: (ids: string[], pageId: string) => void,
  renderInstances: (
    images: Record<string, DrawingUserAnnotationImage>,
    rulers: Record<string, DrawingUserAnnotationRuler>,
  ) => void,
  onAddImages: (image: DrawingUserAnnotationImage[], pageId: string) => void,
  onAddRulers: (rulers: DrawingUserAnnotationRuler[], pageId: string) => void,
  onAddStickers: (stickers: DrawingUserAnnotationSticker[], pageId: string) => void,
): UndoRedoActionMethods {
  const restoreStickers = [];
  const restoreImages: Record<string, DrawingUserAnnotationImage> = {};
  const restoreRulers: Record<string, DrawingUserAnnotationRuler> = {};
  for (const id of ids) {
    if (id in stickers) {
      restoreStickers.push(stickers[id]);
    } else if (id in images) {
      restoreImages[id] = images[id];
    } else if (id in rulers) {
      restoreRulers[id] = rulers[id];
    }
  }
  return {
    undo: () => {
      renderInstances(restoreImages, restoreRulers);
      onAddImages(Object.values(restoreImages), pageId);
      onAddRulers(Object.values(restoreRulers), pageId);
      onAddStickers(restoreStickers, pageId);
    },
    redo: () => remove(ids, pageId),
  };
}

function dragAnnotationUndoRedo(
  ids: string[],
  diff: paper.Point,
  changeElementsPosition: (diff: paper.Point, finish: boolean, ids: string[]) => void,
): UndoRedoActionMethods {
  return {
    undo: () => changeElementsPosition((new paper.Point(0, 0).subtract(diff)), true, ids),
    redo: () => changeElementsPosition(diff, true, ids),
  };
}

function stickerTextChange(
  text: string,
  oldText: string,
  changeHandler: (text: string) => void,
): UndoRedoActionMethods {
  return {
    undo: () => changeHandler(oldText),
    redo: () => changeHandler(text),
  };
}

function getSelectDeselectPagesUndoRedo(
  ids: string[],
  select: boolean,
  openLast: boolean,
  onChangeDrawingSelection: (ids: string[], select: boolean, openLast: boolean) => void,
): UndoRedoActionMethods {
  return {
    undo: () => onChangeDrawingSelection(ids, !select, openLast),
    redo: () => onChangeDrawingSelection(ids, select, openLast),
  };
}

function getSelectDeselectAllTabsUndoRedo(
  selectAll: () => void,
  deselectAll: () => void,
): UndoRedoActionMethods {
  return {
    undo: () => selectAll(),
    redo: () => deselectAll(),
  };
}

export const DrawingsUndoRedoHelper = {
  createRemoveUndoRedo,
  createAddElementUndoRedo,
  createRemovePointUndoRedo,
  createMovePointUndoRedo,
  createAddPointUndoRedo,
  createSelectUndoRedo,
  createVisibilityUndoRedo,
  createUndoRedoColor,
  renameMeasurementUndoRedo,
  updateMeasurementGroupUndoRedo,
  updateInstancesColorUndoRedo,
  updateColorPageUndoRedo,
  onRenameWithUndoRedo,
  entityRenameUndoRedo,
  fileRenameUndoRedo,
  getGroupUndoRedo,
  getSelectDrawingUndoRedo,
  getTabsUndoRedo,
  updateParameterUndoRedo,
  createBooleanEditUndoRedo,
  removeAnnotationsUndo,
  dragAnnotationUndoRedo,
  stickerTextChange,
  createUndoRedoStrokeStyleField,
  splitCountUndoRedo,
  createUndoRedoCountShape,
  getSelectDeselectPagesUndoRedo,
  getSelectDeselectAllTabsUndoRedo,
};
