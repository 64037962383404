import { Icons, ModalWrapper } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { AddProjectUserPopup } from 'common/components/add-project-user-popup';
import { ProjectType } from 'common/constants/project-type';
import { RoleGroup } from 'common/enums/role-group';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { Company } from '../../../units/account/interfaces/company';
import { Person } from '../../../units/people/interfaces/person';
import { Styled } from './styled';


interface StateProps {
  addUserDialog: boolean;
  people: Person[];
  companyId: number;
  currentUserEmail: string;
  company: Company;
}

interface DispatchProps {
  closeInviteDialog: () => void;
}

interface Props extends StateProps, DispatchProps {
  onFormSubmit: (forms: Person[], isSharedWithCompany: boolean) => void;
  onCloseDialog?: () => void;
  invitedUsers?: string[];
  projectType: string;
  showCurrentUser?: boolean;
  isSharedWithCompany: boolean;
  onChangeSharedWithCompany?: () => void;
}

interface OwnState {
  isSharedWithCompany: boolean;
}

export const INVITE_PEOPLE_DIALOG = 'INVITE_PEOPLE_DIALOG';

class InviteUsersToProjectDialogComponent extends React.PureComponent<Props, OwnState> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isSharedWithCompany: props.isSharedWithCompany,
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.isSharedWithCompany !== this.props.isSharedWithCompany) {
      this.setState({ isSharedWithCompany: this.props.isSharedWithCompany });
    }
  }

  public render(): JSX.Element {
    if (!this.props.addUserDialog || !this.props.people || !this.props.people.length) {
      return null;
    }
    const filterPeople = this.getFilter();
    const userEmails = arrayUtils.filterMap(this.props.people, filterPeople, person => person.email);
    const guestEmails = arrayUtils.filterMap(
      this.props.people,
      person => person.roleGroup === RoleGroup.Guest,
      person => person.email,
    );
    const company = this.props.company;

    return (
      <ModalWrapper onExit={this.onCloseDialog}>
        <Styled.AddProjectUserPopupWrapper>
          <AddProjectUserPopup
            onFormSubmit={this.onFormSubmit}
            title={'Share'}
            buttonTitle={'Share'}
            ButtonIcon={Icons.AddUser}
            userEmails={this.state.isSharedWithCompany ? guestEmails : userEmails}
            addedEmails={this.props.invitedUsers}
            currentUserEmail={this.props.currentUserEmail}
            onClose={this.onCloseDialog}
            isShareWithCompany={this.state.isSharedWithCompany}
            company={company as any}
            onChangeShareWithCompany={this.changeIsSharedWithCompany}
          />
        </Styled.AddProjectUserPopupWrapper>
      </ModalWrapper>
    );
  }

  @autobind
  private changeIsSharedWithCompany(): void {
    this.setState(s => ({ isSharedWithCompany: !s.isSharedWithCompany }));
    if (this.props.onChangeSharedWithCompany) {
      this.props.onChangeSharedWithCompany();
    }
  }

  private getFilter(): (person: Person) => boolean {
    if (this.props.projectType === ProjectType.Project2dTemplate) {
      if (this.props.showCurrentUser) {
        return person => person.roleGroup !== RoleGroup.Guest;
      } else {
        return person => person.roleGroup !== RoleGroup.Guest && person.email !== this.props.currentUserEmail;
      }
    } else if (this.props.showCurrentUser) {
      return () => true;
    } else {
      return person => person.email !== this.props.currentUserEmail;
    }
  }

  @autobind
  private onFormSubmit(emails: string[]): void {
    const emailsMap = new Set(emails);
    this.props.onFormSubmit(this.props.people.filter(x => emailsMap.has(x.email)), this.state.isSharedWithCompany);

    if (this.props.onCloseDialog) {
      this.props.onCloseDialog();
    }
    this.props.closeInviteDialog();
  }

  @autobind
  private onCloseDialog(): void {
    this.props.closeInviteDialog();
    if (this.props.onCloseDialog) {
      this.props.onCloseDialog();
    }
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    addUserDialog: INVITE_PEOPLE_DIALOG in state.dialog,
    people: state.people.companiesUsers || [],
    companyId: state.account.selectedCompany.id,
    currentUserEmail: state.account.email,
    company: state.account.selectedCompany,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    closeInviteDialog: () => dispatch(KreoDialogActions.closeDialog(INVITE_PEOPLE_DIALOG)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const InviteUsersToProjectDialog = connector(InviteUsersToProjectDialogComponent);
