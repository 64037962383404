import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './chart-card.scss';

import { State } from 'common/interfaces/state';
// eslint-disable-next-line import/named
import { ChartRangeHoverEvent } from '../../../../../../components/engine/KreoEngine';
import { KreoChart, KreoChartMode } from '../../../../../../components/kreo-chart';
import { ResourcesData } from '../../../../interfaces/gantt-chart';
import {
  ChartDataProvider,
  ResourceDescription,
  ResourceType,
  SingleChartData,
} from '../../../../utils/gantt-chart';
import { actions as fourDActions } from '../../actions';
import { datesFormatter } from '../../dates-formatter';
import { ResourceIdentifier } from '../../interfaces/resource-identifier';
import { ResourceSelector } from '../resource-selector';
import { actions as resourceSelectorActions } from '../resource-selector/actions';

interface CardOwnProps {
  height: number;
  width: number;
  chartId: number;
  currentMoment: number;
  nextCurrentMoment: number | null;
  dataProvider: ChartDataProvider;
  timeframeStartDay: number;
  timeframeDuration: number;
}

interface CardStateProps {
  resourceToRender: ResourceDescription | null;
  resources: ResourcesData;
  projectStartDate: Date;
  isDisplayedOnTimeline: boolean;
  selectedResource: ResourceDescription | null;
}

interface CardDispatchProps {
  selectResource: (chartId: number, resource: ResourceIdentifier) => void;
  selectResourceForTimeline: (resource: ResourceIdentifier) => void;
  deselectResourceForTimeline: () => void;
}

interface CardProps extends CardOwnProps, CardStateProps, CardDispatchProps { }

function formatAsIntegerIfClose(n: number): string {
  const digits = Math.abs(Math.round(n) - n) < 0.05 ? 0 : 1;
  return n.toFixed(digits);
}

class ChartCardComponent extends React.Component<CardProps> {
  private topPadding: number = 60;

  public render(): React.ReactNode {
    const pixelsPerDay = this.props.width / this.props.timeframeDuration;
    const currentMomentToRender = this.props.nextCurrentMoment
      ? this.props.nextCurrentMoment
      : this.props.currentMoment;
    const currentMomentOffset = (currentMomentToRender - this.props.timeframeStartDay) * pixelsPerDay;

    const isAvailable =
      this.props.resourceToRender
        ? this.props.dataProvider.isResourceAvailable(this.props.resourceToRender.type, this.props.resourceToRender.id)
        : false;

    const chartData = this.props.resourceToRender
      ? this.props.dataProvider.calculateTimeboundChart(
        this.props.resourceToRender.type,
        this.props.resourceToRender.id,
        this.props.width)
      : null;


    return (
      <div
        className={classNames('chart-card', { selected: this.props.isDisplayedOnTimeline })}
        style={{ height: this.props.height }}
        onClick={
          this.props.isDisplayedOnTimeline
            ? this.props.deselectResourceForTimeline
            : this.selectResourceForTimeline
        }
      >
        <ResourceSelector
          chartId={this.props.chartId}
        />
        {
          this.props.resourceToRender
            ? (
              <KreoChart
                id={this.props.chartId}
                backgroundColor={'#202533'}
                height={this.props.height - this.topPadding}
                width={this.props.width}
                mode={KreoChartMode.Single}
                colors={this.props.resourceToRender.colors}
                data={chartData}
                isAvailable={isAvailable}
                topRightLabel={this.getCurrentValue(chartData)}
                renderTooltip={this.renderTooltip}
                renderLegend={this.props.resourceToRender.type !== ResourceType.Material}
              />
            )
            : null
        }
        <div
          className='chart-card__time-pointer'
          style={{ transform: `translateX(${currentMomentOffset}px)` }}
        />
      </div>
    );
  }

  public componentDidMount(): void {
    if (!this.props.resourceToRender) {
      this.props.selectResource(
        this.props.chartId,
        this.getDefaultSelectedResource(this.props.chartId),
      );
    }
  }

  @autobind
  private renderTooltip(event: ChartRangeHoverEvent): React.ReactNode {
    if (!event || !this.props.resourceToRender) {
      return;
    }

    const consumption = this.props.dataProvider.calculateResourceConsumptionForTimerange(
      this.props.resourceToRender.type,
      this.props.resourceToRender.id,
      event.startDay,
      event.endDay,
    );

    let datesString;
    const itemDuration = event.endDay - event.startDay;
    if (itemDuration <= 1) {
      datesString = datesFormatter.formatFullDate(event.startDay + itemDuration / 2, this.props.projectStartDate);
    } else {
      const dates = datesFormatter.formatDateSpan(
        event.startDay,
        event.endDay - 0.5,
        this.props.projectStartDate);
      datesString = `${dates.start} - ${dates.end}`;
    }

    const unit = this.props.resourceToRender && this.props.resourceToRender.unit || '';

    return (
      <div className='chart-card__tooltip'>
        <div>
          <b>{datesString}</b>
          {
            this.props.resourceToRender.type === ResourceType.Material
              ? ` (${consumption.amount.toFixed(3)} ${unit})`
              : null
          }
        </div>
        {
          this.props.resourceToRender.type !== ResourceType.Material
            ? (
              <div>
                Max: {formatAsIntegerIfClose(consumption.max)}
                <br />
                Hours: {formatAsIntegerIfClose(consumption.amount * 24)}
              </div>
            ) : null
        }
      </div>
    );
  }

  private getDefaultSelectedResource(chartId: number): ResourceIdentifier {
    const resourceTypesCount = 3;
    const type: ResourceType = chartId % resourceTypesCount + 1; // charts are counted starting from 0
    const resourceIndex = Math.floor(chartId / resourceTypesCount);
    const resourceId = Array.from(this.props.resources[type].keys())[resourceIndex];

    return {
      id: resourceId,
      type,
    };
  }

  private getCurrentValue(chartData: SingleChartData): string | null {
    if (!chartData) {
      return null;
    }

    const currentMomentToRender = this.props.nextCurrentMoment
      ? this.props.nextCurrentMoment
      : this.props.currentMoment;

    const rangeUnderThePointerIndex =
      Math.floor((currentMomentToRender - chartData.mergeStart) / chartData.mergeDuration);
    const value = chartData.mergedValues[rangeUnderThePointerIndex] || 0;

    const resource = this.props.resourceToRender;
    return resource.type === ResourceType.Material
      ? this.formatMaterialValue(value, resource.unit)
      : this.formatCrewValue(value);
  }

  private formatMaterialValue(value: number, unit: string): string {
    return `${value.toFixed(3)} ${unit}`;
  }

  private formatCrewValue(value: number): string {
    return Math.round(value).toString();
  }

  @autobind
  private selectResourceForTimeline(): void {
    this.props.selectResourceForTimeline(this.props.selectedResource);
  }
}

const mapStateToProps = (state: State, ownProps: CardOwnProps): CardStateProps => {
  const chartState =
    state.fourDVisualisation.sidePanel.resourcesTab.charts[ownProps.chartId];
  const resources = state.fourDVisualisation.resources;
  const resourceIdentifier = chartState
    ? chartState.previewedResource ? chartState.previewedResource : chartState.selectedResource
    : null;

  const resourceToRender = resourceIdentifier
    ? resources[resourceIdentifier.type].get(resourceIdentifier.id)
    : null;

  const selectedResourceIdentifier
    = chartState && chartState.selectedResource ? chartState.selectedResource : null;
  const selectedResource = selectedResourceIdentifier
    ? resources[selectedResourceIdentifier.type].get(selectedResourceIdentifier.id)
    : null;

  const resourceOnTimeline = state.fourDVisualisation.timelineChart.selectedResource
    || state.fourDVisualisation.timelineChart.defaultResource;

  const isDisplayedOnTimeline = selectedResource && resourceOnTimeline
    ? resourceOnTimeline.type === selectedResource.type && resourceOnTimeline.id === selectedResource.id
    : false;

  return {
    resourceToRender,
    resources,
    projectStartDate: state.fourDVisualisation.projectStartDate,
    selectedResource,
    isDisplayedOnTimeline,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): CardDispatchProps => {
  return {
    selectResource: (chartId: number, resource: ResourceIdentifier): void => {
      dispatch(resourceSelectorActions.selectResource(chartId, resource));
    },
    selectResourceForTimeline: (resource: ResourceIdentifier): void => {
      dispatch(fourDActions.selectResourceForTimeline(resource));
    },
    deselectResourceForTimeline: (): void => {
      dispatch(fourDActions.deselectResourceForTimeline());
    },
  };
};

export const ChartCard = connect(mapStateToProps, mapDispatchToProps)(ChartCardComponent);
