import { MentionConstants } from 'unit-2d-comments/constants';

const EMAIL_MENU_HEIGHT = 130;
const EMOJI_MENU_HEIGHT = 260;
const MAX_HEIGHT_COMMENTS_LIST = 700;
const MIN_INPUT_HEIGHT = 30;
const INPUT_HEIGHT_WITH_BUTTON = 55;

function getTextLength(text: string): number {
  const transformedText = text.replace(MentionConstants.MentionRegex, ' ');
  return transformedText.length;
}

function getStyleDropList(inputRef: HTMLInputElement, isMenuEmoji?: boolean): number {
  const menuHeight = isMenuEmoji ? EMOJI_MENU_HEIGHT : EMAIL_MENU_HEIGHT;
  const height = inputRef?.getBoundingClientRect().height;
  const inputTop = inputRef?.getBoundingClientRect().top;
  const inputHeight = height > INPUT_HEIGHT_WITH_BUTTON ? height - MIN_INPUT_HEIGHT : 0;
  const top = inputTop > MAX_HEIGHT_COMMENTS_LIST ? inputTop - menuHeight + inputHeight : inputTop + inputHeight;

  return top;
}

export const CommentTextAreaUtils = {
  getTextLength,
  getStyleDropList,
};
