import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import './permission-select.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { ExtendedPermission } from '../../interfaces/extended-permission';
import { PermissionActivityAccess } from '../../interfaces/permission-activity-access';
import { Permission } from '../permission';

interface Props extends AbilityAwareProps {
  permission: ExtendedPermission;
  dependantFromActivities: string[];
  onChange: (value: PermissionActivityAccess) => void;
}

class PermissionSelectComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { permission, ability } = this.props;
    return (
      <MaterialSelect
        value={permission.activityAccess}
        className='permission-select'
        dropdownClassName='permission-select-dropdown'
        selectionRenderer={this.selectionRenderer}
        onChange={this.onChange}
        displayUnderline={false}
        autoWidth={false}
        disabled={ability.cannot(Operation.Update, Subject.Role)}
      >
        {Object.keys(permission.availableAccess).map((access: PermissionActivityAccess) => {
          const tooltipProps = {};
          const disabled = this.isBlocked(access);
          if (disabled) {
            tooltipProps['data-for'] = 'permission-no-access-tooltip';
            tooltipProps['data-tip'] = '';
          }
          return (
            <MaterialMenuItem
              key={access}
              value={access}
              disabled={disabled}
              className={'permission-select-item'}
            >
              <span
                {...tooltipProps}
                className={classNames({ 'permission-select-item--disabled': disabled })}
              >
                <Permission access={access} displayNoAccessText={true} />
              </span>
              {disabled && this.renderTooltip()}
            </MaterialMenuItem>
          );
        })}
      </MaterialSelect>
    );
  }

  @autobind
  private selectionRenderer(value: PermissionActivityAccess): React.ReactNode {
    return <Permission access={value} />;
  }

  private isBlocked(value: PermissionActivityAccess): boolean {
    return (
      this.props.dependantFromActivities.length > 0 && value === PermissionActivityAccess.NoAccess
    );
  }

  @autobind
  private onChange(_e: React.SyntheticEvent<{}>, value: PermissionActivityAccess): void {
    if (!this.isBlocked(value) && value !== this.props.permission.activityAccess) {
      this.props.onChange(value);
    }
  }

  private renderTooltip(): React.ReactNode {
    const { dependantFromActivities } = this.props;
    return (
      <ReactTooltip
        id={'permission-no-access-tooltip'}
        className='permission-select-item__tooltip'
        type='light'
        effect='solid'
        place='bottom'
      >
        Please select <b>No Access</b> for <b>{dependantFromActivities.join(', ')}</b>&nbsp;
        feature{dependantFromActivities.length > 1 && 's'} to enable.
      </ReactTooltip>
    );
  }
}

export const PermissionSelect = withAbilityContext(PermissionSelectComponent);
