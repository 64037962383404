import { CellEditingStartedEvent, CellEditingStoppedEvent, ICellEditorParams } from 'ag-grid-community';

import { KreoColors } from 'common/enums/kreo-colors';
import { QtoTreeRowProperty } from '../../../units/projects/interfaces/quantity-take-off';
import {
  PropertyHelper,
} from '../../../units/projects/utils/quantity-take-off-tree-table';
import {
  QuantityTakeOffFormulaHelper,
} from '../../../units/projects/utils/quantity-take-off-tree-table/formula-helper';

const colors = [
  '#e51c23',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#5677fc',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#259b24',
  '#8bc34a',
  '#afb42b',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

export class HighlightFormulaHelper {
  private rowKey: string = null;
  private columnWithColor: Record<string, string> = {};
  private getValue: (event: CellEditingStartedEvent) => QtoTreeRowProperty;

  public constructor(getValue: (event: CellEditingStartedEvent) => QtoTreeRowProperty) {
    this.getValue = getValue;
  }

  public set(event: CellEditingStartedEvent): void {
    const value = this.getValue(event);
    this.rowKey = event.node.key;
    if (PropertyHelper.isFormula(value)) {
      const formula = PropertyHelper.getActualValue<string>(value);
      formula.replace(QuantityTakeOffFormulaHelper.formulaVariableRegex, (_match, key) => {
        this.columnWithColor[key] = this.getColor(key);
        return '';
      });
      event.api.refreshCells({ force: true });
    }
  }

  public reset(event: CellEditingStoppedEvent): void {
    this.rowKey = null;
    this.columnWithColor = {};
    event.api.refreshCells({ force: true });
  }

  public updateColumnColor(formula: string, params: ICellEditorParams): void {
    this.columnWithColor = {};
    formula.replace(QuantityTakeOffFormulaHelper.formulaVariableRegex, (_match, header) => {
      const colDef = params.columnApi.getAllColumns().find((column) => {
        return column.getColDef().headerName.toUpperCase() === header.toUpperCase();
      });
      if (colDef) {
        this.columnWithColor[colDef.getColId()] = this.getColor(colDef.getColId());
      }

      return '';
    });
  }

  public getStyle(params: ICellEditorParams): {} {
    if (params.node.key === this.rowKey) {
      const columnId = params.column.getColId();
      return {
        backgroundColor: this.columnWithColor[columnId] || null,
        color: this.columnWithColor[columnId] ? '#FFFFFF' : KreoColors.f1,
      };
    }
    return { backgroundColor: null, color: KreoColors.f1 };
  }

  public getColor(key: string): string {
    const hash = this.getHash(key);
    const index = ((hash % colors.length) + colors.length) % colors.length;
    return colors[index];
  }

  private getHash(value: string): number {
    let hash = 0;
    if (value.length === 0) return hash;
    for (let i = 0; i < value.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
      // eslint-disable-next-line no-bitwise
      hash = hash & hash;
    }
    return hash;
  }
}
