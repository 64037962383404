import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './person-tile.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RoleGroup } from 'common/enums/role-group';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { State } from 'common/interfaces/state';
import { BaseTile } from '../../../../components/tiles';
import { CompanySubscriptionModel } from '../../../../units/account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../units/account/selectors';
import { AccountApi } from '../../../account/api';
import { PeopleActions } from '../../actions/actions';
import { Person } from '../../interfaces/person';
import { PeopleUtil } from '../../people-util';
import { PersonTileMenu } from '../person-tile-menu';

interface OwnProps {
  person: Person;
  isLicensesLimitExceeded?: boolean;
  deletePerson(person: Person): void;
}

interface StateProps {
  subscriptionRoles: CompanyRoles;
  subscription: CompanySubscriptionModel;
  paidEmployeeCount: number;
}

interface DispatchProps {
  resendInvitation: (invitationToken: string) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps, AbilityAwareProps { }

interface ComponentState {
  hasImage: boolean;
}

class PersonTileComponent extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasImage: true,
    };
  }

  public render(): React.ReactNode {
    const { person, isLicensesLimitExceeded, subscriptionRoles, ability, subscription, paidEmployeeCount } = this.props;
    const role =
      subscriptionRoles && subscriptionRoles.roles
        ? subscriptionRoles.roles.find(r => r.id === person.roleId)
        : null;
    const isEmployee = role && role.group === RoleGroup.Employee;

    const canUpdateRole = ability.can(Operation.Update, Subject.Role);
    const canDelete = PeopleUtil.canByRoleGroup(ability, role ? role.group : null, Operation.Delete);

    const hasAvailableEmployeeLicenses = subscription && paidEmployeeCount < subscription.employeesMaxCount;


    return (
      <BaseTile
        component={
          <PersonTileMenu
            person={person}
            personRole={role}
            deletePerson={this.deletePerson}
            canUpdateRole={canUpdateRole}
            canDelete={canDelete}
            hasAvailableEmployeeLicenses={hasAvailableEmployeeLicenses}
          />
        }
        className={classNames('person-tile', { 'person-tile--admin': person.isOwner })}
      >
        <div
          className={classNames('person-tile__inner', {
            'person-tile__inner--limit-over':
              isLicensesLimitExceeded && isEmployee && person.isAccepted && !person.isOwner,
          })}
        >
          <div className='person-tile__common-info-section'>
            <div className='person-tile__admin-badge-line'>
              {person.isOwner ? (
                <div className='person-tile__admin-badge'>Admin</div>
              ) : null}
            </div>
            <div className='person-tile__avatar'>
              {this.state.hasImage ? (
                <img src={AccountApi.buildAvatarLink(person.id)} onError={this.avatarLoadingError} />
              ) : (
                this.getPersonInitials()
              )}
            </div>
            <div className='person-tile__main-info-line'>{this.getMainInfoLine()}</div>
            <div className='person-tile__additional-info-line'>{this.getAdditionalInfoLine()}</div>
          </div>
          {person.isAccepted ? (
            <div className='person-tile__role-section'>
              {role ? <div className='person-tile__role-badge'>{role.name}</div> : null}
            </div>
          ) : (
              <div className='person-tile__actions-section'>{this.getResendInvitationButton()}</div>
          )}
        </div>
      </BaseTile>
    );
  }

  @autobind
  private avatarLoadingError(): void {
    this.setState({ hasImage: false });
  }

  @autobind
  private resendInvitation(): void {
    this.props.resendInvitation(this.props.person.id);
  }

  private getPersonInitials(): string {
    const { person } = this.props;
    let initials: string;
    if (person.isAccepted) {
      const firstInitial = person.firstName ? person.firstName[0] : '';
      const secondInitial = person.lastName ? person.lastName[0] : '';
      initials = `${firstInitial}${secondInitial}`.toUpperCase();
    } else {
      initials = person.email[0].toUpperCase();
    }

    return initials;
  }

  private getMainInfoLine(): string {
    const { person } = this.props;
    if (person.isAccepted) {
      return `${person.firstName} ${person.lastName}`;
    } else {
      return person.email;
    }
  }

  private getAdditionalInfoLine(): string {
    const { person } = this.props;
    return person.isAccepted ? person.email : 'Never logged in';
  }

  private getResendInvitationButton(): React.ReactNode {
    const { person } = this.props;
    return (
      <div
        className={classNames('person-tile__resend-invite-button', {
          'person-tile__resend-invite-button--disabled': person.invitationResent,
        })}
        onClick={person.invitationResent ? null : this.resendInvitation}
      >
        {person.invitationResent ? 'Email has been sent' : 'Resend Invitation'}
      </div>
    );
  }

  @autobind
  private deletePerson(): void {
    const { person } = this.props;
    this.props.deletePerson(person);
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    subscriptionRoles: state.account.subscriptionRoles,
    subscription: AccountSelectors.currentSubscription(state),
    paidEmployeeCount: AccountSelectors.getPaidEmployeesCount(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    resendInvitation: (userId: string) =>
      dispatch(PeopleActions.resendInvitation(userId)),
  };
}

export const PersonTile = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(PersonTileComponent),
);
