import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './kreo-dialog.scss';

import { KreoDialogActions } from '../';
import { State } from '../../../../common/interfaces/state';
import { getOrCreateRoot } from '../utils';
import { DialogContainer } from './dialog-container';
import { TitleContainer } from './title-container';


interface StateProps<T> {
  open: boolean;
  data: T;
}

interface DispatchProps {
  closeDialog: (name: string) => void;
}

interface OwnProps<T> {
  name: string;
  size?: 'large';
  isModal?: boolean;
  title?: React.ReactNode;
  titleClassName?: string;
  bodyClassName?: string;
  overlayClassName?: string;
  onClose?: (data: T) => void;
  onOpen?: (data: T) => void;
  children?: React.ReactNode;
}

interface Props<T> extends StateProps<T>, DispatchProps, OwnProps<T> {
}

export const dialogAnimationDelay: number = 300;

export class KreoDialogComponent<T> extends React.PureComponent<Props<T>> {
  public static root: HTMLDivElement;
  private dialogRef: HTMLDivElement;

  constructor(props: Props<T>) {
    super(props);
    this.dialogRef = document.createElement('div');
    this.dialogRef.className = 'kreo-dialog';
    KreoDialogComponent.getRoot();
  }

  public static getRoot(): HTMLDivElement {
    if (!KreoDialogComponent.root) {
      KreoDialogComponent.root = getOrCreateRoot();
    }
    return KreoDialogComponent.root;
  }

  public componentDidMount(): void {
    // push ref to root element
    const root = KreoDialogComponent.root;
    if (!!root && this.props.open) {
      root.appendChild(this.dialogRef);
    }
  }

  public componentDidUpdate(prevProps: Props<T>): void {
    if (this.props.open !== prevProps.open) {
      const root = KreoDialogComponent.root;
      if (this.props.open) {
        root.appendChild(this.dialogRef);
        if (this.props.onOpen) {
          this.props.onOpen(this.props.data);
        }
      } else {
        setTimeout(() => root.removeChild(this.dialogRef), dialogAnimationDelay);
      }
    }
  }

  public componentWillUnmount(): void {
    // remove ref from root element
    const root = KreoDialogComponent.root;
    if (!!root && this.props.open) {
      setTimeout(() => root.removeChild(this.dialogRef), dialogAnimationDelay);
    }
  }

  public render(): React.ReactNode {
    return ReactDOM.createPortal(
      <DialogContainer
        dialogName={this.props.name}
        overlayClassName={this.props.overlayClassName}
        bodyClassName={this.props.bodyClassName}
        size={this.props.size}
        isModal={this.props.isModal}
        onClose={this.onClose}
        open={this.props.open}
      >
        <TitleContainer
          className={this.props.titleClassName}
          onClose={this.onClose}
        >
          {this.props.title}
        </TitleContainer>
        {this.props.children}
      </DialogContainer>,
      this.dialogRef);
  }


  @autobind
  private onClose(): void {
    if (this.props.onClose) {
      this.props.onClose(this.props.data);
    }
    this.props.closeDialog(this.props.name);
  }
}


function mapStateToProps<T>(state: State, ownProps: OwnProps<T>): StateProps<T> {
  return {
    open: ownProps.name in state.dialog,
    data: state.dialog[ownProps.name],
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    closeDialog: (name: string) => {
      dispatch(KreoDialogActions.closeDialog(name));
    },
  };
};

const connecter = connect(mapStateToProps, mapDispatchToProps);
export const KreoDialog = connecter(KreoDialogComponent);
