import styled from 'styled-components';

const MenuItemButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  height: 40px;
  padding: 0 20px;
  svg {
    height: 20px;
    fill: ${p => p.theme.color.turquoise};
  }
`;

const MenuItemText = styled.div`
  flex-grow: 1;
  overflow: hidden;

  div {
    > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }
`;

const MenuItem = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`;

const MenuContainer = styled.div<{ x: number, y: number }>`
  position: absolute;
  left: ${p => p.x}px;
  top: ${p => p.y}px;
  > div {
    position: fixed;
  }
`;

const SearchListItemContainer = styled.div`
  > div {
    box-shadow: none;
    border-radius: 0;
  }
`;

export const Styled = {
  MenuItemButton,
  MenuItem,
  MenuItemText,
  MenuContainer,
  SearchListItemContainer,
};
