import * as paper from 'paper';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DrawingsActions } from 'common/components/drawings/actions/creators/common';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { useDrawingsLayoutApi } from 'common/components/drawings/drawings-layout-api-context';
import { useCurrentDrawingInfo } from 'common/components/drawings/hooks';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { ComparisonLine } from 'common/pdf/compare-document';
import { useOpenCloseDialog } from 'common/UIKit';
import { CompareMenu } from './compare-menu';
import { ComparePagesLayout } from './compare-pages-layout';
import { SelectPageToCompareDialog } from './select-page-to-compare-dialog';


interface Props {
  onFinished: () => void;
}

const CompareButtonComponent: React.FC<Props> = ({ onFinished }) => {
  const [ openSelectDrawing, closeSelectDrawing ] = useOpenCloseDialog(DrawingDialogs.SELECT_COMPARE_PAGE);
  const [currentDrawingId, setCurrentDrawingId] = useState<string>('');

  const currentDrawing = useCurrentDrawingInfo();
  const dispatch = useDispatch();

  const setDrawingToCompare = useCallback(
    (drawingToCompareId) =>
      dispatch(DrawingsActions.setPageToCompare(currentDrawing.drawingId, drawingToCompareId)),
    [dispatch, currentDrawing]);

  const drawingRendered = useSelector<State, boolean>(s => s.drawings.drawingRenderedStatus);
  const [ showCompareLayout, setShowCompareLayout ] = useState<boolean>(false);
  const drawingLayoutApi = useDrawingsLayoutApi();

  const updateSettingProperty = useCallback((currentId, property, value) =>
    dispatch(DrawingsActions.updateComparisonSetting(currentId, property, value)),
  [dispatch]);

  const closeCompareLayout = useCallback(() => {
    paper.activate();
    setShowCompareLayout(false);
  }, []);

  const openCompareLayout = useCallback((e: React.MouseEvent) => {
    ConstantFunctions.stopEvent(e);
    if (drawingRendered) {
      setShowCompareLayout(true);
    }
  }, [drawingRendered]);


  const applyTransform = useCallback((
    target: { sourceDrawingLine: ComparisonLine, targetDrawingLine: ComparisonLine },
  ) => {
    if (!target) {
      updateSettingProperty(currentDrawing.drawingId, 'sourceLine', null);
      updateSettingProperty(currentDrawing.drawingId, 'targetLine', null);
    } else {
      const { sourceDrawingLine, targetDrawingLine } = target;
      updateSettingProperty(currentDrawing.drawingId, 'sourceLine', sourceDrawingLine);
      updateSettingProperty(currentDrawing.drawingId, 'targetLine', targetDrawingLine);
    }
    closeCompareLayout();
  }, [closeCompareLayout, currentDrawing.drawingId, updateSettingProperty]);

  const onReset = useCallback(() => {
    closeSelectDrawing();
    onFinished();
    dispatch(DrawingsActions.resetComparisonSettings(currentDrawing.drawingId));
  }, [closeSelectDrawing, onFinished, dispatch, currentDrawing.drawingId]);

  useEffect(() => {
    if (!currentDrawing) {
      onFinished();
    } else if (!currentDrawingId && !currentDrawing.pageToCompareId) {
      openSelectDrawing();
    } else if (!currentDrawing.pageToCompareId && currentDrawingId && currentDrawingId !== currentDrawing.drawingId) {
      onReset();
      setCurrentDrawingId('');
    }

    if (currentDrawing?.pageToCompareId && currentDrawing.drawingId !==currentDrawingId) {
      setCurrentDrawingId(currentDrawing.drawingId);
    }
  }, [currentDrawing, currentDrawingId, onFinished, onReset, openSelectDrawing]);

  const onCloseSelect = useCallback(() => {
    closeSelectDrawing();
    if (!currentDrawing.pageToCompareId) {
      onFinished();
    }
  }, [closeSelectDrawing, currentDrawing, onFinished]);

  if (!currentDrawing) {
    return null;
  }

  return (
    <>
      <SelectPageToCompareDialog
        currentDrawings={currentDrawing}
        onSelectDrawing={setDrawingToCompare}
        onCloseDialog={onCloseSelect}
      />
      {showCompareLayout ? (
        <ComparePagesLayout
          drawingRendered={drawingRendered}
          close={closeCompareLayout}
          currentDrawing={drawingLayoutApi.getViewport().drawingInfo.pdfDocument}
          applyTransform={applyTransform}
        />
      ) : null}
      {
        currentDrawing.pageToCompareId ? (
          <CompareMenu
            shown={currentDrawing.pagesCompareSettings?.isActive}
            drawingRendered={drawingRendered}
            currentDrawing={currentDrawing}
            openCompareLayout={openCompareLayout}
            onReset={onReset}
            onOpenFileMenuClick={openSelectDrawing}
          />
        ) : null
      }
    </>
  );
};

export const CompareControl = React.memo(CompareButtonComponent);
