import styled from 'styled-components';

const Container = styled.div`
  height: 24px;
  min-width: auto;
  gap: 2px;
  background-color: ${p => p.theme.color.background};
  cursor: pointer;
  border-radius: 8px;
  flex-wrap: wrap;
  align-self: center;
  padding: 2px;
  transition: all 0.1s ease-in;
  display: flex;
  box-sizing: border-box;
  &:active {
    transform: scale(0.95);
  }
  &:hover {
    background-color: ${p => p.theme.color.backgroundRush};
    box-shadow: 0 0 0 2px ${p => p.theme.color.pale};
  }
`;

const Count = styled.div`
  min-width: 20px;
  background-color: ${p => p.theme.color.pale};
  color: ${p => p.theme.color.turquoise};
  text-align: center;
  border-radius: 6px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  display: flex;
  box-sizing: border-box;
`;

export const Styled = {
  Container,
  Count,
};
