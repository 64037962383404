import { Action } from 'redux';
import { ActionWith } from 'common/interfaces/action-with';
import { RevisionInfoModel, RevisionState } from 'unit-cost-estimate/interfaces';
import { DuplicateRevisionPayload, SetRevisionStatePayload, UpdateRevisionPayload } from '../payloads';
import { RevisionsActionTypes } from '../types';

function fetchProjectRevisionsInfo(): Action {
  return {
    type: RevisionsActionTypes.FETCH_PROJECT_REVISIONS_INFO,
  };
}

function setProjectRevisionsInfo(revisionsInfo: RevisionInfoModel[]): ActionWith<RevisionInfoModel[]> {
  return {
    type: RevisionsActionTypes.SET_PROJECT_REVISIONS_INFO,
    payload: revisionsInfo,
  };
}

function deleteRevision(revisionId: number): ActionWith<number> {
  return {
    type: RevisionsActionTypes.DELETE_REVISION,
    payload: revisionId,
  };
}

function updateRevision(revisionId: number, name: string): ActionWith<UpdateRevisionPayload> {
  return {
    type: RevisionsActionTypes.UPDATE_REVISION,
    payload: { revisionId, form: { name } },
  };
}


function duplicateRevision(revisionId: number, name: string): ActionWith<DuplicateRevisionPayload> {
  return {
    type: RevisionsActionTypes.DUPLICATE_REVISION,
    payload: { revisionId, name },
  };
}

function fetchRevisionsState(revisionId: number): ActionWith<number> {
  return {
    type: RevisionsActionTypes.FETCH_REVISION_STATE,
    payload: revisionId,
  };
}

function setRevisionState(revisionId: number, revisionState: RevisionState): ActionWith<SetRevisionStatePayload> {
  return {
    type: RevisionsActionTypes.SET_REVISION_STATE,
    payload: { revisionId, revisionState },
  };
}

function setCurrentRevisionState(revisionState: RevisionState): ActionWith<RevisionState> {
  return {
    type: RevisionsActionTypes.SET_CURRENT_REVISION_STATE,
    payload: revisionState,
  };
}

export const RevisionsActions = {
  fetchProjectRevisionsInfo,
  setProjectRevisionsInfo,
  deleteRevision,
  updateRevision,
  duplicateRevision,
  fetchRevisionsState,
  setRevisionState,
  setCurrentRevisionState,
};
