import React from 'react';
import { DrawingsLayoutApi } from 'common/components/drawings';
import { TwoDViewTableType } from '../../../../constants/table-type';
import { ReportPage } from '../../../../interfaces';
import { ItemsView } from '../items-view';
import { MeasureView } from '../measure-view';
import { useLoadConfigEffect, useStateVariables } from './hooks';

interface OwnProps {
  drawingsLayoutApi: DrawingsLayoutApi;
  report: ReportPage;
}

export const ViewTable: React.FC<OwnProps> = ({
  report,
  drawingsLayoutApi,
}) => {
  const { config, hasLoading, isPiaLoading } = useStateVariables(report.id);
  useLoadConfigEffect(report.id);
  if (!config || hasLoading || isPiaLoading) {
    return <></>;
  }

  const isItemSource = config.sourceType === TwoDViewTableType.Items;

  return (isItemSource
    ? (
      <ItemsView
        key={report.id}
        tableId={report.id}
        drawingsLayoutApi={drawingsLayoutApi}
        config={config}
        tableName={report.name}
      />)
    : (
      <MeasureView
        key={report.id}
        tableId={report.id}
        drawingsLayoutApi={drawingsLayoutApi}
        config={config}
        tableName={report.name}
      />)
  );
};
