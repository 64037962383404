export enum AvailabilityKeys {
  EditMeasurements,
  OnlyOneSelected,
  MoreThanOneSelected,
  Rendered,
  NotIntersectedAtStart,
  IsArea,
  IsLine,
  IsCount,
  NotCount,
  NotArea,
  CanBoolWithPolygons,
  CanJoin,
  CanUseAutoCount,
  CanTraceLink,
  CanMagicSearch,
}
