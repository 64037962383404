import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { DashboardActionTypes } from '../actions/types/dashboard';
import { DASHBOARD_INITIAL_STATE } from '../constants/dashboard-initial-state';
import { CostEstimateTreeMap } from '../interfaces/dashboard/cost-estimate-tree-map';
import { CostHistogram } from '../interfaces/dashboard/cost-histogram';
import { DashboardReduxState } from '../interfaces/dashboard/dashboard-redux-state';

export const DashboardReducerMethods: ReducerMethods<DashboardReduxState> = {
  [DashboardActionTypes.GET_ALL_DATA_REQUEST]: state => {
    return new MonoliteHelper(state)
      .set(_ => _.statuses.costEstimateTreeMap, RequestStatus.Loading)
      .set(_ => _.statuses.costHistogram, RequestStatus.Loading)
      .get();
  },
  [DashboardActionTypes.GET_COST_HISTOGRAM_DATA_SUCCEED]: (state, payload: CostHistogram[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.costHistogram, payload)
      .set(_ => _.statuses.costHistogram, RequestStatus.Loaded)
      .get();
  },
  [DashboardActionTypes.GET_COST_ESTIMATE_TREE_MAP_DATA_SUCCEED]: (state, payload: CostEstimateTreeMap[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.costEstimateTreeMap, payload)
      .set(_ => _.statuses.costEstimateTreeMap, RequestStatus.Loaded)
      .get();
  },
  [DashboardActionTypes.DROP_ALL_DATA]: () => {
    return DASHBOARD_INITIAL_STATE;
  },
};
