import React from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { Property } from 'common/components/property';
import { RenderIf } from 'common/components/render-if';
import { DrawingsColorButton } from '../../drawings-controls/color-control/drawings-color-button';
import { DrawingsDrawMode } from '../../enums';
import { AiSuggest } from '../top-control-group/ai-suggest-button/ai-suggest';
import { FolderProperties } from './folder-properties';
import { GeometryParams } from './geometry-params';
import { useNewInstanceColor } from './hooks';
import { useSelectedGroup } from './hooks/use-selected-group';
import { NameProperty } from './name-property';
import { Styled } from './styled';
import { StylesMenu } from './styles-menu';


const NewInstancePanelComponent: React.FC = () => {
  const canUseAiSuggest = useAbility(Operation.Manage, Subject.Takeoff2dAiSuggest);
  const canUseAiSuggestForLines = useAbility(Operation.Manage, Subject.Takeoff2dMeasurementAutocomplete);

  const selectedGroup = useSelectedGroup();
  const { drawMode } = useDrawModeApi();
  const showAiSuggest = (drawMode === DrawingsDrawMode.Polygon || drawMode === DrawingsDrawMode.Polyline)
    && canUseAiSuggestForLines;

  const [newDrawingColor, setColor] = useNewInstanceColor(selectedGroup);

  return (
    <Styled.Container>
      <NameProperty
        selectedGroup={selectedGroup}
      />
      { selectedGroup ? (
          <FolderProperties
            selectedGroup={selectedGroup}
          />) : null
      }
      <GeometryParams />
      <Property title='Color'>
        <DrawingsColorButton
          color={newDrawingColor}
          changeColor={setColor}
        />
      </Property>
      <StylesMenu/>
      <RenderIf condition={showAiSuggest}>
        <AiSuggest
          enabled={canUseAiSuggest}
        />
      </RenderIf>
    </Styled.Container>
  );
};


export const NewInstanceSettings = React.memo(NewInstancePanelComponent);
