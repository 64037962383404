import styled from 'styled-components';

const Container = styled.div`
  gap: 4px;
  flex-direction: column;
  padding: 0;
  display: flex;
`;

const Content = styled.div`
  gap: 4px;
  flex-wrap: wrap;
  display: flex;
`;

export const Styled = {
  Container,
  Content,
};
