import autobind from 'autobind-decorator';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsCountGeometry } from 'common/components/drawings/interfaces';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { EngineObject } from '../../../common';
import {
  DrawingsGeometryEntityCountConfig,
  DrawingsGeometryEntityCount,
} from '../../../drawings-geometry-entities';
import { ModifiableInstanceType } from '../../drawings-geometry-modify';
import { GeometryFactory } from './geometry-factory';

export class CountFactory extends EngineObject<Partial<DrawingsGeometryEntityCountConfig>>
  implements GeometryFactory<DrawingsCountGeometry, DrawingsGeometryEntityCountConfig>  {

  @autobind
  public render(
    instanceId: string,
    geometry: DrawingsCountGeometry,
    color: DrawingsPaperColorInfo,
    overrodeProps: Partial<DrawingsGeometryEntityCountConfig>,
  ): ModifiableInstanceType {
    const config = {
      ...this._config,
      id: instanceId,
      geometry,
      color,
      instanceType: DrawingsInstanceType.Count,
      ...overrodeProps,
    };

    return new DrawingsGeometryEntityCount(config as DrawingsGeometryEntityCountConfig);
  }
}

