import { AnyAction } from 'redux';
import { CE_MEASUREMENT_INITIAL_STATE } from 'unit-cost-estimate/constants';
import { CEMeasurementsState } from 'unit-cost-estimate/interfaces';
import { ceMeasurementReducerMethods } from 'unit-cost-estimate/reducers/measurements';

export default function(
  state: CEMeasurementsState = CE_MEASUREMENT_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null }): CEMeasurementsState {
  if (action.type in ceMeasurementReducerMethods) {
    return ceMeasurementReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
