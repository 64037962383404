import { ElementTooltip, IconButton, Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { SortData, SortOptions, SortValue } from '../interfaces/drawing-measurement-sort';
import {
  DrawingsAnnotationLegendSortMenu,
} from './drawings-annotation-legend-sort-menu/drawings-annotation-legend-sort-menu';

interface ComponentState {
  sortMenu: { isShow: boolean, x: number, y: number };
}

interface Props {
  sortDataState: SortData;
  changeSortData: (sortData: SortData) => void;
}

class DrawingsAnnotationLegendSortComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sortMenu: { isShow: false, x: 0, y: 0 },
    };
  }

  public render(): React.ReactNode {
    const { isShow, x, y } = this.state.sortMenu;
    return (
      <>
        <ElementTooltip
          text='Sort'
          position='top'
          speed='l'
        >
          <IconButton
            Icon={Icons.Sorting}
            onClick={this.sortButtonClick}
            width={20}
            height={20}
            defaultColor={isShow && 'turquoise'}
          />
        </ElementTooltip>
        {isShow && <DrawingsAnnotationLegendSortMenu
          x={x}
          y={y}
          onClose={this.sortClose}
          selected={this.props.sortDataState}
          handleSortChange={this.handleSortChange}
        />}
      </>
    );
  }

  @autobind
  private sortClose(): void {
    this.setState({ sortMenu: { isShow: false, x: 0, y: 0 } });
  }

  @autobind
  private sortButtonClick(e: React.MouseEvent<HTMLButtonElement>): void {
    const { left, top } = e.currentTarget.getBoundingClientRect();
    this.setState({ sortMenu:
      { isShow: true, x: left, y: top + 10 } });
  }

  @autobind
  private handleSortChange(sortOption: SortOptions, value: SortValue): void {
    const { sortDataState } = this.props;
    const updatedSortSetting = {
      ...sortDataState,
      [sortOption]: value,
    };
    this.props.changeSortData(updatedSortSetting);
  }
}

export const DrawingsAnnotationLegendSort = DrawingsAnnotationLegendSortComponent;
