import * as paper from 'paper';
import { DrawingsCanvasColors, DrawingsCanvasConstants } from '../../constants';

import { ShortPointDescription } from '../../interfaces';
import { VisibleEntity, VisibleEntityConfig } from '../common';
import { Highlightable } from './text-search-highlight-controller';

export interface TextSearchResultRectConfig extends VisibleEntityConfig<ShortPointDescription[]> {
  layer: paper.Layer | paper.Group;
  fillColor?: paper.Color;
  strokeColor?: paper.Color;
  highlightColor?: paper.Color;
}

export class TextSearchResultRect<T extends TextSearchResultRectConfig = TextSearchResultRectConfig>
  extends VisibleEntity<ShortPointDescription[], T> implements Highlightable {
  protected _path: paper.Path;

  public set strokeWith(value: number) {
    this._path.strokeWidth = value;
  }

  public override destroy(): void {
    this._path.remove();
  }

  public setHighlightStatus(status: boolean): void {
    if (status) {
      this._path.fillColor = this._config.highlightColor || DrawingsCanvasColors.utilityHighlight;
    } else {
      this._path.fillColor = this._config.fillColor || DrawingsCanvasColors.utilityTransparent;
    }
  }

  protected override render(geometry: ShortPointDescription[]): void {
    this._path = new paper.Path(geometry);
    this._path.closePath();
    this.applyStyles();
    this._path.addTo(this._config.layer);
  }

  protected applyStyles(): void {
    this._path.strokeColor = this._config.strokeColor || DrawingsCanvasColors.utility;
    this._path.fillColor = this._config.fillColor || DrawingsCanvasColors.utilityTransparent;
    this._path.strokeWidth = DrawingsCanvasConstants.infoLinesStroke
      / this._config.renderParamsContextObserver.getContext().zoom;
    this._path.strokeCap = 'round';
  }
}
