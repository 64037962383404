import { ClickOutsideWrapper, OpenArrowButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { RenderIf } from 'common/components/render-if';
import { ConstantFunctions } from 'common/constants/functions';
import { ButtonInput, InputSmallStyled } from '..';
import { DropdownCustomList } from './dropdown-custom-list';
import { useArrowMove, useInputHandler } from './hooks';
import { Styled } from './styled';

interface Props {
  isCollapse?: boolean;
  elements: string[];
  activeElement: string;
  renderElement: (element: string) => React.ReactNode;
  onSelect: (element: string) => void;
  onBlur?: (element: string) => void;
  inputId?: string;
  isShowArrow?: boolean;
  placeholder?: string;
  placeholderAsValue?: boolean;
}

export const InputSelectWithCustomOptions: React.FC<Props> = ({
  elements,
  renderElement,
  isCollapse,
  onSelect,
  onBlur,
  activeElement,
  isShowArrow,
  placeholder,
  placeholderAsValue,
}) => {
  const handleSelect = React.useCallback(
    (element: string) => {
      onSelect(element);
    },
    [onSelect, elements, onBlur],
  );

  const { isDropdownOpen, openDropdown, closeDropdown, onClickDropDownTextItem } = useInputHandler(handleSelect);

  const showValue = !!activeElement;

  const { handleArrowKeys, handleOpen, focusElement } = useArrowMove(
    elements,
    isDropdownOpen,
    openDropdown,
    activeElement,
    ConstantFunctions.doNothing,
    onClickDropDownTextItem,
    false,
  );

  const handlExit = useCallback(() => {
    closeDropdown();
  }, [isDropdownOpen, showValue]);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      ConstantFunctions.stopEvent(e);
      handleOpen();
    },
    [handleOpen],
  );

  return (
    <ClickOutsideWrapper onExit={handlExit}>
      <Styled.DropDownContainer onClick={handleClick} onKeyDown={handleArrowKeys} isCollapse={isCollapse}>
        {activeElement ? (
          <InputSmallStyled.FakeInputStyle>
            {renderElement(activeElement)}
          </InputSmallStyled.FakeInputStyle>
        ) : (
          <ButtonInput value={placeholder && placeholderAsValue ? placeholder : undefined} placeholder={placeholder}/>
        )}
        <RenderIf condition={isShowArrow}>
          <OpenArrowButton isOpen={isDropdownOpen} />
        </RenderIf>
        {isDropdownOpen && (
          <Styled.DropDownMenuContainer>
            <DropdownCustomList
              elements={elements}
              onClick={onClickDropDownTextItem}
              focusElement={focusElement}
              activeElement={activeElement}
              optionRenderer={renderElement}
            />
          </Styled.DropDownMenuContainer>
        )}
      </Styled.DropDownContainer>
    </ClickOutsideWrapper>
  );
};
