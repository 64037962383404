import autobind from 'autobind-decorator';
import { DrawingsInstanceMeasure } from 'common/components/drawings';
import { UpdateCellData } from 'common/components/excel-table';
import { SheetUpdateCells } from '../../actions/payloads';
import { ReportPage } from '../../interfaces';
import { UpdatedCell } from '../get-ref-map';
import { cellStoreActionEventType, cellStoreActions } from './exted-with-cell-store';
import { CellDataController, ReportCellController } from './report-cell-controller';
import { CellDataStore, SheetDataStore } from './report-cell-data-store';

export class ParentReportCellController implements CellDataController {
  private sendEvent: (type: string, value: any) => void = null;
  private cellStoreController: ReportCellController = null;

  constructor(
    sendEvent: (type: string, value: any) => void,
    addEventHandler: (type: string, handler: (data: any) => void) => void,
    cellStoreController: ReportCellController,
  ) {
    this.sendEvent = sendEvent;
    this.cellStoreController = cellStoreController;
    addEventHandler(cellStoreActionEventType, this.handleEvents);
  }

  public async initReportCellStores(projectId: string): Promise<void> {
    this.cellStoreController.initReportCellStores(projectId);
  }

  public async loadData(pages: ReportPage[], projectId: string, onFinish: (id: string) => void): Promise<void> {
    this.cellStoreController.loadData(pages, projectId, onFinish);
  }

  public updateCells(payload: Array<SheetUpdateCells<UpdateCellData[]>>, skipRefresh?: boolean): void {
    this.cellStoreController.updateCells(payload, skipRefresh);
    // this.sendUpdate(cellStoreActions.updateCells, true, payload[0].sheetId);
  }

  public updateCellDataOnFill(sheetId: string, columnId: string, rowId: string, data: string | number): void {
    this.cellStoreController.updateCellDataOnFill(sheetId, columnId, rowId, data);
  }

  public updateCellsByMeasure(measures: DrawingsInstanceMeasure[]): void {
    this.cellStoreController.updateCellsByMeasure(measures);
    this.sendUpdate(cellStoreActions.updateCellsByMeasure, measures);
  }

  public updateCellsByRemoveInstancesIds(instancesIds: string[]): void {
    this.cellStoreController.updateCellsByRemoveInstancesIds(instancesIds);
    // this.sendUpdate(cellStoreActions.updateCellsByRemoveInstancesIds);
  }

  public addNewRows(sheetId: string, count: number): void {
    this.cellStoreController.addNewRows(sheetId, count);
  }

  public setColumnWidthBulck(sheetId: string, payload: Array<{ columnId: string, width: number }>): void {
    this.cellStoreController.setColumnWidthBulck(sheetId, payload);
  }

  public addNewColumns(sheetId: string, count: number): void {
    this.cellStoreController.addNewColumns(sheetId, count);
  }

  public updateCellValueWithDrawings(drawingsIds: string[]): void {
    this.cellStoreController.updateCellValueWithDrawings(drawingsIds);
    // this.sendUpdate(cellStoreActions.updateCellsByRemoveInstancesIds, drawingsIds);
  }

  public updateDynamicCells(dynamicGroups: Record<string, string[]>): void {
    this.cellStoreController.updateDynamicCells(dynamicGroups);
    // this.sendUpdate(cellStoreActions.updateDynamicCells, dynamicGroups);
  }

  public onDrawingsLoaded(drawingsIds: string[], dynamicGroups: Record<string, string[]>): void {
    this.cellStoreController.onDrawingsLoaded(drawingsIds, dynamicGroups);
    // this.sendUpdate(cellStoreActions.updateCellsByRemoveInstancesIds);
  }

  public selectSheetValue(sheetId: string, columnId: string, rowId: string): string | number {
    return this.cellStoreController.selectSheetValue(sheetId, columnId, rowId);
  }

  public selectCellData(sheetId: string, columnId: string, rowId: string): string {
    return this.cellStoreController.selectCellData(sheetId, columnId, rowId);
  }

  public selectSheetData(reportId: string): SheetDataStore {
    return this.cellStoreController.selectSheetData(reportId);
  }

  public getUpdatedCells(sheetId: string): UpdatedCell[] {
    return this.cellStoreController.getUpdatedCells(sheetId);
  }

  public getReportData(): CellDataStore {
    return this.cellStoreController.getReportData();
  }

  public isValidCell(cellId: string): boolean {
    return this.cellStoreController.isValidCell(cellId);
  }

  public deleteCellDataStore(sheetId: string): void {
    return this.cellStoreController.deleteCellDataStore(sheetId);
  }

  public dropState(): void {
    return this.cellStoreController.dropState();
  }

  public clearStore(): void {
    return this.cellStoreController.clearStore();
  }

  @autobind
  private handleEvents(data: any): void {
    const type = data.type;
    switch (type) {
      case cellStoreActions.updateCells: {
        this.cellStoreController.updateCellStore(data.value);
        break;
      }
      case cellStoreActions.addNewRows: {
        this.cellStoreController.updateColumns(data.value);
        this.cellStoreController.updateCellStore(data.value);
        break;
      }
      case cellStoreActions.setColumnWidthBulck: {
        this.cellStoreController.updateColumns(data.value);
        this.cellStoreController.updateCellStore(data.value);
        break;
      }
      case cellStoreActions.addNewColumns: {
        this.cellStoreController.updateColumns(data.value);
        this.cellStoreController.updateCellStore(data.value);
        break;
      }
      case cellStoreActions.updateCellsByRemoveInstancesIds: {
        this.cellStoreController.updateCellStore(data.value);
        break;
      }
      case cellStoreActions.updateCellsByMeasure: {
        this.cellStoreController.updateCellStore(data.value);
        break;
      }
      default: {
        console.error(`Wrong cell store event type: ${type}`);
      }
    }
  }

  private sendUpdate(type: string, ...payload: any[]): void {
    const dataPayload = this.cellStoreController.getReportData();
    const valuePayload = this.cellStoreController.getValueStore();
    const dynamicPayload = this.cellStoreController.getDynamicStore();
    this.sendEvent(
      cellStoreActionEventType,
      {
        type,
        value: { dataPayload, valuePayload, dynamicPayload },
        payload,
      });
  }
}
