import autobind from 'autobind-decorator';
import axios, { CancelTokenSource } from 'axios';

import { CommonApi } from '../../../api/server';
import { AIChat } from '../interfaces';
import { GPTMessage } from './interfaces';

export interface RequestBody {
  model: string;
  messages: GPTMessage[];
  stream: boolean;
  temperature: number;
}

export interface ChatContext {
  full: string;
  updatesCount: number;
  prevLoaded: number;
  delta: string;
}

function createCtx(): ChatContext {
  return {
    full: '',
    delta: '',
    updatesCount: 0,
    prevLoaded: 0,
  };
}

function parse(output: string): any {
  try {
    return JSON.parse(output);
  } catch (e) {
    console.warn('Failed to parse GPT output', output, e);
    return { choices: [{ delta: null }] };
  }
}

export function createDefaultRequestBody(query: GPTMessage[]): RequestBody {
  return {
    model: 'gpt-3.5-turbo',
    messages: query,
    stream: true,
    temperature: 0,
  };
}

export class GptAIChat<T = RequestBody> implements AIChat<T> {
  protected _url: string = '/copilot/v1';

  private _cancelationToken: CancelTokenSource;

  constructor(url?: string) {
    if (url) {
      this._url = url;
    }
  }

  @autobind
  public send(requestBody: T, onProgress: (value: any) => void): Promise<any> {
    if (this._cancelationToken) {
      this.cancel();
    }
    this._cancelationToken = axios.CancelToken.source();
    const ctx = createCtx();
    const onDownloadProgress = (progressEvent: any): void => {
      this.onDownloadProgress(progressEvent, ctx, onProgress);
    };
    return CommonApi.postWithProgress(
      this._url,
      requestBody,
      onDownloadProgress,
      null,
      this._cancelationToken,
    ).then(() => {
      this._cancelationToken = null;
      return ctx.full;
    });
  }

  @autobind
  public cancel(): void {
    if (this._cancelationToken) {
      this._cancelationToken.cancel();
      this._cancelationToken = null;
    }
  }

  protected onDownloadProgress(progressEvent: any, context: ChatContext, onProgress: (text: string) => void): void {
    const chunkText: string = progressEvent.currentTarget.responseText.substr(context.prevLoaded, progressEvent.loaded);
    context.prevLoaded += chunkText.length;
    const lines = chunkText.split(/(\r?\n)/g);
    for (const line of lines) {
      if (!line.startsWith('data: ')) {
        continue;
      }
      const output = line.slice(6).trim();
      if (output === '[DONE]') {
        break;
      }

      const json = parse(output);
      const content = json.choices[0].delta?.content;
      if (!(content?.length > 0)) {
        continue;
      }
      context.delta += content;
      if (context.delta.length > 5 || context.full.length > 5) {
        context.full += context.delta;
        onProgress(context.full);
        context.delta = '';
        context.updatesCount++;
      }
    }
  }
}
