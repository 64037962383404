import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { TwoDCommentsApi } from 'unit-2d-comments/api';
import {
  AddSubCommentRequestPayload,
  AddCommentPayload,
  EditCommentTextPayload,
  RemoveSubCommentPayload,
  EditSubCommentTextPayload,
  MoveSpreadSheetCommentPayload,
} from 'unit-2d-comments/interfaces';
import { TwoDCommentsActions } from '../store-slice';
import { twoDCommentsRealtimeSaga } from './2d-comments-realtime-saga';

function *loadComments(): SagaIterator {
  try {
    const projectId = yield selectWrapper(x => x.projects.currentProject?.id);
    const comments = yield call(TwoDCommentsApi.getCommentaries, projectId);
    yield put(TwoDCommentsActions.loadCommentsSucceed(comments));
  } catch (e) {
    console.error('2d comments error: loadComments', e);
  }
}

function *addComment(action: ActionWith<AddCommentPayload>): SagaIterator {
  try {
    const projectId = yield selectWrapper(x => x.projects.currentProject?.id);
    const comment = yield call(TwoDCommentsApi.createComment, projectId, action.payload);
    yield put(TwoDCommentsActions.addCommentIfNotExists(comment));
    yield put(TwoDCommentsActions.addCommentFinished(action.payload.tempararyId));
  } catch (e) {
    console.error('2d comments error: addComment', e, action.payload);
  }
}

function *addSubComment(action: ActionWith<AddSubCommentRequestPayload>): SagaIterator {
  try {
    const projectId = yield selectWrapper(x => x.projects.currentProject?.id);
    if (yield selectWrapper(x => x.twoDComments.comments.find(c => c.id === action.payload.commentId).resolved)) {
      yield put(TwoDCommentsActions.resolveCommentRequest(action.payload.commentId));
    }
    const sub = yield call(TwoDCommentsApi.createMessage, projectId, action.payload);
    yield put(TwoDCommentsActions.addSubCommentOrUpdate(
      {
        commentId: action.payload.commentId,
        subComment: sub,
      },
    ));
  } catch (e) {
    console.error('2d comments error: addSubComment', e, action.payload);
  }
}

function* removeComment(action: ActionWith<number>): SagaIterator {
  try {
    const projectId = yield selectWrapper(x => x.projects.currentProject?.id);
    yield call(TwoDCommentsApi.removeComment, projectId, action.payload);
  } catch (e) {
    console.error('2d comments error: removeComment', e, action.payload);
  }
}

function* removeSubComment(action: ActionWith<RemoveSubCommentPayload>): SagaIterator {
  try {
    const projectId = yield selectWrapper(x => x.projects.currentProject?.id);
    yield call(TwoDCommentsApi.removeMessage, projectId, action.payload);
  } catch (e) {
    console.error('2d comments error: removeSubComment', e, action.payload);
  }
}

function* editCommentText(action: ActionWith<EditCommentTextPayload>): SagaIterator {
  try {
    const projectId = yield selectWrapper(x => x.projects.currentProject?.id);
    const editedData = yield call(TwoDCommentsApi.updateComment, projectId, action.payload);
    yield put(TwoDCommentsActions.commentUpdated(editedData));
  } catch (e) {
    console.error('2d comments error: editCommentText', e, action.payload);
  }
}

function* editSubCommentText(action: ActionWith<EditSubCommentTextPayload>): SagaIterator {
  try {
    const projectId = yield selectWrapper(x => x.projects.currentProject?.id);
    const editedData = yield call(TwoDCommentsApi.editMessage, projectId, action.payload);
    yield put(TwoDCommentsActions.subCommentUpdated({ subComment: editedData, commentId: action.payload.commentId }));
  } catch (e) {
    console.error('2d comments error: editSubCommentText', e, action.payload);
  }
}

function* toggleResolveComment(action: ActionWith<number>): SagaIterator {
  try {
    const { resolved, projectId } = yield selectWrapper((s) => {
      return {
        resolved: s.twoDComments.comments.find(x => x.id === action.payload).resolved,
        projectId: s.projects.currentProject?.id,
      };
    });
    const addedSubComment = yield call(
      resolved ? TwoDCommentsApi.openComment : TwoDCommentsApi.resolveComment,
      projectId,
      action.payload,
    );
    yield put(TwoDCommentsActions.resolveCommentSuccess(action.payload));
    yield put(TwoDCommentsActions.changeResolveCommentStatus({ commentId: action.payload, status: !resolved }));
    yield put(TwoDCommentsActions.addSubCommentOrUpdate({ commentId: action.payload, subComment: addedSubComment }));
  } catch (e) {
    yield put(TwoDCommentsActions.resolveCommentRequestError(action.payload));
    console.error('2d comments error: toggleResolveComment', e, action.payload);
  }
}

function* moveComments(action: ActionWith<MoveSpreadSheetCommentPayload>): SagaIterator {
  try {
    const { projectId, commentId, target } = action.payload;
    yield call(TwoDCommentsApi.moveSpreadSheetComments, projectId, commentId, target);
  } catch (e) {
    console.error('2d comments error: move comments', e, action.payload);
  }
}

export function *twoDCommentsSagas(): SagaIterator {
  yield takeLatest(TwoDCommentsActions.addCommentRequest.type, addComment);
  yield takeLatest(TwoDCommentsActions.removeComment.type, removeComment);
  yield takeLatest(TwoDCommentsActions.addSubCommentRequest.type, addSubComment);
  yield takeLatest(TwoDCommentsActions.removeSubCommentRequest.type, removeSubComment);
  yield takeLatest(TwoDCommentsActions.editCommentRequest.type, editCommentText);
  yield takeLatest(TwoDCommentsActions.editSubCommentRequest.type, editSubCommentText);
  yield takeLatest(TwoDCommentsActions.loadCommentsRequest.type, loadComments);

  yield takeEvery(TwoDCommentsActions.resolveCommentRequest.type, toggleResolveComment);
  yield takeEvery(TwoDCommentsActions.moveComments.type, moveComments);

  yield fork(twoDCommentsRealtimeSaga);
}
