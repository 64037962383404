import * as Ag from 'ag-grid-community';
import uuid from 'uuid';

import {
  TreeTableColumn,
  TreeTableColumnsData,
} from './interfaces';


export interface TreeTableColumnWithId<T> extends TreeTableColumn<T> {
  id?: string;
}

interface TreeTableColumnUpdateModel<T> {
  id: string;
  properties: Record<string, T>;
}

type GetColumnDefsType<T> = (columnsData: TreeTableColumnsData<T>) =>  Array<Ag.ColDef | Ag.ColGroupDef>;

export class TreeTableColumnController<T> {
  private gridApi: Ag.GridApi;
  private columnApi: Ag.ColumnApi;
  private getColumnDefs: GetColumnDefsType<T>;
  private getColumnProperties: (column: Ag.Column) => Record<string, T>;


  constructor(
    gridApi: Ag.GridApi,
    columnApi: Ag.ColumnApi,
    getColumnDefs: GetColumnDefsType<T>,
    getColumnProperties: (column: Ag.Column) => Record<string, T>,
  ) {
    this.gridApi = gridApi;
    this.columnApi = columnApi;
    this.getColumnDefs = getColumnDefs;
    this.getColumnProperties = getColumnProperties;
  }

  public getColumnModel(): TreeTableColumnsData<T> {
    // const columns = this.columnApi.getAllGridColumns()
    //  .filter(x => x.getColId() !== Ag.Constants.GROUP_AUTO_COLUMN_ID);
    // const groupMaps: Record<string, Set<string>> = {};
    // const columnsMap: Record<string, TreeTableColumn<T>> = {};
    // columns.forEach(column => {
    //   columnsMap[column.getColId()] = {
    //     properties: this.getColumnProperties ? this.getColumnProperties(column) : {},
    //   };

    //   this.appendParentGroupsToMap(column, groupMaps);
    // });
    // Object.entries(groupMaps).forEach(([groupId, children]) => {
    //   columnsMap[groupId] = {
    //     childColumnKeys: Array.from(children),
    //     properties: {},
    //   };
    // });

    // return {
    //   firstLevelColumns: columns.map(x => x.getColId()),
    //   columns: columnsMap,
    // };

    const columns = this.columnApi.getColumnState().filter(x => x.colId !== Ag.Constants.GROUP_AUTO_COLUMN_ID);
    return {
      firstLevelColumns: columns.map(x => x.colId),
      columns: columns.reduce(
        (map: Record<string, TreeTableColumn<T>>, column) => {
          const col = this.columnApi.getColumn(column.colId);
          map[column.colId] = {
            properties: this.getColumnProperties ? this.getColumnProperties(col) : {},
          };
          return map;
        },
        {},
      ),
    };
  }

  public addColumns(columns: Array<TreeTableColumnWithId<T>>, toIndex: number = 0): void {
    const model = this.getColumnModel();
    const newRowsIds = [];

    columns.forEach(column => {
      const id = column.id ? column.id : uuid.v4();
      newRowsIds.push(id);
      model.columns[id] = {
        properties: column.properties,
      };
    });

    model.firstLevelColumns = newRowsIds.concat(model.firstLevelColumns);
    this.gridApi.setColumnDefs(this.getColumnDefs(model));
    this.columnApi.moveColumns(newRowsIds, toIndex);
  }

  public removeColumns(columnIds: string[]): void {
    const model = this.getColumnModel();
    columnIds.forEach(colId => {
      const column = model.columns[colId];
      if (column.parentId) {
        const parent = model.columns[column.parentId];
        parent.childColumnKeys = parent.childColumnKeys.filter(x => x !== colId);
      } else {
        model.firstLevelColumns = model.firstLevelColumns.filter(x => x !== colId);
      }

      delete model.columns[colId];
    });

    this.gridApi.setColumnDefs(this.getColumnDefs(model));
  }

  public updateColumns(columns: Array<TreeTableColumnUpdateModel<T>>): void {
    const model = this.getColumnModel();
    columns.forEach(column => {
      const properties = model.columns[column.id].properties;
      for (const propertyKey in column.properties) {
        if (properties[propertyKey]) {
          properties[propertyKey] = column.properties[propertyKey];
        }
      }
    });

    this.gridApi.setColumnDefs(this.getColumnDefs(model));
  }

  // private appendParentGroupsToMap(
  //   columnOrGroup: Ag.Column | Ag.ColumnGroup, groupsMap: Record<string, Set<string>>,
  // ): void {
  //   const parent = columnOrGroup.getParent();
  //   if (!parent || this.isDefaultGroupId(parent.getGroupId())) {
  //     return;
  //   }

  //   const groupId = parent.getGroupId();
  //   groupsMap[groupId] = groupsMap[groupId] || new Set<string>();
  //   groupsMap[groupId].add(this.getColumnOrGroupId(columnOrGroup));
  //   this.appendParentGroupsToMap(parent, groupsMap);
  // }

  // private getColumnOrGroupId(columnOrGroup: Ag.Column | Ag.ColumnGroup): string {
  //   if ((columnOrGroup as Ag.ColumnGroup).getGroupId) {
  //     return (columnOrGroup as Ag.ColumnGroup).getGroupId();
  //   } else {
  //     return (columnOrGroup as Ag.Column).getColId();
  //   }
  // }

  // private isDefaultGroupId(groupId: string): boolean {
  //   return !isNaN(+groupId);
  // }
}
