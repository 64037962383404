import * as React from 'react';

import './properties-popup-container.scss';

import { MovableContainer, MovablePanelProps } from 'common/components/movable-panel';
import { KreoIconNwResize, KreoScrollbars } from 'common/UIKit';
import { RevitPropertiesPopupBaseHeaderNew } from '../revit-properties-popup-base-header-new';


interface Props extends MovablePanelProps {
  name: string;
  count: number;
  onClose: () => void;
}

class PropertiesPopupComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { name, count, children } = this.props;
    return (
      <div
        className='properties-popup-container'
        ref={this.props.savePopupContainerRef}
      >
        <RevitPropertiesPopupBaseHeaderNew
          title={name}
          count={count}
          onMouseDown={this.props.headerOnMouseDown}
          onClose={this.props.onClose}
        />
        <div className='properties-popup-container__content'>
          <KreoScrollbars showShadowTop={true}>
            {children}
          </KreoScrollbars>
        </div>
        <div className='properties-popup-container__footer'>
          <div
            className='properties-popup-container__footer-icon'
            onMouseDown={this.props.resizeIconOnMouseDown}
          >
            <KreoIconNwResize />
          </div>
        </div>
      </div>
    );
  }
}

export const PropertiesPopupContainer = MovableContainer(PropertiesPopupComponent);
