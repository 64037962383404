import { CurrentCountry } from 'common/environment/current-country-service';
import { ActionWith } from 'common/interfaces/action-with';
import { CurrentCountryActionTypes } from '../types';


function getCurrentCountrySucceed(payload: CurrentCountry): ActionWith<CurrentCountry> {
  return {
    type: CurrentCountryActionTypes.GET_CURRENT_COUNTRY_SUCCEED,
    payload,
  };
}

export const CurrentCountryActions = {
  getCurrentCountrySucceed,
};
