import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import {
  change,
  FieldArray,
  formValueSelector,
  GenericFieldArray,
  InjectedFormProps,
  reduxForm,
  WrappedFieldArrayProps,
} from 'redux-form';

import './manage-roles-dialog.scss';

import { Subject } from 'common/ability/subject';
import { RoleGroup } from 'common/enums/role-group';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { Role } from 'common/interfaces/account/role';
import { KreoDialogActions, KreoScrollbars } from 'common/UIKit';
import { State as ReduxState } from '../../../../common/interfaces/state';
import { KreoFormDialog } from '../../../../components/dialog/base/kreo-form-dialog';
import { MANAGE_ROLES } from '../../../../constants/forms';
import { SubscriptionActions } from '../../../../units/subscription/actions/creators';
import { StringUtil } from '../../../../utils/string';
import { RoleItem } from './role-item';

interface ReduxProps {
  companyRoles: CompanyRoles;
  groups: FormRoleGroup[];
  companyId: number;
}

interface ReduxActions {
  dialogClose(): void;
  updateFormValue(key: string, value: string): void;
  updateSubscriptionRoles(): void;
}

interface Props extends ReduxProps, ReduxActions, InjectedFormProps<{}, {}> {}

interface State {
  editableRole: string;
}

interface InnerComponentProps {
  editableRole: string;
  group: FormRoleGroup;
}

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<
  WrappedFieldArrayProps<Role>,
  InnerComponentProps
>;

const selector = formValueSelector(MANAGE_ROLES);
export const ManageRolesDialogName = 'manageRoles';

export interface FormRoleGroup {
  name: string;
  code: string;
  roles: Role[];
}

class ManageRolesDialogComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editableRole: null,
    };
  }

  public componentDidMount(): void {
    this.initialize();
  }

  public componentDidUpdate(prevProps: Props): void {
    if (!isEqual(this.props.companyRoles, prevProps.companyRoles) || this.props.companyId !== prevProps.companyId) {
      this.initialize();
    }
  }

  public render(): React.ReactNode {
    const { groups } = this.props;
    return (
      <KreoFormDialog
        name={ManageRolesDialogName}
        title='Manage Roles'
        submitButtonText='Accept'
        handleSubmit={this.onSubmit()}
        onDialogClose={this.onDialogClose}
        modal={true}
        formName={MANAGE_ROLES}
        bodyClassName='manage-roles-dialog'
      >
        {groups && (
          <KreoScrollbars>
          <div className='manage-roles-dialog__roles'>
            {groups.map((g, i) => {
              return (
                <div key={i} className='manage-roles-dialog__roles-group'>
                  <FieldArrayCustom
                    name={`groups[${i}]roles`}
                    component={this.renderRoles}
                    editableRole={this.state.editableRole}
                    group={g}
                  />
                </div>
              );
            })}
          </div>
          </KreoScrollbars>
        )}
      </KreoFormDialog>
    );
  }

  @autobind
  private renderRoles({
    fields,
    editableRole,
    group,
  }: WrappedFieldArrayProps<Role> & InnerComponentProps): any {
    return (
      <React.Fragment>
        <div className='manage-roles-dialog__roles-group-name'>
          {group.name}
        </div>
        {fields.map((roleKey, i) => {
          return (
            <RoleItem
              key={i}
              role={group.roles[i]}
              index={i}
              roleKey={roleKey}
              onSave={this.props.updateFormValue}
              onDelete={fields.length > 1 ? fields.remove : null}
              onEditRole={this.onEditRole}
              editableRole={editableRole}
            />
          );
        })}
        {group.name !== Subject.Subcontractors &&
          <RoleItem
            create={true}
            onCreate={fields.push}
            onEditRole={this.onEditRole}
            editableRole={editableRole}
            groupCode={group.code}
          />
        }
      </React.Fragment>
    );
  }

  @autobind
  private onDialogClose(): void {
    this.props.reset();
    this.setState({ editableRole: null });
  }

  @autobind
  private onEditRole(roleKey: string): void {
    if (this.state.editableRole !== roleKey) {
      this.setState({ editableRole: roleKey });
    }
  }

  @autobind
  private onSubmit(): () => void {
    return this.props.handleSubmit(() => {
      this.props.updateSubscriptionRoles();
      this.onDialogClose();
      this.props.dialogClose();
    });
  }

  private initialize(): void {
    const { companyRoles } = this.props;

    const groups = [];
    if (companyRoles) {
      Object.keys(RoleGroup).map((key) => {
        groups.push({
          name: companyRoles.roleGroups[StringUtil.lowerize(key)],
          code: key,
          roles: companyRoles.roles.filter((x) => x.group === key),
        });
      });
    }
    this.props.initialize({ groups });
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const companyId =
    state.account.selectedCompany && state.account.selectedCompany.id;
  return {
    companyId,
    companyRoles: state.account.subscriptionRoles,
    groups: selector(state, 'groups'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    dialogClose: () =>
      dispatch(KreoDialogActions.closeDialog(ManageRolesDialogName)),
    updateFormValue: (key: string, value: string) =>
      dispatch(change(MANAGE_ROLES, key, value)),
    updateSubscriptionRoles: () => dispatch(SubscriptionActions.updateSubscriptionRoles()),
  };
};

const ManageRolesDialogComponentRedux = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageRolesDialogComponent);

export const ManageRolesDialog = reduxForm<{}, {}>({
  form: MANAGE_ROLES,
  destroyOnUnmount: true,
})(ManageRolesDialogComponentRedux);
