import * as React from 'react';


export const KreoIconCount: React.FC = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
    >
      <g id='Icons/24x24/Tools/Counts' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <path
            // eslint-disable-next-line max-len
            d='M7.5,13 C9.43299662,13 11,14.5670034 11,16.5 C11,18.4329966 9.43299662,20 7.5,20 C5.56700338,20 4,18.4329966 4,16.5 C4,14.5670034 5.56700338,13 7.5,13 Z M16.5,13 C18.4329966,13 20,14.5670034 20,16.5 C20,18.4329966 18.4329966,20 16.5,20 C14.5670034,20 13,18.4329966 13,16.5 C13,14.5670034 14.5670034,13 16.5,13 Z M7.5,14 C6.11928813,14 5,15.1192881 5,16.5 C5,17.8807119 6.11928813,19 7.5,19 C8.88071187,19 10,17.8807119 10,16.5 C10,15.1192881 8.88071187,14 7.5,14 Z M16.5,14 C15.1192881,14 14,15.1192881 14,16.5 C14,17.8807119 15.1192881,19 16.5,19 C17.8807119,19 19,17.8807119 19,16.5 C19,15.1192881 17.8807119,14 16.5,14 Z M16.5,4 C18.4329966,4 20,5.56700338 20,7.5 C20,9.43299662 18.4329966,11 16.5,11 C14.5670034,11 13,9.43299662 13,7.5 C13,5.56700338 14.5670034,4 16.5,4 Z M16.5,5 C15.1192881,5 14,6.11928813 14,7.5 C14,8.88071187 15.1192881,10 16.5,10 C17.8807119,10 19,8.88071187 19,7.5 C19,6.11928813 17.8807119,5 16.5,5 Z'
            id='color2'
            fill='currentColor'
            fillRule='nonzero'
            className='icon__color-light'
          />
          <circle
            id='color1'
            fill='currentColor'
            fillRule='nonzero'
            cx='7.5'
            cy='7.5'
            r='3.5'
          />
      </g>
    </svg>
  );
};
