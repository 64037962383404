import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';


export const InfoIconDrawingsExport: React.ComponentType = () => {
  const isExportLoading = useSelector<State, boolean>(s => s.drawings.exportSettings.loadingExport);

  return isExportLoading ? <Icons.Loader/> : <Icons.ExportSmall/>;
};
