import styled from 'styled-components';

const CardList = styled.div`
  max-height: 90px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-right: 3px;

  > div {
    max-width: 505px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
    border-width: 2px;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: ${p => p.theme.color.gray} !important;
  }
  ::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 5px;
    background: ${p => p.theme.color.background};
  }
`;

export const Styled = {
  CardList,
};
