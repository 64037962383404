import * as React from 'react';
import { connect } from 'react-redux';

import './help-menu.scss';

import { State } from 'common/interfaces/state';
import { ChatIcon } from '../../../units/analytics/components/chat';
import { ElementTooltip, TooltipPosition } from '../element-tooltip';

interface OwnProps {
  isCollapsedMenu?: boolean;
}

interface ReduxProps {
  isChatCloudDismissed: boolean;
}

interface Props extends OwnProps, ReduxProps {}

class HelpMenuComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <div className='help-menu'>
        <ElementTooltip
          className='help-menu__item'
          tooltipPosition={TooltipPosition.RightCenter}
          marginLeft={-5}
          tooltipClassName='help-menu__item-tooltip'
          size='medium'
          disabled={!this.props.isChatCloudDismissed}
          targetElement={<ChatIcon isCollapsedMenu={this.props.isCollapsedMenu} />}
        >
          Help
        </ElementTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  return {
    isChatCloudDismissed: state.persistedStorage.isChatCloudDismissed,
  };
};

export const HelpMenu = connect(mapStateToProps)(HelpMenuComponent);
