import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsMeasurePolygon, DrawingsPolygonGeometry } from 'common/components/drawings/interfaces';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { HoverSource } from '../../enums';
import { DrawingsGeometryEntityText } from '../drawings-geometry-entity-text';
import { DrawingsGeometryEntityStroked, DrawingsGeometryEntityStrokedConfig } from './drawings-geometry-entity-stroked';

export interface EntityClosedConfig extends DrawingsGeometryEntityStrokedConfig {
  color: DrawingsPaperColorInfo;
  textLayer: paper.Layer;
  geometry: DrawingsPolygonGeometry;
}

export abstract class GeometryEntityClosed<
  T extends EntityClosedConfig = EntityClosedConfig,
  K extends DrawingsMeasurePolygon = DrawingsMeasurePolygon,
> extends DrawingsGeometryEntityStroked<T> {
  protected _text: DrawingsGeometryEntityText;
  protected _hoverSource: HoverSource;

  constructor(config: T) {
    super(config);
    this._path = new paper.Path();
    this._path.strokeColor = config.color.stroke;
    this._path.fillColor = config.color.fill;
    this.render();
    this.addEvents();
    this._config.textRenderParamsObserver.subscribe(this.updateLabel);
  }

  public override destroy(): void {
    super.destroy();
    this._config.textRenderParamsObserver.unsubscribe(this.updateLabel);
    if (this._text) {
      this._text.destroy();
    }
  }

  public getMeasures(): K {
    const { scale, metersPerPixel } = this._config.textRenderParamsObserver.getContext();
    return DrawingsCanvasUtils.getPolygonMeasures(
      this._path,
      scale,
      metersPerPixel,
      this._config.geometry,
    ) as K;
  }

  protected override removeEntities(): void {
    super.removeEntities();
  }

  protected abstract render(): void;

  @autobind
  protected updateLabel({ isImperial, rotation, showLabel }: MeasuresViewSettings): K {
    const measures = this.getMeasures();
    if (showLabel) {
      if (this._text) {
        this._text.rotation = -rotation;
        this._text.updateText(this.getText(measures, isImperial));
      } else {
        this.renderLabel(isImperial, rotation, this.getLabelPosition());
      }
    } else if (this._text) {
      this._text.destroy();
      this._text = null;
    }
    if (this._lines) {
      this._lines.forEach(x => x.changeLabel());
    }
    return measures;
  }

  protected getText(measures: K, isImperial: boolean): string {
    const { area, perimeter } = measures;
    const areaText = UnitUtil.measureToString2d(Math.abs(area), UnitTypes.M2, isImperial);
    const perimeterText = UnitUtil.lengthToString(Math.abs(perimeter), UnitTypes.M, isImperial);
    return `${areaText}\n${perimeterText}`;
  }

  protected updateLabelTextAndGetMeasures(): K {
    const measures = this.getMeasures();
    if (this._text) {
      const text = this.getText(measures, this._config.textRenderParamsObserver.getContext().isImperial);
      this._text.updateText(text);
    }
    return measures;
  }

  protected getLabelPosition(): paper.Point {
    return this._path.position;
  }

  protected renderLabel(isImperial: boolean, rotation: number, position: paper.Point): void {
    const { area, perimeter } = this.getMeasures();
    const areaText = UnitUtil.measureToString2d(area, UnitTypes.M2, isImperial);
    const perimeterText = UnitUtil.lengthToString(perimeter, UnitTypes.M, isImperial);
    const text = `${areaText}\n${perimeterText}`;
    this._text = new DrawingsGeometryEntityText(
      {
        geometry: position,
        text,
        layer: this._config.textLayer,
        color: this._config.color.stroke,
        parentPath: this._path,
        withIcon: true,
        eventApplier: this._eventsApplier,
        onMouseEnter: this.onTextMouseEnter,
        onMouseLeave: this.onTextMouseLeave,
        renderParamsContextObserver: this._renderParamsObserverGroup,
      },
    );
    this._text.rotation = -rotation;
  }

  protected addEvents(): void {
    this._path.onMouseEnter = this.onTextMouseEnter;
    this._eventsApplier.setExtraInfo(this.id);
    this._eventsApplier.setHandlers(this._path);
  }

  @autobind
  protected onSegmentMouseEnter(id: string, e: PaperMouseEvent): void {
    this._hoverSource = HoverSource.Stroke;
    this.mouseEnter(id, e);
  }

  @autobind
  protected onSegmentMouseLeave(id: string, e: PaperMouseEvent): void {
    if (this._hoverSource === HoverSource.Stroke) {
      this.mouseLeave(id, e);
    }
  }

  protected override changeColor(value: DrawingsPaperColorInfo, colorCode: string): void {
    super.changeColor(value, colorCode);
    this._path.fillColor = value.fill;
  }

  @autobind
  private onTextMouseEnter(e: PaperMouseEvent): void {
    this._hoverSource = HoverSource.Body;
    this.mouseEnter(this._config.id, e);
  }

  @autobind
  private onTextMouseLeave(e: PaperMouseEvent): void {
    if (this._hoverSource === HoverSource.Body) {
      this.mouseLeave(this._config.id, e);
    }
  }
}
