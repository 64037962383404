import { ElementTooltip, IconButton } from '@kreo/kreo-ui-components';
import React from 'react';
import { ConstantFunctions } from 'common/constants/functions';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';

interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tooltip: string;
  icon: React.ComponentType;
  mood?: string;
}

export const DrawingsFlyingMenuIconButton: React.FC<Props> = ({ tooltip, icon, onClick, mood }) => {
  const onClickHandler = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      ConstantFunctions.stopEvent(e);
      onClick(e);
    }
  }, [onClick]);

  return (
    <ElementTooltip
      position={'top'}
      speed={'xl'}
      text={tooltip}
    >
      <IconButton
        onClick={onClickHandler}
        Icon={icon}
        height={DrawingsCanvasConstants.menuButtonSize}
        width={DrawingsCanvasConstants.menuButtonSize}
        mood={mood}
      />
    </ElementTooltip>
  );
};
