import React, { useMemo } from 'react';

import { RenderIf } from 'common/components/render-if';
import { ElementTooltip, ElementTutorialTooltip } from 'common/components/tooltip';
import { ConstantFunctions } from 'common/constants/functions';
import { arrayUtils } from 'common/utils/array-utils';
import { FormulaInputAutocompleteItemType } from '../enums';
import { FormulaAPI } from '../input';
import { FORMULA_FUNCTIONS, FUNCTION_NAMES, VISIBLE_FUNCTIONS, HIDDEN_MENU_FUNCTIONS } from '../input/constants';
import { Category } from './category';
import { FunctionsDescriptions, MEASURED_FUNCTIONS_DESC } from './descriptions';
import { PropertyButton } from './property-button';
import { Styled } from './styled';


interface Props {
  formulaApi: FormulaAPI;
  properties?: string[];
}


const InsertionMenuComponent: React.FC<Props> = ({ formulaApi, properties }) => {
  const insert =  formulaApi ? formulaApi.insertToFormula : ConstantFunctions.doNothing;
  const sortedProperties = useMemo(
    () => properties?.slice().sort((a, b) => arrayUtils.localCompareWithNumber(a, b)),
    [properties],
  );

  return (
    <Styled.Container>
      <Category title='Operators'>
        <PropertyButton
          insert={insert}
          name='+'
          textToInsert='+'
          type={FormulaInputAutocompleteItemType.Operator}
        />
        <PropertyButton
          insert={insert}
          name='-'
          textToInsert='-'
          type={FormulaInputAutocompleteItemType.Operator}
        />
        <PropertyButton
          insert={insert}
          name='*'
          textToInsert='*'
          type={FormulaInputAutocompleteItemType.Operator}
        />
        <PropertyButton
          insert={insert}
          name='/'
          textToInsert='/'
          type={FormulaInputAutocompleteItemType.Operator}
        />
      </Category>
      <Category title='Measurements'>
        {
          VISIBLE_FUNCTIONS.map(func => {
            if (HIDDEN_MENU_FUNCTIONS.includes(func)) {
              return null;
            }
            return MEASURED_FUNCTIONS_DESC[func] ? (
              <ElementTutorialTooltip
                description={FUNCTION_NAMES[func]}
                key={func}
                tutorialLink={MEASURED_FUNCTIONS_DESC[func].link}
                animation={MEASURED_FUNCTIONS_DESC[func].animation}
                position='top'
              >
                <PropertyButton
                  insert={insert}
                  name={FUNCTION_NAMES[func]}
                  textToInsert={func}
                  type={FormulaInputAutocompleteItemType.MeasuredFunction}
                />
              </ElementTutorialTooltip>
            ) : (
              <PropertyButton
                insert={insert}
                key={func}
                name={FUNCTION_NAMES[func]}
                textToInsert={func}
                type={FormulaInputAutocompleteItemType.MeasuredFunction}
              />
            );
          })
        }
      </Category>
      <RenderIf condition={!!properties?.length}>
        <Category title="Properties">
          {sortedProperties.map((property) => (
            <PropertyButton
              insert={insert}
              key={property}
              name={property}
              textToInsert={property}
              type={FormulaInputAutocompleteItemType.Property}
            />
          ))}
        </Category>
      </RenderIf>
      <Category title="Functions">
        {FORMULA_FUNCTIONS.map((func) => (
          <ElementTooltip
            key={func}
            position='top'
            text={FunctionsDescriptions[func].text}
            size='small'
            description={FunctionsDescriptions[func].description}
          >
            <PropertyButton
              insert={insert}
              key={func}
              name={func}
              textToInsert={func}
              type={FormulaInputAutocompleteItemType.Function}
            />
          </ElementTooltip>
        ))}
      </Category>
    </Styled.Container>
  );
};

export const InsertionMenu = React.memo(InsertionMenuComponent);
