import { Icons, MovableContextMenu } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ContextMenuItem } from 'common/components/context-menu';
import { RenderIf } from 'common/components/render-if';
import { FinishDrawApi, FinishDrawConfig } from '../drawings-geometry';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import { Styled } from './styled';

interface Props {
  position: ShortPointDescription;
  api: FinishDrawApi;
  config: FinishDrawConfig;
  onClose: () => void;
}

export const DrawingsCompleteContextMenu: React.FC<Props> = (props) => {
  const {
    onClose,
    api,
    config,
    position: [x, y],
  } = props;
  return (
    <MovableContextMenu
      x={x}
      y={y}
      onClose={onClose}
    >
      <RenderIf condition={config.canFinishWithAI}>
        <Styled.AISuggestMenuItem>
          <ContextMenuItem onClick={api.finishWithAi} icon={Icons.AiSuggest}>
            Create with AI Suggest
          </ContextMenuItem>
        </Styled.AISuggestMenuItem>
      </RenderIf>
      <RenderIf condition={config.canFinish}>
        <ContextMenuItem onClick={api.finish} icon={Icons.Finish}>
          Create
        </ContextMenuItem>
      </RenderIf>
      <ContextMenuItem onClick={api.clear} icon={Icons.UndoClassic}>
        Reset
      </ContextMenuItem>
    </MovableContextMenu>
  );
};
