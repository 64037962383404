import { H5, TinyText } from '@kreo/kreo-ui-components';
import React from 'react';
import { Styled } from '../styled';
import { HotKey } from './hotkey';

interface BigTooltipProps {
  picture: string;
  text: string;
  hotKey: string;
  description: string;
}

const BigTooltipComponent: React.FC<BigTooltipProps> = ({ picture, text, hotKey, description }) => (
  <>
    {picture && <img src={picture} alt="tooltip" />}
    <Styled.TextWithHotKey picture={picture}>
      <H5 textAlign="center">{text}</H5>
      {hotKey && <HotKey hotKey={hotKey} />}
    </Styled.TextWithHotKey>
    {description && (
      <Styled.DescriptionContainer>
        <TinyText color="gray">{description}</TinyText>
      </Styled.DescriptionContainer>
    )}
  </>
);


export const BigTooltip = React.memo(BigTooltipComponent);
