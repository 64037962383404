import autobind from 'autobind-decorator';
import * as React from 'react';

import { CommentModel, CommentPointType } from '../../interfaces';
import { Point } from './point';


interface Props {
  comments: CommentModel[];
  timemarker: any;
  showNewMessage: any;
  markerMode: boolean;
  commentsLevel: number;
  checkVisible: (info: number[]) => boolean;
  getPosition: (info: number[]) => number[];
  selectComment: (index: number) => void;
  onCommentClick: (x: CommentModel) => void;
  onToState: (state: any) => void;
}

export class Markers extends React.Component<Props> {
  public render(): React.ReactNode {
    const tempMarker = this.props.timemarker;
    let tempPoint = null;
    if (this.props.showNewMessage && tempMarker && this.props.checkVisible(tempMarker.point)) {
      const pos = this
        .props
        .getPosition(tempMarker.point);
      if (tempMarker.type === 'point') {
        tempPoint = <Point disabled={true} key={-1} position={pos} number={undefined} selected={false} />;
      }
    }

    if (this.props.markerMode) {
      return [tempPoint];
    }

    return [
      tempPoint,
      ...this
        .props
        .comments
        .map((comment, index) => {
          if (comment.pointType === CommentPointType.OnElement) {
            if (!comment.pointPosition) {
              return undefined;
            }
            const p = JSON.parse(comment.pointPosition);
            if (!this.props.checkVisible(p)) {
              return undefined;
            }
            const pos = this
              .props
              .getPosition(p);
            return (
              <Point
                selected={comment.selected}
                key={index}
                index={index}
                position={pos}
                onClick={this.onClick}
                number={comment.pointSerialNumber}
              />);
          }
        }),
    ];
  }

  @autobind
  private onClick(index: number): void {
    const comment = this.props.comments[index];
    this.props.selectComment(comment.pointSerialNumber);
    if (this.props.commentsLevel === 1) {
      this.props.onCommentClick(comment);
    }
    this.props.onToState(JSON.parse(comment.engineState));
  }
}
