import styled from 'styled-components';

import { CustomDialogStyled } from 'common/components/custom-dialog';
import { DialogTextStyled } from '../../dialog-text';

const Container = styled.div`
  .cancel-subscription-trial-dialog {
    max-height: calc(100vh - 40px) !important;
    max-width: 380px;
    width: auto;

    > h6 {
      text-align: left;
      margin-bottom: 10px;
    }

    ${CustomDialogStyled.Body} {
      margin-bottom: 0;
    }

    ${CustomDialogStyled.Buttons} {
      height: auto;
      margin-top: 15px;

      > button {
        width: auto;
        background: transparent;
        box-shadow: none;
        height: fit-content;

        :nth-child(2) {
          display: none;
        }

        > p {
          color: ${p => p.theme.color.red};
        }

        .waves {
          display: none;
        }
      }
    }

    ${DialogTextStyled.Container} {
      margin-bottom: 10px;

      > p {
        text-align: left;
      }
    }
  }

  .cancel-subscription-active-dialog {
    max-height: calc(100vh - 40px) !important;
    max-width: 380px;
    width: auto;

    > h6 {
      text-align: left;
      margin-bottom: 10px;
    }

    ${CustomDialogStyled.Body} {
      margin-bottom: 0;

      > p {
        margin-bottom: 10px;

        > b {
          color: ${p => p.theme.color.mainFont};
        }
      }
    }

    ${CustomDialogStyled.Buttons} {
      button:first-child {
        > p {
          color: ${p => p.theme.color.gray};
        }

        :hover {
          > p {
            color: ${p => p.theme.color.turquoise};
          }
        }
      }
    }
  }

  .cancel-subscription-consequences-dialog {
    max-height: calc(100vh - 40px) !important;
    max-width: 490px;
    width: auto;
    padding: 0;
    > h6 {
      display: none;
    }

    ${CustomDialogStyled.Buttons} {
      height: auto;
      margin-top: 0;
      margin-bottom: 5px;
      flex-direction: column-reverse;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;

      > button {
        margin: 0;
        width: 100%;
        max-width: 450px;
        border-radius: 30px;
      }

      button:last-child {
        background: ${p => p.theme.color.turquoise};

        p {
          color: ${p => p.theme.color.white};
        }
      }

      button:first-child {
        background: transparent;
        box-shadow: none;
        p {
          color: ${p => p.theme.color.red};
          font-size: 14px;
        }

        .waves {
          display: none;
        }
      }
    }

    ${CustomDialogStyled.Body} {
      margin-bottom: 0;
    }
  }

  .extended-trial-dialog {
    max-height: calc(100vh - 40px) !important;

    ${CustomDialogStyled.Buttons} {
      button:last-child {
        display: none;
      }
    }
  }
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${p => p.theme.color.background};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 5px;

  > a {
    text-decoration: none;
  }
`;

const OptionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  > img {
    width: 20px;
    height: 20px;
  }
`;


export const Styled = {
  Container,
  OptionContainer,
  OptionHeader,
};
