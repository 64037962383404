import { ElementTooltip } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Text } from '../text';
import { Styled } from './styled';

const PROJECT_NAME_MAX_GUARANTEED_LENGTH = 30;

interface Props {
  projectName: string;
  isTranslucent: boolean;
}

export const ProjectNameContainer: React.FC<Props> = React.memo<Props>(({ projectName, isTranslucent }: Props) => {
  return (
    <Styled.ProjectName isTranslucent={isTranslucent}>
      <ElementTooltip
        text={projectName}
        position={'bottom'}
        speed={'s'}
        wordBreakAll={true}
        disabled={projectName.length < PROJECT_NAME_MAX_GUARANTEED_LENGTH}
        isTranslucent={isTranslucent}
      >
        <Text
          withEllipsis={true}
          textAlign={'center'}
          fontSize={14}
        >
          {projectName}
        </Text>
      </ElementTooltip>
    </Styled.ProjectName>
  );
});
