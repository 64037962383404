import { BaseFormat, NumericFormat, PropertyFormatEnum } from '../interfaces';

function isNumeric(format: BaseFormat): format is NumericFormat {
  return format.type === PropertyFormatEnum.Number;
}

function isText(format: BaseFormat): format is BaseFormat {
  return format.type === PropertyFormatEnum.Text;
}

export const FormatTypeGuards = {
  isNumeric,
  isText,
};
