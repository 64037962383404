import { Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { DropDownTextItem } from './dropdown-text-item';
import { Styled } from './styled';

interface Props {
  elements: string[];
  onClick: (id: string) => void;
  focusElement: number;
  activeElement: string;
  createNewElement?: string;
  showCreateNew?: boolean;
  getElementLabel?: (value: string) => string;
}

const DropdownListComponent: React.FC<Props> = (props) => {
  const {
    elements,
    onClick,
    activeElement,
    focusElement,
    showCreateNew,
    getElementLabel,
  } = props;

  return (
    <>
      {elements.length ? (
        elements.map((element, index) => {
          const isActive = activeElement === element;
          const isFocus = index === focusElement;
          const showBadge = showCreateNew && elements.length - 1 === index;

          return (
            <>
              <DropDownTextItem
                key={index}
                id={element}
                onClick={onClick}
                isActive={isActive}
                content={getElementLabel ? getElementLabel(element) : element}
                isFocus={isFocus}
                showBadge={showBadge}
              />
            </>
          );
        })
      ) : (
        <Styled.NoResults>
          <Text>Empty list, to create an option, type the text and press "Enter"</Text>
        </Styled.NoResults>
      )}
    </>
  );
};

export const DropdownList = React.memo(DropdownListComponent);
