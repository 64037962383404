import autobind from 'autobind-decorator';
import React from 'react';

import './subcontractor-bid.scss';

import { KreoButton } from 'common/UIKit';
import { UserBidPricingStatus } from '../../../enums/user-bid-pricing-status';
import { BidPricingUserCost } from '../../../interfaces/bid-pricing/bid-pricing-user-cost';
import { formatCostValue } from './utils';

interface Props {
  contractorCost: BidPricingUserCost;
  onClick(element: BidPricingUserCost): void;
}

export class SubcontractorBid extends React.Component<Props> {

  public render(): React.ReactNode {
    const { contractorCost } = this.props;
    return (
      <div className='bid-pricing-invitations__row bid' onClick={this.onClick}>
        <div className='bid-pricing-invitations__inner-table bid'>
          <div className='bid-pricing-invitations__data cell'>
            {formatCostValue(contractorCost.laborCost)}
          </div>
          <div className='bid-pricing-invitations__data cell'>
            {formatCostValue(contractorCost.plantCost)}
          </div>
          <div className='bid-pricing-invitations__data cell'>
            {formatCostValue(contractorCost.materialCost)}
          </div>
          <div className='bid-pricing-invitations__data cell'>
            {formatCostValue(contractorCost.indirectCost)}
          </div>
          <div className='bid-pricing-invitations__data cell'>
            {formatCostValue(contractorCost.totalCost)}
          </div>
          <div className='bid-pricing-invitations__data cell'>
            {formatCostValue(contractorCost.duration)}
          </div>
          <div className='bid-pricing-invitations__action cell'>
            <div className='action-buttons'>{this.getButton()}</div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private onClick(): void {
    this.props.onClick(this.props.contractorCost);
  }

  private getButton(): React.ReactNode {
    switch (this.props.contractorCost.status) {
      case UserBidPricingStatus.AcceptedInvite:
        return (
          <KreoButton
            caption='Estimate'
            size='medium'
          />
        );
      case UserBidPricingStatus.Winner:
      case UserBidPricingStatus.Estimated:
        return (
          <KreoButton
            mode='dark'
            size='medium'
            caption='Submitted'
          />
        );
      default:
        return;
    }
  }
}
