import { ElementTooltip, TinyText } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { MoveToCellOptionType } from 'common/components/drawings/drawings-canvas-menus';
import { ConstantFunctions } from 'common/constants/functions';
import { MeasureUtil } from 'common/utils/measure-util';
import { StringUtils } from 'common/utils/string-utils';
import { Styled } from './styled';

interface Props {
  type: MoveToCellOptionType;
  value: string;
  isActive: boolean;
  onSelect: (type: MoveToCellOptionType) => void;
}

export class MeasureTypePupon extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { type, isActive, value } = this.props;
    const Icon = MeasureUtil.measureIcon[type];
    const tooltipText = MeasureUtil.measureVisibleName[type] || type;
    return (
      <ElementTooltip
        text={StringUtils.firstUpperLetter(tooltipText)}
        position='top'
        key={type}
      >
        <Styled.Measure isActive={isActive} onClick={this.onClick} onMouseDown={ConstantFunctions.stopEvent}>
          <Icon/>
          <TinyText color={isActive ? 'white' : 'gray'}>{value}</TinyText>
        </Styled.Measure>
      </ElementTooltip>
    );
  }

  @autobind
  private onClick(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onSelect(this.props.type);
  }
}
