import styled from 'styled-components';

const OperationButton = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: flex;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.color.background};
  border-right-width: 0;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right-width: 1px;
  }

  &:hover svg {
    fill: ${p => p.theme.color.turquoise};
  }

  &:active svg {
    transform: scale(0.8);
  }

  svg{
    margin: auto;
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.gray};
    transition: all ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  }
`;


export const Styled = {
  OperationButton,
};
