import { MovableContextMenu } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { SortData, SortField, SortOptions, SortOrder, SortValue } from '../../interfaces/drawing-measurement-sort';
import { Element } from './element';
import { SortElement } from './interfaces';
import { Styled } from './styled';

interface SortSet {
  name?: string;
  type: SortOptions;
  elements: SortElement[];
}

const sortSet: SortSet[] = [
  {
    name: 'SORT BY :',
    type: SortOptions.Field,
    elements: [
      { name: 'Creation date', type: SortField.Date },
      { name: 'Name', type: SortField.Name },
    ],
  },
  {
    type: SortOptions.Order,
    elements: [
      { name: 'Ascending', type: SortOrder.Ascending },
      { name: 'Descending', type: SortOrder.Descending },
    ],
  },
];

interface Props {
  x: number;
  y: number;
  onClose: () => void;
  selected: SortData;
  handleSortChange: (sortOption: SortOptions, value: SortValue) => void;
}

export class DrawingsAnnotationLegendSortMenuComponent extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    const { x, y, onClose } = this.props;
    return (
      <MovableContextMenu
        x={x}
        y={y}
        onClose={onClose}
      >
        <Styled.Container>
          {sortSet.map(set => (
            <Styled.Set key={set.type} border={!!set.name}>
              <Styled.SetTitle color={'turquoiseFont'}>{set.name}</Styled.SetTitle>
                {set.elements.map(optionSort => this.renderMenuItems(optionSort, set.type))}
            </Styled.Set>
          ))}
        </Styled.Container>
      </MovableContextMenu>
    );
  }

  @autobind
  private renderMenuItems(sort: SortElement, sortOptionName: SortOptions): React.ReactNode {
    const { selected, handleSortChange } = this.props;
    return (
      <Element
        key={sort.name}
        handleSortChange={handleSortChange}
        sort={sort}
        sortOptionName={sortOptionName}
        selected={selected[sortOptionName] === sort.type}
      />
    );
  }
}

export const DrawingsAnnotationLegendSortMenu = DrawingsAnnotationLegendSortMenuComponent;
