import styled from 'styled-components';

const Icon = styled.div`
  height: 20px;
  margin-right: 10px;
  svg {
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.gray}
  }
`;

export const Styled = {
  Icon,
};
