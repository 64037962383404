import * as t from 'io-ts';
import { CEActivityAssignmentTreeNodeActivityResponseC } from './ce-activity-assignment-tree-node-activity-response';

export const CEActivityAssignmentTreeNodeResponseC = <T extends t.Mixed>(
  tValidator: T,
  tName: string = 'CEActivityAssignmentTreeNodeResponse',
): t.Any => t.strict(
    {
      name: t.string,
      children: t.array(tValidator),
    },
    tName,
  );

export const CEActivityAssignmentElementResponseC = CEActivityAssignmentTreeNodeResponseC(
  CEActivityAssignmentTreeNodeActivityResponseC, 'CEActivityAssignmentElementResponse',
);
export const CEActivityAssignmentFamilyResponseC =
  CEActivityAssignmentTreeNodeResponseC(CEActivityAssignmentElementResponseC, 'CEActivityAssignmentFamilyResponse');
export const CEActivityAssignmentCategoryResponseC =
  CEActivityAssignmentTreeNodeResponseC(CEActivityAssignmentFamilyResponseC, 'CEActivityAssignmentCategoryResponse');

export interface CEActivityAssignmentTreeNodeResponse<T> {
  name: string;
  children: T[];
}

export type CEActivityAssignmentElementResponse = t.TypeOf<typeof CEActivityAssignmentElementResponseC>;
export type CEActivityAssignmentFamilyResponse = t.TypeOf<typeof CEActivityAssignmentFamilyResponseC>;
export type CEActivityAssignmentCategoryResponse = t.TypeOf<typeof CEActivityAssignmentCategoryResponseC>;
