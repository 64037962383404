const prefix = '@database-resource-variant';

export const DatabaseResourceVariantActionTypes = {
  GET_RESOURCE_VARIANT_REQUEST: `${prefix}/GET_RESOURCE_VARIANT_REQUEST`,
  GET_RESOURCE_VARIANT_SUCCEEDED: `${prefix}/GET_RESOURCE_VARIANT_SUCCEEDED`,
  CREATE_RESOURCE_VARIANT_REQUEST: `${prefix}/CREATE_RESOURCE_VARIANT_REQUEST`,
  CREATE_RESOURCE_VARIANT_SUCCEEDED: `${prefix}/CREATE_RESOURCE_VARIANT_SUCCEEDED`,
  UPDATE_RESOURCE_VARIANT_REQUEST: `${prefix}/UPDATE_RESOURCE_VARIANT_REQUEST`,
  UPDATE_RESOURCE_VARIANT_SUCCEEDED: `${prefix}/UPDATE_RESOURCE_VARIANT_SUCCEEDED`,
  DELETE_RESOURCE_VARIANT_REQUEST: `${prefix}/DELETE_RESOURCE_VARIANT_REQUEST`,
  DELETE_RESOURCE_VARIANT_SUCCEEDED: `${prefix}/DELETE_RESOURCE_VARIANT_SUCCEEDED`,
};
