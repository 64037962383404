import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { SpinnerStyled } from 'common/components/spinner';

const BORDER_RADIUS = 25;


const Container = styled.div<{ fullScreen: boolean }>`
  position: relative;
  right: ${p => p.fullScreen ? null : '20px'};
  display: flex;
  width: ${p => p.fullScreen ? '100%' : '1140px'};
  height: ${p => p.fullScreen ? '100%' : '685px'};
  max-height: 100%;
  box-shadow: ${p => p.theme.shadow.xl};
  border-radius: ${p => p.fullScreen ? null : `${BORDER_RADIUS}px`};
  background-color: ${p => p.theme.color.backgroundRush};
  animation: ${Constants.Animations.slowTopAppear()} ${p => p.theme.duration.s} linear;
  *,& {
    outline: none;
  }

  & ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  & ::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }

  ${SpinnerStyled.SpinnerContainer} {
    border-radius: ${BORDER_RADIUS}px;
  }

  .ag-root.ag-layout-normal {
    width: 100%;
    overflow: auto;
  }
`;

const AddFolderButton = styled.div`
  display: flex;
  gap: 15px;
  margin-left: auto;
`;

export const Styled = {
  Container,
  AddFolderButton,
};
