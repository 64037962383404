import { RegexpUtil } from './regexp-util';

const bracketsRegex = /[\(|\)+]/gi;
const REPLACE_INVALID_SYMBOLS_REGEX = /^0*([0-9]+)/g;

export interface PreparedSearchQuery {
  searchRegex: RegExp;
  searchWords: string[];
}

export const StringUtils = {
  splitCamelCase: (camelCasedString: string): string => {
    return camelCasedString.replace(/([a-z])([A-Z])/g, '$1 $2');
  },
  titleCaseToSnakeCase: (titleCasedString: string): string => {
    return titleCasedString.replace(/[' ']/g, '-').toLowerCase();
  },
  createSearchByWordRegex: (query: string): PreparedSearchQuery => {
    const preparedQuery = query.toLowerCase().replace(bracketsRegex, value => `\\${value}`).replace(' ', '|');
    return {
      searchRegex: new RegExp(preparedQuery, 'gi'),
      searchWords: preparedQuery.split('|'),
    };
  },
  compareStringWithSearchQuery: (targetString: string, query: PreparedSearchQuery): boolean => {
    if (!targetString) {
      return false;
    }
    const lowerCasedTargetString = targetString.toLowerCase();
    return query.searchWords.every(x => lowerCasedTargetString.includes(x));
  },
  firstUpperLetter: (text) => `${text.charAt(0).toUpperCase()}${text.slice(1)}`,
  iterateName: (allNames: string[], name: string): string => {
    const escapedName = RegexpUtil.escapeRegExp(name);
    if (!allNames.some(x => x === name)) {
      return name;
    }
    const nextNameCount = allNames.reduce(
      (result, info) => {
        const infoNameMatch = info.match(new RegExp(`^${escapedName}( \\((?<count>\\d+)\\))?$`));
        if (!infoNameMatch) {
          return result;
        }
        const newCount = Number(infoNameMatch.groups.count || 0) + 1;
        return newCount > result ? newCount : result;
      },
      0);
    return nextNameCount ? `${name} (${nextNameCount})` : name;
  },
  removeNonNumericSymbols: (value: string) =>
    value.trim().replace(',', '.').replace(REPLACE_INVALID_SYMBOLS_REGEX, '$1'),
};
