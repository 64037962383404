import { ElementTooltip, IconButton, Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { isEqual, pick } from 'lodash';
import * as React from 'react';

import { DrawingsPdfBrowserFilterMenu } from './drawings-pdf-browser-filter-menu/drawings-pdf-browser-filter-menu';
import { PageFilters, MeasurementsFilterValue } from './pdf-filters';
import { Styled } from './styled';

export interface FilterElement {
  name: string;
  type: string;
}

export interface FilterChangePayload {
  filterType: PageFilters;
  element: string;
}

export interface FilterData {
  [PageFilters.Measurements]: string;
}

export const FilterDataInitialState: FilterData = {
  [PageFilters.Measurements]: MeasurementsFilterValue.All,
};

export interface PageFiltersSet {
  name: string;
  elements: FilterElement[];
}
export interface FilterSet {
  [PageFilters.Measurements]: PageFiltersSet;
}
interface ComponentState {
  filtersMenu: { isShow: boolean, x: number, y: number };
}

interface Props {
  filterData: FilterData;
  changeFilterData: (changeFilterData: FilterChangePayload) => void;
}

class DrawingsPdfBrowserFilterComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filtersMenu: { isShow: false, x: 0, y: 0 },
    };
  }

  public render(): React.ReactNode {
    const { isShow, x, y } = this.state.filtersMenu;
    const isFiltersChanged = this.isFiltersChanged(FilterDataInitialState, this.props.filterData);
    return (
      <>
        <ElementTooltip
          text='Filters'
          position='bottom'
          speed='l'
        >
          <Styled.DotMarkWrapper active={isFiltersChanged}>
            <IconButton
              Icon={Icons.Filters2D}
              onClick={this.filtersButtonClick}
              width={20}
              height={20}
              defaultColor={isFiltersChanged && 'turquoise'}
            />
          </Styled.DotMarkWrapper>
        </ElementTooltip>
        {isShow && <DrawingsPdfBrowserFilterMenu
          x={x}
          y={y}
          onClose={this.filtersClose}
          selected={this.props.filterData[PageFilters.Measurements]}
          handleFilterChange={this.handleFilterChange}
        />}
      </>
    );
  }

  private isFiltersChanged(prevData: FilterData, currentData: FilterData): boolean {
    const propsToCompare = [PageFilters.Measurements];
    return !isEqual(pick(prevData, propsToCompare), pick(currentData, propsToCompare));
  }

  @autobind
  private filtersClose(): void {
    this.setState({ filtersMenu: { isShow: false, x: 0, y: 0 } });
  }

  @autobind
  private filtersButtonClick(e: React.MouseEvent): void {
    const { left, top } = e.currentTarget.getBoundingClientRect();
    this.setState({ filtersMenu:
      { isShow: true, x: left, y: top + 10 } });
  }

  @autobind
  private handleFilterChange(filterType: PageFilters, element: string): void {
    this.props.changeFilterData({ filterType, element });
  }
}

export const DrawingsPdfBrowserFilter = DrawingsPdfBrowserFilterComponent;
