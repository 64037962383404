import { ActionWith } from 'common/interfaces/action-with';
import { Feed } from 'common/interfaces/feed';
import { ActivitiesRequestFilterModel, ActivityModel } from '../../interfaces/data';
import { ActivityForm } from '../../interfaces/rest-data';
import {
  CreateActivityPayload,
  DeleteActivityPayload,
  DuplicateActivityPayload,
  EditActivityPayload,
  LoadActivitiesPayload,
  LoadActivityPayload,
} from '../payloads/database-activity';
import { DatabaseActivityActionTypes } from '../types/database-activity';

function createActivity(
  databaseId: number,
  model: ActivityForm,
  formId: string,
): ActionWith<CreateActivityPayload> {
  return {
    type: DatabaseActivityActionTypes.CREATE_ACTIVITY_REQUEST,
    payload: { databaseId, model, formId },
  };
}

function createActivityResponse(
  model: ActivityModel,
): ActionWith<ActivityModel> {
  return {
    type: DatabaseActivityActionTypes.CREATE_ACTIVITY_SUCCEEDED,
    payload: model,
  };
}

function loadActivities(
  databaseId: number,
  filter: ActivitiesRequestFilterModel,
): ActionWith<LoadActivitiesPayload> {
  return {
    type: DatabaseActivityActionTypes.GET_ACTIVITIES_REQUEST,
    payload: {
      databaseId,
      filter,
    },
  };
}

function loadActivitiesResponse(
  responseModel: Feed<ActivityModel>,
): ActionWith<Feed<ActivityModel>> {
  return {
    type: DatabaseActivityActionTypes.GET_ACTIVITIES_SUCCEEDED,
    payload: responseModel,
  };
}

function loadActivity(
  databaseId: number,
  activityId: number,
): ActionWith<LoadActivityPayload> {
  return {
    type: DatabaseActivityActionTypes.GET_ACTIVITY_REQUEST,
    payload: {
      databaseId,
      activityId,
    },
  };
}

function loadActivityResponse(
  model: ActivityModel,
): ActionWith<ActivityModel> {
  return {
    type: DatabaseActivityActionTypes.GET_ACTIVITY_SUCCEEDED,
    payload: model,
  };
}

function updateActivity(
  databaseId: number,
  activityId: number,
  model: ActivityForm,
  formId: string,
): ActionWith<EditActivityPayload> {
  return {
    type: DatabaseActivityActionTypes.UPDATE_ACTIVITY_REQUEST,
    payload: { databaseId, activityId, model, formId },
  };
}


function updateActivityResponse(
  model: ActivityModel,
): ActionWith<ActivityModel> {
  return {
    type: DatabaseActivityActionTypes.UPDATE_ACTIVITY_SUCCEEDED,
    payload: model,
  };
}

function deleteActivity(
  databaseId: number,
  activityId: number,
): ActionWith<DeleteActivityPayload> {
  return {
    type: DatabaseActivityActionTypes.DELETE_ACTIVITY_REQUEST,
    payload: { databaseId, activityId },
  };
}

function deleteActivityResponse(
  id: number,
): ActionWith<number> {
  return {
    type: DatabaseActivityActionTypes.DELETE_ACTIVITY_SUCCEEDED,
    payload: id,
  };
}

function duplicateActivity(
  databaseId: number,
  activityId: number,
): ActionWith<DuplicateActivityPayload> {
  return {
    type: DatabaseActivityActionTypes.DUPLICATE_ACTIVITY,
    payload: { databaseId, activityId },
  };
}

export const DatabaseActivityActions = {
  createActivity,
  createActivityResponse,
  loadActivities,
  loadActivitiesResponse,
  loadActivity,
  loadActivityResponse,
  updateActivity,
  updateActivityResponse,
  deleteActivity,
  deleteActivityResponse,
  duplicateActivity,
};
