import { DrawingsInstanceMeasure } from 'common/components/drawings';
import { getMeasure } from '../get-measure';
import { getMoveToCellGroupValue } from './move-to-cell-group';

export function getValueToInsertInCell(instancesMeasures: DrawingsInstanceMeasure[], type: string): string {
  if (instancesMeasures.length === 1) {
    return getMeasure(instancesMeasures[0], type);
  } else {
    return getMoveToCellGroupValue(type, instancesMeasures);
  }
}
