import React from 'react';
import { connect } from 'react-redux';

import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { ApplyTemplateDialog } from '../apply-template-dialog';
import { Body } from '../body';
import { Header } from '../header';

interface StateProps {
  loadingStatus: RequestStatus;
}

interface Props extends StateProps {

}

const TwoDProjectReportTemplateComponent: React.FC<Props> = ({
  loadingStatus,
}) => {
  return (<>
    <Header />
    <Body />
    <ApplyTemplateDialog />
    <RenderIf condition={loadingStatus === RequestStatus.Loading}>
      <Spinner show={true} />
    </RenderIf>
  </>);
};

const mapStateToProps = (state: State): StateProps => {
  return {
    loadingStatus: state.twoDReportTemplate.requestStatus,
  };
};

export const TwoDProjectReportTemplate = connect(mapStateToProps)(TwoDProjectReportTemplateComponent);
