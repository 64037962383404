import { IconButton } from '@material-ui/core';
import { default as Clear } from '@material-ui/icons/Clear';
import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  ConnectDropTarget,
  DropTarget,
  DropTargetConnector,
} from 'react-dnd';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './index.scss';

import { TitleEditable } from 'common/components/title-editable';
import { WorkPackageAction } from '../../actions';
import { WorkPackagesData } from '../../interfaces';
import { ItemTarget } from '../targets';

// Begin: DnD region
const collect = (conn: DropTargetConnector): object => {
  return {
    connectDropTarget: conn.dropTarget(),
  };
};
// End: DnD region

interface ReduxActions {
  setActivePackage: (pack: WorkPackagesData.GropedWorkPackage) => void;
  removePackage: (id: number) => void;
  changeName: (id: number, value: string) => void;
  replaceGroup: (idPackage: number, groupId: number) => void;
  replaceActivity: (idPackage: number, activityId: number) => void;
}

interface Props extends ReduxActions {
  packageItem: WorkPackagesData.GropedWorkPackage;
  isActive: boolean;
}

interface DNDTargetProps extends Props {
  connectDropTarget: ConnectDropTarget;
}

class PackageItemComponent extends React.Component<DNDTargetProps> {

  public render(): JSX.Element {
    const { isActive } = this.props;
    const { name, count } = this.props.packageItem;
    const { connectDropTarget } = this.props;

    return connectDropTarget(
      <div
        className={`package-item ${isActive ? 'active' : ''}`}
        onClick={this.onPackClick}
      >
        <div className='package-item__title'>
          <TitleEditable
            label='Package Name'
            text={name}
            onChange={this.onChangeName}
            canEdit={true}
            isDisplayMultiline={true}
            multilineRows={2}
            sizeSmall={true}
            isEditByIconClick={true}
            withoutSaveBtn={true}
            className='package-item__title-edit'
          />
        </div>
        <div className='package-item__info'>{count}</div>
        {
          count === 0 ?
            <div className='package-item__action'>
              <IconButton
                onClick={this.removePackage}
                className='package-item__button'
              >
                <Clear />
              </IconButton>
            </div> : null
        }
      </div>,
    );
  }

  @autobind
  private removePackage(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    this.props.removePackage(this.props.packageItem.id);
  }

  @autobind
  private onPackClick(): void {
    const { isActive } = this.props;

    if (isActive) {
      this.props.setActivePackage(null);
    } else {
      this.props.setActivePackage(this.props.packageItem);
    }
  }

  @autobind
  private onChangeName(value: string): void {
    this.props.changeName(this.props.packageItem.id, value);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setActivePackage: (pack: WorkPackagesData.GropedWorkPackage): void => {
      dispatch(WorkPackageAction.setActive(pack));
    },
    removePackage: (id: number) => {
      dispatch(WorkPackageAction.removeWorkpackage(id));
    },
    changeName: (id: number, value: string): void => {
      dispatch(WorkPackageAction.renamePackage(id, value));
    },
    replaceGroup: (idPackage: number, groupId: number): void => {
      dispatch(WorkPackageAction.replaceGroup(idPackage, groupId));
    },
    replaceActivity: (idPackage: number, activityId: number): void => {
      dispatch(WorkPackageAction.replaceActivity(idPackage, activityId));
    },
  };
};

const dropTarget = DropTarget<Props>('ACTIVITY_GROUP', ItemTarget, collect)(PackageItemComponent);
const connector = connect(null, mapDispatchToProps);

export const PackageItem = connector(dropTarget) as React.ComponentType<any>;
