import { SearchSelection } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CalibrateActions } from 'common/components/drawings/actions/creators/calibrate';
import { DrawingsScaleConstant } from 'common/components/drawings/constants/drawings-scale-constants';
import { State } from 'common/interfaces/state';
import { Styled } from './styled';

const CalibrateScaleInputWrapperComponent: React.FC = () => {
  const isImperial = useSelector<State, boolean>((s) => s.account.settings.isImperial);
  const drawingLineLength = useSelector<State, number>((s) => s.drawings.temporaryCalibration.drawingLength);
  const originalLineLength = useSelector<State, number>((s) => s.drawings.temporaryCalibration.originalLength);
  const pageFormat = useSelector<State, string>((s) => s.drawings.temporaryCalibration.paperSize);

  const scaleValue = useMemo(() => {
    const scale = `${Math.round(drawingLineLength)}:${Math.round(originalLineLength)}`;
    return isImperial ? DrawingsScaleConstant.ScalesDescription[scale] || scale : scale;
  }, [isImperial, originalLineLength, drawingLineLength]);

  const currentScaleList = isImperial ? DrawingsScaleConstant.ImperialScales : DrawingsScaleConstant.MetricScales;

  const dispatch = useDispatch();

  const changeScale = useCallback(
    (value: number) => {
      const [divisor, dividend] = Object.keys(DrawingsScaleConstant.ScalesDescription)[value].split(':').map(Number);
      dispatch(CalibrateActions.changeScale(divisor, dividend));
    },
    [dispatch],
  );
  const changeCurrentPageSize = useCallback((index: number) => {
    dispatch(CalibrateActions.setPageFormat(DrawingsScaleConstant.FormatsList[index]));
  }, []);

  const activeScaleIndices = useMemo(() => {
    const scaleIndex = currentScaleList.indexOf(scaleValue);
    return [scaleIndex];
  }, [scaleValue, currentScaleList]);

  const activePageFormatIndex = useMemo(() => {
    const formatIndex = DrawingsScaleConstant.FormatsList.indexOf(pageFormat);
    return [formatIndex];
  }, [pageFormat]);

  return (
    <Styled.InputsContainer>
      <Styled.DropdownContainer>
        <SearchSelection
          header={'Scale'}
          elements={currentScaleList}
          activeElementIndexes={activeScaleIndices}
          onClick={changeScale}
          maxItemsAmount={2}
          closeAfterClick={true}
        />
      </Styled.DropdownContainer>
      <Styled.DropdownContainer>
        <SearchSelection
          header={'List'}
          elements={DrawingsScaleConstant.FormatsList}
          activeElementIndexes={activePageFormatIndex}
          onClick={changeCurrentPageSize}
          maxItemsAmount={2}
          closeAfterClick={true}
        />
      </Styled.DropdownContainer>
    </Styled.InputsContainer>
  );
};

export const CalibrateScaleInputWrapper = React.memo(CalibrateScaleInputWrapperComponent);
