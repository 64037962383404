import { DeferredUpdater, DeferredUpdaterFactory, OnExecFuncType } from 'common/utils/deferred-updater';
import { UpdateCellForm } from '../..';
import { FormWithProjectAndReportIdsPayload } from '../../actions/payloads';

type UpdateCellsForm = FormWithProjectAndReportIdsPayload<UpdateCellForm[]>;

const aggregateUpdateCells = (form: UpdateCellsForm | null, newForm: UpdateCellsForm): UpdateCellsForm => {
  if (!form) {
    return newForm;
  }

  const appendValueToValueMap = (valueMap: Record<string, Record<string, any>>, cell: UpdateCellForm): void => {
    valueMap[cell.columnId] = valueMap[cell.columnId] || {};
    valueMap[cell.columnId][cell.rowId] = cell.value;
  };

  const colIdToRowIdToValueMap = {};
  form.form.forEach((x) => appendValueToValueMap(colIdToRowIdToValueMap, x));
  newForm.form.forEach((x) => appendValueToValueMap(colIdToRowIdToValueMap, x));

  const resultCells: UpdateCellForm[] = [];
  Object.entries(colIdToRowIdToValueMap)
    .forEach(([columnId, rowIdToValueMap]) => Object.entries(rowIdToValueMap)
      .forEach(([rowId, value]) => resultCells.push({
        columnId,
        rowId,
        value,
      })),
    );

  return {
    form: resultCells,
    sheetId: form.sheetId,
    projectId: form.projectId,
  };
};


interface CallBacks {
  onCellsUpdate: (form: UpdateCellsForm) => void;
}

export class Qto2dReportUpdater {
  private cellUpdateExecuter: DeferredUpdater<UpdateCellsForm>;

  constructor(delay: number, callBacks: CallBacks) {
    const factory = new DeferredUpdaterFactory(delay, delay * 5);
    this.cellUpdateExecuter = factory.create(
      this.getDeferredUpdaterCallBack(callBacks.onCellsUpdate),
      aggregateUpdateCells,
    );
  }

  public executeImmediatelyAll(): void {
    this.cellUpdateExecuter.executeImmediatelyIfDeferred();
  }

  public cancelAll(): void {
    this.cellUpdateExecuter.cancel();
  }

  public onCellUpdate(data: UpdateCellsForm): void {
    const currentForm = this.cellUpdateExecuter.getCurrentForm();
    if (currentForm && data.sheetId !== currentForm.sheetId) {
      this.cellUpdateExecuter.executeImmediatelyIfDeferred();
    }

    this.cellUpdateExecuter.execute(data);
  }

  private getDeferredUpdaterCallBack<T>(func: (form: T) => void,
  ): OnExecFuncType<T> {
    return func;
  }
}
