import { camelCase } from 'lodash';

import { StringDictionary } from 'common/interfaces/dictionary';

export function getGetErrorModelFromValidationResponse(response: StringDictionary<string[]>): object {
  const result = {};
  const propertyNameRegex = /([^\.\[\]]+)/g;

  for (const field in response) {
    const keys = field.match(propertyNameRegex).map(camelCase);
    let linkToLastObject = result;
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      if (!linkToLastObject[key]) {
        linkToLastObject[key] = {};
      }
      linkToLastObject = linkToLastObject[key];
    }

    linkToLastObject[keys.pop()] = response[field];
  }

  return result;
}
