import { Icons } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi, useShowCalibrationDialogIfNeed } from 'common/components/drawings/hooks';
import { useOpenEngineIfNeeded } from 'common/components/drawings/hooks/use-open-engine-if-needed';
import { QueryLineWithOpenSearch } from 'common/components/drawings/layout-components/auto-measure-ai';
import { ExportProgress } from 'common/components/drawings/layout-components/top-control-group/drawings-export';
import { ControlNames } from 'common/constants/control-names';
import { ProjectType } from 'common/constants/project-type';
import { useActionWithArgsDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { TwoDCopilotActions } from 'unit-2d-copilot/store-slice';
import { BaseMenuConfig, BaseMenuSettings, Menu } from 'unit-2d-info-panel/constants/menus';
import { useStartScaling } from 'unit-2d-info-panel/hooks/use-start-scaling';
import { TwoDInfoPanelActions } from 'unit-2d-info-panel/store-slice';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { ScaleMenuItem } from '../scale';

interface DefaultViewSection extends BaseMenuConfig {
  title: string;
  show: boolean;
  ManageIcon: React.ComponentType;
  onSelectionMenuClick: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onIconClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  infoIcon?: React.ComponentType;
  badge?: JSX.Element;
  children?: () => React.ReactNode;
  isNeedUpdate?: boolean;
  disabled?: boolean;
  dataControlName: string;
}

export const useDefaultViewSection = (): DefaultViewSection[] => {
  const dispatch = useDispatch();
  const projectType = useSelector<State, ProjectType>(s => s.projects.currentProject.type);
  const drawingsCount = useSelector<State, number>(s => Object.keys(s.drawings.drawingsInfo).length);
  const selectedPagesCount = useSelector<State, number>(s => s.drawings.selectedPages.length);
  const drawingRendered = useSelector<State, boolean>(s => s.drawings.drawingRenderedStatus);
  const autoMeasureCalculating = useSelector<State, boolean>(s => s.drawings.currentDrawingInfo?.recognitionStatus &&
    s.drawings.currentDrawingInfo.recognitionStatus === ViewModelStatus.Calculating);
  const isExportLoading = useSelector<State, boolean>(s => s.drawings.exportSettings.loadingExport);
  const canUseAutoCount = useAbility(Operation.Manage, Subject.Takeoff2DAutoMeasure);
  const canUseCaddy = useAbility(Operation.Manage, Subject.Takeoff2dCaddie);
  const canProposeExtendSubscription = useAbility(Operation.Read, Subject.ProposeExtendSubscription);
  const canManageCompare = useAbility(Operation.Manage, Subject.Project2DPagesCompare);
  const canEditMeasurement = useAbility(Operation.Update, Subject.Takeoff2DMeasurement);
  const canSeeCompare = drawingsCount > 1 && !!selectedPagesCount;
  const hasDrawings = drawingsCount > 0;
  const canUseExport = useAbility(Operation.Manage, Subject.Takeoff2dPdfExport);
  const canUseAutoMeasure20 = useAbility(Operation.Manage, Subject.AutoMeasure2);
  const canUseFilter = useAbility(Operation.Manage, Subject.Takeoff2dPdfFilter);
  const openEngineIfNeeded = useOpenEngineIfNeeded();
  const startScaling = useStartScaling();
  const openScaleIfNeed = useShowCalibrationDialogIfNeed();

  const { setDrawMode } = useDrawModeApi();

  const openAutomeasureMenu = useActionWithArgsDispatch(TwoDInfoPanelActions.setMenu, Menu.AutoMeasure2);

  return useMemo<DefaultViewSection[]>(() => {
    return [
      {
        ...BaseMenuSettings[Menu.Scale],
        ManageIcon: Icons.RightSmall,
        onSelectionMenuClick: startScaling,
        show: drawingRendered, // этот параметр обязательный не зачем давать калибровку если нет чертежа
        children: () => canEditMeasurement ? <ScaleMenuItem/> : null,
        disabled: !canEditMeasurement,
        dataControlName: ControlNames.scale,
      },
      {
        ...BaseMenuSettings[Menu.AutoMeasure],
        ManageIcon: Icons.RightSmall,
        onSelectionMenuClick: () => {
          if (!autoMeasureCalculating) {
            setDrawMode(DrawingsDrawMode.Disabled);
            dispatch(TwoDInfoPanelActions.setMenu(Menu.AutoMeasure));
          }
        },
        isNeedUpdate: !canUseAutoCount,
        show: hasDrawings && selectedPagesCount && projectType === ProjectType.Project2d,
        disabled: !canEditMeasurement,
        dataControlName: ControlNames.autoMeasure,
      },
      {
        ...BaseMenuSettings[Menu.AutoMeasure2],
        ManageIcon: Icons.RightSmall,
        onSelectionMenuClick: () => {
          openScaleIfNeed(() => {
            setDrawMode(DrawingsDrawMode.AutoMeasure2, {
              afterSave: openAutomeasureMenu,
            });
          });
        },
        isNeedUpdate: !canUseAutoCount,
        show: hasDrawings
          && drawingRendered
          && projectType === ProjectType.Project2d && canUseAutoMeasure20,
        disabled: !canEditMeasurement,
        dataControlName: ControlNames.autoMeasure2,
        children: () => canEditMeasurement && canUseAutoCount
          ? <QueryLineWithOpenSearch onRun={openAutomeasureMenu}/>
          : null,
      },
      {
        ...BaseMenuSettings[Menu.AskAi],
        ManageIcon: Icons.RightSmall,
        onSelectionMenuClick: () => {
          setDrawMode(DrawingsDrawMode.Disabled);
          dispatch(TwoDCopilotActions.setPopupOpened(true));
        },
        isNeedUpdate: !canUseCaddy,
        show: drawingRendered
          && canUseCaddy
          && canProposeExtendSubscription
          || canProposeExtendSubscription && canUseCaddy,
        dataControlName: ControlNames.askAi,
      },
      {
        ...BaseMenuSettings[Menu.Compare],
        ManageIcon: Icons.RightSmall,
        onSelectionMenuClick: () => {
          setDrawMode(DrawingsDrawMode.Disabled);
          openEngineIfNeeded();
          dispatch(TwoDInfoPanelActions.setMenu(Menu.Compare));
        },
        show: canSeeCompare,
        isNeedUpdate: !canManageCompare,
        disabled: !canEditMeasurement,
        dataControlName: ControlNames.comparePage,
      }, {
        ...BaseMenuSettings[Menu.TextSearch],
        ManageIcon: Icons.RightSmall,
        onSelectionMenuClick: () => {
          setDrawMode(DrawingsDrawMode.Disabled);
          openEngineIfNeeded();
          dispatch(TwoDInfoPanelActions.setMenu(Menu.TextSearch));
        },
        show: hasDrawings,
        dataControlName: ControlNames.textSearch,
      },
      {
        ...BaseMenuSettings[Menu.Export],
        ManageIcon: Icons.RightSmall,
        onSelectionMenuClick: () => {
          if (!isExportLoading) {
            setDrawMode(DrawingsDrawMode.Disabled);
            dispatch(TwoDInfoPanelActions.setMenu(Menu.Export));
          }
        },
        isNeedUpdate: !canUseExport,
        show: hasDrawings,
        dataControlName: ControlNames.exportPdf,
        children: () => isExportLoading ? <ExportProgress/> : null,
      },
      {
        ...BaseMenuSettings[Menu.PdfFilter],
        ManageIcon: Icons.RightSmall,
        onSelectionMenuClick: () => {
          setDrawMode(DrawingsDrawMode.Disabled);
          dispatch(TwoDInfoPanelActions.setMenu(Menu.PdfFilter));
        },
        disabled: !canUseFilter,
        show: drawingRendered,
        dataControlName: ControlNames.styleFilter,
      },
    ];
  }, [
    startScaling,
    drawingRendered,
    canEditMeasurement,
    canUseAutoCount,
    hasDrawings,
    selectedPagesCount,
    projectType,
    canUseAutoMeasure20,
    canUseCaddy,
    canUseFilter,
    canProposeExtendSubscription,
    canSeeCompare,
    canManageCompare,
    canUseExport,
    autoMeasureCalculating,
    dispatch,
    openScaleIfNeed,
    setDrawMode,
    openEngineIfNeeded,
    isExportLoading,
    openAutomeasureMenu,
  ]);
};
