import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ButtonInput, NumberInput } from 'common/components/inputs';
import { State } from 'common/interfaces/state';
import { numberUtils } from 'common/utils/number-utils';
import { TWODUnitConversionMap, UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { Styled } from './styled';

interface Props {
  value: number;
  onChange: (newValue: number) => void;
  unit: UnitTypes;
  readonly?: boolean;
}


const CustomMeasurementInputComponent: React.FC<Props> = ({
  value,
  onChange,
  unit,
  readonly,
}) => {
  const isImperial = useSelector<State, boolean>(s => s.account.settings.isImperial);
  const imperialUnit = TWODUnitConversionMap[unit];
  const convertedValue = useMemo(() => {
    return isImperial ? numberUtils.round(UnitUtil.convertUnit(value, unit, imperialUnit).value, 3) : value;
  }, [isImperial, value, unit, imperialUnit]);

  const onChangeCallback = useCallback((newValue: number | '') => {
    if (newValue === '') {
      onChange(undefined);
      return;
    }
    if (isImperial) {
      newValue = UnitUtil.convertUnit(newValue, imperialUnit, unit).value;
    }
    onChange(newValue);
  }, [isImperial, onChange, imperialUnit, unit]);

  return (
    <Styled.Container>
      <NumberInput
        value={convertedValue}
        onBlur={onChangeCallback}
        disabled={readonly}
      />
      <ButtonInput
        value={isImperial ? imperialUnit : unit}
      />
    </Styled.Container>
  );
};

export const MeasurementInput = React.memo(CustomMeasurementInputComponent);

