import styled from 'styled-components';
import { Text } from 'common/components/text';

const MARGIN = 10;
const ICON_SIZE = 20;

const Info = styled.div`
  margin-left: auto;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  svg {
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    fill: ${(p) => p.theme.color.gray};
  }
`;

const Container = styled.div<{ isDisabled?: boolean }>`
  position: relative;
  box-sizing: border-box;
  cursor: ${p => p.isDisabled ? 'not-allowed' : 'pointer'};
  opacity: ${p => p.isDisabled ? 0.5 : 1};
  width: 100%;
  display: flex;
  align-items: center;
  ${Text} {
    width: calc(100% - ${MARGIN + ICON_SIZE * 2}px);
    margin-left: ${MARGIN}px;
  }
`;

export const Styled = {
  Container,
  Info,
};
