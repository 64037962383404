import { Icons, RoundButton, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import { DrawingDialogs } from '../../constants/drawing-dialogs';


import { Styled } from './styled';

interface DispatchProps {
  openDialog: () => void;
}

interface OwnProps {
  show: boolean;
}

interface Props extends DispatchProps, OwnProps {
}

const PdfManagerButtonComponent: React.FC<Props> = ({ openDialog, show }) => {
  return show ? (
    <Styled.Container>
      <RoundButton
        onClick={openDialog}
        Icon={Icons.PlusBig}
        diameter={60}
        id='open-pdf-browser-button'
        mood='secondary'
      />
      <Text
        fontSize={14}
        color='gray'
        textAlign='center'
      >
        A page is not selected. Select the page to open it.
      </Text>
    </Styled.Container>
  ) : null;
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    openDialog: () => dispatch(KreoDialogActions.openDialog(DrawingDialogs.PDF_MANAGER_DIALOG)),
  };
}

export const DrawingsPdfManagerButton = connect(null, mapDispatchToProps)(PdfManagerButtonComponent);

