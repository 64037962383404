import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

// import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { DrawingsAnnotationLegendActions } from '../actions/creators/drawings-annotation-legend';
import {
  GroupTemplatePayload,
  MoveDrawingGroupTree,
} from '../actions/payloads/drawings-annotation-legend';
import { DrawingsAnnotationLegendActionTypes } from '../actions/types/drawings-annotation-legend';
import { DrawingsApi } from '../api/drawings-api';
import { GroupTemplateShortInfo } from '../interfaces/drawings-group-template';


function* loadGroups({ payload }: ActionWith<number>): SagaIterator {
  try {
    const groups = yield call(DrawingsApi.getDrawingsGroups, payload);
    yield put(DrawingsAnnotationLegendActions.setGroups(groups));
    yield put(DrawingsAnnotationLegendActions.setGroupLoadSuccess());
    // yield put(TwoDElementViewActions.handleCreateGroup());
  } catch (error) {
    console.error('drawings annotation legend get groups failed', error);
  }
}

function* loadGroupTemplates(): SagaIterator {
  try {
    const templates = yield call(DrawingsApi.getGroupTemplates);
    yield put(DrawingsAnnotationLegendActions.setGroupTemplates(templates));
  } catch (error) {
    console.error('drawings annotation legend: get group templates failed', error);
  }
}

function* createGroupTemplate({ payload }: ActionWith<GroupTemplateShortInfo>): SagaIterator {
  try {
    const newTemplate = yield call(DrawingsApi.createGroupTemplate, payload);
    yield put(DrawingsAnnotationLegendActions.addGroupTemplate(newTemplate));
  } catch (error) {
    console.error('drawings annotation legend: create group template failed', error, payload);
  }
}

function* applyGroupTemplate({ payload }: ActionWith<number>): SagaIterator {
  try {
    const pinned = yield selectWrapper(s => s.drawings.pinnedGroupIds);
    const groups = yield call(DrawingsApi.applyGroupTemplate, payload);
    yield put(DrawingsAnnotationLegendActions.setGroups({ groups, pinnedGroupIds: pinned }));
  } catch (error) {
    console.error('drawings annotation legend: apply group template failed', error, payload);
  }
}

function* updateGroupTemplate({ payload }: ActionWith<GroupTemplatePayload>): SagaIterator {
  try {
    const { templateId, templateInfo } = payload;
    yield call(DrawingsApi.updateGroupTemplate, templateId, templateInfo);
    yield put(DrawingsAnnotationLegendActions.updateGroupTemplateSuccess(templateId, templateInfo));
  } catch (error) {
    console.error('drawings annotation legend: update group template failed', error, payload);
  }
}

function* duplicateGroupTemplate({ payload }: ActionWith<GroupTemplatePayload>): SagaIterator {
  const { templateId, templateInfo } = payload;
  try {
    const newTemplate = yield call(DrawingsApi.duplicateGroupTemplate, templateId, templateInfo);
    yield put(DrawingsAnnotationLegendActions.addGroupTemplate(newTemplate));
  } catch (error) {
    console.error('drawings annotation legend: duplicate group template failed', error, payload);
  }
}

function* deleteGroupTemplate({ payload }: ActionWith<number>): SagaIterator {
  try {
    yield call(DrawingsApi.deleteGroupTemplate, payload);
    yield put(DrawingsAnnotationLegendActions.deleteGroupTemplateSuccess(payload));
  } catch (error) {
    console.error('drawings annotation legend: delete group template failed', error, payload);
  }
}

function* handleMoveGroup({ payload }: ActionWith<MoveDrawingGroupTree>): SagaIterator {
  try {
    // const handler = yield selectWrapper(s => s.twoDElementView.handleMoveGroup);
    // handler(payload);
  } catch (error) {
    console.error('failed to move group', error, payload);
  }
}

export function* drawingsAnnotationLegendSagas(): SagaIterator {
  yield takeLatest(DrawingsAnnotationLegendActionTypes.LOAD_GROUPS, loadGroups);
  yield takeLatest(DrawingsAnnotationLegendActionTypes.LOAD_GROUP_TEMPLATES, loadGroupTemplates);
  yield takeLatest(DrawingsAnnotationLegendActionTypes.CREATE_GROUP_TEMPLATE, createGroupTemplate);
  yield takeLatest(DrawingsAnnotationLegendActionTypes.APPLY_GROUP_TEMPLATE, applyGroupTemplate);
  yield takeLatest(DrawingsAnnotationLegendActionTypes.UPDATE_GROUP_TEMPLATE, updateGroupTemplate);
  yield takeLatest(DrawingsAnnotationLegendActionTypes.DUPLICATE_GROUP_TEMPLATE, duplicateGroupTemplate);
  yield takeLatest(DrawingsAnnotationLegendActionTypes.DELETE_GROUP_TEMPLATE, deleteGroupTemplate);
  yield takeLatest(DrawingsAnnotationLegendActionTypes.MOVE_GROUPS, handleMoveGroup);
}
