import { H5 } from '@kreo/kreo-ui-components';
import React from 'react';
import { Styled } from '../styled';

interface HotkeyProps {
  hotKey?: string;
}

export const HotKey: React.FC<HotkeyProps> = ({ hotKey }) => (
  <Styled.HotKeyContainer>
    <H5 color="turquoise">{hotKey}</H5>
  </Styled.HotKeyContainer>
);
