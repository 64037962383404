import styled from 'styled-components';

const Button = styled.div`
  > button {
    width: 100%;
  }
`;

export const Styled = {
  Button,
};
