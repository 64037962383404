export interface MessageContent {
  img: string;
  message: string;
}

export const HEADER_TEXT = 'Thank you for starting your free trial!';

export const LIST_MESSAGES: MessageContent[] = [
  {
    img: '/static/img/photo_karolina.jpg',
    message: `We're happy you've decided to give Kreo Software a try.
    During the free trial period, you have access to all the product's
    features and add-ons despite the plan you've selected.
    We encourage you to explore Kreo thoroughly to make sure it meets your requirements`,
  },
  {
    img: '/static/img/photo_karolina.jpg',
    message: `If you have any questions or need help getting started,
    please don't hesitate to reach out to our customer support team. We're always here to help`,
  },
  {
    img: '/static/img/photo_karolina.jpg',
    message: `Our team has worked hard to create a solution that we believe will meet your needs
    and help you achieve your goals. We look forward to hearing about your experience during the trial. Thank you`,
  },
];
