import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const CONTAINER_WIDTH = 420;

const Container = styled.div`
  background: ${p => p.theme.color.backgroundRush};
  width: ${CONTAINER_WIDTH}px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  animation: ${Constants.Animations.modalAppear}  ${p => p.theme.duration.m} ease-in-out;

  label {
    margin: 0 auto;
  }

  button {
    margin: 0 40px;
  }
`;

const Separator = styled.div`
  background: ${p => p.theme.color.pale};
  width: 100%;
  height: 10px;
  margin: 20px 0;
`;

export const Styled = {
  Container,
  Separator,
};
