import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import './validation.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State as ReduxState } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { ValidationRouteParams } from '../../../../routes/app-routes-params';
import { ProjectLayout } from '../../../project-dashbord';
import { ScenariosData } from '../../../scenarios';
import { ActivityAssignmentActions } from '../../actions/creators/activity-assignment';
import { ProjectsActions } from '../../actions/creators/common';
import { MeasurementsActions } from '../../actions/creators/measurements';
import { ValidationActions } from '../../actions/creators/validation';
import { ValidationResultsStep } from '../../components/validation-results-step';
import { ValidationStepsNames } from '../../constants/validation-steps-names';
import {
  getPreviousStep,
  getValidationStep,
  isValidationStepGreaterThan,
  isValidationStepLessThan,
  ValidationStep,
} from '../../enums/validation-step';
import { getValidationStepName, ValidationStepName } from '../../enums/validation-step-name';
import { ProjectValidationState } from '../../interfaces/project-validation-state';
import { StepProps, ValidationStepper, ValidationStepperComponent } from './components/validation-stepper';
import {
  ActivityAssignmentStep,
  ClassificationStep,
  MeasurementStep,
} from './steps';

interface StateProps {
  scenario: ScenariosData.Scenario;
  calculation: ScenariosData.Calculation;
  validationState: ProjectValidationState;
}

interface DispatchProps {
  approveStep: (step: ValidationStep, projectId: number, scenarioId?: number) => void;
  revertToStep: (step: ValidationStep, projectId: number) => void;
  dropMeasurementsStats: () => void;
  dropActivityAssignmentStats: () => void;
  runClassification: () => void;
  navigateTo: (url: string) => void;
}

interface Props
  extends StateProps,
    DispatchProps,
    RouteComponentProps<ValidationRouteParams>,
    AbilityAwareProps {}

interface State {
  stepperRef: ValidationStepperComponent | null;
}

class Validation extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      stepperRef: null,
    };
  }

  public render(): React.ReactNode {
    const { validationState, ability } = this.props;
    const { projectId, step } = this.props.match.params;

    if (!validationState) {
      return null;
    }

    const viewableStep = this.getViewableStep(ValidationStep.Results);
    if (viewableStep == null) {
      return <Redirect to={AppUrls.plan.project.index.url({ projectId })} />;
    }

    let redirectStep: ValidationStep = null;
    if (
      step == null ||
      isValidationStepGreaterThan(getValidationStep(step), validationState.validationStep)
    ) {
      redirectStep = validationState.validationStep;
    }

    if (
      isValidationStepLessThan(
        viewableStep,
        redirectStep != null ? redirectStep : getValidationStep(step),
      )
    ) {
      redirectStep = viewableStep;
    }

    if (redirectStep != null) {
      return this.redirectToStep(redirectStep);
    }

    const mountPath = this.props.location.pathname.replace(`/${step}`, '');
    const canApproveMeasurements = ability.can(Operation.Approve, Subject.ValidationMeasurements);
    const canRevertMeasurements = ability.can(Operation.Revert, Subject.ValidationMeasurements);
    const canApproveActivityAssignment = ability.can(
      Operation.Approve,
      Subject.ValidationActivityAssignment,
    );
    const canRevertActivityAssignment = ability.can(
      Operation.Revert,
      Subject.ValidationActivityAssignment,
    );
    const canApproveClassification = ability.can(
      Operation.Approve,
      Subject.ValidationClassification,
    );
    const projectIdAsNumber = parseInt(this.props.match.params.projectId, 10);
    return (
      <ProjectLayout projectId={projectId}>
        <div className='validation-page'>
          <ValidationStepper ref={this.setStepperRef} selectedStep={step} mountPath={mountPath}>
            {(<ClassificationStep
              key={ValidationStep.Classification}
              title={ValidationStepsNames.classification}
              with3dView={true}
              projectId={projectIdAsNumber}
              stepperRef={this.state.stepperRef}
              reclassify={this.reclassify}
              onApprove={canApproveClassification ? this.approveClassification : null}
              step={ValidationStep.Classification}
              onRemoveApproval={canRevertActivityAssignment ? this.revertToClassification : null}
            />) as React.ReactElement<StepProps>}
            {ability.can(Operation.Read, Subject.ValidationActivityAssignment) && (
              <ActivityAssignmentStep
                key={ValidationStep.ActivityAssignment}
                title={ValidationStepsNames.activityAssignment}
                stepperRef={this.state.stepperRef}
                onApprove={canApproveActivityAssignment ? this.approveActivityAssignment : null}
                step={ValidationStep.ActivityAssignment}
                onRevertToPreviousStep={
                  canRevertActivityAssignment ? this.revertToClassification : null
                }
                with3dView={true}
              />
            ) as React.ReactElement<StepProps>}
            {ability.can(Operation.Read, Subject.ValidationMeasurements) && (
              <MeasurementStep
                key={ValidationStep.Measurements}
                title={ValidationStepsNames.measurements}
                stepperRef={this.state.stepperRef}
                onApprove={canApproveMeasurements ? this.approveMeasurements : null}
                step={ValidationStep.Measurements}
                onRevertToPreviousStep={
                  canRevertMeasurements ? this.revertToActivityAssignment : null
                }
                with3dView={true}
              />
            ) as React.ReactElement<StepProps>}
            {ability.can(Operation.Read, Subject.ValidationResults) && (
              <ValidationResultsStep
                projectId={projectIdAsNumber}
                scenarioId={this.props.scenario ? this.props.scenario.id : null}
                title={ValidationStepsNames.result}
                step={ValidationStep.Results}
                onRevertToPreviousStep={this.revertToMeasurements}
                with3dView={false}
              />
            ) as React.ReactElement<StepProps>}
          </ValidationStepper>
        </div>
      </ProjectLayout>
    );
  }

  @autobind
  private reclassify(): void {
    this.props.runClassification();
  }

  @autobind
  private setStepperRef(ref: any): void {
    if (ref) {
      this.setState({ stepperRef: ref });
    }
  }

  @autobind
  private approveClassification(): void {
    this.props.approveStep(ValidationStep.Classification, parseInt(this.props.match.params.projectId, 10));
    if (this.props.scenario) {
      this.props.dropActivityAssignmentStats();
    }
  }

  @autobind
  private approveActivityAssignment(): void {
    this.props.approveStep(
      ValidationStep.ActivityAssignment,
      parseInt(this.props.match.params.projectId, 10),
      this.props.scenario ? this.props.scenario.id : null,
    );
    this.props.dropMeasurementsStats();
  }

  @autobind
  private approveMeasurements(): void {
    const { projectId } = this.props.match.params;
    this.props.approveStep(ValidationStep.Measurements, parseInt(projectId, 10), this.props.scenario.id);
    this.props.navigateTo(
      AppUrls.plan.project.validation.step.url({
        projectId: projectId.toString(),
        step: ValidationStepName.Results,
      }),
    );
  }

  @autobind
  private revertToClassification(): void {
    this.props.revertToStep(ValidationStep.Classification, parseInt(this.props.match.params.projectId, 10));
  }

  @autobind
  private revertToActivityAssignment(): void {
    this.props.revertToStep(ValidationStep.ActivityAssignment, parseInt(this.props.match.params.projectId, 10));
  }

  @autobind
  private revertToMeasurements(): void {
    this.props.revertToStep(ValidationStep.Measurements, parseInt(this.props.match.params.projectId, 10));
  }

  private canViewStep(step: ValidationStep): boolean {
    const { ability } = this.props;
    let subject: Subject;
    switch (step) {
      case ValidationStep.Results:
        subject = Subject.ValidationResults;
        break;
      case ValidationStep.ModelCheck:
        subject = Subject.ValidationModelCheck;
        break;
      case ValidationStep.Classification:
        subject = Subject.ValidationClassification;
        break;
      case ValidationStep.ActivityAssignment:
        subject = Subject.ValidationActivityAssignment;
        break;
      case ValidationStep.Measurements:
        subject = Subject.ValidationMeasurements;
        break;
      default:
        return false;
    }
    return ability.can(Operation.Read, subject);
  }

  private getViewableStep(step?: ValidationStep): ValidationStep | null {
    if (step == null) {
      return null;
    }

    if (this.canViewStep(step)) {
      return step;
    }
    return this.getViewableStep(getPreviousStep(step));
  }

  private redirectToStep(step: ValidationStep): React.ReactNode {
    const targetStepName = getValidationStepName(step);
    return (
      <Redirect
        to={AppUrls.plan.project.validation.step.url({
          projectId: this.props.match.params.projectId,
          step: targetStepName,
        })}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  const currentProject = state.projects.currentProject;
  const validationState = currentProject ? currentProject.validationState : null;
  return {
    scenario: state.scenarios.active_scenario,
    calculation: state.scenarios.active_calculation,
    validationState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    approveStep: (step, projectId, scenarioId?) => {
      dispatch(ValidationActions.approveValidationStep(step, projectId, scenarioId));
    },
    revertToStep: (step, projectId) => {
      dispatch(ValidationActions.revertToValidationStep(step, projectId));
    },
    dropMeasurementsStats: () => dispatch(MeasurementsActions.dropStatistic()),
    dropActivityAssignmentStats: () => dispatch(ActivityAssignmentActions.dropStatistics()),
    runClassification: () => dispatch(ProjectsActions.runClassification()),
    navigateTo: url => dispatch(push(url)),
  };
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export const ValidationPage = withAbilityContext(connector(Validation));
