const prefix = `@admin-demo-projects`;

const CREATE_DEMO_PROJECT = `${prefix}/CREATE_DEMO_PROJECT`;
const UPDATE_DEMO_PROJECT = `${prefix}/UPDATE_DEMO_PROJECT`;
const LOAD_DEMO_PROJECTS = `${prefix}/LOAD_DEMO_PROJECTS`;
const LOAD_DEMO_PROJECTS_SUCCEEDED = `${prefix}/LOAD_DEMO_PROJECTS_SUCCEEDED`;
const DELETE_DEMO_PROJECT = `${prefix}/DELETE_DEMO_PROJECT`;
const DROP_STORE = `${prefix}/DROP_STORE`;


export const AdminDemoProjectsActionTypes = {
  CREATE_DEMO_PROJECT,
  UPDATE_DEMO_PROJECT,
  LOAD_DEMO_PROJECTS,
  LOAD_DEMO_PROJECTS_SUCCEEDED,
  DELETE_DEMO_PROJECT,
  DROP_STORE,
};
