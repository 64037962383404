import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classificaiton-ontology-tree-element.scss';

import { State } from 'common/interfaces/state';
import { KreoIconTreeCollapsed, KreoIconTreeExpanded } from 'common/UIKit';
import { ClassificationActions } from '../../actions/creators/classification';
import { BimElementLayerPropertyType } from '../../enums/bim-element-layer-function';
import { ClassificationObjectType } from '../../enums/classification-object-type';
import {
  ClassificationOntologyNode,
  ClassificationOntologyUniclassInfo,
  ClassificationOntologyVariantTree,
} from '../../interfaces/classification/ontology-data';
import { ClassificationUtils } from '../../utils/classification-utils';
import {
  ClassificationOntologyGraph,
} from '../classification-ontology-graph';
import { ClassificationOntologyTreeElementBody } from '../classification-ontology-tree-element-body';


interface OwnProps {
  index: number;
  count: number;
  isEdit: boolean;
}
interface StateProps {
  name: string;
  type: ClassificationObjectType;
  graph: ClassificationOntologyNode;
  elementsInfo: string;
  isError: boolean;
  isMultilayer: boolean;
  uniclass: ClassificationOntologyUniclassInfo;
  mainLayer: boolean;
}

interface DispatchProps {
  onSelect: (variant: ClassificationOntologyVariantTree) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {

}

interface ComponentState {
  open: boolean;
}

class ClassificationOntologyTreeElementComponent extends React.PureComponent<Props, ComponentState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      open: !!this.props.count,
    };
  }

  public render(): React.ReactNode {
    if (!this.props.graph) return null;

    return (
      <div
        className='classification-ontology-tree-element'
      >
        <div className='classification-ontology-tree-element__content' onClick={this.toggleExpandStatus}>
          <div
            className='classification-ontology-tree-element__collapse-expand-button'
          >
            {this.props.count > 1 && (this.state.open ? <KreoIconTreeExpanded/> : <KreoIconTreeCollapsed/>)}
          </div>
          <ClassificationOntologyTreeElementBody
            isError={this.props.isError}
            isEdit={this.props.isEdit}
            elementInfo={this.props.elementsInfo}
            type={this.props.type}
            variants={this.props.graph.variantsNew}
            onSelect={this.props.onSelect}
            isMultilayer={this.props.isMultilayer}
            uniclass={this.props.uniclass}
            index={this.props.index}
            readableName={this.props.name}
            canonicalName={this.props.graph.canonicalName}
            mainLayer={this.props.mainLayer}
          />
        </div>
        { this.state.open && this.props.graph.subnodes && (
          <div className='classification-ontology-tree-element__graph-container'>
            <ClassificationOntologyGraph
              isEdit={this.props.isEdit}
              graphId={this.props.index}
              graph={this.props.graph.subnodes}
            />
          </div>)
        }
      </div>
    );
  }

  @autobind
  private toggleExpandStatus(): void {
    if (this.props.count <= 1) return;
    this.setState({ open: !this.state.open });
  }
}

function mapStateToProps(
  { classification: { classificationTarget, elementOntologyGraph } }: State,
  { index }: OwnProps): StateProps {
  const node = elementOntologyGraph[index];
  let name = '';
  let type: ClassificationObjectType;
  let elementsInfo: string;
  if (index > 0) {
    if (elementOntologyGraph[index]) {
      const layerInfo = classificationTarget.layers[index - 1].materials[0];
      if (layerInfo) {
        name = layerInfo.name;
        for (const { k, v } of layerInfo.rawDatas) {
          if (k === BimElementLayerPropertyType.LayerFunction) {
            type = ClassificationUtils.getLayerFunctionType(v);
          } else if (k === BimElementLayerPropertyType.Width) {
            elementsInfo = v;
          }
        }
      } else {
        type = ClassificationObjectType.Empty;
      }
    }
  } else {
    type = ClassificationObjectType.Object;
    name = node.graph.humanReadableName;
    elementsInfo = `${classificationTarget.ids.length} objects`;
  }
  return {
    type,
    graph: node.graph,
    isError: node.isUndefined,
    elementsInfo,
    isMultilayer: classificationTarget.layers && !!classificationTarget.layers.length,
    uniclass: node.uniclass,
    name,
    mainLayer: node.graph.mainLayer,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
  return {
    onSelect: variant => dispatch(ClassificationActions.selectNodeVariant(ownProps.index, '-1', variant)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationOntologyTreeElement = connector(ClassificationOntologyTreeElementComponent);
