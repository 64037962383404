import { AnyAction } from 'redux';
import { AnalyticsState } from '../units/analytics/interfaces/redux-state';
import { analyticsReducerMethods, INITIAL_STATE } from '../units/analytics/reducers';

export default function analytics(
  state: AnalyticsState = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): AnalyticsState {
  if (action.type in analyticsReducerMethods) {
    return analyticsReducerMethods[action.type](state, action.payload);
  }
  return state;
}
