import * as React from 'react';

export const IconValidation: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M12.5815273,11.8744205 L13.8535534,13.1464466 C14.3249579,13.6178511 13.6178511,14.3249579 13.1464466,
          13.8535534 L11.8744205,12.5815273 C10.8290793,13.4664216 9.47685163,14 8,14 C4.6862915,14 2,
          11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 C11.3137085,2 14,4.6862915 14,8 C14,9.47685163 13.4664216,
          10.8290793 12.5815273,11.8744205 Z M8,3 C5.23857625,3 3,5.23857625 3,8 C3,10.7614237 5.23857625,13 8,
          13 C10.7614237,13 13,10.7614237 13,8 C13,5.23857625 10.7614237,3 8,3 Z M7.5,8.79289322 L10.1464466,
          6.14644661 C10.3417088,5.95118446 10.6582912,5.95118446 10.8535534,6.14644661 C11.0488155,
          6.34170876 11.0488155,6.65829124 10.8535534,6.85355339 L7.85355339,9.85355339 C7.65829124,
          10.0488155 7.34170876,10.0488155 7.14644661,9.85355339 L5.14644661,7.85355339 C4.95118446,
          7.65829124 4.95118446,7.34170876 5.14644661,7.14644661 C5.34170876,6.95118446 5.65829124,
          6.95118446 5.85355339,7.14644661 L7.5,8.79289322 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
