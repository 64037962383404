import { SvgComponent } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { MultilevelSelectOptionDataWithObjectValue } from 'common/components/multi-level-drop-down-menu';
import { State } from 'common/interfaces/state';
import { MultiViewSelectors } from 'common/utils/multi-view/multi-view-selector';
import { TwoDFullScreenMode } from 'persisted-storage/interfaces/state';
import { PersistedStorageSelectors } from 'persisted-storage/selectors/projectTwoDFullScreenSelector';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { HELP_TOOLTIP_MENU } from '../../drawings-context-menu-renderers';
import { MoveToCellOptionData } from '../../interfaces/options';
import { DrawingsFlyingMeasureSelect } from '../drawings-flying-measure-select';
import { Submenu } from '../submenu';

interface Props {
  color: string;
  tooltip: string;
  icon: SvgComponent;
  moveToCell: (type: string) => void;
  getVariants: () => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>;
}

const FolderToCellMenuComponent: React.FC<Props> = ({
  getVariants,
  color,
  moveToCell,
  tooltip,
  icon,
}) => {
  const isReportInWindow = !MultiViewSelectors.useIsMultiViewConnect();
  const isShowTwoDReport = useSelector<State, boolean>(
    x => x.twoD.isShowTwoDReport
    && (isReportInWindow || PersistedStorageSelectors.selectTwoDFullScreenMode(
      x.persistedStorage,
      x.projects.currentProject.id.toString(),
    ) !== TwoDFullScreenMode.Drawings),
  );
  const isImperial = useSelector<State, boolean>(x => x.account.settings.isImperial);
  const menuTooltip = !isShowTwoDReport ? HELP_TOOLTIP_MENU : tooltip;
  const analytics = useAnalytics();
  const moveToCellCallback = useCallback((type: string) => {
    analytics.sendEvent(MetricNames.transferMeasurements.folderToCell);
    moveToCell(type);
  }, [analytics, moveToCell]);

  const menuRenderer = useCallback(() => {
    return (
      <DrawingsFlyingMeasureSelect
        getMeasures={getVariants}
        moveToCell={moveToCellCallback}
        currentColor={color}
        isImperial={isImperial}
      />
    );
  }, [isImperial, getVariants, moveToCellCallback, color]);

  return (
    <Submenu
      menuRenderer={menuRenderer}
      disabled={!isShowTwoDReport}
      iconType={icon}
      tooltip={menuTooltip}
    />
  );
};

export const MoveToCellMenu = React.memo(FolderToCellMenuComponent);
