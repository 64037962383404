import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { isConfigKey, isStylePropertyKey } from '../excel-table-cell-formatter/common';
import { RowData } from './interfaces';

export const getValue = (value: string | number): string | number => {
  return value === undefined
    ? ''
    : value;
};

export const getCellValue = (row: RowData, key: string): string | number | object | null => {
  const value = row[key];
  if (isStylePropertyKey(key)) {
    return value || {};
  } else if (isConfigKey(key)) {
    return value || null;
  }
  return getValue(value);
};

export const forEachColumnId = (ids: string[] | Set<string>) =>
  (filter: (id: string) => boolean, action: (id: string) => void) => {
    ids.forEach((id: string) => {
      if (filter(id)) {
        action(id);
      }
    });
  };

export const getShiftedColumnId = (columnKey: string, offset: number): string => {
  const [, prefix, columnName] = columnKey.match(/(\w_)?(\w+)/);
  const columnId = ExcelColumnHelper.shiftColumnName(columnName, offset);
  return prefix ? `${prefix}${columnId}` : columnId;
};

export const getColumnIndex = (columnKey: string): number => {
  const columnName = columnKey.split('_')[1];
  return ExcelColumnHelper.excelColNameToIndex(columnName || columnKey);
};
