import { UploadingFile } from 'common/interfaces/common-state';
import { UuidUtil } from 'common/utils/uuid-utils';
import { CommonsApi } from '../../../../api/common';
import { DrawingsUploadPdfResult } from '../interfaces';
import { DrawingsCreateFile } from '../interfaces/drawings-api-payload';

async function upload(file: File, parentId: string): Promise<DrawingsUploadPdfResult> {
  const [ tempFileName ] = await CommonsApi.uploadFileWithoutProgressTracking(file);
  const pdfId = UuidUtil.generateUuid();
  const pdf: DrawingsCreateFile = {
    id: pdfId,
    name: file.name,
    temporaryFilePath: tempFileName,
    pages: [],
    parentId,
  };
  return { drawings: [], pdf };
}

function prepareFileData(file: UploadingFile, parentId: string): DrawingsUploadPdfResult {
  const pdfId = UuidUtil.generateUuid();
  const pdf: DrawingsCreateFile = {
    id: pdfId,
    name: file.file.name,
    temporaryFilePath: file.uploadedFileName,
    pages: [],
    parentId,
  };
  return { drawings: [], pdf };
}

export const DrawingsUploadFileUtils = {
  upload,
  prepareFileData,
};
