import * as React from 'react';

import './help-dialog.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { Checkbox, KreoDialog } from 'common/UIKit';

interface Props {
  title: string;
  src: string;
  name: string;
  video?: boolean;
  labelCheckbox?: string;
  disabledShowStartup?: boolean;
  onChangeShowStartup?: () => void;
}

export const HelpDialog: React.FC<Props> = props => {
  const { title, src, name, video, labelCheckbox, disabledShowStartup, onChangeShowStartup } = props;
  return src && title ? (
    <KreoDialog
      bodyClassName='help-dialog'
      titleClassName='help-dialog__title'
      title={title}
      name={name}
      size='large'
    >
      {labelCheckbox
        ? (
          <div className='help-dialog__checkbox'>
            <Checkbox
              label={labelCheckbox}
              checked={disabledShowStartup || false}
              onChange={onChangeShowStartup}
              size='large'
              color='blue'
            />
          </div>
        ) : null}
      <div className='help-dialog__content'>
        {video
          ? <iframe src={src} allowFullScreen={true} className='help-dialog__video' />
          : <img src={src} alt={title} className='help-dialog__image' />
        }
        <SvgSpinner size='large' />
      </div>
    </KreoDialog>
  ) : null;
};
