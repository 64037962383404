const prefix = '@revisions';

export const RevisionsActionTypes = {
  FETCH_PROJECT_REVISIONS_INFO: `${prefix}/FETCH_PROJECT_REVISIONS_INFO`,
  SET_PROJECT_REVISIONS_INFO: `${prefix}/SET_PROJECT_REVISIONS_INFO`,
  DELETE_REVISION: `${prefix}/DELETE_REVISION`,
  DUPLICATE_REVISION: `${prefix}/DUPLICATE_REVISION`,
  UPDATE_REVISION: `${prefix}/UPDATE_REVISION`,
  FETCH_REVISION_STATE: `${prefix}/FETCH_REVISION_STATE`,
  SET_REVISION_STATE: `${prefix}/SET_REVISION_STATE`,
  SET_CURRENT_REVISION_STATE: `${prefix}/SET_CURRENT_REVISION_STATE`,
};
