import { ModalWrapper, SliderHorizontal, Text, RectangleButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import AvatarEditor from 'react-avatar-editor';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';


import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { RESTRICT_AVATAR } from '../../../../constants/forms';
import { AccountActions } from '../../../../units/account/actions/creators';
import { State as ReduxState } from '../../../interfaces/state';
import { Styled } from './styled';

interface ReduxProps {
  file: File;
}

interface ReduxActions {
  close: () => void;
}

interface OwnProps {
  onCrop: (image: any) => void;
  prefixName?: string;
}

interface CustomProps extends OwnProps, ReduxProps, ReduxActions { }

interface Props extends InjectedFormProps<any, CustomProps>, CustomProps { }

interface State {
  scale: number;
}

export const RESTRICT_AVATAR_DIALOG_NAME = 'restrictAvatarDialog';

class RestrictImageDialogComponent extends React.Component<Props, State> {
  private avatarEditorColor: number[] = [0, 0, 0, 0.6];
  private editor: AvatarEditor;

  constructor(props: Props) {
    super(props);

    this.state = {
      scale: 1,
    };
  }

  public render(): JSX.Element {
    const { file, prefixName = '' } = this.props;

    return (
      <DialogWrapper name={RESTRICT_AVATAR_DIALOG_NAME + prefixName}>
        <ModalWrapper onExit={this.onDialogClose} zIndex={1400}>
          {file ?
            <Styled.Container>
              <Styled.Title>
                <Text>
                  Crop an avatar
                </Text>
              </Styled.Title>
              <AvatarEditor
                ref={this.setEditorRef}
                image={file}
                width={240}
                height={240}
                border={30}
                scale={this.state.scale}
                color={this.avatarEditorColor}
              />
              <Styled.Slider>
                <SliderHorizontal
                  min={.5}
                  max={4}
                  step={.1}
                  onChange={this.onSliderChange}
                  value={this.state.scale}
                />
              </Styled.Slider>
              <Styled.Action>
                <RectangleButton
                  onClick={this.onCrop}
                  text='Save'
                  height={50}
                  width={120}
                  mood='secondary'
                />
              </Styled.Action>
            </Styled.Container> : null
          }
        </ModalWrapper>
      </DialogWrapper >
    );
  }

  @autobind
  private onSliderChange(value: number): void {
    this.setState({ scale: value });
  }

  @autobind
  private onDialogClose(): void {
    this.props.reset();
    this.props.close();
    this.setState({
      scale: 1,
    });
  }

  @autobind
  private setEditorRef(editor: AvatarEditor): void {
    this.editor = editor;
  }

  @autobind
  private onCrop(): void {
    const canvas = this.editor.getImage();

    if (this.props.onCrop) {
      this.props.onCrop(canvas);
    }

    this.onDialogClose();
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    file: state.account.restrictAvatarFile,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): ReduxActions => {
  const prefixName = props.prefixName || '';
  return {
    close: () => {
      dispatch(KreoDialogActions.closeDialog(RESTRICT_AVATAR_DIALOG_NAME + prefixName));
      dispatch(AccountActions.setAvatarFile(null));
    },
  };
};

const restrictImageDialogForm = reduxForm<any, CustomProps>({
  form: RESTRICT_AVATAR,
  destroyOnUnmount: true,
})(RestrictImageDialogComponent);
const connector = connect(mapStateToProps, mapDispatchToProps);

export const RestrictImageDialog = connector(restrictImageDialogForm);
