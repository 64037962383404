import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';

export function useAnnotationTryCreatePermission(): boolean {
  const canEditAnnotation = useAbility(Operation.Update, Subject.Takeoff2dAnnotations);
  const canViewAnnotation = useAbility(Operation.Preview, Subject.Takeoff2dAnnotations);

  return canEditAnnotation || canViewAnnotation;
}
