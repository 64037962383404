import * as t from 'io-ts';
import { CEActivityAssignmentDataTreeNodeC } from './ce-activity-assignment-data-tree-node';


export const UpdateActivityAssignmentActivityC = t.intersection(
  [
    t.type({ id: t.number }),
    CEActivityAssignmentDataTreeNodeC,
  ],
  'UpdateActivityAssignmentActivity',
);

export type UpdateActivityAssignmentActivity = t.TypeOf<typeof UpdateActivityAssignmentActivityC>;
