import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './manage-people-on-workpackage-dialog.scss';

import {
  HorizontalTab,
  HorizontalTabProps,
  HorizontalTabsContainer,
} from 'common/components/horizontal-tabs-container';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialog, KreoDialogActions } from 'common/UIKit';
import { KreoDialogActionsPanel } from 'common/UIKit/dialogs/kreo-dialog-actions-panel';
import { Toggle } from '../../../../components/toggle';
import { BidPricingActions } from '../../actions/creators/bid-pricing';
import { BidPricingInviteUsers } from '../../interfaces/bid-pricing/bid-pricing-invite-users';
import { BidPricingWorkPackage } from '../../interfaces/bid-pricing/bid-pricing-work-package';
import { ProjectPerson } from '../../interfaces/project-person';
import { PeopleOnProjectSelect } from '../people-on-project-select';
import { createInvitation, createPeople } from './utils';

interface ReduxProps {
  people: ProjectPerson[];
  companyId: number;
  open: boolean;
}

interface ReduxActions {
  dialogClose(): void;
  invite(inviteData: BidPricingInviteUsers, emailsForRemove: string[]): void;
}

interface OwnProps {
  workpackage: BidPricingWorkPackage;
}

interface Props extends ReduxProps, ReduxActions, OwnProps {}

interface State {
  selectedEmails: string[];
  assignCompany: boolean;
}

export const ManagePeopleOnWorkPackageDialogName = 'ManagePeopleOnWorkpackageDialog';

class ManagePeopleOnWorkPackage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { selectedEmails: [], assignCompany: false };
  }


  public componentDidUpdate(prevProps: Props): void {
    if (this.props.open && !prevProps.open) {
      this.setState({
        selectedEmails: [],
        assignCompany: !!this.props.workpackage && this.props.workpackage.assignCompany,
      });
    }
  }

  public render(): React.ReactNode {
    const { workpackage } = this.props;
    const { selectedEmails, assignCompany } = this.state;

    return (
      <KreoDialog
        name={ManagePeopleOnWorkPackageDialogName}
        title={'Manage contractors on Work Package'}
        isModal={true}
        bodyClassName='manage-people-on-workpackage'
      >
        <div className='manage-people-on-workpackage__package'>
          {workpackage && workpackage.name}
        </div>
        <HorizontalTabsContainer>
          {[
            <HorizontalTab
              name='Subcontractors'
              className='manage-people-on-workpackage__tab'
              key={'subcontractors'}
            >
              <div
                className={classNames('manage-people-on-workpackage__assign-company', {
                  active: assignCompany,
                })}
              >
                <span>Assign this package to our company</span>
                <Toggle
                  checked={assignCompany}
                  onCheck={this.onChangeAssignCompany}
                  checkedColor='#5C8AE6'
                />
              </div>
              <PeopleOnProjectSelect
                onSelectChange={this.selectPerson}
                selectedEmails={selectedEmails}
                people={this.props.people}
                emptyText='You don’t have Subcontractors'
                onInviteClick={this.props.dialogClose}
                className='manage-people-on-workpackage__subcontractors'
              />
            </HorizontalTab>,
          ] as Array<React.ReactElement<HorizontalTabProps>>}
        </HorizontalTabsContainer>
        <KreoDialogActionsPanel
          onCancel={this.props.dialogClose}
          onSubmit={this.onSubmit}
          submitDisabled={
            selectedEmails.length === 0 &&
            (workpackage && workpackage.assignCompany === assignCompany)
          }
        />
      </KreoDialog>
    );
  }

  @autobind
  private selectPerson(selected: boolean, email: string, defaultSelected: boolean): void {
    const selectedValue = defaultSelected ? !selected : selected;
    const selectedEmails = selectedValue
      ? this.state.selectedEmails.concat(email)
      : this.state.selectedEmails.filter(x => x !== email);

    this.setState({ selectedEmails });
  }

  @autobind
  private onSubmit(): void {
    const data = createInvitation(
      this.props.workpackage,
      this.state.selectedEmails,
      this.state.assignCompany,
    );
    this.props.invite(data.invitation, data.emailsForRemove);
    this.props.dialogClose();
  }

  @autobind
  private onChangeAssignCompany(value: boolean): void {
    this.setState({ assignCompany: value });
  }
}

function mapStateToProps(state: ReduxState, ownProps: OwnProps): ReduxProps {
  const currentProject = state.projects.currentProject;
  const companyId = currentProject ? currentProject.companyId : null;
  return {
    people: createPeople(state, ownProps.workpackage),
    companyId,
    open: ManagePeopleOnWorkPackageDialogName in state.dialog,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): ReduxActions {
  return {
    dialogClose: () => dispatch(KreoDialogActions.closeDialog(ManagePeopleOnWorkPackageDialogName)),
    invite: (inviteData, emailsForRemove) => {
      if (emailsForRemove.length > 0) {
        dispatch(
          BidPricingActions.removePeopleFromWorkPackage(inviteData.workPackageId, emailsForRemove),
        );
      }
      dispatch(BidPricingActions.inviteUsersToPackage(inviteData));
    },
  };
}

export const ManagePeopleOnWorkPackageDialog = connect(mapStateToProps, mapDispatchToProps)(
  ManagePeopleOnWorkPackage,
);
