import * as t from 'io-ts';

import { ProjectStatusC } from 'common/enums/project-status';
import { FeedC } from 'common/interfaces/feed';
import { ProjectAccessReasonC } from 'unit-projects/enums';
import { ProjectValidationStateC } from './project-validation-state';
import { ViewModelStatusPairC } from './view-model-status-pair';

export const CompanyProjectHeaderUserC = t.intersection([
  t.type({
    email: t.string,
    id: t.string,
  }),
  t.partial({
    isAdmin: t.boolean,
    userName: t.string,
    firstName: t.string,
    lastName: t.string,
  }),
]);

export const CompanyProjectHeaderC = t.intersection(
  [
    t.type({
      id: t.number,
      companyId: t.number,
      ownerId: t.string,
      type: t.string,
      name: t.string,
      status: ProjectStatusC,
      projectFailureNotified: t.boolean,
      hasNoSourceFiles: t.boolean,
      hasUnstartedProducts: t.boolean,
      viewModelStatuses: t.array(ViewModelStatusPairC),
      validationState: ProjectValidationStateC,
      isCompanyShared: t.boolean,
    }),
    t.partial({
      isOwn: t.boolean,
      created: t.string,
      accessReason: ProjectAccessReasonC,
      users: t.array(CompanyProjectHeaderUserC),
    }),
  ],
  'CompanyProjectHeader');

export const CompanyProjectHeadersFeedC = FeedC(CompanyProjectHeaderC, 'CompanyProjectHeaderFeed');
export type CompanyProjectHeader = t.TypeOf<typeof CompanyProjectHeaderC>;
export type CompanyProjectHeaderUser = t.TypeOf<typeof CompanyProjectHeaderUserC>;
