import { IconButton, Icons, MovableContextMenu, SmallMenuList } from '@kreo/kreo-ui-components';
import { useOpenMenuWithPosition } from '@kreo/kreo-ui-components/utils';
import React, { useCallback } from 'react';

import { TablePreset } from '2d/index';
import { ContextMenuItem } from 'common/components/context-menu';

interface Props {
  itemData: TablePreset;
  openCreateDialog: (preset?: TablePreset) => void;
  deletePreset: (id: string) => void;
}

const ItemContextMenuComponent: React.FC<Props> = ({
  itemData,
  openCreateDialog,
  deletePreset,
}) => {
  const { isOpen, onOpen, onClose, position } = useOpenMenuWithPosition();

  const edit = useCallback(() => {
    openCreateDialog(itemData);
    onClose();
  }, [itemData]);

  const deleteItem = useCallback(() => {
    deletePreset(itemData.id);
    onClose();
  }, [itemData.id]);

  return (
    <>
      <IconButton
        Icon={Icons.Menu}
        width={20}
        height={20}
        onClick={onOpen}
      />
      {
        isOpen ? (
          <MovableContextMenu
            x={position.x}
            y={position.y}
            onClose={onClose}
          >
            <SmallMenuList width={180} onExit={onClose}>
              <ContextMenuItem icon={Icons.Edit2D} onClick={edit}>
                Edit
              </ContextMenuItem>
              <ContextMenuItem icon={Icons.Delete} onClick={deleteItem}>
                Delete
              </ContextMenuItem>
          </SmallMenuList>
          </MovableContextMenu>
        ) : null
      }
    </>
  );
};

export const ItemContextMenu = React.memo(ItemContextMenuComponent);
