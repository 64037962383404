import { MonoliteHelper } from 'common/monolite';
import { DrawingsEditFileTreeItem } from '../actions/payloads/common';
import { DrawingsFSHighlightState, DrawingsState } from '../interfaces/drawings-state';

function editFileTreeItem(
  drawingsState: DrawingsState,
  itemUpdate: DrawingsEditFileTreeItem,
): DrawingsFSHighlightState {
  const { fsHighlightStatus, files } = drawingsState;
  const helper = new MonoliteHelper(fsHighlightStatus);
  const filesWithSelectedElement = { ...fsHighlightStatus.filesWithSelectedElement };
  if (itemUpdate.parentFolderId) {
    let entity = files.entities[itemUpdate.parentFolderId];
    do {
      filesWithSelectedElement[entity.id] = true;
      entity = files.entities[entity.parentId];
    } while (entity && !filesWithSelectedElement[entity.id]);
  }
  const oldParentId = files.entities[itemUpdate.id].parentId;
  if (oldParentId) {
    const needReselectParent = !files.entities[oldParentId].children
      .filter(x => filesWithSelectedElement[x]).length;
    if (needReselectParent) {
      let entity = files.entities[oldParentId];
      do {
        filesWithSelectedElement[entity.id] = false;
        entity = files.entities[entity.parentId];
      } while (
        entity
        && entity.children.filter(x => filesWithSelectedElement[x]).length
      );
    }
  }
  return helper.get();
}


export const DrawingsSelectionUtils = {
  editFileTreeItem,
};
