import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import { TabNav } from './tab-nav';

interface Props {
  page: number;
  currentPage: number;
  action: () => void;
  onClick: (page: number) => void;
}

class ItemComponent extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): React.ReactNode {
    return (
      <div
        className={classNames('tab-nav__header__menu-item', {
          active: this.props.page === this.props.currentPage,
        })
        }
        onClick={this.onClick}
      >
        {this.props.children}
      </div>
    );
  }

  @autobind
  private onClick(): void {
    this.props.onClick(this.props.page);
    if (this.props.action) {
      this.props.action();
    }
  }
}


interface RendererProps {
  page: number;
  action: () => void;
}

export const MenuItem: React.FC<RendererProps> = (props) => {
  const Context = TabNav.getContext();
  return (
    <Context.Consumer>
      {
        (value) => (
          <ItemComponent
            page={props.page}
            currentPage={value.page}
            onClick={value.onChangePage}
            action={props.action}
          >
            {props.children}
          </ItemComponent>
        )
      }
    </Context.Consumer>
  );
};
