import React, { useCallback } from 'react';

import { RadioMenu } from 'common/components/selection-menus';
import { Filters } from 'unit-2d-comments/enums';
import { FilterSettings } from './commentary-filters';
import { Styled } from './styled';

interface Props {
  filter: FilterSettings;
  value: string | string[];
  onFilterValueChanged: (filter: Filters, value: string) => void;
}

const FilterComponent: React.FC<Props> = ({ filter, value, onFilterValueChanged }) => {
  const onFilterValueChangedCallback = useCallback((newValue: string) => {
    onFilterValueChanged(filter.filter, newValue);
  }, [onFilterValueChanged, filter.filter]);

  return (
    <Styled.Filter>
      <RadioMenu<string>
        options={filter.options}
        value={value as string}
        onSelectionChanged={onFilterValueChangedCallback}
      />
    </Styled.Filter>
  );
};

export const Filter = React.memo(FilterComponent);
