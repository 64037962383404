import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { MainLayout } from 'common/components/main-layout';
import { Spinner } from 'common/components/spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { CreateCompany } from 'components/dialog/create-company-dialog/create-company';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { Styled } from './styled';

const emptyMenuList = (): any[] => [];

interface ReduxActions {
  dismissChatCloud: () => void;
}

interface ReduxProps {
  createCompanyStatus: RequestStatus;
}

interface Props extends ReduxProps, ReduxActions { }

class EmptyOrganizations extends React.Component<Props> {
  public render(): React.ReactNode {
    const { createCompanyStatus } = this.props;
    return (
      <Styled.Container>
        <MainLayout
          getMenuItems={emptyMenuList}
          hideCompaniesSelect={true}
          metaTitle='No Company'
        >
          <Styled.CreateCompanyWrapper>
            {
              createCompanyStatus === RequestStatus.Loading ?
              <Spinner show={true} withBackground={true} /> :
              <CreateCompany />
            }
          </Styled.CreateCompanyWrapper>
        </MainLayout>
      </Styled.Container>
    );
  }

  public componentDidMount(): void {
    this.props.dismissChatCloud();
  }
}


const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    dismissChatCloud: () => dispatch(PersistedStorageActions.dismissChatCloud()),
  };
};

const mapStateToProps = (state: State): ReduxProps => {
  return {
    createCompanyStatus: state.account.statuses.createCompany,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const EmptyOrganizationsPage = connector(EmptyOrganizations);
