import React, { useMemo } from 'react';
import { RoleGroup } from 'common/enums/role-group';
import { PersonUtils } from 'common/utils/person-utils';
import { CommentaryUserInfo, UserForMention } from 'unit-2d-comments/interfaces';
import { Person } from '../../../../units/people/interfaces/person';
import { CommentUserContext, CommentUserContextProps } from './comment-user-context';

interface Props {
  users: Person[];
}

export const CommentUserContextProvider: React.FC<Props> = ({ users, children }) => {
  const value: CommentUserContextProps = useMemo(() => {
    const usersDict: Record<string, CommentaryUserInfo> = {};
    const mentionUsers = new Array<UserForMention>();

    for (const user of users) {
      usersDict[user.id] = user as CommentaryUserInfo;
      const displayName = PersonUtils.getPersonName(user);
      mentionUsers.push({
        id: user.id,
        display: displayName,
        email: user.email,
        isGuest: user.roleGroup === RoleGroup.Guest,
      });
    }
    const getUserDisplayName = (id: string): string => {
      const user = usersDict[id];
      return PersonUtils.getPersonName(user);
    };

    return {
      users: usersDict,
      usersForMention: mentionUsers,
      getUserDisplayName,
    };
  }, [users]);

  return (
    <CommentUserContext.Provider value={value}>
      {children}
    </CommentUserContext.Provider>
  );
};

