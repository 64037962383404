import { RefHandler } from './ref-handler';

export class DrawingsRef extends RefHandler {
  public addRefs(groups: Record<string, string>, cellId: string): void {
    if (groups.drawingInstance) {
      if (groups.drawingInstanceType.startsWith('segment')) {
        const id = groups.otherArgs?.replace(',', '');
        if (id) {
          if (!this.state[id]) {
            this.state[id] = [cellId];
          } else {
            this.state[id].push(cellId);
          }
        }
      }
      const id = groups.drawingInstanceId;
      if (!this.state[id]) {
        this.state[id] = [cellId];
      } else {
        this.state[id].push(cellId);
      }
    }
  }

  public removeRefs(groups: Record<string, string>, cellId: string): void {
    if (groups.drawingInstance) {
      if (groups.drawingInstanceType.startsWith('segment')) {
        const id = groups.otherArgs?.replace(',', '');
        if (id) {
          const drawingRefs = this.state[id];
          if (drawingRefs) {
            const index = drawingRefs.indexOf(cellId);
            if (index !== -1) {
              this.state[id].splice(index, 1);
            }
          }
        }
      }
      const id = groups.drawingInstanceId;
      const drawingRefs = this.state[id];
      if (drawingRefs) {
        const index = drawingRefs.indexOf(cellId);
        if (index !== -1) {
          this.state[id].splice(index, 1);
        }
      }
    }
  }
}
