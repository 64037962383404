import { Icons } from '@kreo/kreo-ui-components';
import styled, { css } from 'styled-components';

const Icon = styled(Icons.CircleArrow)`
  width: 20px;
  height: 20px;
`;

const Container = styled.div`
  display: inline-flex;
  vertical-align: middle;
  margin-right: 5px;
  z-index: 1;
`;

const IconContainer = styled.div<{ selected: boolean, inherited: boolean, assigned: boolean }>`
  box-sizing: border-box;
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${p => p.theme.color[
    p.selected && p.inherited || p.selected && p.assigned ? 'turquoise' : 'background'
  ]};
  justify-content: center;
  align-items: center;

  ${p => p.assigned && css`
    ${Icon} {
      fill: ${p.theme.color.turquoise};
    }
  `}
  ${p => p.inherited && css`
    ${Icon} {
      fill: ${p.theme.color.gray};
    }
  `}
  ${p => !p.inherited && !p.assigned && css`
    ${Icon} {
      fill: ${p.theme.color.disabled};
    }
  `}
  ${p => (p.selected && p.inherited || p.selected && p.assigned) && css`
    ${Icon} {
      fill: ${p.theme.color.white};
    }
  `}
`;

export const Styled = {
  Container,
  IconContainer,
  Icon,
};
