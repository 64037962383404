import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function useActionDispatch<T extends (...args: any[]) => any>(
  action: T,
): (...args: Parameters<T>) => void {
  const dispatch = useDispatch();
  return useCallback(
    (...args: Parameters<T>): void  => dispatch(action(...args)),
    [dispatch, action],
  );
}


export function useActionWithArgsDispatch<T extends (...args: any[]) => any>(
  action: T,
  ...params: Parameters<T>
): () => void {
  const dispatch = useDispatch();
  return useCallback(
    (): void  => dispatch(action(...params)),
    [dispatch, action, ...params],
  );
}
