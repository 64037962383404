import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { FormField } from '../form-fields/form-field';
import { TextProps } from '../text';
import { Styled } from './styled';


interface Props extends TextProps {
  title: string;
  startEditing?: (e: React.MouseEvent<HTMLHtmlElement>) => void;
}

export const Property: React.FC<Props> = ({ title, children, startEditing }) => {
  return (
    <FormField label={title} editIcon={Icons.RenameSmall} startEditing={startEditing}>
      <Styled.Setting>
        {children}
      </Styled.Setting>
    </FormField>
  );
};
