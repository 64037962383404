import { ColumnApi, RowNode } from 'ag-grid-community';
import { Parser } from 'expr-eval';

import { TreeTableValueGetterParams } from 'common/components/tree-table/interfaces';
import { FormulaOperation } from 'common/enums/formula-operation';
import {
  QtoTreeTableCommon,
} from '../../components/quantity-take-off-report-table/quantity-take-off-tree-table-common';
import { DefaultPivotColumnKey } from '../../enums/default-pivot-column-key';
import { QtoColumnPropertyHelper } from './column-properties-helper';
import { QtoReportPivotTableIdHelper } from './pivot-table';
import { PropertyHelper } from './property-helper';

enum ValueType {
  Formula,
  Cell,
}

interface Value {
  type: ValueType;
  value: QuantityTakeOffParsedFormula | string;
}

interface QuantityTakeOffParsedFormula {
  firstValue: Value;
  secondValue: Value;
  operation: FormulaOperation;
}

const formulaVariableRegex = /\[(.*?)\]/g;
const availableFormulaVariableRegex = /^=((\[[\w\-\#\_\=@]+\])|([\d\.\s,\+\^\-\*\/\(\)]+))+$/;
const nonBreakingSpaceRegex = new RegExp(String.fromCharCode(160), 'g');
const parser = new Parser();


function calculateFormula(
  formula: string,
  params: TreeTableValueGetterParams,
): string {
  const exp = replaceWithNumber(formula, params);
  return parser.evaluate(exp.replace(/=/g, '').toLocaleUpperCase()).toString();
}

function calculateFormulaPivotMode(
  formula: string,
  node: RowNode,
  params: TreeTableValueGetterParams,
): string {
  const exp = replaceWithNumberPivotMode(formula, node, params);
  return parser.evaluate(exp.replace(/=/g, '').toLocaleUpperCase()).toString();
}


function replaceWithNumberPivotMode(
  formula: string,
  node: RowNode,
  params: TreeTableValueGetterParams,
  knownValue: Record<string, string> = {},
): string {
  if (!isValidFormulaFormat(formula)) {
    return null;
  }

  return formula.replace(formulaVariableRegex, (_match, key) => {
    const data = node.data;

    if (isLoop(knownValue, key)) {
      knownValue[key] = key;
      return key;
    }
    if (isNewVariable(knownValue, key)) {
      knownValue[key] = null;

      const property = data.properties[key];

      if (PropertyHelper.isFormula(property)) {
        const subFormula = PropertyHelper.getActualValue<string>(property);
        knownValue[key] = replaceWithNumberPivotMode(
          subFormula.replace(nonBreakingSpaceRegex, ''),
          node,
          params,
          knownValue,
        );
        return `(${knownValue[key]})`;
      }

      const { groupId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(params.node.id);
      const updNode: any = { ...params.node, id: QtoReportPivotTableIdHelper.getPivotRowId(groupId, key) };

      if (PropertyHelper.isAggregation(property)) {
        knownValue[key] = params.api.getValue(DefaultPivotColumnKey.Quantity, updNode);
      } else {
        const result = PropertyHelper.getActualValue<string>(property);
        knownValue[key] = result;
      }

      const unit = params.api.getValue(DefaultPivotColumnKey.Unit, updNode);
      if (unit) {
        knownValue[key] = QtoTreeTableCommon.metricValueConverter(
          Number(knownValue[key]),
          unit,
          params.context.isImperial,
        ).toString();
      }
    }

    return knownValue[key];
  });
}

function replaceWithNumber(
  formula: string,
  params: TreeTableValueGetterParams,
  knownValue: Record<string, string> = {},
): string {
  if (!isValidFormulaFormat(formula)) {
    return null;
  }

  return formula.replace(formulaVariableRegex, (_match, key) => {
    const { node: { data } } = params;

    if (isLoop(knownValue, key)) {
      knownValue[key] = key;
      return key;
    }

    if (isNewVariable(knownValue, key)) {
      knownValue[key] = null;
      const property = data.properties[key];

      if (PropertyHelper.isFormula(property)) {
        const subFormula = PropertyHelper.getActualValue<string>(property);
        knownValue[key] = replaceWithNumber(
          subFormula.replace(nonBreakingSpaceRegex, ''),
          params,
          knownValue,
        );
        return `(${knownValue[key]})`;
      }

      if (PropertyHelper.isAggregation(property)) {
        knownValue[key] = params.api.getValue(key, params.node);
      } else {
        const result = PropertyHelper.getActualValue<string>(property);
        knownValue[key] = result;
      }
    }


    convertUnit(knownValue, key, params);
    return knownValue[key];
  });
}

function isLoop(knownValue: Record<string, string>, key: string): boolean {
  return knownValue[key] === null;
}

function isNewVariable(knownValue: Record<string, string>, key: string): boolean {
  return !knownValue[key];
}

function replaceKeyToHeader(formula: string, columnApi: ColumnApi): string {
  return formula.replace(formulaVariableRegex, (_match, key) => {
    const column = columnApi.getColumn(key);
    if (!column) {
      return `[${key}]`;
    }
    const header = column.getColDef().headerName;
    return `[${header}]`;
  });
}

function isValidFormulaFormat(formula: string): boolean {
  return availableFormulaVariableRegex.test(formula);
}

function convertUnit(knownValue: Record<string, string>, key: string, params: TreeTableValueGetterParams): void {
  if (!params.columnApi) {
    return;
  }

  const column = params.columnApi.getColumn(key);
  const unit = QtoColumnPropertyHelper.getColumnUnit(column);
  const isNeedConvert = params.context.isImperial;
  if (unit) {
    knownValue[key] = QtoTreeTableCommon.metricValueConverter(
      Number(knownValue[key]),
      unit,
      isNeedConvert,
    )
      .toString();
  }
}

export const QuantityTakeOffFormulaHelper = {
  calculateFormula,
  calculateFormulaPivotMode,
  replaceKeyToHeader,
  formulaVariableRegex,
  nonBreakingSpaceRegex,
};
