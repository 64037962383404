import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { WizzardToolsActions } from '../../../actions/creators';
import { WizzardStatus } from '../../../enums/dropper-state';
import { useWizzardSetting } from '../../../hooks';
import { ShortPointDescription } from '../../../interfaces';

interface UseSpecifySearchingArea {
  area: ShortPointDescription[];
  clearArea: () => void;
  specifySearchingArea: () => void;
}

export function useSearchingArea(): UseSpecifySearchingArea {
  const [area, setArea] = useWizzardSetting('searchArea');
  const dispatch = useDispatch();
  const clearArea = useCallback(() => setArea(null), []);
  const specifySearchingArea = useCallback(() => {
    dispatch(WizzardToolsActions.setWizzardState(WizzardStatus.SpecifySearchArea));
  }, [dispatch]);
  return {
    area,
    clearArea,
    specifySearchingArea,
  };
}
