import { RefHandler } from './ref-handler';

export class DynamicGroupsRef extends RefHandler {
  public addRefs(groups: Record<string, string>, cellId: string): void {
    if (groups.drawingInstanceType === 'dynamicTable') {
      const id = groups.drawingInstanceId;
      if (!this.state[id]) {
        this.state[id] = [cellId];
      } else {
        this.state[id].push(cellId);
      }
    }
  }

  public removeRefs(groups: Record<string, string>, cellId: string): void {
    if (groups.drawingInstanceType === 'dynamicTable') {
      const id = groups.drawingInstanceId;
      const drawingRefs = this.state[id];
      if (drawingRefs) {
        const index = drawingRefs.indexOf(cellId);
        if (index !== -1) {
          this.state[id].splice(index, 1);
        }
      }
    }
  }
}
