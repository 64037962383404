import autobind from 'autobind-decorator';
import Axios, { Canceler } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { change } from 'redux-form';

import './demo-file-upload-button.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { KreoButton, KreoIconCreateNew } from 'common/UIKit';
import { Common } from '../../actions';
import { CommonApi } from '../../api/server';
import { CREATE_PROJECT } from '../../constants/forms';

interface OwnProps {
  url: string;
  name: string;
  fieldName: string;
  formName: string;
  controlName: string;
  onClick: () => void;
}
interface ReduxActions {
  uploadFiles: (files: File[], form: string, field: string) => void;
  downloadFile: (name: string, canceler: Canceler) => void;
  removeDownloadFileName: () => void;
  setName: (value: string) => void;
}

interface ReduxProps {
  hasAnyFile: boolean;
}

interface Props extends OwnProps, ReduxActions, ReduxProps { }

class DemoFileUploadButtonComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { name, controlName } = this.props;
    return (
      <div className='demo-file-upload-button'>
        <KreoButton
          caption={`Create ${name}`}
          onClick={this.onClick}
          size='medium'
          mode='action'
          icon={<KreoIconCreateNew />}
          rounded={true}
          controlName={controlName}
        />
      </div>
    );
  }

  @autobind
  private uploadFile(blob: Blob): void {
    const file = new File(
      [blob],
      `${this.props.name}.bim`,
      { type: blob.type, lastModified: Date.now() },
    );
    const files = [];
    files.push(file);
    this.props.setName(this.props.name);
    this.props.uploadFiles(files, this.props.formName, this.props.fieldName);
    this.props.removeDownloadFileName();
  }

  @autobind
  private onClick(): void {
    this.props.onClick();
    const cancelationTokenSource = Axios.CancelToken.source();
    CommonApi.getBlob(this.props.url, 'Error in demo file getting', cancelationTokenSource).then(this.uploadFile);
    this.props.downloadFile(this.props.name, cancelationTokenSource.cancel);
  }
}

function mapStateToProps(state: ReduxState): ReduxProps {
  return {
    hasAnyFile: state.common.files && !!state.common.files.length,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): ReduxActions {
  return {
    uploadFiles: (files, form, field) => dispatch(Common.uploadFiles(files, form, field)),
    downloadFile: (name, canceler) => dispatch(Common.downloadFile(name, canceler)),
    removeDownloadFileName: () => dispatch(Common.removeDownloadFileName()),
    setName: (value: string) => {
      dispatch(change(CREATE_PROJECT, 'name', value));
    },
  };
}

export const DemoFileUploadButton = connect(mapStateToProps, mapDispatchToProps)(
  DemoFileUploadButtonComponent,
);
