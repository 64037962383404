import * as React from 'react';

import './controls-3d.scss';

import {
  IconButton,
  KreoIconClipBoxFilter,
  KreoIconClipBoxNew,
  KreoIconFocus,
  KreoIconFullScreen,
  KreoIconFullScreenExit,
  KreoIconGhost,
  KreoIconHide,
  KreoIconHome,
  KreoIconIsolate,
  KreoIconIsolation,
  KreoIconIsometry,
  KreoIconPerspective,
  KreoIconRulerPoints,
  KreoIconShowHidden,
} from 'common/UIKit';
import { PropertiesPopupApi, PropertiesPopupButton } from '../../../units/projects/components/properties-popup-button';
import { PropertiesView } from '../../../units/projects/pages/model-check-view/layouts/properties-view';
import { ColorPickerControl } from '../color-picker-control';
import { EngineHotkeysHint, EngineHotkeysList, EngineHotkeysListItem } from '../engine-hotkeys-hint';
import { KreoToolbarButton } from '../kreo-toolbar/kreo-toolbar-button';


interface Props {
  color: string;
  isFullScreen: boolean;
  engineLayoutContainer: HTMLDivElement;
  isSelectedElement: boolean;
  showHideElementsEnabled?: boolean;
  clipBoxEnabled?: boolean;
  ghostEnabled?: boolean;
  rulerEnabled?: boolean;
  isometryEnabled?: boolean;
  isAutoFocus?: boolean;
  isImperialUnit?: boolean;
  isShowAllEnabled?: boolean;
  isShowAllOn?: boolean;
  isAnyInvisible?: boolean;
  specialAbilitiesHotkeyList?: EngineHotkeysListItem[];
  modelCheckProperties?: boolean;
  toggleFullScreen: () => void;
  onHome?: () => void;
  onIsolate?: () => void;
  onHide?: () => void;
  onShowHideElements?: () => void;
  onAssignColor?: () => void;
  onDefaultColor?: () => void;
  onChangeColor?: (color: string) => void;
  toggleClipBox?: () => void;
  toggleGhost?: () => void;
  toggleIsometry?: () => void;
  toggleRuler?: () => void;
  onFocus?: () => void;
  toggleAutoFocus?: () => void;
  toggleShowAll?: () => void;
  toggleMetricImperial?: () => void;
  onApplyClipboxToFilter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  savePropertiesPopupApi?: (api: PropertiesPopupApi) => void;
}

export class Controls3D extends React.Component<Props> {

  public render(): JSX.Element {
    return (
      <>
        <div className='controls-3d__left-group'>
          <KreoToolbarButton
            onClick={this.props.onHome}
            controlName='home-button'
            defaultTooltipText='Home'
            hotKey='Ctrl + F'
          >
            <KreoIconHome />
          </KreoToolbarButton>
          <KreoToolbarButton
            onClick={this.props.toggleClipBox}
            controlName='clip-box-button'
            active={this.props.clipBoxEnabled}
            defaultTooltipText='Clip Box'
            hotKey='C'
          >
            <div className='controls-3d__combine-button'>
              <KreoIconClipBoxNew />
              {this.props.onApplyClipboxToFilter && this.props.clipBoxEnabled && (
                <IconButton
                  onClick={this.props.onApplyClipboxToFilter}
                  controlName='apply-clipbox-button'
                  tooltip='Apply Clipbox To Filters'
                  rounded={false}
                  size='small'
                >
                  <KreoIconClipBoxFilter/>
                </IconButton>
              )}
            </div>
          </KreoToolbarButton>
          <KreoToolbarButton
            onClick={this.props.toggleGhost}
            controlName='ghost-button'
            active={!this.props.ghostEnabled}
            defaultTooltipText='Hide Ghost'
          >
            <KreoIconGhost />
          </KreoToolbarButton>
          <KreoToolbarButton
            onClick={this.props.toggleIsometry}
            controlName='isometry-button'
            defaultTooltipText={this.props.isometryEnabled ? 'Isometric' : 'Perspective'}
            hotKey='P'
          >
            {this.props.isometryEnabled ? <KreoIconIsometry /> : <KreoIconPerspective />}
          </KreoToolbarButton>
          {this.props.toggleAutoFocus && (
            <KreoToolbarButton
              onClick={this.props.toggleAutoFocus}
              controlName='auto-focus-button'
              active={this.props.isAutoFocus}
              defaultTooltipText='Auto Focus'
            >
              <KreoIconIsolation />
            </KreoToolbarButton>
          )}
          <KreoToolbarButton
            onClick={this.props.toggleRuler}
            controlName='ruler-button'
            active={this.props.rulerEnabled}
            defaultTooltipText='Ruler'
            hotKey='R'
          >
            <KreoIconRulerPoints />
          </KreoToolbarButton>
          {this.props.toggleMetricImperial && (
            <KreoToolbarButton
              onClick={this.props.toggleMetricImperial}
              controlName='unit-button'
              active={this.props.isImperialUnit}
              defaultTooltipText='Metric / Imperial'
              text={this.props.isImperialUnit ? 'Ft' : 'M'}
            />
          )}
          <KreoToolbarButton
            onClick={this.props.onFocus}
            controlName='focus-button'
            disabled={!this.props.isSelectedElement}
            disabledTooltipText='Select Element to unlock'
            defaultTooltipText='Focus on Element'
            hotKey='F'
          >
            <KreoIconFocus />
          </KreoToolbarButton>
          {this.props.toggleShowAll && this.props.isShowAllEnabled ? (
            <KreoToolbarButton
              onClick={this.props.toggleShowAll}
              controlName='show-all-button'
              active={this.props.isShowAllOn}
              disabled={!this.props.isShowAllEnabled}
              disabledTooltipText='Work in Isolated mode'
              defaultTooltipText='Show All Elements'
              text='Show All'
            />
          ) : (
            <>
              <KreoToolbarButton
                onClick={this.props.onIsolate}
                controlName='isolate-button'
                disabled={!this.props.isSelectedElement}
                disabledTooltipText='Select Element to unlock'
                defaultTooltipText='Selected Element to Isolate'
                hotKey='I'
              >
                <KreoIconIsolate />
              </KreoToolbarButton>
              <KreoToolbarButton
                onClick={this.props.onHide}
                controlName='hide-button'
                disabled={!this.props.isSelectedElement}
                disabledTooltipText='Select Element to unlock'
                defaultTooltipText='Selected Element to Hide'
                hotKey='H'
              >
                <KreoIconHide />
              </KreoToolbarButton>
              <KreoToolbarButton
                onClick={this.props.onShowHideElements}
                controlName='show-hide-elements-button'
                defaultTooltipText='Show Hidden Elements'
                disabledTooltipText='Hide Elements to unlock'
                disabled={!this.props.isAnyInvisible}
                hotKey='Ctrl + H'
              >
                <KreoIconShowHidden />
              </KreoToolbarButton>
            </>
          )}
          <ColorPickerControl
            containerRef={this.props.engineLayoutContainer}
            onChangeColor={this.props.onChangeColor}
            onAssignColor={this.props.onAssignColor}
            onDefaultColor={this.props.onDefaultColor}
            disabled={!this.props.isSelectedElement}
            color={this.props.color}
          />
        </div>
        <div className='controls-3d__right-group'>
          {this.props.savePropertiesPopupApi && (
            <PropertiesPopupButton
              sendApi={this.props.savePropertiesPopupApi}
              containerRef={this.props.engineLayoutContainer}
            />
          )}
          {this.props.modelCheckProperties && <PropertiesView containerRef={this.props.engineLayoutContainer} />}
          <EngineHotkeysHint
            specialAbilitiesHotkeyList={this.props.specialAbilitiesHotkeyList}
            containerRef={this.props.engineLayoutContainer}
            hotkeyList={EngineHotkeysList}
          />
          <KreoToolbarButton
            onClick={this.props.toggleFullScreen}
            controlName='full-screen-button'
            active={this.props.isFullScreen}
            defaultTooltipText='Full Screen'
          >
            {!this.props.isFullScreen ? <KreoIconFullScreen /> : <KreoIconFullScreenExit />}
          </KreoToolbarButton>
        </div>
      </>
    );
  }
}
