import * as React from 'react';

import { arrayUtils } from 'common/utils/array-utils';
import { PreparedSearchQuery } from 'common/utils/string-utils';
import { ToggleIcon, ToggleSize } from '../../../components/toggle';
import { FilterOption, FilterValue } from '../filter-select/interfaces';
import { FilterKeys } from './constants';

type OptionsMap = (options: string[], displayNames: Record<string, string>) => FilterValue[];

const mapStandardClassification: OptionsMap = (options: string[], displayNames: Record<string, string>) => {
  const filteredOptions = options
    .filter(option => option)
    .sort(arrayUtils.sortStringArrayWithEmptyValue)
    .map((value) => ({ name: displayNames[value], value }));

  return filteredOptions;
};

interface StandardClassificationValueProps {
  value: string;
}

const StandardClassificationValue = (props: StandardClassificationValueProps): JSX.Element => {
  const [code, ...name] = props.value.split('|')[0].split(' ');
  const isDefaultValue = props.value.startsWith('All ');

  return (
    <div className='filter-select-option__label'>
      {!isDefaultValue
        ? (
          <React.Fragment>
            <div className='filter-select-option__label-top-row'>
              {code}
            </div>
            <div className='filter-select-option__label-bottom-row'>
              {name.join(' ')}
            </div>
          </React.Fragment>
        )
        : props.value
      }
    </div>
  );
};

const renderStandardClassification =
  (option: FilterOption, _: PreparedSearchQuery, isSelected: boolean): React.ReactNode => {
    const value = option.value as string;
    const name = option.name as string;
    return (
      <div
        className='filter-select-option'
        data-control-name={`filter-option-${value}`}
        title={`${option.name}`}
      >
        <ToggleIcon
          checked={isSelected}
          checkedColor='rgba(255, 255, 255, .4)'
          size={ToggleSize.Small}
        />
        <StandardClassificationValue value={value || name} />
      </div>
    );
  };

const MasterFormatValue = (props: StandardClassificationValueProps): JSX.Element => {
  const [level1, level2, level3, ...name] = props.value.split('|')[0].split(' ');
  const isDefaultValue = props.value.startsWith('All ');

  return (
    <div className='filter-select-option__label'>
      {!isDefaultValue
        ? (
          <React.Fragment>
            <div className='filter-select-option__label-top-row'>
              {`${level1} ${level2 || ''} ${level3 || ''}`}
            </div>
            <div className='filter-select-option__label-bottom-row'>
              {name.join(' ')}
            </div>
          </React.Fragment>
        )
        : props.value
      }
    </div>
  );
};

const renderMasterFormat = (option: FilterOption, _: PreparedSearchQuery, isSelected: boolean): React.ReactNode => {
  const value = option.value as string;
  const name = option.name as string;
  return (
    <div
      className='filter-select-option'
      data-control-name={`filter-option-${value}`}
      title={`${option.name}`}
    >
      <ToggleIcon
        checked={isSelected}
        checkedColor='rgba(255, 255, 255, .4)'
        size={ToggleSize.Small}
      />
      <MasterFormatValue value={value || name} />
    </div>
  );
};

interface CustomOption {
  optionsMap: OptionsMap;
  optionsRender: (option: FilterOption, query: PreparedSearchQuery, isSelected: boolean) => React.ReactNode;
}

export const CustomOptionConfig: Record<string, CustomOption> = {
  [FilterKeys.F_UNI_SYS]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
  [FilterKeys.F_UNI_PROD]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
  [FilterKeys.F_NRM1]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
  [FilterKeys.F_NRM1_0_LEVEL]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
  [FilterKeys.F_NRM1_1_LEVEL]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
  [FilterKeys.F_NRM1_2_LEVEL]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
  [FilterKeys.F_NRM1_3_LEVEL]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
  [FilterKeys.F_NRM2]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
  [FilterKeys.F_NRM2_0_LEVEL]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
  [FilterKeys.F_MAS_FORM]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderMasterFormat,
  },
  [FilterKeys.F_UNI_FORM]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
  [FilterKeys.F_SMM7_CLF]: {
    optionsMap: mapStandardClassification,
    optionsRender: renderStandardClassification,
  },
};
