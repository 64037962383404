import { InputStyled } from '@kreo/kreo-ui-components';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { TRANSITION_POINT } from '../../../../constants';


interface StylesCheckBox {
  isCurrentAnswer: boolean;
  theme: { color: Record<string, string> };
}

function getCheckBoxStyle({ isCurrentAnswer, theme }: StylesCheckBox): FlattenSimpleInterpolation {
  if (isCurrentAnswer) {
    return css`
      > div {
        > div {
          border-color: ${theme.color.white};
          background-color: ${theme.color.turquoise};
          :hover div {
            background: ${theme.color.white};
          }
          > div {
            background: ${theme.color.white};
          }
        }
      }
    `;
  }
}

const ReplyContainer = styled.div<{ isCurrentAnswer: boolean }>`
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 15px;
  background-color: ${(p) =>
    p.isCurrentAnswer ? p.theme.color.turquoise : p.theme.color.backgroundRush};

  :hover {
    box-shadow: 0 0 0 2px ${(p) => p.theme.color.turquoise};
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    height: 40px;
  }
`;

const CheckBoxContainer = styled.div<StylesCheckBox>`
  padding-right: 15px;
  padding-left: 10px;

  ${p => getCheckBoxStyle(p)};

  > div {
    > div {
      > div {
        opacity: 1;
      }
    }
  }
`;

const TextContainer = styled.div`
  max-width: 275px;
  height: 20px;
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div<{ isCurrentAnswer: boolean }>`
  display: flex;
  position: relative;
  height: 40px;
  width: 100%;
  margin-right: 5px;

  ${InputStyled.InputContainer} {
    height: 40px;
    width: 100%;
    cursor: pointer;

    &:not(:focus-within):after {
      content: '';
      border: none;
    }
  }

  ${InputStyled.InputStyle} {
    padding-left: 0;
    background-color: ${(p) =>
    p.isCurrentAnswer
      ? p.theme.color.turquoise
      : p.theme.color.backgroundRush};

    &:focus {
      padding-left: 15px;
      background-color: ${(p) => p.theme.color.background};
    }
  }

  ${InputStyled.InputText} {
    left: 0;
    top: 11px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    font-size: 16px;
  }
`;

export const Styled = {
  ReplyContainer,
  CheckBoxContainer,
  TextContainer,
  InputWrapper,
};
