import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ClassificationAssignmentBody } from '../classification-assignment-body';
import { ClassificationOntologyTreeBody } from '../classification-ontology-tree-body';


interface Props {
  isUniclassEdit: boolean;
}

const ClassificationLeftSideEditComponent: React.FC<Props> = ({ isUniclassEdit }) => {
  return isUniclassEdit ? <ClassificationAssignmentBody/> : <ClassificationOntologyTreeBody isEdit={true}/>;
};

function mapStateToProps(state: State): Props {
  return {
    isUniclassEdit: state.classification.isUniclass,
  };
}

export const ClassificationLeftSideEdit = connect(mapStateToProps)(ClassificationLeftSideEditComponent);
