import * as paper from 'paper';

import { PdfRect } from 'common/components/drawings/interfaces/api-payloads';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { GeometryPreview, GeometryPreviewConfig } from './geometry-preview';

interface Config extends GeometryPreviewConfig<PdfRect> {
  color: DrawingsPaperColorInfo;
}

export class FinderPreviewRectangle extends GeometryPreview<PdfRect, Config> {

  public set color(color: DrawingsPaperColorInfo) {
    this._config.color = color;
    this._path.strokeColor = color.stroke;
    this._path.fillColor = color.fill;
  }

  protected render(geometry: PdfRect): void {
    const startPoint = new paper.Point(geometry.positionA);
    const [xA, yA] = geometry.positionA;
    const [xB, yB] = geometry.positionB;
    const size = new paper.Size(xB - xA, yB - yA);
    const rectangle = new paper.Rectangle(startPoint, size);
    this._path = new paper.Path.Rectangle(rectangle);
    this._path.strokeColor = this._config.color.stroke;
    this._path.fillColor = this._config.color.fill;
  }
}
