import { getNameById } from 'unit-2d-database/components/breakdown-property/utils';
import { IPropertyFormUpdater } from 'unit-2d-database/components/side-panel/helpers/update-property-form';
import { PropertiesTypeGuards } from '../../../../../../helpers/properties-typeguards';
import { BreakdownProperty, Property, PropertyTypeEnum, PropertyValue } from '../../../../../../interfaces';
import { Field } from '../../interfaces';
import { mapBreakdownPropertyToField } from '../map-breakdown-property-to-field';
import { FormulaFieldInput, mapFormulaPropertyToField } from '../map-formula-property-to-field';
import { mapNumberPropertyToField, NumberFieldInput } from '../map-number-property-to-field';
import { mapSingleSelectPropertyToField, SingleSelectFieldInput } from '../map-single-select-property-to-field';
import { mapTextPropertyToField, TextFieldInput } from '../map-text-property-to-field';

export type FieldInput = NumberFieldInput | TextFieldInput | FormulaFieldInput | SingleSelectFieldInput;

const mapPropertyToField = (
  property: Property,
  originProperty: Property,
  units: string[],
  updater: IPropertyFormUpdater,
  onFormulaFieldClick: (id: string, groupName: string) => void,
  onBreakdownFieldClick: (id: string, groupName: string) => void,
  getKey?: (property: Property) => string,
): Field<FieldInput> => {
  const key = getKey ? getKey(property) : property.id;
  const [
    onChange,
    onDeleteClick,
    onClickUnit,
    onVisibilityClick,
    onBlurUnit,
  ] = updater.getFieldHandlers(key, property.groupName);
  const propertyData = {
    ...property,
    id: key,
  };
  if (PropertiesTypeGuards.isNumber(propertyData)) {
    return mapNumberPropertyToField(
      propertyData,
      originProperty?.id,
      units,
      onChange,
      onDeleteClick,
      onClickUnit,
      onVisibilityClick,
      onBlurUnit,
    );
  } else if (PropertiesTypeGuards.isText(propertyData)) {
    return mapTextPropertyToField(
      propertyData,
      originProperty?.id,
      onChange,
      onDeleteClick,
      onVisibilityClick,
    );
  } else if (PropertiesTypeGuards.isFormula(propertyData)) {
    return mapFormulaPropertyToField(
      propertyData,
      originProperty?.id,
      units,
      () => onFormulaFieldClick(key, property.groupName),
      onDeleteClick,
      onClickUnit,
      onVisibilityClick,
      onBlurUnit,
    );
  } else if (PropertiesTypeGuards.isSingleSelect(propertyData)) {
    return mapSingleSelectPropertyToField(
      propertyData,
      originProperty as Property<PropertyValue<PropertyTypeEnum.SingleSelect>>,
      units,
      onChange,
      onDeleteClick,
      onClickUnit,
      onBlurUnit,
      onVisibilityClick,
    );
  } else if (PropertiesTypeGuards.isBreakdown(propertyData)) {
    const root = (originProperty as BreakdownProperty)
      ? (originProperty as BreakdownProperty).value.root
      : [];
    const selectedId = propertyData.value.value;
    const isNodeSelected =  selectedId && selectedId !== '00000000-0000-0000-0000-000000000000';
    let name = '';
    if (isNodeSelected) {
      name = getNameById(root, selectedId);
    }
    return mapBreakdownPropertyToField(
      propertyData,
      originProperty?.id,
      () => onBreakdownFieldClick(key, property.groupName),
      onDeleteClick,
      onVisibilityClick,
      name,
      root,
    );
  } else  {
    throw new Error('Invalid Property type');
  }
};

export const getFieldWithGroup = (
  property: Property,
  originProperty: Property,
  units: string[],
  updater: IPropertyFormUpdater,
  onFormulaFieldClick: (id: string, groupName: string) => void,
  onBreakdownFieldClick: (id: string, groupName: string) => void,
  getKey?: (property: Property) => string,
): [string, Field<FieldInput>] => {
  const field = mapPropertyToField(
    property,
    originProperty,
    units,
    updater,
    onFormulaFieldClick,
    onBreakdownFieldClick,
    getKey,
  );
  return [property.groupName, field];
};
