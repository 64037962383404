import styled from 'styled-components';

const LineContainer = styled.div`
  height: 10px;
  width: 100%;
  position: absolute;
`;

const Line = styled.div<{ hovering: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${p => p.hovering && p.theme.color.turquoiseDay};
  transition: ${p => p.theme.duration.s} ${p => p.theme.function.l};
`;

const OptionContainer = styled.div`
  position: relative;
  >div {
    margin-left: 0 !important;
  }
  >div[draggable] {
    margin-top: 4px;
  }
`;

export const Styled = {
  Line,
  LineContainer,
  OptionContainer,
};
