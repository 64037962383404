interface RangeHandle {
  isAttached: boolean;
  indexInLine: number | null;
  isAvailable: boolean;
  lineId: string | null;
  element: HTMLDivElement;
}

interface Range {
  indexInLine: number;
  needsRedraw: boolean;
  element: HTMLDivElement;
}

export class InternalRangesPool {
  private pool: Map<string, RangeHandle[]> = new Map();

  private lineRefProvider: (lineId: string) => HTMLDivElement = null;
  private elementsClassName: string;

  constructor(
    lineRefProvider: (lineId: string) => HTMLDivElement,
    elementsClassName: string,
  ) {
    this.lineRefProvider = lineRefProvider;
    this.elementsClassName = elementsClassName;
  }

  public markAllAsAvailable(): void {
    for (const bucket of this.pool.values()) {
      for (const node of bucket) {
        node.isAvailable = true;
      }
    }
  }

  public markLineElementsAsDetached(lineId: string): void {
    const bucket = this.getBucket(lineId);
    for (const node of bucket) {
      node.isAttached = false;
      node.indexInLine = null;
    }
  }

  public getNodes(neededRanges: Map<string, number[]>, scaleChanged: boolean): Map<string, Range[]> {
    const unsatisfiedYetRequests = new Map<string, number[]>(neededRanges);
    const result = new Map<string, Range[]>();

    // initialize results
    for (const lineId of neededRanges.keys()) {
      result.set(lineId, []);
    }

    if (!scaleChanged) {
      // collect nodes that are attached to right lines and are still in viewport
      // no point in doing this if scale has changed
      this.collectNodes(
        unsatisfiedYetRequests,
        result,
        false,
        false,
        (lineId: string, indexInLine: number) =>
          this.getBucket(lineId).find((h) => h.indexInLine === indexInLine && h.isAvailable && h.isAttached),
      );
    }

    // collect nodes that are attached to right lines, but left viewport
    this.collectNodes(
      unsatisfiedYetRequests,
      result,
      true,
      false,
      (lineId: string, _indexInLine: number) =>
        this.getBucket(lineId).find((h) => h.isAvailable && h.isAttached),
    );

    // collect nodes that are attached to other lines
    this.collectNodes(
      unsatisfiedYetRequests,
      result,
      true,
      true,
      (_lineId: string, _indexInLine: number) => {
        for (const bucket of this.pool.values()) {
          for (const handle of bucket) {
            if (handle.isAvailable && handle.isAttached) {
              return handle;
            }
          }
        }

        return null;
      },
    );

    // collect existing detached nodes
    this.collectNodes(
      unsatisfiedYetRequests,
      result,
      true,
      true,
      (_lineId: string, _indexInLine: number) => {
        for (const bucket of this.pool.values()) {
          for (const handle of bucket) {
            if (handle.isAvailable) {
              return handle;
            }
          }
        }

        return null;
      },
    );

    // create new nodes
    for (const lineRequest of unsatisfiedYetRequests) {
      const lineId = lineRequest[0];
      const requestedIndexes = lineRequest[1];

      for (const indexInLine of requestedIndexes) {
        const element = document.createElement('div');
        element.className = this.elementsClassName;

        const handle: RangeHandle = {
          isAttached: false,
          lineId: null,
          indexInLine,
          isAvailable: false,
          element,
        };

        this.attachRange(handle, lineId);

        result.get(lineId).push({
          indexInLine,
          needsRedraw: true,
          element: handle.element,
        });
      }
    }

    if (process.env.NODE_ENV === 'development') {
      for (const request of neededRanges) {
        const lineId = request[0];
        const ranges = request[1];
        const poolHasEnoughElementsInLine = this.getBucket(lineId).length >= ranges.length;
        console.assert(
          poolHasEnoughElementsInLine,
          'Pool has not enough elements in line to serve request',
          lineId,
          ranges,
          neededRanges.get(lineId),
        );
      }

      for (const line of result) {
        const lineId = line[0];
        const providedRanges = line[1];
        const lineRequestedRanges = neededRanges.get(lineId);

        const poolProvidedEnoughElements = providedRanges.length === lineRequestedRanges.length;
        console.assert(
          poolProvidedEnoughElements,
          'Pool provided less elements than was requested for line',
          lineId,
          providedRanges,
          lineRequestedRanges,
        );

        const poolProvidedRightElements = lineRequestedRanges.every(
          (requested) => providedRanges.some((provided) => provided.indexInLine === requested));
        console.assert(
          poolProvidedRightElements,
          'Pool provided elements with wrong ids',
          lineId,
          providedRanges,
          lineRequestedRanges,
        );
      }
    }

    return result;
  }

  public detachAvailableElements(): void {
    for (const bucket of this.pool.values()) {
      for (const handle of bucket) {
        if (handle.isAvailable && handle.isAttached) {
          const lineRef = this.lineRefProvider(handle.lineId);
          if (lineRef) {
            lineRef.removeChild(handle.element);
          }

          handle.isAttached = false;
          handle.indexInLine = null;
        }
      }
    }
  }

  private getBucket(lineId: string): RangeHandle[] {
    if (!this.pool.has(lineId)) {
      this.pool.set(lineId, []);
    }

    return this.pool.get(lineId);
  }

  private attachRange(handle: RangeHandle, newLineId: string): void {
    if (handle.lineId) {
      const oldBucket = this.getBucket(handle.lineId);
      oldBucket.splice(oldBucket.indexOf(handle), 1);
    }

    if (handle.isAttached) {
      const oldLineRef = this.lineRefProvider(handle.lineId);
      if (oldLineRef) {
        oldLineRef.removeChild(handle.element);
      }
    }

    const newLineRef = this.lineRefProvider(newLineId);
    newLineRef.appendChild(handle.element);
    this.getBucket(newLineId).push(handle);
    handle.lineId = newLineId;
    handle.isAttached = true;
  }

  private collectNodes(
    requests: Map<string, number[]>,
    result: Map<string, Range[]>,
    needsRedraw: boolean,
    shouldReattach: boolean,
    findNode: (lineId: string, indexInLine: number) => RangeHandle | null,
  ): void {
    for (const lineRequest of requests) {
      const notFoundRanges: number[] = [];

      const lineId = lineRequest[0];
      const requestedIndexes = lineRequest[1];
      for (const indexInLine of requestedIndexes) {
        const handle = findNode(lineId, indexInLine);
        if (handle) {
          handle.isAvailable = false;
          handle.indexInLine = indexInLine;

          if (shouldReattach) {
            this.attachRange(handle, lineId);
          }
          result.get(lineId).push({
            indexInLine,
            needsRedraw,
            element: handle.element,
          });
        } else {
          notFoundRanges.push(indexInLine);
        }
      }

      if (notFoundRanges.length > 0) {
        requests.set(lineId, notFoundRanges);
      } else {
        requests.delete(lineId);
      }
    }
  }
}
