import autobind from 'autobind-decorator';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { EngineObjectConfig } from '../../common';
import { DestroyableObject } from '../../common/destroyable-object';
import {
  DrawingsDragEntityEventHandler,
  DrawingsGeometrySelectionBoundingRect,
} from '../../drawings-geometry-entities';
import { EditPermissions, RotationProcessor } from '../../interfaces';
import { DrawingsGeometryDragEventHelper } from '../drawings-geometry-drag-event-helper';
import { DrawingsCursorTypeHelper } from './cursor-type-helper';

interface Config extends EngineObjectConfig {
  renderParamsContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
  editPermissionsObserver: ContextObserver<EditPermissions>;
  onDragEntity: DrawingsDragEntityEventHandler;
  cursorHelper: DrawingsCursorTypeHelper;
  pointsLayer: paper.Layer;
  getRotationProcessor: () => RotationProcessor;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
}

export class BoundingRectHelper extends DestroyableObject<Config> {

  private _selectionRect: DrawingsGeometrySelectionBoundingRect;

  public destroy(): void {
    if (this._selectionRect) {
      this._selectionRect.destroy();
      this._selectionRect = null;
    }
  }

  public get selectionRect(): DrawingsGeometrySelectionBoundingRect {
    return this._selectionRect;
  }

  public get rectPosition(): paper.Point {
    return this._selectionRect.position;
  }

  public renderByBounds(bounds: paper.Rectangle): void {
    this._selectionRect = new DrawingsGeometrySelectionBoundingRect(
      {
        geometry: bounds,
        renderParamsContextObserver: this._config.renderParamsContextObserver,
        pointParams: {
          textParamsObserver: this._config.textRenderParamsObserver,
          onStartDrag: this.startDragByPoint,
          cursorHelper: this._config.cursorHelper,
          layer: this._config.pointsLayer,
        },
        rotationProcessor: this._config.getRotationProcessor(),
        editPermissionsObserver: this._config.editPermissionsObserver,
      },
    );
    this._selectionRect.pointVisibility = this._config.dragEventsHelper.canDrag;
  }

  public updateRectPosition(positionDiff: paper.Point): void {
    if (this._selectionRect) {
      this._selectionRect.position = this._selectionRect.position.add(positionDiff);
    }
  }

  public updatePointVisibility(): void {
    if (this._selectionRect) {
      this._selectionRect.pointVisibility = this._config.dragEventsHelper.canDrag;
    }
  }

  @autobind
  private startDragByPoint(id: string, e: PaperMouseEvent): void {
    this._config.onDragEntity(id, e, true);
  }
}
