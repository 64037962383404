import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { KreoButton, KreoDialogActions, KreoIconAdmin, MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { AppUrls } from 'routes/app-urls';
import { RevisionInfoModel } from 'unit-cost-estimate/interfaces';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';
import { RevisionSettingDialog, REVISION_SETTING_DIALOG } from './revision-setting-dialog';
import { Styled } from './styled';

interface DispatchProps {
  openDialog: (dialogName: string) => void;
  selectRevision: (projectId, id: number) => void;
  goTo: (path: string) => void;
}

interface StateProps {
  revisionInfo: RevisionInfoModel[];
  selectedRevisionId: number;
  projectId: number;
}

interface Props extends StateProps, DispatchProps {}


export class RevisionHeaderComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { selectedRevisionId, revisionInfo } =this.props;
    if (!selectedRevisionId || !revisionInfo) {
      return null;
    }

    return (
      <Styled.Container>
        <KreoButton
          onClick={this.openSettingDialog}
          size='small'
        >
          <KreoIconAdmin />
        </KreoButton>
        <MaterialSelect
          className='material-select__content'
          dropdownClassName='companies-select__paper'
          autoWidth={true}
          value={selectedRevisionId}
          onChange={this.selectRevision}
          displayedType={MaterialComponentType.Native}
          placeholder='Revision'
        >
          {revisionInfo.map((revision) => (
            <MaterialMenuItem className='companies-select__item' key={revision.id} value={revision.id}>
              {revision.name}
            </MaterialMenuItem>
          ))}
        </MaterialSelect>
        <RevisionSettingDialog />
      </Styled.Container>
    );
  }

  @autobind
  private selectRevision(_: React.SyntheticEvent, id: number): void {
    const { projectId, selectRevision, goTo } = this.props;
    selectRevision(projectId, id);
    goTo(AppUrls.costEstimate.project.revision.index.url({
      projectId: projectId.toString(),
      revisionId: id.toString(),
    }));
  }

  @autobind
  private openSettingDialog(): void {
    this.props.openDialog(REVISION_SETTING_DIALOG);
  }
}

const mapStateToProps = (state: State): StateProps => {
  const currentProject = state.projects.currentProject;
  const currentProjectId = currentProject && currentProject.id;
  const projectIdToRevisionId = state.persistedStorage.projectIdToRevisionId;
  const selectedRevisionId = projectIdToRevisionId && projectIdToRevisionId[currentProjectId];

  return {
    selectedRevisionId,
    projectId: currentProjectId,
    revisionInfo: state.projects.currentProject.revisions,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openDialog: name => dispatch(KreoDialogActions.openDialog(name)),
    selectRevision: (projectId, id) =>
      dispatch(PersistedStorageActions.persistSelectedProjectRevisionId(projectId, id)),
    goTo: path => dispatch(push(path)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const RevisionHeader = connector(RevisionHeaderComponent);
