import autobind from 'autobind-decorator';
import React from 'react';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { Styled } from './styled';


interface Props {
  renderMenuContainer: () => React.ReactNode;
  showHoverMenuLeft?: boolean;
  delayHoverMenu?: number;
}

interface State {
  position: { x: number, y: number };
}

export class HoverMenuInteractive extends React.PureComponent<Props, State> {
  private leaveExecutor: DeferredExecutor = new DeferredExecutor(
    (this.props.delayHoverMenu || this.props.delayHoverMenu === 0)
      ? this.props.delayHoverMenu
      : 1000,
  );

  constructor(props: Props) {
    super(props);
    this.state = {
      position: null,
    };
  }

  public render(): React.ReactNode {
    const { children, renderMenuContainer } = this.props;
    const { position } = this.state;
    return (
      <div onMouseLeave={this.onMouseLeave}>
        <div onMouseOver={this.onMouseOver}>
          {children}
        </div>
        {
          position ? (
            <Styled.MenuContainer
              top={position.y}
              left={position.x}
              onMouseOver={this.removeLeave}
            >
              {renderMenuContainer()}
            </Styled.MenuContainer>
          ) : null
        }
      </div>
    );
  }

  @autobind
  private onMouseOver(e: React.MouseEvent<HTMLDivElement>): void {
    const halfMenuWidth = 170;
    let x;
    let y;
    this.leaveExecutor.reset();
    const buttonRect = e.currentTarget.getBoundingClientRect();

    if (this.props.showHoverMenuLeft) {
      x = buttonRect.left - halfMenuWidth;
      y = buttonRect.bottom - 15;
    } else {
      x = buttonRect.left;
      y = buttonRect.bottom + 15;
    }

    this.setState({ position: { x, y } });
  }

  @autobind
  private removeLeave(): void {
    this.leaveExecutor.reset();
  }

  @autobind
  private onMouseLeave(): void {
    this.leaveExecutor.execute(() => this.setState({ position: null }));
  }
}
