import styled from 'styled-components';

const ResultTitle = styled.div`
  position: relative;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-bottom: 5px;
`;

const Header = styled.div`
`;

const Container = styled.div`
  position: absolute;
  left: 0px;
  padding: 10px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.color.backgroundRush};
  border-radius: 10px;
  z-index: 1000;
  width: 170px;
  pointer-events: all;
`;

export const Styled = {
  ResultTitle,
  Header,
  Container,
};
