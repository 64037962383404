import * as React from 'react';

export const KreoIconTopProjects: React.FC = () => {
  return (
    <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='icon_databases' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect id='Rectangle' x='0' y='0' width='20' height='20' />
        <path
          // eslint-disable-next-line max-len
          d='M12,16 L15,16 L15,9 C15,8.44771525 14.5522847,8 14,8 L12,8 L12,10 L13.5,10 C13.7761424,10 14,10.2238576 14,10.5 C14,10.7761424 13.7761424,11 13.5,11 L12,11 L12,13 L13.5,13 C13.7761424,13 14,13.2238576 14,13.5 C14,13.7761424 13.7761424,14 13.5,14 L12,14 L12,16 Z M17,16 L17.5,16 C18.0522847,16 18.5,16.4477153 18.5,17 C18.5,17.5522847 18.0522847,18 17.5,18 L2.5,18 C1.94771525,18 1.5,17.5522847 1.5,17 C1.5,16.4477153 1.94771525,16 2.5,16 L3,16 L3,5 C3,3.34314575 4.34314575,2 6,2 L9,2 C10.6568542,2 12,3.34314575 12,5 L12,6 L14,6 C15.6568542,6 17,7.34314575 17,9 L17,16 Z M10,16 L10,5 C10,4.44771525 9.55228475,4 9,4 L6,4 C5.44771525,4 5,4.44771525 5,5 L5,16 L10,16 Z M6.5,8 C6.22385763,8 6,7.77614237 6,7.5 C6,7.22385763 6.22385763,7 6.5,7 L8.5,7 C8.77614237,7 9,7.22385763 9,7.5 C9,7.77614237 8.77614237,8 8.5,8 L6.5,8 Z M6.5,11 C6.22385763,11 6,10.7761424 6,10.5 C6,10.2238576 6.22385763,10 6.5,10 L8.5,10 C8.77614237,10 9,10.2238576 9,10.5 C9,10.7761424 8.77614237,11 8.5,11 L6.5,11 Z'
          id='icon'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
