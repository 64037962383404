import styled, { FlattenSimpleInterpolation } from 'styled-components';


const Container = styled.div<{ styled?: FlattenSimpleInterpolation }>`
  position: absolute;
  z-index: 1002;
  overflow: auto;
  ${p => p.styled};
`;

const Blank = styled.div`
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  cursor: default;
  position: absolute;
  z-index: 1001;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Styled = {
  Container,
  Blank,
  ButtonContainer,
};
