import { Text } from '@kreo/kreo-ui-components';
import React from 'react';
import { Styled } from './styled';

interface Props {
  name: string;
  icon: React.ComponentType;
}

const NameBadgeComponent: React.FC<Props> = ({
  name,
  icon,
}) => {
  const Icon = icon;
  return (
    <Styled.NameBadge>
      <Text
        fontSize={14}
        lineHeight={16}
      >
        {name}
      </Text>
      <Icon/>
    </Styled.NameBadge>
  );
};


export const NameBadge = React.memo(NameBadgeComponent);
