
import { Action } from 'redux';

import { SubscriptionType } from 'common/constants/subscription';
import { RequestStatus } from 'common/enums/request-status';
import { UserPermission } from 'common/enums/user-permission';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { ActionWith } from 'common/interfaces/action-with';
import { UpdateRolePermissions } from '../../../account/interfaces/update-role-permissions';
import { EstimateSubscriptionError } from '../../interfaces';
import { BillingCollection } from '../../interfaces/billing-collection';
import { BillingCountryModel } from '../../interfaces/billing-country-model';
import { CompanyBillingInfoModel } from '../../interfaces/company-billing-info-model';
import { CreateBillingCardForm } from '../../interfaces/create-billing-card-form';
import { CreateSubscriptionForm } from '../../interfaces/create-subscription-form';
import { EstimateSubscriptionForm } from '../../interfaces/estimate-subscription-form';
import { EstimateSubscriptionModel } from '../../interfaces/estimation-subscription-model';
import { PaymentSourcesModel } from '../../interfaces/payment-sources-model';
import { SubscriptionPlanListingModel } from '../../interfaces/subscription-plan-listing-model';
import { UpdateSubscriptionForm } from '../../interfaces/update-subscription-form';
import { CreateSubscriptionEstimationPayload, FormWithSubscriptionId } from '../payloads';
import { CurrencyExchangeActionTypes, PaymentMethodActionTypes, SubscriptionActionTypes } from '../types';

function setSubscriptionType(subscriptionType: SubscriptionType): ActionWith<SubscriptionType> {
  return {
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_TYPE,
    payload: subscriptionType,
  };
}

function setSelectedCompany(companyId: number): ActionWith<number> {
  return {
    type: SubscriptionActionTypes.SET_SELECTED_COMPANY,
    payload: companyId,
  };
}

function loadSubscriptionRoles(): Action {
  return {
    type: SubscriptionActionTypes.LOAD_SUBSCRIPTION_ROLES,
  };
}

function loadSubscriptionRolesSucceeded(roles: CompanyRoles): ActionWith<CompanyRoles> {
  return {
    type: SubscriptionActionTypes.LOAD_SUBSCRIPTION_ROLES_SUCCEEDED,
    payload: roles,
  };
}

function updateSubscriptionPermissionRoles(
  rolesPermissions: UpdateRolePermissions[],
): ActionWith<UpdateRolePermissions[]> {
  return {
    type: SubscriptionActionTypes.UPDATE_SUBSCRIPTION_PERMISSION_ROLES_REQUEST,
    payload: rolesPermissions,
  };
}

function updateSubscriptionPermissionRolesSucceeded(
  rolesPermissions: Record<number, UserPermission[]>,
): ActionWith<Record<number, UserPermission[]>> {
  return {
    type: SubscriptionActionTypes.UPDATE_SUBSCRIPTION_PERMISSION_ROLES_SUCCEEDED,
    payload: rolesPermissions,
  };
}

function updateSubscriptionRoles(): Action {
  return {
    type: SubscriptionActionTypes.UPDATE_SUBSCRIPTION_ROLES_REQUEST,
  };
}

function updateSubscriptionRolesSucceeded(): Action {
  return {
    type: SubscriptionActionTypes.UPDATE_SUBSCRIPTION_ROLES_SUCCEEDED,
  };
}


function getSubscriptionPlansRequest(subscriptionType: SubscriptionType): ActionWith<SubscriptionType> {
  return {
    type: SubscriptionActionTypes.GET_SUBSCRIPTION_PLANS_REQUEST,
    payload: subscriptionType,
  };
}

function getSubscriptionPlansSucceeded(
  plans: SubscriptionPlanListingModel,
  hasMonthBillingPeriod: boolean,
  hasYearBillingPeriod: boolean,
): ActionWith<{
  plans: SubscriptionPlanListingModel,
  hasMonthBillingPeriod: boolean,
  hasYearBillingPeriod: boolean,
}> {
  return {
    type: SubscriptionActionTypes.GET_SUBSCRIPTION_PLANS_SUCCEEDED,
    payload: { plans, hasMonthBillingPeriod, hasYearBillingPeriod },
  };
}

function getSubscriptionPlansFailed(): Action {
  return {
    type: SubscriptionActionTypes.GET_SUBSCRIPTION_PLANS_FAILED,
  };
}

function createSubscription(form: CreateSubscriptionForm): ActionWith<CreateSubscriptionForm> {
  return {
    type: SubscriptionActionTypes.CREATE_SUBSCRIPTION,
    payload: form,
  };
}

function createFreeSubscription(form: {
    planId: string,
    subscriptionType: string,
 }): ActionWith<{ planId: string, subscriptionType: string }> {
  return {
    type: SubscriptionActionTypes.CREATE_FREE_SUBSCRIPTION,
    payload: form,
  };
}

function updateSubscription(
  subscriptionId: string,
  form: UpdateSubscriptionForm,
  afterApply?: (id: string) => void,
): ActionWith<FormWithSubscriptionId<UpdateSubscriptionForm>> {
  return {
    type: SubscriptionActionTypes.UPDATE_SUBSCRIPTION,
    payload: { form, subscriptionId, afterApply },
  };
}

function upgradeSubscription(
  subscriptionId: string,
  form: UpdateSubscriptionForm,
  cardId:  string,
): ActionWith<FormWithSubscriptionId<UpdateSubscriptionForm & { cardId: string }>> {
  return {
    type: SubscriptionActionTypes.UPGRADE_SUBSCRIPTION,
    payload: { form: { ...form, cardId }, subscriptionId },
  };
}

function cancelSubscription(id: string): ActionWith<string> {
  return {
    type: SubscriptionActionTypes.CANCEL_SUBSCRIPTION,
    payload: id,
  };
}

function prolongateTrial(id: string): ActionWith<string> {
  return {
    type: SubscriptionActionTypes.PROPLONGARE_TRIAL,
    payload: id,
  };
}

function pauseSubscription(id: string): ActionWith<string> {
  return {
    type: SubscriptionActionTypes.PAUSE_SUBSCRIPTION,
    payload: id,
  };
}


function renewSubscription(id: string): ActionWith<string> {
  return {
    type: SubscriptionActionTypes.RENEW_SUBSCRIPTION,
    payload: id,
  };
}

function resumeSubscription(id: string): ActionWith<string> {
  return {
    type: SubscriptionActionTypes.RESUME_SUBSCRIPTION,
    payload: id,
  };
}

function getUpdateSubscriptionEstimation(
  subscriptionId: string,
  form: EstimateSubscriptionForm,
): ActionWith<FormWithSubscriptionId<EstimateSubscriptionForm>> {
  return {
    type: SubscriptionActionTypes.GET_UPDATE_SUBSCRIPTION_ESTIMATION,
    payload: { form, subscriptionId },
  };
}

function getCreateSubscriptionEstimation(
  subscriptionType: SubscriptionType,
  form: EstimateSubscriptionForm,
): ActionWith<CreateSubscriptionEstimationPayload> {
  return {
    type: SubscriptionActionTypes.GET_CREATE_SUBSCRIPTION_ESTIMATION,
    payload: { form, subscriptionType },
  };
}


function setSubscriptionEstimation(model: EstimateSubscriptionModel): ActionWith<EstimateSubscriptionModel> {
  return {
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_ESTIMATION,
    payload: model,
  };
}

function setSubscriptionEstimationError(error: EstimateSubscriptionError): ActionWith<EstimateSubscriptionError> {
  return {
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_ESTIMATION_ERROR,
    payload: error,
  };
}

function setSubscriptionPaymentMethod(
  subscriptionId: string,
  paymentMethodId: string,
): ActionWith<FormWithSubscriptionId<string>> {
  return {
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_PAYMENT_METHOD_REQUEST,
    payload: {
      subscriptionId,
      form: paymentMethodId,
    },
  };
}

function fetchOwnCompanyBillingInfo(subscriptionType: SubscriptionType): ActionWith<SubscriptionType> {
  return {
    type: SubscriptionActionTypes.FETCH_OWN_COMPANY_BILLING_INFO_REQUEST,
    payload: subscriptionType,
  };
}

function fetchOwnCompanyBillingInfoSucceeded(model: CompanyBillingInfoModel): ActionWith<CompanyBillingInfoModel> {
  return {
    type: SubscriptionActionTypes.FETCH_OWN_COMPANY_BILLING_INFO_SUCCEEDED,
    payload: model,
  };
}

function fetchOwnCompanyBillingInfoFailed(): Action {
  return {
    type: SubscriptionActionTypes.FETCH_OWN_COMPANY_BILLING_INFO_FAILED,
  };
}

function dropSubscriptionPlansState(): Action {
  return {
    type: SubscriptionActionTypes.DROP_SUBSCRIPTION_PLANS_STATE,
  };
}

function dropOwnCompanyBillingInfoState(): Action {
  return {
    type: SubscriptionActionTypes.DROP_OWN_COMPANY_BILLING_INFO_STATE,
  };
}

function startUpdateSubscription(): Action {
  return {
    type: SubscriptionActionTypes.START_UPDATE_SUBSCRIPTION,
  };
}

function endUpdateSubscription(): Action {
  return {
    type: SubscriptionActionTypes.END_UPDATE_SUBSCRIPTION,
  };
}

export const SubscriptionActions = {
  setSubscriptionType,
  setSelectedCompany,
  loadSubscriptionRoles,
  loadSubscriptionRolesSucceeded,
  updateSubscriptionPermissionRoles,
  updateSubscriptionPermissionRolesSucceeded,
  updateSubscriptionRoles,
  updateSubscriptionRolesSucceeded,

  getSubscriptionPlansRequest,
  getSubscriptionPlansSucceeded,
  getSubscriptionPlansFailed,

  createSubscription,
  updateSubscription,
  cancelSubscription,
  pauseSubscription,
  renewSubscription,
  resumeSubscription,

  getUpdateSubscriptionEstimation,
  getCreateSubscriptionEstimation,
  setSubscriptionEstimationError,
  setSubscriptionEstimation,

  setSubscriptionPaymentMethod,

  fetchOwnCompanyBillingInfo,
  fetchOwnCompanyBillingInfoSucceeded,
  fetchOwnCompanyBillingInfoFailed,

  dropSubscriptionPlansState,
  dropOwnCompanyBillingInfoState,

  createFreeSubscription,

  upgradeSubscription,

  startUpdateSubscription,
  endUpdateSubscription,

  prolongateTrial,
};

function setPrimaryCard(id: string): ActionWith<string> {
  return {
    type: PaymentMethodActionTypes.SET_PRIMARY_CARD,
    payload: id,
  };
}

function setPrimaryCardSucceeded(model: PaymentSourcesModel): ActionWith<PaymentSourcesModel> {
  return {
    type: PaymentMethodActionTypes.SET_PRIMARY_CARD_SUCCEEDED,
    payload: model,
  };
}

function setPrimaryCardFailed(): Action {
  return {
    type: PaymentMethodActionTypes.SET_PRIMARY_CARD_FAILED,
  };
}


function setBackupCard(id: string): ActionWith<string> {
  return {
    type: PaymentMethodActionTypes.SET_BACKUP_CARD,
    payload: id,
  };
}

function setBackupCardSucceeded(model: PaymentSourcesModel): ActionWith<PaymentSourcesModel> {
  return {
    type: PaymentMethodActionTypes.SET_BACKUP_CARD_SUCCEEDED,
    payload: model,
  };
}

function setBackupCardFailed(): Action {
  return {
    type: PaymentMethodActionTypes.SET_BACKUP_CARD_FAILED,
  };
}


function getPaymentData(): Action {
  return {
    type: PaymentMethodActionTypes.GET_PAYMENT_DATA,
  };
}

function getPaymentMethods(force?: boolean): ActionWith<boolean> {
  return {
    type: PaymentMethodActionTypes.GET_PAYMENT_METHODS,
    payload: force,
  };
}

function getPaymentDataFailed(): Action {
  return {
    type: PaymentMethodActionTypes.GET_PAYMENT_DATA_FAILED,
  };
}

function getPaymentMethodsSucceeded(model: PaymentSourcesModel): ActionWith<PaymentSourcesModel> {
  return {
    type: PaymentMethodActionTypes.GET_PAYMENT_METHODS_SUCCEEDED,
    payload: model,
  };
}

function setAvailableBillingCountries(billingCountries: BillingCountryModel[]): ActionWith<BillingCountryModel[]> {
  return {
    type: PaymentMethodActionTypes.SET_AVAILABLE_BILLING_COUNTRIES,
    payload: billingCountries,
  };
}

function removeCard(id: string): ActionWith<string> {
  return {
    type: PaymentMethodActionTypes.REMOVE_CARD,
    payload: id,
  };
}

function removeCardSucceeded(model: PaymentSourcesModel): ActionWith<PaymentSourcesModel> {
  return {
    type: PaymentMethodActionTypes.REMOVE_CARD_SUCCEEDED,
    payload: model,
  };
}

function removeCardFailed(): Action {
  return {
    type: PaymentMethodActionTypes.REMOVE_CARD_FAILED,
  };
}

function createCardLegacy(card: CreateBillingCardForm): ActionWith<CreateBillingCardForm> {
  return {
    type: PaymentMethodActionTypes.CREATE_CARD_LEGACY,
    payload: card,
  };
}

function createCardSucceeded(model: PaymentSourcesModel): ActionWith<PaymentSourcesModel> {
  return {
    type: PaymentMethodActionTypes.CREATE_CARD_SUCCEEDED,
    payload: model,
  };
}

function createCardFailed(): Action {
  return {
    type: PaymentMethodActionTypes.CREATE_CARD_FAILED,
  };
}

function dropPaymentState(): Action {
  return {
    type: PaymentMethodActionTypes.DROP_PAYMENT_STATE,
  };
}

function getBillingHistory(payload: { limit: number, offset: string }): ActionWith<{ limit: number, offset: string }> {
  return {
    type: PaymentMethodActionTypes.GET_BILLING_HISTORY,
    payload,
  };
}

function fetchBillingHistory(
  payload: { billingCollection: BillingCollection, append: boolean },
): ActionWith<{ billingCollection: BillingCollection, append: boolean }> {
  return {
    type: PaymentMethodActionTypes.FETCH_BILLING_HISTORY,
    payload,
  };
}

function setBillingHistoryStatus(status: RequestStatus): ActionWith<RequestStatus> {
  return {
    type: PaymentMethodActionTypes.SET_BILLING_HISTORY_STATUS,
    payload: status,
  };
}

function getInvoicesPdf(id: string): ActionWith<string> {
  return {
    type: PaymentMethodActionTypes.GET_INVOICES_PDF,
    payload: id,
  };
}

export const PaymentMethodActions = {
  setPrimaryCard,
  setPrimaryCardSucceeded,
  setPrimaryCardFailed,

  setBackupCard,
  setBackupCardSucceeded,
  setBackupCardFailed,


  getPaymentMethods,
  getPaymentMethodsSucceeded,

  getPaymentData,
  getPaymentDataFailed,

  setAvailableBillingCountries,

  removeCard,
  removeCardSucceeded,
  removeCardFailed,

  createCardLegacy,
  createCardSucceeded,
  createCardFailed,

  dropPaymentState,

  getBillingHistory,
  fetchBillingHistory,
  setBillingHistoryStatus,
  getInvoicesPdf,
};

function fetchCurrencyExchange(): Action {
  return {
    type: CurrencyExchangeActionTypes.FETCH_CURRENCY_EXCHANGE,
  };
}

function setCurrencyRate(payload: Record<string, number>): ActionWith<Record<string, number>> {
  return {
    type: CurrencyExchangeActionTypes.SET_CURRENCY_RATE,
    payload,
  };
}

export const CurrencyExchangeActions = {
  fetchCurrencyExchange,
  setCurrencyRate,
};
