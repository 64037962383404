import React from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ProgressBarState, ProgressState } from '../../interfaces';
import { Loader } from '../loader';

interface Props {
  progressKey: string;
  customProgressTextGetter?: (progressBar: ProgressBarState) => string;
}

const LoadersListComponent: React.FC<Props> = (
  {
    progressKey,
    customProgressTextGetter,
  }: Props,
) => {
  const progress = useSelector<State, ProgressState>(x => x.progress[progressKey]);
  if (!progress) {
    return null;
  }
  return (
    <>
      {
        progress.progressBars.map(
          (loader) => {
            return (
              <Loader
                key={loader.title}
                progressBar={loader}
                customProgressTextGetter={customProgressTextGetter}
              />
            );
          },
        )
      }
    </>
  );
};

export const LoadersList = React.memo(LoadersListComponent);
