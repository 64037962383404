import { AnyAction } from 'redux';

import { MODEL_CHECK_INITIAL_STATE } from '../units/projects/interfaces/model-check/initial-state';
import { ModelCheckState } from '../units/projects/pages/model-check-view/interfaces/model-check-state';
import { modelCheckerReducerMethods } from '../units/projects/pages/model-check-view/reducer-methods';


export default function(
  state: ModelCheckState = MODEL_CHECK_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): ModelCheckState {
  if (action.type in modelCheckerReducerMethods) {
    return modelCheckerReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
