import { SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { DrawingsUpdateActions } from '../actions/creators/update';
import { UpdateVisibilityPayload } from '../actions/payloads/instances-visibility';
import { DrawingsElementChanges } from '../actions/payloads/update';
import { DrawingsInstancesVisibilityActionTypes } from '../actions/types/instances-visibility';
import { DrawingsInstancesUpdateType } from '../enums';
import { DrawingChangeOperation } from '../enums/drawing-change-operation';
import { DrawingChangeSource } from '../enums/drawing-change-source';
import { DrawingsState } from '../interfaces/drawings-state';

function* updateVisibility({ payload }: ActionWith<UpdateVisibilityPayload>): SagaIterator {
  try {
    if (!payload.shouldSave) {
      return;
    }
    const drawingsState: DrawingsState = yield selectWrapper(x => x.drawings);
    const updatesByPages: Record<string, DrawingsElementChanges> = {};

    const groupByFiles = (instancesIds: string[]): void => {
      for (const id of instancesIds) {
        const drawingId = drawingsState.aiAnnotation.geometry[id]?.drawingId;
        if (drawingId) {
          updatesByPages[drawingId] = updatesByPages[drawingId] || {
            elementIds: [],
            pageId: drawingId,
            operation: DrawingChangeOperation.Update,
            updateType: DrawingsInstancesUpdateType.Description,
          };
          updatesByPages[drawingId].elementIds.push(id);
        }
      }
    };

    groupByFiles(payload.instancesToHide);
    groupByFiles(payload.instancesToShow);
    yield put(
      DrawingsUpdateActions.commitUpdates(Object.values(updatesByPages), DrawingChangeSource.Elements),
    );
  } catch (error) {
    console.error('drawings: export PDF failed', error, payload);
  }
}

export function* drawingsInstancesVisibilitySaga(): SagaIterator {
  yield takeLatest(DrawingsInstancesVisibilityActionTypes.UPDATE_VISIBILITY, updateVisibility);
}
