import { SagaIterator } from 'redux-saga';
import { call, put, SelectEffect } from 'redux-saga/effects';

import { Notification, NotificationProcessor } from 'common/interfaces/realtime-messages';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { ViewModelStatus } from '../../../../units/projects/enums/view-model-status';
import { DrawingsActions } from '../actions/creators/common';
import { DrawingsFile } from '../interfaces/drawings-file-info';
import { reloadAfterRecognition } from '../saga-utils/reload-after-recognition';
import { PdfScaleFinishData } from './interfaces';
import { DrawingsNotificationKeys } from './keys';


function isCurrentProject(projectId: number): SelectEffect {
  return selectWrapper(x => x.projects.currentProject && x.projects.currentProject.id === projectId);
}

function* screenshotGenerated(notification: Notification): SagaIterator {
  try {
    const { fileId, projectId }: { fileId: string, projectId: number } = notification.data;
    if (yield isCurrentProject(projectId)) {
      yield put(DrawingsActions.pdfScreenshotGenerated(fileId));
    }
  } catch (error) {
    console.error('drawings screeshot generated event error', error, notification);
  }
}

function* recognitionSuccess(notification: Notification): SagaIterator {
  try {
    const { pageId, fileId, projectId } = notification.data;
    if (yield isCurrentProject(projectId)) {
      yield call(reloadAfterRecognition, projectId, fileId, pageId);
    }
    yield put(DrawingsActions.setStatusDrawingRecognition(pageId, ViewModelStatus.Ready));
  } catch (error) {
    console.error('drawings recognition success event error', error, notification);
  }
}


function* pdfScaleFinish(notification: Notification): SagaIterator {
  try {
    const { projectId, pagePatch, pageId }: PdfScaleFinishData = notification.data;
    if ((yield isCurrentProject(projectId))
      && pagePatch
      && pagePatch.recommendedDrawingCalibrationLineLength !== undefined
      && pagePatch.recommendedOriginalCalibrationLineLength !== undefined
      && pagePatch.recommendedPaperSize !== undefined) {
      yield put(DrawingsActions.patchPageScale(pageId, pagePatch));
    }
  } catch (error) {
    console.error('pdf scale success event error', error, notification);
  }
}

interface UpdatePdfStatusPayload {
  fileId: string;
  projectId: number;
  rasterizationChanged: boolean;
  cadFileConvertingStatus: ViewModelStatus;
  splittingStatus: ViewModelStatus;
  rasterizationStatus: Record<string, ViewModelStatus>;
}


function shouldLoadFileData(
  { cadFileConvertingStatus, rasterizationStatus, rasterizationChanged }: UpdatePdfStatusPayload,
  file: DrawingsFile,
): boolean {
  return file.cadFileConvertingStatus !== cadFileConvertingStatus || (rasterizationChanged && !rasterizationStatus);
}

function* updatePdfStatus(notification: Notification): SagaIterator {
  try {
    const {
      fileId,
      projectId,
      cadFileConvertingStatus,
      splittingStatus,
      rasterizationStatus,
    }: UpdatePdfStatusPayload = notification.data;
    if (yield selectWrapper(x => x.projects.currentProject && x.projects.currentProject.id === projectId)) {
      if (cadFileConvertingStatus === ViewModelStatus.Failed) {
        yield put(DrawingsActions.setDrawingConversionStatus(fileId, cadFileConvertingStatus));
      } else {
        const file = yield selectWrapper(x => x.drawings.files.entities[fileId]);
        if (!file) {
          return;
        }

        if (shouldLoadFileData(notification.data, file)) {
          yield put(DrawingsActions.loadFileData(projectId, fileId));
        } else {
          if (rasterizationStatus) {
            yield put(DrawingsActions.updateDrawingRasterizationStatus(rasterizationStatus));
          }

          if (file.splittingStatus !== splittingStatus) {
            yield put(DrawingsActions.updateDrawingSplittingStatus(fileId, splittingStatus));
          }
        }
      }
    }
  } catch (error) {
    console.error('drawings update pdf status', error, notification);
  }
}

export const DrawingsNotificationProcessor: NotificationProcessor = {
  [DrawingsNotificationKeys.PdfScreenshotsSuccess]: screenshotGenerated,
  [DrawingsNotificationKeys.DrawingsPDFRecognitionSuccess]: recognitionSuccess,
  [DrawingsNotificationKeys.DrawingsPDFScaleRecognitionSuccess]: pdfScaleFinish,
  [DrawingsNotificationKeys.DrawingsPDFScaleRecognitionFailed]: pdfScaleFinish,
  [DrawingsNotificationKeys.DrawingsPDFStatusUpdated]: updatePdfStatus,
};
