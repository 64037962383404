import { TinyText } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  background: ${p => p.theme.color.backgroundRush};
  margin: 10px 0;
  position: relative;
  :nth-child(odd) {
    :before {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% + 20px);
      background: ${p => p.theme.color.background};
      position: absolute;
      right: 0;
      top: -20px;
    }
  }
`;

const Title = styled(TinyText)`
  margin-bottom: 15px;
`;

export const Styled = {
  Container,
  Title,
};
