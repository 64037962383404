import { Constants } from '@kreo/kreo-ui-components';
import styled, { css } from 'styled-components';

import { PdfPagePreviewStyled } from 'common/components/drawings/components/pdf-page-preview';
import { KreoColors } from 'common/enums/kreo-colors';
import { CommentCountMarkStyled } from 'unit-2d-comments/comment-count-mark';

export const PAGE_WIDTH = 160;
export const PAGE_HEIGHT = 120;
const BORDER_WIDTH = 2;

const Container = styled.div<{ selected: boolean, isColorSelectorOpened: boolean }>`
  position: relative;
  width: ${PAGE_WIDTH - BORDER_WIDTH * 2}px;
  height: ${PAGE_HEIGHT - BORDER_WIDTH * 2}px;
  overflow: hidden;
  border: ${BORDER_WIDTH}px solid ${p => p.selected ? p.theme.color.turquoise : 'transparent'};
  transition: all 0.175s ease-in-out 0s;
  border-radius: 10px;
  background-color: ${p => p.theme.color.background};
  cursor: pointer;
  ${PdfPagePreviewStyled.Container} {
    transition: all 0.175s ease-in-out;
    &:active {
      transform: ${p => !p.isColorSelectorOpened && 'scaleX(1.05) scaleY(1.05) scaleZ(1.05)'};
    }
  }
  ${CommentCountMarkStyled.Container} {
    position: absolute;
    top: 12px;
    z-index: 3;
    border-radius: 8px 8px 8px 2px;
    left: 40px;
  }
`;

const FloatPanel = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 50px);
  height: 25px;
  color: $${p => p.theme.color.white};
  border-radius: 13px;
  background-color: ${p => p.theme.color.backgroundRush};
  padding: 0 10px;
`;

const Menu = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20px;
  margin-left: 5px;
  color: ${KreoColors.iconColor};
  cursor: pointer;

  svg {
    display: flex;
    width: 20px;
    height: 20px;
  }
`;

const Color = styled.div<{ itemColor: string }>`
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => (props.itemColor)};
  cursor: pointer;
`;

const NameContainer = styled.div<{ measurementEditPermission: boolean }>`
  width: calc(100% - ${p => p.measurementEditPermission ? 35 : 0}px);
  height: 100%;
`;

const PageMenuWrapper = styled.div<{ left: number, top: number }>`
  position: fixed;
  left: ${p => p.left - 180}px;
  top: ${p => p.top}px;
  z-index: 10;
`;

const Icon = css<{ selected: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 2;
  transition: all 0.175s ease-in-out 0s;
  background-color: ${p => p.theme.color.turquoise};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${p => p.selected ? 1 : 0};
  svg {
    height: 10px;
    fill: ${p => p.theme.color.white};
  }
`;

const CheckIcon = styled.div`
  top: 10px;
  right: 10px;
  ${Icon};
`;

const Optimized = styled.div<{ selected: boolean, count: boolean }>`
  ${Icon};
  top: 10px;
  left: ${p => p.count ? 60 : 40}px;
  background-color: ${p => p.theme.color.backgroundRush};
  svg {
    fill: ${Constants.Colors.GENERAL_COLORS.green}
  }
`;

const ColorPickerContainer = styled.div<{ x: number, y: number }>`
  position: fixed;
  left: ${p => p.x}px;
  top: ${p => p.y}px;
  z-index: 2;
  border-radius: 15px;
  background-color: ${p => p.theme.color.background};
  animation: ${Constants.Animations.slowAppear({ xStart: -10, xEnd: 0 })} ${p => p.theme.duration.s} linear;
  cursor: default;
`;

export const Styled = {
  Container,
  Color,
  Optimized,
  FloatPanel,
  NameContainer,
  Menu,
  PageMenuWrapper,
  CheckIcon,
  ColorPickerContainer,
};
