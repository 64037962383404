import styled from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;

  &, * {
    box-sizing: border-box;
    outline: none;
  }
`;

const Container = styled.div<{ image: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: ${p => p.theme.color.background};
  background-image: ${p => `url(${p.image})`};
  background-position: center;
  background-size:  cover;
  box-shadow: ${p => p.theme.shadow.xs};
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  box-sizing: border-box;
  aspect-ratio: 1 / 1;

  &:hover {
    box-shadow: 0 0 0 2px ${p => p.theme.color.turquoise};
  }
`;

const ProjectName = styled.div`
  width: calc(100% - 10px);
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.color.backgroundRush};
  align-self: center;
  margin-top: auto;
  margin-bottom: 5px;
  padding: 0 10px;
  > div:first-of-type {
    width: 100%;
  }
`;


export const Styled = {
  Wrapper,
  Container,
  ProjectName,
};
