import styled from 'styled-components';

const Container = styled.div<{ isPinned: boolean }>`
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);

  .scroll-box__track-horizontal {
    visibility: hidden !important;
  }

  .virtual-list-content__item {
    margin-left: -5px;

    > div {
      height: 28px;
    }
  }
`;


export const Styled = {
  Container,
};
