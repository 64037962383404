import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './quantity-take-off-report-panel-approximate-report.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State } from 'common/interfaces/state';
import { IconButton, KreoIconCost, KreoIconDownloadFile } from 'common/UIKit';
import { QuantityTakeOffActions } from '../../actions/creators/quantity-take-off';
import { KnownViewModel } from '../../enums/known-view-model';
import { ViewModelStatus } from '../../enums/view-model-status';
import { ModelType } from '../../interfaces/quantity-take-off';

interface ReduxProps {
  approximateCostReportStatus: ViewModelStatus;
}

interface DispatchProps {
  buildApproximateCostReport: () => void;
  downloadApproximateCostReport: () => void;
}

interface OwnProps {
  projectId: number;
  modelType: ModelType;
}

interface Props extends OwnProps, DispatchProps, ReduxProps {
}
class QtoReportPanelApproximateReportComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { approximateCostReportStatus, projectId } = this.props;

    // Remove when Optimized library with intersection contour.
    if (projectId && approximateCostReportStatus !== ViewModelStatus.Ready) {
      return null;
    }

    return (
      <IconButton
        size='medium'
        className='quantity-take-off-report-panel-approximate-report'
        controlName='approximate-report-btn'
        rounded={false}
        onClick={this.onApproximateCostReportItemClick}
        tooltip={`${this.getCaptionPrefix(approximateCostReportStatus)} Approximate Cost Report`}
      >
        {this.getApproximateCostReportIcon()}
      </IconButton>
    );
  }

  @autobind
  private onApproximateCostReportItemClick(event: React.MouseEvent<HTMLDivElement>): void {
    const { approximateCostReportStatus, buildApproximateCostReport, downloadApproximateCostReport } = this.props;
    this.onReportItemClick(
      event,
      approximateCostReportStatus,
      buildApproximateCostReport,
      downloadApproximateCostReport,
    );
  }

  private onReportItemClick(
    event: React.MouseEvent<HTMLDivElement>,
    reportStatus: ViewModelStatus,
    buildReport: () => void,
    downloadReport: () => void,
  ): void {
    if (reportStatus !== ViewModelStatus.Ready) {
      // prevent list from hiding if something is going to change inside it
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }

    switch (reportStatus) {
      case ViewModelStatus.Ready:
        downloadReport();
        break;
      case ViewModelStatus.Calculating:
        // do nothing;
        break;
      default:
        buildReport();
    }
  }

  private getApproximateCostReportIcon(): any {
    switch (this.props.approximateCostReportStatus) {
      case ViewModelStatus.Calculating:
        return <SvgSpinner size='small' />;
      case ViewModelStatus.Ready:
        return <KreoIconDownloadFile />;
      default:
        return <KreoIconCost />;
    }
  }

  private getCaptionPrefix(reportStatus: ViewModelStatus): string {
    switch (reportStatus) {
      case ViewModelStatus.Empty:
      case ViewModelStatus.Failed:
        return 'Build';
      case ViewModelStatus.Calculating:
        return 'Building';
      case ViewModelStatus.Ready:
        return 'Download';
      default:
        return '';
    }
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const approximateCostReportStatus =
    state.projects.currentProject.viewModelsStatuses[KnownViewModel.QtoApproximateCostReport] ||
    ViewModelStatus.Empty;
  return {
    approximateCostReportStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  const { projectId, modelType } = props;

  return {
    buildApproximateCostReport: () => dispatch(QuantityTakeOffActions.buildApproximateCostReport(projectId, modelType)),
    downloadApproximateCostReport: () =>
      dispatch(QuantityTakeOffActions.downloadApproximateCostReport(projectId, modelType)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const QtoReportPanelApproximateReport = connector(QtoReportPanelApproximateReportComponent);
