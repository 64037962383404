import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './resource-limitations-row-menu-item.scss';

import { WorkPackagesData } from '../../../scenarios';


interface Props {
  item: WorkPackagesData.ResourceLimitation;
  activate: (index: number) => void;
}

export class ResourceLimitationsRowMenuItem extends React.Component<Props> {
  public render(): JSX.Element {
    const { item } = this.props;

    return (
      <div
        className={classNames('resource-limitations-row-menu-item', { 'active': item.activated === true })}
        onClick={this.onClick}
      >
        {item.name}
      </div>
    );
  }

  @autobind
  private onClick(): void {
    const { item } = this.props;
    this.props.activate(item.index);
  }
}
