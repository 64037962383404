import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { WizzardConstants } from 'common/components/drawings/constants/wizzard-constants';
import { TooManyGeometriesDialog } from 'common/components/drawings/dialogs/too-many-geometries';
import { WizzardStatus } from 'common/components/drawings/enums/dropper-state';
import { useOpenCloseDialog } from 'common/UIKit';
import { AiButtonWithStatus } from '../../ai-button-with-status';

interface Props {
  count: number;
  apply: () => void;
}

const ApplyButtonComponent: React.FC<Props> = ({ count, apply }) => {
  const [openDialog] = useOpenCloseDialog(DrawingDialogs.TOO_MANY_GEOMETRIES_DIALOG);
  const applyCallback = useCallback(() => {
    if (count > WizzardConstants.RESULT_LIMITATION) {
      openDialog();
    } else {
      apply();
    }
  }, [apply, count, openDialog]);
  return (
    <>
      <TooManyGeometriesDialog
        onSubmit={apply}
      />
      <AiButtonWithStatus
        status={WizzardStatus.Preview}
        onClick={applyCallback}
        icon={Icons.PlusBig}
        text='Create'
        disabled={count === 0}
      />
    </>
  );
};

export const ApplyButton = React.memo(ApplyButtonComponent);
