import autobind from 'autobind-decorator';
import React from 'react';
import { Field } from 'redux-form';

import './database-resource-plant.scss';

import { MaterialInputField, MaterialInputProps } from 'common/UIKit';
import { PlantModel } from '../../interfaces/resources-data';


interface Props {
  readonly: boolean;
  resource: PlantModel;
  onChange: (root: PlantModel) => void;
}

export class DatabaseResourcePlant extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <div className='database-resource-plant'>
        <div className='database-resource-plant__name'>
          <Field<MaterialInputProps>
            name='name'
            component={MaterialInputField}
            label='Name'
            multiLine={true}
            value={this.props.resource.name}
            onChange={this.onNameChange}
            onBlur={this.onNameBlur}
            displayBottomInformation={true}
            disabled={this.props.readonly}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onNameChange(_: React.ChangeEvent, name: string): void {
    this.props.onChange({
      ...this.props.resource,
      name,
    });
  }

  @autobind
  private onNameBlur(_: React.ChangeEvent, value: string): void {
    const name = value.trim();
    this.props.onChange({
      ...this.props.resource,
      name,
    });
  }
}

