import autobind from 'autobind-decorator';
import * as paper from 'paper';
import { DrawingsAnnotationsPosition } from 'common/components/drawings/actions/payloads/user-annotation';
import { DrawingsUserAnnotationsState } from 'common/components/drawings/interfaces/drawings-state';
import {
  DrawingUserAnnotationImage,
  DrawingUserAnnotationRuler,
  DrawingRulerPoints,
} from 'common/components/drawings/interfaces/drawings-user-annotation';
import { DrawingsStickerLayoutApi } from 'common/components/drawings/user-annotations-components';
import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { UndoRedoAdd } from 'common/undo-redo';
import { arrayUtils } from 'common/utils/array-utils';
import { DrawingRendererEngine } from '../../drawing-renderer-engine';
import { DrawingsUserAnnotationEvents } from './drawings-user-annotation-helper';

interface DrawingsUserAnnotationEventProcessorConfig {
  getEngine: () => DrawingRendererEngine;
  getCurrentDrawingId: () => string;
  getAnnotationState: () => DrawingsUserAnnotationsState;
  getStickerLayoutApi: () => DrawingsStickerLayoutApi;
  updateImageParameter: (id: string, value: number, parameter: keyof DrawingUserAnnotationImage) => void;
  updateRulerPositions: (id: string, points: DrawingRulerPoints) => void;
  updateImagesPositions: (updates: DrawingsAnnotationsPosition[]) => void;
  updateStickersPositions: (updates: DrawingsAnnotationsPosition[]) => void;
  selectAnnotation: (id: string, ctrlKey: boolean) => void;
  addImages: (images: DrawingUserAnnotationImage[], pageId: string) => void;
  addRulers: (rulers: DrawingUserAnnotationRuler[], pageId: string) => void;
  addUndoRedo: UndoRedoAdd;
  removeAnnotations: (ids: string[], pageId: string) => void;
}

export class DrawingsUserAnnotationEventProcessor implements DrawingsUserAnnotationEvents {
  private _config: DrawingsUserAnnotationEventProcessorConfig;

  constructor(config: DrawingsUserAnnotationEventProcessorConfig) {
    this._config = config;
  }

  private get engine(): DrawingRendererEngine {
    return this._config.getEngine();
  }

  private get annotationState(): DrawingsUserAnnotationsState {
    return this._config.getAnnotationState();
  }

  @autobind
  public onChangeSelection(id: string, e: PaperMouseEvent): void {
    this._config.selectAnnotation(id, HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e.event));
  }


  @autobind
  public onAddImages(images: DrawingUserAnnotationImage[]): void {
    const image = arrayUtils.toDictionary(images, x => x.id, x => x);
    this.engine.renderAnnotations(image, {});
    const drawingId = this._config.getCurrentDrawingId();
    this._config.addImages(images, drawingId);
    const redo = (): void => {
      const ruler = arrayUtils.toDictionary(images, x => x.id, x => x);
      if (this.engine) {
        this.engine.renderAnnotations(ruler, {});
      }
      this._config.addImages(images, drawingId);
    };

    const undo = (): void => this.removeUserAnnotations(images.map(x => x.id), drawingId);
    this._config.addUndoRedo(undo, redo);
  }

  @autobind
  public onAddRulers(rulers: DrawingUserAnnotationRuler[]): void {
    const drawingId = this._config.getCurrentDrawingId();
    this._config.addRulers(rulers, drawingId);
    const redo = (): void => {
      const ruler = arrayUtils.toDictionary(rulers, x => x.id, x => x);
      if (this.engine) {
        this.engine.renderAnnotations({}, ruler);
      }
      this._config.addRulers(rulers, drawingId);
    };

    const undo = (): void => this.removeUserAnnotations(rulers.map(x => x.id), drawingId);
    this._config.addUndoRedo(undo, redo);
  }

  @autobind
  public onUpdatePositions(ids: string[], { x, y }: paper.Point): void {
    const imageUpdates = [];
    const stickerUpdates = [];
    const sourceImages = [];
    const sourceStickers = [];
    const state = this.annotationState;
    for (const id of ids) {
      if (state.stickers[id]) {
        const { position } = state.stickers[id];
        const newPosition = [position[0] + x, position[1] + y];
        stickerUpdates.push({ id, position: newPosition });
        sourceStickers.push({ id, position });
      } else if (state.images[id]) {
        const { position } = state.images[id];
        const newPosition = [position[0] + x, position[1] + y];
        imageUpdates.push({ id, position: newPosition });
        sourceImages.push({ id, position });
      }
    }

    this.savePositions(imageUpdates, stickerUpdates);

    const undo =  this.getApplyPositionsMethod(sourceImages, sourceStickers);
    const redo = this.getApplyPositionsMethod(imageUpdates, stickerUpdates);
    this._config.addUndoRedo(undo, redo);
  }


  @autobind
  public onImageChangeParameter(id: string, value: number, parameter: keyof DrawingUserAnnotationImage): void {
    const oldImage = this.annotationState.images[id];
    const redo = (): void => this.applyImageParameter(id, value, parameter);
    const undo = (): void => this.applyImageParameter(id, oldImage[parameter] as number, parameter);
    this._config.addUndoRedo(undo, redo);
    this._config.updateImageParameter(id, value, parameter);
  }


  @autobind
  public onRulerPointsChange(id: string, points: DrawingRulerPoints): void {
    const oldRuler = this.annotationState.rulers[id];
    this._config.updateRulerPositions(id, points);
    const redo = (): void => this.applyRulerPoint(id, points);
    const undo = (): void => this.applyRulerPoint(id, oldRuler.positions);
    this._config.addUndoRedo(undo, redo);
  }

  private applyRulerPoint(id: string, points: DrawingRulerPoints): void {
    if (this.engine) {
      this.engine.setUserAnnotationRulerPoints(id, points);
    }
    this._config.updateRulerPositions(id, points);
  }

  private applyImageParameter(id: string, value: number, parameter: keyof DrawingUserAnnotationImage): void {
    if (this.engine) {
      this.engine.setAnnotationImageParameter(id, value, parameter);
    }
    this._config.updateImageParameter(id, value, parameter);
  }

  @autobind
  private removeUserAnnotations(ids: string[], pageId: string): void {
    if (this.engine) {
      this.engine.removeAnnotations(ids);
    }
    this._config.removeAnnotations(ids, pageId);
  }

  @autobind
  private savePositions(images: DrawingsAnnotationsPosition[], stickers: DrawingsAnnotationsPosition[]): void {
    if (images.length) {
      this._config.updateImagesPositions(images);
    }
    if (stickers.length) {
      this._config.updateStickersPositions(stickers);
    }
  }

  private getApplyPositionsMethod(
    images: DrawingsAnnotationsPosition[],
    stickers: DrawingsAnnotationsPosition[],
  ): () => void {
    return (): void => {
      const stickerApi = this._config.getStickerLayoutApi();
      if (stickerApi) {
        stickerApi.updateStickerPosition(stickers);
      }
      if (this.engine) {
        this.engine.changeAnnotationImagesPositions(images);
      }
      this.savePositions(images, stickers);
    };
  }
}
