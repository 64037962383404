import { ProjectType } from 'common/constants/project-type';
import { ProjectTypeToFileExtension } from 'common/constants/project-type-to-file-extensions';

function get2DFileExtensions(isAdmin: boolean, canUseConverter: boolean): string[] {
  const extensions = ProjectTypeToFileExtension[ProjectType.Project2d];
  const result = extensions.default.slice();
  if (isAdmin) {
    result.push(...extensions.admin);
  }
  if (canUseConverter) {
    result.push(...extensions.convertable);
  }
  return result;
}

export const FileExtensionUtils = {
  get2DFileExtensions,
};
