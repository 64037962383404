import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';

import './tree-table-cell-edit.scss';

import { TreeTableRowType } from '../../../../common/components/tree-table/interfaces';
import { QtoTreeRowProperty } from '../../interfaces/quantity-take-off';
import { PropertyHelper } from '../../utils/quantity-take-off-tree-table';
import {
  ReportTableCellDefaultEditor,
  ReportTableCellEditorCreator,
  ReportTableFormulaCellEditor,
} from '../quantity-take-off-report-table/cell-editor';
import { CombineCellEditor } from '../quantity-take-off-tree-table-cell-edit/combine-cell-editor';

const innerElementHTML = (editor: string): string => `
${editor}
<div class="tree-table-cell-edit__btn revert-value" title="Lose all changes">Revert</div>`;
const innerGroupAggregatedHTML = `
<span class="tree-table-cell-edit__value-aggregation">Aggregation sum</span>
<div class="tree-table-cell-edit__btn remove-aggregation" title="Define quantities manually">Remove</div>`;
const innerGroupNonAggregatedHTML = (editor: string): string => `
${editor}
<div class="tree-table-cell-edit__btn set-aggregation" title="Sum of quantities for the group">Set</div>`;


export class TreeTableCellEdit implements Ag.ICellEditorComp {
  private eGui: HTMLDivElement;
  private eRevertValueButton: HTMLDivElement;
  private eSetAggregationButton: HTMLDivElement;
  private eRemoveAggregationButton: HTMLDivElement;
  private eValueAggregation: HTMLSpanElement;
  private params: Ag.ICellEditorParams;
  private cellEditor: CombineCellEditor<QtoTreeRowProperty, ReportTableCellDefaultEditor, ReportTableFormulaCellEditor>;

  private isGroup: boolean;
  private isAggregation: boolean;

  public init(params: Ag.ICellEditorParams): void {
    this.params = params;

    const property = this.getProperty();
    this.isGroup = this.params.data.type === TreeTableRowType.Group;
    this.isAggregation = this.isGroup && PropertyHelper.isAggregation(property);

    this.eGui = document.createElement('div');
    this.eGui.className = 'tree-table-cell-edit';

    this.renderInnerHTML();
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public destroy(): void {
    this.destroyEvents();
  }

  @autobind
  public getValue(): QtoTreeRowProperty {
    if (this.isGroup && this.isAggregation) {
      return this.setAggregationValue();
    }

    return this.cellEditor.getValue();
  }

  public afterGuiAttached(): void {
    this.cellEditor.setFocus();
  }

  private renderInnerHTML(): void {
    const property = this.getProperty();
    const cellEditor = PropertyHelper.isFormula(property)
      ? ReportTableFormulaCellEditor.getInnerHtml()
      : ReportTableCellDefaultEditor.getInnerHtml();

    const innerHTML = !this.isGroup
      ? innerElementHTML(cellEditor)
      : this.isAggregation
        ? innerGroupAggregatedHTML
        : innerGroupNonAggregatedHTML(cellEditor);

    this.destroyEvents();

    this.eGui.innerHTML = innerHTML;
    this.eRevertValueButton = this.eGui.querySelector('.revert-value');
    this.eValueAggregation = this.eGui.querySelector('.tree-table-cell-edit__value-aggregation');
    this.eRemoveAggregationButton = this.eGui.querySelector('.remove-aggregation');
    this.eSetAggregationButton = this.eGui.querySelector('.set-aggregation');
    this.cellEditor = new CombineCellEditor(
      this.params,
      this.eGui,
      new ReportTableCellEditorCreator(),
      this.getProperty,
    );

    this.setValues();
    this.initEvents();
  }

  private destroyEvents(): void {
    if (this.cellEditor) {
      this.cellEditor.destroyEvents();
    }
    if (this.eRevertValueButton) {
      this.eRevertValueButton.addEventListener('click', this.revertValue);
    }
    if (this.eSetAggregationButton) {
      this.eSetAggregationButton.addEventListener('click', this.setAggregation);
    }
    if (this.eRemoveAggregationButton) {
      this.eRemoveAggregationButton.addEventListener('click', this.removeAggregation);
    }
  }

  private setValues(): void {
    this.cellEditor.setValue();
    if (this.eValueAggregation) {
      const valueAggregation = this.params.formatValue(this.params.value);
      this.eValueAggregation.innerHTML = valueAggregation !== undefined ? valueAggregation : '';
    }
  }

  private initEvents(): void {
    this.eGui.addEventListener('focusout', this.stopEditing);
    this.cellEditor.initEvents();
    if (this.eRevertValueButton) {
      this.eRevertValueButton.addEventListener('click', this.revertValue);
    }
    if (this.eSetAggregationButton) {
      this.eSetAggregationButton.addEventListener('click', this.setAggregation);
    }
    if (this.eRemoveAggregationButton) {
      this.eRemoveAggregationButton.addEventListener('click', this.removeAggregation);
    }
  }

  @autobind
  private stopEditing(event: FocusEvent): void {
    if (!event.relatedTarget) {
      this.params.api.stopEditing(true);
    }
  }

  @autobind
  private revertValue(): void {
    this.cellEditor.revertValue();
  }

  @autobind
  private setAggregation(): void {
    this.isAggregation = true;
    this.renderInnerHTML();
  }

  @autobind
  private removeAggregation(): void {
    this.isAggregation = false;
    this.renderInnerHTML();
    this.revertValue();
  }

  @autobind
  private getProperty(): any {
    const properties = this.params.data.properties;
    const colId = this.params.column.getId();
    return properties[colId];
  }

  private setAggregationValue(): QtoTreeRowProperty {
    const properties = this.params.data.properties;
    const colId = this.params.column.getId();
    properties[colId] = PropertyHelper.getAggregationProperty();

    return properties[colId];
  }
}
