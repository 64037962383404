import * as React from 'react';

import { ProjectCreateStatus } from 'common/enums/project-create-status';
import { RequestStatus } from 'common/enums/request-status';
import { CompanyProjectHeader } from 'unit-projects/interfaces/company-project-header';
import { ProjectsContainer } from '../projects-container';
import { Styled } from '../styled';

interface Props {
  loaded: boolean;
  allFetched: boolean;
  projectTypeName: string;
  isAdminMode: boolean;
  projectHeaders: Record<number, CompanyProjectHeader>;
  projectHeadersRequestStatus: RequestStatus;
  fetchProjects: () => void;
  onLeaveProject: (projectId: number, projectCreateStatus: ProjectCreateStatus) => void;
  onRemoveProject: (projectId: number, projectCreateStatus: ProjectCreateStatus) => void;
  onInviteToProjectClick: (projectId: number) => void;
  onOpenEditProjectNameDialog: (projectId: number) => void;
  onOpenDuplicateProjectDialog: (projectId: number) => void;
  openMoveToFolderDialog: (projectId: number) => void;
  saveProjectAsTemplate: (projectId: number) => void;
}


const ProjectsGridComponent: React.FC<Props> = (props) => {
  const {
    loaded,
    allFetched,
    projectTypeName,
    projectHeaders,
    fetchProjects,
    onRemoveProject,
    onLeaveProject,
    onInviteToProjectClick,
    onOpenEditProjectNameDialog,
    onOpenDuplicateProjectDialog,
    openMoveToFolderDialog,
    saveProjectAsTemplate,
    projectHeadersRequestStatus,
    isAdminMode,
  } = props;
  const loading = projectHeadersRequestStatus === RequestStatus.Loading;

  return (
    <Styled.Main>
      <Styled.MainGrid
        isLoading={loading}
      >
        <ProjectsContainer
          loaded={loaded}
          allFetched={allFetched}
          projectTypeName={projectTypeName}
          projectHeaders={projectHeaders}
          fetchProjects={fetchProjects}
          onRemoveProject={onRemoveProject}
          onLeaveProject={onLeaveProject}
          onInviteToProjectClick={onInviteToProjectClick}
          onOpenEditProjectNameDialog={onOpenEditProjectNameDialog}
          onOpenDuplicateProjectDialog={onOpenDuplicateProjectDialog}
          openMoveToFolderDialog={openMoveToFolderDialog}
          saveProjectAsTemplate={saveProjectAsTemplate}
          projectHeadersRequestStatus={projectHeadersRequestStatus}
          isAdminMode={isAdminMode}
        />
      </Styled.MainGrid>
    </Styled.Main>
  );
};

export const ProjectsGrid = React.memo(ProjectsGridComponent);
