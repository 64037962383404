import {
  DrawingsShortInfo,
} from 'common/components/drawings/interfaces';
import { DrawingsFiles } from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingInfo } from '../interfaces';

interface FileInfo {
  fileName: string;
  fileId: string;
  pageName: string;
  pageId: string;
}

const getFileInfo = (
  drawingId: string,
  files: DrawingsFiles,
  drawingsInfo: Record<string, DrawingsShortInfo>,
): FileInfo => {
  const drawingInfo = drawingsInfo[drawingId];
  if (!drawingInfo) {
    return {
      fileId: '',
      fileName: '',
      pageId: '',
      pageName: '',
    };
  }

  const fileInfo = files.entities[drawingInfo.pdfId];
  if (!fileInfo) {
    return {
      fileId: '',
      fileName: '',
      pageId: drawingInfo.drawingId,
      pageName: drawingInfo.name,
    };
  }

  return {
    fileId: fileInfo.id,
    fileName: fileInfo.properties.name,
    pageId: drawingInfo.drawingId,
    pageName: drawingInfo.name,
  };
};

export const getDrawingElementInfo = (drawingElementId: string, drawingInfo: DrawingInfo): any => {
  const { files, drawingsInfo, aiAnnotation } = drawingInfo;
  const measureValue = aiAnnotation.geometry[drawingElementId];
  const { fileName, pageName, fileId, pageId } = getFileInfo(measureValue.drawingId, files, drawingsInfo);

  const drawingElementInfo = {
    measureName: measureValue.name,
    measureId: drawingElementId,
    fileName,
    pageName,
    fileId,
    pageId,
  };

  return drawingElementInfo;
};
