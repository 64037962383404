import { CheckboxButton, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceCollector,
  DropTarget,
  DropTargetCollector,
} from 'react-dnd';

import { getMovingElement } from 'common/utils/dnd-utils';
import { Setting } from './interfaces';
import { Styled } from './styled';

interface OwnProps {
  setting: Setting;
  index: number;
  changePosition: (dragIndex: number, hoverIndex: number) => void;
  onClick: (item: Setting) => void;
}

interface DnDProps {
  connectDropTarget: (element: JSX.Element) => JSX.Element;
  connectDragSource: (element: JSX.Element) => JSX.Element;
  connectDragPreview: (element: JSX.Element) => JSX.Element;
  isDragging: boolean;
}

interface Props extends OwnProps, DnDProps { }

class PresetTableDnDSettingComponent extends React.PureComponent<Props> {
  private divRef: React.RefObject<HTMLDivElement> = React.createRef();

  public render(): JSX.Element {
    const { setting, isDragging, connectDragPreview, connectDragSource, connectDropTarget } = this.props;
    return connectDragPreview(connectDropTarget(
      <div style={{ padding: '7px 0 8px' }}>
        <Styled.DnDCheckboxWithLabel
          ref={this.divRef}
          isDragging={isDragging}
          onClick={this.onClick}
        >
          {connectDragSource(<div style={{ marginRight: '-5px' }}><Styled.DnDIcon /></div>)}
          <CheckboxButton checked={true} />
          <Text fontSize={14}>{setting.name}</Text>
        </Styled.DnDCheckboxWithLabel>
      </div>,
    ));
  }

  @autobind
  private onClick(): void {
    this.props.onClick(this.props.setting);
  }
}

const specDrop = {
  hover: (props: Props, monitor, component) => {
    if (!component) {
      return null;
    }

    const node = component.divRef.current;
    const hoverIndex = props.index;
    const element = getMovingElement(hoverIndex, node, monitor, true);

    if (element) {
      const { dragElement, dragIndex } = element;
      props.changePosition(dragIndex, hoverIndex);
      dragElement.index = hoverIndex;
    }
  },
};

interface DropCollectedProps {
  connectDropTarget: ConnectDropTarget;
}

const collectDrop: DropTargetCollector<any, any> = (connect) => {
  return {
    connectDropTarget: connect.dropTarget(),
  };
};

const specDrag = {
  beginDrag: (props: Props) => ({
    index: props.index,
  }),
};

interface DragCollectedProps {
  connectDragSource: ConnectDragSource;
  connectDragPreview: ConnectDragPreview;
  isDragging: boolean;
}

const collectDrag: DragSourceCollector<any, any> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const dragConnector = DragSource<OwnProps, DragCollectedProps>('presetProperty', specDrag, collectDrag);

const dropConnector = DropTarget<OwnProps, DropCollectedProps>('presetProperty', specDrop, collectDrop);

export const PresetTableDnDSetting = dropConnector(dragConnector(PresetTableDnDSettingComponent));
