import { Coordinate } from './interfaces';

export interface PositionPxHelper {
  getXPxPadding: () => number;
  getPxPositions: (x: number, y: number) => Coordinate;
  getXPx: (x: number) => number;
  getYPx: (y: number) => number;
  getX: (xPx: number) => number;
  getY: (yPx: number) => number;
}

const getPositionPxHelper = (
  edgeStepWidth: number,
  vertexWidth: number,
  yStep: number,
  levels: number,
): PositionPxHelper => {
  const getXPxPadding = (): number => {
    return (vertexWidth + edgeStepWidth) * levels + edgeStepWidth;
  };

  const getXPx = (x: number): number => {
    return getXPxPadding() + x * (vertexWidth + edgeStepWidth);
  };

  const getX = (xPx: number): number =>  {
    return (xPx - getXPxPadding()) / (vertexWidth + edgeStepWidth);
  };

  const getYPx = (y: number): number => {
    return y * yStep;
  };

  const getY = (yPx: number): number => {
    return yPx / yStep;
  };

  const getPxPositions = (x: number, y: number): Coordinate => {
    return {
      x: getXPx(x),
      y: getYPx(y),
    };
  };

  return {
    getXPxPadding,
    getPxPositions,
    getXPx,
    getYPx,
    getX,
    getY,
  };
};

export const PositionPxHelperProvider = {
  getPositionPxHelper,
};
