import React, { useCallback } from 'react';

import { DrawingStrokeStyles, StrokeStyleToIconMap } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsStrokeStyleSwitcher } from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { useEditStyle } from 'common/components/drawings/hooks';
import { DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import { arrayUtils } from 'common/utils/array-utils';
import { PropertyWithMenuEditor } from '../property-menu-editor';
import { Styled } from './styled';


interface Props {
  instances: Record<string, DrawingsGeometryInstance>;
  values: Record<string, string[]>;
  canEdit: boolean;
}

const StrokeStylePropertyComponent: React.FC<Props> = ({
  values,
  instances,
  canEdit,
}) => {
  const editStrokeStyle = useEditStyle('strokeStyle', instances);

  const optionRenderer = useCallback((property: string) => {
    const Icon = StrokeStyleToIconMap[property];
    if (!Icon) {
      return null;
    }
    return (
      <Styled.OptionContainer>
        <Icon/>
      </Styled.OptionContainer>
    );
  }, []);

  const changeStrokeStyle = useCallback((value: DrawingStrokeStyles): void => {
    const instancesIds = Object.values(values).reduce((acc, v) => {
      arrayUtils.extendArray(acc, v);
      return acc;
    }, []);
    editStrokeStyle(instancesIds, value);
  }, [editStrokeStyle, values]);

  const editorRenderer = useCallback(() => {
    return (
      <Styled.Switcher>
        <DrawingsStrokeStyleSwitcher
          strokeStyle={Object.keys(values)[0] as DrawingStrokeStyles}
          setStrokeStyle={changeStrokeStyle}
        />
      </Styled.Switcher>
    );
  }, [values, changeStrokeStyle]);

  return (
    <PropertyWithMenuEditor
      name='Style'
      elements={values}
      optionRenderer={optionRenderer}
      editorRenderer={canEdit ? editorRenderer : undefined}
    />
  );
};

export const StrokeStyleProperty = React.memo(StrokeStylePropertyComponent);
