import { GridApi } from 'ag-grid-community';
import autobind from 'autobind-decorator';

interface RowData {
  id: number | string;
}

export class AgGridSyncSelectHelper {
  private gridApi: GridApi = null;
  private onSelectionChangeHandler: (ids: Array<number | string>) => void = null;

  public static getSkipEventCount(numberIds: Array<number | string>, prevSelectedId: Array<number | string>): number {
    if (numberIds.length === 0) {
      return prevSelectedId.length;
    }

    if (prevSelectedId.length === 0) {
      return numberIds.length;
    }

    const { lessArray, maxArray } = numberIds.length < prevSelectedId.length
      ? {
        lessArray: numberIds,
        maxArray: prevSelectedId,
      }
      : {
        lessArray: prevSelectedId,
        maxArray: numberIds,
      };
    const maxMap = {};
    maxArray.forEach(id => maxMap[id] = true);
    const repeatIdsCount = lessArray.reduce(
      (result, id) => {
        if (maxMap[id]) {
          return (result as number) + 1;
        }

        return result;
      },
      0);

    return maxArray.length + lessArray.length - (repeatIdsCount as number * 2);
  }

  public init(
    gridOptions: GridApi,
    onSelectionChangeHandler: (ids: Array<string | number>) => void,
  ): void {
    this.gridApi = gridOptions;
    this.onSelectionChangeHandler = onSelectionChangeHandler;
  }

  @autobind
  public onSelectionChanged(): void {
    const selectedRows: RowData[] = this.gridApi.getSelectedRows();
    const selectedBimIds = selectedRows.map(row => row.id);
    this.onSelectionChangeHandler(selectedBimIds);
  }
}

