import { TinyText } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { ICON_DRAG } from 'common/UIKit/backgrounds-svg/background-svg';

const DialogContainer = styled.div`
  border-radius: 20px;
  background: ${p => p.theme.color.backgroundRush};
  border: 1px solid ${p => p.theme.color.background};
  display: flex;
`;

const GeneralSettings = styled.div`
  width: 480px;
  padding: 30px;
  box-sizing: border-box;
`;

const GeneralSettingsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  > div:last-child {
    margin-left: auto;
    height: 20px;
  }
  > svg {
    fill: ${p => p.theme.color.gray};
  }
  p {
    line-height: initial;
  }
`;

const Divider = styled.div`
  height: 10px;
  margin: 30px -30px 20px;
  background-color: ${p => p.theme.color.pale};
`;

const SettingsHeader = styled(TinyText)`
  margin: 4px 0 20px;
`;

const PropertySettings = styled.div`
  width: 240px;
  border-left: 1px solid ${p => p.theme.color.background};
  display: flex;
  flex-direction: column;
`;

const PropertySettingsHeader = styled.div`
  height: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${p => p.theme.color.background};
  display: flex;
  align-items: center;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 13px;
  svg {
    height: 20px;
    fill: ${p => p.theme.color.gray};
    flex-shrink: 0;
  }
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  font-size: 14px;
  width: 100%;
  color: ${props => props.theme.color.mainFont};
  background-color: ${p => p.theme.color.backgroundRush};
  ::placeholder {
    color: ${p => p.theme.color.disabled};
  }
`;

const Checkbox = styled.div<{ checked: boolean }>`
  display: flex;
  gap: 15px;
  cursor: pointer;
  p {
    line-height: 20px;
  }
`;

const CheckboxWithLabel = styled(Checkbox)<any>`
  margin-bottom: 15px;
`;

const DnDCheckboxWithLabel = styled(Checkbox)<any>`
  opacity: ${p => p.isDragging ? 0 : 1};
`;

const PropertySettingsContainer = styled.div`
  height: 410px;
  padding: 13px 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const PropertyList = styled.div`
  overflow-y: auto;
`;

const EmptyList = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const DnDIcon = styled.div`
  width: 20px;
  height: 20px;
  cursor: grab;
  background-image: ${p => ICON_DRAG(p.theme.color.gray)};
  &:hover {
    background-image: ${p => ICON_DRAG(p.theme.color.turquoise)};
  }
`;

export const Styled = {
  DialogContainer,
  GeneralSettings,
  GeneralSettingsHeader,
  Divider,
  SettingsHeader,
  PropertySettings,
  SearchContainer,
  SearchInput,
  PropertySettingsHeader,
  PropertySettingsContainer,
  PropertyList,
  EmptyList,
  CheckboxWithLabel,
  DnDCheckboxWithLabel,
  DnDIcon,
};
