import { Icons } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';

const MenuContainer = styled.div`
  margin: -10px 0;
  width: 220px;

  ${UpgradeWrapperStyled.StarsContainer} {
    right: 5px;
  }
`;

const ArrowIcon = styled(Icons.SmallArrow)`
  && {
    margin-right: 0;
    vertical-align: middle;
  }
`;

const MenuItemWrapper = styled.div<{ withBorder?: boolean }>`
  box-sizing: border-box;
  height: 40px;
  ${p => p.withBorder &&
    `border-bottom: 1px solid ${p.theme.color.background};`
  }
  > div {
    height: 40px;
  }
`;

const HoverMenuContainer = styled.div<{ allItemsCount: number }>`
  position: absolute;
  top: -${p => p.allItemsCount > 0 ? 76 : 26}px;
  left: -10px;

  > div > div {
    box-sizing: initial;
  }
`;

const SwitcherItem = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;

  &:hover p {
    color: ${p => p.theme.color.turquoise};
  }
`;

export const Styled = {
  MenuContainer,
  ArrowIcon,
  MenuItemWrapper,
  HoverMenuContainer,
  SwitcherItem,
};
