import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { usePersistedStorageSetter, usePersistedStorageValue } from 'persisted-storage/hooks';
import { NewInstanceSettingsActions } from '../../actions/creators/new-instance-settings';
import { NewDrawingSettings } from '../../drawings-geometry/interfaces';
import { NewInstanceSettings } from '../../interfaces';


export function useNewInstancesSettingsSetter<T extends keyof NewInstanceSettings>(
  settingKey: T,
): (value: NewInstanceSettings[T]) => void {
  const dispatch = useDispatch();
  return useCallback(
    (value: NewInstanceSettings[T]) => {
      dispatch(
        NewInstanceSettingsActions.updateSetting({
          key: settingKey as keyof NewInstanceSettings,
          value,
        }),
      );
    },
    [dispatch, settingKey],
  );
}

export function useNewInstancesSettings(): NewDrawingSettings {
  const newInstanceSettings = useSelector<State, NewInstanceSettings>((s) => s.drawings.newInstanceSettings);
  const polylineThickness = usePersistedStorageValue('drawingNewPolylineThickness');
  const polylineHeight = usePersistedStorageValue('drawingNewPolylineHeight');
  const polygonHeight = usePersistedStorageValue('drawingNewPolygonHeight');
  const strokeWidth = usePersistedStorageValue('drawingNewInstanceStrokeWidth');
  return useMemo<NewDrawingSettings>(() => {
    return {
      ...newInstanceSettings,
      strokeWidth,
      polylineThickness,
      polylineHeight,
      polygonHeight,
    };
  }, [newInstanceSettings, polylineHeight, polylineThickness, polygonHeight, strokeWidth]);
}

export function useNewInstancesSetting<T extends keyof NewInstanceSettings>(
  setting: T,
): [NewInstanceSettings[T], (value: NewInstanceSettings[T]) => void] {
  const value = useSelector<State, NewInstanceSettings[T]>((s) => s.drawings.newInstanceSettings[setting]);
  const setValue = useNewInstancesSettingsSetter(setting);
  return [value, setValue];
}


export function useUpdateNewInstanceSettings(): (newSettings: Partial<NewDrawingSettings>) => void {
  const setColor = useNewInstancesSettingsSetter('color');
  const setStrokeWidth = usePersistedStorageSetter('drawingNewInstanceStrokeWidth');
  const setPolylineThickness = usePersistedStorageSetter('drawingNewPolylineThickness');
  const setPolylineHeight = usePersistedStorageSetter('drawingNewPolylineHeight');
  const setPolygonHeight = usePersistedStorageSetter('drawingNewPolygonHeight');
  const setOffset = useNewInstancesSettingsSetter('offset');
  const setShape = useNewInstancesSettingsSetter('shape');
  const setStrokeStyle = useNewInstancesSettingsSetter('strokeStyle');
  const setName = useNewInstancesSettingsSetter('name');

  const setters = useMemo(() => {
    return {
      ['color']: setColor,
      ['strokeWidth']: setStrokeWidth,
      ['polylineThickness']: setPolylineThickness,
      ['polylineHeight']: setPolylineHeight,
      ['polygonHeight']: setPolygonHeight,
      ['offset']: setOffset,
      ['shape']: setShape,
      ['strokeStyle']: setStrokeStyle,
      ['name']: setName,
    };
  }, [
    setColor,
    setName,
    setOffset,
    setPolygonHeight,
    setPolylineHeight,
    setPolylineThickness,
    setShape,
    setStrokeStyle,
    setStrokeWidth,
  ]);

  return useCallback((newSettings: Partial<NewDrawingSettings>) => {
    for (const [key, value] of Object.entries(newSettings)) {
      if (setters[key]) {
        setters[key](value);
      }
    }
  }, [setters]);
}
