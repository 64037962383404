import * as React from 'react';

import { SpliterPaneConfig, SplitterLayout } from '../../../../../components/splitter-layout';
import { ViewProps } from './interfaces';

export class FourViewsSideBySideSplit extends React.Component<ViewProps> {
  private horizontalSize1: SpliterPaneConfig[] = [];
  private horizontalSize2: SpliterPaneConfig[] = [];
  private verticalSize: SpliterPaneConfig[] = [];
  constructor(props: ViewProps) {
    super(props);
    this.horizontalSize1 = [
      { size: props.height * 0.70, minSize: 50 },
      { size: props.height * 0.30, minSize: 50 },
    ];
    this.horizontalSize2 = [
      { size: props.height * 0.30, minSize: 50 },
      { size: props.height * 0.70, minSize: 50 },
    ];
    this.verticalSize = [
      { size: props.width * 0.50, minSize: 50 },
      { size: props.width * 0.50, minSize: 50 },
    ];
  }

  public render(): JSX.Element {
    return (
      <SplitterLayout
        primaryIndex={0}
        horizontal={false}
        paneConfigs={this.verticalSize}
      >
        <SplitterLayout
          primaryIndex={0}
          horizontal={true}
          paneConfigs={this.horizontalSize1}
        >
          {this.props.children[0]}
          {this.props.children[1]}
        </SplitterLayout>
        <SplitterLayout
          primaryIndex={0}
          horizontal={true}
          paneConfigs={this.horizontalSize2}
        >
          {this.props.children[2]}
          {this.props.children[3]}
        </SplitterLayout>
      </SplitterLayout>);
  }
}
