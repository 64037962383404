import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconNavbarBack: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons/16x16/Subnav_back' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <path
            // eslint-disable-next-line max-len
            d='M4.70710678,8.5 L7.85355339,11.6464466 C8.04881554,11.8417088 8.04881554,12.1582912 7.85355339,12.3535534 C7.65829124,12.5488155 7.34170876,12.5488155 7.14644661,12.3535534 L3.14644661,8.35355339 C2.95118446,8.15829124 2.95118446,7.84170876 3.14644661,7.64644661 L7.14644661,3.64644661 C7.34170876,3.45118446 7.65829124,3.45118446 7.85355339,3.64644661 C8.04881554,3.84170876 8.04881554,4.15829124 7.85355339,4.35355339 L4.70710678,7.5 L13.5,7.5 C13.7761424,7.5 14,7.72385763 14,8 C14,8.27614237 13.7761424,8.5 13.5,8.5 L4.70710678,8.5 Z'
            id='Combined-Shape'
            fill={KreoColors.f2}
            fillRule='nonzero'
          />
      </g>
    </svg>
  );
};
