import * as React from 'react';

import { CostEstimateGroupingType } from '../../../units/projects/enums/cost-estimate-grouping-type';
import { EditableCostRateCell } from './editable-cost-rate-cell';
import { ReadonlyCostRateCell } from './readonly-cost-rate-cell';

interface CostRateCellProps {
  rate: number | undefined;
  rateFieldName: string;
  cost: number | undefined;
  costFieldName: string;
  className?: string;
  isEditable: boolean;
  levelOfNesting: number;
  groupingType: CostEstimateGroupingType;
  isIndirectCostsRow: boolean;
  hasUnits: boolean;
  onInputChange: (value: string, fieldName: string) => void;
}

export const CostRateCell: React.FC<CostRateCellProps> = (props) => {
  if (props.isEditable) {
    return (
      <EditableCostRateCell
        className={props.className}
        rate={props.rate}
        rateFieldName={props.rateFieldName}
        cost={props.cost}
        costFieldName={props.costFieldName}
        onInputChange={props.onInputChange}
      />
    );
  }

  const isCostBlank = props.isIndirectCostsRow;
  const isRateBlank = props.isIndirectCostsRow || !props.hasUnits;

  return (
      <ReadonlyCostRateCell
        className={props.className}
        rate={isRateBlank ? null : props.rate}
        cost={isCostBlank ? null : props.cost}
      />
  );
};
