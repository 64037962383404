import React from 'react';

import { Text } from 'common/components/text';
import { Styled } from '../styled';


interface SmallTooltipProps {
  text: string;
  hotKey: string;
  description: string;
}

const SmallTooltipComponent: React.FC<SmallTooltipProps> = ({ text, hotKey, description }) => (
  <>
    <Styled.Header description={description}>
      <Text color="mainFont" fontSize={14} fontWeight={500}>
        {text}
      </Text>
      {hotKey && (
        <Styled.Hotkey description={description}>
          <Text color="white" fontSize={14}>
            {hotKey}
          </Text>
        </Styled.Hotkey>
      )}
    </Styled.Header>
    {description && (
      <Styled.Description>
        <Text color="gray" fontSize={12}>
          {description}
        </Text>
      </Styled.Description>
    )}
  </>
);

export const SmallTooltip = React.memo(SmallTooltipComponent);
