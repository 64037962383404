import { Text, TinyText } from '@kreo/kreo-ui-components';
import moment from 'moment';
import * as React from 'react';

import { RenderIf } from 'common/components/render-if';
import { Styled } from './styled';


export interface Props {
  projectName: string;
  created: string;
  error: boolean;
}

export const ProjectInfo: React.FC<Props> = React.memo(({ projectName, created, error }: Props) => {
  return (
    <Styled.Container>
      <Styled.ProjectNameContainer>
        <Text
          fontSize={16}
          textAlign={'center'}
        >
          {projectName}
        </Text>
        <RenderIf condition={error}>
          <Text
            fontSize={18}
            color={'red'}
            textAlign={'center'}
          >
            {'(Something went wrong!)'}
          </Text>
        </RenderIf>
      </Styled.ProjectNameContainer>
      <TinyText color={'gray'}>
        {moment(created).format('MMMM Do YYYY, h:mm a')}
      </TinyText>
    </Styled.Container>
  );
});
