import { RectangleButton, TinyText } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import { ProjectsApi } from 'unit-projects/api/common';
import { DemoProject } from 'unit-projects/interfaces/demo-project';
import { ExampleImage } from './database-expample-image-auth-wrapper';
import { Styled } from './styled';

interface Props {
  project: DemoProject;
  onApply: (id: number) => void;
}

export class DatabaseExampleItem extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <Styled.ExampleItem>
        <ExampleImage image={ProjectsApi.buildDemoProjectScreenshotUrl(this.props.project.id)} />
        <Styled.ExampleName>
          <TinyText>{this.props.project.name}</TinyText>
          <RectangleButton
            height={20}
            width={60}
            borderRadius={15}
            fontSize={12}
            text={'Apply'}
            mood={'secondary'}
            onClick={this.apply}
          />
        </Styled.ExampleName>
      </Styled.ExampleItem>
    );
  }

  @autobind
  private apply(): void {
    this.props.onApply(this.props.project.id);
  }
}
