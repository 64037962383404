import React from 'react';

import './activity-grouping-page.scss';

import { ActivityGroupingHeader } from '../activity-grouping-header';
import { EditActivityGroupsArea } from '../edit-activity-groups-area';

interface Props {
  projectId: number;
  scenarioId: number;
}

export class ActivityGroupingPage extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='activity-grouping-page'>
        <ActivityGroupingHeader/>
        <div className='activity-grouping-page__content'>
          <EditActivityGroupsArea
            projectId={this.props.projectId}
            scenarioId={this.props.scenarioId}
          />
        </div>
      </div>
    );
  }
}

