import autobind from 'autobind-decorator';
import * as classnames from 'classnames';
import * as React from 'react';

import { Checkbox } from 'common/UIKit';
import {
  ItemMenu,
  ItemMenuType,
} from '../../../../components/controls/item-menu';
import { DatabaseTableVariantModel } from '../../interfaces/data';
import { DatabaseTableColumn, DatabaseTableVariantMenuItem } from './interfaces';


interface Props {
  rootItemId: number;
  variant: DatabaseTableVariantModel;
  columns: Array<DatabaseTableColumn<any>>;
  menuItems: DatabaseTableVariantMenuItem[];

  isSelected: boolean;
  isSelectDisabled: boolean;
  editVariant: (rootId: number, variantId: number) => void;
  selectVariant?: (rootId: number, variantId: number, value: boolean) => void;
}


export class DatabaseTableVariant extends React.Component<Props> {
  public render(): JSX.Element {
    const containerClassNames = classnames('variant', {
      selected: this.props.isSelected,
    });

    return (
      <div
        className={containerClassNames}
        onClick={this.editActivityVariants}
      >
        <div className='action-column' onClick={this.stopPropagation}>
          {this.props.selectVariant ? <div className='checkbox-column'>
            <Checkbox
              checked={this.props.isSelected}
              onChange={this.onChangeChecked}
              disabled={this.props.isSelectDisabled}
            />
          </div> : null }
          {
            this.props.menuItems ? (
              <ItemMenu className='action-menu' menu={this.getMenuItems()} />
            ) : null
          }
        </div>
        {this.renderColumns()}
      </div>
    );
  }

  private renderColumns(): React.ReactNode[] {
    return this.props.columns.map(x => (
      <div className={x.className} key={x.key}>
        {this.props.variant.values[x.key]}
      </div>
    ));
  }

  private getMenuItems(): ItemMenuType[] {
    return this.props.menuItems.map(x => ({
      name: x.name,
      disabled: !x.action,
      action: x.action
        ? () => x.action(this.props.rootItemId, this.props.variant.id)
        : null,
    }));
  }

  @autobind
  private onChangeChecked(value: boolean): void {
    if (this.props.selectVariant) {
      this.props.selectVariant(this.props.rootItemId, this.props.variant.id, value);
    }
  }

  private stopPropagation(e: any): void {
    e.stopPropagation();
  }

  @autobind
  private editActivityVariants(e: any): void {
    if (!e.target.closest('.action-menu')) {
      this.props.editVariant(this.props.rootItemId, this.props.variant.id);
    }
  }
}
