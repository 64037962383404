import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './help-icon-dialog.scss';

import { KreoDialogActions, KreoIconTooltipHelp } from 'common/UIKit';
import { HelpTooltip } from '../controls/tooltips';
import { HelpDialog } from '../dialog/help-dialog';

interface DispatchProps {
  openDialog: (name: string) => void;
}

interface OwnProps extends DispatchProps {
  title: string;
  url: string;
  dialogName: string;
  className: string;
  video?: boolean;
}

class HelpIconDialogComponent extends React.Component<OwnProps> {
  public render(): JSX.Element {
    return (
      <>
        <div
          onClick={this.onOpenDialog}
          className={classNames('help-icon-dialog', this.props.className)}
        >
          <HelpTooltip
            icon={<KreoIconTooltipHelp />}
            text='Click to open help video'
          />
        </div>
        <HelpDialog
          name={this.props.dialogName}
          title={this.props.title}
          src={this.props.url}
          video={true}
        />
      </>
    );
  }

  @autobind
  private onOpenDialog(): void {
    this.props.openDialog(this.props.dialogName);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
  };
};

const connector = connect(null, mapDispatchToProps);
export const HelpIconDialog = connector(HelpIconDialogComponent);
