import React from 'react';

import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';

export const USER_MORE_THAN_SEATS_DIALOG = `USER_MORE_THAN_SEATS_DIALOG`;
interface Props {
  isArhived?: boolean;
}

export const UserMoreThanSeatsDialog: React.FC<Props> = ({ isArhived }) => {
  const text = isArhived
    ? 'Remove extra users from your company or buy additional seats to continue or contact with support'
    : 'Remove extra users from your company or buy additional seats to continue';
  return (
    <ConfirmationDialog
      name={USER_MORE_THAN_SEATS_DIALOG}
      title={'You have more users than seats in the selected plan'}
      text={text}
      cancelButtonText='Okay'
    />
  );
};
