import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { Spinner } from 'common/components/spinner';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { UuidUtil } from 'common/utils/uuid-utils';
import { Item, Property, UpdateItemRequest } from 'unit-2d-database/interfaces';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { Selector } from 'unit-2d-info-panel/content/selection-view/assign-form/helpers/selectors';
import { TwoDActions } from '../../../../../../units/2d/actions/creators';
import { AssignedPia, AssignItemPatch, AssignPiaPatch } from '../../../../../../units/2d/interfaces';
import { PersistedStorageActions } from '../../../../../../units/persisted-storage/actions/creators';
import { SidePanelContentProps } from '../../interfaces';
import { useResetChildCallback } from '../hooks';
import { ItemPanel, OwnState } from '../item-panel/item-panel';

interface StateProps {
  rootIds: string[];
  childIds: string[];
  assign: Record<string, AssignedPia>;
  showSpinner: boolean;
}

interface DispatchProps {
  sendPatchPia: (assign: AssignPiaPatch[], callback: () => void) => void;
  close: () => void;
  toggleEntitiesPanel: () => void;
}

interface Props extends StateProps, DispatchProps, SidePanelContentProps<Property, OwnState> {}

const ItemPanelPageComponet = (props: Props): JSX.Element => {
  const isShowRenameDialog = useCallback(() => {
    return false;
  }, []);

  const getResetChildPatch = useResetChildCallback(props.childIds, props.assign);

  const update = useCallback((payload: UpdateItemRequest, data: Item) => {
    const patch: AssignPiaPatch = {
      ids: props.rootIds,
    };
    const assign = props.assign[props.rootIds[0]];
    if (assign?.items?.length) {
      const item = assign.items.find(i => i.name === data.name);
      const updatedProperties: Property[] = payload.updatedProperties;
      const addedProperties: Property[] = [];
      const deletedProperties: string[] = [];
      payload.addedProperties.forEach(ap => {
        const property = item.properties.find(p => p.name === ap.name);
        if (property) {
          updatedProperties.push(ap);
        } else {
          addedProperties.push(ap);
        }
      });

      payload.deletedProperties.forEach(dp => {
        const property = item.properties.find(p => p.name === dp);
        if (property) {
          updatedProperties.push({
            ...property,
            value: null,
          });
        } else {
          addedProperties.push({
            name: dp,
            ...property,
            value: null,
          });
        }
      });

      const updatedItem: AssignItemPatch = {
        name: data.name,
        iconType: data.iconType,
        addedProperties,
        deletedProperties,
        updatedProperties,
      };
      patch.updatedItems = [updatedItem];
    } else {
      const addedItem: Item = JSON.parse(JSON.stringify(data));
      payload.updatedProperties.forEach(up => {
        addedItem.properties.forEach(p => {
          if (p.name === up.name) {
            p.value = up.value;
          }
        });
      });
      payload.addedProperties.forEach(p => {
        addedItem.properties.push(p);
      });
      payload.deletedProperties.forEach(dp => {
        addedItem.properties.forEach(p => {
          if (p.name === dp) {
            p.value = null;
          }
        });
      });
      patch.addedItems = [addedItem];
    }

    const resetChildPatch = getResetChildPatch();
    props.sendPatchPia([patch, ...resetChildPatch], () => {
      props.close();
      props.toggleEntitiesPanel();
    });

  }, [props.rootIds, props.assign]);
  const create = useCallback(ConstantFunctions.doNothing, []);
  const openAssignFloatPanel = useCallback(props.toggleEntitiesPanel, []);
  useEffect(() => {
    props.setAfterClose(openAssignFloatPanel);
  }, []);

  return (
    <>
      <Spinner show={props.showSpinner} withBackground={true} />
      <ItemPanel
        {...props}
        isShowRenameDialog={isShowRenameDialog}
        update={update}
        create={create}
        skipCloseAfterSubmit={true}
        disableEditName={true}
        afterClose={openAssignFloatPanel}
      />
    </>
  );
};

const mapStateToProps = (state: State): StateProps => {
  const { rootIds, childIds } = Selector.getSelectedIds(state);
  return {
    rootIds,
    childIds,
    assign: state.twoD.assignPia,
    showSpinner: state.twoDDatabase.sidePanel.showSpinner,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    sendPatchPia: (assign, callback) => {
      dispatch(TwoDDatabaseActions.showSpinner());
      const iterationId = UuidUtil.generateUuid();
      dispatch(TwoDActions.setExportExcelLoad(iterationId));
      dispatch(TwoDElementViewActions.changeColumnAfterAssign(assign));
      dispatch(TwoDActions.sendAssignPatch(assign, iterationId, () => {
        callback();
        dispatch(TwoDElementViewActions.handleChangeAssign(assign));
      }));
    },
    close: () => {
      dispatch(TwoDDatabaseActions.closeSidePanel());
      dispatch(TwoDDatabaseActions.closeItemPanel());
    },
    toggleEntitiesPanel: () => {
      dispatch(PersistedStorageActions.openEntitiesPanel());
    },
  };
};

export const ItemPanelPage = connect(mapStateToProps, mapDispatchToProps)(ItemPanelPageComponet);
