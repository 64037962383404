const prefix = '@classification';

const GET_STATISTIC_REQUEST = `${prefix}/GET_STATISTIC_REQUEST`;
const GET_STATISTIC_SUCCESS = `${prefix}/GET_STATISTIC_SUCCESS`;
const ASSIGN_CLASSIFICATION = `${prefix}/ASSIGN_CLASSIFICATION`;
const TO_ASSIGNMENT = `${prefix}/TO_ASSIGMENT`;
const SET_CURRENT_TAB = `${prefix}/SET_CURRENT_TAB`;
const ON_HEADER_BACK_CLICK = `${prefix}/ON_HEADER_BACK_CLICK`;
const LOAD_DATA = `${prefix}/LOAD_DATA`;
const DROP_STATE = `${prefix}/DROP_STATE`;
const COLLAPSE_ALL_TREE = `${prefix}/COLLAPSE_ALL_TREE`;
const ON_BREAD_CRUMB_CLICK = `${prefix}/ON_BREAD_CRUMB_CLICK`;
const ISOLATE_NODE = `${prefix}/ISOLATE_NODE`;
const LOAD_BIM_MODEL_INFO_SUCCESS = `${prefix}/LOAD_BIM_MODEL_INFO_SUCCESS`;
const TOGGLE_COLLAPSE_EXPAND_STATUS = `${prefix}/TOGGLE_COLLAPSE_EXPAND_STATUS`;
const FILTER_BIM_INFO_BY_TAB = `${prefix}/FILTER_BIM_INFO_BY_TAB`;
const SELECT_CLASSIFICATION_VARIANT = `${prefix}/SELECT_CLASSIFICATION_VARIANT`;
const LOAD_ELEMENT_ONTOLOGY_SUCCESS = `${prefix}/LOAD_ELEMENT_ONTOLOGY_SUCCESS`;
const CHANGE_PRODUCT_FILTER = `${prefix}/CHANGE_PRODUCT_FILTER`;
const CHANGE_SYSTEM_FILTER = `${prefix}/CHANGE_SYSTEM_FILTER`;
const SET_CUSTOM_PRODUCT = `${prefix}/SET_CUSTOM_PRODUCT`;
const SET_CUSTOM_SYSTEM = `${prefix}/SET_CUSTOM_SYSTEM`;
const SET_SELECTED_UNICLASS = `${prefix}/SET_SELECTED_UNICLASS`;
const EDIT_ASSEMBLY_CLASSIFICATION = `${prefix}/EDIT_ASSEMBLY_ClASSIFICATION`;
const EDIT_LAYER_CLASSIFICATION = `${prefix}/EDIT_LAYER_CLASSIFICATION`;
const SET_CUSTOM_ASSEMBLY_SYSTEM = `${prefix}/SET_CUSTOM_ASSEMBLY_SYSTEM`;
const SET_CUSTOM_LAYER_PRODUCT = `${prefix}/SET_CUSTOM_LAYER_PRODUCT`;
const TO_OLD_ASSIGNMENT = `${prefix}/TO_OLD_ASSIGNMENT`;
const SAVE_UNICLASS_DATA = `${prefix}/LOAD_UNICLASS_DATA`;
const SAVE_CURRENT_CLASSIFICATION = `${prefix}/SAVE_CURRENT_CLASSIFICATION`;
const CHANGE_ASSIGNMENT_LEVEL = `${prefix}/CHANGE_ASSIGNMENT_LEVEL`;
const CREATE_CUSTOM_UNICLASS = `${prefix}/CREATE_CUSTOM_UNICLASS`;
const SET_ASSEMBLY_ROOT_CLASSIFICATION = `${prefix}/SET_ASSEMBLY_ROOT_CLASSIFICATION`;
const SET_ASSEMBLY_LAYER_CLASSIFICATION = `${prefix}/SET_ASSEMBLY_LAYER_CLASSIFICATION`;
const CHANGE_PRODUCT_FILTER_COMMIT = `${prefix}/CHANGE_PRODUCT_FILTER_COMMIT`;
const CHANGE_SYSTEM_FILTER_COMMIT = `${prefix}/CHANGE_SYSTEM_FILTER_COMMIT`;
const SAVE_GRAPH = `${prefix}/SAVE_GRAPH`;
const TOGGLE_UNCLASSIFIED_FILTER = `${prefix}/TOGGLE_UNCLASSIFIED_FILTER`;
const REMOVE_NODE = `${prefix}/REMOVE_NODE`;
const TOGGLE_SEARCH_MODE = `${prefix}/TOGGLE_SEARCH_MODE`;
const CHANGE_SEARCH_QUERY = `${prefix}/CHANGE_SEARCH_QUERY`;
const COPY_CLASSIFICATION = `${prefix}/COPY_CLASSIFICATION`;
const COPY_CLASSIFICATION_SUCCESS = `${prefix}/COPY_CLASSIFICATION_ONTOLOGY_TREE_LOADED`;
const PASTE_CLASSIFICATION = `${prefix}/PASTE_CLASSIFICATION`;
const PASTE_CLASSIFICATION_SUCCESS = `${prefix}/PASTE_CLASSIFICATION_SUCCESS`;
const ON_SELECT = `${prefix}/ON_SELECT`;
const SELECT_ALL = `${prefix}/SELECT_ALL`;
const SELECT_NOT_PASTED = `${prefix}/SELECT_NOT_PASTED`;
const SELECT_PASTED = `${prefix}/SELECT_PASTED`;
const SELECT_BY_ENGINE_IDS = `${prefix}/SELECT_BY_ENGINE_IDS`;
const TRY_START_MULTIPLE_EDIT = `${prefix}/TRY_START_MULTIPLE_EDIT`;
const SELECT_GROUP = `${prefix}/SELECT_GROUP`;
const EDIT_GROUP = `${prefix}/EDIT_GROUP`;
const SAVE_GROUPING = `${prefix}/SAVE_GROUPING`;
const SELECT_TREE_NODE = `${prefix}/SELECT_TREE_NODE`;
const SELECT_TREE_NODE_BY_ENGINE_ID = `${prefix}/SELECT_TREE_NODE_BY_ENGINE_ID`;
const SELECT_NODE_VARIANT = `${prefix}/SELECT_NODE_VARIANT`;


export const ClassificationActionTypes = {
  GET_STATISTIC_REQUEST,
  GET_STATISTIC_SUCCESS,
  ASSIGN_CLASSIFICATION,
  SET_CURRENT_TAB,
  ON_HEADER_BACK_CLICK,
  LOAD_DATA,
  DROP_STATE,
  COLLAPSE_ALL_TREE,
  ON_BREAD_CRUMB_CLICK,
  ISOLATE_NODE,
  LOAD_BIM_MODEL_INFO_SUCCESS,
  TOGGLE_COLLAPSE_EXPAND_STATUS,
  FILTER_BIM_INFO_BY_TAB,
  TO_ASSIGNMENT,
  LOAD_ELEMENT_ONTOLOGY_SUCCESS,
  SELECT_CLASSIFICATION_VARIANT,
  CHANGE_PRODUCT_FILTER,
  CHANGE_SYSTEM_FILTER,
  SET_CUSTOM_PRODUCT,
  SET_CUSTOM_SYSTEM,
  SET_SELECTED_UNICLASS,
  EDIT_ASSEMBLY_CLASSIFICATION,
  EDIT_LAYER_CLASSIFICATION,
  TO_OLD_ASSIGNMENT,
  SET_CUSTOM_ASSEMBLY_SYSTEM,
  SET_CUSTOM_LAYER_PRODUCT,
  SAVE_UNICLASS_DATA,
  SAVE_CURRENT_CLASSIFICATION,
  CHANGE_ASSIGNMENT_LEVEL,
  CREATE_CUSTOM_UNICLASS,
  SET_ASSEMBLY_ROOT_CLASSIFICATION,
  SET_ASSEMBLY_LAYER_CLASSIFICATION,
  CHANGE_PRODUCT_FILTER_COMMIT,
  CHANGE_SYSTEM_FILTER_COMMIT,
  SAVE_GRAPH,
  TOGGLE_UNCLASSIFIED_FILTER,
  REMOVE_NODE,
  TOGGLE_SEARCH_MODE,
  CHANGE_SEARCH_QUERY,
  COPY_CLASSIFICATION,
  COPY_CLASSIFICATION_SUCCESS,
  PASTE_CLASSIFICATION,
  PASTE_CLASSIFICATION_SUCCESS,
  ON_SELECT,
  SELECT_ALL,
  SELECT_NOT_PASTED,
  SELECT_PASTED,
  SELECT_BY_ENGINE_IDS,
  TRY_START_MULTIPLE_EDIT,
  SELECT_GROUP,
  EDIT_GROUP,
  SAVE_GROUPING,
  SELECT_TREE_NODE,
  SELECT_TREE_NODE_BY_ENGINE_ID,
  SELECT_NODE_VARIANT,
};
