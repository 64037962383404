import * as React from 'react';

import { ResourceDescription } from '../../../utils/gantt-chart';
import { ItemType, SelectItem } from './select-item';


interface Props {
  resources: ResourceDescription[];
  selectedResourceId: number;
  onSelectItem: (id: number, name: string) => void;
}

class ResourceSelectorContentPlant extends React.Component<Props> {
  private selectResourceMethods: Map<number, () => void> = new Map<number, () => void>();

  public render(): React.ReactNode {
    return (
      <div>
        {
          this.props.resources && this.props.resources.map((resourceDescription) => {
            return (
              <SelectItem
                value={resourceDescription.id}
                title={resourceDescription.displayName}
                key={resourceDescription.id}
                type={ItemType.Radio}
                onCheck={this.getSelectMethod(resourceDescription.id, resourceDescription.displayName)}
                selected={resourceDescription.id === this.props.selectedResourceId}
                level={0}
              />);
          })
        }
      </div>
    );
  }

  private getSelectMethod(id: number, name: string): () => void {
    if (!this.selectResourceMethods.has(id)) {
      this.selectResourceMethods.set(id, () => {
        this.props.onSelectItem(id, name);
      });
    }
    return this.selectResourceMethods.get(id);
  }
}


export { ResourceSelectorContentPlant };
