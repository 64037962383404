import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import './resource-limitations-page.scss';

import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { Stepper } from '../../../../actions';
import { ScenarioRouteParams } from '../../../../routes/app-routes-params';
import { Master, ScenarioEditPage, ScenariosData } from '../../../scenarios';
import { ResourceLimitationsList } from '../../components/resource-limitations-list';
import { ResourceLimitationsListHeader } from '../../components/resource-limitations-list-header';

interface ReduxProps {
  scenario: ScenariosData.Scenario;
  savePackagesStatus: RequestStatus;
}

interface ReduxActions {
  setStep: (step: number) => void;
}

interface Props
  extends ReduxProps,
  ReduxActions,
  RouteComponentProps<ScenarioRouteParams> { }

class ResourceLimitationsPageComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.props.setStep(ScenarioEditPage.Resources);
  }

  public render(): JSX.Element {
    const { scenario, savePackagesStatus, match } = this.props;

    return (
      <Master
        scenario={scenario}
        projectId={match.params.projectId}
        wait={savePackagesStatus !== RequestStatus.Loaded}
      >
        <div className='resource-limitations-page'>
          <div className='resource-limitations-page__title'>Resource management</div>
          <ResourceLimitationsListHeader />
          <ResourceLimitationsList scenarioId={+match.params.scenarioId} />
        </div>
      </Master>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    scenario: state.scenarios.editScenario,
    savePackagesStatus: state.scenarios.statuses.savePackages,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    setStep: (step: number) => {
      dispatch(Stepper.setStep(step));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ResourceLimitationsPage = connector(ResourceLimitationsPageComponent);
