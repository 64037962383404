import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TwoDActions } from '2d/actions/creators';
import { State } from 'common/interfaces/state';
import { WizzardToolsActions } from '../actions/creators';
import { DrawingsActions } from '../actions/creators/common';
import { DrawingsAnnotationLegendActions } from '../actions/creators/drawings-annotation-legend';
import { useRendererApiContext } from '../drawings-contexts/renderer-api-context';
import {
  DrawingModesWithoutSelectionBlock,
  DrawingsDrawMode,
} from '../enums';
import { DrawingsGeometryGroup, SaveDrawModeOptions } from '../interfaces';
import { DrawingsGroupUtils } from '../utils/drawings-group-utils';
import { useOpenEngineIfNeeded } from './use-open-engine-if-needed';

export interface DrawModeContextProps {
  drawMode: DrawingsDrawMode;
  setDrawMode: (drawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => void;
}

export function useDrawingMode(): DrawingsDrawMode {
  return useSelector<State, DrawingsDrawMode>((state) => state.drawings.drawMode);
}

export function useDrawModeApi(): DrawModeContextProps {
  const drawMode = useSelector<State, DrawingsDrawMode>((state) => state.drawings.drawMode);
  const dispatch = useDispatch();
  const drawingRendered = useSelector<State, boolean>((state) => state.drawings.drawingRenderedStatus);
  const setWrite = useCallback((value) => dispatch(TwoDActions.setWrite(value)), [dispatch]);
  const { tryCancelDrawMode } = useRendererApiContext();
  const groups = useSelector<State, DrawingsGeometryGroup[]>((state) => state.drawings.drawingGeometryGroups);
  const selectedGroups = useSelector<State, string[]>((state) => state.drawings.selectGeometryGroup);
  const openEngine = useOpenEngineIfNeeded();

  const tryChangeDrawMode = useCallback((newDrawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => {
    const action = (): void => {
      if (options?.prevSave) {
        options?.prevSave();
      }
      dispatch(DrawingsActions.setDrawMode(newDrawMode));
      if (options?.afterSave) {
        options?.afterSave();
      }
    };
    if (options?.ignoreCancelMessage) {
      action();
    } else {
      tryCancelDrawMode(action, options?.cancel);
    }
  }, [tryCancelDrawMode, dispatch]);

  const dropWizzardState = useCallback(() => {
    dispatch(WizzardToolsActions.dropDropperState());
  }, [dispatch]);

  const setDrawMode = useCallback((newDrawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => {
    const isSameDrawMode = newDrawMode === drawMode;
    if (!isSameDrawMode) {
      openEngine();
    }
    if (!drawingRendered || isSameDrawMode) {
      if (options?.afterSave) {
        options.afterSave();
      }
      return;
    }
    const commonAfterSave = (): void => {
      setWrite(newDrawMode === DrawingsDrawMode.SelectItems);

      if (!options?.blockDeselection && !DrawingModesWithoutSelectionBlock.includes(drawMode)) {
        const groupToSelect = selectedGroups.length > 0
          ? [DrawingsGroupUtils.getFirstSelectedGroupId({ groups, selectedGroups })]
          : [];
        dispatch(DrawingsAnnotationLegendActions.updateSelection({ instanceIds: [], groupIds: groupToSelect }));
      }

      if (options?.afterSave) {
        options.afterSave();
      }
    };

    if (drawMode === DrawingsDrawMode.Offset) {
      tryChangeDrawMode(newDrawMode, {
        ...(options || {}),
        afterSave: () => {
          commonAfterSave();
          if (options?.afterSave) {
            options.afterSave();
          }
        },
      });
    } else if (drawMode === DrawingsDrawMode.Dropper || drawMode === DrawingsDrawMode.Finder) {
      tryChangeDrawMode(newDrawMode, { ...(options || {}), afterSave: commonAfterSave, prevSave: dropWizzardState });
    } else {
      tryChangeDrawMode(newDrawMode, { ...(options || {}), afterSave: commonAfterSave });
    }
  }, [
    dispatch,
    drawingRendered,
    drawMode,
    setWrite,
    tryChangeDrawMode,
    groups,
    selectedGroups,
    openEngine,
  ]);

  return useMemo(() => ({ drawMode, setDrawMode }), [drawMode, setDrawMode]);
}


export function withDrawingModeApi<P>(
  Component: React.ComponentType<P & DrawModeContextProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawModeContextProps>>> {
  return (props: P) => {
    const { drawMode, setDrawMode } = useDrawModeApi();
    return <Component {...props} drawMode={drawMode} setDrawMode={setDrawMode} />;
  };
}
