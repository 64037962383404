export enum RequestStatus {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Failed = 'Failed',
  NotRequested = 'NotRequested',
}

export const ActiveRequestStatuses = [
  RequestStatus.Loaded,
  RequestStatus.Loading,
];
