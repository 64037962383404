import { useCallback, useState } from 'react';

interface CollapseGroupStatusesController {
  collapsedGroupStatus: Record<string, boolean>;
  toggleCollapse: (id: string) => void;
}

export function useCollapse(): CollapseGroupStatusesController {
  const [ collapsedGroupStatuses, setCollapsedGroupStatus ] = useState<Record<string, boolean>>({});

  const handleCollapse = useCallback((groupId: string) => {
    setCollapsedGroupStatus((prev) => ({
      ...prev,
      [groupId]: !prev[groupId],
    }));
  }, [collapsedGroupStatuses]);

  return {
    collapsedGroupStatus: collapsedGroupStatuses,
    toggleCollapse: handleCollapse,
  };
}
