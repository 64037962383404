import { CodecUtils } from 'common/utils/codec-utils';

export enum SubscriptionStatus {
  None = 'None',
  Valid = 'Valid',
  Canceled = 'Canceled',
  Paused = 'Paused',
}

export const SubscriptionStatusC =
  CodecUtils.createEnumCodec<SubscriptionStatus>(SubscriptionStatus, 'SubscriptionStatus');
