import { SubscriptionType } from 'common/constants/subscription';
import { CommonApi, CompanyResourcesApi } from '../../../api/server';
import { CompaniesC, Company } from '../../../units/account/interfaces/company';
import { CompanyBillingInfoModel, CompanyBillingInfoModelC } from '../interfaces/company-billing-info-model';
import { CreateSubscriptionForm } from '../interfaces/create-subscription-form';
import { EstimateSubscriptionForm } from '../interfaces/estimate-subscription-form';
import { EstimateSubscriptionModel, EstimateSubscriptionModelC } from '../interfaces/estimation-subscription-model';
import {
  SubscriptionPlanListingModel,
  SubscriptionPlanListingModelC,
} from '../interfaces/subscription-plan-listing-model';
import { UpdateSubscriptionForm } from '../interfaces/update-subscription-form';

function getSubscriptionPlans(subscriptionType: SubscriptionType): Promise<SubscriptionPlanListingModel> {
  return CommonApi.getV(
    `/billing/${subscriptionType}/plans`,
    SubscriptionPlanListingModelC,
    'Error while fetching subscription plans from backend',
  );
}

function setSubscriptionPaymentMethod(subscriptionId: string, cardId: string): void {
  CompanyResourcesApi.put(
    `/subscription/${subscriptionId}/payment/${cardId}`,
    null,
    'Error while set subscription payment method',
  );
}

function createSubscription(companyId: number, createSubscriptionForm: CreateSubscriptionForm): Promise<Company[]> {
  return CommonApi.postV(
    `/companies/${companyId}/subscription`,
    createSubscriptionForm,
    CompaniesC,
  );
}

function createFreeSubscription(companyId: number, createSubscriptionForm: CreateSubscriptionForm): Promise<Company[]> {
  return CommonApi.post(
    `/companies/${companyId}/subscription/free`,
    createSubscriptionForm,
  );
}

function updateSubscription(
  subscriptionId: string,
  createSubscriptionForm: UpdateSubscriptionForm,
): Promise<void> {
  return CompanyResourcesApi.put(
    `/subscription/${subscriptionId}`,
    createSubscriptionForm,
  );
}

function cancelSubscription(subscriptionId: string): void {
  CompanyResourcesApi.post(
    `/subscription/${subscriptionId}/cancel`,
    null,
    'Error while subscription cancel',
  );
}

function pauseSubscription(subscriptionId: string): void {
  CompanyResourcesApi.post(
    `/subscription/${subscriptionId}/pause`,
    null,
    'Error while subscription pause',
  );
}

function renewSubscription(subscriptionId: string): Promise<void> {
  return CompanyResourcesApi.post(
    `/subscription/${subscriptionId}/reactivate`,
    null,
    'Error while subscription renew',
  );
}

function resumeSubscription(subscriptionId: string): Promise<void> {
  return CompanyResourcesApi.post(
    `/subscription/${subscriptionId}/resume`,
    null,
    'Error while subscription resume',
  );
}

function getUpdateSubscriptionEstimation(
  subscriptionId: string,
  form: EstimateSubscriptionForm,
): Promise<EstimateSubscriptionModel> {
  return CompanyResourcesApi.postV(
    `/subscription/${subscriptionId}/estimate`,
    form,
    EstimateSubscriptionModelC,
  );
}

function getCurrentSubscriptionEstimation(subscriptionId: string): Promise<EstimateSubscriptionModel> {
  return CompanyResourcesApi.getV(
    `/subscription/${subscriptionId}/estimate`,
    EstimateSubscriptionModelC,
  );
}

function getCreateSubscriptionEstimation(
  subscriptionType: SubscriptionType,
  form: EstimateSubscriptionForm,
): Promise<EstimateSubscriptionModel> {
  return CommonApi.postV(
    `/billing/${subscriptionType}/subscription/estimate`,
    form,
    EstimateSubscriptionModelC,
  );
}

function getBillingInfo(subscriptionType: SubscriptionType): Promise<CompanyBillingInfoModel> {
  return CommonApi.getV(
    `user/billing/info/${subscriptionType}`,
    CompanyBillingInfoModelC,
    'Error while fetching user\'s own company billing info');
}

function prolongateTrial(id: string): Promise<void> {
  return CompanyResourcesApi.put(
    `/subscription/${id}/trial/prolongate`,
    undefined,
  );
}

export const SubscriptionApi = {
  getSubscriptionPlans,
  createSubscription,
  updateSubscription,
  cancelSubscription,
  renewSubscription,
  resumeSubscription,
  setSubscriptionPaymentMethod,
  getUpdateSubscriptionEstimation,
  getCreateSubscriptionEstimation,
  getBillingInfo,
  createFreeSubscription,
  pauseSubscription,
  prolongateTrial,
  getCurrentSubscriptionEstimation,
};
