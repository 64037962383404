import { SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';

import { DatabaseResourceListingActions } from '../actions/creators/database-resource-listing';
import { DatabaseResourceActionTypes } from '../actions/types/database-resource';
import { DatabaseResourceVariantActionTypes } from '../actions/types/database-resource-variant';


function* reloadResourceListing(): SagaIterator {
  try {
    yield put(DatabaseResourceListingActions.resetRequestStatuses());
  } catch (error) {
    console.error('database resource listing: reload resource listing failed', error);
  }
}


export function* databaseResourceListingSagas(): SagaIterator {
  yield takeLatest(DatabaseResourceActionTypes.CREATE_RESOURCE_SUCCEEDED, reloadResourceListing);
  yield takeLatest(DatabaseResourceActionTypes.UPDATE_RESOURCE_SUCCEEDED, reloadResourceListing);
  yield takeLatest(DatabaseResourceActionTypes.DELETE_RESOURCE_SUCCEEDED, reloadResourceListing);
  yield takeLatest(DatabaseResourceVariantActionTypes.CREATE_RESOURCE_VARIANT_SUCCEEDED, reloadResourceListing);
  yield takeLatest(DatabaseResourceVariantActionTypes.UPDATE_RESOURCE_VARIANT_SUCCEEDED, reloadResourceListing);
  yield takeLatest(DatabaseResourceVariantActionTypes.DELETE_RESOURCE_VARIANT_SUCCEEDED, reloadResourceListing);
}
