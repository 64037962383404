import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  ConnectDragSource,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
} from 'react-dnd';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { Checkbox, KreoIconSubnavForward } from 'common/UIKit';
import { WorkPackageAction } from '../../actions';
import { WorkPackagesData } from '../../interfaces';
import { ItemSource } from '../targets';


import './index.scss';

interface CollectDragProps {
  connectDragSource: ConnectDragSource;
  isDragging: boolean;
}

const collect = (conn: DragSourceConnector, monitor: DragSourceMonitor): CollectDragProps => {
  return {
    connectDragSource: conn.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

interface ReduxActions {
  /**
   * @description check/uncheck current group
   */
  selectGroup: (id: number) => void;
  setActiveGroup: (group: WorkPackagesData.GroupOfActivity) => void;
}

interface Props extends ReduxActions, CollectDragProps {
  groupItem: WorkPackagesData.GroupOfActivity;
  isSelected: boolean;
  getIdsByHover: (ids: number[]) => void;
}

class ActivityGroupItemComponent extends React.Component<Props> {

  public render(): JSX.Element {
    const { groupItem, isSelected } = this.props;
    const { nameGroup, count } = groupItem;
    const { connectDragSource } = this.props;

    return connectDragSource(
      <div
        className={`activity-group-item ${isSelected ? 'selected' : ''}`}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
      >
        <span className='activity-group-item__draggable-indicator'>{'░'}</span>
        <div className='activity-group-item__checkbox'>
          <Checkbox checked={isSelected} onChange={this.onGroupCheck} />
        </div>
        <div className='activity-group-item__information' onClick={this.onClick}>
          <div className='activity-group-item__information-title' title={nameGroup}>{nameGroup}</div>
          <div className='activity-group-item__information-info'>{count}</div>
          <div className='activity-group-item__information-icon'>
            <KreoIconSubnavForward />
          </div>
        </div>
      </div>,
    );
  }

  @autobind
  private onMouseOver(): void {
    if (typeof this.props.getIdsByHover === 'function') {
      const { groupItem } = this.props;
      const ids = groupItem.activities.reduce((total: number[], activity) => [...total, ...activity.engineids], []);
      this.props.getIdsByHover(ids);
    }
  }

  @autobind
  private onMouseLeave(): void {
    if (typeof this.props.getIdsByHover === 'function') {
      this.props.getIdsByHover(null);
    }
  }

  @autobind
  private onClick(): void {
    const { groupItem } = this.props;
    this.props.setActiveGroup(groupItem);
  }

  @autobind
  private onGroupCheck(): void {
    const { idGroup } = this.props.groupItem;
    this.props.selectGroup(idGroup);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    selectGroup: (id: number): void => {
      dispatch(WorkPackageAction.selectGroup(id));
    },
    setActiveGroup: (group: WorkPackagesData.GroupOfActivity): void => {
      dispatch(WorkPackageAction.setActiveGroup(group));
    },
  };
};

const connected = connect(null, mapDispatchToProps)(ActivityGroupItemComponent);
export const ActivityGroupItem = DragSource('ACTIVITY_GROUP', ItemSource, collect)(connected);
