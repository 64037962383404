import { useCallback } from 'react';

export function useChange(
  variants: number[],
  onChange: (value: number) => void,
): { onClick: (index: number) => void, onBlur: (value: string) => void } {
  const onClick = useCallback((index: number) => {
    onChange(variants[index]);
  }, [onChange, variants]);

  const onBlur = useCallback((value: string) => {
    const numberValue = Number(value);
    if (!Number.isNaN(numberValue) && numberValue > 1) {
      onChange(numberValue);
    }
  }, [onChange]);

  return { onClick, onBlur };
}
