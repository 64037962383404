import styled from 'styled-components';


const RadioMenuContainer = styled.div`
  position: relative;
`;

const Discount = styled.div`
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 57px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  border-radius: 6px;
  background-color: ${props => props.theme.color.pale};
  margin-right: 5px;
`;

export const Styled = {
  RadioMenuContainer,
  Discount,
};
