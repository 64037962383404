import { H6 } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const HEADER_HEIGHT = 40;

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
  grid-gap: 20px 20px;
  margin: 10px 0 40px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  gap: 10px;

  ${H6} {
    line-height: 1px;
  }
`;

const Button = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: end;
`;

const Folder = styled.div`
  border: 1px solid ${props => props.theme.color.background};
  border-radius: 10px;
  display: flex;
  height: 40px;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 10px;
  gap: 10px;
  cursor: pointer;

  svg {
    flex-shrink: 0;
    fill: ${p => p.theme.color.gray};
  }

  > div {
    overflow: hidden;
  }

  :hover {
    border-color: ${props => props.theme.color.turquoise};
  }
`;

const BreadcrumbsWrapper = styled.div`
  overflow-x: scroll;
  margin-bottom: 5px;
`;

const MenuWrapper = styled.div<{ left: number, top: number }>`
  position: absolute;
  left: ${p => p.left - 180}px;
  top: ${p => p.top + 20}px;
  z-index: 1;
`;

export const Styled = {
  Grid,
  Header,
  Button,
  Folder,
  BreadcrumbsWrapper,
  MenuWrapper,
};
