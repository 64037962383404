import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: ${props => props.theme.color.backgroundRush};
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BodyContainer = styled.div`
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MessageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContinueButtonContainer = styled.div`
  width: fit-content;
  margin-top: 30px;

  > button {
    > svg {
      margin: 0 20px 0 0px;
    }
  }
`;

export const Styled = {
  Container,
  HeaderTextContainer,
  BodyContainer,
  MessageContentContainer,
  ContinueButtonContainer,
};
