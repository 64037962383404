import styled from 'styled-components';

const Container = styled.div`
  border-top: 1px solid ${p => p.theme.color.background};
  padding-top: 10px;
`;

export const Styled = {
  Container,
};
