import { RevisionState } from 'unit-cost-estimate/interfaces';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';

export class RevisionStateHelper {
  public static shouldLoadActivityAssignmentModel(
    currentState: RevisionState, previousState: RevisionState,
  ): boolean {
    return RevisionStateHelper.shouldLoadModel(currentState, previousState, 'activityAssignmentStatus');
  }

  public static shouldLoadMeasurementModel(
    currentState: RevisionState, previousState: RevisionState,
  ): boolean {
    return RevisionStateHelper.shouldLoadModel(currentState, previousState, 'measurementsStatus');
  }

  private static shouldLoadModel(
    currentState: RevisionState, previousState: RevisionState, moduleName: string,
  ): boolean {
    return (
      currentState && currentState[moduleName] === ViewModelStatus.Ready
    ) && (
      !previousState || previousState[moduleName] !== ViewModelStatus.Ready
    );
  }
}
