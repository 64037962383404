import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './measurements-extractor-editor-group.scss';

import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { MeasurementsExtractorEditorRowType } from '../../enums/measurements-extractor-editor-row-type';
import { MeasurementsUtils } from '../../utils/measurements-utils';
import { MeasurementExtractorEditorRow } from '../measurements-extractor-editor-row';

interface StateProps {
  indeterminate: boolean;
  isSelected: boolean;
}

interface OwnProps {
  isDisabled: boolean;
  index: number;
  name: string;
  onCheck: (index: number, value: boolean) => void;
}

interface Props extends StateProps, OwnProps {
}

class MeasurementsExtractorEditorGroupComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isDisabled, isSelected, index, name, indeterminate } = this.props;
    return (
      <MeasurementExtractorEditorRow
        indeterminate={indeterminate}
        isSelected={isSelected}
        isDisabled={isDisabled}
        isGroup={true}
        onCheck={this.onCheck}
        index={index}
        title={name}
        isError={false}
        onDisabledItemClick={ConstantFunctions.doNothing}
        checkBoxClassName='measurements-extractor-editor-group__checkbox-area'
      />
    );
  }

  @autobind
  private onCheck(value: boolean): void {
    this.props.onCheck(this.props.index, value);
  }
}


function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const { selected, indeterminate } =
    MeasurementsUtils.extractorsGroupSelectionStatus(
      ownProps.index,
      state.measurements,
      MeasurementsExtractorEditorRowType.GroupHeader,
    );
  return {
    isSelected: selected,
    indeterminate,
  };
}

const connector = connect(mapStateToProps);
export const MeasurementsExtractorEditorGroup = connector(MeasurementsExtractorEditorGroupComponent);
