import * as React from 'react';

import './faq-link.scss';

import { FaqUrl } from 'common/constants/faq-url';
import { KreoIconInfoBigColor } from '../icons';

interface Props {
  caption: string;
}

export const FaqLink: React.FC<Props> = (props) => {
  if (!FaqUrl[props.caption]) {
    return null;
  }

  return (
    <a
      className='faq-link'
      href={FaqUrl[props.caption]}
      target='_blank'
    >
      <i className='faq-link__icon' title={props.caption}>
        <KreoIconInfoBigColor />
        </i>
      <span className='faq-link__name'>
        {props.caption}
      </span>
    </a>
  );
};
