import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import {
  GetQuantityTakeOffModelSucceededPayload,
  QtoElementalViewHighlightPayload,
  QtoSelectElementsPayload,
} from '../actions/payloads/quantity-take-off';
import { QuantityTakeOffActionTypes } from '../actions/types/quantity-take-off';
import { QuantityTakeOffInitialState } from '../constants/quantity-take-off-initial-state';
import { QtoElementalViewBreakdownType } from '../enums/qto-elemental-view-breakdown-type';
import { GraphStorageRecordsConfig } from '../interfaces/graph-storage-records-config';
import { QuantityTakeOffLinearTreeNode } from '../interfaces/quantity-take-off/quantity-take-off-linear-tree-node';
import { QtoRecords } from '../interfaces/quantity-take-off/quantity-take-off-records';
import { QuantityTakeOffState } from '../interfaces/quantity-take-off/quantity-take-off-state';
import { QuantityTakeOffTreeBuilder } from '../pages/quantity-take-off/tree-builder';

export const quantityTakeOffReducerMethods: ReducerMethods<QuantityTakeOffState> = {
  [QuantityTakeOffActionTypes.GET_MODEL_REQUEST]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.getModelRequestStatus, RequestStatus.Loading)
      .get();
  },
  [QuantityTakeOffActionTypes.GET_MODEL_SUCCEEDED]: (
    s: QuantityTakeOffState,
    { model, projectId }: GetQuantityTakeOffModelSucceededPayload,
  ) => {
    arrayUtils.sortByField(model.elements, 0, model.elements.length - 1, 'bimId');
    const revitTree = QuantityTakeOffTreeBuilder.buildRevitTree(model);

    return new MonoliteHelper(s)
      .set(_ => _.model, model)
      .set(_ => _.tree, revitTree)
      .set(_ => _.loadedProjectId, projectId)
      .set(_ => _.getModelRequestStatus, RequestStatus.Loaded)
      .get();
  },
  [QuantityTakeOffActionTypes.GET_ELEMENT_RECORDS]: (
    s: QuantityTakeOffState,
    payload: { records: QtoRecords },
  ) => {
    return new MonoliteHelper(s)
      .set(_ => _.elementRecords, payload.records)
      .set(_ => _.getModelRequestStatus, RequestStatus.Loaded)
      .set(_ => _.isAddNewPropsInRecords, true)
      .get();
  },
  [QuantityTakeOffActionTypes.GET_RECORDS_CONFIG]: (
    s: QuantityTakeOffState,
    payload: GraphStorageRecordsConfig,
  ) => {
    return new MonoliteHelper(s)
      .set(_ => _.recordsConfig, payload)
      .get();
  },
  [QuantityTakeOffActionTypes.UPDATE_STATE_AFTER_ADD_NEW_PROPS]: (s: QuantityTakeOffState) => {
    return new MonoliteHelper(s)
      .set(_ => _.isAddNewPropsInRecords, false)
      .get();
  },
  [QuantityTakeOffActionTypes.GET_MODEL_FAILED]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.model, null)
      .set(_ => _.getModelRequestStatus, RequestStatus.Failed)
      .get();
  },
  [QuantityTakeOffActionTypes.TOGGLE_NODE_EXPANSION]: (s, nodeIndex: number) => {
    const stateUpdater = new MonoliteHelper(s);

    const isExpanded = s.tree.expandedRowsIndexes.includes(nodeIndex);
    if (isExpanded) {
      stateUpdater.setFilter(_ => _.tree.expandedRowsIndexes, index => index !== nodeIndex);
    } else {
      stateUpdater.setAppend(_ => _.tree.expandedRowsIndexes, nodeIndex);
    }

    return stateUpdater.get();
  },
  [QuantityTakeOffActionTypes.HIGHLIGHT_TREE_NODE]: (s, nodeIndex: number | null) => {
    const stateUpdater = new MonoliteHelper(s);
    stateUpdater.set(_ => _.tree.highlightedNodeIndex, nodeIndex);

    if (nodeIndex !== null) {
      // expand branch to target node

      const node = s.tree.nodes[nodeIndex];

      const getNodeParent = (n: QuantityTakeOffLinearTreeNode): QuantityTakeOffLinearTreeNode =>
        n.parentIndex !== null ? s.tree.nodes[n.parentIndex] : null;

      let currentNode = node;
      const nodesToExpandIndexes = [];

      while (getNodeParent(currentNode) !== null) {
        nodesToExpandIndexes.push(currentNode.parentIndex);
        currentNode = getNodeParent(currentNode);
      }

      for (const nodeToExpandIndex of nodesToExpandIndexes) {
        if (!s.tree.expandedRowsIndexes.includes(nodeToExpandIndex)) {
          stateUpdater.setAppend(_ => _.tree.expandedRowsIndexes, nodeToExpandIndex);
        }
      }
    }

    return stateUpdater.get();
  },
  [QuantityTakeOffActionTypes.SELECT_BIM_ELEMENTS]: (s, { bimIds, eventSource }: QtoSelectElementsPayload) => {
    const stateUpdater = new MonoliteHelper(s);

    return stateUpdater
      .set(_ => _.selectedBimElementsIds, bimIds)
      .set(_ => _.selectElementsEventSource, eventSource)
      .get();
  },
  [QuantityTakeOffActionTypes.CHANGE_EXPAND_STATUS]: (s, status?: boolean) => {
    const stateUpdater = new MonoliteHelper(s);

    return stateUpdater
      .set(_ => _.expandTable, status)
      .get();
  },
  [QuantityTakeOffActionTypes.DROP_STATE]: () => QuantityTakeOffInitialState,
  [QuantityTakeOffActionTypes.OPEN_ELEMENTAL_VIEW]: (s, selectedElementalViewLeafIndex: number) => {
    return new MonoliteHelper(s)
      .set(_ => _.tree.elementalView.selectedTreeLeafIndex, selectedElementalViewLeafIndex)
      .get();
  },
  [QuantityTakeOffActionTypes.CLOSE_ELEMENTAL_VIEW]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.tree.elementalView.selectedTreeLeafIndex, null)
      .set(_ => _.tree.elementalView.highlightedElementsIds, null)
      .set(_ => _.tree.elementalView.breakdown, QtoElementalViewBreakdownType.ByLevel)
      .get();
  },
  [QuantityTakeOffActionTypes.HIGHLIGHT_ELEMENTAL_VIEW_ELEMENTS]: (
    s,
    { elementsIds, eventSource }: QtoElementalViewHighlightPayload,
  ) => {
    return new MonoliteHelper(s)
      .set(_ => _.tree.elementalView.highlightedElementsIds, elementsIds)
      .set(_ => _.tree.elementalView.highlightEventSource, eventSource)
      .get();
  },
  [QuantityTakeOffActionTypes.CHANGE_ELEMENTAL_VIEW_BREAKDOWN]: (
    s,
    newType: QtoElementalViewBreakdownType,
  ) => {
    return new MonoliteHelper(s)
      .set(_ => _.tree.elementalView.breakdown, newType)
      .get();
  },
  [QuantityTakeOffActionTypes.FILTER_TREE]: (s, bimIds) => {
    return new MonoliteHelper(s)
      .set(_ => _.filteredIds, bimIds)
      .get();
  },
  [QuantityTakeOffActionTypes.SET_GLOBAL_CONFIG]: (s, config) => {
    return new MonoliteHelper(s)
      .set(_ => _.globalConfig, config)
      .get();
  },
};
