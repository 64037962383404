import { Menu } from '@material-ui/core';
import autobind from 'autobind-decorator';
import * as React from 'react';

import './popover-button.scss';

import { KreoButton } from 'common/UIKit';

interface Props {
  btnText: string;
  btnStyle?: React.CSSProperties;
  btnClassName?: string;
}

interface State {
  openMenu: boolean;
  anchorEl: HTMLElement;
}

export class PopoverButton extends React.Component<Props, State> {
  private anchorOrigin: any = { horizontal: 'left', vertical: 'bottom' };
  private targetOrigin: any = { horizontal: 'left', vertical: 'top' };

  constructor(props: Props) {
    super(props);

    this.state = {
      openMenu: false,
      anchorEl: null,
    };
  }

  public render(): JSX.Element {
    const { btnText, btnStyle, btnClassName, children } = this.props;

    return (
      <div className='popover-button'>
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.openMenu}
          onClose={this.closeSelect}
          getContentAnchorEl={null}
          anchorOrigin={this.anchorOrigin}
          transformOrigin={this.targetOrigin}
          onClick={this.onClose}
        >
          {children}
        </Menu>
        <KreoButton
          style={btnStyle}
          className={btnClassName}
          onClick={this.openSelect}
          size='small'
        >
          {btnText}
        </KreoButton>
      </div>
    );
  }

  @autobind
  private onClose(): void {
    this.setState({
      openMenu: false,
    });
  }

  @autobind
  private openSelect(event: React.MouseEvent<HTMLButtonElement>): void {
    this.setState({
      openMenu: true,
      anchorEl: event.currentTarget,
    });
  }

  @autobind
  private closeSelect(): void {
    this.setState({
      openMenu: false,
    });
  }
}
