import * as React from 'react';

export const KreoIconIsolate = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <polyline
      points='14 6 14 10 20 4'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <line
      x1='18'
      y1='10'
      x2='14'
      y2='10'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <polyline
      points='10 6 10 10 4 4'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <line
      x1='6'
      y1='10'
      x2='10'
      y2='10'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <polyline
      points='14 18 14 14 20 20'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <line
      x1='18'
      y1='14'
      x2='14'
      y2='14'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <polyline
      points='10 18 10 14 4 20'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <line
      x1='6'
      y1='14'
      x2='10'
      y2='14'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
