import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconDbCreate: React.FC = (_props) => {
  return (
    <svg width='60px' height='60px' viewBox='0 0 60 60' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='icon_database_empty' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <path
              d='M48,23.3257079 C44.9324751,26.2551891 38.5153847,28 30,28 C21.4846153,28 15.0675249,26.2551891
              12,23.3257079 L12,32.4049584 C23.2499508,38.3993089 36.7500492,38.3993089 48,32.4049584 L48,23.3257079
              Z M50,32.9799262 C50.0002459,32.9927219 50.0002453,33.0055071 50,33.0182747 L50,41.0833333 C50,46.4979357
              42.1294426,50 30,50 C17.8705574,50 10,46.4979357 10,41.0833333 L10,33.0182749 C9.99975474,33.0055071
              9.99975406,32.9927219 10,32.9799262 L10,18.9166667 C10,13.5020643 17.8705574,10 30,10 C42.1294426,10
              50,13.5020643 50,18.9166667 L50,32.9799237 Z M48,34.6554429 C36.670943,40.3158141 23.329057,40.3158141
              12,34.6554429 L12,41.0833333 C12,44.9507673 18.8528613,48 30,48 C41.1471387,48 48,44.9507673 48,41.0833333
              L48,34.6554429 Z M30,26 C41.14832,26 48,22.9192012 48,19 C48,15.0807988 41.14832,12 30,12 C18.85168,12
              12,15.0807988 12,19 C12,22.9192012 18.85168,26 30,26 Z'
              id='Combined-Shape'
              fill={KreoColors.f4}
              fillRule='nonzero'
            />
        </g>
    </svg>
  );
};
