import autobind from 'autobind-decorator';
import React from 'react';
import { Field } from 'redux-form';

import { KreoIconCloseSmall, MaterialNumberInputField, MaterialNumberInputProps } from 'common/UIKit';
import { GreaterThan } from '../../../../components/dialog/validators';
import { ActivityVariantResource, UnitModel } from '../../interfaces/data';
import { MaterialModel } from '../../interfaces/resources-data';

const quantityGreaterThan0 = GreaterThan(0, 'Quantity');

interface Props {
  fieldKey: string;
  index: number;
  readonly: boolean;
  material: ActivityVariantResource<MaterialModel>;
  onQuantityChange: (materialId: number, quantity: number) => void;
  onDelete: (materialId: number) => void;
  unitMap: Record<number, UnitModel>;
}

export class DatabaseActivityVariantMaterialItem extends React.Component<Props> {
  public render(): JSX.Element {
    const { unitMap, material } = this.props;

    return (
      <div className='database-entity-modify-wrap-tile material'>
        <div className='database-entity-modify-wrap-tile__name'>
          <b>{material.resource.name}</b>
        </div>
        <div className='database-entity-modify-wrap-tile__content'>
          <Field<MaterialNumberInputProps>
            name={`${this.props.fieldKey}.amount`}
            component={MaterialNumberInputField}
            className='database-entity-modify-wrap-tile__quantity'
            label='Quantity'
            isFloatingLabel={false}
            value={material.amount}
            onChange={this.onChange}
            placeholder='0.00'
            precision={2}
            valueType='float'
            disabled={this.props.readonly}
            displayBottomInformation={true}
            validate={quantityGreaterThan0}
          />
          <div className='database-entity-modify-wrap-tile__key'>
            <div className='database-entity-modify-wrap-tile__label'>Unit</div>
            <div className='database-entity-modify-wrap-tile__value'>
              {unitMap[material.unitId].acronym}
            </div>
          </div>
          {
            !this.props.readonly ? (
              <div onClick={this.onDelete} className='database-entity-modify-wrap-tile__del-btn'>
                <KreoIconCloseSmall/>
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }

  @autobind
  private onDelete(): void {
    this.props.onDelete(this.props.index);
  }

  @autobind
  private onChange(_: React.ChangeEvent, value?: number): void {
    this.props.onQuantityChange(this.props.index, value);
  }
}
