import { RegexpUtil } from 'common/utils/regexp-util';
import { OS } from './os-detector';
import { OsDetector } from '.';

const macToWindowsKeysMap = {
  '⌥': 'alt',
  'option': 'alt',
  '^': 'ctrl',
  '⌘': 'ctrl',
  'cmd': 'ctrl',
  'command': 'ctrl',
};

const windowsToMacKeysMap = {
  'alt': '⌥',
  'ctrl': '⌘',
};

const macKeysToShow = {
  '⌥': '⌥ option',
  'option': '⌥ option',
  '^': '⌘ command',
  '⌘': '⌘ command',
  'cmd': '⌘ command',
  'command': '⌘ command',
};

const getMapRegexp = (map): RegExp => new RegExp(Object.keys(map).map(RegexpUtil.escapeRegExp).join('|'), 'g');

const replaceHotkeys = (hotkey: string, map: Record<string, string>): string => {
  const regexp = getMapRegexp(map);
  return hotkey.toLowerCase().replace(regexp, (key) => map[key]);
};


const toWindowsHotkey = (hotkey): string => replaceHotkeys(hotkey, macToWindowsKeysMap);
const toMacHotkey = (hotkey): string => replaceHotkeys(hotkey, windowsToMacKeysMap);

const isCtrlOrCommandKeyDown = (e: { ctrlKey: boolean, metaKey: boolean }): boolean => {
  const os = OsDetector.getOs();
  return os === OS.Windows && e.ctrlKey
    || os === OS.Mac && e.metaKey;
};

const joinedMackKeys = Object.keys(macKeysToShow).map(x => `(${RegexpUtil.escapeRegExp(x)})`).join('|');
const macKeysRegexp = new RegExp(`${joinedMackKeys}`, 'g');
const getShortcutToShow = (hotkey): string => {
  return hotkey && hotkey
    .toLowerCase().replace(macKeysRegexp, (x) => macKeysToShow[x])
    .replace(/\b\w/g, l => l.toUpperCase());
};


export const HotkeyMultiOsHelper = {
  toWindowsHotkey,
  toMacHotkey,
  getShortcutToShow,
  isCtrlOrCommandKeyDown,
};
