import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { DrawingsSelectAggregationGroup } from '../interfaces';
import { DrawingInstancesAggregationUtils } from '../utils';
import { DrawingsMeasureUtils } from '../utils/drawings-measure-utils';
import { useDrawModeApi } from './use-draw-mode-api';

export function useNewInstanceAggregationType(): DrawingsSelectAggregationGroup {
  const { drawMode } = useDrawModeApi();
  const wizzardWandIsPolygon = useSelector<State, boolean>(s => {
    return s.drawings.wizzardTools.connect && s.drawings.wizzardTools.enclose;
  });
  const dropperType = useSelector<State, DrawingsSelectAggregationGroup>(
    s => s.drawings.wizzardTools.dropperInstanceType,
  );

  const finderIsCount = useSelector<State, boolean>(
    s => s.drawings.wizzardTools.isCount,
  );

  return useMemo(() => {
    const instanceType = DrawingsMeasureUtils.getInstanceTypeFromDrawMode({
      drawMode,
      wizzardIsPolygon: wizzardWandIsPolygon,
      dropperType,
      finderIsCount,
    });
    return DrawingInstancesAggregationUtils.INSTANCE_TYPE_TO_AGGREGATION[instanceType];
  }, [wizzardWandIsPolygon, dropperType, drawMode, finderIsCount]);
}
