import styled from 'styled-components';

const Cell = styled.div<{ isSelected?: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 30px;
  min-width: 30px;
  padding: 0 4px;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 28px;
  color: ${p => p.isSelected ? p.theme.color.turquoise : p.theme.color.mainFont};
  background: ${p => p.theme.color.background};
  border: 1px solid ${p => p.isSelected ? p.theme.color.turquoise : p.theme.color.background};
  transition: ${props => props.theme.duration.s} ease;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-color: ${p => p.theme.color.background};
  border-style: solid;
  border-width: 0;
  border-left-width: 1px;
  padding: 0px 10px;
  height: 100%;
  min-width: 100px;
  max-width: 130px;
  overflow: hidden;
  cursor: pointer;

  &:last-child {
    border-right-width: 1px;
  }

  &:hover ${Cell} {
    background: transparent;
    border: 1px solid ${p => p.theme.color.turquoise};
  }
`;

const Sheet = styled.span`
  padding: 0px 10px;
`;

export const Styled = {
  Container,
  Cell,
  Sheet,
};
