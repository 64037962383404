import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';

import './graph.scss';

import { Coordinate, EdgeControlsComponentProps, EdgeWithDisplayType } from './interfaces';
import { PositionPxHelper } from './position-px-helper-provider';


interface Props {
  edges: EdgeWithDisplayType[];
  vertexPositions: Record<number, Coordinate>;
  positionPxHelper: PositionPxHelper;
  vertexWidth: number;
  edgeControlsComponent: React.ComponentType<EdgeControlsComponentProps>;
  onChangeEditStatus: (isEdit: boolean) => void;
}


export class GraphEdgeControls extends React.Component<Props> {
  public shouldComponentUpdate(prevProps: Props): boolean {
    const props = this.props;
    return !isEqual(props.edges, prevProps.edges) ||
      !isEqual(props.positionPxHelper, prevProps.positionPxHelper);
  }

  public render(): JSX.Element {
    const edges = this.props.edges;
    return (
      <>
        {edges.map(this.renderEdge)}
      </>
    );
  }

  @autobind
  private renderEdge(edge: EdgeWithDisplayType): React.ReactNode {
    const {
      vertexPositions,
      positionPxHelper,
      vertexWidth,
      edgeControlsComponent: EdgeControlsComponent,
    } = this.props;

    const fromPosition = vertexPositions[edge.fromId];
    const fromPxPosition = positionPxHelper.getPxPositions(fromPosition.x, fromPosition.y);
    const toPosition = vertexPositions[edge.toId];
    const toPxPosition = positionPxHelper.getPxPositions(toPosition.x, toPosition.y);
    const key = `edgeControl/${edge.fromId}/${edge.toId}`;
    return (
      <EdgeControlsComponent
        key={key}
        from={fromPxPosition}
        to={toPxPosition}
        direction={edge.direction}
        displayType={edge.displayType}
        source={edge.fromId}
        target={edge.toId}
        vertexWidth={vertexWidth}
        onChangeEditStatus={this.props.onChangeEditStatus}
      />
    );
  }
}
