import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';
import { DialogContainerStyled } from './components';

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 10;
  animation: ${Constants.Animations.slowTopAppear()} ${p => p.theme.duration.s} linear;
  box-shadow: ${p => p.theme.shadow.xl};
  background: ${p => p.theme.color.backgroundRush};
  border-radius: 20px;
  >:not(:last-child) {
    border-right:  1px solid ${p => p.theme.color.background};
  }
`;

const OpenPolygonDialog = styled(DialogContainerStyled.Container)`
  width: 250px;
`;

const OpenDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${DialogContainerStyled.Switcher} {
    padding: unset;
  }
  padding: 0 20px 20px 20px;
`;

export const Styled = {
  Container,
  OpenPolygonDialog,
  OpenDialogBody,
};
