import {
  Input,
  ModalWrapper,
  RectangleButton,
  Switcher,
  Text,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { Canceler } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { change, Field, FormSubmitHandler, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { FileUploadWrap } from 'common/components/file-upload-wrap';
import { ImageUploader } from 'common/components/image-uploader';
import { ApiConstants } from 'common/constants/api';
import { ConstantFunctions } from 'common/constants/functions';
import { DemoProjectType } from 'common/constants/project-type';
import { DemoProjectTypeToFileExtension } from 'common/constants/project-type-to-file-extensions';
import { UploadingFile } from 'common/interfaces/common-state';
import { InputPropsInterface } from 'common/interfaces/library/input';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { Common } from '../../../../../actions';
import { CommonsApi } from '../../../../../api/common';
import Form from '../../../../../components/form';
import { CREATE_DEMO_PROJECT } from '../../../../../constants/forms';
import { Styled } from './styled';

export const CREATE_DEMO_PROJECT_DIALOG_NAME = 'CREATE_DEMO_PROJECT_DIALOG_NAME';

const selector = formValueSelector(CREATE_DEMO_PROJECT);

interface StateProps {
  files: UploadingFile[];
  projectName: string;
  orderIndex: number;
  isDownloadFileInProgress: boolean;
  cancelDownLoadFile: Canceler;
  disableSubmit: boolean;
  isImperial: boolean;
}

interface DispatchProps {
  closeDialog: () => void;
  setImage: (value: string) => void;
  setProjectName: (value: string) => void;
  setOrderIndex: (value: number) => void;
  clearFiles: () => void;
  removeDownloadFileName: () => void;
  setType: (type: DemoProjectType) => void;
  setIsImperial: (value: boolean) => void;
}

interface OwnProps {
  projectType: DemoProjectType;
}

interface Props extends OwnProps, StateProps, DispatchProps, InjectedFormProps<{}, OwnProps> { }

class Create2dProjectDialogComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      isDownloadFileInProgress,
      disableSubmit,
    } = this.props;

    return (
      <DialogWrapper name={CREATE_DEMO_PROJECT_DIALOG_NAME}>
        <ModalWrapper onExit={this.onCloseDialog}>
          <Styled.Container>
            <Form
              handleSubmit={this.handleSubmit}
            >
              <Styled.InputWrapper>
                <ImageUploader
                  previewUrl={CommonsApi.tempFileLink}
                  defaultImagePreviewUrl={''}
                  deleteImage={ConstantFunctions.doNothing}
                  onLoadEnd={this.props.setImage}
                  size={'big'}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Field<InputPropsInterface>
                  width={'340px'}
                  name='name'
                  component={Input}
                  type='text'
                  text={'Project name'}
                />
              </Styled.InputWrapper>
              <FileUploadWrap
                onUploadStarted={this.onUploadStarted}
                isDownloadFileInProgress={isDownloadFileInProgress}
                onDeleteFile={this.onDeleteFile}
                formName={CREATE_DEMO_PROJECT_DIALOG_NAME}
                extensions={this.fileExtenions}
                extensionsToShow={this.fileExtenions}
              />
              <Styled.Separator />
              <Styled.InputWrapper>
                <Text>Is Imperial</Text>
                <Switcher
                  size='l'
                  checked={this.props.isImperial}
                  onChange={this.setIsImperial}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Field<InputPropsInterface>
                  width={'340px'}
                  isMathInput={true}
                  name='orderIndex'
                  component={Input}
                  type='text'
                  text={'Order Index'}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Field<InputPropsInterface>
                  width={'340px'}
                  isMathInput={true}
                  name='description'
                  component={Input}
                  type='text'
                  text={'Description'}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Field<InputPropsInterface>
                  width={'340px'}
                  isMathInput={true}
                  name='tags'
                  component={Input}
                  type='text'
                  text={'Tags'}
                />
              </Styled.InputWrapper>
              <RectangleButton
                width={340}
                height={60}
                mood={disableSubmit ? 'disabled' : 'positive'}
                text='Create project'
              />
            </Form>
          </Styled.Container>
        </ModalWrapper>
      </DialogWrapper>
    );
  }

  @autobind
  private setIsImperial(): void {
    this.props.setIsImperial(!this.props.isImperial);
  }

  @autobind
  private onCloseDialog(): void {
    this.props.closeDialog();
    this.props.clearFiles();
    this.props.reset();
    this.props.setProjectName('');
    if (this.props.isDownloadFileInProgress) {
      this.props.cancelDownLoadFile(ApiConstants.RequestCancelMessage);
      this.props.removeDownloadFileName();
    }
  }

  private get fileExtenions(): string[] {
    return DemoProjectTypeToFileExtension[this.props.projectType].default;
  }

  private getExtensionsRegExp(extensions: string[]): RegExp {
    const regexExtensions = extensions
      .map(x => `\\.${x}`)
      .join('|');

    return new RegExp(`(${regexExtensions})$`, 'i');
  }

  @autobind
  private onDeleteFile(file: UploadingFile): void {
    const { projectName, setProjectName, files } = this.props;
    const fileExtensionRegExp = this.getExtensionsRegExp(this.fileExtenions);
    if (file && file.file.name.replace(fileExtensionRegExp, '') === projectName) {
      if (files.length > 1) {
        setProjectName(files[1].file.name.replace(fileExtensionRegExp, ''));
      } else {
        setProjectName('');
      }
    }
  }

  @autobind
  private handleSubmit(handler: FormSubmitHandler<{}, {}>): void {
    if (!this.props.disableSubmit) {
      this.props.setType(this.props.projectType);
      this.props.handleSubmit(handler);
      this.onCloseDialog();
    }
  }

  @autobind
  private onUploadStarted(files: File[]): void {
    const { projectName, setProjectName } = this.props;
    if (!projectName && files && files.length) {
      const fileExtensionRegExp = this.getExtensionsRegExp(this.fileExtenions);
      setProjectName(files[0].name.replace(fileExtensionRegExp, ''));
    }
  }
}

function mapStateToProps(state: State): StateProps {
  const isDownloadFileInProgress = !!state.common.downloadFileInfo;
  return {
    isDownloadFileInProgress,
    files: state.common.files,
    projectName: selector(state, 'name'),
    orderIndex: selector(state, 'orderIndex'),
    cancelDownLoadFile: isDownloadFileInProgress ? state.common.downloadFileInfo.canceler : null,
    disableSubmit: !state.common.files.length
      || !selector(state, 'name')
      || state.common.files.some(f => f.progress < 1),
    isImperial: selector(state, 'isImperial'),
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(CREATE_DEMO_PROJECT_DIALOG_NAME)),
    setImage: (value) => dispatch(change(CREATE_DEMO_PROJECT, 'image', value)),
    setProjectName: (value) => dispatch(change(CREATE_DEMO_PROJECT, 'name', value)),
    setOrderIndex: (value) => dispatch(change(CREATE_DEMO_PROJECT, 'orderIndex', value)),
    setIsImperial: (value) => dispatch(change(CREATE_DEMO_PROJECT, 'isImperial', value)),
    clearFiles: () => dispatch(Common.commonClearFiles()),
    removeDownloadFileName: () => dispatch(Common.removeDownloadFileName()),
    setType: (type) => dispatch(change(CREATE_DEMO_PROJECT, 'type', type)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
const Create2dProjectDialogConnected = connector(Create2dProjectDialogComponent);

export const CreateDemoProjectDialog = reduxForm<{}, OwnProps>({
  form: CREATE_DEMO_PROJECT,
  destroyOnUnmount: true,
  initialValues: {
    isShared: true,
  },
})(Create2dProjectDialogConnected);
