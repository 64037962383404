import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';
import { CEActivityAssignmentActions } from 'unit-cost-estimate/actions/creators';
import { CEActivityAssignmentUtils } from 'unit-cost-estimate/utils/ce-activity-assignment-utils';
import { ValidationResizableEngineLayoutNew } from 'unit-projects/components/validation-resizable-engine-layout-new';
import { ValidationResizableEngineLayoutApi } from 'unit-projects/interfaces/validation-resizable-engine-layout-api';

import './activity-assignment-engine-layout.scss';
import { ActivityAssignmentRevitTreeLayout } from './activity-assignment-revit-tree-layout';

interface StateProps {
  selectedPath: string;
  selectedIds: number[];
  isSaveData: boolean;
  viewTree: boolean;
  isShowActivities: boolean;
  errorEngineIds: number[];
}

interface OwnProps {
  projectId: string;
  revisionId: string;
  children?: React.ReactNode;
}

interface DispatchProps {
  loadData: () => void;
  dropState: () => void;
  getRevitData: () => void;
  selectFromEngine: (id: number) => void;
  goTo: (newLocation: string) => void;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

class ActivityAssignmnetEngineLayoutComponent extends React.PureComponent<Props> {
  private layoutApi: ValidationResizableEngineLayoutApi = null;
  private isEngineSelect: boolean = true;

  public componentDidMount(): void {
    if (!this.props.isSaveData) {
      this.props.loadData();
      this.props.getRevitData();
    }
  }

  public componentWillUnmount(): void {
    this.props.dropState();
  }

  public componentDidUpdate(prevProps: Props): void {
    const { selectedPath, selectedIds, isShowActivities } = this.props;
    if (selectedPath) {
      this.layoutApi.showIds(selectedIds);
      if (this.props.selectedPath !== prevProps.selectedPath) {
        if (this.layoutApi.getSelected().length > 0) {
          this.isEngineSelect = false;
          this.layoutApi.setSelected([]);
        }
        this.layoutApi.showProperties(isShowActivities ? selectedIds || [] : []);
        this.layoutApi.focus(selectedIds);
      }
    }
  }

  public render(): React.ReactNode {
    return (
      <div className='activity-assignment-engine-layout'>
        <ActivityAssignmentRevitTreeLayout/>
        <ValidationResizableEngineLayoutNew
          errorEngineIds={this.props.errorEngineIds}
          isShowResizablePanel={this.props.isShowActivities}
          projectId={this.props.projectId}
          getApi={this.saveLayoutApi}
          engineSelect={this.engineSelect}
          className={classNames({ 'activity-assignmnet-engine-layout__resizable-layout': this.props.viewTree })}
        >
          {this.props.children}
        </ValidationResizableEngineLayoutNew>
      </div>
    );
  }

  @autobind
  private saveLayoutApi(api: ValidationResizableEngineLayoutApi): void {
    this.layoutApi = api;
  }

  @autobind
  private engineSelect(ids: number[]): void {
    if (!this.isEngineSelect) {
      this.isEngineSelect = true;
      return;
    }
    if (ids.length > 0 && !this.props.isShowActivities) {
      this.isEngineSelect = false;
      this.layoutApi.setSelected([]);
      this.props.selectFromEngine(ids[0]);
    }
  }
}

const mapStateToProps = (state: State): StateProps => {
  const { ceActivityAssignment } = state;

  return {
    errorEngineIds: ceActivityAssignment.badIds,
    selectedPath: ceActivityAssignment.selectedPath,
    selectedIds: CEActivityAssignmentUtils.getSelectedIds(ceActivityAssignment),
    isSaveData: ceActivityAssignment.isSaveData,
    viewTree: ceActivityAssignment.viewTree,
    isShowActivities: ceActivityAssignment.isShowActivities,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    loadData: () => dispatch(CEActivityAssignmentActions.loadData()),
    dropState: () => dispatch(CEActivityAssignmentActions.dropState()),
    getRevitData: () => dispatch(CEActivityAssignmentActions.getRevitData()),
    selectFromEngine: id => dispatch(CEActivityAssignmentActions.selectFromEngine(id)),
    goTo: newLocation => dispatch(push(newLocation)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityAssignmentEngineLayout = connector(ActivityAssignmnetEngineLayoutComponent);
