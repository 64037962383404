import { AnyAction } from 'redux';

import { AccountActionTypes } from '../units/account/actions/types';
import { DATABASE_INITIAL_STATE } from '../units/databases/constants/initial-state';
import { DatabaseReduxState } from '../units/databases/interfaces/redux-state';
import { DatabaseActivityListingReducerMethods } from '../units/databases/reducers/database-activity-listing-reducer';
import { DatabaseActivityReducerMethods } from '../units/databases/reducers/database-activity-reducer';
import { DatabaseActivityVariantReducerMethods } from '../units/databases/reducers/database-activity-variant-reducer';
import { DatabaseCommonReducerMethods } from '../units/databases/reducers/database-common-reducer';
import { DatabaseReducerMethods } from '../units/databases/reducers/database-reducer';
import { DatabaseResourceListingReducerMethods } from '../units/databases/reducers/database-resource-listing-reducer';
import { DatabaseResourceReducerMethods } from '../units/databases/reducers/database-resource-reducer';
import { DatabaseResourceVariantReducerMethods } from '../units/databases/reducers/database-resource-variant-reducer';


export default function database(
  state: DatabaseReduxState = DATABASE_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): DatabaseReduxState {
  if (action.type in DatabaseReducerMethods) {
    return DatabaseReducerMethods[action.type](state, action.payload);
  } else if (action.type in DatabaseActivityListingReducerMethods) {
    return DatabaseActivityListingReducerMethods[action.type](state, action.payload);
  } else if (action.type in DatabaseActivityReducerMethods) {
    return DatabaseActivityReducerMethods[action.type](state, action.payload);
  } else if (action.type in DatabaseActivityVariantReducerMethods) {
    return DatabaseActivityVariantReducerMethods[action.type](state, action.payload);
  } else if (action.type in DatabaseCommonReducerMethods) {
    return DatabaseCommonReducerMethods[action.type](state, action.payload);
  } else if (action.type in DatabaseResourceListingReducerMethods) {
    return DatabaseResourceListingReducerMethods[action.type](state, action.payload);
  } else if (action.type in DatabaseResourceReducerMethods) {
    return DatabaseResourceReducerMethods[action.type](state, action.payload);
  } else if (action.type in DatabaseResourceVariantReducerMethods) {
    return DatabaseResourceVariantReducerMethods[action.type](state, action.payload);
  } else {
    if (
      action.type === AccountActionTypes.LOG_OUT
    ) {
      return DATABASE_INITIAL_STATE;
    }
    return state;
  }
}
