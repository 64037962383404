import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './avatar.scss';

import { AccountApi } from '../../../units/account/api';

interface Props {
  userId: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  title?: boolean;
  avatar?: React.ReactText;
  text?: string;
  className?: string;
}

interface State {
  filePath: string;
}

export class Avatar extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      filePath: AccountApi.buildAvatarLink(this.props.userId),
    };
  }
  public render(): React.ReactNode {
    return (
      <div className={classNames('avatar', this.props.className)} title={this.title}>
        {
          this.state.filePath ? <img className='avatar__img' src={this.state.filePath} onError={this.imageOnError}/>
            :
            (
            <span className='avatar__text'>
              {this.props.text}
              {this.firstInitial}
              {this.lastInitial}
            </span>
            )
        }
      </div>
    );
  }

  @autobind
  private imageOnError(): void {
    this.setState({ filePath: null });
  }

  private get title(): string | null {
    const { userName, firstName, lastName, title } = this.props;
    if (!title) return null;
    return userName ? `${firstName} ${lastName}` : '';
  }

  private get firstInitial(): string {
    return this.props.firstName ? this.props.firstName.charAt(0) : '';
  }

  private get lastInitial(): string {
    return this.props.lastName ? this.props.lastName.charAt(0) : '';
  }
}
