import { ValueHelper } from 'common/utils/value-helper';
import { QtoTreeRowProperty } from '../../../interfaces/quantity-take-off';
import { CellEditor } from '../../quantity-take-off-tree-table-cell-edit/cell-editor';

export abstract class ReportCellEditor<TElement extends HTMLElement>
  extends CellEditor<QtoTreeRowProperty, TElement> {
  protected static className: string = 'tree-table-cell-edit__value';

  public revertValue(): void {
    const property = this.params.data.properties[this.params.column.getId()];
    const value = ValueHelper.isNumberValue(property && property.default)
      ? this.getConvertedValue(property.default)
      : property && property.default || null;
    this.setEValue(value);
    this.setFocus();
  }

  protected initEValue(eGui: HTMLDivElement): void {
    this.eValue = eGui.querySelector(`.${ReportCellEditor.className}`);
  }
}
