import { CodecUtils } from 'common/utils/codec-utils';

export enum BillingPaymentStatus {
  UnKnown = 'UnKnown',
  Valid = 'Valid',
  Expiring = 'Expiring',
  Expired = 'Expired',
  Invalid = 'Invalid',
  PendingVerification = 'PendingVerification',
}

export const BillingPaymentStatusC = CodecUtils
  .createEnumCodec<BillingPaymentStatus>(BillingPaymentStatus, 'BillingPaymentStatus');

