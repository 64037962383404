import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


export const  TableHeader: React.FC = () => {
  return (
    <Styled.RowContainer>
      <Styled.Cell>
        <Styled.ContentWrapper isItem={true}>
          <Text color={'turquoise'}>ITEMS</Text>
        </Styled.ContentWrapper>
      </Styled.Cell>
      <Styled.Cell>
        <Text color={'turquoise'}>UNITS</Text>
      </Styled.Cell>
      <Styled.Cell>
        <Text color={'turquoise'}>UNIT PRICE</Text>
      </Styled.Cell>
      <Styled.Cell>
        <Text color={'turquoise'}>AMOUNT (GBP)</Text>
      </Styled.Cell>
    </Styled.RowContainer>
  );
};
