import { RectangleButton, LinkComponent, Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  apply: () => void;
  cancel: () => void;
}

export const Apply = React.memo<Props>(
  ({ apply, cancel }) => {
    return (
      <Styled.Buttons>
        <RectangleButton size='s' mood='positive' Icon={Icons.ApplyXs} text='Apply' onClick={apply} iconHeight={10} />
        <LinkComponent text='Cancel' onClick={cancel} />
      </Styled.Buttons>
    );
  },
);
