import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';

export const useSelectedRootElementIds = (): string[] => {
  const selectedGroup = useSelector<State, string[]>(s => s.drawings.selectGeometryGroup);
  const aiAnnotation = useSelector<State, DrawingsGeometryState>(s => s.drawings.aiAnnotation);
  const selectedIds = useSelector<State, string[]>(s => s.drawings.selectedInstances);
  return useMemo(() => {
    const ids = [];
    const selectedGroupSet = new Set(selectedGroup);

    for (const id of selectedIds) {
      const measure = aiAnnotation.geometry[id];
      if (!measure.groupId) {
        ids.push(id);
        continue;
      } else if (!selectedGroupSet.has(measure.groupId)) {
        ids.push(id);
      }
    }
    return ids;
  }, [selectedGroup, aiAnnotation, selectedIds ]);

};
