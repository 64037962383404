import React from 'react';

import { AdvDialog } from 'common/components/adv-dialog';
import { ControlNames } from 'common/constants/control-names';
import { ConstantFunctions } from 'common/constants/functions';
import { VideoUrl } from 'common/constants/video-url';

interface Props {
  onCloseDialog: () => void;
  onConfirm: () => void;
}

export const TEMPLATES_ADV_DIALOG_NAME = 'TEMPLATES_ADV_DIALOG_NAME';
export const TEMPLATES_HEADER_ADV_DIALOG = 'Templates for Projects';
const DESCRIPTION_ADV_DIALOG = `Templates for projects help you to get rid of routine tasks when creating a project. 
You’re able to create a template from the already existing project or create a new template from scratch. 
Templates are easy to update and edit.`;
const FEATURES_ADV_DIALOG = [
  'Save time and avoid errors from mechanical operations',
  'Link the measurement folder structure and the report by making relevant links',
  'Templates are easy to update by adding new best practice solutions and fixing bugs',
  'Create a single structure for all employees. It is easier to understand projects when everyone does the same',
];

export class TemplatesAdvDialog extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <AdvDialog
        videoUrl={VideoUrl.TemplatesMechanism2D}
        header={TEMPLATES_HEADER_ADV_DIALOG}
        description={DESCRIPTION_ADV_DIALOG}
        features={FEATURES_ADV_DIALOG}
        onConfirm={this.props.onConfirm}
        text={'Add to my plan'}
        controlName={ControlNames.advDialogTemplates}
        dialogName={TEMPLATES_ADV_DIALOG_NAME}
        onCloseDialog={this.props.onCloseDialog}
        onChatOpen={ConstantFunctions.doNothing}
      />
    );
  }
}
