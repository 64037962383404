import { ModalWrapper } from '@kreo/kreo-ui-components';
import React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { DrawingDialogs } from '../../constants/drawing-dialogs';
import { MoveToDialogContent } from './dialog-content';

interface StateProps {
  isOpen: boolean;
}

interface DispatchProps {
  onClose: () => void;
}

interface Props extends StateProps, DispatchProps {
}


const MoveToDialogContentComponent: React.FC<Props> = ({ isOpen: isOpen, onClose }) => {
  return (
    <DialogWrapper
      name={DrawingDialogs.MOVE_TO_DIALOG}
    >
      <ModalWrapper onExit={onClose} zIndex={1001}>
      { isOpen ? <MoveToDialogContent /> : null }
      </ModalWrapper>
    </DialogWrapper>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    isOpen: DrawingDialogs.MOVE_TO_DIALOG in state.dialog,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    onClose: () => dispatch(KreoDialogActions.closeDialog(DrawingDialogs.MOVE_TO_DIALOG)),
  };
}

export const MoveToDialog = connect(mapStateToProps, mapDispatchToProps)(MoveToDialogContentComponent);
