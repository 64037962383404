import styled from 'styled-components';

const Blank = styled.div`
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  cursor: default;
  position: absolute;
  z-index: 900;
`;

export const Styled = {
  Blank,
};
