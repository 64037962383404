import * as React from 'react';


export const KreoIconArea: React.FC = () => (
  <svg width='24px' height='24px' viewBox='0 0 24 24'>
    <g id='Icons/24x24/Tools/Area' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        className='icon__color-light'
        // eslint-disable-next-line max-len
        d='M16.3535534,11.6464466 C16.5271197,11.820013 16.5464049,12.0894374 16.4114088,12.2843055 L16.3535534,12.3535534 L13.3535534,15.3535534 C13.1582912,15.5488155 12.8417088,15.5488155 12.6464466,15.3535534 C12.4728803,15.179987 12.4535951,14.9105626 12.5885912,14.7156945 L12.6464466,14.6464466 L15.6464466,11.6464466 C15.8417088,11.4511845 16.1582912,11.4511845 16.3535534,11.6464466 Z M15.3535534,8.64644661 C15.5271197,8.82001296 15.5464049,9.08943736 15.4114088,9.2843055 L15.3535534,9.35355339 L10.3535534,14.3535534 C10.1582912,14.5488155 9.84170876,14.5488155 9.64644661,14.3535534 C9.47288026,14.179987 9.45359511,13.9105626 9.58859116,13.7156945 L9.64644661,13.6464466 L14.6464466,8.64644661 C14.8417088,8.45118446 15.1582912,8.45118446 15.3535534,8.64644661 Z M8.64644661,10.6464466 L11.6464466,7.64644661 C11.8417088,7.45118446 12.1582912,7.45118446 12.3535534,7.64644661 C12.5271197,7.82001296 12.5464049,8.08943736 12.4114088,8.2843055 L12.3535534,8.35355339 L9.35355339,11.3535534 C9.15829124,11.5488155 8.84170876,11.5488155 8.64644661,11.3535534 C8.47288026,11.179987 8.45359511,10.9105626 8.58859116,10.7156945 L8.64644661,10.6464466 L11.6464466,7.64644661 L8.64644661,10.6464466 Z'
        id='color2'
        fill='currentColor'
        fillRule='nonzero'
      />
      <path
        // eslint-disable-next-line max-len
        d='M6.5,4 L17.5,4 C18.3284271,4 19,4.67157288 19,5.5 L19,18.5 C19,19.3284271 18.3284271,20 17.5,20 L11.3284271,20 C10.6653859,20 10.0295011,19.7366079 9.56066017,19.267767 L5.73223305,15.4393398 C5.2633921,14.9704989 5,14.3346141 5,13.6715729 L5,5.5 C5,4.67157288 5.67157288,4 6.5,4 Z M6.5,5 C6.22385763,5 6,5.22385763 6,5.5 L6,13.6715729 C6,14.0693976 6.15803526,14.4509285 6.43933983,14.732233 L10.267767,18.5606602 C10.5490715,18.8419647 10.9306024,19 11.3284271,19 L17.5,19 C17.7761424,19 18,18.7761424 18,18.5 L18,5.5 C18,5.22385763 17.7761424,5 17.5,5 L6.5,5 Z'
        id='color1'
        fill='currentColor'
        fillRule='nonzero'
      />
    </g>
  </svg>
);

