import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { AdminCompaniesPage, AdminDemoProjectsPage, AdminPeoplePage, AdminSettingsPage } from '../../../units/admin';
import { NotFoundPage } from '../../../units/notfound/page';
import { Urls } from './urls';

export class Routes extends React.Component<RouteComponentProps<{}>> {
  public render(): React.ReactNode {
    const path = this.props.match.path;
    return (
      <Switch>
        <Redirect exact={true} from={path} to={Urls.people.path} />
        <Route exact={true} path={Urls.people.path} component={AdminPeoplePage} />
        <Route exact={true} path={Urls.companies.path} component={AdminCompaniesPage} />
        <Route exact={true} path={Urls.settings.path} component={AdminSettingsPage} />
        <Route exact={true} path={Urls.demoProjects.path} component={AdminDemoProjectsPage} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}
