import { useCallback } from 'react';
import { arrayUtils } from 'common/utils/array-utils';

import { AssignedPia, AssignPiaPatch } from '../../../../../units/2d/interfaces';

export const useResetChildCallback = (
  childIds: string[],
  assignPia: Record<string, AssignedPia>,
): () => AssignPiaPatch[] => {
  return useCallback((): AssignPiaPatch[] => {
    const ids = childIds;
    return arrayUtils.filterMap(
      ids,
      id => !!assignPia[id],
      (id) => (({
        ids: [id],
        deletedAssemblies: assignPia[id].assemblies?.map(a => a.name),
        deletedItems: assignPia[id].items?.map(i => i.name),
      })));
  }, [assignPia, childIds]);
};
