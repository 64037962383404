import { Icons, LinkComponent, Text } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { RenderIf } from 'common/components/render-if';
import { ProjectType } from 'common/constants/project-type';
import { ColorsName } from 'common/enums/kreo-colors';
import { KreoDialogActions } from 'common/UIKit';
import { CREATE_2D_PROJECT_DIALOG_NAME } from 'components/dialog';
import { Styled } from '../styled';

interface Props {
  projectTypeName: string;
}

export const NoFound: React.FC<Props> = ({ projectTypeName }) => {
  const dispatch = useDispatch();
  const canUseTemplates = useAbility(Operation.Manage, Subject.Project2DTemplates);

  const openCreate2dProjectDialog = useCallback(() => {
    const projectType = projectTypeName === 'template' ? ProjectType.Project2dTemplate : ProjectType.Project2d;
    dispatch(KreoDialogActions.openDialog(CREATE_2D_PROJECT_DIALOG_NAME, {
      projectType,
      canUseTemplates,
    }));
  }, [canUseTemplates, dispatch, projectTypeName]);

  return (
    <Styled.Placeholder>
      <Text
        fontSize='24'
        color={ColorsName.turquoiseFont}
      >
        {`No ${projectTypeName} found`}
      </Text>
      <RenderIf condition={canUseTemplates}>
        <LinkComponent
          text={'Create'}
          Icon={Icons.PlusBig}
          mood={'secondary'}
          onClick={openCreate2dProjectDialog}
        />
      </RenderIf>
    </Styled.Placeholder>
  );
};
