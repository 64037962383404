import {
  AnnotationFilters,
  FilterChangePayload,
  FilterData,
} from 'common/components/drawings/interfaces/drawing-filters';
import { DrawingsGroupTemplate } from 'common/components/drawings/interfaces/drawings-group-template';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import { TreeUtils } from 'common/utils/tree-utils';
import {
  BulkGroupsChangePayload,
  CreateDrawingGroupsTree,
  DrawingsGroupUpdateUser,
  GroupTemplatePayload,
  MoveDrawingGroupTree,
  UpdateDrawingGroupTree,
  UpdateSelectionPayload,
} from '../actions/payloads/drawings-annotation-legend';
import { DrawingsAnnotationLegendActionTypes } from '../actions/types/drawings-annotation-legend';
import { DrawingsModesWithFullSelectionBlock } from '../enums';
import { GeometryGroupsResponse } from '../interfaces';
import { AnnotationLegendItem } from '../interfaces/annotation-legend-tree';
import { DrawingsState } from '../interfaces/drawings-state';
import { DrawingAnnotationReducerUtils } from '../utils/drawing-annotation-reducer-utils';


export function setGroups(
  helper: MonoliteHelper<DrawingsState>,
  { groups, pinnedGroupIds }: GeometryGroupsResponse,
): MonoliteHelper<DrawingsState> {
  return helper
    .set(_ => _.drawingGeometryGroups, groups)
    .set(_ => _.pinnedGroupIds, pinnedGroupIds)
    .set(_ => _.usedColors, _ => {
      return arrayUtils.uniq(_.concat(arrayUtils.filterMap(groups, x => !!x.color, x => x.color)));
    });
}

export const DrawingsAnnotationLegendReducerMethods: ReducerMethods<DrawingsState> = {
  [DrawingsAnnotationLegendActionTypes.SET_GROUPS]: (state, payload: GeometryGroupsResponse) => {
    return setGroups(new MonoliteHelper(state), payload).get();
  },
  [DrawingsAnnotationLegendActionTypes.SET_GROUP_LOAD_SUCCESS]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.groupsLoaded, true)
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.LOAD_GROUPS]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.groupsLoaded, false)
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.ADD_GROUPS]: (state, payload: CreateDrawingGroupsTree) => {
    const helper = new MonoliteHelper(state);
    DrawingAnnotationReducerUtils.addGroups(helper, payload);
    return helper.get();
  },
  [DrawingsAnnotationLegendActionTypes.DELETE_GROUPS]: (state, payload: string[]) => {
    const helper = new MonoliteHelper(state);

    DrawingAnnotationReducerUtils.deleteGroups(helper, payload);

    return helper.get();
  },
  [DrawingsAnnotationLegendActionTypes.UPDATE_GROUPS]: (state, payload: UpdateDrawingGroupTree) => {
    const helper = new MonoliteHelper(state);
    const idToUpdatedGroup = arrayUtils.toDictionary(payload.groups, x => x.id, x => x);

    return helper
      .setMap(_ => _.drawingGeometryGroups, x => x.id in idToUpdatedGroup ? { ...x, ...idToUpdatedGroup[x.id] } : x)
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.REMOVE_DRAWINGS_FROM_GROUPS]: (state, payload: string[]) => {
    const helper = new MonoliteHelper(state);
    DrawingAnnotationReducerUtils.removeInstancesFromGroups(helper, payload);

    return helper.get();
  },
  [DrawingsAnnotationLegendActionTypes.MOVE_GROUPS]: (state, payload: MoveDrawingGroupTree) => {
    const helper = new MonoliteHelper(state);
    DrawingAnnotationReducerUtils.processInstancesMovements(helper, payload);

    return helper.get();
  },
  [DrawingsAnnotationLegendActionTypes.TOGGLE_GROUP_OPEN]: (state, payload: string[]) => {
    const helper = new MonoliteHelper(state);
    payload.forEach(groupId => {
      helper.set(
        _ => _.drawingGeometryOpenGroups[groupId],
        isOpen => !isOpen,
      );
    });
    return helper
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.TOGGLE_ALL_GROUPS_OPEN]: (state, payload: boolean) => {
    return new MonoliteHelper(state)
      .set(
        _ => _.drawingGeometryOpenGroups,
        state.drawingGeometryGroups.reduce((acc, group) => ({ ...acc, [group.id]: payload }), {}),
      )
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.UPDATE_SELECTION]: (s, { instanceIds, groupIds }: UpdateSelectionPayload) => {
    if (DrawingsModesWithFullSelectionBlock.includes(s.drawMode)) {
      return s;
    }
    const helper = new MonoliteHelper(s);
    if (instanceIds) {
      helper.set(_ => _.selectedInstances, instanceIds.filter(x => s.aiAnnotation.geometry[x]));
    }
    if (groupIds) {
      helper.set(_ => _.selectGeometryGroup, groupIds);
    }
    return helper.get();
  },
  [DrawingsAnnotationLegendActionTypes.SAVE_FILTERED_ELEMENT_IDS]: (state, payload: string[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.filteredElementIds, payload)
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.SET_FILTER_DATA]: (state, payload: FilterData) => {
    return new MonoliteHelper(state)
      .set(_ => _.filterData, payload)
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.CHANGE_FILTER_DATA]: (state, payload: FilterChangePayload) => {
    const { filterType, element } = payload;
    const helper = new MonoliteHelper(state);
    switch (filterType) {
      case AnnotationFilters.Shapes: {
        helper.set(s => s.filterData[AnnotationFilters.Shapes], element);
        break;
      }
      case AnnotationFilters.Origin:
      case AnnotationFilters.Name:
      case AnnotationFilters.Pages: {
        helper.set(s => s.filterData[filterType], element);
        break;
      }
      case AnnotationFilters.Color:
      case AnnotationFilters.Report:
      case AnnotationFilters.Assign:
      case AnnotationFilters.Users: {
        const newValue = state.filterData[filterType] === element ? null : element;
        helper.set(s => s.filterData[filterType], newValue);
        break;
      }
      default:
    }
    return helper.get();
  },
  [DrawingsAnnotationLegendActionTypes.SET_GROUP_TEMPLATES]: (state, payload: DrawingsGroupTemplate[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.drawingGroupTemplates, payload)
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.UPDATE_GROUP_TEMPLATE_SUCCESS]: (state, payload: GroupTemplatePayload) => {
    const helper = new MonoliteHelper(state);
    const { templateId, templateInfo } = payload;

    if (templateInfo.isDefault) {
      helper.setMap(
        _ => _.drawingGroupTemplates,
        template => {
          if (template.id === templateId) {
            return { ...template, ...templateInfo };
          }
          return template.isDefault ? { ...template, isDefault: false } : template;
        },
      );
    } else {
      helper.setFind(
        _ => _.drawingGroupTemplates,
        template => template.id === templateId,
        template => ({ ...template, ...templateInfo }),
      );
    }
    return helper.get();
  },
  [DrawingsAnnotationLegendActionTypes.ADD_GROUP_TEMPLATE]: (state, payload: DrawingsGroupTemplate) => {
    const helper = new MonoliteHelper(state);

    if (payload.isDefault) {
      helper.setFind(
        _ => _.drawingGroupTemplates,
        template => template.isDefault,
        template => ({ ...template, isDefault: false }),
      );
    }
    return helper
      .setAppend(_ => _.drawingGroupTemplates, payload)
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.DELETE_GROUP_TEMPLATE_SUCCESS]: (state, payload: number) => {
    return new MonoliteHelper(state)
      .setFilter(_ => _.drawingGroupTemplates, template => template.id !== payload)
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.UPDATE_USER_IN_GROUPS]: (state, payload: DrawingsGroupUpdateUser) => {
    const { creationInfo: { userId, appliedAt }, groups } = payload;
    const groupIds = new Set(TreeUtils.flatTreeMapIterator(groups, x => x.innerGroups, x => x.id));

    return new MonoliteHelper(state)
      .setMap(
        _ => _.drawingGeometryGroups,
        group => {
          if (groupIds.has(group.id)) {
            return { ...group, creatorId: userId, createdAt: appliedAt };
          }
          return group;
        },
      )
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.ADD_INSTANCE_TO_GROUP]: (state, payload: Array<[string, string[]]>) => {
    const helper = new MonoliteHelper(state);

    for (const [groupId, measurementIds] of payload) {
      DrawingAnnotationReducerUtils.addInstancesToGroup(helper, groupId, measurementIds);
    }

    return helper.get();
  },
  [DrawingsAnnotationLegendActionTypes.TOGGLE_SHOW_ONLY_GROUPS]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.showOnlyGroups, _ => !_)
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.SET_FILTERED_RESULT_ITEMS]: (state, payload: AnnotationLegendItem[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.filteredItems, payload)
      .get();
  },
  [DrawingsAnnotationLegendActionTypes.BULK_GROUPS_UPDATE]: (state, payload: BulkGroupsChangePayload) => {
    let helper = setGroups(new MonoliteHelper(state), payload);
    if (payload.moveMeasurements.length > 0) {
      helper = DrawingAnnotationReducerUtils.moveMeasurements(helper, payload.moveMeasurements);
    }
    if (payload.delete.length > 0) {
      helper = DrawingAnnotationReducerUtils.deleteGroups(helper, payload.delete);
    }
    return helper.get();
  },
};

