import * as t from 'io-ts';

import { ShortUsersC } from './short-user';

export const AdminCompanySubscriptionVMC = t.intersection(
  [
    t.type({
      employees: ShortUsersC,
    }),
    t.partial({
      mockSubscriptionPlanId: t.string,
      mockSubscriptionPlanQuantity: t.number,
    }),
  ],
  'AdminCompanySubscriptionVM');

export type AdminCompanySubscriptionVM = t.TypeOf<typeof AdminCompanySubscriptionVMC>;
