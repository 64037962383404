import React, { useCallback } from 'react';
import { TutorialTooltip } from './content';
import { ElementTooltip } from './element-tooltip';

interface Props {
  id?: string;
  description: string;
  tutorialLink: string;
  speed?: 's' | 'm' | 'l' | 'xl';
  size?: 'small' | 'big';
  position?: 'top' | 'bottom' | 'left' | 'right';
  disabled?: boolean;
  animation: string;
}

export const ElementTutorialTooltip: React.FC<Props> = (
  {
    id,
    description,
    tutorialLink,
    children,
    speed,
    size,
    position,
    disabled,
    animation,
  },
) => {
  const renderTooltip = useCallback(() => {
    return (
      <TutorialTooltip
        description={description}
        riveLink={tutorialLink}
        animation={animation}
      />
    );
  }, [description, tutorialLink, animation]);


  return (
    <ElementTooltip
      id={id}
      position={position}
      speed={speed}
      size={size}
      disabled={disabled}
      childComponent={renderTooltip}
      tooltipWidth={260}
      padding="10px"
    >
      {children}
    </ElementTooltip>
  );
};
