import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './title-editable.scss';

import { IconButton, KreoIconAddedDone, KreoIconEditPen, MaterialInput, MaterialInputValueType } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';

interface Props {
  className?: string;
  text: string;
  label: string;
  onChange: (value: string) => void;
  onEditStatusChanged?: (isEdit: boolean) => void;
  isEditByIconClick?: boolean;
  canEdit: boolean;
  withoutSaveBtn?: boolean;
  isDisplayMultiline?: boolean;
  multilineRows?: number;
  sizeSmall?: boolean;
  valueType?: MaterialInputValueType;
}

interface State {
  text: string;
  edit: boolean;
  sendRequest: boolean;
  canEdit: boolean;
}

export class TitleEditable extends React.Component<Props, State> {
  private input: MaterialInput = null;
  private saveButtonId: string = 'title-editable-save-button-wrap';

  constructor(props: Props) {
    super(props);

    this.state = {
      text: this.props.text,
      edit: false,
      sendRequest: false,
      canEdit: props.canEdit && props.onChange ? true : false,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): Partial<State> {
    return !state.edit ? { text: props.text } : null;
  }

  public render(): JSX.Element {
    const { text, isEditByIconClick } = this.props;
    const canEdit = this.state.canEdit;
    const onClick = !isEditByIconClick && !this.state.edit
      ? this.onEdit
      : this.state.edit
        ? this.stopPropagation
        : null;

    return (
      <div
        className={classNames('title-editable', this.props.className, {
          'title-editable--no-edit': !this.props.canEdit,
          'title-editable--multiline': this.props.isDisplayMultiline,
          'title-editable--edit-by-icon': isEditByIconClick,
        })}
        onClick={onClick}
      >
        {this.state.edit ? (
         <React.Fragment>
          <MaterialInput
            value={this.state.text}
            onChange={this.onChangeValue}
            ref={this.makeInputRef}
            placeholder={this.props.label}
            displayedType={MaterialComponentType.Native}
            onBlur={this.onBlur}
            onKeyPress={this.onKeyPress}
            multiLine={this.props.isDisplayMultiline}
            rows={this.props.multilineRows}
            sizeSmall={this.props.sizeSmall}
            valueType={this.props.valueType}
          />
          {!this.props.withoutSaveBtn && (
            <div tabIndex={0} id={this.saveButtonId}>
              <IconButton size='small' onClick={this.onSaveValue} disabled={!this.state.text}>
                <KreoIconAddedDone />
              </IconButton>
            </div>
          )}
        </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              className={classNames('title-editable__title', {
                'title-editable__title--multiline': this.props.isDisplayMultiline,
              })}
            >
              {text}
            </div>
            <div
              className='title-editable__edit-icon'
              onClick={canEdit ? this.onEdit : null}
            >
              <KreoIconEditPen />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  @autobind
  private makeInputRef(materialInput: MaterialInput): void {
    this.input = materialInput;
  }

  @autobind
  private onEdit(event: any): void {
    if (this.state.canEdit) {
      event.stopPropagation();

      if (this.props.onEditStatusChanged) {
        this.props.onEditStatusChanged(true);
      }

      const { isEditByIconClick } = this.props;

      this.setState({ edit: true }, () => {
        this.input.focus();

        if (!isEditByIconClick) {
          this.input.setSelectionRange(this.state.text.length, this.state.text.length);
        }
      });
    }
  }

  @autobind
  private onChangeValue(_event: React.ChangeEvent, newValue?: string): void {
    this.setState({ text: newValue, sendRequest: false });
  }

  @autobind
  private onBlur(event: React.FocusEvent<HTMLInputElement>): void {
    if (!event.relatedTarget || (event.relatedTarget as HTMLDivElement).id !== this.saveButtonId) {
      if (this.props.withoutSaveBtn) {
        this.onSaveValue();
      }
    } else {
      this.onSaveValue();
    }
    this.setState({ edit: false }, () => {
      if (this.props.onEditStatusChanged) {
        this.props.onEditStatusChanged(false);
      }
    });
  }

  @autobind
  private onKeyPress(event: React.KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onSaveValue();
    }
  }

  @autobind
  private onSaveValue(): void {
    let isSendRequest = false;
    if (this.state.text !== '' && this.state.text !== this.props.text && !this.state.sendRequest) {
      this.props.onChange(this.state.text);
      isSendRequest = true;
    }
    this.setState({
      edit: false,
      sendRequest: isSendRequest ? true : this.state.sendRequest,
    },            () => {
      if (this.props.onEditStatusChanged) {
        this.props.onEditStatusChanged(false);
      }
    });
  }

  @autobind
  private stopPropagation(event: any): void {
    event.stopPropagation();
  }
}
