import React from 'react';

import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';

export const CANT_SELECT_PLAN_DIALOG = `CANT_SELECT_PLAN_DIALOG`;

export const CantSelectSinglePlanDialog: React.FC = () => {
  return (
    <ConfirmationDialog
      name={CANT_SELECT_PLAN_DIALOG}
      title={'Selected plan is for single users only'}
      text={'Remove all Users and Guests or choose another plan'}
      cancelButtonText='Okay'
    />
  );
};
