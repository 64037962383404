import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconProjectCreate: React.FC = (_props) => {
  return (
    <svg width='60px' height='60px' viewBox='0 0 60 60' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='icon_projects_empty' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <path
              d='M34,50 L47,50 L47,23 C47,22.4477153 46.5522847,22 46,22 L34,22 L34,31 L40.5,31 C40.7761424,31
              41,31.2238576 41,31.5 C41,31.7761424 40.7761424,32 40.5,32 L34,32 L34,40 L40.5,40 C40.7761424,40
              41,40.2238576 41,40.5 C41,40.7761424 40.7761424,41 40.5,41 L34,41 L34,50 Z M49,50 L52.5,50
              C53.0522847,50 53.5,50.4477153 53.5,51 C53.5,51.5522847 53.0522847,52 52.5,52 L7.5,52 C6.94771525,52
              6.5,51.5522847 6.5,51 C6.5,50.4477153 6.94771525,50 7.5,50 L11,50 L11,11 C11,9.34314575 12.3431458,8
              14,8 L31,8 C32.6568542,8 34,9.34314575 34,11 L34,20 L46,20 C47.6568542,20 49,21.3431458 49,23 L49,50
              Z M32,50 L32,11 C32,10.4477153 31.5522847,10 31,10 L14,10 C13.4477153,10 13,10.4477153 13,11 L13,50
              L32,50 Z M19.5,23 C19.2238576,23 19,22.7761424 19,22.5 C19,22.2238576 19.2238576,22 19.5,22 L25.5,22
              C25.7761424,22 26,22.2238576 26,22.5 C26,22.7761424 25.7761424,23 25.5,23 L19.5,23 Z M19.5,32
              C19.2238576,32 19,31.7761424 19,31.5 C19,31.2238576 19.2238576,31 19.5,31 L25.5,31 C25.7761424,31
              26,31.2238576 26,31.5 C26,31.7761424 25.7761424,32 25.5,32 L19.5,32 Z'
              id='Combined-Shape'
              fill={KreoColors.f4}
              fillRule='nonzero'
            />
        </g>
    </svg>
  );
};
