import * as React from 'react';

import { DrawingsGeometryRendererAPI } from '../../interfaces/drawings-geometry-renderer-api';


export type DrawingsRendererApiSetter = (api: DrawingsGeometryRendererAPI) => void;

export interface DrawingsRendererApiContextProps {
  rendererApi: DrawingsGeometryRendererAPI;
  tryCancelDrawMode: (accept: () => void, cancel?: () => void) => void;
}

export const DrawingsRendererApiContext = React.createContext<DrawingsRendererApiContextProps>(null);
export const DrawingsRendererApiContextSetter = React.createContext<DrawingsRendererApiSetter>(null);
