import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { isSpecKey } from '../../excel-table-cell-formatter/common';
import { MoveCellRefMap, MoveCellTempData, MoveDataPayload, UpdatedCellsMap } from '../interfaces';
import { setEmptyCell } from '../set-empty-cell';
import { shiftColumns } from '../shift-columns/shift-columns';
import { getColumnIndex, getShiftedColumnId } from '../utils';

export const insertColumns = ({ startIndex, sheetsData, sheetId, offset }: MoveDataPayload): MoveCellTempData => {
  const { rows } = sheetsData[sheetId];
  const updatedCellsMap: UpdatedCellsMap = {};
  const moveCellRefMap: MoveCellRefMap = {};
  const endIndex = startIndex + offset;

  const shiftCellsInRow = shiftColumns(-offset, updatedCellsMap, moveCellRefMap);

  rows.forEach(rowData => {
    for (let i = startIndex; i < endIndex; i++) {
      setEmptyCell(updatedCellsMap, rowData, ExcelColumnHelper.indexToExcelColName(i));
    }

    const columnKeys = new Set<string>();
    Object.keys(rowData).forEach(key => {
      const columnIndexToSet = getColumnIndex(key);
      if (isSpecKey(key) || columnIndexToSet < startIndex) {
        return;
      }
      if (columnIndexToSet > endIndex - 1) {
        columnKeys.add(key);
      }
      columnKeys.add(getShiftedColumnId(key, offset));
    });

    shiftCellsInRow(columnKeys, rowData);
  });

  return { updatedCellsMap, moveCellRefMap };
};
