import { CommentaryThread } from '../interfaces';

function getCommentLastDate(comment: CommentaryThread): number {
  const lastSubCommentDate = comment.messages ? comment.messages[comment.messages.length - 1]?.createdAt : null;
  const commentDate = new Date(comment.editedAt || comment.createdAt).getTime();
  if (lastSubCommentDate) {
    const lastSubDate = new Date(lastSubCommentDate).getTime();
    return lastSubDate > commentDate ? lastSubDate : commentDate;
  }
  return commentDate;
}

function sortCommentaries(comments: CommentaryThread[]): CommentaryThread[] {
  return comments.slice().sort((a, b) => getCommentLastDate(b) - getCommentLastDate(a));
}

function sortSubcomments(comment: CommentaryThread): void {
  comment.messages = comment.messages?.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  );
}

function deepSort(comments: CommentaryThread[]): CommentaryThread[] {
  comments.forEach(sortSubcomments);
  return comments.sort((a, b) => getCommentLastDate(b) - getCommentLastDate(a));
}

export const SortingUtils = {
  sortCommentaries,
  deepSort,
};
