import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { CalibrateChangeScalePayload } from '../actions/payloads/calibrate';
import { CalibrateActionTypes } from '../actions/types/calibrate';
import { CallibrationInitialState as CalibrationInitialState } from '../constants';
import { DrawingsShortInfo, DrawingsState } from '../interfaces';
import { DrawingsCanvasUtils } from '../utils/drawings-canvas-utils';
import { DrawingsUtils } from '../utils/drawings-utils';

export const DrawingsCalibrateReducerMethods: ReducerMethods<DrawingsState> = {
  [CalibrateActionTypes.DROP_STATE]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.temporaryCalibration, CalibrationInitialState)
      .get();
  },
  [CalibrateActionTypes.START_EDIT]: (state, drawing: DrawingsShortInfo) => {
    const {
      paperSize,
      drawingCalibrationLineLength,
      originalCalibrationLineLength,
    } = DrawingsUtils.getCurrentPageScales(drawing);
    const { width, height } = drawing;
    const metersPerPx = DrawingsUtils.getMetersPerPx(width, height, paperSize);
    return new MonoliteHelper(state)
      .set(_ => _.temporaryCalibration, {
        ...CalibrationInitialState,
        metersPerPx,
        paperSize,
        drawingLength: drawingCalibrationLineLength,
        originalLength: originalCalibrationLineLength,
      })
      .get();
  },
  [CalibrateActionTypes.CHANGE_SCALE]: (
    state,
    { drawingLineLength, originalLineLength }: CalibrateChangeScalePayload,
  ) => {
    const scale = originalLineLength / drawingLineLength;
    const lineLength = DrawingsCanvasUtils.pxToMetres(
      state.temporaryCalibration.lineLengthPx,
      scale,
      state.temporaryCalibration.metersPerPx,
    ).toString();
    return new MonoliteHelper(state)
      .set(_ => _.temporaryCalibration.originalLength, originalLineLength)
      .set(_ => _.temporaryCalibration.drawingLength, drawingLineLength)
      .set(_ => _.temporaryCalibration.lineLength, lineLength)
      .get();
  },
  [CalibrateActionTypes.SET_ORIGINAL_LINE_LENGTH]: (state, length: string) => {
    let originalLength = Number(length);
    const { width, height } = state.currentDrawingInfo;
    const metersPerPx = DrawingsUtils.getMetersPerPx(
      width,
      height,
      state.temporaryCalibration.paperSize,
    );
    let drawingLength = DrawingsCanvasUtils.pxToMetres(
      state.temporaryCalibration.lineLengthPx,
      1,
      metersPerPx,
    );
    const min = Math.min(originalLength, drawingLength);
    if (min < 1) {
      const additionalScale = 1 / min;
      originalLength *= additionalScale;
      drawingLength *= additionalScale;
    }
    return new MonoliteHelper(state)
      .set(_ => _.temporaryCalibration.originalLength, originalLength)
      .set(_ => _.temporaryCalibration.drawingLength, drawingLength)
      .set(_ => _.temporaryCalibration.lineLength, length)
      .get();
  },
  [CalibrateActionTypes.SET_PAGE_FORMAT]: (state, paperSize: string) => {
    const { width, height } = state.currentDrawingInfo;
    const metersPerPx = DrawingsUtils.getMetersPerPx(
      width,
      height,
      paperSize,
    );
    const scale = state.temporaryCalibration.originalLength / state.temporaryCalibration.drawingLength;
    const lineLength = DrawingsCanvasUtils.pxToMetres(
      state.temporaryCalibration.lineLengthPx,
      scale,
      metersPerPx,
    ).toString();

    return new MonoliteHelper(state)
      .set(_ => _.temporaryCalibration.paperSize, paperSize)
      .set(_ => _.temporaryCalibration.metersPerPx, metersPerPx)
      .set(_ => _.temporaryCalibration.lineLength, lineLength)
      .get();
  },
  [CalibrateActionTypes.ON_CALIBRATE_LINE_DRAWN]: (state, length: number) => {
    const scale = state.temporaryCalibration.originalLength / state.temporaryCalibration.drawingLength;
    const lineLength = DrawingsCanvasUtils.pxToMetres(
      length,
      scale,
      state.temporaryCalibration.metersPerPx,
    ).toString();

    return new MonoliteHelper(state)
      .set(_ => _.temporaryCalibration.lineLengthPx, length)
      .set(_ => _.temporaryCalibration.lineLength, lineLength)
      .get();
  },
};
