import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconNotificationEmpty: React.FC = () => {
  return (
    <svg
      width='120px'
      height='120px'
      viewBox='0 0 120 120'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <linearGradient
          x1='23.5574777%'
          y1='23.7248884%'
          x2='68.1166295%'
          y2='74.1183036%'
          id='linearGradient-1'
        >
          <stop stopColor='#FFFFFF' stopOpacity='0.8' offset='0%' />
          <stop stopColor='#FFFFFF' stopOpacity='0.6' offset='100%' />
        </linearGradient>
        <rect id='path-2' x='0' y='0' width='120' height='120' rx='60' />
      </defs>
      <g
        id='Illustrations/120x120/Notification_Empty'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Circle' fillRule='nonzero'>
          <use fill='#E6E8EB' xlinkHref='#path-2' />
          <use fill='url(#linearGradient-1)' xlinkHref='#path-2' />
        </g>
        <path
          d='M75.3630473,75.3755456 L75.261319,74.9958905 L39.7357566,84.5149362 C38.7573644,84.7770956 37.7516986,
          84.1964742 37.4895392,83.218082 C37.397705,82.875352 37.4072595,82.5133417 37.5170445,82.175933 L40.5322393,
          72.9091614 L36.7585267,58.8254742 C33.5995027,47.0358359 40.5959979,34.9175508 52.3856361,
          31.7585267 C64.1752744,28.5995027 76.2935595,35.5959979 79.4525835,47.3856361 L83.2262962,
          61.4693233 L90.4709173,67.9869855 C91.2239335,68.6644406 91.2851879,69.8240668 90.6077328,
          70.577083 C90.3704208,70.840864 90.0616879,71.0301436 89.7189579,71.1219778 L75.2679729,
          74.9941076 C75.3020252,75.1211925 75.3337076,75.2483542 75.3630473,75.3755456 Z M55.5637292,
          84.0712964 L75.6125135,78.6992409 C75.2595384,83.2185831 72.1148745,87.2313357 67.4868477,
          88.4714118 C62.8588209, 89.7114878 58.1290858,87.8086741 55.5637292,84.0712964 Z'
          fill={KreoColors.f5}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
