import React from 'react';

import { arrayUtils } from 'common/utils/array-utils';

export interface Content {
  Component: React.ComponentType;
  title: string;
  type: string;
  isAlwaysRender: () => boolean;
  styled: any;
  onClick: () => void;
  isDisabled: () => boolean;
  isUpgrade?: () => boolean;
}

interface OwnProps<T> {
  activeNavigationType: T | string;
}

interface Props<T> extends OwnProps<T> { }

function filterContent<T>(content: Content, activeNavigationType: T | string): boolean {
  if (content.isAlwaysRender()) {
    return true;
  }
  return !content.isDisabled() && content.type === activeNavigationType;
}

export const getManager = (contents: Content[]): React.ComponentType<OwnProps<any>> => {
  class ContentManagerComponent<T> extends React.PureComponent<Props<T>> {

    public componentDidUpdate(): void {
      const content = contents.find(c => c.type === this.props.activeNavigationType);
      if (content.isDisabled()) {
        contents[0].onClick();
      }
    }

    public render(): React.ReactNode {
      const elements = arrayUtils.filterMap(
        contents,
        (c) => filterContent(c, this.props.activeNavigationType),
        content => {
          const Component = content.Component;
          const isHide = content.isAlwaysRender() && content.type !== this.props.activeNavigationType;
          const StyledContainer = content.styled;
          return (
            <StyledContainer
              key={content.type}
              id={content.type}
              isHide={isHide}
              isShowLink={true}
            >
              <Component {...this.props} />
            </StyledContainer>
          );
        },
      );
      return (<>{elements}</>);
    }
  }
  return ContentManagerComponent;
};
