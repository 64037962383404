import * as React from 'react';

import { MultiViewersStates } from 'common/utils/multi-view/multi-view-states';
import { DrawingsDrawMode } from '../enums';
import { DrawingsViewportHelper } from '../helpers/viewport';
import { DrawingsGeometryInstance, ShortPointDescription } from '../interfaces';
import { DrawingsInstanceMeasure } from '../interfaces/drawings-instance-measure';
import { DrawingsShortInfo } from '../interfaces/drawings-short-drawing-info';
import { DrawingsGeometryState } from '../interfaces/drawings-state';

export interface DrawingsLayoutApi {
  scrollToDrawingInstances: (instanceId: Array<string | [string, string]>) => void;
  getInstancesMeasures: (
    instancesIds: Array<string | string[]>,
    drawings: Record<string, DrawingsShortInfo>,
    aiAnnotation: DrawingsGeometryState,
    elementMeasurement: Record<string, DrawingsInstanceMeasure>,
  ) => DrawingsInstanceMeasure[];
  getInstancesMeasuresSimple: (
    instancesIds: Array<string | string[]>,
  ) => DrawingsInstanceMeasure[];
  scrollToPositionOnDrawing: (point: { x: number, y: number }, drawingId: string) => void;
  getSegmentMeasures: (
    pointIds: [string, string],
    drawings: Record<string, DrawingsShortInfo>,
    aiAnnotation: DrawingsGeometryState,
    elementMeasurement: Record<string, DrawingsInstanceMeasure>,
  ) => DrawingsInstanceMeasure | null;
  changeEntitiesColor: (ids: string[], color: string) => void;
  getDrawMode: () => DrawingsDrawMode;
  changeGroupColor: (color: string, groupId: string) => void;
  onInstancesMeasuresUpdated: (measures: DrawingsInstanceMeasure[]) => void;
  getViewport: () => DrawingsViewportHelper;
  focusTextSearchResult: (id: number, drawingId: string) => void;
  getCallbacks: () => {
    onInstancesRemove?: (instancesIds: string[]) => void,
    onInstancesAdd?: (instances: DrawingsGeometryInstance[], onlyNewSelected: boolean) => void,
  };
  saveMeasuresOnCreate: (
    geometries: DrawingsGeometryInstance[],
    points: Record<string, ShortPointDescription>,
  ) => void;
}

export interface WithDrawingsLayoutApi {
  drawingsLayoutApi: DrawingsLayoutApi;
}

export const DrawingsLayoutApiContext = React.createContext<DrawingsLayoutApi>(null);
export const DrawingsLayoutApiSetter = React.createContext<(api: DrawingsLayoutApi) => void>(null);

export const DrawingsLayoutApiContextProvider: React.FC = ({ children }) => {
  const [api, setApi] = React.useState<DrawingsLayoutApi>(null);
  const handleSetRendererApi = React.useCallback((payload: DrawingsLayoutApi) => {
    const apiWithMultiViewSender = MultiViewersStates.extendApiWithMultiViewState(
      payload,
      'drawingApi',
      MultiViewersStates.views[0],
      ['changeEntitiesColor'],
    );
    setApi(apiWithMultiViewSender);
  }, []);

  return (
    <DrawingsLayoutApiContext.Provider value={api}>
      <DrawingsLayoutApiSetter.Provider value={handleSetRendererApi}>
        {children}
      </DrawingsLayoutApiSetter.Provider>
    </DrawingsLayoutApiContext.Provider>
  );
};
