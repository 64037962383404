import { PiaCalculatedAssemblies, PiaCalculatedItems } from '2d/interfaces/pia-calculated-assignment';
import { arrayUtils } from 'common/utils/array-utils';
import * as Pia from 'unit-2d-database/interfaces';
import { Assembly } from './assembly';
import { NOT_DEFINED_PROPERTY } from './constants';
import { CachedProperty, MeasuredValueGetter, PrecompiledFormulaApi, PropertyCalculator, Value } from './interfaces';
import { Item } from './item';


interface Config {
  assemblies: Pia.Assembly[];
  items: Pia.Item[];
  originProperties: Record<string, Pia.Property>;
  getMeasuredValue: MeasuredValueGetter;
  precompiledFormulas: PrecompiledFormulaApi;
}

export class AssignmentCalculator implements PropertyCalculator {
  private _items: Item[];
  private _assemblies: Assembly[];

  private _cachedProperties: Map<string, CachedProperty>;

  constructor({
    originProperties,
    assemblies,
    items,
    getMeasuredValue,
    precompiledFormulas,
  }: Config) {
    this._assemblies = assemblies.map(assembly => new Assembly({
      parent: this,
      originProperties,
      getMeasuredValue,
      items: assembly.items,
      name: assembly.name,
      precompiledFormulas,
    }));
    this._items = items.map(item => new Item({
      parent: this,
      originProperties,
      getMeasuredValue,
      item,
      precompiledFormulas,
    }));
  }

  public getPropertyValue(
    propertyName: string,
    formulaPath: Pia.Property[],
    withPercentageConversion: boolean,
  ): Value {
    this.initCache();
    if (this._cachedProperties.has(propertyName)) {
      const { isRef, value } = this._cachedProperties.get(propertyName);
      return isRef
        ? (value as PropertyCalculator).getPropertyValue(propertyName, formulaPath, withPercentageConversion)
        : value as Value;
    }
    return NOT_DEFINED_PROPERTY;
  }

  public calculate(): { items: PiaCalculatedItems, assemblies: PiaCalculatedAssemblies } {
    return {
      items: arrayUtils.toDictionary(this._items, item => item.getName(), item => item.calculate()),
      assemblies: arrayUtils.toDictionary(
        this._assemblies,
        assembly => assembly.getName(),
        assembly => assembly.calculate(),
      ),
    };
  }

  private addPropertyToCache(propertyName: string, item: PropertyCalculator): void {
    if (this._cachedProperties.has(propertyName)) {
      this._cachedProperties.set(propertyName, { isRef: false, value: NOT_DEFINED_PROPERTY });
    } else {
      this._cachedProperties.set(propertyName, { isRef: true, value: item });
    }
  }

  private initCache(): void {
    if (this._cachedProperties) {
      return;
    }

    this._cachedProperties = new Map();
    for (const item of this._items) {
      for (const propertyName of item.getPropertyNames()) {
        this.addPropertyToCache(propertyName, item);
      }
    }

    for (const assembly of this._assemblies) {
      for (const propertyName of assembly.getPropertyNames()) {
        this.addPropertyToCache(propertyName, assembly);
      }
    }
  }
}
