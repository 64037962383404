import React, { useCallback } from 'react';
import { InputSelectNoIndex } from 'common/components/inputs';
import { PropertyWithInfo } from '../property-with-info';

interface Props {
  dpi: number;
  options: string[];
  onChange: (dpi: number) => void;
  names: Record<number, string>;
}

const TOOLTIP_TEXT = 'Select a mode based on your priority: “Quick” for speed, “Detailed” for quality';

const DpiSelectorComponent: React.FC<Props> = ({ dpi, options, onChange, names }) => {
  const getElementLabel = useCallback((value: string) => names[value] || value, [names]);
  const onChangeCallback = useCallback(
    (value: string) => {
      onChange(Number(value));
    },
    [onChange],
  );

  return (
    <PropertyWithInfo name="Mode" tooltipText={TOOLTIP_TEXT}>
      <InputSelectNoIndex
        getElementLabel={getElementLabel}
        elements={options}
        createNewElement={false}
        onClick={onChangeCallback}
        activeElement={dpi.toString()}
        isShowArrow={true}
      />
    </PropertyWithInfo>
  );
};

export const DpiSelector = React.memo(DpiSelectorComponent);
