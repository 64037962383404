import * as React from 'react';

export const KreoIconProductPlan: React.FC = () => {
  return (
    <svg
      width='200px'
      height='160px'
      viewBox='0 0 200 160'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          // eslint-disable-next-line max-len
          d='M24.8944272,0.447213595 L46.8944272,11.4472136 C47.5719952,11.7859976 48,12.4785239 48,13.236068 L48,56.763932 C48,57.5214761 47.5719952,58.2140024 46.8944272,58.5527864 L24.8944272,69.5527864 C24.3313722,69.8343139 23.6686278,69.8343139 23.1055728,69.5527864 L1.10557281,58.5527864 C0.428004752,58.2140024 1.16888235e-13,57.5214761 1.16795462e-13,56.763932 L1.13686838e-13,13.236068 C1.13594065e-13,12.4785239 0.428004752,11.7859976 1.10557281,11.4472136 L23.1055728,0.447213595 C23.6686278,0.165686105 24.3313722,0.165686105 24.8944272,0.447213595 Z'
          id='path-1'
        />
        <circle id='path-3' cx='15' cy='15' r='15' />
      </defs>
      <g id='Illustration_Plan' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <ellipse id='Oval' fillOpacity='0.1' fill='#384A6F' cx='100' cy='158' rx='70' ry='2' />
        <path
          // eslint-disable-next-line max-len
          d='M16,0 L184,0 C187.313708,-6.08718376e-16 190,2.6862915 190,6 L190,10 L10,10 L10,6 C10,2.6862915 12.6862915,6.08718376e-16 16,0 Z'
          id='Rectangle'
          fill='#DBECFF'
        />
        <path
          d='M10,10 L122,10 L122,150 L16,150 C12.6862915,150 10,147.313708 10,144 L10,10 Z'
          id='Rectangle-Copy-2'
          fill='#5C89E5'
        />
        <g id='Group-5' transform='translate(42.000000, 25.000000)'>
          <mask id='mask-2' fill='white'>
            <use xlinkHref='#path-1' />
          </mask>
          <g id='Mask' />
          <g mask='url(#mask-2)' fill='#FFFFFF'>
            <g transform='translate(-0.000000, 0.000000)'>
              <polygon id='Rectangle-4' fillOpacity='0.1' points='24 24 24 70 1.13686838e-13 58 1.13686838e-13 12' />
              <polygon id='Rectangle-4' fillOpacity='0.16' points='24 24 24 70 48 58 48 12' />
              <path
                // eslint-disable-next-line max-len
                d='M14,57 L14,65 L10,63 L10,55 L14,57 Z M31,27 L35,25 L35,33 L31,35 L31,27 Z M39,23 L43,21 L43,29 L39,31 L39,23 Z M31,40 L35,38 L35,46 L31,48 L31,40 Z M39,36 L43,34 L43,42 L39,44 L39,36 Z M31,53 L35,51 L35,59 L31,61 L31,53 Z M39,49 L43,47 L43,55 L39,57 L39,49 Z'
                id='Combined-Shape'
                fillOpacity='0.2'
              />
              <polygon id='Rectangle-4' fillOpacity='0.34' points='24 1.13742349e-13 48 12 24 24 -2.84217094e-14 12' />
            </g>
          </g>
        </g>
        <circle id='Oval' fill='#BDE2FF' cx='21' cy='21' r='4' />
        <g id='Group-12' transform='translate(6.000000, 86.000000)'>
          <circle id='Oval-Copy-3' fill='#2D4969' cx='9' cy='9' r='9' />
          <path
            // eslint-disable-next-line max-len
            d='M7,12.0657415 L7,5.93425855 C7,5.65811617 7.22385763,5.43425855 7.5,5.43425855 C7.59871249,5.43425855 7.69521634,5.46347756 7.7773501,5.5182334 L12.3759623,8.58397485 C12.6057266,8.73715108 12.6678136,9.04758575 12.5146373,9.2773501 C12.4780199,9.33227624 12.4308884,9.37940772 12.3759623,9.41602515 L7.7773501,12.4817666 C7.54758575,12.6349428 7.23715108,12.5728559 7.08397485,12.3430916 C7.02921901,12.2609578 7,12.1644539 7,12.0657415 Z'
            id='Path-2'
            fill='#FFFFFF'
          />
        </g>
        <g id='Group-7' transform='translate(30.000000, 96.000000)' fill='#FFFFFF'>
          <path
            // eslint-disable-next-line max-len
            d='M1,2 L23,2 C23.5522847,2 24,2.44771525 24,3 L24,3 C24,3.55228475 23.5522847,4 23,4 L1,4 C0.44771525,4 6.76353751e-17,3.55228475 0,3 L0,3 C-6.76353751e-17,2.44771525 0.44771525,2 1,2 Z'
            id='Rectangle'
          />
          <circle id='Oval-Copy-4' stroke='#5C89E5' strokeWidth='2' cx='14' cy='3' r='4' />
        </g>
        <path
          d='M122,10 L190,10 L190,144 C190,147.313708 187.313708,150 184,150 L122,150 L122,10 Z'
          id='Rectangle-Copy-2'
          fill='#E6F4FF'
        />
        <path
          // eslint-disable-next-line max-len
          d='M130,16 L174,16 C175.104569,16 176,16.8954305 176,18 L176,42 C176,43.1045695 175.104569,44 174,44 L130,44 C128.895431,44 128,43.1045695 128,42 L128,18 C128,16.8954305 128.895431,16 130,16 Z'
          id='Rectangle-Copy-2'
          fill='#FFFFFF'
        />
        <path
          // eslint-disable-next-line max-len
          d='M129.5,48 L154.5,48 C155.328427,48 156,48.6715729 156,49.5 L156,49.5 C156,50.3284271 155.328427,51 154.5,51 L129.5,51 C128.671573,51 128,50.3284271 128,49.5 L128,49.5 C128,48.6715729 128.671573,48 129.5,48 Z'
          id='Rectangle-Copy-5'
          fill='#FFFFFF'
        />
        <path
          // eslint-disable-next-line max-len
          d='M129.5,55 L154.5,55 C155.328427,55 156,55.6715729 156,56.5 L156,56.5 C156,57.3284271 155.328427,58 154.5,58 L129.5,58 C128.671573,58 128,57.3284271 128,56.5 L128,56.5 C128,55.6715729 128.671573,55 129.5,55 Z'
          id='Rectangle-Copy-2'
          fill='#FFFFFF'
        />
        <path
          // eslint-disable-next-line max-len
          d='M129.5,62 L144.5,62 C145.328427,62 146,62.6715729 146,63.5 L146,63.5 C146,64.3284271 145.328427,65 144.5,65 L129.5,65 C128.671573,65 128,64.3284271 128,63.5 L128,63.5 C128,62.6715729 128.671573,62 129.5,62 Z'
          id='Rectangle-Copy-2'
          fill='#FFFFFF'
        />
        <g id='Group-8' transform='translate(154.000000, 21.000000)'>
          <path
            // eslint-disable-next-line max-len
            d='M6,0 L36,0 C39.3137085,-6.08718376e-16 42,2.6862915 42,6 L42,46 C42,49.3137085 39.3137085,52 36,52 L6,52 C2.6862915,52 4.05812251e-16,49.3137085 0,46 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z'
            id='Rectangle-Copy-2'
            fill='#BDE2FF'
          />
          <g id='Group-11' transform='translate(9.000000, 42.000000)'>
            <rect id='Rectangle' fill='#FFFFFF' x='9' y='0' width='5' height='3' rx='1.5' />
            <rect id='Rectangle-Copy-7' fill='#5C89E5' x='0' y='0' width='5' height='3' rx='1.5' />
            <rect id='Rectangle-Copy-8' fill='#F5CA5D' x='18' y='0' width='5' height='3' rx='1.5' />
          </g>
          <g id='Group-10' transform='translate(6.000000, 6.000000)'>
            <circle id='Oval' fill='#BDE2FF' cx='15' cy='15' r='15' />
            <g id='Rectangle'>
              <mask id='mask-4' fill='white'>
                <use xlinkHref='#path-3' />
              </mask>
              <use id='Mask' fill='#E6F4FF' xlinkHref='#path-3' />
              <polygon fill='#F5CA5D' mask='url(#mask-4)' points='32 15 32 31 21 31 15 15' />
              <polygon fill='#5C89E5' mask='url(#mask-4)' points='15 -2 32 -2 32 21 15 15' />
            </g>
          </g>
        </g>
        <path
          // eslint-disable-next-line max-len
          d='M115,78 L165,78 C167.209139,78 169,79.790861 169,82 L169,100 C169,102.209139 167.209139,104 165,104 L115,104 C112.790861,104 111,102.209139 111,100 L111,82 C111,79.790861 112.790861,78 115,78 Z'
          id='Rectangle-Copy-2'
          fill='#FFFFFF'
        />
        <path
          // eslint-disable-next-line max-len
          d='M117,99 C119,99 122.833333,99 128.5,99 C137,99 137.5,94 142,94 C146.5,94 148.50327,98.9026647 159.5,99 C159.5,99 160.333333,99 162,99'
          id='Path-3'
          stroke='#F5CA5D'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          // eslint-disable-next-line max-len
          d='M117,99 C116.333333,99 118.166667,99 122.5,99 C129,99 129,88 135,88 C141,88 141,99 148.5,99 C153.5,99 158,99 162,99'
          id='Path-3'
          stroke='#FF8566'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          // eslint-disable-next-line max-len
          d='M117,99 C119,99 122.166667,99 126.5,99 C133,99 135,83 141,83 C147,83 148.50327,98.9077117 159.5,99 C159.5,99 160.333333,99 162,99'
          id='Path-3'
          stroke='#BDE2FF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          // eslint-disable-next-line max-len
          d='M10,110 L190,110 L190,144 C190,147.313708 187.313708,150 184,150 L16,150 C12.6862915,150 10,147.313708 10,144 L10,110 Z'
          id='Rectangle-Copy-4'
          fill='#384A6F'
        />
        <path
          // eslint-disable-next-line max-len
          d='M155,121 L155,121 C155.552285,121 156,121.447715 156,122 L156,138 C156,138.552285 155.552285,139 155,139 L155,139 C154.447715,139 154,138.552285 154,138 L154,122 C154,121.447715 154.447715,121 155,121 Z'
          id='Rectangle'
          fill='#5C89E5'
        />
        <circle id='Oval-Copy-4' stroke='#384A6F' fill='#5C89E5' cx='155' cy='132' r='3.5' />
        <path
          // eslint-disable-next-line max-len
          d='M165,121 L165,121 C165.552285,121 166,121.447715 166,122 L166,138 C166,138.552285 165.552285,139 165,139 L165,139 C164.447715,139 164,138.552285 164,138 L164,122 C164,121.447715 164.447715,121 165,121 Z'
          id='Rectangle'
          fill='#5C89E5'
        />
        <path
          // eslint-disable-next-line max-len
          d='M165,132.5 C163.067003,132.5 161.5,130.932997 161.5,129 C161.5,127.067003 163.067003,125.5 165,125.5 C166.932997,125.5 168.5,127.067003 168.5,129 C168.5,130.932997 166.932997,132.5 165,132.5 Z'
          id='Oval-Copy-4'
          stroke='#384A6F'
          fill='#5C89E5'
        />
        <path
          // eslint-disable-next-line max-len
          d='M42,127 L78,127 L78,131 L42,131 C40.8954305,131 40,130.104569 40,129 L40,129 C40,127.895431 40.8954305,127 42,127 Z'
          id='Rectangle-Copy-3'
          stroke='#F5CA5D'
          strokeWidth='2'
          fill='#F5CA5D'
        />
        <path
          // eslint-disable-next-line max-len
          d='M78,127 L92,127 C93.1045695,127 94,127.895431 94,129 L94,129 C94,130.104569 93.1045695,131 92,131 L78,131 L78,127 Z'
          id='Rectangle-Copy-3'
          stroke='#F5CA5D'
          strokeWidth='2'
          opacity='0.6'
        />
        <path
          // eslint-disable-next-line max-len
          d='M22,118 L37,118 C38.1045695,118 39,118.895431 39,120 L39,120 C39,121.104569 38.1045695,122 37,122 L22,122 C20.8954305,122 20,121.104569 20,120 L20,120 C20,118.895431 20.8954305,118 22,118 Z'
          id='Rectangle-Copy-3'
          stroke='#BDE2FF'
          strokeWidth='2'
          fill='#BDE2FF'
        />
        <path
          // eslint-disable-next-line max-len
          d='M58,136 L78,136 L78,140 L58,140 C56.8954305,140 56,139.104569 56,138 L56,138 C56,136.895431 56.8954305,136 58,136 Z'
          id='Rectangle-Copy-6'
          stroke='#5C89E5'
          strokeWidth='2'
          fill='#5C89E5'
        />
        <path
          // eslint-disable-next-line max-len
          d='M78,136 L126,136 C127.104569,136 128,136.895431 128,138 L128,138 C128,139.104569 127.104569,140 126,140 L78,140'
          id='Rectangle-Copy-9'
          stroke='#5C89E5'
          strokeWidth='2'
          opacity='0.6'
        />
        <polygon
          id='Path'
          fill='#FF8566'
          points='79 146 83 150 73 150 77 146 77 114 73 110 83 110 79 114'
        />
        <path
          // eslint-disable-next-line max-len
          d='M18,7 C16.8954305,7 16,6.1045695 16,5 C16,3.8954305 16.8954305,3 18,3 C19.1045695,3 20,3.8954305 20,5 C20,6.1045695 19.1045695,7 18,7 Z M25,3 L51,3 C52.1045695,3 53,3.8954305 53,5 C53,6.1045695 52.1045695,7 51,7 L25,7 C23.8954305,7 23,6.1045695 23,5 C23,3.8954305 23.8954305,3 25,3 Z'
          id='Shape'
          fill='#FFFFFF'
        />
      </g>
    </svg>
  );
};
