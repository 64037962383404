import * as React from 'react';
import { MaterialTileMenu, MaterialTileMenuGroup, MaterialTileMenuItem } from 'common/components/material-tile-menu';

export interface MenuItemType {
  label: string;
  action: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
}

interface Props {
  menu: MenuItemType[];
  openBtnWhite?: boolean;
}

export class TileMenu extends React.Component<Props> {
  public render(): JSX.Element {
    const { menu } = this.props;
    return (
      <MaterialTileMenu openBtnWhite={this.props.openBtnWhite}>
        <MaterialTileMenuGroup>
        {menu.map(menuItem => (
          <MaterialTileMenuItem key={menuItem.label} label={menuItem.label} onClick={menuItem.action} />
        ))}
        </MaterialTileMenuGroup>
      </MaterialTileMenu>
    );
  }
}
