import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { CurrencyModel } from '../../units/account/interfaces/currency-model';
import { KreoI18n } from './kreo-i18n';


const I18nContext = React.createContext<KreoI18n>(null);

const DEFAULT_CURRENCY: CurrencyModel = {
  code: 'GBP',
  name: 'British Pound Sterling',
  symbol: '£',
};


interface ProviderStateProps {
  currency: CurrencyModel;
}

interface ProviderProps extends ProviderStateProps {
  children?: React.ReactNode;
}

interface ProviderState {
  i18n: KreoI18n;
}

class I18nContextProviderComponent extends React.Component<ProviderProps, ProviderState> {
  public constructor(props: ProviderProps) {
    super(props);

    this.state = {
      i18n: new KreoI18n(props.currency),
    };
  }

  public static getDerivedStateFromProps(props: ProviderProps, state: ProviderState): Partial<ProviderState> | null {
    if (props.currency && props.currency.code !== state.i18n.currency.code) {
      return { i18n: new KreoI18n(props.currency) };
    }

    return null;
  }

  public render(): React.ReactNode {
    return (
      <I18nContext.Provider value={this.state.i18n}>
        {this.props.children}
      </I18nContext.Provider>
    );
  }
}

function mapStateToProps(state: State): ProviderStateProps {
  const currency = state.account &&
    state.account.selectedCompany &&
    state.account.selectedCompany.currency
    ? state.account.selectedCompany.currency
    : DEFAULT_CURRENCY;

  return { currency };
}

export const I18nContextProvider = connect(mapStateToProps)(I18nContextProviderComponent);

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface I18nAwareProps {
  i18n: KreoI18n;
}

export function withI18n<P extends I18nAwareProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof I18nAwareProps>>> {
  // eslint-disable-next-line react/no-multi-comp
  return class I18nAwareComponent extends React.Component<Pick<P, Exclude<keyof P, keyof I18nAwareProps>>> {
    public render(): React.ReactNode {
      return (
        <I18nContext.Consumer>
          {
            (i18n) => {
              return <Component {...this.props as P} i18n={i18n} />;
            }
          }
        </I18nContext.Consumer>
      );
    }
  };
}
