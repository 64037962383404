import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
`;

const Avatar = styled.div`
  .image-uploader__files-input-container {
    margin: unset;
  }
  .image-uploader__load-container {
    width: 50px;
    height: 50px;
  }

  .image-uploader__dropzone {
    border-radius: 10px !important;
    border: none !important;
    background: ${p => p.theme.color.turquoise};
  }

  .image-uploader__delete-preview {
    display: none;
  }

  .image-uploader__dropzone:before {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-size: 20px;
  }

  .image-uploader__preview {
    height: 100%;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;

  > button:hover {
    svg {
      fill: ${p => p.theme.color.red};
    }
  }
`;

export const Styled = {
  Container,
  ButtonContainer,
  Buttons,
  Avatar,
};
