import { InputWithSlider, ContextMenuGroup } from '@kreo/kreo-ui-components';
import React from 'react';

import { DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsStrokeStyleSwitcher } from '../../drawings-stroke-style-switcher';
import { Styled } from './styled';


interface Props {
  setStrokeStyle?: (value: DrawingStrokeStyles) => void;
  strokeWidth?: number;
  setStrokeWidth?: (value: number) => void;
  strokeStyle?: DrawingStrokeStyles;
}

const StrokedMenuComponent: React.FC<Props> = ({ setStrokeStyle, strokeWidth, setStrokeWidth, strokeStyle }) => {
  return (
    <ContextMenuGroup>
      <Styled.InputWithSliderWrap>
        <InputWithSlider
          min={1}
          max={10}
          onChange={setStrokeWidth}
          value={strokeWidth}
          type='Stroke width, px'
        />
      </Styled.InputWithSliderWrap>
      <Styled.RoundSwitcher>
        <DrawingsStrokeStyleSwitcher
          setStrokeStyle={setStrokeStyle}
          strokeStyle={strokeStyle}
        />
      </Styled.RoundSwitcher>
    </ContextMenuGroup>
  );
};

export const StrokeMenu = React.memo(StrokedMenuComponent);
