import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';


export const KreoIconLayerFinish: React.FC = () => (
  <svg width='44px' height='44px' viewBox='0 0 44 44' version='1.1'>
    <g id='Icons/44x44/Layer-Finish' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        // eslint-disable-next-line max-len
        d='M12.361232,9.54945118 L28.4690376,17.3732424 L31.5675857,15.6518268 L15.5437785,7.63992326 L12.361232,9.54945118 Z M11.5,11.3545758 L11.5,27.881966 L27.5,35.881966 L27.5,19.1260044 L11.5,11.3545758 Z M29.5,35.7338096 L32.5,33.9338096 L32.5,17.4217367 L29.5,19.0884033 L29.5,35.7338096 Z M9.5,27.881966 L9.5,10.0661904 C9.5,9.36366467 9.86859767,8.71265102 10.4710085,8.35120453 L14.514787,5.92493741 C15.1011413,5.57312483 15.8265958,5.54526389 16.4382057,5.85106888 L33.3944272,14.3291796 C34.0719952,14.6679636 34.5,15.3604899 34.5,16.118034 L34.5,33.9338096 C34.5,34.6363353 34.1314023,35.287349 33.5289915,35.6487955 L29.485213,38.0750626 C28.8988587,38.4268752 28.1734042,38.4547361 27.5617943,38.1489311 L10.6055728,29.6708204 C9.92800475,29.3320364 9.5,28.6395101 9.5,27.881966 Z M16.2763932,18.9472136 L14.2763932,17.9472136 C13.6801084,17.6490712 14.127322,16.754644 14.7236068,17.0527864 L16.7236068,18.0527864 C17.3198916,18.3509288 16.872678,19.245356 16.2763932,18.9472136 Z M16.2763932,26.9472136 L14.2763932,25.9472136 C13.6801084,25.6490712 14.127322,24.754644 14.7236068,25.0527864 L16.7236068,26.0527864 C17.3198916,26.3509288 16.872678,27.245356 16.2763932,26.9472136 Z M24.2763932,22.9472136 L22.2763932,21.9472136 C21.6801084,21.6490712 22.127322,20.754644 22.7236068,21.0527864 L24.7236068,22.0527864 C25.3198916,22.3509288 24.872678,23.245356 24.2763932,22.9472136 Z M24.2763932,30.9472136 L22.2763932,29.9472136 C21.6801084,29.6490712 22.127322,28.754644 22.7236068,29.0527864 L24.7236068,30.0527864 C25.3198916,30.3509288 24.872678,31.245356 24.2763932,30.9472136 Z M20.2763932,24.9472136 L18.2763932,23.9472136 C17.6801084,23.6490712 18.127322,22.754644 18.7236068,23.0527864 L20.7236068,24.0527864 C21.3198916,24.3509288 20.872678,25.245356 20.2763932,24.9472136 Z'
        id='Shape'
        fill={KreoColors.blue}
        fillRule='nonzero'
      />
    </g>
  </svg>
);

