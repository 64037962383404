import { isEqual } from 'lodash';
import * as React from 'react';

import './graph-edge.scss';

import { Direction, GraphItemDisplayType } from './enums';
import { Coordinate, EdgePosition, GraphEdgeProps } from './interfaces';


export class GraphEdge extends React.Component<GraphEdgeProps> {
  private edgeVertexPadding: number = 20;
  private firstXPathLength: number = 30;
  private radius: number = 10;
  private defaultStrokeWidth: number = 1;

  public shouldComponentUpdate(prevProps: GraphEdgeProps): boolean {
    const props = this.props;
    return !isEqual(props.from, prevProps.from) ||
      !isEqual(props.to, prevProps.to) ||
      props.vertexWidth !== prevProps.vertexWidth ||
      props.direction !== prevProps.direction ||
      props.displayType !== prevProps.displayType;
  }

  public render(): React.ReactFragment {
    const position = this.props;
    const direction = this.props.direction;
    return this.getEdgeSvg(position, this.defaultStrokeWidth, direction);
  }

  private getClassName(displayType: GraphItemDisplayType): string {
    if (!displayType) {
      displayType = GraphItemDisplayType.Normal;
    }

    return `graph-edge graph-edge--${displayType}`;
  }

  private getEdgeSvg(position: EdgePosition, strokeWidth: number, direction: Direction): JSX.Element {
    switch (direction) {
      case Direction.Forward:
      case Direction.Back:
        return this.getStartFinishEdge(position, strokeWidth, direction);
      default:
        return this.getStartStartEdge(position, strokeWidth, direction);
    }
  }

  private getEdge(coordinate: Coordinate, path: string): JSX.Element {
    return (
      <svg
        x={coordinate.x}
        y={coordinate.y}
        className={this.getClassName(this.props.displayType)}
      >
          <path d={path} fill='none'/>
      </svg>
    );
  }

  private getStartFinishEdge(position: EdgePosition, strokeWidth: number, direction: Direction): JSX.Element {
    const from: Coordinate = {
      x: position.from.x + this.props.vertexWidth,
      y: position.from.y + this.edgeVertexPadding,
    };
    const to: Coordinate = {
      x: position.to.x,
      y: position.to.y + this.edgeVertexPadding,
    };
    const coordinate: Coordinate = {
      x: Math.min(from.x, to.x),
      y: Math.min(from.y, to.y),
    };
    const radiusHeights = to.y !== from.y ? this.radius * 2 : 0;
    const yPath = Math.sign(to.y - from.y) * (Math.abs(to.y - from.y) - radiusHeights);
    const xPath1 = direction === Direction.Forward
      ? this.firstXPathLength
      : (to.x - from.x) - this.firstXPathLength - radiusHeights;
    const xPath2 = direction === Direction.Back
      ? this.firstXPathLength
      : (to.x - from.x) - this.firstXPathLength - radiusHeights;

    const path = `
      M0,${from.y - coordinate.y + strokeWidth}
      h${xPath1}
      ${this.getFirstTurn(from.y, to.y, direction)}
      v${yPath}
      ${this.getSecondTurn(from.y, to.y, direction)}
      h${xPath2}`;

    return this.getEdge(coordinate, path);
  }

  private getStartStartEdge(position: EdgePosition, strokeWidth: number, direction: Direction): JSX.Element {
    const from: Coordinate = {
      x: position.from.x,
      y: position.from.y + this.edgeVertexPadding,
    };
    const to: Coordinate = {
      x: position.to.x,
      y: position.to.y + this.edgeVertexPadding,
    };
    const coordinate: Coordinate = {
      x: Math.min(from.x, to.x) - this.radius - this.firstXPathLength,
      y: Math.min(from.y, to.y),
    };
    const yPath = Math.sign(to.y - from.y) * (Math.abs(to.y - from.y) - this.radius * 2);
    const xPath1 = -this.firstXPathLength;
    const xPath2 = (to.x - from.x) + this.firstXPathLength;

    const path = `
      M${this.firstXPathLength + this.radius},${from.y - coordinate.y + strokeWidth}
      h${xPath1}
      ${this.getFirstTurn(from.y, to.y, direction)}
      v${yPath}
      ${this.getSecondTurn(from.y, to.y, direction)}
      h${xPath2}`;

    return this.getEdge(coordinate, path);
  }

  private getFirstTurn(fromY: number, toY: number, direction: Direction): string {
    if (fromY === toY) {
      return '';
    }

    const r = this.radius;

    switch (direction) {
      case Direction.Forward:
      case Direction.Back:
        return fromY > toY
          ? `a${r},${r} 1 0 0 ${r},-${r}`
          : `a${r},${r} 0 0 1 ${r},${r}`;
      // case LimitationType.StartStart:
      //   return fromY > toY
      //     ? `a${r},${r} 0 0 1 -${r},-${r}`
      //     : `a${r},${r} 0 0 0 -${r},${r}`;
      // case LimitationType.FinishFinish:
      //   return fromY > toY
      //     ? `a${r},${r} 0 0 1 ${r},${r}`
      //     : `a${r},${r} 0 0 0 ${r},-${r}`;
      default:
        return '';
    }
  }

  private getSecondTurn(fromY: number, toY: number, direction: Direction): string {
    if (fromY === toY) {
      return '';
    }

    const r = this.radius;

    switch (direction) {
      case Direction.Forward:
      case Direction.Back:
        return fromY > toY
          ? `a${r},${r} 0 0 1 ${r},-${r}`
          : `a${r},${r} 1 0 0 ${r},${r}`;
      // case LimitationType.StartStart:
      //   return fromY > toY
      //     ? `a${r},${r} 0 0 1 ${r},-${r}`
      //     : `a${r},${r} 0 0 0 ${r},${r}`;
      // case LimitationType.FinishFinish:
      //   return fromY > toY
      //     ? `a${r},${r} 0 0 1 -${r},${r}`
      //     : `a${r},${r} 0 0 0 -${r},-${r}`;
      default:
        return '';
    }
  }
}
