const shortDateFormatter: Intl.DateTimeFormat = new Intl.DateTimeFormat(
  'en-GB',
  { day: 'numeric', month: 'short' });
const fullDateFormatter: Intl.DateTimeFormat = new Intl.DateTimeFormat(
  'en-GB',
  { day: 'numeric', month: 'short', year: 'numeric' });

export const datesFormatter = {
  formatDateSpan: (startDay: number, endDay: number, projectStartDate: Date): { start: string, end: string } => {
    const startDate = new Date(projectStartDate);
    startDate.setDate(startDate.getDate() + startDay);

    const endDate = new Date(projectStartDate);
    endDate.setDate(endDate.getDate() + endDay);

    const isYearNeeded = endDate.getFullYear() !== startDate.getFullYear();

    const startDateString = isYearNeeded
      ? fullDateFormatter.format(startDate)
      : shortDateFormatter.format(startDate);

    const endDateString = fullDateFormatter.format(endDate);

    return {
      start: startDateString,
      end: endDateString,
    };
  },
  formatFullDate: (day: number, projectStartDate: Date): string => {
    const date = new Date(projectStartDate);
    date.setDate(date.getDate() + day);

    return fullDateFormatter.format(date);
  },
};
