import {
  TreeTableData,
  TreeTableRow,
  TreeTableRowAddModel,
  TreeTableRowType,
} from 'common/components/tree-table/interfaces';
import { ValueHelper } from 'common/utils/value-helper';
import { DefaultPivotColumnKey } from '../../../enums/default-pivot-column-key';
import { QtoReportRowPropertiesKeys } from '../../../enums/qto-report-row-properties-keys';
import {
  AggFuncType,
  QtoReport,
  QtoReportRow,
  QtoTreeColumn,
  QtoTreeColumnsForm,
  QtoTreeColumnWithId,
  QtoTreeRowForm,
  QtoTreeRowProperty,
} from '../../../interfaces/quantity-take-off';
import { reportTableAggregations } from '../aggregation-functions';
import { QtoTreeTableHelper } from '../column-helper';
import { QuantityTakeOffFormulaHelper } from '../formula-helper';
import { PropertyHelper } from '../property-helper';
import { QtoReportPivotTableIdHelper } from './id-helper';

interface QtoReportPivotTableMapResult {
  treeTableData: TreeTableData<QtoTreeRowProperty, QtoTreeRowProperty>;
  defaultValues: Record<string, QtoTreeRowProperty>;
}

export class QtoReportPivotTableMapper {
  public static getDefaultPivotColumnsForm(): QtoTreeColumnsForm {
    const columns = {
      [DefaultPivotColumnKey.Quantity]: {
        properties: {
          [PropertyHelper.columnProperties.header]: { default: 'Quantity' },
          [PropertyHelper.columnProperties.isVisible]: { default: true },
        },
      },
      [DefaultPivotColumnKey.Unit]: {
        properties: {
          [PropertyHelper.columnProperties.header]: { default: 'Unit' },
          [PropertyHelper.columnProperties.isVisible]: { default: true },
        },
      },
      [DefaultPivotColumnKey.Rate]: {
        properties: {
          [PropertyHelper.columnProperties.header]: { default: 'Rate' },
          [PropertyHelper.columnProperties.isVisible]: { default: true },
        },
      },
      [DefaultPivotColumnKey.Total]: {
        properties: {
          [PropertyHelper.columnProperties.header]: { default: 'Total' },
          [PropertyHelper.columnProperties.isVisible]: { default: true },
        },
      },
    };

    return {
      columns,
      firstLevelColumns: [
        DefaultPivotColumnKey.Quantity,
        DefaultPivotColumnKey.Unit,
        DefaultPivotColumnKey.Rate,
        DefaultPivotColumnKey.Total,
      ],
    };
  }

  public static formNewPivotRow(id: string): TreeTableRowAddModel<QtoTreeRowProperty> {
    return {
      id,
      type: TreeTableRowType.Element,
      properties: {},
    };
  }

  public static appendGroupPropertiesFromPivotRowForm(
    groupProperties: Record<string, QtoTreeRowProperty>,
    pivotRow: QtoTreeRowForm,
  ): void {
    const ids = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(pivotRow.id);
    if (!ids) {
      return;
    }

    Object.entries(pivotRow.properties).forEach(([key, property]) => {
      if (key === DefaultPivotColumnKey.Unit || key === DefaultPivotColumnKey.Name) {
        return;
      } else if (key === DefaultPivotColumnKey.Quantity) {
        if (property && property.override && property.override !== 0) {
          groupProperties[ids.columnId].override = property.override;
        } else {
          groupProperties[ids.columnId] = PropertyHelper.getAggregationProperty();
        }
      } else {
        groupProperties[QtoReportPivotTableIdHelper.getPivotPropertyId(ids.columnId, key)] = property;
      }
    });

    if (!(DefaultPivotColumnKey.IsVisible in pivotRow.properties)) {
      const visibilityKey = QtoReportPivotTableIdHelper.getPivotPropertyId(
        ids.columnId, DefaultPivotColumnKey.IsVisible);
      delete groupProperties[visibilityKey];
    }
  }

  public static map(data: QtoReport): QtoReportPivotTableMapResult {
    const visibleColumns = QtoReportPivotTableMapper.getFilteredColumns(
      data.basicColumns.columns,
      data.basicColumns.firstLevelColumns,
    );

    const defaultValues = {};
    const treeTableData = {
      columns: data.pivotColumns.columns,
      firstLevelColumns: data.pivotColumns.firstLevelColumns,
      firstLevelRows: data.firstLevelRows,
      rows: QtoReportPivotTableMapper.getRows(data.rows, visibleColumns, defaultValues),
    };

    return {
      defaultValues,
      treeTableData,
    };
  }

  private static isPivotRowVisible(row: QtoReportRow, column: QtoTreeColumnWithId): boolean {
    const pivotVisibilityValue = QtoReportPivotTableMapper.getPivotRowVisibilityValue(row, column.id);
    return pivotVisibilityValue === undefined
      ? QtoReportPivotTableMapper.isColumnVisible(row, column)
      : pivotVisibilityValue;
  }

  private static getPivotRowVisibilityValue(row: QtoReportRow, columnId: string): boolean | undefined {
    const visiblePropertyKey = QtoReportPivotTableIdHelper
      .getPivotPropertyId(columnId, DefaultPivotColumnKey.IsVisible);
    const visibleProperty = row.properties[visiblePropertyKey];
    return PropertyHelper.getActualValue<boolean | undefined>(visibleProperty);
  }

  private static isColumnVisible(row: QtoReportRow, column: QtoTreeColumnWithId): boolean {
    const columnVisibleProp = column.properties[PropertyHelper.columnProperties.isVisible];
    const hasValue = !!row.properties[column.id];
    return hasValue && !!PropertyHelper.getActualValue(columnVisibleProp);
  }

  private static getFilteredColumns(
    columns: Record<string, QtoTreeColumn>, firstLevelColumns: string[],
  ): QtoTreeColumnWithId[] {
    const result = [];
    for (const columnId of firstLevelColumns) {
      if (QtoTreeTableHelper.isColumnIdHasDiffPostfix(columnId)) {
        continue;
      }

      result.push({
        id: columnId,
        ...columns[columnId],
      });
    }

    return result;
  }

  private static getColumnValuesAndUpdatedIndex(
    rows: QtoReportRow[],
    index: number,
    columns: QtoTreeColumnWithId[],
  ): [Record<string, any[]>, number] {
    const columnToValues = QtoReportPivotTableMapper.getEmptyColumnValuesMap(columns);
    while (rows[index] && rows[index].type === TreeTableRowType.Element) {
      const row = rows[index];
      for (const [colId, values] of Object.entries(columnToValues)) {
        let value = PropertyHelper.getActualValue<string | number>(row.properties[colId] as any);
        if (PropertyHelper.isFormula(row.properties[colId])) {
          const params: any = {
            node: {
              data: {
                properties: row.properties,
              },
            },
          };
          try {
            value = QuantityTakeOffFormulaHelper.calculateFormula(
              (value as string).replace(QuantityTakeOffFormulaHelper.nonBreakingSpaceRegex, ''),
              params,
            );
          } catch {
            value = 'Invalid';
          }
        }
        if (!ValueHelper.isNumberValue(value)) {
          columnToValues[colId] = null;
        } else if (values) {
          values.push(value);
        }
      }

      index++;
    }

    return [columnToValues, index];
  }

  private static getPivotRowsForNotLeafGroup(
    row: QtoReportRow,
    columns: QtoTreeColumnWithId[],
    diffStatusProperty: QtoTreeRowProperty,
  ): Array<TreeTableRow<QtoTreeRowProperty>> {
    const result = [];
    columns.forEach(column => {
      if (column.id in row.properties && QtoReportPivotTableMapper.isPivotRowVisible(row, column)) {
        const pivotRowId = QtoReportPivotTableIdHelper.getPivotRowId(row.id, column.id);
        const pivotRow = QtoReportPivotTableMapper.formPivotRow(pivotRowId, row.id);
        pivotRow.properties[QtoReportRowPropertiesKeys.DiffStatus] = diffStatusProperty;
        result.push(pivotRow);
      }
    });

    return result;
  }

  private static getRows(
    rows: QtoReportRow[],
    columns: QtoTreeColumnWithId[],
    defaultValues: Record<string, QtoTreeRowProperty>,
  ): Array<TreeTableRow<QtoTreeRowProperty>> {
    const result: Array<TreeTableRow<QtoTreeRowProperty>> = [];
    const rowsLength = rows.length;

    let index = 0;
    while (index < rowsLength) {
      const row = rows[index];
      const nextRow = rows[index + 1];
      const diffStatus = row.properties[QtoReportRowPropertiesKeys.DiffStatus];
      if (nextRow && nextRow.type === TreeTableRowType.Element) {
        const [columnValues, newIndex] = QtoReportPivotTableMapper
          .getColumnValuesAndUpdatedIndex(rows, index + 1, columns);
        const children = QtoReportPivotTableMapper.getPivotRows(
          columns,
          columnValues,
          row,
          defaultValues,
          diffStatus,
        );
        result.push(QtoReportPivotTableMapper.getGroupRow(row, children.map(x => x.id), diffStatus));
        children.forEach(x => result.push(x));
        index = newIndex;
      } else {
        const groupPivotRows =
          QtoReportPivotTableMapper.getPivotRowsForNotLeafGroup(
            row,
            columns,
            diffStatus,
          );
        const rowChildrenIds = groupPivotRows.map(x => x.id).concat(row.children);
        result.push(QtoReportPivotTableMapper.getGroupRow(row, rowChildrenIds, diffStatus));
        groupPivotRows.forEach(x => result.push(x));
        index += 1;
      }
    }

    return result;
  }

  private static getEmptyColumnValuesMap(columns: QtoTreeColumnWithId[]): Record<string, any[]> {
    return columns
      .reduce(
        (result, column) => {
          result[column.id] = [];
          return result;
        },
        {});
  }

  private static getGroupRow(
    row: QtoReportRow,
    children: string[],
    diffStatusProperty: QtoTreeRowProperty,
  ): TreeTableRow<QtoTreeRowProperty> {
    return {
      id: row.id,
      type: row.type,
      properties: {
        ...row.properties,
        [QtoReportRowPropertiesKeys.DiffStatus]: diffStatusProperty,
      },
      parentId: row.parentId,
      children,
    };
  }

  private static getPivotRows(
    columns: QtoTreeColumnWithId[],
    columnValues: Record<string, any[]>,
    row: QtoReportRow,
    defaultValues: Record<string, QtoTreeRowProperty>,
    diffStatusProperty: QtoTreeRowProperty,
  ): Array<TreeTableRow<QtoTreeRowProperty>> {
    const result = [];
    columns.forEach(x => {
      if (QtoReportPivotTableMapper.isPivotRowVisible(row, x)) {
        const pivotRowId = QtoReportPivotTableIdHelper.getPivotRowId(row.id, x.id);
        defaultValues[pivotRowId] = this.getDefaultValue(row, x, columnValues);
        const pivotRow = QtoReportPivotTableMapper.formPivotRow(pivotRowId, row.id);
        pivotRow.properties[QtoReportRowPropertiesKeys.DiffStatus] = diffStatusProperty;
        result.push(pivotRow);
      }
    });

    return result;
  }

  private static getDefaultValue(
    row: QtoReportRow,
    column: QtoTreeColumnWithId,
    columnValues: Record<string, any[]>,
  ): QtoTreeRowProperty {
    const aggFunction = QtoReportPivotTableMapper.getAggFunction(column);
    return {
      default: aggFunction && columnValues[column.id]
        ? aggFunction(columnValues[column.id])
        : null,
      override: row.properties[column.id] && row.properties[column.id].override,
    };
  }

  private static getAggFunction(column: QtoTreeColumnWithId): AggFuncType {
    const aggType = PropertyHelper.getActualValue<string>(
      column.properties[PropertyHelper.columnProperties.aggregationStrategy],
    );
    return reportTableAggregations[aggType];
  }

  private static formPivotRow(
    pivotRowId: string, parentId: string,
  ): TreeTableRow<QtoTreeRowProperty> {
    return {
      id: pivotRowId,
      parentId,
      type: TreeTableRowType.Element,
      properties: {},
    };
  }
}
