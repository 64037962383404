import { ElementTooltip } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { KreoIconCellSelect } from 'common/UIKit/icons/modular';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { Styled } from './styled';

interface Props extends AnalyticsProps {
  onSelectClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  isSelect: boolean;
  canEditReport: boolean;
  canViewReport: boolean;
}

const description =
  `To add a measurement into the spreadsheet, select the relevant cell there,
  then click on the measurement. To finish, either press "S" or Escape.`;

const SelectMeasurementsButtonComponent = ({
  canEditReport,
  isSelect,
  onSelectClick,
  sendEvent,
}: Props): JSX.Element => {
  const onClick = React.useCallback((e: React.MouseEvent<HTMLDivElement>): void => {
    if (canEditReport) {
      onSelectClick(e);
      sendEvent(
        MetricNames.transferMeasurements.selectMeasures, { status: !isSelect ? 'Enable' : 'Disable' });
    }
  },
  [canEditReport, isSelect, onSelectClick, sendEvent]);

  return (
    <Styled.SelectButton
      onClick={onClick}
      isChecked={isSelect}
      reportEditPermission={canEditReport}
    >
      <ElementTooltip
        text='Select Measurements'
        position='left'
        size='small'
        description={description}
        tooltipWidth={210}
        speed='l'
      >
        <KreoIconCellSelect />
      </ElementTooltip>
    </Styled.SelectButton>
  );
};

export const SelectMeasurementsButton = withAnalyticsContext(SelectMeasurementsButtonComponent);
