import React from 'react';
import { RowData } from 'common/components/data-base-table';

type UseHandleRowSelectedCallback = (applyTemplate: (id: string) => void) => (data: RowData) => void;

export const useHandleRowSelectedCallback: UseHandleRowSelectedCallback = (applyTemplate) => {
  return React.useCallback((data) => {
    applyTemplate(data.id as string);
  }, [applyTemplate]);
};
