import { RequestStatus } from 'common/enums/request-status';
import { StringDictionary } from 'common/interfaces/dictionary';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import {
  LoadActivityCategoryCountsPayload,
  LoadActivityCategoryCountsResponsePayload,
  LoadFunctionsWithUnitsPayload,
} from '../actions/payloads/database-common';
import { DatabaseCommonActionTypes } from '../actions/types/database-common';
import { DATABASE_INITIAL_STATE } from '../constants/initial-state';
import { ActivityCategory } from '../interfaces/data';
import { DatabaseReduxState } from '../interfaces/redux-state';


export const DatabaseCommonReducerMethods: ReducerMethods<DatabaseReduxState> = {
  [DatabaseCommonActionTypes.GET_ACTIVITY_CATEGORIES]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.categories, RequestStatus.Loading)
      .get();
  },
  [DatabaseCommonActionTypes.GET_ACTIVITY_CATEGORIES_SUCCEEDED]: (
    state, payload: StringDictionary<ActivityCategory[]>,
  ) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.categories, RequestStatus.Loaded)
      .set((_) => _.categories, payload)
      .get();
  },
  [DatabaseCommonActionTypes.GET_ACTIVITY_CATEGORY_COUNTS]: (
    state, payload: LoadActivityCategoryCountsPayload,
  ) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.categoriesCounts[payload.type], RequestStatus.Loading)
      .set(
        (_) => _.currentDatabase.activityListing.categories.counts[payload.type],
        { total: 0, undefined: 0, byCategoryId: {} })
      .get();
  },
  [DatabaseCommonActionTypes.GET_ACTIVITY_CATEGORY_COUNTS_SUCCEEDED]: (
    state, payload: LoadActivityCategoryCountsResponsePayload,
  ) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.categoriesCounts[payload.type], RequestStatus.Loaded)
      .set((_) => _.currentDatabase.activityListing.categories.counts[payload.type], payload.counts)
      .get();
  },
  [DatabaseCommonActionTypes.LOAD_FUNCTIONS_WITH_UNITS_SUCCEEDED]: (
    state, payload: LoadFunctionsWithUnitsPayload,
  ) => {
    const measurementsMap = arrayUtils.toDictionary(payload.measurements, x => x.name, x => x);
    const unitMap = arrayUtils.toDictionary(payload.units, x => x.id, x => x);
    const measurementToUnits = arrayUtils.groupBy(payload.units, x => x.measurement);

    return new MonoliteHelper(state)
      .set(_ => _.statuses.databaseResources, RequestStatus.Loaded)
      .set(_ => _.currentDatabase.units, payload.units)
      .set(_ => _.currentDatabase.functions, payload.extractorFunctions)
      .set(_ => _.currentDatabase.measurementsMap, measurementsMap)
      .set(_ => _.currentDatabase.unitMap, unitMap)
      .set(_ => _.currentDatabase.measurementToUnits, measurementToUnits)
      .set(_ => _.currentDatabase.measurementTypes, payload.measurementTypes)
      .get();
  },
  [DatabaseCommonActionTypes.DROP_CURRENT_DATABASE_STATE]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase, DATABASE_INITIAL_STATE.currentDatabase)
      .set(_ => _.statuses.categoriesCounts, DATABASE_INITIAL_STATE.statuses.categoriesCounts)
      .set(_ => _.statuses.activityListing, RequestStatus.NotRequested)
      .set(_ => _.statuses.resourceListing, RequestStatus.NotRequested)
      .get();
  },
  [DatabaseCommonActionTypes.DROP_STATE]: () => {
    return DATABASE_INITIAL_STATE;
  },
};
