import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './index.scss';

import { EngineHotkeysHint, EngineHotkeysList } from 'common/components/engine-hotkeys-hint';
import { State as ReduxState } from 'common/interfaces/state';
import { Engine } from '../../../../components/engine';

interface ReduxProps {
  isActivePackage: boolean;
}

interface Props extends ReduxProps {
  projectId: number;
  sendEngineApi: (engine: any) => void;
  engineHandleClick: (ids: any[], anchorElement: any) => void;
  affterLoadEngineAction: () => void;
  children?: React.ReactNode;
}

interface State {
  engineContainerRef: HTMLDivElement;
}

class LayoutComponent extends React.Component<Props, State> {
  private engineContainerWrapRef: any = null;
  constructor(props: Props) {
    super(props);

    this.state = {
      engineContainerRef: null,
    };
  }

  public render(): JSX.Element {
    const { isActivePackage } = this.props;

    return (
      <div className='package-layout'>
        <div className={`packages-menu-container ${!isActivePackage ? 'reduced' : ''}`}>
          {this.props.children}
        </div>
        <div
          ref={this.makeEngineRef}
          className={`engine-container ${!isActivePackage ? 'enlarged' : ''}`}
        >
          <Engine
            textures='/static/textures/'
            sendEngineApi={this.props.sendEngineApi}
            projectId={this.props.projectId}
            onHandleClick={this.onHandleClick}
            affterLoadAction={this.props.affterLoadEngineAction}
            saveContainerRef={this.saveEngineContainerRef}
          />
          <EngineHotkeysHint containerRef={this.state.engineContainerRef} hotkeyList={EngineHotkeysList} />
        </div>
      </div>
    );
  }

  @autobind
  private saveEngineContainerRef(ref: HTMLDivElement): void {
    this.setState({ engineContainerRef: ref });
  }

  @autobind
  private makeEngineRef(ref: any): void {
    this.engineContainerWrapRef = ref;
  }

  @autobind
  private onHandleClick(ids: any[]): void {
    if (this.props.engineHandleClick) {
      this.props.engineHandleClick(ids, this.engineContainerWrapRef);
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    isActivePackage: state.workpackages.activePackage ? true : false,
  };
};

const connector = connect(mapStateToProps);
export const Layout = connector(LayoutComponent);
