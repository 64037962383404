export enum FaqCaption {
  Scenarios = 'FAQ on Scenarios',
  Costs =  'FAQ on Costs',
  Sequence = 'FAQ on Sequence',
  Schedule = 'FAQ on Schedule',
  ActivityAssignment = 'FAQ on Activity Assignment',
  Classification = 'FAQ on Information Modeling',
  Measurements = 'FAQ on Measurements',
  FourD = 'FAQ on 4D',
  QtoGeneral = 'HELP',
  Databases =  'FAQ on Databases',
  QuantityTakeOff = 'FAQ on Quantity Take Off',
}
