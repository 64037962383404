import { SvgComponent } from '@kreo/kreo-ui-components';
import { connect } from 'react-redux';
import { State } from 'common/interfaces/state';
import { TargetUtils } from 'unit-2d-comments/utils';
import { NewComment } from './new-comment';

interface StateProps {
  targetIcon: SvgComponent;
  targetTitle: string;
  targetTooltip: string;
}

function mapStateToProps(state: State): StateProps {
  const target = state.twoDComments.newCommentData;
  return {
    targetTitle: TargetUtils[target.type].nameSelector(state, target),
    targetIcon: TargetUtils[target.type].icon,
    targetTooltip: TargetUtils[target.type].tooltip,
  };
}


export const NewCommentReduxWrapper = connect(mapStateToProps)(NewComment);
