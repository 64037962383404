import * as React from 'react';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { DrawingDialogs } from '../constants/drawing-dialogs';

const PermissionDialogsComponents: React.FC = () => {
  return (
    <>
      <ConfirmationDialog
        name={DrawingDialogs.USER_ANNOTATION_INSTRUCTION_DIALOG}
        title={`The Annotations feature is not available.`}
        text={`To be able to use the feature, add the Annotation Tools add-on to your account first.`}
        cancelButtonText='Okay'
      />
      <ConfirmationDialog
        name={DrawingDialogs.BUY_USER_ANNOTATION_DIALOG}
        title='The Annotations Trial period has expired.'
        text='Contact your company owner to add the Annotations add-on, please'
        cancelButtonText='Okay'
      />
    </>
  );
};

export const PermissionDialogs = React.memo(PermissionDialogsComponents);
