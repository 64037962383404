import { ColorPicker, Icons, RoundButton, SvgComponent } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';

import { AccordionElement } from 'common/components/accordion-element';

interface Props {
  colorList: string[];
  selectedColor: string;
  icon: SvgComponent;
  onColor: (color: string) => void;
  title?: string;
  isBlocksOpen?: boolean;
  openMenu?: () => void;
  deleteColor?: (color: string) => void;
}

export const CollapseColorPicker = React.memo<Props>((props) => {
  const { colorList, selectedColor, title, isBlocksOpen, onColor, openMenu, deleteColor } = props;

  const roundButtonBadge = useMemo(() => {
    if (!openMenu) {
      return null;
    }
    return <RoundButton Icon={Icons.PlusSmall} diameter={20} onClick={openMenu} mood="secondary" />;
  }, [openMenu]);

  return (
    <AccordionElement isOpen={isBlocksOpen} title={title} badge={roundButtonBadge}>
      <ColorPicker colorList={colorList} selectedColor={selectedColor} onColor={onColor} deleteColor={deleteColor} />
    </AccordionElement>
  );
});
