import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  padding: 0 20px 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-right: auto;
  height: 40px;
`;

export const Styled = {
  Container,
  ButtonsContainer,
};
