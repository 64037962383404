import QueryString from 'query-string';
import { CompanyResourcesApi } from '../../../api/server/company-resources-api';
import { CompanyNotificationsCollectionViewModel } from './interfaces';

function getNotifications(beforeId?: number, limit?: number):
  Promise<CompanyNotificationsCollectionViewModel> {
  const query = QueryString.stringify({
    beforeId,
    limit,
  });
  return CompanyResourcesApi.get(`/notifications?${query}`);
}

function updateAccessNotifications(): Promise<void> {
  return CompanyResourcesApi.put(`/notifications/access`, { accessTime: new Date(Date.now()) });
}


export const TwoDNotificationsApi = {
  getNotifications,
  updateAccessNotifications,
};
