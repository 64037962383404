import { MenuItem, MovableContextMenu } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';


import { ExcelFormulaHelper } from 'common/components/excel-table';
import { AbsoluteLinkUtils } from 'common/components/excel-table/utils/absolute-link-utils';
import { ColorsName } from 'common/enums/kreo-colors';
import { TwoDRegexGetter } from '../../../../units/2d/units/2d-regex';
import { INVALID_SHEET_NAME, PuponContextData } from '../formula-toolbar';

interface Props {
  puponContextData: PuponContextData;
  closePuponContext: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClickLinkType: (childIndex: number, newValue: string) => void;
}

export class CellContextMenu extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { puponContextData, closePuponContext } = this.props;

    const { linkList, selectedTypeIndex, sheetId: sheetName } = this.getCellLinkTypeInfo();
    if (puponContextData.cellData.cellId.includes(INVALID_SHEET_NAME)) {
      return null;
    }
    return (
      <MovableContextMenu
        x={puponContextData.x}
        y={puponContextData.y}
        onClose={closePuponContext}
      >
        {
          linkList.filter((_, index) => index !== selectedTypeIndex).map(link => {
            return (
              <MenuItem
                onClick={this.onClickLinkType(link, sheetName)}
                text={link}
                textColor={ColorsName.mainFont}
                size='m'
                fontSize={14}
                key={link}
              />
            );
          })
        }
      </MovableContextMenu>
    );
  }

  @autobind
  private onClickLinkType(link: string, sheetId: string): (e: React.MouseEvent<HTMLDivElement>) => void {
    return (e: React.MouseEvent<HTMLDivElement>) => {
      const newCellLink = sheetId
        ? ExcelFormulaHelper.getCellWithSheetId(sheetId, link)
        : link;
      this.props.onClickLinkType(this.props.puponContextData.puponIndex, newCellLink);
      this.props.closePuponContext(e);
    };
  }

  private getCellLinkTypeInfo(): { linkList: string[], selectedTypeIndex: number, sheetId: string } {
    const cellId = this.props.puponContextData.cellData.cellId;
    const fullCellId = TwoDRegexGetter.getFullCellField(cellId);
    const linkList = AbsoluteLinkUtils.getAllCellLinkTypes(fullCellId.cellId);
    const selectedTypeIndex = linkList.indexOf(fullCellId.cellId);

    return {
      linkList,
      selectedTypeIndex,
      sheetId: fullCellId.sheetId,
    };
  }
}
