import styled from 'styled-components';

import { ICON_ASC, ICON_CHECKED, ICON_DESC, ICON_FILTER, ICON_MENU } from 'common/UIKit/backgrounds-svg';

const Container = styled.div`
  width: 100%;
  height: 100%;
  color: ${p => p.theme.color.mainFont};

  .ag-root-wrapper {
    background: ${p => p.theme.color.background};
  }

  .ag-header {
    background-color: ${p => p.theme.color.backgroundRush};
    border-bottom: 1px solid ${p => p.theme.color.background};

    &-focus {
      background-color: ${p => p.theme.color.pale};
    }

    &-row {
      color: ${p => p.theme.color.turquoiseFont};
      font-size: 14px;
      line-height: 23px;
      font-weight: 400;
    }

    &-group-cell,
    &-cell {
      display: flex;
      height: 23px;
      padding-top: 2px;
      outline: 0;

      &-label {
        display: flex;
        flex: 1 1 auto;
        overflow: hidden;
        align-items: center;
        text-overflow: ellipsis;
        align-self: stretch;
      }
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        display: block;
        width: 1px;
        height: 100%;
        top: 0;
        right: 0;
        background-color: ${p => p.theme.color.background};
      }

      &-text {
        min-width: 30px;
        font-size: 15px;
        text-align: center;
        margin-left: 5px;
        margin-right: auto;
      }
    }
  }

  .ag-pinned-left-header * {
    pointer-events: none
  }

  .ag-tabs {
    &.ag-popup-child {
      padding-top: 0;
      width: 240px;
      transform: translate(25px, 5px);
    }
    &-header {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      border-bottom: 1px solid ${props => props.theme.color.background};
    }
    .ag-input-wrapper {
      margin: 10px 15px;
      .ag-input-field-input {
        border: 0;
        border-radius: 10px;
        outline: 0;
        padding: 12px 15px;
        color: ${props => props.theme.color.mainFont};
        background-color: ${props => props.theme.color.background};

        &::placeholder {
          color: ${props => props.theme.color.disabled};
        }
      }
    }
  }

  .ag-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    outline: 0;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: 10px;
    cursor: pointer;

    .ag-icon {
      margin-right: 0;
      &-filter {
        &::before {
          background-image: ${p => ICON_FILTER(p.theme.color.turquoiseFont)};
        }
        &:hover::before {
          background-image: ${p => ICON_FILTER(p.theme.color.turquoise)};
        }
      }
    }

    &-selected {
      background-color: ${p => p.theme.color.turquoise};

      .ag-icon {
        &.ag-icon-filter::before {
          background-image: ${p => ICON_FILTER(p.theme.color.white)};
        }
        &.ag-icon-menu::before {
          background-image: ${p => ICON_MENU(p.theme.color.white)};
        }
      }
    }
  }

  .ag-set-filter-list {
    height: 120px;
  }

  .ag-icon {
    &::before {
      box-sizing: border-box;
      outline: none;
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      cursor: pointer;
      color: ${props => props.theme.color.mainFont};
    }
    &-menu {
      flex-shrink: 0;
      margin-right: 5px;
      &::before {
        background-position-y: 0;
        background-image: ${p => ICON_MENU(p.theme.color.turquoiseFont)};
      }
      &:hover::before {
        background-image: ${p => ICON_MENU(p.theme.color.turquoise)};
      }
    }
    &-filter {
      margin-right: 5px;
      &::before {
        background-image: ${p => ICON_FILTER(p.theme.color.turquoise)};
      }
    }
    &-asc {
      margin-right: 5px;
      &::before {
        background-image: ${p => ICON_ASC(p.theme.color.turquoise)};
      }
    }
    &-desc {
      margin-right: 5px;
      &::before {
        background-image: ${p => ICON_DESC(p.theme.color.turquoise)};
      }
    }
  }

  .ag-input-field-label.ag-label.ag-checkbox-label {
    padding-left: 5px;
  }

  .ag-checkbox-input-wrapper {
    position: relative;
    height: 20px;
    width: 20px;
    margin: 0 !important;
    margin-left: 15px !important;
    cursor: pointer;

    .ag-checkbox-input {
      &:not(input:checked):hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: ${p => p.theme.color.backgroundRush};
        background-image: ${p => ICON_CHECKED(p.theme.color.disabled)};
        border: 2px solid ${p => p.theme.color.disabled};
        box-sizing: border-box;
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    input::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: ${p => p.theme.color.background};
      border: 2px solid ${p => p.theme.color.disabled};
      box-sizing: border-box;
    }

    &.ag-checked input::before {
      background-image: ${p => ICON_CHECKED(p.theme.color.white)};
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: ${p => p.theme.color.turquoise};
      border: none;
    }
  }

  .ag-set-filter-item-value {
    box-sizing: border-box;
    width: 100%;
    padding: 0 15px;
    font-size: 14px;
    color: ${p => p.theme.color.mainFont};
    cursor: pointer;
  }

  .ag-set-filter-select-all {
    height: 30px;

    &:hover .ag-set-filter-item-value {
      color: ${p => p.theme.color.turquoiseFont};
    }
  }

  .ag-filter-apply-panel {
    margin-top: 10px;
    padding: 10px 15px;
    padding-bottom: 0;
    border-top: 1px solid ${p => p.theme.color.background};
  }

  .ag-filter-no-matches {
    margin-left: 15px;
  }

  .ag-standard-button {
    background-color: transparent;
    border-radius: 30px;
    font-size: 14px;
    line-height: 14px;
    padding: 6px 27px;
    color: ${p => p.theme.color.turquoise};
    border: 2px solid ${p => p.theme.color.turquoise};
    cursor: pointer;
    transition: 0.2s;

    &:active {
      transform: scale(0.95);
    }
  }

  .ag-virtual-list {
    &-item {
      color: ${p => p.theme.color.gray};
      font-size: 16px;
      outline: 0;
      height: 30px;
      cursor: pointer;

      &:hover .ag-set-filter-item-value {
        color: ${p => p.theme.color.turquoiseFont};
      }

      * {
        cursor: pointer;
      }
    }
  }

  .ag-row {
    box-sizing: border-box;
    border-top: none 1px ${p => p.theme.color.background};

    &:not(.ag-row-first) {
      border-top-style: solid;
    }

    &-focus {
      .excel-table__first-column-cel {
        color: ${p => p.theme.color.mainFont};
        background-color: ${p => p.theme.color.pale};
      }
    }
  }

  .ag-cell:has(.selected_comment_hight_light) {
    background: ${p => p.theme.color.green};
    opacity: 0.5;
  }

  .ag-cell {
    box-sizing: border-box;
    padding: 0 5px;
    padding-top: 3px;
    vertical-align: bottom;
    font-size: 14px;
    line-height: 20px;
    color: ${p => p.theme.color.mainFont};
    background-color: ${p => p.theme.color.backgroundRush};
    border-right: 1px solid ${p => p.theme.color.background};
    z-index: -1;
    text-overflow: unset;
    text-anchor: start;
    cursor: pointer;

    &:not(.excel-table__first-column-cel) {
      &:not(.ag-cell-range-selected):hover {
        background-color: ${p => p.theme.color.pale};
      }
    }

    &.excel-table__first-column-cel {
      outline: 0;
      color: ${p => p.theme.color.turquoiseFont};
      font-size: 14px;
      text-align: center !important;
      box-shadow: none !important;
    }

    &-focus:not(.excel-table__first-column-cel) {
      z-index: 1;
      outline: 0;
      box-shadow:
        inset 0 0 0 2px ${p => p.theme.color.turquoise},
        inset 0 -2px 0 0 ${p => p.theme.color.turquoise};

      &::after {
        display: none;
      }
    }

    &-inline-editing {
      padding-left: 0;
      padding-right: 0;
      background-color: ${p => p.theme.color.background} !important;
    }

    .excel-table-cell-edit {
      font-size: inherit;
      line-height: inherit;

      input {
        box-sizing: border-box;
        width: calc(100% - 2px);
        outline: 0;
        border: none;
        padding: 0 2px;
        padding-left: 5px;
        color: ${p => p.theme.color.mainFont};
        font-size: inherit;
        line-height: inherit;
        background-color: transparent;
      }
    }

    &-range {
      &-selected::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${p => p.theme.color.turquoise};
        opacity: 0.2;
      }
      &-right {
        box-shadow: inset -2px 0 0 ${p => p.theme.color.turquoise};
        &.ag-cell-range-left {
          box-shadow: inset -2px 0 0 ${p => p.theme.color.turquoise}, inset 2px 0 0 ${p => p.theme.color.turquoise};
        }
        &:not(.ag-cell-range-selected):last-child {
          box-shadow: none;
        }
      }
      &-left {
        box-shadow: inset 2px 0 0 ${p => p.theme.color.turquoise};
        &.ag-cell-range-right {
          box-shadow: inset 2px 0 0 ${p => p.theme.color.turquoise}, inset -2px 0 0 ${p => p.theme.color.turquoise};
        }
      }
      &-top {
        box-shadow: inset 0 2px 0 ${p => p.theme.color.turquoise};
        &.ag-cell-range-right {
          box-shadow: inset -2px 0 0 ${p => p.theme.color.turquoise}, inset 0 2px 0 ${p => p.theme.color.turquoise};
        }
        &.ag-cell-range-left {
          box-shadow: inset 2px 0 0 ${p => p.theme.color.turquoise}, inset 0 2px 0 ${p => p.theme.color.turquoise};
        }
        &.ag-cell-range-right.ag-cell-range-left {
          box-shadow: inset 2px 2px 0 ${p => p.theme.color.turquoise}, inset -2px 0 0 ${p => p.theme.color.turquoise};
        }
        &.ag-cell-range-right.ag-cell-range-bottom {
          box-shadow: inset 0 2px 0 ${p => p.theme.color.turquoise}, inset -2px -2px 0 ${p => p.theme.color.turquoise};
        }
        &.ag-cell-range-bottom.ag-cell-range-left {
          box-shadow: inset 2px 2px 0 ${p => p.theme.color.turquoise}, inset 0 -2px 0 ${p => p.theme.color.turquoise};
        }
      }
      &-bottom {
        box-shadow: inset 0 -2px 0 ${p => p.theme.color.turquoise};
        &.ag-cell-range-top {
          box-shadow: inset 0 -2px 0 ${p => p.theme.color.turquoise}, inset 0 2px 0 ${p => p.theme.color.turquoise};
        }
        &.ag-cell-range-right {
          box-shadow: inset -2px 0 0 ${p => p.theme.color.turquoise}, inset 0 -2px 0 ${p => p.theme.color.turquoise};
        }
        &.ag-cell-range-left {
          box-shadow: inset 2px 0 0 ${p => p.theme.color.turquoise}, inset 0 -2px 0 ${p => p.theme.color.turquoise};
        }
        &.ag-cell-range-right.ag-cell-range-left {
          box-shadow: inset 2px -2px 0 ${p => p.theme.color.turquoise}, inset -2px 0 0 ${p => p.theme.color.turquoise};
        }
      }
      &-top.ag-cell-range-right.ag-cell-range-bottom.ag-cell-range-left {
        box-shadow: inset 0 0 0 2px ${p => p.theme.color.turquoise}, inset 0 -2px 0 0 ${p => p.theme.color.turquoise};
      }
      &-single-cell{
        box-shadow:
        inset 0 0 0 2px ${p => p.theme.color.turquoise},
        inset 0 -2px 0 0 ${p => p.theme.color.turquoise};
      }
    }

    .ag-fill-handle {
      height: 6px;
      width: 6px;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: ${p => p.theme.color.turquoise};
      cursor: cell;
      z-index: 10;
    }
  }

  .ag-popup-child {
    box-sizing: border-box;
    max-height: calc(100% - 5px);
    min-width: 220px;
    overflow: auto;
    box-shadow: ${p => p.theme.shadow.xl};
    border-radius: 15px;
    padding: 10px 0;
    background: ${p => p.theme.color.backgroundRush};
  }

  .ag-menu {
    &-list {
      outline: 0;
    }

    &-option {
      display: flex;
      align-items: center;
      height: 40px;
      color: ${p => p.theme.color.gray};
      padding: 0 20px;
      font-size: 16px;
      outline: 0;
      cursor: pointer;
      fill: ${p => p.theme.color.turquoise};
      svg {
        height: 20px;
        width: 20px;
        margin-right: 15px;
      }

      &-active {
        color: ${p => p.theme.color.turquoise};
      }
    }
  }

  .ag-menu-list.ag-focus-managed {
    .ag-menu-option {
      font-size: 14px;
      fill: ${p => p.theme.color.gray};
      color: ${p => p.theme.color.mainFont};
      height: 30px;
      padding: 0 15px;

      :hover {
        color: ${p => p.theme.color.turquoise};
        fill: ${p => p.theme.color.turquoise};
      }

      .ag-menu-option-part.ag-menu-option-icon {
        height: 20px;
      }
    }
  }

  & ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  & ::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }

  .ag-ltr,
  .ag-rtl {
    .ag-selection-fill-top {
      border-top: 2px dashed ${p => p.theme.color.turquoise};
      &.ag-cell.ag-cell-range-selected {
        border-top: 2px dashed darken(${p => p.theme.color.turquoise}, 20%);
      }
    }

    .ag-selection-fill-right {
      border-right: 2px dashed ${p => p.theme.color.turquoise};
      &.ag-cell.ag-cell-range-selected {
        border-right: 2px dashed darken(${p => p.theme.color.turquoise}, 20%);
      }
    }

    .ag-selection-fill-bottom {
      border-bottom: 2px dashed ${p => p.theme.color.turquoise};
      &.ag-cell.ag-cell-range-selected {
        border-bottom: 2px dashed darken(${p => p.theme.color.turquoise}, 20%);
      }
    }

    .ag-selection-fill-left {
      border-left: 2px dashed ${p => p.theme.color.turquoise};
      &.ag-cell.ag-cell-range-selected {
        border-left: 2px dashed darken(${p => p.theme.color.turquoise}, 20%);
      }
    }
  }

  .CommentHightlight {
    background: ${p => p.theme.color.turquoise};

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      right: inherit;
      background: transparent;
      z-index: 1;
    }

    &:hover {
      width: 10px !important;
      height: 10px !important;
      transition: all 0.35s cubic-bezier(0.72, 0.04, 0.78, 1.62);
    }
  }
`;

const CellCoardinates = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 85px;
  height: 36px;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0 25px 0 8px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${p => p.theme.color.mainFont};
  box-shadow: ${p => p.theme.shadow.s};
  background-color: ${p => p.theme.color.backgroundRush};
`;

export const Styled = {
  Container,
  CellCoardinates,
};
