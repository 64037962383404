import { ExcelFormulaHelper } from 'common/components/excel-table';
import { flatten } from './flatten';

export function MAX(...numbers: number[] | number[][]): number | string {
  if (numbers.length === 0) {
    return ExcelFormulaHelper.INVALID_VALUE;
  }

  const arr = flatten(numbers);

  let max = Number.NEGATIVE_INFINITY;

  arr.forEach((arg) => {
    if (Array.isArray(arg)) {
      arg.forEach((innerArg) => {
        if (typeof innerArg !== 'number') {
          return;
        }

        if (innerArg > max) {
          max = innerArg;
        }
      });
    } else {
      if (typeof arg !== 'number') {
        return;
      }

      if (arg > max) {
        max = arg;
      }
    }
  });

  if (max === Number.NEGATIVE_INFINITY) {
    return 0;
  }

  return max;
}
