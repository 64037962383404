import React from 'react';

import { useNewInstancesSetting } from 'common/components/drawings/hooks';
import { Property } from 'common/components/property';
import { UnitTypes } from 'common/utils/unit-util';
import { MeasurementInput } from '../../measurement-input';
import { GeometryProperty } from './geometry-property';

export const PolylineParamsComponent: React.FC = () => {
  const [ offset, setOffset ] = useNewInstancesSetting('offset');
  return (
    <>
      <GeometryProperty
        title='Height'
        localStorageKey='drawingNewPolylineHeight'
      />
      <GeometryProperty
        title='Thickness'
        localStorageKey='drawingNewPolylineThickness'
      />
      <Property title={'Offset'}>
        <MeasurementInput
          value={offset}
          onChange={setOffset}
          unit={UnitTypes.M}
        />
      </Property>
    </>
  );
};

export const PolylineParams = React.memo(PolylineParamsComponent);
