import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { QuantityTakeOffReportActionTypes } from '../actions/types/quantity-take-off-report';
import { QuantityTakeOffTemplateActionTypes } from '../actions/types/quantity-take-off-template';
import { QtoGenerateReportStatus } from '../enums/qto-generate-report-status';
import { QtoTemplateInfo, QuantityTakeOffState } from '../interfaces/quantity-take-off';

export const quantityTakeOffTemplateReducerMethods: ReducerMethods<QuantityTakeOffState> = {
  [QuantityTakeOffTemplateActionTypes.CREATE_TEMPLATE]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.template.templateCreationStatus, QtoGenerateReportStatus.Generating)
      .get();
  },
  [QuantityTakeOffReportActionTypes.CREATE_TEMPLATE_FROM_REPORT]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.template.templateCreationStatus, QtoGenerateReportStatus.Generating)
      .get();
  },
  [QuantityTakeOffTemplateActionTypes.SET_SELECTED_TEMPLATE_ID]: (s, id: number) => {
    return new MonoliteHelper(s)
      .set(_ => _.template.selectedTemplateId, id)
      .get();
  },
  [QuantityTakeOffTemplateActionTypes.GET_TEMPLATES_INFO]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.template.statuses.loadTemplatesInfo, RequestStatus.Loading)
      .get();
  },
  [QuantityTakeOffTemplateActionTypes.SET_TEMPLATES_INFO]: (s, templates) => {
    return new MonoliteHelper(s)
      .set(_ => _.template.templatesInfo, templates)
      .set(_ => _.template.statuses.loadTemplatesInfo, RequestStatus.Loaded)
      .get();
  },
  [QuantityTakeOffTemplateActionTypes.DELETE_TEMPLATE]: (s, id: number) => {
    return new MonoliteHelper(s)
      .set(_ => _.template.templateModel, null)
      .set(_ => _.template.selectedTemplateId, null)
      .set(_ => _.template.statuses.loadTemplate, RequestStatus.NotRequested)
      .setFilter(_ => _.template.templatesInfo, (x) => x.id !== id)
      .get();
  },
  [QuantityTakeOffTemplateActionTypes.LOAD_TEMPLATE]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.template.statuses.loadTemplate, RequestStatus.Loading)
      .set(_ => _.template.templateCreationStatus, QtoGenerateReportStatus.NoActions)
      .get();
  },
  [QuantityTakeOffTemplateActionTypes.SET_TEMPLATE_MODEL]: (s, model) => {
    return new MonoliteHelper(s)
      .set(_ => _.template.statuses.loadTemplate, RequestStatus.Loaded)
      .set(_ => _.template.templateModel, model)
      .get();
  },
  [QuantityTakeOffTemplateActionTypes.UPDATE_TEMPLATES_INFO_AFTER_CREATE_VIA_REPORT]: (s, model: QtoTemplateInfo) => {
    return new MonoliteHelper(s)
      .set(_ => _.template.selectedTemplateId, model.id)
      .set(_ => _.template.templatesInfo, [...s.template.templatesInfo, model])
      .get();
  },
};
