import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Cell, Legend, Margin, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { AnyAction, Dispatch } from 'redux';

import './classification-chart.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { KreoButton, KreoIconAttention, KreoIconNoCalculation } from 'common/UIKit';
import { StringUtils } from 'common/utils/string-utils';
import { ValidationStep } from '../../units/projects/enums/validation-step';
import { ChartData, ChartDataValue } from './interfaces/chart-data';
import { ObjectStatisticType } from './interfaces/object-statistic-type';

interface LegendItemProps {
  color: string;
  name: string;
}

const LegendItem: React.FC<LegendItemProps> = props => {
  return (
    <div>
      <i style={{ backgroundColor: props.color }} />
      <span>{props.name}</span>
    </div>
  );
};

interface LegendRendererProps {
  data: ObjectStatisticType[];
}

const LegendRenderer: React.FC<LegendRendererProps> = props => {
  return (
    <div className='classification-chart-legend'>
      {props.data.map((value, index) => (
        <LegendItem key={index} color={value.color} name={value.name} />
      ))}
    </div>
  );
};

interface ChartActiveTitleProps {
  value?: number;
  name: string;
}

const ChartActiveTitle: React.FC<ChartActiveTitleProps> = props => {
  return (
    <div className='classification-chart-active'>
      <b className='classification-chart-active__title'>{props.value}</b>
      <div className='classification-chart-active__description'>{props.name}</div>
    </div>
  );
};

interface DispatchProps {
  onEdit: () => void;
  onView: () => void;
}

interface OwnProps {
  data: ObjectStatisticType[];
  noDataMessage?: string;
  isLoading?: boolean;
  fixLink: string;
  viewLink?: string;
  attentionStatus: boolean;
  isFixable: boolean;
  chartName: string;
  onSelectIds: (ids: number[]) => void;
  onDeselect: () => void;
}

interface Props extends DispatchProps, OwnProps {}

interface OwnState {
  key: string;
  keys: number[];
  chartData: ChartData[];
  totalValue: number;
  value: number;
}

export class ClassificationChartComponent extends React.Component<Props, OwnState> {
  private readonly wrapperStyle: React.CSSProperties = { top: 0, left: 0, height: 0 };
  private readonly chartMargin: Margin = {
    top: 10,
    right: 0,
    left: 190,
    bottom: 0,
  };
  private readonly baseRadius: number = 60;
  private colors: string[] = [];
  private classificationStep: boolean = this.props.chartName === ValidationStep.Classification;

  constructor(props: Props) {
    super(props);

    this.state = {
      keys: null,
      key: null,
      chartData: [],
      totalValue: 0,
      value: null,
    };
  }

  public componentDidMount(): void {
    this.draw(this.props.data);
  }

  public UNSAFE_componentWillUpdate(nextProps: Props): void {
    if (JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)) {
      this.draw(nextProps.data);
    }
  }

  public render(): React.ReactNode {
    const chartData = this.state.chartData;

    return (
      <div className='classification-chart'>
        {!this.props.isLoading && chartData && chartData.length !== 0 ? (
          <React.Fragment>
            <ResponsiveContainer>
              <PieChart margin={this.chartMargin} onMouseLeave={this.onMouseOut}>
                {chartData.map(data => {
                  return (
                    <Pie
                      key={data.depth}
                      data={data.values}
                      startAngle={90}
                      endAngle={450}
                      dataKey='value'
                      innerRadius={this.getPieLevelInnerRadius(data.depth)}
                      outerRadius={this.getPieLevelOuterRadius(data.depth)}
                      fill='#8884d8'
                      onMouseEnter={this.onMouseOver}
                      onMouseLeave={this.onMouseOut}
                    >
                      {data.values.map((y, index) => <Cell key={index} fill={this.getColor(y)} />)}
                    </Pie>
                  );
                })}
                <Legend content={this.legendRenderer} wrapperStyle={this.wrapperStyle} />
              </PieChart>
            </ResponsiveContainer>
            {this.state.key == null ? (
              <ChartActiveTitle
                name={this.classificationStep ? 'Element types' : 'Elements'}
                value={this.state.totalValue}
              />
            ) : (
              <ChartActiveTitle
                name={this.state.key}
                value={this.state.value}
              />
            )}
            <KreoButton
              disabled={!this.props.isFixable && !this.props.viewLink}
              onClick={this.onClick}
              mode='toaction'
              size='medium'
              controlName={`${StringUtils.titleCaseToSnakeCase(this.props.chartName)}-view-button`}
            >
              {this.props.isFixable ? 'Edit' : 'View'}
            </KreoButton>
            {this.props.attentionStatus ? (
              <div className='classification-chart-icon'>
                <KreoIconAttention />
              </div>
            ) : null}
          </React.Fragment>
        ) : (
          <div className='classification-chart__wrap'>
            {this.props.isLoading ? (
              <SvgSpinner size='middle' />
            ) : (
              <div className='classification-chart__content'>
                <KreoIconNoCalculation />
                <span>{this.props.noDataMessage}</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  @autobind
  private onClick(): void {
    // if(this.props.viewLink) временное есловие пока не будут готовы
    // readonly режимы для страниц ActivityAssignment и Measurements
    if (this.props.isFixable) {
      this.props.onEdit();
    } else if (this.props.viewLink) {
      this.props.onView();
    }
  }

  private getColor(data: ChartDataValue): string {
    let color = '#E8E9EB';
    const stringKey = this.state.keys ? this.state.keys.join('.') : '';
    const p = data.originalPath.join('.');
    if (this.state.key && (p === stringKey || stringKey.startsWith(`${p}.`))) {
      color = this.colors[data.originalPath[0]];
    }
    color = data.depth === 0 ? this.colors[data.originalPath[0]] : color;

    return color;
  }

  @autobind
  private onMouseOver(item: { payload: ChartDataValue }): void {
    const selected = this.getSelected(item.payload.originalPath);
    const ids = this.rejectIds(selected);
    this.props.onSelectIds(ids);
    this.setState({
      key: item.payload.name,
      keys: item.payload.originalPath,
      value: item.payload.value,
    });
  }

  private rejectIds(node: ObjectStatisticType): number[] {
    let ids = new Array<number>();
    if (!node.children && !!node.bimHandleIds) {
      return node.bimHandleIds;
    } else if (node.children) {
      node.children.forEach(value => {
        ids = ids.concat(this.rejectIds(value));
      });
    }
    return ids;
  }

  private getSelected(path: number[]): ObjectStatisticType {
    let items = [];
    let item: ObjectStatisticType = null;
    path.forEach((value, index) => {
      if (index === 0) {
        item = this.props.data[value];
      } else {
        item = items[value];
      }
      if (item.children) {
        items = item.children;
      }
    });
    return item;
  }

  @autobind
  private onMouseOut(): void {
    this.props.onDeselect();
    this.setState({
      keys: null,
      key: null,
      value: null,
    });
  }

  @autobind
  private legendRenderer(): React.ReactNode {
    return <LegendRenderer data={this.props.data} />;
  }

  private draw(data: ObjectStatisticType[]): void {
    this.colors = data.map(x => x.color);
    const chartData = this.getChartData(data);
    let totalValue = 0;
    if (chartData && chartData.length > 0) {
      chartData[0].values.map(x => (totalValue += x.value));
    }
    this.setState({
      chartData,
      totalValue,
      keys: null,
      key: null,
    });
  }

  private calcValue(node: ObjectStatisticType): number {
    if (node.children) {
      return node.children.length;
    }

    let sum = 0;
    if (node.children) {
      node.children.forEach(x => {
        sum += this.calcValue(x);
      });
    }

    return sum;
  }

  private getChartData(data: ObjectStatisticType[]): ChartData[] {
    const chartData = new Array<ChartData>();
    this.makeChartData(data).forEach(value => {
      if (!(value.depth in chartData)) {
        chartData[value.depth] = { depth: value.depth, values: [] };
      }
      chartData[value.depth].values.push(value);
    });
    return chartData;
  }

  private makeChartData(
    data: ObjectStatisticType[],
    parentPath: number[] = [],
    depth: number = 0,
  ): ChartDataValue[] {

    let chartData = new Array<ChartDataValue>();
    data.forEach((value, index) => {
      const path = [...parentPath, index];
      chartData.push({
        value: value.amount,
        name: value.name.replace(/_/gm, ' '),
        depth,
        originalPath: path,
      });
      if (value.children) {
        chartData = chartData.concat(this.makeChartData(value.children, path, depth + 1));
      }
    });
    return chartData;
  }

  private getPieLevelOuterRadius(level: number): number {
    let radius = this.baseRadius + this.getPieLevelWidth(level);
    while (level > 0) {
      level--;
      radius += this.getPieLevelWidth(level);
    }

    return radius;
  }

  private getPieLevelInnerRadius(level: number): number {
    let radius = this.baseRadius;
    while (level > 0) {
      level--;
      radius += this.getPieLevelWidth(level);
    }

    return radius;
  }

  private getPieLevelWidth(level: number): number {
    switch (level) {
      case 0:
        return 10;
      default:
        return 10;
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  return {
    onEdit: () => {
      dispatch(push(props.fixLink));
    },
    onView: () => {
      dispatch(push(props.viewLink));
    },
  };
};

const connector = connect(null, mapDispatchToProps);
export const ClassificationChart = connector(ClassificationChartComponent);
