import { fromJS } from 'immutable';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { KreoDialogActions } from 'common/UIKit';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { REPORT_SENT_DIALOG_NAME } from '../../../components/dialog/reports-sent/reports-sent-popup';
import * as CostUtils from '../../../utils/costs';
import { Calculation } from '../../scenarios/interfaces/scenarios-data';
import { CostsActions } from '../actions/creators/costs';
import { CostsActionTypes } from '../actions/types/costs';
import { CalculationApi } from '../api/calculation';
import { CostsApi } from '../api/costs';
import { CostsTreeNode } from '../interfaces/bid-pricing/costs-tree-node';
import {
  CostsTreeNodeVm,
} from '../interfaces/bid-pricing/costs-tree-node-vm';


function* getCostEstimateRoots(action: any): SagaIterator {
  try {
    const data = yield call(CostsApi.getCostEstimateRoots, action.payload.id, action.payload.type);
    const processed = CostUtils.processCost(data);
    yield put(CostsActions.getCostEstimateRootsSucceeded(processed));
  } catch (error) {
    console.error('costs: get cost estimate roots failed', error, action.payload);
  }
}

function* getIndirectCost(action: ActionWith<number>): SagaIterator {
  try {
    const calculationId = action.payload;
    const data = yield call(CalculationApi.getIndirectData, calculationId);
    yield put(CostsActions.saveIndirectData(data));
  } catch (error) {
    console.error('costs: get indirect cost failed', error, action.payload);
  }
}

function* collapseExpand(action: ActionWith<CostsTreeNode>): SagaIterator {
  try {
    const item = action.payload;
    if (item.expanded) {
      yield put(CostsActions.collapse(item.identificator.split('.')));
    } else {
      if (!item.childrenProcessed) {
        if (item.childrens === undefined || item.childrens.length === 0) {
          const data: CostsTreeNodeVm =
            yield call(CostsApi.getPackageInfo, item.id);
          if (!data) {
            console.error(`Cost Estimate: children data for line ${item.id} is undefined`);
          } else {
            const processed = CostUtils.processCost(data.childrens, item.identificator);
            yield put(CostsActions.addChilds(item.identificator.split('.'), processed));
          }
        } else {
          const data = CostUtils.processCost(item.childrens, item.identificator);
          yield put(CostsActions.addChilds(item.identificator.split('.'), data));
        }
      }
      yield put(CostsActions.expand(item.identificator.split('.')));
    }
  } catch (error) {
    console.error('costs: collapse expand failed', error, action.payload);
  }
}

function* changeField(action: any): SagaIterator {
  try {
    const path = action.payload.path;
    const value = action.payload.value;
    const costs = yield selectWrapper(state => state.cost.get('costs'));
    const paths = [];
    CostUtils.recalc(costs, path, value, pair => {
      paths.push(fromJS(pair));
    });
    yield put(CostsActions.changeFieldCommit(paths.reverse()));
  } catch (error) {
    console.error('costs: change field failed', error, action.payload);
  }
}

function* getExcelReportTypes(): SagaIterator {
  try {
    const calculation: Calculation = yield selectWrapper(state => state.scenarios.active_calculation);
    const calculationId = calculation ? calculation.id : null;
    const data = yield call(CostsApi.getExcelReportTypes, calculationId);
    yield put(CostsActions.loadExcelReportTypesSuccessed(data));
  } catch (error) {
    console.error('costs: get excel report types failed', error);
  }
}

function* exportExcelReport(action: ActionWith<string[]>): SagaIterator {
  try {
    const calculation: Calculation = yield selectWrapper(state => state.scenarios.active_calculation);
    const calculationId = calculation ? calculation.id : null;
    yield call(CostsApi.exportExcelReport, calculationId, action.payload);
    yield put(KreoDialogActions.openDialog(REPORT_SENT_DIALOG_NAME));
  } catch (error) {
    console.error('costs: export excel report failed', error, action.payload);
  }
}

export function* costsSagas(): SagaIterator {
  yield takeLatest(CostsActionTypes.COST_GET_EXCEL_REPORT_TYPES_REQUEST, getExcelReportTypes);
  yield takeLatest(CostsActionTypes.COST_EXPORT_EXCEL_REPORT, exportExcelReport);
  yield takeLatest(CostsActionTypes.COST_GET_INDIRECT_DATA, getIndirectCost);
  yield takeLatest(CostsActionTypes.COST_GET_COST_ESTIMATE_ROOTS_REQUEST, getCostEstimateRoots);
  yield takeLatest(CostsActionTypes.COST_COLLAPSE_EXPAND, collapseExpand);
  yield takeLatest(CostsActionTypes.COST_CHANGE_FIELD, changeField);
}
