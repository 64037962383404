import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import { MultiViewersStates } from 'common/utils/multi-view/multi-view-states';
import { rootReducer as reducers } from '../reducers';
import saga from '../sagas';

const sagaMiddleware = createSagaMiddleware();
const history = createHistory();
const multiViewMiddleware = MultiViewersStates.createReduxMiddleware();
const middleware = [routerMiddleware(history), sagaMiddleware, multiViewMiddleware];
const routerReducer = connectRouter(history);


if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const rootReducer = combineReducers({
  ...reducers,
  router: routerReducer,
  form: formReducer,
});

const persistConfig = {
  key: 'kreo_state',
  storage,
  whitelist: ['persistedStorage'],
  stateReconciler: autoMergeLevel1,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

const persistor = persistStore(store);

sagaMiddleware.run(saga);

export { history, store, persistor };
