import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { SvgSpinner } from 'common/components/svg-spinner';
import { State } from 'common/interfaces/state';
import { KreoInput } from 'common/UIKit';
import './activity-assignment-revit-tree-layout.scss';
import { CEActivityAssignmentActions } from 'unit-cost-estimate/actions/creators';
import { ValidationHidableLayout } from 'unit-projects/components/validation-hidable-layout';
import { ActivityAssignmentRevitTreeBody } from './activity-assignment-revit-tree-body';

interface StateProps {
  showTree: boolean;
  loaded: boolean;
}

interface DispatchProps {
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onShowHide: () => void;
}


interface Props extends StateProps, DispatchProps { }

const ActivityAssignmentRevitTreeLayoutComponent: React.FC<Props> = (props) => {
  if (!props.loaded) {
    return (
      <ValidationHidableLayout
        toggleShowStatus={props.onShowHide}
        isShow={props.showTree}
      >
        <SvgSpinner size='middle'/>
      </ValidationHidableLayout>
    );
  }

  return (
    <ValidationHidableLayout
      toggleShowStatus={props.onShowHide}
      isShow={props.showTree}
    >
      <div className='activity-assignment-revit-tree__search'>
        <KreoInput placeholder='Search' type='text' onChange={props.onSearch} />
      </div>
      <div className='activity-assignment-revit-tree__body'>
        <ActivityAssignmentRevitTreeBody/>
      </div>
    </ValidationHidableLayout>
  );
};

function mapStateToProps({ ceActivityAssignment }: State): StateProps {
  return {
    showTree: ceActivityAssignment.viewTree,
    loaded: ceActivityAssignment.dataLoaded,
  };
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onSearch: (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(CEActivityAssignmentActions.runSearch(e.target.value));
    },
    onShowHide: () => dispatch(CEActivityAssignmentActions.showHideTree()),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityAssignmentRevitTreeLayout = connector(ActivityAssignmentRevitTreeLayoutComponent);
