import * as React from 'react';

export const KreoIconFullscreenNormal: React.FC = () => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/20x20/Fullscreen_normal'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          // eslint-disable-next-line max-len
          d='M13.5857864,5 L12,5 C11.4477153,5 11,4.55228475 11,4 C11,3.44771525 11.4477153,3 12,3 L16,3 C16.5522847,3 17,3.44771525 17,4 L17,8 C17,8.55228475 16.5522847,9 16,9 C15.4477153,9 15,8.55228475 15,8 L15,6.41421356 L12.2071068,9.20710678 C11.8165825,9.59763107 11.1834175,9.59763107 10.7928932,9.20710678 C10.4023689,8.81658249 10.4023689,8.18341751 10.7928932,7.79289322 L13.5857864,5 Z M6.41421356,15 L8,15 C8.55228475,15 9,15.4477153 9,16 C9,16.5522847 8.55228475,17 8,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,12 C3,11.4477153 3.44771525,11 4,11 C4.55228475,11 5,11.4477153 5,12 L5,13.5857864 L7.79289322,10.7928932 C8.18341751,10.4023689 8.81658249,10.4023689 9.20710678,10.7928932 C9.59763107,11.1834175 9.59763107,11.8165825 9.20710678,12.2071068 L6.41421356,15 Z'
          id='Combined-Shape'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
