import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconUser: React.FC = (_props) => {
  return (
    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='icon_account' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <rect id='14x14' x='0' y='0' width='14' height='14'/>
            <path
              d='M5.01609137,7.25040562 C4.39300945,6.70068734 4,5.89623981 4,5 C4,3.34314575 5.34314575,2 7,2
              C8.65685425,2 10,3.34314575 10,5 C10,5.89623981 9.60699055,6.70068734 8.98390863,7.25040562
              C10.7400976,7.86359395 12,9.53466399 12,11.5 C12,11.7761424 11.7761424,12 11.5,12
              C11.2238576,12 11,11.7761424 11,11.5 C11,9.56700338 9.43299662,8 7.5,8 L6.5,8 C4.56700338,8
              3,9.56700338 3,11.5 C3,11.7761424 2.77614237,12 2.5,12 C2.22385763,12 2,11.7761424 2,11.5 C2,9.53466399
              3.25990237,7.86359395 5.01609137,7.25040562 Z M7,7 C8.1045695,7 9,6.1045695 9,5 C9,3.8954305 8.1045695,3
              7,3 C5.8954305,3 5,3.8954305 5,5 C5,6.1045695 5.8954305,7 7,7 Z'
              id='Combined-Shape'
              fill={KreoColors.f2}
              fillRule='nonzero'
            />
        </g>
    </svg>
  );
};
