import * as Ag from 'ag-grid-community';
import { useEffect, useMemo, useRef } from 'react';

import { TransactionGenerator } from '2d/components/2d-element-view/utils';
import { useCompanyUserSelector } from 'common/hooks';
import { User } from 'unit-2d-database/interfaces';
import { getRows } from './get-rows';

type UseTransactionEffect = (files: any[], gridApi: Ag.GridApi) => void;

export const useTransactionEffect: UseTransactionEffect = (files, gridApi) => {
  const users = useCompanyUserSelector() as User[];
  const transactionGeneratorRef = useRef(new TransactionGenerator());
  const rowDataMap = useMemo(() => getRows(files, users), [files, users]);
  useEffect(() => {
    if (!gridApi) {
      return;
    }

    const transaction = transactionGeneratorRef.current.getTransaction(rowDataMap);
    if (transaction) {
      gridApi.applyTransaction(transaction);
      gridApi.refreshCells({ force: true });
    }
  }, [rowDataMap, gridApi]);
};
