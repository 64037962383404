import { NumberDictionary, StringDictionary } from 'common/interfaces/dictionary';
import { arrayUtils } from 'common/utils/array-utils';
import { numberUtils } from 'common/utils/number-utils';
import { UnitUtil } from 'common/utils/unit-util';
import { MeasurementsLoadDataSuccessPayload } from '../actions/payloads/measurements';
import { MeasurementsConstants } from '../constants/measurements';
import { MeasurementsBimElementType } from '../enums/measurements-bim-element-type';
import { MeasurementsExtractorEditorRowType } from '../enums/measurements-extractor-editor-row-type';
import { MeasurementsValueKey } from '../enums/measurements-value-key';
import { RevitTreeLevel } from '../enums/revit-tree-level';
import {
  MeasurementActivityGroupModel,
  MeasurementActivityModel,
} from '../interfaces/measurements/measurement-activity';
import { MeasurementEntityData } from '../interfaces/measurements/measurement-entity-data';
import { MeasurementExtractorFunction } from '../interfaces/measurements/measurement-extractor-function';
import {
  MeasurementExtractorFunctionTemplate,
} from '../interfaces/measurements/measurement-extractor-function-template';
import { MeasurementGeneralExtractorEditor } from '../interfaces/measurements/measurement-general-extractor-editor';
import { MeasurementRevitTreeNodeData } from '../interfaces/measurements/measurement-revit-tree-node-data';
import { MeasurementUpdateForm } from '../interfaces/measurements/measurement-update-form';
import { MeasurementValue, MeasurementValueGroup } from '../interfaces/measurements/measurement-value';
import { MeasurementsActivityExtractor } from '../interfaces/measurements/measurements-activity-extractor';
import { MeasurementsBimElementInfo } from '../interfaces/measurements/measurements-bim-element-info';
import {
  MeasurementExtractorElement,
  MeasurementsExtractorEditorVisualRow,
} from '../interfaces/measurements/measurements-extractor-element';
import { MeasurementsResponseTreeNode } from '../interfaces/measurements/measurements-response-tree-node';
import { MeasurementsState } from '../interfaces/measurements/measurements-state';
import { RevitGroupNode } from '../interfaces/revit-group-node';


const measurementsRelationsRules: StringDictionary<MeasurementsValueKey> = {
  [MeasurementsValueKey.ReinforcementMass]: MeasurementsValueKey.ReinforcementConcentration,
};

function extractRevitTreeNodeIdsForRender(list: Array<RevitGroupNode<MeasurementRevitTreeNodeData>>): number[] {
  const length = list.length;
  const result = new Array<number>();
  for (let i = 0; i < length; i++) {
    const item = list[i];
    result.push(i);
    if (item.isExpandable && !item.isExpanded) {
      i = item.lastChildrenIndex;
    }
  }
  return result;
}


function createGeneralExtractorEditors(
  templatesMap: Record<string, MeasurementExtractorFunctionTemplate>,
  measurementEntities: MeasurementActivityGroupModel[],
): Record<string, MeasurementGeneralExtractorEditor> {
  const result: Record<string, MeasurementGeneralExtractorEditor> = {};

  for (const template of Object.values(templatesMap)) {
    for (const extractor of Object.values(template.extractors)) {
      result[extractor.extractorFunctionId] = {
        selected: false,
        totalValue: 0,
        value: null,
      };
    }
  }

  for (const measure of measurementEntities) {
    for (const [extractorId, value] of Object.entries(measure.measurements)) {
      result[extractorId].totalValue += numberUtils.getNumeralFormatter(value.commonValue).value();
    }
  }

  return result;
}


function getSelectedIds({ selectedNodeId, modelBrowser: list, engineIds }: MeasurementsState): number[] {
  if (selectedNodeId !== null && selectedNodeId !== undefined) {
    const { bimIds } = list[selectedNodeId];
    return engineIds.slice(bimIds.start, bimIds.end).map(({ id }) => id);
  } else {
    return [];
  }
}

function sortSourceTree(items: MeasurementsResponseTreeNode[], level: RevitTreeLevel): MeasurementsResponseTreeNode[] {
  if (level === RevitTreeLevel.Element) {
    return items;
  }
  for (const value of items) {
    if (value.children && value.children.length > 0) {
      value.children = sortSourceTree(value.children, level + 1);
    }
  }
  return arrayUtils.sortByField(items, 0, items.length - 1, 'name');
}

function ejectNeededData({ rootId, name }: MeasurementsResponseTreeNode): MeasurementRevitTreeNodeData {
  return {
    rootId,
    name,
    layerIds: null,
    functions: null,
  };
}

function makeMeasurmentRevitNode(
  node: MeasurementsResponseTreeNode,
  level: RevitTreeLevel,
  parentId: number = null,
): RevitGroupNode<MeasurementRevitTreeNodeData> {
  return {
    level,
    data: ejectNeededData(node),
    bimIds: null,
    lastChildrenIndex: null,
    isExpandable: node.children && node.children.length > 0,
    isExpanded: false,
    isSelected: false,
    isError: false,
    parentId,
  };
}


function makeBimElementData(
  id: number,
  elementType: MeasurementsBimElementType,
  category: number,
  family: number,
  type: number,
  measurement: number,
): MeasurementsBimElementInfo {
  return {
    id,
    elementType,
    category,
    family,
    type,
    measurement,
    properties: null,
    typeProperties: null,
  };
}


function isMeasurementBad(measurement: MeasurementValue): boolean {
  return measurement && measurement.value <= 0;
}

function getMeasurementGroupErrorCount(group: MeasurementValueGroup): number {
  let count = 0;
  for (const value of Object.values(group.valuesMap)) {
    if (isMeasurementBad(value)) {
      count++;
    }
  }

  return count;
}

function getErrorMeasurementCount(measurements: Record<string, MeasurementValueGroup>): number {
  let count = 0;
  for (const values of Object.values(measurements)) {
    count += getMeasurementGroupErrorCount(values);
  }

  return count;
}

function aggregateMeasurements(
  measurements: MeasurementValue[],
  extractorFunctions: MeasurementExtractorFunction[],
): Record<string, MeasurementValue> {
  const aggregatedMeasurements: Record<string, MeasurementValue> = {};
  for (let i = 0; i < measurements.length; i++) {
    const measurementValue = measurements[i];
    if (measurementValue) {
      const extractorFunction = extractorFunctions[i];
      const unitName = extractorFunction.unitName;
      aggregatedMeasurements[extractorFunction.extractorFunctionId] = {
        value: UnitUtil.round(numberUtils.getNumeralFormatter(measurementValue.value).value(), unitName),
        variantIds: measurementValue.variantIds,
      };
    }
  }
  return aggregatedMeasurements;
}


interface ActivityExtractorData {
  activityExtractor: MeasurementsActivityExtractor;
  errorEngineIds: number[];
}

function extractActivityExtractorData(
  { name, description, unisystem, id, data }: MeasurementsResponseTreeNode,
  measurementId: number,
): ActivityExtractorData {
  const { engineIds, activities, geometryLessIds, extractorFunctions } = data;
  let aggregatedActivities = new Array<MeasurementActivityModel>(activities.length);
  const engineIdSourcePosition: NumberDictionary<number> = {};
  const activityExtractor: MeasurementsActivityExtractor = {
    name,
    description,
    id,
    unisystem,
    data: null,
    leafNodeId: measurementId,
  };

  const errorEngineIds = new Array<number>();
  for (let i = 0; i < activities.length; i++) {
    const { level, name: activityName, activityId, measurements } = activities[i];
    const engineId = engineIds[i];
    const aggregatedMeasurements = aggregateMeasurements(measurements, extractorFunctions);
    if (Object.values(aggregatedMeasurements).some(isMeasurementBad)) {
      errorEngineIds.push(engineId);
    }
    engineIdSourcePosition[engineId] = i;
    aggregatedActivities[i] = {
      level,
      name: activityName,
      activityId,
      measurements: aggregatedMeasurements,
      engineId,
      geometryLessId: geometryLessIds[i],
      leafNodeId: measurementId,
    };
  }
  aggregatedActivities = arrayUtils.sortByField(aggregatedActivities, 0, aggregatedActivities.length - 1, 'level');

  const relationParameters: Record<number, Record<string, number>> = {};
  for (let i = 0; i < extractorFunctions.length; i++) {
    const currentKey = extractorFunctions[i].extractorFunctionKey;
    if (currentKey in measurementsRelationsRules) {
      const keys = measurementsRelationsRules[currentKey];
      for (let j = 0; j < extractorFunctions.length; j++) {
        if (
          keys === extractorFunctions[j].extractorFunctionKey &&
          currentKey === MeasurementsValueKey.ReinforcementMass
        ) {
          for (let k = 0; k < activities.length; k++) {
            const engineId = engineIds[k];
            const measurements = activities[k].measurements;
            const areAllNeededValuesExist = measurements[i] && measurements[j];
            const volume = areAllNeededValuesExist
              ? Number(measurements[i].value) / Number(measurements[j].value)
              : null;

            if (!volume) {
              continue;
            }
            if (relationParameters[engineId]) {
              relationParameters[engineId][MeasurementsConstants.reinforcementVolumeKey] = volume;
            } else {
              relationParameters[engineId] = { [MeasurementsConstants.reinforcementVolumeKey]: volume };
            }
          }
        }
      }
    }
  }

  activityExtractor.data = {
    relationParameters,
    extractorFunctions,
    activities: aggregatedActivities,

  };
  return {
    activityExtractor,
    errorEngineIds,
  };
}

function extractData(
  serverData: MeasurementsResponseTreeNode[],
): MeasurementsLoadDataSuccessPayload {
  const rootIds = new Array<number>();
  const nodes = new Array<RevitGroupNode<MeasurementRevitTreeNodeData>>();
  const engineIds = new Array<MeasurementsBimElementInfo>();
  const layerIds = new Array<MeasurementsBimElementInfo>();
  const errorEngineIds = new Array<number>();
  const nodeCountOfErrors: NumberDictionary<number> = {};
  const extractors: NumberDictionary<MeasurementsActivityExtractor> = {};

  const sortedData = sortSourceTree(serverData, RevitTreeLevel.Category);
  for (const category of sortedData) {
    const categoryNode = makeMeasurmentRevitNode(category, RevitTreeLevel.Category);
    const currentCategoryId = nodes.push(categoryNode) - 1;
    for (const family of category.children) {
      const familyNode = makeMeasurmentRevitNode(family, RevitTreeLevel.Family, currentCategoryId);
      const currentFamilyId = nodes.push(familyNode) - 1;
      for (const type of family.children) {
        const typeNode = makeMeasurmentRevitNode(type, RevitTreeLevel.ElementType, currentFamilyId);
        const currentTypeId = nodes.push(typeNode) - 1;
        for (const measurement of type.children) {
          const measurementNode = makeMeasurmentRevitNode(measurement, 3, currentTypeId);
          const currentMeasurementId = nodes.push(measurementNode) - 1;
          let start = engineIds.length;
          for (const i in measurement.data.engineIds) {
            const idOfVisibleBimHandle = measurement.data.engineIds[i];
            const bimElementInfo = makeBimElementData(
              idOfVisibleBimHandle,
              MeasurementsBimElementType.Element,
              currentCategoryId,
              currentFamilyId,
              currentTypeId,
              currentMeasurementId,
            );
            engineIds.push(bimElementInfo);
          }
          let end = engineIds.length;
          measurementNode.bimIds = { start, end };
          start = layerIds.length;
          for (const bimHandleId of measurement.data.geometryLessIds) {
            const bimElementInfo = makeBimElementData(
              bimHandleId,
              MeasurementsBimElementType.Layer,
              currentCategoryId,
              currentFamilyId,
              currentTypeId,
              currentMeasurementId,
            );
            layerIds.push(bimElementInfo);
          }
          end = layerIds.length;
          measurementNode.data.layerIds = { start, end };
          const {
            activityExtractor, errorEngineIds: errorIds,
          } = extractActivityExtractorData(measurement, currentMeasurementId);
          extractors[currentMeasurementId] = activityExtractor;
          const errorIdsCount = errorIds.length;
          if (errorIdsCount) {
            nodeCountOfErrors[currentMeasurementId] = (nodeCountOfErrors[currentMeasurementId] || 0) + errorIdsCount;
            nodeCountOfErrors[currentTypeId] = (nodeCountOfErrors[currentTypeId] || 0) + errorIdsCount;
            nodeCountOfErrors[currentFamilyId] = (nodeCountOfErrors[currentFamilyId] || 0) + errorIdsCount;
            nodeCountOfErrors[currentCategoryId] = (nodeCountOfErrors[currentCategoryId] || 0) + errorIdsCount;
            arrayUtils.extendArray(errorEngineIds, errorIds);
          }
        }
        typeNode.lastChildrenIndex = nodes.length - 1;
        typeNode.bimIds = { start: nodes[currentTypeId + 1].bimIds.start, end: engineIds.length };
      }
      familyNode.lastChildrenIndex = nodes.length - 1;
      familyNode.bimIds = { start: nodes[currentFamilyId + 1].bimIds.start, end: engineIds.length };
    }
    categoryNode.lastChildrenIndex = nodes.length - 1;
    categoryNode.bimIds = { start: nodes[currentCategoryId + 1].bimIds.start, end: engineIds.length };
    rootIds[currentCategoryId] = category.rootId;
  }
  return {
    badIds: errorEngineIds,
    extractors,
    errorNodes: nodeCountOfErrors,
    engineIds,
    rootIds,
    list: nodes,
    layerIds,
  };
}

interface ProcessedExtractorFunctionTemplates {
  measurementData: MeasurementActivityGroupModel[];
  templates: Record<string, MeasurementExtractorFunctionTemplate>;
  measurementsRelationsParameters?: Record<number, Record<string, number>>;
}


function getExtractorTemplates(data: MeasurementEntityData): MeasurementExtractorFunctionTemplate[] {
  const result = [];
  const relatedExtractors = data.extractorFunctions.filter(x => x.extractorFunctionKey in measurementsRelationsRules);
  const activityNames = getMergedActivityNames(data.activities.map(activity => activity.name));
  let singleExtractors = data.extractorFunctions;

  if (relatedExtractors) {
    const relatedExtractorsSet = new Set<string>();

    relatedExtractors.forEach(x => {
      const relatedExtractor = data.extractorFunctions.find(
        y => y.extractorFunctionKey === measurementsRelationsRules[x.extractorFunctionKey]);
      result.push({
        relationParameters: data.relationParameters,
        generalFunctionId: x.extractorFunctionId,
        activityNames,
        extractors: [x, relatedExtractor],
      });

      relatedExtractorsSet.add(x.extractorFunctionKey);
      relatedExtractorsSet.add(measurementsRelationsRules[x.extractorFunctionKey]);
    });

    singleExtractors = data.extractorFunctions.filter(x => !relatedExtractorsSet.has(x.extractorFunctionKey));
  }

  singleExtractors.forEach(x => result.push({
    generalFunctionId: x.extractorFunctionId,
    activityNames,
    extractors: [x],
  }));

  return result;
}

function getMergedActivityNames(arr1: string[], arr2?: string[]): string[] {
  const activityNames = new Set<string>(arr2 ? arr1.concat(arr2) : arr1);
  return Array.from(activityNames.values());
}

function getMergedRelationParameters(
  parameters1: Record<number, Record<string, number>>,
  parameters2: Record<number, Record<string, number>>,
): Record<number, Record<string, number>> | undefined {
  if (!parameters1 && !parameters2) {
    return;
  }

  if (parameters1 && parameters2) {
    return { ...parameters1, ...parameters2 };
  }

  return parameters1 ? parameters1 : parameters2;
}

function getExtractorFunctions(
  selectedNodes: MeasurementsActivityExtractor[],
): ProcessedExtractorFunctionTemplates {
  if (!selectedNodes.length) {
    return {
      templates: {},
      measurementData: null,
    };
  }

  const activitiesMap: Record<number, MeasurementActivityGroupModel> = {};
  const templates: Record<string, MeasurementExtractorFunctionTemplate> = {};
  selectedNodes.forEach(extractor => {
    getExtractorTemplates(extractor.data).forEach(template => {
      if (!(template.generalFunctionId in templates)) {
        templates[template.generalFunctionId] = template;
      } else {
        const mainTemplate = templates[template.generalFunctionId];
        mainTemplate.activityNames = getMergedActivityNames(mainTemplate.activityNames, template.activityNames);
        mainTemplate.relationParameters = getMergedRelationParameters(
          mainTemplate.relationParameters, template.relationParameters);
      }
    });

    extractor.data.activities.forEach(activity => {
      if (!(activity.engineId in activitiesMap)) {
        const measurements: Record<string, MeasurementValueGroup> = {};
        for (const [extractorId, measure] of Object.entries(activity.measurements)) {
          measurements[extractorId] = {
            commonValue: measure.value,
            valuesMap: { [activity.leafNodeId]: measure },
          };
        }
        activitiesMap[activity.engineId] = {
          activityId: activity.activityId,
          name: activity.name,
          engineId: activity.engineId,
          level: activity.level,
          geometryLessId: activity.geometryLessId,
          measurements,
          leafNodeId: activity.leafNodeId,
        };
      }

      for (const [extractorId, measure] of Object.entries(activity.measurements)) {
        const elementMeasurements = activitiesMap[activity.engineId].measurements;
        if (extractorId in elementMeasurements) {
          if (elementMeasurements[extractorId].commonValue !== measure.value) {
            elementMeasurements[extractorId].commonValue = null;
          }

          elementMeasurements[extractorId].valuesMap[activity.leafNodeId] = measure;
        } else {
          elementMeasurements[extractorId] = {
            commonValue: measure.value,
            valuesMap: { [activity.leafNodeId]: measure },
          };
        }
      }
    });
  });

  const aggregatedActivities = Object.values(activitiesMap);
  const measurementData = arrayUtils.sortByField(aggregatedActivities, 0, aggregatedActivities.length - 1, 'level');

  return { templates, measurementData };
}

function processLevelName(levelName: string | null | undefined): string {
  return levelName || 'Unknown level';
}

function getExtractorElements(
  measurementData: MeasurementActivityGroupModel[],
): MeasurementsExtractorEditorVisualRow[] {
  const extractorVisualRows = new Array<MeasurementsExtractorEditorVisualRow>();
  if (measurementData && measurementData.length) {
    let currentLevel = processLevelName(measurementData[0].level);
    extractorVisualRows.push({
      name: currentLevel,
      type: MeasurementsExtractorEditorRowType.GroupHeader,
      data: null,
      index: 0,
    });
    for (let i = 0; i < measurementData.length; i++) {
      const activity = measurementData[i];
      const activityLevel = processLevelName(activity.level);
      if (activityLevel !== currentLevel) {
        currentLevel = activityLevel;
        extractorVisualRows.push({
          name: currentLevel,
          type: MeasurementsExtractorEditorRowType.GroupHeader,
          data: null,
          index: extractorVisualRows.length,
        });
      }
      const extractorElement: MeasurementExtractorElement = {
        values: activity.measurements,
        isError: getErrorMeasurementCount(activity.measurements) > 0,
        engineId: activity.engineId,
        level: activityLevel,
      };
      extractorVisualRows.push({
        name: `Element ${i + 1}`,
        type: MeasurementsExtractorEditorRowType.Activity,
        data: extractorElement,
        index: extractorVisualRows.length,
      });
    }
  }
  return extractorVisualRows;
}


function containsTerm(field: string, term: string[]): boolean  {
  const fieldLowerCase = field.toLowerCase();
  return term.every(word => fieldLowerCase.includes(word));
}

function searchInList(
  list: Array<RevitGroupNode<MeasurementRevitTreeNodeData>>,
  extractors: number[],
  filterWords: string[],
): number[] {
  const result = new Array<number>();
  const ids = new Set(extractors);
  list.forEach((x, index) => {
    if (ids.has(index)) {
      result.push(index);
    }
    if (containsTerm(x.data.name, filterWords)) {
      result.push(index);
    }
  });
  return result;
}

function search(
  tree: Array<RevitGroupNode<MeasurementRevitTreeNodeData>>,
  extractors: NumberDictionary<MeasurementsActivityExtractor>,
  filter: string,
): number[] {
  const extractorIds = new Array<number>();
  const filterWords = filter.toLowerCase().split(' ');
  const keys = Object.keys(extractors);
  keys.forEach((id) => {
    const extractor = extractors[id];
    if (containsTerm(extractor.name, filterWords)) {
      extractorIds.push(parseInt(id, 10));
    }
  });
  return searchInList(tree, extractorIds, filterWords);
}

function extractorsGroupSelectionStatus(
  index: number,
  state: MeasurementsState,
  currentRowType: MeasurementsExtractorEditorRowType,
): { selected: boolean, indeterminate: boolean } {
  const isGeneral = currentRowType === MeasurementsExtractorEditorRowType.General;
  if (!isGeneral && state.extractorEditorRows[index].type !== currentRowType) {
    return null;
  }

  let selected = true;
  let indeterminate = false;
  for (let i = index + 1; i < state.extractorEditorRows.length; i++) {
    const row = state.extractorEditorRows[i];
    if (isGeneral && row.type === MeasurementsExtractorEditorRowType.GroupHeader) {
      continue;
    }
    const canChangesBeAdded = !selected && indeterminate;
    if (canChangesBeAdded || row.type === currentRowType) {
      break;
    }
    if (state.elementSelectStatuses[i]) {
      indeterminate = true;
    } else {
      selected = false;
    }
  }

  return { selected, indeterminate };
}

function getRootId(
  modelBrowser: Array<RevitGroupNode<MeasurementRevitTreeNodeData>>,
  rootIds: number[],
  leafNodeId: number): number {
  let item = modelBrowser[leafNodeId];
  let selectedRootId = -1;
  while (item.parentId !== undefined && item.parentId !== null) {
    selectedRootId = item.parentId;
    item = modelBrowser[item.parentId];
  }
  return rootIds[selectedRootId];
}

function getRevertChangesForm(
  cachedMeasurementsValues: Array<Record<string, MeasurementValueGroup>>,
  modelBrowser: Array<RevitGroupNode<MeasurementRevitTreeNodeData>>,
  rootIds: number[],
): MeasurementUpdateForm[] {
  const rootIdToValuesMap: Record<string, MeasurementValue[]> = {};
  for (const cachedMeasurementsValue of cachedMeasurementsValues) {
    for (const measurements of Object.values(cachedMeasurementsValue)) {
      for (const [rootId, value] of Object.entries(measurements.valuesMap)) {
        rootIdToValuesMap[rootId] = rootIdToValuesMap[rootId] || [];
        rootIdToValuesMap[rootId].push(value);
      }
    }
  }

  return getChangesForm(rootIdToValuesMap, modelBrowser, rootIds);
}

function getChangesForm(
  uncommitedChanges: Record<number, MeasurementValue[]>,
  modelBrowser: Array<RevitGroupNode<MeasurementRevitTreeNodeData>>,
  rootIds: number[],
): MeasurementUpdateForm[] {
  const rootIdToValues: Record<number, MeasurementValue[]> = {};

  Object.entries(uncommitedChanges).forEach(([leafNodeId, activityValues]) => {
    const rootId = getRootId(modelBrowser, rootIds, +leafNodeId);
    rootIdToValues[rootId] = rootIdToValues[rootId] || [];
    rootIdToValues[rootId] = rootIdToValues[rootId].concat(activityValues);
  });

  return Object.entries(rootIdToValues)
    .map(([rootId, activityValues]) => ({ rootId: +rootId, activityValues }));
}


export const MeasurementsUtils = {
  extractRevitTreeNodeIdsForRender,
  createGeneralExtractorEditors,
  getSelectedIds,
  extractData,
  isMeasurementBad,
  getErrorMeasurementCount,
  getMeasurementGroupErrorCount,
  getExtractorFunctions,
  getExtractorElements,
  search,
  extractorsGroupSelectionStatus,
  getRevertChangesForm,
  getChangesForm,
};
