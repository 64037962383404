import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-right: 30px;
  align-items: end;
`;

const TextContainer = styled.div`
  padding: 10px 15px;
  background-color: ${p => p.theme.color.turquoise};
  border-radius: 20px 20px 20px 5px;
  margin-left: 15px;
`;

export const Styled = {
  Container,
  TextContainer,
};
