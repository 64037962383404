import { RequestStatus } from 'common/enums/request-status';
import { SortingDirection } from 'common/interfaces/sorting-direction';
import { FilterType } from './filter-type';
import { UserActivitiesState } from './interfaces/redux-state';

export const USER_ACTIVITIES_INITIAL_STATE: UserActivitiesState = {
  activities: [],
  filteredActivities: [],
  statuses: {
    activities: RequestStatus.Loaded,
  },
  filter: {},
  timeInterval: {
    start: null,
    end: null,
  },
  sorting: {
    type: FilterType.Date,
    direction: SortingDirection.Descending,
  },
};
