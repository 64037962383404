import * as React from 'react';
import { ConstantFunctions } from 'common/constants/functions';
import { Styled } from './styled';

interface Props {
  isOpen: boolean;
  withSidePanel: boolean;
  isClickOutsideActive: boolean;
  withBlur?: boolean;
  isBreakdownPanel?: boolean;
  onClose: () => void;
}

export const SidePanelContainer: React.FC<Props> = (props): JSX.Element => {
  const { isOpen, withSidePanel, children, onClose, isClickOutsideActive, withBlur = true, isBreakdownPanel } = props;
  return (
    <>
      <Styled.Backdrop
        withBlur={withBlur}
        show={isOpen}
        onClick={isOpen && isClickOutsideActive ? onClose : ConstantFunctions.doNothing}
      />
      <Styled.Container
        isOpen={isOpen}
        withSidePanel={withSidePanel}
        isBreakdownPanel={isBreakdownPanel}
      >
        {children}
      </Styled.Container>
    </>
  );
};
