import autobind from 'autobind-decorator';
import { BaseStreamRunConfig, IStream, Stream } from './stream';

export class StreamedRequestController<
  RequestType = unknown,
  ResultType = unknown,
  C extends BaseStreamRunConfig<RequestType, ResultType> = BaseStreamRunConfig<RequestType, ResultType>,
>  {
  private _stream: Stream<RequestType, ResultType>;
  private _streamType: IStream<RequestType, ResultType, C>;

  constructor(streamType: IStream<RequestType, ResultType, C>) {
    this._streamType = streamType;
  }

  @autobind
  public async run(
    config: C,
  ): Promise<void> {
    if (this._stream) {
      this._stream.cancel();
    }
    this._stream = new this._streamType();
    await this._stream.run(config);
    this._stream = null;
  }

  @autobind
  public cancel(): void {
    if (this._stream) {
      this._stream.cancel();
      this._stream = null;
    }
  }
}
