import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawingsTextSearchActions } from 'common/components/drawings/actions/creators';
import { PdfLoader } from 'common/components/drawings/helpers';
import { TextSearchHelper } from 'common/components/drawings/helpers/text-search-helper';
import { DrawingsShortInfo, TextSearchResultsInfo, TextSearchSettings } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { useCurrentDrawingInfo } from '../../hooks';

export function useDrawingsTextSearch(): void {
  const searchHelper = useMemo(() => new TextSearchHelper(new PdfLoader()), []);

  useEffect(() => {
    return () => searchHelper.cancel();
  }, []);

  const dispatch = useDispatch();

  const drawings = useSelector<State, Record<string, DrawingsShortInfo>>(s => s.drawings.drawingsInfo);
  const settings = useSelector<State, TextSearchSettings>(s => s.drawings.textSearch.settings);
  const result = useSelector<State, TextSearchResultsInfo>(s => s.drawings.textSearch.results);
  const currentDrawingInfo = useCurrentDrawingInfo();

  const { sendEvent } = useAnalytics();

  const prevDrawings = useRef<Record<string, DrawingsShortInfo>>(null);
  const prevSettings = useRef<TextSearchSettings>(null);

  useEffect(() => {
    if (prevDrawings.current !== drawings || prevSettings.current !== settings) {
      prevDrawings.current = drawings;
      prevSettings.current = settings;

      searchHelper
        .runSearch(
          settings,
          drawings,
          () => dispatch(DrawingsTextSearchActions.setIsLoading(true)),
          result,
          currentDrawingInfo?.drawingId,
        )
        .then(({ isFinished, data }) => {
          if (isFinished) {
            if (settings.query) {
              sendEvent(MetricNames.engineNavigation.textSearch, {
                ['Search&Highlight Text Settings']: settings,
              });
            }
            dispatch(DrawingsTextSearchActions.setResults(data));
          }
        }).catch((e) => {
          console.error(e);
          dispatch(DrawingsTextSearchActions.setIsError(true));
        });
    }
  }, [settings, drawings, dispatch, sendEvent]);
}
