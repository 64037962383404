import styled from 'styled-components';


const Header = styled.div`
  border-radius: 20px;
  background: ${p => p.theme.color.red};
  display: flex;
  align-items: center;
  gap: 35px;
  padding: 14px 14px 18px 20px;
  margin: 20px 20px 15px;
  box-sizing: border-box;
`;

const Icon = styled.div`
  width: 85px;
  min-width: 85px;
  height: 85px;

  > svg {
    width: 100%;
    height: 100%;
    fill: ${p => p.theme.color.white};

    circle,
    path {
      fill: ${p => p.theme.color.white};
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;

  p {
    color: ${p => p.theme.color.white}
  }
`;


export const Styled = {
  Header,
  Icon,
  Text,
};
