const constants = {
  ID_PREV_POSTFIX: '_old',
};

const isColumnIdHasDiffPostfix = (columnId: string): boolean => {
  return columnId && columnId.endsWith(constants.ID_PREV_POSTFIX);
};

export const QtoTreeTableHelper = {
  constants,
  isColumnIdHasDiffPostfix,
};
