import * as React from 'react';
import { connect } from 'react-redux';

import './classification-assembly-entity-assignment.scss';

import { State } from 'common/interfaces/state';
import { ClassificationAssemblyEntityCustomAssignment } from '../classification-assembly-entity-custom-assignment';
import { ClassificationAssemblyEntityPredicted } from '../classification-assembly-entity-predicted';

interface Props {
  isAssembly: boolean;
  classificationsAmount: number;
}

function renderVariants(amount: number): React.ReactNode[] {
  const variants = new Array<React.ReactNode>();
  for (let i = 0; i < amount; i++) {
    variants[i] = <ClassificationAssemblyEntityPredicted index={i} key={i} />;
  }
  return variants;
}

const ClassificationAssemblyEntityAssignmentComponent: React.FC<Props> = props => {
  return (
    <div className='classification-assembly-entity-assignment'>
      <ClassificationAssemblyEntityCustomAssignment isAssembly={props.isAssembly} />
      {renderVariants(props.classificationsAmount)}
    </div>
  );
};

function mapStateToProps(state: State): Props {
  return {
    isAssembly: state.classification.changeAssemblyRoot,
    classificationsAmount: state.classification.itemClassification.length,
  };
}

const connector = connect(mapStateToProps);
export const ClassificationAssemblyEntityAssignment = connector(
  ClassificationAssemblyEntityAssignmentComponent,
);
