import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './activity-popover-item.scss';

import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { ActivityGroupData } from '../../interfaces';

interface Props {
  item: ActivityGroupData.IdsByName;
  groupId: number;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick: (ids: number[]) => void;
  selectItem: (groupId: number, name: string) => void;
}

export class ActivityPopoverItem extends React.Component<Props> {
  public render(): React.ReactNode {
    const { item, isSelected, isDisabled } = this.props;

    return (
      <div
        className={classNames('activity-popover-item', {
          ['activity-popover-item--selected']: isSelected && !isDisabled,
          ['activity-popover-item--disabled']: isDisabled,
        })}
        onClick={this.onClick}
      >
        <div className='activity-popover-item__title'>
          {item.name}
        </div>
      </div>
    );
  }

  @autobind
  private onClick(event: React.MouseEvent<HTMLDivElement>): void {
    if (this.props.isDisabled) {
      return;
    }

    if (HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(event)) {
      this.props.selectItem(this.props.groupId, this.props.item.name);
    } else {
      if (this.props.onClick) {
        this.props.onClick(this.props.item.ids);
      }
    }
  }
}
