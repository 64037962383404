import { Icons, RectangleButton, SvgComponent } from '@kreo/kreo-ui-components';
import React from 'react';

import { Mood } from 'common/enums/mood';
import { WizzardStatus } from '../../enums/dropper-state';
import { Styled } from './styled';

const StatusMoods = {
  [WizzardStatus.Error]: Mood.Negative,
};

const StatusText = {
  [WizzardStatus.Loading]: 'Searching...',
  [WizzardStatus.Error]: 'Try again',
};

interface Props {
  onClick: () => void;
  text: string;
  status?: WizzardStatus;
  disabled?: boolean;
  icon: SvgComponent;
  textOverrides?: { [key in WizzardStatus]?: string };
}

const AiButtonWithStatusComponent: React.FC<Props> = ({
  status = WizzardStatus.Start,
  onClick: run,
  disabled,
  icon,
  text,
  textOverrides = {},
}) => {
  return (
    <Styled.Button>
      <RectangleButton
        height={36}
        mood={disabled ? Mood.Disabled : (StatusMoods[status] || Mood.Secondary)}
        onClick={run}
        Icon={status === WizzardStatus.Loading ? Icons.Loader : icon}
        text={textOverrides[status] || StatusText[status] || text}
      />
    </Styled.Button>
  );
};


export const AiButtonWithStatus = React.memo(AiButtonWithStatusComponent);
