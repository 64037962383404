import { useOpenMenuWithPosition } from '@kreo/kreo-ui-components/utils';
import * as React from 'react';

import { RenderIf } from 'common/components/render-if';
import { ButtonQuestions } from './button-questions';
import { MenuQuestions } from './menu-questions';
import { Styled } from './styled';


export const MenuQuestionsFastNavigation: React.FC = () => {
  const { isOpen, position, onClose, onOpen } = useOpenMenuWithPosition(30, -7);

  return (
    <>
      <Styled.Container>
        <ButtonQuestions onToggleMenu={onOpen}/>
      </Styled.Container>
      <RenderIf condition={isOpen}>
        <MenuQuestions
          onClose={onClose}
          menuPositionX={position.x}
          menuPositionY={position.y}
        />
      </RenderIf>
    </>
  );
};
