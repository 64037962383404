import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './chart-body.scss';

import { State } from 'common/interfaces/state';
import { mathUtils } from 'common/utils/math-utils';
import { LineData } from '../../../../../../interfaces/gantt-chart';
import { ChartDataProvider, GanttLineHorizontalCoords, GroupType } from '../../../../../../utils/gantt-chart';
import { ChartLine } from './components/chart-line';
import { InternalRangesPool } from './internal-ranges-pool';

interface ChartBodyOwnProps {
  dataProvider: ChartDataProvider;
  timeframeStartDay: number;
  timeframeDuration: number;
  width: number;
  leftPanelWidth: number;
  height: number;
  isolatedLine: LineData | null;
  toggleIsolation: (line: LineData | null) => void;
}

interface ChartBodyStateProps {
  workPackages: LineData[];
}

interface ChartBodyProps extends ChartBodyStateProps, ChartBodyOwnProps { }

interface ChartBodyState {
  offset: number;
}

interface CoordsDictionary {
  [lineId: string]: GanttLineHorizontalCoords;
}

class ChartBodyComponent extends React.Component<ChartBodyProps, ChartBodyState> {
  private readonly lineHeight: number = 26;

  private scrollContainer: HTMLDivElement;

  private visibleLinesIds: string[] = [];
  private outerRangesRefs: Map<string, HTMLDivElement> = new Map<string, HTMLDivElement>();
  private coordsLookup: CoordsDictionary = {};
  private linesToRender: LineData[] = [];
  private rangesPool: InternalRangesPool = new InternalRangesPool(this.getOuterRangeRef, 'internal-range');

  constructor(props: ChartBodyProps) {
    super(props);
    this.state = { offset: 0 };
  }

  public render(): React.ReactNode {
    this.props.dataProvider.setGanttPixelsRange(
      mathUtils.clamp(this.props.width - this.props.leftPanelWidth, 1, Number.MAX_SAFE_INTEGER),
    );

    const visibleLinesCount = Math.ceil(this.props.height / this.lineHeight);
    const topUnrenderedLinesCount = Math.floor(this.state.offset / this.lineHeight);
    const topSpacerHeight = topUnrenderedLinesCount * this.lineHeight;

    this.coordsLookup = {};

    const linesData: LineData[] = [];

    const isInIsolationMode = this.props.isolatedLine !== null;

    if (isInIsolationMode) {
      const isolatedLine = this.props.isolatedLine;
      this.coordsLookup[isolatedLine.lineId] =
        this.props.dataProvider.calculateGanttLineCoords(isolatedLine.type, isolatedLine.entityId);
      if (this.coordsLookup[isolatedLine.lineId] !== null) {
        linesData.push(isolatedLine);
      }

      for (const childLine of isolatedLine.children) {
        this.coordsLookup[childLine.lineId] =
          this.props.dataProvider.calculateGanttLineCoords(childLine.type, childLine.entityId);
        if (this.coordsLookup[childLine.lineId] === null) {
          continue;
        }

        linesData.push(childLine);
      }
    } else {
      for (const workPackage of this.props.workPackages) {
        this.coordsLookup[workPackage.lineId] =
          this.props.dataProvider.calculateGanttLineCoords(GroupType.WorkPackage, workPackage.entityId);
        if (this.coordsLookup[workPackage.lineId] === null) {
          continue;
        }

        linesData.push(workPackage);
        if (workPackage.isExpanded) {
          for (const activityGroup of workPackage.children) {
            this.coordsLookup[activityGroup.lineId] =
              this.props.dataProvider.calculateGanttLineCoords(GroupType.ActivityGroup, activityGroup.entityId);
            if (this.coordsLookup[activityGroup.lineId] === null) {
              continue;
            }

            linesData.push(activityGroup);
          }
        }
      }
    }

    this.linesToRender = linesData.slice(topUnrenderedLinesCount, topUnrenderedLinesCount + visibleLinesCount);
    this.visibleLinesIds = this.linesToRender.map((l) => l.lineId);

    return (
      <div
        className='chart-body'
        ref={this.setContainerRef}
        onScroll={this.onScroll}
      >
        <div
          className='chart-body__content'
          style={{ minHeight: linesData.length * this.lineHeight }}
        >
          <div
            className='chart-body__spacer'
            style={{ height: topSpacerHeight }}
          />
          {
            this.linesToRender && this.linesToRender.length > 0
              ? this.linesToRender.map((lineData) => {
                return this.renderLine(lineData, this.coordsLookup[lineData.lineId], isInIsolationMode);
              })
              : (
                <div className='chart-body__message'>
                  No activities have been scheduled for chosen timeframe
              </div>
              )
          }
        </div>
      </div>
    );
  }

  public componentDidMount(): void {
    this.renderInternalRanges(false);
  }

  public componentDidUpdate(prevProps: ChartBodyProps): void {
    const scaleChanged = prevProps.width !== this.props.width ||
      prevProps.timeframeDuration !== this.props.timeframeDuration;
    this.renderInternalRanges(scaleChanged);
  }

  private renderLine(
    lineData: LineData,
    horizontalCoords: GanttLineHorizontalCoords,
    isInIsolationMode: boolean,
  ): React.ReactNode {
    const isIsolated = isInIsolationMode
      ? this.props.isolatedLine === lineData
      : false;
    const nestingLevel = this.getNestingLevel(lineData, isInIsolationMode, isIsolated);

    return (
      <ChartLine
        isIsolated={isIsolated}
        key={lineData.lineId}
        left={horizontalCoords.start}
        nestingLevel={nestingLevel}
        data={lineData}
        width={horizontalCoords.width}
        toggleIsolation={this.props.toggleIsolation}
        saveOuterRangeRef={this.saveOuterRangeRef}
      />
    );
  }

  private renderInternalRanges(scaleChanged: boolean): void {
    // recycle all elements
    this.rangesPool.markAllAsAvailable();

    // prepare request to the pool
    const request = new Map<string, number[]>();
    for (const line of this.linesToRender) {
      const coords = this.coordsLookup[line.lineId];
      const neededRangesCount = coords.internalLinesSizes.length / 2;
      const neededRanges: number[] = new Array<number>(neededRangesCount);

      for (let i = 0; i < neededRangesCount; ++i) {
        neededRanges[i] = i + coords.internalLinesStartIndex / 2;
      }

      request.set(line.lineId, neededRanges);
    }

    // request elements
    const linesElements = this.rangesPool.getNodes(request, scaleChanged);

    // position and redraw elements
    for (const line of this.linesToRender) {
      const lineId = line.lineId;
      const ranges = linesElements.get(lineId);

      const coords = this.coordsLookup[lineId];

      for (const range of ranges) {
        const indexInSizesArray = (range.indexInLine - coords.internalLinesStartIndex / 2) * 2;
        const rangeOffset = coords.internalLinesSizes[indexInSizesArray];
        const rangeWidth = coords.internalLinesSizes[indexInSizesArray + 1];

        if (range.needsRedraw) {
          range.element.style.transform = `translateX(${rangeOffset}px)`;
          range.element.style.width = `${rangeWidth}px`;
          range.element.style.background = line.mainColor;
          range.element.id = `${lineId}--${range.indexInLine}`;
        }
      }
    }

    // remove unused elements
    this.rangesPool.detachAvailableElements();
  }

  private getNestingLevel(lineData: LineData, isInIsolationMode: boolean, isIsolatedLine: boolean): number {
    switch (lineData.type) {
      case GroupType.WorkPackage:
        return 0;
      case GroupType.ActivityGroup:
        return isInIsolationMode && isIsolatedLine ? 0 : 1;
      case GroupType.Activity:
        return 1;
      default:
        throw new Error('Unexpected GanttLineType');
    }
  }

  @autobind
  private setContainerRef(ref: HTMLDivElement): void {
    this.scrollContainer = ref;
  }

  @autobind
  private onScroll(): void {
    this.setState((prevState) => {
      const newOffset = this.scrollContainer.scrollTop;
      const oldOffset = prevState.offset;

      if (newOffset < 0 || oldOffset === newOffset) {
        return;
      }

      return {
        offset: newOffset,
      };
    });
  }

  @autobind
  private saveOuterRangeRef(lineId: string, ref: HTMLDivElement): void {
    this.outerRangesRefs.set(lineId, ref);
    this.rangesPool.markLineElementsAsDetached(lineId);
  }

  @autobind
  private getOuterRangeRef(lineId: string): HTMLDivElement {
    if (!this.visibleLinesIds.includes(lineId)) {
      return null;
    }

    return this.outerRangesRefs.get(lineId);
  }
}


const mapStateToProps = (state: State): ChartBodyStateProps => {
  return {
    workPackages: state.fourDVisualisation.ganttData.workPackages,
  };
};

export const ChartBody = connect(mapStateToProps)(ChartBodyComponent);
