import { IconButton, Icons } from '@kreo/kreo-ui-components';
import { useOpenMenuWithPosition } from '@kreo/kreo-ui-components/utils';
import React from 'react';

import { CommentMenuContent } from './comment-menu-content';

interface Props {
  onEdit: () => void;
  onRemove: () => void;
}

const CommentMenuComponent: React.FC<Props> = ({ onEdit, onRemove }) => {
  const { isOpen, position, onClose, onOpen } = useOpenMenuWithPosition();

  return (
    <>
      <IconButton
        Icon={Icons.Menu}
        width={20}
        height={20}
        onClick={onOpen}
      />
      {
        isOpen ? (
          <CommentMenuContent
            onClose={onClose}
            onEdit={onEdit}
            onRemove={onRemove}
            position={position}
          />
        ) : null
      }
    </>
  );
};

export const CommentMenu = React.memo(CommentMenuComponent);
