import autobind from 'autobind-decorator';
import startCase from 'lodash/startCase';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './edit-person-role-dialog.scss';

import {
  HorizontalTab,
  HorizontalTabProps,
  HorizontalTabsContainer,
} from 'common/components/horizontal-tabs-container';
import { RoleGroup } from 'common/enums/role-group';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { Role } from 'common/interfaces/account/role';
import { NumberDictionary, StringDictionary } from 'common/interfaces/dictionary';
import { State } from 'common/interfaces/state';
import {
  KreoDialog,
  KreoDialogActions,
  MaterialMenuItem,
  MaterialSelect,
} from 'common/UIKit';
import { KreoDialogActionsPanel } from 'common/UIKit/dialogs/kreo-dialog-actions-panel';
import { AccountSelectors } from '../../../account/selectors';
import { PeopleActions } from '../../actions/actions';
import { Person } from '../../interfaces/person';

interface DialogOwnProps {
  person: Person;
  onClose: () => void;
  intendedRoleGroup?: RoleGroup;
}

interface DialogStateProps {
  companyId: number;
  companyRoles: CompanyRoles;
  isLicensesLimitExceeded: boolean;
}

interface DialogDispatchProps {
  closeDialog: () => void;
  updateUserRole: (userId: string, companyId: number, roleId: number) => void;
}

interface DialogProps extends DialogOwnProps, DialogStateProps, DialogDispatchProps {}

interface DialogState {
  selectedRoles: StringDictionary<number>;
}

export const EditPersonRoleDialogName = 'edit-person-role-dialog';

const roleGroupsIndexes = {
  [RoleGroup.Employee]: 0,
  [RoleGroup.Guest]: 1,
  [RoleGroup.Subcontractor]: 2,
};

class EditPersonRoleDialogComponent extends React.Component<DialogProps, DialogState> {
  private changeHandlers: NumberDictionary<
    (e: React.SyntheticEvent<{}>, value: number) => void
  > = {};
  private submitHandlers: NumberDictionary<() => void> = {};

  constructor(props: DialogProps) {
    super(props);

    this.state = {
      selectedRoles: {
        [RoleGroup.Employee]: null,
        [RoleGroup.Guest]: null,
        [RoleGroup.Subcontractor]: null,
      },
    };
  }

  public static getDerivedStateFromProps(
    props: DialogProps,
    prevState: DialogState,
  ): Partial<DialogState> {
    if (
      props.person &&
      props.companyRoles &&
      prevState.selectedRoles[RoleGroup.Employee] === null &&
      prevState.selectedRoles[RoleGroup.Guest] === null &&
      prevState.selectedRoles[RoleGroup.Subcontractor] === null
    ) {
      const { person, companyRoles } = props;

      const personRole = companyRoles.roles.find(r => r.id === person.roleId);

      const employeeRoles = companyRoles.roles.filter(r => r.group === RoleGroup.Employee);
      const guestRoles = companyRoles.roles.filter(r => r.group === RoleGroup.Guest);
      const subcontractorRoles = companyRoles.roles.filter(
        r => r.group === RoleGroup.Subcontractor,
      );

      const selectedEmployeeRoleId =
        personRole.group === RoleGroup.Employee ? personRole.id : employeeRoles[0].id;
      const selectedGuestRoleId =
        personRole.group === RoleGroup.Guest ? personRole.id : guestRoles[0].id;
      const selectedSubcontractorRoleId =
        personRole.group === RoleGroup.Subcontractor ? personRole.id : subcontractorRoles[0].id;

      return {
        selectedRoles: {
          [RoleGroup.Employee]: selectedEmployeeRoleId,
          [RoleGroup.Guest]: selectedGuestRoleId,
          [RoleGroup.Subcontractor]: selectedSubcontractorRoleId,
        },
      };
    }

    return null;
  }

  public render(): React.ReactNode {
    const { person, onClose, companyRoles, intendedRoleGroup, isLicensesLimitExceeded } = this.props;

    if (!companyRoles || !person) {
      return null;
    }

    const personRole = companyRoles.roles.find(r => r.id === person.roleId);

    if (!personRole) {
      return null;
    }

    const defaultTabIndex = intendedRoleGroup !== undefined ? intendedRoleGroup : personRole.group;

    const isEmployeeTabEnabled = !isLicensesLimitExceeded || personRole.group === RoleGroup.Employee;

    return (
      <KreoDialog
        name={EditPersonRoleDialogName}
        isModal={true}
        title='Edit Role'
        onClose={onClose}
      >
        <HorizontalTabsContainer
          defaultTabIndex={roleGroupsIndexes[defaultTabIndex]}
          className='edit-person-role-dialog'
        >
          {this.renderTab(personRole, RoleGroup.Employee, !isEmployeeTabEnabled)}
          {this.renderTab(personRole, RoleGroup.Guest)}
          {this.renderTab(personRole, RoleGroup.Subcontractor)}
        </HorizontalTabsContainer>
      </KreoDialog>
    );
  }

  private getIntendedAction(currentRoleGroup: RoleGroup, intendedRoleGroup: RoleGroup): string {
    if (currentRoleGroup === intendedRoleGroup) {
      return null;
    }

    // role groups are indexed from the most powerful, so transfer from 0 to 1 is a downgrade
    return roleGroupsIndexes[currentRoleGroup] < roleGroupsIndexes[intendedRoleGroup]
      ? 'downgrade'
      : 'upgrade';
  }

  private getTransferNotice(
    currentRoleGroup: RoleGroup,
    intendedRoleGroup: RoleGroup,
  ): React.ReactNode {
    if (currentRoleGroup === intendedRoleGroup) {
      return null;
    }

    const { person } = this.props;

    const action = this.getIntendedAction(currentRoleGroup, intendedRoleGroup);

    return (
      <div className='edit-person-role-dialog__subtitle'>
        Do you want to {action}{' '}
        <b>
          {person.firstName} {person.lastName}
        </b>{' '}
        from the user type of <b>{RoleGroup[currentRoleGroup]}</b> to{' '}
        <b>{RoleGroup[intendedRoleGroup]}</b>?
      </div>
    );
  }

  private renderTab(
    personRole: Role,
    roleGroup: RoleGroup,
    disabled?: boolean,
  ): React.ReactElement<HorizontalTabProps> {
    const roles = this.props.companyRoles.roles.filter(r => r.group === roleGroup);
    const action = this.getIntendedAction(personRole.group, roleGroup);
    const buttonLabel = action ? `${startCase(action)} User` : 'Assign';

    return (
      <HorizontalTab
        name={this.props.companyRoles.roleGroups[roleGroup.toString().toLowerCase()]}
        className='edit-person-role-dialog__tab'
        disabled={disabled}
      >
        {this.getTransferNotice(personRole.group, roleGroup)}
        <MaterialSelect
          label={'Role'}
          value={this.state.selectedRoles[roleGroup]}
          onChange={this.getRoleChangeHandler(roleGroup)}
          className='edit-person-role-dialog__role-select'
        >
          {roles.map(role => (
            <MaterialMenuItem
              className='edit-person-role-dialog__role-select-item'
              key={role.id}
              value={role.id}
            >
              {role.name}
            </MaterialMenuItem>
          ))}
        </MaterialSelect>
        <KreoDialogActionsPanel
          onCancel={this.closeDialog}
          submitBtnName={buttonLabel}
          onSubmit={this.getSubmitHandler(roleGroup)}
        />
      </HorizontalTab>
    ) as React.ReactElement<HorizontalTabProps>;
  }

  private getRoleChangeHandler(
    roleGroup: RoleGroup,
  ): (e: React.SyntheticEvent<{}>, value: number) => void {
    if (!this.changeHandlers[roleGroup]) {
      this.changeHandlers[roleGroup] = (_e: React.SyntheticEvent<{}>, value: number) => {
        this.setState(actualState => ({
          selectedRoles: { ...actualState.selectedRoles, [roleGroup]: value },
        }));
      };
    }

    return this.changeHandlers[roleGroup];
  }

  private getSubmitHandler(roleGroup: RoleGroup): () => void {
    if (!this.submitHandlers[roleGroup]) {
      const { person, companyId, updateUserRole } = this.props;

      this.submitHandlers[roleGroup] = () => {
        updateUserRole(person.id, companyId, this.state.selectedRoles[roleGroup]);
        this.closeDialog();
      };
    }

    return this.submitHandlers[roleGroup];
  }

  @autobind
  private closeDialog(): void {
    this.props.closeDialog();
    this.props.onClose();
  }
}

const mapStateToProps = (state: State): DialogStateProps => {
  const company = state.account.selectedCompany;
  const companyId = company ? company.id : null;
  const companyRoles = state.account.subscriptionRoles;

  return {
    companyRoles,
    companyId,
    isLicensesLimitExceeded: AccountSelectors.isSelectedCompanyLicensesLimitExceeded(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DialogDispatchProps => {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(EditPersonRoleDialogName)),
    updateUserRole: (userId, companyId, roleId) =>
      dispatch(PeopleActions.updateUserRole(userId, companyId, roleId)),
  };
};

export const EditPersonRoleDialog = connect(mapStateToProps, mapDispatchToProps)(
  EditPersonRoleDialogComponent,
);
