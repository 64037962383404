import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconEnterIsolateMode: React.FC = () => {
  return (
    <svg
      className='kreo-icon enter-isolate-mode'
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icon_isolate-mode' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <polyline
          stroke={KreoColors.f3}
          strokeLinecap='round'
          strokeLinejoin='round'
          points='10.5 1.5 12.5 1.5 12.5 3.5'
        />
        <polyline
          stroke={KreoColors.f3}
          strokeLinecap='round'
          strokeLinejoin='round'
          points='3.5 1.5 1.5 1.5 1.5 3.5'
        />
        <polyline
          stroke={KreoColors.f3}
          strokeLinecap='round'
          strokeLinejoin='round'
          points='10.5 12.5 12.5 12.5 12.5 10.5'
        />
        <polyline
          stroke={KreoColors.f3}
          strokeLinecap='round'
          strokeLinejoin='round'
          points='3.5 12.5 1.5 12.5 1.5 10.5'
        />
        <rect
          id='Rectangle-19'
          stroke={KreoColors.f3}
          x='3.5'
          y='3.5'
          width='7'
          height='7'
          rx='2'
        />
      </g>
    </svg>
  );
};
