const ALPHABET_LENGTH = 26;
const FIRST_COLUMN_NAME = 'A'.charCodeAt(0);

const indexToExcelColName = (index: number): string => {
  let colName = '';
  let dividend = Math.floor(Math.abs(index));
  let rest;

  while (dividend > 0) {
    rest = (dividend - 1) % ALPHABET_LENGTH;
    colName = String.fromCharCode(FIRST_COLUMN_NAME + rest) + colName;
    dividend = Math.trunc((dividend - rest) / ALPHABET_LENGTH);
  }
  return colName;
};


const excelColNameToIndex = (colName: string): number => {
  const digits = colName.toUpperCase().split('');

  return digits.reduce(
    (result, x, index) => {
      const code = (x.charCodeAt(0) - FIRST_COLUMN_NAME);
      const pow = Math.pow(ALPHABET_LENGTH, digits.length - index - 1);
      return result + (code + 1) * pow;
    },
    0,
  );
};

const shiftColumnName = (colName: string, offset: number): string => {
  const columnIndex = excelColNameToIndex(colName);
  return indexToExcelColName(columnIndex + offset);
};

export const ExcelColumnHelper = {
  ALPHABET_LENGTH,
  FIRST_COLUMN_NAME,
  indexToExcelColName,
  excelColNameToIndex,
  shiftColumnName,
};
