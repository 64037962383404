import { useCallback } from 'react';
import { useActionDispatch } from 'common/hooks';
import { WizzardToolsActions } from '../../actions/creators';
import { DrawingsDrawMode } from '../../enums';
import { DrawingsSelectAggregationGroup, WizzardToolsState } from '../../interfaces';

type StartWizzardWithTargetType = (
  drawMode: DrawingsDrawMode,
  targetInstanceGroup: DrawingsSelectAggregationGroup
) => void;

export function useStartWizzardWithTargetType(): StartWizzardWithTargetType {
  const setWizzardSettings = useActionDispatch(WizzardToolsActions.batchUpdateSettings);
  return useCallback<StartWizzardWithTargetType>((drawMode, targetInstanceType) => {
    const settingsUpdate: Partial<WizzardToolsState> = {};
    switch (drawMode) {
      case DrawingsDrawMode.Wand:
        if (targetInstanceType === DrawingsSelectAggregationGroup.Area) {
          settingsUpdate.enclose = true;
          settingsUpdate.connect = true;
        } else if (targetInstanceType === DrawingsSelectAggregationGroup.Line) {
          settingsUpdate.enclose = false;
        }
        break;
      case DrawingsDrawMode.Dropper:
        settingsUpdate.dropperInstanceType = targetInstanceType;
        break;
      default:
    }
    setWizzardSettings(settingsUpdate);

  }, [setWizzardSettings]);
}
