import { Icons, RectangleButton, TinyText } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';

import { PdfPagePreviewWithBubble } from 'common/components/drawings/components';
import { RenderIf } from 'common/components/render-if';
import { ToggleButton } from 'common/components/toggle-button';
import { ColorList } from 'common/constants/color-list';
import { ColorsName } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { Styled } from './styled';

interface OwnProps {
  pageId: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  shown?: boolean;
  drawingRendered?: boolean;
  onResize?: (e: React.MouseEvent) => void;
  onHide?: () => void;
}

interface StateProps {
  pdfName: string;
  pageNumber: number;
  name: string;
  screenshotCreated: boolean;
  color: string;
  pdfId: string;
}

interface Props extends StateProps, OwnProps {}

class FileInfoComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      screenshotCreated,
      color,
      pageId,
      pdfId,
      pageNumber,
      name,
      pdfName,
      onClick,
      onResize,
      onHide,
      shown,
      drawingRendered,
    } = this.props;

    return (
      <Styled.Container onClick={onClick}>
        <PdfPagePreviewWithBubble
          created={screenshotCreated}
          color={color}
          pageId={pageId}
          pdfId={pdfId}
          pageNumber={pageNumber}
        />
        <Styled.TitlesContainer canResize={!!onResize}>
          <TinyText withEllipsis={true}>{name}</TinyText>
          <TinyText color={ColorsName.turquoiseFont} withEllipsis={true}>
            {pdfName}
          </TinyText>
        </Styled.TitlesContainer>
        <Styled.Buttons>
          <RenderIf condition={!!onResize}>
            <Styled.ResizeButton>
              <RectangleButton
                backgroundColor={drawingRendered ? 'pale' : 'disabled'}
                height={20}
                width={50}
                fontSize={12}
                textColor={'gray'}
                textHoverColor={'turquoise'}
                text='Resize'
                onClick={onResize}
              />
            </Styled.ResizeButton>
          </RenderIf>
          <RenderIf condition={!!onHide}>
            <ToggleButton
              onToggle={onHide}
              active={shown}
              activeIcon={Icons.Show2D}
              icon={Icons.Hide2D}
              activeTooltip="Hide"
              tooltip="Show"
            />
          </RenderIf>
        </Styled.Buttons>
      </Styled.Container>
    );
  }
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const { pdfId, screenshotCreated, pageNumber, name, color } = state.drawings.drawingsInfo[ownProps.pageId];
  return {
    pdfId,
    screenshotCreated,
    pageNumber,
    name,
    color: color || ColorList[6],
    pdfName: state.drawings.files.entities[pdfId].properties.name,
  };
}

export const FileInfo = connect(mapStateToProps)(FileInfoComponent);
