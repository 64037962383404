import { StringDictionary } from 'common/interfaces/dictionary';
import { CommonApi, CompanyResourcesApi } from '../../../api/server';
import { ActivityCategoryType } from '../enums';
import { ActivityCategory, ActivityCategoryCounts } from '../interfaces/data';


function getActivityCategories(): Promise<StringDictionary<ActivityCategory[]>> {
  return CommonApi.get(
    `/cmtdb/activity-categories`,
    `api get database categories error`,
  );
}

function getActivityCategoryCounts(
  databaseId: number,
  type: ActivityCategoryType,
): Promise<ActivityCategoryCounts> {
  return CompanyResourcesApi.get(
    `/databases/${databaseId}/activity-counts?type=${type}`,
    `api get database(${databaseId}) category (${type}) counts error`,
  );
}

export const ActivityCategoriesApi = {
  getActivityCategories,
  getActivityCategoryCounts,
};
