import React from 'react';

import {
  DrawingsSelectionContext,
  DrawingsSelectionContextProps,
  DrawingsSelectionContextProvider,
  DrawingsSelectionContextProviderProps,
} from './drawings-selection-context';

export function connectToDrawingsSelectionContext<P>(
  Component: React.ComponentType<P & DrawingsSelectionContextProps>,
): React.ComponentType<
  Pick<P, Exclude<keyof P, keyof DrawingsSelectionContextProps>> & DrawingsSelectionContextProviderProps
> {
  return class WrappedComponent extends React.PureComponent<P & DrawingsSelectionContextProviderProps> {
    public render(): React.ReactNode {
      return (
        <DrawingsSelectionContextProvider
          onInstancesSelect={this.props.onInstancesSelect}
          onSegmentSelect={this.props.onSegmentSelect}
        >
          <DrawingsSelectionContext.Consumer>
            {
              contextProps => <Component {...this.props} {...contextProps}/>
            }
          </DrawingsSelectionContext.Consumer>
        </DrawingsSelectionContextProvider>
      );
    }
  };
}
