import { useCallback } from 'react';

export function useDoActionAndClose(onClose: () => void): (action: (...args: any) => void, ...args: any) => void {
  return useCallback(
    (action: (...args: any) => void, ...args: any) => {
      onClose();
      action(...args);
    },
    [onClose],
  );
}
