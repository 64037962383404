import * as React from 'react';

export const IconSettings: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M7.55263158,3.03603944 C7.55263158,3.27624808 7.38181657,3.48251165 7.14581646,
      3.52727928 C6.51612517,3.64672746 5.923082,3.89779775 5.40062837,4.26184834 C5.20189999,
      4.40032414 4.93249538,4.37644427 4.76122291,4.2051718 L4.48855187,3.93250076 C4.3350026,
      3.77895148 4.08605003,3.77895148 3.93250076,3.93250076 C3.77895148,4.08605003 3.77895148,
      4.3350026 3.93250076,4.48855187 L4.2051718,4.76122291 C4.37644427,4.93249538 4.40032414,
      5.20189999 4.26184834,5.40062837 C3.89779775,5.923082 3.64672746,6.51612517 3.52727928,
      7.14581646 C3.48251165,7.38181657 3.27624808,7.55263158 3.03603944,7.55263158 L2.94736842,
      7.55263158 C2.70029366,7.55263158 2.5,7.75292524 2.5,8 C2.5,8.24707476 2.70029366,
      8.44736842 2.94736842,8.44736842 L3.03603944,8.44736842 C3.27624808,8.44736842 3.48251165,
      8.61818343 3.52727928,8.85418354 C3.64672746,9.48387483 3.89779775,10.076918 4.26184834,
      10.5993716 C4.40032414,10.7981 4.37644427,11.0675046 4.2051718,11.2387771 L3.93250076,
      11.5114481 C3.77895148,11.6649974 3.77895148,11.91395 3.93250076,12.0674992 C4.08605003,
      12.2210485 4.3350026,12.2210485 4.48855187,12.0674992 L4.76122291,11.7948282 C4.93249538,
      11.6235557 5.20189999,11.5996759 5.40062837,11.7381517 C5.923082,12.1022022 6.51612517,
      12.3532725 7.14581646,12.4727207 C7.38181657,12.5174884 7.55263158,12.7237519 7.55263158,
      12.9639606 L7.55263158,13.0526316 C7.55263158,13.2997063 7.75292524,13.5 8,13.5 C8.24707476,
      13.5 8.44736842,13.2997063 8.44736842,13.0526316 L8.44736842,12.9639606 C8.44736842,
      12.7237519 8.61818343,12.5174884 8.85418354,12.4727207 C9.48387483,12.3532725 10.076918,
      12.1022022 10.5993716,11.7381517 C10.7981,11.5996759 11.0675046,11.6235557 11.2387771,
      11.7948282 L11.5114481,12.0674992 C11.6649974,12.2210485 11.91395,12.2210485 12.0674992,
      12.0674992 C12.2210485,11.91395 12.2210485,11.6649974 12.0674992,11.5114481 L11.7948282,
      11.2387771 C11.6235557,11.0675046 11.5996759,10.7981 11.7381517,10.5993716 C12.1022022,
      10.076918 12.3532725,9.48387483 12.4727207,8.85418354 C12.5174884,8.61818343 12.7237519,
      8.44736842 12.9639606,8.44736842 L13.0526316,8.44736842 C13.2997063,8.44736842 13.5,
      8.24707476 13.5,8 C13.5,7.75292524 13.2997063,7.55263158 13.0526316,7.55263158 L12.9639606,
      7.55263158 C12.7237519,7.55263158 12.5174884,7.38181657 12.4727207,7.14581646 C12.3532725,
      6.51612517 12.1022022,5.923082 11.7381517,5.40062837 C11.5996759,5.20189999 11.6235557,
      4.93249538 11.7948282,4.76122291 L12.0674992,4.48855187 C12.2210485,4.3350026 12.2210485,
      4.08605003 12.0674992,3.93250076 C11.91395,3.77895148 11.6649974,3.77895148 11.5114481,
      3.93250076 L11.2387771,4.2051718 C11.0675046,4.37644427 10.7981,4.40032414 10.5993716,
      4.26184834 C10.076918,3.89779775 9.48387483,3.64672746 8.85418354,3.52727928 C8.61818343,
      3.48251165 8.44736842,3.27624808 8.44736842,3.03603944 L8.44736842,2.94736842 C8.44736842,
      2.70029366 8.24707476,2.5 8,2.5 C7.75292524,2.5 7.55263158,2.70029366 7.55263158,
      2.94736842 L7.55263158,3.03603944 Z M8,1.5 C8.68986005,1.5 9.26699469,1.98263486 9.41216615,
      2.6286668 C9.90636378,2.75827693 10.3783549,2.95544576 10.8162545,3.2136203 C11.3612161,
      2.68134914 12.2344857,2.6852737 12.774606,3.22539398 C13.3147263,3.76551426 13.3186509,
      4.63878385 12.7863797,5.18374552 C13.0445542,5.62164507 13.2417231,6.09363622 13.3713332,
      6.58783385 C14.0173651,6.73300531 14.5,7.31013995 14.5,8 C14.5,8.68986005 14.0173651,
      9.26699469 13.3713332,9.41216615 C13.2417231,9.90636378 13.0445542,10.3783549 12.7863797,
      10.8162545 C13.3186509,11.3612161 13.3147263,12.2344857 12.774606,12.774606 C12.2344857,
      13.3147263 11.3612161,13.3186509 10.8162545,12.7863797 C10.3783549,13.0445542 9.90636378,
      13.2417231 9.41216615,13.3713332 C9.26699469,14.0173651 8.68986005,14.5 8,14.5 C7.31013995,
      14.5 6.73300531,14.0173651 6.58783385,13.3713332 C6.09363622,13.2417231 5.62164507,
      13.0445542 5.18374552,12.7863797 C4.63878385,13.3186509 3.76551426,13.3147263 3.22539398,
      12.774606 C2.6852737,12.2344857 2.68134914,11.3612161 3.2136203,10.8162545 C2.95544576,
      10.3783549 2.75827693,9.90636378 2.6286668,9.41216615 C1.98263486,9.26699469 1.5,
      8.68986005 1.5,8 C1.5,7.31013995 1.98263486,6.73300531 2.6286668,6.58783385 C2.75827693,
      6.09363622 2.95544576,5.62164507 3.2136203,5.18374552 C2.68134914,4.63878385 2.6852737,
      3.76551426 3.22539398,3.22539398 C3.76551426,2.6852737 4.63878385,2.68134914 5.18374552,
      3.2136203 C5.62164507,2.95544576 6.09363622,2.75827693 6.58783385,2.6286668 C6.73300531,
      1.98263486 7.31013995,1.5 8,1.5 Z M8,10.3947368 C6.67742336,10.3947368 5.60526316,
      9.32257664 5.60526316,8 C5.60526316,6.67742336 6.67742336,5.60526316 8,5.60526316 C9.32257664,
      5.60526316 10.3947368,6.67742336 10.3947368,8 C10.3947368,9.32257664 9.32257664,10.3947368 8,
      10.3947368 Z M8,9.39473684 C8.77029189,9.39473684 9.39473684,8.77029189 9.39473684,8 C9.39473684,
      7.22970811 8.77029189,6.60526316 8,6.60526316 C7.22970811,6.60526316 6.60526316,7.22970811 6.60526316,
      8 C6.60526316,8.77029189 7.22970811,9.39473684 8,9.39473684 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
