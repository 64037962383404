import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { CustomDialog } from 'common/components/custom-dialog';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { NewFileUploadWrap } from 'common/components/file-upload-wrap';
import { ConstantFunctions } from 'common/constants/functions';
import { Mood } from 'common/enums/mood';
import { UploadingFile } from 'common/interfaces/common-state';
import { State } from 'common/interfaces/state';
import { useOpenCloseDialog } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { FileExtensionUtils } from 'common/utils/file-extension-utils';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import Form from 'components/form';
import { Common } from '../../../../../../actions';
import { Styled } from './styled';

const UPLOAD_PDF_FORM = 'UPLOAD_PDF_FORM';
const CLOSE_DIALOG_UPLOAD_PDF_DIALOG = 'CLOSE_DIALOG_UPLOAD_PDF_DIALOG';

interface Props {
  onSubmit: (files: UploadingFile[]) => void;
}


const UploadDialogComponent: React.FC<Props> = ({ onSubmit }) => {
  const canConvertFiles = useAbility(Operation.Manage, Subject.TakeOff2dFileConverter);
  const extensions = useMemo(() => {
    return FileExtensionUtils.get2DFileExtensions(false, canConvertFiles);
  }, [canConvertFiles]);

  const [, close] = useOpenCloseDialog(DrawingDialogs.PDF_UPLOAD_DIALOG);
  const files = useSelector<State, UploadingFile[]>((state) => state.common.files);

  const ingoreCancel = useRef(false);

  const dispatch = useDispatch();
  const onCancelClick = useCallback(() => {
    if (ingoreCancel.current) {
      ingoreCancel.current = false;
      return;
    }
    dispatch(Common.commonClearFiles());
    close();
  }, [close, dispatch]);

  const onSubmitClick = useCallback(() => {
    onSubmit(files);
    onCancelClick();
  }, [files, onSubmit, onCancelClick]);

  const canContinue = useMemo(() => files.every(f => !f.error && f.progress === 1), [files]);
  const [openConfirmation] = useOpenCloseDialog(CLOSE_DIALOG_UPLOAD_PDF_DIALOG);

  const onExitCallback = useCallback(() => {
    if (files.length) {
      ingoreCancel.current = true;
      openConfirmation();
    } else {
      onCancelClick();
    }
  }, [files, onCancelClick, openConfirmation]);

  return (
    <DialogWrapper name={DrawingDialogs.PDF_UPLOAD_DIALOG}>
      <ConfirmationDialog
        name={CLOSE_DIALOG_UPLOAD_PDF_DIALOG}
        title='Are you sure you want to exit the "Upload files" Mode?'
        text='This action cannot be undone'
        onConfirm={onCancelClick}
        confirmButtonText='Exit'
      />
      <Form handleSubmit={ConstantFunctions.doNothing}>
        <Styled.Container>
          <CustomDialog
            onExit={onExitCallback}
            width={400}
            height={560}
            buttonsHeight={40}
            cancelButtonWidth={160}
            submitButtonWidth={160}
            submitButtonMood={canContinue ? Mood.Secondary : Mood.Disabled}
            onSubmit={files?.length ? onSubmitClick : null}
            onReject={files?.length ? onCancelClick : null}
            padding={'30px'}
            submitText='Continue'
          >
            <NewFileUploadWrap
              formName={UPLOAD_PDF_FORM}
              extensions={extensions}
              extensionsToShow={extensions}
            />
          </CustomDialog>
        </Styled.Container>
      </Form>
    </DialogWrapper>
  );
};

export const UploadDialog = reduxForm<{}, Props>({
  form: UPLOAD_PDF_FORM,
  destroyOnUnmount: true,
})(UploadDialogComponent);
