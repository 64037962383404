import * as paper from 'paper';

type ObserverMethod<T> = (context: T) => void;

export class DrawingContextObserver<T> {
  protected context: T;
  protected observers: Array<ObserverMethod<T>> = [];

  constructor(context: T) {
    this.context = context;
  }

  public getContext(): T {
    return this.context;
  }

  public updateContext(context: T): void {
    if (this.context === context) {
      return;
    }
    this.context = context;
    this.updateView();
  }

  public subscribe(observer: ObserverMethod<T>, ingnoreFirst?: boolean): void {
    this.observers.push(observer);
    if (!ingnoreFirst) {
      observer(this.context);
    }
  }

  public unsubscribe(observer: ObserverMethod<T>): void {
    this.observers = this.observers.filter(x => x !== observer);
  }

  protected updateView(): void {
    paper.view.autoUpdate = false;
    this.observers.forEach(x => x(this.context));
    paper.view.update();
    paper.view.autoUpdate = true;
  }
}
