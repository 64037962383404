import styled, { css } from 'styled-components';

export interface TextProps {
  fontWeight?: number;
  color?: string;
  customColor?: string;
  nonSelect?: boolean;
  textAlign?: string;
  nowrap?: boolean;
  lineHeight?: number;
  fontSize?: number;
  textTransform?: string;
  wordBreak?: string;
  overflow?: string;
  withEllipsis?: boolean;
  letterSpacing?: number;
}

const GenericText = css<TextProps>`
  ${p => p.fontWeight && `font-weight: ${p.fontWeight};`}
  color: ${props =>
    props.customColor
      ? props.customColor
      : props.theme.color[props.color] || props.theme.color.mainFont};
  user-select: ${props => (props.nonSelect ? 'none' : 'auto')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'initial')};
  white-space: ${props => (props.nowrap ? 'nowrap' : 'normal')};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px` : 'normal'};
  text-transform: ${p => (p.textTransform ? p.textTransform : 'none')};
  word-break: ${p => (p.wordBreak ? p.wordBreak : 'normal')};
  overflow: ${p => (p.overflow || 'none')};
  word-wrap: break-word;
  ${p => p.letterSpacing ? `letter-spacing: ${p.letterSpacing}px;` : ''}

  ${p =>
    p.withEllipsis ?
    `
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    ` : ''
  }
`;

export const H1 = styled.h1`
  ${GenericText}
  font-size: 60px;
  line-height: 75px;
`;

export const H2 = styled.h2`
  ${GenericText}
  font-size: 52px;
  line-height: 78px;
`;

export const H3 = styled.h3`
  ${GenericText}
  font-size: 36px;
  line-height: 54px;
`;

export const H4 = styled.h4`
  ${GenericText}
  font-size: 30px;
  line-height: 45px;
`;

export const H5 = styled.h5`
  ${GenericText}
  font-size: 22px;
  line-height: 33px;
`;

export const H6 = styled.h6`
  ${GenericText}
  font-size: 18px;
  line-height: 27px;
`;


function getLineHeight(text: TextProps): number {
  if (text.lineHeight) {
    return text.lineHeight;
  }
  if (text.fontSize) {
    return Math.ceil(text.fontSize * 1.5);
  }
  return 24;
}

export const Text = styled.p`
  ${GenericText}
  font-size: ${props => props.fontSize || 16}px;
  line-height: ${getLineHeight}px;
`;

export const TinyText = styled.p`
  ${GenericText}
  font-size: 12px;
  line-height: 18px;
`;
