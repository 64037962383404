import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div<{ showTooltip?: boolean }>`
  min-width: 15px;
  height: 15px;
  padding: 0px 4px;
  background-color: ${p => p.theme.color.turquoise};
  width: fit-content;
  box-sizing: border-box;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -6px;
  animation: scale-up-bl 0.35s both;
  cursor: ${p => p.showTooltip ? 'pointer' : 'inherit'};

  @keyframes scale-up-bl {
    0% {
      transform: scale(0);
      transform-origin: 0% 100%;
    }
    75% {
      transform: scale(1.1);
      transform-origin: 0% 100%;
    }
    100% {
      transform: scale(1);
      transform-origin: 0% 100%;
    }
  }

  ${Text} {
    margin: auto;
    padding-top: 1px;
  }
`;

export const Styled = {
  Container,
};

