import * as React from 'react';

import { ElementsContent, IssuesContent, ScrollToType } from './errors-content';
import { LeftPanelLayout } from './layouts';

interface Props {
  selectedIssues: number;
  onIssueSelect: (order: number) => void;
  onIssueDeselect: () => void;
  onSelectBimHandle: (id: number) => void;
  onSecondHandleSelect: (id: number) => void;
  saveScrollApi: (scrollApi: ScrollToType) => void;
}

export const Errors: React.FC<Props> = props => {
  const hasSelected = props.selectedIssues !== null;
  return (
    <LeftPanelLayout hasSelected={hasSelected}>
      <IssuesContent
        selectedIssues={props.selectedIssues}
        onIssueSelect={props.onIssueSelect}
        onDeselect={props.onIssueDeselect}
      />
      {hasSelected && (
        <ElementsContent
          saveScrollApi={props.saveScrollApi}
          onSecondHandleSelect={props.onSecondHandleSelect}
          onSelectBimHandle={props.onSelectBimHandle}
        />
      )}
    </LeftPanelLayout>
  );
};
