import { DrawingsInstanceMeasure } from 'common/components/drawings';
import { MoveToCellOptionType } from 'common/components/drawings/drawings-canvas-menus';
import { getMeasure } from '../get-measure';

const functionSeparator = ',';

const getValue = (instancesMeasures: DrawingsInstanceMeasure[], type: string): string => {
  return `SUM(${instancesMeasures.map(i => getMeasure(i, type)).join(functionSeparator)})`;
};

const validType = new Set([
  MoveToCellOptionType.area,
  MoveToCellOptionType.length,
  MoveToCellOptionType.perimeter,
  MoveToCellOptionType.count,
  MoveToCellOptionType.pointsCount,
  MoveToCellOptionType.segmentsCount,
  MoveToCellOptionType.volume,
  MoveToCellOptionType.verticalArea,
]);

export const getMoveToCellGroupValue = (type: string, instancesMeasures: DrawingsInstanceMeasure[]): string => {
  const isValid = validType.has(type as MoveToCellOptionType);
  return isValid
    ? getValue(instancesMeasures, type)
    : '';
};
