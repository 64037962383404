import { InputSelect } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { AnnotationFilters } from '../../../interfaces/drawing-filters';
import { Styled } from './styled';

interface Element {
  name: string;
  value: string;
}

interface Props {
  elements: Element[];
  selected: string;
  filterType: AnnotationFilters;
  changeFilterData: (filterType: AnnotationFilters, value: string) => void;
  getDropdownMenuPosition?: (isOpen: boolean) => void;
}

interface State {
  selectOptions: string[];
}

export class SelectFilter extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectOptions: this.props.elements.map(x => x.name),
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.elements !== prevProps.elements) {
      this.setState(
        {
          selectOptions: this.props.elements.map(x => x.name),
        },
      );
    }
  }

  public render(): React.ReactNode {
    return (
      <Styled.Container>
        <InputSelect
          elements={this.state.selectOptions}
          closeAfterClick={true}
          activeElementIndex={this.getSelectedIndex()}
          onClick={this.onClick}
          onDropdownOpenChanged={this.props.getDropdownMenuPosition}
        />
      </Styled.Container>
    );
  }

  @autobind
  private onClick(index: number): void {
    this.props.changeFilterData(this.props.filterType, this.props.elements[index].value);
  }

  private getSelectedIndex(): number {
    return this.props.elements.findIndex(e => e.value === this.props.selected);
  }
}
