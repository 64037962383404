import { Property } from '../../../../interfaces';


export enum FieldType {
  TextInput = 'TextInput',
  NumberInput = 'NumberInput',
  Button = 'Button',
  SingleSelect = 'SingleSelect',
}

export interface DropDownProps {
  elements: string[];
  activeElementIndex: number;
  onClick: (index: number) => void;
  value: string;
  onBlur: (unit: string) => void;
}

export interface Field<TInput> {
  id: string;
  name: string;
  type: FieldType;
  input: TInput;
  originId?: string;
  dropDownProps?: DropDownProps;
  isHide?: boolean;
  error?: string;
  onDeleteClick?: () => void;
  onVisibilityClick?: () => void;
  getDataUpdate: (field: Field<TInput>) => Property;
}
