import { InputWithSlider } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { useEditStyle } from 'common/components/drawings/hooks';
import { DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import { arrayUtils } from 'common/utils/array-utils';
import { PropertyWithMenuEditor } from '../property-menu-editor';
import { Styled } from './styled';


interface Props {
  instances: Record<string, DrawingsGeometryInstance>;
  values: Record<string, string[]>;
  canEdit: boolean;
}

const StrokeWidthPropertyComponent: React.FC<Props> = ({
  values,
  instances,
  canEdit,
}) => {
  const editStrokeWidth = useEditStyle('strokeWidth', instances);

  const changeStrokeWidth = useCallback((value: number): void => {
    const instancesIds = Object.values(values).reduce((acc, v) => {
      arrayUtils.extendArray(acc, v);
      return acc;
    }, []);
    editStrokeWidth(instancesIds, value);
  }, [editStrokeWidth, values]);

  const editorRenderer = useCallback(() => {
    const keys = Object.keys(values);
    const value = Number(keys[0]);
    return (
      <Styled.InputWithSliderWrapper>
        <InputWithSlider
          min={1}
          max={10}
          onChange={changeStrokeWidth}
          value={value}
          type='Stroke width, px'
        />
      </Styled.InputWithSliderWrapper>
    );
  }, [values, changeStrokeWidth]);

  const getElementLabel = useCallback((property: string) => {
    return `${property} px`;
  }, []);

  return (
    <PropertyWithMenuEditor
      name='Stroke'
      elements={values}
      defaultValue='Mixed'
      editorRenderer={canEdit ? editorRenderer : undefined}
      getElementLabel={getElementLabel}
    />
  );
};

export const StrokeWidthProperty = React.memo(StrokeWidthPropertyComponent);
