import * as Ag from 'ag-grid-community';
import { useEffect, useMemo, useRef } from 'react';


import { TransactionGenerator } from '2d/components/2d-element-view/utils';
import { PiaBreakdownNode } from '../../side-panel/components/property-panel/forms/tree-form/interfaces';
import { getRow } from '../../side-panel/components/property-panel/forms/tree-form/utils/get-row';

type UseTransactionEffect = (options: PiaBreakdownNode[], gridApi: Ag.GridApi) => void;

export const useTransactionEffect: UseTransactionEffect = (options, gridApi) => {
  const transactionGeneratorRef = useRef(new TransactionGenerator());
  const rowDataMap = useMemo(() => {
    const map = {};
    let nextCode = 1;
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      nextCode = option.code
        ? Number(option.code)
        : nextCode;
      const rowsData = getRow(option, nextCode, i);
      nextCode++;
      rowsData.forEach(r => {
        map[r.id] = r;
      });
    }
    return map;
  }, [options]);
  useEffect(() => {
    if (!gridApi) {
      return;
    }

    const transaction = transactionGeneratorRef.current.getTransaction(rowDataMap);
    if (transaction) {
      gridApi.applyTransaction(transaction);
      gridApi.refreshCells();
      gridApi.resetRowHeights();
    }
  }, [rowDataMap, gridApi]);
};
