import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';

import { DrawingsFile } from 'common/components/drawings/interfaces/drawings-file-info';
import { State } from 'common/interfaces/state';
import { Styled } from './styled';

interface StateProps {
  name: string;
  pdfName: string;
}

interface OwnProps {
  drawingId: string;
}

interface Props extends OwnProps, StateProps {

}

const PageComponent: React.SFC<Props> = ({ name, pdfName }) => {
  return (
    <Styled.Container>
      <Text>
        {pdfName}, {name}
      </Text>
    </Styled.Container>
  );
};


function mapStateToProps(state: State, { drawingId }: OwnProps): StateProps {
  const drawing = state.drawings.drawingsInfo[drawingId];
  if (drawing) {
    const { name, pdfId } = drawing;
    return {
      name,
      pdfName: (state.drawings.files.entities[pdfId] as DrawingsFile).properties.name,
    };
  }
  return {
    name: '',
    pdfName: '',
  };
}


export const PageInfo = connect(mapStateToProps)(PageComponent);
