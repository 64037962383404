import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


export const STICKER_BACKGROUND_DEFAULT = '#FFF799';

const Container = styled.div<{ selected: boolean, grabbed: boolean }>`
  position: absolute;
  pointer-events: all;
  position: absolute;
  background-color: ${STICKER_BACKGROUND_DEFAULT};
  border-radius: 10px;
  cursor: ${p => p.grabbed ? 'grabbing' : p.selected ? 'grab' : 'pointer'};

  width: 30px;
  height: 30px;
  display: flex;
  svg {
    margin: auto;
    width: 20px;
    height: 20px;
  }

  box-shadow: ${p => p.selected ?
    `0 0 0 2px ${Constants.Colors.GENERAL_COLORS.turquoiseDay};`
    : '0 3px 6px #00000029;'
  };
  :hover {
    box-shadow: ${p => p.selected ?
      `0 0 0 2px ${Constants.Colors.GENERAL_COLORS.turquoiseDay};`
    : 'rgba(0, 0, 0, 0.2) 0px 15px 30px -6px, rgba(0, 0, 0, 0.3) 0px 9px 18px -9px;'
    };
  }
`;

const Textarea = styled.textarea`
  resize: none;
  outline: none;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-style: none;
  border-color: Transparent;
  color: ${Constants.Colors.GENERAL_COLORS.darkBlue};
  font-weight: 500;
`;

const TextContainer = styled.div<{ isPlaceholder: boolean }>`
  height: calc(100% - 40px);
  overflow: hidden;
  p {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    font-weight: 500;
    height: 100%;
    opacity: ${props => props.isPlaceholder ? 0.5 : 1};
  }
`;

const Content = styled.div`
  display: flex;
`;

export const Styled = {
  Container,
  Textarea,
  TextContainer,
  Content,
};
