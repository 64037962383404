import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 165px;
    height: 20px;
    margin: 0 30px;
  }

  p {
    font-size: 10px;
    line-height: 14px;
  }

  svg {
    width: 30px;
    height: 20px;
    margin: 0;
  }
`;

export const Styled = {
  Container,
};
