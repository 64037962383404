import autobind from 'autobind-decorator';
import * as classnames from 'classnames';
import { some } from 'lodash';
import * as React from 'react';

import { TCollapseMini, TExpandMini } from '../../icons';
import { ActivityCategory } from '../../units/databases/interfaces/data';

import './tree-view-item.scss';

export interface Item {
  title: string;
  code: string;
  children: Item[];
  data: ActivityCategory;
  id: number;
}

interface Props {
  item: Item;
  filter: string;
  level: number;
  onClick?: (data: ActivityCategory) => void;
  onDoubleClick?: () => void;
  number: number;
  isLastItem: boolean;
  isParentLastItem: boolean;
  isOnlyLeavesSelect?: boolean;
  selectedCode: string;
}

interface State {
  expanded: boolean;
  isMatch: boolean;
  selectedCode: string;
}

const FILTER_LENGTH_TO_EXPAND = 3;

const isMatchFilter = (item: Item, filter: string): boolean => {
  const words = filter.toLowerCase().split(' ');
  const codeAndName = `${item.code} ${item.title}`;
  let match = words.every((word) => codeAndName.toLowerCase().includes(word));

  if (!match && item.children) {
    item.children.map((x) => {
      if (!match) {
        match = isMatchFilter(x, filter);
      }
    });
  }

  return match;
};

const shouldBeExpanded = (item: Item, filter: string): boolean => {
  if (!filter || filter.length < FILTER_LENGTH_TO_EXPAND) {
    return false;
  }

  return isMatchFilter(item, filter);
};

const isExpanded = (item: Item, code: string): boolean => {
  if (
    item.code === code ||
    (item.children && some(item.children, (x) => isExpanded(x, code)))
  ) {
    return true;
  }
  return false;
};

export class TreeViewItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const isMatch = props.filter && shouldBeExpanded(props.item, props.filter);
    this.state = {
      isMatch,
      expanded: isMatch || isExpanded(props.item, props.selectedCode),
      selectedCode: props.selectedCode,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.filter) {
      const isMatch = shouldBeExpanded(props.item, props.filter);
      return {
        isMatch: props.filter.length < FILTER_LENGTH_TO_EXPAND ? true : isMatch,
        expanded: isMatch,
        selectedCode: props.selectedCode,
      };
    } else if (props.selectedCode !== state.selectedCode) {
      return {
        ...state,
        isMatch: false,
        selectedCode: props.selectedCode,
      };
    }
    return { ...state, isMatch: false };
  }

  @autobind
  public collapse(): void {
    if (this.state.expanded) {
      this.setState({
        expanded: false,
      });
    }
  }

  public render(): JSX.Element {
    const {
      item,
      level,
      filter,
      number,
      isLastItem,
      isOnlyLeavesSelect,
      selectedCode,
    } = this.props;
    const { expanded, isMatch } = this.state;
    const lines = this.buildLines();
    if (!isMatch && (filter && filter !== '')) {
      return null;
    }

    const className = classnames('tree-view-node__root', {
      'tree-view-node__root--selected': this.props.item.code === selectedCode,
    });

    return (
      <div className='tree-view-node'>
        <div className={className}>
          {lines}
          {this.getContent(lines.length)}
        </div>
        {expanded && item.children && item.children.length > 0 ? (
          <div className='tree-view-node__children'>
            {item.children.map((x, i) => (
              <TreeViewItem
                key={`${number}.${i}`}
                number={i}
                filter={filter}
                item={x}
                level={level + 1}
                onClick={this.props.onClick}
                isLastItem={item.children.length === i + 1}
                isParentLastItem={isLastItem}
                isOnlyLeavesSelect={isOnlyLeavesSelect}
                onDoubleClick={this.props.onDoubleClick}
                selectedCode={selectedCode}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }

  private buildLines(): JSX.Element[] {
    const { level, item, isLastItem, isParentLastItem } = this.props;
    const { expanded } = this.state;
    const lines = [];
    const isLeaf = !item.children || item.children.length === 0;
    for (let i = 0; i < level; i++) {
      const islevel = i === level - 1;
      const lineClasses = classnames('line', {
        empty: isParentLastItem && !islevel && i !== 0,
        first: islevel,
        finish: islevel && isLastItem && level > 1,
      });
      lines.push(<div key={i} className={lineClasses} />);
    }

    if (level === 0 || expanded) {
      const lastClasses = classnames('line', 'last', {
        leaf: isLeaf,
        finish: isLastItem,
        level0: level === 0,
      });
      lines.push(<div key={lines.length} className={lastClasses} />);
    }
    return lines;
  }

  private getContent(linesCount: number): JSX.Element {
    const { item, filter, level } = this.props;
    const title = item.title;
    let displayValue = item.title;
    let displayCode = item.code.toString();

    if (filter) {
      const words = filter.toLowerCase().replace(' ', '|');
      const wordRegex = new RegExp(`(${words})`, 'ig');
      displayValue = displayValue.replace(wordRegex, '<b>$1</b>');
      displayCode = displayCode.replace(wordRegex, '<b>$1</b>');
    }

    return (
      <div
        className='tree-view-node__root-container'
        style={{
          width: `calc(100% - ${level === 0 ? 0 : linesCount * 29}px - 8px)`,
        }}
      >
        {item.children && item.children.length > 0 && (
          <div
            className='tree-view-node__root-container-link'
            onClick={this.toggleExpand}
            data-control-name='tree-view-node-expand-control'
          >
            <img src={this.state.expanded ? TExpandMini : TCollapseMini} />
          </div>
        )}
        <div
          onClick={this.onClick}
          onDoubleClick={this.props.onDoubleClick}
          title={title}
          className='tree-view-node__root-container-content'
          data-control-name='tree-view-node-content'
        >
          <div
            className='tree-view-node__root-container-content-code'
            dangerouslySetInnerHTML={{ __html: displayCode }}
          />
          <div
            className='tree-view-node__root-container-content-name'
            dangerouslySetInnerHTML={{
              __html: displayValue,
            }}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onClick(e: any): void {
    e.stopPropagation();
    const { isOnlyLeavesSelect, selectedCode, item } = this.props;
    if (isOnlyLeavesSelect && item.children && item.children.length !== 0) {
      this.setState({
        expanded: !this.state.expanded,
      });
      return;
    }

    if (this.props.onClick) {
      if (selectedCode === item.code) {
        this.setState({
          expanded: !this.state.expanded,
        });
      }
      this.props.onClick(item);
    }
  }

  @autobind
  private toggleExpand(): void {
    const children = this.props.item.children;
    if (children && children.length > 0) {
      this.setState({
        expanded: !this.state.expanded,
      });
    }
  }
}
