import autobind from 'autobind-decorator';
import * as React from 'react';

import './resources-chart-container.scss';

import { mathUtils } from 'common/utils/math-utils';
import {
  ChartDataProvider,
  ResourceType,
} from '../../../utils/gantt-chart';
import { ganttChartContants } from '../utils/constants';
import { OverlayTimeSelector } from './overlay-time-selector';
import { ResourcesChartBody } from './resources-chart-body';
import { ResourcesChartLeftSide } from './resources-chart-left-side';

interface Props {
  dataProvider: ChartDataProvider;
  chartWidth: number;
  timeframeDuration: number;
  timeframeStartDay: number;
  timelineDuration: number;
  projectStartDate: Date;
  updateTimeframe: (startDay: number, duration: number) => void;
}

interface State {
  selectedResourceType: ResourceType;
  selectedResourceId: number;
  pixelPerDay: number;
}

class ResourcesChartContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedResourceType: ResourceType.Labour,
      selectedResourceId: -7,
      pixelPerDay: (this.props.chartWidth - ganttChartContants.leftPanelWidth) / this.props.timeframeDuration,
    };
  }

  public static getDerivedStateFromProps(props: Props): Partial<State> {
    return { pixelPerDay: (props.chartWidth - ganttChartContants.leftPanelWidth) / props.timeframeDuration };
  }

  public render(): React.ReactNode {
    return (
      <OverlayTimeSelector
        className='gantt-resources-chart-container'
        isCursorOnTimeframeHandle={false}
        onCompleteSelection={this.onCompleteSelection}
        clampStartPoint={this.clamStartPointMethod}
        isResizeAffterSelectionComplete={true}
        isSelectInValidArea={this.isSelectInValidArea}
      >
        <div className='gantt-resources-chart-container__chart'>
          <ResourcesChartLeftSide
            selectedResourceId={this.state.selectedResourceId}
            selectResources={this.selectIds}
          />
          <ResourcesChartBody
            projectStartDate={this.props.projectStartDate}
            dataProvider={this.props.dataProvider}
            resourceType={this.state.selectedResourceType}
            selectedResourceId={this.state.selectedResourceId}
            chartWidth={this.props.chartWidth}
          />
        </div>
      </OverlayTimeSelector>
    );
  }

  private isSelectInValidArea(offset: number): boolean {
    return offset - ganttChartContants.leftPanelWidth >= 0;
  }

  @autobind
  private selectIds(selectedResourceId: number, selectedResourceType: ResourceType): void {
    this.setState({ selectedResourceId, selectedResourceType });
  }

  @autobind
  private onCompleteSelection(startOffset: number, width: number): void {
    const newDuration = mathUtils.clamp(
      width / this.state.pixelPerDay,
      ganttChartContants.minTimeframeDuration,
      this.props.timelineDuration,
    );
    const startDay =
      this.props.timeframeStartDay + (startOffset - ganttChartContants.leftPanelWidth) / this.state.pixelPerDay;
    this.props.updateTimeframe(startDay, newDuration);
  }

  @autobind
  private clamStartPointMethod(offset: number): number {
    return mathUtils.clamp(
      offset,
      ganttChartContants.leftPanelWidth,
      this.props.chartWidth,
    );
  }
}


export { ResourcesChartContainer };
