import { _ as Utils } from 'ag-grid-community';
// eslint-disable-next-line import/no-unresolved
import { FillOperationParams } from 'ag-grid-community/dist/lib/entities/gridOptions';

import { ValueHelper } from 'common/utils/value-helper';
import { CellFillPatternHelperFactory } from './cell-fill-pattern-heleper-factory';
import { CellFillHelper, PatternHelper } from './interfaces';


const getReferenceValues = (params: FillOperationParams): Array<string | number> | null => {
  const { values, initialValues } = params;
  const allValues = initialValues.concat(values);
  return allValues.splice(allValues.length - initialValues.length).map(x => x && x.type ? x.data : x);
};

const getNumberSequence = (values: Array<string | number>, patternHelper: PatternHelper | null): number[] => {
  if (!patternHelper) {
    return [];
  }

  const result: number[] = [];
  for (const value of values) {
    const sequenceNumber = patternHelper.getSequenceNumberFromPattern(value);
    if (!ValueHelper.isNumberValue(sequenceNumber)) {
      break;
    }

    result.push(sequenceNumber);
  }

  return result;
};

const getNextValue = (_params: FillOperationParams, referencedValues: Array<string | number>): number | string => {
  const firstValue = referencedValues[0];

  const patternHelper = CellFillPatternHelperFactory.getFillPatternHelper(firstValue);
  const numberSequence = getNumberSequence(referencedValues, patternHelper);
  if (numberSequence.length) {
    const nextNumber = Utils.last(Utils.findLineByLeastSquares(numberSequence));
    return patternHelper.getPatternValue(nextNumber);
  } else {
    return firstValue;
  }
};

export const SequenceCellFill: CellFillHelper<string | number> = {
  getReferenceValues,
  getNextValue,
};
