const prefix = '@activity-grouping-engine';

export const EngineActionTypes = {
  SET_COLORED_BIM_ELEMENTS: `${prefix}/SET_COLORED_BIM_ELEMENTS`,
  RESET_COLORED_BIM_ELEMENTS: `${prefix}/RESET_COLORED_BIM_ELEMENTS`,
  SET_SELECTED_BIM_ELEMENTS: `${prefix}/SET_SELECTED_BIM_ELEMENTS`,
  SET_AVAILABLE_BIM_ELEMENTS: `${prefix}/SET_AVAILABLE_BIM_ELEMENTS`,
  SET_DEFAULT_AVAILABLE_BIM_ELEMENTS: `${prefix}/SET_DEFAULT_AVAILABLE_BIM_ELEMENTS`,
  SET_HIGHLIGHTED_BIM_ELEMENTS: `${prefix}/SET_HIGHLIGHTED_BIM_ELEMENTS`,
  RESET_ENGINE: `${prefix}/RESET_ENGINE`,
};
