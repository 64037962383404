import * as t from 'io-ts';
import { ActivityAssignmentMaterialVariantC } from './activity-assignment-material-variant';

export const ActivityAssignmentSubVariantC = t.exact(
  t.intersection([
    t.type({
      name: t.string,
      laborHours: t.number,
      dbModalityId: t.number,
    }),
    t.partial({
      dataBaseId: t.number,
      rootActivityId: t.number,
      materials: t.array(ActivityAssignmentMaterialVariantC),
    }),
  ]),
  'ActivityAssignmentSubVariant');

export type ActivityAssignmentSubVariant = t.TypeOf<typeof ActivityAssignmentSubVariantC>;
