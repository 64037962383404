import styled from 'styled-components';

const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;

const DoubleInputContainer = styled.div`
`;

export const Styled = {
  DoubleInputContainer,
  InputsContainer,
};
