import React from 'react';

import { AggregationGroup } from '../aggregation-group';
import { Styled } from './styled';

interface Props {
  instancesIds: string[];
  color: string;
}

const ColorGroupComponent: React.FC<Props> = ({
  instancesIds,
  color,
}) => {
  return (
    <AggregationGroup instancesIds={instancesIds}>
      <Styled.Value color={color} />
    </AggregationGroup>
  );
};

export const ColorGroup = React.memo(ColorGroupComponent);
