import React, { useEffect, useRef } from 'react';

interface Props {
  value: string;
  focus?: boolean;
}

const UserMentionComponent: React.FC<Props> = ({ value, focus }) => {
  const ref = useRef<HTMLSpanElement>();
  useEffect(() => {
    if (ref.current && focus) {
      const containerRect = ref.current.getBoundingClientRect();
      const scrollViewRect = ref.current.parentElement.parentElement.getBoundingClientRect();
      if (scrollViewRect.top > containerRect.top) {
        ref.current.scrollIntoView({ inline: 'start' });
      } else if (scrollViewRect.bottom < containerRect.bottom) {
        ref.current.scrollIntoView({ inline: 'end' });
      }
    }
  }, [focus, ref.current]);

  return (
    <span
      className="mention-input__suggestions__item__display"
      ref={ref}
    >
      {value}
    </span>
  );
};

export const UserMention = React.memo(UserMentionComponent);
