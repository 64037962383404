import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { KreoDialogActions } from 'common/UIKit';

import { BillingHistoryDialog, BILLING_HISTORY_DIALOG } from './billing-history-dialog';
import { Styled } from './styled';


interface DispatchProps {
  openDialog: () => void;
}

interface Props extends DispatchProps {

}

class BillingHistoryButtonComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { openDialog } = this.props;

    return (
      <>
        <Styled.Container
          color='additionalFont'
          onClick={openDialog}
        >
          <Icons.BillingHistory />
          Billing history
        </Styled.Container>
        <BillingHistoryDialog />
      </>
    );
  }
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    openDialog: () => dispatch(KreoDialogActions.openDialog(BILLING_HISTORY_DIALOG)),
  };
}

export const BillingHistoryButton = connect(null, mapDispatchToProps)(BillingHistoryButtonComponent);
