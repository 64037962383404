import { SagaIterator, Task } from 'redux-saga';
import { call, fork, join, put, take, takeLatest } from 'redux-saga/effects';
import uuid from 'uuid';

import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { NotificationActions } from '../../../units/notifications/actions';
import { AlertType } from '../../../units/notifications/alert-type';
import { QuantityTakeOffFilterActions } from '../actions/creators/quantity-take-off-filter';
import { QuantityTakeOffReportActions } from '../actions/creators/quantity-take-off-report';
import { QuantityTakeOffTemplateActions } from '../actions/creators/quantity-take-off-template';
import {
  BaseReportPayload,
  CopyReportPayload,
  CreateReportPayload,
  FormWithProjectAndReportIdsPayload,
  GenerateDefaultReportPayload,
  ProjectAndReportIdsPayload,
  UpdateReportQueueMessagePayload,
} from '../actions/payloads/quantity-take-off-report';
import { QuantityTakeOffReportActionTypes } from '../actions/types/quantity-take-off-report';
import { QuantityTakeOffReportApi } from '../api/quantity-take-off-report';
import {
  CreateQtoTreeRowsForm,
  ModelType,
  QtoReportInfo,
  QtoReportInfoForm,
  QtoTreeColumnsForm,
  QtoTreeRowForm,
  ReorderQtoTreeRowsForm,
} from '../interfaces/quantity-take-off';
import { QtoReportPivotTableMapper } from '../utils/quantity-take-off-tree-table';

export const getEmptyModel = (name: string): any => {
  const defaultRowId = uuid.v4();
  return {
    basicColumns: {
      columns: {},
      firstLevelColumns: [],
    },
    pivotColumns: QtoReportPivotTableMapper.getDefaultPivotColumnsForm(),
    firstLevelRows: [defaultRowId],
    rows: {
      [defaultRowId]: {
        id: defaultRowId,
        type: TreeTableRowType.Group,
        properties: { 'name': { default: 'New Group' } },
        children: [],
        parentId: null,
      },
    },
    name,
  };
};

function* generateDefaultReport(action: ActionWith<GenerateDefaultReportPayload>): SagaIterator {
  try {
    const { projectId, modelType, type } = action.payload;
    const { id } = yield call(QuantityTakeOffReportApi.generateDefaultReport, projectId, modelType, type);
    yield put(QuantityTakeOffReportActions.loadReportsInfo(projectId, modelType));
    yield put(QuantityTakeOffReportActions.setSelectedReportId(id));
    yield put(QuantityTakeOffReportActions.createReportFinish());
    yield put(QuantityTakeOffReportActions.loadAvailableReportGenerators(projectId, modelType));
  } catch (error) {
    console.error('quantity take off reports: generate default report failed', error, action.payload);
  }
}

function* getAvailableReportGenerators({ payload }: ActionWith<BaseReportPayload>): SagaIterator {
  try {
    const { projectId, modelType } = payload;
    const defaultReportTypes = yield call(QuantityTakeOffReportApi.getAvailableReportGenerators, projectId, modelType);
    yield put(QuantityTakeOffReportActions.setAvailableReportGenerators(defaultReportTypes));
  } catch (error) {
    console.error('quantity take off reports: get available report generators failed', error);
  }
}

function* getReportsInfo({ payload }: ActionWith<BaseReportPayload>): SagaIterator {
  try {
    const { projectId, modelType } = payload;
    const reports = yield call(QuantityTakeOffReportApi.getReports, projectId, modelType);
    yield put(QuantityTakeOffReportActions.setReportsInfo(reports));
  } catch (error) {
    console.error('quantity take off reports: get reports info failed', error);
  }
}

function* getInitialState({ payload }: ActionWith<BaseReportPayload>): SagaIterator {
  try {
    const { projectId, modelType } = payload;
    const reports = yield call(QuantityTakeOffReportApi.getReports, projectId, modelType);
    yield put(QuantityTakeOffReportActions.setReportsInfo(reports));
    if (reports.length) {
      yield put(QuantityTakeOffReportActions.setSelectedReportId(reports[0].id));
    } else if (modelType === ModelType.QuantityTakeOff) {
      const model = getEmptyModel('New Report');
      const { id } = yield call(QuantityTakeOffReportApi.createReport, projectId, modelType, model);
      yield put(QuantityTakeOffReportActions.setSelectedReportId(id));
      yield put(QuantityTakeOffReportActions.loadReportsInfo(projectId, modelType));
    }
  } catch (error) {
    console.error('quantity take off reports: get reports info failed', error);
  }
}

function* getAvailableReportForCopy({ payload: modelType }: ActionWith<ModelType>): SagaIterator {
  try {
    const projectsReports = yield call(QuantityTakeOffReportApi.getAvailableReportForCopy, modelType);
    yield put(QuantityTakeOffReportActions.setReportsForCopy(projectsReports));
  } catch (error) {
    console.error('quantity take off report: get reports for copy', error);
  }
}

function* createReport({ payload }: ActionWith<CreateReportPayload>): SagaIterator {
  try {
    const { projectId, modelType, model } = payload;
    const { id } = yield call(QuantityTakeOffReportApi.createReport, projectId, modelType, model);
    yield put(QuantityTakeOffReportActions.setSelectedReportId(id));
    yield put(QuantityTakeOffReportActions.loadReportsInfo(projectId, modelType));
  } catch (error) {
    console.error('quantity take off reports: create report failed', error, payload);
  }
}

function* publishReport({ payload }: ActionWith<ProjectAndReportIdsPayload>): SagaIterator {
  try {
    const { projectId, modelType, reportId } = payload;
    yield call(QuantityTakeOffReportApi.publishReport, projectId, modelType, reportId);
  } catch (error) {
    console.error('quantity take off reports: publish report failed', error, payload);
  }
}

function* getReport({ payload }: ActionWith<ProjectAndReportIdsPayload>): SagaIterator {
  try {
    const { projectId, modelType, reportId } = payload;
    const report = yield call(QuantityTakeOffReportApi.getReport, projectId, modelType, reportId);
    yield put(QuantityTakeOffReportActions.setReport(report));
  } catch (error) {
    console.error('quantity take off reports: get report failed', error, payload);
  }
}

function* deleteReport({ payload }: ActionWith<ProjectAndReportIdsPayload>): SagaIterator {
  try {
    const { projectId, modelType, reportId } = payload;
    yield call(QuantityTakeOffReportApi.deleteReport, projectId, modelType, reportId);
    if (modelType !== ModelType.PublishedReports) {
      yield put(QuantityTakeOffReportActions.loadAvailableReportGenerators(projectId, modelType));
    }
  } catch (error) {
    console.error('quantity take off reports: delete report failed', error, payload);
  }
}

function* updateReportInfo(
  { payload }: ActionWith<FormWithProjectAndReportIdsPayload<QtoReportInfoForm>>,
): SagaIterator {
  try {
    const { projectId, modelType, reportId, form } = payload;
    const reportInfo = yield call(QuantityTakeOffReportApi.updateReportInfo, projectId, modelType, reportId, form);
    const reports: QtoReportInfo[] = yield selectWrapper(s => s.quantityTakeOff.report.reportsInfo);
    const index = reports.findIndex(x => x.id === reportId);
    const updatedReports = reports.slice();
    updatedReports[index] = reportInfo;
    yield put(QuantityTakeOffReportActions.setReportsInfo(updatedReports));
  } catch (error) {
    console.error('quantity take off reports: update report info failed', error, payload);
  }
}

function* exportReportToExcel(
  { payload }: ActionWith<FormWithProjectAndReportIdsPayload<undefined>>,
): SagaIterator {
  try {
    yield put(QuantityTakeOffReportActions.putMessageToUpdateReportQueue(
      QuantityTakeOffReportApi.exportReportToExcel,
      payload,
    ));
  } catch (error) {
    console.error('quantity take off reports: export report to excel failed', error, payload);
  }
}

function* updateBasicColumns(
  { payload }: ActionWith<FormWithProjectAndReportIdsPayload<QtoTreeColumnsForm>>,
): SagaIterator {
  try {
    const { projectId, modelType, reportId, form } = payload;
    yield call(QuantityTakeOffReportApi.updateBasicColumns, projectId, modelType, reportId, form);
  } catch (error) {
    console.error('quantity take off reports: update columns failed', error, payload);
  }
}

function* updatePivotColumns(
  { payload }: ActionWith<FormWithProjectAndReportIdsPayload<QtoTreeColumnsForm>>,
): SagaIterator {
  try {
    const { projectId, modelType, reportId, form } = payload;
    yield call(QuantityTakeOffReportApi.updatePivotColumns, projectId, modelType, reportId, form);
  } catch (error) {
    console.error('quantity take off reports: update columns failed', error, payload);
  }
}

function* updateReportRows(
  { payload }: ActionWith<FormWithProjectAndReportIdsPayload<QtoTreeRowForm[]>>,
): SagaIterator {
  try {
    yield put(QuantityTakeOffReportActions.putMessageToUpdateReportQueue(
      QuantityTakeOffReportApi.updateRows,
      payload,
    ));
  } catch (error) {
    console.error('quantity take off reports: update report rows failed', error, payload);
  }
}

function* addReportRows(
  { payload }: ActionWith<FormWithProjectAndReportIdsPayload<CreateQtoTreeRowsForm>>,
): SagaIterator {
  try {
    yield put(QuantityTakeOffReportActions.putMessageToUpdateReportQueue(
      QuantityTakeOffReportApi.addRows,
      payload,
    ));
    yield put(QuantityTakeOffFilterActions.callFilter());
  } catch (error) {
    console.error('quantity take off reports: add report rows failed', error, payload);
  }
}

function* removeReportRows(
  { payload }: ActionWith<FormWithProjectAndReportIdsPayload<string[]>>,
): SagaIterator {
  try {
    yield put(QuantityTakeOffReportActions.putMessageToUpdateReportQueue(
      QuantityTakeOffReportApi.deleteRows,
      payload,
    ));
    yield put(QuantityTakeOffFilterActions.callFilter());
  } catch (error) {
    console.error('quantity take off reports: remove report rows failed', error, payload);
  }
}

function* onReportUpdateFail({ payload }: ActionWith<ProjectAndReportIdsPayload>): SagaIterator {
  try {
    const { projectId, modelType, reportId } = payload;
    yield put(NotificationActions.addAlert({
      alertType: AlertType.Error,
      message: 'Failed to Report update. The Report will be reloaded',
    }));
    yield put(QuantityTakeOffReportActions.loadReport(projectId, modelType, reportId));
  } catch (error) {
    console.error('quantity take off reports: on update report fail failed', error, payload);
  }
}

function* reorderReportRows(
  { payload }: ActionWith<FormWithProjectAndReportIdsPayload<ReorderQtoTreeRowsForm>>,
): SagaIterator {
  try {
    yield put(QuantityTakeOffReportActions.putMessageToUpdateReportQueue(
      QuantityTakeOffReportApi.reorderRows,
      payload,
    ));
  } catch (error) {
    console.error('quantity take off reports: reorder report rows failed', error, payload);
  }
}

function* watchUpdateReportQueue(): SagaIterator {
  try {
    let task: Task = null;
    while (true) {
      const { payload } = yield take(QuantityTakeOffReportActionTypes.PUT_MESSAGE_TO_UPDATE_REPORT_QUEUE);
      if (task) {
        yield join(task);
      }

      const isSync = yield selectWrapper((s) => s.quantityTakeOff.report.isSync);
      if (isSync) {
        task = yield fork(handleUpdateReportQueueMessage, payload);
      } else {
        task = null;
      }
    }
  } catch (error) {
    console.error('quantity take off reports: update report queue failed', error);
  }
}

function* handleUpdateReportQueueMessage(payload: UpdateReportQueueMessagePayload<any>): SagaIterator {
  try {
    const { apiCall, form: { projectId, modelType, reportId, form } } = payload;
    yield call(apiCall, projectId, modelType, reportId, form);
    yield put(QuantityTakeOffReportActions.updateReportQueueMessageDone());
  } catch (error) {
    if (payload.form) {
      const { projectId, reportId, modelType } = payload.form;
      yield put(QuantityTakeOffReportActions.updateReportQueueMessageFailed(projectId, modelType, reportId));
    }
    console.error('quantity take off reports: handle update report queue message failed', error, payload);
  }
}

function* copyReport({ payload }: ActionWith<CopyReportPayload>): SagaIterator {
  try {
    const copyResult = yield call(
      QuantityTakeOffReportApi.copyReport,
      payload.projectId,
      payload.modelType,
      payload.reportId,
      payload.currentProjectId,
    );
    const { projectId, id } = copyResult;
    yield put(QuantityTakeOffReportActions.loadReportsInfo(projectId, payload.modelType));
    yield put(QuantityTakeOffReportActions.setSelectedReportId(id));
    yield put(QuantityTakeOffReportActions.createReportFinish());
  } catch (error) {
    console.error('quantity take off reports: copy report failed', error, payload);
  }
}

function* createTemplateFromReport({ payload }: ActionWith<ProjectAndReportIdsPayload>): SagaIterator {
  try {
    const { projectId, modelType, reportId } = payload;
    const templateInfo = yield call(QuantityTakeOffReportApi.createTemplateFromReport, projectId, modelType, reportId);
    yield put(QuantityTakeOffTemplateActions.updateTemplateInfoAfterCreationViaReport(templateInfo));
  } catch (error) {
    console.error('quantity take off reports: create template from report failed', error, payload);
  }
}


export function* quantityTakeOffReportsSagas(): SagaIterator {
  yield takeLatest(QuantityTakeOffReportActionTypes.CREATE_REPORT, createReport);
  yield takeLatest(QuantityTakeOffReportActionTypes.PUBLISH_REPORT, publishReport);
  yield takeLatest(QuantityTakeOffReportActionTypes.GET_AVAILABLE_REPORT_GENERATORS, getAvailableReportGenerators);
  yield takeLatest(QuantityTakeOffReportActionTypes.GET_AVAILABLE_REPORT_FOR_COPY, getAvailableReportForCopy);
  yield takeLatest(QuantityTakeOffReportActionTypes.GENERATE_DEFAULT_REPORT, generateDefaultReport);
  yield takeLatest(QuantityTakeOffReportActionTypes.GET_REPORTS_INFO, getReportsInfo);
  yield takeLatest(QuantityTakeOffReportActionTypes.GET_REPORT, getReport);
  yield takeLatest(QuantityTakeOffReportActionTypes.DELETE_REPORT, deleteReport);
  yield takeLatest(QuantityTakeOffReportActionTypes.UPDATE_REPORT_INFO, updateReportInfo);
  yield takeLatest(QuantityTakeOffReportActionTypes.UPDATE_REPORT_BASIC_COLUMNS, updateBasicColumns);
  yield takeLatest(QuantityTakeOffReportActionTypes.UPDATE_REPORT_PIVOT_COLUMNS, updatePivotColumns);
  yield takeLatest(QuantityTakeOffReportActionTypes.EXPORT_REPORT_TO_EXCEL, exportReportToExcel);
  yield takeLatest(QuantityTakeOffReportActionTypes.COPY_REPORT, copyReport);
  yield takeLatest(QuantityTakeOffReportActionTypes.GET_INITIAL_STATE, getInitialState);

  yield takeLatest(QuantityTakeOffReportActionTypes.ADD_REPORT_ROWS, addReportRows);
  yield takeLatest(QuantityTakeOffReportActionTypes.UPDATE_REPORT_ROWS, updateReportRows);
  yield takeLatest(QuantityTakeOffReportActionTypes.DELETE_REPORT_ROWS, removeReportRows);
  yield takeLatest(QuantityTakeOffReportActionTypes.REORDER_REPORT_ROWS, reorderReportRows);

  yield fork(watchUpdateReportQueue);
  yield takeLatest(QuantityTakeOffReportActionTypes.UPDATE_REPORT_QUEUE_MESSAGE_FAILED, onReportUpdateFail);
  yield takeLatest(QuantityTakeOffReportActionTypes.CREATE_TEMPLATE_FROM_REPORT, createTemplateFromReport);
}

