import produce from 'immer';
import { useCallback, useState } from 'react';

import { PiaBreakdownNode } from '../interfaces';

export type UpdateFunction = (
  update: (o: PiaBreakdownNode[], opsMap: Record<string, PiaBreakdownNode>) => void,
) => void;

const extendMap = <T extends { id: string, children: any }>(option: T, map: Record<string, T>): void => {
  map[option.id] = option;
  option.children.forEach((c) => extendMap(c, map));
};

export const useOptionState = (
  propsOptions: PiaBreakdownNode[],
  onChange: (updates: Record<string, string | PiaBreakdownNode[]>) => void,
): [PiaBreakdownNode[], UpdateFunction] => {
  const [options, setOptions] = useState(propsOptions);
  const updateOptions = useCallback(
    (update: (o: PiaBreakdownNode[], opsMap: Record<string, PiaBreakdownNode>) => void): void => {
      const newState = produce(options, (ops) => {
        const opsMap = {};
        ops.forEach((o) => extendMap(o, opsMap));

        update(ops, opsMap);
      });
      setOptions(newState);
      onChange({ root: newState });
    },
    [options, onChange],
  );

  return [options, updateOptions];
};
