export const FLOAT_NUMBER = /(|[0-9]*(\.|\,)?[0-9]*)/;
export const FLOAT_NUMBER_ONLY = new RegExp(`^${FLOAT_NUMBER.source}$`);
export const INT_NUMBER = /([0-9]*)/;
// eslint-disable-next-line max-len
export const EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
export const KREO_EMAIL = /^.+@kreo.net$/i;
/**
 * @description Get all '0' from start to first unzero symbol. If '00[more 0]00' -> get '00...0'
 */
export const FIRST_ZEROS = /^[0]*(?=[0\(.|,)1-9)])/;
/**
 * @description Get float with 1 number after point
 */
export const FLOAT_NUMBER_1 = /^\d*[,|.]\d{1}/;
/**
 * @description Get float with 3 numbers after point
 */
export const FLOAT_NUMBER_3 = /^\d*[,|.]\d{3}/;
export const LAST_COMMA_REGEX = /,[^,]*$/;
