import autobind from 'autobind-decorator';
import * as React from 'react';

import './styles.scss';

import { KreoButton } from 'common/UIKit';
import { AdminDatabaseVm } from '../../interfaces/admin-database-vm';

interface DialogDatabaseItemProps {
  database: AdminDatabaseVm;
  onAttach?: (databaseId: number) => void;
}

export class DialogDatabaseItem extends React.Component<DialogDatabaseItemProps>  {
  public render(): React.ReactNode {
    return (
      <div
        className='admin-item'
      >
        <div className='admin-item__information'>
          <span className='admin-item__head'>
            {this.props.database.name}
            {
              this.props.database.version ?
              <span className='admin-item__secondary'> (
                {`${this.props.database.version}`})
              </span> : null
            }
          </span>
          <KreoButton
            onClick={this.onClick}
            mode='toaction'
            size='medium'
          >
            Attach
          </KreoButton>
        </div>
      </div>
    );
  }

  @autobind
  private onClick(): void {
    if (this.props.onAttach) {
      this.props.onAttach(this.props.database.id);
    }
  }
}
