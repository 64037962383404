import * as React from 'react';

import './index.scss';

interface Props {
  stamp: Date;
}


const hourFormatter: Intl.DateTimeFormatOptions = {
  hour: 'numeric', minute: 'numeric', hour12: true,
};


const fullOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

const dateFormatter = (date: Date): string => {
  const now = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  if (year === now.getFullYear() && month === now.getMonth()) {
    const nowDay = now.getDate();
    if (day === nowDay) {
      return `Today, ${date.toLocaleTimeString('en-GB', hourFormatter)}`;
    } else if (nowDay - day === 1) {
      return `Yesterday,${date.toLocaleTimeString('en-GB', hourFormatter)}`;
    } else {
      return `${date.toLocaleTimeString('en-GB', fullOptions)}`;
    }
  } else {
    return `${date.toLocaleTimeString('en-GB', fullOptions)}`;
  }
};

export const TimeStamp: React.FC<Props> = (props) => {
  const formatted = dateFormatter(props.stamp);
  return (<div className='viewer-timestamp' title={formatted}>{formatted}</div>);
};
