import autobind from 'autobind-decorator';
import React from 'react';

import { DrawingsDragLayoutCanvasWrapper } from 'common/components/drawings/drawings-canvas/drag-layout-canvas-wrapper';
import { DrawingContextObserver, DrawingContextObserverWithPrev } from 'common/components/drawings/drawings-contexts';
import { LineEngine } from 'common/components/drawings/drawings-geometry';
import { DrawingsViewportHelper, PdfDocumentRenderer, PdfRenderer } from 'common/components/drawings/helpers/viewport';
import { DrawOptions } from 'common/components/drawings/helpers/viewport/interfaces';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { DrawingSnapping } from 'common/components/drawings/interfaces/drawing';
import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { ConstantFunctions } from 'common/constants/functions';
import { ComparableDocumentType, isCompareDocument } from 'common/pdf/compare-document';
import { OriginalDocumentRenderer } from './original-document-renderer';
import { Styled } from './styled';

interface Props {
  drawing: Core.Document | ComparableDocumentType;
  snapping: DrawingSnapping;
  line: { x1: number, y1: number, x2: number, y2: number };
  loaded: boolean;
  drawingId: string;
  currentDrawing: DrawingsShortInfo;
  onLineChange: (start: { x: number, y: number }, end: { x: number, y: number }) => void;
}

interface State {
  rendered: boolean;
}

export class LinesSelectors extends React.PureComponent<Props, State> {
  private canvasRef: HTMLCanvasElement | null;
  private canvasWrapper: HTMLDivElement | null;
  private snappingObserver: DrawingContextObserver<DrawingSnapping> = new DrawingContextObserver<DrawingSnapping>(
    this.props.snapping,
  );
  private zoomHandler: DrawingContextObserverWithPrev<{ zoom: number }> = new DrawingContextObserverWithPrev({
    zoom: 1,
  });
  private viewportHelper: DrawingsViewportHelper;

  private engine: LineEngine;
  private renderer: PdfDocumentRenderer;

  constructor(props: Props) {
    super(props);
    this.state = {
      rendered: false,
    };

    const type = isCompareDocument(this.props.drawing) ? OriginalDocumentRenderer : PdfRenderer;
    this.renderer = new type({
      syncPageViewportRect: (options: DrawOptions) => this.viewportHelper.syncPageViewportRect(options),
      getCurrentDrawingInfo: () => this.props.currentDrawing,
      getCurrentZoom: () => this.zoomHandler.getContext().zoom,
    });
    this.viewportHelper = new DrawingsViewportHelper({
      zoomHandler: this.zoomHandler,
      getSelectedInstancesBounds: () => null,
      getCurrentRotation: () => 0,
      getCurrentDrawingId: () => this.props.drawingId,
      canShowMainCanvas: () => true,
      documentRenderer: this.renderer,
    });
    this.viewportHelper.drawingInfo = {
      pageNumber: 0,
      drawingId: this.props.drawingId,
      pdfDocument: this.props.drawing,
    };
    this.viewportHelper.drawingLayoutApi = {
      setCanvasSize: (width, height) => {
        this.engine.viewHelper.setCanvasSize(width, height);
      },
      setViewportZoom: (zoom) => {
        this.engine.viewHelper.setViewportZoom(zoom);
      },
      updateCanvasRenderParameters: (rect) => {
        this.engine.viewHelper.updateCanvasRenderParameters(rect);
      },
      updateViewportParams: ConstantFunctions.doNothing,
      getDrawingCanvas: () => this.canvasRef,
      getInstanceMeasures: () => null,
      rotate: ConstantFunctions.doNothing,
      changeEntitiesColor: ConstantFunctions.doNothing,
      getAnnotationsBounds: () => null,
      clearAnnotationsLayout: ConstantFunctions.doNothing,
      focusTextSearchResults: ConstantFunctions.doNothing,
    };
  }

  public render(): React.ReactNode {
    return (
      <>
        <RenderIf condition={!this.state.rendered || !this.props.loaded}>
          <Styled.SpinnerContainer>
            <Spinner show={true} />
          </Styled.SpinnerContainer>
        </RenderIf>
        <DrawingsDragLayoutCanvasWrapper
          shouldIgnoreSettings={true}
          onDragStart={ConstantFunctions.doNothing}
          onDragFinish={ConstantFunctions.doNothing}
          canViewMeasurement={true}
          isLeftMouseButtonDragEnabled={false}
          onScroll={this.viewportHelper.onChangeViewport}
          setScrollLayoutRef={this.saveScrollLayoutRef}
          setMainLayoutRef={this.viewportHelper.setMainLayoutRef}
          setDrawingsWrapperRef={this.setDrawingsWrapperRef}
          onCanvasResized={ConstantFunctions.doNothing}
          viewportHelper={this.viewportHelper}
        >
          <canvas className="drawings-canvas__drawing drawings-canvas__drawing--draw" ref={this.saveCanvasRef} />
        </DrawingsDragLayoutCanvasWrapper>
      </>
    );
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.snapping !== prevProps.snapping) {
      this.snappingObserver.updateContext(this.props.snapping);
    }
    if (this.props.line !== prevProps.line) {
      this.engine.setLine(this.props.line);
    }
  }

  public componentDidMount(): void {
    if (this.canvasRef) {
      this.engine = new LineEngine({
        renderParamsContextObserver: this.zoomHandler,
        snappingObserver: this.snappingObserver,
        onLineChanged: this.props.onLineChange,
        getCurrentDrawingInfo: () => this.props.drawing.getPageInfo(1),
        geometry: null,
        canvas: this.canvasRef,
      });
      this.engine.viewHelper.setDrawingsWrapperRef(this.canvasWrapper);
      this.viewportHelper.renderMainPdfLayout(0).then((x) => this.setState({ rendered: x }));
      if (this.props.line) {
        this.engine.setLine(this.props.line);
      }
    }
  }

  public componentWillUnmount(): void {
    this.viewportHelper.cancel();
    if (this.engine) {
      this.engine.destroy();
    }
  }

  @autobind
  private saveScrollLayoutRef(ref: HTMLDivElement): void {
    this.viewportHelper.saveScrollLayout(ref);
    this.viewportHelper.scrollLayoutRef.addEventListener('wheel', this.viewportHelper.zoomEventHandler, {
      passive: false,
    });
  }

  @autobind
  private setDrawingsWrapperRef(ref: HTMLDivElement | null): void {
    this.canvasWrapper = ref;
  }

  @autobind
  private saveCanvasRef(ref: HTMLCanvasElement | null): void {
    this.canvasRef = ref;
  }
}
