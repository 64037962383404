import { DeleteButtonStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { Styled as FormStyled } from 'common/components/form/styled';
import { Styled as LabelGroupStyled } from 'common/components/label-group/styled';
import { ItemFormIconStyled } from 'unit-2d-database/components/icons';
import { Styled as MenuPropertiesStyled } from 'unit-2d-database/components/menu-properties/styled';

const LabelGroupWrapper = styled.div<{ hideDeleteButton?: boolean }>`
  position: relative;
  ${LabelGroupStyled.LabelGroupWithIcon} {
    border-right: none;
    border-left: none;

    :hover {
      ${ItemFormIconStyled.Container} {
        svg {
          fill: ${p => p.theme.color.turquoise};
          path {
            fill: ${p => p.theme.color.turquoise};
          }
          circle {
            fill: ${p => p.theme.color.turquoise};
          }
        }
      }
    }
  }
  ${DeleteButtonStyled.Container} {
    display: ${p => p.hideDeleteButton ? 'none' : 'flex'};
  }

  ${MenuPropertiesStyled.Container} {
    z-index: 2 !important;
  }
`;

const ItemGroupForm = styled.div`
  ${DeleteButtonStyled.Container} {
    display: none;
  }
`;

const FormContainer = styled.div<{ hideDeleteButton?: boolean, withLabel: boolean }>`
    ${FormStyled.Container} {
      margin-top: 5px;
    }
    ${DeleteButtonStyled.Container} {
    display: ${p => p.hideDeleteButton ? 'none' : 'flex'};
  }
`;

const AssemblyGroup = styled.div`
  padding: 5px;
  box-shadow: 0 0 0 1px ${p => p.theme.color.background};
  border-radius: 10px;
  margin: 5px;
`;

const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 1px ${p => p.theme.color.background};
  border-radius: 15px;
  background: ${p => p.theme.color.background};
`;

const ItemForm = styled.div<{ hasContent: boolean }>`
  ${p => {
    if (p.hasContent) {
      return 'margin: 5px;';
    }
    return '';
  }}
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const PropertyGroup = styled.div`
  background: ${p => p.theme.color.backgroundRush};
  box-shadow: 0 0 0 1px ${p => p.theme.color.background};
  border-radius: 10px;
`;

const PropertyForm = styled.div<{ hasContent: boolean }>`

  ${p => {
    if (p.hasContent) {
      return `
        background: ${p.theme.color.background};
        padding: 5px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        gap: 5px;
      `;
    }
    return 'margin: 0px !important';
  }}
`;

export const Styled = {
  LabelGroupWrapper,
  ItemGroupForm,
  FormContainer,
  AssemblyGroup,
  ItemGroup,
  ItemForm,
  PropertyGroup,
  PropertyForm,
};
