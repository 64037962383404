import autobind from 'autobind-decorator';
import * as React from 'react';

import { KreoIconAddPlus } from 'common/UIKit';

interface Props {
  workPackageId: number;
  addNewBid: (workpackageId: number) => void;
}

export class NewBid extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <div className='bid-pricing-invitations__row'>
        <div className='bid-pricing-invitations__add-bid' onClick={this.createNewBid}>
          <KreoIconAddPlus />
        </div>
      </div>
    );
  }

  @autobind
  private createNewBid(): void {
    this.props.addNewBid(this.props.workPackageId);
  }
}
