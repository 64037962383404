import React, { useMemo } from 'react';

import {
  DrawingsStyleMenuType,
  DrawingsStylesMenuUtils,
  ShapeSwitcher,
} from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { useDrawModeApi, useNewInstancesSetting } from 'common/components/drawings/hooks';
import { Property } from 'common/components/property';
import { StrokeStyles } from './stroke-styles';
import { Styled } from './styled';

const StylesMenuComponent: React.FC = () => {
  const { drawMode } = useDrawModeApi();

  const menuType = useMemo(() => DrawingsStylesMenuUtils.getTypeByDrawingMode(drawMode), [drawMode]);

  const [ shape, changeShape ] = useNewInstancesSetting('shape');

  switch (menuType) {
    case DrawingsStyleMenuType.Stroked:
      return (<StrokeStyles/>);
    case DrawingsStyleMenuType.Count:
      return (
        <Styled.ShapeSwitcherWrapper>
          <Property title='Shape'>
            <ShapeSwitcher shape={shape} setShape={changeShape}/>
          </Property>
        </Styled.ShapeSwitcherWrapper>
      );
    default:
      return null;
  }
};

export const StylesMenu = React.memo(StylesMenuComponent);
