import { set } from 'monolite';

import { Actions } from '../constants';

const INITIAL_STATE = {
  current: 0,
};


export default function stepper(
  state: any = INITIAL_STATE,
  action: any = { type: '', payload: null }
): any {
  switch (action.type) {
    case Actions.Stepper.STEPPER_SET_CURRENT_STEP:
      return set(state, (_: any): any => _.current)(action.payload);
    default:
      return state;
  }
}


