const prefix = '@drawings-annotation-legend';

export const DrawingsAnnotationLegendActionTypes = {
  SET_GROUPS: `${prefix}/SET_GROUPS`,
  UPDATE_GROUPS: `${prefix}/UPDATE_GROUPS`,
  BULK_GROUPS_UPDATE: `${prefix}/BULK_GROUPS_UPDATE`,
  MOVE_GROUPS: `${prefix}/MOVE_GROUPS`,
  DELETE_GROUPS: `${prefix}/DELETE_GROUPS`,
  REMOVE_DRAWINGS_FROM_GROUPS: `${prefix}/REMOVE_DRAWINGS_FROM_GROUPS`,
  ADD_GROUPS: `${prefix}/ADD_GROUPS`,
  LOAD_GROUPS: `${prefix}/LOAD_GROUPS`,
  TOGGLE_GROUP_OPEN: `${prefix}/TOGGLE_GROUP_OPEN`,
  TOGGLE_ALL_GROUPS_OPEN: `${prefix}/TOGGLE_ALL_GROUPS_OPEN`,
  SAVE_FILTERED_ELEMENT_IDS: `${prefix}/SAVE_FILTERED_ELEMENT_IDS`,
  SET_FILTER_DATA: `${prefix}/SET_FILTER_DATA`,
  CHANGE_FILTER_DATA: `${prefix}/CHANGE_FILTER_DATA`,
  LOAD_GROUP_TEMPLATES: `${prefix}/LOAD_GROUP_TEMPLATES`,
  SET_GROUP_TEMPLATES: `${prefix}/SET_GROUP_TEMPLATES`,
  CREATE_GROUP_TEMPLATE: `${prefix}/CREATE_GROUP_TEMPLATE`,
  APPLY_GROUP_TEMPLATE: `${prefix}/APPLY_GROUP_TEMPLATE`,
  UPDATE_GROUP_TEMPLATE: `${prefix}/UPDATE_GROUP_TEMPLATE`,
  UPDATE_GROUP_TEMPLATE_SUCCESS: `${prefix}/UPDATE_GROUP_TEMPLATE_SUCCESS`,
  DUPLICATE_GROUP_TEMPLATE: `${prefix}/DUPLICATE_GROUP_TEMPLATE`,
  ADD_GROUP_TEMPLATE: `${prefix}/ADD_GROUP_TEMPLATE`,
  DELETE_GROUP_TEMPLATE: `${prefix}/DELETE_GROUP_TEMPLATE`,
  DELETE_GROUP_TEMPLATE_SUCCESS: `${prefix}/DELETE_GROUP_TEMPLATE_SUCCESS`,
  UPDATE_USER_IN_GROUPS: `${prefix}/UPDATE_USER_IN_GROUPS`,
  ADD_INSTANCE_TO_GROUP: `${prefix}/ADD_INSTANCE_TO_GROUP`,
  SET_GROUP_LOAD_SUCCESS: `${prefix}/SET_GROUP_LOAD_SUCCESS`,

  UPDATE_SELECTION: `${prefix}/UPDATE_SELECTION`,

  TOGGLE_SHOW_ONLY_GROUPS: `${prefix}/TOGGLE_SHOW_ONLY_GROUPS`,

  SET_FILTERED_RESULT_ITEMS: `${prefix}/SET_FILTERED_RESULT_ITEMS`,
};
