import * as React from 'react';

export const AlertWarning: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/14x14/Warning_Small'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M1.03645142,9.42514833 L5.42248028,2.07485167 C6.27058302,0.653564453 7.6543994,0.652800358 8.51230228,
        2.07485167 L12.9466294,9.42514833 C13.8040713,10.8464355 13.1571128,12 11.5005775,12 L2.49942248,
        12 C0.844350735,12 0.187892731,10.8471996 1.03645142,9.42514833 Z'
          id='Path-3'
          fill='#F6AC46'
        />
        <path
          d='M6.49544305,4 L7.49544305,4 L7.49544305,8 L6.49544305,8 L6.49544305,4 Z M6.49544305,
        9 L7.49544305,9 L7.49544305,10 L6.49544305,10 L6.49544305,9 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
