import * as React from 'react';

export const AlertAttention: React.FC = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/24x24/Alert_Attention'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M2.99739742,16.5372 L9.96210091,4.46280002 C11.0626366,2.55484974 12.8603733,2.55034838
          13.9716588,4.46280002 L20.9878395,16.5372 C22.0965094,18.4451503 21.2048598,20 18.9904421,20
          L5.00955791,20 C2.80035233,20 1.89426529,18.4496516 2.99739742,16.5372 Z'
          id='Path-3'
          fill='#F6AC46'
        />
        <path
          d='M11,9 C11,8.44771525 11.4477153,8 12,8 C12.5522847,8 13,8.44771525 13,9 L13,13 C13,13.5522847
          12.5522847,14 12,14 C11.4477153,14 11,13.5522847 11,13 L11,9 Z M12,15 C12.5522847,15 13,15.4477153
          13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 C11,15.4477153 11.4477153,15
          12,15 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
