import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DrawingsGeometryGroup } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';

export function useSelectedGroup(): DrawingsGeometryGroup {
  const selectedGroupId = useSelector<State, string>(
    state => state.drawings.selectGeometryGroup && state.drawings.selectGeometryGroup[0]);
  const groups = useSelector<State, DrawingsGeometryGroup[]>(state => state.drawings.drawingGeometryGroups);
  return useMemo(() => groups.find(g => g.id === selectedGroupId), [groups, selectedGroupId]);
}
