import React, { useCallback, useState } from 'react';
import { TooltipContent } from './content';
import { Styled } from './styled';

interface Props {
  id?: string;
  position: 'top' | 'bottom' | 'left' | 'right';
  text?: string;
  speed?: 's' | 'm' | 'l' | 'xl';
  size?: 'small' | 'big';
  targetTop: number;
  targetBottom: number;
  targetLeft: number;
  targetRight: number;
  targetWidth: number;
  targetHeight: number;
  hotKey?: string;
  tooltipWidth?: number;
  wordBreakAll?: boolean;
  picture?: string;
  description: string;
  tooltipOverflow?: string;
  isTranslucent?: boolean;
  childComponent?: React.ComponentType;
  padding?: string;
}

export const Tooltip: React.FC<Props> = ({
  id,
  position,
  text,
  speed,
  size,
  targetTop,
  targetBottom,
  targetLeft,
  targetRight,
  targetWidth,
  targetHeight,
  hotKey,
  tooltipWidth,
  wordBreakAll,
  picture,
  description,
  tooltipOverflow,
  isTranslucent,
  padding,
  childComponent: ChildComponent,
}) => {
  const [dimensions, setDimensions] = useState(null);
  const toolTipCallbackRef = useCallback((domNode) => {
    if (domNode && !dimensions) {
      setDimensions(domNode.getBoundingClientRect());
    }
  }, []);
  const containerWidth = dimensions && dimensions.width;
  const containerHeight = dimensions && dimensions.height;
  return (
    <Styled.TooltipContainer
      readyToShow={!!dimensions}
      id={id}
      position={position}
      targetTop={targetTop}
      targetBottom={targetBottom}
      targetLeft={targetLeft}
      targetRight={targetRight}
      targetWidth={targetWidth}
      targetHeight={targetHeight}
      width={containerWidth}
      height={containerHeight}
      speed={speed}
      hotKey={hotKey}
      tooltipWidth={tooltipWidth}
      picture={picture}
      size={size}
      isTranslucent={isTranslucent}
      ref={toolTipCallbackRef}
      padding={padding}
    >
      <TooltipContent
        hotKey={hotKey}
        description={description}
        picture={picture}
        text={text}
        size={size}
        wordBreakAll={wordBreakAll}
        tooltipOverflow={tooltipOverflow}
        childComponent={ChildComponent}
      />
    </Styled.TooltipContainer>
  );
};
