import * as Ag from 'ag-grid-community';

import { TreeTableRowType, TreeTableValueGetterParams } from 'common/components/tree-table/interfaces';
import { ValueHelper } from 'common/utils/value-helper';
import { PropertyHelper, QtoReportPivotTableIdHelper, reportTableAggregations } from '..';
import { AggFuncType, QtoTreeRowProperty } from '../../../interfaces/quantity-take-off';

type AggregationFunction =
  (params: TreeTableValueGetterParams, quantityValues: Record<string, QtoTreeRowProperty>) => number | null;

const getAggregationFunction = (aggFunc: AggFuncType): AggregationFunction => {
  const getPivotAggValueByParent = (
    api: Ag.GridApi,
    group: Ag.RowNode,
    pivotColumnId: string,
    columnId: string,
    quantityValues: Record<string, QtoTreeRowProperty>,
  ): number | null => {
    const values: number[] = [];
    const pivotRowId = QtoReportPivotTableIdHelper.getPivotRowId(group.id, columnId);
    if (pivotRowId in quantityValues) {
      return PropertyHelper.getActualValue(quantityValues[pivotRowId]);
    }

    for (const child of group.childrenAfterGroup) {
      if (child.data.type === TreeTableRowType.Element) {
        continue;
      }

      const pivotChildRowId = QtoReportPivotTableIdHelper.getPivotRowId(child.id, columnId);
      const value = child.childrenMapped[pivotChildRowId]
        ? api.getValue(pivotColumnId, child.childrenMapped[pivotChildRowId])
        : getPivotAggValueByParent(api, child, pivotColumnId, columnId, quantityValues);

      if (!ValueHelper.isNumberValue(value)) {
        return null;
      }
      values.push(Number(value));
    }

    return aggFunc(values);
  };

  return (params: TreeTableValueGetterParams, quantityValues: Record<string, QtoTreeRowProperty>): number | null => {
    const { node, api, columnId: pivotColumnId } = params;
    if (!api) {
      return null;
    }

    const { columnId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(node.id);
    return getPivotAggValueByParent(api, node.parent, pivotColumnId, columnId, quantityValues);
  };
};


export const pivotAggregationFunctions = Object.entries(reportTableAggregations)
  .reduce(
    (result, [key, aggFunc]) => {
      result[key] = getAggregationFunction(aggFunc);
      return result;
    },
    {});
