import { Icons, LinkComponent, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';

interface Props {
  headerIcon: React.ComponentType<any>;
  headerText: string;
  isEdit: boolean;
  onSubmit: () => void;
  isValid: boolean;
}

export const Header = ({
  headerIcon: Icon,
  headerText,
  isEdit,
  onSubmit,
  isValid,
}: Props): JSX.Element => {
  return (
    <Styled.Header>
      <Styled.EntityType>
        <Icon height={20} width={20} />
        <Text fontSize={14}>{headerText}</Text>
      </Styled.EntityType>
      <LinkComponent
        text={isEdit ? 'Save' : 'Create'}
        onClick={onSubmit}
        Icon={isEdit ? Icons.Save : Icons.PlusBig}
        mood={isValid ? 'secondary' : 'disabled'}
      />
    </Styled.Header>
  );
};
