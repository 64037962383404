import * as React from 'react';

export const KreoIconRowExpanded: React.FC = (_props) => {
  return (
    <svg width='8px' height='3px' viewBox='0 0 8 3' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Client_GenC_Costs' transform='translate(-163.000000, -288.000000)'>
          <g id='Group-10' transform='translate(100.000000, 60.000000)'>
            <g id='WP_1' transform='translate(50.000000, 200.000000)'>
              <g id='icon_Expanded'>
                <g transform='translate(10.000000, 23.000000)'>
                  <g id='icon_Collapse'>
                    <rect id='Rectangle-7' x='0' y='0' width='14' height='14' />
                    <polyline
                      id='Rectangle-5'
                      stroke='#ffffff'
                      strokeLinecap='round'
                      transform='translate(7.000000, 6.500000) rotate(-180.000000) translate(-7.000000, -6.500000) '
                      points='10 5 7 8 4 5'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
