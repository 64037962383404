import { FormulaInputAutocompleteItemType } from 'common/components/formula-editor/enums';
import { arrayUtils } from 'common/utils/array-utils';
import { mathUtils } from 'common/utils/math-utils';
import { FormulaInputAutocompleteItemInfo } from '../../auto-complete';
import { VISIBLE_FUNCTIONS } from '../../constants';
import { FormulaPartTypes } from '../../enums';
import { FormulaPart } from '../../interfaces';

type CurrentPositionInfo = [string, FormulaPart];

function extractWordFromPart(part: FormulaPart, startCaretPosition: number): CurrentPositionInfo {
  if (part.type === FormulaPartTypes.Constant && part.text[0] === '\'' || part.text[0] ==='"') {
    return null;
  }
  if (part.type === FormulaPartTypes.Property) {
    return [part.text.substring(1, startCaretPosition), part];
  } else if (part.type === FormulaPartTypes.Constant) {
    return [part.text.substring(0, startCaretPosition), part];
  }
  return null;
}

function getCurrentWord(
  startCaretPosition: number,
  parts: FormulaPart[],
  startScopeIndex: number = 0,
):  CurrentPositionInfo {
  for (const part of parts) {
    const { bounds: [start, end], children }  = part;
    if (mathUtils.changeInRange(startCaretPosition, startScopeIndex + start, startScopeIndex + end)) {
      if (children) {
        return getCurrentWord(startCaretPosition, children, 0);
      } else {
        return extractWordFromPart(part, startCaretPosition - start);
      }
    }
  }
  return null;
}

function filterData(
  query: string,
  properties: string[],
  functions: string[],
  onlyProperties?: boolean,
): FormulaInputAutocompleteItemInfo[] {
  const lowerCasedQuery = query.toLowerCase();
  const results = new Array<FormulaInputAutocompleteItemInfo>();
  const secondPriority = new Array<FormulaInputAutocompleteItemInfo>();
  const filterDataset = (dataset: string[], type: FormulaInputAutocompleteItemType): void => {
    for (const item of dataset) {
      const lowerCased = item.toLowerCase();
      if (lowerCased.startsWith(lowerCasedQuery)) {
        results.push({ text: item, type });
      } else if (lowerCased.includes(lowerCasedQuery)) {
        secondPriority.push({ text: item, type });
      }
    }
  };
  filterDataset(properties, FormulaInputAutocompleteItemType.Property);
  if (!onlyProperties) {
    filterDataset(functions, FormulaInputAutocompleteItemType.Function);
    filterDataset(VISIBLE_FUNCTIONS, FormulaInputAutocompleteItemType.MeasuredFunction);
  }
  results.sort((a, b) => a.text > b.text ? -1 : 1);
  if (secondPriority.length) {
    secondPriority.sort((a, b) => a.text > b.text ? -1 : 1);
  }
  arrayUtils.extendArray(results, secondPriority);
  return results;
}

function buildNameFromResult({ text, type }: FormulaInputAutocompleteItemInfo): string {
  switch (type) {
    case FormulaInputAutocompleteItemType.Property:
      return `[${text}]`;
    case FormulaInputAutocompleteItemType.MeasuredFunction:
      return `${text}()`;
    case FormulaInputAutocompleteItemType.Function:
      return `${text}(`;
    default:
  }
}

function pasteAutocompleteResult(
  part: { bounds: [number, number] },
  value: string,
  textToPaste: string,
): string {
  const prevSubstring = value.substring(0, part.bounds[0]);
  const pastSubstring = value.substring(part.bounds[1]);
  return `${prevSubstring}${textToPaste}${pastSubstring}`;
}

export const FormulaInputAutocompleteUtils = {
  getCurrentWord,
  filterData,
  buildNameFromResult,
  pasteAutocompleteResult,
};
