import autobind from 'autobind-decorator';
import { posthog } from 'posthog-js';
import * as React from 'react';
import { connect } from 'react-redux';

import { ConstantFunctions } from 'common/constants/functions';
import { EnvironmentConfigurationProps } from 'common/environment/environment-configuration-props';
import { withEnvironmentConfiguration } from 'common/environment/with-environment-configuration';
import { State } from 'common/interfaces/state';
import { getInitStatus, init, resetAnalytics } from 'utils/posthog';
import { CompanySubscriptionModel } from '../../units/account/interfaces/company-subscription-model';
import { FeatureFlagService } from './feature-flag-service';
import { UserAbility } from './user-ability';

export interface AbilityContextValue {
  ability: UserAbility;
  featureFlagService: FeatureFlagService;
}

export const AbilityContext = React.createContext<AbilityContextValue>(null);

interface StateProps {
  userId: string | null;
  email: string | null;
  isAdmin: boolean;
  isOwner: boolean;
  subscription: CompanySubscriptionModel | null;
}

interface Props extends StateProps, EnvironmentConfigurationProps {

}

interface ProviderState {
  featureFlagService: FeatureFlagService | null;
}

class AbilityContextProviderComponent extends React.Component<Props, ProviderState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      featureFlagService: null,
    };
  }

  public render(): React.ReactNode {
    const { isAdmin, isOwner, subscription } = this.props;
    const { featureFlagService } = this.state;
    const ability = new UserAbility(isAdmin, isOwner, featureFlagService, subscription);

    return (
      <AbilityContext.Provider value={{ ability, featureFlagService }}>
        {this.props.children}
      </AbilityContext.Provider>
    );
  }

  public componentDidUpdate(): void {
    const environmentConfiguration = this.props.environmentConfiguration;
    const hasNeededDataForInitialization = environmentConfiguration
      && environmentConfiguration.postHogApiKey
      && this.props.userId
      && this.props.email;
    if (hasNeededDataForInitialization) {
      if (!getInitStatus()) {
        init(environmentConfiguration.postHogApiKey);
        posthog.onFeatureFlags(this.updateFeatureFlagService);
      }
    }
  }

  public componentWillUnmount(): void {
    resetAnalytics();
    posthog.onFeatureFlags(ConstantFunctions.doNothing);
  }

  @autobind
  private updateFeatureFlagService(): void {
    this.setState({ featureFlagService: new FeatureFlagService() });
  }
}

const mapStateToProps = (state: State): StateProps => {
  const subscriptionType = state.account.selectedSubscriptionType;
  const company = state.account && state.account.selectedCompany
    ? state.account.selectedCompany
    : null;
  const ownCompanyId = state.account.ownedCompany && state.account.ownedCompany.id;
  const subscription = company && company.subscriptions && company.subscriptions[subscriptionType];

  return {
    userId: state.account && state.account.id ? state.account.id : null,
    email: state.account && state.account.email ? state.account.email : null,
    isAdmin: state.account.isAdmin,
    isOwner: (company && company.id) === ownCompanyId,
    subscription,
  };
};


export const AbilityContextProvider = withEnvironmentConfiguration(
  connect(mapStateToProps)(AbilityContextProviderComponent),
);
