
import React from 'react';
import {
  DropTarget,
  DropTargetCollector,
  DropTargetSpec,
} from 'react-dnd';

const collectDrop: DropTargetCollector<any, any> = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    hovering: monitor.isOver({ shallow: true }) && monitor.canDrop(),
  };
};


export interface DNDDropTargetProps {
  connectDropTarget: (element: JSX.Element) => JSX.Element;
  connectDragSource: (element: JSX.Element) => JSX.Element;
  hovering: boolean;
  canDrag: boolean;
}


export function withDropTargetWrapper<P extends DNDDropTargetProps>(
  Component: React.ComponentType<any>, spec: DropTargetSpec<P>, type: string,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DNDDropTargetProps>>> {
  class DNDSourceComponent extends React.Component<any> {
    public render(): React.ReactNode {
      const { connectDropTarget } = this.props;
      return connectDropTarget(
        <div
          style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}
        >
          <Component {...this.props}/>
        </div>,
      );
    }
  }

  const dropConnector = DropTarget<P>(type, spec, collectDrop);
  return dropConnector(DNDSourceComponent) as any;
}
