import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: 105px 1fr;
  grid-column-gap: 15px;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid ${p => p.theme.color.background};
  border-radius: 15px;
  box-sizing: border-box;
  cursor: pointer;

  :hover {
    border-color: ${p => p.theme.color.turquoise};
  }
`;

const ProjectPreview = styled.div<{ image: string }>`
  position: relative;
  display: flex;
  border-radius: 5px;
  background-color: ${p => p.theme.color.background};
  background-image: ${p => `url(${p.image})`};
  background-position: center;
  background-size:  cover;
  box-sizing: border-box;
  aspect-ratio: 1 / 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.color.turquoise};
  border-radius: 5px;
  padding: 3px;
  min-width: 18px;
  box-sizing: border-box;

  > p {
    line-height: 12px;
  }
`;

export const Styled = {
  Container,
  ProjectPreview,
  ContentWrapper,
  DescriptionContainer,
  TagsContainer,
  Tag,
};
