import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { DrawingsShortInfo } from '../../interfaces';
import { CalibrateChangeScalePayload } from '../payloads/calibrate';
import { CalibrateActionTypes } from '../types/calibrate';

function dropState(): Action {
  return {
    type: CalibrateActionTypes.DROP_STATE,
  };
}

function startEdit(drawing: DrawingsShortInfo): ActionWith<DrawingsShortInfo> {
  return {
    type: CalibrateActionTypes.START_EDIT,
    payload: drawing,
  };
}

function changeScale(drawingLineLength: number, originalLineLength: number): ActionWith<CalibrateChangeScalePayload> {
  return {
    type: CalibrateActionTypes.CHANGE_SCALE,
    payload: {
      drawingLineLength,
      originalLineLength,
    },
  };
}

function setOriginalLineLength(length: string): ActionWith<string> {
  return {
    type: CalibrateActionTypes.SET_ORIGINAL_LINE_LENGTH,
    payload: length,
  };
}

function setPageFormat(paperSize: string): ActionWith<string> {
  return {
    type: CalibrateActionTypes.SET_PAGE_FORMAT,
    payload: paperSize,
  };
}

function onCalibrateLineDrawn(length: number): ActionWith<number> {
  return {
    type: CalibrateActionTypes.ON_CALIBRATE_LINE_DRAWN,
    payload: length,
  };
}

export const CalibrateActions = {
  dropState,
  startEdit,
  changeScale,
  setOriginalLineLength,
  setPageFormat,
  onCalibrateLineDrawn,
};
