import { IconButton, Icons } from '@kreo/kreo-ui-components';
import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import React from 'react';
import { Styled } from '../styled';

interface Props {
  color: string;
  openColorPicker: (e: React.MouseEvent) => void;
  canEditMeasurement: boolean;
  hide: boolean;
}

const ColorIndicatorComponent: React.FC<Props> = ({
  color,
  openColorPicker,
  canEditMeasurement,
  hide,
}) => {
  return (
    <Styled.ColorIndicatorContainer
      color={color}
      onClick={ConstantFunctions.stopEvent}
      hideColorIndicator={hide}
    >
      <IconButton
        Icon={color ? Icons.CircleFilled : Icons.Circle2D}
        onClick={openColorPicker}
        width={20}
        height={20}
        mood={canEditMeasurement ? 'default' : 'disabled'}
      />
    </Styled.ColorIndicatorContainer>
  );
};


export const ColorIndicator = React.memo(ColorIndicatorComponent);
