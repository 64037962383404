import { UpdateCellData } from 'common/components/excel-table';
import { SheetUpdateCells } from '../../actions/payloads';
import { UpdateCellForm } from '../../index';
import { TwoDRegex } from '../2d-regex';
import { getUpdatedCell } from './get-updated-cell';
import { MoveCellTempData, MoveDataPayload } from './interfaces';
import { replaceCellRef } from './replace-cell-ref';

const getSheetUpdateCells = (
  sheetsMap: Record<string, Record<string, UpdateCellData>>,
): Array<SheetUpdateCells<UpdateCellForm[]>> => {
  return Object.entries(sheetsMap).map(
    (pair) => {
      const [sheet, values] = pair;
      return { sheetId: sheet, form: Object.values(values) };
    });
};

export const updateRefs = (
  tempData: MoveCellTempData,
  payload: MoveDataPayload,
): Array<SheetUpdateCells<UpdateCellForm[]>> => {
  const { updatedCellsMap, moveCellRefMap } = tempData;
  const { cellToCellMap, sheetId } = payload;
  const sheetsMap: Record<string, Record<string, UpdateCellData>> = {
    [sheetId]: updatedCellsMap,
  };
  const cellRefs = new Set<string>();
  for (const cellId in cellToCellMap) {
    for (const ref of cellToCellMap[cellId]) {
      if (cellRefs.has(ref)) {
        continue;
      }
      cellRefs.add(ref);
      const { sheetId: cellSheetId, cellId: refCellId } = TwoDRegex.fullCellId.exec(ref).groups;
      const cellIdAfterMove = sheetId === cellSheetId
        ? moveCellRefMap[refCellId] || refCellId
        : refCellId;
      if (!sheetsMap[cellSheetId]) {
        sheetsMap[cellSheetId] = {};
      }
      const sheetUpdatedCellsMap = sheetsMap[cellSheetId];
      const updatedCell = getUpdatedCell(sheetUpdatedCellsMap, cellIdAfterMove, cellSheetId, payload);

      if (typeof updatedCell.value === 'string') {
        replaceCellRef(updatedCell, sheetId, moveCellRefMap, payload);
      }
    }
  }

  const result = getSheetUpdateCells(sheetsMap);
  return result;
};
