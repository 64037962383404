import {
  DrawingsGeometryGroup,
  DrawingsGeometryGroupWithNesting,
  DrawingsGeometryInstanceWithIdAndGroupId,
  PivotedInstance,
} from '../interfaces';
import { AnnotationLegendItem } from '../interfaces/annotation-legend-tree';


function isGeometry(item: AnnotationLegendItem): item is DrawingsGeometryInstanceWithIdAndGroupId {
  return item && !!(item as DrawingsGeometryInstanceWithIdAndGroupId).geometry;
}

function isGroup(item: AnnotationLegendItem): item is DrawingsGeometryGroupWithNesting {
  return item && (item as DrawingsGeometryGroup).measurements !== undefined;
}

function isPivoted(item: AnnotationLegendItem): item is PivotedInstance {
  return item && (item as PivotedInstance).groupedGeometries !== undefined;
}

export const AnnotationLegendItemTypeguards = {
  isGeometry,
  isGroup,
  isPivoted,
};
