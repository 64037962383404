import React from 'react';

import { BidPricingUserCost } from '../../../interfaces/bid-pricing/bid-pricing-user-cost';
import { BidPricingWorkPackage } from '../../../interfaces/bid-pricing/bid-pricing-work-package';
import { SubcontractorTable } from './subcontractor-table';

interface SubContractorsProps {
  workpackage: BidPricingWorkPackage;
  onItemClick(item: BidPricingUserCost): void;
  onWin(userId: string, workPackageId: number, constructionCostId: number): void;
  onCancelRequest(email: string, workPackageId: number): void;
  onDeleteSubcontractorBid(constructionCostId: number, workPackageId: number): void;
}

export class SubContractors extends React.Component<SubContractorsProps> {
  public render(): React.ReactNode {
    return (
      <SubcontractorTable
        workpackage={this.props.workpackage}
        onItemClick={this.props.onItemClick}
        onWin={this.props.onWin}
        onCancelRequest={this.props.onCancelRequest}
        onDeleteSubcontractorBid={this.props.onDeleteSubcontractorBid}
      />
    );
  }
}
