import autobind from 'autobind-decorator';
import * as React from 'react';
import { RegexTemplates } from '../../constants';

interface Props {
  value: string;
  fieldName: string;
  onChange: (value: string, field: string) => void;
}

interface State {
  value: string;
  changedCall: boolean;
}

export class Input extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { value: props.value, changedCall: true };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (state.changedCall) {
      return { value: props.value, changedCall: true };
    }
    return null;
  }

  public render(): React.ReactNode {
    return (
      <input
        className='cost-estimate-input'
        value={this.state.value}
        onBlur={this.onChange}
        onKeyPress={this.onKeyPress}
        onChange={this.onChangeInput}
      />
    );
  }

  @autobind
  public onChange(): void {
    this.props.onChange(this.state.value, this.props.fieldName);
    this.setState({ changedCall: true });
  }

  @autobind
  public onChangeInput(e: React.ChangeEvent<HTMLInputElement>): void {
    if (RegexTemplates.FLOAT_NUMBER_ONLY.test(e.target.value)) {
      this.setState({ value: e.target.value, changedCall: false });
    }
  }

  @autobind
  public onKeyPress(e: React.KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.onChange();
    }
  }
}
