import { BaseFormat } from './format';

export enum PropertyTypeEnum {
  Text = 'Text',
  SingleSelect = 'SingleSelect',
  Number = 'Number',
  Formula = 'Formula',
  SingleSelectLink = 'SingleSelectLink',
  Breakdown = 'Breakdown',
  BreakdownLink = 'BreakdownLink',
}

export interface DefaultField {
  id?: string;
  name: string;
  creatorId?: string;
  createdAt?: Date;
  editorId?: string;
  editedAt?: Date;
}

export interface SingleSelect {
  available: Array<string | number>;
  value: string | number;
}

export interface PiaBreakdownNode {
  id: string;
  name: string;
  code?: number;
  children: PiaBreakdownNode[];
}

export interface PiaBreakdown {
  root: PiaBreakdownNode[];
  value: string;
}

export type PropertyValueDataType = string | number | SingleSelect | PiaBreakdown;
interface PropertyValueBase<TType extends PropertyTypeEnum> {
  type: TType;
  format: BaseFormat;
  value: TType extends PropertyTypeEnum.Number
    ? number
    : TType extends PropertyTypeEnum.SingleSelect
      ? (string | number)
      : TType extends PropertyTypeEnum.Formula
        ? string
        : string;
}

export type PropertyValue<TType extends PropertyTypeEnum> = TType extends PropertyTypeEnum.SingleSelect
  ? PropertyValueBase<TType> & SingleSelect
  : TType extends PropertyTypeEnum.Breakdown
    ? PropertyValueBase<TType> & PiaBreakdown
    : PropertyValueBase<TType>;

export type SingleSelectLinkProperty = PropertyValue<PropertyTypeEnum.SingleSelectLink>;
export type SingleSelectPropertyValue = PropertyValue<PropertyTypeEnum.SingleSelect>;
export type NumericPropertyValue = PropertyValue<PropertyTypeEnum.Number>;
export type FormulaPropertyValue = PropertyValue<PropertyTypeEnum.Formula>;
export type TextPropertyValue = PropertyValue<PropertyTypeEnum.Text>;
export type BreakDownPropertyValue = PropertyValue<PropertyTypeEnum.Breakdown>;
export type BreakDownPropertyLink = PropertyValue<PropertyTypeEnum.BreakdownLink>;

export type AllPropertyValues = SingleSelectPropertyValue
| NumericPropertyValue
| FormulaPropertyValue
| TextPropertyValue
| SingleSelectLinkProperty
| BreakDownPropertyValue
| BreakDownPropertyLink;

export interface Property<T extends AllPropertyValues = AllPropertyValues> extends DefaultField {
  value: T;
  groupName: string;
}

export type FormulaProperty = Property<FormulaPropertyValue>;
export type NumericProperty = Property<NumericPropertyValue>;
export type TextProperty  = Property<TextPropertyValue>;
export type SingleSelectProperty = Property<SingleSelectPropertyValue>;
export type BreakdownProperty = Property<BreakDownPropertyValue>;
export type PredefinedProperty = SingleSelectProperty | NumericProperty | TextProperty;

export interface Item extends DefaultField {
  properties: Property[];
  iconType: string;
  folderId?: string;
  baseItemId?: string;
}

export interface Assembly extends DefaultField {
  items: Item[];
  folderId?: string;
}

export interface Group {
  id: string;
  name: string;
  parentFolderId?: string;
}

export interface User {
  id: string;
  lastName: string;
  firstName: string;
  hasAvatar: boolean;
}
