import * as React from 'react';

import { IconButton, KreoIconAction, MaterialIconMenu, MaterialMenuItem } from 'common/UIKit';

interface MenuProps {
  remove: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const Menu: React.FC<MenuProps> = (props) => {
  return (
    <div className='remove-icon-button' key={1}>
      <MaterialIconMenu
        iconButtonElement={<IconButton size='medium' icon={<KreoIconAction />} />}
      >
        <MaterialMenuItem
          onClick={props.remove}
        >
          Remove
        </MaterialMenuItem>
      </MaterialIconMenu>
    </div>
  );
};
