import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { RevisionsApi } from 'unit-cost-estimate/api/revisions';
import { RevisionState } from 'unit-cost-estimate/interfaces';
import { RevisionsActions } from '../actions/creators';
import { DuplicateRevisionPayload, UpdateRevisionPayload } from '../actions/payloads';
import { RevisionsActionTypes } from '../actions/types';


function* fetchProjectRevisions(): SagaIterator {
  try {
    const revisionsInfo = yield call(RevisionsApi.getProjectRevisionInfo);
    yield put(RevisionsActions.setProjectRevisionsInfo(revisionsInfo));
  } catch (error) {
    console.error('revisions: Failed to load revisions info', error);
  }
}

function* fetchRevisionState({ payload }: ActionWith<number>): SagaIterator {
  try {
    const revisionState = yield call(RevisionsApi.getRevision, payload);
    yield put(RevisionsActions.setRevisionState(payload, revisionState.state));
  } catch (error) {
    console.error('revisions: Failed to load revisions state', error);
  }
}

function* duplicateRevision({ payload }: ActionWith<DuplicateRevisionPayload>): SagaIterator {
  try {
    yield call(RevisionsApi.duplicateRevision, payload.revisionId, payload.name);
    yield put(RevisionsActions.fetchProjectRevisionsInfo());
  } catch (error) {
    console.error('revisions: Failed to duplicate revision', error, payload);
  }
}

function* deleteRevision({ payload }: ActionWith<number>): SagaIterator {
  try {
    yield call(RevisionsApi.deleteRevision, payload);
  } catch (error) {
    console.error('revisions: Failed to delete revision', error, payload);
  }
}

function* updateRevision({ payload }: ActionWith<UpdateRevisionPayload>): SagaIterator {
  try {
    yield call(RevisionsApi.updateRevision, payload.revisionId, payload.form);
  } catch (error) {
    console.error('revisions: Failed to update revision', error, payload);
  }
}

function* setCurrentRevisionState({ payload }: ActionWith<RevisionState>): SagaIterator {
  try {
    const revisionId: number = yield selectWrapper(s => {
      const projectId = s.projects.currentProject && s.projects.currentProject.id;
      return s.persistedStorage.projectIdToRevisionId[projectId];
    });

    if (revisionId) {
      yield put(RevisionsActions.setRevisionState(revisionId, payload));
    }
  } catch (error) {
    console.error('revisions: Failed to set current revision state', error, payload);
  }
}


export function* revisionSagas(): SagaIterator {
  yield takeLatest(RevisionsActionTypes.FETCH_PROJECT_REVISIONS_INFO, fetchProjectRevisions);
  yield takeLatest(RevisionsActionTypes.FETCH_REVISION_STATE, fetchRevisionState);
  yield takeLatest(RevisionsActionTypes.DUPLICATE_REVISION, duplicateRevision);
  yield takeLatest(RevisionsActionTypes.SET_CURRENT_REVISION_STATE, setCurrentRevisionState);
  yield takeLatest(RevisionsActionTypes.DELETE_REVISION, deleteRevision);
  yield takeLatest(RevisionsActionTypes.UPDATE_REVISION, updateRevision);
}
