import { MeasurementsBimElementInfo } from '../interfaces/measurements/measurements-bim-element-info';
import { MeasurementsState } from '../interfaces/measurements/measurements-state';

export const MEASUREMENT_INITIAL_STATE: MeasurementsState = {
  centralEditorRowPosition: null,
  centralTreeElement: null,
  elementSelectStatuses: {},
  statistic: [],
  activityMeasurement: [],
  searchedNodes: [],
  searchMode: false,
  dataLoaded: false,
  badIds: [],
  engineIds: new Array<MeasurementsBimElementInfo>(),
  viewTree: true,
  extractorFunctions: {},
  changedValues: [],
  rootIds: [],
  extractorEditorRows: [],
  showMeasurementEditor: false,
  modelBrowser: [],
  layerIds: [],
  extractors: {},
  selectedNodeId: null,
  errorLinks: [],
  hasChanges: false,
  statisticLoaded: false,
  generalExtractorEditors: {},
  isGeneralSelected: false,
  uncommitedChanges: {},
  cachedMeasurementsValues: [],
  cachedErrorLinks: [],
  areLevelsShown: false,
};
