import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import { InjectedFormProps } from 'redux-form';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import Form from '../../../../components/form';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { DatabaseResourceVariantActions } from '../../actions/creators/database-resource-variant';
import { ModifyDatabaseEntityMode, ResourceType } from '../../enums';
import { UnitModel } from '../../interfaces/data';
import { LaborVariantModel } from '../../interfaces/resources-data';
import { LaborVariantForm } from '../../interfaces/resources-rest-data';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';
import { DatabaseResourceLaborVariant } from './database-resource-labor-variant';

interface ReduxProps {
  variant: LaborVariantModel;
  resourceId: number;
  databaseId: number;
  units: UnitModel[];
}

interface ReduxActions {
  setEditResourceVariantModel: (variant: LaborVariantModel) => void;
  createDatabaseResourceVariant: (
    databaseId: number, resourceId: number, type: ResourceType, model: LaborVariantForm, formId: string,
  ) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps, InjectedFormProps<{}, {}> {
}

class CreateDatabaseResourceLaborVariantComponent extends React.Component<Props> {
  private readonly formId: string = 'create-labour-variant';

  public componentDidMount(): void {
    this.props.setEditResourceVariantModel(this.getDefaultVariantModel());
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Labour Variant'
        isApplyButtonDisabled={this.isCreateButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Create}
        readonly={this.props.readonly}
        onSubmit={this.create}
        form={this.formId}
        initialValues={this.getDefaultVariantModel()}
      >
      {
        this.props.variant ? (
          <KreoScrollbars>
            <Form handleSubmit={this.create}>
              <DatabaseResourceLaborVariant
                variant={this.props.variant}
                onChange={this.props.setEditResourceVariantModel}
                readonly={this.props.readonly}
              />
            </Form>
          </KreoScrollbars>
        ) : <SvgSpinner size='middle'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }


  @autobind
  private create(values: LaborVariantForm): void {
    this.props.createDatabaseResourceVariant(
      this.props.databaseId, this.props.resourceId, ResourceType.Labour, values, this.formId,
    );
  }

  private isCreateButtonDisabled(): boolean {
    const { variant } = this.props;
    return !variant ||
      !variant.name ||
      !variant.name.trim() ||
      !(variant.cost >= 0);
  }

  private getDefaultVariantModel(): LaborVariantModel {
    return {
      id: 0,
      name: '',
      cost: null,
      resourceId: this.props.resourceId,
      resourceName: null,
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    variant: editModel.variant,
    resourceId: editModel.rootId,
    databaseId: state.database.currentDatabase.database.id,
    units: state.database.currentDatabase.units,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditResourceVariantModel: variant =>
      dispatch(DatabaseResourceListingActions.setEditResourceVariantModel(variant)),
    createDatabaseResourceVariant: (databaseId, resourceId, type, model, formId) =>
      dispatch(DatabaseResourceVariantActions.createResourceVariant(databaseId, resourceId, type, model, formId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const CreateDatabaseResourceLaborVariant = connector(CreateDatabaseResourceLaborVariantComponent);
