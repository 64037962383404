import { H6, Text, RectangleButton } from '@kreo/kreo-ui-components';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { AppUrls } from 'routes/app-urls';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { Styled } from './styled';


interface DispatchProps {
  goBack: () => void;
}

interface Props extends DispatchProps { }

const PageNotFound2DComponent: React.FC<Props> = ({ goBack }) => {
  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.Img>
          <img src='/static/img/empty.png' />
        </Styled.Img>
        <H6>You don't have access to this project</H6>
        <Text color={'gray'} fontSize={12}>
          To get access to this project, please contact your company administrator
        </Text>
        <Styled.ContentContainer>
          <RectangleButton
            mood={'negative'}
            onClick={goBack}
            text={'Go Back'}
            width={125}
            height={50}
          />
        </Styled.ContentContainer>
      </Styled.Wrapper>
    </Styled.Container>
  );
};


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    goBack: () => {
      dispatch(push(AppUrls.qto2d.listing.path));
      dispatch(ProjectsActions.changeLoadingStatus());
      dispatch(ProjectsActions.setIsProjectNotFound(false));
    },
  };
};

export const PageNotFound2D = connect(null, mapDispatchToProps)(PageNotFound2DComponent);
