import * as React from 'react';

import './layout.scss';

import { RouteComponentProps, withRouter } from 'react-router';
import { FaqCaption } from 'common/enums/faq-caption';
import { LogoType } from 'common/enums/logo-type';
import { RoutingContextProps, withRoutingContext } from 'common/routing/with-routing-context';
import { KreoScrollbars } from 'common/UIKit';
import { MetaTagUtils } from 'common/utils/meta-tag-utils';
import { Logo } from '../../../components/logo';
import { AppUrls } from '../../../routes/app-urls';
import { MainHeader } from '../main-header';
import { NavMenu } from '../nav-menu';
import { NavMenuItem } from '../nav-menu/nav-menu-item';

interface LayoutProps extends RouteComponentProps<{}>, RoutingContextProps  {
  faqCaption?: FaqCaption;
  getMenuItems: (urls: any) => NavMenuItem[];
  subHeader?: React.ReactNode;
  hideCompaniesSelect?: boolean;
  notScrollContent?: boolean;
  metaTitle: string;
  backUrl?: string;
}

export class MainLayoutComponent extends React.Component<LayoutProps> {

  public componentDidMount(): void {
    MetaTagUtils.setTitle(this.props.metaTitle);
  }

  public render(): React.ReactNode {
    const {
      getMenuItems,
      subHeader,
      children,
      backUrl,
      faqCaption,
      hideCompaniesSelect,
      notScrollContent,
      urls,
    } = this.props;

    return (
      <div className='main-layout'>
        <div className='main-layout__header-row'>
          <div className='main-layout__logo-wrap'>
            <Logo
              logoType={this.getLogoType()}
              backUrl={backUrl}
            />
          </div>
          <div className='main-layout__header-wrap'>
            <MainHeader hideCompaniesSelect={hideCompaniesSelect} faqCaption={faqCaption} />
          </div>
        </div>
        <div className='main-layout__content-row'>
          <div className='main-layout__side-panel'>
            <NavMenu items={getMenuItems(urls)} />
          </div>
          <div className='main-layout__main-panel'>
            {
              subHeader
                ? (
                  <div className='main-layout__subheader-wrap'>
                    {subHeader}
                  </div>
                ) : null
            }
            <div className='main-layout__content-wrap'>
              {notScrollContent ?
                children :
                (
                  <KreoScrollbars>
                    {children}
                  </KreoScrollbars>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  private getLogoType(): LogoType {
    const path = this.props.match.path;
    if (path.startsWith(AppUrls.plan.index.url()) || path.startsWith(AppUrls.qto3d.index.url())) {
      return LogoType.LogoTakeoff3D;
    } else if (path.startsWith(AppUrls.qto2d.index.url())) {
      return LogoType.LogoTakeoff2D;
    }
    return undefined;
  }
}


export const MainLayout = withRoutingContext(withRouter(MainLayoutComponent));
