import styled from 'styled-components';
import { StringifiedIconStyled } from '../string-icon-renderer';

const Container = styled.div`
  width: 100%;
  height: 100%;
  color: ${p => p.theme.color.mainFont};
  font-family: 'ProximaSoft', sans-serif;
  .ag-header {
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
    &-row {
      color: ${p => p.theme.color.mainFont};
      font-size: 14px;
      line-height: 23px;
      font-weight: 400;
    }
    &-cell {
      padding-left: 20px;
    }
  }
  .ag-header-cell::after {
    background-color: ${p => p.theme.color.background};
  }
  .ag-menu-separator-part:after {
    border-color: ${p => p.theme.color.background};
  }
  .ag-menu-separator-cell:after {
    border-color: ${p => p.theme.color.background};
  }
  .ag-header-cell.ag-header-cell-moving {
    background-color: ${p => p.theme.color.background};
  }
  .ag-row {
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
    color: ${p => p.theme.color.mainFont};
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    cursor: pointer;
    &.ag-row-hover {
      background-color: ${p => p.theme.color.pale};
    }
    &.ag-row-focus {
      display: flex;
      align-items: center;
      box-shadow: inset 0 0 0px 2px ${p => p.theme.color.turquoise};
    }
  }
  .ag-cell-value {
    display: flex;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      height: 100%;
      span:first-child {
        height: 20px;
        svg {
          fill: ${p => p.theme.color.gray};
        }
      }

      > div {
        div {
          background-color: ${p => p.theme.color.turquoise};
          p {
            color: ${p => p.theme.color.white};
          }
        }
        p {
          color: ${p => p.theme.color.mainFont};
        }
      }
    }
  }
  .ag-row-group {
    font-weight: 600;
  }
  .ag-group-value {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      height: 100%;

      > span {
        display: flex;
        align-items: center;
      }
      span:first-child {
        svg {
          fill: ${p => p.theme.color.gray};
          g {
            path {
              stroke: ${p => p.theme.color.gray};
            }
          }
        }
      }
    }
  }
  .ag-ltr .ag-row-group-indent-1 {
    align-items: center;
  }
  .ag-root-wrapper {
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
  }
  .ag-icon {
    &.ag-icon-tree-closed {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-tree-open {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-desc {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-asc {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-menu {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-filter {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-columns {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-grip:before {
      color: ${p => p.theme.color.gray};
    }
  }
  .ag-menu {
    color: ${p => p.theme.color.mainFont};
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
  }
  .ag-menu-header {
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
  }
  .ag-tab-selected {
    background-color: ${p => p.theme.color.background};
    border-color: ${p => p.theme.color.background};
  }
  input.ag-input-field-input {
    background-color: ${p => p.theme.color.background};
  }
  .ag-filter-header-container {
    border-bottom-color: ${p => p.theme.color.background};
  }
  .ag-column-select-header {
    border-bottom-color: ${p => p.theme.color.background};
  }
  .ag-cell.ag-cell-auto-height.ag-cell-range-left.ag-cell-inline-editing {
    left: 5px !important;
    width: -webkit-fill-available !important;
  }
  .ag-cell-inline-editing {
    border-radius: 5px;
    border-color: ${p => p.theme.color.background};
    input[class^=ag-][type=text] {
      border-radius: 5px;
      border-color: ${p => p.theme.color.background};
      :focus {
        box-shadow: none;
        border-radius: 5px;
        border-color: ${p => p.theme.color.background};
      }
    }
  }
  input[class^=ag-][type=text] {
    border-color: ${p => p.theme.color.background};
    :focus {
      border-color: ${p => p.theme.color.background};
      box-shadow: none;
    }
  }
  .ag-menu-option-active {
    background-color: ${p => p.theme.color.pale};
  }
  .ag-checkbox-input-wrapper {
    background-color: ${p => p.theme.color.background};

    &::after {
      color: ${p => p.theme.color.disabled};
    }
    :focus-within {
      box-shadow: none;
    }
    &.ag-checked::after {
      color: ${p => p.theme.color.turquoise};
    }
  }

  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    color: ${p => p.theme.color.turquoise};
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  border-right-color: ${p => p.theme.color.background};
  }
  .ag-pinned-left-header {
    border-right-color: ${p => p.theme.color.background};
  }
  .ag-pinned-right-header {
    border-left-color: ${p => p.theme.color.background};
  }
  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    border-left-color: ${p => p.theme.color.background};
  }
  .ag-virtual-list-item.ag-column-select-virtual-list-item {
    cursor: pointer;
  }
  .ag-input-field-input.ag-checkbox-input {
    cursor: pointer;
  }
  .ag-set-filter-item {
    cursor: pointer;
  }
  .ag-input-field-input.ag-text-field-input {
    &::placeholder {
      color: ${p => p.theme.color.disabled};
    }
  }
  ${StringifiedIconStyled.Container} {
    svg {
      path {
        fill: ${p => p.theme.color.gray};
      }
      circle {
        fill: ${p => p.theme.color.gray};
      }
    }
  }

  .ag-body-viewport.ag-layout-normal.ag-row-animation {
    scroll-behavior: smooth;
  }
`;

export const Styled = {
  Container,
};
