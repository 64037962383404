const prefix = '@database-resource';

export const DatabaseResourceActionTypes = {
  GET_RESOURCES_REQUEST: `${prefix}/GET_RESOURCES_REQUEST`,
  GET_RESOURCES_SUCCEEDED: `${prefix}/GET_RESOURCES_SUCCEEDED`,
  GET_RESOURCE_REQUEST: `${prefix}/GET_RESOURCE_REQUEST`,
  GET_RESOURCE_SUCCEEDED: `${prefix}/GET_RESOURCE_SUCCEEDED`,
  CREATE_RESOURCE_REQUEST: `${prefix}/CREATE_RESOURCE_REQUEST`,
  CREATE_RESOURCE_SUCCEEDED: `${prefix}/CREATE_RESOURCE_SUCCEEDED`,
  UPDATE_RESOURCE_REQUEST: `${prefix}/UPDATE_RESOURCE_REQUEST`,
  UPDATE_RESOURCE_SUCCEEDED: `${prefix}/UPDATE_RESOURCE_SUCCEEDED`,
  DELETE_RESOURCE_REQUEST: `${prefix}/DELETE_RESOURCE_REQUEST`,
  DELETE_RESOURCE_SUCCEEDED: `${prefix}/DELETE_RESOURCE_SUCCEEDED`,
};
