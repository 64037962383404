import { useCallback, useEffect, useRef } from 'react';

export const useHandleEnter = (
  isArea?: boolean,
  onKeyEnterDown?: () => void,
): {
  handleEnterDown: (event: React.KeyboardEvent) => void,
  inputRef: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement>,
} => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>();
  const handleEnterDown = useCallback((event: React.KeyboardEvent) => {
    if (!isArea && event.key === 'Enter') {
      if (onKeyEnterDown) {
        onKeyEnterDown();
      }
      inputRef.current.blur();
    }
  }, [isArea, onKeyEnterDown]);

  return { handleEnterDown, inputRef };
};

export const useDisabledEffect = (
  disabled: boolean,
  inputRef: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement>): void => {
  useEffect(() => {
    if (disabled) {
      inputRef.current.blur();
    }
  }, [disabled]);
};

