import React, { useCallback, useMemo } from 'react';

import { Operation } from 'common/ability/operation';
import { useAbilityContext } from 'common/ability/use-ability-context';
import { DrawingsDrawMode } from '../../enums';
import { OptionsSubjectsMap } from '../helpers';
import { useSelectedSubvariants } from '../hooks/use-selected-subvariant';
import { InstrumentGroupInfo } from '../interfaces';
import { Instrument } from './instrument';
import { InstrumentMenuItem } from './instruments-menu-item';
import { SelectModeSettings } from './interfaces';
import { Styled } from './styled';
import { SubvariantButton } from './subvariants-menu/subvariant-button';


interface GroupProps {
  group: InstrumentGroupInfo;
  onClick: (settings: SelectModeSettings) => void;
  activeMenuItem: DrawingsDrawMode;
  canBeActive: boolean;
}

const InstrumentGroupComponent: React.FC<GroupProps> = ({
  group: { subInstruments, resultType, addSeparationBefore, groupId },
  onClick,
  activeMenuItem,
  canBeActive,
}) => {
  const onClickCallback = useCallback(
    ({ mode, image }) => {
      onClick({ mode, resultType, image });
    },
    [onClick, resultType],
  );

  const subvariants = useMemo(() => {
    return subInstruments.filter((subvariant) => !subvariant.hidden);
  }, [subInstruments]);

  const selectedInstrument = useSelectedSubvariants(
    subvariants,
    activeMenuItem,
    canBeActive,
    groupId,
  );

  const { ability } = useAbilityContext();
  const needUpdatePlan = useMemo(
    () => OptionsSubjectsMap[selectedInstrument.id]?.some((s) => ability.cannot(Operation.Update, s)),
    [selectedInstrument.id, ability],
  );

  if (!subvariants.length) {
    return null;
  }

  if (subvariants.length === 1) {
    return (
      <InstrumentMenuItem
        instrument={selectedInstrument}
        onClick={onClickCallback}
        isActive={canBeActive && activeMenuItem === selectedInstrument.id}
      />
    );
  }

  return (
    <Styled.InstrumentContainer
      addSeparationBefore={addSeparationBefore}
    >
      <Instrument
        instrument={selectedInstrument}
        onClick={onClickCallback}
        isActive={canBeActive && activeMenuItem === selectedInstrument.id}
        needUpdatePlan={needUpdatePlan}
      />
      <SubvariantButton
        activeMenuItem={canBeActive && activeMenuItem}
        subvariants={subvariants}
        onClick={onClickCallback}
      />
    </Styled.InstrumentContainer>
  );
};

export const InstrumentGroup = React.memo(InstrumentGroupComponent);
