import * as React from 'react';

export const KreoIconForward: React.FC = () => {
  return (
    <svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-197.000000, -568.000000)'>
        <g id='Timeline' transform='translate(60.000000, 556.000000)' stroke='#FFFFFF'>
          <g id='Top-panel'>
            <g id='Controls'>
              <g transform='translate(65.000000, 12.000000)' id='4D_controls_forward'>
                <g transform='translate(72.000000, 0.000000)'>
                  <g id='play'>
                    <circle id='Oval' strokeOpacity='0.2' cx='16' cy='16' r='15.5' />
                    <path d='M14,12.5 L14,19.5' id='Rectangle-7' strokeLinecap='round' strokeLinejoin='round' />
                    <polyline
                      id='Rectangle-7'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      points='17 12.5 20.5 16 17 19.5'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
