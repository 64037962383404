
export enum ModelCheckType {
  BadMaterials = 'BadMaterials',
  BadEngineeringSystems = 'BadEngineeringSystems',
  DifferentMepSystems = 'DifferentMepSystems',
  BadLayerConstructions = 'BadLayerConstructions',
  DifferentFunctionPairs = 'DifferentFunctionPairs',
  IntersectingPairs = 'IntersectingPairs',
  NonConnectingWithBearingElements = 'NonConnectingWithBearingElements',
}
