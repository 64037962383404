import { Constants } from '@kreo/kreo-ui-components';
import { isBoolean } from 'lodash';

import { DrawingsSnappingModes } from 'common/components/drawings/enums/drawing-snapping-modes';
import { SortData } from 'common/components/drawings/interfaces/drawing-measurement-sort';
import { SubscriptionType } from 'common/constants/subscription';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { PagesFilterValue } from '../../common/components/drawings/interfaces/drawing-filters';
import { ThemeToneInterface, ThemeType } from '../../components/theme-provider/interfaces';
import { ProjectsView } from '../2d-projects-page/2d-projects-content/constants';
import { SetProjectRevisionPayload, SetProjectsAccessFilterPayload } from './actions/payloads';
import { PersistedStorageActionTypes } from './actions/types';
import { THREE_D_SETTINGS_INITIAL_STATE } from './constants/initial-state';
import {
  PersistedStorageState,
  TableInnerClipboard,
  TwoDFullScreenMode,
  SplitterPaneSizePayload,
  PanelPositionInfo,
} from './interfaces/state';
import { PersistedStorageDrawingsSelectors } from './selectors/drawings-selectors';

export const PersistedStorageReducerMethods: ReducerMethods<PersistedStorageState> = {
  [PersistedStorageActionTypes.PERSIST_SELECTED_COMPANY_ID]: (s, selectedCompanyId: number) => {
    return new MonoliteHelper(s)
      .set(_ => _.selectedCompanyId, selectedCompanyId)
      .get();
  },
  [PersistedStorageActionTypes.PERSIST_SELECTED_PROJECT_REVISION_ID]: (s, payload: SetProjectRevisionPayload) => {
    const helper = new MonoliteHelper(s);
    if (!s.projectIdToRevisionId) {
      helper.set(_ => _.projectIdToRevisionId, {});
    }

    return helper
      .set(_ => _.projectIdToRevisionId[payload.projectId], payload.revisionId)
      .get();
  },
  [PersistedStorageActionTypes.DROP_SELECTED_COMPANY_ID]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.selectedCompanyId, null)
      .get();
  },
  [PersistedStorageActionTypes.PERSIST_CHAT_CLOUD_DISMISSED]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isChatCloudDismissed, true)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_ENGINE_AUTO_FOCUS]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isAutoFocusEngine, !s.isAutoFocusEngine)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_DISABLE_SHOW_DIALOG]: (s, dialogName: string) => {
    const helper = new MonoliteHelper(s);
    if (s.disableShowDialogList) {
      if (s.disableShowDialogList.includes(dialogName)) {
        helper.setFilter(
          _ => _.disableShowDialogList,
          _ => _ !== dialogName);
      } else {
        helper.setAppend((_) => _.disableShowDialogList, dialogName);
      }
    } else {
      helper.set((_) => _.disableShowDialogList, [dialogName]);
    }
    return helper
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_DISABLE_SHOW_TOUR]: (s, tourName: string) => {
    const helper = new MonoliteHelper(s);
    if (s.disableShowTourList) {
      helper.set((_) => _.disableShowTourList[tourName], !s.disableShowTourList[tourName]);
    } else {
      helper.set((_) => _.disableShowTourList, { [tourName]: true });
    }
    return helper
      .get();
  },
  [PersistedStorageActionTypes.UPDATE_VIEWED_RELEASE_NOTES_VERSION]: (s, currentRelease: string) => {
    return new MonoliteHelper(s)
      .set(_ => _.viewedReleaseNotesVersion, currentRelease)
      .get();
  },
  [PersistedStorageActionTypes.SAVE_SPLITTER_SIZE]: (state, payload: SplitterPaneSizePayload) => {
    const helper = new MonoliteHelper(state);
    if (!state.splitterPaneSize) {
      helper.set(_ => _.splitterPaneSize, {});
    }

    return helper
      .set(_ => _.splitterPaneSize[payload.nameType], payload.value)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_TWOD_DRAWING_BLACK_AND_WHITE]: (s) => {
    return new MonoliteHelper(s).set((_) => _.drawingBlackAndWhiteToggle, !s.drawingBlackAndWhiteToggle).get();
  },
  [PersistedStorageActionTypes.TOGGLE_TWOD_DRAWING_NIGHT_MODE]: (s) => {
    return new MonoliteHelper(s).set((_) => _.drawingNightModeToggle, !s.drawingNightModeToggle).get();
  },
  [PersistedStorageActionTypes.TOGGLE_TWOD_DRAWING_SNAPPING]: (s) => {
    const snappingToggle = isBoolean(s.drawingSnappingToggle) ? !s.drawingSnappingToggle : false;
    return new MonoliteHelper(s).set((_) => _.drawingSnappingToggle, snappingToggle).get();
  },
  [PersistedStorageActionTypes.SET_TWOD_DRAWING_OPACITY]: (s, payload: number) => {
    return new MonoliteHelper(s).set((_) => _.drawingOpacity, payload).get();
  },
  [PersistedStorageActionTypes.TOGGLE_PAGES_TABS_MINIFIED]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isPagesTabsMinified, !s.isPagesTabsMinified)
      .get();
  },
  [PersistedStorageActionTypes.SET_THEME]: (s, payload: ThemeType) => {
    return new MonoliteHelper(s)
      .set(_ => _.theme, payload)
      .get();
  },
  [PersistedStorageActionTypes.SET_THEME_TONE]: (s, payload: ThemeToneInterface) => {
    const stateUpdater = new MonoliteHelper(s);
    payload.theme === Constants.Theme.DARK_THEME.name
      ? stateUpdater.set(_ => _.darkTone, payload.tone)
      : stateUpdater.set(_ => _.lightTone, payload.tone);
    return stateUpdater.get();
  },
  [PersistedStorageActionTypes.TOGGLE_QUICK_SEARCH]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isQuickSearchVisible, !s.isQuickSearchVisible)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_MEASUREMENTS_SEARCH]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isMeasurementsSearchVisible, !s.isMeasurementsSearchVisible)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_AUTO_MOVE_TO_CELL]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isAutoMoveToCell, !s.isAutoMoveToCell)
      .get();
  },
  [PersistedStorageActionTypes.SET_2D_FULLSCREEN_MODE]: (
    s,
    payload: { type: TwoDFullScreenMode, projectId: string }) => {
    if (s.projectTwoDFullScreenMode) {
      const helper = new MonoliteHelper(s);
      if (s.projectTwoDFullScreenMode.length > 10) {
        helper.set(_ => _.projectTwoDFullScreenMode, s.projectTwoDFullScreenMode.slice(1));
      }
      return helper
        .setFilter(_ => _.projectTwoDFullScreenMode, (p) => p[0] !== payload.projectId)
        .setAppend(_ => _.projectTwoDFullScreenMode, [payload.projectId, payload.type])
        .get();
    } else {
      return new MonoliteHelper(s)
        .set(_ => _.projectTwoDFullScreenMode, [[payload.projectId, payload.type]])
        .get();
    }
  },
  [PersistedStorageActionTypes.SET_FILTERS_PAGES]: (s, payload: PagesFilterValue) => {
    return new MonoliteHelper(s)
      .set(_ => _.selectedFilterPages, payload)
      .get();
  },
  [PersistedStorageActionTypes.SET_SORT_DATA]: (s, payload: SortData) => {
    return new MonoliteHelper(s)
      .set(_ => _.selectedMeasurementSort, payload)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_PROJECT_MENU]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isCollapseMenu, !s.isCollapseMenu)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_DRAWING_KEEP_GROUP]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.drawingIsKeepGroup, !s.drawingIsKeepGroup)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_DRAWING_KEEP_OLD_POLYGON]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.drawingIsKeepPolygon, !s.drawingIsKeepPolygon)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_DRAWINGS_OFFSET_IS_STROKE]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.drawingOffsetIsStroke, !s.drawingOffsetIsStroke)
      .get();
  },
  [PersistedStorageActionTypes.SET_TWO_D_TABLE_INNER_CLIPBOARD]: (s, payload: TableInnerClipboard) => {
    const helper = new MonoliteHelper(s);

    if (!s.innerClipboard) {
      helper.set(_ => _.innerClipboard, { twoDTable: { rows: [], expectedClipboard: '' } });
    }

    helper.set(
      _ => _.innerClipboard.twoDTable,
      payload,
    );

    return helper
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_OPEN_DRAWINGS_ANNOTATION_LEGEND_PANEL]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isOpenDrawingsAnnotationLegendPanel, !s.isOpenDrawingsAnnotationLegendPanel)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_IS_DEMO_PROJECTS_COLLAPSED]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isDemoProjectCollapsed, !s.isDemoProjectCollapsed)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_FOLDERS_SECTION]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isFoldersSectionCollapsed, !s.isFoldersSectionCollapsed)
      .get();
  },
  [PersistedStorageActionTypes.SET_DRAWING_SNAPPING_MODES]: (
    s,
    payload: DrawingsSnappingModes[],
  ) => {
    return new MonoliteHelper(s).set(_ => _.drawingSnappingModes, payload).get();
  },
  [PersistedStorageActionTypes.TOGGLE_DRAWING_KEEP_MEASURE_NAME]: (s) => {
    return new MonoliteHelper(s).set(_ => _.drawingKeepMeasuresNames, _ => !_).get();
  },
  [PersistedStorageActionTypes.TOGGLE_DRAWINGS_AUTO_FOCUS]: (s) => {
    return new MonoliteHelper(s).set(_ => _.drawingAutofocusEnabled, _ => !_).get();
  },
  [PersistedStorageActionTypes.TOGGLE_KEEP_STRUCTURE]: (s) => {
    return new MonoliteHelper(s).set(_ => _.drawingKeepStructureEnabled, _ => !_).get();
  },
  [PersistedStorageActionTypes.TOGGLE_TWOD_DRAWING_MINIMAP_OPENED]: (s) => {
    return new MonoliteHelper(s).set(_ => _.drawingMinimapOpened, _ => !_).get();
  },
  [PersistedStorageActionTypes.SET_TWOD_DATA_BASE_COLUMNS_STATE]: (s, payload: { type: number, state: any[] }) => {
    const helper = new MonoliteHelper(s);

    if (!s.twoDDataBaseColumnState) {
      helper.set(_ => _.twoDDataBaseColumnState, {});
    }

    return helper.set(_ => _.twoDDataBaseColumnState[payload.type], payload.state).get();
  },
  [PersistedStorageActionTypes.TOGGLE_TWOD_ORTHOGONAL_MODE]: (s) => {
    return new MonoliteHelper(s).set(_ => _.drawingOrthogonalMode, _ => !_).get();
  },
  [PersistedStorageActionTypes.TOGGLE_HIDE_GROUPS]: (s) => {
    return new MonoliteHelper(s).set(_ => _.hideUnselectedPageGroups, _ => !_).get();
  },
  [PersistedStorageActionTypes.TOGGLE_DRAWINGS_AUTOCOMPLETE]: (s) => {
    return new MonoliteHelper(s).set(_ => _.drawingAutocomplete, _ => !_).get();
  },
  [PersistedStorageActionTypes.SET_ENTITIES_PANEL_POSITION]: (s, payload: PanelPositionInfo) => {
    const helper = new MonoliteHelper(s);

    if (!s.entityPanel) {
      helper.set(_ => _.entityPanel, {
        position: payload,
        show: true,
      });
    } else {
      helper.set(_ => _.entityPanel.position, payload);
    }

    return helper.get();
  },
  [PersistedStorageActionTypes.TOGGLE_ENTITIES_PANEL]: (s) => {
    const helper = new MonoliteHelper(s);

    if (!s.entityPanel) {
      helper.set(_ => _.entityPanel, {
        position: { top: null, width: null, left: null, height: null },
        show: true,
      });
    } else {
      helper.set(_ => _.entityPanel.show, !s.entityPanel.show);
    }

    return helper.get();
  },
  [PersistedStorageActionTypes.OPEN_ENTITIES_PANEL]: (s) => {
    const helper = new MonoliteHelper(s);
    if (!s.entityPanel) {
      helper.set(_ => _.entityPanel, {
        position: { top: null, width: null, left: null, height: null },
        show: true,
      });
    } else {
      helper.set(_ => _.entityPanel.show, true);
    }
    return helper.get();
  },
  [PersistedStorageActionTypes.SHOW_UNITS_TOGGLE]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.showTableUnits, _ => !_)
      .get();
  },
  [PersistedStorageActionTypes.SHOW_TABLE_TOTAL_TOGGLE]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.showTableTotal, _ => !_)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_TWOD_DRAWING_SHOW_LABEL]: (state) => {
    const showLabel = isBoolean(state.drawingsShowLabel) ? !state.drawingsShowLabel : false;
    return new MonoliteHelper(state)
      .set(_ => _.drawingsShowLabel, showLabel)
      .get();
  },
  [PersistedStorageActionTypes.SET_NEW_DRAWING_INSTANCE_STROKE_WIDTH]: (state, value: number) => {
    return new MonoliteHelper(state).set(_ => _.drawingNewInstanceStrokeWidth, value).get();
  },
  [PersistedStorageActionTypes.SET_SELECTED_CURRENCY]: (state, currency: string) => {
    return new MonoliteHelper(state)
      .set(_ => _.selectedCurrency, currency)
      .get();
  },
  [PersistedStorageActionTypes.SET_DRAWING_EXPORT_FONT_SIZE]: (state, fontSize: number) => {
    return new MonoliteHelper(state).set(_ => _.drawingExportFontSize, fontSize).get();
  },
  [PersistedStorageActionTypes.SET_DRAWING_EXPORT_SCALE_FACTOR]: (state, lineThickness: number) => {
    return new MonoliteHelper(state).set(_ => _.drawingExportLineThickness, lineThickness).get();
  },
  [PersistedStorageActionTypes.SET_PROJECTS_ACCESS_FILTER]: (
    state,
    { projectType, filterName }: SetProjectsAccessFilterPayload,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.projectAccessFilter, _ => _ || {})
      .set(_ => _.projectAccessFilter[projectType], filterName)
      .get();
  },
  [PersistedStorageActionTypes.SET_LAST_USED_PRODUCT_TYPE]: (s, payload: SubscriptionType) => {
    return new MonoliteHelper(s)
      .set(_ => _.lastUsedProductType, payload)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_DRAWING_USE_GROUP_NAME_FOR_NEW_GEOMETRY]: (s) => {
    const value = PersistedStorageDrawingsSelectors.selectGroupNameAsGeometry(s);
    return new MonoliteHelper(s)
      .set(_ => _.drawingUseGroupNameForNewGeometry, !value)
      .get();
  },
  [PersistedStorageActionTypes.SET_CURRENT_PATH_NAME]: (state, path: string) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentPathname, path)
      .get();
  },
  [PersistedStorageActionTypes.HIDE_SMALL_SCREEN_DISPLAY_WARNING]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.hideSmallScreenDialog, true)
      .get();
  },
  [PersistedStorageActionTypes.CLOSE_TRY_AI_PANEL]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isTryAiPanelShown, false)
      .get();
  },
  [PersistedStorageActionTypes.CHANGE_VIEW_PROJECTS]: (s, viewType: ProjectsView) => {
    return new MonoliteHelper(s)
      .set(_ => _.projectsViewType, viewType)
      .get();
  },
  [PersistedStorageActionTypes.SET_DRAWING_3D_SETTING]: (s, payload) => {
    if (!s.drawingThreeDSettings) {
      return new MonoliteHelper(s)
        .set(_ => _.drawingThreeDSettings, { ...THREE_D_SETTINGS_INITIAL_STATE, [payload.key]: payload.value })
        .get();
    } else {
      return new MonoliteHelper(s)
        .set(_ => _.drawingThreeDSettings[payload.key], payload.value)
        .get();
    }
  },
  [PersistedStorageActionTypes.TOGGLE_DRAWING_3D_SETTING]: (s, key) => {
    if (!s.drawingThreeDSettings) {
      return new MonoliteHelper(s)
        .set(_ => _.drawingThreeDSettings, {
          ...THREE_D_SETTINGS_INITIAL_STATE,
          [key]: !THREE_D_SETTINGS_INITIAL_STATE[key],
        })
        .get();
    } else {
      return new MonoliteHelper(s)
        .set(_ => _.drawingThreeDSettings[key], !s.drawingThreeDSettings[key])
        .get();
    }
  },

  [PersistedStorageActionTypes.UPDATE_VALUE]: (s, payload) => {
    return new MonoliteHelper(s)
      .set(_ => _[payload.key], payload.value)
      .get();
  },
  [PersistedStorageActionTypes.TOGGLE_VALUE]: (s, key: string) => {
    return new MonoliteHelper(s)
      .set(_ => _[key], !s[key])
      .get();
  },
};
