import * as React from 'react';

export const KreoIconIsolation = (): JSX.Element => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path
        // eslint-disable-next-line max-len
        d='M10.5,8.5 L13.5,8.5 C14.6045695,8.5 15.5,9.3954305 15.5,10.5 L15.5,13.5 C15.5,14.6045695 14.6045695,15.5 13.5,15.5 L10.5,15.5 C9.3954305,15.5 8.5,14.6045695 8.5,13.5 L8.5,10.5 C8.5,9.3954305 9.3954305,8.5 10.5,8.5 Z'
        id='Rectangle'
        stroke='currentColor'
        fillRule='nonzero'
      />
      <polyline id='Path-10' stroke='currentColor' fillRule='nonzero' points='5 7.5 7.5 7.5 7.5 5' />
      <polyline id='Path-10' stroke='currentColor' fillRule='nonzero' points='5 16.5 7.5 16.5 7.5 19' />
      <polyline id='Path-10' stroke='currentColor' fillRule='nonzero' points='19 7.5 16.5 7.5 16.5 5' />
      <polyline id='Path-10' stroke='currentColor' fillRule='nonzero' points='19 16.5 16.5 16.5 16.5 19' />
    </g>
  </svg>
);
