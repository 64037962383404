import formulajs from '@formulajs/formulajs';
import * as customFormula from './custom-formula';

const allowedFunctionsMap = [
  'ABS',
  'ACOS',
  'ACOSH',
  'ACOT',
  'ACOTH',
  'ADD',
  'AGGREGATE',
  'AND',
  'ARABIC',
  'A',
  'ASIN',
  'ASINH',
  'ATAN',
  'ATANH',
  'AVEDEV',
  'AVERAGE',
  'AVERAGEA',
  'AVERAGEIF',
  'AVERAGEIFS',
  'BASE',
  'BETAINV',
  'BITAND',
  'BITLSHIFT',
  'BITOR',
  'BITRSHIFT',
  'BITXOR',
  'CEILING',
  'CEILINGMATH',
  'CEILINGPRECISE',
  'CHAR',
  'CHIDIST',
  'CHIDISTRT',
  'CHIINV',
  'CHIINVRT',
  'CHITEST',
  'CHOOSE',
  'CLEAN',
  'CODE',
  'COMBIN',
  'COMBINA',
  'COMPLEX',
  'CONCAT',
  'CONCATENATE',
  'CONFIDENCE',
  'CONVERT',
  'CORREL',
  'COS',
  'COSH',
  'COT',
  'COTH',
  'COUNT',
  'COUNTA',
  'COUNTIF',
  'COUNTIFS',
  'COUNTBLANK',
  'COUNTUNIQUE',
  'COVARIANCEP',
  'COVARIANCES',
  'CRITBINOM',
  'CSC',
  'CSCH',
  'DB',
  'DDB',
  'DECIMAL',
  'DEGREES',
  'DELTA',
  'DEVSQ',
  'DGET',
  'DIVIDE',
  'DMAX',
  'DMIN',
  'EQ',
  'ERFC',
  'ERFCPRECISE',
  'EVEN',
  'EXACT',
  'EXP',
  'EXPONDIST',
  'FACT',
  'FACTDOUBLE',
  'FDIST',
  'FIND',
  'FISHER',
  'FISHERINV',
  'FLOOR',
  'FLOORMATH',
  'FORECAST',
  'FREQUENCY',
  'FTEST',
  'FV',
  'FVSCHEDULE',
  'GAMMA',
  'GAMMADIST',
  'GAMMAINV',
  'GAMMALN',
  'GAMMALNPRECISE',
  'GAUSS',
  'GCD',
  'GEOMEAN',
  'GESTEP',
  'GTE',
  'HARMEAN',
  'HLOOKUP',
  'HYPGEOMDIST',
  'IFERROR',
  'IFNA',
  'IMABS',
  'IMAGINARY',
  'IMARGUMENT',
  'IMCONJUGATE',
  'IMCOS',
  'IMCOSH',
  'IMCOT',
  'IMCSC',
  'IMCSCH',
  'IMDIV',
  'IMEXP',
  'IMLN',
  'IMPOWER',
  'IMPRODUCT',
  'IMREAL',
  'IMSEC',
  'IMSECH',
  'IMSIN',
  'IMSINH',
  'IMSQRT',
  'IMSUB',
  'IMSUM',
  'IMTAN',
  'INDEX',
  'INT',
  'INTERCEPT',
  'IPMT',
  'ISBLANK',
  'ISEVEN',
  'ISLOGICAL',
  'ISNONTEXT',
  'ISOCEILING',
  'ISODD',
  'KURT',
  'LARGE',
  'LCM',
  'LEFT',
  'LEN',
  'LINEST',
  'LN',
  'LOGEST',
  'LOGINV',
  'LOGNORMINV',
  'LOOKUP',
  'LOWER',
  'LT',
  'LTE',
  'MATCH',
  'MAX',
  'MAXA',
  'MEDIAN',
  'MID',
  'MIN',
  'MINA',
  'MINUS',
  'MIRR',
  'MODEMULT',
  'MODESNGL',
  'MROUND',
  'MULTIPLY',
  'NE',
  'NEGBINOM',
  'NORMINV',
  'NORMSINV',
  'NOT',
  'ODD',
  'OR',
  'PDURATION',
  'PEARSON',
  'PERCENTILE',
  'PERCENTILEEXC',
  'PERCENTRANK',
  'PERCENTRANKEXC',
  'PERCENTRANKINC',
  'PERMUT',
  'PERMUTATIONA',
  'PHI',
  'PMT',
  'POISSON',
  'POISSONDIST',
  'POW',
  'POWER',
  'PPMT',
  'PROPER',
  'QUARTILE',
  'QUARTILEEXC',
  'QUOTIENT',
  'RADIANS',
  'RANK',
  'RATE',
  'REGEXEXTRACT',
  'REGEXMATCH',
  'REGEXREPLACE',
  'REPT',
  'RIGHT',
  'ROMAN',
  'ROUND',
  'ROUNDDOWN',
  'ROUNDUP',
  'ROWS',
  'RRI',
  'RSQ',
  'SEC',
  'SECH',
  'SIGN',
  'SIN',
  'SINH',
  'SKEW',
  'SKEWP',
  'SLN',
  'SLOPE',
  'SQRT',
  'SQRTPI',
  'STANDARDIZE',
  'STDEV',
  'STDEVA',
  'STDEVP',
  'STDEVPA',
  'STDEVS',
  'SUBSTITUTE',
  'SUMPRODUCT',
  'SUMSQ',
  'SWITCH',
  'SYD',
  'T',
  'TAN',
  'TANH',
  'TRIM',
  'TRIMMEAN',
  'TRUNC',
  'TTEST',
  'UNICHAR',
  'UNICODE',
  'UPPER',
  'VAR',
  'VARA',
  'VARP',
  'VLOOKUP',
  'WEIBULL',
  'WEIBULLDIST',
  'XOR',
  'ZTEST',
];

const customFunctionList = [
  'TEXTJOIN',
  'REPLACE',
  'IF',
  'XLOOKUP',
  'SUM',
  'SUMIF',
  'SUMIFS',
  'SMALL',
  'PI',
  'COUNT',
  'MIN',
  'MAX',
  'LOOKUP',
  'RIGHT',
  'LEFT',
  'LEN',
];

export const ExcelExpressionEvaluatorOverrideFunctions: Record<string, any> = {};

allowedFunctionsMap.forEach(formula => {
  if (formula in formulajs) {
    ExcelExpressionEvaluatorOverrideFunctions[formula.toLowerCase()] = formulajs[formula];
  }
});

customFunctionList.forEach(formula => {
  if (formula in customFormula) {
    ExcelExpressionEvaluatorOverrideFunctions[formula.toLowerCase()] = customFormula[formula];
  }
});

export const functionNameRegex = new RegExp(Object.keys(ExcelExpressionEvaluatorOverrideFunctions).join('\\(|'), 'ig');


export const ExcelExpressionEvaluatorAvaliableDefaultFunctions: string[] = [];
