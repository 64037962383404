import { Icons } from '@kreo/kreo-ui-components';

import { UnitTypes } from './unit-util';

export const MeasureUtil = {
  measureUnit: {
    area: UnitTypes.M2,
    perimeter: UnitTypes.M,
    length: UnitTypes.M,
    count: UnitTypes.Nr,
    pointsCount: UnitTypes.Nr,
    segmentsCount: UnitTypes.Nr,
    width: UnitTypes.M,
    height: UnitTypes.M,
    segmentLength: UnitTypes.M,
    rectangleMin: UnitTypes.M,
    rectangleMax: UnitTypes.M,
    volume: UnitTypes.M3,
    verticalArea: UnitTypes.M2,
    thickness: UnitTypes.M,
    table: null,
    name: null,
    drawingName: null,
    groupName: null,
    documentName: null,
    property: null,
    dynamicTable: null,
  },
  measureIcon: {
    area: Icons.Area2D,
    perimeter: Icons.Perimeter,
    length: Icons.Line,
    count: Icons.Point,
    width: Icons.WidthRectangle,
    height: Icons.HeightRectangle,
    volume: Icons.Volume,
    verticalArea: Icons.VerticalArea,
    thickness: Icons.WidthRectangle,
    name: Icons.Name2D,
    table: Icons.Table2D,
    groupName: Icons.Group_2,
    segmentLength: Icons.WidthRectangle,
    drawingName: Icons.PageName2D,
    documentName: Icons.PageName2D,
    property: Icons.PageName2D,
    dynamicTable: Icons.DynamicMoveToCell,
    pointsCount: Icons.PointsCount,
    segmentsCount: Icons.SegmentsCount,
  },
  measureVisibleName: {
    pointsCount: 'Points Count',
    segmentsCount: 'Segments Count',
    verticalArea: 'Vertical Area',
  },
  measure3dKey: {
    volume: 'volume',
    verticalArea: 'verticalArea',
    thickness: 'thickness',
    height: 'height',
  },
};

