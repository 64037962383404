import { TinyText } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 10px 0 20px;
  :not(:last-child) {
    border-bottom: 10px solid ${p => p.theme.color.pale};
  }
  :last-child {
    margin-bottom: 0px;
  }
`;

const Title = styled(TinyText)`
  margin-bottom: 15px;
`;

export const Styled = {
  Container,
  Title,
};
