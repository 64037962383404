import styled from 'styled-components';

import { SelectFilterStyled } from '../../drawings-annotation-legend-filters-menu/select-filter';


const SelectFilterContainer = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid ${p => p.theme.color.background};
  margin-bottom: 5px;
  z-index: ${p => p.isOpen ? 2 : 1};

  > p {
    line-height: 14px;
  }

  ${SelectFilterStyled.Container} {
    margin-bottom: 0px;
    height: 20px;
    max-width: 110px;
  }
`;


export const Styled = {
  SelectFilterContainer,
};
