import styled from 'styled-components';

const IconContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: ${p => p.theme.color.backgroundRush};
  transition: all 0.25s ease;

  > svg {
    fill: ${p => p.theme.color.gray};
    width: 10px;
    height: 10px;
    transform: ${p => p.isOpen ? 'rotate(0deg)' : 'rotate(-90deg)'};
    transition: all 0.35s ease;
  }
`;

const Container = styled.div`
  width: max-content;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.35s ease;
  cursor: pointer;

  &:hover button {
    transform: translateY(-1px);
  }
  &:active button{
    transform: scale(0.95) translateY(2px);
  }
  &:hover button p {
    color: ${p => p.theme.color.turquoise};
  }
  &:hover ${IconContainer} {
    background: ${p => p.theme.color.turquoise};

    svg {
      transition: all 0.25s ease;
      transform: rotate(0deg);
      fill: ${p => p.theme.color.white};
    }
  }
`;

const ButtonContainer = styled.div<{ isLongName: boolean }>`
  > button {
    background: ${p => p.theme.color.background};
    border-radius: 5px;
    width: 100%;

    &:hover {
      background: ${p => p.theme.color.backgroundRush};
      > p {
        color: ${p => p.theme.color.mainFont} !important;
      }
    }

    > p {
      max-width: 529px;
      padding: 2px 6px;
      text-align: center;
      line-height: 21px;
      color: ${p => p.theme.color.mainFont};
      font-size: 14px;
      ${p => p.isLongName &&
        `
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `}
    }
  }
`;

export const Styled = {
  Container,
  ButtonContainer,
  IconContainer,
};
