import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './resource-selector-popup.scss';

import { State } from 'common/interfaces/state';
import {
  KreoDialogComponent,
} from 'common/UIKit/dialogs/kreo-dialog';
import { mathUtils } from 'common/utils/math-utils';

import { ResourcesData } from '../../../../interfaces/gantt-chart';
import {
  ChartDataProvider,
  ResourceDescription,
  ResourceType,
  resourceTypeValues,
} from '../../../../utils/gantt-chart';
import { ResourceIdentifier } from '../../interfaces/resource-identifier';
import {
  ResourceMouseEventHandlerDictionary,
} from '../../interfaces/resource-mouse-event-handler-dictionary';
import { actions } from '../resource-selector/actions';

interface PopupOwnProps {
  dataProvider: ChartDataProvider;
}

interface PopupStateProps {
  isOpen: boolean;
  selectedResource: ResourceIdentifier;
  clientX: number;
  clientY: number;
  resources: ResourcesData;
  selectedTab: ResourceType;
  chartId: number;
}

interface PopupDispatchProps {
  selectResource: (chartId: number, resource: ResourceIdentifier) => void;
  selectTab: (tab: ResourceType) => void;
  closePopup: () => void;
  previewResource: (chartId: number, resource: ResourceIdentifier) => void;
  resetResourcePreview: () => void;
}

interface PopupProps extends PopupOwnProps, PopupStateProps, PopupDispatchProps { }

class ResourceSelectorPopupComponent extends React.Component<PopupProps> {
  private readonly popupMaxHeight: number = 400;

  private tabButtonClickHandlers: { [type: number]: (event: React.MouseEvent<HTMLDivElement>) => void } = {};
  private resourceItemClickHandlers: ResourceMouseEventHandlerDictionary = {};
  private resourceItemMouseEnterHandlers: ResourceMouseEventHandlerDictionary = {};
  private popupRef: HTMLDivElement;


  constructor(props: PopupProps) {
    super(props);
    this.popupRef = document.createElement('div');
  }

  public componentDidMount(): void {
    const root = KreoDialogComponent.getRoot();
    root.appendChild(this.popupRef);
  }


  public componentWillUnmount(): void {
    const root = KreoDialogComponent.getRoot();
    root.removeChild(this.popupRef);
  }

  public render(): React.ReactNode {
    if (!this.props.isOpen) {
      return null;
    }

    const availabilityMap = new Map<number, boolean>();
    const resources = Array.from(this.props.resources[this.props.selectedTab].values());
    for (const resource of resources) {
      availabilityMap.set(resource.id, this.props.dataProvider.isResourceAvailable(resource.type, resource.id));
    }

    return ReactDOM.createPortal(
      (
        <React.Fragment>
          <div
            className='resource-selector-popup__backdrop'
            onClick={this.onOutsideClick}
          />
          <div
            className='resource-selector-popup'
            style={{
              top: mathUtils.clamp(this.props.clientY, 0, window.innerHeight - this.popupMaxHeight),
              left: this.props.clientX,
            }}
          >
            <div className='resource-selector-popup__tabs-line'>
              {
                resourceTypeValues.map((type) => {
                  const className = classNames(
                    'resource-selector-popup__tab-button',
                    { active: type === this.props.selectedTab });

                  return (
                    <div
                      className={className}
                      key={type}
                      onClick={this.getTabButtonClickHandler(type)}
                    >
                      <span className={'resource-selector-popup__tab-label'}>
                        {`${ResourceType[type].toUpperCase()}S`}
                      </span>
                    </div>
                  );
                })
              }
            </div>
            <div className='resource-selector-popup__body'>
              {
                resources
                  .sort((r1, r2) => {
                    const availabilityCriterion = (+availabilityMap.get(r2.id)) - (+availabilityMap.get(r1.id));
                    return availabilityCriterion === 0
                      ? r1.displayName.trim().localeCompare(r2.displayName.trim())
                      : availabilityCriterion;
                  })
                  .map((resource) => {
                    const selected = resource.type === this.props.selectedResource.type &&
                      resource.id === this.props.selectedResource.id;
                    const isAvailable = this.props.dataProvider.isResourceAvailable(resource.type, resource.id);

                    const className = classNames(
                      'resource-selector-popup__resource-item',
                      {
                        selected,
                        'not-available': !isAvailable,
                      });


                    return (
                      <div
                        className={className}
                        key={`${resource.type}_${resource.id}`}
                        onClick={this.getResourceItemClickHandler(resource)}
                        onMouseEnter={this.getResourceItemMouseEnterHandler(resource)}
                        onMouseLeave={this.onResourceItemMouseLeave}
                      >
                        <span className='resource-selector-popup__resource-name'>{resource.displayName}</span>
                      </div>
                    );
                  })
              }
            </div>
          </div>
        </React.Fragment>),
      this.popupRef);
  }

  @autobind
  private onOutsideClick(): void {
    this.props.closePopup();
  }

  private getTabButtonClickHandler(type: ResourceType): (event: React.MouseEvent<HTMLDivElement>) => void {
    if (!this.tabButtonClickHandlers[type]) {
      this.tabButtonClickHandlers[type] = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
        this.props.selectTab(type);
      };
    }

    return this.tabButtonClickHandlers[type];
  }

  private getResourceItemClickHandler(
    resource: ResourceDescription,
  ): (event: React.MouseEvent<HTMLDivElement>) => void {
    if (!this.resourceItemClickHandlers[resource.type] || !this.resourceItemClickHandlers[resource.type][resource.id]) {
      if (!this.resourceItemClickHandlers[resource.type]) {
        this.resourceItemClickHandlers[resource.type] = {};
      }

      this.resourceItemClickHandlers[resource.type][resource.id] = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
        this.props.selectResource(this.props.chartId, { type: resource.type, id: resource.id });
        this.props.closePopup();
        this.props.resetResourcePreview();
      };
    }

    return this.resourceItemClickHandlers[resource.type][resource.id];
  }

  private getResourceItemMouseEnterHandler(
    resource: ResourceDescription,
  ): (event: React.MouseEvent<HTMLDivElement>) => void {
    if (
      !this.resourceItemMouseEnterHandlers[resource.type] ||
      !this.resourceItemMouseEnterHandlers[resource.type][resource.id]
    ) {
      if (!this.resourceItemMouseEnterHandlers[resource.type]) {
        this.resourceItemMouseEnterHandlers[resource.type] = {};
      }

      this.resourceItemMouseEnterHandlers[resource.type][resource.id] = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
        this.props.previewResource(this.props.chartId, { type: resource.type, id: resource.id });
      };
    }

    return this.resourceItemMouseEnterHandlers[resource.type][resource.id];
  }

  @autobind
  private onResourceItemMouseLeave(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    event.nativeEvent.stopPropagation();
    this.props.resetResourcePreview();
  }
}

const mapStateToProps = (state: State): PopupStateProps => {
  const chartId = state.fourDVisualisation.sidePanel.resourcesTab.resourceSelector.chartId;
  const chartState = state.fourDVisualisation.sidePanel.resourcesTab.charts[chartId];
  const selectedResource = chartState
    ? chartState.selectedResource
    : null;

  return {
    isOpen: state.fourDVisualisation.sidePanel.resourcesTab.resourceSelector.isOpen,
    clientX: state.fourDVisualisation.sidePanel.resourcesTab.resourceSelector.clientX,
    clientY: state.fourDVisualisation.sidePanel.resourcesTab.resourceSelector.clientY,
    selectedResource,
    selectedTab: state.fourDVisualisation.sidePanel.resourcesTab.resourceSelector.selectedTab,
    resources: state.fourDVisualisation.resources,
    chartId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): PopupDispatchProps => {
  return {
    closePopup: () => { dispatch(actions.closePopup()); },
    selectResource: (chartId: number, resource: ResourceIdentifier) => {
      dispatch(actions.selectResource(chartId, resource));
    },
    selectTab: (tab: ResourceType) => { dispatch(actions.setTab(tab)); },
    previewResource: (chartId: number, resource: ResourceIdentifier) => {
      dispatch(actions.previewResource(chartId, resource));
    },
    resetResourcePreview: () => {
      dispatch(actions.resetResourcePreview());
    },
  };
};

export const ResourceSelectorPopup = connect(mapStateToProps, mapDispatchToProps)(ResourceSelectorPopupComponent);
