import styled from 'styled-components';

const Container = styled.div`
  max-width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar-thumb {
    opacity: 0;
    visibility: hidden;
    background: ${p => p.theme.color.disabled};
  }

  :hover {
    ::-webkit-scrollbar-thumb {
      opacity: 1;
      visibility: visible;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${p => p.theme.color.gray};
    }
  }
`;

const TabsList = styled.div`
  display: flex;
  height: 100%;
`;

const IconArrow = styled.i`
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${p => p.theme.color.pale};
  opacity: 0.8;
  transition: ${p => p.theme.duration.s};

  svg {
    transition: ${p => p.theme.duration.s};
    width: 10px;
    fill: ${p => p.theme.color.turquoiseInversed};
  }

  &:hover {
    background: ${p => p.theme.color.turquoise};
    svg {
      fill: ${p => p.theme.color.white};
    }
  }

  &:active {
    transform:  scaleX(0.9) scaleY(0.9) scaleZ(0.9)  ;
  }
`;

const ScrollButton = styled.div<{ enabled: boolean, left?: boolean }>`
  display: ${p => p.enabled ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0;
  right: ${p => p.left ? 'unset' : '0'};
  left: ${p => p.left ? '0' : 'unset'};
  color: ${p => p.theme.color.mainFont};
  background-color: ${p => p.theme.color.backgroundRush};
  transform: rotate(${p => p.left ? 180 : 0}deg);
  cursor: pointer;
  z-index: 2;

  &:hover ${IconArrow} {
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    width: 60px;
    height: 100%;
    cursor: default;
    pointer-events: none;
    background: transparent linear-gradient(
      270deg,
      ${p => p.theme.color.backgroundRush} 0%,
      ${p => p.theme.color.backgroundRush}00 100%
    ) 0% 0% no-repeat padding-box;
  }
`;

export const Styled = {
  Container,
  TabsList,
  ScrollButton,
  IconArrow,
};
