import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


interface Props {
  onButtonClick: () => void;
  buttonText: string;
  buttonMood?: string;
  buttonWidth?: number;
}

export const DrawingsFailedMessageContainer: React.FC<Props> = ({
  onButtonClick,
  buttonText,
  children,
  buttonMood = 'negative',
  buttonWidth = 120,
}) => {
  return (
    <Styled.Container>
      <Icons.Error color={'red'} />
      {children}
      <Styled.ButtonContainer>
        <RectangleButton
          height={30}
          width={buttonWidth}
          text={buttonText}
          mood={buttonMood}
          onClick={onButtonClick}
        />
      </Styled.ButtonContainer>
    </Styled.Container>
  );
};

