
import React, { useContext } from 'react';

import {
  DrawingsRendererApiContext,
  DrawingsRendererApiContextProps,
  DrawingsRendererApiContextSetter,
  DrawingsRendererApiSetter,
} from './renderer-api-context';

export function withRendererApiContext<P>(
  Component: React.ComponentType<P & DrawingsRendererApiContextProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawingsRendererApiContextProps>>> {
  return (props: P) => {
    const api = useContext(DrawingsRendererApiContext);
    return <Component {...props} {...api}/>;
  };
}

export interface DrawingsRendererApiFullContextProps extends DrawingsRendererApiContextProps {
  setRendererApi: DrawingsRendererApiSetter;
}

export function withFullRendererApiContext<P>(
  Component: React.ComponentType<P & DrawingsRendererApiFullContextProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawingsRendererApiFullContextProps>>> {
  return (props: P) => {
    const setter = useContext(DrawingsRendererApiContextSetter);
    const api = useContext(DrawingsRendererApiContext);
    return <Component {...props} setRendererApi={setter} {...api}/>;
  };
}
