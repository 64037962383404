import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { ConstantFunctions } from 'common/constants/functions';
import { DrawingContextObserver } from '../../drawings-contexts';
import { DrawingsRenderParams } from '../../interfaces';
import { DrawingsCanvasRect } from '../../interfaces/drawings-canvas-rect';
import { DrawingsPaperUtils } from '../../utils/drawings-paper-utils';
import { DrawingsGeometrySymbolsStore } from './drawings-geometry-symbols-store';
import { LayerDoubleClickProcessor } from './geometry-event-processors/layer-double-click-processor';
import { PaperMouseEventUtils } from './paper-mouse-event-utils';


export interface EventConfiguration {
  onLeftMouseClick: (e: PaperMouseEvent) => void;
  onRightMouseClick: (e: PaperMouseEvent) => void;
  onMouseDown: (e: PaperMouseEvent) => void;
  onMouseUp: (e: PaperMouseEvent) => void;
  onMouseMove: (e: PaperMouseEvent) => void;
  onDoubleClick: (e: PaperMouseEvent) => void;
  onMouseLeave?: (e: PaperMouseEvent) => void;
}

interface DrawingsViewHelperConfiguration {
  scope: paper.PaperScope;
  eventDefaults: EventConfiguration;
  sharedSpaceBarHeldContext?: DrawingContextObserver<boolean>;
  zoomObserver?: DrawingContextObserver<DrawingsRenderParams>;
}

export class DrawingsViewHelper implements EventConfiguration {
  private _mouseMoveHandler: (e: PaperMouseEvent) => void;
  private _mouseDownHandler: (e: PaperMouseEvent) => void;
  private _leftMouseClick: (e: PaperMouseEvent) => void;
  private _rightMouseClick: (e: PaperMouseEvent) => void;
  private _onMouseUp: (e: PaperMouseEvent) => void;
  private _onDoubleClick: (e: PaperMouseEvent) => void;
  private _onMouseLeave: (e: PaperMouseEvent) => void;

  private _config: DrawingsViewHelperConfiguration;

  private _canvasRef: HTMLCanvasElement;
  private _canvasWrapperRef: HTMLDivElement;

  private _disableListeners: boolean;

  private _layerDblClickProcessor: LayerDoubleClickProcessor;

  constructor(config: DrawingsViewHelperConfiguration) {
    this._config = config;
    this._layerDblClickProcessor = new LayerDoubleClickProcessor({
      zoomObserver: this._config.zoomObserver,
    });
  }

  public init(canvas: HTMLCanvasElement): void {
    this._config.scope.setup(canvas);
    DrawingsGeometrySymbolsStore.init();
    this.initDefaultEvents();
    this._canvasRef = canvas;
  }

  public set disableListeners(value: boolean) {
    this._disableListeners = value;
  }

  public set cursor(value: string) {
    this._config.scope.view.element.style.cursor = value;
  }

  public set onMouseLeave(value: (e: PaperMouseEvent) => void) {
    this._onMouseLeave = value;
  }

  public set onDoubleClick(value: (e: PaperMouseEvent) => void) {
    this._onDoubleClick = value;
  }

  public set onLeftMouseClick(value: (e: PaperMouseEvent) => void) {
    this._leftMouseClick = value;
  }

  public set onRightMouseClick(value: (e: PaperMouseEvent) => void) {
    this._rightMouseClick = value;
  }

  public set onMouseDown(value: (e: PaperMouseEvent) => void) {
    this._mouseDownHandler = value;
  }

  public set onMouseUp(value: (e: PaperMouseEvent) => void) {
    this._onMouseUp = value;
  }

  public set onMouseMove(value: (e: PaperMouseEvent) => void) {
    this._mouseMoveHandler = value;
  }

  public restoreDefaultEvents(eventNames: Array<keyof EventConfiguration>): void {
    eventNames.forEach(this.restoreDefaultEvent);
  }

  @autobind
  public rotate(rotation: number): void {
    this._config.scope.view.rotation = rotation;
  }

  @autobind
  public updateCanvasRenderParameters({ left, top, x1, x2, y1, y2 }: DrawingsCanvasRect): void {
    this._canvasWrapperRef.style.left = `${left}px`;
    this._canvasWrapperRef.style.top = `${top}px`;
    DrawingsPaperUtils.changeViewCenter(x1, y1, x2, y2, this._config.scope);
  }

  @autobind
  public setDrawingsWrapperRef(drawingCanvasWrapperRef: HTMLDivElement): void {
    this._canvasWrapperRef = drawingCanvasWrapperRef;
  }

  @autobind
  public getDrawingsWrapperRef(): HTMLDivElement {
    return this._canvasWrapperRef;
  }

  @autobind
  public setCanvasSize(width: number, height: number): void {
    this._config.scope.view.viewSize = new paper.Size(width, height);
    this._canvasRef.width = width;
    this._canvasRef.height = height;
  }

  @autobind
  public setViewportZoom(zoom: number): void {
    this._config.scope.view.zoom = zoom;
  }

  @autobind
  public restoreDefaultEvent(eventName: keyof EventConfiguration): void {
    this[eventName] = this._config.eventDefaults[eventName];
  }

  private initDefaultEvents(): void {
    this._config.scope.view.onClick = this.onClick;
    this._config.scope.view.onMouseMove = this.layoutMouseMove;
    this._config.scope.view.onMouseDown = this.layoutMouseDown;
    this._config.scope.view.onMouseUp = this.layoutMouseUp;
    this._config.scope.view.onMouseLeave = this.layoutMouseLeft;
    Object.keys(this._config.eventDefaults).forEach((key: keyof EventConfiguration) => this.restoreDefaultEvent(key));
  }

  @autobind
  private doubleClick(e: PaperMouseEvent): void {
    if (this._disableListeners) {
      return;
    }
    if (this._onDoubleClick) {
      this._onDoubleClick(e);
    }
  }

  @autobind
  private onClick(e: PaperMouseEvent): void {
    if (this._disableListeners) {
      return;
    }
    if (PaperMouseEventUtils.isRightMouseButton(e) && this._rightMouseClick) {
      this._rightMouseClick(e);
    } else if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      if (this._config.sharedSpaceBarHeldContext?.getContext()) {
        ConstantFunctions.stopEvent(e);
        return;
      }

      this._layerDblClickProcessor.onClick(e, this._leftMouseClick, this.doubleClick);
    }
  }

  @autobind
  private layoutMouseLeft(e: PaperMouseEvent): void {
    if (this._disableListeners) {
      return;
    }
    if (this._onMouseLeave) {
      this._onMouseLeave(e);
    }
  }

  @autobind
  private layoutMouseUp(e: PaperMouseEvent): void {
    if (this._disableListeners) {
      return;
    }
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      if (this._config.sharedSpaceBarHeldContext?.getContext()) {
        ConstantFunctions.stopEvent(e);
        return;
      }
      if (this._onMouseUp) {
        this._onMouseUp(e);
      }
    }
  }

  @autobind
  private layoutMouseDown(e: PaperMouseEvent): void {
    if (this._disableListeners) {
      return;
    }
    if (!this._config.sharedSpaceBarHeldContext?.getContext() && this._mouseDownHandler) {
      this._mouseDownHandler(e);
    }
  }

  @autobind
  private layoutMouseMove(e: PaperMouseEvent): void {
    if (this._disableListeners) {
      return;
    }
    if (this._mouseMoveHandler) {
      this._mouseMoveHandler(e);
    }
  }
}
