import * as React from 'react';
import { KreoDialog } from 'common/UIKit';
import { RevisionSettingContent } from './revision-setting-content';


export const REVISION_SETTING_DIALOG = 'REVISION_SETTING_DIALOG';

export class RevisionSettingDialog extends React.Component<{}> {
  public render(): JSX.Element {
    return (
      <KreoDialog
        name={REVISION_SETTING_DIALOG}
        title='Project Revisions'
      >
        <RevisionSettingContent />
      </KreoDialog>
    );
  }
}

