import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { AnyAction, Dispatch } from 'redux';

import { KreoToolbarButton } from 'common/components/kreo-toolbar/kreo-toolbar-button';
import { State } from 'common/interfaces/state';
import { KreoIconPropertiesInfo } from 'common/UIKit';
import { ModelCheckActions } from '../../actions';
import * as Types from '../../interfaces/types';
import { PropertiesPopup } from './properties-popup';

interface StateProps {
  showPopUp: boolean;
  secondHandle: Types.SelectedHandle;
  selectedHandle: Types.SelectedHandle;
}

interface DispatchProps {
  onShowHide: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface OwnProps {
  containerRef: HTMLDivElement;
}

interface Props extends OwnProps, DispatchProps, StateProps {
}

class PropertiesViewComponent extends React.Component<Props> {

  public render(): React.ReactNode {
    if (!this.props.containerRef) {
      return null;
    }
    return (
      <>
        <KreoToolbarButton
          controlName='properties-popup-button'
          onClick={this.props.onShowHide}
          active={this.props.showPopUp}
          defaultTooltipText='Properties'
          disabled={!this.props.selectedHandle}
          disabledTooltipText='Select Element to unlock'
        >
          <KreoIconPropertiesInfo />
        </KreoToolbarButton>
        {this.renderPopup()}
      </>
    );
  }

  @autobind
  private renderPopup(): React.ReactNode {
    return ReactDOM.createPortal(
      (
        <CSSTransition
          in={this.props.showPopUp && !!this.props.selectedHandle}
          mountOnEnter={true}
          unmountOnExit={true}
          timeout={200}
          className='properties-popup'
        >
          <div className='properties-popup'>
            {this.props.selectedHandle ? (
              <PropertiesPopup
                top={this.props.selectedHandle}
                bottom={this.props.secondHandle}
                onClose={this.props.onShowHide}
              />
            ) : null}
          </div>
        </CSSTransition>
      ),
      this.props.containerRef,
    );
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    showPopUp: state.modelcheck.showProps,
    selectedHandle: state.modelcheck.selectedHandle,
    secondHandle: state.modelcheck.secondHandle,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onShowHide: (): void => {
      dispatch(ModelCheckActions.showHideProps());
    },
  };
}

const connecter = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps);
export const PropertiesView = connecter(PropertiesViewComponent);
