import autobind from 'autobind-decorator';

import { CustomElementFilterCollectionOperation } from 'common/enums/custom-element-filter-collection-operation';
import {
  CustomElementFilterTreeNode,
  CustomElementFilterViewModel,
} from 'common/interfaces/custom-element-filter-builder';
import { CustomElementFilterResolvers } from './custom-element-filter-resolvers';

type ValueType = Array<string | number> | number | string;
type FilterFunction<T> = (object: T) => boolean;
type ValueGetter<T> = (object: T, key: string) => ValueType;
type InnerValueGetter = (key: string) => ValueType;
const { resolveInversion, resolveStringFilter, resolveNumberFilter } = CustomElementFilterResolvers;


export class CustomElementFilterHelper {
  public static createFilterFunction<T>(
    filter: CustomElementFilterViewModel,
    valueGetter: ValueGetter<T>,
  ): FilterFunction<T> {
    if (!filter || filter.rootFilterIds.length !== 1) {
      return null;
    }

    const node = filter.filters[filter.rootFilterIds[0]].rootNode;

    return (object: any) => {
      const innerValueGetter = (key: string): ValueType => valueGetter(object, key);
      return CustomElementFilterHelper.executeFiltration(node, innerValueGetter, filter);
    };
  }

  @autobind
  private static getFilterRootNodeWithoutReferences(
    node: CustomElementFilterTreeNode,
    filter: CustomElementFilterViewModel,
  ): CustomElementFilterTreeNode {
    const result = { ...node };

    for (const referencedFilter of result.referencedFilters) {
      const filterRootNode = filter.filters[referencedFilter.elementFilterId].rootNode;
      filterRootNode.inverse = referencedFilter.inverse;
      result.innerNodes.push(filterRootNode);
    }

    result.referencedFilters = [];

    return result;
  }

  @autobind
  private static executeFiltration(
    node: CustomElementFilterTreeNode,
    valueGetter: InnerValueGetter,
    filterModel: CustomElementFilterViewModel,
  ): boolean {
    const nodeWithoutReferences = this.getFilterRootNodeWithoutReferences(node, filterModel);
    const { stringBasedFilters, doubleBasedFilters, innerNodes, operation, inverse } = nodeWithoutReferences;

    let result = false;
    switch (operation) {
      case CustomElementFilterCollectionOperation.Any:
        result =
          stringBasedFilters.some(x => resolveStringFilter(this.stringValueGetter(valueGetter, x.propertyKey), x))
          || doubleBasedFilters.some(x => resolveNumberFilter(this.numberValueGetter(valueGetter, x.propertyKey), x))
          || innerNodes.some(x => this.executeFiltration(x, valueGetter, filterModel));
        break;
      case CustomElementFilterCollectionOperation.All:
        result =
          stringBasedFilters.every(x => resolveStringFilter(this.stringValueGetter(valueGetter, x.propertyKey), x))
          && doubleBasedFilters.every(x => resolveNumberFilter(this.numberValueGetter(valueGetter, x.propertyKey), x))
          && innerNodes.every(x => this.executeFiltration(x, valueGetter, filterModel));
        break;
      default: result = false;
    }

    return resolveInversion(result, inverse);
  }

  private static numberValueGetter(valueGetter: InnerValueGetter, key: string): number | number[] {
    return valueGetter(key) as number | number[];
  }

  private static stringValueGetter(valueGetter: InnerValueGetter, key: string): string | string[] {
    return valueGetter(key) as string | string[];
  }
}
