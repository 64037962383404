import { Avatar } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { ThemesDialogModal } from 'common/components/page-header-avatar/themes-dialog-modal';

import { State } from 'common/interfaces/state';
import { AccountActions } from '../../../units/account/actions/creators';
import { AccountApi } from '../../../units/account/api';
import { ProfileDialog } from '../../../units/account/components/profile-dialog';
import { CompanySubscriptionModel } from '../../../units/account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../units/account/selectors';
import { SubscriptionDialog } from '../../../units/subscription/components/subscription-dialog';
import { UpgradeSubscriptionDialog } from '../../../units/subscription/components/upgrade-subscription-dialog';
import { PageHeaderAvatarMenu } from './page-header-avatar-menu';
import { Styled } from './styled';

const SIZE = 60;

interface DispatchProps {
  logOut: () => void;
}

interface StateProps {
  firstName: string;
  lastName: string;
  userId: string;
  email: string;
  isAdminPage?: boolean;
  hasAvatar: boolean;
  subscription: CompanySubscriptionModel;
}

interface ComponentState {
  isProfileDropDownOpened: boolean;
}

interface Props extends DispatchProps, StateProps {
  size?: number;
}

class PageHeaderAvatarComponent extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isProfileDropDownOpened: false,
    };
  }

  public render(): React.ReactNode {
    const { firstName, lastName, email, logOut, isAdminPage, userId, hasAvatar, size } = this.props;
    const avatarUrl = AccountApi.getAvatarLink({ id: userId, hasAvatar });
    const fullName = `${firstName} ${lastName}`;
    const dialog = this.getSubscribtionDialog();
    return (
      <Styled.Container className='page-header-avatar'>
        <ThemesDialogModal />
        {dialog}
        <Styled.AvatarContainer size={size || SIZE}>
          <Avatar
            name={fullName}
            size={size || SIZE}
            avatar={avatarUrl}
            onClick={this.toggleDropDown}
          />
        </Styled.AvatarContainer>
        {this.state.isProfileDropDownOpened && <PageHeaderAvatarMenu
          userId={userId}
          hasAvatar={hasAvatar}
          isAdminPage={isAdminPage}
          fullName={fullName}
          userName={email}
          logOut={logOut}
          onClose={this.toggleDropDown}
        />}
        <ProfileDialog />
    </Styled.Container>
    );
  }

  @autobind
  private toggleDropDown(): void {
    this.setState({ isProfileDropDownOpened: !this.state.isProfileDropDownOpened });
  }

  private getSubscribtionDialog(): JSX.Element {
    const isFreePlan = AccountSelectors.isFreeSubscription(this.props.subscription);

    return isFreePlan
      ? <UpgradeSubscriptionDialog />
      : <SubscriptionDialog />;
  }
}


const mapStateTopProps = (state: State): StateProps => {
  const { firstName, lastName, id, email, hasAvatar } = state.account;
  return {
    firstName,
    lastName,
    userId: id,
    email,
    isAdminPage: state.router.location.pathname.startsWith('/admin'),
    hasAvatar,
    subscription: AccountSelectors.currentSubscription(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    logOut: () => dispatch(AccountActions.logOut(false)),
  };
};

export const PageHeaderAvatar = connect(mapStateTopProps, mapDispatchToProps)(PageHeaderAvatarComponent);
