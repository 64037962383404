import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './alert-notification.scss';

import { NavLink } from 'react-router-dom';
import { SubscriptionType } from 'common/constants/subscription';
import { State } from 'common/interfaces/state';
import { AlertAttention, AlertSuccess, KreoIconCloseSmall } from 'common/UIKit';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { AlertType } from '../../alert-type';
import { Alert } from '../../interfaces';

interface StateProps {
  subscriptionType: SubscriptionType;
}

interface Props extends StateProps {
  alert: Alert;
  onClose: (alert: Alert) => void;
}

const INITIAL_CLOSING_TIMEOUT = 8000;
const CLOSING_TIMEOUT_AFTER_HOVERING = 2000;

class AlertNotificationComponent extends React.Component<Props> {
  private closeExecuter: DeferredExecutor = new DeferredExecutor(INITIAL_CLOSING_TIMEOUT);
  private closeAfterHoverExecuter: DeferredExecutor = new DeferredExecutor(CLOSING_TIMEOUT_AFTER_HOVERING);

  public componentDidMount(): void {
    if (this.props.alert.alertType === AlertType.Info || this.props.alert.alertType === AlertType.Warning) {
      this.closeExecuter.execute(() => this.removeAlert());
    }
  }

  public render(): React.ReactNode {
    const { alert, subscriptionType } = this.props;
    const link = alert.urlBuilder && alert.urlBuilder(subscriptionType);

    let icon = null;
    switch (alert.alertType) {
      case AlertType.Info:
        icon = <AlertSuccess />;
        break;
      case AlertType.Warning:
        icon = <AlertAttention />;
        break;
      case AlertType.Error:
        icon = <AlertAttention />;
        break;
      default:
    }

    return (
      <div
        className='alert-notification'
        onMouseEnter={this.stopTimeOut}
        onMouseLeave={this.setTimeOut}
      >
        {icon && <div className='alert-notification__icon'>{icon}</div>}
        {link
          ? <NavLink to={link} >{this.getAlert()}</NavLink>
          : this.getAlert()
        }
        {alert.alertType === AlertType.Error && (
          <div className='alert-notification__close' onClick={this.removeAlert}>{<KreoIconCloseSmall />}</div>
        )}
      </div>
    );
  }

  @autobind
  private getAlert(): React.ReactNode {
    return (
      <div
        className='alert-notification__message'
        onClick={this.onClose}
        dangerouslySetInnerHTML={{ __html: this.props.alert.message }}
      />
    );
  }

  @autobind
  private stopTimeOut(): void {
    this.closeExecuter.reset();
  }

  @autobind
  private setTimeOut(): void {
    this.closeAfterHoverExecuter.execute(this.removeAlert);
  }

  @autobind
  private removeAlert(): void {
    this.closeExecuter.reset();
    this.onClose();
  }

  @autobind
  private onClose(): void {
    this.props.onClose(this.props.alert);
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    subscriptionType: state.account.selectedSubscriptionType,
  };
}

export const AlertNotification = connect(mapStateToProps)(AlertNotificationComponent);
