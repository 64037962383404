import { ColDef, ColGroupDef } from 'ag-grid-community';

import { FilterKeys } from 'common/components/tree-filter-panel/constants';
import {
  GraphStorageRecordsConfig,
  GroupConfigRecord,
  UserExtractors,
} from '../../interfaces/graph-storage-records-config';
import { AGG_HASH } from '../quantity-take-off-common-table/custom-cell/aggregation-cell/aggregation-cell';
import { QtoLeftPanelConstants } from './constants';
import { getNRMColumns } from './get-additional-nrm-column';
import { getColumnDef } from './get-column';
import { isAdditionalProperties } from './is-additional-properties';

const isNRM = (key: string): boolean => key === FilterKeys.F_NRM1 || key === FilterKeys.F_NRM2;

export const addAdditionalColumn = (
  columnConfig: GraphStorageRecordsConfig,
  fieldName: string,
  recordValue: string[],
  colDefs: Record<string, ColDef>,
  mainColDefDictionary: Record<string, boolean>,
  columnsValues: Record<string, string[]>,
): void => {
  if (isNRM(fieldName)) {
    const nrmColumns = getNRMColumns(
      fieldName,
      recordValue,
      columnConfig,
    );
    if (!nrmColumns[0]) {
      console.error(`Invalid fieldName ${fieldName} ${recordValue}`);
      return;
    }
    if (!mainColDefDictionary[nrmColumns[0].field]) {
      for (const colDef of nrmColumns) {
        colDefs[colDef.field] = colDef;
        mainColDefDictionary[colDef.field] = true;
        columnsValues[colDef.field] = [];
      }
    }
  }
};

export const setColumnConfig = (
  mainColDefDictionary: Record<string, boolean>,
  fieldName: string,
  colDef: Record<string, ColDef>,
  groupConfig: Record<string, number>,
  columnConfig: GraphStorageRecordsConfig,
  columnsValues: Record<string, string[]>,
  isLocation: boolean,
): void => {
  if (!mainColDefDictionary[fieldName]) {
    colDef[fieldName] = getColumnDef(fieldName, columnConfig, isLocation, groupConfig[fieldName]);
    mainColDefDictionary[fieldName] = true;
    columnsValues[fieldName] = [];
  }
};


function groupColumns(
  groupKey: string,
  group: GroupConfigRecord,
  colDefs: Record<string, ColDef>,
  config: GraphStorageRecordsConfig,
): ColDef {
  function getKey(key: string): string {
    if (groupKey === QtoLeftPanelConstants.BREAKDOWN_GROUPS_EXTRACTORS_KEY) {
      const extractor = (config[QtoLeftPanelConstants.USER_EXTRACTORS_KEY] as UserExtractors)
        .find(x => x.name.toLowerCase() === key);
      return extractor ? (extractor as any).extractorKey : key;
    } else {
      return key;
    }
  }
  const result: ColGroupDef = {
    groupId: groupKey,
    headerName: groupKey === 'brg_measurments' ? 'Measurements' : group.name,
    children: [],
  };
  if (group.sub_subgroups) {
    for (const key of group.subgroups) {
      result.children.push(groupColumns(key, group.sub_subgroups[key], colDefs, config));
    }
  } else {
    for (const key of group.subgroups) {
      const k = getKey(key);
      if (colDefs[k]) {
        result.children.push(colDefs[k]);
        delete colDefs[k];
      }
    }
  }
  return result;
}

export const isMainColumn = (key: string): boolean => key !== QtoLeftPanelConstants.OBJECT_COMPLEX_KEY
  && key !== QtoLeftPanelConstants.BREAKDOWN_GROUPS_EXTRACTORS_KEY
  && key !== QtoLeftPanelConstants.ELEMENT_TABLE_BIM_ELEMENT_ID_KEY
  && key !== QtoLeftPanelConstants.ELEMENT_TABLE_NAME_KEY
  && key !== AGG_HASH
  && key !== QtoLeftPanelConstants.F_LOC_LOC_TYPES
  && !key.startsWith(QtoLeftPanelConstants.REASON_EF_PREFIX)
  && key !== QtoLeftPanelConstants.EXTRA_INFO_IMPERIAL;


export function groupColummns(
  columnConfig: GraphStorageRecordsConfig,
  colDefs: Record<string, ColDef>,
): ColGroupDef[] {
  const groupedColumns = [];
  for (const
    [key, group] of (
      Object.entries(columnConfig[QtoLeftPanelConstants.BREAKDOWN_GROUPS_KEY]) as Array<[string, GroupConfigRecord]>
    )) {
    groupedColumns.push(groupColumns(key, group, colDefs, columnConfig));
  }
  const sharedParameters: ColGroupDef = {
    groupId: QtoLeftPanelConstants.BREAKDOWN_GROUPS_SHARED_PARAMETERS,
    headerName:
      columnConfig[QtoLeftPanelConstants.BREAKDOWN_GROUPS_KEY]
        [QtoLeftPanelConstants.BREAKDOWN_GROUPS_SHARED_PARAMETERS].name,
    children: [],
  };

  const additionalProperties: ColGroupDef = {
    groupId: 'additional_properties',
    headerName: 'Additional Properties',
    children: [],
  };

  for (const [key, coldDef] of Object.entries(colDefs)) {
    if (isAdditionalProperties(key)) {
      sharedParameters.children.push(coldDef);
    } else {
      additionalProperties.children.push(coldDef);
    }
  }
  groupedColumns.push(sharedParameters, additionalProperties);
  return groupedColumns;
}
