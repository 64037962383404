const prefix = `@admin-companies`;

export const AdminCompaniesActionTypes = {
  LOAD_REQUEST: `${prefix}/LOAD_REQUEST`,
  LOAD_SUCCEED: `${prefix}/LOAD_SUCCEED`,
  DELETE_PROJECTS_REQUEST: `${prefix}/DELETE_PROJECTS_REQUEST`,
  DELETE_PROJECTS_SUCCEED: `${prefix}/DELETE_PROJECTS_SUCCEED`,
  DELETE_COMPANY: `${prefix}/DELETE_COMPANY`,
  DELETE_COMPANY_SUCCEED: `${prefix}/DELETE_COMPANY_SUCCEED`,

  LOAD_DATABASES_REQUEST: `${prefix}/LOAD_DATABASES_REQUEST`,
  LOAD_DATABASES_SUCCEED: `${prefix}/LOAD_DATABASES_SUCCEED`,
  ATTACH_DATABASES_REQUEST: `${prefix}/ATTACH_DATABASES_REQUEST`,
  ATTACH_DATABASES_SUCCEED: `${prefix}/ATTACH_DATABASES_SUCCEED`,
  DETACH_DATABASE_REQUEST: `${prefix}/DETACH_DATABASE_REQUEST`,
  DETACH_DATABASE_SUCCEED: `${prefix}/DETACH_DATABASE_SUCCEED`,

  LOAD_EMPLOYEES_REQUEST: `${prefix}/LOAD_EMPLOYEES_REQUEST`,
  LOAD_EMPLOYEES_SUCCEED: `${prefix}/LOAD_EMPLOYEES_SUCCEED`,
  ATTACH_EMPLOYEE_REQUEST: `${prefix}/ATTACH_EMPLOYEE_REQUEST`,
  ATTACH_EMPLOYEE_SUCCEED: `${prefix}/ATTACH_EMPLOYEE_SUCCEED`,
  DETACH_EMPLOYEE_REQUEST: `${prefix}/DETACH_EMPLOYEE_REQUEST`,
  DETACH_EMPLOYEE_SUCCEED: `${prefix}/DETACH_EMPLOYEE_SUCCEED`,

  LOAD_ROLES_REQUEST: `${prefix}/LOAD_ROLES_REQUEST`,
  LOAD_ROLES_SUCCEED: `${prefix}/LOAD_ROLES_SUCCEED`,
  CHANGE_ROLE_REQUEST: `${prefix}/CHANGE_ROLE_REQUEST`,
  CHANGE_ROLE_SUCCEED: `${prefix}/CHANGE_ROLE_SUCCEED`,

  LOAD_PROJECTS_EMPLOYEES_REQUEST: `${prefix}/LOAD_PROJECTS_EMPLOYEES_REQUEST`,
  LOAD_PROJECTS_EMPLOYEES_SUCCEED: `${prefix}/LOAD_PROJECTS_EMPLOYEES_SUCCEED`,
  ATTACH_EMPLOYEE_TO_PROJECT_REQUEST: `${prefix}/ATTACH_EMPLOYEE_TO_PROJECT_REQUEST`,
  ATTACH_EMPLOYEE_TO_PROJECT_SUCCEED: `${prefix}/ATTACH_EMPLOYEE_TO_PROJECT_SUCCEED`,
  DETACH_EMPLOYEE_FROM_PROJECT_REQUEST: `${prefix}/DETACH_EMPLOYEE_FROM_PROJECT_REQUEST`,
  DETACH_EMPLOYEE_FROM_PROJECT_SUCCEED: `${prefix}/DETACH_EMPLOYEE_FROM_PROJECT_SUCCEED`,

  GET_SUBSCRIPTION_PLANS_REQUEST: `${prefix}/GET_SUBSCRIPTION_PLANS_REQUEST`,
  GET_SUBSCRIPTION_PLANS_SUCCEEDED: `${prefix}/GET_SUBSCRIPTION_PLANS_SUCCEEDED`,
  GET_SUBSCRIPTION_PLANS_FAILED: `${prefix}/GET_SUBSCRIPTION_PLANS_FAILED`,

  SET_MOCK_SUBSCRIPTION_REQUEST: `${prefix}/SET_MOCK_SUBSCRIPTION_REQUEST`,
  SET_MOCK_SUBSCRIPTION_SUCCEEDED: `${prefix}/SET_MOCK_SUBSCRIPTION_SUCCEEDED`,
  SET_MOCK_SUBSCRIPTION_FAILED: `${prefix}/SET_MOCK_SUBSCRIPTION_FAILED`,
  RESET_MOCK_SUBSCRIPTION_REQUEST: `${prefix}/RESET_MOCK_SUBSCRIPTION_REQUEST`,
  RESET_MOCK_SUBSCRIPTION_SUCCEEDED: `${prefix}/RESET_MOCK_SUBSCRIPTION_SUCCEEDED`,
  RESET_MOCK_SUBSCRIPTION_FAILED: `${prefix}/RESET_MOCK_SUBSCRIPTION_FAILED`,
  CREATE_EMPTY_SUBSCRIPTION: `${prefix}/CREATE_EMPTY_SUBSCRIPTION`,
  CREATE_EMPTY_SUBSCRIPTION_SUCCEEDED: `${prefix}/CREATE_EMPTY_SUBSCRIPTION_SUCCEEDED`,
  CREATE_EMPTY_SUBSCRIPTION_FAILED: `${prefix}/CREATE_EMPTY_SUBSCRIPTION_FAILED`,

  LOAD_CURRENCIES_REQUEST: `${prefix}/LOAD_CURRENCIES_REQUEST`,
  LOAD_CURRENCIES_SUCCEEDED: `${prefix}/LOAD_CURRENCIES_SUCCEEDED`,
  LOAD_CURRENCIES_FAILED: `${prefix}/LOAD_CURRENCIES_FAILED`,
  SET_CURRENCY_REQUEST: `${prefix}/SET_CURRENCY_REQUEST`,
  SET_CURRENCY_SUCCEEDED: `${prefix}/SET_CURRENCY_SUCCEEDED`,
  SET_CURRENCY_FAILED: `${prefix}/SET_CURRENCY_FAILED`,
  SET_PREFER_IMPERIAL_REQUEST: `${prefix}/SET_PREFER_IMPERIAL_REQUEST`,
  SET_PREFER_IMPERIAL_SUCCEEDED: `${prefix}/SET_PREFER_IMPERIAL_SUCCEEDED`,
  SET_PREFER_IMPERIAL_FAILED: `${prefix}/SET_PREFER_IMPERIAL_FAILED`,
};
