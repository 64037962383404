import * as React from 'react';

import './context.scss';


import { HasInvisible } from './has-invisible';
import { HasSelectedContext } from './has-selected';


interface Props {
  position: any;
  props: any;
  color: any;
  contextmode: any;
  onShowAll: () => void;
  onHide: () => void;
  onGhost: () => void;
  onFocus: () => void;
  onIsolate: () => void;
  onAssignColor: (color: any) => void;
  resetAllColor: () => void;
}

export class ContextMenu extends React.Component<Props> {
  private link: HTMLDivElement;

  public getStyle(): any {
    if (!this.link || !this.props.contextmode) {
      return { display: 'none' };
    }
    const position = this.props.position;
    const rootW = this.link.offsetWidth;
    const rootH = this.link.offsetHeight;
    const right = (position.parrentWidth - position.x) > rootW;
    const left = !right;
    const top = (position.parrentHeight - position.y) > rootH;
    const bottom = !top;
    const style: any = {
    };
    if (right) {
      style.left = position.x + 5;
    }
    if (left) {
      style.left = position.x - rootW - 5;
    }
    if (top) {
      style.top = position.y + 5;
    }
    if (bottom) {
      style.top = position.y - rootH - 5;
    }
    return style;
  }

  public render(): any {
    return (
      <div className='viewer-context-menu' ref={(ref: any): any => { this.link = ref; }} style={this.getStyle()}>
        {this.props.props && this.props.props.hasSelected ?
          <HasSelectedContext
            isTint={this.props.props.isTint}
            onIsolate={this.props.onIsolate}
            onGhost={this.props.onGhost}
            onFocus={this.props.onFocus}
            onHide={this.props.onHide}
            color={this.props.color}
            onAssignColor={this.props.onAssignColor}
            onShowAll={this.props.onShowAll}
          />
          : this.props.props &&
          <HasInvisible
            isTint={this.props.props.anyColors}
            onShowAll={this.props.onShowAll}
            resetAllColor={this.props.resetAllColor}
          />
        }

      </div>
    );
  }
}

