import autobind from 'autobind-decorator';
import { Moment } from 'moment';
import * as React from 'react';

import './people-item-attribute.scss';

import { TitleEditable } from 'common/components/title-editable';
import { MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { AttributeOption } from '../../interfaces/user';
import { DateTimeInput } from '../date-time-input';

export type PeopleItemAttributeType = 'date-time' | 'text' | 'bool';

interface Props {
  item: AttributeOption;
  userId: string;
  valueType?: PeopleItemAttributeType;
  disabled?: boolean;
  updateUserAttribute: (key: string, value: string, userId: string) => void;
}

interface SelectBoxOption {
  value: string | undefined;
  text: string;
}

export class PeopleItemAttribute extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    valueType: 'text',
  };

  private readonly boolVariants: SelectBoxOption[] = [
    { value: undefined, text: 'no value' },
    { value: 'false', text: 'false' },
    { value: 'true', text: 'true' },
  ];

  public render(): React.ReactNode {
    const { item } = this.props;
    return (
      <div className='people-item-attribute'>
        <div className='people-item-attribute__name'>{item.name}:</div>
        {this.renderValueControl()}
      </div>
    );
  }

  private renderValueControl(): React.ReactNode {
    const { item, disabled } = this.props;

    if (disabled) {
      return item.value;
    }

    let node: React.ReactNode;
    switch (this.props.valueType) {
      case 'date-time':
        node =  (
          <DateTimeInput
            value={item.value}
            onChange={this.updateDateTimeAttribute}
            className='people-item-attribute__value'
          />
        );
        break;
      case 'bool':
        node = (
          <MaterialSelect
            value={item.value}
            onChange={this.onMaterialSelectChange}
            autoWidth={true}
            className='people-item-attribute__value little'
          >
            {
              this.boolVariants.map((v) => (
                <MaterialMenuItem key={`${v.value}-${v.text}`} value={v.value}>{v.text}</MaterialMenuItem>
              ))
            }
          </MaterialSelect>
        );
        break;
      default:
        node =  (
          <TitleEditable
            label='Attribute value'
            text={item.value || 'no value'}
            onChange={this.updateUserAttribute}
            canEdit={false}
          />
        );
    }

    return node;
  }

  @autobind
  private onMaterialSelectChange(
    _e: React.SyntheticEvent<{}>,
    menuItemValue: string | null,
  ): void {
    this.updateUserAttribute(menuItemValue);
  }

  @autobind
  private updateDateTimeAttribute(value: Moment): void {
    this.updateUserAttribute(value.format('Y-MM-DD HH:mm:ss\\Z'));
  }

  @autobind
  private updateUserAttribute(value: string): void {
    if (this.props.updateUserAttribute) {
      this.props.updateUserAttribute(this.props.item.key, value, this.props.userId);
    }
  }
}
