import { Icons, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  ErrorComponent?: React.ComponentType;
}

export const ErrorContainer: React.FC<Props> = React.memo(({ ErrorComponent }: Props) => {
  return (
    <Styled.ErrorMessage>
      <Icons.Error />
      <Text>Something went wrong!</Text>
      {ErrorComponent ? <ErrorComponent /> : null}
    </Styled.ErrorMessage>
  );
});
