import React from 'react';
import { withDropTargetWrapper } from 'common/utils/with-drag/with-drop-target-wrapper';
import { SINGLE_SELECT_PROPERTY_DND_TYPE } from '../../dnd-type';
import { Styled } from './styled';

interface OwnProps {
  moveTo(target: number, source: number): void;
  index: number;
}

interface DnDProps {
  connectDropTarget: (element: JSX.Element) => JSX.Element;
  hovering: boolean;
}

interface Props extends OwnProps, DnDProps {
}

const DropTargetLineComponent: React.FC<Props> = ({ hovering }) => {
  return (
    <Styled.LineContainer>
      <Styled.Line hovering={hovering} />
    </Styled.LineContainer>
  );
};

const specDrop = {
  drop: (props: Props, monitor) => {
    const item = monitor.getItem();
    props.moveTo(props.index, item.value);

    return item;
  },
  canDrop: (props, monitor): boolean => {
    const item = monitor.getItem();
    return props.index !== item.value;
  },
};

export const DropTargetLine = withDropTargetWrapper(DropTargetLineComponent, specDrop, SINGLE_SELECT_PROPERTY_DND_TYPE);
