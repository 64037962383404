import { ActivityGroupData } from '../interfaces';

export class TempActivityGroupingDataMapper {
  public static getWorkPackages(
    packages: Record<number, ActivityGroupData.WorkPackage>, groups: ActivityGroupData.ActivityGroup[],
    ungrouped: ActivityGroupData.UngroupedActivity[], categories: ActivityGroupData.Category[],
  ):  Record<number, ActivityGroupData.WorkPackage> {
    for (const workPackageId in packages) {
      packages[workPackageId].activityGroupIds = [];
      packages[workPackageId].ungroupedIds = [];
      packages[workPackageId].categoryIds = [];
    }
    for (const group of groups) {
      packages[group.workPackageId].activityGroupIds.push(group.id);
    }
    for (const group of ungrouped) {
      packages[group.workPackageId].ungroupedIds.push(group.activityGroupId);
    }
    for (const category of categories) {
      packages[category.workPackageId].categoryIds.push(category.id);
    }

    return packages;
  }

  public static getCategories(
    categories: Record<number, ActivityGroupData.Category>, groups: ActivityGroupData.ActivityGroup[],
  ):  Record<number, ActivityGroupData.Category> {
    for (const categoryId in categories) {
      categories[categoryId].activityGroupIds = [];
    }
    for (const group of groups) {
      categories[group.categoryId].activityGroupIds.push(group.id);
    }

    return categories;
  }

  public static getActivityGroups(
    groups: Record<number, ActivityGroupData.ActivityGroup>, activities: ActivityGroupData.Activity[],
  ):  Record<number, ActivityGroupData.ActivityGroup> {
    for (const groupsId in groups) {
      groups[groupsId].activityIds = [];
    }

    for (const activity of activities) {
      groups[activity.activityGroupId].activityIds.push(activity.id);
    }

    return groups;
  }
}
