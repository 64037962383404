import * as Ag from 'ag-grid-community';
import { useCallback, useState } from 'react';

export const useGridApiState = (): [
  (gridApi: Ag.GridApi, columnApi: Ag.ColumnApi) => void,
  Ag.GridApi,
  Ag.ColumnApi,
] => {
  const [gridApiState, setGridApi] = useState<Ag.GridApi>();
  const [columnApiState, setColumnApi] = useState<Ag.ColumnApi>();
  const saveGridRef = useCallback((gridApi: Ag.GridApi, columnApi: Ag.ColumnApi) => {
    setGridApi(gridApi);
    setColumnApi(columnApi);
  }, []);

  return [saveGridRef, gridApiState, columnApiState];
};
