import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-assignment-footer.scss';

import { State } from 'common/interfaces/state';
import { KreoButton } from 'common/UIKit';
import { ClassificationActions } from '../../actions/creators/classification';
import { ClassificationAssignmentStage } from '../../enums/classification-assignment-stage';
import { ClassificationUtils } from '../../utils/classification-utils';

interface ReduxProps {
  onAssign: () => void;
  changePredictedInfoLevel: () => void;
  saveCustomUniclass: (isMultilayer: boolean, isAssemblyRootEditing?: boolean) => void;
}

interface OwnProps {
  footerSaveOnly: boolean;
}

interface StateProps {
  level: ClassificationAssignmentStage;
  isMultilayer: boolean;
  isSaveBlocked: boolean;
  isSaveEnabled: boolean;
  isAssemblyRootEditing: boolean;
}

interface Props extends StateProps, ReduxProps, OwnProps { }

class ClassificationAssignmentFooterComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <div className='classification-assignment-footer'>
        {this.props.footerSaveOnly ? (
          <KreoButton
            mode='submit'
            size='large'
            onClick={this.props.onAssign}
            caption='Save'
            disabled={!this.props.isSaveEnabled}
            controlName='classification-assign-button'
          />
        ) : (
            <React.Fragment>
              <KreoButton
                mode='submit'
                size='large'
                onClick={this.saveCustomUniclass}
                caption='Apply'
                disabled={this.props.isSaveBlocked}
                controlName='classification-assign-button'
              />
              <KreoButton
                mode='ghost'
                size='large'
                onClick={this.props.changePredictedInfoLevel}
                caption='Cancel'
                controlName='classification-cancel-button'
              />
            </React.Fragment>
        )}
      </div>
    );
  }

  @autobind
  private saveCustomUniclass(): void {
    this.props.saveCustomUniclass(this.props.isMultilayer, this.props.isAssemblyRootEditing);
    this.props.changePredictedInfoLevel();
  }
}

function mapStateToProps({ classification }: State): StateProps {
  let isSaveBlocked = false;
  const isMultilayer = ClassificationUtils.classificationTargetIsMultilayer(classification.classificationTarget);
  const tempUniclass = classification.tempUniclass;
  isSaveBlocked = !(tempUniclass && tempUniclass.system && tempUniclass.product) && !isMultilayer;
  return {
    level: classification.assignmentStage,
    isMultilayer,
    isSaveEnabled: classification.isSaveButtonEnabled,
    isSaveBlocked,
    isAssemblyRootEditing: classification.changeAssemblyRoot,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): ReduxProps {
  return {
    onAssign: () => {
      dispatch(ClassificationActions.assignClassification());
    },
    changePredictedInfoLevel: () => {
      dispatch(ClassificationActions.changeAssignmentLevel(ClassificationAssignmentStage.PredictedInfo));
    },
    saveCustomUniclass: (isMultilayer: boolean, isAssemblyRootEditing) => {
      if (isMultilayer) {
        if (isAssemblyRootEditing) {
          dispatch(ClassificationActions.setAssemblyRootClassification());
        } else {
          dispatch(ClassificationActions.setAssemblyLayerClassification());
        }
      } else {
        dispatch(ClassificationActions.createCustomUniclass());
      }
    },
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationAssignmentFooter = connector(ClassificationAssignmentFooterComponent);
