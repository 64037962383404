import { useCallback } from 'react';
import { Item } from 'unit-2d-database/interfaces';
import { AssemblyForm, ItemUpdater } from '../../assembly-panel/item-form-updater';
import { MeasurePanelType } from '../interfaces';

type OpenItemPanelCallback = (
  form: AssemblyForm,
  openSidePanel: (type: MeasurePanelType) => void,
  setForm: (item: Item) => void,
  closePanel: () => void,
) => (name: string) => void;

export const useOpenItemPanelCallback: OpenItemPanelCallback = (form, openSidePanel, setForm, closePanel) => {
  return useCallback((name) => {
    const data = ItemUpdater.getData(form);
    const items = data.updatedItems.map(u => {
      const item: Item = {
        name: u.name,
        iconType: u.iconType,
        properties: [...u.updatedProperties, ...u.addedProperties],
      };
      return item;
    });
    const item = items.find(i => i.name === name);
    if (item) {
      openSidePanel(MeasurePanelType.ItemMeasure);
      setForm(item);
      closePanel();
    }
  }, [form, openSidePanel, setForm, closePanel]);
};
