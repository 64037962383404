import { CheckboxButton, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { arrayUtils } from 'common/utils/array-utils';

import { OptionCustomRender, OptionInfo, OptionKeyType } from '../interfaces';
import { OptionContainer, OptionContainerStyled } from '../option-container';
import { SummaryItem } from './summary-item';

interface FilterAndCreateOptionsResult {
  items: JSX.Element[];
  areAllSelected: boolean;
  indeterminate: boolean;
}

export interface Props<K extends OptionKeyType, T extends OptionInfo<K> = OptionInfo<K>> {
  filterQuery?: string;
  withCommon?: boolean;
  renderCommonNode?: (data: T, selected: boolean, indeterminate: boolean) => void;
  onSelectionChanged: (value: K[]) => void;
  customOptionRender?: OptionCustomRender<T>;
  options: T[];
  value: K[];
}

export class CheckboxGroupMenu
  <K extends OptionKeyType, T extends OptionInfo<K> = OptionInfo<K>>
  extends React.PureComponent<Props<K, T>> {
  public render(): React.ReactNode {
    const { items, indeterminate, areAllSelected } = this.filterAndCreateOptions();
    return (
      <>
        {this.props.withCommon && items.length > 0 ? this.renderCommonNode(areAllSelected, indeterminate) : null}
        {items}
      </>
    );
  }

  private filterAndCreateOptions(): FilterAndCreateOptionsResult {
    const { value } = this.props;


    const selectedSet = new Set(value);
    const items = [];
    let areAllSelected = true;
    let indeterminate = false;


    for (const option of this.filterOptionsIterator()) {
      const selected = selectedSet.has(option.value);
      if (selected) {
        indeterminate = true;
      } else {
        areAllSelected = false;
      }

      items.push(
        <OptionContainer
          key={option.value.toString()}
          value={option.value}
          onSelect={this.updateSelection}
        >
          {
            this.renderItem(option,  selected)
          }
        </OptionContainer>,
      );
    }
    return {
      items,
      indeterminate,
      areAllSelected,
    };
  }


  private renderCommonNode(selected: boolean, indeterminate: boolean): JSX.Element {
    return (
      <OptionContainerStyled.Container onClick={this.selectDeselectAll}>
        <SummaryItem
          indeterminate={indeterminate}
          selected={selected}
          title='Select All'
        />
      </OptionContainerStyled.Container>
    );
  }

  private *filterOptionsIterator(): IterableIterator<T> {
    const { filterQuery, options } = this.props;
    const filterQueryLowerCased = filterQuery?.toLocaleLowerCase();
    for (const option of options) {
      if (filterQuery && !option.name.toLocaleLowerCase().includes(filterQueryLowerCased)) {
        continue;
      }
      yield option;
    }
  }

  @autobind
  private selectDeselectAll(): void {
    const selectedFiltered = [...this.filterOptionsIterator()].map(x => x.value);
    const selectedValuesSet = new Set(selectedFiltered);
    const filteredValue = this.props.value.filter(v => selectedValuesSet.has(v));
    if (selectedValuesSet.size === filteredValue.length) {
      this.props.onSelectionChanged(this.props.value.filter(v => !selectedValuesSet.has(v)));
    } else {
      this.props.onSelectionChanged(arrayUtils.uniq(this.props.value.concat(selectedFiltered)));
    }
  }

  @autobind
  private updateSelection(value: K): void {
    if (this.props.value.includes(value)) {
      this.props.onSelectionChanged(this.props.value.filter(x => x !== value));
    } else {
      this.props.onSelectionChanged(this.props.value.concat(value));
    }
  }

  private renderItem(data: T, selected: boolean): React.ReactNode {
    if (this.props.customOptionRender) {
      return this.props.customOptionRender(data, selected);
    }
    return (
      <>
        <CheckboxButton checked={selected} />
        <Text fontSize={14}>{data.name}</Text>
      </>
    );
  }
}
