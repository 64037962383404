import { PaneConfig } from './interfaces';
import { Utils } from './utils';

const getDiff = (
  offset: number,
  panes: PaneConfig[],
  isConditionExist: (pane: PaneConfig) => boolean,
  isSizeCorrect: (pane: PaneConfig, newSize: number) => boolean,
  getNewOffset: (pane: PaneConfig, newSize: number) => number,
  getMaxDiff: (pane: PaneConfig) => number,
): number[] => {
  const result = [];
  let offsetBalance = offset;

  for (const pane of panes) {
    if (offsetBalance === 0) {
      result.push(offsetBalance);
      continue;
    }

    if (!isConditionExist(pane)) {
      offsetBalance = 0;
      result.push(offset);
      continue;
    }

    const newSize = pane.size + offsetBalance;
    if (isSizeCorrect(pane, newSize)) {
      result.push(offsetBalance);
      offsetBalance = 0;
      continue;
    }

    result.push(getMaxDiff(pane));
    offsetBalance = getNewOffset(pane, newSize);
  }

  return result;
};

const getIncreaseDiff = (offset: number, panes: PaneConfig[]): number[] => getDiff(
  offset,
  panes,
  Utils.isIncreaseConditionExist,
  Utils.isIncreaseSizeCorrect,
  Utils.getNewIncreaseOffset,
  Utils.getIncreaseMaxDiff,
);

const getDecreaseDiff = (offset: number, panes: PaneConfig[]): number[] => getDiff(
  -offset,
  panes,
  Utils.isDecreaseConditionExist,
  Utils.isDecreaseSizeCorrect,
  Utils.getNewDecreaseOffset,
  Utils.getDecreaseMaxDiff,
);

export const getPaneSizeDiffs = (offset: number, increasePanes: PaneConfig[], decreasePane: PaneConfig[]): {
  increaseDiffs: number[], decreaseDiffs: number[],
} => {
  const increaseDiffs = getIncreaseDiff(offset, increasePanes);
  const decreaseDiffs = getDecreaseDiff(offset, decreasePane);

  return {
    increaseDiffs,
    decreaseDiffs,
  };
};
