import styled from 'styled-components';

const Container = styled.div`
  width: 20px;
  height: 20px;
  svg {
    path {
      fill: ${p => p.theme.color.gray};
    }
    circle {
      fill: ${p => p.theme.color.gray};
    }
  }
`;

export const Styled = {
  Container,
};
