import { useEffect, useMemo } from 'react';
import { DrawingContextObserver } from '../../drawings-contexts';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';

export function useScaleObserver(scale: number, metersPerPixel: number): DrawingContextObserver<number> {
  const scaleObserver = useMemo(
    () => new DrawingContextObserver<number>(DrawingsCanvasUtils.metersToPx(1, scale, metersPerPixel)),
    [],
  );

  useEffect(() => {
    scaleObserver.updateContext(DrawingsCanvasUtils.metersToPx(1, scale, metersPerPixel));
  }, [scale, metersPerPixel, scaleObserver]);

  return scaleObserver;
}
