import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ActivityPage } from '../../../units/activity/page';

interface Props extends RouteComponentProps<{}> {
  urls: any;
}

export class Routes extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <Route exact={true} path={this.props.urls.index.path} component={ActivityPage} />
    );
  }
}
