import { InputSelectStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin-bottom: 10px;
  ${InputSelectStyled.DropDownContainer} {
    width: 100%;
  }
`;

export const Styled = {
  Container,
};
