import React from 'react';

type UseToggleSelectionCallback = (
  selectedAddonIds: string[],
  onSelectedAddonIdsChange: (ids: string[]) => void,
  canAddAddons?: boolean,
) => (
  id: string,
  isSelected: boolean,
) => void;

export const useToggleSelectionCallback: UseToggleSelectionCallback = (
  selectedAddonIds,
  onSelectedAddonIdsChange,
  canAddAddons,
) => {
  return React.useCallback((id, isSelected) => {
    if (!isSelected) {
      onSelectedAddonIdsChange(selectedAddonIds.filter(x => x !== id));
    } else if (canAddAddons) {
      onSelectedAddonIdsChange(selectedAddonIds.concat(id));
    }
  }, [selectedAddonIds, onSelectedAddonIdsChange, canAddAddons]);
};
