import { DeferredExecutor } from './deferred-executer';

export type OnExecFuncType<T> = (form: T) => void;
type UpdateFormFuncType<T> = (formBefore: T | null, form: T) => T;

export class DeferredUpdater<T> {
  private mainExecuter: DeferredExecutor;
  private maxDelayExecuter: DeferredExecutor;

  private updateForm: UpdateFormFuncType<T>;
  private form: T = null;

  private onExec: OnExecFuncType<T>;

  constructor(delay: number, maxDelay: number, onExec: OnExecFuncType<T>, updateForm: UpdateFormFuncType<T>) {
    this.mainExecuter = new DeferredExecutor(delay);
    this.maxDelayExecuter = new DeferredExecutor(maxDelay);
    this.onExec = onExec;
    this.updateForm = updateForm;
  }

  public getCurrentForm(): T {
    return this.form;
  }

  public cancel(): void {
    this.mainExecuter.reset();
    this.maxDelayExecuter.reset();
  }

  public execute(data: T, dependExecuters?: Array<DeferredUpdater<any>>): void {
    if (!this.mainExecuter.isWaitingForExecution()) {
      this.maxDelayExecuter.execute(() => {
        this.mainExecuter.executeImmediately();
      });
    }

    this.form = this.updateForm(this.form, data);
    this.mainExecuter.execute(() => {
      this.maxDelayExecuter.reset();
      if (dependExecuters) {
        dependExecuters.forEach(x => x.executeImmediatelyIfDeferred());
      }

      this.onExec(this.form);
      this.form = null;
    });
  }

  public executeImmediately(data: T, dependExecuters?: Array<DeferredUpdater<any>>): void {
    this.mainExecuter.reset();
    this.maxDelayExecuter.reset();
    if (dependExecuters) {
      dependExecuters.forEach(x => x.executeImmediatelyIfDeferred());
    }

    this.form = this.updateForm(this.form, data);
    this.onExec(this.form);
    this.form = null;
  }

  public executeImmediatelyIfDeferred(): void {
    if (this.mainExecuter.isWaitingForExecution) {
      this.mainExecuter.executeImmediately();
    }
  }
}

export class DeferredUpdaterFactory {
  private delay: number;
  private maxDelay: number;
  constructor(delay: number, maxDelay: number) {
    this.delay = delay;
    this.maxDelay = maxDelay;
  }

  public create<T>(callBack: OnExecFuncType<T>, updater: UpdateFormFuncType<T>): DeferredUpdater<T> {
    return new DeferredUpdater(this.delay, this.maxDelay, callBack, updater);
  }
}
