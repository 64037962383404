import * as React from 'react';

import { UiTour } from './ui-tour';

export const connectUiTour = (Element: any, tourName: string) => (props: any) => {
  return (
    <>
      <Element {...props} />
      <UiTour tourName={tourName} />
    </>
  );
};
