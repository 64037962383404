import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { PdfLoader } from '../../helpers';
import { loadPageAsDocument } from '../../helpers/pdf-current-doc-getter';
import { DrawingsViewportHelper } from '../../helpers/viewport';
import { DrawingsShortInfo } from '../../interfaces';
import { DrawingsFile, DrawingsFiles } from '../../interfaces/drawings-file-info';
import { DrawingsUtils } from '../../utils/drawings-utils';

export function useCanvas(
  viewport: DrawingsViewportHelper,
  drawing: DrawingsShortInfo,
): HTMLCanvasElement | null {
  const rendered = useSelector<State, boolean>(x => x.drawings.drawingRenderedStatus);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const fileSystem = useSelector<State, DrawingsFiles>(x => x.drawings.files);

  useEffect(() => {
    if (rendered) {
      if (drawing.pageToCompareId) {
        const loader = new PdfLoader();
        loadPageAsDocument(loader, drawing, fileSystem.entities as Record<string, DrawingsFile>).then((document) => {
          if (!document) {
            return;
          }
          document.loadCanvas({
            pageNumber: 1,
            zoom: 1 / window.devicePixelRatio,
            drawComplete: (drawnCanvas) => {
              setCanvas(drawnCanvas);
            },
          });
        });
      } else {
        const drawingInfo = viewport.drawingInfo;
        drawingInfo.pdfDocument.loadCanvas({
          pageNumber: DrawingsUtils.getPDFPageNumber(drawingInfo.pageNumber),
          zoom: 1 / window.devicePixelRatio,
          drawComplete: (drawnCanvas) => {
            setCanvas(drawnCanvas);
          },
        });
      }
    } else {
      setCanvas(null);
    }
  }, [rendered, drawing.drawingId, drawing.pageToCompareId]);

  return canvas;
}
