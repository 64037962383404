import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './indirect-cost-list.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit/scrollbars/kreo-scrollbars';
import { ScenariosActions } from '../../../scenarios/actions';
import { WorkPackagesData } from '../../../scenarios/interfaces';
import { IndirectCostTile } from '../indirect-cost-tile';
import { IndirectCostTileItem, IndirectCostTileItemComponent } from '../indirect-cost-tile-item';


interface ReduxProps {
  editableData: WorkPackagesData.IndirectCost[];
  editableDataLoadStatus: RequestStatus;
}

interface ReduxActions {
  loadData: (id: number) => void;
  onEditParameter: (id: number, value: string) => void;
}

interface Props extends ReduxProps, ReduxActions {
  calculation: number;
}

interface State {
  expanded: boolean;
}

class IndirectCostListComponent extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    if (this.props.calculation != null) {
      this.props.loadData(this.props.calculation);
    }
    this.state = {
      expanded: true,
    };
  }

  public render(): JSX.Element {
    const { editableData: editedData, editableDataLoadStatus: editedDataLoadStatus } = this.props;
    const parent = editedData.length && editedData.find((x) => x.levelOfNesting === 0);
    const indirectList = this.getChildren(parent.innerGuid);

    return (
      <div className='indirect-cost-list'>
        {editedDataLoadStatus !== RequestStatus.Loaded
          ? <SvgSpinner size='large' />
          : <KreoScrollbars showShadowTop={true}>{this.getIndirectList(indirectList)}</KreoScrollbars>
        }
      </div>
    );
  }

  @autobind
  private getIndirectList(indirectList: WorkPackagesData.IndirectCost[]): JSX.Element {
    return (
      <React.Fragment>
        {indirectList.map((item, index) => {
          const childList = this.getChildren(item.innerGuid);
          return (
            <IndirectCostTile item={item} key={index}>
              {childList.map((child, i) => {
                return (
                  <IndirectCostTileItem
                    key={i}
                    item={child}
                    onEditParameter={this.props.onEditParameter}
                  />
                );
              }) as Array<React.ReactElement<IndirectCostTileItemComponent>>}
            </IndirectCostTile>
          );
        })}
      </React.Fragment>
    );
  }

  @autobind
  private getChildren(innerId: string): WorkPackagesData.IndirectCost[] {
    return this.props.editableData.filter((x) => x.parentGuid === innerId);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    editableData: state.scenarios.indirectCost,
    editableDataLoadStatus: state.scenarios.statuses.indirectCost,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    loadData: (id: number) => dispatch(ScenariosActions.getIndirectSettings(id)),
    onEditParameter: (id: number, value: string) => {
      dispatch(ScenariosActions.changeIndirectSettingsParameter({ id, value }));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const IndirectCostList = connector(IndirectCostListComponent);
