import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconAction: React.FC = () => {
  return (
  <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='icon_action' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        d='M3,6 C3.55228475,6 4,6.44771525 4,7 C4,7.55228475 3.55228475,8 3,8 C2.44771525,8 2,7.55228475 2,7
        C2,6.44771525 2.44771525,6 3,6 Z M7,6 C7.55228475,6 8,6.44771525 8,7 C8,7.55228475 7.55228475,8 7,8
        C6.44771525,8 6,7.55228475 6,7 C6,6.44771525 6.44771525,6 7,6 Z M11,6 C11.5522847,6 12,6.44771525 12,7
        C12,7.55228475 11.5522847,8 11,8 C10.4477153,8 10,7.55228475 10,7 C10,6.44771525 10.4477153,6 11,6 Z'
        id='Combined-Shape'
        fill={KreoColors.f1}
      />
    </g>
  </svg>);
};
