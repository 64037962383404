import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 60px;
  padding: 40px 40px 0px 40px;
`;

const ButtonTextWrapper = styled.div`
  margin-left: 25px;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  :last-child {
    margin-left: 90px;
  }
  p {
    font: 14;
  }
`;

export const Styled = {
  Header,
  ButtonTextWrapper,
};
