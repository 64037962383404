import { BaseAfterEffects } from '../../helpers/update-form-state';
import { AssemblyForm, ItemGroupUpdaterParams } from '../assembly-panel/item-form-updater';
import { AssemblyGroupUpdaterParams, GroupForm } from './helpers';

export enum MeasurePanelType {
  AssemblyMeasure = 'AssemblyMeasure',
  ItemMeasure = 'ItemMeasure',
}

export interface MeasureForm {
  assemblyGroupForm: GroupForm;
}

export interface AssemblyAfterEffects {
  baseParams: BaseAfterEffects<MeasureForm>;
  params: AssemblyGroupUpdaterParams<MeasureForm>;
}

export interface ItemAfterEffects {
  baseParams: BaseAfterEffects<AssemblyForm>;
  params: ItemGroupUpdaterParams<AssemblyForm>;
}

export interface PanelAfterEffects<TFrom> {
  afterSetForm: (form: TFrom) => void;
}

export type MeasurePanelAssemblyAfterEffects = AssemblyAfterEffects & PanelAfterEffects<MeasureForm>;
export type MeasurePanelItemAfterEffects = ItemAfterEffects & PanelAfterEffects<AssemblyForm>;
