import { numberUtils } from 'common/utils/number-utils';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { CEMeasurementsPercentageExtractorType as Type, CEMeasurementsValueId } from 'unit-cost-estimate/constants';
import { CEMeasurementExtractorFunctionTemplate, CEMeasurementValueGroup } from 'unit-cost-estimate/interfaces';
import { CEMeasurementsUtils } from './ce-measurements-utils';
import { CEPercentageExtractorUtils as PercentageUtils } from './ce-percentage-extractor-utils';

export interface ChangeResult {
  primaryExtractorValues: Record<string, Array<{ id: string, value: number }>>;
  changedValues: Record<string, ValueChange>;
}

interface ValueChange {
  value: number | string;
  delta: number;
}

function getGroupValue(measurement: CEMeasurementValueGroup): number {
  const formattedValue = measurement.commonValue ? measurement.commonValue.toString().replace(/,/, '.') : '';
  return numberUtils.getNumeralFormatter(formattedValue).value();
}

function getValueChange(
  rowValues: Record<string, CEMeasurementValueGroup>, extractorId: string, value: number | string,
): ValueChange {
  return {
    value,
    delta: +value - getGroupValue(rowValues[extractorId]),
  };
}

function castPercentageDouble(value: number): number {
  return value < 0
    ? 0
    : value > 100
      ? 1
      : value / 100;
}

function isPercentageRelatedExtractorId(extractorId: string, rowExtractors: string[]): boolean {
  const baseExtractorId = PercentageUtils.getBaseExtractorId(extractorId);
  return rowExtractors.some(x => x.startsWith(baseExtractorId) && baseExtractorId !== x);
}

function getRelatedValuesWithDelta(
  rowValues: Record<string, CEMeasurementValueGroup>,
  extractorId: string,
  baseValue: number,
  primaryPercentage: number,
  extractorValueString: string,
  unit: UnitTypes,
): Record<string, ValueChange> {
  const relatedValues = PercentageUtils.getRelatedValues(baseValue, primaryPercentage);
  const baseExtractorId = PercentageUtils.getBaseExtractorId(extractorId);

  const valueMap: Record<string, number | string> = {
    [baseExtractorId]: UnitUtil.round(baseValue, unit),
    [PercentageUtils.getPrimaryPercentageId(baseExtractorId)]:
      UnitUtil.round(primaryPercentage * 100, UnitTypes.Percentage),
    [PercentageUtils.getPrimaryValueId(baseExtractorId)]:
      UnitUtil.round(relatedValues[Type.PrimaryValue], unit),
    [PercentageUtils.getSecondaryPercentageId(baseExtractorId)]:
      UnitUtil.round(relatedValues[Type.SecondaryPercentage] * 100, UnitTypes.Percentage),
    [PercentageUtils.getSecondaryValueId(baseExtractorId)]:
      UnitUtil.round(relatedValues[Type.SecondaryValue], unit),
  };
  valueMap[extractorId] = extractorValueString;

  return getValuesWithDelta(rowValues, valueMap);
}

function getValuesWithDelta(
  rowValues: Record<string, CEMeasurementValueGroup>, valueMap: Record<string, number | string>,
): Record<string, ValueChange> {
  const result: Record<string, ValueChange> = {};
  Object.entries(valueMap).forEach(([extractorId, value]) => {
    result[extractorId] = getValueChange(rowValues, extractorId, value);
  });

  return result;
}

function getValuesOnPrimaryPercentageChange(
  rowValues: Record<string, CEMeasurementValueGroup>, extractorId: string, value: string, unit: UnitTypes,
): ChangeResult {
  const baseExtractorId = PercentageUtils.getBaseExtractorId(extractorId);
  const baseValue = getGroupValue(rowValues[baseExtractorId]);
  const primaryPercentage = castPercentageDouble(+value);
  const stringValue = value !== ''
    ? UnitUtil.round(primaryPercentage * 100, UnitTypes.Percentage).toString()
    : '';

  return {
    primaryExtractorValues: { [baseExtractorId]: [
      { id: baseExtractorId, value: baseValue },
      { id: extractorId, value: primaryPercentage },
    ] },
    changedValues: getRelatedValuesWithDelta(rowValues, extractorId, baseValue, primaryPercentage, stringValue, unit),
  };
}

function getValuesOnPrimaryValueChange(
  rowValues: Record<string, CEMeasurementValueGroup>, extractorId: string, value: string, unit: UnitTypes,
): ChangeResult {
  const baseExtractorId = PercentageUtils.getBaseExtractorId(extractorId);
  const primaryPercentage = getGroupValue(rowValues[PercentageUtils.getPrimaryPercentageId(baseExtractorId)]);
  const primaryPercentageCasted = castPercentageDouble(primaryPercentage);
  const baseValue = UnitUtil.round(+value, unit) / primaryPercentageCasted;

  return {
    primaryExtractorValues: { [baseExtractorId]: [
      { id: baseExtractorId, value: baseValue },
    ] },
    changedValues: getRelatedValuesWithDelta(rowValues, extractorId, baseValue, primaryPercentageCasted, value, unit),
  };
}

function getValuesOnSecondaryPercentageChange(
  rowValues: Record<string, CEMeasurementValueGroup>, extractorId: string, value: string, unit: UnitTypes,
): ChangeResult {
  const baseExtractorId = PercentageUtils.getBaseExtractorId(extractorId);
  const primaryPercentageId = PercentageUtils.getPrimaryPercentageId(baseExtractorId);
  const baseValue = getGroupValue(rowValues[baseExtractorId]);
  const secondaryPercentage = castPercentageDouble(+value);
  const primaryPercentage = 1 - secondaryPercentage;
  const stringValue = value !== ''
    ? UnitUtil.round(secondaryPercentage * 100, UnitTypes.Percentage).toString()
    : '';

  return {
    primaryExtractorValues: { [baseExtractorId]: [
      { id: baseExtractorId, value: baseValue },
      { id: primaryPercentageId, value: primaryPercentage },
    ] },
    changedValues: getRelatedValuesWithDelta(rowValues, extractorId, baseValue, primaryPercentage, stringValue, unit),
  };
}

function getValuesOnSecondaryValueChange(
  rowValues: Record<string, CEMeasurementValueGroup>, extractorId: string, value: string, unit: UnitTypes,
): ChangeResult {
  const baseExtractorId = PercentageUtils.getBaseExtractorId(extractorId);
  const secondaryPercentage = getGroupValue(rowValues[PercentageUtils.getSecondaryPercentageId(baseExtractorId)]);
  const secondaryPercentageCasted = castPercentageDouble(secondaryPercentage);
  const primaryPercentage = (1 - secondaryPercentageCasted);
  const baseValue = UnitUtil.round(+value, unit) / secondaryPercentageCasted;

  return {
    primaryExtractorValues: { [baseExtractorId]: [
      { id: baseExtractorId, value: baseValue },
    ] },
    changedValues: getRelatedValuesWithDelta(rowValues, extractorId, baseValue, primaryPercentage, value, unit),
  };
}

function getValuesOnBaseValueChange(
  rowValues: Record<string, CEMeasurementValueGroup>, extractorId: string, value: string, unit: UnitTypes,
): ChangeResult {
  const primaryPercentage = getGroupValue(rowValues[PercentageUtils.getSecondaryPercentageId(extractorId)]);
  const primaryPercentageCasted = castPercentageDouble(primaryPercentage);
  const baseValue = UnitUtil.round(+value, unit);

  return {
    primaryExtractorValues: { [extractorId]: [
      { id: extractorId, value: baseValue },
    ] },
    changedValues: getRelatedValuesWithDelta(rowValues, extractorId, baseValue, primaryPercentageCasted, value, unit),
  };
}

function getPercentageRelatedValues(
  rowValues: Record<string, CEMeasurementValueGroup>, extractorId: string, value: string, unit: UnitTypes,
): ChangeResult {
  if (extractorId.endsWith(Type.PrimaryPercentage)) {
    return getValuesOnPrimaryPercentageChange(rowValues, extractorId, value, unit);
  }
  if (extractorId.endsWith(Type.PrimaryValue)) {
    return getValuesOnPrimaryValueChange(rowValues, extractorId, value, unit);
  }
  if (extractorId.endsWith(Type.SecondaryPercentage)) {
    return getValuesOnSecondaryPercentageChange(rowValues, extractorId, value, unit);
  }
  if (extractorId.endsWith(Type.SecondaryValue)) {
    return getValuesOnSecondaryValueChange(rowValues, extractorId, value, unit);
  }

  return getValuesOnBaseValueChange(rowValues, extractorId, value, unit);
}

function getExtractorsChanges(
  rowValues: Record<string, CEMeasurementValueGroup>,
  extractors: Record<string, CEMeasurementExtractorFunctionTemplate>,
  extractorId: string,
  value: string,
): ChangeResult {
  if (isPercentageRelatedExtractorId(extractorId, Object.keys(rowValues))) {
    const baseExtractorId = PercentageUtils.getBaseExtractorId(extractorId);
    const baseExtractor = extractors[baseExtractorId].extractors.find(x => x.extractorFunctionId === baseExtractorId);
    return getPercentageRelatedValues(rowValues, extractorId, value, baseExtractor.unitName);
  }

  const { numberValue } = CEMeasurementsUtils.convertMeasurementValues(value, rowValues[extractorId]);
  return {
    primaryExtractorValues: { [extractorId]: [{ id: extractorId, value: numberValue }] },
    changedValues: { [extractorId]: getValueChange(rowValues, extractorId, value) },
  };
}

function getReinforcementChanges(
  values: Record<string, CEMeasurementValueGroup>,
  extractors: Record<string, CEMeasurementExtractorFunctionTemplate>,
  volume: number,
  generalExtractorId: string,
  extractorId: string,
  value: string,
): ChangeResult {
  const generalExtractor = extractors[generalExtractorId].extractors
    .find(x => x.extractorFunctionId === generalExtractorId);
  const secondaryExtractor = extractors[generalExtractorId].extractors
    .find(x => x.extractorFunctionId !== generalExtractorId);
  const { elementExtractorValue, numberValue } = CEMeasurementsUtils
    .convertMeasurementValues(value, values[extractorId]);

  let delta = 0;
  if (generalExtractor.extractorFunctionId === CEMeasurementsValueId.ReinforcementMass) {
    const changedFunction = values[generalExtractorId];
    let concentrationValue: string;
    let massValue: string;
    let massNumberValue = 0;
    if (extractorId === generalExtractor.extractorFunctionId) {
      massValue = value;
      concentrationValue = UnitUtil.round(numberValue / volume, secondaryExtractor.unitName).toString();
      delta = numberValue - elementExtractorValue;
      massNumberValue = numberValue;
    } else {
      massNumberValue = numberValue * volume;
      const massRounded = UnitUtil.round(massNumberValue, generalExtractor.unitName);
      const massSourceValue = numberUtils.getNumeralFormatter(changedFunction.commonValue).value();
      delta = massRounded - massSourceValue;
      massValue =  massRounded.toString();
      concentrationValue = value;
    }

    return {
      primaryExtractorValues: {
        [CEMeasurementsValueId.ReinforcementMass]: [
          { id: CEMeasurementsValueId.ReinforcementMass, value: massNumberValue },
        ],
      },
      changedValues: {
        [CEMeasurementsValueId.ReinforcementMass]: { delta, value: massValue },
        [CEMeasurementsValueId.ReinforcementConcentration]: { delta, value: concentrationValue },
      },
    };
  }
}

function appendChanges(changes: ChangeResult, newChanges: ChangeResult): void {
  changes.changedValues = { ...changes.changedValues, ...newChanges.changedValues };
  for (const [key, values] of Object.entries(newChanges.primaryExtractorValues)) {
    if (key in changes.primaryExtractorValues) {
      for (const [index, value] of values.entries()) {
        changes.primaryExtractorValues[key][index] = value;
      }
    } else {
      changes.primaryExtractorValues[key] = values;
    }
  }
}

export const CEMeasurementValueChangeUtils = {
  appendChanges,
  getExtractorsChanges,
  getReinforcementChanges,
};
