import styled from 'styled-components';

const HEIGHT = 100;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: ${HEIGHT};
  width: 100%;
  padding: 20px 0;
  flex-shrink: 0;
  button {
    border-radius: 15px;
  }
`;

export const Styled = {
  HEIGHT,
  Container,
};
