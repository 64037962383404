import { IconButton, Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { Title } from '../../item-title';
import { Styled } from '../styled';


interface Props {
  isOpen: boolean;
  hideOpenToggleIcon: boolean;
  disableEdit: boolean;
  name: string;
  hideTitle: boolean;
  onOpenChange: (e: React.MouseEvent) => void;
  getContainerTitleRef: (ref: HTMLDivElement) => void;
  onNameChange: (name: string) => void;
  setRenameMode: (renameMode: boolean) => void;
  isHighlighted?: boolean;
}

const GroupTitleWrapperComponent: React.FC<Props> = ({
  isOpen,
  hideOpenToggleIcon,
  onOpenChange,
  getContainerTitleRef,
  disableEdit,
  name,
  onNameChange,
  hideTitle,
  setRenameMode,
  isHighlighted,
}) => {
  return (
    <Styled.Wrapper
      isHighlighted={isHighlighted}
    >
      <Styled.OpenToggle
        isOpen={isOpen}
        hideOpenToggleIcon={hideOpenToggleIcon}
      >
        <IconButton
          Icon={Icons.RightSmall}
          onClick={onOpenChange}
          height={30}
        />
      </Styled.OpenToggle>
      <Styled.Inner
        ref={getContainerTitleRef}
        hideTitle={hideTitle}
      >
        <Title
          onChange={onNameChange}
          name={name}
          disabled={disableEdit}
          setRenameMode={setRenameMode}
        />
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

export const GroupTitleWrapper = React.memo(GroupTitleWrapperComponent);
