import { MultiViewHelper } from 'common/utils/multi-view/multi-view-helper';
import { ChildReportCellController } from './child-report-cell-controller';
import { ParentReportCellController } from './parent-report-cell-controller';
import { CellDataController, ReportCellController } from './report-cell-controller';

export const cellStoreActionEventType = 'CELL_STORE';

export const cellStoreActions: Record<keyof CellDataController, string> = {
  initReportCellStores: 'initReportCellStores',
  loadData: 'loadData',
  updateCells: 'updateCells',
  updateCellsByMeasure: 'updateCellsByMeasure',
  updateCellsByRemoveInstancesIds: 'updateCellsByRemoveInstancesIds',
  addNewRows: 'addNewRows',
  addNewColumns: 'addNewColumns',
  setColumnWidthBulck: 'setColumnWidthBulck',
  selectSheetValue: 'selectSheetValue',
  selectSheetData: 'selectSheetData',
  getUpdatedCells: 'getUpdatedCells',
  getReportData: 'getReportData',
  onDrawingsLoaded: 'onDrawingsLoaded',
  updateCellValueWithDrawings: 'updateCellValueWithDrawings',
  updateCellDataOnFill: 'updateCellDataOnFill',
  updateDynamicCells: 'updateDynamicCells',
  clearStore: 'clearStore',
};

export function extendWithCellStore(
  this: MultiViewHelper,
  cellStoreController: ReportCellController,
): CellDataController {
  if (!this.module) {
    return cellStoreController;
  }
  return this.module.isParentMode
    ? new ParentReportCellController(this.sendEvent, this.addEventHandler, cellStoreController)
    : new ChildReportCellController(this.sendEvent, this.addEventHandler, cellStoreController);
}
