import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MultilevelSelectOptionDataWithObjectValue } from 'common/components/multi-level-drop-down-menu';
import { State } from 'common/interfaces/state';
import { MoveToCellOptionData } from '..';
import { getFolderToCellOptions } from '../../drawings-canvas/get-folder-to-cell-options';
import { useFilterMeasure3d } from '../../hooks';
import {
  DrawingsGeometryGroup,
  DrawingsGeometryState,
  DrawingsInstanceMeasure,
  DrawingsShortInfo,
} from '../../interfaces';
import { DrawingsInstanceMeasureHelper } from '../../utils/measures/get-drawings-instance-measure-helper';
import { useGetInstancesMeasures } from './use-get-instances-measures';

export function useGetFolderToCellOptions(
  getInstanceMeasureHelper: () => DrawingsInstanceMeasureHelper,
): () => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>> {
  const selectedGroups = useSelector<State, string[]>(s => s.drawings.selectGeometryGroup);
  const groups = useSelector<State, DrawingsGeometryGroup[]>(s => s.drawings.drawingGeometryGroups);
  const elementMeasurement = useSelector<State, Record<string, DrawingsInstanceMeasure>>(
    (x) => x.drawings.elementMeasurement,
  );
  const drawings = useSelector<State, Record<string, DrawingsShortInfo>>((x) => x.drawings.drawingsInfo);
  const aiAnnotation = useSelector<State, DrawingsGeometryState>(s => s.drawings.aiAnnotation);

  const getInstancesMeasures = useGetInstancesMeasures(getInstanceMeasureHelper);
  const filter3d = useFilterMeasure3d();

  return useCallback(() => {
    return getFolderToCellOptions(
      groups,
      selectedGroups,
      elementMeasurement,
      drawings,
      aiAnnotation,
      getInstancesMeasures,
    ).filter(({ value }) => filter3d(value.type));
  }, [
    groups,
    selectedGroups,
    elementMeasurement,
    drawings,
    aiAnnotation,
    getInstancesMeasures,
    filter3d,
  ]);
}
