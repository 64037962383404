import * as React from 'react';

import { MultilevelSelectOptionDataWithObjectValue } from 'common/components/multi-level-drop-down-menu';
import { MultiLevelSelectOption } from 'common/components/multi-level-drop-down-menu/multi-level-select-option';
import { KreoColors } from 'common/enums/kreo-colors';
import { ContextMenuMoveToCellItem } from './context-menu-move-to-cell-item';
import { MoveToCellOptionData } from './interfaces/options';

export const HELP_TOOLTIP_MENU = `The function is only available for sending data to the Spreadsheet. 
To send the data, open the Spreadsheet first`;

interface MoveToCellProps {
  option: MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>;
  color: string;
  isImperial: boolean;
}

export const MoveToCellOption: React.FC<MoveToCellProps> = ({ option, color, isImperial }) => {
  const childRenderer = option.value.renderMenu;
  if (childRenderer) {
    return (
      <MultiLevelSelectOption
        isSelected={false}
        isSelectable={false}
        selectedValue={null}
        value={null}
        childRenderer={childRenderer}
        optionData={null}
        themeStyle={true}
        subvariants={null}
        onSelectClick={null}
      >
        <ContextMenuMoveToCellItem
          name={option.name}
          value={option.value.value}
          type={option.value.type}
          valueColor={color || KreoColors.red}
          isImperial={isImperial}
        />
      </MultiLevelSelectOption>
    );
  } else {
    return (
      <ContextMenuMoveToCellItem
        name={option.name}
        value={option.value.value}
        type={option.value.type}
        valueColor={color || KreoColors.red}
        isImperial={isImperial}
      />
    );
  }
};
