import { CellStyleGetter } from './cell-style-getter';
import { PropertyGetter } from './property-getter';
import { PropertySetter } from './property-setter';


export const ExcelTableCellFormatter = {
  getCellStyle: CellStyleGetter.getCellStyle,
  getCellsStyleProperty: PropertyGetter.getCellsStyleProperty,
  setCellsStyleProperty: PropertySetter.setCellsStyleProperty,
};
