import React, { useEffect } from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { SignalRLogger } from './signalr/signalr-logger';


export const RealtimeAbilitiesHandler: React.FC = () => {
  const canLog = useAbility(Operation.Manage, Subject.SignalRLogging);

  useEffect(() => {
    SignalRLogger.canLog = canLog;
  }, [canLog]);
  return (<></>);
};

