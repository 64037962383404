import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconSubnavBack: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icon_add' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M5.70710678,8 L9.85355339,12.1464466 C10.0488155,12.3417088 10.0488155,12.6582912
          9.85355339,12.8535534 C9.65829124,13.0488155 9.34170876,13.0488155 9.14644661,12.8535534
          L4.64644661,8.35355339 C4.45118446,8.15829124 4.45118446,7.84170876 4.64644661,7.64644661
          L9.14644661,3.14644661 C9.34170876,2.95118446 9.65829124,2.95118446 9.85355339,3.14644661
          C10.0488155,3.34170876 10.0488155,3.65829124 9.85355339,3.85355339 L5.70710678,8 Z'
          id='Path-3'
          fill={KreoColors.f1}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
