import * as Ag from 'ag-grid-community';

export interface AgGridState {
  nodes: Record<number, Ag.RowNode>;
  columns: Ag.ColumnState[];
}

export interface AgGridTableApi {
  api: Ag.GridApi;
  columnApi: Ag.ColumnApi;
}

export type AgGridSaveStateType  = (gridOptions: AgGridTableApi) => void;
export type AgGridCustomSaveType = (state: AgGridState) => void;

export interface AgGridSaveStateHelper<EventHandlersType> {
  eventHandlers: EventHandlersType;
  saveState: AgGridSaveStateType;
}


interface BaseStateHelperConfig {
  name: string;
  saveState?: AgGridSaveStateType;
}

type StateHelperConfig<T = {}> = Partial<T> & BaseStateHelperConfig;


type BaseEventHandlersType = {
  [key: string]: (e: Ag.AgGridEvent) => void,
};


function getTableEventHandlers<EventHandlersType extends BaseEventHandlersType, K extends keyof EventHandlersType>(
  config: StateHelperConfig<EventHandlersType>,
  eventHandlerKeys: K[],
): EventHandlersType {
  const handlers: Record<string, (e: Ag.AgGridEvent) => void> = {};
  const customSave = config.saveState;
  for (const key of eventHandlerKeys) {
    handlers[key as string] = (e: Ag.AgGridEvent) => {
      if (config[key] && typeof config[key] === 'function') {
        config[key](e);
      }
      if (customSave) {
        customSave(e);
      }
    };
  }
  return handlers as EventHandlersType;
}


export function getSaveAgGridStateHelper<EventHandlersType extends BaseEventHandlersType>(
  config: StateHelperConfig<EventHandlersType>,
  handlers: Array<keyof EventHandlersType>,
): AgGridSaveStateHelper<EventHandlersType> {
  return {
    eventHandlers: getTableEventHandlers(config, handlers),
    saveState: config.saveState,
  };
}
