import styled from 'styled-components';

import { Styled as ItemStyled } from './drawings-snapping-modes-selection-item-styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: all ${p => p.theme.duration.s} linear;

  ${ItemStyled.Container} + ${ItemStyled.Container} {
    margin-top: 5px;
  }
`;

export const Styled = {
  Container,
};
