import { FilterKeys } from 'common/components/tree-filter-panel/constants';
import { GraphStorageRecordsConfig, RecordConfig } from '../../interfaces/graph-storage-records-config';
import { getNRMCodeByLevel } from './get-additional-nrm-column';

const isStandardClassifications = (fieldName: string): boolean => {
  return fieldName === FilterKeys.F_NRM1
    || fieldName === FilterKeys.F_NRM2
    || fieldName === FilterKeys.F_UNI_SYS
    || fieldName === FilterKeys.F_UNI_PROD;
};

const expandNRM = (
  rowData: Record<string, Array<string | number>>,
  fieldName: string,
  recordsConfig: GraphStorageRecordsConfig,
): void => {
  if (fieldName === FilterKeys.F_NRM1 || fieldName === FilterKeys.F_NRM2) {
    const fullCode = rowData[fieldName][0] as string;
    const additionalProps = getNRMCodeByLevel(
      fullCode,
      fieldName,
    );
    for (const [key, nrmValue] of Object.entries(additionalProps)) {
      rowData[key] = [`${nrmValue} ${(recordsConfig[fieldName] as RecordConfig).displayNames[nrmValue]}`];
    }
  }
};

type FormatLevels = Partial<Record<keyof typeof FilterKeys, string>>;

const getMasterFormatLevels = (fullCode: string, fieldName: string): FormatLevels => {
  const [level1] = fullCode.split(' ');
  return {
    [fieldName]: fullCode,
    [FilterKeys.F_MAS_FORM_0_LEVEL]: level1,
  };
};

const expandMasterFormat = (
  rowData: Record<string, Array<string | number>>,
  fieldName: string,
  recordsConfig: GraphStorageRecordsConfig,
): void => {
  if (fieldName === FilterKeys.F_MAS_FORM) {
    const fullCode = rowData[fieldName][0] as string;
    const additionalProps = getMasterFormatLevels(
      fullCode,
      fieldName,
    );
    for (const [key, masterValue] of Object.entries(additionalProps)) {
      const displayName = (recordsConfig[fieldName] as RecordConfig).displayNames[masterValue];
      rowData[key] = [`${masterValue} ${displayName}`];
    }
  }
};

const getSMM7FormatLevel = (fullCode: string, fieldName: string): FormatLevels => {
  return {
    [fieldName]: fullCode,
    [FilterKeys.F_SMM7_CLF_0_level]: fullCode[0],
  };
};

const expandSMM7Format = (
  rowData: Record<string, Array<string | number>>,
  fieldName: string,
  recordsConfig: GraphStorageRecordsConfig,
): void => {
  if (fieldName === FilterKeys.F_SMM7_CLF) {
    const fullCode = rowData[fieldName][0] as string;
    const additionalProps = getSMM7FormatLevel(fullCode, fieldName);
    for (const [key, smm7Value] of Object.entries(additionalProps)) {
      const displayName = (recordsConfig[fieldName] as RecordConfig).displayNames[smm7Value];
      rowData[key] = [`${smm7Value} ${displayName}`];
    }
  }
};

const getUniFormatLevels = (fullCode: string, fieldName: string): FormatLevels => {
  const level1 = fullCode[0];
  const level2 = fullCode.slice(0, 3);

  return {
    [fieldName]: fullCode,
    [FilterKeys.F_UNI_FORM_0_LEVEL]: level1,
    [FilterKeys.F_UNI_FORM_1_LEVEL]: level2,
  };
};

const expandUniFormat = (
  rowData: Record<string, Array<string | number>>,
  fieldName: string,
  recordsConfig: GraphStorageRecordsConfig,
): void => {
  if (fieldName === FilterKeys.F_UNI_FORM) {
    const fullCode = rowData[fieldName][0] as string;
    const additionalProps = getUniFormatLevels(
      fullCode,
      fieldName,
    );
    for (const [key, nrmValue] of Object.entries(additionalProps)) {
      const displayName = (recordsConfig[fieldName] as RecordConfig).displayNames[nrmValue];
      rowData[key] = [`${nrmValue} ${displayName}`];
    }
  }
};

const expandStandardClassifications = (
  rowData: Record<string, Array<string | number>>, fieldName: string, recordsConfig: GraphStorageRecordsConfig,
): void => {
  if (isStandardClassifications(fieldName)) {
    const value = rowData[fieldName][0];
    rowData[fieldName] = [`${value} ${(recordsConfig[fieldName] as RecordConfig).displayNames[value]}`];
  }
};

export const expandAggHash = (rowData: Record<string, Array<string | number>>, aggHashKey: string): number => {
  let hash = 0;
  if (aggHashKey.length === 0) return hash;
  for (let i = 0; i < aggHashKey.length; i++) {
    const char = aggHashKey.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + char;
    // eslint-disable-next-line no-bitwise
    hash = hash & hash;
  }
  rowData.aggHash = [hash.toString()];
};

export const expandRowData = (
  rowData: Record<string, Array<string | number>>,
  fieldName: string,
  recordsConfig: GraphStorageRecordsConfig,
): void => {
  expandNRM(rowData, fieldName, recordsConfig);
  expandMasterFormat(rowData, fieldName, recordsConfig);
  expandUniFormat(rowData, fieldName, recordsConfig);
  expandStandardClassifications(rowData, fieldName, recordsConfig);
  expandSMM7Format(rowData, fieldName, recordsConfig);
};
