import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  height: 10px;
  display: flex;
  padding: 5px 20px 10px 10px;
  align-items: center;

  ${Text} {
    height: 10px;
    margin-left: 10px;
  }
`;

const PinIcon = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  fill: ${p => p.theme.color.gray};
`;

export const Styled = {
  Container,
  PinIcon,
};
