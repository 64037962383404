const prefix = '@quantity-take-off-template';

const CREATE_TEMPLATE = `${prefix}/CREATE_TEMPLATE`;
const SET_SELECTED_TEMPLATE_ID = `${prefix}/SET_SELECTED_TEMPLATE_ID`;
const GET_TEMPLATES_INFO = `${prefix}/GET_TEMPLATES_INFO`;
const SET_TEMPLATES_INFO = `${prefix}/SET_TEMPLATES_INFO`;
const UPDATE_TEMPLATE_INFO = `${prefix}/UPDATE_TEMPLATE_INFO`;
const GET_INITIAL_STATE = `${prefix}/GET_INITIAL_STATE`;
const DELETE_TEMPLATE = `${prefix}/DELETE_TEMPLATE`;
const LOAD_TEMPLATE = `${prefix}/LOAD_TEMPLATE`;
const SET_TEMPLATE_MODEL = `${prefix}/SET_TEMPLATE_MODEL`;
const UPDATE_BASIC_COLUMNS = `${prefix}/UPDATE_BASIC_COLUMNS`;
const UPDATE_TEMPLATE_ROWS = `${prefix}/UPDATE_TEMPLATE_ROWS`;
const ADD_ROWS = `${prefix}/ADD_ROWS`;
const REMOVE_ROWS = `${prefix}/REMOVE_ROWS`;
const REORDER_ROWS = `${prefix}/REORDER_ROWS`;
const UPDATE_TEMPLATES_INFO_AFTER_CREATE_VIA_REPORT = `${prefix}/UPDATE_TEMPLATES_INFO_AFTER_CREATE_VIA_REPORT`;
const CREATE_REPORT_FROM_TEMPLATE = `${prefix}/CREATE_REPORT_FROM_TEMPLATE`;


export const QuantityTakeOffTemplateActionTypes = {
  REORDER_ROWS,
  REMOVE_ROWS,
  ADD_ROWS,
  UPDATE_TEMPLATE_ROWS,
  UPDATE_BASIC_COLUMNS,
  CREATE_TEMPLATE,
  SET_SELECTED_TEMPLATE_ID,
  GET_TEMPLATES_INFO,
  SET_TEMPLATES_INFO,
  UPDATE_TEMPLATE_INFO,
  GET_INITIAL_STATE,
  DELETE_TEMPLATE,
  LOAD_TEMPLATE,
  SET_TEMPLATE_MODEL,
  UPDATE_TEMPLATES_INFO_AFTER_CREATE_VIA_REPORT,
  CREATE_REPORT_FROM_TEMPLATE,
};
