import styled from 'styled-components';

import { TRANSITION_POINT } from '../../../../constants';


const ProgressBar = styled.div<{ size: number, completed: number }>`
  display: block;
  width: 100%;
  height: 5px;
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 5px;
  margin-bottom: 30px;
  box-sizing: border-box;

  ::after {
    content: '';
    display: block;
    width: ${p => (100 / p.size) * p.completed}%;
    height: 100%;
    background-color: ${p => p.theme.color.turquoise};
    border-radius: 5px;
    transition: 0.35s ease-in-out;
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    margin-bottom: 20px;
  }
`;

export const Styled = {
  ProgressBar,
};
