import { RequestStatus } from 'common/enums/request-status';
import { CEActivityAssignmentState } from 'unit-cost-estimate/interfaces';

export const CE_ACTIVITY_ASSIGNMENT_INITIAL_STATE: CEActivityAssignmentState = {
  statistic: null,
  activtyLayoutView: false,
  tree: [],
  works: [],
  selectedPath: null,
  searchedPathes: [],
  searchMode: false,
  selectedWorks: [],
  rawData: [],
  elementDataMap: [],
  layersData: [],
  dataDictionary: [],
  groupNames: [],
  properties: [],
  dataLoaded: false,
  badIds: [],
  workLinks: [],
  errorNodes: [],
  engineIds: new Array<number>(),
  selectedWork: null,
  isSaveData: false,
  viewTree: true,
  statuses: {
    loadData: RequestStatus.NotRequested,
    revitData: RequestStatus.NotRequested,
  },
  assignmentExistedModalities: [],
  isShowActivities: false,
  isShowSelection: true,
  hasChanges: false,
  centralTreeElement: null,
};
