import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { CalibrateActions } from '../../actions/creators/calibrate';
import { CalibrationDrawModes, DrawingsDrawMode } from '../../enums';
import { DrawingsShortInfo } from '../../interfaces';

export function useCallibrationEffect(drawMode: DrawingsDrawMode): void {
  const isCalibrationMode = CalibrationDrawModes.includes(drawMode);

  const currentDrawing = useSelector<State, DrawingsShortInfo>(x => x.drawings.currentDrawingInfo);
  const dispatch = useDispatch();

  const prevDrawingInfo = useRef<DrawingsShortInfo>(null);
  const prevIsCalibrate = useRef<boolean>(false);

  useEffect(() => {
    if (
      (isCalibrationMode && !prevIsCalibrate.current)
      || (currentDrawing && !prevDrawingInfo.current && isCalibrationMode)
    ) {
      dispatch(CalibrateActions.startEdit(currentDrawing));
    } else if (!isCalibrationMode && prevIsCalibrate.current) {
      dispatch(CalibrateActions.dropState());
    }
    prevIsCalibrate.current = isCalibrationMode;
    prevDrawingInfo.current = currentDrawing;
  }, [isCalibrationMode, currentDrawing]);
}
