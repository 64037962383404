import { SvgComponent } from '@kreo/kreo-ui-components';
import { connect } from 'react-redux';

import { AnyAction, Dispatch } from 'redux';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { CommentaryTargetType, TwoDCommentsActions } from 'unit-2d-comments/index';
import { TargetUtils } from 'unit-2d-comments/utils';
import { Comment, Props } from './comment';

interface StateProps {
  targetIcon: SvgComponent;
  targetTooltip: string;
  targetTitle: string;
  isEdit: boolean;
  subCommentOnEdit: number;
  canEditStatusAndComment: boolean;
  targetType: CommentaryTargetType;
  shouldFocusViewToComment: boolean;
  isSelected: boolean;
}

interface DispatchProps {
  setEditComment: (id: number) => void;
  setEditSubComment: (id: number) => void;
  onFocused: () => void;
}

function mapStateToProps(state: State, ownProps: Props): StateProps {
  const target = ownProps.target;
  const canEdit = state.twoDComments.createSubCommentStatus[ownProps.id] !== RequestStatus.Loading
    && state.twoDComments.resolveRequestStatuses[ownProps.id] !== RequestStatus.Loading;
  return {
    subCommentOnEdit: state.twoDComments.subCommentOnEdit,
    canEditStatusAndComment: canEdit,
    targetIcon: TargetUtils[target.type].icon,
    targetTooltip: TargetUtils[target.type].tooltip,
    targetTitle: TargetUtils[target.type].nameSelector(state, target),
    isEdit: state.twoDComments.commentOnEdit === ownProps.id,
    targetType: target.type,
    shouldFocusViewToComment: state.twoDComments.commentToFocus === ownProps.id,
    isSelected: state.twoDComments.selectedCommentId === ownProps.id,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setEditComment: (id) => dispatch(TwoDCommentsActions.setEditComment(id)),
    setEditSubComment: (id) => dispatch(TwoDCommentsActions.setEditSubComment(id)),
    onFocused: () => dispatch(TwoDCommentsActions.selectComment(null)),
  };
}

export const CommentReduxWrapper = connect(mapStateToProps, mapDispatchToProps)(Comment);
