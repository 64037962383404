import React from 'react';
import { Mood } from 'common/enums/mood';
import { Styled } from './styled';

export interface Props {
  mood?: Mood;
}

export const Paper: React.FC<Props> = ({ mood = Mood.Default, children }) => {
  return (
    <Styled.Container mood={mood}>
      <Styled.StatusBar mood={mood}/>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Container>
  );
};
