import { TwoDRegex } from '2d/units/2d-regex';
import { ExcelFormulaHelper } from './excel-formula-helper';
import { getDiffs } from './get-diffs';

export const getFormulaToPaste = (
  fromCell: string,
  toCell: string,
  formula: string,
  defaultSheetId: string,
): string => {
  const { diffColumn, diffRow } = getDiffs(fromCell, toCell);

  return formula.replace(
    TwoDRegex.formulaPartGlobal,
    (...args) => {
      return ExcelFormulaHelper.updateCellLinkByOffset(args, diffColumn, diffRow, defaultSheetId);
    },
  );
};
