import { MenuItemStyled, Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 220px;
  margin: -5px 0;

  ${MenuItemStyled.Item} {
    height: 40px;
    box-sizing: border-box;
    padding: 10px 0;

    :not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.color.background};
    }

    > div {
      ${Text} {
        display: inline-flex;
        align-items: center;
        line-height: 20px;
      }
    }

    svg {
      margin-right: 20px;
    }
  }

  a {
    width: 100%;
    text-decoration: none;

    :not(:nth-child(5)) {
      border-bottom: 1px solid ${props => props.theme.color.background};
    }
  }
`;

export const Styled = {
  Container,
};
