import React from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';


import { DrawingSettings } from '../hooks/settings/use-drawing-settings';

interface StateProps {
  isNightTheme: boolean;
}

interface MainLayoutProps extends DrawingSettings, React.HTMLProps<HTMLDivElement>, StateProps {
  measurementViewPermission: boolean;
  shouldIgnoreSettings: boolean;
  hasHighQualityLayout: boolean;
  drawingRendered: boolean;
}

function getFilterValue(props: MainLayoutProps): string {
  let value = '';
  if (props.isBlackAndWhiteEnabled) {
    value += 'grayscale(1) ';
  }
  if (props.isNightModeEnabled && props.isNightTheme) {
    value += 'invert(1)';
  }

  return value || 'none';
}

function getOpacity(props: MainLayoutProps): number {
  const targetMainOpactity = props.shouldIgnoreSettings ? 0.5 : props.opacity / 100;
  if (!props.hasHighQualityLayout) {
    return targetMainOpactity;
  }
  const targetBackgroundOpacity = 1 - targetMainOpactity;
  const opacity = 1 - Math.sqrt(targetBackgroundOpacity);
  return opacity;
}

function getCanvasStyle(props: MainLayoutProps): FlattenSimpleInterpolation {
  const opacity = getOpacity(props);
  if (props.shouldIgnoreSettings) {
    return css`
      canvas {
        opacity: ${opacity};
      }
    `;
  } else {
    return css`
      canvas {
        filter: ${getFilterValue(props)};
        opacity: ${opacity};
      }
    `;
  }
}

function removeScrollWithoutPdf(props: MainLayoutProps): FlattenSimpleInterpolation {
  if (!props.drawingRendered) {
    return css`
      min-width: 100% !important;
      min-height: 100% !important;
    `;
  }
}

function getMixBlendMode({ isNightModeEnabled, isNightTheme, shouldIgnoreSettings }: MainLayoutProps): string {
  return isNightModeEnabled && isNightTheme && !shouldIgnoreSettings ? 'screen' : 'normal';
}

const MainLayout = styled.div<MainLayoutProps>`
  position: relative;
  display: flex;
  z-index: 0;
  mix-blend-mode: ${getMixBlendMode};
  pointer-events: ${p => p.measurementViewPermission ? 'auto' : 'none'};
  ${getCanvasStyle}
  ${p => removeScrollWithoutPdf(p)};
`;

export const Styled = {
  MainLayout,
};
