import * as React from 'react';

export const KreoIconDataBase: React.FC = (_props) => {
  return (
    <svg width='26px' height='26px' viewBox='0 0 26 26' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='UI-KIT' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Database_Activities_UI-Elements'
          transform='translate(-178.000000, -4631.000000)'
          fill='#748091'
          fillRule='nonzero'
        >
          <g id='Group-17' transform='translate(0.000000, 4428.000000)'>
            <g id='Group-14' transform='translate(149.000000, 66.000000)'>
              <g id='Limit' transform='translate(0.000000, 110.000000)'>
                <g id='Group-4' transform='translate(29.000000, 27.000000)'>
                  <path
                    d='M1.5,6 L1.5,20 C1.5,22.5443773 5.89507662,24.5 13,24.5
                    C20.1049234,24.5 24.5,22.5443773 24.5,20 L24.5,6 C24.5,3.45562275
                    20.1049234,1.5 13,1.5 C5.89507662,1.5 1.5,3.45562275 1.5,6 Z M0.5,6
                    C0.5,2.68203855 5.40392467,0.5 13,0.5 C20.5960753,0.5 25.5,2.68203855
                    25.5,6 L25.5,20 C25.5,23.3179614 20.5960753,25.5 13,25.5 C5.40392467,25.5
                    0.5,23.3179614 0.5,20 L0.5,6 Z'
                    id='Combined-Shape'
                  />
                  <path
                    d='M13,11.5 C5.40392467,11.5 0.5,9.31796145 0.5,6 C0.5,2.68203855
                    5.40392467,0.5 13,0.5 C20.5960753,0.5 25.5,2.68203855 25.5,6 C25.5,9.31796145
                    20.5960753,11.5 13,11.5 Z M13,10.5 C20.1049234,10.5 24.5,8.54437725 24.5,6 C24.5,3.45562275
                    20.1049234,1.5 13,1.5 C5.89507662,1.5 1.5,3.45562275 1.5,6 C1.5,8.54437725 5.89507662,10.5
                    13,10.5 Z'
                    id='Oval'
                  />
                  <path
                    d='M0.776393202,15.4472136 C0.529403954,15.323719 0.42929178,15.0233825 0.552786405,14.7763932
                    C0.676281029,14.529404 0.976617549,14.4292918 1.2236068,14.5527864 C8.63701833,18.2594922
                    17.3629817,18.2594922 24.7763932,14.5527864 C25.0233825,14.4292918 25.323719,14.529404
                    25.4472136,14.7763932 C25.5707082,15.0233825 25.470596,15.323719 25.2236068,15.4472136
                    C17.5286678,19.2946831 8.47133223,19.2946831 0.776393202,15.4472136 Z'
                    id='Path-2'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
