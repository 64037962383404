import classNames from 'classnames';
import * as React from 'react';
import ReactTooltip, { Place } from 'react-tooltip';

import './help-tooltip.scss';

import { KreoIconTooltipHelp, KreoIconTooltipInfo } from 'common/UIKit';

interface Props {
  text: string | JSX.Element;
  id?: string;
  className?: string;
  place?: Place;
  infoMode?: boolean;
  icon?: JSX.Element;
  dark?: boolean;
}

export class HelpTooltip extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { text, id, infoMode, icon } = this.props;
    const place = this.props.place ? this.props.place : 'top';
    const className = classNames('db-help-tooltip', this.props.className, { 'db-help-tooltip--dark': this.props.dark });
    return (
      <div className={className} data-for={id} data-tip=''>
        {icon ? icon : (infoMode ? <KreoIconTooltipInfo /> : <KreoIconTooltipHelp />)}
        <ReactTooltip
          id={id}
          className='db-help-tooltip__tooltip'
          type='light'
          place={place}
          effect='solid'
          delayHide={150}
          delayShow={150}
        >
          {text}
        </ReactTooltip>
      </div>
    );
  }
}
