export const MIN_COLUMN_WIDTH = 60;

export const QtoCommonTableConstants = {
  rowHeight: 40,
  aggregationQuantityRowHeight: 16,
  defaultColDef: {
    minWidth: MIN_COLUMN_WIDTH,
    suppressMenu: false,
    resizable: true,
  },
};
