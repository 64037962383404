import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { Styled } from './styled';

export interface TagProps {
  text: string;
  remove: (text: string) => void;
  hasProblem?: boolean;
  disabled?: boolean;
  className?: string;
}

const TagComponent: React.FC<TagProps> = ({ text, remove, disabled, hasProblem }) => {
  const handleOnRemove = useCallback(e => {
    e.stopPropagation();
    remove(text);
  }, [text, remove]);

  return (
    <Styled.Tag hasProblem={hasProblem}>
      <span>{text}</span>
      {!disabled && (
        <Icons.CloseSmall
          onClick={handleOnRemove}
        />
      )}
    </Styled.Tag>
  );
};


export const Tag = React.memo(TagComponent);
