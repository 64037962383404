import { QtoLeftPanelConstants } from '../components/quantity-take-off-left-panel/constants';
import { QtoRecord } from '../components/quantity-take-off-left-panel/interfaces';
import { SharedParameterKeyHelper } from './shared-parameter-key-helper';

type CommonPropertyNameToValuesType = Record<string, string[]>;
type MemoType = Record<number, CommonPropertyNameToValuesType>;


const getCommonProperties = (
  obj: QtoRecord,
  memo: MemoType,
  getElementPropertyNameToValuesMap: (ids: number[]) => CommonPropertyNameToValuesType,
  mapBimIdsToEngineIds: (bimId: number[]) => number[],
): CommonPropertyNameToValuesType => {
  const id = obj.id;
  if (id in memo) {
    return memo[id];
  }

  const engineIds = mapBimIdsToEngineIds([id as number]);
  const result = getElementPropertyNameToValuesMap(engineIds);
  memo[id] = result;

  return result;
};

const valueGetter = (
  obj: QtoRecord, key: string, memo: MemoType,
  getElementPropertyNameToValuesMap: (ids: number[]) => CommonPropertyNameToValuesType,
  mapBimIdsToEngineIds: (bimId: number[]) => number[],
): Array<string | number> | number | string => {
  if (key in obj.props) {
    return obj.props[key];
  }

  const prefix = QtoLeftPanelConstants.ADDITIONAL_PROPERTIES_PREFIX;
  if (key.startsWith(prefix)) {
    const commonProperties = getCommonProperties(obj, memo, getElementPropertyNameToValuesMap, mapBimIdsToEngineIds);
    return commonProperties[SharedParameterKeyHelper.getNameFormKey(key, prefix)];
  }

  return undefined;
};

type CustomFilterValueGetter = (obj: QtoRecord, key: string) => Array<string | number> | number | string;

const getQtoCustomFilterValueGetterFunction = (
  getElementPropertyNameToValuesMap: (ids: number[]) => CommonPropertyNameToValuesType,
  mapBimIdsToEngineIds: (bimId: number[]) => number[],
): CustomFilterValueGetter => {
  const memo = {};
  return (obj: QtoRecord, key: string) =>
    valueGetter(obj, key, memo, getElementPropertyNameToValuesMap, mapBimIdsToEngineIds);
};


export const QtoCustomFilterValueGetter = {
  getQtoCustomFilterValueGetterFunction,
};
