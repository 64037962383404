import { RectangleButton, Text } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadersList } from 'common/components/progress';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { ProgressUtils } from 'common/utils/progress-utils';
import { DrawingsActions } from '../../actions/creators/common';
import { Styled } from './styled';

const DrawingsProgressComponent: React.FC = () => {
  const currentDrawingId = useSelector<State, string | null>((x) => x.drawings.currentDrawingInfo?.drawingId);
  const showMessage = useSelector<State, boolean>((x) => x.drawings.showOptimizeMessage);
  const progressKey = currentDrawingId ? ProgressUtils.createCurrentDrawingKey(currentDrawingId) : null;
  const dispatch = useDispatch();
  const optimize = useCallback(() => {
    dispatch(DrawingsActions.toggleDrawingOptimize(currentDrawingId, true));
  }, [currentDrawingId]);
  return (
    <Styled.Background>
      <Styled.Container>
        <LoadersList progressKey={progressKey} />
        <LoadersList progressKey={ProgressUtils.DRAWING_PROGRESS_KEY} />
        <RenderIf condition={showMessage}>
          <Text fontSize={12}>
            Complex geometry in the file. Display takes longer. Try optimizing for faster rendering.
          </Text>
          <RectangleButton height={20} width={225} mood="positive" onClick={optimize} text="Optimize" />
        </RenderIf>
      </Styled.Container>
    </Styled.Background>
  );
};

export const DrawingsProgress = React.memo(DrawingsProgressComponent);
