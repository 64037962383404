import * as t from 'io-ts';

import { DateMillisecondsNumberC } from 'common/codecs/date-milliseconds-number';
import { KnownViewModelC } from '../enums/known-view-model';
import { ViewModelStatusC } from '../enums/view-model-status';


export const ViewModelStatusPairC = t.intersection(
  [
    t.type({
      viewModelType: KnownViewModelC,
      status: ViewModelStatusC,
    }),
    t.partial({
      startTime: DateMillisecondsNumberC,
      estimatedDuration: t.number,
    }),
  ],
  'ViewModelStatusPair',
);

export type ViewModelStatusPair = t.TypeOf<typeof ViewModelStatusPairC>;
