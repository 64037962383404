import { ConstantFunctions } from 'common/constants/functions';
import { arrayUtils } from 'common/utils/array-utils';
import { FormulaInputValidationStatus, FormulaPartTypes } from '../../enums';
import { FormulaFormIssues, FormulaInputValidationResult, FormulaPart } from '../../interfaces';
import { FormulaInputParsers } from '../formula-parsers';
import { FormulaValidationStatusBuilder } from '../formula-validation-status-builder';


type SaveStatusCallback = (result: FormulaInputValidationResult, span: HTMLSpanElement) => void;

const NOT_VALID_SYMBOLS_FOR_VALUE = /[.,]/g;

function isStartOfScope(type: FormulaPartTypes | null): boolean {
  return !type
    || type === FormulaPartTypes.FunctionOpen
    || type === FormulaPartTypes.ScopeOpen
    || type === FormulaPartTypes.Splitter;
}

function isEndOfScope(type: FormulaPartTypes | null): boolean {
  return !type
    || type === FormulaPartTypes.FunctionClose
    || type === FormulaPartTypes.ScopeClose
    || type === FormulaPartTypes.Splitter;
}

function isValuableType(type: FormulaPartTypes): boolean {
  return type !== FormulaPartTypes.Spaces;
}

function isClosedScope({ children, type, text }: FormulaPart): boolean {
  const closeType = type === FormulaPartTypes.RoundBracerScope
    ? FormulaPartTypes.ScopeClose
    : FormulaPartTypes.FunctionClose;
  return children ? children[children.length - 1].type === closeType : text[text.length - 1] === ')';
}

function validateRoundBracerScope(commonArray: FormulaPart[], part: FormulaPart): FormulaInputValidationResult {
  if (isClosedScope(part)) {
    if (part.children) {
      arrayUtils.extendArray(commonArray, part.children);
    } else {
      return FormulaValidationStatusBuilder.emptyExpression();
    }
  } else {
    return FormulaValidationStatusBuilder.cannotFindClosingBracer();
  }
  return FormulaValidationStatusBuilder.ok();
}

function validateConstant(text: string, beforeValuablePart: FormulaPart): FormulaInputValidationResult {
  if (text[0] === `"`) {
    if (text.length <= 1 || text[0] !== text[text.length - 1]) {
      return FormulaValidationStatusBuilder.cannotFindTextCloser(text[0]);
    }
  } else {
    const numValue = Number(text);
    if (Number.isNaN(numValue)) {
      return FormulaValidationStatusBuilder.invalidNumberFormat();
    } else if (
      numValue === 0
      && beforeValuablePart
      && beforeValuablePart.type === FormulaPartTypes.Operation
      && beforeValuablePart.text === '\/'
    ) {
      return FormulaValidationStatusBuilder.divisionByZero();
    }
  }
  return FormulaValidationStatusBuilder.ok();
}

function validateOperation(text: string, previousValuableType: FormulaPartTypes): FormulaInputValidationResult  {
  if (isStartOfScope(previousValuableType)) {
    if (text !== '+' && text !== '-') {
      return FormulaValidationStatusBuilder.operationWontWork();
    }
  } else if (previousValuableType === FormulaPartTypes.Operation) {
    return FormulaValidationStatusBuilder.unexpectedToken(text);
  }
  return FormulaValidationStatusBuilder.ok();
}

function validateProperty(text: string, propertyNames: Set<string>): FormulaInputValidationResult  {
  if (text[text.length - 1] !== ']') {
    return FormulaValidationStatusBuilder.parameterIsNotClosed();
  }
  const matches = text.match(NOT_VALID_SYMBOLS_FOR_VALUE);
  const propertyName = FormulaInputParsers.getPropertyName(text);
  if (matches) {
    return FormulaValidationStatusBuilder.unexpectedParameterSymbols(matches);
  } else if (!propertyName.length) {
    return FormulaValidationStatusBuilder.emptyPropertyName();
  } else if (!propertyNames.has(propertyName)) {
    return FormulaValidationStatusBuilder.propertyIsNotDefined();
  }
  return FormulaValidationStatusBuilder.ok();
}

function getPreviousValuablePart(index: number, parts: FormulaPart[]): FormulaPart | null {
  for (let i = index - 1; i >= 0; i--) {
    const part = parts[i];
    const { type, bounds } = part;
    if (i > 0 && bounds[0] < parts[i - 1].bounds[0]) {
      return null;
    }
    if (isValuableType(type)) {
      return part;
    }
  }
  return null;
}


function nextValuableItemInfo(parts: FormulaPart[], index: number): [FormulaPart, number] | null {
  for (let i = index + 1; i < parts.length; i++) {
    const part = parts[i];
    const { type, bounds } = part;
    if (i > 0 && bounds[0] < parts[i - 1].bounds[0]) {
      return null;
    }
    if (isValuableType(type)) {
      return [part, i];
    }
  }
  return null;
}

function validateFunction(
  commonArray: FormulaPart[],
  part: FormulaPart,
  libFunctions: Record<string, number>,
  propertyNamesSet: Set<string>,
  saveStatus: SaveStatusCallback,
): FormulaInputValidationResult  {
  if (isClosedScope(part)) {
    const { name, argsCount } = FormulaInputParsers.getFunctionInfo(part);
    if (name.toUpperCase() in libFunctions) {
      if (part.children) {
        const { children } = part;
        if (argsCount > 1) {
          let partsBetweenSplitters = [];
          for (let i = 1; i < children.length; i++) {
            if (children[i].type === FormulaPartTypes.Splitter) {
              if (partsBetweenSplitters.length === 0) {
                return FormulaValidationStatusBuilder.functionEmptyArgument();
              }
              validateParts(partsBetweenSplitters, libFunctions, propertyNamesSet, saveStatus);
              partsBetweenSplitters = [];
            } else {
              partsBetweenSplitters.push(children[i]);
            }
          }
          if (partsBetweenSplitters.length) {
            validateParts(partsBetweenSplitters, libFunctions, propertyNamesSet, saveStatus);
          }
        } else {
          arrayUtils.extendArray(commonArray, children);
        }
      }
    } else {
      return FormulaValidationStatusBuilder.functionDoesntExist(name);
    }
  } else {
    return FormulaValidationStatusBuilder.cannotFindClosingBracer();
  }
  return FormulaValidationStatusBuilder.ok();
}

function validateMeasuredFunction(
  part: FormulaPart,
): FormulaInputValidationResult {
  if (isClosedScope(part)) {
    const { text } = part;
    if (text[text.length - 2] !== '(') {
      return FormulaValidationStatusBuilder.unexpectedSymbolsBetweenBracers();
    }
  } else {
    return FormulaValidationStatusBuilder.cannotFindClosingBracer();
  }
  return FormulaValidationStatusBuilder.ok();
}

function isScopeUtilityType(type: FormulaPartTypes): boolean {
  return type === FormulaPartTypes.FunctionClose
    || type === FormulaPartTypes.ScopeClose
    || type === FormulaPartTypes.ScopeOpen
    || type === FormulaPartTypes.FunctionOpen;
}


function validateParts(
  checkParts: FormulaPart[],
  libFunctions: Record<string, number>,
  propertyNamesSet: Set<string>,
  saveStatus: SaveStatusCallback,
): void {
  for (let i = 0; i < checkParts.length; i++) {
    const { type, span, text } = checkParts[i];
    const previousValuablePart = getPreviousValuablePart(i, checkParts);
    const previousValuableType = previousValuablePart ? previousValuablePart.type : null;
    if (type === FormulaPartTypes.Operation) {
      const result = validateOperation(text, previousValuableType);
      saveStatus(result, span);
      if (result.status !== FormulaInputValidationStatus.Ok) {
        continue;
      }
      const nextValuableInfo = nextValuableItemInfo(checkParts, i);
      if (!nextValuableInfo) {
        saveStatus(FormulaValidationStatusBuilder.cannotFinishWithOperation(), span);
      } else {
        const [nextValuableItem, index] = nextValuableInfo;
        if (isEndOfScope(nextValuableItem.type)) {
          saveStatus(FormulaValidationStatusBuilder.cannotFinishWithOperation(), span);
        }
        i = index - 1;
      }
    } else if (
      type !== FormulaPartTypes.Spaces
      && !isScopeUtilityType(type)
      && previousValuableType !== FormulaPartTypes.Operation
      && !isStartOfScope(previousValuableType)
    ) {
      saveStatus(FormulaValidationStatusBuilder.operationIsNotDefined(), span);
    }
    if (type === FormulaPartTypes.Property) {
      saveStatus(validateProperty(text, propertyNamesSet), span);
    } else if (type === FormulaPartTypes.Constant) {
      saveStatus(validateConstant(text, previousValuablePart), span);
    } else if (type === FormulaPartTypes.RoundBracerScope) {
      saveStatus(validateRoundBracerScope(checkParts, checkParts[i]), span);
    } else if (type === FormulaPartTypes.FunctionSplitted) {
      saveStatus(validateFunction(checkParts, checkParts[i], libFunctions, propertyNamesSet, saveStatus), span);
    } else if (type === FormulaPartTypes.MeasureFunction) {
      saveStatus(validateMeasuredFunction(checkParts[i]), span);
    }
  }
}

function validateFormula(
  parts: FormulaPart[],
  libFunctions: Record<string, number>,
  propertyNamesSet: Set<string>,
): FormulaFormIssues {
  const checkParts = parts.slice();
  const errors = [];
  const warnings = [];

  const setError = (text: string, span: HTMLSpanElement): void => {
    errors.push(text);
    if (span) {
      span.title = text;
      span.style.textDecoration = 'red wavy underline';
    }
  };

  const setWarning = (text: string, span: HTMLSpanElement): void => {
    warnings.push(text);
    if (span) {
      span.style.textDecoration = 'yellow wavy underline';
      span.title = text;
    }
  };

  const resultActions: Record<number, (text: string, span: HTMLSpanElement) => void> = {
    [FormulaInputValidationStatus.Ok]: ConstantFunctions.doNothing,
    [FormulaInputValidationStatus.Error]: setError,
    [FormulaInputValidationStatus.Warning]: setWarning,
  };

  const saveStatus = ({ status, text }: FormulaInputValidationResult, span: HTMLSpanElement): void => {
    resultActions[status](text, span);
  };

  validateParts(checkParts, libFunctions, propertyNamesSet, saveStatus);
  return {
    warnings,
    errors,
  };
}

export const FormulaInputValidators = {
  isStartOfScope,
  isClosedScope,
  validateRoundBracerScope,
  validateConstant,
  validateOperation,
  validateProperty,
  validateFunction,
  validateFormula,
  getPreviousValuablePart,
};
