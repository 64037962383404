import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';

import './category-header.scss';

import { TitleEditable } from 'common/components/title-editable/index';
import { KreoIconCollapse } from 'common/UIKit';
import { KreoIconAddPlusBordered } from 'common/UIKit/icons/add-icon-plus-bordered';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { ActivityGroupData } from '../../interfaces';


interface Props {
  id: number;
  name: string;
  draggingActivityGroup: ActivityGroupData.ActivityGroup | null;
  activityGroupsCount: number;
  expand: boolean;
  onExpandCollapse: () => void;
  replaceActivityGroup(activityGroupId: number, categoryId: number): void;
  renameCategory(categoryId: number, name: string): void;
  removeCategory(categoryId: number): void;
  createActivityGroup(categoryId: number): void;
}

class CategoryHeaderComponent extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const { name, expand } = this.props;
    const collapseClassNames = classNames(
      'activity-group-category-header__collapse-icon',
      { 'activity-group-category-header__collapse-icon--collapse': !expand },
    );
    return (
      <div className='activity-group-category-header'>
        {this.props.activityGroupsCount
          ? (
            <div
              className={collapseClassNames}
              onClick={this.props.onExpandCollapse}
            >
              <KreoIconCollapse />
            </div>
          ) : (
            <div className='activity-group-category-header__controls'>
              {this.renderMenu()}
            </div>
          )}
        <div className='activity-group-category-header__name'>
          <TitleEditable
            label={name}
            text={name}
            onChange={this.renameCategory}
            canEdit={true}
            isEditByIconClick={true}
            sizeSmall={true}
          />
        </div>
        <div className='activity-group-category-header__add-new-group-button' onClick={this.createActivityGroup}>
          <KreoIconAddPlusBordered />
        </div>
      </div>
    );
  }

  @autobind
  private renameCategory(name: string): void {
    this.props.renameCategory(this.props.id, name);
  }

  @autobind
  private removeCategory(): void {
    if (!this.props.activityGroupsCount) {
      this.props.removeCategory(this.props.id);
    }
  }

  @autobind
  private createActivityGroup(): void {
    this.props.createActivityGroup(this.props.id);
  }

  private renderMenu(): JSX.Element {
    const menu = this.getMenuProps();
    if (menu.length === 0) {
      return null;
    }

    return (
      <ItemMenu
        menu={menu}
      />);
  }

  private getMenuProps(): ItemMenuType[] {
    const menu: ItemMenuType[] = [];

    if (this.props.activityGroupsCount === 0) {
      menu.push({
        name: 'Remove',
        action: this.removeCategory,
      });
    }

    return menu;
  }
}


export const CategoryHeader = CategoryHeaderComponent;
