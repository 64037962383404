import styled from 'styled-components';

const Container = styled.div`
  width: 500px;
  height: 400px;
  padding: 25px;
  overflow: scroll;
`;

const Content = styled.div`
`;

export const Styled = {
  Container,
  Content,
};
