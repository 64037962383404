import * as React from 'react';

import './custom-element-filter-builder-warning-tooltip.scss';

import { ElementTooltip, TooltipPosition } from '../element-tooltip';


interface Props {
  text: string;
}

const targetElement = (
  <div className='custom-element-filter-builder-warning-tooltip'>
    <b>!</b>
  </div>
);

export class CustomElementFilterBuilderWarningTooltip extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <ElementTooltip
        tooltipPosition={TooltipPosition.CenterTop}
        size='small'
        targetElement={targetElement}
      >
        {this.props.text}
      </ElementTooltip>
    );
  }
}

