import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { debounce } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { AnyAction, Dispatch } from 'redux';

import './page.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { MainLayout } from 'common/components/main-layout';
import { SubHeader } from 'common/components/sub-header/sub-header';
import { SubheaderBillingNotice } from 'common/components/subheader-billing-notice/subheader-billing-notice';
import { ControlNames } from 'common/constants/control-names';
import { ProjectType } from 'common/constants/project-type';
import { FaqCaption } from 'common/enums/faq-caption';
import { ProjectCreateStatus } from 'common/enums/project-create-status';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import {
  IconButton,
  KreoButton,
  KreoDialogActions,
  KreoIconCreateNew,
  KreoIconProjectCreate,
  KreoIconSearchLarge,
  MaterialInput,
} from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { AppUrls } from 'routes/app-urls';
import { ProjectAccessReason } from 'unit-projects/enums';
import { DemoFileUploadButton } from '../../../../components/demo-file-upload-button';
import {
  Create3dProjectDialog,
  CREATE_3D_PROJECT_DIALOG_NAME,
} from '../../../../components/dialog';
import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { CREATE_PROJECT } from '../../../../constants/forms';
import { connectUiTour, UiToursName } from '../../../../ui-tour';
import { AccountActions } from '../../../../units/account/actions/creators';
import { CompanySubscriptionModel } from '../../../account/interfaces/company-subscription-model';
import { getRootMenuItems } from '../../../menu-items';
import { ProjectsActions } from '../../actions/creators/common';
import { ProjectsApi } from '../../api/common';
import { LoadMore } from '../../components/load-more';
import { ProjectTile } from '../../components/project-tile';
import { CompanyProjectHeader } from '../../interfaces/company-project-header';

interface PageStateProps {
  projectHeaders: Record<number, CompanyProjectHeader>;
  searchQuery: string | null;
  allFetched: boolean;
  companyId: number;
  projectHeadersRequestStatus: RequestStatus;
  subscription: CompanySubscriptionModel;
}

interface PageDispatchProps {
  fetchCompanyProjectHeaders: (skip: number, take: number, companyId: number, search?: string) => void;
  showDialog: (name: string) => void;
  closeDialog: (dialogName: string) => void;
  openSelfServePortal: () => void;
  removeProject: (projectId: number) => void;
  failureProjectIdSend: (projectId: number) => void;
  clearProjectHeaders: (projectId: number) => void;
  createProject: () => void;
  changeSearchQuery: (companyId: number, searchQuery: string | null) => void;
  dropStore: () => void;
}

interface PageProps extends PageStateProps, PageDispatchProps, AbilityAwareProps { }

interface PageState {
  projectToBeRemoved: CompanyProjectHeader | null;
  searchOpen: boolean;
  projectCreateStatus: ProjectCreateStatus;
}

export const PROJECTS_PAGE_SIZE = 10;
const REMOVE_PROJECT_DIALOG_NAME = 'project-remove-dialog';

// TODO:
// 1. refactor so that searchQuery updates trigger search by themselves, not relying on this component
// 2. refactor so that all actions dispatched from this component don't include companyId,
//    but use selectedCompanyId from state instead
class ProjectsPageComponent extends React.Component<PageProps, PageState> {
  private changeFilterDebounced: (value: string) => void;
  private searchInputRef: React.RefObject<MaterialInput> = React.createRef();

  constructor(props: PageProps) {
    super(props);

    this.changeFilterDebounced = debounce(this.updateSearch, 1000);

    this.state = {
      projectToBeRemoved: null,
      searchOpen: !!props.searchQuery,
      projectCreateStatus: null,
    };
  }

  public render(): React.ReactNode {
    const { projectHeaders, allFetched, ability, subscription: companySubscription } = this.props;

    const projectIds = Object.keys(projectHeaders).reverse();

    const projectsLimitHit = companySubscription &&
      Number.isInteger(companySubscription.projectsLimit) &&
      projectIds.length >= companySubscription.projectsLimit;
    const isPlanFeatures = ability.can(Operation.Read, Subject.PlanFeatures);
    const canCreateProject = ability.can(Operation.Create, Subject.Project) && !projectsLimitHit;

    return (
      <MainLayout
        faqCaption={isPlanFeatures ? null : FaqCaption.QtoGeneral}
        getMenuItems={getRootMenuItems}
        backUrl={AppUrls.products.url()}
        subHeader={
          <SubHeader
            title='Projects'
            leftSectionContent={this.getCreateProjectButtons(canCreateProject)}
            rightSectionContent={this.renderSearchInput()}
            alert={
              projectsLimitHit && (
                <SubheaderBillingNotice
                  text={<>Your subscription only supports <b>{companySubscription.projectsLimit} projects</b>.</>}
                />
              )
            }
          />
        }
        metaTitle='Projects'
      >
        <div className='content-wrapper'>
          <div className='projects-page' data-control-name={ControlNames.projectsPage}>
            {this.renderProjectRemoveDialog()}
            <Create3dProjectDialog onSubmit={this.onSubmit3d} />
            {projectIds.length
              ? projectIds.map(projectId => {
                return (
                  <ProjectTile
                    key={projectId}
                    projectHeader={projectHeaders[projectId]}
                    onRemoveConfirm={this.onRemoveProject}
                  />
                );
              })
              : canCreateProject && this.props.projectHeadersRequestStatus !== RequestStatus.Loading && (
                <div
                  className={
                    classNames(
                      'projects-page__placeholder',
                      { 'projects-page__placeholder--search': this.state.searchOpen },
                    )
                  }
                  onClick={!this.props.searchQuery ? this.openCreate3dProjectDialog : null}
                >
                  <KreoIconProjectCreate />
                  <div className='projects-page__placeholder-text'>
                    {this.state.searchOpen ? 'No projects found' : 'Create your first project!'}
                  </div>
                </div>
              )}
            {allFetched ? null : (
              <LoadMore status={this.props.projectHeadersRequestStatus} onLoadMore={this.fetchProjects} />
            )}
          </div>
        </div>
      </MainLayout>
    );
  }

  public componentDidMount(): void {
    this.fetchProjectsFirstPageIfNeeded();
  }

  public componentDidUpdate(): void {
    const { projectToBeRemoved, projectCreateStatus } = this.state;
    this.fetchProjectsFirstPageIfNeeded();
    if (
      projectToBeRemoved
      && !projectToBeRemoved.projectFailureNotified
      && projectCreateStatus === ProjectCreateStatus.Failed
    ) {
      this.props.failureProjectIdSend(projectToBeRemoved.id);
    }
  }

  public componentWillUnmount(): void {
    this.props.dropStore();
  }

  @autobind
  private onSubmit3d(): void {
    this.props.createProject();
    this.props.closeDialog(CREATE_3D_PROJECT_DIALOG_NAME);
  }

  @autobind
  private renderSearchInput(): React.ReactNode {
    return (
      <>
        <CSSTransition
          timeout={200}
          classNames='projects-page__search-button'
          in={!this.state.searchOpen}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <div className='projects-page__search-button'>
            <IconButton
              size='medium'
              icon={<KreoIconSearchLarge />}
              onClick={this.onSearchOpen}
            />
          </div>
        </CSSTransition>
        <CSSTransition
          timeout={200}
          classNames='projects-page__search-input'
          in={this.state.searchOpen}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <MaterialInput
            ref={this.searchInputRef}
            autoFocus={false}
            showClearButton={true}
            searchType={true}
            placeholder='Search'
            className='projects-page__search-input'
            displayedType={MaterialComponentType.Native}
            onChange={this.onSearchChanged}
            value={this.props.searchQuery}
            onBlur={this.onSearchBlur}
          />
        </CSSTransition>
      </>
    );
  }

  @autobind
  private onSearchOpen(): void {
    this.setState({ searchOpen: true }, () => this.searchInputRef.current.focus());
  }

  @autobind
  private updateSearch(): void {
    const { companyId, clearProjectHeaders } = this.props;

    clearProjectHeaders(companyId);
  }

  @autobind
  private onSearchChanged(event: React.ChangeEvent, value: string): void {
    const { changeSearchQuery, companyId } = this.props;

    event.preventDefault();
    this.changeFilterDebounced(value);
    changeSearchQuery(companyId, value);
  }

  @autobind
  private onSearchBlur(event: React.ChangeEvent, value: string): void {
    event.preventDefault();
    if (!value) {
      this.setState({ searchOpen: false });
    }
  }

  @autobind
  private getCreateProjectButtons(canCreateProject: boolean): React.ReactNode {
    if (!canCreateProject) {
      return null;
    }

    return (
      <div className='kreo-sub-header__btn-wrap'>
        <KreoButton
          size='medium'
          className='kreo-sub-header__action-button'
          caption='Create Project'
          icon={<KreoIconCreateNew />}
          rounded={true}
          mode='submit'
          onClick={this.openCreate3dProjectDialog}
          controlName={ControlNames.create3dProjectDialogButton}
        />
        <DemoFileUploadButton
          url={ProjectsApi.demoModelUrl}
          name='Demo Project'
          fieldName='fileName'
          formName={CREATE_PROJECT}
          onClick={this.openCreate3dProjectDialog}
          controlName={ControlNames.createDemoProjectDialogButton}
        />
      </div>
    );
  }

  private renderProjectRemoveDialog(): React.ReactNode {
    if (!this.state.projectToBeRemoved) {
      return null;
    }

    const projectName = this.state.projectToBeRemoved.name;
    let dialogText;

    switch (this.state.projectCreateStatus) {
      case ProjectCreateStatus.Loading:
        dialogText = (
          <>
            The <b>"{projectName}"</b> project is calculating.
            This may take some time. Are you sure you want to Delete <b>"{projectName}"</b> Project?
          </>
        );
        break;
      case ProjectCreateStatus.Failed:
        dialogText = <>Notification has sent. Are you sure you want to Delete <b>"{projectName}"</b> Project?</>;
        break;
      default:
        dialogText = <>Are you sure you want to Delete <b>"{projectName}"</b> Project?</>;
    }

    return (
      <KreoConfirmationDialog
        name={REMOVE_PROJECT_DIALOG_NAME}
        onYes={this.onProjectRemoveConfirmed}
        yesText='Delete'
        noText='Cancel'
        title='Are you sure you want to remove the project?'
      >
        {dialogText}
      </KreoConfirmationDialog>
    );
  }

  @autobind
  private openCreate3dProjectDialog(): void {
    this.openCreateProjectDialog(CREATE_3D_PROJECT_DIALOG_NAME);
  }

  private openCreateProjectDialog(dialogName: string): void {
    this.props.showDialog(dialogName);
  }

  @autobind
  private onProjectRemoveConfirmed(): void {
    this.props.removeProject(this.state.projectToBeRemoved.id);
    this.setState({ projectToBeRemoved: null });
  }

  @autobind
  private onRemoveProject(projectId: number, projectCreateStatus: ProjectCreateStatus): void {
    const { projectHeaders, showDialog } = this.props;
    this.setState(
      {
        projectToBeRemoved: projectHeaders[projectId],
        projectCreateStatus,
      },
      () => showDialog(REMOVE_PROJECT_DIALOG_NAME),
    );
  }

  private fetchProjectsFirstPageIfNeeded(): void {
    const {
      projectHeaders,
      companyId,
      allFetched,
      projectHeadersRequestStatus,
      fetchCompanyProjectHeaders,
    } = this.props;

    const projectsCount = Object.keys(projectHeaders).length;
    if (
      companyId !== null &&
      (!projectsCount || projectsCount < PROJECTS_PAGE_SIZE) &&
      !allFetched &&
      projectHeadersRequestStatus !== RequestStatus.Loading
    ) {
      fetchCompanyProjectHeaders(projectsCount, PROJECTS_PAGE_SIZE, companyId, this.props.searchQuery);
    }
  }

  @autobind
  private fetchProjects(): void {
    const {
      companyId,
      allFetched,
      projectHeadersRequestStatus,
      projectHeaders,
      fetchCompanyProjectHeaders,
    } = this.props;

    if (companyId !== null && !allFetched && projectHeadersRequestStatus !== RequestStatus.Loading) {
      const projectsCount = Object.keys(projectHeaders).length;
      fetchCompanyProjectHeaders(projectsCount, PROJECTS_PAGE_SIZE, companyId, this.props.searchQuery);
    }
  }
}

const mapStateToProps = (state: State): PageStateProps => {
  const selectedCompany = state.account.selectedCompany;
  const selectedSubscriptionType = state.account.selectedSubscriptionType;
  const companyId = selectedCompany ? selectedCompany.id : null;

  const headersStore = state.projects.projectHeadersByCompanyId[companyId];
  const projectHeadersStore = headersStore && headersStore[ProjectType.Project3d];

  const { data: projectHeaders, searchQuery, allFetched } = projectHeadersStore
    ? projectHeadersStore
    : { data: {}, searchQuery: '', allFetched: false };

  const projectHeadersRequestStatus = state.projects.requests.projectHeadersByCompanyId[companyId] ||
    RequestStatus.NotRequested;

  return {
    companyId,
    projectHeaders,
    allFetched,
    searchQuery,
    projectHeadersRequestStatus,
    subscription: state.account.selectedCompany.subscriptions[selectedSubscriptionType],
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): PageDispatchProps => {
  return {
    fetchCompanyProjectHeaders: (skip, take, companyId, search) =>
      dispatch(ProjectsActions.fetchCompanyProjectHeadersRequest({
        skip,
        take,
        companyId,
        search,
        type: ProjectType.Project3d,
        accessReason: [ProjectAccessReason.Owner, ProjectAccessReason.Shared],
      })),
    clearProjectHeaders: companyId =>
      dispatch(ProjectsActions.clearCompanyProjectHeaders(companyId, ProjectType.Project3d)),
    showDialog: dialogName => dispatch(KreoDialogActions.openDialog(dialogName)),
    closeDialog: dialogName => dispatch(KreoDialogActions.closeDialog(dialogName)),
    openSelfServePortal: () => dispatch(AccountActions.openSelfServePortal()),
    removeProject: projectId => dispatch(ProjectsActions.removeProject(projectId)),
    createProject: () => dispatch(ProjectsActions.createProject()),
    changeSearchQuery: (companyId, searchQuery) =>
      dispatch(ProjectsActions.changeCompanyProjectsSearchQuery(companyId, searchQuery, ProjectType.Project3d)),
    failureProjectIdSend: projectId => dispatch(ProjectsActions.failureProjectIdSend(projectId)),
    dropStore: () => dispatch(ProjectsActions.dropStore()),
  };
};

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const projectPageWithAbilityContext = withAbilityContext(ProjectsPageComponent);
const projectPageWithUiTour = connectUiTour(projectPageWithAbilityContext, UiToursName.CREATE_DEMO_PROJECT);

export const ProjectsPage = reduxConnector(projectPageWithUiTour);
