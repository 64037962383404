import { ActionWith } from 'common/interfaces/action-with';
import { DatabaseModel } from '../../interfaces/data';
import { CreateDatabaseForm, UpdateDatabaseForm } from '../../interfaces/rest-data';
import { DatabaseActionTypes } from '../types/database';

function createDatabase(model: CreateDatabaseForm): ActionWith<CreateDatabaseForm> {
  return {
    type: DatabaseActionTypes.CREATE_DATABASE_REQUEST,
    payload: model,
  };
}

function loadDatabases(companyId: number): ActionWith<number> {
  return {
    type: DatabaseActionTypes.GET_DATABASES_REQUEST,
    payload: companyId,
  };
}

function loadDatabasesResponse(
  model: DatabaseModel[],
): ActionWith<DatabaseModel[]> {
  return {
    type: DatabaseActionTypes.GET_DATABASES_SUCCEEDED,
    payload: model,
  };
}

function createDatabaseResponse(
  model: DatabaseModel,
): ActionWith<DatabaseModel> {
  return {
    type: DatabaseActionTypes.CREATE_DATABASE_SUCCEEDED,
    payload: model,
  };
}

function updateDatabase(
  model: UpdateDatabaseForm,
): ActionWith<UpdateDatabaseForm> {
  return {
    type: DatabaseActionTypes.UPDATE_DATABASE_REQUEST,
    payload: model,
  };
}


function updateDatabaseResponse(
  model: DatabaseModel,
): ActionWith<DatabaseModel> {
  return {
    type: DatabaseActionTypes.UPDATE_DATABASE_SUCCEEDED,
    payload: model,
  };
}

function deleteDatabase(
  id: number,
): ActionWith<number> {
  return {
    type: DatabaseActionTypes.DELETE_DATABASE_REQUEST,
    payload: id,
  };
}

function deleteDatabaseResponse(
  id: number,
): ActionWith<number> {
  return {
    type: DatabaseActionTypes.DELETE_DATABASE_SUCCEEDED,
    payload: id,
  };
}

function setCurrentDatabase(
  model: DatabaseModel,
): ActionWith<DatabaseModel> {
  return {
    type: DatabaseActionTypes.SET_CURRENT_DATABASE,
    payload: model,
  };
}

function setDefaultDatabase(
  id: number,
): ActionWith<number> {
  return {
    type: DatabaseActionTypes.SET_DEFAULT_DATABASE,
    payload: id,
  };
}

function setDefaultDatabaseResponse(
  id: number,
): ActionWith<number> {
  return {
    type: DatabaseActionTypes.SET_DEFAULT_DATABASE_SUCCEEDED,
    payload: id,
  };
}

export const DatabaseActions = {
  createDatabase,
  createDatabaseResponse,
  loadDatabases,
  loadDatabasesResponse,
  updateDatabase,
  updateDatabaseResponse,
  deleteDatabase,
  deleteDatabaseResponse,
  setCurrentDatabase,
  setDefaultDatabase,
  setDefaultDatabaseResponse,
};
