import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CalibrationDrawModes, ModesWithNewDrawingPanel, DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { State } from 'common/interfaces/state';
import { Menu, ViewType } from 'unit-2d-info-panel/constants';
import { MagicSearchView } from 'unit-2d-info-panel/content/magic-search-view/magic-search-view';
import { DefaultView } from '../content/default-view';
import { NewMeasureView } from '../content/new-measure-view';
import { SelectionView } from '../content/selection-view';
import { useOpenMenu } from './use-open-menu';


const MODES_WITH_BLOCKED_SELECTION = [
  ...CalibrationDrawModes,
  DrawingsDrawMode.AutoMeasure2,
];

export function useContent(setView: (view: ViewType) => void): React.ComponentType {
  const hasSelectedInstances = useSelector<State, boolean>(s => !!s.drawings.selectedInstances.length);
  const hasSelectedGroups = useSelector<State, boolean>(s => !!s.drawings.selectGeometryGroup.length);
  const { drawMode } = useDrawModeApi();
  const openDefaultMenu = useOpenMenu(Menu.Default);

  const content = useMemo(() => {
    if (drawMode === DrawingsDrawMode.MagicSearch) {
      return MagicSearchView;
    }

    if (ModesWithNewDrawingPanel.includes(drawMode)) {
      return NewMeasureView;
    }

    if ((hasSelectedInstances || hasSelectedGroups) && !MODES_WITH_BLOCKED_SELECTION.includes(drawMode)) {
      return SelectionView;
    }

    return DefaultView;
  }, [hasSelectedInstances, hasSelectedGroups, drawMode]);

  useEffect(() => {
    if (content !== DefaultView) {
      setView(ViewType.Page);
      openDefaultMenu();
    }
  }, [content]);

  return content;
}
