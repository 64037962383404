import autobind from 'autobind-decorator';
import * as React from 'react';

import './index.scss';

import { WorkPackagesData } from '../../interfaces';

interface Props {
  item: WorkPackagesData.Activity;
  isSelected: boolean;
  onItemClick: (item: WorkPackagesData.Activity) => void;
}

export class PopoverActivityItem extends React.Component<Props> {

  public render(): JSX.Element {
    const { item, isSelected } = this.props;

    return (
      <div
        onClick={this.onClick}
        className={`popover-activity-item ${isSelected ? 'active' : ''}`}
      >
        <div className='popover-activity-item__title' title={item.name}>
          {item.name}
        </div>
      </div>
    );
  }

  @autobind
  private onClick(): void {
    if (this.props.onItemClick) {
      this.props.onItemClick(this.props.item);
    }
  }
}
