import { PiaBreakdownNode } from '../interfaces';

export const findParent = (options: PiaBreakdownNode[], id: string, parent: PiaBreakdownNode): PiaBreakdownNode => {
  for (const option of options) {
    if (option.id === id) {
      return parent;
    } else if (option.children.length) {
      const data = findParent(option.children, id, option);
      if (data) {
        return data;
      }
    }
  }

  return null;
};
