import { Icons } from '@kreo/kreo-ui-components';
import { AiBadge } from 'unit-2d-info-panel/content/ai-badge';

export enum DrawingsContextMenuActions {
  Copy = 'Copy',
  Cut = 'Cut',
  Paste = 'Paste',
  PasteInSamePlace = 'Paste in the Same Place',
  PasteWithPoint = 'Paste With a Point',
  Hide = 'Hide',
  ShowAll = 'Show All',
  Show = 'Show',
  Delete = 'Delete',
  Modify = 'Modify',
  Ungroup = 'Ungroup',
  Focus = 'Focus',
  MoveToCell = 'Move to Cell',
  Isolate = 'Isolate',
  TraceLink = 'TraceLink',
  SelectAll = 'Select All',
  FullScreen = 'Full Screen',
  ExitFullscreen = 'Exit Full Screen',
  AddComment = 'Add Comment',
  Group = 'Group',
  Duplicate = 'Duplicate',
  DynamicMoveToCell = 'Dynamic Table to Cell',
  GroupToCell = 'Group to Cell',
  MoveToGroup = 'Move to Group',
  MagicSearch = 'Search for areas',
}


export enum DrawingsContextMenuFilter {
  HasSelectedInstances,
  CanDelete,
  IsDrawingSource,
  CanPaste,
  CanInstancesHide,
  CanInstancesShow,
  HasHiddenInstances,
  CanUngroup,
  CanGroup,
  CanTraceLink,
  IsSelectOne,
  HasMeasure,
  HasVisibleInstances,
  NotFullScreen,
  FullScreen,
  CanEditMeasurement,
  CanEditReport,
  CanViewReport,
  CanComment,
  IsGroupSelected,
  IsRendererInitialized,
  CanMoveToGroup,
  IsDrawingOpened,
  CanPasteInSamePlace,
  Is2D,
  CanSearch,
  HasRendered,
  IsDrawingRendered,
}

export enum DrawingsContextMenuOptionsGetter {
  MoveToCell,
  DynamicMoveToCell,
  FolderToCell,
}

export interface DrawingsContextMenuItem {
  action: DrawingsContextMenuActions;
  filters: DrawingsContextMenuFilter[];
  multilevel?: boolean;
  optionsGetter?: DrawingsContextMenuOptionsGetter;
  hotKey?: string;
  icon?: React.ComponentType;
  badge?: React.ComponentType;
}

export const DrawingContextMenuItems: DrawingsContextMenuItem[] = [
  {
    action: DrawingsContextMenuActions.Modify,
    filters: [
      DrawingsContextMenuFilter.IsSelectOne,
      DrawingsContextMenuFilter.CanEditMeasurement,
      DrawingsContextMenuFilter.HasRendered,
    ],
    icon: Icons.Modify2D,
  },
  {
    action: DrawingsContextMenuActions.Group,
    filters: [DrawingsContextMenuFilter.CanGroup, DrawingsContextMenuFilter.CanEditMeasurement],
    icon: Icons.Group_2,
    hotKey: 'Ctrl + G',
  },
  {
    action: DrawingsContextMenuActions.Ungroup,
    filters: [DrawingsContextMenuFilter.CanUngroup, DrawingsContextMenuFilter.CanEditMeasurement],
    icon: Icons.UnGroup2D,
    hotKey: 'Ctrl+Shift+G',
  },
  {
    action: DrawingsContextMenuActions.Focus,
    filters: [DrawingsContextMenuFilter.HasSelectedInstances, DrawingsContextMenuFilter.Is2D],
    icon: Icons.Focus,
    hotKey: 'F',
  },
  {
    action: DrawingsContextMenuActions.MagicSearch,
    filters: [
      DrawingsContextMenuFilter.CanSearch,
      DrawingsContextMenuFilter.IsDrawingOpened,
      DrawingsContextMenuFilter.IsDrawingRendered,
    ],
    icon: Icons.MagicSearch2D,
    badge: AiBadge,
  },
  {
    action: DrawingsContextMenuActions.TraceLink,
    filters: [
      DrawingsContextMenuFilter.HasSelectedInstances,
      DrawingsContextMenuFilter.CanTraceLink,
      DrawingsContextMenuFilter.CanViewReport,
    ],
    icon: Icons.Tracelinks2D,
  },
  {
    action: DrawingsContextMenuActions.Copy,
    filters: [DrawingsContextMenuFilter.HasSelectedInstances, DrawingsContextMenuFilter.CanEditMeasurement],
    hotKey: 'Ctrl + C',
    icon: Icons.CopyDuplicate,
  },
  {
    action: DrawingsContextMenuActions.Duplicate,
    filters: [
      DrawingsContextMenuFilter.CanEditMeasurement,
      DrawingsContextMenuFilter.HasSelectedInstances,
    ],
    hotKey: 'Ctrl + D',
    icon: Icons.Duplicate2D,
  },
  {
    action: DrawingsContextMenuActions.Cut,
    filters: [DrawingsContextMenuFilter.HasSelectedInstances, DrawingsContextMenuFilter.CanEditMeasurement],
    hotKey: 'Ctrl + X',
    icon: Icons.Cut,
  },
  {
    action: DrawingsContextMenuActions.PasteWithPoint,
    filters: [
      DrawingsContextMenuFilter.CanPaste,
      DrawingsContextMenuFilter.IsDrawingSource,
      DrawingsContextMenuFilter.CanEditMeasurement,
    ],
    hotKey: 'Ctrl + V',
    icon: Icons.PasteWithPoint,
  },
  {
    action: DrawingsContextMenuActions.Paste,
    filters: [
      DrawingsContextMenuFilter.CanPaste,
      DrawingsContextMenuFilter.CanEditMeasurement,
      DrawingsContextMenuFilter.IsDrawingOpened,
    ],
    hotKey: 'Ctrl + Shift + V',
    icon: Icons.Paste,
  },
  {
    action: DrawingsContextMenuActions.PasteInSamePlace,
    filters: [
      DrawingsContextMenuFilter.CanPasteInSamePlace,
      DrawingsContextMenuFilter.IsDrawingSource,
      DrawingsContextMenuFilter.CanEditMeasurement,
    ],
    icon: Icons.Paste,
  },
  {
    action: DrawingsContextMenuActions.Hide,
    filters: [DrawingsContextMenuFilter.HasSelectedInstances, DrawingsContextMenuFilter.CanInstancesHide],
    hotKey: 'H',
    icon: Icons.Hide2D,
  },
  {
    action: DrawingsContextMenuActions.Show,
    filters: [DrawingsContextMenuFilter.HasSelectedInstances, DrawingsContextMenuFilter.CanInstancesShow],
    hotKey: 'H',
    icon: Icons.Show2D,
  },
  {
    action: DrawingsContextMenuActions.Isolate,
    filters: [DrawingsContextMenuFilter.HasSelectedInstances],
    hotKey: 'I',
    icon: Icons.Isolate2D,
  },
  {
    action: DrawingsContextMenuActions.Delete,
    filters: [DrawingsContextMenuFilter.CanDelete, DrawingsContextMenuFilter.CanEditMeasurement],
    icon: Icons.Delete,
    hotKey: 'Del',
  },
  {
    action: DrawingsContextMenuActions.ShowAll,
    filters: [DrawingsContextMenuFilter.HasHiddenInstances],
    hotKey: 'Ctrl + H',
    icon: Icons.Show2D,
  },
  {
    action: DrawingsContextMenuActions.GroupToCell,
    filters: [
      DrawingsContextMenuFilter.IsGroupSelected,
      DrawingsContextMenuFilter.CanEditReport,
    ],
    icon: Icons.LinkOnGroup,
    multilevel: true,
    optionsGetter: DrawingsContextMenuOptionsGetter.FolderToCell,
  },
  {
    action: DrawingsContextMenuActions.DynamicMoveToCell,
    filters: [
      DrawingsContextMenuFilter.IsGroupSelected,
      DrawingsContextMenuFilter.CanEditReport,
    ],
    icon: Icons.DynamicMoveToCell,
    multilevel: true,
    optionsGetter: DrawingsContextMenuOptionsGetter.DynamicMoveToCell,
  },
  {
    action: DrawingsContextMenuActions.MoveToCell,
    filters: [
      DrawingsContextMenuFilter.HasSelectedInstances,
      DrawingsContextMenuFilter.HasMeasure,
      DrawingsContextMenuFilter.CanEditReport,
    ],
    icon: Icons.MoveToCell,
    multilevel: true,
    optionsGetter: DrawingsContextMenuOptionsGetter.MoveToCell,
  },
  {
    action: DrawingsContextMenuActions.SelectAll,
    filters: [DrawingsContextMenuFilter.HasVisibleInstances],
    hotKey: 'Ctrl + A',
    icon: Icons.SelectAll,
  },
  {
    action: DrawingsContextMenuActions.FullScreen,
    filters: [DrawingsContextMenuFilter.NotFullScreen],
    icon: Icons.FullScreen,
  },
  {
    action: DrawingsContextMenuActions.ExitFullscreen,
    filters: [DrawingsContextMenuFilter.FullScreen],
    icon: Icons.ExitFullscreen,
  },
  {
    action: DrawingsContextMenuActions.AddComment,
    filters: [DrawingsContextMenuFilter.CanComment, DrawingsContextMenuFilter.IsDrawingOpened],
    icon: Icons.Comments2D,
  },
  {
    action: DrawingsContextMenuActions.MoveToGroup,
    filters: [DrawingsContextMenuFilter.CanMoveToGroup, DrawingsContextMenuFilter.CanEditMeasurement],
    icon: Icons.MoveToGroup_1,
  },
];
