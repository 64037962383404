import styled, { keyframes } from 'styled-components';

import { FolderContainerStyled } from 'common/components/folder-container';
import { TargetFolderStyled } from 'common/components/folder-container/target-folder';
import { PropertyStyled } from 'common/components/property';
import { DrawingsLayoutConstants } from '../../constants/drawings-layout-constants';
import {
  DrawingsStrokeStypeSwitcherStyled,
} from '../../drawings-controls/drawings-instances-styles/drawings-stroke-style-switcher';

const COLOR_WIDTH = 20;
const COLORS_GAP = 10;
const NAME_SVG_SIZE = 20;
const MARGIN = 20;
const NAME_PARAGRAPH_WIDTH = 40;
const MIN_NAME_WIDTH = 240;

const NewDrawingPanelAnimation = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: ${DrawingsLayoutConstants.NEW_DRAWING_PANEL_HEIGHT}px;
    opacity: 1;
  }
`;

const NewDrawingPanel = styled.div`
  height: ${DrawingsLayoutConstants.NEW_DRAWING_PANEL_HEIGHT}px;
  width: 100%;
  background-color: ${p => p.theme.color.backgroundRush};
  box-shadow: inset 0 -1px 0 ${p => p.theme.color.background}; // border-bottom
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 15px 20px;
  animation: ${NewDrawingPanelAnimation} ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  *, & {
    box-sizing: border-box;
  }
`;

const NewDrawingName = styled.div<{ isFullColorList?: boolean, stylesWidth: number }>`
  display: flex;
  align-items: center;
  max-width: calc(100% - ${p => p.isFullColorList ? p.stylesWidth : COLOR_WIDTH}px - ${MARGIN}px);
  height: ${NAME_SVG_SIZE}px;
  margin-right: ${MARGIN}px;
  > svg {
    height: 20px;
    min-width: 20px;
    fill: ${p => p.theme.color.gray};
    margin-right: ${MARGIN}px
  }
  > p {
    width: ${NAME_PARAGRAPH_WIDTH}px;
    margin-right: ${MARGIN}px;
  }
`;

const NewDrawingColor = styled.div<{ newDrawingColor: string }>`
  display: flex;
  gap: ${COLORS_GAP}px;
  margin-left: auto;
  button {
    svg {
      fill: ${p => p.newDrawingColor};
    }
    :hover {
      svg {
        fill: ${p => p.newDrawingColor};
      }
    }
  }
`;

const StrokeStyle = styled.div`
  display: flex;
`;

const InputContainer = styled.div`
  max-width: calc(100% - ${NAME_SVG_SIZE + NAME_PARAGRAPH_WIDTH + MARGIN * 2}px);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 10px 5px;
  border-bottom: 1px solid ${p => p.theme.color.background};
  margin-bottom: 10px;

  > div {
    min-height: 24px;
  }

  ${PropertyStyled.Setting} {
    display: flex;
    justify-content: flex-end;
  }

  ${PropertyStyled.PropertyWithSlider} {
    grid-template-columns: 1fr 30px 100px;
    grid-column-gap: 7px;
  }

  ${DrawingsStrokeStypeSwitcherStyled.Container} {
    > div {
      width: 129px;
      margin: 0;
    }
  }

  ${FolderContainerStyled.Icon} {
    transform: rotate(0deg);
  }

  ${TargetFolderStyled.Container} {
    height: 24px;
    padding: 3px 15px 3px 10px;
  }
`;

export const Styled = {
  NewDrawingPanel,
  NewDrawingName,
  NewDrawingColor,
  InputContainer,
  StrokeStyle,
  COLORS_GAP,
  COLOR_WIDTH,
  MIN_NAME_WIDTH,
  Container,
};
