import { TwoDRegexGetter } from '2d/units/2d-regex';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';

type Diffs = { diffColumn: number, diffRow: number };

export const getDiffs = (fromCell: string, toCell: string): Diffs => {
  const fromGroups = TwoDRegexGetter.getFullCellField(fromCell);
  const fromColumn = ExcelColumnHelper.excelColNameToIndex(fromGroups.columnId);
  const fromRow = Number(fromGroups.rowId);
  const toGroups = TwoDRegexGetter.getFullCellField(toCell);
  const toColumn = ExcelColumnHelper.excelColNameToIndex(toGroups.columnId);
  const toRow = Number(toGroups.rowId);

  const diffColumn = toColumn - fromColumn;
  const diffRow = toRow - fromRow;

  return { diffColumn, diffRow };
};
