import { ColDef } from 'ag-grid-community';
import autobind from 'autobind-decorator';
import { max } from 'lodash';
import { DrawingsGeometryGroup } from 'common/components/drawings';
import { extendDataByEmptyGroup, getColumn, getGroupExtender, GROUP_ID_POSTFIX } from '../extends-by-group';
import { ColumnTransaction } from './column-transaction';
import { GroupRowData, HandleMoveElementPayload, HandleMoveGroupPayload, MetaGroupInfo } from './interfaces';

const EMPTY_NESTING_VALUE = 0;

export class GroupTransaction extends ColumnTransaction {
  protected groupMap: Record<string, MetaGroupInfo[]> = {};
  protected groupColumns: Record<string, ColDef> = {};

  private groupMaxNesting: number = EMPTY_NESTING_VALUE;
  private groupNesting: Record<string, number> = {};

  protected initColumnState(): void {
    super.initColumnState();
    if (!super.hasGroupColumn(this.groupMaxNesting)) {
      this.setGroupHierarchyMap();
    }
  }

  @autobind
  protected createGroupInfo(drawingElementId: string, rowId: string): GroupRowData {
    const [groupData, groupHierarchyMap] = this.getGroupInfo(drawingElementId);
    if (groupData.parentGroupId) {
      this.applyGroupHierarchyMap(groupHierarchyMap, rowId, drawingElementId, groupData.parentGroup);
    }
    return groupData;
  }

  @autobind
  protected handleCreateGroup(groupsByParent: Record<string, DrawingsGeometryGroup[]>): void {
    for (const [parentKey, groups] of Object.entries(groupsByParent)) {
      const parentId = parentKey === 'undefined'
        ? undefined
        : parentKey;
      this.updateNesting(parentId, groups.map(g => g.id));
    }
    const isNeedUpdateColumn = this.isNeedUpdateColumn();
    if (isNeedUpdateColumn) {
      this.updateColumn();
    }
  }

  @autobind
  protected handleDeleteGroup(ids: Set<string>): void {
    ids.forEach(id => delete this.groupNesting[id]);

    const isNeedUpdateColumn = this.isNeedUpdateColumn();
    if (isNeedUpdateColumn) {
      this.updateColumn();
    }
  }

  @autobind
  protected handleMoveGroup(payload: Record<string, HandleMoveGroupPayload[]>): void {
    for (const [parentId, groups] of Object.entries(payload)) {
      const groupIds = groups.map(g => g.id);
      this.updateNesting(parentId, groupIds);
      const isNeedUpdateColumn = this.isNeedUpdateColumn();
      if (isNeedUpdateColumn) {
        this.updateColumn();
      }

      const isNeedUpdateRowData = this.isNeedUpdateRowData(groupIds);
      if (isNeedUpdateRowData) {
        const groupNestingField = this.getGroupNestingField();
        for (const groupId of groupIds) {
          const elements = this.getElementsByGroupId(groupId);
          this.updateRowData(elements, groupNestingField);
        }
      }
    }
  }

  @autobind
  protected handleMoveElement(data: HandleMoveElementPayload): void {
    const groupNestingField = this.getGroupNestingField();
    this.updateRowData(data.measurements, groupNestingField);
  }

  @autobind
  protected getElementsByGroupId(groupId: string): string[] {
    const elements = [];
    const groupMetaInfo = this.groupMap[groupId];
    if (groupMetaInfo) {
      for (const metaInfo of groupMetaInfo) {
        elements.push(metaInfo.elementId);
      }
    }
    return elements;
  }

  @autobind
  protected removeMetaInfo(drawingElementIds: Set<string>): void {
    this.filterMetaInfo(new Set(), drawingElementIds);
  }

  private getGroupNestingField(): string[] {
    const groupNestingField = [];
    for (let i = 0; i < this.groupMaxNesting; i++) {
      const column = getColumn(i);
      groupNestingField.push(column.colId);
    }
    return groupNestingField;
  }

  private applyGroupHierarchyMap(
    groupHierarchyMap: Record<string, string>,
    rowId: string,
    elementId: string,
    parentId: string,
  ): void {
    for (const [key, value] of Object.entries(groupHierarchyMap)) {
      if (key.endsWith(GROUP_ID_POSTFIX)) {
        if (value !== parentId) {
          this.setGroupMetaInfo(value, {
            groupId: value,
            fields: [key.replace(GROUP_ID_POSTFIX, '')],
            rowId,
            elementId,
          });
        } else {
          this.setGroupMetaInfo(parentId, {
            groupId: parentId,
            fields: ['parentGroup', key.replace(GROUP_ID_POSTFIX, '')],
            rowId,
            elementId,
          });
        }
        delete groupHierarchyMap[key];
      }
    }
  }

  private getGroupInfo(drawingElementId: string): [GroupRowData, Record<string, string>] {
    const { groupsMap, aiAnnotation, groups } = this.getDrawingInfo();

    const measureValue = aiAnnotation.geometry[drawingElementId];
    const [extender] = getGroupExtender(groupsMap);
    const parentGroup = this.getGroupName(measureValue.groupId, groups);
    const groupHierarchyMap: Record<string, string> = {};
    extender(groupHierarchyMap, measureValue.groupId);

    if (this.isPivot && this.isPivot()) {
      extendDataByEmptyGroup({ [drawingElementId]: groupHierarchyMap }, this.groupColumns);
    }

    const groupData = {
      parentGroupId: measureValue.groupId,
      parentGroup,
      ...groupHierarchyMap,
    };

    return [groupData, groupHierarchyMap];
  }

  private updateRowData(elementIds: string[], groupNestingField: string[], skipFilterEmpty?: boolean): Set<string> {
    const updatedGroupIds = new Set<string>();
    const columnKey = new Set<string>();

    for (const elementId of elementIds) {
      const rowsIds = this.getRowsIdsByDrawingElementId(elementId);
      if (rowsIds === null) {
        continue;
      }
      const [groupRowData, groupHierarchyMap] = this.getGroupInfo(elementId);
      for (const rowId of rowsIds) {
        const source = this.sourceData[rowId];
        this.deleteGroupField(source, groupNestingField);
        this.setNewGroupRowData(groupRowData, source);
        if (groupRowData.parentGroupId) {
          this.updateMetaInfo(groupRowData, groupHierarchyMap, rowId, elementId, updatedGroupIds);
        }
      }
    }
    if (!skipFilterEmpty) {
      this.filterMetaInfo(updatedGroupIds, new Set(elementIds));
    }
    return columnKey;
  }

  private updateMetaInfo(
    groupRowData: GroupRowData,
    groupHierarchyMap: Record<string, string>,
    rowId: string,
    elementId: string,
    updatedGroupIds: Set<string>,
  ): void {
    for (const [key, value] of Object.entries(groupHierarchyMap)) {
      if (key.endsWith(GROUP_ID_POSTFIX)) {
        delete groupHierarchyMap[key];
        const isParent = value === groupRowData.parentGroupId;
        const groupMetaInfo = this.groupMap[value];
        const field = key.replace(GROUP_ID_POSTFIX, '');
        const newMetaInfo = {
          fields: isParent ? ['parentGroup', field] : [field],
          groupId: value,
          elementId,
          rowId,
        };
        updatedGroupIds.add(value);
        if (!groupMetaInfo) {
          this.setGroupMetaInfo(value, newMetaInfo);
          continue;
        }
        const metaInfo = groupMetaInfo.find(g => g.elementId === elementId);
        if (metaInfo) {
          metaInfo.fields = isParent ? ['parentGroup', field] : [field];
        } else {
          groupMetaInfo.push(newMetaInfo);
        }
      }
    }

  }

  private filterMetaInfo(updatedGroupIds: Set<string>, relatedElementIds: Set<string>): void {
    for (const groupId of Object.keys(this.groupMap)) {
      if (!updatedGroupIds.has(groupId)) {
        const groupMetaInfo = this.groupMap[groupId];
        this.groupMap[groupId] = groupMetaInfo.filter(g => !relatedElementIds.has(g.elementId));
        if (!this.groupMap[groupId].length) {
          delete this.groupMap[groupId];
        }
      }
    }
  }

  private setNewGroupRowData(groupRowData: GroupRowData, source: GroupRowData): void {
    for (const [key, value] of Object.entries(groupRowData)) {
      source[key] = value;
      this.update.push(source);
    }
  }

  private deleteGroupField(source: GroupRowData, groupNestingField: string[]): void {
    delete source.parentGroupId;
    delete source.parentGroup;
    for (const field of groupNestingField) {
      delete source[field];
    }
  }

  private setGroupMetaInfo(rowId: string, metaInfo: MetaGroupInfo): void {
    if (this.groupMap[rowId]) {
      this.groupMap[rowId].push(metaInfo);
    } else {
      this.groupMap[rowId] = [metaInfo];
    }
  }

  private updateColumn(): void {
    const newMaxNesting = this.getNewMaxNesting();
    if (this.groupMaxNesting === EMPTY_NESTING_VALUE) {
      for (let i = 0; i < newMaxNesting; i++) {
        const column = getColumn(i);
        this.groupColumns[column.colId] = column;
        this.handleNewField(column.colId);
      }
    } else {
      const diff = newMaxNesting - this.groupMaxNesting;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          const column = getColumn(this.groupMaxNesting + i);
          this.groupColumns[column.colId] = column;
          this.handleNewField(column.colId);
        }
      } else {
        for (let i = diff; i < 0; i++) {
          const column = getColumn(this.groupMaxNesting + i);
          this.deleteField(column.colId);
          delete this.groupColumns[column.colId];
        }
      }
    }

    this.groupMaxNesting = newMaxNesting;
  }

  private getGroupName(groupId: string, groups: DrawingsGeometryGroup[]): string {
    const group = groups.find((g) => g.id === groupId);
    if (!group) {
      return undefined;
    }

    return group.name;
  }

  private isNeedUpdateColumn(): boolean {
    const newMaxNesting = this.getNewMaxNesting();

    if (newMaxNesting !== this.groupMaxNesting) {
      return true;
    }
    return false;
  }

  private getNewMaxNesting(): number {
    const nesting = max(Object.values(this.groupNesting));
    return nesting === undefined
      ? 0
      : nesting;
  }

  private updateNesting(parentGroupId: string, groups: string[]): void {
    const startNesting = parentGroupId && parentGroupId !== 'undefined'
      ? this.getNesting(parentGroupId)
      : 0;
    for (const groupId of groups) {
      this.setNesting(startNesting + 1, groupId);
    }
  }

  private setNesting(nesting: number, id: string): void {
    this.groupNesting[id] = nesting;
    const childList = this.getChildList(id);
    for (const child of childList) {
      this.setNesting(nesting + 1, child);
    }
  }

  private getChildList(parentId: string): string[] {
    const { groupsMap } = this.getDrawingInfo();
    const result = [];
    Object.values(groupsMap).forEach(group => {
      if (group.parentId === parentId) {
        result.push(group.id);
      }
    });
    return result;
  }

  private isNeedUpdateRowData(groupIds: string[]): boolean {
    return groupIds.some(id => this.groupMap[id]);
  }

  private setGroupHierarchyMap(): void {
    const { groupsMap } = this.getDrawingInfo();
    for (const groupKey of Object.keys(groupsMap)) {
      // extender(groupHierarchyMap, groupKey);
      const nesting = this.getNesting(groupKey);
      this.groupNesting[groupKey] = nesting;
      if (this.groupMaxNesting < nesting) {
        this.groupMaxNesting = nesting;
      }
    }
    const groupNestingField = this.getGroupNestingField();

    const elements = Object.keys(this.drawingElementRows);
    this.updateRowData(elements, groupNestingField, true);

    groupNestingField.forEach(c => {
      const column = this.handleNewField(c);
      this.groupColumns[c] = column;
    });
  }

  @autobind
  private getNesting(groupId: string): number {
    if (this.groupNesting[groupId]) {
      return this.groupNesting[groupId];
    }
    const { groupsMap } = this.getDrawingInfo();
    const group = groupsMap[groupId];
    if (!group) {
      return 1;
    }

    if (group.parentId) {
      const calcNesting = this.groupNesting[group.parentId];
      if (calcNesting) {
        return calcNesting + 1;
      }

      return this.getNesting(group.parentId) + 1;
    }

    return 1;
  }
}
