import autobind from 'autobind-decorator';
import * as paper from 'paper';
import { DrawingsCanvasConstants } from 'common/components/drawings/constants';
import { DrawingsRenderParams, DrawingsBounds } from 'common/components/drawings/interfaces';
import { BaseSelectionAreaHelper, BaseSelectionAreaHelperConfig } from './base-selection-area-helper';

interface DrawingsMarqueeZoomAreaHelperConfig extends BaseSelectionAreaHelperConfig{
  onAreaDrawn: (bounds: DrawingsBounds) => void;
}

const BOUNDS_THRESHOLD = 2;

export class DrawingsMarqueeZoomAreaHelper extends BaseSelectionAreaHelper<DrawingsMarqueeZoomAreaHelperConfig> {
  public finishDrag(): void {
    const { width, height } = this._selectionArea.bounds;
    if (Math.max(width, height) > BOUNDS_THRESHOLD) {
      this._config.onAreaDrawn(this._selectionArea.bounds);
    }
  }

  @autobind
  protected renderArea(point: paper.Point): void {
    super.renderArea(point);
    const zoom = this._config.geometryRenderParamsObserver.getContext().zoom;
    this.applyZoomToParams(zoom);
  }

  @autobind
  protected updateRenderParams({ zoom }: DrawingsRenderParams): void {
    if (this._selectionArea) {
      this.applyZoomToParams(zoom);
    }
  }

  private applyZoomToParams(zoom: number): void {
    const strokeWidth = DrawingsCanvasConstants.lineStroke / zoom;
    this._selectionArea.strokeWidth = strokeWidth;
    this._selectionArea.dashArray = [DrawingsCanvasConstants.dashArray * strokeWidth];
  }
}
