import { BillingPeriodUnit } from '../enums/billing-period-unit';
import { SubscriptionAddon } from '../interfaces/subscription-addon';
import { SubscriptionPlanListingModel } from '../interfaces/subscription-plan-listing-model';
import { SubscriptionPlanVariantWithName } from '../interfaces/subscription-plan-variant-with-name';

function getFilteredPlanGroups(
  plansModel: SubscriptionPlanListingModel,
  billingPeriod: BillingPeriodUnit,
): SubscriptionPlanVariantWithName[] {
  const result = [];
  for (const planGroup of plansModel.plans) {
    const variant = planGroup.variants.find(x => x.billingPeriodUnit === billingPeriod);
    if (variant) {
      result.push({
        ...variant,
        name: planGroup.name,
        isFree: planGroup.isFree,
      });
    }
  }

  return result;
}

function getSelectedPlanVariant(
  plansModel: SubscriptionPlanListingModel,
  billingPeriod: BillingPeriodUnit,
  selectedPlanGroup: string,
): SubscriptionPlanVariantWithName {
  return getFilteredPlanGroups(plansModel, billingPeriod)
    .find(x => x.name.toLowerCase() === selectedPlanGroup.toLocaleLowerCase());
}

function getFilteredAddons(
  plansModel: SubscriptionPlanListingModel,
  billingPeriod: BillingPeriodUnit,
): SubscriptionAddon[] {
  const addons = plansModel.addons;
  return addons.filter(x => x.billingPeriodUnit === billingPeriod);
}

function getSelectedAddons(
  plansModel: SubscriptionPlanListingModel,
  billingPeriod: BillingPeriodUnit,
  selectedAddonGroups: string[],
): SubscriptionAddon[] {
  const selectedAddonGroupsMap = new Set(selectedAddonGroups);
  return getFilteredAddons(plansModel, billingPeriod)
    .filter(x => selectedAddonGroupsMap.has(x.groupName));
}

function filterAddonsGroups(
  addonGroups: string[],
  plansModel: SubscriptionPlanListingModel,
  planVariant: SubscriptionPlanVariantWithName,
): string[] {
  const availableGroupAddons = new Set();
  planVariant.availableAddons.forEach(id => {
    const addon = plansModel.addons.find(a => a.id === id);
    if (addon) {
      availableGroupAddons.add(addon.groupName);
    }
  });
  return addonGroups.filter(groupName => availableGroupAddons.has(groupName));
}

export const SubscriptionListingUtils = {
  getFilteredPlanGroups,
  getSelectedPlanVariant,
  getFilteredAddons,
  getSelectedAddons,
  filterAddonsGroups,
};
