import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { BillingAddressStyled } from '../../payment-method-manager/billing-address';


const FillStepScaleContainer = styled.div`
`;

const ContentContainer = styled.div<{ headerMargin: number }>`
  margin-left: 10px;
  
  > ${Text} {
    margin-bottom: ${p => p.headerMargin}px;
    margin-top: 16px;
  }

  ${BillingAddressStyled.VatCountryLabel} {
    margin-left: 20px;
  }

  ${BillingAddressStyled.Column} {
    &:first-child {
      width: 280px;
    }
  }
`;

const PaymentManagerWrapper = styled.div<{ isOldUI: boolean }>`
  display: flex;
  align-items: ${p => !p.isOldUI ? 'center' : 'flex-start'};
  flex-direction: ${p => !p.isOldUI ? 'row' : 'column'};
  justify-content: ${p => p.isOldUI && 'flex-start'};
  margin-bottom: 20px;
`;

const PaymentManagerInfo = styled.div<{ isOldUI: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${p => !p.isOldUI ? 0 : 10}px 0 0 ${p => !p.isOldUI ? 19 : 0}px;
  width: ${p => !p.isOldUI ? 'auto' : '100%'};
`;

const Info = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  > svg {
    width: 10px;
    height: 10px;
    fill: ${p => p.theme.color.turquoise};
  }
`;

export const Styled = {
  FillStepScaleContainer,
  ContentContainer,
  PaymentManagerWrapper,
  PaymentManagerInfo,
  Info,
  IconContainer,
};
