import { arrayUtils } from 'common/utils/array-utils';

export class CollectionsUnifier<T> {
  private _collections: Record<string, any> = {};

  public add(valueToAdd: T): void {
    for (const [key, value] of Object.entries(valueToAdd)) {
      this._collections[key] = this._collections[key] || [];
      this._collections[key].push(value);
    }
  }

  public getUnifiedOrDefault(defaultValues: T): T {
    const result: Partial<T> = {};
    for (const [key, value] of Object.entries(this._collections)) {
      result[key] = arrayUtils.uniq(value).length === 1 ? value[0] : defaultValues[key];
    }
    return result as T;
  }
}
