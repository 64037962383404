import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import './indirect-cost-page.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State as ReduxState } from 'common/interfaces/state';
import { Stepper } from '../../../../actions';
import { ScenarioStatus } from '../../../../constants';
import { ScenarioRouteParams } from '../../../../routes/app-routes-params';
import { Master } from '../../../scenarios/components/master';
import { ScenarioEditPage, ScenariosData } from '../../../scenarios/interfaces';
import { IndirectCostList } from '../../components/indirect-cost-list';

interface ReduxProps {
  scenario: ScenariosData.Scenario;
}

interface ReduxActions {
  setStep: (step: number) => void;
}

interface Props
  extends ReduxProps,
    ReduxActions,
    AbilityAwareProps,
    RouteComponentProps<ScenarioRouteParams> {}

class IndirectCostPageComponent extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);

    const isActivitiesGroupingAvailable = this.props.ability.can(Operation.Read, Subject.SequenceStep);
    this.props.setStep(isActivitiesGroupingAvailable ? ScenarioEditPage.Contractor : ScenarioEditPage.Contractor - 1);
  }

  public render(): JSX.Element {
    const { scenario, match } = this.props;
    const isWaited = this.props.scenario && this.props.scenario.status === ScenarioStatus.Calculating;
    return (
      <Master
        scenario={scenario}
        projectId={match.params.projectId}
        wait={isWaited}
      >
        <div className='indirect-cost-page'>
          <div className='indirect-cost-page__title'>Main Contractor's Cost</div>
          <div className='indirect-cost-page__content'>
            <IndirectCostList
              calculation={parseInt(match.params.scenarioId, 10)}
            />
          </div>
        </div>
      </Master>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    scenario: state.scenarios.editScenario,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    setStep: (step: number): void => {
      dispatch(Stepper.setStep(step));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const IndirectCostPage = withAbilityContext(connector(IndirectCostPageComponent));
