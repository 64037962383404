import { ICellEditorParams } from 'ag-grid-community';

import { TreeTableValueGetterParams } from 'common/components/tree-table/interfaces';
import { ValueHelper } from 'common/utils/value-helper';
import { QtoTreeRowProperty } from '../../../interfaces/quantity-take-off';
import { CellEditor } from '../../quantity-take-off-tree-table-cell-edit/cell-editor';

export interface QtoReportPivotTableCellEditParams extends ICellEditorParams {
  propertyGetter: (params: TreeTableValueGetterParams) => QtoTreeRowProperty;
}

export abstract class PivotCellEditor<TElement extends HTMLElement> extends CellEditor<QtoTreeRowProperty, TElement> {
  public static className: string = 'quantity-take-off-report-pivot-table-cell-edit__value';
  protected params: QtoReportPivotTableCellEditParams;

  public revertValue(): void {
    const property = this.propertyGetter(this.params.column.getId());
    const value = ValueHelper.isNumberValue(property && property.default)
      ? this.getConvertedValue(property.default)
      : property && property.default || null;
    this.setEValue(value);
    this.setFocus();
  }

  protected propertyGetter(columnId: string): QtoTreeRowProperty {
    return this.params.propertyGetter({
      api: this.params.api,
      columnApi: this.params.columnApi,
      node: this.params.node,
      columnId,
      context: this.params.context,
    });
  }

  protected initEValue(eGui: HTMLDivElement): void {
    this.eValue = eGui.querySelector(`.${PivotCellEditor.className}`);
  }
}
