import { StringDictionary } from 'common/interfaces/dictionary';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { EngineActionTypes } from '../actions';
import { ActivityGroupsReduxState } from '../interfaces';

export const engineActivityGroupingStepReducerMethods: ReducerMethods<ActivityGroupsReduxState> = {
  [EngineActionTypes.SET_COLORED_BIM_ELEMENTS]: (state, payload: StringDictionary<number[]>) => {
    return new MonoliteHelper(state)
      .set((_) => _.engine.colorToBimIdsMap, { ...state.engine.colorToBimIdsMap, ...payload })
      .get();
  },
  [EngineActionTypes.RESET_COLORED_BIM_ELEMENTS]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.engine.colorToBimIdsMap, {})
      .get();
  },
  [EngineActionTypes.SET_SELECTED_BIM_ELEMENTS]: (state, payload: number[]) => {
    return new MonoliteHelper(state)
      .set((_) => _.engine.selectedIds, payload)
      .get();
  },
  [EngineActionTypes.SET_HIGHLIGHTED_BIM_ELEMENTS]: (state, payload: number[]) => {
    return new MonoliteHelper(state)
      .set((_) => _.engine.highlightedIds, payload)
      .get();
  },
  [EngineActionTypes.SET_AVAILABLE_BIM_ELEMENTS]: (state, payload: number[]) => {
    return new MonoliteHelper(state)
      .set((_) => _.engine.availableBimHandleIds, payload)
      .get();
  },
  [EngineActionTypes.SET_DEFAULT_AVAILABLE_BIM_ELEMENTS]: (state, payload: number[]) => {
    return new MonoliteHelper(state)
      .set((_) => _.engine.defaultEngineAvailableBimElementIds, payload)
      .get();
  },
  [EngineActionTypes.RESET_ENGINE]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.engine.colorToBimIdsMap, {})
      .set((_) => _.engine.availableBimHandleIds, null)
      .set((_) => _.engine.defaultEngineAvailableBimElementIds, null)
      .set((_) => _.engine.highlightedIds, [])
      .set((_) => _.engine.selectedIds, [])
      .get();
  },
};
