import { ModalWrapper } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { DemoProject } from '../../../pages/demo-projects/interfaces/demo-project';
import { UpdateDemoProjectForm } from './update-demo-project-form';

export const UPDATE_DEMO_PROJECT_DIALOG_NAME = 'UPDATE_DEMO_PROJECT_DIALOG_NAME';

interface StateProps {
  project: DemoProject;
}

interface DispatchProps {
  closeDialog: () => void;
}

interface Props extends StateProps, DispatchProps {
  onSubmit: () => void;
}

class UpdateDemoProjectDialogComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { project } = this.props;
    if (!project) {
      return null;
    }

    const initialValues = {
      name: project.name,
      orderIndex: project.orderIndex,
      id: project.id,
      type: project.type,
      tags: project.tags.join(','),
      description: project.description,
      isImperial: project.isImperial,
    };

    return (
      <DialogWrapper name={UPDATE_DEMO_PROJECT_DIALOG_NAME}>
        <ModalWrapper onExit={this.props.closeDialog}>
          <UpdateDemoProjectForm
            initialValues={initialValues}
            onSubmit={this.onSubmit}
          />
        </ModalWrapper>
      </DialogWrapper>
    );
  }

  @autobind
  private onSubmit(): void {
    this.props.onSubmit();
    this.props.closeDialog();
  }
}

function mapStateToProps(state: State): StateProps {
  const dialogData = state.dialog[UPDATE_DEMO_PROJECT_DIALOG_NAME];
  const projectId = dialogData && dialogData.id;
  const project = state.admin.demoProjects.demoProjects.find(x => x.id === projectId);

  return { project };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(UPDATE_DEMO_PROJECT_DIALOG_NAME)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const UpdateDemoProjectDialog = connector(UpdateDemoProjectDialogComponent);
