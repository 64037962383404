import autobind from 'autobind-decorator';
import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsInstanceType } from 'common/components/drawings/enums/drawings-instance-type';
import { ShortPointDescription } from 'common/components/drawings/interfaces/drawing-ai-annotation';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { DrawingsPolygonConverterResult } from 'common/components/drawings/interfaces/drawings-converter-result';
import {
  DrawingsAllowedPathType,
  DrawingsGeometryStrokedType,
} from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingsGeometryInstance } from 'common/components/drawings/interfaces/drawings-geometry-instance';
import { DrawingAnnotationNamingUtils } from 'common/components/drawings/utils/drawing-annotation-naming-utils';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { UuidUtil } from 'common/utils/uuid-utils';
import { ContextObserverWithPrevious } from '../../../drawings-contexts';
import { GetCurrentPageSizeInfoCallback } from '../../interfaces';


interface BaseOffsetEntitySettings {
  basePath: DrawingsAllowedPathType;
  layer: paper.Layer;
  color: DrawingsPaperColorInfo;
  instance: DrawingsGeometryInstance<DrawingsGeometryStrokedType>;
  stroke: boolean;
  strokeWidth: number;
  height: number;
  thickness?: number;
  strokeStyle: DrawingStrokeStyles;
  observableRenderContext: ContextObserverWithPrevious<DrawingsRenderParams>;
  getCurrentPageInfo: GetCurrentPageSizeInfoCallback;
}

export interface BaseOffsetEntity {
  findEdgeOfPoint(pointInPath: paper.Point): [paper.Point, paper.Point];
  updateOffsetRenderParameters(renderState: DrawingsRenderParams): void;
  removeOffsetPath(): void;
  getOffsetPath(): DrawingsAllowedPathType | DrawingsAllowedPathType[];
  convertToGeometry(): DrawingsPolygonConverterResult;
}

export class BaseOffsetEntity implements BaseOffsetEntity {
  protected _config: BaseOffsetEntitySettings;
  protected _offsetPath: DrawingsAllowedPathType | DrawingsAllowedPathType[];
  protected _offset: number;

  private _isError: boolean;
  private _removeErrorExecutor: DeferredExecutor = new DeferredExecutor(3000);

  constructor(config: BaseOffsetEntitySettings) {
    this._config = config;
  }

  public get instance(): DrawingsGeometryInstance<DrawingsGeometryStrokedType> {
    return this._config.instance;
  }

  public destroy(): void {
    this.removeOffsetPath();
  }

  public updateOffset(_value: number, _stroke: boolean): boolean {
    if (this._isError) {
      this.removeErrorColors();
    }
    return true;
  }

  public getGeometry(): { instances: DrawingsGeometryInstance[], points: Record<string, ShortPointDescription> } {
    let addPoints = {};
    const instances = [];
    const convertedResult = this.convertToGeometry();
    if (convertedResult) {
      const { geometriesIterator, newPoints } = convertedResult;
      for (const [isValid, geometry] of geometriesIterator) {
        if (!isValid) {
          this.showErrorColors();
          return {
            instances: [],
            points: {},
          };
        }
        const id = UuidUtil.generateUuid();
        instances.push({
          id,
          drawingId: this._config.instance.drawingId,
          type: DrawingsInstanceType.Polygon,
          name: DrawingAnnotationNamingUtils.getOffsetGeometryName(this._config.instance.name),
          geometry,
          groupId: this._config.instance.groupId,
        });
      }
      addPoints = { ...addPoints, ...newPoints };
    }
    return {
      instances,
      points: addPoints,
    };
  }

  protected renderPath(path: DrawingsAllowedPathType): void {
    path.strokeColor = this._config.color.stroke;
    path.fillColor = this._config.color.fill;
    const { zoom } = this._config.observableRenderContext.getContext();
    path.strokeWidth = this._config.strokeWidth / zoom;
    path.dashArray = DrawingsCanvasUtils.scaleStroke(this._config, zoom);
    path.addTo(this._config.layer);
    DrawingsPaperUtils.updatePathStyles(path);
  }

  protected validateOffsetPath(path: DrawingsAllowedPathType): boolean {
    const bounds = path.bounds;
    const { width, height } = this._config.getCurrentPageInfo();
    return bounds.bottom < height && bounds.top > 0 && bounds.right < width && bounds.x > 0;
  }

  @autobind
  private showErrorColors(): void {
    this.applyColors(DrawingsCanvasColors.warningStrokeColor, DrawingsCanvasColors.warningFillColor);
    this._removeErrorExecutor.execute(this.removeErrorColors);
    this._isError = true;
  }

  @autobind
  private removeErrorColors(): void {
    this.applyColors(this._config.color.stroke, this._config.color.fill);
    this._removeErrorExecutor.reset();
    this._isError = false;
  }

  private applyColors(stroke: paper.Color, fill: paper.Color): void {
    if (!this._offsetPath) {
      return;
    }
    if (Array.isArray(this._offsetPath)) {
      for (const path of this._offsetPath) {
        path.strokeColor = stroke;
        path.fillColor = fill;
      }
    } else {
      this._offsetPath.strokeColor = stroke;
      this._offsetPath.fillColor = fill;
    }
  }
}
