import * as React from 'react';

import { Styled } from './styled';

interface Props {
  size: number;
  completed: number;
}

export const ProgressBar: React.FC<Props> = ({ size, completed }) => {
  return <Styled.ProgressBar size={size} completed={completed}/>;
};
