import { Icons, RoundButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import { RenderIf } from 'common/components/render-if';
import { ToggleButton } from 'common/components/toggle-button';
import { ElementTooltip } from 'common/components/tooltip';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DrawingsInstanceMeasure } from '../../interfaces';
import { DrawingsAnnotationLegendUserInfo } from '../drawings-annotation-legend-user-info';
import { EntityLabel } from '../entity-label';
import { LegendItemAPI } from '../interfaces';
import { ItemMeasures } from './item-measures';
import { Styled } from './styled';
import { ItemTitle } from './title';

interface Props {
  id: string;
  color: string;
  isImperial: boolean;
  name: string;
  onNameChange: (name: string) => void;
  onDoubleClick: () => void;
  onVisibleToggle: () => void;
  measure: DrawingsInstanceMeasure;
  canEditMeasurement: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onContextMenu: (e: React.MouseEvent<HTMLDivElement>) => void;
  hasInheritedPia: boolean;
  hasAssignedPia: boolean;
  nestingCount: number;
  isSelected: boolean;
  isHidden: boolean;
  ignoreAvatar: boolean;
  minWidth: number;
  sendUpdateApi: (api: LegendItemAPI, id: string) => void;
  onOpenInstanceMenu: (e: React.MouseEvent) => void;
  editor?: string;
  creator?: string;
  createdAt?: string;
  editedAt?: string;
}

interface ComponentState {
  hideInfo: boolean;
  hideIconsInfo: boolean;
  hideAvatar: boolean;
  hideVisibleToggle: boolean;
  hideEntityLabel: boolean;
  hideTitle: boolean;
  hideCreateLikeThis: boolean;
  hideIconContainer: boolean;
}

class DrawingsAnnoationBaseItem extends React.PureComponent<Props, ComponentState> {
  private updateContainerWidthExecutor = new DeferredExecutor(100);
  private containerAnnotationRef: HTMLDivElement;
  private containerSeparatorRef: HTMLDivElement;
  private containerTitleRef: HTMLDivElement;
  private nameWidth: number;
  private containerAnnotationWidth: number;
  constructor(props: Props) {
    super(props);
    this.state = {
      hideInfo: false,
      hideIconsInfo: false,
      hideVisibleToggle: false,
      hideAvatar: false,
      hideEntityLabel: false,
      hideTitle: false,
      hideIconContainer: false,
      hideCreateLikeThis: false,
    };
  }

  public render(): React.ReactNode {
    const {
      name,
      isSelected,
      isHidden,
      nestingCount,
      canEditMeasurement,
      isImperial,
      hasAssignedPia,
      hasInheritedPia,
      onClick,
      onContextMenu,
      onNameChange,
      onDoubleClick,
      onVisibleToggle,
      color,
      createdAt,
      editedAt,
      creator,
      editor,
      ignoreAvatar,
    } = this.props;
    return (
      <Styled.Container
        ref={this.getContainerAnnotationRef}
        onClick={onClick}
        onContextMenu={onContextMenu}
        isSelected={isSelected}
        nestingCount={nestingCount}
      >
        <ItemTitle
          markColor={color}
          hideIconContainer={this.state.hideIconContainer}
          hideTitle={this.state.hideTitle}
          name={name}
          canEditMeasurement={canEditMeasurement}
          onNameChange={onNameChange}
          getContainerTitleRef={this.getContainerTitleRef}
          hideIconsInfo={this.state.hideIconsInfo}
          hideInfo={this.state.hideInfo}
          isImperial={isImperial}
          measure={this.props.measure}
        />
        <Styled.SeparatorResizeContainer ref={this.getContainerSeparatorRef} />
        <Styled.ItemsContainer
          hideInfo={this.state.hideInfo}
          hideVisibleToggle={this.state.hideVisibleToggle}
          hideAvatar={this.state.hideAvatar}
          hideEntityLabel={this.state.hideEntityLabel}
          hideCreateLikeThis={this.state.hideCreateLikeThis}
        >
          <ItemMeasures
            measure={this.props.measure}
            showTooltip={true}
            hideIconsInfo={this.state.hideIconsInfo}
            hideInfo={this.state.hideInfo}
            isImperial={this.props.isImperial}
          />
          <Styled.CreateSameButton>
            <ElementTooltip
              text={'Create with the same properties'}
              position={'top'}
              speed={'xl'}
              wordBreakAll={true}
            >
              <RoundButton
                Icon={Icons.PlusSmall}
                diameter={20}
                onClick={this.props.onOpenInstanceMenu}
                mood={canEditMeasurement ? 'secondary' : 'disabled'}
              />
            </ElementTooltip>
          </Styled.CreateSameButton>
          <ToggleButton
            activeIcon={Icons.Hide2D}
            icon={Icons.Show2D}
            tooltip='Hide'
            activeTooltip='Show'
            tooltipId={name}
            active={isHidden}
            onDoubleClick={onDoubleClick}
            onToggle={onVisibleToggle}
          />
          <RenderIf condition={!ignoreAvatar}>
            <DrawingsAnnotationLegendUserInfo
              creatorId={creator}
              editorId={editor}
              createdAt={createdAt}
              editedAt={editedAt}
            />
          </RenderIf>
          <EntityLabel
            selected={isSelected}
            inherited={hasInheritedPia}
            assigned={hasAssignedPia}
          />
        </Styled.ItemsContainer>
      </Styled.Container>
    );
  }


  public componentDidMount(): void {
    this.updateItemContainerWidth();
    this.props.sendUpdateApi(
      {
        updateRenderParams: this.updateStyleWrapper,
      },
      this.props.id,
    );
  }

  public componentWillUnmount(): void {
    this.updateContainerWidthExecutor.reset();
    this.props.sendUpdateApi(null, this.props.id);
  }

  public componentDidUpdate(): void {
    this.updateStyleWrapper();
  }

  @autobind
  private updateStyleWrapper(): void {
    this.updateContainerWidthExecutor.execute(this.updateItemContainerWidth);
  }

  @autobind
  private updateItemContainerWidth(): void {
    const {
      hideInfo,
      hideIconsInfo,
      hideAvatar,
      hideEntityLabel,
      hideVisibleToggle,
      hideIconContainer,
      hideTitle,
      hideCreateLikeThis,
    } = this.state;
    const { minWidth } = this.props;

    if (this.containerAnnotationRef) {
      const minItemWidth = 31;
      const minTitleWidth = 60;
      const maxTitleWidth = 100;
      const maxSeparatorWidth = 50;
      const itemWidth = this.containerAnnotationRef.offsetWidth;
      const minRatioSeparator = itemWidth > maxTitleWidth ? 10 : 25;
      const separatorWidth = this.containerSeparatorRef.offsetWidth;
      const titleWidth = this.containerTitleRef.offsetWidth;
      const ratioSeparatorContainer = separatorWidth * 100 / itemWidth;

      const hideItem = separatorWidth === 0 && titleWidth < minTitleWidth;
      const showItem = separatorWidth > maxSeparatorWidth || titleWidth > maxTitleWidth;

      if (this.containerAnnotationWidth === itemWidth) {
        this.nameWidth = titleWidth;
      }

      if (this.containerAnnotationWidth === undefined) {
        this.containerAnnotationWidth = itemWidth;
      }

      if (minWidth !== 0) {
        if (separatorWidth === 0) {
          this.containerAnnotationWidth = itemWidth;
          this.setState({ hideInfo: true });
        } else if (ratioSeparatorContainer > minRatioSeparator
          && this.containerAnnotationWidth < itemWidth
          || this.containerAnnotationWidth === itemWidth
          && titleWidth < this.nameWidth
        ) {
          this.setState({ hideInfo: false });
        }

        if (hideInfo && hideItem) {
          this.setState({ hideIconsInfo: true });
        }
        if (hideIconsInfo && showItem) {
          this.setState({ hideIconsInfo: false });
        }

        if (this.props.ignoreAvatar) {
          if (hideIconsInfo && hideItem) {
            this.setState({ hideEntityLabel: true });
          }
          if (hideIconsInfo && showItem) {
            this.setState({ hideEntityLabel: false });
          }
        } else {
          if (hideIconsInfo && hideItem) {
            this.setState({ hideAvatar: true });
          }
          if (hideIconsInfo && showItem) {
            this.setState({ hideAvatar: false });
          }

          if (hideAvatar && hideItem) {
            this.setState({ hideEntityLabel: true });
          }
          if (hideAvatar && showItem) {
            this.setState({ hideEntityLabel: false });
          }
        }

        if (hideEntityLabel && hideItem) {
          this.setState({ hideCreateLikeThis: true });
        }
        if (hideEntityLabel && showItem) {
          this.setState({ hideCreateLikeThis: false });
        }

        if (hideCreateLikeThis && hideItem) {
          this.setState({ hideVisibleToggle: true });
        }
        if (hideCreateLikeThis && showItem) {
          this.setState({ hideVisibleToggle: false });
        }

        if (hideVisibleToggle && titleWidth < 8 && titleWidth !== 0) {
          this.setState({ hideTitle: true });
        }
        if (separatorWidth > minRatioSeparator && !hideIconContainer) {
          this.setState({ hideTitle: false });
        }

        if (hideTitle && itemWidth < minItemWidth) {
          this.setState({ hideIconContainer: true });
        }
        if (hideTitle && separatorWidth > minItemWidth) {
          this.setState({ hideIconContainer: false });
        }
      }
    }
  }

  @autobind
  private getContainerAnnotationRef(ref: HTMLDivElement): void {
    this.containerAnnotationRef = ref;
  }

  @autobind
  private getContainerTitleRef(ref: HTMLDivElement): void {
    this.containerTitleRef = ref;
  }

  @autobind
  private getContainerSeparatorRef(ref: HTMLDivElement): void {
    this.containerSeparatorRef = ref;
  }
}

export { DrawingsAnnoationBaseItem };
