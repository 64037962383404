import React from 'react';
import { DatabaseEntityModifyPanel } from '../database-entity-modify-panel';
import { CreateDatabaseResourcePlant } from './create-database-resource-plant';
import { CreateDatabaseResourcePlantVariant } from './create-database-resource-plant-variant';
import { EditDatabaseResourcePlant } from './edit-database-resource-plant';
import { EditDatabaseResourcePlantVariant } from './edit-database-resource-plant-variant';


export class DatabaseResourcePlantModifyPanel extends React.Component {
  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyPanel
        createRootComponent={CreateDatabaseResourcePlant}
        createVariantComponent={CreateDatabaseResourcePlantVariant}
        editRootComponent={EditDatabaseResourcePlant}
        editVariantComponent={EditDatabaseResourcePlantVariant}
      />
    );
  }
}
