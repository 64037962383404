import * as React from 'react';

import './revit-tree-error-amount.scss';

interface Props {
  all: number;
  error: number;
}

export const RevitTreeErrorAmount: React.FC<Props> = ({ all, error }) => {
  if (all === null || error === null) {
    return <React.Fragment />;
  } else {
    if (!error) {
      return <span className='revit-tree-error-amount'>{all}</span>;
    }
    return (
      <div className='revit-tree-error-amount'>
        <span className='revit-tree-error-amount__errors'>{error}</span>
        /
        <span className='revit-tree-error-amount__all'>{all}</span>
      </div>
    );
  }
};
