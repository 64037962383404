import { isSpecKey } from '../../excel-table-cell-formatter/common';
import { MoveCellRefMap, MoveCellTempData, MoveDataPayload, UpdatedCellsMap } from '../interfaces';
import { shiftColumns } from '../shift-columns/shift-columns';
import { getColumnIndex, getShiftedColumnId } from '../utils';

export const deleteColumns = ({ startIndex, sheetsData, sheetId, offset }: MoveDataPayload): MoveCellTempData => {
  const { rows } = sheetsData[sheetId];
  const updatedCellsMap: UpdatedCellsMap = {};
  const moveCellRefMap: MoveCellRefMap = {};

  const shiftCellsInRow = shiftColumns(offset, updatedCellsMap, moveCellRefMap);

  rows.forEach((row) => {
    const columnKeys = new Set<string>();
    Object.keys(row).forEach(key => {
      const currentColumnIndex = getColumnIndex(key);
      if (isSpecKey(key) || currentColumnIndex < startIndex) {
        return;
      }
      columnKeys.add(key);
      if (currentColumnIndex > startIndex + offset - 1) {
        columnKeys.add(getShiftedColumnId(key, -offset));
      }
    });

    shiftCellsInRow(columnKeys, row);
  });

  return { updatedCellsMap, moveCellRefMap };
};
