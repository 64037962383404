import * as Ag from 'ag-grid-community';


export enum TreeTableRowType {
  Group = 'Group',
  Element = 'Element',
}

export interface TreeTableRow<T> {
  id: string;
  parentId?: string;
  children?: string[];
  type: TreeTableRowType;
  properties: Record<string, T>;
  height?: string;
}

export interface TreeTableColumn<T> {
  parentId?: string;
  childColumnKeys?: string[];
  properties: Record<string, T>;
}

export interface TreeTableColumnsData<T> {
  firstLevelColumns: string[];
  columns: Record<string, TreeTableColumn<T>>;
}

export interface TreeTableRowsData<T> {
  rows: Array<TreeTableRow<T>>;
  firstLevelRows: string[];
}

export interface TreeTableData<TRow, TColumn> extends TreeTableRowsData<TRow>, TreeTableColumnsData<TColumn> {}

export interface TreeTableRowAddModel<T> {
  id?: string;
  children?: Array<TreeTableRowAddModel<T>>;
  type: TreeTableRowType;
  properties: Record<string, T>;
}

export interface TreeTableRowUpdateModel<T> {
  id: string;
  properties: Record<string, T>;
}

export interface TreeTableGroupRules {
  EmptyGroupNode: Ag.RowNode;
  EmptyElementNode: Ag.RowNode;
  isGroup: (node: Ag.RowNode) => boolean;
  isItem: (node: Ag.RowNode) => boolean;
  availableInsertAfter: (node: Ag.RowNode, overNode: Ag.RowNode) => boolean;
  availableInsertInto: (node: Ag.RowNode, overNode: Ag.RowNode) => boolean;
  getClosestAvailableOverNode: (draggableNode: Ag.RowNode, overNode: Ag.RowNode) => Ag.RowNode;
}

export interface TreeTableDragEventsHandlers {
  onDragStart: () => void;
  onDragEnd: () => void;
}


export interface TreeTableValueSetterParams<TRow> {
  data: TreeTableRow<TRow>;
  columnId: string;
  newValue: TRow;
}

export interface TreeTableValueGetterParams {
  node: Ag.RowNode;
  columnId: string;
  api: Ag.GridApi;
  columnApi: Ag.ColumnApi;
  context: {
    isImperial: boolean,
  };
}
