import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './database-resource-type-navigation-list.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { ResourceType } from '../../enums';
import { DatabaseResourceTypeNavigationListItem } from './database-resource-type-navigation-list-item';

interface ReduxProps {
  selectedResourceType: ResourceType;
}

interface ReduxActions {
  setSelectedResourceType: (type: ResourceType) => void;
}

interface Props extends ReduxProps, ReduxActions {
}

class DatabaseResourceTypeNavigationListComponent extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='database-resource-type-navigation-list'>
        <DatabaseResourceTypeNavigationListItem
          isSelected={this.props.selectedResourceType === ResourceType.Labour}
          onSelect={this.props.setSelectedResourceType}
          title='Labour'
          type={ResourceType.Labour}
        />
        <DatabaseResourceTypeNavigationListItem
          isSelected={this.props.selectedResourceType === ResourceType.Plant}
          onSelect={this.props.setSelectedResourceType}
          title='Plant'
          type={ResourceType.Plant}
        />
        <DatabaseResourceTypeNavigationListItem
          isSelected={this.props.selectedResourceType === ResourceType.Material}
          onSelect={this.props.setSelectedResourceType}
          title='Materials'
          type={ResourceType.Material}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    selectedResourceType: state.database.currentDatabase.resourceListing.resourceType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setSelectedResourceType: (type) => dispatch(DatabaseResourceListingActions.setSelectedResourceType(type)),
  };
};


const connector = connect(mapStateToProps, mapDispatchToProps);
export const DatabaseResourceTypeNavigationList = connector(DatabaseResourceTypeNavigationListComponent);
