import * as t from 'io-ts';
import { CEActivityAssignmentCategoryResponseC } from './ce-activity-assignment-tree-node-response';


export const CEActivityAssignmentResponseC = t.type({
  databaseIds: t.array(t.number),
  tree: t.array(CEActivityAssignmentCategoryResponseC),
},
'ActivityAssignmentResponse');

export type CEActivityAssignmentResponse = t.TypeOf<typeof CEActivityAssignmentResponseC>;
