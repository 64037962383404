export function flatten<T>(arr: T[][] | T[]): T[] {
  const flattenedArray: T[] = [];

  arr.forEach((subArray: T[] | T[][] | T): void => {
    if (Array.isArray(subArray)) {
      flattenedArray.push(...flatten(subArray));
    } else {
      flattenedArray.push(subArray);
    }
  });

  return flattenedArray;
}
