import { arrayUtils } from 'common/utils/array-utils';
import { objectUtils } from 'common/utils/object-utils';
import { ProcessFileDataResult } from '../actions/payloads/annotation';
import { DrawingsFilesData, DrawingsShortInfo } from '../interfaces';
import { DrawingAnnotationUtils } from '../utils/drawing-annotation-utils';
import { DrawingsApi } from './drawings-api';

interface Payload {
  projectId: number;
  pdfId: string;
  pageId: string;
  filesData: DrawingsFilesData;
  onUpdateTotal: (total: number) => void;
  onUpdateProgress: (progress: number) => void;
}


async function process(
  { projectId, pdfId, pageId, onUpdateProgress, onUpdateTotal, filesData }: Payload,
  onCancellationReady: (cancellation: () => void) => void,
  isCancelled: () => boolean,
): Promise<ProcessFileDataResult> {
  const data = await DrawingsApi.getMarkups(projectId, pdfId, pageId);
  if (data) {
    if (isCancelled()) {
      return null;
    }
    const geometriesCount = Object.keys(data.geometry).length;
    onUpdateTotal(geometriesCount);
    const [ promise, cancel ] = DrawingAnnotationUtils.canelableProcessFileDataAsync(
      filesData,
      pdfId,
      pageId,
      data,
      onUpdateProgress,
    );
    onCancellationReady(cancel);
    return promise;
  }
  return null;
}

export function fileGeometriesLoader(
  payload: Payload,
): [() => Promise<ProcessFileDataResult>, () => void] {
  let processCancellation = null;
  let isCanceled = false;
  const processPromise = process(payload, cancel => processCancellation = cancel, () => isCanceled);

  const result = (): Promise<ProcessFileDataResult> => {
    return processPromise.then((data) => {
      if (!isCanceled) {
        return data;
      }
    });
  };

  const cancellation = (): void => {
    isCanceled = true;
    if (processCancellation) {
      processCancellation();
    }
  };
  return [result, cancellation];
}

interface FindAllPayload {
  projectId: number;
  filesData: DrawingsFilesData;
  onUpdateTotal: (total: number) => void;
  onUpdateProgress: (progress: number) => void;
  drawings: Record<string, DrawingsShortInfo>;
}

const REQUEST_BATCH_SIZE = 50;

export function extendFileData(result: DrawingsFilesData, data: DrawingsFilesData): void {
  for (const [ key, value ] of Object.entries(data)) {
    if (!result[key]) {
      result[key] = value;
    } else {
      objectUtils.extend(result[key], value);
    }
  }
}


function extendResult(result: ProcessFileDataResult, data: ProcessFileDataResult): void {
  objectUtils.extend(result.geometryInstances, data.geometryInstances);
  objectUtils.extend(result.points, data.points);
  objectUtils.extend(result.pointsInfo, data.pointsInfo);
  extendFileData(result.fileData, data.fileData);
  arrayUtils.extendArray(result.usedColors, data.usedColors);
  arrayUtils.extendArray(result.hiddenInstances, data.hiddenInstances);
  arrayUtils.extendArray(result.users, data.users);
  objectUtils.extend(result.pagePoints, data.pagePoints);
}

async function processAll(
  { onUpdateProgress, onUpdateTotal, filesData, drawings, projectId }: FindAllPayload,
  onCancellationReady: (cancellation: () => void) => void,
  isCancelled: () => boolean,
): Promise<ProcessFileDataResult> {
  let isCanceled = false;
  const cancellations = [];

  const result: ProcessFileDataResult = {
    geometryInstances: {},
    points: {},
    pointsInfo: {},
    fileData: {},
    usedColors: [],
    hiddenInstances: [],
    users: [],
    pagePoints: {},
  };

  onCancellationReady(() => {
    isCanceled = true;
    cancellations.forEach(cancel => cancel());
  });

  let promises: Array<Promise<ProcessFileDataResult>> = [];

  for (const [ key, info ] of Object.entries(drawings)) {
    if (!info.hasMeasurements) {
      continue;
    }
    const payload = {
      projectId,
      pdfId: info.pdfId,
      pageId: key,
      filesData,
      onUpdateProgress,
      onUpdateTotal,
    };
    const promise = process(payload, cancel => cancellations.push(cancel), () => isCanceled);
    promises.push(promise);
    if (promises.length >= REQUEST_BATCH_SIZE) {
      if (isCancelled()) {
        return null;
      }
      const results = await Promise.all(promises);
      results.forEach(r => extendResult(result, r));
      promises = [];
    }
  }
  if (promises.length) {
    if (isCancelled()) {
      return null;
    }
    const results = await Promise.all(promises);
    results.forEach(r => extendResult(result, r));
  }

  return result;
}

export function loadAllFilesGeometries(
  payload: FindAllPayload,
): [() => Promise<ProcessFileDataResult>, () => void]  {
  let processCancellation = null;
  let isCanceled = false;
  const processPromise = processAll(payload, cancel => processCancellation = cancel, () => isCanceled);

  const result = (): Promise<ProcessFileDataResult> => {
    return processPromise.then((data) => {
      if (!isCanceled) {
        return data;
      }
    });
  };

  const cancellation = (): void => {
    isCanceled = true;
    if (processCancellation) {
      processCancellation();
    }
  };
  return [result, cancellation];
}
