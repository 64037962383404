import * as React from 'react';

import {
  PositionalDialog as Pd,
  TopCardPanel,
} from 'common/UIKit';
import { NewComment } from './new-comment';

const PositionalDialog: any = Pd;

interface Props {
  markerMode: boolean;
  markerPosition: any;
  showNewCommentField: () => void;
  createNewComment: (value: string) => void;
}

export class NewCommentDialog extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <PositionalDialog {...this.getDialogData()}>
        <TopCardPanel
          content={'NEW COMMENT'}
          onExitClick={this.props.showNewCommentField}
          exit={true}
          controlNameButtonClose='new-comment-close-button'
        />
        <NewComment
          createNew={this.props.createNewComment}
        />
      </PositionalDialog>);
  }
  private getDialogData(): any {
    if (this.props.markerMode) {
      return this.props.markerPosition;
    } else {
      return {};
    }
  }
}
