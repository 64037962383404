import { AdminResourcesApi } from '../../../api/server';

function dump2dProject(projectId: number, quality: number): Promise<void> {
  return AdminResourcesApi.downloadFile(
    `/projects/${projectId}/dump?quality=${quality}`, null);
}

export const AdminProjectsApi = {
  dump2dProject,
};
