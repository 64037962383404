import { CodecUtils } from 'common/utils/codec-utils';

export enum SubscriptionActivityStatus {
  None = 'None',
  Active = 'Active',
  Trial = 'Trial',
  Canceled = 'Canceled',
  Mocked = 'Mocked',
  NonRenewing = 'NonRenewing',
  Paused = 'Paused'
}

export const SubscriptionActivityStatusC =
  CodecUtils.createEnumCodec<SubscriptionActivityStatus>(SubscriptionActivityStatus, 'SubscriptionActivityStatus');
