import styled from 'styled-components';

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;
  padding-top: 23px;
  box-sizing: border-box;
`;

const Container = styled.div`
  height: calc(100% - 90px);
`;


export const Styled = {
  PageContainer,
  Container,
};
