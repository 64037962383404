import styled from 'styled-components';

import { TwoDHeaderPageTabStyled } from 'common/components/two-d-header/two-d-header-page-tab';

const Container = styled.div`
  width: 320px;
  display: flex;
`;

const Tab = styled.div<{ selected: boolean }>`
  width: 160px;
  padding: 8px 10px;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  border-radius: 15px;
  background-color: ${({ selected, theme }) => selected ? theme.color.backgroundRush : 'transparent'};
  ${TwoDHeaderPageTabStyled.TextContainer} {
    max-width: calc(100% - 60px);
  }
`;


export const Styled = {
  Container,
  Tab,
};
