import { TwoDRegex } from '../2d-regex';
import { CellsRef } from './cell-ref-handler';
import { DrawingsRef } from './drawings-ref-handler';
import { DynamicGroupsRef } from './dynamic-groups-ref-handler';
import { RefHandler } from './ref-handler';

export interface UpdatedCell {
  fullCellId: string;
  newValue: string;
  prevValue: string;
}

const clearPrevRef = (handlers: RefHandler[], prevValue: string, cellId: string): void => {
  let prevMatch = TwoDRegex.formulaPartGlobal.exec(prevValue);
  while (prevMatch) {
    const groups = prevMatch.groups;
    handlers.forEach(
      handler => {
        handler.removeRefs(groups, cellId);
      });
    prevMatch = TwoDRegex.formulaPartGlobal.exec(prevValue);
  }
};

const addNewRef = (handlers: RefHandler[], newValue: string, cellId): void => {
  let match = TwoDRegex.formulaPartGlobal.exec(newValue);
  while (match) {
    const groups = match.groups;
    handlers.forEach(handler => handler.addRefs(groups, cellId));
    match = TwoDRegex.formulaPartGlobal.exec(newValue);
  }
};

type RefMaps = {
  drawingInstanceToCellMap: Record<string, string[]>,
  dynamicGroupsToCellMap: Record<string, string[]>,
  cellToCellMap: Record<string, string[]>,
};

export const getRefMaps = (
  payload: UpdatedCell[],
  prevDrawingInstanceToCell: Record<string, string[]>,
  prevDynamicGroupsToCell: Record<string, string[]>,
  prevCellToCell: Record<string, string[]>,
): RefMaps => {
  const refsHandlersMap = {
    drawingInstanceToCellMap: new DrawingsRef(prevDrawingInstanceToCell),
    dynamicGroupsToCellMap: new DynamicGroupsRef(prevDynamicGroupsToCell),
    cellToCellMap: new CellsRef(prevCellToCell),
  };
  const refsHandlers = Object.values(refsHandlersMap);

  for (const cell of payload) {
    const newValue = cell.newValue ? cell.newValue.toString() : '';
    const prevValue = cell.prevValue ? cell.prevValue.toString() : '';

    clearPrevRef(refsHandlers, prevValue, cell.fullCellId);
    addNewRef(refsHandlers, newValue, cell.fullCellId);
  }

  return {
    drawingInstanceToCellMap: refsHandlersMap.drawingInstanceToCellMap.getResultState(),
    dynamicGroupsToCellMap: refsHandlersMap.dynamicGroupsToCellMap.getResultState(),
    cellToCellMap: refsHandlersMap.cellToCellMap.getResultState(),
  };
};
