import autobind from 'autobind-decorator';
import React from 'react';

import { FormSingleSelectField } from 'common/components/form-fields';

interface Props {
  value: string;
  groups: string[];
  onChange: (updates: Record<string, string>) => void;
}

export class GroupsDropdown extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const groupIndex = this.props.groups.indexOf(this.props.value);
    const isNewGroup = groupIndex === -1;
    const groups = isNewGroup && this.props.value !== undefined
      ? [this.props.value, ...this.props.groups]
      : this.props.groups;
    return (
      <FormSingleSelectField
        label='Group'
        input={{
          activeElementIndex: groupIndex,
          elements: groups,
          onClick: this.changeGroup,
          onBlur: this.createGroup,
          value: this.props.value,
          createNewElement: true,
          createEmptyElement: true,
        }}
      />
    );
  }

  @autobind
  private changeGroup(index: number): void {
    this.props.onChange({ groupName: this.props.groups[index] });
  }

  @autobind
  private createGroup(name: string): void {
    this.props.onChange({ groupName: name });
  }
}
