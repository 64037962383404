import autobind from 'autobind-decorator';
import * as React from 'react';

import './control-line.scss';

import { ColorPickerControl } from 'common/components/color-picker-control';
import { Fab, KreoIconHome } from 'common/UIKit';
import { BaseControl } from './base-control';
import { ClipBoxControl } from './clipbox-control';

interface Props {
  color: string;
  hasSelected: boolean;
  ghostChecked: boolean;
  clipboxActive: boolean;
  showAllButton: boolean;
  containerRef: HTMLDivElement;
  onClipBoxOnOffClick: () => void;
  onAssignColor: (color: string) => void;
  setClipBoxMode: (mode: any) => void;
  onHomeClick: () => void;
  onShowAll: () => void;
  onHide: () => void;
  onIsolate: () => void;
  onFocus: () => void;
  onChangeColor: (color: string) => void;
}

export class ControlLine extends React.Component<Props> {

  public render(): JSX.Element {
    return (
      <div className='viewer-control-line'>
          <Fab
            color='white'
            size={40}
            floatVertical={0}
            floatHorizontal={0}
            onClick={this.props.onHomeClick}
            controlName='home-button'
          >
            <KreoIconHome />
          </Fab>
          <ClipBoxControl
            onBoxClick={this.toggleClipBox}
            active={this.props.clipboxActive}
            ghostChecked={this.props.ghostChecked}
            setClipBoxMode={this.props.setClipBoxMode}
          />
          <ColorPickerControl
            color={this.props.color}
            onChangeColor={this.onChangeColor}
            disabled={!this.props.hasSelected}
            containerRef={this.props.containerRef}
          />
          {this.props.hasSelected && [
            <BaseControl
              key={1}
              onClick={this.props.onHide}
              controlName='hide-button'
              caption='Hide'
            />,
            <BaseControl
              key={2}
              onClick={this.props.onIsolate}
              controlName='isolate-button'
              caption='Isolate'
            />,
            <BaseControl
              key={3}
              onClick={this.props.onFocus}
              controlName='focus-button'
              caption='Focus'
            />,
          ]}
          {this.props.showAllButton && (
            <BaseControl
              key={4}
              onClick={this.props.onShowAll}
              controlName='show-all-button'
              caption='Show All'
            />
          )}
      </div>
    );
  }

  @autobind
  private onChangeColor(color: string): void {
    this.props.onChangeColor(color);
  }

  @autobind
  private toggleClipBox(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.props.onClipBoxOnOffClick();
  }
}
