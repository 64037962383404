import styled from 'styled-components';

const Value = styled.div`
  box-sizing: border-box;
  height: 20px;
  padding: 4px 0;
  padding-right: 7px;
  padding-left: 8px;
  color: ${p =>  p.theme.color.mainFont};
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: auto;
`;

const StrokeToggle = styled.div<{ isActive: boolean }>`
  > button{
    svg {
      fill: ${p => p.theme.color[p.isActive ? 'turquoise' : 'gray']}
    }
  }
`;

export const Styled = {
  Value,
  StrokeToggle,
};
