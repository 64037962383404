import { Avatar, Text, Switcher, ElementTooltip } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';
import { AccountApi } from '../../../units/account/api';
import { Company } from '../../../units/account/interfaces/company';
import { Styled } from './styled';

export interface CompanyInfo {
  name: string;
  logo: string;
}

interface Props {
  checked: boolean;
  onChange: () => void;
  company: Company;
}

export const ShareWithCompany: React.FC<Props> = ({
  checked,
  onChange,
  company,
}) => {
  const companyLogo = useMemo(() => {
    return AccountApi.getCompanyLogoLink(company);
  }, [company]);
  return (
    <Styled.Container>
      <Styled.AvatarContainer>
        <Avatar
          name={company.name}
          avatar={companyLogo}
          size={34}
        />
      </Styled.AvatarContainer>
      <Styled.ContentWrapper>
        <Styled.TextContainer>
          <Text>Share with company</Text>
        </Styled.TextContainer>
        <Styled.SwitcherContainer>
          <ElementTooltip
            disabled={!checked}
            text={'Available to the whole company'}
            position='top'
            wordBreakAll={true}
          >
            <Switcher
              size='l'
              checked={checked}
              onChange={onChange}
            />
          </ElementTooltip>
        </Styled.SwitcherContainer>
      </Styled.ContentWrapper>
    </Styled.Container>
  );
};
