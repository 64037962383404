import React, { useCallback } from 'react';

import { DrawingsSettingsSwitcherWithLabel } from 'common/components/drawings/components/setting-parameter';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { NoScaleDescriptionType } from 'common/components/drawings/dialogs';
import { useCurrentDrawingInfo, useWizzardSetting } from 'common/components/drawings/hooks';
import { AiRequestQuality } from 'common/components/drawings/interfaces';
import { DrawingsUtils } from 'common/components/drawings/utils/drawings-utils';
import { InputSelectNoIndex } from 'common/components/inputs';
import { RenderIf } from 'common/components/render-if';
import { useOpenCloseDialog } from 'common/UIKit';
import { MagicSearchToggle } from '../../magic-search-toggle';
import { PropertyWithInfo } from '../../property-with-info';
import { WizzardMainContainer } from '../wizzard-main-container';

// eslint-disable-next-line max-len
const TOOLTIP_TEXT = `Adjust this setting to change the size of the polygons. The default is automatic, but you can select 'Big areas' for larger polygons or 'Small areas' for smaller ones. This ensures the best quality for your polygons`;

const OPTIONS = [AiRequestQuality.Auto, AiRequestQuality.Big, AiRequestQuality.Small];

const BucketSettingsComponent: React.FC = () => {
  const [ boostWithAi, setBoostWithAi] = useWizzardSetting('boostWithAi');
  const currentDrawingInfo = useCurrentDrawingInfo();
  const isCalibrated = currentDrawingInfo ? DrawingsUtils.isCalibrated(currentDrawingInfo) : false;
  const [ openDialog ] = useOpenCloseDialog(DrawingDialogs.NOT_SCALED_DIALOG);

  const setBoostCalback = useCallback((value: boolean) => {
    if (isCalibrated || !value) {
      setBoostWithAi(value);
    } else {
      openDialog({
        onSkip: () => setBoostWithAi(value),
        descriptionType: NoScaleDescriptionType.BucketFIll,
      });
    }
  }, [isCalibrated, setBoostWithAi, openDialog]);

  const [ quality, setQuality ] = useWizzardSetting('quality');

  return (
    <WizzardMainContainer>
      <DrawingsSettingsSwitcherWithLabel
        label='AI Turbo Boost'
        checked={boostWithAi}
        toggle={setBoostCalback}
        id='ai-turbo-boost'
      />
      <RenderIf condition={boostWithAi}>
        <PropertyWithInfo
          name='Mode'
          tooltipText={TOOLTIP_TEXT}
        >
          <InputSelectNoIndex
            elements={OPTIONS}
            onClick={setQuality}
            createNewElement={false}
            activeElement={quality}
            isShowArrow={true}
          />
        </PropertyWithInfo>
      </RenderIf>
      <MagicSearchToggle/>
    </WizzardMainContainer>
  );
};

export const BucketSettings = React.memo(BucketSettingsComponent);
