import { ShortcutDialog as Dialog } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { ShortcutGroup } from './shortcut-list';

export const SHORTCUT_DIALOG_NAME = 'SHORTCUT_DIALOG';

interface Props {
  onCloseDialog: (dialogName: string) => void;
}


export class ShortcutDialog extends React.Component<Props> {
  public render(): React.ReactNode {

    return (
      <DialogWrapper name={SHORTCUT_DIALOG_NAME}>
        <Dialog onClose={this.onClose} shortcutGroup={ShortcutGroup} />
      </DialogWrapper>
    );
  }

  @autobind
  private onClose(): void {
    this.props.onCloseDialog(SHORTCUT_DIALOG_NAME);
  }
}
