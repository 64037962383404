import autobind from 'autobind-decorator';
import { DrawingsCanvasConstants } from 'common/components/drawings/constants';
import { ConstantFunctions } from 'common/constants/functions';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import {
  DraggableMouseEventsProcessor,
} from '../../drawings-helpers/geometry-event-processors';
import { PaperMouseEventUtils } from '../../drawings-helpers/paper-mouse-event-utils';


export class MouseEventsApplier extends DraggableMouseEventsProcessor<string> {
  private _dragExecutor: DeferredExecutor = new DeferredExecutor(DrawingsCanvasConstants.doubleClickDelay / 2);

  public override setHandlers(path: paper.Item): void {
    super.setHandlers(path);
    path.onMouseUp = this.onMouseLeave;
  }

  @autobind
  protected override onMouseDown(e: PaperMouseEvent): void {
    if (!this._config.onStartDrag || !this.canDrag()) {
      return;
    }

    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      ConstantFunctions.stopEvent(e);
      this._dragExecutor.execute(() => this._config.onStartDrag(e, this.extraInfo));
    }
  }
}
