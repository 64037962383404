import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const RoundPicker = styled.div<{ elementWidth: number, backgroundColor: string }>`
  display: inline-flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  width: 100%;
  > div {
    width: ${p => p.elementWidth}%;
  }
  background-color: ${p => p.theme.color.backgroundRush};
  background-color: ${p => p.backgroundColor ? p.theme.color[p.backgroundColor] : p.theme.color.background};
`;

interface RoundProps {
  elementHeight: number;
  iconHeight?: number;
  active: boolean;
  rightIcon?: boolean;
}

const Round = styled.div<RoundProps>`
  position: relative;
  width: 100%;
  height: ${p => p.elementHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
  transition: ${p => p.theme.duration.m} ease-in;
  cursor: pointer;
  svg {
    width: ${p => p.iconHeight || 30}px;
    height: ${p => p.iconHeight || 20}px;
    margin: 0 ${p => (p.rightIcon ? 0 : 10)}px 0
      ${p => (p.rightIcon ? 10 : 0)}px;
    fill: ${p => p.active ? p.theme.color.white : p.theme.color.gray};
  }

  ${Text}, svg {
    z-index: 1;
    transition: ${p => p.theme.duration.m} ease-in;
    text-align: center
  }

  &:hover {
    ${Text}, svg {
      color: ${p => (p.active ? p.theme.color.white : p.theme.color.turquoise)};
      fill: ${p => (p.active ? p.theme.color.white : p.theme.color.turquoise)};
    }
  }
`;

interface RoundSwitcherProps {
  elementWidthPercentage: number;
  leftCoefficient: number;
}

const RoundSwitcher = styled.div<RoundSwitcherProps>`
  width: ${p => p.elementWidthPercentage}%;
  height: 100%;
  border-radius: 30px;
  transition: ${p => p.theme.duration.m} ease-in;
  background-color: ${p => p.theme.color.turquoise};
  position: absolute;
  left: calc(${p => p.elementWidthPercentage}% * ${p => p.leftCoefficient});
`;

export const Styled = {
  RoundPicker,
  Round,
  RoundSwitcher,
};
