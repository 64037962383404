import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { FaqCaption } from 'common/enums/faq-caption';
import { HeaderContext } from 'common/enums/header-context';
import { LogoType } from 'common/enums/logo-type';
import { PageHeader } from 'common/interfaces/page-header';
import { State as ReduxState } from 'common/interfaces/state';
import { PageLayout } from '../../../layouts/page-layout';
import { DatabaseActions } from '../../../units/databases/actions/creators/database';
import { DatabaseCommonActions } from '../../../units/databases/actions/creators/database-common';
import { DatabaseModel } from '../../../units/databases/interfaces/data';
import { DatabaseActivityListingPage, DatabaseResourceListingPage } from '../../../units/databases/pages';
import { NotFoundPage } from '../../../units/notfound/page';
import { DatabaseRouteParams } from './route-params';
import { DatabaseUrls } from './urls-fabric';


interface ReduxProps {
  database: DatabaseModel;
}

interface ReduxActions {
  changeDatabaseName: (databaseId: number, name: string) => void;
  dropCurrentDatabaseState: () => void;
}

interface Props<T extends DatabaseRouteParams>
  extends ReduxProps,
    ReduxActions,
    RouteComponentProps<DatabaseRouteParams>,
    AbilityAwareProps {
  urls: DatabaseUrls<T>;
}

const databaseHeader: PageHeader = {
  context: HeaderContext.Database,
  color: 'white',
  logoType: LogoType.LogoBlackLight,
  faqCaption: FaqCaption.Databases,
};

class RoutesComponent<T extends DatabaseRouteParams> extends React.Component<Props<T>> {
  public render(): JSX.Element {
    const { urls } = this.props;

    const routeProps = {
      subject: Subject.Database,
      redirectUrl: urls.listing.url(),
      header: {
        ...databaseHeader,
        backUrl: urls.listing.url(),
        title: this.props.database ? this.props.database.name : null,
        onEditTitle: this.props.ability.can(Operation.Update, Subject.Database)
          ? this.changeDatabaseName
          : null,
      },
    };

    return (
      <Switch>
        <PageLayout
          exact={true}
          metaTitle='Database Activity'
          path={urls.activities.path}
          component={DatabaseActivityListingPage}
          {...routeProps}
        />
        <PageLayout
          exact={true}
          metaTitle='Database Resources'
          path={urls.resources.path}
          component={DatabaseResourceListingPage}
          backgroundColor='#fff'
          {...routeProps}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  public componentWillUnmount(): void {
    this.props.dropCurrentDatabaseState();
  }

  @autobind
  private changeDatabaseName(name: string): void {
    this.props.changeDatabaseName(this.props.database.id, name);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    database: state.database.currentDatabase && state.database.currentDatabase.database,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    changeDatabaseName: (databaseId: number, name: string) =>
      dispatch(DatabaseActions.updateDatabase({ databaseId, name })),
    dropCurrentDatabaseState: () => dispatch(DatabaseCommonActions.dropCurrentDatabaseState()),
  };
};

export const Routes = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RoutesComponent),
);
