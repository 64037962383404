import { AvatarStyled, MenuItemStyled, UsersListStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  ${MenuItemStyled.Item} {
    border-top-width: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 10px;
`;

const FullName = styled.div`
  height: 40px;
  max-width: 145px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 15px;
`;

const ProjectMenuDate = styled.div`
  padding: 15px 20px 25px 20px;
  display: flex;
  align-items: center;
  border-bottom: 10px solid ${p => p.theme.color.background};

  svg {
    height: 20px;
    fill: ${p => p.theme.color.gray};
    margin-right: 10px;
  }
`;

const FolderContainer = styled.div<{ showPath?: boolean }>`
  padding: 10px 20px 0px ${p => p.showPath ? 10 : 20}px;
  display: flex;
  align-items: center;

  div:not(:last-child) {
    > div:first-child {
      > div {
        > svg {
          transform: rotate(90deg);
        }
      }
    }
  }
`;

const MoveToItemContainer = styled.div<{ withFolderPath?: boolean }>`
  border-bottom: 10px solid ${p => p.theme.color.background};
  
  ${MenuItemStyled.Item} {
    height: ${p => p.withFolderPath ? 50 : 40}px;
  }
`;

const ShareContainer = styled.div<{ isInvitedUsers?: boolean, isRoleGuest?: boolean, isCompanyShared?: boolean }>`
  border-bottom: 10px solid ${p => p.theme.color.background};
  height: ${p => p.isInvitedUsers && !p.isRoleGuest || p.isCompanyShared ? 90
    : p.isInvitedUsers && p.isRoleGuest ? 55 : 40}px;
  display: flex;
  flex-direction: column;

  ${UsersListStyled.OverUsers} {
    max-height: 290px;
    top: ${p => p.isRoleGuest ? -115 : 20}px;
    overflow-y: auto;
    left: -85px;
  }

  ${UsersListStyled.Container} {
    margin: 10px 20px 0;
  }

  ${MenuItemStyled.Item} {
    height: 40px;
  }
`;

const UsersListWrapper = styled.div<{ isCompanyShared: boolean }>`
  display: flex;
  justify-content: space-between;

  ${AvatarStyled.Avatar} {
    display: ${p => p.isCompanyShared ? 'flex' : 'none'};
    border: 4px solid ${p => p.theme.color.background};
    margin: 10px 20px 0;
  }
`;

const Main = styled.div`
  ${MenuItemStyled.Item} {
    height: 40px;
  }
`;

const ThemeSwitcher = styled.div`
  width: 100%;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid ${p => p.theme.color.background};
  cursor: pointer;
  &, * {
    box-sizing: border-box;
  }
  p {
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  }
  :hover {
    p {
      color: ${p => p.theme.color.turquoise};
    }
  }
`;

export const Styled = {
  Container,
  Header,
  FullName,
  ProjectMenuDate,
  FolderContainer,
  MoveToItemContainer,
  ShareContainer,
  UsersListWrapper,
  Main,
  ThemeSwitcher,
};
