import { Text, Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import { CheckboxGroupMenu } from 'common/components/selection-menus';
import { arrayUtils } from 'common/utils/array-utils';
import { AnnotationFilters } from '../../interfaces/drawing-filters';
import { FilterElement } from './interfaces';
import { Styled } from './styled';


interface Props {
  filterType: AnnotationFilters;
  value: Record<string, boolean>;
  options: FilterElement[];
  onChangeFilter: (filterType: AnnotationFilters, value: Record<string, boolean>) => void;
}

export class CheckboxFilter extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <CheckboxGroupMenu
        value={this.getSelectedValues()}
        options={this.props.options}
        customOptionRender={this.renderOption}
        onSelectionChanged={this.onChangeSelection}
      />
    );
  }

  @autobind
  private onChangeSelection(value: string[]): void {
    const result = arrayUtils.toDictionary(value, item => item, _ => true);
    this.props.onChangeFilter(this.props.filterType, result);
  }

  private renderOption(data: FilterElement, selected: boolean): React.ReactNode {
    return (
      <>
        <Styled.SetElementIconWithText>
          {data.icon}
          <Text>{data.name}</Text>
        </Styled.SetElementIconWithText>
        {selected ? <Icons.ApplyS /> : null}
      </>
    );
  }

  @autobind
  private getSelectedValues(): string[] {
    return arrayUtils.filterMap(
      Object.entries(this.props.value),
      ([_, value]) => value,
      ([key]) => key,
    );
  }
}
