import { ColumnKeys, ColumnNames } from '../../../units/2d/constants/table-settings';

export const PROPERTIES = Object.entries(ColumnNames)
  .map(([key, name]) => {
    return key === ColumnKeys.groupsBreakdown
      ? { key, name: 'Group breakdown' }
      : { key, name };
  });

export const SETTINGS = [{
  key: 'showColumnNames',
  name: 'Show column names',
}, {
  key: 'showTotal',
  name: 'Show total value',
}];
