export enum KreoColors {
  white = '#FFFFFF',
  f1 = '#152A42',
  f2 = '#2D4969',
  f3 = '#4D6B8C',
  f4 = '#728FAD',
  f5 = '#93ACC7',
  gray1 = '#EDF2F5',
  gray2 = '#E1EAF0',
  gray3 = '#D3E1EB',
  gray4 = '#BACEDE',
  gray5 = '#93ACC7',
  gray6 = '#728FAD',
  gray7 = '#4D6B8C',
  gray8 = '#2D4969',
  gray9 = '#152A42',
  gray10 = '#1D2733',
  orange = '#FF8566',
  orange1 = '#FFD000',
  blue = '#5C89E5',
  blue1 = '#EBF4FF',
  blue2 = '#D0EDFE',
  blue3 = '#B3D1FF',
  blue4 = '#00B2FF',
  success = '#68CC52',
  success1 = '#E3F5CE',
  success2 = '#D7F5B5',
  success3 = '#5CE573',
  attention = '#F6AC46',
  attention1 = '#FFF1C7',
  attention2 = '#FFECB3',
  attention3 = '#F5C431',
  danger = '#F65469',
  danger1 = '#FFE0E0',
  blueDark = '#001c4d',
  blueGray = '#15366e',
  nightBackground = '#222E3C',
  nightPale = '#293849',
  nightSecondaryFontColor = '#4E6A8A',
  nightDeepBackground = '#1D2733',
  nightSecondary = '#2974AD',
  red = '#E15556',
  dayBackground = '#FFFFFF',
  daySecondaryFontColor = '#5C7EA3',
  dayDeepBackground = '#F5F7FB',
  green = '#29AD67',
  iconColor = '#79A5D6',
  nightDisabled = '#314357',
  dayResedential = '#61A9B3',
  nightResedential = '#2A494D',
  dayOffice = '#6282B3',
  dayPale = '#E8F2FF',
  dayDisabled = '#CDD8E5',
  daySecondary = '#32A6FE',
  ctaColor = '#FF8E6A',
  nightOffice = '#384A66',
  nightWarehouse = '#2A4D3F',
  dayWarehouse = '#61B392',
  dayMixedUse = '#8297A6',
  nightMixedUse = '#293E4D',
}

export enum ColorsName {
  background = 'background',
  backgroundRush = 'backgroundRush',
  action = 'action',
  mainFont = 'mainFont',
  fontHover = 'fontHover',
  gradient = 'gradient',
  backgroundHeader = 'backgroundHeader',
  additionalFont = 'additionalFont',
  icon = 'icon',
  additionalIcon = 'additionalIcon',
  disabled = 'disabled',
  residential = 'residential',
  residentialByModular = 'residentialByModular',
  office = 'office',
  warehouse = 'warehouse',
  parking = 'parking',
  mixedUse = 'mixedUse',
  modular = 'modular',
  templateHover = 'templateHover',
  highLight = 'highLight',
  turquoise = 'turquoise',
  turquoiseFont = 'turquoiseFont',
  turquoiseInversed = 'turquoiseInversed',
  pale = 'pale',
  yellow = 'yellow',
}
