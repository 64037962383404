import React, { PropsWithChildren, createContext } from 'react';
import { useParentId } from '../inherit-label/use-parent-id';
import { useCurrentId } from './use-current-id';
import { useDiffAssign } from './use-diff-assign';

export const OverrideContext = createContext<Array<{
  assemblyName: string,
  itemName: string,
  propertyName: string,
  inheritId: string,
}>>([]);

export const OverrideLabel: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const parentId = useParentId();
  const currentId = useCurrentId();
  const diffAssign = useDiffAssign(parentId, currentId, parentId);
  return <OverrideContext.Provider value={diffAssign}>{children}</OverrideContext.Provider>;
};
