import * as React from 'react';

import './page-footer.scss';

import { ChartDataProvider } from '../../../utils/gantt-chart';
import { ProjectTimeline } from './project-timeline';

interface OwnProps {
  width: number;
  projectStartDate: Date;
  timelineStartDay: number;
  timelineDuration: number;
  timeframeStartDay: number;
  timeframeDuration: number;
  dataProvider: ChartDataProvider;
  updateTimeframe: (start: number, duration: number) => void;
  updateTimeframeTarget: (start: number, duration: number) => void;
}

class PageFooter extends React.Component<OwnProps> {
  public render(): React.ReactNode {
    return (
      <div className='gantt-chart-page-container-footer'>
        <ProjectTimeline
          width={this.props.width}
          projectStartDate={this.props.projectStartDate}
          timelineStartDay={this.props.timelineStartDay}
          timelineDuration={this.props.timelineDuration}
          timeframeStartDay={this.props.timeframeStartDay}
          timeframeDuration={this.props.timeframeDuration}
          dataProvider={this.props.dataProvider}
          updateTimeframe={this.props.updateTimeframe}
          updateTimeframeTarget={this.props.updateTimeframeTarget}
        />
      </div>
    );
  }
}

export { PageFooter };
