import classNames from 'classnames';
import * as React from 'react';

import './load-more.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';

interface Props {
  status: RequestStatus;
  onLoadMore: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export class LoadMore extends React.Component<Props> {

  public render(): JSX.Element {
    const { status } = this.props;
    const loaded = status === RequestStatus.Loaded;
    return (
      <div className='load-more'>
        <div
          className='load-more__button'
          onClick={status === RequestStatus.Loading ? null : this.props.onLoadMore}
        >
          <div className={classNames('load-more__content', { 'load-more__content--loading': !loaded })}>
            {loaded ? 'Load More' : <React.Fragment><SvgSpinner size='small' />Loading...</React.Fragment>}
          </div>
        </div>
      </div>
    );
  }
}
