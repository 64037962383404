import {
  CustomElementFilterItemType,
  FilterNode,
} from 'common/components/custom-element-filter-builder/inner-interfaces';
import {
  CustomElementFilterNumber,
  CustomElementFilterReference,
  CustomElementFilterString,
} from 'common/interfaces/custom-element-filter-builder';
import { CustomElementFilterBuilderTypeGuards } from './custom-element-filter-builder-type-guards';


export class CustomElementFilterBuilderValidator {
  public static validate(node: CustomElementFilterItemType): string {
    if (CustomElementFilterBuilderTypeGuards.isStringBaseFilter(node)) {
      return CustomElementFilterBuilderValidator.validateStringFilter(node);
    } else if (CustomElementFilterBuilderTypeGuards.isNumberBaseFilter(node)) {
      return CustomElementFilterBuilderValidator.validateNumberFilter(node);
    } else if (CustomElementFilterBuilderTypeGuards.isReferenceBaseFilter(node)) {
      return CustomElementFilterBuilderValidator.validateReferenceFilter(node);
    } else if (CustomElementFilterBuilderTypeGuards.isFilterNode(node)) {
      return CustomElementFilterBuilderValidator.validateNode(node);
    }

    return 'No conditions selected';
  }

  private static validateNode(node: FilterNode): string {
    if (!node.innerNodes.length) {
      return 'The group is empty';
    }

    for (const filter of node.innerNodes) {
      const error = CustomElementFilterBuilderValidator.validate(filter);
      if (error) {
        return error;
      }
    }

    return null;
  }

  private static validateStringFilter(filter: CustomElementFilterString): string {
    if (!filter.propertyKey) {
      return 'Filter Key is empty';
    }

    if (!filter.collectionOperation) {
      return 'Collection operation is empty';
    }

    if (!filter.operation) {
      return 'Operation is empty';
    }

    if (!filter.operationArg) {
      return 'Value is empty';
    }

    return null;
  }

  private static validateNumberFilter(filter: CustomElementFilterNumber): string {
    if (!filter.propertyKey) {
      return 'Filter Key is empty';
    }

    if (!filter.operation) {
      return 'Operation is empty';
    }

    if (!filter.operationArg && filter.operationArg !== null) {
      return 'Value is empty';
    }

    return null;
  }

  private static validateReferenceFilter(filter: CustomElementFilterReference): string {
    if (!filter.elementFilterId) {
      return 'Filter Reference is empty';
    }

    return null;
  }
}
