import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { ClassificationEngineLayout } from '../../components/classification-engine-layout';
import { ClassificationLeftSideView } from '../../components/classification-left-side-view';
import { ClassificationOntologyTreeLeftSide } from '../../components/classification-ontology-tree-left-side';
import { ValidationStepMode } from '../../enums/validation-step-mode';

export class ClassificationViewPage extends React.PureComponent<RouteComponentProps<PlanProjectRouteParams>> {
  public render(): React.ReactNode {
    return (
      <ClassificationEngineLayout
        {...this.props}
        mode={ValidationStepMode.View}
      >
        <ClassificationOntologyTreeLeftSide
          mode={ValidationStepMode.View}
        >
          <ClassificationLeftSideView/>
        </ClassificationOntologyTreeLeftSide>
      </ClassificationEngineLayout>
    );
  }
}
