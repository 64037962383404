import * as React from 'react';

import {
  ClassificationEngineContextAwareProps,
} from '../../interfaces/classification/classification-engine-context-aware-props';
import { ClassificationEngineContext } from '../classification-engine-context-provider';

export function withClassificationEngineContext<P>(
  Component: React.ComponentType<P & ClassificationEngineContextAwareProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof ClassificationEngineContextAwareProps>>> {
  return class WithClassificationEngineContext extends React.Component<P> {
    public render(): React.ReactNode {
      return (
        <ClassificationEngineContext.Consumer>
          {contextProps => {
            return (<Component
              {...this.props}
              {...contextProps}
            />);
          }}
        </ClassificationEngineContext.Consumer>
      );
    }
  };
}
