import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const AddonItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 45%);
`;

const AddonGroupName = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;

  > p {
    min-width: 120px;
    margin-left: 20px;
  }

  > div:hover {
    cursor: ${p => p.isSelected ? 'pointer' : 'default'};
  }
`;

const CostAddonAndIconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const CostAddon = styled.div`
  display: flex;
  align-items: center;
  background: ${p => p.theme.color.turquoise};
  border-radius: 5px;
  height: 20px;
  padding: 0 5px;
  font-size: 12px;
  color: ${p => p.theme.color.mainFont};
  ${Text} {
    font-size: 12px;
  }

  > p {
    width: max-content;
  }
`;

const PriceField = styled.div<{ fontWeight?: string }>`
  display: inline-flex;
  align-items: center;

  > p {
    font-weight: ${p => p.fontWeight ? p.fontWeight : 'normal'};
  }
`;

const AdvDialogButton = styled.div`
  > svg {
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }
`;

export const Styled = {
  AddonItem,
  AddonGroupName,
  CostAddonAndIconButtonWrapper,
  CostAddon,
  PriceField,
  AdvDialogButton,
};
