import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import { Constants } from 'common/components/data-base-table/constants';
import { RowData, UserInfo } from 'common/components/data-base-table/interfaces';
import { Property, PropertyTypeEnum, User } from '../../interfaces';
import { formatData } from '../format-data-getter';
import { FormatTypeGuards } from '../format-typeguards';
import { mapDate } from '../map-date';
import { mapUser } from '../map-user';

export const PropertyTypeNames = {
  [PropertyTypeEnum.Formula]: 'Formula',
  [PropertyTypeEnum.Number]: 'Number',
  [PropertyTypeEnum.SingleSelect]: 'Single Select',
  [PropertyTypeEnum.Text]: 'Text',
  [PropertyTypeEnum.Breakdown]: 'Tree',
};

ReactDOMServer.renderToString(
  React.createElement(Icons.Formula, {}, null),
);

const PropertyIcons = {
  [PropertyTypeEnum.Formula]: ReactDOMServer.renderToString(
    React.createElement(Icons.Formula, {}, null),
  ),
  [PropertyTypeEnum.Number]: ReactDOMServer.renderToString(
    React.createElement(Icons.Number, {}, null),
  ),
  [PropertyTypeEnum.SingleSelect]: ReactDOMServer.renderToString(
    React.createElement(Icons.SingleSelect, {}, null),
  ),
  [PropertyTypeEnum.Text]: ReactDOMServer.renderToString(
    React.createElement(Icons.TextDataBase, {}, null),
  ),
  [PropertyTypeEnum.Breakdown]: ReactDOMServer.renderToString(
    React.createElement(Icons.Breakdown, {}, null),
  ),
};

const valueGetter: Record<PropertyTypeEnum, (property: Property) => string> = {
  [PropertyTypeEnum.Formula]: (property) => property.value.value as string,
  [PropertyTypeEnum.Number]: (property) => property.value.value.toString(),
  [PropertyTypeEnum.SingleSelect]: (property) => property.value.value.toString(),
  [PropertyTypeEnum.SingleSelectLink]: (property) => property.value.value.toString(),
  [PropertyTypeEnum.Text]: (property) => property.value.value as string,
  [PropertyTypeEnum.Breakdown]: () => '',
  [PropertyTypeEnum.BreakdownLink]: () => '',
};

const getGroupId = (id: string): string => `g_${id}`;

const userGetter: (users: User[]) => (id: string) => UserInfo = (users) => {
  const userMap: Record<string, UserInfo>  = {};
  return (id: string) => {
    const userInfo = userMap[id];
    if (!userInfo) {
      const newInfo = mapUser(users.find(u => u.id === id));
      userMap[id] = newInfo;
      return newInfo;
    }
    return userInfo;
  };
};

export const mapProperties = (properties: Property[], users: User[]): RowData[] => {
  const groups = new Set<string>();
  const getUser = userGetter(users);
  const rows: RowData[] = properties.map(property => {
    if (property.groupName) {
      groups.add(property.groupName);
    }
    const h_path = property.groupName
      ? `${getGroupId(property.groupName)}${Constants.DATA_PATH_SPLITER}${property.name}`
      : property.name;
    const createdBy = getUser(property.creatorId);
    const lastEditBy = getUser(property.editorId);
    const createdTime = mapDate(property.createdAt);
    const lastEditTime = mapDate(property.editedAt);
    const type = PropertyTypeNames[property.value.type];
    const format = formatData.getFormat(property.value.format);
    const unit = FormatTypeGuards.isNumeric(property.value.format)
      ? property.value.format.unit
      : '';
    const h_type_icon = PropertyIcons[property.value.type];
    const value = valueGetter[property.value.type](property);

    return {
      id: property.id,
      name: property.name,
      groupName: property.groupName,

      value,
      type,
      format,
      createdBy,
      lastEditBy,
      createdTime,
      lastEditTime,
      unit,

      h_path,
      h_isDragSource: false,
      h_isDragTarget: false,
      h_type_icon,
    };
  });

  groups.forEach(g => {
    rows.push({
      h_path: getGroupId(g),
      h_isDragSource: false,
      h_isDragTarget: true,
      name: g,
      type: '',
      id: getGroupId(g),
    });
  });

  return rows;
};
