import { UserPermission } from 'common/enums/user-permission';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { PermissionActivityDescription } from 'common/interfaces/account/permission-activity-description';
import { Role } from 'common/interfaces/account/role';
import { NumberDictionary, StringDictionary } from 'common/interfaces/dictionary';
import { StringUtil } from '../../../utils/string';
import { ExtendedPermission } from '../interfaces/extended-permission';
import { MappedRolesPermissions } from '../interfaces/mapped-roles-permissions';
import { PermissionActivityAccess } from '../interfaces/permission-activity-access';

function getPermissionAccess(
  activity: PermissionActivityDescription,
  permission: UserPermission,
): PermissionActivityAccess {
  return activity.readWriteAccess
    ? getReadWritePermissionAccess(permission)
    : PermissionActivityAccess.Enabled;
}

function getReadWritePermissionAccess(permission: UserPermission): PermissionActivityAccess {
  return permission.endsWith(PermissionActivityAccess.Edit)
    ? PermissionActivityAccess.Edit
    : PermissionActivityAccess.View;
}

function getRolePermissions(
  role: Role,
  activities: PermissionActivityDescription[],
  allowedPermissions: UserPermission[],
  permissionToImplicitPermissions: NumberDictionary<UserPermission[]>,
): StringDictionary<ExtendedPermission> {
  const roleActivityPermissions: StringDictionary<ExtendedPermission> = {};

  activities.forEach(activity => {
    let activityAccess = PermissionActivityAccess.NoAccess;

    const availableActivityPermissionsInRole = role.permissions.filter(x =>
      activity.permissions.includes(x),
    );
    if (availableActivityPermissionsInRole.length > 0) {
      activityAccess = availableActivityPermissionsInRole.map(x =>
        getPermissionAccess(activity, x),
      )[availableActivityPermissionsInRole.length - 1];
    }

    const availableAccess = {};
    const relatedActivitiesAccess = [];

    for (const permission of activity.permissions) {
      const implicitPermissions: UserPermission[] =
        permissionToImplicitPermissions[StringUtil.lowerize(permission)];
      const permissionAccess = getPermissionAccess(activity, permission);

      availableAccess[permissionAccess] = implicitPermissions
        ? [...new Set([...implicitPermissions, permission])]
        : [permission];

      if (implicitPermissions) {
        implicitPermissions.forEach(implicitPermission => {
          const implicitActivity = activities.find(x => x.permissions.includes(implicitPermission));
          if (!relatedActivitiesAccess.some(x => x.activity === implicitActivity.activity)) {
            relatedActivitiesAccess.push({
              activity: implicitActivity.activity,
              access: getPermissionAccess(implicitActivity, implicitPermission),
            });
          }
        });
      }
    }
    availableAccess[PermissionActivityAccess.NoAccess] = [];

    roleActivityPermissions[activity.activity] = {
      activityAccess,
      availableAccess,
      isBlocked: !allowedPermissions.some(x => activity.permissions.includes(x)),
      relatedActivitiesAccess,
    };
  });

  return roleActivityPermissions;
}

function getRolesPermissions(subscriptionRoles: CompanyRoles): MappedRolesPermissions {
  if (!subscriptionRoles) {
    return null;
  }

  const {
    roleGroups,
    roles,
    activities,
    roleGroupToAllowedPermissions,
    permissionToImplicitPermissions,
  } = subscriptionRoles;
  const rolesPermissions: Record<number, Record<string, ExtendedPermission>> = {};
  roles.map(r => {
    rolesPermissions[r.id] = getRolePermissions(
      r,
      activities,
      roleGroupToAllowedPermissions[r.group],
      permissionToImplicitPermissions,
    );
  });

  return {
    roleGroups,
    roles,
    activities,
    permissions: rolesPermissions,
  };
}

export const PermissionUtil = {
  getRolePermissions,
  getRolesPermissions,
};
