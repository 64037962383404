import React from 'react';

import { ProjectCreateStatus } from 'common/enums/project-create-status';
import { RequestStatus } from 'common/enums/request-status';
import { CompanyProjectHeader } from 'unit-projects/interfaces/company-project-header';
import { ProjectsView } from '../constants';
import { ProjectsGrid } from '../projects-grid';
import { ProjectsList } from '../projects-list';


const TypeRenderer = {
  [ProjectsView.Grid]: ProjectsGrid,
  [ProjectsView.List]: ProjectsList,
};

interface Props {
  loaded: boolean;
  allFetched: boolean;
  projectTypeName: string;
  isAdminMode: boolean;
  projectHeaders: Record<number, CompanyProjectHeader>;
  projectHeadersRequestStatus: RequestStatus;
  projectsViewType: ProjectsView;
  fetchProjects: () => void;
  onLeaveProject: (projectId: number, projectCreateStatus: ProjectCreateStatus) => void;
  onRemoveProject: (projectId: number, projectCreateStatus: ProjectCreateStatus) => void;
  onInviteToProjectClick: (projectId: number) => void;
  onOpenEditProjectNameDialog: (projectId: number) => void;
  onOpenDuplicateProjectDialog: (projectId: number) => void;
  openMoveToFolderDialog: (projectId: number) => void;
  saveProjectAsTemplate: (projectId: number) => void;
}

const ProjectsRendererComponent: React.FC<Props> = (props) => {
  const {
    loaded,
    allFetched,
    projectTypeName,
    projectHeaders,
    projectHeadersRequestStatus,
    isAdminMode,
    projectsViewType,
    fetchProjects,
    onRemoveProject,
    onLeaveProject,
    onInviteToProjectClick,
    onOpenEditProjectNameDialog,
    onOpenDuplicateProjectDialog,
    openMoveToFolderDialog,
    saveProjectAsTemplate,
  } = props;
  const Renderer = TypeRenderer[projectsViewType];

  return (
    <Renderer
      projectHeadersRequestStatus={projectHeadersRequestStatus}
      isAdminMode={isAdminMode}
      loaded={loaded}
      projectTypeName={projectTypeName}
      projectHeaders={projectHeaders}
      allFetched={allFetched}
      fetchProjects={fetchProjects}
      onRemoveProject={onRemoveProject}
      onLeaveProject={onLeaveProject}
      onInviteToProjectClick={onInviteToProjectClick}
      onOpenEditProjectNameDialog={onOpenEditProjectNameDialog}
      onOpenDuplicateProjectDialog={onOpenDuplicateProjectDialog}
      openMoveToFolderDialog={openMoveToFolderDialog}
      saveProjectAsTemplate={saveProjectAsTemplate}
    />
  );
};

export const ProjectsRenderer = React.memo(ProjectsRendererComponent);
