import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { DrawingsGeometryInstance } from '../../interfaces';

export function useColor(): [string, (color: string) => void] {
  const instancesIds = useSelector<State, string[]>(s => s.drawings.instanceToOperateWith);
  const instances = useSelector<State, Record<string, DrawingsGeometryInstance>>(s => s.drawings.aiAnnotation.geometry);
  const defaultColor = useMemo(() => {
    if (instancesIds.length === 0) {
      return null;
    }
    const startColor = instances[instancesIds[0]].geometry.color;
    for (const id of instancesIds) {
      const currentColor = instances[id].geometry.color;
      if (currentColor !== startColor) {
        return null;
      }
    }
    return startColor;
  }, [instances, instancesIds]);

  return useState<string>(defaultColor);
}
