export enum NotificationType {
  None = 'None',

  CompanySubscriptionChanged = 'CompanySubscriptionChanged',
  CompanyBillingInfoChanged = 'CompanyBillingInfoChanged',
  CompanySubscriptionUsersChanged = 'CompanySubscriptionUsersChanged',

  DatabaseDuplicatePassed = 'DatabaseDuplicatePassed',
  DatabaseDuplicateFailed = 'DatabaseDuplicateFailed',
  DatabaseImportPassed = 'DatabaseImportPassed',
  DatabaseImportFailed = 'DatabaseImportFailed',
  DatabaseCreated = 'DatabaseCreated',
  DatabaseDeleted = 'DatabaseDeleted',

  ViewerAddComment = 'ViewerAddComment',
  ViewerAddSubComment = 'ViewerAddSubComment',
  ViewerRemoveComment = 'ViewerRemoveComment',
  ViewerUpdateCommentStatus = 'ViewerUpdateCommentStatus',
  ViewerNotification = 'ViewerNotification',

  ViewModelChanged = 'ViewModelChanged',
}
