import { diff } from 'deep-object-diff';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AssignedPia } from '2d/interfaces';
import { DrawingsGeometryGroup } from 'common/components/drawings';
import { State } from 'common/interfaces/state';
import { getAssignByGroup } from '../measure-panel/hooks/use-assign';

export const useDiffAssign = (parentId: string, currentId: string, inheritId: string): any => {
  const assign = useSelector<State, Record<string, AssignedPia>>(s => s.twoD.assignPia);
  const groups = useSelector<State, DrawingsGeometryGroup[]>(s => s.drawings.drawingGeometryGroups);

  return useMemo(() => {
    const [parentAssemblies, parentItems] = getAssignByGroup(parentId, groups, assign);
    const parentAssign = {
      items: parentItems,
      assemblies: parentAssemblies,
    };
    const currentAssign = assign[currentId];
    const diffAssign: any = diff(parentAssign, currentAssign);
    const hoverEntities = [];
    if (!diffAssign) {
      return [];
    }
    if (diffAssign.items && currentAssign.items) {
      for (const [key, value] of Object.entries(diffAssign.items)) {
        const item = currentAssign.items[key];
        if (!item) {
          continue;
        }
        const parentItem = parentAssign?.items?.find(i => i.name === item.name);
        if (!parentItem) {
          continue;
        }
        const itemName = item?.name;
        if (!value || !(value as any).properties) {
          continue;
        }
        for (const [pk] of Object.entries((value as any).properties)) {
          const property = item?.properties[pk];
          const propertyName = property?.name;
          hoverEntities.push({ itemName, propertyName, assemblyName: '', inheritId });
        }
      }
    }

    if (diffAssign.assemblies && currentAssign.assemblies) {
      for (const [ak, av] of Object.entries(diffAssign.assemblies)) {
        if (!av || !(av as any).items) {
          continue;
        }
        const assembly = currentAssign.assemblies[ak];
        if (!assembly) {
          continue;
        }
        const parentAssembly = parentAssign?.assemblies?.find(a => a.name === assembly.name);
        if (!parentAssembly) {
          continue;
        }
        const assemblyName = assembly?.name;
        for (const [key, value] of Object.entries((av as any).items)) {
          const item = assembly?.items[key];
          const itemName = item?.name;
          if (!value || !(value as any).properties) {
            continue;
          }
          for (const pk of Object.keys((value as any).properties)) {
            const property = item?.properties[pk];
            const propertyName = property?.name;
            hoverEntities.push({ assemblyName, itemName, propertyName, inheritId });
          }
        }
      }
    }
    return hoverEntities;
  }, [parentId, currentId, assign, inheritId, groups]);
};
