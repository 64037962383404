import * as React from 'react';

export const AlertSuccess: React.FC = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/24x24/Alert_Success'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <circle id='Oval-3' fill='#68CC52' cx='12' cy='12' r='10'/>
        <path
          d='M15.2928932,9.29289322 C15.6834175,8.90236893 16.3165825,8.90236893 16.7071068,9.29289322
          C17.0976311,9.68341751 17.0976311,10.3165825 16.7071068,10.7071068 L11.7071068,15.7071068
          C11.3165825,16.0976311 10.6834175,16.0976311 10.2928932,15.7071068 L7.29289322,12.7071068
          C6.90236893,12.3165825 6.90236893,11.6834175 7.29289322,11.2928932 C7.68341751,10.9023689
          8.31658249,10.9023689 8.70710678,11.2928932 L11,13.5857864 L15.2928932,9.29289322 Z'
          id='Path-4'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
