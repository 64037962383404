const prefix = '@drawings-user-annotation';

export const DrawingsUserAnnotationActionTypes = {
  ADD_STICKER: `${prefix}/ADD_STICKER`,
  ADD_STICKER_SUCCEED: `${prefix}/ADD_STICKER_SUCCEED`,

  CHANGE_STICKER_TEXT: `${prefix}/CHANGE_STICKER_TEXT`,
  REMOVE_INSTANCES: `${prefix}/REMOVE_INSTANCES`,
  REMOVE_INSTANCES_FROM_STATE: `${prefix}/REMOVE_INSTANCES_FROM_STATE`,
  SELECT_ANNOTATION: `${prefix}/SELECT_ANNOTATION`,
  UPDATE_STICKERS_POSITIONS: `${prefix}/UPDATE_STICKERS_POSITIONS`,
  ADD_IMAGES: `${prefix}/ADD_IMAGES`,
  ADD_RULERS: `${prefix}/ADD_RULERS`,
  CHANGE_COLORS: `${prefix}/CHANGE_COLORS`,
  UPDATE_IMAGES_POSITIONS: `${prefix}/UPDATE_IMAGES_POSITION`,
  UPDATE_IMAGE_PARAMETER: `${prefix}/UPDATE_IMAGE_PARAMETER`,
  UPDATE_RULER_POINTS: `${prefix}/UPDATE_RULER_POINTS`,
  UPDATE_LEGEND_POSITION: `${prefix}/UPDATE_LEGEND`,
  REMOVE_LEGEND: `${prefix}/REMOVE_LEGEND`,
  GET_ANNOTATIONS: `${prefix}/GET_ANNOTATIONS`,
  GET_ANNOTATIONS_SUCCESS: `${prefix}/GET_ANNOTATIONS_SUCCESS`,

  TOGGLE_HAS_UPDATE: `${prefix}/TOGGLE_HAS_UPDATE`,
  NEXT_UPDATE: `${prefix}/NEXT_UPDATE`,

  CACHE_GROUP_VALUES: `${prefix}/CACHE_GROUP_VALUES`,

  SET_TEMP_STICKER_POSITION: `${prefix}/SET_TEMP_STICKER_POSITION`,
  SET_TEMP_STICKER_HAS_TEXT_STATUS: `${prefix}/SET_TEMP_STICKER_HAS_TEXT_STATUS`,
};
