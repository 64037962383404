import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { Field } from 'redux-form';

import { Location } from 'common/interfaces/common-state';
import { State as ReduxState } from 'common/interfaces/state';
import { MaterialMenuItem, MaterialSelectField, MaterialSelectProps } from 'common/UIKit';
import { Common } from '../../../actions';

import './location-menu.scss';

interface ReduxProps {
  locations: Location[];
}

interface ReduxActions {
  getLocations: () => void;
}

interface Props extends ReduxProps, ReduxActions {
  validate: Array<(value: string) => string>;
}

class LocationMenuComponent extends React.Component<Props> {
  public componentDidMount(): void {
    if (this.props.locations.length === 0) {
      this.props.getLocations();
    }
  }

  public render(): React.ReactNode {
    const { locations, validate } = this.props;
    return (
      <Field<MaterialSelectProps>
        component={MaterialSelectField}
        name='locationId'
        label='Location'
        validate={validate}
        autoWidth={true}
        className='location-menu__material-select'
      >
        {
          locations &&
          locations.map((location, index) => {
            return (
              <MaterialMenuItem key={index} value={location.id}>{location.name}</MaterialMenuItem>
            );
          })
        }
      </Field>
    );
  }
}

function mapStateToProps(state: ReduxState): ReduxProps {
  return {
    locations: state.common.locations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): ReduxActions {
  return {
    getLocations: () => {
      dispatch(Common.getLocationsRequest());
    },
  };
}

export const LocationMenu = connect(mapStateToProps, mapDispatchToProps)(
  LocationMenuComponent,
);
