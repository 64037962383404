import { MenuItem } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';


export interface Item {
  icon: JSX.Element;
  text: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface Props {
  item: Item;
  sizeItem: string;
  onExit: () => void;
}

export const MenuItemContainer: React.FC<Props> = ({ item, sizeItem, onExit }) => {
  const { icon, text, onClick } = item;

  const onMenuItemHandelClick = useCallback((e) => {
    onExit();
    onClick(e);
  }, [onExit, onClick]);

  return (
    <MenuItem
      Icon={icon}
      text={text}
      size={sizeItem}
      onClick={onMenuItemHandelClick}
    />
  );
};
