import { Icons, MenuItem } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ColorsName } from 'common/enums/kreo-colors';
import { KreoDialogActions } from 'common/UIKit/dialogs';
import { SHORTCUT_DIALOG_NAME } from 'components/dialog/shortcut-dialog';


interface DispatchProps {
  openDialog: (dialogName: string) => void;
}

interface Props extends DispatchProps {

}


const ShortcutsButtonComponent: React.FC<Props> = ({ openDialog }) => {
  const onOpenShortcutDialog = useCallback(() => {
    openDialog(SHORTCUT_DIALOG_NAME);
  }, [openDialog]);

  return (
    <MenuItem
      Icon={Icons.Shortcut2D}
      text='Shortcuts'
      onClick={onOpenShortcutDialog}
      fontSize={14}
      size='m'
      textColor={ColorsName.mainFont}
      withBorder={true}
      withoutWaves={true}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    openDialog: (dialogName) => dispatch(KreoDialogActions.openDialog(dialogName)),
  };
};

export const ShortcutsButton = connect(null, mapDispatchToProps)(ShortcutsButtonComponent);
