import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ResourcesData } from '../../../interfaces/gantt-chart';
import {
  ResourceType,
} from '../../../utils/gantt-chart';
import { ResourceSelectorContentLabours } from './resource-selector-content-labours';
import { ResourceSelectorContentPlant } from './resource-selector-content-plant';
import { ResourceSelectorContentTab } from './resource-selector-content-tab';
import { SelectBody } from './select-body';


interface OwnProps {
  open: boolean;
  selectedResourceId: number;
  onClose: () => void;
  onSelectResources: (id: number, selectedType: ResourceType, resourceName: string) => void;
}

interface StateProps {
  resources: ResourcesData;
}

interface Props extends OwnProps, StateProps { }


interface ResourceSelectorContentBodyState {
  selectedType: ResourceType;
}

class ResourceSelectorContentBodyComponent extends React.Component<Props, ResourceSelectorContentBodyState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedType: ResourceType.Labour,
    };
  }

  public render(): React.ReactNode {
    return (
      <SelectBody
        onCollapseExpand={this.props.onClose}
        open={this.props.open}
        contentContainerClassName='gantt-resources-chart-container-resource-selector__body'
      >
        <div className='gantt-resources-chart-container-resource-selector__body__tab-container'>
          <ResourceSelectorContentTab
            isSelected={ResourceType.Labour === this.state.selectedType}
            type={ResourceType.Labour}
            onSelect={this.onSelectResourceType}
          >
            Labours
          </ResourceSelectorContentTab>
          <ResourceSelectorContentTab
            isSelected={ResourceType.Plant === this.state.selectedType}
            type={ResourceType.Plant}
            onSelect={this.onSelectResourceType}
          >
            Plant
          </ResourceSelectorContentTab>
        </div>
        {this.getBody()}
      </SelectBody>
    );
  }

  private getBody(): React.ReactNode {
    if (!this.props.resources) {
      return null;
    }
    if (this.state.selectedType === ResourceType.Labour) {
      return (
        <ResourceSelectorContentLabours
          selectedResourceId={this.props.selectedResourceId}
          onSelectAllLabours={this.onSelectAllLabours}
          onSelectItem={this.onSelectResource}
          resources={Array.from(this.props.resources[ResourceType.Labour].values())}
        />
      );
    } else {
      return (
        <ResourceSelectorContentPlant
          selectedResourceId={this.props.selectedResourceId}
          onSelectItem={this.onSelectResource}
          resources={Array.from(this.props.resources[ResourceType.Plant].values())}
        />
      );
    }
  }


  @autobind
  private onSelectAllLabours(): void {
    this.props.onSelectResources(-7, ResourceType.Labour, 'All Labours');
  }

  @autobind
  private onSelectResource(id: number, name: string): void {
    this.props.onSelectResources(id, this.state.selectedType, name);
  }

  @autobind
  private onSelectResourceType(selectedType: ResourceType): void {
    this.setState({ selectedType });
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    resources: state.fourDVisualisation.resources,
  };
};

export const ResourceSelectorContentBody = connect(mapStateToProps)(ResourceSelectorContentBodyComponent);
