import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyI: React.FC = () => {
  return (
    <svg
      width='20px'
      height='22px'
      viewBox='0 0 20 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='hotkey_i' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='I'>
          <g id='background'>
            <g id='Group' transform='translate(0.000000, 2.000000)'>
              <g id='path-1-link' fill={KreoColors.f1}>
                <rect id='path-1' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
            <g id='Group'>
              <g id='path-2-link' fill={KreoColors.f1}>
                <rect id='path-2' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
          </g>
          <rect id='padding-left' x='0' y='6' width='6' height='10' />
          <rect id='padding-right' x='14' y='6' width='6' height='10' />
          <path
            // eslint-disable-next-line max-len
            d='M10.5940004,13.2354999 C10.5940004,13.5269999 10.5885004,13.6204999 10.5060003,13.7745 C10.4180003,13.934 10.2365003,14.044 10.0000003,14.044 C9.76350023,14.044 9.5820002,13.934 9.49400018,13.7745 C9.41150017,13.6204999 9.40600017,13.5269999 9.40600017,13.2354999 L9.40600017,6.93249884 C9.40600017,6.64099879 9.41150017,6.54749878 9.49400018,6.39349875 C9.5820002,6.23399873 9.76350023,6.12399871 10.0000003,6.12399871 C10.2365003,6.12399871 10.4180003,6.23399873 10.5060003,6.39349875 C10.5885004,6.54749878 10.5940004,6.64099879 10.5940004,6.93249884 L10.5940004,13.2354999 Z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
};
