import pluralize from 'pluralize';

import { FilterOption } from './interfaces';

const getSelectAllOption = (entityName: string): FilterOption => ({
  value: null,
  name: `All ${pluralize.plural(entityName)}`,
  isSelectable: true,
  children: undefined,
});

const getSpinnerOption = ({
  value: null,
  name: undefined,
  optionType: 'spinner',
  isSelectable: false,
  children: undefined,
});

export const DefaultOptions = {
  getSelectAllOption,
  getSpinnerOption,
};
