import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  width: 210px;
  overflow: hidden;
`;

export const Styled = {
  Container,
};
