import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const ToggleItem = styled.div`
  width: 100%;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 20px;
  min-width: 210px;
  svg {
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }

  > ${Text} {
    margin-left: -5px;
    font-family: "Quicksand", Helvetica, Arial, sans-serif;
  }
`;

const TextOptimizeWrapper = styled.div`
  display: flex;

  > svg {
    margin-right: 15px;
  }

  > ${Text} {
    font-size: 14px;
    line-height: 21px;
    font-family: "Quicksand", Helvetica, Arial, sans-serif;
  }
`;

const Splitter = styled.div`
  border-bottom: 10px solid ${p => p.theme.color.pale};
`;

export const Styled = {
  ToggleItem,
  TextOptimizeWrapper,
  Splitter,
};
