import { Icons, H6, RoundButton } from '@kreo/kreo-ui-components';
import React from 'react';

import { RenderIf } from 'common/components/render-if';
import { ProjectAccessFilter } from '../project-access-filter/project-access-filter';
import { ProjectsDisplayFormatButtons } from '../projects-display-format-buttons';
import { Styled } from '../styled';

interface Props {
  showFilter: boolean;
  title: string;
  accessFilterValue: string;
  accessFilterAvailableValues: string[];
  createButtonDisabled: boolean;
  setProjectAccessFilter: (filterName: string) => void;
  openCreateProjectDialog: () => void;
}

export const ProjectsTitle: React.FC<Props> = ({
  title,
  accessFilterValue,
  accessFilterAvailableValues,
  showFilter,
  createButtonDisabled,
  setProjectAccessFilter,
  openCreateProjectDialog,
}) => {
  return (
    <Styled.ProjectsHeader>
      <Styled.CountTitleWrapper>
        <RoundButton
          Icon={Icons.PlusSmall}
          size='s'
          onClick={openCreateProjectDialog}
          mood={createButtonDisabled ? 'disabled' : 'secondary'}
          iconHeight={10}
          diameter={20}
        />
        <H6>{title}</H6>
      </Styled.CountTitleWrapper>
      <Styled.FilterAndViewWrapper>
        <RenderIf condition={showFilter}>
          <ProjectAccessFilter
            selectedFilter={accessFilterValue}
            availableFilters={accessFilterAvailableValues}
            onSelectFilter={setProjectAccessFilter}
          />
        </RenderIf>
        <ProjectsDisplayFormatButtons />
      </Styled.FilterAndViewWrapper>
    </Styled.ProjectsHeader>
  );
};

