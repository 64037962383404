import { ColDef, Column, RowNode, ColumnState } from 'ag-grid-community';

import { AbilityAwareProps } from 'common/ability/with-ability-context';
import { SyncStatus } from 'common/enums/sync-status';
import { KreoEngine } from '../../../../components/engine/KreoEngine';
import { MapIdHelper } from '../../../../components/engine/map-id-helper';
import { QtoSelectElementsEventSource } from '../../enums/qto-select-elements-event-source';
import { GraphStorageRecordsConfig } from '../../interfaces/graph-storage-records-config';
import { PropertiesDataProviderApi } from '../../interfaces/properties-provider-types';
import { ModelType, QtoRecords } from '../../interfaces/quantity-take-off';
import { LeftPanelTablesConfigs } from './get-config';

export type RowData = Record<string, string | number>;

export interface QtoRecord {
  id: string | number;
  props: Record<string, Array<string | number>>;
}

export interface TableData {
  columnDefs: ColDef[];
  rowData: RowData[];
  context: Context;
}

export interface Context {
  isImperialUnit: boolean;
}

export interface QtoLeftPanelState {
  autoGroupColumnDef: ColDef;
  elementColumnConfig: ColDef[];
  elementRowData: RowData[];
  elementContext: Context;
  elementFilteredIds: number[];
  showElementData: RowData[];
  tableElement: JSX.Element;
  isElementOpen: boolean;
  isUpdating: boolean;
  locationColumnConfig: ColDef[];
  locationRowData: RowData[];
  locationContext: Context;
  isLocationOpen: boolean;
  tableLocation: JSX.Element;
  locationFilteredIds: number[];
  showLocationData: RowData[];
  elementRecords: QtoRecord[];
  locationRecords: QtoRecord[];
  isLoading: boolean;
  className: string;
  secondaryPaneSize: number;
  elementExpandRowsStatus?: boolean;
  locationExpandRowsStatus?: boolean;
  useEngineFilter: boolean;
}

export interface QtoLeftPanelProps extends PropertiesDataProviderApi, AbilityAwareProps {
  modelType: ModelType;
  userExpand: () => void;
  selectElementsEventSource: QtoSelectElementsEventSource | null;
  currentProjectId: number;
  recordsConfig: GraphStorageRecordsConfig;
  elementRecords: QtoRecords;
  onElementTableSelected: (bimIds: number[]) => void;
  sendElementTableApi: (ref: TableApi) => void;
  isLocation: boolean;
  onFilter: (bimIds: number[]) => void;
  height: number;
  engine: KreoEngine;
  mapIdHelper: MapIdHelper;
  expandTable?: boolean;
  loadRecords: (projectId: number) => void;
  getReportElementIds: () => { nodeIds: Record<string, boolean>, duplicatedNodeIds: Record<string, boolean> };
  isImperialUnit: boolean;
  onReady: () => void;
  locationsViewChange: (ids: number[], status: boolean) => void;
  elementViewChange: (ids: number[], status: boolean) => void;
  syncWithElementsDataInReport: (nodes: RowNode[]) => void;
  disableEngineFilter: () => void;
  changeSyncStatus: (source: QtoSelectElementsEventSource, syncStatus: SyncStatus) => void;
  visibleElementIds: Record<number, boolean>;
  visibleClipBoxElements: number[];
  recordValuesMap: Record<string, Record<string, void>>;
  pivotEnabled: boolean;
  onCustomFiltersSelect: (ids: string[]) => void;
  sendCustomFiltersSelectionApi: (ref: TableApi) => void;
  isPageDataReady: boolean;
}

export interface TableApi {
  setSelected?: (ids: Array<number | string>, isExpand?: boolean) => void;
  focusAndExpand?: (ids: Array<string | number>) => void;
  getSelectedValue?: () => number[];
  setColumnDefs?: (colDefs: ColDef[]) => void;
  getColumnState?: () => ColumnState[];
  getElementIds?: () => { nodeIds: Record<string, boolean>, duplicatedNodeIds: Record<string, boolean> };
  refreshCells?: () => void;
  setSelectedAfterUpdateRecords?: (ids: Array<number | string>) => void;
  getColumn?: (id: string) => Column;
  getRowNodes?: (ids: Array<string | number>) => RowNode[];
  showLoadingCapture?: (show: boolean) => void;
}

export interface TableState {
  leftPanelTableConfigs: LeftPanelTablesConfigs;
  showElementData: RowData[];
  showLocationData: RowData[];
  elementIds: number[];
  locationIds: number[];
}

export interface SelectUserExtractorResult {
  extractorId: number;
  extractorName: string;
  extractorKey: string;
}

export interface SelectSharedParametersResult {
  value: string;
  name: string;
  groupName: string;
}

export enum RecordsUpdateType {
  StartUpdateUserExtractor,
  FinishUpdateUserExtractor,
  UpdateSharedParameter,
}
