import React from 'react';
import { useAbilityContext } from 'common/ability/use-ability-context';
import { AbilityAwareProps } from 'common/ability/with-ability-context';
import { DrawingsRendererApiContextProps, useRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { useDuplicateInstancesToPage } from 'common/components/drawings/hooks/geometries';
import { SaveDrawModeOptions } from 'common/components/drawings/interfaces';
import { UndoRedoContextApiProps, useUndoRedoFullApi } from 'common/undo-redo';
import { AnalyticsProps, useAnalytics } from 'utils/posthog';

export interface WithDrawingContext extends
UndoRedoContextApiProps,
AbilityAwareProps,
AnalyticsProps,
DrawingsRendererApiContextProps {
  duplicateToPage: (instancesIds: string[], pageId: string) => void;
  setDrawMode: (mode: DrawingsDrawMode, options: SaveDrawModeOptions) => void;
}

export function withContext<P extends WithDrawingContext>(
  Component: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithDrawingContext>> {
  return function WithContext(props: Omit<P, keyof WithDrawingContext>) {
    const duplicateToPage = useDuplicateInstancesToPage();
    const undoRedoApi = useUndoRedoFullApi();
    const abilityContext = useAbilityContext();
    const rendererApi = useRendererApiContext();
    const analytics = useAnalytics();
    const { setDrawMode } = useDrawModeApi();
    return (
      <Component
        {...props as P}
        duplicateToPage={duplicateToPage}
        {...undoRedoApi}
        {...abilityContext}
        {...rendererApi}
        {...analytics}
        setDrawMode={setDrawMode}
      />
    );
  };
}
