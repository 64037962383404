import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNewInstancesSetting } from 'common/components/drawings/hooks';
import { DrawingsGeometryGroup } from 'common/components/drawings/interfaces';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { State } from 'common/interfaces/state';
import { getClosestGroupWithColor } from '../utils';

export function useNewInstanceColor(selectedGroup: DrawingsGeometryGroup): [
  color: string,
  setColor: (color: string) => void
] {
  const groups = useSelector<State, DrawingsGeometryGroup[]>(state => state.drawings.drawingGeometryGroups);

  const [newDrawingColor, setColor ] = useNewInstancesSetting('color');
  useEffect(() => {
    const groupColor = selectedGroup && getClosestGroupWithColor(selectedGroup, groups)?.color;
    if (groupColor) {
      if (newDrawingColor !== groupColor) {
        setColor(groupColor);
      }
    } else if (!newDrawingColor) {
      setColor(DrawingsCanvasUtils.getColorFromList());
    }
  }, [selectedGroup, groups]);

  return [newDrawingColor, setColor];
}
