import { ColorPickerItem, ContainerWithContextMenu } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { ContextMenuItem } from '../context-menu';

interface Props {
  color: string;
  isSelected: boolean;
  onColor: (color: string) => void;
  deleteColor: (color: string) => void;
}

export class CustomColorItem extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { color, isSelected, onColor } = this.props;

    return (
      <ContainerWithContextMenu renderContextMenuContent={this.renderContextMenuContent}>
        <ColorPickerItem
          color={color}
          isSelected={isSelected}
          onColor={onColor}
        />
      </ContainerWithContextMenu>
    );
  }

  @autobind
  private renderContextMenuContent(): React.ReactNode {
    return (
      <ContextMenuItem onClick={this.deleteColor}>
        Remove
      </ContextMenuItem>
    );
  }

  @autobind
  private deleteColor(): void {
    this.props.deleteColor(this.props.color);
  }
}
