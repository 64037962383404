import styled from 'styled-components';

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  
  &, * {
    box-sizing: border-box;
  }
`;

const RadioButtonStyle = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${props => (props.checked ? props.theme.color.turquoise : props.theme.color.disabled)};
  transition: all 0.175s cubic-bezier(0.25, 0.1, 0.25, 1);
  background-color: ${props => (props.checked ? props.theme.color.turquoise : props.theme.color.background)};
  box-shadow: ${props => props.checked ? `inset 0 0 0 4px ${props.theme.color.background}` : 'none'};

  :hover {
    background-color: ${props => props.checked ? props.theme.color.turquoise : props.theme.color.disabled};
    box-shadow: ${props => `inset 0 0 0 4px ${props.theme.color.background}`};
  }
`;

export const Styled = {
  Container,
  RadioButtonStyle,
};
