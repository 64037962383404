import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { WizzardToolsActions } from '../../../../actions/creators';
import { WizzardStatus } from '../../../../enums/dropper-state';
import { PdfGeometry } from '../../../../interfaces/api-responses/pdf-geometry-response';

interface AggregatedSelectedGeometries {
  areas: number[];
  lines: number[];
}

interface EraserRedux {
  applyEraser: () => void;
  cancelEraser: () => void;
  exit: () => void;
  hasGeometriesToDelete: boolean;
  aggregatedSelectedGeometries: AggregatedSelectedGeometries;
}

export function useEraserRedux(): EraserRedux {
  const dispatch = useDispatch();
  const geometriesToDelete = useSelector<State, number[]>(x => x.drawings.wizzardTools.selectedToRemove);
  const selectedGeometries = useSelector<State, PdfGeometry[]>(x => x.drawings.wizzardTools.finderSelectedGeometries);
  const aggregatedSelectedGeometries = useMemo<AggregatedSelectedGeometries>(() => {
    const geometreisToDeleteSet = new Set(geometriesToDelete);
    const filtered = arrayUtils.filterIterator(selectedGeometries, x => !geometreisToDeleteSet.has(x.id));
    const aggregated = arrayUtils.groupByWithMap(filtered, x => x.isClosed ? 'areas' : 'lines', x => x.id);
    return aggregated;
  }, [selectedGeometries, geometriesToDelete]);
  return {
    hasGeometriesToDelete: !!geometriesToDelete.length,
    aggregatedSelectedGeometries,
    applyEraser: useCallback(() => dispatch(WizzardToolsActions.applyEraser()), []),
    cancelEraser: useCallback(() => dispatch(WizzardToolsActions.dropEraser()), []),
    exit: useCallback(() => {
      dispatch(WizzardToolsActions.dropEraser());
      dispatch(WizzardToolsActions.setWizzardState(WizzardStatus.Start));
    }, []),
  };
}
