const prefix = '@common';

export const CommonActionTypes = {
  UPLOAD_FILES: `${prefix}/UPLOAD_FILES`,
  UPLOAD_FILES_ADD: `${prefix}/UPLOAD_FILES_ADD`,
  UPLOAD_FILE_REMOVE: `${prefix}/UPLOAD_FILE_REMOVE`,
  UPLOAD_FILE_SUCCESSED: `${prefix}/UPLOAD_FILE_SUCCESSED`,
  UPLOAD_FILE_FAILED: `${prefix}/UPLOAD_FILE_FAILED`,
  REMOVE_ALL_FILES: `${prefix}/FILES_CLEAR_ALL`,
  DOWNLOAD_FILE: `${prefix}/DOWNLOAD_FILE`,
  REMOVE_DOWNLOAD_FILE_NAME: `${prefix}/REMOVE_DOWNLOAD_FILE_NAME`,
  GET_LOCATIONS: `${prefix}/GET_LOCATIONS`,
  GET_LOCATIONS_SUCCESSED: `${prefix}/GET_LOCATIONS_SUCCESSED`,
  FILE_PROGRESS: `${prefix}/UPLOAD_FILE_PROGRESS`,
  SET_REPORTS_TEXT: `${prefix}/SET_REPORTS_TEXT`,
  PUSH_SEARCH: `${prefix}/PUSH_SEARCH`,
};
