import React, { Dispatch, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { State } from 'common/interfaces/state';
import { useUndoRedoFullApi } from 'common/undo-redo';
import { UuidUtil } from 'common/utils/uuid-utils';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { DrawingsUserAnnotationActions } from '../../actions/creators/user-annotation';
import { DrawingsGeometryDragEventHelper } from '../../drawings-geometry';
import { DrawingsDrawMode } from '../../enums';
import { ShortPointDescription } from '../../interfaces';
import { DrawingUserAnnotationSticker } from '../../interfaces/drawings-user-annotation';
import { Sticker } from './sticker';
import { STICKER_BACKGROUND_DEFAULT } from './styled';
import { DrawingsStickerAPI } from './utils';

export const TEMP_STICKER_ID = 'tempSticker';

interface StateProps {
  stickers: Record<string, DrawingUserAnnotationSticker>;
  selectedAnnotations: string[];
}

interface DispatchProps {
  addSticker: (sticker: DrawingUserAnnotationSticker, drawingId: string) => void;
  changeStickerText: (id: string, text: string) => void;
}

interface OwnProps {
  drawMode: DrawingsDrawMode;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
  currentDrawingId: string;
  canEditUserAnnotations: boolean;
  currentUserId: string;
  tempPosition: ShortPointDescription;
  saveStickerRef: (ref: DrawingsStickerAPI, id: string) => void;
  removeTempSticker: () => void;
  stopDrag: () => void;
  startDragSticker?: (id: string, startPosition: paper.Point) => void;
  removeStickers: (ids: string[], pageId: string) => void;
}

interface Props extends StateProps, OwnProps, DispatchProps {

}

const StickersListComponent: React.FC<Props> = ({
  stickers,
  selectedAnnotations,
  canEditUserAnnotations,
  tempPosition,
  currentDrawingId,
  dragEventsHelper,
  currentUserId,
  removeStickers,
  saveStickerRef,
  changeStickerText,
  startDragSticker,
  stopDrag,
  removeTempSticker,
  addSticker,
}) => {

  const { sendEvent } = useAnalytics();
  const { addUndoRedo } = useUndoRedoFullApi();
  const selectedSet = useMemo(() => new Set(selectedAnnotations), [selectedAnnotations]);

  const removeSticker = useCallback((id) => {
    removeStickers([id], currentDrawingId);
  }, [canEditUserAnnotations, currentDrawingId]);

  const createSticker = useCallback((_id, text: string) => {
    const sticker = {
      id: UuidUtil.generateUuid(),
      text,
      position: tempPosition,
      drawingId: currentDrawingId,
      color: STICKER_BACKGROUND_DEFAULT,
    };
    removeTempSticker();
    const undo = (): void => removeStickers([sticker.id], currentDrawingId);
    const redo = (): void => {
      addSticker(sticker, currentDrawingId);
      sendEvent(MetricNames.annotaion.createStiker);
    };
    addUndoRedo(undo, redo);
    redo();
  }, [sendEvent, addUndoRedo, currentDrawingId, currentDrawingId, tempPosition]);

  const hasTemp = !!tempPosition;

  return (<>
    {
      Object.entries(stickers).map(([id, sticker]) => {
        return (
          <Sticker
            disableOpen={hasTemp}
            selected={selectedSet.has(id)}
            text={sticker.text}
            sendStickerRef={saveStickerRef}
            id={sticker.id}
            key={sticker.id}
            createdAt={sticker.createdAt}
            creator={sticker.creatorId}
            canEdit={canEditUserAnnotations}
            removeSticker={removeSticker}
            changeStickerText={changeStickerText}
            dragEventsHelper={dragEventsHelper}
            startDragSticker={startDragSticker}
            stopDragEvent={stopDrag}
          />
        );
      })
    }
    {
      hasTemp ? (
        <Sticker
          text={''}
          id={TEMP_STICKER_ID}
          createdAt={new Date()}
          creator={currentUserId}
          sendStickerRef={saveStickerRef}
          canEdit={true}
          isTemp={true}
          removeSticker={removeTempSticker}
          changeStickerText={createSticker}
          dragEventsHelper={dragEventsHelper}
        />
      ) : null
    }
  </>);
};

function mapStateToProps(state: State): StateProps {
  return {
    stickers: state.drawings.userAnnotations.stickers,
    selectedAnnotations: state.drawings.userAnnotations.selectedAnnotations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    changeStickerText: (id, text) => dispatch(DrawingsUserAnnotationActions.changeStickerText(id, text)),
    addSticker: (sticker, drawingId) => {
      dispatch(DrawingsUserAnnotationActions.addSticker([sticker], drawingId));
    },
  };
}

export const StickersList = connect(mapStateToProps, mapDispatchToProps)(StickersListComponent);
