import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-revit-tree-item-container.scss';

import { State } from 'common/interfaces/state';
import { ClassificationActions } from '../../actions/creators/classification';
import { RevitTreeLevel } from '../../enums/revit-tree-level';
import { RevitTreeBaseItemNew } from '../revit-tree-base-item-new';
import { RevitTreeIconStatus } from '../revit-tree-icon-status';

interface DispatchProps {
  onExpand: () => void;
}


interface StateProps {
  level: RevitTreeLevel;
  expanded: boolean;
  lastInLevel: boolean;
  parentIsLast: boolean;
  firstInLevel: boolean;
  isError: boolean;
  isAugmentation: boolean;
}


interface OwnProps {
  index: number;
  selected: boolean;
  engineIdsStart: number;
  engineIdsEnd: number;
  minimumLevelInTree: RevitTreeLevel;
  className?: string;
  isTreeIsolated: boolean;
  isElementType: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  onHover: (start: number, end: number) => void;
  onFocus: (start: number, end: number) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {

}

class ClassificationRevitTreeItemContainerComponent extends React.PureComponent<Props> {
  private hovered: boolean = false;


  public componentWillUnmount(): void {
    if (this.hovered) {
      this.props.onHover(0, 0);
    }
  }

  public render(): React.ReactNode {
    const {
      level,
      selected,
      isElementType,
      minimumLevelInTree,
      className,
      parentIsLast,
      lastInLevel: lastInLvl,
      firstInLevel: firstInLvl,
      expanded,
      isError,
      isAugmentation,
    } = this.props;
    const containerClassName = classNames(
      'classification-revit-tree-item',
      {
        'classification-revit-tree-item--element-type': isElementType,
        'classification-revit-tree-item--selected': selected,
      },
      className,
    );
    return (
      <RevitTreeBaseItemNew
        className={containerClassName}
        level={level}
        minimumLevel={minimumLevelInTree}
        lastInLvl={lastInLvl}
        firstInLvl={firstInLvl}
        parentIsLast={parentIsLast}
        expandable={!isElementType}
        onDoubleClick={this.props.onExpand}
        mouseOver={this.onMouseOver}
        mouseOut={this.onMouseOut}
        onClick={this.onClick}
        onExpand={this.onExpand}
        expanded={expanded}
      >
        {this.props.children}
        <RevitTreeIconStatus error={isError} isAugmentation={isAugmentation} />
      </RevitTreeBaseItemNew>
    );
  }


  @autobind
  private onMouseOut(): void {
    this.hovered = false;
    this.props.onHover(0, 0);
  }

  @autobind
  private onMouseOver(): void {
    const { engineIdsEnd, engineIdsStart } = this.props;
    this.hovered = true;
    this.props.onHover(engineIdsStart, engineIdsEnd);
  }

  @autobind
  private onClick(): void {
    if (!this.props.selected) {
      const { engineIdsEnd, engineIdsStart } = this.props;
      this.props.onFocus(engineIdsStart, engineIdsEnd);
    }
    if (!this.props.expanded) {
      this.props.onExpand();
    }
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  @autobind
  private onExpand(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.props.onExpand();
  }
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const { index, isTreeIsolated } = ownProps;
  const { classification: { modelBrowserFiltered, modelTree } } = state;
  const item = (isTreeIsolated ? modelBrowserFiltered : modelTree)[index];
  const {
    level,
    expanded,
    isError,
    isAugmentation,
    firstEntityOfLevel,
    lastEntityOfLevel,
    isParentLastEntityOfLevel,
  } = item;
  return {
    level,
    expanded,
    firstInLevel: firstEntityOfLevel,
    lastInLevel: lastEntityOfLevel,
    parentIsLast: isParentLastEntityOfLevel,
    isError,
    isAugmentation,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
  return {
    onExpand: () => dispatch(ClassificationActions.toggleCollapseExpandStatus(ownProps.index)),
  };
}


const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationRevitTreeItemContainer = connector(ClassificationRevitTreeItemContainerComponent);
