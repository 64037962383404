import { SmallMenuList } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Item, MenuItemContainer } from './menu-item-container';


interface Props {
  menuItems: Item[];
  menuWidth: number;
  sizeItem: string;
  onExit: () => void;
}

export const ItemMenuContent: React.FC<Props> = ({
  menuItems,
  menuWidth = 210,
  sizeItem='mm',
  onExit,
}: Props) => {
  return (
    <SmallMenuList emergencePlace={'right top'} onExit={onExit} width={menuWidth}>
      {menuItems.map(item => {
        return (
          <MenuItemContainer
            key={item.text}
            item={item}
            sizeItem={sizeItem}
            onExit={onExit}
          />
        );
      })}
    </SmallMenuList>
  );
};
