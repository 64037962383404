import { AvatarStyled, RoundButtonStyle } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Wrapper = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }
  &, * {
    box-sizing: border-box;
    outline: none;
  }
`;

const Container = styled.div<{ image: string, error: boolean, isInaccessibleProject: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-image: ${p => !p.error && `url(${p.image})`};
  background-color: ${p => p.theme.color[p.error ? 'red' : 'background']};
  background-position: center;
  background-size:  cover;
  box-shadow: ${p => p.theme.shadow.xs};
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  box-sizing: border-box;
  aspect-ratio: 1 / 1;
  opacity: ${p => p.isInaccessibleProject ? 0.5 : 1};

  &:hover {
    box-shadow: ${p => p.theme.shadow.l};
  }
`;

const ProjectName = styled.div<{ isTranslucent: boolean }>`
  width: calc(100% - 10px);
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.color.backgroundRush};
  align-self: center;
  margin-top: auto;
  margin-bottom: 5px;
  padding: 0 10px;
  opacity: ${p => p.isTranslucent ? 0.5 : 1};

  > div:first-of-type {
    width: 100%;
  }
  > div {
    opacity: ${p => p.isTranslucent ? 0.5 : 1};
  }
`;

const AvatarsContainer = styled.div<{ height: number, isTranslucent: boolean, isUsers: boolean }>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${p => p.height}px;
  opacity: ${p => p.isTranslucent ? 0.5 : 1};

  > div {
    right: 5px;
    top: 5px;
    :last-child {
      margin-left: auto;
    }
  }

  ${AvatarStyled.Avatar} {
    position: relative;
    top: 5px;
    ${p => p.isUsers ? 'left' : 'right'}: 5px;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  width: 150px;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: 20px;
    fill: ${p => p.theme.color.white};
    margin-bottom: 15px;
  }
  button {
    margin-top: 15px;
  }

  > p {
    color: ${p => p.theme.color.white};
    font-size: 14px;
  }
`;

const MenuButtonContainer = styled.div<{ isTranslucent: boolean }>`
  position: absolute;
  align-self: flex-end;
  bottom: calc(50% - 15px);
  right: 5px;

  ${RoundButtonStyle} {
    opacity: ${p => p.isTranslucent ? 0.5 : 1};
  }
`;

export const Styled = {
  Wrapper,
  Container,
  AvatarsContainer,
  ProjectName,
  ErrorMessage,
  MenuButtonContainer,
};
