import { EventChannel, SagaIterator } from 'redux-saga';
import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
import { reloadWrapper } from 'common/utils/reload-wrapper';
import { createSagaEventsChannel, selectWrapper } from 'common/utils/saga-wrappers';
import { CompanyResourcesApi, ProjectResourcesApi } from '../../../api/server';
import { SignalRConnectionClient } from '../../../common/realtime/signalr';
import { CommentaryThread, Message } from '../interfaces';
import { TwoDCommentsActions } from '../store-slice';

enum EventsType {
  CreateThread = 'CreateThread',
  UpdateThread = 'UpdateThread',
  DeleteThread = 'DeleteThread',
  CreateMessage = 'CreateMessage',
  UpdateMessage = 'UpdateMessage',
  DeleteMessage = 'DeleteMessage',
  Resolve = 'Resolve',
  Open = 'Open',
  Disconnect = 'Disconnect',
}

function* createThread(data: CommentaryThread[]): SagaIterator {
  try {
    const [ comment ] = data;
    yield put(TwoDCommentsActions.addCommentIfNotExists(comment));
  } catch (e) {
    console.error('on create thread', data);
  }
}

function* updateThread([comment]: CommentaryThread[]): SagaIterator {
  try {
    if (yield selectWrapper(x => x.twoDComments.comments.find(c => c.id === comment.id))) {
      yield put(TwoDCommentsActions.commentUpdated(comment));
    } else {
      yield put(TwoDCommentsActions.addCommentIfNotExists(comment));
    }
  } catch (e) {
    console.error('on update thread', comment);
  }
}

function* deleteThread([commentId]: [number]): SagaIterator {
  try {
    yield put(TwoDCommentsActions.onCommentRemoved(commentId));
  } catch (e) {
    console.error('on delete thread', commentId);
  }
}

function* createMessage([commentId, subComment]: [number, Message]): SagaIterator {
  try {
    yield put(TwoDCommentsActions.addSubCommentOrUpdate({ commentId, subComment }));
  } catch (e) {
    console.error('on create message', commentId, subComment);
  }
}

function* updateMessage([commentId, subComment]: [number, Message]): SagaIterator {
  try {
    yield put(TwoDCommentsActions.subCommentUpdated({ commentId, subComment }));
  } catch (e) {
    console.error('on create message', commentId, subComment);
  }
}

function* deleteMessage([commentId, subCommentId]: [number, number]): SagaIterator {
  try {
    yield put(TwoDCommentsActions.onRemoveSubComment({ commentId, subCommentId }));
  } catch (e) {
    console.error('on delete message', commentId, subCommentId);
  }
}

function* open([commentId, subComment]: [number, Message]): SagaIterator {
  try {
    yield put(TwoDCommentsActions.addSubCommentOrUpdate({ commentId, subComment }));
    yield put(TwoDCommentsActions.changeResolveCommentStatus({ commentId, status: false }));
  } catch (e) {
    console.error('on create message', commentId, subComment);
  }
}

function* resolve([commentId, subComment]: [number, Message]): SagaIterator {
  try {
    yield put(TwoDCommentsActions.addSubCommentOrUpdate({ commentId, subComment }));
    yield put(TwoDCommentsActions.changeResolveCommentStatus({ commentId, status: true }));
  } catch (e) {
    console.error('on create message', commentId, subComment);
  }
}

function* disconnect([kickedUsers]: [string[] | null]): SagaIterator {
  try {
    if (
      (yield selectWrapper(x => x.projects.currentProject)) && (
        !kickedUsers
        || (yield selectWrapper(x => kickedUsers.includes(x.account.id)))
      )
    ) {
      reloadWrapper('user kicked from project');
    }
  } catch (e) {
    console.error('disconnect error', e, kickedUsers);
  }
}

const EventsHandlers: Record<EventsType, (...args: any[]) => SagaIterator> = {
  [EventsType.CreateThread]: createThread,
  [EventsType.UpdateThread]: updateThread,
  [EventsType.DeleteThread]: deleteThread,
  [EventsType.CreateMessage]: createMessage,
  [EventsType.UpdateMessage]: updateMessage,
  [EventsType.DeleteMessage]: deleteMessage,
  [EventsType.Open]: open,
  [EventsType.Resolve]: resolve,
  [EventsType.Disconnect]: disconnect,
};

interface WatchProps  {
  channel: EventChannel<any>;
  eventType: string;
}

function* watch({ channel, eventType }: WatchProps): SagaIterator {
  while (true) {
    const data = yield take(channel);
    yield fork(EventsHandlers[eventType], data);
  }
}

function* restartWatch(channel: EventChannel<any>): SagaIterator {
  yield take(channel);
  yield put(TwoDCommentsActions.realtimeDisconnected());
  yield put(TwoDCommentsActions.connectRealtime());
}

function* initCommentsRealtime(): SagaIterator {
  try {
    if (yield selectWrapper(x => x.twoDComments.realtimeConnection)) {
      yield put(TwoDCommentsActions.disconnectRealtime());
    }
    const connection = new SignalRConnectionClient();


    yield call(connection.init, '/api/hubs/comment-threads');

    const restartChannel = createSagaEventsChannel(emitter => connection.onCloseCallback = () => emitter('closed'));
    yield fork(restartWatch, restartChannel);

    const room = ProjectResourcesApi.getBaseUrl();
    const projectId = yield selectWrapper(x => x.projects.currentProject?.id);
    if (!projectId) {
      return;
    }
    yield call(connection.invoke, 'JoinProject', `${CompanyResourcesApi.getBaseUrl()}/projects/${projectId}`);
    yield put(TwoDCommentsActions.realtimeConnected({ connection, room }));
    for (const eventType of Object.keys(EventsHandlers)) {
      const channel = createSagaEventsChannel(emitter => connection.subscribe(eventType, (...args) => emitter(args)));
      yield fork<(props: WatchProps) => SagaIterator>(watch, { channel,  eventType });
    }
  } catch (error) {
    console.error('2d comments realtime init error', error);
  }
}

function* disconnectConnection(): SagaIterator {
  try {
    const connectionInfo = yield selectWrapper(x => x.twoDComments.realtimeConnection);
    if (!connectionInfo) {
      return;
    }
    const { connection, room } = connectionInfo;
    yield put(TwoDCommentsActions.realtimeDisconnected());
    yield call(connection.invoke, 'LeaveProject', room);
    connection.stop();
  } catch (error) {
    console.error('2d comments disconnect connection', error);
  }
}

export function* twoDCommentsRealtimeSaga(): SagaIterator {
  yield takeLatest(TwoDCommentsActions.connectRealtime, initCommentsRealtime);
  yield takeLatest(TwoDCommentsActions.disconnectRealtime, disconnectConnection);
  yield takeLatest(TwoDCommentsActions.dropState, disconnectConnection);
}
