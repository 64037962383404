import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { AutoMeasureActionTypes } from '../actions/types/auto-measure';
import { AutoMeasureOptionResponse } from '../interfaces';
import { DrawingsState } from '../interfaces/drawings-state';

export const DrawingsAutoMeasureReducerMethods: ReducerMethods<DrawingsState> = {
  [AutoMeasureActionTypes.SAVE_AVAILABLE_OPTIONS]: (s, payload: AutoMeasureOptionResponse[]) => {
    const availableOptions = [];
    const values = [];
    for (let i = 0; i < payload.length; i++) {
      values.push(i);
      availableOptions.push({ ...payload[i], value: availableOptions.length });
    }
    return new MonoliteHelper(s)
      .set(_ => _.autoMeasureSettings.availableOptions, availableOptions)
      .set(_ => _.autoMeasureSettings.selectedOptions, values)
      .get();
  },
  [AutoMeasureActionTypes.SET_SELECTED_OPTIONS]: (s, selectedOptions: number[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.autoMeasureSettings.selectedOptions, selectedOptions)
      .get();
  },
};
