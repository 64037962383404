import { State } from 'common/interfaces/state';

const getSelectedIds = (state: State): { rootIds: string[], childIds: string[] } => {
  const { selectGeometryGroup, selectedInstances, aiAnnotation, drawingGeometryGroups } = state.drawings;
  const rootIds = [];
  const childIds = [];
  const selectedGroupIds = new Set(selectGeometryGroup);
  const measureIds = new Set(selectedInstances);

  measureIds.forEach(measureId => {
    const measure = aiAnnotation.geometry[measureId];
    if (selectedGroupIds.has(measure.groupId)) {
      childIds.push(measureId);
    } else {
      rootIds.push(measureId);
    }
  });

  selectedGroupIds.forEach(groupId => {
    const group = drawingGeometryGroups.find(g => g.id === groupId);
    if (selectedGroupIds.has(group.parentId)) {
      childIds.push(groupId);
    } else {
      rootIds.push(groupId);
    }
  });

  return {
    rootIds,
    childIds,
  };
};

export const Selector = {
  getSelectedIds,
};
