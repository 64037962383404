import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  ${(p) => p.position}: 0px;
  width: 170px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 10px;
`;

const InformationLine = styled.div`
  display: flex;
  justify-content: space-between;
  height: 10px;
  align-items: center;
  & + & {
    margin-top: 5px;
  }
  ${Text} + ${Text} {
    margin-left: 5px;
  }
`;

const StyleIcon = styled.div<{ color: string }>`
  svg {
    min-height: 10px;
    fill: ${p => p.color};
  }
`;

const LineStyleIcon = styled(StyleIcon)`
  height: 10px;
  padding-bottom: 8px;
  svg {
    width: 30px;
    height: 20px;
  }
`;

const ColorIndicator = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${p => p.color};
  margin-top: auto;
  margin-bottom: auto;
`;


export const Styled = {
  Container,
  InformationLine,
  StyleIcon,
  LineStyleIcon,
  ColorIndicator,
};
