import autobind from 'autobind-decorator';
import { mathUtils } from 'common/utils/math-utils';
import { DrawingsCanvasConstants } from '../../constants';
import { FocusElementsStatus } from './interfaces';

interface LayoutView {
  scrollLayoutRef: HTMLDivElement;
  defaultCanvasRef: HTMLCanvasElement;
  mainLayoutRef: HTMLDivElement;
}

interface Config {
  layoutView: LayoutView;
  getFocusElementsState: () => FocusElementsStatus;
}

export class MenuView {
  private _menuRef: HTMLDivElement;
  private _isBoolean: boolean;
  private _config: Config;

  constructor(config: Config) {
    this._config = config;
  }

  public saveMenuRef(menuRef: HTMLDivElement, isBoolean: boolean): void {
    this._menuRef = menuRef;
    this._isBoolean = isBoolean;
    this.updateMenuPosition();
  }

  @autobind
  public updateMenuPosition(): void {
    const {
      defaultCanvasRef,
      mainLayoutRef,
      scrollLayoutRef,
    } = this._config.layoutView;
    const focusElementsState = this._config.getFocusElementsState();
    if (focusElementsState.zoomedBounds && this._menuRef && defaultCanvasRef) {
      const { scrollLeft, scrollTop } = scrollLayoutRef;
      const {
        width: scrollWidth,
        height: scrollHeight,
      } = scrollLayoutRef.getBoundingClientRect();
      const {
        width: canvasWidth,
        height: canvasHeight,
      } = defaultCanvasRef.getBoundingClientRect();
      const { width: mainWidth, height: mainHeight } = mainLayoutRef.getBoundingClientRect();
      const { x, y, width } = focusElementsState.zoomedBounds;
      const canvasLeft = (mainWidth - canvasWidth) / 2;
      let left = 0;
      const maxTop = Math.abs(scrollHeight - DrawingsCanvasConstants.menuMarginVertical - this._menuRef.clientHeight);
      let top = this._menuRef.clientHeight < scrollHeight
        ? (scrollHeight - this._menuRef.clientHeight) / 2
        : 0;
      if (DrawingsCanvasConstants.menuMarginVertical < maxTop) {
        top =
          y - scrollTop - DrawingsCanvasConstants.menuMarginVertical / 4
          - this._menuRef.clientHeight + (mainHeight - canvasHeight) / 2;
        top = mathUtils.clamp(top, DrawingsCanvasConstants.menuMarginVertical, maxTop);
      }
      if (!this._isBoolean) {
        const upperBound = scrollWidth - DrawingsCanvasConstants.menuMarginHorizontal - this._menuRef.clientWidth;
        const lowerBound = DrawingsCanvasConstants.menuMarginHorizontal * 2;
        left = x - scrollLeft + (width - this._menuRef.clientWidth) / 2 + (mainWidth - canvasWidth) / 2;
        if (upperBound > lowerBound) {
          left = mathUtils.clamp(left, lowerBound, upperBound);
        }
      } else {
        const leftDiff = canvasLeft + x - scrollLeft;
        const right = canvasLeft + x + width - scrollLeft;
        const rightDiff = scrollWidth - (right);
        if (Math.abs(leftDiff) > Math.abs(rightDiff)) {
          left = leftDiff - this._menuRef.clientWidth;
        } else {
          left = right;
        }
        left = mathUtils.clamp(
          left,
          DrawingsCanvasConstants.menuMarginHorizontal * 2,
          scrollWidth - DrawingsCanvasConstants.menuMarginHorizontal - this._menuRef.clientWidth,
        );
      }
      this._menuRef.style.left = `${left}px`;
      this._menuRef.style.top = `${top}px`;
    }
  }
}
