import { useMemo } from 'react';
import { DrawingsActions } from 'common/components/drawings/actions/creators/common';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { useRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { DrawingGeometryOperationType } from 'common/components/drawings/enums/drawing-geometry-operation-type';
import { useDrawModeApi, useStartBooleanOperation } from 'common/components/drawings/hooks';
import { DrawingsSelectAggregationGroup } from 'common/components/drawings/interfaces';
import { DrawingsGeometryRendererAPI } from 'common/components/drawings/interfaces/drawings-geometry-renderer-api';
import { useActionDispatch } from 'common/hooks';
import { useStopEvent } from 'common/hooks/use-event-hooks';
import { useOpenCloseDialog } from 'common/UIKit';
import { useModify, useStartMagicSearch } from '../../hooks';
import { FlyingMenuActionKeys } from '../constants/action-keys';


function getFlipCallbacks(
  selectedInstances: string[],
  rendererApi: DrawingsGeometryRendererAPI,
): { flipHorizontal: () => void, flipVertical: () => void } {
  return {
    flipHorizontal: () => {
      rendererApi.engine.flipElements(selectedInstances, 'horizontal');
    },
    flipVertical: () => {
      rendererApi.engine.flipElements(selectedInstances, 'vertical');
    },
  };
}

interface UseStartGeometryOperationPayload {
  drawMode: DrawingsDrawMode;
  operationType: DrawingGeometryOperationType;
  instancesIds: string[];
  rendererApi: DrawingsGeometryRendererAPI;
}

function useStartGeometryOperation(
  {
    drawMode,
    operationType,
    instancesIds,
    rendererApi,
  }: UseStartGeometryOperationPayload,
): (e?: React.MouseEvent) => void {
  const { setDrawMode } = useDrawModeApi();
  const setOperationIds = useActionDispatch(DrawingsActions.setOperationIds);
  return useStopEvent(() => {
    rendererApi.engine.changeSelection([]);
    setOperationIds(instancesIds);
    rendererApi.engine.setIdsForOperation(instancesIds, operationType);
    setDrawMode(drawMode);
  }, [drawMode, operationType, instancesIds, rendererApi, setDrawMode, setOperationIds]);
}

interface FlyingMenuCallbacksPayload {
  instancesIds: string[];
  moveToGroup: () => void;
  traceLink: (id: string) => void;
}

export function useFlyingMenuCallbacks({
  instancesIds,
  traceLink,
  moveToGroup,
}: FlyingMenuCallbacksPayload): Record<FlyingMenuActionKeys, (e?: React.MouseEvent) => void> {
  const { rendererApi } = useRendererApiContext();
  const startMagicSearch = useStartMagicSearch(instancesIds);
  const modifyCallback = useModify(instancesIds);
  const [ openMoveToGroupDialog ] = useOpenCloseDialog(DrawingDialogs.MOVE_TO_DIALOG);
  const startKnife = useStartGeometryOperation({
    drawMode: DrawingsDrawMode.Knife,
    operationType: DrawingGeometryOperationType.Knife,
    instancesIds,
    rendererApi,
  });
  const startOffset = useStartGeometryOperation({
    drawMode: DrawingsDrawMode.Offset,
    operationType: DrawingGeometryOperationType.Offset,
    instancesIds,
    rendererApi,
  });

  const merge = useStartBooleanOperation(instancesIds, DrawingsSelectAggregationGroup.Area, DrawingsDrawMode.Union);
  const subtract = useStartBooleanOperation(
    instancesIds,
    DrawingsSelectAggregationGroup.Area,
    DrawingsDrawMode.Subtract,
  );
  const enclose = useStartBooleanOperation(
    instancesIds,
    DrawingsSelectAggregationGroup.Line,
    DrawingsDrawMode.Enclose,
  );

  const joinCount = useStartBooleanOperation(
    instancesIds,
    DrawingsSelectAggregationGroup.Count,
    DrawingsDrawMode.Join,
  );

  const joinLine = useStartBooleanOperation(
    instancesIds,
    DrawingsSelectAggregationGroup.Line,
    DrawingsDrawMode.Join,
  );

  const openPolygon = useStartBooleanOperation(
    instancesIds,
    DrawingsSelectAggregationGroup.Area,
    DrawingsDrawMode.OpenPolygon,
  );

  const splitPolyline = useStartBooleanOperation(
    instancesIds,
    DrawingsSelectAggregationGroup.Line,
    DrawingsDrawMode.SplitPolyline,
  );

  return useMemo(() => {
    const { flipHorizontal, flipVertical } = getFlipCallbacks(instancesIds, rendererApi);
    return {
      [FlyingMenuActionKeys.FlipHorizontally]: flipHorizontal,
      [FlyingMenuActionKeys.FlipVertically]: flipVertical,
      [FlyingMenuActionKeys.Modify]: modifyCallback,
      [FlyingMenuActionKeys.MoveTo]: openMoveToGroupDialog,
      [FlyingMenuActionKeys.Knife]: startKnife,
      [FlyingMenuActionKeys.Offset]: startOffset,
      [FlyingMenuActionKeys.Merge]: merge,
      [FlyingMenuActionKeys.Subtract]: subtract,
      [FlyingMenuActionKeys.Enclose]: enclose,
      [FlyingMenuActionKeys.JoinCount]: joinCount,
      [FlyingMenuActionKeys.JoinLine]: joinLine,
      [FlyingMenuActionKeys.TraceLink]: () => traceLink(instancesIds[0]),
      [FlyingMenuActionKeys.Group]: moveToGroup,
      [FlyingMenuActionKeys.MagicSearch]: () => startMagicSearch(),
      [FlyingMenuActionKeys.OpenPolygon]: openPolygon,
      [FlyingMenuActionKeys.SplitPolyline]: splitPolyline,
    };
  }, [
    instancesIds,
    rendererApi,
    modifyCallback,
    openMoveToGroupDialog,
    startKnife,
    startOffset,
    merge,
    subtract,
    enclose,
    joinCount,
    joinLine,
    moveToGroup,
    startMagicSearch,
    traceLink,
    openPolygon,
    splitPolyline,
  ]);
}
