import styled from 'styled-components';


const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  width: ${p => p.isOpen ? '100%' : 0};
  flex-shrink: 0;
  color: ${p => p.theme.color.mainFont};
  background-color: ${p => p.theme.color.backgroundRush};
  border-color: ${p => p.theme.color.background};
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  transition: ${p => p.theme.duration.s};
  z-index: 15;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid ${p => p.theme.color.background};

  > p {
    line-height: 14px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: calc(100% - 10px);
`;

const Footer = styled.div`
  border-top: 1px solid ${p => p.theme.color.background};
`;

const DotMarkWrapper = styled.div<{ active: boolean }>`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 5px;
    width: 5px;
    right: -5px;
    top: -5px;
    background-color: ${p => p.active ? p.theme.color.turquoise : 'transparent'};
    border: 3px solid ${p => p.active ? p.theme.color.backgroundRush : 'transparent'};
    border-radius: 50%;
  }
`;

const MenuButton = styled.div`
  margin-right: 5px;
`;

const HeaderButtonsRight = styled.div`
  display: flex;
  gap: 5px;
  margin-left: 15px;
`;

const HeaderButtonsLeft = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
`;

const FilterButtonContainer = styled.div`
  position: relative;
`;

const List = styled.div`
  height: 100%;

  .scroll-box__thumb-vertical {
    border-width: 2px;
    border-radius: 5px;
    background: ${p => p.theme.color.disabled};
    width: 5px;
    box-sizing: border-box;
  }
  .scroll-box:hover .scroll-box__thumb-vertical {
    background: ${p => p.theme.color.disabled};
  }

  .scroll-box__thumb-vertical:hover,
  .scroll-box__thumb-vertical:active {
    background: ${p => p.theme.color.gray} !important;
  }

  .scroll-box__track-vertical {
    width: 5px !important;
    border-radius: 5px;
    margin-top: 0;
    margin-bottom: 0;
    background: ${p => p.theme.color.background};
  }

  .scroll-box__content {
    margin-right: -10px !important;
    margin-bottom: 3px !important;
    overflow-x: hidden !important;
  }
`;

export const Styled = {
  Container,
  Inner,
  Header,
  MenuButton,
  HeaderButtonsRight,
  HeaderButtonsLeft,
  Body,
  DotMarkWrapper,
  FilterButtonContainer,
  Footer,
  List,
};
