import { SvgComponent } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { CommentaryTargetType } from 'unit-2d-comments/enums';
import { CommentaryThread, Message } from 'unit-2d-comments/interfaces';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { useCommentUserContext } from '../comment-user-context';
import { BaseCommentProps } from '../comments-list/interfaces';
import { CommentTypeContainer } from './comment-type-container';
import { Content } from './content';
import { SubComment } from './subcomment';
import { SubCommentCreator } from './subcomment-creator';

export interface Props extends CommentaryThread, BaseCommentProps {
  shouldFocusViewToComment: boolean;
  currentUserId: string;
  saveButtonText: string;
  subCommentOnEdit: number;
  isEdit: boolean;
  typeIcon?: SvgComponent;
  targetIcon?: SvgComponent;
  targetTooltip?: string;
  targetTitle?: string;
  resolved?: boolean;
  text?: string;
  messages?: Message[];
  canEditStatusAndComment?: boolean;
  isSelected?: boolean;
  isPanelOpened?: boolean;
  targetType: CommentaryTargetType;
  onFocused: () => void;
  onAddSubComment: (commentId: number, subCommentText: string) => void;
  onRemoveComment: (commentId: number) => void;
  onRemoveSubComment: (commentId: number, subCommentId: number) => void;
  onEditComment: (commentId: number, text: string) => void;
  onEditSubComment: (commentId: number, subCommentId: number, text: string) => void;
  onResolve: (commentId: number) => void;
  onCommentClick: (commentId: number) => void;
  setEditComment: (id: number) => void;
  setEditSubComment: (id: number) => void;
}

const UNRESOLVED_PLACEHOLDER = 'Reply or add others with @';
const RESOLVED_PLACE_HOLDER = 'Adding Comment with re-open this discussion';

export const Comment: React.FC<Props> = ({
  id,
  isEdit,
  currentUserId,
  targetIcon,
  targetTooltip,
  targetTitle,
  text,
  subCommentOnEdit,
  messages,
  userId: creator,
  createdAt,
  editedAt,
  resolved,
  canEditStatusAndComment,
  isSelected,
  targetType,
  onRemoveSubComment,
  onAddSubComment,
  onRemoveComment,
  onEditComment,
  onResolve,
  onEditSubComment,
  onCommentClick,
  setEditComment,
  setEditSubComment,
  shouldFocusViewToComment,
  onFocused,

}) => {
  const [ showExpand, setShowExpand ] = React.useState(false);

  const { sendEvent } = useAnalytics();
  const onAddSubCommentCallback = useCallback(
    (subCommentText: string) => onAddSubComment(id, subCommentText),
    [],
  );
  const onRemoveCallback = useCallback(() => {
    onRemoveComment(id);
    sendEvent(MetricNames.comments.deleteThread);
  }, []);
  const onRemoveSubCommentCallback = useCallback(
    (subCommentId: number) => {
      onRemoveSubComment(id, subCommentId);
      sendEvent(MetricNames.comments.deleteMessage);
    },
    [],
  );
  const onEditCallback = useCallback((newText: string) => {
    onEditComment(id, newText);
    sendEvent(MetricNames.comments.editThread);
  }, []);
  const onEditSubCommentCallback = useCallback(
    (subCommentId: number, newText: string) => {
      onEditSubComment(id, subCommentId, newText);
      sendEvent(MetricNames.comments.editMessage);
    },
    [],
  );
  const onResolveCallback = useCallback(() => onResolve(id), []);
  const onCommentClickCallback = useCallback(() => onCommentClick(id), [onCommentClick, id]);
  const { users } = useCommentUserContext();

  const onStartEdit = useCallback(() => setEditComment(id), []);
  const onStopEdit = useCallback(() => setEditComment(null), []);

  return (
    <CommentTypeContainer
      iconType={targetIcon}
      tooltip={targetTooltip}
      title={targetTitle}
      resolved={resolved}
      shouldFocus={shouldFocusViewToComment}
      onFocused={onFocused}
      onClick={onCommentClickCallback}
      isSelected={isSelected}
      showExpand={showExpand}
      setShowExpand={setShowExpand}
    >
      <Content
        isEdit={isEdit}
        resolved={resolved}
        isOwner={currentUserId === creator}
        user={users[creator]}
        lastActionDate={editedAt || createdAt}
        text={text}
        onResolve={onResolveCallback}
        onRemove={onRemoveCallback}
        onEdit={onEditCallback}
        onStartEdit={onStartEdit}
        onStopEdit={onStopEdit}
        edited={!!editedAt}
        targetType={targetType}
      />
      {
        messages?.map((subComment) => {
          return (
            <SubComment
              setEditSubComment={setEditSubComment}
              isEdit={subCommentOnEdit === subComment.id}
              resolveDiff={subComment.resolveDiff}
              key={subComment.id}
              id={subComment.id}
              text={subComment.text}
              currentUserId={currentUserId}
              onEdit={onEditSubCommentCallback}
              onRemove={onRemoveSubCommentCallback}
              user={users[subComment.userId]}
              createdAt={subComment.createdAt}
              updatedAt={subComment.editedAt}
              resolved={resolved}
            />
          );
        })
      }
      <RenderIf condition={canEditStatusAndComment && !showExpand}>
        <SubCommentCreator
          placeholder={resolved ? RESOLVED_PLACE_HOLDER : UNRESOLVED_PLACEHOLDER}
          currentUser={users[currentUserId]}
          onAddSubComment={onAddSubCommentCallback}
          resolved={resolved}
        />
      </RenderIf>
      <Spinner show={!canEditStatusAndComment} fullScreen={true} withBackground={true} />
    </CommentTypeContainer>
  );
};
