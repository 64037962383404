export enum CursorHintType {
  Continue = 1,
  WizzardPolylineInProgress,
  WizzardPolylineNotFound,
  BucketFillInProgress,
  BucketFillNotFound,
  BucketAIOutOffArea,
  NoGeometriesInArea,
  ReachedLimitInArea,
  SelectionAreaCrashed,
  NoGeometryFoundByClick,
}
