import React, { useEffect, useRef } from 'react';
import { FlattenInterpolation } from 'styled-components';

import { Styled } from './styled';


export interface MovableContainerProps {
  x: number;
  y: number;
  screenBorderMargin?: number;
  zIndex?: number;
  bottom?: boolean;
  right?: boolean;
  styles?: FlattenInterpolation<any>;
}


export const MovableContainer: React.FC<MovableContainerProps> = ({
  x,
  y,
  screenBorderMargin,
  children,
  zIndex,
  bottom,
  right,
  styles,
}) => {
  const dropDownRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const verticalDiff = window.innerHeight - (y + dropDownRef.current.clientHeight + screenBorderMargin);
    const verticalPos = verticalDiff < 0 ? y + verticalDiff : y;
    if (bottom) {
      dropDownRef.current.style.bottom = `${verticalPos}px`;
      dropDownRef.current.style.top = 'unset';
    } else {
      dropDownRef.current.style.top = `${verticalPos}px`;
      dropDownRef.current.style.bottom = 'unset';
    }

    const horizontalDiff = window.innerWidth - (x + dropDownRef.current.clientWidth + screenBorderMargin);
    const horizontalPos = horizontalDiff < 0 ? x + horizontalDiff : x;
    if (right) {
      dropDownRef.current.style.right = `${horizontalPos}px`;
      dropDownRef.current.style.left = 'unset';
    } else {
      dropDownRef.current.style.left = `${horizontalPos}px`;
      dropDownRef.current.style.right = 'unset';
    }

    dropDownRef.current.style.transformOrigin = `${right ? 'right': 'left'} ${bottom ? 'top' : 'bottom'}`;

  }, [dropDownRef, x, y, bottom, right, screenBorderMargin]);

  return (
      <Styled.Container
        ref={dropDownRef}
        zIndex={zIndex}
        styles={styles}
      >
        {children}
      </Styled.Container>
  );
};
