import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './activity-assignment-activity-tile-view.scss';

import { State } from 'common/interfaces/state';
import { CEActivityAssignmentDataMaterial, CEActivityAssignmentDataTreeNode } from 'unit-cost-estimate/interfaces';
import { CEActivityAssignmentUtils } from 'unit-cost-estimate/utils/ce-activity-assignment-utils';
import {
  ActivityAssignmentActivityTileBody,
  ActivityAssignmentActivityTileBodyTitle,
  ActivityAssignmentActivityTileContainer,
} from 'unit-projects/components/activity-assignment-activity-tile-containers';


interface OwnProps {
  index: number;
}

interface StateProps {
  selectedWorks: CEActivityAssignmentDataTreeNode;
  isUsed: boolean;
}


interface Props extends StateProps, OwnProps {
}


class ActivityAssignmnentActivityTileComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isUsed, selectedWorks } = this.props;
    const selectedVariantId = CEActivityAssignmentUtils.getSelectedVariantId(selectedWorks.selectedVariant);
    const selectedVariant = selectedWorks.variants[selectedVariantId];
    const materials = selectedVariant && selectedVariant.materials.map(x => selectedWorks.materials[x]);

    return (
      <ActivityAssignmentActivityTileContainer enabled={isUsed}>
        <ActivityAssignmentActivityTileBody>
          <div className='activity-assignment-activity-tile-view__variant'>
            <ActivityAssignmentActivityTileBodyTitle>
                Activity Variant
            </ActivityAssignmentActivityTileBodyTitle>
            <div
              title={selectedVariant.name}
              className='activity-assignment-activity-tile-view__selected-title'
            >
              {selectedVariant && selectedVariant.name}
            </div>
          </div>
          {this.renderRelatedMaterials(selectedWorks)}
          {
            materials && materials.length
              ? (
                <div className='activity-assignment-activity-tile-view__material'>
                  <ActivityAssignmentActivityTileBodyTitle>
                    Materials
                  </ActivityAssignmentActivityTileBodyTitle>
                  {materials && materials.map(this.renderMaterial)}
                </div>
              ) : null
          }
        </ActivityAssignmentActivityTileBody>
      </ActivityAssignmentActivityTileContainer>
    );
  }


  private renderRelatedMaterials(data: CEActivityAssignmentDataTreeNode): React.ReactNode {
    const selectedVariantId = CEActivityAssignmentUtils.getSelectedVariantId(data.selectedVariant);
    const selectedVariant = data.variants[selectedVariantId];
    if (!selectedVariant.primaryMaterial || !selectedVariant.secondaryMaterial) {
      return null;
    }

    return (
      <>
        <div className='activity-assignment-activity-tile-view__material'>
          <ActivityAssignmentActivityTileBodyTitle>
            Primary Material
          </ActivityAssignmentActivityTileBodyTitle>
          {this.renderMaterial(data.materials[selectedVariant.primaryMaterial])}
        </div>
        <div className='activity-assignment-activity-tile-view__material'>
          <ActivityAssignmentActivityTileBodyTitle>
            Secondary Material
          </ActivityAssignmentActivityTileBodyTitle>
          {this.renderMaterial(data.materials[selectedVariant.secondaryMaterial])}
        </div>
      </>
    );
  }

  @autobind
  private renderMaterial(materialSelect: CEActivityAssignmentDataMaterial): React.ReactNode {
    const selectedVariantId = CEActivityAssignmentUtils.getSelectedVariantId(materialSelect.selectedVariant);
    const selectedMaterial = materialSelect.variants.find(x => x.id === selectedVariantId);

    return (
      <div
        title={selectedMaterial.name}
        className='activity-assignment-activity-tile-view__selected-title'
        key={selectedMaterial.id}
      >
        {selectedMaterial.name}
      </div>
    );
  }
}


function mapStateToProps(state: State, { index }: OwnProps): StateProps {
  const selectedVariant = state.ceActivityAssignment.selectedWorks[index];
  return { isUsed: true, selectedWorks: selectedVariant };
}

const connector = connect(mapStateToProps);
export const ActivityAssignmentActivityTileView = connector(ActivityAssignmnentActivityTileComponent);
