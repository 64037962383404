import { RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { Paper } from 'common/components/paper';
import { Text } from 'common/components/text';
import { Mood } from 'common/enums/mood';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { useApplyCalibration } from '../../hooks/calibration/use-apply-scale';
import { DrawingsShortInfo } from '../../interfaces';
import { Styled } from './styled';

interface Props {
  currentDrawing: DrawingsShortInfo;
  selectedPages?: string[];
  onCalibrated?: () => void;
}

export const AutoScaleButtonComponent: React.FC<Props> = ({
  currentDrawing,
  selectedPages,
  onCalibrated,
}) => {
  const canUseAutoScale = useAbility(Operation.Manage, Subject.Takeoff2dAutoScale);
  const {
    recommendedDrawingCalibrationLineLength,
    recommendedOriginalCalibrationLineLength,
    recommendedPaperSize,
  } = currentDrawing;
  const disabled = !canUseAutoScale;

  const { sendEvent } = useAnalytics();
  const applyCalbration = useApplyCalibration();

  const onApply = useCallback(() => {
    const drawingIds = selectedPages?.length ? selectedPages : [currentDrawing.drawingId];
    applyCalbration(drawingIds, {
      paperSize: recommendedPaperSize,
      drawingLength: recommendedDrawingCalibrationLineLength,
      originalLength: recommendedOriginalCalibrationLineLength,
    });
    sendEvent(MetricNames.measurements.setUpAutoScale);
    if (onCalibrated) {
      onCalibrated();
    }
  }, [currentDrawing, selectedPages, onCalibrated, applyCalbration]);

  return (
    <Paper mood={Mood.Secondary}>
      <Styled.Container>
        <Styled.Content>
          <Text
            fontSize={14}
          >
            Scale suggested by AI:
            <Styled.ScaleValue>
              {recommendedDrawingCalibrationLineLength}:{recommendedOriginalCalibrationLineLength}
            </Styled.ScaleValue>
            ,<Styled.ScaleValue>
              {recommendedPaperSize}
            </Styled.ScaleValue>
          </Text>
        </Styled.Content>
        <UpgradeWrapper isNeedUpdate={disabled}>
          <Styled.SetButtonWrapper isNeedUpdate={disabled}>
            <RectangleButton
              size="s"
              mood={disabled ? Mood.Disabled : Mood.Secondary}
              onClick={onApply}
              text='Apply'
              fontSize={14}
            />
          </Styled.SetButtonWrapper>
        </UpgradeWrapper>
      </Styled.Container>
    </Paper>
  );
};

export const AutoScaleButton = React.memo(AutoScaleButtonComponent);
