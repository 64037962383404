import * as React from 'react';

import { Paper } from 'common/components/paper';
import { Text } from 'common/components/text';
import { Mood } from 'common/enums/mood';
import { Styled } from './styled';


export interface Props {
  text: string;
  mood?: Mood;
}

const HintWithDescriptionComponent: React.FC<Props> = ({ text, mood = Mood.Secondary }) => {
  return (
    <Paper mood={mood}>
      <Styled.Container>
        <Styled.Content>
          <Text
            fontSize={14}
          >
            {text}
          </Text>
        </Styled.Content>
      </Styled.Container>
    </Paper>
  );
};

export const HintWithDescription = React.memo(HintWithDescriptionComponent);
