import autobind from 'autobind-decorator';
import * as paper from 'paper';
import { DrawingsCanvasColors, DrawingsCanvasConstants } from 'common/components/drawings/constants';
import { DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import { ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsRenderParams, ShortPointDescription } from 'common/components/drawings/interfaces';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { DrawingsGeometryEntity } from '../../drawings-geometry-entities';
import { ThreeDParamsHelper } from '../../drawings-helpers/threed-params-helper';
import { DrawingsGeometryAutocompleteEntityTypes, NewDrawingSettings } from '../../interfaces';
import { FinishedDrawingElement } from './drawings-geometry-temp-entity';

interface DrawingsGeometryAutocompleteEntityConfig  {
  type: DrawingsGeometryAutocompleteEntityTypes;
  points: ShortPointDescription[];
  layer: paper.Layer;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
}

export class DrawingsGeometryAutocompleteEntity implements DrawingsGeometryEntity {
  private _config: DrawingsGeometryAutocompleteEntityConfig;
  private _path: paper.Path;

  constructor(config: DrawingsGeometryAutocompleteEntityConfig) {
    this._config = config;
    this.render();
    this._config.renderParametersContextObserver.subscribe(this.updateRenderParams);
  }

  public destroy(): void {
    this._path.remove();
    this._config.renderParametersContextObserver.unsubscribe(this.updateRenderParams);
  }

  public get points(): ShortPointDescription[] {
    return this._config.points;
  }

  public get isValid(): boolean {
    return this._config.type === DrawingsInstanceType.Polyline ?
      this._path.length > 1
      : this._config.points.length > 2  && !DrawingsPaperUtils.isPolygonSelfIntersected(this._path);
  }

  public convert(settings: NewDrawingSettings): FinishedDrawingElement {
    const { color, strokeStyle, strokeWidth } = settings;

    const addLastPoint = this._config.type !== DrawingsInstanceType.Polygon
      || !this._path.lastSegment.point.equals(this._path.firstSegment.point);

    const { points, polyline } = DrawingsPaperUtils.convertPathToPolyline(
      this._path,
      color,
      strokeWidth,
      strokeStyle,
      addLastPoint,
    );
    if (this._config.type === DrawingsInstanceType.Polygon) {
      ThreeDParamsHelper.addToPolygon(polyline, settings);
    } else {
      ThreeDParamsHelper.addToPolyline(polyline, settings);
    }
    return {
      type: this._config.type,
      geometry: polyline,
      points,
    };
  }

  private render(): void {
    this._path = new paper.Path(this._config.points);
    if (this._config.type === DrawingsInstanceType.Polygon) {
      if (!this._path.lastSegment.point.equals(this._path.firstSegment.point)) {
        this._path.addSegments([this._path.lastSegment]);
      }
      this._path.fillColor = DrawingsCanvasColors.autocompleteColorInfo.fill;
    }
    this._path.strokeColor = DrawingsCanvasColors.autocompleteColorInfo.stroke;


    this._path.addTo(this._config.layer);
  }

  @autobind
  private updateRenderParams({ zoom }: DrawingsRenderParams): void {
    this._path.strokeWidth = DrawingsCanvasConstants.infoLinesStroke / zoom;
    this._path.dashArray = DrawingsCanvasUtils.scaleStroke(
      {
        strokeStyle: DrawingStrokeStyles.Dashed,
        strokeWidth: DrawingsCanvasConstants.infoLinesStroke,
      },
      zoom,
    );
  }
}
