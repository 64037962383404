import { formatData } from 'unit-2d-database/helpers/format-data-getter';
import { roundNumeric } from 'unit-2d-database/helpers/round-numeric';
import { AllPropertyValues, NumericFormat, NumericProperty } from 'unit-2d-database/interfaces';
import { Field, FieldType } from '../../interfaces';

export interface NumberFieldInput {
  onChange: (value: number) => void;
  value: number;
}

const getChangeHandler = (onChange: (input: NumberFieldInput) => void) => (value: number) => {
  try {
    onChange({ value, onChange: getChangeHandler(onChange) });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const mapNumberPropertyToField = (
  property: NumericProperty,
  originId: string,
  units: string[],
  onChange: (input: NumberFieldInput) => void,
  onDeleteClick: () => void,
  onClickUnit: (index: number) => void,
  onVisibilityClick: () => void,
  onBlurUnit: (unit: string) => void,
): Field<NumberFieldInput> => {
  const unit = (property.value.format as NumericFormat).unit;
  const activeElementIndex = units.indexOf(unit);
  return {
    id: property.id,
    originId,
    name: property.name,
    type: FieldType.NumberInput,
    isHide: false,
    input: {
      value: roundNumeric(property.value.value),
      onChange: getChangeHandler(onChange),
    },
    dropDownProps: {
      elements: units,
      activeElementIndex,
      onClick: onClickUnit,
      value: unit,
      onBlur: onBlurUnit,
    },
    onDeleteClick,
    onVisibilityClick,
    getDataUpdate: (field: Field<NumberFieldInput>) => {
      const format = property.value.format;
      const { activeElementIndex: currentIndex, elements, value: valueUnit } = field.dropDownProps;
      const value: AllPropertyValues = {
        value: field.input.value,
        format: formatData.updateFormatUnit(format, valueUnit || elements[currentIndex]),
        type: property.value.type,
      };
      return {
        ...property,
        id: field.id,
        value,
      };
    },
  };
};
