import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { TwoDActions } from '2d/actions/creators';
import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { AssignPiaPatch, AssignedPia, AssignedPiaAssembly, AssignedPiaItem } from '2d/interfaces';
import { DrawingsGeometryGroup } from 'common/components/drawings';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import {
  AssemblyForm,
  ItemUpdater,
} from 'unit-2d-database/components/side-panel/components/assembly-panel/item-form-updater';
import {
  ItemForm,
  PropertyGroupUpdater,
} from 'unit-2d-database/components/side-panel/components/item-panel/helpers/property-group-form-updater';
import {
  MeasureForm,
  MeasurePanelAssemblyAfterEffects,
  MeasurePanelItemAfterEffects,
} from 'unit-2d-database/components/side-panel/components/measure-panel';
import { AssemblyUpdater } from 'unit-2d-database/components/side-panel/components/measure-panel/helpers';
import {
  GroupForm,
} from 'unit-2d-database/components/side-panel/components/measure-panel/helpers/map-assembly-to-form';
import {
  PropertyField,
  PropertyGroupForm,
  PropertyUpdater,
} from 'unit-2d-database/components/side-panel/helpers/update-property-form';
import { FormatTypeGuards } from 'unit-2d-database/helpers/format-typeguards';
import { AllPropertyValues, Assembly, Item, NumericFormat } from 'unit-2d-database/interfaces';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { resetAssignAfterChangeProperty } from './reset-assign-after-change-property';

interface DispatchProps {
  setSelectedAssembliesIds: (ids: string[]) => void;
  setSelectedItemsIds: (ids: string[]) => void;
  sendAssignPatch: (assign: AssignPiaPatch[]) => void;
  setAssignPiaLoad: () => void;
  setAssignPiaReady: () => void;
}

interface StateProps {
  selectedMeasureGroupIds: string[];
  assembliesDataBase: Assembly[];
  itemsDataBase: Item[];
  selectedMeasureIds: string[];
  assign: Record<string, AssignedPia>;
  groups: DrawingsGeometryGroup[];
  aiAnnotation: DrawingsGeometryState;
  isAssignReady: boolean;
}

export interface WithAfterEffectsProps extends AnalyticsProps {
  assemblyAfterEffects: MeasurePanelAssemblyAfterEffects;
  itemAfterEffects: MeasurePanelItemAfterEffects;
  isAssignReady: boolean;
}

interface ComponentProps<T> {
  Component: React.ComponentType<T>;
}

interface Props<T extends WithAfterEffectsProps>
  extends StateProps,
  DispatchProps,
  Readonly<ComponentProps<T>>,
  AnalyticsProps {
  componentProps: T;
}

export class WithAfterEffectsComponent<TProps extends WithAfterEffectsProps> extends React.PureComponent<
  Props<TProps>
> {
  private assemblyAfterEffects: MeasurePanelAssemblyAfterEffects = {
    baseParams: {
      afterChange: this.afterAssemblyPropertyChange,
      afterDelete: this.afterAssemblyPropertyDelete,
      afterUnitChange: this.afterAssemblyPropertyChange,
      afterVisibilityChange: ConstantFunctions.doNothing,
    },
    params: {
      afterAddAssemblyForm: this.afterAddAssembly,
      afterDeleteAssembly: this.afterDeleteAssembly,
      afterAddItem: ConstantFunctions.doNothing,
      afterAddProperty: ConstantFunctions.doNothing,
      afterDeleteItem: ConstantFunctions.doNothing,
      afterDeletePropertyGroup: ConstantFunctions.doNothing,
      afterChangeFormula: this.afterChangeAssemblyFormula,
    },
    afterSetForm: this.afterSetAssemblyForm,
  };

  private itemAfterEffects: MeasurePanelItemAfterEffects = {
    baseParams: {
      afterChange: this.afterItemPropertyChange,
      afterDelete: this.afterItemPropertyDelete,
      afterUnitChange: this.afterItemPropertyChange,
      afterVisibilityChange: ConstantFunctions.doNothing,
    },
    params: {
      afterAddItem: this.afterAddItem,
      afterDeleteItem: this.afterDeleteItem,
      afterAddProperty: ConstantFunctions.doNothing,
      afterDeletePropertyGroup: ConstantFunctions.doNothing,
      afterChangeFormula: this.afterChangeItemFormula,
    },
    afterSetForm: this.afterSetItemFrom,
  };

  public render(): JSX.Element {
    const { Component, isAssignReady } = this.props;
    return (
      <Component
        assemblyAfterEffects={this.assemblyAfterEffects}
        itemAfterEffects={this.itemAfterEffects}
        isAssignReady={isAssignReady}
        {...this.props.componentProps}
      />
    );
  }

  @autobind
  private afterAssemblyPropertyChange(
    _f: MeasureForm,
    assemblyName: string,
    itemForm: AssemblyForm,
    itemName: string,
    _i: ItemForm,
    _p: string,
    propertiesGroup: PropertyGroupForm,
    propertyName: string,
  ): void {
    const { rootIds, childIds } = this.getSelectedIds();
    const rootPatch = this.getAssemblyPropertyRootAssignPatch(
      rootIds,
      assemblyName,
      itemForm,
      itemName,
      propertiesGroup,
      propertyName,
    );
    if (!rootPatch.length) {
      this.props.setAssignPiaReady();
      return;
    }

    this.sendUpdateProperty(rootPatch, childIds, assemblyName, itemName, propertyName);
  }

  private sendUpdateProperty(
    rootPatch: AssignPiaPatch[],
    childIds: string[],
    assemblyName: string,
    itemName: string,
    propertyName: string,
  ): void {
    const patchList: AssignPiaPatch[] = [
      ...rootPatch,
      ...resetAssignAfterChangeProperty(childIds, assemblyName, itemName, propertyName, this.props.assign),
    ];
    this.props.sendAssignPatch(patchList);
  }

  private getAssemblyPropertyRootAssignPatch(
    rootIds: string[],
    assemblyName: string,
    itemForm: AssemblyForm,
    itemName: string,
    propertiesGroup: PropertyGroupForm,
    propertyName: string,
  ): AssignPiaPatch[] {
    const patch: AssignPiaPatch[] = [];
    const field = propertiesGroup.find((p) => p.name === propertyName);
    const property = PropertyUpdater.getData(field);
    const item = {
      name: itemName,
      iconType: itemForm.itemGroupForm[itemName].iconType,
      properties: [property],
    };
    const assembly = {
      name: assemblyName,
      items: [item],
    };
    for (const rootId of rootIds) {
      const assign = this.props.assign[rootId];
      if (!assign || !assign.assemblies || !assign.assemblies.length) {
        this.props.setAssignPiaLoad();
        patch.push({
          ids: [rootId],
          addedAssemblies: [assembly],
        });
        continue;
      }
      const assignAssembly = assign.assemblies.find((a) => a.name === assemblyName);
      if (!assignAssembly) {
        this.props.setAssignPiaLoad();
        patch.push({
          ids: [rootId],
          addedAssemblies: [assembly],
        });
        continue;
      }
      const assignItem = assignAssembly.items.find((i) => i.name === itemName);
      if (!assignItem) {
        this.props.setAssignPiaLoad();
        patch.push({
          ids: [rootId],
          updatedAssemblies: [
            {
              name: assemblyName,
              addedItems: [item],
            },
          ],
        });
        continue;
      }
      const assignProperty = assignItem.properties.find((p) => p.name === propertyName);
      if (assignProperty) {
        if (!this.isPropertyValueChanged(assignProperty.value, property.value)) {
          continue;
        }
        patch.push({
          ids: [rootId],
          updatedAssemblies: [
            {
              name: assemblyName,
              updatedItems: [
                {
                  name: itemName,
                  iconType: itemForm.itemGroupForm[itemName]?.iconType,
                  updatedProperties: [property],
                },
              ],
            },
          ],
        });
      } else {
        this.props.setAssignPiaLoad();
        patch.push({
          ids: [rootId],
          updatedAssemblies: [
            {
              name: assemblyName,
              updatedItems: [
                {
                  name: itemName,
                  iconType: itemForm.itemGroupForm[itemName]?.iconType,
                  addedProperties: [property],
                },
              ],
            },
          ],
        });
      }
    }
    return patch;
  }

  @autobind
  private afterAssemblyPropertyDelete(
    _f: MeasureForm,
    assemblyName: string,
    itemForm: AssemblyForm,
    itemName: string,
    _i: ItemForm,
    _propertyGroupName: string,
    _propertiesGroup: PropertyGroupForm,
    propertyName: string,
  ): void {
    const { rootIds, childIds } = this.getSelectedIds();
    const rootPatch = [];
    this.props.setAssignPiaLoad();
    for (const rootId of rootIds) {
      const isItemHasValue = Object
        .values(itemForm.itemGroupForm[itemName].itemForm.groupForm)
        .some(i => i.fields.length);
      let itemChange = {};
      if (isItemHasValue) {
        itemChange = {
          updatedItems: [
            {
              name: itemName,
              iconType: itemForm.itemGroupForm[itemName]?.iconType,
              deletedProperties: [propertyName],
            },
          ],
        };
      } else {
        itemChange = {
          deleteItems: [itemName],
        };
      }
      const patch: AssignPiaPatch = {
        ids: [rootId],
        updatedAssemblies: [
          {
            name: assemblyName,
            ...itemChange,
          },
        ],
      };
      rootPatch.push(patch);
    }
    if (!rootPatch.length) {
      this.props.setAssignPiaReady();
      return;
    }

    this.sendUpdateProperty(rootPatch, childIds, assemblyName, itemName, propertyName);
  }

  @autobind
  private afterSetAssemblyForm(form: MeasureForm): void {
    this.setSelectedAssembliesIds(form);
  }

  @autobind
  private afterSetItemFrom(form: AssemblyForm): void {
    this.setSelectedItemsIds(form);
  }

  @autobind
  private afterAddAssembly(form: MeasureForm, _: string, addedAssembliesForm: GroupForm): void {
    this.props.setAssignPiaLoad();
    this.setSelectedAssembliesIds(form);
    const patch: AssignPiaPatch[] = this.getAddAssemblyPatches(addedAssembliesForm);
    this.props.sendAssignPatch(patch);
    const targetType = this.getTargetType(patch[0].ids[0]);
    this.props.sendEvent(MetricNames.assignItemAssembly.assignEntity, {
      piaType: 'Assembly',
      targetType,
    });
  }

  private getAddAssemblyPatches(addedAssembliesForm: GroupForm): AssignPiaPatch[] {
    const nestedSelection = this.getNestedSelectionInfo();
    const addedAssemblies = AssemblyUpdater.getData(addedAssembliesForm);
    const patches: Record<string, AssignPiaPatch> = {};

    const getOrCreatePatch = (rootId: string): AssignPiaPatch => {
      if (!patches[rootId]) {
        patches[rootId] = { ids: [rootId], addedAssemblies: [], updatedAssemblies: [] };
      } else {
        patches[rootId].addedAssemblies = patches[rootId].addedAssemblies || [];
        patches[rootId].updatedAssemblies = patches[rootId].updatedAssemblies || [];
      }
      return patches[rootId];
    };

    for (const [rootId, childIds] of Object.entries(nestedSelection)) {
      const patch = getOrCreatePatch(rootId);
      const assign = this.props.assign[rootId];

      for (const assembly of addedAssemblies) {
        const sourceItem = assign?.assemblies.find((i) => i.name === assembly.name);
        if (sourceItem) {
          patch.updatedAssemblies.push({ ...assembly, addedItems: assembly.items });
        } else {
          patch.addedAssemblies.push(assembly);
        }
      }
      for (const childPatch of this.resetAssignPatchesIterator(
        childIds,
        new Set(),
        new Set(addedAssemblies.map(a => a.name)),
      )) {
        for (const id of childPatch.ids) {
          if (patches[id]) {
            patches[id] = {
              ...patches[id],
              ...childPatch,
            };
          } else {
            patches[id] = childPatch;
          }
        }
      }
    }
    return Object.values(patches);
  }

  @autobind
  private afterDeleteAssembly(form: MeasureForm, assemblyName: string): void {
    this.props.setAssignPiaLoad();
    this.setSelectedAssembliesIds(form);
    const { rootIds, childIds } = this.getSelectedIds();
    const patch: AssignPiaPatch[] = [];
    for (const rootId of rootIds) {
      const assembly: AssignedPiaAssembly = {
        name: assemblyName,
        items: null,
      };
      const assign = this.props.assign[rootId];
      if (!assign || !assign.assemblies || !assign.assemblies.length) {
        patch.push({
          ids: [rootId],
          addedAssemblies: [assembly],
        });
        continue;
      }
      const assignAssembly = assign.assemblies.find((a) => a.name === assemblyName);
      if (!assignAssembly) {
        patch.push({
          ids: [rootId],
          addedAssemblies: [assembly],
        });
        continue;
      }
      patch.push({
        ids: [rootId],
        deletedAssemblies: [assemblyName],
      });
    }

    this.props.sendAssignPatch([...patch, ...this.resetAssign(childIds, new Set(), new Set([assemblyName]))]);
  }

  private setSelectedAssembliesIds(form: MeasureForm): void {
    const ids = arrayUtils.filterMap(
      this.props.assembliesDataBase,
      (assembly) => assembly.name in form.assemblyGroupForm,
      (assembly) => assembly.id,
    );
    this.props.setSelectedAssembliesIds(ids);
  }

  @autobind
  private afterChangeAssemblyFormula(
    _f: MeasureForm,
    assemblyName: string,
    itemForm: AssemblyForm,
    itemName: string,
    _: ItemForm,
    _gN: string,
    _g: PropertyGroupForm,
    field: PropertyField,
  ): void {
    const { rootIds, childIds } = this.getSelectedIds();
    const patch: AssignPiaPatch[] = [];
    const property = PropertyUpdater.getData(field);

    const item = {
      name: itemName,
      iconType: itemForm.itemGroupForm[itemName].iconType,
      properties: [property],
    };
    const assembly = {
      name: assemblyName,
      items: [item],
    };
    for (const rootId of rootIds) {
      const assign = this.props.assign[rootId];
      if (!assign || !assign.assemblies || !assign.assemblies.length) {
        patch.push({
          ids: [rootId],
          addedAssemblies: [assembly],
        });
        continue;
      }
      const assignAssembly = assign.assemblies.find((a) => a.name === assemblyName);
      if (!assignAssembly) {
        patch.push({
          ids: [rootId],
          addedAssemblies: [assembly],
        });
        continue;
      }
      const assignItem = assignAssembly.items.find((i) => i.name === itemName);
      if (!assignItem) {
        patch.push({
          ids: [rootId],
          updatedAssemblies: [
            {
              name: assemblyName,
              addedItems: [item],
            },
          ],
        });
        continue;
      }

      const { addedProperties } = PropertyGroupUpdater.getData(itemForm.itemGroupForm[itemName]?.itemForm);
      const itemProperties = new Set(assignItem.properties.map((p) => p.name));
      if (itemProperties.has(field.name)) {
        patch.push({
          ids: [rootId],
          updatedAssemblies: [
            {
              name: assemblyName,
              updatedItems: [
                {
                  name: itemName,
                  iconType: itemForm.itemGroupForm[itemName]?.iconType,
                  updatedProperties: [property],
                  addedProperties: addedProperties.filter((x) => !itemProperties.has(x.name)),
                },
              ],
            },
          ],
        });
      } else {
        patch.push({
          ids: [rootId],
          updatedAssemblies: [
            {
              name: assemblyName,
              updatedItems: [
                {
                  name: itemName,
                  iconType: itemForm.itemGroupForm[itemName]?.iconType,
                  addedProperties: [property, ...addedProperties.filter((x) => !itemProperties.has(x.name))],
                },
              ],
            },
          ],
        });
      }

    }

    this.props.sendAssignPatch([
      ...patch,
      ...resetAssignAfterChangeProperty(childIds, assemblyName, itemName, property.name, this.props.assign),
    ]);
  }

  @autobind
  private afterChangeItemFormula(
    form: AssemblyForm,
    itemName: string,
    _: ItemForm,
    _gN: string,
    _g: PropertyGroupForm,
    field: PropertyField,
  ): void {
    const { childIds, rootIds } = this.getSelectedIds();
    const property = PropertyUpdater.getData(field);
    const patch: AssignPiaPatch[] = [];
    const { addedProperties } = PropertyGroupUpdater.getData(form.itemGroupForm[itemName]?.itemForm);
    for (const rootId of rootIds) {
      const item: AssignedPiaItem = {
        name: itemName,
        iconType: form.itemGroupForm[itemName].iconType,
        properties: [property],
      };
      const assign = this.props.assign[rootId];
      if (!assign || !assign.items || !assign.items.length) {
        patch.push({
          ids: [rootId],
          addedItems: [item],
        });
        continue;
      }
      const assignItem = assign.items.find((a) => a.name === itemName);
      if (!assignItem) {
        patch.push({
          ids: [rootId],
          addedItems: [item],
        });
        continue;
      }
      const itemProperties = new Set(assignItem.properties.map((p) => p.name));
      const propertiesToAdd = addedProperties.filter((p) => !itemProperties.has(p.name));
      if (itemProperties.has(field.name)) {
        patch.push({
          ids: [rootId],
          updatedItems: [
            {
              name: itemName,
              iconType: form.itemGroupForm[itemName]?.iconType,
              updatedProperties: [property],
              addedProperties: propertiesToAdd,
            },
          ],
        });
      } else {
        patch.push({
          ids: [rootId],
          updatedItems: [
            {
              name: itemName,
              iconType: form.itemGroupForm[itemName]?.iconType,
              addedProperties: [property, ...propertiesToAdd],
            },
          ],
        });
      }
    }

    this.props.sendAssignPatch([
      ...patch,
      ...resetAssignAfterChangeProperty(childIds, undefined, itemName, property.name, this.props.assign),
    ]);
  }

  @autobind
  private afterItemPropertyChange(
    form: AssemblyForm,
    itemName: string,
    _f: ItemForm,
    _fN: string,
    propertiesGroup: PropertyGroupForm,
    propertyName: string,
  ): void {
    const { rootIds, childIds } = this.getSelectedIds();
    const field = propertiesGroup.find((f) => f.name === propertyName);
    const property = PropertyUpdater.getData(field);
    const patch: AssignPiaPatch[] = [];
    for (const rootId of rootIds) {
      const item: AssignedPiaItem = {
        name: itemName,
        iconType: form.itemGroupForm[itemName].iconType,
        properties: [property],
      };
      const assign = this.props.assign[rootId];
      if (!assign || !assign.items || !assign.items.length) {
        this.props.setAssignPiaLoad();
        patch.push({
          ids: [rootId],
          addedItems: [item],
        });
        continue;
      }
      const assignItem = assign.items.find((a) => a.name === itemName);
      if (!assignItem) {
        this.props.setAssignPiaLoad();
        patch.push({
          ids: [rootId],
          addedItems: [item],
        });
        continue;
      }
      const assignProperty = assignItem.properties.find((p) => p.name === propertyName);
      if (assignProperty) {
        if (!this.isPropertyValueChanged(assignProperty.value, property.value)) {
          continue;
        }
        patch.push({
          ids: [rootId],
          updatedItems: [
            {
              name: itemName,
              iconType: form.itemGroupForm[itemName]?.iconType,
              updatedProperties: [property],
            },
          ],
        });
      } else {
        this.props.setAssignPiaLoad();
        patch.push({
          ids: [rootId],
          updatedItems: [
            {
              name: itemName,
              iconType: form.itemGroupForm[itemName]?.iconType,
              addedProperties: [property],
            },
          ],
        });
      }
    }

    if (!patch.length) {
      this.props.setAssignPiaReady();
      return;
    }

    this.props.sendAssignPatch([
      ...patch,
      ...resetAssignAfterChangeProperty(childIds, undefined, itemName, propertyName, this.props.assign),
    ]);
  }

  @autobind
  private afterItemPropertyDelete(
    form: AssemblyForm,
    itemName: string,
    _f: ItemForm,
    _fN: string,
    _propertiesGroup: PropertyGroupForm,
    propertyName: string,
  ): void {
    const { rootIds, childIds } = this.getSelectedIds();
    const patches: AssignPiaPatch[] = [];
    this.props.setAssignPiaLoad();
    for (const rootId of rootIds) {
      const isItemHasValue = Object.values(form.itemGroupForm[itemName].itemForm.groupForm).some(g => g.fields.length);
      const patch: AssignPiaPatch = isItemHasValue
        ? {
          ids: [rootId],
          updatedItems: [
            {
              name: itemName,
              iconType: form.itemGroupForm[itemName]?.iconType,
              deletedProperties: [propertyName],
            },
          ],
        }
        : {
          ids: [rootId],
          deletedItems: [itemName],
        };
      patches.push(patch);
    }
    if (!patches.length) {
      this.props.setAssignPiaReady();
      return;
    }
    this.props.sendAssignPatch([
      ...patches,
      ...resetAssignAfterChangeProperty(childIds, undefined, itemName, propertyName, this.props.assign),
    ]);
  }

  @autobind
  private afterAddItem(form: AssemblyForm, _: string, itemGroup: GroupForm): void {
    this.props.setAssignPiaLoad();
    this.setSelectedItemsIds(form);
    const patches = this.getAddItemsPatches(form, itemGroup);
    this.props.sendAssignPatch(patches);
    const targetType = this.getTargetType(patches[0].ids[0]);
    this.props.sendEvent(MetricNames.assignItemAssembly.assignEntity, {
      piaType: 'Item',
      targetType,
    });
  }

  private getAddItemsPatches(form: AssemblyForm, itemGroup: GroupForm): AssignPiaPatch[] {
    const nestedSelection = this.getNestedSelectionInfo();
    const items = this.getItems(form);
    const addedItems = items.filter((i) => !!itemGroup[i.name]);
    const patches: Record<string, AssignPiaPatch> = {};

    const getOrCreatePatch = (rootId: string): AssignPiaPatch => {
      if (!patches[rootId]) {
        patches[rootId] = { ids: [rootId], addedItems: [], updatedItems: [] };
      } else {
        patches[rootId].addedItems = patches[rootId].addedItems || [];
        patches[rootId].updatedItems = patches[rootId].updatedItems || [];
      }
      return patches[rootId];
    };

    for (const [rootId, childIds] of Object.entries(nestedSelection)) {
      const patch = getOrCreatePatch(rootId);
      const assign = this.props.assign[rootId];

      for (const item of addedItems) {
        const sourceItem = assign?.items.find((i) => i.name === item.name);
        if (sourceItem) {
          patch.updatedItems.push({ ...item, addedProperties: item.properties });
        } else {
          patch.addedItems.push(item);
        }
      }
      for (const childPatch of this.resetAssignPatchesIterator(
        childIds,
        new Set(addedItems.map(i => i.name)),
        new Set(),
      )) {
        for (const id of childPatch.ids) {
          if (patches[id]) {
            patches[id] = {
              ...patches[id],
              ...childPatch,
            };
          } else {
            patches[id] = childPatch;
          }
        }
      }
    }
    return Object.values(patches);
  }

  @autobind
  private afterDeleteItem(form: AssemblyForm, itemName: string): void {
    this.props.setAssignPiaLoad();
    this.setSelectedItemsIds(form);
    const { rootIds, childIds } = this.getSelectedIds();
    const patch: AssignPiaPatch[] = [];
    for (const rootId of rootIds) {
      const iconType = form.removedItems[itemName] ? form.removedItems[itemName].iconType : '';
      const item: AssignedPiaItem = {
        name: itemName,
        iconType,
        properties: null,
      };
      const assign = this.props.assign[rootId];
      if (!assign || !assign.items || !assign.items.length) {
        patch.push({
          ids: [rootId],
          addedItems: [item],
        });
        continue;
      }
      const assignItem = assign.items.find((a) => a.name === itemName);
      if (!assignItem) {
        patch.push({
          ids: [rootId],
          addedItems: [item],
        });
        continue;
      }
      patch.push({
        ids: [rootId],
        deletedItems: [itemName],
      });
    }
    this.props.sendAssignPatch([...patch, ...this.resetAssign(childIds, new Set([itemName]), new Set())]);
  }

  private getItems(form: AssemblyForm): Item[] {
    const data = ItemUpdater.getData(form);
    const items: Item[] = data.updatedItems.map((u) => {
      const item: Item = {
        name: u.name,
        iconType: u.iconType,
        properties: u.updatedProperties,
      };
      return item;
    });

    return [...items, ...data.addedItems];
  }

  private setSelectedItemsIds(form: AssemblyForm): void {
    const ids = arrayUtils.filterMap(
      this.props.itemsDataBase,
      (item) => item.name in form.itemGroupForm,
      (item) => item.id,
    );
    this.props.setSelectedItemsIds(ids);
  }

  private getNestedSelectionInfo(): Record<string, string[]> {
    const { selectedMeasureGroupIds, selectedMeasureIds, groups } = this.props;
    const result: Record<string, string[]> = {};
    const measureIds = new Set(selectedMeasureIds);
    const groupMap: Record<string, DrawingsGeometryGroup> = {};
    const parentIdWithChildMap: Record<string, string[]> = {};
    groups.forEach(g => {
      groupMap[g.id] = g;
      if (g.parentId) {
        if (parentIdWithChildMap[g.parentId]) {
          parentIdWithChildMap[g.parentId].push(g.id);
        } else {
          parentIdWithChildMap[g.parentId] = [g.id];
        }
      }
    });
    const filteredSelectedGroups = selectedMeasureGroupIds.filter(id => {
      const group = groupMap[id];
      return !(group.parentId && selectedMeasureGroupIds.includes(group.parentId));
    });
    const selectedGroupIds = new Set(filteredSelectedGroups);
    const usedMeasureIds = new Set<string>();

    const getChilds = (id: string): string[] => {
      const group = groupMap[id];
      const childsIds = parentIdWithChildMap[id] || [];
      let resultList = group.measurements;
      group.measurements.forEach((m) => usedMeasureIds.add(m));

      childsIds.forEach(childId => {
        resultList = resultList.concat(getChilds(childId));
      });

      resultList = resultList.concat(childsIds);

      return resultList;
    };


    const addToResult = (rootId: string, childId?: string): void => {
      result[rootId] = result[rootId] || [];
      if (childId) {
        result[rootId].push(childId);
      }
    };

    for (const group of groups) {
      if (selectedGroupIds.has(group.id)) {
        if (selectedGroupIds.has(group.parentId)) {
          addToResult(group.parentId, group.id);
        } else {
          addToResult(group.id);
          const childs = getChilds(group.id);
          result[group.id] = childs;
        }
      }
    }

    for (const measureId of measureIds) {
      if (usedMeasureIds.has(measureId)) {
        continue;
      }
      addToResult(measureId);
    }

    return result;
  }

  private getTargetType(id: string): string {
    const { groups } = this.props;

    const group = groups.find((g) => g.id === id);
    if (group) {
      return 'Group';
    }

    return 'Element';
  }

  private getSelectedIds(): { rootIds: string[], childIds: string[] } {
    const { selectedMeasureGroupIds, selectedMeasureIds, aiAnnotation, groups } = this.props;
    const rootIds = [];
    const childIds = [];
    const selectedGroupIds = new Set(selectedMeasureGroupIds);
    const measureIds = new Set(selectedMeasureIds);

    measureIds.forEach((measureId) => {
      const measure = aiAnnotation.geometry[measureId];
      if (selectedGroupIds.has(measure.groupId)) {
        childIds.push(measureId);
      } else {
        rootIds.push(measureId);
      }
    });

    selectedGroupIds.forEach((groupId) => {
      const group = groups.find((g) => g.id === groupId);
      if (selectedGroupIds.has(group.parentId)) {
        childIds.push(groupId);
      } else {
        rootIds.push(groupId);
      }
    });

    for (const id of rootIds) {
      const childGroups = groups.filter((g) => g.parentId && g.parentId === id && !childIds.includes(g.id));
      if (childGroups.length) {
        childGroups.map(child => childIds.push(child.id));
      }
    }
    return {
      rootIds,
      childIds,
    };
  }

  private *resetAssignPatchesIterator(
    ids: string[],
    updatedItemsName: Set<string>,
    updatedAssembliesNames: Set<string>,
  ): Iterable<AssignPiaPatch> {
    for (const id of ids) {
      const assign = this.props.assign[id];
      if (assign) {
        yield {
          ids: [id],
          deletedAssemblies: arrayUtils.filterMap(
            assign.assemblies || [],
            a => updatedAssembliesNames.has(a.name),
            a => a.name),
          deletedItems: arrayUtils.filterMap(
            assign.items || [],
            i => updatedItemsName.has(i.name),
            i => i.name),
        };
      }
    }
  }

  private resetAssign(
    ids: string[],
    updatedItemsName: Set<string>,
    updatedAssembliesNames: Set<string>,
  ): AssignPiaPatch[] {
    const patch: AssignPiaPatch[] = [...this.resetAssignPatchesIterator(ids, updatedItemsName, updatedAssembliesNames)];
    return patch;
  }

  private isPropertyValueChanged(newValue: AllPropertyValues, oldValue: AllPropertyValues): boolean {
    if (newValue.value !== oldValue.value) {
      return true;
    }
    if (FormatTypeGuards.isNumeric(newValue.format)) {
      return newValue.format.unit !== (oldValue.format as NumericFormat).unit;
    }
    return false;
  }
}

const mapStateProps = (state: State): StateProps => {
  return {
    selectedMeasureGroupIds: state.drawings.selectGeometryGroup,
    assembliesDataBase: state.twoDDatabase.assemblies,
    itemsDataBase: state.twoDDatabase.items,
    selectedMeasureIds: state.drawings.selectedInstances,
    assign: state.twoD.assignPia,
    aiAnnotation: state.drawings.aiAnnotation,
    groups: state.drawings.drawingGeometryGroups,
    isAssignReady: state.twoD.isAssignPiaReady,
  };
};

const mapDispatchProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    setAssignPiaLoad: () => dispatch(TwoDActions.setAssignPiaLoad()),
    setSelectedAssembliesIds: (ids) => dispatch(TwoDActions.setSelectedAssemblyIds(ids)),
    setSelectedItemsIds: (ids) => dispatch(TwoDActions.setSelectedItemsIds(ids)),
    sendAssignPatch: (assign) => {
      const iterationId = UuidUtil.generateUuid();
      dispatch(TwoDActions.setExportExcelLoad(iterationId));
      dispatch(TwoDElementViewActions.changeColumnAfterAssign(assign));
      dispatch(TwoDActions.sendAssignPatch(assign, iterationId, () => {
        dispatch(TwoDElementViewActions.handleChangeAssign(assign));
      }));
    },
    setAssignPiaReady: () => dispatch(TwoDActions.setAssignPiaReady()),
  };
};

const withAnalytics = withAnalyticsContext(WithAfterEffectsComponent);
const WithRedux = connect(mapStateProps, mapDispatchProps)(withAnalytics);

export const withAfterEffects =
  <TProps extends WithAfterEffectsProps>(
    Component: React.ComponentType<TProps>,
  ): React.ComponentType<Pick<TProps, Exclude<keyof TProps, keyof WithAfterEffectsProps>>> =>
    (props: TProps) =>
      <WithRedux Component={Component} componentProps={props} />;
