import { Switcher, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';


interface Props {
  title: string;
  onChange: () => void;
  value: boolean;
  disabled?: boolean;
}


const SwitcherItemComponent: React.FC<Props> = ({
  title,
  onChange,
  value,
  disabled,
}) => {
  return (
    <Styled.MenuItemWrapper withBorder={true}>
      <Styled.SwitcherItem>
        <Text fontSize={14} color={'gray'}>{title}</Text>
        <Switcher
          size={'m'}
          checked={value}
          onChange={onChange}
          disabled={disabled}
        />
      </Styled.SwitcherItem>
    </Styled.MenuItemWrapper>
  );
};


export const SwitcherItem = React.memo(SwitcherItemComponent);
