import { CSVFormatData } from 'common/components/excel-table/utils/range-helper';
import { ValueHelper } from 'common/utils/value-helper';
import { TableSettings } from '../../../../units/2d/interfaces';
import { ExcelFormulaHelper } from './excel-formula-helper';
import { getFormulaToPaste } from './get-formula-to-paste';

export interface ClipboardDataType {
  value: string | number;
  type: string;
  settings?: TableSettings;
}

const CLIPBOARD_VALUE = 'CLIPBOARD_VALUE';

const formatFromClipboard = (
  clipboardValue: CSVFormatData | string,
  toCell: string,
  sheetId: string,
): ClipboardDataType => {
  if (typeof clipboardValue === 'string') {
    const value = ValueHelper.isNumberValue(clipboardValue.trim())
      ? Number(clipboardValue)
      : clipboardValue;
    return { value, type: CLIPBOARD_VALUE };
  }

  const { fromCell, data, settings } = clipboardValue;
  const stringValue = data.toString();
  let value: string | number;
  if (ValueHelper.isNumberValue(stringValue.trim())) {
    value = Number(data);
  } else if (stringValue.startsWith('=')) {
    value = getFormulaToPaste(fromCell, toCell, stringValue, sheetId);
  } else {
    value = data;
  }

  return { value, settings, type: CLIPBOARD_VALUE };
};

const formatForClipboard = (
  columnId: string, rowId: string, data: string, value: string, settings?: TableSettings,
): string => {
  const cellLink = ExcelFormulaHelper.getCellLink(null, columnId, rowId);
  const payload: CSVFormatData = { fromCell: cellLink, data, value, settings };
  return JSON.stringify(payload);
};

const isExpectedClipboardValue = (clipboardValue: string, expectedValue: string): boolean => {
  return clipboardValue === expectedValue;
};

export const ClipboardHelper = {
  CLIPBOARD_VALUE,
  formatFromClipboard,
  formatForClipboard,
  isExpectedClipboardValue,
};
