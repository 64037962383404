import { AppRoute } from 'common/routing/app-route';
import { SubscribePageQueryParams } from './routes-params';

export const UrlsFabric = (indexPath: string): any => {
  const subscribe =     new AppRoute<SubscribePageQueryParams>(`${indexPath}/subscribe`);
  const billingNotice = new AppRoute(`${indexPath}/billing-notice`);
  const renewSubscription = new AppRoute(`${indexPath}/renew-subscription`);
  const resumeSubscription = new AppRoute(`${indexPath}/resume-subscruption`);
  const updateSubscription = new AppRoute(`${indexPath}/update-subscription`);
  const upgradeSubscription = new AppRoute(`${indexPath}/upgrade-plans`);

  return {
    subscribe,
    billingNotice,
    renewSubscription,
    updateSubscription,
    upgradeSubscription,
    resumeSubscription,
  };
};
