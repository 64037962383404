import { CompanyNotification } from 'unit-2d-notification/store-slice/interfaces';
import { CommentData, CommentDataAction } from 'unit-2d-notification/store-slice/interfaces/notification-data';

const getCreateMessageText = (dataActionType: CommentDataAction): string => {
  switch (dataActionType) {
    case CommentDataAction.CreateMessage:
      return 'commented in the thread in project';
    case CommentDataAction.ResolveThread:
      return 'resolved the thread in project';
    case CommentDataAction.CreateThread:
      return 'created a thread in project';
    case CommentDataAction.AddToThread:
      return 'added you to the thread in project';
    case CommentDataAction.OpenThread:
      return 'reopened the thread in project';
    default:
      throw new Error(`Invalid action type in Comment notification: ${dataActionType}`);
  }
};

export const getCommentText = (notification: CompanyNotification<CommentData>): string => {
  return getCreateMessageText(notification.data.action);
};
