import autobind from 'autobind-decorator';
import * as React from 'react';
import { Field } from 'redux-form';

import './profile-section.scss';

import { ImageUploader } from 'common/components/image-uploader';
import { StateStatus } from 'common/interfaces/account';
import { KreoButton, MaterialInputField, MaterialInputProps } from 'common/UIKit';
import { CommonsApi } from '../../../../../api/common';
import { Required } from '../../../../../components/dialog/validators';
import { AccountApi } from '../../../api';

interface Props {
  setImage: (name: string) => void;
  deleteImage: () => void;
  saveProfileSettings: () => void;
  onDialogClose: () => void;
  logOut: () => void;
  userId: string;
  accountStatus: StateStatus;
  canEditCompany: boolean;
  submitDisabled: boolean;
}

const validateRequired = [Required];

export class ProfileSection extends React.Component<Props> {
  public render(): React.ReactNode {
    const { accountStatus, userId, submitDisabled } = this.props;
    let err = null;
    if (accountStatus.hasError) {
      err = <div className='profile-section__error'>{accountStatus.message}</div>;
    }
    return (
      <div className='profile-section'>
        <div className='profile-section__avatar'>
          <ImageUploader
            previewUrl={CommonsApi.tempFileLink}
            defaultImagePreviewUrl={AccountApi.buildAvatarLinkPostFix(userId)}
            deleteImage={this.props.deleteImage}
            onLoadEnd={this.props.setImage}
          />
        </div>
        {err}
        <div className='profile-section__inputs'>
          <div className='profile-section__inputs-column'>
            <Field<MaterialInputProps>
              className='profile-section__material-input'
              name='email'
              component={MaterialInputField}
              label='Email'
              disabled={true}
              autocomplete='email'
            />
            <div className='profile-section__inputs-row'>
              <Field<MaterialInputProps>
                name='firstName'
                component={MaterialInputField}
                className='profile-section__material-input'
                label='First Name'
                validate={validateRequired}
                autocomplete='name'
              />
              <Field<MaterialInputProps>
                name='lastName'
                component={MaterialInputField}
                className='profile-section__material-input'
                label='Last Name'
                validate={validateRequired}
                autocomplete='family-name'
              />
            </div>
            {this.props.canEditCompany && (
              <Field<MaterialInputProps>
                name='companyName'
                component={MaterialInputField}
                className='profile-section__material-input'
                label='Company'
                validate={validateRequired}
                autocomplete='organization'
              />
            )}
          </div>
          <div className='profile-section__inputs-column__passwords'>
            <Field<MaterialInputProps>
              name='oldPassword'
              component={MaterialInputField}
              className='profile-section__material-input'
              label='Old Password'
              type='text'
              autocomplete='old-password'
              title=''
            />
            <Field<MaterialInputProps>
              name='newPassword'
              component={MaterialInputField}
              className='profile-section__material-input'
              label='New Password'
              type='text'
              autocomplete='new-password'
              title=''
            />
          </div>
        </div>
        <div className='profile-section__actions'>
          <div>
            <KreoButton
              htmlButtonType='submit'
              mode='submit'
              size='large'
              caption='Save changes'
              disabled={submitDisabled}
            />
          </div>
          <KreoButton
            htmlButtonType='button'
            mode='ghost'
            size='large'
            caption='Log Out'
            onClick={this.logOut}
          />

        </div>
      </div>
    );
  }

  @autobind
  private logOut(): void {
    this.props.onDialogClose();
    this.props.logOut();
    localStorage.clear();
  }
}
