function getNumberEnumKeys<T>(enumObject: T): string[] {
  return Object.keys(enumObject).filter(key => typeof enumObject[key] === 'number');
}

function getNumberEnumValues<T>(enumObject: T): number[] {
  return Object.keys(enumObject)
    .filter(key => typeof enumObject[key] === 'string')
    .map(value => parseInt(value, 10));
}

function getStringEnumValues<T>(enumObject: T): string[] {
  return Object.values(enumObject);
}

function isGreaterThan<T>(
  enumSequence: T[],
  current: T,
  target: T,
  equal: boolean = false,
): boolean {
  const currentIndex = enumSequence.findIndex(x => x === current);
  const targetIndex = enumSequence.findIndex(x => x === target);
  return currentIndex > targetIndex || (equal && currentIndex === targetIndex);
}

function isLessThan<T>(
  enumSequence: T[],
  current: T,
  target: T,
  equal: boolean = false,
): boolean {
  const currentIndex = enumSequence.findIndex(x => x === current);
  const targetIndex = enumSequence.findIndex(x => x === target);
  return currentIndex < targetIndex || (equal && currentIndex === targetIndex);
}

export const EnumUtils = {
  getNumberEnumKeys,
  getNumberEnumValues,
  getStringEnumValues,
  isGreaterThan,
  isLessThan,
};
