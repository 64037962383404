import { useCallback } from 'react';

import { useActionDispatch } from 'common/hooks';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { MagicSearchActions } from '../../actions/creators/magic-search';

export function useRun(): (query: string, dpi: number) => void {
  const { sendEvent } = useAnalytics();
  const runSearch = useActionDispatch(MagicSearchActions.textSearch);

  return useCallback((query: string, dpi: number) => {
    if (query) {
      runSearch(query);
    }
    sendEvent(MetricNames.measurements.requestAM2, { query, dpi });
  }, [runSearch, sendEvent]);
}
