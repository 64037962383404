import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { reloadWrapper } from 'common/utils/reload-wrapper';
import { ReconnectDialog } from './reconnect-dialog';

export const RECONNECTION_DIALOG_NAME = 'RECONNECTION_DIALOG_NAME';
const RECONNECT_DELAY = 1800000;

interface StateProps {
  firstName: string;
}

interface DispatchProps {
  showDialog: (name: string) => void;
}

interface Props extends StateProps, DispatchProps {
  children?: React.ReactNode;
}

class ReconnectDialogWrapperComponent extends React.Component<Props> {
  private reconnectDeferredExecutor: DeferredExecutor = new DeferredExecutor(RECONNECT_DELAY);

  public componentDidMount(): void {
    this.addEventListeners();
    this.reconnectDeferredExecutor.execute(this.openReconnectDialog);
  }

  public componentWillUnmount(): void {
    this.removeEventListeners();
    this.reconnectDeferredExecutor.reset();
  }

  public render(): JSX.Element {
    const { firstName } = this.props;
    const text = `${firstName ? firstName : 'Hi there'}, are you still here?`;

    return (
      <>
        <ReconnectDialog
          description={text}
          buttonName={'Yes, reconnect'}
          onClick={this.refresh}
          controlName={RECONNECTION_DIALOG_NAME}
          onClose={this.refresh}
        />
        {this.props.children}
      </>
    );
  }

  @autobind
  private addEventListeners(): void {
    document.addEventListener('click', this.clearInterval);
    document.addEventListener('keypress', this.clearInterval);
    document.addEventListener('mousemove', this.clearInterval);
  }

  @autobind
  private removeEventListeners(): void {
    document.removeEventListener('click', this.clearInterval);
    document.removeEventListener('keypress', this.clearInterval);
    document.removeEventListener('mousemove', this.clearInterval);
  }

  @autobind
  private clearInterval(): void {
    this.reconnectDeferredExecutor.execute(this.openReconnectDialog);
  }

  @autobind
  private openReconnectDialog(): void {
    this.props.showDialog(RECONNECTION_DIALOG_NAME);
  }

  @autobind
  private refresh(): void {
    reloadWrapper('reconnect dialog');
  }
}


const mapStateToProps = (state: State): StateProps => {
  return {
    firstName: state.account.firstName,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    showDialog: dialogName => dispatch(KreoDialogActions.openDialog(dialogName)),
  };
};

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const ReconnectDialogWrapper = reduxConnector(ReconnectDialogWrapperComponent);
