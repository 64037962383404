import autobind from 'autobind-decorator';
import * as paper from 'paper';

import {
  BucketAiStream,
  BucketAiStreamConfig,
  BucketInput,
} from 'common/components/drawings/api/bucket-ai/bucket-ai-stream';
import { StreamedRequestController, StreamProgress } from 'common/components/drawings/api/streams';
import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { DrawingsScaleConstant } from 'common/components/drawings/constants/drawings-scale-constants';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { WizzardStatus } from 'common/components/drawings/enums/dropper-state';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import { MagicSearchUtils } from 'common/components/drawings/helpers/geometry/magic-search';
import { DrawingsRenderParams, DrawingsShortInfo, ShortPointDescription } from 'common/components/drawings/interfaces';
import { PdfAiPolygonClickPayloadExtended } from 'common/components/drawings/interfaces/api-payloads';
import {
  PdfAiClickResponse,
  PdfGeometry,
  PdfGeometryResponse,
  PdfGeometryStatus,
} from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { State } from 'common/interfaces/state';
import { store } from '../../../../../../store';
import { DestroyableObject, EngineObjectConfig } from '../../common';
import { DrawingsGeometryEntityPoint } from '../../drawings-geometry-entities';
import { NewDrawingSettings } from '../../interfaces';
import { BucketAiMasks } from './bucket-ai-masks';
import { BucketAIWindowsHelper } from './bucket-ai-windows-helper';

export interface BucketAIWithProcessingConfig extends EngineObjectConfig {
  layer: paper.Layer | paper.Group;
  colorCache: DrawingsColorCacheHelper;
  newDrawingStylesObserver: ContextObserver<NewDrawingSettings>;
  renderParamsContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
}


(window as any).BUCKET_POLYLINE = false;

const PREVIEW_STYLES = {
  shadowBlur: 4,
  dashArray: [6, 3],
  strokeWidth: 2,
  shadownOffset: [2, 2],
};

interface RequestPayload {
  isAuto: boolean;
  drawingInfo: DrawingsShortInfo;
  dpi: number;
}

export interface PointsInfo {
  points: ShortPointDescription[];
  statuses: Array<{ positive: boolean }>;
}

export class BucketAIBase<T extends BucketAIWithProcessingConfig = BucketAIWithProcessingConfig>
  extends DestroyableObject<T> {
  protected _points: DrawingsGeometryEntityPoint[] = [];
  protected _pointsGroup: paper.Group;
  protected _resultGroup: paper.Group;
  protected _destroyed: boolean = false;
  protected _progressContour: paper.Path;
  protected _windowsHelper: BucketAIWindowsHelper;
  protected _masks: BucketAiMasks;

  protected _streamController = new StreamedRequestController<BucketInput, PdfAiClickResponse, BucketAiStreamConfig>(
    BucketAiStream,
  );
  protected _canceller: () => void;

  constructor(config: T) {
    super(config);
    this._resultGroup = new paper.Group();
    this._resultGroup.addTo(this._config.layer);
    this._pointsGroup = new paper.Group();
    this._pointsGroup.addTo(this._config.layer);
    this._config.renderParamsContextObserver.subscribe(this.updateRenderParams);
  }

  public destroy(): void {
    this._destroyed = true;
    if (this._canceller) {
      this._canceller();
    }
    this._streamController.cancel();
    this._resultGroup.remove();
    this._config.renderParamsContextObserver.unsubscribe(this.updateRenderParams);
    this._points.forEach((point) => point.destroy());
    this._points = [];
    this._pointsGroup.remove();
  }

  public clear(): void {
    this._points.forEach((point) => point.destroy());
    this._points = [];
    this.removeMasks();
  }

  public async getBucketResults({
    isAuto,
    drawingInfo,
    dpi,
  }: RequestPayload): Promise<PdfGeometryResponse> {
    try {
      const {
        originalCalibrationLineLength,
        drawingCalibrationLineLength,
        paperSize,
        width,
        height,
        pdfId,
        pageNumber,
        drawingId,
      } = drawingInfo;
      const pointsInfo = this.getPoints();
      const state: State = store.getState();
      const project = state.projects.currentProject;
      const res = await this.streamRequest(
        {
          dpi,
          autoDpi: isAuto,
          scale: originalCalibrationLineLength / drawingCalibrationLineLength || 1,
          paperSize: paperSize || DrawingsScaleConstant.DEFAULT_FORMAT,
          type: (window as any).BUCKET_POLYLINE ? 'polyline' : 'polygon',
          userScreen: [0, 0, width, height],
          points: pointsInfo.points,
          pointsInfo: pointsInfo.statuses,
          text: '',
          fileId: pdfId,
          projectId: project.id,
          pageIdx: pageNumber,
          withPolygonizer: true,
        },
        drawingId,
      );
      if (!res.output) {
        return {
          status: PdfGeometryStatus.Empty,
          geometries: [],
          response: res,
        };
      }
      const geometries: PdfGeometry[] = res.geometries.map(({ value, confidence }) => {
        return {
          type: 'polygon',
          points: value.points,
          similarity: confidence,
          holes: value.holes || [],
        };
      });

      return {
        status: PdfGeometryStatus.Succcess,
        geometries,
        response: res,
      };
    } catch (e) {
      console.error(e);
      return {
        status: PdfGeometryStatus.Empty,
        geometries: [],
        response: null,
      };
    }
  }

  @autobind
  protected updateRenderParams({ zoom }: DrawingsRenderParams): void {
    if (this._progressContour) {
      this._progressContour.strokeWidth = PREVIEW_STYLES.strokeWidth / zoom;
      this._progressContour.dashArray = DrawingsCanvasUtils.scaleDashArray(
        PREVIEW_STYLES.dashArray,
        PREVIEW_STYLES.strokeWidth,
        zoom,
      );
      this._progressContour.shadowBlur = PREVIEW_STYLES.shadowBlur / zoom;
      this._progressContour.shadowOffset = new paper.Point(PREVIEW_STYLES.shadownOffset.map((x) => x / zoom));
    }
    if (this._points) {
      this._points.forEach((point) => {
        point.styles.shadowOffset = new paper.Point(0, 4 / zoom);
        point.styles.shadowBlur = 5 / zoom;
      });
    }
  }

  protected getPoints(): PointsInfo {
    return this._points.reduce<PointsInfo>((acc, x) => {
      acc.points.push(x.position);
      acc.statuses.push({ positive: x.metadata.isPositive });
      return acc;
    }, { points: [], statuses: [] });
  }

  protected createPoint(point: paper.Point | ShortPointDescription, isPositive: boolean): DrawingsGeometryEntityPoint {
    const result = new DrawingsGeometryEntityPoint({
      id: '',
      geometry: point,
      layer: this._pointsGroup,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      color: isPositive
        ? DrawingsCanvasColors.autocompleteColorInfo.stroke
        : DrawingsCanvasColors.warningStrokeColor,
      strokeWidth: 3,
      radius: 8,
      metadata: { isPositive },
    });

    result.styles.shadowOffset = new paper.Point(0, 4 / this._config.renderParamsContextObserver.getPrevContext().zoom);
    result.styles.shadowBlur = 5 / this._config.renderParamsContextObserver.getPrevContext().zoom;
    result.styles.shadowColor = new paper.Color(0, 0, 0, 0.5);
    return result;
  }

  protected async showMask(countours: ShortPointDescription[][]): Promise<void> {
    this.removeMasks();
    const paths = await MagicSearchUtils.contoursUnion(countours);
    this._masks = new BucketAiMasks({
      layer: this._config.layer,
      geometry: paths,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      withBorder: true,
    });
  }

  protected removeMasks(): void {
    this._masks?.destroy();
  }

  private streamRequest(
    input: PdfAiPolygonClickPayloadExtended,
    drawingId: string,
  ): Promise<PdfAiClickResponse> {
    return new Promise((resolve, reject) => {
      let cancelled = false;

      const onProgress = async ({ value, status }: StreamProgress<PdfAiClickResponse>): Promise<void> => {
        if (cancelled) {
          this.removeMasks();
          this._progressContour?.remove();
          return;
        }
        if (value.output.windows?.length) {
          this._windowsHelper = new BucketAIWindowsHelper({
            size: value.output.windows[0].size,
            layer: this._config.layer,
            renderParamsContextObserver: this._config.renderParamsContextObserver,
          });
        }
        if (status === WizzardStatus.Preview) {
          this.removeMasks();
          this._progressContour?.remove();
          this._canceller = null;
          resolve(value);
          return;
        }

        if (value.output.maskContours.length) {
          await this.showMask(value.output.maskContours.map((x) => x.points));
        }
        if (value.output.contours.length > 0) {
          this.showLoadingPreview(value.output.contours[0].points);
        }
      };

      this._streamController
        .run({
          request: { input },
          onProgress,
          fileId: input.fileId,
          drawingId,
          snap: true,
        })
        .catch(reject);
      this._canceller = () => {
        cancelled = true;
        this._streamController.cancel();
        this._canceller = null;
      };
    });
  }


  private showLoadingPreview(contour: ShortPointDescription[]): void {
    if (this._progressContour) {
      this._progressContour.remove();
    }

    if (!contour) {
      return;
    }

    const { zoom } = this._config.renderParamsContextObserver.getContext();
    this._progressContour = new paper.Path({
      segments: contour,
      strokeColor: DrawingsCanvasColors.utility,
      shadowColor: DrawingsCanvasColors.utility,
      shadowBlur: PREVIEW_STYLES.shadowBlur / zoom,
      strokeCap: 'round',
      strokeWidth: PREVIEW_STYLES.strokeWidth / zoom,
      dashArray: DrawingsCanvasUtils.scaleDashArray(
        PREVIEW_STYLES.dashArray,
        PREVIEW_STYLES.strokeWidth,
        zoom,
      ),
      shadowOffset: new paper.Point(PREVIEW_STYLES.shadownOffset.map((x) => x / zoom)),
      closed: true,
    });
    this._progressContour.addTo(this._config.layer);
  }
}
