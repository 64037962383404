import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './index.scss';

import { MaterialMenuItem, MaterialSelect } from 'common/UIKit/material';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';

interface Props {
  statusChanged: () => void;
  isPublic: boolean;
  disabled: boolean;
  className: string;
}

const SCENARIO_STATUS_MENU = [
  { name: 'Public', value: 'Public' },
  { name: 'Private', value: 'Private' },
  // { name: 'Master', value: 'Master' }, TODO: E.Mazurkevich
];

export class ScenarioStatusSelect extends React.Component<Props> {
  public render(): JSX.Element {
    const scenarioStatus = this.props.isPublic ? 'Public' : 'Private';
    const selectClassName = classNames(
      'scenario-status-select',
      { 'scenario-status-select--public': this.props.isPublic },
      // { 'scenario-status-select--master': !this.props.isMaster }, TODO: E.Mazurkevich
      this.props.className,
    );
    return (
      <MaterialSelect
        className={selectClassName}
        dropdownClassName='scenario-status-select__paper'
        autoWidth={true}
        value={scenarioStatus}
        onChange={this.change}
        displayedType={MaterialComponentType.Native}
        disabled={this.props.disabled}
      >
        {SCENARIO_STATUS_MENU.map((menuItem) => (
          <MaterialMenuItem className='companies-status-select__item' key={menuItem.value} value={menuItem.value}>
            {menuItem.name}
          </MaterialMenuItem>
        ))}
      </MaterialSelect>
    );
  }

  @autobind
  private change(): void {
    this.props.statusChanged();
  }
}
