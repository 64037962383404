import styled from 'styled-components';

const OptionContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  > div {
    margin-top: auto;
    margin-bottom: auto;
  }
  svg {
    fill: ${p => p.theme.color.turquoise};
  }
`;

const EditorContainer = styled.div`
  height: 20px;
  > div {
    > div {
      margin: unset;
      padding: unset;
    }
  }
`;

export const Styled = {
  OptionContainer,
  EditorContainer,
};
