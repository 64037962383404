
export enum SetType {
  List,
  RadioButton,
  Select,
}

export type FilterChangePayloadElement =
  Record<ShapesFilterValue, boolean> | ScheduleFilterValue | PagesFilterValue | string | OriginFilterValue;
export type CommonFilterValuesTypes = ShapesFilterValue | ScheduleFilterValue | PagesFilterValue | string;

export interface FilterChangePayload {
  filterType: AnnotationFilters;
  element: FilterChangePayloadElement;
}

export enum AnnotationFilters {
  Shapes = 'shapes',
  Pages = 'pages',
  Report = 'report',
  Color = 'color',
  Name = 'name',
  Assign = 'assign',
  Users = 'users',
  Origin = 'origin',
}

export enum OriginFilterValue {
  All = 'all',
  AutoMeasure = 'autoMeasure',
  Manual = 'manual',
}

export enum ShapesFilterValue {
  Points = 'points',
  Polygons = 'polygons',
  Lines = 'lines',
}

export enum PagesFilterValue {
  Current = 'current',
  All = 'all',
  Open = 'open',
}

export enum ScheduleFilterValue {
  Unscheduled = 'unscheduled',
  Scheduled = 'scheduled',
  CellRange = 'cellRange',
}

export enum AssignFilterValue {
  All = 'all',
  Assign = 'assign',
  UnAssign = 'unassign',
}

export interface FilterData {
  [AnnotationFilters.Shapes]: {
    [ShapesFilterValue.Points]: boolean,
    [ShapesFilterValue.Polygons]: boolean,
    [ShapesFilterValue.Lines]: boolean,
  };
  [AnnotationFilters.Pages]: PagesFilterValue;
  [AnnotationFilters.Report]: ScheduleFilterValue;
  [AnnotationFilters.Color]: string;
  [AnnotationFilters.Name]: string;
  [AnnotationFilters.Assign]: string;
  [AnnotationFilters.Users]: string;
  [AnnotationFilters.Origin]: OriginFilterValue;
}
