
import { twoDElementViewReducers } from '2d/components/2d-element-view/store-slice';
import { contextMenuReducers } from 'common/components/item-context-menu';
import { progressReducers } from 'common/components/progress';
import { multiViewReducerMethod } from 'common/utils/multi-view/multi-view-reducers';
import { twoDCommentsReducer } from 'unit-2d-comments/store-slice';
import { TwoDCopilotReducer } from 'unit-2d-copilot/store-slice';
import { twoDDatabaseReducer } from 'unit-2d-database/store-slice';
import { twoDFastNavigationReducer } from 'unit-2d-database/store-slice-fast-navigation';
import { twoDInfoPanelReducers } from 'unit-2d-info-panel/store-slice';
import { twoDNotificationsReducers } from 'unit-2d-notification/store-slice/store-slice';
import { twoDReportTemplateReducer } from 'unit-2d-report-template/store-slice';
import { fourDVisualisation } from '../units/projects/pages/four-d-visualisation/reducer';
import { subscriptionReducers } from '../units/subscription/store';
import account from './account';
import activityAssignment from './activity-assignment';
import activityGrouping from './activity-grouping';
import admin from './admin';
import analytics from './analytics';
import { bidPricingReducer } from './bid-pricing';
import ceActivityAssignment from './ce-activity-assignment';
import ceMeasurements from './ce-measurements';
import classification from './classification';
import common from './common';
import cost from './cost';
import { dashboard } from './dashboard';
import database from './database';
import dialog from './dialog';
import { drawings } from './drawings';
import measurements from './measurements';
import { modelManagement } from './model-management';
import modelcheck from './modelcheck';
import notifications from './notifications';
import people from './people';
import { persistedStorage } from './persisted-storage';
import projects from './projects';
import { quantityTakeOff } from './quantity-take-off';
import resources from './resources';
import scenarios from './scenarios';
import stepper from './stepper';
import toppanel from './toppanel';
import { twoD } from './two-d';
import userActivities from './user-activities';
import viewer from './viewer';
import viewersets from './viewer-sets';
import workpackages from './work-packages';

export const rootReducer = {
  account,
  notifications,
  toppanel,
  people,
  projects,
  admin,
  cost,
  resources,
  scenarios,
  stepper,
  common,
  database,
  userActivities,
  measurements,
  modelcheck,
  modelManagement,
  workpackages,
  activityGrouping,
  viewer,
  viewersets,
  fourDVisualisation,
  classification,
  activityAssignment,
  ceActivityAssignment,
  ceMeasurements,
  dialog,
  bidPricing: bidPricingReducer,
  dashboard,
  quantityTakeOff,
  analytics,
  persistedStorage,
  drawings,
  twoD,
  multiView: multiViewReducerMethod,
  twoDDatabase: twoDDatabaseReducer,
  twoDFastNavigation: twoDFastNavigationReducer,
  twoDComments: twoDCommentsReducer,
  twoDNotifications: twoDNotificationsReducers,
  twoDCopilot: TwoDCopilotReducer,
  progress: progressReducers,
  twoDReportTemplate: twoDReportTemplateReducer,
  twoDElementView: twoDElementViewReducers,
  contextMenu: contextMenuReducers,
  twoDInfoPanel: twoDInfoPanelReducers,
  subscription: subscriptionReducers,
};

export default rootReducer;
