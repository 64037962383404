import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { EnvironmentConfigurationProps } from 'common/environment/environment-configuration-props';
import { withEnvironmentConfiguration } from 'common/environment/with-environment-configuration';
import { State } from 'common/interfaces/state';
import { OpenReplay } from 'utils/openreplay/openreplay';
import { Person } from '../../units/people/interfaces/person';
import { analyticEvent, analyticToggleEvent, setPosthogIdentify } from './posthog';
import { usePageTracking } from './use-page-tracking';

interface StateProps {
  email: string;
  companyName: string;
  companyId: number;
  companyUsers: Person[];
}

interface Props extends React.PropsWithChildren<StateProps>, EnvironmentConfigurationProps {}

interface ContextValue {
  sendEvent: (eventType: string, props?: any) => void;
  sendToggleEvent: (eventName: string, currentStatus: boolean, params?: any) => void;
}

export const AnalyticContext = React.createContext<ContextValue>({
  sendEvent: (eventType: string, props: any = {}) => analyticEvent(eventType, props),
  sendToggleEvent: (eventName: string, currentStatus: boolean, params?: any) =>
    analyticToggleEvent(eventName, currentStatus, params),
});

const AnalyticsWraperComponent = (props: Props): JSX.Element => {
  const sendEvent = useCallback((eventName: string, params: any) => {
    analyticEvent(eventName, { email: props.email, companyName: props.companyName, ...params });
  }, [props.email, props.companyName]);
  const sendToggleEvent = useCallback((eventName: string, currentStatus: boolean, params?: any) => {
    analyticToggleEvent(eventName, currentStatus, params);
  }, [props.email, props.companyName]);

  const senders = useMemo(() => {
    return ({
      sendEvent,
      sendToggleEvent,
    });
  }, [ sendToggleEvent,  sendEvent ]);

  useEffect(() => {
    setPosthogIdentify(
      props.email,
      props.companyId,
      props.companyName,
      props.companyUsers.map(u => ({ id: u.id, email: u.email })),
    );
  }, [props.email, props.companyId, props.companyName, props.companyUsers]);

  usePageTracking();

  return (<AnalyticContext.Provider value={senders} >
    { props.environmentConfiguration.openReplayApiKey ? <OpenReplay/> : null }
    {props.children}
  </AnalyticContext.Provider>);
};

const mapStateToProps = (state: State): StateProps => {
  return ({
    email: state.account.email,
    companyName: state.account.selectedCompany?.name,
    companyId: state.account.selectedCompany?.id,
    companyUsers: state.people.companiesUsers,
  });
};

export const AnalyticsWraper = connect(mapStateToProps)(withEnvironmentConfiguration(AnalyticsWraperComponent));
