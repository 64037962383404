import styled from 'styled-components';

import { TRANSITION_POINT } from '../../../constants';


const Container = styled.div`
  width: 100%;
  max-width: 800px;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  h4 {
    margin-left: 30px;
    margin-bottom: 20px;
  }

  * {
    box-sizing: border-box;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 30px;
`;

export const Styled = {
  Container,
  Wrapper,
  ButtonsContainer,
};
