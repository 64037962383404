import styled from 'styled-components';

const ColorIndicatorContainer = styled.div`
  svg {
    fill: ${p => p.color} !important;
  }
`;

export const Styled = {
  ColorIndicatorContainer,
};

