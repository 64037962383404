import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { INITIAL_STATE } from '../../../reducers/admin-demo-projects';
import { AdminDemoProjectsActionTypes } from '../actions/types/demo-projects';
import { DemoProject } from '../pages/demo-projects/interfaces/demo-project';
import { AdminDemoProjectsState } from '../pages/demo-projects/interfaces/state';

export const adminDemoProjectsReducerMethods: ReducerMethods<AdminDemoProjectsState> = {
  [AdminDemoProjectsActionTypes.LOAD_DEMO_PROJECTS]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.demoProjectsRequestStatus, RequestStatus.Loading)
      .set(_ => _.demoProjects, [])
      .get();
  },
  [AdminDemoProjectsActionTypes.LOAD_DEMO_PROJECTS_SUCCEEDED]: (state, payload: DemoProject[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.demoProjectsRequestStatus, RequestStatus.Loaded)
      .set(_ => _.demoProjects, payload)
      .get();
  },
  [AdminDemoProjectsActionTypes.DROP_STORE]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _, INITIAL_STATE)
      .get();
  },
};
