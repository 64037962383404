import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './people-list.scss';

import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AdminPeopleActions } from '../actions/creators/people';
import { ChangeEmailDialog, CHANGE_EMAIL_DIALOG } from '../change-email-dialog/change-email-dialog';
import { Feed } from '../components/feed/feed';
import { User } from '../interfaces/user';
import { PeopleItem } from './items';

interface ReduxProps {
  count: number;
  status: RequestStatus;
  data: User[];
  initialSearch: string;
}

interface ReduxActions {
  loadMore: (skip: number, search: string) => void;
  deleteUser: (email: string) => void;
  enableUser: (guid: string, enable: boolean) => void;
  updateUserAttribute: (key: string, value: string, userId: string) => void;
  /**
   * @param companySearch company's id, email, or name
   */
  goTo: (url: string, companySearch: React.ReactText) => void;
  onDeleteOwnedCompany: (userId: string) => void;
  openChangeEmailDialog: () => void;
  changeUserEmail: (id: string, email: string) => void;
}

interface ComponentState {
  userEmail: string;
  userId: string;
}

interface Props extends ReduxProps, ReduxActions {

}

class PeopleListComponent extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userEmail: '',
      userId: '',
    };
  }

  public render(): JSX.Element {
    return (
      <div className='people-list'>
        <Feed
          className='people-list__feed'
          searchPlaceholder='by first name, last name, and email'
          data={this.props.data}
          count={this.props.count}
          status={this.props.status}
          renderItem={this.renderItem}
          loadMore={this.props.loadMore}
          initialSearch={this.props.initialSearch}
        />
        <ChangeEmailDialog
          email={this.state.userEmail}
          onConfirm={this.changeUserEmail}
        />
      </div>
    );
  }

  @autobind
  private renderItem(item: User): React.ReactNode {
    return (
      <PeopleItem
        key={item.guid}
        user={item}
        onDelete={this.props.deleteUser}
        onEnable={this.props.enableUser}
        updateUserAttribute={this.props.updateUserAttribute}
        onCompanyClick={this.onCompanyClick}
        onDeleteOwnedCompany={this.props.onDeleteOwnedCompany}
        openChangeEmailDialog={this.openChangeEmailDialog}
      />
    );
  }

  @autobind
  private openChangeEmailDialog(id: string, email: string): void {
    this.setState({ userEmail: email, userId: id });
    this.props.openChangeEmailDialog();
  }

  @autobind
  private changeUserEmail(email: string): void {
    const { userId } = this.state;
    this.props.changeUserEmail(userId, email);
  }

  @autobind
  private onCompanyClick(companySearch: React.ReactText): void {
    this.props.goTo('/admin/companies', companySearch);
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const search = state.router.location.search;

  return {
    count: state.admin.people.count,
    data: state.admin.people.data,
    status: state.admin.people.status,
    initialSearch: search.substring(1, search.length),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadMore: (skip: number, search: string) =>  dispatch(AdminPeopleActions.loadUsers({ skip, search })),
    deleteUser: (userId: string) => dispatch(AdminPeopleActions.deleteUser(userId)),
    enableUser: (guid: string, enable: boolean) => dispatch(AdminPeopleActions.enableUser({ guid, enable })),
    updateUserAttribute: (key: string, value: string, userId: string) =>
      dispatch(AdminPeopleActions.updateUserAttribute(key, value, userId)),
    goTo: (url: string, companySearch: React.ReactText) => dispatch(push({
      pathname: url,
      search: companySearch.toString(),
    })),
    onDeleteOwnedCompany: (userId: string) => dispatch(AdminPeopleActions.deletedOwnedCompany(userId)),
    openChangeEmailDialog: () => dispatch(KreoDialogActions.openDialog(CHANGE_EMAIL_DIALOG)),
    changeUserEmail: (id: string, email: string) => dispatch(AdminPeopleActions.changeUserEmail(id, email)),
  };
};

export const PeopleList = connect(mapStateToProps, mapDispatchToProps)(PeopleListComponent);
