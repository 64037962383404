import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { DrawingsGeometryGroup } from '../../interfaces';
import { DrawingsGroupUtils } from '../../utils/drawings-group-utils';

export function useIsOneGroupSelected(): boolean {
  const groups = useSelector<State, DrawingsGeometryGroup[]>(s => s.drawings.drawingGeometryGroups);
  const selectedGroups = useSelector<State, string[]>(s => s.drawings.selectGeometryGroup);

  return useMemo(() => {
    return DrawingsGroupUtils.getSelectedGroupRoots(selectedGroups, groups).length === 1;
  }, [groups, selectedGroups]);
}
