import * as React from 'react';

import './internal-header-menu-wrapper.scss';

export class InternalHeaderMenuWrapper extends React.Component {
  public render(): JSX.Element {
    return (
      <div className='internal-header-menu-wrapper'>
        {this.props.children}
      </div>
    );
  }
}
