import { UuidUtil } from 'common/utils/uuid-utils';
import { DrawingsInstanceType } from '../../enums';
import {
  DrawingsGeometryInstance,
  DrawingsGeometryInstanceWithId,
  DrawingsGeometryType,
  DrawingsPointInfo,
  DrawingsPolygonGeometry,
  ShortPointDescription,
} from '../../interfaces';
import { DrawingAnnotationUtils } from '../drawing-annotation-utils';
import { DrawingsGeometryUtils } from '../drawings-geometry-utils';

function getInstanceIdBySegment(id: [string, string], pointsInfo: Record<string, DrawingsPointInfo>): string {
  const pointInfo = pointsInfo[id[0]];
  const lineKey = DrawingAnnotationUtils.getLineKey(...id);
  const isSegmentExist = !pointInfo || !pointInfo.lines.includes(lineKey);
  return isSegmentExist ? pointInfo.instanceId : null;
}

function getInstanceId(id: string | [string, string], pointsInfo: Record<string, DrawingsPointInfo>): string {
  const isSegment = Array.isArray(id);
  return isSegment ? getInstanceIdBySegment(id, pointsInfo) : id;
}

function groupInstancesIdsByDrawings(
  instancesIds: Array<string | [string, string]>,
  instances: Record<string, DrawingsGeometryInstance>,
  pointsInfo: Record<string, DrawingsPointInfo>,
): Record<string, string[]> {
  const instancesByDrawing: Record<string, string[]> = {};
  for (const id of instancesIds) {
    const instanceId = getInstanceId(id, pointsInfo);
    const instance = instances[instanceId];
    if (instance) {
      instancesByDrawing[instance.drawingId] = instancesByDrawing[instance.drawingId] || [];
      instancesByDrawing[instance.drawingId].push(instanceId);
    }
  }
  return instancesByDrawing;
}


interface ProcessableInstance {
  geometry: DrawingsGeometryType;
  type: DrawingsInstanceType;
  name: string;
  id: string;
  groupId?: string;
}

type ProcessInstance<T extends ProcessableInstance> = (instance: T) => DrawingsGeometryInstanceWithId;
type ProcessPoints = (pointId: string) => string;

function copyInstances<T extends ProcessableInstance>(
  instances: Iterable<T>,
  wrapInstance: ProcessInstance<T>,
  processPoint: ProcessPoints,
  addInstanceToGroupIfNeeded?: (instance: DrawingsGeometryInstanceWithId) => void,
): DrawingsGeometryInstanceWithId[] {
  const newInstances = [];
  for (const instance of instances) {
    let geometry = instance.geometry;
    const isPointsGeometry =
      DrawingsGeometryUtils.isPolyGeometry(instance.type, instance.geometry) ||
      DrawingsGeometryUtils.isCount(instance.type, instance.geometry);
    if (isPointsGeometry) {
      geometry = {
        ...instance.geometry,
        points: instance.geometry.points.map(processPoint),
      };
      if (DrawingsGeometryUtils.isPolygon(instance.type, instance.geometry)) {
        if (instance.geometry.children) {
          (geometry as DrawingsPolygonGeometry).children = instance.geometry.children.map(x =>
            x.map(processPoint),
          );
        }
      }
    }
    const newInstance = wrapInstance({
      ...instance,
      geometry,
    });
    if (addInstanceToGroupIfNeeded) {
      addInstanceToGroupIfNeeded(newInstance);
    }
    newInstances.push(newInstance);
  }
  return newInstances;
}

function copyInstancesToPage(
  instances: Iterable<DrawingsGeometryInstanceWithId>,
  points: Record<string, ShortPointDescription>,
  pageId: string,
): {
  instances: DrawingsGeometryInstanceWithId[],
  points: Record<string, ShortPointDescription>,
  groupInstances: Record<string, string[]>,
} {
  const newPoints = {};
  const groupInstances = {};
  const addInstanceToGroupIfNeeded = (instance: DrawingsGeometryInstanceWithId): void => {
    if (!instance.groupId) {
      return;
    }
    groupInstances[instance.groupId] = groupInstances[instance.groupId] || [];
    groupInstances[instance.groupId].push(instance.id);
  };
  const pointsRemaper = (pointId: string): string => {
    const id = UuidUtil.generateUuid();
    newPoints[id] = points[pointId];
    return id;
  };
  return {
    instances: copyInstances(
      instances,
      (instance) => ({ ...instance, id: UuidUtil.generateUuid(), drawingId: pageId }),
      pointsRemaper,
      addInstanceToGroupIfNeeded,
    ),
    points: newPoints,
    groupInstances,
  };
}

export const InstancesUtils = {
  copyInstances,
  copyInstancesToPage,
  groupInstancesIdsByDrawings,
};
