import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { KreoDialogActions } from 'common/UIKit';
import { FORMULA_DIALOG_NAME } from 'unit-2d-database/components/side-panel/components';

interface ButtonFunctionState {
  onClick: () => void;
  value: string;
}

export const useButtonFunctionState = (input: any): ButtonFunctionState => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    input.onClick();
    dispatch(KreoDialogActions.updateDialogData(FORMULA_DIALOG_NAME, { formulaValue: '' }));
  }, []);
  return {
    value: undefined,
    onClick: handleClick,
  };
};
