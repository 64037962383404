import React from 'react';
import { DrawingsSettingsSwitcherWithLabel } from 'common/components/drawings/components/setting-parameter';
import { useDrawingSettingsWithUpdate } from 'common/components/drawings/hooks/settings/use-drawing-settings';

const MeasurementSettingsComponent: React.FC = () => {
  const {
    isKeepOriginName,
    toggleKeepNames,
    isAutofocusEnabled,
    toggleAutoFocus,
    isKeepStructure,
    toggleKeepStructure,
  } = useDrawingSettingsWithUpdate();
  return (
    <>
      <DrawingsSettingsSwitcherWithLabel
        toggle={toggleKeepNames}
        label="Keep Names"
        checked={isKeepOriginName}
        tooltip="Enable the setting to keep the measurements initial names when duplicating, copying, cutting."
      />
      <DrawingsSettingsSwitcherWithLabel
        toggle={toggleAutoFocus}
        label="Auto Focus"
        checked={isAutofocusEnabled}
        // eslint-disable-next-line max-len
        tooltip="Use the setting to automatically focus on a measurement when selecting it from the Measurements manager."
      />
      <DrawingsSettingsSwitcherWithLabel
        toggle={toggleKeepStructure}
        label="Keep Structure"
        checked={isKeepStructure}
        // eslint-disable-next-line max-len
        tooltip="Use the setting to save existing folder structure when copying, cutting or duplicating measurements to another page"
      />
    </>
  );
};

export const MeasurementSettings = React.memo(MeasurementSettingsComponent);
