import { CodecUtils } from 'common/utils/codec-utils';

export enum KreoProduct {
  View = 'View',
  BillOfQuantities = 'BillOfQuantities',
  Qto2D = 'Qto2D',
  Database = 'Database',
  Plan = 'Plan',
  CostEstimate = 'CostEstimate',
  Takeoff2dAutoMeasure = 'Takeoff2dAutoMeasure',
  Takeoff2dAutoMeasureApi = 'Takeoff2dAutoMeasureApi',
  Takeoff2dAnnotations = 'Takeoff2dAnnotations',
  Project2DTemplates = 'Project2DTemplates',
  PropertiesItemsAssemblies = 'PropertiesItemsAssemblies',
  Project2DPagesCompare = 'Project2DPagesCompare',
  Comments = 'Comments',
  Takeoff2dAiSuggest = 'Takeoff2dAiSuggest',
  Takeoff2dAutoScale = 'Takeoff2dAutoScale',
  Takeoff2dAutoCount = 'Takeoff2dAutoCount',
  Takeoff2dCaddie = 'Takeoff2dCaddie',
  Takeoff2dGroupTemplates = 'Takeoff2dGroupTemplates',
  Takeoff2dReportTemplate = 'Takeoff2dReportTemplate',
  Takeoff2dPdfExport = 'Takeoff2dPdfExport',
  TakeOff2dFileConverter = 'TakeOff2dFileConverter',
  Takeoff2dFileOptimizer = 'Takeoff2dFileOptimizer',
  Takeoff2dExcelImport = 'Takeoff2dExcelImport',
  Takeoff2dMeasurement3d = 'Takeoff2dMeasurement3d',
  Takeoff2dWizard = 'Takeoff2dWizard',
}

export const basicKreoProducts = [ KreoProduct.View ];

export const KreoProductC = CodecUtils.createEnumCodec<KreoProduct>(KreoProduct, 'KreoProduct');
