import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';

export const REMOVE_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG = 'REMOVE_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG';

interface StateProps {
  data: { ids: string[] };
}

interface Props extends StateProps {
  onSubmit: (ids: string[]) => void;
}

const DeleteBreakDownPropertyConfirmationDialogComponent: React.FC<Props> = ({ onSubmit, data }) => {
  const textPropertyAssembly = `If you delete tree property from the database,
    it will be removed from all items in your database and all existing projects.
    If you still want to have this property in your existing projects, you have to
    keep it in your database.`;
  const dialogText = textPropertyAssembly;

  const onConfirm = React.useCallback(() => {
    if (data) {
      onSubmit(data.ids);
    }
  }, [data, onSubmit]);

  return (
    <ConfirmationDialog
      name={REMOVE_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG}
      title='This property will be deleted everywhere'
      text={dialogText}
      confirmButtonText='Delete'
      cancelButtonText='Cancel'
      onConfirm={onConfirm}
      autoHeight={true}
    />
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    data: state.dialog[REMOVE_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG],
  };
};

export const DeleteBreakDownPropertyConfirmationDialog = connect(mapStateToProps)
(DeleteBreakDownPropertyConfirmationDialogComponent);
