import { MenuItem, MovableContextMenu } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ColorsName } from 'common/enums/kreo-colors';
import { AdminProjectsActions } from '../../../actions/creators/projects';

interface DispatchProps {
  download: (projectId: number, quality: number) => void;
}

interface Props extends DispatchProps {
  projectId: number;
  x: number;
  y: number;
  onClose: () => void;
}

const ProjectContextMenuComponent: React.FC<Props> = (props: Props) => {
  const { projectId, x, y, onClose, download: dump } = props;

  const onFullDownloadClick = useCallback(() => {
    dump(projectId, 0);
    onClose();
  }, [projectId]);

  const onLightDownloadClick = useCallback(() => {
    dump(projectId, 1);
    onClose();
  }, [projectId]);

  const onFakeDownloadClick = useCallback(() => {
    dump(projectId, 2);
    onClose();
  }, [projectId]);

  return (
    <MovableContextMenu
      x={x}
      y={y}
      onClose={onClose}
    >
      <MenuItem
        onClick={onFullDownloadClick}
        text='Download (Full)'
        textColor={ColorsName.mainFont}
        size='m'
        fontSize={14}
      />
      <MenuItem
        onClick={onLightDownloadClick}
        text='Download (Light)'
        textColor={ColorsName.mainFont}
        size='m'
        fontSize={14}
      />
      <MenuItem
        onClick={onFakeDownloadClick}
        text='Download (Fake)'
        textColor={ColorsName.mainFont}
        size='m'
        fontSize={14}
      />
    </MovableContextMenu>
  );
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    download: (projectId, quality) => dispatch(AdminProjectsActions.download(projectId, quality)),
  };
}

export const ProjectContextMenu = connect(null, mapDispatchToProps)(ProjectContextMenuComponent);
