import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { TwoDActions } from '2d/actions/creators';
import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import {
  AssignedPia,
  AssignPiaPatch,
  AssignItemPatch,
  AssignedPiaProperty,
  AssignAssemblyPatch,
  AssignedPiaItem,
} from '2d/interfaces';
import { Spinner } from 'common/components/spinner';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { UuidUtil } from 'common/utils/uuid-utils';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { Assembly, Item, Property, UpdateAssemblyPayload, UpdateItemRequest } from 'unit-2d-database/interfaces';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { Selector } from 'unit-2d-info-panel/content/selection-view/assign-form/helpers/selectors';
import { SidePanelContentProps } from '../../interfaces';
import { AssemblyPanel, OwnState } from '../assembly-panel/assembly-panel';
import { useResetChildCallback } from '../hooks';

interface StateProps {
  rootIds: string[];
  childIds: string[];
  assign: Record<string, AssignedPia>;
  showSpinner: boolean;
  items: Item[];
}

interface DispatchProps {
  sendPatchPia: (assign: AssignPiaPatch[], callback: () => void) => void;
  close: () => void;
  openEntitiesPanel: () => void;
}

interface Props extends StateProps, DispatchProps, SidePanelContentProps<Property, OwnState> {}

const mapUpdatedItem = (updateItem: UpdateItemRequest): AssignItemPatch => {
  const deletedProperties: string[] = updateItem.deletedProperties;
  const updatedProperties: AssignedPiaProperty[] = [];

  for (const updatedProperty of updateItem.updatedProperties) {
    if (updatedProperty.value) {
      updatedProperties.push(updatedProperty);
    } else {
      deletedProperties.push(updatedProperty.id);
    }
  }

  return {
    name: updateItem.name,
    iconType: updateItem.iconType,
    addedProperties: updateItem.addedProperties,
    deletedProperties,
    updatedProperties,
  };
};

const useUpdateHandlerCallback = (
  rootIds: string[],
  childIds: string[],
  assign: Record<string, AssignedPia>,
  sendPatchPia: (assign: AssignPiaPatch[], callback: () => void) => void,
  close: () => void,
  openEntitiesPanel: () => void,
  items: Item[],
): (
  updateAssembly: UpdateAssemblyPayload,
  data: Assembly,
) => void => {
  const getResetChildPatch = useResetChildCallback(childIds, assign);

  return useCallback((updateAssembly, data) => {
    const patch: AssignPiaPatch = {
      ids: rootIds,
    };
    const assignModel = assign[rootIds[0]];
    if (assignModel?.assemblies?.length) {
      const assembly = assignModel.assemblies.find(a => a.name === data.name);
      const updatedItems: AssignItemPatch[] = updateAssembly.updatedItems.map(mapUpdatedItem);
      const addedItems: AssignedPiaItem[] = [];
      updateAssembly.addedItems.forEach(ai => {
        const item = assembly.items.find(i => i.name === ai.name);
        if (item) {
          updatedItems.push(ai);
        } else {
          const baseItem = items.find(bItem => bItem.name === ai.name);
          addedItems.push({
            ...ai,
            properties: baseItem.properties,
          });
        }
      });
      const updatedAssembly: AssignAssemblyPatch = {
        name: data.name,
        addedItems,
        deletedItems: updateAssembly.deletedItems,
        updatedItems,
      };
      patch.updatedAssemblies = [updatedAssembly];
    } else {
      const addedAssembly: Assembly = JSON.parse(JSON.stringify(data));
      updateAssembly.deletedItems.forEach(di => {
        addedAssembly.items.forEach(i => {
          if (i.name === di) {
            i.properties = null;
          }
        });
      });
      updateAssembly.addedItems.forEach(i => {
        const baseItem = items.find(bItem => bItem.name === i.name);
        addedAssembly.items.push({
          ...i,
          properties: baseItem.properties,
        });
      });
      updateAssembly.updatedItems.forEach(payloadItem => {
        const item = addedAssembly.items.find(addedAssemblyItem => addedAssemblyItem.name === payloadItem.name);
        payloadItem.updatedProperties.forEach(up => {
          item.properties.forEach(addedAssemblyProperty => {
            if (up.name === addedAssemblyProperty.name) {
              addedAssemblyProperty.value = up.value;
            }
          });
        });
        payloadItem.addedProperties.forEach(ap => {
          item.properties.push(ap);
        });
        payloadItem.deletedProperties.forEach(dp => {
          item.properties.forEach(addedAssemblyProperty => {
            if (dp === addedAssemblyProperty.name) {
              addedAssemblyProperty.value = null;
            }
          });
        });
      });
      patch.addedAssemblies = [addedAssembly];
    }

    const resetChildPatch = getResetChildPatch();
    sendPatchPia([patch, ...resetChildPatch], () => {
      openEntitiesPanel();
      close();
    });
  }, [rootIds, childIds]);
};

const AssemblyPanelPageComponent = (props: Props): JSX.Element => {
  const {
    items,
    rootIds,
    childIds,
    assign,
    sendPatchPia,
    close,
    showSpinner,
    openEntitiesPanel,
    setAfterClose,
  } = props;
  const isShowRenameDialog = useCallback(() => {
    return false;
  }, []);
  const update = useUpdateHandlerCallback(rootIds, childIds, assign, sendPatchPia, close, openEntitiesPanel, items);
  const create = useCallback(ConstantFunctions.doNothing, []);
  const openAssignFloatPanel = useCallback(openEntitiesPanel, []);
  useEffect(() => {
    setAfterClose(openAssignFloatPanel);
  }, []);

  return (
    <>
      <Spinner show={showSpinner} withBackground={true} />
      <AssemblyPanel
        {...props}
        isShowRenameDialog={isShowRenameDialog}
        update={update}
        create={create}
        skipCloseAfterSubmit={true}
        disableHeaderEdit={true}
        afterClose={openAssignFloatPanel}
        isSkipBaseItem={true}
      />
    </>
  );
};

const mapStateToProps = (state: State): StateProps => {
  const { rootIds, childIds } = Selector.getSelectedIds(state);
  return {
    rootIds,
    childIds,
    assign: state.twoD.assignPia,
    showSpinner: state.twoDDatabase.sidePanel.showSpinner,
    items: state.twoDDatabase.items,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    sendPatchPia: (assign, callback: () => void) => {
      dispatch(TwoDDatabaseActions.showSpinner());
      const iterationId = UuidUtil.generateUuid();
      dispatch(TwoDActions.setExportExcelLoad(iterationId));
      dispatch(TwoDElementViewActions.changeColumnAfterAssign(assign));
      dispatch(TwoDActions.sendAssignPatch(assign, iterationId, () => {
        callback();
        dispatch(TwoDElementViewActions.handleChangeAssign(assign));
      }));
    },
    close: () => {
      dispatch(TwoDDatabaseActions.closeSidePanel());
      dispatch(TwoDDatabaseActions.closeAssemblyPanel());
    },
    openEntitiesPanel: () => {
      dispatch(PersistedStorageActions.openEntitiesPanel());
    },
  };
};

export const AssemblyPanelPage = connect(mapStateToProps, mapDispatchToProps)(AssemblyPanelPageComponent);
