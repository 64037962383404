import { flatten } from './flatten';

export function SMALL(arr: number[], k: number): number {
  const args = flatten(arr);
  // Sort the array in ascending order
  args.sort((a, b) => a - b);

  // Return the kth smallest element
  return args[k - 1];
}
