import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { DrawingsInstanceMeasure } from 'common/components/drawings/interfaces';
import { AnnotationLegendItem } from 'common/components/drawings/interfaces/annotation-legend-tree';
import { State } from 'common/interfaces/state';
import { MeasureValue } from '../../drawings-annotation-legend-item';
import { calculateGroupsMeasures } from '../../utils';

export function useGroupMeasures(
  instanceMeasures: Record<string, DrawingsInstanceMeasure>,
  getInstancesMeasures: (instanceId: string[]) => DrawingsInstanceMeasure[],
): Record<string, MeasureValue[]> {
  const filterResultItems = useSelector<State, AnnotationLegendItem[]>(x => x.drawings.filteredItems);
  const prevMeasure = useRef<Record<string, MeasureValue[]>>();
  return useMemo(() => {
    const groupMeasures = calculateGroupsMeasures(
      filterResultItems,
      prevMeasure.current || {},
      (instanceId: string) => instanceMeasures[instanceId] || getInstancesMeasures([instanceId])[0],
    );
    prevMeasure.current = groupMeasures;
    return groupMeasures;
  }, [filterResultItems, instanceMeasures, getInstancesMeasures]);
}
