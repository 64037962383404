import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ClassificationAssignmentStage } from '../../enums/classification-assignment-stage';
import { ClassificationUtils } from '../../utils/classification-utils';
import { ClassificationAssemblyAssignment } from '../classification-assembly-assignment';
import { ClassificationAssignmentLayout } from '../classification-assignment-layout';
import { ClassificationSimpleObjectAssignment } from '../classification-simple-object-assignment';

interface Props {
  isMultilayer: boolean;
  assignmentLevel: ClassificationAssignmentStage;
  footerPredictedInfo: boolean;
}

const ClassificationAssignmentBodyComponent: React.FC<Props> = props => {
  return (
    <ClassificationAssignmentLayout
      showFooter={true}
      showHeader={true}
      footerSaveOnly={props.footerPredictedInfo}
    >
      {props.isMultilayer ? (
        <ClassificationAssemblyAssignment stage={props.assignmentLevel} />
      ) : (
        <ClassificationSimpleObjectAssignment stage={props.assignmentLevel} />
      )}
    </ClassificationAssignmentLayout>
  );
};

const mapStateToProps = (state: State): Props => {
  const assignmentLevel = state.classification.assignmentStage;
  return {
    isMultilayer: ClassificationUtils.classificationTargetIsMultilayer(state.classification.classificationTarget),
    assignmentLevel,
    footerPredictedInfo: assignmentLevel === ClassificationAssignmentStage.PredictedInfo,
  };
};


const connector = connect(mapStateToProps);
export const ClassificationAssignmentBody = connector(ClassificationAssignmentBodyComponent);
