import * as React from 'react';

import { SpliterPaneConfig, SplitterLayout } from '../../../../../components/splitter-layout';
import { ViewProps } from './interfaces';

export class TwoReportViewsStacked extends React.Component<ViewProps> {
  private sizes: SpliterPaneConfig[] = [];
  constructor(props: ViewProps) {
    super(props);
    this.sizes = [
      { size: props.height * 0.5, minSize: 50 },
      { size: props.height * 0.5, minSize: 50 },
    ];
  }

  public render(): JSX.Element {
    return (
      <SplitterLayout
        primaryIndex={0}
        horizontal={true}
        paneConfigs={this.sizes}
      >
        {this.props.children}
      </SplitterLayout>);
  }
}
