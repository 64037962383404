import * as React from 'react';

import { Text } from 'common/components/text';
import { Styled } from './styled';


interface Props {
  badgeText?: string;
  BadgeIcon?: React.ComponentType;
  withEllipsis?: boolean;
}

export const Badge: React.FC<Props> = ({ badgeText, BadgeIcon, withEllipsis }) => {
  return (
    <Styled.BadgeContainer>
      <Text
        lineHeight={22}
        fontSize={10}
        color={'white'}
        withEllipsis={withEllipsis}
      >
        {badgeText}
      </Text>
      {BadgeIcon ? <BadgeIcon /> : null}
    </Styled.BadgeContainer>
  );
};
