import * as React from 'react';

export const IconDatabase: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M13,6.80689036 C11.9650968,7.57543509 10.1933171,8 8,8 C5.80668293,8 4.03490325,
          7.57543509 3,6.80689036 L3,8.00000035 C3,9.1186575 4.93550154,10 8,10 C11.0644985,
          10 13,9.1186575 13,8 L13,6.80689036 Z M13,5 C13,3.8813425 11.0644985,3 8,3 C4.93550154,
          3 3,3.8813425 3,5 C3,6.1186575 4.93550154,7 8,7 C11.0644985,7 13,6.1186575 13,5 Z M13,
          9.80689036 C11.9650968,10.5754351 10.1933171,11 8,11 C5.80668293,11 4.03490325,10.5754351 3,
          9.80689036 L3,11 C3,12.1186575 4.93550154,13 8,13 C11.0644985,13 13,12.1186575 13,11 L13,
          9.80689036 Z M2,5 C2,3.11419875 4.44687325,2 8,2 C11.5531268,2 14,3.11419875 14,5 L14,11 C14,
          12.8858013 11.5531268,14 8,14 C4.44687325,14 2,12.8858013 2,11 L2,5 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
