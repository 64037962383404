import autobind from 'autobind-decorator';
import classNames from 'classnames';
import  * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './validation-resizable-engine-layout.scss';

import { Controls3D } from 'common/components/controls-3d';
import { KreoToolbar } from 'common/components/kreo-toolbar';
import { KreoColors } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { Engine } from '../../../../components/engine';
import { KreoEngine } from '../../../../components/engine/KreoEngine';
import { Unit } from '../../../../components/engine/KreoEngineConsts';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';
import { ValidationResizableEditorApi } from '../../interfaces/validation-resizable-editor-api';
import { ValidationResizableEngineLayoutApi } from '../../interfaces/validation-resizable-engine-layout-api';
import { PropertiesDataContextProvider } from '../properties-data-context-provider';
import { PropertiesPopupApi } from '../properties-popup-button';
import { ValidationEngineResizableLayoutContextProvider } from '../validation-engine-resizable-layout-context-provider';


interface OwnProps {
  className?: string;
  isShowResizablePanel: boolean;
  projectId: string;
  errorEngineIds?: number[];
  children?: React.ReactNode;
  engineSelect: (ids: number[]) => void;
  getApi: (api: ValidationResizableEngineLayoutApi) => void;
}

interface DispatchProps {
  toggleAutoFocusEngine: () => void;
}

interface StateProps {
  isAutoFocus: boolean;
}

interface ComponentState {
  enableResize: boolean;
  isClipBoxEnabled: boolean;
  isIsometryEnabled: boolean;
  isGhostEnabled: boolean;
  engineContainerRef: HTMLDivElement;
  isRulerEnabled: boolean;
  isFullScreen: boolean;
  isToolbarHide: boolean;
  isImperialUnit: boolean;
  selectedIds: number[];
  isShowAllOn: boolean;
  color: string;
}

interface Props extends DispatchProps, StateProps, OwnProps {}

export class ValidationResizableEngineLayoutNewComponent extends React.PureComponent<Props, ComponentState> {
  public static defaultProps: Partial<Props> = {
    errorEngineIds: [],
  };

  private api: ValidationResizableEditorApi = null;
  private engineLayoutContainer: HTMLDivElement = null;
  private engineApi: KreoEngine = null;
  private propertiesPopupApi: PropertiesPopupApi = null;
  private elementIds: number[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      enableResize: false,
      isClipBoxEnabled: false,
      isIsometryEnabled: false,
      isRulerEnabled: false,
      isGhostEnabled: true,
      engineContainerRef: null,
      isFullScreen: false,
      isToolbarHide: false,
      isImperialUnit: false,
      selectedIds: [],
      isShowAllOn: false,
      color: KreoColors.blue4,
    };
    props.getApi({
      showIds: this.showIds,
      focus: this.focus,
      setSelected: this.setSelected,
      getSelected: this.getSelected,
      showProperties: this.showProperties,
      showAll: this.showAll,
    });
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.isShowResizablePanel && !this.props.isShowResizablePanel) {
      this.engineFullWidth();
    }

    if (prevProps.errorEngineIds.length !== this.props.errorEngineIds.length && this.engineApi) {
      this.engineApi.setColorTint(null);
      this.engineApi.setColorTint('#ffb856', this.props.errorEngineIds);
    }
  }

  public render(): React.ReactNode {
    const className = classNames('validation-resizable-engine-layout', this.props.className);
    const engineContainerClassName = classNames(
      'validation-resizable-engine-layout__engine',
      {
        'validation-resizable-engine-layout__engine--full-width': !this.props.isShowResizablePanel,
        'validation-resizable-engine-layout__engine--full-screen': this.state.isFullScreen,
      },
    );
    const engineContainerInnerClassName = classNames(
      'validation-resizable-engine-layout__engine-inner',
      {
        'validation-resizable-engine-layout__engine-inner--toolbar-hide': this.state.isToolbarHide,
      },
    );
    return (
      <div
        className={className}
        onMouseMove={this.onMouseMove}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseUp}
      >
        <PropertiesDataContextProvider >
          <ValidationEngineResizableLayoutContextProvider
            getApi={this.saveResizableEditorApi}
            onResize={this.onResize}
            onResizeStart={this.onResizeStart}
          >
            {this.props.isShowResizablePanel && this.props.children}
          </ValidationEngineResizableLayoutContextProvider>
          <div className={engineContainerClassName} ref={this.saveEngineLayoutContainer}>
            <div className={engineContainerInnerClassName}>
              <Engine
                textures='/static/textures/'
                projectId={parseInt(this.props.projectId, 10)}
                sendEngineApi={this.saveEngineApi}
                onHandleClick={this.onEngineSelect}
                onToggleRuler={this.onEngineToggleRuler}
                onToggleClipBox={this.onEngineToggleClipBox}
                toggleCameraParallel={this.onEngineToggleCameraParallel}
                saveContainerRef={this.saveEngineContainerRef}
              />
              <KreoToolbar
                isToolbarHide={this.state.isToolbarHide}
                onHideToggle={this.onHideToolbarToggle}
                className='model-check-page__engine-toolbar'
              >
                <Controls3D
                  isSelectedElement={!!this.state.selectedIds.length}
                  ghostEnabled={this.state.isGhostEnabled}
                  isometryEnabled={this.state.isIsometryEnabled}
                  clipBoxEnabled={this.state.isClipBoxEnabled}
                  rulerEnabled={this.state.isRulerEnabled}
                  isFullScreen={this.state.isFullScreen}
                  isImperialUnit={this.state.isImperialUnit}
                  isAutoFocus={this.props.isAutoFocus}
                  isShowAllOn={this.state.isShowAllOn}
                  isShowAllEnabled={true}
                  engineLayoutContainer={this.state.engineContainerRef}
                  color={this.state.color}
                  onChangeColor={this.onChangeColor}
                  onAssignColor={this.onAssignColor}
                  onDefaultColor={this.onDefaultColor}
                  onHome={this.onHome}
                  onFocus={this.onFocus}
                  toggleGhost={this.toggleGhost}
                  toggleClipBox={this.toggleClipBox}
                  toggleIsometry={this.toggleIsometry}
                  toggleRuler={this.toggleRuler}
                  toggleFullScreen={this.toggleFullScreen}
                  toggleAutoFocus={this.props.toggleAutoFocusEngine}
                  toggleMetricImperial={this.toggleUnit}
                  savePropertiesPopupApi={this.savePropertiesApi}
                  toggleShowAll={this.toggleShowAll}
                />
              </KreoToolbar>
            </div>
          </div>
        </PropertiesDataContextProvider>
      </div>
    );
  }

  @autobind
  private onDefaultColor(): void {
    const ids = this.engineApi.getSelected();
    this.engineApi.setColorTint(null, ids);
  }

  private engineFullWidth(): void {
    this.engineLayoutContainer.style.width = '100%';
  }

  @autobind
  private saveEngineContainerRef(ref: HTMLDivElement): void {
    this.setState({ engineContainerRef: ref });
  }

  @autobind
  private onAssignColor(): void {
    const ids = this.engineApi.getSelected();
    this.engineApi.setColorTint(this.state.color, ids);
  }

  @autobind
  private onChangeColor(color: string): void {
    this.setState({ color });
  }

  @autobind
  private onHome(): void {
    this.engineApi.cameraToHome();
  }

  @autobind
  private onFocus(): void {
    const ids = this.engineApi.getSelected();
    this.engineApi.focusCamera(ids);
  }

  @autobind
  private onEngineToggleClipBox(isClipBoxEnabled: boolean): void {
    this.setState({ isClipBoxEnabled });
  }

  @autobind
  private toggleClipBox(): void {
    if (this.engineApi) {
      this.engineApi.toggleClipbox(!this.state.isClipBoxEnabled, this.state.isGhostEnabled ? 'ghost' : 'none');
    }
  }

  @autobind
  private toggleGhost(): void {
    if (this.engineApi) {
      const { isGhostEnabled, isClipBoxEnabled } = this.state;
      if (isClipBoxEnabled) {
        this.engineApi.toggleClipbox(true, !isGhostEnabled ? 'ghost' : 'none');
      }
      this.engineApi.showInvisibleAsGhost(!isGhostEnabled);
      this.setState({ isGhostEnabled: !isGhostEnabled });
    }
  }

  @autobind
  private onEngineToggleCameraParallel(isIsometryEnabled: boolean): void {
    this.setState({ isIsometryEnabled });
  }

  @autobind
  private toggleIsometry(): void {
    this.engineApi.toggleParallelProjection(!this.state.isIsometryEnabled);
  }

  @autobind
  private onEngineToggleRuler(enabledRuler: boolean): void {
    this.setState({ isRulerEnabled: enabledRuler });
  }

  @autobind
  private toggleRuler(): void {
    if (this.engineApi) {
      const { isRulerEnabled } = this.state;
      this.engineApi.toggleRuler(!isRulerEnabled);
    }
  }

  @autobind
  private onHideToolbarToggle(): void {
    this.setState((s) => ({ isToolbarHide: !s.isToolbarHide }));
  }

  @autobind
  private toggleFullScreen(): void {
    this.setState((s) => ({ isFullScreen: !s.isFullScreen }));
  }

  @autobind
  private toggleUnit(): void {
    if (this.engineApi) {
      const { isImperialUnit } = this.state;
      this.engineApi.setUiUnits(
        isImperialUnit
          ? { length: Unit.Meter, area: Unit.MeterPow2 }
          : { length: Unit.FootInch, area: Unit.FootPow2 },
      );
      this.setState({ isImperialUnit: !isImperialUnit });
    }
  }

  @autobind
  private toggleShowAll(): void {
    if (!this.state.isShowAllOn) {
      this.engineApi.toggleVisibility(true);
    } else {
      this.engineApi.toggleVisibility(true, this.elementIds, false);
    }
    this.setState((s) => ({ isShowAllOn: !s.isShowAllOn }));
  }

  @autobind
  private showAll(): void {
    this.engineApi.toggleVisibility(true);
  }

  @autobind
  private savePropertiesApi(api: PropertiesPopupApi): void {
    this.propertiesPopupApi = api;
  }

  @autobind
  private onMouseMove(e: React.MouseEvent<HTMLDivElement>): void {
    if (this.state.enableResize && this.api) {
      const delta = this.api.onResize(e);
      if (delta) {
        this.engineLayoutContainer.style.width = `calc(100% - ${delta}px)`;
      }
    }
  }

  @autobind
  private onMouseUp(): void {
    if (this.state.enableResize && !!this.api) {
      const delta = this.api.onResizeOff();
      if (delta) {
        const width = this.engineLayoutContainer.getBoundingClientRect().width - delta;
        this.engineLayoutContainer.style.width = `${width}px`;
      }
      this.setState({ enableResize: false });
    }
  }

  @autobind
  private saveResizableEditorApi(api: ValidationResizableEditorApi): void {
    this.api = api;
  }

  @autobind
  private saveEngineLayoutContainer(ref: HTMLDivElement): void {
    this.engineLayoutContainer = ref;
  }

  @autobind
  private saveEngineApi(engine: KreoEngine): void {
    this.engineApi = engine;
    this.engineApi.toggleMultiselect(false);
    this.engineApi.showInvisibleAsGhost(true);
  }

  @autobind
  private onResizeStart(): void {
    this.setState({ enableResize: true });
  }

  @autobind
  private onResize(width: number): void {
    if (this.engineLayoutContainer) {
      this.engineLayoutContainer.style.width = `calc(100% - ${width}px)`;
    }
  }

  @autobind
  private showIds(ids: number[]): void {
    this.elementIds = ids;
    if (!this.state.isShowAllOn) {
      this.engineApi.toggleVisibility(true, ids, false);
    }
  }

  @autobind
  private focus(ids: number[]): void {
    if (this.props.isAutoFocus) {
      this.engineApi.focusCamera(ids);
    }
  }

  @autobind
  private onEngineSelect(ids: number[]): void {
    this.props.engineSelect(ids);
    this.setState({ selectedIds: ids });
  }

  @autobind
  private setSelected(ids: number[]): void {
    this.engineApi.setSelected(ids);
    this.showProperties(ids);
  }

  @autobind
  private getSelected(): number[] {
    return this.engineApi.getSelected();
  }

  @autobind
  private showProperties(ids: number[]): void {
    if (this.propertiesPopupApi) {
      this.propertiesPopupApi.showPropertiesForElements(ids);
    }
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    isAutoFocus: state.persistedStorage.isAutoFocusEngine,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    toggleAutoFocusEngine: () => dispatch(PersistedStorageActions.toggleEngineAutoFocus()),
  };
}

export const ValidationResizableEngineLayoutNew =
  connect(mapStateToProps, mapDispatchToProps)(ValidationResizableEngineLayoutNewComponent);
