import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsGeometryEntityState } from 'common/components/drawings/enums/drawings-geometry-entity-state';
import { ShortPointDescription } from 'common/components/drawings/interfaces/drawing-ai-annotation';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingRulerPoints } from 'common/components/drawings/interfaces/drawings-user-annotation';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { ConstantFunctions } from 'common/constants/functions';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { VisibleEntity, VisibleEntityConfig } from '../../common';
import {
  DrawingsGeometryEntityMeasureLength,
  DrawingsGeometryEntityPoint,
  DrawingsMouseEventHandler,
} from '../../drawings-geometry-entities';
import { DrawingsGeometrySelectionArea } from '../../drawings-geometry-entities/utility';
import { EditPermissions } from '../../interfaces';
import {
  DrawingsDragCallbackParams,
  DrawingsDragProcessingHelper,
} from '../drag-instances/drawings-drag-processing-helper';
import { PaperMouseEventUtils } from '../paper-mouse-event-utils';

type RulerPoints = [ShortPointDescription, ShortPointDescription, ShortPointDescription];

export interface DrawingsUserAnnotationRulerConfig extends VisibleEntityConfig<RulerPoints> {
  id: string;
  textRenderParametersObserver: ContextObserver<MeasuresViewSettings>;
  dragProcessingHelper: DrawingsDragProcessingHelper;
  editPermissionsObserver: ContextObserver<EditPermissions>;
  color: paper.Color;
  layer: paper.Layer;
  onChangePoints: (id: string, points: DrawingRulerPoints) => void;
  onSelect: DrawingsMouseEventHandler;
}

export class DrawingsUserAnnotationRulerEntity extends VisibleEntity<RulerPoints, DrawingsUserAnnotationRulerConfig> {
  private _dragPointIndex: string;
  private _measure: DrawingsGeometryEntityMeasureLength;
  private _visualPoints: DrawingsGeometryEntityPoint[];
  private _state: DrawingsGeometryEntityState = DrawingsGeometryEntityState.Default;
  private _paperPoints: paper.Point[];

  private _mouseLeaveExecutor: DeferredExecutor = new DeferredExecutor(200);

  constructor(config: DrawingsUserAnnotationRulerConfig) {
    super(config);
    config.textRenderParametersObserver.subscribe(this._measure.changeLabel);
  }


  public set points(value: DrawingRulerPoints) {
    this._paperPoints = value.map(x => new paper.Point(x));
    const [start, end, offset] = this._paperPoints;
    if (this._visualPoints) {
      this._visualPoints[0].paperPosition = start;
      this._visualPoints[1].paperPosition = end;
    }
    this._measure.changeOffset(DrawingsCanvasUtils.getPointOfLineOffset(start, end, offset));
    this._measure.changeLine([start, end]);
  }

  public get selected(): boolean {
    return this._state === DrawingsGeometryEntityState.Selected;
  }

  public set selected(value: boolean) {
    if (value === this.selected) {
      return;
    }
    this._state = value ? DrawingsGeometryEntityState.Selected : DrawingsGeometryEntityState.Default;
  }

  public get bounds(): paper.Rectangle {
    return this._measure.bounds;
  }

  public set color(color: paper.Color) {
    this._measure.changeColor(color);
  }

  public destroy(): void {
    this._config.textRenderParametersObserver.subscribe(this._measure.changeLabel);
    this._measure.destroy();
    if (this._visualPoints) {
      this._visualPoints.forEach(x => x.destroy());
      this._visualPoints = null;
    }
  }

  public isInRect(rect: paper.Rectangle, selectionArea: DrawingsGeometrySelectionArea): boolean {
    if (this._paperPoints.some(x => x.isInside(rect))) {
      return true;
    }
    const line = new paper.Path.Line(this._paperPoints[0], this._paperPoints[1]);
    return selectionArea.intersects(line);
  }

  protected render(geometry: RulerPoints): void {
    this._paperPoints = geometry.map(x => new paper.Point(x));
    const [start, end, offset] = this._paperPoints;
    const offsetValue = DrawingsCanvasUtils.getPointOfLineOffset(start, end, offset);
    this._measure = new DrawingsGeometryEntityMeasureLength({
      id: this._config.id,
      points: [start, end] as [paper.Point, paper.Point],
      color: this._config.color,
      layer: this._config.layer,
      textLayer: this._config.layer,
      textRenderParamsObserver: this._config.textRenderParametersObserver,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      pinnedBorderLength: true,
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave,
      onMouseDown: this.onTextMouseDown,
      onSelect: this.onSelect,
    });
    this._measure.changeOffset(offsetValue);
  }

  @autobind
  private onSelect(id: string, e: PaperMouseEvent): void {
    if (
      PaperMouseEventUtils.isLeftMouseButton(e)
      && this._config.editPermissionsObserver.getContext().canEditUserAnnotation
    ) {
      ConstantFunctions.stopEvent(e);
      this._config.onSelect(id, e);
    }
  }

  @autobind
  private onTextMouseDown(_id: string, e: PaperMouseEvent): void {
    if (this.selected) {
      this.startDragPoint('2', e);
    }
  }

  @autobind
  private onMouseEnter(): void {
    this._mouseLeaveExecutor.reset();
    if (this.selected || this._state === DrawingsGeometryEntityState.Hover) {
      return;
    }
    this._state = DrawingsGeometryEntityState.Hover;
    this.showOrHidePoints();
  }

  @autobind
  private onMouseLeave(): void {
    this._mouseLeaveExecutor.execute(() => {
      if (this.selected) {
        return;
      }
      this._state = DrawingsGeometryEntityState.Default;
      this.showOrHidePoints();
    });
  }

  private showOrHidePoints(): void {
    if (this._state === DrawingsGeometryEntityState.Default) {
      if (this._visualPoints) {
        this._visualPoints.forEach(x => x.destroy());
        this._visualPoints = null;
      }
    } else if (!this._visualPoints && this._config.editPermissionsObserver.getContext().canEditUserAnnotation) {
      this._visualPoints = [];
      const [start, end] = this._paperPoints;
      this._visualPoints.push(
        this.renderPoint(start, '0'),
        this.renderPoint(end, '1'),
      );
    }
  }

  private renderPoint(point: paper.Point, id: string): DrawingsGeometryEntityPoint {
    return new DrawingsGeometryEntityPoint(
      {
        id,
        renderParamsContextObserver: this._config.renderParamsContextObserver,
        geometry: point,
        layer: this._config.layer,
        canModify: true,
        onStartDrag: this.startDragPoint,
        onMouseEnter: this.onMouseEnter,
        onMouseLeave: this.onMouseLeave,
        forceCanMove: true,
      },
    );
  }

  @autobind
  private startDragPoint(id: string, e: PaperMouseEvent): void {
    ConstantFunctions.stopEvent(e);
    this.onMouseEnter();
    this._dragPointIndex = id;
    this._config.dragProcessingHelper.setCallback(this.dragPoint);
  }

  @autobind
  private dragPoint({ point, finish }: DrawingsDragCallbackParams): boolean {
    if (point) {
      this._paperPoints[this._dragPointIndex] = point;
      if (this._visualPoints && this._visualPoints[this._dragPointIndex]) {
        this._visualPoints[this._dragPointIndex].paperPosition = point;
      }
    }
    const [start, end, offset] = this._paperPoints;
    this._measure.changeLine([start, end]);
    this._measure.changeOffset(DrawingsCanvasUtils.getPointOfLineOffset(start, end, offset));
    if (finish) {
      this._config.onChangePoints(this._config.id, this._paperPoints.map(({ x, y }) => [x, y]) as DrawingRulerPoints);
      this.onMouseLeave();
    }
    return true;
  }
}
