import { Icons, SvgComponent } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { SidePanelNavigation } from 'common/components/side-panel-navigation';
import { ConstantFunctions } from 'common/constants/functions';
import { DemoProjectType } from 'common/constants/project-type';
import { KreoDialogActions } from 'common/UIKit';
import { AdminDemoProjectsActions } from '../../actions/creators/demo-projects';
import { CREATE_DEMO_PROJECT_DIALOG_NAME, CreateDemoProjectDialog } from '../../components/demo-projects';
import { DemoProjects2d } from '../../components/demo-projects-2d';
import { AdminMasterPage } from '../../master';
import { Styled } from './styled';

export enum MenuTab {
  Projects,
  Database,
}

interface MenuItem {
  text: string;
  Icon: SvgComponent;
  onClick: () => void;
  type: DemoProjectType;
}

interface DispatchProps {
  showDialog: (name: string) => void;
  createDemoProject: () => void;
}

interface ComponentState {
  activeTabIndex: number;
}

export class AdminDemoProjectsPageComponent extends React.Component<DispatchProps, ComponentState> {
  private projectsMenu: MenuItem[] = [
    {
      text: 'Demo Projects 2d',
      Icon: Icons.DemoProjectNavigation,
      onClick: () => this.changeTab(MenuTab.Projects),
      type: DemoProjectType.Project2d,
    },
    {
      text: 'Assemblies Database',
      Icon: Icons.DatabaseSmall,
      onClick: () => this.changeTab(MenuTab.Database),
      type: DemoProjectType.PiaDb,
    },
  ];

  public constructor(props: DispatchProps) {
    super(props);
    this.state = {
      activeTabIndex: MenuTab.Projects,
    };
  }

  public render(): JSX.Element {
    const activeTab = this.projectsMenu[this.state.activeTabIndex];
    return (
      <AdminMasterPage title='Demo Projects'>
        <Styled.Container>
          <SidePanelNavigation
            menuItems={this.projectsMenu}
            Logo={Icons.TakeOffLogo}
            SmallLogo={Icons.TakeOffIcon}
            onClickLogo={ConstantFunctions.doNothing}
            activeItem={activeTab.text}
            onCreateButtonClick={this.openCreate2dDemoProjectDialog}
            isCollapsed={false}
            collapseToggle={ConstantFunctions.doNothing}
            createButtonText={'Create'}
          />
          <DemoProjects2d
            projectType={activeTab.type}
            title={activeTab.text}
          />
          <CreateDemoProjectDialog
            onSubmit={this.props.createDemoProject}
            projectType={activeTab.type}
          />
        </Styled.Container>
      </AdminMasterPage>
    );
  }

  @autobind
  private openCreate2dDemoProjectDialog(): void {
    this.props.showDialog(CREATE_DEMO_PROJECT_DIALOG_NAME);
  }

  @autobind
  private changeTab(index: number): void {
    this.setState({ activeTabIndex: index });
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    showDialog: dialogName => dispatch(KreoDialogActions.openDialog(dialogName)),
    createDemoProject: () => dispatch(AdminDemoProjectsActions.createDemoProject()),
  };
};

const reduxConnector = connect(null, mapDispatchToProps);

export const AdminDemoProjectsPage = reduxConnector(AdminDemoProjectsPageComponent);
