import { AnyAction } from 'redux';

import { RequestStatus } from 'common/enums/request-status';
import { StepActionTypes } from '../units/scenarios/actions';
import {
  ActivityGroupingStepPage,
  ActivityGroupsReduxState,
} from '../units/scenarios/interfaces';
import {
  activityGroupingStepReducerMethods,
  ActivityGroupsReducerMethods,
  engineActivityGroupingStepReducerMethods,
  MacroSequenceReducerMethods,
} from '../units/scenarios/reducers';

const INITIAL_STATE: ActivityGroupsReduxState = {
  activeStepPage: ActivityGroupingStepPage.ActivityGrouping,

  workPackages: {},
  activities: {},
  activityGroups: {},
  categories: {},
  ungroupedActivities: {},
  ungroupedActivityGroupIdToId: {},
  bimIdToActivityIds: {},

  activeWorkPackageId: null,
  activeActivityGroupId: null,
  activeCategoryId: null,
  activeActivityId: null,

  activityGrouping: {
    changes: {
      activities: [],
      activityGroups: [],
      categories: [],
      removedActivityGroupIds: [],
      removedCategoryIds: [],
    },
    dndItems: {
      activity: null,
      activityGroup: null,
    },
    availableIds: {
      activityGroup: 0,
      category: 0,
    },
    selectedActivityIds: {},
    selectedActivityGroupIds: {},
  },

  statuses: {
    activityGrouping: RequestStatus.Loaded,
    macroSequence: RequestStatus.Loaded,
    updateActivityGrouping: RequestStatus.Loaded,
    updateMacroSequence: RequestStatus.Loaded,
  },

  macroSequence: {
    edgeCreator: null,
    graph: {
      limitations: {},
      vertexIds: [],
    },
    changes: [],
    highlightedGraphItemId: null,
    filteredWorkPackageIds: null,
    filteredActivityGroupIds: null,
  },

  engine: {
    availableBimHandleIds: [],
    defaultEngineAvailableBimElementIds: [],
    selectedIds: [],
    colorToBimIdsMap: {},
    highlightedIds: [],
  },
};

export default function(
  state: ActivityGroupsReduxState = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): ActivityGroupsReduxState {
  if (action.type in ActivityGroupsReducerMethods) {
    return ActivityGroupsReducerMethods[action.type](state, action.payload);
  } else if (action.type in MacroSequenceReducerMethods) {
    return MacroSequenceReducerMethods[action.type](state, action.payload);
  } else if (action.type in activityGroupingStepReducerMethods) {
    return activityGroupingStepReducerMethods[action.type](state, action.payload);
  } else if (action.type in engineActivityGroupingStepReducerMethods) {
    return engineActivityGroupingStepReducerMethods[action.type](state, action.payload);
  } else {
    if (action.type === StepActionTypes.DROP_ACTIVITY_GROUPING_STATE) {
      return INITIAL_STATE;
    }
    return state;
  }
}
