import { Action } from 'redux';
import { ActionWith } from 'common/interfaces/action-with';
import { StringDictionary } from 'common/interfaces/dictionary';
import { EngineActionTypes } from '../types';

function setColoredBimElements(colorToBimIds: StringDictionary<number[]>): ActionWith<StringDictionary<number[]>> {
  return {
    type: EngineActionTypes.SET_COLORED_BIM_ELEMENTS,
    payload: colorToBimIds,
  };
}

function resetColoredBimElements(): Action {
  return {
    type: EngineActionTypes.RESET_COLORED_BIM_ELEMENTS,
  };
}

function setHighlightedBimElements(ids: number[]): ActionWith<number[]> {
  return {
    type: EngineActionTypes.SET_HIGHLIGHTED_BIM_ELEMENTS,
    payload: ids,
  };
}

function setSelectedBimElements(ids: number[]): ActionWith<number[]> {
  return {
    type: EngineActionTypes.SET_SELECTED_BIM_ELEMENTS,
    payload: ids,
  };
}

function setAvailableBimElements(ids: number[] | null): ActionWith<number[]> {
  return {
    type: EngineActionTypes.SET_AVAILABLE_BIM_ELEMENTS,
    payload: ids,
  };
}

function setDefaultAvailableBimElements(ids: number[] | null): ActionWith<number[]> {
  return {
    type: EngineActionTypes.SET_DEFAULT_AVAILABLE_BIM_ELEMENTS,
    payload: ids,
  };
}

function resetEngine(): Action {
  return {
    type: EngineActionTypes.RESET_ENGINE,
  };
}

export const EngineActions = {
  setColoredBimElements,
  resetColoredBimElements,
  setSelectedBimElements,
  setHighlightedBimElements,
  setAvailableBimElements,
  setDefaultAvailableBimElements,
  resetEngine,
};
