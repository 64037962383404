import { RequestStatus } from 'common/enums/request-status';
import { ActionWith } from 'common/interfaces/action-with';
import { ShortPointDescription } from '../../interfaces';
import { PdfPageFilterResponse } from '../../interfaces/api-responses/pdf-filter';
import {
  PdfFilterBasePayload,
  PdfFilterRectPayload,
  PdfFilterSwitchPayload,
  PdfSaveFilterResultPayload,
  PdfSetFilterStatusPayload,
} from '../payloads/pdf-filter';
import { PdfFilterActionTypes } from '../types/pdf-filter';

function hideBackground(fileId: string, pageId: string): ActionWith<PdfFilterBasePayload> {
  return {
    type: PdfFilterActionTypes.HIDE_BACKGROUND,
    payload: { fileId, pageId },
  };
}

function switchFilter(
  fileId: string,
  pageId: string,
  value: boolean,
): ActionWith<PdfFilterSwitchPayload> {
  return {
    type: PdfFilterActionTypes.SWITCH,
    payload: { fileId, pageId, value },
  };
}

function createEmptyFilter(fileId: string, pageId: string, afterCreate: () => void): ActionWith<PdfFilterBasePayload> {
  return {
    type: PdfFilterActionTypes.CREATE_EMPTY,
    payload: { fileId, pageId, afterCreate },
  };
}

function addToFilter(
  fileId: string,
  pageId: string,
  rect: ShortPointDescription[],
): ActionWith<PdfFilterRectPayload> {
  return {
    type: PdfFilterActionTypes.ADD_RECT,
    payload: { fileId, pageId, rect },
  };
}

function saveFilterResult(
  pageId: string,
  filter: PdfPageFilterResponse,
): ActionWith<PdfSaveFilterResultPayload> {
  return {
    type: PdfFilterActionTypes.SAVE_FILE_FILTER,
    payload: { pageId, filter },
  };
}

function setFilterApplyStatus(pageId: string, status: RequestStatus): ActionWith<PdfSetFilterStatusPayload> {
  return {
    type: PdfFilterActionTypes.SET_STATUS,
    payload: { pageId, status },
  };
}


export const PdfFilterActions = {
  hideBackground,
  switchFilter,
  createEmptyFilter,
  addToFilter,
  saveFilterResult,
  setFilterApplyStatus,
};
