import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { DrawingsActions } from '../../actions/creators/common';
import { DrawingsUserAnnotationActions } from '../../actions/creators/user-annotation';
import { useDrawingsLayoutApi } from '../../drawings-layout-api-context';
import { DrawingsDrawMode } from '../../enums';
import {
  DrawingsGeometryInstance,
  DrawingsGroupMeasure,
  DrawingsInstanceMeasure,
  DrawingsPointInfo,
  DrawingsShortInfo,
} from '../../interfaces';
import { DrawingsMeasureUtils } from '../../utils/drawings-measure-utils';
import { DrawingsUtils } from '../../utils/drawings-utils';
import { useDrawModeApi } from '../use-draw-mode-api';

export interface ApplyCalibrationPayload {
  paperSize: string;
  drawingLength: number;
  originalLength: number;
}

export function useRecalculateInstancesByScale(): (drawingIds: string[], payload: ApplyCalibrationPayload) => void {
  const dispatch = useDispatch();
  const elementMeasurement = useSelector<State, Record<string, DrawingsInstanceMeasure>>(
    s => s.drawings.elementMeasurement,
  );
  const pointsInfo = useSelector<State, Record<string, DrawingsPointInfo>>(s => s.drawings.aiAnnotation.pointsInfo);
  const instances = useSelector<State, Record<string, DrawingsGeometryInstance>>(s => s.drawings.aiAnnotation.geometry);
  const groupsMeasuresCache = useSelector<State, Record<string, DrawingsGroupMeasure>>(
    s => s.drawings.userAnnotations.groupsMeasuresCache,
  );
  const drawingsInfo = useSelector<State, Record<string, DrawingsShortInfo>>(s => s.drawings.drawingsInfo);
  const drawingLayoutApi = useDrawingsLayoutApi();

  return useCallback(
    (drawingIds, payload) => {


      const drawingIdsSet = new Set(drawingIds);
      const scale = payload.originalLength / payload.drawingLength;
      const resultMeasures = new Array<DrawingsInstanceMeasure>();
      const updatedGroups: Record<string, DrawingsGroupMeasure> = {};
      const getGroupToUpdate = (groupId: string): DrawingsGroupMeasure => {
        if (groupId in updatedGroups) {
          return updatedGroups[groupId];
        }
        return { ...groupsMeasuresCache[groupId] };
      };
      for (const measure of Object.values(elementMeasurement)) {
        const instanceId = Array.isArray(measure.id) ? pointsInfo[measure.id[0]].instanceId : measure.id;
        if (!drawingIdsSet.has(instances[instanceId].drawingId)) {
          continue;
        }
        const { width, height } = drawingsInfo[instances[instanceId].drawingId];
        const metersPerPx = DrawingsUtils.getMetersPerPx(width, height, payload.paperSize);
        const groupId = instances[instanceId].groupId;
        const updatedMeasure = DrawingsMeasureUtils.updateMeasureWithScale(measure, metersPerPx, scale);
        if (groupId && groupId in groupsMeasuresCache) {
          const negativizedCurrent = DrawingsMeasureUtils.negativizeMeasure(measure);
          const group = DrawingsMeasureUtils.addToGroupMeasures(getGroupToUpdate(groupId), negativizedCurrent);
          updatedGroups[groupId] = DrawingsMeasureUtils.addToGroupMeasures(group, updatedMeasure);
        }
        resultMeasures.push(updatedMeasure);
      }

      drawingLayoutApi.onInstancesMeasuresUpdated(resultMeasures);
      dispatch(DrawingsUserAnnotationActions.saveGroupMeasure(updatedGroups));
    },
    [elementMeasurement, pointsInfo, instances, groupsMeasuresCache, drawingLayoutApi, dispatch, drawingsInfo],
  );

}

export function useApplyCalibration(): (drawingIds: string[], payload: ApplyCalibrationPayload) => void {
  const drawingsInfo = useSelector<State, Record<string, DrawingsShortInfo>>(s => s.drawings.drawingsInfo);
  const { setDrawMode, drawMode } = useDrawModeApi();

  const dispatch = useDispatch();

  const recalculateMeasuresByScale = useRecalculateInstancesByScale();

  return useCallback(
    (drawingIds, payload) => {
      const updatedDrawingScale = [];
      for (const id of drawingIds) {
        const drawing = drawingsInfo[id];
        if (!drawing) {
          continue;
        }
        const { originalCalibrationLineLength, drawingCalibrationLineLength, paperSize } = drawing;
        if (
          originalCalibrationLineLength === payload.originalLength &&
          drawingCalibrationLineLength === payload.drawingLength &&
          paperSize === payload.paperSize
        ) {
          continue;
        }

        const { width, height } = drawing;
        const metersPerPx = DrawingsUtils.getMetersPerPx(width, height, payload.paperSize);
        updatedDrawingScale.push({
          drawingId: id,
          paperSize: payload.paperSize,
          metersPerPx,
          originalCalibrationLineLength: payload.originalLength,
          drawingCalibrationLineLength: payload.drawingLength,
        });
      }

      if (!updatedDrawingScale.length) {
        setDrawMode(DrawingsDrawMode.Disabled);
        return;
      }

      dispatch(DrawingsActions.pagesRescale(updatedDrawingScale));

      if (drawMode === DrawingsDrawMode.Calibrate || drawMode === DrawingsDrawMode.CalibrateScale) {
        setDrawMode(DrawingsDrawMode.Disabled);
      }
      recalculateMeasuresByScale(drawingIds, payload);
    },
    [drawingsInfo, dispatch, setDrawMode, drawMode, recalculateMeasuresByScale],
  );
}


export function useApplySettedCalibrationFromState(): (drawingsIds: string[]) => void {
  const paperSize = useSelector<State, string>(s => s.drawings.temporaryCalibration.paperSize);
  const drawingLength = useSelector<State, number>(s => s.drawings.temporaryCalibration.drawingLength);
  const originalLength = useSelector<State, number>(s => s.drawings.temporaryCalibration.originalLength);
  const applyCalibration = useApplyCalibration();

  return useCallback((drawingsIds) => {
    applyCalibration(drawingsIds, {
      paperSize,
      drawingLength,
      originalLength,
    });
  }, [paperSize, drawingLength, originalLength, applyCalibration]);
}
