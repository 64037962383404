import { FormulaDefaultFunctions } from '../input/constants';

export const MEASURED_FUNCTIONS_DESC: Record<FormulaDefaultFunctions, { link: string, animation: string }> = {
  [FormulaDefaultFunctions.MeasuredArea]: {
    link: 'https://public.rive.app/hosted/280597/124886/nsJYdq9MnUG37rvqTzU6JQ.riv',
    animation: 'Area',
  },
  [FormulaDefaultFunctions.MeasuredLength]: {
    link: 'https://public.rive.app/hosted/280597/124877/Ssi_qqZZzEKMDI2yhXinSw.riv',
    animation: 'Length',
  },
  [FormulaDefaultFunctions.MeasuredSegmentsCount]: {
    link: 'https://public.rive.app/hosted/280597/124878/j8Qw_xIj9kGN2BEU1k4LNA.riv',
    animation: 'Segment count',
  },
  [FormulaDefaultFunctions.MeasuredPointsCount]: {
    link: 'https://public.rive.app/hosted/280597/124879/8xUMeJn0Lkeu3f-_o3yZ0A.riv',
    animation: 'Points count',
  },
  [FormulaDefaultFunctions.MeasuredHeight]: {
    link: 'https://public.rive.app/hosted/280597/124880/k9oVkiSsckihvQeSg53PsA.riv',
    animation: 'Height',
  },
  [FormulaDefaultFunctions.MeasuredThickness]: {
    link: 'https://public.rive.app/hosted/280597/124881/mQoVapHYIUyJe27pc3Bmbw.riv',
    animation: 'Thickness',
  },
  [FormulaDefaultFunctions.MeasuredPerimeter]: {
    link: 'https://public.rive.app/hosted/280597/125681/c6tv-B5nFUy6UsX0o_VsQA.riv',
    animation: 'Perimeter',
  },
  [FormulaDefaultFunctions.MeasuredVerticalArea]: {
    link: 'https://public.rive.app/hosted/280597/124883/2W3KJckKs0OP0fB80icmvg.riv',
    animation: 'Vertical area',
  },
  [FormulaDefaultFunctions.MeasuredVolume]: {
    link: 'https://public.rive.app/hosted/280597/124884/LNwOC19t3kedqU8dx2JPcw.riv',
    animation: 'Volume',
  },
  [FormulaDefaultFunctions.MeasuredCount]: {
    link: 'https://public.rive.app/hosted/280597/124885/CPeHNOimE0WBufJL3uhEQg.riv',
    animation: 'Count',
  },
  [FormulaDefaultFunctions.MeasuredRectangleMax]: null,
  [FormulaDefaultFunctions.MeasuredRectangleMin]: null,
  [FormulaDefaultFunctions.MeasuredPageName]: null,
};

export const FunctionsDescriptions: Record<string, { text: string, description: string }> = {
  ABS: {
    text: 'Returns the absolute value of a number.',
    description: 'Example: ABS(-5) returns 5.',
  },
  AND: {
    text: 'Returns TRUE if all its arguments are TRUE.',
    description: 'Example: AND(TRUE, FALSE) returns FALSE.',
  },
  AVERAGE: {
    text: 'Calculates the average of its arguments.',
    description: 'Example: AVERAGE(1, 2, 3) returns 2.',
  },
  CHOOSE: {
    text: 'Selects a value from a list of values based on index number.',
    description: 'Example: CHOOSE(2, "apple", "banana", "cherry") returns banana.',
  },
  CONCAT: {
    text: 'Combines two or more strings into one string.',
    description: 'Example: CONCAT("Hello", " ", "World") returns Hello World.',
  },
  COS: {
    text: 'Returns the cosine of a number.',
    description: 'Example: COS(0) returns 1.',
  },
  COT: {
    text: 'Returns the cotangent of a number.',
    description: 'Example: COT(PI()/4) returns 1.',
  },
  COUNT: {
    text: 'Counts the number of cells that contain numbers in a range.',
    description: 'Example: COUNT(1, 2, "a", "b") returns 2.',
  },
  DEGREES: {
    text: 'Converts radians to degrees.',
    description: 'Example: DEGREES(PI()) returns 180.',
  },
  FIND: {
    text: 'Finds one text string within another and returns the position of the first occurrence.',
    description: 'Example: FIND("n", "banana") returns 3.',
  },
  IF: {
    text: 'Checks whether a condition is met and returns one value if TRUE and another value if FALSE.',
    description: 'Example: IF(1>2, "Yes", "No") returns No.',
  },
  LEFT: {
    text: 'Returns the first character(s) in a text string.',
    description: 'Example: LEFT("Hello", 2) returns He.',
  },
  LEN: {
    text: 'Returns the number of characters in a text string.',
    description: 'Example: LEN("Hello") returns 5.',
  },
  MAX: {
    text: 'Returns the maximum value in a list of arguments.',
    description: 'Example: MAX(1, 2, 3) returns 3.',
  },
  MEDIAN: {
    text: 'Returns the median of the given numbers.',
    description: 'Example: MEDIAN(1, 2, 3, 4, 5) returns 3.',
  },
  MID: {
    text: 'Returns a specific number of characters from a text string starting at the position you specify.',
    description: 'Example: MID("apple", 2, 3) returns ppl.',
  },
  MIN: {
    text: 'Returns the minimum value in a list of arguments.',
    description: 'Example: MIN(1, 2, 3) returns 1.',
  },
  NOT: {
    text: 'Reverses the logic of its argument.',
    description: 'Example: NOT(FALSE) returns TRUE.',
  },
  OR: {
    text: 'Returns TRUE if any argument is TRUE.',
    description: 'Example: OR(TRUE, FALSE) returns TRUE.',
  },
  PI: {
    text: 'Returns the value of π (pi).',
    description: 'Example: PI() returns approximately 3.14159.',
  },
  POWER: {
    text: 'Returns the result of a number raised to a power.',
    description: 'Example: POWER(2, 3) returns 8.',
  },
  RADIANS: {
    text: 'Converts degrees to radians.',
    description: 'Example: RADIANS(180) returns PI().',
  },
  RIGHT: {
    text: 'Returns the last character(s) in a text string.',
    description: 'Example: RIGHT("Hello", 2) returns lo.',
  },
  ROUND: {
    text: 'Rounds a number to a specified number of digits.',
    description: 'Example: ROUND(2.567, 2) returns 2.57.',
  },
  ROUNDDOWN: {
    text: 'Rounds a number down, towards zero, to a specified number of digits.',
    description: 'Example: ROUNDDOWN(2.567, 2) returns 2.56.',
  },
  ROUNDUP: {
    text: 'Rounds a number up, away from zero, to a specified number of digits.',
    description: 'Example: ROUNDUP(2.567, 2) returns 2.57.',
  },
  SIN: {
    text: 'Returns the sine of the given angle.',
    description: 'Example: SIN(PI()/2) returns 1.',
  },
  SQRT: {
    text: 'Returns the square root of a number.',
    description: 'Example: SQRT(9) returns 3.',
  },
  SWITCH: {
    // eslint-disable-next-line max-len
    text: 'Evaluates an expression against a list of values and returns the result corresponding to the first matching value.',
    description: 'Example: SWITCH(2, 1, "one", 2, "two", "none") returns two.',
  },
  TAN: {
    text: 'Returns the tangent of a number.',
    description: 'Example: TAN(PI()/4) returns 1.',
  },
};
