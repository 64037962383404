import {
  DrawingsGeometryInstance,
  DrawingsGeometryInstanceWithId,
  DrawingsGeometryUtils,
  DrawingsInstanceMeasure,
} from 'common/components/drawings';
import {
  DrawingsBatchUpdateGeometries,
  DrawingsUpdateAnnotationGeometry,
} from 'common/components/drawings/actions/payloads/annotation';
import { DrawingsInstanceType } from 'common/components/drawings/enums/drawings-instance-type';
import { ShortPointDescription } from 'common/components/drawings/interfaces/drawing-ai-annotation';
import { DrawingsCoordinateId } from 'common/components/drawings/interfaces/drawing-point-info';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import {
  DrawingsGeometryStrokedType,
  DrawingsPolygonGeometry,
} from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingAnnotationNamingUtils } from 'common/components/drawings/utils/drawing-annotation-naming-utils';
import { DrawingAnnotationUtils } from 'common/components/drawings/utils/drawing-annotation-utils';
import { arrayUtils } from 'common/utils/array-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { GetColorOfInstanceCallback, GetInstanceMeasureCallback, GetPointCoordinatesCallback } from '../../interfaces';

interface GeometryData {
  lines: Record<string, boolean>;
  coordinateId: DrawingsCoordinateId;
  points: string[];
  lineMeasures: Record<string, DrawingsInstanceMeasure>;
}

export interface ProcessingResult {
  changes: DrawingsBatchUpdateGeometries;
  oldMeasures: DrawingsInstanceMeasure[];
  newMeasures: DrawingsInstanceMeasure[];
}

function processContour(
  elementPoints: string[],
  lines: Record<string, boolean>,
  coordinateId: DrawingsCoordinateId,
  lineMeasures: Record<string, DrawingsInstanceMeasure>,
  points: string[],
  type: DrawingsInstanceType,
  getPointCoordinates: GetPointCoordinatesCallback,
  getInstanceMeasures: GetInstanceMeasureCallback,
): void {
  for (const [start, end] of DrawingAnnotationUtils.iteratePoints(elementPoints, type)) {
    const [x, y] = getPointCoordinates(start);
    coordinateId[x] = coordinateId[x] || {};
    coordinateId[x][y] = start;
    points.push(start);

    if (!end) {
      continue;
    }

    const lineKey = DrawingAnnotationUtils.getLineKey(start, end);
    const measures = getInstanceMeasures(lineKey);
    if (measures) {
      lineMeasures[lineKey] = measures;
    }
    lines[lineKey] = false;
  }
}

function collectGeometryData(
  instance: DrawingsGeometryInstance<DrawingsGeometryStrokedType>,
  getPointCoordinates: GetPointCoordinatesCallback,
  getInstanceMeasures: GetInstanceMeasureCallback,
): GeometryData {
  const { geometry, type } = instance;
  const lines: Record<string, boolean> = {};
  const coordinateId: DrawingsCoordinateId = {};
  const points = new Array<string>();
  const lineMeasures: Record<string, DrawingsInstanceMeasure> = {};
  processContour(
    geometry.points,
    lines,
    coordinateId,
    lineMeasures,
    points,
    type,
    getPointCoordinates,
    getInstanceMeasures,
  );
  if (DrawingsGeometryUtils.isClosedContour(type, geometry)) {
    if (DrawingsGeometryUtils.isPolygon(type, geometry) && geometry.children) {
      for (const child of geometry.children) {
        processContour(
          child,
          lines,
          coordinateId,
          lineMeasures,
          points,
          type,
          getPointCoordinates,
          getInstanceMeasures,
        );
      }
    }
  }

  return { lines, coordinateId, lineMeasures, points };
}


function mapPolygonChildrenPoints(
  newGeometry: DrawingsPolygonGeometry,
  oldGeometry: DrawingsPolygonGeometry,
  target: DrawingsPolygonGeometry,
  changePointId: (pointId: string) => string,
): void {
  if (newGeometry.children) {
    target.children = newGeometry.children.map(x => x.map(p => changePointId(p)));
  } else if (oldGeometry.children) {
    delete target.children;
  }
}

function mapGeometryPoints<T extends DrawingsGeometryStrokedType>(
  baseGeometry: T,
  geometry: T,
  type: DrawingsInstanceType,
  color: string,
  changePointId: (pointId: string) => string,
): T {

  const targetGeometry: T = {
    ...baseGeometry,
    color,
    points: geometry.points.map((point) => changePointId(point)),
  };
  if (DrawingsGeometryUtils.isPolyline(type, geometry[1])) {
    return targetGeometry;
  } else {
    mapPolygonChildrenPoints(
      geometry as DrawingsPolygonGeometry,
      baseGeometry as DrawingsPolygonGeometry,
      targetGeometry as DrawingsPolygonGeometry,
      changePointId,
    );
  }

  return targetGeometry;
}

function fillMeasures(
  oldLines: Record<string, boolean>,
  oldMeasures: Record<string, DrawingsInstanceMeasure>,
  baseInstanceId: string,
  baseGeometry: DrawingsGeometryInstanceWithId,
  newInstanceType: DrawingsInstanceType,
  getInstanceMeasures: GetInstanceMeasureCallback,
  { scale, metersPerPixel }: MeasuresViewSettings,
  getPointCoordinates: GetPointCoordinatesCallback,
): DrawingsInstanceMeasure[] {
  const result = new Array<DrawingsInstanceMeasure>();
  for (const [key, value] of Object.entries(oldLines)) {
    if (value && key in oldMeasures) {
      result.push(oldMeasures[key]);
    }
  }

  const instanceMeasures = getInstanceMeasures(baseInstanceId);
  if (instanceMeasures) {
    const color = instanceMeasures.color;
    oldMeasures[baseInstanceId] = instanceMeasures;
    const newMeasure = DrawingsGeometryUtils.calculateInstanceMeasurements(
      baseGeometry,
      scale,
      metersPerPixel,
      getPointCoordinates,
    );
    result.push({
      id: baseInstanceId,
      measures: newMeasure,
      type: newInstanceType,
      color,
    });
  }
  return result;
}


function processStrokedGeometryResultAsEdit(
  baseInstanceId: string,
  instance: DrawingsGeometryInstance<DrawingsGeometryStrokedType>,
  newPoints: Record<string, ShortPointDescription>,
  polygonsGeometry: DrawingsGeometryStrokedType[],
  removedInstances: string[],
  currentDrawingId: string,
  getPointCoordinates: GetPointCoordinatesCallback,
  getInstanceMeasures: GetInstanceMeasureCallback,
  scaleParams: MeasuresViewSettings,
  getColorOfInstance: GetColorOfInstanceCallback,
): ProcessingResult {
  let addedPoints = {};
  const usedPointsSet = new Set<string>();
  const groupId = instance.groupId;
  const {
    lines,
    lineMeasures: oldMeasures,
    coordinateId: oldPoints,
    points,
  } = collectGeometryData(instance, getPointCoordinates, getInstanceMeasures);


  const changePointId = (pointId: string, fromMain?: boolean): string => {
    const [x, y] = newPoints[pointId];
    if (oldPoints[x] && oldPoints[x][y] && !usedPointsSet.has(oldPoints[x][y])) {
      usedPointsSet.add(oldPoints[x][y]);
      const id = oldPoints[x][y];
      if (!fromMain) {
        addedPoints[id] = [x, y];
      }
      return id;
    } else {
      usedPointsSet.add(pointId);
      addedPoints[pointId] = [x, y];
      return pointId;
    }
  };

  const firstPolygon = polygonsGeometry.pop();

  const color = getColorOfInstance(baseInstanceId);

  const mappedBasePolygon = mapGeometryPoints(
    instance.geometry,
    firstPolygon,
    instance.type,
    color,
    pointId => changePointId(pointId, true),
  );

  const newInstanceType =  DrawingsGeometryUtils.isClosedContour(instance.type, instance.geometry)
    ? DrawingsInstanceType.Polygon
    : instance.type;

  const newLines = {};
  for (const lineKey of DrawingAnnotationUtils.iterateLines(mappedBasePolygon, newInstanceType)) {
    if (!(lineKey in lines)) {
      newLines[lineKey] = DrawingAnnotationUtils.getPointsIdsFromLineKey(lineKey);
    }
    lines[lineKey] = true;
  }

  const baseGeometry = {
    ...instance,
    id: baseInstanceId,
    geometry: mappedBasePolygon,
    type: newInstanceType,
  };

  const updated: DrawingsUpdateAnnotationGeometry = {
    instance: baseGeometry,
    newPoints: addedPoints,
    removePoints: points.filter(x => !usedPointsSet.has(x)),
    lines: {
      removedLines: arrayUtils.filterMap(Object.entries(lines), ([_, value]) => !value, ([key]) => key),
      addLines: newLines,
    },
  };
  addedPoints = {};

  const createdRemoved: DrawingsBatchUpdateGeometries = {
    removedInstances: removedInstances.filter(x => x !== baseInstanceId),
    newPoints: addedPoints,
    addedInstances: [],
    pageId: currentDrawingId,
    updated,
  };

  if (polygonsGeometry.length) {
    const castedType = DrawingAnnotationNamingUtils.getDefaultGeometryName(newInstanceType);

    for (const polygonGeometry of polygonsGeometry) {
      const id = UuidUtil.generateUuid();
      const geometry = mapGeometryPoints(instance.geometry, polygonGeometry, instance.type, color, changePointId);
      createdRemoved.addedInstances.push(
        {
          id,
          drawingId: currentDrawingId,
          type: newInstanceType,
          name: castedType,
          geometry,
          isAuto: false,
          groupId,
        },
      );

      for (const lineKey of DrawingAnnotationUtils.iterateLines(geometry, DrawingsInstanceType.Polygon)) {
        lines[lineKey] = true;
      }
    }
  }

  const newMeasures = fillMeasures(
    lines,
    oldMeasures,
    baseInstanceId,
    baseGeometry,
    updated.instance.type,
    getInstanceMeasures,
    scaleParams,
    pointId => (updated.newPoints ? updated.newPoints[pointId] : null) || getPointCoordinates(pointId),
  );
  return { changes: createdRemoved, oldMeasures: Object.values(oldMeasures), newMeasures };
}

export const DrawingsOperationResultsPostProcessors = {
  processStrokedGeometryResultAsEdit,
  collectGeometryData,
};
