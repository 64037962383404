import * as Ag from 'ag-grid-community';

import { DeferredExecutor } from 'common/utils/deferred-executer';
import { TreeTableDragEventsHandlers, TreeTableGroupRules } from './interfaces';


export enum DragType {
  InsertAfter = 'insertAfter',
  InsertInto = 'insertInto',
}
export abstract class RowDragHelper {
  protected deferredExecutor: DeferredExecutor;
  protected dragType: DragType | null;
  protected draggableNode: Ag.RowNode = null;
  protected draggableNodeChildrenIdSet: Set<string> = null;
  protected overNode: Ag.RowNode = null;
  protected groupRules: TreeTableGroupRules;
  protected eventHandlers: TreeTableDragEventsHandlers;

  constructor(
    changeDragTypeTime: number,
    groupRules: TreeTableGroupRules,
    eventHandlers?: TreeTableDragEventsHandlers,
  ) {
    this.deferredExecutor = new DeferredExecutor(changeDragTypeTime);
    this.groupRules = groupRules;
    this.eventHandlers = eventHandlers;
  }

  public abstract moveNode(
    api: Ag.GridApi,
    node: Ag.RowNode,
    onMove: (parentId: string | null, position: number, rowIdsToMove: string[]) => void,
  ): void;

  public abstract setOverNode(api: Ag.GridApi, overNode: Ag.RowNode): void;

  public abstract reset(api: Ag.GridApi): void;

  public getDragType(node: Ag.RowNode): DragType {
    return node === this.overNode
      ? this.dragType
      : null;
  }

  public setDraggableNode(draggableNode: Ag.RowNode): void {
    this.draggableNode = draggableNode;
    this.draggableNodeChildrenIdSet = new Set(draggableNode.allLeafChildren.map(node => node.id));
  }

  public freezeDragType(): void {
    this.deferredExecutor.reset();
  }

  public getOverNode(): Ag.RowNode | null {
    return this.overNode;
  }

  public onDragEnd(): void {
    if (this.eventHandlers && this.eventHandlers.onDragEnd) {
      this.eventHandlers.onDragEnd();
    }
  }

  public onDragStart(): void {
    if (this.eventHandlers && this.eventHandlers.onDragStart) {
      this.eventHandlers.onDragStart();
    }
  }

  protected innerReset(api: Ag.GridApi, withRefresh: boolean): void {
    this.deferredExecutor.reset();
    if (this.overNode) {
      const prevOverNode = this.overNode;
      this.overNode = null;
      if (withRefresh) {
        this.refreshRows(api, [prevOverNode]);
      }
    }
    this.draggableNode = null;
    this.draggableNodeChildrenIdSet = null;
    this.dragType = null;
  }

  protected innerSetOverNode(api: Ag.GridApi, overNode: Ag.RowNode, withRefresh: boolean): void {
    const newOverNode = this.groupRules.getClosestAvailableOverNode(this.draggableNode, overNode);
    if (this.overNode === newOverNode) {
      return;
    }

    this.setDragType(api, newOverNode, withRefresh);
    if (this.dragType) {
      const previousOverNode = this.overNode && api.getRowNode(this.overNode.data.id);
      this.overNode = newOverNode;
      const rowsToUpdate = [this.overNode];
      if (previousOverNode) {
        rowsToUpdate.push(previousOverNode);
      }
      if (withRefresh) {
        this.refreshRows(api, rowsToUpdate);
      }
    } else if (this.overNode) {
      if (withRefresh) {
        this.refreshRows(api, [this.overNode]);
      }
      this.overNode = null;
    }
  }

  protected setDragType(api: Ag.GridApi, newOverNode: Ag.RowNode, withRefresh: boolean): void {
    if (this.draggableNode === newOverNode || this.isOverNodeInDraggableNodeChildrenIdSet(newOverNode)) {
      this.deferredExecutor.reset();
      this.dragType = null;
    } else if (this.groupRules.availableInsertAfter(this.draggableNode, newOverNode)) {
      this.dragType = DragType.InsertAfter;
      if (this.groupRules.availableInsertInto(this.draggableNode, newOverNode)) {
        this.deferredExecutor.execute(() => {
          this.dragType = DragType.InsertInto;
          if (withRefresh) {
            this.refreshRows(api, [this.overNode]);
            if (!this.overNode.expanded && this.overNode.allLeafChildren.length) {
              this.overNode.setExpanded(true);
            }
          }
        });
      } else {
        this.deferredExecutor.reset();
      }
    } else if (this.groupRules.availableInsertInto(this.draggableNode, newOverNode)) {
      this.deferredExecutor.reset();
      this.dragType = DragType.InsertInto;
    } else {
      this.deferredExecutor.reset();
      this.dragType = null;
    }
  }

  protected refreshRows(api: Ag.GridApi, rowsToRefresh: Ag.RowNode[]): void {
    const params: Ag.RefreshCellsParams = {
      rowNodes: rowsToRefresh,
      force: true,
    };
    api.refreshCells(params);
  }

  private isOverNodeInDraggableNodeChildrenIdSet(newOverNode: Ag.RowNode): boolean {
    return newOverNode
      && this.draggableNodeChildrenIdSet
      && this.draggableNodeChildrenIdSet.has(newOverNode.id);
  }
}
