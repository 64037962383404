import { ProjectResourcesApi } from '../../../api/server';
import {
  RevisionForm,
  RevisionInfoModel,
  RevisionInfoModelsC,
  RevisionStateModel,
  RevisionStateModelC,
} from '../interfaces';

function getProjectRevisionInfo(): Promise<RevisionInfoModel[]> {
  return ProjectResourcesApi.getV(`/revisions`, RevisionInfoModelsC);
}

function getRevision(revisionId: number): Promise<RevisionStateModel> {
  return ProjectResourcesApi.getV(`/revisions/${revisionId}`, RevisionStateModelC);
}

function deleteRevision(revisionId: number): Promise<void> {
  return ProjectResourcesApi.delete(`/revisions/${revisionId}`, null);
}

function updateRevision(revisionId: number, form: RevisionForm): Promise<void> {
  return ProjectResourcesApi.put(`/revisions/${revisionId}`, form);
}

function duplicateRevision(revisionId: number, name: string): Promise<void> {
  return ProjectResourcesApi.post(`/revisions/${revisionId}/duplicate?name=${name}`);
}

export const RevisionsApi = {
  getProjectRevisionInfo,
  getRevision,
  deleteRevision,
  updateRevision,
  duplicateRevision,
};


