import { Icons, LinkComponent } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ControlNames } from 'common/constants/control-names';
import { Styled } from './styled';


export const GiftLinkButton: React.FC = () => {
  return (
    <Styled.IconGift controlName={ControlNames.giftButton}>
      <LinkComponent
        id={'refferalgift'}
        Icon={Icons.ReferallProgram}
        fontSize={14}
        text={'Referral program'}
      />
    </Styled.IconGift>
  );
};
