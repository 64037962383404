import * as React from 'react';

export const KreoIconDiff: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke='none'
        id='svg_1'
        fill='currentColor'
        // eslint-disable-next-line max-len
        d='m31.951764,16.33169l-7.646486,0l0,7.952424l-7.646486,0l0,7.952424l7.646486,0l0,7.952424l7.646486,0l0,-7.952424l7.646486,0l0,-7.952424l-7.646486,0l0,-7.952424zm-15.292972,39.762121l22.939458,0l0,-7.952424l-22.939458,0l0,7.952424zm26.762701,-55.666969l-30.585944,0l0,3.976212l28.674323,0l13.381351,13.916742l0,37.774015l3.823243,0l0,-39.762121l-15.292972,-15.904848zm-38.23243,7.952424l0,55.666969l45.878916,0l0,-43.738333l-11.469729,-11.928636l-34.409187,0zm42.055673,51.690757l-38.23243,0l0,-47.714545l28.674323,0l9.558108,9.94053l0,37.774015z'
      />
    </svg>
  );
};
