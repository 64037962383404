import autobind from 'autobind-decorator';
import React from 'react';

import { FormTextInputField } from 'common/components/form-fields';
import { PropertyFormData } from '../interfaces';
import { GroupsDropdown, PropertyNameInput } from './fields';
import { validatePropertyNames } from './helper';

interface Props {
  property: PropertyFormData;
  groups: string[];
  onChange: (data: PropertyFormData, isValid: boolean) => void;
}

export class TextForm extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return (
      <>
        <PropertyNameInput
          value={this.props.property.name}
          onChange={this.triggerChange}
        />
        <GroupsDropdown
          groups={this.props.groups}
          value={this.props.property.groupName}
          onChange={this.triggerChange}
        />
        <FormTextInputField
          label={'Default value'}
          input={{
            value: this.props.property.value.toString(),
            onChange: this.changeValue,
          }}
        />
      </>
    );
  }

  @autobind
  private changeValue(value: string): void {
    this.triggerChange({ value });
  }

  @autobind
  private triggerChange(updates: Record<string, string | number>): void {
    const updatedProperty = { ...this.props.property, ...updates };
    const isValid = validatePropertyNames(updatedProperty);
    this.props.onChange(updatedProperty, isValid);
  }
}
