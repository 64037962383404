import { ElementTooltip, IconButton, Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RenderIf } from 'common/components/render-if';
import { Mood } from 'common/enums/mood';
import { State } from 'common/interfaces/state';
import { ConnectionStatus } from 'common/utils/multi-view/multi-view-reducers';
import { MultiViewersStates } from 'common/utils/multi-view/multi-view-states';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { TwoDFullScreenMode } from 'persisted-storage/interfaces/state';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { StatusIcon } from './status-icon';
import { Styled } from './styled';

interface ConnectionOption {
  text: string;
  color: Mood;
}

interface Props {
  positionTooltip: string;
  openInNewWindow: (path: string) => void;
}

const blockedText = 'To move your report to a separate window, create this report first';
const defaultText = 'Open in a separate window';
const activeText = 'The connection is active';
const connectionLostText = 'Connection lost';

export const OpenInNewWindowButton: React.FC<Props> = React.memo(({
  positionTooltip = 'top',
  openInNewWindow,
}) => {
  const dispatch = useDispatch();
  const { sendEvent } = useAnalytics();
  const connectionStatus = useSelector<State, ConnectionStatus>(s => s.multiView.connectionStatus);
  const projectId = useSelector<State, string>(s => s.projects.currentProject.id.toString());
  const reportCount = useSelector<State, number>(s => s.twoD.reportPages.length);
  const [buttonMood, setButtonMood] = useState<ConnectionOption>({ text: '', color: null });

  const handleOpen = useCallback(() => {
    if (reportCount === 0 || connectionStatus === ConnectionStatus.connect) {
      return;
    }
    if (connectionStatus === null) {
      sendEvent(MetricNames.reports.openReportInNewWindow, { status: 'Open' });
    }
    const path = `${window.location.origin}/2d/project/${projectId}/report`;
    openInNewWindow(path);
    dispatch(PersistedStorageActions.set2dFullScreenMode({ type: TwoDFullScreenMode.Drawings, projectId }));
  }, [reportCount, connectionStatus, projectId, openInNewWindow, dispatch, sendEvent]);

  useEffect(() => {
    const mood = reportCount === 0
      ? { text: blockedText, color: Mood.Disabled }
      : connectionStatus === ConnectionStatus.connect
        ? { text: activeText, color: Mood.Positive }
        : connectionStatus === ConnectionStatus.disconnect
          ? { text: connectionLostText, color: Mood.Negative }
          : { text: defaultText, color: Mood.Default };
    setButtonMood(mood);
  }, [connectionStatus, reportCount]);
  const showConnectionStatus = connectionStatus !== null;
  const isHide = MultiViewersStates.isDisable();
  if (isHide) {
    return null;
  }

  return (
    <>
      <RenderIf condition={showConnectionStatus}>
        <StatusIcon openInNewWindow={handleOpen} canReopen={!!reportCount} />
      </RenderIf>
      <Styled.ButtonWrapper mood={buttonMood.color}>
        <ElementTooltip
          text={buttonMood.text}
          wordBreakAll={true}
          position={positionTooltip}
        >
          <IconButton
            Icon={Icons.OpenReportNewWindow}
            onClick={handleOpen}
            width={20}
            height={20}
            mood={buttonMood.color !== Mood.Default && Mood.Disabled}
          />
        </ElementTooltip>
      </Styled.ButtonWrapper>
    </>
  );
});
