import autobind from 'autobind-decorator';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './page.scss';

import { FaqCaption } from 'common/enums/faq-caption';
import { State } from 'common/interfaces/state';
import { FaqLink } from 'common/UIKit/faq-link';
import { mathUtils } from 'common/utils/math-utils';
import { Engine } from '../../../../components/engine';
import { KreoEngine } from '../../../../components/engine/KreoEngine';
import { SplitterLayout } from '../../../../components/splitter-layout';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { ProjectLayout } from '../../../project-dashbord';
import { GanttChartActions } from '../../actions/creators/gantt-chart';
import { ViewerApi } from '../../api/viewer';
import {
  AnimationDataResponse,
  GanttData,
  LineData,
  ResourcesData,
  TimeframeAnimationTarget,
} from '../../interfaces/gantt-chart';
import {
  animationDataResponseMapper,
  ChartDataProvider,
  GroupType,
  ResourceDescription,
  ResourceType,
} from '../../utils/gantt-chart';
import { actions } from './actions';
import { ExitIsolationButton } from './components/exit-isolation-button';
import { FourDSidePanel } from './components/four-d-side-panel';
import { GhostingToggle } from './components/ghosting-toggle';
import { NewGanttChart } from './components/new-gantt-chart';
import { Timestamp } from './components/timestamp';
import { AnimationSpeed } from './enums/animation-speed';
import { TimeframeAnimation } from './interfaces/timeframe-animation';
import { utils } from './utils';

interface PageStateProps {
  calculationId: number | null;
  animationData: AnimationDataResponse;
  calculationIdData: number;
}

interface PageDispatchProps {
  getAnimationData(calculationId: number): void;
  saveGanttData(ganttData: GanttData): void;
  setSelectedElementsIds(ids: number[]): void;
  saveResourcesData(data: ResourcesData): void;
  resetState(): void;
  resetActiveTab(): void;
}

interface PageOwnProps {
  match: match<PlanProjectRouteParams>;
}

interface PageProps extends
  PageOwnProps,
  PageStateProps,
  PageDispatchProps { }

interface PageState {
  dataProvider: ChartDataProvider;
  /**
   * @description Current moment in days since project start.
   */
  currentMoment: number;
  nextCurrentMoment: number | null;
  leftPanelWidth: number;
  leftPanelHeight: number;
  ganttChartHeight: number;
  isPlaying: boolean;
  speed: number;
  timeframeStartDay: number;
  timeframeDuration: number;
  projectDuration: number;
  ghostFinished: boolean;
  skipOffTime: boolean;
  timelineStartDay: number;
  timelineDuration: number;
  isolatedLinesStack: LineData[];
  geometry: any;
  pagePanesConfigs: Array<{ size: number, minSize: number }>;
  rightPanesConfigs: Array<{ size: number, minSize: number }>;
}

const animationTargetTreshold = 1 / 24 / 60 / 6; // 10 seconds in days
const minTimelineDurationInDays = 0.001;
const minPagePanesSizes = [300, 680];
const minRightPaneSizes = [200, 300];

class FourDVisualisationPageComponent extends Component<PageProps, PageState> {
  private readonly containerStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
  };

  private engine: KreoEngine = null;
  private isUnmounted: boolean = false;
  private prevFrameTimestamp: number = 0;
  private animationSpeed: AnimationSpeed = AnimationSpeed.Regular;
  private timeframeAnimation: TimeframeAnimation = null;
  private engineInitialHeight: number = 0;

  private deferredStateUpdates: Partial<PageState> = {};

  constructor(props: PageProps) {
    super(props);

    const viewportHeight = window.innerHeight;

    const ganttChartHeight = viewportHeight * 0.4;
    this.engineInitialHeight = viewportHeight - ganttChartHeight;
    this.state = {
      currentMoment: 0,
      nextCurrentMoment: null,
      leftPanelHeight: viewportHeight,
      leftPanelWidth: 400,
      ganttChartHeight,
      isPlaying: false,
      speed: utils.getSpeedValue(0.5),
      timeframeStartDay: 0,
      timeframeDuration: 0,
      projectDuration: 0,
      dataProvider: null,
      ghostFinished: false,
      skipOffTime: false,
      timelineStartDay: 0,
      timelineDuration: 0,
      isolatedLinesStack: [],
      geometry: null,
      pagePanesConfigs: minPagePanesSizes.map(minSize => ({ size: 0, minSize })),
      rightPanesConfigs: [
        { size: this.engineInitialHeight, minSize: minRightPaneSizes[0] },
        { size: ganttChartHeight, minSize: minRightPaneSizes[1] },
      ],
    };

    requestAnimationFrame(this.animationLoop);
    this.loadGeometry();
  }

  public static getDerivedStateFromProps(nextProps: PageProps, prevState: PageState | null): Partial<PageState> {
    if (!nextProps.animationData && nextProps.calculationId !== null) {
      nextProps.getAnimationData(nextProps.calculationId);
      return null;
    } else if (nextProps.animationData && nextProps.calculationId !== nextProps.calculationIdData) {
      nextProps.resetState();
      nextProps.getAnimationData(nextProps.calculationId);
      return {
        dataProvider: null,
      };
    }

    if (!(prevState && prevState.dataProvider) && nextProps.animationData) {
      const dataProvider = new ChartDataProvider(nextProps.animationData);

      const ganttData = animationDataResponseMapper.getGanttData(
        nextProps.animationData,
        dataProvider);

      nextProps.saveGanttData(ganttData);

      const resourcesData: ResourcesData = {};

      const resourceTypes: ResourceType[] = [ResourceType.Labour, ResourceType.Material, ResourceType.Plant];

      for (const type of resourceTypes) {
        resourcesData[type] = new Map<number, ResourceDescription>();
        const resourcesFromProvider = dataProvider.getResourcesDescriptionsOfType(type);

        for (const resource of resourcesFromProvider) {
          resourcesData[type].set(resource.id, resource);
        }
      }

      nextProps.saveResourcesData(resourcesData);

      const projectDuration = dataProvider.getTotalDuration();

      return {
        dataProvider,
        timeframeDuration: projectDuration,
        projectDuration,
        timelineDuration: projectDuration,
      };
    }

    return null;
  }

  public componentWillUnmount(): void {
    this.isUnmounted = true;
    this.props.resetActiveTab();
  }

  public render(): JSX.Element {
    let waitingForData = false;
    if (!this.props.animationData || !this.state.dataProvider || !this.state.geometry) {
      waitingForData = true;
    }

    if (this.state.dataProvider) {
      this.state.dataProvider.setTimeRange(
        this.state.timeframeStartDay,
        this.state.timeframeDuration,
      );
    }

    const isolatedLine = this.state.isolatedLinesStack.length > 0
      ? this.state.isolatedLinesStack[this.state.isolatedLinesStack.length - 1]
      : null;

    return (
      <ProjectLayout
        projectId={this.props.match.params.projectId}
        headless={true}
        waitingForAdditionalInfo={waitingForData}
      >
        <div
          className='four-d-visualisation-page'
          style={this.containerStyle}
          onContextMenu={this.preventDefault}
          ref={this.savePageRef}
        >
          <SplitterLayout
            primaryIndex={1}
            onResize={this.onVerticalLayoutResize}
            onSecondaryPaneSizeChange={this.onLeftPanelResize}
            paneConfigs={this.state.pagePanesConfigs}
          >
            <FourDSidePanel
              width={this.state.leftPanelWidth}
              height={this.state.leftPanelHeight}
              engine={this.engine}
              currentMoment={this.state.currentMoment}
              nextCurrentMoment={this.state.nextCurrentMoment}
              dataProvider={this.state.dataProvider}
              updateCurrentMoment={this.updateCurrentMoment}
              selectElementOnEngine={this.selectElementOnEngine}
              timeframeStartDay={this.state.timeframeStartDay}
              timeframeDuration={this.state.timeframeDuration}
            />
            <SplitterLayout
              horizontal={true}
              onResize={this.onGanttChartResize}
              onSecondaryPaneSizeChange={this.onGanttChartUserResize}
              customClassName='engine-layout'
              paneConfigs={this.state.rightPanesConfigs}
            >
              <React.Fragment>
                <Engine
                  ghostColor={'#FFFFFF'}
                  textures='/static/textures/'
                  sendEngineApi={this.setEngineRef}
                  projectId={parseInt(this.props.match.params.projectId, 10)}
                  onHandleClick={this.onEngineClick}
                  backgroundColor='#343948'
                  animationData={this.props.animationData}
                  dataProvider={this.state.dataProvider}
                  onChangeTime={this.onChangeTime}
                  initialSpeed={this.state.speed}
                  geometry={this.state.geometry}
                />
                <Timestamp
                  currentMoment={this.state.currentMoment}
                  nextCurrentMoment={this.state.nextCurrentMoment}
                />
                <FaqLink caption={FaqCaption.FourD} />
                <GhostingToggle
                  ghostFinished={this.state.ghostFinished}
                  setGhostFinished={this.setGhostFinished}
                />
                <ExitIsolationButton
                  visible={!!isolatedLine}
                  activityGroupType={isolatedLine ? isolatedLine.type : null}
                  exitIsolation={this.exitCurrentIsolation}
                />
              </React.Fragment>
              <NewGanttChart
                currentMoment={this.state.currentMoment}
                nextCurrentMoment={this.state.nextCurrentMoment}
                isPlaying={this.state.isPlaying}
                speed={this.state.speed}
                height={this.state.ganttChartHeight}
                dataProvider={this.state.dataProvider}
                timeframeStartDay={this.state.timeframeStartDay}
                timeframeDuration={this.state.timeframeDuration}
                timelineStartDay={this.state.timelineStartDay}
                timelineDuration={this.state.timelineDuration}
                skipOffTime={this.state.skipOffTime}
                isolatedLine={isolatedLine}
                play={this.play}
                pause={this.pause}
                moveForward={this.moveForward}
                moveBackward={this.moveBackward}
                changeSpeed={this.changeSpeed}
                updateCurrentMoment={this.updateCurrentMoment}
                updateTimeframe={this.updateTimeframe}
                updateNextCurrentMoment={this.updateNextCurrentMoment}
                setSkipOffTime={this.setSkipOffTime}
                setAnimationSpeed={this.setAnimationSpeed}
                toggleIsolation={this.toggleIsolation}
                triggerTimeframeChangeAnimation={this.triggerTimeframeChangeAnimation}
              />
            </SplitterLayout>
          </SplitterLayout>
        </div>
      </ProjectLayout>
    );
  }

  @autobind
  private savePageRef(ref: HTMLDivElement): void {
    if (ref === null) {
      return;
    }
    const rect = ref.getBoundingClientRect();
    const pagePanesConfigs = [
      { size: 400, minSize: minPagePanesSizes[0] },
      { size: rect.width - 400, minSize: minPagePanesSizes[1] },
    ];

    this.setState({ pagePanesConfigs });
  }

  @autobind
  private play(): void {
    this.deferStateUpdate((_) => {
      this.engine.togglePlaying(true);
      return { isPlaying: true };
    });
  }

  @autobind
  private pause(): void {
    this.deferStateUpdate((_) => {
      this.engine.togglePlaying(false);
      return { isPlaying: false };
    });
  }

  @autobind
  private setEngineRef(ref: KreoEngine): void {
    this.engine = ref;
    this.engine.toggleParallelProjection(true);
    this.engine.resize();
  }

  @autobind
  private onChangeTime(currentMoment: number): void {
    this.deferStateUpdate((actualState) => {
      const timelineEndMoment = actualState.timelineStartDay + actualState.timelineDuration;
      const delta = currentMoment - timelineEndMoment;

      if (delta > -0.001) {
        this.pause();
      }

      return { currentMoment };
    });
  }

  @autobind
  private changeSpeed(speed: number): void {
    this.deferStateUpdate((_) => {
      this.engine.setAnimationSpeed(speed);
      return { speed };
    });
  }

  @autobind
  private onVerticalLayoutResize(_width: number, height: number): void {
    this.deferStateUpdate((_) => {
      return { leftPanelHeight: height };
    });
  }

  @autobind
  private onGanttChartResize(_width: number, height: number): void {
    this.deferStateUpdate((_) => {
      return { ganttChartHeight: height };
    });
  }

  @autobind
  private onLeftPanelResize(width: number): void {
    this.deferStateUpdate((_) => {
      return { leftPanelWidth: width };
    });
  }

  @autobind
  private onGanttChartUserResize(height: number): void {
    this.deferStateUpdate((_) => {
      return { ganttChartHeight: height };
    });
  }

  @autobind
  private updateCurrentMoment(currentMoment: number, force: boolean = false): void {
    this.deferStateUpdate((actualState) => {
      const safeCurrentMoment = force
        ? currentMoment
        : mathUtils.clamp(
          currentMoment,
          actualState.timeframeStartDay,
          actualState.timeframeStartDay + actualState.timeframeDuration);
      this.engine.setTimelineValue(safeCurrentMoment);

      return { currentMoment: safeCurrentMoment };
    });
  }

  @autobind
  private updateNextCurrentMoment(nextCurrentMoment: number | null): void {
    this.deferStateUpdate((actualState) => {
      let safeNextCurrentMoment = nextCurrentMoment;
      if (nextCurrentMoment !== null) {
        safeNextCurrentMoment = mathUtils.clamp(
          nextCurrentMoment,
          actualState.timeframeStartDay,
          actualState.timeframeStartDay + actualState.timeframeDuration);
      }

      return { nextCurrentMoment: safeNextCurrentMoment };
    });
  }

  @autobind
  private updateTimeframe(startDay: number, duration: number): void {
    this.deferStateUpdate((actualState) => {
      const safeDuration = mathUtils.clamp(duration, minTimelineDurationInDays, actualState.timelineDuration);
      const safeStartDay = mathUtils.clamp(
        startDay,
        actualState.timelineStartDay,
        actualState.timelineStartDay + (actualState.timelineDuration - safeDuration));

      if (
        Math.abs(safeStartDay - actualState.timeframeStartDay) > 0.0001 ||
        Math.abs(safeDuration - actualState.timeframeDuration) > 0.0001
      ) {
        return {
          timeframeStartDay: safeStartDay,
          timeframeDuration: safeDuration,
        };
      } else {
        return null;
      }
    });
  }

  @autobind
  private moveForward(): void {
    this.moveTimeframe(1);
  }

  @autobind
  private moveBackward(): void {
    this.moveTimeframe(-1);
  }

  private moveTimeframe(delta: number): void {
    const actualState = this.getActualState();
    const newCurrentMoment = this.timeframeAnimation && this.timeframeAnimation.target
      ? this.timeframeAnimation.target.currentMoment + delta
      : actualState.currentMoment + delta;

    this.triggerTimeframeChangeAnimation({
      currentMoment: newCurrentMoment,
      timeframeDuration: actualState.timeframeDuration,
    });
  }

  @autobind
  private setGhostFinished(ghostFinished: boolean): void {
    this.deferStateUpdate((_) => {
      const engineMode = ghostFinished ? 'ghost' : 'animated';
      this.engine.setPostWorksRenderMode(engineMode, 0);

      return { ghostFinished };
    });
  }

  @autobind
  private setSkipOffTime(skipOffTime: boolean): void {
    this.deferStateUpdate((_) => {
      this.engine.toggleSkippingOffTime(skipOffTime);

      return { skipOffTime };
    });
  }

  @autobind
  private onEngineClick(elementsIds: number[]): void {
    this.props.setSelectedElementsIds(elementsIds);
  }

  @autobind
  private selectElementOnEngine(bimId: number): void {
    const idsArray = [bimId];
    this.engine.setSelected(idsArray);
    this.engine.focusCamera(idsArray);
  }

  private getActualState(): PageState {
    return { ...this.state, ...this.deferredStateUpdates };
  }

  private deferStateUpdate(
    updateFunc: (actualState: PageState) => Partial<PageState> | null,
    callback?: () => void,
  ): void {
    const newUpdates = updateFunc(this.getActualState());
    if (newUpdates !== null) {
      this.deferredStateUpdates = {
        ...this.deferredStateUpdates,
        ...newUpdates,
      };
    }

    if (callback) {
      callback();
    }
  }

  private commitDeferredStateUpdates(): void {
    if (Object.keys(this.deferredStateUpdates).length > 0) {
      this.setState({ ...this.deferredStateUpdates } as any);
      this.deferredStateUpdates = {};
    }
  }

  @autobind
  private animationLoop(time: number): void {
    if (this.isUnmounted) {
      return;
    }

    requestAnimationFrame(this.animationLoop);

    const delta = time - this.prevFrameTimestamp;

    this.prevFrameTimestamp = time;

    const state = this.getActualState();

    let k = delta * 0.01;
    if (this.animationSpeed === AnimationSpeed.Instant || state.isPlaying) {
      k = 1;
    } else if (this.animationSpeed === AnimationSpeed.None) {
      k = 0;
    }

    if (this.timeframeAnimation) {
      const desiredTarget = this.timeframeAnimation.target;

      const target = {
        currentMoment: mathUtils.clamp(
          desiredTarget.currentMoment,
          state.timelineStartDay,
          state.timelineStartDay + state.timelineDuration),
        timeframeDuration: mathUtils.clamp(desiredTarget.timeframeDuration, 1, state.timelineDuration),
      };

      const newNextCurrentMoment = mathUtils.lerp(state.nextCurrentMoment, target.currentMoment, k);
      const newDuration = mathUtils.lerp(state.timeframeDuration, target.timeframeDuration, k);
      const newStartDay = newNextCurrentMoment - newDuration / 2;

      if (
        Math.abs(state.timeframeDuration - newDuration) > 0.005 ||
        Math.abs(state.nextCurrentMoment - newNextCurrentMoment) > 0.005
      ) {
        this.updateNextCurrentMoment(newNextCurrentMoment);
        this.updateTimeframe(newStartDay, newDuration);
      } else {
        this.updateNextCurrentMoment(target.currentMoment);
        this.updateTimeframe(target.currentMoment - target.timeframeDuration / 2, target.timeframeDuration);
        this.finishTimeframeAnimation();
      }
    } else {
      const currentMoment = state.nextCurrentMoment !== null
        ? state.nextCurrentMoment
        : state.currentMoment;

      const targetStartDay = currentMoment - state.timeframeDuration / 2;
      const newStartDay = mathUtils.lerp(state.timeframeStartDay, targetStartDay, k);

      if (Math.abs(state.timeframeStartDay - newStartDay) > animationTargetTreshold) {
        this.updateTimeframe(newStartDay, state.timeframeDuration);
      }
    }

    this.commitDeferredStateUpdates();
  }

  @autobind
  private setAnimationSpeed(animationSpeed: AnimationSpeed): void {
    this.animationSpeed = animationSpeed;
  }

  @autobind
  private toggleIsolation(line: LineData | null): void {
    this.deferStateUpdate(
      (actualState) => {
        const stack = actualState.isolatedLinesStack;
        const newStack = line !== null
          ? [...stack, line]
          : stack.length > 0 ? stack.slice(0, stack.length - 1) : stack;

        return {
          isolatedLinesStack: newStack,
        };
      },
      this.visualizeIsolation);
  }

  @autobind
  private exitCurrentIsolation(): void {
    this.toggleIsolation(null);
  }

  @autobind
  private visualizeIsolation(): void {
    this.deferStateUpdate((actualState) => {
      if (actualState.isolatedLinesStack.length > 0) {
        const line = actualState.isolatedLinesStack[actualState.isolatedLinesStack.length - 1];

        let isolatedWorks: number[] = [];
        if (line.type === GroupType.ActivityGroup) {
          isolatedWorks = line.children.map((activity) => activity.entityId);
        } else {
          isolatedWorks = [];
          for (const activityGroup of line.children) {
            isolatedWorks.push(...activityGroup.children.map((activity) => activity.entityId));
          }
        }

        this.engine.setActiveWorks(isolatedWorks);
        actualState.dataProvider.setActiveGroup(line.type, line.entityId);
        const timelineBounds = actualState.dataProvider.getActiveTimeframe();
        const newCurrentMoment =
          mathUtils.clamp(actualState.currentMoment, timelineBounds.startDay, timelineBounds.endDay);
        this.engine.setTimelineValue(newCurrentMoment);
        this.engine.setTimelineBounds(timelineBounds.startDay, timelineBounds.endDay);

        const safeTimelineDuration = Math.max(timelineBounds.endDay - timelineBounds.startDay, 1);

        return {
          timeframeStartDay: timelineBounds.startDay,
          timeframeDuration: safeTimelineDuration,
          timelineStartDay: timelineBounds.startDay,
          timelineDuration: safeTimelineDuration,
          currentMoment: newCurrentMoment,
        };
      } else {
        this.engine.setActiveWorks();
        actualState.dataProvider.makeAllActive();
        this.engine.setTimelineBounds(0, actualState.projectDuration);
        return {
          timelineStartDay: 0,
          timelineDuration: actualState.projectDuration,
          timeframeStartDay: 0,
          timeframeDuration: actualState.projectDuration,
        };
      }
    });
  }

  @autobind
  private triggerTimeframeChangeAnimation(target: TimeframeAnimationTarget): void {
    const actualState = this.getActualState();
    const wasAnimating = this.timeframeAnimation && this.timeframeAnimation.target;
    const wasPlaying = wasAnimating ? this.timeframeAnimation.wasPlaying : actualState.isPlaying;

    this.timeframeAnimation = {
      wasPlaying,
      target,
    };

    if (wasPlaying) {
      this.pause();
    }

    if (!wasAnimating) {
      this.updateNextCurrentMoment(actualState.currentMoment);
    }
  }

  private preventDefault(event: React.MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
    event.nativeEvent.preventDefault();
  }

  private finishTimeframeAnimation(): void {
    const actualState = this.getActualState();
    this.updateCurrentMoment(actualState.nextCurrentMoment);
    this.updateNextCurrentMoment(null);
    if (this.timeframeAnimation.wasPlaying) {
      this.play();
    }

    this.timeframeAnimation = null;
  }

  private loadGeometry(): void {
    ViewerApi.getBimGeometry().then((geometry: any) => {
      this.deferStateUpdate((_) => {
        return {
          geometry,
        };
      });
    });
  }
}

function mapStateToProps(state: State): PageStateProps {
  return {
    calculationId: state.scenarios.active_calculation ? state.scenarios.active_calculation.id : null,
    calculationIdData: state.fourDVisualisation.calculationId,
    animationData: state.fourDVisualisation.animationData,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PageDispatchProps {
  return {
    getAnimationData: (calculationId: number): void => {
      dispatch(GanttChartActions.getAnimationData(calculationId));
    },
    saveGanttData: (ganttData: GanttData): void => {
      dispatch(GanttChartActions.saveGanttData(ganttData));
    },
    setSelectedElementsIds: (ids: number[]): void => {
      dispatch(actions.setSelectedElementsIds(ids));
    },
    saveResourcesData: (data: ResourcesData): void => {
      dispatch(GanttChartActions.saveResourcesData(data));
    },
    resetState: (): void => {
      dispatch(GanttChartActions.resetState());
    },
    resetActiveTab: (): void => {
      dispatch(GanttChartActions.resetActiveTab());
    },
  };
}

export const FourDVisualisationPage = connect(mapStateToProps, mapDispatchToProps)(FourDVisualisationPageComponent);
