import { ModifyHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { DrawingsEventsController } from '../../interfaces';

interface DrawingsContinueKeyListenerHelperConfig {
  api: GlobalKeyboardEventsControllerContextProps;
  cancel: () => void;
  apply: () => void;
}

export class BooleanOperationsKeyListeners implements DrawingsEventsController {
  private _config: DrawingsContinueKeyListenerHelperConfig;

  constructor(config: DrawingsContinueKeyListenerHelperConfig) {
    this._config = config;
  }

  public activate(): void {
    const { api } = this._config;
    api.addKeyDownEventListener(ModifyHotkey.Escape, this._config.cancel);
    api.addKeyDownEventListener(ModifyHotkey.FinishDraw, this._config.apply);
  }

  public deactivate(): void {
    const { api } = this._config;
    api.removeKeyDownEventListener(ModifyHotkey.Escape, this._config.cancel);
    api.removeKeyDownEventListener(ModifyHotkey.FinishDraw, this._config.apply);
  }
}
