import * as React from 'react';

export const KreoIconUpgradeStar: React.FC = (_props) => {
  return (
    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='icon_upgrade-plan' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect id='14x14' x='0' y='0' width='14' height='14'/>
        <path
          d='M6.48812476,10.3054405 C6.80858119,10.1369666 7.19141881,10.1369666 7.51187524,10.3054405
          L9.91889079,11.5708834 C9.93835684,11.5811173 9.96065365,11.5846488 9.98232941,11.5809311
          C10.0367631,11.571595 10.0733218,11.5198994 10.0639857,11.4654658 L9.60428667,8.78521457
          C9.54308494,8.4283811 9.66138827,8.06428088 9.92064297,7.81157001 L11.8679595,5.91340557
          C11.8837078,5.8980547 11.8939565,5.87794046 11.897119,5.85617676 C11.9050608,5.80152227
          11.8671926,5.75077799 11.8125381,5.74283623 L9.12141292,5.35179285 C8.76313174,5.29973154
          8.4534096,5.07470523 8.29318138,4.75004741 L7.08967361,2.31147431 C7.07994058,2.29175305
          7.06397783,2.27579029 7.04425657,2.26605727 C6.99473121,2.24161504 6.93476862,2.26194894
          6.91032639,2.31147431 L5.70681862,4.75004741 C5.5465904,5.07470523 5.23686826,5.29973154
          4.87858708,5.35179285 L2.18746188,5.74283623 C2.16569818,5.74599868 2.14558394,5.7562474
          2.13023308,5.77199576 C2.09168296,5.81154412 2.09249219,5.87485544 2.13204054,5.91340557
          L4.07935703,7.81157001 C4.33861173,8.06428088 4.45691506,8.4283811 4.39571333,8.78521457
          L3.93601426,11.4654658 C3.93229658,11.4871415 3.93582805,11.5094384 3.94606195,11.5289044
          C3.97176203,11.5777889 4.03222475,11.5965835 4.08110921,11.5708834 L6.48812476,10.3054405
          Z M6.95346589,11.1905719 L4.54645034,12.4560149 C4.00872129,12.7387157 3.34363142,12.5319746
          3.06093053,11.9942455 C2.94835755,11.780119 2.90951141,11.5348541 2.95040594,11.2964207
          L3.410105,8.61616952 C3.4156688,8.58373011 3.40491395,8.55063009 3.38134534,8.52765637
          L1.43402885,6.62949193 C0.998996917,6.2054406 0.99009538,5.509016 1.41414671,5.07398407
          C1.58300618,4.90075207 1.80426283,4.78801618 2.04366356,4.75322921 L4.73478876,4.36218583
          C4.76735978,4.35745299 4.79551634,4.33699605 4.81008254,4.3074817 L6.01359032,1.86890859
          C6.28245484,1.32412957 6.94204326,1.10045667 7.48682228,1.36932119 C7.70375615,1.47638446
          7.87934642,1.65197473 7.98640968,1.86890859 L9.18991746,4.3074817 C9.20448366,4.33699605
          9.23264022,4.35745299 9.26521124,4.36218583 L11.9563364,4.75322921 C12.5575358,4.84058859
          12.9740854,5.39877573 12.886726,5.99997508 C12.851939,6.23937581 12.7392031,6.46063246
          12.5659711,6.62949193 L10.6186547,8.52765637 C10.5950861,8.55063009 10.5843312,8.58373011
          10.589895,8.61616952 L11.0495941,11.2964207 C11.1522912,11.8951908 10.7501446,12.4638423
          10.1513745,12.5665394 C9.91294108,12.607434 9.66767619,12.5685878 9.45354966,12.4560149
          L7.04653411,11.1905719 C7.01740171,11.1752561 6.98259829,11.1752561 6.95346589,11.1905719 Z'
          id='Star'
          fill='#5C8AE6'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
