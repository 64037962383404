import * as t from 'io-ts';

import { SubscriptionTypeC } from 'common/constants/subscription';
import { CompanySubscriptionModelC } from './company-subscription-model';
import { CurrencyModelC } from './currency-model';

export const CompanyC = t.intersection(
  [
    t.type({
      id: t.number,
      email: t.string,
      name: t.string,
      currency: CurrencyModelC,
      hasLogo: t.boolean,
    }),
    t.partial({
      subscriptions: t.record(SubscriptionTypeC, CompanySubscriptionModelC),
    }),
  ],
  'Company');

export const CompaniesC = t.array(CompanyC);

export type Company = t.TypeOf<typeof CompanyC>;

export interface UpdateCompany {
  name?: string;
  logoTemporaryKey?: string;
  resetLogo?: boolean;
}
