import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { WrappedFieldArrayProps } from 'redux-form';

import { DrawingsUtils } from 'common/components/drawings/utils/drawings-utils';
import { LinkComponent } from 'common/components/link-component';
import { RenderIf } from 'common/components/render-if';
import { Mood } from 'common/enums/mood';
import { UploadingFile } from 'common/interfaces/common-state';
import { useOpenCloseDialog } from 'common/UIKit';
import { Common } from '../../../../actions';
import { FILE_FORMAT_SUPPORTED_DIALOG } from '../file-format-supported-dialog';
import { FileLine } from './file-line';
import { FileProgress } from './file-progress/file-progress';
import { Styled } from './styled';

export interface InnerComponentProps {
  files: UploadingFile[];
}

export interface FieldInnerComponentProps extends WrappedFieldArrayProps<any>, InnerComponentProps {}

interface Props {
  fieldProps: FieldInnerComponentProps;
  accept: string | string[];
  disabled?: boolean;
  name: string;
  form: string;
  title: React.ReactNode;
  onUploadStarted?: (files: File[]) => void;
  onDeleteFile?: (file: UploadingFile) => void;
}

const FilesDropzoneComponent: React.FC<Props> = ({
  fieldProps: {
    fields,
    meta: { error, submitFailed },
    files,
  },
  accept,
  disabled,
  name,
  form,
  onUploadStarted,
  title,
  onDeleteFile,
}) => {
  const [open] = useOpenCloseDialog(FILE_FORMAT_SUPPORTED_DIALOG);

  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) {
        open();
        return;
      }
      const filesForUpload = [];
      for (const file of acceptedFiles) {
        if (!files.find((x) => x.key === DrawingsUtils.getFileKey(file))) {
          filesForUpload.push(file);
        }
      }

      dispatch(Common.uploadFiles(filesForUpload, form, name));
      if (onUploadStarted) {
        onUploadStarted(filesForUpload);
      }
    },
    [form, onUploadStarted, name, files, open, dispatch],
  );

  const { getRootProps, getInputProps, rootRef, isDragActive } = useDropzone({
    multiple: true,
    accept,
    disabled,
    onDrop,
  });

  const commonProgress = useMemo(() => {
    const progress = files.reduce((acc, file) => acc + file.progress, 0);
    return files.length > 0 ? progress / files.length : 0;
  }, [files]);

  const onDeleteFileCallback = useCallback((file: UploadingFile, index: number) => {
    fields.remove(index);
    if (onDeleteFile) {
      onDeleteFile(file);
    }
  }, [fields, onDeleteFile]);

  const hasErrorInFiles = useMemo(() => {
    return files.some((file) => file.error);
  }, [files]);

  const isValid = !hasErrorInFiles && !error && !submitFailed;

  const commonUploadTitle = useMemo(() => {
    if (!isValid) {
      return 'Something went wrong';
    }
    if (commonProgress < 1) {
      return `Uploading ${files.length} file${files.length > 1 ? 's' : ''}`;
    } else {
      return 'Upload successful';
    }
  }, [files, isValid, commonProgress]);

  const addFile = useCallback(() => {
    rootRef.current?.click();
  }, [rootRef]);

  return (
    <Styled.Container>
      <RenderIf condition={!!files.length}>
        <Styled.ProgressLine>
          <FileProgress title={commonUploadTitle} progress={commonProgress} isValid={isValid} />
          <LinkComponent
            text='Add'
            icon={Icons.PlusBig}
            onClick={addFile}
            mood={Mood.Secondary}
            size="m"
          />
        </Styled.ProgressLine>
      </RenderIf>
      <Styled.Dropzone {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} />
        {files.length === 0 ? (
          <Styled.Label>{title}</Styled.Label>
        ) : (
          <>
            {files.map((file, index) => {
              return (
                <FileLine
                  key={index}
                  file={file}
                  index={index}
                  remove={onDeleteFileCallback}
                />
              );
            })}
          </>
        )}
      </Styled.Dropzone>
    </Styled.Container>
  );
};

export const FilesDropzone = React.memo(FilesDropzoneComponent);
