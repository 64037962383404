import { objectUtils } from 'common/utils/object-utils';
import { ProjectResourcesApi } from '../../../api/server';
import { CostEstimateGroupingType } from '../enums/cost-estimate-grouping-type';
import {
  CostsTreeNodeVm,
  CostsTreeNodeVmC,
} from '../interfaces/bid-pricing/costs-tree-node-vm';

function getCostEstimateRoots(calculationId: number, type?: CostEstimateGroupingType): Promise<any> {
  const query = type
    ? `?${objectUtils.toQueryString({ type })}`
    : '';

  return ProjectResourcesApi.get(`/calculations/${calculationId}/boq-roots${query}`,
  );
}

function getPackageInfo(packageId: number): Promise<CostsTreeNodeVm> {
  return ProjectResourcesApi.getV(
    `/bid-pricing/packages/${packageId}/info`,
    CostsTreeNodeVmC,
    'get packages info error',
  );
}

function getExcelReportTypes(calculationId: number): Promise<any> {
  return ProjectResourcesApi.get(
    `/calculations/${calculationId}/available-reports`,
    'error in get excel report types',
  );
}

function exportExcelReport(
  calculationId: number,
  selectedReportTypes: string[],
): Promise<any> {
  const query = selectedReportTypes.length > 0
    ? `?${objectUtils.toQueryString({ type: selectedReportTypes })}`
    : '';

  return ProjectResourcesApi.get(
    `/calculations/${calculationId}/export${query}`,
    'error in get excel report types');
}

export const CostsApi = {
  getCostEstimateRoots,
  getPackageInfo,
  getExcelReportTypes,
  exportExcelReport,
};
