import { DatadogLogger } from 'common/environment/datadog-logger';

export class SignalRLogger {
  public static canLog = false;

  public static getInstance(): { log: (level: SignalR.LogLevel, message: string) => void } {
    return {
      log: (...args) => SignalRLogger.log(...args),
    };
  }

  private static log(level: SignalR.LogLevel, message: string): void {
    if (this.canLog) {
      switch (level) {
        case signalR.LogLevel.Error:
        case signalR.LogLevel.Critical:
          if (message.includes('WebSocket closed with status code: 1006')) {
            console.warn(message);
          } else {
            DatadogLogger.error(message);
            console.error(message);
          }
          break;
        case signalR.LogLevel.Warning:
          DatadogLogger.warn(message);
          console.warn(message);
          break;
        default:
          break;
      }
    }
  }
}

