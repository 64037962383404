import { Icons, LinkComponent } from '@kreo/kreo-ui-components';
import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import React, { useCallback } from 'react';

import { BreakdownProperty } from 'unit-2d-database/components/breakdown-property';
import { PropertyFormData } from '../../interfaces';
import { PropertyNameInput, GroupsDropdown } from '../fields';
import { validatePropertyNames } from '../helper';
import { Styled } from '../styled';
import {
  useAddedOptionCallback,
  useDragEndCallback,
  useExtendContextMenuItemCallback,
  useOptionState,
  useRemoveOverideCallback,
  useUpdateOption,
} from './hooks';
import { PiaBreakdownNode } from './interfaces';

interface Props {
  property: PropertyFormData;
  groups: string[];
  onChange: (data: PropertyFormData, isValid: boolean) => void;
}

export const TreeForm: React.FC<Props> = ({ property, groups, onChange }: Props) => {
  const triggerChange = useCallback(
    (updates: Record<string, string | PiaBreakdownNode[]>) => {
      const updatedProperty = { ...property, ...updates };
      const isValid = validatePropertyNames(updatedProperty);
      onChange(updatedProperty, isValid);
    },
    [onChange, property],
  );
  const [options, updateOptions] = useOptionState(property.root, triggerChange);
  const addOption = useAddedOptionCallback(updateOptions);
  const removeCodes = useRemoveOverideCallback(updateOptions);
  const updateOption = useUpdateOption(updateOptions);
  const handleDragEnd = useDragEndCallback(updateOptions);
  const extendContextMenuItem = useExtendContextMenuItemCallback(updateOptions);

  return (
    <>
      <PropertyNameInput value={property.name} onChange={triggerChange} />
      <GroupsDropdown groups={groups} value={property.groupName} onChange={triggerChange} />
      <Styled.ButtonWrapper>
        <LinkComponent text={'Create section'} onClick={addOption} Icon={Icons.PlusBig} />
        <LinkComponent text={'Reset override'} onClick={removeCodes} Icon={Icons.Delete} />
      </Styled.ButtonWrapper>
      <Styled.TreePanelContainer>
        <BreakdownProperty
          options={options}
          addOption={addOption}
          updateOption={updateOption}
          handleDragEnd={handleDragEnd}
          extendContextMenuItem={extendContextMenuItem}
          onRowSelected={ConstantFunctions.doNothing}
          editable={true}
          enableMultiSelect={true}
          suppressRowClickSelection={true}
        />
      </Styled.TreePanelContainer>
    </>
  );
};
