import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  ${Text} {
    font-size: 1.75;
  }
`;

export const Styled = {
  Container,
};
