import * as t from 'io-ts';

export const ExtractorFunctionC = t.type(
  {
    id: t.number,
    name: t.string,
    unit: t.string,
  },
  'ExtractorFunction');

export type ExtractorFunction = t.TypeOf<typeof ExtractorFunctionC>;
