import { useContext } from 'react';
import {
  DrawingsGeometryOperationsApiContext,
  DrawingsGeometryOperationsContext,
  DrawingsGeometryOperationsFullContext,
} from './geometry-operations-context';

export function useGeometryOperationContext(): DrawingsGeometryOperationsFullContext {
  const api = useContext(DrawingsGeometryOperationsApiContext);
  const state = useContext(DrawingsGeometryOperationsContext);
  return { ...state, ...api };
}

