import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { AIRequestStatus, Message as MessageType } from 'unit-2d-copilot/interfaces';
import { TwoDCopilotActions } from 'unit-2d-copilot/store-slice';
import { ChatHistory } from './chat-history';
import { DialogContainer } from './dialog-container';
import { Header } from './header';
import { InputPanel } from './input-panel/input-panel';
import { NoMessages } from './no-messages/no-messages';
import { StatusButton } from './status-button';

interface StateProps {
  isOpened: boolean;
  history: MessageType[];
  tempMessage: string;
  status: AIRequestStatus;
}

interface DispatchProps {
  onPause: () => void;
  tryAgain: () => void;
}

interface OwnProps {
  getPdfText: () => Promise<string>;
}

interface Props extends StateProps, OwnProps, DispatchProps {}

const TwoDCopilotPopupComponent: React.FC<Props> = ({
  isOpened,
  getPdfText,
  history,
  tempMessage,
  onPause,
  status,
  tryAgain,
}) => {
  const canShowHistory = useMemo(() => tempMessage || history.some(x => !x.hideInHistory), [history, tempMessage]);
  if (!isOpened) {
    return null;
  }

  return (
    <DialogContainer>
      <Header canReset={!!history.length} />
      {canShowHistory? (
        <ChatHistory
          history={history}
          status={status}
          tempMessage={tempMessage}
        />
      ) : (
        <NoMessages getPdfText={getPdfText} />
      )}
      <StatusButton
        status={status}
        onPause={onPause}
        tryAgain={tryAgain}
      />
      <InputPanel status={status} getPdfText={getPdfText} />
    </DialogContainer>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    isOpened: state.twoDCopilot.isPopupOpened,
    history: state.twoDCopilot.history,
    tempMessage: state.twoDCopilot.tempAnswerText,
    status: state.twoDCopilot.currentRequestStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onPause: () => dispatch(TwoDCopilotActions.stop()),
    tryAgain: () => dispatch(TwoDCopilotActions.tryAgain()),
  };
}

export const TwoDCopilotPopup = connect(mapStateToProps, mapDispatchToProps)(TwoDCopilotPopupComponent);
