import React from 'react';

import { ConfirmationDialog } from '../../../../../../components/dialog/confirmation-dialog';
import { DrawingDialogs } from '../../../constants/drawing-dialogs';
import { AutoMeasure } from './auto-measure';


export const AutoMeasureControl: React.FC = () => {
  return (
    <>
      <AutoMeasure />
      <ConfirmationDialog
        name={DrawingDialogs.AUTO_MEASURE_INSTRUCTION_DIALOG}
        title={`The Auto Measure feature is not available.`}
        text={`To use the feature, add the Auto Measure add-on to your account first.`}
        cancelButtonText='Okay'
      />
      <ConfirmationDialog
        name={DrawingDialogs.BUY_AUTO_MEASURE_DIALOG}
        title='The Auto Measure Trial period has expired.'
        text='Contact your company owner to add the Auto Measure add-on, please'
        cancelButtonText='Okay'
      />
    </>
  );
};
