import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { StringDictionary } from 'common/interfaces/dictionary';
import { ActivityCategoryType } from '../../enums';
import {
  ActivityCategory,
  ActivityModel,
  ActivityVariantModel,
} from '../../interfaces/data';
import {
  OpenEditActivityVariantPayload, SelectActivityVariantPayload,
} from '../payloads/database-activity-listing';
import { DatabaseActivityListingActionTypes } from '../types/database-activity-listing';


function setSelectedCategory(
  model: ActivityCategory,
): ActionWith<ActivityCategory> {
  return {
    type: DatabaseActivityListingActionTypes.SET_SELECTED_CATEGORY,
    payload: model,
  };
}

function setSelectedCategoryType(
  type: ActivityCategoryType,
): ActionWith<ActivityCategoryType> {
  return {
    type: DatabaseActivityListingActionTypes.SET_SELECTED_CATEGORY_TYPE,
    payload: type,
  };
}

function openEditActivity(
  activityId: number,
): ActionWith<number> {
  return {
    type: DatabaseActivityListingActionTypes.OPEN_EDIT_ACTIVITY,
    payload: activityId,
  };
}

function openEditActivityVariant(
  activityId: number,
  activityVariantId: number,
): ActionWith<OpenEditActivityVariantPayload> {
  return {
    type: DatabaseActivityListingActionTypes.OPEN_EDIT_ACTIVITY_VARIANT,
    payload: { activityId, activityVariantId },
  };
}

function openCreateActivity(): Action {
  return {
    type: DatabaseActivityListingActionTypes.OPEN_CREATE_ACTIVITY,
  };
}

function openCreateActivityVariant(activityId: number): ActionWith<number> {
  return {
    type: DatabaseActivityListingActionTypes.OPEN_CREATE_ACTIVITY_VARIANT,
    payload: activityId,
  };
}

function closeEditPanel(): Action {
  return {
    type: DatabaseActivityListingActionTypes.CLOSE_EDIT_PANEL,
  };
}

function resetRequestStatuses(): Action {
  return {
    type: DatabaseActivityListingActionTypes.RESET_REQUEST_STATUSES,
  };
}

function resetLoadActivitiesRequestStatus(): Action {
  return {
    type: DatabaseActivityListingActionTypes.RESET_LOAD_ACTIVITY_REQUEST_STATUS,
  };
}

function setEditActivityModel(activity: ActivityModel): ActionWith<ActivityModel> {
  return {
    type: DatabaseActivityListingActionTypes.SET_EDIT_ACTIVITY_MODEL,
    payload: activity,
  };
}

function setEditActivityVariantModel(activity: ActivityVariantModel): ActionWith<ActivityVariantModel> {
  return {
    type: DatabaseActivityListingActionTypes.SET_EDIT_ACTIVITY_VARIANT_MODEL,
    payload: activity,
  };
}


function selectActivityVariant(
  activityId: number,
  variantId: number,
  value: boolean,
): ActionWith<SelectActivityVariantPayload> {
  return {
    type: DatabaseActivityListingActionTypes.SELECT_ACTIVITY_VARIANT,
    payload: { value, activityId, variantId },
  };
}

function setDisabledActivityVariantIds(
  ids: number[],
): ActionWith<number[]> {
  return {
    type: DatabaseActivityListingActionTypes.SET_DISABLED_ACTIVITY_VARIANT_IDS,
    payload: ids,
  };
}

function setSelectedActivityVariantIds(
  ids: number[],
): ActionWith<number[]> {
  return {
    type: DatabaseActivityListingActionTypes.SET_SELECTED_ACTIVITY_VARIANT_IDS,
    payload: ids,
  };
}

function setDefaultCategories(
  categories: StringDictionary<ActivityCategory>,
): ActionWith<StringDictionary<ActivityCategory>> {
  return {
    type: DatabaseActivityListingActionTypes.SET_DEFAULT_CATEGORIES,
    payload: categories,
  };
}

function setLastSelectedExtractorFunction(
  extractorFunction: string,
): ActionWith<string> {
  return {
    type: DatabaseActivityListingActionTypes.SET_LAST_SELECTED_EXTRACTOR_FUNCTION,
    payload: extractorFunction,
  };
}

function setLastSelectedUnit(
  unitId: number,
): ActionWith<number> {
  return {
    type: DatabaseActivityListingActionTypes.SET_LAST_SELECTED_UNIT,
    payload: unitId,
  };
}

export const DatabaseActivityListingActions = {
  setSelectedCategory,
  setSelectedCategoryType,
  openEditActivity,
  openEditActivityVariant,
  openCreateActivity,
  openCreateActivityVariant,
  closeEditPanel,

  resetRequestStatuses,
  resetLoadActivitiesRequestStatus,
  setEditActivityModel,
  setEditActivityVariantModel,

  selectActivityVariant,
  setDisabledActivityVariantIds,
  setSelectedActivityVariantIds,

  setDefaultCategories,
  setLastSelectedExtractorFunction,
  setLastSelectedUnit,
};
