type KeyType = string | number;
type IndexAccessor<T> = (item: T) => KeyType;

export class KeyIndexArrayBuilder<T, R> {
  private keyIndexAccessors: Array<IndexAccessor<T>>;
  private mapper: (t: T) => R;

  constructor(mapper: (t: T) => R, ...indexAccessors: Array<IndexAccessor<T>>) {
    this.keyIndexAccessors = indexAccessors;
    this.mapper = mapper;
  }

  public buildKeys(array: T[]): {} {
    const result = {};
    const lastKeyAccessor = this.keyIndexAccessors[this.keyIndexAccessors.length - 1];
    for (const item of array) {
      let keyIndexes = result;
      for (let i = 0; i < this.keyIndexAccessors.length - 1; i++) {
        const key = this.keyIndexAccessors[i](item);
        if (!keyIndexes[key]) {
          keyIndexes[key] = {};
        }
        keyIndexes = keyIndexes[key];
      }
      keyIndexes[lastKeyAccessor(item)] = this.mapper(item);
    }

    return result;
  }
}
