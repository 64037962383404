import * as React from 'react';

export const IconSequence: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M10.0630087,11.5 L8.5,11.5 C7.11928813,11.5 6,10.3807119 6,9 L6,7 C6,6.3175879 5.54430112,
          5.74160997 4.92060815,5.55977103 C4.67849346,6.39189494 3.910262,7 3,7 C1.8954305,7 1,
          6.1045695 1,5 C1,3.8954305 1.8954305,3 3,3 C3.93191971,3 4.71496986,3.63738639 4.93699126,
          4.5 L10.0630087,4.5 C10.2850301,3.63738639 11.0680803,3 12,3 C13.1045695,3 14,3.8954305 14,
          5 C14,6.1045695 13.1045695,7 12,7 C11.0680803,7 10.2850301,6.36261361 10.0630087,5.5 L6.50018309,
          5.5 C6.81402759,5.91783027 7,6.43719704 7,7 L7,9 C7,9.82842712 7.67157288,10.5 8.5,10.5 L10.0630087,
          10.5 C10.2850301,9.63738639 11.0680803,9 12,9 C13.1045695,9 14,9.8954305 14,11 C14,12.1045695 13.1045695,
          13 12,13 C11.0680803,13 10.2850301,12.3626136 10.0630087,11.5 Z M12,12 C12.5522847,12 13,11.5522847 13,
          11 C13,10.4477153 12.5522847,10 12,10 C11.4477153,10 11,10.4477153 11,11 C11,11.5522847 11.4477153,
          12 12,12 Z M12,4 C11.4477153,4 11,4.44771525 11,5 C11,5.55228475 11.4477153,6 12,6 C12.5522847,
          6 13,5.55228475 13,5 C13,4.44771525 12.5522847,4 12,4 Z M3,4 C2.44771525,4 2,4.44771525 2,5 C2,
          5.55228475 2.44771525,6 3,6 C3.55228475,6 4,5.55228475 4,5 C4,4.44771525 3.55228475,4 3,4 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
