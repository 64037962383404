import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { ScenariosActionTypes } from '../actions';
import {
  ScenariosActionsType,
  ScenariosData,
  ScenariosReduxState,
  TextFormatting,
  WorkPackagesData,
} from '../interfaces';


export const ScenariosReducerMethods: ReducerMethods<ScenariosReduxState> = {
  [ScenariosActionTypes.SET_ACTIVE_CALCULATION]: (state, payload) => {
    return new MonoliteHelper(state)
      .set((_) => _.active_calculation, payload)
      .get();
  },
  [ScenariosActionTypes.GET_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.scenarios, RequestStatus.Loading)
      .set((_) => _.scenariosToCopy, [])
      .set((_) => _.statuses.calculations, [])
      .get();
  },
  [ScenariosActionTypes.GET_SUCCEEDED]: (state, payload: ScenariosData.Scenario[]) => {
    const activeScenario = payload.find((s) => s.isActivated);

    return new MonoliteHelper(state)
      .set((_) => _.active_scenario, activeScenario)
      .set((_) => _.active_calculation, activeScenario ? activeScenario.calculation : null)
      .set((_) => _.statuses.scenarios, RequestStatus.Loaded)
      .set((_) => _.scenarios, payload)
      .get();
  },
  [ScenariosActionTypes.GET_CALCULATIONS_REQUEST]: (state, payload) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.calculations, [
        ...state.statuses.calculations,
        payload,
      ])
      .get();
  },
  [ScenariosActionTypes.GET_CALCULATIONS_SUCCEEDED]: (state, payload) => {
    const { calculations, scenarioId } = payload;
    const index = state.scenarios.findIndex((x) => x.id === scenarioId);

    return new MonoliteHelper(state)
      .set((_) => _.scenarios[index].calculations, calculations)
      .set(
        (_) => _.statuses.calculations,
        state.statuses.calculations.filter((x) => x !== scenarioId),
      )
      .get();
  },
  [ScenariosActionTypes.GET_ACTIVE_SCENARIO_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.activeScenario, RequestStatus.Loading)
      .set((_) => _.active_scenario, null)
      .set((_) => _.active_calculation, null)
      .get();
  },
  [ScenariosActionTypes.SET_ACTIVE]: (state, payload) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.activeScenario, RequestStatus.Loaded)
      .set((_) => _.active_scenario, payload)
      .set((_) => _.active_calculation, payload.calculation)
      .get();
  },
  [ScenariosActionTypes.REMOVE_SCENARIO_SUCCEEDED]: (state, scenarioId: number) => {
    return new MonoliteHelper(state)
      .set((_) => _.scenarios, state.scenarios.filter((x) => x.id !== scenarioId))
      .get();
  },
  [ScenariosActionTypes.GET_INDIRECT_SETTINGS]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.indirectCost, RequestStatus.Loading)
      .get();
  },
  [ScenariosActionTypes.GET_INDIRECT_SETTINGS_SUCCEEDED]: (
    state,
    payload: WorkPackagesData.IndirectCost[],
  ) => {
    const indirect = payload.map((x) => {
      if (x.parameters) {
        x.parameters = x.parameters.map((y) => {
          if (y.formating === TextFormatting.Percentage) {
            y.value = (y.value * 100).toFixed(2);
          }
          return y;
        });
      }
      return x;
    });
    return new MonoliteHelper(state)
      .set((_) => _.statuses.indirectCost, RequestStatus.Loaded)
      .set((_) => _.indirectCost, indirect)
      .get();
  },
  [ScenariosActionTypes.UPDATE_SCENARIO_NAME_SUCCEEDED]: (state, payload) => {
    const index = state.scenarios.findIndex((x) => x.id === payload.id);
    return new MonoliteHelper(state)
      .set((_) => _.scenarios[index].name, payload.name)
      .get();
  },
  [ScenariosActionTypes.UPDATE_CALCULATION_NAME_SUCCEEDED]: (state, payload) => {
    const { data, scenarioId } = payload;
    const scenarioIndex = state.scenarios.findIndex((x) => x.id === scenarioId);
    const scenario = state.scenarios[scenarioIndex];
    const helper = new MonoliteHelper(state);
    if (scenario.calculation.id === data.id) {
      helper.set((_) => _.scenarios[scenarioIndex].calculation.name, data.name);
    }
    if (scenario.calculations && scenario.calculations.length > 0) {
      const calcIndex = scenario.calculations.findIndex((x) => x.id === data.id);
      helper.set(
        (_) => _.scenarios[scenarioIndex].calculations[calcIndex].name,
        data.name,
      );
    }
    return helper.get();
  },
  [ScenariosActionTypes.REMOVE_CALCULATION_SUCCEEDED]: (state, payload) => {
    const { id, scenarioId } = payload;
    const scenarioIndex = state.scenarios.findIndex((x) => x.id === scenarioId);

    if (scenarioIndex < 0) {
      return state;
    }

    return new MonoliteHelper(state)
      .setFilter(_ => _.scenarios[scenarioIndex].calculations, x => x.id !== id)
      .set(
        _ => _.scenarios[scenarioIndex].calculationsQuantity,
        state.scenarios[scenarioIndex].calculationsQuantity - 1,
      )
      .get();
  },
  [ScenariosActionTypes.COPY_SCENARIO_REQUEST]: (state, payload) => {
    return new MonoliteHelper(state)
      .set((_) => _.scenariosToCopy, [...state.scenariosToCopy, payload])
      .get();
  },
  [ScenariosActionTypes.COPY_SCENARIO_SUCCEEDED]: (state, payload: ScenariosActionsType.CopyScenarioSucceeded) => {
    const { sourceScenarioId, scenario } = payload;
    return new MonoliteHelper(state)
      .setFilter((_) => _.scenariosToCopy, (x) => x !== sourceScenarioId)
      .setAppend((_) => _.scenarios, scenario)
      .get();
  },
  [ScenariosActionTypes.COPY_SCENARIO_FAILED]: (state, payload: number) => {
    return new MonoliteHelper(state)
      .setFilter(_ => _.scenariosToCopy, x => x !== payload)
      .get();
  },
  [ScenariosActionTypes.CHANGE_INDIRECT_SETTINGS_PARAMETER]: (
    state,
    payload: ScenariosActionsType.ChangeIndirectSettingsParameter,
  ) => {
    const { id, value } = payload;
    const helper = new MonoliteHelper(state);
    state.indirectCost.map((x, i) => {
      if (x.parameters && x.parameters.length > 0) {
        x.parameters.map((param, j) => {
          if (param.id === id) {
            helper.set((_) => _.indirectCost[i].parameters[j].value, value);
          }
        });
      }
    });
    return helper.get();
  },
  [ScenariosActionTypes.PUBLISH_SUCCEEDED]: (state, payload: ScenariosActionsType.Publish) => {
    const { id, value } = payload;
    const index = state.scenarios.findIndex(x => x.id === id);
    const helper = new MonoliteHelper(state).set(_ => _.scenarios[index].isPublic, value);
    if (id === state.active_scenario.id) {
      helper.set(_ => _.active_scenario.isPublic, value);
    }
    return helper.get();
  },
  [ScenariosActionTypes.DELETE_ALL_SCENARIOS_EXCEPT_ACTIVE_SUCCEEDED]: (
    state,
    payload: boolean,
  ) => {
    const scenario = state.scenarios.find((x) => x.isActivated);
    const helper = new MonoliteHelper(state);
    if (scenario) {
      scenario.isOwner = true;
      scenario.isPublic = true;
      scenario.isAnyUserActivated = payload;
      const newScenarious = [scenario];
      helper.set((_) => _.scenarios, newScenarious);
    }
    return helper.get();
  },
  [ScenariosActionTypes.SET_ACTIVE_CALCULATION_SUCCEEDED]: (
    state,
    payload: ScenariosActionsType.ScenarioCalculationIds,
  ) => {
    const helper = new MonoliteHelper(state);
    const { calculationId, scenarioId } = payload;
    const scenarioIndex = state.scenarios.findIndex((x) => x.id === scenarioId);
    const scenario = state.scenarios[scenarioIndex];
    if (scenario.calculations) {
      const activeIndex = scenario.calculations.findIndex((x) => x.isActivated);
      const newActiveIndex = scenario.calculations.findIndex(
        (x) => x.id === calculationId,
      );
      const calc = scenario.calculations[newActiveIndex];
      calc.isActivated = true;

      helper
        .set((_) => _.scenarios[scenarioIndex].calculation, calc)
        .set(
          (_) => _.scenarios[scenarioIndex].calculations[activeIndex].isActivated,
          false,
        )
        .set(
          (_) =>
            _.scenarios[scenarioIndex].calculations[newActiveIndex].isActivated,
          true,
        );
      if (scenarioId === state.active_scenario.id) {
        helper.set((_) => _.active_calculation, calc);
      }
    } else {
      helper
        .set((_) => _.scenarios[scenarioIndex].calculation.isActivated, true)
        .set((_) => _.active_calculation, scenario.calculation);
    }
    return helper.get();
  },
  [ScenariosActionTypes.SET_ACTIVE_SCENARIO_SUCCEEDED]: (state, payload: number) => {
    const activeIndex = state.scenarios.findIndex((x) => x.isActivated);
    const newActiveIndex = state.scenarios.findIndex((x) => x.id === payload);
    const scenario = state.scenarios[newActiveIndex];
    scenario.isActivated = true;
    const helper = new MonoliteHelper(state);

    if (activeIndex >= 0) {
      helper.set((_) => _.scenarios[activeIndex].isActivated, false);
    }

    return helper
      .set((_) => _.scenarios[newActiveIndex].isActivated, true)
      .set((_) => _.active_scenario, scenario)
      .set((_) => _.active_calculation, scenario.calculation)
      .get();
  },
  [ScenariosActionTypes.EDITING_GET_BASE_RESOURCE_LIMITATIONS_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.resourceLimitations, RequestStatus.Loading)
      .get();
  },
  [ScenariosActionTypes.EDITING_GET_BASE_RESOURCE_LIMITATIONS_SUCCEEDED]: (
    state,
    payload,
  ) => {
    const helper = new MonoliteHelper(state);
    const allCount = payload.length;

    if (allCount > 0) {
      const firstShift = payload[0].calendarIds;
      const firstProd = payload[0].crewProductivity;
      const firstAmdahl = payload[0].useAmdahl;

      const prodEqCount = payload.filter(
        (rl) => rl.crewProductivity === firstProd,
      ).length;
      const shiftEqCount = payload.filter((rl) => rl.calendarIds === firstShift)
        .length;
      const amdahlEqCount = payload.filter((rl) => rl.useAmdahl === firstAmdahl)
        .length;

      helper
        .set(
          (_) => _.totalLimitation.crewProductivity,
          prodEqCount === allCount ? firstProd : null,
        )
        .set(
          (_) => _.totalLimitation.calendarIds,
          shiftEqCount === allCount ? firstShift : null,
        )
        .set(
          (_) => _.totalLimitation.useAmdahl,
          amdahlEqCount === allCount ? firstAmdahl : false,
        );
    }
    return helper
      .set((_) => _.resourceLimitations, payload)
      .set((_) => _.statuses.resourceLimitations, RequestStatus.Loaded)
      .get();
  },
  [ScenariosActionTypes.DROP_LIMITATIONS_STATE]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.resourceLimitations, [])
      .set((_) => _.statuses.resourceLimitations, RequestStatus.NotRequested)
      .get();
  },
  [ScenariosActionTypes.EDITING_CHANGE_RESOURCE_VALUE]: (
    state,
    payload: ScenariosActionsType.ChangeResourceParameter,
  ) => {
    const helper = new MonoliteHelper(state);
    const index = state.resourceLimitations.findIndex((x) => x.id === payload.id);

    const isAllEqualsLength = state.resourceLimitations.filter(
      (rl) => rl[payload.fieldKey] === payload.value,
    ).length;

    if (isAllEqualsLength >= state.resourceLimitations.length - 1) {
      if (typeof payload.value === 'boolean') {
        helper.set((_) => _.totalLimitation.useAmdahlIndeterminate, false);
      }
      helper.set((_) => _.totalLimitation[payload.fieldKey], payload.value);
    } else {
      if (typeof payload.value === 'boolean') {
        helper.set((_) => _.totalLimitation.useAmdahlIndeterminate, true);
        helper.set((_) => _.totalLimitation[payload.fieldKey], false);
      } else {
        helper.set((_) => _.totalLimitation[payload.fieldKey], '');
      }
    }

    return helper
      .set((_) => _.resourceLimitations[index][payload.fieldKey], payload.value)
      .get();
  },
  [ScenariosActionTypes.EDITING_CHANGE_TOTAL_RESOURCE_VALUE]: (
    state,
    payload: ScenariosActionsType.ChangeTotalResourceParameter,
  ) => {
    const helper = new MonoliteHelper(state);

    state.resourceLimitations.forEach((_limitation, index) => {
      helper.set(
        (_) => _.resourceLimitations[index][payload.fieldKey],
        payload.value,
      );
    });

    if (typeof payload.value === 'boolean') {
      helper.set((_) => _.totalLimitation.useAmdahlIndeterminate, payload.value);
    }

    return helper
      .set((_) => _.totalLimitation[payload.fieldKey], payload.value)
      .get();
  },
  [ScenariosActionTypes.EDITING_CHANGE_LIMITATION_VALUE]: (
    state,
    payload: ScenariosActionsType.ChangeLimitationParameter,
  ) => {
    const resourceLimitation = state.resourceLimitations.find(
      (x) => x.id === payload.resourceManagementId,
    );
    const limitation = resourceLimitation.limitations.find(
      (x) => x.id === payload.id,
    );
    const pIndex = state.resourceLimitations.indexOf(resourceLimitation);
    const rIndex = resourceLimitation.limitations.indexOf(limitation);
    const limitationsFieldName = 'limitations';

    return new MonoliteHelper(state)
      .set(
        (_) =>
          _.resourceLimitations[pIndex][limitationsFieldName][rIndex][
            payload.fieldKey
          ],
        payload.value,
      )
      .get();
  },
  [ScenariosActionTypes.SET_EDIT_SCENARIO]: (state, payload: ScenariosData.Scenario) => {
    return new MonoliteHelper(state).set((_) => _.editScenario, payload).get();
  },
  [ScenariosActionTypes.SET_CHANGE_WP_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.savePackages, RequestStatus.Loading)
      .get();
  },
  [ScenariosActionTypes.SET_CHANGE_WP_SUCCEEDED]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.savePackages, RequestStatus.Loaded)
      .get();
  },
  [ScenariosActionTypes.UPDATE_SCENARIO_SUCCEEDED]: (
    state,
    payload: ScenariosActionsType.UpdateScenario,
  ) => {
    const helper = new MonoliteHelper(state);
    const index = state.scenarios.findIndex((x) => x.id === payload.scenarioId);
    if (index >= 0) {
      helper
        .set((_) => _.scenarios[index].locationId, payload.locationId)
        .set((_) => _.scenarios[index].startDate, payload.startDate);
    }
    return helper.get();
  },
  [ScenariosActionTypes.UPDATE_SCENARIO_STATUS]: (
    state,
    { scenarioId, status }: ScenariosActionsType.UpdateStatus,
  ) => {
    const helper = new MonoliteHelper(state);
    if (state.active_scenario && state.active_scenario.id === scenarioId) {
      helper.set((_) => _.active_scenario.status, status);
    }
    if (state.editScenario && state.editScenario.id === scenarioId) {
      helper.set((_) => _.editScenario.status, status);
    }
    if (state.scenarios && state.scenarios.length > 0) {
      const index = state.scenarios.findIndex((x) => x.id === scenarioId);
      if (index >= 0) {
        helper.set((_) => _.scenarios[index].status, status);
      }
    }
    return helper.get();
  },
};
