// @flow

import { fromJS } from 'immutable';

import {
  Actions
} from '../constants';

const INITIAL_STATE = fromJS({
  count: 0,
  notifications: [],
  editedtype: -1,
  editedvalue: -1,
  leftmenuExpanded: true,
  leftmenuExpandActionActive: false,
  mobile: false
})

export default function toppanel(
  state: any = INITIAL_STATE,
  action: any = { type: '', payload: null }
  ): any {

  switch (action.type) {
    case Actions.TopPanel.TOPPANEL_LEFT_MENU_EXPAND:
      return state.merge(fromJS({
        leftmenuExpanded: action.payload
      }))
    case Actions.TopPanel.TOPPANEL_LEFT_MENU_ACTIVATE_ACTION:
      return state.merge(fromJS({
        leftmenuExpandActionActive: action.payload
      }))
    case Actions.TopPanel.TOPPANEL_SET_MOBILE:
      return state.merge(fromJS({
        mobile: action.payload
      }))
    default:
      return state;
  }
}
