
const PagesFormatDescription = {
  'ISO A0': [0.841, 1.189],
  'ISO A1': [0.594, 0.841],
  'ISO A2': [0.420, 0.594],
  'ISO A3': [0.297, 0.420],
  'ISO A4': [0.210, 0.297],
  'ISO A5': [0.148, 0.210],
  'ARCH A': [0.2286, 0.3048],
  'ARCH B': [0.3048, 0.4572],
  'ARCH C': [0.4572, 0.6096],
  'ARCH D': [0.6096, 0.9144],
  'ARCH E': [0.9144, 1.2192],
  'ARCH E1': [0.762, 1.0668],
  'ANSI A': [0.2159, 0.2794],
  'ANSI B': [0.2794, 0.4318],
  'ANSI C': [0.4318, 0.5588],
  'ANSI D': [0.5588, 0.8636],
  'ANSI E': [0.8636, 1.1176],
};


const ScalesDescription = {
  '1:5': '1:5',
  '1:10': '1:10',
  '1:20': '1:20',
  '1:25': '1:25',
  '1:30': '1:30',
  '1:40': '1:40',
  '1:50': '1:50',
  '1:75': '1:75',
  '1:100': '1:100',
  '1:125': '1:125',
  '1:150': '1:150',
  '1:200': '1:200',
  '1:250': '1:250',
  '1:300': '1:300',
  '1:400': '1:400',
  '1:500': '1:500',
  '1:750': '1:750',
  '1:1000': '1:1000',
  '1:1250': '1:1250',
  '1:2000': '1:2000',
  '1:1': `1'=1'-0"`,
  '1:6': `6"=1'-0"`,
  '1:8': `1-1/2"=1'-0"`,
  '1:12': `1"=1'-0"`,
  '1:16': `3/4"=1'-0"`,
  '1:24': `1/2"=1'-0"`,
  '1:32': `3/8"=1'-0"`,
  '1:48': `1/4"=1'-0"`,
  '1:64': `3/16"=1'-0"`,
  '1:96': `1/8"=1'-0"`,
  '1:128': `3/32"=1'-0"`,
  '1:192': `1/16"=1'-0"`,
  '1:384': `1/32"=1'-0"`,
  '1:768': `1/64"=1'-0"`,
  '1:1536': `1/128"=1'-0"`,
  '1:120': `1"=10'`,
  '1:240': `1"=20'`,
  '1:360': `1"=30'`,
  '1:480': `1"=40'`,
  '1:600': `1"=50'`,
  '1:720': `1"=60'`,
  '1:840': `1"=70'`,
  '1:960': `1"=80'`,
  '1:1080': `1"=90'`,
  '1:1200': `1"=100'`,
  '1:1800': `1"=150'`,
  '1:2400': `1"=200'`,
  '1:3600': `1"=300'`,
  '1:4800': `1"=400'`,
  '1:6000': `1"=500'`,
};

const FormatsList = Object.keys(PagesFormatDescription);

export const DrawingsScaleConstant = {
  PagesFormatDescription,
  ImperialScales: Object.values(ScalesDescription),
  MetricScales: Object.keys(ScalesDescription),
  FormatsList,
  ScalesDescription,
  DEFAULT_FORMAT: 'ISO A1',
};
