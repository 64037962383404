import * as React from 'react';
import { ViewModelStatus } from '../../enums/view-model-status';

interface Props {
  status: ViewModelStatus;
}

export const ProjectTimeProgressWithoutDuration: React.FC<Props> = (props) => {
  return (
    <div className='project-tile-progress-bar'>
      <div className='project-tile-progress-bar__name'>
        {props.children}
      </div>
      <div className='project-tile-progress-bar__progress-bar'>
        {props.status}
      </div>
    </div>
  );
};
