import { useCallback } from 'react';

import { RowData } from 'common/components/data-base-table';

type UseRowClickedCallback = (
  handleClickRow: (id: string) => void,
) => [(data: RowData) => void];

export const useRowClickedCallback: UseRowClickedCallback = (handleClick) => {
  return [useCallback((data) => {
    handleClick(data.id as string);
  }, [])];
};
