import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import React from 'react';
import { AIRequestStatus } from 'unit-2d-copilot/interfaces';

import { Styled } from './styled';

interface Props {
  status: AIRequestStatus;
  onPause: () => void;
  tryAgain: () => void;
}

const StatusButtonComponent: React.FC<Props> = ({
  status,
  onPause,
  tryAgain,
}) => {
  switch (status) {
    case AIRequestStatus.Loading:
      return (
        <Styled.Container>
          <RectangleButton
            Icon={Icons.PauseStop}
            text='Stop'
            onClick={onPause}
            mood='negative'
            width={120}
            height={30}
          />
        </Styled.Container>
      );
    case AIRequestStatus.Error:
      return (
        <Styled.Container>
          <RectangleButton
            Icon={Icons.ResetAi}
            text="Try again"
            onClick={tryAgain}
            mood='negative'
            width={120}
            height={30}
          />
        </Styled.Container>
      );
    case AIRequestStatus.Stoped:
      return (
        <Styled.Container>
          <RectangleButton
            Icon={Icons.ResetAi}
            text="Regenerate"
            onClick={tryAgain}
            mood='negative'
            width={120}
            height={30}
          />
        </Styled.Container>
      );
    default:
      return null;
  }
};

export const StatusButton = React.memo(StatusButtonComponent);
