import classNames from 'classnames';
import * as React from 'react';

import './classification-ontology-tree-element-state-indicator.scss';

import { KreoIconAttention, KreoIconSuccessSmall } from 'common/UIKit';


interface Props {
  hasError: boolean;
  className?: string;
}

export class ClassificationOntologyTreeElementStateIndicator extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      hasError,
      className,
    } = this.props;
    return (
      <div className={classNames('classification-ontology-tree-element-state-indicator', className)}>
        { hasError ? <KreoIconAttention/> : <KreoIconSuccessSmall/>}
      </div>
    );
  }
}
