import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './styles.scss';

import { ConstantFunctions } from 'common/constants/functions';
import { ProjectType } from 'common/constants/project-type';
import { SubscriptionType } from 'common/constants/subscription';
import { HeaderAddContainer, InformationItem, InformationRow } from '..';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { AdminCompanyVm } from '../../interfaces/admin-company-vm';
import { AdminDatabaseVm } from '../../interfaces/admin-database-vm';
import { AdminProjectVm } from '../../interfaces/admin-project-vm';
import { CompanyCurrencyControl } from '../company-currency-control';
import { CompanyPreferImperial } from '../company-prefer-imperial';
import { CompanySubscriptionsControl } from '../company-subscriptions-control.tsx';
import { ProjectsFilter } from './projects-filter';
import { ShortDatabaseItem } from './short-database-item';
import { ShortProjectItem } from './short-project-item';

interface CompanyItemProps {
  company: AdminCompanyVm;
  isActive?: boolean;
  onClick?: (item: AdminCompanyVm) => void;
  onCleanDeletedProjectsClick?: (companyId: number) => void;
  onDatabasesAttach?: (companyId: number) => void;
  onEmployeesAttach?: (companyId: number, subscriptionType: SubscriptionType) => void;
  onEmployeesAttachToProject?: (companyId: number, projectId: number) => void;
  onEmployeeClick?: (email: string, subscriptionType: SubscriptionType) => void;
  onDatabaseDetachClick?: (companyId: number, databaseId: number) => void;
  onEmployeeDetachClick?: (companyId: number, employeeGuid: string, subscriptionType: SubscriptionType) => void;
  onDeleteCompany: (companyId: number) => void;
  createEmptySubscription: (companyId: number, subscriptionType: SubscriptionType) => void;
}

interface State {
  selectedProjectType: ProjectType;
  filteredProjects: AdminProjectVm[];
}

export class CompanyItem extends React.Component<CompanyItemProps, State>  {
  private menu: ItemMenuType[] = [
    {
      name: '',
      action: ConstantFunctions.doNothing,
    },
    {
      name: 'Delete all projects',
      action: this.deleteProjects,
    },
    {
      name: 'Delete company',
      action: this.deleteCompony,
    },
  ];

  constructor(props: CompanyItemProps) {
    super(props);
    this.state = {
      selectedProjectType: null,
      filteredProjects: null,
    };
  }

  public render(): React.ReactNode {
    const { company } = this.props;
    const documents = this.state.filteredProjects || company.documents;
    return (
      <div
        className={classNames('admin-item', { active: this.props.isActive })}
        onClick={this.onItemClick}
      >
        <div className='admin-item__information'>
          <span className='admin-item__head'>
            {company.name}
          </span>
          <ItemMenu menu={this.menu} className='admin-item__item-actions-menu' />
        </div>
        <InformationRow>
          <InformationItem
            title='Id'
            data={company.id}
          />
          <InformationItem
            title='Email'
            data={company.email}
          />
        </InformationRow>
        <InformationRow>
          <InformationItem
            title='Databases'
            data={company.databases}
            renderItem={this.renderDatabase}
            renderHeader={this.renderDatabaseHeader}
          />
          <InformationItem
            title='Projects'
            className={
              documents?.length > 0 ?
                'admin-item__information-item--overflow' : null
            }
            data={documents}
            renderHeader={this.renderProjectsFilter}
            renderItem={this.renderProject}
          />
        </InformationRow>
        <CompanySubscriptionsControl
          company={company}
          createEmptySubscription={this.props.createEmptySubscription}
          onEmployeeClick={this.props.onEmployeeClick}
          onEmployeeDetachClick={this.props.onEmployeeDetachClick}
          onEmployeesAttach={this.props.onEmployeesAttach}
        />
        <CompanyCurrencyControl companyId={company.id} />
        <CompanyPreferImperial companyId={company.id} />
      </div>
    );
  }

  @autobind
  private renderProjectsFilter(): React.ReactNode {
    return (
      <ProjectsFilter
        selectedType={this.state.selectedProjectType}
        onChangeSelection={this.onChangeProjectFilter}
      />
    );
  }

  @autobind
  private onChangeProjectFilter(type: ProjectType): void {
    if (type) {
      this.setState({
        selectedProjectType: type,
        filteredProjects: this.props.company.documents?.filter((item: AdminProjectVm) => item.type === type),
      });
    } else {
      this.setState({
        selectedProjectType: null,
        filteredProjects: null,
      });
    }
  }

  @autobind
  private renderDatabaseHeader(): React.ReactNode {
    return (
      <HeaderAddContainer action={this.onDatabasesAttach}/>
    );
  }

  @autobind
  private deleteCompony(): void {
    this.props.onDeleteCompany(this.props.company.id);
  }

  @autobind
  private renderDatabase(item: AdminDatabaseVm): React.ReactNode {
    return (
      <ShortDatabaseItem
        key={`${this.props.company.id}_${item.id}`}
        className='admin-item__chip'
        item={item}
        onDetachClick={this.onDbDetachClick}
      />
    );
  }

  @autobind
  private onDbDetachClick(item: AdminDatabaseVm): void {
    if (this.props.onDatabaseDetachClick) {
      this.props.onDatabaseDetachClick(this.props.company.id, item.id);
    }
  }

  @autobind
  private renderProject(item: AdminProjectVm): React.ReactNode {
    return (
      <ShortProjectItem
        key={`${this.props.company.id}_${item.id}`}
        item={item}
        showType={!this.state.selectedProjectType}
        className={classNames('admin-item__chip', 'project', {
          removed: item.isDeleted,
          clickable: !item.isDeleted,
        })}
        onClick={this.onProjectClick}
      />
    );
  }

  @autobind
  private onProjectClick(item: AdminProjectVm): void {
    if (this.props.onEmployeesAttachToProject && !item.isDeleted) {
      this.props.onEmployeesAttachToProject(this.props.company.id, item.id);
    }
  }

  @autobind
  private onItemClick(): void {
    if (this.props.onClick) {
      this.props.onClick(this.props.company);
    }
  }

  @autobind
  private deleteProjects(): void {
    if (this.props.onCleanDeletedProjectsClick) {
      this.props.onCleanDeletedProjectsClick(this.props.company.id);
    }
  }

  @autobind
  private onDatabasesAttach(): void {
    if (this.props.onDatabasesAttach) {
      this.props.onDatabasesAttach(this.props.company.id);
    }
  }
}
