import * as React from 'react';

const doNothing = (): void => { /* do nothing */ };

const stopEvent = (e?: Event | React.SyntheticEvent | PaperMouseEvent): void => {
  if (e) {
    e.stopPropagation();
    e.preventDefault();
  }
};

const preventDefault = (e?: Event | React.SyntheticEvent | PaperMouseEvent): void => {
  if (e) {
    e.preventDefault();
  }
};

const stopPropagation = (e?: Event | React.SyntheticEvent | PaperMouseEvent): void => {
  if (e) {
    e.stopPropagation();
  }
};


export const ConstantFunctions = {
  doNothing,
  stopEvent,
  preventDefault,
  stopPropagation,
};
