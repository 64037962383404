import React from 'react';

import { DrawingsSelectionContext, DrawingsSelectionContextProps } from './drawings-selection-context';

export function withDrawingsSelectionContextProps<P>(
  Component: React.ComponentType<P & DrawingsSelectionContextProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawingsSelectionContextProps>>> {
  return class WrappedComponent extends React.PureComponent<P> {
    public render(): React.ReactNode {
      return (
        <DrawingsSelectionContext.Consumer>
          {
            contextProps => <Component {...this.props} {...contextProps}/>
          }
        </DrawingsSelectionContext.Consumer>
      );
    }
  };
}
