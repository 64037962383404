import styled from 'styled-components';
import { FormulaFieldStyled } from '../formula-field';

const Container = styled.div`
  height: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  overflow: hidden;
  display: grid;
`;

const FormulaPart = styled.div`
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  display: flex;

  ${FormulaFieldStyled.Container} {
    flex-grow: 1;
  }
  ${FormulaFieldStyled.InputContainer} {
    flex-grow: 1;
    margin-top: unset;
  }
`;


export const Styled = {
  Container,
  FormulaPart,
};
