import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LinkComponent } from 'common/components/link-component';
import { RequestStatus } from 'common/enums/request-status';
import { useActionWithArgsDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { PdfFilterActions } from '../../actions/creators/pdf-filter';
import { DrawingsDrawMode } from '../../enums';
import { useCurrentDrawingInfo, useDrawModeApi } from '../../hooks';
import { AiButtonWithStatus } from '../ai-button-with-status';
import { WizzardMainContainer } from '../wizzard-tools';
import { PdfFilterEditor } from './edit-filter';
import { StatusesMap, TextOverrides } from './statuses-map';
import { Styled } from './styled';

const PdfFilterSettingsComponent: React.FC = () => {
  const currentDrawing = useCurrentDrawingInfo();
  const { setDrawMode } = useDrawModeApi();
  const startAddRect = useCallback(() => {
    setDrawMode(DrawingsDrawMode.PdfFilterArea);
  }, [setDrawMode]);


  const createEmptyAction = useActionWithArgsDispatch(
    PdfFilterActions.createEmptyFilter,
    currentDrawing.pdfId,
    currentDrawing.drawingId,
    startAddRect,
  );
  const hideBG = useActionWithArgsDispatch(
    PdfFilterActions.hideBackground,
    currentDrawing.pdfId,
    currentDrawing.drawingId,
  );

  const onCreateClick = useCallback(() => {
    if (currentDrawing?.filter.state) {
      startAddRect();
    } else {
      createEmptyAction();
    }
  }, [createEmptyAction, !!currentDrawing?.filter.state, startAddRect]);

  const status = useSelector<State, RequestStatus>(s => s.drawings.pageFilterApplyStatus[currentDrawing.drawingId]);

  if (currentDrawing?.filter?.state) {
    return (
      <Styled.Container>
        <PdfFilterEditor
          startAddRect={startAddRect}
          createEmptyAction={createEmptyAction}
          hideBG={hideBG}
          status={status}
        />
      </Styled.Container>
    );
  } else {
    return (
      <Styled.Container>
        <WizzardMainContainer>
          <AiButtonWithStatus
            text={'Create new'}
            onClick={onCreateClick}
            icon={Icons.PlusBig}
            status={StatusesMap[status]}
            textOverrides={TextOverrides}
          />
          <LinkComponent
            text="Hide Background"
            fontSize={12}
            icon={Icons.Hide2D}
            onClick={hideBG}
            fontColor="mainFont"
            size="m"
          />
        </WizzardMainContainer>
      </Styled.Container>
    );
  }
};

export const PdfFilterSettings = React.memo(PdfFilterSettingsComponent);
