import * as React from 'react';

import { EnvironmentConfigurationProps } from './environment-configuration-props';
import { EnvironmentContext } from './environment-context-provider';


export function withEnvironmentConfiguration<P extends EnvironmentConfigurationProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof EnvironmentConfigurationProps>>> {
  return class AbilityAwareComponent
    extends React.Component<Pick<P, Exclude<keyof P, keyof EnvironmentConfigurationProps>>> {
    public render(): React.ReactNode {
      return (
        <EnvironmentContext.Consumer>
          {
            environmentConfiguration =>
              <Component {...this.props as P} environmentConfiguration={environmentConfiguration} />
          }
        </EnvironmentContext.Consumer>
      );
    }
  };
}
