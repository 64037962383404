import React from 'react';

import {
  AddInstanceWithGroupCallback,
  useAddInstances,
  useAddInstancesWithUndo,
  useRemoveInstances,
} from '../../hooks';
import { DrawingsAddInstanceUndoRedoFunctionType } from './element-and-group-operations-context';

export interface WithStateConnectorProps {
  addInstancesWithUndo: DrawingsAddInstanceUndoRedoFunctionType;
  addInstances: AddInstanceWithGroupCallback;
  removeInstances: (instancesByDrawings: Record<string, string[]>, groupIds: string[]) => void;
}

export function withStateConnector<P extends WithStateConnectorProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithStateConnectorProps>> {
  return function WithStateConnector(props: Omit<P, keyof WithStateConnectorProps>) {
    const addInstancesWithUndo = useAddInstancesWithUndo();
    const addInstances = useAddInstances();
    const removeInstances = useRemoveInstances();
    return (
      <Component
        {...props as P}
        addInstancesWithUndo={addInstancesWithUndo}
        addInstances={addInstances}
        removeInstances={removeInstances}
      />
    );
  };
}
