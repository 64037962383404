import autobind from 'autobind-decorator';
import React from 'react';
import { OptionKeyType } from '../interfaces';
import { Styled } from './styled';

interface Props<K extends OptionKeyType | boolean> {
  value: K;
  onSelect: (value: K) => void;
}

export class OptionContainer<K extends OptionKeyType | boolean> extends React.PureComponent<Props<K>> {
  public render(): React.ReactNode {
    return (
      <Styled.Container onClick={this.onClick}>
        {this.props.children}
      </Styled.Container>
    );
  }

  @autobind
  private onClick(): void {
    this.props.onSelect(this.props.value);
  }
}
