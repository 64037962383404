import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ProductConfig, ProductConfigId, ProductsPage } from 'common/components/products-page';
import { SubscriptionType } from 'common/constants/subscription';
import { State } from 'common/interfaces/state';
import { MetaTagUtils } from 'common/utils/meta-tag-utils';
import { AppUrls } from 'routes/app-urls';
import { Company } from '../account/interfaces/company';


interface StateProps {
  companies: Company[];
}


class ProductsPageWrapperComponent extends React.Component<StateProps> {
  public componentDidMount(): void {
    MetaTagUtils.setTitle('Select Product');
  }

  public componentDidUpdate(): void {
    MetaTagUtils.setTitle('Select Product');
  }

  public render(): React.ReactNode {
    const companies = this.props.companies;
    const hasPlanSubscriptionCompany = companies && companies.some(x => SubscriptionType.KreoPlan in x.subscriptions);
    const hasCostEstimateSubscriptionCompany = companies
      && companies.some(x => SubscriptionType.CostEstimate in x.subscriptions);

    const products: ProductConfig[] = [
      {
        name: '2d takeoff',
        id: ProductConfigId.Takeoff2d,
        url: AppUrls.qto2d.index.url(),
        color: '#478fbc',
        Logo: Icons.TakeOffIcon,
        image: '/static/img/2d_promo_image.jpg',
      },
      {
        name: '3d takeoff',
        id: ProductConfigId.Takeoff3d,
        url: AppUrls.qto3d.index.url(),
        color: '#478fbc',
        Logo: Icons.TakeOffIcon,
        image: 'https://imageskd.s3.eu-west-2.amazonaws.com/product-page/background-modular.png',
      },
    ];

    if (hasPlanSubscriptionCompany) {
      products.push({
        name: 'plan',
        id: ProductConfigId.KreoPlan,
        url: AppUrls.plan.index.url(),
        color: '#478fbc',
        Logo: Icons.TakeOffIcon,
        image: 'https://imageskd.s3.eu-west-2.amazonaws.com/product-page/background-modular.png',
      });
    }

    if (hasCostEstimateSubscriptionCompany) {
      products.push({
        name: 'cost estimate',
        id: ProductConfigId.CostEstimate,
        url: AppUrls.costEstimate.index.url(),
        color: '#478fbc',
        Logo: Icons.TakeOffIcon,
        image: 'https://imageskd.s3.eu-west-2.amazonaws.com/product-page/background-modular.png',
      });
    }

    return (
      <ProductsPage
        products={products}
        RouterLink={Link}
        defaultImage='https://imageskd.s3.eu-west-2.amazonaws.com/product-page/background.png'
      />
    );
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    companies: state.account.companies,
  };
}

export const ProductsPageWrapper = connect(mapStateToProps)(ProductsPageWrapperComponent);
