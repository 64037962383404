import { useMemo, useState } from 'react';
import { DrawingsGeometryGroup } from 'common/components/drawings/interfaces';

interface SelectGroupState {
  selectedGroupName: string;
  selectedGroupId: string | null;
  setSelectedGroupId: (id: string | null) => void;
}

export function useSelectGroup(groups: DrawingsGeometryGroup[]): SelectGroupState {
  const [ selectedGroupId, setSelectedGroupId ] = useState(undefined);
  const selectedGroupName = useMemo(() => {
    if (!selectedGroupId) {
      return '';
    }
    const group = groups.find((g) => g.id === selectedGroupId);
    return group ? group.name : '';
  }, [selectedGroupId, groups]);
  return {
    selectedGroupName,
    selectedGroupId,
    setSelectedGroupId,
  };
}
