import { CodecUtils } from 'common/utils/codec-utils';

export enum ViewModelStatus {
  Empty = 'Empty',
  Ready = 'Ready',
  Calculating = 'Calculating',
  Failed = 'Failed',
  Waiting = 'Waiting',
}

export const ViewModelStatusC = CodecUtils.createEnumCodec<ViewModelStatus>(ViewModelStatus, 'ViewModelStatus');
