import autobind from 'autobind-decorator';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './bell-notification.scss';

import { Notification } from 'common/interfaces/realtime-messages';
import { State } from 'common/interfaces/state';
import { Avatar, KreoIconCloseSmall } from 'common/UIKit';
import { NotificationActions } from '../../actions';
import { NotificationStatusIcon } from '../notification-status-icon';

interface ReduxProps {
  notification: Notification;
}

interface ReduxActions {
  readNotification(notificationId: number, value: boolean): void;
  deleteNotification(notificationId: number): void;
}

interface OwnProps {
  id: number;
}

interface Props extends OwnProps, ReduxProps, ReduxActions {}

class BellNotificationComponent extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Props): boolean {
    if (
      this.props.notification &&
      nextProps.notification &&
      this.props.notification.isRead === nextProps.notification.isRead
    ) {
      return false;
    }

    return true;
  }

  public render(): JSX.Element {
    const { notification } = this.props;
    if (!notification) {
      return null;
    }

    const { sender, message, isRead, created, relatedArea, icon } = notification;
    return (
      <div className='bell-notification'>
        <div className='bell-notification__actions'>
          <div className='bell-notification__info-wrapper'>
            <div className='bell-notification__status-wrapper'>
              <div
                className={classNames('bell-notification__status', {
                  unread: !isRead,
                })}
                onClick={this.onReadNotification}
              />
            </div>
            <div className='bell-notification__time'>{moment(created).fromNow()}</div>
          </div>
          <div className='bell-notification__delete' onClick={this.onDeleteNotification}>
            <KreoIconCloseSmall />
          </div>
        </div>
        <div className='bell-notification__data-wrapper'>
          <NotificationStatusIcon relatedArea={relatedArea} icon={icon} />
          {sender && (
            <Avatar firstName={sender.firstName} lastName={sender.lastName} userId={sender.id} />
          )}
          <div
            className='bell-notification__message'
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onReadNotification(): void {
    const { notification } = this.props;
    this.props.readNotification(notification.id, !notification.isRead);
  }

  @autobind
  private onDeleteNotification(): void {
    this.props.deleteNotification(this.props.notification.id);
  }
}

const mapStateToProps = (state: State, { id }: OwnProps): ReduxProps => {
  return {
    notification: state.notifications.notifications.find(x => x.id === id),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    readNotification: (notificationId, value) => {
      dispatch(NotificationActions.readNotifications([notificationId], value));
    },
    deleteNotification: notificationId => {
      dispatch(NotificationActions.deleteNotifications([notificationId]));
    },
  };
};

export const BellNotification = connect(mapStateToProps, mapDispatchToProps)(
  BellNotificationComponent,
);
