import { roundNumeric } from 'unit-2d-database/helpers';
import { FormatTypeGuards } from 'unit-2d-database/helpers/format-typeguards';
import {
  Property,
  PropertyFormatEnum,
  PropertyTypeEnum,
  SidePanelExtraProps,
  SingleSelect,
} from '../../../../../interfaces';
import { validateName, validateValue  } from '../../../helpers/validate-name';
import { PropertyFormData } from '../interfaces';

export function getFormData(property: Property): PropertyFormData {
  const value =
    property.value.type === PropertyTypeEnum.SingleSelect
      ? (property.value as SingleSelect)
      : { value: property.value.value as string | number, available: [] };
  const roundedValue: SingleSelect =
    property.value.type !== PropertyTypeEnum.Formula && property.value.format.type !== PropertyFormatEnum.Text
      ? roundValue(value)
      : { value: value.value, available: value.available };
  return {
    name: property.name,
    unit: getUnit(property),
    groupName: property.groupName,
    format: property.value.format.type,
    root: property.value.type === PropertyTypeEnum.Breakdown ? property.value.root : undefined,
    ...roundedValue,
  };
}

function getUnit(property: Property): string {
  if (FormatTypeGuards.isNumeric(property.value.format)) {
    return property.value.format.unit;
  }
  if (property.value.type === PropertyTypeEnum.SingleSelect) {
    return 'Text';
  }

  return '';
}

function roundValue(value: SingleSelect): SingleSelect {
  return {
    value: roundNumeric(value.value as number),
    available: value.available.map(roundNumeric),
  };
}

export function getInitialFormData(
  type: PropertyTypeEnum,
  group: string,
  extraProps: SidePanelExtraProps,
): PropertyFormData {
  return {
    name: '',
    unit: type === PropertyTypeEnum.Text || type === PropertyTypeEnum.Breakdown ? undefined : 'm',
    groupName: extraProps.defaultPropertyGroup || group,
    format:
      type === PropertyTypeEnum.Text || type === PropertyTypeEnum.Breakdown
        ? PropertyFormatEnum.Text
        : PropertyFormatEnum.Number,
    value: '',
    available: [],
    root: [],
  };
}

export function validatePropertyNames({ name }: PropertyFormData): boolean {
  return validateName(name);
}

export function validatePropertyUnit({ unit }: PropertyFormData): boolean {
  return validateName(unit);
}

export function validatePropertyValue({ value }: PropertyFormData): boolean {
  return validateValue(value);
}
