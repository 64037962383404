import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { State } from 'common/interfaces/state';
import { MeasurementsApi as CEMeasurementsApi } from 'unit-cost-estimate/api/measurements';
import { MeasurementsActions } from '../actions/creators/measurements';
import { MeasurementsActionTypes } from '../actions/types/measurements';
import { MeasurementsApi } from '../api/measurements';
import { MeasurementsState } from '../interfaces/measurements/measurements-state';
import { MeasurementsUtils } from '../utils/measurements-utils';

function* getData(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    const data = scenarioId
      ? yield call(MeasurementsApi.getData, scenarioId)
      : yield call(CEMeasurementsApi.getData);
    const preparedData = MeasurementsUtils.extractData(data);
    yield put(MeasurementsActions.loadDataSuccess(preparedData));
  } catch (error) {
    console.error('measurement: get data failed', error, action.payload);
  }
}

function* getStatistic(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    const statistic = scenarioId
      ? yield call(MeasurementsApi.getStatistic, scenarioId)
      : yield call(CEMeasurementsApi.getStatistic);
    yield put(MeasurementsActions.getStatisticSuccess(statistic));
  } catch (error) {
    console.error('measurement: get statistic failed', error, action.payload);
  }
}

function selectMeasurementsStateAndCurrentProjectAndScenarioId(state: State): [MeasurementsState, number] {
  return [
    state.measurements,
    state.scenarios.active_scenario ? state.scenarios.active_scenario.id : null,
  ];
}

function* onEditBlur(): SagaIterator {
  try {
    const [measurementsState, scenarioId]: [MeasurementsState, number] =
      yield select(selectMeasurementsStateAndCurrentProjectAndScenarioId);
    const { uncommitedChanges, modelBrowser, rootIds } = measurementsState;
    const changes = MeasurementsUtils.getChangesForm(uncommitedChanges, modelBrowser, rootIds);
    yield call(MeasurementsApi.bulkUpdateMeasurement, scenarioId, changes);
    yield put(MeasurementsActions.commitChangesSuccess());
  } catch (error) {
    console.error('measurement: on edit blur failed', error);
  }
}

function* cancel(): SagaIterator {
  try {
    const [measurementsState, scenarioId]: [MeasurementsState, number] =
      yield select(selectMeasurementsStateAndCurrentProjectAndScenarioId);
    const { cachedMeasurementsValues, modelBrowser, rootIds } = measurementsState;
    if (measurementsState.hasChanges) {
      const changes = MeasurementsUtils.getRevertChangesForm(cachedMeasurementsValues, modelBrowser, rootIds);
      yield call(MeasurementsApi.bulkUpdateMeasurement, scenarioId, changes);
    }
    yield put(MeasurementsActions.cancelEditSuccess());
  } catch (error) {
    console.error('measurement: cancel failed', error);
  }
}

export function* measurementsSaga(): SagaIterator {
  yield takeLatest(MeasurementsActionTypes.LOAD_DATA_REQUEST, getData);
  yield takeLatest(MeasurementsActionTypes.GET_STATISTIC_REQUEST, getStatistic);
  yield takeLatest(MeasurementsActionTypes.ON_BLUR_EDIT, onEditBlur);
  yield takeLatest(MeasurementsActionTypes.CANCEL_EDIT, cancel);
}
