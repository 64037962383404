import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './quantity-take-off-report-panel-controls.scss';

import { ElementTooltip, TooltipPosition } from 'common/components/element-tooltip';
import { ExpandCollapseControls } from 'common/components/expand-collapse-controls';
import { KreoToolbarButton } from 'common/components/kreo-toolbar/kreo-toolbar-button';
import { TitleEditable } from 'common/components/title-editable';
import { VideoUrl } from 'common/constants/video-url';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import {
  KreoDialogActions,
  KreoIconDiff,
  KreoIconPivot,
  MaterialMenuItem,
  MaterialSelect,
} from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { HelpIconDialog } from '../../../../components/help-icon-dialog';
import { HELP_VIDEO_DIALOG_NAME } from '../../../analytics/components/help-center';
import { QuantityTakeOffReportActions } from '../../actions/creators/quantity-take-off-report';
import { QuantityTakeOffTemplateActions } from '../../actions/creators/quantity-take-off-template';
import { QtoGenerateReportStatus } from '../../enums/qto-generate-report-status';
import { ModelType, QtoTemplateInfo } from '../../interfaces/quantity-take-off';
import { QtoProjectReport } from '../../interfaces/quantity-take-off/quantity-take-off-project-reports';
import { QtoReportForm } from '../../interfaces/quantity-take-off/quantity-take-off-report-form';
import { QtoReportSetting } from '../../interfaces/quantity-take-off/quantity-take-off-report-generator';
import { QtoReportInfo } from '../../interfaces/quantity-take-off/quantity-take-off-report-info';
import { QtoReportInfoForm } from '../../interfaces/quantity-take-off/quantity-take-off-report-info-form';
import { getEmptyModel } from '../../saga/quantity-take-off-reports';
import { QtoAddReportMenu } from './quantity-take-off-add-report-menu';
import { QtoReportPanelApproximateReport } from './quantity-take-off-report-panel-approximate-report';


const DELETE_REPORT_CONFIRMATION_DIALOG_NAME = 'DELETE_REPORT_CONFIRMATION_DIALOG_NAME';
const HELP_CREATE_REPORT_DIALOG_NAME = 'HELP_REPORT_VIDEO_DIALOG_NAME';

interface ReduxProps {
  selectedReportId: number | null;
  loadReportsInfoStatus: RequestStatus;
  reportsInfo: QtoReportInfo[];
  reportGenerators: QtoReportSetting[];
  reportsForCopy: QtoProjectReport[];
  disableShowDialogList: string[];
  showDiff: boolean;
  showPivot: boolean;
  isSync: boolean;
  templatesForCreateReport: QtoTemplateInfo[];
  reportCreationStatus: QtoGenerateReportStatus;
}

interface DispatchProps {
  loadReportsInfo: () => void;
  loadAvailableReportGenerators: () => void;
  generateDefaultReport: (type: string) => void;
  createReport: (model: QtoReportForm) => void;
  publishReport: (reportId: number) => void;
  exportReportToExcel: (reportId: number) => void;
  setSelectedReportId: (id: number) => void;
  deleteReport: (reportId: number) => void;
  updateReportInfo: (reportId: number, form: QtoReportInfoForm) => void;
  openDialog: (name: string) => void;
  loadAvailableReportForCopy: () => void;
  onReportCopy: (projectId: number, reportId: number) => void;
  loadInitialState: () => void;
  onCreateReportFromTemplate: (templateId: number) => void;
}

interface OwnProps {
  isOnlyGenerateReport?: boolean;
  onReportExport: () => void;
  projectId: number;
  modelType: ModelType;
  pivot: boolean;
  pivotChanged: (value: boolean) => void;
  collapseAll: () => void;
  expandAll: () => void;
}

interface Props extends OwnProps, DispatchProps, ReduxProps {
}
class QtoReportPanelControlsComponent extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.loadInitialState();
    this.props.loadAvailableReportGenerators();
    this.props.loadAvailableReportForCopy();
    if (!this.props.disableShowDialogList) {
      this.props.openDialog(HELP_VIDEO_DIALOG_NAME);
    }
  }

  public render(): JSX.Element {
    const {
      reportsInfo,
      selectedReportId,
      generateDefaultReport,
      reportGenerators,
      reportsForCopy,
      onReportCopy,
      projectId,
      modelType,
      pivot,
      showDiff,
      isSync,
      templatesForCreateReport,
      reportCreationStatus,
      onCreateReportFromTemplate,
      loadAvailableReportForCopy,
    } = this.props;
    const selectedReportInfo = reportsInfo.find(x => x.id === selectedReportId);

    return (
      <div className='quantity-take-off-report-panel-controls'>
        <div className='quantity-take-off-report-panel-controls__container-left'>
          <ElementTooltip
            size='medium'
            tooltipPosition={TooltipPosition.CenterTop}
            className={classNames(
              'quantity-take-off-report-panel-controls__report-menu',
              { 'quantity-take-off-report-panel-controls__report-menu--disabled': !selectedReportInfo },
            )}
            targetElement={
              <ItemMenu
                buttonSize='small'
                menu={this.getReportMenuItems(selectedReportInfo)}
              />
            }
            disabled={!!selectedReportInfo}
          >
            You have to select a report firstly
          </ElementTooltip>
          <ExpandCollapseControls
            className='quantity-take-off-report-panel-controls__expand-collapse-controls'
            onExpand={this.props.expandAll}
            onCollapse={this.props.collapseAll}
          />
          <KreoToolbarButton
            controlName={'diff-toggle'}
            className='quantity-take-off-report-panel-controls__diff-toggle'
            active={showDiff}
            defaultTooltipText={`${showDiff ? 'Hide' : 'Show'} the difference in the reports' copies`}
            onClick={this.toggleShowDiff}
          >
            <KreoIconDiff />
          </KreoToolbarButton>
          { this.props.showPivot &&
            <KreoToolbarButton
              className='quantity-take-off-report-panel-controls__pivot-toggle'
              onClick={this.changePivotMode}
              controlName='pivot-mode-toggle'
              active={pivot}
              defaultTooltipText={`Pivot mode ${pivot ? 'on' : 'off'}`}
              disabled={!isSync}
              disabledTooltipText='Wait for changes saving'
            >
              <KreoIconPivot />
            </KreoToolbarButton>
          }
          <div className='quantity-take-off-report-panel-controls__report-name'>
            {
              selectedReportInfo ? (
                <ElementTooltip
                  size='small'
                  tooltipPosition={TooltipPosition.CenterTop}
                  className='quantity-take-off-report-panel-controls__report-title'
                  targetElement={
                    <TitleEditable
                      isEditByIconClick={true}
                      canEdit={true}
                      onChange={this.renameReport}
                      text={selectedReportInfo.name}
                      label='Report Name'
                    />
                  }
                >
                  <b className='quantity-take-off-report-panel-controls__tooltip-title-name'>
                    {selectedReportInfo.name}
                  </b>
                  {
                    selectedReportInfo.description &&
                    <div className='quantity-take-off-report-panel-controls__tooltip-title-description'>
                      {selectedReportInfo.description}
                    </div>
                  }
                </ElementTooltip>
              ) : (
                  <span className='quantity-take-off-report-panel-controls__report-label'>
                    Please, Select Report
                  </span>
              )
            }
          </div>
        </div>
        <div className='quantity-take-off-report-panel-controls__container-right'>
          <QtoReportPanelApproximateReport projectId={projectId} modelType={modelType}/>
          <QtoAddReportMenu
            isOnlyGenerateReport={this.props.isOnlyGenerateReport}
            defaultReportNames={reportGenerators}
            reportsForCopy={reportsForCopy}
            onGenerateDefaultReport={generateDefaultReport}
            onNewReportCreate={this.createReport}
            onReportCopy={onReportCopy}
            className='quantity-take-off-report-panel-controls__create-button'
            templatesForCreate={templatesForCreateReport}
            onCreateViaTemplate={onCreateReportFromTemplate}
            onClickButton={loadAvailableReportForCopy}
            reportCreationStatus={reportCreationStatus}
          />
          <HelpIconDialog
            className='quantity-take-off-report-panel-controls__help-icon'
            video={true}
            url={VideoUrl.QtoCreateReport}
            dialogName={HELP_CREATE_REPORT_DIALOG_NAME}
            title='Create Report Help'
          />
          <MaterialSelect
            value={selectedReportId}
            className='quantity-take-off-report-panel-controls__material-select'
            dropdownClassName='quantity-take-off-report-panel-controls__material-paper'
            onChange={this.selectReport}
            noDataText='No Reports'
            placeholder={reportsInfo && reportsInfo.length ? 'Select Report' : 'Create New Report'}
            displayedType={MaterialComponentType.Native}
            autoWidth={true}
          >
            {reportsInfo &&
              reportsInfo.map(report => {
                return (
                  <MaterialMenuItem
                    key={report.id}
                    value={report.id}
                  >
                    <ElementTooltip
                      size='small'
                      tooltipPosition={TooltipPosition.CenterTop}
                      className='quantity-take-off-report-panel-controls__material-select-item'
                      tooltipClassName='quantity-take-off-report-panel-controls__material-select-tooltip'
                      targetElement={report.name}
                    >
                      <b className='quantity-take-off-report-panel-controls__tooltip-title-name'>
                        {report.name}
                      </b>
                      {
                        report.description &&
                        <div className='quantity-take-off-report-panel-controls__tooltip-title-description'>
                          {report.description}
                        </div>
                      }
                    </ElementTooltip>
                  </MaterialMenuItem>
                );
              })}
          </MaterialSelect>
        </div>
        <KreoConfirmationDialog
          name={DELETE_REPORT_CONFIRMATION_DIALOG_NAME}
          onYes={this.deleteReport}
          yesText='Remove'
          noText='Cancel'
          title='Remove Report'
        >
          Are you sure you want to remove <b>{selectedReportInfo ? selectedReportInfo.name : ''}</b> report?
        </KreoConfirmationDialog>
      </div>
    );
  }

  private getReportMenuItems(selectedReportInfo: QtoReportInfo): ItemMenuType[] {
    if (selectedReportInfo == null) {
      return null;
    }

    const reportMenu = [
      {
        name: 'Export To Excel',
        action: this.exportToExcel,
      },
      {
        name: 'Remove',
        action: this.openRemoveReportDialog,
      },
    ];

    if (this.props.modelType === ModelType.CostEstimate && selectedReportInfo.isPublishable) {
      reportMenu.push({
        name: 'Publish',
        action: this.publishReport,
      });
    }

    return reportMenu;
  }

  @autobind
  private changePivotMode(): void {
    this.props.pivotChanged(!this.props.pivot);
  }

  @autobind
  private openRemoveReportDialog(): void {
    this.props.openDialog(DELETE_REPORT_CONFIRMATION_DIALOG_NAME);
  }

  @autobind
  private publishReport(): void {
    this.props.publishReport(this.props.selectedReportId);
  }

  @autobind
  private deleteReport(): void {
    this.props.deleteReport(this.props.selectedReportId);
  }

  @autobind
  private exportToExcel(): void {
    this.props.onReportExport();
    this.props.exportReportToExcel(this.props.selectedReportId);
  }


  @autobind
  private toggleShowDiff(): void {
    const { selectedReportId, updateReportInfo, showDiff, reportsInfo } = this.props;
    const selectedReportInfo = reportsInfo.find(x => x.id === selectedReportId);
    updateReportInfo(selectedReportId, { name: selectedReportInfo.name, showDiff: !showDiff });
  }


  @autobind
  private renameReport(name: string): void {
    const { selectedReportId, updateReportInfo, showDiff } = this.props;
    updateReportInfo(selectedReportId, { name, showDiff });
  }

  @autobind
  private createReport(): void {
    const model = getEmptyModel('New Report');
    this.props.createReport(model);
  }

  @autobind
  private selectReport(_: React.SyntheticEvent, reportId: number): void {
    if (reportId !== this.props.selectedReportId) {
      this.props.setSelectedReportId(reportId);
    }
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const reportState = state.quantityTakeOff.report;
  return {
    loadReportsInfoStatus: reportState.statuses.loadReportsInfo,
    reportsInfo: reportState.reportsInfo,
    selectedReportId: reportState.selectedReportId,
    reportGenerators: reportState.availableReportGenerators,
    reportsForCopy: reportState.reportsForCopy,
    disableShowDialogList: state.persistedStorage.disableShowDialogList,
    showDiff: reportState.reportModel && reportState.reportModel.showDiff,
    showPivot: reportState.reportModel && reportState.reportModel.showPivot,
    isSync: reportState.isSync,
    templatesForCreateReport: state.quantityTakeOff.template.templatesInfo,
    reportCreationStatus: state.quantityTakeOff.report.reportCreationStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  const { projectId, modelType } = props;

  return {
    loadReportsInfo: () => dispatch(QuantityTakeOffReportActions.loadReportsInfo(projectId, modelType)),
    loadInitialState: () => dispatch(QuantityTakeOffReportActions.loadInitialState(projectId, modelType)),
    createReport: (model) => dispatch(QuantityTakeOffReportActions.createReport(projectId, modelType, model)),
    publishReport: (id) => dispatch(QuantityTakeOffReportActions.publishReport(projectId, modelType, id)),
    exportReportToExcel: (id) =>
      dispatch(QuantityTakeOffReportActions.exportReportToExcel(projectId, modelType, id)),
    updateReportInfo: (id, form) =>
      dispatch(QuantityTakeOffReportActions.updateReportInfo(projectId, modelType, id, form)),
    setSelectedReportId: (id) => dispatch(QuantityTakeOffReportActions.setSelectedReportId(id)),
    deleteReport: (id) => dispatch(QuantityTakeOffReportActions.deleteReport(projectId, modelType, id)),
    generateDefaultReport: (type) =>
      dispatch(QuantityTakeOffReportActions.generateDefaultReport(projectId, modelType, type)),
    loadAvailableReportGenerators: () =>
      dispatch(QuantityTakeOffReportActions.loadAvailableReportGenerators(projectId, modelType)),
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
    loadAvailableReportForCopy: () =>
      dispatch(QuantityTakeOffReportActions.loadAvailableReportForCopy(modelType)),
    onReportCopy: (projId: number, reportId: number) => dispatch(
      QuantityTakeOffReportActions.copyReport(projId, modelType, reportId, projectId),
    ),
    onCreateReportFromTemplate: (templateId) =>
      dispatch(QuantityTakeOffTemplateActions.createReportFromTemplate(projectId, modelType, templateId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const QtoReportPanelControls = connector(QtoReportPanelControlsComponent);
