export enum Arrangement {
  SingleView = 'SingleView',
  TwoViewsStacked = 'TwoViewsStacked',
  TwoViewsSideBySide = 'TwoViewsSideBySide',
  ThreeViewsTopSplit = 'ThreeViewsTopSplit',
  ThreeViewsBottomSplit = 'ThreeViewsBottomSplit',
  ThreeViewsLeftSplit = 'ThreeViewsLeftSplit',
  ThreeViewsRightSplit = 'ThreeViewsRightSplit',
  FourViews = 'FourViews',
  FourViewsTopSplit = 'FourViewsTopSplit',
  FourViewsBottomSplit = 'FourViewsBottomSplit',
  FourViewsLeftSplit = 'FourViewsLeftSplit',
  FourViewsRightSplit = 'FourViewsRightSplit',
  FourViewsStacked = 'FourViewsStacked',
  FourViewsSideBySide = 'FourViewsSideBySide',
}
