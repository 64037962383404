import { InputSelect, Text } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { ACCOUNT_INITIAL_STATE } from '../../../../reducers/account';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';
import { Currency } from '../../constants';
import { Styled } from './styled';

interface StateProps {
  selectCurrency: string;
  currencyRates: Record<string, number>;
}

interface DispatchProps {
  setSelectedCurrency: (currency: string) => void;
}

interface Props extends StateProps, DispatchProps {}

const CurrencySelectorComponent = ({ selectCurrency, setSelectedCurrency, currencyRates }: Props): JSX.Element => {
  const currency = useMemo(() => {
    return Object.keys(currencyRates).map(c => `${Currency[c].symbol}, ${Currency[c].name}`);
  }, []);
  const currencyShortcats = useMemo(() => Object.keys(currencyRates), []);
  const activeElementIndex = useMemo(() => currencyShortcats.indexOf(selectCurrency), [
    selectCurrency,
  ]);
  const handleClick = useCallback(
    (index: number) => setSelectedCurrency(currencyShortcats[index]),
    [],
  );
  return (<></>);

  return (
    <Styled.Container>
      <Text fontSize={12} color='mainFont'>Currency:</Text>
      <InputSelect
        elements={currency}
        activeElementIndex={activeElementIndex}
        closeAfterClick={true}
        onClick={handleClick}
      />
    </Styled.Container>
  );
};

const mapStateToProps = (state: State): StateProps => {
  const { persistedStorage: { selectedCurrency }, account: { currencyRates } } = state;
  const showCurrency = selectedCurrency && currencyRates && currencyRates[selectedCurrency]
    ? selectedCurrency
    : Currency.GBP.shortcat;
  return {
    selectCurrency: showCurrency,
    currencyRates: state.account.currencyRates || ACCOUNT_INITIAL_STATE.currencyRates,
  };
};

const dispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    setSelectedCurrency: currency =>
      dispatch(PersistedStorageActions.setSelectedCurrency(currency)),
  };
};

export const CurrencySelector = connect(
  mapStateToProps,
  dispatchToProps,
)(CurrencySelectorComponent);
