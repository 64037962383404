import styled from 'styled-components';

const MultiValuesContainer = styled.div`
  svg{
    width: 10px;
    height: 10px;
    fill: ${p => p.theme.color.gray};
  }
`;

const MeasureValueContainer = styled.div`
`;

export const Styled = {
  MultiValuesContainer,
  MeasureValueContainer,
};
