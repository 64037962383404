import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { DrawingHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { HotkeysUtils } from 'common/hotkeys';
import { useCloseAfterAction } from '../../hooks';
import { DrawingsBooleanOperationButton } from './drawings-boolean-operation-button';
import { Styled } from './styled';

interface Props {
  unite: () => void;
  subtract: () => void;
  joinCount: () => void;
  joinPolyline: () => void;
  openPolygon: () => void;
  doActionAndClose: (action: (...args: any) => void, ...args: any) => void;
  booleanOperationEnabled: boolean;
  joinCountEnabled: boolean;
  joinPolylineEnabled: boolean;
  openPolygonEnabled: boolean;
  encloseEnabled: boolean;
  enclose: () => void;
  splitPolyline: () => void;
}


export const DrawingsBooleanOperationPanel: React.FC<Props> = ({
  joinCount,
  joinPolyline,
  joinCountEnabled,
  joinPolylineEnabled,
  booleanOperationEnabled,
  encloseEnabled,
  unite,
  subtract,
  enclose,
  doActionAndClose,
  openPolygonEnabled,
  openPolygon,
  splitPolyline,
}) => {
  const uniteCallback = useCloseAfterAction(doActionAndClose, unite);
  const subtractCallback = useCloseAfterAction(doActionAndClose, subtract);
  const encloseCallback = useCloseAfterAction(doActionAndClose, enclose);
  const joinCountCallback = useCloseAfterAction(doActionAndClose, joinCount);
  const joinPolylineCallback = useCloseAfterAction(doActionAndClose, joinPolyline);
  const openPolygonCallback = useCloseAfterAction(doActionAndClose, openPolygon);
  const splitPolylineCallback = useCloseAfterAction(doActionAndClose, splitPolyline);

  if (
    !joinCountEnabled
    && !booleanOperationEnabled
    && !joinPolylineEnabled
    && !encloseEnabled
    && !openPolygonEnabled
  ) {
    return null;
  }
  return (
    <Styled.Container>
      <Styled.ButtonsContainer>
        { booleanOperationEnabled && (
          <>
            <DrawingsBooleanOperationButton
              onClick={uniteCallback}
              title='Merge'
              iconType={Icons.Union}
              description='Use the option to merge two or more polygons together'
            />
            <DrawingsBooleanOperationButton
              onClick={subtractCallback}
              title='Subtract'
              iconType={Icons.Subtract}
              hotkey={HotkeysUtils.stringifyHotkeysList(DrawingHotkey.Subtract)}
              description=''
            />
          </>
        )}
        {
          openPolygonEnabled && (
            <DrawingsBooleanOperationButton
              onClick={openPolygonCallback}
              title='Extract polyline from polygon'
              iconType={Icons.ConvertToPolylines}
              description='Use the option to convert polygon to polyline'
            />
          )
        }
        {
          joinCountEnabled && (
            <DrawingsBooleanOperationButton
              onClick={joinCountCallback}
              title='Join Counts'
              iconType={Icons.CountJoin}
              description='Use the option to merge two or more counts together'
            />
          )
        }
        {
          joinPolylineEnabled && (
            <DrawingsBooleanOperationButton
              onClick={joinPolylineCallback}
              title='Join Polyline'
              iconType={Icons.JoinPath}
              description='Use the option to merge two or more lines together'
            />
          )
        }
        {
          encloseEnabled && (
            <>
              <DrawingsBooleanOperationButton
                onClick={encloseCallback}
                title='Enclose'
                iconType={Icons.Enclose}
                description='Use the option to close polylines'
              />
              <DrawingsBooleanOperationButton
                onClick={splitPolylineCallback}
                title='Split polyline into pegments'
                iconType={Icons.PolylineToSegments}
                description='Use the option to split polyline into segments'
              />
            </>
          )
        }
      </Styled.ButtonsContainer>
    </Styled.Container>
  );
};
