import { NumberDictionary } from 'common/interfaces/dictionary';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { numberUtils } from 'common/utils/number-utils';
import { UnitUtil } from 'common/utils/unit-util';
import {
  MeasurementsLoadDataSuccessPayload,
  MeasurementsOnChangeExtractorValuePayload,
  MeasurementsOnChangeGeneraralValuePayload,
  MeasurementsToggleItemSelectStatusPayload,
} from '../actions/payloads/measurements';
import { ValidationStepStatisticDataPayload } from '../actions/payloads/validation';
import { MeasurementsActionTypes } from '../actions/types/measurements';
import { MeasurementsConstants } from '../constants/measurements';
import { MEASUREMENT_INITIAL_STATE } from '../constants/measurements-initial-state';
import { MeasurementsExtractorEditorRowType } from '../enums/measurements-extractor-editor-row-type';
import { MeasurementsValueKey } from '../enums/measurements-value-key';
import { RevitTreeLevel } from '../enums/revit-tree-level';
import { MeasurementRevitTreeNodeData } from '../interfaces/measurements/measurement-revit-tree-node-data';
import { MeasurementValue, MeasurementValueGroup } from '../interfaces/measurements/measurement-value';
import { MeasurementsActivityExtractor } from '../interfaces/measurements/measurements-activity-extractor';
import { MeasurementsState } from '../interfaces/measurements/measurements-state';
import { RevitGroupNode } from '../interfaces/revit-group-node';
import { MeasurementsUtils } from '../utils/measurements-utils';
import { mapStatisticRequest } from '../utils/step-statistic-request-mapper';

function saveData(
  state: MeasurementsState,
  payload: MeasurementsLoadDataSuccessPayload,
): MeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  return stateModifier
    .set(s => s.extractors, payload.extractors)
    .set(s => s.badIds, payload.badIds)
    .set(s => s.dataLoaded, true)
    .set(s => s.engineIds, payload.engineIds)
    .set(s => s.rootIds, payload.rootIds)
    .set(s => s.modelBrowser, payload.list)
    .set(s => s.layerIds, payload.layerIds)
    .set(s => s.errorLinks, payload.errorNodes)
    .get();
}

function toggleGeneralSelectStatus(state: MeasurementsState, payload: boolean): MeasurementsState {
  const elementSelectStatuses: NumberDictionary<boolean> = {};
  if (payload) {
    for (let i = 0; i < state.extractorEditorRows.length; i++) {
      elementSelectStatuses[i] = true;
    }
  }
  return new MonoliteHelper(state)
    .set(_ => _.elementSelectStatuses, elementSelectStatuses)
    .set(_ => _.isGeneralSelected, payload)
    .get();
}


function disabledItemSelectStatus(state: MeasurementsState, itemIndex: number): MeasurementsState {
  return new MonoliteHelper(state)
    .set(_ => _.elementSelectStatuses, {})
    .set(_ => _.elementSelectStatuses[itemIndex], true)
    .get();
}

function toggleItemSelectStatus(
  state: MeasurementsState,
  { itemIndex, value }: MeasurementsToggleItemSelectStatusPayload,
): MeasurementsState {
  const helper = new MonoliteHelper(state);
  if (state.isGeneralSelected && !value) {
    helper.set(_ => _.isGeneralSelected, false);
  }
  if (state.extractorEditorRows[itemIndex].type === MeasurementsExtractorEditorRowType.GroupHeader) {
    for (let i = itemIndex + 1; i < state.extractorEditorRows.length; i++) {
      if (state.extractorEditorRows[i].type === MeasurementsExtractorEditorRowType.GroupHeader) {
        break;
      }
      if (state.elementSelectStatuses[i] !== value) {
        helper.set(_ => _.elementSelectStatuses[i], value);
      }
    }
  }
  return helper.set(_ => _.elementSelectStatuses[itemIndex], value).get();
}

function changeErrorCount(
  state: MeasurementsState,
): MeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  const newErrorCountMap: Record<number, number> = {};
  for (const [i, row] of state.extractorEditorRows.entries()) {
    if (row.type !== MeasurementsExtractorEditorRowType.Activity) {
      continue;
    }

    let hasError = false;
    const { values, isError } = row.data;
    Object.values(values).forEach(
      extractorValue => Object.entries(extractorValue.valuesMap)
        .forEach(([leafNodeId, value]) => {
          newErrorCountMap[leafNodeId] = newErrorCountMap[leafNodeId] || 0;
          if (MeasurementsUtils.isMeasurementBad(value)) {
            newErrorCountMap[leafNodeId]++;
          }
          hasError = hasError || newErrorCountMap[leafNodeId];
        }));

    if (isError !== hasError) {
      stateModifier.set(_ => _.extractorEditorRows[i].data.isError, hasError);
    }
  }

  for (const [leafNodeId, count] of Object.entries(newErrorCountMap)) {
    modifyErrorCount(stateModifier, state, +leafNodeId, count);
  }

  return stateModifier.get();
}

/**
 * modify error counts in state modifier
 * @param stateModifier
 * @param state
 * @param leafNodeId
 * @param newErrorCount
 */
function modifyErrorCount(
  stateModifier: MonoliteHelper<MeasurementsState>,
  state: MeasurementsState,
  leafNodeId: number,
  newErrorCount: number,
): void {
  const countDifference = (state.errorLinks[leafNodeId] || 0) - newErrorCount;
  stateModifier.set(s => s.errorLinks[leafNodeId], newErrorCount);
  let itemIndex = state.modelBrowser[leafNodeId].parentId;
  let item = state.modelBrowser[itemIndex];
  while (item.parentId !== undefined && item.parentId !== null) {
    item = state.modelBrowser[itemIndex];
    stateModifier.set(s => s.errorLinks[itemIndex], value => (value || 0) - countDifference);
    itemIndex = item.parentId;
  }
}

function pushChanges(
  changes: Record<number, MeasurementValue[]>,
  valueGroup: MeasurementValueGroup,
  value: number,
): void {
  Object.entries(valueGroup.valuesMap).forEach(
    ([leafNodeId, measurementValue]) => {
      changes[leafNodeId] = changes[leafNodeId] || [];
      changes[leafNodeId].push({
        variantIds: measurementValue.variantIds,
        value,
      });
    });
}

function iterateLeafNodeIndexMap(leafNodeIndexMap: Record<number, number>, group: MeasurementValueGroup): void {
  Object.keys(group.valuesMap).forEach(x => leafNodeIndexMap[x] = x in leafNodeIndexMap ? leafNodeIndexMap[x] + 1 : 0);
}

function setMeasurementValue(
  state: MeasurementsState,
  helper: MonoliteHelper<MeasurementsState>,
  leafNodeIndexMap: Record<number, number>,
  rowIndex: number,
  extractorId: string,
  value: any,
): MonoliteHelper<MeasurementsState> {
  const row = state.extractorEditorRows[rowIndex];

  helper.set(_ => _.extractorEditorRows[rowIndex].data.values[extractorId].commonValue, value);
  for (const leafNodeId of Object.keys(row.data.values[extractorId].valuesMap)) {
    const nodeIndex = leafNodeIndexMap[leafNodeId];
    helper
      .set(_ => _.extractors[leafNodeId].data.activities[nodeIndex].measurements[extractorId].value, value)
      .set(_ => _.extractorEditorRows[rowIndex].data.values[extractorId].valuesMap[leafNodeId].value, value);
  }

  return helper;
}

function onChangeGeneralInputValue(
  state: MeasurementsState,
  { value, extractorId, generalExtractorId }: MeasurementsOnChangeGeneraralValuePayload,
): MeasurementsState {
  const helper = new MonoliteHelper(state);
  const template = state.extractorFunctions[generalExtractorId];
  let currentTotalValue = state.generalExtractorEditors[extractorId].totalValue;
  const totalValues = { ...state.generalExtractorEditors };
  const changes: Record<number, MeasurementValue[]> = {};
  const leafNodeIndexMap = {};
  for (let i = 0; i < state.extractorEditorRows.length; i++) {
    const row = state.extractorEditorRows[i];
    if (row.type === MeasurementsExtractorEditorRowType.GroupHeader) {
      continue;
    }


    const isSelected = state.elementSelectStatuses[i];
    const { values } = row.data;
    const measurement = values[extractorId];
    if (!measurement) {
      continue;
    }

    iterateLeafNodeIndexMap(leafNodeIndexMap, measurement);

    const { elementExtractorValue, numberValue } = convertMeasurementValues(value, measurement);
    if (!isSelected || elementExtractorValue === numberValue) {
      continue;
    }

    const engineId = row.data.engineId;
    const volume = template.relationParameters
      && template.relationParameters[engineId]
      && template.relationParameters[engineId][MeasurementsConstants.reinforcementVolumeKey];

    if (volume) {
      const generalExtractor = template.extractors.find(x => x.extractorFunctionId === template.generalFunctionId);
      const secondaryExtractor = template.extractors.find(x => x.extractorFunctionId !== template.generalFunctionId);
      const secondaryExtractorId = secondaryExtractor.extractorFunctionId;
      if (generalExtractor.extractorFunctionKey === MeasurementsValueKey.ReinforcementMass) {
        const changedFunction = values[generalExtractorId];


        let massValue: string;
        let concentrationValue: string;
        if (extractorId === generalExtractor.extractorFunctionId) {
          concentrationValue = UnitUtil.round(numberValue / volume, secondaryExtractor.unitName).toString();
          totalValues[generalExtractorId].totalValue += numberValue - elementExtractorValue;
          pushChanges(changes, changedFunction, numberValue);
          massValue = value;
        } else {
          const mass = numberValue * volume;
          pushChanges(changes, changedFunction, mass);
          const massSourceValue = numberUtils.getNumeralFormatter(changedFunction.commonValue).value();
          totalValues[generalExtractorId].totalValue += mass - massSourceValue;
          massValue = UnitUtil.round(mass, generalExtractor.unitName).toString();
          concentrationValue = value;
        }

        setMeasurementValue(state, helper, leafNodeIndexMap, i, generalExtractorId, massValue);
        setMeasurementValue(state, helper, leafNodeIndexMap, i, secondaryExtractorId, concentrationValue);
        helper.set(_ => _.generalExtractorEditors, totalValues);
      }
    } else {
      pushChanges(changes, measurement, numberValue);
      currentTotalValue += numberValue - elementExtractorValue;
      setMeasurementValue(state, helper, leafNodeIndexMap, i, extractorId, numberValue);
      helper.set(_ => _.generalExtractorEditors[extractorId].totalValue, currentTotalValue);
    }
  }

  Object.keys(totalValues).forEach(x => helper.set(_ => _.generalExtractorEditors[x].value, ''));
  helper.set(_ => _.generalExtractorEditors[extractorId].value, value);
  state = helper
    .set(_ => _.uncommitedChanges, changes)
    .set(_ => _.hasChanges, true)
    .get();

  return changeErrorCount(state);
}

interface ConveredValues {
  elementExtractorValue: number;
  numberValue: number;
}

function convertMeasurementValues(value: string, measurement: MeasurementValueGroup): ConveredValues {
  const numberValue = value === '' || value === null
    ? 0
    : numberUtils.getNumeralFormatter(value.replace(/,/, '.')).value();
  const formattedValue = measurement.commonValue ? measurement.commonValue.toString().replace(/,/, '.') : '';
  const elementExtractorValue = numberUtils.getNumeralFormatter(formattedValue).value();
  return { elementExtractorValue, numberValue };
}

function onChangeInputValue(
  state: MeasurementsState,
  { elementIndex, value, extractorId, generalExtractorId }: MeasurementsOnChangeExtractorValuePayload,
): MeasurementsState {
  const helper = new MonoliteHelper(state);
  const changes: Record<number, MeasurementValue[]> = {};
  const template = state.extractorFunctions[generalExtractorId];
  const row = state.extractorEditorRows[elementIndex];
  const values = row.data.values;
  const measurement = row.data.values[extractorId];
  const { elementExtractorValue, numberValue } = convertMeasurementValues(value, measurement);
  let deltaValue = 0;

  const leafNodeIndexMap = {};
  for (let i = 0; i <= elementIndex; i++) {
    if (state.extractorEditorRows[i].type === MeasurementsExtractorEditorRowType.Activity) {
      iterateLeafNodeIndexMap(leafNodeIndexMap, state.extractorEditorRows[i].data.values[extractorId]);
    }
  }


  const engineId = row.data.engineId;
  const volume = template.relationParameters
    && template.relationParameters[engineId]
    && template.relationParameters[engineId][MeasurementsConstants.reinforcementVolumeKey];
  if (volume) {
    const generalExtractor = template.extractors.find(x => x.extractorFunctionId === template.generalFunctionId);
    const secondaryExtractor = template.extractors.find(x => x.extractorFunctionId !== template.generalFunctionId);
    const secondaryExtractorId = secondaryExtractor.extractorFunctionId;

    if (generalExtractor.extractorFunctionKey === MeasurementsValueKey.ReinforcementMass) {
      const changedFunction = values[generalExtractorId];
      let concentrationValue: string;
      let massValue: string;
      if (extractorId === generalExtractor.extractorFunctionId) {
        massValue = value;
        concentrationValue = UnitUtil.round(numberValue / volume, secondaryExtractor.unitName).toString();
        deltaValue =  numberValue - elementExtractorValue;
        pushChanges(changes, changedFunction, numberValue);
      } else {
        const mass = numberValue * volume;
        const massRounded = UnitUtil.round(mass, generalExtractor.unitName);
        const massSourceValue = numberUtils.getNumeralFormatter(changedFunction.commonValue).value();
        deltaValue = massRounded - massSourceValue;
        massValue =  massRounded.toString();
        concentrationValue = value;
        pushChanges(changes, changedFunction, mass);
      }

      setMeasurementValue(state, helper, leafNodeIndexMap, elementIndex, generalExtractorId, massValue);
      setMeasurementValue(state, helper, leafNodeIndexMap, elementIndex, secondaryExtractorId, concentrationValue);

      helper.set(_ => _.uncommitedChanges, changes);
    }
  } else {
    deltaValue = numberValue - elementExtractorValue;
    pushChanges(changes, measurement, numberValue);
    helper.set(_ => _.uncommitedChanges, changes);
    setMeasurementValue(state, helper, leafNodeIndexMap, elementIndex, extractorId, value);
  }

  const oldTotalValue = state.generalExtractorEditors[extractorId].totalValue;
  const totalValue = oldTotalValue + deltaValue;
  state = helper
    .set(_ => _.generalExtractorEditors[extractorId].totalValue, totalValue)
    .set(_ => _.hasChanges, true)
    .get();
  return changeErrorCount(state);
}

function cancelEdit(
  state: MeasurementsState,
): MeasurementsState {
  if (state.hasChanges) {
    const helper = new MonoliteHelper(state);
    for (const cachedValue of state.cachedMeasurementsValues) {
      for (const [extractorId, measurements] of Object.entries(cachedValue)) {
        const leafNodeIdMap = {};
        for (const [leafNodeId, value] of Object.entries(measurements.valuesMap)) {
          const index = leafNodeId in leafNodeIdMap ? leafNodeIdMap[leafNodeId] + 1 : 0;
          leafNodeIdMap[leafNodeId] = index;
          helper.set(_ => _.extractors[leafNodeId].data.activities[index].measurements[extractorId], value);
        }
      }
    }
    helper
      .set(_ => _.errorLinks, state.cachedErrorLinks)
      .set(_ => _.cachedMeasurementsValues, [])
      .set(_ => _.cachedErrorLinks, [])
      .set(_ => _.hasChanges, false);
    return dropSelection(helper.get());
  } else {
    return dropSelection(state);
  }
}

function commitChangesSuccess(state: MeasurementsState): MeasurementsState {
  return new MonoliteHelper(state).set(_ => _.uncommitedChanges, []).get();
}

function getStatisticSuccess(
  state: MeasurementsState,
  payload: ValidationStepStatisticDataPayload,
): MeasurementsState {
  const { errorIds, statistic } = mapStatisticRequest(payload);
  return new MonoliteHelper(state)
    .set(_ => _.statisticLoaded, true)
    .set(_ => _.statistic, statistic)
    .set(_ => _.badIds, errorIds)
    .get();
}

function getStatisticRequest(state: MeasurementsState): MeasurementsState {
  return new MonoliteHelper(state).set(_ => _.statisticLoaded, false).get();
}

function dropSelection(state: MeasurementsState): MeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  return stateModifier
    .set(s => s.selectedNodeId, null)
    .set(s => s.extractorFunctions, {})
    .set(s => s.extractorEditorRows, [])
    .set(s => s.elementSelectStatuses, {})
    .set(s => s.showMeasurementEditor, false)
    .get();
}

function selectNode(state: MeasurementsState, payload: number): MeasurementsState {
  state = dropSelection(state);
  return new MonoliteHelper(state).set(_ => _.selectedNodeId, payload).get();
}


function selectExtractorById(state: MeasurementsState, payload: number): MeasurementsState {
  const selectedNodes = getSelectedExtractors(state, payload);
  const { templates, measurementData } = MeasurementsUtils.getExtractorFunctions(selectedNodes);
  const extractorEditorRows = MeasurementsUtils.getExtractorElements(measurementData);
  const currentMeasurementsValues = measurementData.map(x => x.measurements);
  return new MonoliteHelper(state)
    .set(_ => _.selectedNodeId, payload)
    .set(_ => _.extractorFunctions, templates)
    .set(_ => _.hasChanges, false)
    .set(_ => _.extractorEditorRows, extractorEditorRows)
    .set(_ => _.cachedMeasurementsValues, currentMeasurementsValues)
    .set(_ => _.cachedErrorLinks, state.errorLinks)
    .set(_ => _.showMeasurementEditor, true)
    .set(_ => _.elementSelectStatuses, {})
    .set(_ => _.centralEditorRowPosition, null)
    .set(
      _ => _.generalExtractorEditors,
      MeasurementsUtils.createGeneralExtractorEditors(templates, measurementData),
    )
    .get();
}

function expandById(state: MeasurementsState, payload: number): MeasurementsState {
  return new MonoliteHelper(state)
    .set(_ => _.modelBrowser[payload].isExpanded, !state.modelBrowser[payload].isExpanded)
    .get();
}

function collapseAllNodesMapFunction(
  node: RevitGroupNode<MeasurementRevitTreeNodeData>,
): RevitGroupNode<MeasurementRevitTreeNodeData> {
  if (!node.isExpanded) {
    return node;
  }
  return new MonoliteHelper(node).set(_ => _.isExpanded, false).get();
}

function collapseAllNodes(state: MeasurementsState): MeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  return stateModifier
    .setMap(_ => _.modelBrowser, collapseAllNodesMapFunction)
    .get();
}

function getSelectedExtractors(state: MeasurementsState, selectedNodeId: number): MeasurementsActivityExtractor[] {
  if (selectedNodeId in state.extractors) {
    return [state.extractors[selectedNodeId]];
  }

  const lastIndex = state.modelBrowser[selectedNodeId].lastChildrenIndex;
  const result = [];
  for (let index = selectedNodeId; index <= lastIndex; index ++) {
    if (index in state.extractors) {
      result.push(state.extractors[index]);
    }
  }

  return result;
}

function selectExtractor(state: MeasurementsState): MeasurementsState {
  const selectedExtractor = getSelectedExtractors(state, state.selectedNodeId);
  const { templates, measurementData } = MeasurementsUtils.getExtractorFunctions(selectedExtractor);
  const extractorEditorRows = MeasurementsUtils.getExtractorElements(measurementData);
  const currentMeasurementsValues = measurementData.map(x => x.measurements);

  return new MonoliteHelper(state)
    .set(_ => _.extractorFunctions, templates)
    .set(_ => _.showMeasurementEditor, true)
    .set(_ => _.elementSelectStatuses, {})
    .set(_ => _.extractorEditorRows, extractorEditorRows)
    .set(_ => _.cachedMeasurementsValues, currentMeasurementsValues)
    .set(_ => _.cachedErrorLinks, state.errorLinks)
    .set(_ => _.centralEditorRowPosition, null)
    .set(
      _ => _.generalExtractorEditors,
      MeasurementsUtils.createGeneralExtractorEditors(templates, measurementData),
    )
    .get();
}

function extractorSelectionByBimHandle(
  state: MeasurementsState,
  payload: number,
): MeasurementsState {
  const element = state.engineIds.find(x => x.id === payload);
  state = collapseAllNodes(state);
  const { measurement, id, type, family, category } = element;
  state = new MonoliteHelper(state).set(_ => _.selectedNodeId, measurement).get();
  state = selectExtractor(state);
  const indexOfRow = state.extractorEditorRows.findIndex(x =>
    x.type === MeasurementsExtractorEditorRowType.Activity && x.data.engineId === id);
  const stateModifier = new MonoliteHelper(state);
  if (indexOfRow !== -1) {
    stateModifier
      .set(_ => _.centralEditorRowPosition, indexOfRow)
      .set(_ => _.elementSelectStatuses[indexOfRow], true);
  }
  let itemPosition = 0;
  for (let i = 0; i < type; i++) {
    itemPosition++;
    switch (state.modelBrowser[i].level) {
      case RevitTreeLevel.Category: {
        if (i !== category) {
          i = state.modelBrowser[i].lastChildrenIndex;
          continue;
        }
        break;
      }
      case RevitTreeLevel.Family: {
        if (i !== family) {
          i = state.modelBrowser[i].lastChildrenIndex;
          continue;
        }
        break;
      }
      default:
    }
  }
  return stateModifier
    .set(_ => _.modelBrowser[type].isExpanded, true)
    .set(_ => _.modelBrowser[family].isExpanded, true)
    .set(_ => _.modelBrowser[category].isExpanded, true)
    .set(_ => _.centralTreeElement, itemPosition)
    .get();
}

function search(state: MeasurementsState, payload: string): MeasurementsState {
  state = collapseAllNodes(state);
  const stateModifier = new MonoliteHelper(state);
  if (payload) {
    const searchResult = MeasurementsUtils.search(state.modelBrowser, state.activityMeasurement, payload);
    searchResult.forEach(itemId => {
      let item = state.modelBrowser[itemId];
      while (item.parentId !== undefined && item.parentId !== null) {
        stateModifier.set(s => s.modelBrowser[item.parentId].isExpanded, true);
        item = state.modelBrowser[item.parentId];
      }
    });
    stateModifier.set(s => s.searchMode, true).set(s => s.searchedNodes, searchResult);
  } else {
    stateModifier.set(s => s.searchMode, false);
  }
  return stateModifier.get();
}

function showHideRevitTree(state: MeasurementsState): MeasurementsState {
  return new MonoliteHelper(state).set(_ => _.viewTree, !state.viewTree).get();
}

function toggleElementCheckStatusFromEngine(
  state: MeasurementsState,
  payload: number[],
): MeasurementsState {
  const idSet = new Set(payload);
  const helper = new MonoliteHelper(state);
  const elementSelectStatuses = {};
  let selectedRowPosition = null;
  for (let i = 0; i < state.extractorEditorRows.length; i++) {
    const row = state.extractorEditorRows[i];
    if (row.type === MeasurementsExtractorEditorRowType.Activity && idSet.has(row.data.engineId)) {
      elementSelectStatuses[i] = true;
      selectedRowPosition = i;
    }
  }
  return helper
    .set(_ => _.elementSelectStatuses, elementSelectStatuses)
    .set(_ => _.centralEditorRowPosition, selectedRowPosition)
    .get();
}

function dropStatistic(state: MeasurementsState): MeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  return stateModifier.set(s => s.statistic, []).get();
}

export const measurementReducerMethods: ReducerMethods<MeasurementsState> = {
  [MeasurementsActionTypes.LOAD_DATA_REQUEST]: state =>
    new MonoliteHelper(state).set(_ => _.dataLoaded, false).get(),
  [MeasurementsActionTypes.LOAD_DATA_SUCCESSED]: saveData,
  [MeasurementsActionTypes.DROP_STATE]: () => MEASUREMENT_INITIAL_STATE,
  [MeasurementsActionTypes.SHOW_HIDE_TREE]: showHideRevitTree,
  [MeasurementsActionTypes.START_SEARCH]: search,
  [MeasurementsActionTypes.SELECT_NODE_BY_ENGINE_ID]: extractorSelectionByBimHandle,
  [MeasurementsActionTypes.DROP_STATISTIC]: dropStatistic,
  [MeasurementsActionTypes.EXPAND_ITEM_BY_ID]: expandById,
  [MeasurementsActionTypes.SHOW_EXTRACTORS_BY_ID]: selectExtractorById,
  [MeasurementsActionTypes.SELECT_NODE]: selectNode,
  [MeasurementsActionTypes.GET_STATISTIC_REQUEST]: getStatisticRequest,
  [MeasurementsActionTypes.GET_STATISTIC_SUCCESS]: getStatisticSuccess,
  [MeasurementsActionTypes.ON_CHANGE_INPUT_VALUE]: onChangeInputValue,
  [MeasurementsActionTypes.ON_CHANGE_GENERAL_INPUT_VALUE]: onChangeGeneralInputValue,
  [MeasurementsActionTypes.TOGGLE_ITEM_SELECT_STATUS]: toggleItemSelectStatus,
  [MeasurementsActionTypes.TOGGLE_GENERAL_SELECT_STATUS]: toggleGeneralSelectStatus,
  [MeasurementsActionTypes.SELECT_ELEMENT_FROM_ENGINE]: toggleElementCheckStatusFromEngine,
  [MeasurementsActionTypes.COMMIT_CHANGES_SUCCESS]: commitChangesSuccess,
  [MeasurementsActionTypes.DISABLED_ITEM_SELECT_STATUS]: disabledItemSelectStatus,
  [MeasurementsActionTypes.CANCEL_EDIT_SUCCESS]: cancelEdit,
  [MeasurementsActionTypes.TOGGLE_LEVELS_SHOW_STATUS]: (state, payload) =>
    new MonoliteHelper(state).set(_ => _.areLevelsShown, payload).get(),
};
