import Ag from 'ag-grid-community';
import { UserInfo } from './interfaces';


const HEADER_HEIGHT = 40;
const ROW_HEIGHT = 40;

const DEFAULT_COL_DEF: Ag.ColDef = {
  flex: 1,
  resizable: true,
  sortable: true,
  filter: true,
};

const USER_FILTER_PARAMS = {
  cellRenderer: 'userFilterCell',
  cellHeight: 35,
};

function getName(value: UserInfo | null): string {
  if (value) {
    return `${value.firstName} ${value.lastName}`;
  } else {
    return '';
  }
}

function userCellComparator(valueA: UserInfo, valueB: UserInfo): number {
  return getName(valueA) > getName(valueB) ? 1 : -1;
}

function filterValueGetter(params: Ag.ValueGetterParams): string {
  const targetFieldData = params.data && params.data[params.colDef.field];
  return targetFieldData ? targetFieldData.content : '';
}

const DEFAULT_COLUMNS: Ag.ColDef[] = [
  {
    colId: 'createdBy',
    field: 'createdBy',
    headerName: 'Created by',
    cellRenderer: 'userCell',
    width: 240,
    filterParams: USER_FILTER_PARAMS,
    filterValueGetter,
    comparator: userCellComparator,
  },
  {
    colId: 'createdTime',
    field: 'createdTime',
    headerName: 'Created date',
  },
  {
    colId: 'lastEditBy',
    field: 'lastEditBy',
    headerName: 'Last edited by',
    cellRenderer: 'userCell',
    width: 240,
    filterParams: USER_FILTER_PARAMS,
    filterValueGetter,
    comparator: userCellComparator,
  },
  {
    colId: 'lastEditTime',
    field: 'lastEditTime',
    headerName: 'Last edited time',
  },
];

const DATA_PATH_SPLITER = '%%DATA_PATH_SPLITER%%';


export const Constants = {
  HEADER_HEIGHT,
  ROW_HEIGHT,
  DEFAULT_COL_DEF,
  DEFAULT_COLUMNS,
  DATA_PATH_SPLITER,
};
