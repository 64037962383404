import * as React from 'react';

import {
  TIconComment,
} from '../../../../icons';

export const NoComments: React.FC = () => {
  return (
    <>
      <div className='viewer-no-comments-icon'>
        <img src={TIconComment} />
      </div>
      <span>
        Create your first comment!
      </span>
    </>
  );
};

