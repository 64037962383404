import { useCallback } from 'react';
import { useRendererApiContext } from '../../drawings-contexts';
import { DrawingsGeometryBooleanOperationHelper } from '../../drawings-geometry/drawings-helpers';
import { DrawingsDrawMode } from '../../enums';
import { useDrawModeApi } from '../../hooks';

export function useApplyOperation(
  ...args: Parameters<DrawingsGeometryBooleanOperationHelper['processOperation']>
): () => void {
  const { rendererApi } = useRendererApiContext();
  const { setDrawMode } = useDrawModeApi();
  return useCallback(() => {
    rendererApi.engine.processBoleanOperation(...args);
    setDrawMode(DrawingsDrawMode.Disabled);
  }, [rendererApi, setDrawMode, ...args]);
}
