import { Icons, RoundButton, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { DECREMENT_INVITED_EMAILS_DIALOG } from '../../decrement-invited-emails-dialog';
import { Styled } from './styled';

interface Props {
  minTeamSize: number;
  currentTeamSize: number;
  countInvitedPeople: number;
  headerText: string;
  onTeamSizeChange: (teamSize: number) => void;
  openDialog: (name: string) => void;
}


export class TeamSize extends React.Component<Props> {
  public render(): JSX.Element {
    const { currentTeamSize, headerText, minTeamSize } = this.props;

    return (
      <Styled.Container>
        <Text fontSize={16} color='mainFont'>{headerText}</Text>
        <Styled.Counter disabled={currentTeamSize === minTeamSize}>
          <RoundButton
            id={'left_round_button'}
            onClick={this.decrement}
            Icon={Icons.MinusBig}
            diameter={50}
          />
          <Styled.CounterText>
            <Text fontSize={16} color='mainFont'>{currentTeamSize} members</Text>
          </Styled.CounterText>
          <RoundButton
            onClick={this.increment}
            Icon={Icons.PlusBig}
            diameter={50}
          />
        </Styled.Counter>
      </Styled.Container>
    );
  }

  @autobind
  private increment(): void {
    this.props.onTeamSizeChange(this.props.currentTeamSize + 1);
  }

  @autobind
  private decrement(): void {
    if (this.props.currentTeamSize > (this.props.minTeamSize || 1)) {
      if (this.props.countInvitedPeople === this.props.currentTeamSize - 1 && this.props.openDialog) {
        this.props.openDialog(DECREMENT_INVITED_EMAILS_DIALOG);
      } else {
        this.props.onTeamSizeChange(this.props.currentTeamSize - 1);
      }
    }
  }
}
