import styled from 'styled-components';


const Container = styled.div<{ isLoading: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  > button {
    position: inherit;
    bottom: -20px;
    width: 100%;
    height: 30px;
    margin: auto;
    pointer-events: ${p => p.isLoading ? 'none' : 'all'};
  }
`;

export const Styled = {
  Container,
};
