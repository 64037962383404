import autobind from 'autobind-decorator';
import { DraggablePaperObjectEventsApplier } from '../../interfaces/helpers';
import { DragExecutionHelper } from '../../interfaces/helpers/drag-execution-helper';
import { PaperMouseEventHandler } from '../../interfaces/helpers/mouse-event-handler';
import { PaperMouseEventUtils } from '../paper-mouse-event-utils';

export interface Config<T> {
  onClick?: PaperMouseEventHandler<T>;
  getDoubleClickHandler?: () => PaperMouseEventHandler<T>;
  onMouseLeave?: PaperMouseEventHandler<T>;
  onStartDrag?: PaperMouseEventHandler<T>;
  dragEventsHelper?: DragExecutionHelper;
}

export class DraggableMouseEventsProcessor<T, C extends Config<T> = Config<T>>
implements DraggablePaperObjectEventsApplier<T> {
  protected _config: C;

  private _extraInfo: T;
  private _canDrag: boolean;

  constructor(config: C) {
    this._config = config;
  }

  protected get extraInfo(): T {
    return this._extraInfo;
  }

  public canDrag(): boolean {
    return this._canDrag;
  }

  public enableDrag(value: boolean): void {
    this._canDrag = value;
  }

  public setExtraInfo(extraInfo: T): void {
    this._extraInfo = extraInfo;
  }

  public setHandlers(path: paper.Item): void {
    path.onClick = this.onClick;
    path.onMouseDown = this.onMouseDown;
    path.onMouseLeave = this.onMouseLeave;
    path.onDoubleClick = this.onDoubleClick;
    path.onMouseUp = this.onMouseUp;
  }

  @autobind
  protected onClick(e: PaperMouseEvent): void {
    if (!this._config.onClick) {
      return;
    }
    this._config.onClick(e, this._extraInfo);
  }

  @autobind
  protected onMouseDown(e: PaperMouseEvent): void {
    if (!this._config.onStartDrag || !this._canDrag) {
      return;
    }
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      if (this._config.dragEventsHelper) {
        this._config.dragEventsHelper.onStartElementDrag(e, () => this._config.onStartDrag(e, this._extraInfo));
      } else {
        this._config.onStartDrag(e, this._extraInfo);
      }
    }
  }

  @autobind
  protected onMouseUp(e: PaperMouseEvent): void {
    if (PaperMouseEventUtils.isLeftMouseButton(e) && this._config.dragEventsHelper) {
      this._config.dragEventsHelper.stopExecutor();
    }
  }

  @autobind
  protected onMouseLeave(e: PaperMouseEvent): void {
    if (this._config.dragEventsHelper) {
      this._config.dragEventsHelper.stopExecutor();
    }
    if (this._config.onMouseLeave) {
      this._config.onMouseLeave(e, this._extraInfo);
    }
  }

  @autobind
  protected onDoubleClick(e: PaperMouseEvent): void {
    const handler = this.tryGetDoubleClickHandler();
    if (PaperMouseEventUtils.isLeftMouseButton(e) && handler) {
      handler(e, this._extraInfo);
    }
  }

  private tryGetDoubleClickHandler(): PaperMouseEventHandler<T> | null {
    return this._config.getDoubleClickHandler ? this._config.getDoubleClickHandler() : null;
  }
}
