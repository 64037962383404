import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';

import { selectWrapper } from 'common/utils/saga-wrappers';
import { QuantityTakeOffReportActions } from '../actions/creators/quantity-take-off-report';
import { QuantityTakeOffTemplateActions } from '../actions/creators/quantity-take-off-template';
import { BaseTemplatePayload, TemplateFromPayload } from '../actions/payloads/quantity-take-off-template';
import { QuantityTakeOffTemplateActionTypes } from '../actions/types/quantity-take-off-template';
import { QuantityTakeOffTemplateApi } from '../api/quantity-take-off-template';
import {
  ModelType,
  QtoTemplateColumnsForm,
  QtoTemplateForm,
  QtoTemplateInfo,
  QtoTemplateInfoForm,
  QtoTemplateRowForm,
} from '../interfaces/quantity-take-off';
import { getEmptyModel } from './quantity-take-off-reports';

function* createTemplate({ payload }: ActionWith<TemplateFromPayload<QtoTemplateForm>>): SagaIterator {
  try {
    const { modelType, form } = payload;
    const { id } = yield call<(form: QtoTemplateForm, type: ModelType) => Promise<QtoTemplateInfo>>(
      QuantityTakeOffTemplateApi.createTemplate, form, modelType,
    );
    yield put(QuantityTakeOffTemplateActions.setSelectedTemplateId(id));
    yield put(QuantityTakeOffTemplateActions.loadTemplatesInfo(modelType));
  } catch (error) {
    console.error('quantity take off template: create template failed', error, payload);
  }
}

function* getTemplatesInfo({ payload: modelType }: ActionWith<ModelType>): SagaIterator {
  try {
    const templates = yield call(QuantityTakeOffTemplateApi.getTemplates, modelType);
    yield put(QuantityTakeOffTemplateActions.setTemplatesInfo(templates));
  } catch (error) {
    console.error('quantity take off template: get template info failed', error);
  }
}

function* updateTemplateInfo(
  { payload }: ActionWith<TemplateFromPayload<QtoTemplateInfoForm>>,
): SagaIterator {
  try {
    const { id, modelType, form } = payload;
    const templateInfo = yield call(QuantityTakeOffTemplateApi.updateTemplateInfo, id, modelType, form);
    const template = yield selectWrapper(s => s.quantityTakeOff.template.templatesInfo);
    const index = template.findIndex(x => x.id === id);
    const updatedTemplate = template.slice();
    updatedTemplate[index] = templateInfo;
    yield put(QuantityTakeOffTemplateActions.setTemplatesInfo(updatedTemplate));
  } catch (error) {
    console.error('quantity take off template: update template info failed', error, payload);
  }
}

function* getInitialState({ payload: modelType }: ActionWith<ModelType>): SagaIterator {
  try {
    const templates = yield call(QuantityTakeOffTemplateApi.getTemplates, modelType);
    yield put(QuantityTakeOffTemplateActions.setTemplatesInfo(templates));
    if (templates.length) {
      yield put(QuantityTakeOffTemplateActions.setSelectedTemplateId(templates[0].id));
    } else {
      const model = getEmptyModel('New Template');
      const { id } = yield call(QuantityTakeOffTemplateApi.createTemplate, model, modelType);
      yield put(QuantityTakeOffTemplateActions.setSelectedTemplateId(id));
      yield put(QuantityTakeOffTemplateActions.loadTemplatesInfo(modelType));
    }
  } catch (error) {
    console.error('quantity take off template: get template info failed', error);
  }
}

function* deleteTemplate({ payload }: ActionWith<BaseTemplatePayload>): SagaIterator {
  try {
    const { id: templateId, modelType } = payload;
    yield call(QuantityTakeOffTemplateApi.deleteTemplate, templateId, modelType);
  } catch (error) {
    console.error('quantity take off template: delete template failed', error);
  }
}

function* loadTemplate({ payload }: ActionWith<BaseTemplatePayload>): SagaIterator {
  try {
    const { id: templateId, modelType } = payload;
    const templateModel = yield call(QuantityTakeOffTemplateApi.loadTemplate, templateId, modelType);
    yield put(QuantityTakeOffTemplateActions.setTemplateModel(templateModel));
  } catch (error) {
    console.error('quantity take off template: load template failed', error);
  }
}

function* updateBasicColumns({ payload }: ActionWith<TemplateFromPayload<QtoTemplateColumnsForm>>): SagaIterator {
  try {
    const { id, modelType, form } = payload;
    yield call(QuantityTakeOffTemplateApi.updateBasicColumns, id, modelType, form);
  } catch (error) {
    console.error('quantity take off template: update columns failed', error, payload);
  }
}

function* updateRows({ payload }: ActionWith<TemplateFromPayload<QtoTemplateRowForm[]>>): SagaIterator {
  try {
    const { id, modelType, form } = payload;
    yield call(QuantityTakeOffTemplateApi.updateRows, id, modelType, form);
  } catch (error) {
    console.error('quantity take off template: update rows failed', error, payload);
  }
}

function* addRows({ payload }: ActionWith<TemplateFromPayload<QtoTemplateRowForm>>): SagaIterator {
  try {
    const { id, modelType, form } = payload;
    yield call(QuantityTakeOffTemplateApi.addRows, id, modelType, form);
  } catch (error) {
    console.error('quantity take off template: add rows failed', error, payload);
  }
}

function* removeRows({ payload }: ActionWith<TemplateFromPayload<string[]>>): SagaIterator {
  try {
    const { id, modelType, form } = payload;
    yield call(QuantityTakeOffTemplateApi.removeRows, id, modelType, form);
  } catch (error) {
    console.error('quantity take off template: remove rows failed', error, payload);
  }
}

function* reorderRows({ payload }: ActionWith<TemplateFromPayload<QtoTemplateRowForm>>): SagaIterator {
  try {
    const { id, modelType, form } = payload;
    yield call(QuantityTakeOffTemplateApi.reorderRows, id, modelType, form);
  } catch (error) {
    console.error('quantity take off template: reorder rows failed');
  }
}

function* createReportFromTemplate({ payload }: ActionWith<TemplateFromPayload<number>>): SagaIterator {
  try {
    const { id: projectId, modelType, form: templateId } = payload;
    const reportInfo = yield call(
      QuantityTakeOffTemplateApi.createReportFromTemplate, projectId, templateId, modelType);
    yield put(QuantityTakeOffReportActions.updateReportAfterCreationViaTemplate(reportInfo));
  } catch (error) {
    console.error('quantity take off template: create report from template failed', error, payload);
  }
}

export function* quantityTakeOffTemplateSagas(): SagaIterator {
  yield takeLatest(QuantityTakeOffTemplateActionTypes.REORDER_ROWS, reorderRows);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.REMOVE_ROWS, removeRows);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.ADD_ROWS, addRows);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.UPDATE_TEMPLATE_ROWS, updateRows);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.CREATE_TEMPLATE, createTemplate);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.GET_TEMPLATES_INFO, getTemplatesInfo);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.UPDATE_TEMPLATE_INFO, updateTemplateInfo);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.GET_INITIAL_STATE, getInitialState);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.DELETE_TEMPLATE, deleteTemplate);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.LOAD_TEMPLATE, loadTemplate);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.UPDATE_BASIC_COLUMNS, updateBasicColumns);
  yield takeLatest(QuantityTakeOffTemplateActionTypes.CREATE_REPORT_FROM_TEMPLATE, createReportFromTemplate);
}

