import { objectUtils } from 'common/utils/object-utils';
import { ProjectResourcesApi } from '../../../api/server';
import {
  ApproveValidationStepPayload,
  RevertToValidationStepPayload,
} from '../actions/payloads/validation';
import { getValidationStepName } from '../enums/validation-step-name';
import {
  ProjectValidationResultsResponse,
  ProjectValidationResultsStatistic,
} from '../interfaces/project-validation-results';
import { ProjectValidationState } from '../interfaces/project-validation-state';

function approveValidationStep({ scenarioId, step }: ApproveValidationStepPayload): Promise<void> {
  const stepName = getValidationStepName(step);
  const query = scenarioId ? `?${objectUtils.toQueryString({ scenarioId })}` : '';

  return ProjectResourcesApi.post(`/validation/approve/${stepName}${query}`);
}

function revertToValidationStep({ targetStep }: RevertToValidationStepPayload): Promise<ProjectValidationState> {
  const stepName = getValidationStepName(targetStep);

  return ProjectResourcesApi.post(`/validation/revert-to/${stepName}`);
}

function getResultsStatistic(scenarioId: number): Promise<ProjectValidationResultsResponse> {
  const query = scenarioId ? `?${objectUtils.toQueryString({ scenarioId })}` : '';

  return ProjectResourcesApi.get(`/validation/results${query}`);
}


function getClassificationAggregatedStatistic(): Promise<ProjectValidationResultsStatistic> {
  return ProjectResourcesApi.get(`/classification/statistic-aggregated`);
}


export const ValidationApi = {
  approveValidationStep,
  revertToValidationStep,
  getResultsStatistic,
  getClassificationAggregatedStatistic,
};
