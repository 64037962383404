import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './gantt-chart.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { NumberDictionary } from 'common/interfaces/dictionary';
import { State } from 'common/interfaces/state';
import { mathUtils } from 'common/utils/math-utils';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { ProjectLayout } from '../../../project-dashbord';
import { GanttChartActions } from '../../actions/creators/gantt-chart';
import {
  BaseChartHeader,
} from '../../components/gantt-chart';
import { AnimationDataResponse, GanttData, TimeframeAnimationTarget } from '../../interfaces/gantt-chart';
import {
  animationDataResponseMapper,
  ChartDataProvider,
  ResourceDescription,
  ResourceType,
  SlackType,
} from '../../utils/gantt-chart';
import { ChartBody } from './components/chart-body';
import { PageFooter } from './components/page-footer';
import { PageHeader } from './components/page-header';
import { ResourcesChartContainer } from './components/resources-chart-container';
import { WorkPackageSelectionControl } from './components/work-package-selection-control';
import { AnimationTarget } from './interfaces/animation-target';
import { ganttChartContants } from './utils/constants';


interface StateProps {
  calculationId: number | null;
  animationData: AnimationDataResponse;
  projectStartDate: Date;
  calculationIdData: number;
}

interface DispatchProps {
  getAnimationData: (id: number) => void;
  saveGanttData: (ganttData: GanttData) => void;
  resetState: () => void;
  saveResourcesData: (data: NumberDictionary<Map<number, ResourceDescription>>) => void;
}

interface Props extends RouteComponentProps<PlanProjectRouteParams>, StateProps, DispatchProps, AbilityAwareProps {}

interface PageState {
  provider: ChartDataProvider;
  totalDuration: number;
  timelineStartDay: number;
  timelineDuration: number;
  timeframeDuration: number;
  timeframeStartDay: number;
  chartWidth: number;
  chartHeight: number;
  visibleSlackType: SlackType;
  showCriticalPath: boolean;
}

// const animationTargetTreshold = 1 / 24 / 60 / 6;
class GanttChartPageComponent extends React.Component<Props, PageState> {
  private deferredStateUpdates: Partial<PageState> = {};
  private isUnmounted: boolean = false;
  private animationTarget: AnimationTarget = null;
  private prevFrameTimestamp: number = 0;


  constructor(props: Props) {
    super(props);
    this.state = {
      provider: null,
      totalDuration: null,
      timelineDuration: null,
      timeframeDuration: null,
      timeframeStartDay: 0,
      timelineStartDay: 0,
      chartWidth: 0,
      chartHeight: 0,
      visibleSlackType: SlackType.Disabled,
      showCriticalPath: false,
    };
    requestAnimationFrame(this.animationLoop);
  }

  public static getDerivedStateFromProps(
    nextProps: Props,
    prevState: PageState | null,
  ): Partial<PageState> {
    if (!nextProps.animationData && nextProps.calculationId !== null) {
      nextProps.getAnimationData(nextProps.calculationId);
      return null;
    } else if (nextProps.animationData && nextProps.calculationId !== nextProps.calculationIdData) {
      nextProps.resetState();
      nextProps.getAnimationData(nextProps.calculationId);
      return {
        provider: null,
      };
    }
    if (!(prevState && prevState.provider) && nextProps.animationData) {
      const provider = new ChartDataProvider(nextProps.animationData);
      const ganttData = animationDataResponseMapper.getGanttData(nextProps.animationData, provider);
      nextProps.saveGanttData(ganttData);

      const resourcesData: NumberDictionary<Map<number, ResourceDescription>> = {};
      const resourceTypes: ResourceType[] = [ResourceType.Labour, ResourceType.Material, ResourceType.Plant];

      for (const type of resourceTypes) {
        resourcesData[type] = new Map<number, ResourceDescription>();
        const resourcesFromProvider = provider.getResourcesDescriptionsOfType(type);

        for (const resource of resourcesFromProvider) {
          resourcesData[type].set(resource.id, resource);
        }
      }

      nextProps.saveResourcesData(resourcesData);

      const projectDuration = provider.getTotalDuration();
      return {
        provider,
        totalDuration: projectDuration,
        timelineDuration: projectDuration,
        timeframeDuration: projectDuration,
      };
    }
    return null;
  }

  public componentWillUnmount(): void {
    this.isUnmounted = true;
    this.removeEventListeners();
  }

  public render(): React.ReactNode {
    let waitingForData = false;
    if (!this.props.animationData || !this.state.provider) {
      waitingForData = true;
    }
    if (this.state.provider) {
      this.state.provider.setTimeRange(
        this.state.timeframeStartDay,
        this.state.timeframeDuration,
      );
    }
    return (
      <ProjectLayout
        projectId={this.props.match.params.projectId}
        waitingForAdditionalInfo={waitingForData}
      >
        <div
          onMouseEnter={this.addEventListeners}
          onMouseLeave={this.removeEventListeners}
          className='gantt-chart-page-container'
        >
          <PageHeader
            showCriticalPath={this.state.showCriticalPath}
            visibleSlackType={this.state.visibleSlackType}
            onChangeSlackType={this.changeSlackType}
            onChangeCriticalPathVisibility={this.onChangeCriticalPathVisibility}
            zoomIn={this.onZoomIn}
            zoomOut={this.onZoomOut}
            onBack={this.onBack}
            onForward={this.onForward}
          />
          <div className='gantt-chart white' ref={this.saveChartRect}>
            <ReactResizeDetector
              handleHeight={true}
              handleWidth={true}
              onResize={this.onResize}
            />
            <BaseChartHeader
              width={this.state.chartWidth}
              projectStartDate={this.props.projectStartDate}
              timelineDuration={this.state.timelineDuration}
              timeframeDuration={this.state.timeframeDuration}
              triggerTimeframeChangeAnimation={this.triggerTimeframeChangeAnimation}
              timeframeStartDay={this.state.timeframeStartDay}
            >
              <WorkPackageSelectionControl/>
            </BaseChartHeader>
            <ChartBody
              showCriticalPath={this.state.showCriticalPath}
              slacksType={this.state.visibleSlackType}
              dataProvider={this.state.provider}
              width={this.state.chartWidth}
              timeframeDuration={this.state.timeframeDuration}
              timeframeStartDay={this.state.timeframeStartDay}
              height={this.state.chartHeight}
            />
          </div>
          {this.props.ability.can(Operation.Read, Subject.GanttResources) && (
            <React.Fragment>
              <ResourcesChartContainer
                projectStartDate={this.props.projectStartDate}
                timelineDuration={this.state.timelineDuration}
                timeframeDuration={this.state.timeframeDuration}
                timeframeStartDay={this.state.timeframeStartDay}
                updateTimeframe={this.updateTarget}
                chartWidth={this.state.chartWidth}
                dataProvider={this.state.provider}
              />
              <PageFooter
                dataProvider={this.state.provider}
                width={this.state.chartWidth}
                projectStartDate={this.props.projectStartDate}
                timelineStartDay={this.state.timelineStartDay}
                timelineDuration={this.state.timelineDuration}
                timeframeDuration={this.state.timeframeDuration}
                timeframeStartDay={this.state.timeframeStartDay}
                updateTimeframe={this.updateTimeframe}
                updateTimeframeTarget={this.updateTarget}
              />
            </React.Fragment>
          )}
        </div>
      </ProjectLayout>
    );
  }

  @autobind
  private changeSlackType(type: SlackType): void {
    this.setDeferState(() => {
      return {
        visibleSlackType: type,
      };
    });
  }

  @autobind
  private onChangeCriticalPathVisibility(value: boolean): void {
    this.setDeferState(() => {
      return {
        showCriticalPath: value,
      };
    });
  }

  @autobind
  private onResize(chartWidth: number, chartHeight: number): void {
    this.setDeferState(() => {
      return {
        chartHeight,
        chartWidth,
      };
    });
  }

  @autobind
  private removeEventListeners(): void {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keypress', this.onKeyPress);
  }


  @autobind
  private addEventListeners(): void {
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keypress', this.onKeyPress);
  }

  @autobind
  private onKeyDown(event: KeyboardEvent): void {
    event.stopPropagation();
    switch (event.keyCode) {
      case 107:
        this.onZoomIn();
        break;
      case 189:
      case 109:
        this.onZoomOut();
        break;
      case 39:
        this.onForward();
        break;
      case 37:
        this.onBack();
        break;
      default:
    }
  }

  @autobind
  private onKeyPress(event: KeyboardEvent): void {
    event.stopPropagation();
    if (event.shiftKey && event.keyCode === 43) {
      this.onZoomIn();
    }
  }

  @autobind
  private updateTarget(start: number, duration: number): void {
    this.animationTarget = { targetStartDay: start, targetTimeframeDuration: duration };
  }

  @autobind
  private triggerTimeframeChangeAnimation(target: TimeframeAnimationTarget): void {
    this.setDeferState((actualState) => {
      const timelineEndDay = actualState.timelineDuration + actualState.timelineStartDay;
      const timeframeStartDay = mathUtils.clamp(target.currentMoment, actualState.timelineStartDay, timelineEndDay);
      const timeframeEndDay =
        mathUtils.clamp(timeframeStartDay + target.timeframeDuration, actualState.timeframeStartDay, timelineEndDay);
      const timeframeDuration = timeframeEndDay - timeframeStartDay;
      return { timeframeStartDay, timeframeDuration, timeframeEndDay };
    });
  }

  @autobind
  private onZoomIn(): void {
    const actualState = this.getPageState();
    const timeframeDuration = this.animationTarget ?
      this.animationTarget.targetTimeframeDuration : actualState.timeframeDuration;
    const timeframeStartDay = this.animationTarget ?
      this.animationTarget.targetStartDay : actualState.timeframeStartDay;
    const newDuration = mathUtils.clamp(
      timeframeDuration / ganttChartContants.zoomCoeficient,
      ganttChartContants.minTimeframeDuration,
      actualState.timelineDuration);
    this.updateTarget(timeframeStartDay, newDuration);
  }

  @autobind
  private onZoomOut(): void {
    const actualState = this.getPageState();
    const timeframeDuration = this.animationTarget ?
      this.animationTarget.targetTimeframeDuration : actualState.timeframeDuration;
    const timeframeStartDay = this.animationTarget ?
      this.animationTarget.targetStartDay : actualState.timeframeStartDay;
    const newTimeframeDuration = mathUtils.clamp(
      timeframeDuration * ganttChartContants.zoomCoeficient,
      ganttChartContants.minTimeframeDuration,
      actualState.timelineDuration);
    const timeframeSum = newTimeframeDuration + timeframeStartDay;
    const timelineSum = actualState.timelineDuration + actualState.timelineStartDay;
    if (timeframeSum > timelineSum) {
      const newStartDay = mathUtils.clamp(
        timelineSum - newTimeframeDuration,
        actualState.timelineStartDay,
        timelineSum - newTimeframeDuration);
      this.updateTarget(newStartDay, newTimeframeDuration);
    } else {
      this.updateTarget(timeframeStartDay, newTimeframeDuration);
    }
  }

  @autobind
  private onForward(): void {
    const actualState = this.getPageState();
    const timeframeDuration = this.animationTarget ?
      this.animationTarget.targetTimeframeDuration : actualState.timeframeDuration;
    const timeframeStartDay =
      this.animationTarget ? this.animationTarget.targetStartDay : actualState.timeframeStartDay;
    const timeframeSum = timeframeDuration + timeframeStartDay;
    const timelineSum = actualState.timelineStartDay + actualState.timelineDuration;
    let timeframeStep = timeframeDuration / 5;
    if (timeframeSum + timeframeStep > timelineSum) {
      timeframeStep = mathUtils.clamp(timelineSum - timeframeSum, 0, timeframeStep);
    }
    this.updateTarget(timeframeStartDay + timeframeStep, timeframeDuration);
  }

  @autobind
  private onBack(): void {
    const actualState = this.getPageState();
    const timeframeDuration = this.animationTarget ?
      this.animationTarget.targetTimeframeDuration : actualState.timeframeDuration;
    const timeframeStartDay = this.animationTarget ?
      this.animationTarget.targetStartDay : actualState.timeframeStartDay;
    const timeframeStep = timeframeDuration / 5;
    const newStartDay = mathUtils.clamp(
      timeframeStartDay - timeframeStep,
      actualState.timelineStartDay,
      actualState.timelineDuration + actualState.timelineStartDay);
    this.updateTarget(newStartDay, timeframeDuration);
  }

  @autobind
  private animationLoop(time: number): void {
    if (this.isUnmounted) {
      return;
    }
    requestAnimationFrame(this.animationLoop);
    if (this.animationTarget) {
      const delta = time - this.prevFrameTimestamp;
      this.prevFrameTimestamp = time;
      const k = delta * 0.01;
      const state = this.getPageState();
      const { targetStartDay, targetTimeframeDuration } = this.animationTarget;
      const safeNewDuration = mathUtils.lerp(state.timeframeDuration, targetTimeframeDuration, k);
      const safeNewStartDay = mathUtils.lerp(state.timeframeStartDay, targetStartDay, k);
      if (
        Math.abs(state.timeframeDuration - safeNewDuration) > 0.005 ||
        Math.abs(state.timeframeStartDay - safeNewStartDay) > 0.005
      ) {
        this.updateTimeframe(safeNewStartDay, safeNewDuration, true);
      } else {
        this.updateTimeframe(targetStartDay, targetTimeframeDuration, true);
        this.finishAnimation();
      }
    }
    this.commitDeferredStateUpdates();
  }


  @autobind
  private updateTimeframe(startDay: number, duration: number, isUpdatedFromLoop: boolean = false): void {
    if (!isUpdatedFromLoop) {
      this.finishAnimation();
    }
    this.setDeferState((actualState) => {
      const safeDuration = mathUtils.clamp(duration, 1, actualState.timelineDuration);
      const safeStartDay = mathUtils.clamp(
        startDay,
        actualState.timelineStartDay,
        actualState.timelineStartDay + (actualState.timelineDuration - safeDuration));

      if (
        Math.abs(safeStartDay - actualState.timeframeStartDay) > 0.0001 ||
        Math.abs(safeDuration - actualState.timeframeDuration) > 0.0001
      ) {
        return {
          timeframeStartDay: safeStartDay,
          timeframeDuration: safeDuration,
        };
      } else {
        return null;
      }
    });
  }

  private commitDeferredStateUpdates(): void {
    if (Object.keys(this.deferredStateUpdates).length > 0) {
      this.setState({ ...this.deferredStateUpdates } as any);
      this.deferredStateUpdates = {};
    }
  }


  @autobind
  private saveChartRect(ref: HTMLDivElement): void {
    if (ref) {
      this.setDeferState(() => {
        const newRect = ref.getBoundingClientRect();
        return { chartHeight: newRect.height, chartWidth: newRect.width - 1 };
      });
    }
  }

  @autobind
  private getPageState(): PageState {
    return { ...this.state, ...this.deferredStateUpdates };
  }

  private finishAnimation(): void {
    this.animationTarget = null;
  }

  private setDeferState(
    updateFunc: (actualState: PageState) => Partial<PageState> | null,
    callback?: () => void,
  ): void {
    const newUpdates = updateFunc(this.getPageState());
    if (newUpdates !== null) {
      this.deferredStateUpdates = {
        ...this.deferredStateUpdates,
        ...newUpdates,
      };
    }

    if (callback) {
      callback();
    }
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    calculationId: state.scenarios.active_calculation
      ? state.scenarios.active_calculation.id
      : null,
    animationData: state.fourDVisualisation.animationData,
    projectStartDate: state.fourDVisualisation.projectStartDate,
    calculationIdData: state.fourDVisualisation.calculationId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    getAnimationData: (calculationId: number): void => {
      dispatch(GanttChartActions.getAnimationData(calculationId));
    },
    saveGanttData: (ganttData: GanttData): void => {
      dispatch(GanttChartActions.saveGanttData(ganttData));
    },
    resetState: (): void => {
      dispatch(GanttChartActions.resetState());
    },
    saveResourcesData: (data: NumberDictionary<Map<number, ResourceDescription>>) => {
      dispatch(GanttChartActions.saveResourcesData(data));
    },
  };
};

const GanttChartPage = withAbilityContext(connect(mapStateToProps, mapDispatchToProps)(GanttChartPageComponent));

export { GanttChartPage };
