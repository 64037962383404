const prefix = '@database-resource-listing';

export const DatabaseResourceListingActionTypes = {
  SET_SELECTED_RESOURCE_TYPE: `${prefix}/SET_SELECTED_RESOURCE_TYPE`,
  OPEN_EDIT_RESOURCE: `${prefix}/OPEN_EDIT_RESOURCE`,
  OPEN_EDIT_RESOURCE_VARIANT: `${prefix}/OPEN_EDIT_RESOURCE_VARIANT`,
  OPEN_CREATE_RESOURCE: `${prefix}/OPEN_CREATE_RESOURCE`,
  OPEN_CREATE_RESOURCE_VARIANT: `${prefix}/OPEN_CREATE_RESOURCE_VARIANT_ID`,
  CLOSE_EDIT_PANEL: `${prefix}/CLOSE_EDIT_PANEL`,
  RESET_REQUEST_STATUSES: `${prefix}/RESET_REQUEST_STATUSES`,
  SET_EDIT_RESOURCE_MODEL: `${prefix}/SET_EDIT_RESOURCE_MODEL`,
  SET_EDIT_RESOURCE_VARIANT_MODEL: `${prefix}/SET_EDIT_RESOURCE_VARIANT_MODEL`,
};
