import styled from 'styled-components';

const PropertyName = styled.div`
  gap: 10px;
  align-items: center;
  display: flex;
`;

const FormulaTitle = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  svg {
    min-width: 20px;
    max-width: 20px;
    fill: ${p => p.theme.color.gray};
  }
`;

export const Styled = {
  FormulaTitle,
  PropertyName,
};
