import * as classNames from 'classnames';
import * as React from 'react';

import './sub-header.scss';

import { KreoButton } from 'common/UIKit';

interface SubHeaderProps {
  title: string;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  buttonClassname?: string;
  alert?: React.ReactNode;
  onButtonClick?: () => void;
  centerSectionContent?: React.ReactNode;
  rightSectionContent?: React.ReactNode;
  leftSectionContent?: React.ReactNode;
}

export class SubHeader extends React.Component<SubHeaderProps> {
  public render(): React.ReactNode {
    const {
      title,
      buttonText,
      buttonIcon,
      onButtonClick,
      buttonClassname,
      leftSectionContent,
      centerSectionContent,
      rightSectionContent,
      alert,
    } = this.props;

    const btnClasses = classNames(
      'kreo-sub-header__action-button',
      buttonClassname,
    );
    return (
      <div className='kreo-sub-header'>
        <div className='kreo-sub-header__content'>
          <div className='kreo-sub-header__left-section'>
            {leftSectionContent
              ? leftSectionContent
              : <div className='kreo-sub-header__title'>{title}</div>
            }
          </div>
          {
            centerSectionContent &&
            (
              <div className='kreo-sub-header__center-section'>
                {centerSectionContent}
              </div>
            )
          }
          <div className='kreo-sub-header__right-section'>
            {rightSectionContent}
            {(buttonText || buttonIcon) && onButtonClick ? (
              <div className='kreo-sub-header__btn-wrap'>
                <KreoButton
                  className={btnClasses}
                  icon={buttonIcon}
                  size='medium'
                  onClick={onButtonClick}
                  rounded={true}
                  mode='submit'
                >
                  {buttonText}
                </KreoButton>
              </div>
            ) : null}
          </div>
        </div>
        <div className='kreo-sub-header__alert'>
          {alert}
        </div>
      </div>
    );
  }
}
