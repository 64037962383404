import * as React from 'react';

import './left-panel-layout.scss';

interface Props extends React.Props<LeftPanelLayout> {
  hasSelected: boolean;
}
export class LeftPanelLayout extends React.Component<Props> {
  public render(): any {
    return (
    <div className={`checker-left-panel-layout ${this.props.hasSelected ? 'x' : ''}`}>
      {this.props.children}
    </div>);
  }
}
