import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';

import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialog } from 'common/UIKit';
import { ResourceType } from '../../enums';
import { ActivityVariantResource, UnitModel } from '../../interfaces/data';
import { MaterialModel } from '../../interfaces/resources-data';
import { ResourceSelector } from '../resource-selector';

export const RESOURCE_SELECTOR_DIALOG_MATERIAL = 'resourceSelectorMaterial';

interface ReduxProps {
  databaseId: number;
  isDialogOpen: boolean;
  unitMap: Record<number, UnitModel>;
}

interface Props extends ReduxProps {
  selectedMaterials: Array<ActivityVariantResource<MaterialModel>>;
  onCancel: () => void;
  onSave: (materials: Array<ActivityVariantResource<MaterialModel>>) => void;
}

export class DatabaseActivityVariantMaterialSelectorComponent extends React.Component<Props> {

  public render(): JSX.Element {
    return (
      <KreoDialog
        name={RESOURCE_SELECTOR_DIALOG_MATERIAL}
        title='Select Materials'
        onClose={this.props.onCancel}
      >
        <ResourceSelector
          databaseId={this.props.databaseId}
          name='Material'
          type={ResourceType.Material}
          selectedResources={this.props.selectedMaterials.map(x => ({ ...x, id: x.resource.id }))}
          onClose={this.props.onCancel}
          onSave={this.onSave}
          isMultiselect={true}
          isOpen={this.props.isDialogOpen}
          unitMap={this.props.unitMap}
        />
      </KreoDialog>
    );
  }

  @autobind
  private onSave(resources: any[]): void {
    const result = resources.map(resource => {
      const previouslySavedLabor = this.props.selectedMaterials.find(x => x.resource.id === resource.id);
      if (previouslySavedLabor) {
        return previouslySavedLabor;
      } else {
        return this.getMaterialFromResource(resource);
      }
    });
    this.props.onSave(result);
    this.props.onCancel();
  }

  private getMaterialFromResource(resource: any): ActivityVariantResource<MaterialModel> {
    const materialResource = {
      id: resource.id,
      cost: resource.cost,
      name: resource.name,
      defaultUnitId: resource.defaultUnitId,
      variants: resource.variants,
    };

    return {
      id: 0,
      amount: 1,
      unitId: resource.defaultUnitId,
      resource: materialResource as MaterialModel,
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    databaseId: state.database.currentDatabase.database.id,
    isDialogOpen: RESOURCE_SELECTOR_DIALOG_MATERIAL in state.dialog,
    unitMap: state.database.currentDatabase.unitMap,
  };
};

export const DatabaseActivityVariantMaterialSelector =
  connect(mapStateToProps)(DatabaseActivityVariantMaterialSelectorComponent);
