import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './filter-panel.scss';

import { NumberDictionary } from 'common/interfaces/dictionary';
import { State as ReduxState } from '../../../../common/interfaces/state';
import { ActivityActions } from '../../actions';
import { FilterType } from '../../filter-type';
import { SimpleOption, TimeInterval } from '../../interfaces';
import { UserActivityUtil } from '../../user-activity-util';
import { FilterPanelOption } from '../filter-panel-option';

interface ReduxProps {
  filter: NumberDictionary<SimpleOption[]>;
  timeInterval: TimeInterval;
}

interface ReduxActions {
  deleteFilterItem: (type: FilterType, optionId: React.ReactText) => void;
  clearTimeInterval: () => void;
}

interface Props extends ReduxProps, ReduxActions {}

class FilterPanelComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { filter, timeInterval } = this.props;
    const show = UserActivityUtil.hasAnyFilter(filter, timeInterval);
    return (
      <div className={classNames('activity-filter-panel', { show })}>
        <React.Fragment>
          {timeInterval &&
            timeInterval.start && (
              <FilterPanelOption
                timeInterval={timeInterval}
                deleteFilterItem={this.props.clearTimeInterval}
              />
          )}
          {Object.keys(filter).map(
            type =>
              filter[type]
                ? filter[type].map(x => (
                    <FilterPanelOption
                      key={x.id}
                      option={x}
                      deleteFilterItem={this.props.deleteFilterItem}
                      type={parseInt(type, 10)}
                    />
                ))
                : [],
          )}
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    filter: state.userActivities.filter,
    timeInterval: state.userActivities.timeInterval,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    deleteFilterItem: (type, optionId) => {
      dispatch(ActivityActions.deleteFilterItem(type, optionId));
    },
    clearTimeInterval: () => {
      dispatch(ActivityActions.clearTimeInterval());
    },
  };
};

export const FilterPanel = connect(mapStateToProps, mapDispatchToProps)(FilterPanelComponent);
