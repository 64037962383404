import autobind from 'autobind-decorator';
import * as React from 'react';


export interface MenuItemProps extends React.Props<MenuItem> {
  value: string | number;
  onSelect?: (value: string | number) => void;
}

export class MenuItem extends React.PureComponent<MenuItemProps> {
  public render(): React.ReactNode {
    return (<div className='kreo-drop-down-menu__item' onClick={this.onClick}>{this.props.children}</div>);
  }

  @autobind
  private onClick(): void {
    this.props.onSelect(this.props.value);
  }
}
