import React, { useMemo } from 'react';

import './file-upload-wrap.scss';

import { UploadingFile } from 'common/interfaces/common-state';
import { FilesUploader } from 'components/dialog/fields/files-uploader';
import { FileTitle } from './file-title';

interface Props {
  isDownloadFileInProgress?: boolean;
  formName: string;
  validate?: Array<(value: string) => string>;
  onUploadStarted?: (file: File[]) => void;
  onDeleteFile?: (file: UploadingFile) => void;
  extensions: string[];
  extensionsToShow: string[];
  children?: React.ReactNode;
}

const FileUploadWrapComponent: React.FC<Props> = ({
  onUploadStarted,
  formName,
  validate,
  onDeleteFile,
  extensions,
  extensionsToShow,
}) => {
  const projectFileExtension = useMemo(() => extensions.map((x) => `.${x}`).join(', '), [extensions]);

  const fileTitle = useMemo(() => {
    return <FileTitle extensionsToShow={extensionsToShow} />;
  }, [extensionsToShow]);

  return (
    <FilesUploader
      accept={projectFileExtension}
      disabled={false}
      form={formName}
      name="fileName"
      title={fileTitle}
      validate={validate}
      onUploadStarted={onUploadStarted}
      onDeleteFile={onDeleteFile}
      extensionsToShow={extensionsToShow}
    />
  );
};

export const NewFileUploadWrap = React.memo(FileUploadWrapComponent);
