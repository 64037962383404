import { Icons } from '@kreo/kreo-ui-components';
import { arrayUtils } from 'common/utils/array-utils';
export enum DrawingStrokeStyles {
  Normal = 'Normal',
  Dashed = 'Dashed',
  DashDoted = 'DashDoted',
}

export enum DrawingMarkShapes {
  Circle = 'Circle',
  Star = 'Star',
  Square = 'Square',
  Pentagon = 'Pentagon',
  RoundedSquare = 'RoundedSquare',
  Hexagon = 'Hexagon',
  Triangle = 'Triangle',
  Octagon = 'Octagon',
  Romb = 'Romb',
}


export const DrawingStylesSwitcherConfig = [
  {
    mode: DrawingStrokeStyles.Normal,
    text: '',
    description: 'Solid',
    Icon: Icons.Solid,
  },
  {
    mode: DrawingStrokeStyles.Dashed,
    text: '',
    description: 'Dashed',
    Icon: Icons.Dashed,
  },
  {
    mode: DrawingStrokeStyles.DashDoted,
    text: '',
    description: 'Dashed With Dot',
    Icon: Icons.Dashedpoint,
  },
];

export const StrokeStyleToIconMap = arrayUtils.toDictionary(DrawingStylesSwitcherConfig, x => x.mode, x => x.Icon);

export const DrawingStylesShapeSettings = [
  {
    Icon: Icons.ShapeCircle,
    mode: DrawingMarkShapes.Circle,
  },
  {
    Icon: Icons.ShapeStar,
    mode: DrawingMarkShapes.Star,
  },
  {
    Icon: Icons.ShapeSquare,
    mode: DrawingMarkShapes.Square,
  },
  {
    Icon: Icons.ShapePentagon,
    mode: DrawingMarkShapes.Pentagon,
  },
  {
    Icon: Icons.ShapeRoundedSquare,
    mode: DrawingMarkShapes.RoundedSquare,
  },
  {
    Icon: Icons.ShapeHexagon,
    mode: DrawingMarkShapes.Hexagon,
  },
  {
    Icon: Icons.ShapeTriangle,
    mode: DrawingMarkShapes.Triangle,
  },
  {
    Icon: Icons.ShapeOctagon,
    mode: DrawingMarkShapes.Octagon,
  },
  {
    Icon: Icons.ShapeRomb,
    mode: DrawingMarkShapes.Romb,
  },
];

export const ShapeToIconMap = arrayUtils.toDictionary(DrawingStylesShapeSettings, x => x.mode, x => x.Icon);

export const DrawingsDashedParameters: Record<string, number[]> = {
  [DrawingStrokeStyles.Normal]: [],
  [DrawingStrokeStyles.Dashed]: [4, 4],
  [DrawingStrokeStyles.DashDoted]: [4, 4, 1, 4],
};
