import { Constants } from 'unit-2d-database/constants';
import { Property } from 'unit-2d-database/interfaces';
import { FormatTypeGuards } from './format-typeguards';

export const getDynamicData = (properties: Property[]): [string[], string[]] => {
  const groups = new Set<string>();
  const units = new Set<string>(Constants.TRANSFORMED_UNITS);
  const countOfUnitUse: Record<string, number> = {};

  properties.forEach(p => {
    groups.add(p.groupName);
    if (FormatTypeGuards.isNumeric(p.value.format)
    && p.value.format.unit !== 'Text'
    ) {
      countOfUnitUse[p.value.format.unit] = countOfUnitUse[p.value.format.unit]
        ? countOfUnitUse[p.value.format.unit] + 1
        : 1;
      units.add(p.value.format.unit);
    }
  });
  const unitsList = Array.from(units).sort((a, b) => (countOfUnitUse[b] || 0) - (countOfUnitUse[a] || 0));
  return [Array.from(groups), unitsList];
};
