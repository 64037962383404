import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  DrawingsAnnotationActions,
} from 'common/components/drawings/actions/creators/annotation';
import { DrawingsGeometryParameters } from 'common/components/drawings/actions/payloads/annotation';
import { useRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import {
  DrawingsGeometryStyle,
} from 'common/components/drawings/interfaces/drawings-geometry-style';
import { DrawingsUndoRedoHelper } from 'common/components/drawings/utils/drawings-undo-redo-helper';
import { useUndoRedoFullApi } from 'common/undo-redo';

type UpdateStylesCallback<T extends keyof DrawingsGeometryStyle> = (
  instanceIds: string[],
  value: DrawingsGeometryStyle[T],
) => void;

export type UpdateStyleWithUndoCallback<T extends keyof DrawingsGeometryStyle = keyof DrawingsGeometryStyle> = (
  parameter: T,
  instances: Record<string, DrawingsGeometryInstance>,
  instanceIds: string[],
  value: DrawingsGeometryStyle[T],
  updateViewStyles: (instanceIds: string[], value: DrawingsGeometryStyle[T]) => void,
) => void;

export function useEditStylesWithUndo(): <T extends keyof DrawingsGeometryStyle>(
  parameter: T,
  instances: Record<string, DrawingsGeometryInstance>,
  instanceIds: string[],
  value: DrawingsGeometryStyle[T],
  updateViewStyles: (instanceIds: string[], value: DrawingsGeometryStyle[T]) => void,
) => void {
  const dispatch = useDispatch();
  const { addUndoRedo } = useUndoRedoFullApi();
  return useCallback(
    <T extends keyof DrawingsGeometryStyle>(parameter, instances, instanceIds, value, updateViewStyles) => {
      const { undo, redo } = DrawingsUndoRedoHelper.createUndoRedoStrokeStyleField<T>(
        instanceIds,
        parameter,
        value,
        (instanceId) => {
          if (!instances[instanceId]) {
            return;
          }
          return instances[instanceId].geometry[parameter] as DrawingsGeometryStyle[T];
        },
        (ids, field, newValue) => {
          updateViewStyles(ids, newValue);
          dispatch(DrawingsAnnotationActions.updateGeometryParams(
            ids,
            field,
            newValue as DrawingsGeometryParameters[T],
          ));
        },
      );
      redo();
      addUndoRedo(undo, redo);
    },
    [dispatch, addUndoRedo],
  );
}

export function useEditStyle<T extends keyof DrawingsGeometryStyle>(
  parameter: T,
  instances: Record<string, DrawingsGeometryInstance>,
): UpdateStylesCallback<T> {
  const { rendererApi } = useRendererApiContext();
  const editWithUndo = useEditStylesWithUndo();
  const updateViewStyles = useCallback(
    (instanceIds: string[], value: DrawingsGeometryStyle[T]) => {
      if (rendererApi) {
        rendererApi.changeEntitiesStyle(instanceIds, parameter, value);
      }
    },
    [rendererApi, parameter],
  );

  return useCallback(
    (
      instanceIds: string[],
      value: DrawingsGeometryStyle[T],
    ) => {
      return editWithUndo(parameter, instances, instanceIds, value, updateViewStyles);
    },
    [instances, parameter, editWithUndo, updateViewStyles],
  );
}

export function useEditStyleForSelectedIds<T extends keyof DrawingsGeometryStyle>(
  parameter: T,
  instances: Record<string, DrawingsGeometryInstance>,
  instancesIds: string[],
): (value: DrawingsGeometryStyle[T]) => void {
  const editStyle = useEditStyle(parameter, instances);
  return useCallback(
    (value: DrawingsGeometryStyle[T]) => {
      editStyle(instancesIds, value);
    },
    [editStyle, instancesIds],
  );
}
