import * as React from 'react';

export class KreoIconPivot extends React.Component {
  public render(): React.ReactNode {
    return (
      <svg
        width='24px'
        height='24px'
        viewBox='0 0 540 540'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
      <g transform='translate(0.000000,540.000000) scale(0.100000,-0.100000)' fill='currentColor' stroke='none'>
      <path
        d='M840 2700 l0 -1860 1860 0 1860 0 0 1860 0 1860 -1860 0 -1860 0 0
      -1860z m1010 1185 l0 -335 -335 0 -335 0 0 328 c0 181 3 332 7 335 3 4 154 7
      335 7 l328 0 0 -335z m2368 -2 l2 -333 -1010 0 -1010 0 0 335 0 335 1008 -2
      1007 -3 3 -332z m-2368 -1693 l0 -1010 -332 2 -333 3 -3 1008 -2 1007 335 0
      335 0 0 -1010z m2368 3 l-3 -1008 -1007 -3 -1008 -2 0 1010 0 1010 1010 0
      1010 0 -2 -1007z'
      />
      <path
        d='M3290 2785 l-255 -255 168 0 167 0 0 -194 c0 -184 -1 -195 -23 -227
      -12 -18 -38 -44 -56 -56 -32 -22 -43 -23 -227 -23 l-194 0 0 167 0 168 -255
      -255 -255 -255 252 -253 253 -252 3 165 2 165 163 0 c226 1 294 11 392 59 107
      53 183 129 236 236 48 98 58 166 59 392 l0 163 165 2 165 3 -252 253 -253 252
      -255 -255z'
      />
      </g>
      </svg>
    );
  }
}
