import { AppRoute as R } from 'common/routing/app-route';
import { ParametrizedAppRoute as PR } from 'common/routing/parametrized-app-route';
import { UrlsFabric } from 'routes/common/subscription-routes-wrap/urls-fabric';

import {
  Qto2dProjectRouteParams as ProjectRP,
} from './route-params';

/* eslint-disable max-len, space-in-parens */
const index =                          new R(                     '/2d');
const questionnaire =                  new R(                     `${index.path}/questionnaire`);
const thankYou =                       new R(                     `${index.path}/thank-you`);
const invitingPeople =                  new R(                     `${index.path}/inviting-people`);
const pageNotFound =                   new R(                     `${index.path}/page-not-found`);
const listing =                        new R(                     `${index.path}/projects`);
const project =                        new PR<ProjectRP>(         `${index.path}/project/:projectId`);
const quantityTakeOff =                new PR<ProjectRP>(         `${project.path}/quantity-take-off`);
const report =                         new PR<ProjectRP>(         `${project.path}/report`);
const database =                       new R(                     `${index.path}/database`);
const templates =                      new R(                     `${index.path}/templates`);
const demoProjects =                   new R(                     `${index.path}/demoprojects`);
const reportTemplates =                new R(                     `${index.path}/report-templates`);
const cancellationSurvey =             new R(                     `${index.path}/cancellation-survey`);
/* eslint-enable */

export const Urls = {
  index,
  thankYou,
  invitingPeople,
  pageNotFound,
  listing,
  questionnaire,
  project: {
    index: project,
    quantityTakeOff,
    report,
  },
  billing: UrlsFabric(index.path),
  database,
  templates,
  demoProjects,
  reportTemplates,
  cancellationSurvey,
};
