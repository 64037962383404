import autobind from 'autobind-decorator';
import * as React from 'react';

import './accordion-menu.scss';

import { DEFAULT_SPLITTER_SIZE, SplitterLayout } from '../splitter-layout';
import { CollapseTab } from './collapse-tab';

const tableControlHeight = 40;

interface State {
  paneConfigs: Array<{
    separatorClassName: string,
    isOpen: boolean,
    size: number,
    minSize: number,
    maxSize?: number,
  }>;
}

export interface TabConfig {
  name: string;
  isOpen?: boolean;
  children?: JSX.Element;
  onOpenStatusChange?: (status: boolean) => void;
  hideHeader?: boolean;
  minSize?: number;
}

interface Props {
  tabConfig: TabConfig[];
  children: JSX.Element[];
}

export class AccordionMenu extends React.PureComponent<Props, State> {
  private ref: React.RefObject<HTMLDivElement> = React.createRef();

  public constructor(props: Props) {
    super(props);

    const paneConfigs = [];
    for (let i = 0; i < this.props.children.length; i++) {
      const child = this.props.children[i];
      const config = this.props.tabConfig[i];
      if (!child) {
        continue;
      }

      paneConfigs.push({
        isOpen: config.isOpen,
        size: tableControlHeight,
        separatorClassName: '',
        minSize: tableControlHeight,
        maxSize: tableControlHeight,
      });

    }
    paneConfigs[0].isOpen = true;
    delete paneConfigs[0].maxSize;
    this.state = { paneConfigs };
  }

  public componentDidMount(): void {
    const containerRect = this.ref.current.getBoundingClientRect();
    const openTabCount = this.state.paneConfigs.filter(c => c.isOpen).length;
    const splitterHeight = (this.state.paneConfigs.length - 1) * DEFAULT_SPLITTER_SIZE;
    const collapseTabHeight = (this.state.paneConfigs.length - openTabCount) * tableControlHeight + splitterHeight;
    const size = (containerRect.height - collapseTabHeight) / openTabCount;
    const paneConfigs = this.state.paneConfigs.map(c => {
      return {
        ...c,
        size: c.isOpen
          ? size
          : c.size,
        minSize: c.isOpen
          ? 250
          : tableControlHeight,
        maxSize: c.isOpen
          ? undefined
          : tableControlHeight,
      };
    });
    this.setState({ paneConfigs });
  }

  public render(): React.ReactNode {
    const paneConfigs = this.state.paneConfigs;

    return (
      <div
        className='accordion-menu'
        ref={this.ref}
      >
        <SplitterLayout
          primaryIndex={0}
          horizontal={true}
          paneConfigs={paneConfigs}
        >
          {this.getChildren()}
        </SplitterLayout>
      </div>
    );
  }

  @autobind
  private getChildren(): JSX.Element[] {
    const result = [];
    for (let i = 0; i < this.props.children.length; i++) {
      const child = this.props.children[i];
      if (!child) {
        continue;
      }
      result.push(this.mapCollapseTabLayout(child, i));
    }

    return result;
  }

  @autobind
  private mapCollapseTabLayout(child: React.ReactNode, index: number): React.ReactNode {
    const tabConfig = this.props.tabConfig[index];
    const { name, hideHeader, children } = tabConfig;
    return hideHeader
      ? child
      : (
        <CollapseTab
          key={index}
          tabName={name}
          className={name.toLowerCase()}
          isOpenTab={this.state.paneConfigs[index].isOpen}
          index={index}
          onClickCollapseButton={this.onClickCollapseButton}
          tabElement={children}
        >
          {child}
        </CollapseTab>
      );
  }

  @autobind
  private onClickCollapseButton(index: number): void {
    const paneConfigs = [...this.state.paneConfigs];
    paneConfigs[index].isOpen = !this.state.paneConfigs[index].isOpen;
    this.setState(
      {
        paneConfigs,
      },
      () => {
        const oldPaneConfigs = this.state.paneConfigs;
        const openTabCount = oldPaneConfigs.filter(c => c.isOpen).length;
        const splitterHeight = (this.state.paneConfigs.length - 1) * DEFAULT_SPLITTER_SIZE;
        const collapseTabHeight = (oldPaneConfigs.length - openTabCount) * tableControlHeight + splitterHeight;
        const openTabsHeight = (this.getAllSize() - collapseTabHeight) / openTabCount;
        let remainingSize = this.getAllSize();

        const newPaneConfigs = [];
        for (let i = 0; i < oldPaneConfigs.length; i++) {
          const paneConfig = oldPaneConfigs[i];
          const isLastTab = i === oldPaneConfigs.length - 1;

          paneConfig.separatorClassName = '';

          if (paneConfig.isOpen) {
            remainingSize -= openTabsHeight;
            paneConfig.size = openTabsHeight;
            paneConfig.minSize = this.props.tabConfig[i].minSize || 250;

            delete paneConfig.maxSize;
            newPaneConfigs.push(paneConfig);
            continue;
          } else {
            paneConfig.minSize = tableControlHeight;
            paneConfig.maxSize = tableControlHeight;
          }

          if (isLastTab && openTabCount === 0) {
            paneConfig.size = remainingSize;
            newPaneConfigs.push(paneConfig);
            continue;
          }

          remainingSize -= tableControlHeight;
          paneConfig.size = tableControlHeight;
          newPaneConfigs.push(paneConfig);
        }

        if (this.props.tabConfig[index].onOpenStatusChange) {
          this.props.tabConfig[index].onOpenStatusChange(paneConfigs[index].isOpen);
        }
        this.setState({ paneConfigs: newPaneConfigs });
      });
  }

  @autobind
  private getAllSize(): number {
    return this.ref.current.getBoundingClientRect().height;
  }
}
