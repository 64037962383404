import * as React from 'react';

import './validation-results-step.scss';

import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { SvgSpinner } from 'common/components/svg-spinner';
import { State } from 'common/interfaces/state';
import { ValidationActions } from '../../actions/creators/validation';
import { ValidationStepsNames } from '../../constants/validation-steps-names';
import { ProjectValidationResultsPayload } from '../../interfaces/project-validation-results';
import { ScenarioState } from '../../interfaces/scenario-state';
import {
  StepProps,
  ValidationStepWrapper,
} from '../../pages/validation/components/validation-stepper';
import { ValidationStatisticTab } from '../validation-statistic-tab';

interface OwnProps {
  scenarioId: number;
  projectId: number;
}

interface StateProps {
  statistic: ProjectValidationResultsPayload;
  scenarioState: ScenarioState;
}

interface DispatchProps {
  getValidationResults: (projectId: number, scenarioId: number) => void;
}

interface Props extends OwnProps, StateProps, StepProps, DispatchProps {}

export class ValidationResultsStepComponent extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.getValidationResults(this.props.projectId, this.props.scenarioId);
  }

  public componentDidUpdate(): void {
    if (!this.props.statistic) {
      this.props.getValidationResults(this.props.projectId, this.props.scenarioId);
    }
  }

  public render(): React.ReactNode {
    const scenarioState = this.props.scenarioState;

    return (
      <ValidationStepWrapper className='validation-result-step'>
        <h3 className='validation-result-step__title'>Validation Results</h3>
        <div className='validation-result-step__content'>
          {this.renderCharts()}
          {scenarioState && scenarioState.inCalculation && (
            <div className='validation-result-step__spinner'>
              <SvgSpinner size='small' /> <span>Calculating...</span>
            </div>
          )}
        </div>
      </ValidationStepWrapper>
    );
  }

  private renderCharts(): React.ReactNode {
    if (!this.props.statistic) return null;
    const {
      classificationStatistic,
      activityAssignmentStatistic,
      measurementStatistic,
    } = this.props.statistic;
    return (
      <div className='validation-result-step__charts'>
        <ValidationStatisticTab
          statistic={classificationStatistic}
          name={ValidationStepsNames.classification}
          legendDescription={'Uncertain Elements'}
        />
        <ValidationStatisticTab
          statistic={activityAssignmentStatistic}
          name={ValidationStepsNames.activityAssignment}
          legendDescription={'Undefined Activities'}
        />
        <ValidationStatisticTab
          statistic={measurementStatistic}
          name={ValidationStepsNames.measurements}
          legendDescription={'Not analyzed elements'}
        />
      </div>
    );
  }
}

function mapStateToProps(state: State): StateProps {
  const project = state.projects.currentProject;
  const scenario = state.scenarios.active_scenario;
  const scenarioState = scenario && state.projects.scenarioStates[scenario.scenarioGuid];
  return {
    statistic: project.validationFinishResult,
    scenarioState,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    getValidationResults: (projectId: number, scenarioId: number) => {
      dispatch(ValidationActions.getValidationResultsRequest(projectId, scenarioId));
    },
  };
}

export const ValidationResultsStep = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ValidationResultsStepComponent);
