import autobind from 'autobind-decorator';
import * as paper from 'paper';
import {
  DrawingsCanvasColors,
  DrawingsCanvasConstants,
} from 'common/components/drawings/constants/drawing-canvas-constants';
import { ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { GetCurrentPageSizeInfoCallback } from '../../interfaces';
import { DrawingsEngineOrientation } from '../../interfaces/orientation';
import { DrawingsGeometryEntity } from '../base';

interface DrawingsGeometryGuidelineConfig {
  orientation: DrawingsEngineOrientation;
  value: number;
  getCurrentDrawingInfo: GetCurrentPageSizeInfoCallback;
  layer: paper.Layer;
  observableRenderParameters: ContextObserverWithPrevious<DrawingsRenderParams>;
}

export class DrawingsGeometryGuideline implements DrawingsGeometryEntity {
  private _path: paper.Path.Line;
  private _config: DrawingsGeometryGuidelineConfig;

  constructor(config: DrawingsGeometryGuidelineConfig) {
    this._config = config;
    this.render();
    this._config.observableRenderParameters.subscribe(this.updateRenderParams);
  }

  public set orientation(value: DrawingsEngineOrientation) {
    if (value === this._config.orientation) {
      return;
    }
    this._config.orientation = value;
    const { width, height }  = this._config.getCurrentDrawingInfo();
    this._path.firstSegment.point = this.updateValueInPointByOrientation(new paper.Point(0, 0), this.value);
    this._path.lastSegment.point = this.updateValueInPointByOrientation(new paper.Point(width, height), this.value);
  }

  public set value(value: number) {
    this._path.firstSegment.point = this.updateValueInPointByOrientation(this._path.firstSegment.point, value);
    this._path.lastSegment.point = this.updateValueInPointByOrientation(this._path.lastSegment.point, value);
    this._config.value = value;
  }

  public get value(): number {
    return this._config.value;
  }

  public destroy(): void {
    this._path.remove();
    this._config.observableRenderParameters.unsubscribe(this.updateRenderParams);
  }

  private render(): void {
    const { width, height }  = this._config.getCurrentDrawingInfo();
    this._path = new paper.Path.Line(
      this.updateValueInPointByOrientation(new paper.Point(0, 0), this._config.value),
      this.updateValueInPointByOrientation(new paper.Point(width, height), this._config.value),
    );
    this._path.strokeColor = DrawingsCanvasColors.pointColor;
    this._path.addTo(this._config.layer);
  }

  private updateValueInPointByOrientation({ x, y }: paper.Point, value: number): paper.Point {
    return this._config.orientation === DrawingsEngineOrientation.Horizontal
      ? new paper.Point(x, value)
      : new paper.Point(value, y);
  }

  @autobind
  private updateRenderParams({ zoom }: DrawingsRenderParams): void {
    const dashArray = DrawingsCanvasConstants.dashArray / zoom;
    this._path.dashArray = [dashArray, dashArray];
    this._path.strokeWidth = DrawingsCanvasConstants.infoLinesStroke / zoom;
  }
}
