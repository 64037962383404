import classNames from 'classnames';
import * as React from 'react';

import './measurements-extractor-layout-container.scss';

import { MeasurementsExtractorFooter } from '../measurements-extractor-footer';
import { MeasurementsExtractorsHeader } from '../measurements-extractors-header';
import { ValidationResizableEditor } from '../validation-resizable-editor';

interface Props {
  cancelButtonEnabled: boolean;
}

export const MeasurementsExtractorLayoutContainer: React.FC<Props> = ({ cancelButtonEnabled, children }) => {
  const className = classNames('measurements-extractor-layout-container', {
    'measurements-extractor-layout-container--with-cancel': cancelButtonEnabled,
  });
  return (
    <ValidationResizableEditor>
      <div className={className}>
        <div className='measurements-extractor-layout-container__wrap'>
          <div className='measurements-extractor-layout-container__wrap__content'>
            <MeasurementsExtractorsHeader/>
            <div className='measurements-extractor-layout-container__body'>
              {children}
            </div>
          </div>
        </div>
        {cancelButtonEnabled && <MeasurementsExtractorFooter/>}
      </div>
    </ValidationResizableEditor>
  );
};
