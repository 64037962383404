import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import { FileUtils } from 'common/utils/file-utils';
import { AccountActions } from '../../../units/account/actions/creators';
import { ImageUploadZone } from './image-upload-zone';
import { RESTRICT_AVATAR_DIALOG_NAME, RestrictImageDialog } from './restrict-image-dialog';


interface OwnProps {
  previewUrl: string;
  defaultImagePreviewUrl?: string;
  deleteImage: () => void;
  // todo typedef
  onLoadEnd: (response: string) => void;
  size?: string;
  prefixName?: string;
  // пока не обновим redux и react-redux
  forwardImageZoneRef?: (ref: ImageUploadZone) => void;
}

interface DispatchProps {
  imageInterceptor: (file: File) => void;
}

interface Props extends OwnProps, DispatchProps { }

const ImageUploaderComponent: React.FC<Props> = ({
  previewUrl,
  defaultImagePreviewUrl,
  size,
  prefixName = '',
  deleteImage,
  imageInterceptor,
  onLoadEnd,
  forwardImageZoneRef,
}) => {
  const imageUploadZone = useRef<ImageUploadZone>();

  useLayoutEffect(() => {
    if (forwardImageZoneRef) {
      forwardImageZoneRef(imageUploadZone.current);
    }
  }, [forwardImageZoneRef]);

  const onCropImage = useCallback((image) => {
    if (imageUploadZone) {
      const fileToSend = FileUtils.dataURItoBlob(image.toDataURL());
      imageUploadZone.current.upload(fileToSend);
    }
  }, [imageUploadZone]);

  return (
    <>
      <ImageUploadZone
        previewUrl={previewUrl}
        defaultImagePreviewUrl={defaultImagePreviewUrl}
        ref={imageUploadZone}
        deleteImage={deleteImage}
        imageInterceptor={imageInterceptor}
        onLoadEnd={onLoadEnd}
        size={size}
      />
      <RestrictImageDialog
        onCrop={onCropImage}
        prefixName={prefixName}
      />
    </>
  );
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps {
  const prefixName = props.prefixName || '';
  return {
    imageInterceptor: file => {
      dispatch(AccountActions.setAvatarFile(file));
      dispatch(KreoDialogActions.openDialog(RESTRICT_AVATAR_DIALOG_NAME + prefixName));
    },
  };
}

export const ImageUploader = connect(null, mapDispatchToProps)(ImageUploaderComponent);
