import classNames from 'classnames';
import { Location } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './validation-stepper-progress-item.scss';

import { State } from 'common/interfaces/state';
import { KreoIconAddedDone } from 'common/UIKit';
import { StringUtils } from 'common/utils/string-utils';
import { isValidationStepGreaterThan, isValidationStepLessThan, ValidationStep } from '../../enums/validation-step';
import { getValidationStepName } from '../../enums/validation-step-name';
import { ProjectValidationState } from '../../interfaces/project-validation-state';


interface OwnProps {
  index: number;
  title: string;
  stepperMountPath: string;
  step: ValidationStep;
}

interface StateProps {
  validationState: ProjectValidationState;
  location: Location;
}

interface Props extends OwnProps, StateProps {
}

class ValidationStepperProgressItemComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { stepperMountPath, step, validationState, title } = this.props;

    const isApproved = this.isApproved();
    const isDisabled = isValidationStepLessThan(validationState.validationStep, step);

    const itemClassName = classNames(
      'validation-stepper-progress-item',
      {
        'validation-stepper-progress-item--disabled': isDisabled,
        'validation-stepper-progress-item--approved': isApproved,
      });

    if (isDisabled) {
      return (
        <div className={itemClassName}>
          {this.renderContent()}
        </div>
      );
    }

    return (
      <NavLink
        className={itemClassName}
        activeClassName='validation-stepper-progress-item--active'
        to={`${stepperMountPath}/${getValidationStepName(step)}`}
        data-control-name={`${StringUtils.titleCaseToSnakeCase(title)}-link`}
      >
        {this.renderContent()}
      </NavLink>
    );
  }

  private renderContent(): React.ReactNode {
    const icon = this.getIcon();
    return (
      <React.Fragment>
        <div className={'validation-stepper-progress-item__icon'}>
          {icon ? <KreoIconAddedDone /> : this.props.index + 1}
        </div>
        <b className='validation-stepper-progress-item__title'>{this.props.title}</b>
      </React.Fragment>
    );
  }

  private getIcon(): JSX.Element | null {
    if (this.isApproved()) {
      return <KreoIconAddedDone />;
    }

    return null;
  }

  private isApproved(): boolean {
    const { step, validationState } = this.props;
    return isValidationStepGreaterThan(validationState.validationStep, step) ||
      (validationState.validationStep === ValidationStep.Results && step === ValidationStep.Results);
  }
}

function mapStateToProps(state: State): StateProps {
  const currentProject = state.projects.currentProject;
  return {
    validationState: currentProject ? currentProject.validationState : null,
    location: state.router.location,
  };
}

export const ValidationStepperProgressItem = connect(mapStateToProps)(ValidationStepperProgressItemComponent);
