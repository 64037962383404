import { ColDef, ColGroupDef } from 'ag-grid-community';

import { arrayUtils } from 'common/utils/array-utils';
import { AsyncArrayUtils } from 'common/utils/async-array-util';
import { ExtractorConfig, GraphStorageRecordsConfig } from '../../interfaces/graph-storage-records-config';
import { QtoRecords } from '../../interfaces/quantity-take-off/quantity-take-off-records';
import { CustomCell } from '../quantity-take-off-common-table/custom-cell';
import { AGG_HASH } from '../quantity-take-off-common-table/custom-cell/aggregation-cell/aggregation-cell';
import { ColumnOrderHelper } from './column-order-helper';
import { QtoLeftPanelConstants } from './constants';
import { expandAggHash, expandRowData } from './expand-row-data';
import { QtoRecord, TableData } from './interfaces';
import { isEFPrefix } from './is-ef-prefix';
import { isLocation } from './is-location';
import { isLocationInst } from './is-location-inst';
import { addAdditionalColumn, groupColummns, isMainColumn, setColumnConfig } from './map-columns-def';


const STEPS = 500;
const DELAY = 0.001;

export interface LeftPanelTablesConfigs {
  tableConfigs: {
    location: TableData,
    element: TableData,
  };
  filterConfigs: {
    location: QtoRecord[],
    element: QtoRecord[],
  };
}

const getAggHashColumn = (extractors: ExtractorConfig): any => ({
  headerName: 'Sum of the quantities',
  field: AGG_HASH,
  rowGroup: true,
  enableRowGroup: true,
  hide: true,
  cellRenderer: CustomCell.aggregationCell(extractors),
  width: 125,
  resizable: true,
});

const extraInfoDefaultValue = ['Untitled Extra Property'];
const separatorSymbol = ', ';

const getPropertiesValue = (value: Array<string | number>): Array<string | number> => (value as string[]).sort(
  arrayUtils.localCompareWithNumber,
);

const getExtraInfoValue = (value: string[]): string[] => value && value.length ? value : extraInfoDefaultValue;

const isExtraInfo = (key: string): boolean => key === QtoLeftPanelConstants.EXTRA_INFO;

const prepareStringToHashKey = (value: string[]): string => value && value.join('');

const getElementAggHashKey = (rowData: Record<string, Array<string | number>>): string =>
  prepareStringToHashKey(rowData.f_name as string[])
  + prepareStringToHashKey(rowData.f_cat as string[])
  + prepareStringToHashKey(rowData.f_fam as string[])
  + prepareStringToHashKey(rowData.f_elem_type as string[])
  + prepareStringToHashKey(rowData.extra_info as string[]);

const getElementName = (id: string): string[] => [`Element ${id}`];

const setUndefinedValue = (rowData: Record<string, Array<string | number>>, col: ColDef): void => {
  if (col.field && !isEFPrefix(col.field) && !rowData[col.field]) {
    rowData[col.field] = ['Undefined'];
  }
};

const setUndefinedValueInGroup = (
  rowData: Record<string, Array<string | number>>,
  columns: Array<ColGroupDef | ColDef>,
): void => {
  for (const colGroup of columns) {
    if ((colGroup as ColGroupDef).children) {
      setUndefinedValueInGroup(rowData, (colGroup as ColGroupDef).children);
    } else {
      setUndefinedValue(rowData, colGroup);
    }
  }
};


const getRowData = (
  props: Record<string, Array<string | number>>,
  id: number,
  revitId: string,
  config: GraphStorageRecordsConfig,
): { [key: string]: Array<string | number> } => {
  const rowProps: Record<string, Array<string | number>> = {
    id: [id],
    elName: getElementName(revitId),
  };
  for (const [key, values] of Object.entries(props)) {
    if (
      key !== QtoLeftPanelConstants.OBJECT_COMPLEX_KEY
      && !isLocationInst(key)
      && key !== QtoLeftPanelConstants.THICKNESS_KEY
    ) {
      const value = getPropertiesValue(values);
      rowProps[key] = isExtraInfo(key) ? getExtraInfoValue(value as string[]) : value;
      expandRowData(rowProps, key, config);
    }
  }

  const elementKey = getElementAggHashKey(rowProps);
  expandAggHash(rowProps, elementKey);
  return rowProps;
};

interface RecordProps {
  id: string | number;
  props: Record<string, Array<string | number>>;
}

function getResultRecord(
  row: Record<string, Array<string | number>>,
): {
  proccessedRowData: Record<string, string | number>,
  filterRecord: RecordProps,
} {
  const id = row.id[0];
  const props = {};
  const agGridProps = { id };
  for (const [key, value] of Object.entries(row)) {
    if (isMainColumn(key) && !isEFPrefix(key)) {
      const filterValue = value;
      props[key] = filterValue;
    }
    agGridProps[key] = value.length > 1 ? value.join(separatorSymbol) : value[0];
  }
  return {
    proccessedRowData: agGridProps,
    filterRecord: { id, props },
  };
}


async function* prepareRowDataIterator(
  records: QtoRecords,
  revitIds: Record<string, string>,
  config: GraphStorageRecordsConfig,
): AsyncIterableIterator<{ isLocationRow: boolean, rowData: { [key: string]: Array<string | number> } }> {
  for await (const record of AsyncArrayUtils.iteratorWithDelayEveryNStep(records, DELAY, STEPS)) {
    yield {
      isLocationRow: isLocation(record.props[QtoLeftPanelConstants.OBJECT_COMPLEX_KEY][0] as string),
      rowData: getRowData(record.props, record.id as number, revitIds[record.id], config),
    };
  }
}

const mapData = async (
  records: QtoRecords,
  revitIds: Record<string, string>,
  columnOrderHelper: ColumnOrderHelper,
  config: GraphStorageRecordsConfig,
): Promise<LeftPanelTablesConfigs> => {
  const preparedElementRowData = [];
  const preparedLocationRowData = [];
  const elementsMainColDefDictionary = {};
  const elementColDefs: Record<string, ColDef> = {};
  const elementColumnsValues = {};
  const elementColumnGroupOrder = columnOrderHelper.getColumnGroupOrder(false);
  const locationsMainColDefDictionary = {};
  const locationsColDefs: Record<string, ColDef> = {};
  const locationsColumnsValues = {};
  const locationsColumnGroupOrder = columnOrderHelper.getColumnGroupOrder(true);
  for await (const { isLocationRow, rowData } of prepareRowDataIterator(records, revitIds, config)) {
    if (isLocationRow) {
      preparedLocationRowData.push(rowData);
      for (const [key, value] of Object.entries(rowData)) {
        if (isMainColumn(key)) {
          setColumnConfig(
            locationsMainColDefDictionary,
            key,
            locationsColDefs,
            locationsColumnGroupOrder,
            config,
            locationsColumnsValues,
            true,
          );
        }
        addAdditionalColumn(
          config,
          key,
          value as string[],
          locationsColDefs,
          locationsMainColDefDictionary,
          locationsColumnsValues,
        );
      }
    } else {
      preparedElementRowData.push(rowData);
      for (const [key] of Object.entries(rowData)) {
        if (isMainColumn(key)) {
          setColumnConfig(
            elementsMainColDefDictionary,
            key,
            elementColDefs,
            elementColumnGroupOrder,
            config,
            elementColumnsValues,
            false,
          );
        }
      }
    }
  }
  const groupedElementColDefs = groupColummns(config, elementColDefs);
  const groupedLocationColDefs = groupColummns(config, locationsColDefs);
  const elementRowData = new Array<Record<string, string | number>>();
  const filterElementRecords = new Array<RecordProps>();
  for await (const rowData of AsyncArrayUtils.iteratorWithDelayEveryNStep(preparedElementRowData, DELAY, STEPS)) {
    setUndefinedValueInGroup(rowData, groupedElementColDefs);
    const { proccessedRowData, filterRecord } =  getResultRecord(rowData);
    elementRowData.push(proccessedRowData);
    filterElementRecords.push(filterRecord);
  }
  const locationRowData = new Array<Record<string, string | number>>();
  const filterLocationRecords = new Array<RecordProps>();
  for await (const rowData of AsyncArrayUtils.iteratorWithDelayEveryNStep(preparedLocationRowData, DELAY, STEPS)) {
    const { proccessedRowData, filterRecord } =  getResultRecord(rowData);
    locationRowData.push(proccessedRowData);
    filterLocationRecords.push(filterRecord);
  }
  return {
    tableConfigs: {
      element: {
        rowData: elementRowData,
        columnDefs: groupedElementColDefs,
        context: { isImperialUnit: false },
      },
      location: {
        rowData: locationRowData,
        columnDefs: groupedLocationColDefs,
        context: { isImperialUnit: false },
      },
    },
    filterConfigs: {
      location: filterLocationRecords,
      element: filterElementRecords,
    },
  };
};

export const getTablesConfig = async (
  records: QtoRecords,
  config: GraphStorageRecordsConfig,
  revitIds: Record<string, string>,
  columnOrderHelper: ColumnOrderHelper,
): Promise<LeftPanelTablesConfigs> => {
  const { tableConfigs, filterConfigs } = await mapData(records, revitIds, columnOrderHelper, config);
  columnOrderHelper.sortColumnsConfig(tableConfigs.element.columnDefs, false);
  columnOrderHelper.sortColumnsConfig(tableConfigs.location.columnDefs, true);
  const aggColumn = getAggHashColumn(config.extractors as ExtractorConfig);
  tableConfigs.location.columnDefs.unshift(aggColumn);
  tableConfigs.element.columnDefs.unshift(aggColumn);
  return { tableConfigs, filterConfigs };
};
