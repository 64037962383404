import autobind from 'autobind-decorator';
import * as React from 'react';

interface Props {
  className?: string;
  isCursorOnTimeframeHandle: boolean;
  isResizeAffterSelectionComplete?: boolean;
  onWheel?: (event: React.WheelEvent<HTMLDivElement>, offset: number) => void;
  handleClick?: (realOffset: number) => void;
  onCompleteSelection: (realStartOffset: number, width: number) => void;
  clampStartPoint: (realStartOffset: number) => number;
  isSelectInValidArea?: (realOffset: number) => boolean;
}

interface State {
  selectionStart: number;
  selectionEnd: number;
  timeframeGestureInProgress: boolean;
  isCursorOnTimeline: boolean;
  mouseButtonPressed: boolean;
}

export class OverlayTimeSelector extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    isSelectInValidArea: () => true,
  };

  private readonly clickToleranceTime: number = 1000;
  private readonly clickToleranceDistance: number = 3;
  private containerRef: HTMLDivElement;
  private mouseDownTime: number = 0;
  private lastMouseX: number = 0;
  private lastMouseY: number = 0;
  private mouseMovement: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectionStart: null,
      selectionEnd: null,
      timeframeGestureInProgress: false,
      isCursorOnTimeline: false,
      mouseButtonPressed: false,
    };
  }


  public render(): React.ReactNode {
    return (
      <div
        className={this.props.className}
        onWheel={this.onWheel}
        onMouseEnter={this.onMouseEnter}
        onMouseDown={this.onMouseDown}
        onMouseLeave={this.onMouseLeave}
        ref={this.saveContainerRef}
      >
        { this.state.selectionStart !== null &&
          !this.props.isCursorOnTimeframeHandle &&
          !this.state.timeframeGestureInProgress && (
            <div
              className='gantt-chart__project-timeline__selection-pointer'
              style={{ left: this.state.selectionStart }}
            />
        )}
        {this.state.selectionEnd !== null && (
          <div
            className='gantt-chart__project-timeline__selection'
            style={{
              left: Math.min(this.state.selectionStart, this.state.selectionEnd),
              width: Math.abs(this.state.selectionEnd - this.state.selectionStart),
            }}
          />
        )}
        {this.state.selectionEnd !== null && (
          <div
            className='gantt-chart__project-timeline__selection-pointer'
            style={{ left: this.state.selectionEnd }}
          />
        )}
        {this.props.children}
      </div>
    );
  }

  private get containerOffset(): number {
    return this.containerRef ? this.containerRef.getBoundingClientRect().left : 0;
  }


  @autobind
  private onWheel(e: React.WheelEvent<HTMLDivElement>): void {
    if (this.props.onWheel) {
      this.props.onWheel(e, this.containerOffset);
    }
  }

  @autobind
  private saveContainerRef(element: HTMLDivElement): void {
    this.containerRef = element;
  }

  @autobind
  private onMouseEnter(): void {
    this.setState({ isCursorOnTimeline: true });
    document.addEventListener('mousemove', this.onMouseMove);
  }

  @autobind
  private onMouseMove(event: MouseEvent): void {
    if (this.state.mouseButtonPressed) {
      this.mouseMovement += Math.sqrt(
        (event.pageX - this.lastMouseX) ** 2 +
        (event.pageY - this.lastMouseY) ** 2);

      this.lastMouseX = event.pageX;
      this.lastMouseY = event.pageY;

      if (
        performance.now() - this.mouseDownTime > this.clickToleranceTime ||
        this.mouseMovement > this.clickToleranceDistance
      ) {
        if (!this.props.isSelectInValidArea(event.pageX - this.containerOffset)) {
          return;
        }
        const selectionEnd = this.props.clampStartPoint(event.pageX - this.containerOffset);

        const selectionLeftBorder = Math.min(this.state.selectionStart, selectionEnd);
        const selectionWidth = Math.abs(selectionEnd - this.state.selectionStart);

        if (!this.props.isResizeAffterSelectionComplete) {
          this.props.onCompleteSelection(selectionLeftBorder, selectionWidth);
        }

        this.setState({ selectionEnd });
      }
    } else {
      if (!this.props.isSelectInValidArea(event.pageX - this.containerOffset)) {
        return;
      }
      const pointerOffset = this.props.clampStartPoint(event.pageX - this.containerOffset);
      this.setState({
        selectionStart: pointerOffset,
      });
    }
  }

  @autobind
  private onMouseDown(event: React.MouseEvent<HTMLDivElement>): void {
    if (!this.props.isSelectInValidArea(event.pageX - this.containerOffset)) return;
    if (
      event.button === 2
      || this.props.isCursorOnTimeframeHandle
    ) {
      this.setState({
        timeframeGestureInProgress: true,
      });

      document.addEventListener('mouseup', this.timeframeGestureMouseUpHandler);
      return;
    }
    if (event.button === 0) {
      this.mouseDownTime = performance.now();
      this.lastMouseX = event.pageX;
      this.lastMouseY = event.pageY;
      this.mouseMovement = 0;


      this.setState({
        selectionEnd: event.pageX - this.containerOffset,
        mouseButtonPressed: true,
      });
      document.addEventListener('mouseup', this.onMouseUp);
    }
  }

  @autobind
  private timeframeGestureMouseUpHandler(_event: MouseEvent): void {
    this.setState({
      timeframeGestureInProgress: false,
    });

    document.removeEventListener('mouseup', this.timeframeGestureMouseUpHandler);
  }

  @autobind
  private onMouseUp(event: MouseEvent): void {
    if (
      performance.now() - this.mouseDownTime < this.clickToleranceTime
      && this.mouseMovement <= this.clickToleranceDistance
    ) {
      this.handleClick(event.pageX);
    }

    if (
      this.props.isResizeAffterSelectionComplete
      &&
      this.state.selectionEnd !== null
      && this.state.selectionStart !== null) {
      const width = Math.abs(this.state.selectionEnd - this.state.selectionStart);
      const start = Math.min(this.state.selectionEnd, this.state.selectionStart);
      this.props.onCompleteSelection(start, width);
    }
    this.setState({
      selectionEnd: null,
      mouseButtonPressed: false,
    });

    document.removeEventListener('mouseup', this.onMouseUp);
    if (!this.state.isCursorOnTimeline) {
      this.setState({
        selectionStart: null,
      });
      document.removeEventListener('mousemove', this.onMouseMove);
    }
  }

  @autobind
  private onMouseLeave(): void {
    this.setState({ isCursorOnTimeline: false });
    if (this.state.selectionEnd === null) {
      this.setState({
        selectionStart: null,
        selectionEnd: null,
      });
      document.removeEventListener('mousemove', this.onMouseMove);
    }
  }

  private handleClick(pointerPageX: number): void {
    const pointerOffset = this.props.clampStartPoint(pointerPageX - this.containerOffset);
    if (this.props.handleClick) {
      this.props.handleClick(pointerOffset);
    }
  }
}
