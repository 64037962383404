import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { KreoColors } from 'common/enums/kreo-colors';
import { KreoIconHotkeys } from 'common/UIKit';
import { KreoToolbarButton } from '../kreo-toolbar/kreo-toolbar-button';
import { EngineHotkeysHintPopup } from './engine-hotkeys-hint-popup';
import { EngineHotkeysBlock, EngineHotkeysListItem } from './engine-hotkeys-list';


interface Props {
  specialAbilitiesHotkeyList?: EngineHotkeysListItem[];
  hotkeyList: EngineHotkeysBlock[];
  containerRef: HTMLDivElement;
}

interface State {
  isOpen: boolean;
}


export class EngineHotkeysHint extends React.Component<Props, State> {
  private engineCanvas: HTMLCanvasElement;
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }
  public render(): React.ReactNode {
    if (!this.props.containerRef) {
      return null;
    }
    const { isOpen } = this.state;
    return (
      <React.Fragment>
        <KreoToolbarButton
          onClick={this.onOpen}
          active={isOpen}
          controlName='engine-hotkeys-hint-button'
          defaultTooltipText='Keyboard Shortcuts'
        >
          <KreoIconHotkeys color={isOpen ? KreoColors.white : null} />
        </KreoToolbarButton>
        {this.renderPopup()}
      </React.Fragment>
    );
  }

  private renderPopup(): React.ReactNode {
    const specialAbilities = {
      blockName: 'Special Abilities',
      hotkeyList: this.props.specialAbilitiesHotkeyList,
    };
    return ReactDOM.createPortal(
      (
      <CSSTransition
        in={this.state.isOpen}
        mountOnEnter={true}
        unmountOnExit={true}
        timeout={200}
        classNames='engine-hotkeys-hint-popup'
      >
        <EngineHotkeysHintPopup
          specialAbilities={this.props.specialAbilitiesHotkeyList && specialAbilities}
          hotkeyList={this.props.hotkeyList}
          onClose={this.onClose}
        />
      </CSSTransition>
      ),
      this.props.containerRef,
    );
  }

  @autobind
  private onOpen(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    if (this.props.containerRef) {
      this.engineCanvas = this.props.containerRef.querySelector('canvas');
      if (this.engineCanvas) {
        this.engineCanvas.focus();
      }
    }
    this.setState({ isOpen: !this.state.isOpen });
  }

  @autobind
  private onClose(): void {
    if (this.engineCanvas) {
      this.engineCanvas.focus();
    }
    this.setState({ isOpen: false });
  }
}
