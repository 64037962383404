import { RequestStatus } from 'common/enums/request-status';
import { Feed } from 'common/interfaces/feed';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { CurrencyModel } from '../../account/interfaces/currency-model';
import {
  AttachDatabasesParams,
  AttachEmployeeParams,
  AttachEmployeeSucceedParams,
  AttachEmployeeToProjectParams,
  CompanyCurrencyPayload,
  CompanyIdAndSubscriptionTypePayload,
  CompanyMockSubscriptionPayload,
  CompanyPreferImperialPayload,
  DatabaseFeedParams,
  DetachDatabasesParams,
} from '../actions/payloads/companies';
import { AdminCompaniesActionTypes } from '../actions/types/companies';
import { AdminDatabaseVm } from '../interfaces/admin-database-vm';
import { FeedParams } from '../interfaces/feed';
import { ShortRole } from '../interfaces/short-role';
import { ShortUser } from '../interfaces/short-user';
import { SubscriptionPlanOption } from '../interfaces/subscription-plan-option';
import { User } from '../interfaces/user';
import { AdminCompaniesState } from '../pages/companies/interfaces';

export const adminCompaniesReducerMethods: ReducerMethods<AdminCompaniesState> = {
  [AdminCompaniesActionTypes.LOAD_REQUEST]: (state, payloads: FeedParams) => {
    const helper = new MonoliteHelper(state);

    if (state.data && payloads.skip < state.data.length) {
      helper.set((_) => _.data, []);
    }

    return helper
      .set((_) => _.status, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_SUCCEED]: (state, payload: Feed<User>) => {
    const helper = new MonoliteHelper(state);

    return helper
      .set((_) => _.data, [...state.data, ...payload.data])
      .set((_) => _.count, payload.count)
      .set((_) => _.status, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.DELETE_PROJECTS_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.deletedProjects, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.DELETE_PROJECTS_SUCCEED]: (state, payload: number) => {
    const helper = new MonoliteHelper(state);
    const index = state.data.findIndex((c) => c.id === payload);

    if (index >= 0) {
      helper.set((_) => _.data[index].documents, state.data[index].documents.map((d) => {
        return {
          ...d,
          isDeleted: true,
        };
      }));
    }

    return helper
      .set((_) => _.statuses.deletedProjects, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_DATABASES_REQUEST]: (state, payloads: DatabaseFeedParams) => {
    const helper = new MonoliteHelper(state);

    if (state.databaseFeed.data && payloads.skip < state.databaseFeed.data.length) {
      helper.set((_) => _.databaseFeed.data, []);
    }

    return helper
      .set((_) => _.databaseFeed.status, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_DATABASES_SUCCEED]: (state, payload: Feed<AdminDatabaseVm>) => {
    const helper = new MonoliteHelper(state);

    return helper
      .set((_) => _.databaseFeed.data, [...state.databaseFeed.data, ...payload.data])
      .set((_) => _.databaseFeed.count, payload.count)
      .set((_) => _.databaseFeed.status, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.ATTACH_DATABASES_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.attachDatabases, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.ATTACH_DATABASES_SUCCEED]: (state, payload: AttachDatabasesParams) => {
    const helper = new MonoliteHelper(state);
    const databases = state.databaseFeed.data.filter((d) => payload.databaseIds.includes(d.id));
    const companyIndex = state.data.findIndex((c) => c.id === payload.companyId);

    if (companyIndex >= 0) {
      helper.set((_) => _.data[companyIndex].databases, [...databases, ...state.data[companyIndex].databases]);
      helper.set(
        (_) => _.databaseFeed.data,
        state.databaseFeed.data.filter((d) => !payload.databaseIds.includes(d.id)),
      );
      helper.set((_) => _.databaseFeed.count, state.databaseFeed.count - databases.length);
    }
    // Get dp. Push it to companies
    // pop it from databases

    return helper
      .set((_) => _.statuses.attachDatabases, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.DETACH_DATABASE_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.attachDatabases, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.DETACH_DATABASE_SUCCEED]: (state, payload: DetachDatabasesParams) => {
    const helper = new MonoliteHelper(state);

    const companyIndex = state.data.findIndex((c) => c.id === payload.companyId);

    if (companyIndex >= 0) {
      helper.set(
        (_) => _.data[companyIndex].databases,
        state.data[companyIndex].databases.filter((d) => d.id !== payload.databaseId),
      );
    }

    return helper
      .set((_) => _.statuses.attachDatabases, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_EMPLOYEES_REQUEST]: (state, payloads: FeedParams) => {
    const helper = new MonoliteHelper(state);

    if (state.employeeFeed.data && payloads.skip < state.employeeFeed.data.length) {
      helper.set((_) => _.employeeFeed.data, []);
    }

    return helper
      .set((_) => _.employeeFeed.status, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_EMPLOYEES_SUCCEED]: (state, payload: Feed<ShortUser>) => {
    const helper = new MonoliteHelper(state);

    return helper
      .set((_) => _.employeeFeed.data, [...state.employeeFeed.data, ...payload.data])
      .set((_) => _.employeeFeed.count, payload.count)
      .set((_) => _.employeeFeed.status, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.ATTACH_EMPLOYEE_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.attachEmployee, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.ATTACH_EMPLOYEE_SUCCEED]: (state, payload: AttachEmployeeSucceedParams) => {
    const { companyId, employee, subscriptionType } = payload;
    const helper = new MonoliteHelper(state);

    const companyIndex = state.data.findIndex((c) => c.id === companyId);

    if (companyIndex >= 0) {
      helper.set(
        (_) => _.data[companyIndex].subscriptions[subscriptionType].employees,
        [employee, ...state.data[companyIndex].subscriptions[subscriptionType].employees],
      );
    }

    return helper
      .set((_) => _.statuses.attachEmployee, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.DETACH_EMPLOYEE_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.detachEmployee, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.DETACH_EMPLOYEE_SUCCEED]: (state, payload: AttachEmployeeParams) => {
    const { subscriptionType, employeeGuid } = payload;
    const helper = new MonoliteHelper(state);

    const companyIndex = state.data.findIndex((c) => c.id === payload.companyId);

    if (companyIndex >= 0) {
      helper.setFilter(
        (_) => _.data[companyIndex].subscriptions[subscriptionType].employees,
        (e) => e.guid !== employeeGuid,
      );
    }

    return helper
      .set((_) => _.statuses.detachEmployee, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_ROLES_REQUEST]: (state) => {
    const helper = new MonoliteHelper(state);

    return helper
      .set((_) => _.selectedCompanyRoles, [])
      .set((_) => _.statuses.loadCompanyRoles, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_ROLES_SUCCEED]: (state, payload: ShortRole[]) => {
    const helper = new MonoliteHelper(state);

    return helper
      .set((_) => _.selectedCompanyRoles, payload)
      .set((_) => _.statuses.loadCompanyRoles, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.CHANGE_ROLE_REQUEST]: (state) => {
    const helper = new MonoliteHelper(state);

    return helper
      .set((_) => _.statuses.changeRole, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.CHANGE_ROLE_SUCCEED]: (state, payload: AttachEmployeeSucceedParams) => {
    const { subscriptionType, companyId, employee } = payload;
    const helper = new MonoliteHelper(state);

    const companyIndex = state.data.findIndex((c) => c.id === companyId);

    if (companyIndex >= 0) {

      const employeeIndex = state.data[companyIndex].subscriptions[subscriptionType].employees
        .findIndex((e) => e.guid === employee.guid);

      if (employeeIndex >= 0) {
        helper.set(
          (_) => _.data[companyIndex].subscriptions[subscriptionType].employees[employeeIndex].roleName,
          employee.roleName,
        );
      }
    }

    return helper
      .set((_) => _.statuses.changeRole, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_PROJECTS_EMPLOYEES_REQUEST]: (state) => {
    const helper = new MonoliteHelper(state);

    return helper
      .set((_) => _.selectedProjectEmployees, [])
      .set((_) => _.statuses.loadProjectsEmployee, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_PROJECTS_EMPLOYEES_SUCCEED]: (state, payload: ShortUser[]) => {
    const helper = new MonoliteHelper(state);

    return helper
      .set((_) => _.selectedProjectEmployees, payload)
      .set((_) => _.statuses.loadProjectsEmployee, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.ATTACH_EMPLOYEE_TO_PROJECT_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.attachEmployeeToProject, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.ATTACH_EMPLOYEE_TO_PROJECT_SUCCEED]: (state, payload: AttachEmployeeToProjectParams) => {
    const { employeeGuid, subscriptionType, companyId } = payload;
    const helper = new MonoliteHelper(state);
    const companyIndex = state.data.findIndex(c => c.id === companyId);

    if (companyIndex >= 0) {
      const employee = state.data[companyIndex].subscriptions[subscriptionType].employees
        .find(e => e.guid === employeeGuid);

      if (employee) {
        helper.set((_) => _.selectedProjectEmployees, [employee, ...state.selectedProjectEmployees]);
      }
    }

    return helper
      .set((_) => _.statuses.attachEmployeeToProject, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.DETACH_EMPLOYEE_FROM_PROJECT_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.detachEmployeeFromProject, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.DETACH_EMPLOYEE_FROM_PROJECT_SUCCEED]: (state, payload: AttachEmployeeToProjectParams) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.detachEmployeeFromProject, RequestStatus.Loaded)
      .set(
        (_) => _.selectedProjectEmployees,
        state.selectedProjectEmployees.filter(e => e.guid !== payload.employeeGuid),
      )
      .get();
  },
  [AdminCompaniesActionTypes.GET_SUBSCRIPTION_PLANS_REQUEST]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.getSubscriptionPlans, RequestStatus.Loading)
      .set(_ => _.subscriptionPlanOptions, [])
      .get();
  },
  [AdminCompaniesActionTypes.GET_SUBSCRIPTION_PLANS_SUCCEEDED]: (s, planOptions: SubscriptionPlanOption[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.getSubscriptionPlans, RequestStatus.Loaded)
      .set(_ => _.subscriptionPlanOptions, planOptions)
      .get();
  },
  [AdminCompaniesActionTypes.GET_SUBSCRIPTION_PLANS_FAILED]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.getSubscriptionPlans, RequestStatus.Failed)
      .set(_ => _.subscriptionPlanOptions, [])
      .get();
  },
  [AdminCompaniesActionTypes.SET_MOCK_SUBSCRIPTION_REQUEST]: (s, payload: CompanyMockSubscriptionPayload) => {
    const { companyId } = payload;

    return new MonoliteHelper(s)
      .set(_ => _.statuses.setMockSubscription[companyId], RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.SET_MOCK_SUBSCRIPTION_SUCCEEDED]: (
    s,
    { companyId, planId, planQuantity, subscriptionType }: CompanyMockSubscriptionPayload,
  ) => {
    const index = s.data.findIndex(x => x.id === companyId);

    return new MonoliteHelper(s)
      .set(_ => _.data[index].subscriptions[subscriptionType].mockSubscriptionPlanId, planId)
      .set(_ => _.data[index].subscriptions[subscriptionType].mockSubscriptionPlanQuantity, planQuantity)
      .set(_ => _.statuses.setMockSubscription[companyId], RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.SET_MOCK_SUBSCRIPTION_FAILED]: (s, companyId: number) => {
    const stateUpdater = new MonoliteHelper(s);

    return stateUpdater
      .set(_ => _.statuses.setMockSubscription[companyId], RequestStatus.Failed)
      .get();
  },
  [AdminCompaniesActionTypes.RESET_MOCK_SUBSCRIPTION_REQUEST]: (s, companyId: number) => {
    const stateUpdater = new MonoliteHelper(s);

    return stateUpdater
      .set(_ => _.statuses.resetMockSubscription[companyId], RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.RESET_MOCK_SUBSCRIPTION_SUCCEEDED]: (s, payload: CompanyIdAndSubscriptionTypePayload) => {
    const { companyId, subscriptionType } = payload;
    const index = s.data.findIndex(x => x.id === companyId);

    return new MonoliteHelper(s)
      .set(_ => _.data[index].subscriptions[subscriptionType].mockSubscriptionPlanId, null)
      .set(_ => _.data[index].subscriptions[subscriptionType].mockSubscriptionPlanQuantity, null)
      .set(_ => _.statuses.resetMockSubscription[companyId], RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.RESET_MOCK_SUBSCRIPTION_FAILED]: (s, companyId: number) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.resetMockSubscription[companyId], RequestStatus.Failed)
      .get();
  },
  [AdminCompaniesActionTypes.CREATE_EMPTY_SUBSCRIPTION]: (
    s, payload: CompanyIdAndSubscriptionTypePayload,
  ) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.createEmptySubscription[payload.companyId], RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.CREATE_EMPTY_SUBSCRIPTION_SUCCEEDED]: (
    s, payload: CompanyIdAndSubscriptionTypePayload,
  ) => {
    const { companyId, subscriptionType } = payload;
    const index = s.data.findIndex(x => x.id === companyId);

    return new MonoliteHelper(s)
      .set(_ => _.data[index].subscriptions[subscriptionType], {
        mockSubscriptionPlanId: null,
        mockSubscriptionPlanQuantity: null,
        employees: [],
      })
      .set(_ => _.statuses.createEmptySubscription[companyId], RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.CREATE_EMPTY_SUBSCRIPTION_FAILED]: (s, companyId: number) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.createEmptySubscription[companyId], RequestStatus.Failed)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_CURRENCIES_REQUEST]: s => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.currencies, RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_CURRENCIES_SUCCEEDED]: (s, currencies: CurrencyModel[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.currencies, currencies)
      .set(_ => _.statuses.currencies, RequestStatus.Loaded)
      .get();
  },
  [AdminCompaniesActionTypes.LOAD_CURRENCIES_FAILED]: s => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.currencies, RequestStatus.Failed)
      .get();
  },
  [AdminCompaniesActionTypes.SET_CURRENCY_REQUEST]: (s, { companyId }: CompanyCurrencyPayload) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.updateCurrency[companyId], RequestStatus.Loading)
      .get();
  },

  [AdminCompaniesActionTypes.SET_CURRENCY_SUCCEEDED]: (s, { companyId, currencyCode }: CompanyCurrencyPayload) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.updateCurrency[companyId], RequestStatus.Loaded)
      .setFind(
        _ => _.data,
        c => c.id === companyId,
        c => ({ ...c, currencyCode }))
      .get();
  },
  [AdminCompaniesActionTypes.SET_CURRENCY_FAILED]: (s, companyId: number) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.updateCurrency[companyId], RequestStatus.Failed)
      .get();
  },
  [AdminCompaniesActionTypes.SET_PREFER_IMPERIAL_REQUEST]: (s, { companyId }: CompanyPreferImperialPayload) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.updateCurrency[companyId], RequestStatus.Loading)
      .get();
  },
  [AdminCompaniesActionTypes.SET_PREFER_IMPERIAL_SUCCEEDED]: (
    s, { companyId, preferImperial }: CompanyPreferImperialPayload) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.updateCurrency[companyId], RequestStatus.Loaded)
      .setFind(
        _ => _.data,
        c => c.id === companyId,
        c => ({ ...c, preferImperial }))
      .get();
  },
  [AdminCompaniesActionTypes.SET_PREFER_IMPERIAL_FAILED]: (s, companyId: number) => {
    return new MonoliteHelper(s)
      .set(_ => _.statuses.updateCurrency[companyId], RequestStatus.Failed)
      .get();
  },
  [AdminCompaniesActionTypes.DELETE_COMPANY_SUCCEED]: (s, companyId: number) => {
    return new MonoliteHelper(s)
      .setFilter((_) => _.data, (d => d.id !== companyId))
      .set((_) => _.count, s.count - 1)
      .get();
  },
};
