import * as React from 'react';

import { Styled } from './styled';

interface ItemProps {
  videoUrl?: string;
}

export class TutorialVideo  extends React.Component<ItemProps> {
  public render(): JSX.Element {
    const { videoUrl } = this.props;
    return (
      <Styled.Container>
        <iframe
          src={videoUrl}
          frameBorder='0'
          allowFullScreen={true}
        />
      </Styled.Container>
    );
  }
}
