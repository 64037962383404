import * as React from 'react';

export const KreoIconPause: React.FC = () => {
  return (
    <svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g  stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-161.000000, -568.000000)'>
        <g id='Timeline' transform='translate(60.000000, 556.000000)'>
          <g id='Top-panel'>
            <g id='Controls'>
              <g transform='translate(65.000000, 12.000000)' id='4D_controls_pause'>
                <g transform='translate(36.000000, 0.000000)'>
                  <g id='play'>
                    <circle id='Oval' fill='#F0813E' cx='16' cy='16' r='16' />
                    <path
                      // eslint-disable-next-line max-len
                      d='M14,11.5 C14.5522847,11.5 15,11.9477153 15,12.5 L15,19.5 C15,20.0522847 14.5522847,20.5 14,20.5 C13.4477153,20.5 13,20.0522847 13,19.5 L13,12.5 C13,11.9477153 13.4477153,11.5 14,11.5 Z M18,11.5 C18.5522847,11.5 19,11.9477153 19,12.5 L19,19.5 C19,20.0522847 18.5522847,20.5 18,20.5 C17.4477153,20.5 17,20.0522847 17,19.5 L17,12.5 C17,11.9477153 17.4477153,11.5 18,11.5 Z'
                      id='Combined-Shape'
                      fill='#FFFFFF'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
