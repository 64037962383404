import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { Constants } from 'unit-2d-database/constants';
import { ResetAssemblyOverridePayload, UpdateItemRequest } from 'unit-2d-database/interfaces';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { Styled } from './styled';

interface DispatchProps {
  resetOverride: (p: ResetAssemblyOverridePayload[]) => void;
  updateItem: (p: UpdateItemRequest) => void;
  updateOverride: (p: string) => void;
}

const TITLE = 'Assemblies override';
const TEXT = `Some Properties of this Item you've edited were
  overridden in the Assemblies. Do you want to reset overrides?`;
const CONFIRM_TEXT = 'Yes, update all';
const CANCEL_TEXT = 'Keep overrides';

class ResetAssemblyOverrideDialogComponent extends React.PureComponent<DispatchProps> {
  public render(): JSX.Element {
    return (<Styled.Container>
      <ConfirmationDialog
        name={Constants.RESET_ASSEMBLY_OVERRIDE_DIALOG}
        title={TITLE}
        text={TEXT}
        confirmButtonText={CONFIRM_TEXT}
        cancelButtonText={CANCEL_TEXT}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
      />
    </Styled.Container>);
  }

  @autobind
  private onCancel([updateItem]: [UpdateItemRequest]): void {
    this.props.updateItem(updateItem);
  }

  @autobind
  private onConfirm([updateItem, resetOverride]: [UpdateItemRequest, ResetAssemblyOverridePayload[]]): void {
    this.props.resetOverride(resetOverride);
    this.props.updateItem(updateItem);
    this.props.updateOverride(updateItem.id);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    resetOverride: (p) => dispatch(TwoDDatabaseActions.resetAssemblyOverride(p)),
    updateItem: (p) => dispatch(TwoDDatabaseActions.updateItemRequest(p)),
    updateOverride: (p) => dispatch(TwoDDatabaseActions.updateItemOverrideByBaseItem(p)),
  };
};

export const ResetAssemblyOverrideDialog = connect(null, mapDispatchToProps)(ResetAssemblyOverrideDialogComponent);
