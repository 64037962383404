import { ModalWrapper } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';

import { SUBSCRIPTION_EDIT_FORM_STATE } from '../../../../reducers/account';
import { AppUrls } from '../../../../routes/app-urls';
import { AccountActions } from '../../../account/actions/creators';
import { SubscriptionEditor } from './subscription-editor';

export const SUBSCRIPTION__DIALOG = 'SUBSCRIPTION__DIALOG';

interface Props {
  closeDialog: () => void;
  redirectTo: (path: string) => void;
  resetSubscriptionEditForm: () => void;
}

class SubscriptionDialogComponent extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <DialogWrapper name={SUBSCRIPTION__DIALOG}>
        <ModalWrapper onExit={this.close}>
          <SubscriptionEditor
            onSubscriptionUpdate={this.close}
            onSubscriptionCancel={this.onSubscriptionCancel}
          />
        </ModalWrapper>
      </DialogWrapper>
    );
  }

  @autobind
  private onSubscriptionCancel(): void {
    this.close();
    this.props.redirectTo(AppUrls.products.url());
  }

  @autobind
  private close(): void {
    this.props.closeDialog();
    this.props.resetSubscriptionEditForm();
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): Props {
  return {
    redirectTo: path => dispatch(push(path)),
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(SUBSCRIPTION__DIALOG)),
    resetSubscriptionEditForm: () =>
      dispatch(AccountActions.setSubscriptionEditFormState(SUBSCRIPTION_EDIT_FORM_STATE)),
  };
}

const connector = connect(null, mapDispatchToProps);
export const SubscriptionDialog = connector(SubscriptionDialogComponent);
