import styled from 'styled-components';

const SectionMenuItemsWrapper = styled.div`
  &:not(:first-of-type) {
    border-top: 1px solid ${p => p.theme.color.background};
  }
`;

export const Styled = {
  SectionMenuItemsWrapper,
};
