import { TinyText } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  width: 240px;
  *, & {
    box-sizing: border-box;
  }
`;

const Set = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  background: ${p => p.theme.color.backgroundRush};
  border-bottom: ${pr => pr.border ? `10px solid ${pr.theme.color.pale}` : 'none'};
  margin: ${p => p.border ? `10px 0` : 0};
  position: relative;
`;

const SetTitle = styled(TinyText)`
  margin-bottom: 15px;
`;

const SetElementStyle = styled.div`
  margin-bottom: 15px;
  display: flex;
  cursor: pointer;
  gap: 15px;
`;

export const Styled = {
  Container,
  Set,
  SetTitle,
  SetElementStyle,
};
