import styled from 'styled-components';

const Container = styled.div<{ showPath: boolean }>`
  margin-left: 20px;
  margin-right: ${p => p.showPath ? 0 :20}px;
  min-width: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  fill: ${p => p.theme.color.gray};
`;

const Icon = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  max-width: 20px;
  transform: rotate(90deg);
  display: flex;
  align-items: center;
  fill: ${p => p.theme.color.gray};
  svg {
    width: 20px;
    height: 20px;
  }
`;

const Menu = styled.div`

`;

export const Styled = {
  Container,
  Menu,
  Icon,
};
