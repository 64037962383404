import styled from 'styled-components';


const PaymentDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  > p {
    display: inline-flex;
    flex-direction: row;
    margin-top: 10px;
    pre {
      font-weight: 400;
    }
  }
`;

const DetailsButton = styled.div`
  position: absolute;
  bottom: 3px;
  right: 0;

  > button {
    background-color: ${props => props.theme.color.pale};
    border-radius: 4px;

    > p {
      font-size: 12px;
      color: ${props => props.theme.color.gray};
    }
  }
`;

export const Styled = {
  PaymentDetails,
  DetailsButton,
};
