import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';


export const KreoIconLayerStructure: React.FC = () => {
  return (
  <svg width='44px' height='44px' viewBox='0 0 44 44' version='1.1'>
    <g id='Icons/44x44/Layer-Structure' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          // eslint-disable-next-line max-len
          d='M12.361232,9.54945118 L28.4690376,17.3732424 L31.5675857,15.6518268 L15.5437785,7.63992326 L12.361232,9.54945118 Z M11.5,11.3545758 L11.5,27.881966 L27.5,35.881966 L27.5,19.1260044 L11.5,11.3545758 Z M29.5,35.7338096 L32.5,33.9338096 L32.5,17.4217367 L29.5,19.0884033 L29.5,35.7338096 Z M9.5,27.881966 L9.5,10.0661904 C9.5,9.36366467 9.86859767,8.71265102 10.4710085,8.35120453 L14.514787,5.92493741 C15.1011413,5.57312483 15.8265958,5.54526389 16.4382057,5.85106888 L33.3944272,14.3291796 C34.0719952,14.6679636 34.5,15.3604899 34.5,16.118034 L34.5,33.9338096 C34.5,34.6363353 34.1314023,35.287349 33.5289915,35.6487955 L29.485213,38.0750626 C28.8988587,38.4268752 28.1734042,38.4547361 27.5617943,38.1489311 L10.6055728,29.6708204 C9.92800475,29.3320364 9.5,28.6395101 9.5,27.881966 Z M14,15.5 C14,15.2238576 14.2238576,15 14.5,15 C14.7761424,15 15,15.2238576 15,15.5 L15,26.5 C15,26.7761424 14.7761424,27 14.5,27 C14.2238576,27 14,26.7761424 14,26.5 L14,15.5 Z M19,18 C19,17.7238576 19.2238576,17.5 19.5,17.5 C19.7761424,17.5 20,17.7238576 20,18 L20,29 C20,29.2761424 19.7761424,29.5 19.5,29.5 C19.2238576,29.5 19,29.2761424 19,29 L19,18 Z M24,20.5 C24,20.2238576 24.2238576,20 24.5,20 C24.7761424,20 25,20.2238576 25,20.5 L25,31.5 C25,31.7761424 24.7761424,32 24.5,32 C24.2238576,32 24,31.7761424 24,31.5 L24,20.5 Z'
          id='Shape'
          fill={KreoColors.blue}
          fillRule='nonzero'
        />
    </g>
  </svg>);
};
