import * as React from 'react';

import { numberUtils } from 'common/utils/number-utils';
import { CostFieldNames } from '../../../constants';
import { CostEstimateGroupingType } from '../../../units/projects/enums/cost-estimate-grouping-type';
import Input from '../../input';
import * as Utils from '../utils';

interface Props {
  levelOfNesting: number;
  isEditable: boolean;
  duration: number | undefined;
  groupingType: CostEstimateGroupingType;
  isIndirectCosts: boolean;
  onInputChange: (value: any, field: any) => void;
}

interface EditableCellContentProps {
  duration: number | undefined;
  onInputChange: (value: string, fieldName: string) => void;
}

const ReadonlyCellContent: React.FC<{ duration: number | undefined }> = (
  { duration },
) => {
  return (
    <div key={2} className='cost'>{duration ? numberUtils.getNumeralFormatter(duration).format('0,0') : ''}</div>
  );
};

const EditableCellContent: React.FC<EditableCellContentProps> = (props) => {
  return (
    <React.Fragment>
      <div key={1} className='rate'>
        <Input
          onChange={props.onInputChange}
          value={Utils._formatter(props.duration ? props.duration.toString() : '', 2)}
          fieldName={CostFieldNames.DURATION}
        />
      </div>
      <ReadonlyCellContent
        duration={props.duration}
      />
    </React.Fragment>
  );
};

const DurationCellContent: React.FC<Props> = (props) => {

  const shouldBeEditable = props.levelOfNesting === 1 && props.isEditable;
  const shouldDisplayValues = props.isIndirectCosts ||
    (props.groupingType === CostEstimateGroupingType.Workpackages
      ? props.levelOfNesting < 2
      : props.levelOfNesting === 0);

  if (shouldBeEditable) {
    return (
      <EditableCellContent
        duration={props.duration}
        onInputChange={props.onInputChange}
      />
    );
  }

  return (
    <ReadonlyCellContent
      duration={shouldDisplayValues ? props.duration : undefined}
    />
  );
};

export const DurationCell: React.FC<Props> = (props) => {
  return (
    <div className='cost-estimate-table__price lbord'>
      <DurationCellContent
        {...props}
      />
    </div>
  );
};
