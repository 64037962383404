import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './template-table-panel-controls.scss';

import { ElementTooltip, TooltipPosition } from 'common/components/element-tooltip';
import { ExpandCollapseControls } from 'common/components/expand-collapse-controls';
import { TitleEditable } from 'common/components/title-editable';
import { VideoUrl } from 'common/constants/video-url';
import { State } from 'common/interfaces/state';
import {
  KreoDialogActions,
  MaterialMenuItem,
  MaterialSelect,
} from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { HelpIconDialog } from '../../../../components/help-icon-dialog';
import { HELP_VIDEO_DIALOG_NAME } from '../../../analytics/components/help-center';
import { QuantityTakeOffReportActions } from '../../actions/creators/quantity-take-off-report';
import { QuantityTakeOffTemplateActions } from '../../actions/creators/quantity-take-off-template';
import { QtoGenerateReportStatus } from '../../enums/qto-generate-report-status';
import {
  ModelType,
  QtoProjectReport,
  QtoTemplateForm,
  QtoTemplateInfo,
  QtoTemplateInfoForm,
} from '../../interfaces/quantity-take-off';
import { getEmptyModel } from '../../saga/quantity-take-off-reports';
import { QtoAddTemplateMenu } from './add-template-menu';


const DELETE_TEMPLATE_CONFIRMATION_DIALOG_NAME = 'DELETE_TEMPLATE_CONFIRMATION_DIALOG_NAME';
const HELP_CREATE_TEMPLATE_VIDEO__DIALOG_NAME = 'HELP_CREATE_TEMPLATE_VIDEO__DIALOG_NAME';

interface ReduxProps {
  selectedTemplateId: number | null;
  templatesInfo: QtoTemplateInfo[];
  disableShowDialogList: string[];
  reportsForCreateTemplate: QtoProjectReport[];
  templateCreationStatus: QtoGenerateReportStatus;
}

interface DispatchProps {
  createTemplate: (model: QtoTemplateForm) => void;
  openDialog: (name: string) => void;
  updateTemplateInfo: (id: number, form: QtoTemplateInfoForm) => void;
  loadInitialState: () => void;
  setSelectedTemplateId: (id: number) => void;
  deleteTemplate: (id: number) => void;
  onCreateTemplateFromReport: (projectId: number, reportId: number) => void;
  loadAvailableReportForCreate: () => void;
}

interface OwnProps {
  collapseAll: () => void;
  expandAll: () => void;
  projectId: number;
  modelType: ModelType;
}

interface Props extends OwnProps, DispatchProps, ReduxProps {
}
class TemplateTablePanelControlsComponent extends React.Component<Props> {
  private templateMenu: ItemMenuType[] = [
    {
      name: 'Remove',
      action: this.openRemoveTemplateDialog,
    },
  ];

  public componentDidMount(): void {
    this.props.loadInitialState();
    if (!this.props.disableShowDialogList) {
      this.props.openDialog(HELP_VIDEO_DIALOG_NAME);
    }
  }

  public render(): JSX.Element {
    const {
      templatesInfo,
      selectedTemplateId,
      onCreateTemplateFromReport,
      reportsForCreateTemplate,
      loadAvailableReportForCreate: loadAvailableReportForCopy,
    } = this.props;
    const selectedTemplate = templatesInfo.find(x => x.id === selectedTemplateId);
    const isShortTemplateName = selectedTemplate && selectedTemplate.name.length < 30;

    return (
      <div className='quantity-take-off-template-panel-controls'>
        <div className='quantity-take-off-template-panel-controls__container-left'>
          <ElementTooltip
            size='medium'
            tooltipPosition={TooltipPosition.CenterTop}
            className={classNames(
              'quantity-take-off-template-panel-controls__template-menu',
              { 'quantity-take-off-template-panel-controls__template-menu--disabled': !selectedTemplate },
            )}
            targetElement={
              <ItemMenu
                buttonSize='medium'
                menu={this.templateMenu}
              />
            }
            disabled={!!selectedTemplate}
          >
            You have to select a template firstly
          </ElementTooltip>
          <ExpandCollapseControls
            className='quantity-take-off-template-panel-controls__expand-collapse-controls'
            onExpand={this.props.expandAll}
            onCollapse={this.props.collapseAll}
          />
          <div className='quantity-take-off-template-panel-controls__template-name'>
            {
              selectedTemplate ? (
                <ElementTooltip
                  size='small'
                  tooltipPosition={TooltipPosition.CenterTop}
                  className='quantity-take-off-template-panel-controls__template-title'
                  targetElement={
                    <TitleEditable
                      isEditByIconClick={true}
                      canEdit={true}
                      onChange={this.renameTemplate}
                      text={selectedTemplate.name}
                      label='Template Name'
                    />
                  }
                  disabled={isShortTemplateName}
                >
                  {selectedTemplate.name}
                </ElementTooltip>
              ) : (
                  <span className='quantity-take-off-template-panel-controls__template-label'>
                    Please, Select Template
                  </span>
              )
            }
          </div>
        </div>
        <div className='quantity-take-off-template-panel-controls__container-right'>
          <QtoAddTemplateMenu
            onNewTemplateCreate={this.onNewTemplateCreate}
            className='quantity-take-off-template-panel-controls__create-button'
            onCreateTemplateFromReport={onCreateTemplateFromReport}
            reportsForCreateTemplate={reportsForCreateTemplate}
            onClickButton={loadAvailableReportForCopy}
            templateCreationStatus={this.props.templateCreationStatus}
          />
          <HelpIconDialog
            className='quantity-take-off-template-panel-controls__help-icon'
            video={true}
            url={VideoUrl.QtoCustomFiltersTemplates}
            dialogName={HELP_CREATE_TEMPLATE_VIDEO__DIALOG_NAME}
            title='Custom filters & Report templates demo'
          />
          <MaterialSelect
            value={selectedTemplateId}
            className='quantity-take-off-template-panel-controls__material-select'
            dropdownClassName='quantity-take-off-template-panel-controls__material-paper'
            onChange={this.selectTemplate}
            noDataText='No Template'
            placeholder={templatesInfo && templatesInfo.length ? 'Select Template' : 'Create New Template'}
            displayedType={MaterialComponentType.Native}
            autoWidth={true}
          >
            {templatesInfo &&
              templatesInfo.map(template => {
                return (
                  <MaterialMenuItem
                    key={template.id}
                    value={template.id}
                  >
                    <div
                      title={template.name}
                      className='quantity-take-off-template-panel-controls__material-select-item'
                    >
                      {template.name}
                    </div>
                  </MaterialMenuItem>
                );
              })}
          </MaterialSelect>
        </div>
        <KreoConfirmationDialog
          name={DELETE_TEMPLATE_CONFIRMATION_DIALOG_NAME}
          onYes={this.deleteTemplate}
          yesText='Remove'
          noText='Cancel'
          title='Remove Template'
        >
          Are you sure you want to remove <b>{selectedTemplate ? selectedTemplate.name : ''}</b> template?
        </KreoConfirmationDialog>
      </div>
    );
  }

  @autobind
  private onNewTemplateCreate(): void {
    const newTemplate = getEmptyModel('New Template');
    this.props.createTemplate(newTemplate);
  }

  @autobind
  private openRemoveTemplateDialog(): void {
    this.props.openDialog(DELETE_TEMPLATE_CONFIRMATION_DIALOG_NAME);
  }

  @autobind
  private deleteTemplate(): void {
    this.props.deleteTemplate(this.props.selectedTemplateId);
  }

  @autobind
  private renameTemplate(name: string): void {
    this.props.updateTemplateInfo(this.props.selectedTemplateId, { name });
  }

  @autobind
  private selectTemplate(_: React.SyntheticEvent, templateId: number): void {
    if (templateId !== this.props.selectedTemplateId) {
      this.props.setSelectedTemplateId(templateId);
    }
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const templateState = state.quantityTakeOff.template;
  return {
    templatesInfo: templateState.templatesInfo,
    selectedTemplateId: templateState.selectedTemplateId,
    disableShowDialogList: state.persistedStorage.disableShowDialogList,
    reportsForCreateTemplate: state.quantityTakeOff.report.reportsForCopy,
    templateCreationStatus: templateState.templateCreationStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  const { modelType } = props;

  return {
    createTemplate: (model) => dispatch(QuantityTakeOffTemplateActions.createTemplate(model, modelType)),
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
    updateTemplateInfo: (id, form) => dispatch(QuantityTakeOffTemplateActions.updateTemplateInfo(id, modelType, form)),
    loadInitialState: () => dispatch(QuantityTakeOffTemplateActions.getInitialState(modelType)),
    setSelectedTemplateId: (id) => dispatch(QuantityTakeOffTemplateActions.setSelectedTemplateId(id)),
    deleteTemplate: (id) => dispatch(QuantityTakeOffTemplateActions.deleteTemplate(id, modelType)),
    onCreateTemplateFromReport: (projectId, reportId) =>
      dispatch(QuantityTakeOffReportActions.createTemplateFromReport(projectId, modelType, reportId)),
    loadAvailableReportForCreate: () =>
      dispatch(QuantityTakeOffReportActions.loadAvailableReportForCopy(modelType)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const TemplateTableTemplatePanelControls = connector(TemplateTablePanelControlsComponent);
