export enum EmptyListName {
  Properties = 'Properties',
  Items = 'Items',
  Assemblies = 'Assemblies',
  Entites = 'Entites',
}

export interface ListNotification {
  headerText: string;
  description: string;
  linkText: string;
  linkId: string;
}

export const LIST_NOTIFICATION: Record<string, ListNotification> = {
  [EmptyListName.Items]: {
    headerText: 'No properties or groups added',
    description: 'To add a property or a group of properties, click on the checkbox. Unclear how it works?',
    linkText: 'Write to us',
    linkId: 'itemsContact',
  },
  [EmptyListName.Assemblies]: {
    headerText: 'No items added',
    description: 'To add an item, click on the checkbox. Unclear how it works?',
    linkText: 'Write to us',
    linkId: 'itemsContact',
  },
  [EmptyListName.Entites]: {
    headerText: 'No entities applied',
    description: 'To add an item or an assembly, click on the checkbox. Unclear how it works?',
    linkText: 'Write to us',
    linkId: 'itemsContact',
  },
};
