import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './element-tooltip.scss';

import { KreoIconInfoBigColor } from 'common/UIKit';
import { getOrCreateRoot } from 'common/UIKit/dialogs';
import { TooltipPosition } from './tooltip-position';

interface Props {
  tooltipPosition: TooltipPosition;
  targetElement?: React.ReactNode;
  className?: string;
  size: 'small' | 'medium';
  tooltipClassName?: string;
  disabled?: boolean;
  disabledClick?: boolean;
  marginLeft?: number;
  marginTop?: number;
  controlName?: string;
  themeDark?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface State {
  open: boolean;
}

export class ElementTooltip extends React.Component<Props, State> {

  private root: HTMLDivElement;
  private tooltip: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.getRoot();

    this.state = {
      open: false,
    };
  }

  public render(): React.ReactNode {
    const { targetElement, controlName, className } = this.props;
    return (
      <div
        className={classNames('element-tooltip', className)}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={this.onClick}
        data-control-name={controlName}
      >
        {targetElement ? targetElement : <KreoIconInfoBigColor />}
        {this.renderTooltip()}
      </div>
    );
  }

  private getRoot(): HTMLDivElement {
    if (!this.root) {
      this.root = getOrCreateRoot();
    }
    return this.root;
  }

  @autobind
  private onClick(e: React.MouseEvent<HTMLDivElement>): void {
    if (!this.props.disabledClick && this.props.onClick) {
      this.props.onClick(e);
    }
    if (this.state.open) {
      this.setState({ open: false });
    }
  }

  @autobind
  private saveTooltipRef(ref: HTMLDivElement): void {
    this.tooltip = ref;
  }

  private renderTooltip(): React.ReactNode {
    return ReactDOM.createPortal(
      (
        <CSSTransition
          in={this.state.open && !this.props.disabled}
          timeout={400}
          unmountOnExit={true}
          classNames='element-tooltip__tooltip'
        >
          <div
            className={classNames(
              'element-tooltip__tooltip',
              `element-tooltip__tooltip--${this.props.size}`,
              { 'element-tooltip__tooltip--dark': this.props.themeDark },
              this.props.tooltipClassName,
            )}
            ref={this.saveTooltipRef}
          >
            {this.props.children}
          </div>
        </CSSTransition>
      ),
      this.root,
    );
  }

  @autobind
  private onMouseOut(): void {
    if (this.state.open) {
      this.setState({ open: false });
    }
  }

  @autobind
  private onMouseOver(event: React.MouseEvent<HTMLDivElement>): void {
    if (!this.props.disabled) {
      const targetRect = event.currentTarget.getBoundingClientRect() as DOMRect;
      this.setState({ open: true }, () => this.drawTooltipRect(targetRect));
    }
  }

  @autobind
  private drawTooltipRect(targetRect: DOMRect): void {
    const tooltipRect = this.tooltip.getBoundingClientRect() as DOMRect;
    const marginTop = this.props.marginTop ? this.props.marginTop : 5;
    const marginLeft = this.props.marginLeft ? this.props.marginLeft : 5;
    const documentRect = document.documentElement.getBoundingClientRect() as DOMRect;
    switch (this.props.tooltipPosition) {
      case TooltipPosition.RightBottom: {
        this.tooltip.style.left = `${targetRect.x + targetRect.width + marginLeft}px`;
        this.tooltip.style.top = `${targetRect.y + targetRect.height + marginTop}px`;
        break;
      }
      case TooltipPosition.LeftTop: {
        this.tooltip.style.left = `${targetRect.x - tooltipRect.width}px`;
        if ((tooltipRect.height + marginTop) - targetRect.y >= 0) {
          this.tooltip.style.top = `${targetRect.y + targetRect.height + marginTop}px`;
        } else {
          this.tooltip.style.top = `${targetRect.y - tooltipRect.height - marginTop}px`;
        }
        break;
      }
      case TooltipPosition.RightTop: {
        this.tooltip.style.left = `${targetRect.x + targetRect.width + marginLeft}px`;
        this.tooltip.style.top = `${targetRect.y - tooltipRect.height - marginTop}px`;
        break;
      }
      case TooltipPosition.RightCenter: {
        this.tooltip.style.left = `${targetRect.x + targetRect.width + marginLeft}px`;
        this.tooltip.style.top = `${(targetRect.y + targetRect.height / 2) - tooltipRect.height / 2}px`;
        break;
      }
      case TooltipPosition.CenterTop: {
        const left = targetRect.x + targetRect.width / 2 - tooltipRect.width / 2;
        if (documentRect.width < left + tooltipRect.width) {
          this.tooltip.style.right = `${marginLeft}px`;
        } else {
          this.tooltip.style.left = `${left}px`;
        }
        this.tooltip.style.top = `${targetRect.y - tooltipRect.height - marginTop}px`;
        break;
      }
      default:
    }
  }
}
