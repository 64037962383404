import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
} from 'react-dnd';
import { DndItem } from 'common/enums/dnd-item';

import './activity-item.scss';

import { Checkbox, KreoIconDragAndDrop } from 'common/UIKit';


export interface DndProps {
  connectDragSource: ConnectDragSource;
  connectDragPreview: ConnectDragPreview;
  isDragging: boolean;
}

interface OwnProps {
  id: number;
  name: string;
  isActive: boolean;
  isSelected: boolean;
  setDraggingActivity: (activityId: number) => void;
  onMouseOver: (id: number) => void;
  onSelectionChange: (id: number, isSelected: boolean) => void;
  onClick: (id: number, event: React.MouseEvent<HTMLDivElement>) => void;
}

interface Props extends OwnProps, DndProps {}

class ActivityItemComponent extends React.Component<Props> {
  public render(): JSX.Element {
    return this.props.connectDragPreview ((
      <div
        className={
          classNames('activities-area-activity-item', {
            ['activities-area-activity-item--active']: this.props.isActive,
            ['activities-area-activity-item--selected']: this.props.isSelected,
          })
        }
        style={{
          opacity: this.props.isDragging ? 0.4 : 1,
        }}
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
      >
        <div className='activities-area-activity-item__inner'>
          {this.props.connectDragSource(
            <span className='activities-area-activity-item__draggable-indicator'>
              <KreoIconDragAndDrop/>
            </span>,
          )}
          <div className='activities-area-activity-item__checkbox'>
            <Checkbox
              checked={this.props.isSelected}
              onChange={this.onCheckboxChange}
            />
          </div>
          <div className='activities-area-activity-item__name'>
            {this.props.name}
          </div>
        </div>
      </div>
    ));
  }

  @autobind
  private onClick(event: React.MouseEvent<HTMLDivElement>): void {
    this.props.onClick(this.props.id, event);
  }

  @autobind
  private onMouseOver(): void {
    if (this.props.onMouseOver) {
      this.props.onMouseOver(this.props.id);
    }
  }

  @autobind
  private onCheckboxChange(isChecked: boolean): void {
    this.props.onSelectionChange(this.props.id, isChecked);
  }
}

const sourceSpec: DragSourceSpec<OwnProps> = {
  beginDrag: (props: OwnProps): OwnProps => {
    props.setDraggingActivity(props.id);
    return props;
  },
  endDrag: (props: OwnProps): void => {
    props.setDraggingActivity(null);
  },
};

const sourceCollect =
  (conn: DragSourceConnector, monitor: DragSourceMonitor): DndProps => {
    return {
      connectDragSource: conn.dragSource(),
      isDragging: monitor.isDragging(),
      connectDragPreview: conn.dragPreview(),
    };
  };

export const ActivityItem = DragSource(DndItem.Activity, sourceSpec, sourceCollect)(ActivityItemComponent);
