import { arrayUtils } from 'common/utils/array-utils';
import { objectUtils } from 'common/utils/object-utils';
import { QtoElementalViewBreakdownType } from '../../enums/qto-elemental-view-breakdown-type';
import { QuantityTakeOffTreeType } from '../../enums/quantity-take-off-tree-type';
import { QuantityTakeOffElement } from '../../interfaces/quantity-take-off/quantity-take-off-element';
import { QuantityTakeOffLinearTree } from '../../interfaces/quantity-take-off/quantity-take-off-linear-tree';
import { QuantityTakeOffLinearTreeLeaf } from '../../interfaces/quantity-take-off/quantity-take-off-linear-tree-leaf';
import { QuantityTakeOffLinearTreeNode } from '../../interfaces/quantity-take-off/quantity-take-off-linear-tree-node';
import { QuantityTakeOffModel } from '../../interfaces/quantity-take-off/quantity-take-off-model';
import { RangePointer } from '../../interfaces/range-pointer';

enum QtoRevitTreeLevel {
  Category = 1,
  Family = 2,
  ElementType = 3,
  Layer = 4,
}

interface QuantityTakeOffTreeNode {
  name: string;
  nestingLevel: number;
  children: QuantityTakeOffTreeNode[] | null;
}

interface QuantityTakeOffTreeLeaf extends QuantityTakeOffTreeNode {
  description: string;
  extraPropertiesIds: number[];
  extraPropertiesValues: number[];
  extractorsIds: number[];
  extractedValues: number[];
  bimIds: number[];
  engineIds: number[];
  children: null;
}

function isLeaf(
  node: QuantityTakeOffTreeNode | QuantityTakeOffTreeLeaf,
): node is QuantityTakeOffTreeLeaf {
  const nodeAsLeaf = node as QuantityTakeOffTreeLeaf;
  return nodeAsLeaf.extractorsIds !== undefined && nodeAsLeaf.children === null;
}

interface QuantityTakeOffTree {
  nodes: QuantityTakeOffTreeNode[];
  type: QuantityTakeOffTreeType;
}

const compareByNames = objectUtils.buildAscComparerBy<QuantityTakeOffTreeNode>('name');
function compareByExtraProperties(first: QuantityTakeOffTreeLeaf, second: QuantityTakeOffTreeLeaf): number {
  if (second.children) {
    return 0;
  }
  for (let i = 0; i < first.extraPropertiesIds.length; i++) {
    const propertyId = first.extraPropertiesIds[i];
    const firstPropertyValue = first.extraPropertiesValues[i];

    const secondPropertyIndex = second.extraPropertiesIds.indexOf(propertyId);
    const secondPropertyValue = second.extraPropertiesValues[secondPropertyIndex];

    if (firstPropertyValue < secondPropertyValue) {
      return -1;
    } else if (firstPropertyValue > secondPropertyValue) {
      return 1;
    }

    // if equal, continue to the next property
  }

  return 0;
}

function compareLeaves(a: QuantityTakeOffTreeLeaf, b: QuantityTakeOffTreeLeaf): number {
  return compareByNames(a, b) || compareByExtraProperties(a, b);
}

/**
 * Inserts new element into collection in ascending order using compare function
 * @param collection Collection of QuantityTakeOffTreeNodes to be extended
 * @param element Element to be inserted
 */
function insertElement(
  collection: QuantityTakeOffTreeNode[],
  element: QuantityTakeOffTreeNode,
  comparer: (a: QuantityTakeOffTreeNode, b: QuantityTakeOffTreeNode) => number,
): void {
  collection.splice(
    arrayUtils.findInsertPoint(collection, comparer, element),
    0,
    element,
  );
}

function insertNewNode(
  name: string,
  nestingLevel: QtoRevitTreeLevel,
  collection: QuantityTakeOffTreeNode[],
): QuantityTakeOffTreeNode {
  const node: QuantityTakeOffTreeNode = {
    name,
    nestingLevel,
    children: [],
  };

  insertElement(collection, node, compareByNames);

  return node;
}

function insertNewLeaf(
  parentNode: QuantityTakeOffTreeNode,
  name: string,
  nestingLevel: QtoRevitTreeLevel,
  element: QuantityTakeOffElement,
): QuantityTakeOffTreeLeaf {
  const leaf = {
    name,
    description: element.extraInfo,
    extraPropertiesIds: element.extraPropertiesIds,
    extraPropertiesValues: element.extraPropertiesValues,
    extractorsIds: element.extractorsIds,
    extractedValues: new Array<number>(element.extractorsIds.length).fill(0),
    nestingLevel,
    children: null,
    bimIds: [],
    engineIds: [],
  };
  insertElement(parentNode.children, leaf, compareLeaves);

  return leaf;
}

const compare = (first: number, second: number): number => first - second;

/**
 * Groups
 * non-layer elements by category -> family -> elementType + extraInfo
 * layer elements by category -> family -> elementType -> layer name + extraInfo
 */
function buildRevitTree(model: QuantityTakeOffModel, filteredValue?: Readonly<number[]>): QuantityTakeOffLinearTree {
  const tree: QuantityTakeOffTree = {
    nodes: [],
    type: QuantityTakeOffTreeType.RevitCategories,
  };
  let filterIndex = 0;
  for (const element of model.elements) {
    if (filteredValue && filteredValue.length > 0) {
      let compareResult = compare(filteredValue[filterIndex], element.bimId);
      while (compareResult < 0) {
        filterIndex += 1;
        compareResult = compare(filteredValue[filterIndex], element.bimId);
      }

      if (Number.isNaN(compareResult) || compareResult > 0) {
        continue;
      }

      filterIndex += 1;
    }

    let category = tree.nodes.find(c => c.name === element.category);
    if (!category) {
      category = insertNewNode(element.category, QtoRevitTreeLevel.Category, tree.nodes);
    }

    let family = category.children.find(f => f.name === element.family);
    if (!family) {
      family = insertNewNode(element.family, QtoRevitTreeLevel.Family, category.children);
    }

    let leaf: QuantityTakeOffTreeLeaf = null;

    if (element.isLayer) {
      let elementType = family.children.find(et => et.name === element.elementType && !isLeaf(et));
      if (!elementType) {
        elementType = insertNewNode(element.elementType, QtoRevitTreeLevel.ElementType, family.children);
      }

      // TODO (m.ziuz): extract search lambda in order to remove code duplication
      let layer = elementType.children.find(
        (l: QuantityTakeOffTreeLeaf) => {
          return l.name === element.name &&
            l.description === element.extraInfo &&
            arrayUtils.areSetsEqual(l.extractorsIds, element.extractorsIds);
        }) as QuantityTakeOffTreeLeaf;
      if (!layer) {
        layer = insertNewLeaf(elementType, element.name, QtoRevitTreeLevel.Layer, element);
      }

      leaf = layer;
    } else {
      let elementType = family.children.find(
        (et: QuantityTakeOffTreeLeaf) => {
          return et.name === element.elementType &&
            et.description === element.extraInfo &&
            arrayUtils.areSetsEqual(et.extractorsIds, element.extractorsIds);
        }) as QuantityTakeOffTreeLeaf;
      if (!elementType) {
        elementType = insertNewLeaf(family, element.elementType, QtoRevitTreeLevel.ElementType, element);
      }

      leaf = elementType;
    }

    for (let i = 0; i < element.extractorsIds.length; i++) {
      const extractorId = element.extractorsIds[i];
      const extractedValue = element.extractorsValues[i];

      const valueIndex = leaf.extractorsIds.indexOf(extractorId);
      leaf.extractedValues[valueIndex] += extractedValue;
    }

    leaf.bimIds.push(element.bimId);
    leaf.engineIds.push(element.engineId);
  }

  return flattenTree(tree);
}

/**
 * Appends sub array to array
 * @param array Array to be extended
 * @param subArray subArray to extend array with
 * @returns RangePointer to subArray inside array. Can be used in slice function.
 */
function appendSubArray<T>(
  array: T[],
  subArray: T[],
): RangePointer {
  const start = array.length;
  const end = array.push(...subArray) - 1;

  return { start, end };
}

/**
 * Appends branch of tree to linear tree array
 * @param node Branch root node
 * @param linearTree Linear tree to modify
 */
function processNode(
  node: QuantityTakeOffTreeNode,
  linearTree: QuantityTakeOffLinearTree,
  parentIndex: number | null,
): void {
  if (isLeaf(node)) {
    const linearLeaf: QuantityTakeOffLinearTreeLeaf = {
      name: node.name,
      nestingLevel: node.nestingLevel,
      description: node.description,
      extraPropertiesIds: node.extraPropertiesIds,
      extraPropertiesValues: node.extraPropertiesValues,
      extractorsIds: node.extractorsIds,
      extractedValues: node.extractedValues,
      parentIndex,
      lastChildIndex: linearTree.nodes.length,
      bimIds: appendSubArray(linearTree.bimIds, node.bimIds),
      engineIds: appendSubArray(linearTree.engineIds, node.engineIds),
    };

    linearTree.nodes.push(linearLeaf);
  } else {
    const linearNode: QuantityTakeOffLinearTreeNode = {
      name: node.name,
      nestingLevel: node.nestingLevel,
      parentIndex,
      lastChildIndex: null,
      bimIds: {
        start: linearTree.bimIds.length,
        end: null,
      },
      engineIds: {
        start: linearTree.engineIds.length,
        end: null,
      },
    };

    const currentNodeIndex = linearTree.nodes.push(linearNode) - 1;
    for (const child of node.children) {
      processNode(child, linearTree, currentNodeIndex);
    }

    linearNode.lastChildIndex = linearTree.nodes.length - 1;
    linearNode.bimIds.end = linearTree.bimIds.length - 1;
    linearNode.engineIds.end = linearTree.bimIds.length - 1;
  }
}

function flattenTree(tree: QuantityTakeOffTree): QuantityTakeOffLinearTree {
  const linearTree: QuantityTakeOffLinearTree = {
    nodes: [],
    bimIds: [],
    engineIds: [],
    expandedRowsIndexes: [],
    type: tree.type,
    highlightedNodeIndex: null,
    elementalView: {
      selectedTreeLeafIndex: null,
      highlightedElementsIds: null,
      breakdown: QtoElementalViewBreakdownType.ByLevel,
      highlightEventSource: null,
    },
  };

  for (const rootNode of tree.nodes) {
    processNode(rootNode, linearTree, null);
  }

  return linearTree;
}

export const QuantityTakeOffTreeBuilder = {
  buildRevitTree,
};
