import React from 'react';
import { PropertyFormatEnum } from 'unit-2d-database/interfaces';
import { SingleSelectValue } from '../fields';
import { DropTargetLine } from './drop-target-line';
import { Styled } from './styled';

interface Props {
  list: Array<string | number>;
  isAutoFocus: boolean;
  format: PropertyFormatEnum;
  changeListValue: (index: number, newValue: string | number) => void;
  addValue: () => void;
  removeValue: (index: number) => void;
  moveTo(target: number, source: number): void;
}

export const OptionList: React.FC<Props> = React.memo(
  ({ list, isAutoFocus, format, changeListValue, removeValue, addValue, moveTo }) => {
    return (
      <Styled.OptionContainer>
        {list.map((listItem, index) => (
          <>
            <DropTargetLine index={index} moveTo={moveTo} />
            <SingleSelectValue
              key={index}
              index={index}
              value={listItem.toString()}
              isAutoFocus={isAutoFocus}
              type={format === PropertyFormatEnum.Text ? 'text' : 'number'}
              onChange={changeListValue}
              onRemove={removeValue}
              onEnter={addValue}
            />
          </>
        ))}
        <DropTargetLine index={list.length} moveTo={moveTo} />
      </Styled.OptionContainer>
    );
  },
);
