import { AnyAction } from 'redux';

import { CLASSIFICATION_INITIAL_STATE } from '../units/projects/constants/classification-intitial-state';
import { ClassificationReduxState } from '../units/projects/interfaces/classification/classification-redux-state';
import { classificationReducerMethods } from '../units/projects/reducers/classification-reducer-methods';

export default function(
  state: ClassificationReduxState = CLASSIFICATION_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): ClassificationReduxState {
  if (action.type in classificationReducerMethods) {
    return classificationReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
