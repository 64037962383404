import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './page.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { KreoColors } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { Engine } from '../../../../components/engine';
import { KreoEngine } from '../../../../components/engine/KreoEngine';
import { PlanProjectRouteParams, Qto3dProjectRouteParams } from '../../../../routes/app-routes-params';
import { ProjectLayout } from '../../../project-dashbord';
import { ModelCheckInfo } from '../../components/model-check-info';
import { ModelCheckStartCalculationControl } from '../../components/model-check-start-calculation-control';
import { KnownViewModel } from '../../enums/known-view-model';
import { ViewModelStatus } from '../../enums/view-model-status';
import { ModelCheckActions } from '../model-check-view/actions';
import { SourceIssue } from '../model-check-view/interfaces/types';


interface StateProps {
  loaded: boolean;
  trivial: number[];
  warning: number[];
  allBims: number;
  issues: SourceIssue[];
  viewModelStatus: ViewModelStatus;
}

interface DispatchProps {
  loadData: () => void;
}

interface ComponentState {
  enginePainted: boolean;
}

interface Props extends StateProps,
  DispatchProps,
  RouteComponentProps<PlanProjectRouteParams | Qto3dProjectRouteParams> { }


class ModelCheckPageComponent extends React.PureComponent<Props, ComponentState> {
  private readonly warningColor: string = '#ffae3d';
  private readonly trivialColor: string = KreoColors.gray6;

  private engine: KreoEngine;
  private allCheck: boolean = true;
  private isEngineUpdate: boolean = false;
  private engineLoadAction: () => void = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      enginePainted: false,
    };
  }

  public render(): React.ReactNode {
    const currentProjectId = parseInt(this.props.match.params.projectId, 10);
    const isLoading = !this.props.loaded
      || this.props.viewModelStatus === ViewModelStatus.Calculating;

    if (!isLoading) {
      this.props.issues.map(category => {
        if (category.count) {
          this.allCheck = false;
          return;
        }
      });
    }

    return (
      <ProjectLayout projectId={this.props.match.params.projectId}>
        <div className='model-check-page'>
          <div className='model-check-page__info'>
            <div className='model-check-page__title'>Model Check</div>
            <div className='model-check-page__content'>
              <KreoScrollbars relative={true} showShadowTop={true}>
                {this.leftPanel(isLoading, currentProjectId)}
              </KreoScrollbars>
            </div>
          </div>
          <div className='model-check-page__engine'>
            <Engine
              projectId={currentProjectId}
              textures='/static/textures/'
              sendEngineApi={this.setEngineApi}
              affterLoadAction={this.afterLoadAction}
              onHandleClick={this.dropSelect}
              backgroundColor='white'
            />
          </div>
        </div>
      </ProjectLayout>
    );
  }

  public componentDidMount(): void {
    this.handlePropsChange();
  }

  public componentDidUpdate(prevProps: Props): void {
    this.handlePropsChange(prevProps);
  }

  private leftPanel(isLoading: boolean, currentProjectId: number): React.ReactNode {
    if (this.props.viewModelStatus === ViewModelStatus.Empty) {
      return (<ModelCheckStartCalculationControl projectId={currentProjectId} issues={this.props.issues} />);
    } else if (isLoading) {
      return (
        <div className='model-check-page__calculating'>
          {this.props.viewModelStatus === ViewModelStatus.Calculating &&
             <div className='model-check-page__calculating-attention'>Calculation may take some time</div>}
          <SvgSpinner size='middle' />
        </div>
      );
    } else if (this.allCheck) {
      return (
        <>
          <ModelCheckStartCalculationControl projectId={currentProjectId} issues={this.props.issues}/>
          <div className='model-check-page__all-check'>
            The model meets all requirements
          </div>
        </>
      );
    } else {
      return (
        <>
          <ModelCheckStartCalculationControl projectId={currentProjectId} issues={this.props.issues}/>
          <ModelCheckInfo />
        </>
      );
    }
  }

  private handlePropsChange(prevProps?: Props): void {
    const {
      viewModelStatus,
      loadData,
      trivial,
      warning,
    } = this.props;
    const currentProjectId = parseInt(this.props.match.params.projectId, 10);
    const prevProjectId = prevProps && parseInt(prevProps.match.params.projectId, 10);

    if (viewModelStatus === ViewModelStatus.Ready) {
      if (prevProjectId !== currentProjectId) {
        loadData();
        if (this.state.enginePainted) {
          this.paintEngine([], []);
          this.setState({ enginePainted: false });
        }
      } else {
        const hasErrors = trivial.length > 0 || warning.length > 0;
        if (hasErrors && !this.state.enginePainted) {
          this.paintEngine(trivial, warning);
          this.setState({ enginePainted: true });
        }
      }
    }
  }

  @autobind
  private dropSelect(): void {
    this.engine.setSelected([]);
  }

  @autobind
  private paintEngine(trivial: number[], warning: number[]): void {
    if (this.isEngineUpdate) {
      this.highlightElements(trivial, warning);
    } else {
      this.engineLoadAction = () => this.highlightElements(trivial, warning);
    }
  }

  private highlightElements(trivial: number[], warning: number[]): void {
    if (trivial.length > 0 || warning.length > 0) {
      this.engine.setColorTint(null);
      this.engine.setColorTint(this.trivialColor, trivial);
      this.engine.setColorTint(this.warningColor, warning);
    }
  }

  @autobind
  private afterLoadAction(): void {
    if (this.engineLoadAction !== null) this.engineLoadAction();
    this.isEngineUpdate = true;
  }

  @autobind
  private setEngineApi(engine: any): void {
    this.engine = engine;
    this.forceUpdate();
  }
}


const mapStateToProps = (state: State): StateProps => {
  const currentProject = state.projects.currentProject;
  return {
    loaded: state.modelcheck.loaded,
    warning: state.modelcheck.warning,
    trivial: state.modelcheck.trivial,
    issues: state.modelcheck.issues,
    allBims: state.modelcheck.totalBim,
    viewModelStatus: currentProject
      ? currentProject.viewModelsStatuses[KnownViewModel.ModelCheck]
      : ViewModelStatus.Calculating,
  };
};


const mapDisaptchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    loadData: () => dispatch(ModelCheckActions.getData()),
  };
};


const connecter = connect<StateProps, DispatchProps>(mapStateToProps, mapDisaptchToProps);
export const ModelCheckPage = connecter(ModelCheckPageComponent);
