import React from 'react';
import { ProjectReportTemplateTable } from '../2d-report-template-table';
import { ReportTemplateVariableForm } from '../report-template-variable';
import { Styled } from './styled';

export const Body: React.FC = () => {
  return (
    <Styled.Container>
      <ProjectReportTemplateTable />
      <ReportTemplateVariableForm />
    </Styled.Container>
  );
};
