import React from 'react';

import { DrawingsInstanceMeasure } from 'common/components/drawings/interfaces';
import { Title } from '../../item-title';
import { ItemMeasures } from '../item-measures';
import { Styled } from '../styled';


interface Props {
  markColor: string;
  hideIconContainer: boolean;
  getContainerTitleRef: (ref: HTMLDivElement) => void;
  hideTitle: boolean;
  name: string;
  canEditMeasurement: boolean;
  hideIconsInfo: boolean;
  hideInfo: boolean;
  isImperial: boolean;
  measure: DrawingsInstanceMeasure;
  onNameChange: (name: string) => void;
}

const TitleComponent: React.FC<Props> = (
  {
    markColor,
    hideIconContainer,
    getContainerTitleRef,
    hideTitle,
    name,
    canEditMeasurement,
    onNameChange,
    hideIconsInfo,
    hideInfo,
    isImperial,
    measure,
  },
) => {
  return (
    <Styled.Wrapper>
      <Styled.IconContainer
        markColor={markColor}
        hideIconContainer={hideIconContainer}
      >
        <ItemMeasures
          measure={measure}
          showTooltip={false}
          hideIconsInfo={hideIconsInfo}
          hideInfo={hideInfo}
          isImperial={isImperial}
        />
      </Styled.IconContainer>
      <Styled.Inner
        ref={getContainerTitleRef}
        hideTitle={hideTitle}
      >
        <Title
          name={name}
          disabled={!canEditMeasurement}
          onChange={onNameChange}
        />
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

export const ItemTitle = React.memo(TitleComponent);
