import autobind from 'autobind-decorator';
import React from 'react';

import { UndoHotkey } from 'common/components/drawings/utils/hotkey-utils';
import {
  GlobalKeyboardEventsControllerContextProps,
  withGlobalKeyboardEventsController,
} from 'common/components/global-keyboard-events-controller';
import { ConstantFunctions } from 'common/constants/functions';
import { UndoRedoContext, UndoRedoListenersContext, UndoRedoStateContext } from './undo-redo-context';
import { UndoRedoContextApiProps } from './undo-redo-context-props';

export interface UndoRedoActionMethods {
  undo: () => void;
  redo: () => void;
}

interface State {
  actionIndex: number;
  active: boolean;
  actions: UndoRedoActionMethods[];
}

class UndoRedoContextProviderComponent extends React.PureComponent<GlobalKeyboardEventsControllerContextProps, State> {
  private readonly CACHE_LIMIT: number = 25;

  private readonly api: UndoRedoContextApiProps;

  constructor(props: GlobalKeyboardEventsControllerContextProps) {
    super(props);
    this.state = {
      actions: [],
      actionIndex: 0,
      active: true,
    };

    this.api = {
      undoRedoChangeActiveStatus: this.activate,
      addUndoRedo: this.addUndoRedoAction,
      cleanUndoRedo: this.cleanUndoRedo,
      undo: this.undo,
      redo: this.redo,
    };
  }

  public render(): React.ReactNode {
    return (
      <UndoRedoContext.Provider value={{ ...this.api }}>
        <UndoRedoStateContext.Provider value={{ canRedo: this.canRedo(), canUndo: this.canUndo() }}>
          <UndoRedoListenersContext.Provider
            value={{ undoListener: this.undoListener, redoListener: this.redoListener }}
          >
            {this.props.children}
          </UndoRedoListenersContext.Provider>
        </UndoRedoStateContext.Provider>
      </UndoRedoContext.Provider>
    );
  }

  public componentDidMount(): void {
    this.props.addKeyDownEventListener(UndoHotkey.Undo, this.undoListener);
    this.props.addKeyDownEventListener(UndoHotkey.Redo, this.redoListener);
  }

  public componentWillUnmount(): void {
    this.props.removeKeyDownEventListener(UndoHotkey.Undo, this.undoListener);
    this.props.removeKeyDownEventListener(UndoHotkey.Redo, this.redoListener);
  }

  @autobind
  private redoListener(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    if (this.state.active) {
      this.redo();
    }
  }

  @autobind
  private undoListener(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    if (this.state.active) {
      this.undo();
    }
  }

  private getLastIndex(): number {
    return this.state.actions.length - 1;
  }

  @autobind
  private undo(): void {
    const { actionIndex, actions } = this.state;
    if (this.canUndo()) {
      const index = this.getLastIndex() - actionIndex;
      const data = actions[index];
      data.undo();
      this.setState({
        actionIndex: actionIndex + 1,
      });
    }
  }

  @autobind
  private redo(): void {
    const { actionIndex, actions } = this.state;

    if (this.canRedo()) {
      const newActionIndex = actionIndex - 1;
      const index = this.getLastIndex() - newActionIndex;
      const data = actions[index];
      data.redo();
      this.setState({
        actionIndex: newActionIndex,
      });
    }
  }

  @autobind
  private activate(value: boolean): void {
    this.setState({ active: value });
  }

  @autobind
  private cleanUndoRedo(): void {
    this.setState({ actionIndex: 0, actions: [] });
  }

  @autobind
  private addUndoRedoAction(undo: () => void, redo: () => void): void {
    this.setState((state) => {
      const { actions, actionIndex } = state;
      const newState = { actions: [...actions], actionIndex };

      if (actionIndex) {
        newState.actions = newState.actions.slice(0, -newState.actionIndex);
      }
      if (newState.actions.length === this.CACHE_LIMIT) {
        newState.actions.shift();
      }
      newState.actions.push({ undo, redo });

      newState.actionIndex = 0;
      return newState;
    });
  }

  @autobind
  private canUndo(): boolean {
    return this.state.actionIndex !== this.state.actions.length;
  }

  @autobind
  private canRedo(): boolean {
    return this.state.actionIndex !== 0;
  }
}

export const UndoRedoContextProvider = withGlobalKeyboardEventsController(UndoRedoContextProviderComponent);
