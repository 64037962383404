import { Action } from 'redux';
import { QuantityTakeOffFilter } from '../types/quantity-take-off-filter';

const callFilter = (): Action => {
  return {
    type: QuantityTakeOffFilter.CALL_FILTER,
  };
};

export const QuantityTakeOffFilterActions = {
  callFilter,
};
