interface CellInfo {
  value: string;
  groupCellId: string;
}

export interface DynamicCellsStore {
  status: Record<string, boolean>;
  cells: Record<string, CellInfo>;
}
export class ReportDynamicCellValueStore {
  private store: DynamicCellsStore = {
    cells: {},
    status: {},
  };

  public setCell(id: string, value: string, groupCellId: string): void {
    this.store.cells[id] = {
      value,
      groupCellId,
    };
  }

  public setStatus(id: string, value: boolean): void {
    this.store.status[id] = value;
  }

  public getCells(): string[] {
    return Object.keys(this.store.cells);
  }

  public hasCell(id: string): boolean {
    return id in this.store.cells;
  }

  public getCellValue(id: string): string {
    return this.store.cells[id] && this.store.cells[id].value;
  }

  public getGroupCell(id: string): string {
    return this.store.cells[id] && this.store.cells[id].groupCellId;
  }

  public isValid(id: string): boolean {
    return !(id in this.store.status) || this.store.status[id];
  }

  public clearStore(): void {
    this.store = {
      cells: {},
      status: {},
    };
  }

  public setStore(store: DynamicCellsStore): void {
    this.store = store;
  }

  public getStore(): DynamicCellsStore {
    return this.store;
  }
}
