import * as t from 'io-ts';

import { QtoReportRowC } from './quantity-take-off-report-row';
import { QtoTemplateColumnsModelC } from './quantity-take-off-template-columns-model';

export const QtoTemplateC = t.type({
  id: t.number,
  companyId: t.number,
  name: t.string,

  basicColumns: QtoTemplateColumnsModelC,
  pivotColumns: QtoTemplateColumnsModelC,

  firstLevelRows: t.array(t.string),
  rows: t.array(QtoReportRowC),
});


export type QtoTemplate = t.TypeOf<typeof QtoTemplateC>;
