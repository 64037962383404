import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import React, { useCallback } from 'react';

import { EntityHeader } from '../entity-header';
import { Styled } from './styled';


export interface SectionMenuItemsProps {
  title: string;
  manageIcon: React.ComponentType;
  onSelectionMenuClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onIconClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  infoIcon: React.ComponentType;
  badge?: JSX.Element;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const SectionMenuItems: React.FC<SectionMenuItemsProps> = ({
  infoIcon,
  manageIcon: ManageIcon,
  title,
  badge,
  onSelectionMenuClick,
  onIconClick,
  disabled,
  children,
}) => {
  const handleOnClick = useCallback((e) => {
    ConstantFunctions.stopEvent(e);
    if (onSelectionMenuClick) {
      onSelectionMenuClick(e);
    }
  }, [onSelectionMenuClick]);

  const handleIconClick = useCallback((e) => {
    if (onIconClick) {
      ConstantFunctions.stopEvent(e);
      onIconClick(e);
    }
    if (onSelectionMenuClick && !onIconClick) {
      ConstantFunctions.stopEvent(e);
      onSelectionMenuClick(e);
    }
  }, [onIconClick, onSelectionMenuClick]);

  return (
    <Styled.Container disabled={disabled}>
      <Styled.SectionMenuContainer
        onClick={handleOnClick}
      >
        <EntityHeader
          badge={badge}
          icon={infoIcon}
          title={title}
          disabled={disabled}
        />
        <Styled.ArrowContainer
          onClick={handleIconClick}
          disabled={disabled}
        >
          <ManageIcon />
        </Styled.ArrowContainer>
      </Styled.SectionMenuContainer>
        {children}
    </Styled.Container>
  );
};
