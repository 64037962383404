import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './chat-icon.scss';

import { State } from 'common/interfaces/state';
import { KreoIconChat, KreoIconCloseSmall } from 'common/UIKit';
import { getOrCreateRoot } from 'common/UIKit/dialogs/';
import { PersistedStorageActions } from '../../../../persisted-storage/actions/creators';
import { TrackerProvider } from '../../utils/tracker-provider';

interface OwnProps {
  isCollapsedMenu?: boolean;
}

interface ReduxProps {
  isChatCloudDismissed: boolean;
  chatOpen: boolean;
}

interface ReduxActions {
  dismissChatCloud: () => void;
}

interface Props extends OwnProps, ReduxProps, ReduxActions { }

class Component extends React.Component<Props> {
  private root: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.getRoot();
  }

  private get intercomTracker(): IntercomTracker {
    return TrackerProvider.intercomTracker();
  }

  public render(): React.ReactNode {
    const { isChatCloudDismissed, chatOpen } = this.props;
    return (
      <div className={classNames('chat', { 'chat--disabled': chatOpen })}>
        <div className='chat__link' onClick={this.onClick}>
          <KreoIconChat />
        </div>
        {!isChatCloudDismissed ? this.renderCloud() : null}
      </div>
    );
  }

  private getRoot(): HTMLDivElement {
    if (!this.root) {
      this.root = getOrCreateRoot();
    }
    return this.root;
  }

  private renderCloud(): React.ReactNode {
    return ReactDOM.createPortal(
      (
        <div className={classNames('chat__cloud', { 'chat__cloud--collapsed-menu': this.props.isCollapsedMenu })}>
          We are here to help! Feel free to reach out if you need assistance.
          <div className='chat__cloud-close-btn' onClick={this.onCloseCloud}>
            <KreoIconCloseSmall />
          </div>
        </div>
      ),
      this.root,
    );
  }

  @autobind
  private onClick(): void {
    if (this.intercomTracker) {
      this.intercomTracker('show');
    }
  }

  @autobind
  private onCloseCloud(): void {
    this.props.dismissChatCloud();
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  return {
    chatOpen: state.analytics.chatOpen,
    isChatCloudDismissed: state.persistedStorage.isChatCloudDismissed,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    dismissChatCloud: () => dispatch(PersistedStorageActions.dismissChatCloud()),
  };
};

export const ChatIcon = connect(mapStateToProps, mapDispatchToProps)(Component);
