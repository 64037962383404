import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';
import { MinimapImageProps } from './minimap-image-props';

const VIEWPORT_AREA_BORDER = 2;

const Container = styled.div<{ width: number, height: number }>`
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  position: absolute;
  bottom: 60px;
  right: 0px;
  background-color: ${p => p.theme.color.backgroundRush};
  border: 5px solid ${p => p.theme.color.backgroundRush};
  border-radius: 20px;
  display: flex;
  overflow: hidden;
`;

const Img = styled.img<MinimapImageProps>`
  margin-top: auto;
  margin-bottom: auto;
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ${p => `rotate(${p.rotation}deg)`};
  --webkit-user-drag: none;
`;

const ViewportArea = styled.div<{ drag: boolean }>`
  border: ${VIEWPORT_AREA_BORDER}px solid ${Constants.Colors.GENERAL_COLORS.turquoiseDay};
  background-color: ${Constants.Colors.GENERAL_COLORS.turquoiseDay}80;
  position: absolute;
  box-sizing: border-box;
  cursor: ${p => p.drag ? 'grabbing' : 'grab'} ;
`;

export const Styled = {
  Container,
  Img,
  ViewportArea,
  VIEWPORT_AREA_BORDER,
};
