import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useRef } from 'react';

import { DrawingsGeometryDragEventHelper } from '../../drawings-geometry';
import { useOpenSticker, useStickerDrag } from './hooks';
import { StickerDialog } from './sticker-content';
import { Styled } from './styled';
import { DrawingsStickerAPI, createApi } from './utils';


interface Props {
  id: string;
  text: string;
  creator: string;
  createdAt: string | Date;
  isTemp?: boolean;
  selected?: boolean;
  canEdit?: boolean;
  disableOpen?: boolean;
  defaultPosition?: paper.Point;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
  startDragSticker?: (id: string, startPosition: paper.Point) => void;
  removeSticker: (id: string) => void;
  changeStickerText: (id: string, text: string) => void;
  sendStickerRef?: (ref: DrawingsStickerAPI, id: string) => void;
  stopDragEvent?: () => void;
}

const StickerComponent: React.FC<Props> = ({
  id,
  text,
  createdAt,
  creator,
  isTemp,
  selected,
  canEdit,
  dragEventsHelper,
  disableOpen,
  startDragSticker,
  removeSticker,
  changeStickerText,
  sendStickerRef,
  stopDragEvent,
}) => {
  const containerRef = useRef<HTMLDivElement>();

  const { dragging, startDrag, stopDrag } = useStickerDrag(id, dragEventsHelper, startDragSticker, stopDragEvent);
  const onRemove = useCallback(() => removeSticker(id), []);
  const {
    onClose,
    onOpen,
    position,
    onPositionChanged,
  } = useOpenSticker(isTemp, containerRef, disableOpen, isTemp ? onRemove : null);
  const onChangeStickerText = useCallback((newText: string) => changeStickerText(id, newText), []);
  useEffect(() => {
    return () => {
      sendStickerRef(null, id);
    };
  }, []);

  const saveRef = useCallback((ref: HTMLDivElement) => {
    containerRef.current = ref;
    const api = createApi(ref, onPositionChanged);
    sendStickerRef(api, id);
  }, [sendStickerRef]);

  return (
    <>
      <Styled.Container
        ref={saveRef}
        grabbed={dragging}
        onMouseDown={startDrag}
        onMouseUp={stopDrag}
        onClick={onOpen}
        selected={selected}
      >
        <Icons.StickyNote/>
      </Styled.Container>
      {
        position ? (
          <StickerDialog
            canEdit={canEdit}
            text={text}
            createdAt={createdAt}
            creator={creator}
            onClose={onClose}
            position={position}
            isTemp={isTemp}
            setStickerText={onChangeStickerText}
            remove={onRemove}
          />
        ) : null
      }
    </>
  );
};

export const Sticker = React.memo(StickerComponent);
