import { Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { MentionTextPartType } from './interfaces';
import { useMentionSplit } from './use-mention-split';
import { UserMention } from './user-mention';


interface Props {
  text: string;
}

const CommentTextWithMentionComponent: React.FC<Props> = ({ text }) => {
  const parts = useMentionSplit(text);
  return (
    <Text>
      {
        parts.map((x, i) =>  {
          switch (x.type) {
            case MentionTextPartType.Mention:
              return <UserMention userId={x.value} key={i}/>;
            case MentionTextPartType.NextLineText:
              return (
                <>
                  <p/>
                  {x.value}
                </>
              );
            default:
              return x.value;
          }
        })
      }
    </Text>
  );
};


export const CommentTextWithMention = React.memo(CommentTextWithMentionComponent);
