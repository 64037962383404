import { useCallback } from 'react';

import { useRendererApiContext } from '../../drawings-contexts';
import { DrawingsDrawMode } from '../../enums';
import { DrawingGeometryOperationType } from '../../enums/drawing-geometry-operation-type';
import { useDrawModeApi } from '../../hooks';

export function useCancelBoolean(): () => void {
  const { setDrawMode } = useDrawModeApi();
  const { rendererApi } = useRendererApiContext();
  return useCallback(() => {
    setDrawMode(DrawingsDrawMode.Disabled);
    rendererApi.setIdsForOperation(null, DrawingGeometryOperationType.Boolean);
  }, [setDrawMode, rendererApi]);
}
