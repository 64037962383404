import styled from 'styled-components';

const Container = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.color.turquoise};
  border-radius: 10px;
  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
`;

export const Styled = {
  Container,
};
