const LIMIT = 20;

interface QueueRequest<T> {
  promiseGetter: () => Promise<T>;
  resolve: (value: T) => void;
  reject: (reason?: any) => void;
}

export class QueueLoader {
  private static currentRequests = 0;
  private static requests: Array<QueueRequest<any>> = [];

  public static request<T>(promiseGetter: () => Promise<T>): Promise<T> {
    if (this.currentRequests < LIMIT) {
      return this.runRequest(promiseGetter);
    }
    return new Promise((resolve, reject) => {
      this.requests.push({ promiseGetter, resolve, reject });
    });
  }

  public static removeFromQueue<T>(promiseGetter: () => Promise<T>): void {
    this.requests = this.requests.filter((request) => request.promiseGetter !== promiseGetter);
  }

  private static async runRequest<T>(promiseGetter: () => Promise<T>): Promise<T> {
    try {
      this.currentRequests++;
      const result = await promiseGetter();
      return result;
    } finally {
      this.currentRequests--;
      this.runNext();
    }
  }

  private static runNext(): void {
    if (this.requests.length && this.currentRequests < LIMIT) {
      const { promiseGetter, resolve, reject } = this.requests.shift();
      this.runRequest(promiseGetter).then(resolve).catch(reject);
    }
  }
}
