import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './companies-list.scss';

import { SubscriptionType } from 'common/constants/subscription';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AdminCompaniesActions } from '../actions/creators/companies';
import { AdminCompanyVm } from '../interfaces/admin-company-vm';
import {
  ATTACH_EMPLOYEE_TO_PROJECT_DIALOG_NAME,
  AttachEmployeeToProjectDialog,
} from './attach-employee-to-project-dialog';
import { ATTACH_DATABASES_TO_COMPANY_DIALOG_NAME, DatabaseSelectDialog } from './database-select-dialog';
import { ATTACH_EMPLOYEE_TO_COMPANY_DIALOG_NAME, EmployeeSelectDialog } from './employee-select-dialog';
import { Feed } from './feed/feed';
import { CompanyItem } from './items';

interface ReduxProps {
  count: number;
  status: RequestStatus;
  data: AdminCompanyVm[];
  initialSearch: string;
  subscriptionPlansRequestStatus: RequestStatus;
  currenciesRequestStatus: RequestStatus;
}

interface ReduxActions {
  loadMore: (skip: number, search: string) => void;
  cleanDeletedProjects: (companyId: number) => void;
  loadRoles: (companyId: number, subscriptionType: SubscriptionType) => void;
  showDialog: (dialogName: string, data: any) => void;
  closeDialog: (dialogName: string) => void;
  goTo: (url: string, email: string) => void;
  onDatabaseDetach: (companyId: number, databaseId: number) => void;
  onEmployeeDetachClick: (companyId: number, employeeGuid: string, subscriptionType: SubscriptionType) => void;
  loadSubscriptionPlans: () => void;
  loadCurrencies: () => void;
  onDeleteCompany: (companyId: number) => void;
  createEmptySubscription: (companyId: number, subscriptionType: SubscriptionType) => void;
}

interface Props extends ReduxProps, ReduxActions {}

class CompaniesListComponent extends React.Component<Props> {
  public componentDidMount(): void {
    const {
      subscriptionPlansRequestStatus,
      currenciesRequestStatus,
      loadSubscriptionPlans,
      loadCurrencies,
    } = this.props;

    if (subscriptionPlansRequestStatus === RequestStatus.NotRequested) {
      loadSubscriptionPlans();
    }

    if (currenciesRequestStatus === RequestStatus.NotRequested) {
      loadCurrencies();
    }
  }

  public render(): JSX.Element {
    return (
      <div className='companies-list'>
        <DatabaseSelectDialog />
        <EmployeeSelectDialog />
        <AttachEmployeeToProjectDialog />
        <Feed
          className='companies-list__feed'
          searchPlaceholder='Company name...'
          data={this.props.data}
          count={this.props.count}
          status={this.props.status}
          renderItem={this.renderItem}
          loadMore={this.props.loadMore}
          initialSearch={this.props.initialSearch}
        />
      </div>
    );
  }

  @autobind
  private renderItem(item: AdminCompanyVm): React.ReactNode {
    return (
      <CompanyItem
        key={item.id}
        company={item}
        createEmptySubscription={this.props.createEmptySubscription}
        onCleanDeletedProjectsClick={this.props.cleanDeletedProjects}
        onDatabasesAttach={this.onOpenDatabaseAttachDialog}
        onEmployeesAttach={this.onOpenEmployeeAttachDialog}
        onEmployeesAttachToProject={this.onOpenEmployeeAttachToProjectDialog}
        onEmployeeClick={this.onEmployeeClick}
        onDatabaseDetachClick={this.props.onDatabaseDetach}
        onEmployeeDetachClick={this.props.onEmployeeDetachClick}
        onDeleteCompany={this.props.onDeleteCompany}
      />
    );
  }

  @autobind
  private onEmployeeClick(email: string): void {
    this.props.goTo('/admin/people', email);
  }

  @autobind
  private onOpenDatabaseAttachDialog(companyId: number): void {
    this.props.showDialog(ATTACH_DATABASES_TO_COMPANY_DIALOG_NAME, { companyId });
  }

  @autobind
  private onOpenEmployeeAttachDialog(companyId: number, subscriptionType: SubscriptionType): void {
    this.props.loadRoles(companyId, subscriptionType);
    this.props.showDialog(ATTACH_EMPLOYEE_TO_COMPANY_DIALOG_NAME, { companyId, subscriptionType });
  }

  @autobind
  private onOpenEmployeeAttachToProjectDialog(companyId: number, projectId: number): void {
    this.props.showDialog(ATTACH_EMPLOYEE_TO_PROJECT_DIALOG_NAME, { companyId, projectId });
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const search = state.router.location.search;

  return {
    count: state.admin.companies.count,
    data: state.admin.companies.data,
    status: state.admin.companies.status,
    initialSearch: search.substring(1, search.length),
    subscriptionPlansRequestStatus: state.admin.companies.statuses.getSubscriptionPlans,
    currenciesRequestStatus: state.admin.companies.statuses.currencies,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadMore: (skip, search) => dispatch(AdminCompaniesActions.loadCompanies({ skip, search })),
    cleanDeletedProjects: (companyId) => dispatch(AdminCompaniesActions.deleteProjects(companyId)),
    loadRoles: (companyId, subscriptionType) => dispatch(AdminCompaniesActions.loadRoles(companyId, subscriptionType)),
    showDialog: (dialogName, data) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    closeDialog: (dialogName) => dispatch(KreoDialogActions.closeDialog(dialogName)),
    goTo: (url, email) => dispatch(push({
      pathname: url,
      search: email,
    })),
    onDatabaseDetach: (companyId, databaseId) =>
      dispatch(AdminCompaniesActions.detachDatabase({
        companyId,
        databaseId,
      })),
    onEmployeeDetachClick: (companyId, employeeGuid, subscriptionType) =>
      dispatch(AdminCompaniesActions.detachEmployee({
        companyId,
        employeeGuid,
        subscriptionType,
      })),
    loadSubscriptionPlans: () => dispatch(AdminCompaniesActions.getSubscriptionPlans()),
    loadCurrencies: () => dispatch(AdminCompaniesActions.loadCurrencies()),
    onDeleteCompany: (companyId) => dispatch(AdminCompaniesActions.deleteCompany(companyId)),
    createEmptySubscription: (companyId, subscriptionType) =>
      dispatch(AdminCompaniesActions.createEmptySubscription(companyId, subscriptionType)),
  };
};

export const CompaniesList = connect(mapStateToProps, mapDispatchToProps)(CompaniesListComponent);
