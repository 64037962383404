// automatically generated by the FlatBuffers compiler, do not modify
/* eslint-disable */
import { flatbuffers } from 'flatbuffers'
/**
 * @constructor
 */
export namespace PropertiesFlatBufferTypes{
export class LayerRawDataFlatBuffer {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer|null = null;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {LayerRawDataFlatBuffer}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):LayerRawDataFlatBuffer {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {LayerRawDataFlatBuffer=} obj
 * @returns {LayerRawDataFlatBuffer}
 */
static getRootAsLayerRawDataFlatBuffer(bb:flatbuffers.ByteBuffer, obj?:LayerRawDataFlatBuffer):LayerRawDataFlatBuffer {
  return (obj || new LayerRawDataFlatBuffer).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
material():string|null
material(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
material(optionalEncoding?:any):string|Uint8Array|null {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
layerFunction():string|null
layerFunction(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
layerFunction(optionalEncoding?:any):string|Uint8Array|null {
  var offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
thickness():string|null
thickness(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
thickness(optionalEncoding?:any):string|Uint8Array|null {
  var offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startLayerRawDataFlatBuffer(builder:flatbuffers.Builder) {
  builder.startObject(3);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} materialOffset
 */
static addMaterial(builder:flatbuffers.Builder, materialOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, materialOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} layerFunctionOffset
 */
static addLayerFunction(builder:flatbuffers.Builder, layerFunctionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, layerFunctionOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} thicknessOffset
 */
static addThickness(builder:flatbuffers.Builder, thicknessOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, thicknessOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endLayerRawDataFlatBuffer(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace PropertiesFlatBufferTypes{
export class LayerPropertiesInfoFlatBuffer {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer|null = null;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {LayerPropertiesInfoFlatBuffer}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):LayerPropertiesInfoFlatBuffer {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {LayerPropertiesInfoFlatBuffer=} obj
 * @returns {LayerPropertiesInfoFlatBuffer}
 */
static getRootAsLayerPropertiesInfoFlatBuffer(bb:flatbuffers.ByteBuffer, obj?:LayerPropertiesInfoFlatBuffer):LayerPropertiesInfoFlatBuffer {
  return (obj || new LayerPropertiesInfoFlatBuffer).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {flatbuffers.Long}
 */
id():flatbuffers.Long {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : this.bb!.createLong(0, 0);
};

/**
 * @returns {number}
 */
properties():number {
  var offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startLayerPropertiesInfoFlatBuffer(builder:flatbuffers.Builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Long} id
 */
static addId(builder:flatbuffers.Builder, id:flatbuffers.Long) {
  builder.addFieldInt64(0, id, builder.createLong(0, 0));
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} properties
 */
static addProperties(builder:flatbuffers.Builder, properties:number) {
  builder.addFieldInt32(1, properties, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endLayerPropertiesInfoFlatBuffer(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace PropertiesFlatBufferTypes{
export class BimElementPropertyGroupFlatBuffer {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer|null = null;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {BimElementPropertyGroupFlatBuffer}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):BimElementPropertyGroupFlatBuffer {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {BimElementPropertyGroupFlatBuffer=} obj
 * @returns {BimElementPropertyGroupFlatBuffer}
 */
static getRootAsBimElementPropertyGroupFlatBuffer(bb:flatbuffers.ByteBuffer, obj?:BimElementPropertyGroupFlatBuffer):BimElementPropertyGroupFlatBuffer {
  return (obj || new BimElementPropertyGroupFlatBuffer).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {number} index
 * @returns {number}
 */
properties(index: number):number|null {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readInt32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
};

/**
 * @returns {number}
 */
propertiesLength():number {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @returns {Int32Array}
 */
propertiesArray():Int32Array|null {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? new Int32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
};

/**
 * @returns {number}
 */
name():number {
  var offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startBimElementPropertyGroupFlatBuffer(builder:flatbuffers.Builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} propertiesOffset
 */
static addProperties(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, propertiesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<number>} data
 * @returns {flatbuffers.Offset}
 */
static createPropertiesVector(builder:flatbuffers.Builder, data:number[] | Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
static startPropertiesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} name
 */
static addName(builder:flatbuffers.Builder, name:number) {
  builder.addFieldInt32(1, name, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endBimElementPropertyGroupFlatBuffer(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace PropertiesFlatBufferTypes{
export class BimElementPropertiesInfoFlatBuffer {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer|null = null;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {BimElementPropertiesInfoFlatBuffer}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):BimElementPropertiesInfoFlatBuffer {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {BimElementPropertiesInfoFlatBuffer=} obj
 * @returns {BimElementPropertiesInfoFlatBuffer}
 */
static getRootAsBimElementPropertiesInfoFlatBuffer(bb:flatbuffers.ByteBuffer, obj?:BimElementPropertiesInfoFlatBuffer):BimElementPropertiesInfoFlatBuffer {
  return (obj || new BimElementPropertiesInfoFlatBuffer).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {number} index
 * @param {PropertiesFlatBufferTypes.LayerPropertiesInfoFlatBuffer=} obj
 * @returns {PropertiesFlatBufferTypes.LayerPropertiesInfoFlatBuffer}
 */
layers(index: number, obj?:PropertiesFlatBufferTypes.LayerPropertiesInfoFlatBuffer):PropertiesFlatBufferTypes.LayerPropertiesInfoFlatBuffer|null {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new PropertiesFlatBufferTypes.LayerPropertiesInfoFlatBuffer).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
};

/**
 * @returns {number}
 */
layersLength():number {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {number} index
 * @param {PropertiesFlatBufferTypes.BimElementPropertyGroupFlatBuffer=} obj
 * @returns {PropertiesFlatBufferTypes.BimElementPropertyGroupFlatBuffer}
 */
properties(index: number, obj?:PropertiesFlatBufferTypes.BimElementPropertyGroupFlatBuffer):PropertiesFlatBufferTypes.BimElementPropertyGroupFlatBuffer|null {
  var offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new PropertiesFlatBufferTypes.BimElementPropertyGroupFlatBuffer).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
};

/**
 * @returns {number}
 */
propertiesLength():number {
  var offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
sourceId():string|null
sourceId(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
sourceId(optionalEncoding?:any):string|Uint8Array|null {
  var offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startBimElementPropertiesInfoFlatBuffer(builder:flatbuffers.Builder) {
  builder.startObject(3);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} layersOffset
 */
static addLayers(builder:flatbuffers.Builder, layersOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, layersOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
static createLayersVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
static startLayersVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} propertiesOffset
 */
static addProperties(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, propertiesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
static createPropertiesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
static startPropertiesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} sourceIdOffset
 */
static addSourceId(builder:flatbuffers.Builder, sourceIdOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, sourceIdOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endBimElementPropertiesInfoFlatBuffer(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace PropertiesFlatBufferTypes{
export class PropertiesFlatBuffer {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer|null = null;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {PropertiesFlatBuffer}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):PropertiesFlatBuffer {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {PropertiesFlatBuffer=} obj
 * @returns {PropertiesFlatBuffer}
 */
static getRootAsPropertiesFlatBuffer(bb:flatbuffers.ByteBuffer, obj?:PropertiesFlatBuffer):PropertiesFlatBuffer {
  return (obj || new PropertiesFlatBuffer).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
value():string|null
value(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
value(optionalEncoding?:any):string|Uint8Array|null {
  var offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startPropertiesFlatBuffer(builder:flatbuffers.Builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} valueOffset
 */
static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, valueOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endPropertiesFlatBuffer(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace PropertiesFlatBufferTypes{
export class BimElementPropertiesFlatBuffer {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer|null = null;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {BimElementPropertiesFlatBuffer}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):BimElementPropertiesFlatBuffer {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {BimElementPropertiesFlatBuffer=} obj
 * @returns {BimElementPropertiesFlatBuffer}
 */
static getRootAsBimElementPropertiesFlatBuffer(bb:flatbuffers.ByteBuffer, obj?:BimElementPropertiesFlatBuffer):BimElementPropertiesFlatBuffer {
  return (obj || new BimElementPropertiesFlatBuffer).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {number} index
 * @returns {flatbuffers.Long}
 */
keys(index: number):flatbuffers.Long|null {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readInt64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : this.bb!.createLong(0, 0);
};

/**
 * @returns {number}
 */
keysLength():number {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {number} index
 * @param {PropertiesFlatBufferTypes.BimElementPropertiesInfoFlatBuffer=} obj
 * @returns {PropertiesFlatBufferTypes.BimElementPropertiesInfoFlatBuffer}
 */
values(index: number, obj?:PropertiesFlatBufferTypes.BimElementPropertiesInfoFlatBuffer):PropertiesFlatBufferTypes.BimElementPropertiesInfoFlatBuffer|null {
  var offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new PropertiesFlatBufferTypes.BimElementPropertiesInfoFlatBuffer).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
};

/**
 * @returns {number}
 */
valuesLength():number {
  var offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startBimElementPropertiesFlatBuffer(builder:flatbuffers.Builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} keysOffset
 */
static addKeys(builder:flatbuffers.Builder, keysOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, keysOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Long>} data
 * @returns {flatbuffers.Offset}
 */
static createKeysVector(builder:flatbuffers.Builder, data:flatbuffers.Long[]):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addInt64(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
static startKeysVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} valuesOffset
 */
static addValues(builder:flatbuffers.Builder, valuesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, valuesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
static createValuesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
static startValuesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endBimElementPropertiesFlatBuffer(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace PropertiesFlatBufferTypes{
export class BimModelRawDataFlatBuffer {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer|null = null;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {BimModelRawDataFlatBuffer}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):BimModelRawDataFlatBuffer {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {BimModelRawDataFlatBuffer=} obj
 * @returns {BimModelRawDataFlatBuffer}
 */
static getRootAsBimModelRawDataFlatBuffer(bb:flatbuffers.ByteBuffer, obj?:BimModelRawDataFlatBuffer):BimModelRawDataFlatBuffer {
  return (obj || new BimModelRawDataFlatBuffer).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {number} index
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array}
 */
groupNames(index: number):string
groupNames(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
groupNames(index: number,optionalEncoding?:any):string|Uint8Array|null {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
};

/**
 * @returns {number}
 */
groupNamesLength():number {
  var offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {number} index
 * @param {PropertiesFlatBufferTypes.LayerRawDataFlatBuffer=} obj
 * @returns {PropertiesFlatBufferTypes.LayerRawDataFlatBuffer}
 */
layerProperties(index: number, obj?:PropertiesFlatBufferTypes.LayerRawDataFlatBuffer):PropertiesFlatBufferTypes.LayerRawDataFlatBuffer|null {
  var offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new PropertiesFlatBufferTypes.LayerRawDataFlatBuffer).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
};

/**
 * @returns {number}
 */
layerPropertiesLength():number {
  var offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {PropertiesFlatBufferTypes.BimElementPropertiesFlatBuffer=} obj
 * @returns {PropertiesFlatBufferTypes.BimElementPropertiesFlatBuffer|null}
 */
elementProperties(obj?:PropertiesFlatBufferTypes.BimElementPropertiesFlatBuffer):PropertiesFlatBufferTypes.BimElementPropertiesFlatBuffer|null {
  var offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new PropertiesFlatBufferTypes.BimElementPropertiesFlatBuffer).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
};

/**
 * @param {number} index
 * @param {PropertiesFlatBufferTypes.PropertiesFlatBuffer=} obj
 * @returns {PropertiesFlatBufferTypes.PropertiesFlatBuffer}
 */
properties(index: number, obj?:PropertiesFlatBufferTypes.PropertiesFlatBuffer):PropertiesFlatBufferTypes.PropertiesFlatBuffer|null {
  var offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new PropertiesFlatBufferTypes.PropertiesFlatBuffer).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
};

/**
 * @returns {number}
 */
propertiesLength():number {
  var offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startBimModelRawDataFlatBuffer(builder:flatbuffers.Builder) {
  builder.startObject(4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} groupNamesOffset
 */
static addGroupNames(builder:flatbuffers.Builder, groupNamesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, groupNamesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
static createGroupNamesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
static startGroupNamesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} layerPropertiesOffset
 */
static addLayerProperties(builder:flatbuffers.Builder, layerPropertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, layerPropertiesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
static createLayerPropertiesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
static startLayerPropertiesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} elementPropertiesOffset
 */
static addElementProperties(builder:flatbuffers.Builder, elementPropertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, elementPropertiesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} propertiesOffset
 */
static addProperties(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, propertiesOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
static createPropertiesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
static startPropertiesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endBimModelRawDataFlatBuffer(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} offset
 */
static finishBimModelRawDataFlatBufferBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
};

}
}
