import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconMenuCollapse: React.FC = () => {
  return (
    <svg
      width='14px'
      height='12px'
      viewBox='0 0 14 12'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Viewer_1_ClipBox_Ghost-Off' transform='translate(-108.000000, -89.000000)'>
          <g id='Group-Copy' transform='translate(90.000000, 70.000000)'>
            <g id='Buttons/Float/icons/menu_collapsed' transform='translate(15.000000, 15.000000)'>
              <rect id='Rectangle-4' x='2' y='2' width='16' height='16' />
              <path
                // eslint-disable-next-line max-len
                d='M7,9 L16,9 C16.5522847,9 17,9.44771525 17,10 C17,10.5522847 16.5522847,11 16,11 L7,11 C6.44771525,11 6,10.5522847 6,10 C6,9.44771525 6.44771525,9 7,9 Z M4,9 C4.55228475,9 5,9.44771525 5,10 C5,10.5522847 4.55228475,11 4,11 C3.44771525,11 3,10.5522847 3,10 C3,9.44771525 3.44771525,9 4,9 Z M7,4 L16,4 C16.5522847,4 17,4.44771525 17,5 C17,5.55228475 16.5522847,6 16,6 L7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 Z M4,4 C4.55228475,4 5,4.44771525 5,5 C5,5.55228475 4.55228475,6 4,6 C3.44771525,6 3,5.55228475 3,5 C3,4.44771525 3.44771525,4 4,4 Z M7,14 L16,14 C16.5522847,14 17,14.4477153 17,15 C17,15.5522847 16.5522847,16 16,16 L7,16 C6.44771525,16 6,15.5522847 6,15 C6,14.4477153 6.44771525,14 7,14 Z M4,14 C4.55228475,14 5,14.4477153 5,15 C5,15.5522847 4.55228475,16 4,16 C3.44771525,16 3,15.5522847 3,15 C3,14.4477153 3.44771525,14 4,14 Z'
                id='Combined-Shape'
                fill={KreoColors.f1}
                fillRule='evenodd'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
