export enum WizzardErrorTypes {
  Crash = 'Timeout',
  SelectionAreaError = 'SelectionAreaError',
}


export const WIZZARD_ERROR_MESSAGES = {
  [WizzardErrorTypes.Crash]: 'Something went wrong. Please try again.',
};


