import { CursorHint, TinyText } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CalibrateActions } from 'common/components/drawings/actions/creators/calibrate';
import { DrawingsCanvasConstants } from 'common/components/drawings/constants';
import { State } from 'common/interfaces/state';
import { StringUtils } from 'common/utils/string-utils';
import { CalibrateImperialInput } from './calibrate-imperial-input';
import { CalibrateMetricInput } from './calibrate-metric-input';


export const CalibrateInputComponent: React.FC = () => {
  const dispatch = useDispatch();
  const disableDistance = useSelector<State, boolean>((state) => {
    const lineLengthPx = state.drawings.temporaryCalibration.lineLengthPx;
    return lineLengthPx === undefined || lineLengthPx === null;
  });
  const isImperialUnit = useSelector<State, boolean>((state) => state.account.settings.isImperial);
  const distance = useSelector<State, string | number>((state) => state.drawings.temporaryCalibration.lineLength);
  const dist = !disableDistance ? distance : '';
  const isDistanceValid = disableDistance || Number(distance) >= DrawingsCanvasConstants.minimumCalibrationLength;

  const changeDistance = useCallback(
    (newValue: string) => {
      const value = StringUtils.removeNonNumericSymbols(newValue);
      const numberValue = Number(value);
      if (Number.isNaN(numberValue)) {
        return;
      }
      dispatch(CalibrateActions.setOriginalLineLength(value || '0'));
    },
    [dispatch],
  );

  const Component = isImperialUnit ? CalibrateImperialInput : CalibrateMetricInput;

  return (
    <>
      <Component
        isDistanceValid={isDistanceValid}
        distance={dist}
        changeDistance={changeDistance}
        disableDistance={disableDistance}
      />
      {!distance ? (
        <CursorHint position={'right'} hintText={'Draw a line first, then enter its length'} yShift={30} />
      ) : null}
      {!isDistanceValid && (
        <TinyText color="red">
          {`The length parameter must be not less than ${DrawingsCanvasConstants.minimumCalibrationLength}`}
        </TinyText>
      )}
    </>
  );
};

export const CalibrateInput = React.memo(CalibrateInputComponent);
