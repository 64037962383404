import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconDelBasket: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons/14x14/Delete' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          // eslint-disable-next-line max-len
          d='M8.5,1 C8.77614237,1 9,1.22385763 9,1.5 L9,2 L12.5,2 C13.1296296,2 13.1646091,2.89197531 12.6049383,2.99108368 L12.5,3 L12,3 L12,11.5 C12,12.3284271 11.3284271,13 10.5,13 L3.5,13 C2.67157288,13 2,12.3284271 2,11.5 L2,3 L1.5,3 C0.833333333,3 0.833333333,2 1.5,2 L5,2 L5,1.5 C5,1.22385763 5.22385763,1 5.5,1 L8.5,1 Z M11,3 L3,3 L3,11.5 C3,11.7761424 3.22385763,12 3.5,12 L10.5,12 C10.7761424,12 11,11.7761424 11,11.5 L11,3 Z M6,4.5 L6,10.5 C6,11.1666667 5,11.1666667 5,10.5 L5,4.5 C5,3.83333333 6,3.83333333 6,4.5 Z M9,4.5 L9,10.5 C9,11.1666667 8,11.1666667 8,10.5 L8,4.5 C8,3.83333333 9,3.83333333 9,4.5 Z'
          id='Shape'
          fill={KreoColors.f5}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
