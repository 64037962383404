import styled from 'styled-components';

const NewDrawingColor = styled.div<{ newDrawingColor: string }>`
  button {
    svg {
      fill: ${p => p.newDrawingColor};
    }
    :hover {
      svg {
        fill: ${p => p.newDrawingColor};
      }
    }
  }
`;

export const Styled = {
  NewDrawingColor,
};
