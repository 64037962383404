import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from '../../../../common/interfaces/reducer-methods';
import { MonoliteHelper } from '../../../../common/monolite';
import {
  BidPricingUserPackageInfoPayload,
  GetPackageCost,
  NewBid,
  SubcontractorBid,
  UpdateBidDescription,
  WinnerPackage,
} from '../../actions/payloads/bid-pricing';
import { BidPricingActionTypes } from '../../actions/types/bid-pricing';
import { UserBidPricingStatus } from '../../enums/user-bid-pricing-status';
import { BidPricingReduxState } from '../../interfaces/bid-pricing/bid-pricing-redux-state';
import { BidPricingUserPackage } from '../../interfaces/bid-pricing/bid-pricing-user-package';
import { BidPricingWorkPackage } from '../../interfaces/bid-pricing/bid-pricing-work-package';

export const bidPricingReducerMethods: ReducerMethods<BidPricingReduxState> = {
  [BidPricingActionTypes.GET_INFO_REQUEST]: state => {
    return new MonoliteHelper(state)
      .set(_ => _.packages, [])
      .set(_ => _.statuses.packages, RequestStatus.Loading)
      .get();
  },
  [BidPricingActionTypes.GET_INFO_SUCCEEDED]: (state, workpackages: BidPricingWorkPackage[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.packages, workpackages)
      .set(_ => _.statuses.packages, RequestStatus.Loaded)
      .get();
  },
  [BidPricingActionTypes.REMOVE_SUBCONTRACTOR_SUCCESSED]: (state, bid: SubcontractorBid) => {
    const wpIndex = state.packages.findIndex(x => x.id === bid.workPackageId);
    if (wpIndex >= 0 && state.packages[wpIndex].usersCost) {
      return new MonoliteHelper(state)
        .setFilter(_ => _.packages[wpIndex].usersCost, x => x.id !== bid.constructionCostId)
        .get();
    }
    return state;
  },
  [BidPricingActionTypes.SET_WINNER_SUCCEEDED]: (state, winner: WinnerPackage) => {
    const helper = new MonoliteHelper(state);
    const wpIndex = state.packages.findIndex(x => x.id === winner.workPackageId);
    if (wpIndex >= 0) {
      const workpackage = state.packages[wpIndex];
      if (workpackage.constructionCostId === winner.constructionCostId) {
        helper.set(_ => _.packages[wpIndex].status, UserBidPricingStatus.Winner).setMap(
          _ => _.packages[wpIndex].usersCost,
          sc => {
            sc.status = UserBidPricingStatus.Estimated;
            return sc;
          },
        );
      } else {
        helper.set(_ => _.packages[wpIndex].status, UserBidPricingStatus.Estimated).setMap(
          _ => _.packages[wpIndex].usersCost,
          sc => {
            if (sc.id === winner.constructionCostId && sc.user.id === winner.userId) {
              sc.status = UserBidPricingStatus.Winner;
            } else if (sc.status === UserBidPricingStatus.Winner) {
              sc.status = UserBidPricingStatus.Estimated;
            }
            return sc;
          },
        );
      }
    }
    return helper.get();
  },
  [BidPricingActionTypes.CANCEL_REQUEST]: (state, payload: BidPricingUserPackageInfoPayload) => {
    const wpIndex = state.packages.findIndex(x => x.id === payload.workPackageId);
    if (wpIndex >= 0) {
      return new MonoliteHelper(state)
        .setFilter(_ => _.packages[wpIndex].usersCost, x => x.user.email !== payload.email)
        .get();
    }
    return state;
  },
  [BidPricingActionTypes.GET_SUBCONTRACTOR_PACKAGES_REQUEST]: state => {
    return new MonoliteHelper(state)
      .set(_ => _.userPackages, [])
      .set(_ => _.statuses.userPackages, RequestStatus.Loading)
      .get();
  },
  [BidPricingActionTypes.GET_SUBCONTRACTOR_PACKAGES_SUCCEEDED]: (
    state,
    userpackages: BidPricingUserPackage[],
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.userPackages, userpackages)
      .set(_ => _.statuses.userPackages, RequestStatus.Loaded)
      .get();
  },
  [BidPricingActionTypes.CREATE_NEW_BID_SUCCEEDED]: (state, payload: NewBid) => {
    const wpIndex = state.userPackages.findIndex(x => x.id === payload.workPackageId);
    if (wpIndex >= 0) {
      return new MonoliteHelper(state)
        .setAppend(_ => _.userPackages[wpIndex].costs, payload.bid)
        .get();
    }
    return state;
  },
  [BidPricingActionTypes.UPDATE_BID_DESCRIPTION_SUCCEEDED]: (
    state,
    payload: UpdateBidDescription,
  ) => {
    return new MonoliteHelper(state).set(_ => _.package.description, payload.description).get();
  },
  [BidPricingActionTypes.SET_SUBCONTRACTOR_PACKAGE]: (state, payload: GetPackageCost) => {
    return new MonoliteHelper(state).set(_ => _.package, payload.subcontractorCost).get();
  },
};
