import * as React from 'react';

export const KreoIconNrm2: React.FC = (_props) => {
  return (
    <svg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='UI-KIT' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Database_Activities_UI-Elements' transform='translate(-118.000000, -448.000000)'>
          <g id='Group-21' transform='translate(118.000000, 308.000000)'>
            <g id='DB/icon-nrm1' transform='translate(0.000000, 140.000000)'>
              <rect id='Background' x='0' y='0' width='18' height='18' />
              <g id='Group'>
                <circle id='Oval' fill='#A64A93' cx='9' cy='9' r='9' />
                <path
                  d='M12,13 L6.046875,13 L6.046875,11.7109375 L8.7890625,8.828125 C9.46484713,8.0585899
                  9.80273438,7.44726789 9.80273438,6.99414062 C9.80273438,6.62695129 9.72265705,6.34765721
                  9.5625,6.15625 C9.40234295,5.96484279 9.1699234,5.86914062 8.86523438,5.86914062
                  C8.56445162,5.86914062 8.32031344,5.99706903 8.1328125,6.25292969 C7.94531156,6.50879034
                  7.8515625,6.82812309 7.8515625,7.2109375 L5.87109375,7.2109375 C5.87109375,6.68749738
                  6.00195182,6.20410378 6.26367188,5.76074219 C6.52539193,5.3173806 6.88866955,4.97070438
                  7.35351562,4.72070312 C7.8183617,4.47070187 8.33788775,4.34570312 8.91210938,4.34570312
                  C9.83398898,4.34570312 10.5439428,4.55859162 11.0419922,4.984375 C11.5400416,5.41015838
                  11.7890625,6.02148039 11.7890625,6.81835938 C11.7890625,7.15429855 11.7265631,7.48144372
                  11.6015625,7.79980469 C11.4765619,8.11816565 11.2822279,8.45214669 11.0185547,8.80175781
                  C10.7548815,9.15136894 10.3300811,9.6191377 9.74414062,10.2050781 L8.64257812,11.4765625
                  L12,11.4765625 L12,13 Z'
                  id='2'
                  fill='#FFFFFF'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
