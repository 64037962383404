import { Popover } from '@material-ui/core';
import { PopoverOrigin } from '@material-ui/core/Popover';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './index.scss';

import { WorkPackageAction } from '../../actions';
import { WorkPackagesData } from '../../interfaces';
import { PopoverActivityItem } from '../popover-activity-item';


interface DispatchProps {
  selectActivity: (activity: WorkPackagesData.Activity) => void;
}

interface OwnProps {
  activities: WorkPackagesData.Activity[];
  packages: WorkPackagesData.WorkPackage[];
  anchor: HTMLElement;
}

interface Props extends OwnProps, DispatchProps {
}

interface State {
  selectedActivityId: number;
  selectedPackageId: number;
}

const paperProps = {
  classes: {
    root: 'popover-activity__paper',
  },
};

const modalClasses = {
  root: 'popover-activity__modal disable-click',
};

class PopoverActivityComponent extends React.Component<Props, State> {
  private anchorOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'top' };
  private targetOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'top' };

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedActivityId: 0,
      selectedPackageId: 0,
    };
  }

  public render(): JSX.Element {
    const { activities, packages } = this.props;
    const isOpen = activities && activities.length !== 0;

    const pack = packages.filter((p) => p.id === this.state.selectedPackageId);
    let packName = '';
    if (pack && pack.length === 1) {
      packName = pack[0].name;
    }

    const act = activities.filter((a) => a.id === this.state.selectedActivityId);
    let engineIdsText = '';
    if (act && act.length === 1 && act[0].engineids) {
      engineIdsText = `[${act[0].engineids.join(', ')}]`;
    }

    return (
      <Popover
        anchorEl={this.props.anchor}
        anchorOrigin={this.anchorOrigin}
        transformOrigin={this.targetOrigin}
        open={isOpen}
        // useLayerForClickAway={false}
        onClose={this.onRequestClose}
        PaperProps={paperProps}
        hideBackdrop={true}
        ModalClasses={modalClasses}
      >
        <div className='popover-activity'>
          <div className='popover-activity__header'>
            <div className='popover-activity__title'>
              <div className='popover-activity__packname' title={packName}>
                {packName}
              </div>
              <div className='popover-activity__ids'>
                {engineIdsText}
              </div>
            </div>
          </div>

          <div className='popover-activity__activity-list'>
            {activities ?
              activities.map((activity, index): JSX.Element => {
                const isSelected = activity.id === this.state.selectedActivityId;
                return (
                <PopoverActivityItem
                  key={index}
                  item={activity}
                  isSelected={isSelected}
                  onItemClick={this.onItemClick}
                />);
              })
              : null}
          </div>
        </div>
      </Popover>
    );
  }

  @autobind
  private onRequestClose(): void {
    this.setState({
      selectedActivityId: 0,
      selectedPackageId: 0,
    });
  }

  @autobind
  private onItemClick(activity: WorkPackagesData.Activity): void {
    this.setState({
      selectedActivityId: activity.id,
      selectedPackageId: activity.workpackageid,
    });
    this.props.selectActivity(activity);
  }
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    selectActivity: activity => dispatch(WorkPackageAction.selectActivity(activity)),
  };
}

export const PopoverActivity = connect(null, mapDispatchToProps)(PopoverActivityComponent);
