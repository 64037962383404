import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import {
  KreoDialogActions,
  KreoIconAdmin,
  KreoIconBackToApp,
  KreoIconLogOut,
  KreoIconUser,
} from 'common/UIKit';
import { DropMenu, HeaderItem, MenuItem } from '../../../components/drop-menu';
import { AppUrls } from '../../../routes/app-urls';
import { AccountActions } from '../../../units/account/actions/creators';
import { ProfileDialog, ProfileDialogName } from '../../../units/account/components/profile-dialog';
import { SUBSCRIPTION__DIALOG, SubscriptionDialog } from '../../../units/subscription/components/subscription-dialog';


interface ReduxActions {
  openBillingDialog: () => void;
  openProfileDialog: () => void;
  updateUserInfo: () => void;
}

interface Props extends ReduxActions, AbilityAwareProps {
  isAdminPage?: boolean;
  userName: string;
  firstName: string;
  lastName: string;
  logOut: () => void;
}

class ProfileDropMenuComponent extends React.Component<Props> {
  private adminIcon: JSX.Element = <KreoIconAdmin />;
  private backToAppIcon: JSX.Element = <KreoIconBackToApp />;
  private accountIcon: JSX.Element = <KreoIconUser />;
  private signIcon: JSX.Element = <KreoIconLogOut />;

  public render(): JSX.Element {
    return (
      <DropMenu>
        <HeaderItem
          title={`${this.props.firstName} ${this.props.lastName}`}
          subTitle={this.props.userName}
        />
        <MenuItem title='Account' clickEvent={this.onAccountClick} icon={this.accountIcon} />
        {this.props.ability.can(Operation.Manage, Subject.Billing) && (
          <MenuItem
            title='Billing'
            clickEvent={this.props.openBillingDialog}
            icon={this.accountIcon}
          />
        )}
        {this.props.ability.can(Operation.Admin, Subject.Application) ? (
          this.props.isAdminPage ? (
            <MenuItem link={AppUrls.products.url()} title='Back to app' icon={this.backToAppIcon} />
          ) : (
            <MenuItem link={AppUrls.admin.index.url()} title='Administration' icon={this.adminIcon} />
          )
        ) : null}
        <MenuItem
          title='Log Out'
          clickEvent={this.props.logOut}
          icon={this.signIcon}
        />
        <ProfileDialog onSubmit={this.onProfileSave} />
        <SubscriptionDialog />
      </DropMenu>
    );
  }

  @autobind
  private onAccountClick(): void {
    this.props.openProfileDialog();
  }

  @autobind
  private onProfileSave(): void {
    this.props.updateUserInfo();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    openBillingDialog: () => dispatch(KreoDialogActions.openDialog(SUBSCRIPTION__DIALOG)),
    openProfileDialog: () => dispatch(KreoDialogActions.openDialog(ProfileDialogName)),
    updateUserInfo: () => dispatch(AccountActions.setUserInfo()),
  };
};

const connector = connect(null, mapDispatchToProps);
export const ProfileDropMenu = connector(withAbilityContext(ProfileDropMenuComponent));
