import * as React from 'react';

import { RenderIf } from 'common/components/render-if';
import { ColorCategory } from './color-category';
import { MeasureCategory } from './measure-category/measure-category';
import { Styled } from './styled';
import { useAggregate } from './use-aggregate';

const InstancesSelectionComponent: React.FC = () => {
  const {
    color,
    height,
    thickness,
    offsets,
  } = useAggregate();
  return (
    <Styled.Container>
      <RenderIf condition={Object.keys(color).length > 0}>
        <ColorCategory colorInstances={color} />
      </RenderIf>
      <RenderIf condition={Object.keys(height).length > 0}>
        <MeasureCategory measureInstances={height} title='height'/>
      </RenderIf>
      <RenderIf condition={Object.keys(thickness).length > 0}>
        <MeasureCategory measureInstances={thickness} title='thickness'/>
      </RenderIf>
      <RenderIf condition={Object.keys(offsets).length > 0}>
        <MeasureCategory measureInstances={offsets} title='offset'/>
      </RenderIf>
    </Styled.Container>
  );
};

export const InstancesAggregation = React.memo(InstancesSelectionComponent);
