import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from '../../common/interfaces/reducer-methods';
import { MonoliteHelper } from '../../common/monolite';
import { ActivityActionTypes, ActivityPayloads } from './actions';
import { USER_ACTIVITIES_INITIAL_STATE } from './initial-state';
import { Sorting, TimeInterval, UserActivity } from './interfaces';
import { UserActivitiesState } from './interfaces/redux-state';
import { UserActivityUtil } from './user-activity-util';

export const userActivitiesReducerMethods: ReducerMethods<UserActivitiesState> = {
  [ActivityActionTypes.LOAD_REQUEST_BY_COMPANY_SUBSCRIPTION]: state => {
    return setInitialLoad(state);
  },
  [ActivityActionTypes.LOAD_REQUEST_BY_DOCUMENT]: state => {
    return setInitialLoad(state);
  },
  [ActivityActionTypes.LOAD_SUCCEEDED]: (state, payload: UserActivity[]) => {
    return new MonoliteHelper(state)
      .set(
        _ => _.activities,
        UserActivityUtil.sortActivities(UserActivityUtil.groupActivities(payload), state.sorting),
      )
      .set(_ => _.statuses.activities, RequestStatus.Loaded)
      .get();
  },
  [ActivityActionTypes.LOAD_FAIL]: state => {
    return new MonoliteHelper(state).set(_ => _.statuses.activities, RequestStatus.Failed).get();
  },
  [ActivityActionTypes.DELETE_FILTER_ITEM]: (state, payload: ActivityPayloads.DeleteFilterItem) => {
    const helper = new MonoliteHelper(state);
    if (payload.optionId != null) {
      helper.setFilter(_ => _.filter[payload.type], x => x.id !== payload.optionId);
      return setFilteredActivities(helper);
    }
    return helper.get();
  },
  [ActivityActionTypes.ADD_FILTER_ITEM]: (state, payload: ActivityPayloads.AddFilterItem) => {
    const helper = new MonoliteHelper(state);
    if (payload.option.id === null) {
      // set default
      helper.set(_ => _.filter[payload.type], null);
    } else {
      if (state.filter[payload.type]) {
        helper.setAppend(_ => _.filter[payload.type], payload.option);
      } else {
        helper.set(_ => _.filter[payload.type], [payload.option]);
      }
    }
    return setFilteredActivities(helper);
  },
  [ActivityActionTypes.SET_TIME_INTERVAL]: (state, payload: TimeInterval) => {
    const helper = new MonoliteHelper(state)
      .set(_ => _.timeInterval, null)
      .set(_ => _.timeInterval, payload);
    return setFilteredActivities(helper);
  },
  [ActivityActionTypes.CLEAR_TIME_INTERVAL]: state => {
    const helper = new MonoliteHelper(state)
      .set(_ => _.timeInterval.start, null)
      .set(_ => _.timeInterval.end, null);
    return setFilteredActivities(helper);
  },
  [ActivityActionTypes.SET_SORTING]: (state, payload: Sorting) => {
    const helper = new MonoliteHelper(state)
      .set(_ => _.sorting, payload)
      .set(_ => _.activities, UserActivityUtil.sortActivities([...state.activities], payload));
    return setFilteredActivities(helper);
  },
};

function setFilteredActivities(
  stateUpdater: MonoliteHelper<UserActivitiesState>,
): UserActivitiesState {
  const state = stateUpdater.get();
  return stateUpdater
    .set(
      _ => _.filteredActivities,
      UserActivityUtil.filterActivities(state.filter, state.timeInterval, state.activities),
    )
    .get();
}

function setInitialLoad(state: UserActivitiesState): UserActivitiesState {
  return new MonoliteHelper(state)
    .set(_ => _.activities, [])
    .set(_ => _.statuses.activities, RequestStatus.Loading)
    .set(_ => _.filteredActivities, [])
    .set(_ => _.filter, USER_ACTIVITIES_INITIAL_STATE.filter)
    .set(_ => _.timeInterval, USER_ACTIVITIES_INITIAL_STATE.timeInterval)
    .set(_ => _.sorting, USER_ACTIVITIES_INITIAL_STATE.sorting)
    .get();
}
