import { Text, ModalWrapper, LinkComponent, RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ControlNames } from 'common/constants/control-names';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { usePaymentPortalWithReload } from '../../payment-method-manager/payment-manager/use-payment-portal';
import { InfoBlock } from './info-block';
import { Styled } from './styled';

export const PAYMENT_METHOD_NOT_ADDED_DIALOG = 'PAYMENT_METHOD_NOT_ADDED_DIALOG';

interface PaymentMethod {
  img: string;
  description: string;
}

const PAYMENT_METHODS: PaymentMethod[] = [
  {
    img: 'free',
    description: 'Free trial, no card charge until trial ends',
  },
  {
    img: 'bell',
    description: 'You get an email reminder 2 days before your trial ends',
  },
  {
    img: 'garantee',
    description: '7-day money-back guarantee if you forgot to cancel the trial',
  },
];

interface DispatchProps {
  closeDialog: () => void;
}

interface Props extends DispatchProps { }


const  PaymentMethodNotAddedDialogComponent: React.FC<Props> = ({ closeDialog }: Props) => {
  const openPaymentPortal = usePaymentPortalWithReload();
  const addPaymentAndClose = useCallback(() => {
    openPaymentPortal();
    closeDialog();
  }, [openPaymentPortal, closeDialog]);
  return (
    <DialogWrapper name={PAYMENT_METHOD_NOT_ADDED_DIALOG}>
      <ModalWrapper onExit={closeDialog}>
        <Styled.Wrapper>
          <Styled.Container>
            <Styled.Header>
              <Text fontSize={18}>Oops! Payment method is not added</Text>
            </Styled.Header>
            <Styled.InfoContainer>
              {PAYMENT_METHODS.map((el, index) => {
                return (
                  <InfoBlock
                    key={index}
                    img={el.img}
                    description={el.description}
                  />
                );
              })}
            </Styled.InfoContainer>
            <Styled.AddButtonContainer controlName={ControlNames.addCardButton}>
              <RectangleButton
                size='m'
                width={290}
                mood={'positive'}
                onClick={addPaymentAndClose}
                text={'Sure, let me add it'}
              />
            </Styled.AddButtonContainer>
            <Styled.ExitButtonContainer controlName={ControlNames.notAddCardButton}>
              <LinkComponent
                size='m'
                onClick={closeDialog}
                text={'I don’t want to use my card for a trial'}
              />
            </Styled.ExitButtonContainer>
          </Styled.Container>
        </Styled.Wrapper>
      </ModalWrapper>
    </DialogWrapper>
  );
};


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(PAYMENT_METHOD_NOT_ADDED_DIALOG)),
  };
}

const connector = connect(null, mapDispatchToProps);
export const PaymentMethodNotAddedDialog = connector(PaymentMethodNotAddedDialogComponent);
