import * as monolite from 'monolite';
import { AnyAction } from 'redux';

import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { objectUtils } from 'common/utils/object-utils';
import { GanttChartActionTypes } from '../../actions/types/gantt-chart';
import {
  ActivityData,
  ActivityDataLookup,
  AnimationDataResponse,
  GanttData,
  ResourcesData,
} from '../../interfaces/gantt-chart';
import { animationDataResponseMapper, GroupType, ResourceType } from '../../utils/gantt-chart';
import { ganttPageReducers } from '../gantt-chart/reducer-methods';
import {
  payloads,
  types as actionTypes,
} from './actions';
import {
  resourceSelectorReducerMethods,
} from './components/resource-selector/reducer-methods';
import { ActivitiesTabMode } from './enums/activities-tab-mode';
import { ResourcesTabMode } from './enums/resources-tab-mode';
import { SidePanelTab } from './enums/side-panel-tab';
import { FourDVisualisationState } from './interfaces/four-d-visualisation-state';
import { ResourceIdentifier } from './interfaces/resource-identifier';

const initialState: FourDVisualisationState = {
  animationData: null,
  ganttData: null,
  projectStartDate: null,
  selectedPackages: {},
  sidePanel: {
    activeTab: SidePanelTab.Resources,
    activitiesTab: {
      activeMode: ActivitiesTabMode.Current,
    },
    resourcesTab: {
      activeMode: ResourcesTabMode.All,
      charts: {},
      resourceSelector: {
        isOpen: false,
        chartId: null,
        clientX: 0,
        clientY: 0,
        selectedTab: ResourceType.Labour,
      },
    },
  },
  activitiesData: {},
  selectedElementsIds: [],
  resources: {},
  timelineChart: {
    defaultResource: {
      type: ResourceType.Labour,
      id: -7,
    },
    selectedResource: null,
    previewedResource: null,
  },
  calculationId: 0,
};

const methods: ReducerMethods<FourDVisualisationState> = {
  [GanttChartActionTypes.ANIMATION_DATA_REQUEST_STARTED]: (
    state,
    payload: number,
  ) => monolite.set(state, (s) => s.calculationId)(payload),
  [GanttChartActionTypes.ANIMATION_DATA_REQUEST_SUCCEEDED]: (
    state,
    payload: AnimationDataResponse,
  ) => {
    if (process.env.NODE_ENV === 'development') {
      objectUtils.deepFreeze(payload);
    }

    // todo: change how date is serialized on backend
    const projectStartDate = new Date(parseInt(payload.startDatetime.substr(6), 10));
    const activitiesDataLookup = animationDataResponseMapper.getActivityDataLookup(payload);

    let newState = monolite.set(state, (s) => s.projectStartDate)(projectStartDate);
    newState = monolite.set(newState, (s) => s.activitiesData)(activitiesDataLookup);
    return monolite.set(newState, (s) => s.animationData)(payload);
  },
  [actionTypes.TOGGLE_CHART_LINE_EXPAND_STATUS]: (
    state,
    payload: payloads.ToggleRowExpandStatusPayload,
  ) => {
    switch (payload.type) {
      case GroupType.WorkPackage:
        const workPackageIndex = state.ganttData.workPackages.findIndex((wp) => wp.entityId === payload.rowId);
        return monolite.set(
          state,
          (s) => s.ganttData.workPackages[workPackageIndex].isExpanded)((prevValue) => !prevValue);
      default:
        return state;
    }
  },
  [GanttChartActionTypes.SAVE_GANTT_DATA]: (
    state,
    payload: GanttData,
  ) => {
    return monolite.set(state, (s) => s.ganttData)(payload);
  },
  [actionTypes.SET_SIDE_PANEL_TAB]: (
    state,
    payload: SidePanelTab,
  ) => {
    return monolite.set(state, (s) => s.sidePanel.activeTab)(payload);
  },
  [actionTypes.SET_ACTIVITIES_TAB_MODE]: (
    state,
    payload: ActivitiesTabMode,
  ) => {
    return monolite.set(state, (s) => s.sidePanel.activitiesTab.activeMode)(payload);
  },
  [actionTypes.SET_SELECTED_ELEMENTS_IDS]: (
    state,
    payload: number[],
  ) => {
    return monolite.set(state, (s) => s.selectedElementsIds)(payload);
  },
  [actionTypes.TOGGLE_ACTIVITY_CARD_EXPAND_STATUS]: (
    state,
    activityId: number,
  ) => {
    return monolite.set(state, (s) => s.activitiesData[activityId].expanded)((isExpanded) => !isExpanded);
  },
  [actionTypes.SET_RESOURCES_TAB_MODE]: (
    state,
    mode: ResourcesTabMode,
  ) => {
    return monolite.set(state, (s) => s.sidePanel.resourcesTab.activeMode)(mode);
  },
  [GanttChartActionTypes.SAVE_RESOURCES_DATA]: (
    state,
    data: ResourcesData,
  ) => {
    return monolite.set(state, (s) => s.resources)(data);
  },
  [GanttChartActionTypes.RESET_STATE]: () => {
    return initialState;
  },
  [GanttChartActionTypes.RESET_ACTIVE_TAB]: (state) => {
    return monolite.set(state, (s) => s.sidePanel.activeTab)(initialState.sidePanel.activeTab);
  },
  [actionTypes.SELECT_RESOURCE_FOR_TIMELINE]: (state, payload: ResourceIdentifier) => {
    return monolite.set(state, (s) => s.timelineChart.selectedResource)(payload);
  },
  [actionTypes.DESELECT_RESOURCE_FOR_TIMELINE]: (state) => {
    return monolite.set(state, (s) => s.timelineChart.selectedResource)(null);
  },
  [actionTypes.EXPAND_ALL_ACTIVITY_CARDS]: (state) => {
    return monolite.set(state, (s) => s.activitiesData)((activitiesData) => {
      const newActivitiesData: ActivityDataLookup = {};
      const activitiesArray: ActivityData[] = Object.values(activitiesData);
      for (const activity of activitiesArray) {
        newActivitiesData[activity.id] = {
          ...activity,
          expanded: true,
        };
      }

      return newActivitiesData;
    });
  },
  [actionTypes.COLLAPSE_ALL_ACTIVITY_CARDS]: (state) => {
    return monolite.set(state, (s) => s.activitiesData)((activitiesData) => {
      const newActivitiesData: ActivityDataLookup = {};
      const activitiesArray: ActivityData[] = Object.values(activitiesData);
      for (const activity of activitiesArray) {
        newActivitiesData[activity.id] = {
          ...activity,
          expanded: false,
        };
      }

      return newActivitiesData;
    });
  },
  ...resourceSelectorReducerMethods,
};

export const fourDVisualisation = (
  state: FourDVisualisationState = initialState,
  action: AnyAction,
): FourDVisualisationState => {
  const handler = methods[action.type];
  const payload = action.payload;
  if (handler) {
    return handler(state, payload);
  }
  if (ganttPageReducers[action.type]) {
    return ganttPageReducers[action.type](state, action.payload);
  }

  return state;
};
