import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { CostEstimateProjectRevisionRouteParams } from 'routes/app-routes-params';
import { MeasurementsEngineLayout, MeasurementsExtractorLayout } from 'unit-cost-estimate/components';

type Props = RouteComponentProps<CostEstimateProjectRevisionRouteParams>;

export class MeasurementsViewPage extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <MeasurementsEngineLayout
        projectId={this.props.match.params.projectId}
      >
        <MeasurementsExtractorLayout disabled={true} />
      </MeasurementsEngineLayout>
    );
  }
}
