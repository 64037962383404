import * as React from 'react';
import { useSelector } from 'react-redux';

import { useEditStyle } from 'common/components/drawings/hooks';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';


export interface ElementColorEditorProps {
  editColor: (instancesIds: string[], color: string) => void;
}


export const withElementColorEditor = (Component: any) => {
  return (props: any) => {
    const aiAnnotation = useSelector<State, DrawingsGeometryState>((state) => state.drawings.aiAnnotation);
    const editStyle = useEditStyle('color', aiAnnotation.geometry);
    const editColor = React.useCallback((instancesIds: string[], color: string) => {
      editStyle(instancesIds, color);
    }, [editStyle]);

    return <Component editColor={editColor} {...props} />;
  };
};
