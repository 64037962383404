import * as React from 'react';

import { ButtonInput } from 'common/components/inputs/small-input';
import { UuidUtil } from 'common/utils/uuid-utils';
import { FormField } from '../form-field';
import { useEditContext } from '../use-edit-context';

export const FormButtonField = (props: any): any => {
  const {
    label,
    input,
    dropDownProps,
    onDeleteClick,
  } = props;
  const inputId = React.useMemo(() => UuidUtil.generateUuid(), []);
  const { startEditing, editIcon } = useEditContext(onDeleteClick);

  return (
    <FormField
      label={label}
      dropDownProps={dropDownProps}
      inputId={inputId}
      startEditing={startEditing}
      editIcon={editIcon}
    >
      <ButtonInput
        value={input.value}
        onClick={input.onClick}
        id={inputId}
      />
    </FormField>
  );
};
