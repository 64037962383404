import * as t from 'io-ts';

const TableSettingsC = t.type(
  {
    columnKeys: t.array(t.string),
    showColumnHeaders: t.boolean,
    showTotal: t.boolean,
  },
  'TableSettings');

const TablePresetC = t.type(
  {
    id: t.string,
    name: t.string,
    settings: TableSettingsC,
  },
  'TablePreset');

export const TablePresetsC = t.array(TablePresetC);

export type TablePreset = t.TypeOf<typeof TablePresetC>;
export type TableSettings = t.TypeOf<typeof TableSettingsC>;
