import * as t from 'io-ts';

import { IsoDateStringC } from 'common/codecs/iso-date-string';
import { FeedC } from 'common/interfaces/feed';

export const AttributeOptionC = t.strict(
  {
    value: t.string,
    name: t.string,
    key: t.string,
  },
  'AttributeOption');

export type AttributeOption = t.TypeOf<typeof AttributeOptionC>;

export const ShortCompanyC = t.strict(
  {
    id: t.number,
    name: t.string,
    subscriptions: t.array(t.string),
  },
  'ShortCompany');

export type ShortCompany = t.TypeOf<typeof ShortCompanyC>;


export const UserC = t.intersection(
  [
    t.type({
      guid: t.string,
      email: t.string,
      firstName: t.string,
      lastName: t.string,
      enabled: t.boolean,
      created: IsoDateStringC,
      attributes: t.array(AttributeOptionC),
    }),
    t.partial({
      avatar: t.number,
      companies: t.array(ShortCompanyC),
      ownCompanyId: t.number,
      hasAvatar: t.boolean,
    }),
  ],
  'User');

export const UserFeedC = FeedC(UserC, 'UserFeed');
export type User = t.TypeOf<typeof UserC>;
