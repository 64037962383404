import { AppRoute as R } from 'common/routing/app-route';
import { ParametrizedAppRoute as PR } from 'common/routing/parametrized-app-route';
import * as Activity from '../common/activity/urls-fabric';
import * as People from '../common/people/urls-fabric';
import * as Subscription from '../common/subscription-routes-wrap/urls-fabric';
import {
  Qto3dProjectRouteParams as ProjectRP,
} from './route-params';


/* eslint-disable max-len, space-in-parens */
const index =                          new R(                     '/3d');
const listing =                        new R(                     `${index.path}/projects`);
const projectIndex =                   new PR<ProjectRP>(         `${index.path}/project/:projectId`);
const quantityTakeOff =                new PR<ProjectRP>(         `${projectIndex.path}/quantity-take-off`);
const modelManagement =                new PR<ProjectRP>(         `${projectIndex.path}/model-management`);
const modelCheck =                     new PR<ProjectRP>(         `${projectIndex.path}/model-check`);
const modelCheckView =                 new PR<ProjectRP>(         `${modelCheck.path}/view`);


export const Urls = {
  index,
  listing,
  project: {
    index: projectIndex,
    quantityTakeOff,
    modelManagement,
    modelCheck: {
      index: modelCheck,
      view: modelCheckView,
    },
  },
  people: People.UrlsFabric(index.path),
  activity: Activity.UrlsFabric(index.path),
  billing: Subscription.UrlsFabric(index.path),
};
