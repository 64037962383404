import React, { useEffect } from 'react';

import { UndoHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { GlobalKeyboardEventsController } from 'common/components/global-keyboard-events-controller';
import { GlobalKeyboardEventsControllerContext } from 'common/components/global-keyboard-events-controller/context';
import { UndoRedoListenersContext } from './undo-redo-context';


const UndoRedoListenerContainer: React.FC = ({ children }) => {
  const { redoListener, undoListener } = React.useContext(UndoRedoListenersContext);
  const {
    addKeyDownEventListener,
  } = React.useContext(GlobalKeyboardEventsControllerContext);

  useEffect(() => {
    addKeyDownEventListener(UndoHotkey.Redo, redoListener);
    addKeyDownEventListener(UndoHotkey.Undo, undoListener);
  }, []);
  return <>{children}</>;
};

export const UndoRedoListenerWrapper: React.FC = ({ children }) => {
  return (
    <GlobalKeyboardEventsController>
      <UndoRedoListenerContainer>{children}</UndoRedoListenerContainer>
    </GlobalKeyboardEventsController>
  );
};
