import { isEqual, isObject } from 'lodash';
import { StringDictionary } from 'common/interfaces/dictionary';

type DiffResult = StringDictionary<{ previous: any, current: any }>;
type ApplyFieldChangesFunctionType = (field: string, value: any) => void;

export class ChangeFormHelper {
  public static applyChanges(prevForm: any, currentForm: any, applyFormChange: ApplyFieldChangesFunctionType): void {
    const diff = this.getDiff(prevForm, currentForm);
    if (diff) {
      for (const field in diff) {
        const newValue = diff[field].current;
        applyFormChange(field, newValue !== undefined ? newValue : undefined);
      }
    }
  }

  public static getDiff(prevForm: any, currentForm: any): DiffResult {
    const getAllKeys = (o1: object, o2: object): string[] => {
      return Object.keys({ ...o1, ...o2 });
    };

    const getPropertyDiff = (previous: any, current: any, baseKey: string): DiffResult => {
      if (isEqual(previous, current)) {
        return null;
      }

      if (!(isObject(previous) && isObject(current))) {
        return { [baseKey || '']: { previous, current } };
      }

      let diffResult = {};
      const keys = getAllKeys(previous, current);
      for (const key of keys) {
        if (isEqual(previous[key], current[key])) {
          continue;
        }
        const propertyKey = baseKey ? `${baseKey}.${key}` : key;
        diffResult = {
          ...diffResult,
          ...getPropertyDiff(previous[key], current[key], propertyKey),
        };
      }
      return diffResult;
    };

    return getPropertyDiff(prevForm, currentForm, '');
  }
}
