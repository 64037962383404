import styled from 'styled-components';

import { KreoColors } from 'common/enums/kreo-colors';

const Container = styled.div`
  display: flex;
  align-content: space-between;
  flex-flow: row wrap;
  align-items: flex-start;
  height: 80%;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 220px;
  min-height: 16px;
  box-sizing: border-box;
  margin: 0 10px 0 15px;
  text-align: justify;

  p {
    font-size: 14px;
    letter-spacing: 0px;
    word-wrap: break-word;
  }
`;

const Marker = styled.div`
  width: 20px;
  height: 20px;

  svg {
    fill: ${KreoColors.nightSecondary};
  }
`;

export const Styled = {
  Container,
  Wrapper,
  List,
  Marker,
};
