import styled, { css } from 'styled-components';
import { Text } from '../text';

export const LINK_COLORS = {
  primary: 'orange',
  secondary: 'turquoise',
  positive: 'green',
  negative: 'red',
  disabled: 'disabled',
  default: 'gray',
  white: 'white',
};

export interface ContainerProps {
  mood?: keyof typeof LINK_COLORS;
  size?: 's' | 'm' | 'l';
  rightIcon?: boolean;
  text?: boolean;
  width?: number;
  textDefault?: boolean;
}


const Container = css<ContainerProps>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: ${(p) => (p.mood === 'disabled' ? 'none' : 'auto')};
  width: ${(p) => (p.width ? `${p.width}px` : 'fit-content')};
  transition: all ${(p) => p.theme.duration.s} ease-in-out;
  text-decoration: none;

  svg {
    width: ${(p) => (p.size === 's' ? 10 : 20)}px;
    height: ${(p) => (p.size === 's' ? 10 : 20)}px;
    margin-right: ${(p) => p.rightIcon || !p.text ? 0 : 10}px;
    margin-left: ${(p) => p.rightIcon && p.text ? 10 : 0}px;
    margin-top: 0px;
    margin-bottom: 0px;
    fill: ${(p) => p.theme.color[LINK_COLORS[p.mood]] || p.theme.color.gray};
  }

  &:hover {
    transform: translateY(-1px);

    svg {
      height: ${(p) => (p.size === 's' ? '10px' : '20px')};
      width: ${(p) => (p.size === 's' ? '10px' : '20px')};
      fill: ${(p) => p.theme.color[LINK_COLORS[p.mood]] || p.theme.color.turquoise};
    }

    ${Text} {
      color: ${(p) => p.theme.color[LINK_COLORS[p.mood]] || p.theme.color.turquoise};
    }
  }

  &:active {
    transform: translateY(2px);

    svg {
      fill: ${(p) => p.theme.color[LINK_COLORS[p.mood]] || p.theme.color.turquoise};
    }

    ${Text} {
      color: ${(p) => p.theme.color[LINK_COLORS[p.mood]] || p.theme.color.turquoise};
    }
  }
`;

const DivContainer = styled.div`
  ${Container};
`;

const AContainer = styled.a`
  ${Container};
`;

export const Styled = {
  DivContainer,
  AContainer,
};


