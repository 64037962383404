import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const Styled = {
  Container,
};
