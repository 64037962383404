import { AnyAction } from 'redux';

import { DASHBOARD_INITIAL_STATE } from '../units/projects/constants/dashboard-initial-state';
import { DashboardReduxState } from '../units/projects/interfaces/dashboard/dashboard-redux-state';
import { DashboardReducerMethods } from '../units/projects/reducers/dashboard-reducer-methods';

export function dashboard(
  state: DashboardReduxState = DASHBOARD_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): DashboardReduxState {
  if (action.type in DashboardReducerMethods) {
    return DashboardReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
