import { H5 } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${p => p.theme.color.background};
  box-sizing: border-box;

  a {
    text-decoration: none;
    width: 100%;
  }

  button {
    width: 100%;
  }

  ${H5} {
    font-size: 21px;
  }
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  height: 41px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    fill: ${props => props.theme.color.green};
  }
`;

const Description = styled.div`
  width: 180px;
  margin-top: 20px;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.color.mainFont};
`;

export const Styled = {
  Container,
  Contact,
  Description,
};
