import * as paper from 'paper';

import {
  DrawingsCanvasColors,
  DrawingsCanvasConstants,
} from 'common/components/drawings/constants/drawing-canvas-constants';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import {
  DrawingsAllowedPathType,
  DrawingsPaperPolygonPath,
  DrawingsSimplifiedBoundingRect,
} from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { DrawingsGeometryPreviewConfig } from './drawings-geometry-preview-config';

export interface DrawingsGeometryPreviewPolygonConfig extends DrawingsGeometryPreviewConfig {
  geometry: { points: string[], children?: string[][] };
  color: DrawingsPaperColorInfo;
}

export class DrawingsGeometryPreviewPolygon {
  protected _config: DrawingsGeometryPreviewPolygonConfig;
  protected _path: DrawingsAllowedPathType;

  constructor(config: DrawingsGeometryPreviewPolygonConfig) {
    this._config = config;
    this.render();
  }

  public set hasError(value: boolean) {
    if (value) {
      this._path.strokeColor = DrawingsCanvasColors.warningStrokeColor;
      this._path.fillColor = DrawingsCanvasColors.warningFillColor;
    } else {
      this._path.strokeColor = this._config.color.stroke;
      this._path.fillColor = this._config.color.fill;
    }
  }

  public set zoom(value: number) {
    this._path.strokeWidth = DrawingsCanvasConstants.previewStrokeWidth / value;
    this._path.dashArray = DrawingsCanvasConstants.previewDashArray.map(x => x * this._path.strokeWidth);
  }

  public get bounds(): DrawingsSimplifiedBoundingRect {
    return this._path.bounds;
  }

  private render(): void {
    let path: DrawingsAllowedPathType
      = DrawingsPaperUtils.simplePolygonToPath(this._config.geometry.points, this._config.getPoint);
    if (!path.clockwise) {
      path.reverse();
    }
    if (this._config.geometry.children) {
      path = new paper.CompoundPath({ children: [path] }) as DrawingsPaperPolygonPath;
      for (const childContour of this._config.geometry.children) {
        const childPolygon = DrawingsPaperUtils.simplePolygonToPath(childContour, this._config.getPoint);
        childPolygon.add(childPolygon.firstSegment);
        if (childPolygon.clockwise) {
          childPolygon.reverse();
        }
        path.addChild(childPolygon);
      }
    }
    this._path = path;
    this._path.strokeColor = this._config.color.stroke;
    this._path.fillColor = this._config.color.fill;
    this._path.addTo(this._config.group);
  }
}
