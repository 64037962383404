const prefix = `@people`;

export const PeopleActionTypes = {
  COMPANY_PEOPLE_GET_SUCCEEDED: `${prefix}/COMPANY_PEOPLE_GET_SUCCEEDED`,
  COMPANY_PEOPLE_GET_REQUEST: `${prefix}/COMPANY_PEOPLE_GET_REQUEST`,
  SET_FILTER: `${prefix}/SET_FILTER`,
  INVITE_TO_COMPANY: `${prefix}/INVITE_TO_COMPANY`,
  INVITE_TO_COMPANY_REQUEST: `${prefix}/INVITE_TO_COMPANY_REQUEST`,
  INVITE_TO_PROJECT_REQUEST: `${prefix}/INVITE_TO_PROJECT_REQUEST`,
  INVITE_CONFIRM_INVITE_TO_WORK_PACKAGE: `${prefix}/INVITE_CONFIRM_INVITE_TO_WORK_PACKAGE`,
  DROP_INVITE_INFO: `${prefix}/DROP_INVITE_INFO`,
  SAVE_USER_MAP: `${prefix}/SAVE_USER_MAP`,
  ADD_TO_USER_MAP: `${prefix}/ADD_TO_USER_MAP`,
  DELETE_COMPANY_PERSON_REQUEST: `${prefix}/DELETE_COMPANY_PERSON_REQUEST`,
  REJECT_INVITATION: `${prefix}/REJECT_INVITATION`,
  REJECT_INVITATION_SUCCEEDED: `${prefix}/REJECT_INVITATION_SUCCEEDED`,
  REMOVE_FROM_PROJECT: `${prefix}/REMOVE_FROM_PROJECT`,
  REMOVE_FROM_PROJECT_SUCCESS: `${prefix}/REMOVE_FROM_PROJECT_SUCCESS`,
  FETCH_COMPANY_ROLES: `${prefix}/FETCH_COMPANY_ROLES`,
  FETCH_COMPANY_ROLES_SUCCEEDED: `${prefix}/FETCH_COMPANY_ROLES_SUCCEEDED`,
  CHANGE_DISPLAYED_ROLE_GROUP: `${prefix}/CHANGE_DISPLAYED_ROLE_GROUP`,
  RESEND_INVITATION_REQUEST: `${prefix}/RESEND_INVITATION_REQUEST`,
  RESEND_INVITATION_SUCCEEDED: `${prefix}/RESEND_INVITATION_SUCCEEDED`,
  UPDATE_USER_ROLE_REQUEST: `${prefix}/UPDATE_USER_ROLE_REQUEST`,
  UPDATE_USER_ROLE_SUCCEEDED: `${prefix}/UPDATE_USER_ROLE_SUCCEEDED`,
  UPDATE_INVITED_PEOPLES: `${prefix}/UPDATE_INVITED_PEOPLES`,
  UPDATE_SUBSCRIPTION_USERS: `${prefix}/UPDATE_SUBSCRIPTION_USERS`,
  DROP_PEOPLE_STATE: `${prefix}/DROP_PEOPLE_STATE`,
  RESEND_INVITATION: `${prefix}/RESEND_INVITATION`,
};
