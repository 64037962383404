import * as React from 'react';

import { ConfirmationDialog } from '../confirmation-dialog';


export const REJECT_COMPANY_NAME_DIALOG = 'REJECT_COMPANY_NAME_DIALOG';
const TITLE = 'Invalid Company Name';
const TEXT = `The company name contains invalid characters or exceeds the allowed number of characters
  (more than 150 symbols). Please ensure that the company name consists only of permitted characters and
  does not exceed the 150-character limit.`;

export const RejectCompanyNameDialog: React.FC = () => {
  return (
    <ConfirmationDialog
      name={REJECT_COMPANY_NAME_DIALOG}
      title={TITLE}
      text={TEXT}
      cancelButtonText='Okay'
      zIndex={99999}
    />
  );
};
