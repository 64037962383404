import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './activity-assignment-activity-tile.scss';

import { AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';
import {
  MaterialMenuItem,
  MaterialMenuItemProps,
  MaterialSelect,
} from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { CEActivityAssignmentActions } from 'unit-cost-estimate/actions/creators';
import {
  CEActivityAssignmentDataMaterial,
  CEActivityAssignmentDataTreeNode,
  CEActivityAssignmentDataVariant,
  CEActivityAssignmentDiffType,
} from 'unit-cost-estimate/interfaces';
import { CEActivityAssignmentUtils } from 'unit-cost-estimate/utils/ce-activity-assignment-utils';
import {
  ActivityAssignmentActivityTileBody,
  ActivityAssignmentActivityTileBodyTitle,
  ActivityAssignmentActivityTileContainer,
} from 'unit-projects/components/activity-assignment-activity-tile-containers';
import { ActivityAssignmentSelectMaterialVariant } from './activity-assignment-select-material-variant';


interface OwnProps {
  index: number;
}

interface StateProps {
  selectedWork: CEActivityAssignmentDataTreeNode;
  activityVariantId: number;
  diffType: number;
}

interface ActivityAssignmentMaterialWithId extends CEActivityAssignmentDataMaterial {
  id: number;
}

interface DispatchProps {
  selectActivity: (variantId: number) => void;
  selectMaterial: (materialId: number, materialVariantId: number) => void;
}

interface Props extends StateProps, DispatchProps, OwnProps {
}


class ActivityAssignmnentActivityTileComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { selectedWork, activityVariantId, diffType } = this.props;
    const { materials, variants } = selectedWork;
    const selectedVariant = variants[activityVariantId];
    const renderMaterials = materials && selectedVariant
      && selectedVariant.materials.map(id => ({ ...materials[id], id }));
    const { primaryMaterial, secondaryMaterial } = selectedVariant || {};
    const variantClassName = selectedWork.selectedVariant.manualId
      ? 'activity-assignment-activity-tile--manually-changed'
      : null;

    return (
      <>
        <ActivityAssignmentActivityTileContainer enabled={true}>
          <ActivityAssignmentActivityTileBody>
            <div className='activity-assignment-activity-tile__variant'>
              <ActivityAssignmentActivityTileBodyTitle>
                  Activity Variant
              </ActivityAssignmentActivityTileBodyTitle>
              <MaterialSelect
                displayedType={MaterialComponentType.Native}
                dropdownClassName='activity-assignment-activity-tile__dropdown'
                value={activityVariantId}
                onChange={this.onChangeActivity}
                className={variantClassName}
              >
                {Object.entries(variants).map(([id, variant]) => this.renderVariant(id, variant))}
              </MaterialSelect>
            </div>
            <div className='activity-assignment-activity-tile__material'>
              {this.renderRelatedMaterials(primaryMaterial, secondaryMaterial, materials)}
              {
                renderMaterials && !!renderMaterials.length && (
                  <>
                    <ActivityAssignmentActivityTileBodyTitle>
                      Materials
                    </ActivityAssignmentActivityTileBodyTitle>
                    {renderMaterials.map(this.renderMaterialSelect)}
                  </>)
              }
            </div>
          </ActivityAssignmentActivityTileBody>
        </ActivityAssignmentActivityTileContainer>
        {this.renderDiffType(diffType)}
      </>
    );
  }

  @autobind
  private onChangeActivity(_e: React.SyntheticEvent<Element>, value: number): void {
    this.props.selectActivity(value);
  }

  @autobind
  private renderDiffType(diffType: number): React.ReactNode {
    if (diffType === 0) {
      return null;
    }
    const diffTypesArray = this.getDiffTypes(diffType);
    return (
      <ActivityAssignmentActivityTileContainer enabled={true}>
        <ActivityAssignmentActivityTileBody>
        <div className='activity-assignment-activity-tile__diff'>
          <ActivityAssignmentActivityTileBodyTitle>
            Difference between variants from previous and current calculation
          </ActivityAssignmentActivityTileBodyTitle>
          <ul>
            {diffTypesArray.map(x => <li key={x}>{x}</li>)}
          </ul>
        </div>
        </ActivityAssignmentActivityTileBody>
      </ActivityAssignmentActivityTileContainer>
    );
  }

  @autobind
  private isContainInDiffType(diffType: number, type: CEActivityAssignmentDiffType): boolean {
    /* eslint-disable no-bitwise */
    return !!(diffType & type);
    /* eslint-enable */
  }

  @autobind
  private getDiffTypes(diffType: number): string[] {
    const result = [];
    if (this.isContainInDiffType(diffType, CEActivityAssignmentDiffType.ActivityNames)) {
      result.push('Name');
    }
    if (this.isContainInDiffType(diffType, CEActivityAssignmentDiffType.AmountOfWork)) {
      result.push('Amount of Work');
    }
    if (this.isContainInDiffType(diffType, CEActivityAssignmentDiffType.Resources)) {
      result.push('Resources');
    }
    if (this.isContainInDiffType(diffType, CEActivityAssignmentDiffType.ResourceNames)) {
      result.push('Resource Names');
    }
    if (this.isContainInDiffType(diffType, CEActivityAssignmentDiffType.Rates)) {
      result.push('Rates');
    }
    if (this.isContainInDiffType(diffType, CEActivityAssignmentDiffType.CrewHours)) {
      result.push('Gang Hours');
    }
    return result;
  }

  @autobind
  private renderRelatedMaterials(
    primaryId: number,
    secondaryId: number,
    materials: Record<number, CEActivityAssignmentDataMaterial>,
  ): React.ReactNode {
    if (!primaryId && !secondaryId) {
      return null;
    }

    return (
      <>
        <ActivityAssignmentActivityTileBodyTitle>
          Primary Material
        </ActivityAssignmentActivityTileBodyTitle>
        {this.renderMaterialSelect({ ...materials[primaryId], id: primaryId })}
        <ActivityAssignmentActivityTileBodyTitle>
          Secondary Material
        </ActivityAssignmentActivityTileBodyTitle>
        {this.renderMaterialSelect({ ...materials[secondaryId], id: secondaryId })}
      </>
    );
  }

  @autobind
  private renderMaterialSelect(materialSelect: ActivityAssignmentMaterialWithId): React.ReactNode {
    return (
      <ActivityAssignmentSelectMaterialVariant
        key={materialSelect.id}
        material={materialSelect}
        materialId={materialSelect.id}
        selectMaterial={this.props.selectMaterial}
      />
    );
  }


  private renderVariant(
    id: string, variant: CEActivityAssignmentDataVariant,
  ): React.ReactElement<MaterialMenuItemProps> {
    return (
      <MaterialMenuItem
        key={id}
        value={+id}
      >
        {variant.name}
      </MaterialMenuItem>
    );
  }
}

function mapStateToProps(state: State, { index }: OwnProps): StateProps {
  const selectedWork = state.ceActivityAssignment.selectedWorks[index];
  const activityVariantId = CEActivityAssignmentUtils.getSelectedVariantId(selectedWork.selectedVariant);
  const diffType = CEActivityAssignmentUtils.getDiffType(state.ceActivityAssignment);

  return { selectedWork, activityVariantId, diffType };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { index }: OwnProps): DispatchProps {
  return {
    selectActivity: variantId => {
      dispatch(CEActivityAssignmentActions.setModality({ index, variantId }));
      dispatch(CEActivityAssignmentActions.assignActivity());
    },
    selectMaterial: (materialId, materialVariantId) => {
      dispatch(CEActivityAssignmentActions.selectMaterial(materialId, materialVariantId, index));
      dispatch(CEActivityAssignmentActions.assignActivity());
    },
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityAssignmentActivityTile = connector(ActivityAssignmnentActivityTileComponent);
