import { OpenArrowButtonStyled, DeleteButtonStyled, EditButtonStyled, Text } from '@kreo/kreo-ui-components';
import { IconButtonStyle } from '@kreo/kreo-ui-components/components/buttons/icon';
import styled from 'styled-components';

const LabelGroupWithIcon = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  transition: all ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  padding: 5px;

  ${IconButtonStyle} {
    g {
      stroke: ${p => p.theme.color.gray}
    }
  }

  ${DeleteButtonStyled.Container} {
    opacity: 0;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  ${EditButtonStyled.Container} {
    right: 20px;
  }

  &:hover {
    ${IconButtonStyle} {
      g {
        stroke: ${p => p.theme.color.turquoise}
      }
    }
    ${Text} {
      color: ${p => p.theme.color.turquoise};
    }
    ${OpenArrowButtonStyled.Container} {
      svg {
        fill: ${p => p.theme.color.turquoise};
      }
    }
    ${DeleteButtonStyled.Container} {
      opacity: 1;
    }
    ${EditButtonStyled.Container} {
      svg {
        fill: ${p => p.theme.color.turquoise};
      }
    }
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;

  ${Text} {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    white-space: normal;
    word-break: break-all;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
  ${DeleteButtonStyled.Container} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const RoundButtonContainer = styled.div`
  > button {
    width: 20px;
    height: 20px;
    background-color: ${p => p.theme.color.turquoise};

    &:hover {
      background-color: ${p => p.theme.color.turquoise};
      svg {
        fill: ${p => p.theme.color.white};
      }
    }
    svg {
      height: 10px;
      fill: ${p => p.theme.color.white};
    }
  }
`;

const InfoContainer = styled.div<{ isExpanded: boolean }>`
  ${OpenArrowButtonStyled.Container} {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;

    svg {
      transform: ${p => (p.isExpanded ? 'rotate(360deg)' : 'rotate(270deg)')};
    }
  }

  display: flex;
  gap: 10px;
`;

export const Styled = {
  LabelGroupWithIcon,
  TextContainer,
  ButtonContainer,
  RoundButtonContainer,
  InfoContainer,
};
