import * as t from 'io-ts';

const nullableNumbersArrayC = t.array(t.union([t.number, t.null]));

export const QuantityTakeOffElementC = t.intersection(
  [
    t.type({
      category: t.string,
      family: t.string,
      elementType: t.string,
      extractorsIds: t.array(t.number),
      extractorsValues: nullableNumbersArrayC,
      bimId: t.number,
      engineId: t.number,
      index: t.number,
      name: t.string,
      nrmBasedName: t.string,
      extraInfo: t.string,
      extraPropertiesIds: t.array(t.number),
      extraPropertiesValues: nullableNumbersArrayC,
      isLayer: t.boolean,
      revitId: t.string,
    }),
    t.partial({
      level: t.string,
      uniProduct: t.string,
      uniSystem: t.string,
      nrm1: t.string,
      nrm2: t.string,
    }),
  ],
  'QuantityTakeOffElement');

export type QuantityTakeOffElement = t.TypeOf<typeof QuantityTakeOffElementC>;
