import { Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';

interface Props {
  title: string;
}

const InfinityLoaderComponent: React.FC<Props> = ({
  title,
}) => {
  return (
    <Styled.Container>
      <Text
        fontSize={12}
      >
        {title}
      </Text>
    </Styled.Container>
  );
};

export const InfinityLoader = React.memo(InfinityLoaderComponent);
