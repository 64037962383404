import styled from 'styled-components';
import { DataBaseSelectorTableStyled } from 'common/components/database-selector-table';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.color.background};
  ${DataBaseSelectorTableStyled.Container} {
    border-left: 1px solid ${p => p.theme.color.background};
  }
`;

const CollapseButton = styled.div`
  height: 50px;
  width: 100%;
  display: flex;

  > div {
    width: 100%;
    justify-content: flex-start;
    padding-left: 15px;
    > p {
      font-size: 14px;
    }
  }
`;

export const Styled = {
  Container,
  CollapseButton,
};
