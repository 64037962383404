import autobind from 'autobind-decorator';
import * as paper from 'paper';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { KreoColors } from 'common/enums/kreo-colors';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { ComparableDocumentType, isCompareDocument } from 'common/pdf/compare-document';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DelayedExecutor } from 'common/utils/delayed-executer';
import { TwoDCopilotPopup } from 'unit-2d-copilot/2d-copilot-popup';
import { TwoDCopilotActions } from 'unit-2d-copilot/store-slice';
import { SpliterPaneConfig, SplitterLayout } from '../../../components/splitter-layout';
import { PrimaryPaneResizeOptionType } from '../../../components/splitter-layout/resize-options';
import { TwoDActions } from '../../../units/2d/actions/creators';
import { TraceLink } from '../../../units/2d/components/trace-link';
import { TablePreset } from '../../../units/2d/interfaces';
import { PersistedStorageActions } from '../../../units/persisted-storage/actions/creators';
import { ProgressUtils } from '../../utils/progress-utils';
import { ProgressActions, ProgressBarState, ProgressBarType } from '../progress';
import { WizzardToolsActions } from './actions/creators';
import { DrawingsAnnotationActions } from './actions/creators/annotation';
import { DrawingsActions } from './actions/creators/common';
import { DrawingsAnnotationLegendActions } from './actions/creators/drawings-annotation-legend';
import { DrawingsUpdateActions } from './actions/creators/update';
import { DrawingsUserAnnotationActions } from './actions/creators/user-annotation';
import { DrawingsChanges } from './actions/payloads/update';
import { DrawingsCanvasConstants } from './constants/drawing-canvas-constants';
import { DrawingDialogs } from './constants/drawing-dialogs';
import { WizzardConstants } from './constants/wizzard-constants';
import { MoveToDialog, NotEnoughGeometriesDialog, PresetDialog } from './dialogs';
import { DrawingAnnotationLegendApi, DrawingsAnnotationLegend } from './drawings-annotation-legend';
import { RenameToolDialog } from './drawings-annotation-legend/drawings-annotation-legend-rename-tool';
import { DrawingLayoutApi, DrawingsCanvas } from './drawings-canvas';
import {
  connectToDrawingsSelectionContext,
  DrawingContextObserver,
  DrawingContextObserverWithPrev,
  DrawingsElementStateOperationsContextProvider,
  DrawingsGeometryOperationContextProvider,
  DrawingsSelectionContextProps,
} from './drawings-contexts';
import { DrawingsLayoutApi } from './drawings-layout-api-context';
import { DrawingsLayoutContainer } from './drawings-layout-container';
import {
  DrawingsLayoutContextWrapperProps,
  withDrawingsLayoutContextWrapper,
} from './drawings-layout-context-wrapper';
import { DrawingProcessingState } from './enums';
import { DrawingChangeSource } from './enums/drawing-change-source';
import {
  DrawingModesWithoutSelectionBlock,
  DrawingsDrawMode,
  MagicSearchDrawModes,
  WizzardDrawModes,
} from './enums/drawings-draw-mode';
import { DrawingsInstanceType } from './enums/drawings-instance-type';
import { PdfLoader } from './helpers';
import { DrawingCompareSettingsReloader } from './helpers/drawing-compare-settings-reloader';
import { DrawingsSelector } from './helpers/drawing-opener';
import { loadPageText } from './helpers/load-page-text';
import { getSnappingWithProgress } from './helpers/pdf-current-doc-getter';
import { DrawOptions } from './helpers/viewport/interfaces';
import { MainCanvasRenderer } from './helpers/viewport/main-canvas-renderer';
import { DrawingsViewportHelper } from './helpers/viewport/viewport';
import { HoverState, TextSearchResult } from './interfaces';
import { PdfStatistic } from './interfaces/api-responses/pdf-geometry-response';
import { DrawingSnapping } from './interfaces/drawing';
import { ShortPointDescription } from './interfaces/drawing-ai-annotation';
import { DrawingsFile, DrawingsFiles } from './interfaces/drawings-file-info';
import {
  DrawingsBounds,
} from './interfaces/drawings-geometry';
import { DrawingsGeometryGroup } from './interfaces/drawings-geometry-group';
import { DrawingsGeometryInstance, DrawingsGeometryInstanceWithId } from './interfaces/drawings-geometry-instance';
import { DrawingsInstanceMeasure } from './interfaces/drawings-instance-measure';
import { DrawingsGroupMeasure } from './interfaces/drawings-measures';
import { DrawingsScaleInfo, DrawingsShortInfo } from './interfaces/drawings-short-drawing-info';
import { DrawingsGeometryState } from './interfaces/drawings-state';
import {
  DrawingsCanvasContainer,
  UnsavedChangesDialog,
  DrawingsDeleteMeasuresConfirmationDialog,
} from './layout-components';
import { DrawingsSelectItemsCursorHint } from './layout-components/drawings-cursor-hint';
import { PermissionDialogs } from './permission-dialogs';
import { InstancesUtils } from './utils';
import { DrawingAnnotationUtils } from './utils/drawing-annotation-utils';
import { DrawingsCommonUtils } from './utils/drawings-common-utils';
import { DrawingsGeometryUtils } from './utils/drawings-geometry-utils';
import { DrawingsGroupUtils } from './utils/drawings-group-utils';
import { DrawingsUtils } from './utils/drawings-utils';
import {
  DrawingsInstanceMeasureHelper,
  getDrawingsInstanceMeasureHelper,
} from './utils/measures/get-drawings-instance-measure-helper';
import { RasterizationFileUtils } from './utils/rasterization-file-utils';

export const DrawingLayoutSize = 'DrawingLayoutSize';

export const ANNOTATION_LEGEND_PANEL_MIN_WIDTH = 320;
export const ANNOTATION_LEGEND_PANEL_MAX_WIDTH = 750;
export const ANNOTATION_LEGEND_PANEL_DEFAULT_WIDTH = 340;
export const CANVAS_CONTAINER_MIN_WIDTH = 575;

interface StateProps {
  instances: Record<string, DrawingsGeometryInstanceWithId>;
  selectedInstances: string[];
  currentDrawing: DrawingsShortInfo;
  loadStatus: RequestStatus;
  elementMeasurement: Record<string, DrawingsInstanceMeasure>;
  drawingGeometryGroups: DrawingsGeometryGroup[];
  colors: Record<string, string>;
  points: Record<string, ShortPointDescription>;
  drawings: Record<string, DrawingsShortInfo>;
  filteredElements: string[];
  selectedPages: string[];
  selectedGroups: string[];
  groupForInstanceCreation: DrawingsGeometryGroup;
  aiAnnotation: DrawingsGeometryState;
  selectedUserAnnotations: string[];
  isOpenDrawingsAnnotationLegendPanel: boolean;
  splitterPaneSize: number;
  rotation: number;
  textSearchResults: Record<string, TextSearchResult[]>;
  files: DrawingsFiles;
  drawingRendered: boolean;
  currentDrawingStatistic: PdfStatistic;
  boostWithAi: boolean;
  instanceToOperateWith: string[];
}

interface DispatchProps {
  saveCurrentDrawingSnapping: (snapping: DrawingSnapping) => void;
  setSelected: (instanceIds: string[], groupIds: string[]) => void;
  getUserAnnotations: (pageId: string) => void;
  onSelectDrawing: (id: string) => void;
  removeInstances: (instancesIds: string[], changes: DrawingsChanges[]) => void;
  pageRescale: (data: DrawingsScaleInfo[]) => void;
  onFinishEditAnnotation: (
    pointsUpdated: Record<string, ShortPointDescription>,
    measurers: DrawingsInstanceMeasure[],
  ) => void;
  getAnnotationData: (projectId: number, documentId: string, pdfId: string) => void;
  instancesMeasuresUpdated: (measures: DrawingsInstanceMeasure[]) => void;
  addPageTab: (pageId: string) => void;
  openDialog: (dialogName: string, data?: any) => void;
  closeDialog: (dialogName: string) => void;
  setSelectGeometryGroup: (groupIds: string[]) => void;
  toggleOpenDrawingsAnnotationLegendPanel: () => void;
  createPreset: (preset: TablePreset) => void;
  updatePreset: (preset: TablePreset) => void;
  getTablePresets: () => void;
  cacheGroupMeasure: (groupMeasures: Record<string, DrawingsGroupMeasure>) => void;
  saveSplitterSize: (size: number) => void;
  markDrawingsWithoutSnapping: (drawingId: string) => void;
  markDrawingWithRenderProblems: (drawingId: string) => void;
  openUnsavedChangesDialog: (accept: () => void, cancel?: () => void) => void;
  clearCopilotHistory: () => void;
  trackProgress: (progressKey: string, progressBar: ProgressBarState) => void;
  removeProgress: (progressKey: string) => void;
  showOptimizeMessage: (value: boolean) => void;
  saveHoverState: (hoverState: HoverState) => void;
  setDrawingRendered: (value: boolean) => void;
  dropWizzardState: () => void;
  getDrawingStatistic: (drawingId: string) => void;
}


interface OwnProps {
  overrideCallBacks?: boolean;
  drawingEnabled: boolean;
  projectId: number;
  isFullScreen: boolean;
  isSelectModeActive: boolean;
  bottomOffset: number;
  canUseCompare: boolean;
  canUseOptimizer: boolean;
  canToggleFullScreen: boolean;
  addInstancesToCellOnCreate: (instanceMeasures: DrawingsInstanceMeasure[]) => void;
  setSelectItemsMode: (value: boolean) => void;
  onInstancesMeasuresUpdated?: (measures: DrawingsInstanceMeasure[]) => void;
  onToggleFullScreen: () => void;
  sendApi: (api: DrawingsLayoutApi) => void;
  onInstancesRemove?: (instancesIds: string[]) => void;
  onInstancesAdd?: (instances: DrawingsGeometryInstance[], onlyNewSelected: boolean) => void;
  onUpdateInstancesGeometry?: (changes: DrawingsGeometryInstance[]) => void;
  onMoveToCell: (instancesIds: string[], type: string) => void;
  onMoveTableToCell: (instancesIds: string[], preset: TablePreset) => void;
  onDynamicMoveToCell: (preset: TablePreset) => void;
  onTraceLink: (instancesId: string) => void;
  getTraceLinks: (instanceId: string) => string[];
  onTraceLinkClick: (sheetId: string, cellId: string) => void;
  onFolderToCell: (type: string) => void;
}

interface Props extends
  StateProps,
  DispatchProps,
  OwnProps,
  DrawingsSelectionContextProps,
  DrawingsLayoutContextWrapperProps {
}

interface ComponentState {
  paperSize: string;
  scale: number;
  metersPerPixel: number;
  color: string;
  loadingSnapping: boolean;
  marqueeZoomEnabled: boolean;
  paneConfigs: SpliterPaneConfig[];
  drawingsInstancesValueHelper: DrawingsInstanceMeasureHelper;
}

export class DrawingsLayoutComponent extends React.PureComponent<Props, ComponentState> {
  // hotfix KREOP-12728
  private measurementsCache: Record<string, DrawingsInstanceMeasure> = {};
  private deferredSaveMeasurementsExecuter: DeferredExecutor = new DeferredExecutor(50);
  // hotfix KREOP-12728

  private saveSplitterSizeDefferedExecuter: DelayedExecutor = new DelayedExecutor(100);

  private readonly canEditReport: boolean = this.props.ability.can(Operation.Update, Subject.Takeoff2DReport);
  private readonly canViewReport: boolean = this.props.ability.can(Operation.Read, Subject.Takeoff2DReport);

  private readonly canViewUserAnnotations: boolean =
    this.props.ability.can(Operation.Read, Subject.Takeoff2dAnnotations);
  private readonly canEditUserAnnotations: boolean =
    this.props.ability.can(Operation.Update, Subject.Takeoff2dAnnotations);

  private readonly canEditMeasurement: boolean =
    this.props.ability.can(Operation.Update, Subject.Takeoff2DMeasurement);
  private readonly canViewMeasurement: boolean =
    this.props.ability.can(Operation.Read, Subject.Takeoff2DMeasurement);
  private readonly canUseAiSuggestForLines: boolean =
    this.props.ability.can(Operation.Manage, Subject.Takeoff2dMeasurementAutocomplete);

  private legendApi: DrawingAnnotationLegendApi;
  private drawingsLayoutRef: HTMLDivElement;

  private viewportHelper: DrawingsViewportHelper;
  private snappingObserver: DrawingContextObserver<DrawingSnapping> = new DrawingContextObserver(null);
  private zoomHandler: DrawingContextObserverWithPrev<{ zoom: number }>
    = new DrawingContextObserverWithPrev({ zoom: 1 });
  private fileLoader = new PdfLoader(this.trackDrawingLoadingProgress);
  private drawingsOpener: DrawingsSelector;
  private documentRenderer: MainCanvasRenderer = new MainCanvasRenderer({
    syncPageViewportRect: (options: DrawOptions) => this.viewportHelper.syncPageViewportRect(options),
    getCurrentDrawingInfo: () => this.props.currentDrawing,
    getCurrentZoom: () => this.zoomHandler.getContext().zoom,
  });
  private compareReloader: DrawingCompareSettingsReloader = new DrawingCompareSettingsReloader(() => {
    return this.props.currentDrawing;
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      color: KreoColors.blue4,
      metersPerPixel: DrawingsCanvasConstants.mInPx,
      paneConfigs: [],
      scale: null,
      paperSize: null,
      drawingsInstancesValueHelper: null,
      marqueeZoomEnabled: false,
      loadingSnapping: false,
    };

    this.viewportHelper = new DrawingsViewportHelper({
      getSelectedInstancesBounds: this.getSelectedInstancesBounds,
      zoomHandler: this.zoomHandler,
      documentRenderer: this.documentRenderer,
      restrictZoom: true,
      scope: paper,
      getCurrentRotation: () => this.props.rotation,
      getCurrentDrawingId: () => this.props.currentDrawing?.drawingId,
      canShowMainCanvas: () => this.canViewMeasurement && this.props.loadStatus !== RequestStatus.Failed,
    });
    this.drawingsOpener = new DrawingsSelector({
      loader: this.fileLoader,
      setSnapping: this.setSnapping,
      viewportHelper: this.viewportHelper,
      getCurrentDrawing: () => this.props.currentDrawing,
      getCurrentRotation: () => this.props.rotation,
      onProcessingChange: this.onProcessingChange,
      isSnappingEnabled: () => this.props.isSnappingEnabled && this.canEditMeasurement,
    });
  }

  public static getDerivedStateFromProps(props: Props): Partial<ComponentState> {
    const stateUpdate: Partial<ComponentState> = { };

    if (props.currentDrawing) {
      const { width, height } = props.currentDrawing;
      const { scale, paperSize } = DrawingsUtils.getCurrentPageScales(props.currentDrawing);
      stateUpdate.metersPerPixel = DrawingsUtils.getMetersPerPx(width, height, paperSize);
      stateUpdate.scale = scale;
      stateUpdate.paperSize = paperSize;
    } else {
      stateUpdate.metersPerPixel = null;
      stateUpdate.scale = null;
      stateUpdate.paperSize = null;
    }

    if (props.drawMode !== DrawingsDrawMode.Disabled) {
      stateUpdate.marqueeZoomEnabled = false;
    }

    return stateUpdate;
  }

  public render(): React.ReactNode {
    const { loadingSnapping } = this.state;
    const {
      currentDrawing,
      instances,
      selectedInstances,
      onToggleFullScreen,
      isFullScreen,
      onFinishEditAnnotation,
      colors,
      points,
      loadStatus,
      onMoveToCell,
      onFolderToCell,
      filteredElements,
      onTraceLink,
      getTraceLinks,
      bottomOffset,
      selectedPages,
      isOpenDrawingsAnnotationLegendPanel,
      drawingRendered,
      drawMode,
    } = this.props;
    const currentDrawingId = currentDrawing?.drawingId;
    const drawingLoaded = currentDrawingId && !drawingRendered;
    const showLoader = this.canViewMeasurement && (
      loadStatus === RequestStatus.Loading
      || drawingLoaded
      || loadingSnapping
    );
    const showFlyingMenu = this.props.selectedInstances?.length
      && DrawingsCommonUtils.isShowFlyingMenu(drawMode)
      && drawingRendered;
    const showPdfManagerButton = !selectedPages.length && !showLoader && this.canViewMeasurement;
    const isUsedInSpreadsheet = this.props.selectedInstances.some(instance => !!getTraceLinks(instance).length);
    return (
      <>
        <DrawingsGeometryOperationContextProvider
          selectedInstances={this.props.selectedInstances}
          setDrawMode={this.props.setDrawMode}
          drawMode={drawMode}
        >
          <DrawingsElementStateOperationsContextProvider
            isKeepOriginName={this.props.isKeepOriginName}
            colors={this.props.colors}
            canEditMeasurements={this.canEditMeasurement}
            groupForInstanceCreation={this.props.groupForInstanceCreation}
            drawMode={drawMode}
            getTraceLinks={getTraceLinks}
            filteredElements={filteredElements}
            onMoveToCell={onMoveToCell}
            onFolderToCell={onFolderToCell}
            onDynamicMoveToCell={this.props.onDynamicMoveToCell}
            onMoveTableToCell={this.props.onMoveTableToCell}
            selectedInstances={this.props.selectedInstances}
            onTraceLink={onTraceLink}
            drawingsInstancesValueHelper={this.state.drawingsInstancesValueHelper}
          >
            <MoveToDialog/>
              <DrawingsLayoutContainer
                drawMode={drawMode}
                ref={this.saveDrawingLayoutRef}
                bottomOffset={bottomOffset}
                isOpenDrawingsAnnotationLegendPanel={this.props.isOpenDrawingsAnnotationLegendPanel}
              >
                <DrawingsSelectItemsCursorHint mode={drawMode} />
                <SplitterLayout
                  paneConfigs={this.state.paneConfigs}
                  separatorHide={!isOpenDrawingsAnnotationLegendPanel}
                  resizingDetectorType={PrimaryPaneResizeOptionType.CalculateSizeIndependently}
                  onPrimaryPaneSizeChange={this.onSplitterPaneSizeChanged}
                  showCollapseExpandButton={this.props.isOpenDrawingsAnnotationLegendPanel}
                  onClickCollapseExpandButton={this.openDrawingsAnnotationLegendPanel}
                >
                  {this.renderAnnotationLegend()}
                  <DrawingsCanvasContainer
                    getInstancesMeasures={this.getInstanceMeasureInner}
                    renderPageError={this.hasRenderProblem()}
                    optimizeStatus={currentDrawing?.rasterizationStatus}
                    toggleMarqueeZoom={this.toggleMarqueeZoom}
                    loadStatus={this.props.loadStatus}
                    showLoader={showLoader}
                    showPdfManagerButton={showPdfManagerButton}
                    viewportHelper={this.viewportHelper}
                    drawMode={drawMode}
                    onToggleFullScreen={onToggleFullScreen}
                    isMarqueeZoomEnabled={this.state.marqueeZoomEnabled}
                    changeDrawingMode={this.props.setDrawMode}
                    canToggleFullScreen={this.props.canToggleFullScreen}
                  >
                    {this.canViewMeasurement &&
                      <DrawingsCanvas
                        canToggleFullScreen={this.props.canToggleFullScreen}
                        disableDrawMouseMove={loadingSnapping}
                        getActuralInstancesMeasures={this.getInstanceMeasureInner}
                        canUseAiSuggestForLines={this.canUseAiSuggestForLines}
                        paperSize={this.state.paperSize}
                        marqueeZoomEnabled={this.state.marqueeZoomEnabled}
                        groups={this.props.drawingGeometryGroups}
                        cacheGroupMeasure={this.props.cacheGroupMeasure}
                        calculateCurrentPageGroupMeasures={this.calculateCurrentPageGroupMeasures}
                        canEditUserAnnotations={this.canEditUserAnnotations}
                        viewportHelper={this.viewportHelper}
                        setDrawMode={this.props.setDrawMode}
                        isFullScreen={isFullScreen}
                        showFlyingMenu={showFlyingMenu}
                        metersPerPixel={this.state.metersPerPixel}
                        onCanvasFocus={this.onCanvasFocus}
                        onInstancesMeasuresUpdated={this.deferredSaveMeasures}
                        drawingRendered={drawingRendered}
                        snappingObserver={this.snappingObserver}
                        instances={instances}
                        selectedInstances={selectedInstances}
                        currentDrawingId={currentDrawing ? currentDrawing.drawingId : null}
                        scale={this.state.scale}
                        zoomObserver={this.zoomHandler}
                        syncGroupSelection={this.syncGroupSelection}
                        drawMode={drawMode}
                        sendApi={this.saveDrawingApi}
                        colors={colors}
                        points={points}
                        onFinishEditAnnotation={onFinishEditAnnotation}
                        isContainsInGroups={this.isContainsInGroups}
                        onFocus={this.scrollToInstances}
                        toggleElementsPanel={this.openDrawingsAnnotationLegendPanel}
                        toggleFullScreen={onToggleFullScreen}
                        setSelectGeometryGroup={this.props.setSelectGeometryGroup}
                        selectDrawingGroups={this.selectInstancesGroupsAndInstances}
                        canEditMeasurement={this.canEditMeasurement}
                        canViewMeasurement={this.canViewMeasurement}
                        canEditReport={this.canEditReport}
                        canViewReport={this.canViewReport}
                      />
                    }
                  </DrawingsCanvasContainer>
                </SplitterLayout>
              </DrawingsLayoutContainer>
            <TraceLink
              getTraceLinks={this.props.getTraceLinks}
              onCellClick={this.onTraceLinkClick}
            />
            <DrawingsDeleteMeasuresConfirmationDialog
              isUsedInSpreadsheet={isUsedInSpreadsheet}
            />
            <UnsavedChangesDialog />
            <PresetDialog/>
            <NotEnoughGeometriesDialog/>
          </DrawingsElementStateOperationsContextProvider>
        </DrawingsGeometryOperationContextProvider>
        <PermissionDialogs />
        <TwoDCopilotPopup
          getPdfText={this.getPdfText}
        />
        <RenameToolDialog/>
      </>
    );
  }

  public componentDidMount(): void {
    if (this.canViewMeasurement) {
      this.viewportHelper.scrollLayoutRef.addEventListener(
        'wheel',
        this.viewportHelper.zoomEventHandler,
        { passive: false },
      );
    }
    if (this.props.currentDrawing) {
      this.selectDrawing(this.props.currentDrawing);
    }
    if (this.canEditReport) {
      this.props.getTablePresets();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.currentDrawing?.drawingId !== prevProps.currentDrawing?.drawingId) {
      this.props.clearCopilotHistory();
      this.props.saveHoverState(null);
    }

    if (!this.props.drawingEnabled || !this.props.currentDrawing) {
      this.props.setDrawMode(DrawingsDrawMode.Disabled, { ignoreCancelMessage: true });
    }

    const shouldReloadComparedDrawing = this.shouldReloadComparedDrawing(prevProps.currentDrawing);
    const isNeedCleanUpDrawing = this.props.currentDrawing && (
      this.props.canUseCompare !== prevProps.canUseCompare || this.props.canUseOptimizer !== prevProps.canUseOptimizer
    );

    if (
      DrawingsUtils.isDrawingInfoChanged(this.props.currentDrawing, prevProps.currentDrawing)
      && !shouldReloadComparedDrawing || isNeedCleanUpDrawing
    ) {
      this.cleanUpDrawing();
    } else {
      if (shouldReloadComparedDrawing) {
        this.props.setDrawingRendered(false);
        this.compareReloader.reload({
          document: this.viewportHelper.drawingInfo.pdfDocument as ComparableDocumentType,
          settings: this.props.currentDrawing.pagesCompareSettings,
          drawingId: this.props.currentDrawing.drawingId,
          drawingToCompareWithId: this.props.currentDrawing.pageToCompareId,
          renderMainLayout: () => {
            this.viewportHelper.resetZoom(1);
            this.viewportHelper.removeDefaultCanvas();
            return this.viewportHelper.renderMainPdfLayout(this.props.rotation);
          },
        }).then((drawingRendered) => this.props.setDrawingRendered(drawingRendered));
      } else {
        this.updateSelectionBoundsIfNeed(this.props, prevProps);
      }
    }


    if (!this.props.currentDrawing) {
      this.viewportHelper.drawingInfo = null;
    }

    this.viewportHelper.updateRenderParameters(this.zoomHandler.getContext().zoom, this.props.rotation);

    if (prevProps.rotation !== this.props.rotation && this.viewportHelper.defaultCanvasRef) {
      this.renderMainPdfLayout(this.props.rotation);
    }

    this.updateDrawOnSelectModeChanged(prevProps);

    if (this.props.isSnappingEnabled && !prevProps.isSnappingEnabled && !this.snappingObserver.getContext()) {
      this.loadSnapping();
    }

    if (this.props.isOpenDrawingsAnnotationLegendPanel !== prevProps.isOpenDrawingsAnnotationLegendPanel) {
      this.setPaneConfig(this.props.isOpenDrawingsAnnotationLegendPanel);
    }

    this.updateStateByChangedDrawMode(this.props.drawMode, prevProps.drawMode);
    this.updateWizzardByAiBoost(this.props.boostWithAi, prevProps.boostWithAi);
  }

  public componentWillUnmount(): void {
    this.drawingsOpener.cancel();
    this.viewportHelper.cancel();
    if (this.canViewMeasurement) {
      this.viewportHelper.scrollLayoutRef.removeEventListener('mousewheel', this.viewportHelper.zoomEventHandler);
    }
  }

  private shouldReloadComparedDrawing(prevDrawing: DrawingsShortInfo): boolean {
    const document = this.viewportHelper.drawingInfo?.pdfDocument;
    return document
      && isCompareDocument(document)
      && DrawingsUtils.canRerenderDrawingComparedDrawing(this.props.currentDrawing, prevDrawing);
  }

  @autobind
  private renderAnnotationLegend(): JSX.Element {
    return (
      <DrawingsAnnotationLegend
        isDrawingMode={
          DrawingsCommonUtils.isDrawEnabled(this.props.drawMode)
          || MagicSearchDrawModes.includes(this.props.drawMode)
        }
        isOpen={this.props.isOpenDrawingsAnnotationLegendPanel}
        getInstancesMeasures={this.getInstanceMeasureInner}
        focus={this.scrollToInstances}
        canEditMeasurement={this.canEditMeasurement}
        selectDrawingGroups={this.selectInstancesGroupsAndInstances}
        syncGroupSelection={this.syncGroupSelection}
        sendApi={this.saveAnnotationLegendApi}
        panelMinWidth={this.props.splitterPaneSize}
      />
    );
  }

  @autobind
  private onTraceLinkClick(sheetId: string, cellId: string): void {
    if (this.props.isFullScreen) {
      this.props.onToggleFullScreen();
    }
    this.props.onTraceLinkClick(sheetId, cellId);
  }

  private getFirstSelectedGroupId(): string {
    const { drawingGeometryGroups, selectedGroups } = this.props;
    return DrawingsGroupUtils.getFirstSelectedGroupId({ groups: drawingGeometryGroups, selectedGroups });
  }

  private updateStateByChangedDrawMode(currentDrawMode: DrawingsDrawMode, prevDrawMode: DrawingsDrawMode): void {
    if (currentDrawMode === prevDrawMode) {
      return;
    }
    this.updateWizzardByChangedDrawMode(currentDrawMode, prevDrawMode);
    if (!DrawingModesWithoutSelectionBlock.includes(currentDrawMode)) {
      this.props.setSelected([], this.props.selectedGroups.length ? [this.getFirstSelectedGroupId()] : []);
    }
  }

  private updateWizzardByChangedDrawMode(currentDrawMode: DrawingsDrawMode, prevDrawMode: DrawingsDrawMode): void {
    const isPrevWizzard = WizzardDrawModes.includes(prevDrawMode);
    const isCurrentWizzard = WizzardDrawModes.includes(currentDrawMode);
    if (!isPrevWizzard && isCurrentWizzard) {
      const shouldCheckByMode = currentDrawMode !== DrawingsDrawMode.BucketFill || !this.props.boostWithAi;
      if (
        shouldCheckByMode
        && this.props.currentDrawingStatistic?.geometriesCount < WizzardConstants.MIN_GEOMETRY_COUNT
      ) {
        this.props.openDialog(DrawingDialogs.NOT_ENOUGH_GEOMETRIES_DIALOG);
      }
    }
  }

  private updateWizzardByAiBoost(aiBoost: boolean, prevAiBoost: boolean): void {
    const showCheckByAi = prevAiBoost !== aiBoost && !aiBoost && this.props.drawMode === DrawingsDrawMode.BucketFill;

    if (showCheckByAi && this.props.currentDrawingStatistic?.geometriesCount < WizzardConstants.MIN_GEOMETRY_COUNT) {
      this.props.openDialog(DrawingDialogs.NOT_ENOUGH_GEOMETRIES_DIALOG);
    }
  }

  @autobind
  private onProcessingChange(status: DrawingProcessingState, drawingId: string): void {
    if (this.props.currentDrawing?.drawingId !== drawingId) {
      return;
    }
    const progressKey = ProgressUtils.createCurrentDrawingKey(drawingId);
    switch (status) {
      case DrawingProcessingState.Loading:
        this.props.trackProgress(progressKey, {
          type: ProgressBarType.Indeterminate,
          title: 'File is processed',
          progressTitle: 'In progress',
        });
        break;
      case DrawingProcessingState.Loaded:
        this.props.removeProgress(progressKey);
        this.props.showOptimizeMessage(false);
        break;
      case DrawingProcessingState.ToLongProcessing:
        this.props.showOptimizeMessage(true);
        break;
      default:
        break;
    }
  }

  @autobind
  private trackDrawingLoadingProgress(process: { total: number, loaded: number }, drawingId: string): void {
    if (this.props.currentDrawing?.drawingId !== drawingId) {
      return;
    }
    const progressKey = ProgressUtils.createCurrentDrawingKey(drawingId);
    this.props.trackProgress(progressKey, {
      total: process.total,
      finished: process.loaded,
      type: ProgressBarType.Percentage,
      title: 'Loading file...',
      progressTitle: 'Loading...',
    });
  }

  @autobind
  private async getPdfText(): Promise<string> {
    if  (this.props.currentDrawing.isOptimized) {
      const { currentDrawing, files } = this.props;
      return loadPageText({
        drawing: currentDrawing,
        files: files.entities as Record<string, DrawingsFile>,
        loader: this.fileLoader,
      });
    } else {
      return this.viewportHelper.getPdfText();
    }
  }

  private updateDrawOnSelectModeChanged(prevProps: Props): void {
    if (this.props.isSelectModeActive !== prevProps.isSelectModeActive) {
      if (this.props.isSelectModeActive) {
        this.props.setDrawMode(DrawingsDrawMode.SelectItems, { cancel: () => this.props.setSelectItemsMode(false) });
      } else if (this.props.drawMode === DrawingsDrawMode.SelectItems) {
        this.props.setDrawMode(DrawingsDrawMode.Disabled);
      }
    }
  }

  @autobind
  private saveAnnotationLegendApi(api: DrawingAnnotationLegendApi): void {
    this.legendApi = api;
  }

  @autobind
  private onSplitterPaneSizeChanged(size: number): void {
    this.saveSplitterSizeDefferedExecuter.execute(() => this.props.saveSplitterSize(size));
  }

  @autobind
  private getSelectedInstancesBounds(
    { width, height }: Core.Document.PageInfo,
  ): { zoomed: DrawingsBounds, bounds: DrawingsBounds } {
    if (!this.props.selectedInstances?.length) {
      return null;
    }
    const bounds = DrawingsGeometryUtils.getCurrentDrawingInstancesBounds(
      this.props.instances,
      this.props.selectedInstances,
      this.props.points,
      this.props.currentDrawing?.drawingId,
    );
    const zoomedBounds = DrawingsGeometryUtils.zoomAndRotateBounds(
      bounds,
      this.zoomHandler.getContext().zoom,
      this.props.rotation,
      width,
      height,
    );
    return { zoomed: zoomedBounds, bounds };
  }

  @autobind
  private cleanUpDrawing(): void {
    this.viewportHelper.removeCanvas();
    this.viewportHelper.removeDefaultCanvas();
    this.compareReloader.cancel();
    this.selectDrawing(this.props.currentDrawing);
  }

  private updateSelectionBoundsIfNeed(
    { currentDrawing, selectedInstances, selectedUserAnnotations, drawMode, instanceToOperateWith }: Props,
    prevProps: Props,
  ): void {
    if (currentDrawing && this.props.drawingRendered) {
      if (
        selectedInstances !== prevProps.selectedInstances
        || selectedUserAnnotations !== prevProps.selectedUserAnnotations
        || drawMode !== prevProps.drawMode
        || instanceToOperateWith !== prevProps.instanceToOperateWith
      ) {
        this.updateSelectionBounds();
      }
    } else {
      this.viewportHelper.updateSelectedBounds(null, null);
    }
  }

  private updateSelectionBoundsWithId(instancesIds: string[]): void {
    const { width, height } = this.viewportHelper.currentPageInfo;
    const bounds = DrawingsGeometryUtils.getCurrentDrawingInstancesBounds(
      this.props.instances,
      instancesIds,
      this.props.points,
      this.props.currentDrawing?.drawingId,
    );
    const zoomedBounds = DrawingsGeometryUtils.zoomAndRotateBounds(
      bounds,
      this.zoomHandler.getContext().zoom,
      this.props.rotation,
      width,
      height,
    );
    this.viewportHelper.updateSelectedBounds(bounds, zoomedBounds);
  }


  private updateSelectionBounds(): void {
    if (DrawingsCommonUtils.isBooleanDrawMode(this.props.drawMode)) {
      this.updateSelectionBoundsWithId(this.props.instanceToOperateWith);
    } else if (this.props.selectedInstances.length) {
      this.updateSelectionBoundsWithId(this.props.selectedInstances);
    } else if (this.props.selectedUserAnnotations.length) {
      const bounds = this.viewportHelper.drawingLayoutApi.getAnnotationsBounds(this.props.selectedUserAnnotations);
      if (bounds) {
        const { width, height } = this.viewportHelper.currentPageInfo;
        const zoomedBounds = DrawingsGeometryUtils.zoomAndRotateBounds(
          bounds,
          this.zoomHandler.getContext().zoom,
          this.props.rotation,
          width,
          height,
        );
        this.viewportHelper.updateSelectedBounds(bounds, zoomedBounds);
      } else {
        this.viewportHelper.updateSelectedBounds(null, null);
      }
    } else {
      this.viewportHelper.updateSelectedBounds(null, null);
    }
  }

  @autobind
  private saveDrawingLayoutRef(ref: HTMLDivElement): void {
    this.drawingsLayoutRef = ref;
    this.setPaneConfig(this.props.isOpenDrawingsAnnotationLegendPanel);
  }

  @autobind
  private onCanvasFocus(): void {
    this.drawingsLayoutRef.focus();
  }

  @autobind
  private openDrawingsAnnotationLegendPanel(): void {
    this.setPaneConfig(!this.props.isOpenDrawingsAnnotationLegendPanel);
    this.props.toggleOpenDrawingsAnnotationLegendPanel();
  }

  @autobind
  private scrollToInstances(instancesIds: Array<string | [string, string]>, ignoreSetting?: boolean): void {
    const instancesByDrawing: Record<string, string[]> = this.groupInstancesByDrawings(instancesIds);
    if (Object.keys(instancesByDrawing).length) {
      this.scrollToDrawingInstances(instancesByDrawing, ignoreSetting);
    }
  }

  private scrollToDrawingInstances(instancesByDrawing: Record<string, string[]>, ignoreSetting?: boolean): void {
    const { currentDrawing, isAutofocusEnabled, tryCancelDrawMode } = this.props;
    if (currentDrawing && currentDrawing.drawingId in instancesByDrawing) {
      if (isAutofocusEnabled || ignoreSetting) {
        this.scrollToInstanceById(instancesByDrawing[currentDrawing.drawingId]);
      } else {
        this.viewportHelper.boundsToScroll = null;
      }
    } else {
      tryCancelDrawMode(() => this.selectDrawingAndScrollToInstances(instancesByDrawing));
    }
  }

  private groupInstancesByDrawings(instancesIds: Array<string | [string, string]>): Record<string, string[]> {
    const { instances, aiAnnotation: { pointsInfo } } = this.props;
    return InstancesUtils.groupInstancesIdsByDrawings(instancesIds, instances, pointsInfo);
  }

  private selectDrawingAndScrollToInstances(instancesByDrawing: Record<string, string[]>): void {
    const { selectedPages } = this.props;
    const targetDrawingId = Object.keys(instancesByDrawing)[0];
    this.viewportHelper.boundsToScroll = {
      bounds: this.getInstancesBounds(instancesByDrawing[targetDrawingId]),
      onlyScroll: false,
    };
    if (!selectedPages.includes(targetDrawingId)) {
      this.props.addPageTab(targetDrawingId);
    }
    this.props.onSelectDrawing(targetDrawingId);
    this.props.selectInstances(instancesByDrawing[targetDrawingId]);
  }

  @autobind
  private scrollToPointOnDrawing({ x, y }: { x: number, y: number }, drawingId: string): void {
    if (!this.props.drawings[drawingId]) {
      return;
    }
    this.scrollToBoundInDrawing({ x, y, width: 0, height: 0, center: { x, y } }, drawingId, true);
  }

  @autobind
  private focusTextSearchResult(id: number, drawingId: string): void {
    const item = this.props.textSearchResults[drawingId].find(x => x.id === id);
    const bounds = DrawingsGeometryUtils.getPointsBounds(item.rectangleBounds);
    this.viewportHelper.drawingLayoutApi.focusTextSearchResults(drawingId, id);
    this.scrollToBoundInDrawing(
      bounds,
      drawingId,
      true,
    );
  }

  private scrollToBoundInDrawing(bounds: DrawingsBounds, drawingId: string, onlyScroll: boolean): void {
    if (drawingId === this.props.currentDrawing?.drawingId) {
      this.scrollToBounds(bounds, onlyScroll);
    } else {
      this.viewportHelper.boundsToScroll = {
        bounds,
        onlyScroll: true,
      };
      if (!this.props.selectedPages.includes(drawingId)) {
        this.props.addPageTab(drawingId);
      }
      this.props.onSelectDrawing(drawingId);
    }
  }

  @autobind
  private getInstancesBounds(ids: string[]): DrawingsBounds {
    return DrawingsGeometryUtils.getInstancesBounds(this.props.instances, ids, this.props.points);
  }

  private scrollToBounds(bounds: DrawingsBounds, onlyScroll?: boolean): void {
    const settings = { bounds, onlyScroll };
    this.viewportHelper.scrollToDrawingInstances(settings)
      .then((value) => {
        this.props.setDrawingRendered(value);
      });
  }

  private scrollToInstanceById(ids: string[], onlyScroll?: boolean): void {
    const bounds = this.getInstancesBounds(ids);
    this.scrollToBounds(bounds, onlyScroll);
  }

  @autobind
  private isContainsInGroups(instanceIds: string[]): boolean {
    if (!instanceIds || !instanceIds.length) {
      return false;
    }

    const instancesMap = new Set(instanceIds);
    for (const group of this.props.drawingGeometryGroups) {
      for (const instance of group.measurements) {
        if (instancesMap.has(instance)) {
          return true;
        }
      }
    }
    return false;
  }

  @autobind
  private selectInstancesGroupsAndInstances(instanceIds: string[]): void {
    const { drawingGeometryGroups } = this.props;
    const instanceIdsSet = new Set(instanceIds);
    const groupIds = [];
    const parentIdGroups = [];
    drawingGeometryGroups.forEach(item => {
      const measurementsFilter = item.measurements.filter(id => !instanceIdsSet.has(id));
      if (!measurementsFilter.length) {
        groupIds.push(item.id);
      } else {
        if (item.parentId) {
          parentIdGroups.push(item.parentId);
        }
      }
    });
    const parentIdGroupSet = new Set(parentIdGroups);
    const groupsToSelect = groupIds.filter(id => !parentIdGroupSet.has(id));
    this.props.setSelected(instanceIds, groupsToSelect);
  }

  private shouldSkipDrawingRendering(drawing: DrawingsShortInfo): boolean {
    const { loadStatus } = this.props;
    return !drawing
      || !this.canViewMeasurement
      || loadStatus === RequestStatus.Failed
      || (drawing.isOptimized && !RasterizationFileUtils.isRasterizationReady(drawing));
  }

  @autobind
  private selectDrawing(drawing: DrawingsShortInfo): void {
    const { ability, drawingRendered } = this.props;
    const canUseWizzard = ability.can(Operation.Manage, Subject.Takeoff2DMeasurement) &&
      ability.can(Operation.Manage, Subject.Takeoff2dWizzardAccess);

    if (drawing && canUseWizzard) {
      this.props.getDrawingStatistic(drawing.drawingId);
    }
    if (this.shouldSkipDrawingRendering(drawing)) {
      if (drawingRendered) {
        this.props.setDrawingRendered(false);
        this.setState({ loadingSnapping: false });
      }
      this.viewportHelper.removeDrawing();
      return;
    }

    if (this.canViewUserAnnotations) {
      this.props.getUserAnnotations(drawing.drawingId);
    }

    this.props.setSelectItemsMode(false);
    this.snappingObserver.updateContext(null);
    this.props.saveCurrentDrawingSnapping(null);
    this.props.setDrawMode(DrawingsDrawMode.Disabled, { ignoreCancelMessage: true });
    this.props.setDrawingRendered(false);
    this.setState({ loadingSnapping: false }, () => {
      this.viewportHelper.dropLayoutSettings();
      this.selectDrawingSetStateCallback(drawing);
    });
  }

  @autobind
  private selectDrawingSetStateCallback(drawing: DrawingsShortInfo): void {
    const { drawings, files } = this.props;
    if (drawing.isOptimized && !RasterizationFileUtils.isRasterizationReady(drawing)) {
      return;
    }

    if (drawing.pagesCompareSettings) {
      drawing.pagesCompareSettings.isActive = this.props.canUseCompare;
    }
    if (drawing.isOptimized) {
      drawing.isOptimized = this.props.canUseOptimizer;
    }
    this.drawingsOpener
      .selectDrawing(drawing, drawings, files.entities as Record<string, DrawingsFile>)
      .then(drawingRendered => this.props.setDrawingRendered(drawingRendered))
      .catch((error) => {
        console.error(error);
        this.props.markDrawingWithRenderProblems(drawing.drawingId);
      });
  }

  private renderMainPdfLayout(rotation: number): Promise<void> {
    this.viewportHelper.removeDefaultCanvas();
    return this.viewportHelper.renderMainPdfLayout(rotation)
      .then(drawingRendered => this.props.setDrawingRendered(drawingRendered));
  }

  private hasRenderProblem(): boolean {
    const { currentDrawing } = this.props;
    if (currentDrawing) {
      return !currentDrawing.isOptimized && currentDrawing.hasRenderProblems;
    }
    return false;
  }

  @autobind
  private setPaneConfig(isOpenDrawingsAnnotationLegendPanel: boolean): void {
    if (this.drawingsLayoutRef) {
      const containerWidth = this.drawingsLayoutRef.clientWidth;
      const drawingSize = this.props.splitterPaneSize || ANNOTATION_LEGEND_PANEL_DEFAULT_WIDTH;
      const annotationLegendWidth = isOpenDrawingsAnnotationLegendPanel ? drawingSize : 0;
      const annotationLegendMinWidth = isOpenDrawingsAnnotationLegendPanel ? ANNOTATION_LEGEND_PANEL_MIN_WIDTH : 0;
      const paneConfigs = [
        { size: annotationLegendWidth, minSize: annotationLegendMinWidth, maxSize: ANNOTATION_LEGEND_PANEL_MAX_WIDTH },
        { size: containerWidth - annotationLegendWidth, minSize: CANVAS_CONTAINER_MIN_WIDTH },
      ];
      this.setState({ paneConfigs });
    }
  }

  @autobind
  private getInstancesMeasures(
    instancesIds: Iterable<string | string[]>,
    drawings: Record<string, DrawingsShortInfo>,
    aiAnnotation: DrawingsGeometryState,
    elementMeasurements: Record<string, DrawingsInstanceMeasure>,
  ): DrawingsInstanceMeasure[] {
    const drawingsInstancesHelper = !this.state.drawingsInstancesValueHelper
      ? getDrawingsInstanceMeasureHelper(this.viewportHelper.drawingLayoutApi).getInstancesMeasures
      : this.state.drawingsInstancesValueHelper.getInstancesMeasures;

    const { measures, measuresForSave } = drawingsInstancesHelper(
      instancesIds,
      drawings,
      aiAnnotation,
      (id) => this.measurementsCache[id] || elementMeasurements[id],
    );

    if (measuresForSave.length) {
      this.deferredSaveMeasures(measuresForSave);
    }
    return measures;
  }

  // hotfix KREOP-12728
  @autobind
  private deferredSaveMeasures(measurements: DrawingsInstanceMeasure[]): void {
    measurements.forEach(x => {
      const id = Array.isArray(x.id)
        ? DrawingAnnotationUtils.getLineKey(...x.id as [string, string])
        : x.id;
      this.measurementsCache[id] = x;
    });

    this.deferredSaveMeasurementsExecuter.execute(() => {
      this.props.instancesMeasuresUpdated(Object.values(this.measurementsCache));
      this.measurementsCache = {};
    });
  }

  @autobind
  private calculateCurrentPageGroupMeasures(groupId: string, instancesIds: string[]): DrawingsGroupMeasure | null {
    const { instances, currentDrawing } = this.props;
    const result: DrawingsGroupMeasure = {
      area: 0,
      perimeter: 0,
      pxArea: 0,
      pxPerimeter: 0,
      length: 0,
      pxLength: 0,
      count: 0,
      pointsCount: 0,
      segmentsCount: 0,
    };
    const currentPageInstances = arrayUtils.filterIterator(
      instancesIds,
      instanceId => instances[instanceId] && instances[instanceId].drawingId === currentDrawing.drawingId,
    );
    for (const measure of this.getInstanceMeasureInner(currentPageInstances)) {
      if (this.props.instances[measure.id as string].type !== DrawingsInstanceType.Count) {
        result.count++;
      }
      for (const [key, value] of Object.entries(measure.measures)) {
        result[key] += value;
      }
    }
    this.props.cacheGroupMeasure({ [groupId]: result });
    return result;
  }

  // hotfix KREOP-12728
  @autobind
  private getInstanceMeasureInner(
    instancesIds: Iterable<string | string[]>,
  ): DrawingsInstanceMeasure[] {
    const { drawings, aiAnnotation, elementMeasurement } = this.props;
    return this.getInstancesMeasures(
      instancesIds,
      drawings,
      aiAnnotation,
      elementMeasurement,
    );
  }

  @autobind
  private getSegmentMeasures(
    pointIds: [string, string],
    drawings: Record<string, DrawingsShortInfo>,
    aiAnnotation: DrawingsGeometryState,
    elementMeasurements: Record<string, DrawingsInstanceMeasure>,
  ): DrawingsInstanceMeasure | null {
    if (!this.state.drawingsInstancesValueHelper) {
      return null;
    }
    const { measure, shouldSave } = this.state.drawingsInstancesValueHelper.getSegmentMeasures(
      pointIds,
      drawings,
      aiAnnotation,
      (id) => this.measurementsCache[id] || elementMeasurements[id],
    );

    if (measure && shouldSave) {
      this.deferredSaveMeasures([measure]);
    }

    return measure;
  }

  @autobind
  private toggleMarqueeZoom(): void {
    this.props.tryCancelDrawMode(() => {
      const { marqueeZoomEnabled } = this.state;
      const { drawMode } = this.props;
      this.props.setSelectItemsMode(false);
      this.setState({ marqueeZoomEnabled: !marqueeZoomEnabled }, () => {
        this.props.setDrawMode(
          this.state.marqueeZoomEnabled ? DrawingsDrawMode.Disabled : drawMode, { ignoreCancelMessage: true });
      });
    });
  }

  @autobind
  private saveDrawingApi(api: DrawingLayoutApi): void {
    this.viewportHelper.drawingLayoutApi = api;
    this.props.sendApi({
      scrollToDrawingInstances: this.scrollToInstances,
      getInstancesMeasures: this.getInstancesMeasures,
      getInstancesMeasuresSimple: this.getInstanceMeasureInner,
      getSegmentMeasures: this.getSegmentMeasures,
      changeEntitiesColor: this.changeEntitiesColor,
      getDrawMode: this.getDrawMode,
      scrollToPositionOnDrawing: this.scrollToPointOnDrawing,
      changeGroupColor: this.changeGroupColor,
      onInstancesMeasuresUpdated: this.deferredSaveMeasures,
      getViewport: () => this.viewportHelper,
      focusTextSearchResult: this.focusTextSearchResult,
      getCallbacks: () => ({
        addInstancesToCellOnCreate: this.props.addInstancesToCellOnCreate,
        onInstancesAdd: this.props.onInstancesAdd,
        onInstancesRemove: this.props.onInstancesRemove,
      }),
      saveMeasuresOnCreate: this.saveMeasuresOnCreate,
    });
    this.setState({
      drawingsInstancesValueHelper: getDrawingsInstanceMeasureHelper(api),
    });
  }

  @autobind
  private saveMeasuresOnCreate(
    geometries: DrawingsGeometryInstance[],
    points: Record<string, ShortPointDescription>,
  ): void {
    const { scale, metersPerPixel } = this.state;
    const getPointInfo = (pointId): ShortPointDescription => points[pointId] || this.props.points[pointId];
    const measuresForSave = new Array<DrawingsInstanceMeasure>();
    for (const instance of geometries) {
      const measures = DrawingsGeometryUtils.calculateInstanceMeasurements(
        instance,
        scale,
        metersPerPixel,
        getPointInfo,
      );
      measuresForSave.push({
        type: instance.type,
        id: instance.id,
        measures,
        color: instance.geometry.color,
      });
    }
    this.props.addInstancesToCellOnCreate(measuresForSave);
  }


  @autobind
  private changeGroupColor(color: string, groupId: string): void {
    this.legendApi.changeGroupColor(color, groupId);
  }

  @autobind
  private getDrawMode(): DrawingsDrawMode {
    return this.props.drawMode;
  }

  @autobind
  private changeEntitiesColor(ids: string[], color: string): void {
    this.viewportHelper.drawingLayoutApi.changeEntitiesColor(ids, color);
  }

  @autobind
  private syncGroupSelection(deselectedMeasurementId: string): void {
    const parentId = this.props.instances[deselectedMeasurementId].groupId;
    if (parentId) {
      const closestGroup = this.props.drawingGeometryGroups.find(
        group => group.id === parentId,
      );
      this.syncParentGroupSelection(closestGroup, this.props.selectedGroups);
    }
  }

  @autobind
  private syncParentGroupSelection(
    deselectedGroup: DrawingsGeometryGroup,
    selectGroupIds: string[],
  ): void {
    const groupsMap = arrayUtils.toDictionary(this.props.drawingGeometryGroups, group => group.id, group => group);
    const groupsToDeselect = DrawingsGroupUtils
      .getGroupsToDeselect({ group: deselectedGroup, groupsMap, selectedGroups: this.props.selectedGroups });
    const groupsToDeselectSet = new Set(groupsToDeselect);
    const selectGroups = selectGroupIds.filter(id => !groupsToDeselectSet.has(id));

    this.props.setSelectGeometryGroup(selectGroups);
  }


  private loadSnapping(): void {
    this.setState({ loadingSnapping: true }, () => {
      const drawingId = this.props.currentDrawing?.drawingId;
      getSnappingWithProgress(
        this.props.currentDrawing,
        this.viewportHelper.drawingInfo,
        (status) => this.onProcessingChange(status, drawingId),
      ).then(doc => {
        this.setSnapping(drawingId, doc.snapping);
        if (this.props.currentDrawing?.drawingId === drawingId) {
          this.setState({ loadingSnapping: false });
        }
      });
    });
  }

  @autobind
  private setSnapping(drawingId: string, snapping: DrawingSnapping): void {
    if (!snapping) {
      this.props.markDrawingsWithoutSnapping(drawingId);
    }
    if (drawingId === this.props.currentDrawing?.drawingId) {
      this.snappingObserver.updateContext(snapping);
      this.props.saveCurrentDrawingSnapping(snapping);
    }
  }
}

function mapStateToProps({ drawings, persistedStorage }: State): StateProps {
  return {
    rotation: drawings.currentDrawingInfo?.rotationAngle || 0,
    drawings: drawings.drawingsInfo,
    instances: drawings.aiAnnotation.geometry as Record<string, DrawingsGeometryInstanceWithId>,
    selectedInstances: drawings.selectedInstances,
    currentDrawing: drawings.currentDrawingInfo,
    loadStatus: drawings.loadStatus,
    files: drawings.files,
    drawingGeometryGroups: drawings.drawingGeometryGroups,
    points: drawings.aiAnnotation.points,
    colors: drawings.aiAnnotation.additionalColors,
    elementMeasurement: drawings.elementMeasurement,
    filteredElements: drawings.filteredElementIds,
    selectedPages: drawings.selectedPages,
    selectedGroups: drawings.selectGeometryGroup,
    groupForInstanceCreation: drawings.drawingGeometryGroups.find(item => item.id === drawings.selectGeometryGroup[0]),
    aiAnnotation: drawings.aiAnnotation,
    selectedUserAnnotations: drawings.userAnnotations.selectedAnnotations,
    isOpenDrawingsAnnotationLegendPanel: persistedStorage.isOpenDrawingsAnnotationLegendPanel,
    splitterPaneSize: persistedStorage.splitterPaneSize && persistedStorage.splitterPaneSize[DrawingLayoutSize],
    textSearchResults: drawings.textSearch.results.results,
    drawingRendered: drawings.drawingRenderedStatus,
    currentDrawingStatistic: drawings.currentDrawingInfo
      ? drawings.drawingStatistic[drawings.currentDrawingInfo.drawingId]
      : null,
    boostWithAi: drawings.wizzardTools.boostWithAi,
    instanceToOperateWith: drawings.instanceToOperateWith,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
  const callOverridden =
    <T extends (...args: any[]) => any>(override: T, methodToOverride: T, ...args: any[]): void => {
      if (!ownProps.overrideCallBacks || !override) {
        dispatch(methodToOverride(...args));
      }
      if (override) {
        override(...args);
      }
    };

  return {
    setDrawingRendered: (value: boolean) => dispatch(DrawingsActions.setDrawingRendered(value)),
    openUnsavedChangesDialog: (accept, cancel) =>
      dispatch(KreoDialogActions.openDialog(DrawingDialogs.UNSAVED_CHANGES_DIALOG, { accept, cancel })),
    getUserAnnotations: pageId => dispatch(DrawingsUserAnnotationActions.getAnnotations(pageId)),
    addPageTab: pageId => dispatch(DrawingsActions.addTabs({ entityIds: [pageId], openLastPage: true })),
    onSelectDrawing: drawingId => {
      dispatch(DrawingsActions.selectDrawing(drawingId));
      dispatch(DrawingsActions.saveSelectedDrawingsState(drawingId));
    },
    removeInstances: (instancesIds, changes) => {
      callOverridden(ownProps.onInstancesRemove, DrawingsAnnotationActions.removeInstances, instancesIds);
      dispatch(DrawingsUpdateActions.commitUpdates(changes, DrawingChangeSource.Elements));
    },
    pageRescale: (data) => dispatch(DrawingsActions.pagesRescale(data)),
    onFinishEditAnnotation: (pointsUpdated, measures) => {
      dispatch(DrawingsAnnotationActions.updatePoint(pointsUpdated, measures));
    },
    getAnnotationData: (projectId, documentId, pageId) =>
      dispatch(DrawingsAnnotationActions.getAnnotationData(projectId, documentId, pageId)),
    instancesMeasuresUpdated: (measures: DrawingsInstanceMeasure[]) => {
      callOverridden(ownProps.onInstancesMeasuresUpdated, DrawingsActions.saveMeasurements, measures);
      dispatch(TwoDElementViewActions.handleSaveElementMeasurements(measures));
    },
    openDialog: (dialogName, data?) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    closeDialog: (dialogName) => dispatch(KreoDialogActions.closeDialog(dialogName)),
    setSelected: (instanceIds, groupIds) =>
      dispatch(DrawingsAnnotationLegendActions.updateSelection({ instanceIds, groupIds })),
    setSelectGeometryGroup: (groupIds) => dispatch(DrawingsAnnotationLegendActions.updateSelection({ groupIds })),
    toggleOpenDrawingsAnnotationLegendPanel: () => {
      dispatch(PersistedStorageActions.toggleOpenDrawingsAnnotationLegendPanel());
    },
    createPreset: (preset: TablePreset) => dispatch(TwoDActions.createTablePreset(preset)),
    updatePreset: (preset: TablePreset) => dispatch(TwoDActions.updateTablePreset(preset)),
    getTablePresets: () => dispatch(TwoDActions.getTablePresets()),
    cacheGroupMeasure: (measures) => {
      dispatch(DrawingsUserAnnotationActions.saveGroupMeasure(measures));
    },
    saveSplitterSize: (size: number) => {
      dispatch(PersistedStorageActions.saveSplitterSize(DrawingLayoutSize, size));
    },
    markDrawingsWithoutSnapping: (drawingId) => {
      dispatch(DrawingsActions.markDrawingWithoutSnapping(drawingId));
    },
    markDrawingWithRenderProblems: (drawingId) => {
      dispatch(DrawingsActions.markDrawingWithRenderProblems(drawingId));
    },
    clearCopilotHistory: () => {
      dispatch(TwoDCopilotActions.reset());
    },
    saveCurrentDrawingSnapping: (snapping: DrawingSnapping) => {
      dispatch(DrawingsActions.setCurrentDrawingSnappings(snapping));
    },
    trackProgress: (progressKey: string, progressBar: ProgressBarState) => {
      dispatch(ProgressActions.addOrUpdateProgress({ progressKey, progressBar }));
    },
    removeProgress: (progressKey: string) => {
      dispatch(ProgressActions.removeProgress(progressKey));
    },
    showOptimizeMessage: (value: boolean) => {
      dispatch(DrawingsActions.showOptimizeMessage(value));
    },
    saveHoverState: (hoverState) => dispatch(DrawingsActions.setHoverState(hoverState)),
    dropWizzardState: () => dispatch(WizzardToolsActions.dropDropperState()),
    getDrawingStatistic: (drawingId) => dispatch(WizzardToolsActions.getPdfStatistic(drawingId)),
  };
}

const reduxConnect = connect(mapStateToProps, mapDispatchToProps)(DrawingsLayoutComponent);

export const DrawingsLayout = connectToDrawingsSelectionContext(withDrawingsLayoutContextWrapper(reduxConnect));
