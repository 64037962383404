import { Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { RadioButton } from 'common/components/radion-button';
import { FilterElement } from '../../../drawings-pdf-browser-filter';
import { Styled } from './styled';

interface Props {
  selected: boolean;
  filterCategoryName: string;
  filter: FilterElement;
  onClick: (filterCategoryName: string, type: string) => void;
}

export class Item extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { selected, filter } = this.props;
    return (
      <Styled.Container
        onClick={this.onClick}
      >
        <RadioButton checked={selected} />
        <Styled.Title>
          <Text>{filter.name}</Text>
        </Styled.Title>
      </Styled.Container>
    );
  }

  @autobind
  private onClick(): void {
    const { onClick, filterCategoryName, filter } = this.props;
    onClick(filterCategoryName, filter.type);
  }
}
