import convert from 'convert-units';

import { mathUtils } from './math-utils';


export enum UnitCategory {
  Quantity = 1,
  Mass,
  Length,
  Area,
  Volume,
  Percentage,
  Currency,
}

export enum UnitTypes {
  M3 = 'm3',
  M2 = 'm2',
  M = 'm',
  Cm3 = 'cm3',
  Cm2 = 'cm2',
  Cm = 'cm',
  Mm3 = 'mm3',
  Mm2 = 'mm2',
  Mm = 'mm',

  Nr = 'Nr',
  Box = 'Box',
  WithoutUnit = 'Without unit',

  Radian = 'radian',
  Degrees = 'deg',
  KgPerM = 'kg/m',

  Kg = 'kg',
  Tonne = 'tonne',
  T = 't',
  USTon = 'USTon',
  Lb = 'lb',

  Yd3 = 'yd3',
  Yd2 = 'yd2',
  Yd = 'yd',
  Ft3 = 'ft3',
  Ft2 = 'ft2',
  Ft = 'ft',
  In3 = 'in3',
  In2 = 'in2',
  In = 'in',

  Percentage = '%',
  USD = '$',
  GBP = '£',
  EUR = '€',
}

export const MetricUnitConversationMap = {
  [UnitTypes.M]: UnitTypes.Ft,
  [UnitTypes.M2]: UnitTypes.Ft2,
  [UnitTypes.M3]: UnitTypes.Yd3,
  [UnitTypes.Kg]: UnitTypes.Lb,
  [UnitTypes.Nr]: UnitTypes.Nr,
};

export const TWODUnitConversionMap = {
  ...MetricUnitConversationMap,
  [UnitTypes.M3]: UnitTypes.Ft3,
};

export const ImperialUnitConversationMap = Object.entries(MetricUnitConversationMap)
  .reduce((result, [metric, imperial]) => (result[imperial] = metric) && result, {});

const SiToUnitConversionMap = {
  [UnitTypes.M]: [UnitTypes.Mm, UnitTypes.Cm, UnitTypes.M, UnitTypes.In, UnitTypes.Ft, UnitTypes.Yd],
  [UnitTypes.M2]: [UnitTypes.Mm2, UnitTypes.Cm2, UnitTypes.M2, UnitTypes.In2, UnitTypes.Ft2, UnitTypes.Yd2],
  [UnitTypes.M3]: [UnitTypes.Mm3, UnitTypes.Cm3, UnitTypes.M3, UnitTypes.In3, UnitTypes.Ft3, UnitTypes.Yd3],
  [UnitTypes.Kg]: [UnitTypes.T, UnitTypes.USTon, UnitTypes.Lb],
};

const UnitsLibFormats = {
  [UnitTypes.T]: 'mt',
  [UnitTypes.USTon]: 't',
};

export const UnitCategoriesMap: Record<UnitCategory, UnitTypes[]> = {
  [UnitCategory.Quantity]: [UnitTypes.Nr, UnitTypes.Box, UnitTypes.WithoutUnit],
  [UnitCategory.Mass]: [UnitTypes.Kg, UnitTypes.Lb, UnitTypes.Tonne, UnitTypes.T, UnitTypes.USTon],
  [UnitCategory.Length]: [UnitTypes.M, UnitTypes.Cm, UnitTypes.Mm, UnitTypes.Ft, UnitTypes.In, UnitTypes.Yd],
  [UnitCategory.Area]: [UnitTypes.M2, UnitTypes.Cm2, UnitTypes.Mm2, UnitTypes.Ft2, UnitTypes.In2, UnitTypes.Yd2],
  [UnitCategory.Volume]: [UnitTypes.M3, UnitTypes.Cm3, UnitTypes.Mm3, UnitTypes.Ft3, UnitTypes.In3, UnitTypes.Yd3],
  [UnitCategory.Percentage]: [UnitTypes.Percentage],
  [UnitCategory.Currency]: [UnitTypes.USD, UnitTypes.GBP, UnitTypes.EUR],
};

export const UnitCategoriesSiMap: Record<UnitCategory, UnitTypes> = {
  [UnitCategory.Quantity]: UnitTypes.WithoutUnit,
  [UnitCategory.Mass]: UnitTypes.Kg,
  [UnitCategory.Length]: UnitTypes.M,
  [UnitCategory.Area]: UnitTypes.M2,
  [UnitCategory.Volume]: UnitTypes.M3,
  [UnitCategory.Percentage]: UnitTypes.Percentage,
  [UnitCategory.Currency]: null,
};

export const UnitToCategoryMap = Object.entries(UnitCategoriesMap)
  .reduce((result, [category, units]) => {
    units.forEach(unit => result[unit] = Number(category));
    return result;
  }, {});

export const UnitToSiConversionMap = Object.entries(SiToUnitConversionMap)
  .reduce((result, [siUnit, units]) => {
    units.forEach(unit => result[unit] = siUnit);
    return result;
  }, {});

const supNumber = {
  '0': '⁰',
  '1': '¹',
  '2': '²',
  '3': '³',
  '4': '⁴',
  '5': '⁵',
  '6': '⁶',
  '7': '⁷',
  '8': '⁸',
  '9': '⁹',
};

export interface UnitConversionResult {
  value: number;
  unit: string;
}

const nonConvertibleUnit = [UnitTypes.Nr, 'rad', UnitTypes.Box, UnitTypes.WithoutUnit];

function getFixedPosition(type: UnitTypes): number {
  switch (type) {
    case UnitTypes.Kg:
    case UnitTypes.Nr:
    case UnitTypes.Percentage:
      return 0;
    case UnitTypes.Radian:
    case UnitTypes.Degrees:
      return 1;
    case UnitTypes.Tonne:
      return 3;
    case UnitTypes.M:
    case UnitTypes.M2:
    case UnitTypes.M3:
    case UnitTypes.KgPerM:
    default:
      return 2;
  }
}

function round(value: number, type: UnitTypes): number {
  return mathUtils.round(value, getFixedPosition(type));
}

function isValidUnit(unit: string): boolean {
  return !nonConvertibleUnit.includes(unit);
}


function toUnderstandableUnit(unit: string): string {
  return UnitsLibFormats[unit] || unit;
}


function convertUnit(value: number | string, originalUnit: string, secondaryUnit?: string): UnitConversionResult {
  try {
    if (secondaryUnit && isValidUnit(secondaryUnit)) {
      const result = convert(value)
        .from(toUnderstandableUnit(originalUnit))
        .to(toUnderstandableUnit(secondaryUnit));
      return { value: mathUtils.floor(result, 8), unit: secondaryUnit };
    }
  } catch {
    // temp for staging.
    // console.error('Invalid unit conversion', originalUnit, secondaryUnit);
  }

  return { value: Number(value), unit: originalUnit };
}

function getSupUnit(unit: string): string {
  return unit && unit.replace(/\d/g, (d) => supNumber[d]);
}


const fractional = {
  0: '',
  1: '⅛',
  2: '¼',
  3: '⅜',
  4: '½',
  5: '⅝',
  6: '¾',
  7: '⅞',
};

const INCHES_IN_FOOT = 12;
const ROUNDING_INCHES_DIVIDER = 8;


function convertLengthToImperial(value: number, type: UnitTypes): [number, number] {
  const converted = convertUnit(value, type, UnitTypes.In);
  let feet = Math.floor(converted.value / INCHES_IN_FOOT);
  const inches = converted.value % INCHES_IN_FOOT;
  let rounded = round(inches, type);
  if (rounded === INCHES_IN_FOOT) {
    feet++;
    rounded = 0;
  }
  return [feet, rounded];
}

function convertImperialLengthToSi([feet, inches]: [number, number]): number {
  return convertUnit(feet * INCHES_IN_FOOT + inches, UnitTypes.In, UnitTypes.M).value;
}

function toString(value: number, type: UnitTypes, withoutUnit?: boolean): string {
  return withoutUnit ?
    round(value, type).toString()
    : `${round(value, type)} ${getSupUnit(type)}`;
}

function lengthToString(value: number, type: UnitTypes, isImperialUnit?: boolean, withoutUnit?: boolean): string {
  if (isImperialUnit && TWODUnitConversionMap[type]) {
    const imperialValues = convertLengthToImperial(value, type);
    const feet = imperialValues[0];
    let inches = imperialValues[1];
    const fractionalValue = Math.round((inches - Math.floor(inches)) * ROUNDING_INCHES_DIVIDER);
    const noFractionalPart = fractionalValue === ROUNDING_INCHES_DIVIDER;
    inches = Math.floor(inches) + Number(noFractionalPart);
    return `${feet ? `${feet}' -` : ''}  ${inches}${noFractionalPart ? '"' : ` ${fractional[fractionalValue]}"`}`;
  } else {
    return toString(value, type, withoutUnit);
  }
}


function measureToString2d(value: number, type: UnitTypes, isImperialUnit: boolean, withoutUnit?: boolean): string {
  if (isImperialUnit && TWODUnitConversionMap[type]) {
    const converted = convertUnit(value, type, TWODUnitConversionMap[type]);
    return withoutUnit ?
      round(converted.value, type).toString()
      : `${round(converted.value, type)} ${getSupUnit(converted.unit)}`;
  } else {
    return toString(value, type, withoutUnit);
  }
}


function getCastedMeasure2d(value: number, type: UnitTypes, isImperialUnit?: boolean): number {
  if (value === null || value === undefined) {
    return value;
  }

  if (isImperialUnit && TWODUnitConversionMap[type]) {
    const converted = convertUnit(value, type, TWODUnitConversionMap[type]);
    return converted.value;
  }

  return value;
}

function unitToString(type: UnitTypes, isImperialUnit?: boolean): string {
  return getSupUnit(isImperialUnit ? MetricUnitConversationMap[type] : type);
}

function getUnitCategory(type: UnitTypes): UnitCategory {
  return UnitToCategoryMap[type];
}

function getCategorySiUnit(category: UnitCategory): UnitTypes {
  return UnitCategoriesSiMap[category];
}

function isCurrencyUnit(unit: UnitTypes): boolean {
  return unit === UnitTypes.GBP
    || unit === UnitTypes.USD
    || unit === UnitTypes.EUR;
}

function isWithoutUnit(unit: UnitTypes): boolean {
  return unit === UnitTypes.WithoutUnit;
}

export const UnitUtil = {
  round,
  measureToString2d,
  lengthToString,
  convertLengthToImperial,
  convertImperialLengthToSi,
  getCastedMeasure2d,
  convertUnit,
  getSupUnit,
  unitToString,

  getUnitCategory,
  getCategorySiUnit,
  isCurrencyUnit,
  isWithoutUnit,
};

