import { CellFocusData } from 'common/components/excel-table';

export enum ReportPageType {
  SpreadSheet = 'Table',
  MeasureView = 'View',
}

export interface ReportPage {
  id: string;
  name: string;
  color: string;
  type: ReportPageType;
}

export interface FocusedCell {
  cell: CellFocusData;
  isCanWrite: boolean;
  isCanRead: boolean;
}

export interface UpdateFocusedCell {
  data: CellFocusData;
  sheetId: string;
}

export interface CellToUpdatePayload {
  cellId: string;
  value: number;
}

export interface ReportPageRow {
  index: number;
  [key: string]: string | number;
}

export interface ReportPageData {
  rows: ReportPageRow[];
  columns: Array<{}>;
  etag: number;
}

export interface FormulaBar {
  value: string;
  isFocused: boolean;
}

export interface UpdateCellForm {
  rowId: string;
  columnId: string;
  value: any;
  prevValue?: any;
}

export enum AddNewSheetStatuses {
  IN_ACTIVE = 'IN_ACTIVE',
  LOADING = 'LOADING',
}
