import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import { convertPropertiesIterator } from 'unit-2d-database/components/side-panel/helpers/convert-properties-iterator';
import { Property } from 'unit-2d-database/interfaces';
import { FormulaDialogData } from '../../formula-dialog';
import { FormulaFieldInput } from '../../item-panel/helpers';
import { mapPropertyGroupForm } from '../../item-panel/helpers/map-property-group-form';
import { Field } from '../../item-panel/interfaces';
import { MeasureForm } from '../interfaces';
import { AssemblyUpdater } from './assembly-form-updater/assembly-form-updater';
import { getAllAddedPropertiesNames } from './get-all-added-properties-names';
import { getNotAddedPropertiesIdByNamesIterator } from './get-non-added-properties-id-by-names-iterator';

const getChangeValueHandler = (
  assemblyName: string,
  itemName: string,
  groupName: string,
  updater: AssemblyUpdater,
  field: Field<FormulaFieldInput>,
  propertiesDatabase: Property[],
  form: MeasureForm,
  openDialog: (data: FormulaDialogData) => void,
  units: string[],
) => (value: string, propertiesToAdd: string[]): void => {
  const propertyGroup = form.assemblyGroupForm[assemblyName].assemblyGroups.itemGroupForm[itemName].itemForm.groupForm;
  const groupsForm = mapPropertyGroupForm(
    convertPropertiesIterator(
      getNotAddedPropertiesIdByNamesIterator(
        propertiesToAdd,
        propertyGroup,
        propertiesDatabase,
      ),
      propertiesDatabase,
    ),
    units,
    updater.getItemGroupUpdater(assemblyName).getPropertyGroupUpdater(itemName),
    (id, propertyGroupName) => handleAssemblyFormulaFieldClick(form, updater, openDialog, propertiesDatabase, units)
    (assemblyName, itemName, id, propertyGroupName),
    ConstantFunctions.doNothing,
    (property) => property.name,
  );
  const newField = {
    ...field,
    input: {
      ...(field.input as FormulaFieldInput),
      value,
    },
  };
  updater.changeFormula(assemblyName, itemName, groupsForm, newField, groupName);
};

type AssemblyFormulaFieldClickCallback = (
  assemblyName: string,
  itemName: string,
  propertyId: string,
  groupName: string,
) => void;

export function handleAssemblyFormulaFieldClick(
  form: MeasureForm,
  updater: AssemblyUpdater,
  openDialog: (data: FormulaDialogData) => void,
  propertiesDatabase: Property[],
  units: string[],
): AssemblyFormulaFieldClickCallback {
  return (assemblyName, itemName, propertyId, groupName) => {
    const field = form
      .assemblyGroupForm[assemblyName].assemblyGroups
      .itemGroupForm[itemName]
      .itemForm.groupForm[groupName]
      .fields.find(f => f.id === propertyId) as Field<FormulaFieldInput>;
    const handleChangeValue = getChangeValueHandler(
      assemblyName,
      itemName,
      groupName,
      updater,
      field,
      propertiesDatabase,
      form,
      openDialog,
      units,
    );
    const itemGroupForms = Object.values(form.assemblyGroupForm).map(a => a.assemblyGroups.itemGroupForm);
    const itemProperties = getAllAddedPropertiesNames(itemGroupForms);
    openDialog({
      canAddProperties: true,
      propertyName: field.name,
      itemProperties,
      formulaValue: field.input.value,
      onChangeValue: handleChangeValue,
    });
  };
}
