import autobind from 'autobind-decorator';

import React from 'react';

import './activity-group-item.scss';

import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
  DropTargetSpec,
} from 'react-dnd';
import { TitleEditable } from 'common/components/title-editable/index';
import { ComponentDisplayType } from 'common/enums/component-display-type';
import { DndItem } from 'common/enums/dnd-item';
import { KreoIconDragAndDrop, KreoIconGroupingSmall } from 'common/UIKit';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { ActivityGroupData } from '../../interfaces';


const getDisplayType = (props: Props): string => {
  if (props.wasChanged) {
    return ComponentDisplayType.WasChanged;
  }

  if (props.isActive) {
    return ComponentDisplayType.Active;
  }
  if (props.selectedActivitiesIsOver ||
    props.activityIsOver ||
    props.activityGroupIsOver && props.canDropActivityGroup
  ) {
    return ComponentDisplayType.ActiveDropTarget;
  }
  if (props.isDragging) {
    return ComponentDisplayType.Disabled;
  }

  return ComponentDisplayType.Normal;
};

interface ActivityGroupDndTargetProps {
  connectActivityGroupDropTarget: ConnectDropTarget;
  canDropActivityGroup: boolean;
  activityGroupIsOver: boolean;
}

interface ActivityDndTargetProps {
  connectActivityDropTarget: ConnectDropTarget;
  canDropActivity: boolean;
  activityIsOver: boolean;
}

interface SelectedActivitiesDndTargetProps {
  connectSelectedActivitiesDropTarget: ConnectDropTarget;
  canDropSelectedActivities: boolean;
  selectedActivitiesIsOver: boolean;
}

interface DndTargetsProps extends
  ActivityDndTargetProps,
  SelectedActivitiesDndTargetProps,
  ActivityGroupDndTargetProps {}

interface DndSourceProps {
  connectDragSource: ConnectDragSource;
  connectDragPreview: ConnectDragPreview;
  isDragging: boolean;
}

interface DndProps extends DndTargetsProps, DndSourceProps {}

interface OwnProps {
  id: number;
  wasChanged: boolean;
  name: string;
  activitiesCount: number;
  isActive: boolean;
  isContainSelectedActivities: boolean;
  draggingActivity: ActivityGroupData.Activity | null;
  draggingActivityGroup: ActivityGroupData.ActivityGroup | null;

  onClick: (activityGroupId: number) => void;
  renameActionGroup: (activityGroupId: number, name: string) => void;
  setDraggingActivityGroup: (activityGroupId: number | null) => void;
  replaceActivity: (activityId: number, activityGroupId: number) => void;
  replaceSelectedActivities: (activityGroupId: number) => void;
  mergeActivityGroups: (destinationId: number, mergedId: number) => void;
  removeActivityGroup: (activityGroupId: number) => void;
  onHover: (groupId: number | null) => void;
}

interface Props extends OwnProps, DndProps {}

class ActivityGroupItemComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const props = this.props;
    const component = (
      <div
        className={`activity-grouping-item ${getDisplayType(this.props)}`}
        onClick={this.clickOnActivityGroup}
        data-id={this.props.id}
        onMouseOver={this.onMouseOver}
        // onMouseLeave={this.onMouseLeave}
      >
        <div className='activity-grouping-item__container'>
          {
            this.props.isContainSelectedActivities ? (
              <span className='activity-grouping-item__contain-selected-activities-icon'/>
            ) : null
          }
          {this.props.connectDragSource(
            <span className='activity-grouping-item__draggable-indicator'>
              <KreoIconDragAndDrop/>
            </span>)
          }
          <div className='activity-grouping-item__icon-group'>
            <KreoIconGroupingSmall/>
          </div>
          <div className='activity-grouping-item__name'>
            <TitleEditable
              label={props.name}
              text={props.name}
              onChange={this.renameActivityGroup}
              canEdit={true}
              isEditByIconClick={true}
              sizeSmall={true}
            />
          </div>
          {
            props.activitiesCount
              ? (
                <div className='activity-grouping-item__activities-count'>
                  {this.props.activitiesCount}
                </div>
              )
              : (
                <div
                  className='activity-grouping-item__controls'
                >
                  <ItemMenu
                    menu={this.getMenuProps()}
                  />
                </div>
              )
          }
        </div>
      </div>
    );

    const activityDropTarget = this.props.connectActivityDropTarget(component);
    const selectedActivitiesDropTarget = this.props.connectSelectedActivitiesDropTarget(activityDropTarget);
    const activityGroupDropTarget = this.props.connectActivityGroupDropTarget(selectedActivitiesDropTarget);
    return this.props.connectDragPreview(activityGroupDropTarget);
  }

  @autobind
  private clickOnActivityGroup(): void {
    const selectedActivityGroupId = !this.props.isActive
      ? this.props.id
      : null;
    this.props.onClick(selectedActivityGroupId);
  }

  @autobind
  private renameActivityGroup(name: string): void {
    this.props.renameActionGroup(this.props.id, name);
  }

  @autobind
  private removeActivityGroup(): void {
    this.props.removeActivityGroup(this.props.id);
  }

  @autobind
  private onMouseOver(): void {
    if (this.props.onHover) {
      this.props.onHover(this.props.id);
    }
  }

  @autobind
  private getMenuProps(): ItemMenuType[] {
    const menu: ItemMenuType[] = [];

    if (this.props.activitiesCount === 0) {
      menu.push({
        name: 'Remove',
        action: this.removeActivityGroup,
      });
    }

    return menu;
  }
}

const sourceSpec: DragSourceSpec<OwnProps> = {
  beginDrag: (props: OwnProps): OwnProps => {
    props.setDraggingActivityGroup(props.id);
    return props;
  },
  endDrag: (props: OwnProps): void => {
    props.setDraggingActivityGroup(null);
  },
};

const sourceCollector = (connector: DragSourceConnector, monitor: DragSourceMonitor): DndSourceProps => {
  return {
    connectDragSource: connector.dragSource(),
    connectDragPreview: connector.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const activityTargetCollector = (
  connector: DropTargetConnector, monitor: DropTargetMonitor,
): ActivityDndTargetProps => {
  return {
    connectActivityDropTarget: connector.dropTarget(),
    canDropActivity: monitor.canDrop(),
    activityIsOver: monitor.isOver(),
  };
};

const selectedActivitiesTargetCollector = (
  connector: DropTargetConnector, monitor: DropTargetMonitor,
): SelectedActivitiesDndTargetProps => {
  return {
    connectSelectedActivitiesDropTarget: connector.dropTarget(),
    canDropSelectedActivities: monitor.canDrop(),
    selectedActivitiesIsOver: monitor.isOver(),
  };
};

const activityGroupTargetCollector = (
  connector: DropTargetConnector, monitor: DropTargetMonitor,
): ActivityGroupDndTargetProps => {
  return {
    connectActivityGroupDropTarget: connector.dropTarget(),
    canDropActivityGroup: monitor.canDrop(),
    activityGroupIsOver: monitor.isOver(),
  };
};

const activityTargetSpec: DropTargetSpec<OwnProps> = {
  drop: (props: OwnProps): void => {
    props.replaceActivity(props.draggingActivity.id, props.id);
  },
  canDrop: (props: OwnProps): boolean => {
    return props.draggingActivity && props.draggingActivity.activityGroupId !== props.id;
  },
};

const selectedActivitiesTargetSpec: DropTargetSpec<OwnProps> = {
  drop: (props: OwnProps): void => {
    props.replaceSelectedActivities(props.id);
  },
};

const activityGroupTargetSpec: DropTargetSpec<OwnProps> = {
  drop: (props: OwnProps): void => {
    if (props.id !== props.draggingActivityGroup.id) {
      props.mergeActivityGroups(props.id, props.draggingActivityGroup.id);
    }
  },
  canDrop: (props: OwnProps): boolean => {
    return props.draggingActivityGroup && props.draggingActivityGroup.id !== props.id;
  },
};

const activityTargetContext = DropTarget(
  DndItem.Activity, activityTargetSpec, activityTargetCollector);
const selectedActivitiesTargetContext = DropTarget(
  DndItem.SelectedActivities, selectedActivitiesTargetSpec, selectedActivitiesTargetCollector);
const activityGroupContext = DropTarget(
  DndItem.ActivityGroup, activityGroupTargetSpec, activityGroupTargetCollector);

const sourceContext = DragSource(DndItem.ActivityGroup, sourceSpec, sourceCollector);

const selectedActivitiesTarget = selectedActivitiesTargetContext(ActivityGroupItemComponent);
const activityTarget = activityTargetContext(selectedActivitiesTarget);
const activityGroupTarget = activityGroupContext(activityTarget);
const SourceActivityGroupItem = sourceContext(activityGroupTarget);
export const ActivityGroupItem = SourceActivityGroupItem;
