import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import './ag-grid.scss';

import Ag from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import autobind from 'autobind-decorator';
import React from 'react';

import { arrayUtils } from 'common/utils/array-utils';
import { AgGridHelper } from '../../ag-grid';
import { CodeInputCellEdit, NameTextareaCellEditor } from './cell-editors';
import {
  ButtonCell,
  NameColumnCell,
  NameTextareaCellRenderer,
  TypeColumnCell,
  UserCell,
  UserFilterCell,
} from './cell-renders';
import { PlusButtonCell } from './cell-renders/plus-button-cell';
import { Constants } from './constants';
import { getNameValue } from './helpers';
import { DataBaseTableProps, NameColumnValue, OwnState, RowData } from './interfaces';
import { Styled } from './styled';

export const getDataPath = (data: RowData): string[] => {
  return data.h_path.split(Constants.DATA_PATH_SPLITER);
};

const agGridComponents = {
  nameColumnCell: NameColumnCell,
  userCell: UserCell,
  userFilterCell: UserFilterCell,
  typeColumnCell: TypeColumnCell,
  plusButtonCell: PlusButtonCell,
  exportButtonCell: ButtonCell,
  codeInputCellEdit: CodeInputCellEdit,
  nameTextareaCellEditor: NameTextareaCellEditor,
  nameTextareaCellRenderer: NameTextareaCellRenderer,
};

export class DataBaseTable<TContext> extends React.PureComponent<DataBaseTableProps<TContext>, OwnState> {
  public constructor(props: DataBaseTableProps<TContext>) {
    super(props);

    this.state = {
      autoColumnDef: {
        headerCheckboxSelection: props.headerCheckboxSelection,
        headerName: props.autoGroupName,
        field: 'h_path',
        sort: props.sort,
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: 'nameColumnCell',
          checkbox: this.props.selectByCheckbox,
        },
        filterValueGetter: this.getAutoColumnFilterValue,
        comparator: this.sortComparator,
        menuTabs: this.props.hideColumnsMenuTab
          ? ['generalMenuTab', 'filterMenuTab']
          : ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        editable: this.isEditable,
        onCellValueChanged: this.onNameCellValueChanged,
        rowDrag: this.isRowDragEnabled,
        rowDragText: this.getRowDragText,
        valueGetter: this.autoGroupColumnValueGetter,
        valueSetter: this.trySetAutoColumnValue,
        minWidth: this.props.autoGroupColumnMinWidth,
        maxWidth: this.props.autoGroupColumnMaxWidth,
      },
      gridApi: null,
    };
  }

  public render(): JSX.Element {
    const {
      rowData,
      onRowSelected,
      selectByCheckbox,
      defaultColumns,
      enableMultiSelect,
      suppressRowClickSelection,
      getRowNodeId,
      context,
    } = this.props;
    const { autoColumnDef } = this.state;

    return (
      <Styled.Container className='ag-theme-balham-dark'>
        <AgGridReact
          treeData={true}
          rowData={rowData}
          getDataPath={getDataPath}
          columnDefs={defaultColumns}
          defaultColDef={Constants.DEFAULT_COL_DEF}
          autoGroupColumnDef={autoColumnDef}
          headerHeight={Constants.HEADER_HEIGHT}
          rowHeight={Constants.ROW_HEIGHT}
          components={agGridComponents}
          animateRows={true}
          onRowDragEnd={this.onRowDragEnd}
          suppressCellSelection={true}
          rowSelection={enableMultiSelect ? 'multiple' : 'single'}
          suppressClipboardPaste={true}
          onSelectionChanged={onRowSelected}
          getContextMenuItems={this.getContextMenuItems}
          stopEditingWhenGridLosesFocus={true}
          onGridReady={this.onGridReady}
          excludeChildrenWhenTreeDataFiltering={selectByCheckbox}
          getMainMenuItems={this.props.columnsMenu ? this.getMainMenuItems : undefined}
          suppressColumnVirtualisation={true}
          suppressRowClickSelection={suppressRowClickSelection}
          getRowNodeId={getRowNodeId}
          onRowClicked={this.onRowClicked}
          context={context}
          onFirstDataRendered={this.setSelectedRowGroupExpand}
        />
      </Styled.Container>
    );
  }

  public componentDidUpdate(prevProps: DataBaseTableProps<TContext>, prevState: OwnState): void {
    const { autoGroupName, selectByCheckbox, hideColumnsMenuTab, columns } = this.props;

    if (this.state.gridApi && this.state.gridApi !== prevState.gridApi) {
      this.setSelectedRowGroupExpand();
    }

    if (prevProps.autoGroupName !== autoGroupName
      || prevProps.selectByCheckbox !== selectByCheckbox
      || prevProps.hideColumnsMenuTab !== hideColumnsMenuTab
    ) {
      this.setState(this.applyPropsToAutoGroupColumnDef() as OwnState);
    }
    if ((this.state.gridApi && columns !== prevProps.columns) || this.state.gridApi !== prevState.gridApi) {
      const defaultColumns = this.props.defaultColumns || [];
      this.state.gridApi.setColumnDefs([...defaultColumns, ...columns]);
    }
  }

  @autobind
  private setSelectedRowGroupExpand(): void {
    const { selectedNodeId } = this.props;

    if (this.state.gridApi) {
      this.state.gridApi.forEachNode(node => {
        if (node.id === selectedNodeId) {
          let parentNode = node.parent;
          while (parentNode) {
            parentNode.expanded = true;
            parentNode = parentNode.parent;
          }

          if (node.rowIndex !== null) {
            this.setScrollSelectedRowVisible(node);
          }
        }
      });
    }
  }

  @autobind
  private setScrollSelectedRowVisible(node: any): void {
    const rowNodeIndex = node?.rowIndex;
    this.state.gridApi.ensureIndexVisible(rowNodeIndex, 'top');
  }

  @autobind
  private getMainMenuItems(): Array<string | Ag.MenuItemDef> {
    return this.props.columnsMenu;
  }

  @autobind
  private onRowClicked(event: Ag.RowClickedEvent): void {
    if (this.props.onRowClicked) {
      this.props.onRowClicked(event.data);
    }
  }

  private applyPropsToAutoGroupColumnDef(): Partial<OwnState> {
    const { autoGroupName, selectByCheckbox, hideColumnsMenuTab } = this.props;
    return {
      autoColumnDef: {
        ...this.state.autoColumnDef,
        headerName: autoGroupName,
        cellRendererParams: { ...this.state.autoColumnDef.cellRendererParams, checkbox: selectByCheckbox },
        menuTabs: hideColumnsMenuTab
          ? ['generalMenuTab', 'filterMenuTab']
          : ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
      },
    };
  }

  private getAutoColumnFilterValue(params: Ag.ValueGetterParams): string {
    return params.data?.name || params.node.groupData['ag-Grid-AutoColumn'];
  }

  @autobind
  private isRowDragEnabled(params: { data: RowData }): boolean {
    return this.props.enableRowDrag && params.data && params.data.h_isDragSource;
  }

  private getRowDragText({ defaultTextValue }: { defaultTextValue: NameColumnValue }): string {
    return getNameValue(defaultTextValue);
  }

  private trySetAutoColumnValue(params: Ag.ValueSetterParams): boolean {
    if (!params.newValue.trim() || params.oldValue === params.newValue) {
      return false;
    }
    params.data.name = params.newValue;
    return true;
  }

  @autobind
  private onGridReady(event: Ag.GridReadyEvent): void {
    if (this.props.onGridReady) {
      this.props.onGridReady(event.api, event.columnApi);
      this.setState({ gridApi: event.api });
    }
  }

  @autobind
  private isEditable(params: Ag.IsColumnFuncParams): boolean {
    const isGroup = params.data && params.data.h_isDragTarget;
    if (!isGroup) {
      return this.props.isEditElement && !!this.props.onChangeElementName;
    }
    return isGroup && !!this.props.onChangeElementName;
  }

  @autobind
  private autoGroupColumnValueGetter(params: Ag.ValueGetterParams): NameColumnValue | string {
    if (!params.data) {
      return { name: params.node.groupData['ag-Grid-AutoColumn'], icon: '' };
    }

    if (params.data.h_isDragTarget) {
      return params.data.name;
    }

    const editingCells = params.api?.getEditingCells();

    if (editingCells && editingCells.length && editingCells.find(c => c.rowIndex === params.node.rowIndex)) {
      return params.data.name;
    }

    return { name: params.data.name, icon: params.data.h_name_icon };
  }

  @autobind
  private onNameCellValueChanged(
    params: { oldValue: NameColumnValue, newValue: NameColumnValue, api: Ag.GridApi, node: Ag.RowNode },
  ): void {
    this.props.onChangeElementName(params.node.data.id, params.node.data.name);
  }

  @autobind
  private onRowDragEnd(event: Ag.RowDragEvent): void {
    const overNode = event.overNode;
    const sourceId = event.node.data.id as string;
    const selectedNodeIds = event.api.getSelectedNodes().map(n => n.id);
    const isGroup = event.node.data.h_isDragTarget;
    const onDragEnd = this.props.onDragEnd;

    if (!onDragEnd) {
      return;
    }

    if (!overNode) {
      if (!this.props.isDragBeforeEnable && AgGridHelper.isRootNode(event.node.parent)) {
        return;
      }
      onDragEnd('', sourceId, isGroup, false, selectedNodeIds);
      return;
    }

    if (overNode.id === event.node.id) {
      return;
    }

    if (overNode.data.h_isDragTarget) {
      const offset = event.y - overNode.rowTop;
      const isBefore = offset < 10;
      onDragEnd(overNode.data.id, sourceId, isGroup, isBefore, selectedNodeIds);
    }
  }

  @autobind
  private getContextMenuItems(params: Ag.GetContextMenuItemsParams): Array<Ag.MenuItemDef | string> {
    const { extendContextMenuItem } = this.props;
    const items: Array<Ag.MenuItemDef | string> = ['autoSizeAll', 'expandAll', 'contractAll', 'resetColumns'];
    return extendContextMenuItem ? extendContextMenuItem(items, params) : items;
  }

  @autobind
  private sortComparator(valueA: NameColumnValue, valueB: NameColumnValue): number {
    const nameA = getNameValue(valueA);
    const nameB = getNameValue(valueB);
    return arrayUtils.localCompareWithNumber(nameA, nameB);
  }
}
