import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';

import './graph.scss';

import { GraphItemDisplayType } from './enums';
import { GraphVertex } from './graph-vertex';
import { Coordinate, VertexComponentProps } from './interfaces';
import { PositionPxHelper } from './position-px-helper-provider';

interface Props {
  vertexIds: number[];
  vertexDisplayTypes: Record<number, GraphItemDisplayType> | null;
  vertexPositions: Record<number, Coordinate>;
  positionPxHelper: PositionPxHelper;
  vertexWidth: number;
  vertexMargin: number;
  vertexComponent: React.ComponentType<VertexComponentProps>;
  readonly: boolean;
}


export class GraphVertexes extends React.Component<Props> {
  public shouldComponentUpdate(prevProps: Props): boolean {
    const props = this.props;
    return !isEqual(props.vertexIds, prevProps.vertexIds) ||
      !isEqual(props.vertexPositions, prevProps.vertexPositions) ||
      !isEqual(props.positionPxHelper, prevProps.positionPxHelper) ||
      props.readonly !== prevProps.readonly;
  }

  public render(): JSX.Element {
    const vertexIds = this.props.vertexIds;
    const result = (<>{vertexIds.map(this.renderVertex)}</>);
    return result;
  }

  @autobind
  private renderVertex(id: number): JSX.Element {
    const position = this.props.vertexPositions[id];
    const key = `vertex/${id}/${position.x}/${position.y}`;
    return (
      <GraphVertex
        key={key}
        id={+id}
        readonly={this.props.readonly}
        width={this.props.vertexWidth}
        margin={this.props.vertexMargin}
        vertexComponent={this.props.vertexComponent}
        displayType={this.props.vertexDisplayTypes[id]}
        x={this.props.positionPxHelper.getXPx(position.x)}
        y={this.props.positionPxHelper.getYPx(position.y)}
      />
    );
  }
}

