import { Text, OpenArrowButtonStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';
import { BadgeStyled } from 'common/components/badge';
import { InputSmallStyled } from '../small-input';

const ITEM_HEIGHT = 18;

export const DropDownContainer = styled.div<{ isCollapse: boolean }>`
  display: flex;
  align-items: center;
  height: 24px;
  width: 100%;
  position: relative;
  ${(p) => {
    if (p.isCollapse) {
      return `
        width: 5ch;
        >${InputSmallStyled.InputStyle} {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      `;
    }
    return '';
  }}

  ${OpenArrowButtonStyled.Container} {
    position: absolute;
    right: 10px;
    display: flex;
    z-index: 1;
  }
`;

const DropDownMenuContainer = styled.div`
  width: 210px;
  z-index: 4;
  box-shadow: 0 0 0 1px ${(p) => p.theme.color.pale};
  background-color: ${(p) => p.theme.color.background};
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 5px 5px 0 5px;
`;

const NoResults = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${(p) => p.theme.color.backgroundRush};
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: default;

  ${Text} {
    color: ${(props) => props.theme.color.mainFont};
    font-size: 12px;
    letter-spacing: 0px;
    text-align: left;
    font-family: ProximaSoft;
  }
`;

const ItemText = styled.div<{ isActive: boolean, isFocus: boolean }>`
  align-items: center;
  gap: 5px;
  display: flex;
  outline: none;
  box-sizing: border-box;
  height: ${ITEM_HEIGHT}px;
  margin: 5px 0;
  border-radius: 5px;
  background: ${(p) => p.isActive ? p.theme.color.turquoise: p.theme.color.backgroundRush} 0% 0% no-repeat padding-box;
  ${(p) => {
    if (p.isFocus) {
      return `box-shadow: 0 0 0 2px ${p.theme.color.turquoise}80;`;
    }
    return '';
  }}
  ${BadgeStyled.BadgeContainer} {
    height: 16px;
    border-radius: 5px;
    p {
      font-size: 12px;
      line-height: 1;
    }
  }
  ${Text} {
    text-overflow: ellipsis;
  }
  text-align: left;
  color: ${(p) => p.theme.color.mainFont};
  letter-spacing: 0px;
  opacity: 1;
  line-height: ${ITEM_HEIGHT}px;
  padding-left: 5px;
  &:hover {
    color: ${(p) => p.theme.color.turquoise};
    background-color: ${(p) => p.theme.color.pale};
    cursor: pointer;
  }
`;

const FakeSelectContainer = styled.div`
  ${InputSmallStyled.InputStyle};
`;

export const Styled = {
  DropDownMenuContainer,
  DropDownContainer,
  ITEM_HEIGHT,
  NoResults,
  ItemText,
  FakeSelectContainer,
};
