import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${Constants.Animations.opacityAppear} ${p => p.theme.duration.s} linear;
  button {
    margin-bottom: 20px;
  }
  p {
    width: 180px;
  }
`;

export const Styled = {
  Container,
};
