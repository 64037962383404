export function getValidInputValueNumber(value: string): number | ''  {
  if (value === '') {
    return '';
  }

  const newValue = Number(value);
  const integerValue = Math.trunc(newValue);

  if (integerValue.toString().length <= 10 && (value.split('.')[1] || '').length < 4) {
    return newValue;
  } else {
    return null;
  }
}
