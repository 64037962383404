import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import { StringUtils } from 'common/utils/string-utils';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { CompanyProjectHeader } from 'unit-projects/interfaces/company-project-header';
import { Project } from 'unit-projects/interfaces/project';
import { REMOVE_PROJECT_DIALOG_NAME } from '../menu-project-fast-navigation';


interface DispatchProps {
  closeDialog: (dialogName: string) => void;
}

interface Props extends DispatchProps {
  projectToBeRemoved: CompanyProjectHeader | Project | null;
  type: string;
  removeProject: (projectId: number) => void;
}

class ProjectRemoveDialogComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { projectToBeRemoved, type } = this.props;
    if (!projectToBeRemoved) {
      return null;
    }

    const projectName = projectToBeRemoved.name;
    const dialogText = `${StringUtils.firstUpperLetter(type)} "${projectName}"
    will be removed immediately. This action can’t be undone.`;

    return (
      <ConfirmationDialog
        name={REMOVE_PROJECT_DIALOG_NAME}
        title={`Are you sure you want to delete the ${type}?`}
        text={dialogText}
        confirmButtonText='Delete'
        cancelButtonText='Cancel'
        onConfirm={this.removeProject}
        onCancel={this.onCloseRemoveDialog}
      />
    );
  }

  @autobind
  private removeProject(): void {
    this.props.removeProject(this.props.projectToBeRemoved.id);
  }

  @autobind
  private onCloseRemoveDialog(): void {
    this.props.closeDialog(REMOVE_PROJECT_DIALOG_NAME);
  }
}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    closeDialog: dialogName => dispatch(KreoDialogActions.closeDialog(dialogName)),
  };
};


const connector = connect(null, mapDispatchToProps);

export const ProjectRemoveDialog = connector(ProjectRemoveDialogComponent);
