import { MovableContextMenu } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ItemMenuContent } from './item-menu-content';
import { ContextMenuActions } from './store-slice';
import { ContextMenuState } from './store-slice/interfaces';
import { Styled } from './styled';


export const TwoDItemContextMenu: React.FC = () => {
  const dispatch = useDispatch();
  const {
    contextMenuPositionX,
    contextMenuPositionY,
    menuItems,
    menuWidth,
    sizeItem,
  } = useSelector<State, ContextMenuState>((s) => s.contextMenu);

  const onCloseMenu = useCallback(() => {
    dispatch(ContextMenuActions.drop());
  }, [dispatch]);

  if (!menuItems.length) {
    return null;
  }

  return (
    <MovableContextMenu
      x={contextMenuPositionX}
      y={contextMenuPositionY}
      onClose={onCloseMenu}
    >
      <Styled.ItemMenuContentWrapper>
        <ItemMenuContent
          menuItems={menuItems}
          menuWidth={menuWidth}
          sizeItem={sizeItem}
          onExit={onCloseMenu}
        />
      </Styled.ItemMenuContentWrapper>
    </MovableContextMenu>
  );
};
