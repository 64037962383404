import styled from 'styled-components';
import { OptionContainerStyled } from 'common/components/selection-menus';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  & + & {
    border-top: 10px solid ${p => p.theme.color.pale};
  }
  ${OptionContainerStyled.Container} {
    width: 150px;
    padding: 10px 20px;
    margin-bottom: unset;
  }
  ${OptionContainerStyled.Container} + ${OptionContainerStyled.Container} {
    border-top: 1px solid ${p => p.theme.color.background};
  }
`;

export const Styled = {
  Container,
  Filter,
};
