import {
  AssignedPia,
  AssignedPiaAssembly,
  AssignedPiaItem,
  AssignedPiaProperty,
} from '../../../../../../2d/interfaces';

const calcProperties = (
  prevProps: AssignedPiaProperty[],
  props: AssignedPiaProperty[],
): AssignedPiaProperty[] => {
  const result: AssignedPiaProperty[] = [];
  const usedProps = new Set();
  prevProps.forEach(property => {
    const prop = props.find(p => p.name === property.name);
    const updatedProp = prop ? prop : property;
    if (!updatedProp.value) {
      return;
    }
    usedProps.add(property.name);
    result.push(updatedProp);
  });

  props.forEach(p => {
    if (!usedProps.has(p.name)) {
      result.push(p);
    }
  });

  return result;
};

const getAssignItems = (items: AssignedPiaItem[]): AssignedPiaItem[] => {
  const itemsMap: Record<string, AssignedPiaItem> = {};
  items.forEach(item => {
    if (!item.properties) {
      delete itemsMap[item.name];
      return;
    }
    itemsMap[item.name] = {
      name: item.name,
      iconType: item.iconType,
      properties: itemsMap[item.name]
        ? calcProperties(itemsMap[item.name].properties, item.properties)
        : item.properties,
    };
  });
  return Object.values(itemsMap);
};

const getAssignAssembly = (assemblies: AssignedPiaAssembly[]): AssignedPiaAssembly[] => {
  const assembliesMap: Record<string, AssignedPiaAssembly> = {};
  assemblies.forEach(assembly => {
    if (!assembly.items) {
      delete assembliesMap[assembly.name];
      return;
    }
    assembliesMap[assembly.name] = {
      name: assembly.name,
      items: assembliesMap[assembly.name]
        ? getAssignItems([...assembliesMap[assembly.name].items, ...assembly.items])
        : assembly.items,
    };
  });
  return Object.values(assembliesMap);
};

export const calcAssign = (assignedPia: AssignedPia[]): AssignedPia => {
  let assignAssemblies: AssignedPiaAssembly[] = [];
  let assignItems: AssignedPiaItem[] = [];

  assignedPia.forEach(assign => {
    if (assign.assemblies) {
      assignAssemblies = [...assignAssemblies, ...assign.assemblies];
    }
    if (assign.items) {
      assignItems = [...assignItems, ...assign.items];
    }
  });

  const assemblies: AssignedPiaAssembly[] = getAssignAssembly(assignAssemblies).filter(a => !!a);
  const items: AssignedPiaItem[] = getAssignItems(assignItems).filter(i => !!i);
  return {
    assemblies,
    items,
  };
};
