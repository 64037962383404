import * as React from 'react';

import { Item } from '../item-context-menu/menu-item-container';
import { Company } from '../project-card';
import { MenuButton } from './menu-button';
import { PreviewProject } from './preview-project';
import { ProjectInfo } from './project-info';
import { UserInfo } from './project-row';
import { Styled } from './styled';
import { UsersListContainer } from './users-list-container';


interface ContentProps {
  projectName: string;
  menuItems: Item[];
  users: UserInfo[];
  url: string;
  sizeItem: string;
  menuWidth: number;
  isInaccessibleProject: boolean;
  image: string;
  created: string;
  company: Company;
  isShareWithCompany: boolean;
}

export const ContentRow: React.FC<ContentProps> = React.memo(({
  projectName,
  menuItems,
  users,
  url,
  sizeItem,
  menuWidth,
  isInaccessibleProject,
  image,
  created,
  company,
  isShareWithCompany,
}) => {
  const error = !url;
  return (
    <Styled.RowContainer>
      <PreviewProject
        image={image}
        error={error}
      />
      <ProjectInfo
        projectName={projectName}
        error={error}
        created={created}
      />
      <UsersListContainer
        users={users}
        isTranslucent={isInaccessibleProject}
        company={company}
        isShareWithCompany={isShareWithCompany}
      />
      <MenuButton
        menuItems={menuItems}
        menuWidth={menuWidth}
        sizeItem={sizeItem}
        isTranslucent={isInaccessibleProject}
      />
    </Styled.RowContainer>
  );
});
