import ContentBox from './content-box';
import Viewport from './viewport';


function VirtualState(viewportEl: any, contentEl: any, items: any, itemHeight: any, bufferSize: any) {
  this.viewport = new Viewport(viewportEl);
  this.contentBox = new ContentBox(contentEl, items, itemHeight);
  const state = {
    contentHeight: 0,
    topOffset: 0,
    items: [],
    startIdx: 0,
  };
  this.calculate = function(): any {
    if (itemHeight <= 0) {
      return state;
    }
    const absTopOffset = this.viewport.size().top - this.contentBox.size().top + this.viewport.getScrollTop();
    const visibleHeight = Math.min(this.viewport.size().height, this.viewport.size().height + absTopOffset);

    const visibleItemsCount = Math.ceil(visibleHeight / itemHeight) + 1;
    let startIdx = Math.max(0, Math.floor(absTopOffset / itemHeight));
    let endIdx = startIdx + visibleItemsCount;

    if (bufferSize) {
      startIdx = Math.max(0, startIdx - bufferSize);
      endIdx = endIdx + bufferSize;
    }

    state.contentHeight = this.contentBox.size().height;
    state.topOffset = startIdx * itemHeight;
    state.items = items.slice(startIdx, endIdx);
    state.startIdx = startIdx;

    return state;
  };

  this.reset = function() {
    this.viewport.reset();
    this.contentBox.reset();
  };
}

export default VirtualState;