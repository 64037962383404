import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';

export class NameColumnCell implements Ag.ICellRendererComp {
  private eGui: HTMLDivElement;
  private valueContainer: HTMLSpanElement;
  private params: Ag.ICellRendererParams;

  public refresh(): boolean {
    return false;
  }

  public getGui(): HTMLDivElement {
    this.eGui = document.createElement('div');
    this.eGui.style.height = '100%';
    const data = this.params.data;
    if (data && data.h_name_icon) {
      this.eGui.append(this.createIcon());
    }
    this.createValue();
    this.eGui.append(this.valueContainer);
    setTimeout(this.addedTitleIfNeed, 0);

    return this.eGui;
  }

  public init(params: Ag.ICellRendererParams): void | Ag.AgPromise<void> {
    this.params = params;
  }

  @autobind
  private addedTitleIfNeed(): void {
    if (this.isNeedTitle()) {
      this.eGui.title = this.valueContainer.innerText;
    }
  }

  private isNeedTitle(): boolean {
    const parentCell = this.eGui?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement;
    if (!parentCell) {
      return false;
    }
    return this.valueContainer.getBoundingClientRect().right > parentCell.getBoundingClientRect().right;
  }

  private createIcon(): HTMLSpanElement {
    const container = document.createElement('span');
    container.style.paddingRight = '15px';
    container.innerHTML = this.params.value.icon;
    const svg = container.children[0] as HTMLElement;
    if (svg) {
      svg.style.height = '20px';
      svg.style.width = '20px';
    }

    return container;
  }

  private createValue(): void {
    const container = document.createElement('span');
    container.innerText = typeof this.params.value === 'string'
      ? this.params.value
      : this.params.value.name;
    this.valueContainer = container;
  }
}
