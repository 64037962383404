import { RequestStatus } from 'common/enums/request-status';
import { WizzardStatus } from '../../enums/dropper-state';

export const StatusesMap = {
  [RequestStatus.NotRequested]: WizzardStatus.Preview,
  [RequestStatus.Loading]: WizzardStatus.Loading,
  [RequestStatus.Loaded]: WizzardStatus.Preview,
  [RequestStatus.Failed]: WizzardStatus.Error,
};


export const TextOverrides = {
  [WizzardStatus.Loading]: 'Loading...',
  [WizzardStatus.Error]: 'Try again',
};
