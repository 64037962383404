import autobind from 'autobind-decorator';

import { ModifyHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { EngineObject, EngineObjectConfig } from '../../common';
import { StrictAngleController } from '../../interfaces/helpers';

interface Config extends EngineObjectConfig {
  api: GlobalKeyboardEventsControllerContextProps;
}

export class StrictAngleKeyListenerHelper extends EngineObject<Config> implements StrictAngleController {
  private _enabled: boolean = false;

  public activate(): void {
    const { api } = this._config;
    api.addKeyDownEventListener(ModifyHotkey.StrictAngleMode, this.enableStrictAngleMode);
    api.addKeyUpEventListener(ModifyHotkey.StrictAngleMode, this.disableStrictAngleMode);
  }

  public deactivate(): void {
    const { api } = this._config;
    api.removeKeyDownEventListener(ModifyHotkey.StrictAngleMode, this.enableStrictAngleMode);
    api.removeKeyUpEventListener(ModifyHotkey.StrictAngleMode, this.disableStrictAngleMode);
    this.disableStrictAngleMode();
  }

  public isEnabled(): boolean {
    return this._enabled;
  }

  @autobind
  private enableStrictAngleMode(): void {
    this._enabled = true;
  }

  @autobind
  private disableStrictAngleMode(): void {
    this._enabled = false;
  }
}

