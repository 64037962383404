import { SagaIterator } from 'redux-saga';
import { call, debounce, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { FAILED_UPDATE_REPORT_DIALOG } from '2d/components';
import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { UPGRADE_PRO_PLAN_CONSTANTS } from '2d/components/upgrade-plan';
import { CRASH_DIALOG } from 'common/components/crash-dialog';
import { DrawingsGeometryGroup, DrawingsInstanceMeasure } from 'common/components/drawings';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import { UpdateColumnData } from 'common/components/excel-table';
import { ActionWith } from 'common/interfaces/action-with';
import { KreoDialogActions } from 'common/UIKit';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { Property, TwoDViewTableConfig } from 'unit-2d-database/interfaces';
import { TwoDActions } from '../actions/creators';
import {
  FormWithProjectAndReportIdsPayload,
  MoveToPagePayload,
  ReportPageAddRowsOrColumnsPayload,
  SetNewPagePayload,
  SetNewPageViewPayload,
  SetUploadFilesPayload,
  UpdateCellsPayload,
  UpdatePageInfoPayload,
  UpdateReportQueueMessagePayload,
  UpdateViewConfigPayload,
} from '../actions/payloads';
import { TwoDActionTypes } from '../actions/types';
import { TwoDApi } from '../api';
import { TwoDViewTableType } from '../constants/table-type';
import {
  calculatePiaAssignment,
  getDefaultPrecompiledFormulaApi,
  getElementTotalAssign,
  isItemViewVisible,
  patchPiaData,
} from '../helpers';
import {
  AddNewSheetStatuses,
  AssignedPia,
  PiaCalculated,
  ReportPage,
  TablePreset,
  UpdateCellForm,
} from '../interfaces';
import { AssignPiaPatch } from '../interfaces/pia-patch';

function* getPages({ payload: projectId }: ActionWith<string>): SagaIterator {
  try {
    const data: ReportPage[] = yield call(TwoDApi.getPages, projectId);
    yield put(TwoDActions.setPages(data));
  } catch (error) {
    console.error('two d: Failed to load reports', error, projectId);
  }
}

function* updatePageInfo({ payload }: ActionWith<UpdatePageInfoPayload>): SagaIterator {
  const { projectId, data } = payload;

  try {
    yield call(TwoDApi.updatePageInfo, projectId, data);
  } catch (error) {
    console.error('two d: update page info failed', error, projectId);
  }
}

function* downloadExcelFile({ payload: projectId }: ActionWith<string>): SagaIterator {
  try {
    yield call(TwoDApi.downloadExcelFile, projectId);
  } catch (error) {
    console.error('two d: download excel failed', error, projectId);
  }
}

function* setUploadExcel({ payload }: ActionWith<SetUploadFilesPayload>): SagaIterator {
  const { projectId, files } = payload;
  try {
    yield call(TwoDApi.postFiles, projectId, files);
  } catch (error) {
    console.error('two d: upload excel failed', error, projectId);
  }
}

function* createTable(): SagaIterator {
  try {
    yield put(TwoDActions.setAddNewSheetStatus(AddNewSheetStatuses.LOADING));
    const page: SetNewPagePayload = yield call(TwoDApi.createPage);
    const newPage = { ...page };
    delete newPage.data;
    yield put(TwoDActions.setNewPage(newPage));
    yield put(TwoDActions.setAddNewSheetStatus(AddNewSheetStatuses.IN_ACTIVE));
  } catch (error) {
    console.error('two d: Failed to create page', error);
  }
}


function* createView({ payload: sourceType }: ActionWith<TwoDViewTableType>): SagaIterator {
  try {
    yield put(TwoDActions.setAddNewSheetStatus(AddNewSheetStatuses.LOADING));
    const config: TwoDViewTableConfig = {
      isPivot: false,
      columns: [],
      sourceType,
      filters: {},
      groupIncludeTotalFooter: false,
      allowColumnsConfiguration: true,
      allowDefaultSorting: true,
      createCodeColumn: false,
    };
    const page: SetNewPageViewPayload = yield call(TwoDApi.createView, config);
    const newPage = { ...page };
    delete newPage.configuration;
    yield put(TwoDActions.setNewPage(newPage));
    yield put(TwoDActions.setViewConfig(newPage.id, config));
    yield put(TwoDActions.setAddNewSheetStatus(AddNewSheetStatuses.IN_ACTIVE));
  } catch (error) {
    console.error('two d: Failed to create page', error);
  }
}


function* getViewConfig({ payload }: ActionWith<string>): SagaIterator {
  try {
    const config = yield call(TwoDApi.getViewConfig, payload);
    yield put(TwoDActions.setViewConfig(payload, config.configuration));
  } catch (error) {
    if (error.code === 403 && error.message === 'Has no PIA access') {
      const emptyItemConfig: TwoDViewTableConfig = {
        columns: [],
        isPivot: false,
        filters: {},
        sourceType: TwoDViewTableType.Items,
        groupIncludeTotalFooter: false,
        allowColumnsConfiguration: true,
        allowDefaultSorting: false,
        createCodeColumn: false,
      };
      yield put(TwoDActions.setViewConfig(payload, emptyItemConfig));
      yield put(KreoDialogActions.openDialog(UPGRADE_PRO_PLAN_CONSTANTS.UPGRADE_PLAN_DIALOG));
      return;
    }

    console.error('two d: Failed to load view', payload);
  }
}

function* updateView({ payload }: ActionWith<UpdateViewConfigPayload>): SagaIterator {
  try {
    const { projectId, tableId, configuration } = payload;

    yield put(TwoDActions.setViewConfig(tableId, configuration));
    const pages: ReportPage[] = yield selectWrapper(s => s.twoD.reportPages);
    const page = pages.find(p => p.id === payload.tableId);
    if (page) {
      yield call(TwoDApi.updateView, projectId, tableId, configuration);
    }
  } catch (error) {
    console.error('two d: update view failed', payload);
  }
}

function* deletePage({ payload: pageId }: ActionWith<string>): SagaIterator {
  try {
    const pages = yield call(TwoDApi.deletePage, pageId);
    yield put(TwoDActions.setPages(pages));
  } catch (error) {
    console.error('two d: delete page failed', error, pageId);
  }
}

function* restorePage({ payload }: ActionWith<[string, string]>): SagaIterator {
  const [pageId, sessionId] = payload;
  try {
    const pages = yield call(TwoDApi.restorePage, pageId);
    yield put(TwoDActions.setPages(pages));
    yield put(TwoDActions.setSessionId(pageId, sessionId));
  } catch (error) {
    console.error('two d: restore page failed', error, pageId);
  }
}

function* duplicatePage({ payload: pageId }: ActionWith<string>): SagaIterator {
  try {
    const data: ReportPage[] = yield call(TwoDApi.duplicatePage, pageId);
    yield put(TwoDActions.setPages(data));
  } catch (error) {
    console.error('two d: duplicate page failed', error);
  }
}

function* moveToPage({ payload }: ActionWith<MoveToPagePayload>): SagaIterator {
  const { pageId, offset } = payload;
  try {
    yield call(TwoDApi.moveToPage, pageId, offset);
  } catch (error) {
    console.error('two d: move to page failed', error, pageId);
  }
}

function* addColumns({ payload }: ActionWith<ReportPageAddRowsOrColumnsPayload>): SagaIterator {
  const { projectId, pageId, count } = payload;
  try {
    yield put(TwoDActions.putMessageToUpdateReportQueue<number>(
      TwoDApi.addColumns,
      {
        projectId,
        sheetId: pageId,
        form: count,
      }));
  } catch (error) {
    yield call(hanleInvalidPageUpdate, error, payload);
    console.error('two d: Failed to add columns', error, projectId);
  }
}

function* addRows({ payload }: ActionWith<ReportPageAddRowsOrColumnsPayload>): SagaIterator {
  const { projectId, pageId, count } = payload;
  try {
    yield put(TwoDActions.putMessageToUpdateReportQueue<number>(
      TwoDApi.addRows,
      {
        projectId,
        sheetId: pageId,
        form: count,
      }));
  } catch (error) {
    yield call(hanleInvalidPageUpdate, error, payload);
    console.error('two d: Failed to add rows', error, projectId);
  }
}

function* saveReportCellsUpdates({ payload }:
  ActionWith<UpdateCellsPayload<UpdateCellForm[]>>): SagaIterator {
  try {
    const { reportPagesEtagMap, reportPagesSessionIdMap, pages } = yield selectWrapper(({ twoD }) => {
      return {
        reportPagesEtagMap: twoD.reportPageEtagMap,
        reportPagesSessionIdMap: twoD.reportPageSessionIdMap,
        pages: twoD.reportPages,
      };
    });
    for (const sheetForm of payload.sheets) {
      if (pages.find(p => p.id === sheetForm.sheetId)) {
        yield put(TwoDActions.putMessageToUpdateReportQueue(
          TwoDApi.updateRows,
          {
            projectId: payload.projectId,
            sheetId: sheetForm.sheetId,
            form: sheetForm.form,
            etag: reportPagesEtagMap[sheetForm.sheetId],
            sessionId: reportPagesSessionIdMap[sheetForm.sheetId],
          }));
      }
    }
  } catch (error) {
    console.error('two d: Failed to update report row', error, payload);
  }
}

function* watchUpdateReportQueue(): SagaIterator {
  try {
    const updates: Record<string, Array<UpdateReportQueueMessagePayload<any>>> = {};
    yield takeEvery(
      TwoDActionTypes.PUT_MESSAGE_TO_UPDATE_REPORT_QUEUE,
      function* ({ payload }: ActionWith<UpdateReportQueueMessagePayload<any>>): SagaIterator {
        if (updates[payload.form.sheetId]) {
          updates[payload.form.sheetId].push(payload);
        } else {
          updates[payload.form.sheetId] = [payload];
          yield put(TwoDActions.updateReportQueueMessageDone(payload.form.sheetId));
        }
      },
    );
    yield takeEvery(
      TwoDActionTypes.UPDATE_REPORT_QUEUE_MESSAGE_DONE,
      function* ({ payload: sheetId }: ActionWith<string>): SagaIterator {
        if (updates[sheetId]?.length > 0) {
          yield fork(handleUpdateReportQueueMessage, updates[sheetId].shift());
        } else {
          delete updates[sheetId];
        }
      },
    );
  } catch (error) {
    console.error('two d: update report queue failed', error);
  }
}

function* handleUpdateReportQueueMessage(payload: UpdateReportQueueMessagePayload<any>): SagaIterator {
  const { apiCall, form: { projectId, sheetId: pageId, form } } = payload;

  try {
    const { reportPagesEtagMap: etag, reportPagesSessionIdMap: sessionId } = yield selectWrapper(({ twoD }) => {
      return {
        reportPagesEtagMap: twoD.reportPageEtagMap[pageId],
        reportPagesSessionIdMap: twoD.reportPageSessionIdMap[pageId],
      };
    });

    const result = yield call(apiCall, projectId, pageId, form, etag, sessionId);
    yield put(TwoDActions.setReportPageEtag(pageId, result.etag));
    yield put(TwoDActions.updateReportQueueMessageDone(pageId));
  } catch (error) {
    if (payload.form) {
      yield put(TwoDActions.updateReportQueueMessageDone(pageId));
    }
    yield call(hanleInvalidPageUpdate, error, payload);
    console.error('two d: handle update report queue message failed', error, payload);
  }
}

function* hanleInvalidPageUpdate(error: any, payload: any): SagaIterator {
  if (error.code === 409) {
    yield put(KreoDialogActions.openDialog(FAILED_UPDATE_REPORT_DIALOG, { message: error.message, payload }));
    return;
  }
  yield put(KreoDialogActions.openDialog(FAILED_UPDATE_REPORT_DIALOG, { payload }));
}

function* updateColumnData({ payload }: ActionWith<
  FormWithProjectAndReportIdsPayload<UpdateColumnData[]>
>): SagaIterator {
  try {
    const { reportPagesEtagMap, reportPagesSessionIdMap } = yield selectWrapper(({ twoD }) => {
      return {
        reportPagesEtagMap: twoD.reportPageEtagMap,
        reportPagesSessionIdMap: twoD.reportPageSessionIdMap,
      };
    });
    const sheetId = payload.sheetId;
    yield call(
      TwoDApi.updateColumns,
      payload.sheetId,
      payload.form,
      reportPagesEtagMap[sheetId],
      reportPagesSessionIdMap[sheetId],
    );
  } catch (error) {
    console.error('two d: update column data', error, payload);
  }
}

function* getTablePresets(): SagaIterator {
  try {
    const presets: TablePreset[] = yield call(TwoDApi.getTablePresets);
    yield put(TwoDActions.setTablePresets(presets));
  } catch (error) {
    console.error('two d: Failed to load table presets', error);
  }
}

function* createTablePreset({ payload }: ActionWith<TablePreset>): SagaIterator {
  try {
    yield call(TwoDApi.createTablePresets, [payload]);
  } catch (error) {
    console.error('two d: Failed to create table presets', error);
    yield put(TwoDActions.getTablePresets());
  }
}

function* updateTablePreset({ payload }: ActionWith<TablePreset>): SagaIterator {
  try {
    yield call(TwoDApi.updateTablePresets, [payload]);
  } catch (error) {
    console.error('two d: Failed to update table presets', error);
    yield put(TwoDActions.getTablePresets());
  }
}

function* deleteTablePreset({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(TwoDApi.deleteTablePresets, [payload]);
  } catch (error) {
    console.error('two d: Failed to delete table presets', error);
    yield put(TwoDActions.getTablePresets());
  }
}

function* sendAssignPatch(
  { payload }: ActionWith<[AssignPiaPatch[], () => void, string, () => void, string | number]>,
): SagaIterator {
  const [patchList, callback, iterationId, afterUpdateCallback, projectId] = payload;
  callback();
  try {
    if (patchList && patchList[0]?.addedAssemblies && patchList[0]?.addedAssemblies[0]?.name === 'testError') {
      throw new Error();
    }
    const targetProjectId = projectId || (yield selectWrapper(s => s.projects.currentProject?.id));
    const {
      assignPia,
    } = yield selectWrapper(({ twoD }) => {
      return {
        assignPia: twoD.assignPia,
      };
    });

    const updatedPia: Record<string, AssignedPia> = {};
    for (const patch of patchList) {
      for (const { id, assignedPia } of patchPiaData({ ...assignPia, ...updatedPia }, patch)) {
        updatedPia[id] = assignedPia;
      }
    }
    const totalAssign = { ...assignPia, ...updatedPia };

    for (const patch of patchList) {
      for (const assignId of patch.ids) {
        const assign: AssignedPia = totalAssign[assignId];
        if (patch.updatedAssemblies && assign.assemblies) {
          for (const updateAssembly of patch.updatedAssemblies) {
            const assembly = assign.assemblies.find(a => a.name === updateAssembly.name);
            if (!assembly) {
              const tempPatch: AssignPiaPatch = {
                ids: [assignId],
                deletedAssemblies: [updateAssembly.name],
              };
              patchList.push(tempPatch);
            } else {
              if (updateAssembly.updatedItems) {
                for (const updatedItem of updateAssembly.updatedItems) {
                  const item = assembly.items.find(i => i.name === updatedItem.name);
                  if (!item) {
                    const tempPatch: AssignPiaPatch = {
                      ids: [assignId],
                      updatedAssemblies: [{
                        name: assembly.name,
                        deletedItems: [updatedItem.name],
                      }],
                    };
                    patchList.push(tempPatch);
                  }
                }
              }
            }
          }
        }
        if (patch.updatedItems && assign.items) {
          for (const updatedItem of patch.updatedItems) {
            const item = assign.items.find(i => i.name === updatedItem.name);
            if (!item) {
              const tempPatch: AssignPiaPatch = {
                ids: [assignId],
                deletedItems: [updatedItem.name],
              };
              patchList.push(tempPatch);
            }
          }
        }
      }
    }
    yield call(TwoDApi.sendAssignPatchList, patchList, targetProjectId);

    yield put(TwoDActions.setIsSkipCalcPia(true));
    yield put(TwoDActions.setInstancesAssignPia(updatedPia));
    yield put(TwoDActions.setAssignPiaReady());
    yield put(TwoDActions.setExportExcelReady(iterationId));
  } catch (error) {
    console.error('two d: Failed to patch items pia', error);
    yield put(TwoDActions.getTablePresets());
    yield put(TwoDActions.setExportExcelReady(iterationId));
    yield put(KreoDialogActions.openDialog(CRASH_DIALOG));
  } finally {
    if (afterUpdateCallback) {
      afterUpdateCallback();
    }
  }
}

function* handleSetNewPage(): SagaIterator {
  const reports = yield selectWrapper((state) => state.twoD.reportPages);
  const id = reports[0].id;
  if (reports.length === 1) {
    yield put(TwoDActions.setShowSheetsIds([id]));
    yield put(TwoDActions.setSelectedSheetId(id));
  }
}

function* fetchAssignPia(): SagaIterator {
  try {
    const { assignment } = yield call(TwoDApi.fetchAssignPia);
    yield put(TwoDActions.setAssignPia(assignment));
    const shouldCalcPia = yield selectWrapper((state) => isItemViewVisible(state));
    if (shouldCalcPia) {
      yield put(TwoDElementViewActions.handlePiaDataLoaded(assignment));
      yield put(TwoDActions.setIsSkipCalcPia(false));
    }
    yield put(TwoDActions.setIsSkipCalcPia(true));
  } catch (error) {
    console.error('two d: Failed fetch assign pia', error);
  }
}

function* calculatePia(): SagaIterator {
  const {
    elementMeasurement,
    groups,
    aiAnnotation,
    isImperial,
    originProperties,
    assignPia,
    isSkipCalcPia,
    drawingsInfo,
  } = yield selectWrapper(({
    drawings,
    account,
    twoDDatabase,
    twoD,
  }) => ({
    assignPia: twoD.assignPia,
    elementMeasurement: drawings.elementMeasurement,
    groups: drawings.drawingGeometryGroups,
    aiAnnotation: drawings.aiAnnotation,
    isImperial: account.settings.isImperial,
    originProperties: twoDDatabase.properties,
    isSkipCalcPia: twoD.isSkipCalcPia,
    drawingsInfo: drawings.drawingsInfo,
  }));
  if (!isSkipCalcPia) {
    return;
  }
  const updatedPiaCalculations = getUpdatedPiaCalculations(
    aiAnnotation,
    assignPia,
    groups,
    elementMeasurement,
    isImperial,
    originProperties,
    drawingsInfo,
  );
  yield put(TwoDActions.setCalculatedPia(updatedPiaCalculations));
  yield put(TwoDActions.setIsSkipCalcPia(false));
}

function getUpdatedPiaCalculations(
  aiAnnotation: DrawingsGeometryState,
  assign: Record<string, AssignedPia>,
  groups: DrawingsGeometryGroup[],
  elementMeasurement: Record<string, DrawingsInstanceMeasure>,
  isImperial: boolean,
  originProperties: Property[],
  drawingsInfo: Record<string, DrawingsShortInfo>,
): Record<string, PiaCalculated> {
  const updatedPiaCalculations: Record<string, PiaCalculated> = {};
  const formulaCache = getDefaultPrecompiledFormulaApi();
  for (const id of Object.keys(aiAnnotation.geometry)) {
    const elementAssign = getElementTotalAssign(id, assign, groups, aiAnnotation);
    const elementMeasurements = elementMeasurement[id];
    const instance = aiAnnotation.geometry[id];
    if (!elementMeasurements || !instance) {
      continue;
    }
    const drawingInfo = drawingsInfo[instance.drawingId];
    updatedPiaCalculations[id] = calculatePiaAssignment(
      elementAssign,
      elementMeasurements,
      isImperial,
      originProperties,
      drawingInfo.name,
      formulaCache,
    );
  }

  return updatedPiaCalculations;
}

export const UpdateSendAssignPatchType = 'updateSendAssignPatch';
function* watchSendPiaAssignPatch(): SagaIterator {
  let patchList = [];
  const clearPatchList = (): void => {
    patchList = [];
  };
  yield takeEvery(TwoDActionTypes.SEND_ASSIGN_PATCH, function* (
    { payload }: ActionWith<[AssignPiaPatch[], string, () => void, string | number]>,
  ) {
    const [patches, iterationId, callBack, projectId] = payload;
    patchList.push(...patches);
    yield put({
      type: UpdateSendAssignPatchType,
      payload: [patchList, clearPatchList, iterationId, callBack, projectId],
    });
  });
  yield debounce(1000, UpdateSendAssignPatchType, sendAssignPatch);
}

export function* towDSagas(): SagaIterator {
  yield takeLatest(TwoDActionTypes.GET_PAGES, getPages);
  yield takeLatest(TwoDActionTypes.CREATE_PAGE, createTable);
  yield takeLatest(TwoDActionTypes.UPDATE_PAGE_INFO, updatePageInfo);
  yield takeLatest(TwoDActionTypes.SAVE_REPORT_CELLS_UPDATES, saveReportCellsUpdates);
  yield takeLatest(TwoDActionTypes.ADD_ROWS, addRows);
  yield takeLatest(TwoDActionTypes.ADD_COLUMNS, addColumns);
  yield takeLatest(TwoDActionTypes.DOWNLOAD_EXCEL_FILE, downloadExcelFile);
  yield takeLatest(TwoDActionTypes.DELETE_PAGE, deletePage);
  yield takeLatest(TwoDActionTypes.RESTORE_PAGE, restorePage);
  yield takeLatest(TwoDActionTypes.SET_UPLOAD_EXCEL, setUploadExcel);
  yield takeLatest(TwoDActionTypes.MOVE_TO_PAGE, moveToPage);
  yield takeLatest(TwoDActionTypes.DUPLICATE_PAGE, duplicatePage);
  yield takeEvery(TwoDActionTypes.UPDATE_COLUMN_DATA, updateColumnData);
  yield takeEvery(TwoDActionTypes.SET_NEW_PAGE, handleSetNewPage);

  yield takeLatest(TwoDActionTypes.GET_TABLE_PRESETS, getTablePresets);
  yield takeLatest(TwoDActionTypes.CREATE_TABLE_PRESET, createTablePreset);
  yield takeLatest(TwoDActionTypes.UPDATE_TABLE_PRESET, updateTablePreset);
  yield takeLatest(TwoDActionTypes.DELETE_TABLE_PRESET, deleteTablePreset);

  yield takeLatest(TwoDActionTypes.FETCH_ASSIGN_PIA, fetchAssignPia);

  yield takeLatest(TwoDActionTypes.CREATE_VIEW_REQUEST, createView);
  yield takeEvery(TwoDActionTypes.LOAD_VIEW_CONFIGURATION, getViewConfig);
  yield takeLatest(TwoDActionTypes.UPDATE_VIEW_CONFIGURATION, updateView);

  yield takeEvery(TwoDActionTypes.CALCULATE_PIA, calculatePia);

  yield fork(watchUpdateReportQueue);
  yield fork(watchSendPiaAssignPatch);
}
