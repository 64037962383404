import { InputSelectNew, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { Person } from '../../../../../units/people/interfaces/person';
import { DrawingsAnnotationLegendActions } from '../../actions/creators/drawings-annotation-legend';
import {
  AnnotationFilters,
  FilterChangePayload,
  FilterData,
} from '../../interfaces/drawing-filters';

import { Styled } from './styled';

interface StateToProps {
  filterData: FilterData;
  users: Person[];
  geometryUsers: string[];
  currentUserId: string;
}

interface DispatchToProps {
  changeFilterData: (changeFilterData: FilterChangePayload) => void;
}

interface UserOption {
  name: string;
  value: string;
}

interface OwnState {
  user: UserOption[];
}

type Props = StateToProps & DispatchToProps;

const ALL_LABEL = 'All';

class UsersSelectFilterComponent extends React.PureComponent<Props, OwnState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      user: this.getElements(),
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.users !== prevProps.users) {
      this.setState({ user: this.getElements() });
    }
  }

  public render(): React.ReactNode {

    return (
      <Styled.Set>
        <Styled.SelectContainer>
          <InputSelectNew
            options={this.state.user}
            closeAfterClick={true}
            maxItemsAmount={4}
            selectedOption={this.props.filterData[AnnotationFilters.Users] || null}
            onClick={this.onClick}
            optionRenderer={this.optionRenderer}
            getValueForFilter={this.getValueForFilter}
          />
        </Styled.SelectContainer>
      </Styled.Set>
    );
  }

  private getElements(): UserOption[] {
    const usersDict = arrayUtils.toDictionaryByKey(this.props.users, x => x.id);

    const getUserInfo = (userId: string): { name: string, value: string } => {
      const user = usersDict[userId];
      return { name: `${user.firstName} ${user.lastName}`, value: userId };
    };

    const elements = arrayUtils.filterMap(
      this.props.geometryUsers,
      userId => !!usersDict[userId],
      getUserInfo,
    );
    elements.unshift({ name: ALL_LABEL, value: null });
    return elements;
  }

  private getValueForFilter({ name }: UserOption): string {
    return name;
  }

  @autobind
  private optionRenderer(user: UserOption): React.ReactNode {
    return <Text withEllipsis={true}>{user.name}</Text>;
  }

  @autobind
  private onClick(value: string): void {
    const selectUserId = this.props.filterData[AnnotationFilters.Users];
    if (value === null && selectUserId === null) {
      return;
    }
    const payload: FilterChangePayload = {
      filterType: AnnotationFilters.Users,
      element: value === selectUserId
        ? null
        : value,
    };
    this.props.changeFilterData(payload);
  }
}

const mapStateToProps = (state: State): StateToProps => {
  const { filterData } = state.drawings;
  return {
    filterData,
    users: state.people.companiesUsers,
    geometryUsers: state.drawings.aiAnnotation.geometryCreators,
    currentUserId: state.account.id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchToProps => {
  return {
    changeFilterData: (changeFilterData) =>
      dispatch(DrawingsAnnotationLegendActions.changeFilterData(changeFilterData)),
  };
};

export const UsersSelectFilter = connect(mapStateToProps, mapDispatchToProps)(UsersSelectFilterComponent);
