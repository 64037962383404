import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './model-check-info.scss';

import { State } from 'common/interfaces/state';
import { RoutingContextProps, withRoutingContext } from 'common/routing/with-routing-context';
import { KreoButton } from 'common/UIKit';
import KreoArrowIcon from 'common/UIKit/icons/arrow';
import { openLinkInNewTab } from 'common/utils/open-link-in-new-tab';
import { PlanProjectRouteParams, Qto2dProjectRouteParams } from '../../../../routes/app-routes-params';
import { ModelCheckApi } from '../../api/model-check';

interface StateProps {
  projectName: string;
}

interface DispatchProps {
  goTo: (newLocation: string) => void;
}

interface Props extends StateProps,
  DispatchProps,
  RouteComponentProps<PlanProjectRouteParams | Qto2dProjectRouteParams>,
  RoutingContextProps {}

export class ModelCheckInfoComp extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.loadReport = this.loadReport.bind(this);
  }

  public render(): JSX.Element {

    return (
      <div className='model-check-info'>
        <div className='model-check-info__content'>
          The model has some issues that can distort the calculation results.
          We recommend fixing those issues in Autodesk Revit ®
          <div className='model-check-info__content-buttons'>
            <KreoButton size='small' onClick={this.loadReport}>
              <KreoArrowIcon />
              <span>
                Issue list
                </span>
            </KreoButton>
            <KreoButton size='small' onClick={this.openRequiremets}>
              <KreoArrowIcon />
              <span
              >
                Requirements
                </span>
            </KreoButton>
          </div>
        </div>
        <div className='model-check-info__button-block'>
          <KreoButton
            onClick={this.onViewIssuesClick}
            mode={'toaction'}
            size='medium'
            controlName='model-check-view-issues-button'
          >
            View issues
          </KreoButton>
        </div>
      </div>);
  }

  @autobind
  private onViewIssuesClick(): void {
    const projectId = this.props.match.params.projectId;

    this.props.goTo(this.props.urls.project.modelCheck.view.url({ projectId }));
  }

  private openRequiremets(): void {
    openLinkInNewTab('/static/files/requirements.pdf');
  }

  private loadReport(): void {
    ModelCheckApi.downloadIssuesList();
  }
}

function mapStateToProps(state: State): StateProps {
  const currentProject = state.projects.currentProject;
  return {
    projectName: currentProject ? currentProject.name : null,
  };
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    goTo: (newLocation) => dispatch(push(newLocation)),
  };
}


const connector = connect(mapStateToProps, mapDispatchToProps);
export const ModelCheckInfo = withRoutingContext(withRouter(connector(ModelCheckInfoComp)));
