import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface OwnProps {
  toggleDropDown: () => void;
  isOpen: boolean;
  projectName: string;
}

export class MenuProjectButton extends React.PureComponent<OwnProps> {
  public render(): React.ReactNode {
    const { isOpen, projectName, toggleDropDown } = this.props;
    const isLongName = projectName && projectName.length > 64;

    return (
      <Styled.Container onClick={toggleDropDown}>
        <Styled.ButtonContainer isLongName={isLongName}>
          <RectangleButton
            height={18}
            width={80}
            text={projectName}
          />
        </Styled.ButtonContainer>
        <Styled.IconContainer isOpen={isOpen}>
          <Icons.DownSmall />
        </Styled.IconContainer>
      </Styled.Container>
    );
  }
}
