import { Action } from 'redux';

import { AssignedPia } from '2d/index';
import {
  DrawingsGeometryInstanceWithId,
  DrawingsInstanceMeasure,
} from 'common/components/drawings';
import { ActionWith } from 'common/interfaces/action-with';
import { GeometryGroupsResponse, GeometryInstanceUpdateResponse } from '../../interfaces';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { UpdateGroupInDrawingsPayload } from '../../interfaces/drawings-geometry-instance';
import {
  DrawingsAnnotationUpdatePoint,
  DrawingsGeometryAddFileData,
  DrawingsGeometryAddInstances,
  DrawingsGeometryParameters,
  DrawingsGeometryRenameInstance,
  DrawingsGeometryUpdateParametr,
  DrawingsGeometryUpdateUser,
  DrawingsGetAnnotationPayload,
  DrawingsUpdateAnnotationGeometry,
  ProcessFileDataResult,
} from '../payloads/annotation';
import { DrawingsAnnotationActionTypes } from '../types/annotation';

function saveAiAnnotation(
  pageId:  string,
  response: ProcessFileDataResult,
): ActionWith<DrawingsGeometryAddFileData> {
  return {
    type: DrawingsAnnotationActionTypes.SAVE_AI_ANNOTATION,
    payload: {
      pageId,
      response,
    },
  };
}


function saveAllGeometries(
  response: ProcessFileDataResult,
  groups: GeometryGroupsResponse,
): ActionWith<{ response: ProcessFileDataResult, groups: GeometryGroupsResponse }> {
  return {
    type: DrawingsAnnotationActionTypes.SAVE_ALL_GEOMETRIES,
    payload: {
      response,
      groups,
    },
  };
}

function updatePoint(
  pointsUpdated: Record<string, ShortPointDescription>,
  measures: DrawingsInstanceMeasure[],
): ActionWith<DrawingsAnnotationUpdatePoint> {
  return {
    type: DrawingsAnnotationActionTypes.UPDATE_POINT,
    payload: {
      pointsUpdated,
      measures,
    },
  };
}

function getAnnotationData(projectId: number, pdfId: string, pageId: string): ActionWith<DrawingsGetAnnotationPayload> {
  return {
    type: DrawingsAnnotationActionTypes.GET_ANNOTATION_DATA,
    payload: {
      projectId,
      pdfId,
      pageId,
    },
  };
}

function updateAnnotationGeometry(
  updates: DrawingsUpdateAnnotationGeometry,
): ActionWith<DrawingsUpdateAnnotationGeometry> {
  return {
    type: DrawingsAnnotationActionTypes.UPDATE_GEOMETRY,
    payload: updates,
  };
}

function addInstance(
  instances: DrawingsGeometryInstanceWithId[],
  newPoints: Record<string, ShortPointDescription>,
  pia?: Record<string, AssignedPia>,
): ActionWith<DrawingsGeometryAddInstances> {
  return {
    type: DrawingsAnnotationActionTypes.ADD_INSTANCE,
    payload: {
      instances,
      newPoints,
      pia,
    },
  };
}

function renameInstance(payload: DrawingsGeometryRenameInstance[]): ActionWith<DrawingsGeometryRenameInstance[]> {
  return {
    type: DrawingsAnnotationActionTypes.RENAME_INSTANCES,
    payload,
  };
}

function removeInstances(instancesIds: string[]): ActionWith<string[]> {
  return {
    type: DrawingsAnnotationActionTypes.REMOVE_INSTANCES,
    payload: instancesIds,
  };
}

function loadFullGeometry(): Action {
  return {
    type: DrawingsAnnotationActionTypes.LOAD_FULL_GEOMETRY,
  };
}

function removePagesData(drawingIds: string[]): ActionWith<string[]> {
  return {
    type: DrawingsAnnotationActionTypes.REMOVE_PAGE_DATA,
    payload: drawingIds,
  };
}

function updateGeometryParams<T extends keyof DrawingsGeometryParameters>(
  instancesIds: string[],
  parameter: T,
  value: DrawingsGeometryParameters[T],
): ActionWith<DrawingsGeometryUpdateParametr<T>> {
  return {
    type: DrawingsAnnotationActionTypes.CHANGE_INSTANCES_GEOMETRY_PARAM,
    payload: { instancesIds, parameter, value },
  };
}

function updateUserInInstances(
  changesInfo: GeometryInstanceUpdateResponse,
  createdIds: string[],
  updatedIds: string[],
): ActionWith<DrawingsGeometryUpdateUser> {
  return {
    type: DrawingsAnnotationActionTypes.UPDATE_USER_IN_INSTANCES,
    payload: { changesInfo, createdIds, updatedIds },
  };
}

function updateGroupInInstances(
  data: UpdateGroupInDrawingsPayload[],
): ActionWith<UpdateGroupInDrawingsPayload[]> {
  return {
    type: DrawingsAnnotationActionTypes.UPDATE_GROUP_IN_INSTANCES,
    payload: data,
  };
}


export const DrawingsAnnotationActions = {
  saveAiAnnotation,
  updatePoint,
  getAnnotationData,
  updateAnnotationGeometry,
  addInstance,
  renameInstance,
  removeInstances,
  loadFullGeometry,
  removePagesData,
  updateGeometryParams,
  updateUserInInstances,
  updateGroupInInstances,
  saveAllGeometries,
};
