import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Mood } from 'common/enums/mood';
import { Styled } from './styled';


interface Props {
  onClick: () => void;
  buttonTitle?: string;
  buttonMood?: Mood;
}

export const ContinueButton: React.FC<Props> = ({ buttonTitle, buttonMood, onClick }) => {
  return (
    <Styled.Container>
      <RectangleButton
        Icon={Icons.Right}
        rightIcon={true}
        mood={buttonMood || Mood.Secondary}
        text={buttonTitle || 'Continue'}
        width={150}
        height={50}
        onClick={onClick}
      />
    </Styled.Container>
  );
};
