import { Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';


interface Props {
  text: string;
  colorText?: string;
}

export const DialogText: React.FC<Props> = ({ text, colorText }) => {
  return (
    <Styled.Container>
      <Text color={colorText || 'gray'}>
        {text}
      </Text>
    </Styled.Container>
  );
};
