import { useMemo } from 'react';
import { DemoProject } from 'unit-projects/interfaces/demo-project';

export const useFilteredDemo = (
  demos: DemoProject[],
  selectedTags: string[],
): DemoProject[] => {
  const filteredDemos = useMemo(() => {
    return demos.filter(demo => {
      if (!demo.tags) {
        return true;
      }
      if (!demo.tags.length) {
        return true;
      }
      return demo.tags.some(tag => selectedTags.includes(tag));
    });
  }, [demos, selectedTags]);
  return filteredDemos;
};
