import autobind from 'autobind-decorator';

export class HandlerOpenStatus {
  private getActualIds: () => number[];
  private showIds: (ids: number[], status: boolean) => void;
  private changeSelection: (status: boolean) => void;

  public constructor(
    getActualIds: () => number[],
    showIds: (ids: number[], status: boolean) => void,
    changeSelection: (status: boolean) => void,
  ) {
    this.getActualIds = getActualIds;
    this.showIds = showIds;
    this.changeSelection = changeSelection;
  }

  @autobind
  public changeOpenStatus(status: boolean): void {
    this.showIds(this.getActualIds(), status);
    this.changeSelection(status);
  }
}
