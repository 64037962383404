import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { Notification } from 'common/interfaces/realtime-messages';
import { Alert } from '../interfaces/alert';
import { AblyConnect, ReadNotification } from './payloads';
import { NotificationActionTypes } from '.';

function connectToAbly(data: AblyConnect): ActionWith<AblyConnect> {
  return {
    type: NotificationActionTypes.CONNECT,
    payload: data,
  };
}

function loadNotifications(): Action {
  return {
    type: NotificationActionTypes.LOAD_NOTIFICATIONS_REQUEST,
  };
}

function loadNotificationsSucceeded(notifications: Notification[]): ActionWith<Notification[]> {
  return {
    type: NotificationActionTypes.LOAD_NOTIFICATIONS_SUCCEEDED,
    payload: notifications,
  };
}

function loadNotificationsFailed(): Action {
  return {
    type: NotificationActionTypes.LOAD_NOTIFICATIONS_FAILED,
  };
}

function showNotificationPanel(isOpen: boolean): ActionWith<boolean> {
  return {
    type: NotificationActionTypes.SHOW_NOTIFICATION_PANEL,
    payload: isOpen,
  };
}

function readNotifications(notificationIds: number[], value: boolean): ActionWith<ReadNotification> {
  return {
    type: NotificationActionTypes.READ_NOTIFICATION,
    payload: { notificationIds, value },
  };
}

function addNotification(notification: Notification): ActionWith<Notification> {
  return {
    type: NotificationActionTypes.ADD_NOTIFICATION,
    payload: notification,
  };
}

function deleteNotifications(notificationIds: number[]): ActionWith<number[]> {
  return {
    type: NotificationActionTypes.DELETE_NOTIFICATION,
    payload: notificationIds,
  };
}

function addAlert(alert: Alert): ActionWith<Alert> {
  return {
    type: NotificationActionTypes.ADD_ALERT,
    payload: alert,
  };
}

function deleteAlert(alert: Alert): ActionWith<Alert> {
  return {
    type: NotificationActionTypes.DELETE_ALERT,
    payload: alert,
  };
}

export const NotificationActions = {
  connectToAbly,
  loadNotifications,
  loadNotificationsSucceeded,
  loadNotificationsFailed,
  showNotificationPanel,
  readNotifications,
  addNotification,
  deleteNotifications,
  addAlert,
  deleteAlert,
};
