import classNames from 'classnames';
import * as React from 'react';

import './toggle-area.scss';

import { Toggle, ToggleSize } from '../../../components/toggle';

interface Props {
  isSelected: boolean;
  style?: React.CSSProperties;
  className?: string;
  onChange: (value: boolean) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export class ToggleArea extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { style, isSelected, children, className } = this.props;
    const toggleClassName = classNames(
      'toggle-area',
      {
        'toggle-area--selected': isSelected,
      },
      className,
    );
    return (
    <div
      className={toggleClassName}
      style={style}
      onClick={this.props.onClick}
    >
      <Toggle
        checked={isSelected}
        onCheck={this.props.onChange}
        checkedColor='rgba(255, 255, 255, .4)'
        toggleSize={ToggleSize.Small}
      />
      <div className='toggle-area__text'>
        {children}
      </div>
    </div>);
  }
}
