import Ag from 'ag-grid-community';

import { RowData } from 'common/components/data-base-table/interfaces';

export type PrevDataMap = Record<string, RowData>;

export const getTransaction = (prevRowDataMap: PrevDataMap, newRowData: RowData[]):
{ transaction: Ag.RowDataTransaction, rowDataMap: PrevDataMap } => {
  const transaction: Ag.RowDataTransaction = {
    add: [],
    remove: [],
    update: [],
  };
  const rowDataMap: PrevDataMap = {};

  const update = (prevData: RowData, newData: RowData): void => {
    Object.keys(prevData).forEach(key => {
      prevData[key] = newData[key];
    });
    transaction.update.push(prevData);
    rowDataMap[prevData.h_path] = prevData;
  };

  const add = (newData: RowData): void => {
    transaction.add.push(newData);
    rowDataMap[newData.h_path] = newData;
  };

  newRowData.forEach(data => {
    if (prevRowDataMap[data.h_path]) {
      update(prevRowDataMap[data.h_path], data);
      delete prevRowDataMap[data.h_path];
    } else {
      add(data);
    }
  });

  transaction.remove = Object.values(prevRowDataMap);

  return { transaction, rowDataMap };
};
