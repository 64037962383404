import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { RemoveFolderPayload } from 'unit-projects/actions/payloads/projects-folders';
import { ProjectsFolderActions } from '../actions/creators/projects-folder';
import { ProjectsFolderActionTypes } from '../actions/types/projects-folder';
import { ProjectsFolderApi } from '../api/projects-folder';
import { ProjectsFolder, ProjectsFolderFormData } from '../interfaces/projects-folder';

function* getFolders(): SagaIterator {
  try {
    const folders: ProjectsFolder[] = yield call(ProjectsFolderApi.getFolders);
    yield put(ProjectsFolderActions.setFolders(folders));
  } catch (error) {
    console.error('folders: get folders failed', error);
  }
}

function* createFolder({ payload }: ActionWith<ProjectsFolderFormData>): SagaIterator {
  try {
    const id: number = yield call(ProjectsFolderApi.createFolder, payload);

    const newFolder: ProjectsFolder = payload.parentFolderId ? { id, ...payload } : { id, name: payload.name };
    yield put(ProjectsFolderActions.createFolderSuccess(newFolder));
  } catch (error) {
    console.error('folders: create folder failed', error);
  }
}

function* updateFolder({ payload }: ActionWith<ProjectsFolder>): SagaIterator {
  try {
    const { id, name, parentFolderId } = payload;
    yield call(ProjectsFolderApi.updateFolder, id, { name, parentFolderId });

    const updatedFolder: ProjectsFolder = parentFolderId ? payload : { id, name };
    yield put(ProjectsFolderActions.updateFolderSuccess(updatedFolder));
  } catch (error) {
    console.error('folders: update folder failed', error);
  }
}

function* removeFolder({ payload: { folderId, force } }: ActionWith<RemoveFolderPayload>): SagaIterator {
  try {
    const result = yield call(ProjectsFolderApi.removeFolder, folderId, force);
    if (!result) {
      yield put(ProjectsFolderActions.removeFolderSuccess(folderId));
    } else {
      yield put(ProjectsFolderActions.setRemovingFolderInfo(result));
    }
  } catch (error) {
    console.error('folders: remove folder failed', error);
  }
}

export function* projectsFolderSagas(): SagaIterator {
  yield takeLatest(ProjectsFolderActionTypes.GET_FOLDERS, getFolders);
  yield takeLatest(ProjectsFolderActionTypes.CREATE_FOLDER, createFolder);
  yield takeLatest(ProjectsFolderActionTypes.UPDATE_FOLDER, updateFolder);
  yield takeLatest(ProjectsFolderActionTypes.REMOVE_FOLDER, removeFolder);
}
