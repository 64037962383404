import { ElementTooltip, Icons, IconButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State } from 'common/interfaces/state';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { PersistedStorageActions } from '../../../persisted-storage/actions/creators';

import { Styled } from './styled';

interface StateToProps {
  isAutoMoveToCellEnabled: boolean;
}

interface DispatchToProps {
  toggleAutoMoveToCell: () => void;
}

type Props = StateToProps & DispatchToProps & AbilityAwareProps & AnalyticsProps;

const AutoMoveToCellButtonComponent = ({
  toggleAutoMoveToCell,
  isAutoMoveToCellEnabled,
  ability,
  sendEvent,
}: Props): JSX.Element => {
  const canEditReport = ability.can(Operation.Update, Subject.Takeoff2DReport);
  const clickCallback = useCallback(() => {
    toggleAutoMoveToCell();
    sendEvent(
      MetricNames.transferMeasurements.autoMoveToCell, { status: !isAutoMoveToCellEnabled ? 'Enable' : 'Disable' });
  }, [toggleAutoMoveToCell, sendEvent, isAutoMoveToCellEnabled]);

  return (
    <Styled.Container>
      <ElementTooltip
        text='Auto Move to Cell'
        position='left'
      >
        <IconButton
          Icon={Icons.AutoMoveToCell}
          onClick={clickCallback}
          width={36}
          height={36}
          defaultColor={canEditReport
            ? isAutoMoveToCellEnabled
              ? 'turquoise'
              : 'gray'
            : 'disabled'}
          mood={!canEditReport && 'disabled'}
        />
      </ElementTooltip>
    </Styled.Container>
  );
};

const mapStateToProps = (state: State): StateToProps => {
  return {
    isAutoMoveToCellEnabled: state.persistedStorage.isAutoMoveToCell,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchToProps => {
  return {
    toggleAutoMoveToCell: () => dispatch(PersistedStorageActions.toggleAutoMoveToCell()),
  };
};

export const AutoMoveToCellButton = connect(mapStateToProps, mapDispatchToProps)(
  withAbilityContext(
    withAnalyticsContext(AutoMoveToCellButtonComponent)),
);
