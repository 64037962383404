import { ColumnApi, GridApi, RowNode } from 'ag-grid-community';

import { CommonElementTableProps } from './qto-common-table';
import { SaveStateHelper } from './save-grid-state-helper';

const isExpandChange = (
  prevExpandTable: boolean,
  expandTable: boolean,
): boolean => expandTable !== undefined
  && prevExpandTable !== expandTable;

const expandAllGroupChildren = (node: RowNode): void => {
  if (node.childrenAfterGroup[0].group) {
    node.expanded = true;
    node.childrenAfterGroup.forEach(expandAllGroupChildren);
  }
};

const handleExpandChange = (
  prevProps: CommonElementTableProps,
  props: CommonElementTableProps,
  api: GridApi,
  columnApi: ColumnApi,
  saveStateHelper: SaveStateHelper,
): void => {
  const expandTable = props.expandTable;
  if (isExpandChange(prevProps.expandTable, expandTable)) {
    if (expandTable) {
      api.forEachNode((node) => {
        if (node.group && node.level === 0) {
          expandAllGroupChildren(node);
        }
      });
      api.onGroupExpandedOrCollapsed();
    } else {
      api.collapseAll();
    }
    saveStateHelper.saveState({ api, columnApi });
  }
};

const expandRows = (api: GridApi, rows: Record<number, RowNode>): void => {
  api.forEachNode((node, index) => {
    node.expanded = rows[index] && rows[index].expanded;
  });
  api.onGroupExpandedOrCollapsed();
};

export const ExpandHelper = {
  handleExpandChange,
  expandRows,
};
