import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { ExcelFormulaHelper } from 'common/components/excel-table/utils';
import { SheetDataStore } from '../cell-data-controllers/report-cell-data-store';
import { isSpecKey } from '../excel-table-cell-formatter/common';
import { collectCellsShiftMap } from './collect-cell-shift-map/collect-cells-shift-map';
import { MoveCellRefMap, MoveCellTempData, RowData, UpdatedCellsMap } from './interfaces';
import { forEachColumnId, getCellValue } from './utils';

const getKeys = (prevRow: RowData, currentRow: RowData): Set<string> => {
  const prevKeys = Object.keys(prevRow);
  const currentKeys = Object.keys(currentRow);
  return new Set(prevKeys.concat(currentKeys));
};

const setUpdatedRow = (rowToSet: RowData, rowFromSet: RowData, rowToSetId: string, updatedCellsMap: UpdatedCellsMap) =>
  (columnId: string) => {
    const prevValue = getCellValue(rowToSet, columnId);
    const value = getCellValue(rowFromSet, columnId);
    const cellId = ExcelFormulaHelper.getCellLink(null, columnId, rowToSetId);
    updatedCellsMap[cellId] = {
      rowId: rowToSetId,
      columnId,
      value,
      prevValue,
    };
  };

export const shiftRows = (
  { rows }: SheetDataStore,
  rowIndexToSet: number,
  fromOffset: number,
): MoveCellTempData => {
  const updatedCellsMap: UpdatedCellsMap = {};
  const moveCellRefMap: MoveCellRefMap = {};

  for (let indexToSet = rowIndexToSet; indexToSet < rows.length; indexToSet++) {
    const indexFromSet = indexToSet + fromOffset;
    const rowToSet = rows[indexToSet] || {};
    const rowFromSet = rows[indexFromSet] || {};
    const keys = getKeys(rowFromSet, rowToSet);

    const rowToSetId = ExcelTableRowIdentification.getRowIdFromIndex(indexToSet);
    const rowFromSetId = ExcelTableRowIdentification.getRowIdFromIndex(indexFromSet);
    const setUpdatedCell = setUpdatedRow(rowToSet, rowFromSet, rowToSetId, updatedCellsMap);

    forEachColumnId(keys)(
      (id) => !isSpecKey(id),
      (id) => {
        setUpdatedCell(id);
        collectCellsShiftMap(moveCellRefMap, rowToSetId, rowFromSetId, id, id);
      },
    );

  }

  return {
    updatedCellsMap,
    moveCellRefMap,
  };
};
