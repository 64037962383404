import uuid from 'uuid';


import { CustomElementFilterCollectionOperation } from 'common/enums/custom-element-filter-collection-operation';
import { CustomElementFilterNumberOperation } from 'common/enums/custom-element-filter-number-operation';
import { CustomElementFilterStringOperation } from 'common/enums/custom-element-filter-string-operation';
import {
  CustomElementFilterBuilderConfig,
  CustomElementFilterBuilderConfigOption,
  CustomElementFilterNumber,
  CustomElementFilterReference,
  CustomElementFilterString,
  CustomElementFilterTreeNode,
} from 'common/interfaces/custom-element-filter-builder';
import { FilterItemTypes } from '../../components/custom-element-filter-builder/inner-enums';
import {
  CustomElementFilterItemType,
  CustomFilterBuilderAutocompleteOption,
  FilterNode,
  InnerCustomElementFilterNumber,
  InnerCustomElementFilterReference,
  InnerCustomElementFilterString,
} from '../../components/custom-element-filter-builder/inner-interfaces';
import { CustomElementFilterBuilderTypeGuards } from './custom-element-filter-builder-type-guards';
import { CustomElementFilterBuilderKeyConfig } from '.';


interface ConfigMapResult {
  filterKeyOptions: CustomFilterBuilderAutocompleteOption[];
  predefineValues: Record<string, CustomFilterBuilderAutocompleteOption[]>;
}

type AppendOptionsFunctionType = (
  options: CustomElementFilterBuilderConfigOption[],
  type: string,
  defaultGroupName?: string,
) => void;


export class CustomElementFilterBuilderMapper {
  public static getEmptyFilter(inverse?: boolean, uniqueKey?: string): CustomElementFilterItemType {
    return {
      uniqueKey: uniqueKey || uuid.v4(),
      inverse: inverse || false,
    } as any as CustomElementFilterItemType;
  }

  public static getEmptyFilterByKey(
    key: string,
    type: FilterItemTypes,
    inverse?: boolean,
    uniqueKey?: string,
  ): CustomElementFilterItemType {
    const baseFilter = CustomElementFilterBuilderMapper.getEmptyFilter(inverse, uniqueKey);
    if (!key) {
      return baseFilter;
    }

    switch (type) {
      case FilterItemTypes.String:
        return {
          ...baseFilter,
          propertyKey: key,
          collectionOperation: CustomElementFilterCollectionOperation.Any,
          isCaseSensitive: false,
          operation: CustomElementFilterStringOperation.Contains,
        } as any as InnerCustomElementFilterString;
      case FilterItemTypes.Number:
        return {
          ...baseFilter,
          propertyKey: key,
          operation: CustomElementFilterNumberOperation.Equal,
        } as any as InnerCustomElementFilterNumber;
      case FilterItemTypes.Reference:
        return {
          ...baseFilter,
          elementFilterId: key,
        } as any as InnerCustomElementFilterReference;
      default:
        return baseFilter;
    }
  }

  public static getFilterNode(
    innerNodes: CustomElementFilterItemType[],
  ): FilterNode {
    return {
      uniqueKey: uuid.v4(),
      inverse: false,
      operation: CustomElementFilterCollectionOperation.All,
      innerNodes,
      orderIndex: 0,
    };
  }

  public static mapConfig(config: CustomElementFilterBuilderConfig): ConfigMapResult {
    const {
      stringOptions, customStringOptions,
      numberOptions, customNumberOptions,
      referenceOptions, disabledKeys,
    } = config;

    if (!config) {
      return {
        filterKeyOptions: [],
        predefineValues: {},
      };
    }

    const filterKeyOptionsGroupMap: Record<string, CustomFilterBuilderAutocompleteOption[]> = {};
    const predefineValues: Record<string, CustomFilterBuilderAutocompleteOption[]> = {};
    const appendOptions = this.getAppendOptionsFunction(filterKeyOptionsGroupMap, predefineValues, disabledKeys);

    const filterGroup = CustomElementFilterBuilderKeyConfig.constants.CREATED_FILTERS_GROUP;

    appendOptions(stringOptions, FilterItemTypes.String);
    appendOptions(customStringOptions, FilterItemTypes.String);
    appendOptions(numberOptions, FilterItemTypes.Number);
    appendOptions(customNumberOptions, FilterItemTypes.Number);
    appendOptions(referenceOptions, FilterItemTypes.Reference, filterGroup);


    return {
      filterKeyOptions: this.getFilterKeyOptions(filterKeyOptionsGroupMap),
      predefineValues,
    };
  }

  public static mapToFilterNode(rootNode: CustomElementFilterTreeNode): FilterNode {
    return {
      uniqueKey: uuid.v4(),
      operation: rootNode.operation,
      inverse: rootNode.inverse,
      orderIndex: rootNode.orderIndex,
      innerNodes: CustomElementFilterBuilderMapper.getFilterNodeInnerNodes(rootNode),
    };
  }

  public static mapToCustomElementFilterTreeNode(
    filterNode: FilterNode, orderIndex?: number,
  ): CustomElementFilterTreeNode {
    const result = {
      inverse: filterNode.inverse,
      operation: filterNode.operation,
      orderIndex: orderIndex || 0,
      doubleBasedFilters: [],
      innerNodes: [],
      referencedFilters: [],
      stringBasedFilters: [],
    };
    CustomElementFilterBuilderMapper.appendInnerFilters(filterNode, result);

    return result;
  }

  private static appendInnerFilters(filterNode: FilterNode, result: CustomElementFilterTreeNode): void {
    filterNode.innerNodes.forEach((x, index) => {
      if (CustomElementFilterBuilderTypeGuards.isStringBaseFilter(x)) {
        result.stringBasedFilters.push({ ...x, orderIndex: index });
      } else if (CustomElementFilterBuilderTypeGuards.isNumberBaseFilter(x)) {
        result.doubleBasedFilters.push({ ...x, orderIndex: index });
      } else if (CustomElementFilterBuilderTypeGuards.isReferenceBaseFilter(x)) {
        result.referencedFilters.push({ ...x, orderIndex: index });
      } else if (CustomElementFilterBuilderTypeGuards.isFilterNode(x)) {
        result.innerNodes.push(CustomElementFilterBuilderMapper.mapToCustomElementFilterTreeNode(x, index));
      }
    });
  }

  private static getAppendOptionsFunction(
    filterKeyOptionsGroupMap: Record<string, CustomFilterBuilderAutocompleteOption[]>,
    predefineValues: Record<string, CustomFilterBuilderAutocompleteOption[]>,
    disabledOptions: Record<string, boolean>,
  ): AppendOptionsFunctionType {
    const keyToGroupMap = CustomElementFilterBuilderKeyConfig.keyToGroupMap;

    return (
      options: CustomElementFilterBuilderConfigOption[],
      type: string,
      defaultGroupName?: string,
    ) => {
      if (!options) {
        return;
      }

      options.forEach(option => {
        const group = keyToGroupMap[option.value] || defaultGroupName;
        if (!group) {
          return;
        }

        filterKeyOptionsGroupMap[group] = filterKeyOptionsGroupMap[group] || [];
        filterKeyOptionsGroupMap[group].push({
          name: option.name,
          value: option.value,
          type,
          isSelectable: !(disabledOptions && disabledOptions[option.value]),
          isContainsInModel: option.isContainsInModel,
        });

        predefineValues[option.value] = CustomElementFilterBuilderMapper.getOptionPredefinedValues(option);
      });
    };
  }

  private static getFilterKeyOptions(
    filterKeyOptionsMap: Record<string, CustomFilterBuilderAutocompleteOption[]>,
  ): CustomFilterBuilderAutocompleteOption[] {
    const result: CustomFilterBuilderAutocompleteOption[] = [];
    CustomElementFilterBuilderKeyConfig.groupOrderedArray.forEach(groupName => {
      if (!filterKeyOptionsMap[groupName]) {
        return;
      }

      result.push({
        name: groupName,
        value: groupName,
        type: null,
        children: filterKeyOptionsMap[groupName],
        isContainsInModel: false,
        isSelectable: false,
      });
    });

    return result;
  }

  private static getOptionPredefinedValues(
    option: CustomElementFilterBuilderConfigOption,
  ): CustomFilterBuilderAutocompleteOption[] {
    const options: CustomFilterBuilderAutocompleteOption[] = [];
    const stack: Array<{ options: CustomFilterBuilderAutocompleteOption[], count: number }> = [{ options, count: 0 }];

    if (option.predefineValues) {
      option.predefineValues.forEach(value => {
        const stackHead = stack[stack.length - 1];
        const childrenCount = value.children && value.children.length || 0;
        if (!childrenCount) {
          stackHead.options.push({
            name: value.name,
            value: value.name,
            type: null,
            isSelectable: true,
            isContainsInModel: value.isContainsInModel,
          });

          if (stack.length) {
            stackHead.count -= 1;
          }

          if (stackHead.count === 0) {
            stack.pop();
          }
        } else {
          const children = [];

          stackHead.options.push({
            name: value.name,
            value: value.name,
            type: null,
            isSelectable: true,
            children,
            isContainsInModel: value.isContainsInModel,
          });

          if (stack.length) {
            stackHead.count -= 1;
          }

          if (stackHead.count === 0) {
            stack.pop();
          }

          stack.push({ options: children, count: childrenCount });
        }
      });
    }

    return options;
  }

  private static appendUniqueKey(
    element: CustomElementFilterNumber | CustomElementFilterString | CustomElementFilterReference,
  ): CustomElementFilterItemType {
    return {
      ...element,
      uniqueKey: uuid.v4(),
    };
  }

  private static getFilterNodeInnerNodes(node: CustomElementFilterTreeNode): CustomElementFilterItemType[] {
    const result = [];
    if (node.doubleBasedFilters) {
      node.doubleBasedFilters.forEach(x => result[x.orderIndex] = this.appendUniqueKey(x));
    }
    if (node.stringBasedFilters) {
      node.stringBasedFilters.forEach(x => result[x.orderIndex] = this.appendUniqueKey(x));
    }
    if (node.referencedFilters) {
      node.referencedFilters.forEach(x => result[x.orderIndex] = this.appendUniqueKey(x));
    }
    if (node.innerNodes) {
      node.innerNodes.forEach(x => result[x.orderIndex] = CustomElementFilterBuilderMapper.mapToFilterNode(x));
    }

    return result;
  }
}
