import { RectangleButton } from '@kreo/kreo-ui-components';
import React from 'react';

import { UploadingFile } from 'common/interfaces/common-state';
import { useOpenCloseDialog } from 'common/UIKit';
import { DrawingDialogs } from '../../constants/drawing-dialogs';
import { UploadDialog } from './upload-dialog';


const UPLOAD_BUTTON_WIDTH = 360;
const UPLOAD_BUTTON_HEIGHT = 60;

interface Props {
  onSaveFiles: (files: UploadingFile[]) => void;
  addFileToRoot: () => void;
  canEditMeasurement: boolean;
  error: boolean;
}

const DrawingsPdfFooterContentComponent: React.FC<Props> = ({
  canEditMeasurement,
  error,
  onSaveFiles,
}) => {
  const [open] = useOpenCloseDialog(DrawingDialogs.PDF_UPLOAD_DIALOG);
  const mood = canEditMeasurement ? error ? 'negative' : 'secondary' : 'disabled';
  return (
    <>
      <UploadDialog
        onSubmit={onSaveFiles}
      />
      <RectangleButton
        mood={mood}
        onClick={open}
        text='Upload new files'
        width={UPLOAD_BUTTON_WIDTH}
        height={UPLOAD_BUTTON_HEIGHT}
      />
    </>
  );
};

export const DrawingsPdfFooterContent = React.memo(DrawingsPdfFooterContentComponent);
