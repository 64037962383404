import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  margin: 0 20px;
  cursor: default;

  .help-center {
    z-index: 1;
  }
`;

const AvatarContainer = styled.div<{ size: number }>`
  height: ${p => p.size + 8}px;
  width: ${p => p.size + 8}px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all ${p => p.theme.duration.s} ease;
  :hover {
    background-color: ${p => p.theme.color.pale};
  }
  :active {
    transform: scale(0.95);
  }
`;

export const Styled = {
  Container,
  AvatarContainer,
};
