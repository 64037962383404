import * as React from 'react';

import { ConfirmationDialog } from '../../../../../components/dialog/confirmation-dialog';
import { DrawingsFile, DrawingsFolderViewInfo } from '../../interfaces/drawings-file-info';

export const DRAWING_LEAVE_PROJECT_CONFIRMATION_DIALOG = 'DRAWING_LEAVE_PROJECT_CONFIRMATION_DIALOG';

interface OwnProps {
  onSubmit: (data: DrawingsFile | DrawingsFolderViewInfo) => void;
}

export class DrawingsLeaveProjectConfirmationDialog extends React.Component<OwnProps> {

  public render(): React.ReactNode {
    const { onSubmit } = this.props;
    const dialogText = `Press the "Leave" button to quit this project. All your data will be saved.`;

    return (
      <ConfirmationDialog
        name={DRAWING_LEAVE_PROJECT_CONFIRMATION_DIALOG}
        title='Are you sure you want to leave?'
        text={dialogText}
        confirmButtonText='Leave'
        onConfirm={onSubmit}
        cancelButtonText='Cancel'
      />
    );
  }
}
