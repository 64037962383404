import { ElementTooltip } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';


interface Props {
  count: number;
  tooltipPosition: string;
}

const CounterComponents: React.FC<Props> = ({ count, tooltipPosition }) => {
  return (
    <ElementTooltip
      text='Measurements'
      position={tooltipPosition}
    >
      <Styled.Counter active={!!count}>
        {count}
      </Styled.Counter>
    </ElementTooltip>
  );
};

export const Counter = React.memo(CounterComponents);
