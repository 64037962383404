import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { Account } from 'common/interfaces/account';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { PersonUtils } from 'common/utils/person-utils';
import { AIRequestStatus, Message as MessageType } from 'unit-2d-copilot/interfaces';
import { BotMessage, Message } from '../message';
import { Styled } from './styled';
import { useScroll } from './use-scroll';

interface OwnProps {
  history: MessageType[];
  tempMessage: string;
  status: AIRequestStatus;
}

interface StateProps {
  account: Account;
}

interface Props extends OwnProps, StateProps {
}

const ChatHistoryComponent: React.FC<Props> = ({ history, tempMessage, account, status }) => {
  const { userName, avatarLink } = useMemo(() => (PersonUtils.getPersonVisualData(account)), [account]);
  const { containerRef, onScroll } = useScroll(history, tempMessage);
  const statusText = status === AIRequestStatus.TextExtracting
    ? 'Extracting PDF data...'
    : null;
  const botMessage = tempMessage || statusText;

  return (
    <Styled.History ref={containerRef} onScroll={onScroll}>
      {arrayUtils.filterMap(
        history,
        (message) => !message.hideInHistory,
        (message, index) => (
          <Message
            key={index}
            role={message.role}
            content={message.content}
            userAvatar={avatarLink}
            userName={userName}
          />
        ),
      )}
      {botMessage ? <BotMessage content={botMessage} /> : null}
    </Styled.History>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    account: state.account,
  };
}

export const ChatHistory = connect(mapStateToProps)(ChatHistoryComponent);
