import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import './issues-content.scss';

import { State } from 'common/interfaces/state';
import * as DataTypes from '../interfaces/types';
import { ContentContainer } from './content-container';
import { IssueTile } from './issue-tile';


interface StateProps {
  issues: DataTypes.SourceIssue[];
  issuesSelected: number;
}

interface OwnProps {
  selectedIssues: number;
  onIssueSelect: (order: number) => void;
  onDeselect: () => void;
}

interface Props extends StateProps, OwnProps {
}

class IssuesContentComp extends React.Component<Props> {

  public render(): any {
    return (
      <div className='checker-issues-content'>
        <ContentContainer title='issues'>
          <div
            className={classNames('model-check-issue-tile', { selected: !this.props.issuesSelected })}
            onClick={this.props.onDeselect}
          >
            Entire Model
          </div>
          {
            this.props.issues.map((x: DataTypes.SourceIssue): any => {
              return x.count ? (
                <IssueTile
                  key={x.order}
                  order={x.order}
                  errorType={x.priority}
                  selected={x.order === this.props.selectedIssues}
                  title={x.name}
                  errorCount={x.count}
                  onSelect={this.props.onIssueSelect}
                />
              ) : null;
            })
          }
        </ContentContainer>
      </div>
    );
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    issues: state.modelcheck.issues,
    issuesSelected: state.modelcheck.selectedErrorCategory,
  };
}


export const IssuesContent =
  connect<StateProps, {}, OwnProps>(mapStateToProps, null)(IssuesContentComp);
