import { Icons, MenuItem } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { ColorsName } from 'common/enums/kreo-colors';


export const HelpCenterButton: React.FC = () => {
  return (
    <a
      href='https://help-takeoff.kreo.net/'
      target='_blank'
    >
      <MenuItem
        Icon={Icons.HelpCenterProject2D}
        text='Help Center'
        onClick={ConstantFunctions.doNothing}
        fontSize={14}
        size='m'
        textColor={ColorsName.mainFont}
        withBorder={true}
        withoutWaves={true}
      />
    </a>
  );
};
