import styled from 'styled-components';

const Title = styled.div`
  display: inline-flex;
  align-items: center;
  svg {
    margin-right: 15px;
  }
`;

const Container = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${Title} {
    p {
      color: ${p => p.disabled ? p.theme.color.disabled : p.theme.color.mainFont};
      font-size: 14px;
    }
  }
`;

export const Styled = {
  Container,
  Title,
};
