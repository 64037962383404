import * as React from 'react';

import './classification-selected-popup.scss';

import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';
import { TooltipContainer } from '../../../../common/components/tooltip-container';
import { Checkbox, KreoButton } from '../../../../common/UIKit';
import { ClassificationActions } from '../../actions/creators/classification';
import { ClassificationSelectedGroupInfo } from '../classification-selected-group-info';

interface StateProps {
  selectedCount: number;
  allElementTypesSelected: boolean;
  hasPattern: boolean;
  groupsCount: number;
}

interface DispatchProps {
  paste: () => void;
  edit: () => void;
  selectAll: (value: boolean) => void;
  deselect: () => void;
}

interface OwnProps {
  isolatedTree: boolean;
}

interface Props extends OwnProps, StateProps, DispatchProps {
}


class ClassificationSelectedPopupComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { selectedCount, allElementTypesSelected, hasPattern } = this.props;
    if (!selectedCount) return null;
    return (
      <div className='classification-selected-popup'>
        <div className='classification-selected-popup__checkbox'>
          <Checkbox
            color='white'
            size='large'
            indeterminate={!allElementTypesSelected && !!selectedCount}
            checked={allElementTypesSelected}
            onChange={this.props.selectAll}
          />
          <TooltipContainer>
            { allElementTypesSelected ? 'Deselect All' : 'Select All' }
          </TooltipContainer>
        </div>
        <div className='classification-selected-popup__count'>{selectedCount}</div>
        <div className='classification-selected-popup__count-text'>
          Element type{selectedCount > 1 ? 's' : ''} Selected
        </div>
        <div className='classification-selected-popup__edit-button-container'>
          { !!this.props.groupsCount &&
            <div className='classification-selected-popup__groups'>
              {this.renderGroups()}
            </div>
          }
          <div className='classification-selected-popup__button-wrap'>
            <TooltipContainer>
              Edit Classification
            </TooltipContainer>
            <KreoButton
              className='classification-selected-popup__button'
              size='medium'
              onClick={this.props.edit}
              rounded={true}
              disabled={this.props.groupsCount > 1}
              controlName='selected-popup-button-edit'
            >
              Edit
            </KreoButton>
          </div>
        </div>
        <div className='classification-selected-popup__button-wrap'>
          <TooltipContainer>
            Paste Classification
          </TooltipContainer>
          <KreoButton
            className='classification-selected-popup__button'
            size='medium'
            onClick={this.props.paste}
            rounded={true}
            disabled={!hasPattern}
            controlName='selected-popup-button-paste'
          >
            Paste
          </KreoButton>
        </div>
        <div className='classification-selected-popup__cancel' onClick={this.props.deselect}>
          Cancel
        </div>
      </div>
    );
  }


  private renderGroups(): JSX.Element[] {
    const { groupsCount } = this.props;
    const group = new Array<JSX.Element>(groupsCount + 1);
    group[0] = (<ClassificationSelectedGroupInfo index={-1}/>);
    for (let i = 0; i < groupsCount; i++) {
      group[i + 1] = (<ClassificationSelectedGroupInfo index={i}/>);
    }
    return group;
  }
}


function mapStateToProps({ classification }: State, { isolatedTree }: OwnProps): StateProps {
  const selectedCount = classification.selectedElementTypesCount;
  const allElementsCount = isolatedTree ?
    classification.filteredElementTypesCount : classification.allElementTypesCount;
  return {
    selectedCount,
    allElementTypesSelected: allElementsCount === selectedCount,
    hasPattern: classification.elementTypeComparisonPattern !== null,
    groupsCount: classification.currentSelectedElementTypesGroups.length,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    paste: () => dispatch(ClassificationActions.pasteClassification()),
    selectAll: value => dispatch(ClassificationActions.onSelectAll(value)),
    deselect: () => dispatch(ClassificationActions.onSelectAll(false)),
    edit: () => dispatch(ClassificationActions.tryStartMultipleEdit()),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationSelectedPopup = connector(ClassificationSelectedPopupComponent);
