const ItemTarget = {
  drop: (props: any, monitor?: any, component?: any) => {

    const { packageItem } = props;
    let item;
    let isGroup;

    const sourceObj = monitor.getItem();
    if (sourceObj) {
      if (sourceObj.groupItem) {
        item = sourceObj.groupItem;
        isGroup = true;
      } else if (sourceObj.activity) {
        item = sourceObj.activity;
        isGroup = false;
      }
    }

    if (!item) {
      return; // something wrog
    }

    if (packageItem) {
      if (isGroup) {
        component.props.replaceGroup(packageItem.id, item.idGroup);
      } else {
        component.props.replaceActivity(packageItem.id, item.id);
      }
    } else {
      if (isGroup) {
        component.props.createPackage(item.idGroup);
      } else {
        component.props.createPackageByActivity(item.id);
      }
    }
  },
};

const ItemSource = {
  beginDrag: (props: any): any => props,
};

export { ItemTarget, ItemSource };
