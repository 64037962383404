
export const TUTORIALS = {
  Connect: {
    link: 'https://public.rive.app/hosted/280597/104355/_M9maQtKCkyTwSK7uLwssw.riv',
    animation: 'Connect',
    // eslint-disable-next-line max-len
    description: 'Connects a new line with the previous ones, automatically completing or cutting off the extra parts of the lines',
  },
  Enclose: {
    link: 'https://public.rive.app/hosted/280597/104379/b7BFD6bmR0mfpr6hzLGEPA.riv',
    animation: 'Enclose',
    // eslint-disable-next-line max-len
    description: 'Creates an area measurement instead of a linear one. Can only be used together with the "Connect" option',
  },
  SameSize: {
    link: 'https://public.rive.app/hosted/280597/104374/ILV5YyRzH026QSfVNDJ6rQ.riv',
    animation: 'Same size',
    description: 'Search for shapes of the same sizes, not just similar ones',
  },
  AllowRotate: {
    link: 'https://public.rive.app/hosted/280597/104377/n4KabmBuaE6vCih92bnW_A.riv',
    animation: 'Allow rotate',
    description: 'Includes rotated shapes in the result',
  },
  AllowFlip: {
    link: 'https://public.rive.app/hosted/280597/104378/iE3itmvRi0_lBxTGsa2h2w.riv',
    animation: 'Allow flipping',
    description: 'Includes mirrored shapes in the result',
  },
  Similarity: {
    link: 'https://public.rive.app/hosted/280597/104407/Oo44WsN9zUWJ4pEkzsZ75Q.riv',
    animation: 'Similarity',
    description: 'Filters results by accuracy - from the most similar ones (100%), to the least similar ones (0%)',
  },
  Eraser: {
    link: 'https://public.rive.app/hosted/280597/111438/KCFxADZuQUGbaymGNiHoOg.riv',
    animation: 'Eraser',
    description: undefined,
  },
};
