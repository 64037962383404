import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AbilityRoute } from 'common/ability/ability-route';
import { Subject } from 'common/ability/subject';
import { SubscriptionType } from 'common/constants/subscription';
import { RoutingContextProvider } from 'common/routing/routing-context';

import { NotFoundPage } from '../../units/notfound/page';
import { ProjectsPage } from '../../units/projects/pages/projects/page';
import * as Activity from '../common/activity';
import * as People from '../common/people';
import * as Subscription from '../common/subscription-routes-wrap';
import { Qto3dProjectRouteParams } from './route-params';
import { ProjectRoutes } from './routes/project';
import { Urls } from './urls';

interface RoutesProps extends RouteComponentProps<Qto3dProjectRouteParams> {
}

const InnerSwitch = (props: RoutesProps): JSX.Element => (
  <Switch>
    <Redirect exact={true} from={Urls.index.path} to={Urls.listing.path} />
    <Route exact={true} path={Urls.listing.path} component={ProjectsPage} />
    <Redirect exact={true} from={Urls.project.index.path} to={Urls.project.quantityTakeOff.path} />
    <Route path={Urls.project.index.path} component={ProjectRoutes}/>
    <Route path={Urls.people.index.path}>
      <People.Routes
        {...props}
        urls={Urls.people}
      />
    </Route>
    <Route path={Urls.activity.index.path}>
      <Activity.Routes
        {...props}
        urls={Urls.activity}
      />
    </Route>
    <Route component={NotFoundPage} />
  </Switch>
);

export class Routes extends React.Component<RoutesProps> {
  public render(): React.ReactNode {
    return (
      <RoutingContextProvider urls={Urls}>
        <Subscription.SubscriptionWrap
          urls={Urls}
          subscriptionType={SubscriptionType.Takeoff3d}
        >
          <AbilityRoute
            path={Urls.index.path}
            subject={Subject.QuantityTakeOff3d}
            component={InnerSwitch}
          />
        </Subscription.SubscriptionWrap>
      </RoutingContextProvider>
    );
  }
}
