import React from 'react';
import { DropDownProps } from 'unit-2d-database/components/side-panel/components/item-panel/interfaces';
import { FormFieldStyled } from '../form-fields/form-field';
import { InputSelect } from '../inputs';
import { useCloseCallback, useEnterEffect, useInputOverrideMemo, useUnitState } from './hooks';
import { Styled } from './styled';

interface Props {
  input: any;
  onClose: () => void;
  isOpen: boolean;
  inputElement: React.FC;
  dropDownProps?: DropDownProps;
}

export const EditDialog: React.FC<Props> = ({
  inputElement,
  input,
  onClose,
  dropDownProps,
}: Props) => {
  const Input = inputElement;
  const inputProps = useInputOverrideMemo(inputElement, input);
  const unitState = useUnitState(dropDownProps);
  const handleChange = useCloseCallback(dropDownProps, unitState, onClose, inputProps, input);
  useEnterEffect(handleChange);
  return (
    <>
      <Styled.BackClickContainer onClick={handleChange} />
      <Styled.EditorContainer>
        <FormFieldStyled.InputContainer hasUnit={!!dropDownProps}>
          <Input {...inputProps} />
          {dropDownProps && (
            <FormFieldStyled.UnitWrap>
              <InputSelect
                elements={dropDownProps.elements}
                activeElementIndex={unitState.activeElementIndex}
                onClick={unitState.onClick}
                isCollaps={true}
                createNewElement={true}
                value={unitState.value}
                onBlur={unitState.onBlur} />
            </FormFieldStyled.UnitWrap>
          )}
        </FormFieldStyled.InputContainer>
      </Styled.EditorContainer>
    </>

  );
};
