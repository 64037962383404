import { DrawingsDrawMode } from './drawings-draw-mode';

export enum WizzardStatus {
  Start,
  Loading,
  Preview,
  SpecifySearchArea,
  Eraser,
  SelectionLoaded,
  Error,
}

export const WizzarsStatusToDropperDrawMode: Record<WizzardStatus, DrawingsDrawMode> = {
  [WizzardStatus.Start]: DrawingsDrawMode.Dropper,
  [WizzardStatus.Loading]: DrawingsDrawMode.Dropper,
  [WizzardStatus.Preview]: DrawingsDrawMode.Dropper,
  [WizzardStatus.SpecifySearchArea]: DrawingsDrawMode.WizzardSearchArea,
  [WizzardStatus.Eraser]: DrawingsDrawMode.Dropper,
  [WizzardStatus.SelectionLoaded]: DrawingsDrawMode.Dropper,
  [WizzardStatus.Error]: DrawingsDrawMode.Dropper,
};


export const WizzardStatusToFinderDrawMode: Record<WizzardStatus, DrawingsDrawMode> = {
  [WizzardStatus.Start]: DrawingsDrawMode.FinderSelectionArea,
  [WizzardStatus.Loading]: DrawingsDrawMode.Finder,
  [WizzardStatus.Preview]: DrawingsDrawMode.Finder,
  [WizzardStatus.SpecifySearchArea]: DrawingsDrawMode.WizzardSearchArea,
  [WizzardStatus.Eraser]: DrawingsDrawMode.Finder,
  [WizzardStatus.SelectionLoaded]: DrawingsDrawMode.Finder,
  [WizzardStatus.Error]: DrawingsDrawMode.Finder,
};
