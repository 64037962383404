import { Arrangement } from '../constants';

export function getArrangementCount(type: Arrangement): number {
  switch (type) {
    case Arrangement.TwoViewsSideBySide:
    case Arrangement.TwoViewsStacked:
      return 2;
    case Arrangement.ThreeViewsBottomSplit:
    case Arrangement.ThreeViewsLeftSplit:
    case Arrangement.ThreeViewsRightSplit:
    case Arrangement.ThreeViewsTopSplit:
      return 3;
    case Arrangement.FourViews:
    case Arrangement.FourViewsBottomSplit:
    case Arrangement.FourViewsLeftSplit:
    case Arrangement.FourViewsRightSplit:
    case Arrangement.FourViewsSideBySide:
    case Arrangement.FourViewsStacked:
    case Arrangement.FourViewsTopSplit:
      return 4;
    default:
      return 1;
  }
}
