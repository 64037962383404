import { RectangleButton } from '@kreo/kreo-ui-components';
import React from 'react';

interface Props {
  width: number;
  text: string;
  isActive: boolean;
  onClick: () => void;
}

export const ModeButton: React.FC<Props> = ({ width, text, isActive, onClick }) => {
  return (
    <RectangleButton
      height={30}
      width={width}
      text={text}
      mood={isActive && 'secondary'}
      ghost={!isActive}
      textHoverColor={isActive ? undefined : 'turquoise'}
      onClick={onClick}
    />
  );
};
