import { RECTANGLE_BORDER_COLORS } from '@kreo/kreo-ui-components/components/buttons/constants';
import styled from 'styled-components';
import { Mood } from 'common/enums/mood';

const Container = styled.div<{ mood: Mood }>`
  border: 1px solid ${(p) => p.theme.color[RECTANGLE_BORDER_COLORS[p.mood]]};
  display: flex;
  gap: 15px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
`;

const Content = styled.div`
  width: 100%;
`;

const StatusBar = styled.div<{ mood: Mood }>`
  background-color: ${(p) => p.theme.color[RECTANGLE_BORDER_COLORS[p.mood]]};
  border-radius: 3px;
  width: 5px;
  min-width: 5px;
`;

export const Styled = {
  Container,
  Content,
  StatusBar,
};
