import autobind from 'autobind-decorator';

import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { ConstantFunctions } from 'common/constants/functions';
import { DrawingHotkey } from '../utils/hotkey-utils';

interface DrawingsKeyboardListenersHelperConfig extends GlobalKeyboardEventsControllerContextProps {
  onShowAll: () => void;
  show: () => void;
  hide: () => void;
  focus: () => void;
  isolate: () => void;
  selectAll: () => void;
  isSelectedInstancesHidden: () => boolean;
  toggleElementsPanel: () => void;
  unselect: () => void;
  toggleAiSuggest: () => void;
  toggleSnapping: () => void;
}

export class DrawingsKeyboardListenersHelper {
  private props: DrawingsKeyboardListenersHelperConfig;

  constructor(config: DrawingsKeyboardListenersHelperConfig) {
    this.props = config;
  }

  public addEventListeners(canViewMeasurement: boolean): void {
    if (canViewMeasurement) {
      this.props.addKeyDownEventListener(DrawingHotkey.ShowAll, this.showAll);
      this.props.addKeyDownEventListener(DrawingHotkey.ToggleAiSuggest, this.props.toggleAiSuggest);
      this.props.addKeyDownEventListener(DrawingHotkey.ShowOrHide, this.toggleItemVisibility);
      this.props.addKeyDownEventListener(DrawingHotkey.Focus, this.props.focus);
      this.props.addKeyDownEventListener(DrawingHotkey.Isolate, this.props.isolate);
      this.props.addKeyDownEventListener(DrawingHotkey.SelectAll, this.selectAll);
      this.props.addKeyDownEventListener(DrawingHotkey.Unselect, this.props.unselect);
      this.props.addKeyDownEventListener(DrawingHotkey.ToggleElementsPanel, this.props.toggleElementsPanel);
      this.props.addKeyDownEventListener(DrawingHotkey.ToggleSnapping, this.props.toggleSnapping);
    }
  }

  public removeEventListeners(canViewMeasurement: boolean): void {
    if (canViewMeasurement) {
      this.props.removeKeyDownEventListener(DrawingHotkey.ShowAll, this.showAll);
      this.props.removeKeyDownEventListener(DrawingHotkey.ToggleAiSuggest, this.props.toggleAiSuggest);
      this.props.removeKeyDownEventListener(DrawingHotkey.ShowOrHide, this.toggleItemVisibility);
      this.props.removeKeyDownEventListener(DrawingHotkey.Focus, this.props.focus);
      this.props.removeKeyDownEventListener(DrawingHotkey.Isolate, this.props.isolate);
      this.props.removeKeyDownEventListener(DrawingHotkey.SelectAll, this.selectAll);
      this.props.removeKeyDownEventListener(DrawingHotkey.Unselect, this.props.unselect);
      this.props.removeKeyDownEventListener(DrawingHotkey.ToggleSnapping, this.props.toggleSnapping);
      this.props.removeKeyDownEventListener(DrawingHotkey.ToggleElementsPanel, this.props.toggleElementsPanel);
    }
  }

  @autobind
  private selectAll(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    this.props.selectAll();
  }


  @autobind
  private showAll(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    this.props.onShowAll();
  }

  @autobind
  private toggleItemVisibility(): void {
    if (this.props.isSelectedInstancesHidden()) {
      this.props.show();
    } else {
      this.props.hide();
    }
  }
}
