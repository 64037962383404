import { Action } from 'redux';
import { ActionWith } from 'common/interfaces/action-with';
import { Direction } from '../../../../components/graph-viewer/enums';
import { GraphEdge } from '../../../../components/graph-viewer/interfaces';
import { ActivityGroupData } from '../../interfaces';
import { MacroSequenceActionTypes } from '../types';

function setEdgeCreatorRootVertexAndDirection(
  rootId: number,
  direction: Direction,
): ActionWith<ActivityGroupData.EdgeCreator> {
  return {
    type: MacroSequenceActionTypes.SET_EDGE_CREATOR_ROOT_VERTEX_AND_DIRECTION,
    payload: {
      rootId,
      direction,
      destinationIds: [],
    },
  };
}

function resetEdgeCreator(): Action {
  return {
    type: MacroSequenceActionTypes.RESET_EDGE_CREATOR,
  };
}

function addEdge(edge: GraphEdge): ActionWith<GraphEdge> {
  return {
    type: MacroSequenceActionTypes.ADD_EDGE,
    payload: edge,
  };
}

function removeEdge(edge: GraphEdge): ActionWith<GraphEdge> {
  return {
    type: MacroSequenceActionTypes.REMOVE_EDGE,
    payload: edge,
  };
}

function setLimitationTimeLag(edge: GraphEdge, timeLag: number): ActionWith<{ edge: GraphEdge, timeLag: number }> {
  return {
    type: MacroSequenceActionTypes.SET_LIMITATION_TIME_LAG,
    payload: { edge, timeLag },
  };
}

function setActiveActivityGroupOrUngroupedActivityById(activityGroupId: number): ActionWith<number> {
  return {
    type: MacroSequenceActionTypes.SET_ACTIVE_ACTIVITY_GROUP_OR_UNGROUPED_ACTIVITY_BY_GROUP_ID,
    payload: activityGroupId,
  };
}

function setHighlightedGraphItemId(id: number | null): ActionWith<number | null> {
  return {
    type: MacroSequenceActionTypes.SET_HIGHLIGHTED_GRAPH_ITEM_ID,
    payload: id,
  };
}

function setFilteredWorkPackageIds(
  ids: Record<number, undefined> | null,
): ActionWith<Record<number, undefined> | null> {
  return {
    type: MacroSequenceActionTypes.SET_FILTERED_WORK_PACKAGE_IDS,
    payload: ids,
  };
}

function setFilteredActivityGroupIds(
  ids: Record<number, undefined> | null,
): ActionWith<Record<number, undefined> | null> {
  return {
    type: MacroSequenceActionTypes.SET_FILTERED_ACTIVITY_GROUP_IDS,
    payload: ids,
  };
}


function setSelectedDestinationId(ids: number[] | null): ActionWith<number[] | null> {
  return {
    type: MacroSequenceActionTypes.setSelectedDestinationId,
    payload: ids,
  };
}

export const MacroSequenceActions = {
  setEdgeCreatorRootVertexAndDirection,
  resetEdgeCreator,
  addEdge,
  removeEdge,
  setLimitationTimeLag,
  setActiveActivityGroupOrUngroupedActivityById,
  setHighlightedGraphItemId,
  setFilteredWorkPackageIds,
  setFilteredActivityGroupIds,
  setSelectedDestinationId,
};
