import autobind from 'autobind-decorator';
import React from 'react';

import './macro-sequence-filter.scss';

import { KreoIconFilters, KreoScrollbars } from 'common/UIKit';
import { Toggle, ToggleSize } from '../../../../components/toggle';
import { NamedEntity } from '../../interfaces';

interface Props {
  workPackages: NamedEntity[];
  selectedIds: number[];
  onChange: (selectedIds: Record<number, undefined> | null) => void;
}

interface State {
  isOpen: boolean;
  selectedIds: number[];
}

export class MacroSequenceFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedIds: props.selectedIds ? props.selectedIds : props.workPackages.map(x => x.id),
    };
  }

  public render(): JSX.Element {
    return (
      <div className='macro-sequence-filter'>
        <div
          className='macro-sequence-btn-round'
          onClick={this.openOrCloseDropdown}
        >
          <KreoIconFilters />
        </div>
        <div className='macro-sequence-filter__button'>
          {this.isSelectedAllWorkPackages() ? 'Entire Project' : 'Selected Work Packages'}
        </div>
        {
          this.state.isOpen ? (
            <React.Fragment>
              <div className='macro-sequence-filter__dropdown'>
                <div>
                  <div className='macro-sequence-filter__dropdown-header'>
                    <b>Entire project</b>
                    <Toggle
                      checked={this.isSelectedAllWorkPackages()}
                      toggleSize={ToggleSize.Small}
                      checkedColor='#5C8AE6'
                      onCheck={this.onHeaderToggleChange}
                    />
                  </div>
                  <div className='macro-sequence-filter__dropdown-content'>
                  <KreoScrollbars>
                  {
                    this.renderDropdownContent()
                  }
                  </KreoScrollbars>
                  </div>
                </div>
              </div>
              <div
                className='macro-sequence-filter__blanket'
                onClick={this.closeDropdown}
              />
            </React.Fragment>
          ) : null
        }
      </div>
    );
  }

  @autobind
  private isSelectedAllWorkPackages(): boolean {
    return this.state.selectedIds.length === this.props.workPackages.length;
  }

  @autobind
  private openOrCloseDropdown(): void {
    if (this.state.isOpen) {
      this.closeDropdown();
    } else {
      this.setState({ isOpen: true });
    }
  }

  @autobind
  private closeDropdown(): void {
    this.setState({ isOpen: false });
    const selectedIds = this.state.selectedIds;
    if (!selectedIds || !selectedIds.length) {
      this.props.onChange(null);
      return;
    }

    const selectedIdsMap = {};
    for (const id of selectedIds) {
      selectedIdsMap[id] = undefined;
    }
    this.props.onChange(selectedIdsMap);
  }

  @autobind
  private onHeaderToggleChange(value: boolean): void {
    this.setState({
      selectedIds: value ? this.props.workPackages.map(x => x.id) : [],
    });
  }

  @autobind
  private getOnCheckFunction(id: number): (value: boolean) => void {
    return (value: boolean) => {
      const selectedIds = value
        ? this.state.selectedIds ? this.state.selectedIds.concat([id]) : [id]
        : this.state.selectedIds.filter(x => x !== id);
      this.setState({ selectedIds });
    };
  }

  @autobind
  private renderDropdownContent(): JSX.Element[] {
    return this.props.workPackages
      .map(x => {
        const isSelected = this.state.selectedIds ? this.state.selectedIds.includes(x.id) : true;
        return (
          <div className={isSelected ? 'selected' : null} key={x.name}>
            <span>{x.name}</span>
            <Toggle
              checked={isSelected}
              onCheck={this.getOnCheckFunction(x.id)}
              toggleSize={ToggleSize.Small}
              checkedColor='#5C8AE6'
            />
          </div>
        );
      });
  }
}
