import * as React from 'react';


export const KreoIconBest: React.FC = () => {
  return (
    <svg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='icon_best' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill='#77CE64' cx='6' cy='6' r='6' />
        <path
          d='M8.14644661,4.14644661 C8.34170876,3.95118446 8.65829124,3.95118446 8.85355339,4.14644661
          C9.04881554,4.34170876 9.04881554,4.65829124 8.85355339,4.85355339 L5.85355339,7.85355339
          C5.65829124,8.04881554 5.34170876,8.04881554 5.14644661,7.85355339 L3.64644661,6.35355339
          C3.45118446,6.15829124 3.45118446,5.84170876 3.64644661,5.64644661 C3.84170876,5.45118446
          4.15829124,5.45118446 4.35355339,5.64644661 L5.5,6.79289322 L8.14644661,4.14644661 Z'
          id='Path-2'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg >
  );
};
