import React from 'react';

import { Property } from 'common/components/property';
import { PickOnlyFieldsOfType } from 'common/interfaces/omiters';
import { UnitTypes } from 'common/utils/unit-util';
import { usePersistedStorageValueWithChange } from 'persisted-storage/hooks';
import { PersistedStorageState } from 'persisted-storage/interfaces/state';
import { MeasurementInput } from '../../measurement-input';

interface Props {
  localStorageKey: keyof PickOnlyFieldsOfType<PersistedStorageState, number>;
  title: string;
}

const GeometryPropertyComponent: React.FC<Props> = ({
  localStorageKey,
  title,
}) => {
  const [ value, setValue ] = usePersistedStorageValueWithChange(localStorageKey);
  return (
    <Property title={title}>
      <MeasurementInput
        value={value}
        onChange={setValue}
        unit={UnitTypes.M}
      />
    </Property>
  );
};


export const GeometryProperty = React.memo(GeometryPropertyComponent);
