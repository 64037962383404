import { Constants } from 'common/components/data-base-table/constants';
import { RowData, UserInfo } from 'common/components/data-base-table/interfaces';
import { Group, Item } from '../../interfaces';
import { calcPath } from '../calc-path';
import { mapDate } from '../map-date';
import { RenderIconsUtils } from '../render-icons';

export const mapItems = (items: Item[], groups: Group[], users: UserInfo[]): RowData[] => {
  const entityPathMap = calcPath(groups, (e) => e.id);

  const result: RowData[] = groups.map((group) => {
    return {
      ...group,

      h_path: entityPathMap[group.id],
      h_isDragSource: true,
      h_isDragTarget: true,
    };
  });

  items.forEach((item) => {
    result.push({
      h_path: entityPathMap[item.folderId]
        ? `${entityPathMap[item.folderId]}${Constants.DATA_PATH_SPLITER}${item.id}`
        : `${item.id}`,
      h_isDragSource: true,
      h_isDragTarget: false,
      h_name_icon: RenderIconsUtils.renderItemIcon(item.iconType),

      id: item.id,
      folderId: item.folderId,
      name: item.name,

      createdBy: users.find(u => u.userId === item.creatorId),
      createdTime: mapDate(item.createdAt),
      lastEditBy: users.find(u => u.userId === item.editorId),
      lastEditTime: mapDate(item.editedAt),
    });
  });

  return result;
};

