import { CheckboxButton, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { Setting } from './interfaces';
import { Styled } from './styled';

interface Props {
  setting: Setting;
  checked: boolean;
  onClick: (setting: Setting) => void;
}

export class PresetTableSetting extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return (
      <Styled.CheckboxWithLabel onClick={this.onClick}>
        <CheckboxButton checked={this.props.checked} />
        <Text fontSize={14}>{this.props.setting.name}</Text>
      </Styled.CheckboxWithLabel>
    );
  }

  @autobind
  private onClick(): void {
    this.props.onClick(this.props.setting);
  }
}
