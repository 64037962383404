import styled from 'styled-components';
import { ContentStyled } from '../content';

const Container = styled.div<{ isSelected: boolean, resolved: boolean }>`
  border: 1px solid ${p => p.theme.color.background};
  background: ${p => p.resolved ? p.theme.color.disabled : p.theme.color.background};
  border-radius: 20px;
  margin: 15px;
  transition: all .175s cubic-bezier(0, 0, 1, 1);
  border: 1px solid ${p => p.isSelected ? p.theme.color.turquoise : p.theme.color.background};
  background: ${p => p.isSelected ? p.theme.color.pale : p.theme.color.background} 0% 0% no-repeat padding-box;

  svg {
    width: 20px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
  }

  &:hover {
    border: 1px solid ${p => p.isSelected ? p.theme.color.turquoise : p.theme.color.pale};
    cursor: pointer;
  }
`;

const Content = styled.div<{ isExpand: boolean }>`
  margin: 10px;
  border-radius: 15px;
  border: 1px solid ${p => p.theme.color.background};
  background: ${p => p.theme.color.backgroundRush};
  max-height: ${p => p.isExpand ? '250px' : 'fit-content'};
  position: relative;
  overflow: hidden;

  ${ContentStyled.Menus} {
    button {
      pointer-events: ${p => p.isExpand ? 'none' : 'auto'};
    }
    div {
      pointer-events: ${p => p.isExpand ? 'none' : 'auto'};
    }
  }
`;

const Header = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: row;

  > div {
    > svg {
      margin-right: 15px;
      fill: ${p => p.theme.color.gray};
    }
  }
`;


export const Styled = {
  Container,
  Content,
  Header,
};
