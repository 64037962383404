import { Switcher } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  toggleKeepOld: () => void;
  isKeepOld: boolean;
  children?: JSX.Element | JSX.Element[];
}

export const KeepOld: React.FC<Props> = ({ toggleKeepOld, isKeepOld, children }) => {
  return (
    <Styled.Switcher>
      {children}
      <Switcher size="s" onChange={toggleKeepOld} checked={isKeepOld} />
    </Styled.Switcher>
  );
};
