import classNames from 'classnames';
import * as React from 'react';

import './measurements-extractor-item-container.scss';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const MeasurementsExtractorItemContainer: React.FC<Props> = ({ className, onClick, children }) => {
  const containerClassName = classNames(
    'measurements-extractor-item-container',
    className,
  );
  return (
    <div
      className={containerClassName}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
