import uuid from 'uuid';

import { BroadcastCommunicator, BroadcastConfig } from '../communicator/broad-cast-communicator';
import { Communicator } from '../communicator/communicator';

export class MultiViewData {
  public readonly id: string;
  public secondViewId: string = null;
  public disable: boolean = false;
  public readonly channel: Communicator<BroadcastConfig> = new BroadcastCommunicator();

  public constructor(id?: string) {
    this.id = id || uuid.v4();
  }
}
