import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { useCurrentDrawingInfo } from '../../hooks';
import { DrawingsFiles } from '../../interfaces/drawings-file-info';

export function useCreateScreenshot(create: (name: string) => Promise<void>): () => Promise<void> {
  const currentDrawing = useCurrentDrawingInfo();
  const files = useSelector<State, DrawingsFiles>(x => x.drawings.files);
  const createCallback = useCallback(() => {
    const currentFile = files.entities[currentDrawing?.pdfId];
    return create(`${currentFile.properties.name}-${currentDrawing.name}`);
  }, [currentDrawing, files, create]);
  return createCallback;
}
