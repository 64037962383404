import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import * as React from 'react';

import { HorizontalSelector } from './horizontal-selector';


export interface HorizontalTabProps {
  name: string;
  className?: string;
  disabled?: boolean;
}

export const HorizontalTab: React.FC<HorizontalTabProps> = (props) => {
  return (<div className={props.className}>{props.children}</div>);
};

interface ContainerProps {
  children: Array<React.ReactElement<HorizontalTabProps>>;
  className?: string;
  defaultTabIndex?: number;
  onItemClick?(): void;
}

interface ContainerState {
  activeTabIndex: number;
  tabNames: string[];
  /**
   * true for index means tab is available
   */
  tabsAvailabilityState: boolean[];
}

// TODO: refactor
export class HorizontalTabsContainer extends React.Component<ContainerProps, ContainerState> {
  constructor(props: ContainerProps) {
    super(props);

    const activeTabIndex = props.defaultTabIndex || props.children.findIndex(t => !t.props.disabled);
    if (activeTabIndex === -1) {
      console.error('No available tabs');
    }

    this.state = {
      tabNames: props.children.map(t => t.props.name),
      activeTabIndex,
      tabsAvailabilityState: props.children.map(t => !t.props.disabled),
    };
  }

  public static getDerivedStateFromProps(props: ContainerProps, state: ContainerState): Partial<ContainerState> | null {
    const newTabNames = props.children.map(x => x.props.name);
    const newTabsAvailabilityState = props.children.map(x => !x.props.disabled);

    const updates: Partial<ContainerState> = {};

    if (!isEqual(state.tabNames, newTabNames)) {
      updates.tabNames = newTabNames;
    }

    if (!isEqual(state.tabsAvailabilityState, newTabsAvailabilityState)) {
      updates.tabsAvailabilityState = newTabsAvailabilityState;
    }

    if (!newTabsAvailabilityState[state.activeTabIndex]) {
      updates.activeTabIndex = newTabsAvailabilityState.findIndex(t => t);
    }

    if (Object.keys(updates).length) {
      return updates;
    }

    return null;
  }

  public render(): React.ReactNode {
    return (
      <div className={classNames('horizontal-tabs-container', this.props.className)}>
        <HorizontalSelector
          activeItem={this.state.tabNames[this.state.activeTabIndex]}
          items={this.state.tabNames}
          itemsAvailabilityState={this.state.tabsAvailabilityState}
          renderer={this.nameRenderer}
          onItemClick={this.onSelectTab}
        />
        {
          this.props.children[this.state.activeTabIndex]
        }
      </div>);
  }

  @autobind
  private onSelectTab(item: string): void {
    this.setState({ activeTabIndex: this.state.tabNames.indexOf(item) });
    if (this.props.onItemClick) {
      this.props.onItemClick();
    }
  }

  private nameRenderer(item: string): string {
    return item;
  }
}
