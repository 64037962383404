import { useCallback } from 'react';

import { PiaBreakdownNode } from '../interfaces';
import { UpdateFunction } from './use-options-state';

export type HandleUpdateOption = (updatedOption: PiaBreakdownNode) => void;

export const useUpdateOption = (updateOptions: UpdateFunction): HandleUpdateOption => {
  return useCallback(
    (updatedOption: PiaBreakdownNode) => {
      updateOptions((_ops, opsMap) => {
        const opt = opsMap[updatedOption.id];
        opt.name = updatedOption.name
          ? updatedOption.name
          : opt.name;
        opt.code = updatedOption.code !== undefined
          ? updatedOption.code === null
            ? undefined
            : updatedOption.code
          : opt.code;
      });
    },
    [updateOptions],
  );
};
