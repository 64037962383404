import { Popover } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';
import { PopoverOrigin } from '@material-ui/core/Popover';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './engine-popover.scss';

import { KreoButton, KreoScrollbars } from 'common/UIKit';
import { GroupedIdsByName, IdsByName } from '../../interfaces';
import { ActivityPopoverItem } from './activity-popover-item';

interface EnginePopoverProps {
  groups: GroupedIdsByName[];
  title: string;
  popoverOperation: EnginePopoverOperationType;
  anchorX: number;
  anchorY: number;
  onSelect: (ids: number[]) => void;
}

interface State {
  selectedItemNames: string[];
  selectedGroupId: number;
}

const anchorOrigin: PopoverOrigin = {
  horizontal: 'left',
  vertical: 'top',
};
const transformOrigin: PopoverOrigin = {
  horizontal: 'left',
  vertical: 'top',
};
const paperProps: PaperProps = {
  classes: {
    root: 'engine-popover__paper',
  },
};

export type EnginePopoverOperationType = 'select' | 'deselect';

export class EnginePopover extends React.Component<EnginePopoverProps, State> {
  constructor(props: EnginePopoverProps) {
    super(props);
    this.state = { selectedItemNames: [], selectedGroupId: null };
  }
  public render(): React.ReactNode {
    const { groups } = this.props;
    const isOpen = !!(groups && (groups.length > 1 || groups[0] && groups[0].items.length > 1));
    const hasSelectedItems = this.hasSelectedItems();
    return (
      <Popover
        open={isOpen}
        anchorReference='anchorPosition'
        anchorPosition={{
          left: this.props.anchorX,
          top: this.props.anchorY,
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        hideBackdrop={true}
        className={classNames('engine-popover', { ['engine-popover--with-footer']: hasSelectedItems })}
        PaperProps={paperProps}
      >
        <div className='engine-popover__title'>
          {this.props.title} {this.props.popoverOperation === 'deselect' ? '(Deselect)' : null}
        </div>
        <div className='engine-popover__content'>
        <KreoScrollbars>
        {
          groups && groups.length === 1 ? this.renderGroupItems(groups[0].items, groups[0].id) : null
        }
        {
          groups && groups.length > 1 ? (
            groups.map(group => this.renderGroup(group))
          ) : null
        }
        </KreoScrollbars>
        </div>
        {
          this.hasSelectedItems() ? (
            <div className='engine-popover__footer'>
              <KreoButton size='small' onClick={this.selectManyItems}>Ok</KreoButton>
            </div>
          ) : null
        }
      </Popover>
    );
  }

  private renderGroup(group: GroupedIdsByName): JSX.Element {
    return (
      <React.Fragment key={group.name}>
        <p>{group.name}</p>
        {this.renderGroupItems(group.items, group.id)}
      </React.Fragment>
    );
  }

  private renderGroupItems(items: IdsByName[], groupId: number): JSX.Element[] {
    const isDisabled = Number.isInteger(this.state.selectedGroupId) && this.state.selectedGroupId !== groupId;

    return items && items.map(item => (
      <ActivityPopoverItem
        key={`${groupId}${item.name}`}
        isSelected={!isDisabled && this.state.selectedItemNames.includes(item.name)}
        isDisabled={isDisabled}
        item={item}
        groupId={groupId}
        onClick={this.props.onSelect}
        selectItem={this.onItemSelect}
      />
    ));
  }

  private hasSelectedItems(): boolean {
    for (const isSelected in this.state.selectedItemNames) {
      if (this.state.selectedItemNames[isSelected]) {
        return true;
      }
    }

    return false;
  }

  @autobind
  private onItemSelect(groupId: number, name: string): void {
    let selectedItemNames = this.state.selectedItemNames;
    if (selectedItemNames.includes(name)) {
      selectedItemNames = selectedItemNames.filter(x => x !== name);
    } else {
      selectedItemNames = selectedItemNames.concat(name);
    }

    this.setState({
      selectedItemNames,
      selectedGroupId: selectedItemNames.length ? groupId : null,
    });
  }

  @autobind
  private selectManyItems(): void {
    const selectedIds = this.props.groups
      .find(x => x.id === this.state.selectedGroupId).items
      .filter(x => this.state.selectedItemNames.includes(x.name))
      .reduce((a, b) => a.concat(b.ids), []);

    this.props.onSelect(selectedIds);
  }
}
