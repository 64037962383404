import React, { useCallback } from 'react';
import { TablePreset } from '2d/interfaces';
import { PresetMenu } from './preset-menu';

interface Props {
  instancesIds: string[];
  onSelectPreset: (ids: string[], preset: TablePreset) => void;
}


export const PresetMenuShortWrap: React.FC<Props> = ({
  onSelectPreset,
  instancesIds,
}) => {
  const onSelect = useCallback((data: TablePreset) => {
    onSelectPreset(instancesIds, data);
  }, [instancesIds, onSelectPreset]);

  return (
    <PresetMenu
      onSelect={onSelect}
    />
  );
};
