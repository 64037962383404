import styled from 'styled-components';


const Container = styled.div`
  width: 100%;
  margin: 15px 0 19px;
  padding: 15px 0 50px;
  border-top: 10px solid ${p => p.theme.color.pale};
  border-bottom: 10px solid ${p => p.theme.color.pale};
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 20px 0 20px;
`;

const Icon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;

  > svg {
    width: 100%;
    height: 100%;
    fill: ${p => p.theme.color.red};

    circle,
    path {
      fill: ${p => p.theme.color.red};
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;


export const Styled = {
  Container,
  Wrapper,
  Icon,
  Text,
};
