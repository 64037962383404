import * as Lodash from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call, delay, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import {
  DrawingsScaleInfo,
  DrawingsShortInfo,
} from 'common/components/drawings/interfaces/drawings-short-drawing-info';
import { ActionWith } from 'common/interfaces/action-with';
import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { AutoMeasureActions } from '../actions/creators';
import { DrawingsAnnotationActions } from '../actions/creators/annotation';
import { DrawingsActions } from '../actions/creators/common';
import { DrawingsAnnotationLegendActions } from '../actions/creators/drawings-annotation-legend';
import { MagicSearchActions } from '../actions/creators/magic-search';
import { DrawingsUpdateActions } from '../actions/creators/update';
import {
  AddTabs,
  DrawingsDeletePage,
  DrawingsEditFileTreeItem,
  DrawingsToggleFileOptimize,
  DuplicatePagePayload,
  LoadFilePayload,
  MoveTab,
  ToggleDrawingOptimizePayload,
  UpdateComparisonSettingPayload,
} from '../actions/payloads/common';
import {
  DrawingGroupChanges,
  DrawingsBeUpdatesState,
  DrawingsPageUpdates,
  DrawingsSelectedPagesUpdates,
  DrawingsUpdateFiles,
  Rescale,
} from '../actions/payloads/update';
import { DrawingsActionTypes } from '../actions/types/common';
import { DrawingsApi } from '../api/drawings-api';
import { DrawingsDrawMode, MagicSearchDrawModes } from '../enums';
import { DrawingChangeOperation } from '../enums/drawing-change-operation';
import { DrawingChangeSource } from '../enums/drawing-change-source';
import { DrawingsPageResponse } from '../interfaces/drawings-api-payload';
import { DrawingsFile } from '../interfaces/drawings-file-info';
import { DrawingsState } from '../interfaces/drawings-state';
import { DrawingsUploadPdfResult } from '../interfaces/drawings-upload-pdf-result';
import { DrawingsPdfBrowserUtils } from '../utils/drawings-pdf-browser-utils';
import { drawingsExportSaga } from './export-saga';
import { drawingsInstancesVisibilitySaga } from './instances-visibility';
import { magicSearchSaga } from './magic-search-saga';
import { pdfFilteresSaga } from './pdf-filter';
import { DrawingsSagaSelectors, ProjectAndDrawings } from './selectors';
import { wizzardSaga } from './wizzard-saga';

function* addFolder({ payload: parentFolderId }: ActionWith<string>): SagaIterator {
  try {
    const drawings: DrawingsState = yield selectWrapper((x) => x.drawings);
    const filesInfo = drawings.files;
    const children = parentFolderId ? filesInfo.entities[parentFolderId].children : filesInfo.rootIds;
    const entity = filesInfo.entities[children[children.length - 1]];
    yield put(
      DrawingsUpdateActions.commitUpdates(
        [
          {
            operation: DrawingChangeOperation.Create,
            entityId: entity.id,
            currentEntity: { id: entity.id, parentFolderId, name: entity.properties.name },
          },
        ],
        DrawingChangeSource.FileSystem,
      ),
    );
  } catch (error) {
    console.error('drawings: add folder failed', error, { parentFolderId });
  }
}

function* uploadPdf({ payload }: ActionWith<DrawingsUploadPdfResult[]>): SagaIterator {
  try {
    const files = new Array<DrawingsUpdateFiles>();
    for (const file of payload) {
      files.push({
        operation: DrawingChangeOperation.Create,
        entityId: file.pdf.id,
        currentEntity: file.pdf,
      });
    }
    yield put(DrawingsUpdateActions.commitUpdates(files, DrawingChangeSource.FileSystem));
  } catch (error) {
    console.error('drawings: upload pdf failed', error, payload);
  }
}

function* deleteFromFileSystem({ payload }: ActionWith<string>): SagaIterator {
  try {
    const {
      drawings: { selectedPages, files, currentDrawingInfo },
    }: ProjectAndDrawings = yield selectWrapper(DrawingsSagaSelectors.getProjectRequiredInfo);
    const fileChanges = new Array<DrawingsFile>();
    const folderChanges = new Array<DrawingsEditFileTreeItem>();
    const drawingsIds = new Array<string>();
    const fileIds = [];
    const entity = files.entities[payload];
    let entities = { ...files.entities };
    if (DrawingsPdfBrowserUtils.isFile(entity)) {
      fileChanges.push(entity);
      for (const page of entity.pages) {
        drawingsIds.push(page.drawingId);
      }
      fileIds.push(entity.id);
    } else {
      const filesForRemove = entity.children.slice();
      folderChanges.push({ id: entity.id, parentFolderId: entity.parentId, name: '' });
      while (filesForRemove.length > 0) {
        const child = files.entities[filesForRemove.pop()];
        if (DrawingsPdfBrowserUtils.isFile(child)) {
          fileIds.push(child.id);
          fileChanges.push(child);
          for (const page of child.pages) {
            drawingsIds.push(page.drawingId);
          }
        } else {
          folderChanges.push({ id: child.id, parentFolderId: child.parentId, name: child.properties.name });
          arrayUtils.extendArray(filesForRemove, child.children);
        }
        delete entities[child.id];
      }
    }
    const newSelectedPages = selectedPages.filter((page) => !drawingsIds.includes(page));
    let currentPage = currentDrawingInfo && currentDrawingInfo.drawingId;
    if (currentDrawingInfo && drawingsIds.includes(currentDrawingInfo.drawingId)) {
      const reversedSelectedPage = [...selectedPages].reverse();
      const lastDeletableSelectedPageIndex =
        selectedPages.length - 1 - reversedSelectedPage.findIndex((id) => drawingsIds.includes(id));
      currentPage =
        selectedPages.slice(lastDeletableSelectedPageIndex + 1).find((page) => !drawingsIds.includes(page)) ||
        reversedSelectedPage.find((page) => !drawingsIds.includes(page));
      yield put(DrawingsActions.selectDrawing(currentPage));
    }
    const fileSystem = new MonoliteHelper(files);
    if (entity.parentId) {
      entities = new MonoliteHelper(entities)
        .setFilter(
          (_) => _[entity.parentId].children,
          (x) => x !== payload,
        )
        .get();
    } else {
      fileSystem.setFilter(
        (_) => _.rootIds,
        (x) => x !== payload,
      );
    }
    delete entities[payload];
    yield put(DrawingsAnnotationActions.removePagesData(drawingsIds));
    yield put(
      DrawingsActions.deleteDrawingFromLocal(
        fileSystem.set((_) => _.entities, entities).get(),
        drawingsIds,
        fileIds,
        newSelectedPages,
      ),
    );
    yield put(
      DrawingsUpdateActions.commitUpdates(
        [
          {
            currentEntity: entity,
            files: fileChanges,
            folders: folderChanges,
            entityId: payload,
            operation: DrawingChangeOperation.Delete,
            currentPage,
          },
        ],
        DrawingChangeSource.FileSystem,
      ),
    );
  } catch (error) {
    console.error('drawings: delete from file system failed', error, payload);
  }
}

function* deletePageFromFileSystem({ payload: { drawingId, pdfId } }: ActionWith<DrawingsDeletePage>): SagaIterator {
  try {
    const {
      drawings: { selectedPages, files, currentDrawingInfo },
    }: ProjectAndDrawings = yield selectWrapper(DrawingsSagaSelectors.getProjectRequiredInfo);
    const entity = files.entities[pdfId] as DrawingsFile;
    if (entity.pages.length < 2) {
      yield put(DrawingsActions.deleteDrawing(pdfId));
    } else {
      const newSelectedPages = selectedPages.filter((id) => id !== drawingId);
      let currentPage = currentDrawingInfo ? currentDrawingInfo.drawingId : undefined;
      if (currentPage === drawingId) {
        const selectedPageIndex = selectedPages.findIndex((id) => id === drawingId);
        currentPage = newSelectedPages[selectedPageIndex]
          ? newSelectedPages[selectedPageIndex]
          : newSelectedPages[selectedPageIndex - 1];
        yield put(DrawingsActions.selectDrawing(currentPage));
      }
      yield put(DrawingsAnnotationActions.removePagesData([drawingId]));
      yield put(DrawingsActions.deleteDrawingFromLocal(files, [drawingId], [], newSelectedPages));
      yield put(
        DrawingsUpdateActions.commitUpdates(
          [
            {
              id: drawingId,
              operation: DrawingChangeOperation.Delete,
              selectedPages: newSelectedPages,
              currentPage,
            },
          ],
          DrawingChangeSource.Page,
        ),
      );
    }
  } catch (error) {
    console.error('drawings: delete page failed', error, drawingId, pdfId);
  }
}

function* loadAllData(): SagaIterator {
  try {
    const projectId = yield selectWrapper((x) => x.projects.currentProject?.id);
    if (!projectId) {
      return;
    }
    const fs: any = yield call(DrawingsApi.loadFileSystem, projectId);
    const autoMeasureOptions = yield call(DrawingsApi.loadAutoMeasureAvailableOptions);
    yield put(AutoMeasureActions.saveAvailableOptions(autoMeasureOptions));
    yield put(DrawingsActions.setFileSystem(fs));
    yield put(DrawingsAnnotationLegendActions.loadGroups(projectId));
    yield put(DrawingsAnnotationActions.loadFullGeometry());
    const currentPage = fs.currentPage || fs.selectedPages[0];
    if (currentPage) {
      yield put(DrawingsActions.selectDrawing(currentPage));
      if (!fs.currentPage) {
        yield put(DrawingsActions.saveSelectedDrawingsState(currentPage));
      }
    }
  } catch (error) {
    console.error('drawings: load file tree failed', error);
  }
}

function* editFileTreeItemName({ payload }: ActionWith<DrawingsEditFileTreeItem>): SagaIterator {
  try {
    const { drawings }: ProjectAndDrawings = yield selectWrapper(DrawingsSagaSelectors.getProjectRequiredInfo);
    if (
      !drawings.files.entities[payload.parentFolderId] ||
      DrawingsPdfBrowserUtils.isFolder(drawings.files.entities[payload.parentFolderId])
    ) {
      yield put(
        DrawingsUpdateActions.commitUpdates(
          [
            {
              entityId: payload.id,
              currentEntity: payload,
              operation: DrawingChangeOperation.Update,
            },
          ],
          DrawingChangeSource.FileSystem,
        ),
      );
      yield put(
        TwoDElementViewActions.handleRenameFile(payload),
      );
    }
  } catch (error) {
    console.error('drawings: edit file tree item name failed', error, payload);
  }
}

function createPageUdpateInfo(drawing: DrawingsShortInfo): DrawingsPageUpdates {
  return {
    operation: DrawingChangeOperation.Update,
    originalCalibrationLineLength: drawing.originalCalibrationLineLength,
    drawingCalibrationLineLength: drawing.drawingCalibrationLineLength,
    paperSize: drawing.paperSize,
    name: drawing.name,
    id: drawing.drawingId,
    color: drawing.color,
    rotationAngle: drawing.rotationAngle,
    isOptimized: drawing.isOptimized,
  };
}

function* commitPagesUpdates(drawingIds: string[]): SagaIterator {
  try {
    const { drawings }: ProjectAndDrawings = yield selectWrapper(DrawingsSagaSelectors.getProjectRequiredInfo);

    const changes: DrawingsPageUpdates[] = [];

    for (const drawingId of drawingIds) {
      const drawing = drawings.drawingsInfo[drawingId];
      changes.push(createPageUdpateInfo(drawing));
    }
    yield put(DrawingsUpdateActions.commitUpdates(changes, DrawingChangeSource.Page));
  } catch (error) {
    console.error('drawings: commit page update', error, drawingIds);
  }
}

function* pageMetaParameterUpdate({ payload }: ActionWith<{ drawingId: string }>): SagaIterator {
  try {
    yield call(commitPagesUpdates, [payload.drawingId]);
    yield put(TwoDElementViewActions.handleRenamePage(payload as any));
  } catch (error) {
    console.error('drawings: page parameter update failed', error, payload);
  }
}

function* pagesRescale({ payload }: ActionWith<DrawingsScaleInfo[]>): SagaIterator {
  try {
    const { drawings, projectId }: ProjectAndDrawings = yield selectWrapper(
      DrawingsSagaSelectors.getProjectRequiredInfo,
    );
    const drawingsInfo = payload.map((scaleInfo) => {
      const oldDrawingInfo = drawings.drawingsInfo[scaleInfo.drawingId];
      const newDrawingInfo: Rescale = {
        id: scaleInfo.drawingId,
      };
      if (oldDrawingInfo.drawingCalibrationLineLength && oldDrawingInfo.originalCalibrationLineLength) {
        newDrawingInfo.originalCalibrationLineLength = oldDrawingInfo.originalCalibrationLineLength;
        newDrawingInfo.drawingCalibrationLineLength = oldDrawingInfo.drawingCalibrationLineLength;
      }
      if (oldDrawingInfo.paperSize) {
        newDrawingInfo.paperSize = oldDrawingInfo.paperSize;
      }
      return newDrawingInfo;
    });
    yield call(DrawingsApi.reScale, projectId, drawingsInfo);
  } catch (error) {
    yield put(DrawingsActions.loadDataFailed());
    console.error('drawings: page parameter update failed', error, payload);
  }
}

function* updateSelectedPages({ payload }: ActionWith<DrawingsSelectedPagesUpdates>): SagaIterator {
  try {
    yield call(DrawingsApi.updateSelectedPages, payload);
  } catch (error) {
    console.error('drawings: update selected pages failed', error, payload);
  }
}

function* saveSelectedDrawingsState({ payload }: ActionWith<string>): SagaIterator {
  try {
    const {
      drawings: { selectedPages },
    }: ProjectAndDrawings = yield selectWrapper(DrawingsSagaSelectors.getProjectRequiredInfo);
    yield put(DrawingsActions.updateSelectedPages({ selectedPages, currentPage: payload }));
  } catch (error) {
    console.error('drawings: select page failed', error, payload);
  }
}

function* addTabs({ payload }: ActionWith<AddTabs>): SagaIterator {
  try {
    const {
      drawings: { selectedPages, currentDrawingInfo },
    }: ProjectAndDrawings = yield selectWrapper(DrawingsSagaSelectors.getProjectRequiredInfo);
    const selectedPageIdsMap = new Set(selectedPages);
    const filteredIds = payload.entityIds.filter((id) => !selectedPageIdsMap.has(id));
    const isNewSelectedDrawing = payload.openLastPage || !currentDrawingInfo;
    const selectedDrawing = isNewSelectedDrawing ? filteredIds[filteredIds.length - 1] : currentDrawingInfo.drawingId;
    if (isNewSelectedDrawing) {
      yield put(DrawingsActions.selectDrawing(filteredIds[filteredIds.length - 1]));
    }
    yield put(DrawingsActions.setTabs(selectedPages.concat(filteredIds)));
    yield put(DrawingsActions.saveSelectedDrawingsState(selectedDrawing));
  } catch (error) {
    console.error('drawings: add tab failed', error, payload);
  }
}

function* moveTab({ payload }: ActionWith<MoveTab>): SagaIterator {
  try {
    const {
      drawings: { selectedPages, currentDrawingInfo },
    }: ProjectAndDrawings = yield selectWrapper(DrawingsSagaSelectors.getProjectRequiredInfo);
    const newSelectedPages = [...selectedPages];
    const elementIndex = selectedPages.indexOf(payload.id);
    const indexAfterMove = elementIndex + payload.offset;
    newSelectedPages.splice(elementIndex, 1);
    newSelectedPages.splice(indexAfterMove, 0, selectedPages[elementIndex]);
    yield put(
      DrawingsActions.updateSelectedPages({
        selectedPages: newSelectedPages,
        currentPage: currentDrawingInfo.drawingId,
      }),
    );
    yield put(DrawingsActions.setTabs(newSelectedPages));
  } catch (error) {
    console.error('drawings: add tab failed', error, payload);
  }
}

function* removeTabs({ payload }: ActionWith<string[]>): SagaIterator {
  try {
    const {
      drawings: { selectedPages, currentDrawingInfo },
    }: ProjectAndDrawings = yield selectWrapper(DrawingsSagaSelectors.getProjectRequiredInfo);
    if (!currentDrawingInfo) {
      return;
    }
    let newSelectedPage;
    if (!payload.includes(currentDrawingInfo.drawingId)) {
      newSelectedPage = currentDrawingInfo.drawingId;
    } else {
      const currentTabIndex = selectedPages.indexOf(currentDrawingInfo.drawingId);
      const enumerationList = [
        ...Lodash.range(currentTabIndex - 1, -1, -1),
        ...Lodash.range(currentTabIndex + 1, selectedPages.length),
      ].map((i) => selectedPages[i]);
      const removesTabsIds = new Set(payload);
      newSelectedPage = enumerationList.find((id) => !removesTabsIds.has(id));
    }
    const removedTabsMap = new Set(payload);
    const newSelectedPages = selectedPages.filter((page) => !removedTabsMap.has(page));
    yield put(DrawingsActions.setTabs(newSelectedPages));
    yield put(DrawingsActions.selectDrawing(newSelectedPage));
    yield put(DrawingsActions.saveSelectedDrawingsState(newSelectedPage));
  } catch (error) {
    console.error('drawings: add tab failed', error, payload);
  }
}

function* loadFileData({ payload }: ActionWith<LoadFilePayload>): SagaIterator {
  try {
    const file = yield call(DrawingsApi.getFileData, payload.projectId, payload.fileId);
    yield put(DrawingsActions.loadFileDataSuccess(file));
  } catch (error) {
    console.error('load file data error', error, payload);
  }
}

function* duplicatePage({ payload }: ActionWith<DuplicatePagePayload>): SagaIterator {
  try {
    const { data, projectId, pdfId, pageId, onDuplicated } = payload;
    const newPage: DrawingsPageResponse = yield call(DrawingsApi.duplicatePage, projectId, pdfId, pageId, data);
    yield put(DrawingsActions.duplicatePageSuccess(pdfId, newPage));
    if (onDuplicated) {
      onDuplicated();
    }
  } catch (error) {
    console.error('drawings: duplicate page error', error, payload);
  }
}

function* updateCompare(currentId: string): SagaIterator {
  const drawing = yield selectWrapper((x) => x.drawings.drawingsInfo[currentId]);
  yield call(DrawingsApi.updateCompare, [drawing]);
}

function* updateCompareSetting({ payload: { currentId } }: ActionWith<UpdateComparisonSettingPayload>): SagaIterator {
  try {
    yield delay(500);
    yield call(updateCompare, currentId);
  } catch (error) {
    console.error('drawings: save compare error', error, currentId);
  }
}

function* removeCompare({ payload: currentId }: ActionWith<string>): SagaIterator {
  try {
    yield call(updateCompare, currentId);
  } catch (error) {
    console.error('drawings: reset compare error', error, currentId);
  }
}

function* toggleOptimize({ payload }: ActionWith<ToggleDrawingOptimizePayload>): SagaIterator {
  try {
    const { drawingId, withReload } = payload;
    if (withReload) {
      const projectId = yield selectWrapper((x) => x.projects.currentProject.id);
      const drawing = yield selectWrapper((x) => x.drawings.drawingsInfo[drawingId]);
      const update: DrawingsBeUpdatesState = {
        createdFolders: [],
        createdFiles: [],

        updateFileSystemForms: [],
        updatedPages: [createPageUdpateInfo(drawing)],

        deletedFileSystemItems: [],
        deletedPages: [],
      };
      yield call(DrawingsApi.update, projectId, update);
      window.location.reload();
    } else {
      yield call(commitPagesUpdates, [drawingId]);
    }
  } catch (error) {
    console.error('drawings toggle optimize error', error, payload);
  }
}

function* toggleFileOptimize({ payload }: ActionWith<DrawingsToggleFileOptimize>): SagaIterator {
  try {
    const { drawingsInfo }: DrawingsState = yield selectWrapper((state) => state.drawings);
    const drawingsToUpdate = arrayUtils.filterMap(
      Object.values(drawingsInfo),
      (d) => d.pdfId === payload.fileId,
      (d) => d.drawingId,
    );
    yield call(commitPagesUpdates, drawingsToUpdate);
  } catch (error) {
    console.error('drawings toggle file optimize error', error, payload);
  }
}

function* updatePin(): SagaIterator {
  try {
    const pinnedGroupIds = yield selectWrapper((s) => s.drawings.pinnedGroupIds);
    const groupsUpdate: DrawingGroupChanges = {
      operation: DrawingChangeOperation.Pin,
      data: { groups: pinnedGroupIds, measurements: [] },
    };
    yield put(DrawingsUpdateActions.commitUpdates([groupsUpdate], DrawingChangeSource.Groups));
  } catch (error) {
    console.error('drawings: update pin error', error);
  }
}

function* setDrawMode({ payload }: ActionWith<DrawingsDrawMode>): SagaIterator {
  try {
    if (!MagicSearchDrawModes.includes(payload)) {
      yield put(MagicSearchActions.cancelSearch());
    }
  } catch (error) {
    console.error('drawings: set draw mode error', error);
  }
}

export function* drawingsSaga(): SagaIterator {
  yield takeLatest(DrawingsActionTypes.ADD_FOLDER, addFolder);
  yield takeLatest(DrawingsActionTypes.UPLOAD_PDF, uploadPdf);
  yield takeLatest(DrawingsActionTypes.DELETE_DRAWING, deleteFromFileSystem);
  yield takeLatest(DrawingsActionTypes.DELETE_PAGE, deletePageFromFileSystem);
  yield takeLatest(DrawingsActionTypes.LOAD_DRAWINGS_DATA, loadAllData);
  yield takeLatest(DrawingsActionTypes.EDIT_FILE_TREE_ITEM, editFileTreeItemName);
  yield takeLatest(DrawingsActionTypes.PAGE_META_PARAMETER_UPDATE, pageMetaParameterUpdate);
  yield takeLatest(DrawingsActionTypes.PAGES_RESCALE, pagesRescale);
  yield takeLatest(DrawingsActionTypes.UPDATE_SELECTED_PAGES, updateSelectedPages);
  yield takeLatest(DrawingsActionTypes.ADD_TABS, addTabs);
  yield takeLatest(DrawingsActionTypes.REMOVE_TABS, removeTabs);
  yield takeLatest(DrawingsActionTypes.SAVE_SELECTED_DRAWINGS_STATE, saveSelectedDrawingsState);
  yield takeLatest(DrawingsActionTypes.MOVE_TAB, moveTab);
  yield takeEvery(DrawingsActionTypes.LOAD_FILE_DATA, loadFileData);
  yield takeLatest(DrawingsActionTypes.DUPLICATE_PAGE, duplicatePage);
  yield takeLatest(DrawingsActionTypes.UPDATE_COMPARE_SETTING, updateCompareSetting);
  yield takeLatest(DrawingsActionTypes.SET_PAGE_TO_COMPARE, updateCompareSetting);
  yield takeLatest(DrawingsActionTypes.REMOVE_COMPARISON, removeCompare);
  yield takeLatest(DrawingsActionTypes.TOGGLE_DRAWING_OPTIMIZE, toggleOptimize);
  yield takeLatest(DrawingsActionTypes.TOGGLE_FILE_OPTIMIZE, toggleFileOptimize);
  yield takeLatest(DrawingsActionTypes.CHANGE_GROUP_PIN_STATUS, updatePin);
  yield takeLatest(DrawingsActionTypes.UNPIN_GROUPS, updatePin);
  yield takeLatest(DrawingsActionTypes.PIN_GROUPS, updatePin);
  yield takeLatest(DrawingsActionTypes.SET_DRAW_MODE, setDrawMode);

  yield fork(drawingsExportSaga);
  yield fork(drawingsInstancesVisibilitySaga);
  yield fork(wizzardSaga);
  yield fork(magicSearchSaga);
  yield fork(pdfFilteresSaga);
}
