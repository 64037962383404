import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  &, * {
    box-sizing: border-box;
  }
  padding: 0px 10px;
`;

const GroupBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
  > button {
    width: 100%;
    min-height: 40px;
  }
`;

const AutoScale = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Styled = {
  Container,
  GroupBlock,
  AutoScale,
};
