const RATE = 0;
const TOTAL = 1;
const OVERHEAD = 2;
const OVERHEAD_PERC = 3;
const DURATION = 4;

export {
  RATE,
  TOTAL,
  OVERHEAD,
  OVERHEAD_PERC,
  DURATION
}