const prefix = '@database-activity-listing';

export const DatabaseActivityListingActionTypes = {
  SET_SELECTED_CATEGORY_TYPE: `${prefix}/SET_SELECTED_CATEGORY_TYPE`,
  SET_SELECTED_CATEGORY: `${prefix}/SET_SELECTED_CATEGORY`,
  OPEN_EDIT_ACTIVITY: `${prefix}/OPEN_EDIT_ACTIVITY`,
  OPEN_EDIT_ACTIVITY_VARIANT: `${prefix}/OPEN_EDIT_ACTIVITY_VARIANT`,
  OPEN_CREATE_ACTIVITY: `${prefix}/OPEN_CREATE_ACTIVITY`,
  OPEN_CREATE_ACTIVITY_VARIANT: `${prefix}/OPEN_CREATE_ACTIVITY_VARIANT_ID`,
  CLOSE_EDIT_PANEL: `${prefix}/CLOSE_EDIT_PANEL`,
  RESET_REQUEST_STATUSES: `${prefix}/RESET_REQUEST_STATUSES`,
  RESET_LOAD_ACTIVITY_REQUEST_STATUS: `${prefix}/RESET_LOAD_ACTIVITY_REQUEST_STATUS`,
  SET_EDIT_ACTIVITY_MODEL: `${prefix}/SET_EDIT_ACTIVITY_MODEL`,
  SET_EDIT_ACTIVITY_VARIANT_MODEL: `${prefix}/SET_EDIT_ACTIVITY_VARIANT_MODEL`,
  SELECT_ACTIVITY_VARIANT: `${prefix}/SELECT_ACTIVITY_VARIANT`,
  SET_DISABLED_ACTIVITY_VARIANT_IDS: `${prefix}/SET_DISABLED_ACTIVITY_VARIANT_IDS`,
  SET_SELECTED_ACTIVITY_VARIANT_IDS: `${prefix}/SET_SELECTED_ACTIVITY_VARIANT_IDS`,
  SET_DEFAULT_CATEGORIES: `${prefix}/SET_DEFAULT_CATEGORIES`,
  SET_LAST_SELECTED_EXTRACTOR_FUNCTION: `${prefix}/SET_LAST_SELECTED_EXTRACTOR_FUNCTION`,
  SET_LAST_SELECTED_UNIT: `${prefix}/SET_LAST_SELECTED_UNIT`,
};
