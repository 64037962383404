import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { dialogAnimationDelay } from './kreo-dialog';

interface Props {
  size?: 'large';
  isModal: boolean;
  dialogName: string;
  overlayClassName: string | undefined;
  bodyClassName: string | undefined;
  open: boolean;
  onClose: () => void;
}

export class DialogContainer extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
        <CSSTransition in={this.props.open} classNames='kreo-dialog__overlay' timeout={dialogAnimationDelay}>
          <div
            className={classNames('kreo-dialog__overlay', this.props.overlayClassName)}
            onClick={this.onOverlayClick}
            data-control-name='dialog-overlay'
          >
            <div
              id={this.props.dialogName}
              className={classNames(
                'kreo-dialog__data-container',
                this.props.size,
                this.props.bodyClassName,
              )}
              onClick={this.onDataContainerClick}
            >
              {this.props.open ? this.props.children : null}
            </div>
          </div>
        </CSSTransition>
    );
  }

  @autobind
  private onOverlayClick(): void {
    if (this.props.isModal) {
      this.props.onClose();
    }
  }

  private onDataContainerClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }
}
