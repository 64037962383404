import { useCallback, useEffect, useMemo } from 'react';
import { usePersistedStorageValueWithChange } from 'persisted-storage/hooks';
import { DrawingsDrawMode } from '../../enums';
import { InstrumentInfo } from '../interfaces';

function useCurrentGroupInstrument(
  groupId: string,
): [ DrawingsDrawMode, (mode: DrawingsDrawMode) => void ] {
  const [
    groupInstruments,
    setInstruments,
  ] = usePersistedStorageValueWithChange('drawingMeasurementCategoryInstrument');
  const setter = useCallback((mode: DrawingsDrawMode) => {
    setInstruments({ ...groupInstruments, [groupId]: mode });
  }, [groupId, groupInstruments, setInstruments]);

  return [ groupInstruments[groupId], setter ];
}


export function useSelectedSubvariants(
  subvariants: InstrumentInfo[],
  activeMenuItem: DrawingsDrawMode,
  canBeActive: boolean,
  groupId: string,
): InstrumentInfo {
  const [ currentItemId, setItemId ] = useCurrentGroupInstrument(groupId);

  const activeInstrument = useMemo(() => {
    const bySelected = canBeActive && subvariants.find((item) => item.id === activeMenuItem);
    return bySelected || currentItemId && subvariants.find((item) => item.id === currentItemId);
  }, [activeMenuItem, subvariants, canBeActive, currentItemId]);

  useEffect(() => {
    if (activeInstrument) {
      setItemId(activeInstrument.id);
    }
  }, [activeInstrument, setItemId]);

  return activeInstrument || subvariants[0];
}

