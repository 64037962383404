import React from 'react';

import { UuidUtil } from 'common/utils/uuid-utils';
import { TextInput } from '../../inputs';
import { FormField } from '../form-field';
import { useEditContext } from '../use-edit-context';

interface Props {
  label: string;
  input: {
    value: string,
    onChange: (newValue: string) => void,
  };
  onDeleteClick?: any;
  disabled?: boolean;
  isHide?: boolean;
  onVisibilityClick?: any;
  dropDownProps?: any;
}

export const FormTextInputField: React.FC<Props> = (props) => {
  const {
    label,
    input,
    onDeleteClick,
    disabled,
  } = props;

  const { value, onChange } = input;
  const inputId = React.useMemo(() => UuidUtil.generateUuid(), []);
  const { startEditing, editIcon } = useEditContext(onDeleteClick);

  return (
    <FormField
      label={label}
      inputId={inputId}
      startEditing={startEditing}
      editIcon={editIcon}
    >
      <TextInput value={value} onBlur={onChange} inputId={inputId} disabled={disabled} />
    </FormField>
  );
};
