import classNames from 'classnames';
import * as React from 'react';

import './wait-button.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import KreoButton, { KreoButtonProps } from './kreo-button';


interface Props extends KreoButtonProps {
  waitCaption?: string | JSX.Element;
  waitTooltip?: string;
  isWaiting: boolean;
}

export class WaitButton extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const className = classNames(
      this.props.className,
      {
        'wait-button--waiting': this.props.isWaiting,
      },
    );
    return (
      <KreoButton
        {...this.props}
        className={className}
        caption={this.props.isWaiting ? this.props.waitCaption : this.props.caption}
        tooltip={this.props.isWaiting ? this.props.waitTooltip : this.props.tooltip}
        disabled={this.props.isWaiting || this.props.disabled}
        icon={this.props.isWaiting ? <SvgSpinner size='small'/> : this.props.icon}
      />
    );
  }
}
