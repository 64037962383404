function* flatTreeIterator<T>(items: Iterable<T>, getChild: (item: T) => Iterable<T>): IterableIterator<T> {
  if (items) {
    for (const item of items) {
      yield item;
      yield* flatTreeIterator(getChild(item), getChild);
    }
  }
}

function* flatTreeMapIterator<T, K>(
  items: Iterable<T>,
  getChild: (item: T) => Iterable<T>,
  map: (item: T) => K,
): IterableIterator<K> {
  for (const item of flatTreeIterator(items, getChild)) {
    yield map(item);
  }
}

export const TreeUtils = {
  flatTreeIterator,
  flatTreeMapIterator,
};
