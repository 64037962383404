import { TreeTableValueGetterParams } from 'common/components/tree-table/interfaces';
import { ValueHelper } from 'common/utils/value-helper';
import { AggFuncType } from '../../interfaces/quantity-take-off';

export enum TreeTableAgg {
  Sum = 'Sum',
}

export const reportTableAggregations: Record<string, AggFuncType> = {
  [TreeTableAgg.Sum]: (values) => {
    if (!values || !values.length) {
      return null;
    }

    let result = 0;
    for (const value of values) {
      if (value === null || Number.isNaN(Number(value))) {
        return null;
      }

      result += value;
    }

    return result;
  },
};

const getAggregationFunction = (aggFunc: AggFuncType) => {
  return (params: TreeTableValueGetterParams): number | null => {
    const { node, api, columnId } = params;
    if (!api) {
      return null;
    }

    const values: number[] = [];
    for (const child of node.childrenAfterGroup) {
      if (!child) {
        return null;
      }

      const value = api.getValue(columnId, child);
      if (!ValueHelper.isNumberValue(value)) {
        return null;
      }
      values.push(Number(value));
    }

    return aggFunc(values);
  };
};


export const aggregationFunctions = Object.entries(reportTableAggregations)
  .reduce(
    (result, [key, aggFunc]) => {
      result[key] = getAggregationFunction(aggFunc);
      return result;
    },
    {});
