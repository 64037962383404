import dotProp from 'dot-prop-immutable';
import { cloneDeep } from 'lodash';
import { KreoColors } from 'common/enums/kreo-colors';
import { arrayUtils } from 'common/utils/array-utils';
import {
  CEActivityAssignmentActivity,
  CEActivityAssignmentActivityLink,
  CEActivityAssignmentAggregatedBimInfo,
  CEActivityAssignmentAggregatedData,
  CEActivityAssignmentDataMaterial,
  CEActivityAssignmentDataSelectedVariant,
  CEActivityAssignmentDataTreeNode,
  CEActivityAssignmentResponse,
  CEActivityAssignmentState,
  CEActivityAssignmentTreeCategory,
  CEActivityAssignmentTreeElementType,
  CEActivityAssignmentTreeFamily,
  CEActivityAssignmentTreeNode,
  CEActivityAssignmentTreeNodeActivityResponse,
  CEActivityAssignmentTreeNodeActivitySort,
  CEActivityAssignmentTreeNodeResponse,
} from 'unit-cost-estimate/interfaces';
import { RevitTreeLevel } from 'unit-projects/enums/revit-tree-level';
import {
  ActivityAssignmentElementProperties,
} from 'unit-projects/interfaces/activity-assignment/activity-assignment-element-properties';
import { RevitPropertiesResponseInfo } from 'unit-projects/interfaces/revit-properties-response-info';
import { RevitPropertyNamedInfo } from 'unit-projects/interfaces/revit-property-named-info';
import { RevitTreeFullInfo } from 'unit-projects/interfaces/revit-tree-full-info';
import { RevitTreeLinearData } from 'unit-projects/interfaces/revit-tree-linear-data';
import { ObjectStatisticType } from '../../../components/charts/interfaces/object-statistic-type';
import { MaterialModel } from '../../../units/databases/interfaces/resources-data';
import { ActivityVariantActivityAssignmentInfoModel, ActivityVariantResource } from '../../databases/interfaces/data';
import {
  FinishLayer,
  Layer,
  LayerMaterial,
  PropertiesGroupAggregatedValues,
  PropertiesGroupToPropertiesMap,
} from '../../project-dashbord';
import {
  mapLayersToProperties,
  mapRawData,
  proccessFinishLayers,
  ungroupRawData,
} from '../../project-dashbord/utils/mappers';


function itemIsSelected(
  { isShowSelection, selectedPath }: CEActivityAssignmentState,
  path: string,
  level: number,
): boolean {
  return isShowSelection && level > 0 && path === selectedPath;
}


function convertActivitiesToTreeItems(
  activities: CEActivityAssignmentActivity[],
  start: number,
  errorIds: Set<number>,
): RevitTreeLinearData[] {
  const revitLineData = new Array<RevitTreeLinearData>();
  for (let i = 0; i < activities.length; i++) {
    const { name, idsStart, idsEnd, id } = activities[i];
    revitLineData[i] = {
      name,
      expandable: false,
      expanded: false,
      path: `${start + i}`,
      level: RevitTreeLevel.Element,
      startIds: idsStart,
      endIds: idsEnd,
      error: errorIds.has(id),
      errors: null,
      all: null,
      changedManually: isActivityChangedManually(activities[i].data),
      hasDiff: activities[i].diffType !== 0,
    };
  }
  return revitLineData;
}

function isActivityChangedManually(data: CEActivityAssignmentDataTreeNode): boolean {
  return isVariantManuallyChanged(data) || isMaterialManuallyChanged(data);
}

function isVariantManuallyChanged(data: CEActivityAssignmentDataTreeNode): boolean {
  return !!(data && data.selectedVariant && data.selectedVariant.manualId);
}

function isMaterialManuallyChanged(data: CEActivityAssignmentDataTreeNode): boolean {
  const selectedVariantId = getSelectedVariantId(data.selectedVariant);
  if (!selectedVariantId) {
    return false;
  }

  const variant = data.variants[selectedVariantId];
  for (const materialId of variant.materials) {
    if (data.materials[materialId].selectedVariant.manualId) {
      return true;
    }
  }

  if (variant.primaryMaterial || variant.secondaryMaterial) {
    if (
      data.materials[variant.primaryMaterial].selectedVariant.manualId
      || data.materials[variant.secondaryMaterial].selectedVariant.manualId
    ) {
      return true;
    }
  }

  return false;
}

function extractManualChangesActivityLinks(
  links: CEActivityAssignmentActivityLink[],
  level: RevitTreeLevel,
  id: number = null,
): CEActivityAssignmentActivityLink[] {
  switch (level) {
    case RevitTreeLevel.Category:
      return links.filter(({ manuallyChanged }) => manuallyChanged);
    case RevitTreeLevel.Family:
      return links.filter(({ category }) => category === id);
    case RevitTreeLevel.ElementType:
      return links.filter(({ family }) => family === id);
    case RevitTreeLevel.Element:
      return links.filter(({ type }) => type === id);
    default:
      return links;
  }
}

function extractDiffActivityLinks(
  links: CEActivityAssignmentActivityLink[],
  level: RevitTreeLevel,
  id: number = null,
): CEActivityAssignmentActivityLink[] {
  switch (level) {
    case RevitTreeLevel.Category:
      return links.filter(({ hasDiff }) => hasDiff);
    case RevitTreeLevel.Family:
      return links.filter(({ category }) => category === id);
    case RevitTreeLevel.ElementType:
      return links.filter(({ family }) => family === id);
    case RevitTreeLevel.Element:
      return links.filter(({ type }) => type === id);
    default:
      return links;
  }
}

function extractErrorActivityLinks(
  links: CEActivityAssignmentActivityLink[],
  level: RevitTreeLevel,
  id: number = null,
): CEActivityAssignmentActivityLink[] {
  switch (level) {
    case RevitTreeLevel.Category:
      return links.filter(({ isGood }) => !isGood);
    case RevitTreeLevel.Family:
      return links.filter(({ category }) => category === id);
    case RevitTreeLevel.ElementType:
      return links.filter(({ family }) => family === id);
    case RevitTreeLevel.Element:
      return links.filter(({ type }) => type === id);
    default:
      return links;
  }
}

function getNeededIndexes(links: CEActivityAssignmentActivityLink[], level: RevitTreeLevel): number[] {
  switch (level) {
    case RevitTreeLevel.ElementType:
      return links.map(({ type }) => type);
    case RevitTreeLevel.Family:
      return links.map(({ family }) => family);
    case RevitTreeLevel.Category:
    default:
      return links.map(({ category }) => category);
  }
}

function getNeededIndex(
  { type, family, category }: CEActivityAssignmentActivityLink,
  level: RevitTreeLevel = RevitTreeLevel.Category,
): number {
  switch (level) {
    case RevitTreeLevel.ElementType:
      return type;
    case RevitTreeLevel.Family:
      return family;
    case RevitTreeLevel.Category:
    default:
      return category;
  }
}

type TreeNodeType = CEActivityAssignmentTreeCategory
  | CEActivityAssignmentTreeFamily
  | CEActivityAssignmentTreeElementType;

function expandTreeNode(
  treeNode: TreeNodeType,
  lineData: RevitTreeLinearData,
  searched: boolean,
  index: number,
  errorLinks: CEActivityAssignmentActivityLink[],
  manualChangesLinks: CEActivityAssignmentActivityLink[],
  diffLinks: CEActivityAssignmentActivityLink[],
  activities: CEActivityAssignmentActivity[],
  pathes: string[],
  level: RevitTreeLevel,
  searchMode: boolean,
): RevitTreeLinearData[] {
  const currentErrors = extractErrorActivityLinks(errorLinks, level + 1, index);
  if (level === 2 && lineData.expandable && treeNode.expanded) {
    const currentErrorIds = currentErrors.map(({ work }) => work);
    return convertActivitiesToTreeItems(
      activities.slice(treeNode.start, treeNode.end),
      treeNode.start,
      new Set(currentErrorIds),
    );
  } else if (lineData.expandable && treeNode.expanded) {
    const isSearchModeOn = !searched && searchMode;
    return treeToList(
      treeNode.children as any,
      activities,
      isSearchModeOn,
      pathes,
      currentErrors,
      manualChangesLinks,
      diffLinks,
      level + 1,
      `${lineData.path}.children.`,
    );
  } else {
    return [];
  }
}


function treeToList(
  tree: TreeNodeType[],
  works: CEActivityAssignmentActivity[],
  searchMode: boolean,
  pathes: string[],
  errorLinks: CEActivityAssignmentActivityLink[],
  manualChangesLinks: CEActivityAssignmentActivityLink[],
  diffLinks: CEActivityAssignmentActivityLink[],
  level: RevitTreeLevel = RevitTreeLevel.Category,
  parrentPath: string = 'tree.',
): RevitTreeLinearData[] {
  const list = new Array<RevitTreeLinearData>();

  const errors = level === 0 ? extractErrorActivityLinks(errorLinks, level) : errorLinks;
  const manualChanges = level === 0 ? extractManualChangesActivityLinks(manualChangesLinks, level) : manualChangesLinks;
  const diffs = level === 0 ? extractDiffActivityLinks(diffLinks, level) : diffLinks;
  const errorIndexes = getNeededIndexes(errors, level);
  const manualChangesIndexes = getNeededIndexes(manualChanges, level);
  const diffIndexes = getNeededIndexes(diffs, level);
  const errorSet = new Set<number>(errorIndexes);
  const manualChangesSet = new Set<number>(manualChangesIndexes);
  const diffsSet = new Set<number>(diffIndexes);
  tree.forEach((value, index) => {
    const errorIds = errors.filter((val) => getNeededIndex(val, level) === index);
    const childrenManualChanges = manualChanges.filter(x => getNeededIndex(x, level) === index);
    const childrenDiffs = diffs.filter(x => getNeededIndex(x, level) === index);
    const data: RevitTreeLinearData = {
      level,
      path: `${parrentPath}${index}`,
      name: value.name,
      expandable: !!value.children || value.end - value.start > 1,
      expanded: value.expanded,
      startIds: value.idsStart,
      endIds: value.idsEnd,
      error: errorSet.has(index),
      all: value.idCount,
      errors: errorIds.length > 0 ? errorIds.map(({ idCount }) => idCount).reduce((x, y) => x + y) : 0,
      changedManually: manualChangesSet.has(index),
      hasDiff: diffsSet.has(index),
    };
    const treePath = data.path.replace('tree.', '');
    if (searchMode && pathes.find(path => path.startsWith(treePath)) || !searchMode) {
      list.push(data);
      list.push(...expandTreeNode(
        value, data, true, index, errors, childrenManualChanges, childrenDiffs, works, pathes, level, searchMode,
      ));
    }
  });
  return list;
}

function getDiffType(state: CEActivityAssignmentState): number {
  const { selectedPath } = state;
  if (selectedPath) {
    if (/\./.test(selectedPath)) {
      const data: CEActivityAssignmentTreeElementType = dotProp.get(state, selectedPath);
      return state.works.slice(data.start, data.end)[0].diffType;
    } else {
      const work = state.works[selectedPath];
      return work.diffType;
    }
  } else {
    return 0;
  }
}

function getSelectedIds(state: CEActivityAssignmentState): number[] {
  const { selectedPath } = state;
  if (selectedPath && state.isShowSelection) {
    if (/\./.test(selectedPath)) {
      const data: CEActivityAssignmentTreeElementType = dotProp.get(state, selectedPath);
      return state.engineIds.slice(data.idsStart, data.idsEnd);
    } else {
      const work = state.works[selectedPath];
      return work.engineIds;
    }
  } else {
    return [];
  }
}

function sortTree<U>(
  items: Array<CEActivityAssignmentTreeNodeResponse<U>> | U[],
  level: RevitTreeLevel,
): Array<CEActivityAssignmentTreeNodeResponse<U>> | U[] {
  if (level === RevitTreeLevel.Element) {
    return items;
  }
  for (const value of (items as Array<CEActivityAssignmentTreeNodeResponse<U>>)) {
    if (value.children && value.children.length > 0) {
      value.children = sortTree(value.children, level + 1) as U[];
    } else if (level === RevitTreeLevel.ElementType && (value as any).id) { // финт ушами, очень плохой финт
      value.children = sortTree([{ ...value }], level + 1) as U[];
    }
  }
  return arrayUtils.sortByField(items as Array<CEActivityAssignmentTreeNodeResponse<U>>, 0, items.length - 1, 'name');
}

function extractEngineIdsFromWorks(
  acivity: CEActivityAssignmentTreeNodeActivityResponse,
  all: boolean = false,
): number[] {
  if (all) {
    if (acivity.geometryLessIds && acivity.geometryLessIds.length > 0) {
      return acivity.geometryLessIds;
    } else {
      return acivity.engineIds;
    }
  } else {
    return acivity.engineIds;
  }
}


function isActivityBad(activityData: CEActivityAssignmentDataTreeNode): boolean {
  const { selectedVariant } = activityData;
  return !selectedVariant || !selectedVariant.autoId && !selectedVariant.manualId;
}

function createElement(
  start: number,
  end: number,
  name: string,
  idsStart: number,
  idsEnd: number,
  idCount: number,
): CEActivityAssignmentTreeElementType {
  return {
    start,
    end,
    expanded: false,
    selected: false,
    name,
    idsStart,
    idsEnd,
    idCount,
  };
}

function createFamily(
  children: CEActivityAssignmentTreeElementType[],
  name: string,
): CEActivityAssignmentTreeFamily {
  const firstChild = children[0];
  const lastChild = children[children.length - 1];
  return {
    start: firstChild.start,
    end: lastChild.end,
    children,
    selected: false,
    expanded: false,
    name,
    idsStart: firstChild.idsStart,
    idsEnd: lastChild.idsEnd,
    idCount: children.map((_) => _.idCount).reduce((x, y) => x + y),
  };
}

function createCategory(
  children: CEActivityAssignmentTreeFamily[],
  name: string,
): CEActivityAssignmentTreeCategory {
  const firstChild = children[0];
  const lastChild = children[children.length - 1];
  return {
    start: firstChild.start,
    end: lastChild.end,
    children,
    name,
    expanded: false,
    selected: false,
    idsStart: firstChild.idsStart,
    idsEnd: lastChild.idsEnd,
    idCount: children.map((_) => _.idCount).reduce((x, y) => x + y),
  };
}

const createBaseStatistic = (goodName: string, badName: string):
{ goodStatistic: ObjectStatisticType, badStatistic: ObjectStatisticType } => {
  const goodStatistic: ObjectStatisticType = {
    name: goodName,
    color: KreoColors.f3,
    amount: 0,
    children: [],
  };
  const badStatistic: ObjectStatisticType = {
    name: badName,
    children: [],
    amount: 0,
    color: '#FFC642',
  };
  return { goodStatistic, badStatistic };
};


function serverDataMapper(serverData: CEActivityAssignmentResponse): CEActivityAssignmentAggregatedData {
  const serverTree = sortTree(serverData.tree, RevitTreeLevel.Category);
  const engineIds = new Array<number>();
  const sortedActivities = new Array<CEActivityAssignmentActivity>();
  const categories = new Array<CEActivityAssignmentTreeCategory>();
  const { goodStatistic, badStatistic } = createBaseStatistic('Assigned', 'Undefined');

  let badIds = new Array<number>();
  const workLinks = new Array<CEActivityAssignmentActivityLink>();
  serverTree.forEach((category, categoryId) => {
    const stat = createBaseStatistic(category.name, category.name);
    const goodCategoryStatistic = stat.goodStatistic;
    goodCategoryStatistic.children = undefined;
    goodCategoryStatistic.bimHandleIds = [];
    const badCategoryStatistic = stat.badStatistic;
    badCategoryStatistic.children = undefined;
    badCategoryStatistic.bimHandleIds = [];

    const families = new Array<CEActivityAssignmentTreeFamily>();
    category.children.forEach((family, familyId) => {
      const types = new Array<CEActivityAssignmentTreeElementType>();
      family.children.forEach((type, typeId) => {
        let elementIdCount = 0;
        const familyActivities = new Array<CEActivityAssignmentActivity>();
        for (const activity of type.children as CEActivityAssignmentTreeNodeActivityResponse[]) {
          const familyActivity: CEActivityAssignmentActivity = {
            ...activity,
            idsEnd: 0,
            idsStart: 0,
          };
          familyActivity.idsStart = engineIds.length;
          const workIdCount = extractEngineIdsFromWorks(activity, true).length;
          elementIdCount += workIdCount;
          const shortActivityInfo: CEActivityAssignmentActivityLink = {
            work: familyActivity.id,
            category: categoryId,
            family: familyId,
            type: typeId,
            isGood: true,
            idCount: workIdCount,
            manuallyChanged: isActivityChangedManually(activity.data),
            hasDiff: activity.diffType !== 0,
          };

          if (isActivityBad(activity.data)) {
            badIds = badIds.concat(activity.engineIds);
            if (activity.geometryLessIds.length > 0) {
              badCategoryStatistic.amount += activity.geometryLessIds.length;
            } else {
              badCategoryStatistic.amount += activity.engineIds.length;
            }
            shortActivityInfo.isGood = false;
            badCategoryStatistic.bimHandleIds = badCategoryStatistic.bimHandleIds.concat(activity.engineIds);
          } else {
            if (activity.geometryLessIds.length > 0) {
              goodCategoryStatistic.amount += activity.geometryLessIds.length;
            } else {
              goodCategoryStatistic.amount += activity.engineIds.length;
            }
            shortActivityInfo.isGood = true;
            goodCategoryStatistic.bimHandleIds = goodCategoryStatistic.bimHandleIds.concat(activity.engineIds);
          }
          workLinks.push(shortActivityInfo);
          arrayUtils.extendArray(engineIds, extractEngineIdsFromWorks(activity));
          familyActivity.idsEnd = engineIds.length;
          familyActivities.push(familyActivity);
        }
        if (familyActivities.length > 0) {
          const start = sortedActivities.length;
          arrayUtils.extendArray(sortedActivities, familyActivities);
          const end = sortedActivities.length;
          const bimHandleIdsStart = familyActivities[0].idsStart;
          const bimHandleIdsEnd = familyActivities[familyActivities.length - 1].idsEnd;
          const newElement =
            createElement(start, end, type.name, bimHandleIdsStart, bimHandleIdsEnd, elementIdCount);
          types.push(newElement);
        }
      });
      const newFamily = createFamily(types, family.name);
      families.push(newFamily);
    });
    const newCategory = createCategory(families, category.name);
    categories.push(newCategory);
    goodStatistic.amount += goodCategoryStatistic.amount;
    badStatistic.amount += badCategoryStatistic.amount;
    goodStatistic.children.push(goodCategoryStatistic);
    badStatistic.children.push(badCategoryStatistic);
  });
  return {
    statistic: [goodStatistic, badStatistic],
    tree: categories,
    works: sortedActivities,
    workLinks,
    badIds,
    engineIds,
    databases: serverData.databaseIds,
  };
}

function getPropertyIdMapAndLayerIdMap(info: RevitTreeFullInfo): CEActivityAssignmentAggregatedBimInfo {
  let elements = [];
  const properties: PropertiesGroupToPropertiesMap[][] = [];

  const layerProps = new Array<FinishLayer[]>();
  info.allBimInfo.forEach((category) => {
    category.v.forEach((family) => {
      family.v.forEach((type) => {
        const rd = [];
        let ids = new Array<number>();
        const layers = new Array<LayerMaterial[]>();
        type.v.forEach((element) => {
          if (element.rd) {
            rd.push(element.rd);
          }
          ids.push(element.id);
          if (element.layers) {
            layers.push(element.layers);
          }
        });
        const rawData = mapRawData(rd);
        properties.push(rawData);
        const elementsMap = ids.map((value) => {
          const dataMap: ActivityAssignmentElementProperties = {
            isLayer: false,
            rawData: properties.length - 1,
            element: value,
          };
          return dataMap;
        });
        elements = elements.concat(elementsMap);
        ids = new Array<number>();
        const layerDataList = new Array<Layer[]>();
        layers.forEach((value) => {
          const data = [];
          value.forEach((layerMaterialData) => {
            ids.push(layerMaterialData.id);
            data.push(layerMaterialData.materials[0]);
          });
          layerDataList.push(data);
        });
        const mergedLayers = proccessFinishLayers(layers);
        if (mergedLayers.length > 0) {
          layerProps.push(mergedLayers);
        }
        const layerMap = ids.map((value) => {
          const dataMap: ActivityAssignmentElementProperties = {
            isLayer: true,
            rawData: layerProps.length - 1,
            element: value,
          };
          return dataMap;
        });
        if (layerMap !== undefined) {
          elements = elements.concat(layerMap);
        }
      });
    });
  });
  return {
    layersData: layerProps,
    elementDataMap: elements,
    rawData: properties,
    dataDictionary: info.rawDataDictionary,
    groupNames: info.parameterGroupDictionary,
  };
}

function getActivityPath(
  { category, family, type }: CEActivityAssignmentActivityLink,
): string {
  return `${category}.children.${family}.children.${type}`;
}


function getElementPathById(state: CEActivityAssignmentState, id: number): { path: string, to: number } {
  const tree = state.tree;
  const works = state.works;
  const indexOfId = state.engineIds.findIndex((value) => value === id);
  const workIndex = works.findIndex((value) => {
    return value.idsStart <= indexOfId && value.idsEnd > indexOfId;
  });
  const work = works[workIndex];
  const link = state.workLinks.find((value) => value.work === work.id);
  const node = tree[link.category].children[link.family].children[link.type];
  const scroll = link.category + link.family + link.type;
  if (node.end - node.start > 1) {
    return {
      path: `${link.category}.children.${link.family}.children.${link.type}.${workIndex}`,
      to: scroll,
    };
  } else {
    return {
      path: `${link.category}.children.${link.family}.children.${link.type}`,
      to: scroll,
    };
  }
}

function getMaterial(material: ActivityVariantResource<MaterialModel>): CEActivityAssignmentDataMaterial {
  return {
    variants: material.resource.variants.map(x => ({
      id: x.id,
      name: x.name,
    })),
    selectedVariant: { autoId: material.resource.variants[0].id },
  };
}

function addToMaterialsIfNeeded(
  materialsMap: Record<number, CEActivityAssignmentDataMaterial>,
  material: ActivityVariantResource<MaterialModel>,
): void {
  if (!(material.resource.id in materialsMap)) {
    materialsMap[material.resource.id] = getMaterial(material);
  }
}

function appendActivityVariantMaterialsToMaterialsMap(
  materialsMap: Record<number, CEActivityAssignmentDataMaterial>,
  activityVariant: ActivityVariantActivityAssignmentInfoModel,
): void {
  activityVariant.materials.forEach(
    material => addToMaterialsIfNeeded(materialsMap, material),
  );

  if (activityVariant.materialBasedCrewHours) {
    const { primaryMaterial, secondaryMaterial } = activityVariant.materialBasedCrewHours;
    addToMaterialsIfNeeded(materialsMap, primaryMaterial);
    addToMaterialsIfNeeded(materialsMap, secondaryMaterial);
  }
}

function putModalityToActivity(
  activity: CEActivityAssignmentTreeNodeActivitySort, variants: ActivityVariantActivityAssignmentInfoModel[],
): CEActivityAssignmentTreeNodeActivitySort {
  const cloneOfTargetActivity = cloneDeep(activity);
  const data = cloneOfTargetActivity.data;
  for (const activityVariant of variants) {
    if (activityVariant.id in data.variants) {
      continue;
    }

    appendActivityVariantMaterialsToMaterialsMap(data.materials, activityVariant);
    data.variants[activityVariant.id] = {
      name: activityVariant.name,
      materials: activityVariant.materials.map(x => x.resource.id),
    };
    if (activityVariant.materialBasedCrewHours) {
      const { primaryMaterial, secondaryMaterial } =  activityVariant.materialBasedCrewHours;
      data.variants[activityVariant.id].primaryMaterial = primaryMaterial.resource.id;
      data.variants[activityVariant.id].secondaryMaterial = secondaryMaterial.resource.id;
    }
  }
  data.selectedVariant.manualId = variants[0].id;
  return cloneOfTargetActivity;
}

function getBimHandleIds({ engineIds, geometryLessIds }: CEActivityAssignmentActivity): number[] {
  let bimHandleIds = new Array<number>();
  bimHandleIds = bimHandleIds.concat(engineIds);
  bimHandleIds = bimHandleIds.concat(geometryLessIds);
  return bimHandleIds;
}


const namesOfProperties = new Set([
  'Enable Analytical Model',
  'Function',
  'System Name',
  'System Classification',
  'Service Type',
  'System Type',
  'Category',
  'Family',
  'Type',
]);

const valuesMap = {
  Type: {
    name: 'Element Type',
  },
  ['Enable Analytical Model']: {
    name: 'Enable Analytical Model',
    values: ['no', 'yes'],
  },
};

function mapPropertyNames(properties: RevitPropertiesResponseInfo[]): RevitPropertyNamedInfo[] {
  const props = properties.filter((value) => namesOfProperties.has(value.k));
  const finished = [];
  props.forEach((value) => {
    if (value.k in valuesMap) {
      const fieldValue = valuesMap[value.k].values ?
        valuesMap[value.k].values[parseInt(value.v, 0)] : value.v;
      const mapped: RevitPropertyNamedInfo = {
        value: fieldValue,
        key: valuesMap[value.k].name,
      };
      finished.push(mapped);
    } else {
      const mapped: RevitPropertyNamedInfo = {
        value: value.v,
        key: value.k,
      };
      finished.push(mapped);
    }
  });
  return finished;
}

function buildProperties(
  work: CEActivityAssignmentActivity,
  layers: FinishLayer[][],
  _groups: string[],
  rawData: PropertiesGroupToPropertiesMap[][],
  dataDictionary: RevitPropertiesResponseInfo[],
  elementDataMap: ActivityAssignmentElementProperties[],
): PropertiesGroupAggregatedValues[] {
  const ids = new Set(getBimHandleIds(work));
  const elementData = elementDataMap.filter(
    (value) => ids.has(value.element));
  const dataIds = new Set<number>();
  const layerIds = new Set<number>();
  elementData.forEach((value) => {
    if (value.isLayer) {
      layerIds.add(value.rawData);
    } else {
      dataIds.add(value.rawData);
    }
  });
  let data: PropertiesGroupToPropertiesMap[] = [];
  if (dataIds.size > 1) {
    const rds = [];
    dataIds.forEach((value) => {
      rds.push(rawData[value]);
    });
    data = mapRawData(rds);
  } else if (dataIds.size !== 0) {
    data = rawData[Array.from(dataIds)[0]];
  }
  const rawDataList = ungroupRawData(data, dataDictionary);
  const filteredPropertiesForElement = mapPropertyNames(rawDataList.slice());
  let layersData: FinishLayer[] = null;
  if (layerIds.size > 1) {
    layerIds.forEach((value) => {
      layersData = layersData.concat(layers[value]);
    });
  } else if (layerIds.size !== 0) {
    layersData = layers[Array.from(layerIds)[0]];
  }
  const objectData: PropertiesGroupAggregatedValues = {
    n: 'Object Properties',
    v: filteredPropertiesForElement.map((value) => {
      return { v: value.value, k: value.key };
    }),
  };
  let namedData = [objectData];
  if (!!layersData && layersData.length !== 0) {
    const workGeometryLessIds = work.geometryLessIds;
    layersData = layersData.filter((value) => {
      const layersDataIds = new Set(value.ids);
      const difference = workGeometryLessIds.filter((x) => !layersDataIds.has(x));
      return difference.length !== workGeometryLessIds.length;
    });
    namedData = namedData.concat(mapLayersToProperties(layersData));
  }
  return namedData;
}

function stateIsFull(state: CEActivityAssignmentState): boolean {
  return (state.rawData.length > 0 && state.dataDictionary.length > 0 && state.elementDataMap.length > 0);
}

function getSelectedVariantId(selectedVariant: CEActivityAssignmentDataSelectedVariant): number {
  return selectedVariant && (selectedVariant.manualId || selectedVariant.autoId);
}

function extractActivityData(activity: CEActivityAssignmentTreeNodeActivitySort): CEActivityAssignmentDataTreeNode[] {
  return Object.keys(activity.data.variants).length
    ? [activity.data]
    : [];
}

function existsPathInSelected(pathes: string[], path: string): boolean {
  return !!pathes.find((value) => value.startsWith(path) && value !== path);
}


function expandTreeByPathes<T>(
  tree: Array<CEActivityAssignmentTreeNode<T>> | T[],
  pathes: string[],
  path: string = '',
): Array<CEActivityAssignmentTreeNode<T>> | T[] {
  (tree as Array<CEActivityAssignmentTreeNode<T>>).forEach((value, index) => {
    const objectPath = `${path}${index}`;
    if (existsPathInSelected(pathes, objectPath)) {
      value.expanded = true;
      if (value.children) {
        value.children =
          expandTreeByPathes(value.children, pathes, `${objectPath}.children.`) as T[];
      }
    } else {
      value.expanded = false;
    }
  });
  return tree;
}

function containsTerm(value: string, term: string[]): boolean {
  const valueLowerCase = value.toLowerCase();
  return term.every((word) => valueLowerCase.includes(word));
}


function findPathesInTree<T>(
  tree: Array<CEActivityAssignmentTreeNode<T>> | T[],
  filter: string[],
  parrentPath: string = '',
): string[] {
  let pathes = new Array<string>();
  (tree as Array<CEActivityAssignmentTreeNode<T>>).forEach((value, index) => {
    const path = `${parrentPath}${index}`;
    if (containsTerm(value.name, filter)) {
      pathes.push(path);
    }
    if (value.children) {
      pathes = pathes.concat(
        findPathesInTree(
          value.children,
          filter,
          `${path}.children.`,
        ));
    }
  });
  return pathes;
}


function search(
  tree: CEActivityAssignmentTreeCategory[],
  filter: string): string[] {
  const filterWords = filter.toLowerCase().split(' ');
  const pathes = findPathesInTree(tree, filterWords);
  return pathes;
}

export const CEActivityAssignmentUtils = {
  getSelectedVariantId,
  itemIsSelected,
  treeToList,
  getSelectedIds,
  getDiffType,
  serverDataMapper,
  isActivityBad,
  isActivityChangedManually,
  getPropertyIdMapAndLayerIdMap,
  getActivityPath,
  getElementPathById,
  putModalityToActivity,
  buildProperties,
  stateIsFull,
  extractActivityData,
  expandTreeByPathes,
  search,
};
