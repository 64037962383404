import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import './activities.scss';

import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoIconInfoBigColor, KreoScrollbars } from 'common/UIKit';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { HelpTooltip } from '../../../../components/controls/tooltips';
import { ActivityGroupingActions, EngineActions, StepActions } from '../../actions';
import { SetEngineHighlighOnHoverDelay } from '../../constants';
import { ActivityGroupData, SequenceColor } from '../../interfaces';
import { EntityCompareHelper } from '../../utils';
import { ActivityItem } from './activity-item';
import { SelectedActivities } from './selected-activities';

interface ReduxProps {
  activitiesToShow: Array<ActivityGroupData.Activity | ActivityGroupData.UngroupedActivity>;
  activities: Record<number, ActivityGroupData.Activity>;
  ungroupedActivities: Record<number, ActivityGroupData.UngroupedActivity>;
  activeActivityId: number | null;
  selectedActivityIds: Record<number, boolean>;
}

interface ReduxActions {
  setDraggingActivity(activityId: number): void;
  setSelectedActivityIds(selectedActivityIds: Record<number, boolean>): void;
  setActiveActivityGroup(id: number | null): void;
  setSelectedBimElementIds(ids: number[]): void;
  setColoredBimElements(color: SequenceColor, ids: number[]): void;
}

interface Props extends ReduxProps, ReduxActions {
  isGrouped: boolean;
  onHover: (engineIds: number[]) => void;
}

class ActivitiesComponent extends React.Component<Props> {
  private deferredExecutor: DeferredExecutor;

  constructor(props: Props) {
    super(props);
    this.deferredExecutor = new DeferredExecutor(SetEngineHighlighOnHoverDelay);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.activeActivityId !== prevProps.activeActivityId) {
      ActivitiesComponent.scrollToActiveActivity();
    }

    if (!isEqual(this.props.selectedActivityIds, prevProps.selectedActivityIds)) {
      this.setColoredBimElements();
    }
  }

  public componentDidMount(): void {
    ActivitiesComponent.scrollToActiveActivity();
    this.setColoredBimElements();
  }

  public render(): JSX.Element {
    const tooltipText = this.props.isGrouped
      ? 'Activities automatically grouped by work zones.'
      : 'Ungrouped Activities. Activities that have not been allocated to any of the automatically created groups.';

    const selectedCount = Object.keys(this.props.selectedActivityIds).length;

    return (
      <div className='activities-area'>
        <div className='activities-area__container'>
          <div className='activities-area__header'>
            <div className='activities-area__header-value'>
              {this.props.isGrouped ? 'Activities' : 'Ungrouped Activities'}
            </div>
            <div className='activities-area__header-info'>
              <HelpTooltip
                icon={<KreoIconInfoBigColor />}
                text={tooltipText}
              />
            </div>
          </div>
          <div
            className={classNames('activities-area__content', {
              ['activities-area__content--with-selected-activities']: !!selectedCount,
            })}
          >
            <KreoScrollbars>
              <div className='activities-area__content-inner' onMouseLeave={this.onMouseLeave}>
                {this.renderActivityItemComponents(this.props.activitiesToShow)}
              </div>
            </KreoScrollbars>
          </div>
          {
            selectedCount > 0 ? (
              <div className='activities-area__footer'>
                <SelectedActivities
                  onCancel={this.resetSelectedActivities}
                  selectedActivitiesCount={selectedCount}
                />
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }

  private static scrollToActiveActivity(): void {
    const activities = document
      .getElementsByClassName('activities-area-activity-item activities-area-activity-item--active');
    const activity = activities && activities.length > 0 && activities[0] as HTMLElement;

    if (activity) {
      const container = activity.closest('.scroll-box__content');
      const activityGroupPosition = activity.parentElement.offsetTop + activity.offsetTop;

      const isVisible = container.scrollTop < activityGroupPosition &&
        activityGroupPosition < container.scrollTop + container.clientHeight;
      if (!isVisible) {
        activity.scrollIntoView();
      }
    }
  }

  @autobind
  private renderActivityItemComponents(
    activities: Array<ActivityGroupData.Activity | ActivityGroupData.UngroupedActivity>,
  ): JSX.Element[] {
    return activities
      .sort(EntityCompareHelper.compareNamedEntity)
      .map((activity) => {
        const isSelected = this.props.selectedActivityIds[activity.id];
        return (
          <ActivityItem
            key={`${activity.id}/${isSelected}`}
            id={activity.id}
            isSelected={isSelected}
            isActive={activity.id === this.props.activeActivityId}
            name={activity.name}
            setDraggingActivity={this.props.setDraggingActivity}
            onMouseOver={this.onMouseOver}
            onSelectionChange={this.onActivitySelectionChange}
            onClick={this.onActivityClick}
          />);
      });
  }

  @autobind
  private onActivitySelectionChange(id: number, isSelected: boolean): void {
    const selectedActivityIds = { ...this.props.selectedActivityIds };
    if (isSelected) {
      selectedActivityIds[id] = true;
    } else {
      delete selectedActivityIds[id];
    }

    this.props.setSelectedActivityIds(selectedActivityIds);
  }

  @autobind
  private resetSelectedActivities(): void {
    this.props.setSelectedActivityIds({});
  }

  @autobind
  private onMouseOver(id: number): void {
    this.deferredExecutor.execute(() => {
      const activity = this.props.activities[id] || this.props.ungroupedActivities[id];
      this.props.onHover(activity ? activity.engineIds : []);
    });
  }

  @autobind
  private onMouseLeave(): void {
    if (this.deferredExecutor.isWaitingForExecution()) {
      this.deferredExecutor.reset();
    }
    this.props.onHover([]);
  }

  @autobind
  private onActivityClick(id: number, event: React.MouseEvent<HTMLDivElement>): void {
    const isCtrl = HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(event);
    if (isCtrl && !event.shiftKey) {
      this.setSelectActivity([id], !this.props.selectedActivityIds[id]);
    } else if (event.shiftKey && !isCtrl && Number.isInteger(this.props.activeActivityId)) {
      const ids = this.getIdsBetweenActivities(this.props.activeActivityId, id);
      this.setSelectActivity(ids, true);
      document.getSelection().removeAllRanges();
    }

    this.setActiveActivity(id);
  }

  private setActiveActivity(id: number): void {
    if (this.props.activeActivityId !== id) {
      const activity = this.props.activities[id] || this.props.ungroupedActivities[id];
      this.props.setActiveActivityGroup(id);
      this.props.setSelectedBimElementIds(activity.engineIds);
    }
  }

  private getIdsBetweenActivities(fromId: number, toId: number): number[] {
    const sortedActivities = this.props.activitiesToShow
      .sort(EntityCompareHelper.compareNamedEntity);
    const fromIndex = sortedActivities.findIndex(x => x.id === fromId);
    const toIndex = sortedActivities.findIndex(x => x.id === toId);
    const startIndex = fromIndex < toIndex ? fromIndex : toIndex;
    const endIndex = toIndex > fromIndex ? toIndex : fromIndex;
    return sortedActivities.slice(startIndex, endIndex + 1)
      .map(x => x.id);
  }

  private setSelectActivity(ids: number[], isSelected: boolean): void {
    const selectedActivities = { ...this.props.selectedActivityIds };
    for (const id of ids) {
      if (isSelected) {
        selectedActivities[id] = true;
      } else {
        delete selectedActivities[id];
      }
    }

    this.props.setSelectedActivityIds(selectedActivities);
  }

  private setColoredBimElements(): void {
    const { activities, ungroupedActivities } = this.props;
    const engineIds = Object.keys(this.props.selectedActivityIds)
      .map(id => activities[id] && activities[id].engineIds || ungroupedActivities[id].engineIds)
      .reduce((a, b) => a.concat(b), []);

    this.props.setColoredBimElements(SequenceColor.SelectedActivity, engineIds);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const activitiesToShow = state.activityGrouping.activityGroups[state.activityGrouping.activeActivityGroupId]
    ? state.activityGrouping.activityGroups[state.activityGrouping.activeActivityGroupId].activityIds
      .map(id => state.activityGrouping.activities[id])
    : state.activityGrouping.workPackages[state.activityGrouping.activeWorkPackageId].ungroupedIds
      .map(id => state.activityGrouping.ungroupedActivities[state.activityGrouping.ungroupedActivityGroupIdToId[id]]);

  return {
    activitiesToShow,
    activities: state.activityGrouping.activities,
    ungroupedActivities: state.activityGrouping.ungroupedActivities,
    activeActivityId: state.activityGrouping.activeActivityId,
    selectedActivityIds: state.activityGrouping.activityGrouping.selectedActivityIds,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setDraggingActivity: id => dispatch(ActivityGroupingActions.setDraggingActivity(id)),
    setActiveActivityGroup: id => dispatch(StepActions.setActiveActivityOrUngroupedActivity(id)),
    setSelectedBimElementIds: ids => dispatch(EngineActions.setSelectedBimElements(ids)),
    setSelectedActivityIds: ids => dispatch(ActivityGroupingActions.setSelectedActivityIds(ids)),
    setColoredBimElements: (color, ids) => dispatch(EngineActions.setColoredBimElements({ [color]: ids })),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const Activities = connector(ActivitiesComponent);

