import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './toggle.scss';

import { KreoColors } from 'common/enums/kreo-colors';
import { ToggleIcon } from './toggle-icon';


export enum ToggleSize {
  Small = 'small',
  Medium = 'medium',
}

interface SwitchType {
  name?: string;
  controlName?: string;
  checkedColor?: string;
  checked?: boolean;
  toggleSize?: ToggleSize;
  onCheck?: (value: boolean) => void;
}


interface State {
  checked: boolean;
}

export class Toggle extends React.Component<SwitchType, State> {
  public static defaultProps: Partial<SwitchType> = {
    checkedColor: KreoColors.blue,
    toggleSize: ToggleSize.Medium,
  };

  constructor(props: SwitchType) {
    super(props);
    this.state = {
      checked: props.checked,
    };
  }

  public static getDerivedStateFromProps(props: SwitchType): State {
    return props.checked === undefined ? null : { checked: props.checked };
  }

  public render(): React.ReactNode {
    return (
      <label
        className={classNames('toggle', this.props.toggleSize)}
        onClick={this.click}
        data-control-name={this.props.controlName}
      >
        <input name={this.props.name} type='checkbox' onChange={this.check} checked={this.state.checked} />
        <ToggleIcon
          checked={this.state.checked}
          checkedColor={this.props.checkedColor}
          size={this.props.toggleSize}
        />
      </label>);
  }

  @autobind
  private click(e: React.MouseEvent<HTMLLabelElement>): void {
    e.stopPropagation();
  }

  @autobind
  private check(e: React.ChangeEvent<HTMLInputElement>): void {
    if (this.props.onCheck) {
      e.stopPropagation();
      this.props.onCheck(e.target.checked);
      this.setState({ checked: e.target.checked });
    }
  }
}
