import { Icons } from '@kreo/kreo-ui-components';
import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { CommentContext } from 'common/components/ag-grid-cell-with-comment';
import { DrawingsLayoutApi } from 'common/components/drawings';
import { State } from 'common/interfaces/state';
import { WithCommentsContext, withCommentsContext } from 'unit-2d-comments/comments-context';
import { CommentaryTargetType } from 'unit-2d-comments/enums';
import { CommentaryTakeOffPageTarget, CommentaryThread } from 'unit-2d-comments/interfaces';
import { TwoDCommentsActions } from 'unit-2d-comments/store-slice';
import { CommentaryTargetTypeGuards } from 'unit-2d-comments/utils';
import { TwoDViewTableConfig } from 'unit-2d-database/interfaces';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { DrawingElementsTable } from '../../../2d-element-view';
import { MeasureHelper } from '../../../2d-element-view/utils';

interface DispatchProps {
  startCreateComment: (target: CommentaryTakeOffPageTarget) => void;
  openComment: (comentId: number) => void;
}

interface StateProps {
  withTableTotal: boolean;
  selectedCommentId: number;
}

interface Props extends StateProps, DispatchProps, WithCommentsContext, AnalyticsProps, AbilityAwareProps {
  drawingsLayoutApi: DrawingsLayoutApi;
  tableId: string;
  tableName: string;
  config: TwoDViewTableConfig;
}

class MeasureViewComponent extends React.PureComponent<Props> {
  private measureDataHelper = new MeasureHelper();
  private tableContext: CommentContext<{}> = {
    comments: [],
    getComent: this.getComment,
    openComent: this.openComment,
    isSelected: this.isCommentSelected,
  };

  public render(): JSX.Element {
    const showCode = this.props.config.createCodeColumn;
    return (
      <DrawingElementsTable
        dataHelper={this.measureDataHelper}
        drawingApi={this.props.drawingsLayoutApi}
        tableId={this.props.tableId}
        config={this.props.config}
        tableName={this.props.tableName}
        key={`${this.props.tableId}${this.props.withTableTotal}${showCode}`}
        getExtraContextMenu={this.getExtraContextMenu}
        tableContext={this.tableContext}
      />
    );
  }

  @autobind
  private getComment(colId: string, _: number, rowId: string): CommentaryThread {
    return this.props.comments.find(c => {
      if (CommentaryTargetTypeGuards.isTakeoffPage(c.target) && c.target.reportPageId === this.props.tableId) {
        const targetColumnId = c.target.columnId;
        const targetRowId = c.target.measurementId;
        return targetColumnId === colId && rowId === targetRowId;
      }
    });
  }

  @autobind
  private isCommentSelected(colId: string, _: number, rowId: string): boolean {
    const { tableId, comments, selectedCommentId } = this.props;
    const actualComments = comments.filter(c => {
      if (CommentaryTargetTypeGuards.isTakeoffPage(c.target) && c.target.reportPageId === tableId) {
        const targetColumnId = c.target.columnId;
        const targetRowId = c.target.measurementId;
        return targetColumnId === colId && rowId === targetRowId;
      }
    });
    return actualComments.some(c => c.id === selectedCommentId);
  }

  @autobind
  private openComment(commentId: number): void {
    this.props.openComment(commentId);
    this.props.sendEvent(MetricNames.comments.openComments, { target: 'measure view' });
  }

  @autobind
  private getExtraContextMenu(param: Ag.GetContextMenuItemsParams): Array<(string | Ag.MenuItemDef)> {
    const options = [];
    if (!param || !param.column) {
      return options;
    }

    const handleAddComment = this.startCreateComment(param);
    if (this.props.ability.can(Operation.Manage, Subject.Comment2d) && handleAddComment) {
      options.push(
        {
          name: 'Add Comment',
          action: this.startCreateComment(param),
          icon: ReactDOMServer.renderToString(<Icons.Comments2D />),
        },
      );
    }
    return options;
  }

  @autobind
  private startCreateComment(param: Ag.GetContextMenuItemsParams): () => void {
    const reportPageId = this.props.tableId;
    const columnId = param.column.getColId();
    const measurementId = param.node.data?.id;
    return columnId && measurementId
      ? () => this.props.startCreateComment({
        type: CommentaryTargetType.TakeoffReportPage,
        reportPageId,
        measurementId,
        columnId,
      })
      : null;
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    withTableTotal: state.persistedStorage.showTableTotal,
    selectedCommentId: state.twoDComments.selectedCommentId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    startCreateComment: (target) => dispatch(TwoDCommentsActions.startAddComment(target)),
    openComment: (commentId) => {
      dispatch(TwoDCommentsActions.selectComment(commentId));
      dispatch(TwoDCommentsActions.setSelectedCommentId(commentId));
    },
  };
};

const ConnectedToRedux = connect(mapStateToProps, mapDispatchToProps)(withCommentsContext(MeasureViewComponent));

export const MeasureView = withAnalyticsContext(withAbilityContext(ConnectedToRedux));
