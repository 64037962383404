import { mathUtils } from 'common/utils/math-utils';

export interface CaretPosition {
  start: number;
  end: number;
}

function getCurrentSelection(element: HTMLElement): CaretPosition {
  const range = window.getSelection().getRangeAt(0);
  const preSelectionRange = range.cloneRange();
  preSelectionRange.selectNodeContents(element);
  preSelectionRange.setEnd(range.startContainer, range.startOffset);
  const start = preSelectionRange.toString().length;
  return {
    start,
    end: start + range.toString().length,
  };
}

function restoreSelection(element: HTMLElement, savedSelection: CaretPosition): void {
  let charIndex = 0;
  const range = document.createRange();
  range.setStart(element, 0);
  range.collapse(true);
  const nodeStack = [element];
  let node;
  let foundStart = false;
  let stop = false;

  while (!stop && (node = nodeStack.pop())) {
    if (node.nodeType === Node.TEXT_NODE) {
      const nextCharIndex = charIndex + node.length;
      foundStart = !foundStart && mathUtils.changeInRange(savedSelection.start, charIndex, nextCharIndex);
      stop = foundStart && mathUtils.changeInRange(savedSelection.end, charIndex, nextCharIndex);
      if (foundStart) {
        range.setStart(node, savedSelection.start - charIndex);
      }
      if (stop) {
        range.setEnd(node, savedSelection.end - charIndex);
      }
      charIndex = nextCharIndex;
    } else {
      let i = node.childNodes.length;
      while (i--) {
        nodeStack.push(node.childNodes[i]);
      }
    }
  }

  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
}

export const CaretPositioningHelper = {
  getCurrentSelection,
  restoreSelection,
};
