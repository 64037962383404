import { Icons, Text } from '@kreo/kreo-ui-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { CommentCountMark } from 'unit-2d-comments/comment-count-mark';
import { CompanyNotification } from 'unit-2d-notification/store-slice/interfaces';
import { EmptyNotification } from './empty-notification';
import { Styled } from './styled';

interface StateProps {
  notificationCount: number;
  notificationsModel: CompanyNotification[];
  isPanelOpen: boolean;
}

interface Props extends StateProps { }

const HeaderNotificationComponent: React.FC<Props> = ({ notificationCount, notificationsModel, isPanelOpen }) => {
  const [count, setCount] = useState<number>(notificationCount);

  useEffect(() => {
    if (notificationCount > 0 && !isPanelOpen || !notificationCount && !isPanelOpen) {
      setCount(notificationCount);
    }
  }, [count, notificationCount, isPanelOpen]);

  const showEmptyNotification = !!notificationsModel.length;

  return (
    <Styled.Container isNotification={showEmptyNotification}>
      <Styled.HeaderContainer>
        <Styled.IconContainer>
          <Icons.Bellka2D />
          <CommentCountMark count={count}/>
        </Styled.IconContainer>
        <Text fontSize={14}>Notifications</Text>
      </Styled.HeaderContainer>
      <RenderIf condition={!notificationsModel.length}>
        <Styled.EmptyNotificationWrapper>
          <EmptyNotification />
        </Styled.EmptyNotificationWrapper>
      </RenderIf>
    </Styled.Container>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    notificationCount: state.twoDNotifications.bellCount,
    notificationsModel: state.twoDNotifications.notifications,
    isPanelOpen: state.twoDNotifications.isPanelOpen,
  };
};

export const HeaderNotification = connect(mapStateToProps)(HeaderNotificationComponent);
