import { Switcher } from '@kreo/kreo-ui-components';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoButton } from 'common/UIKit';
import { AdminCompaniesActions } from '../../actions/creators/companies';

interface Props {
  companyId: number;
}

export const CompanyPreferImperial: React.FC<Props> = ({ companyId }) => {
  const { hasInProgressRequests, isImperial } = useSelector<
    State,
    {
      hasInProgressRequests: boolean,
      isImperial: boolean,
    }>(s => {
      const request = [
        s.admin.companies.statuses.currencies,
        s.admin.companies.statuses.updateCurrency[companyId],
      ].includes(RequestStatus.Loading);
      const company = s.admin.companies.data.find(c => c.id === companyId);

      return {
        hasInProgressRequests: request,
        isImperial: company.preferImperial,
      };
    });
  const [preferImperial, setPreferImperial] = useState(isImperial);
  const dispatch = useDispatch();

  const handleChangePreferImperial = useCallback(() => {
    setPreferImperial(!preferImperial);
  }, [preferImperial]);

  const handleSave = useCallback(() => {
    dispatch(AdminCompaniesActions.setPreferImperial(companyId, preferImperial));
  }, [preferImperial]);

  return (
    <div className='company-currency-control'>
      {
        hasInProgressRequests
          ? <SvgSpinner size='small' />
          : (<React.Fragment>
            <div className='company-currency-control__title'>Prefer Imperial</div>
            <div className='company-currency-control__body'>
              <Switcher
                size='l'
                checked={preferImperial}
                onChange={handleChangePreferImperial}
              />
              <KreoButton
                size='medium'
                mode='action'
                caption='Save Changes'
                disabled={preferImperial === isImperial}
                className='company-currency-control__save-changes-button'
                onClick={handleSave}
              />
            </div>
          </React.Fragment>)
      }
    </div>
  );
};
