import * as React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'common/components/spinner';
import { State } from 'common/interfaces/state';
import { ProgressUtils } from 'common/utils/progress-utils';
import { DrawingsProgress } from '../../drawings-progress';


export const SpinnerWrapper: React.FC = () => {
  const hasLoading = useSelector<State, boolean>(x => {
    const currentDrawingId = x.drawings.currentDrawingInfo?.drawingId;
    const progressKey = currentDrawingId ? ProgressUtils.createCurrentDrawingKey(currentDrawingId) : null;
    return x.progress[ProgressUtils.DRAWING_PROGRESS_KEY]?.progressBars.length > 0
    || x.progress[progressKey]?.progressBars.length > 0;
  });
  return hasLoading ? <DrawingsProgress /> : <Spinner show={true} withBackground={true} />;
};
