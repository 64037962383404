import { Direction } from './enums';
import { Coordinate, GraphForNode, GraphNode } from './interfaces';

export interface CoordinateAndId extends Coordinate {
  id: number | string;
}

export interface EdgePosition {
  from: CoordinateAndId;
  to: CoordinateAndId;
  direction: Direction;
}

export interface GraphPositions {
  boxes: Record<number, Box[]>;
  positions: Record<number, Coordinate>;
}


const Y_BOX_SIZE = 10;
const X_BOX_SIZE = 1;

const getPositionsInfo = (graphForNode: GraphForNode): GraphPositions => {
  const boxes: Record<number, Box[]> = {};
  const getXBoxes = (x: number): Box[] =>  {
    if (!boxes[x]) {
      boxes[x] = [];
    }

    return boxes[x];
  };
  const getBox = (x: number, y: number): Box => {
    const xBoxes = getXBoxes(x);
    if (!xBoxes[y]) {
      xBoxes[y] = {
        vertexIds: [],
        edges: {
          fully: null,
          toBottom: [],
          toTop: [],
        },
      };
    }

    return xBoxes[y];
  };


  const getVertexesPosition = (graph: GraphForNode): Record<number, Coordinate> => {
    const relativeGroups = graph.additionalInfo.relativeGroups;
    const commonChildren = graph.additionalInfo.allCommonChild;
    const onEachLevels = graph.additionalInfo.nodesOnEachLevels;
    const rootNode = graph.rootNode;

    const appendVertex = (id: number, coordinate: Coordinate): void => {
      getBox(coordinate.x, Math.trunc(coordinate.y / Y_BOX_SIZE)).vertexIds.push(id);
    };

    const getCentralColumn = (rootYCoordinate: number): Record<string, Coordinate> => {
      const position = {
        x: 0,
        y: rootYCoordinate,
      };

      appendVertex(rootNode.id, position);
      return { [rootNode.id]: position };
    };

    const getPositionForDirection = (direction): {
      positions: Record<number, Coordinate>,
      endGroupPosition: number,
    } => {
      const positions: Record<number, Coordinate> = {};
      const i = direction === Direction.Forward ? 1 : -1;
      let startGroupPosition = 0;
      let endGroupPosition = 0;
      rootNode[direction]
        .sort((a, b) => {
          if (!relativeGroups[a.id]) return -1;
          if (!relativeGroups[b.id]) return 1;
          if (relativeGroups[a.id].find(x => x.groupId === b.id)) return 0;
          const result = relativeGroups[b.id].length - relativeGroups[a.id].length;
          if (result) return result;
          return b[direction].length - a[direction].length;
        })
        .forEach(x => {
          startGroupPosition = startGroupPosition === endGroupPosition
            ? endGroupPosition + 1
            : endGroupPosition;
          endGroupPosition = startGroupPosition;
          x[direction]
            .sort(y => (commonChildren.find(child => child === y.id) ? 1 : -1))
            .forEach(y => {
              if (!positions[y.id] && onEachLevels.indexOf(y.id) === -1) {
                positions[y.id] = { x: i * 2, y: endGroupPosition };
                endGroupPosition += 1;
              }
            });

          if (!positions[x.id]) {
            positions[x.id] = {
              x: onEachLevels.indexOf(x.id) !== -1 ? i * 2 : i,
              y: Math.trunc((endGroupPosition - startGroupPosition) / 2) + startGroupPosition,
            };
          }
        });

      return {
        positions,
        endGroupPosition,
      };
    };


    const shiftTreeAndAppendBoxes = (positions, count): Record<number, Coordinate> => {
      for (const key in positions) {
        positions[key].y += count;
        appendVertex(+key, positions[key]);
      }

      return positions;
    };

    const leftTree = getPositionForDirection(Direction.Back);
    const rightTree = getPositionForDirection(Direction.Forward);
    const shift = Math.trunc(Math.abs(leftTree.endGroupPosition - rightTree.endGroupPosition) / 2);

    if (leftTree.endGroupPosition <= rightTree.endGroupPosition) {
      leftTree.positions = shiftTreeAndAppendBoxes(leftTree.positions, shift);
      rightTree.positions = shiftTreeAndAppendBoxes(rightTree.positions, 0);
    } else if (leftTree.endGroupPosition > rightTree.endGroupPosition) {
      leftTree.positions = shiftTreeAndAppendBoxes(leftTree.positions, 0);
      rightTree.positions = shiftTreeAndAppendBoxes(rightTree.positions, shift);
    }

    const rootNodeYCoordinate = Math.trunc(Math.max(leftTree.endGroupPosition, rightTree.endGroupPosition) / 2);
    const centerPositions = getCentralColumn(rootNodeYCoordinate);

    return {
      ...leftTree.positions,
      ...rightTree.positions,
      ...centerPositions,
    };
  };

  const vertexesPositions = getVertexesPosition(graphForNode);

  const appendEdgesToBoxes = (rootNode: GraphNode): void => {

    const appendEdge = (edge: EdgeIds): void => {
      const fromCoordinate = vertexesPositions[edge.fromId];
      const toCoordinate = vertexesPositions[edge.toId];
      const yMin = Math.min(fromCoordinate.y, toCoordinate.y);
      const yMax = Math.max(fromCoordinate.y, toCoordinate.y);
      const xMin = Math.min(fromCoordinate.x, toCoordinate.x);
      const xMax = Math.max(fromCoordinate.x, toCoordinate.x);

      const longestEdgeHorizontalPartY = edge.direction === Direction.Forward ? toCoordinate.y : fromCoordinate.y;
      const longestEdgeHorizontalPartYBoxNumber = Math.trunc(longestEdgeHorizontalPartY / Y_BOX_SIZE);
      const longestEdgeHorizontalPartIsToTop = yMax === longestEdgeHorizontalPartY;
      for (let x = Math.trunc(xMin / X_BOX_SIZE); x <= Math.trunc(xMax / X_BOX_SIZE); x++) {
        if (longestEdgeHorizontalPartIsToTop) {
          getBox(x, longestEdgeHorizontalPartYBoxNumber).edges.toTop.push(edge);
        } else {
          getBox(x, longestEdgeHorizontalPartYBoxNumber).edges.toBottom.push(edge);
        }
      }

      const edgeVerticalPartX = edge.direction === Direction.Forward ? xMin : xMax;
      if (longestEdgeHorizontalPartIsToTop) {
        getBox(edgeVerticalPartX, Math.trunc(yMin / Y_BOX_SIZE)).edges.toBottom.push(edge);
      } else {
        getBox(edgeVerticalPartX, Math.trunc(yMax / Y_BOX_SIZE)).edges.toTop.push(edge);
      }

      for (let y = Math.trunc(yMin / Y_BOX_SIZE) + 1; y < Math.trunc(yMax / Y_BOX_SIZE); y++) {
        const box = getBox(edgeVerticalPartX, y);
        if (!box.edges.fully) {
          box.edges.fully = edge;
        }
      }
    };

    const getEdgesForDirection = (node: GraphNode, direction: Direction): void => {
      if (!node[direction] || !node[direction].length) {
        return;
      }
      for (const x of node[direction]) {
        appendEdge({
          fromId: direction === Direction.Back ? x.id : node.id,
          toId: direction === Direction.Back ? node.id : x.id,
          direction,
        });
      }
      for (const x of node[direction]) {
        getEdgesForDirection(x, direction);
      }
    };

    getEdgesForDirection(rootNode, Direction.Forward);
    getEdgesForDirection(rootNode, Direction.Back);
  };

  appendEdgesToBoxes(graphForNode.rootNode);


  return {
    boxes,
    positions: vertexesPositions,
  };
};

export const PositionHelper = {
  getPositionsInfo,
  getWindowElements,
};


export interface EdgeIds {
  fromId: number;
  toId: number;
  direction: Direction;
}

export interface Box {
  vertexIds: number[];
  edges: {
    toTop: EdgeIds[],
    toBottom: EdgeIds[],
    fully: EdgeIds,
  };
}


function getWindowElements(
  positionsInfo: GraphPositions,
  windowFrom: Coordinate,
  windowTo: Coordinate,
): { vertexIds: number[], edges: EdgeIds[] } {
  if (!positionsInfo) {
    return {
      edges: [],
      vertexIds: [],
    };
  }
  const { positions, boxes } = positionsInfo;
  interface EdgesStartOrEndNotInWindowMapsType {
    sourceToTarget: Map<number, EdgeIds>;
    targetToSource: Map<number, EdgeIds>;
  }
  const tempData = {
    vertexes: new Map<number, boolean>(),
    edgesStartAndEndInWindow: new Map<number, Map<number, Direction>>(),
    edgesStartOrEndNotInWindow: {
      toTop: {
        sourceToTarget: new Map<number, EdgeIds>(),
        targetToSource: new Map<number, EdgeIds>(),
      },
      toBottom: {
        sourceToTarget: new Map<number, EdgeIds>(),
        targetToSource: new Map<number, EdgeIds>(),
      },
    },
  };

  const executeForArrayIf = <T>(
    array: T[],
    executer: (item: T) => void,
    condition?: (item: T) => boolean,
  ): void => {
    for (const item of array) {
      if (!condition || condition(item)) {
        executer(item);
      }
    }
  };

  const getMinY = (edge: EdgeIds): number => {
    return Math.min(positions[edge.fromId].y, positions[edge.toId].y);
  };

  const getMaxY = (edge: EdgeIds): number => {
    return Math.max(positions[edge.fromId].y, positions[edge.toId].y);
  };

  const appendVertex = (vertexId: number): void => {
    tempData.vertexes.set(vertexId, true);
  };

  const appendEdgeStartAndEndInWindow = (edge: EdgeIds): void => {
    const { fromId, toId, direction } = edge;
    if (!tempData.edgesStartAndEndInWindow.has(fromId)) {
      tempData.edgesStartAndEndInWindow.set(fromId, new Map());
    }
    tempData.edgesStartAndEndInWindow.get(fromId).set(toId, direction);
  };

  const appendEdgeStartOrEndInWindow = (maps: EdgesStartOrEndNotInWindowMapsType, edge: EdgeIds): void => {
    const { fromId, toId } = edge;
    const sourceYPosition = positions[fromId].y;
    if (windowFrom.y <= sourceYPosition && windowTo.y >= sourceYPosition) {
      if (!maps.sourceToTarget.has(fromId)) {
        maps.sourceToTarget.set(fromId, edge);
      }
    } else {
      if (!maps.targetToSource.has(toId)) {
        maps.targetToSource.set(toId, edge);
      }
    }
  };

  const appendEdgeToTopStartOrEndNotInWindow = (edge: EdgeIds): void => {
    appendEdgeStartOrEndInWindow(tempData.edgesStartOrEndNotInWindow.toTop, edge);
  };

  const appendEdgeToBottomStartOrEndNotInWindow = (edge: EdgeIds): void => {
    appendEdgeStartOrEndInWindow(tempData.edgesStartOrEndNotInWindow.toBottom, edge);
  };

  const appendEdgeToTop = (edge: EdgeIds): void => {
    if (getMinY(edge) >= windowFrom.y) {
      appendEdgeStartAndEndInWindow(edge);
    } else {
      appendEdgeToTopStartOrEndNotInWindow(edge);
    }
  };

  const appendEdgeToBottom = (edge: EdgeIds): void => {
    if (getMaxY(edge) <= windowTo.y) {
      appendEdgeStartAndEndInWindow(edge);
    } else {
      appendEdgeToBottomStartOrEndNotInWindow(edge);
    }
  };


  for (let x = Math.trunc(windowFrom.x / X_BOX_SIZE); x <= Math.trunc(windowTo.x / X_BOX_SIZE); x++) {
    for (let y =  Math.trunc(windowFrom.y / Y_BOX_SIZE); y <= Math.trunc(windowTo.y / Y_BOX_SIZE); y++) {
      const xBoxes = boxes[x];
      if (!xBoxes) {
        continue;
      }
      const box = xBoxes[y];
      if (!box) {
        continue;
      }

      if (y * Y_BOX_SIZE <= windowFrom.y) {
        executeForArrayIf(box.vertexIds, appendVertex, id => positions[id].y >= windowFrom.y);
        executeForArrayIf(box.edges.toTop, appendEdgeToTop, edge => getMaxY(edge) >= windowFrom.y);
        executeForArrayIf(box.edges.toBottom, appendEdgeToBottom, edge => getMinY(edge) >= windowFrom.y);
      } else if ((y + 1) * Y_BOX_SIZE >= windowTo.y) {
        executeForArrayIf(box.vertexIds, appendVertex, id => positions[id].y <= windowTo.y);
        executeForArrayIf(box.edges.toTop, appendEdgeToTop, edge => getMaxY(edge) <= windowTo.y);
        executeForArrayIf(box.edges.toBottom, appendEdgeToBottom, edge => getMinY(edge) <= windowTo.y);
      } else {
        executeForArrayIf(box.vertexIds, appendVertex);
        executeForArrayIf(box.edges.toTop, appendEdgeToTop);
        executeForArrayIf(box.edges.toBottom, appendEdgeToBottom);
      }

      if (box.edges.fully) {
        appendEdgeStartAndEndInWindow(box.edges.fully);
      }
    }
  }

  const vertexIds = Array.from(tempData.vertexes.keys());

  const resultEdgesMap = tempData.edgesStartAndEndInWindow;
  const appendResultEdgeMap = (map: Map<number, EdgeIds>): void => {
    map.forEach(({ fromId, toId, direction }) => {
      if (!resultEdgesMap.has(fromId)) {
        resultEdgesMap.set(fromId, new Map([[toId, direction]]));
      } else {
        resultEdgesMap.get(fromId).set(toId, direction);
      }
    });
  };

  appendResultEdgeMap(tempData.edgesStartOrEndNotInWindow.toTop.sourceToTarget);
  appendResultEdgeMap(tempData.edgesStartOrEndNotInWindow.toTop.targetToSource);
  appendResultEdgeMap(tempData.edgesStartOrEndNotInWindow.toBottom.sourceToTarget);
  appendResultEdgeMap(tempData.edgesStartOrEndNotInWindow.toBottom.targetToSource);

  const edges = [];
  resultEdgesMap.forEach((value, fromId) => {
    value.forEach((direction, toId) => {
      edges.push({ fromId, toId, direction });
    });
  });

  return {
    vertexIds,
    edges,
  };
}

