import { ActionWith } from 'common/interfaces/action-with';
import { Feed } from 'common/interfaces/feed';
import { ResourceType } from '../../enums';
import { ActivitiesRequestFilterModel, DatabaseTableRootModel } from '../../interfaces/data';
import { ResourceModel } from '../../interfaces/resources-data';
import { ResourceForm } from '../../interfaces/resources-rest-data';
import {
  CreateResourcePayload,
  DeleteResourcePayload,
  EditResourcePayload,
  LoadResourcePayload,
  LoadResourcesPayload,
} from '../payloads/database-resource';
import { DatabaseResourceActionTypes } from '../types/database-resource';

function createResource(
  databaseId: number,
  type: ResourceType,
  model: ResourceForm,
  formId: string,
): ActionWith<CreateResourcePayload> {
  return {
    type: DatabaseResourceActionTypes.CREATE_RESOURCE_REQUEST,
    payload: { databaseId, model, formId, type },
  };
}

function createResourceResponse(
  model: ResourceModel,
): ActionWith<ResourceModel> {
  return {
    type: DatabaseResourceActionTypes.CREATE_RESOURCE_SUCCEEDED,
    payload: model,
  };
}

function loadResources(
  databaseId: number,
  type: ResourceType,
  filter: ActivitiesRequestFilterModel,
): ActionWith<LoadResourcesPayload> {
  return {
    type: DatabaseResourceActionTypes.GET_RESOURCES_REQUEST,
    payload: {
      databaseId,
      filter,
      type,
    },
  };
}

function loadResourcesResponse(
  responseModel: Feed<DatabaseTableRootModel>,
): ActionWith<Feed<DatabaseTableRootModel>> {
  return {
    type: DatabaseResourceActionTypes.GET_RESOURCES_SUCCEEDED,
    payload: responseModel,
  };
}

function loadResource(
  databaseId: number,
  resourceId: number,
  type: ResourceType,
): ActionWith<LoadResourcePayload> {
  return {
    type: DatabaseResourceActionTypes.GET_RESOURCE_REQUEST,
    payload: {
      databaseId,
      resourceId,
      type,
    },
  };
}

function loadResourceResponse(
  model: ResourceModel,
): ActionWith<ResourceModel> {
  return {
    type: DatabaseResourceActionTypes.GET_RESOURCE_SUCCEEDED,
    payload: model,
  };
}

function updateResource(
  databaseId: number,
  resourceId: number,
  type: ResourceType,
  model: ResourceForm,
  formId: string,
): ActionWith<EditResourcePayload> {
  return {
    type: DatabaseResourceActionTypes.UPDATE_RESOURCE_REQUEST,
    payload: { databaseId, resourceId, model, type, formId },
  };
}


function updateResourceResponse(
  model: ResourceModel,
): ActionWith<ResourceModel> {
  return {
    type: DatabaseResourceActionTypes.UPDATE_RESOURCE_SUCCEEDED,
    payload: model,
  };
}

function deleteResource(
  databaseId: number,
  resourceId: number,
  type: ResourceType,
): ActionWith<DeleteResourcePayload> {
  return {
    type: DatabaseResourceActionTypes.DELETE_RESOURCE_REQUEST,
    payload: { databaseId, resourceId, type },
  };
}

function deleteResourceResponse(
  id: number,
): ActionWith<number> {
  return {
    type: DatabaseResourceActionTypes.DELETE_RESOURCE_SUCCEEDED,
    payload: id,
  };
}


export const DatabaseResourceActions = {
  createResource,
  createResourceResponse,
  loadResources,
  loadResourcesResponse,
  loadResource,
  loadResourceResponse,
  updateResource,
  updateResourceResponse,
  deleteResource,
  deleteResourceResponse,
};
