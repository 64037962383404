import styled from 'styled-components';

const Container = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  padding: 10px;
  display: grid;
  overflow-y: auto;
  ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: ${p => p.theme.color.gray} !important;
  }
  ::-webkit-scrollbar-track {
    background: ${p => p.theme.color.background};
  }
`;


export const Styled = {
  Container,
};
