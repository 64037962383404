import styled from 'styled-components';


const Container = styled.div`
  .kreo-nav-menu__help-icon {
    display: none;
  }
  .help-center--active {
    display: none;
  }
  .bell-badge {
    display: none;
  }
  .main-layout__side-panel {
    display: none;
  }
  .kreo-main-header__profile-wrap {
    display: none;
  }

  .page-header-avatar {
    display: flex !important;
  }

  .scroll-box {
    background: ${p => p.theme.color.background};
  }
  .main-layout__header-wrap {
    background: ${p => p.theme.color.backgroundRush};
    box-shadow: 0 1px 0 0 ${p => p.theme.color.backgroundRush};
  }
  .logo {
    background-color: ${p => p.theme.color.backgroundRush};
  }

  .main-layout__header-row {
    height: 50px;
    min-height: 50px;
  }

  .main-layout__header-row {
    background: ${p => p.theme.color.backgroundRush};
  }
`;

const CreateCompanyWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Styled = {
  Container,
  CreateCompanyWrapper,
};
