import * as Ag from 'ag-grid-community';
import uuid from 'uuid';

import {
  CustomElementFilterBuilderModel,
  CustomElementFilterForm,
} from 'common/interfaces/custom-element-filter-builder';

export class CustomElementFilterBuilderCopyHelper {
  public static getCopyForm(model: CustomElementFilterBuilderModel, name: string): CustomElementFilterForm {
    return {
      id: uuid.v4(),
      name,
      rootNode: model.rootNode,
    };
  }

  public static getCopyName(node: Ag.RowNode, api: Ag.GridApi, nameField: string): string {
    const regExp = new RegExp(' \\((\\d+)\\)$');
    const copyName = node.data[nameField].replace(regExp, '');
    const copyIndex = this.getCopyIndex(api, nameField, copyName, regExp);

    return copyIndex
      ? `${copyName} (${copyIndex})`
      : copyName;
  }

  private static getCopyIndex(api: Ag.GridApi, nameField: string, copyName: string, indexRegexp: RegExp): number {
    const usedIndexes = [];
    api.forEachNode(x => {
      const nameWithoutPostfix = x.data[nameField].replace(indexRegexp, '');
      if (nameWithoutPostfix === copyName) {
        const matches = x.data[nameField].match(indexRegexp);
        const index = matches && matches[1] || 0;
        usedIndexes[index] = Number(index);
      }
    });

    let copyIndex = 0;
    for (const index of usedIndexes) {
      if (copyIndex !== index) {
        return copyIndex;
      }

      copyIndex++;
    }

    return copyIndex;
  }
}
