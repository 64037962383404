import autobind from 'autobind-decorator';
import * as React from 'react';


import { ColorsName } from 'common/enums/kreo-colors';
import { MenuItem } from '../menu-item';
import {
  MultilevelSelectSimpleOptionData,
} from '../multi-level-drop-down-menu/interfaces/multi-level-select-option-data';
import { MultiLevelSelectOption } from '../multi-level-drop-down-menu/multi-level-select-option';

export interface ContextMenuItemProps {
  icon?: React.ComponentType;
  hotkey?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  options?: MultilevelSelectSimpleOptionData[];
  onSelect?: (value: string) => void;
  onMouseOver?: (value: React.ReactText) => void;
  onMouseOut?: (value: React.ReactText) => void;
  childRenderer?: () => React.ReactNode;
  optionContentRenderer?:
    (option: MultilevelSelectSimpleOptionData, isSelected: boolean, isHighlighted?: boolean) => React.ReactNode;
  helpToolTip?: string;
  disabled?: boolean;
  badge?: React.ComponentType;
}

export class ContextMenuItem extends React.Component<ContextMenuItemProps> {
  public render(): React.ReactNode {
    const {
      children,
      options,
      onSelect,
      onMouseOver,
      onMouseOut,
      icon,
      hotkey,
      optionContentRenderer,
      childRenderer,
      helpToolTip,
      disabled,
      badge: Badge,
    } = this.props;
    if (options && options.length || childRenderer) {

      return (
        <MultiLevelSelectOption
          subvariants={options}
          isSelected={false}
          isSelectable={false}
          selectedValue={null}
          value={null}
          onSelectClick={onSelect}
          onOptionMouseOver={onMouseOver}
          onOptionMouseOut={onMouseOut}
          optionData={null}
          optionContentRenderer={optionContentRenderer}
          themeStyle={true}
          childRenderer={childRenderer}
        >
          <MenuItem
            onClick={this.onClick}
            Icon={icon}
            text={children as string}
            textColor={ColorsName.mainFont}
            fontSize={14}
            withBorder={true}
            size='m'
            helpToolTip={helpToolTip}
            disabled={disabled}
            badge={Badge && <Badge />}
          />
        </MultiLevelSelectOption>
      );
    } else {
      return (
        <MenuItem
          onClick={this.onClick}
          Icon={icon}
          text={children as string}
          textColor={ColorsName.mainFont}
          subText={hotkey}
          subTextColor={ColorsName.disabled}
          fontSize={14}
          size='m'
          withBorder={true}
          tooltipPosition='top'
          helpToolTip={helpToolTip}
          disabled={disabled}
          badge={Badge && <Badge />}
        />
      );
    }
  }

  @autobind
  private onClick(e: React.MouseEvent<HTMLDivElement>): void {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }
}
