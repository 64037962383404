import * as React from 'react';

export const KreoIconExpand: React.FC = () => {
  return (
    <svg
      className='kreo-icon expand'
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect id='Rectangle-7' x='0' y='0' width='14' height='14' />
        <polyline id='Rectangle-5' stroke='#1F222D' strokeLinecap='round' points='10 6 7 9 4 6' />
      </g>
    </svg>
  );
};
