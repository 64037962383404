import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { InteractiveMenuContainer } from 'common/components/interactive-menu';
import { useOpenMenuWithRect } from 'common/hooks';
import { InstrumentInfo } from '../../interfaces';
import { SelectModeSettings } from '../interfaces';
import { Styled } from './styled';
import { Subvariant } from './subvariant';


interface Props {
  subvariants: InstrumentInfo[];
  activeMenuItem: DrawingsDrawMode;
  onClick: (payload: SelectModeSettings) => void;
}

const SubvariantButtonComponent: React.FC<Props> = ({ subvariants, onClick, activeMenuItem }) => {
  const { isOpen, onOpen, onClose, position } = useOpenMenuWithRect(true);
  const onClickCallback = React.useCallback((payload) => {
    onClick(payload);
    onClose();
  }, [onClick, onClose]);

  return (
    <>
      <Styled.MenuButton onClick={onOpen} opened={isOpen}>
        <Icons.DownSmall/>
      </Styled.MenuButton>
      {
        isOpen ? (
          <InteractiveMenuContainer
            bindVertical='top'
            bindHorizontal='left'
            onCloseClick={onClose}
            parentRect={position}
            orientation='vertical'
            withBlanket={true}
            marginTop={10}
          >
            <Styled.Container>
              {
                subvariants.map((subvariant) => {
                  return (
                    <Subvariant
                      key={subvariant.id}
                      instrument={subvariant}
                      onClick={onClickCallback}
                      active={subvariant.id === activeMenuItem}
                    />
                  );
                })
              }
            </Styled.Container>
          </InteractiveMenuContainer>
        ) : null
      }
    </>
  );
};

export const SubvariantButton = React.memo(SubvariantButtonComponent);
