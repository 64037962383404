import * as React from 'react';

import { TabsContainerWithScroll } from '../tabs-container-with-scroll';
import { ContextMenuProps, DEFAULT_COLOR, ItemData } from './interfaces';
import { Styled } from './styled';
import { TabItem } from './tab-item';
import { TabsMenu } from './tabs-menu';

interface Props<T extends ItemData> {
  tabList: T[];
  activeTabId: string;
  defaultColor?: string;
  onClickTab: (id: string) => void;
  onChangeTabInfo: (pageInfo: T) => void;
  onMoveTo: (id: string, offset: number) => void;
  canEdit: boolean;
  ContextMenu: React.FC<ContextMenuProps<T>>;
  direction: 'vertical' | 'horizontal';
  itemTooltipPosition?: 'left' | 'right';
}

type TabsListType = <T extends ItemData>(props: Props<T>) => React.ReactElement<Props<T>>;

export const TabsList: TabsListType = React.forwardRef((props) => {
  const [tabList, setTabList] = React.useState(props.tabList);
  React.useEffect(() => {
    setTabList(props.tabList);
  }, [props.tabList]);

  const sortSelectedItems = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const draggableSheet = tabList[dragIndex];
      const sheetsTabsCopy = tabList.slice();

      sheetsTabsCopy.splice(dragIndex, 1);
      sheetsTabsCopy.splice(hoverIndex, 0, draggableSheet);
      setTabList(sheetsTabsCopy);
    },
    [tabList],
  );

  const getOriginalTabIndex = React.useCallback(
    (id: string): number => {
      return props.tabList.findIndex((tab) => tab.id === id);
    },
    [props.tabList],
  );

  const {
    onClickTab,
    activeTabId,
    defaultColor = DEFAULT_COLOR,
    canEdit,
    onChangeTabInfo,
    onMoveTo,
    direction,
    itemTooltipPosition = 'right',
    ContextMenu,
  } = props;

  return (
    <Styled.Container>
      <TabsMenu
        tabList={tabList}
        onClickItem={onClickTab}
        activeId={activeTabId}
        itemTooltipPosition={itemTooltipPosition}
        defaultColor={defaultColor}
      />
      <Styled.TabsList fullContainer={tabList.length < 2}>
        <TabsContainerWithScroll dragDirection={direction} disableAutoScroll={true}>
          {tabList.map((item, index) => {
            const isActive = item.id === activeTabId;
            const key = `${item.name}${item.id}`;
            return (
              <TabItem
                key={key}
                index={index}
                item={item}
                itemList={tabList}
                isActive={isActive}
                onClickTab={onClickTab}
                onChangeTabInfo={onChangeTabInfo}
                onMoveTo={onMoveTo}
                changePosition={sortSelectedItems}
                getOriginalTabIndex={getOriginalTabIndex}
                ContextMenu={ContextMenu}
                canEdit={canEdit}
                defaultColor={defaultColor}
              />
            );
          })}
        </TabsContainerWithScroll>
      </Styled.TabsList>
    </Styled.Container>
  );
});
