import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { AbilityRoute } from 'common/ability/ability-route';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { GlobalKeyboardEventsController } from 'common/components/global-keyboard-events-controller';
import { SubscriptionType } from 'common/constants/subscription';
import { DndProvider } from 'common/dnd-context';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { AccountActions } from '../units/account/actions/creators';
import { OwnCompany } from '../units/account/interfaces/own-company';
import { EmptyOrganizationsPage } from '../units/empty-organizations/page';
import { NotFoundPage } from '../units/notfound/page';
import { ProductsPageWrapper } from '../units/products';
import { QuestionnairePage } from '../units/questionnaire-page';
import { AppUrls } from './app-urls';
import * as Admin from './common/admin';
import * as CostEstimate  from './cost-estimate/routes';
import { getUrlByLastUsedProductType } from './get-url-by-last-product-type';
import * as Plan  from './plan/routes';
import * as Qto2d from './quantity-take-off-2d';
import * as Qto3d from './quantity-take-off-3d';
import { SpinnerPage } from './spinner-page';


interface StateProps {
  initialAppDataStatus: RequestStatus;
  lastUsedProductType: SubscriptionType;
  showQuestionnaire: boolean;
  ownedCompany: OwnCompany;
}

interface DispatchProps {
  getInitialAppData: () => void;
}

interface Props extends StateProps, DispatchProps, AbilityAwareProps, RouteComponentProps<{}> { }


class AppRoutesComponent extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.getInitialAppData();
  }

  public render(): React.ReactNode {
    const {
      initialAppDataStatus,
      ability,
      ownedCompany,
      showQuestionnaire,
    } = this.props;
    const isAppInitialized = initialAppDataStatus === RequestStatus.Loaded;
    return (
      <DndProvider>
        <GlobalKeyboardEventsController>
          <Switch>
            {!ownedCompany
              ? <Route exact={false} path={AppUrls.emptyAccount.path} component={EmptyOrganizationsPage} />
              : null}
            {
              isAppInitialized ? (
                <Switch>
                  <Route exact={true} path={AppUrls.products.path} component={ProductsPageWrapper} />
                  <Redirect exact={true} from={AppUrls.root.path} to={this.getAppUrls()} />
                  <Route path={AppUrls.qto2d.index.path} component={Qto2d.Routes} />
                  <Route path={AppUrls.qto3d.index.path} component={Qto3d.Routes} />
                  <Route path={AppUrls.costEstimate.index.path} component={CostEstimate.Routes} />
                  <Route path={AppUrls.plan.index.path} component={Plan.Routes} />
                  {
                    !ability.can(Operation.Read, Subject.PlanFeatures) && (
                      <Redirect
                        from={AppUrls.plan.index.path}
                        to={AppUrls.qto3d.index.url()}
                      />
                    )
                  }
                  <AbilityRoute
                    subject={Subject.AdminPanel}
                    path={AppUrls.admin.index.path}
                    component={Admin.Routes}
                  />
                  {showQuestionnaire
                    ? <Route component={QuestionnairePage} />
                    : <Route component={NotFoundPage} />
                  }
                </Switch>
              ) : (
                <Route component={SpinnerPage} />
              )
            }
          </Switch>
        </GlobalKeyboardEventsController>
      </DndProvider>
    );
  }

  private getAppUrls(): string {
    const { lastUsedProductType, showQuestionnaire } = this.props;
    if (showQuestionnaire) {
      return AppUrls.qto2d.questionnaire.path;
    }

    return getUrlByLastUsedProductType(lastUsedProductType || SubscriptionType.Takeoff2d);
  }
}

function mapStateToProps(state: ReduxState): StateProps {
  return {
    initialAppDataStatus: state.account.initialAppDataStatus,
    lastUsedProductType: state.persistedStorage.lastUsedProductType,
    showQuestionnaire: state.account.showQuestionnaire,
    ownedCompany: state.account.ownedCompany,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    getInitialAppData: () => dispatch(AccountActions.getInitialAppData()),
  };
}

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const AppRoutes = withRouter(withAbilityContext(withRedux(AppRoutesComponent)));
