import { Button, RectangleButton } from '@kreo/kreo-ui-components';
import React from 'react';
import { Styled } from './styled';

interface Props {
  applyText: string;
  disabled: boolean;
  apply: () => void;
  cancel: () => void;
}

const ConfirmationButtonsPanelComponent: React.FC<Props> = ({ applyText, disabled, apply, cancel }) => {
  return (
    <Styled.Container>
      <RectangleButton
        height={25}
        width={90}
        fontSize={12}
        text={applyText}
        mood={disabled ? 'disabled' : 'positive'}
        onClick={apply}
      />
      <Button
        onClick={cancel}
        size='s'
        color='submit'
      >
        Cancel
      </Button>
    </Styled.Container>
  );
};

export const ConfirmationButtonsPanel = React.memo(ConfirmationButtonsPanelComponent);
