import { AssignedPia } from '2d/index';
import { arrayUtils } from 'common/utils/array-utils';
import { TreeUtils } from 'common/utils/tree-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { CreateDrawingGroup } from '../actions/payloads/drawings-annotation-legend';
import { DrawingsGeometryGroup } from '../interfaces/drawings-geometry-group';

const NEW_GROUP_NAME = 'New Group';

function isGroupEmpty(
  id: string,
  groupMap: Record<string, { value: DrawingsGeometryGroup, children: string[], isEmpty?: boolean }>,
): boolean {
  const groupInfo = groupMap[id];
  if (groupInfo.isEmpty === undefined) {
    groupInfo.isEmpty = !groupInfo.value.measurements.length && groupInfo.children.every(childId =>
      groupMap[childId] ? this.isGroupEmpty(childId, groupMap) : false);
  }
  return groupInfo.isEmpty;
}

function getClosestGroup(
  group: DrawingsGeometryGroup,
  groupMap: Record<string, DrawingsGeometryGroup>,
  filterFn: (group: DrawingsGeometryGroup) => boolean,
): DrawingsGeometryGroup {
  if (filterFn(group)) {
    return group;
  }
  const parentGroup = groupMap[group.parentId];
  return parentGroup ? getClosestGroup(parentGroup, groupMap, filterFn) : null;
}

function getAllInnerMeasurements(
  group: DrawingsGeometryGroup,
  groupMap: ParentToChildMap,
  filterFn: (group: DrawingsGeometryGroup) => boolean,
): string[] {
  let measurements = group.measurements;
  groupMap[group.id].children.forEach(
    (id) => {
      const childGroup = groupMap[id].value;
      if (filterFn(childGroup)) {
        measurements = measurements.concat(getAllInnerMeasurements(childGroup, groupMap, filterFn));
      }
    });
  return measurements;
}

function * getAllInnerGroupsIterator(
  id: string,
  groupMap: ParentToChildMap,
): IterableIterator<DrawingsGeometryGroup> {
  const groupInfo = groupMap[id];
  yield groupInfo.value;

  if (groupInfo.children) {
    for (const childId of groupInfo.children) {
      yield *getAllInnerGroupsIterator(childId, groupMap);
    }
  }
}

function getAllInnerGroups(
  id: string,
  groupMap: ParentToChildMap,
): Record<string, DrawingsGeometryGroup> {
  return arrayUtils.toDictionaryByKey(getAllInnerGroupsIterator(id, groupMap), group => group.id);
}

export type ParentToChildMap = Record<string, { value: DrawingsGeometryGroup, children: string[] }>;

function getParentToChildMap(
  groups: DrawingsGeometryGroup[],
): ParentToChildMap {
  const groupsMap = {};

  groups.forEach(group => {
    if (!groupsMap[group.id]) {
      groupsMap[group.id] = {
        children: [],
      };
    }
    groupsMap[group.id].value = group;

    if (group.parentId) {
      if (!groupsMap[group.parentId]) {
        groupsMap[group.parentId] = {
          children: [],
        };
      }
      groupsMap[group.parentId].children.push(group.id);
    }
  });

  return groupsMap;
}

function getSelectedGroupRoots(
  ids: string[],
  groups: DrawingsGeometryGroup[],
): DrawingsGeometryGroup[] {
  const rootGroups = [];
  const groupMap = arrayUtils.toDictionary(groups, group => group.id, group => group);
  const selectedIdsSet = new Set(ids);
  for (const id of selectedIdsSet) {
    const group = groupMap[id];
    if (!group) {
      return rootGroups;
    }
    if (!group.parentId || !selectedIdsSet.has(group.parentId)) {
      rootGroups.push(group);
    }
  }

  return rootGroups;
}

function groupBySequentialIndex(
  groups: Array<{ id: string, orderIndex: number }>,
): Array<Array<{ id: string, orderIndex: number }>> {
  const sortedGroups = arrayUtils.sortByField(groups, 0, groups.length - 1, 'orderIndex');
  return sortedGroups.reduce(
    (acc, group) => {
      const lastSubArray = acc[acc.length - 1];
      const lastSubArrayOrderIndex = lastSubArray && lastSubArray[lastSubArray.length - 1].orderIndex;
      if (
        !lastSubArray
        || (lastSubArrayOrderIndex !== group.orderIndex - 1 && lastSubArrayOrderIndex !== group.orderIndex)
      ) {
        acc.push([]);
      }
      acc[acc.length - 1].push(group);

      return acc;
    },
    []);
}

function getGroupNestingCount(
  groupId: string,
  groupsMap: ParentToChildMap,
): number {
  let maxNesting = 0;
  let parentId = groupsMap[groupId].value.parentId;
  while (parentId) {
    parentId = groupsMap[parentId].value.parentId;
    maxNesting++;
  }
  return maxNesting;
}

function getMeasurementNestingCount(
  instancesMeasures: string[],
  groupsMap: ParentToChildMap,
  groups: DrawingsGeometryGroup[],
): number {
  let maxNestingCount = -1;

  for (const instanceId of instancesMeasures) {
    const group = groups.find(g => g.measurements.includes(instanceId));
    let parentId = group.id;
    let nestingCount = -1;
    while (parentId) {
      parentId = groupsMap[parentId].value.parentId;
      nestingCount++;
    }

    if (maxNestingCount < nestingCount) {
      maxNestingCount = nestingCount;
    }
  }
  return maxNestingCount;
}

function getGroupsToDeselect(
  {
    group,
    groupsMap,
    selectedGroups,
  }: {
    group: DrawingsGeometryGroup,
    groupsMap: Record<string, DrawingsGeometryGroup>,
    selectedGroups: string[],
  },
): string[] {
  const groupsToDeselect = [];
  while (group) {
    if (selectedGroups.some(id => id === group.id)) {
      groupsToDeselect.push(group.id);
      group = groupsMap[group.parentId];
    } else {
      group = null;
    }
  }
  return groupsToDeselect;
}

function getFirstSelectedGroupId({
  groups,
  selectedGroups,
}: {
  groups: DrawingsGeometryGroup[],
  selectedGroups: string[],
}): string {
  let order = 0;
  const groupOrders = {};
  const sortedGroups = groups.sort((a, b) => (a.orderIndex < b.orderIndex ? -1 : 1));
  const rootIterator = arrayUtils.filterIterator(sortedGroups, (x) => !x.parentId);
  const getChildren = (g: DrawingsGeometryGroup): IterableIterator<DrawingsGeometryGroup> =>
    arrayUtils.filterIterator(sortedGroups, (x) => x.parentId === g.id);
  for (const group of TreeUtils.flatTreeIterator(rootIterator, getChildren)) {
    order++;
    groupOrders[group.id] = order;
  }
  const newFolderSelection = arrayUtils.getMinByField(selectedGroups, (g) => groupOrders[g]);
  return newFolderSelection;
}

function createNewGroup(name: string = NEW_GROUP_NAME): CreateDrawingGroup {
  return {
    id: UuidUtil.generateUuid(),
    name,
    innerGroups: [],
  };
}

interface DuplicateGroupsPayload {
  groups: DrawingsGeometryGroup[];
  rootGroups: DrawingsGeometryGroup[];
  pia: Record<string, AssignedPia>;
}

function duplicateGroups({ groups, rootGroups, pia }: DuplicateGroupsPayload): {
  groups: CreateDrawingGroup[],
  pia: Record<string, AssignedPia>,
}  {
  const newGroups = new Array<CreateDrawingGroup>();

  const groupsMap = getParentToChildMap(groups);
  const idsMap: Record<string, string> = {};
  const piasMap: Record<string, AssignedPia> = {};

  for (const root of rootGroups) {
    for (const group of getAllInnerGroupsIterator(root.id, groupsMap)) {
      const newGroupId = UuidUtil.generateUuid();
      idsMap[group.id] = newGroupId;
      const newGroup: CreateDrawingGroup = {
        ...group,
        id: newGroupId,
        parentGroupId: idsMap[group.parentId] || group.parentId,
        innerGroups: [],
        orderIndex: group.id === root.id ? undefined : group.orderIndex,
      };
      newGroups.push(newGroup);
      const assignedPia = pia[group.id];
      if (assignedPia) {
        piasMap[newGroupId] = assignedPia;
      }
    }
  }
  return { groups: newGroups, pia: piasMap };
}

export const DrawingsGroupUtils = {
  NEW_GROUP_NAME,
  isGroupEmpty,
  getClosestGroup,
  getAllInnerMeasurements,
  getParentToChildMap,
  getSelectedGroupRoots,
  groupBySequentialIndex,
  getGroupNestingCount,
  getMeasurementNestingCount,
  getAllInnerGroups,
  getGroupsToDeselect,
  getFirstSelectedGroupId,
  createNewGroup,
  duplicateGroups,
};
