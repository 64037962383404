import { CodecUtils } from 'common/utils/codec-utils';

export enum CompanyEmployeeRole {
  GeneralContractor = 'GeneralContractor',
  SubContractor = 'SubContractor',
  Owner = 'Owner',
}

export const CompanyEmployeeRoleC =
  CodecUtils.createEnumCodec<CompanyEmployeeRole>(CompanyEmployeeRole, 'CompanyEmployeeRole');
