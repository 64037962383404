import { DeferredExecutor } from './deferred-executer';

export class DelayedExecutor {
  private _executor: DeferredExecutor;
  private _methodToExecute: () => void;

  constructor(delay: number) {
    this._executor = new DeferredExecutor(delay);
  }

  public execute(method: () => void): void {
    this._methodToExecute = method;
    if (!this._executor.isWaitingForExecution()) {
      this._executor.execute(() => this._methodToExecute());
    }
  }

  public reset(): void {
    this._executor.reset();
    this._methodToExecute = null;
  }
}
