import autobind from 'autobind-decorator';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { KreoScrollbars } from 'common/UIKit';
import { addTouchZoom } from 'common/utils/touch-zoom';


interface Props {
  className?: string;
  centered?: boolean;
  block?: boolean;
  blockButtons?: number[];
  sendScrollRef?: (ref: HTMLDivElement) => void;
  onPositionChanged?: (x: number, y: number) => void;
  onDragStart?: () => void;
  onDragFinish?: () => void;
  onResize?: (height: number, width: number) => void;
  onZoom?: (zoomCoefficient: number, targetCenter: [number, number]) => void;
}

interface State {
  dragging: boolean;
}

export class DragScroll extends React.Component<Props, State> {
  private lastClientX: number;
  private lastClientY: number;
  private container: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  public static getDerivedStateFromProps(props: Props): State {
    return props.block ? { dragging: false } : null;
  }

  public render(): JSX.Element {

    return (
      <KreoScrollbars
        onSendRef={this.makeContainerRef}
      >
        {this.props.children}
        <ReactResizeDetector
          handleHeight={true}
          handleWidth={true}
          onResize={this.resizeHandle}
        />
      </KreoScrollbars>
    );
  }

  public center(): void {
    if (this.container) {
      const leftScroll = (this.container.scrollWidth - this.container.clientWidth) / 2;
      this.container.scrollLeft = leftScroll;
      const topScroll = (this.container.scrollHeight - this.container.clientHeight) / 2;
      this.container.scrollTop = topScroll;
    }
  }

  public componentDidMount(): void {
    this.container.onmouseup = this.mouseUpHandle;
    this.container.onmousedown = this.mouseDownHandle;
    this.container.onmousemove = this.mouseMoveHandle;
    this.container.onscroll = this.onScroll;
    window.addEventListener('mouseup', this.mouseUpHandle);
    document.addEventListener('mousemove', this.mouseMoveHandle);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('mouseup', this.mouseUpHandle);
    document.removeEventListener('mousemove', this.mouseMoveHandle);
  }

  @autobind
  private makeContainerRef(container: HTMLDivElement): void {
    this.container = container;
    if (this.props.onZoom) {
      addTouchZoom(container, this.props.onZoom);
    }
    if (this.props.sendScrollRef) {
      this.props.sendScrollRef(container);
    }
  }

  @autobind
  private mouseUpHandle(): void {
    if (this.state.dragging) {
      this.setState({ dragging: false });
      if (this.props.onDragFinish) {
        this.props.onDragFinish();
      }
    }
  }

  @autobind
  private mouseDownHandle(e: MouseEvent): void {
    if (!this.state.dragging && this.isNotBlocked(e)) {
      this.setState({ dragging: true });
      this.lastClientX = e.clientX;
      this.lastClientY = e.clientY;
      e.preventDefault();
      if (this.props.onDragStart) {
        this.props.onDragStart();
      }
    }
  }

  @autobind
  private mouseMoveHandle(e: MouseEvent): void {
    if (this.state.dragging) {
      if (e.buttons === 0) {
        this.mouseUpHandle();
      } else {
        this.container.scrollLeft -=
          (-this.lastClientX + (this.lastClientX = e.clientX));
        this.container.scrollTop -=
          (-this.lastClientY + (this.lastClientY = e.clientY));
      }
    }
  }

  private isNotBlocked(e: MouseEvent): boolean {
    const { block, blockButtons } = this.props;
    return !block && (!blockButtons || !blockButtons.includes(e.button));
  }

  @autobind
  private onScroll(): void {
    if (this.props.onPositionChanged) {
      this.props.onPositionChanged(this.container.scrollLeft, this.container.scrollTop);
    }
  }

  @autobind
  private resizeHandle(width: number, height: number): void {
    if (this.props.onResize) {
      this.props.onResize(height, width);
    }
  }
}
