import React, { useCallback, useEffect, useState } from 'react';
import { useCurrentDrawingInfo } from '../../hooks';
import { DrawingsCommentsList } from '../../user-annotations-components/comments';
import { DrawingsCommentApi } from '../../user-annotations-components/comments/comment';
import { ThreeEngine } from '../engine';

interface Props {
  engine: ThreeEngine;
}

const CommentsViewComponent: React.FC<Props> = ({
  engine,
}) => {
  const [ commentApis, setApis] = useState<Record<string, DrawingsCommentApi>>({});

  const applyPosition = useCallback((api: DrawingsCommentApi) => {
    const position = api.getTargetPosition();
    const newPosition = engine.getProjection(position);
    if (newPosition) {
      api.setPosition(newPosition.x, newPosition.y);
    } else {
      api.hide();
    }
  }, [engine]);

  const addCommentApi = useCallback((api: DrawingsCommentApi, id: number | string) => {
    if (api) {
      applyPosition(api);
      setApis(prev => ({
        ...prev,
        [id]: api,
      }));
    } else {
      setApis(prev => {
        const newApis = { ...prev };
        delete newApis[id];
        return newApis;
      });
    }
  }, [applyPosition]);

  useEffect(() => {
    if (!engine) {
      return;
    }
    engine.onCameraChanged = () => {
      Object.values(commentApis).forEach(applyPosition);
    };
  }, [applyPosition, commentApis]);

  const currentDrawinInfo = useCurrentDrawingInfo();

  return (
    <DrawingsCommentsList
      sendCommentRef={addCommentApi}
      currentDrawingId={currentDrawinInfo.drawingId}
    />
  );
};


export const CommentsView = React.memo(CommentsViewComponent);
