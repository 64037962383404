import { ScenarioState, ScenarioStatesC } from '../../units/projects/interfaces/scenario-state';
import { ScenariosActionsType, ScenariosData, WorkPackagesData } from '../../units/scenarios';
import { ProjectResourcesApi } from '../server';

function scenariosUrl(url: string = ''): string {
  return `/scenarios${url}`;
}

function getScenarios(): Promise<ScenariosData.Scenario[]> {
  return ProjectResourcesApi.get(scenariosUrl(), 'Error in Scenarios Loading');
}

function deleteAllScenariosExceptActive(): Promise<void> {
  return ProjectResourcesApi.post(
    scenariosUrl('/clean'),
    'Error in deleting scenarios',
  );
}

function getActiveScenarioByDocumentId(): Promise<ScenariosData.Scenario> {
  return ProjectResourcesApi.get(
    scenariosUrl('/active'),
    'Error in getting active scenario',
  );
}

function setActiveScenario(scenarioId: number, calculationId: number): Promise<void> {
  return ProjectResourcesApi.put(
    scenariosUrl('/active'),
    { scenarioId, calculationId },
    'Error in activating scenario',
  );
}

function updateScenario(data: ScenariosActionsType.UpdateScenario): Promise<void> {
  return ProjectResourcesApi.patch(
    scenariosUrl(`/${data.scenarioId}`),
    data,
    'Error in updating scenario',
  );
}

function changeScenarioName(scenarioId: number, name: string): Promise<void> {
  return ProjectResourcesApi.put(
    scenariosUrl(`/${scenarioId}/name`),
    { value: name },
    'Error in updating scenario name',
  );
}

function publishScenario(scenarioId: number, value: boolean): Promise<void> {
  return ProjectResourcesApi.put(
    scenariosUrl(`/${scenarioId}/public`),
    { value },
    'Error in publish scenario',
  );
}

function deleteScenario(scenarioId: number): Promise<void> {
  return ProjectResourcesApi.delete(
    scenariosUrl(`/${scenarioId}`),
    'Error in deleting scenario',
  );
}

function getCalculations(scenarioId: number): Promise<ScenariosData.Calculation[]> {
  return ProjectResourcesApi.get(
    scenariosUrl(`/${scenarioId}/calculations`),
    'Error in Calculations Loading',
  );
}

function setActiveCalculation(scenarioId: number, calculationId: number): Promise<void> {
  return ProjectResourcesApi.put(
    scenariosUrl(`/${scenarioId}/calculations/active`),
    { value: calculationId },
    'Error in activating calc',
  );
}

function getResourceLimitationRequest(scenarioId: number): Promise<WorkPackagesData.Management> {
  return ProjectResourcesApi.get(
    scenariosUrl(`/${scenarioId}/resource-limitations`),
    `Error in getting resource limitations for ${scenarioId}`,
  );
}

function setResourceLimitationRequest(
  scenarioId: number,
  data: ScenariosActionsType.ResourceLimitationApiData,
): Promise<ScenariosActionsType.ResourceLimitationApiData> {
  return ProjectResourcesApi.put(
    scenariosUrl(`/${scenarioId}/resource-limitations`),
    data,
    'Error in setting resource limitations',
  );
}

function getIndirectSettings(scenarioId: number): Promise<WorkPackagesData.IndirectCost> {
  return ProjectResourcesApi.get(
    scenariosUrl(`/${scenarioId}/indirect-cost-settings`),
    'Error in get indirect cost settings',
  );
}

function setIndirectSettings(scenarioId: number, data: WorkPackagesData.IndirectCost[]): Promise<void> {
  return ProjectResourcesApi.put(
    scenariosUrl(`/${scenarioId}/indirect-cost-settings`),
    data,
    'Error in setting indirect cost',
  );
}

function copyScenario(scenarioId: number): Promise<ScenariosData.Scenario> {
  return ProjectResourcesApi.post(
    scenariosUrl(`/${scenarioId}/copy`),
    'Error in copy scenario',
  );
}

function getPackages(scenarioId: number): Promise<WorkPackagesData.ApiWorkpackageData> {
  return ProjectResourcesApi.get(
    scenariosUrl(`/${scenarioId}/workpackages`),
    'Error in getting workpackages',
  );
}

function setPackages(
  scenarioId: number,
  data: WorkPackagesData.ApiWorkpackageData,
): Promise<WorkPackagesData.ApiWorkpackageData> {
  return ProjectResourcesApi.put(
    scenariosUrl(`/${scenarioId}/workpackages`),
    data,
    'Error in setting workpackages',
  );
}

function fetchScenarioStates(): Promise<ScenarioState[]> {
  return ProjectResourcesApi.getV(
    scenariosUrl(`/statuses`),
    ScenarioStatesC,
    'Error in setting workpackages',
  );
}

export const ScenariosApi = {
  getScenarios,
  changeScenarioName,
  getCalculations,
  getActiveScenarioByDocumentId,
  getResourceLimitationRequest,
  setResourceLimitationRequest,
  setActiveScenario,
  setActiveCalculation,
  deleteScenario,
  getIndirectSettings,
  setIndirectSettings,
  deleteAllScenariosExceptActive,
  copyScenario,
  publishScenario,
  updateScenario,
  getPackages,
  setPackages,
  fetchScenarioStates,
};
