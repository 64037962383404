import * as React from 'react';

import { GraphItemDisplayType } from './enums';
import { Coordinate, VertexComponentProps } from './interfaces';

interface Props extends Coordinate, VertexComponentProps {
  width: number;
  margin: number;
  vertexComponent: React.ComponentType<VertexComponentProps>;
}

export class GraphVertex extends React.Component<Props> {
  public shouldComponentUpdate(prevProps: Props): boolean {
    const props = this.props;
    return props.id !== prevProps.id ||
      props.readonly !== prevProps.readonly ||
      props.width !== prevProps.width ||
      props.margin !== prevProps.margin ||
      props.displayType !== prevProps.displayType ||
      props.vertexComponent !== prevProps.vertexComponent ||
      props.x !== prevProps.x ||
      props.y !== prevProps.y;
  }

  public render(): React.ReactFragment {
    const props = this.props;
    const Component = this.props.vertexComponent;
    const height = this.props.displayType === GraphItemDisplayType.Active ? 100 : 40;
    const width = props.width + this.props.margin * 2;
    const x = props.x - this.props.margin;
    const y = props.y;
    const vertexStyle: React.CSSProperties = {
      position: 'absolute',
      height,
      width,
      top: y,
      left: x,
    };

    return  (
      <div
        style={vertexStyle}
        className={this.props.displayType}
      >
        <Component
          id={this.props.id}
          displayType={this.props.displayType}
          readonly={this.props.readonly}
        />
      </div>
    );
  }
}
