import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyA: React.FC = () => {
  return (
    <svg
      width='20px'
      height='22px'
      viewBox='0 0 20 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='hotkey_a' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='A'>
          <g id='background'>
            <g id='Group' transform='translate(0.000000, 2.000000)'>
              <g id='path-1-link' fill={KreoColors.f1}>
                <rect id='path-1' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
            <g id='Group'>
              <g id='path-2-link' fill={KreoColors.f1}>
                <rect id='path-2' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
          </g>
          <rect id='padding-left' x='0' y='6' width='6' height='10' />
          <rect id='padding-right' x='14' y='6' width='6' height='10' />
          <path
            // eslint-disable-next-line max-len
            d='M11.2540008,11.4149996 L10.0000006,7.98849901 L8.74600036,11.4149996 L11.2540008,11.4149996 Z M11.6225008,12.4269997 L8.3775003,12.4269997 L7.98700023,13.4774999 C7.88800022,13.7415 7.83850021,13.8405 7.72850019,13.9285 C7.64050017,14 7.53050016,14.044 7.40400014,14.044 C7.23900011,14.044 7.07400008,13.978 6.96950006,13.868 C6.74400003,13.6369999 6.81550004,13.3894999 6.93100006,13.0814998 L9.32900045,6.6959988 C9.41150047,6.47049877 9.47200048,6.37699875 9.56550049,6.28899874 C9.68100051,6.17899872 9.81300053,6.12399871 10.0000006,6.12399871 C10.1870006,6.12399871 10.3190006,6.17899872 10.4345006,6.28899874 C10.5280006,6.37699875 10.5885007,6.47049877 10.6710007,6.6959988 L13.0690011,13.0814998 C13.1845011,13.3894999 13.2560011,13.6369999 13.0305011,13.868 C12.926001,13.978 12.761001,14.044 12.596001,14.044 C12.469501,14.044 12.3595009,14 12.2715009,13.9285 C12.1615009,13.8405 12.1120009,13.7415 12.0130009,13.4774999 L11.6225008,12.4269997 Z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
};
