const prefix = '@subscription';

export const SubscriptionActionTypes = {
  SET_SUBSCRIPTION_TYPE: `${prefix}/SET_SUBSCRIPTION_TYPE`,
  SET_SELECTED_COMPANY: `${prefix}/SET_SELECTED_COMPANY`,
  LOAD_SUBSCRIPTION_ROLES: `${prefix}/LOAD_SUBSCRIPTION_ROLES`,
  LOAD_SUBSCRIPTION_ROLES_SUCCEEDED: `${prefix}/LOAD_SUBSCRIPTION_ROLES_SUCCEEDED`,
  UPDATE_SUBSCRIPTION_PERMISSION_ROLES_REQUEST: `${prefix}/UPDATE_SUBSCRIPTION_PERMISSION_ROLES_REQUEST`,
  UPDATE_SUBSCRIPTION_PERMISSION_ROLES_SUCCEEDED: `${prefix}/UPDATE_SUBSCRIPTION_PERMISSION_ROLES_SUCCEEDED`,
  UPDATE_SUBSCRIPTION_ROLES_REQUEST: `${prefix}/UPDATE_SUBSCRIPTION_ROLES_REQUEST`,
  UPDATE_SUBSCRIPTION_ROLES_SUCCEEDED: `${prefix}/UPDATE_SUBSCRIPTION_ROLES_SUCCEEDED`,

  GET_SUBSCRIPTION_PLANS_REQUEST: `${prefix}/GET_SUBSCRIPTION_PLANS_REQUEST`,
  GET_SUBSCRIPTION_PLANS_SUCCEEDED: `${prefix}/GET_SUBSCRIPTION_PLANS_SUCCEEDED`,
  GET_SUBSCRIPTION_PLANS_FAILED: `${prefix}/GET_SUBSCRIPTION_PLANS_FAILED`,

  CREATE_SUBSCRIPTION: `${prefix}/CREATE_SUBSCRIPTION`,
  UPDATE_SUBSCRIPTION: `${prefix}/UPDATE_SUBSCRIPTION`,
  CANCEL_SUBSCRIPTION: `${prefix}/CANCEL_SUBSCRIPTION`,
  PAUSE_SUBSCRIPTION: `${prefix}/PAUSE_SUBSCRIPTION`,
  RENEW_SUBSCRIPTION: `${prefix}/RENEW_SUBSCRIPTION`,
  RESUME_SUBSCRIPTION: `${prefix}/RESUME_SUBSCRIPTION`,
  UPGRADE_SUBSCRIPTION: `${prefix}/UPGRADE_SUBSCRIPTION`,

  CREATE_FREE_SUBSCRIPTION: `${prefix}/CREATE_FREE_SUBSCRIPTION`,

  GET_UPDATE_SUBSCRIPTION_ESTIMATION: `${prefix}/GET_UPDATE_SUBSCRIPTION_ESTIMATION`,
  GET_CREATE_SUBSCRIPTION_ESTIMATION: `${prefix}/GET_CREATE_SUBSCRIPTION_ESTIMATION`,
  SET_SUBSCRIPTION_ESTIMATION: `${prefix}/SET_SUBSCRIPTION_ESTIMATION`,
  SET_SUBSCRIPTION_ESTIMATION_ERROR: `${prefix}/SET_SUBSCRIPTION_ESTIMATION_ERROR`,

  SET_SUBSCRIPTION_PAYMENT_METHOD_REQUEST: `${prefix}/SET_SUBSCRIPTION_PAYMENT_METHOD`,

  FETCH_OWN_COMPANY_BILLING_INFO_REQUEST: `${prefix}/FETCH_OWN_COMPANY_BILLING_INFO_REQUEST`,
  FETCH_OWN_COMPANY_BILLING_INFO_SUCCEEDED: `${prefix}/FETCH_OWN_COMPANY_BILLING_INFO_SUCCEEDED`,
  FETCH_OWN_COMPANY_BILLING_INFO_FAILED: `${prefix}/FETCH_OWN_COMPANY_BILLING_INFO_FAILED`,

  DROP_SUBSCRIPTION_PLANS_STATE: `${prefix}/DROP_SUBSCRIPTION_PLANS_STATE`,
  DROP_OWN_COMPANY_BILLING_INFO_STATE: `${prefix}/DROP_OWN_COMPANY_BILLING_INFO_STATE`,

  START_UPDATE_SUBSCRIPTION: `${prefix}/START_UPDATE_SUBSCRIPTION`,
  END_UPDATE_SUBSCRIPTION: `${prefix}/END_UPDATE_SUBSCRIPTION`,

  PROPLONGARE_TRIAL: `${prefix}/PROPLONGARE_TRIAL`,
};


const paymentPrefix = '@paymentMethod';

export const PaymentMethodActionTypes = {
  SET_PRIMARY_CARD: `${paymentPrefix}/SET_PRIMARY_CARD`,
  SET_PRIMARY_CARD_SUCCEEDED: `${paymentPrefix}/SET_PRIMARY_CARD_SUCCEEDED`,
  SET_PRIMARY_CARD_FAILED: `${paymentPrefix}/SET_PRIMARY_CARD_FAILED`,

  SET_BACKUP_CARD: `${paymentPrefix}/SET_BACKUP_CARD`,
  SET_BACKUP_CARD_SUCCEEDED: `${paymentPrefix}/SET_BACKUP_CARD_SUCCEEDED`,
  SET_BACKUP_CARD_FAILED: `${paymentPrefix}/SET_BACKUP_CARD_FAILED`,

  GET_PAYMENT_DATA: `${paymentPrefix}/GET_PAYMENT_DATA`,
  GET_PAYMENT_DATA_FAILED: `${paymentPrefix}/GET_PAYMENT_DATA_FAILED`,

  GET_PAYMENT_METHODS: `${paymentPrefix}/GET_PAYMENT_METHODS`,
  GET_PAYMENT_METHODS_SUCCEEDED: `${paymentPrefix}/GET_PAYMENT_METHODS_SUCCEEDED`,

  SET_AVAILABLE_BILLING_COUNTRIES: `${paymentPrefix}/SET_AVAILABLE_BILLING_COUNTRIES`,

  REMOVE_CARD: `${paymentPrefix}/REMOVE_CARD`,
  REMOVE_CARD_SUCCEEDED: `${paymentPrefix}/REMOVE_CARD_SUCCEEDED`,
  REMOVE_CARD_FAILED: `${paymentPrefix}/REMOVE_CARD_FAILED`,

  CREATE_CARD_LEGACY: `${paymentPrefix}/CREATE_CARD_LEGACY`,
  CREATE_CARD_SUCCEEDED: `${paymentPrefix}/CREATE_CARD_SUCCEEDED`,
  CREATE_CARD_FAILED: `${paymentPrefix}/CREATE_CARD_FAILED`,

  DROP_PAYMENT_STATE: `${paymentPrefix}/DROP_PAYMENT_STATE`,

  GET_BILLING_HISTORY: `${prefix}/GET_BILLING_HISTORY`,
  FETCH_BILLING_HISTORY: `${prefix}/FETCH_BILLING_HISTORY`,
  SET_BILLING_HISTORY_STATUS: `${prefix}/SET_BILLING_HISTORY_STATUS`,
  GET_INVOICES_PDF: `${prefix}/GET_INVOICES_PDF`,
};

const currencyExcange = '@currencyExcange';

export const CurrencyExchangeActionTypes = {
  FETCH_CURRENCY_EXCHANGE: `${currencyExcange}/FETCH_CURRENCY_EXCHANGE`,
  SET_CURRENCY_RATE: `${currencyExcange}/SET_CURRENCY_RATE`,
};
