const prefix = '@account';

export const AccountActionTypes = {
  SIGN_IN_FAILED: `${prefix}/SIGN_IN_FAILED`,
  GET_INFO: `${prefix}/GET_INFO`,
  GET_INFO_SUCCEEDED: `${prefix}/GET_INFO_SUCCEEDED`,
  UPDATE_COMPANY: `${prefix}/UPDATE_COMPANY`,
  SET_INFO: `${prefix}/SET_INFO`,
  SET_INFO_SUCCEEDED: `${prefix}/SET_INFO_SUCCEEDED`,
  LOG_OUT: `${prefix}/LOG_OUT`,
  SET_AVATAR_FILE: `${prefix}/SET_AVATAR_FILE`,
  SET_COMPANY_LOGO_TEMPORARY_KEY: `${prefix}/SET_COMPANY_LOGO_TEMPORARY_KEY`,
  SET_RESET_PASSWORD_STATUS: `${prefix}/SET_RESET_PASSWORD_STATUS`,
  FETCH_COMPANIES: `${prefix}/FETCH_COMPANIES_REQUEST`,
  FETCH_COMPANIES_SUCCEEDED: `${prefix}/FETCH_COMPANIES_SUCCEEDED`,
  SELECT_COMPANY: `${prefix}/SELECT_COMPANY`,
  TRY_REFRESH_SESSION: `${prefix}/TRY_REFRESH_SESSION`,
  SET_QUESTIONNAIRE_COMPLETED: `${prefix}/SET_QUESTIONNAIRE_COMPLETED`,
  SET_SHOW_QUESTIONNAIRE: `${prefix}/SET_SHOW_QUESTIONNAIRE`,
  SEND_CONFIRM_AGAIN_SUCCEEDED: `${prefix}/SEND_CONFIRM_AGAIN_SUCCEEDED`,
  CREATE_COMPANY_REQUEST: `${prefix}/CREATE_COMPANY_REQUEST`,
  CREATE_COMPANY_SUCCEEDED: `${prefix}/CREATE_COMPANY_SUCCEEDED`,
  GET_INITIAL_APP_DATA: `${prefix}/GET_INITIAL_APP_DATA`,
  SET_INITIAL_APP_DATA_STATUS: `${prefix}/SET_INITIAL_APP_DATA_STATUS`,
  CHECKOUT_NEW_SUBSCRIPTION: `${prefix}/CHECKOUT_NEW_SUBSCRIPTION`,
  CHANGE_COMPANY_SUBSCRIPTION: `${prefix}/CHANGE_COMPANY_SUBSCRIPTION`,
  OPEN_SELF_SERVE_PORTAL: `${prefix}/OPEN_SELF_SERVE_PORTAL`,
  ACKNOWLEDGE_SUBSCRIRPTION_CHANGES: `${prefix}/ACKNOWLEDGE_SUBSCRIPTION_CHANGES`,
  LOAD_MOTIVATION_SETTINGS_REQUEST: `${prefix}/LOAD_MOTIVATION_SETTINGS`,
  LOAD_MOTIVATION_SETTINGS_SUCCEED: `${prefix}/LOAD_MOTIVATION_SETTINGS_SUCCEED`,
  SET_MOTIVATION_SETTINGS: `${prefix}/SET_MOTIVATION_SETTINGS`,
  DELETE_MOTIVATION_SETTINGS: `${prefix}/DELETE_MOTIVATION_SETTINGS`,
  TOGGLE_IMPERIAL_METRIC: `${prefix}/TOGGLE_IMPERIAL_METRIC`,
  SET_CUSTOM_COLORS: `${prefix}/SET_CUSTOM_COLORS`,
  REMOVE_USER_COLOR: `${prefix}/REMOVE_USER_COLOR`,
  SET_SUBSCRIBE_ERROR: `${prefix}/SET_SUBSCRIBE_ERROR`,
  TOGGLE_SHOW_LOADING: `${prefix}/TOGGLE_SHOW_LOADING`,
  SET_AVATAR: `${prefix}/SET_AVATAR`,
  SET_AVATAR_SUCCEED: `${prefix}/SET_AVATAR_SUCCEED`,
  REMOVE_AVATAR: `${prefix}/REMOVE_AVATAR`,
  SET_SUBSCRIPTION_EDIT_FORM_STATE: `${prefix}/SET_SUBSCRIPTION_EDIT_FORM_STATE`,
};
