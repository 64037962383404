import * as React from 'react';

import { DrawingsCommonPointsContextProps } from '../../interfaces/drawings-common-points-context-props';
import { DrawingsCommonPointsContext } from './drawings-common-points-context';

export function withDrawingsCommonPointsContextProps<P>(
  Component: React.ComponentType<P & DrawingsCommonPointsContextProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawingsCommonPointsContextProps>>> {
  return class WithCostEstimateDrawingCanvasContex extends React.PureComponent<P> {
    public render(): React.ReactNode {
      return (
        <DrawingsCommonPointsContext.Consumer>
          {
            contextProps => {
              return (<Component
                {...this.props}
                {...contextProps}
              />);
            }
          }
        </DrawingsCommonPointsContext.Consumer>
      );
    }
  };
}
