import { Action } from 'redux';

import { DemoProjectType, ProjectType } from 'common/constants/project-type';
import { ProjectStatus } from 'common/enums/project-status';
import { RequestStatus } from 'common/enums/request-status';
import { ActionWith } from 'common/interfaces/action-with';
import { BidPricingData } from 'common/interfaces/bid-pricing-data';
import { Feed } from 'common/interfaces/feed';
import { ProjectAccessReason } from 'unit-projects/enums';
import { Person } from '../../../../units/people/interfaces/person';
import { EngineBasedPages } from '../../enums/engine-based-pages';
import { CompanyProjectHeader } from '../../interfaces/company-project-header';
import { DemoProject } from '../../interfaces/demo-project';
import { EngineFilterState } from '../../interfaces/engine-filter-state';
import { Project } from '../../interfaces/project';
import { ScenarioState } from '../../interfaces/scenario-state';
import { ShortProjectHeader } from '../../interfaces/short-project-header';
import { ViewModelStatusPair } from '../../interfaces/view-model-status-pair';
import * as payloads from '../payloads/common';
import { ProjectsActionTypes } from '../types/common';

function removeProject(
  id: number,
): ActionWith<number> {
  return {
    type: ProjectsActionTypes.REMOVE_PROJECT,
    payload: id,
  };
}

function leaveProject(
  id: number,
): ActionWith<number> {
  return {
    type: ProjectsActionTypes.LEAVE_PROJECT,
    payload: id,
  };
}

function removeProjectSucceeded(
  companyId: number,
  projectToRemoveId: number,
): ActionWith<payloads.RemoveProjectPayload> {
  return {
    type: ProjectsActionTypes.REMOVE_PROJECT_SUCCEEDED,
    payload: {
      companyId,
      projectToRemoveId,
    },
  };
}

function runClassification(): Action {
  return {
    type: ProjectsActionTypes.RUN_CLASSIFICATION,
  };
}

function updateProjectStatusRequest(
  projectId: number,
  newStatus: ProjectStatus,
): ActionWith<payloads.UpdateProjectStatusPayload> {
  return {
    type: ProjectsActionTypes.UPDATE_PROJECT_STATUS_REQUEST,
    payload: { projectId, status: newStatus },
  };
}

function updateProjectStatusSucceeded(
  projectId: number,
  newStatus: ProjectStatus,
): ActionWith<payloads.UpdateProjectStatusPayload> {
  return {
    type: ProjectsActionTypes.UPDATE_PROJECT_STATUS_SUCCEEDED,
    payload: { projectId, status: newStatus },
  };
}

function setCurrentProject(
  projectId: number,
): ActionWith<number> {
  return {
    type: ProjectsActionTypes.LOAD_CURRENT_PROJECT,
    payload: projectId,
  };
}

function addProject(
  projectHeader: CompanyProjectHeader,
): ActionWith<CompanyProjectHeader> {
  return {
    type: ProjectsActionTypes.ADD_PROJECT,
    payload: projectHeader,
  };
}

function createProject(): Action {
  return {
    type: ProjectsActionTypes.CREATE_PROJECT,
  };
}

function duplicate2dProject(
  projectId: number,
  companyId: number,
): ActionWith<payloads.DuplicateProjectPayload> {
  return {
    type: ProjectsActionTypes.DUPLICATE_2D_PROJECT,
    payload: {
      projectId,
      companyId,
    },
  };
}

function duplicate2dProjectSucceeded(): Action {
  return {
    type: ProjectsActionTypes.DUPLICATE_2D_PROJECT_SUCCEEDED,
  };
}

function dump2dProject(
  projectId: number,
  quality: number,
): ActionWith<payloads.DumpProjectPayload> {
  return {
    type: ProjectsActionTypes.DUMP_2D_PROJECT,
    payload: {
      projectId,
      quality,
    },
  };
}


function createProjectFromDemoProject(demoProjectId: number): ActionWith<number> {
  return {
    type: ProjectsActionTypes.CREATE_PROJECT_FROM_DEMO_PROJECT,
    payload: demoProjectId,
  };
}

function createProjectSucceeded(): Action {
  return {
    type: ProjectsActionTypes.CREATE_PROJECT_SUCCEEDED,
  };
}

function storeProjectInfo(project: Project): ActionWith<Project> {
  return {
    type: ProjectsActionTypes.STORE_PROJECT_INFO,
    payload: project,
  };
}

/**
 * initialize a contactor invintation
 */
function contractorInvite(): Action {
  return {
    type: ProjectsActionTypes.CONTRACTOR_INVITE_REQUEST,
  };
}

/**
 *
 * @param {string} name
 *
 *
 * update project name request
 */
function updateProjectName(
  name: string,
): ActionWith<string> {
  return {
    type: ProjectsActionTypes.UPDATE_PROJECT_NAME_REQUEST,
    payload: name,
  };
}

function updateProjectNameById(
  name: string,
  id: number,
): ActionWith<payloads.UpdateProjectNamePayload> {
  return {
    type: ProjectsActionTypes.UPDATE_PROJECT_NAME_BY_ID_REQUEST,
    payload: {
      name,
      id,
    },
  };
}

function updateProjectNameSucceeded(
  projectId: number,
  name: string,
): ActionWith<payloads.UpdateProjectNamePayload> {
  return {
    type: ProjectsActionTypes.UPDATE_PROJECT_NAME_SUCCEEDED,
    payload: {
      id: projectId,
      name,
    },
  };
}

function getProjectBidPricingInfo(
  projectId: number,
): ActionWith<number> {
  return {
    type: ProjectsActionTypes.GET_BID_PRICING_INFO,
    payload: projectId,
  };
}

function getProjectBidPricingInfoSucceeded(
  data: BidPricingData,
): ActionWith<BidPricingData> {
  return {
    type: ProjectsActionTypes.GET_BID_PRICING_INFO_SUCCEEDED,
    payload: data,
  };
}

function setProjectNotFinishedScenarioId(
  id: number,
): ActionWith<number> {
  return {
    type: ProjectsActionTypes.SET_NOT_FINISHED_SCENARIO_ID,
    payload: id,
  };
}

function resetProjectHasUnstartedProducts(
  projectId: number,
): ActionWith<number> {
  return {
    type: ProjectsActionTypes.RESET_PROJECT_HAS_UNSTARTED_PRODUCTS,
    payload: projectId,
  };
}

function updateViewModelStatus(
  payload: payloads.UpdateViewModelStatusPayload,
): ActionWith<payloads.UpdateViewModelStatusPayload> {
  return {
    type: ProjectsActionTypes.UPDATE_VIEW_MODEL_STATUS,
    payload: { ...payload },
  };
}

function updateViewModelStatuses(
  projectId: number,
  statuses: ViewModelStatusPair[],
): ActionWith<payloads.UpdateViewModelStatusesPayload> {
  return {
    type: ProjectsActionTypes.UPDATE_VIEW_MODEL_STATUSES,
    payload: {
      projectId,
      statuses,
    },
  };
}

function updateProjectInvitedUsers(
  payload: Person[],
): ActionWith<Person[]> {
  return {
    type: ProjectsActionTypes.UPDATE_PROJECT_INVITED_USERS,
    payload,
  };
}

function dropCurrentProjectInfo(): Action {
  return {
    type: ProjectsActionTypes.DROP_CURRENT_PROJECT_INFO,
  };
}

function fetchScenarioStates(): Action {
  return {
    type: ProjectsActionTypes.FETCH_SCENARIO_STATES,
  };
}

function fetchScenarioStatesSucceeded(scenarioStates: ScenarioState[]): ActionWith<ScenarioState[]> {
  return {
    type: ProjectsActionTypes.FETCH_SCENARIO_STATES_SUCCEEDED,
    payload: scenarioStates,
  };
}

function appendScenarioStates(scenarioState: ScenarioState): ActionWith<ScenarioState> {
  return {
    type: ProjectsActionTypes.APPEND_SCENARIO_STATES,
    payload: scenarioState,
  };
}

function fetchDemoProjectsRequest(type: DemoProjectType, imperial?: boolean): ActionWith<[DemoProjectType, boolean]> {
  return {
    type: ProjectsActionTypes.FETCH_DEMO_PROJECTS_REQUEST,
    payload: [type, imperial],
  };
}

function fetchDemoProjectsSucceeded(
  type: DemoProjectType, demoProjects: DemoProject[],
): ActionWith<payloads.DemoProjectResponsePayload> {
  return {
    type: ProjectsActionTypes.FETCH_DEMO_PROJECTS_SUCCEEDED,
    payload: { type, demoProjects },
  };
}

function patchCompanyFromDemoProject(demoProjectId: number): ActionWith<number> {
  return {
    type: ProjectsActionTypes.PATCH_COMPANY_FROM_DEMO_PROJECT,
    payload: demoProjectId,
  };
}

function setApplyDemoProjectStatus(status: RequestStatus): ActionWith<RequestStatus> {
  return {
    type: ProjectsActionTypes.SET_APPLY_DEMO_PROJECT_STATUS,
    payload: status,
  };
}

function fetchCompanyProjectHeadersRequest(
  payload: payloads.CompanyProjectHeadersFeedRequestPayload,
): ActionWith<payloads.CompanyProjectHeadersFeedRequestPayload> {
  return {
    type: ProjectsActionTypes.FETCH_COMPANY_PROJECT_HEADERS_REQUEST,
    payload,
  };
}

function fetchCompanyProjectHeadersSucceeded(
  feed: Feed<CompanyProjectHeader>,
  companyId: number,
  requestedCount: number,
  type: ProjectType,
): ActionWith<payloads.CompanyProjectHeadersFeedResponsePayload> {
  return {
    type: ProjectsActionTypes.FETCH_COMPANY_PROJECT_HEADERS_SUCCEEDED,
    payload: { companyId, feed, requestedCount, type },
  };
}

function fetchCompanyProjectHeadersFailed(companyId: number): ActionWith<number> {
  return {
    type: ProjectsActionTypes.FETCH_COMPANY_PROJECT_HEADERS_FAILED,
    payload: companyId,
  };
}


function clearCompanyProjectHeaders(
  companyId: number,
  type: ProjectType,
): ActionWith<payloads.ClearProjectHeadersPayload> {
  return {
    type: ProjectsActionTypes.CLEAR_COMPANY_PROJECT_HEADERS,
    payload: { companyId, type },
  };
}

function changeCompanyProjectsSearchQuery(
  companyId: number,
  searchQuery: string | null,
  type: ProjectType,
): ActionWith<payloads.ChangeCompanyProjectsSearchQueryPayload> {
  return {
    type: ProjectsActionTypes.CHANGE_COMPANY_PROJECTS_SEARCH_QUERY,
    payload: { companyId, searchQuery, type },
  };
}

function setEngineFilterState(
  projectId: number,
  state: EngineFilterState,
  engineBasedPage: EngineBasedPages,
): ActionWith<payloads.ProjectSaveEngineFiltersState> {
  return {
    type: ProjectsActionTypes.SET_ENGINE_FILTER_STATE,
    payload: { state, projectId, engineBasedPage },
  };
}

function getEngineFilterState(
  engineBasedPage: EngineBasedPages,
): ActionWith<EngineBasedPages> {
  return {
    type: ProjectsActionTypes.GET_ENGINE_FILTER_STATE,
    payload: engineBasedPage,
  };
}

function getEngineFilterStateSucceeded(
  projectId: number,
  state: EngineFilterState,
  engineBasedPage: EngineBasedPages,
): ActionWith<payloads.ProjectSaveEngineFiltersState> {
  return {
    type: ProjectsActionTypes.GET_ENGINE_FILTER_STATE_SUCCEEDED,
    payload: { state, projectId, engineBasedPage },
  };
}

function failureProjectIdSend(
  id: number,
): ActionWith<number> {
  return {
    type: ProjectsActionTypes.FAILURE_PROJECT_SEND,
    payload: id,
  };
}

function failureProjectIdSendSucceeded(
  project: Project,
): ActionWith<Project> {
  return {
    type: ProjectsActionTypes.FAILURE_PROJECT_SEND_SUCCEEDED,
    payload: project,
  };
}

function locallyUpdateProjectHeader(
  project: CompanyProjectHeader,
): ActionWith<CompanyProjectHeader> {
  return {
    type: ProjectsActionTypes.LOCALLY_UPDATE_PROJECT_HEADER,
    payload: project,
  };
}

function moveProjectToFolder(
  projectId: number,
  folderId: number,
  isNeedRemove: boolean,
): ActionWith<payloads.MoveProjectToFolderPayload> {
  return {
    type: ProjectsActionTypes.MOVE_PROJECT_TO_FOLDER,
    payload: { projectId, folderId, isNeedRemove },
  };
}

function fetchAllTemplates(): Action {
  return {
    type: ProjectsActionTypes.FETCH_ALL_TEMPLATES,
  };
}

function fetchAllTemplatesSucceeded(
  projectHeaders: ShortProjectHeader[],
): ActionWith<ShortProjectHeader[]> {
  return {
    type: ProjectsActionTypes.FETCH_ALL_TEMPLATES_SUCCEEDED,
    payload: projectHeaders,
  };
}

function createTemplateFromProject(
  projectId: number,
): ActionWith<number> {
  return {
    type: ProjectsActionTypes.CREATE_TEMPLATE_FROM_PROJECT,
    payload: projectId,
  };
}

function dropStore(): Action {
  return {
    type: ProjectsActionTypes.DROP_STORE,
  };
}

function setIsProjectNotFound(
  isProjectNotFound: boolean,
): ActionWith<boolean> {
  return {
    type: ProjectsActionTypes.SET_PROJECT_NOT_FOUND,
    payload: isProjectNotFound,
  };
}

function changeLoadingStatus(): Action {
  return {
    type: ProjectsActionTypes.CHANGE_LOADING_STATUS,
  };
}

function changeProjectAccessReason(
  projectHeader: CompanyProjectHeader,
  accessReason: ProjectAccessReason,
): ActionWith<payloads.ChangeProjectAccessReasonPayload> {
  return {
    type: ProjectsActionTypes.CHANGE_PROJECT_ACCESS_REASON,
    payload: { projectHeader, accessReason },
  };
}

export const ProjectsActions = {
  locallyUpdateProjectHeader,
  clearCompanyProjectHeaders,
  removeProject,
  leaveProject,
  removeProjectSucceeded,
  runClassification,
  updateProjectStatusRequest,
  updateProjectStatusSucceeded,
  setCurrentProject,
  addProject,
  createProject,
  duplicate2dProject,
  duplicate2dProjectSucceeded,
  dump2dProject,
  createProjectFromDemoProject,
  createProjectSucceeded,
  storeProjectInfo,
  contractorInvite,
  updateProjectNameById,
  updateProjectName,
  updateProjectNameSucceeded,
  updateProjectInvitedUsers,
  getProjectBidPricingInfo,
  getProjectBidPricingInfoSucceeded,
  setProjectNotFinishedScenarioId,
  resetProjectHasUnstartedProducts,
  updateViewModelStatus,
  updateViewModelStatuses,
  dropCurrentProjectInfo,
  fetchScenarioStates,
  fetchScenarioStatesSucceeded,
  appendScenarioStates,
  fetchCompanyProjectHeadersRequest,
  fetchCompanyProjectHeadersSucceeded,
  fetchCompanyProjectHeadersFailed,
  fetchDemoProjectsRequest,
  fetchDemoProjectsSucceeded,
  patchCompanyFromDemoProject,
  setApplyDemoProjectStatus,
  changeCompanyProjectsSearchQuery,
  setEngineFilterState,
  failureProjectIdSend,
  failureProjectIdSendSucceeded,
  getEngineFilterState,
  getEngineFilterStateSucceeded,
  moveProjectToFolder,
  fetchAllTemplates,
  fetchAllTemplatesSucceeded,
  createTemplateFromProject,
  dropStore,
  setIsProjectNotFound,
  changeLoadingStatus,
  changeProjectAccessReason,
};
