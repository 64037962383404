import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import { FileUploadWrapObsolete } from 'common/components/file-upload-wrap';
import { ProjectType } from 'common/constants/project-type';
import { ProjectTypeToFileExtension } from 'common/constants/project-type-to-file-extensions';
import { UploadingFile } from 'common/interfaces/common-state';
import { State } from 'common/interfaces/state';
import { Common } from '../../../../actions';
import { KreoFormDialog } from '../../../../components/dialog/base/kreo-form-dialog';
import { Required } from '../../../../components/dialog/validators';
import { ADD_FILE_TO_PROJECT } from '../../../../constants/forms';
import { ModelManagementActions } from '../../actions/creators/model-management';
import { ModelManagementDialogs } from '../../constants/model-management-dialogs';

interface StateProps {
  isButtonDisabled: boolean;
  files: UploadingFile[];
}

interface DispatchProps {
  clearFiles: () => void;
  addFiles: (file: UploadingFile[]) => void;
}

interface Props extends StateProps, DispatchProps, InjectedFormProps<{}, {}> {

}

const validateFields = {
  name: 'Required',
  files: { _error: 'Required' },
};

export const projectValidateRequired = [Required];

class ModelManagementLoadFileDialogComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const submitButtonText = this.props.files && this.props.files.length > 1
      ? 'Add Files'
      : 'Add File';
    return (
      <KreoFormDialog
        formName={ADD_FILE_TO_PROJECT}
        title='Add Files'
        submitButtonText={submitButtonText}
        name={ModelManagementDialogs.LOAD_FILE}
        handleSubmit={this.onSubmitClick}
        onDialogClose={this.onClose}
        modal={false}
        validateFields={validateFields}
        submitDisabled={this.props.isButtonDisabled}
      >
        <FileUploadWrapObsolete
          isDownloadFileInProgress={false}
          formName={ADD_FILE_TO_PROJECT}
          validate={projectValidateRequired}
          extensions={ProjectTypeToFileExtension[ProjectType.Project3d].default}
          extensionsToShow={ProjectTypeToFileExtension[ProjectType.Project3d].toShow}
        />
      </KreoFormDialog>
    );
  }

  @autobind
  private onSubmitClick(handler: FormSubmitHandler<{}, {}>): void {
    this.props.addFiles(this.props.files);
    this.props.handleSubmit(handler);
    this.onClose();
  }

  @autobind
  private onClose(): void {
    this.props.reset();
    this.props.clearFiles();
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    isButtonDisabled: !state.common.files.length || state.common.files.some(x => x.progress < 1),
    files: state.common.files,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    clearFiles: () => dispatch(Common.commonClearFiles()),
    addFiles: files => dispatch(ModelManagementActions.addFiles(files)),
  };
}

const ModelManagementLoadFileDialogRedux =
  connect(mapStateToProps, mapDispatchToProps)
  (ModelManagementLoadFileDialogComponent);

export const ModelManagementLoadFileDialog = reduxForm<{}, {}>({
  form: ADD_FILE_TO_PROJECT,
  destroyOnUnmount: true,
})(ModelManagementLoadFileDialogRedux);
