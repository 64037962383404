import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  ConnectDragSource,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
} from 'react-dnd';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { Checkbox } from 'common/UIKit';
import { WorkPackageAction } from '../../actions';
import { WorkPackagesData } from '../../interfaces';
import { ItemSource } from '../targets';

import './index.scss';

interface CollectDragProps {
  connectDragSource: ConnectDragSource;
  isDragging: boolean;
}

const collect = (conn: DragSourceConnector, monitor: DragSourceMonitor): CollectDragProps => {
  return {
    connectDragSource: conn.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

interface ReduxActions {
  /**
   * @description check/uncheck current activity
   */
  selectActivity: (id: number) => void;
}

interface Props extends ReduxActions, CollectDragProps {
  activity: WorkPackagesData.Activity;
  isSelected: boolean;
  getIdsByHover: (ids: number[]) => void;
}

class ActivityItemComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { activity, isSelected, connectDragSource } = this.props;
    const { name } = activity;

    return connectDragSource (
      <div
        className={`activity-item ${isSelected ? 'selected' : ''}`}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
      >
        <span className='activity-item__draggable-indicator'>{'░'}</span>
        <div className='activity-item__checkbox'>
          <Checkbox checked={isSelected} onChange={this.onActivityCheck} />
        </div>
        <div className='activity-item__title' title={name}>{name}</div>
      </div>,
    );
  }

  @autobind
  private onMouseOver(): void {
    if (typeof this.props.getIdsByHover === 'function') {
      const { activity } = this.props;
      this.props.getIdsByHover(activity.engineids);
    }
  }

  @autobind
  private onMouseLeave(): void {
    if (typeof this.props.getIdsByHover === 'function') {
      this.props.getIdsByHover(null);
    }
  }

  @autobind
  private onActivityCheck(): void {
    const { id } = this.props.activity;
    this.props.selectActivity(id);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    selectActivity: (id: number): void => {
      dispatch(WorkPackageAction.selectGroupActivity(id));
    },
  };
};

const connected = connect(null, mapDispatchToProps)(ActivityItemComponent);
export const ActivityItem = DragSource('ACTIVITY_GROUP', ItemSource, collect)(connected);
