import autobind from 'autobind-decorator';
import * as React from 'react';
import { mathUtils } from 'common/utils/math-utils';
import { FormulaInputAutocompleteItemInfo } from './interfaces';
import { FormulaInputAutoCompleteItem, FormulaInputAutoCompleteItemConstants } from './item';
import { Styled } from './styled';


interface Props {
  searchText: string;
  targetElement: HTMLSpanElement;
  autoCompleteResults: FormulaInputAutocompleteItemInfo[];
  selectedIndex: number;
  parentContainerRef: HTMLDivElement;
  onItemClick: (item: FormulaInputAutocompleteItemInfo) => void;
}

interface ComponentState {
  containerRef: HTMLDivElement;
}

export class FormulaInputAutoComplete extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      containerRef: null,
    };
  }

  public render(): React.ReactNode {
    const {
      searchText,
      autoCompleteResults,
      selectedIndex,
      onItemClick,
    } = this.props;
    return (
      <Styled.Container
        id={'autocomplete'}
        style={this.getPositionParams()}
        ref={this.saveContainerRef}
      >
        {
          autoCompleteResults.map((item, index: number) => {
            return (
              <FormulaInputAutoCompleteItem
                key={item.text + item.type}
                item={item}
                searchText={searchText}
                selected={selectedIndex === index}
                onClick={onItemClick}
              />
            );
          })
        }
      </Styled.Container>
    );
  }

  public componentDidMount(): void {
    this.updateScrollByItemIndex(this.props.selectedIndex);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.selectedIndex !== this.props.selectedIndex) {
      this.updateScrollByItemIndex(this.props.selectedIndex);
    }
  }

  private updateScrollByItemIndex(index: number): void {
    const { containerRef } = this.state;
    if (containerRef) {
      containerRef.scrollTop = index * FormulaInputAutoCompleteItemConstants.itemHeight;
    }
  }

  @autobind
  private saveContainerRef(container: HTMLDivElement): void {
    this.setState({ containerRef: container });
  }

  private getPositionParams(): React.CSSProperties {
    if (!this.state.containerRef) {
      return {};
    }
    const { targetElement, parentContainerRef } = this.props;
    const targetElementBoundingRect = targetElement.getBoundingClientRect() as DOMRect;
    const parentContainerBoundingRect = parentContainerRef.getBoundingClientRect() as DOMRect;
    return {
      ...this.getHorizontalStyles(targetElementBoundingRect, parentContainerBoundingRect),
      ...this.getVerticalStyles(targetElementBoundingRect, parentContainerBoundingRect),
    };
  }

  private getVerticalStyles(
    targetElementBoundingRect: DOMRect,
    parentContainerBoundingRect: DOMRect,
  ): React.CSSProperties {
    const style: React.CSSProperties = {};
    const y = targetElementBoundingRect.y - parentContainerBoundingRect.y;
    const top = y + targetElementBoundingRect.height;
    if (y > parentContainerBoundingRect.height - top) {
      style.bottom = parentContainerBoundingRect.height - y;
      style.maxHeight = y;
    } else {
      style.maxHeight = parentContainerBoundingRect.bottom - targetElementBoundingRect.bottom;
      style.top = top;
    }
    return style;
  }

  private getHorizontalStyles(
    targetElementBoundingRect: DOMRect,
    parentContainerBoundingRect: DOMRect,
  ): React.CSSProperties {
    const style: React.CSSProperties = {};
    const { width: containerWidth } = this.state.containerRef.getBoundingClientRect() as DOMRect;
    const x = targetElementBoundingRect.x - parentContainerBoundingRect.x;

    if (x + targetElementBoundingRect.width > parentContainerBoundingRect.width - x) {
      style.right = parentContainerBoundingRect.right - targetElementBoundingRect.right;
      style.maxWidth = parentContainerBoundingRect.width;
      const containerLeft = style.right + containerWidth;
      if (parentContainerBoundingRect.width < containerLeft) {
        const right = style.right - (containerLeft - parentContainerBoundingRect.width);
        style.right = mathUtils.clamp(right, 0, parentContainerBoundingRect.width);
      }
    } else {
      style.maxWidth = parentContainerBoundingRect.width;
      style.left = x;
      const containerRight = style.left + containerWidth;
      if (parentContainerBoundingRect.width < containerRight) {
        const left = style.left - (containerRight - parentContainerBoundingRect.width);
        style.left = mathUtils.clamp(left, 0, parentContainerBoundingRect.width);
      }
    }
    return style;
  }
}
