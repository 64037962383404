import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FormControlLabelClassKey } from '@material-ui/core/FormControlLabel';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipPayload,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import './index.scss';

import { KreoColors } from 'common/enums/kreo-colors';
import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import {
  CostHistogram,
  CostHistogramColumn,
  CostHistogramHeader,
} from '../../../../interfaces/dashboard/cost-histogram';
import { Resources, ResourcesData } from '../../constants';

interface Props extends I18nAwareProps {
  data: CostHistogram[];
}

interface State {
  filter: number;
  selected: Resources[];
}

interface ToolTipCosts {
  values: string[];
  sum: string;
}

interface TooltipRenderProps extends TooltipProps {
  payload: Array<TooltipPayload & { payload: CostHistogramColumn }>;
}

interface TickType {
  x: number;
  y: number;
  width: number;
  height: number;
  payload: {
    index: number,
    value: string,
  };
  index: number;
}

const FILTERS = [
  { name: 'Weekly', value: 0 },
  { name: 'Monthly', value: 1 },
  { name: 'Quarterly', value: 2 },
  { name: 'Yearly', value: 3 },
];
const BAR_CHART_MARGIN = { top: 10, right: 30, left: 0, bottom: 0 };
const AXIS_TICK = { fill: KreoColors.gray7, fontSize: 11 };
const TOOLTIP_CURSOR = { opacity: '0.2' };
const formControlLabelClasses: Partial<ClassNameMap<FormControlLabelClassKey>> = {
  root: 'resource-allocation-chart__label-control',
  label: 'resource-allocation-chart__checkbox-label',
};

const COSTS_DISPLAY_THOUSANDS_THRESHOLD = 100;
const COSTS_DISPLAY_MILLIONS_THRESHOLD = 1000000;


class ResourceAllocationChartComponent extends React.Component<Props, State> {
  public state: State = {
    filter: 0,
    selected: [Resources.Labour, Resources.Plant, Resources.Material],
  };

  private xAxisRenderedYears: Set<string> = new Set();

  public render(): JSX.Element {
    if (!this.props.data || this.props.data.length === 0) return null;
    const { filter, selected } = this.state;

    return (
      <div className='resource-allocation-chart'>
        <ResponsiveContainer>
          <BarChart
            data={this.props.data.find(d => d.period === this.state.filter).columns}
            margin={BAR_CHART_MARGIN}
            barGap={0}
          >
            <CartesianGrid stroke={KreoColors.gray2} vertical={false} />
            <XAxis
              dataKey={
                filter === FILTERS[0].value || filter === FILTERS[1].value
                  ? 'header.title'
                  : 'header'
              }
              tick={this.customXTick}
            />
            <YAxis axisLine={false} tick={AXIS_TICK} tickFormatter={this.yAxisTicksFormatter} />
            {selected.includes(Resources.Labour) && (
              <Bar
                dataKey={'data.labor_cost'}
                fill={ResourcesData[Resources.Labour].color}
                name={ResourcesData[Resources.Labour].name}
                stackId='1'
              />
            )}
            {selected.includes(Resources.Plant) && (
              <Bar
                dataKey={'data.plant_cost'}
                fill={ResourcesData[Resources.Plant].color}
                name={ResourcesData[Resources.Plant].name}
                stackId='1'
              />
            )}
            {selected.includes(Resources.Material) && (
              <Bar
                dataKey={'data.material_cost'}
                fill={ResourcesData[Resources.Material].color}
                name={ResourcesData[Resources.Material].name}
                stackId='1'
              />
            )}
            <Tooltip cursor={TOOLTIP_CURSOR} content={this.tooltipRender} />
            <Legend verticalAlign='top' content={this.legendRenderer} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  @autobind
  private customXTick(data: TickType): JSX.Element {
    const item = this.props.data.find(d => d.period === this.state.filter).columns[data.payload.index]
      .header as CostHistogramHeader;
    if (data.index === 0) {
      this.xAxisRenderedYears.clear();
    }
    let year: string = null;
    if (!this.xAxisRenderedYears.has(item.year)) {
      year = item.year;
      this.xAxisRenderedYears.add(year);
    }
    return (
      <g>
        <text
          width={data.width}
          height={data.height}
          x={data.x}
          y={data.y}
          stroke='none'
          fill='#8390A3'
          textAnchor='middle'
          className='resource-allocation-chart__xtick'
        >
          <tspan dy='0.71em'>{data.payload.value}</tspan>
        </text>
        {(this.state.filter === FILTERS[0].value || this.state.filter === FILTERS[1].value) &&
          year && (
            <text
              x={data.x}
              y={data.y}
              stroke='none'
              fill='#8390A3'
              textAnchor='middle'
              className='resource-allocation-chart__xtick'
            >
              <tspan dy='1.8em'>{year}</tspan>
            </text>
        )}
      </g>
    );
  }

  @autobind
  private yAxisTicksFormatter(value: string): string {
    return this.props.i18n.c(value, '0.[00]a');
  }

  @autobind
  private setFilter(value: number): void {
    this.setState({ filter: value });
  }

  @autobind
  private legendRenderer(): JSX.Element {
    return (
      <div className='resource-allocation-legend'>
        <div className='filter'>
          {FILTERS.map(x => {
            return (
              <div
                key={x.value}
                className={this.state.filter === x.value ? 'x' : ''}
                onClick={this.setFilter.bind(this, x.value)} // eslint-disable-line react/jsx-no-bind
              >
                {x.name}
              </div>
            );
          })}
        </div>
        <div className='legend'>
          {ResourcesData.map(x => {
            const disableCheckbox = this.state.selected.length === 1 && this.state.selected.includes(x.id);
            return (
              <FormControlLabel
                key={x.id}
                control={
                  <Checkbox
                    checked={this.state.selected.includes(x.id)}
                    onChange={this.selectResource.bind(this, x.id)} // eslint-disable-line react/jsx-no-bind
                    disabled={disableCheckbox}
                    classes={{
                      root: classNames(
                        'resource-allocation-chart__checkbox',
                        x.name.toLocaleLowerCase(),
                      ),
                    }}
                  />
                }
                label={x.name}
                classes={formControlLabelClasses}
              />
            );
          })}
        </div>
      </div>
    );
  }

  @autobind
  private tooltipRender(data: TooltipRenderProps): JSX.Element {
    if (!data || !data.payload || data.payload.length === 0) {
      return null;
    }

    const costs = this.formatCost(data.payload.map(x => x.value as number));
    const header = data.payload[0].payload.header as CostHistogramHeader;
    const filter = this.state.filter;
    return (
      <div className='resource-allocation-tooltip'>
        <b>
          {filter === FILTERS[0].value
            ? `Week ${header.title} of ${header.year}`
            : filter === FILTERS[1].value ? `${header.title} of ${header.year}` : data.label}
        </b>
        <span>{costs.sum}</span>
        <div>
          {data.payload.map((x, i) => {
            return (
              <div key={x.dataKey as string}>
                <div>
                  <span style={{ backgroundColor: x.color }} />
                  <div>{x.name}</div>
                </div>
                <span style={{ color: x.color }}>{costs.values[i]}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  @autobind
  private selectResource(value: Resources): void {
    const selected = this.state.selected;
    const index = selected.indexOf(value);
    index >= 0 ? selected.splice(index, 1) : selected.push(value);
    this.setState({ selected });
  }

  @autobind
  private formatCost(costs: number[]): ToolTipCosts {
    const currencySymbol = this.props.i18n.currency.symbol;
    let minimalCost = costs[0];
    let sum = 0;
    costs.forEach((cost, i) => {
      if (i > 0 && cost > 0 && cost < minimalCost) {
        minimalCost = +cost;
      }
      sum += cost;
    });

    if (minimalCost < COSTS_DISPLAY_THOUSANDS_THRESHOLD) {
      return {
        values: costs.map(x => `${currencySymbol}${x.toFixed()}`),
        sum: `${currencySymbol}${sum.toFixed()}`,
      };
    } else if (minimalCost >= COSTS_DISPLAY_THOUSANDS_THRESHOLD || minimalCost < COSTS_DISPLAY_MILLIONS_THRESHOLD) {
      return {
        values: costs.map(x => (x === 0 ? `${currencySymbol}0` : `${currencySymbol}${(x / 1000).toFixed(2)}k`)),
        sum: `${currencySymbol}${(sum / 1000).toFixed(2)}k`,
      };
    } else if (minimalCost >= COSTS_DISPLAY_MILLIONS_THRESHOLD) {
      return {
        values: costs.map(x => (x === 0 ? `${currencySymbol}0` : `${currencySymbol}${(x / 1000000).toFixed(2)}m`)),
        sum: `${currencySymbol}${(sum / 1000000).toFixed(2)}m`,
      };
    }

    return { values: [], sum: '0' };
  }
}

export const ResourceAllocationChart = withI18n(ResourceAllocationChartComponent);
