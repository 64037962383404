import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';


import { Notification, NotificationProcessor } from 'common/interfaces/realtime-messages';
import { CodecUtils } from 'common/utils/codec-utils';
import { AppUrls } from '../../../../routes/app-urls';
import { CommonNotificationProcessor } from '../../../notifications/common-notification-processor';
import { ScenariosActions } from '../../../scenarios/actions';
import { ProjectsActions } from '../../actions/creators/common';
import { ScenarioStateC } from '../../interfaces/scenario-state';
import { ProjectSelectors } from '../../selectors';
import { ScenariosNotificationKeys } from '../keys/scenarios';


function* updateScenarioStatus(notification: Notification): SagaIterator {
  try {
    const { status, documentId, scenarioId } = notification.data;
    if (yield select(ProjectSelectors.projectIsCurrent, documentId)) {
      yield put(ScenariosActions.updateScenarioStatus({ scenarioId, status }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateScenarioState(notification: Notification): SagaIterator {
  try {
    const decodedValue = CodecUtils.decode(notification.data, ScenarioStateC);
    yield put(ProjectsActions.appendScenarioStates(decodedValue));
  } catch (error) {
    console.error(error);
  }
}

function* duplicatePassed(notification: Notification): SagaIterator {
  try {
    const { documentId } = notification.data;
    if (!window.location.pathname.startsWith(
      AppUrls.plan.project.scenario.listing.url({ projectId: documentId.toString() }),
    )) {
      yield call(CommonNotificationProcessor.addInfoAlert, notification);
    } else {
      yield put(ScenariosActions.getScenarios(documentId));
    }
  } catch (error) {
    console.error(error);
  }
}

function* updatePassed(notification: Notification): SagaIterator {
  try {
    const { status, documentId, scenarioId } = notification.data;
    if (yield select(ProjectSelectors.projectIsCurrent, documentId)) {
      yield put(ScenariosActions.updateScenarioStatus({ scenarioId, status }));
      if (/\/project\/[0-9]*\/scenarios\/?$/.test(window.location.href)) {
        // reload scenarios list on scenarios page
        yield put(ScenariosActions.getScenarios(documentId));
      }
    }

    if (!window.location.pathname.startsWith(
      AppUrls.plan.project.scenario.listing.url({ projectId: documentId.toString() }),
    )) {
      yield call(CommonNotificationProcessor.addInfoAlert, notification);
    }
  } catch (error) {
    console.error(error);
  }
}

export const ScenariosNotificationsProcessor: NotificationProcessor = {
  [ScenariosNotificationKeys.StatusUpdated]: updateScenarioStatus,
  [ScenariosNotificationKeys.DuplicatePassed]: duplicatePassed,
  [ScenariosNotificationKeys.DuplicateFailed]: CommonNotificationProcessor.addErrorAlert,
  [ScenariosNotificationKeys.UpdatePassed]: updatePassed,
  [ScenariosNotificationKeys.UpdateFailed]: CommonNotificationProcessor.addErrorAlert,
  [ScenariosNotificationKeys.Publish]: CommonNotificationProcessor.addNotification,
  [ScenariosNotificationKeys.DeletedAllExceptActive]: CommonNotificationProcessor.addNotification,
  [ScenariosNotificationKeys.StateChanged]: updateScenarioState,
};
