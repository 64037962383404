import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


interface Props {
  item: string;
  unit: number;
  unitPrice: number;
  amount: number;
}


export const  TableCell: React.FC<Props> = ({ item, unit, unitPrice, amount }: Props) => {
  return (
    <Styled.RowContainer>
      <Styled.Cell>
        <Styled.ContentWrapper isItem={true}>
          <Text>{item}</Text>
        </Styled.ContentWrapper>
      </Styled.Cell>
      <Styled.Cell>
        <Text>{unit}</Text>
      </Styled.Cell>
      <Styled.Cell>
        <Styled.ContentWrapper>
          <Text>£{unitPrice}</Text>
        </Styled.ContentWrapper>
      </Styled.Cell>
      <Styled.Cell>
        <Styled.ContentWrapper>
          <Text>£{amount}</Text>
        </Styled.ContentWrapper>
      </Styled.Cell>
    </Styled.RowContainer>
  );
};
