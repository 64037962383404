import {
  TextFieldInput,
} from 'unit-2d-database/components/side-panel/components/item-panel/helpers/map-text-property-to-field';
import { Field, FieldType } from 'unit-2d-database/components/side-panel/components/item-panel/interfaces';
import { ReportTemplateVariable } from '../../../interfaces';


const getChangeHandler = (onChange: (input: TextFieldInput) => void) => (value: string) => {
  onChange({ value, onChange: getChangeHandler(onChange) });
};

export const mapVariableToField = (
  variable: ReportTemplateVariable,
  onChange: (value: TextFieldInput) => void,
): Field<TextFieldInput> => {
  return {
    id: variable.name,
    name: variable.name,
    type: FieldType.TextInput,
    input: {
      value: variable.value,
      onChange: getChangeHandler(onChange),
    },
    getDataUpdate: (field): any => {
      return {
        groupName: variable.groupName,
        name: variable.name,
        value: field.input.value,
      };
    },
  };
};
