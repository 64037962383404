import { useCallback } from 'react';
import { useUndoRedoFullApi } from 'common/undo-redo';
import { AddInstancesWithUndoRedoPayload } from '../../drawings-contexts';
import { DrawingsUndoRedoHelper } from '../../utils/drawings-undo-redo-helper';
import { useAddGroups } from '../groups';
import { useAddInstances } from './use-add-instances';
import { useGroupsForInstanceCreate } from './use-groups-for-instance-create';
import { useRemoveInstances } from './use-remove-instances';

export function useAddInstancesWithUndo(): (payload: AddInstancesWithUndoRedoPayload) => void {
  const groupsForInstancesCreate = useGroupsForInstanceCreate();
  const processAddInstances = useAddInstances();
  const removeInstances = useRemoveInstances();
  const addGroups = useAddGroups();
  const { addUndoRedo } = useUndoRedoFullApi();
  return useCallback((payload: AddInstancesWithUndoRedoPayload) => {
    const { undo, redo } = DrawingsUndoRedoHelper.createAddElementUndoRedo(
      payload,
      groupsForInstancesCreate,
      (addInstancesPayload, groupsPayload) => {
        if (addInstancesPayload.groups?.length) {
          addGroups({ groups: addInstancesPayload.groups });
        }
        processAddInstances(addInstancesPayload, groupsPayload);
      },
      (instancesIds) => removeInstances(instancesIds, payload.groups?.map((g) => g.id) || []),
    );
    addUndoRedo(undo, redo);
    if (payload.groups) {
      addGroups({ groups: payload.groups });
    }
    processAddInstances(payload);
  }, [groupsForInstancesCreate, processAddInstances, removeInstances, addGroups, addUndoRedo]);
}
