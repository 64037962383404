import * as t from 'io-ts';
import { BillingPricingModelC } from '../enums/billing-pricing-model';

export const InvoiceTaxModelC = t.type(
  {
    amount: t.number,
    description: t.string,
  },
  'InvoiceTaxModel');

export const InvoiceItemModelC = t.type(
  {
    from: t.string,
    to: t.string,
    quantity: t.number,
    amount: t.number,
    pricingModel: BillingPricingModelC,
    description: t.string,
    entityType: t.string,
  },
  'InvoiceItemModel');

export const InvoiceDiscountModelC = t.type(
  {
    amount: t.number,
    description: t.string,
    discountType: t.string,
  },
  'InvoiceDiscountModel',
);

export const InvoiceModelC = t.type(
  {
    total: t.number,
    creditApplied: t.number,
    currency: t.string,
    items: t.array(InvoiceItemModelC),
    taxes: t.array(InvoiceTaxModelC),
    discounts: t.array(InvoiceDiscountModelC),
  },
  'InvoiceModel');

export const EstimateSubscriptionModelC = t.partial(
  {
    nextBillingAt: t.string,
    immediateInvoice: InvoiceModelC,
    nextInvoice: InvoiceModelC,
  },
  'EstimateSubscriptionModel');


export type InvoiceTaxModel = t.TypeOf<typeof InvoiceTaxModelC>;
export type InvoiceItemModel = t.TypeOf<typeof InvoiceItemModelC>;
export type InvoiceModel = t.TypeOf<typeof InvoiceModelC>;
export type InvoiceDiscountsModel = t.TypeOf<typeof InvoiceDiscountModelC>;
export type EstimateSubscriptionModel = t.TypeOf<typeof EstimateSubscriptionModelC>;
