import classNames from 'classnames';
import React from 'react';

import './fab.scss';

import { TooltipContainer } from 'common/components/tooltip-container';


interface Props {
  onClick: (e: any) => void;
  size: number;
  color?: string;
  top?: boolean;
  left?: boolean;
  tooltipText?: string;
  floatVertical?: number;
  floatHorizontal?: number;
  shadow?: boolean;
  badgeActive?: boolean;
  style?: React.CSSProperties;
  hasShadow?: boolean;
  /**
   * used by analytics and autotests
   */
  controlName: string;
  className?: string;
}
/**
 * Floating Action Button
 */
class Fab extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    onClick: () => {
      console.warn('please add event handler for onClick');
    },
    size: 30,
    color: '#5C8AE6',
    top: false,
    left: false,
    floatVertical: 30,
    floatHorizontal: 30,
    style: {},
  };

  public getStyle(): React.CSSProperties {
    const { size, color, top, left, floatVertical, floatHorizontal } = this.props;

    const style: React.CSSProperties = {
      width: size,
      height: size,
      borderRadius: size / 2,
      backgroundColor: color,
    };

    if (top) {
      style.top = floatVertical;
    } else {
      style.bottom = floatVertical;
    }
    if (left) {
      style.left = floatHorizontal;
    } else {
      style.right = floatHorizontal;
    }

    return { ...style, ...this.props.style };
  }

  public render(): JSX.Element {
    const { className, onClick, tooltipText, controlName, children, hasShadow, badgeActive } = this.props;
    return (
      <div
        className={classNames(
          'floating-action',
          { 'floating-action--has-shadow': hasShadow },
          { 'floating-action--badge': badgeActive },
          className,
        )}
        style={this.getStyle()}
        onClick={onClick}
        data-control-name={controlName}
      >
        {children}
        {tooltipText
          ? <TooltipContainer className='floating-action__tooltip'>{tooltipText}</TooltipContainer>
          : null
        }
      </div>
    );
  }
}

export default Fab;
