import { SheetUpdateCells } from '../../actions/payloads';
import { UpdateCellForm } from '../../interfaces';
import { MoveDataPayload } from './interfaces';
import { moveData } from './move-data';
import { updateRefs } from './update-refs';

export function getUpdatedCells(payload: MoveDataPayload): Array<SheetUpdateCells<UpdateCellForm[]>> {
  const tempData = moveData(payload);

  if (tempData === null) {
    return null;
  }

  return updateRefs(tempData, payload);
}

