import * as paper from 'paper';

import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import { DrawingPointsType } from '../interfaces/drawing-point-info';

function mergePointInfo(source: DrawingPointsType, target: DrawingPointsType): DrawingPointsType {
  const result = { ...source };
  for (const [x, ysInfo] of Object.entries(target)) {
    if (!result[x]) {
      result[x] = ysInfo;
      continue;
    }
    const resultX = { ...result[x] };
    for (const [y, info] of Object.entries(ysInfo)) {
      if (!resultX[y]) {
        resultX[y] = info;
        continue;
      }
      arrayUtils.extendArray(info.ids, resultX[y].ids);
      resultX[y].ids = arrayUtils.uniq(info.ids);
    }
    result[x] = resultX;
  }
  return result;
}

function addPoint(
  points: DrawingPointsType,
  pointId: string,
  [x, y]: ShortPointDescription,
  withoutPaper?: boolean,
): void {
  points[x] = points[x] || {};
  points[x][y] = points[x][y] || {
    paperPoint: withoutPaper ? null : new paper.Point(x, y),
    ids: [],
  };
  points[x][y].ids.push(pointId);
}

function addPointsToCommon(
  points: Record<string, ShortPointDescription>,
  drawingId: string,
  drawingPoints: Record<string, string[]>,
): Record<string, string[]> {
  return new MonoliteHelper(drawingPoints)
    .set(_ => _[drawingId], _ => _ ? _.concat(Object.keys(points)) : Object.keys(points))
    .get();
}

function removeDrawingPoints(
  source: string[],
  pointsToRemove: string[],
): string[] {
  const pointsToRemoveSet = new Set(pointsToRemove);
  return source.filter(s => !pointsToRemoveSet.has(s));
}

export const DrawingsPointUtils = {
  addPointsToCommon,
  addPoint,
  mergePointInfo,
  removeDrawingPoints,
};
