import * as paper from 'paper';

import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { ColorList } from 'common/constants/color-list';
import { ViewModelStatus } from '../../../../units/projects/enums/view-model-status';
import { DrawingsScaleConstant } from '../constants/drawings-scale-constants';
import { PdfPageFilterResponse } from '../interfaces/api-responses/pdf-filter';
import { DrawingPointsType } from '../interfaces/drawing-point-info';
import { DrawingsCreateFile, DrawingsFileSystem, DrawingsPageResponse } from '../interfaces/drawings-api-payload';
import {
  DrawingsFile,
  DrawingsFiles,
  DrawingsFolderViewInfo,
  DrawingsPage,
} from '../interfaces/drawings-file-info';
import { DrawingsGeometryPointCoordinates } from '../interfaces/drawings-geometry';
import { DrawingsShortInfo } from '../interfaces/drawings-short-drawing-info';
import { RasterizationFileUtils } from './rasterization-file-utils';

interface DrawingsAggregationResult {
  files: DrawingsFiles;
  drawings: Record<string, DrawingsShortInfo>;
  filesCount: number;
  selectedPages: string[];
}
interface DrawingsPageAggregationResult {
  page: DrawingsPage;
  drawingInfo: DrawingsShortInfo;
}

interface DrawingsFileAggregationResult {
  file: DrawingsFile;
  drawings: Record<string, DrawingsShortInfo>;
}

function getMetersPerPx(width: number, height: number, format: string): number {
  const formatPageWidth = DrawingsScaleConstant.PagesFormatDescription[format][1];
  return formatPageWidth / Math.max(width, height);
}

function parsePage(
  page: DrawingsPageResponse,
  pdfId: string,
  screenshotsStatus: ViewModelStatus,
): DrawingsPageAggregationResult {
  const filePage: DrawingsPage = {
    drawingId: page.id,
    pageNumber: page.pageNumber,
    pdfId,
    recognitionStatus: page.recognitionStatus,
    screenshotCreated: screenshotsStatus === ViewModelStatus.Ready,
    isOptimized: page.isOptimized,
  };
  return {
    drawingInfo: {
      ...page,
      ...filePage,
      color: page.color || ColorList[0],
      name: page.name || `${page.pageNumber + 1}`,
      drawingCalibrationLineLength: page.drawingCalibrationLineLength,
      originalCalibrationLineLength: page.originalCalibrationLineLength,
      recommendedDrawingCalibrationLineLength: page.recommendedDrawingCalibrationLineLength,
      recommendedOriginalCalibrationLineLength: page.recommendedOriginalCalibrationLineLength,
      recommendedPaperSize: page.recommendedPaperSize,
      paperSize: page.paperSize,
      width: page.width,
      height: page.height,
      hasMeasurements: page.hasMeasurements,
      scaleRecognitionStatus: page.scaleRecognitionStatus,
      isOptimized: page.isOptimized,
      rasterizationStatus: page.rasterizationStatus,
    },
    page: filePage,
  };
}

interface CurrentPageScale {
  paperSize: string;
  scale: number;
  originalCalibrationLineLength: number;
  drawingCalibrationLineLength: number;
}

function getOriginalSizes(
  {
    drawingCalibrationLineLength,
    originalCalibrationLineLength,
  }: DrawingsPageResponse | DrawingsShortInfo,
): { originalCalibrationLineLength: number, drawingCalibrationLineLength: number } {
  return {
    originalCalibrationLineLength: originalCalibrationLineLength || 1,
    drawingCalibrationLineLength: drawingCalibrationLineLength || 1,
  };
}

function getCurrentPageScales(
  page: DrawingsPageResponse | DrawingsShortInfo,
): CurrentPageScale {
  const { originalCalibrationLineLength, drawingCalibrationLineLength } = getOriginalSizes(page);
  return {
    paperSize: page.paperSize || page.recommendedPaperSize || DrawingsScaleConstant.DEFAULT_FORMAT,
    scale: originalCalibrationLineLength / drawingCalibrationLineLength,
    drawingCalibrationLineLength,
    originalCalibrationLineLength,
  };
}

function isCalibrated(
  { originalCalibrationLineLength, drawingCalibrationLineLength }: DrawingsPageResponse | DrawingsShortInfo,
): boolean {
  return !!(originalCalibrationLineLength && drawingCalibrationLineLength);
}


function parseFile(
  fileResponse: DrawingsCreateFile,
  parentId?: string,
  drawings?: Record<string, DrawingsShortInfo>,
): DrawingsFileAggregationResult {
  const drawingsResult: Record<string, DrawingsShortInfo> = drawings || {};
  const { id, name, pages, screenshotsStatus, cadFileConvertingStatus, splittingStatus } = fileResponse;
  const file: DrawingsFile =  {
    id, parentId,
    pages: [],
    properties: { name },
    type: TreeTableRowType.Element,
    cadFileConvertingStatus,
    screenshotsStatus,
    splittingStatus,
  };
  for (const page of pages) {
    const { drawingInfo, page: parsedPage } = parsePage(page, id, screenshotsStatus);
    file.pages.push(parsedPage);
    drawingsResult[page.id] = drawingInfo;
  }
  file.pages.sort((x, y) => x.pageNumber - y.pageNumber);
  return {
    file,
    drawings: drawingsResult,
  };
}

function prepareDrawingsData(
  fs: DrawingsFileSystem,
  files?: DrawingsFiles,
  drawings?: Record<string, DrawingsShortInfo>,
  parentId?: string,
): DrawingsAggregationResult {
  const fileResult: DrawingsFiles = files || { filesCount: 0, rootIds: [], entities: {} };
  const drawingsResult: Record<string, DrawingsShortInfo> = drawings || {};
  let filesCount = 0;
  for (const { id, name, innerFileSystem } of fs.folders) {
    (fileResult.entities[id] as DrawingsFolderViewInfo) = {
      id,
      parentId,
      children: [],
      properties: { name },
      expanded: false,
      type: TreeTableRowType.Group,
    };
    if (parentId) {
      fileResult.entities[parentId].children.push(id);
    } else {
      fileResult.rootIds.push(id);
    }
    const fsResult = prepareDrawingsData(innerFileSystem, fileResult, drawingsResult, id);
    filesCount += fsResult.filesCount;
  }
  for (const fileResponse  of fs.files) {
    filesCount++;
    const { file } = parseFile(fileResponse, parentId, drawingsResult);
    fileResult.entities[file.id] = file;
    if (parentId) {
      fileResult.entities[parentId].children.push(file.id);
    } else {
      fileResult.rootIds.push(file.id);
    }
  }

  return {
    files: fileResult,
    drawings: drawingsResult,
    filesCount,
    selectedPages: fs.selectedPages,
  };
}

function addPointToCurrentDrawing(
  current: DrawingPointsType,
  point: DrawingsGeometryPointCoordinates,
  instanceId: string,
): void {
  if (!point) {
    return;
  }
  current[point.x] = current[point.x] || {};
  current[point.x][point.y] = current[point.x][point.y] || { paperPoint: new paper.Point(point), ids: [] };
  current[point.x][point.y].ids.push(instanceId);
}

function removePointFromCurrentDrawing(
  current: DrawingPointsType,
  point: DrawingsGeometryPointCoordinates,
  instanceId: string,
): void {
  if (!point || !current[point.x] || !current[point.x][point.y]) {
    return;
  }
  current[point.x][point.y].ids = current[point.x][point.y].ids.filter(x => x !== instanceId);
  if (!current[point.x][point.y].ids.length) {
    delete current[point.x][point.y];
  }
  if (!Object.keys(current[point.x]).length) {
    delete current[point.x];
  }
}

function getFileKey(file: File): string {
  return `${file.lastModified}${file.name}`;
}


function getPDFPageNumber(pageNumber: number): number {
  return pageNumber + 1;
}

function isFilterEnabled(filter: PdfPageFilterResponse): boolean {
  return filter?.isEnabled && filter.state.uselessElementStylesCount > 0;
}

function isFilterDrawingFilterChanged(
  drawing: DrawingsShortInfo,
  drawingToCompareWith: DrawingsShortInfo,
): boolean {
  const isPrevFilterEnabled = isFilterEnabled(drawing?.filter);
  const isNextFilterEnabled = isFilterEnabled(drawingToCompareWith?.filter);

  if (isPrevFilterEnabled !== isNextFilterEnabled) {
    return true;
  }

  if (isPrevFilterEnabled && isNextFilterEnabled) {
    return drawing?.filter !== drawingToCompareWith?.filter;
  } else {
    return false;
  }
}

function isDrawingInfoChanged(
  drawing: DrawingsShortInfo,
  drawingToCompareWith: DrawingsShortInfo,
): boolean {
  if (!drawing && !drawingToCompareWith) {
    return false;
  }

  if (
    drawing?.drawingId !== drawingToCompareWith?.drawingId
    || isFilterDrawingFilterChanged(drawing, drawingToCompareWith)
  ) {
    return true;
  }
  if (drawing && drawingToCompareWith) {
    const rerenderByOptimize = drawing.isOptimized
      && RasterizationFileUtils.isRasterizationReady(drawing)
      && !RasterizationFileUtils.isRasterizationReady(drawingToCompareWith);
    return drawingToCompareWith.pageToCompareId !== drawing.pageToCompareId
      || drawing.isOptimized !== drawingToCompareWith.isOptimized
      || rerenderByOptimize
      || drawing.pagesCompareSettings?.isActive !== drawingToCompareWith.pagesCompareSettings?.isActive;
  }
  return false;
}

function canRerenderDrawingComparedDrawing(
  drawing: DrawingsShortInfo,
  prevDrawingInfo: DrawingsShortInfo,
): boolean {
  if (!drawing || !prevDrawingInfo) {
    return false;
  }
  if (drawing.pageToCompareId === prevDrawingInfo.pageToCompareId) {
    return drawing.pagesCompareSettings !== prevDrawingInfo.pagesCompareSettings;
  }
  return false;
}

export const DrawingsUtils = {
  prepareDrawingsData,
  addPointToCurrentDrawing,
  removePointFromCurrentDrawing,
  getFileKey,
  parseFile,
  parsePage,
  getPDFPageNumber,
  getMetersPerPx,
  getCurrentPageScales,
  isCalibrated,
  isDrawingInfoChanged,
  canRerenderDrawingComparedDrawing,
};
