import { AnyAction } from 'redux';


import { MODEL_MANAGEMENT_INITIAL_STATE } from '../units/projects/constants/model-management-initial-state';
import { ModelManagementState } from '../units/projects/interfaces/model-management/state';
import { modelManagementReducerMethods } from '../units/projects/reducers/model-management';

export function modelManagement(
  state: ModelManagementState = MODEL_MANAGEMENT_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null }): ModelManagementState {
  if (action.type in modelManagementReducerMethods) {
    return modelManagementReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
