import * as React from 'react';

export const KreoIconBack: React.FC = () => {
  return (
    <svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' >
      <g
        id='U01_Project_4D_SelectedPeriod_Month'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        transform='translate(-125.000000, -568.000000)'
      >
        <g id='Timeline' transform='translate(60.000000, 556.000000)' stroke='#FFFFFF'>
          <g id='Top-panel'>
            <g id='Controls'>
              <g transform='translate(65.000000, 12.000000)' id='4D_controls_back'>
                <g>
                  <g id='play'>
                    <circle id='Oval' strokeOpacity='0.2' cx='16' cy='16' r='15.5' />
                    <polyline
                      id='Rectangle-7'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      points='15 12.5 11.5 16 15 19.5'
                    />
                    <path d='M18,12.5 L18,19.5' id='Rectangle-7' strokeLinecap='round' strokeLinejoin='round' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
