import * as React from 'react';

import './title-container.scss';

import { ModelCheckPriority } from '../../../enums/mode-check-priority';
import { ModelCheckType } from '../../../enums/model-check-type';
import { CopyToClipBoard } from '../components/copy-to-clipboard';
import { TipPositions } from '../components/tip-positions';
import { TooltipIcon } from '../components/tooltip-icon';


interface Props {
  title: string;
  overlay: boolean;
  main?: boolean;
  statusType?: ModelCheckPriority;
  modelCheckType?: ModelCheckType;
  onCopyClick?: () => void;
}


export const TitleContainer: React.FC<Props> = (props) => {
  return (
    <div className={`model-check-title-container ${props.overlay ? 'overlay' : ''}`}>
      {props.title}
      {props.main && [
        <CopyToClipBoard key={0} onClick={props.onCopyClick} text={'Copy ids to clipboard'}/>,
        <TooltipIcon
          modelCheckType={props.modelCheckType}
          type={props.statusType}
          key={1}
          tipPosition={TipPositions.BOTTOM}
        />,
      ]
      }
    </div>
  );
};


TitleContainer.defaultProps = {
  main: false,
  onCopyClick: () => {
    console.warn('please assign method');
  },
};
