import autobind from 'autobind-decorator';

import { CommunicatorMessage } from '../communicator/communicator';
import { MultiViewData } from './multi-view-data';
import { MultiViewHelperChild } from './multi-view-helper-child';
import { MultiViewHelperParent } from './multi-view-helper-parent';


const innerCommandType = 'INNER_COMMAND';
export const INNER_COMMANDS = {
  DESTROY: `${innerCommandType}_DESTROY`,
  LOADED: `${innerCommandType}_LOADED`,
  IS_ALIVE: `${innerCommandType}_IS_ALIVE`,
};

interface EventData<T> {
  type: string;
  value: T;
}

export interface MultiVieHelperModule {
  isParentMode: boolean;
  handleOnDestroy: () => void;
  handleOnDestroyPartner: () => void;
  openChild: (url: string) => void;
  handleOnLoaded: (value: string) => void;
  handleIsAlive: () => void;
  closeSecondWindow: () => void;
}

export class MultiViewHelper {
  public data: MultiViewData;
  protected module: MultiVieHelperModule = null;

  private eventHandlers: Record<string, (data: any) => void> = {};

  private resetInitState: () => void;
  private onOpenChild: () => void;

  public constructor(
    chanelName: string,
    parentId?: string,
    currentId?: string,
    onSetParentType?: (state: MultiViewHelper) => void,
    onSetChildType?: (state: MultiViewHelper) => void,
    resetInitState?: () => void,
    onOpenChild?: () => void,
  ) {
    this.data = new MultiViewData(currentId);
    this.data.disable = this.isBroadCastDisable();
    if (this.data.disable) {
      return;
    }

    this.resetInitState = resetInitState;
    this.onOpenChild = onOpenChild;

    this.data.channel.createChannel({ chanelName, messageReceiver: this.onMessage });
    this.data.channel.setMessageReceiver();
    this.addEventHandler(innerCommandType, this.handleInnerCommand);
    if (parentId) {
      this.module = new MultiViewHelperChild(this.data, parentId, this.sendInnerCommand);
      onSetChildType(this);
    } else {
      this.module = new MultiViewHelperParent(
        this.data,
        this.sendInnerCommand,
        this.resetInitState,
        this.onOpenChild,
      );
      onSetParentType(this);
    }
  }

  public openChild(url: string): void {
    if (this.module === null) {
      return;
    }
    if (!this.data.disable) {
      this.module.openChild(url);
    } else {
      this.resetInitState();
    }
  }

  public hasPartner(): boolean {
    return !!this.data.secondViewId;
  }

  public destroy(): void {
    this.sendDestroyEvent();
    if (this.module) {
      this.module.handleOnDestroy();
      this.module = null;
    }
  }

  public closeSecondWindow(): void {
    this.module.closeSecondWindow();
  }

  public isDisable(): boolean {
    return this.data.disable;
  }

  public isParentMode(): boolean {
    return this.data.disable
      || (this.module !== null && this.module.isParentMode);
  }

  @autobind
  public addEventHandler<T>(type: string, handler: (data: T) => void): void {
    this.eventHandlers[type] = handler;
  }

  @autobind
  public sendEvent<T>(type: string, value: T): void {
    this.data.channel.sendMessage({ type, value, source: this.data.id, target: this.data.secondViewId });
  }

  protected removeEventHandler(type: string): void {
    delete this.eventHandlers[type];
  }

  @autobind
  private sendInnerCommand(value: any): void {
    this.sendEvent(innerCommandType, value);
  }

  @autobind
  private handleInnerCommand(data: EventData<any>): void {
    switch (data.type) {
      case INNER_COMMANDS.DESTROY:
        if (this.module) {
          this.module.handleOnDestroyPartner();
        }
        break;
      case INNER_COMMANDS.LOADED:
        if (this.module) {
          this.module.handleOnLoaded(data.value);
        }
        break;
      case INNER_COMMANDS.IS_ALIVE:
        if (this.module) {
          this.module.handleIsAlive();
        }
        break;
      default:
        console.error('MV INVALID INNER COMMAND');
    }
  }

  private isBroadCastDisable(): boolean {
    try {
      return !BroadcastChannel;
    } catch {
      return true;
    }
  }

  @autobind
  private onMessage(data: CommunicatorMessage): void {
    if (this.isSkipEventHandle(data)) {
      return;
    }
    this.eventHandlers[data.type](data.value);
  }

  private isSkipEventHandle(data: CommunicatorMessage): boolean {
    return this.isSelfMessage(data)
      || !this.isTargetCorrect(data)
      || !this.isHandlerRegister(data);
  }

  private isSelfMessage(data: CommunicatorMessage): boolean {
    return data.source === this.data.id;
  }

  private isTargetCorrect(data: CommunicatorMessage): boolean {
    return data.target === this.data.id;
  }

  private isHandlerRegister(data: CommunicatorMessage): boolean {
    return !!this.eventHandlers[data.type];
  }

  private sendDestroyEvent(): void {
    this.sendEvent(innerCommandType, { type: INNER_COMMANDS.DESTROY });
  }
}
