import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ClassificationAssignmentBodyView } from '../classification-assignment-body-view';
import { ClassificationOntologyTreeBody } from '../classification-ontology-tree-body';


interface Props {
  isUniclassEdit: boolean;
}

const ClassificationLeftSideViewComponent: React.FC<Props> = ({ isUniclassEdit }) => {
  return isUniclassEdit ? <ClassificationAssignmentBodyView/> : <ClassificationOntologyTreeBody isEdit={false}/>;
};

function mapStateToProps(state: State): Props {
  return {
    isUniclassEdit: state.classification.isUniclass,
  };
}

export const ClassificationLeftSideView = connect(mapStateToProps)(ClassificationLeftSideViewComponent);
