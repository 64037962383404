import React from 'react';

import { InstancesPropertiesEditor } from 'common/components/drawings/layout-components/instances-properties-editor';
import { AssignForm } from './assign-form';
interface Props {
  selectedInstancesIds: string[];
}

export const InstancesView: React.FC<Props> = () => {
  return (
    <>
      <InstancesPropertiesEditor/>
      <AssignForm isGroup={false} />
    </>
  );
};
