import React from 'react';

import { ConfirmationDialog } from '../../../../../components/dialog/confirmation-dialog';
import { DialogText } from '../../dialog-text';

export const CONFIRM_PAUSE_SUBSCRIPTION_DIALOG = 'CONFIRM_PAUSE_SUBSCRIPTION_DIALOG';

const TEXT = `Your subscription will be paused immediately`;
const WARNING = `You can resume it at any time`;

interface Props {
  onConfirm: () => void;
}

export const PauseSubscriptionDialog: React.FC<Props> = ({ onConfirm }) => {
  return (
    <ConfirmationDialog
      name={CONFIRM_PAUSE_SUBSCRIPTION_DIALOG}
      title="Pause Subscription"
      text={TEXT}
      cancelButtonText="No, Go Back!"
      confirmButtonText="Yes"
      onConfirm={onConfirm}
    >
      <DialogText
        text={WARNING}
      />
    </ConfirmationDialog>
  );
};
