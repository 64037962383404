const toUpperCase = (value: string): string => {
  return value.toUpperCase();
};

const toLowerCase = (value: string): string => {
  return value.toLowerCase();
};

export const StringUtil = {
  capitalize: (value: string): string => {
    return value.replace(/(?:^|\s)\S/g, toUpperCase);
  },
  lowerize: (value: string): string => {
    return value.replace(/(?:^|\s)\S/g, toLowerCase);
  },
};
