import { DrawingsInstanceMeasure, DrawingsMeasureType, DrawingsMeasureUtils } from 'common/components/drawings';
import { MoveToCellOptionType } from 'common/components/drawings/drawings-canvas-menus';
import { DrawingsInstanceType } from 'common/components/drawings/enums/drawings-instance-type';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { ColumnKeys, ColumnNames, measure3dColumnKeys } from '../../constants/table-settings';
import { TableSettings } from '../../interfaces';
import { getMeasureKey } from '../get-measure';

type MeasureGetter = (id: string, type: DrawingsInstanceType, measures: DrawingsMeasureType) => string[];
type SettingGetter = (columnKey: string, index: number) => string;
type CheckInstanceType = (type: DrawingsInstanceType, measures: DrawingsMeasureType) => boolean;

interface StartCellInfo {
  sheetId: string;
  columnId: string;
  rowIndex: number;
}

interface GroupBreakdownInfo {
  maxNesting: number;
  groupColumnCount: number;
}

const summableColumns = new Set([
  ColumnKeys.area,
  ColumnKeys.perimeter,
  ColumnKeys.length,
  ColumnKeys.count,
  ColumnKeys.pointsCount,
  ColumnKeys.segmentsCount,
  ColumnKeys.volume,
  ColumnKeys.verticalArea,
]);

const isPolygon = (type: DrawingsInstanceType, measures: DrawingsMeasureType): boolean => {
  return DrawingsMeasureUtils.isPolygonMeasure(type, measures)
    || DrawingsMeasureUtils.isRectangleMeasure(type, measures);
};

const isLength = (type: DrawingsInstanceType, measures: DrawingsMeasureType): boolean => {
  return DrawingsMeasureUtils.isLengthMeasure(type, measures);
};

const isStroked = (type: DrawingsInstanceType, measures: DrawingsMeasureType): boolean => {
  return isPolygon(type, measures) || isLength(type, measures);
};

const isCount = (type: DrawingsInstanceType, measures: DrawingsMeasureType): boolean => {
  return DrawingsMeasureUtils.isCountMeasure(type, measures);
};

const getGroupRef = (id: string, nesting: number, groupColumnCount: number): string[] => {
  const groups = [];
  for (let i = 0; i < groupColumnCount; i++) {
    groups.unshift(`${MoveToCellOptionType.groupName}(${id}, ${nesting - i})`);
  }
  return groups;
};

const measureConditions: Record<string, CheckInstanceType> = {
  [ColumnKeys.perimeter]: isPolygon,
  [ColumnKeys.length]: isLength,
  [ColumnKeys.segmentsCount]: isStroked,
  [ColumnKeys.area]: (type, measure) => !isCount(type, measure),
  [ColumnKeys.height]: (type, measure) => !isCount(type, measure),
  [ColumnKeys.thickness]: isLength,
  [ColumnKeys.verticalArea]: (type, measure) => !isCount(type, measure),
  [ColumnKeys.volume]: (type, measure) => !isCount(type, measure),
};

const measureIsAvailable = (column: string, type: DrawingsInstanceType, measures: DrawingsMeasureType): boolean => {
  return column in measureConditions ? measureConditions[column](type, measures) : true;
};

const getMeasuresGetters = (columns: string[], nesting: number, groupColumnCount: number): MeasureGetter[] => {
  return columns.map(column => {
    if (column === ColumnKeys.groupsBreakdown) {
      return (id) => getGroupRef(id, nesting, groupColumnCount);
    }
    if (column === ColumnKeys.parentGroupName) {
      return (id) => getGroupRef(id, -1, 1);
    }
    return (id, type, measures) => measureIsAvailable(column, type, measures)
      ? [getMeasureKey(id, column.split('_')[1])]
      : [''];
  });
};

const processSettingRow = (
  columnKeys: string[],
  getValue: SettingGetter,
  groupColumnCount: number,
  groupColumnValue: string,
): string[] => {
  let values = [];
  let columnIndex = 0;
  columnKeys.forEach((column) => {
    if (column === ColumnKeys.groupsBreakdown) {
      if (groupColumnCount > 0) {
        values = values.concat(Array(groupColumnCount).fill(groupColumnValue));
        columnIndex += groupColumnCount;
      }
    } else {
      values.push(getValue(column, columnIndex));
      columnIndex++;
    }
  });
  return values;
};

const getSumGetter = (
  sheetId: string,
  startColumnId: string,
  startRowIndex: number,
  length: number,
): SettingGetter => {
  return (columnKey: string, index: number) => {
    if (summableColumns.has(columnKey)) {
      if (length < 0) {
        return '0';
      }
      const columnId = ExcelColumnHelper.shiftColumnName(startColumnId, index);
      const startRowId = ExcelTableRowIdentification.getRowIdFromIndex(startRowIndex);
      const endRowId = ExcelTableRowIdentification.getRowIdFromIndex(startRowIndex + length);
      const cellRange = `${sheetId}!${columnId}${startRowId}:${columnId}${endRowId}`;
      return `SUM(${cellRange})`;
    }
    return '';
  };
};

export const getValueToInsertInTable = (
  startCell: StartCellInfo,
  instancesMeasures: DrawingsInstanceMeasure[],
  groupBreakdown: GroupBreakdownInfo,
  settings: TableSettings,
  showMeasure3d: boolean,
): string[][] => {
  const { columnKeys, showColumnHeaders, showTotal } = settings;
  const { maxNesting, groupColumnCount } = groupBreakdown;
  const table: string[][] = [];

  const availableColumnsKey = showMeasure3d
    ? columnKeys
    : columnKeys.filter(c => !measure3dColumnKeys.has(c));

  if (showColumnHeaders) {
    const headers = processSettingRow(
      availableColumnsKey,
      (column: string) => ColumnNames[column],
      groupColumnCount,
      ColumnNames[ColumnKeys.groupsBreakdown],
    );
    table.push(headers);
  }

  const measureGetters = getMeasuresGetters(availableColumnsKey, maxNesting, groupColumnCount);
  instancesMeasures.forEach(instance => {
    const { type, measures, id } = instance;
    let options = [];
    measureGetters.forEach(getter => options = options.concat(getter(id as string, type, measures)));
    table.push(options);
  });

  if (showTotal) {
    const startRow = startCell.rowIndex + Number(showColumnHeaders);
    const getter = getSumGetter(startCell.sheetId, startCell.columnId, startRow, instancesMeasures.length - 1);
    const values = processSettingRow(
      availableColumnsKey,
      getter,
      groupColumnCount,
      '',
    );
    table.push(values);
  }

  return table;
};
