import { detailedDiff } from 'deep-object-diff';
import { Item, Property } from 'unit-2d-database/interfaces';
import { isDiffEmpty } from 'utils/object-diff';

export const isChangePrevValue = (property: Property, prevItem: Item): boolean => {
  if (!property || !prevItem) {
    return false;
  }
  const prevProperty = prevItem.properties.find(p => p.id === property.id);
  if (!prevProperty) {
    return false;
  }
  const diff = detailedDiff(property, prevProperty);
  return !isDiffEmpty(diff);
};
