import { CodecUtils } from 'common/utils/codec-utils';

export enum BillingPricingModel {
    UnKnown = 'UnKnown',
    FlatFee = 'FlatFee',
    PerUnit = 'PerUnit',
    Tiered = 'Tiered',
    Volume = 'Volume',
    Stairstep = 'Stairstep',
}

export const BillingPricingModelC = CodecUtils.createEnumCodec<BillingPricingModel>(
  BillingPricingModel, 'BillingPricingModel',
);

