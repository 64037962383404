import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  svg {
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }
  margin-right: 10px;
  align-items: center;
  display: flex;
`;

const Input = styled.div`
  margin-left: auto;
  align-items: center;
`;

export const Styled = {
  Icon,
  Container,
  Input,
};
