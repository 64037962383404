import * as classNames from 'classnames';
import React from 'react';

import './sub-step.scss';

interface Props {
  width: number;
  className?: string;
}

export class SubStep extends React.Component<Props> {
  public render(): React.ReactNode {
    const style: React.CSSProperties = {};
    if (this.props.width) {
      style.width = this.props.width;
    }
    const className = classNames('sub-step', this.props.className);

    return (
      <div className={className} style={style}>
        <div className='sub-step__wrap'>
          <div className='sub-step__content'>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
