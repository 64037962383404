export enum DrawingsSVGIcons {
  SolidSticker = 'SolidSticker',
  SolidStar = 'SolidStar',
  SolidSmile = 'SolidSmile',
  SolidSmall = 'SolidSmall',
  SolidSigns = 'SolidSigns',
  SolidSad = 'SolidSad',
  SolidQuestin = 'SolidQuestin',
  SolidPlus = 'SolidPlus',
  SolidInfinity = 'SolidInfinity',
  SolidHeart = 'SolidHeart',
  SolidError = 'SolidError',
  SolidDone = 'SolidDone',
  SolidCloud = 'SolidCloud',
  SolidClose = 'SolidClose',
  SolidChat = 'SolidChat',
  SolidArrow = 'SolidArrow',
  ThumbsUp = 'ThumbsUp',
  Target = 'Target',
  Share = 'Share',
  Search = 'Search',
  Question = 'Question',
  Pencil = 'Pencil',
  Paint = 'Paint',
  Message = 'Message',
  Lamp = 'Lamp',
  Hi = 'Hi',
  Error = 'Error',
  Cloud = 'Cloud',
  Close = 'Close',
  Arrow = 'Arrow',
  Approved = 'Approved',
  Fire = 'Fire',
}

export function getSVGImageUrl(icon: DrawingsSVGIcons): string {
  return `/static/svg-lib/${icon}.svg`;
}

export const DrawingsSolidColorSVGUrls = [
  DrawingsSVGIcons.SolidSticker,
  DrawingsSVGIcons.SolidStar,
  DrawingsSVGIcons.SolidSmile,
  DrawingsSVGIcons.SolidSmall,
  DrawingsSVGIcons.SolidSigns,
  DrawingsSVGIcons.SolidSad,
  DrawingsSVGIcons.SolidQuestin,
  DrawingsSVGIcons.SolidPlus,
  DrawingsSVGIcons.SolidInfinity,
  DrawingsSVGIcons.SolidHeart,
  DrawingsSVGIcons.SolidError,
  DrawingsSVGIcons.SolidDone,
  DrawingsSVGIcons.SolidCloud,
  DrawingsSVGIcons.SolidClose,
  DrawingsSVGIcons.SolidChat,
  DrawingsSVGIcons.SolidArrow,
];

export const DrawingsColoredSVGUrls = [
  DrawingsSVGIcons.ThumbsUp,
  DrawingsSVGIcons.Target,
  DrawingsSVGIcons.Share,
  DrawingsSVGIcons.Search,
  DrawingsSVGIcons.Question,
  DrawingsSVGIcons.Pencil,
  DrawingsSVGIcons.Paint,
  DrawingsSVGIcons.Message,
  DrawingsSVGIcons.Lamp,
  DrawingsSVGIcons.Hi,
  DrawingsSVGIcons.Fire,
  DrawingsSVGIcons.Error,
  DrawingsSVGIcons.Cloud,
  DrawingsSVGIcons.Close,
  DrawingsSVGIcons.Arrow,
  DrawingsSVGIcons.Approved,
];
