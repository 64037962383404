import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './measurements-extractor-footer.scss';

import { KreoButton } from 'common/UIKit';
import { CEMeasurementsActions } from 'unit-cost-estimate/actions/creators';
import { withMeasurementEngineLayoutContext } from 'unit-projects/components/with-measurement-engine-layout-context';
import {
  WithMeasurementsEngineContextAwareProps,
} from 'unit-projects/interfaces/measurements/with-measurements-engine-context-aware-props';


interface DispatchProps {
  onCancelClick: () => void;
}

interface Props extends DispatchProps, WithMeasurementsEngineContextAwareProps {
}

const MeasurementsExtractorFooterComponent: React.FC<Props> = ({ onCancelClick }) => {
  return (
    <div className='measurements-extractor-footer'>
      <KreoButton mode='ghost' onClick={onCancelClick} size='medium'>
        Cancel
      </KreoButton>
    </div>
  );
};

function mapDispatchToProps(
  dispatch: Dispatch<AnyAction>,
  engineProps: WithMeasurementsEngineContextAwareProps,
): DispatchProps {
  return {
    onCancelClick: () => {
      dispatch(CEMeasurementsActions.cancelEdit());
      engineProps.onShowProperties([]);
      engineProps.showAll();
    },
  };
}

const connector = connect(null, mapDispatchToProps);
export const MeasurementsExtractorFooter =
  withMeasurementEngineLayoutContext(connector(MeasurementsExtractorFooterComponent));
