import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import {
  CreateQtoTreeRowsForm,
  ModelType,
  QtoProjectReport,
  QtoReport,
  QtoReportForm,
  QtoReportInfo,
  QtoReportInfoForm,
  QtoReportSetting,
  QtoTreeColumnsForm,
  QtoTreeRowForm,
  ReorderQtoTreeRowsForm,
} from '../../interfaces/quantity-take-off';
import {
  BaseReportPayload,
  CopyReportPayload,
  CreateReportPayload,
  FormWithProjectAndReportIdsPayload,
  GenerateDefaultReportPayload,
  ProjectAndReportIdsPayload,
  UpdateReportQueueMessageApiCallBackType,
  UpdateReportQueueMessagePayload,
} from '../payloads/quantity-take-off-report';
import { QuantityTakeOffReportActionTypes } from '../types/quantity-take-off-report';


function generateDefaultReport(
  projectId: number, modelType: ModelType, type: string,
): ActionWith<GenerateDefaultReportPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.GENERATE_DEFAULT_REPORT,
    payload: { projectId, modelType, type },
  };
}

function loadAvailableReportGenerators(projectId: number, modelType: ModelType): ActionWith<BaseReportPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.GET_AVAILABLE_REPORT_GENERATORS,
    payload: { projectId, modelType },
  };
}

function loadReportsInfo(projectId: number, modelType: ModelType): ActionWith<BaseReportPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.GET_REPORTS_INFO,
    payload: { projectId, modelType },
  };
}

function loadInitialState(projectId: number, modelType: ModelType): ActionWith<BaseReportPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.GET_INITIAL_STATE,
    payload: { projectId, modelType },
  };
}

function loadAvailableReportForCopy(modelType: ModelType): ActionWith<ModelType> {
  return {
    type: QuantityTakeOffReportActionTypes.GET_AVAILABLE_REPORT_FOR_COPY,
    payload: modelType,
  };
}

function setReportsInfo(infos: QtoReportInfo[]): ActionWith<QtoReportInfo[]> {
  return {
    type: QuantityTakeOffReportActionTypes.SET_REPORTS_INFO,
    payload: infos,
  };
}

function setSelectedReportId(reportId: number): ActionWith<number> {
  return {
    type: QuantityTakeOffReportActionTypes.SET_SELECTED_REPORT_ID,
    payload: reportId,
  };
}

function setReportsForCopy(reportForCopy: QtoProjectReport[]): ActionWith<QtoProjectReport[]> {
  return {
    type: QuantityTakeOffReportActionTypes.SET_REPORTS_FOR_COPY,
    payload: reportForCopy,
  };
}


function loadReport(projectId: number, modelType: ModelType, reportId: number): ActionWith<ProjectAndReportIdsPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.GET_REPORT,
    payload: { projectId, modelType, reportId },
  };
}

function setReport(report: QtoReport): ActionWith<QtoReport> {
  return {
    type: QuantityTakeOffReportActionTypes.SET_REPORT,
    payload: report,
  };
}

function setAvailableReportGenerators(reportTypes: QtoReportSetting[]): ActionWith<QtoReportSetting[]> {
  return {
    type: QuantityTakeOffReportActionTypes.SET_AVAILABLE_REPORT_GENERATORS,
    payload: reportTypes,
  };
}

function createReport(projectId: number, modelType: ModelType, model: QtoReportForm): ActionWith<CreateReportPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.CREATE_REPORT,
    payload: { projectId, modelType, model },
  };
}

function publishReport(
  projectId: number, modelType: ModelType, reportId: number,
): ActionWith<ProjectAndReportIdsPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.PUBLISH_REPORT,
    payload: { projectId, modelType, reportId },
  };
}

function deleteReport(
  projectId: number, modelType: ModelType, reportId: number,
): ActionWith<ProjectAndReportIdsPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.DELETE_REPORT,
    payload: { projectId, modelType, reportId },
  };
}

function updateReportInfo(
  projectId: number, modelType: ModelType, reportId: number, form: QtoReportInfoForm,
): ActionWith<FormWithProjectAndReportIdsPayload<QtoReportInfoForm>> {
  return {
    type: QuantityTakeOffReportActionTypes.UPDATE_REPORT_INFO,
    payload: { projectId, modelType, reportId, form },
  };
}

function exportReportToExcel(
  projectId: number, modelType: ModelType, reportId: number,
): ActionWith<ProjectAndReportIdsPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.EXPORT_REPORT_TO_EXCEL,
    payload: { projectId, modelType, reportId },
  };
}

function updateBasicColumns(
  projectId: number, modelType: ModelType, reportId: number, form: QtoTreeColumnsForm,
): ActionWith<FormWithProjectAndReportIdsPayload<QtoTreeColumnsForm>> {
  return {
    type: QuantityTakeOffReportActionTypes.UPDATE_REPORT_BASIC_COLUMNS,
    payload: { projectId, modelType, reportId, form },
  };
}

function updatePivotColumns(
  projectId: number, modelType: ModelType, reportId: number, form: QtoTreeColumnsForm,
): ActionWith<FormWithProjectAndReportIdsPayload<QtoTreeColumnsForm>> {
  return {
    type: QuantityTakeOffReportActionTypes.UPDATE_REPORT_PIVOT_COLUMNS,
    payload: { projectId, modelType, reportId, form },
  };
}

function updateRows(
  projectId: number, modelType: ModelType, reportId: number, form: QtoTreeRowForm[],
): ActionWith<FormWithProjectAndReportIdsPayload<QtoTreeRowForm[]>> {
  return {
    type: QuantityTakeOffReportActionTypes.UPDATE_REPORT_ROWS,
    payload: { projectId, modelType, reportId, form },
  };
}

function addRows(
  projectId: number, modelType: ModelType, reportId: number, form: CreateQtoTreeRowsForm,
): ActionWith<FormWithProjectAndReportIdsPayload<CreateQtoTreeRowsForm>> {
  return {
    type: QuantityTakeOffReportActionTypes.ADD_REPORT_ROWS,
    payload: { projectId, modelType, reportId, form },
  };
}

function removeRows(
  projectId: number, modelType: ModelType, reportId: number, form: string[],
): ActionWith<FormWithProjectAndReportIdsPayload<string[]>> {
  return {
    type: QuantityTakeOffReportActionTypes.DELETE_REPORT_ROWS,
    payload: { projectId, modelType, reportId, form },
  };
}

function reorderRows(
  projectId: number, modelType: ModelType, reportId: number, form: ReorderQtoTreeRowsForm,
): ActionWith<FormWithProjectAndReportIdsPayload<ReorderQtoTreeRowsForm>> {
  return {
    type: QuantityTakeOffReportActionTypes.REORDER_REPORT_ROWS,
    payload: { projectId, modelType, reportId, form },
  };
}

function putMessageToUpdateReportQueue<T>(
  apiCall: UpdateReportQueueMessageApiCallBackType<T>,
  form: FormWithProjectAndReportIdsPayload<T>,
): ActionWith<UpdateReportQueueMessagePayload<T>> {
  return {
    type: QuantityTakeOffReportActionTypes.PUT_MESSAGE_TO_UPDATE_REPORT_QUEUE,
    payload: { apiCall, form },
  };
}

function updateReportQueueMessageDone(): Action {
  return {
    type: QuantityTakeOffReportActionTypes.UPDATE_REPORT_QUEUE_MESSAGE_DONE,
  };
}

function updateReportQueueMessageFailed(
  projectId: number, modelType: ModelType, reportId: number,
): ActionWith<ProjectAndReportIdsPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.UPDATE_REPORT_QUEUE_MESSAGE_FAILED,
    payload: { projectId, modelType, reportId },
  };
}

function copyReport(
  projectId: number, modelType: ModelType, reportId: number, currentProjectId: number,
): ActionWith<CopyReportPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.COPY_REPORT,
    payload: { projectId, modelType, reportId, currentProjectId },
  };
}

function createTemplateFromReport(
  projectId: number, modelType: ModelType, reportId: number,
): ActionWith<ProjectAndReportIdsPayload> {
  return {
    type: QuantityTakeOffReportActionTypes.CREATE_TEMPLATE_FROM_REPORT,
    payload: { projectId, modelType, reportId },
  };
}

function updateReportAfterCreationViaTemplate(model: QtoReportInfo): ActionWith<QtoReportInfo> {
  return {
    type: QuantityTakeOffReportActionTypes.UPDATE_REPORT_AFTER_CREATION_VIA_TEMPLATE,
    payload: model,
  };
}

function createReportFinish(): Action {
  return {
    type: QuantityTakeOffReportActionTypes.CREATE_REPORT_FINISH,
  };
}


export const QuantityTakeOffReportActions = {
  generateDefaultReport,
  loadAvailableReportGenerators,
  loadReportsInfo,
  setReportsInfo,
  setSelectedReportId,
  setAvailableReportGenerators,
  loadReport,
  setReport,
  createReport,
  publishReport,
  deleteReport,
  updateReportInfo,
  exportReportToExcel,
  updateBasicColumns,
  updatePivotColumns,
  updateRows,
  addRows,
  removeRows,
  reorderRows,
  putMessageToUpdateReportQueue,
  updateReportQueueMessageDone,
  updateReportQueueMessageFailed,
  loadAvailableReportForCopy,
  setReportsForCopy,
  copyReport,
  loadInitialState,
  createTemplateFromReport,
  updateReportAfterCreationViaTemplate,
  createReportFinish,
};
