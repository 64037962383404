import { ICellRendererFunc, RowNode } from 'ag-grid-community';

import { EMPTY_VALUE } from 'common/components/tree-filter-panel/constants';
import { mathUtils } from 'common/utils/math-utils';
import { UnitUtil, UnitConversionResult } from 'common/utils/unit-util';
import { QtoLeftPanelConstants } from '../../quantity-take-off-left-panel/constants';
import { calculatingStatus } from '../../quantity-take-off-left-panel/handle-select-user-extractor';
import { AGG_HASH } from './aggregation-cell/aggregation-cell';


export function isParentAggHash(parent: RowNode): boolean {
  if (parent.level === -1) {
    return false;
  }

  if (parent.field !== AGG_HASH) {
    return isParentAggHash(parent.parent);
  }

  return true;
}

export const roundExtractorValue = (value: string | number, unit: string): string => {
  if (value === calculatingStatus) {
    return value;
  }
  const result = value ? `${mathUtils.round(Number(value), 2)} ${unit}` : '';
  return result;
};

const convert = (
  originalValue: number, originalUnit: string, secondaryUnit: string, isConvert?: boolean,
): UnitConversionResult => {
  const { value, unit } = isConvert
    ? UnitUtil.convertUnit(originalValue, originalUnit, secondaryUnit)
    : { value: originalValue, unit: originalUnit };
  return { value, unit: UnitUtil.getSupUnit(unit) };
};

export const extractorCell = (
  originalUnit: string,
  secondaryUnit?: string,
): ICellRendererFunc => (params) => {
  const isImperialUnit = params.context.isImperialUnit;
  if (params.value && params.value !== EMPTY_VALUE) {
    const reason = params.data[`${QtoLeftPanelConstants.REASON_PREFIX}${params.colDef.field}`];
    const container = document.createElement('div');
    const { value, unit } = convert(params.value, originalUnit, secondaryUnit, isImperialUnit);
    container.className = 'reason';
    const reasonValue = document.createElement('span');
    reasonValue.innerText = roundExtractorValue(value, unit);
    container.appendChild(reasonValue);
    const reasonInfoIcon = document.createElement('div');
    reasonInfoIcon.className = 'reason__info-icon';
    reasonInfoIcon.innerText = 'i';
    container.appendChild(reasonInfoIcon);
    const reasonTooltip = document.createElement('div');
    reasonTooltip.innerText = reason;
    reasonTooltip.className = 'reason__tooltip';
    const leftPanel = document.getElementsByClassName('quantity-take-off-page__tree-panel')[0];
    const oldReason = leftPanel.getElementsByClassName('reason__tooltip')[0];
    if (oldReason) {
      leftPanel.removeChild(oldReason);
    }
    reasonInfoIcon.onmouseenter = () => {
      const position = container.getBoundingClientRect();
      reasonTooltip.style.top = `${position.top - 5}px`;
      reasonTooltip.style.left = `${position.left}px`;
      leftPanel.appendChild(reasonTooltip);
    };
    reasonInfoIcon.onmouseleave = () => leftPanel.removeChild(reasonTooltip);
    return container;
  }

  if (params.node.field === AGG_HASH) {
    const agg = params.node.allLeafChildren.reduce(
      (prevValue, node) => {
        return prevValue + Number(node.data[params.column.getColId()]);
      },
      0);
    const { value, unit } = convert(agg, originalUnit, secondaryUnit, isImperialUnit);
    return roundExtractorValue(value, unit);
  }

  if (isParentAggHash(params.node.parent) && params.node.allLeafChildren) {
    const agg = params.node.allLeafChildren.reduce(
      (prevValue, node) => {
        return prevValue + Number(node.data[params.column.getColId()]);
      },
      0);

    const { value, unit } = convert(agg, originalUnit, secondaryUnit, isImperialUnit);
    return roundExtractorValue(value, unit);
  }

  return '';
};
