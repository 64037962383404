import styled from 'styled-components';

const RoundSwitcher = styled.div`
  max-width: 100%;

  > div {
    background-color: ${props => props.theme.color.background};
  }

  p {
    font-size: 14px;
  }
`;

export const Styled = {
  RoundSwitcher,
};
