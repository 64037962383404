import * as classNames from 'classnames';
import * as React from 'react';

import './resource-limitations-tile-list.scss';

import {
  ResourceLimitationsMenuType,
  WorkPackagesData,
} from '../../../scenarios';
import { ResourceLimitationsTile } from '../resource-limitations-tile';

interface Props {
  item: WorkPackagesData.ResourceLimitation;
  activeMenu: ResourceLimitationsMenuType;
}

export class ResourceLimitationsTileList extends React.Component<Props> {
  public render(): JSX.Element {
    const { item: { activated, limitations, name }, activeMenu } = this.props;
    const className = classNames(
      'resource-limitations-tile-list',
      `resource-limitations-tile-list--${name.toLowerCase()}`,
      { active: activated },
    );

    return (
      <div className={className}>
        {limitations.map((item) => (
          <ResourceLimitationsTile key={item.id} item={item} activeMenu={activeMenu} />
        ))}
      </div>
    );
  }
}
