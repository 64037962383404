import { MultiViewHelper } from './multi-view-helper';

const apiActionEventType = 'API_ACTION';

const getApiActionEventType = (apiKey: string): string => `${apiActionEventType}_${apiKey}`;

interface MultiViewWithApi {
  sendAPIAction: (methodKey: string, ...args: any[]) => void;
}

interface ApiActionPayload {
  apiMethodKey: string;
  args: any[];
}


export function extendWithApi<T>(
  this: MultiViewHelper,
  api: T,
  apiKey: string,
  methodWhiteList?: string[],
): MultiViewWithApi {
  const sendAPIAction = (methodKey: string, args: any[]): void => {
    if (this.hasPartner()) {
      const payload: ApiActionPayload = {
        apiMethodKey: methodKey,
        args,
      };
      this.sendEvent<ApiActionPayload>(getApiActionEventType(apiKey), payload);
    }
  };

  const apiMethods = Object.getOwnPropertyNames(api).filter(method => {
    if (!methodWhiteList) {
      return true;
    }
    return methodWhiteList.includes(method);
  });

  const originalMethods = {};

  apiMethods.forEach(methodName => {
    const originalMethod = api[methodName];
    originalMethods[methodName] = originalMethod;
    api[methodName] = (...args) => {
      sendAPIAction(methodName, args);
      // originalMethod(...args);
    };
  });

  const apiActionHandler = (payload: ApiActionPayload): void => {
    originalMethods[payload.apiMethodKey](...payload.args);
  };

  this.addEventHandler(getApiActionEventType(apiKey), apiActionHandler);

  return {
    sendAPIAction,
  };
}
