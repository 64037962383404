import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyF: React.FC = () => {
  return (
    <svg
      width='20px'
      height='22px'
      viewBox='0 0 20 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='hotkey_f' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='F'>
          <g id='background'>
            <g id='Group' transform='translate(0.000000, 2.000000)'>
              <g id='path-1-link' fill={KreoColors.f1}>
                <rect id='path-1' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
            <g id='Group'>
              <g id='path-2-link' fill={KreoColors.f1}>
                <rect id='path-2' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
          </g>
          <rect id='padding-left' x='0' y='6' width='6' height='10' />
          <rect id='padding-right' x='14' y='6' width='6' height='10' />
          <path
            // eslint-disable-next-line max-len
            d='M8.99350036,7.22399889 L8.99350036,9.62749928 L11.5785008,9.62749928 C11.8315008,9.62749928 11.9415008,9.63849929 12.0625009,9.7044993 C12.2055009,9.78699931 12.3045009,9.94649934 12.3045009,10.1554994 C12.3045009,10.3644994 12.2055009,10.5239994 12.0625009,10.6064994 C11.9415008,10.6724995 11.8315008,10.6834995 11.5785008,10.6834995 L8.99350036,10.6834995 L8.99350036,13.2354999 C8.99350036,13.5269999 8.98800036,13.6204999 8.90550035,13.7745 C8.81750033,13.934 8.6360003,14.044 8.39950026,14.044 C8.16300023,14.044 7.9815002,13.934 7.89350018,13.7745 C7.81100017,13.6204999 7.80550017,13.5269999 7.80550017,13.2354999 L7.80550017,6.89399884 C7.80550017,6.71799881 7.80550017,6.50899877 7.97600019,6.33849874 C8.14650022,6.16799872 8.35550026,6.16799872 8.53150029,6.16799872 L12.1395009,6.16799872 C12.3925009,6.16799872 12.5025009,6.17899872 12.623501,6.24499873 C12.766501,6.32749874 12.865501,6.48699877 12.865501,6.6959988 C12.865501,6.90499884 12.766501,7.06449886 12.623501,7.14699888 C12.5025009,7.21299889 12.3925009,7.22399889 12.1395009,7.22399889 L8.99350036,7.22399889 Z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
};
