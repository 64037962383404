import * as React from 'react';

export const KreoIconReset = (): JSX.Element => (
  <svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1'>
    <g id='Icons/16x16/Reset' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        // eslint-disable-next-line max-len
        d='M8,6.5 C8,6.87763726 7.60363907,7.10858577 7.28205161,6.95039136 L7.2093809,6.90686674 L3.7093809,4.40686674 C3.45812382,4.22739739 3.43299811,3.87337117 3.63400378,3.65899044 L3.7093809,3.59313326 L7.2093809,1.09313326 C7.51667698,0.873636066 7.93498763,1.06193212 7.99317845,1.41556724 L8,1.5 L8.00010047,3.00010047 C11.313809,3.00010047 14.0001005,5.68639197 14.0001005,9.00010047 C14.0001005,12.313809 11.313809,15.0001005 8.00010047,15.0001005 C5.14422202,15.0001005 2.70081483,12.9901065 2.12598508,10.2276396 C2.01347261,9.68693688 2.36058936,9.15740137 2.90129204,9.0448889 C3.44199472,8.93237643 3.97153023,9.27949318 4.0840427,9.82019586 C4.46677932,11.6595183 6.09643002,13.0001005 8.00010047,13.0001005 C10.2092395,13.0001005 12.0001005,11.2092395 12.0001005,9.00010047 C12.0001005,6.79096147 10.2092395,5.00010047 8.00010047,5.00010047 L8,6.5 Z'
        id='shape'
        fill='currentColor'
        fillRule='nonzero'
      />
    </g>
  </svg>
);
