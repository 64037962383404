import { Action, AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';
import { persistedStoragePrefix } from '../../../units/persisted-storage/actions/types';
import { MultiViewHelper } from './multi-view-helper';
import { multiViewPrefix } from './multi-view-reducers';

export interface MultiViewWithReduxActions {
  sendReduxAction: (data: AnyAction) => void;
  handleReduxAction: (action: AnyAction, next: Dispatch<Action<State>>) => any;
}

const reduxActionType = 'REDUX_ACTION';
const syncActionTypePrefix = 'SYNC_MULTI_VIEW_STATE';
const syncActionTypePrefixRegex =
  new RegExp(`${syncActionTypePrefix}_[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}_`);

const blackListActionType = [
  syncActionTypePrefix,
  persistedStoragePrefix,
  multiViewPrefix,
];

const isTypeInList = (type: string, list: string[]): boolean => {
  return list.some(blackActionType => type.includes(blackActionType));
};

const getSyncActionType = (id: string, type: string): string => `${syncActionTypePrefix}_${id}_${type}`;

export function extendWithReduxAction(
  this: MultiViewHelper,
  dispatch: Dispatch<Action<State>>,
  blackListToExtends: string[],
  whiteList: string[],
): MultiViewWithReduxActions {
  const blackList = blackListActionType.concat(blackListToExtends);
  const sendReduxAction = (action: AnyAction): void => {
    if (this.hasPartner()) {
      const type: string = action.type;
      const isWhiteAction = isTypeInList(type, whiteList);
      const isBlackAction = isTypeInList(type, blackList);
      if (isWhiteAction || !isBlackAction) {
        const syncStateType = getSyncActionType(this.data.id, type);
        this.sendEvent(reduxActionType, { ...action, type: syncStateType });
      }
    }
  };

  const handleReduxActionEvent = (action: AnyAction): void => {
    const syncActionPrefixWithId = getSyncActionType(this.data.id, '');
    if (action.type.includes(syncActionPrefixWithId)) {
      return;
    }

    dispatch(action);
  };

  const handleReduxAction = (action: AnyAction, next: Dispatch<Action<State>>): AnyAction => {
    if (!action.type.includes(syncActionTypePrefix)) {
      sendReduxAction(action);
    }

    const correctType = action.type.replace(syncActionTypePrefixRegex, '');
    return next({ ...action, type: correctType });
  };

  this.addEventHandler<AnyAction>(reduxActionType, handleReduxActionEvent);
  return {
    sendReduxAction,
    handleReduxAction,
  };
}
