import React from 'react';

import { BidPricingUserCost } from '../../../interfaces/bid-pricing/bid-pricing-user-cost';
import { BidPricingWorkPackage } from '../../../interfaces/bid-pricing/bid-pricing-work-package';
import { SubcontractorTableItem } from './subcontractor-table-item';
import * as ItemUtils from './utils';

interface Props {
  workpackage: BidPricingWorkPackage;
  onItemClick(item: BidPricingUserCost): void;
  onWin(userId: string, workPackageId: number, constructionCostId: number): void;
  onCancelRequest(email: string, workpackageId: number): void;
  onDeleteSubcontractorBid(constructionCostId: number, workPackageId: number): void;
}

export class SubcontractorTable extends React.Component<Props> {
  public render(): React.ReactNode {
    const wp = this.props.workpackage;
    const winner = ItemUtils.getWinner(wp.usersCost);

    return (
      <React.Fragment>
        {wp.usersCost.map((item, index): React.ReactNode => (
          <SubcontractorTableItem
            workpackageId={wp.id}
            item={item}
            labour={item.laborCost}
            total={item.totalCost}
            plant={item.plantCost}
            material={item.materialCost}
            indirect={item.indirectCost}
            duration={item.duration}
            status={item.status}
            sourceLabour={winner ? winner.laborCost : wp.laborCost}
            sourcePlant={winner ? winner.plantCost : wp.plantCost}
            sourceMaterial={winner ? winner.materialCost : wp.materialCost}
            sourceTotal={winner ? winner.totalCost : wp.totalCost}
            sourceDuration={winner ? winner.duration : wp.duration}
            sourceIndirect={winner ? winner.indirectCost : wp.indirectCost}
            onItemClick={this.props.onItemClick}
            onWin={this.props.onWin}
            onCancelRequest={this.props.onCancelRequest}
            onDeleteSubcontractorBid={this.props.onDeleteSubcontractorBid}
            key={index}
          />
        ))}
      </React.Fragment>
    );
  }
}
