import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';

// eslint-disable-next-line import/no-unresolved
import { UserInfoResponse } from 'src/units/account/interfaces/user-info-response';

export class DatadogLogger {
  private static initialized: boolean = false;
  private static env: string;

  public static init(config: LogsInitConfiguration): void {
    this.env = config.env;
    datadogLogs.onReady(() => {
      datadogLogs.init(config);
      this.initialized = true;
    });
  }

  public static log(message: string, context: object = {}): void {
    if (this.initialized) {
      datadogLogs.logger.info(
        message,
        { context, host: window.location.host, source: this.env },
      );
    }
  }

  public static warn(message: string, context: object = {}): void {
    if (this.initialized) {
      datadogLogs.logger.warn(message, { context, host: window.location.host, source: this.env });
    }
  }

  public static error(message: string, context: object = {}): void {
    if (this.initialized) {
      datadogLogs.logger.error(message, { context, host: window.location.host, source: this.env });
    }
  }

  public static setUser(user: UserInfoResponse | null): void {
    datadogLogs.addLoggerGlobalContext('user_name', user ? user.email : null);
  }
}
