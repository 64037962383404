import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './activity-assignment-activity-tile-view.scss';

import { State } from 'common/interfaces/state';
import {
  ActivityAssignmentMaterialVariant,
} from '../../interfaces/activity-assignment/activity-assignment-material-variant';
import { ActivityAssignmentSubVariant } from '../../interfaces/activity-assignment/activity-assignment-sub-variant';
import {
  ActivityAssignmentActivityTileBody,
  ActivityAssignmentActivityTileBodyTitle,
  ActivityAssignmentActivityTileContainer,
} from '../activity-assignment-activity-tile-containers';

interface OwnProps {
  index: number;
}

interface StateProps {
  selectedVariant: ActivityAssignmentSubVariant;
  isUsed: boolean;
}


interface Props extends StateProps, OwnProps {
}


class ActivityAssignmnentActivityTileComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isUsed, selectedVariant } = this.props;
    const materials = selectedVariant.materials && selectedVariant.materials.filter(x => x.subVariants.length);
    return (
      <ActivityAssignmentActivityTileContainer enabled={isUsed}>
        <ActivityAssignmentActivityTileBody>
          <div className='activity-assignment-activity-tile-view__variant'>
            <ActivityAssignmentActivityTileBodyTitle>
                Activity Variant
            </ActivityAssignmentActivityTileBodyTitle>
            <div
              title={selectedVariant.name}
              className='activity-assignment-activity-tile-view__selected-title'
            >
              {selectedVariant.name}
            </div>
          </div>
          {
            materials && materials.length
              ? (
              <div className='activity-assignment-activity-tile-view__material'>
                <ActivityAssignmentActivityTileBodyTitle>
                  Materials
                </ActivityAssignmentActivityTileBodyTitle>
                {materials && materials.map(this.renderMaterial)}
              </div>
              ) : null
          }
        </ActivityAssignmentActivityTileBody>
      </ActivityAssignmentActivityTileContainer>
    );
  }


  @autobind
  private renderMaterial(materialSelect: ActivityAssignmentMaterialVariant): React.ReactNode {
    const selectedMaterial = materialSelect.subVariants.find(x => x.materialId === materialSelect.selectedMaterialId);
    return (
      <div
        title={selectedMaterial.name}
        className='activity-assignment-activity-tile-view__selected-title'
        key={materialSelect.rootMaterialId}
      >
        {selectedMaterial.name}
      </div>
    );
  }
}


function mapStateToProps(state: State, { index }: OwnProps): StateProps {
  const { subVariants, selectedDbModalityId, isUsed } = state.activityAssignment.selectedWorks[index];
  const selectedVariant = subVariants.find(x => x.dbModalityId === selectedDbModalityId);
  return { isUsed, selectedVariant };
}

const connector = connect(mapStateToProps);
export const ActivityAssignmentActivityTileView = connector(ActivityAssignmnentActivityTileComponent);
