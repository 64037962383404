import Ag from 'ag-grid-community';

import { TwoDViewTableConfig } from '2d/interfaces/measure-view-table-state';
import { ExcelFormulaHelper } from 'common/components/excel-table';
import { mathUtils } from 'common/utils/math-utils';
import { numberUtils } from 'common/utils/number-utils';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';

export const NUMBER_COLUMN_DEF_TYPE = 'NUMBER_COLUMN_DEF_TYPE';
export const STRING_COLUMN_DEF_TYPE = 'STRING_COLUMN_DEF_TYPE';

const NumberChar = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);

const isNumberValue = (value: string | number | boolean): boolean => {
  const type = typeof value;
  if (type === 'number') {
    return true;
  } else if (type === 'string' && !Number.isNaN(Number(value))) {
    return true;
  }

  return false;
};

const isNumberValueNeedFormat = (value?: number | string): boolean => {
  const hasValue = value !== undefined || value !== null;
  const isValueEmptyString = value === '';
  const isNumber = isNumberValue(value);

  return hasValue && !isValueEmptyString && isNumber;
};

const getUnitKey = (param: Ag.ValueFormatterParams): string => {
  const field = param.colDef.field;
  if (/^pivot_/.test(field)) {
    const originField = param.colDef.pivotValueColumn?.getColId().replace(/[\[\]]/g, '');
    return `unit_${originField}`;
  } else {
    return `unit_${field}`;
  }
};

const getFirstUnit = (nodes: Ag.RowNode[], unitKey: string): [string, boolean] => {
  for (const n of nodes) {
    if (n.data[unitKey] !== undefined) {
      return [n.data[unitKey], n.data.withUnit];
    }
  }
  return [undefined, undefined];
};

const getAllNodesRelatedFooter = (param: Ag.ValueFormatterParams): Ag.RowNode[] => {
  const nodes = [];
  param.api.forEachNodeAfterFilter((el) => {
    if (!el.group) {
      nodes.push(el);
    }
  });
  return nodes;
};

const getAllGroupChildren = (node: Ag.RowNode): Ag.RowNode[] => {
  if (!node.group) {
    return [node];
  }

  if (node.childrenAfterFilter.length) {
    let allChildrenGroups = [];
    for (const child of node.childrenAfterFilter) {
      allChildrenGroups = allChildrenGroups.concat(getAllGroupChildren(child));
    }
    return allChildrenGroups;
  }
  return [];
};

const isDataUndefined = (data: string | undefined): boolean => {
  return data === undefined || data.toString().startsWith('Undefined');
};

const getIsOneUnit = (unitKey: string, nodes: Ag.RowNode[], unit: string, field: string): boolean => {
  let isOneUnit = true;

  nodes.forEach((n) => {
    if (n.data && isOneUnit) {
      const data = n.data[field];
      if (!isDataUndefined(data) && n.data[unitKey] !== unit) {
        isOneUnit = false;
        return;
      }
    }
  });
  return isOneUnit;
};

const formatRowGroupFooter = (param: Ag.ValueFormatterParams): string => {
  const number = numberUtils.getNumeralFormatter(param.value).format(',000.[00]');
  const unitKey = getUnitKey(param);
  const nodes = getAllNodesRelatedFooter(param);
  const [unit, withUnit] = getFirstUnit(nodes, unitKey);
  const field = param.colDef.field;
  const isOneUnit = getIsOneUnit(unitKey, nodes, unit, field);

  return isOneUnit ? getValueWithUnit(number, unit, withUnit) : '';
};

const isRowGroupFooter = (param: Ag.ValueFormatterParams): boolean => {
  return param.node.id.includes('rowGroupFooter');
};

const isPivotColumn = (param: Ag.ValueFormatterParams): boolean => {
  return param.column.getColId().includes('pivot_');
};

const formatPivot = (param: Ag.ValueFormatterParams): string => {
  const number = numberUtils.getNumeralFormatter(param.value).format(',000.[00]');
  const unitKey = getUnitKey(param);
  const allChildrenGroups = getAllGroupChildren(param.node);
  const [unit, withUnit] = getFirstUnit(allChildrenGroups, unitKey);
  const field = param.colDef.field;
  const isOneUnit = getIsOneUnit(unitKey, allChildrenGroups, unit, field);

  return isOneUnit ? getValueWithUnit(number, unit, withUnit) : '';
};

export const numberValueFormatter = (param: Ag.ValueFormatterParams): string => {
  if (isNumberValueNeedFormat(param.value)) {
    const number = numberUtils.getNumeralFormatter(param.value).format(',000.[00]');
    const unitKey = getUnitKey(param);
    if (isRowGroupFooter(param)) {
      return formatRowGroupFooter(param);
    }
    if (param.columnApi.isPivotMode() || isPivotColumn(param)) {
      return formatPivot(param);
    }
    if (param.data) {
      const withUnit = param.data.withUnit;
      const unit = param.data[unitKey];
      return getValueWithUnit(number, unit, withUnit);
    } else {
      const allChildrenGroups = getAllGroupChildren(param.node);
      const [unit, withUnit] = getFirstUnit(allChildrenGroups, unitKey);
      const field = param.colDef.field;
      const isOneUnit = getIsOneUnit(unitKey, allChildrenGroups, unit, field);

      return isOneUnit ? getValueWithUnit(number, unit, withUnit) : '';
    }
  }
  return param.value as string;
};

export const NumberColumnDef: Ag.ColDef = {
  cellClass: 'ag-right-aligned-cell',
  enableValue: true,
  allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg', 'first', 'last'],
  filter: 'agMultiColumnFilter',
  filterParams: {
    filters: [
      {
        filter: 'agNumberColumnFilter',
        filterParams: {
          numberParser: (text: string | null) => {
            const number = text == null ? null : parseFloat(text.replace(',', '.'));
            return Number.isNaN(number) ? '' : number;
          },
        },
        defaultOption: 'equals',
      },
      {
        filter: 'agSetColumnFilter',
      },
    ],
  },
  valueFormatter: numberValueFormatter,
  type: NUMBER_COLUMN_DEF_TYPE,
  cellRenderer: 'defaultCellRenderer',
};

function getValueWithUnit(number: string, unit: string, withUnit: boolean): string {
  if (!unit || !withUnit) {
    return number;
  }

  return UnitUtil.isCurrencyUnit(unit as UnitTypes) ? `${unit} ${number}` : `${number} ${unit}`;
}

export const StringColumnDef: Ag.ColDef = {
  comparator: sortComparator,
  type: STRING_COLUMN_DEF_TYPE,
  cellRenderer: 'defaultCellRenderer',
};

export const columnTypes = {
  [STRING_COLUMN_DEF_TYPE]: StringColumnDef,
  [NUMBER_COLUMN_DEF_TYPE]: NumberColumnDef,
};

export const DEFAULT_COLUMN_DEF: Ag.ColDef = {
  width: 180,
  enableRowGroup: true,
  resizable: true,
  sortable: true,
  floatingFilter: true,
  filter: 'agMultiColumnFilter',
  cellRenderer: 'defaultCellRenderer',
  cellRendererParams: {
    footerValueGetter: (params) => {
      return params.value;
    },
  },
  filterParams: {
    applyMiniFilterWhileTyping: true,
    filters: [
      {
        filter: 'agTextColumnFilter',
        filterParams: {
          defaultOption: 'startsWith',
        },
      },
      {
        filter: 'agSetColumnFilter',
      },
    ],
  },
  enablePivot: true,
  suppressSizeToFit: true,
};

export const AGG_FUNCS = {
  sum: (params) => {
    let sum = 0;
    let isValid = true;

    for (const value of params.values) {
      if (value === ExcelFormulaHelper.INVALID_VALUE) {
        isValid = false;
        break;
      }
      if (typeof value === 'number') {
        sum += value;
      }
    }

    return isValid ? mathUtils.round(sum, 2) : ExcelFormulaHelper.INVALID_VALUE;
  },
  avg: (params) => {
    let sum = 0;
    let count = 0;
    let isValid = true;

    for (const value of params.values) {
      if (value === ExcelFormulaHelper.INVALID_VALUE) {
        isValid = false;
        break;
      }
      if (typeof value === 'number') {
        sum += value;
        count++;
      }
    }

    return isValid ? mathUtils.round(sum / count, 2) : ExcelFormulaHelper.INVALID_VALUE;
  },
  single: (params) => {
    const value = params.values[0];
    if (params.values.every(v => value === v)) {
      return value;
    } else {
      return ExcelFormulaHelper.INVALID_VALUE;
    }
  },
};

export function sortComparator(s1: string, s2: string): number {
  if (!s1) {
    return !s2 ? 0 : 1;
  }
  if (!s2) {
    return -1;
  }

  let i1 = 0;
  let i2 = 0;
  const strLen1 = s1.length;
  const strLen2 = s2.length;
  let lng1: number;
  let lng2: number;
  let c1: string;
  let c2: string;
  let neg1: boolean;
  let neg2: boolean;

  while (i1 < strLen1 && i2 < strLen2) {
    neg1 = (s1[i1] === '-' && (i1 === 0 || s1[i1 - 1] === ' '));
    if (neg1) {
      i1++;
    }
    neg2 = s2[i2] === '-' && (i2 === 0 || s2[i2 - 1] === ' ');
    if (neg2) {
      i2++;
    }
    const si1 = i1;
    const si2 = i2;
    while (NumberChar.has(s1[i1])) {
      i1++;
      if (i1 === strLen1) {
        break;
      }
    }
    while (NumberChar.has(s2[i2])) {
      i2++;
      if (i2 === strLen2) {
        break;
      }
    }
    lng1 = i1 - si1;
    lng2 = i2 - si2;
    if (lng1 === 0) {
      if (lng2 === 0) {
        // оба не число
        // учтем что если знак отрицательный то мы уже смещались
        if (neg1) {
          if (neg2) {
            // две черточки но без чисел
            continue;
          }
          c1 = s1[i1 - 1];
        } else {
          c1 = s1[i1++];
        }
        if (neg2) {
          c2 = s2[i2 - 1];
        } else {
          c2 = s2[i2++];
        }
        // проверка букв игнорируя регистр
        const cc = c1.toUpperCase().charCodeAt(0) - c2.toUpperCase().charCodeAt(0);
        if (cc !== 0) {// буквы разные
          return cc;
        }
      } else {
        // первое не число, второе число
        return 1;
      }
    } else {
      if (lng2 === 0) {
        // первое число, второе не число
        return -1;
      }
      if (neg1 === neg2) {
        // оба числа имеют одинаковый знак
        if (lng1 < lng2) {
          // первое короче второго
          // реверсим если негативные числа
          return neg1 ? 1 : -1;
        }
        if (lng1 > lng2) {
          // второе короче первого
          // реверсим если негативные числа
          return neg2 ? -1 : 1;
        }
        // текущие числа имеют одинаковое количество цифр
        // проверяем цифры попарно
        for (let i = 0; i < lng1; i++) {
          const cc = Number(s1[si1 + i])- Number(s2[si2 + i]);
          if (cc !== 0) {
            // у нас разные цифры
            // реверсим если негативные числа
            return neg1 ? -cc : cc;
          }
        }
      } else if (neg1) {
        // первой отрицательное, второе положительное
        return -1;
      } else {
        // первой положительное, второе отрицательное
        return 1;
      }
    }
  }
  // если обе строки закончились одновременно, то они равны.
  // иначе та что короче будет раньше
  if (i1 === strLen1) {
    return i2 === strLen2 ? 0 : -1;
  }

  return 1;
}

export const FooterValue = 'Total';

export function wrapZeroValue(value: number | string): number | string {
  return value === 0
    ? '0'
    : value;
}

export function sortColumns(iterateConfig: TwoDViewTableConfig, config: TwoDViewTableConfig): TwoDViewTableConfig {
  const sortedColumns = iterateConfig.columns.map((viewColumn) => {
    return config.columns.find((payloadColumn) => payloadColumn.colId === viewColumn.colId);
  }).filter((column) => column !== undefined);

  const sortedPayload: TwoDViewTableConfig = {
    ...config,
    columns: sortedColumns,
  };

  return sortedPayload;
}
