import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { CostEstimateTreeMap } from '../../interfaces/dashboard/cost-estimate-tree-map';
import { CostHistogram } from '../../interfaces/dashboard/cost-histogram';
import { DashboardActionTypes } from '../types/dashboard';

function getAllData(calculationId: number): ActionWith<number> {
  return {
    type: DashboardActionTypes.GET_ALL_DATA_REQUEST,
    payload: calculationId,
  };
}

function getCostHistogramDataSucceed(data: CostHistogram[]): ActionWith<CostHistogram[]> {
  return {
    type: DashboardActionTypes.GET_COST_HISTOGRAM_DATA_SUCCEED,
    payload: data,
  };
}

function getCostEstimateTreeMapDataSucceed(
  data: CostEstimateTreeMap[],
): ActionWith<CostEstimateTreeMap[]> {
  return {
    type: DashboardActionTypes.GET_COST_ESTIMATE_TREE_MAP_DATA_SUCCEED,
    payload: data,
  };
}


function dropAllData(): Action {
  return {
    type: DashboardActionTypes.DROP_ALL_DATA,
  };
}

export const DashboardActions = {
  getAllData,
  getCostHistogramDataSucceed,
  getCostEstimateTreeMapDataSucceed,
  dropAllData,
};
