import * as t from 'io-ts';
import { CEActivityAssignmentDataTreeNodeC } from './ce-activity-assignment-data-tree-node';


export const CEActivityAssignmentTreeNodeActivitySortC = t.type(
  {
    id: t.number,
    name: t.string,

    uniProduct: t.string,
    uniSystem: t.string,
    predictedNrm1: t.string,
    predictedNrm2: t.string,

    data: CEActivityAssignmentDataTreeNodeC,
  },
  'ActivityAssignmentTreeNodeActivitySort',
);

export type CEActivityAssignmentTreeNodeActivitySort = t.TypeOf<typeof CEActivityAssignmentTreeNodeActivitySortC>;
