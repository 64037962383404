import { useCallback, useState } from 'react';
import { DropDownProps } from 'unit-2d-database/components/side-panel/components/item-panel/interfaces';

export interface UnitState {
  onBlur: (unit: string) => void;
  value: string;
  onClick: (index: number) => void;
  activeElementIndex: number;
  isUnitChanged: boolean;
}

export const useUnitState = (dropDownProps?: DropDownProps): UnitState  => {
  const [isUnitChanged, setUnitChanged] = useState<boolean>(false);
  const [unit, changeUnit] = useState<string>(dropDownProps?.value);
  const [activeElementIndex, setActiveElementIndex] = useState<number>(dropDownProps?.activeElementIndex);
  const handleClick = useCallback((index: number) => {
    setActiveElementIndex(index);
    const element = dropDownProps.elements[index];
    if (element) {
      changeUnit(element);
    }
    setUnitChanged(true);
  }, []);
  const handleBlur = useCallback((newUnit: string) => {
    setUnitChanged(true);
    changeUnit(newUnit);
  }, []);
  if (!dropDownProps) {
    return {
      value: null,
      onClick: null,
      onBlur: null,
      activeElementIndex: null,
      isUnitChanged: false,
    };
  }
  return {
    value: unit,
    onClick: handleClick,
    onBlur: handleBlur,
    activeElementIndex,
    isUnitChanged,
  };
};
