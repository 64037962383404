import autobind from 'autobind-decorator';
import * as monolite from 'monolite';
import * as React from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';

import './gantt-chart.scss';

import { State } from 'common/interfaces/state';
import { LineData, TimeframeAnimationTarget } from '../../../../interfaces/gantt-chart';
import { ChartDataProvider } from '../../../../utils/gantt-chart';
import { AnimationSpeed } from '../../enums/animation-speed';
import { ChartBody } from './components/chart-body';
import { ChartHeader } from './components/chart-header';
import { ProjectTimeline } from './components/project-timeline';
import { TimePointer } from './components/time-pointer';

interface OwnProps {
  height: number;
  isPlaying: boolean;
  /**
   * @description Current moment in days since project start.
   */
  currentMoment: number;
  nextCurrentMoment: number | null;
  speed: number;
  dataProvider: ChartDataProvider;
  timeframeStartDay: number;
  timeframeDuration: number;
  skipOffTime: boolean;
  timelineStartDay: number;
  timelineDuration: number;
  isolatedLine: LineData | null;
  play: () => void;
  pause: () => void;
  moveForward: () => void;
  moveBackward: () => void;
  changeSpeed: (speed: number) => void;
  updateCurrentMoment: (currentMoment: number, force?: boolean) => void;
  updateTimeframe: (startDay: number, duration: number) => void;
  updateNextCurrentMoment: (nextCurrentMoment: number | null) => void;
  setSkipOffTime: (skipOffTime: boolean) => void;
  setAnimationSpeed: (animationSpeed: AnimationSpeed) => void;
  toggleIsolation: (line: LineData | null) => void;
  triggerTimeframeChangeAnimation: (target: TimeframeAnimationTarget) => void;
}

interface StateProps {
  projectStartDate: Date;
}

interface ChartProps extends OwnProps, StateProps { }

interface ChartState {
  width: number;
  containerStyle: React.CSSProperties;
}

class NewGanttChartComponent extends React.Component<ChartProps, ChartState> {
  private readonly leftPanelWidth: number = 340;
  private readonly headerHeight: number = 70;
  private readonly projectTimelineHeight: number = 70;

  constructor(props: ChartProps) {
    super(props);

    const derivedState = NewGanttChartComponent.getDerivedStateFromProps(props, null);

    this.state = {
      width: 1,
      containerStyle: derivedState.containerStyle,
    };
  }

  public static getDerivedStateFromProps(nextProps: ChartProps, prevState: ChartState | null): Partial<ChartState> {
    let newState: Partial<ChartState> | null = null;

    if (!prevState || nextProps.height !== prevState.containerStyle.height) {
      newState = monolite.set(newState || {}, (s) => s.containerStyle)({ height: nextProps.height });
    }

    return newState;
  }

  public render(): JSX.Element {
    return (
      <div
        className='gantt-chart'
        style={this.state.containerStyle}
      >
        <ReactResizeDetector
          handleWidth={true}
          onResize={this.onWidthChange}
        />
        <TimePointer
          currentMoment={this.props.currentMoment}
          nextCurrentMoment={this.props.nextCurrentMoment}
          leftPanelWidth={this.leftPanelWidth}
          width={this.state.width}
          timeframeStartDay={this.props.timeframeStartDay}
          timeframeDuration={this.props.timeframeDuration}
          isPlaying={this.props.isPlaying}
          updateCurrentMoment={this.props.updateCurrentMoment}
          updateNextCurrentMoment={this.props.updateNextCurrentMoment}
          play={this.props.play}
          pause={this.props.pause}
          setAnimationSpeed={this.props.setAnimationSpeed}
        />
        <ChartHeader
          width={this.state.width}
          leftPanelWidth={this.leftPanelWidth}
          projectStartDate={this.props.projectStartDate}
          timeframeStartDay={this.props.timeframeStartDay}
          timeframeDuration={this.props.timeframeDuration}
          isPlaying={this.props.isPlaying}
          speed={this.props.speed}
          skipOffTime={this.props.skipOffTime}
          timelineDuration={this.props.timelineDuration}
          play={this.props.play}
          pause={this.props.pause}
          moveForward={this.props.moveForward}
          moveBackward={this.props.moveBackward}
          changeSpeed={this.props.changeSpeed}
          setSkipOffTime={this.props.setSkipOffTime}
          triggerTimeframeChangeAnimation={this.props.triggerTimeframeChangeAnimation}
        />
        <ChartBody
          dataProvider={this.props.dataProvider}
          timeframeStartDay={this.props.timeframeStartDay}
          timeframeDuration={this.props.timeframeDuration}
          width={this.state.width}
          leftPanelWidth={this.leftPanelWidth}
          height={this.props.height - this.headerHeight - this.projectTimelineHeight}
          isolatedLine={this.props.isolatedLine}
          toggleIsolation={this.props.toggleIsolation}
        />
        <ProjectTimeline
          width={this.state.width}
          projectStartDate={this.props.projectStartDate}
          timelineStartDay={this.props.timelineStartDay}
          timelineDuration={this.props.timelineDuration}
          timeframeStartDay={this.props.timeframeStartDay}
          timeframeDuration={this.props.timeframeDuration}
          currentMoment={this.props.currentMoment}
          nextCurrentMoment={this.props.nextCurrentMoment}
          isPlaying={this.props.isPlaying}
          dataProvider={this.props.dataProvider}
          updateTimeframe={this.props.updateTimeframe}
          updateCurrentMoment={this.props.updateCurrentMoment}
          updateNextCurrentMoment={this.props.updateNextCurrentMoment}
          setAnimationSpeed={this.props.setAnimationSpeed}
          play={this.props.play}
          pause={this.props.pause}
        />
      </div>
    );
  }

  @autobind
  private onWidthChange(width: number): void {
    this.setState({ width });
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    projectStartDate: state.fourDVisualisation.projectStartDate,
  };
};

export const NewGanttChart = connect(mapStateToProps)(NewGanttChartComponent);
