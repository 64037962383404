import * as t from 'io-ts';
import { CEActivityAssignmentDataMaterialC } from './ce-activity-assignment-data-material';
import { CEActivityAssignmentDataSelectedVariantC } from './ce-activity-assignment-data-selected-variant';
import { CEActivityAssignmentDataVariantC } from './ce-activity-assignment-data-variant';


export const CEActivityAssignmentDataTreeNodeC = t.type(
  {
    selectedVariant: CEActivityAssignmentDataSelectedVariantC,
    variants: t.record(t.string, CEActivityAssignmentDataVariantC),
    materials: t.record(t.string, CEActivityAssignmentDataMaterialC),
  },
  'CEActivityAssignmentDataTreeNode',
);

export type CEActivityAssignmentDataTreeNode = t.TypeOf<typeof CEActivityAssignmentDataTreeNodeC>;
