import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './kreo-button.scss';

type ButtonModes = 'action' | 'submit' | 'success' | 'dark' | 'error'
                  | 'toaction' | 'delete' | 'cancel' | 'ghost' | 'add'
                  | 'toaction-white';

export interface KreoButtonProps {
  style?: React.CSSProperties;
  disabled?: boolean;
  caption?: string | JSX.Element;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  mode?: ButtonModes;
  htmlButtonType?: 'button' | 'submit' | 'reset';
  controlName?: string;
  rounded?: boolean;
  size: 'small' | 'medium' | 'large';
  notFilled?: boolean;
  icon?: React.ReactNode;
  tooltip?: string;
}

class KreoButton extends React.PureComponent<KreoButtonProps> {
  public static defaultProps: Partial<KreoButtonProps> = {
    style: {},
    size: 'medium',
    disabled: false,
    htmlButtonType: null,
  };

  public render(): React.ReactNode {
    const {
      style, htmlButtonType, mode, size, className, disabled, rounded, icon, notFilled, tooltip,
    } = this.props;
    return (
      <button
        style={style}
        type={!disabled ? htmlButtonType : 'button'}
        title={tooltip ? tooltip : null}
        className={classNames('kreo-btn', `kreo-btn--${size}`, mode, className, {
          disabled,
          'kreo-btn--rounded': rounded,
          'kreo-btn--has-icon': !!icon,
          'kreo-btn--not-filled': notFilled,
        })}
        onClick={this.onClick}
        data-control-name={this.props.controlName}
      >
        <i>{icon}</i>
        <span className='kreo-btn__caption'>
          {this.props.caption ? this.props.caption : this.props.children}
        </span>
      </button>
    );
  }
  @autobind
  private onClick(ev: React.MouseEvent<HTMLButtonElement>): void {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick(ev);
    }
  }
}

export default KreoButton;
