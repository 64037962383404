import { DrawingsGeometryFilterBase } from './filter-base';

export class DrawingsInstanceSetFilter implements DrawingsGeometryFilterBase {
  public applyFilter: (instanceId: string) => boolean;

  private _idsSet: Set<string>;

  constructor(ids: string[], reversedIfExist: boolean = false) {
    if (ids) {
      this._idsSet = new Set(ids);
      this.applyFilter = reversedIfExist ? (id) => !this._idsSet.has(id) : id => this._idsSet.has(id);
    } else {
      this.applyFilter = () => true;
    }
  }
}
