import styled from 'styled-components';

const History = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 30px;
`;

export const Styled = {
  History,
};
