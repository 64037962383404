import * as React from 'react';

import { CostEstimateGroupingType } from '../../../units/projects/enums/cost-estimate-grouping-type';
import { ReadonlyCostRateCell } from './readonly-cost-rate-cell';

interface TotalCellProps {
  totalCost: number | undefined;
  levelOfNesting: number;
  groupingType: CostEstimateGroupingType;
  isIndirectCosts: boolean;
}

export const TotalCell: React.FC<TotalCellProps> = (
  { totalCost, levelOfNesting, groupingType, isIndirectCosts },
) => {

  const isBlank = !isIndirectCosts &&
    (groupingType === CostEstimateGroupingType.Workpackages ||
      groupingType === CostEstimateGroupingType.WorkpackagesExtended
      ? levelOfNesting > 1
      : levelOfNesting > 0);

  return (
      <ReadonlyCostRateCell
        rate={null}
        cost={isBlank ? null : totalCost}
      />
  );
};
