import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';

export const QUOTE_EXCEPTION_DIALOG = 'QUOTE_EXCEPTION_DIALOG';

const TITLE = 'Invalid value';
const TEXT = 'You are trying to save an invalid value';

interface DispatchProps {
  closeDialog: () => void;
}

interface Props extends DispatchProps {}

const QuoteExceptionDialogComponent = ({ closeDialog }: Props): JSX.Element => {
  useEffect(() => {
    closeDialog();
  }, []);
  const handleCancle = useCallback((callBack: () => void) => {
    if (callBack) {
      callBack();
    }
  }, []);
  return (
    <ConfirmationDialog
      name={QUOTE_EXCEPTION_DIALOG}
      title={TITLE}
      text={TEXT}
      cancelButtonText={'Okay'}
      onCancel={handleCancle}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(QUOTE_EXCEPTION_DIALOG)),
  };
};

export const QuoteExceptionDialog = connect(null, mapDispatchToProps)(QuoteExceptionDialogComponent);
