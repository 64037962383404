import { ICellEditorParams } from 'ag-grid-community';
import autobind from 'autobind-decorator';

import { DefaultPivotColumnKey } from '../../../../../units/projects/enums/default-pivot-column-key';
import { QtoTreeRowProperty } from '../../../../../units/projects/interfaces/quantity-take-off';
import {
  PropertyHelper,
  QtoReportPivotTableIdHelper,
} from '../../../../../units/projects/utils/quantity-take-off-tree-table';
import {
  QuantityTakeOffFormulaHelper,
} from '../../../../../units/projects/utils/quantity-take-off-tree-table/formula-helper';
import { CellEditorType } from '../../quantity-take-off-tree-table-cell-edit/cell-editor-type';
import { FormulaCellEditorHelper } from '../../quantity-take-off-tree-table-cell-edit/formula-cell-editor-helper';
import { PivotCellEditor } from './pivot-cell-editor';

export class PivotTableFormulaCellEditor extends PivotCellEditor<HTMLSpanElement> {
  public constructor(params: ICellEditorParams, eGui: HTMLDivElement) {
    super(params, eGui);
    this.cellEditorType = CellEditorType.FormulaCellEditor;
  }

  public static getInnerHtml(): string {
    return FormulaCellEditorHelper.getInnerHtml(this.className);
  }

  public getNode(value: string): Node {
    const span = FormulaCellEditorHelper.getValueElement(value, PivotCellEditor.className, this.params);
    this.eValue = span;
    return span;
  }

  public initEvents(): void {
    this.eValue.addEventListener('input', this.handelInput);
  }

  public destroyEvents(): void {
    if (this.eValue) {
      this.eValue.removeEventListener('input', this.handelInput);
    }
  }

  public getCurrentValue(): string {
    return this.eValue.textContent;
  }

  public setFocus(): void {
    document.getSelection().setPosition(this.eValue, this.eValue.children.length);
  }

  public getNewValue(): QtoTreeRowProperty {
    const colId = this.params.column.getId();
    const property = this.propertyGetter(colId) || {};
    const hasDefaultValue = property && property.default;
    if (!this.eValue.innerText && !hasDefaultValue && colId !== DefaultPivotColumnKey.Quantity) {
      return undefined;
    }
    const { columnId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(this.params.node.data.id);
    const formula = this.eValue.innerText.replace(
      QuantityTakeOffFormulaHelper.formulaVariableRegex,
      (_match, header) => {
        const colDef = this.params.columnApi.getAllColumns().find((column) => {
          return column.getColDef().headerName.toUpperCase() === header.toUpperCase();
        });

        if (!colDef) {
          return `[${header}]`;
        }

        if (colDef.getColId() === DefaultPivotColumnKey.Quantity) {
          return `[${columnId}]`;
        } else {
          return `[${QtoReportPivotTableIdHelper.getPivotRowId(columnId, colDef.getColId())}]`;
        }
      });

    property.override = formula;

    return property;
  }

  protected setEValue(value: string): void {
    this.eValue.innerText = value;
  }

  protected currentInitEValue(): void {
    const formula = PropertyHelper.getActualValue<string>(this.getProperty());
    const innerHTML = formula.replace(
      QuantityTakeOffFormulaHelper.formulaVariableRegex,
      (_match, key: string) => {
        const columnKey = this.getColumnKey(key);
        const column = this.params.columnApi.getColumn(columnKey);
        const header = column
          ? column.getColDef().headerName
          : key;

        return `[${header}]`;
      });
    this.eValue.innerHTML = this.getColoredText(innerHTML);
  }

  protected isEmpty(): boolean {
    return !this.eValue.innerText;
  }

  private getColumnKey(key: string): string {
    if (QtoReportPivotTableIdHelper.isIdContainSeparator(key)) {
      return QtoReportPivotTableIdHelper.getIdsFromPivotRowId(key).columnId;
    }
    return this.params.node.parent.data.properties[key]
      ? DefaultPivotColumnKey.Quantity
      : key;
  }

  @autobind
  private handelInput(ev: Event): void {
    FormulaCellEditorHelper.handelInput(ev, this.params);
  }

  @autobind
  private getProperty(): QtoTreeRowProperty {
    const parent = this.params.node.parent;
    const colId = this.params.column.getColId();
    const { columnId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(this.params.node.data.id);
    const id = QtoReportPivotTableIdHelper.getPivotRowId(columnId, colId);
    return parent.data.properties[id];
  }

  private getColoredText(data: string): string {
    return FormulaCellEditorHelper.getColoredText(data, this.params);
  }
}
