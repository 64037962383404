import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './database-activity-variant-measurement-item.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { mathUtils } from 'common/utils/math-utils';
import {
  ActivityVariantMaterialBasedCrewHoursModel,
  ExtractorFunctionModel,
  MaterialBasedCrewHoursModel,
  MeasurementModel,
  UnitModel,
} from '../../interfaces/data';
import { MaterialModel } from '../../interfaces/resources-data';
import { DatabaseEntityNamedHeader } from '../database-entity-named-header';
import {
  DatabaseActivityVariantResourceBasedCrewHoursMaterilaItem,
} from './database-activity-variant-resource-based-crew-hours-material-item';
import {
  CREW_HOURS_MATERIAL_SELECTOR_DIALOG,
  DatabaseActivityVariantResourceBasedCrewHoursMaterialSelector,
  DialogData,
} from './database-activity-variant-resource-based-crew-hours-material-selector';


interface ReduxProps {
  unitMap: Record<number, UnitModel>;
  extractorFunctions: ExtractorFunctionModel[];
  measurementToUnits: Record<string, UnitModel[]>;
  measurementsMap: Record<string, MeasurementModel>;
}

interface ReduxActions {
  openDialog: (data: DialogData) => void;
}

interface Props extends ReduxProps, ReduxActions {
  fieldName?: string;
  readonly: boolean;
  materialBasedCrewHours: ActivityVariantMaterialBasedCrewHoursModel;
  onChange: (model: ActivityVariantMaterialBasedCrewHoursModel) => void;
}

class DatabaseActivityVariantResourceBasedCrewHoursComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { materialBasedCrewHours, readonly } = this.props;
    const primaryPercentageInputValue = Math.round(materialBasedCrewHours.primaryPercentage * 100);

    return (
      <div className='measurement-right-panel'>
        <DatabaseEntityNamedHeader
          name='Materials mix'
          // info='The way the activity should be measured'
        />
          <DatabaseActivityVariantResourceBasedCrewHoursMaterilaItem
            title={'Primary Material'}
            fieldKey={this.nestedFieldName('primaryMaterial')}
            model={materialBasedCrewHours.primaryMaterial}
            onChange={this.onPrimaryChange}
            onPercentageChange={this.onPrimaryPercentageChange}
            percentage={primaryPercentageInputValue}
            readonly={readonly}
            onSelectMaterialClick={this.primaryMaterialSelectClick}
            unitMap={this.props.unitMap}
          />
          <DatabaseActivityVariantResourceBasedCrewHoursMaterilaItem
            title={'Secondary Material'}
            fieldKey={this.nestedFieldName('secondaryMaterial')}
            model={materialBasedCrewHours.secondaryMaterial}
            onChange={this.onSecondaryChange}
            onPercentageChange={this.onSecondaryPercentageChange}
            percentage={100 - primaryPercentageInputValue}
            readonly={readonly}
            onSelectMaterialClick={this.secondaryMaterialSelectClick}
            unitMap={this.props.unitMap}
          />
        <DatabaseActivityVariantResourceBasedCrewHoursMaterialSelector />
      </div>
    );
  }

  @autobind
  private primaryMaterialSelectClick(): void {
    this.props.openDialog({
      title: 'Select Primary Material',
      onSelect: this.onPrimaryMaterialSelect,
      selectedMaterial: this.props.materialBasedCrewHours.primaryMaterial.resource,
    });
  }

  @autobind
  private secondaryMaterialSelectClick(): void {
    const model = this.props.materialBasedCrewHours;
    this.props.openDialog({
      title: 'Select Secondary Material',
      onSelect: this.onSecondaryMaterialSelect,
      selectedMaterial: model.secondaryMaterial.resource,
      unitId: model.primaryMaterial.unitId,
    });
  }

  @autobind
  private onPrimaryMaterialSelect(resource: MaterialModel): void {
    const primaryMaterial = {
      ...this.props.materialBasedCrewHours.primaryMaterial,
      resource,
      unitId: resource.defaultUnitId,
    };

    this.props.onChange({ ...this.props.materialBasedCrewHours, primaryMaterial });
  }

  @autobind
  private onSecondaryMaterialSelect(resource: MaterialModel): void {
    const secondaryMaterial = {
      ...this.props.materialBasedCrewHours.secondaryMaterial,
      resource,
      unitId: resource.defaultUnitId,
    };

    this.props.onChange({ ...this.props.materialBasedCrewHours, secondaryMaterial });
  }


  @autobind
  private onPrimaryPercentageChange(value: number): void {
    const primaryPercentage = mathUtils.clamp(value, 0, 100) / 100;

    this.props.onChange({ ...this.props.materialBasedCrewHours, primaryPercentage });
  }

  @autobind
  private onSecondaryPercentageChange(secondaryPercentage: number): void {
    this.onPrimaryPercentageChange(100 - secondaryPercentage);
  }

  @autobind
  private onPrimaryChange(primaryMaterial: MaterialBasedCrewHoursModel): void {
    this.props.onChange({ ...this.props.materialBasedCrewHours, primaryMaterial });
  }

  @autobind
  private onSecondaryChange(secondaryMaterial: MaterialBasedCrewHoursModel): void {
    this.props.onChange({ ...this.props.materialBasedCrewHours, secondaryMaterial });
  }

  private nestedFieldName(fieldName: string): string {
    return this.props.fieldName
      ? `${this.props.fieldName}.${fieldName}`
      : fieldName;
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    unitMap: state.database.currentDatabase.unitMap,
    extractorFunctions: state.database.currentDatabase.functions,
    measurementToUnits: state.database.currentDatabase.measurementToUnits,
    measurementsMap: state.database.currentDatabase.measurementsMap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    openDialog: data =>
      dispatch(KreoDialogActions.openDialog(CREW_HOURS_MATERIAL_SELECTOR_DIALOG, data)),
  };
};

export const DatabaseActivityVariantResourceBasedCrewHours =
  connect(mapStateToProps, mapDispatchToProps)(DatabaseActivityVariantResourceBasedCrewHoursComponent);
