import styled from 'styled-components';

import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';

const Container = styled.div<{ isCollapsed: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.color.background};
  width: ${p => p.isCollapsed ? 60 : 260}px;
  height: 100%;
  padding: 10px;
  transition: ${p => p.theme.duration.s};
  overflow: hidden;
  &, * {
    box-sizing: border-box;
  }
  button {
    svg {
      margin: ${p => p.isCollapsed && 0};
    }
    p {
      white-space: nowrap;
      overflow: hidden;
    }
  }

  ${UpgradeWrapperStyled.StarsContainer} {
    right: ${p => p.isCollapsed ? 0 : 50}px;
    top: ${p => p.isCollapsed ? 0 : 5}px;
  }

  div[id='Report Template'] {
    ${UpgradeWrapperStyled.StarsContainer} {
      right: ${p => p.isCollapsed ? 0 : 50}px;
    }
  }
`;

const MenuItem = styled.div<{ isCollapsed: boolean, isActive: boolean, isNeedUpdate: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 10px;
  padding-left: 10px;
  background: ${p => p.theme.color[p.isActive ? 'backgroundRush' : 'background']};
  box-shadow: 0 0 0 ${p => p.isActive ? 2 : 0}px ${p => p.theme.color.pale};
  border-radius: 10px;
  cursor: ${p => p.isNeedUpdate ? 'default' : 'pointer'};
  svg {
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }
  > p {
    position: absolute;
    left: 40px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition: ${p => p.theme.duration.s};
    opacity: ${p => p.isCollapsed ? 0 : 1};
    min-width: max-content;
  }

  :hover {
    box-shadow: 0 0 0 ${p => p.isNeedUpdate ? 0 : 2}px ${p => p.theme.color.pale};
  }
`;

const CollapseButton = styled.i<{ isCollapsed: boolean }>`
  width: 30px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${p => p.theme.color.pale};
  opacity: 0.8;
  transition: ${p => p.theme.duration.s};
  cursor: pointer;

  svg {
    transition: ${p => p.theme.duration.s};
    width: 10px;
    fill: ${p => p.theme.color.turquoiseInversed};
    transform: scaleX(${ p => p.isCollapsed ? -1 : 1});
  }

  &:hover {
    background: ${p => p.theme.color.turquoise};
    svg {
      fill: ${p => p.theme.color.white};
    }
  }

  &:active {
    transform:  scaleX(0.9) scaleY(0.9) scaleZ(0.9)  ;
  }
`;

const Logo = styled.div<{ onClick: () => void }>`
  cursor: ${p => p.onClick ? 'pointer' : 'default'};
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin: 65px 0px 20px 0px;
`;


export const Styled = {
  Container,
  MenuItem,
  CollapseButton,
  Logo,
  ButtonContainer,
};
