import { AvatarStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const AvatarContainer = styled.div<{ lastUser: boolean }>`
  display: inline-flex;
  vertical-align: middle;
  margin-right: 5px;
  z-index: 2;
  opacity: ${p => p.lastUser ? 1 : 0};

  ${AvatarStyled.Avatar} {
    > p {
      display: flex;
      align-items: center;
      height: 100%;
      line-height: 10px;
    }
  }
`;

const UsersTooltip = styled.div`
  background-color: ${p => p.theme.color.backgroundRush};
  box-shadow: ${p => p.theme.shadow.xl};
  border-radius: 15px;
  width: 190px;
  padding: 5px 0;
`;

const InstanceInfoContainer = styled.div`
  padding: 10px 15px;
  :not(:last-child) {
    border-bottom: 10px solid ${p => p.theme.color.pale};
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 8px 0;
  div {
    flex-shrink: 0;
  }
`;

export const Styled = {
  AvatarContainer,
  UsersTooltip,
  InstanceInfoContainer,
  UserInfo,
};
