import { AvatarStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div<{ showButtons: boolean }>`
  display: flex;
  align-items: flex-end;
  margin: 10px;

  ${AvatarStyled.Avatar} {
    margin-bottom: ${p => p.showButtons ? 45 : 0}px;
  }
`;

const Input = styled.div`
  width: 100%;
  margin-left: 10px;
  flex-direction: column;
  width: calc(100% - 20px);
`;

export const Styled = {
  Container,
  Input,
};
