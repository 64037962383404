import { Icons, IconButton, ElementTooltip } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { DrawingsActions } from 'common/components/drawings/actions/creators/common';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import {
  DrawingsDeleteFileConfirmationDialog,
  DRAWING_DELETE_FILE_CONFIRMATION_DIALOG,
} from 'common/components/drawings/dialogs';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { DrawingsFilesData, DrawingsShortInfo, DrawingsUploadPdfResult } from 'common/components/drawings/interfaces';
import { DrawingsFSHighlightState } from 'common/components/drawings/interfaces/drawings-state';
import { DrawingsUploadFileUtils } from 'common/components/drawings/utils';
import { DrawingsPdfBrowserUtils } from 'common/components/drawings/utils/drawings-pdf-browser-utils';
import { DrawingsUndoRedoHelper } from 'common/components/drawings/utils/drawings-undo-redo-helper';
import { RenderIf } from 'common/components/render-if';
import { TreeTable } from 'common/components/tree-table';
import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { UploadingFile } from 'common/interfaces/common-state';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { MetricNames } from 'utils/posthog';
import {
  DrawingsFile,
  DrawingsFiles,
  DrawingsFolderViewInfo,
  DrawingsPage,
} from '../../../interfaces/drawings-file-info';
import {
  DrawingsPdfBrowserFilter,
  FilterChangePayload,
  FilterData,
  FilterDataInitialState,
} from '../../drawings-pdf-browser-filter';
import { DrawingsPdfFooterContent } from '../../drawings-pdf-footer-content';
import { MeasurementsFilterValue } from '../../pdf-filters';
import { DrawingsPdf } from './drawings-pdf';
import { Styled } from './styled';
import { WithDrawingContext, withContext } from './with-contexts';


interface StateProps {
  files: DrawingsFiles;
  selectedPages: string[];
  highlightState: DrawingsFSHighlightState;
  fileData: DrawingsFilesData;
  currentDrawing: DrawingsShortInfo;
}

interface DispatchProps {
  onPdfUpload: (uploadedResult: DrawingsUploadPdfResult[]) => void;
  onEditEntity: (id: string, name: string, parentId: string) => void;
  onOpenTabs: (entityIds: string[], selectLast: boolean) => void;
  onRemoveTabs: (removedTabs: string[]) => void;
  addFolder: (parentId?: string) => void;
  onExpandEntity: (id: string) => void;
  openDialog: (name: string, data: Array<DrawingsFile | DrawingsFolderViewInfo>) => void;
  onPageParameterUpdate:
    (drawingId: string, parameter: string, value: string | number) => void;
  onDeleteDrawings: (id: string) => void;
  openUnsavedChangesDialog: (accept: () => void, cancel?: () => void) => void;
  onDeletePage: (drawingId: string, pdfId: string) => void;
}

interface Props extends
  StateProps,
  DispatchProps,

  WithDrawingContext {
  isShowFilter?: boolean;
}

interface ComponentState {
  selectedFiles: DrawingsFile[];
  selectedFile: string;
  error: boolean;
  isLoading: boolean;
  filterData: FilterData;
}

class DrawingsPdfLinkPlansWrapperComponent extends React.Component<Props, ComponentState> {
  private canEditMeasurement: boolean =
    this.props.ability.can(Operation.Update, Subject.Takeoff2DMeasurement);
  private folderIdForAddFile: string;
  private readonly contextMenuItems: any = [];
  private tableApi: TreeTable<string, void>;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedFiles: [],
      selectedFile: null,
      error: false,
      isLoading: false,
      filterData: FilterDataInitialState,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (
      this.tableApi
      && this.props.highlightState.filesWithSelectedElement !== prevProps.highlightState.filesWithSelectedElement
    ) {
      const files = {
        ...prevProps.highlightState.filesWithSelectedElement,
        ...this.props.highlightState.filesWithSelectedElement,
      };
      const updates = Object.keys(files).map(x => ({ id: x, properties: this.props.files.entities[x].properties }));
      this.tableApi.rowController.updateRows(updates);
    }
    this.canEditMeasurement = this.props.ability.can(Operation.Update, Subject.Takeoff2DMeasurement);
  }

  public render(): React.ReactNode {
    const {
      files,
      selectedPages,
      highlightState,
      ability,
      onExpandEntity,
      addFolder,
      onPageParameterUpdate,
    } = this.props;

    this.canEditMeasurement = ability.can(Operation.Update, Subject.Takeoff2DMeasurement);
    const selectedFiles = this.getSelectedPdfFiles();

    return (
      <>
        <DrawingsPdf
          files={files}
          multiSelect={true}
          selectedPages={selectedPages}
          highlightState={highlightState}
          isLoading={this.state.isLoading}
          canEdit={this.canEditMeasurement}
          renderFilesFooterContent={this.renderFooterContent}
          renderFilesHeaderAdditionalContent={this.renderHeaderAdditionalContent}
          selectedFile={this.state.selectedFile}
          selectedFiles={selectedFiles}
          onChangeDrawingSelection={this.onChangeDrawingSelection}
          onExpandEntity={onExpandEntity}
          onSelectFile={this.onSelectFile}
          addFolder={addFolder}
          getContextMenuItems={this.getContextMenuItems}
          onDeleteFileClick={this.openDeletePageConfirmDialog}
          onDragRow={this.onDragRow}
          onChangeName={this.onChangeName}
          startAddFile={this.startAddFile}
          onPageParameterUpdate={onPageParameterUpdate}
          copyDrawingGeometriesToPage={this.copyDrawingGeometriesToPage}
          onDeletePage={this.onDeletePage}
        />
        <DrawingsDeleteFileConfirmationDialog onSubmit={this.onDeleteFiles} />
      </>
    );
  }

  @autobind
  private onDeletePage(drawingId: string, pdfId: string): void {
    const applyDelete = (): void => {
      this.props.onDeletePage(drawingId, pdfId);
      this.props.cleanUndoRedo();
    };
    if (this.props.currentDrawing?.drawingId === drawingId) {
      this.props.setDrawMode(DrawingsDrawMode.Disabled, {
        ignoreCancelMessage: true,
        afterSave: applyDelete,
      });
    } else {
      applyDelete();
    }
  }

  @autobind
  private onSelectFile(fileId: string): void {
    this.setState({
      selectedFile: fileId,
      selectedFiles: this.getSelectedPdfFiles(),
    });
  }

  @autobind
  private copyDrawingGeometriesToPage(pdfId: string, drawingId: string, targetDrawingId: string): void {
    const instances = this.props.fileData[pdfId][drawingId].instances;
    this.props.duplicateToPage(instances, targetDrawingId);
  }

  @autobind
  private getSelectedPdfFiles(): DrawingsFile[] {
    const { files, fileData: drawingPaperPoints } = this.props;
    const { filterData } = this.state;

    let selectedFiles = [];
    Object.values(files.entities).forEach(f => {
      if (f.type === TreeTableRowType.Element) {
        selectedFiles = selectedFiles.concat(
          getChildrenPdfFiles(f.id, files.entities, filterData.measurements, drawingPaperPoints),
        );
      }
    });

    return selectedFiles;
  }

  @autobind
  private renderFooterContent(): React.ReactNode {
    return (
      <DrawingsPdfFooterContent
        onSaveFiles={this.onSaveFiles}
        addFileToRoot={this.addFileToRoot}
        canEditMeasurement={this.canEditMeasurement}
        error={this.state.error}
      />
    );
  }

  @autobind
  private renderHeaderAdditionalContent(): React.ReactNode {
    if (this.canEditMeasurement || this.props.isShowFilter) {
      return (
        <Styled.AddFolderButton>
          <RenderIf condition={this.props.isShowFilter}>
            <DrawingsPdfBrowserFilter
              filterData={this.state.filterData}
              changeFilterData={this.changeFilterData}
            />
          </RenderIf>
          <RenderIf condition={this.canEditMeasurement}>
            <ElementTooltip
              text='Create an empty folder'
              position='bottom'
              speed='l'
            >
              <IconButton
                Icon={Icons.Group_2}
                width={20}
                height={20}
                onClick={this.onAddFolder}
              />
            </ElementTooltip>
          </RenderIf>
        </Styled.AddFolderButton>
      );
    } else {
      return null;
    }
  }

  @autobind
  private changeFilterData(changeFilterData: FilterChangePayload): void {
    const newFilterData = { measurements: changeFilterData.element };
    this.setState({ filterData: newFilterData });
  }


  @autobind
  private addFileToRoot(): void {
    this.props.openDialog(DrawingDialogs.PDF_UPLOAD_DIALOG, null);
  }

  @autobind
  private onSaveFiles(files: UploadingFile[]): void {
    const converted = files.map((file) => {
      return DrawingsUploadFileUtils.prepareFileData(file, this.folderIdForAddFile);
    });
    this.folderIdForAddFile = undefined;
    this.onPdfUpload(converted);
  }

  @autobind
  private onPdfUpload(uploadedResult: DrawingsUploadPdfResult[]): void {
    this.props.onPdfUpload(uploadedResult);
    uploadedResult.forEach(file => {
      if (file.pdf.parentId) {
        this.props.onEditEntity(file.pdf.id, file.pdf.name, file.pdf.parentId);
      }
    });
    this.props.sendEvent(MetricNames.fileManager.addedFile, { files: uploadedResult.map(f => f.pdf.name).join(',') });
  }

  @autobind
  private onDragRow(parentId: string | null, _position: number, rowIdsToMove: string[]): void {
    rowIdsToMove.forEach(rowId => {
      this.props.onEditEntity(rowId, this.props.files.entities[rowId].properties.name, parentId);
    });
  }

  @autobind
  private onChangeDrawingSelection(ids: string[], selected: boolean, selectLast?: boolean): void {
    const { currentDrawing, onOpenTabs, onRemoveTabs, openUnsavedChangesDialog, rendererApi } = this.props;
    const canLoseChanges = rendererApi?.hasNotFinishedGeometry();
    if (selected) {
      if (selectLast && canLoseChanges && currentDrawing?.drawingId !== ids[ids.length - 1]) {
        openUnsavedChangesDialog(() => this.props.onOpenTabs(ids, selectLast));
      } else {
        onOpenTabs(ids, selectLast);
      }
    } else {
      if (canLoseChanges && ids.includes(currentDrawing?.drawingId)) {
        openUnsavedChangesDialog(() => onRemoveTabs(ids));
      } else {
        onRemoveTabs(ids);
      }
    }
  }

  @autobind
  private onAddFolder(): void {
    this.props.addFolder();
    this.props.sendEvent(MetricNames.fileManager.addedFolder);
  }

  @autobind
  private getContextMenuItems(): any[] {
    return this.contextMenuItems;
  }

  @autobind
  private openDeletePageConfirmDialog(deleteIds: string[]): void {
    const entities = deleteIds.map(id => this.props.files.entities[id]);
    this.props.openDialog(DRAWING_DELETE_FILE_CONFIRMATION_DIALOG, entities);
  }

  @autobind
  private onDeleteFiles(data: Array<DrawingsFile | DrawingsFolderViewInfo>): void {
    if (data) {
      const { rendererApi, currentDrawing } = this.props;
      if (
        rendererApi?.hasNotFinishedGeometry()
        && currentDrawing
        && data.some(x => x.id === currentDrawing.pdfId)
      ) {
        this.props.openUnsavedChangesDialog(() => this.deleteFiles(data));
      } else {
        this.deleteFiles(data);
      }
    }
  }

  @autobind
  private deleteFiles(data: Array<DrawingsFile | DrawingsFolderViewInfo>): void {
    if (data.some(x => x.type === TreeTableRowType.Element || x.children.length)) {
      this.props.cleanUndoRedo();
    }
    data.forEach(x => this.props.onDeleteDrawings(x.id));
  }

  @autobind
  private onChangeName(id: string, name: string): void {
    const { parentId, properties } = this.props.files.entities[id];

    const { undo, redo } = DrawingsUndoRedoHelper.fileRenameUndoRedo(
      name,
      properties.name,
      id,
      parentId,
      this.props.onEditEntity,
    );
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private startAddFile(id?: string): void {
    this.folderIdForAddFile = id;
    this.props.openDialog(DrawingDialogs.PDF_UPLOAD_DIALOG, null);
    this.setState({ error: false });
  }
}

function getChildrenPdfFiles(
  id: string,
  entities: Record<string, DrawingsFile | DrawingsFolderViewInfo>,
  filterValue: string,
  fileData: DrawingsFilesData,
): DrawingsFile[] {
  const arr = [];
  const entitiesInfo = entities[id];
  if (!entitiesInfo) return arr;
  if (entitiesInfo.children) {
    entitiesInfo.children.forEach(file => arr.push(
      ...getChildrenPdfFiles(file, entities, filterValue, fileData),
    ));
  } else {
    arr.push({
      ...entitiesInfo,
      pages: getFilteredPages(filterValue, entitiesInfo, fileData),
    });
  }
  return arr;
}

function getFilteredPages(
  filterValue: string,
  entitiesInfo: DrawingsFile | DrawingsFolderViewInfo,
  fileData: DrawingsFilesData,
): DrawingsPage[] {
  if (DrawingsPdfBrowserUtils.isFile(entitiesInfo)) {
    if (filterValue === MeasurementsFilterValue.All) {
      return entitiesInfo.pages;
    }
    if (filterValue === MeasurementsFilterValue.With) {
      return entitiesInfo.pages.filter(
        page => fileData[page.pdfId] && fileData[page.pdfId][page.drawingId]?.instances.length,
      );
    }
    if (filterValue === MeasurementsFilterValue.Without) {
      return entitiesInfo.pages.filter(
        page => {
          return !fileData[page.pdfId] || !fileData[page.pdfId][page.drawingId]?.instances.length;
        },
      );
    }
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    files: state.drawings.files,
    selectedPages: state.drawings.selectedPages,
    highlightState: state.drawings.fsHighlightStatus,
    fileData: state.drawings.aiAnnotation.fileData,
    currentDrawing: state.drawings.currentDrawingInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onPdfUpload: (uploadedPDFPayload) => dispatch(DrawingsActions.pdfUploaded(uploadedPDFPayload)),
    onEditEntity: (id, name, parentId) => dispatch(DrawingsActions.editFileTreeItem(id, name, parentId)),
    onOpenTabs: (ids, selectLast) => dispatch(DrawingsActions.addTabs({ entityIds: ids, openLastPage: selectLast })),
    onRemoveTabs: (payload) => dispatch(DrawingsActions.removeTabs(payload)),
    addFolder: (parentFolderId) => dispatch(DrawingsActions.addFolder(parentFolderId)),
    onExpandEntity: (id: string) => dispatch(DrawingsActions.expandFolder(id)),
    openDialog: (name, data) => dispatch(KreoDialogActions.openDialog(name, data)),
    onPageParameterUpdate: (drawingId, parameter, value) =>
      dispatch(DrawingsActions.pageMetaParameterUpdate(drawingId, parameter, value)),
    onDeleteDrawings: (drawingId) => dispatch(DrawingsActions.deleteDrawing(drawingId)),
    openUnsavedChangesDialog: (accept, cancel) =>
      dispatch(KreoDialogActions.openDialog(DrawingDialogs.UNSAVED_CHANGES_DIALOG, { accept, cancel })),
    onDeletePage: (drawingId, pdfId) => dispatch(DrawingsActions.deletePage(drawingId, pdfId)),
  };
}

const ConnectedToRedux = connect(mapStateToProps, mapDispatchToProps)(DrawingsPdfLinkPlansWrapperComponent);

export const DrawingsPdfLinkPlansWrapper = withContext(ConnectedToRedux);
