import autobind from 'autobind-decorator';

import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsPolylineGeometry } from 'common/components/drawings/interfaces';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { PolylineThickness } from '../utility/polyline-thickness';
import {
  FinishedDrawingElement,
  TryAddPointConfig,
} from './drawings-geometry-temp-entity';
import {
  DrawingsGeometryTempEntityWithStroke,
  DrawingsGeometryTempEntityWithStrokeConfig,
} from './drawings-geometry-temp-entity-with-stroke';

export class DrawingsGeometryTempEntityPolyline extends DrawingsGeometryTempEntityWithStroke {
  private _thicknessShape: PolylineThickness;
  private _renderThicknessExecutor: DeferredExecutor = new DeferredExecutor(50);

  constructor(config: DrawingsGeometryTempEntityWithStrokeConfig) {
    super(config);
    this._config.newDrawingStylesObserver.subscribe(this.updateParams);
    this._config.textRenderParametersObserver.subscribe(this.updateParams);
  }

  public override destroy(): void {
    super.destroy();
    this._config.newDrawingStylesObserver.unsubscribe(this.updateParams);
    this._config.textRenderParametersObserver.unsubscribe(this.updateParams);
    if (this._thicknessShape) {
      this._thicknessShape.destroy();
    }
  }

  public override tryAddPoint(point: paper.Point, config: TryAddPointConfig): boolean {
    const addPointResult = super.tryAddPoint(point, config);
    this.renderThickness(false);
    return addPointResult;
  }

  public override removeLastPoint(): paper.Point | null {
    const removePointResult = super.removeLastPoint();
    this.renderThickness(false);
    return removePointResult;
  }

  public override updateTempPointPosition(point: paper.Point, strictAngle?: boolean): paper.Point {
    const updatedPoint = super.updateTempPointPosition(point, strictAngle);
    this.renderThickness(false);
    return updatedPoint;
  }

  public override convert(
    addLastPoint: boolean,
    instanceType: DrawingsInstanceType,
  ): FinishedDrawingElement[] {
    const convertedBase = super.convert(addLastPoint, instanceType);
    const { polylineThickness, polylineHeight } = this._config.newDrawingStylesObserver.getContext();
    const thicknessIsDefined = polylineThickness !== undefined && polylineThickness !== null;
    const heightIsDefined = polylineHeight !== undefined && polylineHeight !== null;
    if (thicknessIsDefined || heightIsDefined) {
      convertedBase.forEach(x => {
        if (thicknessIsDefined) {
          (x.geometry as DrawingsPolylineGeometry).thickness = polylineThickness;
        }
        if (heightIsDefined) {
          (x.geometry as DrawingsPolylineGeometry).height = polylineHeight;
        }
      });
    }
    return convertedBase;
  }

  @autobind
  private updateParams(): void {
    this.renderThickness(true);
  }

  @autobind
  private renderThickness(withDelay: boolean): void {
    const render = (): void => {
      if (this._thicknessShape) {
        this._thicknessShape.destroy();
        this._thicknessShape = null;
      }
      const { polylineThickness: thickness } = this._config.newDrawingStylesObserver.getContext();
      const { showThickness } = this._config.textRenderParametersObserver.getContext();
      if (!showThickness || !thickness || this._path.segments.length < 2) {
        return;
      }
      const { scale, metersPerPixel } = this._config.textRenderParametersObserver.getContext();
      const thicknessInPx = thickness / scale / metersPerPixel;
      this._thicknessShape = new PolylineThickness({
        geometry: this._path.segments,
        layer: this._config.layer,
        color: this._config.color.thickness,
        offset: thicknessInPx / 2,
        renderParamsContextObserver: this._config.renderParametersContextObserver,
      });
    };

    this._renderThicknessExecutor.reset();
    if (withDelay) {
      this._renderThicknessExecutor.execute(() => render());
    } else {
      render();
    }
  }
}
