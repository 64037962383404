import * as React from 'react';

export const KreoIconRelease: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
    >
      <path
        d='M10.14,7.41c-1.76,1.79-4,2.45-5,1.47s-.37-3.21,1.39-5,4-2.45,5-1.47.37,3.21-1.39,5Z'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeMiterlimit='10'
      />
      <path
        d='M7.09,3.37,5.33,3a1,1,0,0,0-.9.28L3.32,4.4a1,1,0,0,0,.51,1.69l1.11.22'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeMiterlimit='10'
      />
      <path
        d='M7.72,9.06,8,10.21a1,1,0,0,0,1.69.51l1.11-1.11a1,1,0,0,0,.27-.9l-.37-1.86'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeMiterlimit='10'
      />
      <path
        d='M9.72,5.73a1,1,0,1,0-1.4,0,1,1,0,0,0,1.4,0Z'
        fill='none'
        stroke='currentColor'
        strokeMiterlimit='10'
      />
      <line
        x1='2.5'
        y1='11.5'
        x2='3.92'
        y2='10.08'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='2.5'
        y1='9.5'
        x2='3.5'
        y2='8.5'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='4.5'
        y1='11.5'
        x2='5.5'
        y2='10.5'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>);
};
