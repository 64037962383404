import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


export const EmptyNotification: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.ImgContainer>
        <img src='/static/img/notification.png' />
      </Styled.ImgContainer>
      <Styled.TextContainer>
        <Text>No notifications</Text>
      </Styled.TextContainer>
    </Styled.Container>
  );
};
