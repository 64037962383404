import * as React from 'react';

export const KreoIconTopDatabases: React.FC = () => {
  return (
    <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='icon_databases' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect id='Rectangle' x='0' y='0' width='20' height='20' />
        <path
          // eslint-disable-next-line max-len
          d='M15,10.3652073 L15,9.11944856 C13.7742985,9.6952957 12.0481981,10 10,10 C7.95180189,10 6.22570154,9.6952957 5,9.11944856 L5,10.3652073 C8.18518384,11.766808 11.8148162,11.766808 15,10.3652073 Z M15,12.5216877 C11.774629,13.7146479 8.22537104,13.7146479 5,12.5216877 L5,13.5 C5,13.7360485 5.27348612,14.0500916 5.95678246,14.3541295 C6.87567715,14.7629987 8.26897889,15 10,15 C11.7310211,15 13.1243229,14.7629987 14.0432175,14.3541295 C14.7265139,14.0500916 15,13.7360485 15,13.5 L15,12.5216877 Z M17,10.9783361 C17.0002926,10.9922814 17.0002916,11.0062115 17,11.0201174 L17,13.5 C17,15.7391689 14.1664016,17 10,17 C5.83359837,17 3,15.7391689 3,13.5 L3,11.0201174 C2.99970843,11.0062115 2.99970743,10.9922814 3,10.9783361 L3,6.5 C3,4.26083113 5.83359837,3 10,3 C14.1664016,3 17,4.26083113 17,6.5 L17,10.9783359 Z M10,8 C11.7310211,8 13.1243229,7.76299866 14.0432175,7.35412949 C14.7265139,7.05009159 15,6.73604853 15,6.5 C15,6.26395147 14.7265139,5.94990841 14.0432175,5.64587051 C13.1243229,5.23700134 11.7310211,5 10,5 C8.26897889,5 6.87567715,5.23700134 5.95678246,5.64587051 C5.27348612,5.94990841 5,6.26395147 5,6.5 C5,6.73604853 5.27348612,7.05009159 5.95678246,7.35412949 C6.87567715,7.76299866 8.26897889,8 10,8 Z'
          id='icon'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
