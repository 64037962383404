import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback, useState } from 'react';

import { MetricNames, useAnalytics } from 'utils/posthog';
import { Styled } from './styled';
import { useCopy } from './use-copy';

enum LikeState {
  Like = 'like',
  Dislike = 'dislike',
}

enum ActionsOnResult {
  Copy = 'Copy',
  Like = 'Like',
  Dislike = 'Dislike',
}

interface Props {
  content: string;
}

const ButtonsComponent: React.FC<Props> = ({
  content,
}) => {

  const { sendEvent } = useAnalytics();

  const [likeState, setLikeState] = useState<LikeState>(null);

  const handleLikeClick = useCallback(() => {
    sendEvent(MetricNames.twoDCopilot.actionOnResult, { action: ActionsOnResult.Like });
    setLikeState(LikeState.Like);
  }, []);

  const handleDislikeClick = useCallback(() => {
    sendEvent(MetricNames.twoDCopilot.actionOnResult, { action: ActionsOnResult.Dislike });
    setLikeState(LikeState.Dislike);
  }, []);

  const copyToClipboard = useCopy(content);

  const handleCopyClick = useCallback(() => {
    sendEvent(MetricNames.twoDCopilot.actionOnResult, { action: ActionsOnResult.Copy });
    copyToClipboard();
  }, [sendEvent]);

  return (
    <Styled.Buttons>
      <RectangleButton
        text={'Copy'}
        onClick={handleCopyClick}
        fontSize={12}
        mood={'default'}
        Icon={Icons.CopySmall}
      />
      <RectangleButton
        text={'Like'}
        onClick={handleLikeClick}
        mood={likeState === LikeState.Like ? 'secondary' : 'default'}
        fontSize={12}
        Icon={Icons.LikeSmall}
      />
      <RectangleButton
        text={'Dislike'}
        onClick={handleDislikeClick}
        mood={likeState === LikeState.Dislike ? 'secondary' : 'default'}
        fontSize={12}
        Icon={Icons.Dislike}
      />
    </Styled.Buttons>
  );
};


export const Buttons = React.memo(ButtonsComponent);
