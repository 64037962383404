import autobind from 'autobind-decorator';
import * as React from 'react';

import './creator.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { IconButton, KreoButton, KreoIconAddedDone, MaterialInput } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';

interface Props {
  placeholder?: string;
  isWaiting?: boolean;
  allowCreate?: (value: string) => boolean;
  onCreate: (value: string) => void;
}

interface State {
  value: string;
  isCreating: boolean;
}

export class Creator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: '',
      isCreating: false,
    };
  }

  public render(): JSX.Element {
    const { value, isCreating } = this.state;
    const disabled = this.props.allowCreate ? this.props.allowCreate(value) : false;

    return (
      <div
        className='creator'
        onClick={this.onClick}
      >
        {
          this.props.isWaiting ?
          <SvgSpinner size='small' /> : (
            isCreating ?
          <div className='creator__container'>
            <MaterialInput
              value={value}
              onChange={this.onChange}
              placeholder={this.props.placeholder}
              className='creator__material-input'
              disabled={this.props.isWaiting}
              displayedType={MaterialComponentType.Native}
              sizeSmall={true}
            />
            <IconButton
              size='small'
              onClick={this.onCreate}
              disabled={disabled}
              className='creator__add-btn'
              controlName='add-database-button'
            >
              <KreoIconAddedDone />
            </IconButton>
          </div> :
          <KreoButton
            className='creator__button'
            size='small'
            mode='add'
            caption='Create new'
            onClick={this.setCreateMode}
            controlName='create-database-button'
          />
          )
        }
      </div>
    );
  }

  @autobind
  private onClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
  }

  @autobind
  private onChange(_event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
    this.setState({
      value: newValue,
    });
  }

  @autobind
  private setCreateMode(): void {
    this.setState({
      isCreating: true,
    });
  }

  @autobind
  private onCreate(): void {
    if (this.props.onCreate && this.state.value) {
      this.props.onCreate(this.state.value);
    }

    this.setState({
      isCreating: false,
      value: '',
    });
  }
}
