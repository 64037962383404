import { Canceler } from 'axios';
import { DatadogLogger } from 'common/environment/datadog-logger';
import { PdfHelper, PdfReaders } from 'common/pdf';
import { DrawingsApi } from '../api/drawings-api';
import { DocsLoadSettings, DocsLoader } from '../interfaces/util';

interface ProgressInfo {
  loaded: number;
  total: number;
  lengthComputable: boolean;
}

type ProgressCallback = (progress: { loaded: number, total: number }, drawingId) => void;

export class PdfLoader implements DocsLoader {
  private _cancelers: Map<string, Canceler> = new Map();
  private _progressCallback: ProgressCallback;

  constructor(progressCallback?: ProgressCallback) {
    this._progressCallback = progressCallback;
  }

  public loadFileAsDoc(settings: DocsLoadSettings): Promise<Core.PDFNet.PDFDoc> {
    return new Promise(resolve => {
      const { fileId, pageNumber, isOptimized, drawingId, isFiltered } = settings;
      const operationKey = this.buildOperationKey(settings);
      const progessCallback = (p: ProgressInfo): void => {
        this.onProgress(p, drawingId);
      };
      if (this._progressCallback) {
        this._progressCallback({ total: 100, loaded: 0 }, drawingId);
      }
      {
        const [promise, canceler] = Number.isInteger(pageNumber)
          ? DrawingsApi.getPage({
            fileId,
            pageNumber,
            drawingId,
            isFiltered,
            onProgress: progessCallback,
            rasterized: isOptimized,
          })
          : DrawingsApi.getFile(fileId, progessCallback);
        this._cancelers.set(operationKey, canceler);
        DatadogLogger.log('PdfLoader.loadFileAsDoc', settings);
        promise.then(x => {
          DatadogLogger.log('PdfLoader.loadFileAsDoc: loaded', settings);
          this._cancelers.delete(operationKey);
          PdfHelper.runAfterInit(PdfReaders.readArrayBuffer, x).then(document => {
            DatadogLogger.log('PdfLoader.loadFileAsDoc: converted to PDFDoc ', settings);
            resolve(document);
          });
        });
      }
    });
  }

  public async loadFileAsCore(settings: DocsLoadSettings): Promise<Core.Document> {
    const doc = await this.loadFileAsDoc(settings);
    const operationKey = this.buildOperationKey(settings);
    const converted = await PdfReaders.convertDocToCore(doc, operationKey);
    return converted;
  }

  public async getFilesData(settings: DocsLoadSettings[]): Promise<Core.PDFNet.PDFDoc[]> {
    return Promise.all(settings.map(x => this.loadFileAsDoc(x)));
  }

  public cancel(): void {
    DatadogLogger.log('PdfLoader.cancel', { cancelersCount: this._cancelers.size });
    this._cancelers.forEach(cancel => cancel());
    this._cancelers.clear();
  }

  private buildOperationKey({ fileId, pageNumber }: DocsLoadSettings): string {
    return `${fileId}_${pageNumber}`;
  }

  private onProgress(info: ProgressInfo, drawingId: string): void {
    if (this._progressCallback) {
      if (info.lengthComputable) {
        this._progressCallback(info, drawingId);
      }
    }
  }
}
