
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironmentConfiguration } from 'common/environment/use-environment-configuration';
import { State } from 'common/interfaces/state';
import { OpenReplaySessionTracker } from './openreplay-session-tracker';

export const OpenReplay: React.FC = () => {
  const email = useSelector<State, string>(state => state.account.email);

  const config = useEnvironmentConfiguration();

  const tracker = useMemo(() => {
    OpenReplaySessionTracker.setKey(config.openReplayApiKey);
    return OpenReplaySessionTracker.getInstance();
  }, []);

  useEffect(() => {
    if (email && tracker) {
      tracker.setUserID(email);
      tracker.getSessionURL();
    }
  }, [tracker, email]);

  return null;
};
