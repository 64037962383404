import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Blanket = styled.div`
  opacity: 0.3;
  background-color: black;
  z-index: 100000;
  left: 0px;
  top: 0px;
  position: fixed;
  width: 100vw;
  height: 100vh;
`;

const Panel = styled.div`
  z-index: 100000;
  position: fixed;
  top: 80px;
  left: 50%;
  font-size: 14px;
  transform: translateX(-50%);
  background-color: ${Constants.Colors.GENERAL_COLORS.red};
  color: white;
  padding: 10px 15px;
  border-radius: 25px;
  box-shadow: 0px 15px 40px #00000029;

  display: flex;
  align-items: center;

  > button {
    background-color: ${Constants.Colors.GENERAL_COLORS.darkBlue};
    margin-left: 20px;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    fill: white;
  }
`;

export const Styled = {
  Blanket,
  Panel,
};
