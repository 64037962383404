import { UnitTypes } from 'common/utils/unit-util';
import { FormatTypeGuards } from 'unit-2d-database/helpers/format-typeguards';
import { BaseFormat, Property, PropertyFormatEnum } from 'unit-2d-database/interfaces';

const breakDownPropertyRegex = /(.*) {(\d+)}/;

export const isPropertyBreakDownProperty = (name: string): [string, number] => {
  const regExec = breakDownPropertyRegex.exec(name);
  return regExec
    ? [regExec[1], Number(regExec[2])]
    : [name, undefined];
};


export function getFormatedValue(value: string | number | boolean, property: Property): string | number {
  if (typeof value === 'boolean') {
    return value.toString();
  }

  return property.value.format.type !== PropertyFormatEnum.Text && !Number.isNaN(Number(value))
    ? Number(value)
    : value;
}


export function convertValue(format: BaseFormat, value: number): number {
  if (FormatTypeGuards.isNumeric(format) && format.unit === UnitTypes.Percentage) {
    return value * 100;
  } else {
    return value;
  }
}
