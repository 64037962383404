const prefix = '@drawings-text-search';

export const DrawingsTextSearchActionTypes = {
  SET_QUERY: `${prefix}/SET_QUERY`,
  TOGGLE_CASE_SENSITIVE: `${prefix}/TOGGLE_CASE_SENSITIVE`,
  TOGGLE_WHOLE_WORD: `${prefix}/TOGGLE_WHOLE_WORD`,
  TOGGLE_REG_EXP: `${prefix}/TOGGLE_REG_EXP`,
  SAVE_RESULTS: `${prefix}/SAVE_RESULTS`,
  SET_IS_LOADING: `${prefix}/SET_IS_LOADING`,
  SET_IS_ERROR: `${prefix}/SET_IS_ERROR`,
  UPDATE_PAGES_SETTING: `${prefix}/UPDATE_PAGES_SETTING`,
  SET_PAGE_TEXT_RECTS: `${prefix}/SET_PAGE_TEXT_RECTS`,
  SET_IS_TEXT_LOADING: `${prefix}/SET_IS_TEXT_LOADING`,
  SET_TEXT_SEARCH_ACTIVE: `${prefix}/SET_TEXT_SEARCH_ACTIVE`,
};

