import { Avatar, UsersList } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ElementTooltip } from '../tooltip';
import { Company, UserInfo } from './project-card';
import { Styled } from './styled';


const AVATAR_SIZE = 50;

interface Props {
  isShareWithCompany: boolean;
  users: UserInfo[];
  isTranslucent: boolean;
  company?: Company;
}

export const AvatarContainer: React.FC<Props> = React.memo<Props>(({
  company,
  isShareWithCompany,
  users,
  isTranslucent,
}: Props) => {
  return (
    <Styled.AvatarsContainer
      height={AVATAR_SIZE}
      isTranslucent={isTranslucent}
      isUsers={!!users?.length}
    >
      {isShareWithCompany && (
        <ElementTooltip
          text={'Available to the whole company'}
          position='bottom'
          wordBreakAll={true}
        >
          <Avatar avatar={company.image} size={AVATAR_SIZE} name={company.name} />
        </ElementTooltip>
      )}
      {!!users?.length && <UsersList maxUsers={4} users={users} isTranslucent={isTranslucent} />}
    </Styled.AvatarsContainer>
  );
});
