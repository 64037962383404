import autobind from 'autobind-decorator';
import * as React from 'react';

import { DrawingsSVGIcons } from '../../constants/drawings-svg-icons';
import { Styled } from './drawings-svg-menu-item-styled';

interface Props {
  iconId: DrawingsSVGIcons;
  multicolor: boolean;
  onSelect: (iconId: DrawingsSVGIcons) => void;
}

export class DrawingsSvgMenuItem extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const ImageContainer = this.props.multicolor ? Styled.ImageMulticolor : Styled.ImageMonochrome;
    return (
      <Styled.Container onClick={this.onClick}>
        <ImageContainer code={this.props.iconId}/>
      </Styled.Container>
    );
  }

  @autobind
  private onClick(): void {
    this.props.onSelect(this.props.iconId);
  }
}
