import {
  AgGridEvent,
  ColDef,
  ColumnRowGroupChangedEvent,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  MenuItemDef,
  RowNode,
  SideBarDef,
  ToolPanelDef,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { merge } from 'lodash';
import React from 'react';

import './qto-common-table.scss';

import { AgGridSyncSelectHelper } from 'common/ag-grid/ag-grid-sync-select-helper';
import { EMPTY_VALUE } from 'common/components/tree-filter-panel/constants';
import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { SelectionHelper } from 'common/components/tree-table/selection-helper';
import { VideoUrl } from 'common/constants/video-url';
import { AgGridDataTransferExporter } from 'common/utils/ag-grid-data-transporter';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { HelpIconDialog } from '../../../../components/help-icon-dialog';
import { QtoSelectElementsEventSource } from '../../enums/qto-select-elements-event-source';
import { locationAggFunction } from '../quantity-take-off-left-panel/get-column';
import { Context, RowData, TableApi } from '../quantity-take-off-left-panel/interfaces';
import { QtoCommonTableConstants } from './constants';
import { AGG_HASH } from './custom-cell/aggregation-cell/aggregation-cell';
import {
  getAggregationCellExtractorsCount,
} from './custom-cell/aggregation-cell/aggregation-cell-value-getter';
import { ExpandHelper } from './expand-helper';
import { AgGridState, gridStateHelper, SaveStateHelper } from './save-grid-state-helper';

const toolPanelColumnsProps = {
  id: 'columns',
  labelKey: 'columns',
  iconKey: 'columns',
  labelDefault: 'Breakdown manager',
  toolPanel: 'agColumnsToolPanel',
  toolPanelParams: {
    suppressValues: true,
    suppressPivots: true,
    suppressPivotMode: true,
    suppressColumnSelectAll: true,
  },
};

const HELP_BREAKDOWN_MANAGER_DIALOG_NAME = 'HELP_BREAKDOWN_MANAGER_DIALOG_NAME';

export interface GetRowParam {
  node: RowNode;
  context: Context;
}

const getRowHeight = (param: GetRowHeightParam): number => {
  const { rowHeight, aggregationQuantityRowHeight } = QtoCommonTableConstants;
  if (param.node.field === AGG_HASH) {
    const extractorsCount = getAggregationCellExtractorsCount(param.node);
    return rowHeight + extractorsCount * aggregationQuantityRowHeight;
  }

  return rowHeight;
};

function getRowClass(_params: GetRowParam): string {
  return 'qto-custom-elements-table-row';
}

export interface CommonElementTableProps {
  id?: string;
  expandTable?: boolean;
  dataExporters?: Array<AgGridDataTransferExporter<any>>;
  onElementTableSelected: (elementIds: Array<string | number>) => void;
  sendElementTableApi: (ref: TableApi) => void;
  getRowHeight?: (param: GetRowParam) => number;
  getRowClass?: (param: GetRowParam) => string;
  userExpand: () => void;
  selectionEventSource: QtoSelectElementsEventSource;
  columnDefs: ColDef[];
  rowData: RowData[];
  autoGroupColumnDef: ColDef;
  context: Context;
  name: string;
  toolPanelColumnsProps?: Partial<ToolPanelDef>;
  saveState?: (state: AgGridState) => void;
  getSaveState?: () => AgGridState;
  getContextItems?: (params: GetContextMenuItemsParams) => MenuItemDef[];
  onColumnRowGroupChanged?: (e: AgGridEvent) => void;
  onGridReady?: (api: GridApi) => void;
  onFirstDataRendered?: () => void;
  className?: string;
  isPageDataReady?: boolean;
}

interface GetRowHeightParam {
  node: RowNode;
  context: Context;
}

const isUndefinedValue = (value: string): boolean => value.toLowerCase() === EMPTY_VALUE;

const defaultGroupRowSortComparator = (nodeA: RowNode, nodeB: RowNode): number => {
  if (!nodeA.key || isUndefinedValue(nodeA.key)) {
    return 1;
  }

  if (!nodeB.key || isUndefinedValue(nodeB.key)) {
    return -1;
  }

  return nodeA.key.localeCompare(nodeB.key, undefined, { numeric: true, sensitivity: 'base' });
};

const TOOLTIP_SHOW_DELAY = 200;

export class QtoCommonElementTable extends React.PureComponent<CommonElementTableProps> {
  public static defaultProps: Partial<CommonElementTableProps> = {
    getRowHeight,
    getRowClass,
  };

  private readonly overlayLoadingTemplate: string =
    'All your data will appear in a few moments. Your model is loading!';

  private gridOptions: GridOptions = {};
  private syncHelper: AgGridSyncSelectHelper = new AgGridSyncSelectHelper();
  private saveStateHelper: SaveStateHelper = null;
  private executor: DeferredExecutor = new DeferredExecutor(3000);
  private highlightedElements: Record<string, RowNode> = {};
  private rowClassRules: Record<string, (params: any) => boolean> = {
    ['qto-custom-elements-table-row--highlighted']: params => params.node.id in this.highlightedElements,
  };
  private selectionHelper: SelectionHelper;
  private sideBarDef: SideBarDef;


  public constructor(props: CommonElementTableProps) {
    super(props);
    this.selectionHelper = new SelectionHelper(
      this.props.onElementTableSelected,
      (node) => {
        if (node.data) {
          return node.data.id;
        }
      },
    );
    this.saveStateHelper = gridStateHelper(
      {
        ...props,
        onRowGroupOpened: this.onRowGroupOpened,
      },
    );
    this.sideBarDef = {
      toolPanels: [
        props.toolPanelColumnsProps
          ? merge({}, props.toolPanelColumnsProps, toolPanelColumnsProps)
          : toolPanelColumnsProps,
      ],
    };
  }

  public render(): JSX.Element {
    const {
      id,
      columnDefs,
      rowData,
      autoGroupColumnDef,
      context,
    } = this.props;
    const autoGroupDef = this.appendDataTransferDef(autoGroupColumnDef);
    autoGroupDef.sortable = true;
    autoGroupDef.headerCheckboxSelectionFilteredOnly = true;
    return (
      <div
        id={id}
        className={classNames('ag-theme-balham', 'ag-theme-balham--kreo', 'qto-common-table', this.props.className)}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          autoGroupColumnDef={autoGroupDef}
          defaultColDef={QtoCommonTableConstants.defaultColDef}
          sideBar={this.sideBarDef}
          defaultGroupSortComparator={defaultGroupRowSortComparator}
          getRowHeight={this.props.getRowHeight}
          getRowClass={this.props.getRowClass}
          gridOptions={this.gridOptions}
          rowSelection='multiple'
          suppressRowClickSelection={true}
          suppressMakeColumnVisibleAfterUnGroup={true}
          context={context}
          onGridReady={this.onGridReady}
          onRowSelected={this.selectionHelper.rowSelectionHandler}
          getContextMenuItems={this.props.getContextItems}
          onRowGroupOpened={this.saveStateHelper.eventHandlers.onRowGroupOpened}
          onColumnRowGroupChanged={this.onColumnRowGroupChanged}
          onColumnVisible={this.saveStateHelper.eventHandlers.onColumnVisible}
          onColumnMoved={this.saveStateHelper.eventHandlers.onColumnMoved}
          onColumnResized={this.saveStateHelper.eventHandlers.onColumnResized}
          overlayLoadingTemplate={this.overlayLoadingTemplate}
          deltaColumnMode={true}
          aggFuncs={{ locationAggFunction }}
          suppressAggFuncInHeader={true}
          rowClassRules={this.rowClassRules}
          onFilterModified={this.onFilterModified}
          onFirstDataRendered={this.props.onFirstDataRendered}
          suppressCopyRowsToClipboard={true}
          tooltipShowDelay={TOOLTIP_SHOW_DELAY}
        />
        <HelpIconDialog
          className='qto-common-table__help-icon'
          video={true}
          url={VideoUrl.QtoBreakdownManager}
          dialogName={HELP_BREAKDOWN_MANAGER_DIALOG_NAME}
          title='Breakdown Manager Video Help'
        />
      </div>
    );
  }

  public componentDidUpdate(prevProps: CommonElementTableProps): void {
    const { api, columnApi } = this.gridOptions;
    ExpandHelper.handleExpandChange(prevProps, this.props, api, columnApi, this.saveStateHelper);
    if (this.props.rowData !== prevProps.rowData) {
      const state: AgGridState = this.props.getSaveState && this.props.getSaveState();
      if (state) {
        ExpandHelper.expandRows(api, state.nodes);
      }
    }

    if (this.props.isPageDataReady !== prevProps.isPageDataReady) {
      this.changeOverlay();
    }
  }

  public componentDidMount(): void {
    if (this.gridOptions && this.gridOptions.api) {
      this.handleAgGridStateChange();
    }
  }

  @autobind
  private getRowNodes(ids: Array<string | number>): RowNode[] {
    const nodes = [];
    this.gridOptions.api.forEachLeafNode(node => {
      if (ids.includes(node.data.id)) {
        nodes.push(node);
      }
    });
    return nodes;
  }

  private changeOverlay(): void {
    if (!this.gridOptions || !this.gridOptions.api) {
      return;
    }
    const displayedRowCount = this.gridOptions.api.getDisplayedRowCount();
    if (this.props.isPageDataReady) {
      this.gridOptions.api.showNoRowsOverlay();
      if (displayedRowCount) {
        this.gridOptions.api.hideOverlay();
      }
    } else {
      if (!displayedRowCount) {
        this.gridOptions.api.showLoadingOverlay();
      }
    }
  }

  private onFilterModified(event: FilterChangedEvent): void {
    event.api.deselectAll();
    const inputs = document.getElementsByClassName('ag-input-field-input ag-radio-button-input');
    for (const i in inputs) {
      if (!(inputs[i] as any).checked && inputs[i].parentElement) {
        inputs[i].parentElement.className = inputs[i].parentElement.className.replace('ag-checked', '');
      }
    }
  }

  @autobind
  private onColumnRowGroupChanged(event: ColumnRowGroupChangedEvent): void {
    if (this.selectionHelper) {
      this.selectionHelper.setPrevSelected();
    }

    this.saveStateHelper.eventHandlers.onColumnRowGroupChanged(event);
  }

  private handleAgGridStateChange(): void {
    const { api, columnApi } = this.gridOptions;
    const state: AgGridState = this.props.getSaveState && this.props.getSaveState();
    if (state) {
      columnApi.setColumnState(state.columns);
      ExpandHelper.expandRows(api, state.nodes);
    }
  }

  @autobind
  private onGridReady(event: GridReadyEvent): void {
    const api = event.api;
    this.selectionHelper.setApi(api);
    this.syncHelper.init(api, this.props.onElementTableSelected);
    this.props.sendElementTableApi({
      focusAndExpand: this.focusAndExpand,
      setSelected: this.setSelected,
      setColumnDefs: this.setColumnState,
      getColumnState: () => this.gridOptions.columnApi.getColumnState(),
      refreshCells: () => this.gridOptions.api.refreshCells({ force: true }),
      setSelectedAfterUpdateRecords: this.selectionHelper.setSelectedAfterUpdateRecords,
      getRowNodes: this.getRowNodes,
      getColumn: id => this.gridOptions.columnApi.getColumn(id),
      showLoadingCapture: show => show
        ? this.gridOptions.api.showLoadingOverlay()
        : this.gridOptions.api.showNoRowsOverlay(),
    });
    if (this.props.onGridReady) {
      this.props.onGridReady(api);
    }
    this.changeOverlay();
  }

  @autobind
  private setSelected(ids: Array<number | string>, isExpand?: boolean): void {
    this.selectionHelper.setSelected(ids, isExpand);
    this.props.userExpand();
  }

  @autobind
  private onRowGroupOpened(): void {
    this.props.userExpand();
  }

  @autobind
  private setColumnState(colsDefs: ColDef[]): void {
    const columns = this.gridOptions.columnApi.getColumnState();
    const updatedColDefs: ColDef[] = colsDefs.map(c => {
      const colState = columns.find(x => x.colId === c.field);
      c.hide = colState && colState.hide;
      return c;
    });
    this.gridOptions.api.setColumnDefs([]);
    this.gridOptions.api.setColumnDefs(updatedColDefs);
  }

  @autobind
  private focusAndExpand(ids: Array<string | number>): void {
    const idsSet = new Set(ids);
    const processedNodes: Record<string, boolean> = {};
    this.executor.executeImmediately();
    let nodeToShow;
    this.gridOptions.api.forEachLeafNode(node => {
      if (idsSet.has(node.data.id)) {
        this.highlightedElements[node.id] = node;
        while (!processedNodes[node.parent.id] && this.allChildsInSet(idsSet, node.parent)) {
          node = node.parent;
          this.highlightedElements[node.id] = node;
          processedNodes[node.id] = true;
        }
        if (!processedNodes[node.parent.id]) {
          nodeToShow = node;
          this.expandAllParent(node.parent);
        }
      }
    });

    if (nodeToShow !== undefined) {
      this.gridOptions.api.ensureNodeVisible(nodeToShow, 'middle');
    }
    this.gridOptions.api.redrawRows({
      rowNodes: Object.values(this.highlightedElements),
    });
    this.executor.execute(this.clearProccessedNodes);
  }

  @autobind
  private clearProccessedNodes(): void {
    const keys = Object.values(this.highlightedElements);
    this.highlightedElements = {};
    this.gridOptions.api.redrawRows({ rowNodes: keys });
  }

  private allChildsInSet(idsSet: Set<string | number>, parent: RowNode): boolean {
    for (const child of parent.allLeafChildren) {
      if (child.data.type === TreeTableRowType.Group) {
        continue;
      }
      if (!idsSet.has(child.data.id)) {
        return false;
      }
    }
    return true;
  }

  @autobind
  private expandAllParent(node: RowNode): void {
    if (node.level === -1) {
      return;
    }
    node.setExpanded(true);
    this.expandAllParent(node.parent);
  }

  @autobind
  private expandAllGroupChildren(node: RowNode): void {
    if (node.childrenAfterGroup[0].group) {
      node.expanded = true;
      node.childrenAfterGroup.forEach(n => this.expandAllGroupChildren(n));
    }
  }

  private appendDataTransferDef(autoGroupColumnDef: ColDef): ColDef {
    const { dataExporters } = this.props;

    return dataExporters && dataExporters.every(e => !!e)
      ? {
        ...autoGroupColumnDef,
        dndSource: true,
        dndSourceOnRowDrag: params => dataExporters
          .forEach(ex => ex.setExportData(params.rowNode, this.gridOptions.api, params.dragEvent)),
      }
      : autoGroupColumnDef;
  }
}
