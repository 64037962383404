import * as t from 'io-ts';

export const NrmPairInfoC = t.strict(
  {
    nrm1: t.number,
    nrm2: t.number,
    defaultActivityName: t.string,
  },
  'NrmPairInfo');

export type NrmPairInfo = t.TypeOf<typeof NrmPairInfoC>;
