import { useCallback, useState } from 'react';

export function useLayoutFocus(): { onFocus: () => void, onBlur: () => void, focused: boolean } {
  const [focused, setFocused] = useState(false);

  return {
    onFocus: useCallback(() => setFocused(true), []),
    onBlur: useCallback(() => setFocused(false), []),
    focused,
  };
}

