import { Location } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';

import './database-header.scss';

import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State as ReduxState } from 'common/interfaces/state';
import { RoutingContextProps, withRoutingContext } from 'common/routing/with-routing-context';
import { DatabaseModel } from '../../../units/databases/interfaces/data';
import { InternalHeaderMenuItem, InternalHeaderMenuWrapper } from '../InternalHeaderMenu';


interface ReduxProps {
  location: Location; // required for update InternalHeaderMenuItem
  currentDatabase: DatabaseModel;
}

interface Props extends ReduxProps, AbilityAwareProps, RoutingContextProps { }

class DatabaseHeaderComponent extends React.Component<Props> {

  public render(): JSX.Element {
    const { urls } = this.props;

    const menu = [
      (
      <InternalHeaderMenuItem
        key={0}
        link={urls.database.activities.url({
          dbId: this.props.currentDatabase && this.props.currentDatabase.id.toString(),
        })}
        name='Activities'
      />),
      (
      <InternalHeaderMenuItem
        key={1}
        link={urls.database.resources.url({
          dbId: this.props.currentDatabase && this.props.currentDatabase.id.toString(),
        })}
        name='Resources'
      />),
    ];

    return (
      <React.Fragment>
        <InternalHeaderMenuWrapper>
          {menu}
        </InternalHeaderMenuWrapper>
        {/* <div className='database-header__buttons'>
          {
            !isReadOnly ? (
              <a href={`/api/cmtdb/export_database/${currentId}`} download={true}>
                <KreoButton
                  className='database-header__btn-export'
                  size='medium'
                  caption='Export'
                />
              </a>
            ) : null
          }
        </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    currentDatabase: state.database.currentDatabase.database,
    location: state.router.location,
  };
};


const connector = connect(mapStateToProps);
export const DatabaseHeader = withRoutingContext(withAbilityContext(connector(DatabaseHeaderComponent)));
