import {
  Activity,
  ActivityDataLookup,
  ActivityGroup,
  AnimationDataResponse,
  GanttData,
  LineData,
} from '../../interfaces/gantt-chart';
import { ChartDataProvider, GroupType } from './chart-data-provider';


function isUndefinedConstructionMethod(constructionMethod: ActivityGroup | Activity): boolean {
  return constructionMethod.name.toLowerCase().startsWith('undefined');
}

const DAYS_PER_SHIFT = 8 / 24;

export const animationDataResponseMapper = {
  getGanttData: (animationData: AnimationDataResponse, dataProvider: ChartDataProvider): GanttData => {

    const workPackages = animationData.workPackages.map((workPackage): LineData => {
      const workPackageInfo = dataProvider.getGanttLineInfo(GroupType.WorkPackage, workPackage.id);
      const workPackageLineId = `workPackage-${workPackage.id}`;

      const activityGroups = workPackage.activityGroups.map((activityGroup): LineData => {
        const activityGroupInfo = dataProvider.getGanttLineInfo(GroupType.ActivityGroup, activityGroup.id);
        const activityGroupLineId = `activityGroup-${activityGroup.id}`;

        const activities = activityGroup.activities.map((activity): LineData => {
          const activityInfo = dataProvider.getGanttLineInfo(GroupType.Activity, activity.id);
          const activityLineId = `activity-${activity.id}`;

          return {
            lineId: activityLineId,
            entityId: activity.id,
            name: activity.name,
            type: GroupType.Activity,
            children: null,
            mainColor: activityInfo.colors.main,
            offColor: activityInfo.colors.off,
            activeDuration: activityInfo.activeDuration,
            startDay: activityInfo.startDay,
            isIsolatable: false,
            isExpanded: false,
            isExpandable: false,
            isUndefined: isUndefinedConstructionMethod(activity),
          };
        });

        return {
          lineId: activityGroupLineId,
          entityId: activityGroup.id,
          name: activityGroup.name,
          type: GroupType.ActivityGroup,
          children: activities.sort((a1, a2) => a1.startDay - a2.startDay),
          mainColor: activityGroupInfo.colors.main,
          offColor: activityGroupInfo.colors.off,
          activeDuration: activityGroupInfo.activeDuration,
          startDay: activityGroupInfo.startDay,
          isIsolatable: true,
          isExpanded: false,
          isExpandable: false,
          isUndefined: isUndefinedConstructionMethod(activityGroup),
          isCritical: activityGroup.total_slack < DAYS_PER_SHIFT,
        };
      });

      return {
        lineId: workPackageLineId,
        entityId: workPackage.id,
        name: workPackage.name,
        isExpanded: false,
        children: activityGroups.sort((a1, a2) => a1.startDay - a2.startDay),
        type: GroupType.WorkPackage,
        mainColor: workPackageInfo.colors.main,
        offColor: workPackageInfo.colors.off,
        activeDuration: workPackageInfo.activeDuration,
        startDay: workPackageInfo.startDay,
        isIsolatable: true,
        isExpandable: true,
      };
    });

    return {
      workPackages: workPackages.sort((first, second) => first.startDay - second.startDay),
    };
  },
  getActivityDataLookup: (animationData: AnimationDataResponse): ActivityDataLookup => {
    const activities = [];
    for (const workPackage of animationData.workPackages) {
      for (const activityGroup of workPackage.activityGroups) {
        activities.push(...activityGroup.activities);
      }
    }

    const lookup: ActivityDataLookup = {};

    for (const activity of activities) {
      lookup[activity.id] = {
        bimId: activity.geoId,
        id: activity.id,
        name: activity.name,
        nrm1: activity.nrm1,
        nrm2: activity.nrm2,
        shortName: activity.shortName,
        up: activity.up,
        us: activity.us,
        expanded: false,
      };
    }

    return lookup;
  },
};
