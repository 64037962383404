import { CodecUtils } from 'common/utils/codec-utils';

export enum Takeoff2dRoleCode {
  Administrator = 'Administrator',
  QuantitySurveyor = 'Quantity Surveyor',

  Client = 'Client',
}

export enum PlanRoleCode {
  Administrator = 'Administrator',
  ProjectManager = 'ProjectManager',
  BimManager = 'BimManager',
  CostEstimator = 'CostEstimator',
  Planner = 'Planner',
  BidManager = 'BidManager',
  EmployeeCustom = 'EmployeeCustom',

  BimConsultant = 'BimConsultant',
  CostConsultant = 'CostConsultant',
  ConstructionConsultant = 'ConstructionConsultant',
  Client = 'Client',
  Architect = 'Architect',
  GuestCustom = 'GuestCustom',

  Subcontractor = 'Subcontractor',
}


export enum RoleCode {
  Administrator = 'Administrator',
  ProjectManager = 'ProjectManager',
  BimManager = 'BimManager',
  CostEstimator = 'CostEstimator',
  Planner = 'Planner',
  BidManager = 'BidManager',
  EmployeeCustom = 'EmployeeCustom',

  BimConsultant = 'BimConsultant',
  CostConsultant = 'CostConsultant',
  ConstructionConsultant = 'ConstructionConsultant',
  Guest = 'Guest',
  Client = 'Client',
  Architect = 'Architect',
  GuestCustom = 'GuestCustom',

  Subcontractor = 'Subcontractor',

  Editor = 'Editor',
}

export const RoleCodeC = CodecUtils.createEnumCodec<RoleCode>(RoleCode, 'RoleCode');
