import styled from 'styled-components';

const ShareButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  > button {
    width: 120px;
    height: 24px;
    border-radius: 12px 0 0 12px;

    > svg {
      fill: ${p => p.theme.color.white};
      width: 18px;
      height: 18px;
    }

    > p {
      color: ${p => p.theme.color.white};
      font-size: 14px;
    }

    :hover {
      p {
        color: ${p => p.theme.color.white} !important;
      }
    }
  }
`;

export const Styled = {
  ShareButtonContainer,
};
