import classNames from 'classnames';
import * as React from 'react';

import './revit-tree-base-item.scss';

import { ExpandButton } from 'common/UIKit';

interface Props {
  className?: string;
  expandable: boolean;
  expanded: boolean;
  title: string;
  level: number;
  onExpand: (e: React.MouseEvent<HTMLDivElement>) => void;
  mouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void;
  mouseOut?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onDoubleClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const RevitTreeBaseItem: React.FC<Props> = (props) => {
  return (
    <div
      style={{ marginLeft: 10 * props.level }}
      className={classNames('revit-tree-base-item', props.className)}
      onMouseOver={props.mouseOver}
      onMouseOut={props.mouseOut}
      onClick={props.onClick}
      data-control-name='revit-tree-item'
      onDoubleClick={props.onDoubleClick}
    >
      <ExpandButton
        expandable={props.expandable}
        expanded={props.expanded}
        onExpand={props.onExpand}
      />
      <div className='revit-tree-base-item__title' title={props.title}>
        {props.title}
      </div>
      {
        props.children
      }
    </div>
  );
};
