import { CompanyResourcesApi } from '../../../api/server';
import { CompanyProjectHeader } from '../interfaces/company-project-header';
import { ModelManagementData, ModelManagementDataC } from '../interfaces/model-management/model-management-data';

function loadData(projectId: number): Promise<ModelManagementData> {
  return CompanyResourcesApi.getV(`/projects/${projectId}/source-files`, ModelManagementDataC);
}

function updateFiles(
  projectId: number,
  data: { filesToAdd: string[], filesToDelete: number[] },
): Promise<CompanyProjectHeader> {
  return CompanyResourcesApi.put(`/projects/${projectId}`, data);
}

export const ModelManagementApi = {
  loadData,
  updateFiles,
};

