import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  max-height: 250px;
  overflow: auto;
  max-width: 300px;
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 20px;
`;

const FolderContainer = styled.div<{ nestedLevel: number }>`
  margin-left: ${p => p.nestedLevel * 20}px;
  & + & {
    margin-top: 15px;
  }
`;

export const Styled = {
  Container,
  FolderContainer,
};
