import * as React from 'react';

import './classification-header-breadcrumbs-container.scss';

import { ClassificationHeaderBreadcrumb } from '../classification-header-breadcrumb';


interface Props {
  breadCrumbsCount: number;
  onBreadcrumbSelect: (callback: () => void) => void;
}

export class ClassificationHeaderBreadcrumbsContainer extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <div className='classification-header-breadcrumbs-container'>
        {this.renderBreadCrumbs()}
      </div>
    );
  }

  private renderBreadCrumbs(): React.ReactNode[] {
    const result = new Array<React.ReactNode>(this.props.breadCrumbsCount);
    for (let i = 0; i < this.props.breadCrumbsCount; i++) {
      result[i] = (
        <ClassificationHeaderBreadcrumb
          onBreadcrumbSelect={this.props.onBreadcrumbSelect}
          key={i}
          index={i}
        />);
    }
    return result;
  }
}

