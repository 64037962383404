import autobind from 'autobind-decorator';
import * as React from 'react';

import './filter-panel-option.scss';

import { KreoIconCloseSmall } from 'common/UIKit';
import { FilterType } from '../../filter-type';
import { SimpleOption, TimeInterval } from '../../interfaces';
import { UserActivityUtil } from '../../user-activity-util';

interface Props {
  type?: FilterType;
  option?: SimpleOption;
  timeInterval?: TimeInterval;
  deleteFilterItem: (type: FilterType, optionId: React.ReactText) => void;
}

export class FilterPanelOption extends React.Component<Props> {
  public render(): JSX.Element {
    const { option, timeInterval } = this.props;
    return (
      <div className='activity-filter-panel-option'>
        {option ? option.name : UserActivityUtil.displayInterval(timeInterval)}
        <div className='activity-filter-panel-option__close' onClick={this.onDelete}>
          <KreoIconCloseSmall />
        </div>
      </div>
    );
  }

  @autobind
  private onDelete(): void {
    const { option, type } = this.props;
    this.props.deleteFilterItem(type, option ? option.id : null);
  }
}
