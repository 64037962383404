import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { NoScaleDescriptionType } from '../dialogs';
import { useShowCalibrationDialogIfNeed } from './calibration/use-show-dialog-if-need';

export function useTurnOnBucketFillEffect(): (skip: () => void) => void {
  const showCalibrationIfNeeded = useShowCalibrationDialogIfNeed();

  const isAIBoostEnabled = useSelector<State, boolean>(state => state.drawings.wizzardTools.boostWithAi);

  return useCallback(
    (skip: () => void) => {
      if (!isAIBoostEnabled) {
        skip();
      } else {
        showCalibrationIfNeeded(skip, NoScaleDescriptionType.BucketFIll);
      }
    },
    [isAIBoostEnabled, showCalibrationIfNeeded],
  );
}
