import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './invitations.scss';

import { GetPackageCost } from '../../../actions/payloads/bid-pricing';
import { BidPricingUserCost } from '../../../interfaces/bid-pricing/bid-pricing-user-cost';
import { BidPricingUserPackage } from '../../../interfaces/bid-pricing/bid-pricing-user-package';
import { BidPricingWorkPackage } from '../../../interfaces/bid-pricing/bid-pricing-work-package';
import { DefaultItem } from './default-item';
import Header from './header';
import { InviteLine } from './invite-line';
import { NewBid } from './new-bid';
import { SubcontractorBid } from './subcontractor-bid';
import { SubContractors } from './subcontractors';
import { WorkPackages } from './work-packages';

interface Props {
  fixedHeader: boolean;
  bidPricingPackages?: BidPricingWorkPackage[];
  userPackages?: BidPricingUserPackage[];
  isBidPricingActive: boolean;
  isSubcontractor?: boolean;
  onWin?: (userId: string, workPackageId: number, constructionCostId: number) => void;
  onCancelRequest?: (email: string, workPackageId: number) => void;
  goToPackage?: (data: GetPackageCost) => void;
  onDeleteSubcontractorBid?: (constructionCostId: number, workPackageId: number) => void;
  addNewBid?: (workpackageId: number) => void;
  openWorkpackageDialog(workPackage: BidPricingWorkPackage): void;
}

export class Invitations extends React.Component<Props> {
  private static context: React.Context<boolean> = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      showMoreSubcontractors: false,
    };
  }

  public static getContext(): React.Context<boolean> {
    if (this.context === null) {
      this.context = React.createContext(null);
    }
    return this.context;
  }

  public render(): React.ReactNode {
    const Context = Invitations.getContext();
    return (
      <div
        className={classNames('bid-pricing-invitations', {
          'bid-pricing-invitations--wp': this.props.isSubcontractor,
        })}
      >
        <div className='bid-pricing-invitations__table'>
          <Header fixed={this.props.fixedHeader} isSubcontractor={this.props.isSubcontractor} />
          <Context.Provider value={this.props.isBidPricingActive}>
            {this.buildValues(this.props.isBidPricingActive)}
          </Context.Provider>
        </div>
      </div>
    );
  }

  @autobind
  private buildValues(isBidPricingActive: boolean): React.ReactNode {
    const { bidPricingPackages, userPackages, isSubcontractor } = this.props;
    if (!isSubcontractor) {
      return bidPricingPackages.map(workpackage => {
        const scLength = workpackage.usersCost.length;
        return (
          <div className='bid-pricing-invitations__row' key={workpackage.id}>
            <WorkPackages
              workPackageName={workpackage.name}
              workPackageInnerId={workpackage.workPackageInnerId}
            />
            <div className='bid-pricing-invitations__inner-table'>
              <DefaultItem
                workpackage={workpackage}
                isSubcontractor={isSubcontractor}
                onWin={this.props.onWin}
                isBidPricingActive={isBidPricingActive}
              />
              {scLength ? (
                <div className='bid-pricing-invitations__subcontractors-wrap'>
                  <SubContractors
                    workpackage={workpackage}
                    onWin={this.props.onWin}
                    onCancelRequest={this.props.onCancelRequest}
                    onItemClick={this.gotToPackagePage}
                    onDeleteSubcontractorBid={this.props.onDeleteSubcontractorBid}
                  />
                </div>
              ) : null}
              <InviteLine
                workPackage={workpackage}
                isBidPricingActive={isBidPricingActive}
                openWorkpackageDialog={this.props.openWorkpackageDialog}
              />
            </div>
          </div>
        );
      });
    } else {
      return userPackages.map(workpackage => {
        return (
          <div className='bid-pricing-invitations__row' key={workpackage.id}>
            <WorkPackages
              workPackageName={workpackage.name}
              workPackageInnerId={workpackage.workPackageInnerId}
            />
            <div className='bid-pricing-invitations__inner-table'>
              <DefaultItem isSubcontractor={isSubcontractor} isBidPricingActive={isBidPricingActive} />
              {workpackage.costs &&
                workpackage.costs.map(cost => (
                  <SubcontractorBid
                    key={cost.id}
                    onClick={this.gotToPackagePage}
                    contractorCost={cost}
                  />
                ))}
              <NewBid addNewBid={this.props.addNewBid} workPackageId={workpackage.id} />
            </div>
          </div>
        );
      });
    }
  }

  @autobind
  private gotToPackagePage(subcontractorCost: BidPricingUserCost): void {
    if (subcontractorCost.id > 0) {
      this.props.goToPackage({
        subcontractorCost,
        mode: this.props.isSubcontractor ? 'edit' : 'view',
      });
    }
  }
}
