import styled from 'styled-components';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  textarea {
    max-height: 84px;
    padding-top: 5px;
    font-size: 12px;
    line-height: 14px;
    min-height: 48px;
  }
`;

const MessageContentTitle = styled.div`
  display: flex;
  flex-direction: row;

  span {
    color: ${p => p.theme.color.gray};
  }
`;

const ThankYou = styled.div`
  height: 16px;
`;

export const Styled = {
  ContentContainer,
  MessageContentTitle,
  ThankYou,
};
