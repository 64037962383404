import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconMenuCollapsed = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <g
      id='Icons/24x24/Menu_Collapsed'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path
        // eslint-disable-next-line max-len
        d='M8,5.5 L8,5.5 C9.38071187,5.5 10.5,6.61928813 10.5,8 L10.5,8 C10.5,9.38071187 9.38071187,10.5 8,10.5 L8,10.5 C6.61928813,10.5 5.5,9.38071187 5.5,8 L5.5,8 C5.5,6.61928813 6.61928813,5.5 8,5.5 Z'
        id='Rectangle'
        stroke={KreoColors.f4}
      />
      <path
        // eslint-disable-next-line max-len
        d='M16,5.5 L16,5.5 C17.3807119,5.5 18.5,6.61928813 18.5,8 L18.5,8 C18.5,9.38071187 17.3807119,10.5 16,10.5 L16,10.5 C14.6192881,10.5 13.5,9.38071187 13.5,8 L13.5,8 C13.5,6.61928813 14.6192881,5.5 16,5.5 Z'
        id='Rectangle'
        stroke={KreoColors.f4}
      />
      <path
        // eslint-disable-next-line max-len
        d='M8,13.5 L8,13.5 C9.38071187,13.5 10.5,14.6192881 10.5,16 L10.5,16 C10.5,17.3807119 9.38071187,18.5 8,18.5 L8,18.5 C6.61928813,18.5 5.5,17.3807119 5.5,16 L5.5,16 C5.5,14.6192881 6.61928813,13.5 8,13.5 Z'
        id='Rectangle'
        stroke={KreoColors.f4}
      />
      <path
        // eslint-disable-next-line max-len
        d='M16,13.5 L16,13.5 C17.3807119,13.5 18.5,14.6192881 18.5,16 L18.5,16 C18.5,17.3807119 17.3807119,18.5 16,18.5 L16,18.5 C14.6192881,18.5 13.5,17.3807119 13.5,16 L13.5,16 C13.5,14.6192881 14.6192881,13.5 16,13.5 Z'
        id='Rectangle'
        stroke={KreoColors.f4}
      />
    </g>
  </svg>
);
