import { Icons, Text, ElementTooltip } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { ConstantFunctions } from 'common/constants/functions';
import { Styled } from './styled';

interface Props {
  folderId: string;
  title: string;
  onRemoveSelection?: () => void;
  onClick?: (folderId: string) => void;
}

function TargetFolderComponent({ title, onRemoveSelection, onClick, folderId }: Props): JSX.Element {

  const onRemoveSelectionCallback = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    ConstantFunctions.stopEvent(e);
    onRemoveSelection();
  }, [onRemoveSelection]);

  const onClickCallback = useCallback(() => onClick && onClick(folderId), [folderId]);

  return (
    <ElementTooltip
      text={title}
      position='bottom'
      speed='l'
    >
      <Styled.Container
        onClick={onClickCallback}
        canRemoveSelection={!!onRemoveSelection}
      >
        <Styled.Icon>
          <Icons.Group_2 />
        </Styled.Icon>
        {
          onRemoveSelection ? (
            <Styled.DeleteButton onClick={onRemoveSelectionCallback}>
              <Icons.CloseSmall />
            </Styled.DeleteButton>
          ) : null
        }
        <Text fontSize={14}>
          {title}
        </Text>
      </Styled.Container>
    </ElementTooltip>
  );
}

export const TargetFolder = React.memo(TargetFolderComponent);
