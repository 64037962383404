import QueryString from 'query-string';
import { FastNavigationLinks } from '../../../../units/2d/constants/fast-navigation-links';


export function getActiveNavigationType(queryString: string): FastNavigationLinks {
  const twoDProjectFastNavigationType = {
    [FastNavigationLinks.Project]: FastNavigationLinks.Project,
    [FastNavigationLinks.Plans]: FastNavigationLinks.Plans,
    [FastNavigationLinks.Assemblies]: FastNavigationLinks.Assemblies,
    [FastNavigationLinks.ReportTemplate]: FastNavigationLinks.ReportTemplate,
  };
  const params = QueryString.parse(queryString);
  const type = params.fastNavigationType;
  const fastNavigationLink = type in twoDProjectFastNavigationType ? twoDProjectFastNavigationType[type]
    : FastNavigationLinks.Project;

  return fastNavigationLink;
}
