import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-paste-elements-dialog.scss';

import { State } from 'common/interfaces/state';
import { KreoButton, KreoDialogActions, KreoIconAttention, KreoIconSuccessSmall } from 'common/UIKit';
import { ResultDialog } from '../../../../components/dialog/result-dialog';
import { ClassificationActions } from '../../actions/creators/classification';
import { ClassificationConstants } from '../../constants/classification';


interface StateProps {
  unclassifiedCount: number;
  classifiedCount: number;
}

interface DispatchProps {
  selectPasted: () => void;
  selectNotPasted: () => void;
}

interface Props extends StateProps, DispatchProps {

}

export const ClassificationPasteElementsDialogComponent: React.FC<Props> = props => {
  return (
    <ResultDialog
      name={ClassificationConstants.pasteResultDialogName}
      title='Result'
    >
      <div className='classification-paste-elements-dialog'>
        {props.classifiedCount ? (
          <div className='classification-paste-elements-dialog__tile'>
            <div
              className='classification-paste-elements-dialog__icon
                classification-paste-elements-dialog__icon--success'
            >
              <KreoIconSuccessSmall />
            </div>
            <div className='classification-paste-elements-dialog__description'>
              The classification was successfully pasted in <b>{props.classifiedCount}</b> element type
              {props.classifiedCount > 1 ? 's' : null}
            </div>
            <KreoButton size='medium' caption='Show elements' onClick={props.selectPasted}/>
          </div>
        ) : null}
        {props.unclassifiedCount ? (
          <div className='classification-paste-elements-dialog__tile'>
            <div
              className='classification-paste-elements-dialog__icon
                classification-paste-elements-dialog__icon--attention'
            >
              <KreoIconAttention />
            </div>
            <div className='classification-paste-elements-dialog__description'>
              The copied classification cannot be inserted in <b>{props.unclassifiedCount}</b> element type
              {props.unclassifiedCount > 1 ? 's' : null}
            </div>
            <KreoButton size='medium' caption='Show elements' onClick={props.selectNotPasted}/>
          </div>
        ) : null}
      </div>
    </ResultDialog>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    unclassifiedCount: state.classification.classificationNotPasted.length,
    classifiedCount: state.classification.classificationPasted.length,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    selectPasted: () => {
      dispatch(ClassificationActions.selectPasted());
      dispatch(KreoDialogActions.closeDialog(ClassificationConstants.pasteResultDialogName));
    },
    selectNotPasted: () => {
      dispatch(ClassificationActions.selectNotPasted());
      dispatch(KreoDialogActions.closeDialog(ClassificationConstants.pasteResultDialogName));
    },
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationPasteElementsDialog = connector(ClassificationPasteElementsDialogComponent);
