import { LinkComponent, H6 } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { QuestionnaireContent, ReplyType } from '../../questionnaire';
import { RepliesBlock } from './replies-block';
import { Styled } from './styled';


interface Props {
  index: number;
  isOpen: boolean;
  isChecked: boolean;
  question: string;
  answers: ReplyType[];
  isOther: boolean;
  openBlocks: number[];
  replies: string[];
  questionContent: QuestionnaireContent[];
  handleChangeReply: (index: number) => void;
  setOpenBlocks: (value: number[]) => void;
  setReplies: (value: string[]) => void;
  setQuestionContent: (value: QuestionnaireContent[]) => void;
}

export const BlockQuestionnaire: React.FC<Props> = (props) => {
  const {
    index,
    isOpen,
    isChecked,
    question,
    answers,
    isOther,
    handleChangeReply,
    openBlocks,
    setOpenBlocks,
    replies,
    setReplies,
    questionContent,
    setQuestionContent,
  } = props;

  const handleOnBlock = React.useCallback(() => {
    isOpen
      ? setOpenBlocks(openBlocks.filter((el) => el !== index))
      : setOpenBlocks([...openBlocks, index]);
  }, [isOpen, setOpenBlocks, openBlocks, index]);

  const handleOnLink = React.useCallback(() => {
    handleChangeReply(index);
  }, [handleChangeReply, index]);

  return (
    <Styled.BlockQuestionnaire
      onClick={handleOnBlock}
    >
      {!isChecked ? (
        <RepliesBlock
          question={question}
          answers={answers}
          isOther={isOther}
          questionContent={questionContent}
          replies={replies}
          id={index}
          setReplies={setReplies}
          setQuestionContent={setQuestionContent}
        />
      ) : (
        <Styled.ClosedQuestion>
          <H6>{question}</H6>
          <Styled.CurrentReply>
            <LinkComponent
              text={replies[index]}
              size={'l'}
              onClick={handleOnLink}
              mood={'secondary'}
            />
          </Styled.CurrentReply>
        </Styled.ClosedQuestion>
      )}
    </Styled.BlockQuestionnaire>
  );
};
