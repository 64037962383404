import { useCallback, useMemo, useState } from 'react';

export interface TextState {
  value: string;
  onChange: (newValue: string) => void;
  onBlur: (newValue: string) => void;
  isChanged: boolean;
}

export const useTextState = (): TextState => {
  const [value, setValue] = useState<string>();
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const handleChange = useCallback((newValue: string) => {
    setIsChanged(true);
    setValue(newValue);
  }, []);

  return useMemo(() => {
    return {
      value,
      onBlur: handleChange,
      onChange: handleChange,
      isChanged,
    };
  }, [value]);
};

