const isNumberValue = (value: string | number): boolean => {
  const numberValue = Number(value);
  return !Number.isNaN(numberValue)
    && value === numberValue || value === numberValue.toString();
};

const castToNumberIfNeeded = (value: string): string | number => {
  return isNumberValue(value)
    ? Number(value)
    : `${value}`;
};

export const ValueHelper = {
  isNumberValue,
  castToNumberIfNeeded,
};
