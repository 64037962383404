import * as React from 'react';

export const KreoIconExpandTriangle: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='collapsed' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icon'>
          <rect id='Rectangle-7' x='0' y='0' width='14' height='14' />
          <polygon
            id='Rectangle-5'
            stroke='currentColor'
            fill='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(7.500000, 7.000000) rotate(-180.000000) translate(-7.500000, -7.000000) '
            points='9.5 4 9.5 10 5.5 7'
          />
        </g>
      </g>
    </svg>
  );
};
