import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: ${p => p.theme.duration.s};
  background-color: ${p => p.theme.color.background};
`;

const Canvas = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: ${p => p.theme.duration.s};
  display: grid;
  grid-template-rows: '1fr';
  grid-template-columns: 100%;
  transition: ${p => p.theme.duration.s} ${p => p.theme.function.oq};

  .scroll-box__track-horizontal {
    display: none;
  }
  .scroll-box__track-vertical {
    display: none;
  }

  .scroll-box {
    overflow: auto !important;
  }

  .scroll-box__content {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    overflow: auto !important;

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${p => p.theme.color.disabled};
      border-width: 2px;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover,
    ::-webkit-scrollbar-thumb:active {
      background: ${p => p.theme.color.gray} !important;
    }
    ::-webkit-scrollbar-track {
      width: 6px;
      border-radius: 5px;
      background: ${p => p.theme.color.background};
      margin-left: 4px;
    }
    ::-webkit-scrollbar-corner {
      background-color: ${p => p.theme.color.background};
    }
  }

  canvas {
    margin: auto;
  }
`;


export const Styled = {
  Container,
  Canvas,
};
