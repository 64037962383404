import * as React from 'react';
import { connect } from 'react-redux';

import './project-people-list.scss';

import { State } from 'common/interfaces/state';
import { Avatar } from 'common/UIKit';
import { ProjectMembersDialog } from '../../../units/projects/components/project-members-dialog';
import { StringUtil } from '../../../utils/string';
import { ProfileIcon } from '../profile/profile-icon';
import { ProjectPeople } from './project-people';


export const RoundContainer: React.FC = (props) => {
  return <div className='people-list-round-container'>{props.children}</div>;
};

interface HumanNameProps {
  firstName: string;
  lastName: string;
}

const HumanName: React.FC<HumanNameProps> = (props) => {
  return <div>{StringUtil.capitalize(`${props.firstName} ${props.lastName}`)}</div>;
};

interface MoreHumanProps {
  people: ProjectPeople[];
}

const MoreHuman: React.FC<MoreHumanProps> = (props) => {
  return (
    <RoundContainer>
      <div className='people-list-show-more round'>
        <span className='avatar'>+{props.people.length}</span>
      </div>
      <div className='people-list-more-people-popup'>
        {props.people.map((x, i) => {
          return (
            <HumanName key={i} firstName={x.firstName} lastName={x.lastName} />
          );
        })}
      </div>
    </RoundContainer>
  );
};

interface OwnProps {
  showAll: boolean;
}

interface StateProps {
  people: ProjectPeople[];
  userId: string;
}

interface Props extends OwnProps, StateProps {}

class ProjectPeopleListComponent extends React.Component<Props> {
  private showPeopleNumber: number = 4;

  public render(): React.ReactNode {
    const people = this.props.people
      ? this.props.people.filter((x) => x.id !== this.props.userId)
      : [];
    let viewPeople = [];
    if (people.length > this.showPeopleNumber) {
      viewPeople = people.splice(0, this.showPeopleNumber);
    } else {
      viewPeople = people.splice(0, people.length);
    }
    return (
      people && (
        <div className={'topmenu-people-list'}>
          {viewPeople.map((x, i) => {
            return (
              <RoundContainer key={i}>
                <Avatar
                  userId={x.id}
                  firstName={x.firstName}
                  lastName={x.lastName}
                />
                <div className='topmenu-people-tooltip'>
                  <div className='topmenu-people-tooltip__content'>
                    <p className='topmenu-people-tooltip__top-row'>{x.firstName} {x.lastName}</p>
                    <p className='topmenu-people-tooltip__bottom-row'>{x.userName}</p>
                  </div>
                </div>
              </RoundContainer>
            );
          })}
          {people && people.length > 0 && !this.props.showAll ? (
            <MoreHuman people={people} />
          ) : null}
          <RoundContainer>
            <ProfileIcon showExpandIcon={false} />
          </RoundContainer>
          <ProjectMembersDialog projectPeople={viewPeople}/>
        </div>
      )
    );
  }
}

const mapStateTopProps = (state: State): StateProps => {
  const { currentProject } = state.projects;
  const people = currentProject ? currentProject.people : null;
  return {
    people,
    userId: state.account.id,
  };
};

export const ProjectPeopleList = connect(mapStateTopProps)(
  ProjectPeopleListComponent,
);
