import { Avatar, SvgComponent } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { ConfirmationButtonsPanel } from 'common/components/button-sets/confirmation-buttons';
import { PersonUtils } from 'common/utils/person-utils';
import { useCommentUserContext } from 'unit-2d-comments/comments-panel/comment-user-context';
import { CommentaryThread } from 'unit-2d-comments/interfaces';
import { CommentTextArea } from '../comment-text-area';
import { CommentTypeContainer } from '../comment-type-container';
import { useInputText } from '../hooks/use-input-text';
import { Styled } from './styled';

const NEW_COMMENT_PLACE_HOLDER = 'Reply or add others with @';

interface Props extends CommentaryThread {
  targetIcon: SvgComponent;
  targetTitle: string;
  targetTooltip: string;
  currentUserId: string;
  create: (text: string) => void;
  cancel: () => void;
}

export const NewComment: React.FC<Props> = ({
  create,
  targetIcon,
  targetTitle,
  targetTooltip,
  currentUserId,
  resolved,
  cancel,
}) => {
  const { onEdit, value, isValueValid } = useInputText('');
  const onValueChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => onEdit(e.target.value), [value]);
  const createCallback = useCallback(() => isValueValid && create(value), [create, value, isValueValid]);
  const { users } = useCommentUserContext();
  const currentUser = users[currentUserId];
  const { userName, avatarLink } = PersonUtils.getPersonVisualData(currentUser);
  return (
    <CommentTypeContainer
      iconType={targetIcon}
      title={targetTitle}
      tooltip={targetTooltip}
      resolved={resolved}
      showExpand={false}
    >
      <Styled.AvatarAndInput>
        <Avatar
          name={userName}
          size={30}
          avatar={avatarLink}
        />
        <CommentTextArea
          onChange={onValueChange}
          onFinish={createCallback}
          value={value}
          autofocus={true}
          placeholder={NEW_COMMENT_PLACE_HOLDER}
        />
      </Styled.AvatarAndInput>
      <Styled.ButtonsPanelWrapper>
        <ConfirmationButtonsPanel
          applyText='Comment'
          cancel={cancel}
          apply={createCallback}
          disabled={!isValueValid}
        />
      </Styled.ButtonsPanelWrapper>
    </CommentTypeContainer>
  );
};


