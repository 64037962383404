import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TablePreset } from '2d/index';
import { State } from 'common/interfaces/state';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { DrawingsContextMenu, DrawingsFlyingMenu } from '../drawings-canvas-menus';
import {
  useGetCurrentMeasureMenu,
  useGetFolderToCellOptions,
  useHasMeasures,
  useMoveToCell,
} from '../drawings-canvas-menus/hooks';
import { useElementAndGroupsOperationContext, useRendererApiContext } from '../drawings-contexts';
import { DrawingContextMenuPosition } from '../interfaces';
import { useActionEventHandlers, useCanTraceLink } from './hooks';

export interface Props {
  canViewReport: boolean;
  showFlyingMenu: boolean;
  isFullScreen: boolean;
  isRendererInitialized: boolean;
  canEditReport: boolean;
  canEditMeasurement: boolean;
  canViewMeasurement: boolean;
  canPasteToSamePlace: () => boolean;
  pasteInSamePlace: () => void;
  onRemoveInstancesHandler: () => void;
  sendFlyingMenuRef: (ref: HTMLDivElement) => void;
  cut: () => void;
  onShowAll: () => void;
  canPaste: () => boolean;
  copy: () => void;
  paste: (coordinates: paper.Point) => void;
  groupDrawings: () => void;
  toggleFullScreen: () => void;
  onContextMenuClose: () => void;
  selectAll: () => void;
  focus: () => void;
  pasteWithPoint: () => void;
  startContinue: () => void;
  instancesToOperateWith: string[];
  hasStrokedInstancesInSelected: boolean;
  canToggleFullScreen: boolean;
}


const DrawingsContextInstancesMenusComponents: React.FC<Props> = (props) => {
  const {
    canPasteToSamePlace,
    isFullScreen,
    canPaste,
    canViewReport,
    canEditReport,
    canEditMeasurement,
    canViewMeasurement,
    showFlyingMenu,
    sendFlyingMenuRef,
    onContextMenuClose,
    startContinue,
    isRendererInitialized,
    instancesToOperateWith,
    hasStrokedInstancesInSelected,
    canToggleFullScreen,
  } = props;
  const {
    canTraceLink,
    getInstanceMeasureHelper,
    onMoveTableToCell,
    onDynamicMoveToCell,
    onTraceLink,
    onFolderToCell,
    groupMeasurementsWithUndoRedo,
    onMoveToCell,
  } = useElementAndGroupsOperationContext();

  const contextMenuPosition = useSelector<State, DrawingContextMenuPosition>(
    s => s.drawings.contextMenuPosition,
  );

  const actionEventHandlers = useActionEventHandlers({ ...props, instancesIds: instancesToOperateWith });
  const { rendererApi } = useRendererApiContext();
  const hasSelectedInstances = instancesToOperateWith?.length > 0;
  const hasMeasure = useHasMeasures(instancesToOperateWith, getInstanceMeasureHelper);
  const canTraceLinkResult = useCanTraceLink({ instancesIds: instancesToOperateWith, canViewReport, canTraceLink });

  const getCurrentMeasureTypes = useGetCurrentMeasureMenu(
    instancesToOperateWith,
    getInstanceMeasureHelper,
    onMoveTableToCell,
    onContextMenuClose,
  );

  const { sendEvent } = useAnalytics();
  const onDynamicMoveToCellCallback = useCallback(
    (preset: TablePreset) => {
      onDynamicMoveToCell(preset);
      sendEvent(MetricNames.transferMeasurements.dynamicTableToCell, { name: preset.name });
    },
    [sendEvent, onDynamicMoveToCell],
  );

  const getFolderToCellOptions = useGetFolderToCellOptions(getInstanceMeasureHelper);
  const moveToCellCallback = useMoveToCell(onMoveToCell, instancesToOperateWith);
  if (contextMenuPosition) {
    return (
      <DrawingsContextMenu
        canToggleFullScreen={canToggleFullScreen}
        canPasteToSamePlace={canPasteToSamePlace}
        hasStrokedInstancesInSelected={hasStrokedInstancesInSelected}
        isFullScreen={isFullScreen}
        canPaste={canPaste()}
        position={contextMenuPosition}
        canTraceLink={canTraceLinkResult}
        hasMeasures={hasMeasure}
        canViewReport={canViewReport}
        onDynamicMoveToCell={onDynamicMoveToCellCallback}
        getCurrentMeasureTypes={getCurrentMeasureTypes}
        canEditReport={canEditReport}
        canEditMeasurement={canEditMeasurement}
        onClose={onContextMenuClose}
        getFolderToCellOptions={getFolderToCellOptions}
        isRendererInitialized={isRendererInitialized}
        actionEventHandlers={actionEventHandlers}
        instancesIds={instancesToOperateWith}
      />
    );
  }
  if (!rendererApi || !rendererApi.engine) {
    return null;
  }
  if (showFlyingMenu && hasSelectedInstances) {
    return (
      <DrawingsFlyingMenu
        selectedInstances={instancesToOperateWith}
        contextMenuActions={actionEventHandlers}
        sendRef={sendFlyingMenuRef}
        getCurrentMeasureTypes={getCurrentMeasureTypes}
        hasMeasures={hasMeasure}
        moveToCell={moveToCellCallback}
        folderToCell={onFolderToCell}
        canTraceLink={canTraceLinkResult}
        traceLink={onTraceLink}
        moveToGroup={groupMeasurementsWithUndoRedo}
        canEditMeasurement={canEditMeasurement}
        canViewMeasurement={canViewMeasurement}
        canEditReport={canEditReport}
        startContinue={startContinue}
        getCurrentFolderToCell={getFolderToCellOptions}
      />
    );
  }
};

export const DrawingsContextInstancesMenus = React.memo(DrawingsContextInstancesMenusComponents);
