const prefix = `@model-management`;

export const ModelManagementActionTypes = {
  LOAD_DATA: `${prefix}/LOAD_DATA`,
  LOAD_DATA_SUCCESS: `${prefix}/LOAD_DATA_SUCCESS`,
  DELETE_FILE: `${prefix}/DELETE_FILE`,
  ADD_FILES: `${prefix}/ADD_FILES`,
  APPLY_CHANGES_FAILED: `${prefix}/APPLY_CHANGES_FAILED`,
  APPLY_CHANGES: `${prefix}/APPLY_CHANGES`,
  DROP_PAGE_STATE: `${prefix}/DROP_PAGE_STATE`,
  SELECT_FROM_ENGINE: `${prefix}/SELECT_FROM_ENGINE`,
  SELECT_FILE: `${prefix}/SELECT_FILE`,
};
