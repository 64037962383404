import * as t from 'io-ts';

export const QtoProjectReportC = t.type({
  id: t.number,
  name: t.string,
  reports: t.array(t.type({
    id: t.number,
    name: t.string,
  })),
});

export type QtoProjectReport = t.TypeOf<typeof QtoProjectReportC>;
export const QtoProjectReportArrayC = t.array(QtoProjectReportC);

