import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { Menu } from 'unit-2d-info-panel/constants';
import { TwoDInfoPanelActions } from 'unit-2d-info-panel/store-slice';

export function useStartScaling(): () => void {
  const { setDrawMode } = useDrawModeApi();
  const dispatch = useDispatch();

  return useCallback(() => {
    setDrawMode(DrawingsDrawMode.Calibrate, {
      afterSave: () => dispatch(TwoDInfoPanelActions.setMenu(Menu.Scale)),
    });
  }, [setDrawMode, dispatch]);
}
