import { ModalWrapper, RectangleButton, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ControlNames } from 'common/constants/control-names';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { Styled } from './styled';

interface ItemProps {
  controlName?: string;
  description: string;
  buttonName: string;
  onClick?: () => void;
  onClose?: () => void;
}

export class ReconnectDialog extends React.Component<ItemProps> {
  public render(): JSX.Element {
    const { controlName, description, buttonName, onClick, onClose } = this.props;

    return (
      <DialogWrapper name={controlName}>
        <ModalWrapper onExit={onClose} zIndex={10001}>
          <Styled.Wrapper controlName={ControlNames.reconnectDialogWrapper}>
            <Styled.TextDialog controlName={ControlNames.reconnectDialogDescription}>
              <Text>{description}</Text>
            </Styled.TextDialog>
            <Styled.ReconnectButton controlName={ControlNames.reconnectDialogButton}>
              <RectangleButton
                height={50}
                width={150}
                text={buttonName}
                mood={'positive'}
                onClick={onClick}
              />
            </Styled.ReconnectButton>
          </Styled.Wrapper>
        </ModalWrapper>
      </DialogWrapper>
    );
  }
}
