import * as t from 'io-ts';

import { BillingPaymentModelC } from './billing-payment-model';


export const PaymentSourcesModelC = t.intersection(
  [
    t.type({
      paymentSources: t.array(BillingPaymentModelC),
    }),
    t.partial({
      primaryCardId: t.string,
      backupCardId: t.string,
      billingCountry: t.string,
      vatNumber: t.string,
    }),
  ],
  'PaymentSourcesModel');

export type PaymentSourcesModel = t.TypeOf<typeof PaymentSourcesModelC>;

