import { END, EventChannel, eventChannel, SagaIterator } from 'redux-saga';
import { call, fork, put, take, takeEvery } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { Notification, NotificationC } from 'common/interfaces/realtime-messages';
import { RealtimeConnectionProvider } from 'common/realtime/realtime-connection-provider';
import { CodecUtils } from 'common/utils/codec-utils';
import { NotificationActions, NotificationActionTypes } from './actions';
import { AblyConnect, ReadNotification } from './actions/payloads';
import { NotificationApi } from './api';
import { MessageType } from './interfaces';
import { MessageTypeC } from './interfaces/notification-type';
import { processMessage } from './process-message';

interface WatchProps  {
  channel: EventChannel<MessageType<Notification>>;
  promise: Promise<{}>;
}


function* watch(properties: WatchProps): SagaIterator {
  while (true) {
    try {
      const data = yield take(properties.channel);
      const decodedData = CodecUtils.decode(data, MessageTypeC(NotificationC));
      yield fork(processMessage, decodedData);
    } catch (error) {
      console.error('notification: watch failed', error);
    }
  }
}

function* connect(action: ActionWith<AblyConnect>): SagaIterator {
  try {
    let emit: (input: {} | END) => void;
    const channel = eventChannel((emitter: (input: MessageType<Notification>) => void) => {
      emit = emitter;
      return () => null;
    });
    const promise = new Promise(resolve => {
      const { namespace, id } = action.payload;
      RealtimeConnectionProvider.subscribe(namespace, id, resolve, emit);
    });

    yield fork<(props: WatchProps) => SagaIterator>(watch, { channel, promise });
    if (action.payload.namespace === 'user') {
      yield put(NotificationActions.loadNotifications());
      const notifications: Notification[] = yield call(NotificationApi.getBells);
      yield put(NotificationActions.loadNotificationsSucceeded(notifications));
    }
  } catch (error) {
    yield put(NotificationActions.loadNotificationsFailed());
    console.error('notification: connect failed', error, action.payload);
  }
}


function* readNotifications(action: ActionWith<ReadNotification>): SagaIterator {
  try {
    yield call(NotificationApi.readBells, action.payload.notificationIds, action.payload.value);
  } catch (error) {
    yield put(NotificationActions.loadNotificationsFailed());
    console.error('notification: read notifications failed', error, action.payload);
  }
}

function* deleteNotifications(action: ActionWith<number[]>): SagaIterator {
  try {
    yield call(NotificationApi.deleteBells, action.payload);
  } catch (error) {
    yield put(NotificationActions.loadNotificationsFailed());
    console.error('notification: delete notifications failed', error, action.payload);
  }
}

export function* notificationSagas(): SagaIterator {
  yield takeEvery(NotificationActionTypes.CONNECT, connect);
  yield takeEvery(NotificationActionTypes.READ_NOTIFICATION, readNotifications);
  yield takeEvery(NotificationActionTypes.DELETE_NOTIFICATION, deleteNotifications);
}
