import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import './card.scss';

import { KreoButton } from 'common/UIKit';

export interface Props {
  title?: string;
  width?: number;
  height?: number;
  className?: string;
  link?: string;
  linkName?: string;
}

export class Card extends React.Component<Props> {
  private button: JSX.Element = this.props.link ? (
    <Link to={this.props.link}>
      <KreoButton size='small' mode='add' caption={this.props.linkName} className='card-component__header-btn'/>
    </Link>
  ) : null;

  public render(): JSX.Element {
    const { title } = this.props;

    const className = classNames('card-component', this.props.className, {
      'has-title': !!title,
    });

    return (
      <div className={className}>
        {title ? (
          <div className='card-component__header'>
            <span className='card-component__title'>{title}</span>
            {this.button}
          </div>
        ) : null}
        <div className='card-component__content'>
          <div className='card-component__content-inner'>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
