import { DrawingContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces';
import { ConstantFunctions } from 'common/constants/functions';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { EngineObject, EngineObjectConfig } from '../../common';

interface Config extends EngineObjectConfig {
  zoomObserver?: DrawingContextObserver<DrawingsRenderParams>;
}

const CLICKS_DISTANCE_THRESHOLD = 30;

export class LayerDoubleClickProcessor extends EngineObject<Config> {
  private _dblClickExecutor: DeferredExecutor = new DeferredExecutor(300);
  private _fistClickPosition: paper.Point;

  public onClick(
    e: PaperMouseEvent,
    onClick: (e: PaperMouseEvent) => void,
    onDblClick: (e: PaperMouseEvent) => void,
  ): void {
    if (onClick) {
      onClick(e);
    }

    if (this._dblClickExecutor.isWaitingForExecution()) {
      this._dblClickExecutor.reset();
      if (this._config.zoomObserver) {
        const { zoom } = this._config.zoomObserver.getContext();
        const distance = this._fistClickPosition.getDistance(e.point);
        if (distance / zoom < CLICKS_DISTANCE_THRESHOLD) {
          onDblClick(e);
        } else {
          this._fistClickPosition = e.point;
          this._dblClickExecutor.execute(ConstantFunctions.doNothing);
        }
      } else {
        onDblClick(e);
      }
    } else {
      this._fistClickPosition = e.point;
      this._dblClickExecutor.execute(ConstantFunctions.doNothing);
    }
  }
}
