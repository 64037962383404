import * as React from 'react';

import './quantity-take-off-controls.scss';

import { ExpandCollapseControls } from 'common/components/expand-collapse-controls';
import { FloatingTreePanel, FloatingTreePanelProps } from 'common/components/tree-filter-panel/floating-tree-panel';

interface Props extends FloatingTreePanelProps {
  expandAllNodes: () => void;
  collapseAllNodes: () => void;
  disableEngineFilter: () => void;
  isLocation: boolean;
}

export class QuantityTakeOffControls extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      currentProjectId,
      onFilter,
      displayNames,
      isLocation,
      filterName,
      records,
      isEngineFilterEnabled,
      saveState,
      getSaveState,
      disableEngineFilter,
    } = this.props;
    return (
      <div className='quantity-take-off-controls__controls-wrap'>
        <FloatingTreePanel
          disableEngineFilter={disableEngineFilter}
          isEngineFilterEnabled={isEngineFilterEnabled}
          className='quantity-take-off-controls__filter-button'
          onFilter={onFilter}
          currentProjectId={currentProjectId}
          displayNames={displayNames}
          isLocation={isLocation}
          filterName={filterName}
          records={records}
          saveState={saveState}
          getSaveState={getSaveState}
          getReportElementIds={this.props.getReportElementIds}
          engineFilter={this.props.engineFilter}
          mapIdHelper={this.props.mapIdHelper}
        />
        <ExpandCollapseControls
          className='quantity-take-off-controls__expand-collapse-controls'
          onCollapse={this.props.collapseAllNodes}
          onExpand={this.props.expandAllNodes}
        />
        {!this.props.isLocation
          ? (
            <div className='quantity-take-off-controls__group'>
              <a
                className='quantity-take-off-controls__description-doc-link'
                href='https://www.kreo.net/qto-help/tag/measurement-details'
                target='_blank'
              >
                Measurement Details
              </a>
            </div>
          )
          : null}
      </div>
    );
  }
}
