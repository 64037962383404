import { ModalWrapper } from '@kreo/kreo-ui-components';
import React from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { BillingEntityStatus } from '../../../enums/billing-entity-status';
import { BillingPeriodUnit } from '../../../enums/billing-period-unit';
import { SubscriptionAddon } from '../../../interfaces/subscription-addon';
import { SubscriptionPlanListingModel } from '../../../interfaces/subscription-plan-listing-model';
import { SubscriptionListingUtils } from '../../../utils/subscription-listing-utils';
import { SubscriptionListing } from '../../subscription-listing';
import { SELECT_SUBSCRIPTION_PLAN_DIALOG_CONSTANTS } from './constants';
import { useHandleCloseCallback, useHandlePlanClickCallback, usePinBlock } from './hooks';
import { Styled } from './styled';

interface Props {
  selectedAddons: SubscriptionAddon[];
  onSubscriptionPlanGroupClick: (groupName: string) => void;
  onBillingPeriodChanged: (billingPeriod: BillingPeriodUnit) => void;
}

export const SelectSubscriptionPlanDialog: React.FC<Props> = ({
  selectedAddons,
  onBillingPeriodChanged,
  onSubscriptionPlanGroupClick,
}) => {
  const plansModel = useSelector<State, SubscriptionPlanListingModel>(s => s.account.subscriptionPlans);
  const [billingPeriod, setBillingPeriod] = React.useState(BillingPeriodUnit.Year);
  const handleClose = useHandleCloseCallback(setBillingPeriod);
  const [isPinBlock, handleScroll] = usePinBlock();
  const handlePlanClick = useHandlePlanClickCallback(
    billingPeriod,
    onBillingPeriodChanged,
    onSubscriptionPlanGroupClick,
    handleClose,
  );
  const filteredPlanGroups = React.useMemo(() =>
    SubscriptionListingUtils
      .getFilteredPlanGroups(plansModel, billingPeriod)
      .filter(v => v.status === BillingEntityStatus.Active && !v.isHidden),
  [plansModel, billingPeriod],
  );

  return (
    <DialogWrapper name={SELECT_SUBSCRIPTION_PLAN_DIALOG_CONSTANTS.DIALOG_NAME}>
      <ModalWrapper onExit={handleClose}>
        <Styled.Container
          isPinBlock={isPinBlock}
          planLength={filteredPlanGroups.length}
          onScroll={handleScroll}
        >
          <SubscriptionListing
            filteredPlanGroups={filteredPlanGroups}
            selectedAddons={selectedAddons}
            billingPeriod={billingPeriod}
            onSubscriptionPlanGroupClick={handlePlanClick}
            onBillingPeriodChanged={setBillingPeriod}
            isPinBlock={isPinBlock}
          />
        </Styled.Container>
      </ModalWrapper>
    </DialogWrapper>
  );
};
