import React from 'react';
import { AssignForm } from './assign-form';

interface Props {
  groupsIds: string[];

}

export const GroupsView: React.FC<Props> = () => {
  return (
    <>
      <AssignForm isGroup={true} />
    </>
  );
};
