const getSmartlook = (): any => {
  const smartlook = (window as any).smartlook;
  const smartlookSentryEnabled = smartlook
    && smartlook.integrations
    && smartlook.integrations.sentry
    && smartlook.integrations.sentry.enabled;
  return smartlookSentryEnabled ? smartlook : null;
};

const getSmartlookInfo = (): any => {
  const smartlook = getSmartlook();
  return smartlook
    ? {
      playUrl: smartlook.playUrl,
      sessionId: smartlook.sessionId,
      visitorId: smartlook.visitorId,
    }
    : null;
};

const disable = (): void => {
  const smartlook = (window as any).smartlook;
  if (smartlook) {
    smartlook('disable', true);
  }
};


interface SmartLookUserInfo {
  id: string;
  companyId: number;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  companyName: string;
  userRoleName: string;
}

const setUserInfo = (user: SmartLookUserInfo): void => {
  const smartlook = (window as any).smartlook;
  if (user && smartlook) {
    smartlook('consentForms', 'I understand');
    smartlook('consentIP', 'I understand');
    smartlook('consentAPI', 'I understand');
    smartlook('identify', user.id, user);
  }
};

const anonymize = (): void => {
  const smartlook = (window as any).smartlook;
  if (smartlook) {
    smartlook('anonymize');
  }
};

export const SmartlookUtils = {
  getSmartlookInfo,
  setUserInfo,
  anonymize,
  disable,
};

