import { Icons, MenuItem } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ColorControl } from 'common/components/color-control';
import { ColorList } from 'common/constants/color-list';
import { ColorsName } from 'common/enums/kreo-colors';
import { ReportPageType } from '../../../interfaces';
import { ContextMenuProps, ReportTab } from './interfaces';


interface Props {
  canEditReport: boolean;
  onChangeTabInfo: (pageInfo: ReportTab) => void;
  onMoveTo: (id: string, offset: number) => void;
  onDuplicateTab: (id: string) => void;
  onDeleteTab: (id: string, name: string) => void;
}

type ContextMenuPropsType = ContextMenuProps<ReportTab>;

export const getTableTabContext = (externalProps: Props): React.FC<ContextMenuPropsType> => {
  return React.memo((props: ContextMenuPropsType) => {
    const {
      item,
      itemList,
      index,
      closeContextMenu,
      editItemName,
    } = props;

    const canMoveRight = itemList.length - 1 > index;
    const canMoveLeft = index > 0;
    const canDuplicate = item.type === ReportPageType.SpreadSheet;

    const onEdit = React.useCallback((): void => {
      if (externalProps.canEditReport && editItemName) {
        closeContextMenu();
        editItemName();
      }
    }, []);

    const onDelete = React.useCallback(() => {
      const { id, name } = item;
      externalProps.onDeleteTab(id, name);
    }, []);

    const onDuplicate = React.useCallback(() => {
      externalProps.onDuplicateTab(item.id);
      closeContextMenu();
    }, []);

    const onColorChange = React.useCallback((color: string) => {
      externalProps.onChangeTabInfo({ ...item, color });
    }, []);

    const moveToLeft = React.useCallback(() => {
      externalProps.onMoveTo(item.id, -1);
    }, []);

    const moveToRight = React.useCallback(() => {
      externalProps.onMoveTo(item.id, 1);
    }, []);

    return (
      <>
        <ColorControl
          colorList={ColorList}
          selectedColor={item.color || ColorList[0]}
          onColor={onColorChange}
        />
        <MenuItem
          onClick={onEdit}
          text='Rename'
          Icon={Icons.Rename}
          textColor={ColorsName.mainFont}
          size='m'
          fontSize={14}
        />
        <MenuItem
          onClick={onDelete}
          text='Delete'
          Icon={Icons.Delete}
          textColor={ColorsName.mainFont}
          size='m'
          fontSize={14}
        />
        {
          canDuplicate && (
            <MenuItem
              onClick={onDuplicate}
              text='Duplicate'
              Icon={Icons.Duplicate}
              textColor={ColorsName.mainFont}
              size='m'
              fontSize={14}
            />
          )
        }
        {canMoveLeft &&
          <MenuItem
            onClick={moveToLeft}
            text='Move left'
            Icon={Icons.Left}
            textColor={ColorsName.mainFont}
            size='m'
            fontSize={14}
          />
        }
        {canMoveRight &&
          <MenuItem
            onClick={moveToRight}
            text='Move right'
            Icon={Icons.Right}
            textColor={ColorsName.mainFont}
            size='m'
            fontSize={14}
          />
        }
      </>);
  });
};

