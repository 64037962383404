const getCorrectNumber = (precision: number): number => Number(`0.${new Array(precision).join('0')}1`);

export const mathUtils = {
  clamp: (value: number, lowerBound: number, upperBound: number): number => {
    if (upperBound < lowerBound) {
      console.error('clamp: upper bound lower than lower bound', upperBound, lowerBound);
      return value;
    }

    return value > lowerBound
      ? value < upperBound ? value : upperBound
      : lowerBound;
  },
  lerp: (from: number, to: number, value: number): number => {
    value = value < 0 ? 0 : value;
    value = value > 1 ? 1 : value;
    return from + (to - from) * value;
  },
  round: (value: number, precision: number): number => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  },
  floor: (value: number, precision: number): number => {
    const multiplier = Math.pow(10, precision || 0);
    const correctValue = getCorrectNumber(precision);
    return Math.floor(value * multiplier + correctValue) / multiplier;
  },
  sign: (value: number): -1 | 0 | 1 => {
    if (value === 0) { return 0; }
    if (value > 0) { return 1; }

    return -1;
  },
  changeInRange: (value: number, lowerBound: number, upperBound: number): boolean => {
    return value >= lowerBound && value <= upperBound;
  },
  randomInRange: (min: number, max: number): number => {
    return Math.random() * (max - min) + min;
  },
};
