import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-unclassified-elements-filter.scss';

import { State } from 'common/interfaces/state';
import { ClassificationActions } from '../../actions/creators/classification';

interface TabProps {
  isActive: boolean;
}

const Tab: React.FC<TabProps> = ({ children, isActive }) => {
  const className = classNames(
    'classification-unclassified-elements-filter-tab',
    {
      'classification-unclassified-elements-filter-tab--active': isActive,
    },
  );
  return (
    <div
      className={className}
    >
      {children}
    </div>
  );
};

interface StateProps {
  unclassifiedEnabled: boolean;
  show: boolean;
}

interface DispatchProps {
  toggleFilterStatus: () => void;
}

interface Props extends StateProps, DispatchProps {}

class ClassificationUnclassifiedElementsFilterComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    if (!this.props.show) return null;
    return (
      <div className='classification-unclassified-elements-filter' onClick={this.onAll}>
        <Tab isActive={!this.props.unclassifiedEnabled}>
          All
        </Tab>
        <Tab isActive={this.props.unclassifiedEnabled}>
          Unclassified
        </Tab>
        <div className='classification-unclassified-elements-filter__slide'/>
      </div>
    );
  }

  @autobind
  private onAll(): void {
    this.props.toggleFilterStatus();
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    unclassifiedEnabled: state.classification.modelBrowserFilters.unclassifiedFilterEnabled,
    show: state.classification.errorIds
      && state.classification.errorIds.length
      && !state.classification.classificationTarget,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    toggleFilterStatus: () => dispatch(ClassificationActions.toggleUnclassifiedFilterStatus()),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationUnclassifiedElementsFilter =
  connector(ClassificationUnclassifiedElementsFilterComponent);
