import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './index.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { Controls3D } from 'common/components/controls-3d';
import { KreoToolbar } from 'common/components/kreo-toolbar';
import { KreoColors } from 'common/enums/kreo-colors';
import { isProjectStatusGreaterThan, isProjectStatusLessThan, ProjectStatus } from 'common/enums/project-status';
import { UserMap } from 'common/interfaces/people';
import { State } from 'common/interfaces/state';
import { Engine } from '../../../../components/engine';
// eslint-disable-next-line import/named
import { EngineRenderMode, KreoEngine } from '../../../../components/engine/KreoEngine';
import { Unit } from '../../../../components/engine/KreoEngineConsts';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { PersistedStorageActions } from '../../../persisted-storage/actions/creators';
import { PropertiesDataContextProvider } from '../../../projects/components/properties-data-context-provider';
import { PropertiesPopupApi } from '../../../projects/components/properties-popup-button';
import { CollaborationElementInfo } from '../../../projects/interfaces/collaboration/element-info';
import { Project } from '../../../projects/interfaces/project';
import { ViewerActions } from '../../actions';
import { CommentModel, CommentStatus, ContextProps, PositionInfo } from '../../interfaces';
import { ContextMenu } from '../controls';
import { MainLayout } from '../main-layout';
import { Markers } from '../markers';
import { NewCommentDialog } from '../new-comment';
import { PageContainer } from '../page-container';


interface PageState {
  fullscreen: boolean;
  key: number;
  engineContainerRef: HTMLDivElement;
  isIsometryEnabled: boolean;
  isRulerEnabled: boolean;
  isToolbarHide: boolean;
  isImperialUnit: boolean;
  color: string;
}

interface StateProps {
  project: Project;
  showTree: any;
  showComments: any;
  commentsLevel: number;
  showNewComment: any;
  comments: CommentModel[];
  markerMode: any;
  markerPosition: any;
  selectedId: any;
  isClipBox: boolean;
  hasSelected: boolean;
  bimids: CollaborationElementInfo[];
  withresolved: boolean;
  clipmode: EngineRenderMode;
  timemarker: any;
  contextpos: PositionInfo;
  contextmode: boolean;
  userid: string;
  peoplemap: UserMap;
  isAutoFocus: boolean;
}

interface DispatchProps {
  getBimInfo: (projectId: number) => void;
  loadComments: (canViewAllComments: boolean) => void;
  createComment: (documentId: string, text: string, engine: any, ids: any) => void;
  dropInfo: () => void;
  dropQs: () => void;
  goToComment: (comment: any) => void;
  goToComments: () => void;
  showComment: () => void;
  changeRenderMode: (ids: number[], mode: string) => void;
  setClipBoxMode: (mode: string) => void;
  onClipBox: () => void;
  showNewCommentField: () => void;
  setTimeMarker: (data: any) => void;
  setMarkerPosition: (position: any) => void;
  selectByIds: (ids: any, provider: any) => void;
  onAddSubcomment: (id: any, value: any, parent: any) => void;
  setContextPositions: (position: PositionInfo) => void;
  selectItem: (item: any, identificator: any) => void;
  onDeselectItem: (item: any, identificator: any) => void;
  onMarkerMode: () => void;
  hide: (ids: any, identificator: any) => void;
  show: (ids: any, identificator: any) => void;
  ghost: (ids: any, identificator: any) => void;
  setIdsForItem: (identificator: any, ids: any) => void;
  closeContext: () => void;
  onAddSelectedItem: (item: any, identificator: any) => void;
  selectComment: (index: number) => void;
  toggleAutoFocusEngine: () => void;
}

interface Props extends RouteComponentProps<PlanProjectRouteParams>, StateProps, DispatchProps, AbilityAwareProps {

}

class ViewerPageComponent extends React.Component<Props, PageState> {
  public state: PageState = {
    key: Math.random(),
    fullscreen: false,
    engineContainerRef: null,
    isToolbarHide: false,
    isImperialUnit: false,
    isIsometryEnabled: false,
    isRulerEnabled: false,
    color: KreoColors.blue4,
  };
  private selected: boolean = false;
  private enginemove: boolean = false;
  private tocomment: boolean = false;
  private comment: any;
  private engine: KreoEngine;
  private ids: any;
  private isEngineEvent: boolean;
  private listprovider: any;
  private contextProperties: ContextProps;
  private tooltip: HTMLSpanElement;
  private treeEvent: boolean = false;
  private engineLayout: HTMLDivElement = null;
  private propertiesPopupApi: PropertiesPopupApi = null;

  public componentDidMount(): void {
    const projectId = parseInt(this.props.match.params.projectId, 10);
    if (
      this.props.project &&
      isProjectStatusGreaterThan(this.props.project.status, ProjectStatus.HasEngineMeshes, true)
    ) {
      this.loadData(projectId);
    }
  }

  public shouldComponentUpdate(nextProps: Props): boolean {
    const projectId = parseInt(this.props.match.params.projectId, 10);
    const nextProjectId = parseInt(nextProps.match.params.projectId, 10);

    if (
      projectId !== nextProjectId
      || this.needLoadBimInfo(nextProps.project, this.props.project)
    ) {
      this.loadData(nextProjectId);
    }

    if (
      this.props.project && nextProps.project &&
      this.props.project.status !== nextProps.project.status &&
      isProjectStatusGreaterThan(nextProps.project.status, ProjectStatus.HasEngineMeshes, true) &&
      isProjectStatusLessThan(this.props.project.status, ProjectStatus.HasEngineMeshes)
    ) {
      this.loadData(nextProjectId);
    }

    this.toComment(this.props.match.params.projectId, nextProps.comments);
    return true;
  }

  public componentWillUnmount(): void {
    this.props.dropInfo();
  }

  public render(): React.ReactNode {
    const {
      showTree,
      showComments,
      showNewComment,
      commentsLevel,
      markerMode,
      markerPosition,
      selectedId,
      withresolved,
      userid,
      ability,
    } = this.props;

    let comments = this.props.comments;
    if (ability.cannot(Operation.Read, Subject.ViewerComments)) {
      comments = comments.filter(x => x.owner === userid);
    }

    if (!withresolved) {
      comments = comments.filter(x => x.status === CommentStatus.Open);
    }

    const ghostEnabled = this.props.clipmode === 'ghost';
    const isAnyInvisible = this.engine && this.engine.isAnyInvisible();

    return (
      <PageContainer
        match={this.props.match}
        project={this.props.project}
      >
        <PropertiesDataContextProvider >
          <MainLayout
            withresolved={withresolved}
            selectedId={selectedId}
            showLeft={showTree}
            showRight={showComments}
            isEngineEvent={this.isEngineEvent}
            engineMove={this.enginemove}
            fullscreen={this.state.fullscreen}
            markerMode={markerMode}
            showNewComment={showNewComment}
            onAddSelect={this.onAddSelect}
            onDeselectItem={this.onDeselectItem}
            onItemSelect={this.onTreeSelect}
            onItemsShow={this.onShow}
            onItemHide={this.onItemHide}
            onItemGhost={this.onItemGhost}
            toState={this.engineToState}
            getTreeList={this.setTreeList}
            onUpdate={this.onUpdate}
            onMarkerMode={this.props.onMarkerMode}
            showNewCommentField={this.onNewCommentFields}
            showComment={this.props.showComment}
            onCreateNewComment={this.createNewComment}
            goToComment={this.goToComment}
            selectComment={this.props.selectComment}
            userId={userid}
            companyId={this.props.project.companyId}
            projectId={this.props.project.id}
            peoplemap={this.props.peoplemap}
            backToComments={this.backToComments}
            onCreateSubcommentScreen={this.takeSubcommentScreen}
            onAddSubcomment={this.onAddSubcomment}
            commentsLevel={commentsLevel}
            commentaries={comments}
          >
            {showComments && (
              <Markers
                markerMode={markerMode}
                selectComment={this.props.selectComment}
                onToState={this.engineToState}
                showNewMessage={showNewComment}
                comments={comments}
                commentsLevel={commentsLevel}
                timemarker={this.props.timemarker}
                checkVisible={this.onMarkerCheckVisible}
                getPosition={this.getMarkerPosition}
                onCommentClick={this.goToComment}
              />
            )}
            {showNewComment && (
              <NewCommentDialog
                markerMode={markerMode}
                markerPosition={markerPosition}
                showNewCommentField={this.props.showNewCommentField}
                createNewComment={this.createNewComment}
              />
            )}
            <div
              className={classNames(
                'viewer-engine-layout',
                {
                  marker: markerMode,
                  'viewer-engine-layout--toolbar-hide': this.state.isToolbarHide,
                },
              )}
              onMouseMove={this.mouseMove}
              ref={this.saveEngineLayout}
              onClick={
                markerMode || this.props.contextmode ? this.getPosition : undefined
              }
            >
              <Engine
                textures='/static/textures/'
                sendEngineApi={this.engineApi}
                projectId={parseInt(this.props.match.params.projectId, 10)}
                onHandleClick={this.handleEngineClick}
                onChangeRenderMode={this.onChangeRenderMode}
                onChangeVisibility={this.onToggleVisibility}
                onToggleClipBox={this.onEngineToggleClipBox}
                onToggleRuler={this.onEngineToggleRuler}
                toggleCameraParallel={this.onEngineToggleCameraParallel}
                onCameraMove={this.update}
                onBimMenuClick={this.onContextMenu}
                onResize={this.update}
                saveContainerRef={this.saveEngineContainerRef}
              />
              {!this.props.showNewComment &&
                markerMode && (
                  <span
                    ref={this.setTooltip}
                    style={{ display: 'none' }}
                    className='engine-layout-tooltip'
                  >
                    Click to leave comment
                  </span>
              )}
              <ContextMenu
                contextmode={this.props.contextmode}
                color={this.state.color}
                props={this.contextProperties}
                position={this.props.contextpos}
                onHide={this.onHide}
                onFocus={this.onFocus}
                onShowAll={this.onShowHideElements}
                onGhost={this.toggleGhost}
                onIsolate={this.onIsolate}
                onAssignColor={this.onAssignColor}
                resetAllColor={this.resetAllColor}
              />
              <KreoToolbar
                isToolbarHide={this.state.isToolbarHide}
                onHideToggle={this.onHideToolbarToggle}
                className='model-check-page__engine-toolbar'
              >
                <Controls3D
                  isSelectedElement={this.props.hasSelected}
                  ghostEnabled={ghostEnabled}
                  isometryEnabled={this.state.isIsometryEnabled}
                  clipBoxEnabled={this.props.isClipBox}
                  rulerEnabled={this.state.isRulerEnabled}
                  isFullScreen={this.state.fullscreen}
                  isImperialUnit={this.state.isImperialUnit}
                  isAutoFocus={this.props.isAutoFocus}
                  engineLayoutContainer={this.state.engineContainerRef}
                  color={this.state.color}
                  onChangeColor={this.onChangeColor}
                  onAssignColor={this.onAssignColor}
                  onDefaultColor={this.onDefaultColor}
                  onHome={this.onHome}
                  onFocus={this.onFocus}
                  onIsolate={this.onIsolate}
                  onHide={this.onHide}
                  isAnyInvisible={isAnyInvisible}
                  onShowHideElements={this.onShowHideElements}
                  toggleGhost={this.toggleGhost}
                  toggleClipBox={this.toggleClipBox}
                  toggleIsometry={this.toggleIsometry}
                  toggleRuler={this.toggleRuler}
                  toggleFullScreen={this.toggleFullScreen}
                  toggleAutoFocus={this.props.toggleAutoFocusEngine}
                  toggleMetricImperial={this.toggleUnit}
                  savePropertiesPopupApi={this.savePropertiesApi}
                />
              </KreoToolbar>
            </div>
          </MainLayout>
        </PropertiesDataContextProvider>
      </PageContainer>);
  }

  @autobind
  private onDefaultColor(): void {
    const ids = this.engine.getSelected();
    this.engine.setColorTint(null, ids);
  }

  @autobind
  private saveEngineContainerRef(ref: HTMLDivElement): void {
    this.setState({ engineContainerRef: ref });
  }

  @autobind
  private saveEngineLayout(ref: HTMLDivElement): void {
    this.engineLayout = ref;
  }

  @autobind
  private savePropertiesApi(api: PropertiesPopupApi): void {
    this.propertiesPopupApi = api;
  }

  private loadData(projectId: number): void {
    this.props.getBimInfo(projectId);
    this.props.loadComments(this.props.ability.can(Operation.Read, Subject.ViewerComments));
  }

  private needLoadBimInfo(newProjectInfo: Project, oldProjectInfo: Project): boolean {
    const projectInfoLoadedAndHasEngineMeshes =
      !oldProjectInfo && newProjectInfo &&
      isProjectStatusGreaterThan(newProjectInfo.status, ProjectStatus.HasEngineMeshes, true);

    if (projectInfoLoadedAndHasEngineMeshes) {
      return true;
    }

    const engineMeshesAddedInCurrentProject = oldProjectInfo && newProjectInfo
      && isProjectStatusLessThan(oldProjectInfo.status, ProjectStatus.HasEngineMeshes)
      && isProjectStatusGreaterThan(newProjectInfo.status, ProjectStatus.HasEngineMeshes, true);

    if (engineMeshesAddedInCurrentProject) {
      return true;
    }
    return false;
  }

  @autobind
  private setTooltip(ref: HTMLSpanElement): void {
    this.tooltip = ref;
  }

  @autobind
  private update(): void {
    this.enginemove = true;
    this.setState({ key: Math.random() });
  }


  @autobind
  private toggleFullScreen(): void {
    this.setState({ fullscreen: !this.state.fullscreen });
  }

  @autobind
  private onHome(): void {
    this.engine.cameraToHome();
  }

  @autobind
  private resetAllColor(): void {
    this.engine.setColorTint(null);
  }

  @autobind
  private onAddSubcomment(id: any, value: any, parent: any): void {
    if (value.screen) {
      value.engineState = JSON.stringify(this.engine.getState());
    }
    this.props.onAddSubcomment(id, value, parent);
  }

  @autobind
  private onHideToolbarToggle(): void {
    this.setState((s) => ({ isToolbarHide: !s.isToolbarHide }));
  }

  @autobind
  private onShowHideElements(): void {
    this.engine.setRenderMode('standard');
    this.engine.toggleVisibility(true);
  }

  @autobind
  private onHide(): void {
    const ids = this.engine.getSelected();
    this.engine.toggleVisibility(false, ids);
  }

  @autobind
  private onIsolate(): void {
    const ids = this.engine.getSelected();
    this.engine.toggleVisibility(true, ids, false);
  }

  @autobind
  private onContextMenu(e: MouseEvent): void {
    const { width, height } = this.engineLayout.getBoundingClientRect();
    const posinfo: PositionInfo = {
      parrentHeight: height,
      parrentWidth: width,
      x: e.offsetX,
      y: e.offsetY,
    };
    const ids = this.engine.getSelected();
    const tint = ids.length > 0 ? this.engine.isColorTinted(ids[0]) : false;

    this.contextProperties = {
      hasSelected: ids.length > 0,
      anyColors: this.engine.isAnyColorTinted(),
      anyInvis: this.engine.isAnyInvisible(),
      isTint: tint,
    };
    this.props.setContextPositions(posinfo);
  }

  @autobind
  private onFocus(): void {
    const ids = this.engine.getSelected();
    this.engine.focusCamera(ids);
  }

  @autobind
  private getMarkerPosition(position: any[]): any {
    return this.engine.getScreenCoordsOfPoint(position);
  }

  @autobind
  private setNewMarkerField(position: any): any {
    this.savePoint();
    this.props.setMarkerPosition(position);
  }

  @autobind
  private handleEngineClick(ids: number[]): void {
    if (this.propertiesPopupApi) {
      this.propertiesPopupApi.showPropertiesForElements(ids, true);
    }
    this.isEngineEvent = true;
    if (!this.selected) {
      this.props.selectByIds(ids, this.listprovider);
      if (this.props.isAutoFocus) {
        this.engine.focusCamera(ids);
      }
    }
    this.selected = false;
  }

  private setEngineSelected(ids: any): void {
    this.engine.setSelected(ids);
    if (this.props.isAutoFocus) {
      this.engine.focusCamera(ids);
    }
  }

  @autobind
  private createNewComment(value: any): any {
    this.props.createComment(
      this.props.match.params.projectId,
      value,
      this.engine,
      this.ids,
    );
  }


  @autobind
  private goToComment(comment: CommentModel): void {
    if (comment.bimHandleIds.length !== undefined) {
      this.engine.setSelected(comment.bimHandleIds);
    }
    this.props.goToComment(comment);
  }

  @autobind
  private backToComments(): any {
    this.engine.setSelected([]);
    this.props.goToComments();
  }

  @autobind
  private onItemGhost(item: any): void {
    if (item.id !== undefined) {
      this.engine.setRenderMode('ghost', [item.id]);
      return;
    }
    const ids = this.props.bimids
      .slice(item.start, item.end)
      .map((x: any): any => x.id);
    this.engine.setRenderMode('ghost', ids);
    this.treeEvent = true;
    this.props.changeRenderMode(ids, 'ghost');
  }

  @autobind
  private onShow(item: any): any {
    if (item.id !== undefined) {
      this.engine.toggleVisibility(true, [item.id]);
      this.engine.setRenderMode('standard', [item.id]);
      return;
    }
    const ids = this.props.bimids
      .slice(item.start, item.end)
      .map((x: any): any => x.id);
    this.engine.toggleVisibility(true, ids);
    this.engine.setRenderMode('standard', ids);
    this.treeEvent = true;
    this.props.changeRenderMode(ids, 'standard');
  }

  @autobind
  private onItemHide(item: any): void {
    if (item.id !== undefined) {
      this.engine.toggleVisibility(false, [item.id]);
      return;
    }
    const ids = this.props.bimids
      .slice(item.start, item.end)
      .map((x: any): any => x.id);
    this.engine.toggleVisibility(false, ids);
    this.treeEvent = true;
    this.props.changeRenderMode(ids, 'none');
  }

  @autobind
  private onChangeRenderMode(mode: string, ids: any[]): void {
    this.isEngineEvent = true;
    this.props.changeRenderMode(ids, mode);
    if (!this.treeEvent) {
      this.props.changeRenderMode(ids, mode);
      this.treeEvent = true;
    }
  }

  private toComment(_projectId: string, comments: CommentModel[]): void {
    if (this.tocomment) {
      if (comments.length > 0) {
        const comment = comments.find(
          (x) => x.id === this.comment,
        );

        if (comment && this.engine) {
          if (!this.props.showComments) {
            this.props.showComment();
          }
          this.goToComment(comment);
          this.tocomment = false;
          this.props.dropQs();
        }
      }
    }
  }

  @autobind
  private onUpdate(): void {
    this.isEngineEvent = false;
    this.enginemove = false;
  }

  @autobind
  private engineApi(api: any): any {
    this.engine = api;
    this.toggleGhost();
  }

  @autobind
  private onToggleVisibility(value: boolean, ids: number[]): void {
    this.isEngineEvent = true;
    this.props.changeRenderMode(ids, !value ? 'none' : 'standard');
  }

  @autobind
  private onMarkerCheckVisible(position: any[]): any {
    return this.engine.isPointVisible(position);
  }


  @autobind
  private onTreeSelect(item: any, identificator: any): void {
    this.selected = true;
    let selected = [];
    if (item.id !== undefined) {
      selected = [item.id];
    } else {
      selected = this.props.bimids
        .slice(item.start, item.end)
        .map((x: any): any => x.id);
    }
    this.setEngineSelected(selected);
    this.props.selectItem(item, identificator);
  }

  @autobind
  private onAssignColor(): void {
    const ids = this.engine.getSelected();
    this.engine.setColorTint(this.state.color, ids);
  }

  @autobind
  private onAddSelect(item: any, identificator: any): void {
    this.selected = true;
    const selected = this.engine.getSelected();
    if (item.id !== undefined) {
      selected.push(item.id);
    } else {
      this.props.bimids
        .slice(item.start, item.end)
        .forEach((x: any): any => selected.push(x.id));
    }
    this.setEngineSelected(selected);
    this.props.onAddSelectedItem(item, identificator);
  }

  @autobind
  private onDeselectItem(item: any, identificator: any): void {
    this.selected = true;
    let selected = this.engine.getSelected();
    if (item.id !== undefined) {
      const index = selected.indexOf(item.id);
      selected.splice(index, 0);
    } else {
      const ids = this.props.bimids.slice(item.start, item.end).map(x => x.id);
      selected = selected.filter(x => !ids.includes(x));
    }

    this.setEngineSelected(selected);
    this.props.onDeselectItem(item, identificator);
  }


  @autobind
  private toggleUnit(): void {
    if (this.engine) {
      const { isImperialUnit } = this.state;
      this.engine.setUiUnits(
        isImperialUnit
          ? { length: Unit.Meter, area: Unit.MeterPow2 }
          : { length: Unit.FootInch, area: Unit.FootPow2 },
      );
      this.setState({ isImperialUnit: !isImperialUnit });
    }
  }

  @autobind
  private engineToState(state: any): void {
    this.engine.setState(state);
  }

  @autobind
  private onEngineToggleClipBox(isClipBoxEnabled: boolean, mode: string): void {
    if (isClipBoxEnabled) {
      this.props.setClipBoxMode(mode);
      if (!this.props.isClipBox) {
        this.props.onClipBox();
      }
    } else {
      if (this.props.isClipBox) {
        this.props.onClipBox();
      }
    }
  }

  @autobind
  private toggleClipBox(): void {
    if (this.engine) {
      this.engine.toggleClipbox(!this.props.isClipBox, this.props.clipmode);
    }
  }

  @autobind
  private toggleGhost(): void {
    const newClipMode = this.props.clipmode === 'ghost' ? 'none' : 'ghost';
    if (this.engine) {
      if (this.props.isClipBox) {
        this.engine.toggleClipbox(true, newClipMode);
      }
      this.engine.showInvisibleAsGhost(this.props.clipmode === 'ghost' ? false : true);
      this.props.setClipBoxMode(newClipMode);
    }
  }

  @autobind
  private onEngineToggleCameraParallel(isIsometryEnabled: boolean): void {
    this.setState({ isIsometryEnabled });
  }

  @autobind
  private toggleIsometry(): void {
    this.engine.toggleParallelProjection(!this.state.isIsometryEnabled);
  }

  @autobind
  private onEngineToggleRuler(enabledRuler: boolean): void {
    this.setState({ isRulerEnabled: enabledRuler });
  }

  @autobind
  private toggleRuler(): void {
    if (this.engine) {
      const { isRulerEnabled } = this.state;
      this.engine.toggleRuler(!isRulerEnabled);
    }
  }

  @autobind
  private takeSubcommentScreen(api: any): any {
    api(this.engine.takeScreenshotImmidiately(384, 216).PngBase64);
  }

  @autobind
  private onNewCommentFields(): void {
    this.savePoint();
    this.props.showNewCommentField();
  }

  private savePoint(): any {
    const { point, type } = this.engine.getInfoForMark();
    this.props.setTimeMarker({ point, type });
  }


  @autobind
  private setTreeList(ref: any): void {
    this.listprovider = ref;
  }

  @autobind
  private onChangeColor(color: string): void {
    this.setState({ color });
  }

  @autobind
  private mouseMove(e: React.MouseEvent<HTMLDivElement>): any {
    if (this.props.markerMode && !this.props.showNewComment) {
      const target = e.currentTarget.getBoundingClientRect();
      this.tooltip.style.left = `${e.clientX - target.left + 20}px`;
      this.tooltip.style.top = `${e.clientY - target.top - 10}px`;
      this.tooltip.style.display = 'block';
    } else if (this.tooltip) {
      this.tooltip.style.display = 'none';
    }
  }

  @autobind
  private getPosition(e: React.MouseEvent<HTMLDivElement>): any {
    if (this.props.markerMode && !this.props.showNewComment) {
      const target = e.currentTarget.getBoundingClientRect();
      const marker = {
        parrentHeight: target.height,
        parrentWidth: target.width,
        x: e.clientX - target.left,
        y: e.clientY - target.top,
      };
      this.setNewMarkerField(marker);
    } else if (this.props.contextmode) {
      this.props.closeContext();
    }
  }

}

function mapStateToProps(state: State): StateProps {
  return {
    showTree: state.viewer.get('showTree'),
    showComments: state.viewer.get('showComments'),
    commentsLevel: state.viewer.get('commentsLevel'),
    showNewComment: state.viewer.get('showNewComment'),
    project: state.projects.currentProject,
    comments: state.viewersets.comments,
    bimids: state.viewersets.ids,
    markerMode: state.viewer.get('markerMode'),
    markerPosition: state.viewer.get('markerPos').toJS(),
    selectedId: state.viewersets.selectedPathes,
    isClipBox: state.viewer.get('isClipBox'),
    hasSelected: state.viewersets.selectedPathes.length > 0,
    withresolved: state.viewer.get('withresolved'),
    clipmode: state.viewer.get('clipmode'),
    timemarker: state.viewersets.timemarker,
    contextpos: state.viewer.get('contextpos').toJS(),
    contextmode: state.viewer.get('contextmode'),
    userid: state.account.id,
    peoplemap: state.people.usersmap,
    isAutoFocus: state.persistedStorage.isAutoFocusEngine,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    showComment: () => {
      dispatch(ViewerActions.showHideComment());
    },
    showNewCommentField: () => {
      dispatch(ViewerActions.showNewComment());
    },
    loadComments: (canViewAllComments) => {
      dispatch(ViewerActions.getComments(canViewAllComments));
    },
    createComment: (documentId: any, text: any, engine: any, ids: any) => {
      dispatch(ViewerActions.showNewComment());
      dispatch(ViewerActions.createComment(documentId, text, engine, ids));
    },
    goToComment: (comment: any) => {
      dispatch(ViewerActions.goToComment(comment));
      dispatch(ViewerActions.readComment(comment.id));
    },
    goToComments: () => {
      dispatch(ViewerActions.backToComments());
    },
    onMarkerMode: () => {
      dispatch(ViewerActions.onMarkerMode());
    },
    setMarkerPosition: (position: any) => {
      dispatch(ViewerActions.setMarkerPos(position));
      dispatch(ViewerActions.showNewComment());
    },
    onAddSubcomment: (id: any, value: any, parent: any) => {
      dispatch(ViewerActions.onAddSubcomment(id, value, parent));
    },
    getBimInfo: (id: number) => {
      dispatch(ViewerActions.getDocumentInfo(id));
    },
    hide: (ids: any, identificator: any) => {
      dispatch(ViewerActions.hide(identificator, ids));
    },
    show: (ids: any, identificator: any) => {
      dispatch(ViewerActions.show(identificator, ids));
    },
    selectItem: (item: any, identificator: any) => {
      dispatch(ViewerActions.selectItem(item, identificator));
    },
    onClipBox: () => {
      dispatch(ViewerActions.clipbox());
    },
    ghost: (ids: any, identificator: any) => {
      dispatch(ViewerActions.ghost(identificator, ids));
    },
    changeRenderMode: (ids: any, mode: any) => {
      dispatch(ViewerActions.changeRenderMode(ids, mode));
    },
    selectByIds: (ids: number[], listprovider: any) => {
      dispatch(ViewerActions.selectByIds(ids, listprovider));
    },
    dropInfo: () => {
      dispatch(ViewerActions.dropAllInfo());
    },
    setClipBoxMode: (mode: any) => {
      dispatch(ViewerActions.setClipBoxMode(mode));
    },
    setIdsForItem: (identificator: any, ids: any) => {
      dispatch(ViewerActions.setIdsForIdentificator(identificator, ids));
    },
    setTimeMarker: (position: any): any => {
      dispatch(ViewerActions.setTimeMarker(position));
    },
    setContextPositions: (position: any) => {
      dispatch(ViewerActions.setContextPosition(position));
    },
    closeContext: () => {
      dispatch(ViewerActions.closeContext());
    },
    selectComment: (index: number) => {
      dispatch(ViewerActions.selectComment([index]));
    },
    onAddSelectedItem: (item: any, identificator: any) => {
      dispatch(ViewerActions.addSelectedItem({ item, identificator: identificator.split('.') }));
    },
    onDeselectItem: (item: any, identificator: any) => {
      dispatch(
        ViewerActions.deselectItem({ item, identificator: identificator.split('.') }));
    },
    dropQs: () => {
      dispatch(
        push({
          search: '',
        }),
      );
    },
    toggleAutoFocusEngine: () => dispatch(PersistedStorageActions.toggleEngineAutoFocus()),
  };
}

export const ViewerPage = withAbilityContext(connect(mapStateToProps, mapDispatchToProps)(ViewerPageComponent));
