import { EMPTY_VALUE } from 'common/components/tree-filter-panel/constants';
import {
  AGG_HASH,
} from '../../components/quantity-take-off-common-table/custom-cell/aggregation-cell/aggregation-cell';
import { QtoLeftPanelConstants } from '../../components/quantity-take-off-left-panel/constants';
import { QtoTreeRowProperty } from '../../interfaces/quantity-take-off';

const disabledDataKeys: Record<string, boolean> = {
  [QtoLeftPanelConstants.ELEMENT_TABLE_BIM_ELEMENT_ID_KEY]: true,
  [AGG_HASH]: true,
  [QtoLeftPanelConstants.ELEMENT_TABLE_NAME_KEY]: true,
};

function isValueEmpty(value: string): boolean {
  return typeof value !== 'number'
    && (!value || value.toLowerCase() === EMPTY_VALUE);
}

function isValidProperty(key: string, value: string): boolean {
  return !disabledDataKeys[key] && !isValueEmpty(value);
}

function convertToTreeRowProperty(value: string): QtoTreeRowProperty {
  const isNumber = value !== null && !Number.isNaN(Number(value));
  return {
    default: isNumber ? Number(value) : value,
  };
}

export const BreakdownPropertiesUtils = {
  isValueEmpty,
  isValidProperty,
  convertToTreeRowProperty,
};
