import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { change, InjectedFormProps, reduxForm } from 'redux-form';

import './profile-dialog.scss';

import { StateStatus } from 'common/interfaces/account';
import { KreoDialogActions } from 'common/UIKit';
import { State as ReduxState } from '../../../../common/interfaces/state';
import { KreoFormDialog } from '../../../../components/dialog/base/kreo-form-dialog';
import { PROFILE } from '../../../../constants/forms';
import { AccountActions } from '../../actions/creators';
import { ProfileSection } from './sections';

interface InitialData {
  firstName: string;
  lastName: string;
  companyName?: string;
  companyId?: number;
  email: string;
  resetAvatar?: boolean;
  avatarFile?: string;
}

export interface FormValues {
  firstName: string;
  lastName: string;
  companyName: string;
  companyId: number;
  email: string;
  avatar?: string;
  oldPassword?: string;
  newPassword?: string;
}

interface ReduxProps {
  userHasOwnCompany: boolean;
  initialData: InitialData;
  show: boolean;
  accountStatus: StateStatus;
  userId: string;
  submitDisabled: boolean;
}

interface ReduxActions {
  saveProfileSettings: () => void;
  logOut: () => void;
  setImage: (name: string) => void;
  deleteImage: () => void;
  goToPeople: () => void;
}

interface Props extends ReduxProps, ReduxActions, InjectedFormProps<{}, {}> { }

export const ProfileDialogName = 'profileDialog';

class ProfileDialogComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <KreoFormDialog
        name={ProfileDialogName}
        showActions={false}
        handleSubmit={this.onSubmit}
        onDialogClose={this.onDialogClose}
        formName={PROFILE}
        bodyClassName='profile-dialog'
        overlayClassName='profile-dialog-overlay'
        title='Account'
        modal={false}
      >
        <ProfileSection
          userId={this.props.userId}
          onDialogClose={this.onDialogClose}
          logOut={this.logOut}
          setImage={this.props.setImage}
          deleteImage={this.props.deleteImage}
          accountStatus={this.props.accountStatus}
          canEditCompany={this.props.userHasOwnCompany}
          submitDisabled={this.props.submitDisabled}
          saveProfileSettings={this.props.saveProfileSettings}
        />
      </KreoFormDialog>
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.show !== this.props.show && this.props.show) {
      this.props.initialize(this.props.initialData);
    }
  }

  @autobind
  private onSubmit(): void {
    if (!this.props.submitDisabled) {
      this.props.saveProfileSettings();
    }
  }

  @autobind
  private onDialogClose(): void {
    this.props.reset();
  }

  @autobind
  private logOut(): void {
    this.props.reset();
    this.props.logOut();
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const  { account, dialog, form } = state;
  const ownedCompany = account.ownedCompany;
  const userHasOwnCompany = !!ownedCompany;
  const userCompany = userHasOwnCompany && account.companies?.find(company => company.id === ownedCompany.id);
  const formData = form[PROFILE] && form[PROFILE].values;
  const submitDisabled = !formData || !formData.companyName || !formData.firstName || !formData.lastName
    || (!!formData.oldPassword && !formData.newPassword) || (!formData.oldPassword && !!formData.newPassword);

  return {
    userHasOwnCompany,
    initialData: {
      firstName: account.firstName,
      lastName: account.lastName,
      companyName: userCompany ? userCompany.name : null,
      companyId: userCompany ? userCompany.id : null,
      email: account.email,
      resetAvatar: false,
    },
    userId: account.id,
    show: ProfileDialogName in dialog,
    accountStatus: account.status,
    submitDisabled,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    logOut: () => {
      dispatch(KreoDialogActions.closeDialog(ProfileDialogName));
      dispatch(AccountActions.logOut(false));
    },
    setImage: (name: string) => {
      dispatch(change(PROFILE, 'avatarFile', name));
    },
    deleteImage: () => dispatch(change(PROFILE, 'resetAvatar', true)),
    goToPeople: () => {
      dispatch(KreoDialogActions.closeDialog(ProfileDialogName));
      dispatch(push('/people'));
    },
    saveProfileSettings: () => {
      dispatch(AccountActions.setUserInfo());
    },
  };
};

export const ProfileDialog = reduxForm<any, any>({
  form: PROFILE,
  destroyOnUnmount: true,
  initialValues: {},
})(connect(mapStateToProps, mapDispatchToProps)(ProfileDialogComponent));
