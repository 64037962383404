const prefix = '@pdf-filter';

export const PdfFilterActionTypes = {
  SWITCH: `${prefix}/SWITCH`,
  CREATE_EMPTY: `${prefix}/CREATE_EMPTY`,
  ADD_RECT: `${prefix}/ADD_RECT`,
  HIDE_BACKGROUND: `${prefix}/HIDE_BACKGROUND`,
  SAVE_FILE_FILTER: `${prefix}/SAVE_FILE_FILTER`,
  SET_STATUS: `${prefix}/SET_STATUS`,
};
