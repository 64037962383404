import styled from 'styled-components';
import { PdfBrowserFileTreeBodyStyled } from './body';
import { PdfBrowserFileTreeFooterStyled } from './footer';


const WIDTH = 400;

function getBodyHeightDiff(p: { hasFooter: boolean }): number {
  return + PdfBrowserFileTreeFooterStyled.HEIGHT * Number(p.hasFooter);
}

const Container = styled.div<{ hasFooter: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${WIDTH}px;
  border-right: 1px solid ${p => p.theme.color.background};
  ${PdfBrowserFileTreeBodyStyled.Container} {
    height: calc(100% - ${p => getBodyHeightDiff(p)}px);
    box-sizing: border-box;
    padding-bottom: ${p => p.hasFooter ? 0 : 20}px;
  }

  .ag-theme-balham-dark {
    > div {
      max-height: calc(100% - ${p => p.hasFooter ? 0 : 20}px);

      > div {
        border-bottom: 0;
      }
    }
  }
`;

export const Styled = {
  Container,
  WIDTH,
};
