import { DrawingMarkShapes, DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { ShortPointDescription } from 'common/components/drawings/interfaces/drawing-ai-annotation';
import { DrawingsGeometryType } from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingRulerPoints } from 'common/components/drawings/interfaces/drawings-user-annotation';
import { DrawingsInstanceType } from '../../../enums/drawings-instance-type';
import { DrawingsPaperColorInfo } from '../../../interfaces/drawings-canvas-context-props';
import { EntityWithSelfSnapping, SnappingGuideInfo } from '../../drawings-helpers/snapping/with-self-snapping';
import { NewDrawingSettings, ShapeFactory } from '../../interfaces';

export interface FinishedDrawingElement {
  type: DrawingsInstanceType;
  geometry: DrawingsGeometryType;
  name?: string;
  points: Record<string, ShortPointDescription>;
}

export interface FinishedDrawingRuler {
  points: DrawingRulerPoints;
  color: string;
}

export interface DrawingsGeometryTempEntity {
  convert(
    addLastPoint?: boolean,
    instanceType?: DrawingsInstanceType,
    keyPrefix?: string,
  ): FinishedDrawingElement[] | FinishedDrawingRuler;
}

export interface InstanceWithTempPoint {
  updateTempPointPosition(point: paper.Point, strictAngle?: boolean): paper.Point;
  removeLastPoint(): void;
}

export interface DrawingsGeometryTempEntityConfig {
  color: DrawingsPaperColorInfo;
  layer: paper.Layer;
  strokeStyle: DrawingStrokeStyles;
  strokeWidth: number;
  shape: DrawingMarkShapes;
  shapeCreator: ShapeFactory;
  newDrawingStylesObserver: ContextObserver<NewDrawingSettings>;
}

export interface TryAddPointConfig {
  pathOnly?: boolean;
  mousePoint?: paper.Point;
  event?: PaperMouseEvent;
}


export abstract class DrawingsGeometryTempEntity<
  T extends DrawingsGeometryTempEntityConfig = DrawingsGeometryTempEntityConfig
> implements InstanceWithTempPoint, EntityWithSelfSnapping {
  protected _config: T;

  constructor(config: T) {
    this._config = config;
  }

  public get isValid(): boolean {
    return true;
  }

  public get canSave(): boolean {
    return true;
  }

  public set strokeStyle(value: DrawingStrokeStyles) {
    this._config.strokeStyle = value;
  }

  public set strokeWidth(value: number) {
    this._config.strokeWidth = value;
  }

  public set shape(value: DrawingMarkShapes) {
    this._config.shape = value;
  }

  public get lastStablePoint(): paper.Point {
    return null;
  }

  public get lastPoint(): paper.Point {
    return null;
  }

  public abstract get pointsCount(): number;

  public abstract get hasPoint(): boolean;

  public abstract canComplete(addLastPoint: boolean): boolean;

  public abstract getPoint(index: number): paper.Point;

  public abstract removeLastPoint(): paper.Point | null | Promise<paper.Point | null>;

  public abstract destroy(): void;

  public abstract tryAddPoint(point: paper.Point, config?: TryAddPointConfig): boolean;

  public abstract updateColor(color: DrawingsPaperColorInfo): void;

  public abstract updateTempPointPosition(point: paper.Point, strictAngle?: boolean): paper.Point;

  public abstract getSnappingGuideInfo(point: paper.Point): SnappingGuideInfo;
}
