import { Constants } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { AnyAction, Dispatch } from 'redux';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { State } from 'common/interfaces/state';
import { PersistedStorageActions } from '../../units/persisted-storage/actions/creators';
import { PERSISTED_STORAGE_INITIAL_STATE } from '../../units/persisted-storage/constants/initial-state';
import { ThemeType } from './interfaces';


interface StateProps {
  theme: ThemeType;
  darkTone: string;
  lightTone: string;
}

interface DispatchProps {
  onChangeThemeTone: (theme: ThemeType, tone: string) => void;
  setDarkTheme: () => void;
}

interface Props extends DispatchProps, StateProps {
  children?: React.ReactNode;
}

class ThemeProviderHocComponent extends React.Component<Props> {
  public componentDidMount(): void {
    this.setDefaultTonesIfNeeded();
  }

  public render(): React.ReactNode {
    return (
      <StyledComponentsThemeProvider theme={this.getTheme()}>
        {this.props.children}
      </StyledComponentsThemeProvider>
    );
  }

  @autobind
  private setDefaultTonesIfNeeded(): void {
    if (!Constants.Colors.DARK_TONES[this.props.darkTone]) {
      this.props.onChangeThemeTone(Constants.Theme.DARK_THEME.name, PERSISTED_STORAGE_INITIAL_STATE.darkTone);
    }
    if (!Constants.Colors.LIGHT_TONES[this.props.lightTone]) {
      this.props.onChangeThemeTone(Constants.Theme.LIGHT_THEME.name, PERSISTED_STORAGE_INITIAL_STATE.lightTone);
    }
  }

  @autobind
  private getTheme(): any {
    const { theme, darkTone, lightTone } = this.props;
    const isNoTheme = theme === undefined;
    const isDarkTheme = theme === Constants.Theme.DARK_THEME.name || isNoTheme;
    const currentTheme = Constants.Theme[isDarkTheme ? 'DARK_THEME' : 'LIGHT_THEME'];
    const isToneEnabled = isDarkTheme && darkTone && darkTone !== PERSISTED_STORAGE_INITIAL_STATE.darkTone
      || !isDarkTheme && lightTone && lightTone !== PERSISTED_STORAGE_INITIAL_STATE.lightTone;
    const toneColors = isToneEnabled &&
      Constants.Colors[isDarkTheme ? 'DARK_TONES' : 'LIGHT_TONES'][isDarkTheme ? darkTone : lightTone];
    if (isNoTheme) {
      this.props.setDarkTheme();
    }
    return isToneEnabled
      ? { ...currentTheme, color: { ...(currentTheme as any).color, ...toneColors } }
      : currentTheme;
  }
}

function mapStateToProps({ persistedStorage }: State): StateProps {
  return {
    theme: persistedStorage.theme,
    darkTone: persistedStorage.darkTone,
    lightTone: persistedStorage.lightTone,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    onChangeThemeTone: (theme, tone) => dispatch(PersistedStorageActions.setThemeTone({ theme, tone })),
    setDarkTheme: () => dispatch(PersistedStorageActions.setTheme(Constants.Theme.DARK_THEME.name)),
  };
};

export const ThemeProvider = connect(mapStateToProps, mapDispatchToProps)(ThemeProviderHocComponent);
