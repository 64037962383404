import Axios, { Canceler } from 'axios';
import { EventChannel, eventChannel } from 'redux-saga';

import { ConstantFunctions } from 'common/constants/functions';
import { Location } from 'common/interfaces/common-state';
import { CommonApi } from '../server';

function uploadFile(file: File): [Promise<string[]>, EventChannel<number>, Canceler] {
  let emit: (progress: number) => void;
  const channel = eventChannel<number>(emitter => {
    emit = emitter;
    return ConstantFunctions.doNothing;
  });
  const cancelTokenSource = Axios.CancelToken.source();
  const promise = new Promise<string[]>((resolve, reject) => {
    CommonApi.uploadFile<string[]>(
      '/common/upload',
      file,
      progress => {
        emit(progress);
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error.message);
      },
      cancelTokenSource,
    );
  });
  return [promise, channel, cancelTokenSource.cancel];
}

function getLocations(): Promise<Location[]> {
  return CommonApi.get<Location[]>('/common/locations', null);
}

function uploadFileWithoutProgressTracking(file: File): Promise<string[]> {
  return uploadFile(file)[0];
}

const tempFileLink = '/common/temp';

export const CommonsApi = {
  uploadFile,
  getLocations,
  uploadFileWithoutProgressTracking,
  tempFileLink,
};
