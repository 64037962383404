import { ShortPointDescription } from './drawing-ai-annotation';

export enum TextSearchPagesType {
  All = 'all',
  Current = 'current',
}

export interface TextSearchSettings {
  query: string;
  caseSensitive: boolean;
  wholeWord: boolean;
  regExp: boolean;
  pages: TextSearchPagesType;
}

export interface TextSearchResult {
  rectangleBounds: ShortPointDescription[];
  ambient: string;
  output: string;
  id: number;
  drawingId: string;
}


export interface TextSearchResultsInfo {
  count: number;
  results: Record<string, TextSearchResult[]>;
}
