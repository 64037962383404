import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import {
  ApiDeletePeopleFromProject,
  ApiRejectInviteFromCompany,
  InvitationCompanyUsers,
} from 'common/interfaces/people';
import { State } from 'common/interfaces/state';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { Forms } from '../../constants';
import { AccountActions } from '../account/actions/creators';
import { ProjectsActions } from '../projects/actions/creators/common';
import { ProjectSelectors } from '../projects/selectors';
import { PeopleActions } from './actions/actions';
import * as PeoplePayloads from './actions/payloads';
import { PeopleActionTypes } from './actions/types';
import { PeopleApi } from './api';
import { Person } from './interfaces/person';


function* getCompanyUsers({ payload: companyId }: ActionWith<number>): SagaIterator {
  try {
    const users: Person[] = yield call(PeopleApi.getCompanyUsers);
    yield put(PeopleActions.getCompanyPeopleSucceeded(companyId, users));
  } catch (error) {
    console.error('people: get company users failed', error, { companyId });
  }
}

function* inviteUsersToCompany(): SagaIterator {
  try {
    const state: State = yield select();
    const companyId = state.account.selectedCompany.id;
    const form: InvitationCompanyUsers = state.form[Forms.INVITE_TO_COMPANY].values;
    form.companyId = companyId;
    const users: Person[] = yield call(PeopleApi.inviteUsersToCompany, form);
    yield put(PeopleActions.getCompanyPeopleSucceeded(companyId, users));
  } catch (error) {
    console.error('people: invite users to company failed', error);
  }
}

function* invitePeopleToCompany({ payload: invites }: ActionWith<InvitationCompanyUsers[]>): SagaIterator {
  try {
    const companyId = invites[0].companyId;
    const users = {};
    for (const invite of invites) {
      const result = yield call(PeopleApi.inviteUsersToCompany, invite);
      result.forEach(u => users[u.id] = u);
    }
    yield put(PeopleActions.getCompanyPeopleSucceeded(companyId, Object.values(users)));
  } catch (error) {
    console.error('people: invite people to company failed', error, invites);
  }
}


function* invitePeopleToProject(
  { payload }: ActionWith<PeoplePayloads.InviteToProjectParams>,
): SagaIterator {
  try {
    const companyId: number = yield selectWrapper(s => s.account.selectedCompany.id);
    yield call(PeopleApi.inviteUsersToProject, payload.projectId, payload);
    yield put(PeopleActions.getCompanyPeople(companyId));
  } catch (error) {
    console.error('people: invite people to project failed', error, payload);
  }
}

function* deleteCompanyPerson({ payload: userToDeleteId }: ActionWith<string>): SagaIterator {
  try {
    const [companyId, loggedInUserId]: [number, string] = yield selectWrapper(
      (state) => [state.account.selectedCompany.id, state.account.id],
    );
    const users = yield call(PeopleApi.deleteCompanyPerson, userToDeleteId);
    if (loggedInUserId === userToDeleteId) {
      yield put(AccountActions.fetchCompanies());
    }
    yield put(PeopleActions.getCompanyPeopleSucceeded(companyId, users));
  } catch (error) {
    console.error('people: delete company person failed', error, { userToDeleteId });
  }
}

function* rejectInvite({ payload: email }: ActionWith<string>): SagaIterator {
  try {
    const [companyId, subscriptionType] = yield selectWrapper(s => [
      s.account.selectedCompany.id,
      s.account.selectedSubscriptionType,
    ]);
    const requestPayload: ApiRejectInviteFromCompany = {
      subscriptionType,
      companyId,
      email,
    };
    yield call(PeopleApi.rejectInvitation, requestPayload);
    yield put(PeopleActions.rejectSucceeded(companyId, email));
  } catch (error) {
    console.error('people: reject invite failed', error, { email });
  }
}

function* removeFromProject({ payload }: ActionWith<ApiDeletePeopleFromProject>): SagaIterator {
  try {
    const { projectId, emails } = payload;
    yield call(PeopleApi.removeUsersFromProject, projectId, emails);
    yield put(PeopleActions.removeFromProjectSuccessed(emails));
  } catch (error) {
    console.error('people: remove from project failed', error, payload);
  }
}

function* updateUserRole(action: ActionWith<PeoplePayloads.UpdateUserRolePayload>): SagaIterator {
  try {
    const { userId, companyId, roleId } = action.payload;
    yield call(PeopleApi.updateUserRole, userId, roleId);
    yield put(PeopleActions.updateUserRoleSucceeded(userId, companyId, roleId));
    yield put(AccountActions.fetchCompanies());
  } catch (error) {
    console.error('people: update user role failed', error, action.payload);
  }
}

function* updateInvitedPeoples({ payload }: ActionWith<PeoplePayloads.UpdateInvitationToProjectParams>): SagaIterator {
  try {
    const state: State = yield select();
    const projectHeader =
      yield selectWrapper(s => ProjectSelectors.findProjectHeader(s.projects, payload.projectId));
    const newEmailSet = new Set(payload.newEmails);
    const oldEmailSet = new Set(payload.oldEmails);
    const newProjectHeader = {
      ...projectHeader,
      users: state.people.companiesUsers
        .filter(user => newEmailSet.has(user.email))
        .map(user => {
          return {
            email: user.email,
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
          };
        }),
      isCompanyShared: payload.isCompanyShared,
    };
    const invitedList = payload.newEmails.filter(email => !oldEmailSet.has(email));
    const rejectedList = payload.oldEmails.filter(email => !newEmailSet.has(email));
    if (invitedList.length || projectHeader.isCompanyShared !== payload.isCompanyShared) {
      yield put(PeopleActions.invitePeopleToProject({
        projectId: payload.projectId,
        emails: invitedList,
        isCompanyShared: payload.isCompanyShared,
      }));
    }
    if (rejectedList && rejectedList.length) {
      yield put(PeopleActions.removeFromProject({ projectId: payload.projectId, emails: rejectedList }));
    }
    if (projectHeader) {
      yield put(ProjectsActions.locallyUpdateProjectHeader(newProjectHeader));
    }
  } catch (error) {
    console.error('people: update invited peoples failed', error, payload);
  }
}

function* resendInvitation({ payload: userId }: ActionWith<string>): SagaIterator {
  try {
    yield call(PeopleApi.resendInvitation, userId);
  } catch (error) {
    console.error('resend invitation', error);
  }
}

export function* peopleSagas(): SagaIterator {
  yield takeLatest(PeopleActionTypes.COMPANY_PEOPLE_GET_REQUEST, getCompanyUsers);
  yield takeLatest(PeopleActionTypes.INVITE_TO_COMPANY_REQUEST, inviteUsersToCompany);
  yield takeLatest(PeopleActionTypes.INVITE_TO_PROJECT_REQUEST, invitePeopleToProject);
  yield takeEvery(PeopleActionTypes.DELETE_COMPANY_PERSON_REQUEST, deleteCompanyPerson);
  yield takeLatest(PeopleActionTypes.REJECT_INVITATION, rejectInvite);
  yield takeLatest(PeopleActionTypes.REMOVE_FROM_PROJECT, removeFromProject);
  yield takeEvery(PeopleActionTypes.UPDATE_USER_ROLE_REQUEST, updateUserRole);
  yield takeLatest(PeopleActionTypes.INVITE_TO_COMPANY, invitePeopleToCompany);
  yield takeLatest(PeopleActionTypes.UPDATE_INVITED_PEOPLES, updateInvitedPeoples);
  yield takeLatest(PeopleActionTypes.RESEND_INVITATION, resendInvitation);
}
