import autobind from 'autobind-decorator';
import { Canceler } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import {
  change,
  Field,
  FormSubmitHandler,
  formValueSelector,
} from 'redux-form';

import './create-project-dialog.scss';

import { FileUploadWrapObsolete } from 'common/components/file-upload-wrap';
import { ApiConstants } from 'common/constants/api';
import { ControlNames } from 'common/constants/control-names';
import { ProjectType } from 'common/constants/project-type';
import { MaterialInputField, MaterialInputProps } from 'common/UIKit';
import { Common } from '../../../actions';
import { UploadingFile } from '../../../common/interfaces/common-state';
import { State as ReduxState } from '../../../common/interfaces/state';
import { CREATE_PROJECT } from '../../../constants/forms';
import { KreoFormDialog } from '../base/kreo-form-dialog';
import { Required } from '../validators';


const selector = formValueSelector(CREATE_PROJECT);

interface ReduxProps {
  projectName: string;
  files: UploadingFile[];
  isButtonDisabled: boolean;
  isDownloadFileInProgress: boolean;
  cancelDownLoadFile: Canceler;
}

interface ReduxActions {
  setName: (value: string) => void;
  setType: (value: string) => void;
  clearFiles: () => void;
  removeDownloadFileName: () => void;
}

interface Props extends ReduxProps, ReduxActions {
  extensions: string[];
  extensionsToShow: string[];
  dialogName: string;
  projectType: ProjectType;
  handleSubmit: (handler: FormSubmitHandler<{}, {}>) => void;
  reset: () => void;
  children?: React.ReactNode;
}

const validateFields = {
  name: 'Required',
  files: { _error: 'Required' },
};

function trimNameValidate(value: string, _values: any, _: any, fieldName: string): string | undefined {
  if (value.length > 0 && value.trim().length === 0) {
    return `${fieldName[0].toUpperCase() + fieldName.slice(1)} can not contain only spaces`;
  }
  return undefined;
}

export const projectValidateRequired = [Required, trimNameValidate];

export const fileUploadValidateRequired = [Required];

class CreateProjectCommonDialogComponent extends React.Component<Props> {

  public render(): React.ReactNode {
    const { isButtonDisabled, isDownloadFileInProgress, dialogName } = this.props;
    return (
      <KreoFormDialog
        submitDisabled={isButtonDisabled}
        name={dialogName}
        title='New Project'
        submitButtonText='Create Project'
        handleSubmit={this.handleSubmit}
        onDialogClose={this.onDialogClose}
        modal={false}
        formName={CREATE_PROJECT}
        validateFields={validateFields}
        bodyClassName='create-project-dialog'
        controlName={ControlNames.createProjectForm}
      >
        <Field<MaterialInputProps>
          name='name'
          component={MaterialInputField}
          label='Project name'
          validate={projectValidateRequired}
        />
        <FileUploadWrapObsolete
          onUploadStarted={this.onUploadStarted}
          onDeleteFile={this.onDeleteFile}
          isDownloadFileInProgress={isDownloadFileInProgress}
          formName={CREATE_PROJECT}
          validate={fileUploadValidateRequired}
          extensions={this.props.extensions}
          extensionsToShow={this.props.extensionsToShow}
        />
        {this.props.children}
      </KreoFormDialog>
    );
  }

  @autobind
  private handleSubmit(handler: FormSubmitHandler<{}, {}>): void {
    this.props.setType(this.props.projectType);
    this.props.handleSubmit(handler);
    this.onDialogClose();
  }

  @autobind
  private onDialogClose(): void {
    this.props.reset();
    this.props.clearFiles();
    if (this.props.isDownloadFileInProgress) {
      this.props.cancelDownLoadFile(ApiConstants.RequestCancelMessage);
      this.props.removeDownloadFileName();
    }
  }

  private getExtensionsRegExp(extensions: string[]): RegExp {
    const regexExtensions = extensions
      .map(x => `\\.${x}`)
      .join('|');

    return new RegExp(`(${regexExtensions})$`, 'i');
  }


  @autobind
  private onDeleteFile(file: UploadingFile): void {
    const { projectName, setName, files, extensions } = this.props;
    const projectFileExtensionRegExp = this.getExtensionsRegExp(extensions);
    if (file && file.file.name.replace(projectFileExtensionRegExp, '') === projectName) {
      if (files.length > 1) {
        setName(files[1].file.name);
      } else {
        setName('');
      }
    }
  }

  @autobind
  private onUploadStarted(files: File[]): void {
    const { projectName, setName, extensions } = this.props;
    if (!projectName && files && files.length) {
      const projectFileExtensionRegExp = this.getExtensionsRegExp(extensions);
      setName(files[0].name.replace(projectFileExtensionRegExp, ''));
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const isDownloadFileInProgress = !!state.common.downloadFileInfo;
  return {
    projectName: selector(state, 'name'),
    isDownloadFileInProgress,
    isButtonDisabled: !state.common.files.length || state.common.files.some(x => x.progress < 1),
    files: state.common.files,
    cancelDownLoadFile: isDownloadFileInProgress ? state.common.downloadFileInfo.canceler : null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setName: (value) => dispatch(change(CREATE_PROJECT, 'name', value)),
    clearFiles: () => dispatch(Common.commonClearFiles()),
    removeDownloadFileName: () => dispatch(Common.removeDownloadFileName()),
    setType: (type) => dispatch(change(CREATE_PROJECT, 'type', type)),
  };
};

export const CreateProjectCommonDialog = connect(mapStateToProps, mapDispatchToProps)
(CreateProjectCommonDialogComponent);
