import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './quantity-take-off-custom-filter-builder.scss';

import { AnyAction, Dispatch } from 'redux';
import { CustomElementFilterBuilder } from 'common/components/custom-element-filter-builder';
import { FilterItemTypes } from 'common/components/custom-element-filter-builder/inner-enums';
import { SvgSpinner } from 'common/components/svg-spinner';
import {
  CustomElementFilterBuilderConfig,
  CustomElementFilterBuilderConfigOptionPredefineValue,
  CustomElementFilterBuilderModel,
  CustomElementFilterForm,
  CustomElementFilterNumber,
  CustomElementFilterSimpleViewModel,
  CustomElementFilterString,
  CustomElementFilterViewModel,
} from 'common/interfaces/custom-element-filter-builder';
import { State } from 'common/interfaces/state';

import { MapIdHelper } from '../../../../components/engine/map-id-helper';
import { CustomFiltersActions } from '../../actions/creators/custom-filters-actions';
import { PropertiesDataProviderApi } from '../../interfaces/properties-provider-types';
import { QtoRecords } from '../../interfaces/quantity-take-off';
import {
  QtoGlobalConfig,
  QtoGlobalConfigPredefineValue,
} from '../../interfaces/quantity-take-off/quantity-take-off-global-config';
import { QtoCustomFilterValueGetter } from '../../utils/quantity-take-off-custom-filter-value-getter';
import {
  CustomElementFilterConditionValidator,
  CustomElementFilterConditionVlidatationResult,
} from '../../utils/quantity-take-off-tree-table/custom-element-filter-condition-validator';
import { withPropertiesDataProviderApi } from '../with-properties-data-provider-api';


interface OwnProps {
  filterId: string;
}

interface OwnState {
  config: CustomElementFilterBuilderConfig;
}

interface DispatchProps {
  loadFilter: () => void;
  saveFilterChanges: (form: CustomElementFilterForm) => void;
  setEditFilter: (model: CustomElementFilterViewModel) => void;
  clearCustomFilterEditModel: () => void;
}

interface ReduxProps {
  globalConfig: QtoGlobalConfig;
  filterListing: CustomElementFilterSimpleViewModel[];
  filterModel: CustomElementFilterViewModel;
  elementRecords: QtoRecords;
}

interface Props extends OwnProps, ReduxProps, DispatchProps, PropertiesDataProviderApi {
  recordValuesMap: Record<string, Record<string, void>>;
  mapIdHelper: MapIdHelper;
  closeFilterEditor: () => void;
}

class QtoCustomFilterBuilderComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      config: null,
    };

    if (props.filterId) {
      this.props.loadFilter();
    }
  }

  public static getDerivedStateFromProps(nextProps: Props): OwnState {
    const { globalConfig, filterListing, filterModel, recordValuesMap } = nextProps;
    if (!globalConfig && !filterListing) {
      return;
    }

    return {
      config: QtoCustomFilterBuilderComponent.getConfig(
        globalConfig,
        recordValuesMap,
        filterListing,
        filterModel && filterModel.nonReferenceableFilterIds,
      ),
    };
  }

  public componentDidUpdate(prevProps: Props, _prevState: OwnState): void {
    if (prevProps.filterId !== this.props.filterId && this.props.filterId) {
      this.props.loadFilter();
    }
  }

  public render(): JSX.Element {
    const { globalConfig, filterId, filterModel } = this.props;
    const model = filterModel && filterModel.filters[filterId];

    if (!filterId) {
      return (
        <div className='quantity-take-off-custom-filter-builder__placeholder'>
          Please, Select Filter in Filters tab
        </div>
      );
    }

    return globalConfig && model
      ? (
        <CustomElementFilterBuilder
          onFilterChange={this.onFilterChange}
          closeFilterEditor={this.onCloseFilterEditor}
          filter={model}
          config={this.state.config}
          validateModelUsing={this.validateModelUsing}
        />
      )
      : (<SvgSpinner size='middle'/>);
  }

  private static getConfig(
    config: QtoGlobalConfig,
    modelValuesMap: Record<string, Record<string, void>>,
    filterListing: CustomElementFilterSimpleViewModel[],
    nonReferenceableFilterIds: string[],
  ): CustomElementFilterBuilderConfig {
    const stringOptions = [];
    const referenceOptions = [];
    if (config) {
      Object.entries(config.all_props).forEach(([key, option]) => {
        stringOptions.push({
          name: option.name,
          type: FilterItemTypes.String,
          value: key,
          isContainsInModel: key in modelValuesMap,
          predefineValues: this.getPredefinedValue(option.values, modelValuesMap[key]),
        });
      });
    }

    if (filterListing) {
      filterListing.forEach(option => {
        referenceOptions.push({
          name: option.name,
          type: FilterItemTypes.Reference,
          value: option.id,
          isContainsInModel: true,
        });
      });
    }

    const disabledKeys = nonReferenceableFilterIds
      ? nonReferenceableFilterIds.reduce(
        (result, key) => {
          result[key] = true;
          return result;
        },
        {},
      )
      : {};

    return {
      stringOptions,
      modelValuesMap,
      referenceOptions,
      disabledKeys,
    };
  }

  private static getPredefinedValue(
    values: QtoGlobalConfigPredefineValue[], valuesMap: Record<string, void>,
  ): CustomElementFilterBuilderConfigOptionPredefineValue[] {
    const result = [];
    const usedValues = {};

    for (const value of values) {
      const isContainsInModel = valuesMap && value.name in valuesMap;
      result.push({
        name: value.name,
        isContainsInModel,
        children: value.children,
      });

      usedValues[value.name] = true;
    }

    if (valuesMap) {
      Object.keys(valuesMap).forEach(key => {
        if (!(key in usedValues)) {
          result.push({
            name: key,
            isContainsInModel: true,
          });
        }
      });
    }

    return result;
  }

  @autobind
  private validateModelUsing(
    filterConditionsMap: Record<string, CustomElementFilterNumber | CustomElementFilterString>,
  ): CustomElementFilterConditionVlidatationResult {
    const { elementRecords, getElementPropertyNameToValuesMap, mapIdHelper } = this.props;
    const valueGetter = QtoCustomFilterValueGetter.getQtoCustomFilterValueGetterFunction(
      getElementPropertyNameToValuesMap,
      mapIdHelper.mapBimIdsToEngineIds,
    );

    return CustomElementFilterConditionValidator.validate(elementRecords, filterConditionsMap, valueGetter);
  }

  @autobind
  private onCloseFilterEditor(): void {
    this.props.clearCustomFilterEditModel();
    this.props.closeFilterEditor();
  }

  @autobind
  private onFilterChange(model: CustomElementFilterBuilderModel): void {
    this.props.saveFilterChanges(model);

    const newFilterModel: CustomElementFilterViewModel = {
      ...this.props.filterModel,
      filters: {
        ...this.props.filterModel.filters,
      },
    };
    newFilterModel.filters[model.id] = model;
    this.props.setEditFilter(newFilterModel);
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  return {
    globalConfig: state.quantityTakeOff.globalConfig,
    filterListing: state.quantityTakeOff.customFilters,
    filterModel: state.quantityTakeOff.customFilterEditModel,
    elementRecords: state.quantityTakeOff.elementRecords,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  return {
    loadFilter: () => dispatch(CustomFiltersActions.getCustomFiltersByIds([props.filterId])),
    saveFilterChanges: (form) => dispatch(CustomFiltersActions.updateCustomFilters([form])),
    setEditFilter: (filter) => dispatch(CustomFiltersActions.setCustomFilterEditModel(filter)),
    clearCustomFilterEditModel: () => dispatch(CustomFiltersActions.setCustomFilterEditModel(null)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const QtoCustomFilterBuilder = withPropertiesDataProviderApi(connector(QtoCustomFilterBuilderComponent));
