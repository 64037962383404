import { LocationDescriptorObject } from 'history';
import QueryString from 'query-string';
import { MenuName, MenuNames } from 'unit-2d-database/interfaces';

interface QueryRouteParams {
  tab?: MenuName;
  fastNavigationType?: MenuName;
}

function getSearch(name: MenuName): QueryRouteParams {
  const key = 'tab';
  const search = QueryString.parse(location.search);
  search[key] = name;
  return search;
}

function getValue(queryString: string): MenuName {
  if (queryString) {
    const params = QueryString.parse(queryString) as QueryRouteParams;
    const tab = params.tab?.toLowerCase();
    const value = MenuNames.find(x => x.toLowerCase() === tab);
    if (value) {
      return value;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function getCurrentTabAndUpdateUrl(
  queryString: string,
  push: (location: LocationDescriptorObject) => void,
  locationSearch?: string,
): MenuName {
  const pushMenuItemToUrl = (name: MenuName): void => {
    const search = getSearch(name);
    push({ search: QueryString.stringify(search) });
  };
  const value = getValue(queryString);
  const valueAssembliesTab = getValue(locationSearch);

  if (value) {
    return value;
  } else if (valueAssembliesTab) {
    pushMenuItemToUrl(valueAssembliesTab);
    return valueAssembliesTab;
  } else {
    pushMenuItemToUrl(MenuName.Properties);
    return MenuName.Properties;
  }
}


export function getQueryStringByTab(name: MenuName): string {
  const search = getSearch(name);
  return QueryString.stringify(search);
}
