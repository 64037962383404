import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { VideoUrl } from 'common/constants/video-url';
import { FaqCaption } from 'common/enums/faq-caption';
import { HeaderContext } from 'common/enums/header-context';
import { KreoColors } from 'common/enums/kreo-colors';
import { PageHeader } from 'common/interfaces/page-header';
import { State } from 'common/interfaces/state';
import { HeadlessPageLayout } from '../../../layouts/headless-page-layout';
import { PageLayout } from '../../../layouts/page-layout';
import { NotFoundPage } from '../../../units/notfound/page';
import { ProjectsActions } from '../../../units/projects/actions/creators/common';
import { Project } from '../../../units/projects/interfaces/project';
import { bidPricingRoutes } from '../../../units/projects/pages/bid-pricing/routes';
import { CostsPage } from '../../../units/projects/pages/costs';
import { DashboardPage } from '../../../units/projects/pages/dashboard/page';
import { FourDVisualisationPage } from '../../../units/projects/pages/four-d-visualisation/page';
import { GanttChartPage } from '../../../units/projects/pages/gantt-chart/gantt-chart';
import { ModelCheckPage, ModelCheckRoutes } from '../../../units/projects/pages/model-check';
import { ModelManagementPage } from '../../../units/projects/pages/model-management';
import { IndexProjectPage } from '../../../units/projects/pages/project/page';
import { QuantityTakeOffPage } from '../../../units/projects/pages/quantity-take-off/page';
import { ScenarioRoutes } from '../../../units/projects/routes/scenario';
import { ValidationRoutes } from '../../../units/projects/routes/validation';
import { ScenarioPage } from '../../../units/scenarios';
import { ViewerPage } from '../../../units/viewer';
import { PlanProjectRouteParams } from '../route-params';
import { Urls } from '../urls';


interface RoutesStateProps {
  currentProject: Project | null;
  isValidationCompleted: boolean;
  projectLoaded: boolean;
}

interface RoutesDispatchProps {
  changeProjectName: (name: string) => void;
  setCurrentProject: (projectId: number) => void;
  dropCurrentProjectInfo: () => void;
  fetchScenarioStates: () => void;
}

interface RoutesProps
  extends RoutesStateProps,
    RoutesDispatchProps,
    RouteComponentProps<PlanProjectRouteParams>,
    AbilityAwareProps {}

const SpinnerPage: React.FC = () => <SvgSpinner size='large' />;

class ProjectRoutesComponent extends React.Component<RoutesProps> {
  public componentDidMount(): void {
    this.fetchProjectIfNeeded();
    this.fetchScenarioStatesIfNeeded();
  }

  public componentDidUpdate(prevProps: RoutesProps): void {
    this.fetchProjectIfNeeded();
    this.fetchScenarioStatesIfNeeded(prevProps);
  }

  public render(): React.ReactNode {
    const { ability, match, isValidationCompleted, projectLoaded } = this.props;

    if (!projectLoaded) {
      return <Route component={SpinnerPage} />;
    }

    if (
      !ability.can(Operation.Read, Subject.PlanFeatures) &&
      !ability.can(Operation.Read, Subject.QuantityTakeOff3d)
    ) {
      return (
        <Switch>
          <Redirect exact={true} from={Urls.project.index.path} to={Urls.project.viewer.path} />
          <PageLayout
            exact={true}
            metaTitle='Collaboration'
            path={Urls.project.viewer.path}
            component={ViewerPage}
            header={this.getProjectHeader()}
          />
        </Switch>
      );
    }

    const indexValidationPageUrl = Urls.project.validation.index.url(match.params);
    if (
      !isValidationCompleted &&
      !window.location.pathname.startsWith(Urls.project.index.url(match.params)) &&
      !window.location.pathname.startsWith(Urls.project.viewer.url(match.params)) &&
      !window.location.pathname.startsWith(indexValidationPageUrl) &&
      !window.location.pathname.startsWith(Urls.listing.url())
    ) {
      return <Redirect to={indexValidationPageUrl} />;
    }

    const indexProjectPageUrl = Urls.project.index.url(match.params);

    return (
      <Switch>
        <Route exact={true} path={Urls.project.index.path} component={IndexProjectPage} />
        <PageLayout
          exact={true}
          metaTitle='Collaboration'
          path={Urls.project.viewer.path}
          component={ViewerPage}
          header={this.getProjectHeader()}
          subject={Subject.Viewer}
          redirectUrl={indexProjectPageUrl}
        />
        <Route path={Urls.project.validation.index.path} component={ValidationRoutes} />
        <PageLayout
          exact={true}
          metaTitle='Dashboard'
          path={Urls.project.dashboard.path}
          component={DashboardPage}
          header={this.getProjectHeader()}
          backgroundColor={KreoColors.gray2}
          subject={Subject.Dashboard}
          redirectUrl={indexProjectPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle='Scenarios'
          path={Urls.project.scenario.listing.path}
          component={ScenarioPage}
          header={this.getProjectHeader(FaqCaption.Scenarios)}
          backgroundColor={KreoColors.gray2}
          subject={Subject.Scenarios}
          redirectUrl={indexProjectPageUrl}
        />
        <Route path={Urls.project.scenario.index.path} component={ScenarioRoutes} />
        <PageLayout
          exact={true}
          metaTitle='Costs'
          path={Urls.project.costs.path}
          backgroundColor='white'
          component={CostsPage}
          header={this.getProjectHeader(FaqCaption.Costs)}
          subject={Subject.Cost}
          redirectUrl={indexProjectPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle='Schedule'
          path={Urls.project.schedule.path}
          backgroundColor='white'
          component={GanttChartPage}
          header={this.getProjectHeader(FaqCaption.Schedule)}
          subject={Subject.Gantt}
          redirectUrl={indexProjectPageUrl}
        />
        {ability.can(Operation.Read, Subject.FourDVisualisation) && (
          <HeadlessPageLayout
            exact={true}
            metaTitle='4D Schedule'
            path={Urls.project.fourD.path}
            backgroundColor='#191D29'
          >
            <FourDVisualisationPage match={this.props.match} />
          </HeadlessPageLayout>
        )}
        <Route path={Urls.project.bidPricing.index.path} component={bidPricingRoutes} />
        <PageLayout
          exact={true}
          metaTitle='Quantity Take Off'
          path={Urls.project.quantityTakeOff.path}
          backgroundColor='white'
          component={QuantityTakeOffPage}
          header={this.getProjectHeader(FaqCaption.QuantityTakeOff, VideoUrl.fullDemo)}
          subject={Subject.QuantityTakeOff3d}
          redirectUrl={indexProjectPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle='Model Management'
          path={Urls.project.modelManagement.path}
          backgroundColor='white'
          component={ModelManagementPage}
          header={this.getProjectHeader()}
          subject={Subject.ModelManagement}
          redirectUrl={indexProjectPageUrl}
        />
        <Route
          path={Urls.project.modelCheck.index.path}
          component={ModelCheckRoutes}
        />
        <PageLayout
          exact={true}
          metaTitle='Model Check'
          path={Urls.project.modelCheck.index.path}
          backgroundColor='white'
          subject={Subject.ValidationModelCheck}
          component={ModelCheckPage}
          redirectUrl={Urls.project.modelCheck.index.path}
          header={this.getProjectHeader()}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  public componentWillUnmount(): void {
    this.props.dropCurrentProjectInfo();
  }

  private fetchProjectIfNeeded(): void {
    const parsedProjectId = parseInt(this.props.match.params.projectId, 10);
    if (
      !isNaN(parsedProjectId) &&
      (!this.props.currentProject || this.props.currentProject.id !== parsedProjectId)
    ) {
      this.props.setCurrentProject(parsedProjectId);
    }
  }

  private fetchScenarioStatesIfNeeded(prevProps?: RoutesProps): void {
    const currentProject = this.props.currentProject;
    const previousProject = prevProps && prevProps.currentProject;
    if (currentProject && currentProject.id !== (previousProject && previousProject.id)) {
      this.props.fetchScenarioStates();
    }
  }

  private getProjectHeader(faqCaption?: FaqCaption, videoUrl?: string): PageHeader {
    return {
      context: HeaderContext.Project,
      backUrl: Urls.listing.url(),
      title: this.props.currentProject ? this.props.currentProject.name : '',
      faqCaption,
      videoUrl,
      onEditTitle: this.props.ability.can(Operation.Update, Subject.Project)
        ? this.props.changeProjectName
        : null,
      showLeftMenu: true,
    };
  }
}

function mapStateToProps(state: State, ownProps: RoutesProps): RoutesStateProps {
  const currentProject = state.projects.currentProject;
  const isValidationCompleted = currentProject ? currentProject.isValidationCompleted : false;
  const projectLoaded =
    !!currentProject &&
    currentProject.id === parseInt(ownProps.match.params.projectId, 10) &&
    !!state.account.selectedCompany &&
    currentProject.companyId === state.account.selectedCompany.id;

  return {
    currentProject,
    isValidationCompleted,
    projectLoaded,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): RoutesDispatchProps {
  return {
    fetchScenarioStates: () => dispatch(ProjectsActions.fetchScenarioStates()),
    changeProjectName: (name: string) => dispatch(ProjectsActions.updateProjectName(name)),
    setCurrentProject: id => dispatch(ProjectsActions.setCurrentProject(id)),
    dropCurrentProjectInfo: () => dispatch(ProjectsActions.dropCurrentProjectInfo()),
  };
}

export const ProjectRoutes = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProjectRoutesComponent),
);
