const isEmpty = (diff: any): boolean => {
  if (!diff) {
    return true;
  }

  return Object.keys(diff).length === 0;
};

export const isDiffEmpty = (diff: any): boolean => {
  if (!diff) {
    return true;
  }

  return isEmpty(diff.added)
    && isEmpty(diff.updated)
    && isEmpty(diff.deleted);
};
