import * as paper from 'paper';

import { DrawingContextObserverWithPrev } from '../../drawings-contexts';
import { DrawingsRenderParams, TextSearchResult } from '../../interfaces';
import { PdfTextRects } from '../../interfaces/pdf-text-rectangle';
import { DestroyableObject, EngineObjectConfig } from '../common';
import { DrawingsCursorTypeHelper } from '../drawings-helpers';
import { TextSearchRects } from './text-search-rects';
import { TextSearchResults } from './text-search-results';

interface TextSearchConfig extends EngineObjectConfig {
  paramsObserver: DrawingContextObserverWithPrev<DrawingsRenderParams>;
  cursorHelper: DrawingsCursorTypeHelper;
  onSelectTextForSearch: (text: string) => void;
}

export class TextSearch extends DestroyableObject<TextSearchConfig> {
  private _layer: paper.Layer = new paper.Layer();
  private _results: TextSearchResults;
  private _rects: TextSearchRects;

  public destroy(): void {
    this._results.destroy();
  }


  public setResults(drawingId: string, results: Record<string, TextSearchResult[]>): void {
    if (!this._results) {
      this._results = new TextSearchResults({
        results,
        currentDrawingId: drawingId,
        paramsObserver: this._config.paramsObserver,
        layer: this._layer,
      });
    } else {
      this._results.changeDrawingId(drawingId);
      this._results.changeResults(results);
    }
  }

  public setHighlightItem(drawingId: string, id: number): void {
    this._results.setHighlightItem(drawingId, id);
  }


  public setRects(rects: PdfTextRects): void {
    if (!this._rects) {
      this._rects = new TextSearchRects({
        paramsObserver: this._config.paramsObserver,
        layer: this._layer,
        rects,
        onSelectTextForSearch: this._config.onSelectTextForSearch,
        cursorHelper: this._config.cursorHelper,
      });
    } else {
      this._rects.setRects(rects);
    }
  }

  public onMouseMove(event: PaperMouseEvent): void {
    if (this._rects) {
      this._rects.onMouseMove(event);
    }
  }
}

