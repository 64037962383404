import { ExcelFormulaHelper } from 'common/components/excel-table';
import { flatten } from './flatten';

export function SUM(...values: Array<number | string>): number | string {
  const arr = flatten(values);
  if (arr.length === 0) {
    return 0;
  }

  return arr.reduce((accumulator: any, currentValue) => {
    if (currentValue === '') {
      return accumulator;
    }
    if (typeof accumulator !== 'number') {
      return ExcelFormulaHelper.INVALID_VALUE;
    }
    if (typeof currentValue === 'number' && !isNaN(currentValue)) {
      return accumulator + currentValue;
    }
    if (currentValue?.toString().toLowerCase() === 'true') {
      return accumulator + 1;
    }

    // Attempt to parse the string value as a number
    const parsedValue = parseFloat(currentValue as string);

    if (!isNaN(parsedValue)) {
      return accumulator + parsedValue;
    } else {
      return accumulator;
    }
  }, 0);
}
