import { CheckboxButton, Text } from '@kreo/kreo-ui-components';
import React from 'react';


interface Props {
  selected: boolean;
  indeterminate: boolean;
  title: string;
}

const SummaryItemComponent: React.FC<Props> = ({ selected, title, indeterminate }) => {
  return (
    <>
      <CheckboxButton checked={selected} indeterminate={indeterminate} />
      <Text fontSize={14}>{title}</Text>
    </>
  );
};


export const SummaryItem = React.memo(SummaryItemComponent);
