import { Text } from '@kreo/kreo-ui-components';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { TRANSITION_POINT } from '../../../../../../../constants';

function getMarginCell(isItem: boolean): FlattenSimpleInterpolation {
  if (isItem) {
    return css` margin-left: 15px`;
  } else {
    return css` margin-right: 15px`;
  }
}

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 226px 70px 102px 127px;
  border: 1px solid ${p => p.theme.color.background};
  height: 50px;
  justify-items: center;
  align-items: center;

  &, * {
    box-sizing: border-box;
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-right: 1px solid ${p => p.theme.color.background};

  ${Text} {
    font-size: 14px;
  }
`;

const ContentWrapper = styled.div<{ isItem?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${p => p.isItem ? 'start' : 'end'};
  width: 100%;
  height: 100%;
  ${p => getMarginCell(p.isItem)};
`;

const FooterBlock = styled.div`
  width: 100%;

  > div:first-child {
    padding-top: 15px;
  }
`;

export const Styled = {
  RowContainer,
  Cell,
  ContentWrapper,
  FooterBlock,
};
