import { CompanyInfo, ModalWrapper as ModalWrapperContainer } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import {
  ADD_SEATS_INSTRUCTION_DIALOG,
  AddSeatsConfirmationDialog,
} from 'common/components/add-seats-confirmation-dialog';
import { ImageUploader } from 'common/components/image-uploader';
import { Spinner } from 'common/components/spinner';
import { RequestStatus } from 'common/enums/request-status';
import { RoleGroup } from 'common/enums/role-group';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { Role } from 'common/interfaces/account/role';
import { InvitationCompanyUsers } from 'common/interfaces/people';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { CommonsApi } from '../../../../api/common';
import { ConfirmationDialog } from '../../../../components/dialog/confirmation-dialog';
import { AccountActions } from '../../../../units/account/actions/creators';
import { AccountDataPayload } from '../../../../units/account/actions/payloads';
import { AccountApi } from '../../../../units/account/api';
import { Company, UpdateCompany } from '../../../../units/account/interfaces/company';
import { CompanySubscriptionModel } from '../../../../units/account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../units/account/selectors';
import { SubscriptionActions } from '../../../../units/subscription/actions/creators';
import { SUBSCRIPTION__DIALOG } from '../../../../units/subscription/components/subscription-dialog';
import { UPGRADE_SUBSCRIPTION_DIAGLOG } from '../../../../units/subscription/components/upgrade-subscription-dialog';
import { PeopleActions } from '../../actions/actions';
import { Person } from '../../interfaces/person';
import { AddCompanyInfoPopup } from './add-company-info-popup';
import { COMPANY_INFO_ADD_PEOPLE_DIALOG } from './constants';
import { Styled } from './styled';

/* eslint-disable @typescript-eslint/no-shadow */
export enum CompanyInfoWrapperTabIndexes {
  Company,
  Users,
  Guests,
}
/* eslint-enable */

interface StateProps {
  company: Company;
  people: Person[];
  subscription: CompanySubscriptionModel;
  subscriptionRoles: CompanyRoles;
  account: AccountDataPayload;
  employeesMaxCount: number;
  addPeopleRequestStatus: RequestStatus;
}

interface DispatchProps {
  loadPeople: (id: number) => void;
  loadSubscriptionRoles: () => void;
  updateUserRole: (userId: string, companyId: number, roleId: number) => void;
  invitePeople: (invites: InvitationCompanyUsers[]) => void;
  updateCompany: (id: number, data: UpdateCompany) => void;
  removePersonFromCompany: (userId: string) => void;
  resendInvitation: (userId: string) => void;
  cancelInvitation: (email: string) => void;
  openAddPeopleDialog: () => void;
  closeAddPeopleDialog: () => void;
  closeInfoDialog: () => void;
  openDialog: (dialogName: string) => void;
}


interface OwnProps extends AbilityAwareProps {
  defaultIndex: CompanyInfoWrapperTabIndexes;
}

interface Props extends StateProps, DispatchProps, OwnProps {

}

export interface PersonVisibleData {
  name: string;
  email: string;
  avatar: string;
  accountRole: string;
  isPayable: boolean;
  isAccepted: boolean;
}

interface RoleData {
  id: number;
  name: string;
}

export interface UsersState {
  employees: PersonVisibleData[];
  guests: PersonVisibleData[];
  userRoles: RoleData[];
  guestRoles: RoleData[];
  disableEditUserList: string[];
}

interface ComponentState extends UsersState {
  addPeoplePopupOpened: boolean;
  isAddGuest: boolean;
  companyName: string;
}

export const COMPANY_INFO_DIALOG = 'COMPANY_INFO_DIALOG';
const BUY_LICENSE_DIALOG = 'BUY_LICENSE_DIALOG';

class CompanyInfoWrapperComponent extends React.PureComponent<Props, ComponentState> {
  private deferredExecutor: DeferredExecutor = new DeferredExecutor(300);

  constructor(props: Props) {
    super(props);
    this.state = {
      employees: [],
      guests: [],
      userRoles: [],
      guestRoles: [],
      disableEditUserList: [],
      addPeoplePopupOpened: false,
      isAddGuest: true,
      companyName: null,
    };
  }

  public componentDidMount(): void {
    if (this.props.company) {
      this.props.loadPeople(this.props.company.id);
      this.setState({ companyName: this.props.company.name });
    }

    this.props.loadSubscriptionRoles();
  }

  public componentDidUpdate(prevProps: Props): void {
    const { people, subscriptionRoles, company } = this.props;
    const peopleChanged = people !== prevProps.people;
    const rolesChanged = prevProps.subscriptionRoles !== subscriptionRoles;
    if (company && (!company || company.id !== prevProps.company.id)) {
      this.props.loadPeople(company.id);
      this.props.loadSubscriptionRoles();
      this.setState({ companyName: company.name });
    } else if (peopleChanged || rolesChanged) {
      const state: UsersState = {
        employees: [],
        guests: [],
        userRoles: [],
        guestRoles: [],
        disableEditUserList: [this.props.company.email],
      };
      const roles: Record<number, Role> = {};
      if (subscriptionRoles) {
        for (const role of subscriptionRoles.roles) {
          roles[role.id] = role;
          if (role.group === RoleGroup.Employee) {
            state.userRoles.push({ id: role.id, name: role.name });
          } else {
            state.guestRoles.push({ id: role.id, name: role.name });
          }
        }
      }
      let owner: PersonVisibleData;
      if (subscriptionRoles && people) {
        for (const person of this.props.people) {
          const role = roles[person.roleId];
          if (!role) { // temp
            continue;
          }
          const parsedPerson = this.parsePersonData(person, role.name);
          if (person.isOwner) {
            owner = parsedPerson;
          } else {
            state[role.group === RoleGroup.Employee ? 'employees' : 'guests'].push(parsedPerson);
          }
        }

        state.employees.sort((a, b) => a.email > b.email ? 1 : -1);
        if (owner) {
          state.employees.unshift(owner);
        }
      }
      this.setState(state);
    } else if (company.name !== prevProps.company.name) {
      this.setState({ companyName: company.name });
    }
  }

  public render(): React.ReactNode {
    const { company, ability, account, defaultIndex, employeesMaxCount, addPeopleRequestStatus } = this.props;
    if (!company) {
      return null;
    }
    const emptySeatCount = employeesMaxCount - this.state.employees.filter(x => x.isPayable).length;
    const canInvite = ability.can(Operation.Create, Subject.Employees) ||
      ability.can(Operation.Create, Subject.Guests) ||
      ability.can(Operation.Create, Subject.Subcontractors);
    const canUpdateCompany = account.email === company.email;

    return (
      <DialogWrapper
        name={COMPANY_INFO_DIALOG}
      >
        <ModalWrapperContainer
          onExit={this.props.closeInfoDialog}
        >
          <Styled.CompanyInfoWrapper canManage={canInvite}>
            <CompanyInfo
              emptySeatsCount={canInvite && emptySeatCount > 0 ? emptySeatCount : 0}
              maxUsersCount={employeesMaxCount}
              canInvite={canInvite}
              addSeatsButtonClick={this.addSeatsButtonClick}
              openTabIndex={defaultIndex}
              companyNameChange={canUpdateCompany ? this.companyNameChange : null}
              companyUsers={this.state.employees}
              companyGuests={this.state.guests}
              userRoles={this.state.userRoles}
              guestRoles={this.state.guestRoles}
              companyId={company.id}
              disableEditUserList={this.state.disableEditUserList}
              changeUserRole={this.changeUserRole}
              companyName={this.state.companyName}
              resendInvitation={this.resendInvitation}
              addUserButtonClick={this.addUserButtonClick}
              addGuestButtonClick={this.addGuestButtonClick}
              deleteUser={this.deleteUser}
              avatar={AccountApi.getCompanyLogoLink(company)}
              avatarComponent={canUpdateCompany ? this.getAvatarComponent : null}
            />
            <Spinner
              show={addPeopleRequestStatus === RequestStatus.Loading}
              withBackground={true}
            />
          </Styled.CompanyInfoWrapper>
        </ModalWrapperContainer>
        <AddCompanyInfoPopup
          onInvitePeople={this.onInvitePeople}
          closeAddPeopleDialog={this.props.closeAddPeopleDialog}
          isAddGuest={this.state.isAddGuest}
          emptySeatCount={emptySeatCount}
          userRoles={this.state.userRoles}
          guestRoles={this.state.guestRoles}
        />
        <ConfirmationDialog
          name={BUY_LICENSE_DIALOG}
          title='There are no available seats'
          text='Contact your company owner to add new seats, please.'
          cancelButtonText='Okay'
        />
        <AddSeatsConfirmationDialog />
      </DialogWrapper>
    );
  }

  private parsePersonData(person: Person, roleName: string): PersonVisibleData {
    return {
      name: `${person.firstName} ${person.lastName}`,
      accountRole: roleName,
      email: person.email,
      avatar: AccountApi.getAvatarLink(person),
      isPayable: person.isPayable,
      isAccepted: person.isCompletedRegistration,
    };
  }

  @autobind
  private getAvatarComponent(): React.ReactNode {
    return (
      <ImageUploader
        previewUrl={CommonsApi.tempFileLink}
        defaultImagePreviewUrl={AccountApi.buildLogoPostFix(this.props.company.id)}
        deleteImage={this.deleteImage}
        onLoadEnd={this.setImage}
        size={'big'}
      />);
  }

  @autobind
  private deleteUser(email: string): void {
    const person = this.props.people.find(x => x.email === email);
    if (person.isAccepted) {
      this.props.removePersonFromCompany(person.id);
    } else {
      this.props.cancelInvitation(person.email);
    }
  }


  @autobind
  private companyNameChange(name: string): void {
    if (this.state.companyName !== name) {
      this.setState(
        { companyName: name },
        this.saveCompanyName,
      );
    }
  }

  @autobind
  private saveCompanyName(): void {
    const companyName = this.state.companyName;
    if (companyName.trim()) {
      this.deferredExecutor.execute(
        () => this.props.updateCompany(
          this.props.company.id,
          {
            name: companyName,
          },
        ),
      );
    }
  }

  @autobind
  private onInvitePeople(peopleInvites: Array<{ email: string, role: RoleData }>): void {
    const invites: Record<number, InvitationCompanyUsers> = {};

    for (const person of peopleInvites) {
      invites[person.role.id] =
        invites[person.role.id] || {
          companyId: this.props.company.id,
          emails: [],
          host: window.location.host,
          roleId: person.role.id,
        };
      invites[person.role.id].emails.push(person.email);
    }

    this.props.invitePeople(Object.values(invites));
  }

  @autobind
  private addSeatsButtonClick(): void {
    if (this.props.ability.can(Operation.Manage, Subject.Billing)) {
      const isFreePlan = AccountSelectors.isFreeSubscription(this.props.subscription);
      if (isFreePlan) {
        this.props.openDialog(UPGRADE_SUBSCRIPTION_DIAGLOG);
      } else {
        this.props.openDialog(SUBSCRIPTION__DIALOG);
      }
      if (this.props.subscription.isCollaborationBlocked) {
        this.props.openDialog(ADD_SEATS_INSTRUCTION_DIALOG);
      }
      return;
    }
    this.props.openDialog(BUY_LICENSE_DIALOG);
  }

  @autobind
  private addUserButtonClick(): void {
    this.setState({ isAddGuest: false }, this.props.openAddPeopleDialog);
  }

  @autobind
  private addGuestButtonClick(): void {
    this.setState({ isAddGuest: true }, this.props.openAddPeopleDialog);
  }

  @autobind
  private resendInvitation(email: string): void {
    this.props.resendInvitation(this.props.people.find(x => x.email === email).id);
  }

  @autobind
  private changeUserRole(email: string, role: RoleData): void {
    this.props.updateUserRole(this.props.people.find(x => x.email === email).id, this.props.company.id, role.id);
  }

  @autobind
  private setImage(logoTemporaryKey: string): void {
    this.props.updateCompany(this.props.company.id, { logoTemporaryKey });
  }

  @autobind
  private deleteImage(): void {
    this.props.updateCompany(this.props.company.id, { resetLogo: true });
  }
}

function mapStateFromProps(state: State): StateProps {
  const { account, people } = state;
  const company = account.selectedCompany;
  const userCompany = account.ownedCompany;
  const subscription = AccountSelectors.currentSubscription(state);

  return {
    company,
    subscription,
    people: people.companiesUsers || [],
    subscriptionRoles: account.subscriptionRoles,
    employeesMaxCount: subscription && subscription.employeesMaxCount,
    addPeopleRequestStatus: people.requests.companiesUsers,
    account: {
      firstName: account.firstName,
      lastName: account.lastName,
      companyName: userCompany ? userCompany.name : null,
      companyId: userCompany ? userCompany.id : null,
      email: account.email,
      avatar: account.id,
    },
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    loadPeople: (id: number) => {
      dispatch(PeopleActions.getCompanyPeople(id));
    },
    loadSubscriptionRoles: () => dispatch(SubscriptionActions.loadSubscriptionRoles()),
    updateUserRole: (userId, companyId, roleId) => {
      dispatch(PeopleActions.updateUserRole(userId, companyId, roleId));
    },
    invitePeople: (invites: InvitationCompanyUsers[]) => {
      dispatch(PeopleActions.invitePeopleToCompanyNew(invites));
      dispatch(KreoDialogActions.closeDialog(COMPANY_INFO_ADD_PEOPLE_DIALOG));
    },
    removePersonFromCompany: userId => dispatch(PeopleActions.deleteCompanyPerson(userId)),
    cancelInvitation: email => dispatch(PeopleActions.rejectInvitation(email)),
    openAddPeopleDialog: () => dispatch(KreoDialogActions.openDialog(COMPANY_INFO_ADD_PEOPLE_DIALOG)),
    closeAddPeopleDialog: () => dispatch(KreoDialogActions.closeDialog(COMPANY_INFO_ADD_PEOPLE_DIALOG)),
    closeInfoDialog: () => dispatch(KreoDialogActions.closeDialog(COMPANY_INFO_DIALOG)),
    updateCompany: (id, data) => dispatch(AccountActions.updateCompany(id, data)),
    openDialog: (dialogName) => dispatch(KreoDialogActions.openDialog(dialogName)),
    resendInvitation: (userId) => dispatch(PeopleActions.resendInvitation(userId)),
  };
}

export const CompanyInfoWrapper =
  connect(mapStateFromProps, mapDispatchToProps)(withAbilityContext(CompanyInfoWrapperComponent));
