import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 60px;
  min-height: 60px;
  min-width: 340px;
  border: 1px solid ${p => p.theme.color.background};
  border-radius: 15px;
  box-sizing: border-box;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  height: 100%;
  border: 1px solid ${p => p.theme.color.background};
  border-radius: 15px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const Styled = {
  Container,
  AvatarContainer,
  ContentWrapper,
  TextContainer,
  SwitcherContainer,
};
