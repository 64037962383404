import styled from 'styled-components';

import { TRANSITION_POINT } from '../../constants';


const ImageBackground = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 90%;
  right: 0;
  bottom: 0;

  img {
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
  }
`;

const Container = styled.div`
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: ${props => props.theme.color.background};

  @media all and (max-width: ${TRANSITION_POINT}px) {
    padding: 10px;

    ${ImageBackground} {
      display: none;
    }
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  padding-bottom: 30px;
  z-index: 1;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    padding-bottom: 20px;

    h2 {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

const ExtraText = styled.div`
  max-width: 705px;
  height: 30px;
  margin-bottom: 20px;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
    height: auto;
  }
`;

const QuestionnaireContainer = styled.div`
  position: relative;
  max-width: 730px;
  z-index: 1;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    max-width: 100%;
  }
`;


export const Styled = {
  Container,
  HeaderContainer,
  ExtraText,
  QuestionnaireContainer,
  ImageBackground,
};
