import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import {
  DrawingsCustomSnappingModes,
  DrawingsSnappingModes,
} from 'common/components/drawings/enums/drawing-snapping-modes';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { DrawingsSnappingModesSelectionItem } from './drawings-snapping-modes-selection-item';
import { Styled } from './styled';


const ITEMS_CONFIG = [
  {
    id: Core.PDFNet.GeometryCollection.SnappingMode.e_LineIntersection,
    title: 'Line Intersection',
    onlyFullDrawingEnabled: true,
  },
  {
    id: Core.PDFNet.GeometryCollection.SnappingMode.e_LineMidpoint,
    title: 'Line Midpoint',
    onlyFullDrawingEnabled: true,
  },
  {
    id: Core.PDFNet.GeometryCollection.SnappingMode.e_PathEndpoint,
    title: 'Path EndPoint',
    onlyFullDrawingEnabled: true,
  },
  {
    id: Core.PDFNet.GeometryCollection.SnappingMode.e_PointOnLine,
    title: 'Point On Line',
    onlyFullDrawingEnabled: true,
  },
  {
    id: DrawingsCustomSnappingModes.DynamicGuideline,
    title: 'Dynamic Guideline',
    onlyFullDrawingEnabled: false,
  },
];

interface StateProps {
  orthogonalMode: boolean;
}

interface Props extends StateProps {
  isOptimized: boolean;
  enabled: boolean;
  selectedModes: DrawingsSnappingModes[];
  selectionChanged: (value: DrawingsSnappingModes[]) => void;
  toggleOrthogonalMode: () => void;
}


export class DrawingsSnappingModesSelectionComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { enabled, isOptimized, orthogonalMode } = this.props;
    return (
      <Styled.Container>
        <RenderIf condition={enabled && !isOptimized}>
          {
            ITEMS_CONFIG.map(({ id, title, onlyFullDrawingEnabled }) => {
              return (
                <DrawingsSnappingModesSelectionItem
                  key={id}
                  id={id}
                  title={title}
                  onSelect={this.onSelect}
                  selected={this.props.selectedModes.includes(id)}
                  disabled={this.props.isOptimized ? onlyFullDrawingEnabled : false}
                />
              );
            })
          }
        </RenderIf>
        <DrawingsSnappingModesSelectionItem
          id={DrawingsCustomSnappingModes.OrthogonalMode2D}
          title={'Orthogonal Mode'}
          onSelect={this.props.toggleOrthogonalMode}
          selected={orthogonalMode}
          disabled={false}
        />
      </Styled.Container>
    );
  }

  @autobind
  private onSelect(id: DrawingsSnappingModes, selected: boolean): void {
    if (!selected) {
      this.props.selectionChanged(this.props.selectedModes.filter(x => x !== id));
    } else {
      this.props.selectionChanged(this.props.selectedModes.concat(id));
    }
  }
}


function mapStateToProps(state: State): StateProps {
  const storage = state.persistedStorage;
  return {
    orthogonalMode: storage.drawingOrthogonalMode,
  };
}

export const DrawingsSnappingModesSelection = connect(mapStateToProps)(DrawingsSnappingModesSelectionComponent);
