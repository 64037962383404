import { Text, Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { RenderIf } from 'common/components/render-if';
import { Styled } from './styled';

interface Props {
  selectedPagesCount: number;
  onOpenClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onRemoveSelectionClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export class SelectPagesButton extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { selectedPagesCount, onOpenClick, onRemoveSelectionClick } = this.props;

    return (
      <Styled.Container onClick={onOpenClick}>
        <Styled.Count>
          {selectedPagesCount}
        </Styled.Count>
        <Text fontSize={14} lineHeight={16}>
          Scale multiple pages
        </Text>
        <RenderIf condition={selectedPagesCount > 1}>
          <Styled.RemoveSelectionButton onClick={onRemoveSelectionClick}>
            <Icons.CloseSmall fill={'white'} />
          </Styled.RemoveSelectionButton>
        </RenderIf>
      </Styled.Container>
    );
  }
}
