import * as React from 'react';

interface Props {
  children: string;
}

export class TextFormatter extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (<span className='formatter' dangerouslySetInnerHTML={{ __html: this.parse(this.props.children) }} />);
  }

  private parse(text: string): string {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      return `<a href='${url}'>${url}</a>`;
    });
  }
}
