import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { HeaderContext } from 'common/enums/header-context';
import { PageHeader } from 'common/interfaces/page-header';
import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { PageLayout } from '../../../../layouts/page-layout';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { NotFoundPage } from '../../../../units/notfound/page';
import { ProjectsActions } from '../../actions/creators/common';
import { Project } from '../../interfaces/project';
import { BidPricingPage, EditedCostPage, InfoCostPage } from '.';

interface RoutesStateProps {
  currentProject: Project | null;
}

interface RoutesDispatchProps {
  changeProjectName: (name: string) => void;
}

interface RoutesProps
  extends RoutesStateProps,
    RoutesDispatchProps,
    RouteComponentProps<PlanProjectRouteParams>,
    AbilityAwareProps {}

class BidPricingRoutesComponent extends React.Component<RoutesProps> {
  public render(): JSX.Element {
    const bidPricingRouteProps = {
      subject: Subject.BidPricing,
      redirectUrl: AppUrls.plan.project.index.url({ projectId: this.props.match.params.projectId }),
      header: this.getProjectHeader(),
      backgroundColor: 'white',
      exact: true,
    };

    return (
      <Switch>
        <PageLayout
          metaTitle='Bid Pricing'
          path={AppUrls.plan.project.bidPricing.index.path}
          component={BidPricingPage}
          {...bidPricingRouteProps}
        />
        <PageLayout
          metaTitle='Bid Pricing Edit Cost'
          path={AppUrls.plan.project.bidPricing.editCost.path}
          component={EditedCostPage}
          {...bidPricingRouteProps}
        />
        <PageLayout
          metaTitle='Bid Pricing Info Cost'
          path={AppUrls.plan.project.bidPricing.costInfo.path}
          component={InfoCostPage}
          {...bidPricingRouteProps}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  private getProjectHeader(): PageHeader {
    return {
      context: HeaderContext.Project,
      backUrl: AppUrls.plan.listing.url(),
      title: this.props.currentProject.name,
      onEditTitle: this.props.ability.can(Operation.Update, Subject.Project)
        ? this.props.changeProjectName
        : null,
      showLeftMenu: true,
    };
  }
}

function mapStateToProps(state: State): RoutesStateProps {
  return {
    currentProject: state.projects.currentProject,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): RoutesDispatchProps {
  return {
    changeProjectName: (name: string) => dispatch(ProjectsActions.updateProjectName(name)),
  };
}

export const bidPricingRoutes = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BidPricingRoutesComponent),
);
