import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseResourceActions } from '../../actions/creators/database-resource';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { ModifyDatabaseEntityMode, ResourceType } from '../../enums';
import { MaterialModel } from '../../interfaces/resources-data';
import { MaterialForm } from '../../interfaces/resources-rest-data';
import { ResourcesRestFormMapper } from '../../utils/resources-rest-form-mapper';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';
import { DatabaseResourceMaterial } from './database-resource-material';

interface ReduxProps {
  resource: MaterialModel;
  databaseId: number;
}

interface ReduxActions {
  setEditResourceModel: (variant: MaterialModel) => void;
  createDatabaseResource: (databaseId: number, type: ResourceType, model: MaterialForm, formId: string) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps {
}

class CreateDatabaseResourceMaterialComponent extends React.Component<Props> {
  private readonly formId: string = 'create-material';

  public componentDidMount(): void {
    this.props.setEditResourceModel(this.getDefaultResourceModel());
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Material'
        isApplyButtonDisabled={this.isCreateButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Create}
        onSubmit={this.create}
        form={this.formId}
        readonly={this.props.readonly}
        initialValues={this.getDefaultResourceModel()}
      >
      {
        this.props.resource ? (
          <KreoScrollbars>
            <DatabaseResourceMaterial
              resource={this.props.resource}
              onChange={this.props.setEditResourceModel}
              readonly={this.props.readonly}
            />
          </KreoScrollbars>
        ) : <SvgSpinner size='middle'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }


  @autobind
  private create(): void {
    const resourceForm = ResourcesRestFormMapper.GetMaterialForm(this.props.resource);
    this.props.createDatabaseResource(this.props.databaseId, ResourceType.Material, resourceForm, this.formId);
  }

  private isCreateButtonDisabled(): boolean {
    const { resource } = this.props;
    return !resource ||
      !resource.name ||
      !resource.name.trim() ||
      !resource.defaultUnitId;
  }

  private getDefaultResourceModel(): MaterialModel {
    return {
      id: 0,
      name: '',
      defaultUnitId: null,
      variants: [],
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    resource: editModel.root,
    databaseId: state.database.currentDatabase.database.id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditResourceModel: variant =>
      dispatch(DatabaseResourceListingActions.setEditResourceModel(variant)),
    createDatabaseResource: (databaseId, type, model, formId) =>
      dispatch(DatabaseResourceActions.createResource(databaseId, type, model, formId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const CreateDatabaseResourceMaterial = connector(CreateDatabaseResourceMaterialComponent);
