import GUI from 'lil-gui';

export interface MaterialSettings {
  transparent: boolean;
  opacity: number;
  roughness: number;
}

export function addMaterialSettings(
  gui: GUI,
  onChange: (settings: MaterialSettings) => void,
): MaterialSettings {
  const settingsObject = {
    transparent: true,
    opacity: 0.5,
    roughness: 0.01,
  };

  const materialFolder = gui.addFolder('Material').onChange(() => onChange(settingsObject));
  materialFolder.add(settingsObject, 'transparent');
  materialFolder.add(settingsObject, 'opacity', 0, 1, 0.01);
  materialFolder.add(settingsObject, 'roughness', 0, 1, 0.01);
  return settingsObject;
}
