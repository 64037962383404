import { IconButton, Icons } from '@kreo/kreo-ui-components';
import React, { Dispatch, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';


import { useSendPromtsAnalitics } from 'unit-2d-copilot/hooks';
import { AIRequestStatus } from 'unit-2d-copilot/interfaces';
import { TwoDCopilotActions } from 'unit-2d-copilot/store-slice';
import { Styled } from './styled';

interface DispatchProps {
  sendMessage: (message: string) => void;
}

interface OwnProps {
  status: AIRequestStatus;
  getPdfText: () => Promise<string>;
}

interface Props extends DispatchProps, OwnProps {
}

const InputPanelComponent: React.FC<Props> = (
  {
    sendMessage,
    status,
  },
) => {
  const [ query, setQuery ] = useState('');

  const sendQuery = useSendPromtsAnalitics(sendMessage);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  const onSend = useCallback(() => {
    if (query) {
      sendQuery(query);
      setQuery('');
    }
  }, [query, sendQuery]);

  const onKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSend();
    }
  }, [query, onSend]);


  return (
    <Styled.Container>
      <Styled.Icon>
        <Icons.Caddy />
      </Styled.Icon>
      <Styled.TextArea
        onKeyPress={onKeyPress}
        onChange={onChange}
        placeholder="Ask AI about the drawing"
        value={query}
        disabled={status === AIRequestStatus.Loading || status === AIRequestStatus.TextExtracting}
      />
      <Styled.Button>
        <IconButton
          Icon={Icons.SendAi}
          onClick={onSend}
          width={20}
          height={20}
        />
      </Styled.Button>
    </Styled.Container>
  );
};


function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { getPdfText }: OwnProps): DispatchProps {
  return {
    sendMessage: (message: string) => {
      dispatch(TwoDCopilotActions.sendMessage({ query: message, getPdfText }));
    },
  };
}

export const InputPanel = connect(null, mapDispatchToProps)(InputPanelComponent);
