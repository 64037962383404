import React from 'react';
import { ReportTemplateInfo } from '../../../interfaces';

export const useFilterTemplatesMemo = (
  templates: ReportTemplateInfo[],
  hideTemplateIds?: Set<number>,
): ReportTemplateInfo[] => {
  return React.useMemo(() => {
    return  hideTemplateIds
      ? templates.filter(t => !hideTemplateIds.has(t.id))
      : templates;
  }, [hideTemplateIds, templates]);
};
