import classNames from 'classnames';
import React from 'react';

import './header.scss';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';

interface HeaderProps extends I18nAwareProps {
  fixed: boolean;
  isSubcontractor: boolean;
}

class HeaderComponent extends React.Component<HeaderProps> {
  public render(): React.ReactNode {
    const { fixed, isSubcontractor, i18n } = this.props;
    return (
      <div
        className={classNames('bid-pricing-invitations__header', { 'bid-pricing-invitations__header--fixed': fixed })}
      >
        <div className='bid-pricing-invitations__work-packages cell'>Work Package</div>
        <div className='bid-pricing-invitations__inner-table'>
          {!isSubcontractor && (
            <div className='bid-pricing-invitations__subcontractor cell'>Sub Contractor</div>
          )}
          <div className='bid-pricing-invitations__data cell'>
            <div className='bid-pricing-invitations__data-inner'>
              Labor
              <div className='sub-row'>{i18n.currency.symbol}</div>
            </div>
          </div>
          <div className='bid-pricing-invitations__data cell'>
            <div className='bid-pricing-invitations__data-inner'>
              Plant
              <div className='sub-row'>{i18n.currency.symbol}</div>
            </div>
          </div>
          <div className='bid-pricing-invitations__data cell'>
            <div className='bid-pricing-invitations__data-inner'>
              Material
              <div className='sub-row'>{i18n.currency.symbol}</div>
            </div>
          </div>
          <div className='bid-pricing-invitations__data cell' title='Overhead and Profit'>
            <div className='bid-pricing-invitations__data-inner'>
              O&amp;P
              <div className='sub-row'>{i18n.currency.symbol}</div>
            </div>
          </div>
          <div className='bid-pricing-invitations__data cell'>
            <div className='bid-pricing-invitations__data-inner'>
              Total&nbsp;Cost
              <div className='sub-row'>{i18n.currency.symbol}</div>
            </div>
          </div>
          <div className='bid-pricing-invitations__data cell'>
            <div className='bid-pricing-invitations__data-inner'>
              Duration
              <div className='duration-days sub-row'>days</div>
            </div>
          </div>
          <div className='bid-pricing-invitations__action cell'>
            <span className='bid-pricing-invitations__action-title'>Action</span>
          </div>
        </div>
      </div>
    );
  }
}

const Header = withI18n(HeaderComponent);

export default Header;
