import * as React from 'react';

import { PageHeader } from '../common/interfaces/page-header';
import { InternalHeader } from '../components/internal-header';

import './page-layout-content.scss';

interface Props {
  header: PageHeader;
  backgroundColor?: string;
}

export class PageLayoutContent extends React.Component<Props> {
  public render(): any {
    const { header, backgroundColor } = this.props;

    const style: React.CSSProperties = {
      backgroundColor,
    };

    return (
      <div style={style} className='page-layout-content'>
        <InternalHeader {...header} />
        <div className='page-layout-content__body'>{this.props.children}</div>
      </div>
    );
  }
}
