import autobind from 'autobind-decorator';
import * as classNames from 'classnames';
import * as React from 'react';

import './base-tile.scss';

interface Props {
  className: string;
  component: React.ReactNode;
  onClick?: () => void;
  controlName?: string;
}

export class BaseTile extends React.Component<Props> {
  public render(): JSX.Element {
    const { component, children, controlName } = this.props;
    const className = classNames('base-tile', this.props.className);
    return (
      <div className={className} tabIndex={0} data-control-name={controlName}>
        <div className='base-tile__inner' onClick={this.onClick}>{children}</div>
        {component}
      </div>
    );
  }

  @autobind
  private onClick(): void {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
}
