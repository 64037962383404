import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './custom-element-filter-builder-operator-item.scss';

import { CustomElementFilterCollectionOperation } from 'common/enums/custom-element-filter-collection-operation';


interface Props {
  current: CustomElementFilterCollectionOperation;
  operation: CustomElementFilterCollectionOperation;
  name: string;
  onChange: (condition: CustomElementFilterCollectionOperation) => void;
}

export class CustomElementFilterOperatorItem extends React.Component<Props> {

  public render(): JSX.Element {
    const { current, operation, name } = this.props;
    const className = classNames(
      'custom-element-filter-builder-operator-item',
      { 'custom-element-filter-builder-operator-item--active': operation === current },
    );

    return (
      <div
        onClick={this.onChange}
        className={className}
      >
        {name}
      </div>
    );
  }

  @autobind
  private onChange(): void {
    this.props.onChange(this.props.operation);
  }
}
