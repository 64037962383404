import * as t from 'io-ts';

import {
  CustomElementFilterCollectionOperation,
  CustomElementFilterCollectionOperationC,
} from 'common/enums/custom-element-filter-collection-operation';
import { CustomElementFilterBase, CustomElementFilterBaseC } from './custom-element-filter-base';
import {
  CustomElementFilterNumber,
  CustomElementFilterNumberC,
  CustomElementFilterReference,
  CustomElementFilterReferenceC,
  CustomElementFilterString,
  CustomElementFilterStringC,
} from '.';


export interface CustomElementFilterTreeNode extends CustomElementFilterBase {
  innerNodes: CustomElementFilterTreeNode[];
  stringBasedFilters: CustomElementFilterString[];
  doubleBasedFilters: CustomElementFilterNumber[];
  referencedFilters: CustomElementFilterReference[];
  operation: CustomElementFilterCollectionOperation;
}

export const CustomElementFilterTreeNodeC = t.recursion<CustomElementFilterTreeNode>(
  'CustomElementFilterTreeNode',
  (self) => t.intersection([
    t.type({
      innerNodes: t.array(self),
      stringBasedFilters: t.array(CustomElementFilterStringC),
      doubleBasedFilters: t.array(CustomElementFilterNumberC),
      referencedFilters: t.array(CustomElementFilterReferenceC),
      operation: CustomElementFilterCollectionOperationC,
    }),
    CustomElementFilterBaseC,
  ]),
);
