import { useActionDispatch } from 'common/hooks';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { usePersistedStorageToggle, usePersistedStorageValue } from 'persisted-storage/hooks';
import { useUndoRedoForSameFunction, useUndoRedoForToggle } from './use-undo-redo-for-toggle';


export interface DrawingSettings {
  isNightModeEnabled: boolean;
  isBlackAndWhiteEnabled: boolean;
  opacity: number;
  isKeepOriginName: boolean;
  isAutofocusEnabled: boolean;
  isKeepStructure: boolean;
  isLabelsEnabled: boolean;
  isShowThickness: boolean;
}

export function useDrawingSettings(): DrawingSettings {
  const isNightModeEnabled = usePersistedStorageValue('drawingNightModeToggle');
  const isBlackAndWhiteEnabled = usePersistedStorageValue('drawingBlackAndWhiteToggle');

  const opacity = usePersistedStorageValue('drawingOpacity');
  const isKeepOriginName = usePersistedStorageValue('drawingKeepMeasuresNames');
  const isAutofocusEnabled = usePersistedStorageValue('drawingAutofocusEnabled');
  const isKeepStructure = usePersistedStorageValue('drawingKeepStructureEnabled');
  const isLabelsEnabled = usePersistedStorageValue('drawingsShowLabel');
  const isShowThickness = usePersistedStorageValue('drawingShowThickness');


  return {
    isNightModeEnabled,
    isBlackAndWhiteEnabled,
    opacity,
    isKeepOriginName,
    isAutofocusEnabled,
    isKeepStructure,
    isLabelsEnabled,
    isShowThickness,
  };
}


export interface UpdateDrawingSettings {
  toggleKeepNames: () => void;
  toggleAutoFocus: () => void;
  toggleNightMode: () => void;
  toggleBlackAndWhite: () => void;
  toggleShowLabel: () => void;
  changeOpacity: (value: number) => void;
  toggleOffsetStroke: () => void;
  toggleKeepStructure: () => void;
  toggleShowThickness: () => void;
}

export type DrawingSettingsApi = DrawingSettings & UpdateDrawingSettings;

export function useDrawingSettingsWithUpdate(): DrawingSettingsApi {
  const settings = useDrawingSettings();
  const toggleKeepNames = useActionDispatch(PersistedStorageActions.toggleDrawingKeepMeasureName);
  const toggleAutoFocus = useActionDispatch(PersistedStorageActions.drawingToggleAutofocus);
  const toggleNightMode = useActionDispatch(PersistedStorageActions.changeDrawingNightModeToggle);
  const toggleBlackAndWhite = useActionDispatch(PersistedStorageActions.changeDrawingBlackAndWhiteToggle);
  const toggleShowLabel = useActionDispatch(PersistedStorageActions.toggleDrawingShowLabel);
  const changeOpacity = useActionDispatch(PersistedStorageActions.changeDrawingOpacity);
  const toggleOffsetStroke = useActionDispatch(PersistedStorageActions.toggleDrawingOffsetIsStroke);
  const toggleKeepStructure = useActionDispatch(PersistedStorageActions.toggleDrawingKeepStructure);
  const toggleShowThickness = usePersistedStorageToggle('drawingShowThickness');
  return {
    ...settings,
    toggleKeepNames: useUndoRedoForToggle(toggleKeepNames),
    toggleAutoFocus: useUndoRedoForToggle(toggleAutoFocus),
    toggleNightMode: useUndoRedoForToggle(toggleNightMode),
    toggleBlackAndWhite: useUndoRedoForToggle(toggleBlackAndWhite),
    toggleShowLabel: useUndoRedoForToggle(toggleShowLabel),
    changeOpacity: useUndoRedoForSameFunction(changeOpacity, settings.opacity),
    toggleOffsetStroke: useUndoRedoForToggle(toggleOffsetStroke),
    toggleKeepStructure: useUndoRedoForToggle(toggleKeepStructure),
    toggleShowThickness: useUndoRedoForToggle(toggleShowThickness),
  };
}
