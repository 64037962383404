import { AssemblyForm, ItemUpdater } from '../../../assembly-panel/item-form-updater';
import { ItemAfterEffects, MeasurePanelItemAfterEffects } from '../../interfaces';

export interface ItemUpdaterCallbacks {
  getForm: () => AssemblyForm;
  setForm: (form: AssemblyForm) => void;
}

type GetItemUpdaterParams = (
  afterEffects: MeasurePanelItemAfterEffects,
  setForm: (form: AssemblyForm) => void,
  setPiaLoad: () => void,
) => ItemAfterEffects;

const getItemUpdaterParams: GetItemUpdaterParams = (afterEffects, setForm, setPiaLoad) => {
  const { params, baseParams }= afterEffects;
  return {
    baseParams: {
      afterChange: (newForm, id, ...args) => {
        setForm(newForm);
        baseParams.afterChange(newForm, id, ...args);
      },
      afterDelete: (newForm, id, ...args) => {
        setForm(newForm);
        baseParams.afterDelete(newForm, id, ...args);
      },
      afterUnitChange: (newForm, id, ...args) => {
        setPiaLoad();
        setForm(newForm);
        baseParams.afterUnitChange(newForm, id, ...args);
      },
      afterVisibilityChange: (newForm, id, ...args) => {
        setForm(newForm);
        baseParams.afterVisibilityChange(newForm, id, ...args);
      },
    },
    params: {
      afterDeletePropertyGroup: (newForm, ...args) => {
        setForm(newForm);
        params.afterDeletePropertyGroup(newForm, ...args);
      },
      afterAddProperty: (newForm, ...args) => {
        setForm(newForm);
        params.afterAddProperty(newForm, ...args);
      },
      afterDeleteItem: (newForm, ...args) => {
        setForm(newForm);
        params.afterDeleteItem(newForm, ...args);
      },
      afterAddItem: (newForm, ...args) => {
        setForm(newForm);
        params.afterAddItem(newForm, ...args);
      },
      afterChangeFormula: (newForm, ...args) => {
        setPiaLoad();
        setForm(newForm);
        params.afterChangeFormula(newForm, ...args);
      },
    },
  };
};

type GetItemUpdater = (
  callbacks: ItemUpdaterCallbacks,
  afterEffects: MeasurePanelItemAfterEffects,
  setPiaLoad: () => void,
) => ItemUpdater;

export const getItemUpdater: GetItemUpdater = (callbacks, afterEffects, setPiaLoad) => {
  const itemParams = getItemUpdaterParams(afterEffects, callbacks.setForm, setPiaLoad);
  return new ItemUpdater(
    {
      getForm: callbacks.getForm,
      afterEffects: itemParams.baseParams,
    },
    itemParams.params,
  );
};
