import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { SubscriptionType } from 'common/constants/subscription';
import { State } from 'common/interfaces/state';
import { Company } from '../../../units/account/interfaces/company';
import { CompanySubscriptionModel } from '../../../units/account/interfaces/company-subscription-model';
import { OwnCompany } from '../../../units/account/interfaces/own-company';
import { AccountSelectors } from '../../../units/account/selectors';
import { PeopleActions } from '../../../units/people/actions/actions';
import { PersistedStorageActions } from '../../../units/persisted-storage/actions/creators';
import { CurrencyExchangeActions, SubscriptionActions } from '../../../units/subscription/actions/creators';
import { SubscriptionPlanListingModel } from '../../../units/subscription/interfaces/subscription-plan-listing-model';
import { SpinnerPage } from '../../spinner-page';
import { RoutesWrap } from './routes-wrap';


interface StateProps {
  ownCompany: OwnCompany | null;
  companies: Company[];
  selectedCompanyId: number | null;
  lastSelectedCompanyId: number | null;
  selectedSubscriptionType: SubscriptionType;
  currentSubscription: CompanySubscriptionModel;
  plansModel: SubscriptionPlanListingModel | null;
}

interface OwnProps {
  subscriptionType: SubscriptionType;
  urls: any;
  children?: React.ReactNode;
  canShowQuestionnaire?: boolean;
}

interface DispatchProps {
  setSubscriptionType: (subscriptionType: SubscriptionType) => void;
  setSelectedCompany: (companyId: number) => void;
  dropPeopleState: () => void;
  getSubscriptionPeople: (companyId: number) => void;
  fetchCurrencyExchange: () => void;
  getSubscriptionPlans: (subscriptionType: SubscriptionType) => void;
  setLastUsedProductType: (lastSubscriptionType: SubscriptionType) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {
}

class SubscriptionWrapComponent extends React.Component<Props> {
  public componentDidMount(): void {
    const {
      subscriptionType,
      setSubscriptionType,
      setSelectedCompany,
      getSubscriptionPlans,
      fetchCurrencyExchange,
      setLastUsedProductType,
    } = this.props;
    setLastUsedProductType(subscriptionType);
    setSubscriptionType(subscriptionType);
    const companyId = this.getSelectedCompanyId();
    setSelectedCompany(companyId);
    fetchCurrencyExchange();
    getSubscriptionPlans(subscriptionType);
  }

  public componentDidUpdate(prevProps: Props): void {
    const { selectedCompanyId, selectedSubscriptionType, currentSubscription } = this.props;
    if (selectedCompanyId
      && selectedSubscriptionType
      && currentSubscription
      && (
        selectedCompanyId !== prevProps.selectedCompanyId
        || selectedSubscriptionType !== prevProps.selectedSubscriptionType
      )
    ) {
      this.props.getSubscriptionPeople(this.props.selectedCompanyId);
    }
  }

  public render(): JSX.Element {
    const {
      urls,
      selectedSubscriptionType,
      selectedCompanyId,
      children,
      plansModel,
      canShowQuestionnaire,
    } = this.props;

    if (!selectedSubscriptionType || !selectedCompanyId || !plansModel) {
      return <SpinnerPage />;
    }

    return (
      <RoutesWrap urls={urls} subscriptionType={selectedSubscriptionType} canShowQuestionnaire={canShowQuestionnaire} >
        {children}
      </RoutesWrap>
    );
  }

  public componentWillUnmount(): void {
    this.props.setSubscriptionType(null);
    this.props.dropPeopleState();
  }

  private getSelectedCompanyId(): number {
    const { companies, ownCompany, subscriptionType, selectedCompanyId, lastSelectedCompanyId } = this.props;
    const availableCompanies = companies.filter(x => subscriptionType in x.subscriptions);
    const company = availableCompanies.find(x => x.id === selectedCompanyId)
      || availableCompanies.find(x => x.id === lastSelectedCompanyId)
      || ownCompany
      || availableCompanies[0]
      || companies[0];

    return company && company.id;
  }
}

function mapStateToProps(state: State): StateProps {
  const selectedCompany = state.account.selectedCompany;
  const subscription: CompanySubscriptionModel = AccountSelectors.currentSubscription(state);
  return {
    ownCompany: state.account.ownedCompany,
    companies: state.account.companies,
    selectedCompanyId: selectedCompany && selectedCompany.id,
    lastSelectedCompanyId: state.persistedStorage.selectedCompanyId,
    selectedSubscriptionType: state.account.selectedSubscriptionType,
    currentSubscription: subscription,
    plansModel: state.account.subscriptionPlans,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    getSubscriptionPlans: (type) =>
      dispatch(SubscriptionActions.getSubscriptionPlansRequest(type)),
    setSubscriptionType: subscriptionType => dispatch(SubscriptionActions.setSubscriptionType(subscriptionType)),
    setSelectedCompany: companyId => dispatch(SubscriptionActions.setSelectedCompany(companyId)),
    dropPeopleState: () => dispatch(PeopleActions.dropPeopleState()),
    getSubscriptionPeople: companyId => dispatch(PeopleActions.getCompanyPeople(companyId)),
    fetchCurrencyExchange: () => dispatch(CurrencyExchangeActions.fetchCurrencyExchange()),
    setLastUsedProductType: (lastSubscriptionType) =>
      dispatch(PersistedStorageActions.setLastUsedProductType(lastSubscriptionType)),
  };
}


const connector = connect(mapStateToProps, mapDispatchToProps);
export const SubscriptionWrap = connector(SubscriptionWrapComponent);
