import * as React from 'react';

import {
  KreoIconCollapse,
  KreoIconExpand,
} from 'common/UIKit/icons';
import { LineData } from '../../interfaces/gantt-chart';
import { utils } from '../../pages/four-d-visualisation/utils';

interface Props {
  data: LineData;
  isShowExpandButton: boolean;
  hideTime?: boolean;
}

class BaseWorkPackageName extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const time = utils.daysToHoursAndMinutes(this.props.data.activeDuration);
    return (
      <div className='chart-line-name'>
        {this.props.children}
        <div className='chart-line-name__expand-sign-container'>
          {
            (this.props.isShowExpandButton) &&
            (
              this.props.data.isExpanded
                ? <KreoIconCollapse />
                : <KreoIconExpand />
            )
          }
        </div>
        <div className='chart-line-name__name' title={this.props.data.name}>
          {this.props.data.name}
        </div>
        {!this.props.hideTime
          ?
            <div className='chart-line-name__duration'>
              {time.hours}
              <span className='chart-line-name__duration-divider'>:</span>
              {time.minutes > 9 ? time.minutes : `0${time.minutes}`}
            </div>
          : null
        }
      </div>
    );
  }
}

export { BaseWorkPackageName };
