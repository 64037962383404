import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { CheckboxWithTextStyled } from 'common/components/checkbox-with-text';
import { QuickSearchStyled } from 'common/components/quick-search';
import { OptionContainerStyled } from 'common/components/selection-menus';

const Container = styled.div`
  ${QuickSearchStyled.Input} {
    width: 90%;
  }
`;

function applyBorders(addBottomBorder: boolean, color: string): FlattenSimpleInterpolation  {
  if (addBottomBorder) {
    return css`
      border-bottom: 1px solid ${color};
      border-top: 1px solid ${color};
    `;
  } else {
    return css`
      border-top: 1px solid ${color};
    `;
  }
}

const Settings = styled.div<{ hasQuery: boolean }>`
  box-sizing: border-box;
  display: flex;
  ${p => applyBorders(p.hasQuery, p.theme.color.background)}
  padding: 10px;
  gap: 10px;
  flex-wrap: wrap;

  ${CheckboxWithTextStyled.Container} {
    > div {
      min-width: 20px;
    }
  }

  ${OptionContainerStyled.Container} {
    margin-bottom: unset;
    gap: 10px;
    p {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const Styled = {
  Container,
  Settings,
};
