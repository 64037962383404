import React from 'react';
import { useDispatch } from 'react-redux';

import { KreoDialogActions } from 'common/UIKit';
import { BillingPeriodUnit } from '../../../../enums/billing-period-unit';
import { SELECT_SUBSCRIPTION_PLAN_DIALOG_CONSTANTS } from '../constants';

type UseHAndleCloseCallback = (
  setBilingPeriod: (value: BillingPeriodUnit) => void,
) => () => void;

export const useHandleCloseCallback: UseHAndleCloseCallback = (setBillingPeriod) => {
  const dispatch = useDispatch();
  return React.useCallback(() => {
    dispatch(KreoDialogActions.closeDialog(SELECT_SUBSCRIPTION_PLAN_DIALOG_CONSTANTS.DIALOG_NAME));
    setBillingPeriod(BillingPeriodUnit.Year);
  }, []);
};
