import React from 'react';
import { DrawingStylesShapeSettings } from 'common/components/drawings/constants/drawing-styles';
import { Shape } from './shape';
import { Styled } from './styled';

interface Props {
  shape: string;
  setShape: (value: string) => void;
}

const ShapeSwitcherComponent: React.FC<Props> = ({ shape, setShape }) => {
  return (
    <Styled.Container>
      {
        DrawingStylesShapeSettings.map(({ Icon, mode }) => {
          return (
            <Shape
              key={mode}
              Icon={Icon}
              shape={mode}
              selected={shape === mode}
              onSelectClick={setShape}
            />
          );
        })
      }
    </Styled.Container>
  );
};

export const ShapeSwitcher = React.memo(ShapeSwitcherComponent);
