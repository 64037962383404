import * as React from 'react';

import { Toggle as Switch } from '../../../../components/toggle';

interface CheckBoxProps {
  text: string;
  checked: boolean;
  onResolve: (value: boolean) => void;
  controlName: string;
}


export const Toggle: React.FC<CheckBoxProps> = (props) => {
  return (
    <span className='checkbox' key={0}>
      <Switch
        checkedColor={'#5C8AE6'}
        onCheck={props.onResolve}
        checked={props.checked}
        controlName={props.controlName}
      />
      <label>
        {props.text}
      </label>
    </span>
  );
};
