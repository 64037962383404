import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import './index.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State } from 'common/interfaces/state';
import LeftMenu from '../left-menu';

interface OwnProps {
  projectId: string;
  headless?: boolean;
  waitingForAdditionalInfo?: boolean;
  children?: any;
}

interface StateProps {
  leftMenuExpanded: boolean;
  projectLoaded: boolean;
}

interface Props extends OwnProps, StateProps { }

class ProjectLayoutComponent extends React.Component<Props> {

  public render(): React.ReactNode {
    const { leftMenuExpanded, projectLoaded, headless, waitingForAdditionalInfo } = this.props;
    const containerClassName = classNames(
      'project-layout',
      { 'project-layout--headless': headless },
    );

    return (
      <div className={containerClassName}>
        <LeftMenu withLogo={headless} />
        <div
          className={classNames(
            'project-layout__content',
            { 'project-layout__content--collapse': !leftMenuExpanded },
          )}
        >
          {projectLoaded && !waitingForAdditionalInfo
            ? (
              this.props.children
            ) : (
              <SvgSpinner size='large' />
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const currentProject = state.projects.currentProject;
  const projectLoaded = currentProject && currentProject.id === parseInt(ownProps.projectId, 10);

  return {
    leftMenuExpanded: state.toppanel.get('leftmenuExpanded'),
    projectLoaded,
  };
};

export const ProjectLayout = connect(mapStateToProps)(ProjectLayoutComponent);
