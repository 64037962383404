import * as React from 'react';

export const KreoIconSearch: React.FC = () => {
  return (
    <svg
      className='icon-search'
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          // eslint-disable-next-line max-len
          d='M6,2.99995634 C4.34314069,2.99995634 3,4.34310716 3,6 C3,7.65685425 4.34314575,9 6,9 C7.65685931,9 9,7.65684919 9,5.99995634 C9,4.34310209 7.65685425,2.99995634 6,2.99995634 Z M10.1715989,8.75738535 L12.7071068,11.2928932 C13.0976311,11.6834175 13.0976311,12.3165825 12.7071068,12.7071068 C12.3165825,13.0976311 11.6834175,13.0976311 11.2928932,12.7071068 L8.75738875,10.1716023 C7.96695692,10.6951233 7.01908336,11 6,11 C3.23857625,11 1,8.76142375 1,6 C1,3.23854103 3.23856781,0.999956343 6,0.999956343 C8.76142375,0.999956343 11,3.23853259 11,5.99995634 C11,7.01905603 10.6951218,7.96694368 10.1715989,8.75738535 L10.1715989,8.75738535 Z'
          id='path-1'
        />
      </defs>
      <g id='Icons/14x14/Search' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <mask id='mask-2' fill='white'>
          <use xlinkHref='#path-1' />
        </mask>
        <use id='Mask' fill='currentColor' fillRule='nonzero' xlinkHref='#path-1' />
      </g>
    </svg>
  );
};
