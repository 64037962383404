import { createSlice } from '@reduxjs/toolkit';
import { AssignedPia, AssignPiaPatch } from '2d/interfaces';
import { DrawingsGeometryGroup, DrawingsInstanceMeasure } from 'common/components/drawings';
import { DrawingsGeometryRenameInstance } from 'common/components/drawings/actions/payloads/annotation';
import { DrawingsEditFileTreeItem, PageMetaParameterUpdate } from 'common/components/drawings/actions/payloads/common';
import { ActionWith } from 'common/interfaces/action-with';
import { HandleMoveElementPayload, HandleMoveGroupPayload } from '../utils/transaction/interfaces';

export interface TwoDElementViewState {
  handleCreateDrawingElement: (drawingElements: string[]) => void;
  handleRemoveDrawingElement: (drawingElements: string[]) => void;
  handleRenameDrawingElement: (payload: DrawingsGeometryRenameInstance) => void;
  handleRenameFile: (payload: DrawingsEditFileTreeItem) => void;
  handleRenamePage: (pageId: string, name: string) => void;
  handleRenameGroup: (groupId: string, name: string) => void;
  handleSaveElementMeasurements: (measurements: DrawingsInstanceMeasure[]) => void;
  handleChangeAssign: (assignPatch: AssignPiaPatch[]) => void;
  changeColumnAfterAssign: (assignPatch: AssignPiaPatch[]) => void;
  handleToggleShowUnit: () => void;
  handleToggleShowCode: (p: boolean) => void;
  handleMoveGroup: (p: Record<string, HandleMoveGroupPayload[]>) => void;
  handleCreateGroup: (groups: Record<string, DrawingsGeometryGroup[]>) => void;
  handleDeleteGroup: (ids: Set<string>) => void;
  handleMoveElement: (data: HandleMoveElementPayload) => void;
  updateColumnBeforeDelete: (ids: string[]) => void;
  handlePiaDataLoaded: (piaData:  Record<string, AssignedPia>) => void;
  handleChangeIsImperial: () => void;
}

export const TWO_D_ELEMENT_VIEW_INITIAL_STATE: TwoDElementViewState = {
  handleCreateDrawingElement: null,
  handleRemoveDrawingElement: null,
  handleRenameDrawingElement: null,
  handleRenameFile: null,
  handleRenamePage: null,
  handleRenameGroup: null,
  handleSaveElementMeasurements: null,
  handleChangeAssign: null,
  handleToggleShowUnit: null,
  handleToggleShowCode: null,
  handleMoveGroup: null,
  changeColumnAfterAssign: null,
  handleCreateGroup: null,
  handleDeleteGroup: null,
  handleMoveElement: null,
  updateColumnBeforeDelete: null,
  handlePiaDataLoaded: null,
  handleChangeIsImperial: null,
};

const TwoDElementView = createSlice({
  name: '@twoDElementView',
  initialState: TWO_D_ELEMENT_VIEW_INITIAL_STATE,
  reducers: {
    setCallbacks: (state, { payload }: ActionWith<TwoDElementViewState>) => {
      Object.keys(payload).forEach(handler => {
        state[handler] = payload[handler];
      });
    },
    handleCreateDrawingElement: (state, { payload }: ActionWith<string[]>) => {
      state.handleCreateDrawingElement(payload);
    },
    handleRemoveDrawingElement: (state, { payload }: ActionWith<string[]>) => {
      state.handleRemoveDrawingElement(payload);
    },
    handleRenameDrawingElement: (state, { payload }: ActionWith<DrawingsGeometryRenameInstance[]>) => {
      payload.forEach((renameInstance: DrawingsGeometryRenameInstance) => {
        state.handleRenameDrawingElement(renameInstance);
      });
    },
    handleRenameFile: (state, { payload }: ActionWith<DrawingsEditFileTreeItem>) => {
      state.handleRenameFile(payload);
    },
    handleRenamePage: (state, { payload }: ActionWith<PageMetaParameterUpdate>) => {
      const parameter = payload.parameter;
      if (parameter === 'name') {
        state.handleRenamePage(payload.drawingId, payload.value as string);
      }
    },
    handleRenameGroup: (state, { payload }: ActionWith<Array<{ id: string, name: string }>>) => {
      payload.forEach(p => {
        state.handleRenameGroup(p.id, p.name);
      });
    },
    handleSaveElementMeasurements: (state, { payload }: ActionWith<DrawingsInstanceMeasure[]>) => {
      if (state.handleSaveElementMeasurements) {
        state.handleSaveElementMeasurements(payload);
      }
    },
    handleChangeAssign: (state, { payload }: ActionWith<AssignPiaPatch[]>) => {
      state.handleChangeAssign(payload);
    },
    changeColumnAfterAssign: (state, { payload }: ActionWith<AssignPiaPatch[]>) => {
      state.changeColumnAfterAssign(payload);
    },
    handleToggleShowUnit: (state) => {
      state.handleToggleShowUnit();
    },
    handleToggleShowCode: (state, { payload }: ActionWith<boolean>) => {
      state.handleToggleShowCode(payload);
    },
    handleMoveGroup: (state, { payload }: ActionWith<Record<string, HandleMoveGroupPayload[]>>) => {
      state.handleMoveGroup(payload);
    },
    handleCreateGroup: (state, { payload }: ActionWith<Record<string, DrawingsGeometryGroup[]>>) => {
      state.handleCreateGroup(payload);
    },
    handleDeleteGroup: (state, { payload }: ActionWith<Set<string>>) => {
      state.handleDeleteGroup(payload);
    },
    handleMoveElement: (state, { payload }: ActionWith<HandleMoveElementPayload>) => {
      state.handleMoveElement(payload);
    },
    updateColumnBeforeDelete: (state, { payload }: ActionWith<string[]>) => {
      state.updateColumnBeforeDelete(payload);
    },
    handlePiaDataLoaded: (state, { payload }: ActionWith<Record<string, AssignedPia>>) => {
      if (state.handlePiaDataLoaded) {
        state.handlePiaDataLoaded(payload);
      }
    },
    handleChangeIsImperial: (state) => {
      if (state.handleChangeIsImperial) {
        state.handleChangeIsImperial();
      }
    },
  },
});

export const TwoDElementViewActions = TwoDElementView.actions;
export const twoDElementViewReducers = TwoDElementView.reducer;
