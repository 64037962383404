import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { TextSearch as TextSearchComponent } from 'common/components/drawings';
import {
  AutoMeasureControl,
  InfoIconAutoMeasure,
} from 'common/components/drawings/layout-components/top-control-group/auto-measure-control';
import {
  DrawingsExport,
  InfoIconDrawingsExport,
} from 'common/components/drawings/layout-components/top-control-group/drawings-export';
import { CompareView } from 'unit-2d-info-panel/content';
import { AiBadge } from 'unit-2d-info-panel/content/ai-badge';
import { AutoMeasureAIView } from 'unit-2d-info-panel/content/auto-measure-ai-view';
import { PdfFilterView } from 'unit-2d-info-panel/content/pdf-filter';
import { ScaleMenu } from 'unit-2d-info-panel/content/scale';

export enum Menu {
  Default = 'Default',
  Scale = 'Scale',
  AutoMeasure = 'AutoMeasure',
  AutoMeasure2 = 'AutoMeasure 2.0',
  AskAi = 'AskAi',
  Compare = 'Compare',
  TextSearch = 'TextSearch',
  PdfFilter = 'Declutter',
  Export = 'Export',
}

export interface BaseMenuConfig {
  badge?: JSX.Element;
  title: string;
  infoIcon?: React.ComponentType;
  menuComponent?: React.ComponentType;
}

export const BaseMenuSettings: Record<Menu, BaseMenuConfig> = {
  [Menu.Default]: null,
  [Menu.Scale]: {
    badge: null,
    title: 'SCALE',
    infoIcon: Icons.ScaleSmall,
    menuComponent: ScaleMenu,
  },
  [Menu.AutoMeasure]: {
    badge: <AiBadge/>,
    title: 'AUTO MEASURE',
    infoIcon: InfoIconAutoMeasure,
    menuComponent: AutoMeasureControl,
  },
  [Menu.AutoMeasure2]: {
    badge: <AiBadge/>,
    title: 'AUTO MEASURE 2.0',
    infoIcon: Icons.AutoMeasureSmall,
    menuComponent: AutoMeasureAIView,
  },
  [Menu.AskAi]: {
    badge: <AiBadge/>,
    title: 'ASK AI',
    infoIcon: Icons.Caddy,
  },
  [Menu.Compare]: {
    title: 'COMPARE PAGES',
    infoIcon: Icons.CompareSmall,
    menuComponent: CompareView,
  },
  [Menu.TextSearch]: {
    title: 'TEXT SEARCH',
    infoIcon: Icons.TextSearchSmall,
    menuComponent: TextSearchComponent,
  },
  [Menu.Export]: {
    badge: null,
    title: 'EXPORT',
    infoIcon: InfoIconDrawingsExport,
    menuComponent: DrawingsExport,
  },
  [Menu.PdfFilter]: {
    title: 'Style filter',
    infoIcon: Icons.Declutter,
    menuComponent: PdfFilterView,
  },
};

