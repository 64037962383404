import { MenuItemDef, RowNode, ToolPanelDef } from 'ag-grid-community';
import autobind from 'autobind-decorator';
import * as React from 'react';

import './quantity-take-off-left-panel.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { SyncStatus } from 'common/enums/sync-status';
import { BimRecordDataApi } from '../../../../api/bim-record-data/api';
import { UserExtractorsApi } from '../../../../api/user-extractors/api';
import { AccordionMenu, TabConfig } from '../../../../components/accordion-menu';
import { QuantityTakeOffApi } from '../../api/quantity-take-off';
import { QtoSelectElementsEventSource } from '../../enums/qto-select-elements-event-source';
import { ExtractorConfig, UserExtractors } from '../../interfaces/graph-storage-records-config';
import { ModelBrowserPropertyGroupView } from '../../interfaces/properties-provider-types';
import { ModelType } from '../../interfaces/quantity-take-off/quantity-take-off-model-type';
import { SharedParameterKeyHelper } from '../../utils/shared-parameter-key-helper';
import { QtoCommonElementTable } from '../quantity-take-off-common-table';
import { QuantityTakeOffControls } from '../quantity-take-off-controls';
import { QtoCustomFilterFilterPanel } from '../quantity-take-off-custom-filter-panel';
import { QtoCustomFilterTabHeader } from '../quantity-take-off-custom-filter-tab-header';
import { QtoElementTabMapper } from '../quantity-take-off-left-table-tab/quantity-take-off-element-tab-mapper';
import { QtoLocationTabMapper } from '../quantity-take-off-left-table-tab/quantity-take-off-location-tab-mapper';
import { UseSaveState } from '../quantity-take-off-left-table-tab/use-save-state';
import { ReportTableExporter } from '../quantity-take-off-report-table/report-table-data-transfer';
import { withPropertiesDataProviderApi } from '../with-properties-data-provider-api';
import { ColumnOrderHelper } from './column-order-helper';
import { QtoLeftPanelConstants } from './constants';
import { getElementContextItems } from './context-menu/get-element-context-items';
import { extendUserExtractorsConfig } from './extend-user-extractors-config';
import { getTablesConfig } from './get-config';
import { handleSelectUserExtractor } from './handle-select-user-extractor';
import { initialState } from './initial-state';
import {
  QtoLeftPanelProps,
  QtoLeftPanelState,
  RecordsUpdateType,
  SelectSharedParametersResult,
  SelectUserExtractorResult,
  TableApi,
  TableState,
} from './interfaces';
import { PropertiesDataProviderHelper } from './properties-data-provider-helper';
import { syncColDefWithColState } from './sync-col-def-with-col-state';
import { HandlerOpenStatus } from './utils';

export class QtoLeftPanel extends React.PureComponent<QtoLeftPanelProps, QtoLeftPanelState> {
  private columnOrderHelper: ColumnOrderHelper;
  private tablesApi: {
    locationApi: TableApi,
    elementApi: TableApi,
  } = { locationApi: null, elementApi: null };
  private prevSelectedLocation: number[] = [];
  private prevSelectedElement: number[] = [];
  private revitIds: Record<string, string> = {};
  private elementDataExporter: ReportTableExporter;
  private locationDataExporter: ReportTableExporter;
  private useElementsSaveState: UseSaveState;
  private useLocationSaveState: UseSaveState;
  private elementsHandlerOpenStatus: HandlerOpenStatus;
  private locationsHandlerOpenStatus: HandlerOpenStatus;
  private isLocationsTabOpen: boolean = false;
  private isElementsTabOpen: boolean = true;
  private locationToolPanelColumnsProps: Partial<ToolPanelDef> = {
    toolPanelParams: {
      suppressRowGroups: true,
    },
  };
  private clipboxVisibleFilter: (itemId: number) => boolean;
  private cachedSelectedData: { selectedBimElementsIds: number[], isExpand?: boolean } = null;

  public constructor(props: QtoLeftPanelProps) {
    super(props);
    this.state = initialState(props);
    this.useElementsSaveState = new UseSaveState(this.props.currentProjectId, props.modelType, 'Elements');
    this.useLocationSaveState = new UseSaveState(this.props.currentProjectId, props.modelType, 'Locations');
    this.props.sendElementTableApi({
      setSelected: this.updateSelection,
      getSelectedValue: this.getSelectedValue,
      focusAndExpand: this.focusAndExpand,
      getRowNodes: this.getRowNodes,
      getColumn: id => this.tablesApi.elementApi.getColumn(id) || this.tablesApi.locationApi.getColumn(id),
    });
    this.elementsHandlerOpenStatus = new HandlerOpenStatus(
      this.getActualElementsIds,
      this.props.elementViewChange,
      this.handelChangeElementOpenStatus,
    );
    this.locationsHandlerOpenStatus = new HandlerOpenStatus(
      this.getActualLocationsIds,
      this.props.locationsViewChange,
      this.handelChangeLocationOpenStatus,
    );
  }

  public static getDerivedStateFromProps(
    nextProps: QtoLeftPanelProps,
    prevState: QtoLeftPanelState,
  ): Partial<QtoLeftPanelState> {
    const isUnitSystemUpdated = nextProps.isImperialUnit !== prevState.elementContext.isImperialUnit
      || nextProps.isImperialUnit !== prevState.locationContext.isImperialUnit;
    if (isUnitSystemUpdated) {
      return {
        elementContext: { ...prevState.elementContext, isImperialUnit: nextProps.isImperialUnit },
        locationContext: { ...prevState.locationContext, isImperialUnit: nextProps.isImperialUnit },
      };
    }
    return null;
  }

  public render(): JSX.Element {
    const isCustomFiltersEnable = this.props.ability.can(Operation.Read, Subject.QuantityTakeOffCustomFilters);
    const tabConfig: TabConfig[] = [
      {
        name: 'Elements',
        children: (
          <QuantityTakeOffControls
            disableEngineFilter={this.props.disableEngineFilter}
            onFilter={this.onElementFilter}
            currentProjectId={this.props.currentProjectId}
            displayNames={this.props.recordsConfig}
            isLocation={false}
            expandAllNodes={this.expandAllElementsRow}
            collapseAllNodes={this.collapseAllElementsRow}
            filterName={`${this.props.currentProjectId}_filter_Elements`}
            records={this.state.elementRecords}
            saveState={this.useElementsSaveState.saveFilterPanelState}
            getSaveState={this.useElementsSaveState.getFilterPanelState}
            getReportElementIds={this.props.getReportElementIds}
            isEngineFilterEnabled={this.state.useEngineFilter}
            engineFilter={this.engineFilter}
            mapIdHelper={this.props.mapIdHelper}
          />
        ),
        onOpenStatusChange: this.elementsHandlerOpenStatus.changeOpenStatus,
        minSize: 210,
      },
      {
        name: 'Locations',
        children: (
          <QuantityTakeOffControls
            disableEngineFilter={this.props.disableEngineFilter}
            onFilter={this.onLocationFilter}
            currentProjectId={this.props.currentProjectId}
            displayNames={this.props.recordsConfig}
            isLocation={true}
            expandAllNodes={this.expandAllLocationsRow}
            collapseAllNodes={this.collapseAllLocationsRow}
            filterName={`${this.props.currentProjectId}_filter_Location`}
            records={this.state.locationRecords}
            saveState={this.useLocationSaveState.saveFilterPanelState}
            getSaveState={this.useLocationSaveState.getFilterPanelState}
            getReportElementIds={this.props.getReportElementIds}
            isEngineFilterEnabled={this.state.useEngineFilter}
            engineFilter={this.engineFilter}
            mapIdHelper={this.props.mapIdHelper}
          />
        ),
        onOpenStatusChange: this.locationsHandlerOpenStatus.changeOpenStatus,
        minSize: 210,
      },
    ];

    if (isCustomFiltersEnable) {
      tabConfig.push({
        name: 'Filters',
        children: (<QtoCustomFilterTabHeader />),
        minSize: 150,
      });
    }

    const userExtractorsConfigs = this.props.recordsConfig[QtoLeftPanelConstants.USER_EXTRACTORS_KEY] as UserExtractors;
    const extractorsConfig = this.props.recordsConfig[QtoLeftPanelConstants.EXTRACTORS_KEY] as ExtractorConfig;
    extendUserExtractorsConfig(userExtractorsConfigs, extractorsConfig);
    const getContextItems = getElementContextItems(
      userExtractorsConfigs,
      this.props.currentProjectId,
      this.props.pivotEnabled,
      this.getCommonProperties,
      this.updateUserExtractor,
      this.updateSharedParameters,
      this.props.syncWithElementsDataInReport,
    );
    return this.state.isLoading
      ? <SvgSpinner size='middle' />
      : (
        <>
          {
            this.state.isUpdating && (
              <div className='quantity-take-off-left-panel__blanket'>
                <SvgSpinner size='middle' />
              </div>
            )
          }
          <AccordionMenu
            tabConfig={tabConfig}
          >
            <QtoCommonElementTable
              id='qto-elements-breakdown-table'
              dataExporters={[this.elementDataExporter]}
              expandTable={this.state.elementExpandRowsStatus}
              userExpand={this.onUserExpandElements}
              selectionEventSource={this.props.selectElementsEventSource}
              columnDefs={this.state.elementColumnConfig}
              rowData={this.state.showElementData}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              context={this.state.elementContext}
              onElementTableSelected={this.onElementTableSelected}
              sendElementTableApi={this.saveElementTable}
              name={`${this.props.currentProjectId}_Elements`}
              saveState={this.useElementsSaveState.saveTableState}
              getSaveState={this.useElementsSaveState.getTableState}
              getContextItems={getContextItems}
              className={this.state.className}
              onColumnRowGroupChanged={this.onColumnRowGroupChanged}
              onFirstDataRendered={this.onFirstDataRenderedElementTable}
              isPageDataReady={this.props.isPageDataReady}
            />
            <QtoCommonElementTable
              dataExporters={[this.locationDataExporter]}
              expandTable={this.state.locationExpandRowsStatus}
              userExpand={this.onUserExpandLocations}
              selectionEventSource={this.props.selectElementsEventSource}
              toolPanelColumnsProps={this.locationToolPanelColumnsProps}
              columnDefs={this.state.locationColumnConfig}
              rowData={this.state.showLocationData}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              context={this.state.locationContext}
              onElementTableSelected={this.onLocationTableSelected}
              sendElementTableApi={this.saveLocationTable}
              name={`${this.props.currentProjectId}_Location`}
              saveState={this.useLocationSaveState.saveTableState}
              getSaveState={this.useLocationSaveState.getTableState}
              getContextItems={this.getEmptyContextItems}
              isPageDataReady={this.props.isPageDataReady}
            />
          {isCustomFiltersEnable && (
            <QtoCustomFilterFilterPanel
              recordValuesMap={this.props.recordValuesMap}
              onCustomFiltersSelect={this.props.onCustomFiltersSelect}
              sendTableSelectionApi={this.props.sendCustomFiltersSelectionApi}
              mapIdHelper={this.props.mapIdHelper}
            />
          )}
          </AccordionMenu>
        </>
      );
  }

  public async componentDidMount(): Promise<void> {
    const { currentProjectId, recordsConfig } = this.props;
    const [revitIds] = await Promise.all([
      BimRecordDataApi.getRevitId(currentProjectId),
      this.useElementsSaveState.loadState(),
      this.useLocationSaveState.loadState(),
    ]);
    this.revitIds = revitIds;
    const elementMapper = new QtoElementTabMapper(recordsConfig).elementTableMapper;
    const locationMapper = new QtoLocationTabMapper(recordsConfig).elementTableMapper;
    this.elementDataExporter = new ReportTableExporter(elementMapper);
    this.locationDataExporter = new ReportTableExporter(locationMapper);
    this.columnOrderHelper = new ColumnOrderHelper(recordsConfig);
    const state = await this.getTablesState();
    this.setTablesConfig(state);
    this.createClipboxVisibleCheck();
    const elementsFilterState = this.useElementsSaveState.getFilterPanelState();
    const locationsFilterState = this.useLocationSaveState.getFilterPanelState();
    if (elementsFilterState) {
      this.onElementFilter(
        Object.keys(this.props.visibleElementIds).length
          ? elementsFilterState.selectedIds.filter(this.engineFilter)
          : elementsFilterState.selectedIds,
        elementsFilterState.selectedIds,
      );
    }
    if (locationsFilterState) {
      this.onLocationFilter(
        Object.keys(this.props.visibleElementIds).length
          ? locationsFilterState.selectedIds.filter(this.engineFilter)
          : locationsFilterState.selectedIds,
        locationsFilterState.selectedIds,
      );
    }
    this.setState({ isLoading: false });
    this.props.onReady();
  }

  public async componentDidUpdate(prevProps: QtoLeftPanelProps): Promise<void> {
    const { elementRecords, visibleClipBoxElements, visibleElementIds, isImperialUnit } = this.props;
    if (elementRecords !== prevProps.elementRecords) {
      await this.updateTableAfterUpdatedRecords();
    }

    const visibileClipBoxElementsChanged = visibleClipBoxElements !== prevProps.visibleClipBoxElements;
    this.createClipboxVisibleCheck();
    if (visibleElementIds !== prevProps.visibleElementIds || visibileClipBoxElementsChanged) {
      const useEngineFilter = (visibleClipBoxElements && !!visibleClipBoxElements.length)
        || Object.entries(visibleElementIds).some(([, value]) => !value);
      if (useEngineFilter !== this.state.useEngineFilter) {
        this.setState({ useEngineFilter });
      } else if (useEngineFilter) {
        this.onElementFilter(this.state.elementFilteredIds.filter(this.engineFilter), this.state.elementFilteredIds);
        this.onLocationFilter(this.state.locationFilteredIds.filter(this.engineFilter), this.state.locationFilteredIds);
      }
    }

    if (isImperialUnit !== prevProps.isImperialUnit) {
      this.tablesApi.elementApi.refreshCells();
      this.tablesApi.locationApi.refreshCells();
    }
  }


  @autobind
  private onFirstDataRenderedElementTable(): void {
    if (this.cachedSelectedData) {
      this.props.changeSyncStatus(QtoSelectElementsEventSource.LeftPanel, SyncStatus.Syncing);
      this.updateSelection(this.cachedSelectedData.selectedBimElementsIds, this.cachedSelectedData.isExpand);
      this.cachedSelectedData = null;
    }
  }

  @autobind
  private onColumnRowGroupChanged(): void {
    this.tablesApi.elementApi.setSelected(this.prevSelectedElement);
  }

  private getEmptyContextItems(): MenuItemDef[] {
    return [];
  }

  @autobind
  private engineFilter(id: number): boolean {
    return this.props.visibleElementIds[id] && this.clipboxVisibleFilter(id);
  }

  @autobind
  private createClipboxVisibleCheck(): void {
    if (this.props.visibleClipBoxElements) {
      const ids = new Set(this.props.visibleClipBoxElements);
      this.clipboxVisibleFilter = itemId => ids.has(itemId);
    } else {
      this.clipboxVisibleFilter = () => true;
    }
  }

  @autobind
  private handelChangeElementOpenStatus(status: boolean): void {
    if (status) {
      this.isElementsTabOpen = true;
      this.handleFilter()();
    } else {
      this.isElementsTabOpen = false;
      this.resetElementsSelection();
    }
  }

  @autobind
  private resetElementsSelection(): void {
    this.tablesApi.elementApi.setSelected([]);
  }

  @autobind
  private handelChangeLocationOpenStatus(status: boolean): void {
    if (status) {
      this.isLocationsTabOpen = true;
      this.handleFilter()();
    } else {
      this.isLocationsTabOpen = false;
      this.resetLocationsSelection();
    }
  }

  @autobind
  private resetLocationsSelection(): void {
    this.tablesApi.locationApi.setSelected([]);
  }

  @autobind
  private getActualElementsIds(): number[] {
    return this.state.elementFilteredIds && this.state.elementFilteredIds.length
      ? this.state.elementFilteredIds : this.state.showElementData.map(data => data.id as number);
  }

  @autobind
  private getActualLocationsIds(): number[] {
    return this.state.locationFilteredIds && this.state.locationFilteredIds.length
      ? this.state.locationFilteredIds : this.state.showLocationData.map(data => data.id as number);
  }

  @autobind
  private expandAllElementsRow(): void {
    this.setState({ elementExpandRowsStatus: true });
  }

  @autobind
  private expandAllLocationsRow(): void {
    this.setState({ locationExpandRowsStatus: true });
  }

  @autobind
  private collapseAllElementsRow(): void {
    this.setState({ elementExpandRowsStatus: false });
  }

  @autobind
  private collapseAllLocationsRow(): void {
    this.setState({ locationExpandRowsStatus: false });
  }

  @autobind
  private onUserExpandElements(): void {
    this.setState({ elementExpandRowsStatus: undefined });
  }

  @autobind
  private onUserExpandLocations(): void {
    this.setState({ locationExpandRowsStatus: undefined });
  }

  @autobind
  private async updateUserExtractor(
    selectedBimIds: number[],
    extractorValue: SelectUserExtractorResult,
  ): Promise<void> {
    await handleSelectUserExtractor(
      this.props.elementRecords,
      this.props.currentProjectId,
      ModelType.QuantityTakeOff,
      selectedBimIds,
      extractorValue,
    );
    await UserExtractorsApi.updateRecords(this.props.currentProjectId, extractorValue.extractorId, selectedBimIds);
  }

  @autobind
  private async updateSharedParameters(
    selectedBimIds: number[],
    sharedParameters: SelectSharedParametersResult,
  ): Promise<void> {
    await this.handleSelectSharedParameters(
      selectedBimIds,
      sharedParameters,
    );
    this.props.loadRecords(this.props.currentProjectId);
  }

  @autobind
  private async handleSelectSharedParameters(
    selectedBimIds: number[],
    sharedParameters: SelectSharedParametersResult,
  ): Promise<void> {
    this.setState({ isUpdating: true });
    const name = `${sharedParameters.groupName.trim()}.${sharedParameters.name.trim()}`;
    const propertiesValues = await PropertiesDataProviderHelper.getElementPropertiesValueAsync(
      selectedBimIds,
      this.props.mapIdHelper.mapBimIdToEngineId,
      this.props.getElementProrertyValue,
      sharedParameters.name,
      sharedParameters.groupName,
    );
    const updatedRecords = [];
    const key = SharedParameterKeyHelper.getKeyFromName(name, QtoLeftPanelConstants.ADDITIONAL_PROPERTIES_PREFIX);
    this.props.elementRecords.forEach((record => {
      const payload = propertiesValues[record.id];
      if (payload) {
        updatedRecords.push({ id: record.id, props: { ...record.props, [key]: [payload] } });
      }
    }));
    await QuantityTakeOffApi.updateRecords(
      this.props.currentProjectId,
      this.props.modelType,
      updatedRecords,
      RecordsUpdateType.UpdateSharedParameter,
    );
  }

  @autobind
  private getCommonProperties(ids: number[]): ModelBrowserPropertyGroupView[] {
    const engineIds = this.props.mapIdHelper.mapBimIdsToEngineIds(ids);
    const result = this.props.getElementPropertiesName(engineIds);
    return result;
  }

  @autobind
  private async updateTableAfterUpdatedRecords(): Promise<void> {
    const elementMapper =
      new QtoElementTabMapper(this.props.recordsConfig).elementTableMapper;
    this.elementDataExporter = new ReportTableExporter(elementMapper);
    this.columnOrderHelper = new ColumnOrderHelper(this.props.recordsConfig);
    const { leftPanelTableConfigs, showElementData } = await this.getTablesState();
    const element = leftPanelTableConfigs.tableConfigs.element;
    const filterConfigs = leftPanelTableConfigs.filterConfigs;
    const saveState = this.useElementsSaveState.getTableState();
    const columnSaveState = saveState
      ? saveState.columns
      : this.tablesApi.elementApi.getColumnState();
    const prevColumnDef = this.state.elementColumnConfig;
    const colDefs = columnSaveState
      ? syncColDefWithColState(element.columnDefs, columnSaveState, prevColumnDef)
      : element.columnDefs;
    this.setState(
      {
        elementRowData: element.rowData,
        elementContext: element.context,
        elementColumnConfig: colDefs,
        showElementData,
        elementRecords: filterConfigs.element,
        isUpdating: false,
      },
      () => {
        this.tablesApi.elementApi.setSelectedAfterUpdateRecords(this.prevSelectedElement);
      });
  }

  @autobind
  private getSelectedValue(): number[] {
    return this.prevSelectedElement.concat(this.prevSelectedLocation);
  }

  private async getTablesState(): Promise<TableState> {
    const { tableConfigs, filterConfigs } = await getTablesConfig(
      this.props.elementRecords,
      this.props.recordsConfig,
      this.revitIds,
      this.columnOrderHelper,
    );
    const { ids: elementIds, showData: showElementData } = this.processRowData(tableConfigs.element.rowData, false);
    const { ids: locationIds, showData: showLocationData } = this.processRowData(tableConfigs.location.rowData, true);
    return {
      leftPanelTableConfigs: { tableConfigs, filterConfigs },
      elementIds,
      locationIds,
      showElementData,
      showLocationData,
    };
  }

  private processRowData(rowData: Array<Record<string, string | number>>, isLocation: boolean): {
    ids: number[],
    showData: Array<Record<string, string | number>>,
  } {
    const ids = [];
    const showData = [];
    for (const data of rowData) {
      const intId = Number(data.id);
      ids.push(intId);
      if (this.filterRecordsIds(isLocation, intId)) {
        showData.push(data);
      }
    }
    return { ids, showData };
  }


  private setTablesConfig(
    tableState: TableState,
  ): void {
    const {
      leftPanelTableConfigs,
      showElementData,
      showLocationData,
      elementIds,
      locationIds } = tableState;
    const { tableConfigs, filterConfigs } = leftPanelTableConfigs;
    this.setState((state) =>
      ({
        locationColumnConfig: tableConfigs.location.columnDefs,
        elementColumnConfig: tableConfigs.element.columnDefs,
        locationRowData: tableConfigs.location.rowData,
        elementRowData: tableConfigs.element.rowData,
        elementContext: tableConfigs.element.context,
        locationContext: tableConfigs.location.context,
        elementFilteredIds: state.elementFilteredIds.length ? state.elementFilteredIds : elementIds,
        locationFilteredIds: state.locationFilteredIds.length ? state.locationFilteredIds : locationIds,
        showElementData,
        showLocationData,
        elementRecords: filterConfigs.element,
        locationRecords: filterConfigs.location,
      }),
    );
  }

  @autobind
  private onElementTableSelected(ids: Array<string | number>): void {
    this.prevSelectedElement = ids.map(Number);
    this.props.onElementTableSelected(this.prevSelectedLocation.concat(this.prevSelectedElement));
  }

  @autobind
  private onLocationTableSelected(ids: Array<string | number>): void {
    this.prevSelectedLocation = ids.map(Number);
    this.props.onElementTableSelected(this.prevSelectedLocation.concat(this.prevSelectedElement));
  }

  private isEmpty(selectedBimElementsIds: number[]): boolean {
    const isEmpty = selectedBimElementsIds.length === 0
      && this.prevSelectedElement.length === 0
      && this.prevSelectedLocation.length === 0;

    return isEmpty;
  }

  @autobind
  private updateSelection(selectedBimElementsIds: number[], isExpand?: boolean): void {
    if (this.isEmpty(selectedBimElementsIds)) {
      this.props.onElementTableSelected(selectedBimElementsIds);
    }

    if (this.state.isLoading) {
      this.cachedSelectedData = { selectedBimElementsIds, isExpand };
      this.props.changeSyncStatus(QtoSelectElementsEventSource.LeftPanel, SyncStatus.InSync);
      return;
    }

    const elementIds = this.state.showElementData.map(rowData => Number(rowData.id));
    const isElementActive = this.isChange(selectedBimElementsIds, this.prevSelectedElement, elementIds);
    if (isElementActive) {
      this.tablesApi.elementApi.setSelected(selectedBimElementsIds, isExpand);
    }

    const locationId = this.state.showLocationData.map(r => Number(r.id));
    const isLocationActive = this.isChange(selectedBimElementsIds, this.prevSelectedLocation, locationId);
    if (isLocationActive) {
      this.tablesApi.locationApi.setSelected(selectedBimElementsIds, isExpand);
    }

    if (!isElementActive && !isLocationActive) {
      this.props.changeSyncStatus(QtoSelectElementsEventSource.LeftPanel, SyncStatus.InSync);
    }
  }


  @autobind
  private getRowNodes(ids: Array<string | number>): RowNode[] {
    return this.tablesApi.elementApi.getRowNodes(ids);
  }

  @autobind
  private focusAndExpand(ids: number[]): void {
    this.tablesApi.elementApi.focusAndExpand(ids);
    this.tablesApi.locationApi.focusAndExpand(ids);
  }

  @autobind
  private isResetAllSelected(selectedIds: number[], prevSelectedId: number[]): boolean {
    return prevSelectedId.length > 0 && selectedIds.length === 0;
  }

  @autobind
  private isUpdate(selectedIds: number[], prevSelectedId: number[], allIds: number[]): boolean {
    return (
      (prevSelectedId.length > 0 && selectedIds.length > 0)
      && (
        prevSelectedId.some(prevId => !selectedIds.includes(prevId))
        || selectedIds.some(selectId => allIds.includes(selectId) && !prevSelectedId.includes(selectId))
      )
    );
  }

  @autobind
  private isSetSelected(selectedIds: number[], prevSelectedId: number[], allIds: number[]): boolean {
    return prevSelectedId.length === 0
      && selectedIds.length > 0
      && selectedIds.some(selectedId => allIds.includes(selectedId));
  }

  @autobind
  private isChange(selectedIds: number[], prevSelectedId: number[], allIds: number[]): boolean {
    return this.isSetSelected(selectedIds, prevSelectedId, allIds)
      || this.isResetAllSelected(selectedIds, prevSelectedId)
      || this.isUpdate(selectedIds, prevSelectedId, allIds);
  }

  @autobind
  private onElementFilter(bimIds: number[], bimIdsWithoutEngineFilter: number[]): void {
    if (bimIdsWithoutEngineFilter) {
      const idsSet = new Set(bimIds);
      const showRowData = this.state.elementRowData.filter(d => idsSet.has(Number(d.id)));
      this.setState(
        {
          elementFilteredIds: bimIdsWithoutEngineFilter,
          showElementData: showRowData,
          elementExpandRowsStatus: undefined,
        },
        this.handleFilter(idsSet),
      );
    } else {
      this.setState(
        {
          showElementData: this.state.elementRowData,
          locationFilteredIds: [],
          elementExpandRowsStatus: undefined,
        },
        this.handleFilter(),
      );
    }
  }

  @autobind
  private onLocationFilter(bimIds: number[], bimIdsWithoutEngineFilter: number[]): void {
    if (bimIds) {
      const idsSet = new Set(bimIds);
      const showRowData = this.state.locationRowData.filter(d => idsSet.has(Number(d.id)));
      this.setState(
        {
          locationFilteredIds: bimIdsWithoutEngineFilter,
          showLocationData: showRowData,
          locationExpandRowsStatus: undefined,
        },
        this.handleFilter(idsSet),
      );
    } else {
      this.setState(
        {
          showElementData: this.state.elementRowData,
          locationFilteredIds: [],
          locationExpandRowsStatus: undefined,
        },
        this.handleFilter(),
      );
    }
  }

  private handleFilter(idsSet?: Set<number>): () => void {
    return () => {
      const filteredIds = this.getResultFilterSet();
      this.props.onFilter(filteredIds);
      if (this.tablesApi.elementApi && idsSet) {
        const selectedIds = this.prevSelectedElement.filter(id => idsSet.has(id));
        this.tablesApi.elementApi.setSelectedAfterUpdateRecords(selectedIds);
      }
      if (this.tablesApi.locationApi && idsSet) {
        const selectedIds = this.prevSelectedLocation.filter(id => idsSet.has(id));
        this.tablesApi.locationApi.setSelectedAfterUpdateRecords(selectedIds);
      }
    };
  }

  @autobind
  private saveElementTable(ref: TableApi): void {
    this.tablesApi.elementApi = ref;
  }

  @autobind
  private saveLocationTable(ref: TableApi): void {
    this.tablesApi.locationApi = ref;
  }

  @autobind
  private getResultFilterSet(): number[] {
    const elementsIds = this.isElementsTabOpen ? this.state.elementFilteredIds : [];
    const locationsIds = this.isLocationsTabOpen ? this.state.locationFilteredIds : [];
    return elementsIds.concat(locationsIds);
  }

  private filterRecordsIds(isLocation: boolean, id: number): boolean {
    const filterBimIds = isLocation ? this.state.locationFilteredIds : this.state.elementFilteredIds;
    return (!filterBimIds.length || filterBimIds.includes(id)) && this.props.visibleElementIds[id];
  }
}

export const QtoLeftPanelWithDataProvider = withAbilityContext(withPropertiesDataProviderApi(QtoLeftPanel));
