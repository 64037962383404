import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './resource-limitations-fields-area.scss';

import { Checkbox, MaterialInput, MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { ScenariosActions } from '../../../scenarios/actions';

const shiftOptions: number[] = [1, 2];

interface ReduxActions {
  setNewProductivity: (id: number, value: any) => void;
  setNewShift: (id: number, value: any) => void;
  setNewUseAmdahl: (id: number, value: any) => void;
}

interface Props extends ReduxActions {
  itemId: number;
  shift: number;
  productivity: number;
  useAmdahl: boolean;
  mixedCheckbox?: boolean;
}

class ResourceLimitationsFieldsAreaComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { itemId, productivity, shift, useAmdahl } = this.props;
    const id = itemId ? itemId : 'total';
    const className = 'resource-limitations-fields-area';

    return (
      <div
        className={className}
        onClick={this.stopPropagation}
      >
        <div className={`${className}__column ${className}__column--shift`}>
          <MaterialSelect
            name={`${id}.shift`}
            value={shift}
            placeholder='...'
            className={`${className}__material-select`}
            onChange={this.onChangeShift}
            displayedType={MaterialComponentType.Native}
          >
            {shiftOptions.map((sh, shiftIndex) => {
              return (
                <MaterialMenuItem
                  className={`${className}__material-menu-item`}
                  key={`shift_${shiftIndex}`}
                  value={sh}
                >
                  {sh.toString()}
                </MaterialMenuItem>
              );
            })}
          </MaterialSelect>
        </div>
        <div className={`${className}__column ${className}__column--resource`}>
          <Checkbox
            color='blue'
            size='large'
            checked={useAmdahl}
            onChange={this.onChangeUseAmdahl}
            indeterminate={this.props.mixedCheckbox}
          />
        </div>
        <div className={`${className}__column ${className}__column--productivity`}>
          <MaterialInput
            name={`${id}.productivity`}
            value={productivity ? productivity : ''}
            placeholder='...'
            className={`${className}__material-input`}
            displayedType={MaterialComponentType.Native}
            onChange={this.onChangeProductivity}
            valueType='float'
            min={0}
          />
        </div>
      </div>
    );
  }

  private stopPropagation(e: React.SyntheticEvent<{}>): void {
    e.stopPropagation();
  }
  @autobind
  private onChangeProductivity(_event: React.ChangeEvent, newVal: string): void {
    const { itemId } = this.props;
    this.props.setNewProductivity(itemId, newVal);
  }

  @autobind
  private onChangeShift(
    _e: React.SyntheticEvent<{}>,
    menuItemValue: any,
  ): void {
    const { itemId } = this.props;
    this.props.setNewShift(itemId, parseInt(menuItemValue, 10));
  }

  @autobind
  private onChangeUseAmdahl(checked: boolean): void {
    const { itemId } = this.props;
    this.props.setNewUseAmdahl(itemId, checked);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    setNewProductivity: (id: number, value: any): void => {
      if (!id) {
        dispatch(
          ScenariosActions.changeTotalResourceValue({
            fieldKey: 'crewProductivity',
            value,
          }),
        );
      } else {
        dispatch(
          ScenariosActions.changeResourceValue({
            id,
            fieldKey: 'crewProductivity',
            value,
          }),
        );
      }
    },
    setNewShift: (id: number, value: any): void => {
      if (!id) {
        dispatch(
          ScenariosActions.changeTotalResourceValue({
            fieldKey: 'calendarIds',
            value,
          }),
        );
      } else {
        dispatch(
          ScenariosActions.changeResourceValue({
            id,
            fieldKey: 'calendarIds',
            value,
          }),
        );
      }
    },
    setNewUseAmdahl: (id: number, value: any): void => {
      if (!id) {
        dispatch(
          ScenariosActions.changeTotalResourceValue({
            fieldKey: 'useAmdahl',
            value,
          }),
        );
      } else {
        dispatch(
          ScenariosActions.changeResourceValue({
            id,
            fieldKey: 'useAmdahl',
            value,
          }),
        );
      }
    },
  };
};

const connector = connect(null, mapDispatchToProps);
export const ResourceLimitationsFieldsArea = connector(ResourceLimitationsFieldsAreaComponent);
