import classNames from 'classnames';
import * as React from 'react';

import './classification-left-side-layout.scss';

import { ClassificationHeader } from '../classification-header';


interface Props {
  className?: string;
}

export const ClassificationLeftSideLayout: React.FC<Props> = (props) => {
  return (
    <div className={classNames('classification-left-side-layout', props.className)}>
      <ClassificationHeader />
      {props.children}
    </div>
  );
};
