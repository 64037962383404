import { Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';


import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { objectUtils } from 'common/utils/object-utils';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import {
  DrawingsGeometryGroup,
  DrawingsGeometryInstance,
  DrawingsGeometryUtils,
  DrawingsGroupMeasure,
} from '../..';
import { DrawingContextObserver } from '../../drawings-contexts';
import { DrawingsUserLegendHeaderConfig } from './header';
import { Styled } from './styled';

interface StateProps {
  isImperialUnit: boolean;
  measures: DrawingsGroupMeasure;
  instances: Record<string, DrawingsGeometryInstance>;
}

interface OwnProps {
  groupInfo: DrawingsGeometryGroup;
  zoomHandler: DrawingContextObserver<{ zoom: number }>;
  headerConfig: DrawingsUserLegendHeaderConfig;
  calculateMeasuresAndCache: (groupId: string, instanceIds: string[]) => void;
}

interface Props extends OwnProps, StateProps {

}

export class Component extends React.PureComponent<Props> {
  private deferredCalculateExecutor: DeferredExecutor = new DeferredExecutor(300);
  private mainRef: HTMLTableRowElement = null;
  private colorIndicatorRef: HTMLDivElement = null;
  private nameRef: HTMLTableCellElement = null;

  public render(): React.ReactNode {
    const { measures, isImperialUnit, groupInfo: { color, name }, headerConfig } = this.props;
    if (!measures || !measures.count) {
      return null;
    }
    const { area, perimeter, length, count } = measures;
    return (
      <Styled.ItemLine ref={this.saveMainRef}>
        <Styled.Name ref={this.saveNameRef}>
          <Styled.ColorIndicator
            color={color}
            ref={this.saveColorIndicatorRef}
          />
          <Text>
            { name }
          </Text>
        </Styled.Name>
        <RenderIf condition={headerConfig.area}>
          <Styled.ItemValue>
            <Text>
              { UnitUtil.measureToString2d(area, UnitTypes.M2, isImperialUnit, true) }
            </Text>
          </Styled.ItemValue>
        </RenderIf>
        <RenderIf condition={headerConfig.perimeter}>
          <Styled.ItemValue>
            <Text>
              { UnitUtil.lengthToString(perimeter, UnitTypes.M, isImperialUnit, true) }
            </Text>
          </Styled.ItemValue>
        </RenderIf>
        <RenderIf condition={headerConfig.length}>
          <Styled.ItemValue>
            <Text>
              { UnitUtil.lengthToString(length, UnitTypes.M, isImperialUnit, true) }
            </Text>
          </Styled.ItemValue>
        </RenderIf>
        <Styled.ItemValue>
          <Text>
            { count }
          </Text>
        </Styled.ItemValue>
      </Styled.ItemLine>
    );
  }

  public componentDidMount(): void {
    if (this.needRecalculateOnMount()) {
      this.recalculate();
    }
    this.props.zoomHandler.subscribe(this.onZoomUpdate);
  }

  public componentWillUnmount(): void {
    this.props.zoomHandler.unsubscribe(this.onZoomUpdate);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.needRecalculateOnUpdate(prevProps)) {
      this.recalculate();
    } else if (
      !objectUtils.areEqualByFields(this.props.headerConfig, prevProps.headerConfig, ['area', 'perimeter', 'length'])
    ) {
      this.onZoomUpdate(this.props.zoomHandler.getContext());
    }
  }

  @autobind
  private saveColorIndicatorRef(ref: HTMLDivElement): void {
    this.colorIndicatorRef = ref;
  }

  @autobind
  private saveNameRef(ref: HTMLTableCellElement): void {
    this.nameRef = ref;
  }

  @autobind
  private saveMainRef(ref: HTMLTableRowElement): void {
    this.mainRef = ref;
    this.onZoomUpdate(this.props.zoomHandler.getContext());
  }

  @autobind
  private recalculate(): void {
    this.deferredCalculateExecutor.execute(() => {
      this.props.calculateMeasuresAndCache(this.props.groupInfo.id, this.props.groupInfo.measurements);
    });
  }

  private needRecalculateOnUpdate(prevProps: Readonly<Props>): boolean {
    if (prevProps.groupInfo.measurements !== this.props.groupInfo.measurements) {
      return true;
    }
    return prevProps.instances !== this.props.instances && this.recalculateByChangedCount();
  }

  private needRecalculateOnMount(): boolean {
    if (!this.props.measures) {
      return true;
    }
    return this.recalculateByChangedCount();
  }

  private recalculateByChangedCount(): boolean {
    let count = this.props.measures?.count || 0;
    for (const measureId of this.props.groupInfo.measurements) {
      if (!(measureId in this.props.instances)) {
        return true;
      }
      const { type, geometry } = this.props.instances[measureId];
      if (DrawingsGeometryUtils.isCount(type, geometry)) {
        count = count - geometry.points.length + 1;
      }
    }
    return count !== this.props.groupInfo.measurements.length;
  }

  @autobind
  private onZoomUpdate({ zoom }: { zoom: number }): void {
    if (!this.mainRef) {
      return;
    }
    const colorIndicatorSize = zoom * Styled.COLOR_INDICATOR_SIZE;
    this.colorIndicatorRef.style.width = `${colorIndicatorSize}px`;
    this.colorIndicatorRef.style.height = `${colorIndicatorSize}px`;
    this.colorIndicatorRef.style.borderWidth = `${2 * zoom}px`;
    this.colorIndicatorRef.style.minWidth = `${colorIndicatorSize}px`;
    this.colorIndicatorRef.style.minHeight = `${colorIndicatorSize}px`;

    const fontSize = zoom * Styled.FONT_SIZE;
    const height = fontSize * Styled.LINE_HEIGHT_MULTIPLIER;
    const texts = this.mainRef.getElementsByTagName('p');
    for (let i = 0; i < texts.length; i++) {
      const text = texts.item(i);
      text.style.fontSize = `${fontSize}px`;
      text.style.lineHeight = `${height}px`;
    }

    this.nameRef.style.width = `${Styled.NAME_WIDTH * zoom}px`;

    const nameText = this.nameRef.getElementsByTagName('p').item(0);
    const nameMargin = Styled.ITEM_NAME_TEXT_MARGIN_LEFT * zoom;
    nameText.style.marginLeft = `${nameMargin}px`;
    nameText.style.maxWidth = `calc(100% - ${nameMargin + colorIndicatorSize}px)`;

  }
}


function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  return {
    isImperialUnit: state.account.settings.isImperial,
    measures: state.drawings.userAnnotations.groupsMeasuresCache[ownProps.groupInfo.id],
    instances: state.drawings.aiAnnotation.geometry,
  };
}

export const DrawingsUserAnnotationLegendItem = connect(mapStateToProps)(Component);
