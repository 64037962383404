import { H5 } from '@kreo/kreo-ui-components';
import { appearWithPosition } from '@kreo/kreo-ui-components/constants/animations';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  position: 'top' | 'bottom' | 'left' | 'right';
  targetTop: number;
  targetLeft: number;
  targetBottom: number;
  targetRight: number;
  targetWidth: number;
  targetHeight: number;
  tooltipWidth?: number;
  tooltipHeght?: number;
  width: number;
  height: number;
  speed?: 's' | 'm' | 'l' | 'xl';
  description?: string;
  hotKey?: string;
  picture?: string;
  size?: 'small' | 'big';
  isTranslucent?: boolean;
  children?: React.ReactNode;
  padding?: string;
  readyToShow: boolean;
}

function getTransform(props: Props): string {
  return props.position === 'top'
    ? 'translate(0, -20px)'
    : props.position === 'bottom'
      ? 'translate(0, 20px)'
      : props.position === 'left'
        ? 'translate(-20px, 0)'
        : 'translate(20px, 0)';
}

function getTop(props: Props): string {
  return props.position === 'bottom'
    ? `${props.targetBottom}px`
    : props.targetTop - (props.position === 'top' ? props.height + 20 : props.height / 2) < 0
      ? `${2 + (props.position === 'top' ? 20 : 0)}px`
      : props.position === 'top'
        ? `${(props.targetTop - props.height)}px`
        : props.targetTop + (props.tooltipHeght + props.height) / 2 > window.innerHeight
          ? `${window.innerHeight - props.height - 2}px`
          : `${props.targetTop + props.targetHeight / 2 - props.height / 2}px`;
}


function left(props: Props): string {
  return props.position === 'right'
    ? `${props.targetRight}px`
    : props.targetLeft - (props.position === 'left' ? props.width + 20 : props.width / 2) < 0
      ? `${2 + (props.position === 'left' ? 20 : 0)}px`
      : props.position === 'left'
        ? `${props.targetLeft - props.width}px`
        : props.targetLeft + (props.tooltipWidth + props.width) / 2 > window.innerWidth
          ? `${window.innerWidth - props.width - 2}px`
          : `${props.targetLeft + props.targetWidth / 2 - props.width / 2}px`;
}

function padding(props: Props): string {
  if (props.padding) {
    return props.padding;
  } else if (!props.picture && !props.hotKey && props.size !== 'small') {
    return '8px 30px';
  }
}

const TooltipContainer = styled.div<Props>`
  position: fixed;
  text-align: center;
  max-width: ${(props) => props.tooltipWidth || 320}px;
  max-height: 450px;
  width: ${(props) => (props.picture ? 320 : props.tooltipWidth)}px;
  padding: ${padding};
  box-shadow: ${(props) => props.theme.shadow.xl};
  background: ${(props) => props.theme.color.backgroundRush};
  border-radius: 15px;
  z-index: 99999;
  pointer-events: none;

  &,
  * {
    box-sizing: border-box;
  }

  top: ${getTop};

  left: ${left};

  transform: ${getTransform};

  animation-delay: ${(p) => (p.speed ? p.theme.duration[p.speed] : '0s')};

  animation-name: ${(p) => appearWithPosition(p.position, 0, -20)};
  animation-duration: ${(p) => p.theme.duration.m};
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;

  img {
    border-radius: 15px 15px 0 0;
    height: 180px;
    width: 320px;
    display: flex;
  }
  opacity: ${(p) => (p.isTranslucent ? 0.5 : 1)} ${(p) => p.isTranslucent && '!important'};
`;

const HotKeyContainer = styled.div`
  height: 40px;
  width: 40px;
  border: 2px solid ${(p) => p.theme.color.turquoise};
  border-radius: 10px;
  display: flex;
  justify-content: center;
`;

const DescriptionContainer = styled.div`
  padding: 0 30px 30px 30px;
`;

const TextWithHotKey = styled.div<{ picture?: any }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 0 30px;
  & > ${H5} {
    padding-right: ${(p) => !p.picture && '20px'};
  }
`;

const Header = styled.div<{ description?: string }>`
  display: ${(p) => (p.description ? 'block' : 'flex')};
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: ${(p) => (p.description ? 10 : 20)}px;
  border-bottom: ${(p) => (p.description ? `10px solid ${p.theme.color.pale}` : 'none')};
`;

const Hotkey = styled.div<{ description?: string }>`
  box-sizing: border-box;
  display: flex;
  padding: ${(p) => (p.description ? '5px 0 0 0' : '0 0 0 10px')};

  p {
    min-width: 20px;
    padding: 4px 5px 2px 5px;
    text-align: center;
    line-height: 14px;
    border-radius: 5px;
    background: ${(p) => p.theme.color.turquoise};
  }
`;

const Description = styled.div`
  padding: 20px;
  padding-top: 10px;
`;

// необходимо чтоб было удобно стили навешивать не создавая дополнительного дива
const TooltipElementWrapper = styled.div`
`;

export const Styled = {
  TooltipContainer,
  HotKeyContainer,
  DescriptionContainer,
  TextWithHotKey,
  Header,
  Hotkey,
  Description,
  TooltipElementWrapper,
};
