import classNames from 'classnames';
import * as React from 'react';

import './bim-handle-tile.scss';

interface Props {
  title: string;
  id: number;
  revitId: string;
  selected: boolean;
  onSelect: (id: any) => void;
}

export class BimHandleTile extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
  }

  public onSelect(): any {
    this.props.onSelect(this.props.id);
  }

  public render(): any {
    return (
      <div
        className={classNames('checker-bim-handle-tile', { 'selected': this.props.selected })}
        onClick={this.onSelect}
        data-control-name='checker-bim-handle'
      >
        <div className='checker-bim-handle-tile__title'>
          {this.props.title}
        </div>
        <span className='checker-bim-handle-tile__id'>
          [{this.props.revitId}]
        </span>
      </div>
    );
  }
}
