import * as React from 'react';

import './engine-hotkeys-hint-popup.scss';

import { IconButton, KreoIconCloseSmall, KreoScrollbars } from 'common/UIKit';
import { EngineHotkeysHintBlock } from './engine-hotkeys-hint-block';
import { EngineHotkeysBlock } from './engine-hotkeys-list';

interface Props {
  specialAbilities: EngineHotkeysBlock;
  hotkeyList: EngineHotkeysBlock[];
  onClose: () => void;
}

export const EngineHotkeysHintPopup: React.FC<Props> = (props) => {
  return (
    <div className='engine-hotkeys-hint-popup'>
      <div className='engine-hotkeys-hint-popup__header'>
        <div className='engine-hotkeys-hint-popup__title'>Keyboard Shortcuts</div>
        <IconButton
          size='small'
          className='engine-hotkeys-hint-popup__button'
          controlName='engine-hotkeys-hint-popup-close-button'
          onClick={props.onClose}
        >
          <KreoIconCloseSmall />
        </IconButton>
      </div>
      <div className='engine-hotkeys-hint-popup__body-wrap'>
        <KreoScrollbars>
          <div className='engine-hotkeys-hint-popup__body'>
            {props.specialAbilities
              ? <EngineHotkeysHintBlock block={props.specialAbilities} />
              : null
            }
            {props.hotkeyList.map(block => {
              return <EngineHotkeysHintBlock key={block.blockName} block={block} />;
            })}
          </div>
        </KreoScrollbars>
      </div>
    </div>
  );
};
