import { ICellEditorParams } from 'ag-grid-community';
import { ValueHelper } from 'common/utils/value-helper';

import {
  QtoTreeTableCommon,
} from '../../../../units/projects/components/quantity-take-off-report-table/quantity-take-off-tree-table-common';
import { QtoColumnPropertyHelper } from '../../../../units/projects/utils/quantity-take-off-tree-table';
import { CellEditorType } from './cell-editor-type';

export abstract class CellEditor<TValue, TInputElement extends HTMLElement = HTMLElement> {
  public static wrapperClassName: string = 'tree-table-cell-edit__value-wrapper';
  protected static className: string = 'tree-table-cell-edit__value';
  protected eValue: TInputElement;
  protected params: ICellEditorParams;
  protected cellEditorType: CellEditorType;

  public constructor(params: ICellEditorParams, eGui: HTMLDivElement) {
    this.params = params;
    this.initEValue(eGui);
  }

  public static getInnerHtml(): string {
    return '';
  }

  public static getEditorHtmlWrapper(): HTMLDivElement {
    const wrapper = document.createElement('div');
    wrapper.className = 'tree-table-cell-edit__value-wrapper';

    return wrapper;
  }

  public abstract getNode(value: string): Node;

  public abstract initEvents(): void;

  public abstract destroyEvents(): void;

  public abstract getCurrentValue(): string;

  public abstract setFocus(): void;

  public revertValue(): void {
    // do nothing;
  }

  public getEditorType(): CellEditorType {
    return this.cellEditorType;
  }

  public getValue(): TValue {
    const properties = this.params.data.properties;
    const colId = this.params.column.getId();
    const hasDefaultValue = properties[colId] && properties[colId].default
      || ValueHelper.isNumberValue(properties[colId] && properties[colId].default);

    if (this.isEmpty() && !hasDefaultValue) {
      return this.setEmptyValue();
    }

    return this.getNewValue();
  }

  public setValue(): void {
    if (!this.eValue) {
      return;
    }

    this.currentInitEValue();
    setTimeout(() => this.eValue.focus(), 1);
    this.eValue.addEventListener('keydown', this.keyListener);
  }

  protected abstract initEValue(eGui: HTMLDivElement): void;
  protected abstract currentInitEValue(): void;
  protected abstract setEValue(value: string): void;
  protected abstract isEmpty(): boolean;
  protected abstract getNewValue(): TValue;

  protected getConvertedValue(value: number): number {
    const unit = QtoColumnPropertyHelper.getColumnUnit(this.params.column);
    const isImperial = this.params.context.isImperial;
    return QtoTreeTableCommon.metricValueConverter(value, unit, isImperial);
  }

  private keyListener(e: KeyboardEvent): void {
    if (e.key !== 'Enter' && e.key !== 'Escape') {
      e.stopPropagation();
    }
  }

  private setEmptyValue(): TValue {
    const properties = this.params.data.properties;
    const colId = this.params.column.getId();

    delete properties[colId];

    return undefined;
  }
}
