import autobind from 'autobind-decorator';

import { splitMap } from '2d/components/2d-element-view/utils/split-map';
import { DrawingsInstanceMeasure } from 'common/components/drawings';
import { ExcelFormulaHelper, UpdateCellData } from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { FormulaInfoController } from 'common/components/excel-table/utils/formula-info-controller';
import { SheetUpdateCells } from '../../actions/payloads';
import { DefaultSettings } from '../../constants/table-settings';
import { ReportPage, ReportPageType } from '../../interfaces';
import { TableSettings } from '../../interfaces/table-presets';
import { TwoDFullCellId } from '../2d-regex';
import { getConfigId } from '../excel-table-cell-formatter/common';
import { UpdatedCell } from '../get-ref-map';
import {
  CloseProgress,
  OnReportDataLoaded,
  OnReportSheetLoaded,
  OnSheetColumnsDataUpdated,
  OnSheetRowsDataUpdated,
  ReportCellDataController,
  ReportCellDataControllerProps,
  SetIsGetPageData,
  StartProgress,
  UpdateProgress,
} from './report-cell-data-controller';
import { CellDataStore, SheetDataStore } from './report-cell-data-store';
import {
  DynamicCellsStore,
  FunctionToRefresh,
  GetDrawingsToCells,
  ReportCellValueController,
  ReportCellValueControllerProps,
  ValueStore,
} from './report-cell-value-controller';
import { GetDrawingValue, IsImperial } from './report-cell-value-getter';

const CALCULATING_PROGRESS_TITLE = 'Data Calculation...';

const ALPHABET_LETTERS_COUNT = 26;

type GetCellsWithDrawings = () => string[];
type GetDynamicTable = (groupId: string, cell: TwoDFullCellId, settings: TableSettings) => Record<string, string>;
export interface ReportCellControllerProps {
  getDrawingsToCells: GetDrawingsToCells;
  functionToRefresh: FunctionToRefresh;
  getCellToCells: (id: string) => string[];
  getDrawingValue: GetDrawingValue;
  isImperial: IsImperial;
  onSheetRowsDataUpdated: OnSheetRowsDataUpdated;
  onSheetColumnsDataUpdated: OnSheetColumnsDataUpdated;
  onReportSheetLoaded: OnReportSheetLoaded;
  getCellsWithDrawings: GetCellsWithDrawings;
  getDynamicTable: GetDynamicTable;
  onReportDataLoaded: OnReportDataLoaded;
  setIsGetPageData: SetIsGetPageData;
  startProgress: StartProgress;
  updateProgress: UpdateProgress;
  closeProgress: CloseProgress;
}

export interface CellUpdatePayload {
  sheetId: string;
  valuePayload: Record<string, string | number>;
  dynamicPayload: DynamicCellsStore;
  dataPayload: CellDataStore;
}

export interface CellDataController {
  initReportCellStores(projectId: string): Promise<void>;
  loadData(sheetIds: ReportPage[], projectId: string, onFinish: (id: string) => void): Promise<void>;
  updateCells(payload: Array<SheetUpdateCells<UpdateCellData[]>>): void;
  updateCellsByMeasure(measures: DrawingsInstanceMeasure[]): void;
  updateCellsByRemoveInstancesIds(instancesIds: string[]): void;
  addNewRows(sheetId: string, count: number): void;
  addNewColumns(sheetId: string, count: number): void;
  setColumnWidthBulck(sheetId: string, payload: Array<{ columnId: string, width: number }>);
  selectSheetValue(sheetId: string, columnId: string, rowId: string): string | number;
  selectSheetData(reportId: string): SheetDataStore;
  getUpdatedCells(sheetId: string): UpdatedCell[];
  getReportData(): CellDataStore;
  onDrawingsLoaded(drawingsIds: string[], dynamicGroups: Record<string, string[]>): void;
  updateCellValueWithDrawings(drawingsIds: string[]): void;
  updateDynamicCells(dynamicGroups: Record<string, string[]>): void;
  updateCellDataOnFill(sheetId: string, columnId: string, rowId: string, data: string | number): void;
  clearStore(): void;
}

export class ReportCellController implements CellDataController {
  private reportCellValueController: ReportCellValueController;
  private reportCellDataController: ReportCellDataController;
  private onReportSheetLoaded: OnReportSheetLoaded = null;
  private isDrawingsPayloadLoaded: boolean = false;
  private functionToRefresh: FunctionToRefresh = null;
  private onRowDataUpdated: OnSheetRowsDataUpdated = null;
  private getDynamicTable: GetDynamicTable = null;
  private startProgress: StartProgress = null;
  private updateProgress: UpdateProgress = null;
  private closeProgress: CloseProgress = null;
  private isDropState: boolean = false;

  public constructor(props: ReportCellControllerProps) {
    this.setProps(props);
  }

  public setProps(props: ReportCellControllerProps): void {
    this.onReportSheetLoaded = props.onReportSheetLoaded;
    this.functionToRefresh = props.functionToRefresh;
    this.onRowDataUpdated = props.onSheetRowsDataUpdated;
    this.getDynamicTable = props.getDynamicTable;
    this.startProgress = props.startProgress;
    this.updateProgress = props.updateProgress;
    this.closeProgress = props.closeProgress;
    const reportCellValueControllerProps: ReportCellValueControllerProps = {
      cellValueGetterProps: {
        getDrawingValue: props.getDrawingValue,
        isImperial: props.isImperial,
        getReportPageData: this.selectSheetData,
        getDynamicCellValue: this.getDynamicCellValue,
        getCellValue: this.selectSheetValue,
      },
      getDrawingsToCells: props.getDrawingsToCells,
      getCellToCells: props.getCellToCells,
      functionToRefresh: props.functionToRefresh,
      getDynamicTable: this.getDynamicTableData,
    };
    this.reportCellValueController = new ReportCellValueController(reportCellValueControllerProps);

    const reportCellDataControllerProps: ReportCellDataControllerProps = {
      onSheetRowsDataUpdated: props.onSheetRowsDataUpdated,
      onSheetColumnsDataUpdated: props.onSheetColumnsDataUpdated,
      onReportDataLoaded: props.onReportDataLoaded,
      setIsGetPageData: props.setIsGetPageData,
      startProgress: props.startProgress,
      updateProgress: props.updateProgress,
      closeProgress: props.closeProgress,
    };
    this.reportCellDataController = new ReportCellDataController(reportCellDataControllerProps);
  }

  public clearStore(): void {
    this.reportCellDataController.clearStore();
  }

  public async initReportCellStores(projectId: string, resetDropState?: boolean): Promise<void> {
    if (resetDropState) {
      this.isDropState = false;
    }
    const pages = await this.reportCellDataController.initState(projectId, this.isActive);
    if (pages === null) {
      return;
    }
    const totalRowCount = this.getReportsInfo(pages) * ALPHABET_LETTERS_COUNT;
    let finishedCount = 0;
    this.startProgress(totalRowCount, CALCULATING_PROGRESS_TITLE);
    const fic = new FormulaInfoController();
    for (const page of pages) {
      if (this.isDropState) {
        return;
      }
      if (page.type === ReportPageType.MeasureView) {
        continue;
      }
      const pageId = page.id;
      const data = this.reportCellDataController.selectSheetDataStore(pageId);
      const updatedCells: UpdatedCell[] = [];
      const keys = data.rows.map((_, index) => index.toString());
      await splitMap(
        100,
        keys,
        (index) => {
          try {
            const row = data.rows[Number(index)];
            for (const [columnKey, value] of Object.entries(row)) {
              const rowId = ExcelTableRowIdentification.getRowIdFromIndex(index);
              const fullCellId = ExcelFormulaHelper.getCellLink(pageId, columnKey, rowId);
              this.reportCellValueController.setCellValueByData(fullCellId, value.toString(), fic);
              updatedCells.push({
                fullCellId,
                newValue: value.toString(),
                prevValue: '',
              });
            }
            finishedCount += ALPHABET_LETTERS_COUNT;
          } catch (e) {
            console.error(e);
          }
        },
        () => { this.updateProgress(finishedCount, CALCULATING_PROGRESS_TITLE); },
      );
      if (this.onRowDataUpdated) {
        this.onRowDataUpdated(pageId, {}, updatedCells);
      }
    }

    if (this.onReportSheetLoaded) {
      this.onReportSheetLoaded(pages);
    }
    this.closeProgress(CALCULATING_PROGRESS_TITLE);
  }

  public async loadData(sheetIds: ReportPage[], projectId: string, onFinish: (id: string) => void): Promise<void> {
    this.reportCellDataController.loadPagesData(sheetIds, projectId, onFinish);
  }

  public updateCells(payload: Array<SheetUpdateCells<UpdateCellData[]>>, skipRefresh?: boolean): void {
    payload.forEach(sheetForm => {
      const [add, update, updatedCell] =
        this.reportCellDataController.bulkUpdateSheetData(sheetForm.sheetId, sheetForm.form);
      this.reportCellValueController.bulkCellValueByData(sheetForm.sheetId, sheetForm.form);
      if (!skipRefresh) {
        this.onRowDataUpdated(sheetForm.sheetId, { add, update }, updatedCell);
      }
    });
  }

  public updateCellsByMeasure(measures: DrawingsInstanceMeasure[]): void {
    this.reportCellValueController.updateCellValueByMeasure(measures.map(measure => measure.id));
  }

  public updateCellsByRemoveInstancesIds(instancesIds: string[]): void {
    this.reportCellValueController.updateCellValueByMeasure(instancesIds);
  }

  public addNewRows(sheetId: string, count: number): void {
    this.reportCellDataController.addRows(sheetId, count);
  }

  public setColumnWidthBulck(sheetId: string, payload: Array<{ columnId: string, width: number }>): void {
    this.reportCellDataController.setColumnWidthBulc(sheetId, payload);
  }

  public addNewColumns(sheetId: string, count: number): void {
    this.reportCellDataController.addColumns(sheetId, count);
  }

  @autobind
  public selectSheetValue(sheetId: string, columnId: string, rowId: string): string | number {
    return this.reportCellValueController.selectCellValue(sheetId, columnId, rowId);
  }

  public selectCellData(sheetId: string, columnId: string, rowId: string): string {
    return this.reportCellValueController.selectCellData(sheetId, columnId, rowId);
  }

  @autobind
  public getDynamicCellValue(id: string): string {
    return this.reportCellValueController.getDynamicCellValue(id);
  }

  @autobind
  public selectSheetData(reportId: string): SheetDataStore {
    return this.reportCellDataController.selectSheetDataStore(reportId);
  }

  public getUpdatedCells(sheetId: string): UpdatedCell[] {
    return this.reportCellDataController.getUpdatedCells(sheetId);
  }

  public getReportData(): CellDataStore {
    return this.reportCellDataController.getReportData();
  }

  public onDrawingsLoaded(drawingsIds: string[], dynamicGroups: Record<string, string[]>): void {
    this.isDrawingsPayloadLoaded = true;
    this.updateCellValueWithDrawings(drawingsIds);
    this.updateDynamicCells(dynamicGroups);
  }

  public updateCellValueWithDrawings(drawingsIds: string[]): void {
    if (this.reportCellDataController.isDataLoaded() && this.isDrawingsPayloadLoaded) {
      this.reportCellValueController.updateCellValueByMeasure(drawingsIds);
    }
  }

  public updateDynamicCells(dynamicGroups: Record<string, string[]>, skipLoadingCheck?: boolean): void {
    if (skipLoadingCheck || this.reportCellDataController.isDataLoaded() && this.isDrawingsPayloadLoaded) {
      this.reportCellValueController.updateDynamicCells(dynamicGroups);
    }
  }

  public updateCellStore(payload: CellUpdatePayload): void {
    this.reportCellValueController.setStore(payload.valuePayload, payload.dynamicPayload);
    this.reportCellDataController.setStore(payload.dataPayload);
    this.functionToRefresh();
  }

  public updateColumns(payload: CellUpdatePayload): void {
    this.reportCellDataController.setSheetColumnsData(payload.sheetId, payload.dataPayload);
  }

  public getValueStore(): ValueStore {
    return this.reportCellValueController.getValueStore();
  }

  public getDynamicStore(): DynamicCellsStore {
    return this.reportCellValueController.getDynamicStore();
  }

  public updateCellDataOnFill(sheetId: string, columnId: string, rowId: string, data: string | number): void {
    this.reportCellDataController.setCellData(sheetId, columnId, rowId, data);
  }

  public refreshData(sheetId: string): void {
    const updateCells = this.getUpdatedCells(sheetId);
    const sheetData = this.selectSheetData(sheetId);
    this.onRowDataUpdated(sheetId, { update: sheetData.rows }, updateCells);
  }

  public isValidCell(cellId: string): boolean {
    return this.reportCellValueController.isValidCell(cellId);
  }

  @autobind
  public getDynamicTableData(groupId: string, cell: TwoDFullCellId): Record<string, string> {
    const sheetData = this.reportCellDataController.selectSheetDataStore(cell.sheetId);
    if (!sheetData && !sheetData.rows) {
      return {};
    }
    const colId = getConfigId(cell.columnId);
    const row = sheetData.rows[ExcelTableRowIdentification.getRowIndexFromId(cell.rowId)];
    if (!row) {
      return {};
    }
    const cellSettings = row[colId] as unknown as TableSettings;
    const settings = cellSettings && cellSettings.columnKeys ? cellSettings : DefaultSettings;
    return this.getDynamicTable(groupId, cell, settings);
  }

  public deleteCellDataStore(sheetId: string): void {
    this.reportCellDataController.deleteSheet(sheetId);
    this.reportCellValueController.deleteStore(sheetId);
  }

  public dropState(): void {
    this.isDropState = true;
    this.onReportSheetLoaded = null;
    this.isDrawingsPayloadLoaded = false;
    this.functionToRefresh = null;
    this.onRowDataUpdated = null;
    this.getDynamicTable = null;
  }

  @autobind
  private isActive(): boolean {
    return !this.isDropState;
  }

  @autobind
  private getReportsInfo(pages: ReportPage[]): number {
    let totalElementCount = 0;
    for (const page of pages) {
      if (page.type === ReportPageType.SpreadSheet) {
        const data = this.reportCellDataController.selectSheetDataStore(page.id);
        totalElementCount += data.rows.length;
      }
    }
    return totalElementCount;
  }
}
