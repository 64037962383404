import * as React from 'react';

export const KreoOntologyTreeIcon: React.FC = () => {
  return (
    <svg
      width='14px'
      height='13px'
      viewBox='0 0 14 13'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Project_ElTypeSelected' transform='translate(-337.000000, -618.000000)'>
          <g id='Side-Nav' transform='translate(0.000000, 60.000000)'>
            <g id='TreeNav' transform='translate(0.000000, 90.000000)'>
              <g id='L3' transform='translate(0.000000, 400.000000)'>
                <g id='Btn_icon' transform='translate(328.000000, 59.000000)'>
                  <g id='Icons/24x24/EditClassification' transform='translate(4.000000, 4.000000)'>
                    <rect id='Rectangle' x='0' y='0' width='24' height='24' />
                    <g id='Group-4' transform='translate(5.000000, 5.000000)'>
                      <path
                        // eslint-disable-next-line max-len
                        d='M6,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,6 C14,6.55228475 13.5522847,7 13,7 L6,7 C5.44771525,7 5,6.55228475 5,6 L5,5 C5,4.44771525 5.44771525,4 6,4 Z'
                        id='Rectangle-Copy-4'
                        fill='#FFFFFF'
                      />
                      <path
                        // eslint-disable-next-line max-len
                        d='M6,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,12 C14,12.5522847 13.5522847,13 13,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,11 C5,10.4477153 5.44771525,10 6,10 Z'
                        id='Rectangle-Copy-6'
                        fill='#FFFFFF'
                      />
                      <path
                        // eslint-disable-next-line max-len
                        d='M0.5,0 L0.5,3.5 C0.5,4.6045695 1.3954305,5.5 2.5,5.5 L4,5.5 L4,5.5'
                        id='Path'
                        stroke='#FFFFFF'
                      />
                      <path
                        // eslint-disable-next-line max-len
                        d='M0.5,0 L0.5,9.5 C0.5,10.6045695 1.3954305,11.5 2.5,11.5 L4,11.5 L4,11.5'
                        id='Path'
                        stroke='#FFFFFF'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
