import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  ActivityAssignmentActivityLayoutEdit,
} from 'unit-cost-estimate/components/activity-assignment-activity-layout-edit';
import {
  CostEstimateProjectRevisionRouteParams as RevisionRP,
} from '../../../routes/app-routes-params';
import { ActivityAssignmentEngineLayout } from '../components/activity-assignment-engine-layout';


export class ActivityAssignmentEditPage extends React.Component<RouteComponentProps<RevisionRP>> {
  public render(): React.ReactNode {
    const { projectId, revisionId } = this.props.match.params;

    return (
      <ActivityAssignmentEngineLayout
        projectId={projectId}
        revisionId={revisionId}
      >
        <ActivityAssignmentActivityLayoutEdit />
      </ActivityAssignmentEngineLayout>
    );
  }
}
