import * as t from 'io-ts';

export const QtoRecordsC = t.array(
  t.strict(
    {
      id: t.union([t.number, t.string]),
      props: t.record(t.string, t.array(t.union([t.string, t.number]))),
    }),
  'QtoRecords',
);

export type QtoRecords = t.TypeOf<typeof QtoRecordsC>;
