import { AppRoute } from 'common/routing/app-route';

const index = new AppRoute('/admin');
const people = new AppRoute(`${index.path}/people`);
const companies = new AppRoute(`${index.path}/companies`);
const settings = new AppRoute(`${index.path}/settings`);
const demoProjects = new AppRoute(`${index.path}/demo-projects`);

export const Urls = {
  index,
  companies,
  people,
  settings,
  demoProjects,
};
