import autobind from 'autobind-decorator';
import * as React from 'react';

interface Props {
  handleSubmit: (data: any) => void;
  className?: string;
  controlName?: string;
}

class Form extends React.Component<Props> {

  public render(): JSX.Element {
    const { children, className, controlName } = this.props;
    return (
      <form
        className={className}
        onSubmit={this.handleSubmit}
        autoComplete='off'
        role='presentation'
        onKeyDown={this.onKeyDown}
        data-control-name={controlName}
      >
        <div className='child-context'>{children}</div>
      </form>
    );
  }

  @autobind
  private handleSubmit(e: any): void {
    e.preventDefault();
    this.props.handleSubmit(e);
  }

  @autobind
  private onKeyDown(e: React.KeyboardEvent<HTMLFormElement>): void {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.props.handleSubmit(e);
    }
  }
}

export default Form;
