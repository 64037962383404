import autobind from 'autobind-decorator';
import { isEqual, pick } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { TwoDActions } from '2d/actions/creators';
import { DrawingsUndoRedoHelper } from 'common/components/drawings/utils/drawings-undo-redo-helper';
import { QuickSearchInput } from 'common/components/quick-search';
import { RenderIf } from 'common/components/render-if';
import { ControlNames } from 'common/constants/control-names';
import { ConstantFunctions } from 'common/constants/functions';
import { RequestStatus } from 'common/enums/request-status';
import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { KreoScrollbarsApi } from 'common/UIKit/scrollbars/kreo-scrollbars';
import { arrayUtils } from 'common/utils/array-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { objectUtils } from 'common/utils/object-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { PERSISTED_STORAGE_INITIAL_STATE } from 'persisted-storage/constants/initial-state';
import { MetricNames } from 'utils/posthog';
import {
  DrawingsActions,
  DrawingsGeometryGroup,
  DrawingsGeometryGroupWithNesting,
  DrawingsGeometryInstance,
  DrawingsGeometryInstanceWithId,
  DrawingsGeometryInstanceWithIdAndGroupId,
  DrawingsInstanceMeasure,
} from '..';
import { AssignPiaPatch, AssignedPia } from '../../../../units/2d';
import { Person } from '../../../../units/people/interfaces/person';
import { DrawingsAnnotationLegendActions } from '../actions/creators/drawings-annotation-legend';
import { InstancesVisibilityActions } from '../actions/creators/instances-visibility';
import {
  UpdateDrawingGroup,
} from '../actions/payloads/drawings-annotation-legend';
import { DrawingDialogs } from '../constants/drawing-dialogs';
import { NoScaleDialogState } from '../dialogs';
import { DrawingsDrawMode, DrawingsInstanceType } from '../enums';
import { DrawingContextMenuPosition, PivotedInstance } from '../interfaces';
import { AnnotationLegendItem, AnnotationLegendTree } from '../interfaces/annotation-legend-tree';
import {
  AnnotationFilters,
  AssignFilterValue,
  FilterChangePayload,
  FilterData,
  PagesFilterValue,
} from '../interfaces/drawing-filters';
import { SortData, SortDataInitialState, SortOrder } from '../interfaces/drawing-measurement-sort';
import { DrawingsFiles } from '../interfaces/drawings-file-info';
import { DrawingAnnotationLegendUtils } from '../utils';
import { AnnotationLegendItemTypeguards } from '../utils/annotation-legend-item-typeguards';
import {
  DrawingAnnotationFiltrationUtils,
} from '../utils/drawing-annotation-filtration-utils';
import { DrawingsDragAndDropData } from '../utils/drawings-annotation-drag-utils';
import { DrawingsGroupUtils } from '../utils/drawings-group-utils';
import { isPivotedInstance } from '../utils/instances-utils/pivoted-typeguards';
import { Body } from './body';
import { connectToLegendContexts, LegendContextWrapperProps } from './connect-to-legend-contexts';
import { GroupMeasureContextProvider } from './contexts/group-measure-context';
import { DrawingsAnnotationLegendGroup } from './drawings-annotation-legend-group';
import {
  DrawingsAnnotationLegendGroupLine,
} from './drawings-annotation-legend-group/drawings-annotation-legend-group-line';
import { DrawingsAnnotationLegendItem } from './drawings-annotation-legend-item';
import {
  DrawingsAnnotationLegendPivotedItem,
} from './drawings-annotation-legend-item/drawings-annotation-pivoted-item';
import { getItemHeight } from './get-item-height';
import { GroupsMenu, GroupsMenuCommonProps, GroupsMenuType } from './groups-menu';
import { Header } from './header';
import { InstanceMenu, InstanceMenuCommonProps } from './instance-menu/instance-menu';
import { LegendItemAPI } from './interfaces';
import { Styled } from './styled';
import { getGroupsSelectionTree, getVisibleItems } from './utils';
import { DrawingsAssign } from './utils/assing-utils';

export const VIRTUAL_LIST_MARGIN = 4;
export const DRAWINGS_ANNOTATION_LEGEND_HEIGHT = 24;
const VIRTUAL_LIST_ITEM_HEIGHT = VIRTUAL_LIST_MARGIN + DRAWINGS_ANNOTATION_LEGEND_HEIGHT;

interface DispatchProps {
  openUnsavedChangesDialog: (accept: () => void, cancel?: () => void) => void;
  toggleOpen: (groupId: string[]) => void;
  toggleAllOpen: (isOpen: boolean) => void;
  addPageTabs: (drawingIds: string[]) => void;
  setCurrentDrawing: (drawingId: string) => void;
  saveCurrentDrawingState: (drawingId: string) => void;
  changeFilterData: (changeFilterData: FilterChangePayload) => void;
  changeGroupPinStatus: (groupId: string) => void;
  setContextMenuPosition: (point: DrawingContextMenuPosition) => void;
  updateInstancesVisibility: (instancesToShow: string[], instancesToHide: string[]) => void;
  setSelected: (instanceIds: string[], groupIds: string[]) => void;
  saveFilteredValue: (ids: string[]) => void;
  setFiltersPages: (filterPages: PagesFilterValue) => void;
  setFilteredResultItems: (items: AnnotationLegendItem[]) => void;
  sendAssignPia: (assignPia: AssignPiaPatch[], projectId: string | number) => void;
  toggleQuickSearch: () => void;
  openNotScaledDialog: (dialogData: NoScaleDialogState) => void;
}

interface StateProps {
  projectId: number;
  isImperial: boolean;
  geometry: Record<string, DrawingsGeometryInstance>;
  selectedInstances: string[];
  hiddenInstances: string[];
  groups: DrawingsGeometryGroup[];
  groupsIsOpenMap: Record<string, boolean>;
  files: DrawingsFiles;
  loadStatus: RequestStatus;
  elementMeasurement: Record<string, DrawingsInstanceMeasure>;
  pageTabs: string[];
  currentDrawingId: string;
  selectGroups: string[];
  filteredElementIds: string[];
  users: Person[];
  selectedMeasurementSort: SortData;
  assignPia: Record<string, AssignedPia>;
  pinnedGroupIds: string[];
  showOnlyGroups: boolean;
  filterData: FilterData;
  isShowTwoDReport: boolean;
  viewMeasureId: Record<string, boolean>;
  dynamicGroupsToCell: Record<string, string[]>;
  drawingInstanceInCellRange: Record<string, boolean>;
  selectedMeasureIdFromView: Record<string, boolean>;
  instanceToCells: Record<string, string[]>;
  selectedSheetId: string;
  filteredNodeIds: Record<string, boolean>;
  selectedFilterPages: PagesFilterValue;
  openGroups: Record<string, boolean>;
  hideUnselectedPageGroups: boolean;
  filterResultItems: AnnotationLegendItem[];
  assignedPia: Record<string, AssignedPia>;
  isMeasurementsSearchVisible: boolean;
  pivotMeasurements: boolean;
}

export interface DrawingAnnotationLegendApi {
  scrollToGroup: (groupId: string) => void;
  changeGroupColor: (color: string, groupId: string) => void;
}

interface OwnProps {
  isOpen: boolean;
  focus: (instanceIds: string[], ignoreSetting?: boolean) => void;
  getInstancesMeasures: (instancesId: string[]) => DrawingsInstanceMeasure[];
  canEditMeasurement: boolean;
  selectDrawingGroups: (instancesIds: string[]) => void;
  syncGroupSelection: (id: string) => void;
  sendApi: (api: DrawingAnnotationLegendApi) => void;
  panelMinWidth: number;
  isDrawingMode: boolean;
}

interface Props extends
  DispatchProps,
  StateProps,
  LegendContextWrapperProps,
  OwnProps {
}

interface ComponentState {
  visibleItems: AnnotationLegendItem[];
  onlyGroupsItems: AnnotationLegendItem[];
  measureInstanceRecords: Record<string, DrawingsInstanceMeasure>;
  hiddenInstanceIdsMap: Record<string, boolean>;
  selectedInstanceIdsMap: Record<string, boolean>;
  highlightGroupMap: Record<string, boolean>;
  lastClickedMeasureId: string;
  lastClickedMeasureGroupId: string;
  groupsMap: Record<string, DrawingsGeometryGroup>;
  groupMenu: GroupsMenuCommonProps;
  instancesMenu: InstanceMenuCommonProps & { isOpen: boolean };
  isOpenFilterPages: boolean;
}

class DrawingsAnnotationLegendComponent extends React.PureComponent<Props, ComponentState> {
  private listApi: KreoScrollbarsApi;

  private itemsApi: Map<string, LegendItemAPI> = new Map();

  private scrollTarget: string = null;
  private isSetScrollTop: boolean = true;

  private haveToBlockFocus: boolean = false;

  private calculateFilteresDeferredExecutor: DeferredExecutor = new DeferredExecutor(100);

  constructor(props: Props) {
    super(props);
    this.state = {
      measureInstanceRecords: {},
      onlyGroupsItems: null,
      visibleItems: getVisibleItems(
        this.props.filterResultItems,
        this.props.openGroups,
        this.props.showOnlyGroups, false,
      )[0],
      hiddenInstanceIdsMap: null,
      selectedInstanceIdsMap: null,
      lastClickedMeasureId: null,
      lastClickedMeasureGroupId: null,
      groupMenu: {
        isOpen: false,
        positionX: 0,
        positionY: 0,
        selectedGroup: null,
        menuType: null,
      },
      instancesMenu: {
        positionX: 0,
        positionY: 0,
        isOpen: false,
        selectedItem: null,
      },
      groupsMap: arrayUtils.toDictionaryByKey(props.groups, g => g.id),
      highlightGroupMap: {},
      isOpenFilterPages: false,
    };
  }

  public componentDidMount(): void {
    const { changeFilterData, selectedFilterPages } = this.props;
    if (this.props.projectId) {
      const hiddenInstanceIdsMap = {};
      const selectedInstanceIdsMap = {};
      this.props.selectedInstances.forEach(x => selectedInstanceIdsMap[x] = true);
      this.props.hiddenInstances.forEach(x => hiddenInstanceIdsMap[x] = true);
      this.setState({ selectedInstanceIdsMap, hiddenInstanceIdsMap });
    }
    this.props.sendApi({
      scrollToGroup: this.scrollToGroupById,
      changeGroupColor: this.setGroupColor,
    });

    this.filterGeometry();
    changeFilterData({ filterType: AnnotationFilters.Pages, element: selectedFilterPages });
  }

  public componentDidUpdate(prevProps: Props): void {
    const areSelectedInstancesChanged = this.props.selectedInstances !== prevProps.selectedInstances;
    const areGroupsChanged = this.props.groups !== prevProps.groups;
    const showOnlyGroupsChanged = this.props.showOnlyGroups !== prevProps.showOnlyGroups;

    const filteredItemsChanged = this.props.filterResultItems !== prevProps.filterResultItems;
    const pivotingChanged = !this.props.showOnlyGroups && this.props.pivotMeasurements !== prevProps.pivotMeasurements;

    if (this.needRecalculateFilteredItems(prevProps)) {
      this.calculateFilteresDeferredExecutor.execute(() => {
        this.filterGeometry();
      });
    } else if (filteredItemsChanged || showOnlyGroupsChanged || pivotingChanged) {
      const [visibleItems] = getVisibleItems(
        this.props.filterResultItems,
        this.props.openGroups,
        this.props.showOnlyGroups,
        this.props.pivotMeasurements,
      );
      this.setState({ visibleItems });
    } else if (this.props.openGroups !== prevProps.openGroups) {
      const visibleItems = DrawingAnnotationLegendUtils.getNewVisibleItems(
        this.state.visibleItems,
        this.props.openGroups,
        prevProps.openGroups,
      );
      this.setState({ visibleItems });
    }

    if (prevProps.elementMeasurement !== this.props.elementMeasurement) {
      const instanceMeasures = this.getMeasures(this.props.filterResultItems);
      this.setState({ measureInstanceRecords: instanceMeasures });
    }
    if (areGroupsChanged) {
      this.setState({ groupsMap: arrayUtils.toDictionaryByKey(this.props.groups, g => g.id) });
    }

    if (prevProps.hiddenInstances !== this.props.hiddenInstances) {
      const hiddenInstanceIdsMap = {};
      this.props.hiddenInstances.forEach(x => hiddenInstanceIdsMap[x] = true);
      this.setState({ hiddenInstanceIdsMap });
    }

    if (areSelectedInstancesChanged) {
      const selectedInstanceIdsMap = {};
      this.props.selectedInstances.forEach(x => selectedInstanceIdsMap[x] = true);
      this.setState({ selectedInstanceIdsMap });
      if (this.haveToBlockFocus) {
        this.haveToBlockFocus = false;
      } else {
        this.focusItemOnSelectionChange();
      }
      this.isSetScrollTop = true;
    }

    if (
      areGroupsChanged
      || areSelectedInstancesChanged
      || this.shouldUpdateHighlightGroups(prevProps)
    ) {
      this.setState({
        highlightGroupMap: getGroupsSelectionTree(
          this.props.selectedInstances,
          this.props.selectGroups,
          this.props.groups,
          this.props.geometry,
        ),
      });
    }
  }

  public render(): React.ReactNode {
    const groupsKeys = Object.keys(this.state.groupsMap);
    const isOpenGroups = groupsKeys.some(id => this.props.groupsIsOpenMap[id]);

    return (
      <Styled.Container
        isOpen={this.props.isOpen}
        onContextMenu={this.preventDefaultContextMenu}
        data-control-name={ControlNames.measurementManager}
      >
        <GroupMeasureContextProvider
          instancesMeasures={this.state.measureInstanceRecords}
          getInstancesMeasures={this.props.getInstancesMeasures}
        >
          <RenderIf condition={this.props.loadStatus === RequestStatus.Loaded}>
            <Styled.Inner>
              <Header
                hasSelectedGroups={!!this.props.selectGroups.length}
                duplicateGroups={this.duplicateGroups}
                canEditMeasurement={this.props.canEditMeasurement}
                toggleQuickSearch={this.props.toggleQuickSearch}
                setQuickSearchValue={this.setQuickSearchValue}
                deleteEmptyGroups={this.deleteEmptyGroups}
                invertSelection={this.invertSelection}
                collapseAllGroups={this.collapseAllGroups}
                expandAllGroups={this.expandAllGroups}
                selectedMeasurementSort={this.props.selectedMeasurementSort}
                createEmptyGroup={this.createEmptyGroup}
                geometryCount={this.props.filteredElementIds?.length || 0}
                isOpenGroups={isOpenGroups}
                hasSelectedGroupsAndElements={!!this.props.selectedInstances.length || !!this.props.selectGroups.length}
                isFiltersChanged={this.isFiltersChanged}
                filterGeometry={this.filterGeometry}
                getFilterPagesPosition={this.getFilterPagesPosition}
                isOpenFilterPages={this.state.isOpenFilterPages}
              />
              <Body
                sendUpdateApi={this.saveItemUpdateApi}
                onUpdateBodyWidth={this.onUpdateBodyWidth}
                canEditMeasurements={this.props.canEditMeasurement}
                cancelSelectedInstancesAndGroup={this.cancelSelectedInstancesAndGroup}
                changeGroupPinStatus={this.changeGroupPinStatus}
                users={this.props.users}
                selectedGroups={this.props.selectGroups}
                groupsIsOpenMap={this.props.groupsIsOpenMap}
                onGroupNameChanged={this.onGroupNameChange}
                filterResultItems={this.state.visibleItems}
                itemHeight={this.getVirtualListItemHeight()}
                renderItemFunction={this.getRenderItemFunction()}
                saveListApi={this.saveListApi}
                onHiddenChanged={this.hiddenChangeUndoRedo}
                onGroupClick={this.onGroupClick}
                onGroupOpenChange={this.onGroupOpenChange}
                onGroupContextMenu={this.onGroupContextMenu}
                onGroupDoubleClick={this.doubleClickUndoRedo}
                moveToGroup={this.moveToGroupWrapper}
                assignPia={this.props.assignPia}
                groupsMap={this.state.groupsMap}
                openGroupMenu={this.openGroupMenu}
                getItemsToMove={this.getItemsToMove}
                pinnedGroupIds={this.props.pinnedGroupIds}
                isOpenFilterPages={this.state.isOpenFilterPages}
              />
              <Styled.Footer>
                <QuickSearchInput
                  placeholder={'Search in measurements'}
                  isQuickSearchVisible={this.props.isMeasurementsSearchVisible}
                  setQuickSearchValue={this.setQuickSearchValue}
                  toggleQuickSearch={this.props.toggleQuickSearch}
                />
              </Styled.Footer>
            </Styled.Inner>
          </RenderIf>
          {this.state.groupMenu.isOpen && (
            <GroupsMenu
              {...this.state.groupMenu}
              onClose={this.closeGroupMenu}
              setColor={this.onColorChange}
              createGroup={this.createChildGroup}
              drawGeometry={this.drawGeometryToFolder}
            />)
          }
          {
            this.state.instancesMenu.isOpen && (
              <InstanceMenu
                {...this.state.instancesMenu}
                onClose={this.closeInstanceMenu}
                selectOnlyGroup={this.selectOnlyGroupById}
              />
            )
          }
        </GroupMeasureContextProvider>
      </Styled.Container>
    );
  }


  private shouldUpdateHighlightGroups(prevProps: Props): boolean {
    return this.props.groups !== prevProps.groups
      || this.props.selectGroups !== prevProps.selectGroups
      || this.props.geometry !== prevProps.geometry;
  }

  private focusItemOnSelectionChange(): void {
    if (!this.listApi) {
      return;
    }
    const topScroll = this.listApi.getScrollTop();
    const bottomScroll = this.listApi.getScrollHeight() + topScroll;
    const targetPosition = this.getSelectedInstancePosition();
    if ((targetPosition > bottomScroll || targetPosition < topScroll) && this.isSetScrollTop) {
      this.listApi.setScrollTop(targetPosition);
    }
  }

  private isFiltersChanged(prevData: FilterData, currentData: FilterData): boolean {
    const propsToCompare = [
      AnnotationFilters.Shapes,
      AnnotationFilters.Report,
      AnnotationFilters.Color,
      AnnotationFilters.Assign,
      AnnotationFilters.Users,
      AnnotationFilters.Origin,
    ];
    return !isEqual(pick(prevData, propsToCompare), pick(currentData, propsToCompare));
  }


  private needRecalculateFilteredItems(prevProps: Props): boolean {
    return !objectUtils.areEqualByFields(
      this.props,
      prevProps,
      [
        'filterData',
        'geometry',
        'groups',
        'hideUnselectedPageGroups',
        'assignPia',
        'currentDrawingId',
        'pageTabs',
        'isShowTwoDReport',
        'viewMeasureId',
        'dynamicGroupsToCell',
        'drawingInstanceInCellRange',
        'selectedMeasureIdFromView',
        'instanceToCells',
        'selectedSheetId',
        'filteredNodeIds',
        'selectedMeasurementSort',
      ],
    );
  }

  @autobind
  private filterGeometry(): void {
    const {
      filterData,
      geometry,
      openGroups,
      hideUnselectedPageGroups,
      groups,
      saveFilteredValue,
    } = this.props;
    const result = DrawingAnnotationFiltrationUtils
      .getFilterResult(
        groups,
        openGroups,
        geometry,
        this.filterInstance,
        this.filterInstanceAndGroup,
        this.sortInstances,
        hideUnselectedPageGroups,
        filterData,
      );
    saveFilteredValue(DrawingAnnotationFiltrationUtils.getDrawingsInstanceIds(result.items));
    this.onFilterChange(result);

    const [ visibleItems ] = getVisibleItems(
      result.items,
      this.props.openGroups,
      this.props.showOnlyGroups,
      this.props.pivotMeasurements,
    );
    this.setState({ visibleItems });
  }

  @autobind
  private sortInstances(
    instances: DrawingsGeometryInstanceWithIdAndGroupId[],
  ): DrawingsGeometryInstanceWithIdAndGroupId[] {
    const { field, order } = this.props.selectedMeasurementSort;
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    const comparator = order === SortOrder.Descending
      ? (a, b) => collator.compare(b[field], a[field])
      : (a, b) => collator.compare(a[field], b[field]);
    return [...instances].sort(comparator);
  }

  @autobind
  private filterInstanceAndGroup(
    instance: DrawingsGeometryInstance | DrawingsGeometryGroup,
  ): boolean {
    const { filterData } = this.props;
    return DrawingAnnotationFiltrationUtils.isValidName(instance.name, filterData[AnnotationFilters.Name]);
  }

  @autobind
  private filterInstance(
    instance: DrawingsGeometryInstance,
    allowedInstancesTypes: Set<DrawingsInstanceType>,
  ): boolean {
    const { filterData } = this.props;
    return !!instance
      && this.isValidDrawing(instance.drawingId)
      && allowedInstancesTypes.has(instance.type)
      && this.isValidReportUsing(instance.id)
      && DrawingAnnotationFiltrationUtils.isValidByOrigin(instance, filterData[AnnotationFilters.Origin])
      && DrawingAnnotationFiltrationUtils.isColorValid(instance, filterData[AnnotationFilters.Color])
      && this.isAssignValid(instance)
      && DrawingAnnotationFiltrationUtils.isUserValid(instance, filterData[AnnotationFilters.Users]);
  }

  @autobind
  private isValidDrawing(drawingId: string): boolean {
    const { filterData, currentDrawingId, pageTabs } = this.props;
    switch (filterData[AnnotationFilters.Pages]) {
      case PagesFilterValue.Current:
        return drawingId === currentDrawingId;
      case PagesFilterValue.All:
        return true;
      case PagesFilterValue.Open:
        return pageTabs.includes(drawingId);
      default:
        return false;
    }
  }

  @autobind
  private isValidReportUsing(instanceId: string): boolean {
    const {
      filterData,
      geometry,
      isShowTwoDReport,
      viewMeasureId,
      dynamicGroupsToCell,
      groups,
      drawingInstanceInCellRange,
      selectedMeasureIdFromView,
      instanceToCells,
      selectedSheetId,
      filteredNodeIds,
    } = this.props;
    return DrawingAnnotationFiltrationUtils.isValidReportUsing(
      instanceId,
      geometry[instanceId].groupId,
      filterData,
      isShowTwoDReport,
      viewMeasureId,
      dynamicGroupsToCell,
      groups,
      drawingInstanceInCellRange,
      selectedMeasureIdFromView,
      instanceToCells,
      selectedSheetId,
      filteredNodeIds,
    );
  }

  @autobind
  private isAssignValid(instance: DrawingsGeometryInstance): boolean {
    const { filterData, assignPia } = this.props;
    return DrawingAnnotationFiltrationUtils.isAssignValid(
      instance.id,
      instance,
      assignPia,
      this.state.groupsMap,
      filterData[AnnotationFilters.Assign] as AssignFilterValue,
    );
  }

  @autobind
  private scrollToGroupById(value: string): void {
    const isGroupWasClose = this.openHierarchyByGroupId(value);
    if (!isGroupWasClose) {
      const targetPosition = this.getEntityToScrollPosition(value);
      const topScroll = this.listApi.getScrollTop();
      const bottomScroll = this.listApi.getScrollHeight() + topScroll;
      if (targetPosition > bottomScroll || targetPosition < topScroll) {
        this.listApi.setScrollTop(targetPosition);
      }
    }
  }

  @autobind
  private getVirtualListItemHeight(): number {
    return VIRTUAL_LIST_ITEM_HEIGHT;
  }

  private getEntityToScrollPosition(entityId: string): number {
    const itemsHeight = this.getVirtualListItemHeight();
    let offset = 0;
    for (const visibleItem of this.state.visibleItems) {
      const item = visibleItem as DrawingsGeometryInstanceWithId | PivotedInstance;
      const existsInPivoted = isPivotedInstance(item) && item.groupedGeometries.includes(entityId);
      if (existsInPivoted || (item.id && item.id === entityId)) {
        break;
      }

      offset += getItemHeight(visibleItem);
    }

    return this.state.visibleItems.length * itemsHeight === offset ? this.listApi.getScrollTop() : offset;
  }

  private getSelectedInstancePosition(): number {
    const { selectedInstances, geometry, filteredElementIds } = this.props;

    if (!selectedInstances.length) {
      return;
    }

    const selectedItemId = selectedInstances.find(id => filteredElementIds.includes(id));

    if (!geometry[selectedItemId]) {
      return;
    }

    const currentGroupId = this.props.geometry[selectedItemId].groupId;

    if (!currentGroupId) {
      return this.getEntityToScrollPosition(selectedItemId);
    }

    const groupToScroll = this.findGroupToScroll(currentGroupId);
    return groupToScroll
      ? this.getEntityToScrollPosition(groupToScroll)
      : this.getEntityToScrollPosition(selectedItemId);
  }

  private findGroupToScroll(currentGroupId: string): string {
    const { groupsMap } = this.state;
    const { groupsIsOpenMap } = this.props;
    let currentGroup = groupsMap[currentGroupId];
    let lastClosed = !groupsIsOpenMap[currentGroupId] ? currentGroupId : null;
    while (currentGroup) {
      if (!groupsIsOpenMap[currentGroup.id]) {
        lastClosed = currentGroup.id;
      }
      currentGroup = groupsMap[currentGroup.parentId];
    }
    return lastClosed;
  }

  private openHierarchyByGroupId(groupId: string): boolean {
    const closeGroups = new Set<string>();
    const group = this.props.groups.find(g => g.id === groupId);
    this.appendCloseGroupHierarchy(closeGroups, group.parentId);
    const isAnyGroupOpen = !!closeGroups.size;
    if (isAnyGroupOpen) {
      this.props.toggleOpen(Array.from(closeGroups));
      this.scrollTarget = groupId;
    }

    return isAnyGroupOpen;
  }


  private appendCloseGroupHierarchy(result: Set<string>, id: string, groupToIgnoreCloseStatus?: string): void {
    const { groupsIsOpenMap } = this.props;
    const { groupsMap } = this.state;
    if (!id || result.has(id) || id === groupToIgnoreCloseStatus) {
      return;
    }

    if (!groupsIsOpenMap[id]) {
      result.add(id);
    }

    const parent = groupsMap[id].parentId;
    this.appendCloseGroupHierarchy(result, parent, groupToIgnoreCloseStatus);
  }

  private isGroupHidden(group: DrawingsGeometryGroupWithNesting): boolean {
    if (!group.allInnerInstances.length) {
      return false;
    }

    let isOnlyGroup = true;

    const isHiddenAll = this.state.hiddenInstanceIdsMap
      ? group.allInnerInstances.every(instance => {
        if (AnnotationLegendItemTypeguards.isGeometry(instance)) {
          isOnlyGroup = false;
          return this.state.hiddenInstanceIdsMap[instance.id];
        } else if (AnnotationLegendItemTypeguards.isPivoted(instance)) {
          isOnlyGroup = false;
          return instance.groupedGeometries.every(g => this.state.hiddenInstanceIdsMap[g]);
        }
        return true;
      })
      : true;

    return !isOnlyGroup && isHiddenAll;
  }

  @autobind
  private getRenderItemFunction(): (item: AnnotationLegendItem) => JSX.Element {
    return (item: AnnotationLegendItem): JSX.Element => {
      const { assignPia } = this.props;
      const { groupsMap } = this.state;
      const idsToCheckPia = AnnotationLegendItemTypeguards.isPivoted(item) ? item.groupedGeometries : [item.id];
      const hasAssignedPia = idsToCheckPia.some(x => DrawingsAssign.hasAssignedPia(x, assignPia));
      const hasInheritedPia = !hasAssignedPia
        && DrawingsAssign.hasInheritedPiaSimple((item as DrawingsGeometryInstanceWithId).groupId, assignPia, groupsMap);

      if (AnnotationLegendItemTypeguards.isGeometry(item)) {
        return (
          <DrawingsAnnotationLegendItem
            onOpenInstanceMenu={this.openInstanceMenu}
            instance={item}
            isSelected={this.state.selectedInstanceIdsMap && this.state.selectedInstanceIdsMap[item.id]}
            isImperial={this.props.isImperial}
            isHidden={this.state.hiddenInstanceIdsMap && this.state.hiddenInstanceIdsMap[item.id]}
            onHiddenChange={this.hiddenChangeUndoRedo}
            onClick={this.onItemClick}
            nestingCount={item.nestingCount}
            measure={this.state.measureInstanceRecords && this.state.measureInstanceRecords[item.id]}
            onContextMenu={this.onItemContextMenu}
            onDoubleClick={this.doubleClickUndoRedo}
            canEditMeasurement={this.props.canEditMeasurement}
            getItemsToMove={this.getItemsToMove}
            hasAssignedPia={hasAssignedPia}
            hasInheritedPia={hasInheritedPia}
            sendUpdateApi={this.saveItemUpdateApi}
            minWidth={this.props.panelMinWidth}
          />
        );
      } else if (AnnotationLegendItemTypeguards.isGroup(item)) {
        const isHidden = this.isGroupHidden(item);
        const isSelected = this.props.selectGroups.some(id => id === item.id);
        const isHighlighted = this.state.highlightGroupMap[item.id];
        const maxOrderIndex =
          DrawingAnnotationFiltrationUtils.getMaxOrderIndex(this.props.filterResultItems, item.parentId);
        return (
          <div>
            <DrawingsAnnotationLegendGroupLine
              group={item}
              moveToGroup={this.moveToGroupWrapper}
            />
            <DrawingsAnnotationLegendGroup
              key={item.id}
              group={item}
              groupId={item.id}
              groupName={this.state.groupsMap[item.id]?.name || item.name}
              isOpen={this.props.groupsIsOpenMap[item.id]}
              onPinClick={this.changeGroupPinStatus}
              isSelected={isSelected}
              isHidden={isHidden}
              onNameChange={this.onGroupNameChange}
              onHiddenChange={this.hiddenChangeUndoRedo}
              onClick={this.onGroupClick}
              onToggleOpen={this.onGroupOpenChange}
              onContextMenu={this.onGroupContextMenu}
              onDoubleClick={this.doubleClickUndoRedo}
              moveToGroup={this.moveToGroupWrapper}
              getItemsToMove={this.getItemsToMove}
              nestingCount={item.nestingCount}
              canEditMeasurement={this.props.canEditMeasurement}
              openGroupMenu={this.openGroupMenu}
              hasAssignedPia={hasAssignedPia}
              hasInheritedPia={hasInheritedPia}
              canChangeVisibility={true}
              isPinned={this.props.pinnedGroupIds.includes(item.id)}
              panelMinWidth={this.props.panelMinWidth}
              isHighlighted={isHighlighted}
              isOpenFilterPages={this.state.isOpenFilterPages}
              sendUpdateApi={this.saveItemUpdateApi}
            />
            {
              maxOrderIndex === item.orderIndex &&
              <DrawingsAnnotationLegendGroupLine
                group={item}
                bottomLine={true}
                moveToGroup={this.moveToGroupWrapper}
              />
            }
          </div>
        );
      } else if (AnnotationLegendItemTypeguards.isPivoted(item)) {

        const measure = this.state.measureInstanceRecords
          && item.groupedGeometries.reduce<DrawingsInstanceMeasure>((acc, id) => {
            const itemMeasures = this.state.measureInstanceRecords[id] || this.props.getInstancesMeasures([id])[0];
            if (!itemMeasures) {
              return acc;
            }
            const { measures } = itemMeasures;
            if (!acc.measures) {
              acc.measures = { ...measures };
            } else {
              Object.entries(acc.measures).forEach(([key, value]) => {
                if (measures[key]) {
                  acc.measures[key] = measures[key] + value;
                }
              });
            }
            return acc;
          }, { ...this.state.measureInstanceRecords[item.groupedGeometries[0]], measures: null });

        return (
          <DrawingsAnnotationLegendPivotedItem
            onOpenInstanceMenu={this.openInstanceMenu}
            instance={item}
            onClick={this.onPivotedItemClick}
            isImperial={this.props.isImperial}
            isHidden={
              this.state.hiddenInstanceIdsMap
              && item.groupedGeometries.every(g => this.state.hiddenInstanceIdsMap[g])
            }
            onHiddenChange={this.hiddenChangeUndoRedo}
            onContextMenu={this.onPivotedItemContextMenu}
            canEditMeasurement={this.props.canEditMeasurement}
            getItemsToMove={this.getItemsToMove}
            onDoubleClick={this.doubleClickUndoRedo}
            sendUpdateApi={this.saveItemUpdateApi}
            minWidth={this.props.panelMinWidth}
            nestingCount={item.nestingCount}
            isSelected={
              this.state.selectedInstanceIdsMap
              && item.groupedGeometries.some(x => this.state.selectedInstanceIdsMap[x])
            }
            measure={measure}
            hasAssignedPia={hasAssignedPia}
            hasInheritedPia={hasInheritedPia}
            onSelectParentGroup={this.selectOnlyGroupById}
          />
        );
      }
    };
  }

  @autobind
  private saveItemUpdateApi(api: LegendItemAPI, id: string): void {
    if (api) {
      this.itemsApi.set(id, api);
    } else {
      this.itemsApi.delete(id);
    }
  }

  @autobind
  private getFilterPagesPosition(isOpen: boolean): void {
    this.setState({ isOpenFilterPages: isOpen });
  }

  @autobind
  private onUpdateBodyWidth(): void {
    this.itemsApi.forEach(api => api.updateRenderParams());
  }

  @autobind
  private moveToGroupWrapper(groupId: string, groupIds: string[], measurementIds: string[], orderIndex?: number): void {
    this.props.sendEvent(MetricNames.measureManager.moveToGroup, { source: 'drag and drop' });
    this.props.moveToGroupWithUndoRedo(groupId, groupIds, measurementIds, orderIndex);
  }

  @autobind
  private openInstanceMenu(
    e: React.MouseEvent,
    item: DrawingsGeometryInstanceWithIdAndGroupId | PivotedInstance,
  ): void {
    ConstantFunctions.stopEvent(e);
    const buttonRect = e.currentTarget.getBoundingClientRect();
    this.setState({
      instancesMenu: {
        isOpen: true,
        positionX: buttonRect.right,
        positionY: buttonRect.bottom,
        selectedItem: item,
      },
    });
  }

  @autobind
  private openGroupMenu(e: React.MouseEvent<HTMLDivElement>, group: any, menuType: GroupsMenuType): void {
    ConstantFunctions.stopEvent(e);
    const buttonRect = e.currentTarget.getBoundingClientRect();
    this.setState({
      groupMenu: {
        isOpen: true,
        selectedGroup: group,
        positionX: buttonRect.right,
        positionY: buttonRect.bottom,
        menuType,
      },
    });
  }

  @autobind
  private closeGroupMenu(): void {
    this.setState({
      groupMenu: {
        isOpen: false,
        selectedGroup: null,
        positionX: 0,
        positionY: 0,
        menuType: null,
      },
    });
  }

  @autobind
  private closeInstanceMenu(): void {
    this.setState({
      instancesMenu: {
        isOpen: false,
        positionX: 0,
        positionY: 0,
        selectedItem: null,
      },
    });
  }

  @autobind
  private changeGroupPinStatus(groupId: string): void {
    const action = (): void => this.props.changeGroupPinStatus(groupId);
    this.props.addUndoRedo(action, action);
    action();
  }

  @autobind
  private setGroupColor(color: string, groupId: string): void {
    const group = this.props.groups.find(g => g.id === groupId);
    this.updateGroupColor(color, group);
  }

  @autobind
  private onColorChange(color: string): void {
    const selectedGroup = this.state.groupMenu.selectedGroup;
    this.updateGroupColor(color, selectedGroup);
  }

  private updateGroupColor(color: string, group: DrawingsGeometryGroup): void {
    const newColor = group.color === color ? null : color;
    const originalGroup = {
      id: group.id,
      name: group.name,
      color: group.color,
    };
    let colorChange;
    if (newColor) {
      const parentToChild = DrawingsGroupUtils.getParentToChildMap(this.props.groups);
      const measurementIds = DrawingsGroupUtils
        .getAllInnerMeasurements(group, parentToChild, (g) => !g.color);
      colorChange = this.props.changeColorUndoRedo(measurementIds, newColor, originalGroup);
    } else {
      colorChange = DrawingsUndoRedoHelper.updateMeasurementGroupUndoRedo(
        'color',
        newColor,
        originalGroup,
        this.props.updateDrawingsGroup,
      );
    }
    colorChange.redo();
    this.props.addUndoRedo(colorChange.undo, colorChange.redo);
    this.closeGroupMenu();
  }

  @autobind
  private cancelSelectedInstancesAndGroup(): void {
    this.setSelected([], []);
    this.setState({ lastClickedMeasureId: null, lastClickedMeasureGroupId: null });
  }

  @autobind
  private saveListApi(api: KreoScrollbarsApi): void {
    this.listApi = api;
  }

  private preventDefaultContextMenu(e: React.MouseEvent<HTMLDivElement>): boolean {
    e.preventDefault();
    return false;
  }

  @autobind
  private onGroupContextMenu(e: React.MouseEvent<HTMLDivElement>, group: DrawingsGeometryGroupWithNesting): boolean {
    e.preventDefault();
    const position = { x: e.pageX, y: e.pageY };
    const isCtrl = HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e);
    this.props.setDrawMode(
      DrawingsDrawMode.Disabled,
      { afterSave: () => this.openGroupContextMenu(group, isCtrl, position) },
    );
    return false;
  }

  private openGroupContextMenu(
    group: DrawingsGeometryGroupWithNesting,
    isCtrl: boolean,
    position: DrawingContextMenuPosition,
  ): void {
    if (!isCtrl) {
      const isSelected = this.props.selectGroups.some(id => id === group.id);
      this.selectGroupChildren(group, isSelected, true);
    }
    this.props.setContextMenuPosition(position);
  }

  @autobind
  private onPivotedItemContextMenu(event: React.MouseEvent<HTMLDivElement>, id: string[]): boolean {
    event.preventDefault();
    const position = { x: event.pageX, y: event.pageY };
    const existancesIds = id.filter(x => this.props.geometry[x]);
    const isCtrl = HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(event);
    this.props.setDrawMode(
      DrawingsDrawMode.Disabled,
      { afterSave: () => this.openItemsContextMenu(existancesIds, isCtrl, position) },
    );
    return false;
  }

  @autobind
  private onItemContextMenu(e: React.MouseEvent<HTMLDivElement>, id: string): boolean {
    e.preventDefault();
    const position = { x: e.pageX, y: e.pageY };
    const isCtrl = HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e);
    this.props.setDrawMode(
      DrawingsDrawMode.Disabled,
      { afterSave: () => this.openItemsContextMenu([id], isCtrl, position) },
    );
    return false;
  }

  private openItemsContextMenu(ids: string[], isCtrl: boolean, position: DrawingContextMenuPosition): void {
    this.props.focus(ids);
    const isSelected = ids.every(id => this.props.selectedInstances.includes(id));
    if (!isCtrl && !isSelected) {
      this.setSelected(ids, [], false);
    }
    this.props.setContextMenuPosition(position);
  }

  @autobind
  private onGroupOpenChange(id: string): void {
    this.props.toggleOpen([id]);
  }

  @autobind
  private collapseAllGroups(): void {
    this.props.toggleAllOpen(false);
  }

  @autobind
  private expandAllGroups(): void {
    this.props.toggleAllOpen(true);
  }

  @autobind
  private onGroupNameChange(id: string, name: string): void {
    const group = this.props.groups.find(x => x.id === id);
    if (group) {
      const oldGroup: UpdateDrawingGroup = { id, name: group.name, color: group.color };
      const { undo, redo } = DrawingsUndoRedoHelper.updateMeasurementGroupUndoRedo(
        'name',
        name,
        oldGroup,
        this.props.updateDrawingsGroup,
      );
      redo();
      this.props.addUndoRedo(undo, redo);
    }
  }

  private getMeasures(items: AnnotationLegendItem[]): Record<string, DrawingsInstanceMeasure> {
    const drawingIds: string[] = DrawingAnnotationFiltrationUtils.getDrawingsInstanceIds(items);
    const measures = this.props.getInstancesMeasures(drawingIds);
    const instanceMeasures = {};

    measures.forEach((measure, index) => {
      instanceMeasures[drawingIds[index]] = measure;
    });

    return instanceMeasures;
  }

  @autobind
  private onFilterChange(filterResult: AnnotationLegendTree): void {
    const instanceMeasures = this.getMeasures(filterResult.items);
    this.setState(
      {
        measureInstanceRecords: instanceMeasures,
      },
      () => {
        this.props.setFilteredResultItems(filterResult.items);
        this.scrollToTarget();
      },
    );
  }

  @autobind
  private scrollToTarget(): void {
    if (this.scrollTarget) {
      if (this.props.geometry[this.scrollTarget]) {
        this.focusItemOnSelectionChange();
      } else {
        this.scrollToGroupById(this.scrollTarget);
      }
      this.scrollTarget = null;
    }
  }

  @autobind
  private onPivotedItemClick(event: React.MouseEvent<HTMLDivElement>, id: string[], itemId: string): void {
    const existancesIds = id.filter(x => this.props.geometry[x]);
    if (!existancesIds.length) {
      return;
    }
    ConstantFunctions.stopEvent(event);
    if (this.props.isDrawingMode) {
      this.selectInstancesAndExistFromDrawMode(existancesIds, itemId);
    } else {
      this.selectInstance(event, existancesIds, itemId);
    }
  }

  @autobind
  private onItemClick(event: React.MouseEvent<HTMLDivElement>, id: string): void {
    if (!this.props.geometry[id]) {
      return;
    }
    ConstantFunctions.stopEvent(event);
    if (this.props.isDrawingMode) {
      this.selectInstancesAndExistFromDrawMode([id]);
    } else {
      this.selectInstance(event, [id]);
    }
  }

  private selectInstancesAndExistFromDrawMode(ids: string[], baseId?: string): void {
    this.props.setDrawMode(
      DrawingsDrawMode.Disabled,
      {
        afterSave: () => {
          this.setState({ lastClickedMeasureId: baseId || ids[0], lastClickedMeasureGroupId: null });
          this.setSelected(ids, [], false);
        },
      },
    );
  }

  private selectInstance(event: React.MouseEvent<HTMLDivElement>, ids: string[], baseInstanceId?: string): void {
    const { selectedInstances, geometry, pageTabs, selectGroups } = this.props;
    let instancesIds = [];
    let groupIds = selectGroups;
    if (HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(event)) {
      this.isSetScrollTop = false;
      const filteredInstancesIds = selectedInstances.filter(x => !ids.includes(x));
      const isNotSelected = filteredInstancesIds.length === selectedInstances.length;
      if (isNotSelected) {
        filteredInstancesIds.unshift(...ids);
      }
      instancesIds = filteredInstancesIds;
      this.setState({ lastClickedMeasureId: baseInstanceId || ids[0], lastClickedMeasureGroupId: null });
      if (!isNotSelected) {
        this.props.syncGroupSelection(ids[0]);
      }
    } else if (event.shiftKey) {
      this.isSetScrollTop = false;
      const id = baseInstanceId || ids[0];
      const item = this.state.visibleItems.find(x => x.id === id);
      this.handleShiftClick(item);
      return;
    } else {
      this.isSetScrollTop = true;
      instancesIds = ids;
      this.setState({ lastClickedMeasureId: baseInstanceId || ids[0], lastClickedMeasureGroupId: null });
      groupIds = [];
    }
    const newPagesTabs = arrayUtils.filterMap(
      instancesIds,
      x => !pageTabs.includes(geometry[x].drawingId),
      x => geometry[x].drawingId,
    );
    if (newPagesTabs.length) {
      this.props.addPageTabs(arrayUtils.uniq(newPagesTabs));
    }
    if (!event.shiftKey && instancesIds.length) {
      const instance = geometry[instancesIds[0]];
      if (this.props.currentDrawingId !== instance.drawingId) {
        this.props.setCurrentDrawing(instance.drawingId);
        this.props.saveCurrentDrawingState(instance.drawingId);
      }
    }
    this.setSelected(instancesIds, groupIds);
    this.setFocus(instancesIds);
  }

  @autobind
  private hiddenChangeUndoRedo(ids: string[], isHidden: boolean): void {
    if (!ids.length) {
      return;
    }

    const [instancesToShow, instancesToHide] = isHidden ? [[], ids] : [ids, []];
    const { undo, redo } = DrawingsUndoRedoHelper.createVisibilityUndoRedo(
      instancesToShow,
      instancesToHide,
      this.props.updateInstancesVisibility,
    );
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private doubleClickUndoRedo(instancesIds: string[]): void {
    const hideInstanceIds = DrawingAnnotationFiltrationUtils.getDrawingsInstanceIds(this.props.filterResultItems);
    const { undo, redo } = DrawingsUndoRedoHelper.createVisibilityUndoRedo(
      instancesIds,
      hideInstanceIds,
      this.props.updateInstancesVisibility,
    );
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private changeDrawMode(drawMode: DrawingsDrawMode): void {
    const groupId = this.state.groupMenu.selectedGroup?.id;

    this.props.setDrawMode(
      drawMode,
      {
        blockDeselection: true,
        afterSave: () => this.setSelected([], [groupId]),
      },
    );
  }

  @autobind
  private drawGeometryToFolder(drawMode: DrawingsDrawMode): void {
    this.changeDrawMode(drawMode);
  }

  @autobind
  private createChildGroup(): void {
    this.createEmptyGroup(this.state.groupMenu.selectedGroup.id);
  }

  @autobind
  private createEmptyGroup(parentId?: string): void {
    const newGroup = DrawingsGroupUtils.createNewGroup();
    newGroup.parentGroupId = parentId;
    this.props.createNewGroupsWithUndoRedo({ groups: [newGroup] });
    this.props.sendEvent(MetricNames.measureManager.createFolder);
  }

  private handleCtrlClickOnGroup(group: DrawingsGeometryGroupWithNesting): void {
    const { selectedInstances, selectGroups } = this.props;
    const isAlreadySelected = selectGroups.some(id => id === group.id);
    if (isAlreadySelected) {
      const { drawingInstances, groupInstance } = this.getInstancesAfterGroupClick(group);
      const drawingsInstancesSet = new Set(drawingInstances);
      const filteredInstancesIds = selectedInstances.filter(x => !drawingsInstancesSet.has(x));
      const groupInstancesIdsSet = new Set(groupInstance);
      const newSelectGroups = selectGroups.filter(id => !groupInstancesIdsSet.has(id));
      this.setSelected(filteredInstancesIds, newSelectGroups);
    } else {
      this.selectGroupChildren(group, true);
    }

    this.setState({ lastClickedMeasureGroupId: group.id, lastClickedMeasureId: null });
  }

  private getInstancesAfterGroupClick(
    group: DrawingsGeometryGroupWithNesting,
  ): { drawingInstances: string[], groupInstance: string[] } {
    const drawingInstances = [];
    const groupInstance = [];
    groupInstance.push(group.id);
    for (const instance of group.allInnerInstances) {
      if (AnnotationLegendItemTypeguards.isPivoted(instance)) {
        arrayUtils.extendArray(drawingInstances, instance.groupedGeometries);
      } else  if (AnnotationLegendItemTypeguards.isGeometry(instance)) {
        drawingInstances.push(instance.id);
      } else {
        groupInstance.push(instance.id);
      }
    }

    return { drawingInstances, groupInstance };
  }

  private selectGroupChildren(
    group: DrawingsGeometryGroupWithNesting,
    keepSelected: boolean,
    forceSelect?: boolean,
  ): void {
    this.setState(
      { lastClickedMeasureGroupId: group.id, lastClickedMeasureId: null },
      () => {
        const { selectedInstances, selectGroups } = this.props;
        const selectedInstancesSet = new Set(selectedInstances);
        const { drawingInstances, groupInstance } = this.getInstancesAfterGroupClick(group);
        const instances = keepSelected
          ? drawingInstances.filter(x => !selectedInstancesSet.has(x)).concat(selectedInstances)
          : drawingInstances;
        const groups = keepSelected
          ? arrayUtils.uniq([...selectGroups, ...groupInstance])
          : groupInstance;
        this.setSelected(instances, groups, forceSelect);
      },
    );
  }

  private handleSimpleClickOnGroup(group: DrawingsGeometryGroupWithNesting): void {
    const { selectGroups } = this.props;
    const isAlreadySelected = selectGroups.some(id => id === group.id);

    if (isAlreadySelected) {
      this.setSelected([], []);
      this.setState({ lastClickedMeasureGroupId: null, lastClickedMeasureId: null });
    } else {
      this.selectGroupChildren(group, false);
    }
  }

  @autobind
  private setFocus(instancesIds: string[]): void {
    if (instancesIds.length) {
      this.props.focus(instancesIds);
    }
  }

  private appendSelectList(
    element: AnnotationLegendItem,
    groupInstancesMap: string[],
    drawingInstancesMap: string[],
  ): void {
    if (AnnotationLegendItemTypeguards.isGroup(element)) {
      groupInstancesMap.push(element.id);
      element.allInnerInstances.forEach(i => this.appendSelectList(i, groupInstancesMap, drawingInstancesMap));
    }
    if (AnnotationLegendItemTypeguards.isGeometry(element)) {
      drawingInstancesMap.push(element.id);
      drawingInstancesMap[element.id] = element;
    }

    if (AnnotationLegendItemTypeguards.isPivoted(element)) {
      arrayUtils.extendArray(drawingInstancesMap, element.groupedGeometries);
    }
  }

  private handleShiftClick(filterItem: AnnotationLegendItem): void {
    const { lastClickedMeasureId, lastClickedMeasureGroupId } = this.state;

    const lastClickedId = lastClickedMeasureId || lastClickedMeasureGroupId;
    const visibleItems = this.state.onlyGroupsItems || this.state.visibleItems;

    const prevIndex = visibleItems.findIndex(item => {
      return item.id === lastClickedId;
    });
    const currentIndex = visibleItems.findIndex(item => filterItem.id === item.id);
    const startIndex = Math.min(prevIndex, currentIndex);
    const endIndex = Math.max(prevIndex, currentIndex);
    const newList = visibleItems.slice(startIndex, endIndex + 1);

    const drawingInstancesMap = [];
    const groupInstancesMap = [];

    newList.forEach(element => {
      this.appendSelectList(element, groupInstancesMap, drawingInstancesMap);
    });

    const groupInstancesIds = arrayUtils.uniq(groupInstancesMap);
    const drawingInstancesIds = arrayUtils.uniq(drawingInstancesMap);
    this.setFocus(drawingInstancesIds);
    this.setSelected(drawingInstancesIds, groupInstancesIds);
  }

  @autobind
  private selectOnlyGroupById(groupId: string): void {
    if (groupId) {
      this.setSelected([], [groupId]);
    } else {
      this.setSelected([], []);
    }
  }

  @autobind
  private onGroupClick(event: React.MouseEvent<HTMLDivElement>, group: DrawingsGeometryGroupWithNesting): void {
    if (!this.state.groupsMap[group.id]) {
      return;
    }
    ConstantFunctions.stopEvent(event);
    if (this.props.isDrawingMode) {
      this.setSelected([], [group.id]);
      return;
    }
    if (HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(event)) {
      this.handleCtrlClickOnGroup(group);
      return;
    }

    if (event.shiftKey) {
      this.handleShiftClick(group);
      return;
    }

    this.handleSimpleClickOnGroup(group);
  }

  @autobind
  private setQuickSearchValue(value: string): void {
    this.props.changeFilterData({ filterType: AnnotationFilters.Name, element: value });
  }

  @autobind
  private deleteEmptyGroups(): void {
    const parentToChildMap = DrawingsGroupUtils.getParentToChildMap(this.props.groups);
    const idsToRemove = arrayUtils.filterMap(
      this.props.groups,
      ({ id }) => DrawingsGroupUtils.isGroupEmpty(id, parentToChildMap),
      ({ id }) => id,
    );
    this.props.removeInstancesWithUndo({}, idsToRemove);
  }

  @autobind
  private invertSelection(): void {
    const instancesToExclude = new Set([
      ...this.props.selectedInstances,
      ...this.props.hiddenInstances,
    ]);
    const instancesIds = this.props.filteredElementIds.filter(id => !instancesToExclude.has(id));
    this.setFocus(instancesIds);
    this.props.selectDrawingGroups(instancesIds);
  }

  @autobind
  private getItemsToMove(): DrawingsDragAndDropData {
    const { groups, selectGroups } = this.props;
    const groupRoots = DrawingsGroupUtils.getSelectedGroupRoots(selectGroups, groups);
    const groupIdsToMove = new Set(groupRoots.map(group => group.id));
    const groupsToMove = this.props.filterResultItems.filter(item =>
      AnnotationLegendItemTypeguards.isGroup(item) && groupIdsToMove.has(item.id),
    );
    const instanceIds = new Set(DrawingAnnotationFiltrationUtils.getDrawingsInstanceIds(groupsToMove));
    const measurementsToMove = Object.keys(this.state.selectedInstanceIdsMap)
      .filter(selectedInstance => !instanceIds.has(selectedInstance));
    return {
      groups: groupsToMove as DrawingsGeometryGroupWithNesting[],
      measurements: measurementsToMove,
    };
  }

  private setSelected(instanceIds: string[], groupIds: string[], force?: boolean): void {
    this.haveToBlockFocus = true;
    if (this.props.isDrawingMode && force) {
      this.props.setSelected([], groupIds.length > 1 ? [groupIds[groupIds.length - 1]] : groupIds);
    } else {
      this.props.setSelected(instanceIds, groupIds);
    }
  }

  @autobind
  private duplicateGroups(): void {
    const { groups, selectGroups, assignedPia } = this.props;
    const groupRoots = DrawingsGroupUtils.getSelectedGroupRoots(selectGroups, groups);
    this.props.sendEvent(MetricNames.measureManager.duplicateGroup);
    const groupsData = DrawingsGroupUtils.duplicateGroups({
      rootGroups: groupRoots,
      groups,
      pia: assignedPia,
    });
    this.props.createNewGroupsWithUndoRedo(groupsData);
  }
}


function mapStateToProps(state: State): StateProps {
  const {
    aiAnnotation,
    currentDrawingInfo,
    files,
    drawingGeometryGroups,
    loadStatus,
    drawingGeometryOpenGroups,
    elementMeasurement,
    selectedInstances,
    hiddenInstances,
    selectedPages,
    selectGeometryGroup,
    filteredElementIds,
    pinnedGroupIds,
    showOnlyGroups,
    filterData,
  } = state.drawings;
  const projectId = state.projects.currentProject.id;

  return {
    pivotMeasurements: state.persistedStorage.drawingMeasurementsPivot ?? false,
    showOnlyGroups,
    projectId,
    isImperial: state.account.settings.isImperial,
    geometry: aiAnnotation.geometry,
    selectedInstances,
    hiddenInstances,
    groups: drawingGeometryGroups,
    groupsIsOpenMap: drawingGeometryOpenGroups,
    files,
    loadStatus,
    elementMeasurement,
    pageTabs: selectedPages,
    currentDrawingId: currentDrawingInfo && currentDrawingInfo.drawingId,
    selectGroups: selectGeometryGroup,
    filteredElementIds,
    users: state.people.companiesUsers,
    selectedMeasurementSort: state.persistedStorage.selectedMeasurementSort || SortDataInitialState,
    assignPia: state.twoD.assignPia,
    pinnedGroupIds,
    filterData,
    isShowTwoDReport: state.twoD.isShowTwoDReport,
    viewMeasureId: state.twoD.usedViewMeasureId,
    dynamicGroupsToCell: state.twoD.dynamicGroupsToCell,
    drawingInstanceInCellRange: state.twoD.drawingInstanceInCellRange,
    selectedMeasureIdFromView: state.twoD.selectedMeasureIdView,
    instanceToCells: state.twoD.drawingInstanceToCell,
    selectedSheetId: state.twoD.selectedSheetId,
    filteredNodeIds: state.twoD.filteredNodeIds,
    selectedFilterPages: state.persistedStorage.selectedFilterPages || PagesFilterValue.Current,
    openGroups: drawingGeometryOpenGroups,
    hideUnselectedPageGroups: state.persistedStorage.hideUnselectedPageGroups,
    filterResultItems: state.drawings.filteredItems,
    assignedPia: state.twoD.assignPia,
    isMeasurementsSearchVisible: state.persistedStorage.isMeasurementsSearchVisible
      ?? PERSISTED_STORAGE_INITIAL_STATE.isMeasurementsSearchVisible,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { canEditMeasurement }: OwnProps): DispatchProps {
  return {
    setSelected: (instanceIds, groupIds) =>
      dispatch(DrawingsAnnotationLegendActions.updateSelection({ instanceIds, groupIds })),
    toggleOpen: (groupId) => dispatch(DrawingsAnnotationLegendActions.toggleOpen(groupId)),
    toggleAllOpen: (isOpen) => dispatch(DrawingsAnnotationLegendActions.toggleAllOpen(isOpen)),
    addPageTabs: (drawingIds) => dispatch(DrawingsActions.addTabs({ entityIds: drawingIds, openLastPage: true })),
    setCurrentDrawing: (drawingId) => dispatch(DrawingsActions.selectDrawing(drawingId)),
    saveCurrentDrawingState: (drawingId) => dispatch(DrawingsActions.saveSelectedDrawingsState(drawingId)),
    changeFilterData: (changeFilterData) =>
      dispatch(DrawingsAnnotationLegendActions.changeFilterData(changeFilterData)),
    changeGroupPinStatus: (groupId) => dispatch(DrawingsActions.changeGroupPinStatus(groupId)),
    updateInstancesVisibility: (instancesToShow, instancesToHide) =>
      dispatch(InstancesVisibilityActions.updateVisibility(instancesToShow, instancesToHide, canEditMeasurement)),
    setContextMenuPosition: (contextMenuPosition) =>
      dispatch(DrawingsActions.setContextMenuPosition(contextMenuPosition)),
    openUnsavedChangesDialog: (accept, cancel) =>
      dispatch(KreoDialogActions.openDialog(DrawingDialogs.UNSAVED_CHANGES_DIALOG, { accept, cancel })),
    saveFilteredValue: (ids: string[]) => dispatch(DrawingsAnnotationLegendActions.saveFiltersId(ids)),
    setFiltersPages: (filterPages) => dispatch(PersistedStorageActions.setFiltersPages(filterPages)),
    setFilteredResultItems: (items) => dispatch(DrawingsAnnotationLegendActions.setFilteredResultItems(items)),
    sendAssignPia: (assign, projectId) => {
      const iterationId = UuidUtil.generateUuid();
      dispatch(TwoDActions.setExportExcelLoad(iterationId));
      dispatch(TwoDActions.sendAssignPatch(assign, iterationId, null, projectId));
    },
    toggleQuickSearch: () => dispatch(PersistedStorageActions.toggleMeasurementsSearch()),
    openNotScaledDialog: (data) => dispatch(KreoDialogActions.openDialog(DrawingDialogs.NOT_SCALED_DIALOG, data)),
  };
}

export const DrawingsAnnotationLegend = connect(mapStateToProps, mapDispatchToProps)(
  connectToLegendContexts(DrawingsAnnotationLegendComponent));
