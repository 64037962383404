import classNames from 'classnames';
import * as React from 'react';

import './header.scss';

interface Props {
  fixed?: boolean;
}

export class Header extends React.PureComponent<Props> {

  public render(): JSX.Element {
    return (
      <div className='table-header'>
        <div className={classNames('table-header__inner', { 'table-header__inner--fixed': this.props.fixed })}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
