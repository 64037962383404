import autobind from 'autobind-decorator';
import * as React from 'react';
import { RenderIf } from 'common/components/render-if';
import { Engine } from 'components/engine';
import { ApproveButton } from './approve-button';
import { RevertButton } from './revet-button';
import { Styled } from './styled';
import { StepWrapperEngineApi } from './utils/step-wrapper-engine-api';

interface Props {
  projectId: number;
  onDisapprove?: () => void;
  onApprove?: () => void;
  onEngineApiReady?: (api: StepWrapperEngineApi) => void;
}

export class StepWrapper extends React.Component<Props> {
  private engine: any;

  public render(): JSX.Element {
    const { onApprove, onDisapprove } = this.props;
    return (
      <Styled.Container>
        <Styled.Top>
          <div className='validation-stepper__btn-wrap'>
            <RenderIf condition={!!onDisapprove}>
              <RevertButton
                onRevert={onDisapprove}
              />
            </RenderIf>
            <RenderIf condition={!!onApprove}>
              <ApproveButton
                isApproved={false}
                title='Approve'
                onApprove={onApprove}
              />
            </RenderIf>
          </div>
        </Styled.Top>
        <Styled.Content>
          <Styled.Wrapper>
            <Styled.StepContent>
              {this.props.children}
            </Styled.StepContent>
            <Styled.StepEngine>
              <Engine
                projectId={this.props.projectId}
                textures='/static/textures/'
                sendEngineApi={this.setEngineApi}
                affterLoadAction={this.afterLoadAction}
                onHandleClick={this.dropSelect}
                backgroundColor='white'
              />
            </Styled.StepEngine>
          </Styled.Wrapper>
        </Styled.Content>
      </Styled.Container>
    );
  }


  @autobind
  private setEngineApi(engine: any): void {
    this.engine = engine;
    const api = new StepWrapperEngineApi(this.engine);
    this.props.onEngineApiReady(api);
  }

  @autobind
  private afterLoadAction(): void {
    const api = new StepWrapperEngineApi(this.engine);
    this.props.onEngineApiReady(api);
  }

  @autobind
  private dropSelect(): void {
    this.engine.setSelected([]);
  }

}
