import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { ProjectsActions } from '../actions/creators/common';
import { QuantityTakeOffActions } from '../actions/creators/quantity-take-off';
import { QtoBasePayload, QtoGetLeftPanelRecordsPayload } from '../actions/payloads/quantity-take-off';
import { QuantityTakeOffActionTypes } from '../actions/types/quantity-take-off';
import { ClassificationApi } from '../api/classification';
import { QuantityTakeOffApi } from '../api/quantity-take-off';
import { KnownViewModel } from '../enums/known-view-model';
import { ViewModelStatus } from '../enums/view-model-status';
import { QuantityTakeOffModel } from '../interfaces/quantity-take-off/quantity-take-off-model';

function* getModel({ payload: { projectId, modelType } }: ActionWith<QtoBasePayload>): SagaIterator {
  try {
    const model: QuantityTakeOffModel = yield call(QuantityTakeOffApi.getModel, projectId, modelType);
    if (model && Array.isArray(model.elements)) {
      yield put(QuantityTakeOffActions.getModelSucceeded(projectId, modelType, model));
    } else {
      yield put(QuantityTakeOffActions.getModelFailed());
    }
  } catch (error) {
    console.error('Error while loading Quantity Take Off model', error, { projectId });
    yield put(QuantityTakeOffActions.getModelFailed());
  }
}

function* getQtoPageData({ payload: { projectId, modelType } }: ActionWith<QtoBasePayload>): SagaIterator {
  try {
    const [records, config, globalConfig] = yield all([
      call(QuantityTakeOffApi.getLeftPanelRecords, projectId, modelType),
      call(ClassificationApi.getQtoRecordsConfig),
      call(QuantityTakeOffApi.getQtoGlobalConfig,  projectId),
    ]);
    yield put(QuantityTakeOffActions.getRecordsConfig(config));
    yield put(QuantityTakeOffActions.getElementRecords(records, false));
    yield put(QuantityTakeOffActions.setGlobalConfig(globalConfig));
  } catch (error) {
    console.error('quantity take off: get qto page data failed', error, { projectId });
  }
}

function* getLeftPanelRecords({ payload }: ActionWith<QtoGetLeftPanelRecordsPayload>): SagaIterator {
  try {
    const records = yield call(QuantityTakeOffApi.getLeftPanelRecords, payload.projectId, payload.modelType);
    yield put(QuantityTakeOffActions.getElementRecords(records, payload.isUpdateRecordsProps));
  } catch (error) {
    console.error('quantity take off: get element records failed', error, { ...payload });
  }
}

function* downloadGeneralReport(
  { payload: { projectId, modelType } }: ActionWith<QtoBasePayload>,
): SagaIterator {
  try {
    yield call(QuantityTakeOffApi.downloadGeneralReportFile, projectId, modelType);
  } catch (error) {
    console.error('Error while downloading QTO General Report', error, { projectId });
  }
}

function* buildApproximateCostReport(
  { payload: { projectId, modelType } }: ActionWith<QtoBasePayload>,
): SagaIterator {
  try {
    yield put(ProjectsActions.updateViewModelStatus({
      projectId,
      viewModelType: KnownViewModel.QtoApproximateCostReport,
      viewModelStatus: ViewModelStatus.Calculating,
    }));
    yield call(QuantityTakeOffApi.buildApproximateCostReport, projectId, modelType);
  } catch (error) {
    console.error('Error while requesting to build QTO Approximate Cost Report', error, { projectId });
    yield put(ProjectsActions.updateViewModelStatus({
      projectId,
      viewModelType: KnownViewModel.QtoApproximateCostReport,
      viewModelStatus: ViewModelStatus.Failed,
    }));
  }
}

function* downloadApproximateCostReport(
  { payload: { projectId, modelType } }: ActionWith<QtoBasePayload>,
): SagaIterator {
  try {
    yield call(QuantityTakeOffApi.downloadApproximateCostReportFile, projectId, modelType);
  } catch (error) {
    console.error('quantity take off: download approximate cost report failed', error, { projectId });
  }
}

function* downloadRawDataFile(
  { payload: { projectId, modelType } }: ActionWith<QtoBasePayload>,
): SagaIterator {
  try {
    yield call(QuantityTakeOffApi.downloadRawDataFile, projectId, modelType);
  } catch (error) {
    console.error('quantity take off: download raw data file failed', error, { projectId });
  }
}

function* getRecordsConfig({ payload: projectId }: ActionWith<number>): SagaIterator {
  try {
    const config = yield call(QuantityTakeOffApi.getRecordsConfig, projectId);
    yield put(QuantityTakeOffActions.getRecordsConfig(config));
  } catch (error) {
    console.error('quantity take off: get records config failed', error, { projectId });
  }
}

export function* quantityTakeOffSagas(): SagaIterator {
  yield takeLatest(QuantityTakeOffActionTypes.GET_MODEL_REQUEST, getModel);
  yield takeLatest(QuantityTakeOffActionTypes.DOWNLOAD_GENERAL_REPORT, downloadGeneralReport);
  yield takeLatest(QuantityTakeOffActionTypes.BUILD_APPROXIMATE_COST_REPORT, buildApproximateCostReport);
  yield takeLatest(QuantityTakeOffActionTypes.DOWNLOAD_APPROXIMATE_COST_REPORT, downloadApproximateCostReport);
  yield takeLatest(QuantityTakeOffActionTypes.DOWNLOAD_RAW_DATA_FILE, downloadRawDataFile);
  yield takeLatest(QuantityTakeOffActionTypes.LOAD_RECORDS_CONFIG, getRecordsConfig);
  yield takeLatest(QuantityTakeOffActionTypes.GET_LEFT_PANEL_RECORDS, getLeftPanelRecords);
  yield takeLatest(QuantityTakeOffActionTypes.UPLOAD_QTO_PAGE_DATA, getQtoPageData);
}
