import * as React from 'react';
import { connect } from 'react-redux';


import { State } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import {
  ActivityAssignmentMappedVariant,
} from '../../interfaces/activity-assignment/activity-assignment-mapped-variant';
import { ActivityAssignmentActivitiesHeader } from '../activity-assignment-activities-header';
import { ActivityAssignmentActivityListContainer } from '../activity-assignment-activity-list-container';
import { ActivityAssignmentActivityTile } from '../activity-assignment-activity-tile';
import { ActivityAssignmentNoActivities } from '../activity-assignment-no-activities';
import { ValidationResizableEditor } from '../validation-resizable-editor';


interface Props {
  activitiesCount: number;
  activities: ActivityAssignmentMappedVariant[];
}


class ActivityAssignmentActivityLayoutEditComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
        <ValidationResizableEditor>
          <ActivityAssignmentActivitiesHeader edit={true} />
          <ActivityAssignmentActivityListContainer>
            <KreoScrollbars showShadowTop={true}>
              {this.props.activitiesCount > 0 ?
                this.getActivityTiles() : (
                <ActivityAssignmentNoActivities/>
                )}
            </KreoScrollbars>
          </ActivityAssignmentActivityListContainer>
        </ValidationResizableEditor>
    );
  }


  private getActivityTiles(): React.ReactNode[] {
    const activities = new Array<React.ReactNode>();
    for (let i = 0; i < this.props.activitiesCount; i++) {
      activities[i] = (
        <ActivityAssignmentActivityTile
          key={i}
          index={i}
        />
      );
    }
    return activities;
  }
}

function mapStateToProps({ activityAssignment }: State): Props {
  return {
    activities: activityAssignment.selectedWorks,
    activitiesCount: activityAssignment.selectedWorks.length,
  };
}

const connector = connect(mapStateToProps);
export const ActivityAssignmentActivityLayoutEdit =
  connector(ActivityAssignmentActivityLayoutEditComponent);
