import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';

import './quantity-take-off-report-pivot-table-cell-edit.scss';

import { TreeTableValueGetterParams } from 'common/components/tree-table/interfaces';
import { QtoTreeRowProperty } from '../../interfaces/quantity-take-off';
import { PropertyHelper, QtoReportPivotTableIdHelper } from '../../utils/quantity-take-off-tree-table';
import { CombineCellEditor } from '../quantity-take-off-tree-table-cell-edit/combine-cell-editor';
import { PivotTableCellEditorCreator } from './cell-editor/pivot-table-cell-editor-creator';
import { PivotTableDefaultCellEditor } from './cell-editor/pivot-table-default-cell-editor';
import { PivotTableFormulaCellEditor } from './cell-editor/pivot-table-formula-cell-editor';

const innerElementHTML = (editor: string): string => `
${editor}
<div class="quantity-take-off-report-pivot-table-cell-edit__btn revert-value" title="Lose all changes">Revert</div>`;

interface QtoReportPivotTableCellEditParams extends Ag.ICellEditorParams {
  propertyGetter: (params: TreeTableValueGetterParams) => QtoTreeRowProperty;
}

export class QtoReportPivotTableCellEdit
implements Ag.IComponent<QtoReportPivotTableCellEditParams>, Ag.ICellEditorComp {
  private eGui: HTMLDivElement;
  private eRevertValueButton: HTMLDivElement;
  private params: QtoReportPivotTableCellEditParams;
  private cellEditor: CombineCellEditor<QtoTreeRowProperty, PivotTableDefaultCellEditor, PivotTableFormulaCellEditor>;

  public init(params: QtoReportPivotTableCellEditParams): void {
    this.params = params;
    this.eGui = document.createElement('div');
    this.eGui.className = 'quantity-take-off-report-pivot-table-cell-edit';

    this.renderInnerHTML();
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public destroy(): void {
    this.destroyEvents();
  }

  @autobind
  public getValue(): any {
    return this.cellEditor.getValue();
  }

  public afterGuiAttached(): void {
    this.cellEditor.setFocus();
  }

  private renderInnerHTML(): void {
    const property = this.getProperty();

    const innerHTML = PropertyHelper.isFormula(property)
      ? innerElementHTML(PivotTableFormulaCellEditor.getInnerHtml())
      : innerElementHTML(PivotTableDefaultCellEditor.getInnerHtml());
    this.destroyEvents();

    this.eGui.innerHTML = innerHTML;
    this.eRevertValueButton = this.eGui.querySelector('.revert-value');
    this.cellEditor = new CombineCellEditor(
      this.params,
      this.eGui,
      new PivotTableCellEditorCreator(),
      this.getProperty,
    );

    this.setValues();
    this.initEvents();
  }

  @autobind
  private getProperty(): QtoTreeRowProperty {
    const parent = this.params.node.parent;
    const colId = this.params.column.getColId();
    const { columnId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(this.params.node.data.id);
    const id = QtoReportPivotTableIdHelper.getPivotRowId(columnId, colId);
    return parent.data.properties[id];
  }

  private destroyEvents(): void {
    if (this.cellEditor) {
      this.cellEditor.destroyEvents();
    }
    if (this.eRevertValueButton) {
      this.eRevertValueButton.addEventListener('click', this.revertValue);
    }
  }

  private setValues(): void {
    this.cellEditor.setValue();
  }

  private initEvents(): void {
    this.cellEditor.initEvents();
    this.eGui.addEventListener('focusout', this.stopEditing);
    this.eRevertValueButton.addEventListener('click', this.revertValue);
  }

  @autobind
  private stopEditing(event: FocusEvent): void {
    if (!event.relatedTarget) {
      this.params.api.stopEditing(true);
    }
  }

  @autobind
  private revertValue(): void {
    this.cellEditor.revertValue();
  }
}
