import { CommentaryThread, Message } from '../interfaces';

function getLastUpdateTime(comments: Message[] | CommentaryThread[]): number {
  let lastTime;
  if (comments?.length) {
    lastTime = 0;
    for (const comment of comments) {
      const time = new Date(comment.editedAt || comment.createdAt).getTime();
      const messagesTime = getLastUpdateTime((comment as CommentaryThread).messages);
      lastTime = Math.max(time, messagesTime || 0, lastTime);
    }
  }
  return lastTime;
}

export const SyncingUtils = {
  getLastUpdateTime,
};
