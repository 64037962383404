import { DatabaseTableColumn } from '../database-table/interfaces';

export class ResourceTableColumnProvider {


  public static getPlantsColumns(currencySymbol: string): Array<DatabaseTableColumn<any>> {
    return ([
      {
        name: 'Name',
        key: 'name',
        className: 'database-table__column--name',
      },
      {
        name: 'Shift',
        key: 'siftHours',
        className: 'database-table__column database-table__column--shift-hours',
      },
      {
        name: 'Cost per hour',
        key: 'costPerHour',
        subText: currencySymbol,
        className: 'database-table__column database-table__column--cost-per-hour',
      },
      {
        name: 'Cost per shift',
        key: 'costPerShift',
        subText: currencySymbol,
        className: 'database-table__column database-table__column--cost-per-shift',
      },
      {
        name: 'Rent time',
        key: 'rentTime',
        className: 'database-table__column database-table__column--rent-time',
        info: 'The maximum reasonable downtime of the plant',
      },
      {
        name: 'Main contractor`s plant',
        key: 'isMainContractor',
        className: 'database-table__column database-table__column--main-contractors-plant',
        info: 'Affects preliminaries',
      },
    ]);
  }
  public static getMaterialsColumns(currencySymbol: string): Array<DatabaseTableColumn<any>> {
    return ([
      {
        name: 'Name',
        key: 'name',
        className: 'database-table__column--name',
      },
      {
        name: 'Unit',
        key: 'defaultUnit',
        className: 'database-table__column database-table__column--default-unit',
      },
      {
        name: 'Cost per unit',
        key: 'costPerUnit',
        subText: currencySymbol,
        className: 'database-table__column database-table__column--cost-per-unit',
      },
      {
        name: 'Package',
        key: 'package',
        className: 'database-table__column database-table__column--package',
        info: 'Material quantity per sales unit',
      },
      {
        name: 'Cost per package',
        key: 'costPerPackage',
        subText: currencySymbol,
        className: 'database-table__column database-table__column--cost-per-package',
      },
    ]);
  }

  public static getLaborsColumns(currencySymbol: string): Array<DatabaseTableColumn<any>> {
    return ([
      {
        name: 'Name',
        key: 'skill',
        className: 'database-table__column--name',
      },
      {
        name: 'Cost per hour',
        key: 'costPerHour',
        subText: currencySymbol,
        className: 'database-table__column database-table__column--cost-per-hour',
      },
    ]);
  }
}
