import * as Ag from 'ag-grid-community';
import React from 'react';

export const useSelectedNodeEffect = (gridApi?: Ag.GridApi, id?: string): void => {
  React.useEffect(() => {
    if (gridApi && id) {
      const node = gridApi.getRowNode(id);
      if (node) {
        gridApi.selectNode(node, true, true);
      }
    }
  }, [gridApi, id]);
};
