import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { DrawingsAnnotationLegendActions } from 'common/components/drawings';
import { Styled } from './styled';

interface Props {
  instancesIds: string[];
}

export const AggregationGroup: React.FC<Props> = ({
  instancesIds,
  children,
}) => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    dispatch(DrawingsAnnotationLegendActions.updateSelection({ instanceIds: instancesIds }));
  }, [dispatch, instancesIds]);

  return (
    <Styled.Container onClick={onClick}>
      {children}
      <Styled.Count>
        {instancesIds.length}
      </Styled.Count>
    </Styled.Container>
  );
};
