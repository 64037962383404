function assignState(state: any, newState: any = {}): Object {
  return { ...state, ...newState }
}

function updateInByPath(state: any, path: any, callback: Function): any {
  const ret = state;
  const p = path.splice(0, 1)
  if (path.length > 0) {
    ret[p] = updateInByPath(ret[p], path, callback);
  } else {
    ret[p] = callback(ret[p]);
  }

  return ret;
}


function setInByPath(state: any, path: any, value: any): any {
  const ret = state;
  const p = path.splice(0, 1)
  if (path.length > 0) {
    ret[p] = setInByPath(ret[p], path, value);
  } else {
    ret[p] = value
  }
  return ret;
}

function deleteInByPath(state: any, path: any): any {
  const ret = state;
  const p = path.splice(0, 1)
  if (path.length > 0) {
    ret[p] = deleteInByPath(ret[p], path);
  } else {
    if (ret instanceof Array) {
      ret.splice(p, 1);
    } else {
      delete ret[p];
    }
  }
  return ret;
}

export {
  assignState,
  updateInByPath,
  setInByPath,
  deleteInByPath
}