import { AccordionStyled, DropDownStyled, H5 } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { CompaniesSelectWithThemeStyled } from 'common/components/companies-select-with-theme';
import { PageHeaderStyled } from 'common/components/page-header';
import { PageHeaderAvatarStyled } from 'common/components/page-header-avatar';
import { TRANSITION_POINT } from '../../../../constants';
import { MenuSelectProductStyled } from '../../../../units/2d-navigation/menu-select-product';
import { SubscriptionListingStyled } from '../../components/subscription-listing';
import {
  SubscriptionCustomOfferCardStyled,
} from '../../components/subscription-listing/subscription-custom-offer-card';
import { SubscriptionListingHeadStyled } from '../../components/subscription-listing/subscription-listing-head';
import { SubscriptionOfferCardStyled } from '../../components/subscription-listing/subscription-offer-card';

const HEADER_HEIGHT = 60;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.color.background};

  *::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  *::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }

  ${CompaniesSelectWithThemeStyled.Container} {
    position: absolute;
    top: 0;
    left: 165px;
    height: auto;
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    ${CompaniesSelectWithThemeStyled.Container} {
      position: static;
      width: 100%;
      border: 1px solid ${props => props.theme.color.background};

      > div {
        border-radius: 0;
        height: 50px;
      }
    }
    
    ${DropDownStyled.DropDownMenuContainer} {
      top: 50px;
      height: 50px;
    }

    ${PageHeaderAvatarStyled.AvatarContainer} {
      width: 30px;
      height: 30px;

      > div {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        > p {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
`;

const Header = styled.div`
  height: ${HEADER_HEIGHT}px;

  ${MenuSelectProductStyled.Container} {
    left: 10px;
    top: 80px;
  }

  ${PageHeaderStyled.Container} {
    height: ${HEADER_HEIGHT}px;

    > a {
      padding: 10px 20px;
    }
  }

  ${PageHeaderStyled.UserMenu} {
    height: ${HEADER_HEIGHT}px;
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    height: 50px;

    ${PageHeaderStyled.Container} {
      height: 50px;
    }
    ${PageHeaderStyled.UserMenu} {
      height: 50px;
    }
    ${PageHeaderStyled.IconContainer} {
      svg {
        height: 30px;
      }
    }

    ${MenuSelectProductStyled.Container} {
      top: 50px;
    }
  }
`;

const Main = styled.div`
  box-sizing: border-box;
  overflow: overlay;
  height: calc(100% - ${HEADER_HEIGHT}px);
  padding: 40px 20px;
  scroll-behavior: smooth;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    padding: 20px 10px;
    height: calc(100% - 50px);

    ${SubscriptionListingHeadStyled.Container} {
      ${H5} {
        font-size: 16px !important;
        line-height: 24px;
      }
    }

    ${SubscriptionListingStyled.SubscriptionFeaturesWrapper} {
      display: none;
    }

    ${SubscriptionListingStyled.Offers} {
      grid-template-columns: 1fr;
      max-height: fit-content;
    }

    ${SubscriptionListingHeadStyled.Period} {
      margin-top: 20px;
    }

    ${SubscriptionOfferCardStyled.Container} {
      border-top: 1px solid ${p => p.theme.color.background};
    }

    ${SubscriptionCustomOfferCardStyled.Container} {
      border-top: 1px solid ${p => p.theme.color.background};
    }

    ${SubscriptionCustomOfferCardStyled.Description} {
      width: 100%;
    }

    ${AccordionStyled.BlockHeader} {
      padding: 15px;
    }

    ${AccordionStyled.Container} {
      > h4 {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
`;

const ScrollTopContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  > button {
    > svg {
      transform: rotate(90deg);
    }
  }
`;


export const Styled = {
  Container,
  Header,
  Main,
  ScrollTopContainer,
};
