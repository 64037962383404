import { State } from 'common/interfaces/state';

export const Selectors = {
  selectProperties: (s: State) => s.twoDDatabase.properties,
  selectItems: (s: State) => s.twoDDatabase.items,
  selectItemGroups: (s: State) => s.twoDDatabase.itemsGroups,
  selectItemsGroupsMap: (s: State) => new Map(s.twoDDatabase.itemsGroups.map(g => [g.id, g])),
  selectAssemblies: (s: State) => s.twoDDatabase.assemblies,
  selectAssemblyGroups: (s: State) => s.twoDDatabase.assemblyGroups,
  selectAssemblyGroupsMap: (s: State) => new Map(s.twoDDatabase.assemblyGroups.map(g => [g.id, g])),
  selectItemOverride: (s: State) => s.twoDDatabase.baseItemOverride,
};
