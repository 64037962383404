import { reset, startSubmit, stopSubmit } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { getGetErrorModelFromValidationResponse } from 'common/utils/redux-form-utils';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { NotificationActions } from '../../../units/notifications/actions';
import { AlertType } from '../../../units/notifications/alert-type';
import { DatabaseActivityListingActions } from '../actions/creators/database-activity-listing';
import { DatabaseActivityVariantActions } from '../actions/creators/database-activity-variant';
import {
  CreateActivityVariantPayload,
  DeleteActivityVariantPayload,
  DuplicateActivityVariantPayload,
  EditActivityVariantPayload,
  LoadActivityVariantPayload,
} from '../actions/payloads/database-activity-variant';
import { DatabaseActivityVariantActionTypes } from '../actions/types/database-activity-variant';
import { ActivityVariantsApi } from '../api/activity-variants';
import { ActivityVariantModel } from '../interfaces/data';
import { RestFormMapper } from '../utils/rest-form-mapper';


function* loadActivityVariant(action: ActionWith<LoadActivityVariantPayload>): SagaIterator {
  try {
    const { databaseId, activityId, activityVariantId } = action.payload;
    const result = yield call(ActivityVariantsApi.getActivityVariant, databaseId, activityId, activityVariantId);
    yield put(DatabaseActivityVariantActions.loadActivityVariantResponse(result));
  } catch (error) {
    console.error('database activity variant: load activity variant failed', error, action.payload);
  }
}

function* createActivityVariant(action: ActionWith<CreateActivityVariantPayload>): SagaIterator {
  try {
    const { databaseId, activityId, model, formId } = action.payload;
    yield put(startSubmit(formId));
    try {
      const result = yield call(ActivityVariantsApi.createActivityVariant, databaseId, activityId, model);
      yield put(reset(formId));
      yield put(stopSubmit(formId));
      yield put(DatabaseActivityVariantActions.createActivityVariantResponse(result));
      yield put(DatabaseActivityListingActions.closeEditPanel());
    } catch (error) {
      yield put(stopSubmit(formId, getGetErrorModelFromValidationResponse(error)));
    }
  } catch (error) {
    console.error('database activity variant: create activity variant failed', error, action.payload);
  }
}

function* updateActivityVariant(action: ActionWith<EditActivityVariantPayload>): SagaIterator {
  try {
    const { databaseId, activityId, activityVariantId, model, formId } = action.payload;
    try {
      const result = yield call(
        ActivityVariantsApi.updateActivityVariant, databaseId, activityId, activityVariantId, model,
      );
      yield put(reset(formId));
      yield put(stopSubmit(formId));
      yield put(DatabaseActivityVariantActions.updateActivityVariantResponse(result));
      yield put(DatabaseActivityListingActions.closeEditPanel());
    } catch (error) {
      yield put(stopSubmit(formId, getGetErrorModelFromValidationResponse(error)));
    }
  } catch (error) {
    console.error('database activity variant: update activity variant failed', error, action.payload);
  }
}

function* deleteActivityVariant(action: ActionWith<DeleteActivityVariantPayload>): SagaIterator {
  try {
    const { databaseId, activityId, activityVariantId } = action.payload;
    const result = yield call(ActivityVariantsApi.deleteActivityVariant, databaseId, activityId, activityVariantId);
    yield put(DatabaseActivityVariantActions.deleteActivityVariantResponse(result));
  } catch (error) {
    yield put(NotificationActions.addAlert({ message: error.message, alertType: AlertType.Error }));
    console.error('database activity variant: delete activity variant failed', error, action.payload);
  }
}

function* duplicateActivityVariant(action: ActionWith<DuplicateActivityVariantPayload>): SagaIterator {
  try {
    const { databaseId, activityId, activityVariantId } = action.payload;
    const model: ActivityVariantModel = yield call(
      ActivityVariantsApi.getActivityVariant, databaseId, activityId, activityVariantId,
    );
    if (model) {
      model.id = 0;
      model.name = `Copy of ${model.name}`;
      model.constraints = model.constraints.map(x => ({ ...x, id: 0 }));
      model.materials = model.materials.map(x => ({ ...x, id: 0 }));
      const functions = yield selectWrapper(x => x.database.currentDatabase.functions);
      const form = RestFormMapper.GetActivityVariantForm(model, functions);
      try {
        const result = yield call(ActivityVariantsApi.createActivityVariant, databaseId, activityId, form);
        yield put(DatabaseActivityVariantActions.createActivityVariantResponse(result));
      } catch (error) {
        yield put(NotificationActions.addAlert({ message: `Can't create ${form.name}`, alertType: AlertType.Error }));
      }
    }
  } catch (error) {
    console.error('database activity variant: duplicate activity variant failed', error, action.payload);
  }
}


export function* databaseActivityVariantSagas(): SagaIterator {
  yield takeLatest(DatabaseActivityVariantActionTypes.GET_ACTIVITY_VARIANT_REQUEST, loadActivityVariant);
  yield takeLatest(DatabaseActivityVariantActionTypes.UPDATE_ACTIVITY_VARIANT_REQUEST, updateActivityVariant);
  yield takeLatest(DatabaseActivityVariantActionTypes.CREATE_ACTIVITY_VARIANT_REQUEST, createActivityVariant);
  yield takeLatest(DatabaseActivityVariantActionTypes.DELETE_ACTIVITY_VARIANT_REQUEST, deleteActivityVariant);
  yield takeLatest(DatabaseActivityVariantActionTypes.DUPLICATE_ACTIVITY_VARIANT, duplicateActivityVariant);
}

