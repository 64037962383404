import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { getCorrectedOffset } from './get-corrected-offset';
import { getPaneSizeDiffs } from './get-pane-size-diff';
import { PaneConfig, Rect } from './interfaces';
import { Pane } from './pane';
import { PrimaryPaneResizeOptionType } from './resize-options';
import { Separator } from './separator';
import { Styled } from './styled';

export interface SpliterPaneConfig {
  size: number;
  minSize: number;
  separatorClassName?: string;
  maxSize?: number;
}

interface Props {
  customClassName?: string;
  primaryPaneCollapseToggleEnable?: boolean;
  horizontal?: boolean;
  percentage?: boolean;
  primaryIndex?: number;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onSecondaryPaneSizeChange?: (width: number) => void;
  onPrimaryPaneSizeChange?: (width: number) => void;
  children: JSX.Element[];
  onResize?: (_width: number, height: number) => void;
  separatorClassName?: string;
  separatorHide?: boolean;
  paneConfigs: SpliterPaneConfig[];
  resizingDetectorType?: PrimaryPaneResizeOptionType;
  isCollapseOpen?: boolean;
  onClickCollapseExpandButton?: () => void;
  showCollapseExpandButton?: boolean;
}

interface State {
  resizing: boolean;
  splitterIndex?: number;
  panesSize: number[];
  isPrimaryPaneCollapse: boolean;
}

const clearSelection = (): void => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges();
    }
  } else if (document.getSelection()) {
    document.getSelection().empty();
  }
};

export const DEFAULT_SPLITTER_SIZE = 4;

export class SplitterLayout extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    customClassName: '',
    horizontal: false,
    percentage: false,
    primaryIndex: 0,
    onDragStart: null,
    onDragEnd: null,
    onSecondaryPaneSizeChange: null,
    onPrimaryPaneSizeChange: null,
    children: [],
  };

  private panes: Array<React.RefObject<HTMLDivElement>>;

  private container: HTMLDivElement;
  private body: HTMLBodyElement;
  private splitter: HTMLDivElement;
  private minTotalSize: number = 0;

  constructor(props: Props) {
    super(props);

    this.panes = props.children.map(() => React.createRef<HTMLDivElement>());
    const panesSize = props.paneConfigs.map(c => {
      this.minTotalSize += c.minSize;
      return c.size;
    });

    this.state = {
      resizing: false,
      panesSize,
      isPrimaryPaneCollapse: false,
    };
  }

  public componentDidMount(): void {
    document.addEventListener('mouseup', this.handleMouseUp);
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (prevState.panesSize !== this.state.panesSize && this.props.onSecondaryPaneSizeChange) {
      const secondarySize = this.getSecondarySize();
      this.props.onSecondaryPaneSizeChange(secondarySize);
    }
    if (prevState.panesSize !== this.state.panesSize && this.props.onPrimaryPaneSizeChange) {
      const primarySize = this.getPrimarySize();
      this.props.onPrimaryPaneSizeChange(primarySize);
    }
    if (prevState.resizing !== this.state.resizing) {
      if (this.state.resizing) {
        if (this.props.onDragStart) {
          this.props.onDragStart();
        }
      } else if (this.props.onDragEnd) {
        this.props.onDragEnd();
      }
    }

    if (prevProps.paneConfigs !== this.props.paneConfigs) {
      this.setState({ panesSize: this.props.paneConfigs.map(c => c.size) });
    }
  }

  public componentWillUnmount(): void {
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  public render(): React.ReactNode {
    const containerClasses = classNames(
      'splitter-layout',
      this.props.customClassName,
      {
        'splitter-layout-vertical': this.props.horizontal,
        'layout-changing': this.state.resizing,
        'splitter-layout--primary-collapse': this.state.isPrimaryPaneCollapse,
        'splitter-layout--separator-hide': this.props.separatorHide,
      },
    );

    const children = React.Children.toArray(this.props.children);
    if (children.length === 0) {
      children.push(<div />);
    }
    const wrappedChildren = [];
    for (let i = 0; i < children.length; ++i) {
      let size = null;
      size = this.state.panesSize[i];
      const minSize = this.props.paneConfigs[i]?.minSize;
      wrappedChildren.push(
        <Pane
          vertical={this.props.horizontal}
          percentage={this.props.percentage}
          size={size}
          childRef={this.panes[i]}
          key={i}
          minSize={minSize}
        >
          {children[i]}
        </Pane>,
      );
    }

    const separatorLength = this.props.horizontal
      ? this.container?.getBoundingClientRect().width
      : this.container?.getBoundingClientRect().height;

    return (
      <Styled.Container
        className={containerClasses}
        ref={this.saveContainerRef}
        isHorizontal={this.props.horizontal}
        showCollapseExpandButton={this.props.showCollapseExpandButton}
        separatorLength={separatorLength}
        isCollapseOpen={this.props.isCollapseOpen}
      >
        <ReactResizeDetector
          handleHeight={true}
          handleWidth={true}
          onResize={this.handleResize}
        />
        {wrappedChildren[0]}
        {this.renderChildren(wrappedChildren)}
      </Styled.Container>
    );
  }

  @autobind
  private renderChildren(wrappedChildren: any[]): React.ReactNode[] {
    const result = [];

    if (wrappedChildren.length > 1) {
      for (let i = 1; i < wrappedChildren.length; i++) {
        const separatorClassName = this.props.paneConfigs
          && this.props.paneConfigs[i]
          && this.props.paneConfigs[i].separatorClassName;
        result.push(
          <React.Fragment key={i}>
            <Separator
              className={separatorClassName}
              separatorIndex={i}
              saveSplitterRef={this.saveSplitterRef}
              onMouseDown={this.handleSplitterMouseDown}
              isToggleVisible={!this.props.horizontal && this.props.primaryPaneCollapseToggleEnable}
              onHorizontalToggle={this.onHorizontalToggle}
              onClickCollapseExpandButton={this.props.onClickCollapseExpandButton}
            />
            {wrappedChildren[i]}
          </React.Fragment>,
        );
      }
    }

    return result;
  }

  @autobind
  private saveContainerRef(ref: HTMLDivElement): void {
    this.container = ref;
  }

  @autobind
  private getPrimaryIndex(): number {
    return this.props.primaryIndex === 0 ? 0 : 1;
  }

  @autobind
  private getSecondaryIndex(): number {
    return this.props.primaryIndex === 0 ? 1 : 0;
  }

  @autobind
  private getPrimarySize(): number {
    const primaryIndex = this.getPrimaryIndex();
    return this.state.panesSize[primaryIndex];
  }

  @autobind
  private getSecondarySize(): number {
    const secondaryIndex = this.getSecondaryIndex();
    return this.state.panesSize[secondaryIndex];
  }

  @autobind
  private onHorizontalToggle(): void {
    const panesSize = [...this.state.panesSize];
    const totalSize = panesSize.reduce((sum, size) => sum + size, 0);
    if (this.state.isPrimaryPaneCollapse) {
      panesSize[0] = this.props.paneConfigs[0].minSize;
      panesSize[1] = totalSize - this.props.paneConfigs[0].minSize;
    } else {
      panesSize[0] = 0;
      panesSize[1] = totalSize;
    }
    this.setState((s) => ({ panesSize, isPrimaryPaneCollapse: !s.isPrimaryPaneCollapse }));
  }

  @autobind
  private saveSplitterRef(ref: HTMLDivElement): void {
    this.splitter = ref;
  }

  @autobind
  private handleResize(): void {
    if (this.props.resizingDetectorType === PrimaryPaneResizeOptionType.CalculateSizeIndependently) {
      this.setState({ panesSize: this.getPanesSizeOnResizeIndependently(this.state.panesSize) });
    } else {
      this.setState({ panesSize: this.getPanesSizeOnResize(this.state.panesSize) });
    }
  }

  private getPanesSizeOnResize(defaultPanesSize: number[]): number[] {
    if (this.splitter && !this.state.isPrimaryPaneCollapse && !this.props.separatorClassName) {
      const containerRect = this.container.getBoundingClientRect();
      const currentSize = this.props.horizontal
        ? containerRect.height
        : containerRect.width;
      const prevSize = defaultPanesSize.reduce((result, size) => result + size, 0);
      const ratio = currentSize / prevSize;
      const panesSize = defaultPanesSize.map((size, index) => {
        const newSize = size * ratio;
        const config = this.props.paneConfigs[index];
        if (newSize < config.minSize) {
          return config.minSize;
        }
        if (config.maxSize && newSize > config.maxSize) {
          return config.maxSize;
        }
        return newSize;
      });
      return panesSize;
    }
    return defaultPanesSize;
  }

  private getPanesSizeOnResizeIndependently(defaultPanesSize: number[]): number[] {
    const { primaryIndex, separatorClassName, paneConfigs, separatorHide } = this.props;

    if (this.splitter && !this.state.isPrimaryPaneCollapse && !separatorClassName) {
      const containerRect = this.container.getBoundingClientRect();
      const secondaryIndex = this.getSecondaryIndex();
      const secondarySize = containerRect.width - defaultPanesSize[primaryIndex];
      const primarySize = defaultPanesSize[primaryIndex];
      const paneSize = [];

      paneSize[primaryIndex] = primarySize;
      paneSize[secondaryIndex] = secondarySize;

      if (primarySize < paneConfigs[primaryIndex].minSize) {
        paneSize[primaryIndex] = paneConfigs[primaryIndex].minSize;
        paneSize[secondaryIndex] = containerRect.width - paneSize[primaryIndex];
      }
      if (secondarySize < paneConfigs[secondaryIndex].minSize) {
        paneSize[secondaryIndex] = paneConfigs[secondaryIndex].minSize;
        paneSize[primaryIndex] = containerRect.width - paneSize[secondaryIndex];
      }
      if (separatorHide) {
        paneSize[primaryIndex] = paneConfigs[primaryIndex].minSize;
      }
      return paneSize;
    }
    return defaultPanesSize;
  }

  @autobind
  private handleMouseMove(e: MouseEvent): void {
    if (this.state.resizing) {
      const containerSize = this.getContainerSize();
      if (containerSize < this.minTotalSize) {
        return;
      }

      const paneRect = this.panes[this.state.splitterIndex];
      const clientRect = {
        left: e.clientX,
        top: e.clientY,
      };
      const clientOffset = this.getClientOffset(paneRect.current.getBoundingClientRect(), clientRect);
      const panesSize = this.getPanesSize(clientOffset);
      this.setState({ panesSize });
    }
  }

  private getContainerSize(): number {
    const rect = this.container.getBoundingClientRect();
    return this.props.horizontal
      ? rect.height
      : rect.width;
  }

  private getPanesSize(clientOffset: number): number[] {
    const panesSize = this.state.panesSize;
    const panesSizeDiffs = this.getPaneSizeDiffs(clientOffset);
    return panesSize.map((paneSize, index) => {
      const diff = panesSizeDiffs[index];
      return paneSize + diff;
    });
  }

  private getPaneSizeDiffs(clientOffset: number): number[] {
    const selectPaneIndex = this.state.splitterIndex;
    const panes = this.getPanes();
    const partBefore = panes.slice(0, selectPaneIndex).reverse();
    const partAfter = panes.slice(selectPaneIndex);
    if (this.isMoveUp(clientOffset)) {
      const correctedOffset = getCorrectedOffset(Math.abs(clientOffset), partAfter, partBefore);
      const diffs = getPaneSizeDiffs(correctedOffset, partAfter, partBefore);
      return diffs.decreaseDiffs.reverse().concat(diffs.increaseDiffs);
    } else {
      const correctedOffset = getCorrectedOffset(Math.abs(clientOffset), partBefore, partAfter);
      const diffs = getPaneSizeDiffs(correctedOffset, partBefore, partAfter);
      return diffs.increaseDiffs.reverse().concat(diffs.decreaseDiffs);
    }
  }

  private getPanes(): PaneConfig[] {
    return this.props.paneConfigs.map((pane, index) => {
      const paneSize = this.state.panesSize[index];
      return {
        size: paneSize,
        maxSize: pane.maxSize,
        minSize: pane.minSize,
      };
    });
  }

  private getClientOffset(
    paneRect: Rect,
    clientPosition: { left: number, top: number },
  ): number {
    return this.props.horizontal
      ? paneRect.top - clientPosition.top + DEFAULT_SPLITTER_SIZE
      : paneRect.left - clientPosition.left + DEFAULT_SPLITTER_SIZE;
  }

  private isMoveUp(offset: number): boolean {
    return offset > 0;
  }

  @autobind
  private handleSplitterMouseDown(index: number): void {
    clearSelection();
    this.body = document.querySelector('body');
    this.body.className = 'text-select-disable';
    this.setState({ resizing: true, splitterIndex: index });
  }

  @autobind
  private handleMouseUp(): void {
    if (this.body) {
      this.body.className = '';
    }
    this.setState({ resizing: false });
  }
}
