import styled from 'styled-components';

const Container = styled.div`
  overflow: hidden;
  word-wrap: normal;
  text-overflow: ellipsis;
  margin-bottom: 10px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  div {
    flex-shrink: 0;
    margin-right: 15px;
  }
`;

const TextInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
`;

export const Styled = {
  Container,
  UserInfo,
  TextInfo,
};
