import styled from 'styled-components';

const Container = styled.div<{ disabled: boolean }>`
  width: 100%;
  pointer-events: ${p => p.disabled ? 'none' : 'auto'};
`;

const ArrowContainer = styled.div<{ disabled: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.color.pale};
  border-radius: 5px;
  z-index: 1;

  svg {
    transition: all ${p => p.theme.duration.s} linear;
    width: 10px;
    height: 10px;
    fill: ${p => p.disabled ? p.theme.color.disabled : p.theme.color.gray};
  }
`;

const SectionMenuContainer = styled.div`
  width: 100%;
  padding: 10px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 20px;
  box-sizing: border-box;
  background-color: ${p => p.theme.color.backgroundRush};
  cursor: pointer;

  :hover {
    background-color: ${p => p.theme.color.pale};

    ${ArrowContainer} {
      background-color: ${p => p.theme.color.background};

      svg {
        fill: ${p => p.theme.color.turquoise};
      }
    }
  }
`;


export const Styled = {
  Container,
  SectionMenuContainer,
  ArrowContainer,
};
