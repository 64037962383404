const prefix = '@drawings-calibrate';

export const CalibrateActionTypes = {
  DROP_STATE: `${prefix}/DROP_STATE`,
  START_EDIT: `${prefix}/START_EDIT`,
  CHANGE_SCALE: `${prefix}/CHANGE_SCALE`,
  SET_ORIGINAL_LINE_LENGTH: `${prefix}/SET_ORIGINAL_LINE_LENGTH`,
  SET_PAGE_FORMAT: `${prefix}/SET_PAGE_FORMAT`,
  ON_CALIBRATE_LINE_DRAWN: `${prefix}/ON_CALIBRATE_LINE_DRAWN`,
};
