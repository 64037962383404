import styled from 'styled-components';

import { BadgeStyled } from '../badge';


const Container = styled.div<{ hasIcon: boolean }>`
  display: grid;
  grid-template-columns: ${p => p.hasIcon ? '20px 1fr' : '1fr'} auto;
  gap: 10px;
  align-items: center;
  max-width: fit-content;

  ${BadgeStyled.BadgeContainer} {
    > p {
      color: ${p => p.theme.color.white} !important;
    }
    svg {
      fill: ${p => p.theme.color.white} !important;
    }
  }
`;

const IconContainer = styled.div<{ disabled: boolean }>`
  width: 20px;
  height: 20px;

  svg {
    fill: ${p => p.disabled ? p.theme.color.disabled : p.theme.color.gray};
  }
`;

const TitleContainer = styled.div<{ disabled: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;

  > p {
    color: ${p => p.disabled && p.theme.color.disabled};
  }
`;


export const Styled = {
  Container,
  IconContainer,
  TitleContainer,
};
