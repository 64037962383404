import styled from 'styled-components';
import { TooltipStyled } from 'common/components/tooltip';

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  > div {
    width: 100%;
  }
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    fill: ${(p) => p.theme.color.gray};
  }

  ${TooltipStyled.TooltipElementWrapper} {
    margin-left: auto;
    height: 20px;
  }
`;

const SliderContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  grid-column-gap: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 30px auto;
`;

const ResultTitle = styled.div`
  position: relative;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-bottom: 5px;
`;

export const Styled = {
  Container,
  SliderContainer,
  Title,
  ResultTitle,
};
