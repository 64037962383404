import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './motivation-popup-settings.scss';

import { State } from 'common/interfaces/state';
import { KreoButton, MaterialNumberInput } from 'common/UIKit';
import { AccountActions } from '../../../units/account/actions/creators';

interface ReduxProps {
  timeBeforeOpening: number;
  timeBeforeClosing: number;
}

interface ReduxActions {
  setNewSettings: (timeBeforeOpening: number, timeBeforeClosing: number) => void;
  resetSettings: () => void;
}

interface Props extends ReduxProps, ReduxActions {

}

interface ComponentState {
  timeBeforeOpening: number;
  timeBeforeClosing: number;
}

class MotivationPopupSettingsComponent extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      timeBeforeOpening: this.props.timeBeforeOpening,
      timeBeforeClosing: this.props.timeBeforeClosing,
    };
  }
  public render(): JSX.Element {
    return (
      <div className='motivation-popup-settings'>
        <div className='motivation-popup-settings__title'>Motivation Popup Settings</div>
        <div className='motivation-popup-settings__fields'>
          <MaterialNumberInput
            className='motivation-popup-settings__input'
            valueType='integer'
            value={this.props.timeBeforeOpening}
            isFloatingLabel={true}
            label='Time before opening popup'
            onChange={this.onChangeBeforeOpeningValue}
          />
          <MaterialNumberInput
            className='motivation-popup-settings__input'
            valueType='integer'
            value={this.props.timeBeforeClosing}
            isFloatingLabel={true}
            label='Countdown before closing popup'
            onChange={this.onChangeBeforeClosingValue}
          />
        </div>
        <KreoButton
          mode='action'
          size='medium'
          caption='Set settings'
          onClick={this.setNewSettings}
        />
        <KreoButton
          mode='delete'
          size='medium'
          caption='Reset'
          onClick={this.props.resetSettings}
        />
      </div>
    );
  }

  @autobind
  private setNewSettings(): void {
    this.props.setNewSettings(this.state.timeBeforeOpening, this.state.timeBeforeClosing);
  }

  @autobind
  private onChangeBeforeOpeningValue(_event: React.ChangeEvent, newValue?: number): void {
    this.setState({ timeBeforeOpening: newValue });
  }
  @autobind
  private onChangeBeforeClosingValue(_event: React.ChangeEvent, newValue?: number): void {
    this.setState({ timeBeforeClosing: newValue });
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const motivationPopupSettings = state.account.motivationPopupSettings;

  return {
    timeBeforeOpening: motivationPopupSettings && motivationPopupSettings.timeInSecondsBeforeOpen,
    timeBeforeClosing: motivationPopupSettings && motivationPopupSettings.timeInSecondsBeforeClose,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setNewSettings: (timeBeforeOpening, timeBeforeClosing) => dispatch(
      AccountActions.setMotivationPopupSettings(timeBeforeOpening, timeBeforeClosing),
    ),
    resetSettings: ()  => dispatch(AccountActions.deleteMotivationPopupSettings()),
  };
};

export const MotivationPopupSettings = connect(mapStateToProps, mapDispatchToProps)(MotivationPopupSettingsComponent);
