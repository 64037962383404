import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './color-picker-control.scss';

import { ColorCircleDialog } from 'common/components/color-circle-dialog';
import { ElementTooltip, TooltipPosition } from 'common/components/element-tooltip';


interface Props {
  color: string;
  containerRef: HTMLDivElement;
  onChangeColor: (color: string) => void;
  onAssignColor?: () => void;
  onDefaultColor?: () => void;
  disabled: boolean;
}

interface State {
  isOpen: boolean;
}

export class ColorPickerControl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }


  public render(): React.ReactNode {
    return (
      <div className='drawings-color-picker' >
        <ElementTooltip
          className='drawings-color-picker__indicator-wrap'
          size='small'
          marginTop={10}
          tooltipPosition={TooltipPosition.CenterTop}
          targetElement={
            <div
              className={classNames(
                'drawings-color-picker__indicator',
                { 'drawings-color-picker__indicator--disabled': this.props.disabled },
              )}
              onClick={this.onOpenPopup}
              data-control-name='color-picker-button'
              style={{ backgroundColor: this.props.color }}
            />
          }
        >
          <div className='kreo-toolbar-button__tooltip'>
            {this.props.disabled ? 'Select any Tool and choose color for it' : null}
            <b>Colour</b>
          </div>
        </ElementTooltip>
        {this.props.containerRef && this.renderPopup()}
      </div>
    );
  }

  private renderPopup(): React.ReactNode {
    return ReactDOM.createPortal(
      (
        <CSSTransition
          in={this.state.isOpen && !this.props.disabled}
          mountOnEnter={true}
          unmountOnExit={true}
          timeout={200}
          className='color-circle-dialog'
        >
          <ColorCircleDialog
            color={this.props.color}
            onExitClick={this.onOpenPopup}
            onChangeColor={this.setColor}
            onAssignColor={this.props.onAssignColor}
            onDefaultColor={this.props.onDefaultColor}
          />
        </CSSTransition>
      ),
      this.props.containerRef,
    );
  }

  @autobind
  private onOpenPopup(): void {
    this.setState({ isOpen: !this.state.isOpen });
  }

  @autobind
  private setColor(newColor: string): void {
    this.props.onChangeColor(newColor);
  }
}
