import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { getOrCreateRoot } from 'common/UIKit';
import { Styled } from './styled';
import { Tooltip } from './tooltip';

const getElementPosition = (e: React.MouseEvent): DOMRect => e.currentTarget.getBoundingClientRect();


interface Props {
  id?: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  text?: string;
  speed?: 's' | 'm' | 'l' | 'xl';
  size?: 'small' | 'big';
  hotKey?: string;
  tooltipWidth?: number;
  wordBreakAll?: boolean;
  picture?: string;
  description?: string;
  tooltipOverflow?: string;
  isTranslucent?: boolean;
  childComponent?: React.ComponentType;
  disabled?: boolean;
  padding?: string;
}


interface State {
  targetElement: DOMRect;
  tooltip: boolean;
}

export class ElementTooltip extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { targetElement: null, tooltip: null };
  }

  public render(): React.ReactNode {
    const { disabled, children } = this.props;
    if (!children) {
      return null;
    }

    if (disabled) {
      return children;
    }

    return (
      <>
        <Styled.TooltipElementWrapper
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onClick={this.onMouseLeave}
        >
          {children}
        </Styled.TooltipElementWrapper>
        {this.state.tooltip && this.renderTooltip()}
      </>
    );
  }

  private renderTooltip(): React.ReactNode {
    const {
      id,
      position,
      tooltipWidth,
      speed,
      size,
      text,
      hotKey,
      picture,
      description,
      wordBreakAll,
      tooltipOverflow,
      isTranslucent,
      childComponent,
      padding,
    } = this.props;
    const targetElement = this.state.targetElement;
    return ReactDOM.createPortal(
      (
        <Tooltip
          id={id}
          targetTop={targetElement.top}
          targetBottom={targetElement.bottom}
          targetLeft={targetElement.left}
          targetRight={targetElement.right}
          targetWidth={targetElement.width}
          targetHeight={targetElement.height}
          position={position}
          tooltipWidth={tooltipWidth}
          wordBreakAll={wordBreakAll}
          size={size}
          text={text}
          speed={speed}
          hotKey={hotKey}
          picture={picture}
          description={description}
          tooltipOverflow={tooltipOverflow}
          isTranslucent={isTranslucent}
          childComponent={childComponent}
          padding={padding}
        />
      ),
      getOrCreateRoot(),
    );
  }

  @autobind
  private onMouseEnter(el: React.MouseEvent): void {
    this.setState({ targetElement: getElementPosition(el), tooltip: true });
  }

  @autobind
  private onMouseLeave(): void {
    this.setState({ tooltip: false });
  }
}
