import * as React from 'react';

import {
  ClassificationEngineContextAwareProps,
} from '../../interfaces/classification/classification-engine-context-aware-props';

export const ClassificationEngineContext =
  React.createContext<ClassificationEngineContextAwareProps>(null);


export class ClassificationEngineContextProvider extends React.PureComponent<ClassificationEngineContextAwareProps> {
  public render(): React.ReactNode {
    return (
      <ClassificationEngineContext.Provider value={this.props}>
        {this.props.children}
      </ClassificationEngineContext.Provider>
    );
  }
}
