import classNames from 'classnames';
import * as React from 'react';

import './validation-step-content.scss';


interface Props {
  width?: string | number;
  className?: string;
}

export const ValidationStepContent: React.FC<Props> = (props) => {
  const style: React.CSSProperties = {};

  if (props.width) {
    style.width = props.width;
  }

  return (
    <div className={classNames('validation-step-content', props.className)} style={style}>
      {props.children}
    </div>
  );
};
