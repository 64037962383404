import { ItemGroupFormData } from '../../assembly-panel/item-form-updater';

export const parseItemFormProperties = (itemGroupForm: Record<string, ItemGroupFormData>, result: string[]): void => {
  for (const { itemForm: { groupForm } } of Object.values(itemGroupForm)) {
    for (const { fields } of Object.values(groupForm)) {
      for (const { name } of Object.values(fields)) {
        result.push(name);
      }
    }
  }
};
