import React from 'react';
import { render } from 'react-dom';
import App from './App';

const rootEl = document.getElementById('root');

window.logging = process.env.NODE_ENV === 'development';

function renderApp() {
  render(
    <App />,
    rootEl
  );
}

// All modern browsers, except `Safari`, have implemented
// the `ECMAScript Internationalization API`.
// For that we need to patch in on runtime.
if (!global.Intl) {
  require.ensure(['intl'], (require: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    require('intl').default;
    renderApp();
  }, 'IntlBundle');
} else {
  renderApp();
}
