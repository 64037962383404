import * as React from 'react';

import { Item } from '../item-context-menu/menu-item-container';
import { RenderIf } from '../render-if';
import { AvatarContainer } from './avatar-container';
import { ErrorContainer } from './error-container';
import { MenuButton } from './menu-button';
import { Company, UserInfo } from './project-card';
import { ProjectNameContainer } from './projects-name-container';


interface ContentProps {
  projectName: string;
  menuItems: Item[];
  users: UserInfo[];
  url: string;
  sizeItem: string;
  menuWidth: number;
  isInaccessibleProject: boolean;
  isShareWithCompany: boolean;
  ErrorComponent?: React.ComponentType;
  company?: Company;
}

export const ContentCard: React.FC<ContentProps> = React.memo<ContentProps>(({
  company,
  isShareWithCompany,
  projectName,
  menuItems,
  users,
  url,
  sizeItem,
  menuWidth,
  isInaccessibleProject,
  ErrorComponent,
}) => {
  const error = !url;
  return (
    <>
      <AvatarContainer
        company={company}
        isShareWithCompany={isShareWithCompany}
        users={users}
        isTranslucent={isInaccessibleProject}
      />
      <RenderIf condition={error}>
        <ErrorContainer ErrorComponent={ErrorComponent} />
      </RenderIf>
      <MenuButton
        menuItems={menuItems}
        menuWidth={menuWidth}
        sizeItem={sizeItem}
        isTranslucent={isInaccessibleProject}
      />
      <ProjectNameContainer
        projectName={projectName}
        isTranslucent={isInaccessibleProject}
      />
    </>
  );
});
