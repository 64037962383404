import Ag from 'ag-grid-community';

export class TypeColumnCell implements Ag.ICellRendererComp {
  private eGui: HTMLDivElement;
  private params: Ag.ICellRendererParams;

  public refresh(): boolean {
    return false;
  }

  public getGui(): HTMLDivElement {
    this.eGui = document.createElement('div');

    if (this.params.data) {
      this.appendContent();
    }

    return this.eGui;
  }

  public init(params: Ag.ICellRendererParams): void | Ag.AgPromise<void> {
    this.params = params;
  }

  private appendContent(): void {
    this.eGui.style.height = '100%';
    const data = this.params.data;
    if (data.h_type_icon) {
      this.eGui.append(this.createIcon());
    }

    this.eGui.append(this.createValue());
  }

  private createIcon(): HTMLSpanElement {
    const container = document.createElement('span');
    container.style.paddingRight = '15px';
    container.innerHTML = this.params.data.h_type_icon;
    const svg = container.children[0] as HTMLElement;
    svg.style.height = '20px';
    svg.style.width = '20px';
    return container;
  }

  private createValue(): HTMLSpanElement {
    const container = document.createElement('span');
    container.innerText = this.params.value;
    return container;
  }
}
