import { CodecUtils } from 'common/utils/codec-utils';

export enum CustomElementFilterCollectionOperation {
  All = 'All',
  Any = 'Any',
}

export const CustomElementFilterCollectionOperationC =
  CodecUtils.createEnumCodec<CustomElementFilterCollectionOperation>(
    CustomElementFilterCollectionOperation,
    'CustomElementFilterCollectionOperation',
  );
