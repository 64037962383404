export enum DrawingsInstanceType {
  Line = 'line',
  Polyline = 'polyline',
  Polygon = 'polygon',
  Rectangle = 'rectangle',
  Angle = 'angle',
  Circle = 'circle',
  Count = 'count',
  CalibrationLine = 'calibrationLine',
  VisualSearchSourcePolygon = 'visualSearchSourcePolygon',
  VisualSearchAreaPolygon = 'visualSearchAreaPolygon',
  WizzardWorkingArea = 'wizzardAreaPolygon',
  WizzardSelectionArea = 'wizzardSelectionAreaPolygon',
  Segment = 'segment',
  PdfFilterArea = 'pdfFilterArea',
}


export const UtilityInstanceTypes = [
  DrawingsInstanceType.CalibrationLine,
  DrawingsInstanceType.VisualSearchSourcePolygon,
  DrawingsInstanceType.VisualSearchAreaPolygon,
  DrawingsInstanceType.WizzardWorkingArea,
  DrawingsInstanceType.WizzardSelectionArea,
  DrawingsInstanceType.PdfFilterArea,
];
