import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Item, Property, UpdateItemRequest } from 'unit-2d-database/interfaces';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { SidePanelContentProps } from '../../interfaces';
import { ItemPanel, OwnState } from '../item-panel/item-panel';

  interface DispatchProps {
  update: (payload: UpdateItemRequest) => void;
  create: (name: string, properties: Property[], iconType: string) => void;
}

interface Props extends SidePanelContentProps<Property, OwnState>, DispatchProps {}

const ItemPanelDatabaseComponent = (props: Props): JSX.Element => {
  const isShowRenameDialog = useCallback((items: Item[], data: Item, formName: string) => {
    return !!items.find(({ id, name }) => (!data || data.id !== id) &&  name === formName);
  }, []);
  return <ItemPanel {...props} isShowRenameDialog={isShowRenameDialog}/>;
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    update: payload => dispatch(TwoDDatabaseActions.sendUpdateItem(payload)),
    create: (name, properties, iconType) =>
      dispatch(TwoDDatabaseActions.createItemRequest({ name, properties, iconType })),
  };
};

export const ItemPanelDatabase = connect(
  null,
  mapDispatchToProps,
)(ItemPanelDatabaseComponent);
