export enum FormulaPartTypes {
  Function = 'function',
  FunctionOpen = 'functionOpen',
  FunctionClose = 'functionClose',
  MeasureFunction = 'measureFunction',
  RoundBracerScope = 'roundBracerScope',
  ScopeOpen = 'scopeOpen',
  ScopeClose = 'scopeClose',
  Operation = 'operation',
  Splitter = 'splitter',
  Constant = 'constant',
  Property = 'property',
  Spaces = 'space',
  FunctionSplitted = 'functionSplitted'
}
