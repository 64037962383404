import { ExcelFormulaHelper } from 'common/components/excel-table';
import { flatten } from './flatten';

export function COUNT(...args: Array<number | string | undefined>): number {
  const arr = flatten(args);
  const filteredArgs = arr.filter(
    (arg) => arg !== ExcelFormulaHelper.INVALID_VALUE
      && arg !== ''
      && (typeof arg === 'number' && !isNaN(Number(arg))),
  );
  return filteredArgs.length;
}
