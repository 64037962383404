import {
  ExtractorFunctionModel,
  ExtractorFunctionModelDropDown,
  ExtractorFunctionOption,
  ExtractorFunctionParameterOption,
  MeasurementModel,
  UnitModel,
} from '../interfaces/data';
import {
  ExtractorFunctionComparator,
  ExtractorFunctionComparatorProvider,
} from './extractor-function-comparator-provider';

const functionsWithImages = {
  ['Object.Volume']: true,
  ['Object.SurfaceArea']: true,
  ['Object.HorizontalSurfaceArea']: true,
  ['Object.VerticalSurfaceArea']: true,
  ['VerticalBB.Height']: true,
  ['VerticalBB.SideSurfaceArea']: true,
  ['VerticalBB.TopSurfaceArea']: true,
  ['VerticalBB.HorizontalPerimeter']: true,
  ['VerticalBB.SectionMaxBBDimension']: true,
  ['VerticalBB.SectionMinBBDimension']: true,
  ['VerticalBB.SectionMaxDimension']: true,
  ['VerticalBB.SectionMinDimension']: true,
  ['VerticalBB.SectionArea']: true,
  ['ObjectBB.Length']: true,
  ['ObjectBB.SideSurfaceArea']: true,
  ['ObjectBB.SectionMaxBBDimension']: true,
  ['ObjectBB.SectionMinBBDimension']: true,
  ['ObjectBB.SectionMaxDimension']: true,
  ['ObjectBB.SectionMinDimension']: true,
  ['SelfDimension.Length']: true,
  ['SelfDimension.SideSurfaceArea']: true,
  ['SelfDimension.TopSurfaceArea']: true,
  ['SelfDimension.BottomSurfaceArea']: true,
  ['SelfDimension.WideArea']: true,
  ['SelfDimension.WidePerimeter']: true,
  ['SelfDimension.SectionVerticalDimension']: true,
  ['SelfDimension.SectionHorizontalDimension']: true,
  ['SelfDimension.SectionMaxDimension']: true,
  ['SelfDimension.SectionMinDimension']: true,
  ['SelfDimension.SectionArea']: true,
  ['SelfDimension.Thickness']: true,
  ['SelfDimension.Girth']: true,
  ['SelfDimension.HorizontalRadius']: true,
  ['Stair.Length']: true,
  ['Stair.Width']: true,
  ['Stair.TreadWidth']: true,
  ['Stair.RiserHeight']: true,
  ['Stair.RiserWidth']: true,
  ['Stair.TreadThickness']: true,
  ['Stair.FormworkToSoffits']: true,
  ['Formwork.Horizontal']: true,
  ['Formwork.HorizontalWithOpenings']: true,
  ['Formwork.SlantedLess15']: true,
  ['Formwork.SlantedMore15']: true,
  ['Formwork.Vertical']: true,
  ['Formwork.VerticalWithOpenings']: true,
  ['Formwork.SlopedPerimeter']: true,
  ['Formwork.SlopedPerimeterWithOpenings']: true,
  ['Formwork.VerticalDoubleSide']: true,
};

export class ExtractorFunctionHelper {
  public static getExtractorFunction(
    functionId: string, functions: ExtractorFunctionModel[],
  ): ExtractorFunctionModel {
    return functions.find(x => x.id === functionId);
  }

  public static getMeasurementExtractorFunctions(
    functionId: string,
    functions: ExtractorFunctionModel[],
    measurementToUnits: Record<string, UnitModel[]>,
    measurementsMap: Record<string, MeasurementModel>,
  ): ExtractorFunctionOption[] {
    const comparator =  ExtractorFunctionComparatorProvider.measurementExtractorFunctionsComparator;

    return ExtractorFunctionHelper.getAvailableExtractorFunctions(
      functionId, functions, measurementToUnits, measurementsMap, x => x.enableForMeasurement, comparator);
  }

  public static getConstraintExtractorFunctions(
    functionId: string,
    functions: ExtractorFunctionModel[],
    measurementToUnits: Record<string, UnitModel[]>,
    measurementsMap: Record<string, MeasurementModel>,
  ): ExtractorFunctionOption[] {
    const comparator =  ExtractorFunctionComparatorProvider.constraintExtractorFunctionsComparator;

    return ExtractorFunctionHelper.getAvailableExtractorFunctions(
      functionId, functions, measurementToUnits, measurementsMap, x => x.enableForConstraint, comparator);
  }

  public static getExtractorFunctionUnits(
    extractorFunction: ExtractorFunctionModel, measurementToUnits: Record<string, UnitModel[]>,
  ): UnitModel[] {
    if (!extractorFunction) {
      return [];
    }

    return measurementToUnits[extractorFunction.measurement];
  }

  public static getExtractorFunctionParameters(
    extractor: ExtractorFunctionModel,
    extractors: ExtractorFunctionModel[],
    measurementToUnits: Record<string, UnitModel[]>,
    measurementsMap: Record<string, MeasurementModel>,
  ): ExtractorFunctionParameterOption[] {
    const getUitId = (id): number => {
      const extractorFunction = ExtractorFunctionHelper.getExtractorFunction(id, extractors);
      return ExtractorFunctionHelper.getDefaultUnitId(extractorFunction, measurementToUnits, measurementsMap);
    };

    return extractor
      ? extractor.extractorParameters.map(x => ({
        id: x.parameterExtractorFunctionId,
        defaultValue: x.value,
        unitId: getUitId(x.parameterExtractorFunctionId),
      }))
      : [];
  }

  public static getDefaultUnitId(
    extractor: ExtractorFunctionModel,
    measurementToUnits: Record<string, UnitModel[]>,
    measurementsMap: Record<string, MeasurementModel>,
  ): number {
    const unit = this.getDefaultUnit(extractor, measurementToUnits, measurementsMap);
    return unit && unit.id;
  }


  private static getDefaultUnit(
    extractor: ExtractorFunctionModel,
    measurementToUnits: Record<string, UnitModel[]>,
    measurementsMap: Record<string, MeasurementModel>,
  ): UnitModel {
    const measurement = measurementsMap[extractor.measurement];
    const metricUnit = measurementToUnits[extractor.measurement].find(x => x.acronym === measurement.metricUnit);
    if (metricUnit) {
      return metricUnit;
    }

    const defaultUnit = measurementToUnits[extractor.measurement].find(x => x.coefficient === 1);
    return defaultUnit;
  }

  private static getAvailableExtractorFunctions(
    functionId: string,
    functions: ExtractorFunctionModel[],
    measurementToUnits: Record<string, UnitModel[]>,
    measurementsMap: Record<string, MeasurementModel>,
    enableCondition: (x: ExtractorFunctionModel) => boolean,
    comparator: ExtractorFunctionComparator,
  ): ExtractorFunctionOption[] {
    const classNameToChildren: Record<string, ExtractorFunctionModelDropDown[]> = {};

    for (const extractor of functions) {
      const alreadySelected = extractor.id === functionId;
      const enableToSelect = enableCondition(extractor) && extractor.measurement in measurementToUnits;

      if (alreadySelected || enableToSelect) {
        const defaultUnit = ExtractorFunctionHelper.getDefaultUnit(extractor, measurementToUnits, measurementsMap);
        const defaultUnitAcronym = defaultUnit && defaultUnit.acronym;
        ExtractorFunctionHelper.appendChildrenIntoGroup(classNameToChildren, extractor, defaultUnitAcronym);
      }
    }

    return Object.keys(classNameToChildren)
      .sort(comparator.compareClasses)
      .map(className => ({
        value: className,
        name: className,
        children: classNameToChildren[className]
          .sort((a, b) => comparator.compareFunctions(a.value, b.value)),
      }));

  }

  private static appendChildrenIntoGroup(
    groupMap: Record<string, ExtractorFunctionModelDropDown[]>,
    extractor: ExtractorFunctionModel,
    defaultUnit: string,
  ): void {
    if (!groupMap[extractor.className]) {
      groupMap[extractor.className] = [];
    }

    groupMap[extractor.className].push({
      value: extractor.id,
      group: extractor.className,
      name: extractor.name,
      description: extractor.description,
      defaultUnit,
      withImage: functionsWithImages[extractor.id],
      isSelectable: true,
    });
  }
}
