import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { InitialFileSystemHighlightState } from 'common/components/drawings/constants';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { DrawingsPdfBrowserContent } from 'common/components/drawings/drawings-pdf-browser';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import {
  DrawingsFile,
  DrawingsFiles,
} from 'common/components/drawings/interfaces/drawings-file-info';
import { State } from 'common/interfaces/state';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { arrayUtils } from 'common/utils/array-utils';

interface StateProps {
  files: DrawingsFiles;
}


interface OwnProps {
  currentDrawings: DrawingsShortInfo;
  onSelectDrawing: (drawingId: string) => void;
  onCloseDialog: () => void;
}


interface Props extends StateProps, OwnProps {}

interface ComponentState {
  selectedFile: string;
}

class SelectPageToCompareDialogComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedFile: null,
    };
  }

  public render(): React.ReactNode {
    const files = this.getSelectedPdfFiles();
    return (
      <DialogWrapper name={DrawingDialogs.SELECT_COMPARE_PAGE}>
        <DrawingsPdfBrowserContent
          files={this.props.files}
          isLoading={false}
          canEdit={false}
          multiSelect={false}
          selectedPages={[]}
          selectedFiles={files}
          selectedFile={this.state.selectedFile}
          highlightState={InitialFileSystemHighlightState}
          onSelectFile={this.onSelectFile}
          getContextMenuItems={this.getContextMenuItems}
          onClose={this.props.onCloseDialog}
          onChangeDrawingSelection={this.onChangeDrawingSelection}
        />
      </DialogWrapper>
    );
  }

  private getContextMenuItems(): string[] {
    return [];
  }


  @autobind
  private onSelectFile(fileId: string): void {
    this.setState({ selectedFile: fileId });
  }

  @autobind
  private getSelectedPdfFiles(): DrawingsFile[] {
    const { pdfId, pageNumber: currentDrawingPageNumber } = this.props.currentDrawings;
    const files = new Array<DrawingsFile>();
    const onlyFiles = arrayUtils.filterIterator(
      Object.values(this.props.files.entities),
      x => !x.children) as Iterable<DrawingsFile>;
    for (const file of onlyFiles) {
      if (file.id === pdfId) {
        const pages = file.pages.filter(({ pageNumber }) => pageNumber !== currentDrawingPageNumber);
        if (pages.length) {
          files.push({
            ...file,
            pages,
          });
        }
      } else {
        files.push(file);
      }
    }
    return files;
  }

  @autobind
  private onChangeDrawingSelection(ids: string[], selected: boolean): void {
    if (selected) {
      this.props.onSelectDrawing(ids[0]);
      this.props.onCloseDialog();
    }
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    files: state.drawings.files,
  };
}


export const SelectPageToCompareDialog =
  connect(mapStateToProps)(SelectPageToCompareDialogComponent);
