import { FieldsProps } from 'redux-form';


import { ActivityResourceVariant, ActivityVariantResource } from '../../interfaces/data';
import { BaseResource, BaseResourceVariant } from '../../interfaces/resources-data';

type ResourceType = ActivityResourceVariant<BaseResourceVariant> | ActivityVariantResource<BaseResource>;

function isActivityResourceVariant(resource: ResourceType): resource is ActivityResourceVariant<BaseResourceVariant> {
  return (resource as ActivityResourceVariant<BaseResourceVariant>).resourceVariant !== undefined;
}

function isActivityVariantResource(resource: ResourceType): resource is ActivityVariantResource<BaseResource> {
  return (resource as ActivityVariantResource<BaseResourceVariant>).resource !== undefined;
}

const isResourcesSame = (a: ResourceType, b: ResourceType): boolean => {
  if (isActivityResourceVariant(a) && isActivityResourceVariant(b)) {
    return a.resourceVariant.id === b.resourceVariant.id;
  } else if (isActivityVariantResource(a) && isActivityVariantResource(b)) {
    return a.resource.id === b.resource.id;
  }
};

const changeResourcesField = <T extends ResourceType> (
  prevResources: T[],
  nextResources: T[],
  fields: FieldsProps<any>,
  onChange: (resources: T[]) => void,
): void => {
  prevResources.map((prev, index) => ({
    isRemoved: !nextResources.find(x => isResourcesSame(prev, x)),
    index,
  }))
    .filter(x => x.isRemoved)
    .forEach(x => {
      fields.remove(x.index);
    });
  onChange(nextResources);
};

export const ResourceFieldHelper = {
  changeResourcesField,
};
