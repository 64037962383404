import * as React from 'react';

import {
  WithMeasurementsEngineContextAwareProps,
} from '../../interfaces/measurements/with-measurements-engine-context-aware-props';

export const MeasurementsEngineLayoutApiContext =
  React.createContext<WithMeasurementsEngineContextAwareProps>(null);

export class MeasurementsEngineLayoutApiContextProvider
  extends React.Component<WithMeasurementsEngineContextAwareProps> {
  public render(): React.ReactNode {
    return (
      <MeasurementsEngineLayoutApiContext.Provider value={this.props}>
        {this.props.children}
      </MeasurementsEngineLayoutApiContext.Provider>
    );
  }
}
