const prefix = '@scenarios';

export const ScenariosActionTypes = {
  GET_INDIRECT_SETTINGS: `${prefix}/GET_INDIRECT_SETTINGS`,
  GET_INDIRECT_SETTINGS_SUCCEEDED: `${prefix}/GET_INDIRECT_SETTINGS_SUCCEEDED`,
  SET_INDIRECT_SETTINGS: `${prefix}/SET_INDIRECT_SETTINGS`,
  CHANGE_INDIRECT_SETTINGS_PARAMETER: `${prefix}/CHANGE_INDIRECT_SETTINGS_PARAMETER`,
  GET_REQUEST: `${prefix}/GET_REQUEST`,
  GET_SUCCEEDED: `${prefix}/GET_SUCCEEDED`,
  GET_CALCULATIONS_REQUEST: `${prefix}/GET_CALCULATIONS_REQUEST`,
  GET_CALCULATIONS_SUCCEEDED: `${prefix}/GET_CALCULATIONS_SUCCEEDED`,
  SET_ACTIVE: `${prefix}/SET_ACTIVE`,
  SET_ACTIVE_SCENARIO_REQUEST: `${prefix}/SET_ACTIVE_SCENARIO_REQUEST`,
  SET_ACTIVE_SCENARIO_SUCCEEDED: `${prefix}/SET_ACTIVE_SCENARIO_SUCCEEDED`,
  GET_ACTIVE_SCENARIO_REQUEST: `${prefix}/GET_ACTIVE_SCENARIO_REQUEST`,
  GET_ACTIVE_SCENARIO_SUCCEEDED: `${prefix}/GET_ACTIVE_SCENARIO_SUCCEEDED`,
  SET_ACTIVE_CALCULATION: `${prefix}/SET_ACTIVE_CALCULATION`,
  SET_ACTIVE_CALCULATION_REQUEST: `${prefix}/SET_ACTIVE_CALCULATION_REQUEST`,
  SET_ACTIVE_CALCULATION_SUCCEEDED: `${prefix}/SET_ACTIVE_CALCULATION_SUCCEEDED`,
  EDITING_GET_BASE_RESOURCE_LIMITATIONS_REQUEST: `${prefix}/EDITING_GET_BASE_RESOURCE_LIMITATIONS_REQUEST`,
  EDITING_GET_BASE_RESOURCE_LIMITATIONS_SUCCEEDED: `${prefix}/EDITING_GET_BASE_RESOURCE_LIMITATIONS_SUCCEEDED`,
  DROP_LIMITATIONS_STATE: `${prefix}/DROP_LIMITATIONS_STATE`,
  SCENARIOS_EDITING_CHANGE_PRODUCTIVITY_VALUE: `${prefix}/SCENARIOS_EDITING_CHANGE_PRODUCTIVITY_VALUE`,
  EDITING_CHANGE_LIMITATION_VALUE: `${prefix}/EDITING_CHANGE_LIMITATION_VALUE`,
  EDITING_CHANGE_RESOURCE_VALUE: `${prefix}/EDITING_CHANGE_RESOURCE_VALUE`,
  EDITING_CHANGE_TOTAL_RESOURCE_VALUE: `${prefix}/EDITING_CHANGE_TOTAL_RESOURCE_VALUE`,
  EDITING_SET_BASE_RESOURCE_LIMITATIONS_REQUEST: `${prefix}/EDITING_SET_BASE_RESOURCE_LIMITATIONS_REQUEST`,
  EDITING_SET_BASE_RESOURCE_LIMITATIONS_SUCCEEDED: `${prefix}/EDITING_SET_BASE_RESOURCE_LIMITATIONS_SUCCEEDED`,
  REMOVE_SCENARIO_REQUEST: `${prefix}/REMOVE_SCENARIO_REQUEST`,
  REMOVE_SCENARIO_SUCCEEDED: `${prefix}/REMOVE_SCENARIO_SUCCEEDED`,
  DROP_VALUES: `${prefix}/DROP_VALUES`,
  UPDATE_SCENARIO_NAME_REQUEST: `${prefix}/UPDATE_SCENARIO_NAME_REQUEST`,
  UPDATE_SCENARIO_NAME_SUCCEEDED: `${prefix}/UPDATE_SCENARIO_NAME_SUCCEEDED`,
  UPDATE_CALCULATION_NAME_REQUEST: `${prefix}/UPDATE_CALCULATION_NAME_REQUEST`,
  UPDATE_CALCULATION_NAME_SUCCEEDED: `${prefix}/UPDATE_CALCULATION_NAME_SUCCEEDED`,
  REMOVE_CALCULATION_REQUEST: `${prefix}/REMOVE_CALCULATION_REQUEST`,
  REMOVE_CALCULATION_SUCCEEDED: `${prefix}/REMOVE_CALCULATION_SUCCEEDED`,
  COPY_SCENARIO_REQUEST: `${prefix}/COPY_SCENARIO_REQUEST`,
  COPY_SCENARIO_SUCCEEDED: `${prefix}/COPY_SCENARIO_SUCCEEDED`,
  COPY_SCENARIO_FAILED: `${prefix}/COPY_SCENARIO_FAILED`,
  DROP_INFO: `${prefix}/DROP_INFO`,
  PUBLISH_REQUEST: `${prefix}/PUBLISH_REQUEST`,
  PUBLISH_SUCCEEDED: `${prefix}/PUBLISH_SUCCEEDED`,
  DELETE_ALL_SCENARIOS_EXCEPT_ACTIVE: `${prefix}/DELETE_ALL_SCENARIOS_EXCEPT_ACTIVE`,
  DELETE_ALL_SCENARIOS_EXCEPT_ACTIVE_SUCCEEDED: `${prefix}/DELETE_ALL_SCENARIOS_EXCEPT_ACTIVE_SUCCEEDED`,
  SET_EDIT_SCENARIO: `${prefix}/SET_EDIT_SCENARIO`,
  SET_CHANGE_WP_REQUEST: `${prefix}/SET_CHANGE_WP_REQUEST`,
  SET_CHANGE_WP_SUCCEEDED: `${prefix}/SET_CHANGE_WP_SUCCEEDED`,
  UPDATE_SCENARIO_REQUEST: `${prefix}/UPDATE_SCENARIO_REQUEST`,
  UPDATE_SCENARIO_SUCCEEDED: `${prefix}/UPDATE_SCENARIO_SUCCEEDED`,
  RELOAD_ACTIVE_CALCULATION_REQUEST: `${prefix}/RELOAD_ACTIVE_CALCULATION_REQUEST`,
  UPDATE_SCENARIO_STATUS: `${prefix}/UPDATE_SCENARIO_STATUS`,
};
