import React, { useCallback } from 'react';

import './styles.scss';

import { ProjectType } from 'common/constants/project-type';
import { useOpenMenuWithPosition } from 'common/hooks';
import { AdminProjectVm } from '../../interfaces/admin-project-vm';
import { ProjectContextMenu } from './project-context-menu/project-context-menu';

interface ShortProjectItemProp {
  item: AdminProjectVm;
  className?: string;
  showType?: boolean;
  onClick?: (item: AdminProjectVm) => void;
}

export const ShortProjectItem: React.FC<ShortProjectItemProp> = ({ item, className, onClick, showType }) => {
  const { onClose, onOpen, isOpen, position } = useOpenMenuWithPosition(true);
  const onClickCallback = useCallback(() => onClick(item), [item]);
  const is2D = item.type === ProjectType.Project2d;
  return (
    <>
      <div className={className} onContextMenu={is2D ? onOpen : undefined} onClick={onClickCallback}>
        {`${item.name} (${item.id}) ${showType ? `[${item.type}]` : ''}`}
      </div>
      {
        isOpen && (
          <ProjectContextMenu
            projectId={item.id}
            x={position.x}
            y={position.y}
            onClose={onClose}
          />
        )
      }
    </>
  );
};

