import { RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { AnyAction, Dispatch } from 'redux';
import { ImageUploader } from 'common/components/image-uploader';
import { ImageUploadZone } from 'common/components/image-uploader/image-upload-zone';
import { CommonsApi } from '../../../../../api/common';
import { AccountActions } from '../../../../../units/account/actions/creators';
import { AccountApi } from '../../../../../units/account/api';
import { Styled } from './styled';


interface DispatchProps {
  setAvatar: (key: string) => void;
  removeAvatar: () => void;
}

interface OwnProps {
  hasAvatar: boolean;
  userId: string;
}

interface Props extends DispatchProps, OwnProps {
}

export const AvatarEditorComponent: React.FC<Props> = ({
  removeAvatar,
  setAvatar,
  hasAvatar,
  userId,
}) => {
  const [imageZone, setImageZone] = useState<ImageUploadZone>(null);

  const onAddAvatar = useCallback((e: React.MouseEvent) => {
    imageZone.openFileDialogClick(e);
  }, [imageZone]);

  return (
    <Styled.Container>
      <Styled.Avatar>
        <ImageUploader
          previewUrl={CommonsApi.tempFileLink}
          defaultImagePreviewUrl={hasAvatar ? AccountApi.buildAvatarLinkPostFix(userId) : undefined}
          deleteImage={removeAvatar}
          onLoadEnd={setAvatar}
          forwardImageZoneRef={setImageZone}
        />
      </Styled.Avatar>
      <RectangleButton
        onClick={onAddAvatar}
        text='Change avatar'
        height={20}
        width={90}
        fontSize={12}
        mood='secondary'
      />
    </Styled.Container>
  );
};


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setAvatar: (url) => dispatch(AccountActions.setAvatar(url)),
    removeAvatar: () => dispatch(AccountActions.removeAvatar()),
  };
}


export const AvatarEditor = connect(null, mapDispatchToProps)(AvatarEditorComponent);
