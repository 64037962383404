import styled from 'styled-components';

export const TRACE_LINK_HEIGHT = 50;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${TRACE_LINK_HEIGHT}px;
  background: ${p => p.theme.color.backgroundRush};
  box-shadow: inset 0 -1px 0 0 ${p => p.theme.color.background};
  display: flex;
  align-items: center;
`;

const Counter = styled.div`
  box-sizing: border-box;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  margin-left: 20px;
  margin-right: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${p => p.theme.color.lightGray};
  border-radius: 5px;
  transition: all ${p => p.theme.duration.s} linear;
  background-color: ${p => p.theme.color.turquoise};
`;

const LinksContainer = styled.div`
  position: relative;
  width: calc(100% - 150px);
  height: 100%;
  margin-left: auto;
`;

export const Styled = {
  Container,
  Counter,
  LinksContainer,
};
