import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { getNameById } from 'unit-2d-database/components/breakdown-property/utils';
import {
  Assembly,
  BreakdownProperty,
  Item,
  Property,
  PropertyFormatEnum,
  PropertyTypeEnum,
} from 'unit-2d-database/interfaces';
import { TotalAssignWithId, useTotalAssign } from 'unit-2d-info-panel/content/selection-view/assign-form/hooks';

interface Variants {
  variants: Record<string, Set<string | number>>;
  getIdsByVariant: (key: string) => { instances: string[], groups: string[] };
}

type UsePiaData = () => [Assembly[], Item[], Variants];

const getValue = (property: Property, originProperties: Property[]): string => {
  if (!property.value) {
    return 'Empty';
  }
  if (property.value.type === PropertyTypeEnum.BreakdownLink || property.value.type === PropertyTypeEnum.Breakdown) {
    const origin: BreakdownProperty = originProperties.find(p => p.name === property.name) as BreakdownProperty;
    if (!origin) {
      return '';
    }
    return getNameById(origin.value.root, property.value.value);
  }

  if (property.value.format.type === PropertyFormatEnum.Number) {
    const value = property.value.value;
    return `${value ? value : 0} ${(property.value.format as any).unit}`;
  }

  return property.value.value.toString();
};

const getPropertyVariants = (totalInfo: TotalAssignWithId[], properties: Property[]): Variants => {
  const propertyVariants: Record<string, Set<string | number>> = {};
  const propertyUniqValueCount: Record<string, number> = {};
  const usePropertyCount: Record<string, number> = {};
  const fullKeyWithId: Record<string, { instances: string[], groups: string[] }> = {};
  const keyMetaInfo: Record<string, Array<{ id: string, isGroup: boolean }>> = {};
  const assignEntityRef: Record<string, Array<{ id: string, isGroup: boolean, propertySet: Set<string> }>> = {};

  const setFullKeyId = (key: string, id: string, isGroup: boolean): void => {
    if (!fullKeyWithId[key]) {
      fullKeyWithId[key] = {
        instances: [],
        groups: [],
      };
    }
    if (isGroup) {
      fullKeyWithId[key].groups.push(id);
    } else {
      fullKeyWithId[key].instances.push(id);
    }
  };

  const setKeyMetaInfo = (key: string, id: string, isGroup: boolean): void => {
    if (keyMetaInfo[key]) {
      keyMetaInfo[key].push({ id, isGroup });
    } else {
      keyMetaInfo[key] = [{ id, isGroup }];
    }
  };

  const setAssignEntityRef = (key: string, id: string, isGroup: boolean, assignProperty: Property[]): void => {
    if (assignEntityRef[key]) {
      assignEntityRef[key].push({ id, isGroup, propertySet: new Set(assignProperty.map(p => p.name)) });
    } else {
      assignEntityRef[key] = [{ id, isGroup, propertySet: new Set(assignProperty.map(p => p.name)) }];
    }
  };

  const setVariants = (items: Item[], id: string, isGroup: boolean, assemblyName?: string): void => {
    for (const item of items) {
      if (item.properties) {
        const assignKey = `${item.name}_${assemblyName || ''}`;
        setAssignEntityRef(assignKey, id, isGroup, item.properties);
        for (const property of item.properties) {
          const key = `${property.name}_${assignKey}`;
          setKeyMetaInfo(key, id, isGroup);
          const value = getValue(property, properties);
          if (propertyVariants[key]) {
            if (!propertyVariants[key].has(value)) {
              propertyVariants[key].add(value);
              propertyUniqValueCount[key] += 1;
            }
            usePropertyCount[key] += 1;
            setFullKeyId(`${value}_${key}`, id, isGroup);
          } else {
            propertyVariants[key] = new Set();
            propertyVariants[key].add(value);
            propertyUniqValueCount[key] = 1;
            usePropertyCount[key] = 1;
            setFullKeyId(`${value}_${key}`, id, isGroup);
          }
        }
      }
    }
  };

  for (const info of totalInfo) {
    const [assemblies, items] = info.assign;
    if (assemblies) {
      for (const assembly of assemblies) {
        if (assembly.items) {
          setVariants(assembly.items, info.id, info.isGroup, assembly.name);
        }
      }
    }

    if (items) {
      setVariants(items, info.id, info.isGroup);
    }
  }

  const result: Record<string, Set<string | number>> = {};

  for (const [key, count] of Object.entries(propertyUniqValueCount)) {
    if (count > 1) {
      result[key] = propertyVariants[key];
    }
  }

  for (const [key, count] of Object.entries(usePropertyCount)) {
    if (count < totalInfo.length) {
      propertyVariants[key].add('Empty');
      result[key] = propertyVariants[key];
      const assignKey = key.split('_').slice(1).join('_');
      const refs = assignEntityRef[assignKey];

      for (const ref of refs) {
        const propertyName = key.split('_')[0];
        if (!ref.propertySet.has(propertyName)) {
          setFullKeyId(`Empty_${key}`, ref.id, ref.isGroup);
        }
      }
    }
  }

  const getIdsByVariant = (key: string): { instances: string[], groups: string[] } => fullKeyWithId[key];

  return { variants: result, getIdsByVariant };
};

const createAssignWithMaximumProperties = (totalInfo: TotalAssignWithId[]): [Assembly[], Item[]] => {
  const assemblyMap: Record<string, Record<string, Record<string, Property>>> = {};
  const assemblyLink: Record<string, Assembly> = {};
  const assemblyItemLink: Record<string, Item> = {};
  const itemMap: Record<string, Record<string, Property>> = {};
  const itemLink: Record<string, Item> = {};

  for (const info of totalInfo) {
    const [infoAssembly, infoItems] = info.assign;
    if (infoAssembly) {
      for (const assembly of infoAssembly) {
        if (!assemblyMap[assembly.name]) {
          assemblyMap[assembly.name] = {};
          assemblyLink[assembly.name] = assembly;
        }
        for (const item of assembly.items) {
          if (!assemblyMap[assembly.name][item.name]) {
            assemblyMap[assembly.name][item.name] = {};
            assemblyItemLink[item.name] = item;
          }
          for (const property of item.properties) {
            if (property.value) {
              assemblyMap[assembly.name][item.name][property.name] = property;
            }
          }
        }
      }
    }

    if (infoItems) {
      for (const item of infoItems) {
        if (!itemMap[item.name]) {
          itemMap[item.name] = {};
          itemLink[item.name] = item;
        }
        for (const property of item.properties) {
          if (property.value) {
            itemMap[item.name][property.name] = property;
          }
        }
      }
    }
  }

  const assemblies: Assembly[] = [];
  const items: Item[] = [];

  for (const [key, value] of Object.entries(itemLink)) {
    const properties = Object.values(itemMap[key]);
    const item = { ...value, properties };
    items.push(item);
  }

  for (const [key, value] of Object.entries(assemblyLink)) {
    const assemblyItemsNames = Object.keys(assemblyMap[key]);
    const newItems = [];
    for (const assemblyItemName of assemblyItemsNames) {
      const itemValue = assemblyItemLink[assemblyItemName];
      const properties = Object.values(assemblyMap[key][assemblyItemName]);
      const item = { ...itemValue, properties };
      newItems.push(item);
    }
    const assembly = { ...value, items: newItems };
    assemblies.push(assembly);
  }

  return [assemblies, items];
};

export const usePiaData: UsePiaData = () => {
  const { assignNameList, getSelectedIds, getTotalAssignByIds } = useTotalAssign();
  const properties = useSelector<State, Property[]>(s => s.twoDDatabase.properties);
  return useMemo(() => {
    const selectInfo = getSelectedIds(assignNameList[0]);
    if (!selectInfo) {
      return [[], [], null];
    }
    const { elementIds, groupIds } = selectInfo;
    const elementsAssign = getTotalAssignByIds(elementIds);
    const groupAssign = getTotalAssignByIds(groupIds);
    groupAssign.forEach(a => a.isGroup = true);
    const assigns = elementsAssign.concat(groupAssign);
    if (!assigns.length) {
      return [[], [], null];
    }
    const variants = getPropertyVariants(assigns, properties);
    const assignWithValue = createAssignWithMaximumProperties(assigns);
    return [...assignWithValue, variants];
  }, [assignNameList, getSelectedIds, getTotalAssignByIds, properties]);
};
