import styled from 'styled-components';

const MAX_ELEMENTS = 3;
const ELEMENT_HEIGHT = 35;

const Container = styled.div`
  border-bottom: 1px solid ${p => p.theme.color.background};
  margin-bottom: 5px;
`;

const List = styled.div<{ elements: number }>`
  height: ${p => Math.min(p.elements, MAX_ELEMENTS) * ELEMENT_HEIGHT}px;
  margin-bottom: 5px;
  overflow: auto;

  > div {
    > div:nth-child(1) {
      > div {
        margin-left: -5px !important;
        padding-bottom: 4px;
      }
    }
  }
`;

export const Styled = {
  Container,
  List,
};
