import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getElementTotalAssign } from '2d/helpers';
import { AssignedPia } from '2d/interfaces';
import { DrawingsGeometryGroup } from 'common/components/drawings';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';
import { getAssignByGroup } from 'unit-2d-database/components/side-panel/components/measure-panel/hooks/use-assign';
import { Assembly, Item } from 'unit-2d-database/interfaces';
import { useSelectedRootElementIds } from './use-selected-root-element-ids';
import { useSelectedRootGroupIds } from './use-selected-root-group-ids';

const getAssignName = (assemblies: Assembly[], items: Item[]): string => {
  const assemblyNames = assemblies.map(a =>
    `${a.name} (${a.items.reduce((r, i) => r ?`${r} ${i.name}` : i.name, '')})`);
  const itemNames = items.map(i => i.name);

  const itemNamesString = itemNames.sort().join(', ');
  const assemblyNamesString = assemblyNames.sort().join(', ');

  let result = itemNamesString;
  if (!itemNamesString) {
    result = assemblyNamesString;
  } else if (assemblyNamesString) {
    result += `, ${assemblyNamesString}`;
  }
  return result;
};

export interface TotalAssignWithId {
  assign: [Assembly[], Item[]];
  id: string;
  isGroup?: boolean;
}

interface TotalAssign {
  assignNameList: string[];
  getSelectedIds: (assignName: string) => { groupIds: string[], elementIds: string[] };
  getTotalAssignByIds: (ids: string[]) => TotalAssignWithId[];
}

export const useTotalAssign = (): TotalAssign => {
  const rootGroupIds = useSelectedRootGroupIds();
  const rootElementIds = useSelectedRootElementIds();

  const assign = useSelector<State, Record<string, AssignedPia>>(s => s.twoD.assignPia);
  const groups = useSelector<State, DrawingsGeometryGroup[]>(s => s.drawings.drawingGeometryGroups);
  const aiAnnotation = useSelector<State, DrawingsGeometryState>(s => s.drawings.aiAnnotation);
  return useMemo(() => {
    const assignNameList = new Set<string>();
    const assignNameToElementMap = {};
    const assignById = {};

    const pushAssignName = (name: string, id: string, isGroup: boolean): void => {
      if (isGroup) {
        assignNameToElementMap[name].groupIds.push(id);
      } else {
        assignNameToElementMap[name].elementIds.push(id);
      }
    };
    const updateAssignName = (name: string, id: string, isGroup: boolean): void => {
      if (assignNameList.has(name)) {
        pushAssignName(name, id, isGroup);
      } else {
        assignNameToElementMap[name] = { groupIds: [], elementIds: [] };
        assignNameList.add(name);
        pushAssignName(name, id, isGroup);
      }
    };

    for (const id of rootGroupIds) {
      const groupAssign = getAssignByGroup(id, groups, assign);
      const name = getAssignName(...groupAssign);
      updateAssignName(name, id, true);
      assignById[id] = groupAssign;
    }
    for (const id of rootElementIds) {
      const { assemblies, items } = getElementTotalAssign(id, assign, groups, aiAnnotation);
      const name = getAssignName(assemblies, items);
      updateAssignName(name, id, false);
      assignById[id] = [assemblies, items];
    }

    const getSelectedIds = (assignName: string): { groupIds: string[], elementIds: string[] } => {
      return assignNameToElementMap[assignName];
    };

    const getTotalAssignByIds = (ids: string[]): TotalAssignWithId[] => {
      return ids.map(id => ({ assign: assignById[id], id }));
    };

    return {
      assignNameList: Array.from(assignNameList),
      getSelectedIds,
      getTotalAssignByIds,
    };
  }, [assign, rootGroupIds, rootElementIds, groups, aiAnnotation]);
};
