import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './activity-assignmnet-engine-layout.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { ActivityAssignmentActions } from '../../actions/creators/activity-assignment';
import { ValidationStepName } from '../../enums/validation-step-name';
import { ValidationResizableEngineLayoutApi } from '../../interfaces/validation-resizable-engine-layout-api';
import { ActivityAssignmentUtils } from '../../utils/activity-assignment-utils';
import { ActivityAssignmentRevitTreeLayout } from '../activity-assignment-revit-tree-layout';
import { ValidationResizableEngineLayoutNew } from '../validation-resizable-engine-layout-new';


interface StateProps {
  activeScenario: number;
  selectedPath: string;
  selectedIds: number[];
  isSaveData: boolean;
  viewTree: boolean;
  isShowActivities: boolean;
  errorEngineIds: number[];
}

interface OwnProps {
  projectId: string;
  children?: React.ReactNode;
}

interface DispatchProps {
  loadData: () => void;
  dropState: () => void;
  getRevitData: () => void;
  selectFromEngine: (id: number) => void;
  goTo: (newLocation: string) => void;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

class ActivityAssignmnetEngineLayoutComponent extends React.PureComponent<Props> {
  private layoutApi: ValidationResizableEngineLayoutApi = null;
  private isEngineSelect: boolean = true;

  public componentDidMount(): void {
    if (!this.props.activeScenario) {
      const validationUrl = AppUrls.plan.project.validation.step.url({
        projectId: this.props.projectId.toString(),
        step: ValidationStepName.ActivityAssignment,
      });
      this.props.goTo(validationUrl);
    } else if (!this.props.isSaveData) {
      this.props.loadData();
      this.props.getRevitData();
    }
  }

  public componentWillUnmount(): void {
    this.props.dropState();
  }

  public componentDidUpdate(prevProps: Props): void {
    const { selectedPath, selectedIds, isShowActivities } = this.props;
    if (selectedPath) {
      this.layoutApi.showIds(selectedIds);
      if (this.props.selectedPath !== prevProps.selectedPath) {
        if (this.layoutApi.getSelected().length > 0) {
          this.isEngineSelect = false;
          this.layoutApi.setSelected([]);
        }
        this.layoutApi.showProperties(isShowActivities ? selectedIds : []);
        this.layoutApi.focus(selectedIds);
      }
    }
  }

  public render(): React.ReactNode {
    return (
      <div className='activity-assignment-engine-layout'>
        <ActivityAssignmentRevitTreeLayout/>
        <ValidationResizableEngineLayoutNew
          errorEngineIds={this.props.errorEngineIds}
          isShowResizablePanel={this.props.isShowActivities}
          projectId={this.props.projectId}
          getApi={this.saveLayoutApi}
          engineSelect={this.engineSelect}
          className={classNames({ 'activity-assignmnet-engine-layout__resizable-layout': this.props.viewTree })}
        >
          {this.props.children}
        </ValidationResizableEngineLayoutNew>
      </div>
    );
  }

  @autobind
  private saveLayoutApi(api: ValidationResizableEngineLayoutApi): void {
    this.layoutApi = api;
  }

  @autobind
  private engineSelect(ids: number[]): void {
    if (!this.isEngineSelect) {
      this.isEngineSelect = true;
      return;
    }
    if (ids.length > 0 && !this.props.isShowActivities) {
      this.isEngineSelect = false;
      this.layoutApi.setSelected([]);
      this.props.selectFromEngine(ids[0]);
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    errorEngineIds: state.activityAssignment.badIds,
    activeScenario: state.scenarios.active_scenario ? state.scenarios.active_scenario.id : null,
    selectedPath: state.activityAssignment.selectedPath,
    selectedIds: ActivityAssignmentUtils.getSelectedIds(state.activityAssignment),
    isSaveData: state.activityAssignment.isSaveData,
    viewTree: state.activityAssignment.viewTree,
    isShowActivities: state.activityAssignment.isShowActivities,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    loadData: () => {
      dispatch(ActivityAssignmentActions.loadData());
    },
    dropState: () => {
      dispatch(ActivityAssignmentActions.dropState());
    },
    getRevitData: () => {
      dispatch(ActivityAssignmentActions.getRevitData());
    },
    selectFromEngine: id => {
      dispatch(ActivityAssignmentActions.selectFromEngine(id));
    },
    goTo: newLocation => dispatch(push(newLocation)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityAssignmentEngineLayout = connector(ActivityAssignmnetEngineLayoutComponent);
