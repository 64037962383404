import { AnyAction } from 'redux';

import { QuantityTakeOffInitialState } from '../units/projects/constants/quantity-take-off-initial-state';
import { QuantityTakeOffState } from '../units/projects/interfaces/quantity-take-off/quantity-take-off-state';
import { quantityTakeOffReducerMethods } from '../units/projects/reducers/quantity-take-off';
import { quantityTakeOffReportReducerMethods } from '../units/projects/reducers/quantity-take-off-report';
import { quantityTakeOffTemplateReducerMethods } from '../units/projects/reducers/quantity-take-off-template';

export function quantityTakeOff(
  state: QuantityTakeOffState = QuantityTakeOffInitialState,
  action: AnyAction,
): QuantityTakeOffState {
  if (action && action.type in quantityTakeOffReducerMethods) {
    return quantityTakeOffReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in quantityTakeOffReportReducerMethods) {
    return quantityTakeOffReportReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in quantityTakeOffTemplateReducerMethods) {
    return quantityTakeOffTemplateReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
