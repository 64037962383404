import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  background: ${p => p.theme.color.backgroundRush};
  width: 800px;
  height: 400px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  animation: ${Constants.Animations.modalAppear}  ${p => p.theme.duration.m} ease-in-out;

  .ag-center-cols-viewport {
    overflow-x: hidden;
  }
`;

export const Styled = {
  Container,
};
