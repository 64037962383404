import React, { Component } from 'react';

interface Props {
  className?: string;
  onClick?: (e: any) => void;
}

export class KreoIconEyeHidden extends Component<Props> {
  public render(): any {
    return (
      <svg
        width='16px'
        height='16px'
        className={this.props.className}
        onClick={this.props.onClick}
        data-control-name='show-button'
        viewBox='0 0 16 16'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <path d='M14,0 L2,12' id='path-1' />
        </defs>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='UI-Elements' transform='translate(-553.000000, -3119.000000)'>
            <g id='eye_hidden' transform='translate(553.000000, 3121.000000)'>
              <path
                // eslint-disable-next-line max-len
                d='M8,11 C5.24097308,11 2.72660566,9.53769803 0.456897739,6.61309408 C0.176892609,6.25229695 0.176893269,5.74764114 0.456899343,5.38684474 L0.456927977,5.38686696 C2.72662799,2.46228899 5.24098534,1 8,1 C10.7590269,1 13.2733943,2.46230197 15.5431023,5.38690592 C15.8231074,5.74770305 15.8231067,6.25235886 15.5431007,6.61315526 L15.543072,6.61313304 C13.273372,9.53771101 10.7590147,11 8,11 Z M8,9 C9.65685425,9 11,7.65685425 11,6 C11,4.34314575 9.65685425,3 8,3 C6.34314575,3 5,4.34314575 5,6 C5,7.65685425 6.34314575,9 8,9 Z'
                id='Combined-Shape'
                fill='#A7B0BD'
              />
              <g id='Rectangle-4' strokeLinecap='round' strokeLinejoin='round'>
                <use stroke='#F0F2F5' strokeWidth='3' xlinkHref='#path-1' />
                <use stroke='#A7B0BD' strokeWidth='1' xlinkHref='#path-1' />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
