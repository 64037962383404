import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { QtoElementalViewBreakdownType } from '../../enums/qto-elemental-view-breakdown-type';
import { QtoElementalViewHighlightEventSource } from '../../enums/qto-elemental-view-highlight-event-source';
import { QtoSelectElementsEventSource } from '../../enums/qto-select-elements-event-source';
import { ModelType, QuantityTakeOffModel } from '../../interfaces/quantity-take-off';
import { QtoRecords } from '../../interfaces/quantity-take-off/quantity-take-off-records';
import {
  GetQuantityTakeOffModelSucceededPayload,
  QtoBasePayload,
  QtoElementalViewHighlightPayload,
  QtoGetLeftPanelRecordsPayload,
  QtoRecordConfig,
  QtoSelectElementsPayload,
} from '../payloads/quantity-take-off';
import { QuantityTakeOffActionTypes } from '../types/quantity-take-off';

function getModel(projectId: number, modelType: ModelType): ActionWith<QtoBasePayload> {
  return {
    type: QuantityTakeOffActionTypes.GET_MODEL_REQUEST,
    payload: { projectId, modelType },
  };
}

function uploadQtoPageData(projectId: number, modelType: ModelType): ActionWith<QtoBasePayload> {
  return {
    type: QuantityTakeOffActionTypes.UPLOAD_QTO_PAGE_DATA,
    payload: { projectId, modelType },
  };
}

function getLeftPanelRecords(
  projectId: number, modelType: ModelType, isUpdateRecordsProps: boolean,
): ActionWith<QtoGetLeftPanelRecordsPayload> {
  return {
    type: QuantityTakeOffActionTypes.GET_LEFT_PANEL_RECORDS,
    payload: { projectId, modelType, isUpdateRecordsProps },
  };
}

function getModelSucceeded(
  projectId: number,
  modelType: ModelType,
  model: QuantityTakeOffModel,
): ActionWith<GetQuantityTakeOffModelSucceededPayload> {
  return {
    type: QuantityTakeOffActionTypes.GET_MODEL_SUCCEEDED,
    payload: { projectId, modelType, model },
  };
}

function getElementRecords(records: QtoRecords, isUpdateRecordsProps: boolean):
 ActionWith<{ records: QtoRecords, isUpdateRecordsProps: boolean }> {
  return {
    type: QuantityTakeOffActionTypes.GET_ELEMENT_RECORDS,
    payload: { records, isUpdateRecordsProps },
  };
}

function getQtoPageData(records: QtoRecords):
 ActionWith<QtoRecords> {
  return {
    type: QuantityTakeOffActionTypes.GET_QTO_PAGE_DATA,
    payload: records,
  };
}

function getModelFailed(): Action {
  return {
    type: QuantityTakeOffActionTypes.GET_MODEL_FAILED,
  };
}

function toggleNodeExpansion(nodeIndex: number): ActionWith<number> {
  return {
    type: QuantityTakeOffActionTypes.TOGGLE_NODE_EXPANSION,
    payload: nodeIndex,
  };
}

function highlightTreeNode(nodeIndex: number): ActionWith<number> {
  return {
    type: QuantityTakeOffActionTypes.HIGHLIGHT_TREE_NODE,
    payload: nodeIndex,
  };
}

function selectBimElements(
  bimIds: number[],
  eventSource: QtoSelectElementsEventSource,
): ActionWith<QtoSelectElementsPayload> {
  return {
    type: QuantityTakeOffActionTypes.SELECT_BIM_ELEMENTS,
    payload: { bimIds, eventSource },
  };
}

function downloadGeneralReport(projectId: number, modelType: ModelType): ActionWith<QtoBasePayload> {
  return {
    type: QuantityTakeOffActionTypes.DOWNLOAD_GENERAL_REPORT,
    payload: { projectId, modelType },
  };
}

function buildApproximateCostReport(projectId: number, modelType: ModelType): ActionWith<QtoBasePayload> {
  return {
    type: QuantityTakeOffActionTypes.BUILD_APPROXIMATE_COST_REPORT,
    payload: { projectId, modelType },
  };
}

function downloadApproximateCostReport(
  projectId: number, modelType: ModelType,
): ActionWith<QtoBasePayload> {
  return {
    type: QuantityTakeOffActionTypes.DOWNLOAD_APPROXIMATE_COST_REPORT,
    payload: { projectId, modelType },
  };
}

function changeExpandStatus(isExpanded?: boolean): ActionWith<boolean | undefined> {
  return {
    type: QuantityTakeOffActionTypes.CHANGE_EXPAND_STATUS,
    payload: isExpanded,
  };
}

function dropState(): Action {
  return {
    type: QuantityTakeOffActionTypes.DROP_STATE,
  };
}

function downloadRawDataFile(projectId: number, modelType: ModelType): ActionWith<QtoBasePayload> {
  return {
    type: QuantityTakeOffActionTypes.DOWNLOAD_RAW_DATA_FILE,
    payload: { projectId, modelType },
  };
}

function openElementalView(selectedLeafIndex: number): ActionWith<number> {
  return {
    type: QuantityTakeOffActionTypes.OPEN_ELEMENTAL_VIEW,
    payload: selectedLeafIndex,
  };
}

function closeElementalView(): Action {
  return {
    type: QuantityTakeOffActionTypes.CLOSE_ELEMENTAL_VIEW,
  };
}

function changeElementalViewBreakdown(
  newType: QtoElementalViewBreakdownType,
): ActionWith<QtoElementalViewBreakdownType> {
  return {
    type: QuantityTakeOffActionTypes.CHANGE_ELEMENTAL_VIEW_BREAKDOWN,
    payload: newType,
  };
}

function highlightElementalViewElements(
  elementsIds: number[] | null,
  eventSource: QtoElementalViewHighlightEventSource,
): ActionWith<QtoElementalViewHighlightPayload> {
  return {
    type: QuantityTakeOffActionTypes.HIGHLIGHT_ELEMENTAL_VIEW_ELEMENTS,
    payload: { elementsIds, eventSource },
  };
}

function filterTree(bimIds: number[]): ActionWith<number[]> {
  return {
    type: QuantityTakeOffActionTypes.FILTER_TREE,
    payload: bimIds,
  };
}

function loadRecordsConfig(projectId: number): ActionWith<number> {
  return {
    type: QuantityTakeOffActionTypes.LOAD_RECORDS_CONFIG,
    payload: projectId,
  };
}

function getRecordsConfig(projectId: QtoRecordConfig): ActionWith<QtoRecordConfig> {
  return {
    type: QuantityTakeOffActionTypes.GET_RECORDS_CONFIG,
    payload: projectId,
  };
}

function updateStateAfterAddNewProps(): Action {
  return {
    type: QuantityTakeOffActionTypes.UPDATE_STATE_AFTER_ADD_NEW_PROPS,
  };
}

function setGlobalConfig(payload: any): ActionWith<any> {
  return {
    type: QuantityTakeOffActionTypes.SET_GLOBAL_CONFIG,
    payload,
  };
}

export const QuantityTakeOffActions = {
  getModel,
  getModelSucceeded,
  getModelFailed,
  toggleNodeExpansion,
  highlightTreeNode,
  selectBimElements,
  downloadGeneralReport,
  changeExpandStatus,
  dropState,
  buildApproximateCostReport,
  downloadApproximateCostReport,
  downloadRawDataFile,
  openElementalView,
  closeElementalView,
  changeElementalViewBreakdown,
  highlightElementalViewElements,
  getElementRecords,
  uploadQtoPageData,
  filterTree,
  loadRecordsConfig,
  getRecordsConfig,
  getQtoPageData,
  getLeftPanelRecords,
  updateStateAfterAddNewProps,
  setGlobalConfig,
};
