import * as React from 'react';

import './revit-tree-base-item-body-content.scss';

interface Props {
  title: string;
  count: number;
}

export class RevitTreeBaseItemBodyContent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { title, count, children } = this.props;
    return (
      <React.Fragment>
        {children}
        <div
          className='revit-tree-base-item-body-content__title'
          title={title.replace(new RegExp('(<b>|</b>)', 'gi'), '')}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className='revit-tree-base-item-body-content__count'>{count}</div>
      </React.Fragment>
    );
  }
}
