import styled from 'styled-components';

const DialogContainer = styled.div`
  width: 480px;
  border-radius: 20px;
  background: ${p => p.theme.color.backgroundRush};
  padding: 30px 30px 40px 30px;
  box-sizing: border-box;
`;

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  > svg {
    fill: ${p => p.theme.color.gray};
  }
  p {
    line-height: initial;
  }
`;

const DialogButton = styled.div`
  margin-left: auto;
  height: 20px;
`;

export const Styled = {
  DialogContainer,
  DialogHeader,
  DialogButton,
};
