import { ICellEditorParams } from 'ag-grid-community';
import autobind from 'autobind-decorator';

import { PropertyHelper } from '../../../../units/projects/utils/quantity-take-off-tree-table';
import { CellEditor } from './cell-editor';
import { CellEditorType } from './cell-editor-type';

import { TreeTableCellEditorCreator } from './tree-table-cell-editor-creator';

export class CombineCellEditor<
  TValue extends { override?: string },
  TDefaultEditor extends CellEditor<TValue, HTMLElement>,
  TFormulaEditor extends CellEditor<TValue, HTMLElement>,
  > {
  private formulaEditor: TFormulaEditor;
  private defaultEditor: TDefaultEditor;
  private params: ICellEditorParams;
  private eGui: HTMLDivElement;
  private actualEditor: CellEditor<TValue>;
  private valueWrapper: HTMLDivElement;

  public constructor(
    params: ICellEditorParams,
    eGui: HTMLDivElement,
    cellEditorCreator: TreeTableCellEditorCreator<TDefaultEditor, TFormulaEditor>,
    getProperty: () => TValue,
  ) {
    this.eGui = eGui;
    this.params = params;
    const { defaultCellEditor, formulaCellEditor } =
      cellEditorCreator.getCellEditors(params, eGui);
    this.defaultEditor = defaultCellEditor;
    this.formulaEditor = formulaCellEditor;

    const property = getProperty();
    this.actualEditor = PropertyHelper.isFormula(property)
      ? this.formulaEditor
      : this.defaultEditor;
  }

  public initEvents(): void {
    const wrapper = this.eGui.querySelector(`.${CellEditor.wrapperClassName}`);
    if (wrapper) {
      wrapper.addEventListener('input', this.handleInput);
      this.valueWrapper = wrapper as HTMLDivElement;
    }

    this.actualEditor.initEvents();
  }

  public destroyEvents(): void {
    const wrapper = this.eGui.querySelector(`.${CellEditor.wrapperClassName}`);
    if (wrapper) {
      wrapper.removeEventListener('input', this.handleInput);
    }
    this.actualEditor.destroyEvents();
  }

  public setValue(): void {
    this.actualEditor.setValue();
  }

  public getValue(): TValue {
    return this.actualEditor.getValue();
  }

  public setFocus(): void {
    this.actualEditor.setFocus();
  }

  public revertValue(): void {
    this.actualEditor.revertValue();
  }

  @autobind
  private handleInput(): void {
    const value = this.actualEditor.getCurrentValue();
    const property = { override: value };
    const actualEditorType = this.actualEditor.getEditorType();
    const valueType = this.getValidEditorType(property as TValue);
    if (valueType !== actualEditorType) {
      this.actualEditor.destroyEvents();
      this.setEditor(valueType, value);
      this.updateEditorHtml(value);
      this.actualEditor.initEvents();
    }
  }

  private setEditor(editorType: CellEditorType, value: string): void {
    switch (editorType) {
      case CellEditorType.DefaultCellEditor: {
        this.actualEditor = this.defaultEditor;
        this.params.context.highlight.updateColumnColor('', this.params);
        this.params.api.refreshCells({ force: true });
        break;
      }
      case CellEditorType.FormulaCellEditor: {
        this.actualEditor = this.formulaEditor;
        this.params.context.highlight.updateColumnColor(value, this.params);
        this.params.api.refreshCells({ force: true });
        break;
      }
      default: {
        throw (new Error('Invalid Editor Type'));
      }
    }
  }

  private updateEditorHtml(value: string): void {
    const oldHtmlValue = this.valueWrapper.firstChild;
    const newHtmlValue = this.actualEditor.getNode(value);
    (this.eGui.parentNode as HTMLElement).focus();
    this.valueWrapper.replaceChild(newHtmlValue, oldHtmlValue);
    this.actualEditor.setFocus();
  }

  private getValidEditorType(value: TValue): CellEditorType {
    return PropertyHelper.isFormula(value)
      ? CellEditorType.FormulaCellEditor
      : CellEditorType.DefaultCellEditor;
  }
}
