import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Mood } from 'common/enums/mood';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { Paper } from '../paper';
import { Text } from '../text';
import { MessageContent } from './message-content';
import { RatingContent } from './rating-content';
import { Styled } from './styled';

interface Props {
  title?: string;
  sendFeedback: (rating: number, message: string) => void;
}

const FeedBackComponent: React.FC<Props> = (
  {
    title = 'Please rate the quality of result',
    sendFeedback,
  },
) => {

  const hideExecutor = useMemo(() => {
    return new DeferredExecutor(3000);
  }, []);

  useEffect(() => {
    return () => {
      hideExecutor.reset();
    };
  }, []);

  const [rating, setRating] = useState<number | null>(null);
  const [ shown, setShown ] = useState<boolean>(true);
  const [ showThanks, setShowThanks ] = useState<boolean>(false);

  const sendFeedbackCalback = useCallback((message: string) => {
    sendFeedback(rating, message);
    setShowThanks(true);
    hideExecutor.execute(() => {
      setShown(false);
    });
  }, [setShowThanks, rating, sendFeedback]);

  const content = useMemo(() => {
    if (showThanks) {
      return (
        <Styled.ThankYou>
          <Text
            fontSize={14}
            lineHeight={18}
            letterSpacing={0}
          >
            Thank you!
          </Text>
        </Styled.ThankYou>
      );
    }
    if (rating === null) {
      return <RatingContent title={title} onSelectRating={setRating}/>;
    }
    return <MessageContent onMessageSend={sendFeedbackCalback}/>;
  }, [rating, setRating, title, sendFeedbackCalback, showThanks]);


  if (!shown) {
    return null;
  }

  return (
    <Paper mood={Mood.Secondary}>
      {content}
    </Paper>
  );
};


export const Feedback = React.memo(FeedBackComponent);
