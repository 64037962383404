import { CheckboxButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import './checkbox.scss';

// eslint-disable-next-line max-len
// TODO:  onChange?: (value: boolean, event: React.MouseEvent<HTMLLabelElement>) => void; -> onChange?: (event: React.MouseEvent<HTMLLabelElement>, value: boolean) => void;
export interface CheckboxProps {
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  controlName?: string;
  indeterminate?: boolean;
  color?: 'white' | 'blue';
  size?: 'large';
  libaryIcon?: boolean;
  error?: string;
  onChange?: (value: boolean, event: React.MouseEvent<HTMLLabelElement>) => void;
}

interface State {
  checked: boolean;
}

export class Checkbox extends React.PureComponent<CheckboxProps, State> {
  public static defaultProps: Partial<CheckboxProps> = {
    label: '',
  };

  constructor(props: CheckboxProps) {
    super(props);
    this.state = { checked: props.checked !== undefined ? props.checked : false };
  }

  public static getDerivedStateFromProps(nextProps: CheckboxProps): State {
    if (nextProps.checked === undefined) {
      return null;
    }
    return {
      checked: nextProps.checked,
    };
  }

  public render(): React.ReactNode {
    return (
      <React.Fragment>
        <input
          key={0}
          type='checkbox'
          className='kreo-input-checkbox'
          checked={this.state.checked}
          disabled={this.props.disabled}
          readOnly={true}
        />
        <label key={1} onClick={this.check} data-control-name={this.props.controlName}>
          {this.props.error && <span className='kreo-input-checkbox__error'>{this.props.error}</span>}
          {this.props.libaryIcon
            ? <CheckboxButton checked={this.state.checked} />
            : (
              <span
                className={classNames('icon', this.props.size, this.props.color, {
                  disabled: this.props.disabled,
                  indeterminate: this.props.indeterminate,
                })}
              />
            )
          }
          {this.props.label && <span className='kreo-input-checkbox__label'>{this.props.label}</span>}
        </label>
      </React.Fragment>
    );
  }

  @autobind
  private check(e: React.MouseEvent<HTMLLabelElement>): void {
    if (!this.props.disabled) {
      if (this.props.onChange) {
        this.props.onChange(!this.state.checked, e);
      }
      this.setState({ checked: !this.state.checked });
    }
  }
}

// eslint-disable-next-line max-len
// TODO: remove after onChange?: (value: boolean, event: React.MouseEvent<HTMLLabelElement>) => void; -> onChange?: (event: React.MouseEvent<HTMLLabelElement>, value: boolean) => void;
export interface CheckboxFieldProps {
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  controlName?: string;
  indeterminate?: boolean;
  libaryIcon?: boolean;
  displayType?: 'white' | 'large-white' | 'large-blue';
  onValueChange?: (value: boolean) => void;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = (
  props: WrappedFieldProps & CheckboxFieldProps,
): JSX.Element => {
  const {
    input,
    meta: { submitFailed, error },
    ...rest } = props;

  const onChange = (value: boolean): void => {
    input.onChange(value);
    if (props.onValueChange) {
      props.onValueChange(value);
    }
  };

  return (
    <Checkbox
      {...input}
      error={submitFailed && error}
      onChange={onChange} // eslint-disable-line react/jsx-no-bind
      {...rest}
    />
  );
};
