import { RoundButtonStyle } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const Container = styled.div<{ isTranslucent: boolean }>`
  width: 30px;
  height: 30px;

  ${RoundButtonStyle} {
    opacity: ${p => p.isTranslucent ? 0.5 : 1};
  }
`;

export const Styled = {
  Container,
};
