import styled from 'styled-components';

const Container = styled.div`
  height: 360px;
  width: 640px;

  iframe {
    border-radius: 20px;
    overflow: hidden;
    min-height: 100%;
    width: 100%;
  }
`;

export const Styled = {
  Container,
};
