import { TextProperty } from '../../../../../../interfaces';
import { Field, FieldType } from '../../interfaces';

export interface TextFieldInput {
  onChange: (value: string) => void;
  value: string;
}

const getChangeHandler = (onChange: (input: TextFieldInput) => void) => (value: string) => {
  onChange({ value, onChange: getChangeHandler(onChange) });
};

export const mapTextPropertyToField = (
  property: TextProperty,
  originId: string,
  onChange: (value: TextFieldInput) => void,
  onDeleteClick: () => void,
  onVisibilityClick: () => void,
): Field<TextFieldInput> => {
  return {
    id: property.id,
    originId,
    name: property.name,
    type: FieldType.TextInput,
    isHide: false,
    input: {
      value: property.value.value.toString(),
      onChange: getChangeHandler(onChange),
    },
    onDeleteClick,
    onVisibilityClick,
    getDataUpdate: (field: Field<TextFieldInput>) => ({
      ...property,
      id: field.id,
      value: {
        value: field.input.value,
        format: property.value.format,
        type: property.value.type,
      },
    }),
  };
};
