import { CodecUtils } from 'common/utils/codec-utils';

export enum NotificationIcon {
  None = 'None',
  UserAdd = 'UserAdd',
  UserDelete = 'UserDelete',
  UserSettings = 'UserSettings',
  Settings = 'Settings',
  Scenario = 'Scenario',
  WorkPackage = 'WorkPackage',
  Sequence = 'Sequence',
  Grouping = 'Grouping',
  Resources = 'Resources',
  Cost = 'Cost',
  Database = 'Database',
  Comment = 'Comment',
  Validation = 'Validation',
  BidPricing = 'BidPricing',
}

export const NotificationIconC = CodecUtils
  .createEnumCodec<NotificationIcon>(NotificationIcon, 'NotificationIcon');
