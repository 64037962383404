import { Icons, TinyText } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';

import { usePersistedStorageValueToggle } from 'persisted-storage/hooks';
import { DrawingsBooleanOperation } from '../drawings-geometry/drawings-helpers';
import { Apply, DialogContainerStyled, Header, KeepOld } from './components';
import { useApplyOperation, useBooleanKeyboardListener, useCancelBoolean } from './hooks';
import { Styled } from './styled';

interface Props {
  sendRef: (ref: HTMLDivElement, isBooleanMenu?: boolean) => void;
}


const EncloseDialogComponent: React.FC<Props> = ({
  sendRef,
}) => {
  const sendRefCallback = useCallback((ref) => {
    sendRef(ref, true);
  }, [sendRef]);

  const [shouldKeepGroups, toggleKeepGroups] = usePersistedStorageValueToggle('drawingIsKeepGroup');

  const cancel = useCancelBoolean();
  const applySettings = useMemo(() => ({
    keepGroups: shouldKeepGroups,
  }), [shouldKeepGroups]);

  const apply = useApplyOperation(DrawingsBooleanOperation.Enclose, applySettings);

  useBooleanKeyboardListener(apply, cancel);

  return (
    <Styled.Container ref={sendRefCallback}>
      <DialogContainerStyled.Container>
        <Header title='Enclose' Icon={Icons.Enclose}/>
        <KeepOld toggleKeepOld={toggleKeepGroups} isKeepOld={shouldKeepGroups}>
          <TinyText color={'gray'}>Save in the same group</TinyText>
        </KeepOld>
        <Apply apply={apply} cancel={cancel}/>
      </DialogContainerStyled.Container>
    </Styled.Container>
  );
};


export const EncloseDialog = React.memo(EncloseDialogComponent);
