import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  MaterialTileMenu,
  MaterialTileMenuGroup,
  MaterialTileMenuItem,
} from 'common/components/material-tile-menu';
import { RoleGroup } from 'common/enums/role-group';
import { Role } from 'common/interfaces/account/role';
import { KreoDialogActions, KreoIconAddedDone } from 'common/UIKit';
import { dialogAnimationDelay } from 'common/UIKit/dialogs/kreo-dialog';
import { Person } from '../../interfaces/person';
import { EditPersonRoleDialog, EditPersonRoleDialogName } from '../edit-person-role-dialog';

interface MenuOwnProps {
  person: Person;
  personRole: Role;
  canUpdateRole: boolean;
  canDelete: boolean;
  hasAvailableEmployeeLicenses: boolean;
  deletePerson(): void;
}

interface MenuDispatchProps {
  openEditRoleDialog: () => void;
}

interface MenuProps extends MenuOwnProps, MenuDispatchProps {}

interface MenuState {
  renderEditRolePopup: boolean;
  intendedRoleGroup?: RoleGroup;
}

class PersonTileMenuComponent extends React.Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);

    this.state = {
      renderEditRolePopup: false,
      intendedRoleGroup: undefined,
    };
  }

  public render(): React.ReactNode {
    const { person, personRole, canUpdateRole, canDelete, hasAvailableEmployeeLicenses } = this.props;

    if (!canUpdateRole && !canDelete) {
      return null;
    }

    const isEmployee = personRole && personRole.group === RoleGroup.Employee;
    const isGuest = personRole && personRole.group === RoleGroup.Guest;
    const isSubcontractor = personRole && personRole.group === RoleGroup.Subcontractor;

    const { renderEditRolePopup, intendedRoleGroup } = this.state;

    return (
      <React.Fragment>
        {renderEditRolePopup ? (
          <EditPersonRoleDialog
            person={person}
            intendedRoleGroup={intendedRoleGroup}
            onClose={this.onEditRoleDialogClose}
          />
        ) : null}
        <MaterialTileMenu>
          {person.isAccepted && canUpdateRole ? (
            <React.Fragment>
              <MaterialTileMenuGroup>
                <MaterialTileMenuItem
                  label='Employee'
                  isActive={isEmployee}
                  onClick={isEmployee ? null : this.makePersonEmployee}
                  icon={isEmployee ? <KreoIconAddedDone /> : null}
                  isDisabled={!hasAvailableEmployeeLicenses}
                />
                <MaterialTileMenuItem
                  label='Guest'
                  isActive={isGuest}
                  onClick={isGuest ? null : this.makePersonGuest}
                  icon={isGuest ? <KreoIconAddedDone /> : null}
                />
                <MaterialTileMenuItem
                  label='Subcontractor'
                  isActive={isSubcontractor}
                  onClick={isSubcontractor ? null : this.makePersonSubcontractor}
                  icon={isSubcontractor ? <KreoIconAddedDone /> : null}
                />
              </MaterialTileMenuGroup>
              <MaterialTileMenuGroup>
                <MaterialTileMenuItem label='Edit Role' onClick={this.editPersonRole} />
              </MaterialTileMenuGroup>
            </React.Fragment>
          ) : null}
          {canDelete && (
            <MaterialTileMenuGroup>
              <MaterialTileMenuItem
                label={person.isAccepted ? 'Remove from Organization' : 'Cancel Request'}
                onClick={this.props.deletePerson}
                isCritical={true}
              />
            </MaterialTileMenuGroup>
          )}
        </MaterialTileMenu>
      </React.Fragment>
    );
  }

  private openEditRoleDialog(intendedRoleGroup?: RoleGroup): void {
    this.setState(
      {
        renderEditRolePopup: true,
        intendedRoleGroup,
      },
      () => {
        this.props.openEditRoleDialog();
      },
    );
  }

  @autobind
  private makePersonEmployee(): void {
    this.openEditRoleDialog(RoleGroup.Employee);
  }

  @autobind
  private makePersonGuest(): void {
    this.openEditRoleDialog(RoleGroup.Guest);
  }

  @autobind
  private makePersonSubcontractor(): void {
    this.openEditRoleDialog(RoleGroup.Subcontractor);
  }

  @autobind
  private editPersonRole(): void {
    this.openEditRoleDialog();
  }

  @autobind
  private onEditRoleDialogClose(): void {
    setTimeout(
      () => this.setState({ renderEditRolePopup: false }),
      dialogAnimationDelay,
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): MenuDispatchProps => {
  return {
    openEditRoleDialog: () => dispatch(KreoDialogActions.openDialog(EditPersonRoleDialogName)),
  };
};

export const PersonTileMenu = connect(null, mapDispatchToProps)(PersonTileMenuComponent);
