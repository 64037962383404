import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { UpdateVisibilityPayload } from '../actions/payloads/instances-visibility';
import { DrawingsInstancesVisibilityActionTypes } from '../actions/types/instances-visibility';
import { DrawingsState } from '../interfaces/drawings-state';

export const DrawingsInstancesVisibilityReducerMethods: ReducerMethods<DrawingsState> = {
  [DrawingsInstancesVisibilityActionTypes.UPDATE_VISIBILITY]: (
    state,
    { instancesToHide, instancesToShow }: UpdateVisibilityPayload,
  ) => {
    const helper = new MonoliteHelper(state);
    if (instancesToHide.length) {
      helper.setConcat(_ => _.hiddenInstances, instancesToHide);
    }
    if (instancesToShow.length) {
      const showInstancesSet = new Set(instancesToShow);
      helper.setFilter(_ => _.hiddenInstances, _ => !showInstancesSet.has(_));
    }
    return helper.get();
  },
};
