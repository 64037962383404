import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { DrawingsGeometryInstance, DrawingsInstanceMeasure, InfoWindowPosition } from '../../interfaces';
import { InformationLine, SimpleInformationLine } from './information-line';
import { InstanceMeasures } from './instance-measures';
import { InstanceStyles } from './instance-styles';
import { Styled } from './styled';

interface StateProps {
  geometry: DrawingsGeometryInstance;
  groupName: string;
}

interface OwnProps {
  instanceId: string;
  position: InfoWindowPosition;
  getInstancesMeasures: (instanceId: string[]) => DrawingsInstanceMeasure[];
}


interface Props extends StateProps, OwnProps {

}

const InstanceInformationComoonent: React.FC<Props> = ({
  position,
  geometry,
  groupName,
  instanceId,
  getInstancesMeasures,
}: Props) => {
  const instanceMeasures = useMemo(() => {
    return getInstancesMeasures([instanceId])[0];
  }, [instanceId, getInstancesMeasures]);

  if (!geometry) {
    return null;
  }

  return (
    <Styled.Container position={position}>
      <InstanceStyles
        instance={geometry}
      />
      <InformationLine title='Color'>
        <Styled.ColorIndicator
          color={geometry.geometry.color}
        />
      </InformationLine>
      <SimpleInformationLine title={'Name'} value={geometry.name} isValueName={true}/>
      <RenderIf condition={!!groupName}>
        <SimpleInformationLine title={'Group'} value={groupName} isValueName={true}/>
      </RenderIf>
      <InstanceMeasures
        instanceMeasures={instanceMeasures}
      />
    </Styled.Container>
  );
};


function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const geometry = state.drawings.aiAnnotation.geometry[ownProps.instanceId];
  return {
    geometry,
    groupName: geometry ? state.drawings.drawingGeometryGroups.find(x => x.id === geometry.groupId)?.name : null,
  };
}

export const InstanceInformation = connect(mapStateToProps)(InstanceInformationComoonent);
