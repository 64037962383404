import React, { useCallback } from 'react';
import { useDrawingsLayoutApi } from 'common/components/drawings/drawings-layout-api-context';
import { DrawingsBounds, DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { FullPreview } from '../full-preview';
import { PreviewCanvasState } from './interfaces';
import { usePreviewState } from './use-canvas';

interface Props<T> {
  items: T[];
  resultSize: { width: number, height: number };
  drawingInfo: DrawingsShortInfo;
  renderItem: (
    instance: T,
    canvasContext: PreviewCanvasState,
    onFocus: (instance: DrawingsBounds) => void,
  ) => React.ReactNode;
}


function PreviewPanelComponent<T>(props: Props<T>): JSX.Element {
  const {
    items,
    renderItem,
    resultSize,
    drawingInfo,
  } = props;

  const drawingApi = useDrawingsLayoutApi();
  const viewportHelper = drawingApi.getViewport();
  const canvasState = usePreviewState(
    {
      resultSize,
      viewportHelper,
      items,
      drawingInfo,
    },
  );
  const onFocusCallback = useCallback(
    (bounds: DrawingsBounds) => {
      viewportHelper.focusToBounds(bounds);
    }, [viewportHelper]);
  const renderItemCallback = useCallback(
    (item: T) => {
      return renderItem(item, canvasState, onFocusCallback);
    }, [canvasState, onFocusCallback]);

  if (!canvasState) {
    return <></>;
  }
  return (
    <FullPreview
      items={items}
      renderItem={renderItemCallback}
    />
  );
}

export const PreviewPanel = React.memo(PreviewPanelComponent);

