import * as t from 'io-ts';

import { CodecUtils } from 'common/utils/codec-utils';


export enum ScenarioModule {
  ResourceManager = 'getResourceManager',
  ActivityVariants = 'getActivityVariants',
  Measurements = 'getMeasurements',
  Workpackages = 'getWorkpackages',
  ActivitiesGrouping = 'getActivitiesGrouping',
  MicroLimitations = 'getMicroLimitations',
  MacroLimitations = 'getMacroLimitations',
  IndirectCostSettings = 'getIndirectCostSettings',
  ActivityVariantsSettings = 'getActivityVariantsSettings',
  Animation = 'getAnimation',
  CalculationSummary = 'getCalculationSummary',
  CostHistogram = 'getCostHistogram',
  IndirectCost = 'getIndirectCost',
  Boqs = 'getBoqs',
  CalculationReportSource = 'getCalculationReportSource',
  MsProjectGanttReport = 'getMsProjectGanttReport',
}

const ModuleC = CodecUtils.createEnumCodec<ScenarioModule>(ScenarioModule, 'Module');

export enum ModuleStatus {
  Ready = 'Ready',
  Empty = 'Empty',
  InCalculating = 'InCalculating',
}

const ModuleStatusC = CodecUtils.createEnumCodec<ModuleStatus>(ModuleStatus, 'ModuleStatus');

export const ScenarioStateC = t.strict(
  {
    scenarioGuid: t.string,
    inCalculation: t.boolean,
    moduleStatuses: t.record(ModuleC, ModuleStatusC),
  },
  'ScenarioState');
export const ScenarioStatesC = t.array(ScenarioStateC);

export type ScenarioState = t.TypeOf<typeof ScenarioStateC>;

