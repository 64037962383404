import { Constants } from '@kreo/kreo-ui-components';
import React from 'react';

import { DrawingsSettingsSwitcherWithLabel } from 'common/components/drawings/components/setting-parameter';
import {
  useDrawingSettingsWithUpdate,
} from 'common/components/drawings/hooks/settings/use-drawing-settings';
import {
  RotationSettings,
} from 'common/components/drawings/layout-components/top-control-group/drawings-settings/rotation-settings';
import { PropertyWithSlider } from 'common/components/property';
import { RenderIf } from 'common/components/render-if';
import { usePersistedStorageValue, usePersistedStorageValueToggle } from 'persisted-storage/hooks';
import { useChangeRotation } from './use-change-rotation';


const DrawingsSettingsComponent: React.FC = () => {
  const theme = usePersistedStorageValue('theme');
  const {
    isBlackAndWhiteEnabled,
    isNightModeEnabled,
    toggleShowLabel,
    isLabelsEnabled,
    isShowThickness,
    changeOpacity,
    opacity,
    toggleBlackAndWhite,
    toggleNightMode,
    toggleShowThickness,
  } = useDrawingSettingsWithUpdate();
  const [ pinFlyingMenu, togglePinFlying ] = usePersistedStorageValueToggle('drawingsPinFlyingMenu');
  const isNightTheme = theme === Constants.Theme.DARK_THEME.name;
  const tooltipText = `Parameter that allows you to hide dimensions on area measurements.
    The setting affects the Export to PDF`;

  const [rotation, changeRotation, isRotationAccessible] = useChangeRotation();

  return (
    <>
      <DrawingsSettingsSwitcherWithLabel
        toggle={togglePinFlying}
        label='Pin the flymenu at the top'
        checked={pinFlyingMenu}
      />
      <DrawingsSettingsSwitcherWithLabel
        toggle={toggleBlackAndWhite}
        label='Black and white'
        checked={isBlackAndWhiteEnabled}
      />
      <RenderIf condition={isNightTheme}>
        <DrawingsSettingsSwitcherWithLabel
          toggle={toggleNightMode}
          label='Night mode'
          checked={isNightModeEnabled}
        />
      </RenderIf>
      <RenderIf condition={isRotationAccessible}>
        <RotationSettings
          rotation={rotation}
          changeRotation={changeRotation}
        />
      </RenderIf>
      <DrawingsSettingsSwitcherWithLabel
        toggle={toggleShowLabel}
        label='Show Dimensions'
        checked={isLabelsEnabled}
        tooltip={tooltipText}
      />
      <DrawingsSettingsSwitcherWithLabel
        toggle={toggleShowThickness}
        checked={isShowThickness}
        label='Display Thickness'
      />
      <PropertyWithSlider
        title='Opacity'
        value={opacity}
        onChange={changeOpacity}
        min={0}
        max={100}
        postfix='%'
      />
    </>
  );
};


export const DrawingsSettings = React.memo(DrawingsSettingsComponent);
