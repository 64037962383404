import React from 'react';
import {
  DrawingSettings,
  DrawingSettingsApi,
  useDrawingSettings,
  useDrawingSettingsWithUpdate,
} from 'common/components/drawings/hooks/settings/use-drawing-settings';


export function withDrawingsSettingsContextProps<P>(
  Component: React.ComponentType<P & DrawingSettings>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawingSettings>>> {
  return (props: P) => {
    const context = useDrawingSettings();
    return <Component {...props} {...context}/>;
  };
}

export function withDrawingsSettingsContextApiProps<P>(
  Component: React.ComponentType<P & DrawingSettingsApi>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawingSettingsApi>>> {
  return (props: P) => {
    const context = useDrawingSettingsWithUpdate();
    return <Component {...props} {...context}/>;
  };
}
