import { PiaBreakdownNode } from '../interfaces';

export const findOption = (options: PiaBreakdownNode[], id: string): PiaBreakdownNode => {
  for (const option of options) {
    if (option.id === id) {
      return option;
    }
    if (option.children.length) {
      const child = findOption(option.children, id);
      if (child) {
        return child;
      } else {
        continue;
      }
    }
  }
  return null;
};
