import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { KeyIndexArrayBuilder } from 'common/key-indexed-array/key-indexed-array';
import { MonoliteHelper } from 'common/monolite';
import { StepActionTypes } from '../actions';
import { UngroupedCategoryId } from '../constants';
import {
  ActivityGroupData,
  ActivityGroupingStepPage,
  ActivityGroupRestData,
  ActivityGroupsReduxState,
} from '../interfaces';
import { TempActivityGroupingDataMapper } from '../utils';


export const activityGroupingStepReducerMethods: ReducerMethods<ActivityGroupsReduxState> = {
  [StepActionTypes.SET_ACTIVITY_GROUPING_STEP_PAGE]: (state, payload: ActivityGroupingStepPage) => {
    return new MonoliteHelper(state)
      .set((_) => _.activeStepPage, payload)
      .get();
  },
  [StepActionTypes.LOAD_ACTIVITY_GROUPING]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.activityGrouping, RequestStatus.Loading)
      .get();
  },
  [StepActionTypes.LOAD_ACTIVITY_GROUPING_SUCCEEDED]: (
    state,
    payload: ActivityGroupRestData.ActivityGroupingResponse,
  ) => {
    type BuilderValuesType = ActivityGroupData.WorkPackage
      | ActivityGroupData.ActivityGroup
      | ActivityGroupData.Category
      | ActivityGroupData.Activity
      | ActivityGroupData.UngroupedActivity;
    const builder = new KeyIndexArrayBuilder<BuilderValuesType, BuilderValuesType>(
      x => x,
      x => x.id,
    );
    const wp = TempActivityGroupingDataMapper.getWorkPackages(
      builder.buildKeys(payload.workPackages),
      payload.activityGroups,
      payload.ungroupedActivities,
      payload.categories);

    const activities = TempActivityGroupingDataMapper.getActivityGroups(
      builder.buildKeys(payload.activityGroups),
      payload.activities);

    const categories = TempActivityGroupingDataMapper.getCategories(
      builder.buildKeys(payload.categories),
      payload.activityGroups,
    );

    const ungroupedActivityGroupIdToId = {};
    const bimIdToActivities = {};
    for (const activity of payload.ungroupedActivities) {
      ungroupedActivityGroupIdToId[activity.activityGroupId] = activity.id;
      for (const bimId of activity.engineIds) {
        if (!bimIdToActivities[bimId]) {
          bimIdToActivities[bimId] = [];
        }

        bimIdToActivities[bimId].push(activity.id);
      }
    }

    for (const activity of payload.activities) {
      for (const bimId of activity.engineIds) {
        if (!bimIdToActivities[bimId]) {
          bimIdToActivities[bimId] = [];
        }

        bimIdToActivities[bimId].push(activity.id);
      }
    }

    return new MonoliteHelper(state)
      .set((_) => _.workPackages, wp)
      .set((_) => _.ungroupedActivities, builder.buildKeys(payload.ungroupedActivities))
      .set((_) => _.ungroupedActivityGroupIdToId, ungroupedActivityGroupIdToId)
      .set((_) => _.bimIdToActivityIds, bimIdToActivities)
      .set((_) => _.categories, categories)
      .set((_) => _.activities, builder.buildKeys(payload.activities))
      .set((_) => _.activityGroups, activities)
      .set((_) => _.activityGrouping.availableIds.activityGroup, payload.minAvailableActivityGroupId)
      .set((_) => _.activityGrouping.availableIds.category, payload.minAvailableCategoryId)
      .set((_) => _.statuses.activityGrouping, RequestStatus.Loaded)
      .get();
  },
  [StepActionTypes.LOAD_MACRO_SEQUENCE]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.macroSequence, RequestStatus.Loading)
      .get();
  },
  [StepActionTypes.LOAD_MACRO_SEQUENCE_SUCCEEDED]: (state, payload: ActivityGroupRestData.MacroSequenceResponse) => {
    const indexBuilder = new KeyIndexArrayBuilder<ActivityGroupData.Limitation, ActivityGroupData.LimitationData>(
      x => ({ isUsed: x.isUsed, timeLag: x.timeLag, type: x.type }),
      x => x.source,
      x => x.target,
    );

    return new MonoliteHelper(state)
      .set((_) => _.macroSequence.graph.limitations, indexBuilder.buildKeys(payload.limitations))
      .set((_) => _.macroSequence.graph.vertexIds, payload.activityGroupIds)
      .set((_) => _.statuses.macroSequence, RequestStatus.Loaded)
      .get();
  },
  [StepActionTypes.SET_ACTIVE_WORK_PACKAGE]: (state, wpId: number | null) => {
    if (!Number.isInteger(wpId)) {
      return new MonoliteHelper(state)
        .set((_) => _.activeWorkPackageId, null)
        .set((_) => _.activeCategoryId, null)
        .set((_) => _.activeActivityGroupId, null)
        .set((_) => _.activeActivityId, null)
        .get();
    }

    return new MonoliteHelper(state)
      .set((_) => _.activeWorkPackageId, wpId)
      .set((_) => _.activeCategoryId, null)
      .set((_) => _.activeActivityGroupId, null)
      .set((_) => _.activeActivityId, null)
      .get();
  },
  [StepActionTypes.SET_ACTIVE_ACTIVITY_GROUP_OR_UNGROUPED_ACTIVITY]: (state, groupId: number | null) => {
    if (!Number.isInteger(groupId)) {
      return new MonoliteHelper(state)
        .set((_) => _.activeActivityGroupId, null)
        .set((_) => _.activeActivityId, null)
        .get();
    }
    if (state.activityGroups[groupId]) {
      const group = state.activityGroups[groupId];
      return new MonoliteHelper(state)
        .set((_) => _.activeWorkPackageId, group.workPackageId)
        .set((_) => _.activeCategoryId, group.categoryId)
        .set((_) => _.activeActivityGroupId, group.id)
        .set((_) => _.activeActivityId, null)
        .get();
    } else {
      const group = state.ungroupedActivities[state.ungroupedActivityGroupIdToId[groupId]];
      return new MonoliteHelper(state)
        .set((_) => _.activeCategoryId, group.workPackageId)
        .set((_) => _.activeCategoryId, UngroupedCategoryId)
        .set((_) => _.activeActivityGroupId, group.id)
        .set((_) => _.activeActivityId, null)
        .get();
    }
  },
  [StepActionTypes.SET_ACTIVE_CATEGORY]: (state, categoryId: number | null) => {
    if (!Number.isInteger(categoryId)) {
      return new MonoliteHelper(state)
        .set((_) => _.activeCategoryId, null)
        .set((_) => _.activeActivityGroupId, null)
        .set((_) => _.activeActivityId, null)
        .get();
    } else {
      return new MonoliteHelper(state)
        .set((_) => _.activeCategoryId, categoryId)
        .set((_) => _.activeActivityGroupId, null)
        .set((_) => _.activeActivityId, null)
        .get();
    }
  },
  [StepActionTypes.SET_ACTIVE_ACTIVITY_OR_UNGROUPED_ACTIVITY]: (state, activityId: number | null) => {
    if (!Number.isInteger(activityId)) {
      return new MonoliteHelper(state)
        .set((_) => _.activeCategoryId, null)
        .set((_) => _.activeActivityGroupId, null)
        .set((_) => _.activeActivityId, null)
        .get();
    }

    if (state.activities[activityId]) {
      const activity = state.activities[activityId];
      const activityGroup = state.activityGroups[activity.activityGroupId];
      return new MonoliteHelper(state)
        .set((_) => _.activeWorkPackageId, activityGroup.workPackageId)
        .set((_) => _.activeCategoryId, activityGroup.categoryId)
        .set((_) => _.activeActivityGroupId, activity.activityGroupId)
        .set((_) => _.activeActivityId, activityId)
        .get();
    } else {
      const activity = state.ungroupedActivities[activityId];
      return new MonoliteHelper(state)
        .set((_) => _.activeWorkPackageId, activity.workPackageId)
        .set((_) => _.activeCategoryId, UngroupedCategoryId)
        .set((_) => _.activeActivityGroupId, activity.activityGroupId)
        .set((_) => _.activeActivityId, activityId)
        .get();
    }
  },
  [StepActionTypes.UPDATE_ACTIVITY_GROUPING]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.updateActivityGrouping, RequestStatus.Loading)
      .get();
  },
  [StepActionTypes.UPDATE_ACTIVITY_GROUPING_SUCCEEDED]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.updateActivityGrouping, RequestStatus.Loaded)
      .set((_) => _.activityGrouping.changes, {
        categories: [],
        activityGroups: [],
        activities: [],
        removedCategoryIds: [],
        removedActivityGroupIds: [],
      })
      .get();
  },
  [StepActionTypes.UPDATE_MACRO_SEQUENCE]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.updateMacroSequence, RequestStatus.Loading)
      .get();
  },
  [StepActionTypes.UPDATE_MACRO_SEQUENCE_SUCCEEDED]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.updateMacroSequence, RequestStatus.Loaded)
      .set((_) => _.macroSequence.changes, [])
      .get();
  },
};
