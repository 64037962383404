import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 15px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${p => p.theme.color.background};
  height: 55px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }

  ${Text} {
    max-width: 330px;
  }
`;

export const Styled = {
  Container,
  Title,
};
