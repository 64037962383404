import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo, useState } from 'react';

import { TinyText } from 'common/components/text';
import { usePersistedStorageValueToggle } from 'persisted-storage/hooks';
import { DrawingsColorButton } from '../drawings-controls/color-control/drawings-color-button';
import { DrawingsBooleanOperation } from '../drawings-geometry/drawings-helpers';
import { Apply, DialogContainerStyled, Header, KeepOld } from './components';
import { useApplyOperation, useBooleanKeyboardListener, useCancelBoolean, useColor } from './hooks';
import { Styled } from './styled';

interface Props {
  sendRef: (ref: HTMLDivElement, isBooleanMenu?: boolean) => void;
}


const OpenPolygonDialogComponent: React.FC<Props> = ({
  sendRef,
}) => {
  const [shouldKeepGroups, toggleKeepGroups] = usePersistedStorageValueToggle('drawingIsKeepGroup');
  const [ splitToSegments, setSplitToSegments ] = useState(false);
  const [ color, setColor ] = useColor();
  const applySettings = useMemo(() => ({
    keepGroups: shouldKeepGroups,
    color,
    splitToSegments,
  }), [shouldKeepGroups, color, splitToSegments]);

  const apply = useApplyOperation(DrawingsBooleanOperation.OpenPolygon, applySettings);
  const cancel = useCancelBoolean();
  useBooleanKeyboardListener(apply, cancel);

  const setSplitToSegmentsCallback = useCallback(
    () => setSplitToSegments(s => !s),
    [setSplitToSegments],
  );

  return (
    <Styled.Container ref={sendRef}>
      <Styled.OpenPolygonDialog>
        <Header title="Extract polyline from polygon" Icon={Icons.ConvertToPolylines} />
        <Styled.OpenDialogBody>
          <KeepOld toggleKeepOld={toggleKeepGroups} isKeepOld={shouldKeepGroups}>
            <TinyText color={'gray'}>Save in the same group</TinyText>
          </KeepOld>
          <KeepOld toggleKeepOld={setSplitToSegmentsCallback} isKeepOld={splitToSegments}>
            <TinyText color={'gray'}>Split to segments</TinyText>
          </KeepOld>
          <DialogContainerStyled.Switcher>
            <TinyText color={'gray'}>Colour</TinyText>
            <DrawingsColorButton
              color={color}
              changeColor={setColor}
              icon={color ? Icons.CircleFilled : Icons.Circle2D}
            />
          </DialogContainerStyled.Switcher>
        </Styled.OpenDialogBody>
        <Apply apply={apply} cancel={cancel} />
      </Styled.OpenPolygonDialog>
    </Styled.Container>
  );
};


export const OpenPolygonDialog = React.memo(OpenPolygonDialogComponent);
