import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './measurements-extractors-header.scss';

import { ToggleArea } from 'common/components/toggle-area';
import { State } from 'common/interfaces/state';
import { CEMeasurementsActions } from 'unit-cost-estimate/actions/creators';
import {
  MeasurementsExtractorsHeaderItemLayout,
} from 'unit-projects/components/measurements-extractors-header-item-layout';
import { MeasurementsExtractorsHeaderItem } from './measurements-extractors-header-item';


interface StateProps {
  extractorFunctionIds: string[];
  areLevelsShown: boolean;
}

interface DispatchProps {
  toggleLevelsShowStatus: (value: boolean) => void;
}

interface Props extends StateProps, DispatchProps {
}

class MeasurementsExtractorsHeaderComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <div className='measurements-extractors-header'>
        <MeasurementsExtractorsHeaderItemLayout>
          <div className='measurements-extractors-header__toggle-container'>
            <ToggleArea
              className='measurements-extractors-header__toggle'
              isSelected={this.props.areLevelsShown}
              onChange={this.props.toggleLevelsShowStatus}
              onClick={this.onToggleClick}
            >
              Show Levels
            </ToggleArea>
          </div>
        </MeasurementsExtractorsHeaderItemLayout>
        {
          this.renderItems()
        }
      </div>
    );
  }

  private renderItems(): React.ReactNode[] {
    return this.props.extractorFunctionIds.map(
      id => (<MeasurementsExtractorsHeaderItem id={id} key={id}/>),
    );
  }

  @autobind
  private onToggleClick(): void {
    this.props.toggleLevelsShowStatus(!this.props.areLevelsShown);
  }
}

function mapStateToProps({ ceMeasurements }: State): StateProps {
  return {
    extractorFunctionIds: Object.keys(ceMeasurements.extractorFunctions),
    areLevelsShown: ceMeasurements.areLevelsShown,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    toggleLevelsShowStatus: value => dispatch(CEMeasurementsActions.toggleLevelsShowStatus(value)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const MeasurementsExtractorsHeader =
  connector(MeasurementsExtractorsHeaderComponent);
