import { Content, getManager } from 'common/components/page-header/content-manager';
import { getFastNavigation } from 'common/components/page-header/page-header-fast-navigation';
import { FastNavigationLinks } from '../../../../units/2d/constants/fast-navigation-links';
import { withActiveNavigationType } from './with-active-navigation-type-helpers';

interface OwnProps {
  activeNavigationType: FastNavigationLinks;
}

interface GetFastNavigationContentsType {
  manager: React.ComponentType<OwnProps>;
  link: React.ComponentType<OwnProps>;
}

export function getFastNavigationContents(contents: Content[]): GetFastNavigationContentsType {
  const manager = getManager(contents);
  const link = getFastNavigation(contents);

  return  { manager: withActiveNavigationType(manager), link: withActiveNavigationType(link) };
}
