import React from 'react';

import { FlattenInterpolation } from 'styled-components';
import { DialogContainer } from 'common/components/dialog-container';
import { Property } from 'common/components/property';
import { useOpenMenuWithPosition } from 'common/hooks';
import { CustomSelectValue } from '../values';
import { SelectValue } from '../values/select-value';
import { Styled } from './styled';

interface Props {
  name: string;
  elements: Record<string, string[]>;
  optionRenderer?: (value: string) => React.ReactNode;
  editorRenderer?: () => React.ReactNode;
  defaultValue?: string;
  getElementLabel?: (value: string) => string;
  dialogStyles?: FlattenInterpolation<any>;
}

const PropertyWithMenuEditorComponent: React.FC<Props> = ({
  name,
  elements,
  optionRenderer,
  editorRenderer,
  defaultValue,
  getElementLabel,
  dialogStyles,
}) => {
  const { onOpen, onClose, isOpen, position } = useOpenMenuWithPosition(true);
  return (
    <>
      <Property title={name} startEditing={editorRenderer ? onOpen : undefined}>
        {
          optionRenderer ? (
            <CustomSelectValue
              elements={elements}
              optionRenderer={optionRenderer}
              selectedOptionRenderer={optionRenderer}
            />
          ) : (
            <SelectValue
              elements={elements}
              defaultValue={defaultValue}
              getElementLabel={getElementLabel}
            />
          )
        }
      </Property>
      {
        isOpen ? (
          <DialogContainer
            onClose={onClose}
            x={position.x}
            y={position.y}
            blockEvents={true}
            screenBorderMargin={20}
          >
            <Styled.EditorContainer styles={dialogStyles}>
              {editorRenderer()}
            </Styled.EditorContainer>
          </DialogContainer>
        ) : null
      }
    </>
  );
};

export const PropertyWithMenuEditor = React.memo(PropertyWithMenuEditorComponent);

