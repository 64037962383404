import styled from 'styled-components';


const Container = styled.div`
  width: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

export const Styled = {
  Container,
};
