import classNames from 'classnames';
import * as React from 'react';

import './classification-revit-tree-item-rounded-button.scss';

import { TooltipContainer } from 'common/components/tooltip-container';
import { IconButton } from 'common/UIKit';

interface Props {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  controlName: string;
  tootlipText: string;
  className?: string;
}

export class ClassificationRevitTreeItemRoundedButton extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const className = classNames('classification-revit-tree-item-rounded-button', this.props.className);
    return (
      <IconButton
        size='medium'
        className={className}
        onClick={this.props.onClick}
        controlName={this.props.controlName}
      >
        {this.props.children}
        <TooltipContainer
          className='classification-revit-tree-item-rounded-button__tooltip'
        >
          {this.props.tootlipText}
        </TooltipContainer>
      </IconButton>
    );
  }
}
