import * as React from 'react';

export const KreoIconAngle: React.FC = () => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <g id='Icons/24x24/Tools/Angle' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        className='icon__color-light'
        // eslint-disable-next-line max-len
        d='M5,19 L5,4.5 C5,4.22385763 4.77614237,4 4.5,4 C4.22385763,4 4,4.22385763 4,4.5 L4,19.5 C4,19.7761424 4.22385763,20 4.5,20 L19.5,20 C19.7761424,20 20,19.7761424 20,19.5 C20,19.2238576 19.7761424,19 19.5,19 L5,19 Z'
        id='color2'
        fill='currentColor'
        fillRule='nonzero'
      />
      <path
        // eslint-disable-next-line max-len
        d='M4.5,8 C5.18975874,8 5.7707793,8.46556353 5.94596444,9.09959337 C10.5880651,9.73822223 14.2618609,13.4119652 14.9012493,18.0540128 C15.5344365,18.2292207 16,18.8102413 16,19.5 C16,20.3284271 15.3284271,21 14.5,21 C13.6715729,21 13,20.3284271 13,19.5 C13,18.8844299 13.3707998,18.3554648 13.901226,18.1242781 C13.3001972,13.9788554 10.0211646,10.6998162 5.87484533,10.0987555 C5.64453523,10.6292002 5.11557008,11 4.5,11 C3.67157288,11 3,10.3284271 3,9.5 C3,8.67157288 3.67157288,8 4.5,8 Z'
        id='color1'
        fill='currentColor'
        fillRule='nonzero'
      />
    </g>
  </svg>
);
