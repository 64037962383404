import styled, { css, keyframes } from 'styled-components';

const WIDTH = 265;

const ShakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-4px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(8px, 0, 0);
  }
`;


const ContainerWithShake = css`
  animation: ${ShakeAnimation} 0.75s ease both;
`;

const Container = styled.div<{ shake?: boolean }>`
  width: ${WIDTH}px;
  border: 1px solid ${p => p.theme.color.background};
  background-color: ${p => p.theme.color.backgroundRush};
  box-shadow: ${p => p.theme.shadow.xl};
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;

  ${p => p.shake ? ContainerWithShake : ''}

  .mention-input__control {
    min-height: 30px;
  }
  
  .mention-input__highlighter {
    max-height: 250px;
  }

  textarea {
    max-height: 250px;
    overflow-y: auto;
  }
`;

export const Styled = {
  WIDTH,
  Container,
  ShakeAnimation,
};
