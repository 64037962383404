import { H5 } from '@kreo/kreo-ui-components';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { SubscriptionCustomOfferCardStyled } from './subscription-custom-offer-card';
import { SubscriptionListingHeadStyled } from './subscription-listing-head';
import { SubscriptionOfferCardStyled } from './subscription-offer-card';

const COLLAPSE_PLAN_HEIGHT = '178px';

function getOffersStyle(
  isPinBlock: boolean,
  hideOffersStyle: boolean,
  background: string,
  rush: string,
): FlattenSimpleInterpolation {
  if (isPinBlock && !hideOffersStyle) {
    return css`
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      max-width: 1200px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      z-index: 1;
      height: fit-content;
      min-height: auto;
      border: 1px solid ${background};

      ${SubscriptionCustomOfferCardStyled.Description} {
        display: none;
      }
      ${SubscriptionCustomOfferCardStyled.Container} {
        max-height: 160px;
      }
      ${SubscriptionOfferCardStyled.Description} {
        display: none;
      }
      ${SubscriptionOfferCardStyled.PriceDescription} {
        display: none;
      }
      ${SubscriptionOfferCardStyled.Price} {
        height: 65px;
      }
      ${SubscriptionListingHeadStyled.Container} {
        height: 65%;
      }
      ${SubscriptionListingHeadStyled.Period} {
        margin-top: 50px;
      }
      ${SubscriptionOfferCardStyled.Container} {
        > button {
          margin-bottom: 20px;
        }
        :hover {
          height: 274px;
          background-color: ${rush};
          border: 1px solid ${background};
          border-radius: 0px 0px 20px 20px;
          box-shadow: 0px 15px 40px #00000029;
          > button {
            margin-top: 15px;
            margin-bottom: 10px;
          }
          ${SubscriptionOfferCardStyled.Description} {
            display: block;
          }
          ${SubscriptionOfferCardStyled.PriceDescription} {
            display: flex;
          }
        }
      }
    `;
  } else {
    return null;
  }
}

const Offers = styled.div<{ isPinBlock: boolean, planLength: number, hideOffersStyle: boolean }>`
  max-width: 1200px;
  height: auto;
  min-height: 280px;
  display: grid;
  grid-template-columns: ${p => p.planLength > 3 ? '240px 1fr' : '300px'} 1fr
    ${p => p.planLength > 2 && '1fr'} ${p => p.planLength > 1 && '1fr'};
  row-gap: 15px;
  background-color: ${props => props.theme.color.backgroundRush};
  border-radius: 40px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding-bottom: ${p => p.planLength > 4 ? 10 : 0}px;
  max-height: ${p => p.planLength > 4 ? 'fit content' : COLLAPSE_PLAN_HEIGHT};

  ${H5} {
    font-size: 21px;
  }

  ${p => getOffersStyle(p.isPinBlock, p.hideOffersStyle, p.theme.color.background, p.theme.color.backgroundRush)};
`;

const SubscriptionFeaturesWrapper = styled.div`

`;

export const Styled = {
  Offers,
  SubscriptionFeaturesWrapper,
};
