import classNames from 'classnames';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import './horizontal-selector.scss';

interface Props {
  name?: string;
  label?: string;
  items: React.ReactText[];
  itemsAvailabilityState?: boolean[];
  activeItem?: React.ReactText;
  className?: string;
  itemClassName?: string;
  onItemClick?: (item: React.ReactText) => void;
  renderer?: (item: React.ReactText) => React.ReactNode;
}

interface ItemProps {
  item: React.ReactText;
  disabled?: boolean;
  isSelected?: boolean;
  className?: string;
  onClick: (item: React.ReactText) => void;
  getRepresentation?: (item: React.ReactText) => React.ReactNode;
}

const SelectorItem: React.FC<ItemProps> = (props) => {
  const representation = props.getRepresentation ?
    props.getRepresentation(props.item) : props.item;
  const onDivClick = (): void => props.onClick && props.onClick(props.item);

  const className = classNames(
    'selector-item',
    props.className,
    {
      selected: props.isSelected,
      'selector-item--disabled': props.disabled,
    },
  );

  return (
    <div
      className={className}
      onClick={props.disabled ? null : onDivClick}
    >
      {representation}
    </div>
  );
};

export class HorizontalSelector extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className={classNames('horizontal-selector', this.props.className)}>
        {
          this.props.items &&
          this.props.items.map((item, index) => {
            return (
              <SelectorItem
                className={this.props.itemClassName}
                key={item}
                item={item}
                isSelected={this.props.activeItem === item}
                onClick={this.props.onItemClick}
                getRepresentation={this.props.renderer}
                disabled={this.props.itemsAvailabilityState && !this.props.itemsAvailabilityState[index]}
              />
            );
          })
        }
      </div>
    );
  }
}


export const HorizontalSelectorField: React.FC<Props> = (
  props: WrappedFieldProps & Props,
): JSX.Element => {
  const {
    input,
    meta,
    label,
    ...rest } = props;

  const onChange = (value: React.ReactText): void => input.onChange(value);

  return (
    <HorizontalSelector
      {...input}
      onItemClick={onChange} // eslint-disable-line react/jsx-no-bind
      {...rest}
    />
  );
};
