import { MenuItem } from '@kreo/kreo-ui-components';
import React from 'react';

interface Props {
  name: string;
  Icon: React.FC;
  onClick: () => void;
  controlName?: string;
  disabled?: boolean;
}

export const CreateTab: React.FC<Props> = ({
  name,
  Icon,
  disabled,
  onClick,
  controlName,
}) => {
  return (
    <MenuItem
      controlName={controlName}
      text={name}
      Icon={Icon}
      onClick={onClick}
      fontSize={14}
      withBorder={true}
      size={'m'}
      textColor={'mainFont'}
      disabled={disabled}
    />
  );
};
