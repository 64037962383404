import { IconButton, Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { State } from 'common/interfaces/state';
import { CommentCountMark } from 'unit-2d-comments/comment-count-mark';
import { TwoDNotificationsActions } from 'unit-2d-notification/store-slice';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { NotificationPanel } from '../notification-panel';
import { useBellClickCallback, useNotificationEffect } from './hooks';
import { Styled } from './styled';

interface StateProps {
  notificationCount: number;
  companyId: number;
}

interface DispatchProps {
  openPanel: () => void;
  loadNotification: () => void;
  updateLastAccess: () => void;
}

interface Props extends StateProps, DispatchProps {
  target: string;
}

const NotificationBellComponents: React.FC<Props> = ({
  target,
  notificationCount,
  companyId,
  loadNotification,
  updateLastAccess,
  openPanel,
}) => {
  const { sendEvent } = useAnalytics();
  useNotificationEffect(loadNotification, companyId);
  const handleBellClick = useBellClickCallback(openPanel, updateLastAccess);
  const canShowNotification = useAbility(Operation.Manage, Subject.Comment2d);

  const onHandleClick = useCallback(() => {
    if (canShowNotification) {
      handleBellClick();
      sendEvent(MetricNames.notification.openNotification, { target, notificationCount });
    }
  }, [canShowNotification, notificationCount]);

  return (
    <>
      <Styled.IconContainer>
        <UpgradeWrapper isNeedUpdate={!canShowNotification}>
          <IconButton
            Icon={Icons.Bellka2D}
            width={20}
            height={20}
            onClick={onHandleClick}
            mood={canShowNotification ? 'default' : 'disabled'}
          />
        </UpgradeWrapper>
        <CommentCountMark count={notificationCount}/>
      </Styled.IconContainer>
      <NotificationPanel />
    </>
  );
};

const mapStateToProps = (state: State): StateProps => {
  const twoDNotifications = state.twoDNotifications;
  return {
    notificationCount: twoDNotifications.bellCount,
    companyId: state.account.selectedCompany && state.account.selectedCompany.id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openPanel: () => dispatch(TwoDNotificationsActions.openPanel()),
    loadNotification: () => {
      dispatch(TwoDNotificationsActions.loadNotifications({}));
      dispatch(TwoDNotificationsActions.connectRealtime());
    },
    updateLastAccess: () => dispatch(TwoDNotificationsActions.updateLastAccess()),
  };
};

export const NotificationBell = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationBellComponents);
