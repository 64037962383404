import { AnyAction } from 'redux';
import {
  CE_ACTIVITY_ASSIGNMENT_INITIAL_STATE,
} from 'unit-cost-estimate/constants/ce-activity-assignment-initial-state';
import { CEActivityAssignmentState } from 'unit-cost-estimate/interfaces';
import { CEActivityAssignmentReducerMethods } from 'unit-cost-estimate/reducers/activity-assignment';


export default function(
  state: CEActivityAssignmentState = CE_ACTIVITY_ASSIGNMENT_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): CEActivityAssignmentState {
  if (action.type in CEActivityAssignmentReducerMethods) {
    return CEActivityAssignmentReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
