import { ElementTooltip, SvgComponent, Text } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { RenderIf } from 'common/components/render-if';
import { ExpandButton } from './expand-button';
import { Styled } from './styled';


const MAX_CONTAINER_COMMENT_HIGHT = 250;

interface Props {
  title: string;
  iconType: SvgComponent;
  tooltip: string;
  resolved: boolean;
  shouldFocus?: boolean;
  isSelected?: boolean;
  showExpand: boolean;
  setShowExpand?: (value: boolean) => void;
  onFocused?: () => void;
  onClick?: () => void;
}

export const CommentTypeContainer: React.FC<Props> = ({
  title,
  iconType,
  tooltip,
  resolved,
  children,
  shouldFocus,
  isSelected,
  onClick,
  onFocused,
  showExpand,
  setShowExpand,
}) => {

  const setRef = useCallback((node: HTMLDivElement) => {
    if (!node) {
      return;
    }
    const containerHight = node.offsetHeight;
    if (containerHight > MAX_CONTAINER_COMMENT_HIGHT && setShowExpand) {
      setShowExpand(false);
    }
    if (shouldFocus) {
      node.scrollIntoView();
      onFocused();
    }
  }, []);

  const onExpandClick = useCallback(() => {
    setShowExpand(false);
  }, [showExpand]);

  const Icon = iconType;

  return (
    <Styled.Container
      onClick={onClick}
      resolved={resolved}
      ref={setRef}
      isSelected={isSelected}
    >
      <Styled.Header>
        {iconType ?
          <ElementTooltip
            text={tooltip}
            position='top'
            speed='l'
          >
            <Icon/>
          </ElementTooltip>
          : null}
        <Text
          withEllipsis={true}
          fontSize={14}
        >
          {title}
        </Text>
      </Styled.Header>
      <Styled.Content isExpand={showExpand}>
        {children}
        <RenderIf condition={showExpand}>
          <ExpandButton onClick={onExpandClick}/>
        </RenderIf>
      </Styled.Content>
    </Styled.Container>
  );
};
