import autobind from 'autobind-decorator';
import * as React from 'react';

import './tree-selector.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { KreoScrollbars, MaterialInput } from 'common/UIKit';
import { KreoDialogActionsPanel } from 'common/UIKit/dialogs/kreo-dialog-actions-panel';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { Item, TreeView } from '../treeview/tree-view';

interface Props {
  name: string;
  filter: string;
  items: Item[];
  isOnlyLeavesSelect?: boolean;
  item: Item | null;
  onSelect: (item: Item) => void;
  onCancel: () => void;
}

interface State {
  filterQuery: string;
  selectedItem: Item;
}

export class TreeSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterQuery: null,
      selectedItem: props.item,
    };
  }

  public render(): JSX.Element {
    return (
      <div className='tree-selector'>
        <div className='tree-selector__header'>
          <div className='tree-selector__header-top'>
            <b className='tree-selector__title'>Select {this.props.name}</b>
          </div>
          <MaterialInput
            value={this.state.filterQuery ? this.state.filterQuery : ''}
            onChange={this.updateFilterQuery}
            displayedType={MaterialComponentType.Native}
            searchType={true}
          />
        </div>
        <div className='tree-selector__content'>
        {
          this.props.items && this.props.items.length ? (
            <KreoScrollbars showShadowTop={true}>
              <TreeView
                filter={this.state.filterQuery}
                items={this.props.items}
                onClick={this.onTreeItemClick}
                isOnlyLeavesSelect={this.props.isOnlyLeavesSelect}
                selectedCode={this.state.selectedItem ? this.state.selectedItem.code : null}
              />
            </KreoScrollbars>
          ) : <SvgSpinner size='middle'/>
        }
        </div>
        <KreoDialogActionsPanel
          size='medium'
          submitBtnName='Apply'
          onSubmit={this.onSelect}
          submitDisabled={!this.state.selectedItem}
          onCancel={this.props.onCancel}
        />
      </div>
    );
  }

  @autobind
  private onTreeItemClick(item: Item): void {
    this.setState({
      selectedItem: item,
    });
  }

  @autobind
  private onSelect(): void {
    this.props.onSelect(this.state.selectedItem);
  }

  @autobind
  private updateFilterQuery(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      filterQuery: event.target.value,
    });
  }
}
