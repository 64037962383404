import * as t from 'io-ts';

import { QtoReportColumnsModelC } from './quantity-take-off-report-columns-model';
import { QtoReportRowC } from './quantity-take-off-report-row';

export const QtoReportC = t.type({
  id: t.number,
  projectId: t.number,
  name: t.string,
  showDiff: t.boolean,
  showPivot: t.boolean,

  basicColumns: QtoReportColumnsModelC,
  pivotColumns: QtoReportColumnsModelC,

  firstLevelRows: t.array(t.string),
  rows: t.array(QtoReportRowC),
});


export type QtoReport = t.TypeOf<typeof QtoReportC>;
