const prefix = '@bid-pricing';

export const BidPricingActionTypes = {
  ACCEPT_REQUEST: `${prefix}/ACCEPT_REQUEST`,
  GET_INFO_REQUEST: `${prefix}/GET_INFO_REQUEST`,
  GET_INFO_SUCCEEDED: `${prefix}/GET_INFO_SUCCEEDED`,
  SET_WINNER_REQUEST: `${prefix}/SET_WINNER_REQUEST`,
  SET_WINNER_SUCCEEDED: `${prefix}/SET_WINNER_SUCCEEDED`,
  CANCEL_REQUEST: `${prefix}/CANCEL_REQUEST`,
  STOP_REQUEST: `${prefix}/STOP_REQUEST`,
  EDIT_REQUEST: `${prefix}/EDIT_REQUEST`,
  SET_DIALOG_TYPE: `${prefix}/SET_DIALOG_TYPE`,
  REMOVE_SUBCONTRACTOR_REQUEST: `${prefix}/REMOVE_SUBCONTRACTOR_REQUEST`,
  REMOVE_SUBCONTRACTOR_SUCCESSED: `${prefix}/REMOVE_SUBCONTRACTOR_SUCCESSED`,
  REMOVE_SUBCONTRACTORS_BY_PACKAGE: `${prefix}/REMOVE_SUBCONTRACTORS_BY_PACKAGE`,
  CREATE_NEW_BID_REQUEST: `${prefix}/CREATE_NEW_BID_REQUEST`,
  CREATE_NEW_BID_SUCCEEDED: `${prefix}/CREATE_NEW_BID_SUCCEEDED`,
  UPDATE_BID_DESCRIPTION_REQUEST: `${prefix}/UPDATE_BID_DESCRIPTION_REQUEST`,
  UPDATE_BID_DESCRIPTION_SUCCEEDED: `${prefix}/UPDATE_BID_DESCRIPTION_SUCCEEDED`,
  GET_SUBCONTRACTOR_PACKAGES_REQUEST: `${prefix}/GET_SUBCONTRACTOR_PACKAGES_REQUEST`,
  GET_SUBCONTRACTOR_PACKAGES_SUCCEEDED: `${prefix}/GET_SUBCONTRACTOR_PACKAGES_SUCCEEDED`,
  SET_SUBCONTRACTOR_PACKAGE: `${prefix}/SET_SUBCONTRACTOR_PACKAGE`,
  INVITE_USERS_TO_PACKAGE: `${prefix}/INVITE_USERS_TO_PACKAGE`,
};

