import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';

import './quantity-take-off-report-table-name-cell.scss';

import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { TreeTableCellName } from 'common/components/tree-table/tree-table-cell-name';
import { QtoReportRowUpdateType } from '../../enums/qto-report-row-update-type';
import {
  QuantityTakeOffReportRowDiffStatusUtils,
} from '../../utils/quantity-take-off-tree-table/row-diff-status-utils';
import { QuantityTakeOffReportTableNameCellIndicator } from './quantity-take-off-report-table-name-cell-indicator';


interface Params extends Ag.ICellRendererParams {
  onRename: (node: Ag.RowNode, name: string) => void | null;
  onAggregate?: (node: Ag.RowNode) => void;
}

export class QuantityTakeOffTreeTableNameCell implements Ag.ICellRendererComp {
  private params: Params;
  private eGui: HTMLDivElement;
  private name: TreeTableCellName;
  private eName: HTMLElement;
  private indicator: QuantityTakeOffReportTableNameCellIndicator;

  public init(params: Params): void {
    this.params = params;
    this.eGui = document.createElement('div');
    this.eGui.className = 'quantity-take-off-report-table-name-cell';
    if (params.data.type === TreeTableRowType.Group && params.onAggregate) {
      const button = document.createElement('div');
      button.className = 'quantity-take-off-report-table-name-cell__btn set-aggregation';
      button.title = 'Sum of quantities for the group';
      button.onclick = this.onAggregate;
      this.eGui.appendChild(button);
    }
    this.name = new TreeTableCellName();
    this.name.init(params);
    this.indicator = new QuantityTakeOffReportTableNameCellIndicator();
    const diffStatus = params.context.hideDiff
      ? QtoReportRowUpdateType.Undefined
      : QuantityTakeOffReportRowDiffStatusUtils.getDiffStatus(this.params.data);
    this.indicator.init({ diffStatus });
    this.eName = this.name.getGui();
    this.eGui.appendChild(this.eName);
    const indicator = this.indicator.getGui();
    this.eGui.appendChild(indicator);
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: Params): boolean {
    this.params = params;
    this.name.refresh(this.getEditTitleParams());
    const diffStatus = params.context.hideDiff
      ? QtoReportRowUpdateType.Undefined
      : QuantityTakeOffReportRowDiffStatusUtils.getDiffStatus(this.params.data);
    this.indicator.refresh({ diffStatus });
    return true;
  }

  public destroy(): void {
    this.name.destroy();
  }

  private getEditTitleParams(): any {
    return {
      value: this.params.value,
      onChange: this.params.onRename
        ? (name) => this.params.onRename(this.params.node, name)
        : null,
    };
  }

  @autobind
  private onAggregate(): void {
    this.params.onAggregate(this.params.node);
  }
}
