import { MenuItem } from '@material-ui/core';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './index.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import {
  Checkbox,
  EnhancedVirtualList,
  IconButton,
  KreoButton,
  KreoIconNavbarBack,
  KreoScrollbars,
} from 'common/UIKit';
import { KreoScrollbarsApi } from 'common/UIKit/scrollbars/kreo-scrollbars';

import { PopoverButton } from '../../../../components/controls/buttons';
import { ModuleStatus, ScenarioModule, ScenarioState } from '../../../projects/interfaces/scenario-state';
import { ScenarioStateHelper } from '../../../projects/utils/scenario-state-helper';
import { WorkPackageAction } from '../../actions';
import { WorkPackagesData } from '../../interfaces';
import { ActivityGroupItem } from '../activity-group-item';
import { ActivityItem } from '../activity-item';
import { DTButton } from '../dt-button';
import { PackageItem } from '../package-item';


const ACTIVITY_ITEM_HEIGHT: number = 44;

interface ReduxProps {
  packageLoadStatus: RequestStatus;
  packages: WorkPackagesData.GropedWorkPackage[];
  activePackage: WorkPackagesData.GropedWorkPackage;
  selectedGroup: number[];
  isAllGroupSelected: boolean;
  activeGroup: WorkPackagesData.GroupOfActivity;
  selectedActivity: number[];
  isAllActivitySelected: boolean;
  scenarioState: ScenarioState;
  subgroupScrollPosition: number;
}

interface ReduxActions {
  loadPackages: (id: number) => void;
  selectAllGroup: (checked: boolean) => void;
  selectAllGroupActivity: (checked: boolean) => void;
  exitFromActiveGroup: () => void;
  createNewPackage: () => void;
  resetActivePackage: () => void;
  replaceSelectedItems: (idPackage: number, isGroup: boolean) => void;
}

interface Props extends ReduxProps, ReduxActions {
  scenarioId: number;
  getIdsByHover: (ids: number[]) => void;
}

class LeftAreaComponent extends React.Component<Props> {
  private scrollbarsApi: KreoScrollbarsApi;

  public componentDidMount(): void {
    this.loadDataIfNeeded(this.props, null);
  }

  public componentDidUpdate(prevProps: Props): void {
    this.loadDataIfNeeded(this.props, prevProps);
    if (Number.isInteger(this.props.subgroupScrollPosition)) {
      this.scrollbarsApi.setScrollTop((this.props.subgroupScrollPosition - 4) * ACTIVITY_ITEM_HEIGHT);
    }
  }

  public render(): JSX.Element {
    const { packageLoadStatus, packages, activePackage, activeGroup, scenarioState } = this.props;
    const workPackagesReady = scenarioState &&
      scenarioState.moduleStatuses[ScenarioModule.Workpackages] === ModuleStatus.Ready;
    return (
        <div className='wp-left-area'>
          <div className='wp-left-area__packages_container'>
            <div className='wp-left-area__packages_header'>
              Work Packages
            </div>
            <div className='wp-left-area__packages_content'>
              <KreoScrollbars>
                {
                  packageLoadStatus === RequestStatus.Loading || !workPackagesReady ?
                    <SvgSpinner size='middle' /> :
                    <React.Fragment>
                      {packages.map((x, index): JSX.Element => {
                        const isActive = activePackage && activePackage.id === x.id;
                        return (
                          <PackageItem
                            key={index}
                            packageItem={x}
                            isActive={isActive}
                          />
                        );
                      })}
                    </React.Fragment>
                }
              </KreoScrollbars>
            </div>
            <div className='wp-left-area__packages_footer'>
              <DTButton
                title='Create New'
                onClick={this.props.createNewPackage}
              />
            </div>
          </div>
          { activeGroup ? this.getActivitiesContent() : (activePackage && this.getGroupsContent())}
        </div>
    );
  }

  private loadDataIfNeeded(currentProps: Props, prevProps: Props): void {
    const currentState = currentProps.scenarioState;
    const prevState = prevProps && prevProps.scenarioState;
    const needToLoad = ScenarioStateHelper.shouldLoadModel(currentState, prevState, ScenarioModule.Workpackages);

    if (needToLoad) {
      this.props.loadPackages(currentProps.scenarioId);
    }
  }

  // Activities

  @autobind
  private getActivitiesContent(): JSX.Element {
    const { activeGroup, selectedActivity, isAllActivitySelected } = this.props;

    const title = selectedActivity.length === 0
      ? activeGroup.nameGroup
      : `${selectedActivity.length} activities selected`;

    const countContent = selectedActivity.length === 0 ? `${activeGroup.count} activities` : this.getSelectionMenu();

    return (
      <div className='wp-left-area__activities-container'>
        <div className='wp-left-area__activities-header'>
          <IconButton size='medium' onClick={this.onExitFromGroup}>
            <KreoIconNavbarBack />
          </IconButton>
          <div className='wp-left-area__activity-name' title={title}>{title}</div>
          <div className='wp-left-area__activity-count'>{countContent}</div>
        </div>
        <div className='wp-left-area__activities-sub-header'>
          <Checkbox
            checked={isAllActivitySelected}
            onChange={this.checkAllActivity}
          />
        </div>
        <div className='wp-left-area__activities-content'>
          {activeGroup.activities ?
            <EnhancedVirtualList
              sendScrollBarApi={this.saveActivitiesScrollbarsApi}
              objects={activeGroup.activities}
              itemHeight={ACTIVITY_ITEM_HEIGHT}
              renderedItemsCount={20}
              renderItem={this.renderActivity}
            /> : null}
        </div>
      </div>
    );
  }

  @autobind
  private saveActivitiesScrollbarsApi(api: KreoScrollbarsApi): void {
    this.scrollbarsApi = api;
  }

  @autobind
  private renderActivity(activity: WorkPackagesData.Activity): JSX.Element {
    const { selectedActivity } = this.props;
    const isSelected = selectedActivity.some((ac: number): boolean => ac === activity.id);

    return (
      <ActivityItem
        activity={activity}
        isSelected={isSelected}
        getIdsByHover={this.props.getIdsByHover}
      />
    );
  }

  @autobind
  private checkAllActivity(): void {
    const { isAllActivitySelected } = this.props;

    this.props.selectAllGroupActivity(!isAllActivitySelected);
  }

  // Group of Activities

  @autobind
  private getGroupsContent(): JSX.Element {
    const { activePackage, selectedGroup, isAllGroupSelected } = this.props;

    const title = selectedGroup.length === 0
      ? activePackage.name
      : `${selectedGroup.length} activities selected`;

    const countContent = selectedGroup.length === 0 ? `${activePackage.count} activities` : this.getSelectionMenu();

    return (
      <div className='wp-left-area__activities-container'>
        <div className='wp-left-area__activities-header'>
          <div className='wp-left-area__activity-name' title={title}>{title}</div>
          <div className='wp-left-area__activity-count'>{countContent}</div>
        </div>
        <div className='wp-left-area__activities-sub-header'>
          <Checkbox
            checked={isAllGroupSelected}
            onChange={this.checkAllGroup}
          />
        </div>
        <div className='wp-left-area__activities-content'>
          {
            activePackage.activitiesGroups && activePackage.activitiesGroups.length !== 0 ?
              <EnhancedVirtualList
                sendScrollBarApi={this.saveActivitiesScrollbarsApi}
                objects={activePackage.activitiesGroups}
                itemHeight={ACTIVITY_ITEM_HEIGHT}
                renderedItemsCount={20}
                renderItem={this.renderGroup}
              /> : null
          }
        </div>
      </div>
    );
  }

  @autobind
  private renderGroup(group: WorkPackagesData.GroupOfActivity): JSX.Element {
    const { selectedGroup } = this.props;
    const isSelected = selectedGroup.some((gr: number): boolean => gr === group.idGroup);

    return (
      <ActivityGroupItem
        groupItem={group}
        isSelected={isSelected}
        getIdsByHover={this.props.getIdsByHover}
      />
    );
  }

  @autobind
  private checkAllGroup(): void {
    const { isAllGroupSelected } = this.props;

    this.props.selectAllGroup(!isAllGroupSelected);
  }

  @autobind
  private onExitFromGroup(): void {
    this.props.exitFromActiveGroup();
  }

  // Other

  @autobind
  private onSelectionCancel(): void {
    const { activeGroup } = this.props;
    if (activeGroup) {
      this.props.selectAllGroupActivity(false);
    } else {
      this.props.selectAllGroup(false);
    }
  }

  @autobind
  private getSelectionMenu(): JSX.Element {
    const { packages } = this.props;

    return (
      <React.Fragment>
        <KreoButton size='small' mode='add' onClick={this.onSelectionCancel}>Cancel</KreoButton>
        <PopoverButton
          btnText='Move to ...'
          btnClassName='move-to'
        >
          {packages.map((pack, index): JSX.Element => {
            return (
              <MenuItem
                key={index}
                value={pack.id}
                className='wp-left-area__menu-item'
                onClick={this.onMenuItemClick.bind(this, pack.id)} // eslint-disable-line react/jsx-no-bind
              >
                {pack.name}
              </MenuItem>
            );
          })}
        </PopoverButton>
      </React.Fragment>
    );
  }

  private onMenuItemClick(wpId: number): void {
    const { activeGroup } = this.props;
    this.props.replaceSelectedItems(wpId, activeGroup ? false : true);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const scenarioGuid = state.scenarios.editScenario && state.scenarios.editScenario.scenarioGuid;
  const scenarioState = state.projects.scenarioStates[scenarioGuid];

  return {
    packageLoadStatus: state.workpackages.statuses.packages,
    packages: state.workpackages.groupedPackages,
    activePackage: state.workpackages.activePackage,
    selectedGroup: state.workpackages.selectedGroup,
    isAllGroupSelected: state.workpackages.isAllGroupSelected,
    activeGroup: state.workpackages.activeGroup,
    selectedActivity: state.workpackages.selectedActivities,
    isAllActivitySelected: state.workpackages.isAllActivitySelected,
    subgroupScrollPosition: state.workpackages.subgroupScrollPosition,
    scenarioState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadPackages: (id: number): void => {
      dispatch(WorkPackageAction.loadData(id));
    },
    selectAllGroup: (checked: boolean): void => {
      dispatch(WorkPackageAction.selectAllGroup(checked));
    },
    selectAllGroupActivity: (checked: boolean): void => {
      dispatch(WorkPackageAction.selectAllGroupActivity(checked));
    },
    exitFromActiveGroup: (): void => {
      dispatch(WorkPackageAction.setActiveGroup(null));
    },
    createNewPackage: (): void => {
      dispatch(WorkPackageAction.createNewPackage());
    },
    resetActivePackage: (): void => {
      dispatch(WorkPackageAction.setActive(null));
    },
    replaceSelectedItems: (idPackage: number, isGroup: boolean): void => {
      dispatch(isGroup ?
        WorkPackageAction.replaceGroup(idPackage, null) :
        WorkPackageAction.replaceActivity(idPackage, null),
      );
    },
  };
};


const connector = connect(mapStateToProps, mapDispatchToProps);

export const LeftArea = connector(LeftAreaComponent);
