import * as React from 'react';

import './exit-isolation-button.scss';


import { KreoIconExitIsolateMode } from 'common/UIKit';
import { GroupType } from '../../../../utils/gantt-chart';

interface ButtonProps {
  visible: boolean;
  activityGroupType: GroupType | null;
  exitIsolation: () => void;
}

export class ExitIsolationButton extends React.Component<ButtonProps> {
  public render(): React.ReactNode {
    if (!this.props.visible) {
      return null;
    }

    const activityGroupTypeName = this.props.activityGroupType === GroupType.WorkPackage
      ? 'WORK PACKAGE'
      : 'ACTIVITY GROUP';

    return (
      <div
        className='exit-isolation-button'
        onClick={this.props.exitIsolation}
      >
        <KreoIconExitIsolateMode />
        <div className='exit-isolation-button__label'>
          EXIT {activityGroupTypeName} ISOLATION
        </div>
      </div>
    );
  }
}
