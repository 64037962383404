import autobind from 'autobind-decorator';
import * as React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

import './project-tile-progress.scss';

import { ProgressBar } from 'common/components/progress-bar';
import { RenewableExecutor } from 'common/utils/renewable-executor';
import { KnownViewModel, KnownViewModelNames } from '../../enums/known-view-model';
import { ViewModelStatus } from '../../enums/view-model-status';
import { ViewModelStatusPair } from '../../interfaces/view-model-status-pair';
import { ProjectTileProgressBar } from './project-tile-progress-bar';
import { ProjectTimeProgressWithoutDuration } from './project-tile-progress-without-duration';
import { FullPercentageData, ProjectTileUtils } from './utils';

interface Props {
  viewModelStatuses: ViewModelStatusPair[];
}

interface State extends FullPercentageData {
  isOpenDescription: boolean;
}

export class ProjectTileProgress extends React.PureComponent<Props, State> {
  private timer: number;
  private renewableExecutor: RenewableExecutor;

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenDescription: false,
      ...ProjectTileUtils.getCommonTime(this.props.viewModelStatuses),
    };
    this.renewableExecutor = new RenewableExecutor(this.update, this.state.stepTime);
  }

  public componentDidMount(): void {
    if (!this.state.allReady) {
      this.renewableExecutor.start();
    }
  }

  public componentWillUnmount(): void {
    this.renewableExecutor.cancel();
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (prevProps.viewModelStatuses !== this.props.viewModelStatuses) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.setState(ProjectTileUtils.getCommonTime(this.props.viewModelStatuses));
    }

    if (prevState.allReady !== this.state.allReady) {
      if (this.state.allReady) {
        this.renewableExecutor.cancel();
      } else {
        this.renewableExecutor.start();
      }
    }

    if (!this.state.allReady && prevState.stepTime !== this.state.stepTime) {
      this.renewableExecutor.updateTime(this.state.stepTime);
    }
  }

  public render(): React.ReactNode {
    if (this.state.allReady) {
      return null;
    }
    return (
      <div
        className='project-tile-progress'
        onMouseEnter={this.onMouseOver}
        onMouseLeave={this.onMouseOut}
      >
        <CSSTransition
          in={this.state.isOpenDescription}
          mountOnEnter={true}
          unmountOnExit={true}
          timeout={200}
          className='project-tile-progress__description'
        >
          <div className='project-tile-progress__description'>
            {
              this.props.viewModelStatuses.map(x => {
                if (
                  x.status !== ViewModelStatus.Waiting &&
                  x.status !== ViewModelStatus.Calculating ||
                  x.viewModelType === KnownViewModel.ProjectCreating
                ) {
                  return undefined;
                }
                if (!x.startTime || !x.estimatedDuration) {
                  return (
                    <ProjectTimeProgressWithoutDuration
                      key={x.viewModelType}
                      status={x.status}
                    >
                      {KnownViewModelNames[x.viewModelType]}
                    </ProjectTimeProgressWithoutDuration>
                  );
                }
                return (
                  <ProjectTileProgressBar
                    key={x.viewModelType}
                    viewModelStatus={x}
                  />
                );
              })
            }
          </div>
        </CSSTransition>
        <ProgressBar value={Math.floor(this.state.percent * 100)} />
      </div>
    );
  }

  @autobind
  private update(): void {
    this.setState(
      ProjectTileUtils.getPercentage(
        this.state.targetTime,
        this.state.startTime,
      ),
    );
  }

  @autobind
  private onMouseOver(): void {
    this.setState({ isOpenDescription: true });
  }

  @autobind
  private onMouseOut(): void {
    this.setState({ isOpenDescription: false });
  }
}
