import * as React from 'react';

export const KreoIconTopActivity: React.FC = () => {
  return (
    <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='icon_activity' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <rect id='Rectangle' x='0' y='0' width='20' height='20'/>
            <path
            // eslint-disable-next-line max-len
              d='M5,3 L15,3 C16.1045695,3 17,3.8954305 17,5 L17,12 C17,13.1045695 16.1045695,14 15,14 L5,14 C3.8954305,14 3,13.1045695 3,12 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M5,5 L5,12 L15,12 L15,5 L5,5 Z M11.7928932,6.29289322 C12.1834175,5.90236893 12.8165825,5.90236893 13.2071068,6.29289322 C13.5976311,6.68341751 13.5976311,7.31658249 13.2071068,7.70710678 L10.2071068,10.7071068 C9.81658249,11.0976311 9.18341751,11.0976311 8.79289322,10.7071068 L6.79289322,8.70710678 C6.40236893,8.31658249 6.40236893,7.68341751 6.79289322,7.29289322 C7.18341751,6.90236893 7.81658249,6.90236893 8.20710678,7.29289322 L9.5,8.58578644 L11.7928932,6.29289322 Z M15,15 C15.5522847,15 16,15.4477153 16,16 C16,16.5522847 15.5522847,17 15,17 L5,17 C4.44771525,17 4,16.5522847 4,16 C4,15.4477153 4.44771525,15 5,15 L15,15 Z'
              id='icon'
              fill='currentColor'
              fillRule='nonzero'
            />
        </g>
    </svg>
  );
};
