
import {
  Actions
} from '../constants'


/**
 *
 * @param {*} value
 */
export function setEditedType(value: any): any {
  return {
    type: Actions.TopPanel.TOPPANEL_EDITED_TYPE,
    payload: value
  }
}

/**
 * expand left menu
 */
export function expandLeftMenu(value: boolean): any {
  return {
    type: Actions.TopPanel.TOPPANEL_LEFT_MENU_EXPAND,
    payload: value
  }
}

/**
 * collapse/expand left menu
 *
 *
 * @param {*} value
 */
export function activateExpandLeftMenuAction(value: boolean): any {
  return {
    type: Actions.TopPanel.TOPPANEL_LEFT_MENU_ACTIVATE_ACTION,
    payload: value
  }
}

/**
 * set mobile property
 *
 * @param {*} value
 */
export function setMobile(value: boolean): any {
  return {
    type: Actions.TopPanel.TOPPANEL_SET_MOBILE,
    payload: value
  }
}
