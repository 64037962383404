import { Text, RectangleButton, Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { Styled } from '../styled';
import { useCreateCounts } from './use-create-counts';

interface Props {
  count: number;
}

export const FoundedResults: React.FC<Props> = ({ count }) => {
  const createCount = useCreateCounts();
  const canCreateCount = useAbility(Operation.Update, Subject.Takeoff2DMeasurement);
  return (
    <Styled.Container>
      <Text color='gray' fontSize={12}>
        {`${count} results found`}
      </Text>
      <RectangleButton
        borderRadius={5}
        mood={canCreateCount ? 'secondary' : 'disabled'}
        Icon={Icons.PlusSmall}
        width={110}
        height={20}
        iconHeight={10}
        fontSize={12}
        text='Create count'
        onClick={createCount}
      />
    </Styled.Container>
  );
};
