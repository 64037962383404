import autobind from 'autobind-decorator';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import './index.scss';

import { ProjectStatus } from 'common/enums/project-status';
import {
  DATE_FORMAT,
  KreoButton,
  KreoDialogActions,
  MaterialDatePickerField,
  MaterialInputField,
  MaterialInputProps,
} from 'common/UIKit';
import { State } from '../../../common/interfaces/state';
import { ADD_BID_PRICING } from '../../../constants/forms';
import { BidPricingActions } from '../../../units/projects/actions/creators/bid-pricing';
import { KreoFormDialog } from '../base/kreo-form-dialog';
import { KreoConfirmationDialog } from '../kreo-confirmation-dialog';
import { Required } from '../validators';

export const BidPricingDialogName = 'bidPricingDialog';

interface BidDialogActionsProps {
  isEdit: boolean;
  onDeactivate: () => void;
}

const BidDialogActions: React.FC<BidDialogActionsProps> = props => {
  if (!props.isEdit) {
    return (
      <div className='bid-pricing-actions'>
        <KreoButton size='large' mode={'success'} htmlButtonType='submit' controlName={'activate-bid-pricing-button'}>
          Activate
        </KreoButton>
      </div>
    );
  } else {
    return (
      <div className='bid-pricing-actions edit'>
        <KreoButton
          onClick={props.onDeactivate}
          mode='ghost'
          htmlButtonType={'button'}
          size='large'
          controlName={'deactivate-bid-pricing-button'}
        >
          Deactivate
        </KreoButton>
        <KreoButton size='large' mode={'submit'} controlName={'save-bid-pricing-button'}>
          Save
        </KreoButton>
      </div>
    );
  }
};

interface FormData {
  startDate?: Date;
  endDate?: Date;
  calculationId?: number;
  description?: string;
  id?: number;
  isPrivate?: boolean;
}

interface StateProps {
  show: boolean;
  isEdit: boolean;
  initValues: FormData;
}

interface DispatchProps {
  showCancelDialog: () => void;
  onFinish: () => void;
}

interface Props extends StateProps, DispatchProps, FormData {}

interface InjectedProps extends Props, InjectedFormProps<FormData, Props> {}

const deactivateBidPricingDialogName = 'deactivate_bid-pricing';

const validateFields = {
  startDate: 'Required',
  endDate: 'Required',
};
const validateRequired = [Required];

class BidPricingDialogComponent extends React.Component<InjectedProps> {
  public componentDidUpdate(prevProps: InjectedProps): void {
    if (this.props.isEdit && this.props.show && !prevProps.show) {
      this.props.initialize(this.props.initValues);
    }
  }

  public render(): React.ReactNode {
    const { endDate, startDate } = this.props;

    return (
      <KreoFormDialog
        name={BidPricingDialogName}
        bodyClassName='bid-pricing-dialog'
        modal={true}
        title={`${!this.props.isEdit ? 'Start' : 'Update'} Bid Pricing`}
        handleSubmit={this.props.handleSubmit}
        onDialogClose={this.onDialogClose}
        actionsComponent={[
          <BidDialogActions
            key={0}
            isEdit={this.props.isEdit}
            onDeactivate={this.props.showCancelDialog}
          />,
        ]}
        formName={ADD_BID_PRICING}
        validateFields={validateFields}
      >
        <KreoConfirmationDialog
          name={deactivateBidPricingDialogName}
          yesText='Deactivate'
          noText='Cancel'
          title='Bid Pricing Deactivation'
          onYes={this.props.onFinish}
        >
          Once you deactivate Bid Pricing, the data received from all the subcontractors will be
          lost.
        </KreoConfirmationDialog>
        <div className='bid-pricing-dialog__date-input-area'>
          <div className='bid-pricing-dialog__date-input-item'>
            <Field<MaterialInputProps>
              name='startDate'
              component={MaterialDatePickerField}
              validate={validateRequired}
              label='Start Date'
              type='text'
              isFloatingLabel={false}
              max={endDate && moment(endDate).format(DATE_FORMAT)}
              className='bid-pricing-dialog__date-input'
            />
          </div>
          <div className='bid-pricing-dialog__date-input-item'>
            <Field<MaterialInputProps>
              name='endDate'
              component={MaterialDatePickerField}
              validate={validateRequired}
              label='End Date'
              type='text'
              isFloatingLabel={false}
              min={startDate && moment(startDate).format(DATE_FORMAT)}
              className='bid-pricing-dialog__date-input'
            />
          </div>
        </div>
        <Field<MaterialInputProps>
          name='description'
          component={MaterialInputField}
          label='Description'
          multiLine={true}
          maxLength={128}
          displayCharactersLeft={true}
        />
      </KreoFormDialog>
    );
  }

  @autobind
  private onDialogClose(): void {
    this.props.reset();
  }
}

const mapStateToProps = (state: State): StateProps => {
  let initValues: FormData;
  const bidPricingData = state.projects.bidPricing;
  const currentProject = state.projects.currentProject;
  const currentProjectStatus = currentProject ? currentProject.status : null;
  const isEdit = currentProjectStatus === ProjectStatus.OnBidPricing;
  if (isEdit) {
    const start = bidPricingData.startDate;
    const end = bidPricingData.endDate;
    initValues = { ...bidPricingData, startDate: start, endDate: end };
  } else {
    initValues = {
      startDate: new Date(),
    };
  }
  return {
    show: BidPricingDialogName in state.dialog,
    initValues,
    isEdit,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    showCancelDialog: () => {
      dispatch(KreoDialogActions.openDialog(deactivateBidPricingDialogName));
    },
    onFinish: () => {
      dispatch(BidPricingActions.stopBidPricing());
      dispatch(KreoDialogActions.closeDialog(BidPricingDialogName));
    },
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BidPricingDialogComponent);

export default reduxForm({
  form: ADD_BID_PRICING,
})(connected);
