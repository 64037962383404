import { RequestStatus } from 'common/enums/request-status';
import { BidPricingReduxState } from '../../interfaces/bid-pricing/bid-pricing-redux-state';

export const intialState: BidPricingReduxState = {
  packages: [],
  userPackages: [],
  package: null,
  statuses: {
    packages: RequestStatus.Loaded,
    userPackages: RequestStatus.Loaded,
  },
};
