import { ModalWrapper } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { PaymentMoreDetails } from '../subscription-details-new-user/payment-details-block/payment-more-details';

export const PAYMENTS_DETAILS_DIALOG = 'PAYMENTS_DETAILS_DIALOG';

interface DispatchProps {
  closeDialog: () => void;
}

interface Props extends DispatchProps { }


const PaymentDetailsDialogComponent: React.FC<Props> = ({ closeDialog }: Props) => {
  return (
    <DialogWrapper name={PAYMENTS_DETAILS_DIALOG}>
      <ModalWrapper onExit={closeDialog}>
        <PaymentMoreDetails />
      </ModalWrapper>
    </DialogWrapper>
  );
};


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(PAYMENTS_DETAILS_DIALOG)),
  };
}

const connector = connect(null, mapDispatchToProps);
export const PaymentDetailsDialog = connector(PaymentDetailsDialogComponent);
