import { Icons, Text, TinyText } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


const CONSEQUENCES_LIST = [
  {
    title: 'Access to Kreo Software',
    description: `Your access to our tools and features will be immediately halted.`,
  },
  {
    title: 'File Storage',
    description: `Your files and projects will be removed from our system 45 days after subscription cancellation.
      Please save any important data in advance.`,
  },
  {
    title: 'AI Learnings',
    description: `Your contributions to the training of our AI and its knowledge gained from your projects will be lost,
      which may decrease the efficiency of future projects.`,
  },
  {
    title: 'Individual Settings',
    description: `Your settings, including templates, preferences, and configurations, will be lost.`,
  },
  {
    title: 'Personalized AI Dataset',
    description: `You will lose access to your dataset,
      which Kreo Software's AI created based on your projects and preferences.`,
  },
  {
    title: 'Pricing Policy',
    description: `It may be difficult to return to your current subscription terms
      in the future due to changes in prices and conditions.`,
  },
];

export const ConsequencesBodyComponent: React.FC = () => {
  return (
    <Styled.Container>
      {CONSEQUENCES_LIST.map((consequence => {
        return (
          <Styled.Wrapper key={consequence.description}>
            <Styled.Icon>
              <Icons.Close2d />
            </Styled.Icon>
            <Styled.Text>
              <Text fontSize={14}>
                {consequence.title}
              </Text>
              <TinyText color={'gray'}>
                {consequence.description}
              </TinyText>
            </Styled.Text>
          </Styled.Wrapper>
        );
      }))}
    </Styled.Container>
  );
};

export const ConsequencesBody = React.memo(ConsequencesBodyComponent);
