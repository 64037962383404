import { useMemo } from 'react';
import { CompanySubscriptionModel } from '../../../../account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../account/selectors';

export const useEditSubscriptionText = (
  subscription: CompanySubscriptionModel,
  isArhived: boolean,
  isPlanChanged: boolean,
): [string, string] => {
  return useMemo(() => {
    const isPausedSubscription = AccountSelectors.isPausedSubscription(subscription);

    if (!isPlanChanged && isArhived && !isPausedSubscription) {
      return ['Choose another plan', 'Your previous plan is no longer available'];
    }

    const submitButtonText = isPausedSubscription
      ? 'Resume'
      : 'Renew';
    const headerText = isPausedSubscription
      ? 'Your subscription was paused'
      : 'Your subscription was cancelled';
    return [submitButtonText, headerText];
  }, [subscription, isArhived, isPlanChanged]);
};
