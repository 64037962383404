import { MenuItemStyled, Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const AISuggestMenuItem = styled.div`
  ${MenuItemStyled.Item} {
    svg { fill: ${Constants.Colors.GENERAL_COLORS.green}; }
    :hover {
      svg { fill: ${Constants.Colors.GENERAL_COLORS.green}; }
    }
  }
`;

export const Styled = {
  AISuggestMenuItem,
};
