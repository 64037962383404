const prefix = '@ce-measurements';

const EXPAND_ITEM_BY_ID = `${prefix}/EXPAND_ITEM_BY_ID`;
const SHOW_EXTRACTORS_BY_ID = `${prefix}/SHOW_EXTRACTORS_BY_ID`;
const SELECT_NODE = `${prefix}/SELECT_NODE`;
const START_SEARCH = `${prefix}/START_SEARCH`;
const SHOW_HIDE_TREE = `${prefix}/SHOW_HIDE_TREE`;
const ON_CHANGE_INPUT_VALUE = `${prefix}/ON_CHANGE_INPUT_VALUE`;
const ON_CHANGE_GENERAL_INPUT_VALUE = `${prefix}/ON_CHANGE_GENERAL_INPUT_VALUE`;
const TOGGLE_ITEM_SELECT_STATUS = `${prefix}/TOGGLE_ITEM_SELECT_STATUS`;
const TOGGLE_GENERAL_SELECT_STATUS = `${prefix}/TOGGLE_GENERAL_SELECT_STATUS`;
const DISABLED_ITEM_SELECT_STATUS = `${prefix}/DISABLED_ITEM_SELECT_STATUS`;
const SELECT_NODE_BY_ENGINE_ID = `${prefix}/SELECT_NODE_BY_ENGINE_ID`;
const LOAD_DATA_REQUEST = `${prefix}/LOAD_DATA_REQUEST`;
const SELECT_ELEMENT_FROM_ENGINE = `${prefix}/SELECT_ELEMENT_FROM_ENGINE`;
const LOAD_DATA_SUCCESSED = `${prefix}/LOAD_DATA_SUCCESSED`;
const DROP_STATE = `${prefix}/DROP_STATE`;
const GET_STATISTIC_REQUEST = `${prefix}/GET_STATISTIC_REQUEST`;
const GET_STATISTIC_SUCCESS = `${prefix}/GET_STATISTIC_SUCCESS`;
const DROP_STATISTIC = `${prefix}/DROP_STATISTIC`;
const ON_BLUR_EDIT = `${prefix}/ON_BLUR_EDIT`;
const COMMIT_CHANGES_SUCCESS = `${prefix}/COMMIT_CHANGES_SUCCESS`;
const CANCEL_EDIT = `${prefix}/CANCEL_EDIT`;
const CANCEL_EDIT_SUCCESS = `${prefix}/CANCEL_EDIT_SUCCESS`;
const TOGGLE_LEVELS_SHOW_STATUS = `${prefix}/TOGGLE_LEVELS_SHOW_STATUS`;


const APPROVE = `${prefix}/APPROVE`;
const DISAPPROVE =`${prefix}/DISAPPROVE`;


export const CEMeasurementsActionTypes = {
  EXPAND_ITEM_BY_ID,
  SHOW_EXTRACTORS_BY_ID,
  SELECT_NODE,
  START_SEARCH,
  SHOW_HIDE_TREE,
  ON_CHANGE_INPUT_VALUE,
  ON_CHANGE_GENERAL_INPUT_VALUE,
  TOGGLE_ITEM_SELECT_STATUS,
  TOGGLE_GENERAL_SELECT_STATUS,
  DISABLED_ITEM_SELECT_STATUS,
  SELECT_NODE_BY_ENGINE_ID,
  LOAD_DATA_REQUEST,
  SELECT_ELEMENT_FROM_ENGINE,
  LOAD_DATA_SUCCESSED,
  DROP_STATE,
  GET_STATISTIC_REQUEST,
  GET_STATISTIC_SUCCESS,
  DROP_STATISTIC,
  ON_BLUR_EDIT,
  COMMIT_CHANGES_SUCCESS,
  CANCEL_EDIT,
  CANCEL_EDIT_SUCCESS,
  TOGGLE_LEVELS_SHOW_STATUS,

  APPROVE,
  DISAPPROVE,
};
