import { useCallback } from 'react';

import { PiaBreakdownNode } from '../interfaces';
import { UpdateFunction } from './use-options-state';

const removeCode = (root: PiaBreakdownNode[]): void => {
  for (const node of root) {
    node.code = undefined;
    removeCode(node.children);
  }
};

export const useRemoveOverideCallback = (updateOptions: UpdateFunction): () => void => {
  return useCallback(() => {
    updateOptions((ops) => {
      removeCode(ops);
    });
  }, [updateOptions]);
};
