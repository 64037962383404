/**
 * @LimitationConnectionType enum on backend
 */
export enum LimitationType {
  StartStart = 'StartStart',
  StartFinish = 'StartFinish',
  FinishStart = 'FinishStart',
  FinishFinish = 'FinishFinish',
}

export enum Direction {
  Forward = 'Forward',
  Back = 'Back',
}

export enum GraphItemDisplayType {
  Normal = 'normal',
  Active = 'active',
  Highlighted = 'highlighted',
  Previous = 'previous',
  Next = 'next',
}
