import { StrokeParams } from 'common/components/drawings/interfaces/stroke-params';
import { Line } from './line';

export class SegmentUtilLineEntity extends Line {
  protected override applyStrokeStyles(params: StrokeParams, zoom: number): void {
    this._line.strokeWidth = this.getStrokeWidth(params, zoom);
  }

  protected getStrokeWidth(_p: StrokeParams, zoom: number): number {
    return 2 / zoom;
  }
}
