import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { DrawingsLayoutApi } from 'common/components/drawings';
import { ExcelFormulaHelper, ExcelTableCelCoordinates, UpdateCellData } from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { ReferenceHelper } from 'common/components/excel-table/utils';
import { FormulaToolbarApi } from 'common/components/formula-toolbar';
import { QUOTE_EXCEPTION_DIALOG } from 'common/components/quote-exception-dialog';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { TwoDActions } from '../../actions/creators';
import { FormulaBarPayload, SheetUpdateCells } from '../../actions/payloads';
import { FocusedCell, ReportPage, TableSettings, TwoDSheetApi } from '../../interfaces';
import { TwoDRegex } from '../../units/2d-regex';
import { SheetDataStore } from '../../units/cell-data-controllers/report-cell-data-store';
import { TwoDReportFormulaBar } from '../2d-report-formula-bar';
import { AutoMoveToCellButton } from '../auto-move-to-cell-button';
import { isValidFormulaQuote } from '../constants';

import { Styled } from './styled';


interface StateToProps {
  focusedCell: FocusedCell;
  editFullCellId: string;
  selectedSheetId: string;
}

interface DispatchToProps {
  openDialog: (name: string, data?: any) => void;
  setEditCellId: (cellId: string) => void;
  setFormulaBar: (formulaBar: FormulaBarPayload) => void;
}

interface OwnProps {
  isReportDataLoaded: boolean;
  drawingsLayoutApi: DrawingsLayoutApi;
  reportPages: ReportPage[];
  isEditAnotherSheet: () => boolean;
  getSelectedGridRef(): TwoDSheetApi;
  referenceReader: ReferenceHelper;
  saveFormulaBarApi: (api: FormulaToolbarApi) => void;
  updateRowData: (rowId: string, columnId: string, value: string, config?: TableSettings) => void;
  selectSheetData: (id: string) => SheetDataStore;
  onChangeTableWithUndoRedo(id: string): void;
  getValueToUpdateFromFormulaBar(value: string, sheetId: string): string | number;
  updateCellsWithUndoRedo(payload: Array<SheetUpdateCells<UpdateCellData[]>>, skipRefresh?: boolean): void;
}

type Props = OwnProps & DispatchToProps & StateToProps & AbilityAwareProps;

class TwoDReportToolBarComponent extends React.PureComponent<Props> {
  private formulaBarApi: FormulaToolbarApi = null;

  public render(): JSX.Element {
    const {
      focusedCell,
      ability,
      drawingsLayoutApi,
      getSelectedGridRef,
      referenceReader,
      openDialog,
    } = this.props;
    const cellCoordinates = focusedCell.cell ? focusedCell.cell.cellId : '';

    const canEditReport = ability.can(Operation.Update, Subject.Takeoff2DReport);
    const canViewReport = ability.can(Operation.Read, Subject.Takeoff2DReport);

    return (
      <Styled.Container>
        <ExcelTableCelCoordinates cellCoordinates={cellCoordinates} />
        <TwoDReportFormulaBar
          drawingsLayoutApi={drawingsLayoutApi}
          updateData={this.onFormulaBarUpdateData}
          onEscapeDown={this.onFormulaBarEscapeDown}
          getSelectedGridRef={getSelectedGridRef}
          referenceReader={referenceReader}
          saveApi={this.saveFormulaApi}
          openDialog={openDialog}
          canEditReport={canEditReport}
          canViewReport={canViewReport}
          getSheetSize={this.getSheetSize}
        />
        <AutoMoveToCellButton />
    </Styled.Container>
    );
  }

  @autobind
  private saveFormulaApi(api: FormulaToolbarApi): void {
    this.formulaBarApi = api;
    this.props.saveFormulaBarApi(api);
  }

  @autobind
  private onFormulaBarUpdateData(formulaValue: string, continueEditing?: boolean): void {
    const formatValue = ExcelFormulaHelper.isFormula(formulaValue)
      ? ExcelFormulaHelper.replaceSheetNameToSheetId(
        ExcelFormulaHelper.autoCloseParentheses(formulaValue),
        this.props.reportPages)
      : formulaValue;
    if (!isValidFormulaQuote(formatValue)) {
      this.props.openDialog(QUOTE_EXCEPTION_DIALOG, () => this.formulaBarApi.setFocus());
      return;
    }
    if (this.props.isEditAnotherSheet()) {
      this.updateAnotherSheet(formatValue);
    } else {
      const { rowId, columnId } = this.props.focusedCell.cell;
      this.props.updateRowData(rowId, columnId, formatValue);
      if (!continueEditing) {
        const gridRef = this.props.getSelectedGridRef();
        gridRef.api.clearRangeSelection();
        gridRef.api.setFocusedCell(
          Number(rowId),
          columnId,
        );
      }
    }
  }

  @autobind
  private onFormulaBarEscapeDown(): void {
    const { focusedCell, editFullCellId, selectedSheetId, setEditCellId, selectSheetData, setFormulaBar } = this.props;
    const focusCell = focusedCell.cell;
    const { sheetId, rowId, columnId } = this.props.isEditAnotherSheet()
      ? TwoDRegex.fullCellId.exec(editFullCellId).groups
      : { sheetId: selectedSheetId, rowId: focusCell.rowId, columnId: focusCell.columnId };

    if (this.props.isEditAnotherSheet()) {
      this.props.onChangeTableWithUndoRedo(sheetId);
      setEditCellId('');
    }

    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const prevData = selectSheetData(sheetId).rows[rowIndex][columnId];

    const value = prevData === undefined
      ? ''
      : prevData.toString();
    setFormulaBar({ value });
  }

  private updateAnotherSheet(value: string): void {
    const {
      editFullCellId,
      selectSheetData,
      setEditCellId,
      getValueToUpdateFromFormulaBar,
      onChangeTableWithUndoRedo,
      updateCellsWithUndoRedo,
    } = this.props;
    const { sheetId, rowId, columnId } = TwoDRegex.fullCellId.exec(editFullCellId).groups;
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const prevValue = selectSheetData(sheetId).rows[rowIndex][columnId];
    const newValue = getValueToUpdateFromFormulaBar(value, sheetId);
    const form = [{
      columnId,
      rowId,
      value: newValue,
      prevValue,
    }];

    const payload = { sheetId, form };

    updateCellsWithUndoRedo([payload], true);
    onChangeTableWithUndoRedo(sheetId);
    setEditCellId('');
  }

  @autobind
  private getSheetSize(sheetId: string): { rowsLength: number, columnsLength: number } {
    const sheetData = this.props.selectSheetData(sheetId);
    if (!sheetData) {
      return { rowsLength: 0, columnsLength: 0 };
    }
    return { rowsLength: sheetData.rows.length - 1, columnsLength: sheetData.columns.length - 1 };
  }
}

const mapStateToProps = (state: State): StateToProps => {
  return {
    focusedCell: state.twoD.focusedCell,
    editFullCellId: state.twoD.editFullCellId,
    selectedSheetId: state.twoD.selectedSheetId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchToProps => {
  return {
    openDialog: (name, data) => dispatch(KreoDialogActions.openDialog(name, data)),
    setEditCellId: (cellId) => dispatch(TwoDActions.setEditFullCellId(cellId)),
    setFormulaBar: (formulaBar) => dispatch(TwoDActions.setFormulaBar(formulaBar)),
  };
};

export const TwoDReportToolBar = connect(mapStateToProps, mapDispatchToProps)(
  withAbilityContext(TwoDReportToolBarComponent),
);
