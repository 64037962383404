import styled from 'styled-components';
import { SpinnerStyled } from 'common/components/spinner';

const Container = styled.div`
  ${SpinnerStyled.SpinnerContainer} {
    background-color: ${props => props.theme.color.background};
  }
`;

export const Styled = {
  Container,
};
