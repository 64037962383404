import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { databaseActivityListingSagas } from './database-activity-listing-sagas';
import { databaseActivitySagas } from './database-activity-sagas';
import { databaseActivityVariantSagas } from './database-activity-variant-sagas';
import { commonSagas } from './database-common-sagas';
import { databaseResourceListingSagas } from './database-resource-listing-sagas';
import { databaseResourceSagas } from './database-resource-sagas';
import { databaseResourceVariantSagas } from './database-resource-variant-sagas';
import { databaseSagas } from './database-sagas';

export function* commonDatabaseSagas(): SagaIterator {
  yield all ([
    fork(commonSagas),
    fork(databaseSagas),
    fork(databaseActivityListingSagas),
    fork(databaseActivitySagas),
    fork(databaseActivityVariantSagas),
    fork(databaseResourceListingSagas),
    fork(databaseResourceSagas),
    fork(databaseResourceVariantSagas),
  ]);
}

