import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-pair-tile.scss';

import { ElementTooltip, TooltipPosition } from 'common/components/element-tooltip';
import { State } from 'common/interfaces/state';
import { KreoIconCheckMark } from 'common/UIKit';
import { numberUtils } from 'common/utils/number-utils';
import { ClassificationActions } from '../../actions/creators/classification';

interface StateProps {
  systemDescription: string;
  productDescription: string;
  confidence: number;
  isSelected: boolean;
  showConfidence: boolean;
}

interface DispatchProps {
  toggelCheckStatus: () => void;
}

interface OwnProps {
  index: number;
  isDisabled?: boolean;
}

interface Props extends OwnProps, StateProps, DispatchProps {}

const ClassificationPairTileComponent: React.FC<Props> = props => {
  return (
    <div
      className={classNames('classification-pair-tile', {
        'classification-pair-tile--selected': props.isSelected,
        'classification-pair-tile--disabled': props.isDisabled,
      })}
      onClick={props.toggelCheckStatus}
      data-control-name='classification-pair-tile'
    >
      {props.showConfidence && (
        <ElementTooltip
          tooltipPosition={TooltipPosition.CenterTop}
          size='small'
          className='classification-pair-tile__confidence'
          targetElement={numberUtils.getNumeralFormatter(props.confidence).format('%0.00')}
        >
          Probability
        </ElementTooltip>
      )}
      <div className='classification-pair-tile__title' title={props.systemDescription}>
        {props.systemDescription}
      </div>
      <div title={props.productDescription} className='classification-pair-tile__title'>
        {props.productDescription}
      </div>
      <div className='classification-pair-tile__radio'>
        <KreoIconCheckMark />
      </div>
    </div>
  );
};

function mapStateToProps({ classification }: State, { index }: OwnProps): StateProps {
  const {
    systemId,
    productId,
    productDescription,
    systemDescription,
    confidence,
  } = classification.elementClassification[0][index];
  return {
    isSelected: index === classification.classificationSelectedIndex,
    showConfidence: !!confidence && Number.isInteger(productId) && Number.isInteger(systemId),
    confidence,
    productDescription,
    systemDescription,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { index }: OwnProps): DispatchProps {
  return {
    toggelCheckStatus: () => {
      dispatch(ClassificationActions.setSelectedUniclass(index));
    },
  };
}

export const ClassificationPairTile = connect(mapStateToProps, mapDispatchToProps)(
  ClassificationPairTileComponent,
);
