import autobind from 'autobind-decorator';
import React from 'react';
import { ConnectDropTarget, DropTarget, DropTargetConnector, DropTargetMonitor, DropTargetSpec } from 'react-dnd';

import './category.scss';

import { ComponentDisplayType } from 'common/enums/component-display-type';
import { DndItem } from 'common/enums/dnd-item';
import { RemoveUpdateHighlighDelay, ScrollDelay } from '../../constants';
import { ActivityGroupData } from '../../interfaces';
import { EntityCompareHelper } from '../../utils';
import { ActivityGroupItem } from './activity-group-item';
import { CategoryHeader } from './category-header';


const getDisplayType = (props: Props): string => {
  if (props.wasChanged) {
    return ComponentDisplayType.WasChanged;
  }

  if (!props.draggingActivityGroup) {
    return props.activeCategoryId === props.id
      ? ComponentDisplayType.Active
      : ComponentDisplayType.Normal;
  }

  if (props.draggingActivityGroup.categoryId === props.id) {
    return ComponentDisplayType.Disabled;
  } else if (props.isOver) {
    return ComponentDisplayType.ActiveDropTarget;
  }
};

interface DndProps {
  connectDropTarget: ConnectDropTarget;
  isOver: boolean;
}

interface PropsActions {
  setActiveActivityGroup(activityGroupId: number | null): void;
  renameActivityGroup(activityGroupId: number, name: string): void;
  renameCategory(categoryId: number, name: string): void;
  removeCategory(categoryId: number): void;
  replaceActivityGroup(activityGroupId: number, categoryId: number): void;
  replaceActivity(activityId: number, activityGroupId: number): void;
  setDraggingActivityGroup(activityGroupId: number): void;
  createActivityGroup(categoryId: number): void;
  removeActivityGroup(activityGroupId: number): void;
  replaceSelectedActivities(destinationActivityGroupId: number): void;
  mergeActivityGroups(destinationId: number, mergedId: number): void;
  onActivityGroupHover(groupId: number): void;
}

interface OwnProps extends PropsActions {
  id: number;
  name: string;
  wasChanged: boolean;
  activityGroups: ActivityGroupData.ActivityGroup[];
  draggingActivity: ActivityGroupData.Activity | null;
  draggingActivityGroup: ActivityGroupData.ActivityGroup | null;
  activeActivityGroupId: number;
  activeCategoryId: number;
  newActivityGroupId: number;
  selectedActivityGroupIds: Record<number, boolean>;
}

interface Props extends OwnProps, DndProps { }

interface State {
  isOpen: boolean;
  changedActivityGroupId: number | null;
}

class ActivityGroupCategoryComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: true,
      changedActivityGroupId: null,
    };
  }

  public render(): JSX.Element {
    const props = this.props;
    return (
      <div className={`activity-group-category ${getDisplayType(props)}`}>
        {
          this.props.connectDropTarget(
            <div className='activity-group-category__header'>
              <CategoryHeader
                id={props.id}
                name={props.name}
                renameCategory={props.renameCategory}
                replaceActivityGroup={this.replaceActivityGroup}
                removeCategory={props.removeCategory}
                draggingActivityGroup={props.draggingActivityGroup}
                activityGroupsCount={props.activityGroups.length}
                createActivityGroup={this.createActivityGroup}
                onExpandCollapse={this.onExpandCollapse}
                expand={this.state.isOpen}
              />
            </div>)
        }
        <div className='activity-group-category__content' onMouseLeave={this.onMouseLeave}>
          {
            this.state.isOpen
              ? this.getActivityGroupItemComponents(this.props.activityGroups)
              : null
          }
        </div>
      </div>
    );
  }

  public componentDidMount(): void {
    ActivityGroupCategoryComponent.scrollToActiveActivityGroup();
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (
      this.props.activeCategoryId === this.props.id &&
      !this.state.isOpen &&
      this.props.activeActivityGroupId !== prevProps.activeActivityGroupId
    ) {
      this.setState({ isOpen: true });
    }
    if (this.props.activeActivityGroupId !== prevProps.activeActivityGroupId ||
      prevState.changedActivityGroupId !== this.state.changedActivityGroupId
    ) {
      ActivityGroupCategoryComponent.scrollToActiveActivityGroup();
    }
  }

  private static scrollToActiveActivityGroup(): void {
    setTimeout(
      () => {
        const activeActivityGroups = document.getElementsByClassName('activity-grouping-item active');
        const changedActivityGroup = document.getElementsByClassName('activity-grouping-item was-changed');
        const activityGroup = activeActivityGroups && activeActivityGroups.length > 0
          ? activeActivityGroups[0] as HTMLElement
          : changedActivityGroup && changedActivityGroup.length
            ? changedActivityGroup[0] as HTMLElement
            : null;

        if (activityGroup) {
          const container = activityGroup.closest('.scroll-box__content');
          const activityGroupPosition = activityGroup.parentElement.offsetTop + activityGroup.offsetTop;

          const isVisible = container.scrollTop < activityGroupPosition &&
            activityGroupPosition < container.scrollTop + container.clientHeight;
          if (!isVisible) {
            activityGroup.scrollIntoView();
          }
        }
      },
      ScrollDelay,
    );
  }

  @autobind
  private onMouseLeave(): void {
    if (this.props.onActivityGroupHover) {
      this.props.onActivityGroupHover(null);
    }
  }

  @autobind
  private setChangedActivityGroup(id: number): void {
    this.setState({ changedActivityGroupId: id });
    const that = this;
    setTimeout(
      () => {
        if (that.state.changedActivityGroupId === id) {
          that.setState({ changedActivityGroupId: null });
        }
      },
      RemoveUpdateHighlighDelay,
    );
  }

  @autobind
  private onExpandCollapse(): void {
    this.setState({ isOpen: !this.state.isOpen });
  }

  @autobind
  private createActivityGroup(categoryId: number): void {
    this.setChangedActivityGroup(this.props.newActivityGroupId);
    this.props.createActivityGroup(categoryId);
  }

  @autobind
  private replaceActivityGroup(categoryId: number, groupId: number): void {
    this.setChangedActivityGroup(groupId);
    this.props.replaceActivityGroup(categoryId, groupId);
  }

  @autobind
  private renameActivityGroup(id: number, name: string): void {
    this.setChangedActivityGroup(id);
    this.props.renameActivityGroup(id, name);
  }

  @autobind
  private replaceActivity(activityId: number, groupId: number): void {
    this.setChangedActivityGroup(groupId);
    this.props.replaceActivity(activityId, groupId);
  }

  @autobind
  private replaceSelectedActivities(groupId: number): void {
    this.setChangedActivityGroup(groupId);
    this.props.replaceSelectedActivities(groupId);
  }

  @autobind
  private mergeActivityGroups(destinationId: number, mergedId: number): void {
    this.setChangedActivityGroup(destinationId);
    this.props.setActiveActivityGroup(destinationId);
    this.props.mergeActivityGroups(destinationId, mergedId);
  }

  @autobind
  private setActiveActivityGroup(activityGroupId: number | null): void {
    this.props.setActiveActivityGroup(activityGroupId);
  }

  private getActivityGroupItemComponents(activityGroups: ActivityGroupData.ActivityGroup[]): React.ReactNodeArray {
    const props = this.props;
    return activityGroups
      .sort(EntityCompareHelper.compareNamedEntity)
      .map((activityGroup) => (
        <ActivityGroupItem
          key={activityGroup.id}
          wasChanged={this.state.changedActivityGroupId === activityGroup.id}
          id={activityGroup.id}
          name={activityGroup.name}
          activitiesCount={activityGroup.activityIds.length}
          isActive={props.activeActivityGroupId === activityGroup.id}
          isContainSelectedActivities={props.selectedActivityGroupIds[activityGroup.id]}
          mergeActivityGroups={this.mergeActivityGroups}
          renameActionGroup={this.renameActivityGroup}
          onClick={this.setActiveActivityGroup}
          replaceActivity={this.replaceActivity}
          replaceSelectedActivities={this.replaceSelectedActivities}
          setDraggingActivityGroup={props.setDraggingActivityGroup}
          draggingActivity={props.draggingActivity}
          draggingActivityGroup={props.draggingActivityGroup}
          removeActivityGroup={props.removeActivityGroup}
          onHover={props.onActivityGroupHover}
        />));
  }
}

const collector = (connector: DropTargetConnector, monitor: DropTargetMonitor): DndProps => {
  return {
    connectDropTarget: connector.dropTarget(),
    isOver: monitor.isOver(),
  };
};

const targetSpec: DropTargetSpec<OwnProps> = {
  drop: (props: OwnProps) => {
    props.replaceActivityGroup(props.draggingActivityGroup.id, props.id);
  },
  canDrop: (props: OwnProps) => {
    return props.draggingActivityGroup && props.draggingActivityGroup.categoryId !== props.id;
  },
};

const dndContext = DropTarget(DndItem.ActivityGroup, targetSpec, collector);

export const ActivityGroupCategory = dndContext(ActivityGroupCategoryComponent);
