import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const CONTAINER_WIDTH = 420;

const Container = styled.div`
  background: ${p => p.theme.color.backgroundRush};
  width: ${CONTAINER_WIDTH}px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  animation: ${Constants.Animations.modalAppear}  ${p => p.theme.duration.m} ease-in-out;
  button {
    margin: 0 40px;
  }
  .file-upload-wrap {
    display: flex;
    justify-content: center;
  }

  .custom-material-input__wrap {
    display: none;
  }

  .line-file {
    width: ${CONTAINER_WIDTH - 80}px;
    margin: 0;
  }

  .line-file__dropzone {
    border-radius: 15px;
    color: ${p => p.theme.color.mainFont};
    background-color: ${p => p.theme.color.turquoise}0d;
    border: 1px dashed ${p => p.theme.color.turquoise};
    max-height: 200px;
    overflow-y: scroll;
    align-items: flex-start;
  }
`;

const Separator = styled.div`
  background: ${p => p.theme.color.pale};
  width: 100%;
  height: 10px;
  margin: 20px 0;
`;

const InputWrapper = styled.div`
  margin: 0 40px;
  button {
    margin: 0;
  }
`;


export const Styled = {
  Container,
  Separator,
  InputWrapper,
};
