import { ElementTooltip, Icons, IconButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

import {  useUndoRedoFullApi } from 'common/undo-redo';
import { Styled } from './styled';

const DrawingsUndoRedoButtonsComponent: React.FC = () => {
  const { canRedo, canUndo, undo, redo } = useUndoRedoFullApi();
  return (
    <Styled.Container>
      <ElementTooltip
        text='Undo'
        position='top'
      >
        <IconButton
          Icon={Icons.UndoClassic}
          onClick={undo}
          height={40}
          width={45}
          defaultColor={canUndo ? 'turquoise' : 'gray'}
          mood={canUndo ? 'default' : 'disabled'}
        />
      </ElementTooltip>
      <ElementTooltip
        text='Redo'
        position='top'
      >
        <IconButton
          Icon={Icons.RedoClassic}
          onClick={redo}
          height={40}
          width={45}
          defaultColor={canRedo ? 'turquoise' : 'gray'}
          mood={canRedo ? 'default' : 'disabled'}
        />
      </ElementTooltip>
    </Styled.Container>
  );
};

export const DrawingsUndoRedoButtons = React.memo(DrawingsUndoRedoButtonsComponent);
