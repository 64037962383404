import autobind from 'autobind-decorator';
import * as React from 'react';

import './custom-element-filter-builder-tree-node.scss';

import { CustomElementFilterCollectionOperation } from 'common/enums/custom-element-filter-collection-operation';
import { CustomElementFilterBuilderMapper } from 'common/utils/custom-element-filter-builder';
import {
  CustomElementFilterConditionVlidatationResult,
} from '../../../units/projects/utils/quantity-take-off-tree-table/custom-element-filter-condition-validator';
import { CustomElementFilterConditionSelector } from './custom-element-filter-builder-condition-selector';
import { CustomElementFilterBuilderItemWrap } from './custom-element-filter-builder-item-wrap';
import { CustomElementFilterItemType, CustomFilterBuilderAutocompleteOption, FilterNode } from './inner-interfaces';


interface Props {
  isOnlyModelValues: boolean;
  node: FilterNode;
  filterKeyOptions: CustomFilterBuilderAutocompleteOption[];
  filterKeyToPredefineValues: Record<string, CustomFilterBuilderAutocompleteOption[]>;
  onChange: (item: CustomElementFilterItemType) => void;
  getCustomKeyFromName: (name: string) => string;
  getCustomNameFromKey: (key: string) => string;
  conditionsValidationResult: CustomElementFilterConditionVlidatationResult;
}


export class CustomElementFilterBuilderTreeNode extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      node,
      filterKeyOptions,
      getCustomKeyFromName,
      getCustomNameFromKey,
      filterKeyToPredefineValues,
    } = this.props;

    return (
      <div className='custom-element-filter-builder-tree-node'>
        <CustomElementFilterConditionSelector
          currentValue={node.operation}
          addNewCondition={this.onAddNewCondition}
          addNewGroup={this.addNewGroup}
          onConditionChange={this.onConditionChange}
        />
        {
          node.innerNodes.length
            ? node.innerNodes.map((item, index) => (
              <CustomElementFilterBuilderItemWrap
                key={item.uniqueKey}
                filterItem={item}
                index={index}
                onChange={this.onItemChange}
                onRemove={this.onItemRemove}
                getCustomKeyFromName={getCustomKeyFromName}
                getCustomNameFromKey={getCustomNameFromKey}
                filterKeyToPredefineValues={filterKeyToPredefineValues}
                filterKeyOptions={filterKeyOptions}
                isOnlyModelValues={this.props.isOnlyModelValues}
                conditionsValidationResult={this.props.conditionsValidationResult}
              />
            ))
            : null
        }
      </div>
    );
  }

  @autobind
  private onItemChange(index: number, item: CustomElementFilterItemType): void {
    const { node, onChange } = this.props;
    const updatedInnerNodes = node.innerNodes.slice();
    updatedInnerNodes[index] = item;

    onChange({
      ...node,
      innerNodes: updatedInnerNodes,
    });
  }

  @autobind
  private onItemRemove(index: number): void {
    const { node, onChange } = this.props;
    const updatedInnerNodes = node.innerNodes.slice();
    updatedInnerNodes.splice(index, 1);

    onChange({
      ...node,
      innerNodes: updatedInnerNodes,
    });
  }

  @autobind
  private onAddNewCondition(): void {
    const { node, onChange } = this.props;
    const updatedInnerNodes = node.innerNodes.slice();
    const newItem = CustomElementFilterBuilderMapper.getEmptyFilter();
    updatedInnerNodes.push(newItem);

    onChange({
      ...node,
      innerNodes: updatedInnerNodes,
    });
  }

  @autobind
  private addNewGroup(): void {
    const { node, onChange } = this.props;
    const updatedInnerNodes = node.innerNodes.slice();
    const newItem = CustomElementFilterBuilderMapper.getEmptyFilter();
    const newGroup = CustomElementFilterBuilderMapper.getFilterNode([newItem]);
    updatedInnerNodes.push(newGroup);

    onChange({
      ...node,
      innerNodes: updatedInnerNodes,
    });
  }

  @autobind
  private onConditionChange(operation: CustomElementFilterCollectionOperation): void {
    const { node } = this.props;
    this.props.onChange({
      ...node,
      operation,
    });
  }

}
