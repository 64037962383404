import { ElementTooltip, Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { ConstantFunctions } from 'common/constants/functions';

import { Styled } from './styled';

interface Props {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export class TwoDHeaderPageTabHoverClose extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { onClick } = this.props;
    return (
      <ElementTooltip
        position={'right'}
        text={'Close'}
      >
        <Styled.Container
          onMouseDown={ConstantFunctions.stopEvent}
          onClick={onClick}
        >
          <Icons.CloseSmall />
        </Styled.Container>
      </ElementTooltip>
    );
  }
}
