import * as t from 'io-ts';

import { NrmPairInfoC } from '../nrm-pair-info';
import { UniPairInfoC } from '../uni-pair-info';
import { ActivityAssignmentVariantDataC } from './activity-assignmnet-variant-data';

export const ActivityAssignmentActivityDataC = t.strict(
  {
    activityIds: t.array(t.number),
    engineIds: t.array(t.number),
    geometryLessIds: t.array(t.number),
    variants: t.array(ActivityAssignmentVariantDataC),
    uniPair: UniPairInfoC,
    nrmPair: NrmPairInfoC,
  },
  'ActivityAssignmentActivityData');

export type ActivityAssignmentActivityData = t.TypeOf<typeof ActivityAssignmentActivityDataC>;
