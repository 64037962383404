import { Input, ModalWrapper, RectangleButton } from '@kreo/kreo-ui-components';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  change,
  formValueSelector,
  isValid,
  reduxForm,
} from 'redux-form';
import { FileUploadWrap } from 'common/components/file-upload-wrap';
import { ControlNames } from 'common/constants/control-names';
import { UploadingFile } from 'common/interfaces/common-state';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import Form from 'components/form';
import {
  projectValidateRequired,
} from 'unit-projects/components/model-management-files-browser/model-management-load-file-dialog';
import { CREATE_REPORT_TEMPLATE } from '../../../../constants/forms';
import { Styled } from './styled';

export const CREATE_2D_REPORT_TEMPLATE_DIAGLOG = 'CREATE_2D_REPORT_TEMPLATE_DIAGLOG';
export const CLOSE_CREATE_2D_REPORT_DIALOG_DIALOG_NAME = 'CLOSE_CREATE_2D_REPORT_DIALOG_DIALOG_NAME';

const extensions = ['xls', 'xlsx'];

const selector = formValueSelector(CREATE_REPORT_TEMPLATE);
const isValidFormSelector = isValid(CREATE_REPORT_TEMPLATE);

interface StateProps {
  files: UploadingFile[];
  reportTemplateName: string;
  disableSubmit: boolean;
}

interface DispatchProps {
  openDialog: (name: string) => void;
  closeDialog: () => void;
  setReportTemplateName: (name: string) => void;
}

interface Props extends StateProps, DispatchProps, InjectedFormProps<{}, {}> {

}

const getExtensionsRegExp = (e: string[]): RegExp => {
  const regexExtensions = e
    .map(x => `\\.${x}`)
    .join('|');

  return new RegExp(`(${regexExtensions})$`, 'i');
};

const CreateReportTemplateDialogComponent: React.FC<Props> = ({
  openDialog,
  closeDialog,
  reportTemplateName,
  setReportTemplateName,
  files,
  disableSubmit,
  handleSubmit,
}) => {
  const handleClose = React.useCallback(() => {
    setReportTemplateName('');
    openDialog(CLOSE_CREATE_2D_REPORT_DIALOG_DIALOG_NAME);
  }, []);

  const handleUploadStarterd = React.useCallback((f: File[]) => {
    if (!reportTemplateName && f && f.length) {
      const fileExtensionRegExp = getExtensionsRegExp(extensions);
      setReportTemplateName(f[0].name.replace(fileExtensionRegExp, ''));
    }
  }, [reportTemplateName, setReportTemplateName]);

  const handleDeleteFile = React.useCallback((file: UploadingFile) => {
    const fileExtensionRegExp = getExtensionsRegExp(extensions);
    if (file && file.file.name.replace(fileExtensionRegExp, '') === reportTemplateName) {
      if (files.length > 1) {
        setReportTemplateName(files[1].file.name.replace(fileExtensionRegExp, ''));
      } else {
        setReportTemplateName('');
      }
    }
  }, [files, setReportTemplateName, reportTemplateName]);

  const handleSubmitForm = React.useCallback((handler: FormSubmitHandler<{}, {}>) => {
    if (!disableSubmit) {
      handleSubmit(handler);
      closeDialog();
    }
  }, [disableSubmit]);

  return (
    <DialogWrapper name={CREATE_2D_REPORT_TEMPLATE_DIAGLOG}>
      <ModalWrapper onExit={handleClose}>
        <Styled.Container>
          <Form
            handleSubmit={handleSubmitForm}
            className='create-project-dialog'
            controlName={ControlNames.createReportTemplate}
          >
            <Field
              width={'340px'}
              name='name'
              component={Input}
              type='text'
              text={'Report template name'}
              validate={projectValidateRequired}
            />
            <FileUploadWrap
              onUploadStarted={handleUploadStarterd}
              isDownloadFileInProgress={false}
              onDeleteFile={handleDeleteFile}
              formName={CREATE_REPORT_TEMPLATE}
              extensions={extensions}
              extensionsToShow={extensions}
            />
            <Styled.ButtonContainer>
              <RectangleButton
                width={340}
                height={60}
                mood={disableSubmit ? 'disabled' : 'positive'}
                text={'Create report template'}
              />
            </Styled.ButtonContainer>
          </Form>
        </Styled.Container>
        <ConfirmationDialog
          name={CLOSE_CREATE_2D_REPORT_DIALOG_DIALOG_NAME}
          title={`Are you sure you want to exit the "Create report template" Mode?`}
          text='This action cannot be undone'
          confirmButtonText='Exit'
          onConfirm={closeDialog}
          cancelButtonText='Cancel'
        />
      </ModalWrapper>
    </DialogWrapper>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    reportTemplateName: selector(state, 'name'),
    files: state.common.files,
    disableSubmit: !selector(state, 'name') || !isValidFormSelector(state)
      || state.common.files.length === 0
      || state.common.files.length > 1
      || state.common.files.some(f => f.progress < 1),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(CREATE_2D_REPORT_TEMPLATE_DIAGLOG)),
    setReportTemplateName: (value) => dispatch(change(CREATE_REPORT_TEMPLATE, 'name', value)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps)(CreateReportTemplateDialogComponent);

export const CreateReportTemplateDialog = reduxForm<{}, {}>({
  form: CREATE_REPORT_TEMPLATE,
  destroyOnUnmount: true,
  initialValues: {
    isShared: true,
  },
})(connector);
