import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import { convertPropertiesIterator } from 'unit-2d-database/components/side-panel/helpers/convert-properties-iterator';
import { Property } from 'unit-2d-database/interfaces';
import { AssemblyForm, ItemUpdater } from '../../assembly-panel/item-form-updater';
import { FormulaDialogData } from '../../formula-dialog';
import { FormulaFieldInput } from '../../item-panel/helpers';
import { mapPropertyGroupForm } from '../../item-panel/helpers/map-property-group-form';
import { Field } from '../../item-panel/interfaces';
import { getAllAddedPropertiesNames } from './get-all-added-properties-names';
import { getNotAddedPropertiesIdByNamesIterator } from './get-non-added-properties-id-by-names-iterator';

type GetChangeValueHandler = (
  form: AssemblyForm,
  updater: ItemUpdater,
  field: Field<FormulaFieldInput>,
  itemName: string,
  groupName: string,
  propertyDatabase: Property[],
  openDialog: (data: FormulaDialogData) => void,
  units: string[],
) => (value: string, propertiesToAdd: string[]) => void;

const getChangeValueHandler: GetChangeValueHandler = (
  form,
  updater,
  field,
  itemName,
  groupName,
  propertiesDatabase,
  openDialog,
  units,
) => (value, propertiesToAdd) => {
  const propertyGroup = form.itemGroupForm[itemName].itemForm.groupForm;
  const groupsForm = mapPropertyGroupForm(
    convertPropertiesIterator(
      getNotAddedPropertiesIdByNamesIterator(propertiesToAdd, propertyGroup, propertiesDatabase),
      propertiesDatabase,
    ),
    units,
    updater.getPropertyGroupUpdater(itemName),
    (id, propertyGroupName) => handleItemFormulaFieldClick(
      form,
      updater,
      openDialog,
      propertiesDatabase,
      units,
    )(itemName, id, propertyGroupName),
    ConstantFunctions.doNothing,
    property => property.name,
  );
  const newField = {
    ...field,
    input: {
      ...(field.input as FormulaFieldInput),
      value,
    },
  };
  updater.changeFormula(itemName, groupsForm, newField, groupName);
};

export function handleItemFormulaFieldClick(
  form: AssemblyForm,
  updater: ItemUpdater,
  openDialog: (data: FormulaDialogData) => void,
  propertiesDatabase: Property[],
  units: string[],
): (
  itemName: string,
  propertyId: string,
  groupName: string,
) => void {
  return (
    itemName,
    propertyId,
    groupName,
  ) => {
    const field = form.itemGroupForm[itemName].itemForm.groupForm[
      groupName
    ].fields.find(f => f.id === propertyId) as Field<FormulaFieldInput>;

    const handleValueChange = getChangeValueHandler(
      form,
      updater,
      field,
      itemName,
      groupName,
      propertiesDatabase,
      openDialog,
      units,
    );
    const itemProperties = getAllAddedPropertiesNames([form.itemGroupForm]);

    openDialog({
      canAddProperties: true,
      propertyName: field.name,
      itemProperties,
      formulaValue: field.input.value,
      onChangeValue: handleValueChange,
    });
  };

}
