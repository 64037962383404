import { useCallback, useMemo } from 'react';

import {
  HandleAddedOption,
} from '../../side-panel/components/property-panel/forms/tree-form/hooks/use-add-option-calback';
import { HandleUpdateOption } from '../../side-panel/components/property-panel/forms/tree-form/hooks/use-update-option';
import {
  DataBaseContext,
  PiaBreakdownNode,
} from '../../side-panel/components/property-panel/forms/tree-form/interfaces';
import { findOption } from '../../side-panel/components/property-panel/forms/tree-form/utils';

export const useTableContextMemo = (
  addOption: HandleAddedOption,
  updateOption: HandleUpdateOption,
  options: PiaBreakdownNode[],
): DataBaseContext => {
  const getCodeValue = useCallback((id: string) => {
    const option = findOption(options, id);
    return option.code && option.code.toString();
  }, [options]);
  return useMemo<DataBaseContext>(
    () => ({
      onAddedClick: addOption,
      updateDescription: updateOption,
      getCodeValue,
    }),
    [addOption, updateOption, getCodeValue],
  );
};
