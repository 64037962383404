import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './virtual-list.scss';

import { KreoScrollbars } from '..';
import { KreoScrollbarsApi } from '../scrollbars/kreo-scrollbars';

export interface EnhancedVirtualListProps<T> {
  objects: T[];
  itemHeight: number;
  renderedItemsCount: number;
  showShadowTop?: boolean;
  smoothScroll?: boolean;
  renderItem: (item: T, index?: number) => React.ReactNode;
  customItemHeight?: (item: T, index?: number) => number;
  transitionClassName?: string;
  totalHeight?: number;
  // to use transition, itemId must be unique
  itemIdProvider?: (item: T) => React.ReactText;
  onStateUpdate?: (state: EnhancedVirtualListState) => void;
  sendScrollBarApi?: (scrollBarApi: KreoScrollbarsApi) => void;
}

export interface EnhancedVirtualListState {
  offset: number;
  startIndex: number;
  endIndex: number;
  totalHeight: number;
}

export class EnhancedVirtualList<T> extends React.Component<EnhancedVirtualListProps<T>, EnhancedVirtualListState> {
  private container: HTMLDivElement;
  private animationFrameRequestHandle: number;

  private itemOffsets: number[] = [];
  private isCustomItemHeight: boolean = this.props.customItemHeight != null;
  private itemsRenderedWithAnimation: React.ReactText[] = [];

  constructor(props: EnhancedVirtualListProps<T>) {
    super(props);

    this.state = {
      offset: 0,
      startIndex: 0,
      endIndex: props.renderedItemsCount,
      totalHeight: this.calculateTotalHeight(props.objects),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: EnhancedVirtualListProps<T>): void {
    if (this.props.objects.length !== nextProps.objects.length) {
      this.setState({
        totalHeight: this.calculateTotalHeight(nextProps.objects),
      });
    } else {
      if (this.isCustomItemHeight && nextProps.objects && nextProps.objects.length > 0) {
        const { startIndex, endIndex } = this.state;
        const { objects } = this.props;
        const end = objects.length < endIndex + 1 ? objects.length : endIndex + 1;
        for (let i = startIndex; i < end; i++) {
          if (
            this.props.customItemHeight(nextProps.objects[i]) !==
            (i === this.props.objects.length - 1
              ? this.props.customItemHeight(this.props.objects[i])
              : this.itemOffsets[i + 1] - this.itemOffsets[i])
          ) {
            this.calculateCustomHeightValues(nextProps.objects);
            return;
          }
        }
      }
    }
  }

  public componentDidUpdate(_: EnhancedVirtualListProps<T>, prevState: EnhancedVirtualListState): void {
    const { objects, itemIdProvider } = this.props;
    this.itemsRenderedWithAnimation = objects.map((x, i) => (itemIdProvider ? itemIdProvider(x) : i));
    if (this.props.onStateUpdate && !isEqual(this.state, prevState)) {
      this.props.onStateUpdate(this.state);
    }
  }

  public render(): JSX.Element {
    const { objects, transitionClassName, itemIdProvider, itemHeight } = this.props;
    const items = objects.slice(this.state.startIndex, this.state.endIndex).map((object, index) => {
      const actualIndex = this.state.startIndex + index;
      const key = itemIdProvider ? itemIdProvider(object) : actualIndex;
      return (
        <div
          key={key}
          style={this.getStyleForItem(actualIndex)}
          className={classNames(
            'virtual-list-content__item',
            transitionClassName,
            { mounted: this.itemsRenderedWithAnimation.includes(key) },
          )}
        >
          {this.props.renderItem(object, actualIndex)}
        </div>
      );
    });

    return (
      <div className='virtual-list-wrapper'>
        <KreoScrollbars
          sendApi={this.props.sendScrollBarApi}
          showShadowTop={this.props.showShadowTop}
          smoothScroll={this.props.smoothScroll}
          onScroll={this.onScroll}
          onSendRef={this.saveContainerRef}
        >
          <div
            className='virtual-list-content'
            style={{
              height: `${objects.length * itemHeight}px`,
            }}
          >
            {transitionClassName ? (
              <TransitionGroup className='virtual-list-content__transition-wrap'>
                {items.map((item) => (
                  <CSSTransition key={item.key} classNames={transitionClassName} timeout={300}>{item}</CSSTransition>
                ))}
              </TransitionGroup>
            ) : (
              items
            )}
          </div>
        </KreoScrollbars>
      </div>
    );
  }

  public scrollToBottom(): void {
    this.container.scrollTop = this.container.scrollHeight;
  }

  public getMainContainer(): HTMLDivElement {
    return this.container;
  }

  @autobind
  private saveContainerRef(ref: HTMLDivElement): void {
    this.container = ref;
  }

  @autobind
  private getStyleForItem(index: number): React.CSSProperties {
    if (this.isCustomItemHeight) {
      return { top: this.itemOffsets[index] };
    }

    return {
      top: index > 0 ? this.props.itemHeight * index : 0,
    };
  }

  @autobind
  private onScroll(): void {
    if (this.animationFrameRequestHandle) {
      window.cancelAnimationFrame(this.animationFrameRequestHandle);
    }

    this.animationFrameRequestHandle = window.requestAnimationFrame(() => {
      const newOffset = this.container.scrollTop;

      this.setState(prevState => {
        const oldOffset = prevState.offset;

        if (newOffset < 0 || oldOffset === newOffset) {
          return;
        }

        let newStartIndex = 0;
        if (this.isCustomItemHeight) {
          // find start index with custom height
          newStartIndex = this.findIndex(
            newOffset,
            newOffset > this.itemOffsets[this.state.startIndex + 1] ? this.state.startIndex : 0,
          );
        } else {
          // find start index with default height
          newStartIndex = Math.floor(newOffset / this.props.itemHeight);
        }
        const newEndIndex = newStartIndex + this.props.renderedItemsCount;

        if (newStartIndex === prevState.startIndex) {
          return;
        }
        const newState = {
          offset: newOffset,
          startIndex: newStartIndex,
          endIndex: newEndIndex,
        };

        return newState;
      });
    });
  }

  @autobind
  private findIndex(offset: number, start: number): number {
    if (
      this.itemOffsets.length === 0
      || this.itemOffsets.length === 1
      || (this.props.totalHeight && offset < this.props.totalHeight)
    ) {
      return 0;
    }

    let i = start;
    let _offset = this.itemOffsets[i];
    let _offsetNext = this.itemOffsets[i + 1];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (_offset <= offset && _offsetNext >= offset) {
        return i;
      }
      i++;
      _offset = this.itemOffsets[i];
      _offsetNext = this.itemOffsets[i + 1];
    }
  }

  @autobind
  private calculateTotalHeight(objects: any[]): number {
    let totalHeight = 0;
    if (this.isCustomItemHeight) {
      totalHeight = this.calculateCustomHeightValues(objects);
    } else {
      totalHeight = (this.props.totalHeight || 0)
        + objects.length * this.props.itemHeight;
    }

    return totalHeight;
  }

  @autobind
  private calculateCustomHeightValues(objects: T[]): number {
    let totalHeight = this.props.totalHeight || 0;
    this.itemOffsets = [];
    objects.map((x, i) => {
      this.itemOffsets[i] = totalHeight;
      totalHeight = totalHeight + this.props.customItemHeight(x, i);
    });

    return totalHeight;
  }
}
