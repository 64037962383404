import classNames from 'classnames';
import * as React from 'react';

import './progress-bar.scss';

interface Props {
  value: number;
}

export class ProgressBar extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <div
        className={
          classNames(
            'progress-bar',
            {
              'progress-bar--completed': this.props.value === 100,
            },
          )
        }
      >
        <div className='progress-bar__indicator' style={{ width: `${this.props.value}%` }}/>
      </div>
    );
  }
}
