import { fromJS } from 'immutable';

import { RequestStatus } from 'common/enums/request-status';
import { CostsActionTypes } from '../units/projects/actions/types/costs';

const INITIAL_STATE = fromJS({
  costEstimateData: {
    construction_cost: [],
  },
  childsShownList: [],
  message: '',
  calculations: [],
  selectedCalculation: 0,
  priliminaries: [],
  costs: [],
  indirectCost: [],
  statuses: {
    costEstimate: RequestStatus.Loaded,
  },
  excelReportTypes: [],
});

export default function cost(
  state: any = INITIAL_STATE,
  action: any = { type: '', payload: null }): any {
  switch (action.type) {
    case CostsActionTypes.COST_SWITCH_CHILDS_REQUEST: {
      state = state.set('childsShownList', state.get('childsShownList').pop(fromJS(action.payload.id)));

      const base = state.getIn(['costEstimateData', 'construction_cost']);

      const deletedParent = [base.find((a: any): boolean => {
        // eslint-disable-next-line eqeqeq
        const item = a.size == undefined ? a : a.toJS();
        // eslint-disable-next-line eqeqeq
        return item.innerId == action.payload.id;
      }).toJS().innerId];

      const result = [];

      base.forEach((x: any): any => {
        // eslint-disable-next-line eqeqeq
        const item = x.size == undefined ? x : x.toJS();
        deletedParent.includes(item.parentId) ? deletedParent.push(item.innerId) : result.push(item);
      });

      return state.setIn(['costEstimateData', 'construction_cost'], fromJS(result));
    }
    case CostsActionTypes.COST_GET_PACKAGES_FAILED:
      return state.set('message', 'Что-то пошло не так...');
    case CostsActionTypes.COST_GET_COST_ESTIMATE_ROOTS_SUCCEEDED:
      return state.setIn(['costs'], fromJS(action.payload));
    case CostsActionTypes.COST_GET_COST_ESTIMATE_ROOTS_FAILED:
      return state.set('message', 'Снова, что-то пошло не так...');
    case CostsActionTypes.COST_LOAD_CALCULATIONS_SUCCESSED:
      return state.merge(fromJS({
        selectedCalculation: action.payload.calculations[0].calculationId,
        ...action.payload,
      }));
    case CostsActionTypes.COST_LOAD_CALCULATIONS_FAILED:
      action.payload = JSON.parse(action.payload);
      return state.merge(fromJS({
        ...action.payload,
      }));
    case CostsActionTypes.COST_CHANGE_CALCULATION:
      return state.merge(fromJS({
        selectedCalculation: action.payload.id,
      }));
    case CostsActionTypes.COST_GET_COST_ESTIMATE_SUCCEEDED:
      return state.merge(fromJS({
        costs: action.payload,
        statuses: { costEstimate: RequestStatus.Loaded },
      }));
    case CostsActionTypes.COST_DROP_DATA:
      return INITIAL_STATE;
    case CostsActionTypes.COST_GET_INDIRECT_DATA_SUCCESSED:
      return state.setIn(['indirectCost'], fromJS(action.payload));
    case CostsActionTypes.COST_COLLAPSE:
      return state.setIn(['costs', ...action.payload, 'expanded'], fromJS(false));
    case CostsActionTypes.COST_EXPAND:
      return state.setIn(['costs', ...action.payload, 'expanded'], fromJS(true));
    case CostsActionTypes.COST_ADD_CHILD:
      let s = state.setIn(['costs', ...action.payload.path, 'childrens'], fromJS(action.payload.childs));
      return s.setIn(['costs', ...action.payload.path, 'childrenProccessed'], fromJS(true));
    case CostsActionTypes.COST_CHANGE_FIELD_COMMIT:
      s = state;
      action.payload.forEach((x: any) => {
        const obj = x.toJS();
        s = s.setIn(['costs', ...obj.path], fromJS(obj.value));
      });
      return s;
    case CostsActionTypes.COST_GET_EXCEL_REPORT_TYPES_SUCCESSED:
      return state.setIn(['excelReportTypes'], fromJS(action.payload));
    default:
      return state;
  }
}

