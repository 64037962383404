import { flatbuffers } from 'flatbuffers';

import { NumberDictionary } from 'common/interfaces/dictionary';
import { arrayUtils } from 'common/utils/array-utils';
import {
  ModelBrowserPropertyGroupView,
  ModelBrowserPropertyLine,
  ModelBrowserPropertyLineLayer,
  PropertiesDataProviderApi,
} from '../../interfaces/properties-provider-types';
import { PropertiesFlatBufferTypes } from './properties-flat-buffer-types';

interface PropertiesGroup {
  name: number;
  properties: number[];
}

interface PropertyEntity {
  parsed: boolean;
  data: ModelBrowserPropertyLine | PropertiesFlatBufferTypes.PropertiesFlatBuffer;
}

interface LayerPropertyLink {
  id: number;
  properties: number;
}

interface ElementPropertiesInfo {
  layers: LayerPropertyLink[];
  properties: PropertiesGroup[];
  sourceId: string;
}


interface LayersIdsGroup {
  properties: number;
  layerIds: number[];
}

export class PropertiesDataProvider implements PropertiesDataProviderApi {
  private readonly invalidGroupNames: string[] = ['INVALID'];
  private uniqueGroupNames: string[];
  private uniqueProperties: PropertyEntity[];
  private elementProperties: Record<number, ElementPropertiesInfo>;
  private uniqueLayerProperties: ModelBrowserPropertyLineLayer[];

  constructor(data: Uint8Array) {
    const buffer = new flatbuffers.ByteBuffer(data);
    const properties = PropertiesFlatBufferTypes.BimModelRawDataFlatBuffer.getRootAsBimModelRawDataFlatBuffer(buffer);
    const invalidGroupIds = new Array<number>();
    {
      const lenght = properties.groupNamesLength();
      const groupNames = new Array<string>(lenght);
      for (let i = 0; i < lenght; i++) {
        const groupName = properties.groupNames(i);
        groupNames[i] = groupName.replace(/(PG_|_)/gi, ' ').toLowerCase();
        if (this.invalidGroupNames.includes(groupName)) {
          invalidGroupIds.push(i);
        }
      }
      this.uniqueGroupNames = groupNames;
    }

    {
      const elementPropertiesMap = properties.elementProperties();
      const length = elementPropertiesMap.keysLength();
      const elementProperties: NumberDictionary<ElementPropertiesInfo> = {};
      for (let i = 0; i < length; i++) {
        const bimHandleId = elementPropertiesMap.keys(i);
        const bimHandleProperties = elementPropertiesMap.values(i);
        const layersLength = bimHandleProperties.layersLength();
        const layerLinks = new Array<LayerPropertyLink>(layersLength);
        for (let j = 0; j < layersLength; j++) {
          const layerPropertyLink = bimHandleProperties.layers(j);
          layerLinks[j] = {
            id: layerPropertyLink.id().toFloat64(),
            properties: layerPropertyLink.properties(),
          };
        }

        const propertyGroups = new Array<PropertiesGroup>();
        for (let j = 0; j < bimHandleProperties.propertiesLength(); j++) {
          const propertyGroup = bimHandleProperties.properties(j);
          if (invalidGroupIds.includes(propertyGroup.name())) {
            continue;
          }
          propertyGroups.push({
            name: propertyGroup.name(),
            properties: Array.from(propertyGroup.propertiesArray()),
          });
        }
        elementProperties[bimHandleId.toFloat64()] = {
          properties: propertyGroups,
          layers: layerLinks,
          sourceId: bimHandleProperties.sourceId(),
        };
      }
      this.elementProperties = elementProperties;
    }

    {
      const propertiesLength = properties.propertiesLength();
      const uniqueProperties = new Array<PropertyEntity>(propertiesLength);
      for (let i = 0; i < propertiesLength; i++) {
        uniqueProperties[i] = {
          parsed: false,
          data: properties.properties(i),
        };
      }
      this.uniqueProperties = uniqueProperties;
    }

    {
      const layerPropertiesLength = properties.layerPropertiesLength();
      const uniqueLayerProperties = new Array<ModelBrowserPropertyLineLayer>(layerPropertiesLength);
      for (let i = 0; i < layerPropertiesLength; i++) {
        const property = properties.layerProperties(i);
        uniqueLayerProperties[i] = {
          layerFunction: property.layerFunction(),
          material: property.material(),
          thickness: property.thickness(),
        };
      }
      this.uniqueLayerProperties = uniqueLayerProperties;
    }
  }

  public getCommonElementProperties(ids: number[], showLayers: boolean = false): ModelBrowserPropertyGroupView[] {
    const { properties, layers } = this.getElementsProperties(ids, showLayers);
    const commonProperties = this.extractCommonProperties(properties);
    const processedCommonProperties = this.addNamesToProperties(commonProperties);
    if (showLayers) {
      this.addLayersToProperties(layers, processedCommonProperties);
    }
    return processedCommonProperties;
  }

  public getElementPropertiesName(ids: number[]): ModelBrowserPropertyGroupView[] {
    const uniqueProperties = this.getUniqueElementPropertiesGroups(this.getElementsPropertiesIterator(ids, false));
    const propsGroups = this.addNamesToProperties(uniqueProperties, (a => a.name));
    return propsGroups;
  }

  public getElementProrertyValue(id: number, group: string, propertyKey: string): string {
    const { properties } = this.getElementsProperties([id]);
    for (const propertyGroup of properties[0]) {
      if (this.uniqueGroupNames[propertyGroup.name] !== group) {
        continue;
      }
      for (const propertyId of propertyGroup.properties) {
        const property = this.getPropertyByPropertyId(propertyId);
        if (property.name === propertyKey) {
          return property.value;
        }
      }
    }
    return undefined;
  }

  public getElementProperties(
    id: number,
    showLayers: boolean = false,
  ): ModelBrowserPropertyGroupView[] {
    const { properties, layers } = this.getElementsProperties([id], showLayers);
    const elementProperties = this.addNamesToProperties(properties[0]);
    if (showLayers) {
      this.addLayersToProperties(layers, elementProperties);
    }
    return elementProperties;
  }

  public getElementPropertyNameToValuesMap(ids: number[]): Record<string, string[]> {
    const result = {};
    const propertyGroups = this.getUniqueElementPropertiesGroups(this.getElementsPropertiesIterator(ids, false));
    for (const group of propertyGroups) {
      group.properties.forEach(propertyId => {
        const property = this.getPropertyByPropertyId(propertyId);
        if (!(property.name in result)) {
          result[property.name] = [];
        }
        result[property.name].push(property.value);
      });
    }

    return result;
  }

  private addLayersToProperties(
    layers: LayerPropertyLink[][],
    processedCommonProperties: ModelBrowserPropertyGroupView[],
  ): void {
    const commonLayers = this.extractCommonLayers(layers);
    if (commonLayers.length) {
      processedCommonProperties.unshift({
        name: 'Layers',
        properties: this.addNamesToLayers(commonLayers),
      });
    }
  }

  private getUniqueElementPropertiesGroups(
    elementsPropertiesGroupsIterator: IterableIterator<{ properties: PropertiesGroup[], layers: LayerPropertyLink[] }>,
  ): PropertiesGroup[] {
    const uniquePropertiesGroups = [];
    const groupWithPropsDictionary = {};
    for (const { properties } of elementsPropertiesGroupsIterator) {
      for (const propertiesGroup of properties) {
        const propsDictionary = groupWithPropsDictionary[propertiesGroup.name];
        if (propsDictionary) {
          for (const propId of propertiesGroup.properties) {
            if (!propsDictionary[propId]) {
              propsDictionary[propId] = true;
              uniquePropertiesGroups[propsDictionary.index].properties.push(propId);
            }
          }
        } else {
          const propertiesMap = arrayUtils.toDictionary(propertiesGroup.properties, x => x, () => true);
          const index = uniquePropertiesGroups.push(
            { ...propertiesGroup, properties: propertiesGroup.properties.slice() },
          ) - 1;
          groupWithPropsDictionary[propertiesGroup.name] = {
            properties: propertiesMap,
            index,
          };
        }
      }
    }
    return uniquePropertiesGroups;
  }

  private getElementsProperties(
    ids: number[],
    withLayers: boolean = false,
  ): {
    properties: PropertiesGroup[][],
    layers: LayerPropertyLink[][],
  } {
    const properties = new Array<PropertiesGroup[]>();
    const layers = new Array<LayerPropertyLink[]>();
    for (const elementData of this.getElementsPropertiesIterator(ids, withLayers)) {
      properties.push(elementData.properties);
      layers.push(elementData.layers);
    }

    if (!properties.length) {
      return { properties: [], layers: [] };
    }

    return { properties, layers };
  }


  private *getElementsPropertiesIterator(
    ids: number[],
    withLayers: boolean,
  ): IterableIterator<{
    properties: PropertiesGroup[],
    layers: LayerPropertyLink[],
  }> {
    if (!ids || !ids.length) return { properties: [], layers: [] };
    const elementsWithoutPropertiesInfo = new Array<number>();
    for (const id of ids) {
      if (id in this.elementProperties) {
        yield {
          properties: [...this.elementProperties[id].properties],
          layers: withLayers ? [...this.elementProperties[id].layers] : [],
        };
      } else {
        elementsWithoutPropertiesInfo.push(id);
      }
    }
    if (elementsWithoutPropertiesInfo.length) {
      console.warn(`Elements without properties info: ${elementsWithoutPropertiesInfo}`);
    }
  }

  private extractCommonProperties(propertiesGroupsOfElements: PropertiesGroup[][]): PropertiesGroup[] {
    if (propertiesGroupsOfElements.length === 1) {
      return propertiesGroupsOfElements[0];
    } else if (!propertiesGroupsOfElements.length) {
      return [];
    }

    let commonProperties = propertiesGroupsOfElements[0];
    for (let i = 1; i < propertiesGroupsOfElements.length; i++) {
      const tempCommonProperties: PropertiesGroup[] = [];
      const { elementProperties, elementPropertyGroupsSet } =
        this.propertiesToDictionary(propertiesGroupsOfElements[i]);
      for (const commonPropertyGroup of commonProperties) {
        if (elementPropertyGroupsSet.has(commonPropertyGroup.name)) {
          const elementPropertiesOfSameGroup = elementProperties[commonPropertyGroup.name];
          const currentGroupProperties =
            commonPropertyGroup.properties.filter(x => elementPropertiesOfSameGroup.has(x));
          if (currentGroupProperties.length) {
            tempCommonProperties.push({
              name: commonPropertyGroup.name,
              properties: currentGroupProperties,
            });
          }
        }
      }
      commonProperties = tempCommonProperties;
    }
    return commonProperties;
  }

  private propertiesToDictionary(propertyGroups: PropertiesGroup[]): {
    elementProperties: NumberDictionary<Set<number>>,
    elementPropertyGroupsSet: Set<number>,
  } {
    const dict: NumberDictionary<Set<number>> = {};
    const propertiesGroups = [];
    for (const propertyGroup of propertyGroups) {
      dict[propertyGroup.name] = new Set(propertyGroup.properties);
      propertiesGroups.push(propertyGroup.name);
    }
    return {
      elementProperties: dict,
      elementPropertyGroupsSet: new Set(propertiesGroups),
    };
  }

  private getPropertyByPropertyId(id: number): ModelBrowserPropertyLine {
    const entity = this.uniqueProperties[id];
    if (!entity.parsed) {
      const serializedData = entity.data as PropertiesFlatBufferTypes.PropertiesFlatBuffer;
      entity.data = {
        value: serializedData.value(),
        name: serializedData.name(),
      };
      entity.parsed = true;
      return entity.data;
    }
    return entity.data as ModelBrowserPropertyLine;
  }


  private addNamesToProperties(
    propertyGroups: PropertiesGroup[],
    getHashKey: (item: ModelBrowserPropertyLine) => string = (item) => `${item.name}|${item.value}`,
  ): ModelBrowserPropertyGroupView[] {
    const processedProperties = new Array<ModelBrowserPropertyGroupView>();
    for (const propertyGroup of propertyGroups) {
      const properties = new Array<ModelBrowserPropertyLine>();
      for (const propertyId of propertyGroup.properties) {
        properties.push(this.getPropertyByPropertyId(propertyId));
      }
      arrayUtils.sortByField(properties, 0, properties.length - 1, 'name');

      const uniqProperties = arrayUtils.uniqWith(properties, getHashKey);
      processedProperties.push({
        name: this.uniqueGroupNames[propertyGroup.name],
        properties: uniqProperties,
      });
    }
    arrayUtils.sortByField(processedProperties, 0, processedProperties.length - 1, 'name');
    return processedProperties;
  }

  private extractCommonLayers(layerProperties: LayerPropertyLink[][]): LayersIdsGroup[] {
    const processedLayers = new Array<LayersIdsGroup>();
    const existedKeys: Record<string, LayersIdsGroup> = {};

    if (layerProperties.length > 0) {
      for (const layerProperty of layerProperties) {
        const layerKeyCounter: Record<string, number> = {};
        for (const layer of layerProperty) {
          let key = `${layer.properties}`;
          layerKeyCounter[key] = (layerKeyCounter[key] || 0) + 1;
          if (layerKeyCounter[key] > 1) {
            key = `${key} ${layerKeyCounter[key]}`;
          }
          existedKeys[key] = existedKeys[key] || { properties: layer.properties, layerIds: [] };
          existedKeys[key].layerIds.push(layer.id);
        }
      }
      for (const value of Object.values(existedKeys)) {
        if (value.layerIds.length === layerProperties.length) {
          processedLayers.push(value);
        }
      }
    }
    return processedLayers;
  }

  private addNamesToLayers(layers: LayersIdsGroup[]): ModelBrowserPropertyLineLayer[] {
    const processedLayers = new Array<ModelBrowserPropertyLineLayer>();
    for (const layer of layers) {
      processedLayers.push(this.uniqueLayerProperties[layer.properties]);
    }
    return processedLayers;
  }
}
