import * as monolite from 'monolite';

import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import {
  GanttChartChangePackageSelectionPayload,
  GanttChartRowExpandPayload,
} from '../../actions/payloads/gantt-chart';
import { GanttChartActionTypes } from '../../actions/types/gantt-chart';
import { GroupType } from '../../utils/gantt-chart';
import { FourDVisualisationState } from '../four-d-visualisation/interfaces/four-d-visualisation-state';


function expandCollapseAll(state: FourDVisualisationState, value: boolean): FourDVisualisationState {
  for (let i = 0; i < state.ganttData.workPackages.length; i++) {
    const workPackage = state.ganttData.workPackages[i];
    let hasGroupWithOtherState = false;
    if (workPackage.isExpanded) {
      for (let j = 0; j < workPackage.children.length; j++) {
        const activityGroup = workPackage.children[j];
        if (activityGroup.isExpanded !== value) {
          hasGroupWithOtherState = true;
          state = monolite.set(state, _ => _.ganttData.workPackages[i].children[j].isExpanded)(value);
        }
      }
    }
    if (!hasGroupWithOtherState) {
      state = monolite.set(state, _ => _.ganttData.workPackages[i].isExpanded)(value);
    }
  }
  return state;
}


export const ganttPageReducers: ReducerMethods<FourDVisualisationState> = {
  [GanttChartActionTypes.EXPAND_ITEM]: (state, payload: GanttChartRowExpandPayload) => {
    switch (payload.type) {
      case GroupType.WorkPackage: {
        const workPackageIndex = state.ganttData.workPackages.findIndex((wp) => wp.entityId === payload.rowId);
        return monolite.set(
          state,
          (s) => s.ganttData.workPackages[workPackageIndex].isExpanded)((prevValue) => !prevValue);
      }
      case GroupType.ActivityGroup: {
        const workPackageIndex = state.ganttData.workPackages.findIndex((wp) => wp.entityId === payload.parentIds[0]);
        const groupIndex =
          state.ganttData.workPackages[workPackageIndex].children.findIndex((ag) => ag.entityId === payload.rowId);
        return monolite.set(
          state, (s) => s.ganttData.workPackages[workPackageIndex].children[groupIndex].isExpanded,
        )((prevValue) => !prevValue);
      }
      default:
        return state;
    }
  },
  [GanttChartActionTypes.MULTIPLE_CHANGE_SELECTION]: (state, payload: GanttChartChangePackageSelectionPayload[]) => {
    const helper = new MonoliteHelper(state);
    if (!state.selectedPackages) {
      helper.set((_) => _.selectedPackages, {});
    }
    for (const change of payload) {
      helper.set((_) => _.selectedPackages[change.lineId], change.isSelect);
    }
    return helper.get();
  },
  [GanttChartActionTypes.CHANGE_SELECTION]: (state, payload: GanttChartChangePackageSelectionPayload) => {
    return new MonoliteHelper(state).set(_ => _.selectedPackages[payload.lineId], payload.isSelect).get();
  },
  [GanttChartActionTypes.EXPAND_ALL]: (state) => {
    return expandCollapseAll(state, true);
  },
  [GanttChartActionTypes.COLLAPSE_ALL]: (state) => {
    return expandCollapseAll(state, false);
  },
};
