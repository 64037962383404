import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1001;
  width: 240px;
  box-shadow: ${p => p.theme.shadow.xl};
  padding: 10px 0;
  border-radius: 15px;
  border: 1px solid ${p => p.theme.color.background};
  background-color: ${p => p.theme.color.backgroundRush};
  overflow: hidden;
  animation: ${Constants.Animations.slowTopAppear()} ${p => p.theme.duration.s} ${p => p.theme.function.qo};
  transform-origin: top right;
`;

const Header = styled.div`
  padding: 20px 20px 10px;
`;

const Main = styled.div`
  border-top: 10px solid ${p => p.theme.color.pale};

  > div:not(:last-child) {
    height: 40px;
  }
`;

const ThemeSwitcher = styled.div`
  width: 100%;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid ${p => p.theme.color.background};
  cursor: pointer;
  &, * {
    box-sizing: border-box;
  }
  p {
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  }
  :hover {
    p {
      color: ${p => p.theme.color.turquoise};
    }
  }
`;

export const Styled = {
  Container,
  Header,
  Main,
  ThemeSwitcher,
};
