import autobind from 'autobind-decorator';
import * as React from 'react';

import './comment.scss';

import { Avatar, IconButton, KreoIconSubnavForward } from 'common/UIKit';
import { CommentModel, CommentStatus } from '../../interfaces';
import { TextFormatter } from '../text-formatter';
import { TimeStamp } from '../time-stamp';


interface Props {
  type: any;
  read: boolean;
  authorId: string;
  authorLastName: string;
  authorFirstName: string;
  companyId: number;
  projectId: number;
  comment: CommentModel;
  date: Date;
  onCommentClick: (comment: any) => void;
  onToStateClick: (state: string, num: number) => void;
}

export class Comment extends React.Component<Props> {
  public render(): JSX.Element {
    let className = 'viewer-comments___comment';
    if (this.props.comment.selected) {
      className = `${className} x`;
    } else {
      if (this.props.comment.status === CommentStatus.Resolved) {
        className = `${className} s`;
      }
    }

    return (
      <div
        className={className}
        onClick={this.toState}
        data-control-name='select-comment'
      >
        {!this.props.read && <div className='viewer-new-message__sign' />}
        <Avatar
          firstName={this.props.authorFirstName}
          lastName={this.props.authorLastName}
          userId={this.props.authorId}
        />
        <div className='viewer-comment___comment-number spot' />
        <div className={`viewer-comment___comment-number ${this.props.type}`}>
          {this.props.comment.pointSerialNumber}
        </div>
        <div className='viewer-comment__comment-text'>
          <TextFormatter>{this.props.comment.data.text}</TextFormatter>
          <TimeStamp stamp={this.props.date} />
        </div>
        <div className='viewer-comment__comment-screenshot'>
          <img
            src={this.buildImageUri(
              this.props.companyId,
              this.props.projectId,
              this.props.comment.data.screen)}
          />
        </div>
        <div className='viwer-comment__comment-button'>
          <IconButton
            size='medium'
            onClick={this.commentClick}
            controlName='show-comment-button'
            icon={<KreoIconSubnavForward />}
          />
        </div>
      </div>
    );
  }

  private buildImageUri = (companyId: number, projectId: number, imageUuid: string): string => {
    return imageUuid ? `/api/company/${companyId}/projects/${projectId}/comments/images/${imageUuid}` : '';
  };

  @autobind
  private toState(): void {
    this.props.onToStateClick(this.props.comment.engineState, this.props.comment.pointSerialNumber);
  }

  @autobind
  private commentClick(): void {
    this.props.onToStateClick(this.props.comment.engineState, this.props.comment.pointSerialNumber);
    this.props.onCommentClick(this.props.comment);
  }
}
