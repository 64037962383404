import styled, { keyframes }  from 'styled-components';


const AppearanceAnimations = keyframes`
  0% {
    transform: rotateZ(225deg) scale(0);
  }
  100% {
    transform: rotateZ(0deg) scale(1); 
  }
`;

const Container = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.color.red};
  z-index: 1;
  animation: ${AppearanceAnimations} 0.375s ease both;
  cursor: pointer;

  svg {
    outline: none;
    box-sizing: border-box;
    fill: ${p => p.theme.color.white};
    height: 10px;
  }
`;

export const Styled = {
  Container,
};
