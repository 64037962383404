import { CompanyResourcesApi } from '../../../api/server';
import { ProjectsFolder, ProjectsFoldersC, ProjectsFolderFormData } from '../interfaces/projects-folder';

function getFolders(): Promise<ProjectsFolder[]> {
  return CompanyResourcesApi.getV(`/folders`, ProjectsFoldersC);
}

function createFolder(data: ProjectsFolderFormData): Promise<number> {
  return CompanyResourcesApi.post(`/folders`, data);
}

function updateFolder(folderId: number, data: ProjectsFolderFormData): Promise<void> {
  return CompanyResourcesApi.put(`/folders/${folderId}`, data);
}

function removeFolder(folderId: number, force: boolean): Promise<string[]> {
  return CompanyResourcesApi.delete(`/folders/${folderId}?force=${force}`, null);
}

export const ProjectsFolderApi = {
  getFolders,
  createFolder,
  updateFolder,
  removeFolder,
};
