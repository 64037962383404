import * as Ag from 'ag-grid-community';
import { useCallback, useState } from 'react';

export const useGridApiState = (): [(gridApi: Ag.GridApi) => void, Ag.GridApi] => {
  const [gridApiState, setGridApi] = useState<Ag.GridApi>();
  const saveGridRef = useCallback((gridApi: Ag.GridApi) => {
    setGridApi(gridApi);
  }, []);

  return [saveGridRef, gridApiState];
};
