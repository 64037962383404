import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
  border-bottom: 1px solid ${p => p.theme.color.background};
  box-sizing: border-box;
  padding: 0 15px;
`;

const HeaderName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }
`;

export const Styled = {
  Container,
  HeaderName,
};
