import styled from 'styled-components';


const InputWithSliderWrapper = styled.div`
  > div {
    width: 160px;
    padding: 0 0 10px 0;
  }
`;

export const Styled = {
  InputWithSliderWrapper,
};
