import React from 'react';
import { DNDDropTargetProps, withDropTargetWrapper } from 'common/utils/with-drag/with-drop-target-wrapper';
import { DrawingsGeometryGroupWithNesting } from '../../interfaces';
import { DrawingsDragAndDropType, DrawingsDragAndDropUtils } from '../../utils/drawings-annotation-drag-utils';

import { Styled } from './styled';

interface Props extends DNDDropTargetProps {
  moveToGroup(groupId: string, groupIds: string[], measurementIds: string[]): void;
  group: DrawingsGeometryGroupWithNesting;
  children: React.ReactNode;
  hovering: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onContextMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
  isSelected: boolean;
  hideCount: boolean;
  hidePin: boolean;
  nestingCount: number;
  forwardedRef: (ref: HTMLDivElement) => void;
  isOpenFilterPages?: boolean;
}

const ContainerContainer: React.FC<Props> = (props: Props) => {
  const {
    hovering,
    isSelected,
    onContextMenu,
    onClick,
    hidePin,
    nestingCount,
    hideCount,
    forwardedRef,
    isOpenFilterPages,
  } = props;
  return (
    <Styled.Container
      ref={forwardedRef}
      onClick={onClick}
      isSelected={isSelected}
      hidePin={hidePin}
      nestingCount={nestingCount}
      onContextMenu={onContextMenu}
      hovering={hovering}
      hideCount={hideCount}
      isOpenFilterPages={isOpenFilterPages}
    >
      {props.children}
    </Styled.Container>
  );
};

const specDrop = {
  drop: (props: Props, monitor) => {
    if (monitor.didDrop()) {
      return;
    }
    const item = monitor.getItem();
    const groupIds = item.groups.map((group) => group.id);
    props.moveToGroup(props.group.id, groupIds, item.measurements);

    return item;
  },
  canDrop: (props, monitor): boolean => {
    const item = monitor.getItem();
    return !DrawingsDragAndDropUtils.isLoop(item.groups, props.group.id);
  },
};


export const Container = withDropTargetWrapper<Props>(
  ContainerContainer,
  specDrop,
  DrawingsDragAndDropType.DrawingGroup,
);
