import { TinyText } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { PdfPagePreview } from '../preview';
import { Styled } from './styled';

interface Props {
  created: boolean;
  pdfId: string;
  pageId: string;
  color: string;
  pageNumber: number;
}

export const PdfPagePreviewWithBubble: React.FC<Props> = ({ created, pdfId, color, pageNumber, pageId }) => {
  return (
    <Styled.Container>
      <PdfPagePreview
        created={created}
        pdfId={pdfId}
        pageId={pageId}
      />
      <Styled.PageBubble color={color}>
        <TinyText color={'white'}>{pageNumber + 1}</TinyText>
      </Styled.PageBubble>
    </Styled.Container>
  );
};
