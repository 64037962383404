import autobind from 'autobind-decorator';
import * as React from 'react';
import { reloadWrapper } from 'common/utils/reload-wrapper';
import { DrawingsFailedMessageContainer } from '../failed-message-container';

export class DrawingsLoadingFailedMessage extends React.PureComponent {
  public render(): React.ReactNode {
    return (
      <DrawingsFailedMessageContainer buttonText='refresh' onButtonClick={this.onButtonClick}>
        <span>
          Oops. Something happened
          <br/>
          Please, reload the page
        </span>
      </DrawingsFailedMessageContainer>
    );
  }

  @autobind
  private onButtonClick(): void {
    reloadWrapper('drawings-loading-failed-message');
  }
}

