import styled from 'styled-components';

import { CollapseExpandButtonStyled } from 'components/splitter-layout/collapse-expand-button';


interface Props {
  bottomOffset?: number;
  isOpenPanel: boolean;
}

const Container = styled.div<Props>`
  height: calc(100% - ${p => (p.bottomOffset ? 50 : 0)}px);
  width: 100%;
  .splitter-layout {
    background: ${p => p.theme.color.backgroundRush};
  }
  .layout-splitter {
    z-index: 12;
    pointer-events: ${p => p.isOpenPanel ? 'all' : 'none'}; 

    &:hover,
    &:active {
      z-index: 16;

      ${CollapseExpandButtonStyled.Container} {
        transition: ${p => p.theme.duration.s} ease-in-out;

        > svg {
          transform: rotate(90deg);
        }
      }
    }
  }
`;

export const Styled = {
  Container,
};
