import React from 'react';
import { GlobalKeyboardEventsController } from 'common/components/global-keyboard-events-controller';
import { DrawingsLayoutConstants } from '../constants/drawings-layout-constants';


export function wrappWithHotkeys<T>(Component: React.ComponentType<T>): React.ComponentType<T> {
  return (p: T) => {
    return (
      <div style={{ width: '100%', height: DrawingsLayoutConstants.DRAWINGS_CONTROLS_HEIGHT }}>
        <GlobalKeyboardEventsController>
          <Component {...p}/>
        </GlobalKeyboardEventsController>
      </div>
    );
  };
}
