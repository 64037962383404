import { FormulaInputAutocompleteItemType } from '../enums';

export const ITEM_TYPE_COLOR: Record<FormulaInputAutocompleteItemType, string> = {
  [FormulaInputAutocompleteItemType.Property]: '#5555e1',
  [FormulaInputAutocompleteItemType.Function]: '#E1E155',
  [FormulaInputAutocompleteItemType.MeasuredFunction]: '#55E184',
  [FormulaInputAutocompleteItemType.Operator]: 'turquoise',
};

export const ITEM_TEXT_COLOR: Record<FormulaInputAutocompleteItemType, string> = {
  [FormulaInputAutocompleteItemType.Property]: 'white',
  [FormulaInputAutocompleteItemType.Function]: 'background',
  [FormulaInputAutocompleteItemType.MeasuredFunction]: 'background',
  [FormulaInputAutocompleteItemType.Operator]: 'white',
};
