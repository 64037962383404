import { DrawingsViewHelper } from '../drawings-view-helper';

export class DrawingsCursorTypeHelper {
  private _viewHelper: DrawingsViewHelper;

  private _autoApply: boolean = true;

  /** state statuses */
  private _grabbing: boolean;
  private _grabLayoutEnabled: boolean;
  private _marqueZoom: boolean;
  private _drawModeEnabled: boolean;
  private _hovered: boolean;
  private _hoveredSelected: boolean;
  private _loading: boolean;
  private _eraser: boolean;

  constructor(viewHelper: DrawingsViewHelper) {
    this._viewHelper = viewHelper;
  }

  public set eraser(value: boolean) {
    this._eraser = value;
    this.applyStateToCursor();
  }

  public set loading(value: boolean) {
    this._loading = value;
    this.applyStateToCursor();
  }

  public set marqueZoom(value: boolean) {
    this._marqueZoom = value;
    this.applyStateToCursor();
  }

  public set autoApply(value: boolean) {
    this._autoApply = value;
    if (value) {
      this.applyStateToCursor();
    }
  }

  public set grabLayerEnabled(value: boolean) {
    this._grabLayoutEnabled = value;
    this.applyStateToCursor();
  }

  public set grabbing(value: boolean) {
    this._grabbing = value;
    this.applyStateToCursor();
  }

  public set drawModeEnabled(value: boolean) {
    this._drawModeEnabled = value;
    this.applyStateToCursor();
  }

  public set hoveredSelected(value: boolean) {
    this._hoveredSelected = value;
    if (value) {
      this._hovered = true;
    }
    this.applyStateToCursor();
  }

  public set hovered(value: boolean) {
    this._hovered = value;
    this._hoveredSelected = false;
    this.applyStateToCursor();
  }

  public applyStateToCursor(): void {
    if (!this._autoApply) {
      return;
    }
    if (this._loading) {
      this._viewHelper.cursor = 'wait';
    } else if (this._eraser) {
      this._viewHelper.cursor = 'crosshair';
    } else if (this._grabbing) {
      this._viewHelper.cursor = 'grabbing';
    } else if (this._grabLayoutEnabled) {
      this._viewHelper.cursor = 'grab';
    } else if (this._marqueZoom) {
      this._viewHelper.cursor = 'zoom-in';
    } else if (this._drawModeEnabled) {
      this._viewHelper.cursor = 'crosshair';
    } else if (this._hoveredSelected) {
      this._viewHelper.cursor = 'grab';
    } else if (this._hovered) {
      this._viewHelper.cursor = 'pointer';
    } else {
      this._viewHelper.cursor = 'unset';
    }
  }
}
