import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { ModelCheckApi } from '../../api/model-check';
import * as ActionTypes from './action-types';
import { ModelCheckActions } from './actions';
import { ModelCheckDataResponse } from './interfaces/model-check-data-response';
import * as Utils from './utils';

function* getData({ payload: projectId }: ActionWith<number>): SagaIterator {
  try {
    const data: ModelCheckDataResponse = yield call(ModelCheckApi.getData);
    if (!data || !Array.isArray(data.singleGroup)) {
      throw new Error(`Faulty Model Check data received from backend, data: ${JSON.stringify(data)}`);
    }

    const preprocessed = Utils.preprocess(data);
    yield put(ModelCheckActions.getDataSuccessed(preprocessed));
  } catch (error) {
    console.error('model checker: get data failed', error, { projectId });
    yield put(ModelCheckActions.getDataFail());
  }
}

function* runModelCheck({ payload: types }: ActionWith<string[]>): SagaIterator {
  try {
    yield call(ModelCheckApi.runModelCheck, types);
  } catch (error) {
    console.error('model checker: run model check failed', error, { types });
    yield put(ModelCheckActions.getDataFail());
  }
}


function* getAvailableChecks(): SagaIterator {
  try {
    const availableChecks = yield call(ModelCheckApi.getAvailableChecks);
    yield put(ModelCheckActions.getAvailableChecksSuccess(availableChecks));
  } catch (error) {
    console.error('model checker: get available checks', error);
  }
}

export function* modelCheckSagas(): SagaIterator {
  yield takeLatest(ActionTypes.MODEL_CHECK_GET_DATA, getData);
  yield takeLatest(ActionTypes.MODEL_CHECK_RUN, runModelCheck);
  yield takeLatest(ActionTypes.MODEL_CHECK_GET_AVAILABLE_CHECKS, getAvailableChecks);
}
