import { DropdownInput } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import { ShortProjectHeader } from 'unit-projects/interfaces/short-project-header';

const EMPTY_TEMPLATE = 'Without template';

interface ComponentState {
  selectedTemplate: number;
}

interface Props {
  templates: ShortProjectHeader[];
  onChange: (id: number) => void;
}

export class TemplatesDropdown extends React.PureComponent<Props, ComponentState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      selectedTemplate: 0,
    };
  }

  public render(): React.ReactNode {
    const templateNames = [EMPTY_TEMPLATE, ...this.props.templates.map(template => template.name)];
    return (
      <DropdownInput
        header={'Project template'}
        elements={templateNames}
        activeElementIndex={this.state.selectedTemplate}
        onClick={this.onClick}
        closeAfterClick={true}
      />
    );
  }

  @autobind
  private onClick(index: number): void {
    const template = this.props.templates[index - 1];
    this.props.onChange(template ? template.id : null);
    this.setState({ selectedTemplate: index });
  }
}
