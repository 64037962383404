import styled from 'styled-components';

const Container = styled.div`
  display: inline-flex;
  height: 20px;
  margin: 0 0 30px 20px;
  > div {
    margin-right: 30px;
  }
`;

export const Styled = {
  Container,
};
