import styled from 'styled-components';


const Container = styled.div`
  padding: 10px;
`;

export const Styled = {
  Container,
};
