import React from 'react';

import { RenderIf } from 'common/components/render-if';
import { arrayUtils } from 'common/utils/array-utils';
import { FormIcon } from 'unit-2d-database/components/icons/item-form-icon/form-icon';
import { Constants } from 'unit-2d-database/constants';
import { VisibilityFilterType } from '../../../../interfaces';
import { ItemGroupFormData } from '../assembly-panel/item-form-updater';
import { InheritLabel, InheritType } from '../inherit-label';
import { Styled } from '../styled';
import { ItemGroup } from './group-item';

interface Props {
  groups: Record<string, ItemGroupFormData>;
  visibilityMode: VisibilityFilterType;
  collapsedGroups: Set<string>;
  toggleGroup: (id: string, isOpen: boolean) => void;
  withInhered: boolean;
  getId?: () => string;
  switchPanel?: (itemId: string) => void;
  onClickSelect?: (itemId: string, baseId: string) => void;
  parentId?: string;
  hideDeleteButton?: boolean;
  editItem?: (name: string) => void;
  disabled?: boolean;

}

export class ItemGroupForm extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const {
      parentId,
      groups,
      collapsedGroups,
      switchPanel,
      toggleGroup,
      visibilityMode,
      onClickSelect,
      hideDeleteButton,
      editItem,
      disabled,
      withInhered,
    } = this.props;

    const sortGroups = Object.entries(groups).sort((ga, gb) => {
      return arrayUtils.localCompareWithNumber(ga[1].name, gb[1].name);
    });

    const hasContent = !!sortGroups.length;

    return (
      <Styled.ItemForm hasContent={hasContent}>
        {sortGroups.map(([itemId, group]) => {
          return (
            <>
              <RenderIf condition={withInhered}>
                <InheritLabel
                  type={InheritType.Item}
                  entityName={group.name}
                />
              </RenderIf>
              <ItemGroup
                key={itemId}
                id={itemId}
                name={group.name}
                itemForm={group.itemForm}
                onDeleteItem={group.onDelete}
                isOpen={!collapsedGroups.has(this.getCollapseKey(itemId))}
                visibilityMode={visibilityMode}
                collapsedGroups={collapsedGroups}
                toggleGroup={toggleGroup}
                Icon={this.getIcon(group.iconType)}
                switchPanel={switchPanel}
                onClickSelect={onClickSelect}
                baseId={group.originId}
                parentId={parentId}
                hideDeleteButton={hideDeleteButton}
                editItem={editItem}
                disabled={disabled}
                getId={this.props.getId}
              />
            </>
          );
        })}
      </Styled.ItemForm>
    );
  }

  private getIcon(iconType: string): React.ComponentType {
    const Icon = Constants.ICON_LIST_MAP[iconType];
    return () => (<FormIcon Icon={<Icon />} />);
  }

  private getCollapseKey(id: string): string {
    const { parentId } = this.props;
    return `${parentId}_${id}`;
  }
}
