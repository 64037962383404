import React from 'react';
import { useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';

import { RenderIf } from 'common/components/render-if';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { DrawingsCommonPointsContextProvider } from '../../drawings-contexts';
import { TwoDZoomControl } from '../../drawings-floating-controls';
import { DrawingsDrawMode } from '../../enums/drawings-draw-mode';
import { DrawingsViewportHelper } from '../../helpers/viewport';
import { useCurrentDrawingInfo } from '../../hooks';
import { DrawingsInstanceMeasure, SaveDrawModeOptions } from '../../interfaces';
import { CanvasStatuses, DrawingsLoadingStatus } from '../drawings-canvas-status';
import { DrawingsPdfManagerButton } from '../pdf-manager-button';
import { DrawingsTopControlGroup } from '../top-control-group';
import { Styled } from './styled';

interface Props {
  showLoader: boolean;
  renderPageError: boolean;
  showPdfManagerButton: boolean;
  viewportHelper: DrawingsViewportHelper;
  drawMode: DrawingsDrawMode;
  isMarqueeZoomEnabled: boolean;
  loadStatus: RequestStatus;
  optimizeStatus: ViewModelStatus;
  toggleMarqueeZoom: () => void;
  onToggleFullScreen: () => void;
  canToggleFullScreen: boolean;
  changeDrawingMode: (mode: DrawingsDrawMode, options?: SaveDrawModeOptions) => void;
  getInstancesMeasures: (instanceId: string[]) => DrawingsInstanceMeasure[];
}


export const DrawingsCanvasContainer: React.FC<Props> = ({
  showLoader,
  renderPageError,
  showPdfManagerButton,
  viewportHelper,
  drawMode,
  isMarqueeZoomEnabled,
  loadStatus,
  optimizeStatus,
  toggleMarqueeZoom,
  onToggleFullScreen,
  getInstancesMeasures,
  children,
  canToggleFullScreen,
}) => {
  const currentDrawing = useCurrentDrawingInfo();
  const isFilterLoading = useSelector<State, boolean>((s) =>
    currentDrawing ? s.drawings.pageFilterApplyStatus[currentDrawing.drawingId] === RequestStatus.Loading : false,
  );


  const canvasStatus = React.useMemo(() => {
    if (renderPageError) {
      return CanvasStatuses.RenderFailed;
    } else if (optimizeStatus === ViewModelStatus.Failed) {
      return CanvasStatuses.OptimizationFailed;
    } else if (loadStatus === RequestStatus.Failed) {
      return CanvasStatuses.LoadingFailed;
    } else if (showLoader || isFilterLoading) {
      return CanvasStatuses.Loading;
    }
    return CanvasStatuses.Loaded;
  }, [renderPageError, optimizeStatus, loadStatus, showLoader, isFilterLoading]);

  const is3d = useSelector<State, boolean>(state => state.drawings.is3d);


  return (
    <Styled.Container id={'canvas-container'}>
      <ReactResizeDetector handleWidth={true} handleHeight={true} onResize={viewportHelper.onResize} />
      <DrawingsCommonPointsContextProvider>
        <Styled.Canvas>
          {children}
        </Styled.Canvas>
      </DrawingsCommonPointsContextProvider>
      <RenderIf condition={!is3d || !currentDrawing}>
        <TwoDZoomControl
          canToggleFullScreen={canToggleFullScreen}
          viewportHelper={viewportHelper}
          isMarqueeZoomEnabled={isMarqueeZoomEnabled}
          toggleMarqueeZoom={toggleMarqueeZoom}
          onToggleFullScreen={onToggleFullScreen}
        />
      </RenderIf>
      <DrawingsTopControlGroup
        drawMode={drawMode}
        getInstancesMeasures={getInstancesMeasures}
      />
      <DrawingsPdfManagerButton show={showPdfManagerButton} />
      <DrawingsLoadingStatus status={canvasStatus} />
    </Styled.Container>
  );
};
