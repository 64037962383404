import * as t from 'io-ts';

export const QtoReportInfoC = t.intersection(
  [
    t.type({
      id: t.number,
      projectId: t.number,
      name: t.string,
      isPublishable: t.boolean,
    }),
    t.partial({
      description: t.string,
    }),
  ],
  'QtoReportInfo',
);

export const QtoReportInfoArrayC = t.array(QtoReportInfoC);

export type QtoReportInfo = t.TypeOf<typeof QtoReportInfoC>;
