import { Icons, LinkComponent, ContextMenuGroup } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { DrawingsActions } from 'common/components/drawings';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { DrawModeContextProps, withDrawingModeApi } from 'common/components/drawings/hooks';
import {
  DrawingsShortInfo,
  UpdateComparisonSettingHandler,
} from 'common/components/drawings/interfaces';
import { FileInfo } from './file-info/file-info';
import { Styled } from './styled';

interface OwnProps {
  currentDrawing: DrawingsShortInfo;
  shown: boolean;
  drawingRendered: boolean;
  onReset: () => void;
  openCompareLayout: (e: React.MouseEvent) => void;
  onOpenFileMenuClick: () => void;
}

interface DispatchProps {
  updateSettingProperty: UpdateComparisonSettingHandler;
}

interface Props extends OwnProps, DispatchProps, DrawModeContextProps {
}

interface ComponentState {
  compareMenuOpened: boolean;
}

class CompareMenuComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      compareMenuOpened: false,
    };
  }

  public render(): React.ReactNode {
    const {
      currentDrawing: {
        pageToCompareId,
        drawingId,
      },
      onOpenFileMenuClick,
      openCompareLayout,
    } = this.props;
    return (
      <>
        <FileInfo
          pageId={drawingId}
        />
        <FileInfo
          pageId={pageToCompareId}
          onClick={onOpenFileMenuClick}
          onResize={openCompareLayout}
          onHide={this.onHide}
          shown={this.props.shown}
          drawingRendered={this.props.drawingRendered}
        />
        <Styled.ContextMenuGroupWrapper>
          <ContextMenuGroup>
            <Styled.Reset>
              <LinkComponent
                text='Reset'
                onClick={this.onResetClick}
                Icon={Icons.UndoClassic}
                fontSize={14}
              />
            </Styled.Reset>
          </ContextMenuGroup>
        </Styled.ContextMenuGroupWrapper>
      </>
    );
  }

  @autobind
  private onResetClick(): void {
    this.props.setDrawMode(DrawingsDrawMode.Disabled, { afterSave: this.props.onReset });
  }

  @autobind
  private onHide(): void {
    this.props.setDrawMode(DrawingsDrawMode.Disabled, {
      afterSave: () => {
        this.props.updateSettingProperty(this.props.currentDrawing.drawingId, 'isActive', !this.props.shown);
      },
    });
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    updateSettingProperty: (currentId, property, value) =>
      dispatch(DrawingsActions.updateComparisonSetting(currentId, property, value)),
  };
}


export const CompareMenu = connect(null, mapDispatchToProps)(withDrawingModeApi(CompareMenuComponent));
