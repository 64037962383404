import * as React from 'react';
import { connect } from 'react-redux';
import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';

import { TwoDDatabase } from 'unit-2d-database/2d-database';

interface DispatchProps {
  databaseLoadingStatus: RequestStatus;
}


const TwoDDatabaseFastNavigationPageComponent: React.FC<DispatchProps> = ({
  databaseLoadingStatus,
}) => {
  return (
    <>
      <TwoDDatabase menuTop={55} />;
      <RenderIf condition={databaseLoadingStatus === RequestStatus.Loading}>
        <Spinner show={true} />
      </RenderIf>
    </>
  );
};


function mapStateToProps(state: State): DispatchProps {
  return {
    databaseLoadingStatus: state.twoDDatabase.requestStatus,
  };
}

export const TwoDDatabaseFastNavigationPage = connect(mapStateToProps)(TwoDDatabaseFastNavigationPageComponent);
