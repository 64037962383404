import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { Notification, NotificationProcessor } from 'common/interfaces/realtime-messages';
import { RevisionsActions } from 'unit-cost-estimate/actions/creators';
import { AppUrls } from '../../../../routes/app-urls';
import { CommonNotificationProcessor } from '../../../notifications/common-notification-processor';
import { RevisionsNotificationKeys } from '../keys/revisions';


function currentUrlIsValid(projectId: string): boolean {
  return !window.location.pathname.startsWith(AppUrls.plan.project.index.url({ projectId })) &&
    !window.location.pathname.startsWith(AppUrls.plan.listing.url());
}

function* revisionStatusChanged(notification: Notification): SagaIterator {
  try {
    const { revisionId, state } = notification.data;
    yield put(RevisionsActions.setRevisionState(revisionId, state));
    if (currentUrlIsValid(notification.data.projectId.toString())) {
      yield call(CommonNotificationProcessor.addInfoAlert, notification);
    }
  } catch (error) {
    console.error(error);
  }
}

export const RevisionsNotificationsProcessor: NotificationProcessor = {
  [RevisionsNotificationKeys.RevisionStatusChanged]: revisionStatusChanged,
};
