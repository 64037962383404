import * as Ag from 'ag-grid-community';

import {
  DEFAULT_FIRST_COLUMN,
  ExcelFormulaHelper,
  UpdateCellData,
  UpdateCellDataTransaction,
} from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { ReportPage, ReportPageData, ReportPageType } from '../..';
import { TwoDApi } from '../../api';
import { UpdatedCell } from '../get-ref-map';
import { CellDataStore, ReportCellDataStore, RowDataStore, SheetDataStore } from './report-cell-data-store';

export const LOAD_REPORT_PROGRESS_TITLE = 'Loading...';

export type OnSheetRowsDataUpdated = (
  sheetId: string,
  payload: UpdateCellDataTransaction,
  updatedCells: UpdatedCell[],
) => void;
export type OnSheetColumnsDataUpdated = (sheetId: string, columns: Ag.ColDef[]) => void;
export type OnReportSheetLoaded = (reportPages: ReportPage[]) => void;
export type OnReportDataLoaded = (sheetId: string, etag: number) => void;
export type SetIsGetPageData = (isGetPageData: boolean) => void;
export type StartProgress = (total: number, title: string) => void;
export type UpdateProgress = (finished: number, title: string) => void;
export type CloseProgress = (title: string) => void;

export interface ReportCellDataControllerProps {
  onSheetRowsDataUpdated: OnSheetRowsDataUpdated;
  onSheetColumnsDataUpdated: OnSheetColumnsDataUpdated;
  onReportDataLoaded: OnReportDataLoaded;
  setIsGetPageData: SetIsGetPageData;
  startProgress: StartProgress;
  updateProgress: UpdateProgress;
  closeProgress: CloseProgress;
}

export class ReportCellDataController {
  private reportCellDataStore: ReportCellDataStore;
  private onSheetRowsDataUpdated: OnSheetRowsDataUpdated = null;
  private onSheetColumnsDataUpdated: OnSheetColumnsDataUpdated = null;
  private onReportDataLoaded: OnReportDataLoaded = null;
  private setIsGetPageData: SetIsGetPageData = null;
  private startProgress: StartProgress = null;
  private updateProgress: UpdateProgress = null;
  private closeProgress: CloseProgress = null;
  private dataLoaded: boolean = false;

  public constructor(props: ReportCellDataControllerProps) {
    this.reportCellDataStore = new ReportCellDataStore();
    this.onSheetRowsDataUpdated = props.onSheetRowsDataUpdated;
    this.onSheetColumnsDataUpdated = props.onSheetColumnsDataUpdated;
    this.onReportDataLoaded = props.onReportDataLoaded;
    this.setIsGetPageData = props.setIsGetPageData;
    this.startProgress = props.startProgress;
    this.updateProgress = props.updateProgress;
    this.closeProgress = props.closeProgress;
  }

  public updateCellDataStore(sheetId: string, columnId: string, rowId: string, data: string | number): void {
    const { rowData, updatedCell } = this.setCellDataStore(sheetId, columnId, rowId, data);
    this.onSheetRowsDataUpdated(sheetId, { update: [rowData] }, [updatedCell]);
  }

  public bulkUpdateSheetData(sheetId: string, updateCellsPayload: UpdateCellData[]):
    [RowDataStore[], RowDataStore[], UpdatedCell[]] {
    const rowsDataToUpdate: RowDataStore[] = [];
    const updatedCells: UpdatedCell[] = [];
    const rowsDataToAdd: Record<string, RowDataStore> = {};
    updateCellsPayload.forEach(updateCellPayload => {
      const { columnId, rowId, value } = updateCellPayload;
      const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
      const isUpdate = this.reportCellDataStore.selectSheetDataStore(sheetId)?.rows[rowIndex];
      const { rowData, updatedCell } = this.setCellDataStore(
        sheetId,
        columnId,
        rowId,
        value,
        updateCellPayload.prevValue,
      );
      updatedCells.push(updatedCell);
      if (isUpdate) {
        if (!rowsDataToAdd[`${sheetId}${rowIndex}`]) {
          rowsDataToUpdate.push(rowData);
        }
      } else {
        rowsDataToAdd[`${sheetId}${rowIndex}`] = rowData;
      }
    });
    if (rowsDataToAdd.length) {
      const newIndex = this.reportCellDataStore.selectSheetDataStore(sheetId).rows.length;
      const { rowData } = this.setCellDataStore(sheetId, 'A', `${newIndex + 1}`, undefined);
      rowsDataToAdd[`${sheetId}${newIndex + 1}`] = rowData;
    }
    return [Object.values(rowsDataToAdd), rowsDataToUpdate, updatedCells];
  }

  public setCellData(sheetId: string, columnId: string, rowId: string, data: string | number): void {
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const path = { sheetId, columnId, rowIndex };
    this.reportCellDataStore.updateCellDataStore(path, data);
  }

  public selectSheetDataStore(sheetId: string): SheetDataStore {
    return this.reportCellDataStore.selectSheetDataStore(sheetId);
  }

  public async initState(projectId: string, isActive: () => boolean): Promise<ReportPage[]> {
    try {
      const pages = await TwoDApi.getPages(projectId);
      if (pages.length) {
        this.startProgress(pages.length, LOAD_REPORT_PROGRESS_TITLE);
      }
      let loadCount = 0;
      for (const page of pages) {
        if (page.type !== ReportPageType.SpreadSheet) {
          loadCount++;
          this.updateProgress(loadCount, LOAD_REPORT_PROGRESS_TITLE);
          continue;
        }
        if (!isActive()) {
          break;
        }
        const pageData = await TwoDApi.getPageData(page.id, projectId);
        const sheetDataStore = this.getPageStore(pageData);
        this.reportCellDataStore.setSheetStore(page.id, sheetDataStore);
        this.onReportDataLoaded(page.id, pageData.etag || 0);
        loadCount++;
        this.updateProgress(loadCount, LOAD_REPORT_PROGRESS_TITLE);
      }
      this.dataLoaded = true;
      this.closeProgress(LOAD_REPORT_PROGRESS_TITLE);
      return pages;
    } catch (e) {
      return null;
    }
  }

  public async loadPagesData(pages: ReportPage[], projectId: string, onFinish: (id: string) => void): Promise<void> {
    const store = this.reportCellDataStore.getSheetsDataStore();
    for (const { id, type } of pages) {
      if (type === ReportPageType.MeasureView) {
        onFinish(id);
        continue;
      }
      if (store[id]) {
        continue;
      }
      try {
        const pageData = await TwoDApi.getPageData(id, projectId);
        const sheetDataStore = this.getPageStore(pageData);
        this.reportCellDataStore.setSheetStore(id, sheetDataStore);
        this.onReportDataLoaded(id, pageData.etag);
      } catch {
        console.error('Create');
      }
      onFinish(id);
    }
    this.setIsGetPageData(true);
  }

  public addRows(sheetId: string, count: number): void {
    const addedRows = this.reportCellDataStore.addRows(sheetId, count);
    this.onSheetRowsDataUpdated(sheetId, { add: addedRows }, []);
  }

  public setColumnWidthBulc(sheetId: string, payload: Array<{ columnId: string, width: number }>): void {
    payload.forEach(({ columnId, width }) => {
      this.reportCellDataStore.setColumnWidth(sheetId, columnId, width);
    });
    const columns = this.reportCellDataStore.selectSheetDataStore(sheetId).columns;
    this.onSheetColumnsDataUpdated(sheetId, columns);
  }

  public addColumns(sheetId: string, count: number): void {
    this.reportCellDataStore.addNewColumns(sheetId, count);
    const columns = this.reportCellDataStore.selectSheetDataStore(sheetId).columns;
    this.onSheetColumnsDataUpdated(sheetId, columns);
  }

  public isDataLoaded(): boolean {
    return this.dataLoaded;
  }

  public getUpdatedCells(sheetId: string): UpdatedCell[] {
    const rows = this.selectSheetDataStore(sheetId).rows;
    const updatedCells = [];
    rows.forEach((row, index) => {
      const rowId = ExcelTableRowIdentification.getRowIdFromIndex(index);
      for (const [columnKey, columnValue] of Object.entries(row)) {
        updatedCells.push({
          fullCellId: ExcelFormulaHelper.getCellLink(sheetId, columnKey, rowId),
          prevValue: columnValue,
          newValue: columnValue,
        });
      }
    });

    return updatedCells;
  }

  public getReportData(): CellDataStore {
    return this.reportCellDataStore.getSheetsDataStore();
  }

  public setStore(store: CellDataStore): void {
    this.reportCellDataStore.setStore(store);
  }

  public setSheetColumnsData(sheetId: string | null, store: CellDataStore): void {
    if (!sheetId || !store[sheetId]) {
      return;
    }

    this.onSheetColumnsDataUpdated(sheetId, store[sheetId].columns);
  }


  public async createSheet(): Promise<ReportPage> {
    const sheet = await TwoDApi.createPage();
    const sheetData = this.getPageStore(sheet.data);
    this.reportCellDataStore.setSheetStore(sheet.id, sheetData);
    delete sheet.data;
    return sheet;
  }

  public deleteSheet(sheetId: string): void {
    this.reportCellDataStore.deleteSheetStore(sheetId);
  }

  public clearStore(): void {
    this.reportCellDataStore.clearStore();
  }

  private setCellDataStore(
    sheetId: string,
    columnId: string,
    rowId: string,
    data: string | number,
    prevValue?: string,
  ): {
    rowData: RowDataStore,
    updatedCell: UpdatedCell,
  } {
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const path = { sheetId, columnId, rowIndex };
    const prevRowValue = this.reportCellDataStore.getRowDataStore(sheetId, rowIndex)[columnId];
    this.reportCellDataStore.updateCellDataStore(path, data);
    const rowData = this.reportCellDataStore.getRowDataStore(sheetId, rowIndex);
    const updatedCell: UpdatedCell = {
      fullCellId: ExcelFormulaHelper.getCellLink(sheetId, columnId, rowId),
      prevValue: prevValue || this.getUpdatedValue(prevRowValue),
      newValue: this.getUpdatedValue(rowData[columnId]),
    };
    return { rowData, updatedCell };
  }

  private getPageStore(reportPage: ReportPageData): SheetDataStore {
    const sheetDataStore: SheetDataStore = { rows: [], columns: [] };
    sheetDataStore.columns = reportPage.columns.map(this.reportCellDataStore.getDefaultColumnDef);
    sheetDataStore.columns.unshift(DEFAULT_FIRST_COLUMN);
    sheetDataStore.rows = reportPage.rows.map((row, index) => ({ ...row, index: index + 1, id: index + 1 }));
    return sheetDataStore;
  }

  private getUpdatedValue(data: string | number | undefined | null): string {
    return data === undefined || data === null
      ? ''
      : data.toString();
  }
}
