import { reset, startSubmit, stopSubmit } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { Feed } from 'common/interfaces/feed';
import { getGetErrorModelFromValidationResponse } from 'common/utils/redux-form-utils';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { NotificationActions } from '../../../units/notifications/actions';
import { AlertType } from '../../../units/notifications/alert-type';
import { DatabaseActivityListingActions } from '../actions/creators/database-activity-listing';
import { DatabaseResourceActions } from '../actions/creators/database-resource';
import {
  CreateResourcePayload,
  DeleteResourcePayload,
  EditResourcePayload,
  LoadResourcePayload,
  LoadResourcesPayload,
} from '../actions/payloads/database-resource';
import { DatabaseResourceActionTypes } from '../actions/types/database-resource';
import { ResourcesApi } from '../api/resources';
import { ResourceModel } from '../interfaces/resources-data';
import { ListModelMapper } from '../utils/list-model-mapper';


function* loadResources(action: ActionWith<LoadResourcesPayload>): SagaIterator {
  try {
    const { databaseId, type, filter } = action.payload;
    const result: Feed<ResourceModel> = yield call(ResourcesApi.getResources, databaseId, type, filter);
    const unitMap = yield selectWrapper(s => s.database.currentDatabase.unitMap);

    yield put(DatabaseResourceActions.loadResourcesResponse({
      count: result.count,
      skip: result.skip,
      data: ListModelMapper.getResourceListModels(result.data, action.payload.type, unitMap),
    }));
  } catch (error) {
    console.error('database resource: load resources failed', error, action.payload);
  }
}

function* loadResource(action: ActionWith<LoadResourcePayload>): SagaIterator {
  try {
    const { databaseId, resourceId, type } = action.payload;
    const result = yield call(ResourcesApi.getResource, databaseId, resourceId, type);
    yield put(DatabaseResourceActions.loadResourceResponse(result));
  } catch (error) {
    console.error('database resource: load resource failed', error, action.payload);
  }
}

function* createResource(action: ActionWith<CreateResourcePayload>): SagaIterator {
  try {
    const { databaseId, type, model, formId } = action.payload;
    yield put(startSubmit(formId));
    try {
      const result = yield call(ResourcesApi.createResource, databaseId, type, model);
      yield put(reset(formId));
      yield put(stopSubmit(formId));
      yield put(DatabaseResourceActions.createResourceResponse(result));
      yield put(DatabaseActivityListingActions.closeEditPanel());
    } catch (error) {
      yield put(stopSubmit(formId, getGetErrorModelFromValidationResponse(error)));
    }
  } catch (error) {
    console.error('database resource: create resource failed', error, action.payload);
  }
}

function* updateResource(action: ActionWith<EditResourcePayload>): SagaIterator {
  try {
    const { databaseId, resourceId, type, model, formId } = action.payload;
    yield put(startSubmit(formId));
    try {
      const result = yield call(ResourcesApi.updateResource, databaseId, resourceId, type, model);
      yield put(reset(formId));
      yield put(stopSubmit(formId));
      yield put(DatabaseResourceActions.updateResourceResponse(result));
      yield put(DatabaseActivityListingActions.closeEditPanel());
    } catch (error) {
      yield put(stopSubmit(formId, getGetErrorModelFromValidationResponse(error)));
    }
  } catch (error) {
    console.error('database resource: update resource failed', error, action.payload);
  }
}

function* deleteResource(action: ActionWith<DeleteResourcePayload>): SagaIterator {
  try {
    const { databaseId, resourceId, type } = action.payload;
    const result = yield call(ResourcesApi.deleteResource, databaseId, resourceId, type);
    yield put(DatabaseResourceActions.deleteResourceResponse(result));
  } catch (error) {
    yield put(NotificationActions.addAlert({ message: error.message, alertType: AlertType.Error }));
    console.error('database resource: delete resource failed', error, action.payload);
  }
}

export function* databaseResourceSagas(): SagaIterator {
  yield takeLatest(DatabaseResourceActionTypes.GET_RESOURCES_REQUEST, loadResources);
  yield takeLatest(DatabaseResourceActionTypes.GET_RESOURCE_REQUEST, loadResource);
  yield takeLatest(DatabaseResourceActionTypes.CREATE_RESOURCE_REQUEST, createResource);
  yield takeLatest(DatabaseResourceActionTypes.UPDATE_RESOURCE_REQUEST, updateResource);
  yield takeLatest(DatabaseResourceActionTypes.DELETE_RESOURCE_REQUEST, deleteResource);
}

