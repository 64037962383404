import { Icons, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface ItemProps {
  features: string[];
  marker?: JSX.Element;
}

export class FeaturesList extends React.Component<ItemProps> {
  public render(): JSX.Element {
    const { features, marker } = this.props;
    return (
      <Styled.Container>
        {features.map(feature => (
          <Styled.Wrapper key={feature}>
            <Styled.Marker>
              {marker ? marker : <Icons.Apply />}
            </Styled.Marker>
            <Styled.List>
              <Text>{feature}</Text>
            </Styled.List>
          </Styled.Wrapper>
        ))}
      </Styled.Container>
    );
  }
}
