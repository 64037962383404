import { SyncStatus } from 'common/enums/sync-status';
import { QtoSelectElementsEventSource } from 'unit-projects/enums/qto-select-elements-event-source';

export class SyncHelper {
  private syncSelected: Record<QtoSelectElementsEventSource, SyncStatus> = {
    LeftPanel: SyncStatus.InSync,
    Engine: SyncStatus.InSync,
    ReportPanel: SyncStatus.InSync,
  };

  public startSync(source: QtoSelectElementsEventSource, callBack: () => void, force?: boolean): void {
    if (force || !this.isOutSync()) {
      Object.keys(this.syncSelected).forEach(k => {
        if (k === source) {
          this.syncSelected[k] = SyncStatus.InSync;
          return;
        }
        this.syncSelected[k] = SyncStatus.OutSync;
      });

      callBack();
    }
  }

  public setInSync(source: QtoSelectElementsEventSource): void {
    if (this.syncSelected[source] === SyncStatus.Syncing) {
      this.syncSelected[source] = SyncStatus.InSync;
    }
  }

  public isSynced(source: QtoSelectElementsEventSource): boolean {
    return this.syncSelected[source] === SyncStatus.InSync;
  }

  public getSourceForSync(): string[] {
    const result: string[] = [];
    Object.keys(this.syncSelected).forEach(k => {
      if (this.syncSelected[k] === SyncStatus.OutSync) {
        this.syncSelected[k] = SyncStatus.Syncing;
        result.push(k);
      }
    });
    return result;
  }

  public setSyncStatus(source: QtoSelectElementsEventSource, syncStatus: SyncStatus): void {
    this.syncSelected[source] = syncStatus;
  }

  private isOutSync(): boolean {
    return Object.keys(this.syncSelected).some(k => this.syncSelected[k] !== SyncStatus.InSync);
  }
}
