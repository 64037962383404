import { ElementTooltip, Icons, LinkComponent } from '@kreo/kreo-ui-components';
import React from 'react';

interface Props {
  selectedPagesCount?: number;
  pagesCount?: number;
  onSelectAll: () => void;
  onDeselectAll: () => void;
  showText: boolean;
  selectAllText?: string;
  deselectAllText?: string;
}

export const OpenCloseButtons = ({
  onSelectAll,
  onDeselectAll,
  selectedPagesCount,
  pagesCount,
  selectAllText = 'Select all pages',
  deselectAllText = 'Deselect all pages',
  showText,
}: Props): JSX.Element => {
  const openText = showText
    ? selectAllText
    : undefined;
  const closeText = showText
    ? deselectAllText
    : undefined;
  const openMood = showText && selectedPagesCount === pagesCount
    ? 'disabled'
    : undefined;
  const closeMood = showText && selectedPagesCount === 0
    ? 'disabled'
    : undefined;
  return (
    <>
      <ElementTooltip
        text="Add all pages of the document to Quick Access"
        position="bottom"
        speed="l"
      >
        <LinkComponent
          text={openText}
          onClick={onSelectAll}
          Icon={Icons.SelectAll}
          mood={openMood}
        />
      </ElementTooltip>
      <ElementTooltip
        text="Remove all pages of the document from Quick Access"
        position="bottom"
        speed='l'
      >
        <LinkComponent
          text={closeText}
          onClick={onDeselectAll}
          Icon={Icons.DeselectAll}
          mood={closeMood}
        />
      </ElementTooltip>
    </>
  );
};
