import autobind from 'autobind-decorator';
import * as React from 'react';

import './role-item.scss';

import { Role } from 'common/interfaces/account/role';
import {
  IconButton,
  KreoIconAddedDone,
  KreoIconAddPlus,
  KreoIconCancel,
  KreoIconDelBasket,
  KreoIconEditPen,
  MaterialInput,
} from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';

interface Props {
  role?: Role;
  create?: boolean;
  index?: number;
  roleKey?: string;
  editableRole: string;
  groupCode?: string;
  onSave?(key: string, value: string): void;
  onCreate?(role: any): void;
  onDelete?(index: number): void;
  onEditRole(roleKey: string): void;
}

interface State {
  isEditing: boolean;
  name: string;
}

export class RoleItem extends React.Component<Props, State> {
  private input: MaterialInput = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isEditing: false,
      name: '',
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.editableRole !== props.roleKey && state.isEditing) {
      return {
        isEditing: false,
        name: '',
      };
    }

    return null;
  }

  public render(): React.ReactNode {
    const { create } = this.props;
    const { isEditing } = this.state;
    const isDeleted = (create && !isEditing) || (!create && !this.props.onDelete);
    return (
      <div className='manage-roles-dialog-role-item'>
        {isEditing
          ? this.renderEditableRoleItem()
          : create ? this.renderCreateNewRole() : this.renderRoleItem()}
        {create ? null : (
          <IconButton
            size='medium'
            className={'manage-roles-dialog-role-item__delete-btn'}
            onClick={this.deleteRole}
            disabled={isDeleted}
          >
            <KreoIconDelBasket />
          </IconButton>
        )}
      </div>
    );
  }

  @autobind
  private renderCreateNewRole(): React.ReactNode {
    return (
      <div
        className='manage-roles-dialog-role-item__create-btn'
        onClick={this.onEdit}
      >
        <KreoIconAddPlus />
      </div>
    );
  }

  @autobind
  private renderRoleItem(): React.ReactNode {
    const { role } = this.props;
    return (
      <div
        className='manage-roles-dialog-role-item__name'
        onClick={this.onEdit}
      >
        <b>{role && role.name}</b>
        <KreoIconEditPen />
      </div>
    );
  }

  @autobind
  private renderEditableRoleItem(): React.ReactNode {
    return (
      <div className='manage-roles-dialog-role-item__editable'>
        <MaterialInput
          value={this.state.name}
          onChange={this.updateName}
          ref={this.makeRef}
          placeholder='Role Name'
          displayedType={MaterialComponentType.Native}
        />
        <IconButton size='medium' onClick={this.saveName} disabled={!this.state.name}>
          <KreoIconAddedDone />
        </IconButton>
        <IconButton size='medium' onClick={this.onCancelEdit}>
          <KreoIconCancel />
        </IconButton>
      </div>
    );
  }

  @autobind
  private onCancelEdit(): void {
    this.setState({ isEditing: false, name: '' });
  }

  @autobind
  private onEdit(): void {
    const name = this.props.role ? this.props.role.name : '';
    this.props.onEditRole(this.props.roleKey);
    this.setState({ isEditing: true, name }, () => {
      if (this.input) {
        this.input.focus();
        const length = name.length;
        this.input.setSelectionRange(length, length);
      }
    });
  }

  @autobind
  private updateName(_event: React.ChangeEvent, value: string): void {
    this.setState({ name: value });
  }

  @autobind
  private saveName(): void {
    if (this.props.create) {
      this.props.onCreate({
        name: this.state.name,
        group: this.props.groupCode,
      });
    } else {
      this.props.onSave(`${this.props.roleKey}.name`, this.state.name);
    }
    this.setState({ isEditing: false, name: '' });
  }

  @autobind
  private makeRef(x: MaterialInput): void {
    this.input = x;
  }

  @autobind
  private deleteRole(): void {
    if (this.props.create) {
      this.setState({ isEditing: false, name: '' });
    } else if (this.props.onDelete) {
      this.props.onDelete(this.props.index);
    }
  }
}
