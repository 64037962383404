import { ModalWrapper } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { CreateCompany } from './create-company/create-company';


export const CREATE_COMPANY_DIALOG_NAME = 'CREATE_COMPANY_DIALOG_NAME';

export const CreateCompanyDialog: React.FC = () => {
  const dispatch = useDispatch();

  const closeDialog = useCallback(() => {
    dispatch(KreoDialogActions.closeDialog(CREATE_COMPANY_DIALOG_NAME));
  }, [dispatch]);

  return (
    <DialogWrapper name={CREATE_COMPANY_DIALOG_NAME}>
      <ModalWrapper onExit={closeDialog}>
        <CreateCompany />
      </ModalWrapper>
    </DialogWrapper>
  );
};
