import { ElementTooltip, Text, Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { CompanySubscriptionModel } from '../../../../account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../account/selectors';
import { CONFIRM_PAUSE_SUBSCRIPTION_DIALOG, PauseSubscriptionDialog } from './pause-subscription-dialog';
import { Styled } from './styled';

const DESCRIPTION_PAUSE = `Your subscription will be paused immediately. You can resume it at any time`;

interface StateProps {
  subscription: CompanySubscriptionModel;
}

interface DispatchProps {
  openDialog: () => void;
}

interface Props extends DispatchProps, StateProps, AbilityAwareProps {
  onClick: () => void;
}

const PauseSubscriptionButtonComponent: React.FC<Props> = ({ onClick, openDialog, ability, subscription }) => {
  if (ability.cannot(Operation.Read, Subject.PauseSubscription)) {
    return null;
  }

  const isPaused = AccountSelectors.isPausedSubscription(subscription);
  if (isPaused) {
    return null;
  }

  const isTrial = AccountSelectors.isTrialSubscription(subscription);
  if (isTrial) {
    return null;
  }

  return (
    <>
      <ElementTooltip
        text={DESCRIPTION_PAUSE}
        position="right"
        wordBreakAll={true}
        tooltipWidth={240}
      >
        <Text onClick={openDialog}>
          <Styled.PauseIcon>
            <Icons.PauseSubscription />
            Pause Subscription
          </Styled.PauseIcon>
        </Text>
      </ElementTooltip>
      <PauseSubscriptionDialog onConfirm={onClick}/>
    </>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    subscription: AccountSelectors.currentSubscription(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openDialog: () => dispatch(KreoDialogActions.openDialog(CONFIRM_PAUSE_SUBSCRIPTION_DIALOG)),
  };
};

export const PauseSubscriptionButton = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PauseSubscriptionButtonComponent));
