import React from 'react';

import { RootWrapper } from './root-wrapper';
import { Styled } from './styled';


export const DialogContainer: React.FC = ({ children }) => {

  return (
    <RootWrapper>
      <Styled.DialogContainer>
        {children}
      </Styled.DialogContainer>
    </RootWrapper>
  );
};

