import { Action } from 'redux';

import { RoleGroup } from 'common/enums/role-group';
import { ActionWith } from 'common/interfaces/action-with';
import {
  ApiDeletePeopleFromProject,
  InvitationCompanyUsers,
  UserMap,
} from 'common/interfaces/people';
import {
  CompanySubscriptionUsersChangedModel,
} from '../../../units/account/interfaces/company-subscription-users-changed-model';
import { Person } from '../interfaces/person';
import * as Payloads from './payloads';
import { PeopleActionTypes } from './types';

function getCompanyPeople(companyId: number): ActionWith<number> {
  return {
    type: PeopleActionTypes.COMPANY_PEOPLE_GET_REQUEST,
    payload: companyId,
  };
}

function getCompanyPeopleSucceeded(companyId: number, people: Person[]): ActionWith<Payloads.CompanyUsersPayload> {
  return {
    type: PeopleActionTypes.COMPANY_PEOPLE_GET_SUCCEEDED,
    payload: { companyId, people },
  };
}

function setPeopleFilter(filter: string): ActionWith<string> {
  return {
    type: PeopleActionTypes.SET_FILTER,
    payload: filter,
  };
}

function invitePeopleToCompanyNew(
  invites: InvitationCompanyUsers[],
): ActionWith<InvitationCompanyUsers[]> {
  return {
    type: PeopleActionTypes.INVITE_TO_COMPANY,
    payload: invites,
  };
}

function invitePeopleToCompany(): Action {
  return {
    type: PeopleActionTypes.INVITE_TO_COMPANY_REQUEST,
  };
}

function invitePeopleToProject(
  params: Payloads.InviteToProjectParams,
): ActionWith<Payloads.InviteToProjectParams> {
  return {
    type: PeopleActionTypes.INVITE_TO_PROJECT_REQUEST,
    payload: params,
  };
}

function saveUserMap(map: UserMap): ActionWith<UserMap> {
  return {
    type: PeopleActionTypes.SAVE_USER_MAP,
    payload: map,
  };
}

function addToUserMap(user: UserMap): ActionWith<UserMap> {
  return {
    type: PeopleActionTypes.ADD_TO_USER_MAP,
    payload: user,
  };
}

function deleteCompanyPerson(userId: string): ActionWith<string> {
  return {
    type: PeopleActionTypes.DELETE_COMPANY_PERSON_REQUEST,
    payload: userId,
  };
}

function rejectInvitation(userEmail: string): ActionWith<string> {
  return {
    type: PeopleActionTypes.REJECT_INVITATION,
    payload: userEmail,
  };
}

function rejectSucceeded(companyId: number, userEmail: string): ActionWith<Payloads.CompanyUserEmailPayload> {
  return {
    type: PeopleActionTypes.REJECT_INVITATION_SUCCEEDED,
    payload: { companyId, userEmail },
  };
}

function removeFromProject(
  params: ApiDeletePeopleFromProject,
): ActionWith<ApiDeletePeopleFromProject> {
  return {
    type: PeopleActionTypes.REMOVE_FROM_PROJECT,
    payload: params,
  };
}

function removeFromProjectSuccessed(emails: string[]): ActionWith<string[]> {
  return {
    type: PeopleActionTypes.REMOVE_FROM_PROJECT_SUCCESS,
    payload: emails,
  };
}

function changeDisplayedRoleGroup(
  roleGroup: RoleGroup | null,
): ActionWith<RoleGroup | null> {
  return {
    type: PeopleActionTypes.CHANGE_DISPLAYED_ROLE_GROUP,
    payload: roleGroup,
  };
}

function resendInvitationSucceeded(
  companyId: number,
  invitationToken: string,
): ActionWith<Payloads.CompanyUserInvitationTokenPayload> {
  return {
    type: PeopleActionTypes.RESEND_INVITATION_SUCCEEDED,
    payload: { companyId, invitationToken },
  };
}

function updateUserRole(userId: string, companyId: number, roleId: number): ActionWith<Payloads.UpdateUserRolePayload> {
  return {
    type: PeopleActionTypes.UPDATE_USER_ROLE_REQUEST,
    payload: {
      userId,
      companyId,
      roleId,
    },
  };
}

function updateUserRoleSucceeded(
  userId: string,
  companyId: number,
  roleId: number,
): ActionWith<Payloads.UpdateUserRolePayload> {
  return {
    type: PeopleActionTypes.UPDATE_USER_ROLE_SUCCEEDED,
    payload: {
      userId,
      companyId,
      roleId,
    },
  };
}

function dropInviteInfo(): Action {
  return {
    type: PeopleActionTypes.DROP_INVITE_INFO,
  };
}

function updateInvitedPeoples(
  payload: Payloads.UpdateInvitationToProjectParams,
): ActionWith<Payloads.UpdateInvitationToProjectParams> {
  return {
    type: PeopleActionTypes.UPDATE_INVITED_PEOPLES,
    payload,
  };
}

function updateCompanySubscriptionUsers(
  model: CompanySubscriptionUsersChangedModel,
): ActionWith<CompanySubscriptionUsersChangedModel> {
  return {
    type: PeopleActionTypes.UPDATE_SUBSCRIPTION_USERS,
    payload: model,
  };
}


function dropPeopleState(): Action {
  return {
    type: PeopleActionTypes.DROP_PEOPLE_STATE,
  };
}

function resendInvitation(userId: string): ActionWith<string> {
  return {
    type: PeopleActionTypes.RESEND_INVITATION,
    payload: userId,
  };
}

export const PeopleActions = {
  getCompanyPeople,
  getCompanyPeopleSucceeded,
  setPeopleFilter,
  invitePeopleToCompany,
  invitePeopleToProject,
  saveUserMap,
  addToUserMap,
  deleteCompanyPerson,
  rejectInvitation,
  rejectSucceeded,
  removeFromProject,
  removeFromProjectSuccessed,
  changeDisplayedRoleGroup,
  resendInvitationSucceeded,
  updateUserRole,
  updateUserRoleSucceeded,
  dropInviteInfo,
  invitePeopleToCompanyNew,
  updateInvitedPeoples,
  updateCompanySubscriptionUsers,
  dropPeopleState,
  resendInvitation,
};
