import React, { useCallback, useRef } from 'react';
import { Mention, SuggestionDataItem } from 'react-mentions';

import { TextArea } from 'common/components/text-area';
import { useCommentUserContext } from 'unit-2d-comments/comments-panel/comment-user-context';
import { MentionConstants } from 'unit-2d-comments/constants';
import { UserForMention } from 'unit-2d-comments/interfaces';
import { UserMention } from './user-mention';

interface Props {
  value: string;
  autofocus?: boolean;
  placeholder?: string;
  resolved?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFinish: () => void;
  onBlur?: (e?: React.FocusEvent<HTMLTextAreaElement>) => void;
}

const CommentTextAreaComponent: React.FC<Props> = ({
  value,
  autofocus,
  placeholder,
  resolved,
  onChange,
  onFinish,
  onBlur,
}) => {
  const { usersForMention, getUserDisplayName } = useCommentUserContext();
  const inputRef = useRef<HTMLInputElement>();

  const searchUsers = useCallback(
    (query: string, callback: (data: SuggestionDataItem[]) => void) => {
      const lowerCasedQuery = query.toLowerCase();
      const filteredUsers = usersForMention.filter(
        (x) => !x.isGuest && (x.display.toLowerCase().includes(lowerCasedQuery) || x.email.includes(lowerCasedQuery)),
      );
      callback(filteredUsers);
    },
    [usersForMention],
  );

  const getUserMention = useCallback((id) => `@${getUserDisplayName(id)}`, [getUserDisplayName]);
  const renderSuggest = useCallback((suggestion: UserForMention, _search, _highlightedDisplay, _index, focused) => {
    return <UserMention value={suggestion.display} focus={focused} />;
  }, []);

  return (
    <TextArea
      minHeight={resolved ? 55 : 30}
      value={value}
      onChange={onChange}
      onFinish={onFinish}
      autofocus={autofocus}
      placeholder={placeholder}
      onBlur={onBlur}
      inputRef={inputRef}
    >
      <Mention
        trigger="@"
        data={searchUsers}
        appendSpaceOnAdd={true}
        markup={MentionConstants.MentionMarkup}
        displayTransform={getUserMention}
        renderSuggestion={renderSuggest}
      />
    </TextArea>
  );
};

export const CommentTextArea = React.memo(CommentTextAreaComponent);
