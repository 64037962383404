import * as React from 'react';

export const KreoIconProductTakeoff: React.FC = () => {
  return (
    <svg
      width='200px'
      height='160px'
      viewBox='0 0 200 160'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Illustration_Takeoff' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <ellipse id='Oval' fillOpacity='0.1' fill='#384A6F' cx='100' cy='158' rx='70' ry='2' />
        <path
          d='M10,10 L100,10 L100,150 L16,150 C12.6862915,150 10,147.313708 10,144 L10,10 Z'
          id='Rectangle-Copy-4'
          fill='#E6F4FF'
        />
        <path
          // eslint-disable-next-line max-len
          d='M22,101 L88,101 C91.3137085,101 94,103.686292 94,107 L94,125 C94,128.313708 91.3137085,131 88,131 L22,131 C18.6862915,131 16,128.313708 16,125 L16,107 C16,103.686292 18.6862915,101 22,101 Z'
          id='Rectangle-Copy-2'
          fill='#FFFFFF'
        />
        <path
          // eslint-disable-next-line max-len
          d='M22,16 L88,16 C91.3137085,16 94,18.6862915 94,22 L94,91 C94,94.3137085 91.3137085,97 88,97 L22,97 C18.6862915,97 16,94.3137085 16,91 L16,22 C16,18.6862915 18.6862915,16 22,16 Z'
          id='Rectangle-Copy-2'
          fill='#FFFFFF'
        />
        <path
          d='M100,10 L190,10 L190,146 C190,148.209139 188.209139,150 186,150 L100,150 L100,10 Z'
          id='Rectangle-Copy-2'
          fill='#5C89E5'
        />
        <circle id='Oval-Copy' fill='#99D3FF' cx='177' cy='20' r='4' />
        <rect id='Rectangle' fill='#FFFFFF' x='108' y='136' width='6' height='6' rx='3' />
        <rect id='Rectangle' fill='#FFFFFF' x='117' y='136' width='16' height='6' rx='3' />
        <g id='Group-19' transform='translate(21.000000, 20.500000)'>
          <g id='Group-18' transform='translate(0.000000, 0.500000)'>
            <path
              // eslint-disable-next-line max-len
              d='M2.5,0 L2.5,0 C3.88071187,-2.53632657e-16 5,1.11928813 5,2.5 L5,2.5 C5,3.88071187 3.88071187,5 2.5,5 L2.5,5 C1.11928813,5 1.69088438e-16,3.88071187 0,2.5 L0,2.5 C-1.69088438e-16,1.11928813 1.11928813,2.53632657e-16 2.5,0 Z'
              id='Rectangle'
              fill='#99D3FF'
            />
            <path
              d='M2,3 L2,8 C2,9.65685425 3.34314575,11 5,11 L10,11'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M2,3 L2,42 C2,43.6568542 3.34314575,45 5,45 L10,45'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M2,3 L2,84 C2,85.6568542 3.34314575,87 5,87 L10,87'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M17.5,10.5 L50.5,10.5'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M24,19 L51,19'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10,11 L10,16 C10,17.6568542 11.3431458,19 13,19 L18,19'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              // eslint-disable-next-line max-len
              d='M10.5,8 L10.5,8 C11.8807119,8 13,9.11928813 13,10.5 L13,10.5 C13,11.8807119 11.8807119,13 10.5,13 L10.5,13 C9.11928813,13 8,11.8807119 8,10.5 L8,10.5 C8,9.11928813 9.11928813,8 10.5,8 Z'
              id='Rectangle'
              fill='#99D3FF'
            />
            <path
              // eslint-disable-next-line max-len
              d='M17.5,16 L17.5,16 C18.8807119,16 20,17.1192881 20,18.5 L20,18.5 C20,19.8807119 18.8807119,21 17.5,21 L17.5,21 C16.1192881,21 15,19.8807119 15,18.5 L15,18.5 C15,17.1192881 16.1192881,16 17.5,16 Z'
              id='Rectangle-Copy-10'
              fill='#99D3FF'
            />
            <path
              d='M24,27 L51,27'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10,15 L10,24 C10,25.6568542 11.3431458,27 13,27 L18,27'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              // eslint-disable-next-line max-len
              d='M17.5,24 L17.5,24 C18.8807119,24 20,25.1192881 20,26.5 L20,26.5 C20,27.8807119 18.8807119,29 17.5,29 L17.5,29 C16.1192881,29 15,27.8807119 15,26.5 L15,26.5 C15,25.1192881 16.1192881,24 17.5,24 Z'
              id='Rectangle-Copy-10'
              fill='#99D3FF'
            />
            <path
              d='M24,35 L51,35'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10,23 L10,32 C10,33.6568542 11.3431458,35 13,35 L18,35'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              // eslint-disable-next-line max-len
              d='M17.5,32 L17.5,32 C18.8807119,32 20,33.1192881 20,34.5 L20,34.5 C20,35.8807119 18.8807119,37 17.5,37 L17.5,37 C16.1192881,37 15,35.8807119 15,34.5 L15,34.5 C15,33.1192881 16.1192881,32 17.5,32 Z'
              id='Rectangle-Copy-10'
              fill='#99D3FF'
            />
            <path
              d='M17.5,86.5 L50.5,86.5'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M24,95 L51,95'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10,87 L10,92 C10,93.6568542 11.3431458,95 13,95 L18,95'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              // eslint-disable-next-line max-len
              d='M10.5,84 L10.5,84 C11.8807119,84 13,85.1192881 13,86.5 L13,86.5 C13,87.8807119 11.8807119,89 10.5,89 L10.5,89 C9.11928813,89 8,87.8807119 8,86.5 L8,86.5 C8,85.1192881 9.11928813,84 10.5,84 Z'
              id='Rectangle'
              fill='#99D3FF'
            />
            <path
              // eslint-disable-next-line max-len
              d='M17.5,92 L17.5,92 C18.8807119,92 20,93.1192881 20,94.5 L20,94.5 C20,95.8807119 18.8807119,97 17.5,97 L17.5,97 C16.1192881,97 15,95.8807119 15,94.5 L15,94.5 C15,93.1192881 16.1192881,92 17.5,92 Z'
              id='Rectangle-Copy-10'
              fill='#99D3FF'
            />
            <path
              d='M24,103 L51,103'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M56.5,10.5 L63.5,10.5'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M56,19 L64,19'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M56,27 L64,27'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M56,35 L64,35'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M56.5,86.5 L63.5,86.5'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M56,95 L64,95'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M56,103 L64,103'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10,91 L10,100 C10,101.656854 11.3431458,103 13,103 L18,103'
              id='Path'
              stroke='#99D3FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              // eslint-disable-next-line max-len
              d='M17.5,100 L17.5,100 C18.8807119,100 20,101.119288 20,102.5 L20,102.5 C20,103.880712 18.8807119,105 17.5,105 L17.5,105 C16.1192881,105 15,103.880712 15,102.5 L15,102.5 C15,101.119288 16.1192881,100 17.5,100 Z'
              id='Rectangle-Copy-10'
              fill='#99D3FF'
            />
          </g>
        </g>
        <g id='Group-5' transform='translate(122.000000, 46.000000)' fill='#FFFFFF'>
          <polygon id='Rectangle-4' fillOpacity='0.1' points='28 23 28 59 9.9475983e-14 45 9.9475983e-14 9' />
          <polygon id='Rectangle-4' fillOpacity='0.2' points='20 31 20 55 8 49 8 25' />
          <polygon id='Rectangle-4' fillOpacity='0.16' points='28 23 28 59 46 50 46 14' />
          <polygon id='Rectangle-4' fillOpacity='0.34' points='18 8.87345752e-14 46 14 28 23 0 9' />
        </g>
        <path
          d='M122,100 L150,114 L168,105'
          id='Path'
          stroke='#FFFFFF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='2,3'
        />
        <path
          // eslint-disable-next-line max-len
          d='M122,97 L122,97 C123.656854,97 125,98.3431458 125,100 L125,100 C125,101.656854 123.656854,103 122,103 L122,103 C120.343146,103 119,101.656854 119,100 L119,100 C119,98.3431458 120.343146,97 122,97 Z'
          id='Rectangle-Copy-10'
          fill='#FF8566'
        />
        <path
          // eslint-disable-next-line max-len
          d='M150,111 L150,111 C151.656854,111 153,112.343146 153,114 L153,114 C153,115.656854 151.656854,117 150,117 L150,117 C148.343146,117 147,115.656854 147,114 L147,114 C147,112.343146 148.343146,111 150,111 Z'
          id='Rectangle-Copy-11'
          fill='#FF8566'
        />
        <g id='Group-25' transform='translate(60.000000, 63.000000)' strokeLinecap='round' strokeLinejoin='round'>
          <path
            d='M29,19 C29,13.4771525 24.5228475,9 19,9 C13.4771525,9 9,13.4771525 9,19'
            id='Oval'
            stroke='#F5CA5D'
            strokeWidth='2'
            transform='translate(19.000000, 14.000000) rotate(-270.000000) translate(-19.000000, -14.000000) '
          />
          <path
            d='M7.36074017,24.0401758 C8.48411814,22.9845795 9.36341498,21.6722198 9.90492013,20.1968072'
            id='Oval'
            stroke='#F5CA5D'
            strokeWidth='2'
            transform='translate(8.632830, 22.118492) rotate(-270.000000) translate(-8.632830, -22.118492) '
          />
          <path
            // eslint-disable-next-line max-len
            d='M32.0641105,6.66399586 C29.7725313,5.02718395 26.9666793,4.06411053 23.9358895,4.06411053 C16.203903,4.06411053 9.93588947,10.332124 9.93588947,18.0641105'
            id='Oval'
            stroke='#FF8566'
            strokeWidth='2'
            transform='translate(21.000000, 11.064111) rotate(-270.000000) translate(-21.000000, -11.064111) '
          />
          <path
            // eslint-disable-next-line max-len
            d='M4.38232923,32.3823292 C12.1143157,32.3823292 18.3823292,26.1143157 18.3823292,18.3823292 C18.3823292,15.0647625 17.228379,12.0167154 15.2999616,9.61767077'
            id='Oval'
            stroke='#FF8566'
            strokeWidth='2'
            transform='translate(11.382329, 21.000000) rotate(-270.000000) translate(-11.382329, -21.000000) '
          />
          <g id='Group-23' transform='translate(12.000000, 8.000000)' stroke='#5C89E5'>
            <path
              // eslint-disable-next-line max-len
              d='M5,2 L2,2 C0.8954305,2 -1.3527075e-16,2.8954305 0,4 L0,4 C1.3527075e-16,5.1045695 0.8954305,6 2,6 L3,6 C4.1045695,6 5,6.8954305 5,8 L5,8 C5,9.1045695 4.1045695,10 3,10 L0,10'
              id='Path-6'
              strokeWidth='2'
            />
            <path d='M2.5,0 L2.5,12' id='Path-7' />
          </g>
        </g>
        <g id='Group-24' transform='translate(2.000000, 63.000000)'>
          <path
            // eslint-disable-next-line max-len
            d='M6,0 L55,0 C58.3137085,-6.08718376e-16 61,2.6862915 61,6 L61,22 C61,25.3137085 58.3137085,28 55,28 L6,28 C2.6862915,28 4.05812251e-16,25.3137085 0,22 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z'
            id='Rectangle-Copy-2'
            fill='#BDE2FF'
          />
          <path
            d='M19.5,8.5 L32.5,8.5'
            id='Path'
            stroke='#5C89E5'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <polyline
            id='Path'
            stroke='#5C89E5'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='8 8.5 10 10.5 14 6.5'
          />
          <path
            d='M38.5,8.5 L44.5,8.5'
            id='Path'
            stroke='#5C89E5'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M19.5,18.5 L32.5,18.5'
            id='Path'
            stroke='#5C89E5'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <polyline
            id='Path'
            stroke='#5C89E5'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='8 18.5 10 20.5 14 16.5'
          />
          <path
            d='M38.5,18.5 L44.5,18.5'
            id='Path'
            stroke='#5C89E5'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M50.5,8.5 L51.5,8.5'
            id='Path'
            stroke='#5C89E5'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M50.5,18.5 L51.5,18.5'
            id='Path'
            stroke='#5C89E5'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          // eslint-disable-next-line max-len
          d='M16,0 L184,0 C187.313708,-6.08718376e-16 190,2.6862915 190,6 L190,10 L10,10 L10,6 C10,2.6862915 12.6862915,6.08718376e-16 16,0 Z'
          id='Rectangle'
          fill='#DBECFF'
        />
        <path
          // eslint-disable-next-line max-len
          d='M18,7 C16.8954305,7 16,6.1045695 16,5 C16,3.8954305 16.8954305,3 18,3 C19.1045695,3 20,3.8954305 20,5 C20,6.1045695 19.1045695,7 18,7 Z M25,3 L51,3 C52.1045695,3 53,3.8954305 53,5 C53,6.1045695 52.1045695,7 51,7 L25,7 C23.8954305,7 23,6.1045695 23,5 C23,3.8954305 23.8954305,3 25,3 Z'
          id='Shape'
          fill='#FFFFFF'
        />
        <polygon
          id='Path-5'
          stroke='#BDE2FF'
          fill='#BDE2FF'
          strokeLinejoin='round'
          transform='translate(174.656854, 106.656854) rotate(-45.000000) translate(-174.656854, -106.656854) '
          // eslint-disable-next-line max-len
          points='174.656854 101.656854 171.656854 107.656854 173.656854 107.656854 173.656854 111.656854 175.656854 111.656854 175.656854 107.656854 177.656854 107.656854'
        />
      </g>
    </svg>
  );
};

