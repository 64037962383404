
import { SubscriptionType } from 'common/constants/subscription';
import { Feed } from 'common/interfaces/feed';
import { ValuePayload } from 'common/request-payloads/value-payload';
import { objectUtils } from 'common/utils/object-utils';
import { CommonApi } from '../../../api/server';
import { CurrencyModel, CurrencyModelsArrayC } from '../../account/interfaces/currency-model';
import {
  AttachDatabasesParams,
  DatabaseFeedParams,
  DetachDatabasesParams,
} from '../actions/payloads/companies';
import { AdminCompanyVm } from '../interfaces/admin-company-vm';
import { AdminDatabaseVm, AdminDatabaseVmsC } from '../interfaces/admin-database-vm';
import {
  AttachCompanyEmployeeRequestPayload,
} from '../interfaces/attach-company-employee-request-payload';
import { CompanyMockSubscriptionSaveModel } from '../interfaces/company-mock-subscription-save-model';
import {
  AdminCompanyVmFeedC,
  AdminDatabaseVmFeedC,
  FeedParams,
  ShortUserFeedC,
} from '../interfaces/feed';
import { ShortRole, ShortRolesC } from '../interfaces/short-role';
import { ShortUser, ShortUsersC } from '../interfaces/short-user';
import { SubscriptionPlanOption } from '../interfaces/subscription-plan-option';

const CompaniesPageSize = 15;
const CompanyEmployeesPageSize = 15;

function getCompaniesFeed(data: FeedParams): Promise<Feed<AdminCompanyVm>> {
  const params = {
    skip: data.skip,
    take: CompaniesPageSize,
    search: data.search,
  };

  return CommonApi.getV(
    `/admin/companies?${objectUtils.toQueryString(params)}`,
    AdminCompanyVmFeedC);
}

function deleteProjects(companyId: number): Promise<void> {
  return CommonApi.delete(`/admin/companies/${companyId}/projects`);
}

function getDatabasesFeed(data: DatabaseFeedParams): Promise<Feed<AdminDatabaseVm>> {
  const query = objectUtils.toQueryString(data);

  return CommonApi.getV(
    `/admin/databases${query ? '?' : ''}${query}`,
    AdminDatabaseVmFeedC,
    'Error while fetching databases feed',
  );
}

function attachDatabasesToCompany(data: AttachDatabasesParams): Promise<AdminDatabaseVm[]> {
  return CommonApi.patchV(
    `/admin/companies/${data.companyId}/databases`,
    { ids: data.databaseIds },
    AdminDatabaseVmsC,
    'Error while attaching databases to company',
  );
}

function detachDatabaseFromCompany(data: DetachDatabasesParams): Promise<void> {
  return CommonApi.delete<void>(
    `/admin/companies/${data.companyId}/databases/${data.databaseId}`,
    null,
    'Error while detaching database from company');
}

function getEmployeesFeed(data: FeedParams): Promise<Feed<ShortUser>> {
  const params = {
    skip: data.skip,
    take: CompanyEmployeesPageSize,
    search: data.search,
  };

  return CommonApi.getV(
    `/admin/employees?${objectUtils.toQueryString(params)}`,
    ShortUserFeedC,
  );
}

function attachEmployeeToCompany(
  companyId: number, subscriptionType: SubscriptionType, userId: string, roleId?: number,
): Promise<void> {
  return CommonApi.post<void, AttachCompanyEmployeeRequestPayload>(
    `/admin/companies/${companyId}/${subscriptionType}/users`,
    { employeeGuid: userId, roleId });
}

function detachEmployeeFromCompany(
  companyId: number, subscriptionType: SubscriptionType, userId: string,
): Promise<void> {
  return CommonApi.delete<void, void>(`/admin/companies/${companyId}/${subscriptionType}/users/${userId}`);
}

function getRoles(companyId: number, subscriptionType: SubscriptionType): Promise<ShortRole[]> {
  return CommonApi.getV(
    `/admin/companies/${companyId}/${subscriptionType}/roles`,
    ShortRolesC);
}

function getProjectUsers(projectId: number): Promise<ShortUser[]> {
  return CommonApi.getV(
    `/admin/projects/${projectId}/users`,
    ShortUsersC,
  );
}

function attachEmployeeToProject(projectId: number, userId: string): Promise<void> {
  return CommonApi.post<void, ValuePayload<string>>(
    `/admin/projects/${projectId}/users`,
    { value: userId },
  );
}

function detachEmployeeFromProject(projectId: number, userId: string): Promise<void> {
  return CommonApi.delete(
    `/admin/projects/${projectId}/users/${userId}`,
    'error in detach employee from project',
  );
}

function getSubscriptionPlans(): Promise<SubscriptionPlanOption[]> {
  return CommonApi.get('/admin/subscription-plans');
}

function setMockSubscription(
  companyId: number,
  subscriptionType: SubscriptionType,
  mockSubscription: CompanyMockSubscriptionSaveModel,
): Promise<void> {
  return CommonApi.put<void, CompanyMockSubscriptionSaveModel>(
    `/admin/companies/${companyId}/${subscriptionType}/mock-subscription`,
    mockSubscription);
}

function deleteMockSubscription(companyId: number, subscriptionType: SubscriptionType): Promise<void> {
  return CommonApi.delete(`/admin/companies/${companyId}/${subscriptionType}/mock-subscription`);
}

function createEmptySubscription(companyId: number, subscriptionType: SubscriptionType): Promise<void> {
  return CommonApi.post(`/admin/companies/${companyId}/subscription/${subscriptionType}`);
}

function getCurrencies(): Promise<CurrencyModel[]> {
  return CommonApi.getV('/common/currencies', CurrencyModelsArrayC);
}

function updateCompanyCurrency(companyId: number, currencyCode: string): Promise<void> {
  return CommonApi.put<void, ValuePayload<string>>(`/admin/companies/${companyId}/currency`, { value: currencyCode });
}

function updatePreferImperial(companyId: number, preferImperial: boolean): Promise<void> {
  return CommonApi.put<void, ValuePayload<boolean>>(
    `/admin/companies/${companyId}/prefer-imperial`,
    { value: preferImperial },
  );
}

function deleteCompany(companyId: number): Promise<void> {
  return CommonApi.delete(`/admin/companies/${companyId}`);
}

function updateUserRole(
  companyId: number, subscriptionType: SubscriptionType, profileId: string, roleId: number,
): Promise<void> {
  return CommonApi.put<void, ValuePayload<number>>(
    `admin/companies/${companyId}/${subscriptionType}/users/${profileId}/role`,
    { value: roleId });
}

export const AdminCompaniesApi = {
  updateUserRole,
  getCompaniesFeed,
  deleteProjects,
  getDatabasesFeed,
  attachDatabasesToCompany,
  detachDatabaseFromCompany,
  getEmployeesFeed,
  attachEmployeeToCompany,
  detachEmployeeFromCompany,
  getRoles,
  getProjectUsers,
  attachEmployeeToProject,
  detachEmployeeFromProject,
  getSubscriptionPlans,
  setMockSubscription,
  deleteMockSubscription,
  createEmptySubscription,
  getCurrencies,
  updateCompanyCurrency,
  deleteCompany,
  updatePreferImperial,
};
