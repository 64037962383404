import { Action } from 'redux';

import { ActionWith } from '../../../../../common/interfaces/action-with';
import { GroupType } from '../../../utils/gantt-chart';
import { ActivitiesTabMode } from '../enums/activities-tab-mode';
import { ResourcesTabMode } from '../enums/resources-tab-mode';
import { SidePanelTab } from '../enums/side-panel-tab';
import { ResourceIdentifier } from '../interfaces/resource-identifier';
import { ToggleRowExpandStatusPayload } from './payloads';
import * as types from './types';


export const toggleChartLineExpandStatus = (
  type: GroupType,
  rowId: number,
): ActionWith<ToggleRowExpandStatusPayload> => {
  return {
    type: types.TOGGLE_CHART_LINE_EXPAND_STATUS,
    payload: {
      type,
      rowId,
    },
  };
};

export const setSidePanelTab = (tab: SidePanelTab): ActionWith<SidePanelTab> => {
  return {
    type: types.SET_SIDE_PANEL_TAB,
    payload: tab,
  };
};

export const setActivitiesTabMode = (mode: ActivitiesTabMode): ActionWith<ActivitiesTabMode> => {
  return {
    type: types.SET_ACTIVITIES_TAB_MODE,
    payload: mode,
  };
};

export const setSelectedElementsIds = (ids: number[]): ActionWith<number[]> => {
  return {
    type: types.SET_SELECTED_ELEMENTS_IDS,
    payload: ids,
  };
};

export const toggleActivityCardExpandStatus = (activityId: number): ActionWith<number> => {
  return {
    type: types.TOGGLE_ACTIVITY_CARD_EXPAND_STATUS,
    payload: activityId,
  };
};


export const setResourcesTabMode = (mode: ResourcesTabMode): ActionWith<ResourcesTabMode> => {
  return {
    type: types.SET_RESOURCES_TAB_MODE,
    payload: mode,
  };
};


export const selectResourceForTimeline = (resource: ResourceIdentifier): ActionWith<ResourceIdentifier> => {
  return {
    type: types.SELECT_RESOURCE_FOR_TIMELINE,
    payload: resource,
  };
};

export const deselectResourceForTimeline = (): Action => {
  return {
    type: types.DESELECT_RESOURCE_FOR_TIMELINE,
  };
};

export const expandAllActivityCards = (): Action => {
  return {
    type: types.EXPAND_ALL_ACTIVITY_CARDS,
  };
};

export const collapseAllActivityCards = (): Action => {
  return {
    type: types.COLLAPSE_ALL_ACTIVITY_CARDS,
  };
};
