import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import {
  ANNOTATIONS_ADV_DIALOG_NAME,
  AnnotationsAdvDialog,
} from '../../../../../../../units/2d/components/annotations-adv-dialog';
import {
  AUTO_MEASURE_ADV_DIALOG_NAME,
  AutoMeasureAdvDialog,
} from '../../../../../../../units/2d/components/auto-measure-adv-dialog';
import {
  TEMPLATES_ADV_DIALOG_NAME,
  TemplatesAdvDialog,
} from '../../../../../../../units/2d/components/templates-adv-dialog';
import { AddonAdvDialogName } from '../../addon-list';

interface DispatchProps {
  onCloseDialog: () => void;
}

interface OwnProps {
  dialogName: AddonAdvDialogName;
  onConfirm: () => void;
}

interface Props extends DispatchProps, OwnProps {}

class AddonAdvDialogsComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { dialogName, onCloseDialog } = this.props;

    switch (dialogName) {
      case AUTO_MEASURE_ADV_DIALOG_NAME:
        return (
          <AutoMeasureAdvDialog
            onCloseDialog={onCloseDialog}
            onConfirm={this.onConfirm}
          />);
      case ANNOTATIONS_ADV_DIALOG_NAME:
        return (
          <AnnotationsAdvDialog
            onCloseDialog={onCloseDialog}
            onConfirm={this.onConfirm}
          />);
      case TEMPLATES_ADV_DIALOG_NAME:
        return (
          <TemplatesAdvDialog
            onCloseDialog={onCloseDialog}
            onConfirm={this.onConfirm}
          />);
      default:
        return null;
    }
  }

  @autobind
  private onConfirm(): void {
    this.props.onConfirm();
    this.props.onCloseDialog();
  }
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
  return {
    onCloseDialog: () => dispatch(KreoDialogActions.closeDialog(ownProps.dialogName)),
  };
}

export const AddonAdvDialogs = connect(null, mapDispatchToProps)(AddonAdvDialogsComponent);
