import * as paper from 'paper';

import {
  DrawingsCanvasColors,
  DrawingsCanvasConstants,
} from 'common/components/drawings/constants/drawing-canvas-constants';
import {
  DrawingsSimplifiedBoundingRect,
} from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingsGeometryPreviewConfig } from './drawings-geometry-preview-config';

export interface DrawingsGeometryPreviewPolylineConfig extends DrawingsGeometryPreviewConfig {
  geometry: { points: string[] };
}

export class DrawingsGeometryPreviewPolyline {
  protected _config: DrawingsGeometryPreviewPolylineConfig;
  protected _path: paper.Path;

  constructor(config: DrawingsGeometryPreviewPolylineConfig) {
    this._config = config;
    this.render();
  }

  public destroy(): void {
    this._path.remove();
  }

  public set hasError(value: boolean) {
    if (value) {
      this._path.strokeColor = DrawingsCanvasColors.warningStrokeColor;
    } else {
      this._path.strokeColor = this._config.color.stroke;
    }
  }

  public get bounds(): DrawingsSimplifiedBoundingRect {
    return this._path.bounds;
  }

  public set zoom(value: number) {
    this._path.strokeWidth = DrawingsCanvasConstants.previewStrokeWidth / value;
    this._path.dashArray = DrawingsCanvasConstants.previewDashArray.map(x => x * this._path.strokeWidth);
  }

  private render(): void {
    this._path = new paper.Path(this._config.geometry.points.map(this._config.getPoint));
    this._path.strokeColor = this._config.color.stroke;
    this._path.addTo(this._config.group);
  }
}
