import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './imperial-toggle.scss';

import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { State } from 'common/interfaces/state';
import { AccountActions } from '../../../units/account/actions/creators';
import { ToggleableButton } from '../toggleable-button';
import { TooltipContainer } from '../tooltip-container';

interface StateProps {
  isImperial: boolean;
}

interface DispatchProps {
  onChangeImperialMetric: () => void;
}

interface Props extends StateProps, DispatchProps { }

class ImperialToggleComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { isImperial, onChangeImperialMetric } = this.props;
    return (
      <div className='imperial-toggle'>
        <ToggleableButton
          key='toggle-unit'
          className='imperial-toggle__button'
          controlName='imperial-toggle'
          active={true}
          size={30}
          onClick={onChangeImperialMetric}
        >
          <TooltipContainer className='imperial-toggle__button-tooltip'>Metric/Imperial</TooltipContainer>
          {isImperial ? 'Ft' : 'M'}
        </ToggleableButton>
      </div>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    isImperial: state.account.settings.isImperial,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    onChangeImperialMetric: () => {
      dispatch(AccountActions.toggleImperialMetric());
      dispatch(TwoDElementViewActions.handleChangeIsImperial());
    },
  };
};

export const ImperialToggle = connect(mapStateToProps, mapDispatchToProps)(ImperialToggleComponent);
