import { RowNode } from 'ag-grid-community';
import autobind from 'autobind-decorator';
import * as React from 'react';


import { withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { ConstantFunctions } from 'common/constants/functions';
import { SyncStatus } from 'common/enums/sync-status';
import { QtoCommonElementTable } from 'unit-projects/components/quantity-take-off-common-table';
import { QuantityTakeOffControls } from 'unit-projects/components/quantity-take-off-controls';
import { ColumnOrderHelper } from 'unit-projects/components/quantity-take-off-left-panel/column-order-helper';
import { QtoLeftPanelConstants } from 'unit-projects/components/quantity-take-off-left-panel/constants';
import {
  getElementContextItems,
} from 'unit-projects/components/quantity-take-off-left-panel/context-menu/get-element-context-items';
import {
  extendUserExtractorsConfig,
} from 'unit-projects/components/quantity-take-off-left-panel/extend-user-extractors-config';
import {
  getTablesConfig,
} from 'unit-projects/components/quantity-take-off-left-panel/get-config';
import { initialState } from 'unit-projects/components/quantity-take-off-left-panel/initial-state';
import {
  QtoLeftPanelProps,
  QtoLeftPanelState,
  TableApi,
  TableState,
} from 'unit-projects/components/quantity-take-off-left-panel/interfaces';
import {
  syncColDefWithColState,
} from 'unit-projects/components/quantity-take-off-left-panel/sync-col-def-with-col-state';
import {
  QtoElementTabMapper,
} from 'unit-projects/components/quantity-take-off-left-table-tab/quantity-take-off-element-tab-mapper';
import { UseSaveState } from 'unit-projects/components/quantity-take-off-left-table-tab/use-save-state';
import {
  ReportTableExporter,
} from 'unit-projects/components/quantity-take-off-report-table/report-table-data-transfer';
import { withPropertiesDataProviderApi } from 'unit-projects/components/with-properties-data-provider-api';
import { QtoSelectElementsEventSource } from 'unit-projects/enums/qto-select-elements-event-source';
import { ExtractorConfig, UserExtractors } from 'unit-projects/interfaces/graph-storage-records-config';
import { ModelBrowserPropertyGroupView } from 'unit-projects/interfaces/properties-provider-types';
import { BimRecordDataApi } from '../../../../api/bim-record-data/api';


export class CostEstimateLeftPanelComponent extends React.PureComponent<QtoLeftPanelProps, QtoLeftPanelState> {
  private columnOrderHelper: ColumnOrderHelper;
  private tablesApi: {
    locationApi: TableApi,
    elementApi: TableApi,
  } = { locationApi: null, elementApi: null };
  private prevSelectedElement: number[] = [];
  private revitIds: Record<string, string> = {};
  private elementDataExporter: ReportTableExporter;
  private useElementsSaveState: UseSaveState;
  private isElementsTabOpen: boolean = true;
  private cachedSelectedData: { selectedBimElementsIds: number[], isExpand?: boolean } = null;

  public constructor(props: QtoLeftPanelProps) {
    super(props);
    this.state = initialState(props);
    this.useElementsSaveState = new UseSaveState(this.props.currentProjectId, props.modelType, 'Elements');
    this.props.sendElementTableApi({
      setSelected: this.updateSelection,
      getSelectedValue: this.getSelectedValue,
      focusAndExpand: this.focusAndExpand,
      getRowNodes: this.getRowNodes,
      getColumn: id => this.tablesApi.elementApi.getColumn(id),
    });
  }

  public static getDerivedStateFromProps(
    nextProps: QtoLeftPanelProps,
    prevState: QtoLeftPanelState,
  ): Partial<QtoLeftPanelState> {
    const isUnitSystemUpdated = nextProps.isImperialUnit !== prevState.elementContext.isImperialUnit;
    if (isUnitSystemUpdated) {
      return {
        elementContext: { ...prevState.elementContext, isImperialUnit: nextProps.isImperialUnit },
      };
    }
    return null;
  }

  public render(): JSX.Element {
    const userExtractorsConfigs = this.props.recordsConfig[QtoLeftPanelConstants.USER_EXTRACTORS_KEY] as UserExtractors;
    const extractorsConfig = this.props.recordsConfig[QtoLeftPanelConstants.EXTRACTORS_KEY] as ExtractorConfig;
    extendUserExtractorsConfig(userExtractorsConfigs, extractorsConfig);
    const getContextItems = getElementContextItems(
      userExtractorsConfigs,
      this.props.currentProjectId,
      this.props.pivotEnabled,
      this.getCommonProperties,
      ConstantFunctions.doNothing,
      ConstantFunctions.doNothing,
      this.props.syncWithElementsDataInReport,
    );
    return this.state.isLoading
      ? <SvgSpinner size='middle' />
      : (
        <>
          {
            this.state.isUpdating && (
              <div className='quantity-take-off-left-panel__blanket'>
                <SvgSpinner size='middle' />
              </div>
            )
          }
            <QuantityTakeOffControls
              disableEngineFilter={this.props.disableEngineFilter}
              onFilter={this.onElementFilter}
              currentProjectId={this.props.currentProjectId}
              displayNames={this.props.recordsConfig}
              isLocation={false}
              expandAllNodes={ConstantFunctions.doNothing}
              collapseAllNodes={ConstantFunctions.doNothing}
              filterName={`${this.props.currentProjectId}_filter_Elements`}
              records={this.state.elementRecords}
              saveState={this.useElementsSaveState.saveFilterPanelState}
              getSaveState={this.useElementsSaveState.getFilterPanelState}
              getReportElementIds={this.props.getReportElementIds}
              isEngineFilterEnabled={this.state.useEngineFilter}
              engineFilter={this.engineFilter}
              mapIdHelper={this.props.mapIdHelper}
            />
            <QtoCommonElementTable
              id='qto-elements-breakdown-table'
              dataExporters={[this.elementDataExporter]}
              expandTable={this.state.elementExpandRowsStatus}
              userExpand={this.onUserExpandElements}
              selectionEventSource={this.props.selectElementsEventSource}
              columnDefs={this.state.elementColumnConfig}
              rowData={this.state.showElementData}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              context={this.state.elementContext}
              onElementTableSelected={this.onElementTableSelected}
              sendElementTableApi={this.saveElementTable}
              name={`${this.props.currentProjectId}_Elements`}
              saveState={this.useElementsSaveState.saveTableState}
              getSaveState={this.useElementsSaveState.getTableState}
              getContextItems={getContextItems}
              className={this.state.className}
              onColumnRowGroupChanged={this.onColumnRowGroupChanged}
              onFirstDataRendered={this.onFirstDataRenderedElementTable}
              isPageDataReady={this.props.isPageDataReady}
            />
        </>
      );
  }

  public async componentDidMount(): Promise<void> {
    const { currentProjectId, recordsConfig } = this.props;
    const [revitIds] = await Promise.all([
      BimRecordDataApi.getRevitId(currentProjectId),
      this.useElementsSaveState.loadState(),
    ]);
    this.revitIds = revitIds;
    const elementMapper = new QtoElementTabMapper(recordsConfig).elementTableMapper;
    this.elementDataExporter = new ReportTableExporter(elementMapper);
    this.columnOrderHelper = new ColumnOrderHelper(recordsConfig);
    const state = await this.getTablesState();
    this.setTablesConfig(state);
    const elementsFilterState = this.useElementsSaveState.getFilterPanelState();
    if (elementsFilterState) {
      this.onElementFilter(
        Object.keys(this.props.visibleElementIds).length
          ? elementsFilterState.selectedIds.filter(this.engineFilter)
          : elementsFilterState.selectedIds,
        elementsFilterState.selectedIds,
      );
    } else {
      this.onElementFilter(
        Object.keys(this.props.visibleElementIds).length
          ? this.state.elementFilteredIds.filter(this.engineFilter)
          : this.state.elementFilteredIds,
        this.state.elementFilteredIds,
      );
    }
    this.setState({ isLoading: false });
    this.props.onReady();
  }

  public async componentDidUpdate(prevProps: QtoLeftPanelProps): Promise<void> {
    const { elementRecords, visibleClipBoxElements, visibleElementIds, isImperialUnit } = this.props;
    if (elementRecords !== prevProps.elementRecords) {
      await this.updateTableAfterUpdatedRecords();
    }

    const visibileClipBoxElementsChanged = visibleClipBoxElements !== prevProps.visibleClipBoxElements;
    if (visibleElementIds !== prevProps.visibleElementIds || visibileClipBoxElementsChanged) {
      const useEngineFilter = (visibleClipBoxElements && !!visibleClipBoxElements.length)
        || Object.entries(visibleElementIds).some(([, value]) => !value);
      if (useEngineFilter !== this.state.useEngineFilter) {
        this.setState({ useEngineFilter });
      } else if (useEngineFilter) {
        this.onElementFilter(this.state.elementFilteredIds.filter(this.engineFilter), this.state.elementFilteredIds);
      }
    }

    if (isImperialUnit !== prevProps.isImperialUnit) {
      this.tablesApi.elementApi.refreshCells();
    }
  }


  @autobind
  private onFirstDataRenderedElementTable(): void {
    if (this.cachedSelectedData) {
      this.props.changeSyncStatus(QtoSelectElementsEventSource.LeftPanel, SyncStatus.Syncing);
      this.updateSelection(this.cachedSelectedData.selectedBimElementsIds, this.cachedSelectedData.isExpand);
      this.cachedSelectedData = null;
    }
  }

  @autobind
  private onColumnRowGroupChanged(): void {
    this.tablesApi.elementApi.setSelected(this.prevSelectedElement);
  }


  @autobind
  private engineFilter(_id: number): boolean {
    return true; // this.props.visibleElementIds[id] && this.clipboxVisibleFilter(id);
  }

  @autobind
  private onUserExpandElements(): void {
    this.setState({ elementExpandRowsStatus: undefined });
  }

  @autobind
  private getCommonProperties(ids: number[]): ModelBrowserPropertyGroupView[] {
    const engineIds = this.props.mapIdHelper.mapBimIdsToEngineIds(ids);
    const result = this.props.getElementPropertiesName(engineIds);
    return result;
  }

  @autobind
  private async updateTableAfterUpdatedRecords(): Promise<void> {
    const elementMapper =
      new QtoElementTabMapper(this.props.recordsConfig).elementTableMapper;
    this.elementDataExporter = new ReportTableExporter(elementMapper);
    this.columnOrderHelper = new ColumnOrderHelper(this.props.recordsConfig);
    const { leftPanelTableConfigs, showElementData } = await this.getTablesState();
    const element = leftPanelTableConfigs.tableConfigs.element;
    const filterConfigs = leftPanelTableConfigs.filterConfigs;
    const saveState = this.useElementsSaveState.getTableState();
    const columnSaveState = saveState
      ? saveState.columns
      : this.tablesApi.elementApi.getColumnState();
    const prevColumnDef = this.state.elementColumnConfig;
    const colDefs = columnSaveState
      ? syncColDefWithColState(element.columnDefs, columnSaveState, prevColumnDef)
      : element.columnDefs;
    this.setState(
      {
        elementRowData: element.rowData,
        elementContext: element.context,
        elementColumnConfig: colDefs,
        showElementData,
        elementRecords: filterConfigs.element,
        isUpdating: false,
      },
      () => {
        this.tablesApi.elementApi.setSelectedAfterUpdateRecords(this.prevSelectedElement);
      });
  }

  @autobind
  private getSelectedValue(): number[] {
    return this.prevSelectedElement;
  }

  private async getTablesState(): Promise<TableState> {
    const { tableConfigs, filterConfigs } = await getTablesConfig(
      this.props.elementRecords,
      this.props.recordsConfig,
      this.revitIds,
      this.columnOrderHelper,
    );
    const { ids: elementIds, showData: showElementData } = this.processRowData(tableConfigs.element.rowData, false);
    return {
      leftPanelTableConfigs: { tableConfigs, filterConfigs },
      elementIds,
      locationIds: [],
      showElementData,
      showLocationData: [],
    };
  }

  private processRowData(rowData: Array<Record<string, string | number>>, isLocation: boolean): {
    ids: number[],
    showData: Array<Record<string, string | number>>,
  } {
    const ids = [];
    const showData = [];
    for (const data of rowData) {
      const intId = Number(data.id);
      ids.push(intId);
      if (this.filterRecordsIds(isLocation, intId)) {
        showData.push(data);
      }
    }
    return { ids, showData };
  }


  private setTablesConfig(
    tableState: TableState,
  ): void {
    const {
      leftPanelTableConfigs,
      showElementData,
      elementIds,
    } = tableState;
    const { tableConfigs, filterConfigs } = leftPanelTableConfigs;
    this.setState((state) =>
      ({
        locationColumnConfig: [],
        elementColumnConfig: tableConfigs.element.columnDefs,
        locationRowData: [],
        elementRowData: tableConfigs.element.rowData,
        elementContext: tableConfigs.element.context,
        locationContext: null,
        elementFilteredIds: state.elementFilteredIds.length ? state.elementFilteredIds : elementIds,
        locationFilteredIds: [],
        showElementData,
        showLocationData: [],
        elementRecords: filterConfigs.element,
        locationRecords: filterConfigs.location,
      }),
    );
  }

  @autobind
  private onElementTableSelected(ids: Array<string | number>): void {
    this.prevSelectedElement = ids.map(Number);
    this.props.onElementTableSelected(this.prevSelectedElement);
  }

  private isEmpty(selectedBimElementsIds: number[]): boolean {
    const isEmpty = selectedBimElementsIds.length === 0
      && this.prevSelectedElement.length === 0;

    return isEmpty;
  }

  @autobind
  private updateSelection(selectedBimElementsIds: number[], isExpand?: boolean): void {
    if (this.isEmpty(selectedBimElementsIds)) {
      this.props.onElementTableSelected(selectedBimElementsIds);
    }

    if (this.state.isLoading) {
      this.cachedSelectedData = { selectedBimElementsIds, isExpand };
      this.props.changeSyncStatus(QtoSelectElementsEventSource.LeftPanel, SyncStatus.InSync);
      return;
    }

    const elementIds = this.state.showElementData.map(rowData => Number(rowData.id));
    const isElementActive = this.isChange(selectedBimElementsIds, this.prevSelectedElement, elementIds);
    if (isElementActive) {
      this.tablesApi.elementApi.setSelected(selectedBimElementsIds, isExpand);
    }

    if (!isElementActive) {
      this.props.changeSyncStatus(QtoSelectElementsEventSource.LeftPanel, SyncStatus.InSync);
    }
  }


  @autobind
  private getRowNodes(ids: Array<string | number>): RowNode[] {
    return this.tablesApi.elementApi.getRowNodes(ids);
  }

  @autobind
  private focusAndExpand(ids: number[]): void {
    this.tablesApi.elementApi.focusAndExpand(ids);
  }

  @autobind
  private isResetAllSelected(selectedIds: number[], prevSelectedId: number[]): boolean {
    return prevSelectedId.length > 0 && selectedIds.length === 0;
  }

  @autobind
  private isUpdate(selectedIds: number[], prevSelectedId: number[], allIds: number[]): boolean {
    return (
      (prevSelectedId.length > 0 && selectedIds.length > 0)
      && (
        prevSelectedId.some(prevId => !selectedIds.includes(prevId))
        || selectedIds.some(selectId => allIds.includes(selectId) && !prevSelectedId.includes(selectId))
      )
    );
  }

  @autobind
  private isSetSelected(selectedIds: number[], prevSelectedId: number[], allIds: number[]): boolean {
    return prevSelectedId.length === 0
      && selectedIds.length > 0
      && selectedIds.some(selectedId => allIds.includes(selectedId));
  }

  @autobind
  private isChange(selectedIds: number[], prevSelectedId: number[], allIds: number[]): boolean {
    return this.isSetSelected(selectedIds, prevSelectedId, allIds)
      || this.isResetAllSelected(selectedIds, prevSelectedId)
      || this.isUpdate(selectedIds, prevSelectedId, allIds);
  }

  @autobind
  private onElementFilter(bimIds: number[], bimIdsWithoutEngineFilter: number[]): void {
    if (bimIdsWithoutEngineFilter) {
      const idsSet = new Set(bimIds);
      const showRowData = this.state.elementRowData.filter(d => idsSet.has(Number(d.id)));
      this.setState(
        {
          elementFilteredIds: bimIdsWithoutEngineFilter,
          showElementData: showRowData,
          elementExpandRowsStatus: undefined,
        },
        this.handleFilter(idsSet),
      );
    } else {
      this.setState(
        {
          showElementData: this.state.elementRowData,
          locationFilteredIds: [],
          elementExpandRowsStatus: undefined,
        },
        this.handleFilter(),
      );
    }
  }

  private handleFilter(idsSet?: Set<number>): () => void {
    return () => {
      const filteredIds = this.getResultFilterSet();
      this.props.onFilter(filteredIds);
      if (this.tablesApi.elementApi && idsSet) {
        const selectedIds = this.prevSelectedElement.filter(id => idsSet.has(id));
        this.tablesApi.elementApi.setSelectedAfterUpdateRecords(selectedIds);
      }
    };
  }

  @autobind
  private saveElementTable(ref: TableApi): void {
    this.tablesApi.elementApi = ref;
  }

  @autobind
  private getResultFilterSet(): number[] {
    return this.isElementsTabOpen ? this.state.elementFilteredIds : [];
  }

  private filterRecordsIds(_: boolean, id: number): boolean {
    const filterBimIds = this.state.elementFilteredIds;
    return (!filterBimIds.length || filterBimIds.includes(id)) && this.props.visibleElementIds[id];
  }
}

export const CostEstimateLeftPanel = withAbilityContext(withPropertiesDataProviderApi(CostEstimateLeftPanelComponent));
