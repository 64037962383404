import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  onClick: () => void;
  isActive: boolean;
  title: string;
  isDisabled: boolean;
}

export class TabLink extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { onClick, isActive, title, isDisabled } = this.props;

    return (
      <Styled.Container
        onClick={onClick}
        isActive={isActive}
        isDisabled={isDisabled}
      >
        <Text>{title}</Text>
      </Styled.Container>
    );
  }
}
