import { AppRoute as R } from 'common/routing/app-route';
import { ParametrizedAppRoute as PR } from 'common/routing/parametrized-app-route';
import { DatabaseRouteParams as DbRP } from './route-params';

export interface DatabaseUrls<T extends DbRP> {
  listing: R;
  index: PR<T>;
  activities: PR<T>;
  resources: PR<T>;
}

export const UrlsFabric = <T extends DbRP>(indexPath: string): DatabaseUrls<T> => {

  /* eslint-disable max-len, space-in-parens */
  const databaseListing =                new R(                     `${indexPath}/databases`);
  const database =                       new PR<DbRP>(              `${indexPath}/database/:dbId`);
  const activities =                     new PR<DbRP>(              `${database.path}/activities`);
  const resources =                      new PR<DbRP>(              `${database.path}/resources`);
  /* eslint-enable */
  return {
    listing: databaseListing,
    index: database,
    activities,
    resources,
  };
};
