import { SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';

import { DatabaseActivityListingActions } from '../actions/creators/database-activity-listing';
import { DatabaseActivityActionTypes } from '../actions/types/database-activity';
import { DatabaseActivityVariantActionTypes } from '../actions/types/database-activity-variant';


function* reloadActivityListing(): SagaIterator {
  try {
    yield put(DatabaseActivityListingActions.resetRequestStatuses());
  } catch (error) {
    console.error('database activity listing: reload activity listing failed', error);
  }
}


export function* databaseActivityListingSagas(): SagaIterator {
  yield takeLatest(DatabaseActivityActionTypes.CREATE_ACTIVITY_SUCCEEDED, reloadActivityListing);
  yield takeLatest(DatabaseActivityActionTypes.UPDATE_ACTIVITY_SUCCEEDED, reloadActivityListing);
  yield takeLatest(DatabaseActivityActionTypes.DELETE_ACTIVITY_SUCCEEDED, reloadActivityListing);
  yield takeLatest(DatabaseActivityVariantActionTypes.CREATE_ACTIVITY_VARIANT_SUCCEEDED, reloadActivityListing);
  yield takeLatest(DatabaseActivityVariantActionTypes.UPDATE_ACTIVITY_VARIANT_SUCCEEDED, reloadActivityListing);
  yield takeLatest(DatabaseActivityVariantActionTypes.DELETE_ACTIVITY_VARIANT_SUCCEEDED, reloadActivityListing);
}
