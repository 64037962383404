import styled from 'styled-components';

import { PageHeaderAvatarStyled } from 'common/components/page-header-avatar';
import { NotificationBellStyled } from 'unit-2d-notification/components';


const HEADER_HEIGHT = 60;

const Container = styled.div<{ showSearch: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  grid-column-gap: 20px;
  height: ${HEADER_HEIGHT}px;
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.color.background};
  flex-shrink: 0;
  justify-content: ${p => p.showSearch ? 'space-between' : 'flex-end'} ;
`;

const HeaderSearch = styled.div<{ searchQuery: string }>`
    max-width: 50%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

  > label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    border: none;
    margin-bottom: 0;
    border-radius: 20px;

    div {
      top: 10px;
      left: 10px;

      > svg {
        fill: ${p => p.theme.color.gray};
      }
    }

    > input {
      border-radius: 20px;
      padding-left: 40px;
      padding-top: 10px;
      padding-bottom: 10px;

      :focus {
        padding-left: 40px;
        & ~ span {
          :first-of-type {
            top: 1px;
          }
        }
      }

      &::placeholder {
        opacity: 1;
      }
    }

    > span {
      :first-of-type {
        top: ${props => props.searchQuery ? '1px' : '7px'};
        left: 40px;
      }
    }

    :not(:focus-within):after {
      border: none !important;
    }

    :not(:focus-within):hover {
      border: none;
    }
  }
`;

const HeaderButtons = styled.div<{ showNotification: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  grid-column-gap: 20px;

  ${NotificationBellStyled.IconContainer} {
    display: ${p => p.showNotification ? 'flex' : 'none'};
    margin-left: 0;
    margin-right: -25px;
  }

  ${PageHeaderAvatarStyled.Container} {
    margin: 0;
  }
`;

const InviteButton = styled.div`
  div {
    > button {
      padding: 0 20px;
      width: auto;
    }
  }
`;

export const Styled = {
  Container,
  HeaderSearch,
  HeaderButtons,
  InviteButton,
};
