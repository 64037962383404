import {
  ContextObserver,
  ContextObserverWithPrevious,
} from 'common/components/drawings/drawings-contexts';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsGeometryType, DrawingsRenderParams } from 'common/components/drawings/interfaces';

import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { DrawingsCursorTypeHelper, DrawingsGeometryDragEventHelper } from '../..';
import { EngineObject, EngineObjectConfig } from '../../common';
import { DrawingsMouseEventHandler } from '../../drawings-geometry-entities';
import { DrawingsGeometryEntityConfig } from '../../drawings-geometry-entities/drawings-geometry-entity-config';
import { ModifiableInstanceType } from '../drawings-geometry-modify';
import { GeometryFactory } from './creators/geometry-factory';

interface FactoryInfo {
  typeguard: (instanceType: DrawingsInstanceType, geometry: DrawingsGeometryType) => boolean;
  factory: GeometryFactory;
}


interface CommonGeometryConfig {
  onSelect: DrawingsMouseEventHandler;
  renderParamsContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
  cursorHelper: DrawingsCursorTypeHelper;
  onDoubleClick: DrawingsMouseEventHandler;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
  getPointInfo: (id: string) => paper.Point;
  onMouseEnter: DrawingsMouseEventHandler;
  onMouseLeave: DrawingsMouseEventHandler;
}

interface GeometryInstancesFactoryConfig extends EngineObjectConfig {
  factories: FactoryInfo[];
  commonGeometryConfig: CommonGeometryConfig;
}

export class GeometryInstancesFactory extends EngineObject<GeometryInstancesFactoryConfig> {
  public render(
    instanceId: string,
    geometry: DrawingsGeometryType,
    instanceType: DrawingsInstanceType,
    color: DrawingsPaperColorInfo,
    overrodeProps: Partial<DrawingsGeometryEntityConfig>,
  ): ModifiableInstanceType {
    const factory = this._config.factories.find(x => x.typeguard(instanceType, geometry))?.factory;
    if (!factory) {
      return null;
    }
    return factory.render(instanceId, geometry, color, { ...this._config.commonGeometryConfig, ...overrodeProps });
  }
}
