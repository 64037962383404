import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ItemMeasure, MeasureValue } from '../../drawings-annotation-legend-item';
import { Styled } from './styled';

interface StateProps {
  isImperial: boolean;
}

interface OwnProps {
  measureValues: MeasureValue[];
  shouldHideIconsInfo: boolean;
  shouldHideInfo: boolean;
}

interface Props extends StateProps, OwnProps {
}

const GroupValuesComponent: React.FC<Props> = ({
  measureValues,
  shouldHideIconsInfo,
  shouldHideInfo,
  isImperial,
}) => {
  if (!measureValues?.length) {
    return null;
  }

  if (measureValues.length === 1) {
    return (
      <ItemMeasure
        measureValue={measureValues[0]}
        showTooltip={true}
        hideIconsInfo={shouldHideIconsInfo}
        hideInfo={shouldHideInfo}
        isImperial={isImperial}
      />
    );
  }

  if (measureValues.length === 2 && !shouldHideInfo) {
    return (
      <>
        {
          measureValues.map((measureValue, index) => (
            <ItemMeasure
              key={index}
              measureValue={measureValue}
              showTooltip={true}
              hideIconsInfo={shouldHideIconsInfo}
              hideInfo={shouldHideInfo}
              isImperial={isImperial}
            />
          ))
        }
      </>
    );
  } else if (!shouldHideIconsInfo) {
    return (
      <Styled.MultiValuesContainer>
        <Icons.Multivalues/>
      </Styled.MultiValuesContainer>
    );
  } else {
    return null;
  }
};


function mapStateToProps(state: State): StateProps {
  return {
    isImperial: state.account.settings.isImperial,
  };
}

export const GroupValues = connect(mapStateToProps)(GroupValuesComponent);
