import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { ResourceType } from '../../../../../utils/gantt-chart';
import { ResourceIdentifier } from '../../../interfaces/resource-identifier';
import * as payloads from './payloads';
import * as types from './types';

export const openPopup = (
  chartId: number,
  clientX: number,
  clientY: number,
): ActionWith<payloads.OpenPopupPayload> => {
  return {
    type: types.OPEN_POPUP,
    payload: {
      chartId,
      clientX,
      clientY,
    },
  };
};

export const closePopup = (): Action => {
  return {
    type: types.CLOSE_POPUP,
  };
};

export const selectResource = (
  chartId: number,
  resource: ResourceIdentifier,
): ActionWith<payloads.ResourceActionPayload> => {
  return {
    type: types.SELECT_RESOURCE,
    payload: {
      chartId,
      resource,
    },
  };
};

export const setTab = (tab: ResourceType): ActionWith<ResourceType> => {
  return {
    type: types.SET_TAB,
    payload: tab,
  };
};

export const previewResource = (
  chartId: number,
  resource: ResourceIdentifier,
): ActionWith<payloads.ResourceActionPayload> => {
  return {
    type: types.PREVIEW_RESOURCE,
    payload: {
      chartId,
      resource,
    },
  };
};

export const resetResourcePreview = (): Action => {
  return {
    type: types.RESET_RESOURCE_PREVIEW,
  };
};
