import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-header-tabs-container.scss';

import { TooltipContainer } from 'common/components/tooltip-container';
import { State } from 'common/interfaces/state';
import { AlertSuccess, IconButton, KreoIconSearchLarge } from 'common/UIKit';
import { ClassificationActions } from '../../actions/creators/classification';
import { ClassificationHeaderTabType } from '../../interfaces/classification/classification-header-tab-type';
import { ClassificationHeaderTab } from '../classification-header-tab';

interface CounterProps {
  count: number;
}

const ErrorCounter: React.FC<CounterProps> = ({ count }) => {
  const countLength = count.toString().length;
  const className = classNames('classification-header-tabs-container__error-counter', {
    'classification-header-tabs-container__error-counter--no-errors': !count,
    'classification-header-tabs-container__error-counter--middle': countLength === 3,
    'classification-header-tabs-container__error-counter--small': countLength === 4,
  });
  return (
    <div className={className}>
      {count ? count : <AlertSuccess/>}
      <TooltipContainer className='classification-header-tabs-container__tooltip'>
        {count ? 'Unclassified Element Types' : 'All elements are classified'}
      </TooltipContainer>
    </div>
  );
};

interface DispatchProps {
  enableSearch: () => void;
}


interface StateProps {
  isSearchModeEnabled: boolean;
  tabs: ClassificationHeaderTabType[];
  errorsCount: number;
}


interface Props extends StateProps, DispatchProps {
}

const ClassificationHeaderTabsContainerComponent: React.FC<Props> = (props) => {
  return (
    <div className='classification-header-tabs-container'>
      {
        props.tabs.map(({ id, name }) => (
          <ClassificationHeaderTab
            key={id}
            id={id}
            name={name}
          />
        ))
      }
      { props.tabs.length ? (
        <div className='classification-header-tabs-container__controls'>
          <IconButton
            size='large'
            onClick={props.enableSearch}
            className={classNames('classification-header-tabs-container__search', {
              'classification-header-tabs-container__search--search-mode': props.isSearchModeEnabled,
            })}
          >
            <KreoIconSearchLarge/>
          </IconButton>
          <ErrorCounter count={props.errorsCount}/>
        </div>
      ) : null}
    </div>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    isSearchModeEnabled: state.classification.isSearchModeEnabled,
    tabs: state.classification.tabs,
    errorsCount: state.classification.errorCategoriesCount,
  };
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    enableSearch: () => dispatch(ClassificationActions.toggleSearchMode()),
  };
}

const connecter = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationHeaderTabsContainer = connecter(ClassificationHeaderTabsContainerComponent);
