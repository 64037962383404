import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Styled = {
  Container,
};
