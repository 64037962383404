import { Constants } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Spinner } from 'common/components/spinner';
import { Text } from 'common/components/text';
import { FoundedResults } from './founded-results';
import { Styled } from './styled';

interface Props {
  count: number;
  isLoading: boolean;
  isError: boolean;
}


export const ResultsStatusComponent: React.FC<Props> = ({ count, isLoading, isError }) => {
  if (isError) {
    return (
      <Styled.Container>
        <Text customColor={Constants.Colors.GENERAL_COLORS.red} fontSize={12}>
          'Something went wrong. Please check your query and try again.'
        </Text>
      </Styled.Container>
    );
  } else if (isLoading) {
    return (
      <Styled.Container>
        <Spinner show={true} />
      </Styled.Container>
    );
  } else if (count === 0) {
    return (
      <Styled.Container>
        <Text color='gray' fontSize={12}>
          'No result found'
        </Text>
      </Styled.Container>
    );
  } else {
    return (
      <FoundedResults count={count} />
    );
  }
};


export const ResultsStatus = React.memo(ResultsStatusComponent);
