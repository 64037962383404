
export interface EngineObjectConfig {
  id?: string;
}

export class EngineObject<T extends EngineObjectConfig = EngineObjectConfig> {
  protected _config: T;

  constructor(config: T) {
    this._config = config;
  }

  public get id(): string {
    return this._config.id;
  }
}
