const prefix = '@activity-grouping';

export const ActivityGroupingActionTypes = {
  CREATE_CATEGORY: `${prefix}/CREATE_CATEGORY`,
  RENAME_CATEGORY: `${prefix}/RENAME_CATEGORY`,
  CREATE_ACTIVITY_GROUP: `${prefix}/CREATE_ACTIVITY_GROUP`,
  RENAME_ACTIVITY_GROUP: `${prefix}/RENAME_ACTIVITY_GROUP`,
  MOVE_ACTIVITY: `${prefix}/MOVE_ACTIVITY`,
  REMOVE_CATEGORY: `${prefix}/REMOVE_CATEGORY`,
  REMOVE_ACTIVITY_GROUP: `${prefix}/REMOVE_ACTIVITY_GROUP`,
  REPLACE_ACTIVITY_GROUP: `${prefix}/REPLACE_ACTIVITY_GROUP`,
  REPLACE_ACTIVITY: `${prefix}/REPLACE_ACTIVITY`,
  REPLACE_SELECTED_ACTIVITIES: `${prefix}/REPLACE_SELECTED_ACTIVITIES`,
  SET_DRAGGING_ACTIVITY: `${prefix}/SET_DRAGGING_ACTIVITY`,
  SET_DRAGGING_ACTIVITY_GROUP: `${prefix}/SET_DRAGGING_ACTIVITY_GROUP`,
  SET_SELECTED_ACTIVITY_IDS: `${prefix}/SET_SELECTED_ACTIVITY_IDS`,
  MERGE_ACTIVITY_GROUPS: `${prefix}/MERGE_ACTIVITY_GROUPS`,
};
