import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';

import { HoverMenuInteractive } from 'common/components/hover-menu-interactive/hover-menu-interactive';
import { Styled } from './styled';
import { TargetFolder } from './target-folder';
import { TargetPath } from './target-path/target-path';

export interface GroupFolders {
  id: string;
  name: string;
  parentId?: string;
}

export interface FolderContainerProps {
  groups: GroupFolders[];
  id: string;
  withoutArrowContainer?: boolean;
  showHoverMenuLeft?: boolean;
  delayHoverMenu?: number;
  onFolderClick?: (folderId: string) => void;
  onRemoveSelection?: () => void;
}

export function FolderContainer({
  onRemoveSelection,
  id,
  onFolderClick,
  groups,
  withoutArrowContainer,
  showHoverMenuLeft,
  delayHoverMenu,
}: FolderContainerProps): JSX.Element {


  const renderTargetPath = useCallback(() => {
    return (
      <TargetPath
        targetId={id}
        groups={groups}
        onClickFolder={onFolderClick}
      />
    );
  }, [groups, id]);

  const group = useMemo(() => id ? groups.find(g => g.id === id) : null, [ groups, id ]);

  if (!id) {
    return null;
  }

  return (
    <>
      {!withoutArrowContainer &&
        <Styled.Container showPath={!!group?.parentId}>
          <Icons.SmallArrow />
        </Styled.Container>
      }
      {
        group?.parentId ? (
          <HoverMenuInteractive
            renderMenuContainer={renderTargetPath}
            showHoverMenuLeft={showHoverMenuLeft}
            delayHoverMenu={delayHoverMenu}
          >
            <Styled.Icon>
              <Icons.Menu />
            </Styled.Icon>
          </HoverMenuInteractive>
        ) : null
      }
      <TargetFolder
        folderId={id}
        title={group?.name}
        onRemoveSelection={onRemoveSelection}
        onClick={onFolderClick}
      />
    </>
  );
}
