import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { GanttChartActions } from '../../../actions/creators/gantt-chart';
import { BaseWorkPackageName } from '../../../components/gantt-chart';
import { LineData } from '../../../interfaces/gantt-chart';
import { isChartLineExpandable } from '../utils/item-expandable';


interface OwnProps {
  className?: string;
  nestingLevel: number;
  data: LineData;
}

interface ChartLineDispatchProps {
  toggleExpandStatus: () => void;
}

interface Props extends OwnProps, ChartLineDispatchProps {

}

class EmptyChartLineComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      nestingLevel,
      className,
      data,
    } = this.props;

    const lineClassName = classNames(
      'chart-line',
      'gantt-chart-line',
      className,
      `nesting-level-${nestingLevel}`,
    );
    return (
      <div
        className={lineClassName}
        onClick={this.onClick}
      >
        <BaseWorkPackageName
          data={data}
          hideTime={data.isUndefined}
          isShowExpandButton={isChartLineExpandable(data)}
        />
      </div>
    );
  }

  @autobind
  private onClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    if (isChartLineExpandable(this.props.data)) {
      this.props.toggleExpandStatus();
    }
  }
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): ChartLineDispatchProps {
  return {
    toggleExpandStatus: () => {
      const { type, entityId, parentLinesIds } = ownProps.data;
      dispatch(GanttChartActions.expandItem(type, entityId, parentLinesIds));
    },
  };
}

export const EmptyChartLine = connect(null, mapDispatchToProps)(EmptyChartLineComponent);
