import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import { END, eventChannel, EventChannel, SagaIterator } from 'redux-saga';
import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
import { SubscriptionType } from 'common/constants/subscription';
import { SignalRConnectionClient } from 'common/realtime/signalr';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { CompanyNotificationViewMode } from 'unit-2d-notification/api';
import { CompanyResourcesApi } from '../../../api/server';
import { mapCompanyNotification } from './helpers';
import { TwoDNotificationsActions } from './store-slice';

enum EventsType {
  AddNotification = 'AddNotification',
  Access = 'Access',
  Disconnect = 'Disconnect',
}

function* addNotification(model: CompanyNotificationViewMode[]): SagaIterator {
  try {
    const notifications = mapCompanyNotification(model);
    yield put(TwoDNotificationsActions.addNotification(notifications));
  } catch (e) {
    console.error('on add notification', model);
  }
}

function* access(data: string): SagaIterator {
  try {
    ConstantFunctions.doNothing();
  } catch (e) {
    console.error('on access', data);
  }
}

function* disconnect([subsctiptionType]: [SubscriptionType]): SagaIterator {
  try {
    if (yield selectWrapper(x => x.account.selectedSubscriptionType === subsctiptionType)) {
      window.open('/', '_self');
    }
  } catch (e) {
    console.error('disconnect error', e, subsctiptionType);
  }
}


const EventsHandlers: Record<EventsType, (...args: any[]) => SagaIterator> = {
  [EventsType.AddNotification]: addNotification,
  [EventsType.Access]: access,
  [EventsType.Disconnect]: disconnect,
};

interface WatchProps  {
  channel: EventChannel<any>;
  eventType: string;
}

function* watch({ channel, eventType }: WatchProps): SagaIterator {
  while (true) {
    const data = yield take(channel);
    yield fork(EventsHandlers[eventType], data);
  }
}

function* restartWatch(channel: EventChannel<any>): SagaIterator {
  yield take(channel);
  yield put(TwoDNotificationsActions.realtimeDisconnected());
  yield put(TwoDNotificationsActions.connectRealtime());
}

function createEventsChannel(subscribe: (emitter: (input: any | END) => void) => void): EventChannel<any> {
  return eventChannel((emitter: (input: any) => void) => {
    subscribe(emitter);
    return () => null;
  });
}

function* initCommentsRealtime(): SagaIterator {
  try {
    if (yield selectWrapper(x => x.twoDNotifications.realtimeConnection)) {
      yield put(TwoDNotificationsActions.disconnectRealtime());
    }
    const connection = new SignalRConnectionClient();


    yield call(connection.init, '/api/hubs/notifications');

    const restartChannel = createEventsChannel(emitter => connection.onCloseCallback = () => emitter('closed'));
    yield fork(restartWatch, restartChannel);

    const room = CompanyResourcesApi.getBaseUrl();
    yield call(connection.invoke, 'JoinCompany', room);
    yield put(TwoDNotificationsActions.realtimeConnected({ connection, room }));
    for (const eventType of Object.keys(EventsHandlers)) {
      const channel = createEventsChannel(emitter => connection.subscribe(eventType, (...args) => emitter(args)));
      yield fork<(props: WatchProps) => SagaIterator>(watch, { channel,  eventType });
    }
  } catch (error) {
    console.error('2d notification realtime init error', error);
  }
}

function* disconnectConnection(): SagaIterator {
  try {
    const connectionInfo = yield selectWrapper(x => x.twoDNotifications.realtimeConnection);
    if (!connectionInfo) {
      return;
    }
    const { connection, room } = connectionInfo;
    yield put(TwoDNotificationsActions.realtimeDisconnected());
    yield call(connection.invoke, 'LeaveCompany', room);
    connection.stop();
  } catch (error) {
    console.error('2d notification disconnect connection', error);
  }
}

export function* twoDCommentsRealtimeSaga(): SagaIterator {
  yield takeLatest(TwoDNotificationsActions.connectRealtime, initCommentsRealtime);
  yield takeLatest(TwoDNotificationsActions.disconnectRealtime, disconnectConnection);
}
