import { Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './logo.scss';

import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { TooltipContainer } from 'common/components/tooltip-container';
import { LogoType } from 'common/enums/logo-type';
import { State as ReduxState } from 'common/interfaces/state';
import {
  KreoIconCollapseExpand,
  KreoLogoBackDark,
  KreoLogoMarkLight,
} from 'common/UIKit';
import { TopPanel } from '../../actions';

interface ReduxProps {
  leftMenuExpanded: boolean;
}

interface ReduxActions {
  goTo: (path: string) => void;
  expandLeftMenu: (value: boolean) => void;
}

interface PageHeaderProps {
  backUrl?: string;
  logoType?: LogoType;
  disableToggle?: boolean;
  showLeftMenu?: boolean;
}

interface Props extends PageHeaderProps, ReduxProps, ReduxActions, AbilityAwareProps {}

class LogoComponent extends React.PureComponent<Props> {
  private scenarioWrapperStyle: React.CSSProperties = { height: '100%' };

  public render(): React.ReactNode {
    const {
      logoType,
      showLeftMenu,
      backUrl,
      leftMenuExpanded,
    } = this.props;

    let logoMark = <KreoLogoMarkLight />;
    let wrapperStyle = null;
    let blackLight = false;

    switch (logoType) {
      case LogoType.LogoTakeoff3D:
      case LogoType.LogoBlack:
        logoMark = <KreoLogoMarkLight />;
        break;
      case LogoType.LogoBlackLight:
        logoMark = <KreoLogoBackDark />;
        wrapperStyle = this.scenarioWrapperStyle;
        blackLight = true;
        break;

      case LogoType.LogoTakeoff2D:
        // иконка одинаковая т.к. в логотипах они не отличаются,
        logoMark = <Icons.TakeOffIcon/>;
        break;
      default:
    }

    const mainClasses = classNames('logo', {
      'with-toggle': showLeftMenu,
      'black-light': blackLight,
      collapsed: showLeftMenu && !leftMenuExpanded,
    });

    const logoClasses = classNames('logo__icon', {
      disabled: !backUrl,
    });

    return (
      <div className={mainClasses} style={wrapperStyle}>
        <div className={logoClasses} onClick={backUrl ? this.goBack : null}>
          {logoMark}
          {showLeftMenu && <TooltipContainer className='logo__icon-tooltip'>
            Back to projects
          </TooltipContainer>}
        </div>
        {showLeftMenu ? (
          <div className='logo__toggle-wrap'>
            <div
              className={classNames('logo__toggle', {
                'logo__toggle--expanded': leftMenuExpanded,
              })}
              onClick={this.onToggle}
            >
              <KreoIconCollapseExpand />
            </div>
            <TooltipContainer className='logo__toggle-tooltip'>
              {leftMenuExpanded ? 'Collapse' : 'Expand'} menu
            </TooltipContainer>
          </div>
        ) : null}
      </div>
    );
  }

  @autobind
  private onToggle(): void {
    this.props.expandLeftMenu(!this.props.leftMenuExpanded);
  }

  @autobind
  private goBack(): void {
    this.props.goTo(this.props.backUrl);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    leftMenuExpanded: state.toppanel.get('leftmenuExpanded'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    expandLeftMenu: value => dispatch(TopPanel.expandLeftMenu(value)),
    goTo: path => dispatch(push(path)),
  };
};

export const Logo = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LogoComponent),
);
