import autobind from 'autobind-decorator';
import * as paper from 'paper';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { DrawingsUserAnnotationActions } from '../../actions/creators/user-annotation';
import { DrawingsAnnotationsPosition } from '../../actions/payloads/user-annotation';
import {
  DrawingContextObserver,
  DrawingsRendererApiContextProps,
  withRendererApiContext,
} from '../../drawings-contexts';
import { DrawingsGeometryDragEventHelper } from '../../drawings-geometry';
import { DrawingsGeometrySelectionArea } from '../../drawings-geometry/drawings-geometry-entities/utility';
import { DrawingsDrawMode } from '../../enums/drawings-draw-mode';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { DrawingUserAnnotationSticker } from '../../interfaces/drawings-user-annotation';
import { DrawingsCommonUtils } from '../../utils/drawings-common-utils';
import { DrawingsCommentApi } from '../comments/comment/comment';
import { DrawingsCommentsList } from '../comments/drawings-comments-list';
import { StickersList, TEMP_STICKER_ID, DrawingsStickerAPI } from '../stickers';
import { Styled } from './styled';


export interface DrawingsStickerLayoutApi {
  updateViewportParams: () => void;
  stickersInRectIdsIterator: (
    rect: paper.Rectangle,
    selectionArea: DrawingsGeometrySelectionArea,
  ) => IterableIterator<string>;
  updateStickerPosition: (updatedPositions: DrawingsAnnotationsPosition[]) => void;
}

interface StateProps {
  stickers: Record<string, DrawingUserAnnotationSticker>;
  currentUserId: string;
  selectedAnnotations: string[];
}

interface DispatchProps {
  addSticker: (sticker: DrawingUserAnnotationSticker) => void;
  changeStickerText: (id: string, text: string) => void;
}


interface OwnProps {
  rotation: number;
  drawingId: string;
  tempPosition?: ShortPointDescription;
  shouldBlockEvents: boolean;
  drawMode: DrawingsDrawMode;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
  canEditUserAnnotations: boolean;
  zoomObserver: DrawingContextObserver<{ zoom: number }>;
  removeStickers: (ids: string[], pageId: string) => void;
  getTruePosition: (position: ShortPointDescription) => ShortPointDescription | null;
  getCanvasCoordinates: (point: paper.Point) => paper.Point;
  sendApi: (api: DrawingsStickerLayoutApi) => void;
  removeTempSticker: () => void;
  onChangeAnnotationSelection: (id: string, ctrlKey: boolean) => void;
}

interface Props extends
  StateProps,
  DispatchProps,
  OwnProps,
  DrawingsRendererApiContextProps {
}

const BASE_STICKER_WIDTH = 240;
const BASE_STICKER_HEIGHT = 260;

class CommentsContentLayoutComponent extends React.PureComponent<Props> {
  private renderedStickers: Map<string, DrawingsStickerAPI> = new Map();
  private renderedCommentaries: Map<number | string, DrawingsCommentApi> = new Map();

  private dragPos: paper.Point;

  public render(): React.ReactNode {
    if (!this.props.drawingId) {
      return null;
    }
    return (
      <Styled.Container shouldBlockEvents={this.props.shouldBlockEvents}>
        <StickersList
          currentDrawingId={this.props.drawingId}
          tempPosition={this.props.tempPosition}
          currentUserId={this.props.currentUserId}
          drawMode={this.props.drawMode}
          dragEventsHelper={this.props.dragEventsHelper}
          canEditUserAnnotations={this.props.canEditUserAnnotations}
          startDragSticker={this.startDragSticker}
          removeStickers={this.props.removeStickers}
          removeTempSticker={this.props.removeTempSticker}
          stopDrag={this.stopDrag}
          saveStickerRef={this.saveStickerRef}
        />
        <DrawingsCommentsList
          currentDrawingId={this.props.drawingId}
          sendCommentRef={this.saveCommentRef}
        />
      </Styled.Container>
    );
  }

  public componentDidMount(): void {
    this.props.sendApi({
      updateViewportParams: this.updateViewportParams,
      stickersInRectIdsIterator: this.stickersInRectIdsIterator,
      updateStickerPosition: this.updateStickersPositions,
    });

    if (this.props.rendererApi) {
      this.props.rendererApi.engine.userAnnotationsDragHelper.dragStickers = this.processDrag;
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.rendererApi !== this.props.rendererApi && this.props.rendererApi) {
      this.props.rendererApi.engine.userAnnotationsDragHelper.dragStickers = this.processDrag;
    }

    if (this.props.tempPosition !== prevProps.tempPosition && this.props.tempPosition && prevProps.tempPosition) {
      this.updateTempPosition();
    }
  }

  public componentWillUnmount(): void {
    if (this.props.rendererApi) {
      this.props.rendererApi.engine.userAnnotationsDragHelper.dragStickers = null;
    }
  }

  @autobind
  private stopDrag(): void {
    if (this.props.rendererApi) {
      this.props.rendererApi.engine.dragHelper.stopDrag();
    }
  }

  @autobind
  private startDragSticker(id: string, point: paper.Point): void {
    if (DrawingsCommonUtils.isDrawEnabled(this.props.drawMode) || !this.props.canEditUserAnnotations) {
      return;
    }
    if (this.props.rotation === 90) {
      point = new paper.Point(point.y, -point.x);
    } else if (this.props.rotation === 180) {
      point = point.multiply(-1);
    } else if (this.props.rotation === 270) {
      point = new paper.Point(-point.y, point.x);
    }
    const zoom = this.props.zoomObserver.getContext().zoom;
    this.dragPos = new paper.Point(this.props.stickers[id].position).add(point.divide(zoom));
    const ids = this.props.selectedAnnotations.includes(id) ? this.props.selectedAnnotations : [id];
    this.props.rendererApi.engine.userAnnotationsDragHelper.startDrag(this.dragPos, ids);
  }

  private dragStickerPosition(id: string, diff: paper.Point): DrawingsAnnotationsPosition {
    const [x, y] = this.props.stickers[id].position;
    const position: ShortPointDescription = [x + diff.x, diff.y + y];
    const truePos = this.props.getTruePosition(position);
    this.renderedStickers.get(id).changePosition(...truePos);
    return { id, position };
  }

  @autobind
  private updateStickersPositions(updates: DrawingsAnnotationsPosition[]): void {
    for (const { id, position } of updates) {
      const truePos = this.props.getTruePosition(position);
      if (this.renderedStickers) {
        this.renderedStickers.get(id).changePosition(...truePos);
      }
    }
  }

  @autobind
  private processDrag(diff: paper.Point, ids: string[]): boolean {
    const updates = [];
    for (const id of ids) {
      if (id in this.props.stickers) {
        updates.push(this.dragStickerPosition(id, diff));
      }
    }
    return true;
  }

  @autobind
  private *stickersInRectIdsIterator(
    rect: paper.Rectangle,
    selectionArea: DrawingsGeometrySelectionArea,
  ): IterableIterator<string> {
    if (this.props.canEditUserAnnotations) {
      const size = new paper.Size(BASE_STICKER_WIDTH, BASE_STICKER_HEIGHT);
      for (const [ stickerId, sticker ] of Object.entries(this.props.stickers)) {
        const path = new paper.Path.Rectangle(
          new paper.Rectangle(new paper.Point(sticker.position)),
          size,
        );
        if (path.isInside(rect) || selectionArea.intersects(path)) {
          yield stickerId;
        }
        path.remove();
      }
    }
  }

  @autobind
  private saveCommentRef(ref: DrawingsCommentApi, id: number | string): void {
    if (ref) {
      this.updateCommentaryRender(ref);
      this.renderedCommentaries.set(id, ref);
    } else {
      this.renderedCommentaries.delete(id);
    }
  }

  private updateCommentaryRender(ref: DrawingsCommentApi): void {
    const position = ref.getTargetPosition();
    const layoutPosition = this.props.getTruePosition(position);
    if (layoutPosition) {
      const [x, y] = layoutPosition;
      ref.setPosition(x, y);
    }
  }

  @autobind
  private saveStickerRef(ref: DrawingsStickerAPI, id: string): void {
    if (ref) {
      const position = this.getStickerPositionById(id);
      if (position) {
        const [x, y] = position;
        ref.changePosition(x, y);
      }
      this.renderedStickers.set(id, ref);
    } else {
      this.renderedStickers.delete(id);
    }
  }

  @autobind
  private updateViewportParams(): void {
    this.renderedStickers.forEach((value, key) => {
      const position = this.getStickerPositionById(key);
      if (position) {
        const [x, y] = position;
        value.changePosition(x, y);
      }
    });
    this.renderedCommentaries.forEach((value) => {
      this.updateCommentaryRender(value);
    });
  }

  private getStickerPositionById(id: string): ShortPointDescription {
    let position: ShortPointDescription;
    if (id === TEMP_STICKER_ID) {
      position = this.props.tempPosition;
    } else if (id in this.props.stickers) {
      position = this.props.stickers[id].position;
    }
    return position ? this.props.getTruePosition(position) : null;
  }

  private updateTempPosition(): void {
    const position = this.props.getTruePosition(this.props.tempPosition);
    if (this.renderedStickers.has(TEMP_STICKER_ID) && position) {
      this.renderedStickers.get(TEMP_STICKER_ID).changePosition(position[0], position[1]);
    }

  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
  return {
    changeStickerText: (id, text) => dispatch(DrawingsUserAnnotationActions.changeStickerText(id, text)),
    addSticker: sticker => {
      dispatch(DrawingsUserAnnotationActions.addSticker([sticker], ownProps.drawingId));
    },
  };
}

function mapStateToProps(state: State): StateProps {
  return {
    stickers: state.drawings.userAnnotations.stickers,
    currentUserId: state.account.id,
    selectedAnnotations: state.drawings.userAnnotations.selectedAnnotations,
  };
}

export const CommentsContentLayout =
  connect(mapStateToProps, mapDispatchToProps)
  (withRendererApiContext(CommentsContentLayoutComponent));
