import autobind from 'autobind-decorator';
import * as paper from 'paper';

import {
  ContextObserver,
  ContextObserverWithPrevious,
} from 'common/components/drawings/drawings-contexts';
import {
  WizzardStatus,
} from 'common/components/drawings/enums/dropper-state';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import {
  DrawingsRenderParams,
  DrawingsSelectAggregationGroup,
  ShortPointDescription,
  WizzardToolsState,
} from 'common/components/drawings/interfaces';
import {
  PdfGeometry,
} from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';

import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DestroyableObject, EngineObjectConfig } from '../../common';
import { DrawingsGeometryUtilityPolygon } from '../../drawings-geometry-entities';
import {
  BatchedUpdateCallback,
  GetCurrentDrawingCallback,
  NewDrawingSettings,
  SetCursorHintCallback,
} from '../../interfaces';
import { DrawingsViewHelper } from '../drawings-view-helper';
import { DrawingsGeometrySnappingHelper, DrawingsSnappingParameters } from '../snapping';
import { WizzardPreview } from './wizzard-preview';

interface Config extends EngineObjectConfig {
  snappingHelper: DrawingsGeometrySnappingHelper;
  layer: paper.Layer;
  viewHelper: DrawingsViewHelper;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  newDrawingStylesObserver: ContextObserver<NewDrawingSettings>;
  wizzardSettingsObserver: ContextObserver<WizzardToolsState>;
  viewSettingsObserver: ContextObserver<MeasuresViewSettings>;
  colorsCacheHelper: DrawingsColorCacheHelper;
  setCursorMessage: SetCursorHintCallback;
  getDrawingInfo: GetCurrentDrawingCallback;
  onBatchUpdateGeometries: BatchedUpdateCallback;
  setDropperState: (state: WizzardStatus, itemsCount?: number) => void;
  onRunDropper: (point: ShortPointDescription) => void;
}

export class Dropper extends DestroyableObject<Config> {
  private _currentPosition: paper.Point;
  private _tempEntity: WizzardPreview;
  private _searchArea: DrawingsGeometryUtilityPolygon;


  constructor(config: Config) {
    super(config);
    this.init();
  }

  public clearResult(): void {
    this._tempEntity?.destroy();
    this._tempEntity = null;
    const { wizzardState: dropperState } = this._config.wizzardSettingsObserver.getContext();
    const { status } = dropperState;
    if (status !== WizzardStatus.Start && status !== WizzardStatus.SpecifySearchArea) {
      this._config.setDropperState(WizzardStatus.Start);
    }
  }

  public async apply(): Promise<void> {
    if (!this._tempEntity) {
      return;
    }
    const drawingId = this._config.getDrawingInfo().drawingId;
    const { instances, newPoints } = await this._tempEntity.getInstances(drawingId);

    this._config.onBatchUpdateGeometries({
      addedInstances: instances,
      newPoints,
      pageId: drawingId,
      moveToSelectedGroup: true,
    });
    this.clearResult();
  }

  public removeEventListeners(): void {
    this._config.viewHelper.restoreDefaultEvents(['onMouseDown', 'onMouseMove', 'onMouseUp']);
  }

  public destroy(): void {
    this.clearResult();
    if (this._searchArea) {
      this._searchArea.destroy();
      this._searchArea = null;
    }
    this._config.wizzardSettingsObserver.unsubscribe(this.updateDropperSettings);
  }

  private init(): void {
    this._config.viewHelper.onMouseDown = null;
    this._config.viewHelper.onMouseMove = this.onMouseMove;
    this._config.viewHelper.onLeftMouseClick = this.leftButtonClick;

    this._config.snappingHelper.onSendPoint = this.updateMousePosition;

    this._config.wizzardSettingsObserver.subscribe(this.updateDropperSettings);
  }

  @autobind
  private onMouseMove(e: PaperMouseEvent): void {
    if (!this._config.snappingHelper.canSnap) {
      this.updateMousePosition(e.point);
    } else {
      this._config.snappingHelper.requestSnapping(e.point);
    }
  }

  @autobind
  private updateMousePosition(point: paper.Point, snappingInfo?: DrawingsSnappingParameters): paper.Point {
    this._currentPosition = point;
    if (snappingInfo) {
      const { threshold, distance } = snappingInfo;
      if (snappingInfo.snappingPoint && distance < threshold) {
        this._currentPosition = snappingInfo.snappingPoint;
        return this._currentPosition;
      }
    }
    return null;
  }

  private request(point: paper.Point): void {
    if (this._tempEntity) {
      this._tempEntity.destroy();
      this._tempEntity = null;
    }
    this._config.onRunDropper([ point.x, point.y ]);
    this._config.setDropperState(WizzardStatus.Loading);
  }

  private renderPreview(geometries: PdfGeometry[]): void {
    if (!geometries?.length) {
      return;
    }
    const {
      dropperInstanceType,
      dropperSimilarity,
      dropperSameGeometry,
    } = this._config.wizzardSettingsObserver.getContext();
    this._tempEntity = new WizzardPreview({
      geometry: geometries,
      colorCache: this._config.colorsCacheHelper,
      newDrawingsStylesObserver: this._config.newDrawingStylesObserver,
      renderParamsContextObserver: this._config.renderParametersContextObserver,
      layer: this._config.layer,
      fill: dropperInstanceType === DrawingsSelectAggregationGroup.Area,
      similarity: dropperSameGeometry ? dropperSimilarity : 0,
      geometriesToHide: {},
      viewSettingsObserver: this._config.viewSettingsObserver,
    });
  }

  @autobind
  private leftButtonClick(e: PaperMouseEvent): void {
    this.request(this._currentPosition || e.point);
  }

  @autobind
  private updateDropperSettings(
    { searchArea, dropperSimilarity, dropperSameGeometry, result }: WizzardToolsState,
  ): void {
    if (this._tempEntity?.geometry !== result) {
      this._tempEntity?.destroy();
      this.renderPreview(result as PdfGeometry[]);
    }
    if (this._tempEntity && dropperSameGeometry) {
      this._tempEntity.updateFilters(dropperSimilarity, {});
    }
    if (!searchArea) {
      this._searchArea?.destroy();
      this._searchArea = null;
    } else if (this._searchArea?.geometry !== searchArea) {
      this._searchArea?.destroy();
      this._searchArea = new DrawingsGeometryUtilityPolygon({
        layer: this._config.layer,
        geometry: searchArea,
        renderParamsContextObserver: this._config.renderParametersContextObserver,
      });
    }
  }
}
