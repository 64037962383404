import autobind from 'autobind-decorator';
import { DrawingsInstanceType } from 'common/components/drawings/enums';

import { DrawingsCursorTypeHelper } from '../..';
import { DrawingsGeometryDragEventHelper } from '../../drawings-helpers/drawings-geometry-drag-event-helper';
import { DraggableMouseEventsProcessor } from '../../drawings-helpers/geometry-event-processors';
import { DraggablePaperObjectEventsApplier } from '../../interfaces';
import { PaperMouseEventHandler } from '../../interfaces/helpers/mouse-event-handler';
import { DrawingsMouseEventHandler } from './drawings-geometry-entity';


export interface DrawingsGeometryEntityBaseConfig {
  id: string;
  instanceType?: DrawingsInstanceType;
  onSelect?: DrawingsMouseEventHandler;
  onDoubleClick?: DrawingsMouseEventHandler;
  dragEventsHelper?: DrawingsGeometryDragEventHelper;
  onStartDrag?: DrawingsMouseEventHandler;
  cursorHelper?: DrawingsCursorTypeHelper;
  onMouseEnter?: DrawingsMouseEventHandler;
  onMouseLeave?: DrawingsMouseEventHandler;
}

export interface DrawingsGeometryEntityBase {
  mouseLeave?(id: string, e: PaperMouseEvent): void;
}


export abstract class DrawingsGeometryEntityBase
  <T extends DrawingsGeometryEntityBaseConfig = DrawingsGeometryEntityBaseConfig> {
  protected _config: T;

  protected _eventsApplier: DraggablePaperObjectEventsApplier<string>;

  constructor(config: T) {
    this._config = config;
    this._eventsApplier = new DraggableMouseEventsProcessor<string>({
      onClick: this.onClick,
      getDoubleClickHandler: this.getDoubleClickEventHandler,
      onMouseLeave: this.onMouseLeave,
      onStartDrag: this.onStartDrag,
      dragEventsHelper: this._config.dragEventsHelper,
    });
  }

  public set onDoubleClick(onDoubleClick: DrawingsMouseEventHandler) {
    this._config.onDoubleClick = onDoubleClick;
  }

  public get instanceType(): DrawingsInstanceType {
    return this._config.instanceType;
  }

  public get id(): string {
    return this._config.id;
  }

  @autobind
  protected onClick(e: PaperMouseEvent, id: string): void {
    if (this._config.onSelect) {
      this._config.onSelect(id, e);
    }
  }

  @autobind
  protected onStartDrag(e: PaperMouseEvent): void {
    if (this._config.onStartDrag) {
      this._config.onStartDrag(this.id, e);
    }
  }


  @autobind
  protected onMouseLeave(e: PaperMouseEvent): void {
    if (this.mouseLeave) {
      this.mouseLeave(this.id, e);
    }
  }

  @autobind
  protected doubleClick(e: PaperMouseEvent): void {
    this._config.onDoubleClick(this.id, e);
  }

  @autobind
  protected getDoubleClickEventHandler(): PaperMouseEventHandler<string> | null {
    return this._config.onDoubleClick ? this.doubleClick : null;
  }
}
