import { Constants } from '@kreo/kreo-ui-components';
import styled, { css } from 'styled-components';

import { FormFieldStyled } from '../form-fields/form-field';
import { InputSelectStyled, InputSmallStyled } from '../inputs';

const InputWithUnitStyle = css`
  > div:nth-child(1) > ${InputSmallStyled.InputStyle}:nth-child(1) {
    border-radius: 5px 0 0 5px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5px 5px 5px;
`;

const ElementWrapper = styled.div<{ hasUnit: boolean }>`
  ${FormFieldStyled.InputContainer} {
    ${(p) => {
    if (p.hasUnit) {
      return InputWithUnitStyle;
    }
    return '';
  }}

    >div>button {
      background: ${Constants.Colors.GENERAL_COLORS.red};

      > span {
        height: 8px;
        > svg {
          height: 8px;
        }
      }
    }
  }
`;

const MixedWrapper = styled.div`
  ${FormFieldStyled.InputContainer} {
    >div>button {
      background: ${Constants.Colors.GENERAL_COLORS.blurryBlue};
    }
  }
`;

const EditorContainer = styled.div`
  border-radius: 10px;
  background-color: ${p => p.theme.color.backgroundRush};
  padding: 10px 20px;
  box-shadow: 0px 15px 40px #00000029;
  width: 180px;
  position: absolute;
  z-index: 5;
  right: 5px;
  ${InputSelectStyled.DropDownMenuContainer} {
    width: 180px;
  }
`;

const BackClickContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top:0;
  left: 0;
  z-index: 0;
`;

export const Styled = {
  BackClickContainer,
  Container,
  ElementWrapper,
  MixedWrapper,
  EditorContainer,
};
