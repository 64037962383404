import { LinkComponent, SvgComponent } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { Styled } from './styled';

export interface BreadcrumbItem {
  id: string | number;
  name: string;
}

interface Props<T> {
  item: T;
  isClickable: boolean;
  Icon: SvgComponent;
  onClick: (item: T) => void;
}

export class Breadcrumb<T extends BreadcrumbItem> extends React.PureComponent<Props<T>> {
  public render(): React.ReactNode {
    const { Icon, item, isClickable } = this.props;
    return (
      <Styled.BreadcrumbContent onClick={this.onClick} isClickable={isClickable}>
        <LinkComponent
          text={item.name}
          Icon={Icon}
          mood={!isClickable && 'disabled'}
          onClick={this.onClick}
        />
      </Styled.BreadcrumbContent>
    );
  }

  @autobind
  private onClick(): void {
    const { item, isClickable, onClick } = this.props;
    if (isClickable) {
      onClick(item);
    }
  }
}
