import { ICellEditorParams } from 'ag-grid-community';

import { MetricUnitConversationMap } from 'common/utils/unit-util';
import { ValueHelper } from 'common/utils/value-helper';
import { QtoTreeRowProperty } from '../../../interfaces/quantity-take-off';
import { QtoColumnPropertyHelper } from '../../../utils/quantity-take-off-tree-table';
import { CellEditor } from '../../quantity-take-off-tree-table-cell-edit/cell-editor';
import { CellEditorType } from '../../quantity-take-off-tree-table-cell-edit/cell-editor-type';
import { DefaultCellEditorHelper } from '../../quantity-take-off-tree-table-cell-edit/default-cell-editor-helper';
import { QtoTreeTableCommon } from '../quantity-take-off-tree-table-common';
import { ReportCellEditor } from './report-table-cell-editor';


export class ReportTableCellDefaultEditor extends ReportCellEditor<HTMLInputElement> {
  public constructor(params: ICellEditorParams, eGui: HTMLDivElement) {
    super(params, eGui);
    this.cellEditorType = CellEditorType.DefaultCellEditor;
  }

  public static getInnerHtml(): string {
    return DefaultCellEditorHelper.getInnerHtml(this.className);
  }

  public getNode(value: string): Node {
    const input = DefaultCellEditorHelper.getInputElement(value, CellEditor.className);
    this.eValue = input;
    return input;
  }

  public getEditorType(): CellEditorType {
    return this.cellEditorType;
  }

  public initEvents(): void {
    // do nothing
  }

  public destroyEvents(): void {
    // do nothing
  }

  public getCurrentValue(): string {
    return this.eValue.value;
  }

  public setFocus(): void {
    if (this.eValue) {
      this.eValue.focus();
    }
  }

  protected setEValue(value: string): void {
    this.eValue.value = value;
  }

  protected getNewValue(): QtoTreeRowProperty {
    const properties = this.params.data.properties;
    const colId = this.params.column.getId();
    if (!properties[colId]) {
      properties[colId] = {};
    }

    const valueWithDots = this.getCurrentValue().replace(',', '.');
    const value = ValueHelper.isNumberValue(valueWithDots)
      ? Number(valueWithDots)
      : this.getCurrentValue() || '';
    properties[colId].override = value !== this.getConvertedValue(properties[colId].default)
      ? ValueHelper.isNumberValue(value)
        ? this.getValueFormConverted(Number(value))
        : value
      : null;

    return properties[colId];
  }

  protected currentInitEValue(): void {
    this.eValue.value = ValueHelper.isNumberValue(this.params.value)
      ? this.getConvertedValue(this.params.value)
      : this.params.value || null;
  }

  protected isEmpty(): boolean {
    return !this.eValue.value;
  }

  private getValueFormConverted(value: number): number {
    const unit = QtoColumnPropertyHelper.getColumnUnit(this.params.column);
    const isImperial = this.params.context.isImperial;
    return QtoTreeTableCommon.imperialValueConverter(value, MetricUnitConversationMap[unit], isImperial);
  }
}
