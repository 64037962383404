import styled, { css } from 'styled-components';

const SharedStyle = css`
  width: 100%;
  border: unset;
  text-align: left;
  padding: 0px 0px 0px 8px;
  height: 24px;
  transition: all 100ms ease-in;
  box-shadow: 0 0 0 1px ${(p) => p.theme.color.pale};
  background: ${(p) => p.theme.color.background} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: ${(p) => p.theme.color.mainFont};
  font-size: 14px;
  line-height: 1.5;
  box-sizing: border-box;
  &::placeholder {
    color: ${(p) => p.theme.color.disabled};
    text-align: left;
    font: normal normal normal 14px/18px;
    letter-spacing: 0px;
    opacity: 1
  }
  &:hover {
    box-shadow: 0 0 0 1px ${(p) => p.theme.color.turquoise};
    cursor: pointer;
    z-index: 1;
  }
  &:focus-visible {
    box-shadow: 0 0 0 2px ${(p) => p.theme.color.turquoise};
    outline-offset: unset;
    outline: unset;
    z-index: 1;
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Form = styled.form`
  width: inherit;
`;

const InputStyle = styled.input`
  ${SharedStyle}
`;

const FakeInputStyle = styled.div`
  ${SharedStyle}
`;

const TextAreaStyle = styled.textarea`
  ${SharedStyle}
  resize: none;
  height: unset;
`;

export const Styled = {
  SharedStyle,
  Form,
  InputStyle,
  FakeInputStyle,
  TextAreaStyle,
};
