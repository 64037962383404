import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconAddedDone: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icon_added' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M11.6464466,3.64644661 C11.8417088,3.45118446 12.1582912,3.45118446 12.3535534,3.64644661
          C12.5488155,3.84170876 12.5488155,4.15829124 12.3535534,4.35355339 L6.35355339,10.3535534
          C6.15829124,10.5488155 5.84170876,10.5488155 5.64644661,10.3535534 L2.14644661,6.85355339
          C1.95118446,6.65829124 1.95118446,6.34170876 2.14644661,6.14644661 C2.34170876,5.95118446
          2.65829124,5.95118446 2.85355339,6.14644661 L6,9.29289322 L11.6464466,3.64644661 Z'
          id='Rectangle-5'
          fill={KreoColors.f1}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
