import { Vector3D } from '../../interfaces';

function crossProduct(v1: Vector3D, v2: Vector3D): Vector3D {
  return [
    v1[1] * v2[2] - v1[2] * v2[1],
    v1[2] * v2[0] - v1[0] * v2[2],
    v1[0] * v2[1] - v1[1] * v2[0],
  ];
}

function magnitude(v: Vector3D): number {
  return Math.sqrt(v[0] ** 2 + v[1] ** 2 + v[2] ** 2);
}

function triangleArea(v1: Vector3D, v2: Vector3D): number {
  return magnitude(crossProduct(v1, v2)) / 2;
}

function subtract(v1: Vector3D, v2: Vector3D): Vector3D {
  return [v1[0] - v2[0], v1[1] - v2[1], v1[2] - v2[2]];
}

function add(v1: Vector3D, v2: Vector3D): Vector3D {
  return [v1[0] + v2[0], v1[1] + v2[1], v1[2] + v2[2]];
}

export const Vector3Utils = {
  crossProduct,
  magnitude,
  triangleArea,
  subtract,
  add,
};


