import autobind from 'autobind-decorator';
import * as React from 'react';

import './clipbox-control.scss';

import { FlatPanel, KreoIconClipBox, KreoIconClipBoxActive } from 'common/UIKit';
import { Toggle } from '../../../../components/toggle';

interface Props {
  active: boolean;
  ghostChecked: boolean;
  onBoxClick: (e: any) => void;
  setClipBoxMode: (mode: any) => void;
}

export class ClipBoxControl extends React.Component<Props> {
  private active: boolean;

  constructor(props: Props) {
    super(props);
    this.active = false;
  }

  public shouldComponentUpdate(nextProps: any): any {
    if (nextProps.active !== this.active) {
      this.active = nextProps.active;
      this.forceUpdate();
    }

    return true;
  }

  public render(): any {
    return (
      <FlatPanel
        floatVertical={0}
        floatHorizontal={0}
        color={this.getStyle()}
        onClick={this.onActive}
        controlName='clip-box-button'
      >
        <div
          style={{ display: 'flex', flexDirection: 'row' }}
          className={`clipbox-control-content ${this.active ? 'x' : ''}`}
        >
          {this.active ? <KreoIconClipBoxActive /> : <KreoIconClipBox />}
          <span className='viewer-control-action-name'>Clip Box</span>
          {this.active && <React.Fragment>
            <Toggle
              checkedColor={'#5C8AE6'}
              onCheck={this.onCheck}
              checked={this.props.ghostChecked}
              controlName='ghost-toggle'
            />,
            <span>Ghost</span>,
          </React.Fragment>}
        </div>
      </FlatPanel>
    );
  }

  @autobind
  private onActive(e: React.MouseEvent<HTMLDivElement>): void {
    this.active = !this.active;
    this.props.onBoxClick(e);
    this.forceUpdate();
  }

  private getStyle(): any {
    if (this.active) {
      return 'rgba(37,49,71,0.9)';
    }
  }

  @autobind
  private onCheck(value: any): any {
    if (value) {
      this.props.setClipBoxMode('ghost');
    } else {
      this.props.setClipBoxMode('none');
    }
  }
}
