import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconBackToApp: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='icon_back-to-app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M1.48483496,2.26516504 C1.33838835,2.11871843 1.33838835,1.88128157 1.48483496,1.73483496
          C1.63128157,1.58838835 1.86871843,1.58838835 2.01516504,1.73483496 C2.16161165,1.88128157
          2.16161165,2.11871843 2.01516504,2.26516504 C1.86871843,2.41161165 1.63128157,2.41161165
          1.48483496,2.26516504 Z M1.48483496,4.76516504 C1.33838835,4.61871843 1.33838835,4.38128157
          1.48483496,4.23483496 C1.63128157,4.08838835 1.86871843,4.08838835 2.01516504,4.23483496
          C2.16161165,4.38128157 2.16161165,4.61871843 2.01516504,4.76516504 C1.86871843,4.91161165
          1.63128157,4.91161165 1.48483496,4.76516504 Z M1.48483496,7.26516504 C1.33838835,7.11871843
          1.33838835,6.88128157 1.48483496,6.73483496 C1.63128157,6.58838835 1.86871843,6.58838835
          2.01516504,6.73483496 C2.16161165,6.88128157 2.16161165,7.11871843 2.01516504,7.26516504
          C1.86871843,7.41161165 1.63128157,7.41161165 1.48483496,7.26516504 Z M1.48483496,9.76516504
          C1.33838835,9.61871843 1.33838835,9.38128157 1.48483496,9.23483496 C1.63128157,9.08838835
          1.86871843,9.08838835 2.01516504,9.23483496 C2.16161165,9.38128157 2.16161165,9.61871843
          2.01516504,9.76516504 C1.86871843,9.91161165 1.63128157,9.91161165 1.48483496,9.76516504 Z
          M1.48483496,12.265165 C1.33838835,12.1187184 1.33838835,11.8812816 1.48483496,11.734835
          C1.63128157,11.5883883 1.86871843,11.5883883 2.01516504,11.734835 C2.16161165,11.8812816
          2.16161165,12.1187184 2.01516504,12.265165 C1.86871843,12.4116117 1.63128157,12.4116117
          1.48483496,12.265165 Z M3.89644661,2.35355339 C3.70118446,2.15829124 3.70118446,1.84170876
          3.89644661,1.64644661 C4.09170876,1.45118446 4.40829124,1.45118446 4.60355339,1.64644661
          C4.79881554,1.84170876 4.79881554,2.15829124 4.60355339,2.35355339 C4.40829124,2.54881554
          4.09170876,2.54881554 3.89644661,2.35355339 Z M3.89644661,4.85355339 C3.70118446,4.65829124
          3.70118446,4.34170876 3.89644661,4.14644661 C4.09170876,3.95118446 4.40829124,3.95118446
          4.60355339,4.14644661 C4.79881554,4.34170876 4.79881554,4.65829124 4.60355339,4.85355339
          C4.40829124,5.04881554 4.09170876,5.04881554 3.89644661,4.85355339 Z M3.89644661,7.35355339
          C3.70118446,7.15829124 3.70118446,6.84170876 3.89644661,6.64644661 C4.09170876,6.45118446
          4.40829124,6.45118446 4.60355339,6.64644661 C4.79881554,6.84170876 4.79881554,7.15829124
          4.60355339,7.35355339 C4.40829124,7.54881554 4.09170876,7.54881554 3.89644661,7.35355339 Z
          M3.89644661,9.85355339 C3.70118446,9.65829124 3.70118446,9.34170876 3.89644661,9.14644661
          C4.09170876,8.95118446 4.40829124,8.95118446 4.60355339,9.14644661 C4.79881554,9.34170876
          4.79881554,9.65829124 4.60355339,9.85355339 C4.40829124,10.0488155 4.09170876,10.0488155
          3.89644661,9.85355339 Z M3.89644661,12.3535534 C3.70118446,12.1582912 3.70118446,11.8417088
          3.89644661,11.6464466 C4.09170876,11.4511845 4.40829124,11.4511845 4.60355339,11.6464466
          C4.79881554,11.8417088 4.79881554,12.1582912 4.60355339,12.3535534 C4.40829124,12.5488155
          4.09170876,12.5488155 3.89644661,12.3535534 Z M6.30805826,2.44194174 C6.06398058,2.19786406
          6.06398058,1.80213594 6.30805826,1.55805826 C6.55213594,1.31398058 6.94786406,1.31398058
          7.19194174,1.55805826 C7.43601942,1.80213594 7.43601942,2.19786406 7.19194174,2.44194174
          C6.94786406,2.68601942 6.55213594,2.68601942 6.30805826,2.44194174 Z M6.30805826,4.94194174
          C6.06398058,4.69786406 6.06398058,4.30213594 6.30805826,4.05805826 C6.55213594,3.81398058
          6.94786406,3.81398058 7.19194174,4.05805826 C7.43601942,4.30213594 7.43601942,4.69786406
          7.19194174,4.94194174 C6.94786406,5.18601942 6.55213594,5.18601942 6.30805826,4.94194174 Z
          M6.30805826,7.44194174 C6.06398058,7.19786406 6.06398058,6.80213594 6.30805826,6.55805826
          C6.55213594,6.31398058 6.94786406,6.31398058 7.19194174,6.55805826 C7.43601942,6.80213594
          7.43601942,7.19786406 7.19194174,7.44194174 C6.94786406,7.68601942 6.55213594,7.68601942
          6.30805826,7.44194174 Z M8.71966991,5.03033009 C8.4267767,4.73743687 8.4267767,4.26256313
          8.71966991,3.96966991 C9.01256313,3.6767767 9.48743687,3.6767767 9.78033009,3.96966991
          C10.0732233,4.26256313 10.0732233,4.73743687 9.78033009,5.03033009 C9.48743687,5.3232233
          9.01256313,5.3232233 8.71966991,5.03033009 Z M8.71966991,10.0303301 C8.4267767,9.73743687
          8.4267767,9.26256313 8.71966991,8.96966991 C9.01256313,8.6767767 9.48743687,8.6767767
          9.78033009,8.96966991 C10.0732233,9.26256313 10.0732233,9.73743687 9.78033009,10.0303301
          C9.48743687,10.3232233 9.01256313,10.3232233 8.71966991,10.0303301 Z M11.1312816,2.61871843
          C10.7895728,2.27700968 10.7895728,1.72299032 11.1312816,1.38128157 C11.4729903,1.03957281
          12.0270097,1.03957281 12.3687184,1.38128157 C12.7104272,1.72299032 12.7104272,2.27700968
          12.3687184,2.61871843 C12.0270097,2.96042719 11.4729903,2.96042719 11.1312816,2.61871843
          Z M11.1312816,12.6187184 C10.7895728,12.2770097 10.7895728,11.7229903 11.1312816,11.3812816
          C11.4729903,11.0395728 12.0270097,11.0395728 12.3687184,11.3812816 C12.7104272,11.7229903
          12.7104272,12.2770097 12.3687184,12.6187184 C12.0270097,12.9604272 11.4729903,12.9604272
          11.1312816,12.6187184 Z M6.30805826,9.94194174 C6.06398058,9.69786406 6.06398058,9.30213594
          6.30805826,9.05805826 C6.55213594,8.81398058 6.94786406,8.81398058 7.19194174,9.05805826
          C7.43601942,9.30213594 7.43601942,9.69786406 7.19194174,9.94194174 C6.94786406,10.1860194
          6.55213594,10.1860194 6.30805826,9.94194174 Z M6.30805826,12.4419417 C6.06398058,12.1978641
          6.06398058,11.8021359 6.30805826,11.5580583 C6.55213594,11.3139806 6.94786406,11.3139806
          7.19194174,11.5580583 C7.43601942,11.8021359 7.43601942,12.1978641 7.19194174,12.4419417
          C6.94786406,12.6860194 6.55213594,12.6860194 6.30805826,12.4419417 Z M8.71966991,2.53033009
          C8.4267767,2.23743687 8.4267767,1.76256313 8.71966991,1.46966991 C9.01256313,1.1767767
          9.48743687,1.1767767 9.78033009,1.46966991 C10.0732233,1.76256313 10.0732233,2.23743687
          9.78033009,2.53033009 C9.48743687,2.8232233 9.01256313,2.8232233 8.71966991,2.53033009 Z
          M8.71966991,12.5303301 C8.4267767,12.2374369 8.4267767,11.7625631 8.71966991,11.4696699
          C9.01256313,11.1767767 9.48743687,11.1767767 9.78033009,11.4696699 C10.0732233,11.7625631
          10.0732233,12.2374369 9.78033009,12.5303301 C9.48743687,12.8232233 9.01256313,12.8232233
          8.71966991,12.5303301 Z'
          id='Combined-Shape'
          fill={KreoColors.f2}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
