import React from 'react';
import { ReportTemplateInfo } from 'unit-2d-report-template/interfaces';

export const useHideTemplateIdsMemo = (usedTemplates: ReportTemplateInfo[]): Set<number> => {
  return React.useMemo(() => {
    if (!usedTemplates.length) {
      return undefined;
    }
    return new Set(usedTemplates.map(t => t.companyReportTemplateId));
  }, [usedTemplates]);
};
