import autobind from 'autobind-decorator';
import { ConnectionChecker } from './connection-checker';
import { MultiViewData } from './multi-view-data';
import { INNER_COMMANDS, MultiVieHelperModule } from '.';

export class MultiViewHelperChild extends ConnectionChecker implements MultiVieHelperModule {
  public readonly isParentMode: boolean = false;

  private readonly data: MultiViewData;
  private sendInnerCommand: (value: any) => void;

  public constructor(
    data: MultiViewData,
    secondViewId: string,
    sendInnerCommand: (value: any) => void,
  ) {
    super();
    this.data = data;
    this.data.secondViewId = secondViewId;

    this.sendInnerCommand = sendInnerCommand;
    this.sendInnerCommand({ type: INNER_COMMANDS.LOADED, value: `Ready ${data.id}` });
  }

  @autobind
  public handleOnDestroy(): void {
    this.sendInnerCommand({ type: INNER_COMMANDS.DESTROY });
  }

  public openChild(): void {
    return;
  }

  @autobind
  public handleOnDestroyPartner(): void {
    window.close();
    this.data.secondViewId = null;
  }

  public handleOnLoaded(): void {
    return;
  }

  @autobind
  public handleIsAlive(): void {
    this.sendInnerCommand({ type: INNER_COMMANDS.IS_ALIVE, value: true });
    this.handleLifeConnection();
  }

  public closeSecondWindow(): void {
    return;
  }
}
