import { Icons, LinkComponent } from '@kreo/kreo-ui-components';
import React from 'react';

import { Badge } from 'common/components/badge';
import { Text } from 'common/components/text';
import { InheritType, useParentAssign } from '../inherit-label';
import { Styled } from './styled';

interface Props {
  handleClick: () => void;
  type: InheritType;
  parentId: string;
  entityName: string;
}

export const Tooltip: React.FC<Props> = ({ handleClick, type, parentId, entityName }) => {
  const data = useParentAssign(type, parentId, entityName);
  return (
    <Styled.Tooltip>
      <Styled.TooltipButton>
        <LinkComponent
          text='Reset'
          Icon={Icons.UndoClassic}
          onClick={handleClick}
        />
      </Styled.TooltipButton>
      <Styled.TooltipText>
        <Text fontSize={12} withEllipsis={true}>Inherit from</Text>
        <Badge badgeText={data.name} withEllipsis={true} />
      </Styled.TooltipText>
    </Styled.Tooltip>
  );
};
