import { DrawingsInstanceMeasure, DrawingsLayoutApi } from 'common/components/drawings';
import { MoveToCellOptionType } from 'common/components/drawings/drawings-canvas-menus';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces/drawings-short-drawing-info';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import { ExcelFormulaHelper } from 'common/components/excel-table';

const measureType = 'length';
const idSeparator = ',';

const getMeasureFunction = (points: string[], drawingsInstanceId: string): string => {
  return `${MoveToCellOptionType.segmentLength}(${drawingsInstanceId}${idSeparator}${points.join(idSeparator)})`;
};

const isSegmentMeasureType = (type: string): boolean => {
  return type === MoveToCellOptionType.segmentLength;
};

const splitId = (id: string): [string, string] => {
  const [first, second] = id.split(idSeparator);
  return [first, second];
};

const isSegmentId = (id: string): boolean => {
  return id.split(idSeparator).length === 2;
};

const getColor = (
  id: string,
  drawingsLayoutApi: DrawingsLayoutApi,
  drawings: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  elementMeasurement: Record<string, DrawingsInstanceMeasure>,
): string => {
  const segmentInstance = drawingsLayoutApi.getSegmentMeasures(
    splitId(id),
    drawings,
    aiAnnotation,
    elementMeasurement,
  );
  return segmentInstance && segmentInstance.color || '';
};

const getSegmentValue = (
  id: string,
  drawingsLayoutApi: DrawingsLayoutApi,
  drawings: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  elementMeasurement: Record<string, DrawingsInstanceMeasure>,
): number | string => {
  const segmentInstance = drawingsLayoutApi.getSegmentMeasures(
    splitId(id),
    drawings,
    aiAnnotation,
    elementMeasurement,
  );
  return segmentInstance ? segmentInstance.measures[measureType] : ExcelFormulaHelper.INVALID_REF;
};

export const SegmentLength = {
  getMeasureFunction,
  getSegmentValue,
  isSegmentMeasureType,
  isSegmentId,
  getColor,
  splitId,
};
