import * as Ag from 'ag-grid-community';

import { TreeTable } from 'common/components/tree-table';
import { TreeTableColumn, TreeTableValueSetterParams } from 'common/components/tree-table/interfaces';
import { TreeTableColumnController } from 'common/components/tree-table/tree-table-column-controller';
import { TreeTableRowController } from 'common/components/tree-table/tree-table-row-controller';
import { mathUtils } from 'common/utils/math-utils';
import { ImperialUnitConversationMap, MetricUnitConversationMap, UnitUtil } from 'common/utils/unit-util';
import { ValueHelper } from 'common/utils/value-helper';
import {
  QtoTreeRowProperty as PropertyType,
} from '../../interfaces/quantity-take-off';
import { PropertyHelper, QtoColumnPropertyHelper } from '../../utils/quantity-take-off-tree-table';


const getColumnDef = (column: TreeTableColumn<PropertyType>): Partial<Ag.ColDef | Ag.ColGroupDef> => {
  const getProperty = <T>(col: TreeTableColumn<PropertyType>, key: string): T =>
    PropertyHelper.getActualValue<T>(col.properties[key]);

  return column.childColumnKeys
    ? {
      headerName: getProperty(column, PropertyHelper.columnProperties.header),
      hide: !getProperty(column, PropertyHelper.columnProperties.isVisible),
      cellRendererParams: column.properties,
    }
    : {
      headerName: getProperty(column, PropertyHelper.columnProperties.header),
      width: getProperty(column, PropertyHelper.columnProperties.width),
      pinned: getProperty(column, PropertyHelper.columnProperties.pinned),
      hide: !getProperty(column, PropertyHelper.columnProperties.isVisible),
      cellRendererParams: column.properties,
    };
};

const getColumnProperties = (column: Ag.Column): Record<string, PropertyType> => {
  const defs = column.getColDef();
  return {
    ...defs.cellRendererParams,
    [PropertyHelper.columnProperties.header]: { default: defs.headerName },
    [PropertyHelper.columnProperties.isVisible]: { default: column.isVisible() },
    [PropertyHelper.columnProperties.width]: { default: column.getActualWidth() },
    [PropertyHelper.columnProperties.pinned]: { default: column.getPinned() },
  };
};

const onColumnHeaderRename = (
  columnController: TreeTableColumnController<PropertyType>,
  id: string,
  name: string,
): void => {
  columnController.updateColumns([{
    id,
    properties: { [PropertyHelper.columnProperties.header]: { default: name } },
  }]);
};

const metricValueConverter = (value: number, unit: string, needToConvert: boolean): number => {
  return needToConvert
    ? UnitUtil.convertUnit(value, unit, MetricUnitConversationMap[unit]).value
    : value;
};

const imperialValueConverter = (value: number, unit: string, needToConvert: boolean): number => {
  return needToConvert
    ? UnitUtil.convertUnit(value, unit, ImperialUnitConversationMap[unit]).value
    : value;
};

const cellValueFormatter = ({ value, column, context, data }: Ag.ValueFormatterParams): string => {
  const unit = QtoColumnPropertyHelper.getColumnUnit(column);
  if (!ValueHelper.isNumberValue(value)) {
    return value;
  }

  const key = column.getColId();
  const convertedValue = PropertyHelper.isFormula(data.properties[key])
    ? value
    : metricValueConverter(value, unit, context.isImperial);
  return mathUtils.round(convertedValue, 2).toString();
};

const cellValueSetter = (
  rowController: TreeTableRowController<PropertyType>,
  params: TreeTableValueSetterParams<PropertyType>,
): boolean => {
  const { columnId, newValue, data } = params;
  if (newValue) {
    data.properties[columnId] = newValue;
  } else {
    delete data.properties[columnId];
  }

  rowController.updateRows([{
    id: data.id,
    properties: data.properties,
  }]);
  return true;
};

const refreshTable = (tableRef: TreeTable<any, any>): void => {
  if (!tableRef || !tableRef.columnController || !tableRef.gridApi) {
    return;
  }

  const { gridColumnApi, gridApi } = tableRef;
  const columns = gridColumnApi.getAllColumns();

  gridApi.refreshHeader();
  gridApi.refreshCells({ columns, force: true });
};


export const QtoTreeTableCommon = {
  getColumnDef,
  getColumnProperties,
  onColumnHeaderRename,
  cellValueFormatter,
  metricValueConverter,
  imperialValueConverter,
  cellValueSetter,
  refreshTable,
};
