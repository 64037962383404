import autobind from 'autobind-decorator';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { FailedUpdateReportDialog } from '2d/components';
import { AbilityRoute } from 'common/ability/ability-route';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { Styled } from './styled';

interface Props<TRouteParams> {
  component: React.ComponentType<RouteComponentProps<TRouteParams>>;
  path: string;
  exact?: boolean;
  subject?: Subject;
  redirectUrl?: string;
  operation?: Operation;
  saveContainerRef: (ref: HTMLDivElement) => void;
}

export class PageLayoutWithoutHeader<TRouteParams> extends React.Component<Props<TRouteParams>> {
  public render(): React.ReactNode {
    const { exact, path, subject, operation, redirectUrl } = this.props;
    return (
      <AbilityRoute
        exact={exact}
        path={path}
        component={this.renderRouteComponent}
        subject={subject}
        operation={operation}
        redirectUrl={redirectUrl}
      />
    );
  }

  @autobind
  public renderRouteComponent(
    props: RouteComponentProps<TRouteParams>,
  ): JSX.Element {
    const {
      component: InnerComponent,
      saveContainerRef,
    } = this.props;

    return (
      <Styled.Container ref={saveContainerRef}>
        <Styled.Content>
          <InnerComponent {...props} />
        </Styled.Content>
        <FailedUpdateReportDialog />
      </Styled.Container>
    );
  }
}
