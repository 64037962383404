import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { TwoDFullScreenMode } from 'persisted-storage/interfaces/state';
import { useCurrentProject } from 'unit-projects/hooks';

export function useCurrentProjectFullScreen(): [mode: TwoDFullScreenMode, setter: (mode: TwoDFullScreenMode) => void] {
  const currentProject = useCurrentProject();
  const currentProjectId = currentProject?.id.toString();
  const currentFullScreenMode = useSelector<State, TwoDFullScreenMode>(
    ({ persistedStorage: ps }) => {
      if (!currentProjectId) {
        return null;
      }
      const currentProjectMode = ps.projectTwoDFullScreenMode?.find(([id]) => id === currentProjectId);
      return currentProjectMode ? currentProjectMode[1] : null;
    },
  );
  const dispatch = useDispatch();
  const setFullScreenMode = useCallback((mode: TwoDFullScreenMode) => {
    if (!currentProjectId) {
      return;
    }
    dispatch(PersistedStorageActions.set2dFullScreenMode({ projectId: currentProjectId, type: mode }));
  }, [currentProjectId, dispatch]);

  return [currentFullScreenMode, setFullScreenMode];
}
