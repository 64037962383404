export const ScenariosNotificationKeys = {
  StatusUpdated: 'ScenarioStatusUpdated',
  DuplicatePassed: 'ScenarioDuplicatePassed',
  DuplicateFailed: 'ScenarioDuplicateFailed',
  UpdatePassed: 'ScenarioUpdatePassed',
  UpdateFailed: 'ScenarioUpdateFailed',
  Publish: 'ScenarioPublish',
  DeletedAllExceptActive: 'ScenarioDeletedAllExceptActive',
  StateChanged: 'ScenarioStateChanged',
};
