export enum IconsTypeEnum {
  Axis = 'Axis',
  Box = 'Box',
  Bricks = 'Bricks',
  Briefcase = 'Briefcase',
  Brush = 'Brush',
  Bucket = 'Bucket',
  Bulb = 'Bulb',
  Cement = 'Cement',
  CircularTubes = 'Circular_Tubes',
  Column = 'Column',
  Constructiongrid = 'Constructiongrid',
  Cord = 'Cord',
  Crane = 'Crane',
  Crane1 = 'Crane_1',
  Dimension = 'Dimension',
  Door = 'Door',
  Elbow = 'Elbow',
  ElectricPlug = 'Electric_Plug',
  Electric = 'Electric',
  Functions = 'Functions',
  Geomtery = 'Geomtery',
  Glass = 'Glass',
  Group = 'Group',
  Hammer = 'Hammer',
  Helmet = 'Helmet',
  Home = 'Home',
  Hook = 'Hook',
  IBeam = 'I_Beam',
  Jelly = 'Jelly',
  Key = 'Key',
  Layers = 'Layers',
  Layers2 = 'Layers2',
  Leaflet = 'Leaflet',
  Lock = 'Lock',
  Painting = 'Painting',
  Paperlist = 'Paperlist',
  Parquet = 'Parquet',
  Reinforcment = 'Reinforcment',
  Roller = 'Roller',
  Roof = 'Roof',
  Roof2Sides = 'Roof2Sides',
  Ruler = 'Ruler',
  Rulers = 'Rulers',
  Saw = 'Saw',
  Screw = 'Screw',
  Screwdriver = 'Screwdriver',
  Shovel = 'Shovel',
  Slab = 'Slab',
  Smile = 'Smile',
  Spatula = 'Spatula',
  Stair = 'Stair',
  Steelplate = 'Steelplate',
  Stone = 'Stone',
  Tiles = 'Tiles',
  Tube = 'Tube',
  Tubes = 'Tubes',
  Valve = 'Valve',
  Venttube = 'Venttube',
  Wall = 'Wall',
  Water = 'Water',
  Welding = 'Welding',
  Wheelbarrow = 'Wheelbarrow',
  Window = 'Window',
  Wood = 'Wood',
}
