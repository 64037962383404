import { Icons, MenuItem, MovableContextMenu } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

interface Props {
  position: { x: number, y: number };
  onClose: () => void;
  onEdit: () => void;
  onRemove: () => void;
}

const CommentMenuContentComponent: React.FC<Props> = ({
  position,
  onClose,
  onEdit,
  onRemove,
}) => {
  const onEditCallback = useCallback(() => {
    onEdit();
    onClose();
  }, []);
  const onRemoveCallback = useCallback(() => {
    onRemove();
    onClose();
  }, []);

  return (
    <MovableContextMenu
      x={position.x}
      y={position.y}
      onClose={onClose}
      zIndex={10001}
    >
      <MenuItem
        Icon={Icons.Edit2D}
        text='Edit'
        size='m'
        onClick={onEditCallback}
      />
      <MenuItem
        Icon={Icons.Delete}
        text='Remove'
        size='m'
        onClick={onRemoveCallback}
      />
    </MovableContextMenu>
  );
};

export const CommentMenuContent = React.memo(CommentMenuContentComponent);
