import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RenderIf } from 'common/components/render-if';
import { SvgSpinner } from 'common/components/svg-spinner';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { ClassificationChart } from 'components/charts';
import { ObjectStatisticType } from 'components/charts/interfaces/object-statistic-type';
import { ItemMenu, ItemMenuType } from 'components/controls/item-menu';
import { KreoConfirmationDialog } from 'components/dialog/kreo-confirmation-dialog';
import { AppUrls } from 'routes/app-urls';
import { ClassificationActions } from 'unit-projects/actions/creators/classification';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { ValidationActions } from 'unit-projects/actions/creators/validation';
import { isValidationStepGreaterThan, ValidationStep } from 'unit-projects/enums/validation-step';
import { ValidationStepState } from 'unit-projects/enums/validation-step-state';
import { StepWrapper, StepWrapperEngineApi } from '../step-wrapper';
import { Styled } from './styled';

const RECLASSIFY_DIALOG_NAME = 'RECLASSIFY_DIALOG_NAME';

interface DispatchProps {
  runClassification: () => void;
  getStatistic: (projectId: number) => void;
  openReclassifyDialog: () => void;
  approveInformationModeling: () => void;
  revertInformationModeling: () => void;
}

interface StateProps {
  loaded: boolean;
  statistic: ObjectStatisticType[];
  errors: number[];
  isPassed: boolean;
  isEditable: boolean;
  canApprove: boolean;
  canRevert: boolean;
  doesNeedCalculation: boolean;
}

interface OwnProps extends AbilityAwareProps {
  projectId: number;
}
interface Props extends StateProps, DispatchProps, OwnProps {
}

class InformationModelingComponent extends React.Component<Props> {
  private stepEngineApi: StepWrapperEngineApi = null;

  public componentDidMount(): void {
    if (this.props.isPassed) {
      this.props.getStatistic(this.props.projectId);
    }
  }

  public componentDidUpdate(): {} {
    const errors = this.props.errors;
    const hasErrors = errors && errors.length > 0;
    if (hasErrors && this.stepEngineApi) {
      this.stepEngineApi.paintEngine([], errors);
    }

    return null;
  }

  public render(): JSX.Element {
    const { projectId, canApprove, canRevert, approveInformationModeling, revertInformationModeling } = this.props;
    const onApprove = canApprove ? approveInformationModeling : null;
    const onDisapprove = canRevert ? revertInformationModeling : null;

    return (
      <Styled.PageContainer>
        <StepWrapper
          projectId={projectId}
          onApprove={onApprove}
          onDisapprove={onDisapprove}
          onEngineApiReady={this.initStepEngine}
        >
          <KreoConfirmationDialog
            onYes={this.props.runClassification}
            name={RECLASSIFY_DIALOG_NAME}
            yesText='Reclassify'
            noText='Cancel'
            title='Model Reclassification'
          >
            <span className='classification-step__dialog-text'>
              The model will be reclassified.
              All your <b>Classification</b> changes will be lost.
            </span>
          </KreoConfirmationDialog>
          <div className='classification-step__header'>
            <h3>Information Modeling</h3>
          </div>
          {this.renderChartContent()}
        </StepWrapper>
      </Styled.PageContainer>
    );
  }

  private renderChartContent(): React.ReactNode {
    const { projectId, doesNeedCalculation, isEditable } = this.props;
    const classificationUrlParams = { projectId: projectId.toString() };
    const disableClassify = !(isEditable || doesNeedCalculation);
    if (doesNeedCalculation) {
      return null;
    } else {
      const isStatisticAvailable = this.isStatisticAvailable(this.props);

      return (
        <Styled.Container>
          <RenderIf condition={isStatisticAvailable}>
            <div className='classification-step__chart-wrap'>
              <ItemMenu
                buttonSize='medium'
                className='classification-step__classify-menu'
                menu={this.getClassifyMenu(disableClassify)}
              />
              <ClassificationChart
                fixLink={AppUrls.costEstimate.project.informationModeling.edit.url(classificationUrlParams)}
                viewLink={AppUrls.costEstimate.project.informationModeling.view.url(classificationUrlParams)}
                isFixable={this.props.isEditable}
                data={this.props.statistic}
                onSelectIds={this.isolate}
                onDeselect={this.showAll}
                attentionStatus={this.hasUndefinedStatistic()}
                chartName={ValidationStep.Classification}
              />
            </div>
          </RenderIf>
          <RenderIf condition={!isStatisticAvailable}>
            <SvgSpinner size='middle' />
          </RenderIf>
        </Styled.Container>);
    }
  }

  @autobind
  private initStepEngine(api: StepWrapperEngineApi): void {
    this.stepEngineApi = api;
    this.stepEngineApi.clearEngine();
    this.stepEngineApi.paintEngine([], this.props.errors);
  }

  @autobind
  private isolate(ids: number[]): void {
    if (this.stepEngineApi) {
      this.stepEngineApi.isolate(ids);
    }
  }

  @autobind
  private showAll(): void {
    if (this.stepEngineApi) {
      this.stepEngineApi.showAll();
    }
  }

  private isStatisticAvailable({ isPassed, loaded }: Props): boolean {
    return isPassed && loaded;
  }

  @autobind
  private hasUndefinedStatistic(): boolean {
    const undefinedStatistic = this.props.statistic.find(s => s.name === 'Undefined');
    return undefinedStatistic && undefinedStatistic.amount > 0;
  }

  private getClassifyMenu(disabled: boolean): ItemMenuType[] {
    const { doesNeedCalculation, openReclassifyDialog, runClassification } = this.props;
    const action = doesNeedCalculation ? runClassification : openReclassifyDialog;
    return [
      {
        name: 'Remove all changes',
        action,
        disabled,
      },
    ];
  }
}


const mapStateToProps = (state: State, { ability }: OwnProps): StateProps => {
  const currentProject = state.projects.currentProject;
  const validationState = currentProject && currentProject.validationState;
  const isApprovable = (validationState.validationStep === ValidationStep.Classification
    && validationState.stepState === ValidationStepState.Approvable)
    || currentProject.hasUnstartedProducts;
  const isEditable = isApprovable && ability.can(Operation.Update, Subject.ValidationClassification);
  const isPassed = !!state.classification.statistic;
  const doesNeedCalculation = false;
  const canApprove = isApprovable && ability.can(Operation.Approve, Subject.ValidationClassification);
  const canRevert = isValidationStepGreaterThan(validationState.validationStep, ValidationStep.Classification)
    && ability.can(Operation.Revert, Subject.ValidationClassification);

  return {
    loaded: state.classification.statisticLoaded,
    statistic: state.classification.statistic,
    errors: state.classification.errorIds,
    doesNeedCalculation,
    isPassed,
    isEditable,
    canApprove,
    canRevert,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => {
  return {
    runClassification: () => dispatch(ProjectsActions.runClassification()),
    getStatistic: projectId => dispatch(ClassificationActions.getStatisticRequest(projectId)),
    openReclassifyDialog: () => dispatch(KreoDialogActions.openDialog(RECLASSIFY_DIALOG_NAME)),
    approveInformationModeling: () =>
      dispatch(ValidationActions.approveValidationStep(ValidationStep.Classification, ownProps.projectId)),
    revertInformationModeling: () =>
      dispatch(ValidationActions.revertToValidationStep(ValidationStep.Classification, ownProps.projectId, false)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const InformationModeling = withAbilityContext(connector(InformationModelingComponent));
