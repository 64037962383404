import * as React from 'react';

export const KreoIconSearchLarge = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <g
      id='Icons/24x24/Search'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <circle id='Oval' stroke='currentColor' strokeWidth='2' cx='11' cy='11' r='5' />
      <path d='M15,15 L18.5,18.5' id='Path-11' stroke='currentColor' strokeWidth='2' />
    </g>
  </svg>
);
