import { AnyAction } from 'redux';

import { ActionWith } from '../../../../common/interfaces/action-with';
import { ViewerModelBrowserItem, ViewerSelectItemPayload } from '../../../projects/actions/payloads/viewer';
import { ModelBrowserAction, PositionInfo } from '../../interfaces';
import { CommentModel } from '../../interfaces/comment';
import * as Actions from '../types';

function showHideComment(): AnyAction {
  return {
    type: Actions.VIEWER_SHOW_HIDE_COMMENTS,
  };
}

function showHideTree(): AnyAction {
  return {
    type: Actions.VIEWER_SHOW_HIDE_TREE,
  };
}

function showNewComment(): AnyAction {
  return {
    type: Actions.VIEWER_SHOW_NEW_COMMENT,
  };
}

function getComments(canViewAllComments: boolean): ActionWith<boolean> {
  return {
    type: Actions.VIEWER_GET_COMMENTS,
    payload: canViewAllComments,
  };
}


function getCommentsSuccess(data: CommentModel[]): ActionWith<CommentModel[]> {
  return {
    type: Actions.VIEWER_GET_COMMENTS_SUCCESS,
    payload: data,
  };
}

interface EnginePayload extends ActionWith<any> {
  engine: any;
}

function createComment(
  documentId: any,
  text: any,
  engine: any,
  bimHandleIds: any = [],
): EnginePayload {
  return {
    type: Actions.VIEWER_CREATE_COMMENT_REQUEST,
    engine,
    payload: {
      documentId,
      bimHandleIds,
      data: {
        text,
      },
    },
  };
}

function createCommentSuccessed(comment: CommentModel): ActionWith<CommentModel> {
  return {
    type: Actions.VIEWER_CREATE_COMMENT_SUCCESSED,
    payload: comment,
  };
}

function goToComment(comment: CommentModel): ActionWith<CommentModel> {
  return {
    type: Actions.VIEWER_GO_TO_COMMENT,
    payload: comment,
  };
}

function backToComments(): AnyAction {
  return {
    type: Actions.VIEWER_BACK_TO_COMMENTS,
  };
}

function onMarkerMode(): AnyAction {
  return {
    type: Actions.VIEWER_ON_MARKER_MODE,
  };
}

function setMarkerPos(position: PositionInfo): ActionWith<PositionInfo> {
  return {
    type: Actions.VIEWER_MARKER_POSITION,
    payload: position,
  };
}

function onAddSubcomment(id: any, value: any, parrent: any): ActionWith<any> {
  return {
    type: Actions.VIEWER_ON_ADD_SUBCOMMENT,
    payload: {
      id,
      value,
      parrent,
    },
  };
}

function addSubcommentSuccessed(parent: any, id: any, data: any): ActionWith<any> {
  return {
    type: Actions.VIEWER_ADD_SUBCOMMENT_SUCCESSED,
    payload: {
      id,
      parent,
      data,
    },
  };
}

function getDocumentInfo(documentId: number): ActionWith<number> {
  return {
    type: Actions.VIEWER_GET_DOCUMENT_INFO,
    payload: documentId,
  };
}

function getDocumentInfoSuccessed(data: any): ActionWith<number> {
  return {
    type: Actions.VIEWER_GET_DOCUMENT_INFO_SUCCESS,
    payload: data,
  };
}

function elementExpand(index: string): ActionWith<string> {
  return {
    type: Actions.VIEWER_TREE_ELEMENT_EXPAND,
    payload: index,
  };
}

function hide(identificator: string, ids: number[]): ModelBrowserAction {
  return {
    type: Actions.VIEWER_HIDE_ELEMENTS,
    payload: {
      identificator,
      ids,
    },
  };
}

function hideCommit(pathes: any): ActionWith<string[]> {
  return {
    type: Actions.VIEWER_HIDE_ELEMENTS_COMMIT,
    payload: pathes,
  };
}

function show(identificator: string, ids: number[]): ModelBrowserAction {
  return {
    type: Actions.VIEWER_SHOW_ELEMENTS,
    payload: {
      identificator,
      ids,
    },
  };
}

function showCommit(pathes: any): ActionWith<string[]> {
  return {
    type: Actions.VIEWER_SHOW_ELEMENTS_COMMIT,
    payload: pathes,
  };
}


function ghost(identificator: any, ids: any): ModelBrowserAction {
  return {
    type: Actions.VIEWER_GHOST_ELEMENTS,
    payload: {
      identificator,
      ids,
    },
  };
}

function ghostCommit(pathes: string[]): ActionWith<string[]> {
  return {
    type: Actions.VIEWER_GHOST_COMMIT,
    payload: pathes,
  };
}

function runSearch(query: string): ActionWith<string> {
  return {
    type: Actions.VIEWER_RUN_SEARCH,
    payload: query,
  };
}

function endSearch(): AnyAction {
  return {
    type: Actions.VIEWER_END_SEARCH,
  };
}

function selectItem(item: ViewerModelBrowserItem, identifier: string): ActionWith<ViewerSelectItemPayload> {
  return {
    type: Actions.VIEWER_SELECT_ITEM,
    payload: {
      item,
      identifier,
    },
  };
}

function clipbox(): AnyAction {
  return {
    type: Actions.VIEWER_ON_OF_CLIPBOX,
  };
}

function changeRenderMode(ids: number[], mode: string): any {
  return {
    type: Actions.VIEWER_CHANGE_RENDER_MODE,
    payload: {
      ids,
      mode,
    },
  };
}

function selectByIds(ids: number[], provider: any): any {
  return {
    type: Actions.VIEWER_SELECT_BY_ID,
    payload: ids,
    provider,
  };
}

function selectByIdsCommit(path: string[], provider: any): any {
  return {
    type: Actions.VIEWER_SELECT_BY_ID_COMMIT,
    payload: path,
    provider,
  };
}

function removeComment(id: any): any {
  return {
    type: Actions.VIEWER_REMOVE_COMMENT,
    payload: id,
  };
}

function removeCommentSuccess(id: any): any {
  return {
    type: Actions.VIEWER_REMOVE_COMMENT_SUCCESS,
    payload: id,
  };
}

function mapParents(): any {
  return {
    type: Actions.VIEWER_MAP_PARENTS,
  };
}

function dropAllInfo(): any {
  return {
    type: Actions.VIEWER_DROP_ALL_INFO,
  };
}

function resolveComment(comment: any, value: any): any {
  return {
    type: Actions.VIEWER_RESOLVE_COMMENT,
    payload: { comment, value },
  };
}

function updateElement(element: any, path: any): any {
  return {
    type: Actions.VIEWER_APPROVE_NODE,
    payload: { element, path },
  };
}

function approveState(): any {
  return {
    type: Actions.VIEWER_APPROVE_STATE,
  };
}

function updateCommentStatus(id: any, status: any): any {
  return {
    type: Actions.VIEWER_UPDATE_STATUS_COMMENT,
    payload: {
      id,
      status,
    },
  };
}

function updateCurrentCommentStatus(status: any): any {
  return {
    type: Actions.VIEWER_UPDATE_CURRENT_COMMENT_STATUS,
    payload: status,
  };
}

function withResolved(): any {
  return {
    type: Actions.VIEWER_FILTER_WITH_RESOLVED,
  };
}

function setClipBoxMode(mode: any): any {
  return {
    type: Actions.VIEWER_SET_CLIPBOX_MODE,
    payload: mode,
  };
}

function setIdsForIdentificator(identificator: any, ids: any): any {
  return {
    type: Actions.VIEWER_SET_IDS_FOR_ITEM,
    payload: {
      ids,
      identificator,
    },
  };
}

function setTimeMarker(position: any): any {
  return {
    type: Actions.VIEWER_ADD_TIME_MARKER,
    payload: position,
  };
}

function setContextPosition(position: any): any {
  return {
    type: Actions.VIEWER_SET_CONTEXT_POSITION,
    payload: position,
  };
}

function closeContext(): any {
  return {
    type: Actions.VIEWER_CLOSE_CONTEXT,
  };
}

function selectComment(ids: number[]): any {
  return {
    type: Actions.VIEWER_SELECT_COMMENT,
    payload: ids,
  };
}

function addSelectedItem(params: any): any {
  return {
    type: Actions.VIEWER_ADD_SELECT_ITEM,
    payload: params,
  };
}

function deselectItem(params: any): any {
  return {
    type: Actions.VIEWER_DESELECT_ITEM,
    payload: params,
  };
}

function readComment(id: any): any {
  return {
    type: Actions.VIEWER_READ_COMMENT,
    payload: id,
  };
}

function goToCommentById(id: any, project: any): any {
  return {
    type: Actions.VIEWER_GO_TO_COMMENT_BY_ID,
    payload: {
      id,
      project,
    },
  };
}

function setMobile(value: boolean): any {
  return {
    type: Actions.VIEWER_SET_MOBILE,
    payload: value,
  };
}

function setPanelsDimansion(value: any): any {
  return {
    type: Actions.VIEWER_SET_PANELS_DIMANSION,
    payload: value,
  };
}

export const ViewerActions = {
  onMarkerMode,
  backToComments,
  goToComment,
  createCommentSuccessed,
  createComment,
  showHideComment,
  showHideTree,
  showNewComment,
  getComments,
  getCommentsSuccess,
  setMarkerPos,
  onAddSubcomment,
  addSubcommentSuccessed,
  getDocumentInfo,
  getDocumentInfoSuccessed,
  elementExpand,
  hide,
  hideCommit,
  show,
  showCommit,
  setPanelsDimansion,
  setMobile,
  goToCommentById,
  readComment,
  deselectItem,
  setTimeMarker,
  addSelectedItem,
  selectComment,
  closeContext,
  setContextPosition,
  updateCommentStatus,
  updateCurrentCommentStatus,
  withResolved,
  setIdsForIdentificator,
  setClipBoxMode,
  removeCommentSuccess,
  mapParents,
  dropAllInfo,
  resolveComment,
  updateElement,
  approveState,
  clipbox,
  changeRenderMode,
  selectByIds,
  selectByIdsCommit,
  removeComment,
  ghost,
  ghostCommit,
  runSearch,
  endSearch,
  selectItem,
};
