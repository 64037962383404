import { Icons } from '@kreo/kreo-ui-components';
import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import * as React from 'react';

import { Styled } from './styled';


interface Props {
  onClick: () => void;
  isOpen?: boolean;
}

export const AddArrowButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Styled.Container
      onClick={onClick}
      onMouseDown={ConstantFunctions.stopEvent}
    >
      <Icons.DownSmall />
    </Styled.Container>
  );
};
