import { Chip } from '@material-ui/core';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { isArray, isEqual } from 'lodash';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import './material-chips-input.scss';

import { MaterialInput } from './material-input';

export interface MaterialChipsInputProps {
  label?: string;
  value?: string[];
  allowSame?: boolean;
  error?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (newValue: string[]) => void;
  onBlur?: (newValue: string[]) => void;
  validateValue?: (value: string, index: number, values: string[]) => boolean;
}

interface State {
  values: string[];
  isSave: boolean;
}

const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;
const SEMICOLON_KEY_CODE = 186;
const COMMA_KEY_CODE = 188;
const SLASH_KEY_CODE = 191;
const TAB_KEY_CODE = 9;
const BACK_SPACE_KEY_CODE = 8;

const INPUT_KEY_CODES =
  [ENTER_KEY_CODE, SPACE_KEY_CODE, SEMICOLON_KEY_CODE, COMMA_KEY_CODE, SLASH_KEY_CODE, TAB_KEY_CODE];
const SEPARATOR_REG_EXP = new RegExp('[\,\;\\s\/]', 'gm');
type EventHandler = (value: string[]) => void;

export class MaterialChipsInput extends React.Component<MaterialChipsInputProps, State> {
  private inputRef: HTMLInputElement;

  constructor(props: MaterialChipsInputProps) {
    super(props);

    this.state = {
      values: props.value || [],
      isSave: false,
    };
  }

  public static getDerivedStateFromProps(
    props: MaterialChipsInputProps,
    state: State,
  ): Partial<State> {
    if (!isEqual(state.values, props.value) && !state.isSave) {
      return {
        values: props.value || [],
      };
    }

    return {
      isSave: false,
    };
  }

  public render(): React.ReactNode {
    return (
      <MaterialInput
        label={this.props.label}
        isFloatingLabel={false}
        inputComponent={this.renderInput}
        onClick={this.onClick}
        error={this.props.error}
        displayBottomInformation={!!this.props.error}
        className='material-chips-input'
      />
    );
  }

  @autobind
  private renderInput(): React.ReactElement<InputBaseComponentProps> {
    return (
      <div className='material-chips-input__wrap'>
        {
          this.state.values && isArray(this.state.values) &&
          this.state.values.map((value, index) => {
            return (
              <Chip
                key={value}
                label={value}
                onDelete={this.makeDeleteHandler(value)}
                className={classNames(
                  'material-chips-input__chip',
                  { 'material-chips-input__chip--error': !this.props.validateValue(value, index, this.state.values) },
                )}
              />
            );
          })
        }
        <input
          ref={this.makeInputRef}
          className='material-chips-input__input'
          onKeyUp={this.onKeyUp}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
        />
      </div>
    );
  }

  @autobind
  private onFocus(event: React.FocusEvent<HTMLInputElement>): void {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  }

  @autobind
  private onClick(): void {
    this.inputRef.focus();
  }

  @autobind
  private makeInputRef(ref: HTMLInputElement): void {
    this.inputRef = ref;
  }

  @autobind
  private onKeyUp(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (INPUT_KEY_CODES.includes(event.keyCode)) {
      this.addElementAndTriggerEvent(event.currentTarget.value.trim(), this.props.onChange);
    }
  }

  @autobind
  private onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      event.stopPropagation();
      if (!event.currentTarget.value.length) {
        event.currentTarget.blur();
      }
    }
    const { values } = this.state;
    if (event.keyCode === BACK_SPACE_KEY_CODE && !event.currentTarget.value.length && values) {
      this.saveValueAndTriggerEvent(values.slice(0, -1), this.props.onChange);
    }
  }

  @autobind
  private onBlur(): void {
    this.addElementAndTriggerEvent(this.inputRef.value.trim(), this.props.onBlur);
  }

  private makeDeleteHandler(valueToDelete: string): () => void {
    return () => {
      this.saveValueAndTriggerEvent(this.state.values.filter(v => v !== valueToDelete), this.props.onChange);
    };
  }

  private addElementAndTriggerEvent(trimmedNewValue: string, eventHandler: EventHandler): void {
    if (!trimmedNewValue) {
      return;
    }

    const newValues = trimmedNewValue.split(SEPARATOR_REG_EXP);
    const updatedCollection =
      this.state.values.concat(
        newValues.filter(nv => this.props.allowSame || !this.state.values.includes(nv) && nv !== ''),
      );
    this.saveValueAndTriggerEvent(updatedCollection, eventHandler);

    this.inputRef.value = '';
  }

  private saveValueAndTriggerEvent(values: string[], eventHandler: EventHandler): void {
    this.setState({ values, isSave: true }, () => {
      if (eventHandler) {
        eventHandler(values);
      }
    });
  }
}

export const MaterialChipsInputField: React.FC<MaterialChipsInputProps> = (
  props: WrappedFieldProps & MaterialChipsInputProps,
): JSX.Element => {
  const {
    input,
    meta: { error, visited },
    ...rest } = props;

  const onChange = (value: string[]): void => {
    input.onChange(value);
  };

  const onBlur = (value: string[]): void => {
    input.onBlur(value);
  };

  return (
    <MaterialChipsInput
      {...input}
      error={visited ? error : null}
      onChange={onChange} // eslint-disable-line react/jsx-no-bind
      onBlur={onBlur} // eslint-disable-line react/jsx-no-bind
      {...rest}
    />
  );
};
