import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
`;

const DrawingContainer = styled.div`
  display: none;
`;

export const Styled = {
  Container,
  DrawingContainer,
};
