import classNames from 'classnames';
import * as React from 'react';

import './revit-tree-level-line.scss';

import { KreoIconTreeCollapsed, KreoIconTreeExpanded } from 'common/UIKit';

interface Props {
  expandable: boolean;
  expanded: boolean;
  level: number;
  firstInLvl: boolean;
  lastInLvl: boolean;
  parentIsLast: boolean;
  minimumLevel: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}


export class RevitTreeLevelLine extends React.Component<Props> {
  public render(): React.ReactNode {
    const { expandable, onClick, expanded } = this.props;
    return (
      <div className={classNames('revit-tree-level-line')}>
      {this.renderLines()}
        {expandable && (
          <div className='revit-tree-level-line__icon' onClick={onClick}>
            {expanded ? <KreoIconTreeExpanded /> : <KreoIconTreeCollapsed />}
          </div>
        )}
      </div>
    );
  }

  private renderLines(): React.ReactNode[] {
    const result = new Array<React.ReactNode>();
    const { level, minimumLevel, parentIsLast, lastInLvl, expanded, expandable } = this.props;
    for (let i = 0; i < level; i++) {
      const isLevel = i === level - 1;
      const empty = parentIsLast && !isLevel && i !== minimumLevel;
      let finish = isLevel && lastInLvl;
      if (level === 1 && !minimumLevel) {
        finish = finish && parentIsLast;
      }
      result.push(
        <div
          id={i.toString()}
          key={i}
          className={classNames('revit-tree-level-line__line', {
            first: isLevel,
            finish,
            empty,
          })}
        />,
      );
    }
    if (level === 0 || expanded) {
      result.push (
        <div
          id='last'
          key='last'
          className={classNames('revit-tree-level-line__line last', {
            leaf: !expandable,
            lvl0: level === 0,
            finish: lastInLvl,
          })}
        />,
      );
    }
    return result;
  }
}
