import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { FaqCaption } from 'common/enums/faq-caption';
import { HeaderContext } from 'common/enums/header-context';
import { LogoType } from 'common/enums/logo-type';
import { PageHeader } from 'common/interfaces/page-header';
import { AppUrls } from 'routes/app-urls';
import { PageLayout } from '../../../layouts/page-layout';
import { PlanProjectRouteParams } from '../../../routes/app-routes-params';
import { NotFoundPage } from '../../notfound/page';
import { ValidationStepName } from '../enums/validation-step-name';
import { ActivityAssignmentEditPage } from '../pages/activity-assignment-edit';
import { ActivityAssignmentViewPage } from '../pages/activity-assignment-view';
import { ActivityAssignmentDatabaseRoutes } from './activity-assignment-database';


interface State {
  header: PageHeader;
}

interface Props extends RouteComponentProps<PlanProjectRouteParams> {}

export class ActivityAssignmentRoutes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      header: this.getActivityAssignmentHeader(),
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.match.params.projectId !== prevProps.match.params.projectId) {
      this.setState({
        header: this.getActivityAssignmentHeader(),
      });
    }
  }

  public render(): JSX.Element {
    const projectId = this.props.match.params.projectId;
    const indexValidationPageUrl = AppUrls.plan.project.validation.index.url({
      projectId: projectId.toString(),
    });

    return (
      <Switch>
        <PageLayout
          exact={true}
          metaTitle='Edit Activity Assignment'
          path={AppUrls.plan.project.validation.editActivityAssignment.path}
          component={ActivityAssignmentEditPage}
          header={this.state.header}
          subject={Subject.ValidationActivityAssignment}
          operation={Operation.Update}
          redirectUrl={indexValidationPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle='View Activity Assignment'
          path={AppUrls.plan.project.validation.viewActivityAssignment.path}
          component={ActivityAssignmentViewPage}
          header={this.state.header}
          subject={Subject.ValidationActivityAssignment}
          redirectUrl={indexValidationPageUrl}
        />
        <Route
          path={AppUrls.plan.project.validation.activityAssignmentDb.path}
          component={ActivityAssignmentDatabaseRoutes}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  private getActivityAssignmentHeader(): PageHeader {
    return {
      context: HeaderContext.ProjectExtension,
      color: 'white',
      logoType: LogoType.LogoBlackLight,
      disableToggle: true,
      title: 'Activity Assignment',
      faqCaption: FaqCaption.ActivityAssignment,
      backUrl: AppUrls.plan.project.validation.step.url({
        projectId: this.props.match.params.projectId,
        step: ValidationStepName.ActivityAssignment,
      }),
    };
  }
}
