import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { ContextObserver, DrawingContextObserver } from '../../drawings-contexts';
import { MagicSearchState } from '../../interfaces';

export function useMagicSearchContextObserver(): ContextObserver<MagicSearchState> {
  const magicSearchState = useSelector<State, MagicSearchState>(s => s.drawings.magicSearch);
  const observer = useMemo(() => new DrawingContextObserver<MagicSearchState>(magicSearchState), []);
  useEffect(() => {
    observer.updateContext(magicSearchState);
  }, [magicSearchState]);
  return observer;
}
