import * as React from 'react';

import './index.scss';

import { Menu } from './menu';
import { Toggle } from './toggle';


interface Props {
  resolved: boolean;
  commentNumber?: number;
  canChange: boolean;
  onResolve: (value: boolean) => void;
  remove?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const TopPanelContent: React.FC<Props> = (props) => {
  const title = props.commentNumber ? `Comment #${props.commentNumber}` : 'Comments';
  return (
    <div className='viewer-comment-top-panel'>
        <span className='viewer-comment-top-panel__title'>{title}</span>
        {props.canChange ? (<>
          <Toggle
            checked={props.resolved}
            onResolve={props.onResolve}
            text={props.commentNumber ? 'Archive' : 'Show Archived'}
            controlName='show-resolved-toggle'
          />
          {props.commentNumber ? <Menu remove={props.remove} /> : null}
        </>) : null}
    </div>
  );
};
