import { KreoColors } from 'common/enums/kreo-colors';
import { GroupType } from '../../../utils/gantt-chart';

export const colorGenerator = {
  generateOffColor: (type: GroupType, isCritical: boolean) => {
    if (isCritical) {
      return '#FEC7C0';
    } else {
      switch (type) {
        case GroupType.WorkPackage:
          return '#7799D4';
        case GroupType.ActivityGroup:
          return '#91C4ED';
        case GroupType.Activity:
        default:
          return KreoColors.gray6;
      }
    }
  },
  generateMainColor: (type: GroupType, isCritical: boolean) => {
    if (isCritical) {
      return '#FF7D6C';
    } else {
      switch (type) {
        case GroupType.WorkPackage:
          return '#3A60A1';
        case GroupType.ActivityGroup:
          return '#5892BF';
        case GroupType.Activity:
        default:
          return KreoColors.gray8;
      }
    }
  },
};
