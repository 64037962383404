import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './employee-select-dialog.scss';

import { SubscriptionType } from 'common/constants/subscription';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialog } from 'common/UIKit';
import { AdminCompaniesActions } from '../actions/creators/companies';
import { ShortUser } from '../interfaces/short-user';
import { AttachedUser, DialogUserItem } from './items/dialog-user-item';
import { Feed } from '.';

export const ATTACH_EMPLOYEE_TO_COMPANY_DIALOG_NAME = 'attachEmployeeToCompany';

interface ReduxProps {
  count: number;
  status: RequestStatus;
  data: ShortUser[];
  companyId: number;
  subscriptionType: SubscriptionType;
  isOpen: boolean;
  attachedEmployees: AttachedUser[];
}

interface ReduxActions {
  loadMore: (skip: number, search: string) => void;
  attach: (companyId: number, subscriptionType: SubscriptionType, user: string, roleId: number) => void;
  detach: (companyId: number, subscriptionType: SubscriptionType, user: string) => void;
  changeRole: (companyId: number, subscriptionType: SubscriptionType, user: string, roleId: number) => void;
}

interface Props extends ReduxProps, ReduxActions {
}

class EmployeeSelectDialogComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <KreoDialog
        name={ATTACH_EMPLOYEE_TO_COMPANY_DIALOG_NAME}
        title='Attach Employees to Subscription'
        isModal={true}
      >
        <div className='employee-select-dialog'>
          {
            this.props.isOpen &&
            <Feed
              className='employee-select-dialog__feed'
              searchPlaceholder='First name, last name, email...'
              data={this.props.data}
              count={this.props.count}
              status={this.props.status}
              renderItem={this.renderItem}
              loadMore={this.props.loadMore}
            />
          }
        </div>
      </KreoDialog>
    );
  }

  @autobind
  private renderItem(item: ShortUser): React.ReactNode {
    return (
      <DialogUserItem
        key={item.guid}
        shortUser={item}
        onAttach={this.attach}
        onDetach={this.detach}
        onChangeRole={this.changeRole}
        attached={this.props.attachedEmployees.find((a) => a.guid === item.guid)}
      />
    );
  }

  @autobind
  private attach(userGuid: string, selectedRoleId: number): void {
    const { companyId, subscriptionType } = this.props;
    this.props.attach(companyId, subscriptionType, userGuid, selectedRoleId);
  }

  @autobind
  private detach(userGuid: string): void {
    const { companyId, subscriptionType } = this.props;
    this.props.detach(companyId, subscriptionType, userGuid);
  }

  @autobind
  private changeRole(userGuid: string, selectedRoleId: number): void {
    const { companyId, subscriptionType } = this.props;
    this.props.changeRole(companyId, subscriptionType, userGuid, selectedRoleId);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const companiesState = state.admin.companies;
  const dialogData = state.dialog[ATTACH_EMPLOYEE_TO_COMPANY_DIALOG_NAME];
  const companyId: number = dialogData && dialogData.companyId;
  const subscriptionType = dialogData && dialogData.subscriptionType;
  const company = state.admin.companies.data.find((c) => c.id === companyId);
  const subscription = company && company.subscriptions[subscriptionType];

  let attachedEmployees: AttachedUser[] = [];
  if (company != null) {
    attachedEmployees = subscription
      ? subscription.employees.map((e) => ({
        guid: e.guid,
        roleCode: e.roleCode,
      }))
      : [];
  }

  return {
    count: companiesState.employeeFeed.count,
    data: companiesState.employeeFeed.data,
    status: companiesState.employeeFeed.status,
    companyId,
    isOpen: ATTACH_EMPLOYEE_TO_COMPANY_DIALOG_NAME in state.dialog,
    attachedEmployees,
    subscriptionType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadMore: (skip: number, search: string) =>
      dispatch(AdminCompaniesActions.loadEmployees({ skip, search })),
    attach: (companyId, subscriptionType, userGuid, roleId) =>
      dispatch(AdminCompaniesActions.attachEmployee({
        companyId,
        employeeGuid: userGuid,
        roleId,
        subscriptionType,
      })),
    detach: (companyId, subscriptionType, userGuid) =>
      dispatch(AdminCompaniesActions.detachEmployee({
        companyId,
        employeeGuid: userGuid,
        subscriptionType,
      })),
    changeRole: (companyId, subscriptionType, userGuid, roleId) =>
      dispatch(AdminCompaniesActions.changeRole({
        companyId,
        employeeGuid: userGuid,
        roleId,
        subscriptionType,
      })),
  };
};

export const EmployeeSelectDialog = connect(mapStateToProps, mapDispatchToProps)(EmployeeSelectDialogComponent);
