import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';


export const KreoIconTreeExpanded: React.FC = () => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
    >
      <g
        id='Icons/20x20/Tree_Expanded'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          // eslint-disable-next-line max-len
          d='M10,0 C15.5228475,-1.01453063e-15 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 6.76353751e-16,15.5228475 0,10 C-6.76353751e-16,4.4771525 4.4771525,1.01453063e-15 10,0 Z'
          fill={KreoColors.blue2}
        />
        <path
          // eslint-disable-next-line max-len
          d='M14,9 C14.5522847,9 15,9.44771525 15,10 C15,10.5522847 14.5522847,11 14,11 L6,11 C5.44771525,11 5,10.5522847 5,10 C5,9.44771525 5.44771525,9 6,9 L14,9 Z'
          id='Combined-Shape'
          fill={KreoColors.blue}
          fillRule='nonzero'
        />
      </g>
    </svg>);
};
