import styled from 'styled-components';

import { ConfirmationButtonsPanelStyled } from 'common/components/button-sets';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Avatar = styled.div`
  display: flex;
  justify-content: end;
`;

const TextArea = styled.div`
  width: 205px;
  margin-left: 15px;
`;

const ButtonsContainer = styled.div`
  padding-left: 45px;
  ${ConfirmationButtonsPanelStyled.Container} {
    margin-bottom: 0px;
  }
`;

export const Styled = {
  Container,
  Avatar,
  TextArea,
  ButtonsContainer,
};
