import * as React from 'react';

export const KreoIconPerspective = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <g
      id='Icons/24x24/Perspective_Normal'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M7,8.5 L17,8.5' className='icon__color-light' stroke='currentColor' fillRule='nonzero' />
      <path d='M6,13.5 L18,13.5' className='icon__color-light' stroke='currentColor' fillRule='nonzero' />
      <path
        d='M6.75,12.25 L21.25,11.25'
        className='icon__color-light'
        stroke='currentColor'
        fillRule='nonzero'
        transform='translate(14.000000, 11.750000) rotate(-270.000000) translate(-14.000000, -11.750000) '
      />
      <path
        d='M2.75,11.25 L17.25,12.25'
        className='icon__color-light'
        stroke='currentColor'
        fillRule='nonzero'
        transform='translate(10.000000, 11.750000) rotate(-270.000000) translate(-10.000000, -11.750000) '
      />
      <path
        // eslint-disable-next-line max-len
        d='M9.13960781,4.5 L14.8603922,4.5 C15.8137542,4.5 16.6345839,5.17291934 16.8215535,6.10776773 L19.0215535,17.1077677 C19.2381774,18.1908872 18.535744,19.2445375 17.4526245,19.4611614 C17.3234781,19.4869906 17.1920962,19.5 17.0603922,19.5 L6.93960781,19.5 C5.83503831,19.5 4.93960781,18.6045695 4.93960781,17.5 C4.93960781,17.368296 4.95261718,17.2369141 4.97844645,17.1077677 L7.17844645,6.10776773 C7.36541613,5.17291934 8.18624577,4.5 9.13960781,4.5 Z'
        stroke='currentColor'
      />
    </g>
  </svg>
);
