import autobind from 'autobind-decorator';
import * as paper from 'paper';
import {
  DrawingsCanvasColors,
  DrawingsCanvasConstants,
} from 'common/components/drawings/constants/drawing-canvas-constants';
import { ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import {
  DrawingsCustomSnappingModes,
  DrawingsSnappingModes,
} from 'common/components/drawings/enums/drawing-snapping-modes';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { DrawingsGeometryEntity } from '../base';

interface DrawingsSnappingPointConfig {
  mode: DrawingsSnappingModes;
  position: paper.Point;
  layer: paper.Layer;
  observableRenderParameters: ContextObserverWithPrevious<DrawingsRenderParams>;
  snappingSize: number;
}

export class DrawingsSnappingPoint implements DrawingsGeometryEntity {
  private _config: DrawingsSnappingPointConfig;
  private _point: paper.Item;

  constructor(config: DrawingsSnappingPointConfig) {
    this._config = config;
    this._config.observableRenderParameters.subscribe(this.updateRenderParams);
    this.render(this._config.observableRenderParameters.getContext().zoom);
  }

  public set snappingSize(value: number) {
    if (this._config.snappingSize === value) {
      return;
    }
    this._config.snappingSize = value;
    if (this._point) {
      this._point.remove();
      this._point = null;
    }
    this.render(this._config.observableRenderParameters.getContext().zoom);
  }

  public set mode(value: DrawingsSnappingModes) {
    if (this._config.mode === value) {
      return;
    }

    this._config.mode = value;
    if (this._point) {
      this._point.remove();
      this._point = null;
    }
    this.render(this._config.observableRenderParameters.getContext().zoom);
  }

  public set position(value: paper.Point) {
    if (this._point) {
      this._point.position = value;
    }
    this._config.position = value;
  }

  public destroy(): void {
    if (this._point) {
      this._point.remove();
    }
    this._config.observableRenderParameters.unsubscribe(this.updateRenderParams);
  }

  private render(zoom: number): void {
    const snappingSize = this._config.snappingSize || DrawingsCanvasConstants.defaultSnappingSize;
    const halfSize = snappingSize / (2 * zoom);
    switch (this._config.mode) {
      case Core.PDFNet.GeometryCollection.SnappingMode.e_PathEndpoint: {
        const start = this._config.position.subtract(halfSize);
        const size = new paper.Size(snappingSize, snappingSize)
          .divide(zoom);
        const path = new paper.Path.Rectangle(start, size);
        this._point = path;
        break;
      }
      case Core.PDFNet.GeometryCollection.SnappingMode.e_PointOnLine: {
        const start = this._config.position.subtract(halfSize);
        const size = new paper.Size(snappingSize, snappingSize)
          .divide(zoom);
        const path = new paper.Path.Rectangle(start, size);
        path.fillColor = DrawingsCanvasColors.pointColor;
        this._point = path;
        break;
      }
      case Core.PDFNet.GeometryCollection.SnappingMode.e_LineIntersection: {
        const l1 = new paper.Path.Line(this._config.position.add(halfSize), this._config.position.subtract(halfSize));
        const l2 = new paper.Path.Line(
          new paper.Point(this._config.position.x + halfSize, this._config.position.y - halfSize),
          new paper.Point(this._config.position.x - halfSize, this._config.position.y + halfSize),
        );
        const group = new paper.Group([l1, l2]);
        this._point = group;
        break;
      }
      case Core.PDFNet.GeometryCollection.SnappingMode.e_LineMidpoint: {
        const path = new paper.Path(
          [
            new paper.Point(this._config.position.x, this._config.position.y - halfSize),
            this._config.position.add(halfSize),
            new paper.Point(this._config.position.x - halfSize, this._config.position.y + halfSize),
          ],
        );
        path.fillColor = DrawingsCanvasColors.pointColor;
        this._point = path;
        break;
      }
      case DrawingsCustomSnappingModes.DynamicGuideline: {
        const circle = new paper.Path.Circle(this._config.position, halfSize);
        circle.fillColor = DrawingsCanvasColors.pointColor;
        this._point = circle;
        break;
      }
      default:
    }
    if (this._point) {
      this._point.strokeWidth = DrawingsCanvasConstants.snappingStroke / zoom;
      this._point.strokeColor = DrawingsCanvasColors.pointColor;
      this._point.addTo(this._config.layer);
    }
  }

  @autobind
  private updateRenderParams({ zoom }: DrawingsRenderParams): void {
    if (this._point) {
      this._point.remove();
      this.render(zoom);
    }
  }
}
