import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'redux-form';

import './database-activity-variant-measurement-item.scss';

import { MultiLevelDropDownMenu } from 'common/components/multi-level-drop-down-menu';
import { State as ReduxState } from 'common/interfaces/state';
import { MaterialMenuItem, MaterialSelectField, MaterialSelectProps } from 'common/UIKit';
import { PreparedSearchQuery } from 'common/utils/string-utils';
import { Input } from '../../../../components/controls/inputs';
import { FieldRequired } from '../../../../components/dialog/validators';
import {
  ExtractorFunctionModel,
  ExtractorFunctionModelDropDown,
  ExtractorFunctionOption,
  ExtractorFunctionParameter,
  ExtractorFunctionParameterOption,
  MeasurementModel,
  UnitModel,
} from '../../interfaces/data';
import { ExtractorParameterForm } from '../../interfaces/rest-data';
import { ExtractorFunctionHelper } from '../../utils/extractor-functions-helper';
import {
  DatabaseActivityVariantMeasurementParameters,
  DatabaseActivityVariantMeasurementParametersProps,
} from '../database-activity-variant-measurement-parameters/database-activity-variant-measurement-parameters';
import { DatabaseEntityNamedHeader } from '../database-entity-named-header';
import { DatabaseActivityVariantSelectInner } from './database-activity-variant-select-inner';

const unitRequired = FieldRequired('Unit');

interface ReduxProps {
  units: UnitModel[];
  extractorFunctions: ExtractorFunctionModel[];
  measurementToUnits: Record<string, UnitModel[]>;
  measurementsMap: Record<string, MeasurementModel>;
  unitMap: Record<number, UnitModel>;
}

interface Props extends ReduxProps {
  fieldName?: string;
  unitId: number;
  extractorFunction: string;
  extractorFunctionParameters: ExtractorFunctionParameter[];
  readonly: boolean;
  onFunctionChange: (extractorFunction: ExtractorFunctionModel) => void;
  onUnitChange: (unitId: number) => void;
  onFunctionParametersChange: (parameters: ExtractorParameterForm[]) => void;
}

interface State {
  extractorFunction: ExtractorFunctionModel;
  extractorFunctions: ExtractorFunctionOption[];
  units: UnitModel[];
  parameters: ExtractorFunctionParameterOption[];
}

class DatabaseActivityVariantMeasurementItemComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getState(props);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (
      !isEqual(prevProps.extractorFunctions, this.props.extractorFunctions) ||
      !isEqual(prevProps.measurementToUnits, this.props.measurementToUnits) ||
      !isEqual(prevProps.units, this.props.units) ||
      prevProps.extractorFunction !== this.props.extractorFunction
    ) {
      this.setState(this.getState(this.props));
    }
  }

  public render(): JSX.Element {
    const { unitId, extractorFunction } = this.props;
    return (
      <div className='measurement-right-panel'>
        <DatabaseEntityNamedHeader
          name='Method of Measurement'
          info='The way the activity should be measured'
        />
        <div className='measurement-right-panel__content'>
          <MultiLevelDropDownMenu
            options={this.state.extractorFunctions}
            compare={MultiLevelDropDownMenu.defaultSearchComparer}
            value={extractorFunction}
            onSelect={this.onExtractorFunctionChange}
            className='measurement-right-panel__function'
            optionContentRenderer={this.renderOption}
            disabled={this.props.readonly}
            displayModeSelect={true}
            label='Function'
          >
            {extractorFunction ? extractorFunction : 'Select Function'}
            {extractorFunction ? (
              <Input
                className='visually-hidden'
                value={extractorFunction}
                onChange={this.onExtractorFunctionChange}
              />
            ) : null}
          </MultiLevelDropDownMenu>
          <Field<MaterialSelectProps>
            name={this.nestedFieldName('unitId')}
            component={MaterialSelectField}
            className='measurement-right-panel__unit'
            value={unitId || ''}
            placeholder='Select Unit'
            label='Unit'
            disabled={this.props.readonly || !extractorFunction}
            onChange={this.onUnitChange}
            validate={unitRequired}
          >
            {this.getUnitOptions()}
          </Field>
        </div>
        <FieldArray<DatabaseActivityVariantMeasurementParametersProps>
          name={this.nestedFieldName('parameters')}
          component={DatabaseActivityVariantMeasurementParameters}
          readonly={this.props.readonly}
          values={this.props.extractorFunctionParameters}
          parameters={this.state.parameters}
          onChange={this.props.onFunctionParametersChange}
          unitMap={this.props.unitMap}
        />
      </div>
    );
  }

  private renderOption(
    option: ExtractorFunctionModelDropDown,
    query: PreparedSearchQuery,
    _isSelected: boolean,
  ): React.ReactNode {
    const name = MultiLevelDropDownMenu.defaultSearchQueryHighlighter(option.name, query);
    return <DatabaseActivityVariantSelectInner option={option} name={name} />;
  }

  private getUnitOptions(): JSX.Element[] {
    return this.state.units && this.state.units
      .map(unit => {
        return (
          <MaterialMenuItem key={unit.id} value={unit.id}>
            {unit.acronym}
          </MaterialMenuItem>
        );
      });
  }

  @autobind
  private onUnitChange(_: React.SyntheticEvent, unitId: number): void {
    this.props.onUnitChange(unitId);
  }

  @autobind
  private onExtractorFunctionChange(value: string): void {
    this.props.onFunctionChange(this.props.extractorFunctions.find(x => x.id === value));
  }

  private nestedFieldName(fieldName: string): string {
    return this.props.fieldName
      ? `${this.props.fieldName}.${fieldName}`
      : fieldName;
  }

  private getState(props: Props): State {
    const extractor = ExtractorFunctionHelper.getExtractorFunction(props.extractorFunction, props.extractorFunctions);

    return {
      extractorFunction: extractor,
      extractorFunctions: ExtractorFunctionHelper.getMeasurementExtractorFunctions(
        props.extractorFunction, props.extractorFunctions, props.measurementToUnits, props.measurementsMap),
      units: ExtractorFunctionHelper.getExtractorFunctionUnits(extractor, props.measurementToUnits),
      parameters: ExtractorFunctionHelper.getExtractorFunctionParameters(
        extractor, props.extractorFunctions, props.measurementToUnits, props.measurementsMap),
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    units: state.database.currentDatabase.units,
    extractorFunctions: state.database.currentDatabase.functions,
    measurementToUnits: state.database.currentDatabase.measurementToUnits,
    measurementsMap: state.database.currentDatabase.measurementsMap,
    unitMap: state.database.currentDatabase.unitMap,
  };
};

export const DatabaseActivityVariantMeasurementItem =
  connect(mapStateToProps)(DatabaseActivityVariantMeasurementItemComponent);
