import { numberUtils } from 'common/utils/number-utils';
import { CurrencyModel } from '../../units/account/interfaces/currency-model';

const DEFAULT_CURRENCY_FORMAT = '0.[00]';

export class KreoI18n {
  public readonly currency: CurrencyModel;

  public constructor(currency: CurrencyModel) {
    this.currency = currency;
  }

  /**
   * I18n.c - currency. Outputs formatted monetary value
   * @param monetaryValue Monetary value to output
   * @param customFormat Format string (using numeral.js syntax)
   */
  public c = (monetaryValue: React.ReactText, customFormat?: string): string => {
    const formattedValue = numberUtils.getNumeralFormatter(monetaryValue)
      .format(customFormat || DEFAULT_CURRENCY_FORMAT);

    return `${this.currency.symbol}${formattedValue}`;
  };
}
