import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';
import { CEActivityAssignmentActions } from 'unit-cost-estimate/actions/creators';
import { CEActivityAssignmentUtils } from 'unit-cost-estimate/utils/ce-activity-assignment-utils';
import { RevitTreeBaseItem } from 'unit-projects/components/revit-tree-base-item';
import { RevitTreeErrorAmount } from 'unit-projects/components/revit-tree-error-amount';
import { RevitTreeLevel } from 'unit-projects/enums/revit-tree-level';
import './activity-assignment-revit-tree-item.scss';


interface OwnProps {
  expandable: boolean;
  expanded: boolean;
  title: string;
  level: RevitTreeLevel;
  path: string;
  start: number;
  end: number;
  error: boolean;
  all: number;
  errors: number;
  isManuallyChanged?: boolean;
  hasDiff?: boolean;
}


interface StateProps {
  selected: boolean;
  searched: boolean;
}

interface DisaptchProps {
  onExpand: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface Props extends StateProps, DisaptchProps, OwnProps {

}

class ActivityAssignmentRevitTreeItemComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      selected,
      error,
      expandable,
      expanded,
      onExpand,
      onClick,
      title,
      errors,
      all,
      level,
      searched,
      isManuallyChanged,
      hasDiff,
    } = this.props;
    const className = classNames(
      'activity-assignment-revit-tree-item',
      {
        'activity-assignment-revit-tree-item--selected': selected,
        'activity-assignment-revit-tree-item--searched': searched,
        'activity-assignment-revit-tree-item--error': error && !expandable,
        'activity-assignment-revit-tree-item--changed-manually': isManuallyChanged,
        'activity-assignment-revit-tree-item--diff': hasDiff,
      },
    );
    return (
      <RevitTreeBaseItem
        className={className}
        expandable={expandable}
        expanded={expanded}
        onExpand={onExpand}
        title={title}
        level={level + 1}
        onClick={onClick}
        onDoubleClick={onExpand}
      >
        { expandable && <RevitTreeErrorAmount error={errors} all={all} />}
      </RevitTreeBaseItem>
    );
  }
}

function mapStateToProps({ ceActivityAssignment }: State, { path, level }: OwnProps): StateProps {
  return {
    searched: ceActivityAssignment.searchMode &&
      ceActivityAssignment.searchedPathes.includes(path.replace('tree.', '')),
    selected: CEActivityAssignmentUtils.itemIsSelected(ceActivityAssignment, path, level + 1),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<AnyAction>,
  { path, level, title, expandable }: OwnProps,
): DisaptchProps {
  return {
    onExpand: (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      dispatch(CEActivityAssignmentActions.expandItem(path));
    },
    onClick: (e: React.MouseEvent<HTMLDivElement>) => {
      if (expandable) {
        dispatch(CEActivityAssignmentActions.selectExpandableTreeNode(path));
      } else {
        e.stopPropagation();
        if (level === RevitTreeLevel.Element) {
          dispatch(CEActivityAssignmentActions.showWorksFromLayer({
            path,
            endElementTitle: title,
          }));
        } else {
          dispatch(CEActivityAssignmentActions.showWorksFromType(path));
        }
      }
    },
  };
}

const connector = connect<StateProps, DisaptchProps, OwnProps>(mapStateToProps, mapDispatchToProps);
export const ActivityAssignmentRevitTreeItem = connector(ActivityAssignmentRevitTreeItemComponent);
