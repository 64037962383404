import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { getArrangementCount } from '2d/units/get-arragement-count';
import { DrawingsLayoutApi } from 'common/components/drawings';
import { ExcelTableApi } from 'common/components/excel-table';
import { ReferenceHelper } from 'common/components/excel-table/utils';
import { FormulaToolbarApi } from 'common/components/formula-toolbar';
import { State } from 'common/interfaces/state';
import { SheetUpdateCells } from '../../actions/payloads';
import { Arrangement } from '../../constants';
import { ReportPage, ReportPageType, UpdateCellForm } from '../../interfaces';
import { CellDataStore, SheetDataStore } from '../../units/cell-data-controllers/report-cell-data-store';
import { FocusChangeData } from './2d-sheet';
import { SpreadSheet, ViewTable } from './components';
import { ViewSplitter } from './view-splitter';

interface OwnProps {
  drawingsLayoutApi: DrawingsLayoutApi;
  saveApi: (
    ref: ExcelTableApi,
    sheetId: string,
    updateColumns: (payload: Array<Record<string, string>>) => void,
  ) => void;
  referenceReader: ReferenceHelper;
  projectId: string;
  formulaBarApi: FormulaToolbarApi;
  getTableBodySize: () => { height: number, width: number };
  onCellRangeChanged: (ranges: Ag.CellRange[]) => void;
  setFormulaBarValue: (value: string) => void;
  updateCells: (payload: Array<SheetUpdateCells<UpdateCellForm[]>>) => void;
  handlePaste: (cvs?: string) => Promise<void>;
  onFocusChange: (data: FocusChangeData) => void;
  reportEditPermission: boolean;
  selectCellValue: (sheetId: string, columnId: string, rowId: string) => string | number;
  selectCellData: (sheetId: string, columnId: string, rowId: string) => string;
  selectSheetData: (id: string) => SheetDataStore;
  addNewRows: (sheetId: string, count: number) => void;
  setColumnWidth: (sheetId: string, payload: Array<{ columnId: string, width: number }>) => void;
  addNewColumns: (sheetId: string, count: number) => void;
  onSheetReady: (sheetId: string) => void;
  getSheetDataStore: () => CellDataStore;
  updateCellDataOnFill: (sheetId: string, cells: UpdateCellForm[]) => void;
  isValidCell: (cellId: string) => boolean;
}


interface StateToProps {
  showSheetIds: string[];
  type: Arrangement;
  reportPages: ReportPage[];
}

type Props = OwnProps & StateToProps;

class TwoDReportComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const tables = this.getTables();

    return (
      <ViewSplitter
        tables={tables}
        type={this.props.type}
        getTableBodySize={this.props.getTableBodySize}
      />
    );
  }

  @autobind
  private getTables(): JSX.Element[] {
    const tables = [];
    const reportCount = getArrangementCount(this.props.type);
    for (const id of this.props.showSheetIds) {
      const report = this.props.reportPages.find(r => r.id === id);
      if (report && tables.length < reportCount) {
        tables.push(this.getContent(report));
      }
    }
    return tables;
  }

  @autobind
  private getContent(report: ReportPage): JSX.Element {
    switch (report.type) {
      case ReportPageType.SpreadSheet:
        return this.getSpreadSheet(report.id);
      case ReportPageType.MeasureView:
        return this.getMeasureView(report);
      default:
        throw new Error('Invalid report type');
    }
  }

  @autobind
  private getSpreadSheet(id: string): JSX.Element {
    const sheet = this.props.reportPages.find(r => r.id === id);
    return (
      <SpreadSheet
        key={id}
        sheetId={id}
        sheetName={sheet.name}
        drawingsLayoutApi={this.props.drawingsLayoutApi}
        saveApi={this.props.saveApi}
        referenceReader={this.props.referenceReader}
        onCellRangeChanged={this.props.onCellRangeChanged}
        setFormulaBarValue={this.props.setFormulaBarValue}
        updateCells={this.props.updateCells}
        projectId={this.props.projectId}
        handlePaste={this.props.handlePaste}
        onFocusChange={this.props.onFocusChange}
        formulaBarApi={this.props.formulaBarApi}
        reportEditPermission={this.props.reportEditPermission}
        selectCellValue={this.props.selectCellValue}
        selectCellData={this.props.selectCellData}
        selectSheetData={this.props.selectSheetData}
        onSheetReady={this.props.onSheetReady}
        getSheetDataStore={this.props.getSheetDataStore}
        updateCellDataOnFill={this.props.updateCellDataOnFill}
        isValidCell={this.props.isValidCell}
        setColumnWidth={this.props.setColumnWidth}
        addNewRows={this.props.addNewRows}
        addNewColumns={this.props.addNewColumns}
      />
    );
  }

  @autobind
  private getMeasureView(report: ReportPage): JSX.Element {
    return (<ViewTable
      drawingsLayoutApi={this.props.drawingsLayoutApi}
      report={report}
    />);
  }
}

function mapStateToProps(state: State): StateToProps {
  return {
    showSheetIds: state.twoD.showSheetIds,
    type: state.twoD.tableViewType,
    reportPages: state.twoD.reportPages,
  };
}

export const TwoDReport = connect(mapStateToProps)(TwoDReportComponent);
