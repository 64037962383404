import styled from 'styled-components';

const ScaleValue = styled.span`
  color: ${p => p.theme.color.turquoise};
  border-radius: 5px;
  padding-right: 4px;
  padding-left: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  background-color: ${p => p.theme.color.pale};
  white-space: nowrap;
  margin-left: 5px;
`;

const Content = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

const SetButtonWrapper = styled.div<{ isNeedUpdate: boolean }>`
  > button {
    background: ${p => p.isNeedUpdate ? p.theme.color.background : p.theme.color.positive};
    padding: 3px 10px;
    width: fit-content;
    height: fit-content;
    > p {
      line-height: 1;
      font-weight: 500;
      color: ${p => p.isNeedUpdate ? p.theme.color.disabled : p.theme.color.white};
    }
  }
`;


export const Styled = {
  Container,
  ScaleValue,
  Content,
  SetButtonWrapper,
};
