import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { DrawingsPdfExport } from '../../interfaces/drawings-pdf';
import {
  AnnotationOptions,
  MeasurementOptions,
  PageOptions,
} from '../../layout-components/top-control-group/drawings-export';
import { ExportPdfPayload } from '../payloads/common';
import { ExportSettingsActionTypes } from '../types/export-settings';

function setExportPages(exportPages: PageOptions): ActionWith<PageOptions> {
  return {
    type: ExportSettingsActionTypes.SET_EXPORT_PAGES,
    payload: exportPages,
  };
}

function setExportMeasurements(exportMeasurements: MeasurementOptions): ActionWith<MeasurementOptions> {
  return {
    type: ExportSettingsActionTypes.SET_EXPORT_MEASUREMENTS,
    payload: exportMeasurements,
  };
}

function setExportAnnotations(annotations: AnnotationOptions[]): ActionWith<AnnotationOptions[]> {
  return {
    type: ExportSettingsActionTypes.SET_EXPORT_ANNOTATIONS,
    payload: annotations,
  };
}

function exportPdf(
  projectId: number,
  pdfId: string,
  requestPayload: DrawingsPdfExport,
  currentFileOnly: boolean,
): ActionWith<ExportPdfPayload> {
  return {
    type: ExportSettingsActionTypes.EXPORT_PDF,
    payload: { projectId, pdfId, requestPayload, currentFileOnly },
  };
}

function toggleDrawingStylesEnabled(): Action {
  return {
    type: ExportSettingsActionTypes.TOGGLE_DRAWING_STYLES_ENABLED,
  };
}

function toggleSegmentLength(): Action {
  return {
    type: ExportSettingsActionTypes.TOGGLE_SEGMENT_LENGTH,
  };
}

function toggleSegmentLengthOnLayer(): Action {
  return {
    type: ExportSettingsActionTypes.TOGGLE_SEGMENT_LENGTH_ON_LAYER,
  };
}

function setLoadingExport(value: boolean): ActionWith<boolean> {
  return {
    type: ExportSettingsActionTypes.SET_LOADING_EXPORT,
    payload: value,
  };
}

export const ExportSettingsActions = {
  setExportPages,
  setExportMeasurements,
  setExportAnnotations,
  exportPdf,
  toggleDrawingStylesEnabled,
  toggleSegmentLength,
  toggleSegmentLengthOnLayer,
  setLoadingExport,
};
