import autobind from 'autobind-decorator';
import * as React from 'react';
import { TitleEditable } from 'common/components/title-editable';
import { ItemMenu, ItemMenuType } from 'components/controls/item-menu';
import { Styled } from './styled';

interface Props {
  onNameChange: (id: number, name: string) => void;
  onDuplicate: (id: number, name: string) => void;
  onRemove: (id: number) => void;
  id: number;
  name: string;
}


export class RevisionItem extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <Styled.Container>
        <ItemMenu
          buttonSize='small'
          menu={this.getMenuItems()}
        />
        <TitleEditable
          label={this.props.name}
          text={this.props.name}
          onChange={this.onNameChange}
          canEdit={true}
        />
      </Styled.Container>
    );
  }

  private getMenuItems(): ItemMenuType[] {
    const result = [{
      name: 'Duplicate',
      action: this.duplicate,
    }];

    if (this.props.onRemove) {
      result.push({
        name: 'Remove',
        action: this.remove,
      });
    }

    return result;
  }

  @autobind
  private onNameChange(name: string): void {
    this.props.onNameChange(this.props.id, name);
  }

  @autobind
  private duplicate(): void {
    this.props.onDuplicate(this.props.id, `Copy of ${this.props.name}`);
  }

  @autobind
  private remove(): void {
    this.props.onRemove(this.props.id);
  }
}
