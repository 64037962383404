import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { ProjectType } from 'common/constants/project-type';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { TwoDNavigation, ProjectMenuTab } from '../2d-navigation';
import { ProjectsHeader } from '../2d-projects-header';
import { TwoDDatabase } from './2d-database/2d-database';
import { SidePanel } from './components';
import { getCurrentTabAndUpdateUrl } from './helpers';
import { MenuName } from './interfaces';
import { TwoDDatabaseActions } from './store-slice';
import { Styled } from './styled';

interface PageStateProps {
  loadingStatus: RequestStatus;
  companyId: number;
}

interface DispatchProps {
  fetchDataBase: () => void;
  goToProjects: () => void;
}

export interface ProjectHeaderSearchTextProps {
  searchPlaceholder: string;
}


type Props = RouteComponentProps<{}> & PageStateProps & DispatchProps & AbilityAwareProps;

interface OwnState {
  currentTab: MenuName;
  searchQuery: string;
}

class TwoDDatabasePageComponent extends React.PureComponent<Props, OwnState> {
  private gridApi: Ag.GridApi;
  private canInvite: boolean = this.props.ability.can(Operation.Create, Subject.Employees) ||
    this.props.ability.can(Operation.Create, Subject.Guests) ||
    this.props.ability.can(Operation.Create, Subject.Subcontractors);
  private searchTextPayload: Record<MenuName, ProjectHeaderSearchTextProps> = {
    [MenuName.Properties]: {
      searchPlaceholder: 'Enter your property name',
    },
    [MenuName.Items]: {
      searchPlaceholder: 'Enter your item name',
    },
    [MenuName.Assemblies]: {
      searchPlaceholder: 'Enter your assembly name',
    },
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      currentTab: props.location && getCurrentTabAndUpdateUrl(props.location.search, props.history.push),
      searchQuery: '',
    };
  }

  public static getDerivedStateFromProps(props: Props): Partial<OwnState> {
    return {
      currentTab: props.location && getCurrentTabAndUpdateUrl(props.location.search, props.history.push),
    };
  }

  public componentDidMount(): void {
    this.props.fetchDataBase();
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.ability.cannot(Operation.Read, Subject.PiaDatabase)) {
      this.props.goToProjects();
      return;
    }

    if (this.props.companyId !== prevProps.companyId && this.props.companyId) {
      this.props.fetchDataBase();
    }
  }

  public render(): React.ReactNode {
    const { searchPlaceholder } = this.searchTextPayload[this.state.currentTab];

    return (
      <TwoDNavigation
        pageTitle='Assemblies Database'
        isCreateButtonHide={true}
        activeTabIndex={ProjectMenuTab.Database}
      >
        <Styled.Container>
          <ProjectsHeader
            onSearchChange={this.onSearchChange}
            searchQueryValue={this.state.searchQuery}
            searchPlaceholder={searchPlaceholder}
            projectType={ProjectType.Database}
            showSearch={true}
            canAddPeople={this.canInvite}
          />
          <TwoDDatabase
            saveGridApi={this.saveGridApi}
            menuTop={145}
          />
          <SidePanel />
        </Styled.Container>
        <RenderIf condition={this.props.loadingStatus === RequestStatus.Loading}>
          <Spinner show={true} />
        </RenderIf>
      </TwoDNavigation>
    );
  }

  @autobind
  private saveGridApi(gridApi: Ag.GridApi): void {
    this.gridApi = gridApi;
  }


  @autobind
  private onSearchChange(_: number, value: string): void {
    this.setState({ searchQuery: value });
    this.gridApi.setQuickFilter(value);
  }
}

const mapStateToProps = (state: State): PageStateProps => {
  const { requestStatus } = state.twoDDatabase;

  return {
    companyId: state.account.selectedCompany ? state.account.selectedCompany.id : null,
    loadingStatus: requestStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    fetchDataBase: () => dispatch(TwoDDatabaseActions.fetchDatabaseRequest()),
    goToProjects: () => dispatch(push(AppUrls.qto2d.listing.path)),
  };
};

export const TwoDDatabasePage = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(TwoDDatabasePageComponent));
