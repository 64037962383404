import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { UuidUtil } from 'common/utils/uuid-utils';
import { useCommentsApiContext, useCommentsContext } from 'unit-2d-comments/comments-context';
import { CommentaryTargetType } from 'unit-2d-comments/enums';
import { TwoDCommentsActions } from 'unit-2d-comments/store-slice';
import { SidePanelContainer } from 'unit-2d-database/components/side-panel/components/side-panel-container';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { Person } from '../../../units/people/interfaces/person';
import { AddCommentPayload, CommentaryTarget } from '../interfaces';
import { CommentReduxWrapper, NewCommentReduxWrapper } from './comment';
import { CommentUserContextProvider } from './comment-user-context';
import { CommentsList } from './comments-list';
import { Header } from './header';
import { Styled } from './styled';

interface DispatchProps {
  onCreateComment: (createPayload: AddCommentPayload) => void;
  onAddSubComment: (commentId: number, subCommentText: string) => void;
  onRemoveComment: (commentId: number) => void;
  onRemoveSubComment: (commentId: number, subCommentId: number) => void;
  onEditComment: (commentId: number, text: string) => void;
  onEditSubComment: (commentId: number, subCommentId: number, text: string) => void;
  onResolve: (commentId: number) => void;
  hideCommentsPanel: () => void;
  cancelAddComment: () => void;
  setSelctedCommentId: (id: number) => void;
}

interface StateProps {
  currentUserId: string;
  users: Person[];
  isPanelOpened: boolean;
  currentCommentaryTarget: CommentaryTarget;
}


interface Props extends DispatchProps, StateProps {

}

export const CommentsPanelComponent: React.FC<Props> = ({
  isPanelOpened,
  users,
  currentCommentaryTarget,
  currentUserId,
  onEditComment,
  onCreateComment,
  onAddSubComment,
  onRemoveComment,
  onRemoveSubComment,
  hideCommentsPanel,
  onResolve,
  onEditSubComment,
  cancelAddComment,
  setSelctedCommentId,
}) => {
  const { sendEvent } = useAnalytics();

  const { focusToComment } = useCommentsApiContext();
  const comments = useCommentsContext();


  const sendEventCallback = (text: string, eventType: string, target: CommentaryTargetType): void => {
    const mathces = text.match(/@\([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\)/g);
    const mentions = mathces === null
      ? 0
      : mathces.length;
    sendEvent(eventType, { target, mentions });
  };

  const onAddComment = useCallback((text: string) => {
    onCreateComment({ text, target: currentCommentaryTarget, tempararyId: UuidUtil.generateUuid() });
    sendEventCallback(text, MetricNames.comments.createThread, currentCommentaryTarget.type);
  }, [currentCommentaryTarget]);

  const onAddSubCommentCallback = useCallback((commentId: number, subCommentText: string) => {
    onAddSubComment(commentId, subCommentText);
    const thread = comments.find(c => c.id === commentId);
    sendEventCallback(subCommentText, MetricNames.comments.createMessage, thread.target.type);
  }, [comments]);

  const onCommentaryClick = useCallback(
    (commentId: number) => {
      const comment = comments.find(x => x.id === commentId);
      if (comment.target.type !== CommentaryTargetType.Project) {
        focusToComment(comment.target);
      }
      setSelctedCommentId(commentId);
    },
    [comments, focusToComment],
  );

  const commentsToShow = useMemo(() => isPanelOpened ? comments : [], [isPanelOpened, comments]);

  return (
    <Styled.SidePanelWrapper>
      <SidePanelContainer
        isOpen={isPanelOpened}
        withBlur={false}
        isClickOutsideActive={true}
        withSidePanel={false}
        onClose={hideCommentsPanel}
      >
        <Header />
        <Styled.Body>
          <CommentUserContextProvider
            users={users}
          >
            {
              currentCommentaryTarget ? (
                <NewCommentReduxWrapper
                  create={onAddComment}
                  cancel={cancelAddComment}
                  currentUserId={currentUserId}
                />
              ) : null
            }
            <CommentsList
              commentType={CommentReduxWrapper}
              currentUserId={currentUserId}
              onCommentClick={onCommentaryClick}
              onRemoveComment={onRemoveComment}
              onRemoveSubComment={onRemoveSubComment}
              onAddSubComment={onAddSubCommentCallback}
              comments={commentsToShow}
              onEditComment={onEditComment}
              onResolve={onResolve}
              onEditSubComment={onEditSubComment}
            />
          </CommentUserContextProvider>
        </Styled.Body>
      </SidePanelContainer>
    </Styled.SidePanelWrapper>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    isPanelOpened: state.twoDComments.isPanelOpened,
    users: state.people.companiesUsers,
    currentUserId: state.account.id,
    currentCommentaryTarget: state.twoDComments.newCommentData,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onEditComment: (commentId, text) => dispatch(TwoDCommentsActions.editCommentRequest({ text, commentId })),
    onCreateComment: (createPayload) => dispatch(TwoDCommentsActions.addCommentRequest(createPayload)),

    onAddSubComment: (commentId, subCommentText) =>
      dispatch(TwoDCommentsActions.addSubCommentRequest({ commentId, text: subCommentText })),
    onRemoveComment: (commentId) =>
      dispatch(TwoDCommentsActions.removeComment(commentId)),
    onRemoveSubComment: (commentId, subCommentId) =>
      dispatch(TwoDCommentsActions.removeSubCommentRequest({ commentId, subCommentId })),
    hideCommentsPanel: () => dispatch(TwoDCommentsActions.changeOpenPanelStatus(false)),
    onEditSubComment: (commentId, subCommentId, text) =>
      dispatch(TwoDCommentsActions.editSubCommentRequest({ commentId, subCommentId, text })),
    onResolve: (commentId) => dispatch(TwoDCommentsActions.resolveCommentRequest(commentId)),
    cancelAddComment: () => dispatch(TwoDCommentsActions.cancelAddComment()),
    setSelctedCommentId: (id) => dispatch(TwoDCommentsActions.setSelectedCommentId(id)),
  };
}

export const CommentsPanel = connect(mapStateToProps, mapDispatchToProps)(CommentsPanelComponent);
