import React from 'react';

import { AdvDialog } from 'common/components/adv-dialog';
import { ControlNames } from 'common/constants/control-names';
import { ConstantFunctions } from 'common/constants/functions';
import { VideoUrl } from 'common/constants/video-url';

interface Props {
  onCloseDialog: () => void;
  onConfirm: () => void;
}

export const ANNOTATIONS_ADV_DIALOG_NAME = 'ANNOTATIONS_ADV_DIALOG_NAME';
const ANNOTATIONS_HEADER_ADV_DIALOG = 'Annotation Tools';
const DESCRIPTION_ADV_DIALOG = `The Annotation Tools add-on helps you to easily collaborate with your colleagues,
check and supervise the work completed without leaving the software.`;
const FEATURES_ADV_DIALOG = [
  'Sticky Notes: leave suggestions and comments for the team or save important notes for later',
  'Legend: add a key to drawings while exporting them to PDF',
  'Built-in Symbols Library: make hints for yourself and the team',
  'Dimension Line: check the accuracy of measurements in seconds',
];
export class AnnotationsAdvDialog extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <AdvDialog
        videoUrl={VideoUrl.AnnotationsMechanism2D}
        header={ANNOTATIONS_HEADER_ADV_DIALOG}
        description={DESCRIPTION_ADV_DIALOG}
        features={FEATURES_ADV_DIALOG}
        onConfirm={this.props.onConfirm}
        text={'Add to my plan'}
        controlName={ControlNames.advDialogAnnotations}
        dialogName={ANNOTATIONS_ADV_DIALOG_NAME}
        onCloseDialog={this.props.onCloseDialog}
        onChatOpen={ConstantFunctions.doNothing}
      />
    );
  }
}
