import { MenuItemStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const ItemMenuContentWrapper = styled.div`
  ${MenuItemStyled.Dropdown} {
    box-shadow: none;
    animation: none;
  }
`;


export const Styled = {
  ItemMenuContentWrapper,
};
