import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import './main-header.scss';

import { VideoUrl } from 'common/constants/video-url';
import { FaqCaption } from 'common/enums/faq-caption';
import { State } from 'common/interfaces/state';
import { FaqLink } from 'common/UIKit/faq-link';
import { HelpCenter } from '../../../units/analytics/components/help-center';
import { BellBadge } from '../../../units/notifications/components/bell-notifications';
import { CompaniesSelect } from '../companies-select';
import { PageHeaderAvatar } from '../page-header-avatar';
import { ProfileIcon } from '../profile';

interface ReduxProps {
  isAdminPage?: boolean;
}

interface Props extends ReduxProps {
  hideCompaniesSelect?: boolean;
  faqCaption: FaqCaption;
}

class MainHeaderComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <React.Fragment>
        <div className={classNames('kreo-main-header', { admin: this.props.isAdminPage })}>
          <div className='kreo-main-header__left-edge'>
            {
              !this.props.hideCompaniesSelect &&
              <div className='kreo-main-header__companies-select-wrap'>
                <CompaniesSelect />
              </div>
            }
          </div>
          <div className='kreo-main-header__right-edge'>
            <HelpCenter url={VideoUrl.fullDemo} />
            <FaqLink caption={this.props.faqCaption} />
            <div className='kreo-main-header__profile-wrap'>
              <ProfileIcon showExpandIcon={true} />
            </div>
            <PageHeaderAvatar size={40} />
            <BellBadge />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  return {
    isAdminPage: state.router.location.pathname.startsWith('/admin'),
  };
};

export const MainHeader = connect(mapStateToProps)(MainHeaderComponent);
