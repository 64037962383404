import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-step.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit/dialogs/';
import { AppUrls } from 'routes/app-urls';
import { ClassificationChart } from '../../../../../components/charts';
import { ObjectStatisticType } from '../../../../../components/charts/interfaces/object-statistic-type';
import { ItemMenu, ItemMenuType } from '../../../../../components/controls/item-menu';
import { KreoConfirmationDialog } from '../../../../../components/dialog/kreo-confirmation-dialog';
import { ClassificationActions } from '../../../actions/creators/classification';
import { isValidationStepGreaterThan, ValidationStep } from '../../../enums/validation-step';
import { ValidationStepState } from '../../../enums/validation-step-state';
import { StepProps, ValidationStepWrapper } from '../components/validation-stepper';

interface DispatchProps {
  getStatistic: (projectId: number) => void;
  openReclassifyDialog: () => void;
}

interface StateProps {
  loaded: boolean;
  statistic: ObjectStatisticType[];
  errors: number[];
  isPassed: boolean;
  isEditable: boolean;
  doesNeedCalculation: boolean;
}

interface OwnProps extends AbilityAwareProps {
  projectId: number;
  reclassify?: () => void;
}

interface Props extends StateProps, DispatchProps, OwnProps, StepProps  { }

const reclassifyDialogName = 'reclassify';

class Classification extends React.PureComponent<Props, {}> {
  constructor(props: Props) {
    super(props);

    if (props.isPassed) {
      props.getStatistic(props.projectId);
    }

    const { stepperRef, errors } = props;
    if (stepperRef) {
      stepperRef.clearEngine();
      stepperRef.paintEngine([], errors);
    }
    this.state = {};
  }

  public static getDerivedStateFromProps(nextProps: Props): {} {
    const { stepperRef, errors } = nextProps;
    const hasErrors = errors.length > 0;
    if (hasErrors && stepperRef) {
      stepperRef.paintEngine([], errors);
    }

    return null;
  }

  public render(): React.ReactNode {

    return (
      <ValidationStepWrapper>
        <KreoConfirmationDialog
          onYes={this.props.reclassify}
          name={reclassifyDialogName}
          yesText='Reclassify'
          noText='Cancel'
          title='Model Reclassification'
        >
          <span className='classification-step__dialog-text'>
            The model will be reclassified.
             All your <b>Classification</b> changes will be lost.
          </span>
        </KreoConfirmationDialog>
        <div className='classification-step__header'>
          <h3>{this.props.title}</h3>
        </div>
        {this.renderChartContent()}
      </ValidationStepWrapper>
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    const { isPassed, projectId, errors, stepperRef } = this.props;
    if (isPassed && !prevProps.isPassed) {
      this.props.getStatistic(projectId);
    }
    if (!stepperRef) {
      return;
    }

    const currentStatisticAvailable = this.isStatisticAvailable(this.props);
    if (this.isStatisticAvailable(prevProps) !== currentStatisticAvailable || stepperRef !== prevProps.stepperRef) {
      if (currentStatisticAvailable && errors.length && stepperRef) {
        stepperRef.paintEngine([], errors);
      } else {
        stepperRef?.clearEngine();
      }
    }
  }

  private isStatisticAvailable({ isPassed, loaded }: Props): boolean {
    return isPassed && loaded;
  }

  private renderChartContent(): React.ReactNode {
    const { projectId, doesNeedCalculation, isEditable } = this.props;
    const classificationUrlParams = { projectId: projectId.toString() };
    const disableClassify = !(isEditable || doesNeedCalculation);
    if (doesNeedCalculation) {
      return null;
    } else {
      return (
        <div className='validation-step-content__inner-wrap'>
          {
            this.isStatisticAvailable(this.props)
              ? (
              <div className='classification-step__chart-wrap'>
                <ItemMenu
                  buttonSize='medium'
                  className='classification-step__classify-menu'
                  menu={this.getClassifyMenu(disableClassify)}
                />
                <ClassificationChart
                  fixLink={AppUrls.plan.project.validation.editClassification.url(classificationUrlParams)}
                  viewLink={AppUrls.plan.project.validation.viewClassification.url(classificationUrlParams)}
                  isFixable={this.props.isEditable}
                  data={this.props.statistic}
                  onSelectIds={this.isolate}
                  onDeselect={this.showAll}
                  attentionStatus={this.hasUndefinedStatistic()}
                  chartName={ValidationStep.Classification}
                />
              </div>
              ) : (
              <SvgSpinner size='middle' />
              )
          }
        </div>);
    }
  }

  private getClassifyMenu(disabled: boolean): ItemMenuType[] {
    const { doesNeedCalculation, openReclassifyDialog, reclassify } = this.props;
    const action = doesNeedCalculation ? reclassify : openReclassifyDialog;
    return [
      {
        name: 'Remove all changes',
        action,
        disabled,
      },
    ];
  }

  @autobind
  private hasUndefinedStatistic(): boolean {
    const undefinedStatistic = this.props.statistic.find(s => s.name === 'Undefined');
    return undefinedStatistic && undefinedStatistic.amount > 0;
  }

  @autobind
  private isolate(ids: number[]): void {
    if (this.props.stepperRef) {
      this.props.stepperRef.isolate(ids);
    }
  }

  @autobind
  private showAll(): void {
    if (this.props.stepperRef) {
      this.props.stepperRef.showAll();
    }
  }
}

const mapStateToProps = (state: ReduxState, { ability }: OwnProps): StateProps => {
  const currentProject = state.projects.currentProject;
  const validationState = currentProject ? currentProject.validationState : null;

  let isEditable = false;
  let isPassed = false;
  let doesNeedCalculation = false;

  if (validationState) {
    const isCurrentStep = validationState.validationStep === ValidationStep.Classification;
    const isApprovable = isCurrentStep && validationState.stepState === ValidationStepState.Approvable;
    isPassed =
      isValidationStepGreaterThan(validationState.validationStep, ValidationStep.Classification) || isApprovable;
    isEditable = isApprovable && ability.can(Operation.Update, Subject.ValidationClassification);
    doesNeedCalculation = isCurrentStep && validationState.stepState === ValidationStepState.NeedsCalculation;
  }

  return {
    loaded: state.classification.statisticLoaded,
    statistic: state.classification.statistic,
    errors: state.classification.errorIds,
    doesNeedCalculation,
    isPassed,
    isEditable,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    getStatistic: (projectId: number) => {
      dispatch(ClassificationActions.getStatisticRequest(projectId));
    },
    openReclassifyDialog: (): void => {
      dispatch(KreoDialogActions.openDialog(reclassifyDialogName));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationStep = withAbilityContext(connector(Classification));
