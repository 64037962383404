import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './project-people-panel.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { KreoIconAddUser } from 'common/UIKit';
import { KreoDialogActions } from 'common/UIKit/dialogs/';
import { ProjectMembers } from '../../../units/projects/components/project-members-dialog';
import { ProjectPeopleList } from './project-people-list';

interface ReduxActions {
  setInvitePeopleToProjectDialogOpen: () => void;
}

interface Props extends ReduxActions, AbilityAwareProps {
  showAll?: boolean;
}

class ProjectPeoplePanelComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <div className='project-people-panel'>
        <ProjectPeopleList showAll={this.props.showAll} />
        {this.showInvite()}
      </div>
    );
  }

  private showInvite(): React.ReactNode {
    return this.props.ability.can(Operation.Update, Subject.Project) && (
        <div
          className='project-people-panel__add-icon'
          onClick={this.props.setInvitePeopleToProjectDialogOpen}
        >
          <KreoIconAddUser />
        </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setInvitePeopleToProjectDialogOpen: () => {
      dispatch(KreoDialogActions.openDialog(ProjectMembers.dialogName));
    },
  };
};

export const ProjectPeoplePanel = withAbilityContext(connect(null, mapDispatchToProps)(
  ProjectPeoplePanelComponent,
));
