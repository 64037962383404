import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { ProjectStatus } from 'common/enums/project-status';
import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { ADD_BID_PRICING } from '../../../constants/forms';
import { AppUrls } from '../../../routes/app-urls';
import * as CostUtils from '../../../utils/costs';
import { ScenariosActions } from '../../scenarios/actions';
import { BidPricingActions } from '../actions/creators/bid-pricing';
import { ProjectsActions } from '../actions/creators/common';
import { CostsActions } from '../actions/creators/costs';
import {
  BidPricingUserPackageInfoPayload,
  GetPackageCost,
  PackageEmails,
  SubcontractorBid,
  UpdateBidDescription,
  WinnerPackage,
} from '../actions/payloads/bid-pricing';
import { BidPricingActionTypes } from '../actions/types/bid-pricing';
import { ProjectsActionTypes } from '../actions/types/common';
import { BidPricingApi } from '../api/bid-pricing-api';
import { CostsApi } from '../api/costs';
import { BidPricingCalculationDetails } from '../interfaces/bid-pricing/bid-pricing-calculation-details';
import { BidPricingInfo } from '../interfaces/bid-pricing/bid-pricing-info';
import { BidPricingInviteUsers } from '../interfaces/bid-pricing/bid-pricing-invite-users';
import { BidPricingUserPackage } from '../interfaces/bid-pricing/bid-pricing-user-package';
import { CostsTreeNodeVm } from '../interfaces/bid-pricing/costs-tree-node-vm';
import { Project } from '../interfaces/project';
import { getCurrentProject } from '../selectors';

function* startBidPricing(): SagaIterator {
  try {
    const formData: BidPricingInfo = yield selectWrapper(state => {
      const formValues = state.form[ADD_BID_PRICING].values;
      const currentProject = getCurrentProject(state);
      return {
        ...formValues,
        isPrivate: true,
        calculationId: state.scenarios.active_calculation.id,
        id: currentProject.id,
      };
    });
    const data = yield call(BidPricingApi.startBidPricing, formData);
    yield put(ProjectsActions.updateProjectStatusSucceeded(formData.id, ProjectStatus.OnBidPricing));
    yield put(ProjectsActions.getProjectBidPricingInfoSucceeded(data));
  } catch (error) {
    console.error('bid pricing: start bid pricing failed', error);
  }
}

function* acceptPackage(): SagaIterator {
  try {
    const costs = yield selectWrapper(state => state.cost.get('costs'));
    yield call(BidPricingApi.insertSubcontractorBid, costs);
  } catch (error) {
    console.error('bid pricing: accept package failed', error);
  }
}

function* getBidPricingInfo(action: ActionWith<number>): SagaIterator {
  try {
    const data: BidPricingCalculationDetails = yield call(
      BidPricingApi.getCalculationBidPricingDetails, action.payload,
    );
    yield put(BidPricingActions.getBidPricingInfoSucceeded(data.workPackages));
  } catch (error) {
    console.error('bid pricing: get bid pricing info failed', error, action.payload);
  }
}

function* setWinner(action: ActionWith<WinnerPackage>): SagaIterator {
  try {
    const project: Project = yield selectWrapper(getCurrentProject);
    const result = yield call(BidPricingApi.setPackageWinner, action.payload);
    if (result) {
      yield put(BidPricingActions.setWinnerSucceded(action.payload));
      yield put(ScenariosActions.reloadActiveScenarioByProject(project.id));
    }
  } catch (error) {
    console.error('bid pricing: set winner failed', error, action.payload);
  }
}

function* cancelRequest(action: ActionWith<BidPricingUserPackageInfoPayload>): SagaIterator {
  try {
    yield call(
      BidPricingApi.cancelSubcontractorInvitationRequest,
      action.payload.workPackageId,
      action.payload.email,
    );
  } catch (error) {
    console.error('bid pricing: cancel request failed', error, action.payload);
  }
}

function* cancelBidPricing(): SagaIterator {
  try {
    const [bidPricingId, project, activeCalculationId]: [number, Project, number] = yield selectWrapper(
      state => [
        state.form[ADD_BID_PRICING].values.id,
        getCurrentProject(state),
        state.scenarios.active_calculation.id,
      ],
    );
    if (bidPricingId) {
      yield call(BidPricingApi.cancelBidPricing);
      yield put(ProjectsActions.updateProjectStatusSucceeded(project.id, ProjectStatus.HasBimFullInfo));
      yield put(BidPricingActions.getBidPricingInfo(activeCalculationId));
    }
  } catch (error) {
    console.error('bid pricing: cancel bid pricing failed', error);
  }
}

function* changeData(): SagaIterator {
  try {
    const data = yield selectWrapper(state => state.form[ADD_BID_PRICING].values);
    yield call(BidPricingApi.setBidPricingInfo, data);
  } catch (error) {
    console.error('bid pricing: change data failed', error);
  }
}

function* removeSubcontractorBid(action: ActionWith<SubcontractorBid>): SagaIterator {
  try {
    yield call(BidPricingApi.removeSubcontractorBid, action.payload.constructionCostId);
    yield put(BidPricingActions.removeSubcontractorBidSuccess(action.payload));
  } catch (error) {
    console.error('bid pricing: remove subcontractor bid failed', error, action.payload);
  }
}

function* removeSubcontractorsByPackage(action: ActionWith<PackageEmails>): SagaIterator {
  try {
    const calculationId: number = yield selectWrapper(state => state.scenarios.active_calculation.id);
    yield call(
      BidPricingApi.removePeopleFromWorkPackage,
      action.payload.id,
      action.payload.emails,
    );
    yield put(BidPricingActions.getBidPricingInfo(calculationId));
  } catch (error) {
    console.error('bid pricing: remove subcontractors by package failed', error, action.payload);
  }
}

function* createNewBid(action: ActionWith<number>): SagaIterator {
  try {
    const result = yield call(BidPricingApi.createSubcontractorBid, action.payload);
    if (result) {
      yield put(BidPricingActions.createNewBidSucceeded(action.payload, result));
    }
  } catch (error) {
    console.error('bid pricing: create new bid failed', error, action.payload);
  }
}

function* updateBidDescriptionRequest(action: ActionWith<UpdateBidDescription>): SagaIterator {
  try {
    const result = yield call(BidPricingApi.updateBidDescription, action.payload);
    if (result) {
      yield put(BidPricingActions.updateBidDescriptionSucceeded(action.payload));
    }
  } catch (error) {
    console.error('bid pricing: update bid description request failed', error, action.payload);
  }
}

function* getSubcontractorPackages(action: ActionWith<number>): SagaIterator {
  try {
    const data: BidPricingUserPackage[] = yield call(BidPricingApi.getSubcontractorPackagesCosts, action.payload);
    yield put(BidPricingActions.getSubcontractorPackagesSucceeded(data));
  } catch (error) {
    console.error('bid pricing: get subcontractor packages failed', error, action.payload);
  }
}

function* getPackageData(action: ActionWith<GetPackageCost>): SagaIterator {
  try {
    const project: Project = yield selectWrapper(getCurrentProject);
    if (project) {
      const workPackageId = action.payload.subcontractorCost.id;
      const data: CostsTreeNodeVm = yield call(CostsApi.getPackageInfo, workPackageId);
      yield put(CostsActions.getCostEstimateSucceeded(CostUtils.processCost([data])));
      const urlParams = {
        projectId: project.id.toString(),
        workPackageId: workPackageId.toString(),
      };
      const url =
        action.payload.mode === 'edit'
          ? AppUrls.plan.project.bidPricing.editCost.url(urlParams)
          : AppUrls.plan.project.bidPricing.costInfo.url(urlParams);
      yield put(push(url));
    }
  } catch (error) {
    console.error('bid pricing: get package data failed', error, action.payload);
  }
}

function* inviteUsersToPackage(action: ActionWith<BidPricingInviteUsers>): SagaIterator {
  try {
    const activeCalculationId: number = yield selectWrapper(state => state.scenarios.active_calculation.id);
    yield call(BidPricingApi.inviteUsersToPackage, action.payload);
    yield put(BidPricingActions.getBidPricingInfo(activeCalculationId));
  } catch (error) {
    console.error('bid pricing: invite users to package failed', error, action.payload);
  }
}

export function* bidPricingSaga(): SagaIterator {
  yield takeLatest(BidPricingActionTypes.ACCEPT_REQUEST, acceptPackage);
  yield takeLatest(BidPricingActionTypes.GET_INFO_REQUEST, getBidPricingInfo);
  yield takeLatest(BidPricingActionTypes.SET_WINNER_REQUEST, setWinner);
  yield takeLatest(BidPricingActionTypes.CANCEL_REQUEST, cancelRequest);
  yield takeLatest(BidPricingActionTypes.STOP_REQUEST, cancelBidPricing);
  yield takeLatest(BidPricingActionTypes.EDIT_REQUEST, changeData);
  yield takeLatest(BidPricingActionTypes.REMOVE_SUBCONTRACTOR_REQUEST, removeSubcontractorBid);
  yield takeLatest(ProjectsActionTypes.START_BID_PRICING_REQUEST, startBidPricing);
  yield takeLatest(
    BidPricingActionTypes.REMOVE_SUBCONTRACTORS_BY_PACKAGE,
    removeSubcontractorsByPackage,
  );
  yield takeEvery(BidPricingActionTypes.CREATE_NEW_BID_REQUEST, createNewBid);
  yield takeEvery(
    BidPricingActionTypes.UPDATE_BID_DESCRIPTION_REQUEST,
    updateBidDescriptionRequest,
  );
  yield takeLatest(
    BidPricingActionTypes.GET_SUBCONTRACTOR_PACKAGES_REQUEST,
    getSubcontractorPackages,
  );
  yield takeLatest(BidPricingActionTypes.SET_SUBCONTRACTOR_PACKAGE, getPackageData);
  yield takeLatest(BidPricingActionTypes.INVITE_USERS_TO_PACKAGE, inviteUsersToPackage);
}
