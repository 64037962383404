import { Constants } from '@kreo/kreo-ui-components';
import styled, { css } from 'styled-components';

import { LinkComponentStyled } from 'common/components/link-component';

const pinnedStyles = css`
  top: 10px!important;
  left: 50%!important;
  transform: translateX(-50%)!important;
`;

const Container = styled.div<{ pinned?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 40px;
  z-index: 1000;
  animation: ${Constants.Animations.slowTopAppear()} ${p => p.theme.duration.s} linear;
  box-shadow: ${p => p.theme.shadow.xl};
  background: ${p => p.theme.color.backgroundRush};
  border-radius: 10px;

  ${p => p.pinned ? pinnedStyles : ''}

  >:not(:last-child) {
    border-right:  1px solid ${p => p.theme.color.background};
  }
  >:first-child {
    > div {
      >:first-child {
        > div {
          height: 40px;
        }
      }
    }
  }

  ${LinkComponentStyled.DivContainer} {
    padding: 0 10px;
  }
`;

const ColorIndicatorContainer = styled.div`
  padding: 0px 10px;
  display: flex;
  align-items: center;
  > div {
    height: 20px;
  }
`;

const ButtonContainer = styled.div`
  padding: 10px;
  button {
    border-radius: 5px;
    padding: 3px 20px;
    p {
      font-size: 12px;
    }
  }
`;

const HoverMenuInteractiveContainer = styled.div`
  > div {
    > div {
      > div {
        > div {
          height: auto;
          width: auto;
          padding: 0 3px;
        }
      }
    }
  }
`;

export const Styled = {
  Container,
  ButtonContainer,
  ColorIndicatorContainer,
  HoverMenuInteractiveContainer,
};
