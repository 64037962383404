import autobind from 'autobind-decorator';
import * as React from 'react';

import './content-container.scss';

import { ModelCheckPriority } from '../../../enums/mode-check-priority';
import { ModelCheckType } from '../../../enums/model-check-type';
import { TitleContainer } from './title-container';

export type ScrollToType = (position: number) => void;

interface Props {
  title: string;
  main?: boolean;
  modelCheckType?: ModelCheckType;
  categoryCritical?: ModelCheckPriority;
  onCopyClick?: () => void;
  getScrollApi?: (api: ScrollToType) => void;
}

interface State {
  scroll: boolean;
}


export class ContentContainer extends React.Component<Props, State> {
  private content: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      scroll: false,
    };
  }

  public componentDidMount(): void {
    if (this.props.getScrollApi) {
      this.props.getScrollApi(this.scrollTo);
    }
  }

  public render(): React.ReactNode {
    return [
      (
        <TitleContainer
          key={0}
          title={this.props.title}
          overlay={this.state.scroll}
          main={this.props.main}
          onCopyClick={this.props.onCopyClick}
          statusType={this.props.categoryCritical}
          modelCheckType={this.props.modelCheckType}
        />
      ),
      (
        <div
          key={1}
          ref={this.setContentBody}
          className='checker-content-body'
          onScroll={this.onScroll}
        >
          {this.props.children}
        </div>
      ),
    ];
  }


  @autobind
  private setContentBody(ref: HTMLDivElement): void {
    this.content = ref;
  }

  @autobind
  private onScroll(e: any): void {
    if (e.target.scrollTop > 10 && !this.state.scroll) {
      this.setState({ scroll: true });
    } else if (this.state.scroll && e.target.scrollTop < 10) {
      this.setState({ scroll: false });
    }
  }

  @autobind
  private scrollTo(position: number): void {
    this.content.scrollTop = position * 42;
  }
}
