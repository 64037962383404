import { State } from 'common/interfaces/state';
import { store } from '../../store';
import { BaseResourcesApi } from './base-resource-api';
import { CompanyResourcesApi } from './company-resources-api';

const getBaseUrl = (url: string = ''): string => {
  const state: State = store.getState();
  const project = state.projects.currentProject;
  const projectId = project && project.id;

  if (!projectId) {
    throw new Error(`ProjectResourcesApi: projectId is empty requested path ${url || '/'}`);
  }

  return CompanyResourcesApi.getUrl(`/projects/${projectId}${url}`);
};

export const ProjectResourcesApi = new BaseResourcesApi(getBaseUrl);
