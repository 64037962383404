import * as t from 'io-ts';
import { BillingEntityStatusC } from '../enums/billing-entity-status';
import { BillingPeriodUnitC } from '../enums/billing-period-unit';
import { BillingPricingModelC } from '../enums/billing-pricing-model';
import { TrialPeriodUnitC } from '../enums/trial-period-unit';

export const BillingModelC = t.intersection(
  [
    t.type({
      pricingModel: BillingPricingModelC,
      price: t.number,
      billingPeriodUnit: BillingPeriodUnitC,
      billEvery: t.number,
      status: BillingEntityStatusC,
    }),
    t.partial({
      trialPeriodUnit: TrialPeriodUnitC,
      trialPeriod: t.number,
    }),
  ],
  'BillingModel');

export type BillingModel = t.TypeOf<typeof BillingModelC>;
