import * as t from 'io-ts';
import { SubscriptionAddonsC } from './subscription-addon';
import { SubscriptionPlansC } from './subscription-plan';

export const SubscriptionPlanListingModelC = t.type(
  {
    plans: SubscriptionPlansC,
    addons: SubscriptionAddonsC,
  },
  'SubscriptionPlan');

export type SubscriptionPlanListingModel = t.TypeOf<typeof SubscriptionPlanListingModelC>;
