import { LocationDescriptorObject } from 'history';
import QueryString from 'query-string';


export function pushItemToUrl(
  queryString: any,
  push: (location: LocationDescriptorObject) => void,
): void {
  push({ search: QueryString.stringify({ fastNavigationType: queryString }) });
}
