import { Text, ElementTooltip, CheckboxButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { OptimizeTooltips } from 'common/components/drawings/constants';
import {
  DrawingsCustomSnappingModes,
  DrawingsSnappingModes,
} from 'common/components/drawings/enums/drawing-snapping-modes';
import { Styled } from './drawings-snapping-modes-selection-item-styled';

interface ElementProps {
  selected: boolean;
  disabled: boolean;
  title: string;
  id: DrawingsSnappingModes;
  onSelect: (id?: DrawingsSnappingModes, selected?: boolean) => void;
}

export class DrawingsSnappingModesSelectionItem extends React.PureComponent<ElementProps> {
  public render(): React.ReactNode {
    const { selected, title, disabled } = this.props;

    return (
      <ElementTooltip
        text={title}
        description={OptimizeTooltips.toolBlocked}
        disabled={!disabled}
        position='right'
        size='small'
        tooltipWidth={210}
      >
        <Styled.Container onClick={this.onSelect} disabled={disabled}>
          <Styled.Title>
            <Text>{title}</Text>
          </Styled.Title>
          <CheckboxButton checked={selected} />
        </Styled.Container>
      </ElementTooltip>
    );
  }

  @autobind
  private onSelect(): void {
    const { id, selected, onSelect } = this.props;
    if (id === DrawingsCustomSnappingModes.OrthogonalMode2D) {
      onSelect();
    } else {
      onSelect(id, !selected);
    }
  }
}
