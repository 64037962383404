import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import './database-activity-variant-resource-based-crew-hours-material-field.scss';

import { MaterialNumberInputProps } from 'common/UIKit';
import { MaterialModel } from '../../interfaces/resources-data';


interface Props {
  material: MaterialModel;
  error?: string;
}

class DatabaseActivityVariantResourceBasedCrewHoursMaterilaFieldComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { material, error } = this.props;

    if (error) {
      return (
        <div className={'database-activity-variant-resource-based-crew-hours-material-field__error'}>
          {error}
        </div>);
    }

    return (
      <b>{material && material.name}</b>
    );
  }
}

export type DatabaseActivityVariantResourceBasedCrewHoursMaterilaFieldProps = Props;

export const DatabaseActivityVariantResourceBasedCrewHoursMaterilaField: React.FC<MaterialNumberInputProps> = (
  props: WrappedFieldProps & Props,
): JSX.Element => {
  const {
    meta: { submitFailed, error },
    ...rest } = props;

  return (
    <DatabaseActivityVariantResourceBasedCrewHoursMaterilaFieldComponent
      error={submitFailed && error}
      {...rest}
    />
  );
};
