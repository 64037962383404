import * as React from 'react';

export const KreoIconCopyClipboard: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
    >
      <g id='icon_copy' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <path
            // eslint-disable-next-line max-len
            d='M12,12 L12,4 C12,3.44771525 11.5522847,3 11,3 L5,3 L5,2 C5,1.44771525 5.44771525,1 6,1 L13,1 C13.5522847,1 14,1.44771525 14,2 L14,11 C14,11.5522847 13.5522847,12 13,12 L12,12 Z'
            id='Combined-Shape'
            fill='currentColor'
          />
          <rect id='Rectangle' fill='currentColor' x='2' y='4' width='9' height='11' rx='1' />
          <rect id='Rectangle-2' fill='#FFFFFF' x='4' y='10' width='5' height='1' rx='0.5' />
          <rect id='Rectangle-2' fill='#FFFFFF' x='4' y='7' width='5' height='1' rx='0.5' />
      </g>
    </svg>);
};
