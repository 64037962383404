import { Icons } from '@kreo/kreo-ui-components';
import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { getMeasurementsList } from '2d/units/get-measurements-list';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import {
  DrawingsGeometryGroup,
  DrawingsInstanceMeasure,
  DrawingsLayoutApi,
} from 'common/components/drawings';
import { DrawingsFile, DrawingsFolderViewInfo } from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces/drawings-short-drawing-info';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import {
  CellFocusData,
  ExcelTable,
  ExcelTableApi,
  ExcelTableContext,
  INDEX_COLUMN_KEY,
  UpdateCellData,
} from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import {
  CellFillHandler,
  ClipboardHelper,
  ExcelFormulaHelper,
  ReferenceHelper,
} from 'common/components/excel-table/utils';
import { FormulaToolbarApi } from 'common/components/formula-toolbar';
import { QUOTE_EXCEPTION_DIALOG } from 'common/components/quote-exception-dialog';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { UndoRedoContextApiProps } from 'common/undo-redo';
import { withUndoRedoApiProps } from 'common/undo-redo/with-undo-redo-api-props';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { mathUtils } from 'common/utils/math-utils';
import { ValueHelper } from 'common/utils/value-helper';
import { WithCommentsContext, withCommentsContext } from 'unit-2d-comments/comments-context';
import { CommentaryTargetType } from 'unit-2d-comments/enums';
import { CommentarySpreadsheetReportPageTarget, CommentaryThread } from 'unit-2d-comments/interfaces';
import { TwoDCommentsActions } from 'unit-2d-comments/store-slice';
import { CommentaryTargetTypeGuards } from 'unit-2d-comments/utils';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';
import { TableInnerClipboard } from '../../../../units/persisted-storage/interfaces/state';
import { TwoDActions } from '../../actions/creators';
import { SheetUpdateCells } from '../../actions/payloads';
import { FocusedCell, ReportPage, UpdateCellForm } from '../../interfaces';
import { ExcelTableCellFormatter } from '../../units';
import { TwoDRegex, TwoDRegexGetter } from '../../units/2d-regex/2d-regex';
import { TwoDReportUndoRedoHelper } from '../../units/2d-report-undo-redo-helper';
import { CellDataStore, SheetDataStore } from '../../units/cell-data-controllers/report-cell-data-store';
import { getConfigId } from '../../units/excel-table-cell-formatter/common';
import { RangeSelection } from '../../units/range-selection';
import { TableDataMoveType, TableDataMoveHelper } from '../../units/table-data-move-helper';
import { isValidFormulaQuote } from '../constants';

interface FocusData {
  sheetId: string;
  cellData: CellFocusData;
}

export interface FocusChangeData {
  prevData: FocusData;
  newData: FocusData;
}

interface StateProps {
  focusedCell: FocusedCell;
  reportPages: ReportPage[];
  drawingsMeasure: Record<string, DrawingsInstanceMeasure>;
  drawingInstanceToCell: Record<string, string[]>;
  selectedSheetId: string;
  cellWithBoarder: string;
  isImperial: boolean;
  drawingsInfo: Record<string, DrawingsShortInfo>;
  cellToCellMap: Record<string, string[]>;
  drawingGroups: DrawingsGeometryGroup[];
  entities: Record<string, DrawingsFile | DrawingsFolderViewInfo>;
  currentDrawing: DrawingsShortInfo;
  drawings: Record<string, DrawingsShortInfo>;
  aiAnnotation: DrawingsGeometryState;
  elementMeasurement: Record<string, DrawingsInstanceMeasure>;
  twoDInnerClipboard: TableInnerClipboard;
  selectedCommentId: number;
}

interface DispatchProps {
  setFocusedCell: (data: CellFocusData, sheetId: string) => void;
  setRead: (value: boolean) => void;
  setDrawingInstanceInCellRange: (sheetId: string, ranges: Record<string, boolean>) => void;
  setFilteredNodeId: (filteredNodeIds: Record<string, boolean>) => void;
  onSendToClipboard: (rows: string[][], expectedClipboard: string) => void;
  openDialog: (callBack: () => void) => void;
  startCreateComment: (target: CommentarySpreadsheetReportPageTarget) => void;
  openComment: (commentId: number) => void;
  moveSpreadSheetComments: (
    projectId: string,
    commentId: number,
    target: CommentarySpreadsheetReportPageTarget,
  ) => void;
}

interface OwnProps {
  sheetId: string;
  sheetName: string;
  formulaBarApi: FormulaToolbarApi;
  drawingsLayoutApi: DrawingsLayoutApi;
  saveApi: (
    ref: ExcelTableApi,
    sheetId: string,
    updateColumns?: (payload: Array<Record<string, string>>) => void,
  ) => void;
  referenceReader: ReferenceHelper;
  onCellRangeChanged: (ranges: Ag.CellRange[]) => void;
  setFormulaBarValue: (value: string) => void;
  setColumnWidth: (sheetId: string, payload: Array<{ columnId: string, width: number }>) => void;
  updateCells: (payload: Array<SheetUpdateCells<UpdateCellForm[]>>) => void;
  addRows: (sheetId: string, count: number) => void;
  addColumns: (sheetId: string, count: number) => void;
  projectId: string;
  handlePaste: (cvs?: string) => Promise<void>;
  onFocusChange: (data: FocusChangeData) => void;
  reportEditPermission: boolean;
  selectCellValue: (sheetId: string, columnId: string, rowId: string) => string | number;
  selectCellData: (sheetId: string, columnId: string, rowId: string) => string;
  isValidCell: (cellId: string) => boolean;
  selectSheetData: (sheetId: string) => SheetDataStore;
  onSheetReady: (sheetId: string) => void;
  getSheetDataStore: () => CellDataStore;
  updateCellDataOnFill: (sheetId: string, cells: UpdateCellForm[]) => void;
}

export interface UpdateColumnWidth {
  columnId: string;
  width: number;
}


interface Props extends StateProps,
  DispatchProps,
  OwnProps,
  UndoRedoContextApiProps,
  WithCommentsContext,
  AbilityAwareProps,
  AnalyticsProps { }

interface OwnState {
  rows: Array<Record<string, string | number>>;
  columns: Ag.ColDef[];
  gridApiRef: ExcelTableApi;
}

class TwoDSheetComponent extends React.PureComponent<Props, OwnState> {
  private defaultColDef: Ag.ColDef | Ag.ColGroupDef;
  private tableContext: Partial<ExcelTableContext>;

  public constructor(props: Props) {
    super(props);
    const store = props.selectSheetData(props.sheetId);
    this.state = {
      rows: [],
      gridApiRef: null,
      columns: store ? store.columns : [],
    };
    this.defaultColDef = {
      cellEditorParams: {
        onDelete: this.onCellDelete,
        onEditStart: this.onEditCellStart,
        onEditEnd: this.onEditCellEnd,
        cellEditorValueFormatter: this.cellEditorValueFormatter,
        onInput: this.props.setFormulaBarValue,
        showInvalidDialog: this.props.openDialog,
      },
      valueSetter: this.valueSetter,
      cellStyle: ExcelTableCellFormatter.getCellStyle,
      cellClassRules: {
        'cell-border': ({ node, colDef }: { node: Ag.RowNode, colDef: Ag.ColDef }) => {
          const rowId = node.id;
          const colId = colDef.colId;
          return `${colId}${rowId}` === this.props.cellWithBoarder;
        },
        'cell-invalid': ({ node, colDef, context }:
          { node: Ag.RowNode, colDef: Ag.ColDef, context: ExcelTableContext }) => {
          return !this.isValidCell(context.sheetId, colDef.colId, node.id);
        },
      },
      getQuickFilterText: params => {
        if (params.colDef.colId === INDEX_COLUMN_KEY) {
          return '';
        }
        const data = params.data[params.colDef.colId] !== undefined
          ? params.data[params.colDef.colId]
          : '';
        if (TwoDRegex.drawingInstance.test(data.toString()) && typeof params.value === 'number') {
          return mathUtils.round(params.value, 2).toString();
        }

        return params.value;
      },
    };
    this.tableContext = {
      referenceReader: this.props.referenceReader,
      sheetId: this.props.sheetId,
      rangeSelection: new RangeSelection(),
      getComent: this.getComment,
      openComent: this.clickComment,
      isSelected: this.isCommentSelected,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.state.gridApiRef) {
      if (this.props.selectedSheetId !== this.props.sheetId) {
        this.state.gridApiRef.api.clearFocusedCell();
        this.state.gridApiRef.api.clearRangeSelection();
      }

      if (this.props.comments !== prevProps.comments || this.props.selectedCommentId !== prevProps.selectedCommentId) {
        this.state.gridApiRef.api.refreshCells({ force: true });
      }
    }

    const instancesIds = Object.keys(this.props.drawingInstanceToCell);
    const measuresCount = Object.keys(this.props.drawingsMeasure).length;
    if (measuresCount < instancesIds.length) {
      this.getInstancesMeasures(instancesIds);
    }

    if (this.props.sheetId !== prevProps.sheetId) {
      this.tableContext.sheetId = this.props.sheetId;
    }
  }

  public render(): JSX.Element {
    const {
      handlePaste,
      sheetId,
      focusedCell,
      reportEditPermission,
      onSendToClipboard,
      twoDInnerClipboard,
      sheetName,
    } = this.props;

    return (
      <ExcelTable
        key={sheetId}
        onCellFocused={this.getCellFocusHandler(sheetId)}
        valueGetter={this.castedValueGetter}
        saveRef={this.saveApiRef}
        removeRef={this.removeApiRef}
        defaultColDef={this.defaultColDef}
        columns={this.state.columns}
        tableContext={this.tableContext as ExcelTableContext}
        onAddRows={this.onAddRows}
        onAddColumns={this.onAddColumns}
        focusedCell={focusedCell.cell}
        handlePaste={handlePaste}
        onCellRangeChanged={this.onCellRangeChanged}
        onColumnResize={this.onColumnResize}
        onFilterChange={this.onFilterChange}
        insertRow={this.insertRow}
        insertColumn={this.insertColumn}
        deleteRow={this.deleteRow}
        deleteColumn={this.deleteColumn}
        isEditable={reportEditPermission}
        sheetId={sheetId}
        sheetName={sheetName}
        onSendToClipboard={onSendToClipboard}
        tableClipboard={twoDInnerClipboard}
        getExtraContextMenu={this.getExtraContextMenu}
      />
    );
  }

  @autobind
  private getComment(colId: string, rowIndex: number): CommentaryThread {
    return this.props.comments.find(c => {
      if (CommentaryTargetTypeGuards.isSpreadsheetPage(c.target)
      && c.target.reportPageId === this.props.sheetId
      && c.target.address !== '') {
        const { columnId: targetColumnId, rowId } = TwoDRegexGetter.getFullCellField(c.target.address);
        const targetRowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
        return targetColumnId === colId && targetRowIndex === rowIndex;
      }
    });
  }

  @autobind
  private isCommentSelected(colId: string, rowIndex: number): boolean {
    const comments = this.props.comments.filter(c => {
      if (CommentaryTargetTypeGuards.isSpreadsheetPage(c.target)
      && c.target.reportPageId === this.props.sheetId
      && c.target.address !== '') {
        const { columnId: targetColumnId, rowId } = TwoDRegexGetter.getFullCellField(c.target.address);
        const targetRowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
        return targetColumnId === colId && targetRowIndex === rowIndex;
      }
      return false;
    });

    return comments.some(c => c.id === this.props.selectedCommentId);
  }

  @autobind
  private getExtraContextMenu(): Array<(string | Ag.MenuItemDef)> {
    const extraItem = [];
    if (this.props.ability.can(Operation.Manage, Subject.Comment2d)) {
      extraItem.push(
        {
          name: 'Add Comment',
          action: this.startCreateComment,
          icon: ReactDOMServer.renderToString(<Icons.Comments2D />),
        },
      );
    }
    return extraItem;
  }

  @autobind
  private startCreateComment(): void {
    const { focusedCell, selectedSheetId } = this.props;
    this.props.startCreateComment({
      type: CommentaryTargetType.SpreadsheetReportPage,
      address: focusedCell.cell.cellId,
      reportPageId: selectedSheetId,
    });
  }

  @autobind
  private getInstancesMeasures(instancesIds: string[]): DrawingsInstanceMeasure[] {
    const { drawings, aiAnnotation, elementMeasurement } = this.props;
    return this.props.drawingsLayoutApi.getInstancesMeasures(
      instancesIds,
      drawings,
      aiAnnotation,
      elementMeasurement,
    );
  }

  @autobind
  private insertRow(offset: number, startIndex: number): void {
    const {
      addRows,
      getSheetDataStore,
      selectedSheetId,
      cellToCellMap,
      updateCells,
    } = this.props;

    addRows(selectedSheetId, offset);

    const updatedCells = TableDataMoveHelper.getUpdatedCells({
      offset,
      startIndex,
      sheetId: selectedSheetId,
      cellToCellMap,
      type: TableDataMoveType.InsertRows,
      sheetsData: getSheetDataStore(),
    });
    updateCells(updatedCells);
    this.moveCommentsAfterInserRow(offset, startIndex);
  }

  private moveCommentsAfterInserRow(offset: number, startIndex: number): void {
    const { comments, sheetId, moveSpreadSheetComments, projectId } = this.props;
    for (const comment of comments) {
      const target = comment.target;
      if (!(CommentaryTargetTypeGuards.isSpreadsheetPage(target)
      && target.reportPageId === sheetId
      && target.address !== '')) {
        continue;
      }

      const { columnId, rowId } = TwoDRegexGetter.getFullCellField(target.address);
      const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
      if (rowIndex < startIndex) {
        continue;
      }
      const newRowId = ExcelTableRowIdentification.getRowIdFromIndex(rowIndex + offset);
      const newTarget: CommentarySpreadsheetReportPageTarget = { ...target, address: `${columnId}${newRowId}` };
      moveSpreadSheetComments(projectId, comment.id, newTarget);
    }
  }

  @autobind
  private insertColumn(offset: number, startIndex: number): void {
    const { addColumns, getSheetDataStore, selectedSheetId, cellToCellMap, updateCells } = this.props;

    addColumns(selectedSheetId, offset);

    const updatedCells = TableDataMoveHelper.getUpdatedCells(
      {
        offset,
        startIndex,
        sheetId: selectedSheetId,
        cellToCellMap,
        type: TableDataMoveType.InsertColumns,
        sheetsData: getSheetDataStore(),
      },
    );
    updateCells(updatedCells);
    this.moveCommentsAfterInserColumn(offset, startIndex);
  }

  private moveCommentsAfterInserColumn(offset: number, startIndex: number): void {
    const { comments, sheetId, moveSpreadSheetComments, projectId } = this.props;
    for (const comment of comments) {
      const target = comment.target;
      if (!(CommentaryTargetTypeGuards.isSpreadsheetPage(target)
      && target.reportPageId === sheetId
      && target.address !== '')) {
        continue;
      }

      const { columnId, rowId } = TwoDRegexGetter.getFullCellField(target.address);
      const columnIndex = ExcelColumnHelper.excelColNameToIndex(columnId);
      if (columnIndex < startIndex) {
        continue;
      }
      const newColumnId = ExcelColumnHelper.indexToExcelColName(columnIndex + offset);
      const newTarget: CommentarySpreadsheetReportPageTarget = { ...target, address: `${newColumnId}${rowId}` };
      moveSpreadSheetComments(projectId, comment.id, newTarget);
    }
  }

  @autobind
  private deleteRow(offset: number, startIndex: number): void {
    const { getSheetDataStore, selectedSheetId, cellToCellMap, updateCells } = this.props;

    const updatedCells = TableDataMoveHelper.getUpdatedCells({
      offset,
      startIndex,
      sheetId: selectedSheetId,
      cellToCellMap,
      type: TableDataMoveType.DeleteRows,
      sheetsData: getSheetDataStore(),
    });
    updateCells(updatedCells);
    this.moveCommentsAfterDeleteRow(offset, startIndex);
  }

  private moveCommentsAfterDeleteRow(offset: number, startIndex: number): void {
    const { comments, sheetId, moveSpreadSheetComments, projectId } = this.props;
    for (const comment of comments) {
      const target = comment.target;
      if (!(CommentaryTargetTypeGuards.isSpreadsheetPage(target)
      && target.reportPageId === sheetId
      && target.address !== '')) {
        continue;
      }

      const { columnId, rowId } = TwoDRegexGetter.getFullCellField(target.address);
      const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
      if (rowIndex < startIndex) {
        continue;
      }

      if (this.isIndexInRange(rowIndex, startIndex, offset)) {
        moveSpreadSheetComments(projectId, comment.id, { ...target, address: '' });
        continue;
      }

      const newIndex = rowIndex - offset;
      const newRowId = ExcelTableRowIdentification.getRowIdFromIndex(newIndex);
      const newTarget: CommentarySpreadsheetReportPageTarget = newIndex < 0
        ? { ...target, address: '' }
        : { ...target, address: `${columnId}${newRowId}` };
      moveSpreadSheetComments(projectId, comment.id, newTarget);
    }
  }

  @autobind
  private deleteColumn(offset: number, startIndex: number): void {
    const { getSheetDataStore, selectedSheetId, cellToCellMap, updateCells } = this.props;

    const updatedCells = TableDataMoveHelper.getUpdatedCells(
      {
        offset,
        startIndex,
        sheetId: selectedSheetId,
        cellToCellMap,
        type: TableDataMoveType.DeleteColumns,
        sheetsData: getSheetDataStore(),
      },
    );
    updateCells(updatedCells);
    this.moveCommentsAfterDeleteColumn(offset, startIndex);
  }

  private moveCommentsAfterDeleteColumn(offset: number, startIndex: number): void {
    const { comments, sheetId, moveSpreadSheetComments, projectId } = this.props;
    for (const comment of comments) {
      const target = comment.target;
      if (!(CommentaryTargetTypeGuards.isSpreadsheetPage(target)
      && target.reportPageId === sheetId
      && target.address !== '')) {
        continue;
      }

      const { columnId, rowId } = TwoDRegexGetter.getFullCellField(target.address);
      const columnIndex = ExcelColumnHelper.excelColNameToIndex(columnId);
      if (columnIndex < startIndex) {
        continue;
      }

      if (this.isIndexInRange(columnIndex, startIndex, offset)) {
        moveSpreadSheetComments(projectId, comment.id, { ...target, address: '' });
        continue;
      }
      const newColumnIndex = columnIndex - offset;
      const newColumnId = ExcelColumnHelper.indexToExcelColName(columnIndex - offset);
      const newTarget: CommentarySpreadsheetReportPageTarget = newColumnIndex < 1
        ? { ...target, address: '' }
        : { ...target, address: `${newColumnId}${rowId}` };
      moveSpreadSheetComments(projectId, comment.id, newTarget);
    }
  }

  private isIndexInRange(index: number, startIndex: number, offset: number): boolean {
    return index === startIndex || offset > 1 && index > startIndex && index <= startIndex + offset;
  }

  @autobind
  private onColumnResize(payload: Array<{ columnId: string, width: number, prevWidth: number }>): void {
    const columnsData = [];
    const prevColumnsData = [];
    payload.forEach(({ columnId, width, prevWidth }) => {
      columnsData.push({ columnId, width });
      prevColumnsData.push({ columnId, width: prevWidth });
    });
    const { undo, redo } = TwoDReportUndoRedoHelper.getUpdateColumnWidthUndoRedo(
      prevColumnsData,
      columnsData,
      this.updateColumnWidth,
    );
    this.props.addUndoRedo(undo, redo);
    this.saveColumnWidth(payload);
  }

  @autobind
  private updateColumnWidth(paylaoad: UpdateColumnWidth[]): void {
    this.state.gridApiRef.updateColumnWidth(paylaoad);
    this.saveColumnWidth(paylaoad);
  }

  @autobind
  private saveColumnWidth(payload: Array<{ columnId: string, width: number }>): void {
    const { sheetId } = this.props;
    this.props.setColumnWidth(sheetId, payload);
  }

  private updateCurrentPageCells(cells: UpdateCellData[]): void {
    const payload = { sheetId: this.props.sheetId, form: cells };
    this.props.updateCells([payload]);
  }

  @autobind
  private onFilterChange(): void {
    const filterNodeId = {};
    this.state.gridApiRef.api.forEachNodeAfterFilter(rowNode => {
      filterNodeId[rowNode.id] = true;
    });

    this.props.setFilteredNodeId(filterNodeId);
  }

  @autobind
  private saveApi(): void {
    this.props.saveApi(
      this.state.gridApiRef,
      this.props.sheetId,
      this.updateColumns,
    );
  }

  @autobind
  private onAddRows(count: number): void {
    const pageId = this.props.sheetId;
    this.props.addRows(pageId, count);
  }


  @autobind
  private onAddColumns(count: number): void {
    const pageId = this.props.sheetId;
    this.props.addColumns(pageId, count);
  }

  @autobind
  private onCellDelete(updatedCell: UpdateCellData[]): void {
    this.updateCurrentPageCells(updatedCell);
  }

  @autobind
  private valueSetter(params: Ag.ValueSetterParams): boolean {
    const { sheetId, updateCells } = this.props;
    const type = params.newValue.type;
    const updateCellPayload = this.getUpdateCellPayload(params);

    if (type && type === CellFillHandler.FILL_OPERATION_VALUE) {
      this.props.updateCellDataOnFill(sheetId, updateCellPayload);
    } else {
      for (const p of updateCellPayload) {
        if (!isValidFormulaQuote(p.value.toString())) {
          this.props.openDialog(() => {
            const editingParams: Ag.StartEditingCellParams = {
              rowIndex: ExcelTableRowIdentification.getRowIndexFromId(p.rowId),
              colKey: p.columnId,
            };
            params.api.startEditingCell(editingParams);
          });
          return false;
        }
      }
      const payload = {
        sheetId,
        form: updateCellPayload,
      };
      updateCells([payload]);
    }
    return false;
  }

  private getValueToUpdate(params: Ag.ValueSetterParams): string | number {
    switch (params.newValue.type) {
      case CellFillHandler.FILL_OPERATION_VALUE:
        return params.newValue.data;
      case ClipboardHelper.CLIPBOARD_VALUE:
        return this.processNewValue(params.newValue.value);
      default:
        return this.processNewValue(params.newValue);
    }
  }

  private processNewValue(value: string): string | number {
    const { sheetId, reportPages } = this.props;
    if (ValueHelper.isNumberValue(value)) {
      return Number(value);
    }

    if (this.isFormula(value)) {
      const normalizedFormula = ExcelFormulaHelper.autoCloseParentheses(value);
      return ExcelFormulaHelper.replaceSheetNameToSheetId(
        ExcelFormulaHelper.extendCellIdBySheetName(normalizedFormula, sheetId, reportPages),
        reportPages,
      );
    }
    return value;
  }

  private getUpdateCellPayload(params: Ag.ValueSetterParams): UpdateCellForm[] {
    const { sheetId, selectSheetData } = this.props;
    const columnId = params.column.getId();
    const rowId = params.node.id;
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const row = selectSheetData(sheetId).rows[rowIndex];
    const newValue = this.getValueToUpdate(params);

    const cells = [{
      columnId,
      rowId,
      value: newValue,
      prevValue: row[columnId],
    }];

    if (params.newValue.settings) {
      const configColumnId = getConfigId(columnId);
      cells.push({
        rowId,
        columnId: configColumnId,
        value: params.newValue.settings,
        prevValue: row[configColumnId],
      });
    }

    return cells;
  }

  @autobind
  private onEditCellStart(): void {
    this.props.setRead(true);
  }

  @autobind
  private onEditCellEnd(): void {
    this.props.setRead(false);
  }

  @autobind
  private getCellFocusHandler(sheetId: string): (cellData: CellFocusData) => void {
    return (cellData) => {
      this.onCellFocusedWithUndoRedo(sheetId, cellData);
      this.onCellFocused(sheetId, cellData);
    };
  }

  @autobind
  private onCellFocusedWithUndoRedo(sheetId: string, cellData: CellFocusData): void {
    const { selectedSheetId, focusedCell, onFocusChange } = this.props;
    if (cellData === null || (selectedSheetId === sheetId && focusedCell.cell.cellId === cellData.cellId)) {
      return;
    }

    const focusedData = {
      prevData: {
        sheetId: selectedSheetId,
        cellData: focusedCell.cell,
      },
      newData: {
        sheetId,
        cellData,
      },
    };

    onFocusChange(focusedData);
  }

  @autobind
  private onCellFocused(sheetId: string, cellData: CellFocusData): void {
    if (cellData === null) {
      return;
    }
    this.props.setFocusedCell(cellData, sheetId);
    const sheetData = this.props.selectSheetData(sheetId);
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(cellData.rowId);
    const row = sheetData.rows[rowIndex];
    const rowData = row[cellData.columnId];
    const data = rowData !== undefined && rowData !== null
      ? rowData.toString()
      : '';
    this.props.setFormulaBarValue(data);
    const drawingInstanceInCellRange = {};
    this.appendDrawingsInstanceInCellData(cellData.columnId, cellData.rowId, drawingInstanceInCellRange);
    this.props.setDrawingInstanceInCellRange(this.props.sheetId, drawingInstanceInCellRange);
  }

  @autobind
  private onCellRangeChanged(ranges: Ag.CellRange[]): void {
    this.props.onCellRangeChanged(ranges);
    const modelApi = this.state.gridApiRef?.api.getModel();
    if (ranges.length && this.state.gridApiRef) {
      const drawingInstanceInCellRange = {};

      ranges.forEach(range => {
        const fromIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
        const toIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
        for (let i = fromIndex; i <= toIndex; i++) {
          const row = modelApi.getRow(i);
          if (!row) {
            return;
          }
          range.columns.forEach(c =>
            this.appendDrawingsInstanceInCellData(
              c.getColId(),
              row.id,
              drawingInstanceInCellRange,
            ));
        }
      });

      this.props.setDrawingInstanceInCellRange(this.props.sheetId, drawingInstanceInCellRange);
    }
  }

  private appendDrawingsInstanceInCellData(
    columnId: string,
    rowId: string,
    drawingInstanceInCellRange: Record<string, boolean>,
  ): void {
    const cellValue = this.props.selectCellData(this.props.sheetId, columnId, rowId);
    if (!cellValue) {
      return;
    }
    const cellStringValue = cellValue.toString();
    let match;
    // eslint-disable-next-line no-cond-assign
    while (match = TwoDRegex.formulaPartGlobal.exec(cellStringValue)) {
      if (match.groups.drawingInstance) {
        const instanceId = match.groups.drawingInstanceId;
        if (this.props.aiAnnotation.geometry[instanceId]) {
          drawingInstanceInCellRange[instanceId] = true;
        } else {
          getMeasurementsList(instanceId, this.props.drawingGroups, this.props.aiAnnotation.geometry)
            ?.forEach((id) => {
              drawingInstanceInCellRange[id] = true;
            });
        }
      }
    }
  }

  @autobind
  private saveApiRef(ref: ExcelTableApi): void {
    const { sheetId } = this.props;
    this.setState(
      { gridApiRef: ref },
      this.saveApi,
    );
    this.props.onSheetReady(sheetId);
  }

  @autobind
  private removeApiRef(sheetId: string): void {
    this.props.saveApi(null, sheetId);
  }

  private isFormula(value: string | number): boolean {
    return value && typeof value === 'string' && value.startsWith('=');
  }

  @autobind
  private castedValueGetter(params: Ag.ValueGetterParams): string | number {
    const result = this.valueGetter(params);
    return ValueHelper.isNumberValue(result)
      ? Number(result)
      : result;
  }

  @autobind
  private valueGetter(params: Ag.ValueGetterParams): string | number {
    const sheetId = this.props.sheetId;
    const rowId = params.node.id;
    const columnId = params.column.getColId().toUpperCase();
    return this.props.selectCellValue(sheetId, columnId, rowId);
  }

  @autobind
  private isValidCell(sheetId: string, columnId: string, rowId: string): boolean {
    const cellId = ExcelFormulaHelper.getCellLink(sheetId, columnId, rowId);
    return this.props.isValidCell(cellId);
  }

  @autobind
  private cellEditorValueFormatter(value: string | number): string {
    const { sheetId, reportPages } = this.props;
    return ExcelFormulaHelper.getFormattingFormulaValue(value.toString(), sheetId, reportPages);
  }

  @autobind
  private updateColumns(columns: Array<Record<string, string>>): void {
    this.setState({ columns });
    this.state.gridApiRef.api.setColumnDefs(columns);
  }

  @autobind
  private clickComment(id: number): void {
    this.props.openComment(id);
    this.props.sendEvent(MetricNames.comments.openComments, { target: 'spreadSheet' });
  }
}

function mapStateToProps(state: State): StateProps {
  const drawings = state.drawings;
  const innerClipboard = state.persistedStorage.innerClipboard;
  return {
    focusedCell: state.twoD.focusedCell,
    reportPages: state.twoD.reportPages,
    drawingInstanceToCell: state.twoD.drawingInstanceToCell,
    selectedSheetId: state.twoD.selectedSheetId,
    cellWithBoarder: state.twoD.cellWithBorder,
    cellToCellMap: state.twoD.cellToCell,
    isImperial: state.account.settings.isImperial,
    drawingsMeasure: drawings.elementMeasurement,
    drawingsInfo: drawings.drawingsInfo,
    drawingGroups: drawings.drawingGeometryGroups,
    entities: drawings.files.entities,
    elementMeasurement: drawings.elementMeasurement,
    drawings: drawings.drawingsInfo,
    aiAnnotation: drawings.aiAnnotation,
    currentDrawing: drawings.currentDrawingInfo,
    twoDInnerClipboard: innerClipboard ? innerClipboard.twoDTable : { rows: [], expectedClipboard: '' },
    selectedCommentId: state.twoDComments.selectedCommentId,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    openDialog: (callBack) =>
      dispatch(KreoDialogActions.openDialog(QUOTE_EXCEPTION_DIALOG, callBack)),
    setFocusedCell: (data, sheetId) => dispatch(TwoDActions.setFocusCell({ data, sheetId })),
    setRead: (value) => dispatch(TwoDActions.setRead(value)),
    setDrawingInstanceInCellRange:
      (sheetId, ranges) => dispatch(TwoDActions.setDrawingInstanceInCellRange(sheetId, ranges)),
    setFilteredNodeId: (filteredNodeIds) => dispatch(TwoDActions.setFilteredNodeId(filteredNodeIds)),
    onSendToClipboard: (
      rows: string[][],
      expectedClipboard: string,
    ) => dispatch(PersistedStorageActions.setTwoDTableInnerClipboard({ rows, expectedClipboard })),
    startCreateComment: (target) => dispatch(TwoDCommentsActions.startAddComment(target)),
    openComment: (commentId) => {
      dispatch(TwoDCommentsActions.selectComment(commentId));
      dispatch(TwoDCommentsActions.setSelectedCommentId(commentId));
    },
    moveSpreadSheetComments: (projectId, commentId, target) => dispatch(TwoDCommentsActions.moveComments({
      projectId, commentId, target,
    })),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export const TwoDSheet =  withAnalyticsContext(
  withAbilityContext(connector(withUndoRedoApiProps(withCommentsContext(TwoDSheetComponent)))),
);
