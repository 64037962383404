import styled from 'styled-components';

const Header = styled.div`
  box-sizing: border-box;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid ${p => p.theme.color.background};

  > p {
    line-height: 14px;
  }
`;

const MenuButton = styled.div`
  margin-right: 5px;
`;

const HeaderButtonsLeft = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
`;


export const Styled = {
  Header,
  MenuButton,
  HeaderButtonsLeft,
};
