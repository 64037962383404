import React from 'react';

import { DrawingsDrawMode } from '../../enums';
import { DrawingsSelectAggregationGroup } from '../../interfaces';
import { InstrumentsTypeGuards } from '../helpers/instruments-type-guards';
import { InstrumentGroupInfo, InstrumentInfo } from '../interfaces';
import { InstrumentGroup } from './instrument-menu-group';
import { InstrumentMenuItem } from './instruments-menu-item';
import { SelectModeSettings } from './interfaces';
import { Styled } from './styled';

interface Props {
  horizontalMode: boolean;
  activeMenuItem: DrawingsDrawMode;
  size: string;
  tooltipDelay: string;
  tooltipPosition: string;
  onClick: (selectMode: SelectModeSettings) => void;
  tools: Array<InstrumentInfo | InstrumentGroupInfo>;
  instanceType: DrawingsSelectAggregationGroup;
}

export const InstrumentMenu: React.FC<Props> = ({
  horizontalMode,
  size = 'big',
  onClick,
  tools,
  activeMenuItem,
  instanceType,
}) => {
  return (
    <Styled.Container
      horizontalMode={horizontalMode}
      size={size}
    >
      {tools.map(instrument => {
        if (instrument.hidden) {
          return null;
        }
        if (InstrumentsTypeGuards.isGroup(instrument)) {
          return (
            <InstrumentGroup
              group={instrument}
              onClick={onClick}
              activeMenuItem={activeMenuItem}
              key={instrument.groupId}
              canBeActive={!instrument.resultType || instrument.resultType === instanceType}
            />
          );
        } else {
          return (
            <InstrumentMenuItem
              onClick={onClick}
              instrument={instrument}
              key={instrument.uniqueId}
              isActive={instrument.id === activeMenuItem}
            />
          );
        }
      })}
    </Styled.Container>
  );
};
