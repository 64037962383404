import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { ParametrizedAppRoute } from 'common/routing/parametrized-app-route';
import { AppUrls } from 'routes/app-urls';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';


interface Props extends RouteComponentProps<PlanProjectRouteParams>, AbilityAwareProps {}

const getProjectIndexRoutes = (): Array<ParametrizedAppRoute<PlanProjectRouteParams>> => [
  AppUrls.plan.project.viewer,
  AppUrls.plan.project.validation.index,
  AppUrls.plan.project.dashboard,
  AppUrls.plan.project.scenario.listing,
  AppUrls.plan.project.costs,
  AppUrls.plan.project.schedule,
  AppUrls.plan.project.fourD,
  AppUrls.plan.project.bidPricing.index,
];

class IndexProjectPageComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const firstProjectUrl = this.getFirstProjectIndexUrl();
    return <Redirect to={firstProjectUrl || AppUrls.plan.listing.url()} />;
  }

  private getFirstProjectIndexUrl(): string | null {
    const viewablePaths = getProjectIndexRoutes().filter(route =>
      this.canViewProjectIndexPath(route.path),
    );
    return viewablePaths.length > 0 ? viewablePaths[0].url(this.props.match.params) : null;
  }

  private canViewProjectIndexPath(path: string): boolean {
    let subject: Subject;
    switch (path) {
      case AppUrls.plan.project.viewer.path:
        subject = Subject.Viewer;
        break;
      case AppUrls.plan.project.validation.index.path:
        subject = Subject.ValidationModelCheck;
        break;
      case AppUrls.plan.project.dashboard.path:
        subject = Subject.Dashboard;
        break;
      case AppUrls.plan.project.scenario.listing.path:
        subject = Subject.Scenarios;
        break;
      case AppUrls.plan.project.costs.path:
        subject = Subject.Cost;
        break;
      case AppUrls.plan.project.schedule.path:
        subject = Subject.Gantt;
        break;
      case AppUrls.plan.project.fourD.path:
        return true;
      case AppUrls.plan.project.bidPricing.index.path:
        subject = Subject.BidPricing;
        break;
      default:
        return false;
    }
    return this.props.ability.can(Operation.Read, subject);
  }
}

export const IndexProjectPage = withAbilityContext(IndexProjectPageComponent);
