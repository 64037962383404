import Ag from 'ag-grid-community';
import { Constants } from 'common/components/data-base-table/constants';

import { RowData, UserInfo } from 'common/components/data-base-table/interfaces';
import { Assembly, Group, Item, SingleSelect } from '../../interfaces';
import { calcPath } from '../calc-path';
import { mapDate } from '../map-date';
import { RenderIconsUtils } from '../render-icons';


interface AppendItemPayload {
  path: string;
  overload: Item;
}


export const mapAssemblies = (
  assemblies: Assembly[],
  groups: Group[],
  baseItems: Item[],
  users: UserInfo[],
  hideItems?: boolean,
): { rowData: RowData[], columns: Ag.ColDef[] } => {
  const columns: Record<string, Ag.ColDef> = {};

  const groupPathMap = calcPath(groups, (e) => e.id);
  const itemsToAppend: Record<string, AppendItemPayload[]> = {};

  const rowData = groups.map((group) => {
    return {
      ...group,

      h_path: groupPathMap[group.id],
      h_isDragSource: true,
      h_isDragTarget: true,
    };
  });

  assemblies.forEach((assembly) => {
    const assemblyRowData = {
      h_path: groupPathMap[assembly.folderId]
        ? `${groupPathMap[assembly.folderId]}${Constants.DATA_PATH_SPLITER}${assembly.id}`
        : `${assembly.id}`,
      h_isDragSource: true,
      h_isDragTarget: false,
      h_name_icon: RenderIconsUtils.renderAssemblyIcon(),

      id: assembly.id,
      folderId: assembly.folderId,
      name: assembly.name,

      createdBy: users.find(u => u.userId === assembly.creatorId),
      createdTime: mapDate(assembly.createdAt),
      lastEditBy: users.find(u => u.userId === assembly.editorId),
      lastEditTime: mapDate(assembly.editedAt),
    };

    rowData.push(assemblyRowData);

    if (!hideItems) {
      assembly.items.forEach((item) => {
        const payload = { path: assemblyRowData.h_path, overload: item };

        if (itemsToAppend[item.baseItemId]) {
          itemsToAppend[item.baseItemId].push(payload);
        } else {
          itemsToAppend[item.baseItemId] = [payload];
        }
      });
    }
  });

  baseItems.forEach(item => {
    const assembliesItemPayload = itemsToAppend[item.id];
    if (!assembliesItemPayload) {
      return;
    }
    assembliesItemPayload.forEach(assemblyItemPayload => {
      const propertiesValueMap: Record<string, string | number | SingleSelect> = {};
      item.properties.forEach(property => {
        const propertyId = property.id;
        columns[propertyId] = { colId: propertyId, field: property.name, headerName: property.name, hide: true };
        propertiesValueMap[property.name] = property.value.value;

        if (assemblyItemPayload.overload[propertyId] !== undefined
          && assemblyItemPayload.overload[propertyId].value !== undefined) {
          propertiesValueMap[property.name] = assemblyItemPayload.overload[propertyId].value;
        }
      });
      const itemRowData = {
        h_path: `${assemblyItemPayload.path}${Constants.DATA_PATH_SPLITER}${item.id}`,
        h_isDragSource: false,
        h_isDragTarget: false,
        h_name_icon: RenderIconsUtils.renderItemIcon(item.iconType),
        id: item.id,
        folderId: item.folderId,
        name: item.name,
        createdBy: users.find(u => u.userId === item.creatorId),
        createdTime: mapDate(item.createdAt),
        lastEditBy: users.find(u => u.userId === item.editorId),
        lastEditTime: mapDate(item.editedAt),
        ...propertiesValueMap,
      };
      rowData.push(itemRowData);
    });
  });


  return { rowData, columns: Object.values(columns) };
};
