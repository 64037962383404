import React from 'react';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { LAST_COMMA_REGEX } from '../../../../constants/regex-templates';

interface Props {
  extensions: string[];
}

export const FILE_FORMAT_SUPPORTED_DIALOG = 'FILE_FORMAT_SUPPORTED_DIALOG';

const FileFormatSupportedDialogComponent: React.FC<Props> = ({
  extensions,
}) => {

  const extensionsString = extensions
    .map((x) => x.toUpperCase())
    .join(', ')
    .replace(LAST_COMMA_REGEX, (x) => x.replace(', ', ' or '));

  return (
    <ConfirmationDialog
      name={FILE_FORMAT_SUPPORTED_DIALOG}
      title={`The File Format is unsupported`}
      text={`Select ${extensionsString} Files`}
      cancelButtonText="Okay!"
    />
  );
};


export const FileFormatSupportedDialog = React.memo(FileFormatSupportedDialogComponent);
