import React from 'react';

export const useHandleChangePlanCallback = (): [boolean, () => void] => {
  const [isChangePlan, setIsChange] = React.useState(false);
  const handleChangePlan = React.useCallback(() => {
    setIsChange(true);
  }, [setIsChange]);

  return [isChangePlan, handleChangePlan];
};
