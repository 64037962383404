import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

export const Container = styled.div<{ isActive: boolean, isDisabled: boolean }>`
  height: 20px;
  margin-right: 10px;
  border-radius: 5px 5px 0px 0px;
  background: ${p => p.theme.color[p.isActive ? 'backgroundRush' : 'background']};
  border-bottom-color: ${p => p.theme.color[p.isActive ? 'backgroundRush' : 'background']};
  cursor: ${p => (p.isDisabled ? 'default' : 'pointer')};
  pointer-events: ${p => (p.isDisabled ? 'none' : 'auto')};

  ${Text} {
    margin: -3px 20px 0 20px;
    font-size: 12px;
  }
`;

export const Styled = {
  Container,
};
