import * as React from 'react';

export const KreoIconAssign = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <path
      d='M7.25,14.4c-.74.74-.45,2.25-2.25,4,.9.9,4.21-.6,5-1.35a1.91,1.91,0,1,0-2.71-2.7Z'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.93,5.34c-.75-.75-7.37,4.66-8.11,5.41a1.91,1.91,0,0,0,2.7,2.7C14.27,12.71,19.68,6.09,18.93,5.34Z'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
