import React, { useCallback, useEffect } from 'react';

import { DrawingsCalibrateMenu } from 'common/components/drawings/drawings-calibrate';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { Menu } from 'unit-2d-info-panel/constants/menus';
import { useOpenMenu } from 'unit-2d-info-panel/hooks/use-open-menu';


const ScaleMenuComponent: React.FC = () => {
  const closeScaleMenu = useOpenMenu(Menu.Default);
  const { setDrawMode, drawMode } = useDrawModeApi();

  const onCloseScaleMenu = useCallback(
    () => {
      setDrawMode(DrawingsDrawMode.Disabled);
      closeScaleMenu();
    },
    [closeScaleMenu, setDrawMode],
  );

  const isCalibrate = drawMode === DrawingsDrawMode.Calibrate || drawMode === DrawingsDrawMode.CalibrateScale;

  useEffect(() => {
    if (!isCalibrate) {
      closeScaleMenu();
    }
  }, [isCalibrate]);


  return isCalibrate ? (<DrawingsCalibrateMenu onCalibrated={onCloseScaleMenu}/>) : null;
};


export const ScaleMenu = React.memo(ScaleMenuComponent);
