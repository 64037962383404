import { useCallback } from 'react';
import { DrawingsGeometryGroup, DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import { MetricNames, useAnalytics } from 'utils/posthog';

export function useMoveToHandler(
  geometries: Record<string, DrawingsGeometryInstance>,
  selectedGroups: string[],
  selectedInstances: string[],
  groups: DrawingsGeometryGroup[],
  targetGroupId: string,
  handleMoveTo: (
    targetGroupId: string,
    groupsIds: string[],
    instancesIds: string[],
  ) => void,
): () => void {
  const { sendEvent } = useAnalytics();

  return useCallback(() => {
    sendEvent(MetricNames.measureManager.moveToGroup, { source: 'Manual' });
    const groupIsTarget = (groupId: string): boolean => {
      return groupId === targetGroupId || (!targetGroupId && !groupId);
    };
    if (selectedGroups.length) {
      const selectedGroupIdsSet = new Set(selectedGroups);
      const instancesIds = [];
      const groupsIds = [];

      const parentInSelectedGroups: Record<string, boolean> = {};

      const hasParentInSelectedGroups = (groupId: string): boolean => {
        if (parentInSelectedGroups[groupId]) {
          return parentInSelectedGroups[groupId];
        }

        if (selectedGroupIdsSet.has(groupId)) {
          parentInSelectedGroups[groupId] = true;
        } else {
          const group = groups.find(g => g.id === groupId);
          if (!group) {
            return false;
          }
          parentInSelectedGroups[groupId] = hasParentInSelectedGroups(group.parentId);
        }

        return parentInSelectedGroups[groupId];
      };

      for (const groupId of selectedGroups) {
        const group = groups.find(g => g.id === groupId);
        const isParentSelected = group.parentId ? hasParentInSelectedGroups(group.parentId) : false;
        parentInSelectedGroups[group.id] = isParentSelected;
        if (!isParentSelected && !groupIsTarget(group.id)) {
          groupsIds.push(groupId);
        }
      }

      for (const instanceId of selectedInstances) {
        const instance = geometries[instanceId];
        if ((!instance.groupId || !hasParentInSelectedGroups(instance.groupId)) && !groupIsTarget(instance.groupId)) {
          instancesIds.push(instanceId);
        }
      }
      handleMoveTo(targetGroupId, groupsIds, instancesIds);
    } else {
      const filteredInstances = selectedInstances.filter(id => !groupIsTarget(geometries[id].groupId));
      handleMoveTo(targetGroupId, [], filteredInstances);
    }
  }, [geometries, selectedGroups, selectedInstances, groups, useMoveToHandler, targetGroupId]);
}
