import { Text } from '@kreo/kreo-ui-components';
import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import remarkGfm from 'remark-gfm';
import { RenderIf } from 'common/components/render-if';
import { BotAvatar } from 'unit-2d-copilot/2d-copilot-popup/bot-avatar';
import { Buttons } from './buttons';
import { Styled } from './styled';

interface Props {
  content: string;
  withButtons?: boolean;
}

const BotMessageComponent: React.FC<Props> = ({
  content,
  withButtons,
}) => {
  return (
    <Styled.Container>
      <Styled.MessageContainer>
        <Styled.TextContainer>
          <Text
            fontSize={14}
            lineHeight={21}
          >
            <ReactMarkdown remarkPlugins={[remarkGfm]} >
              {content}
            </ReactMarkdown>
          </Text>
        </Styled.TextContainer>
        <BotAvatar/>
      </Styled.MessageContainer>
      <RenderIf condition={withButtons}>
        <Buttons content={content}/>
      </RenderIf>
    </Styled.Container>
  );
};

export const BotMessage = React.memo(BotMessageComponent);
