import { arrayUtils } from 'common/utils/array-utils';
import { delay } from 'common/utils/delay';
import { ProjectResourcesApi } from '../../../../../api/server';
import { WizzardStatus } from '../../enums/dropper-state';
import { PdfAiPolygonClickPayloadExtended } from '../../interfaces/api-payloads';
import { PdfAiClickResponse } from '../../interfaces/api-responses/pdf-geometry-response';
import { DrawingsApi } from '../drawings-api';
import { BaseStreamRunConfig, Stream } from '../streams';
import { StreamStatuses } from '../streams/stream-statuses';

export type BucketInput = { input: PdfAiPolygonClickPayloadExtended };

export interface BucketAiStreamConfig extends BaseStreamRunConfig<BucketInput, PdfAiClickResponse> {
  snap: boolean;
}

export class BucketAiStream extends Stream<BucketInput, PdfAiClickResponse, BucketAiStreamConfig> {
  public override async cancel(): Promise<void> {
    super.cancel();
    if (!this._jobId) {
      return Promise.resolve();
    }
    const { fileId, drawingId } = this._config;
    return this.sendCancelRequest(fileId, drawingId);
  }

  protected runRequest({ fileId, drawingId, request, snap }: BucketAiStreamConfig): Promise<string> {
    return DrawingsApi.processAiClick(fileId, drawingId, request, snap).then(({ id }) => id);
  }

  protected sendCancelRequest(fileId: string, drawingId: string): Promise<void> {
    return DrawingsApi.cancelAiClick(fileId, drawingId, this._jobId);
  }

  protected async streamRequest(config: BucketAiStreamConfig): Promise<void> {
    await super.streamRequest(config);
    const { fileId, drawingId, snap } = this._config;
    let status = StreamStatuses.IN_PROGRESS;
    let maskContours = [];
    while (status === StreamStatuses.IN_PROGRESS && !this._cancelled) {
      const result = await ProjectResourcesApi.get<{ status: StreamStatuses, stream: PdfAiClickResponse[] }>(
        `/drawings/files/${fileId}/pages/${drawingId}/geometries/ai-click/${this._jobId}/stream?snap=${snap}`,
      );
      if (result.status === StreamStatuses.IN_QUEUE) {
        await delay(500);
        status = StreamStatuses.IN_PROGRESS;
        continue;
      }
      status = result.status;
      if (status === StreamStatuses.COMPLETED) {
        await config.onProgress({ value: result.stream[result.stream.length - 1], status: WizzardStatus.Preview });
      } else if (status === StreamStatuses.FAILED) {
        await config.onProgress({ value: null, status: WizzardStatus.Error });
      } else {
        for (const stream of result.stream) {
          if (stream.output.type === 'snapshot') {
            status = StreamStatuses.COMPLETED;
          }
          arrayUtils.extendArray(stream.output.maskContours, maskContours);
          maskContours = stream.output.maskContours;
          await config.onProgress({
            value: stream,
            status: status === StreamStatuses.IN_PROGRESS ? WizzardStatus.Loading : WizzardStatus.Preview,
          });
          await delay(10);
        }
      }
    }
  }
}
