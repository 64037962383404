import { Icons, MenuItem } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { ColorsName } from 'common/enums/kreo-colors';


export const WhatsNewButton: React.FC = () => {
  return (
    <a
      href='https://www.kreo.net/news-2d-takeoff'
      target='_blank'
    >
      <MenuItem
        Icon={Icons.WhatsNew}
        text="What's New"
        onClick={ConstantFunctions.doNothing}
        fontSize={14}
        size='m'
        textColor={ColorsName.mainFont}
        withBorder={true}
        withoutWaves={true}
      />
    </a>
  );
};
