import { ViewModelStatus } from 'unit-projects/enums/view-model-status';

interface Rasterizable {
  rasterizationStatus?: ViewModelStatus;
}

function isRasterizationReady({ rasterizationStatus }: Rasterizable): boolean {
  return rasterizationStatus === ViewModelStatus.Ready || rasterizationStatus === ViewModelStatus.Empty;
}

function canRunRasterization({ rasterizationStatus }: Rasterizable): boolean {
  return rasterizationStatus !== ViewModelStatus.Calculating && rasterizationStatus !== ViewModelStatus.Ready;
}

export const RasterizationFileUtils = {
  isRasterizationReady,
  canRunRasterization,
};
