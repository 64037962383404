import * as t from 'io-ts';

export const CEActivityAssignmentDataSelectedVariantC = t.partial(
  {
    autoId: t.number,
    manualId: t.number,
  },
  'CEActivityAssignmentDataSelectedVariant',
);

export type CEActivityAssignmentDataSelectedVariant = t.TypeOf<typeof CEActivityAssignmentDataSelectedVariantC>;
