import React from 'react';
import { MovableContainer } from 'common/components/dialog-container/movable-container';
import { SelectPanel } from 'unit-2d-database/components/side-panel/components/measure-panel/select-panel';
import { Styled } from '../styled';


interface Props {
  top: number;
  isOpen: boolean;
  handleSelectAssemblies: (ids: string[]) => void;
  handleSelectItems: (ids: string[]) => void;
}

export const SelectPanelWrapper: React.FC<Props> = ({
  top,
  isOpen,
  handleSelectAssemblies,
  handleSelectItems,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <MovableContainer x={0} y={top} right={true}>
      <Styled.SelectPanelContainer>
        <SelectPanel handleSelectAssemblies={handleSelectAssemblies} handleSelectItems={handleSelectItems} />
      </Styled.SelectPanelContainer>
    </MovableContainer>
  );
};

