import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { ProjectsFolder, ProjectsFolderFormData } from '../../interfaces/projects-folder';
import { RemoveFolderPayload } from '../payloads/projects-folders';
import { ProjectsFolderActionTypes } from '../types/projects-folder';

function getFolders(): Action {
  return {
    type: ProjectsFolderActionTypes.GET_FOLDERS,
  };
}

function setFolders(
  folders: ProjectsFolder[],
): ActionWith<ProjectsFolder[]> {
  return {
    type: ProjectsFolderActionTypes.SET_FOLDERS,
    payload: folders,
  };
}

function createFolder(
  data: ProjectsFolderFormData,
): ActionWith<ProjectsFolderFormData> {
  return {
    type: ProjectsFolderActionTypes.CREATE_FOLDER,
    payload: data,
  };
}

function createFolderSuccess(
  folder: ProjectsFolder,
): ActionWith<ProjectsFolder> {
  return {
    type: ProjectsFolderActionTypes.CREATE_FOLDER_SUCCESS,
    payload: folder,
  };
}

function removeFolder(
  folderId: number,
  force: boolean,
): ActionWith<RemoveFolderPayload> {
  return {
    type: ProjectsFolderActionTypes.REMOVE_FOLDER,
    payload: { folderId, force },
  };
}

function removeFolderSuccess(
  id: number,
): ActionWith<number> {
  return {
    type: ProjectsFolderActionTypes.REMOVE_FOLDER_SUCCESS,
    payload: id,
  };
}

function setRemovingFolderInfo(
  users: string[],
): ActionWith<string[]> {
  return {
    type: ProjectsFolderActionTypes.SET_REMOVING_FOLDER_INFO,
    payload: users,
  };
}

function updateFolder(
  folder: ProjectsFolder,
): ActionWith<ProjectsFolder> {
  return {
    type: ProjectsFolderActionTypes.UPDATE_FOLDER,
    payload: folder,
  };
}

function updateFolderSuccess(
  folder: ProjectsFolder,
): ActionWith<ProjectsFolder> {
  return {
    type: ProjectsFolderActionTypes.UPDATE_FOLDER_SUCCESS,
    payload: folder,
  };
}

function setCurrentFolder(
  folder: ProjectsFolder,
): ActionWith<ProjectsFolder> {
  return {
    type: ProjectsFolderActionTypes.SET_CURRENT_FOLDER,
    payload: folder,
  };
}

export const ProjectsFolderActions = {
  getFolders,
  setFolders,
  createFolder,
  createFolderSuccess,
  removeFolder,
  removeFolderSuccess,
  setRemovingFolderInfo,
  updateFolder,
  updateFolderSuccess,
  setCurrentFolder,
};
