import { Icons, RoundButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import QueryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { CompaniesSelectWithTheme } from 'common/components/companies-select-with-theme';
import { PageHeader } from 'common/components/page-header';
import { RenderIf } from 'common/components/render-if';
import { SubscriptionType } from 'common/constants/subscription';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { MetaTagUtils } from 'common/utils/meta-tag-utils';
import { TRANSITION_POINT } from '../../../../constants';
import { AppUrls } from '../../../../routes/app-urls';
import { SpinnerPage } from '../../../../routes/spinner-page';
import { MenuSelectProduct } from '../../../../units/2d-navigation/menu-select-product';
import { AnalyticContext } from '../../../../utils/posthog';
import { AnalyticsProps, withAnalyticsContext } from '../../../../utils/posthog/analytics-wraper';
import { MetricNames } from '../../../../utils/posthog/metric-names';
import { SubscriptionActions } from '../../actions/creators';
import {
  SubscribeNewUserApproveDialog,
  SUBSCRIBE_NEW_USER_APPROVE_DIALOG,
} from '../../components/subscribe-new-user-approve-dialog';
import { SubscriptionFaq } from '../../components/subscription-faq';
import { SubscriptionListing } from '../../components/subscription-listing';
import { BillingEntityStatus } from '../../enums/billing-entity-status';
import { BillingPeriodUnit } from '../../enums/billing-period-unit';
import { CreateSubscriptionForm } from '../../interfaces/create-subscription-form';
import { SubscriptionPlanListingModel } from '../../interfaces/subscription-plan-listing-model';
import { SubscriptionPlanVariantWithName } from '../../interfaces/subscription-plan-variant-with-name';
import { TempInvitePeople } from '../../store/interface';
import { SubscriptionListingUtils } from '../../utils/subscription-listing-utils';
import { Styled } from './styled';

interface StateProps {
  subscriptionType: SubscriptionType;
  subscriptionPlansRequestStatus: RequestStatus;
  plansModel: SubscriptionPlanListingModel | null;
  invitePeople: TempInvitePeople[];
}

interface DispatchProps {
  getSubscriptionPlans: (subscriptionType: SubscriptionType) => void;
  createFreeSubscription: (form: { planId: string, subscriptionType: string }) => void;
  openSubscriptionApproveDialog: () => void;
  createSubscription: (form: CreateSubscriptionForm) => void;
}

interface PageState {
  selectedPlanGroup: string;
  billingPeriod: BillingPeriodUnit;
  selectedAddonGroups: string[];
  isOpenMenuSelectProduct: boolean;
  isPinBlock: boolean;
}

interface PageProps extends StateProps, DispatchProps, RouteComponentProps<{}>, AnalyticsProps { }

class SubscribePageComponent extends React.Component<PageProps, PageState> {
  public static contextType = AnalyticContext;
  private containerRef: HTMLDivElement;

  constructor(props: PageProps) {
    super(props);

    const { period } = QueryString.parse(this.props.location.search);
    this.state = {
      billingPeriod: period in BillingPeriodUnit
        ? period
        : BillingPeriodUnit.Year,
      selectedAddonGroups: [],
      selectedPlanGroup: null,
      isOpenMenuSelectProduct: false,
      isPinBlock: false,
    };
  }

  public componentDidMount(): void {
    const { plansModel, subscriptionType } = this.props;
    const { billingPeriod } = this.state;
    this.getPlansIfNeeded();
    this.setDefaultSelectedPlanIfNeeded();
    MetaTagUtils.setTitle('Select Plans');

    const filteredPlanGroups = this.filterVariation(
      SubscriptionListingUtils.getFilteredPlanGroups(plansModel, billingPeriod),
    );
    const planGroups = filteredPlanGroups?.filter(plan => plan.isSelfService);
    if (planGroups && planGroups.length === 1 && subscriptionType === SubscriptionType.Takeoff2d) {
      this.onSelectPlanGroup(planGroups[0].name);
    }
  }

  public componentDidUpdate(): void {
    this.getPlansIfNeeded();
  }

  public render(): React.ReactNode {
    if (!this.props.plansModel) {
      return <SpinnerPage />;
    }

    const { plansModel, subscriptionType } = this.props;
    const { billingPeriod, selectedAddonGroups, isOpenMenuSelectProduct } = this.state;
    const filteredPlanGroups = this.filterVariation(
      SubscriptionListingUtils.getFilteredPlanGroups(plansModel, billingPeriod),
    );
    this.extendListByFreePlan(filteredPlanGroups, plansModel);
    const filteredAddons = SubscriptionListingUtils.getFilteredAddons(plansModel, billingPeriod);
    const selectedAddons = SubscriptionListingUtils.getSelectedAddons(plansModel, billingPeriod, selectedAddonGroups);
    const isTakeoff2d = subscriptionType === SubscriptionType.Takeoff2d;
    const showMenuSelectProduct = isTakeoff2d && isOpenMenuSelectProduct;
    const redirectUrl = isTakeoff2d ? AppUrls.qto2d.billing : AppUrls.qto2d.index.path;

    return (
      <Styled.Container>
        <Styled.Header>
          <PageHeader
            redirectUrl={redirectUrl}
            logoSrc='/static/icons/kreo-logo-description.svg'
            Icon={Icons.KreoLogo2D}
            hideButton={true}
            onClick={this.openMenuSelectProduct}
            size={50}
          />
          <RenderIf condition={showMenuSelectProduct}>
            <MenuSelectProduct
              closeMenu={this.closeMenuSelectProduct}
            />
          </RenderIf>
        </Styled.Header>
        <CompaniesSelectWithTheme />
        <Styled.Main
          onScroll={this.onScroll}
          ref={this.saveContainerRef}
        >
          <SubscriptionListing
            billingPeriod={this.state.billingPeriod}
            filteredPlanGroups={filteredPlanGroups}
            selectedAddons={selectedAddons}
            onSubscriptionPlanGroupClick={this.onSelectPlanGroup}
            onBillingPeriodChanged={this.setBillingPeriod}
            isPinBlock={this.state.isPinBlock}
            hideOffersStyle={window.innerWidth < TRANSITION_POINT}
          />
          <SubscriptionFaq subscriptionType={this.props.subscriptionType} />
        </Styled.Main>
        <RenderIf condition={this.state.isPinBlock}>
          <Styled.ScrollTopContainer>
            <RoundButton
              Icon={Icons.Left}
              size='m'
              onClick={this.onScrollTop}
              mood={'secondary'}
            />
          </Styled.ScrollTopContainer>
        </RenderIf>
        <SubscribeNewUserApproveDialog
          subscriptionType={this.props.subscriptionType}
          onCreateSubscriptionClick={this.createSubscription}
          addons={filteredAddons}
          onBillingPeriodChanged={this.onBillingPeriodChanged}
          onSelectedAddonGroupsChanged={this.onSelectedAddonGroupsChanged}
          planVariant={filteredPlanGroups.find(x => x.name === this.state.selectedPlanGroup)}
          selectedAddonIds={selectedAddons.map(x => x.id)}
          billingPeriod={this.state.billingPeriod}
        />
      </Styled.Container>
    );
  }

  private extendListByFreePlan(
    list: SubscriptionPlanVariantWithName[],
    subscriptionModel: SubscriptionPlanListingModel,
  ): void {
    for (const plan of subscriptionModel.plans) {
      if (plan
        && this.isFreePlan(plan.name)
        && plan.variants[0].status === BillingEntityStatus.Active
        && !plan.variants[0].isHidden) {
        list.unshift({
          ...plan.variants[0],
          name: plan.name,
          isFree: true,
        });
      }
    }
  }

  private filterVariation(list: SubscriptionPlanVariantWithName[]): SubscriptionPlanVariantWithName[] {
    const { invitePeople } = this.props;
    const isShowCollaborationBlocked = !invitePeople || !invitePeople.length;
    return list.filter(l =>
      l.status === BillingEntityStatus.Active
      && !l.isHidden
      && (isShowCollaborationBlocked ||  !l.isCollaborationBlocked));
  }

  @autobind
  private onScroll(event: React.UIEvent<HTMLDivElement>): void {
    const {
      currentTarget: { scrollTop },
    } = event;
    this.setState({ isPinBlock: scrollTop > 60 });
  }

  @autobind
  private onScrollTop(): void {
    this.containerRef.scrollTop = 0;
  }

  @autobind
  private saveContainerRef(ref: HTMLDivElement): void {
    this.containerRef = ref;
  }

  @autobind
  private openMenuSelectProduct(): void {
    this.setState({ isOpenMenuSelectProduct: true });
  }

  @autobind
  private closeMenuSelectProduct(): void {
    this.setState({ isOpenMenuSelectProduct: false });
  }

  @autobind
  private setDefaultSelectedPlanIfNeeded(): void {
    const { plan, period } = QueryString.parse(this.props.location.search);
    if (!plan) {
      return;
    }

    const { plansModel } = this.props;
    const variant = SubscriptionListingUtils.getSelectedPlanVariant(plansModel, period, plan);
    if (variant && variant.isSelfService) {
      if (variant.isFree) {
        this.props.createFreeSubscription({
          planId: variant.id,
          subscriptionType: this.props.subscriptionType,
        });
        return;
      }
      this.setState({ selectedPlanGroup: plan });
      this.props.openSubscriptionApproveDialog();
    }
  }

  private getPlansIfNeeded(): void {
    if (this.props.subscriptionType && this.props.subscriptionPlansRequestStatus === RequestStatus.NotRequested) {
      this.props.getSubscriptionPlans(this.props.subscriptionType);
    }
  }

  @autobind
  private onSelectedAddonGroupsChanged(selectedAddonGroups: string[]): void {
    this.setState({ selectedAddonGroups });
  }

  @autobind
  private onBillingPeriodChanged(billingPeriod: BillingPeriodUnit): void {
    this.setState({ billingPeriod });
  }

  @autobind
  private setBillingPeriod(billingPeriod: BillingPeriodUnit): void {
    this.setState({ billingPeriod });
  }

  @autobind
  private onSelectPlanGroup(selectedPlanGroup: string): void {
    this.setState({ selectedPlanGroup });
    if (this.isFreePlan(selectedPlanGroup)) {
      this.createFreeSubscription(selectedPlanGroup);
    } else {
      this.props.openSubscriptionApproveDialog();
    }
  }

  private isFreePlan(selectedPlanGroup: string): boolean {
    const plan = this.props.plansModel.plans.find(x => x.name === selectedPlanGroup);
    if (!plan) {
      return false;
    }

    return plan.isFree;
  }

  @autobind
  private createFreeSubscription(selectedPlanGroup: string): void {
    const plan = this.props.plansModel.plans.find(x => x.name === selectedPlanGroup);
    this.props.createFreeSubscription(
      {
        planId: plan.variants[0].id,
        subscriptionType: this.props.subscriptionType,
      },
    );
    this.props.sendEvent(MetricNames.trial.startFreeTrialFree);
  }

  @autobind
  private createSubscription(
    quantity: number,
    paymentSourceId: string,
    billingCountry: string,
    vatNumber: string,
    coupons: string[],
  ): void {
    const { plansModel } = this.props;
    const { billingPeriod, selectedAddonGroups, selectedPlanGroup } = this.state;
    const selectedAddons = SubscriptionListingUtils.getSelectedAddons(plansModel, billingPeriod, selectedAddonGroups);
    const variant = SubscriptionListingUtils.getSelectedPlanVariant(plansModel, billingPeriod, selectedPlanGroup);

    this.props.createSubscription({
      addons: selectedAddons.map(x => x.id),
      paymentSourceId,
      planId: variant.id,
      quantity: variant.isCollaborationBlocked ? 1 : quantity,
      subscriptionType: this.props.subscriptionType,
      billingCountry,
      vatNumber,
      coupons,
    });
    if (variant.isCollaborationBlocked) {
      this.props.sendEvent(MetricNames.trial.startFreeTrialIndividual);
    } else {
      this.props.sendEvent(MetricNames.trial.startFreeTrialTeam);
    }
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    subscriptionType: state.account.selectedSubscriptionType,
    subscriptionPlansRequestStatus: state.account.requests.subscriptionPlans,
    plansModel: state.account.subscriptionPlans,
    invitePeople: state.subscription.tempInvitePeople,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    createSubscription: form => dispatch(SubscriptionActions.createSubscription(form)),
    createFreeSubscription: form => dispatch(SubscriptionActions.createFreeSubscription(form)),
    getSubscriptionPlans: type => dispatch(SubscriptionActions.getSubscriptionPlansRequest(type)),
    openSubscriptionApproveDialog: () => dispatch(KreoDialogActions.openDialog(SUBSCRIBE_NEW_USER_APPROVE_DIALOG)),
  };
}

export const SubscribePage = connect(mapStateToProps, mapDispatchToProps)(withAnalyticsContext(SubscribePageComponent));
