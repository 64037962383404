import * as React from 'react';
import { ConnectDropTarget, DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { WorkPackageAction } from '../../actions';
import { ItemTarget } from '../targets';

import './index.scss';

interface CollectedProps {
  connectDropTarget: ConnectDropTarget;
}

const collect = (conn: any): CollectedProps => {
  return {
    connectDropTarget: conn.dropTarget(),
  };
};

interface ReduxActions {
  createPackage: (idGroup: number) => void;
  createPackageByActivity: (activityId: number) => void;
}

interface Props extends ReduxActions {
  title: string;
  onClick: () => any;
  // createPackage: (groupId: number) => void;
}

interface DNDTargetProps extends Props, CollectedProps {
}

class DTButtonComponent extends React.Component<DNDTargetProps> {
  public render(): JSX.Element {
    const { connectDropTarget, title, onClick } = this.props;

    return connectDropTarget (
      <div className='dt-button' onClick={onClick}>
        {title}
      </div>,
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    createPackage: (id: number): void => {
      dispatch(WorkPackageAction.createNewPackage());
      dispatch(WorkPackageAction.replaceGroup(null, id));
    },
    createPackageByActivity: (id: number): void => {
      dispatch(WorkPackageAction.createNewPackage());
      dispatch(WorkPackageAction.replaceActivity(null, id));
    },
  };
};

const dropTarget = DropTarget<Props, CollectedProps>('ACTIVITY_GROUP', ItemTarget, collect)(DTButtonComponent);
const connector = connect(null, mapDispatchToProps);

export const DTButton = connector(dropTarget);
