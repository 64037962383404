import { ColorSelector, Constants, RectangleButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ConstantFunctions } from 'common/constants/functions';
import { KreoDialogActions } from 'common/UIKit';
import { ConfirmationDialog } from '../../../components/dialog/confirmation-dialog';
import { AccountActions } from '../../../units/account/actions/creators';
import { DrawingsSubmenuContainer } from '../drawings/drawings-canvas-menus/drawings-flying-menu/submenu-containers';
import { CreateColorDialogStyled } from './create-color-dialog-styled';


interface OwnProps {
  color: string;
  onChangeColor: (color: string) => void;
  userColors: string[];
}


interface DispatchProps {
  onSaveColor: (value: string[]) => void;
  openDialog: () => void;
}

interface Props extends OwnProps, DispatchProps {

}

interface ComponentState {
  color: string;
}

const MAX_COLOR_COUNT = 64;

const COLOR_CONSTRAINT_DIALOG = 'COLOR_CONSTRAINT_DIALOG';

export class CreateColorDialogComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      color: props.color || Constants.Colors.GENERAL_COLORS.white,
    };
  }

  public render(): React.ReactNode {
    return (
      <DrawingsSubmenuContainer onClick={ConstantFunctions.stopEvent}>
        <ConfirmationDialog
          name={COLOR_CONSTRAINT_DIALOG}
          title={`Colours limit exceeded`}
          text={`Please remove some colour from your palette and try again later`}
          cancelButtonText='Okay'
          zIndex={1003}
        />
        <CreateColorDialogStyled.Container>
          <ColorSelector changeColor={this.onChangeColor} color={this.state.color}/>
          <CreateColorDialogStyled.Button>
            <RectangleButton
              height={40}
              width={225}
              mood='secondary'
              onClick={this.saveAndApplyColor}
              text='Apply and Save'
            />
          </CreateColorDialogStyled.Button>
        </CreateColorDialogStyled.Container>
      </DrawingsSubmenuContainer>
    );
  }


  @autobind
  private saveAndApplyColor(): void {
    const color = this.state.color;
    if (this.props.userColors.includes(color)) {
      return;
    }
    const colors = [color, ...this.props.userColors];
    if (colors.length > MAX_COLOR_COUNT) {
      this.setState({ color }, this.props.openDialog);
    } else {
      this.props.onSaveColor(colors);
      this.props.onChangeColor(color);
    }
  }

  @autobind
  private onChangeColor(color: { hex: string }): void {
    this.setState({ color: color.hex });
  }
}


function mapDispatchProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onSaveColor: value => dispatch(AccountActions.setUserColors(value)),
    openDialog: () => dispatch(KreoDialogActions.openDialog(COLOR_CONSTRAINT_DIALOG)),
  };
}

export const CreateColorDialog = connect(null, mapDispatchProps)(CreateColorDialogComponent);
