import * as Ag from 'ag-grid-community';
import { useEffect, useMemo, useRef } from 'react';


import { TransactionGenerator } from '2d/components/2d-element-view/utils';
import { DrawingsFiles } from 'common/components/drawings/interfaces/drawings-file-info';
import { getRows } from '../../helpers/get-rows';

type UseTransactionEffect = (files: DrawingsFiles, gridApi: Ag.GridApi) => void;

export const useTransactionEffect: UseTransactionEffect = (files, gridApi) => {
  const transactionGeneratorRef = useRef(new TransactionGenerator());
  const rowDataMap = useMemo(() => getRows(files), [files]);
  useEffect(() => {
    if (!gridApi) {
      return;
    }

    const transaction = transactionGeneratorRef.current.getTransaction(rowDataMap);
    if (transaction) {
      gridApi.applyTransaction(transaction);
      gridApi.refreshCells();
    }
  }, [rowDataMap, gridApi]);
};
