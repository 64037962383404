import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { DatabaseResourceVariantActions } from '../../actions/creators/database-resource-variant';
import { ModifyDatabaseEntityMode, ResourceType } from '../../enums';
import { LaborVariantModel } from '../../interfaces/resources-data';
import { LaborVariantForm } from '../../interfaces/resources-rest-data';
import { ResourcesRestFormMapper } from '../../utils/resources-rest-form-mapper';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseResourceLaborVariant } from './database-resource-labor-variant';

interface ReduxProps {
  variant: LaborVariantModel;
  resourceId: number;
  variantId: number;
  databaseId: number;
}

interface ReduxActions {
  setEditResourceVariantModel: (variant: LaborVariantModel) => void;
  loadDatabaseResourceVariant: (databaseId: number, resourceId: number, variantId: number, type: ResourceType) => void;
  updateDatabaseResourceVariant: (
    databaseId: number, resourceId: number, variantId: number,
    type: ResourceType, model: LaborVariantForm, formId: string,
  ) => void;
}

interface Props extends ReduxProps, ReduxActions {
  readonly: boolean;
}

interface State {
  initialValues: LaborVariantForm;
}


class EditDatabaseResourceLaborVariantComponent extends React.Component<Props, State> {
  private readonly formId: string = 'edit-labour-variant';

  constructor(props: Props) {
    super(props);
    this.state = { initialValues: null };
  }

  public componentDidMount(): void {
    const { databaseId, resourceId, variantId } = this.props;
    this.props.loadDatabaseResourceVariant(databaseId, resourceId, variantId, ResourceType.Labour);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.variant === null && this.props.variant !== null) {
      this.setState({ initialValues: ResourcesRestFormMapper.GetLaborVariantForm(this.props.variant) });
    }
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        readonly={this.props.readonly}
        onSubmit={this.save}
        form={this.formId}
        entityName='Labor Variant'
        isApplyButtonDisabled={this.isSaveButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Edit}
        initialValues={this.state.initialValues}
      >
      {
        this.props.variant ? (
          <KreoScrollbars>
            <DatabaseResourceLaborVariant
              variant={this.props.variant}
              onChange={this.props.setEditResourceVariantModel}
              readonly={this.props.readonly}
            />
          </KreoScrollbars>
        ) : <SvgSpinner size='middle'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }


  @autobind
  private save(values: LaborVariantForm): void {
    const { databaseId, resourceId, variantId } = this.props;
    this.props.updateDatabaseResourceVariant(
      databaseId, resourceId, variantId, ResourceType.Labour, values, this.formId);
  }

  private isSaveButtonDisabled(): boolean {
    const { variant } = this.props;
    return !variant ||
      !variant.name ||
      !variant.name.trim() ||
      !(variant.cost >= 0);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    variant: editModel.variant,
    resourceId: editModel.rootId,
    variantId: editModel.variantId,
    databaseId: state.database.currentDatabase.database.id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditResourceVariantModel: variant =>
      dispatch(DatabaseResourceListingActions.setEditResourceVariantModel(variant)),
    updateDatabaseResourceVariant: (databaseId, resourceId, variantId, type, model, formId) =>
      dispatch(DatabaseResourceVariantActions.updateResourceVariant(
        databaseId, resourceId, variantId, type, model, formId,
      )),
    loadDatabaseResourceVariant: (databaseId, resourceId, variantId, type) =>
      dispatch(DatabaseResourceVariantActions.loadResourceVariant(databaseId, resourceId, variantId, type)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const EditDatabaseResourceLaborVariant = connector(EditDatabaseResourceLaborVariantComponent);
