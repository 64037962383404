const prefix = '@database-activity-variant';

export const DatabaseActivityVariantActionTypes = {
  GET_ACTIVITY_VARIANT_REQUEST: `${prefix}/GET_ACTIVITY_VARIANT_REQUEST`,
  GET_ACTIVITY_VARIANT_SUCCEEDED: `${prefix}/GET_ACTIVITY_VARIANT_SUCCEEDED`,
  CREATE_ACTIVITY_VARIANT_REQUEST: `${prefix}/CREATE_ACTIVITY_VARIANT_REQUEST`,
  CREATE_ACTIVITY_VARIANT_SUCCEEDED: `${prefix}/CREATE_ACTIVITY_VARIANT_SUCCEEDED`,
  UPDATE_ACTIVITY_VARIANT_REQUEST: `${prefix}/UPDATE_ACTIVITY_VARIANT_REQUEST`,
  UPDATE_ACTIVITY_VARIANT_SUCCEEDED: `${prefix}/UPDATE_ACTIVITY_VARIANT_SUCCEEDED`,
  DELETE_ACTIVITY_VARIANT_REQUEST: `${prefix}/DELETE_ACTIVITY_VARIANT_REQUEST`,
  DELETE_ACTIVITY_VARIANT_SUCCEEDED: `${prefix}/DELETE_ACTIVITY_VARIANT_SUCCEEDED`,
  DUPLICATE_ACTIVITY_VARIANT: `${prefix}/DUPLICATE_ACTIVITY_VARIANT`,
};
