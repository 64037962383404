import styled from 'styled-components';

const HeaderContainer = styled.div`
  min-height: 38px;
  height: 38px;
`;

export const Styled = {
  HeaderContainer,
};
