import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import {
  MeasurementsLoadDataSuccessPayload,
  MeasurementsOnChangeExtractorValuePayload,
  MeasurementsOnChangeGeneraralValuePayload,
  MeasurementsToggleItemSelectStatusPayload,
} from '../payloads/measurements';
import { ValidationStepStatisticDataPayload } from '../payloads/validation';
import { MeasurementsActionTypes } from '../types/measurements';

function expandItemById(id: number): ActionWith<number> {
  return {
    type: MeasurementsActionTypes.EXPAND_ITEM_BY_ID,
    payload: id,
  };
}

function showExtractorByTreeId(id: number): ActionWith<number> {
  return {
    type: MeasurementsActionTypes.SHOW_EXTRACTORS_BY_ID,
    payload: id,
  };
}

function selectTreeNode(id: number): ActionWith<number> {
  return {
    type: MeasurementsActionTypes.SELECT_NODE,
    payload: id,
  };
}

function runSearch(query: string): ActionWith<string> {
  return {
    type: MeasurementsActionTypes.START_SEARCH,
    payload: query,
  };
}

function showHideTree(): Action {
  return {
    type: MeasurementsActionTypes.SHOW_HIDE_TREE,
  };
}


function onChangeInputValue(
  elementIndex: number,
  value: string,
  extractorId: string,
  generalExtractorId: string,
): ActionWith<MeasurementsOnChangeExtractorValuePayload> {
  return {
    type: MeasurementsActionTypes.ON_CHANGE_INPUT_VALUE,
    payload: {
      elementIndex,
      value,
      extractorId,
      generalExtractorId,
    },
  };
}

function onChangeGeneralInputValue(
  value: string,
  extractorId: string,
  generalExtractorId: string,
): ActionWith<MeasurementsOnChangeGeneraralValuePayload> {
  return {
    type: MeasurementsActionTypes.ON_CHANGE_GENERAL_INPUT_VALUE,
    payload: {
      value,
      extractorId,
      generalExtractorId,
    },
  };
}

function toggleItemSelectStatus(
  value: boolean,
  itemIndex: number,
): ActionWith<MeasurementsToggleItemSelectStatusPayload> {
  return {
    type: MeasurementsActionTypes.TOGGLE_ITEM_SELECT_STATUS,
    payload: {
      value,
      itemIndex,
    },
  };
}


function toggleGeneralSelectStatus(
  value: boolean,
): ActionWith<boolean> {
  return {
    type: MeasurementsActionTypes.TOGGLE_GENERAL_SELECT_STATUS,
    payload: value,
  };
}


function disabledItemSelectStatus(
  itemIndex: number,
): ActionWith<number> {
  return {
    type: MeasurementsActionTypes.DISABLED_ITEM_SELECT_STATUS,
    payload: itemIndex,
  };
}


function selectNodeByEngineId(
  id: number,
): ActionWith<number> {
  return {
    type: MeasurementsActionTypes.SELECT_NODE_BY_ENGINE_ID,
    payload: id,
  };
}

function loadDataRequest(id: number): ActionWith<number> {
  return {
    type: MeasurementsActionTypes.LOAD_DATA_REQUEST,
    payload: id,
  };
}

function loadDataSuccess(data: MeasurementsLoadDataSuccessPayload): ActionWith<MeasurementsLoadDataSuccessPayload> {
  return {
    type: MeasurementsActionTypes.LOAD_DATA_SUCCESSED,
    payload: data,
  };
}


function toggleCheckStatusFromEngine(engineIds: number[]): ActionWith<number[]> {
  return {
    type: MeasurementsActionTypes.SELECT_ELEMENT_FROM_ENGINE,
    payload: engineIds,
  };
}

function dropState(): Action {
  return {
    type: MeasurementsActionTypes.DROP_STATE,
  };
}

function getStatisticRequest(scenarioId: number): ActionWith<number> {
  return {
    type: MeasurementsActionTypes.GET_STATISTIC_REQUEST,
    payload: scenarioId,
  };
}

function getStatisticSuccess(
  statistic: ValidationStepStatisticDataPayload,
): ActionWith<ValidationStepStatisticDataPayload> {
  return {
    type: MeasurementsActionTypes.GET_STATISTIC_SUCCESS,
    payload: statistic,
  };
}

function dropStatistic(): Action {
  return {
    type: MeasurementsActionTypes.DROP_STATISTIC,
  };
}

function onBlurEdit(): Action {
  return {
    type: MeasurementsActionTypes.ON_BLUR_EDIT,
  };
}

function commitChangesSuccess(): Action {
  return {
    type: MeasurementsActionTypes.COMMIT_CHANGES_SUCCESS,
  };
}

function cancelEdit(): Action {
  return {
    type: MeasurementsActionTypes.CANCEL_EDIT,
  };
}

function cancelEditSuccess(): Action {
  return {
    type: MeasurementsActionTypes.CANCEL_EDIT_SUCCESS,
  };
}

function toggleLevelsShowStatus(value: boolean): ActionWith<boolean> {
  return {
    type: MeasurementsActionTypes.TOGGLE_LEVELS_SHOW_STATUS,
    payload: value,
  };
}

export const MeasurementsActions = {
  expandItemById,
  showExtractorByTreeId,
  selectTreeNode,
  runSearch,
  showHideTree,
  onChangeInputValue,
  onChangeGeneralInputValue,
  toggleItemSelectStatus,
  toggleGeneralSelectStatus,
  selectNodeByEngineId,
  loadDataRequest,
  toggleCheckStatusFromEngine,
  loadDataSuccess,
  dropState,
  getStatisticRequest,
  getStatisticSuccess,
  dropStatistic,
  onBlurEdit,
  commitChangesSuccess,
  disabledItemSelectStatus,
  cancelEdit,
  cancelEditSuccess,
  toggleLevelsShowStatus,
};
