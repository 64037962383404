export interface EngineHotkeysListItem {
  name: string;
  key: string[];
  additionalKey?: string[];
}

export interface EngineHotkeysBlock {
  blockName: string;
  hotkeyList: EngineHotkeysListItem[];
}

export const EngineHotkeysList: EngineHotkeysBlock[] = [
  {
    blockName: 'Objects',
    hotkeyList: [
      {
        name: 'Select Object',
        key: ['Left Click'],
      },
      {
        name: 'Multiselect',
        key: ['Ctrl', 'Left Click'],
      },
      {
        name: 'Rect Select',
        key: ['Ctrl', 'Drag'],
      },
      {
        name: 'Rect Deselect',
        key: ['Shift', 'Drag'],
      },
      {
        name: 'Hide',
        key: ['H'],
      },
      {
        name: 'Show all objects',
        key: ['Ctrl', 'H'],
      },
      {
        name: 'Isolate',
        key: ['I'],
      },
      {
        name: 'Focus',
        key: ['F'],
      },
      {
        name: 'Clip selected objects',
        key: ['C'],
      },
    ],
  },
  {
    blockName: 'Viewport',
    hotkeyList: [
      {
        name: 'Rotate',
        key: ['Left Click'],
      },
      {
        name: 'Move',
        key: ['Right Click'],
      },
      {
        name: 'Zoom',
        key: ['Mouse Wheel'],
      },
      {
        name: 'Clipbox',
        key: ['C'],
      },
      {
        name: 'Perspective / Isometry',
        key: ['P'],
      },
      {
        name: 'Teleport',
        key: ['Space'],
      },
      {
        name: 'Home',
        key: ['Ctrl', 'F'],
      },
      {
        name: 'Walk',
        key: ['A', 'W', 'S', 'D'],
      },
      {
        name: 'Move Up',
        key: ['Q'],
      },
      {
        name: 'Move Down',
        key: ['E'],
      },
    ],
  },
  {
    blockName: 'Ruler',
    hotkeyList: [
      {
        name: 'Activate / Deactivate Ruler',
        key: ['R'],
      },
      {
        name: 'Add measurement point',
        key: ['Left Click'],
      },
      {
        name: 'Complete measurement contour',
        key: ['Right Click'],
      },
      {
        name: 'Vertex snapping',
        key: ['Ctrl + Click'],
      },
      {
        name: 'Copy to clipboard',
        key: ['Left Click Label'],
      },
      {
        name: 'Delete measurement',
        key: ['Right Click Label'],
      },
    ],
  },
  {
    blockName: 'Camera',
    hotkeyList: [
      {
        name: 'Front',
        key: ['1'],
      },
      {
        name: 'Back',
        key: ['Ctrl', '1'],
      },
      {
        name: 'Top',
        key: ['2'],
      },
      {
        name: 'Bottom',
        key: ['Ctrl', '2'],
      },
      {
        name: 'Right',
        key: ['3'],
      },
      {
        name: 'Left',
        key: ['Ctrl', '3'],
      },
    ],
  },
];

export const DrawingsHotkeysList: EngineHotkeysBlock[] = [
  {
    blockName: 'Drawings',
    hotkeyList: [
      {
        name: 'Area selection',
        key: ['Ctrl', 'Drag'],
      },
      {
        name: 'Add to selected',
        key: ['Ctrl', 'Left Click'],
      },
      {
        name: 'Hide selected drawing instances',
        key: ['H'],
      },
      {
        name: 'Show all drawing instances',
        key: ['Ctrl', 'H'],
      },
      {
        name: 'Remove previous point in Polygon/Polyline/Count',
        key: ['Q'],
      },
      {
        name: 'Turn off selected tool',
        key: ['ESC'],
      },
      {
        name: 'Copy',
        key: ['Ctrl', 'C'],
      },
      {
        name: 'Paste',
        key: ['Ctrl', 'V'],
      },
      {
        name: 'Zoom',
        key: ['Scroll Wheel '],
      },
      {
        name: 'Scroll layout',
        key: ['Hold Mouse Wheel', 'Drag'],
      },
      {
        name: 'Complete Drawing',
        key: ['Space'],
      },
    ],
  },
];
