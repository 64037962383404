import { Text } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';

import { RenderIf } from 'common/components/render-if';
import { BillingEntityStatus } from '../../../enums/billing-entity-status';
import { BillingPricingModel } from '../../../enums/billing-pricing-model';
import { SubscriptionAddon } from '../../../interfaces/subscription-addon';
import { SubscriptionDetailsModel } from '../../../interfaces/subscription-details';
import { AddonList } from '../../subscription-details/addon-list';
import { FillStepScaleSubscription } from '../fill-step-scale-subscription';
import { Styled } from './styled';


interface Props {
  addons: SubscriptionAddon[];
  currentSubscription: SubscriptionDetailsModel;
  hideChangeSubscription: boolean;
  headerText: string;
  onSubscriptionChange: (subscription: SubscriptionDetailsModel) => void;
  canAddAddons?: boolean;
}

export const AddonListBlock: React.FC<Props> = (props) => {
  const {
    currentSubscription,
    addons,
    hideChangeSubscription,
    headerText,
    canAddAddons,
    onSubscriptionChange,
  } = props;
  const planVariant = currentSubscription.planVariant;
  const isCurrentSubscriptionArhived = currentSubscription.planVariant.status === BillingEntityStatus.Archived;
  const isTeamPlan = planVariant && planVariant.pricingModel === BillingPricingModel.PerUnit;
  const numberStep = isTeamPlan ? 4 : 3;
  const availableAddons = useMemo(() => {
    return addons.filter((addon) => planVariant.availableAddons.includes(addon.id));
  }, [planVariant.availableAddons, addons]);

  const onSelectedAddonIdsChange = useCallback((selectedAddonIds: string[]) => {
    onSubscriptionChange({
      ...currentSubscription,
      selectedAddonIds,
    });
  }, [onSubscriptionChange, currentSubscription]);

  return (
    <>
      <Styled.FillStepScaleContainer>
        <FillStepScaleSubscription
          numberStep={numberStep}
          isValid={true}
        />
      </Styled.FillStepScaleContainer>
      <Styled.ContentContainer headerMargin={19}>
        <Styled.TextContainer>
          <Text>{headerText}</Text>
          <Styled.ExtraText>
            <Text
              color={'turquoiseFont'}
              fontSize={12}
            >
              Enjoy all add-ons for free during the trial. Buy anytime you're ready.
            </Text>
          </Styled.ExtraText>
        </Styled.TextContainer>
        <Styled.AddonListWrapper>
          <RenderIf condition={!hideChangeSubscription}>
            <AddonList
              availableAddons={availableAddons}
              onSelectedAddonIdsChange={onSelectedAddonIdsChange}
              selectedAddonIds={currentSubscription.selectedAddonIds}
              isTeamPlan={isTeamPlan}
              billingPeriod={currentSubscription.billingPeriod}
              canAddAddons={canAddAddons}
              fontWeight={'bold'}
              switcherSize={'m'}
              isPlanArhived={isCurrentSubscriptionArhived}
            />
          </RenderIf>
        </Styled.AddonListWrapper>
      </Styled.ContentContainer>
    </>
  );
};
