import * as React from 'react';

export const KreoIconUnion = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <path
      // eslint-disable-next-line max-len
      d='M18.09,20H9.66a1.92,1.92,0,0,1-1.91-1.91V16.25H5.91A1.92,1.92,0,0,1,4,14.34V5.91A1.91,1.91,0,0,1,5.91,4h8.43a1.91,1.91,0,0,1,1.91,1.91V7.75h1.84A1.91,1.91,0,0,1,20,9.66v8.43A1.92,1.92,0,0,1,18.09,20ZM5.91,5A.91.91,0,0,0,5,5.91v8.43a.91.91,0,0,0,.91.91H8.25a.5.5,0,0,1,.5.5v2.34a.91.91,0,0,0,.91.91h8.43a.91.91,0,0,0,.91-.91V9.66a.91.91,0,0,0-.91-.91H15.75a.5.5,0,0,1-.5-.5V5.91A.91.91,0,0,0,14.34,5Z'
      fill='currentColor'
    />
  </svg>
);
