import * as React from 'react';

import { numberUtils } from 'common/utils/number-utils';
import { CostFieldNames } from '../../../constants';
import { CostEstimateGroupingType } from '../../../units/projects/enums/cost-estimate-grouping-type';
import Input from '../../input';
import * as Utils from '../utils';

interface Props {
  levelOfNesting: number;
  isEditable: boolean;
  overheadPercentage: number | undefined;
  extraPayment: number | undefined;
  groupingType: CostEstimateGroupingType;
  isIndirectCosts: boolean;
  onInputChange: (value: any, field: any) => void;
}

interface EditableCellContentProps {
  overheadPercentage: number | undefined;
  extraPayment: number | undefined;
  onInputChange: (value: string, fieldName: string) => void;
}

const ReadonlyCellContent: React.FC<{ extraPayment: number | undefined }> = (
  { extraPayment },
) => {
  return (
    <div key={2} className='cost'>
      {
        extraPayment !== undefined ?
          numberUtils.getNumeralFormatter(extraPayment).format('0,0').replace(/,/g, ' ') : ''
      }
    </div>
  );
};

const EditableCellContent: React.FC<EditableCellContentProps> = (props) => {
  return (
    <React.Fragment>
      <div key={1} className='rate'>
        <Input
          onChange={props.onInputChange}
          value={Utils._formatter(props.overheadPercentage ? props.overheadPercentage.toString() : '', 2)}
          fieldName={CostFieldNames.OVERHEAD_PERCENTAGE}
        />
      </div>
      <ReadonlyCellContent
        extraPayment={props.extraPayment}
      />
    </React.Fragment>
  );
};

const OverheadAndProfitCellContent: React.FC<Props> = (props) => {

  const shouldBeEditable = props.levelOfNesting === 1 && props.isEditable;
  const shouldDisplayValues = props.isIndirectCosts ||
    (props.groupingType === CostEstimateGroupingType.Workpackages ||
      props.groupingType === CostEstimateGroupingType.WorkpackagesExtended
      ? props.levelOfNesting < 2
      : props.levelOfNesting === 0);

  if (shouldBeEditable) {
    return (
      <EditableCellContent
        extraPayment={props.extraPayment}
        overheadPercentage={props.overheadPercentage}
        onInputChange={props.onInputChange}
      />
    );
  }

  return (
    <ReadonlyCellContent
      extraPayment={shouldDisplayValues ? props.extraPayment : undefined}
    />
  );
};

export const OverheadAndProfitCell: React.FC<Props> = (props) => {
  return (
    <div className='cost-estimate-table__price overhead rbord'>
      <OverheadAndProfitCellContent
        {...props}
      />
    </div>
  );
};
