import { MenuItem, Switcher, Text, Icons, ElementTooltip, MovableContextMenu } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { ColorControl } from 'common/components/color-control';
import { DrawingsActions } from 'common/components/drawings';
import { OptimizeTooltips } from 'common/components/drawings/constants';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { RenderIf } from 'common/components/render-if';
import { ColorList } from 'common/constants/color-list';
import { ConstantFunctions } from 'common/constants/functions';
import { ColorsName } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import {
  PersistedStorageActions,
} from '../../../../../units/persisted-storage/actions/creators';
import { Styled } from './styled';


interface StateProps {
  minifiedTabs: boolean;
  isOptimized: boolean;
}

interface DispatchProps {
  toggleMinifyTabs: () => void;
  toggleOptimize: (drawingId: string) => void;
}

interface OwnProps {
  drawingId: string;
  drawingName: string;
  fileName: string;
  positionX: number;
  positionY: number;
  color: string;
  isActive: boolean;
  canEditMeasurements: boolean;
  selectedPages: string[];
  isFirstTab: boolean;
  isLastTab: boolean;
  canChangeColor: boolean;
  onClose: () => void;
  onColorChange: (color: string) => void;
  onRemoveTab: (e: React.MouseEvent<HTMLDivElement>) => void;
  onRemoveOtherTabs: (e: React.MouseEvent<HTMLDivElement>) => void;
  openDeletePageConfirmDialog: () => void;
  onMoveTabLeft: () => void;
  onMoveTabRight: () => void;
}

interface Props extends OwnProps, StateProps, DispatchProps, AnalyticsProps, AbilityAwareProps {

}

const ContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    positionX,
    positionY,
    onClose,
    minifiedTabs,
    toggleMinifyTabs,
    color,
    onColorChange,
    onRemoveTab,
    onRemoveOtherTabs,
    selectedPages,
    openDeletePageConfirmDialog,
    canEditMeasurements,
    isFirstTab,
    isLastTab,
    onMoveTabLeft,
    onMoveTabRight,
    drawingId,
    toggleOptimize,
    isOptimized,
    drawingName,
    fileName,
    canChangeColor,
    sendToggleEvent,
    isActive,
    ability,
  } = props;
  const canOptimize = ability.can(Operation.Update, Subject.Takeoff2dFileOptimizer);

  const onToggleMinifyTabs = useCallback(() => {
    toggleMinifyTabs();
    onClose();
  }, []);

  const onToggleOptimize = useCallback(() => {
    if (!canOptimize) {
      return;
    }
    sendToggleEvent(
      MetricNames.fileManager.optimizePage,
      isOptimized,
      { pageName: drawingName, fileName },
    );
    toggleOptimize(drawingId);
    onClose();
  }, [drawingName, isOptimized, canOptimize]);

  const { setDrawMode } = useDrawModeApi();

  const optimizeListener = useCallback(() => {
    setDrawMode(DrawingsDrawMode.Disabled, { afterSave: onToggleOptimize });
  }, [onToggleOptimize, isActive, setDrawMode]);

  return (
    <div onClick={ConstantFunctions.stopEvent}>
      <MovableContextMenu
        x={positionX}
        y={positionY}
        onClose={onClose}
      >
        <RenderIf condition={canChangeColor}>
          <ColorControl
            colorList={ColorList}
            selectedColor={color || ColorList[0]}
            onColor={onColorChange}
          />
        </RenderIf>
        <Styled.ToggleItem>
          <Text>Minimize</Text>
          <Switcher
            size={'m'}
            checked={minifiedTabs}
            onChange={onToggleMinifyTabs}
          />
        </Styled.ToggleItem>
        <Styled.Splitter/>
        <UpgradeWrapper isNeedUpdate={!canOptimize}>
          <ElementTooltip
            size='small'
            text='Optimize'
            description={OptimizeTooltips.optimize}
            position='bottom'
            tooltipWidth={210}
          >
            <Styled.ToggleItem>
              <Styled.TextOptimizeWrapper>
                <Icons.Optimize/>
                <Text>Optimize</Text>
              </Styled.TextOptimizeWrapper>
              <Switcher
                size={'m'}
                checked={canOptimize && isOptimized}
                onChange={optimizeListener}
                disabled={!canOptimize}
              />
            </Styled.ToggleItem>
          </ElementTooltip>
        </UpgradeWrapper>
        <MenuItem
          onClick={onRemoveTab}
          text='Close'
          Icon={Icons.Close}
          textColor={ColorsName.mainFont}
          size='m'
          fontSize={14}
        />
        <MenuItem
          onClick={onRemoveOtherTabs}
          text='Close other tabs'
          Icon={Icons.CloseOther}
          textColor={ColorsName.mainFont}
          size='m'
          fontSize={14}
          disabled={selectedPages.length === 1}
        />
        <MenuItem
          onClick={openDeletePageConfirmDialog}
          text='Delete'
          Icon={Icons.Delete}
          textColor={ColorsName.mainFont}
          size='m'
          fontSize={14}
          disabled={!canEditMeasurements}
        />
        <MenuItem
          onClick={onMoveTabLeft}
          text='Move left'
          Icon={Icons.Left}
          textColor={ColorsName.mainFont}
          size='m'
          fontSize={14}
          disabled={isFirstTab}
        />
        <MenuItem
          onClick={onMoveTabRight}
          text='Move right'
          Icon={Icons.Right}
          textColor={ColorsName.mainFont}
          size='m'
          fontSize={14}
          disabled={isLastTab}
        />
      </MovableContextMenu>
    </div>
  );
};

function mapStateToProps(state: State, { drawingId }: OwnProps): StateProps {
  return {
    minifiedTabs: state.persistedStorage.isPagesTabsMinified,
    isOptimized: state.drawings.drawingsInfo[drawingId].isOptimized,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps  {
  return {
    toggleMinifyTabs: () => dispatch(PersistedStorageActions.toggleTwoDPagesMinified()),
    toggleOptimize: (drawingId) => dispatch(DrawingsActions.toggleDrawingOptimize(drawingId)),
  };
}

export const ContextMenu =
  connect(mapStateToProps, mapDispatchToProps)(
    withAbilityContext(withAnalyticsContext(ContextMenuComponent)));
