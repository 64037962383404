function intercomTracker(): any {
  return window && (window as any).Intercom ? (window as any).Intercom : null;
}

function hubspotTracker(): any {
  return window && (window as any)._hsp ? (window as any)._hsp : null;
}

function segmentTracker(): any {
  return window && (window as any).analytics ? (window as any).analytics : null;
}

export const TrackerProvider = {
  intercomTracker,
  hubspotTracker,
  segmentTracker,
};
