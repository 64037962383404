import { useEffect } from 'react';
import { useActionDispatch } from 'common/hooks';
import { DrawingsTextSearchActions } from '../../actions/creators';

export function useUpdateActiveStatus(): void {
  const setIsActive = useActionDispatch(DrawingsTextSearchActions.setTextSearchActive);

  useEffect(() => {
    setIsActive(true);
    return () => {
      setIsActive(false);
    };
  }, []);
}
