import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { ActionWith } from 'common/interfaces/action-with';
import { TwoDNotificationsApi } from 'unit-2d-notification/api/2d-notification-api';
import { twoDCommentsRealtimeSaga } from './2d-notifications-realtime-saga';
import { mapNotificationModelToState } from './helpers/map-notification-model-to-state';
import { LoadNotificationPayload } from './interfaces';
import { TwoDNotificationsActions } from './store-slice';

function *loadNotifications({ payload }: ActionWith<LoadNotificationPayload>): SagaIterator {
  try {
    const notificationModel = yield call(TwoDNotificationsApi.getNotifications, payload.beforeId, payload.limit);
    const [notifications, lastAccess] = mapNotificationModelToState(notificationModel);
    if (payload.beforeId) {
      yield put(TwoDNotificationsActions.updateNotifications(notifications));
      yield put(TwoDNotificationsActions.setLastAccess(new Date(Date.now())));
    } else {
      yield put(TwoDNotificationsActions.setNotifications(notifications));
      yield put(TwoDNotificationsActions.setLastAccess(lastAccess));
    }
  } catch (error) {
    console.error('2d notifications error: fail to load notification', error, payload);
  } finally {
    yield put(TwoDNotificationsActions.finishLoadNotifications());
  }
}

function *updateLastAccess(): SagaIterator {
  try {
    yield call(TwoDNotificationsApi.updateAccessNotifications);
    yield put(TwoDNotificationsActions.setLastAccess(new Date(Date.now())));
  } catch (error) {
    console.error('2d notifications error: fail to update last access', error);
  } finally {
    yield put(TwoDNotificationsActions.finishLoadNotifications());
  }
}

export function *twoDNotificationsSagas(): SagaIterator {
  yield takeLatest(TwoDNotificationsActions.loadNotifications.type, loadNotifications);
  yield takeLatest(TwoDNotificationsActions.updateLastAccess.type, updateLastAccess);

  yield fork(twoDCommentsRealtimeSaga);
}
