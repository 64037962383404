import React from 'react';
import { useDispatch } from 'react-redux';
import { KreoDialogActions } from 'common/UIKit';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import {
  SELECT_SUBSCRIPTION_PLAN_DIALOG_CONSTANTS,
} from '../../components/subscription-dialog/select-subscription-plan-dialog';
import { Styled } from './styled';

export const RENEW_ARCHIVED_DIALOG = 'RENEW_ARCHIVED_DIALOG';

const dialogText = 'Your previous plan is no longer available. Please choose another.';

export const RenewArchivedDialog: React.FC = () => {
  const dispatch = useDispatch();
  const handleExit = React.useCallback(() => {
    dispatch(KreoDialogActions.closeDialog(RENEW_ARCHIVED_DIALOG));
    dispatch(KreoDialogActions.openDialog(SELECT_SUBSCRIPTION_PLAN_DIALOG_CONSTANTS.DIALOG_NAME));
  }, []);
  return (
    <ConfirmationDialog
      name={RENEW_ARCHIVED_DIALOG}
      title='Plan is not available '
      text={dialogText}
      cancelButtonText={'Choose another plan'}
      onCancel={handleExit}
      zIndex={1003}
      cancelButtonWidth={290}
      ExtraStyled={Styled.DialogContainer}
    />
  );
};
