import autobind from 'autobind-decorator';
import * as React from 'react';

import './styles.scss';

import { ShortCompany } from '../../interfaces/user';

interface ShortCompanyItemProp {
  item: ShortCompany;
  className?: string;
  onClick: (item: ShortCompany) => void;
  ownCompanyId: number;
}

export class ShortCompanyItem extends React.Component<ShortCompanyItemProp> {
  public render(): React.ReactNode {
    const { item, ownCompanyId } = this.props;
    const isOwnCompany = ownCompanyId === item.id;
    return (
      <div className={this.props.className} onClick={this.onClick}>
        <div>
          <strong style={{ fontWeight: 'bold' }}>{item.name}</strong> {isOwnCompany && <span>(own)</span>}
        </div>
        <div>
          {item.subscriptions.join(', ')}
        </div>
      </div>
    );
  }

  @autobind
  private onClick(): void {
    if (this.props.onClick) {
      this.props.onClick(this.props.item);
    }
  }
}
