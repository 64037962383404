import classNames from 'classnames';
import * as React from 'react';

import './select-overlay.scss';

interface Props {
  onClick: () => void;
  className?: string;
}

const contentClick = (e: React.MouseEvent<HTMLDivElement>): void => {
  e.stopPropagation();
};

export const SelectOverlay: React.FC<Props> = (props) => {
  return (
    <div className='gantt-chart-header-select-body__overlay' onClick={props.onClick}>
      <div
        onClick={contentClick}
        className={classNames('gantt-chart-header-select-body__overlay__content', props.className)}
      >
        {props.children}
      </div>
    </div>
  );
};
