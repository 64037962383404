import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect  } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { AnyAction, Dispatch } from 'redux';

import './index.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { HelpMenu } from 'common/components/help-menu';
import { SubscriptionType } from 'common/constants/subscription';
import { LogoType } from 'common/enums/logo-type';
import { ProjectStatus } from 'common/enums/project-status';
import { BidPricingData } from 'common/interfaces/bid-pricing-data';
import { State } from 'common/interfaces/state';
import { ParametrizedAppRoute } from 'common/routing/parametrized-app-route';
import { RoutingContextProps, withRoutingContext } from 'common/routing/with-routing-context';
import { KreoScrollbars } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { Urls as CostEstimateUrls } from 'routes/cost-estimate';
import { CostEstimateValidationStep, RevisionState } from 'unit-cost-estimate/interfaces';
import { isCostEstimateValidationStepGreaterThan } from 'unit-cost-estimate/utils/validation-step';
import { TopPanel } from '../../../../actions';
import { Logo } from '../../../../components/logo';
import {
  TNav4D,
  TNavBidPricing,
  TNavCosts,
  TNavDashboard,
  TNavQTO,
  TNavScenarios,
  TNavSchedule,
  TNavValidation,
  TNavViewer,
} from '../../../../icons';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { KnownViewModel } from '../../../projects/enums/known-view-model';
import {
  isValidationStepGreaterThan,
  isValidationStepLessThan,
  ValidationStep,
} from '../../../projects/enums/validation-step';
import { ValidationStepState } from '../../../projects/enums/validation-step-state';
import { ViewModelStatus } from '../../../projects/enums/view-model-status';
import { Project } from '../../../projects/interfaces/project';
import { ScenariosData } from '../../../scenarios';
import { MenuItem } from '../menu-item';

interface MenuOwnProps {
  withLogo?: boolean;
}

interface MenuStateProps {
  isExpanded: boolean;
  project: Project;
  location: string;
  calculation: ScenariosData.Calculation;
  scenario: ScenariosData.Scenario;
  bidPricing: BidPricingData;
  projectIdToRevisionId: Record<number, number>;
  subscriptionType: SubscriptionType;
  revisionState: RevisionState;
}

interface MenuDispatchProps {
  activateExpandLeftMenuAction: (isActivated: boolean) => void;
}

interface MenuProps extends MenuOwnProps, MenuStateProps, MenuDispatchProps, AbilityAwareProps, RoutingContextProps { }

interface MenuState {
  hasVerticalScroll: boolean;
  scrolledBottom: boolean;
}

interface ItemDescriptor {
  text: string;
  route: ParametrizedAppRoute<PlanProjectRouteParams>;
  icon: string;
  availableWithoutValidationResult: boolean;
  abilitySubjects: Subject[];
  availableWithoutProjectFiles?: boolean;
  availableWithoutBimProcessing?: boolean;
  availableWithoutUnstartedProducts?: boolean;
  status?: ProjectStatus;
  relatedViewModel?: KnownViewModel;
  waitForRelatedViewModelReady?: boolean;
  isAvailableOnlyForBIDPricingCalculation?: boolean;
  isAvailable?: boolean;
}

const qtoMenuItemText = 'Quantity Take Off';
const costEstimateMenuItemText = 'Cost Estimate';

const getCostEstimateMenuItems = (project: Project, revisionState: RevisionState): ItemDescriptor[] => {
  return [
    {
      text: 'Model Management',
      route: CostEstimateUrls.project.modelManagement,
      icon: TNavViewer,
      availableWithoutValidationResult: true,
      availableWithoutBimProcessing: true,
      availableWithoutProjectFiles: true,
      abilitySubjects: [Subject.ModelManagement],
    },
    {
      text: 'Collaboration',
      route: CostEstimateUrls.project.viewer,
      icon: TNavViewer,
      availableWithoutValidationResult: true,
      abilitySubjects: [Subject.Viewer],
      relatedViewModel: KnownViewModel.BimProcessing,
      waitForRelatedViewModelReady: true,
    },
    {
      text: 'Information Modeling',
      route: CostEstimateUrls.project.informationModeling.index,
      icon: TNavValidation,
      availableWithoutValidationResult: true,
      availableWithoutProjectFiles: true,
      availableWithoutUnstartedProducts: true,
      abilitySubjects: [Subject.CostEstimate, Subject.ValidationClassification],
      relatedViewModel: KnownViewModel.Classifier,
      waitForRelatedViewModelReady: true,
    },
    {
      text: 'Activity Assignment',
      route: CostEstimateUrls.project.revision.activityAssignment.index,
      icon: TNavQTO,
      availableWithoutValidationResult: true,
      availableWithoutProjectFiles: true,
      availableWithoutUnstartedProducts: false,
      abilitySubjects: [Subject.CostEstimate, Subject.Database, Subject.ValidationActivityAssignment],
      isAvailable: !!project.revisions && !!project.revisions.length && isValidationStepGreaterThan(
        project.validationState.validationStep, ValidationStep.Classification),
    },
    {
      text: 'Measurements',
      route: CostEstimateUrls.project.revision.measurements.index,
      icon: TNavQTO,
      availableWithoutValidationResult: true,
      availableWithoutProjectFiles: true,
      availableWithoutUnstartedProducts: false,
      abilitySubjects: [Subject.CostEstimate, Subject.Database, Subject.ValidationActivityAssignment],
      isAvailable: !!revisionState && isCostEstimateValidationStepGreaterThan(
        revisionState.validationStep, CostEstimateValidationStep.Measurements, true),
    },
    {
      text: costEstimateMenuItemText,
      route: CostEstimateUrls.project.revision.costEstimate,
      icon: TNavCosts,
      availableWithoutValidationResult: true,
      availableWithoutProjectFiles: true,
      availableWithoutUnstartedProducts: false,
      abilitySubjects: [Subject.CostEstimate],
      isAvailable: !!revisionState && isCostEstimateValidationStepGreaterThan(
        revisionState.validationStep, CostEstimateValidationStep.Reports, true),
    },
    {
      text: 'Reports',
      route: CostEstimateUrls.project.publishedReports,
      icon: TNavDashboard,
      availableWithoutValidationResult: true,
      availableWithoutProjectFiles: true,
      abilitySubjects: [Subject.CostEstimate],
    },
    {
      text: qtoMenuItemText,
      route: CostEstimateUrls.project.quantityTakeOff,
      icon: TNavQTO,
      availableWithoutValidationResult: true,
      availableWithoutProjectFiles: true,
      availableWithoutUnstartedProducts: false,
      abilitySubjects: [Subject.QuantityTakeOff3d],
      relatedViewModel: KnownViewModel.QuantityTakeOff,
      waitForRelatedViewModelReady: true,
    },
    {
      text: 'Model Check',
      route: CostEstimateUrls.project.modelCheck.index,
      availableWithoutValidationResult: true,
      icon: TNavValidation,
      abilitySubjects: [Subject.ValidationModelCheck],
    },
  ];
};

// revert menuItems: ItemDescriptor[]
const getMenuItems = (
  urls,
  subscriptionType: SubscriptionType,
  project: Project,
  revisionState: RevisionState,
): ItemDescriptor[] => {
  if (subscriptionType === SubscriptionType.CostEstimate) {
    return getCostEstimateMenuItems(project, revisionState);
  }

  return [
    {
      text: 'Model Management',
      route: urls.project.modelManagement,
      icon: TNavViewer,
      availableWithoutValidationResult: true,
      availableWithoutBimProcessing: true,
      availableWithoutProjectFiles: true,
      abilitySubjects: [Subject.ModelManagement],
    },
    {
      text: 'Collaboration',
      route: AppUrls.plan.project.viewer,
      icon: TNavViewer,
      availableWithoutValidationResult: true,
      abilitySubjects: [Subject.Viewer],
      relatedViewModel: KnownViewModel.BimProcessing,
      waitForRelatedViewModelReady: true,
    },
    {
      text: 'Validation',
      route: AppUrls.plan.project.validation.index,
      availableWithoutValidationResult: true,
      icon: TNavValidation,
      abilitySubjects: [
        Subject.ValidationClassification,
        Subject.ValidationActivityAssignment,
        Subject.ValidationMeasurements,
        Subject.ValidationResults,
      ],
    },
    {
      text: 'Scenarios',
      route: AppUrls.plan.project.scenario.listing,
      icon: TNavScenarios,
      availableWithoutValidationResult: false,
      abilitySubjects: [Subject.Scenarios],
    },
    {
      text: 'Dashboard',
      route: AppUrls.plan.project.dashboard,
      icon: TNavDashboard,
      availableWithoutValidationResult: false,
      abilitySubjects: [Subject.Dashboard],
    },
    {
      text: 'Costs',
      route: AppUrls.plan.project.costs,
      icon: TNavCosts,
      availableWithoutValidationResult: false,
      abilitySubjects: [Subject.Cost],
    },
    {
      text: 'Schedule',
      route: AppUrls.plan.project.schedule,
      icon: TNavSchedule,
      availableWithoutValidationResult: false,
      abilitySubjects: [Subject.Gantt],
    },
    {
      text: '4D Schedule',
      route: AppUrls.plan.project.fourD,
      icon: TNav4D,
      availableWithoutValidationResult: false,
      abilitySubjects: [Subject.FourDVisualisation],
    },
    {
      text: 'Bid Pricing',
      route: AppUrls.plan.project.bidPricing.index,
      icon: TNavBidPricing,
      status: ProjectStatus.OnBidPricing,
      availableWithoutValidationResult: false,
      abilitySubjects: [Subject.BidPricing],
      isAvailableOnlyForBIDPricingCalculation: true,
    },
    {
      text: qtoMenuItemText,
      route: urls.project.quantityTakeOff,
      icon: TNavQTO,
      availableWithoutValidationResult: true,
      availableWithoutProjectFiles: true,
      abilitySubjects: [Subject.QuantityTakeOff3d],
      relatedViewModel: KnownViewModel.QuantityTakeOff,
      waitForRelatedViewModelReady: true,
    },
    {
      text: 'Model Check',
      route: urls.project.modelCheck.index,
      availableWithoutValidationResult: true,
      icon: TNavValidation,
      abilitySubjects: [Subject.ValidationModelCheck],
    },
  ];
};

export class LeftMenu extends React.Component<MenuProps, MenuState> {
  private containerRef: HTMLDivElement;
  private listRef: HTMLDivElement;
  constructor(props: MenuProps) {
    super(props);

    this.state = {
      hasVerticalScroll: false,
      scrolledBottom: false,
    };

    this.props.activateExpandLeftMenuAction(true);
  }

  public componentWillUnmount(): void {
    this.props.activateExpandLeftMenuAction(false);
  }

  public componentDidMount(): void {
    this.setState({ hasVerticalScroll: this.containerRef.clientHeight < this.listRef.clientHeight });
  }

  public render(): React.ReactNode {
    const { isExpanded } = this.props;
    const items = this.getItems();

    const menuContainerClassName = classNames('project-menu', {
      'project-menu--collapsed': !isExpanded,
    });

    return (
      <CSSTransition
        timeout={300}
        in={!isExpanded}
        addEndListener={this.addEndListener}
      >
        <div className={menuContainerClassName}>
          {this.props.withLogo ? (
            <div className='logo-wrapper'>
              <Logo logoType={LogoType.LogoBlack} backUrl={AppUrls.products.url()} showLeftMenu={true} />
            </div>
          ) : null}
          <div
            className={classNames(
              'project-menu__container',
              { 'project-menu__container--has-scroll': this.state.hasVerticalScroll },
              { 'project-menu__container--scrolled-bottom': this.state.scrolledBottom },
            )}
            ref={this.saveContainerRef}
          >
            <KreoScrollbars onScroll={this.onScroll} showShadowTop={true} themeWhite={true}>
              <div className='project-menu__list' ref={this.saveListRef}>
                {items.map(this.renderItem)}
              </div>
            </KreoScrollbars>
          </div>
          <div className='project-menu__help-menu'>
            <HelpMenu isCollapsedMenu={!isExpanded} />
          </div>
        </div>
      </CSSTransition>
    );
  }

  @autobind
  private saveContainerRef(ref: HTMLDivElement): void {
    this.containerRef = ref;
  }

  @autobind
  private saveListRef(ref: HTMLDivElement): void {
    this.listRef = ref;
  }

  @autobind
  private checkIsVerticalScroll(): void {
    if (this.containerRef.clientHeight < this.listRef.clientHeight && !this.state.hasVerticalScroll) {
      this.setState({ hasVerticalScroll: true });
    } else if (this.containerRef.clientHeight >= this.listRef.clientHeight && this.state.hasVerticalScroll) {
      this.setState({ hasVerticalScroll: false });
    }
  }

  @autobind
  private addEndListener(): void {
    this.containerRef.addEventListener('transitionend', this.checkIsVerticalScroll, false);
  }

  @autobind
  private onScroll(e: React.UIEvent<HTMLDivElement>): void {
    if (
      this.listRef.clientHeight - e.currentTarget.scrollTop === this.containerRef.clientHeight &&
      !this.state.scrolledBottom
    ) {
      this.setState({ scrolledBottom: true });
    } else if (this.state.scrolledBottom) {
      this.setState({ scrolledBottom: false });
    }
  }

  @autobind
  private renderItem(item: ItemDescriptor, index: number): React.ReactNode {
    const isBlocked = this.isItemBlocked(item);
    const projectId = this.props.project ? this.props.project.id.toString() : null;
    const revisionId = this.props.projectIdToRevisionId && this.props.projectIdToRevisionId[projectId];

    return (
      <MenuItem
        key={index}
        isExpanded={this.props.isExpanded}
        text={item.text}
        icon={item.icon}
        route={item.route}
        blocked={isBlocked}
        inProgress={this.isItemInProcess(isBlocked, item)}
        projectId={projectId}
        revisionId={revisionId}
      />
    );
  }

  private getItems(): ItemDescriptor[] {
    const { urls, subscriptionType, revisionState, project, ability } = this.props;
    return getMenuItems(urls, subscriptionType, project, revisionState).filter(
      x =>
        x.abilitySubjects.length === 0 ||
        x.abilitySubjects.some(s => ability.can(Operation.Read, s)),
    );
  }

  // TODO (m-ziuz): refactor editability conditions
  private isItemBlocked(item: ItemDescriptor): boolean {
    if (item.isAvailable === false) {
      return true;
    }
    const { project, calculation } = this.props;
    const projectDataExists = project && project.id !== 0;
    if (
      !projectDataExists
      || (!item.availableWithoutProjectFiles && project.hasNoSourceFiles)
      || (!item.availableWithoutBimProcessing
        && project.viewModelsStatuses[KnownViewModel.BimProcessing] !== ViewModelStatus.Ready)
    ) {
      return true;
    }
    if (project.hasUnstartedProducts && item.availableWithoutUnstartedProducts !== null) {
      return !item.availableWithoutUnstartedProducts;
    }
    if (item.waitForRelatedViewModelReady) {
      const requiredViewModelReady = !project.viewModelsStatuses
        || project.viewModelsStatuses[item.relatedViewModel] !== ViewModelStatus.Ready;
      if (item.text === qtoMenuItemText) {
        // TODO (verich): investigate why isValidationStepLessThan ActivityAssignment is required,
        // requiredViewModelReady should be enough ?
        return requiredViewModelReady
          || !project.validationState
          || isValidationStepLessThan(
            project.validationState.validationStep,
            ValidationStep.ActivityAssignment,
            false,
          );
      } else {
        return requiredViewModelReady;
      }
    }

    return (
      this.isBlockedByValidationState(item) ||
      this.isBidPricingBlockedByCalculation(item, calculation)
    );
  }

  private isItemInProcess(isBlocked: boolean, item: ItemDescriptor): boolean {
    const { project } = this.props;
    if (isBlocked && project) {
      const { relatedViewModel, availableWithoutValidationResult } = item;
      const viewModelStatus = project.viewModelsStatuses
        ? project.viewModelsStatuses[relatedViewModel]
        : null;
      if (viewModelStatus) {
        return viewModelStatus === ViewModelStatus.Calculating;
      } else if (availableWithoutValidationResult) {
        return (
          project.validationState.validationStep === ValidationStep.Results &&
          project.validationState.stepState === ValidationStepState.Calculating
        );
      }
    }
    return false;
  }

  private isBlockedByValidationState(item: ItemDescriptor): boolean {
    if (item.availableWithoutValidationResult) {
      return false;
    }
    return !this.props.calculation || !this.props.project.isValidationCompleted;
  }

  private isBidPricingBlockedByCalculation(
    item: ItemDescriptor,
    calculation: ScenariosData.Calculation,
  ): boolean {
    const bidPricing = this.props.bidPricing;
    if (bidPricing.id) {
      // [permissions-todo]: add role check
      // was blocked for subcontractor

      return (
        calculation &&
        item.isAvailableOnlyForBIDPricingCalculation &&
        calculation.id !== bidPricing.calculationId
      );
    } else {
      return false;
    }
  }
}

const mapStateToProps = (state: State): MenuStateProps => {
  const currentProject = state.projects.currentProject;
  const projectIdToRevisionId = state.persistedStorage.projectIdToRevisionId || {};
  const revisionId = projectIdToRevisionId[currentProject && currentProject.id];
  const revisionState = state.projects.revisionStates[revisionId];

  return {
    isExpanded: state.toppanel.get('leftmenuExpanded'),
    project: currentProject,
    location: state.router.location.pathname.toString(),
    calculation: state.scenarios.active_calculation,
    scenario: state.scenarios.active_scenario,
    bidPricing: state.projects.bidPricing,
    projectIdToRevisionId: state.persistedStorage.projectIdToRevisionId,
    subscriptionType: state.account.selectedSubscriptionType,
    revisionState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): MenuDispatchProps => {
  return {
    activateExpandLeftMenuAction: (value: boolean) => {
      dispatch(TopPanel.activateExpandLeftMenuAction(value));
    },
  };
};

export default withRoutingContext(
  withAbilityContext(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(LeftMenu),
  ),
);
