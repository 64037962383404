import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';

export function useAreInstancesIdsHidden(instancesIds: string[]): boolean {
  const hiddenIds = useSelector<State, string[]>(s => s.drawings.hiddenInstances);
  return useMemo(() => {
    const hiddenIdsSet = new Set(hiddenIds);
    return instancesIds.every((id) => hiddenIdsSet.has(id));
  }, [instancesIds, hiddenIds]);
}
