import styled, { css, FlattenSimpleInterpolation } from 'styled-components';


function getColumnsWidth(planLength: number): FlattenSimpleInterpolation {
  if (planLength > 3) {
    return css`
      grid-template-columns: 250px 240px 240px 240px 240px;
      `;
  } else {
    return css`
      grid-template-columns: 310px 1fr ${planLength > 2 && '1fr'} ${planLength > 1 && '1fr'};
    `;
  }
}

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  background-color: ${props => props.theme.color.backgroundRush};
  border-radius: 20px;
  margin-bottom: 40px;
`;

const ContainerTable = styled.div`
  margin: 0 -30px -10px;
`;

const Row = styled.div<{ planLength: number }>`
  display: grid;
  ${p => getColumnsWidth(p.planLength)};
  grid-template-rows: auto;
`;

const FeatureHead = styled.div`
  display: flex;
  padding: 10px 20px 10px 30px;
  justify-content: space-between;
`;

const FeatureValue = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  border-left: 1px solid ${props => props.theme.color.background};
`;

const QuestionIcon = styled.div`
  padding-left: 5px;
  svg {
    width: 20px;
    fill: ${p => p.theme.color.turquoise}
  }
  display: flex;
`;

export const Styled = {
  Container,
  ContainerTable,
  Row,
  FeatureHead,
  FeatureValue,
  QuestionIcon,
};
