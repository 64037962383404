import { DrawingsDrawMode, DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsSelectAggregationGroup } from 'common/components/drawings/interfaces';
import { DrawingsStyleMenuType } from './enums';

const COUNT_RESULT_MODES = [
  DrawingsDrawMode.Count,
  DrawingsDrawMode.Finder,
];

function getTypeByDrawingMode(drawMode: DrawingsDrawMode): DrawingsStyleMenuType {
  return COUNT_RESULT_MODES.includes(drawMode) ? DrawingsStyleMenuType.Count : DrawingsStyleMenuType.Stroked;
}

function getTypeByAggregatedType(aggregation: DrawingsSelectAggregationGroup): DrawingsStyleMenuType {
  switch (aggregation) {
    case DrawingsSelectAggregationGroup.Area:
    case DrawingsSelectAggregationGroup.Line:
      return DrawingsStyleMenuType.Stroked;
    case DrawingsSelectAggregationGroup.Count:
      return DrawingsStyleMenuType.Count;
    default:
      return DrawingsStyleMenuType.OnlyColors;
  }
}

function getTypeByInstanceType(instanceType: DrawingsInstanceType): DrawingsStyleMenuType {
  switch (instanceType) {
    case DrawingsInstanceType.Polygon:
    case DrawingsInstanceType.Polyline:
    case DrawingsInstanceType.Rectangle:
      return DrawingsStyleMenuType.Stroked;
    case DrawingsInstanceType.Count:
      return DrawingsStyleMenuType.Count;
    default:
      return DrawingsStyleMenuType.OnlyColors;
  }
}

export const DrawingsStylesMenuUtils = {
  getTypeByDrawingMode,
  getTypeByAggregatedType,
  getTypeByInstanceType,
};
