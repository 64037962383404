import _ from 'lodash';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import {
  DrawingsShortInfo,
  TextSearchPagesType,
  TextSearchResult,
  TextSearchResultsInfo,
  TextSearchSettings,
} from '../interfaces';
import { CancelableResult } from '../interfaces/util';
import { PdfLoader } from './pdf-loader';
import { searchInDocs } from './search-text-in-pdfs';

export class TextSearchHelper  {
  private _loader: PdfLoader;

  private _executor: DeferredExecutor = new DeferredExecutor(1500);

  private _canceler: () => void;
  private _settings: TextSearchSettings;
  private _drawings: Record<string, DrawingsShortInfo>;

  constructor(loader: PdfLoader) {
    this._loader = loader;
  }

  public async runSearch(
    settings: TextSearchSettings,
    drawings: Record<string, DrawingsShortInfo>,
    onRunCallback: () => void,
    prevResults: TextSearchResultsInfo,
    currentDrawingId?: string,
  ): Promise<CancelableResult<TextSearchResultsInfo>> {
    onRunCallback();

    const areSettingsEqual = this.compareSettings(settings);
    const changedPagesSettingToCurrent = settings.pages !== this._settings?.pages
      && settings.pages === TextSearchPagesType.Current;
    this._settings = settings;
    const areDrawingsEqual = this.compareAndUpdateDrawings(drawings);
    if (areSettingsEqual && areDrawingsEqual) {
      return { isFinished: false, data: null };
    }

    await this.delay();
    this.cancel();

    if (!this._settings.query) {
      this._settings = settings;
      return { isFinished: true, data: { count: 0, results: {} } };
    }

    if (areSettingsEqual) {
      return { isFinished: true, data: this.filterResultByDrawingsChanged(prevResults, drawings) };
    } if (changedPagesSettingToCurrent) {
      if (!currentDrawingId) {
        return { isFinished: true, data: { count: 0, results: {} } };
      } else if (prevResults.results[currentDrawingId]) {
        return {
          isFinished: true,
          data: {
            count: prevResults.results[currentDrawingId].length,
            results: { [currentDrawingId]: prevResults.results[currentDrawingId] },
          },
        };
      }
    }

    if (this._settings.pages === TextSearchPagesType.Current && !currentDrawingId) {
      return { isFinished: true, data: { count: 0, results: {} } };
    }

    const drawingsToSearch = settings.pages === TextSearchPagesType.Current ?
      { [currentDrawingId]: drawings[currentDrawingId] }
      : drawings;

    const { promise, cancel } = searchInDocs(this._loader, drawingsToSearch, this._settings);
    this._canceler = cancel;
    const result = await promise;
    if (!result.isFinished) {
      return { isFinished: false, data: null };
    }
    let id = 0;
    const data: Record<string, TextSearchResult[]> = {};
    for (const [drawingId, results] of Object.entries(result.data)) {
      data[drawingId] = [];
      for (const { ambient, contour, output } of results) {
        id++;
        data[drawingId].push({
          id,
          drawingId,
          rectangleBounds: contour,
          ambient,
          output,
        });
      }

    }

    return { isFinished: true, data: { count: id, results: data } };
  }

  public cancel(): void {
    if (this._canceler) {
      this._canceler();
      this._canceler = null;
    }
  }

  private filterResultByDrawingsChanged(
    oldResult: TextSearchResultsInfo,
    newDrawings: Record<string, DrawingsShortInfo>,
  ): TextSearchResultsInfo {
    const result = { count: 0, results: {} };
    for (const [drawingId, drawingResult] of Object.entries(oldResult.results)) {
      if (newDrawings[drawingId]) {
        result.results[drawingId] = drawingResult;
        result.count += drawingResult.length;
      }
    }
    return result;
  }

  private compareSettings(settings: TextSearchSettings): boolean {
    return settings && _.isEqual(settings, this._settings);
  }

  private compareAndUpdateDrawings(drawings: Record<string, DrawingsShortInfo>): boolean {
    if (!this._drawings) {
      this._drawings = drawings;
      return false;
    }
    const currentKeys = Object.keys(this._drawings);
    const isEqual = drawings === this._drawings
      || (currentKeys.length === Object.keys(drawings).length && currentKeys.every(x => drawings[x]));
    this._drawings = drawings;
    return isEqual;
  }

  private delay(): Promise<true> {
    return new Promise(resolve => {
      this._executor.execute(() => resolve(true));
    });
  }
}
