import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ProjectType } from 'common/constants/project-type';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import {
  PROJECT_NAME_EDIT_DIALOG_NAME,
  TwoDProjectsNameEditDialog,
} from '.';


interface DispatchProps {
  changeProjectNameById: (name: string, id: number) => void;
  closeDialog: (dialogName: string) => void;
}

interface StateProps {
  project: { id: number, type: ProjectType, name: string };
}

interface Props extends DispatchProps, StateProps {
  onClose?: () => void;
}

class EditProjectNameDialogComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { project } = this.props;
    if (!project) {
      return null;
    }

    return (
      <TwoDProjectsNameEditDialog
        projectName={project.name}
        projectType={project.type}
        onEditProjectName={this.onEditProjectName}
        onClose={this.onCloseEditProjectNameDialog}
      />
    );
  }

  @autobind
  private onEditProjectName(name: string): void {
    const { project: { name: projectName, id }, changeProjectNameById } = this.props;
    this.onCloseEditProjectNameDialog();
    if (projectName !== name) {
      changeProjectNameById(name, id);
    }
  }

  @autobind
  private onCloseEditProjectNameDialog(): void {
    this.props.closeDialog(PROJECT_NAME_EDIT_DIALOG_NAME);
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    project: state.dialog[PROJECT_NAME_EDIT_DIALOG_NAME],
  };
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    changeProjectNameById: (name: string, id: number) => dispatch(ProjectsActions.updateProjectNameById(name, id)),
    closeDialog: dialogName => dispatch(KreoDialogActions.closeDialog(dialogName)),
  };
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export const EditProjectNameDialog = connector(EditProjectNameDialogComponent);
