export interface ExtractorFunctionComparator {
  compareClasses: (classNameFirst: string, classNameSecond: string) => number;
  compareFunctions: (classNameFirst: string, classNameSecond: string) => number;
}

export class ExtractorFunctionComparatorProvider {
  public static measurementExtractorFunctionsComparator: ExtractorFunctionComparator = {
    compareClasses: (a, b) => ExtractorFunctionComparatorProvider
      .compare(a, b, ExtractorFunctionComparatorProvider.compareMeasurementClassesTable),
    compareFunctions: (a, b) => ExtractorFunctionComparatorProvider
      .compare(a, b, ExtractorFunctionComparatorProvider.compareMeasurementFunctionsTable),
  };

  public static constraintExtractorFunctionsComparator: ExtractorFunctionComparator = {
    compareClasses: (a, b) => ExtractorFunctionComparatorProvider
      .compare(a, b, ExtractorFunctionComparatorProvider.compareConstraintClassesTable),
    compareFunctions: (a, b) => ExtractorFunctionComparatorProvider
      .compare(a, b, ExtractorFunctionComparatorProvider.compareConstraintFunctionsTable),
  };

  private static compareConstraintClassesTable: Record<string, number> = {
    ['Object']: 1,
    ['VerticalBB']: 2,
    ['ObjectBB']: 3,
    ['SelfDimension']: 4,
    ['DistanceTo']: 5,
    ['Reinforcement']: 6,
    ['Stair']: 7,
    ['Pipeline']: 8,
    ['Fitting']: 9,
    ['Ductline']: 10,
    ['DuctFitting']: 11,
    ['Storage']: 12,
    ['Location']: 13,
    ['Formwork']: 14,
  };

  private static compareConstraintFunctionsTable: Record<string, number> = {
    ['Object.SurfaceArea']: 101,
    ['Object.Quantity']: 102,
    ['Object.Mass']: 103,
    ['Object.Voltage']: 104,
    ['Object.MassPerMeter']: 105,
    ['VerticalBB.Height']: 201,
    ['VerticalBB.SectionMaxBBDimension']: 202,
    ['VerticalBB.SectionMinBBDimension']: 203,
    ['VerticalBB.SectionMaxDimension']: 204,
    ['VerticalBB.SectionMinDimension']: 205,
    ['VerticalBB.SectionArea']: 206,
    ['VerticalBB.Slope']: 207,
    ['ObjectBB.Length']: 301,
    ['ObjectBB.SectionMaxBBDimension']: 302,
    ['ObjectBB.SectionMinBBDimension']: 303,
    ['ObjectBB.SectionMaxDimension']: 304,
    ['ObjectBB.SectionMinDimension']: 305,
    ['SelfDimension.Length']: 401,
    ['SelfDimension.SectionVerticalDimension']: 402,
    ['SelfDimension.SectionHorizontalDimension']: 403,
    ['SelfDimension.SectionMaxDimension']: 404,
    ['SelfDimension.SectionMinDimension']: 405,
    ['SelfDimension.SectionArea']: 406,
    ['SelfDimension.Thickness']: 407,
    ['SelfDimension.WideArea']: 408,
    ['SelfDimension.Girth']: 409,
    ['SelfDimension.HorizontalRadius']: 410,
    ['DistanceToUpperSlab']: 501,
    ['DistanceToLowerSlab']: 502,
    ['Reinforcement.Diameter']: 601,
    ['Reinforcement.MassPerMeter']: 602,
    ['Stair.Length']: 701,
    ['Stair.Width']: 702,
    ['Stair.TreadWidth']: 703,
    ['Stair.RiserHeight']: 704,
    ['Stair.RiserWidth']: 705,
    ['Stair.TreadThickness']: 706,
    ['Stair.TreadNumber']: 707,
    ['Stair.FormworkToSoffits']: 708,
    ['Pipeline.Diameter']: 801,
    ['Fitting.MaxDiameter']: 901,
    ['Fitting.MinDiameter']: 902,
    ['Fitting.Diameter']: 904,
    ['Fitting.Adapter']: 905,
    ['Fitting.Barb']: 906,
    ['Fitting.Branch']: 907,
    ['Fitting.Cap']: 908,
    ['Fitting.Coupling']: 909,
    ['Fitting.Cross']: 910,
    ['Fitting.Elbow']: 911,
    ['Fitting.Flange']: 912,
    ['Fitting.Inlet']: 913,
    ['Fitting.Nipple']: 914,
    ['Fitting.Offset']: 915,
    ['Fitting.Outlet']: 916,
    ['Fitting.Plug']: 917,
    ['Fitting.Reducer']: 918,
    ['Fitting.Ring']: 919,
    ['Fitting.Socket']: 920,
    ['Fitting.Spigot']: 921,
    ['Fitting.StubEnd']: 922,
    ['Fitting.Tee']: 923,
    ['Fitting.Union']: 924,
    ['Fitting.Valve']: 925,
    ['Fitting.Wye']: 926,
    ['Ductline.Diameter']: 1001,
    ['Ductline.SmallerSize']: 1002,
    ['Ductline.LargerSize']: 1003,
    ['DuctFitting.Diameter']: 1101,
    ['DuctFitting.SmallerSectionSize']: 1102,
    ['DuctFitting.LargerSectionSize']: 1103,
    ['Storage.Volume']: 1201,
    ['Location.Internal']: 1301,
    ['Location.External']: 1302,
    ['Formwork.Horizontal']: 1401,
    ['Formwork.HorizontalWithOpenings']: 1402,
    ['Formwork.SlantedLess15']: 1403,
    ['Formwork.SlantedMore15']: 1404,
    ['Formwork.Vertical']: 1405,
    ['Formwork.VerticalWithOpenings']: 1406,
    ['Formwork.SlopedPerimeter']: 1407,
    ['Formwork.SlopedPerimeterWithOpenings']: 1408,
    ['Formwork.VerticalDoubleSide']: 1409,
  };

  private static compareMeasurementClassesTable: Record<string, number> = {
    ['Object']: 1,
    ['VerticalBB']: 2,
    ['ObjectBB']: 3,
    ['SelfDimension']: 4,
    ['Reinforcement']: 5,
    ['Stair']: 6,
    ['Frame']: 7,
    ['Truss']: 8,
    ['Pipeline']: 9,
    ['Cable']: 10,
    ['Ductline']: 11,
    ['Insulation']: 12,
    ['Storage']: 13,
    ['Formwork']: 14,
  };


  private static compareMeasurementFunctionsTable: Record<string, number> = {
    ['Object.Volume']: 101,
    ['Object.SurfaceArea']: 102,
    ['Object.HorizontalSurfaceArea']: 103,
    ['Object.VerticalSurfaceArea']: 104,
    ['Object.Quantity']: 105,
    ['Object.Mass']: 106,
    ['VerticalBB.Height']: 201,
    ['VerticalBB.SideSurfaceArea']: 202,
    ['VerticalBB.TopSurfaceArea']: 203,
    ['VerticalBB.BottomSurfaceArea']: 204,
    ['VerticalBB.HorizontalPerimeter']: 205,
    ['ObjectBB.Length']: 301,
    ['ObjectBB.SideSurfaceArea']: 302,
    ['SelfDimension.Length']: 401,
    ['SelfDimension.SideSurfaceArea']: 402,
    ['SelfDimension.TopSurfaceArea']: 403,
    ['SelfDimension.BottomSurfaceArea']: 404,
    ['SelfDimension.WideArea']: 405,
    ['SelfDimension.WidePerimeter']: 406,
    ['Reinforcement.Mass']: 501,
    ['Reinforcement.MeshArea']: 502,
    ['Stair.TreadsAndRisersArea']: 601,
    ['Stair.Length']: 602,
    ['Stair.FormworkToSoffits']: 603,
    ['Frame.Mass']: 701,
    ['Truss.Mass']: 801,
    ['Pipeline.Length']: 901,
    ['Cable.Length']: 1001,
    ['Ductline.Length']: 1101,
    ['Insulation.AreaOnMepLine']: 1201,
    ['Storage.Volume']: 1301,
    ['Formwork.Horizontal']: 1401,
    ['Formwork.HorizontalWithOpenings']: 1402,
    ['Formwork.SlantedLess15']: 1403,
    ['Formwork.SlantedMore15']: 1404,
    ['Formwork.Vertical']: 1405,
    ['Formwork.VerticalWithOpenings']: 1406,
    ['Formwork.SlopedPerimeter']: 1407,
    ['Formwork.SlopedPerimeterWithOpenings']: 1408,
    ['Formwork.VerticalDoubleSide']: 1409,
  };

  private static compare(
    nameFirst: string,
    nameSecond: string,
    compareTable: Record<string, number>,
  ): number {
    const firstOrderValue = compareTable[nameFirst];
    const secondOrderValue = compareTable[nameSecond];
    if (firstOrderValue && secondOrderValue) {
      return firstOrderValue - secondOrderValue;
    }

    if (!firstOrderValue && !secondOrderValue) {
      return nameFirst.localeCompare(nameSecond);
    }

    if (!firstOrderValue) {
      return 1;
    } else {
      return -1;
    }
  }
}
