import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './project-members-dialog.scss';

import {
  HorizontalTab,
  HorizontalTabProps,
  HorizontalTabsContainer,
} from 'common/components/horizontal-tabs-container';
import { ProjectPeople } from 'common/components/project-people-panel/project-people';
import { RoleGroup } from 'common/enums/role-group';
import { State } from 'common/interfaces/state';
import { KreoDialog, KreoDialogActions } from 'common/UIKit';
import { KreoDialogActionsPanel } from 'common/UIKit/dialogs/kreo-dialog-actions-panel';
import { PlanProjectRouteParams, Qto3dProjectRouteParams } from '../../../../routes/app-routes-params';
import { Company } from '../../../account/interfaces/company';
import { PeopleActions } from '../../../people/actions/actions';
import { ProjectPerson } from '../../interfaces/project-person';
import { PeopleOnProjectSelect } from '../people-on-project-select';

interface StateProps {
  people: ProjectPerson[];
  company: Company;
  open: boolean;
}

interface OwnProps {
  projectPeople: ProjectPeople[];
}

interface DispatchProps {
  confirmForm: (deletePeople: string[], addPeople: string[], projectId: number) => void;
  dialogClose: () => void;
}

interface Props extends StateProps,
  OwnProps,
  DispatchProps,
  RouteComponentProps<PlanProjectRouteParams | Qto3dProjectRouteParams> {}
interface DialogState {
  selectedEmails: string[];
}

export class ProjectMembers extends React.Component<Props, DialogState> {
  public static dialogName: string = 'project-members';

  constructor(props: Props) {
    super(props);
    this.state = { selectedEmails: [] };
  }

  public static getDerivedStateFromProps(props: Props): DialogState {
    if (props.open) {
      return null;
    } else {
      return { selectedEmails: [] };
    }
  }

  public render(): React.ReactNode {
    return (
      <KreoDialog
        name={ProjectMembers.dialogName}
        isModal={true}
        title='Manage Members'
        bodyClassName='project-members-dialog'
      >
        <div className='project-members-dialog__description'>
          Add of remove members to collaborate on the project.
        </div>
        <HorizontalTabsContainer>
          {(<HorizontalTab name='Employees' className='project-members-dialog__tab'>
            <PeopleOnProjectSelect
              onSelectChange={this.onSelectChange}
              selectedEmails={this.state.selectedEmails}
              people={this.props.people.filter(person => person.roleGroup === RoleGroup.Employee)}
              emptyText='You don’t have Employees'
              onInviteClick={this.props.dialogClose}
            />
          </HorizontalTab>) as React.ReactElement<HorizontalTabProps>}
          {(<HorizontalTab name='Guests' className='project-members-dialog__tab'>
            <PeopleOnProjectSelect
              onSelectChange={this.onSelectChange}
              selectedEmails={this.state.selectedEmails}
              people={this.props.people.filter(person => person.roleGroup === RoleGroup.Guest)}
              emptyText='You don’t have Guests'
              onInviteClick={this.props.dialogClose}
            />
          </HorizontalTab>) as React.ReactElement<HorizontalTabProps>}
        </HorizontalTabsContainer>
        <KreoDialogActionsPanel
          onCancel={this.props.dialogClose}
          onSubmit={this.onConfirm}
          submitDisabled={this.state.selectedEmails.length === 0}
        />
      </KreoDialog>
    );
  }

  @autobind
  private onSelectChange(selected: boolean, email: string, defaultSelected: boolean): void {
    const selectedValue = defaultSelected ? !selected : selected;
    if (selectedValue) {
      this.setState({
        selectedEmails: this.state.selectedEmails.concat(email),
      });
    } else {
      const selectedEmails = this.state.selectedEmails.filter(x => x !== email);
      this.setState({ selectedEmails });
    }
  }

  @autobind
  private onConfirm(): void {
    const deletePeople = [];
    const addPeople = [];
    const emails = new Set(this.props.projectPeople.map(x => x.email));
    this.state.selectedEmails.forEach(email => {
      if (emails.has(email)) {
        deletePeople.push(email);
      } else {
        addPeople.push(email);
      }
    });
    this.props.confirmForm(deletePeople, addPeople, parseInt(this.props.match.params.projectId, 10));
  }
}

const mapStateToProps = (state: State, props: OwnProps): StateProps => {
  const company = state.account.selectedCompany;
  const emails = props.projectPeople.map(x => x.email);

  const companyUsers = state.people.companiesUsers;

  const people: ProjectPerson[] = [];

  if (companyUsers) {
    companyUsers.forEach(person => {
      if (person.email !== state.account.email) {
        people.push({
          ...person,
          selectedDefault: emails.includes(person.email),
          isYou: false,
        });
      }
    });
  }

  return {
    people,
    company,
    open: ProjectMembers.dialogName in state.dialog,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    confirmForm: (deletePeople: string[], addPeople: string[], projectId: number) => {
      if (deletePeople.length > 0) {
        dispatch(PeopleActions.removeFromProject({ emails: deletePeople, projectId }));
      }
      if (addPeople.length > 0) {
        dispatch(
          PeopleActions.invitePeopleToProject({
            emails: addPeople,
            projectId,
            isCompanyShared: false,
          }),
        );
      }
      dispatch(KreoDialogActions.closeDialog(ProjectMembers.dialogName));
    },
    dialogClose: () => {
      dispatch(KreoDialogActions.closeDialog(ProjectMembers.dialogName));
    },
  };
};

export const ProjectMembersDialog = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectMembers));
