import * as Ag from 'ag-grid-community';

export const getExportColumn = (exportReport: (id: number) => void): Ag.ColDef => {
  return {
    colId: 'export-column',
    field: 'exportId',
    cellRenderer: 'exportButtonCell',
    onCellClicked: (event: Ag.CellClickedEvent) => {
      exportReport(event.node.data.id);
    },
  };
};
