import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { HeaderContext } from 'common/enums/header-context';
import { LogoType } from 'common/enums/logo-type';
import { State as ReduxState } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { PageLayout } from '../../../layouts/page-layout';
import { ScenarioRouteParams } from '../../../routes/app-routes-params';
import { NotFoundPage } from '../../notfound/page';
import { ActivityGroupingPage, ScenariosActions, ScenariosData, WorkPackages } from '../../scenarios';
import { IndirectCostPage } from '../pages/indirect-cost-page';
import { ResourceLimitationsPage } from '../pages/resource-limitations-page';

interface ReduxProps {
  scenarios: ScenariosData.Scenario[];
  editScenario: ScenariosData.Scenario;
}

interface ReduxActions {
  setEditScenario: (scenario: ScenariosData.Scenario) => void;
}

interface Props extends ReduxProps, ReduxActions, RouteComponentProps<ScenarioRouteParams> {}

const editScenarioHeader = {
  context: HeaderContext.EditScenario,
  logoType: LogoType.LogoBlackLight,
};

class ScenarioRoutesComponent extends React.Component<Props> {
  public componentDidMount(): void {
    if (!this.props.editScenario && this.props.scenarios) {
      const scenario = this.props.scenarios.find(
        x => x.id === parseInt(this.props.match.params.scenarioId, 10),
      );

      if (scenario) {
        this.props.setEditScenario(scenario);
      }
    }
  }

  public componentDidUpdate(): void {
    if (!this.props.editScenario && this.props.scenarios) {
      const scenario = this.props.scenarios.find(
        x => x.id === parseInt(this.props.match.params.scenarioId, 10),
      );

      if (scenario) {
        this.props.setEditScenario(scenario);
      }
    }
  }

  public render(): JSX.Element {
    const editScenarioRouteProps = {
      subject: Subject.Scenarios,
      operation: Operation.Update,
      redirectUrl: AppUrls.plan.project.index.url({ projectId: this.props.match.params.projectId }),
      header: {
        ...editScenarioHeader,
        title: this.props.editScenario ? this.props.editScenario.name : null,
        backUrl: AppUrls.plan.project.scenario.listing.url({ projectId: this.props.match.params.projectId }),
      },
    };

    return (
      <Switch>
        <PageLayout
          exact={true}
          metaTitle='Resources Limitation'
          path={AppUrls.plan.project.scenario.resourceLimitations.path}
          component={ResourceLimitationsPage}
          {...editScenarioRouteProps}
        />
        <PageLayout
          exact={true}
          metaTitle='Work Packages'
          path={AppUrls.plan.project.scenario.workpackages.path}
          component={WorkPackages}
          {...editScenarioRouteProps}
        />
        <PageLayout
          exact={true}
          metaTitle='Sequence'
          path={AppUrls.plan.project.scenario.sequence.path}
          component={ActivityGroupingPage}
          subject={Subject.SequenceStep}
          {...editScenarioRouteProps}
        />
        <PageLayout
          exact={true}
          metaTitle={`Main Contractor\'s Cost`}
          path={AppUrls.plan.project.scenario.indirectCosts.path}
          component={IndirectCostPage}
          {...editScenarioRouteProps}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    scenarios: state.scenarios.scenarios,
    editScenario: state.scenarios.editScenario,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    setEditScenario: (scenario: ScenariosData.Scenario) => dispatch(ScenariosActions.setEditScenario(scenario)),
  };
};

export const ScenarioRoutes = connect(mapStateToProps, mapDispatchToProps)(ScenarioRoutesComponent);
