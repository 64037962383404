import * as React from 'react';

export const KreoIconEditPen: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
    <g id='icon_edit-pen' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M9.64644661,6.35355339 L4.64644661,11.3535534 C4.55267842,11.4473216 4.42550146,11.5 4.29289322,11.5
          L3,11.5 C2.72385763,11.5 2.5,11.2761424 2.5,11 L2.5,9.70710678 C2.5,9.57449854 2.55267842,9.44732158
          2.64644661,9.35355339 L7.64644661,4.35355339 C7.84170876,4.15829124 8.15829124,4.15829124
          8.35355339,4.35355339 L9.64644661,5.64644661 C9.84170876,5.84170876 9.84170876,6.15829124
          9.64644661,6.35355339 Z M11.6464466,4.35355339 L10.8535534,5.14644661 C10.6582912,5.34170876
          10.3417088,5.34170876 10.1464466,5.14644661 L8.85355339,3.85355339 C8.65829124,3.65829124
          8.65829124,3.34170876 8.85355339,3.14644661 L9.64644661,2.35355339 C9.84170876,2.15829124
          10.1582912,2.15829124 10.3535534,2.35355339 L11.6464466,3.64644661 C11.8417088,3.84170876
          11.8417088,4.15829124 11.6464466,4.35355339 Z'
          id='Combined-Shape'
          fill='currentColor'
        />
    </g>
    </svg>
  );
};
