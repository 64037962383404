import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import { CommentModel, CommentPointType } from '../../interfaces';
import { Comment } from './comment';
import { NoComments } from './no-comments';

interface Props {
  commentaries: CommentModel[];
  peopleMap: any;
  companyId: number;
  projectId: number;
  className: string;
  onCommentClick: (value: any) => void;
  onToStateClick: (value: any) => void;
  selectComment: (index: number) => void;
}

export class Body extends React.Component<Props> {
  public render(): React.ReactNode {
    const withComments = this.props.commentaries.length > 0;
    const noCommentClassName = !withComments ? `${this.props.className}--no-comments` : null;
    return (
      <div className={classNames(this.props.className, noCommentClassName)}>
        {withComments ? this.props.commentaries.map((x, index) => {
          const userInfo = this.props.peopleMap[x.owner];
          if (!userInfo) {
            return undefined;
          }
          let read = false;
          const lastActivity = this.getCommentLastActivity(x);
          const lastRead = new Date(x.lastRead).getTime() - new Date(lastActivity).getTime();
          if (lastRead) {
            read = true;
          }
          return (
            <Comment
              key={index}
              comment={x}
              date={lastActivity}
              read={read}
              companyId={this.props.companyId}
              projectId={this.props.projectId}
              authorId={x.owner}
              authorFirstName={userInfo.firstName}
              authorLastName={userInfo.lastName}
              type={x.pointType === CommentPointType.OnElement ? 'point' : 'view'}
              onCommentClick={this.onCommentClick}
              onToStateClick={this.toStateClick}
            />
          );

        }) : (
            <NoComments />
        )}
      </div>
    );
  }

  @autobind
  private getCommentLastActivity(comment: CommentModel): Date {
    if (comment.data.subComments && comment.data.subComments.length > 0) {
      return comment.data.subComments[comment.data.subComments.length - 1].created;
    } else {
      return comment.data.created;
    }
  }

  @autobind
  private onCommentClick(comment: CommentModel): void {
    this.props.onCommentClick(comment);
  }

  @autobind
  private toStateClick(state: string, num: number): void {
    this.props.selectComment(num);
    if (state) {
      this.props.onToStateClick(JSON.parse(state));
    }

  }
}
