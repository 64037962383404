import { ICellEditorParams } from 'ag-grid-community';

import { CellEditor } from './cell-editor';

const formulaPartSeparationRegex = /([^\=\+\-\*\ \/\^\[\]]+)|(\[[^\]]+\])|([\=\+\-\*\ \/\^]+)/g;

function getIndexOfInnerText(parent: HTMLSpanElement): number {
  const selection = document.getSelection();
  const range = selection.getRangeAt(0);
  const startContainer = range.startContainer;

  const rangeForCount = new Range();
  rangeForCount.setStart(parent, 0);
  rangeForCount.setEnd(startContainer, range.endOffset);

  return rangeForCount.toString().length;
}

function getNewPosition(index: number, parent: HTMLSpanElement): { node: HTMLElement, index: number } {
  let count = index;
  const childNodes = parent.childNodes as any;

  for (const element of childNodes) {
    const length = element.nodeType === 3
      ? element.data.length
      : element.innerText.length;
    if (count <= length) {
      if (element.nodeType !== 3) {
        return { node: element.childNodes[0], index: count };
      } else {
        return { node: element, index: count };
      }
    }
    count -= length;
  }

  return { node: parent, index: parent.childNodes.length };
}

function getInnerHtml(className: string): string {
  const wrapper = CellEditor.getEditorHtmlWrapper();
  const span = document.createElement('span');
  span.className = className;
  span.contentEditable = 'true';
  wrapper.appendChild(span);
  return wrapper.outerHTML;
}

function getValueElement(value: string, className: string, params: ICellEditorParams): HTMLSpanElement {
  const span = document.createElement('span');
  span.contentEditable = 'true';
  span.className = className;
  const coloredValue = getColoredText(value, params);
  span.innerHTML = coloredValue;
  return span;
}

function getColoredText(data: string, params: ICellEditorParams): string {
  const updatedText = data.replace(
    formulaPartSeparationRegex,
    (_match, customText, key, separator) => {
      if (separator) {
        return wrapSeparator(separator);
      }

      if (key) {
        return wrapKey(key, params);
      }

      if (customText) {
        return wrapCustomText(customText);
      }
    });
  params.context.highlight.updateColumnColor(data, params);
  params.api.refreshCells({ force: true, rowNodes: [params.node] });
  return updatedText;
}

function wrapSeparator(separator: string): string {
  const separatorSpan = document.createElement('span');
  const separatorSpanValue = document.createTextNode(separator);
  separatorSpan.appendChild(separatorSpanValue);
  return separatorSpan.outerHTML;
}

function wrapKey(key: string, params: ICellEditorParams): string {
  const keySpan = document.createElement('span');
  const keySpanValue = document.createTextNode(key);
  keySpan.appendChild(keySpanValue);
  const headerName = key.toUpperCase().replace(/[\[\]]/g, '');
  const colDef = params.columnApi.getAllColumns().find((column) => {
    return column.getColDef().headerName.toUpperCase() === headerName;
  });
  if (colDef) {
    keySpan.style.color = '#ffffff';
    keySpan.style.backgroundColor = params.context.highlight.getColor(colDef.getColId());
  }
  return keySpan.outerHTML;
}

function wrapCustomText(customText: string): string {
  if (isNaN(Number(customText))) {
    const otherTextSpan = document.createElement('span');
    const otherTextSpanValue = document.createTextNode(customText);
    otherTextSpan.appendChild(otherTextSpanValue);
    return otherTextSpan.outerHTML;
  } else {
    const numberSpan = document.createElement('span');
    numberSpan.className = 'number';
    const numberText = document.createTextNode(customText);
    numberSpan.appendChild(numberText);
    return numberSpan.outerHTML;
  }
}

function handelInput(ev: Event, params: ICellEditorParams): void {
  const target = ev.target as HTMLSpanElement;
  const data = target.innerText;

  const updatedText = getColoredText(data, params);
  const oldIndex = getIndexOfInnerText(target);
  target.innerHTML = updatedText;
  const { node, index } = getNewPosition(oldIndex, target);
  document.getSelection().setPosition(node, index);
}


export const FormulaCellEditorHelper = {
  getInnerHtml,
  getValueElement,
  getColoredText,
  handelInput,
  formulaPartSeparationRegex,
};
