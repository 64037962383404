const prefix = '@four-d-visualisation';

export const TOGGLE_CHART_LINE_EXPAND_STATUS = `${prefix}/TOGGLE_CHART_LINE_EXPAND_STATUS`;
export const SET_SIDE_PANEL_TAB = `${prefix}/SET_ACTIVE_SIDE_PANEL_TAB`;
export const SET_ACTIVITIES_TAB_MODE = `${prefix}/SET_ACTIVITIES_TAB_MODE`;
export const SET_SELECTED_ELEMENTS_IDS = `${prefix}/SET_SELECTED_ELEMENTS_IDS`;
export const TOGGLE_ACTIVITY_CARD_EXPAND_STATUS = `${prefix}/TOGGLE_ACTIVITY_CARD_EXPAND_STATUS`;
export const SET_RESOURCES_TAB_MODE = `${prefix}/SET_RESOURCES_TAB_MODE`;
export const SELECT_RESOURCE_FOR_TIMELINE = `${prefix}/SELECT_RESOURCE_FOR_TIMELINE`;
export const DESELECT_RESOURCE_FOR_TIMELINE = `${prefix}/DESELECT_RESURCE_FOR_TIMELINE`;
export const EXPAND_ALL_ACTIVITY_CARDS = `${prefix}/EXPAND_ALL_ACTIVITY_CARDS`;
export const COLLAPSE_ALL_ACTIVITY_CARDS = `${prefix}/COLLAPSE_ALL_ACTIVITY_CARDS`;
