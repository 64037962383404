import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px;
  padding-top: 0;
  padding-bottom: 23px;
`;

const Content = styled.div`
  height: calc(100% - 63px);
  box-shadow: 0 4px 20px 0 rgba(77, 92, 128, 0.08);
  background: white;
  border-radius: 6px;
  overflow: hidden;

  h3 {
    flex-grow: 1;
    margin: 0;
    padding-top: 29px;
    padding-bottom: 28px;
    color: #2D4969;
    font-size: 24px;
    line-height: 33px;
    display: block;
    font-weight: 400;
  }
`;

const StepContent = styled.div`
  width: 36%;
  &:first-child {
    box-sizing: border-box;
    min-width: 461px;
    flex-basis: 461px;
    padding: 0 30px;
    border-right: solid #BACEDE 1px;
  }

  &:last-child {
    flex-grow: 1;
  }
`;

const StepEngine = styled.div`
  width: 64%;
  display: flex;
  justify-content: center;
  padding-top: 20px;

  position: relative;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
`;

export const Styled = {
  Content,
  Top,
  Container,
  StepContent,
  StepEngine,
  Wrapper,
};
