import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import {
  AnnotationFilters,
  PagesFilterValue,
  ScheduleFilterValue,
  SetType,
  ShapesFilterValue,
  OriginFilterValue,
} from '../../interfaces/drawing-filters';
import { FilterGroup, FilterSet } from './interfaces';


const shapesFilterData: FilterSet = {
  selectType: SetType.List,
  filterType: AnnotationFilters.Shapes,
  elements: [
    {
      icon: <Icons.Area2D />,
      name: 'Area',
      value: ShapesFilterValue.Polygons,
    },
    {
      icon: <Icons.Line />,
      name: 'Line',
      value: ShapesFilterValue.Lines,
    },
    {
      icon: <Icons.Dot />,
      name: 'Count',
      value: ShapesFilterValue.Points,
    },
  ],
};

const sourceFilterData: FilterSet = {
  selectType: SetType.Select,
  filterType: AnnotationFilters.Origin,
  elements: [
    {
      name: 'All measurements',
      value: OriginFilterValue.All,
    },
    {
      name: 'Auto Measure measurements',
      value: OriginFilterValue.AutoMeasure,
    },
    {
      name: 'Manual measurements',
      value: OriginFilterValue.Manual,
    },
  ],
};

const shapesFilterGroup: FilterGroup = {
  sets: [shapesFilterData, sourceFilterData],
};

const pagesFilterData: FilterSet = {
  name: 'BY PAGES',
  filterType: AnnotationFilters.Pages,
  selectType: SetType.RadioButton,
  elements: [
    {
      name: 'Current page',
      value: PagesFilterValue.Current,
    },
    {
      name: 'All pages',
      value: PagesFilterValue.All,
    },
    {
      name: 'Opened pages',
      value: PagesFilterValue.Open,
    },
  ],
};
const reportFilterData = {
  name: 'BY REPORT',
  filterType: AnnotationFilters.Report,
  selectType: SetType.RadioButton,
  elements: [
    {
      name: 'Unused',
      value: ScheduleFilterValue.Unscheduled,
    },
    {
      name: 'Used',
      value: ScheduleFilterValue.Scheduled,
    },
    {
      name: 'Cell Range',
      value: ScheduleFilterValue.CellRange,
    },
  ],
};

export const FILTER_SETS = [shapesFilterGroup, pagesFilterData, reportFilterData];
