import React from 'react';

import { useWizzardSetting } from 'common/components/drawings/hooks';
import { MagicSearchToggle } from '../../magic-search-toggle';
import { SettingItem } from '../../setting-item';
import { TUTORIALS } from '../constants';
import { WizzardMainContainer } from '../wizzard-main-container';


const WandToolSettingsComponent: React.FC = () => {
  const [connect, changeConnect] = useWizzardSetting('connect');
  const [enclose, changeEnclose] = useWizzardSetting('enclose');

  return (
    <WizzardMainContainer>
      <SettingItem
        label="Connect"
        checked={connect}
        onChange={changeConnect}
        disabled={false}
        tutorial={TUTORIALS.Connect}
      />
      <SettingItem
        label="Enclose"
        checked={enclose}
        onChange={changeEnclose}
        disabled={!connect}
        tutorial={TUTORIALS.Enclose}
      />
      <MagicSearchToggle
        disabled={!enclose || !connect}
      />
    </WizzardMainContainer>
  );
};

export const WandToolSettings = React.memo(WandToolSettingsComponent);
