import styled from 'styled-components';
import { PdfBrowserFileTitleStyled } from './drawings-pdf-browser-file-title';
import { PAGE_HEIGHT, PAGE_WIDTH } from './drawings-pdf-browser-page/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 40px);
  max-height: inherit;
  margin: 20px 0;
  position: relative;
`;

const FilesContainer = styled.div`
  padding: 0 17px;
  overflow-y: scroll;
  height: 100%;
`;

const PagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,${PAGE_WIDTH}px);
  grid-template-rows: repeat(auto-fill,${PAGE_HEIGHT}px);
  grid-gap: 20px;
  margin-top: 20px;
`;

const WrapperContent = styled.div`
  padding: 15px 0;
  ${PdfBrowserFileTitleStyled.Container} > p {
    word-break: break-all;
  }
`;

const FileTitle = styled.div`

`;

const Placeholder = styled.div`
  margin: auto;
  font-size: 14px;
  color: ${p => p.theme.color.mainFont};
`;

const ContainerFullScreen = styled.div`
  position: absolute;
  right: 30px;
  top: -5px;
`;

export const Styled = {
  Container,
  FilesContainer,
  Placeholder,
  ContainerFullScreen,
  WrapperContent,
  PagesContainer,
  FileTitle,
};
