import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-header-tab.scss';

import { State } from 'common/interfaces/state';
import { ClassificationActions } from '../../actions/creators/classification';


interface StateProps {
  isSelected: boolean;
}

interface DispatchProps {
  onClick: () => void;
}


interface OwnProps {
  name: string;
  id: number;
}

interface TabProps extends DispatchProps, StateProps, OwnProps { }


const ClassificationHeaderTabComponent: React.FC<TabProps> = (props) => {
  const className = classNames('classification-header-tab', {
    'classification-header-tab--selected': props.isSelected,
  });
  return (
    <div
      onClick={props.onClick}
      className={className}
      data-control-name='classification-header-tab'
    >
      {props.name}
    </div>
  );
};


function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isSelected: state.classification.modelBrowserFilters.tab === props.id,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps {
  return {
    onClick: () => {
      dispatch(ClassificationActions.setCurrentTab(props.id));
    },
  };
}


const connector = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps);
export const ClassificationHeaderTab = connector(ClassificationHeaderTabComponent);
