import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { DatabaseResourceVariantActionTypes } from '../actions/types/database-resource-variant';
import { DatabaseReduxState } from '../interfaces/redux-state';
import { ResourceVariantModel } from '../interfaces/resources-data';


export const DatabaseResourceVariantReducerMethods: ReducerMethods<DatabaseReduxState> = {
  [DatabaseResourceVariantActionTypes.GET_RESOURCE_VARIANT_SUCCEEDED]: (state, payload: ResourceVariantModel) => {
    return new MonoliteHelper(state)
      .set((_) => _.currentDatabase.editModel.variant, payload)
      .get();
  },
};
