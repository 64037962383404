import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconAddPlus: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icon_add' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M7.5,6.5 L12,6.5 C12.6666667,6.5 12.6666667,7.5 12,7.5 L7.5,7.5 L7.5,12 C7.5,12.6666667 6.5,12.6666667
          6.5,12 L6.5,7.5 L2,7.5 C1.33333333,7.5 1.33333333,6.5 2,6.5 L6.5,6.5 L6.5,2 C6.5,1.33333333 7.5,1.33333333
          7.5,2 L7.5,6.5 Z'
          id='Combined-Shape'
          fill={KreoColors.f1}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
