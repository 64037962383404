import { CompanyEmployeeRole } from 'common/enums/company-employee-role';
import { State } from 'common/interfaces/state';
import { KREO_EMAIL } from '../../constants/regex-templates';
import { SubscriptionActivityStatus } from '../subscription/enums/subscription-activity-status';
import { Company } from './interfaces/company';
import { CompanySubscriptionModel } from './interfaces/company-subscription-model';

function getCompanySubscription(s: State, company: Company): CompanySubscriptionModel {
  const selectedSubscriptionType = s.account.selectedSubscriptionType;
  return company && company.subscriptions && company.subscriptions[selectedSubscriptionType];
}

function getPaidEmployeesCount(s: State): number {
  const users = s.people.companiesUsers;
  return users && users.filter(x => x.isPayable).length || 0;
}

function getGuestUsersCount(s: State): number {
  const users = s.people.companiesUsers;
  return users && users.filter(x =>
    !x.isPayable && x.email
    && !KREO_EMAIL.test(x.email.toLowerCase()),
  ).length || 0;
}

function isSelectedCompanyLicensesLimitExceeded(s: State): boolean {
  const subscription = currentSubscription(s);
  if (!subscription) {
    return false;
  }
  if (subscription.userRole === CompanyEmployeeRole.Owner) {
    return false;
  }
  const paidEmployeesCount = getPaidEmployeesCount(s);

  return paidEmployeesCount > subscription.employeesMaxCount;
}

function currentSubscription(s: State): CompanySubscriptionModel {
  const selectedCompany = s.account.selectedCompany;
  return getCompanySubscription(s, selectedCompany);
}

function isFreeSubscription(subscription: CompanySubscriptionModel): boolean {
  return subscription && subscription.billingStatus === SubscriptionActivityStatus.NonRenewing;
}

function isPausedSubscription(subscription: CompanySubscriptionModel): boolean {
  return subscription && subscription.billingStatus === SubscriptionActivityStatus.Paused;
}

function isTrialSubscription(subscription: CompanySubscriptionModel): boolean {
  return subscription && subscription.billingStatus === SubscriptionActivityStatus.Trial;
}

export const AccountSelectors = {
  isSelectedCompanyLicensesLimitExceeded,
  currentSubscription,
  getPaidEmployeesCount,
  getGuestUsersCount,
  isFreeSubscription,
  isPausedSubscription,
  isTrialSubscription,
};
