import { H3, H5, RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { reloadWrapper } from 'common/utils/reload-wrapper';
import { SegmentContextProvider } from '../analytics/components/segment-context';
import { Intercom } from './intercom';
import { Styled } from './styled';


export const CrashPage: React.FC = () => {
  const reload = useCallback(() => {
    reloadWrapper('crash-page');
  }, []);

  return (
    <Styled.Container>
      <Styled.Img>
        <img src='/static/img/bug.png' />
      </Styled.Img>
      <H3>Oops.. An unexpected error occurred</H3>
      <H5>Our team is already running to fix it</H5>
      <Styled.ContentContainer>
        <RectangleButton
          mood={'secondary'}
          onClick={reload}
          text={'Go Back'}
          width={180}
          height={60}
        />
      </Styled.ContentContainer>
      <SegmentContextProvider>
        <Intercom />
      </SegmentContextProvider>
    </Styled.Container>
  );
};
