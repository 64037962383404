import * as Ag from 'ag-grid-community';
import { UpdateCellData } from 'common/components/excel-table';
import { getStartAndEndRowIndexes, getStylePropertyId } from './common';
import { StyleFields, StyleProperty } from '.';


const getUpdateCellData = (rowNode: Ag.RowNode, columnId: string, isResetProperty: boolean): UpdateCellData => {
  const data = rowNode.data;
  const styleColumnId = getStylePropertyId(columnId);
  const prevData = { ...data[styleColumnId] };

  if (isResetProperty || !data[styleColumnId]) {
    data[styleColumnId] = {};
  }

  return {
    rowId: data.id,
    columnId: styleColumnId,
    value: data[styleColumnId],
    prevValue: prevData,
  };
};

const updateCellStyleProperty = (cellData: UpdateCellData, property: Partial<StyleProperty>): void => {
  const styleProperty = cellData.value;
  Object.keys(property).forEach(key => {
    switch (key) {
      case StyleFields.BackgroundColor:
      case StyleFields.FontColor:
      case StyleFields.FontSize:
      case StyleFields.FontName:
      case StyleFields.HorizontalAlign: {
        if (property[key] === undefined) {
          delete styleProperty[key];
        } else {
          styleProperty[key] = property[key];
        }
        break;
      }
      case StyleFields.FontStyle: {
        if (property[key] < 0) {
          // eslint-disable-next-line no-bitwise
          styleProperty[key] = (styleProperty[key] || 0) & property[key];
        } else {
          // eslint-disable-next-line no-bitwise
          styleProperty[key] = (styleProperty[key] || 0) | property[key];
        }
        break;
      }
      default:
    }
  });
};

const setCellsStyleProperty = (
  gridApi: Ag.GridApi,
  ranges: Ag.CellRange[],
  updateCells: (cells: UpdateCellData[]) => void,
  property: Partial<StyleProperty>,
): void => {
  const isResetProperty: boolean = !property || !Object.keys(property).length;
  const rowNodesToRefresh = new Set<Ag.RowNode>();
  const columnIdsToRefresh = new Set<string>();
  const updatedCellsData: UpdateCellData[] = [];
  for (const range of ranges) {
    const [startIndex, endIndex] = getStartAndEndRowIndexes(range);
    for (let rowIndex = startIndex; rowIndex <= endIndex; rowIndex++) {
      const rowNode = gridApi.getModel().getRow(rowIndex);
      for (const column of range.columns) {
        const columnId = column.getColId();
        const updateCellData = getUpdateCellData(rowNode, columnId, isResetProperty);
        rowNodesToRefresh.add(rowNode);
        columnIdsToRefresh.add(columnId);
        updateCellStyleProperty(updateCellData, property);
        updatedCellsData.push(updateCellData);
      }
    }
  }

  updateCells(updatedCellsData);
  gridApi.refreshCells({
    columns: Array.from(columnIdsToRefresh),
    rowNodes: Array.from(rowNodesToRefresh),
    force: true,
  });
};

export const PropertySetter = {
  setCellsStyleProperty,
};
