import React, { useCallback } from 'react';

import { DrawingsColorControl } from 'common/components/drawings/drawings-controls';
import { useEditStyle } from 'common/components/drawings/hooks';
import { DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import { ColorList } from 'common/constants/color-list';
import { InstanceInformationStyled } from '../../../instance-information';
import { PropertyWithMenuEditor } from '../property-menu-editor';
import { Styled } from './styled';

interface Props {
  instances: Record<string, DrawingsGeometryInstance>;
  values: Record<string, string[]>;
  selectedInstancesIds: string[];
  canEdit: boolean;
}

const ColorPropertyComponent: React.FC<Props> = ({ values, instances, selectedInstancesIds, canEdit }) => {
  const editColor = useEditStyle('color', instances);

  const optionRenderer = useCallback((property: string) => {
    return (
      <Styled.ColorIndicatorWrapper>
        <InstanceInformationStyled.ColorIndicator color={property} />
      </Styled.ColorIndicatorWrapper>
    );
  }, []);

  const changeColor = useCallback(
    (value: string): void => {
      editColor(selectedInstancesIds, value);
    },
    [editColor, selectedInstancesIds],
  );

  const editorRenderer = useCallback(() => {
    const colors = Object.keys(values);
    return (
      <DrawingsColorControl
        colorList={ColorList}
        onColor={changeColor}
        title={'Color'}
        selectedColor={colors.length === 1 ? (colors[0] as string) : undefined}
      />
    );
  }, [values, changeColor]);

  return (
    <PropertyWithMenuEditor
      name='Color'
      elements={values}
      defaultValue='Mixed'
      dialogStyles={Styled.DialogStyles}
      optionRenderer={optionRenderer}
      editorRenderer={canEdit ? editorRenderer : undefined}
    />
  );
};

export const ColorProperty = React.memo(ColorPropertyComponent);
