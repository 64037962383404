import * as Ag from 'ag-grid-community';

import './quantity-take-off-report-table-name-cell-indicator.scss';

import { QtoReportRowUpdateType } from '../../enums/qto-report-row-update-type';


interface Params {
  diffStatus: QtoReportRowUpdateType;
}

export class QuantityTakeOffReportTableNameCellIndicator implements Ag.IComponent<Params>, Ag.ICellRenderer  {
  private params: Params;
  private eGui: HTMLDivElement;


  public init(params: Params): void {
    this.params = params;
    this.eGui = document.createElement('div');
    this.renderIndicator();
  }

  public getGui(): HTMLDivElement {
    return this.eGui;
  }

  public refresh(params: any): boolean {
    this.params = params;
    this.renderIndicator();
    return true;
  }

  private renderIndicator(): void {
    switch (this.params.diffStatus) {
      case QtoReportRowUpdateType.Added:
        this.eGui.className = `quantity-take-off-report-table-name-cell-indicator
          quantity-take-off-report-table-name-cell-indicator--added`;
        this.eGui.title = 'Row added';
        break;
      case QtoReportRowUpdateType.Deleted:
        this.eGui.className = `quantity-take-off-report-table-name-cell-indicator
          quantity-take-off-report-table-name-cell-indicator--deleted`;
        this.eGui.title = 'Row deleted';
        break;
      case QtoReportRowUpdateType.Updated:
        this.eGui.className = `quantity-take-off-report-table-name-cell-indicator
          quantity-take-off-report-table-name-cell-indicator--updated`;
        this.eGui.title = 'Row updated';
        break;
      default:
        this.eGui.className = `quantity-take-off-report-table-name-cell-indicator
          quantity-take-off-report-table-name-cell-indicator--undefined`;
        this.eGui.title = '';
    }
  }
}
