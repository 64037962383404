import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ClassificationUtils } from '../../utils/classification-utils';
import { ClassificationAssemblyInfoLayout } from '../classification-assembly-info-layout';
import { ClassificationAssignmentLayout } from '../classification-assignment-layout';
import { ClassificationSimpleObjectAssignmentList } from '../classification-simple-object-assignment-list';


interface Props {
  isMultilayer: boolean;
}

const ClassificationAssignmentBodyViewComponent: React.FC<Props> = (props) => {
  return (
    <ClassificationAssignmentLayout
      showFooter={false}
      showHeader={false}
    >
      {
        props.isMultilayer
          ? <ClassificationAssemblyInfoLayout isDisabled={true}/>
          : <ClassificationSimpleObjectAssignmentList isDisabled={true}/>
      }
    </ClassificationAssignmentLayout>
  );
};

function mapStateToProps(state: State): Props {
  return {
    isMultilayer: ClassificationUtils.classificationTargetIsMultilayer(state.classification.classificationTarget),
  };
}

const connector = connect(mapStateToProps);
export const ClassificationAssignmentBodyView = connector(ClassificationAssignmentBodyViewComponent);
