import { Input, SearchSelection } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { BillingCountryModel } from '../../../interfaces/billing-country-model';
import { Styled } from './styled';

interface Props {
  availableCountries: BillingCountryModel[];
  selectedCountryId?: string;
  vatNumber: string | null;
  vatNumberErrorText?: string;
  vatCountryLabelWidth?: string;
  onCountryChange: (countryId: string) => void;
  onVatNumberChange: (countryId: string) => void;
}

export class BillingAddress extends React.Component<Props> {
  public render(): JSX.Element {
    const { selectedCountryId, availableCountries, vatNumberErrorText, vatCountryLabelWidth } = this.props;
    const countryNames = availableCountries.map(x => x.name);
    const selectedCountryIndex = availableCountries.findIndex(x => x.id === selectedCountryId);
    const selectedCountry = availableCountries[selectedCountryIndex];
    const isVatNumberAvailable = selectedCountry && selectedCountry.allowVatNumber;

    const isVatNumberInvalid = !!vatNumberErrorText;

    return (
      <Styled.Container>
        <Styled.Column>
          <SearchSelection
            header={'Country'}
            elements={countryNames}
            activeElementIndexes={[selectedCountryIndex]}
            onClick={this.selectCountry}
            closeAfterClick={true}
            backgroundColor='background'
            maxItemsAmount={4}
          />
        </Styled.Column>
        {
          isVatNumberAvailable && (
            <Styled.Column>
              <Styled.VatCountryLabel isInvalid={isVatNumberInvalid}>
                {`${selectedCountry.id} |`}
              </Styled.VatCountryLabel>
              <Styled.InputContainer>
                <Input
                  width={vatCountryLabelWidth || '180px'}
                  type={'text'}
                  text={'VAT NUMBER'}
                  maxLength={20}
                  input={{
                    value: this.props.vatNumber,
                    onChange: this.vatNumberChanged,
                  }}
                  isInvalid={isVatNumberInvalid}
                />
                <Styled.ValidationError>{vatNumberErrorText}</Styled.ValidationError>
              </Styled.InputContainer>
            </Styled.Column>
          )
        }
      </Styled.Container>
    );
  }

  @autobind
  private selectCountry(index: number): void {
    const selectedCountry = this.props.availableCountries[index];
    this.props.onCountryChange(selectedCountry.id);
    if (!selectedCountry || !selectedCountry.allowVatNumber) {
      this.props.onVatNumberChange(null);
    }
  }

  @autobind
  private vatNumberChanged(event: any): void {
    this.props.onVatNumberChange(event.target.value);
  }
}
