import * as React from 'react';
import { TabNames } from '../enums';
import { CalibrateInput } from './calibrate-input';
import { CalibrateScaleInputWrapper } from './calibrate-scale-input-wrapper';

interface Props {
  currentTab: TabNames;
  disableDistance: boolean;
}

export class DrawingsCalibrateInput extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    switch (this.props.currentTab) {
      case TabNames.Scale:
        return <CalibrateScaleInputWrapper />;
      case TabNames.Calibrate:
        return <CalibrateInput/>;
      default:
        return null;
    }
  }
}
