import { Icons, Text } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';

import { usePersistedStorageValueToggle } from 'persisted-storage/hooks';
import { useRendererApiContext } from '../drawings-contexts';
import { DrawingsBooleanOperation } from '../drawings-geometry/drawings-helpers';
import { DrawingsInstanceType } from '../enums';
import { Apply, DialogContainerStyled, Header, KeepOld } from './components';
import { useApplyOperation, useBooleanKeyboardListener, useCancelBoolean } from './hooks';
import { Styled } from './styled';

interface Props {
  sendRef: (ref: HTMLDivElement) => void;
}

const typeToText = {
  [DrawingsInstanceType.Polyline]: 'Keep polylines',
  [DrawingsInstanceType.Count]: 'Keep points',
};

const typeToIcon = {
  [DrawingsInstanceType.Polyline]: Icons.JoinPath,
  [DrawingsInstanceType.Count]: Icons.CountJoin,
};

const DrawingsJoinCountComponent: React.FC<Props> = ({ sendRef }) => {
  const [shouldKeepOld, toggleKeepOld] = usePersistedStorageValueToggle('drawingIsKeepPolygon');

  const { rendererApi } = useRendererApiContext();
  const instanceType = rendererApi?.engine?.joinType;

  const icon = typeToIcon[instanceType] || Icons.CountJoin;
  const text = typeToText[instanceType] || 'Keep points';

  const cancel = useCancelBoolean();
  const applySettings = useMemo(() => ({ keepOld: shouldKeepOld }), [shouldKeepOld]);
  const apply = useApplyOperation(DrawingsBooleanOperation.Join, applySettings);

  useBooleanKeyboardListener(apply, cancel);

  return (
    <Styled.Container ref={sendRef}>
      <DialogContainerStyled.Container>
        <Header title="Join" Icon={icon} />
        <KeepOld toggleKeepOld={toggleKeepOld} isKeepOld={shouldKeepOld}>
          <Text fontSize={12} color={'gray'}>
            {text}
          </Text>
        </KeepOld>
        <Apply apply={apply} cancel={cancel} />
      </DialogContainerStyled.Container>
    </Styled.Container>
  );
};

export const DrawingsJoinCountDialog = React.memo(DrawingsJoinCountComponent);
