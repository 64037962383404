import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { TwoDActions } from '2d/actions/creators';
import { TablePreset } from '2d/interfaces';
import { KreoDialogActions } from 'common/UIKit';
import { UuidUtil } from 'common/utils/uuid-utils';
import { PRESET_TABLE_DIALOG_NAME, PresetTableDialog } from 'components/dialog/preset-table-dialog';


const PresetDialogComponent: React.FC = () => {
  const dispatch = useDispatch();
  const onSubmit = useCallback((preset: TablePreset) => {
    if (!preset.id) {
      const id = UuidUtil.generateUuid();
      dispatch(TwoDActions.createTablePreset({ ...preset, id }));
    } else {
      dispatch(TwoDActions.updateTablePreset(preset));
    }
  }, []);

  const onCloseDialog = useCallback(() => {
    dispatch(KreoDialogActions.closeDialog(PRESET_TABLE_DIALOG_NAME));
  }, []);

  return (
    <PresetTableDialog
      onFormSubmit={onSubmit}
      onCloseDialog={onCloseDialog}
    />
  );
};

export const PresetDialog = React.memo(PresetDialogComponent);
