import { RoleGroup } from 'common/enums/role-group';
import { State } from 'common/interfaces/state';
import { BidPricingInviteUsers } from '../../interfaces/bid-pricing/bid-pricing-invite-users';
import { BidPricingWorkPackage } from '../../interfaces/bid-pricing/bid-pricing-work-package';
import { ProjectPerson } from '../../interfaces/project-person';

interface InvitationData {
  invitation: BidPricingInviteUsers;
  emailsForRemove: string[];
}

function getUserEmails(workPackage: BidPricingWorkPackage): Set<string> {
  return new Set(workPackage && workPackage.usersCost.map(x => x.user.email));
}

export function createPeople(state: State, workPackage: BidPricingWorkPackage): ProjectPerson[] {
  const userEmails = getUserEmails(workPackage);

  const subcontractors: ProjectPerson[] = [];

  const selectedCompanyUsers = state.people.companiesUsers;

  if (selectedCompanyUsers) {
    selectedCompanyUsers.forEach(person => {
      if (person.roleGroup === RoleGroup.Subcontractor) {
        subcontractors.push({
          ...person,
          selectedDefault: userEmails.has(person.email),
          isYou: person.email === state.account.email,
        });
      }
    });
  }

  return subcontractors;
}

export const createInvitation = (
  workPackage: BidPricingWorkPackage,
  selectedEmails: string[],
  assignCompany: boolean,
): InvitationData => {
  if (!workPackage) {
    console.error('workpackage is undefined');
  }
  const userEmails = getUserEmails(workPackage);
  const invitation: BidPricingInviteUsers = {
    workPackageId: workPackage ? workPackage.id : null,
    emails: [],
    host: window.location.host,
    assignCompany,
  };
  const emailsForRemove = new Array<string>();

  selectedEmails.forEach(x => {
    if (userEmails.has(x)) {
      emailsForRemove.push(x);
    } else {
      invitation.emails.push(x);
    }
  });

  return { invitation, emailsForRemove };
};
