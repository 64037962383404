import {
  HotKeys,
  IconButton,
  RectangleButton,
  SearchSelection,
  SvgComponent,
} from '@kreo/kreo-ui-components';
import { CloseSmall, PlusBig, SelectAll, Share } from '@kreo/kreo-ui-components/icons';
import React, { useCallback, useEffect, useMemo } from 'react';

import { SubscriptionType } from 'common/constants/subscription';
import { CompanyEmployeeRole } from 'common/enums/company-employee-role';
import { Mood } from 'common/enums/mood';
import { RoleCode } from 'common/enums/role-code';
import { Company } from '../../../units/account/interfaces/company';
import { ENTER_ESCAPE } from '../custom-dialog/custom-dialog';
import { LinkComponent } from '../link-component';
import { RenderIf } from '../render-if';
import { ShareWithCompany } from '../share-with-company';
import { Text } from '../text';
import { Styled } from './styled';


const EmailDropdown: React.FC<any> = ({ elements, onEmailChange, formData, index }) => {
  const activeElementIndex = useMemo(() => elements.findIndex(x => x === formData), [elements, formData]);

  const handleClick = useCallback(
    emailIndex => onEmailChange(index, elements[emailIndex]),
    [onEmailChange, elements, index],
  );

  return (
    <RenderIf condition={!(activeElementIndex < 0)}>
      <Styled.DropdownContainer>
        <SearchSelection
          header={'Email'}
          elements={elements}
          activeElementIndexes={[activeElementIndex]}
          onClick={handleClick}
          closeAfterClick={true}
        />
      </Styled.DropdownContainer>
    </RenderIf>
  );
};

const DeleteButton: React.FC<{ index: number, onDelete: (index: number) => void }> = ({
  index,
  onDelete,
}) => {
  const handleDelete = useCallback(() => onDelete(index), [onDelete, index]);
  return (
    <IconButton
      Icon={CloseSmall}
      height={30}
      iconHeight={10}
      onClick={handleDelete}
    />
  );
};

interface Props {
  onFormSubmit: (emails: string[]) => void;
  onClose: () => void;
  onChangeShareWithCompany: () => void;
  title: string;
  buttonTitle: string;
  ButtonIcon?: SvgComponent;
  userEmails: string[];
  addedEmails: string[];
  isShareWithCompany: boolean;
  company: Company;
  currentUserEmail: string;
  userLimit?: number;
}

export const AddProjectUserPopup = React.memo<Props>(({
  onFormSubmit,
  title,
  buttonTitle,
  ButtonIcon,
  userEmails,
  addedEmails,
  onClose,
  isShareWithCompany,
  onChangeShareWithCompany,
  company,
  currentUserEmail,
}) => {
  const [formsData, setFormsData] = React.useState(null);
  const [showExtraButton, setShowExtraButton] = React.useState(null);

  const isAdmin = useMemo(
    () => company.subscriptions[SubscriptionType.Takeoff2d].userRoleCode === RoleCode.Administrator
      && company.subscriptions[SubscriptionType.Takeoff2d].userRole !== CompanyEmployeeRole.Owner,
    [company],
  );

  const newEmailDropdown = useMemo(
    () => userEmails.find(x => !(formsData && formsData.some(f => f === x))),
    [userEmails, formsData],
  );
  const defaultFormData = useMemo(
    () => addedEmails && addedEmails.length ? addedEmails : [newEmailDropdown],
    [addedEmails, newEmailDropdown],
  );
  const notUsedEmail = useMemo(
    () => userEmails.filter(x => !(formsData && formsData.some(f => f === x))),
    [userEmails, formsData],
  );
  const isCanAddMore = useMemo(() => !!notUsedEmail.length, [notUsedEmail]);
  const emailsElements = useMemo(() => {
    if (formsData) {
      return userEmails.filter(x => !formsData.some(f => f === x));
    }
    return [];
  }, [userEmails, formsData]);

  useEffect(() => {
    setFormsData(defaultFormData);
  }, []);

  useEffect(() => {
    if (!isShareWithCompany) {
      setFormsData(addedEmails);
    }
  }, [addedEmails, isShareWithCompany]);

  useEffect(() => {
    if (!formsData?.filter(el => el !== currentUserEmail).length) {
      setShowExtraButton(true);
    } else {
      setShowExtraButton(false);
    }
  }, [currentUserEmail, formsData]);

  useEffect(() => {
    if (isShareWithCompany && !addedEmails.length) {
      setFormsData([]);
    } else if (isShareWithCompany) {
      const foundEmails = userEmails.filter(email => addedEmails.includes(email));
      setFormsData(foundEmails);
    }
  }, [addedEmails, isShareWithCompany, userEmails]);

  const moreClick = useCallback(() => {
    setFormsData([...formsData, newEmailDropdown]);
  }, [formsData, newEmailDropdown]);

  const selectAllClick = useCallback(() => {
    setFormsData([...formsData, ...notUsedEmail]);
  }, [formsData, notUsedEmail]);

  const submitHandle = useCallback(() => {
    const guests =  addedEmails.filter(email => !userEmails.includes(email));

    return onFormSubmit([...formsData, ...guests]);
  }, [formsData, onFormSubmit, addedEmails, userEmails]);

  const onEmailChange = useCallback(
    (index, value) => {
      const newFormsData = [...formsData];
      newFormsData[index] = value;
      setFormsData(newFormsData);
    }, [formsData]);

  const deleteForm = useCallback(
    index =>
      setFormsData(formsData.filter((_, i) => i !== index)),
    [setFormsData, formsData]);

  return (
    <HotKeys keyMap={ENTER_ESCAPE} handlers={{ escape: onClose, enter: submitHandle }} autofocus={true}>
      <Styled.Container>
        <Text>
          <Styled.IconShare>
            <Share />
            {title}
          </Styled.IconShare>
          <LinkComponent
            text={'Select All'}
            icon={SelectAll}
            onClick={selectAllClick}
            mood={isCanAddMore ? Mood.Secondary : Mood.Disabled}
          />
        </Text>
        <ShareWithCompany
          checked={isShareWithCompany}
          onChange={onChangeShareWithCompany}
          company={company}
        />
        <Styled.FormWrapper>
          <RenderIf condition={showExtraButton}>
            <LinkComponent
              text={isShareWithCompany ? 'Add guest' : 'Add more'}
              icon={PlusBig}
              onClick={moreClick}
              mood={isCanAddMore ? Mood.Secondary : Mood.Disabled}
            />
          </RenderIf>
          <RenderIf condition={!!formsData}>
            {formsData && formsData.map((formData, index) => {
              if (formData === currentUserEmail && !isAdmin) {
                return null;
              }

              const currentElement = userEmails.find(u => u === formData);
              const allEmail = currentElement ? [...emailsElements, currentElement] : emailsElements;

              return (
                <Styled.Form key={index}>
                  <EmailDropdown
                    onEmailChange={onEmailChange}
                    elements={allEmail.sort()}
                    formData={formData}
                    index={index}
                  />
                  <DeleteButton index={index} onDelete={deleteForm} />
                </Styled.Form>
              );
            })}
          </RenderIf>
        </Styled.FormWrapper>
        <Styled.BtnWrapper>
          <LinkComponent
            text={isShareWithCompany ? 'Add guest' : 'Add more'}
            icon={PlusBig}
            onClick={moreClick}
            mood={isCanAddMore ? Mood.Secondary : Mood.Disabled}
          />
          <RectangleButton
            Icon={ButtonIcon}
            mood={Mood.Secondary}
            text={buttonTitle}
            width={165}
            height={60}
            onClick={submitHandle}
          />
        </Styled.BtnWrapper>
      </Styled.Container>
    </HotKeys>
  );
});

