import styled from 'styled-components';

import { Text } from 'common/components/text';

const BadgeContainer = styled.div`
  width: auto;
  height: 20px;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 0 5px;
  background: ${p => p.theme.color.turquoise};
  display: flex;
  align-items: center;
  justify-content: center;

  ${Text} {
    max-height: 100%;
    margin-right: 1px;
  }

  > svg {
    width: 10px;
    height: 10px;
    fill: ${p => p.theme.color.white};
  }
`;


export const Styled = {
  BadgeContainer,
};
