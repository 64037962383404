import { HoverMenu, Icons, MenuItem } from '@kreo/kreo-ui-components';
import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { DrawingsAnnotationLegendActions } from '../../actions/creators/drawings-annotation-legend';
import { Styled } from './styled';
import { TempalatesMenuWrapper } from './templates-menu-wrapper';

interface Props {
  onCloseMenu: () => void;
}

const TemplatesMenuItemComponents: React.FC<Props> = ({
  onCloseMenu,
}) => {
  const isTemplateEnabled = useAbility(Operation.Manage, Subject.Takeoff2dGroupTemplates);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isTemplateEnabled) {
      dispatch(DrawingsAnnotationLegendActions.loadGroupTemplates());
    }
  }, [isTemplateEnabled]);

  const renderTemplatesMenu = useCallback(() => {
    return (
      <TempalatesMenuWrapper
        onCloseMenu={onCloseMenu}
      />
    );
  }, [onCloseMenu ]);

  return (
    <UpgradeWrapper isNeedUpdate={!isTemplateEnabled}>
      <HoverMenu
        position={'right'}
        MenuComponent={renderTemplatesMenu}
        disabled={!isTemplateEnabled}
      >
        <Styled.MenuItemWrapper withBorder={true}>
          <MenuItem
            fontSize={14}
            text='Templates'
            Icon={Icons.Templates}
            subText={<Styled.ArrowIcon />}
            onClick={ConstantFunctions.stopEvent}
            disabled={!isTemplateEnabled}
          />
        </Styled.MenuItemWrapper>
      </HoverMenu>
    </UpgradeWrapper>
  );
};


export const TemplatesMenuItem = React.memo(TemplatesMenuItemComponents);
