import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  overflow-y: auto;
  padding: 10px;
  background-color: ${p => p.theme.color.backgroundRush};
  z-index: 3;
  border-radius: 10px;
  box-sizing: border-box;
`;

export const Styled = {
  Container,
};
