/* eslint-disable max-len */
export const ICON_MENU = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15px' height='15px' fill='%23${color.slice(1)}' viewBox='0 0 15 15'%3E%3Cg id='Group_11624' data-name='Group 11624'%3E%3Cpath d='M1.5,2.25h12a.75.75,0,0,0,0-1.5H1.5a.75.75,0,0,0,0,1.5Z'/%3E%3Cpath d='M13.5,6.75H1.5a.75.75,0,0,0,0,1.5h12a.75.75,0,0,0,0-1.5Z'/%3E%3Cpath d='M13.5,12.75H1.5a.75.75,0,0,0,0,1.5h12a.75.75,0,0,0,0-1.5Z'/%3E%3C/g%3E%3C/svg%3E")`;
export const ICON_FILTER = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15'  fill='%23${color.slice(1)}' viewBox='0 0 15 15'%3E%3Cg id='Group_11624' data-name='Group 11624'%3E%3Cpath d='M13,.25H2A1.76,1.76,0,0,0,.25,2V4.55A1.73,1.73,0,0,0,.84,5.86l3.83,3.4a.27.27,0,0,1,.08.19V13A1.76,1.76,0,0,0,6.5,14.75h2A1.76,1.76,0,0,0,10.25,13V9.45a.27.27,0,0,1,.08-.19l3.83-3.4a1.73,1.73,0,0,0,.59-1.31V2A1.76,1.76,0,0,0,13,.25Zm.25,4.3a.27.27,0,0,1-.08.19L9.34,8.14a1.73,1.73,0,0,0-.59,1.31V13a.25.25,0,0,1-.25.25h-2A.25.25,0,0,1,6.25,13V9.45a1.73,1.73,0,0,0-.59-1.31L1.83,4.74a.27.27,0,0,1-.08-.19V2A.25.25,0,0,1,2,1.75H13a.25.25,0,0,1,.25.25Z'/%3E%3C/g%3E%3C/svg%3E")`;
export const ICON_ASC = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15'  fill='%23${color.slice(1)}' viewBox='0 0 15 15'%3E%3Cg id='Group_11624' data-name='Group 11624'%3E%3Cpath d='M10,1.75h2.44l-3,3.78a.76.76,0,0,0-.09.79.76.76,0,0,0,.68.43h4a.75.75,0,0,0,0-1.5H11.56l3-3.78a.76.76,0,0,0,.09-.79A.76.76,0,0,0,14,.25H10A.76.76,0,0,0,9.25,1,.76.76,0,0,0,10,1.75Z'/%3E%3Cpath d='M14,8.29H10A.76.76,0,0,0,9.25,9v5a.75.75,0,0,0,1.5,0V12.79h2.5V14a.75.75,0,0,0,1.5,0V9A.76.76,0,0,0,14,8.29Zm-3.25,3V9.79h2.5v1.5Z'/%3E%3Cpath d='M4.53.47A.78.78,0,0,0,4.29.31a.73.73,0,0,0-.58,0,.78.78,0,0,0-.24.16l-3,3a.75.75,0,0,0,0,1.06.75.75,0,0,0,1.06,0L3.25,2.81V14a.75.75,0,0,0,1.5,0V2.81L6.47,4.53a.75.75,0,0,0,1.06,0,.75.75,0,0,0,0-1.06Z'/%3E%3C/g%3E%3C/svg%3E")`;
export const ICON_DESC = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15'  fill='%23${color.slice(1)}' viewBox='0 0 15 15'%3E%3Cg id='Group_11624' data-name='Group 11624'%3E%3Cpath d='M14,13.27H11.56l3-3.78a.76.76,0,0,0,.09-.79A.76.76,0,0,0,14,8.27H10a.75.75,0,0,0,0,1.5h2.44l-3,3.78a.77.77,0,0,0-.09.8.75.75,0,0,0,.68.42h4a.75.75,0,0,0,0-1.5Z'/%3E%3Cpath d='M14,.23H10A.75.75,0,0,0,9.25,1V6a.75.75,0,0,0,1.5,0V4.73h2.5V6a.75.75,0,0,0,1.5,0V1A.75.75,0,0,0,14,.23Zm-3.25,3V1.73h2.5v1.5Z'/%3E%3Cpath d='M6.47,10.45,4.75,12.17V1a.75.75,0,0,0-1.5,0V12.17L1.53,10.45A.75.75,0,0,0,.47,11.51l3,3a.78.78,0,0,0,.24.16.73.73,0,0,0,.58,0,.78.78,0,0,0,.24-.16l3-3a.75.75,0,0,0-1.06-1.06Z'/%3E%3C/g%3E%3C/svg%3E")`;
export const ICON_CHECKED = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' fill='%23${color.slice(1)}'%3E%3Cpath d='M9.55 1.86a1.13 1.13 0 00-1.6 0L4.06 5.75l-2-2a1.13 1.13 0 00-1.6 0 1.14 1.14 0 000 1.59l2.81 2.8a1.11 1.11 0 00.79.33 1.13 1.13 0 00.8-.33l4.69-4.69a1.14 1.14 0 000-1.59z'%3E%3C/path%3E%3C/svg%3E")`;
export const ICON_DRAG = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'  fill='%23${color.slice(1)}' viewBox='0 0 20 20'%3E%3Cg id='Group_11624' data-name='Group 11624'%3E%3Ccircle cx='6' cy='2' r='1.5'/%3E%3Ccircle cx='6' cy='10' r='1.5'/%3E%3Ccircle cx='6' cy='18' r='1.5'/%3E%3Ccircle cx='14' cy='2' r='1.5'/%3E%3Ccircle cx='14' cy='10' r='1.5'/%3E%3Ccircle cx='14' cy='18' r='1.5'/%3E%3C/g%3E%3C/svg%3E")`;
export const ICON_DELETE = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='%23${color.slice(1)}' viewBox='0 0 20 20'%3E%3Cg id='Group_11624' data-name='Group 11624'%3E%3Cpath d='M18 4.25h-4.25V2A1.76 1.76 0 0 0 12 .25H8A1.76 1.76 0 0 0 6.25 2v2.25H2a.75.75 0 0 0 0 1.5h1.25v11.4a2.61 2.61 0 0 0 2.6 2.6h8.3a2.61 2.61 0 0 0 2.6-2.6V5.75H18a.75.75 0 0 0 0-1.5ZM7.75 2A.25.25 0 0 1 8 1.75h4a.25.25 0 0 1 .25.25v2.25h-4.5Zm7.5 15.15a1.1 1.1 0 0 1-1.1 1.1h-8.3a1.1 1.1 0 0 1-1.1-1.1V5.75h10.5Z'/%3E%3Cpath d='M8 7.25a.76.76 0 0 0-.75.75v7a.75.75 0 0 0 1.5 0V8A.76.76 0 0 0 8 7.25ZM12 7.25a.76.76 0 0 0-.75.75v7a.75.75 0 0 0 1.5 0V8a.76.76 0 0 0-.75-.75Z'/%3E%3C/g%3E%3C/svg%3E")`;
export const ICON_EDIT = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'  fill='%23${color.slice(1)}' viewBox='0 0 20 20'%3E%3Cpath d='M18.47,10.76l-4.61,4.61L9.77,11.29l9.76-9.76A.75.75,0,0,0,18.47.47L8.71,10.23,4.63,6.14,9.24,1.53a.74.74,0,0,0,0-1.06.75.75,0,0,0-1.06,0L3,5.61a1.07,1.07,0,0,0-.15.22s0,0,0,.08a.32.32,0,0,0,0,.09L.26,18.85a.77.77,0,0,0,.21.68.75.75,0,0,0,.53.22h.15L14,17.16a.32.32,0,0,0,.09,0l.08,0a1.07,1.07,0,0,0,.22-.15l5.14-5.14a.75.75,0,0,0,0-1.06A.74.74,0,0,0,18.47,10.76ZM7.81,16.87,3.13,12.19,4,7.66,12.34,16ZM2.77,14,6,17.23,2,18Z'/%3E%3C/svg%3E")`;
export const ICON_ADD_FOLDER = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'  fill='%23${color.slice(1)}' viewBox='0 0 20 20'%3E%3Cpath d='M19,5.25H9.75V3A1.76,1.76,0,0,0,8,1.25H2A1.76,1.76,0,0,0,.25,3V16A2.75,2.75,0,0,0,3,18.75H17A2.75,2.75,0,0,0,19.75,16V6A.76.76,0,0,0,19,5.25ZM1.75,3A.25.25,0,0,1,2,2.75H8A.25.25,0,0,1,8.25,3V5.25H1.75Zm16.5,13A1.25,1.25,0,0,1,17,17.25H3A1.25,1.25,0,0,1,1.75,16V6.75h16.5Z'/%3E%3Cpath d='M8,11.25a.75.75,0,0,0,0,1.5H9.25V14a.75.75,0,0,0,1.5,0V12.75H12a.75.75,0,0,0,0-1.5H10.75V10a.75.75,0,0,0-1.5,0v1.25Z'/%3E%3C/svg%3E")`;
export const ICON_ADD_PLUS = (color): string => `url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='20' height='20'  fill='%23${color.slice(1)}' viewBox='0 0 20 20'%3E%3Cpath d='M19,9.25H10.75V1a.75.75,0,0,0-1.5,0V9.25H1a.75.75,0,0,0,0,1.5H9.25V19a.75.75,0,0,0,1.5,0V10.75H19a.75.75,0,0,0,0-1.5Z'/%3E%3C/svg%3E")`;
export const ICON_ROW_RIGHT_SMALL = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'  fill='%23${color.slice(1)}' viewBox='0 0 10 10'%3E%3Cpath d='M7.53,4.47l-4-4A.75.75,0,0,0,2.47,1.53L5.94,5,2.47,8.47a.75.75,0,0,0,0,1.06.75.75,0,0,0,1.06,0l4-4A.75.75,0,0,0,7.53,4.47Z'/%3E%3C/svg%3E")`;
export const ICON_ROW_BOTTOM_SMALL = (color): string => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'  fill='%23${color.slice(1)}' viewBox='0 0 10 10'%3E%3Cpath d='M9.53,2.47a.75.75,0,0,0-1.06,0L5,5.94,1.53,2.47A.75.75,0,0,0,.47,3.53l4,4a.75.75,0,0,0,1.06,0l4-4A.75.75,0,0,0,9.53,2.47Z'/%3E%3C/svg%3E")`;
