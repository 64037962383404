import { AppRoute as R } from 'common/routing/app-route';
import { ParametrizedAppRoute as PR } from 'common/routing/parametrized-app-route';
import * as Activity from '../common/activity/urls-fabric';
import * as Database from '../common/database/urls-fabric';
import * as People from '../common/people/urls-fabric';
import * as Subscription from '../common/subscription-routes-wrap/urls-fabric';
import {
  CostEstimateProjectRouteParams as ProjectRP,
  CostEstimateProjectRevisionRouteParams as RevisionRP,
  CostEstimateDatabaseAssignActivityRouteParams as DbActivityRP,
} from './route-params';


/* eslint-disable max-len, space-in-parens */
const index =                          new R(                     '/cost-estimate');
const listing =                        new R(                     `${index.path}/projects`);

const databasesUrls = Database.UrlsFabric(index.path);

const projectIndex =                   new PR<ProjectRP>(         `${index.path}/project/:projectId`);

const informationModelingIndex =       new PR<ProjectRP>(         `${projectIndex.path}/information-modeling`);
const informationModelingEdit =        new PR<ProjectRP>(         `${informationModelingIndex.path}/edit`);
const informationModelingView =        new PR<ProjectRP>(         `${informationModelingIndex.path}/view`);

const informationModeling = {
  index: informationModelingIndex,
  edit: informationModelingEdit,
  view: informationModelingView,
};

const revisionIndex =                  new PR<RevisionRP>(         `${projectIndex.path}/revision/:revisionId`);

const activityAssignmentIndex =        new PR<RevisionRP>(         `${revisionIndex.path}/activity-assignment`);
const activityAssignmentEdit =         new PR<RevisionRP>(         `${activityAssignmentIndex.path}/edit`);
const activityAssignmentDbIndex      = new PR<DbActivityRP>(       `${activityAssignmentEdit.path}/:elementId`);
const activityAssignmentDbActivities = new PR<DbActivityRP>(       `${activityAssignmentDbIndex.path}${databasesUrls.activities.path}`);
const activityAssignmentDbResources =  new PR<DbActivityRP>(       `${activityAssignmentDbIndex.path}${databasesUrls.resources.path}`);
const activityAssignmentView =         new PR<RevisionRP>(         `${activityAssignmentIndex.path}/view`);

const costEstimate =                   new PR<RevisionRP>(        `${revisionIndex.path}/cost-estimate`);

const activityAssignment = {
  index: activityAssignmentIndex,
  edit: activityAssignmentEdit,
  view: activityAssignmentView,
  database: {
    index: activityAssignmentDbIndex,
    activities: activityAssignmentDbActivities,
    resources: activityAssignmentDbResources,
  },
};

const measurementsIndex =        new PR<RevisionRP>(         `${revisionIndex.path}/measurements`);
const measurementsEdit =         new PR<RevisionRP>(         `${measurementsIndex.path}/edit`);
const measurementsView =         new PR<RevisionRP>(         `${measurementsIndex.path}/view`);

const measurements = {
  index: measurementsIndex,
  edit: measurementsEdit,
  view: measurementsView,
};

const viewer =                         new PR<ProjectRP>(         `${projectIndex.path}/collaboration`);
const quantityTakeOff =                new PR<ProjectRP>(         `${projectIndex.path}/quantity-take-off`);
const publishedReports =               new PR<ProjectRP>(         `${projectIndex.path}/published-reports`);
const modelCheck =                     new PR<ProjectRP>(         `${projectIndex.path}/model-check`);
const modelCheckView =                 new PR<ProjectRP>(         `${modelCheck.path}/view`);
const modelManagement =                new PR<ProjectRP>(         `${projectIndex.path}/model-management`);
// tslint:enable

export const Urls = {
  people: People.UrlsFabric(index.path),
  activity: Activity.UrlsFabric(index.path),
  billing: Subscription.UrlsFabric(index.path),
  database: databasesUrls,
  index,
  listing,
  project: {
    index: projectIndex,
    viewer,
    informationModeling,
    revision: {
      index: revisionIndex,
      activityAssignment,
      measurements,
      costEstimate,
    },
    quantityTakeOff,
    publishedReports,
    modelManagement,
    modelCheck: {
      index: modelCheck,
      view: modelCheckView,
    },
  },
};
