import { ElementTooltip, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';

interface Props {
  count: number;
  showTooltip?: boolean;
  tooltipPosition?: string;
}

const CommentCountMarkComponent: React.FC<Props> = ({ count, showTooltip, tooltipPosition }) => {
  if (!count) {
    return null;
  }
  return (
    <ElementTooltip
      text={`You have ${count} open conversations. Check them`}
      position={tooltipPosition || 'top'}
      speed='l'
      disabled={!showTooltip}
    >
      <Styled.Container showTooltip={showTooltip}>
        <Text
          fontSize={10}
          lineHeight={16}
          color={'white'}
        >
          {count}
        </Text>
      </Styled.Container>
    </ElementTooltip>
  );
};

export const CommentCountMark = React.memo(CommentCountMarkComponent);
