import autobind from 'autobind-decorator';
import * as React from 'react';

import { MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import {
  ActivityAssignmentSubVariantMaterial,
} from '../../interfaces/activity-assignment/activity-assignmnet-subvariant-material';


interface Props {
  index: number;
  selectedMaterialId: number;
  activitySubVariantId: number;
  subVariants: ActivityAssignmentSubVariantMaterial[];
  selectMaterial: (index: number, activitySubVariantId: number, selectedMaterialId: number) => void;
}


export class ActivityAssignmentSelectMaterialVariant extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { subVariants, selectedMaterialId } = this.props;
    return (
      <MaterialSelect
        displayedType={MaterialComponentType.Native}
        value={selectedMaterialId}
        onChange={this.onChangeMaterial}
        dropdownClassName='activity-assignment-activity-tile__dropdown'
      >
        {
          subVariants.map(x => <MaterialMenuItem key={x.materialId} value={x.materialId}>{x.name}</MaterialMenuItem>)
        }
      </MaterialSelect>
    );
  }

  @autobind
  private onChangeMaterial(_e: React.SyntheticEvent<Element>, value: number): void {
    const { index, activitySubVariantId } = this.props;
    this.props.selectMaterial(index, activitySubVariantId, value);
  }
}
