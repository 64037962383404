import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { usePersistedStorageValue } from 'persisted-storage/hooks';
import { GroupPayloadForInstanceCreate } from '../../drawings-contexts';
import { DrawingsGeometryGroup } from '../../interfaces';

export function useGroupsForInstanceCreate(): GroupPayloadForInstanceCreate {
  const groupForInstanceCreation = useSelector<State, DrawingsGeometryGroup>(
    state => state.drawings.drawingGeometryGroups.find(group => group.id === state.drawings.selectGeometryGroup[0]),
  );
  const useGroupNameForNewGeometry = usePersistedStorageValue('drawingUseGroupNameForNewGeometry');
  return useMemo(
    () => ({ useGroupNameForNewGeometry, groupForInstanceCreation }),
    [useGroupNameForNewGeometry, groupForInstanceCreation]);
}
