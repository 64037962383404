import { createSlice } from '@reduxjs/toolkit';
import { ActionWith } from 'common/interfaces/action-with';
import { SubscriptionState, TempInvitePeople } from './interface';

export const INITIAL_STATE: SubscriptionState = {
  tempInvitePeople: null,
};

const Subscription = createSlice({
  name: '@subscription',
  initialState: INITIAL_STATE,
  reducers: {
    setTempInvitePeople: (s, { payload }: ActionWith<TempInvitePeople[]>) => {
      s.tempInvitePeople = payload;
    },
  },
});

export const SubscriptionActions = Subscription.actions;
export const subscriptionReducers = Subscription.reducer;
