import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';

import { AgGridDataTransferStorage } from './ag-grid-data-transfer-storage';

export type MapperType<T> = (row: Ag.RowNode, api: Ag.GridApi) => T;

export class AgGridDataTransferExporter<T> {
  private mapper: MapperType<T>;
  private transferKey: string;

  constructor(transferKey: string, mapper: MapperType<T>) {
    this.transferKey = transferKey;
    this.mapper = mapper;
  }

  public setExportData(row: Ag.RowNode, api: Ag.GridApi, event: DragEvent): void {
    const data = this.mapper(row, api);
    AgGridDataTransferStorage.set(this.transferKey, data);
    event.dataTransfer.dropEffect = 'move';
    event.target.addEventListener('dragend', this.clear);
  }

  @autobind
  public clear(): void {
    AgGridDataTransferStorage.block(this.transferKey);
  }
}
