import * as clipperLib from 'js-angusj-clipper';

import { arrayUtils } from 'common/utils/array-utils';
import { objectUtils } from 'common/utils/object-utils';
import { PreviewsInfo, SetPreviewsPayload } from '../../actions/payloads/magic-search';
import { ShortPointDescription } from '../../interfaces';
import { MagicSearchContour } from '../../interfaces/api-responses/magic-search-responses';
import { ClipperLoaderWrapper } from './clipper';

export function contour_area(contour: ShortPointDescription[]): number {
  let area = 0;
  const n = contour.length;
  for (let p = n - 1, q = 0; q < n; p = q++) {
    area += contour[p][0] * contour[q][1] - contour[q][0] * contour[p][1];
  }
  return area / 2;
}

export function contoursArea(contours: ShortPointDescription[][]): number {
  return contours.reduce((acc, c) => acc + contour_area(c), 0);
}

async function contoursUnion(contours: ShortPointDescription[][]): Promise<ShortPointDescription[][]> {
  if (contours.length === 0) {
    return [];
  }
  contours = contours.filter((c) => c.length > 3);
  const subjectInputs = contours.map((c) => ({
    data: [c.map((p) => ({ x: Math.round(p[0]), y: Math.round(p[1]) }))],
    closed: true,
  }));
  try {
    const clipper = await ClipperLoaderWrapper.getInstanceAsync();
    const polyResult = clipper.clipToPaths({
      clipType: clipperLib.ClipType.Union,
      subjectInputs,
      subjectFillType: clipperLib.PolyFillType.NonZero,
    });

    return polyResult.map((poly: any) => poly.map((p: any) => [p.x, p.y]));
  } catch (err) {
    console.error(err, subjectInputs);
    return [];
  }
}


function fildConnectedComponentsInsideGraph(g: Map<number, number[]>): number[][] {
  const visited: Set<number> = new Set();
  const components: number[][] = [];
  for (const node of g.keys()) {
    if (visited.has(node)) continue;
    const component: number[] = [];
    const stack: number[] = [node];
    while (stack.length > 0) {
      const current = stack.pop();
      if (visited.has(current)) continue;
      visited.add(current);
      component.push(current);
      for (const neighbor of g.get(current)) {
        if (!visited.has(neighbor)) {
          stack.push(neighbor);
        }
      }
    }
    components.push(component);
  }
  return components;
}

async function isIntersected(a: clipperLib.SubjectInput, b: clipperLib.SubjectInput): Promise<boolean> {
  const clipper = await ClipperLoaderWrapper.getInstanceAsync();
  const polyResult = clipper.clipToPaths({
    clipType: clipperLib.ClipType.Intersection,
    subjectFillType: clipperLib.PolyFillType.Positive,
    subjectInputs: [a],
    clipInputs: [b],
  });
  return polyResult.length > 0;
}

async function isIntersectedWithSource(
  contour: ShortPointDescription[],
  contoursToAvoid: ShortPointDescription[][],
): Promise<boolean> {
  if (contoursToAvoid.length === 0) {
    return false;
  }
  const clipper = await ClipperLoaderWrapper.getInstanceAsync();
  const path = contour.map(([x, y]) => ({ x: Math.round(x), y: Math.round(y) }));
  for (const contourToAvoid of contoursToAvoid) {
    const pathToAvoid = contourToAvoid.map(([x, y]) => ({ x: Math.round(x), y: Math.round(y) }));

    if (await isIntersected({ data: [path], closed: true }, { data: [pathToAvoid], closed: true })) {
      return true;
    }

    if (path.some((p) => clipper.pointInPolygon(p, pathToAvoid) !== clipperLib.PointInPolygonResult.Outside)) {
      return true;
    }
    if (pathToAvoid.some((p) => clipper.pointInPolygon(p, path) !== clipperLib.PointInPolygonResult.Outside)) {
      return true;
    }
  }
  return false;
}

function contourInCropBox(contour: ShortPointDescription[], cropBox: [number, number, number, number]): boolean {
  if (!cropBox) {
    return true;
  }
  for (const point of contour) {
    if (point[0] < cropBox[0] || point[0] > cropBox[2] || point[1] < cropBox[1] || point[1] > cropBox[3]) {
      return false;
    }
  }
  return true;
}

async function filterContoursBySource<T>(
  suggestedContours: T[],
  sourceContours: ShortPointDescription[][],
  cropBox: [number, number, number, number],
  pointsGetter: (point: T) => ShortPointDescription[],
): Promise<T[]> {
  if (!sourceContours?.length && !cropBox?.length) {
    return suggestedContours;
  }
  const result: T[] = [];
  for (const contour of suggestedContours) {
    const points = pointsGetter(contour);
    if (!contourInCropBox(points, cropBox)) {
      continue;
    }
    if (await isIntersectedWithSource(points, sourceContours)) {
      continue;
    }
    result.push(contour);
  }
  return result;
}

async function findConnectedComponents(
  suggestedContours: MagicSearchContour[],
): Promise<number[][]> {
  const g: Map<number, number[]> = new Map();

  function addEdge(a: number, b: number): void {
    if (!g.has(a)) {
      g.set(a, []);
    }
    if (!g.has(b)) {
      g.set(b, []);
    }
    g.get(a).push(b);
    g.get(b).push(a);
  }

  const contoursIds: Set<number> = new Set();
  for (const c of suggestedContours) {
    contoursIds.add(c.id);
  }

  for (const contour of suggestedContours) {
    if (contour.points.length < 3) {
      continue;
    }
    if (contour_area(contour.points) < 0) {
      contour.points = contour.points.reverse();
    }
    g.set(contour.id, []);
    for (const edge of contour.edges) {
      if (edge.dstId === contour.id || !contoursIds.has(edge.dstId)) {
        continue;
      }
      if (edge.iou > 0.05) {
        addEdge(contour.id, edge.dstId);
      }
    }
  }
  const connectedComponents = fildConnectedComponentsInsideGraph(g);
  return connectedComponents;
}

async function mergeContours(
  suggestedContours: MagicSearchContour[],
  connectedComponents: number[][],
  startId: number = 0,
): Promise<PreviewsInfo> {
  const result: ShortPointDescription[][] = [];
  const idToContour = new Map<number, ShortPointDescription[]>();
  const resultSources = {};
  let id = startId || 0;
  for (const c of suggestedContours) {
    idToContour.set(c.id, c.points);
  }
  for (const component of connectedComponents) {
    let contours = arrayUtils.filterMap(
      component,
      (i) => idToContour.has(i),
      (i) => idToContour.get(i),
    );
    if (contours.length === 0) {
      continue;
    }
    if (contours.length > 1) {
      contours = await contoursUnion(contours);
    }

    if (contours.length > 1) {
      let componentChild = component.filter(cId => idToContour.has(cId)).slice();
      for (const contour of contours) {
        const notUsedComponents = [];
        const usedComponents = [];
        while (componentChild.length) {
          const contourId = componentChild.pop();
          if (!idToContour.has(contourId)) {
            continue;
          }
          const componentContour = idToContour.get(contourId);
          if (await isIntersectedWithSource(contour, [componentContour])) {
            usedComponents.push(contourId);
          } else {
            notUsedComponents.push(contourId);
          }
        }
        resultSources[id] = usedComponents;
        if (notUsedComponents.length) {
          componentChild = notUsedComponents;
        }
        id++;
      }
    } else {
      resultSources[id] =component.filter(cId => idToContour.has(cId));
      id++;
    }
    arrayUtils.extendArray(result, contours);

  }

  return { contours: result.map(x => ({ points: x, holes: [] })), resultToSources: resultSources };
}


async function getMergedContours(
  contours: MagicSearchContour[],
  contoursToDelete: number[],
  contoursToIngore: number[],
  similarity: number,
): Promise<SetPreviewsPayload> {
  const contoursToDeleteSet = new Set(contoursToDelete);
  const contoursToIngoreSet = new Set(contoursToIngore);
  const groupedContours = contours.reduce((prev, current) => {
    if (contoursToIngoreSet.has(current.id)) {
      return prev;
    }
    if (current.conf >= similarity) {
      if (contoursToDeleteSet.has(current.id)) {
        prev.negative.push(current);
      } else {
        prev.positive.push(current);
      }
    }
    return prev;
  }, { positive: [], negative: [] });
  const positiveCompontents = await findConnectedComponents(contours);
  const mergeResult = await mergeContours(groupedContours.positive, positiveCompontents);
  const negativeComponents = await findConnectedComponents(groupedContours.negative);
  const negativeResult = await mergeContours(
    groupedContours.negative,
    negativeComponents,
    mergeResult.contours.length);
  arrayUtils.extendArray(mergeResult.contours, negativeResult.contours);
  objectUtils.extend(mergeResult.resultToSources, negativeResult.resultToSources);
  return {
    ...mergeResult,
    previewsToDelete: Object.keys(negativeResult.resultToSources).map(r => Number(r)),
  };
}

export const MagicSearchUtils = {
  findConnectedComponents,
  mergeContours,
  filterContoursBySource,
  contoursUnion,
  getMergedContours,
};
