import * as React from 'react';

export const KreoIconVideo: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
    >
      <rect
        x='2'
        y='3.5'
        width='10'
        height='7'
        rx='2'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeMiterlimit='10'
      />
      <path
        d='M8.18,6.35,7,5.65a.75.75,0,0,0-1.12.65V7.7A.75.75,0,0,0,7,8.35l1.21-.7A.75.75,0,0,0,8.18,6.35Z'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeMiterlimit='10'
      />
    </svg>);
};
