import * as React from 'react';

import { PropertiesDataProviderApi } from '../../interfaces/properties-provider-types';
import { PropertiesDataContext } from '../properties-data-context-provider';

export function withPropertiesDataProviderApi<P>(Component: React.ComponentType<P & PropertiesDataProviderApi>):
React.ComponentType<Pick<P, Exclude<keyof P, keyof PropertiesDataProviderApi>>> {
  return class WithPropertiesDataProviderApi extends React.PureComponent<P> {
    public render(): React.ReactNode {
      return (
        <PropertiesDataContext.Consumer>
          {
            contextProps => {
              return (<Component
                {...this.props}
                {...contextProps}
              />);
            }
          }
        </PropertiesDataContext.Consumer>
      );
    }
  };
}
