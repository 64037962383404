import { useEffect } from 'react';
import { AssemblyForm } from '../../assembly-panel/item-form-updater';
import { MeasureForm } from '../interfaces';

export const useSetFormEffects = (
  assemblyForm: MeasureForm,
  itemForm: AssemblyForm,
  afterAssemblyFormSet: (assemblyForm: MeasureForm) => void,
  afterItemFormSet: (itemForm: AssemblyForm) => void,
): void => {
  useEffect(() => {
    setTimeout(() => {
      afterAssemblyFormSet(assemblyForm);
      afterItemFormSet(itemForm);
    });
  }, [assemblyForm, itemForm]);
};
