import * as React from 'react';

import { SpliterPaneConfig, SplitterLayout } from '../../../../../components/splitter-layout';
import { ViewProps } from './interfaces';

export class FourViewsStakedSplit extends React.Component<ViewProps> {
  private horizontalSize: SpliterPaneConfig[] = [];
  private verticalSize1: SpliterPaneConfig[] = [];
  private verticalSize2: SpliterPaneConfig[] = [];

  constructor(props: ViewProps) {
    super(props);
    this.horizontalSize = [
      { size: props.height * 0.50, minSize: 50 },
      { size: props.height * 0.50, minSize: 50 },
    ];
    this.verticalSize1 = [
      { size: props.width * 0.30, minSize: 50 },
      { size: props.width * 0.70, minSize: 50 },
    ];
    this.verticalSize2 = [
      { size: props.width * 0.70, minSize: 50 },
      { size: props.width * 0.30, minSize: 50 },
    ];
  }

  public render(): JSX.Element {
    return (
      <SplitterLayout
        primaryIndex={0}
        horizontal={true}
        paneConfigs={this.horizontalSize}
      >
        <SplitterLayout
          primaryIndex={0}
          horizontal={false}
          paneConfigs={this.verticalSize1}
        >
          {this.props.children[0]}
          {this.props.children[1]}
        </SplitterLayout>
        <SplitterLayout
          primaryIndex={0}
          horizontal={false}
          paneConfigs={this.verticalSize2}
        >
          {this.props.children[2]}
          {this.props.children[3]}
        </SplitterLayout>
      </SplitterLayout>);
  }
}

