import { ExcelFormulaHelper, UpdateCellData } from 'common/components/excel-table';
import { TwoDRegex, TwoDRegexGetter, TwoDRegexTypings } from '../2d-regex';
import { MoveDataPayload } from './interfaces';
import { removeRefAfterDeleteRows } from './remove-ref-after-move';

const getMapedCellLink = (movedCellId: string, isColumnAbsolute: boolean, isRowAbsolute: boolean): string => {
  if (!isColumnAbsolute && !isRowAbsolute) {
    return movedCellId;
  }

  const groups = TwoDRegexGetter.getFullCellField(movedCellId);

  const column = isColumnAbsolute
    ? `$${groups.columnId}`
    : groups.columnId;

  const row = isRowAbsolute
    ? `$${groups.rowId}`
    : groups.rowId;

  return `${column}${row}`;
};

export const replaceCellRef = (
  updatedCell: UpdateCellData,
  sheetId: string,
  moveCellRefMap: Record<string, string>,
  payload: MoveDataPayload,
): void => {
  const value = updatedCell.value.replace(TwoDRegex.formulaReplaceValueGlobal, (...args) => {
    const groups = TwoDRegexTypings.typingFormulaReplaceValueGlobal(args);

    if (groups.cellRange) {
      if (groups.cellRange.includes(sheetId)) {
        const startId = groups.startId.split('!')[1];
        const endId = groups.endId;
        const [updatedStartId, updatedEndId] = removeRefAfterDeleteRows(
          updatedCell,
          payload,
          startId,
          endId,
        );

        if (updatedStartId === null || updatedEndId === null) {
          return ExcelFormulaHelper.INVALID_REF;
        }

        return `${sheetId}!${moveCellRefMap[startId] || updatedStartId}:${moveCellRefMap[endId] || updatedEndId}`;
      }
      return groups.cellRange;
    }
    if (groups.fullCellId.includes(sheetId)) {
      const cleanCellId = ExcelFormulaHelper.getCellLink(undefined, groups.columnId, groups.rowId);
      const movedCellId = moveCellRefMap[cleanCellId];

      const resultCellId = movedCellId
        ? getMapedCellLink(movedCellId, !!groups.isColumnAbsolute, !!groups.isRowAbsolute)
        : groups.cellId;

      return `${sheetId}!${resultCellId}`;
    }
    return groups.fullCellId;
  });
  updatedCell.value = value;
};
