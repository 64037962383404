import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuProjectDropDownWrapper = styled.div`
  position: absolute;
  top: 20px;
  z-index: 1001;
  width: 240px;
  box-shadow: ${p => p.theme.shadow.xl};
  padding: 10px 0;
  border-radius: 15px;
  border: 1px solid ${p => p.theme.color.background};
  background-color: ${p => p.theme.color.backgroundRush};
  overflow: hidden;
  animation: ${Constants.Animations.slowTopAppear()} ${p => p.theme.duration.s} ${p => p.theme.function.qo};
  transform-origin: top right;
`;

export const Styled = {
  Container,
  MenuProjectDropDownWrapper,
};
