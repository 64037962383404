import { Icons, RectangleButton, Text, Waves, SvgComponent } from '@kreo/kreo-ui-components';
import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import * as React from 'react';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { DemoProjectButton } from '../demo-project-button';
import { Styled } from './styled';

interface MenuItem {
  text: string;
  Icon: SvgComponent;
  onClick: () => void;
  type: string;
  createBtnText?: string;
  showNew?: () => boolean;
  isHide?: () => boolean;
  showUpgrade?: () => boolean;
}

interface Props {
  menuItems: MenuItem[];
  Logo: SvgComponent;
  SmallLogo: SvgComponent;
  activeItem: string;
  isCollapsed: boolean;
  onClickLogo: () => void;
  collapseToggle: () => void;
  onCreateButtonClick?: () => void;
  buttonId?: string;
  createButtonDisabled?: boolean;
  isCreateButtonHide?: boolean;
  createButtonText?: string;
  withEllipsis?: boolean;
}

export const SidePanelNavigation: React.FC<Props> = ({
  buttonId,
  menuItems,
  Logo,
  SmallLogo,
  activeItem,
  isCollapsed,
  onClickLogo,
  collapseToggle,
  onCreateButtonClick,
  createButtonDisabled,
  isCreateButtonHide,
  createButtonText,
  withEllipsis,
}) => {
  const activeItemIndex = menuItems.findIndex(item => item.text === activeItem);

  return (
    <Styled.Container isCollapsed={isCollapsed}>
      <Styled.Logo onClick={onClickLogo}>
        {isCollapsed ? <SmallLogo /> : <Logo />}
      </Styled.Logo>
      <Styled.ButtonContainer id={buttonId}>
        {!isCreateButtonHide && (
          <RectangleButton
            mood={createButtonDisabled ? 'disabled' : 'secondary'}
            onClick={onCreateButtonClick}
            height={40}
            fontSize={14}
            borderRadius={30}
            width={isCollapsed ? 40 : 240}
            Icon={Icons.PlusBig}
            text={!isCollapsed && createButtonText}
          />
        )}
      </Styled.ButtonContainer>
      {menuItems.map(({ Icon, text, onClick, isHide, showUpgrade }, index) => {
        const isActive = activeItemIndex === index;
        const isHideItem = isHide && isHide();
        if (isHideItem) {
          return null;
        }
        const isNeedUpdate = showUpgrade && showUpgrade();
        const handleClick = isNeedUpdate
          ? ConstantFunctions.doNothing
          : onClick;

        return (
          <UpgradeWrapper
            key={text}
            id={text}
            isNeedUpdate={isNeedUpdate}
          >
            <Styled.MenuItem
              isActive={isActive}
              onClick={handleClick}
              isCollapsed={isCollapsed}
              isNeedUpdate={isNeedUpdate}
            >
              <Icon />
              <Text
                withEllipsis={withEllipsis}
                fontSize={14}
              >
                {text}
              </Text>
            </Styled.MenuItem>
          </UpgradeWrapper>
        );
      })}
      <DemoProjectButton isCollapsed={isCollapsed} />
      <Styled.CollapseButton
        isCollapsed={isCollapsed}
        onClick={collapseToggle}
      >
        <Icons.LeftSmall />
        <Waves />
      </Styled.CollapseButton>
    </Styled.Container>
  );
};
