export enum HeaderContext {
  Project = 'Project',
  ProjectExtension = 'ProjectExtension',
  People = 'People',
  Database = 'Database',
  DatabaseActivityAssignment = 'DatabaseActivityAssignment',
  EditScenario = 'EditScenario',
  BillingPages = 'BillingPages',
  ProjectRevision= 'ProjectRevision',
}
