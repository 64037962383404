const OBJECT_COMPLEX_KEY = 'f_obj_complex';
const ELEMENT_TABLE_BIM_ELEMENT_ID_KEY = 'id';
const ELEMENT_TABLE_NAME_KEY = 'elName';
const TREE_TABLE_NAME_KEY = 'name';
const TREE_TABLE_BIM_ELEMENT_ID_KEY = 'bimElementId';
const USER_EXTRACTORS_KEY = 'user_extractors';
const BREAKDOWN_GROUPS_KEY = 'breakdown_groups';
const BREAKDOWN_GROUPS_EXTRACTORS_KEY = 'brg_base_extractors';
const BREAKDOWN_GROUPS_SHARED_PARAMETERS = 'brg_shared_params';
const EXTRACTORS_KEY = 'extractors';
const THICKNESS_KEY = 'ef_thickness';
const REASON_EF_PREFIX = 'reason_ef_';
const FILTER_PREFIX = 'f_';
const ADDITIONAL_PROPERTIES_PREFIX = `${FILTER_PREFIX}ap_`;
const EXTRACTOR_FIELD_PREFIX = 'ef_';
const F_LOC_LOC_TYPES = 'f_loc_loc_types';
const REASON_PREFIX = 'reason_';
const QTO_COLUMNS_ELEMENT = 'qto_columns_element';
const QTO_TABLE_ELEMENT = 'qto_table_element';
const QTO_COLUMNS_LOCATION = 'qto_columns_location';
const QTO_TABLE_LOCATION = 'qto_table_location';
const EXTRA_INFO = 'extra_info';
const EXTRA_INFO_IMPERIAL = 'extra_info_imperial';


export const QtoLeftPanelConstants = {
  OBJECT_COMPLEX_KEY,
  ELEMENT_TABLE_BIM_ELEMENT_ID_KEY,
  ELEMENT_TABLE_NAME_KEY,
  TREE_TABLE_NAME_KEY,
  TREE_TABLE_BIM_ELEMENT_ID_KEY,
  USER_EXTRACTORS_KEY,
  EXTRACTORS_KEY,
  THICKNESS_KEY,
  REASON_EF_PREFIX,
  ADDITIONAL_PROPERTIES_PREFIX,
  EXTRACTOR_FIELD_PREFIX,
  FILTER_PREFIX,
  F_LOC_LOC_TYPES,
  REASON_PREFIX,
  QTO_COLUMNS_ELEMENT,
  QTO_TABLE_ELEMENT,
  QTO_COLUMNS_LOCATION,
  QTO_TABLE_LOCATION,
  EXTRA_INFO,
  EXTRA_INFO_IMPERIAL,
  BREAKDOWN_GROUPS_KEY,
  BREAKDOWN_GROUPS_EXTRACTORS_KEY,
  BREAKDOWN_GROUPS_SHARED_PARAMETERS,
};
