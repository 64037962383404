import * as React from 'react';
import { connect } from 'react-redux';

import './classification-ontology-tree-left-side.scss';

import { State } from 'common/interfaces/state';
import { RevitTreeLevel } from '../../enums/revit-tree-level';
import { ValidationStepMode } from '../../enums/validation-step-mode';
import { RevitTreePathInfo } from '../../interfaces/revit-tree-path-info';
import { ClassificationUtils } from '../../utils/classification-utils';
import { ClassificationLeftSideLayout } from '../classification-left-side-layout';
import { ClassificationRevitTreeBody } from '../classification-revit-tree-body';

interface BodyStateProps {
  lastBreadCrumb: RevitTreePathInfo;
}

interface OwnProps {
  mode: ValidationStepMode;
  children?: React.ReactNode;
}

interface Props extends BodyStateProps, OwnProps {
}


class ClassificationOntologyTreeLeftSideComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { lastBreadCrumb, children } = this.props;
    const isEdit =
      this.props.lastBreadCrumb &&
      (lastBreadCrumb.type === RevitTreeLevel.ElementType || lastBreadCrumb.multiple);
    return (
      <ClassificationLeftSideLayout>
        {isEdit ? children : <ClassificationRevitTreeBody mode={this.props.mode} />}
      </ClassificationLeftSideLayout>
    );
  }
}

function mapStateToProps(state: State): BodyStateProps {
  return {
    lastBreadCrumb: ClassificationUtils.getLastBreadCrumb(state.classification.modelBrowserFilters),
  };
}

const connector = connect(mapStateToProps);
export const ClassificationOntologyTreeLeftSide = connector(ClassificationOntologyTreeLeftSideComponent);

