import { Action, AnyAction } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { ModelCheckAvailableCheck } from '../../interfaces/model-check/model-check-available-checks';
import * as ActionsTypes from './action-types';
import { ScrollToType } from './errors-content';
import { ScrollToPayload } from './interfaces/payloads';
import * as Types from './interfaces/types';

export function getData(): Action {
  return {
    type: ActionsTypes.MODEL_CHECK_GET_DATA,
  };
}

function getDataFail(): AnyAction {
  return {
    type: ActionsTypes.MODEL_CHECK_GET_DATA_FAIL,
  };
}

function getDataSuccessed(data: Types.PostProcessed): ActionWith<Types.PostProcessed> {
  return {
    type: ActionsTypes.MODEL_CHECK_GET_DATA_SUCCESSED,
    payload: data,
  };
}


function onIssueSelect(order: number): ActionWith<number> {
  return {
    type: ActionsTypes.MODEL_CHECK_ORDER_SELECT,
    payload: order,
  };
}


function onSelectHandle(handle: number): ActionWith<number> {
  return {
    type: ActionsTypes.MODEL_CHECK_SELECT_HANDLE,
    payload: handle,
  };
}


function onDeselect(): Action {
  return {
    type: ActionsTypes.MODEL_CHECK_DESELECT,
  };
}


function showHideProps(): Action {
  return {
    type: ActionsTypes.SHOW_HIDE_PROPS,
  };
}

function secondHandleSelect(id: number): ActionWith<number> {
  return {
    type: ActionsTypes.SELECT_SECOND_HANDLE,
    payload: id,
  };
}


function selectHandleById(id: number, api: ScrollToType): ActionWith<ScrollToPayload> {
  return {
    type: ActionsTypes.SELECT_BY_ID,
    payload: { id, api },
  };
}


function deselectHandle(): Action {
  return {
    type: ActionsTypes.DESELECT_HANDLE,
  };
}

function runModelCheck(types: string[]): ActionWith<string[]> {
  return {
    type: ActionsTypes.MODEL_CHECK_RUN,
    payload: types,
  };
}

function getAvailableChecks(): Action {
  return {
    type: ActionsTypes.MODEL_CHECK_GET_AVAILABLE_CHECKS,
  };
}

function getAvailableChecksSuccess(
  availableChecks: ModelCheckAvailableCheck[],
): ActionWith<ModelCheckAvailableCheck[]> {
  return {
    type: ActionsTypes.MODEL_CHECK_GET_AVAILABLE_CHECKS_SUCCESS,
    payload: availableChecks,
  };
}


function toggleCheckSelected(
  checkType: string,
): ActionWith<string> {
  return {
    type: ActionsTypes.MODEL_CHECK_TOGGLE_CHECK_SELECTED,
    payload: checkType,
  };
}

function dropState(): Action {
  return {
    type: ActionsTypes.MODEL_CHECK_DROP_STATE,
  };
}

export const ModelCheckActions = {
  runModelCheck,
  deselectHandle,
  selectHandleById,
  secondHandleSelect,
  showHideProps,
  onDeselect,
  onSelectHandle,
  getDataSuccessed,
  onIssueSelect,
  getDataFail,
  getData,
  getAvailableChecks,
  getAvailableChecksSuccess,
  toggleCheckSelected,
  dropState,
};
