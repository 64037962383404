import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { Notification, NotificationProcessor } from 'common/interfaces/realtime-messages';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { AppUrls } from '../../../../routes/app-urls';
import { CommonNotificationProcessor } from '../../../notifications/common-notification-processor';
import { ProjectsActions } from '../../actions/creators/common';
import { Project } from '../../interfaces/project';
import { ProjectNotificationKeys } from '../keys/common';


function currentUrlIsValid(projectId: string): boolean {
  return !window.location.pathname.startsWith(AppUrls.plan.project.index.url({ projectId })) &&
    !window.location.pathname.startsWith(AppUrls.plan.listing.url()) &&
    !window.location.pathname.startsWith(AppUrls.qto2d.listing.url());
}

function* created(notification: Notification): SagaIterator {
  try {
    const { projectId, status } = notification.data;
    yield put(ProjectsActions.updateProjectStatusSucceeded(projectId, status));
    if (currentUrlIsValid(notification.data.projectId.toString())) {
      yield call(CommonNotificationProcessor.addInfoAlert, notification);
    }
  } catch (error) {
    console.error(error);
  }
}

function* bidPricingRemoveSubContractor(notification: Notification): SagaIterator {
  try {
    const currentProject: Project = yield selectWrapper(state => state.projects.currentProject);
    if (currentProject && currentProject.id === notification.data.projectId) {
      yield put(push(AppUrls.plan.listing.url()));
    }
  } catch (error) {
    console.error(error);
  }
}

export const ProjectsNotificationsProcessor: NotificationProcessor = {
  [ProjectNotificationKeys.Created]: created,
  [ProjectNotificationKeys.Failed]: CommonNotificationProcessor.addErrorAlert,
  [ProjectNotificationKeys.ReportsGenerated]: CommonNotificationProcessor.addInfoAlert,
  [ProjectNotificationKeys.ReportsFailed]: CommonNotificationProcessor.addErrorAlert,
  [ProjectNotificationKeys.BidPricingCanceled]: CommonNotificationProcessor.addNotification,
  [ProjectNotificationKeys.BidPricingStarted]: CommonNotificationProcessor.addNotification,
  [ProjectNotificationKeys.BidPricingInviteSubcontractor]: CommonNotificationProcessor.addNotification,
  [ProjectNotificationKeys.BidPricingInvitationAccepted]: CommonNotificationProcessor.addNotification,
  [ProjectNotificationKeys.BidPricingMakeBid]: CommonNotificationProcessor.addNotification,
  [ProjectNotificationKeys.BidPricingInviteMainContractor]: CommonNotificationProcessor.addNotification,
  [ProjectNotificationKeys.BidPricingRemovedSubcontractor]: bidPricingRemoveSubContractor,
};
