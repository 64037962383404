
import { connect } from 'react-redux';
import { ColorControl, ColorControlOwnProps } from 'common/components/color-control';
import { State } from 'common/interfaces/state';

interface StateProps {
  usedColors?: string[];
}

type OwnProps = Pick<ColorControlOwnProps, Exclude<keyof ColorControlOwnProps, keyof StateProps>>;

function mapStateToProps(state: State): StateProps {
  return {
    usedColors: state.drawings.usedColors,
  };
}

export const DrawingsColorControl = connect<StateProps, {}, OwnProps>(mapStateToProps)(ColorControl);
