import { useMemo } from 'react';
import { DrawingsBounds } from '../../interfaces';
import { PdfGeometry } from '../../interfaces/api-responses/pdf-geometry-response';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';

export function useItemBounds(item: PdfGeometry): DrawingsBounds {
  return useMemo(() => {
    const bounds = DrawingsGeometryUtils.getPointsBounds(item.points);
    return bounds;
  }, [item]);
}
