import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './bid-description-dialog.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialog, KreoDialogActions, MaterialInput } from 'common/UIKit';
import { KreoDialogActionsPanel } from 'common/UIKit/dialogs/kreo-dialog-actions-panel';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';

interface ReduxProps {
  open: boolean;
}

interface ReduxActions {
  dialogClose: () => void;
}

interface Props extends ReduxProps, ReduxActions {
  description: string;
  isSubcontractor: boolean;
  updateBidDescription?: (description: string) => void;
}

interface State {
  description: string;
}

export const bidDescriptionDialogName = 'bid-description-dialog';
const descriptionLength = 1000;

class BidDescriptionDialogComponent extends React.Component<Props, State> {
  public state: State = {
    description: this.props.description || '',
  };

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.open && !prevProps.open) {
      this.setState({ description: this.props.description || '' });
    }
  }

  public render(): React.ReactNode {
    const { isSubcontractor, updateBidDescription } = this.props;
    return (
      <KreoDialog
        name={bidDescriptionDialogName}
        isModal={true}
        title='Bid Description'
        bodyClassName='bid-description-dialog'
      >
        {isSubcontractor ? (
          <React.Fragment>
            <div className='bid-description-dialog__input-wrap'>
              <div className='bid-description-dialog__input-counter'>
                {descriptionLength - this.state.description.length}
              </div>
              <MaterialInput
                className='bid-description-dialog__input'
                multiLine={true}
                rows={6}
                placeholder='Enter Description'
                displayedType={MaterialComponentType.Native}
                value={this.state.description}
                onChange={this.onChangeDescription}
                disabled={!isSubcontractor}
              />
            </div>
            <KreoDialogActionsPanel
              onCancel={this.props.dialogClose}
              onSubmit={this.onConfirm}
              submitDisabled={
                !this.state.description.length || !isSubcontractor || !updateBidDescription
              }
              submitBtnName='Save'
            />
          </React.Fragment>
        ) : (
          <div className='bid-description-dialog__text'>{this.state.description}</div>
        )}
      </KreoDialog>
    );
  }

  @autobind
  private onConfirm(): void {
    if (this.props.updateBidDescription) {
      this.props.updateBidDescription(this.state.description);
      this.props.dialogClose();
    }
  }

  @autobind
  private onChangeDescription(
    _event: React.ChangeEvent<HTMLTextAreaElement>,
    newValue?: string,
  ): void {
    if (newValue.length <= descriptionLength) {
      this.setState({ description: newValue });
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    open: bidDescriptionDialogName in state.dialog,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    dialogClose: () => {
      dispatch(KreoDialogActions.closeDialog(bidDescriptionDialogName));
    },
  };
};

export const BidDescriptionDialog = connect(mapStateToProps, mapDispatchToProps)(
  BidDescriptionDialogComponent,
);
