import { ElementTooltip, Icons, RoundButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { DrawingsActions } from 'common/components/drawings';
import { ControlNames } from 'common/constants/control-names';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { UndoRedoContextApiProps } from 'common/undo-redo';
import { withUndoRedoApiProps } from 'common/undo-redo/with-undo-redo-api-props';
import { arrayUtils } from 'common/utils/array-utils';
import { PersistedStorageSelectors } from 'persisted-storage/selectors/projectTwoDFullScreenSelector';
import { CompanyProjectHeaderUser } from 'unit-projects/interfaces/company-project-header';
import { Project } from 'unit-projects/interfaces/project';
import { PersistedStorageActions } from '../../../units/persisted-storage/actions/creators';
import { TwoDFullScreenMode } from '../../../units/persisted-storage/interfaces/state';
import { DrawingDialogs } from '../drawings/constants/drawing-dialogs';
import { DrawingsDeletePage, DrawingsDeletePageConfirmationDialog } from '../drawings/dialogs';
import { DrawingsPdfBrowserUndoWrapper } from '../drawings/drawings-pdf-browser/drawings-pdf-browser-undo-wrapper';
import { DrawingsDrawMode } from '../drawings/enums';
import { DrawModeContextProps, withDrawingModeApi } from '../drawings/hooks';
import { DrawingsShortInfo } from '../drawings/interfaces/drawings-short-drawing-info';
import { DrawingsUndoRedoHelper } from '../drawings/utils/drawings-undo-redo-helper';
import { TabsContainerWithScroll } from '../tabs-container-with-scroll';
import { InviteUsersProjectDialog } from './invite-users-project-dialog';
import { Styled } from './styled';
import { TwoDHeaderPageTab } from './two-d-header-page-tab';

interface StateProps {
  selectedPages: string[];
  drawingsInfo: Record<string, DrawingsShortInfo>;
  activeId: string;
  isPdfManagerDialogOpened: boolean;
  isFullScreenTable: boolean;
  currentProject: Project;
  projectId: string;
  currentDrawingId: string;
  projectUsers: CompanyProjectHeaderUser[];
}

interface DispatchProps {
  onDeletePage: (drawingId: string, pdfId: string) => void;
  onSelectDrawing: (id: string) => void;
  saveSelectedDrawingsState: (id: string) => void;
  openPdfManagerDialog: () => void;
  set2dFullScreenMode?: (mode: { type: TwoDFullScreenMode, projectId: string }) => void;
}

interface ComponentState {
  selectedPages: string[];
}

interface Props extends StateProps, DispatchProps, UndoRedoContextApiProps, AbilityAwareProps, DrawModeContextProps {}

class TwoDHeaderComponent extends React.Component<Props, ComponentState> {
  private readonly canChangeColor: boolean = this.props.ability.can(Operation.Manage, Subject.Takeoff2dPageColor);

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedPages: [],
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    const { selectedPages } = this.props;
    if (prevProps.selectedPages !== selectedPages) {
      this.setState({ selectedPages });
    }
  }

  public render(): JSX.Element {
    const { isPdfManagerDialogOpened, ability, drawingsInfo, activeId, currentProject, projectUsers } = this.props;
    const canEditMeasurement = ability.can(Operation.Update, Subject.Takeoff2DMeasurement);
    const canViewMeasurement: boolean = ability.can(Operation.Read, Subject.Takeoff2DMeasurement);
    const tabList =
      this.state.selectedPages && canViewMeasurement
        ? arrayUtils.filterMap(this.state.selectedPages, (tab) => tab in drawingsInfo, (tab) => drawingsInfo[tab])
        : [];

    return (
      <Styled.Container>
        <Styled.TabsWrapper>
          <TabsContainerWithScroll disableAutoScroll={true} isSynchronizationScrolls={true} step={500}>
            {tabList.map((item, index) => {
              const isActive = item.drawingId === activeId;
              return (
                <TwoDHeaderPageTab
                  key={item.drawingId}
                  index={index}
                  id={item.drawingId}
                  pdfId={item.pdfId}
                  color={item.color}
                  name={item.name}
                  screenshotCreated={item.screenshotCreated}
                  pageNumber={item.pageNumber}
                  isActive={isActive}
                  onClickTab={this.onSelectDrawingWithUndoRedo}
                  canEditMeasurement={canEditMeasurement}
                  changePosition={this.sortSelectedItems}
                  canChangeColor={this.canChangeColor}
                />
              );
            })}
          </TabsContainerWithScroll>
        </Styled.TabsWrapper>
        <Styled.PdfBrowserContainer>
          <Styled.PdfBrowserInner isPdfManagerDialogOpened={isPdfManagerDialogOpened}>
            <ElementTooltip text="Add new page" position="bottom">
              <RoundButton
                onClick={this.props.openPdfManagerDialog}
                Icon={Icons.PlusBig}
                diameter={40}
                id={canViewMeasurement ? 'open-pdf-browser-button' : ''}
                mood={canViewMeasurement ? (isPdfManagerDialogOpened ? 'secondary' : 'default') : 'disabled'}
                controlName={canViewMeasurement ? ControlNames.openFileManager : null}
              />
            </ElementTooltip>
            <DialogWrapper name={DrawingDialogs.PDF_MANAGER_DIALOG}>
              <DrawingsPdfBrowserUndoWrapper />
            </DialogWrapper>
          </Styled.PdfBrowserInner>
        </Styled.PdfBrowserContainer>
        <DrawingsDeletePageConfirmationDialog onSubmit={this.deletePage} />
        {currentProject ? (
          <InviteUsersProjectDialog
            projectId={currentProject.id}
            projectUsers={projectUsers}
            projectType={currentProject.type}
          />
        ) : null}
      </Styled.Container>
    );
  }

  @autobind
  private sortSelectedItems(dragIndex: number, hoverIndex: number): void {
    const { selectedPages } = this.state;
    const draggablePageTab = selectedPages[dragIndex];
    const selectedPagesCopy = selectedPages.slice();
    selectedPagesCopy.splice(dragIndex, 1);
    selectedPagesCopy.splice(hoverIndex, 0, draggablePageTab);
    this.setState({ selectedPages: selectedPagesCopy });
  }

  @autobind
  private deletePage(data: DrawingsDeletePage): void {
    const applyDelete = (): void => {
      this.props.cleanUndoRedo();
      this.props.onDeletePage(data.pageId, data.pdfId);
      const selectedPages = this.props.selectedPages.filter((id) => id !== data.pageId);
      this.setState({ selectedPages });
    };
    if (this.props.currentDrawingId === data.pageId) {
      this.props.setDrawMode(DrawingsDrawMode.Disabled, { ignoreCancelMessage: true, afterSave: applyDelete });
    } else {
      applyDelete();
    }
  }

  @autobind
  private onSelectDrawing(drawingId: string): void {
    this.props.onSelectDrawing(drawingId);
    this.props.saveSelectedDrawingsState(drawingId);
  }

  @autobind
  private onSelectDrawingWithUndoRedo(drawingId: string): void {
    if (this.props.isFullScreenTable) {
      const projectId = this.props.projectId;
      this.props.set2dFullScreenMode({ type: null, projectId });
    }
    const prevDrawingId = this.props.activeId;
    if (prevDrawingId === drawingId) {
      return;
    }
    const { undo, redo } = DrawingsUndoRedoHelper.getSelectDrawingUndoRedo(
      prevDrawingId,
      drawingId,
      this.onSelectDrawing,
    );
    this.props.addUndoRedo(undo, redo);
    redo();
  }
}

function mapStateToProps(state: State): StateProps {
  const projectId = state.projects.currentProject.id;
  const selectedCompany = state.account.selectedCompany;
  const currentProject = state.projects.currentProject;
  const projectType = currentProject?.type;
  const companyId = selectedCompany ? selectedCompany.id : null;
  const headersStore = state.projects.projectHeadersByCompanyId[companyId];
  const projectHeader = currentProject && headersStore && headersStore[projectType]?.data[currentProject.id];

  return {
    selectedPages: state.drawings.selectedPages,
    drawingsInfo: state.drawings.drawingsInfo,
    activeId: state.drawings.currentDrawingInfo && state.drawings.currentDrawingInfo.drawingId,
    isPdfManagerDialogOpened: DrawingDialogs.PDF_MANAGER_DIALOG in state.dialog,
    isFullScreenTable:
      PersistedStorageSelectors.selectTwoDFullScreenMode(state.persistedStorage, projectId.toString()) ===
      TwoDFullScreenMode.Report,
    currentProject: state.projects.currentProject,
    projectId: state.projects.currentProject.id.toString(),
    currentDrawingId: state.drawings.currentDrawingInfo?.drawingId,
    projectUsers: projectHeader ? projectHeader.users : [],
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onDeletePage: (drawingId, pdfId) => dispatch(DrawingsActions.deletePage(drawingId, pdfId)),
    onSelectDrawing: (drawingId) => dispatch(DrawingsActions.selectDrawing(drawingId)),
    saveSelectedDrawingsState: (drawingId) => dispatch(DrawingsActions.saveSelectedDrawingsState(drawingId)),
    openPdfManagerDialog: () => dispatch(KreoDialogActions.openDialog(DrawingDialogs.PDF_MANAGER_DIALOG)),
    set2dFullScreenMode: (mode) => dispatch(PersistedStorageActions.set2dFullScreenMode(mode)),
  };
}

export const TwoDHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAbilityContext(withUndoRedoApiProps(withDrawingModeApi(TwoDHeaderComponent))));
