import * as React from 'react';
import { connect } from 'react-redux';

import './measurements-extractors-header-item.scss';

import { State } from 'common/interfaces/state';
import { KreoIconInfoBigColor } from 'common/UIKit';
import { UnitUtil } from 'common/utils/unit-util';
import { HelpTooltip } from '../../../../components/controls/tooltips';
import { MeasurementsValueKey } from '../../enums/measurements-value-key';
import { MeasurementExtractorFunction } from '../../interfaces/measurements/measurement-extractor-function';
import { MeasurementGeneralExtractorEditor } from '../../interfaces/measurements/measurement-general-extractor-editor';
import { MeasurementsExtractorsHeaderItemLayout } from '../measurements-extractors-header-item-layout';

interface OwnProps {
  id: string;
}

interface StateProps {
  activityNames: string[];
  generalFunctionId: string;
  generalExtractorEditors: Record<string, MeasurementGeneralExtractorEditor>;
  extractors: MeasurementExtractorFunction[];
}

interface Props extends OwnProps, StateProps {
}

class MeasurementsExtractorsHeaderItemComponent extends React.PureComponent<Props> {
  private readonly valuesKeysWithHiddenTotal: MeasurementsValueKey[] = [
    MeasurementsValueKey.ReinforcementConcentration,
  ];

  public render(): React.ReactNode {
    return (
      <MeasurementsExtractorsHeaderItemLayout>
        <div className='measurements-extractors-header-item__tooltip-icon'>
          <HelpTooltip
            id='measurements-extractors-header-item__tooltip'
            icon={<KreoIconInfoBigColor />}
            place='bottom'
            className='measurements-extractors-header-item__tooltip'
            text={this.renderTooltip()}
          />
        </div>
        <div
          className='measurements-extractors-header-item__name'
          title={this.props.generalFunctionId}
        >
          <p>{this.props.generalFunctionId}</p>
        </div>
        <div className='measurements-extractors-header-item__totals'>
          {this.renderTotals()}
        </div>
      </MeasurementsExtractorsHeaderItemLayout>
    );
  }

  private renderTotals(): React.ReactNode {
    const { generalExtractorEditors, extractors } = this.props;
    return extractors.map(extractor => {
      const totalMustBeHidden = this.valuesKeysWithHiddenTotal.includes(extractor.extractorFunctionKey);
      const totalValue = generalExtractorEditors[extractor.extractorFunctionId].totalValue;
      const value = totalMustBeHidden
        ? ''
        : UnitUtil.round(totalValue, extractor.unitName).toString();
      const title = `${value} ${extractor.unitName}`;
      return (
        <p
          key={extractor.extractorFunctionId || 'undefined'}
          className='measurements-extractors-header-item__volume'
          title={title}
        >
          {value} <span>{extractor.unitName}</span>
        </p>
      );
    });
  }

  private renderTooltip(): JSX.Element {
    return (
      <React.Fragment>
        <p>
          <b>ACTIVITIES</b>
        </p>
        {this.props.activityNames.map((activityName, index) => {
          return <p key={index}>{activityName}</p>;
        })}
      </React.Fragment>
    );
  }

}


function mapStateToProps({ measurements }: State, { id }: OwnProps): StateProps {
  const { activityNames, generalFunctionId, extractors } = measurements.extractorFunctions[id];
  return {
    generalExtractorEditors: measurements.generalExtractorEditors,
    activityNames,
    generalFunctionId,
    extractors,
  };
}


const connector = connect(mapStateToProps);
export const MeasurementsExtractorsHeaderItem =
  connector(MeasurementsExtractorsHeaderItemComponent);
