import { createSlice } from '@reduxjs/toolkit';

import { ConstantFunctions } from 'common/constants/functions';
import { ActionWith } from 'common/interfaces/action-with';
import { TwoDCopilotState, SendMessagePayload, Message, AIRequestStatus } from '../interfaces';

export const TWOD_COPILLOT_INITIAL_STATE: TwoDCopilotState = {
  isPopupOpened: false,
  history: [],
  tempAnswerText: '',
  currentRequestStatus: AIRequestStatus.Default,
  windowHeight: null,
};

const TwoDCopilot = createSlice({
  name: '@2dCopilot',
  initialState: TWOD_COPILLOT_INITIAL_STATE,
  reducers: {
    setPopupOpened: (state, { payload }: { payload: boolean }) => {
      state.isPopupOpened = payload;
    },
    sendMessage: (state, action: ActionWith<SendMessagePayload>) => {
      if (state.history.length === 0) {
        state.history.push({
          role: 'user',
          content: action.payload.query,
          dontSend: true,
        });
      }
    },
    setUpdatedHistory: (state, { payload: history }: ActionWith<Message[]>) => {
      state.history = history;
    },
    setTempAnswerText: (state, { payload }: { payload: string }) => {
      state.tempAnswerText = payload;
    },
    addToHistory: (state, { payload }: { payload: Message }) => {
      state.history = state.history.concat(payload);
    },
    reset: (state) => {
      state.history = [];
    },
    tryAgain: (state) => {
      state.currentRequestStatus = AIRequestStatus.Loading;
    },
    setRequestStatus: (state, { payload }: { payload: AIRequestStatus }) => {
      state.currentRequestStatus = payload;
    },
    setWindowHeight: (state, { payload }: { payload: number }) => {
      state.windowHeight = payload;
    },
    stop: ConstantFunctions.doNothing,
  },
});

export const TwoDCopilotActions = TwoDCopilot.actions;
export const TwoDCopilotReducer = TwoDCopilot.reducer;
