import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';

export class EnumCodec<TEnum> extends t.Type<TEnum> {
  public readonly _tag: 'EnumCodec' = 'EnumCodec';
  public enumObject!: object;
  public constructor(e: object, name?: string) {
    super(
      name || 'enum',
      (u): u is TEnum => Object.values(this.enumObject).some(v => v === u),
      (u, c) => (this.is(u) ? t.success(u) : t.failure(u, c)),
      t.identity,
    );

    this.enumObject = e;
  }
}

function decode<TCodec extends t.Any>(
  data: t.TypeOf<TCodec>,
  codec: TCodec,
): t.TypeOf<TCodec> {
  const decoded = codec.decode(data);
  if (decoded.isLeft()) {
    const message = `Invalid model: ${PathReporter.report(decoded)}`;

    // throw after release/2.13.1
    if (process.env.NODE_ENV === 'development') {
      throw new Error(message);
    } else {
      console.error(message);
      return data;
    }
  }

  return decoded.value;
}

export const CodecUtils = {
  createEnumCodec<TEnum>(e: object, name?: string): EnumCodec<TEnum> {
    return new EnumCodec(e, name);
  },
  decode,
};
