import * as React from 'react';

export const KreoIconLength: React.FC = () => (
  // eslint-disable-next-line max-len
  <svg width='24px' height='24px' viewBox='0 0 24 24'>
    <g id='Icons/24x24/Tools/Length' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        className='icon__color-light'
        // eslint-disable-next-line max-len
        d='M3.5,4 C3.77614237,4 4,4.22385763 4,4.5 L4,4.5 L4,18.5 C4,18.7761424 3.77614237,19 3.5,19 C3.22385763,19 3,18.7761424 3,18.5 L3,18.5 L3,4.5 C3,4.22385763 3.22385763,4 3.5,4 Z M20.5,4 C20.7761424,4 21,4.22385763 21,4.5 L21,4.5 L21,18.5 C21,18.7761424 20.7761424,19 20.5,19 C20.2238576,19 20,18.7761424 20,18.5 L20,18.5 L20,4.5 C20,4.22385763 20.2238576,4 20.5,4 Z'
        id='color2'
        fill='currentColor'
        fillRule='nonzero'
      />
      <path
        // eslint-disable-next-line max-len
        d='M7.85355339,9.35355339 C7.94732158,9.44732158 8,9.57449854 8,9.70710678 L7.99910678,10.9991068 L15.9991068,10.9991068 L16,9.70710678 C16,9.60102019 16.0337142,9.49840961 16.0952092,9.41359954 L16.1464466,9.35355339 C16.3417088,9.15829124 16.6582912,9.15829124 16.8535534,9.35355339 L16.8535534,9.35355339 L18.6464466,11.1464466 C18.8417088,11.3417088 18.8417088,11.6582912 18.6464466,11.8535534 L18.6464466,11.8535534 L16.8535534,13.6464466 C16.7597852,13.7402148 16.6326082,13.7928932 16.5,13.7928932 C16.2238576,13.7928932 16,13.5690356 16,13.2928932 L16,13.2928932 L15.9991068,11.9991068 L7.99910678,11.9991068 L8,13.2928932 C8,13.5690356 7.77614237,13.7928932 7.5,13.7928932 C7.36739176,13.7928932 7.2402148,13.7402148 7.14644661,13.6464466 L5.35355339,11.8535534 C5.15829124,11.6582912 5.15829124,11.3417088 5.35355339,11.1464466 L7.14644661,9.35355339 C7.34170876,9.15829124 7.65829124,9.15829124 7.85355339,9.35355339 Z'
        id='color1'
        fill='currentColor'
        fillRule='nonzero'
      />
    </g>
  </svg>
);
