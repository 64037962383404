import React from 'react';

import './database-entity-named-header.scss';

import { KreoIconAddPlus, KreoIconInfoBigColor } from 'common/UIKit';
import { HelpTooltip } from '../../../../components/controls/tooltips';


interface Props {
  name: string;
  info?: string;
  onAddButtonClick?: () => void;
}

export class DatabaseEntityNamedHeader extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='database-entity-named-header'>
        <div className='database-entity-named-header__name'>{this.props.name}</div>
        {
          this.props.info ? (
            <HelpTooltip
              id={`${this.props.name}Tooltip`}
              icon={<KreoIconInfoBigColor />}
              className='database-entity-named-header__info'
              text={this.props.info}
            />
          ) : null
        }
        {
          this.props.onAddButtonClick ? (
            <div onClick={this.props.onAddButtonClick} className='database-entity-named-header__add-button'>
              <KreoIconAddPlus />
            </div>
          ) : null
        }
      </div>
    );
  }
}
