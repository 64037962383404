import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './table-header-column.scss';

import { Feed } from 'common/interfaces/feed';
import { KreoIconInfoBigColor } from 'common/UIKit';
import { HelpTooltip } from '../../../components/controls/tooltips';
import { FilterOption, FilterValue } from '../filter-select/interfaces';
import { LazyLoadingFilterSelect } from '../lazy-loading-filter-select';


interface ColumnHeaderFilterProps<T> {
  value: FilterValue[];
  onFilterValueChange: (filterName: string, value: FilterValue[]) => void;
  mapToFilterOption: (option: T) => FilterOption;
  load: (skip: number, take: number, searchQuery?: string) => Promise<Feed<T>>;
}

interface Props {
  name: string;
  helpText?: string;
  subText?: string;
  className?: string;
  filterProps?: ColumnHeaderFilterProps<any>;
}

export class TableHeaderColumn extends React.Component<Props> {
  public render(): JSX.Element {
    const { className, name, helpText, subText, filterProps: filter } = this.props;
    return (
        <div className={classNames('table-header-column', className)}>
          <div className='table-header-column__text'>
            {
              !filter ? (
                <React.Fragment>
                  {name}
                  {subText && <span className='table-header-column__sub-text'> {subText}</span>}
                </React.Fragment>
              ) : (
                <LazyLoadingFilterSelect
                  batch={25}
                  entityName={name}
                  load={this.props.filterProps.load}
                  value={this.props.filterProps.value}
                  onChangeSelectedValue={this.onFilterValueChange}
                  mapToFilterOption={this.props.filterProps.mapToFilterOption}
                />
              )
            }
          </div>
          {
            helpText &&
            <HelpTooltip
              id={`${name}Tooltip`}
              icon={<KreoIconInfoBigColor />}
              className='table-header-column__help-tooltip'
              text={helpText}
            />
          }
        </div>
    );
  }

  @autobind
  private onFilterValueChange(value: FilterValue[]): void {
    this.props.filterProps.onFilterValueChange(this.props.name, value);
  }
}
