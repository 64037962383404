import { push } from 'connected-react-router';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionType } from 'common/constants/subscription';
import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { SpinnerPage } from 'routes/spinner-page';
import { SubscriptionActions } from '../../actions/creators';
import { BillingEntityStatus } from '../../enums/billing-entity-status';
import { SubscriptionPlanListingModel } from '../../interfaces/subscription-plan-listing-model';
import { TempInvitePeople } from '../../store/interface';

export const BusinessSubscribePage: React.FC = () => {
  const dispatch = useDispatch();
  const tempInvitePeople = useSelector<State, TempInvitePeople[]>(s => s.subscription.tempInvitePeople);
  const plansModel = useSelector<State, SubscriptionPlanListingModel | null>(s => s.account.subscriptionPlans);
  const isPlanLoaded = useMemo(() => {
    return !!plansModel;
  }, [plansModel]);
  const variant = useMemo(() => {
    if (isPlanLoaded) {
      const plan = plansModel.plans.find(x => x.isFree);
      return plan && plan.variants.find(v => v.isHidden === true && v.status === BillingEntityStatus.Active);
    }
  }, [isPlanLoaded]);

  useEffect(() => {
    if (isPlanLoaded) {
      if (variant) {
        const form = {
          planId: variant.id,
          subscriptionType: SubscriptionType.Takeoff2d,
          quantity: tempInvitePeople
            ? tempInvitePeople.filter(p => p.email && !p.email.endsWith('@kreo.net')).length + 1
            : 1,
        };
        dispatch(SubscriptionActions.createFreeSubscription(form));
      } else {
        dispatch(push(AppUrls.qto2d.billing.subscribe));
      }
    }
  }, [isPlanLoaded]);

  return <SpinnerPage />;
};
