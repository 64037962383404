const prefix = `@notification`;

export const NotificationActionTypes = {
  CONNECT: `${prefix}/CONNECT`,
  LOAD_NOTIFICATIONS_REQUEST: `${prefix}/LOAD_NOTIFICATIONS_REQUEST`,
  LOAD_NOTIFICATIONS_SUCCEEDED: `${prefix}/LOAD_NOTIFICATIONS_SUCCEEDED`,
  LOAD_NOTIFICATIONS_FAILED: `${prefix}/LOAD_NOTIFICATIONS_FAILED`,
  SHOW_NOTIFICATION_PANEL: `${prefix}/SHOW_NOTIFICATION_PANEL`,
  READ_NOTIFICATION: `${prefix}/READ_NOTIFICATION`,
  ADD_NOTIFICATION: `${prefix}/ADD_NOTIFICATION`,
  DELETE_NOTIFICATION: `${prefix}/DELETE_NOTIFICATION`,
  ADD_ALERT: `${prefix}/ADD_ALERT`,
  DELETE_ALERT: `${prefix}/DELETE_ALERT`,
};
