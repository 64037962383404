import { Constants, HotKeys, Icons, LinkComponent, ModalWrapper, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { ProjectsFolderActions } from 'unit-projects/actions/creators/projects-folder';
import { CompanyProjectHeader } from 'unit-projects/interfaces/company-project-header';
import { Project } from 'unit-projects/interfaces/project';
import { ProjectsFolder } from '../../../projects/interfaces/projects-folder';
import { MoveToFolderItem } from './move-to-folder-item';
import { Styled } from './styled';

interface ComponentState {
  folderToMove: ProjectsFolder;
}

interface DialogData {
  currentFolder: ProjectsFolder;
  selectedFolder: ProjectsFolder;
  projectId: number;
}

interface DispatchProps {
  setCurrentFolder: (folder: ProjectsFolder) => void;
  updateProjectHeader: (header: CompanyProjectHeader) => void;
}

interface StateProps {
  data: DialogData;
  folders: ProjectsFolder[];
  currentProject: Project;
}

interface OwnProps {
  onSubmit: (selectedFolder: ProjectsFolder, projectId: number, parentId: number) => void;
  onCloseDialog: () => void;
  createFolder: ({ parentFolder }) => void;
  projectHeader?: CompanyProjectHeader;
  isFolderDialog?: boolean;
}

interface Props extends OwnProps, DispatchProps, StateProps {
}

export const MOVE_TO_FOLDER_DIALOG_NAME = 'moveToFolderDialogName';

class MoveToFolderDialogComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      folderToMove: null,
    };
  }

  public componentDidMount(): void {
    const { currentProject, folders } = this.props;
    if (currentProject) {
      const folder = folders.find(item => item.id === currentProject?.folderId);
      this.setState({ folderToMove: folder });
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    const { isFolderDialog, data } = this.props;
    const isRootRenderMoveToFolderDialog = isFolderDialog
      ? data && (data !== prevProps.data) && data.currentFolder
      : data && (data !== prevProps.data);

    if (isRootRenderMoveToFolderDialog) {
      this.setState({ folderToMove: data.currentFolder });
    }
  }

  public render(): React.ReactNode {
    if (!this.props.data) {
      return null;
    }

    let submitEnabled: boolean;
    if (
      this.state.folderToMove === undefined
      || (this.state.folderToMove?.id === this.props.currentProject?.folderId && !this.props.data.currentFolder)
      || (this.state.folderToMove && this.state.folderToMove.id === this.props.data.currentFolder?.id)
    ) {
      submitEnabled = false;
    } else {
      submitEnabled = true;
    }

    const currentFolderId = this.state.folderToMove ? this.state.folderToMove.id : undefined;
    const isMoveProjectMode = !this.props.data.selectedFolder;
    const visibleFolders = this.props.folders.filter(folder =>
      folder.parentFolderId === currentFolderId
      && (isMoveProjectMode || folder.id !== this.props.data.selectedFolder.id));

    return (
      <DialogWrapper name={MOVE_TO_FOLDER_DIALOG_NAME}>
        <ModalWrapper onExit={this.props.onCloseDialog}>
          <HotKeys
            keyMap={Constants.KeyMaps.ENTER_ESCAPE}
            handlers={{ escape: this.props.onCloseDialog, enter: this.onSubmit }}
            autofocus={true}
          >
            <Styled.DialogContainer>
              <Styled.DialogHeader>
                <Icons.MoveToGroup_1 height={20} width={20} />
                <Text fontSize={14}>Move to {this.state.folderToMove ? this.state.folderToMove.name : 'Home'}</Text>
                <Styled.DialogButton>
                  <LinkComponent
                    text={'Move'}
                    onClick={this.onSubmit}
                    Icon={Icons.Right}
                    rightIcon={true}
                    mood={submitEnabled ? 'secondary' : 'disabled'}
                  />
                </Styled.DialogButton>
              </Styled.DialogHeader>
              <Styled.Navigation>
              {this.state.folderToMove
                ? (<LinkComponent
                  text={'Back'}
                  onClick={this.openPrevFolder}
                  Icon={Icons.Left}
                  mood={'secondary'}
                />)
                : <Text fontSize={14}>Home</Text>
              }
              </Styled.Navigation>
              <Styled.FoldersList>
                {visibleFolders.map(folder => (
                  <MoveToFolderItem
                    key={folder.id}
                    folder={folder}
                    onClick={this.openFolder}
                  />
                ))}
              </Styled.FoldersList>
              <Styled.DialogFolder onClick={this.createFolder}>
                <Icons.NewGroup2d height={20} width={20} />
                <Text fontSize={14}>Create folder</Text>
              </Styled.DialogFolder>
            </Styled.DialogContainer>
          </HotKeys>
        </ModalWrapper>
      </DialogWrapper>
    );
  }

  @autobind
  private onSubmit(): void {
    this.props.onSubmit(
      this.props.data.selectedFolder,
      this.props.data.projectId,
      this.state.folderToMove ? this.state.folderToMove.id : null,
    );
    if (this.props.projectHeader) {
      this.props.updateProjectHeader(this.props.projectHeader);
    }

    if (this.props.isFolderDialog) {
      this.props.setCurrentFolder(this.state.folderToMove);
    }
    this.props.onCloseDialog();
  }

  @autobind
  private openFolder(folder: ProjectsFolder): void {
    this.setState({ folderToMove: folder });
  }

  @autobind
  private openPrevFolder(): void {
    const prevFolderId = this.state.folderToMove.parentFolderId;
    const prevFolder = this.props.folders.find(folder => folder.id === prevFolderId) || null;
    this.openFolder(prevFolder);
  }

  @autobind
  private createFolder(): void {
    this.props.createFolder({ parentFolder: this.state.folderToMove });
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    data: state.dialog[MOVE_TO_FOLDER_DIALOG_NAME],
    folders: state.projects.folders,
    currentProject: state.projects.currentProject,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    setCurrentFolder: (folder) => dispatch(ProjectsFolderActions.setCurrentFolder(folder)),
    updateProjectHeader: projectHeader => dispatch(ProjectsActions.locallyUpdateProjectHeader(projectHeader)),
  };
};

export const MoveToFolderDialog = connect(mapStateToProps, mapDispatchToProps)(MoveToFolderDialogComponent);
