import { CodecUtils } from 'common/utils/codec-utils';

export enum ValidationStepState {
  NeedsCalculation = 'NeedsCalculation',
  UnderEdit = 'UnderEdit',
  Calculating = 'Calculating',
  Approvable = 'Approvable',
  Blocked = 'Blocked',
}

export const ValidationStepStateC =
  CodecUtils.createEnumCodec<ValidationStepState>(ValidationStepState, 'ValidationStepState');
