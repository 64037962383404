import styled from 'styled-components';

const Container = styled.div<{ isHightLight: boolean }>`
  display: flex;
  margin: 10px 10px 10px 15px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid ${p => p.isHightLight ? p.theme.color.turquoise : p.theme.color.background};
`;

const AvatarContainer = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const Badge = styled.div`
  width: 5px;
  height: 5px;
  border: 2px solid ${p => p.theme.color.background};
  background: ${p => p.theme.color.turquoise};
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const DateContainer = styled.div`
  padding-bottom: 10px;
`;

const ButtonContainer = styled.div`
  height: 20px;
  margin-top: 10px;

  > div {
    > p {
      font-size: 14px;
    }
  }
`;

export const Styled = {
  Container,
  AvatarContainer,
  Badge,
  Content,
  DateContainer,
  ButtonContainer,
};
