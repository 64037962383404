import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './resource-limitations-list.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { ScenarioModule, ScenarioState } from '../../../projects/interfaces/scenario-state';
import { ScenarioStateHelper } from '../../../projects/utils/scenario-state-helper';
import { ScenariosActions, WorkPackagesData } from '../../../scenarios';
import { ResourceLimitationsRow } from '../resource-limitations-row';
import { ResourceLimitationsRowTotal } from '../resource-limitations-row-total';

interface ReduxProps {
  loadStatus: RequestStatus;
  limitations: WorkPackagesData.Management[];
  scenarioState: ScenarioState;
}

interface ReduxActions {
  getLimitations: (scenarioId: number) => void;
  dropLimitationsState: () => void;
}

interface Props extends ReduxProps, ReduxActions {
  scenarioId: number;
}

class ResourceLimitationsListComponent extends React.Component<Props> {
  public componentWillUnmount(): void {
    this.props.dropLimitationsState();
  }

  public componentDidMount(): void {
    this.loadDataIfNeeded(this.props, null);
  }

  public componentDidUpdate(prevProps: Props): void {
    this.loadDataIfNeeded(this.props, prevProps);
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        {this.props.scenarioId ?
          (
            this.props.loadStatus === RequestStatus.Loaded
              ? <div className='resource-limitations-list'>
                <KreoScrollbars showShadowTop={true}>
                  <div className='resource-limitations-list__content'>
                    <ResourceLimitationsRowTotal />
                    {this.props.limitations.map((item, index) => (
                      <ResourceLimitationsRow key={index} item={item} />
                    ))}
                  </div>
                </KreoScrollbars>
              </div>
              : <SvgSpinner size='large' />
          )
          : null}
      </React.Fragment>
    );
  }

  private loadDataIfNeeded(currentProps: Props, prevProps: Props): void {
    const currentState = currentProps.scenarioState;
    const prevState = prevProps && prevProps.scenarioState;
    const needToLoad = ScenarioStateHelper.shouldLoadModel(currentState, prevState, ScenarioModule.ResourceManager);

    if (needToLoad) {
      this.props.getLimitations(currentProps.scenarioId);
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const scenarioGuid = state.scenarios.editScenario && state.scenarios.editScenario.scenarioGuid;
  const scenarioState = state.projects.scenarioStates[scenarioGuid];

  return {
    loadStatus: state.scenarios.statuses.resourceLimitations,
    limitations: state.scenarios.resourceLimitations,
    scenarioState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    getLimitations: (scenarioId) => dispatch(ScenariosActions.getResourceLimitations(scenarioId.toString())),
    dropLimitationsState: () => dispatch(ScenariosActions.dropLimitationsState()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ResourceLimitationsList = connector(ResourceLimitationsListComponent);
