import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-assembly-entity-predicted.scss';

import { ElementTooltip, TooltipPosition } from 'common/components/element-tooltip';
import { State } from 'common/interfaces/state';
import { KreoIconCheckMark } from 'common/UIKit';
import { numberUtils } from 'common/utils/number-utils';
import { ClassificationActions } from '../../actions/creators/classification';
import { ClassificationUtils } from '../../utils/classification-utils';

interface StateProps {
  code: string;
  description: string;
  confidence: number;
  isUndefined: boolean;
  isSelected: boolean;
}

interface DispatchProps {
  toggleSelectStatus: () => void;
}

interface OwnProps {
  index: number;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

const ClassificationAssemblyEntityPredictedComponent: React.FC<Props> = props => {
  const className = classNames('classification-assembly-entity-predicted', {
    'classification-assembly-entity-predicted--selected': props.isSelected,
  });
  return (
    <div
      className={className}
      onClick={props.toggleSelectStatus}
      data-control-name='classification-assembly-entity-predicted'
    >
      <b className='classification-assembly-entity-predicted__code'>{props.code}</b>
      <div className='classification-assembly-entity-predicted__title'>{props.description}</div>
      <div className='classification-assembly-entity-predicted__status'>
        {!!props.confidence &&
          !props.isUndefined && (
            <ElementTooltip
              tooltipPosition={TooltipPosition.CenterTop}
              size='small'
              className='classification-assembly-entity-predicted__confidence'
              targetElement={numberUtils.getNumeralFormatter(props.confidence).format('%0.00')}
            >
              Probability
            </ElementTooltip>
        )}
        <div className='classification-assembly-entity-predicted__radio'>
          {props.isSelected && <KreoIconCheckMark />}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps({ classification }: State, { index }: OwnProps): StateProps {
  const {
    code,
    confidence,
    id,
    description,
  } = ClassificationUtils.extractNeededInfoForAssemblyOrLayer(
    classification.itemClassification[index],
    classification.changeAssemblyRoot,
  );
  return {
    isSelected: index === classification.classificationSelectedIndex,
    code,
    confidence,
    isUndefined: !Number.isInteger(id),
    description,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { index }: OwnProps): DispatchProps {
  return {
    toggleSelectStatus: () => {
      dispatch(ClassificationActions.setSelectedUniclass(index));
    },
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationAssemblyEntityPredicted = connector(
  ClassificationAssemblyEntityPredictedComponent,
);
