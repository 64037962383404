import { ActionWith } from 'common/interfaces/action-with';
import { NewInstanceSettings } from '../../interfaces';
import { UpdateNewInstanceSettingsPayload } from '../payloads/new-instance-settings';
import { NewInstanceSettingsActionType } from '../types/new-instance-settings';

function updateSetting<T extends keyof NewInstanceSettings, K = NewInstanceSettings[T]>(
  payload: UpdateNewInstanceSettingsPayload<T, K>,
): ActionWith<UpdateNewInstanceSettingsPayload<T, K>> {
  return {
    type: NewInstanceSettingsActionType.UPDATE_NEW_INSTANCE_SETTINGS,
    payload,
  };
}


export const NewInstanceSettingsActions = {
  updateSetting,
};
