import autobind from 'autobind-decorator';
import * as paper from 'paper';
import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { DrawingsCommonPointsContextProps } from '../../interfaces/drawings-common-points-context-props';
import { DrawingsGeometryPointCoordinates } from '../../interfaces/drawings-geometry';
import { DrawingsShortInfo } from '../../interfaces/drawings-short-drawing-info';
import { Vector2Utils } from '../../utils/math-utils/vector2-utils';

export const DrawingsCommonPointsContext = React.createContext<DrawingsCommonPointsContextProps>(null);

interface StateProps {
  drawingPoints: Record<string, string[]>;
  currentDrawingInfo: DrawingsShortInfo;
  points: Record<string, ShortPointDescription>;
}

interface Props extends StateProps {
  children?: React.ReactNode;
}

class Provider extends React.Component<Props> {
  private contextProps: DrawingsCommonPointsContextProps;

  constructor(props: Props) {
    super(props);
    this.contextProps = {
      getSnappingPoint: this.getSnappingPoint,
    };
  }

  public render(): React.ReactNode {
    return (
      <DrawingsCommonPointsContext.Provider value={this.contextProps}>
        {this.props.children}
      </DrawingsCommonPointsContext.Provider>
    );
  }

  @autobind
  private getSnappingPoint(point: DrawingsGeometryPointCoordinates, threshold: number): paper.Point {
    const { drawingPoints, currentDrawingInfo, points } = this.props;
    if (drawingPoints[currentDrawingInfo.drawingId]) {
      let minimumLength = Infinity;
      let resultIndex = -1;
      const currentPoint: ShortPointDescription = [point.x, point.y];
      const squaredThreshold = threshold ** 2;
      const currentDrawingPoints = drawingPoints[currentDrawingInfo.drawingId];
      for (let i = 0; i < currentDrawingPoints.length; i++) {
        const pointId = currentDrawingPoints[i];
        const pointToCheck = points[pointId];
        const length = Vector2Utils.squaredDist(pointToCheck, currentPoint);
        if (length < minimumLength && length < squaredThreshold) {
          minimumLength = length;
          resultIndex = i;
        }
      }
      const minPointId = currentDrawingPoints[resultIndex];
      return resultIndex === -1 ? null : new paper.Point(points[minPointId]);
    }
    return null;
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    drawingPoints: state.drawings.drawingPaperPoints,
    currentDrawingInfo: state.drawings.currentDrawingInfo,
    points: state.drawings.aiAnnotation.points,
  };
}

export const DrawingsCommonPointsContextProvider = connect(mapStateToProps)(Provider);
