import { FilterGroup, FilterSet } from './interfaces';

function isGroup(value: FilterSet | FilterGroup): value is FilterGroup {
  return !!(value as FilterGroup).sets;
}

export const FilterTypeguards = {
  isGroup,
};

