import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, WrappedFieldProps } from 'redux-form';

import './database-activity-variant-constraint-item.scss';

import { MultiLevelDropDownMenu } from 'common/components/multi-level-drop-down-menu';
import { State as ReduxState } from 'common/interfaces/state';
import {
  KreoIconCloseSmall,
  MaterialInputField,
  MaterialInputProps,
  MaterialMenuItem,
  MaterialNumberInputField,
  MaterialNumberInputProps,
  MaterialSelectField,
  MaterialSelectProps,
} from 'common/UIKit';
import { PreparedSearchQuery } from 'common/utils/string-utils';
import { Input } from '../../../../components/controls/inputs';
import { FieldRequired } from '../../../../components/dialog/validators';
import {
  ConstraintModel,
  ExtractorFunctionModel,
  ExtractorFunctionModelDropDown,
  ExtractorFunctionOption,
  MeasurementModel,
  MeasurementType,
  MeasurementTypeModel,
  UnitModel,
} from '../../interfaces/data';
import { ExtractorFunctionHelper } from '../../utils/extractor-functions-helper';
import { DatabaseActivityVariantSelectInner } from './database-activity-variant-select-inner';

const unitRequired = FieldRequired('Unit');

interface ReduxProps {
  units: UnitModel[];
  extractorFunctions: ExtractorFunctionModel[];
  measurementToUnits: Record<string, UnitModel[]>;
  measurementsMap: Record<string, MeasurementModel>;
  measurementTypes: Record<MeasurementType, MeasurementTypeModel>;
}

export interface DatabaseActivityVariantConstraintItemProps {
  name: string;
  error?: string;
  readonly: boolean;
  constraint: ConstraintModel;
  index: number;
  onConstraintChange: (index: number, constraint: ConstraintModel) => void;
  onDelete: (index: number) => void;
}

interface Props extends ReduxProps, DatabaseActivityVariantConstraintItemProps {}

interface State {
  extractorFunctions: ExtractorFunctionOption[];
  units: UnitModel[];
  measurement: MeasurementModel;
}

export class DatabaseActivityVariantConstraintItemComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getState(props);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (
      !isEqual(prevProps.extractorFunctions, this.props.extractorFunctions) ||
      !isEqual(prevProps.measurementToUnits, this.props.measurementToUnits) ||
      !isEqual(prevProps.units, this.props.units) ||
      prevProps.constraint.extractorFunction !== this.props.constraint.extractorFunction
    ) {
      this.setState(this.getState(this.props));
    }

    if (this.isConstraintWithValue(this.state.measurement)) {
      const unit = this.state.units.find(x => x.coefficient === 1);
      this.props.onConstraintChange(this.props.index, {
        ...this.props.constraint,
        value: 0,
        stringValue: null,
        operation: '>',
        unitId: unit && unit.id,
      });
    }
  }

  public render(): JSX.Element {
    const { unitId: unitId, extractorFunction, operation, value, stringValue } = this.props.constraint;
    const constraintWithValue = this.state.measurement && this.state.measurement.name !== 'probability';
    const className = classNames('database-activity-variant-constraint', {
      'database-activity-variant-constraint--invalid': !!this.props.error,
    });

    return (
      <React.Fragment>
        {
          this.props.error ? (
            <span className='database-activity-variant-constraint__error-message'>
              {this.props.error}
            </span>
          ) : null
        }
        <div className={className}>
          <div className='database-activity-variant-constraint__row-wrap'>
            <MultiLevelDropDownMenu
              options={this.state.extractorFunctions}
              compare={MultiLevelDropDownMenu.defaultSearchComparer}
              value={extractorFunction}
              onSelect={this.onExtractorFunctionChange}
              className='database-activity-variant-constraint__function'
              optionContentRenderer={this.renderOption}
              disabled={this.props.readonly}
              displayModeSelect={true}
              label='Function'
            >
              {extractorFunction ? extractorFunction : 'Select Function'}
              {extractorFunction ? (
                <Input
                  className='visually-hidden'
                  value={extractorFunction}
                  onChange={this.onExtractorFunctionChange}
                />
              ) : null}
            </MultiLevelDropDownMenu>
            {
              constraintWithValue ? (
                <Field<MaterialSelectProps>
                  name={`${this.props.name}.unitId`}
                  component={MaterialSelectField}
                  className='database-activity-variant-constraint__unit'
                  value={unitId}
                  placeholder='Select Unit'
                  label='Unit'
                  onChange={this.onUnitChange}
                  disabled={this.props.readonly || !extractorFunction}
                  displayBottomInformation={true}
                  validate={unitRequired}
                >
                  {this.getUnitOptions()}
                </Field>
              ) : null
            }
          </div>
          {
            constraintWithValue ? (
              <div className='database-activity-variant-constraint__row-wrap'>
                <div className='database-activity-variant-constraint__field'>
                  <Field<MaterialSelectProps>
                    name={`${this.props.name}.operation`}
                    component={MaterialSelectField}
                    value={operation}
                    label='Option'
                    onChange={this.onOperationChange}
                    disabled={this.props.readonly}
                    displayBottomInformation={true}
                  >
                    {this.getOperations().map(x => <MaterialMenuItem key={x} value={x}>{x}</MaterialMenuItem>)}
                  </Field>
                </div>
                <div className='database-activity-variant-constraint__field'>
                  {this.state.measurement.type === MeasurementType.Numeric ? (
                    <Field<MaterialNumberInputProps>
                      name={`${this.props.name}.value`}
                      component={MaterialNumberInputField}
                      label='Value'
                      value={value}
                      placeholder='0.00'
                      isFloatingLabel={false}
                      precision={2}
                      onChange={this.onValueChange}
                      disabled={this.props.readonly}
                      displayBottomInformation={true}
                      valueType='float'
                    />) : (
                      <Field<MaterialInputProps>
                        name={`${this.props.name}.stringValue`}
                        component={MaterialInputField}
                        label='Value'
                        value={stringValue}
                        placeholder='Value'
                        onChange={this.onStringValueChange}
                        disabled={this.props.readonly}
                        displayBottomInformation={true}
                      />)}
                </div>
              </div>
            ) : null
          }
          {
            !this.props.readonly ? (
              <div className='database-activity-variant-constraint__btn-del' onClick={this.onDelete}>
                <KreoIconCloseSmall />
              </div>
            ) : null
          }
        </div>
      </React.Fragment>
    );
  }

  private isConstraintWithValue(measurement: MeasurementModel): boolean {
    return measurement && measurement.name === 'probability';
  }

  private renderOption(
    option: ExtractorFunctionModelDropDown,
    query: PreparedSearchQuery,
    _isSelected: boolean,
  ): React.ReactNode {
    const name = MultiLevelDropDownMenu.defaultSearchQueryHighlighter(option.name, query);
    return <DatabaseActivityVariantSelectInner option={option} name={name} />;
  }

  private getUnitOptions(): JSX.Element[] {
    return this.state.units && this.state.units
      .map(unit => {
        return (
          <MaterialMenuItem key={unit.id} value={unit.id}>
            {unit.acronym}
          </MaterialMenuItem>
        );
      });
  }

  @autobind
  private getOperations(): string[] {
    const type = this.state.measurement.type;
    if (!type) {
      return [];
    }

    return this.props.measurementTypes[type].availableConstraintOperations;
  }

  @autobind
  private onUnitChange(_: React.SyntheticEvent, unitId: number): void {
    this.props.onConstraintChange(this.props.index, {
      ...this.props.constraint,
      unitId,
    });
  }

  @autobind
  private onValueChange(_: React.SyntheticEvent, value: number): void {
    this.props.onConstraintChange(this.props.index, {
      ...this.props.constraint,
      value,
    });
  }

  @autobind
  private onStringValueChange(_: React.SyntheticEvent, stringValue: string): void {
    this.props.onConstraintChange(this.props.index, {
      ...this.props.constraint,
      stringValue,
    });
  }

  @autobind
  private onExtractorFunctionChange(extractorFunctionId: string): void {
    const { measurementToUnits, measurementsMap, extractorFunctions, constraint } = this.props;
    const extractorFunction = ExtractorFunctionHelper.getExtractorFunction(extractorFunctionId, extractorFunctions);
    const measurement = measurementsMap[extractorFunction.measurement];

    this.props.onConstraintChange(this.props.index, {
      ...constraint,
      extractorFunction: extractorFunctionId,
      stringValue: measurement.type === MeasurementType.String ? constraint.stringValue : null,
      value: measurement.type === MeasurementType.Numeric ? constraint.value : null,
      unitId: ExtractorFunctionHelper.getDefaultUnitId(extractorFunction, measurementToUnits, measurementsMap),
    });
  }

  @autobind
  private onOperationChange(_: React.SyntheticEvent, value: string): void {
    this.props.onConstraintChange(this.props.index, {
      ...this.props.constraint,
      operation: value,
    });
  }

  @autobind
  private onDelete(): void {
    this.props.onDelete(this.props.index);
  }

  private getState(props: Props): State {
    const extractorFunctionId = props.constraint.extractorFunction;
    const extractorFunction = ExtractorFunctionHelper.getExtractorFunction(
      extractorFunctionId, props.extractorFunctions);
    const units = ExtractorFunctionHelper.getExtractorFunctionUnits(extractorFunction, props.measurementToUnits);

    return {
      extractorFunctions: ExtractorFunctionHelper.getConstraintExtractorFunctions(
        extractorFunctionId, props.extractorFunctions, props.measurementToUnits, props.measurementsMap),
      units,
      measurement: props.measurementsMap[extractorFunction && extractorFunction.measurement],
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    units: state.database.currentDatabase.units,
    extractorFunctions: state.database.currentDatabase.functions,
    measurementToUnits: state.database.currentDatabase.measurementToUnits,
    measurementsMap: state.database.currentDatabase.measurementsMap,
    measurementTypes: state.database.currentDatabase.measurementTypes,
  };
};

export const DatabaseActivityVariantConstraintItem =
  connect(mapStateToProps)(DatabaseActivityVariantConstraintItemComponent);

export const DatabaseActivityVariantConstraintItemField: React.FC<DatabaseActivityVariantConstraintItemProps> = (
  props: WrappedFieldProps & DatabaseActivityVariantConstraintItemProps,
): JSX.Element => {
  const {
    input: { name },
    meta: { submitFailed, error },
  } = props;

  const constraintError = typeof error === 'string' ? error : undefined;
  return (
    <DatabaseActivityVariantConstraintItem
      error={submitFailed && constraintError}
      name={name}
      readonly={props.readonly}
      constraint={props.constraint}
      index={props.index}
      onConstraintChange={props.onConstraintChange}
      onDelete={props.onDelete}
    />
  );
};
