import React from 'react';
import { useDispatch } from 'react-redux';
import { KreoDialogActions } from 'common/UIKit';
import { CompanySubscriptionModel } from '../../../../account/interfaces/company-subscription-model';
import { RENEW_ARCHIVED_DIALOG } from '../renew-arhived-dialog';
import { useIsCanceledMemo } from './use-is-canceled-memo';


export const useRenewCanceledArhivedSubscriptionEffect = (
  subscription: CompanySubscriptionModel,
  isArhived: boolean,
): void => {
  const isCanceled = useIsCanceledMemo(subscription);
  const dispatch = useDispatch();
  const openRenewArhivedPlan = React.useCallback(() => {
    dispatch(KreoDialogActions.openDialog(RENEW_ARCHIVED_DIALOG));
  }, []);
  return React.useEffect(() => {
    if (isCanceled && isArhived) {
      openRenewArhivedPlan();
    }
  }, []);
};
