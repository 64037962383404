import { AssemblyAfterEffects, MeasureForm, MeasurePanelAssemblyAfterEffects } from '../../interfaces';
import { AssemblyUpdater } from './assembly-form-updater';

export interface Callbacks {
  getForm: () => MeasureForm;
  setForm: (form: MeasureForm) => void;
}

const getAssemblyUpdaterParams = (
  setFrom: (f: MeasureForm) => void,
  afterEffects: MeasurePanelAssemblyAfterEffects,
  setPiaLoad: () => void,
): AssemblyAfterEffects => {
  const { baseParams, params } = afterEffects;
  return {
    baseParams: {
      afterChange: (newForm, id, ...args) => {
        setFrom(newForm);
        baseParams.afterChange(newForm, id, ...args);
      },
      afterDelete: (newForm, id, ...args) => {
        setFrom(newForm);
        baseParams.afterDelete(newForm, id, ...args);
      },
      afterUnitChange: (newForm, id, ...args) => {
        setPiaLoad();
        setFrom(newForm);
        baseParams.afterUnitChange(newForm, id, ...args);
      },
      afterVisibilityChange: (newForm, id, ...args) => {
        setFrom(newForm);
        baseParams.afterVisibilityChange(newForm, id, ...args);
      },
    },
    params: {
      afterDeletePropertyGroup: (form, ...args) => {
        setFrom(form);
        params.afterDeletePropertyGroup(form, ...args);
      },
      afterAddProperty: (form, ...args) => {
        setFrom(form);
        params.afterAddProperty(form, ...args);
      },
      afterDeleteItem: (form, ...args) => {
        setFrom(form);
        params.afterDeleteItem(form, ...args);
      },
      afterAddItem: (form, ...args) => {
        setFrom(form);
        params.afterAddItem(form, ...args);
      },
      afterAddAssemblyForm: (form, ...args) => {
        setFrom(form);
        params.afterAddAssemblyForm(form, ...args);
      },
      afterDeleteAssembly: (form, id) => {
        setFrom(form);
        params.afterDeleteAssembly(form, id);
      },
      afterChangeFormula: (form, ...args) => {
        setPiaLoad();
        setFrom(form);
        params.afterChangeFormula(form, ...args);
      },
    },
  };
};

export const getAssemblyUpdater = (
  callbacks: Callbacks,
  afterEffects: MeasurePanelAssemblyAfterEffects,
  setPiaLoad: () => void,
): AssemblyUpdater => {
  const assemblyParams = getAssemblyUpdaterParams(callbacks.setForm, afterEffects, setPiaLoad);
  return new AssemblyUpdater(
    {
      getForm: callbacks.getForm,
      afterEffects: assemblyParams.baseParams,
    },
    assemblyParams.params,
  );
};
