import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import {
  Checkbox,
  KreoButton,
  KreoIconCopyClipboard,
} from 'common/UIKit';
import { ClassificationActions } from '../../actions/creators/classification';
import { RevitTreeLevel } from '../../enums/revit-tree-level';
import { ClassificationRevitTreeItemButtonsContainer } from '../classification-revit-tree-item-buttons-container';
import {
  ClassificationRevitTreeItemContainer,
} from '../classification-revit-tree-item-container/classification-revit-tree-item-container';
import { ClassificationRevitTreeItemRoundedButton } from '../classification-revit-tree-item-rounded-button';
import { ClassificationEditUniclassButton } from '../classificiation-edit-uniclass-button';
import { RevitTreeBaseItemBodyContent } from '../revit-tree-base-item-body-content';

interface DispatchProps {
  onExpand: () => void;
  onAssign: () => void;
  onIsolate: () => void;
  onOldAssignment: () => void;
  onCopyButtonClick: () => void;
  onItemSelect: (value: boolean) => void;
}

interface StateProps {
  selected: boolean;
  name: string;
  level: RevitTreeLevel;
  start: number;
  end: number;
  hasSelectedChildren: boolean;
  selectedCount: number;
}

interface OwnProps {
  buttonName: string;
  index: number;
  minimumLevel: RevitTreeLevel;
  isIsolatedTree: boolean;
  onHover: (start: number, end: number) => void;
  onFocus: (start: number, end: number) => void;
}

interface Props extends OwnProps, DispatchProps, StateProps { }

class ClassificationRevitTreeItemComponent extends React.PureComponent<Props> {
  private hovered: boolean = false;

  public componentWillUnmount(): void {
    if (this.hovered) {
      this.props.onHover(0, 0);
    }
  }

  public render(): React.ReactNode {
    const {
      index,
      selected,
      start,
      end,
      minimumLevel,
      isIsolatedTree,
      level,
      hasSelectedChildren,
      name,
    } = this.props;
    const isElementType = level === RevitTreeLevel.ElementType;

    return (
      <ClassificationRevitTreeItemContainer
        index={index}
        isTreeIsolated={isIsolatedTree}
        engineIdsEnd={end}
        engineIdsStart={start}
        selected={selected}
        minimumLevelInTree={minimumLevel}
        onHover={this.props.onHover}
        onFocus={this.props.onFocus}
        isElementType={level === RevitTreeLevel.ElementType}
      >
        <Checkbox
          checked={selected}
          onChange={this.onChangeSelectStatus}
          indeterminate={!selected && hasSelectedChildren}
          color='blue'
          size='large'
        />
        <RevitTreeBaseItemBodyContent title={name} count={isElementType ? end - start : null} />
        <ClassificationRevitTreeItemButtonsContainer
          onIsolateClick={this.onIsolate}
          showIsolationButton={!isElementType}
        >
          <ClassificationRevitTreeItemRoundedButton
            tootlipText='Copy Classification'
            onClick={this.onCopyButtonClick}
            controlName='copy-classification'
          >
            <KreoIconCopyClipboard />
          </ClassificationRevitTreeItemRoundedButton>
          {this.renderEditButtons()}
        </ClassificationRevitTreeItemButtonsContainer>
      </ClassificationRevitTreeItemContainer>
    );
  }

  private renderEditButtons(): React.ReactNode {
    if (this.props.selectedCount > 1) {
      return null;
    }
    return (
      <React.Fragment>
        <ClassificationEditUniclassButton onClick={this.onUniclassAssignmentButtonClick}>
          Edit
        </ClassificationEditUniclassButton>
        <KreoButton size='medium' key='0' mode='action' rounded={true} onClick={this.onClickEdit}>
          Edit
        </KreoButton>
      </React.Fragment>
    );
  }

  @autobind
  private onChangeSelectStatus(value: boolean, event: React.MouseEvent<HTMLLabelElement>): void {
    event.stopPropagation();
    this.props.onItemSelect(value);
  }

  @autobind
  private onCopyButtonClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.props.onCopyButtonClick();
  }

  @autobind
  private onIsolate(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.props.onFocus(this.props.start, this.props.end);
    this.props.onIsolate();
  }

  @autobind
  private onClickEdit(e: React.MouseEvent<HTMLButtonElement>): void {
    e.stopPropagation();
    this.props.onFocus(this.props.start, this.props.end);
    if (this.props.level === RevitTreeLevel.ElementType) {
      this.props.onAssign();
    }
  }

  @autobind
  private onUniclassAssignmentButtonClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    if (this.props.level === RevitTreeLevel.ElementType) {
      this.props.onOldAssignment();
    }
  }
}

const mapStateToProps = (state: State, { index, isIsolatedTree }: OwnProps): StateProps => {
  const item = isIsolatedTree ?
    state.classification.modelBrowserFiltered[index]
    : state.classification.modelTree[index];
  const [start, end] = item.engineIds;
  return {
    name: item.name,
    level: item.level,
    start,
    end,
    selected: item.selected,
    hasSelectedChildren: item.hasSelectedChildren,
    selectedCount: state.classification.selectedElementTypesCount,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  return {
    onExpand: () => dispatch(ClassificationActions.toggleCollapseExpandStatus(props.index)),
    onAssign: () => dispatch(ClassificationActions.toAssignment(props.index)),
    onIsolate: () => dispatch(ClassificationActions.isolateNode(props.index)),
    onOldAssignment: () => dispatch(ClassificationActions.toOldAssignment(props.index)),
    onCopyButtonClick: () => dispatch(ClassificationActions.copyClassification(props.index)),
    onItemSelect: value => dispatch(ClassificationActions.onSelect(props.index, value)),
  };
};

const connector = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps);
export const ClassificationRevitTreeItemEdit = connector(ClassificationRevitTreeItemComponent);
