import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { HeaderContext } from 'common/enums/header-context';
import { PageHeader } from 'common/interfaces/page-header';
import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { AccountSelectors } from '../../../units/account/selectors';
import { SubscriptionPlanListingModel } from '../../../units/subscription/interfaces/subscription-plan-listing-model';
import { UpgradeSubscriptionPage } from '../../../units/subscription/pages';

interface StateProps {
  plansModel: SubscriptionPlanListingModel | null;
  subscription: any;
}

interface Props extends StateProps {
  urls: any;
}

const UpgradePageRouterComponent = ({ urls, plansModel, subscription }: Props): JSX.Element => {
  const billingHeader: PageHeader = useMemo(() => ({
    context: HeaderContext.BillingPages,
    backUrl: AppUrls.products.url(),
  }), []);
  const isFreePlanEnable = plansModel && plansModel.plans
    .find(x => x.variants.find(v => v.id === subscription.planId))?.isFree;

  return isFreePlanEnable
    ? (
      <Route
        path={urls.billing.upgradeSubscription.path}
        exact={true}
        metaTitle="Upgrade"
        component={UpgradeSubscriptionPage}
        header={billingHeader}
      />
    )
    : null;
};

const mapStateToProps = (state: State): StateProps => {
  return {
    plansModel: state.account.subscriptionPlans,
    subscription: AccountSelectors.currentSubscription(state),
  };
};

export const UpgradePageRouter = connect(mapStateToProps)(UpgradePageRouterComponent);
