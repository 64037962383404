import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';

import './timestamp.scss';

interface TimestampOwnProps {
  currentMoment: number;
  nextCurrentMoment: number | null;
}

interface TimestampStateProps {
  projectStartDate: Date | null;
}

interface TimestampProps extends TimestampOwnProps, TimestampStateProps {}

const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const msInDay = 1000 * 60 * 60 * 24;

class TimestampComponent extends React.Component<TimestampProps> {
  public render(): React.ReactNode {
    if (this.props.projectStartDate === null) {
      return null;
    }

    const containerClassName = classNames(
      'timestamp',
      {
        next: this.props.nextCurrentMoment !== null,
      });

    const actualCurrentMoment = this.props.nextCurrentMoment !== null
      ? this.props.nextCurrentMoment
      : this.props.currentMoment;

    const currentDate = new Date(this.props.projectStartDate.getTime() + actualCurrentMoment * msInDay);
    const timestamp = this.formatDate(currentDate);

    return (
      <div
        className={containerClassName}
      >
        <div className='timestamp__content'>
          {timestamp}
        </div>
      </div>
    );
  }

  private formatDate(date: Date): string {
    const weekDay = date.getDay();
    const monthDay = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const weekDayName = dayNames[weekDay];
    const monthDayName = monthDay >= 10 ? monthDay.toString() : ` ${monthDay}`;
    const monthName = monthNames[date.getMonth()];
    const hours12 = hours % 12 || 12;
    const hoursPadded = hours12 >= 10 ? hours12.toString() : ` ${hours12}`;
    const minutes2digit = minutes >= 10 ? minutes.toString() : `0${minutes}`;
    const amPm = hours < 12 ? 'AM' : 'PM';

    return `${weekDayName} ${monthDayName} ${monthName} ${year} ${hoursPadded}:${minutes2digit} ${amPm}`;
  }
}


const mapStateToProps = (state: State): TimestampStateProps => {
  return {
    projectStartDate: state.fourDVisualisation.projectStartDate,
  };
};

export const Timestamp = connect(mapStateToProps)(TimestampComponent);
