import { Switcher, Text, Waves } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { ElementTooltip } from 'common/components/tooltip/element-tooltip';
import { ColorsName } from 'common/enums/kreo-colors';
import { Styled } from './styled';

interface Props {
  toggle: (newValue: boolean) => void;
  label: string;
  checked: boolean;
  disabled?: boolean;
  tooltip?: string;
  disabledTooltip?: string;
  id?: string;
}

const DrawingsSettingsSwitcherWithLabelComponent: React.FC<Props> = ({
  toggle,
  label,
  checked,
  disabled,
  tooltip,
  disabledTooltip,
  id,
}) => {
  const toggleSwitcher = useCallback(() => toggle(!checked), [toggle, checked]);
  const currentTooltip = disabled ? disabledTooltip : tooltip;
  return (
    <ElementTooltip
      disabled={!currentTooltip}
      text={currentTooltip}
      position='top'
      tooltipWidth={260}
      wordBreakAll={true}
    >
      <Styled.Container
        id={id}
        onClick={disabled ? undefined : toggleSwitcher}
      >
        <Text
          fontSize={14}
        >
          {label}
        </Text>
        <Switcher
          checked={checked}
          size='m'
          disabled={disabled}
        />
        <Waves color={ColorsName.turquoise} />
      </Styled.Container>
    </ElementTooltip>
  );
};

export const DrawingsSettingsSwitcherWithLabel = React.memo(DrawingsSettingsSwitcherWithLabelComponent);
