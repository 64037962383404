import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { AssignedPia } from '2d/interfaces';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import {
  useElementAndGroupsOperationContext,
} from 'common/components/drawings/drawings-contexts';
import { DrawingsGeometryGroup, DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import { QuickSearchInput } from 'common/components/quick-search';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { useCollapse, useGroups, useMoveToHandler, useSelectGroup } from '../hooks';
import { DialogContentGroup } from './dialog-content-group';
import { Header } from './header';
import { Styled } from './styled';


interface StateProps {
  selectedGroupIds: string[];
  selectedInstaceIds: string[];
  geometries: Record<string, DrawingsGeometryInstance>;
  groups: DrawingsGeometryGroup[];
  assignPia: Record<string, AssignedPia>;
  filteredInstancesIds: string[];
}

interface DispatchProps {
  onClose: () => void;
}

interface Props extends StateProps, DispatchProps {

}

const MoveToDialogContentComponent: React.FC<Props> = ({
  selectedGroupIds,
  selectedInstaceIds,
  groups,
  onClose,
  geometries,
  assignPia,
  filteredInstancesIds,
}) => {
  const { collapsedGroupStatus, toggleCollapse  } = useCollapse();
  const { moveToGroupWithUndoRedo } = useElementAndGroupsOperationContext();

  const [ searchQuery, setSearchQuery ] = useState<string>('');
  const { groups: groupsToShow, groupsPiaStatuses } = useGroups(
    selectedGroupIds,
    groups,
    collapsedGroupStatus,
    searchQuery,
    assignPia,
    filteredInstancesIds,
  );
  const { selectedGroupId, selectedGroupName, setSelectedGroupId } = useSelectGroup(groups);
  const moveToCallback = useCallback((groupId: string, groupsIds: string[], instancesIds: string[]) => {
    if (groupsIds.length || instancesIds.length) {
      moveToGroupWithUndoRedo(groupId, groupsIds, instancesIds);
    }
    onClose();
  }, [selectedInstaceIds, selectedGroupIds, selectedGroupId, moveToGroupWithUndoRedo]);

  const moveToHandler = useMoveToHandler(
    geometries,
    selectedGroupIds,
    selectedInstaceIds,
    groups,
    selectedGroupId,
    moveToCallback,
  );

  const setQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  return (
    <Styled.Container>
      <Header
        selectedGroupName={selectedGroupName}
        onMoveTo={moveToHandler}
      />
      <QuickSearchInput
        placeholder={'Start typing...'}
        isQuickSearchVisible={true}
        setQuickSearchValue={setQuery}
        tooltipText={'Clear'}
      />
      <Styled.Groups>
        {
          groupsToShow.map((group) => {
            const { hasAssignedPia, hasInheritedPia } = groupsPiaStatuses[group.id];
            return (
              <DialogContentGroup
                key={group.id}
                group={group}
                collapsed={collapsedGroupStatus[group.id]}
                selected={selectedGroupId === group.id}
                toggleCollapse={toggleCollapse}
                onSelect={setSelectedGroupId}
                hasAssignedPia={hasAssignedPia}
                hasInheritedPia={hasInheritedPia}
              />
            );
          })
        }
      </Styled.Groups>
    </Styled.Container>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    selectedGroupIds: state.drawings.selectGeometryGroup,
    selectedInstaceIds: state.drawings.selectedInstances,
    groups: state.drawings.drawingGeometryGroups,
    geometries: state.drawings.aiAnnotation.geometry,
    assignPia: state.twoD.assignPia,
    filteredInstancesIds: state.drawings.filteredElementIds,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onClose: () => {
      dispatch(KreoDialogActions.closeDialog(DrawingDialogs.MOVE_TO_DIALOG));
    },
  };
}

export const MoveToDialogContent = connect(mapStateToProps, mapDispatchToProps)(MoveToDialogContentComponent);
