import { OptionInfo } from 'common/components/selection-menus/interfaces';
import { BillingPeriodUnit } from '../../../../units/subscription/enums/billing-period-unit';

export const UPGRADE_PRO_PLAN_CONSTANTS = {
  UPGRADE_PLAN_DIALOG: 'UPGRADE_PLAN_DIALOG',
  PLAN_GROUP_NAME: 'Pro',
  SUBMIT_TEXT: 'Continue',
  TEXT_HEADER: 'Upgrade to Pro',
  HEADER_DESCRIPTION: `Get access to full range of Kreo features to speed up your workflow at maximum capacity`,
};

export const FeatureOptions: Array<OptionInfo<string>> = [
  {
    name: 'Team Members',
    value: `Control of roles and permissions. Unlimited free guest accounts`,
  },
  {
    name: 'Comments',
    value: `Discuss real-time with teammates in-app, share thoughts,
    leave notes, tag colleagues. Add endless comments to drawings, reports, and projects`,
  },
  {
    name: 'Annotation Tools',
    value: `Add keys to your drawings while exporting them to PDF,
    leave suggestions and comments for the team or make hints and save important notes for later right in the app`,
  },
  {
    name: 'Compare Drawings',
    value: `Compare different drawings with any from your current project,
    which is useful while working with a project’s revisions`,
  },
  {
    name: 'Properties, Items, Assemblies',
    value: `Create accurate and user-friendly entities once, store and apply them in any of your projects.
    Use all this data to prepare your final report right in the app`,
  },
  {
    name: 'Drawing Legend',
    value: `Visualize measurement data from your drawings and add keys to them while exporting your drawings to PDF`,
  },
  {
    name: 'Export to PDF',
    value: `Save final versions of your drawings into PDF format with all the measurements,
    modifications and improvements being preserved`,
  },
  {
    name: 'Templates for Projects',
    value: `Create templates for projects to reuse them in your future projects`,
  },
  {
    name: 'Templates for Groups',
    value: `Group and structure your measurements in the way you need and reuse the previously
    created templates in any of your next projects`,
  },
  {
    name: 'Import Excel files',
    value: `Import your Excel files to Kreo Software to continue working on them right in the app`,
  },
  {
    name: 'Auto Measure',
    value: `Utilize AI to identify and categorize elements in your drawings within minutes of launching the tool`,
  },
  {
    name: 'Auto Count',
    value: `Count all geometry and text on your drawings similar to the selected one automatically`,
  },
  {
    name: 'Optimize Big Files',
    value: `Boost file performance in-app with Kreo Software; it rasterizes large files for faster operations`,
  },
  {
    name: 'File Formats Supported',
    value: `PDF, DWG, DXF, DWF, DGN, PNG, TIFF, JPG, JPEG, BMP, EMF, GIF`,
  },
];

export const UpgradePeriodOptions: Array<OptionInfo<BillingPeriodUnit>> = [
  {
    name: 'Annually billing',
    value: BillingPeriodUnit.Year,
  },
  {
    name: 'Monthly billing',
    value: BillingPeriodUnit.Month,
  },
];
