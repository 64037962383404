import { ItemGroupFormData } from '../../assembly-panel/item-form-updater';
import { parseItemFormProperties } from './parse-item-from-properties';

export const getAllAddedPropertiesNames = (itemGroupForms: Array<Record<string, ItemGroupFormData>>): string[] => {
  const result = [];
  for (const itemGroupForm of itemGroupForms) {
    parseItemFormProperties(itemGroupForm, result);
  }

  return result;
};
