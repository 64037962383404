import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { SidePanelContainer } from 'unit-2d-database/components/side-panel/components/side-panel-container';
import { TwoDNotificationsActions } from 'unit-2d-notification/store-slice';
import { TwoDNotificationsList } from '../notification-list';
import { HeaderNotification } from '../notification-list/header-notification';
import { Styled } from './styled';

interface StateProps {
  isPanelOpen: boolean;
}

interface DispatchProps {
  closePanel: () => void;
}

interface Props extends StateProps, DispatchProps {
}

const NotificationPanelComponent: React.FC<Props> = ({
  isPanelOpen,
  closePanel,
}) => {
  return (
    <Styled.SidePanelWrapper>
      <SidePanelContainer
        isOpen={isPanelOpen}
        onClose={closePanel}
        withSidePanel={false}
        withBlur={false}
        isClickOutsideActive={true}
      >
        <HeaderNotification />
        {isPanelOpen && <TwoDNotificationsList />}
      </SidePanelContainer>
    </Styled.SidePanelWrapper>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    isPanelOpen: state.twoDNotifications.isPanelOpen,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    closePanel: () => dispatch(TwoDNotificationsActions.closePanel()),
  };
};

export const NotificationPanel = connect(mapStateToProps, mapDispatchToProps)(NotificationPanelComponent);

