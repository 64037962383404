import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';
import { CheckboxWithTextStyled } from 'common/components/checkbox-with-text';


const SEARCH_HEIGHT = 40;

const ModalWrapper =styled.div`
  z-index: 9999;
  > div {
    backdrop-filter: none;
  }
`;

const Container = styled.div`
  display: flex;
  width: 430px;
  height: 90vh;
  box-shadow: ${p => p.theme.shadow.xl};
  border-radius: 20px;
  flex-direction: column;
  background-color: ${p => p.theme.color.backgroundRush};
  box-sizing: border-box;
  animation: ${Constants.Animations.slowTopAppear()} ${p => p.theme.duration.s} linear;
  padding: 20px;
  *,& {
    outline: none;
  }

  & ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.gray};
  }
  ${CheckboxWithTextStyled.Container} {
    margin-top: 15px;
  }
`;

const PropertyName = styled.div`
  svg {
    min-width: 20px;
    margin-right: 15px;
    fill: ${p => p.theme.color.gray};
  }

  overflow: hidden;
  margin-right: 5px;
  align-items: center;
  display: flex;
  color: ${p => p.theme.color.mainFont};
`;

const ApplyContainer = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const BreakdownContainer = styled.div`
  width: 390px;
  height: 100%;
  margin-top: 25px;

  .ag-theme-balham-dark {
    height: calc(100% - ${SEARCH_HEIGHT}px);

    .ag-theme-balham-dark.ag-popup {
      height: auto;
    }

    div[role='row'] {
      > div:first-child {
        div {
          align-items: center;
        }
      }
      > div:nth-child(2) {
        align-items: center;
      }
    }
  }

  .ag-cell-wrapper {
    align-items: center;
  }

  div[role='row'] {
    > div:first-child {
    }
    > div:nth-child(2) {
      align-items: center;
    }
    > div:nth-child(3) {
    }
    > div:nth-child(4) {
    }
  }


  div::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  div::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }

  div::-webkit-scrollbar-track {
    background: ${p => p.theme.color.background};
  }

  .ag-body-horizontal-scroll-viewport {
    overflow-x: hidden;
  }
  .ag-body-horizontal-scroll {
    display: none;
  }

  .ag-center-cols-viewport {
    overflow-x: hidden;
  }
  .ag-header {
    display: none;
  }

  .ag-ltr .ag-row-drag {
    margin-right: 4px !important;
  }
  .ag-group-checkbox {
    position: absolute;
    left: 25px;
    .ag-selection-checkbox {
      margin-right: 0;
    }
  }
  .ag-theme-balham-dark .ag-cell {
    padding-left: 4px;
    padding-right: 0px;
  }

  div[role='row'] {
    display: inline-flex;
    align-items: center;

    > div:first-child {
      position: relative;
      width: auto !important;

      > div {
        align-items: flex-start;
      }
    }
    > div:nth-child(2) {
      position: relative;
      left: auto !important;
      display: inline-flex;
      align-items: flex-start;
      padding-left: 0 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > div:nth-child(3) {
      top: 0;
      left: 160px !important;
      width: 190px !important;
      margin-left: 30px !important;
      align-items: baseline !important;
    }
  }

  .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 16px !important;
  }

  .ag-group-expanded {
    margin-right: 0 !important;
  }
  .ag-group-contracted {
    margin-right: 0 !important;
  }

  .ag-cell-wrapper.ag-row-group-leaf-indent.ag-row-group-indent-0 {
    margin-left: 34px !important;
  }

  .ag-cell-wrapper.ag-cell-expandable.ag-row-group.ag-row-group-indent-0 {
    padding-left: 20px;
  }
  .ag-ltr .ag-row-group-leaf-indent-0 {
    padding-left: 18px;
  }
  .ag-ltr .ag-row-group-indent-1 {
    padding-left: 30px;
  }
  .ag-ltr .ag-row-group-indent-2 {
    padding-left: 40px;
  }
  .ag-ltr .ag-row-group-indent-3 {
    padding-left: 50px;
  }
  .ag-ltr .ag-row-group-indent-4 {
    padding-left: 60px;
  }
  .ag-ltr .ag-row-group-indent-5 {
    padding-left: 70px;
  }
  .ag-ltr .ag-row-group-indent-6 {
    padding-left: 80px;
  }
  .ag-ltr .ag-row-group-indent-7 {
    padding-left: 90px;
  }
  .ag-ltr .ag-row-group-indent-8 {
    padding-left: 100px;
  }
  .ag-ltr .ag-row-group-indent-9 {
    padding-left: 110px;
  }
  .ag-ltr .ag-row-group-indent-10 {
    padding-left: 120px;
  }
  .ag-ltr .ag-row-group-indent-11 {
    padding-left: 130px;
  }
  .ag-ltr .ag-row-group-indent-12 {
    padding-left: 140px;
  }
  .ag-ltr .ag-row-group-indent-13 {
    padding-left: 150px;
  }
  .ag-ltr .ag-row-group-indent-14 {
    padding-left: 160px;
  }
  .ag-ltr .ag-row-group-indent-15 {
    padding-left: 170px;
  }
`;

export const Styled = {
  ModalWrapper,
  Container,
  HeaderContainer,
  PropertyName,
  ApplyContainer,
  BreakdownContainer,
};
