import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { UserAbility } from 'common/ability/user-ability';
import { RoleGroup } from 'common/enums/role-group';

function canByRoleGroup(ability: UserAbility, roleGroup: RoleGroup, operation: Operation): boolean {
  if (roleGroup == null) {
    return true;
  }

  let subject: Subject = null;
  if (roleGroup === RoleGroup.Employee) {
    subject = Subject.Employees;
  } else if (roleGroup === RoleGroup.Guest) {
    subject = Subject.Guests;
  } else {
    subject = Subject.Subcontractors;
  }

  return ability.can(operation, subject);
}

export const PeopleUtil = {
  canByRoleGroup,
};
