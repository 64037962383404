import autobind from 'autobind-decorator';
import * as classNames from 'classnames';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import './index.scss';

import { TitleEditable } from 'common/components/title-editable';
import { KreoButton, KreoIconArrowDown, KreoIconArrowUp, KreoIconBest } from 'common/UIKit';
import { numberUtils } from 'common/utils/number-utils';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { ScenariosData } from '../../interfaces';


interface Props {
  item: ScenariosData.Calculation;
  changeCalculationName: (id: any, name: string) => void;
  deleteCalculation: (calculationId: number) => void;
  activateCalculation: (id: number) => void;
  isScenarioActive: boolean;
  activeCalculation?: ScenariosData.Calculation;
  isBestTotal?: boolean;
  activateScenario: (calculationId: number) => void;
  showShortLevelling: boolean;
  isOwner: boolean;
  isBidPricingCalculation: boolean;
  canUpdate: boolean;
}

const format = (value: React.ReactText): string => numberUtils.getNumeralFormatter(value).format('0,0[.]00');
const formatMoney = (value: React.ReactText): string =>
  numberUtils.getNumeralFormatter(value).format('0,0').replace(/,/g, ' ');

export class CalculationItem extends React.Component<Props> {
  private menu: ItemMenuType[] = [
    {
      name: 'Delete calculation',
      action: this.deleteCalculation,
    },
  ];

  public render(): JSX.Element {
    const {
      item,
      isScenarioActive,
      isBestTotal,
      showShortLevelling,
      isOwner,
      isBidPricingCalculation: isBidPricingCalculation,
      canUpdate,
    } = this.props;
    // menu delete
    this.menu[0].disabled = !isOwner || item.isActivated || isBidPricingCalculation || !canUpdate;

    const className = classNames('calculation-item__info-wrap', {
      'active': item.isActivated,
      'scneario-active': isScenarioActive,
    });

    return (
      <div className='calculation-item'>
        <div className={className} onClick={canUpdate ? this.activateCalculation : null}>
          {isOwner &&
            <ItemMenu
              menu={this.menu}
              buttonSize='small'
              className={'calculation-item__actions-menu'}
              handleClick={this.stopPropagation}
            />
          }
          <div className='calculation-item__info'>
            <div className={'calculation-item__info-name'}>
              <TitleEditable
                label='Name'
                text={item.name}
                onChange={this.changeCalculationName}
                withoutSaveBtn={true}
                sizeSmall={true}
                isEditByIconClick={true}
                className='calculation-item__title-edit'
                canEdit={isOwner && canUpdate}
              />
            </div>
            {this.renderItem('labor_cost', 'labour', formatMoney(item.labor_cost))}
            {this.renderItem('plant_cost', 'plant', formatMoney(item.plant_cost))}
            {this.renderItem('material_cost', 'material', formatMoney(item.material_cost))}
            {this.renderItem('totalPreliminariesOverhead', 'overhead', formatMoney(item.totalPreliminariesOverhead))}
            {this.renderItem(
              'total_cost',
              'total',
              formatMoney(item.total_cost),
              isBestTotal ? (
                <i className={`calculation-item__info-icon`}>
                  <KreoIconBest />
                </i>
              ) : null,
              true,
            )}
            {this.renderItem(
              'resourceLeveling',
              'levelling',
              <span
                className={
                  showShortLevelling
                    ? `calculation-item__info-levelling-hide-data`
                    : ''
                }
              >{`${format(item.maxLabors)} / ${format(
                item.averageLabors,
              )}`}
              </span>,
              <React.Fragment>
                <b
                  className={`calculation-item__info-levelling-value`}
                  data-for={`resourceLeveling_${item.id}`}
                  data-tip=''
                >
                  {format(item.resourceLeveling)}
                </b>
                <ReactTooltip
                  id={`resourceLeveling_${item.id}`}
                  type='light'
                  effect='solid'
                  place='bottom'
                  className={`calculation-item__levelling-tooltip`}
                >
                  <div>
                    Maximum: <span>{format(item.maxLabors)}</span>
                  </div>
                  <div>
                    Average: <span>{format(item.averageLabors)}</span>
                  </div>
                </ReactTooltip>
              </React.Fragment>,
            )}
            {this.renderItem(
              'total_duration',
              'duration',
              item.total_duration.split('.')[0],
            )}
            <div className={'calculation-item__info-actions'}>
              <KreoButton
                caption={isScenarioActive && item.isActivated ? 'Active' : 'Activate'}
                mode={isScenarioActive && item.isActivated ? 'success' : null}
                size='medium'
                onClick={this.activateScenario}
                className='scenario-btn'
                controlName='scenario-activate'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private changeCalculationName(value: string): void {
    this.props.changeCalculationName(this.props.item.id, value);
  }

  @autobind
  private deleteCalculation(): void {
    this.props.deleteCalculation(this.props.item.id);
  }

  @autobind
  private activateCalculation(): void {
    const { item, isScenarioActive, isOwner } = this.props;
    if (isScenarioActive && !item.isActivated) {
      this.props.activateScenario(item.id);
    } else if (!isScenarioActive && !item.isActivated && isOwner) {
      this.props.activateCalculation(item.id);
    }
  }

  @autobind
  private activateScenario(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation();
    const { item, isScenarioActive } = this.props;
    if (!isScenarioActive || (isScenarioActive && !item.isActivated)) {
      this.props.activateScenario(item.id);
    }
  }

  @autobind
  private getArrow(field: keyof ScenariosData.Calculation): JSX.Element {
    const { activeCalculation, item } = this.props;

    if (!activeCalculation || item.isActivated) {
      return null;
    }

    const activeValue = activeCalculation[field]
      ? activeCalculation[field]
      : 0;
    const value = item[field] ? item[field] : 0;

    if (activeValue === value) {
      return null;
    }

    const direction = activeValue < value;
    return (
      <i className={`calculation-item__info-icon`}>
        {!direction ? (
          <KreoIconArrowDown />
        ) : (
          <KreoIconArrowUp />
        )}
      </i>
    );
  }

  @autobind
  private renderItem(
    field: keyof ScenariosData.Calculation,
    className: string,
    value: React.ReactNode,
    element?: JSX.Element,
    hideArrow?: boolean,
  ): JSX.Element {
    return (
      <div className={`calculation-item__info-${className}`}>
        {value}
        {element}
        {!hideArrow && this.getArrow(field)}
      </div>
    );
  }

  private stopPropagation(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
  }
}
