import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { DrawingsAnnotationLegendActions } from '../../actions/creators/drawings-annotation-legend';
import { DrawingsUpdateActions } from '../../actions/creators/update';
import { DrawingChangeOperation } from '../../enums/drawing-change-operation';
import { DrawingChangeSource } from '../../enums/drawing-change-source';

export function useDeleteGroups(): (groupsIds: string[]) => void {
  const dispatch = useDispatch();
  return useCallback(
    (groupsIds) => {
      dispatch(TwoDElementViewActions.updateColumnBeforeDelete(groupsIds));
      dispatch(DrawingsAnnotationLegendActions.deleteGroups(groupsIds));
      const groupChange = {
        operation: DrawingChangeOperation.Delete,
        data: { groups: groupsIds, measurements: [] },
      };
      dispatch(DrawingsUpdateActions.commitUpdates([groupChange], DrawingChangeSource.Groups));
    },
    [dispatch],
  );
}
