import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 60px;
`;

const Icons = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  gap: 15px;

  > svg {
    fill: ${p => p.theme.color.gray};
    width: 20px;
    height: 20px;
    g {
      path {
        stroke: ${p => p.theme.color.gray};
      }
    }
  }
`;

const ButtonsContainer = styled.div`
  min-width: 425px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

  > svg {
    fill: ${p => p.theme.color.background};
    width: 20px;
    height: 20px;
  }
`;

const ButtonTextWrapper = styled.div`
  margin-left: 25px;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  :last-child {
    margin-left: 90px;
  }
  p {
    font: 14;
  }
`;

const MenuContainer = styled.div<{ top: number }>`
  position: absolute;
  right: 45px;
  top: ${p => p.top}px;
  z-index: 1002;
`;

export const Styled = {
  Header,
  Icons,
  ButtonsContainer,
  ButtonTextWrapper,
  MenuContainer,
};
