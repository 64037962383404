import { ExcelFormulaHelper } from 'common/components/excel-table';
import { RowDataStore } from '../cell-data-controllers/report-cell-data-store';
import { isSpecKey, isConfigKey } from '../excel-table-cell-formatter/common';
import { MoveCellTempData } from './interfaces';
import { forEachColumnId, getCellValue } from './utils';

export const setEmptyRows = (
  fromIndex: number,
  offset: number,
  rows: RowDataStore[],
  updatedRows: MoveCellTempData,
): void => {
  const toIndex = fromIndex + offset;
  const slicedRows = rows.slice(fromIndex, toIndex);

  for (const data of slicedRows) {
    forEachColumnId(Object.keys(data))(
      (id) => !isSpecKey(id),
      (columnId) => {
        const rowId = data.id.toString();
        const cellId = ExcelFormulaHelper.getCellLink(null, columnId, rowId);
        const prevValue = getCellValue(data, columnId);
        const isUpdatedRows = typeof(prevValue) !== 'object';

        if (isUpdatedRows) {
          updatedRows.updatedCellsMap[cellId] = {
            rowId,
            columnId,
            value: isConfigKey(columnId) ? null : '',
            prevValue: data[columnId],
          };
        }
      },
    );
  }
};
