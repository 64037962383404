import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { WrappedFieldArrayProps } from 'redux-form';

import { KreoDialogActions } from 'common/UIKit';
import { ActivityResourceVariant } from '../../interfaces/data';
import { PlantVariantModel } from '../../interfaces/resources-data';
import { PlantForm } from '../../interfaces/rest-data';
import { DatabaseEntityNamedHeader } from '../database-entity-named-header';
import { ResourceFieldHelper } from '../resource-selector/resource-field-helper';
import { PlantItem } from './database-activity-plant-item';
import { PlantSelector, RESOURCE_SELECTOR_DIALOG_PLANT } from './database-activity-plant-selector';

export interface PlantsProps {
  readonly: boolean;
  plants: Array<ActivityResourceVariant<PlantVariantModel>>;
  onChange: (plants: Array<ActivityResourceVariant<PlantVariantModel>>) => void;
}
interface DispatchProps {
  close: (name: string) => void;
  open: (name: string) => void;
}

interface Props extends PlantsProps, DispatchProps {}

class PlantsComponent extends React.Component<Props & WrappedFieldArrayProps<PlantForm>> {

  public render(): React.ReactNode {
    return (
      <div className='database-activity-plants'>
        <DatabaseEntityNamedHeader
          name='Plant'
          onAddButtonClick={this.props.readonly ? null : this.openPlantSelector}
        />
        <div className='database-activity-plants__content'>
        {
          this.props.fields.map((fieldKey, index) => {
            return this.props.plants[index] ? (
              <PlantItem
                fieldKey={fieldKey}
                plant={this.props.plants[index]}
                key={fieldKey}
                index={index}
                onQuantityChange={this.onQuantityChange}
                onDelete={this.onDelete}
                readonly={this.props.readonly}
              />
            ) : null;
          })
        }
        </div>
        {
          !this.props.readonly ? (
            <PlantSelector
              selectedPlants={this.props.plants}
              onCancel={this.closePlantSelector}
              onSave={this.onChange}
            />
          ) : null
        }
      </div>
    );
  }

  @autobind
  private onChange(plants: Array<ActivityResourceVariant<PlantVariantModel>>): void {
    ResourceFieldHelper.changeResourcesField(
      this.props.plants,
      plants,
      this.props.fields,
      this.props.onChange,
    );
  }

  @autobind
  private openPlantSelector(): void {
    this.props.open(RESOURCE_SELECTOR_DIALOG_PLANT);
  }

  @autobind
  private closePlantSelector(): void {
    this.props.close(RESOURCE_SELECTOR_DIALOG_PLANT);
  }

  @autobind
  private onQuantityChange(index: number, amount: number): void {
    const result = this.props.plants.slice();
    result[index].amount = amount;
    this.props.onChange(result);
  }

  @autobind
  private onDelete(index: number): void {
    const plants = this.props.plants.slice();
    plants.splice(index, 1);
    this.props.fields.remove(index);
    this.props.onChange(plants);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    close: (name: string) => dispatch(KreoDialogActions.closeDialog(name)),
    open: (name: string) => dispatch(KreoDialogActions.openDialog(name)),
  };
};

export const Plants =
  connect(null, mapDispatchToProps)(PlantsComponent);
