import { ModalWrapper } from '@kreo/kreo-ui-components';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { TwoDReportTemplateActions } from 'unit-2d-report-template/store-slice';
import { CreateProjectReportTemplate, ReportTemplateInfo } from '../../interfaces';
import { InfoReportTemplateTable } from '../2d-report-template-table';
import { useHandleApplyTemplateCallback, useHideTemplateIdsMemo } from './hooks';
import { Styled } from './styled';

export const APPLY_TEMPLATE_DIALOG = 'APPLY_TEMPLATE_DIALOG';

interface StateProps {
  projectId: number;
  templates: ReportTemplateInfo[];
}

interface DispatchProps {
  closeDialog: () => void;
  applyTemplate: (payload: CreateProjectReportTemplate) => void;
}

interface Props extends StateProps, DispatchProps {

}

const ApplyTemplateDialogComponent: React.FC<Props> = ({
  projectId,
  templates,
  closeDialog,
  applyTemplate,
}) => {
  const handleApplyTemplate = useHandleApplyTemplateCallback(projectId, applyTemplate, closeDialog);
  const hideTemplateIds = useHideTemplateIdsMemo(templates);
  return (
    <DialogWrapper name={APPLY_TEMPLATE_DIALOG}>
      <ModalWrapper onExit={closeDialog}>
        <Styled.Container>
          <InfoReportTemplateTable
            canEdit={false}
            applyTemplate={handleApplyTemplate}
            hideTemplateIds={hideTemplateIds}
          />
        </Styled.Container>
      </ModalWrapper>
    </DialogWrapper>);
};

const mapStateToProps = (state: State): StateProps => {
  const project = state.projects.currentProject;
  const projectId = project && project.id;

  return {
    templates: state.twoDReportTemplate.projectReportTemplates.templates,
    projectId,
  };
};

const mapDispatchProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(APPLY_TEMPLATE_DIALOG)),
    applyTemplate: (payload) => dispatch(TwoDReportTemplateActions.applyProjectReportTemplate(payload)),
  };
};

export const ApplyTemplateDialog = connect(mapStateToProps, mapDispatchProps)(ApplyTemplateDialogComponent);
