import { Icons, MenuItem } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { ColorsName } from 'common/enums/kreo-colors';


export const VideoTutorialsButton: React.FC = () => {
  return (
    <a
      href='https://www.youtube.com/watch?v=OkkEj1dQTcM&list=PL3ykr_5w7hUPzL7xi7dn4feRXZrU9Mgtd'
      target='_blank'
    >
      <MenuItem
        Icon={Icons.Videos2D}
        text="Video Tutorials"
        onClick={ConstantFunctions.doNothing}
        fontSize={14}
        size='m'
        textColor={ColorsName.mainFont}
        withBorder={true}
        withoutWaves={true}
      />
    </a>
  );
};
