import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';

import { Notification, NotificationProcessor } from 'common/interfaces/realtime-messages';
import { AppUrls } from '../../../../routes/app-urls';
import { CommonNotificationProcessor } from '../../../notifications/common-notification-processor';
import { ScenariosActions } from '../../../scenarios/actions';
import { ActivityAssignmentActions } from '../../actions/creators/activity-assignment';
import { ClassificationActions } from '../../actions/creators/classification';
import { MeasurementsActions } from '../../actions/creators/measurements';
import { ValidationActions } from '../../actions/creators/validation';
import { ValidationStep } from '../../enums/validation-step';
import { ValidationStepName } from '../../enums/validation-step-name';
import { ValidationStepState } from '../../enums/validation-step-state';
import { ProjectSelectors } from '../../selectors';
import { ValidationNotificationKeys } from '../keys/validation';

function userIsNotOnValidationStep(projectId: number, validationStep: ValidationStepName): boolean {
  const stepUrl = AppUrls.plan.project.validation.step.url({
    projectId: projectId.toString(),
    step: validationStep,
  });
  return !window.location.pathname.startsWith(stepUrl);
}


function* setProjectValidationState({ data: validationState }: Notification): SagaIterator {
  try {
    yield put(ValidationActions.setProjectValidationState(validationState));
  } catch (error) {
    console.error(error);
  }
}

function* validationStepChange(notification: Notification): SagaIterator {
  try {
    yield all([
      call(CommonNotificationProcessor.addNotification, notification),
      call(setProjectValidationState, notification),
    ]);
  } catch (error) {
    console.error(error);
  }
}

function* processFailureMessage(notification: Notification): SagaIterator {
  try {
    yield put(ActivityAssignmentActions.dropState());
    yield all([
      call(setProjectValidationState, notification),
      call(CommonNotificationProcessor.addErrorAlert, notification),
    ]);
  } catch (error) {
    console.error(error);
  }
}

function* classificationPassed(notification: Notification): SagaIterator {
  try {
    yield call(setProjectValidationState, notification);
    if (notification.isAlert
      && userIsNotOnValidationStep(notification.data.documentId, ValidationStepName.Classification)) {
      yield call(CommonNotificationProcessor.addInfoAlert, notification);
    }
  } catch (error) {
    console.error(error);
  }
}

function* activityAssignmentPassed(notification: Notification): SagaIterator {
  try {
    yield call(setProjectValidationState, notification);
    if (yield select(ProjectSelectors.projectIsCurrent, notification.data.documentId)) {
      yield put(ScenariosActions.getActiveScenarioByProject(notification.data.documentId));
    }
    if (notification.isAlert
      && userIsNotOnValidationStep(notification.data.documentId, ValidationStepName.ActivityAssignment)) {
      yield call(CommonNotificationProcessor.addInfoAlert, notification);
    }
  } catch (error) {
    console.error(error);
  }
}

function* measurementPassed(notification: Notification): SagaIterator {
  try {
    yield call(setProjectValidationState, notification);
    yield put(ActivityAssignmentActions.dropState());
    if (notification.isAlert
        && userIsNotOnValidationStep(notification.data.documentId, ValidationStepName.Measurements)) {
      yield call(CommonNotificationProcessor.addInfoAlert, notification);
    }
  } catch (error) {
    console.error(error);
  }
}

function* completed(notification: Notification): SagaIterator {
  try {
    yield call(setProjectValidationState, notification);
    if (yield select(ProjectSelectors.projectIsCurrent, notification.data.documentId)) {
      yield put(ScenariosActions.getActiveScenarioByProject(notification.data.documentId));
    }
    yield call(CommonNotificationProcessor.addInfoAlert, notification);
  } catch (error) {
    console.error(error);
  }
}

function* stateChanged(notification: Notification): SagaIterator {
  try {
    yield call(setProjectValidationState, notification);
    const isCurrentProject = yield select(ProjectSelectors.projectIsCurrent, notification.data.documentId);
    if (isCurrentProject && notification.data.stepState === ValidationStepState.Calculating) {
      switch (notification.data.validationStep) {
        case ValidationStep.Classification:
          yield put(ClassificationActions.dropState());
          break;
        case ValidationStep.ActivityAssignment:
          yield put(ActivityAssignmentActions.dropState());
          break;
        case ValidationStep.Measurements:
          yield put(MeasurementsActions.dropState());
          break;
        default:
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export const ValidationNotificationsProcessor: NotificationProcessor = {
  [ValidationNotificationKeys.ClassificationStarted]: setProjectValidationState,
  [ValidationNotificationKeys.ClassificationPassed]: classificationPassed,
  [ValidationNotificationKeys.ClassificationFailed]: processFailureMessage,
  [ValidationNotificationKeys.ActivityAssignmentPassed]: activityAssignmentPassed,
  [ValidationNotificationKeys.ActivityAssignmentFailed]: processFailureMessage,
  [ValidationNotificationKeys.MeasurementPassed]: measurementPassed,
  [ValidationNotificationKeys.MeasurementFailed]: processFailureMessage,
  [ValidationNotificationKeys.Completed]: completed,
  [ValidationNotificationKeys.Failed]: CommonNotificationProcessor.addErrorAlert,
  [ValidationNotificationKeys.StepApproved]: validationStepChange,
  [ValidationNotificationKeys.StepReverted]: validationStepChange,
  [ValidationNotificationKeys.ClassificationEdited]: CommonNotificationProcessor.addNotification,
  [ValidationNotificationKeys.ActivityAssignmentEdited]: CommonNotificationProcessor.addNotification,
  [ValidationNotificationKeys.MeasurementEdited]: CommonNotificationProcessor.addNotification,
  [ValidationNotificationKeys.StateChanged]: stateChanged,
  [ValidationNotificationKeys.ClassificationStepDissapproved]: CommonNotificationProcessor.addNotification,
};
