import { NotificationRelatedArea } from 'common/enums/notification-related-area';
import { StringDictionary } from 'common/interfaces/dictionary';

interface ActivityTypeInfo {
  text: string;
  styles: React.CSSProperties;
}

export const activityTypes: StringDictionary<ActivityTypeInfo> = {
  [NotificationRelatedArea.Management]: {
    text: 'Management',
    styles: {
      color: '#598F84',
      backgroundColor: '#E1F5F1',
    },
  },
  [NotificationRelatedArea.Scenarios]: {
    text: 'Scenarios',
    styles: {
      color: '#5D79B3',
      backgroundColor: '#F0F6FF',
    },
  },
  [NotificationRelatedArea.Database]: {
    text: 'Database',
    styles: {
      color: '#6D80A1',
      backgroundColor: '#F2F4F7',
    },
  },
  [NotificationRelatedArea.Comment]: {
    text: 'Comments',
    styles: {
      color: '#538299',
      backgroundColor: '#EBF7FC',
    },
  },
  [NotificationRelatedArea.Validation]: {
    text: 'Validation',
    styles: {
      color: '#D19745',
      backgroundColor: '#FFF5E8',
    },
  },
  [NotificationRelatedArea.BidPricing]: {
    text: 'Bid Pricing',
    styles: {
      color: '#638C5A',
      backgroundColor: '#E5FAE1',
    },
  },
};
