// eslint-disable-next-line import/no-unresolved
import { FillOperationParams } from 'ag-grid-community/dist/lib/entities/gridOptions';

import { FormulaCellFill } from './formula-cell-fill';
import { IterateCellFill } from './iterate-fill-handler';
import { SequenceCellFill } from './sequence-cell-fill';

const cellFillHelpers = [
  FormulaCellFill,
  IterateCellFill,
  SequenceCellFill,
];

const FILL_OPERATION_VALUE = 'FILL_OPERATION_VALUE';

const fillOperation = (params: FillOperationParams): any => {
  for (const helper of cellFillHelpers) {
    const values = helper.getReferenceValues(params);
    if (values) {
      const data = helper.getNextValue(params, values);
      return { data, type: FILL_OPERATION_VALUE };
    }
  }

  return null;
};

export const CellFillHandler = {
  fillOperation,
  FILL_OPERATION_VALUE,
};
