import { useEffect, useMemo } from 'react';
import { ThreeDSettings } from 'persisted-storage/interfaces/state';
import { ContextObserverWithPrevious, DrawingContextObserverWithPrev } from '../../drawings-contexts';
import { useThreeDSettings } from '../../hooks/settings/use-three-d-settings';

export function useSettingsObserver(): ContextObserverWithPrevious<ThreeDSettings> {
  const settings = useThreeDSettings();
  const settingsObserver = useMemo(
    () => new DrawingContextObserverWithPrev<ThreeDSettings>(settings),
    [],
  );

  useEffect(() => {
    settingsObserver.updateContext(settings);
  }, [settings]);
  return settingsObserver;
}
