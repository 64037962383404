import { arrayUtils } from 'common/utils/array-utils';
import { PreparedSearchQuery } from 'common/utils/string-utils';
import { QtoLeftPanelConstants } from '../../../units/projects/components/quantity-take-off-left-panel/constants';
import { QtoRecord } from '../../../units/projects/components/quantity-take-off-left-panel/interfaces';
import {
  isAdditionalProperties,
} from '../../../units/projects/components/quantity-take-off-left-panel/is-additional-properties';
import { isEFPrefix } from '../../../units/projects/components/quantity-take-off-left-panel/is-ef-prefix';
import {
  GraphStorageRecordsConfig,
  RecordConfig,
} from '../../../units/projects/interfaces/graph-storage-records-config';
import { SharedParameterKeyHelper } from '../../../units/projects/utils/shared-parameter-key-helper';
import { FilterOption, FilterValue } from '../filter-select/interfaces';
import { CUSTOM_FILTER, EMPTY_VALUE, ENGINE_FILTER_KEY, REPORT_FILTER_KEY } from './constants';
import { CustomOptionConfig } from './custom-filter-map';
import { FilterByCustomFilter } from './filter-by-custom-filter';
import { FilterByEngineFilter } from './filter-by-engine-filter';
import { FilterByReportData } from './filter-by-report-data';
import { FilterConfig, TreeFilterPanelState } from './interfaces';

const QTO_FILTERS = 'qto_filters';

interface FilterOptionsConfig {
  options: FilterValue[];
  optionsRender?: (option: FilterOption, query: PreparedSearchQuery, isSelected: boolean) => React.ReactNode;
}

type FilterOptionMap = Record<string, FilterOptionsConfig>;

type ExistFilterOption = Record<string, string[]>;

export const reportFilterConfig = {
  key: REPORT_FILTER_KEY,
  name: 'Report Filters',
  options: [
    {
      name: 'Duplicate Elements',
      value: FilterByReportData.DuplicateElements,
    },
    {
      name: 'Unused Elements',
      value: FilterByReportData.UnusedElements,
    },
  ],
};

export const engineFilterConfig = {
  key: ENGINE_FILTER_KEY,
  name: 'Engine Filter',
  options: [
    {
      name: 'Use Engine Filter',
      value: FilterByEngineFilter.UseEngineFilter,
    },
  ],
};

export const customFilterConfig = {
  key: CUSTOM_FILTER,
  name: 'Custom Filter',
  options: [
    {
      name: 'Use Active Custom Filter',
      value: FilterByCustomFilter.UseActiveCustomFilter,
    },
  ],
};

const getFilterKeys = (recordConfig: GraphStorageRecordsConfig): string[] => {
  return Object.keys(recordConfig)
    .filter(k =>
      k.startsWith(QtoLeftPanelConstants.FILTER_PREFIX)
      && (recordConfig[k] as RecordConfig).ui_groups[QTO_FILTERS],
    )
    .sort((a, b) => {
      const configA = (recordConfig[a] as RecordConfig);
      const configB = (recordConfig[b] as RecordConfig);
      if (!configA || !configB) {
        return 1;
      }
      const aValue = configA.ui_groups[QTO_FILTERS];
      const bValue = configB.ui_groups[QTO_FILTERS];
      return aValue - bValue;
    });
};

const getOption = (data: string): FilterValue => ({ value: data, name: data });

const getDataOptions = (
  data: Record<string, Array<string | number>>,
  filterKey: string[],
  result: ExistFilterOption,
): void => {
  for (const key of filterKey) {
    const dataKeyValue = data[key];
    if (dataKeyValue && dataKeyValue.length && !isEFPrefix(key)) {
      result[key] = result[key] || [];
      arrayUtils.extendArray(result[key], dataKeyValue as string[]);
    }
  }
};

const mapFilterOptions = (
  records: QtoRecord[],
  recordConfig: GraphStorageRecordsConfig,
): FilterOptionMap => {
  const filterKeys = getFilterKeys(recordConfig);
  const resultFilterOptionsMap: FilterOptionMap = {};
  const filterOptionData: ExistFilterOption = {};
  for (const data of records.values()) {
    getDataOptions(data.props, filterKeys, filterOptionData);
  }
  for (const [key, value] of Object.entries(filterOptionData).values()) {
    const uniqList = arrayUtils.uniq<string>(value);
    uniqList.sort(arrayUtils.sortStringArrayWithEmptyValue);
    const customOption = CustomOptionConfig[key];
    const customOptionRender = customOption ? customOption.optionsRender : undefined;
    resultFilterOptionsMap[key] = {
      options: uniqList.map(getOption),
      optionsRender: customOptionRender,
    };
  }
  return resultFilterOptionsMap;
};


const isOptionsValid = (value: FilterOptionsConfig): boolean => value
  && value.options.length > 0
  && (value.options.length !== 1 || value.options[0].value.toLowerCase() !== EMPTY_VALUE);

const getFiltersConfigs = (
  filterOptionsMap: FilterOptionMap,
  recordConfig: GraphStorageRecordsConfig,
): FilterConfig[] => {
  const resultMap = [];
  for (const [key, value] of Object.entries(filterOptionsMap)) {
    if (isOptionsValid(value)) {
      const config = recordConfig[key] as RecordConfig;
      const name = isAdditionalProperties(key)
        ? SharedParameterKeyHelper.getNameFormKey(key, QtoLeftPanelConstants.ADDITIONAL_PROPERTIES_PREFIX)
        : config.name;
      resultMap.push({ ...value, key, name });
    }
  }
  return resultMap;
};

export const handleFilterConfig = (
  records: QtoRecord[],
  recordConfig: GraphStorageRecordsConfig,
  callBack: (state: Partial<TreeFilterPanelState>) => void,
): void => {
  const filterOptionsMap = mapFilterOptions(records, recordConfig);
  const filterConfigs = getFiltersConfigs(filterOptionsMap, recordConfig);
  filterConfigs.push(reportFilterConfig);
  filterConfigs.push(customFilterConfig);
  filterConfigs.push(engineFilterConfig);
  const defaultFilterOptions = filterConfigs;
  callBack({ filterConfigs, defaultFilterOptions });
};
