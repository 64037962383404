export function RIGHT(value: string | number, n: number = 1): string {
  const str = value.toString();
  if (n <= 0) {
    return '';
  } else if (n > str.length) {
    return str;
  } else {
    return str.substring(str.length - n);
  }
}
