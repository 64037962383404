import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import './billing-notice-page.scss';

import { State } from 'common/interfaces/state';
import { SubscriptionStatus } from '../../../subscription/enums/subscription-status';
import { AccountSelectors } from '../../selectors';

interface StateProps {
  isOwnCompanySelected: boolean;
  isSelectedCompanySubscriptionValid: boolean;
  isSelectedCompanyLicensesLimitExceeded: boolean;
}

interface PageProps extends StateProps, RouteComponentProps<void> {}

class BillingNoticePageComponent extends React.Component<PageProps> {
  public render(): React.ReactNode {
    const {
      isOwnCompanySelected,
      isSelectedCompanySubscriptionValid,
      isSelectedCompanyLicensesLimitExceeded,
    } = this.props;

    let message = 'This company has no billing related issues, you\'re all good!';

    if (!isOwnCompanySelected && !isSelectedCompanySubscriptionValid) {
      message = 'This company has no active subscription, please contact its owner for details.';
    } else if (!isOwnCompanySelected && isSelectedCompanyLicensesLimitExceeded) {
      message = 'This company has exceeded the Employee licenses limit, please contact its owner for details.';
    }

    return (
      <div className='billing-notice-page'>
        <div className='billing-notice-page--message'>
          {message}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: State): StateProps {
  const ownCompany = state.account.ownedCompany;
  const selectedCompany = state.account.selectedCompany;

  const isOwnCompanySelected = ownCompany && ownCompany.id === selectedCompany.id;

  const subscription = AccountSelectors.currentSubscription(state);
  const isSelectedCompanySubscriptionValid = subscription && subscription.status === SubscriptionStatus.Valid;


  return {
    isOwnCompanySelected,
    isSelectedCompanySubscriptionValid,
    isSelectedCompanyLicensesLimitExceeded: AccountSelectors.isSelectedCompanyLicensesLimitExceeded(state),
  };
}

export const BillingNoticePage = connect(mapStateToProps)(BillingNoticePageComponent);
