import { Accordion } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { SubscriptionType } from 'common/constants/subscription';
import { Styled } from './styled';
import { getFAQList } from './subscription-faq-list';

interface Props {
  subscriptionType: SubscriptionType;
}

export const SubscriptionFaq: React.FC<Props> = (props: Props) => {
  const trialPeriod = props.subscriptionType === SubscriptionType.Takeoff2d ? 7 : 5;
  const FAQList = getFAQList(trialPeriod);

  return (
    <Styled.Container>
      <Accordion
        title='FAQ'
        content={FAQList}
      />
    </Styled.Container>
  );
};
