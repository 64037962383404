export type SortValue = SortField | SortOrder;

export enum SortField {
  Date = 'createdAt',
  Name = 'name',
}

export enum SortOrder {
  Ascending = 'ascending',
  Descending = 'descending',
}

export enum SortOptions {
  Field = 'field',
  Order = 'order',
}

export interface SortData {
  [SortOptions.Field]: SortField;
  [SortOptions.Order]: SortOrder;
}

export const SortDataInitialState: SortData = {
  [SortOptions.Field]: SortField.Date,
  [SortOptions.Order]: SortOrder.Ascending,
};

