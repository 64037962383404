import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyD: React.FC = () => {
  return (
    <svg
      width='20px'
      height='22px'
      viewBox='0 0 20 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='hotkey_d' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='D'>
          <g id='background'>
            <g id='Group' transform='translate(0.000000, 2.000000)'>
              <g id='path-1-link' fill={KreoColors.f1}>
                <rect id='path-1' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
            <g id='Group'>
              <g id='path-2-link' fill={KreoColors.f1}>
                <rect id='path-2' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
          </g>
          <rect id='padding-left' x='0' y='6' width='6' height='10' />
          <rect id='padding-right' x='14' y='6' width='6' height='10' />
          <path
            // eslint-disable-next-line max-len
            d='M7.52500019,6.33849874 C7.69550022,6.16799872 7.90450026,6.16799872 8.08050029,6.16799872 L10.1100006,6.16799872 C11.0945008,6.16799872 11.8150009,6.49799877 12.343001,7.16349888 C12.8930011,7.86749899 12.9205011,8.69249913 12.9205011,10.0839994 C12.9205011,11.4754996 12.8820011,12.3114997 12.332001,13.0154998 C11.8095009,13.6864999 11.0945008,14 10.1100006,14 L8.08050029,14 C7.90450026,14 7.69550022,14 7.52500019,13.8295 C7.35450017,13.6589999 7.35450017,13.4499999 7.35450017,13.2739999 L7.35450017,6.89399884 C7.35450017,6.71799881 7.35450017,6.50899877 7.52500019,6.33849874 Z M8.54250036,7.22399889 L8.54250036,12.9439998 L9.9725006,12.9439998 C10.5390007,12.9439998 10.9900008,12.7899998 11.3200008,12.4104997 C11.7105009,11.9704997 11.7325009,11.2444995 11.7325009,10.0839994 C11.7325009,8.92349917 11.7105009,8.19749905 11.3200008,7.75749898 C10.9900008,7.37799891 10.5390007,7.22399889 9.9725006,7.22399889 L8.54250036,7.22399889 Z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
};
