import { Location } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SubscriptionType } from 'common/constants/subscription';
import { State as ReduxState } from 'common/interfaces/state';
import { AccountSelectors } from '../../../units/account/selectors';
import { SubscriptionPlanListingModel } from '../../../units/subscription/interfaces/subscription-plan-listing-model';
import { Routes } from '.';


interface StateProps {
  currentLocation: Location;
  isSelectedCompanyLicensesLimitNotExceeded: boolean;
  plansModel: SubscriptionPlanListingModel | null;
  subscription: any;
}

interface Props extends StateProps, AbilityAwareProps, RouteComponentProps<{}> {
  urls: any;
  subscriptionType: SubscriptionType;
  canShowQuestionnaire: boolean;
}

class RoutesWrapComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      children,
      urls,
      subscriptionType,
      canShowQuestionnaire,
    } = this.props;

    if (this.isSubscriptionAccessible(this.props)) {
      return (
        <Switch>
          <Redirect exact={true} from={urls.billing.subscribe.path} to={urls.index.path} />
          <Redirect exact={true} from={urls.billing.billingNotice.path} to={urls.index.path} />
          <Redirect exact={true} from={urls.billing.renewSubscription.path} to={urls.index.path} />
          <Redirect exact={true} from={urls.billing.resumeSubscription.path} to={urls.index.path} />
          <Redirect exact={true} from={urls.billing.updateSubscription.path} to={urls.index.path} />
          <Redirect exact={true} from={urls.billing.upgradeSubscription.path} to={urls.index.path} />
          {children}
        </Switch>
      );
    }

    return (<Routes urls={urls} subscriptionType={subscriptionType} canShowQuestionnaire={canShowQuestionnaire}/>);
  }

  private isSubscriptionAccessible(props: Props): boolean {
    const {
      isSelectedCompanyLicensesLimitNotExceeded,
      ability,
    } = props;
    return isSelectedCompanyLicensesLimitNotExceeded && ability.can(Operation.Read, Subject.Application);
  }
}

function mapStateToProps(state: ReduxState): StateProps {
  return {
    currentLocation: state.router.location,
    isSelectedCompanyLicensesLimitNotExceeded: !AccountSelectors.isSelectedCompanyLicensesLimitExceeded(state),
    plansModel: state.account.subscriptionPlans,
    subscription: AccountSelectors.currentSubscription(state),
  };
}

const withRedux = connect(mapStateToProps);

export const RoutesWrap = withAbilityContext(withRouter(withRedux(RoutesWrapComponent)));
