import autobind from 'autobind-decorator';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import { Field, WrappedFieldArrayProps } from 'redux-form';

import './database-activity-code-group-selector.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoButton, KreoDialog, KreoDialogActions, KreoIconAddPlus, KreoScrollbars } from 'common/UIKit';
import { CodeGroupModel } from '../../interfaces/data';
import { CodeGroupForm } from '../../interfaces/rest-data';
import { CodeGroupItemField } from './database-activity-code-group-item';

export const CODE_GROUP_SELECTOR_DIALOG_NAME = 'codeGroupSelector';

interface ReduxActions {
  closeDialog: (dialogName: string) => void;
}

interface ReduxProps {
  isOpen: boolean;
  databaseId: number;
  isReadonly: boolean;
}

export interface CodeGroupSelectorProps {
  codeGroups: CodeGroupModel[];
  onChangeCodeGroups: (codeGroups: CodeGroupModel[]) => void;
}


interface Props extends CodeGroupSelectorProps, ReduxActions, ReduxProps, AbilityAwareProps {}

interface State {
  codeGroups: CodeGroupModel[];
}

class CodeGroupSelectorComponent extends React.Component<Props & WrappedFieldArrayProps<CodeGroupForm>, State> {
  constructor(props: Props & WrappedFieldArrayProps<CodeGroupForm>) {
    super(props);
    this.state = { codeGroups: props.codeGroups };
  }

  public render(): JSX.Element {
    const isReadonly = this.props.isReadonly ||
      !this.props.ability.can(Operation.Create, Subject.Database);

    return (
      <KreoDialog
        name={CODE_GROUP_SELECTOR_DIALOG_NAME}
        title={`${isReadonly ? 'View' : 'Edit'} Classification`}
        onClose={this.onCancel}
        bodyClassName='code-group-selector'
        size='large'
      >
        <div className='database-activity-code-group-selector__header'>
          <div className='database-activity-code-group-selector__header-item'>UniSystem</div>
          <div className='database-activity-code-group-selector__header-item'>UniProduct</div>
          <div className='database-activity-code-group-selector__header-item'>NRM 1</div>
          <div className='database-activity-code-group-selector__header-item'>NRM 2</div>
        </div>
        <div className='database-activity-code-group-selector__content'>
        {
          this.props.codeGroups.length
            ? this.renderFieldCodeGroupList(isReadonly)
            : this.renderEmptyCodeGroupList(isReadonly)
        }
        </div>
        <div className='database-activity-code-group-selector__footer'>
          <KreoButton
            onClick={this.onCancel}
            size='large'
            mode='ghost'
          >
            Cancel
          </KreoButton>
          {
            !isReadonly ? (
              <KreoButton
                onClick={this.onSave}
                size='large'
                mode='submit'
              >
                Apply
              </KreoButton>
            ) : null
          }
        </div>
      </KreoDialog>
    );
  }

  private renderFieldCodeGroupList(isReadonly: boolean): JSX.Element {
    return (
      <KreoScrollbars showShadowTop={true}>
        <React.Fragment>
        {
          this.props.fields.map((fieldKey, index) => {
            return this.props.codeGroups[index] ? (
              <Field
                name={fieldKey}
                component={CodeGroupItemField}
                codeGroup={this.props.codeGroups[index]}
                key={fieldKey}
                onDelete={this.onDeleteCodeGroup}
                onDuplicate={this.onDuplicateCodeGroup}
                onCodeGroupChange={this.updateCodeGroup}
                index={index}
                readonly={isReadonly}
                validate={[this.NotEmpty, this.NotContainDuplicate]}
              />
            ) : null;
          })
        }
        {
          !isReadonly ? (
            <div
              className='database-activity-code-group-selector__create-btn'
              onClick={this.addNewCodeGroup}
            >
              <KreoIconAddPlus />
            </div>
          ) : null
        }
        </React.Fragment>
      </KreoScrollbars>
    );
  }

  private renderEmptyCodeGroupList(isReadonly: boolean): JSX.Element {
    return (
      <React.Fragment>
        <div className='database-activity-code-group-selector__empty-tile'>No Code Group</div>
        {
          !isReadonly ? (
            <div className='database-activity-code-group-selector__create-btn-wrap'>
              <div
                className='database-activity-code-group-selector__create-btn'
                onClick={this.addNewCodeGroup}
              >
                <KreoIconAddPlus />
              </div>
              <span>Create New</span>
            </div>
          ) : null
        }
      </React.Fragment>
    );
  }

  @autobind
  private onSave(): void {
    this.setState({ codeGroups: this.props.codeGroups });
    this.closeDialog();
  }

  @autobind
  private addNewCodeGroup(): void {
    this.props.onChangeCodeGroups(
      this.props.codeGroups.concat({
        id: 0,
        nrm1: null,
        nrm2: null,
        uniProduct: null,
        uniSystem: null,
        category: null,
        family: null,
        elementType: null,
        comment: null,
      }));
  }

  @autobind
  private onDuplicateCodeGroup(codeGroup: CodeGroupModel): void {
    this.props.onChangeCodeGroups(
      this.props.codeGroups.concat({
        ...codeGroup,
        id: 0,
      }));
  }

  @autobind
  private onDeleteCodeGroup(index: number): void {
    const codeGroups = this.props.codeGroups.slice();
    codeGroups.splice(index, 1);
    this.props.fields.remove(index);
    this.props.onChangeCodeGroups(codeGroups);
  }


  @autobind
  private updateCodeGroup(index: number, codeGroup: CodeGroupModel): void {
    const codeGroups = this.props.codeGroups.slice();
    codeGroups[index] = codeGroup;
    this.props.onChangeCodeGroups(codeGroups);
  }

  @autobind
  private onCancel(): void {
    this.props.onChangeCodeGroups(this.state.codeGroups);
    this.closeDialog();
  }

  @autobind
  private closeDialog(): void {
    this.props.closeDialog(CODE_GROUP_SELECTOR_DIALOG_NAME);
  }

  @autobind
  private NotContainDuplicate(value: CodeGroupForm, values: any, __: any, fieldName: string): string | undefined {
    if (!value) {
      return;
    }

    const hasDuplicate = this.props.fields.map(name => {
      const codeGroup = get(values, name, {});
      return  name !== fieldName &&
        (value.nrm1Id || null) === (codeGroup.nrm1Id || null) &&
        (value.nrm2Id || null) === (codeGroup.nrm2Id || null) &&
        (value.uniSystemId || null) === (codeGroup.uniSystemId || null) &&
        (value.uniProductId || null) === (codeGroup.uniProductId || null);
    }).includes(true);

    if (hasDuplicate) {
      return 'Such code group already exists in the classification';
    }
  }

  private NotEmpty(value: CodeGroupForm): string | undefined {
    if (!value) {
      return;
    }
    if (!value.nrm1Id && !value.nrm2Id && !value.uniSystemId && !value.uniProductId
      && !!value.category && !!value.family && !!value.elementType && !!value.comment) {
      return 'Code Group is empty';
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    databaseId: state.database.currentDatabase.database.id,
    isOpen: CODE_GROUP_SELECTOR_DIALOG_NAME in state.dialog,
    isReadonly: state.database.currentDatabase.database.isReadOnly,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    closeDialog: (dialogName: string) => dispatch(KreoDialogActions.closeDialog(dialogName)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const CodeGroupSelector = withAbilityContext(connector(CodeGroupSelectorComponent));
