import * as Ag from 'ag-grid-community';
import React from 'react';
import { Constants as DataBaseConstants } from 'common/components/data-base-table/constants';
import { getExportColumn } from '../export-column';

export const useDefaultReportTemplateColumns = (exportReport: (id: number) => void): Ag.ColDef[] => {
  return React.useMemo(() => {
    const defaultColumns = DataBaseConstants.DEFAULT_COLUMNS.filter(dc =>
      dc.field === 'createdBy'
      || dc.field === 'createdTime',
    );

    defaultColumns.push(getExportColumn(exportReport));
    return defaultColumns;
  }, []);
};
