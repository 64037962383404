import { RectangleButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


interface Props {
  onClick: () => void;
}

export const CloseButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Styled.Container>
      <RectangleButton
        mood={'default'}
        text={'No, Go back'}
        width={150}
        height={50}
        onClick={onClick}
      />
    </Styled.Container>
  );
};
