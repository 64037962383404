import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { DuplicateProjectPayload } from 'unit-projects/actions/payloads/common';
import {
  PROJECT_DUPLICATE_DIALOG,
  TwoDProjectDuplicateDialog,
} from '../../../../../units/2d-projects-page/2d-project-duplicate-dialog';


interface DispatchProps {
  closeDialog: (dialogName: string) => void;
  duplicateProject: (payload: DuplicateProjectPayload) => void;
}

interface StateProps {
  isDuplicateDialog: boolean;
}

interface Props extends DispatchProps, StateProps {
  currentProjectId: number;
}

class DuplicateProjectDialogComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isDuplicateDialog, currentProjectId } = this.props;
    if (!isDuplicateDialog) {
      return null;
    }

    return (
      <TwoDProjectDuplicateDialog
        projectId={currentProjectId}
        onDuplicateProject={this.onDuplicateProject}
        onClose={this.onCloseDuplicateProjectDialog}
      />
    );
  }

  @autobind
  private onDuplicateProject(companyId: number): void {
    this.props.duplicateProject({ projectId: this.props.currentProjectId, companyId });
    this.onCloseDuplicateProjectDialog();
  }

  @autobind
  private onCloseDuplicateProjectDialog(): void {
    this.props.closeDialog(PROJECT_DUPLICATE_DIALOG);
  }
}


const mapStateTopProps = (state: State): StateProps => {
  return {
    isDuplicateDialog: PROJECT_DUPLICATE_DIALOG in state.dialog,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    duplicateProject: ({ projectId, companyId }) => dispatch(ProjectsActions.duplicate2dProject(projectId, companyId)),
    closeDialog: dialogName => dispatch(KreoDialogActions.closeDialog(dialogName)),
  };
};


const connector = connect(mapStateTopProps, mapDispatchToProps);

export const DuplicateProjectDialog = connector(DuplicateProjectDialogComponent);
