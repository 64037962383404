import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { DrawingsGroupMeasure } from '../..';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import {
  DrawingRulerPoints,
  DrawingUserAnnotationImage,
  DrawingUserAnnotationRuler,
  DrawingUserAnnotationSticker,
} from '../../interfaces/drawings-user-annotation';
import {
  DrawingsAddImages,
  DrawingsAddRulers,
  DrawingsAddStickers,
  DrawingsAnnotationRotation,
  DrawingsAnnotationsChangeColors,
  DrawingsAnnotationsPosition,
  DrawingsChangeStickerTextPayload,
  DrawingsDeleteAnnotations,
  DrawingsDeleteAnnotationsPayload,
  DrawingsDeleteAnnotationsRequest,
  DrawingsGetUserAnnotationsSuccess,
  DrawingsSaveStickerSuccess,
  DrawingsStickerAppliedPayload,
  DrawingsUpdateRulerPoints,
} from '../payloads/user-annotation';
import { DrawingsUserAnnotationActionTypes } from '../types/user-annotation';

function addSticker(stickers: DrawingUserAnnotationSticker[], drawingId: string): ActionWith<DrawingsAddStickers> {
  return {
    type: DrawingsUserAnnotationActionTypes.ADD_STICKER,
    payload: { stickers, drawingId },
  };
}


function changeStickerText(id: string, text: string): ActionWith<DrawingsChangeStickerTextPayload> {
  return {
    type: DrawingsUserAnnotationActionTypes.CHANGE_STICKER_TEXT,
    payload: { id, text },
  };
}

function updateStickersPosition(
  positionUpdates: DrawingsAnnotationsPosition[],
): ActionWith<DrawingsAnnotationsPosition[]> {
  return {
    type: DrawingsUserAnnotationActionTypes.UPDATE_STICKERS_POSITIONS,
    payload: positionUpdates,
  };
}

function selectAnnotations(ids: string[]): ActionWith<string[]> {
  return {
    type: DrawingsUserAnnotationActionTypes.SELECT_ANNOTATION,
    payload: ids,
  };
}

function removeAnnotations(ids: string[], drawingId: string): ActionWith<DrawingsDeleteAnnotationsRequest> {
  return {
    type: DrawingsUserAnnotationActionTypes.REMOVE_INSTANCES,
    payload: {
      drawingId,
      annotationsIds: ids,
    },
  };
}

function removeAnnotationsFromState(
  deletedAnnotations: DrawingsDeleteAnnotations,
  drawingId: string,
): ActionWith<DrawingsDeleteAnnotationsPayload> {
  return {
    type: DrawingsUserAnnotationActionTypes.REMOVE_INSTANCES_FROM_STATE,
    payload: { deletedAnnotations, drawingId },
  };
}

function addImages(
  images: DrawingUserAnnotationImage[],
  drawingId: string,
): ActionWith<DrawingsAddImages> {
  return {
    type: DrawingsUserAnnotationActionTypes.ADD_IMAGES,
    payload: { images, drawingId },
  };
}


function addRulers(
  rulers: DrawingUserAnnotationRuler[],
  drawingId: string,
): ActionWith<DrawingsAddRulers> {
  return {
    type: DrawingsUserAnnotationActionTypes.ADD_RULERS,
    payload: { rulers, drawingId },
  };
}

function changeColorOfAnnotation(
  colorChanges: DrawingsAnnotationsChangeColors[],
): ActionWith<DrawingsAnnotationsChangeColors[]> {
  return {
    type: DrawingsUserAnnotationActionTypes.CHANGE_COLORS,
    payload: colorChanges,
  };
}

function updateImagesPosition(
  positionUpdates: DrawingsAnnotationsPosition[],
): ActionWith<DrawingsAnnotationsPosition[]> {
  return {
    type: DrawingsUserAnnotationActionTypes.UPDATE_IMAGES_POSITIONS,
    payload: positionUpdates,
  };
}

function updateImageParameter(
  id: string,
  value: number,
  parameter: string,
): ActionWith<DrawingsAnnotationRotation> {
  return {
    type: DrawingsUserAnnotationActionTypes.UPDATE_IMAGE_PARAMETER,
    payload: { id, value, parameter },
  };
}

function updateRulerPoints(
  id: string,
  points: DrawingRulerPoints,
): ActionWith<DrawingsUpdateRulerPoints> {
  return {
    type: DrawingsUserAnnotationActionTypes.UPDATE_RULER_POINTS,
    payload: { id, points },
  };
}

function getAnnotations(drawingId: string): ActionWith<string> {
  return {
    type: DrawingsUserAnnotationActionTypes.GET_ANNOTATIONS,
    payload: drawingId,
  };
}

function getAnnotationSuccess(
  payload: DrawingsGetUserAnnotationsSuccess,
): ActionWith<DrawingsGetUserAnnotationsSuccess> {
  return {
    type: DrawingsUserAnnotationActionTypes.GET_ANNOTATIONS_SUCCESS,
    payload,
  };
}

function addStickerSuccuss(
  applied: DrawingsStickerAppliedPayload,
  stickers: DrawingUserAnnotationSticker[],
): ActionWith<DrawingsSaveStickerSuccess> {
  return {
    type: DrawingsUserAnnotationActionTypes.ADD_STICKER_SUCCEED,
    payload: { applied, stickers },
  };
}


function removeLegend(): Action {
  return {
    type: DrawingsUserAnnotationActionTypes.REMOVE_LEGEND,
  };
}

function updateLegend(point: ShortPointDescription): ActionWith<ShortPointDescription> {
  return {
    type: DrawingsUserAnnotationActionTypes.UPDATE_LEGEND_POSITION,
    payload: point,
  };
}

function saveGroupMeasure(
  groupMeasures: Record<string, DrawingsGroupMeasure>,
): ActionWith<Record<string, DrawingsGroupMeasure>> {
  return {
    type: DrawingsUserAnnotationActionTypes.CACHE_GROUP_VALUES,
    payload: groupMeasures,
  };
}

function setTempStickerPosition(
  position: ShortPointDescription,
): ActionWith<ShortPointDescription> {
  return {
    type: DrawingsUserAnnotationActionTypes.SET_TEMP_STICKER_POSITION,
    payload: position,
  };
}

function setTempStickerHasText(
  payload: boolean,
): ActionWith<boolean> {
  return {
    type: DrawingsUserAnnotationActionTypes.SET_TEMP_STICKER_HAS_TEXT_STATUS,
    payload,
  };
}

export const DrawingsUserAnnotationActions = {
  addSticker,
  addStickerSuccuss,
  changeStickerText,
  updateStickersPosition,
  selectAnnotations,
  removeAnnotations,
  addImages,
  addRulers,
  changeColorOfAnnotation,
  updateImagesPosition,
  updateImageParameter,
  updateRulerPoints,
  getAnnotations,
  getAnnotationSuccess,
  removeAnnotationsFromState,
  updateLegend,
  saveGroupMeasure,
  removeLegend,

  setTempStickerPosition,
  setTempStickerHasText,
};
