import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './company-currency-control.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoButton, MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { CurrencyModel } from '../../../account/interfaces/currency-model';
import { AdminCompaniesActions } from '../../actions/creators/companies';

interface ControlOwnProps {
  companyId: number;
}

interface ControlStateProps {
  storedCurrencyCode: string;
  currencies: CurrencyModel[];
  hasInProgressRequests: boolean;
}

interface ControlDispatchProps {
  updateCurrency: (currencyCode: string) => void;
}

interface ControlProps extends ControlOwnProps, ControlStateProps, ControlDispatchProps { }

interface ControlState {
  selectedCurrencyCode: string;
}

class CompanyCurrencyControlComponent extends React.Component<ControlProps, ControlState> {
  public constructor(props: ControlProps) {
    super(props);

    this.state = {
      selectedCurrencyCode: props.storedCurrencyCode,
    };
  }

  public render(): React.ReactNode {
    const { hasInProgressRequests, currencies, storedCurrencyCode } = this.props;
    const { selectedCurrencyCode } = this.state;

    return (
      <div className='company-currency-control'>
        {
          hasInProgressRequests
            ? <SvgSpinner size='small' />
            : (<React.Fragment>
              <div className='company-currency-control__title'>Currency</div>
              <div className='company-currency-control__body'>
                <MaterialSelect
                  className='company-currency-control__currency-select'
                  autoWidth={true}
                  value={selectedCurrencyCode}
                  displayedType={MaterialComponentType.Native}
                  onChange={this.onCurrencyCodeChange}
                >
                  {currencies.map(c => (
                    <MaterialMenuItem key={c.code} value={c.code}>
                      {c.code} - {c.name}, {c.symbol}
                    </MaterialMenuItem>
                  ))}
                </MaterialSelect>
                <KreoButton
                  size='medium'
                  mode='action'
                  caption='Save Changes'
                  disabled={storedCurrencyCode === selectedCurrencyCode}
                  className='company-currency-control__save-changes-button'
                  onClick={this.onSaveButtonClick}
                />
              </div>
            </React.Fragment>)
        }
      </div>
    );
  }

  @autobind
  private onCurrencyCodeChange(_: React.SyntheticEvent<Element>, value: string): void {
    this.setState({ selectedCurrencyCode: value });
  }

  @autobind
  private onSaveButtonClick(): void {
    this.props.updateCurrency(this.state.selectedCurrencyCode);
  }
}

function mapStateToProps(s: State, { companyId }: ControlOwnProps): ControlStateProps {
  const hasInProgressRequests = [
    s.admin.companies.statuses.currencies,
    s.admin.companies.statuses.updateCurrency[companyId],
  ].includes(RequestStatus.Loading);

  const company = s.admin.companies.data.find(c => c.id === companyId);

  return {
    currencies: s.admin.companies.currencies,
    storedCurrencyCode: company.currencyCode,
    hasInProgressRequests,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { companyId }: ControlOwnProps): ControlDispatchProps {
  return {
    updateCurrency: currencyCode => dispatch(AdminCompaniesActions.setCurrency(companyId, currencyCode)),
  };
}

export const CompanyCurrencyControl = connect(mapStateToProps, mapDispatchToProps)(CompanyCurrencyControlComponent);
