import styled from 'styled-components';

const Container = styled.div<{ shouldBlockEvents: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  > div {
    pointer-events: ${p => p.shouldBlockEvents ? 'none' : 'all'};
  }
`;

export const Styled = {
  Container,
};
