import * as classNames from 'classnames';
import * as React from 'react';

import './resource-limitations-row-sub-content.scss';

import {
  ResourceLimitationsMenuType,
  WorkPackagesData,
} from '../../../scenarios';
import { ResourceLimitationsTileList } from '../resource-limitations-tile-list';

interface Props {
  collapsed: boolean;
  items: WorkPackagesData.ResourceLimitation[];
  activeMenu: ResourceLimitationsMenuType;
}

export class ResourceLimitationsRowSubContent extends React.Component<Props> {
  public render(): JSX.Element {
    const { collapsed, items, activeMenu } = this.props;
    const className = classNames('resource-limitations-row-sub-content', { collapsed });
    return (
      <div className={className}>
        {items.map((item, index) => {
          return (
            <ResourceLimitationsTileList key={index} item={item} activeMenu={activeMenu} />
          );
        })}
      </div>
    );
  }
}
