import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import {
  ApplyContourFixPayload,
  MagicSearchSetContourPayload,
  SetPreviewsPayload,
} from '../actions/payloads/magic-search';
import { MagicSearchActionTypes } from '../actions/types/magic-search';
import { WizzardStatus } from '../enums/dropper-state';
import { ContourToFixType, DrawingsState, ShortPointDescription } from '../interfaces';
import { MagicSearchSnappedGeometry } from '../interfaces/api-responses/magic-search-responses';

export const MagicSearchReducerMethods: ReducerMethods<DrawingsState> = {
  [MagicSearchActionTypes.SET_CONTOURS_TO_SEARCH]: (state, { contours }: MagicSearchSetContourPayload) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.contoursToSearch, contours)
      .set(_ => _.magicSearch.dpi, 150)
      .get();
  },
  [MagicSearchActionTypes.RUN_SEARCH]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.status, WizzardStatus.Loading)
      .set(_ => _.magicSearch.fixContour, null)
      .set(_ => _.magicSearch.fixedContours, [])
      .get();
  },
  [MagicSearchActionTypes.RUN_TEXT_SEARCH]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.status, WizzardStatus.Loading)
      .set(_ => _.magicSearch.fixContour, null)
      .set(_ => _.magicSearch.fixedContours, [])
      .get();
  },
  [MagicSearchActionTypes.SAVE_RESULT]: (state, { contours, status, geometries }) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.contours, contours)
      .set(_ => _.magicSearch.status, status)
      .set(_ => _.magicSearch.previewsToDelete, [])
      .set(_ => _.magicSearch.contoursToDelete, [])
      .set(_ => _.magicSearch.lastSnapped, geometries || [])
      .get();
  },
  [MagicSearchActionTypes.SET_DPI]: (state, dpi) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.dpi, dpi)
      .get();
  },
  [MagicSearchActionTypes.SET_SIMILARITY]: (state, similarity) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.similarity, similarity)
      .get();
  },
  [MagicSearchActionTypes.SAVE_PREVIEW_RESULT]: (
    state,
    { contours, resultToSources, previewsToDelete }: SetPreviewsPayload,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.previews, contours)
      .set(_ => _.magicSearch.resultToSources, resultToSources)
      .set(_ => _.magicSearch.previewsToDelete, previewsToDelete)
      .get();
  },
  [MagicSearchActionTypes.SET_STATUS]: (state, status: WizzardStatus) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.status, status)
      .get();
  },
  [MagicSearchActionTypes.SET_SPECIFY_SEARCH_ZONE]: (state, specify: boolean) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.searchAreaSpecifyMode, specify)
      .get();
  },
  [MagicSearchActionTypes.SET_SEARCH_ZONE]: (state, searchArea: ShortPointDescription[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.searchArea, searchArea)
      .set(_ => _.magicSearch.searchAreaSpecifyMode, false)
      .get();
  },
  [MagicSearchActionTypes.SET_QUERY]: (state, query: string) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.query, query)
      .get();
  },
  [MagicSearchActionTypes.TOGGLE_DELETE_PREVIEW]: (state, ids: number[]) => {
    const helper = new MonoliteHelper(state);
    const idsSet = new Set(ids);
    const previewsIds = arrayUtils.filterMap(
      Object.entries(state.magicSearch.resultToSources),
      ([_, sources]) => sources.some(s => idsSet.has(s)),
      ([id]) => +id,
    );
    if (state.magicSearch.contoursToDelete.some(c => idsSet.has(c))) {
      helper
        .setFilter(_ => _.magicSearch.previewsToDelete, _ => !previewsIds.includes(_))
        .setFilter(_ => _.magicSearch.contoursToDelete, _ => !idsSet.has(_));
    } else {
      helper
        .setConcat(_ => _.magicSearch.previewsToDelete, previewsIds)
        .setConcat(_ => _.magicSearch.contoursToDelete, ids);
    }
    return helper.get();
  },
  [MagicSearchActionTypes.RESET_DELETE_PREVIEWS]: (state) => {
    const previewsToDelete = state.magicSearch.previewsToDelete;
    const contoursToReset = new Set(previewsToDelete.reduce((acc, id) => {
      arrayUtils.extendArray(acc, state.magicSearch.resultToSources[id]);
      return acc;
    }, []));
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.previewsToDelete, [])
      .setFilter(_ => _.magicSearch.contoursToDelete, _ => !contoursToReset.has(_))
      .get();
  },
  [MagicSearchActionTypes.SET_SNAPPING_STATUS]: (state, status: RequestStatus) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.snappingStatus, status)
      .get();
  },
  [MagicSearchActionTypes.SAVE_SNAPPED_GEOMETRIES]: (state, geometries: MagicSearchSnappedGeometry[]) => {
    const source = state.magicSearch.lastSnapped;
    const usedSourceContours = [];
    geometries.forEach(geometry => {
      arrayUtils.extendArray(usedSourceContours, geometry.initialIds);
    });
    const usedSet = new Set(usedSourceContours);
    const sourceFiltered = source.filter(s => s.initialIds.every(x => !usedSet.has(x)));
    arrayUtils.extendArray(sourceFiltered, geometries);
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.lastSnapped, sourceFiltered)
      .get();
  },
  [MagicSearchActionTypes.SET_FIX_API]: (state, api) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.fixContour, api)
      .get();
  },
  [MagicSearchActionTypes.SET_FIX_STATUS]: (state, status) => {
    return new MonoliteHelper(state)
      .set(_ => _.magicSearch.fixContour.state, status)
      .get();
  },
  [MagicSearchActionTypes.APPLY_FIX_CONTOUR]: (
    state,
    { sourceIndices, contour }: ApplyContourFixPayload,
  ) => {
    const childContours = sourceIndices;
    const isFixedBefore = state.magicSearch.fixContour.contourType === ContourToFixType.Fixed;
    const contourIndex = state.magicSearch.fixContour.contourIndex;
    const helper = new MonoliteHelper(state)
      .setConcat(_ => _.magicSearch.replacedContours, childContours)
      .set(_ => _.magicSearch.fixContour, null);
    if (isFixedBefore) {
      helper
        .set(_ => _.magicSearch.fixedContours[contourIndex].points, contour[0])
        .set(_ => _.magicSearch.fixedContours[contourIndex].holes, contour.slice(1));
    } else {
      helper.setAppend(
        _ => _.magicSearch.fixedContours,
        {
          points: contour[0],
          sourceContours: childContours,
          previewIndex: contourIndex,
          holes: contour.slice(1),
        },
      );
    }
    return helper.get();
  },
  [MagicSearchActionTypes.DELETE_FIX_CONTOUR]: (state, contourIndex: number) => {
    const fixedContour = state.magicSearch.fixedContours[contourIndex];
    return new MonoliteHelper(state)
      .setFilter(_ => _.magicSearch.hiddenPreviews, _ => _ !== fixedContour.previewIndex)
      .setAppend(_ => _.magicSearch.previewsToDelete, fixedContour.previewIndex)
      .setConcat(_ => _.magicSearch.contoursToDelete, fixedContour.sourceContours)
      .setFilter(_ => _.magicSearch.replacedContours, _ => !fixedContour.sourceContours.includes(_))
      .set(_ => _.magicSearch.fixedContours, _ => _.filter((__, i) => i !== contourIndex))
      .set(_ => _.magicSearch.fixContour, null)
      .get();
  },
};
