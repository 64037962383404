import autobind from 'autobind-decorator';
import * as paper from 'paper';
import {
  DrawingsCanvasColors,
  DrawingsCanvasConstants,
} from 'common/components/drawings/constants/drawing-canvas-constants';
import { ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { ShortPointDescription } from 'common/components/drawings/interfaces/drawing-ai-annotation';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { InstanceWithTempPoint } from '../../drawings-geometry-entities/temporary';

export interface KnifeLineConfig {
  point: paper.Point;
  layer: paper.Layer;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
}

export class KnifeLine implements InstanceWithTempPoint {
  private _isError: boolean = false;

  private _config: KnifeLineConfig;
  private _path: paper.Path;


  constructor(config: KnifeLineConfig) {
    this._config = config;

    this._path = new paper.Path([config.point, config.point]);
    this._path.strokeColor = DrawingsCanvasColors.pointColor;

    this._config.renderParametersContextObserver.subscribe(this.updateRenderParams);
  }

  public set tempPointPosition(point: paper.Point) {
    this._path.lastSegment.point = point;
    this.updateErrorStatus(DrawingsPaperUtils.checkPolylinePathSelfIntersections(this._path));
  }

  public get points(): ShortPointDescription[] {
    return this._path.segments.map(x => [x.point.x, x.point.y]);
  }

  public get path(): paper.Path {
    return this._path;
  }

  public get isError(): boolean {
    return this._isError;
  }

  public addPoint(point: paper.Point): void {
    this._path.add(point);
  }

  public removeLastPoint(): void {
    this._path.removeSegment(this._path.segments.length - 1);
  }

  public destroy(): void {
    this._path.remove();
  }


  public updateTempPointPosition(point: paper.Point, strictAngle?: boolean): paper.Point {
    this._path.lastSegment.point = point;
    const lastSegment = this._path.lastSegment;
    if (strictAngle) {
      const points = [
        lastSegment.point,
        lastSegment.previous.point,
        lastSegment.previous.previous ? lastSegment.previous.previous.point : null,
      ] as [paper.Point, paper.Point, paper.Point];
      this._path.lastSegment.point =
        DrawingsCanvasUtils.bindToStrictAngle(points, DrawingsCanvasConstants.snappingAngleStep);
    }
    this.updateErrorStatus(DrawingsPaperUtils.checkPolylinePathSelfIntersections(this._path));
    return this._path.lastSegment.point;
  }

  @autobind
  private updateRenderParams({ zoom }: DrawingsRenderParams): void {
    this._path.strokeWidth = DrawingsCanvasConstants.infoLinesStroke / zoom;
  }

  private updateErrorStatus(value: boolean): void {
    if (this._isError === value) {
      return;
    }
    this._isError = value;
    this._path.strokeColor = value ? DrawingsCanvasColors.warningStrokeColor : DrawingsCanvasColors.pointColor;
  }
}
