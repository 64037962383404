import * as t from 'io-ts';

import { BillingModelC } from './billing-model';

const SubscriptionPlanVariantFeatureC = t.intersection(
  [
    t.type({
      name: t.string,
      value: t.string,
    }),
    t.partial({
      description: t.string,
    }),
  ],
  'SubscriptionPlanVariantFeature',
);

const WrapperFeaturesC = t.intersection(
  [
    t.type({
      features: t.array(SubscriptionPlanVariantFeatureC),
    }),
    t.partial({
      name: t.string,
    }),
  ],
);

export const SubscriptionPlanVariantC = t.intersection(
  [
    BillingModelC,
    t.type({
      id: t.string,
      isSelfService: t.boolean,
      featureGroups: t.array(WrapperFeaturesC),
      buttonCaption: t.string,
      products: t.array(t.string),
      isCollaborationBlocked: t.boolean,
    }),
    t.partial({
      isHidden: t.boolean,
      description: t.string,
      availableAddons: t.array(t.string),
      projectsLimit: t.number,
      buttonLink: t.string,
    }),
  ],
  'SubscriptionPlanVariant');

export type SubscriptionPlanVariant = t.TypeOf<typeof SubscriptionPlanVariantC>;
export type SubscriptionPlanVariantFeature = t.TypeOf<typeof SubscriptionPlanVariantFeatureC>;
