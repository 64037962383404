import {
  ActivityModel,
  ActivityResourceVariant,
  ActivityVariantModel,
  ActivityVariantType,
  UnitModel,
} from '../interfaces/data';
import {
  LaborVariantModel,
  MaterialModel,
  MaterialVariantModel,
  PlantVariantModel,
} from '../interfaces/resources-data';

export interface DatabaseActivityVariantCost {
  laborCost: number;
  plantCost: number;
  materialsCost: number;
  totalCost: number;
}

export class DatabaseCostCalculator {
  public static getCosts(
    activity: ActivityModel,
    variant: ActivityVariantModel,
    unitMap: Record<number, UnitModel>,
  ): DatabaseActivityVariantCost {
    const crewHours = this.getCrewHours(variant);
    const laborCost = this.getLaborsCost(activity.labors) * crewHours;
    const plantCost = this.getPlantsCost(activity.plants) * crewHours;
    const materialsCost = this.getMaterialsCost(variant.materials, unitMap);
    const crewHoursMaterialsCost = this.getCrewHoursMaterialsCost(variant, unitMap);
    const totalMaterialCost = crewHoursMaterialsCost + materialsCost;

    return {
      laborCost,
      materialsCost: totalMaterialCost,
      plantCost,
      totalCost: laborCost + plantCost + totalMaterialCost,
    };
  }

  public static getCrewHours(variant: ActivityVariantModel): number {
    if (variant.type === ActivityVariantType.FixedCrewHours) {
      return variant.crewHours || 0;
    }
    const materialCrewHours = variant.materialBasedCrewHours;

    return materialCrewHours.primaryMaterial.crewHours * materialCrewHours.primaryPercentage
      + materialCrewHours.secondaryMaterial.crewHours * (1 - materialCrewHours.primaryPercentage);
  }

  public static getCrewHoursMaterialsCost(variant: ActivityVariantModel, unitMap: Record<number, UnitModel>): number {
    if (variant.type === ActivityVariantType.FixedCrewHours) {
      return 0;
    }

    const materialCrewHours = variant.materialBasedCrewHours;

    return this.getMaterialsCost(
      [
        {
          ...materialCrewHours.primaryMaterial,
          amount: materialCrewHours.primaryMaterial.amount * materialCrewHours.primaryPercentage,
        },
        {
          ...materialCrewHours.secondaryMaterial,
          amount: materialCrewHours.secondaryMaterial.amount * (1 - materialCrewHours.primaryPercentage),
        },
      ],
      unitMap);
  }

  public static getLaborsCost(labors: Array<ActivityResourceVariant<LaborVariantModel>>): number {
    return labors
      .map(x => x.amount * x.resourceVariant.cost)
      .reduce((a, b) => a + b, 0);
  }

  public static getPlantsCost(labors: Array<ActivityResourceVariant<PlantVariantModel>>): number {
    return labors
      .map(x => x.amount * x.resourceVariant.cost)
      .reduce((a, b) => a + b, 0);
  }

  public static getMaterialsCost(
    materials: Array<{ resource: MaterialModel, amount: number }>,
    unitMap: Record<number, UnitModel>,
  ): number {
    return materials
      .map(material => material.amount * DatabaseCostCalculator.getMaterialCost(material.resource, unitMap))
      .reduce((a, b) => a + b, 0);
  }

  public static getMaterialVariantCostPerUnit(
    material: MaterialModel,
    variant: MaterialVariantModel,
    unitMap: Record<number, UnitModel>,
  ): number {
    const defaultUnit = unitMap[material.defaultUnitId];
    const variantUnit = unitMap[variant.unitId];
    const defaultCoefficient = defaultUnit.coefficient;
    return variant.cost * defaultCoefficient / (variant.amount * variantUnit.coefficient);
  }

  private static getMaterialCost(material: MaterialModel, unitMap: Record<number, UnitModel>): number {
    if (!material || material.variants.length === 0) {
      return 0;
    }

    const variantCostsSum = material.variants
      .map(variant => DatabaseCostCalculator.getMaterialVariantCostPerUnit(material, variant, unitMap))
      .reduce((a, b) => a + b, 0);

    return variantCostsSum / material.variants.length;
  }
}
