import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { BillingAddressStyled } from '../../payment-method-manager/billing-address';
import { AddonStyled } from '../../subscription-details/addon-list/addon';


const FillStepScaleContainer = styled.div`
`;

const ContentContainer = styled.div<{ headerMargin: number }>`
  margin-left: 10px;
  
  > ${Text} {
    margin-bottom: ${p => p.headerMargin}px;
  }

  ${BillingAddressStyled.VatCountryLabel} {
    margin-left: 20px;
  }

  ${BillingAddressStyled.Column} {
    &:first-child {
      width: 280px;
    }
  }
`;

const AddonListWrapper = styled.div`
  width: 422px;

  > div {
    div {
      grid-template-columns: repeat(2,50%);
    }
  }

  ${AddonStyled.CostAddon} {
    background: ${p => p.theme.color.background};

    p {
      color: ${p => p.theme.color.turquoise};
    }
    :nth-child(2) {
      font-weight: bold;
    }
    &:nth-child(2) {
      font-weight: bold;
    }
    :not(:first-child, :last-child) {
      font-weight: bold;
    }
    &:not(:first-child, :last-child) {
      font-weight: bold;
    }
  }

  ${AddonStyled.AdvDialogButton} {
    > button {
      width: 20px;
      height: 20px;
      margin-left: 15px;
    }
  }

  ${AddonStyled.AddonItem} {
    margin-bottom: 10px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 19px 0;
`;

const ExtraText = styled.div`
  margin-left: 20px;
`;

export const Styled = {
  FillStepScaleContainer,
  ContentContainer,
  TextContainer,
  ExtraText,
  AddonListWrapper,
};
