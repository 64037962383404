import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${p => p.theme.color.background};
`;


export const Styled = {
  Container,
};
