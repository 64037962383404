import autobind from 'autobind-decorator';
import React from 'react';
import { Field } from 'redux-form';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import {
  MaterialInputField,
  MaterialInputProps,
  MaterialNumberInput,
  MaterialNumberInputField,
  MaterialNumberInputProps,
} from 'common/UIKit';
import { CheckboxField, CheckboxFieldProps } from 'common/UIKit/inputs/checkbox';
import { PlantVariantModel } from '../../interfaces/resources-data';


interface Props extends I18nAwareProps {
  readonly: boolean;
  variant: PlantVariantModel;
  onChange: (root: PlantVariantModel) => void;
}

interface State {
  costPerShift: number;
}

class DatabaseResourcePlantVariantComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      costPerShift: this.props.variant.cost ? this.props.variant.shiftHours * this.props.variant.cost : null,
    };
  }
  public render(): React.ReactNode {
    return (
      <div className='database-resource-plant'>
        <div className='database-resource-plant__name'>
          <Field<MaterialInputProps>
            name='name'
            component={MaterialInputField}
            label='Name'
            placeholder='Name'
            multiLine={true}
            value={this.props.variant.name}
            onChange={this.onNameChange}
            onBlur={this.onNameBlur}
            displayBottomInformation={true}
            disabled={this.props.readonly}
          />
        </div>
        <div className='database-resource-plant__contractor'>
          <Field<CheckboxFieldProps>
            name='isMainContractor'
            component={CheckboxField}
            label='Main Contractor Plant'
            checked={this.props.variant.isMainContractor}
            onValueChange={this.onIsMainContractorChange}
            disabled={this.props.readonly}
          />
        </div>
        <div className='database-resource-plant__shift-hours'>
          <Field<MaterialNumberInputProps>
            name='shiftHours'
            component={MaterialNumberInputField}
            label='Shift Hours'
            isFloatingLabel={false}
            value={this.props.variant.shiftHours}
            onChange={this.onShiftHoursChange}
            valueType='integer'
            placeholder='0'
            disabled={this.props.readonly}
            displayBottomInformation={true}
          />
        </div>
        <div className='database-resource-plant__rent-time'>
          <Field<MaterialNumberInputProps>
            name='rentTime'
            component={MaterialNumberInputField}
            label='Rent Time'
            isFloatingLabel={false}
            value={this.props.variant.rentTime}
            onChange={this.onRentTimeChange}
            valueType='integer'
            placeholder='0'
            disabled={this.props.readonly}
            displayBottomInformation={true}
          />
        </div>
        <div className='database-resource-plant__shift-hours'>
          <MaterialNumberInput
            label='Cost per Shift'
            isFloatingLabel={false}
            value={this.state.costPerShift}
            onChange={this.onCostPerShiftChange}
            placeholder='0.00'
            precision={2}
            valueType='float'
            disabled={this.props.readonly}
            displayBottomInformation={true}
          />
        </div>
        <div className='database-resource-plant__cost-per-hour'>
          <Field<MaterialNumberInputProps>
            name='cost'
            component={MaterialNumberInputField}
            label={`Cost Per Hour (${this.props.i18n.currency.symbol})`}
            isFloatingLabel={false}
            value={this.props.variant.cost}
            onChange={this.onCostPerHourChange}
            placeholder='0.00'
            precision={2}
            valueType='float'
            disabled={this.props.readonly}
            displayBottomInformation={true}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onNameChange(_: React.ChangeEvent, name: string): void {
    this.props.onChange({
      ...this.props.variant,
      name,
    });
  }

  @autobind
  private onNameBlur(_: React.ChangeEvent, value: string): void {
    const name = value.trim();
    this.props.onChange({
      ...this.props.variant,
      name,
    });
  }

  @autobind
  private onRentTimeChange(_: React.ChangeEvent, rentTime: number): void {
    this.props.onChange({
      ...this.props.variant,
      rentTime,
    });
  }

  @autobind
  private onShiftHoursChange(_: React.ChangeEvent, shiftHours: number): void {
    this.props.onChange({
      ...this.props.variant,
      shiftHours,
    });
    this.setState({ costPerShift: this.props.variant.cost * shiftHours });
  }

  @autobind
  private onCostPerShiftChange(_: React.ChangeEvent, costPerShift: number): void {
    const costPerHour = costPerShift / this.props.variant.shiftHours;
    this.setState({ costPerShift }, () => {
      this.props.onChange({
        ...this.props.variant,
        cost: costPerHour,
      });
    });
  }

  @autobind
  private onIsMainContractorChange(isMainContractor: boolean): void {
    this.props.onChange({
      ...this.props.variant,
      isMainContractor,
    });
  }

  @autobind
  private onCostPerHourChange(_: React.ChangeEvent, costPerHour: number): void {
    this.props.onChange({
      ...this.props.variant,
      cost: costPerHour,
    });
    this.setState({ costPerShift: this.props.variant.shiftHours * costPerHour });
  }
}

export const DatabaseResourcePlantVariant = withI18n(DatabaseResourcePlantVariantComponent);
