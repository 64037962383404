import earcut from 'earcut';

import { arrayUtils } from 'common/utils/array-utils';
import { ShortPointDescription, Vector3D } from '../../interfaces';
import { DrawingsCanvasUtils } from '../drawings-canvas-utils';
import { Vector2Utils } from '../math-utils/vector2-utils';
import { Vector3Utils } from '../math-utils/vector3-utils';

function pitchPointsByAxis(
  points: ShortPointDescription[],
  axisStart: ShortPointDescription,
  axisEnd: ShortPointDescription,
  pitchAngleDeg: number,
): Vector3D[] {
  const angleRad = (pitchAngleDeg * Math.PI) / 180.0;
  const tan = Math.tan(angleRad);
  return points.map((p) => {
    const projection = Vector2Utils.pointProjectionToSegment(p, axisStart, axisEnd);
    const length = DrawingsCanvasUtils.calculateLineLength(projection, p);
    return [p[0], p[1], length * tan];
  });
}


function pitchPolygonWithHoles(
  polygon2D: ShortPointDescription[],
  polygonHoles2D: ShortPointDescription[][],
  pitchAngleDeg: number,
  segmentIndex: number,
): Vector3D[][] {
  if (!pitchAngleDeg) {
    return [
      polygon2D.map((p) => [p[0], p[1], 0]),
      ...(polygonHoles2D.map((hole) => hole.map((p) => [p[0], p[1], 0])) as Vector3D[][]),
    ];
  }
  const segmentStart2D = polygon2D[segmentIndex];
  const segmentEnd2D = polygon2D[segmentIndex + 1] || polygon2D[0];

  return [
    pitchPointsByAxis(polygon2D, segmentStart2D, segmentEnd2D, pitchAngleDeg),
    ...polygonHoles2D.map((hole) => pitchPointsByAxis(hole, segmentStart2D, segmentEnd2D, pitchAngleDeg)),
  ];
}


interface ConvertionResult {
  indices: Uint16Array;
  vertices: Float32Array;
}

function extrudePolygonWithHoles(
  vertices3D: Vector3D[][],
  holesIndices: number[],
  height: number,
): ConvertionResult {
  const flattened = earcut.flatten(vertices3D);
  const earcutIndices = earcut(flattened.vertices, flattened.holes, 3);
  const vertices: number[][] = [];
  const faces: number[][] = [];

  for (let i = 0; i < flattened.vertices.length; i += 3) {
    vertices.push([flattened.vertices[i], flattened.vertices[i + 1], flattened.vertices[i + 2]]);
  }
  for (let i = 0; i < flattened.vertices.length; i += 3) {
    vertices.push([flattened.vertices[i], flattened.vertices[i + 1], flattened.vertices[i + 2] + height]);
  }
  for (let i = 0; i < earcutIndices.length; i += 3) {
    faces.push([earcutIndices[i], earcutIndices[i + 1], earcutIndices[i + 2]]);
  }

  for (let i = 0; i < earcutIndices.length; i += 3) {
    faces.push([
      earcutIndices[i] + flattened.vertices.length / 3,
      earcutIndices[i + 2] + flattened.vertices.length / 3,
      earcutIndices[i + 1] + flattened.vertices.length / 3,
    ]);
  }
  const numTopVertices = flattened.vertices.length / 3;
  holesIndices.unshift(0);
  holesIndices.push(numTopVertices);

  for (let h = 0; h < holesIndices.length - 1; h++) {
    const start = holesIndices[h];
    const end = holesIndices[h + 1];
    for (let i = start; i < end; i++) {
      const next = i + 1 < end ? i + 1 : start;
      faces.push([i, next, next + numTopVertices]);
      faces.push([i, next + numTopVertices, i + numTopVertices]);
    }
  }

  return {
    vertices: new Float32Array(arrayUtils.flatArray(vertices)),
    indices: new Uint16Array(arrayUtils.flatArray(faces)),
  };
}


interface CreatePolygonConfig {
  polygon2D: ShortPointDescription[];
  polygonHoles2D: ShortPointDescription[][];
  pitchAngleDeg?: number;
  segmentIndex?: number;
  height: number;
}

function createPitched3DPolygonWithHeightAndHoles(
  {
    polygon2D,
    polygonHoles2D,
    pitchAngleDeg = 0,
    segmentIndex = 0,
    height,
  }: CreatePolygonConfig,
): ConvertionResult {
  const pitched = pitchPolygonWithHoles(polygon2D, polygonHoles2D, pitchAngleDeg, segmentIndex);
  return extrudePolygonWithHoles(
    pitched,
    pitched.reduce<number[]>((result, current, index, array) => {
      if (index === array.length - 1) {
        return result;
      } else if (index === 0) {
        result.push(current.length);
      } else {
        result.push(result[result.length - 1] + current.length);
      }
      return result;
    }, []),
    height,
  );
}

function pitchPolyline(polyline2d: ShortPointDescription[], pitch: number): Vector3D[] {
  if (!pitch) {
    return polyline2d.map((p) => [p[0], p[1], 0]);
  }

  const [first, second] = polyline2d;
  const perpendicularToLine = Vector2Utils.peprendicularVectorToLine(first, second);
  const axisEnd = Vector2Utils.add(first, perpendicularToLine);

  return pitchPointsByAxis(polyline2d, first, axisEnd, pitch);
}

interface CreatePolylineConfig {
  polyline2D: ShortPointDescription[];
  pitch?: number;
  height: number;
}

function convert2DPolygine(
  {
    polyline2D,
    pitch = 0,
    height,
  }: CreatePolylineConfig,
): ConvertionResult {
  const pitched = pitchPolyline(polyline2D, pitch);

  const heightVector = [0, 0, height] as Vector3D;

  const vertices = pitched.reduce<number[]>((current, vertice) => {
    current.push(...vertice);
    current.push(...Vector3Utils.add(vertice, heightVector));
    return current;
  }, []);

  const faces = [];
  const vertexCount = polyline2D.length;
  for (let i = 0; i < vertexCount - 1; i++) {
    const nextIndex = (i + 1) % vertexCount;
    const currBase = i * 2;
    const currTop = i * 2 + 1;
    const nextBase = nextIndex * 2;
    const nextTop = nextIndex * 2 + 1;
    faces.push(currBase, nextBase, currTop);
    faces.push(currTop, nextBase, nextTop);
  }

  return {
    indices: new Uint16Array(faces),
    vertices: new Float32Array(vertices),
  };
}

export const ThreeDMeasuresCreators = {
  convert2DPolygine,
  createPitched3DPolygonWithHeightAndHoles,
};
