import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconTooltipInfo: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
    <g id='icon_help' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.3'>
        <path
          d='M7,13 C3.6862915,13 1,10.3137085 1,7 C1,3.6862915 3.6862915,1 7,1 C10.3137085,1 13,3.6862915 13,7
          C13,10.3137085 10.3137085,13 7,13 Z M7,12 C9.76142375,12 12,9.76142375 12,7 C12,4.23857625 9.76142375,2 7,2
          C4.23857625,2 2,4.23857625 2,7 C2,9.76142375 4.23857625,12 7,12 Z M7.45900027,9.9104999
          C7.45900027,10.1309999 7.44550027,10.212 7.39150026,10.32 C7.32850025,10.4415 7.19800023,10.536
          7.00000019,10.536 C6.80200016,10.536 6.67150014,10.4415 6.60850013,10.32 C6.55450012,10.212
          6.54100012,10.1309999 6.54100012,9.9104999 L6.54100012,6.52649935 C6.54100012,6.30599931 6.55450012,6.2249993
          6.60850013,6.11699928 C6.67150014,5.99549926 6.80200016,5.90099925 7.00000019,5.90099925
          C7.19800023,5.90099925 7.32850025,5.99549926 7.39150026,6.11699928 C7.44550027,6.2249993
          7.45900027,6.30599931 7.45900027,6.52649935 L7.45900027,9.9104999 Z M6.4465001,4.44749901
          C6.4465001,4.14149896 6.69400014,3.89399892 7.00000019,3.89399892 C7.30600024,3.89399892
          7.55350029,4.14149896 7.55350029,4.44749901 C7.55350029,4.75349906 7.30600024,5.0009991 7.00000019,5.0009991
          C6.69400014,5.0009991 6.4465001,4.75349906 6.4465001,4.44749901 Z'
          id='Combined-Shape'
          fill={KreoColors.f2}
          fillRule='nonzero'
        />
    </g>
    </svg>
  );
};
