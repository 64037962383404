import { SubscriptionType } from 'common/constants/subscription';
import { CreateOrganizationFormData } from '../../../units/empty-organizations/components/create-organization-dialog';
import { UpdateCompany } from '../interfaces/company';
import { CompanySubscriptionModel } from '../interfaces/company-subscription-model';

export interface ChangeCompanySubscriptionPayload {
  companyId: number;
  subscription: CompanySubscriptionModel;
  subscriptionType: SubscriptionType;
}

export interface MotivationPopupSettingsPayload {
  timeInSecondsBeforeOpen: number;
  timeInSecondsBeforeClose: number;
}

export interface AccountDataPayload {
  firstName: string;
  lastName: string;
  companyName?: string;
  companyId?: number;
  email: string;
  avatar?: string;
  avatarFile?: string;
}

export enum ValidationField {
  VatNumber = 'vat_number',
  RenewSubscription = 'renew_subscription',
}

export interface SubscribeErrorPayload {
  field: ValidationField;
  errorText: string;
}

export interface UpdateCompanyPayload {
  data: UpdateCompany;
  id: number;
}

export interface CreateCompanyPayload {
  data: CreateOrganizationFormData;
  logoTemporaryKey?: string;
}
