export const findInvalidCategories = (categories: string[]): Set<number> => {
  const ret = new Set<number>();
  categories.forEach((value, index) => {
    if (value.toLowerCase().match('invalid') !== null) {
      ret.add(index);
    }
  });
  return ret;
};

