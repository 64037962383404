import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDrawingsLayoutApi } from 'common/components/drawings/drawings-layout-api-context';
import {
  DrawingsGeometryGroup,
  DrawingsGeometryInstance,
  DrawingsInstanceMeasure,
} from 'common/components/drawings/interfaces';
import { DrawingInstancesAggregationUtils } from 'common/components/drawings/utils/instances-utils/aggregation-utils';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { AggreagatedProperties } from '../interfaces';


function mapGroupPropeties(
  groupInstances: Record<string, string[]>,
  groups: DrawingsGeometryGroup[],
): Record<string, { name: string, instancesIds: string[] }> {
  const groupsDictionary = Object.keys(groupInstances).length > 1
    ? arrayUtils.toDictionary(groups, x => x.id, x => x.name)
    : {};

  return Object.entries(groupInstances).reduce((acc, [groupId, instancesIds]) => {
    const name = groupId === 'undefined'
      ? 'Without group' : (groupsDictionary[groupId] || groups.find(x => x.id === groupId)?.name || 'Unknown');
    acc[groupId] = {
      instancesIds,
      value: groupId,
      name,
    };
    return acc;
  }, {});
}


export function aggregateInstanceProperties(
  selectedInstances: string[],
  instances: Record<string, DrawingsGeometryInstance>,
  groups: DrawingsGeometryGroup[],
  getInstancesMeasures: (instanceId: string[]) => DrawingsInstanceMeasure[],
): AggreagatedProperties {
  const {
    names,
    type,
    strokeStyle,
    strokeWidth,
    color,
    shape,
    hasStroke,
    hasCount,
    group: groupInstances,
    height,
    thickness,
    offsets,
    measures,
  } = DrawingInstancesAggregationUtils.aggregateInstances(
    selectedInstances,
    instances,
    getInstancesMeasures,
  );

  const uniqNames = arrayUtils.uniq(names);

  return {
    type: Object.entries(type).reduce((acc, [key, value]) => {
      if (value.length > 0) {
        acc[key] = value;
      }
      return acc;
    }, {}),
    strokeStyle,
    strokeWidth,
    color,
    shape,
    group: mapGroupPropeties(groupInstances, groups),
    measures,
    name: uniqNames.length === 1 ? uniqNames[0] : 'Multiple names',
    hasStroke,
    hasCount,
    height,
    thickness,
    offsets,
  };
}

export function useSelectedInstancesParameters(): {
  aggregatedProperties: AggreagatedProperties,
  selectedInstances: string[],
  instances: Record<string, DrawingsGeometryInstance>,
  } {
  const drawingsLayoutApi = useDrawingsLayoutApi();
  const selectedInstances = useSelector<State, string[]>(state => state.drawings.selectedInstances);
  const instances = useSelector<State, Record<string, DrawingsGeometryInstance>>(
    state => state.drawings.aiAnnotation.geometry,
  );
  const groups = useSelector<State, DrawingsGeometryGroup[]>(
    state => state.drawings.drawingGeometryGroups);

  const aggregatedProperties = useMemo(
    () => {
      return aggregateInstanceProperties(
        selectedInstances,
        instances,
        groups,
        drawingsLayoutApi.getInstancesMeasuresSimple,
      );
    },
    [selectedInstances, instances, groups, drawingsLayoutApi],
  );

  return {
    aggregatedProperties,
    selectedInstances,
    instances,
  };
}

