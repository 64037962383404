import QueryString from 'query-string';

import { CompanyResourcesApi } from '../../../api/server';
import { CalculateActivityAssignmentPayload } from '../actions/payloads/activity-assignment';
import {
  ActivityAssignmentResponse,
  ActivityAssignmentResponseC,
} from '../interfaces/activity-assignment/activity-assignment-response-data';
import {
  ActivityAssignmentVariantMaterialsResponse,
  ActivityAssignmentVariantMaterialsResponseC,
} from '../interfaces/activity-assignment/activity-assignment-variant-materials-response';
import { ActivityAssignmentActivity } from '../interfaces/activity-assignment/activity-assignmnet-activity';

function calculate(
  payload: CalculateActivityAssignmentPayload,
): Promise<void> {
  const { projectId } = payload;
  return CompanyResourcesApi.post(`/projects/${projectId}/activity-assignment/calculate`, payload);
}

function getData(
  projectId: number,
): Promise<ActivityAssignmentResponse> {
  return CompanyResourcesApi.get(`/projects/${projectId}/activity-assignment`);
}

function getWork(
  projectId: number,
  workId: number,
): Promise<ActivityAssignmentResponse> {
  return CompanyResourcesApi.getV(
    `/projects/${projectId}/activity-assignment/works/${workId}`,
    ActivityAssignmentResponseC);
}

function saveWork(
  projectId: number,
  activity: ActivityAssignmentActivity,
): Promise<void> {
  return CompanyResourcesApi.patch(`/projects/${projectId}/activity-assignment`, activity);
}

function getActivitiesMaterials(
  projectId: number,
  activitiesIds: string[],
): Promise<ActivityAssignmentVariantMaterialsResponse> {
  const query = QueryString.stringify({ activityVariantId: activitiesIds });
  return CompanyResourcesApi.getV(
    `/projects/${projectId}/activity-assignment/activity-variant-materials?${query}`,
    ActivityAssignmentVariantMaterialsResponseC,
  );
}

export const ActivityAssignmentApi = {
  calculate,
  getData,
  getWork,
  saveWork,
  getActivitiesMaterials,
};
