import { useCallback } from 'react';

import { MagicSearchActions } from 'common/components/drawings/actions/creators/magic-search';
import { useActionDispatch } from 'common/hooks';
import { MetricNames, useAnalytics } from 'utils/posthog';

export function useRun(): (dpi: number) => void {
  const { sendEvent } = useAnalytics();
  const runSearch = useActionDispatch(MagicSearchActions.runSearch);

  return useCallback((dpi: number) => {
    runSearch();
    sendEvent(MetricNames.measurements.requestMagicSearch, { dpi });
  }, [runSearch, sendEvent]);
}
