import autobind from 'autobind-decorator';
import * as React from 'react';

import './timeline-cell.scss';

import { TimeframeAnimationTarget } from '../../../../../../interfaces/gantt-chart';
import { getQuarter } from '../../timeline-data-provider';
import { TimelineScale } from '../../timeline-scale';

const hourMs = 60 * 60 * 1000;
const dayMs = 24 * hourMs;

const weekDays = 7;
const monthDays = 30.475;
const quarterDays = 3 * monthDays;
const yearDays = 365;

interface TimelineCellProps {
  width: number;
  scale: TimelineScale;
  startDate: Date;
  projectStartDate: Date;
  duration: number;
  timeframeDuration: number;
  timelineDuration: number;
  triggerTimeframeChangeAnimation: (target: TimeframeAnimationTarget) => void;
}

export class TimelineCell extends React.Component<TimelineCellProps> {
  public shouldComponentUpdate(nextProps: TimelineCellProps): boolean {
    return this.props.width !== nextProps.width
      || this.props.scale !== nextProps.scale
      || this.props.startDate.getTime() !== nextProps.startDate.getTime();
  }

  public render(): JSX.Element {
    return (
      <div
        className='timeline-cell'
        style={{
          width: `${this.props.width}px`,
        }}
        onClick={this.props.scale !== TimelineScale.Hour ? this.zoomIn : null}
        onContextMenu={this.zoomOut}
      >
        <div className='timeline-cell__label-area'>
          {this.getFormattedDate()}
        </div>
        <div className='timeline-cell__border-area' />
      </div>
    );
  }

  private getFormattedDate(): string {
    switch (this.props.scale) {
      case TimelineScale.Year:
        return this.props.startDate.getFullYear().toString();
      case TimelineScale.Quarter: {
        const quarter = getQuarter(this.props.startDate.getMonth());
        return `Q${quarter} ${this.props.startDate.getFullYear()}`;
      }
      case TimelineScale.Month:
        return this.props.startDate.toLocaleDateString(
          'en-GB',
          { month: 'short', year: 'numeric' });
      case TimelineScale.Week:
        return this.props.startDate.toLocaleDateString(
          'en-GB',
          { day: 'numeric', month: 'short', year: 'numeric' });
      case TimelineScale.Day:
        return this.props.startDate.toLocaleDateString(
          'en-GB',
          { day: 'numeric', month: 'short' });
      case TimelineScale.Hour:
        return this.props.startDate.toLocaleDateString(
          'en-GB',
          { hour12: true, hour: 'numeric', day: 'numeric', month: 'short' });
      default:
        return '';
    }
  }

  @autobind
  private zoomIn(): void {
    const startDay = this.getDayFromProjectStart(this.props.startDate);
    const currentMoment = startDay + this.props.duration / 2;
    this.props.triggerTimeframeChangeAnimation({
      currentMoment,
      timeframeDuration: this.props.duration,
    });
  }

  @autobind
  private zoomOut(event: React.MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
    let targetDuration: number;

    const currentTimeframeDuration = this.props.timeframeDuration;

    if (currentTimeframeDuration < weekDays) {
      targetDuration = weekDays;
    } else if (currentTimeframeDuration < monthDays) {
      targetDuration = monthDays;
    } else if (currentTimeframeDuration < quarterDays) {
      targetDuration = quarterDays;
    } else if (currentTimeframeDuration < yearDays) {
      targetDuration = yearDays;
    } else {
      targetDuration = this.props.timelineDuration;
    }

    const startDay = this.getDayFromProjectStart(this.props.startDate);
    const cellMiddle = startDay + this.props.duration / 2;
    this.props.triggerTimeframeChangeAnimation({
      currentMoment: cellMiddle,
      timeframeDuration: targetDuration,
    });
  }

  private getDayFromProjectStart(date: Date): number {
    return (date.getTime() - this.props.projectStartDate.getTime()) / dayMs;
  }
}
