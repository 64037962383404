import { H6, HotKeys, ModalWrapper, RectangleButton, TinyText } from '@kreo/kreo-ui-components';
import { Loader } from '@kreo/kreo-ui-components/icons';
import React from 'react';

import { Styled } from './styled';


export interface CustomDialogProps {
  mainText?: string;
  secondaryText?: string;
  submitText?: string;
  rejectText?: string;
  submitButtonMood?: string;
  cancelButtonMood?: string;
  submitButtonWidth?: number;
  cancelButtonWidth?: number;
  width?: number;
  height?: number;
  padding?: string;
  buttonsHeight?: number;
  zIndex?: number;
  autoHeight?: boolean;
  loaderMode?: boolean;
  className?: string;
  onSubmit?: () => void;
  onReject?: () => void;
  onExit?: () => void;
}

export const ENTER_ESCAPE = {
  enter: ['Enter', 'NumpadEnter'],
  escape: 'Escape',
};


const CustomDialogComponent: React.FC<CustomDialogProps> = ({
  mainText,
  secondaryText,
  onSubmit,
  onReject,
  submitText = 'Delete',
  rejectText = 'Cancel',
  submitButtonMood = 'negative',
  cancelButtonMood,
  width = 370,
  height,
  autoHeight,
  children,
  loaderMode,
  submitButtonWidth,
  cancelButtonWidth,
  padding,
  buttonsHeight,
  className,
})  => {

  if (loaderMode) {
    return (
      <Styled.Container
        containerWidth={width}
        containerHeight={height}
        autoHeight={autoHeight}
        padding={padding}
      >
        <Loader />
      </Styled.Container>
    );
  }
  const withButtons = onSubmit || onReject;
  return (
    <HotKeys keyMap={ENTER_ESCAPE} handlers={{ escape: onReject, enter: onSubmit }} autofocus={true}>
      <Styled.Container
        containerWidth={width}
        containerHeight={height}
        autoHeight={autoHeight}
        padding={padding}
        className={className}
      >
        {mainText ? <H6 textAlign="center">{mainText}</H6> : null}
        {secondaryText && <TinyText textAlign="center" font="Light" color="gray">{secondaryText}</TinyText>}
        {children && <Styled.Body>{children}</Styled.Body>}
        {withButtons &&
          <Styled.Buttons>
            {onSubmit &&
              <RectangleButton
                onClick={onSubmit}
                text={submitText}
                height={buttonsHeight || 50}
                width={submitButtonWidth || 125}
                mood={submitButtonMood}
              />
            }
            {onReject &&
              <RectangleButton
                onClick={onReject}
                height={buttonsHeight || 50}
                width={cancelButtonWidth || 125}
                text={rejectText}
                mood={cancelButtonMood}
              />
            }
          </Styled.Buttons>
        }
      </Styled.Container>
    </HotKeys>
  );
};

export const CustomDialog: React.FC<CustomDialogProps> = (props) => {
  const onExit = React.useCallback(() => {
    if (props.onExit) {
      props.onExit();
    }
    if (props.onReject) {
      props.onReject();
    }
  }, [props.onReject, props.onExit]);

  return (
    <ModalWrapper onExit={onExit} zIndex={props.zIndex}>
      <CustomDialogComponent {...props} />
    </ModalWrapper>
  );
};
