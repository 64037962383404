import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import React from 'react';
import { useSelector } from 'react-redux';

import { DataBaseTable, useGridApiState } from 'common/components/data-base-table';
import { useProjectIdSelector } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { ReportTemplateInfo } from '../../interfaces';
import {
  useDefaultReportTemplateColumns,
  useExtendsContextMenuCallback,
  useHandleDeleteProjectTemplate,
  useLoadTemplateEffect,
  useTransactionEffect,
  useProjectIdRef,
  useReportTemplateCallbacks,
} from './hooks';

const rowsData = [];
const columns = [];

export const ProjectReportTemplateTable: React.FC = () => {
  const projectId = useProjectIdSelector();
  const templates = useSelector<State, ReportTemplateInfo[]>(
    (s) => s.twoDReportTemplate.projectReportTemplates.templates,
  );
  const { deleteProjectReportTemplate, loadProjectReportTempalte, exportReport } = useReportTemplateCallbacks();
  const projectIdRef = useProjectIdRef(projectId);
  const [saveGridRef, gridApiState] = useGridApiState();
  const handleDeleteProjectTemplate = useHandleDeleteProjectTemplate(deleteProjectReportTemplate, projectId);

  const extendReportTemplate = useExtendsContextMenuCallback(handleDeleteProjectTemplate);

  useLoadTemplateEffect(loadProjectReportTempalte, projectId);
  useTransactionEffect(templates, gridApiState);
  const getRowNodeId = React.useCallback((d) => d.id, []);
  const handleExportClick = React.useCallback((id: number) => {
    exportReport(projectIdRef.current, id);
  }, []);

  const defaultColumns = useDefaultReportTemplateColumns(handleExportClick);

  return (
    <DataBaseTable
      key={'ProjectReportTemplateTableComponent'}
      rowData={rowsData}
      columns={columns}
      defaultColumns={defaultColumns}
      onRowSelected={ConstantFunctions.doNothing}
      selectByCheckbox={false}
      suppressRowClickSelection={false}
      autoGroupName='Name'
      onGridReady={saveGridRef}
      extendContextMenuItem={extendReportTemplate}
      getRowNodeId={getRowNodeId}
    />);
};
