
import autobind from 'autobind-decorator';
import { arrayUtils } from 'common/utils/array-utils';
import { objectUtils } from 'common/utils/object-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { DrawingStrokeStyles } from '../../constants/drawing-styles';
import {
  ContextObserver,
} from '../../drawings-contexts';
import { DrawingsInstanceType } from '../../enums/drawings-instance-type';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { MeasuresViewSettings } from '../../interfaces/drawing-text-render-parameters';
import { DrawingsPolygonGeometry, DrawingsPolylineGeometry } from '../../interfaces/drawings-geometry';
import { DrawingsGeometryInstance, DrawingsGeometryInstanceWithId } from '../../interfaces/drawings-geometry-instance';
import { DrawingsGeometryOperationResult } from '../../interfaces/drawings-geometry-operation-result';
import { DrawingAnnotationNamingUtils } from '../../utils/drawing-annotation-naming-utils';
import { DrawingAnnotationUtils } from '../../utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';
import { DrawingsGeometryConverters, GeometryConfig } from '../../utils/drawings-geometry-converters';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';
import { DrawingsPaperUtils } from '../../utils/drawings-paper-utils';
import { EngineObjectConfig } from '../common';
import {
  BatchedUpdateCallback,
  GetColorOfInstanceCallback,
  GetCurrentDrawingCallback,
  GetDrawingInstanceCallback,
  GetInstanceMeasureCallback,
  GetPointCoordinatesCallback,
  SavePointsToCacheCallback,
} from '../interfaces';
import { JoinUtils } from './boolean';
import { CollectionsUnifier } from './collections-unifier';
import { DrawingsOperationResultsPostProcessors } from './common';
import { DrawingsOperationHelper } from './common/operation-helper';
import { DrawingsGeometryEntityHelper } from './drawings-geometry-entity-helper';

interface GeometryBooleanOperationConfig extends EngineObjectConfig {
  entityRenderHelper: DrawingsGeometryEntityHelper;
  textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
  getInstanceMeasures: GetInstanceMeasureCallback;
  getCurrentDrawingInfo: GetCurrentDrawingCallback;
  getInstance: GetDrawingInstanceCallback;
  getColorOfInstances: GetColorOfInstanceCallback;
  getPointCoordinates: GetPointCoordinatesCallback;
  addPointsToCached: SavePointsToCacheCallback;
  onUpdate: BatchedUpdateCallback;
  getInstanceColor: (instanceId: string) => string;
}

interface IntersectionsGraph {
  rootIds: string[];
  nodes: Record<string, string[]>;
  paperPolygons: Record<string, paper.PathItem>;
}

export enum DrawingsBooleanOperation {
  Unite = 'unite',
  Subtract = 'subtract',
  Join = 'join',
  Enclose = 'enclose',
  OpenPolygon = 'openPolygon',
  SplitPolyline = 'splitPolyline',
}

const UNDEFINED_GROUP = 'undefined';

export interface OpenPolygonSettings {
  keepGroups: boolean;
  splitToSegments: boolean;
  color: string;
}

export interface SplitPolylineSettings {
  keepGroups: boolean;
  color: string;
}

export interface DrawingsGeometryOperationSettings {
  [DrawingsBooleanOperation.Unite]: {
    keepOld: boolean,
  };
  [DrawingsBooleanOperation.Subtract]: {
    keepOld: boolean,
    baseInstanceId?: string,
  };
  [DrawingsBooleanOperation.Join]: {
    keepOld: boolean,
  };
  [DrawingsBooleanOperation.Enclose]: {
    keepGroups: boolean,
  };
  [DrawingsBooleanOperation.OpenPolygon]: OpenPolygonSettings;
  [DrawingsBooleanOperation.SplitPolyline]: SplitPolylineSettings;
}

export class DrawingsGeometryBooleanOperationHelper extends DrawingsOperationHelper<GeometryBooleanOperationConfig> {

  public get instacesType(): DrawingsInstanceType {
    return this._config.getInstance(this._instancesIds[0])?.type;
  }

  public processOperation<O extends DrawingsBooleanOperation>(
    operation: DrawingsBooleanOperation,
    settings: DrawingsGeometryOperationSettings[O],
  ): void {
    (this[operation] as (s: DrawingsGeometryOperationSettings[O]) => void)(settings);
  }

  @autobind
  private splitPolyline({ keepGroups, color }: SplitPolylineSettings): void {
    const geometries = new Array<DrawingsGeometryInstance>();
    const newPoints: Record<string, ShortPointDescription> = {};

    const addPoint = (pointId: string): string => {
      const newPointId = UuidUtil.generateUuid();
      newPoints[newPointId] = this._config.getPointCoordinates(pointId);
      return newPointId;
    };

    for (const instanceId of this._instancesIds) {
      const instance = this._config.getInstance(instanceId) as DrawingsGeometryInstance<DrawingsPolylineGeometry>;
      const points = instance.geometry.points;
      for (let i = 1; i < points.length; i++) {
        const newId = UuidUtil.generateUuid();
        geometries.push({
          ...instance,
          type: DrawingsInstanceType.Polyline,
          id: newId,
          groupId: keepGroups ? instance.groupId : undefined,
          geometry: {
            ...instance.geometry,
            points: [addPoint(points[i - 1]), addPoint(points[i])],
            color: color || instance.geometry.color,
          },
        });
      }
    }
    this._config.onUpdate({
      addedInstances: geometries,
      newPoints,
      removedInstances: [],
      pageId: this._config.getCurrentDrawingInfo().drawingId,
    });
  }

  @autobind
  private openPolygon(
    {
      keepGroups,
      splitToSegments,
      color,
    }: OpenPolygonSettings,
  ): void {
    const geometries = new Array<DrawingsGeometryInstance>();
    const newPoints: Record<string, ShortPointDescription> = {};

    const remapLine = (line: string[], close: boolean): string[] => {
      const result = [];
      for (const pointId of line) {
        const point = this._config.getPointCoordinates(pointId);
        const newId = UuidUtil.generateUuid();
        result.push(newId);
        newPoints[newId] = point;
      }
      if (close) {
        const startPoint = result[0];
        const newPointId = UuidUtil.generateUuid();
        newPoints[newPointId] = newPoints[startPoint];
        result.push(newPointId);
      }
      return result;
    };

    if (splitToSegments) {
      for (const instanceId of this._instancesIds) {
        const instance = this._config.getInstance(instanceId) as DrawingsGeometryInstance<DrawingsPolygonGeometry>;
        for (
          const segment
          of DrawingAnnotationUtils.iteratePoints(instance.geometry.points, DrawingsInstanceType.Polygon)
        ) {
          const points = remapLine(segment, false);
          geometries.push({
            ...instance,
            type: DrawingsInstanceType.Polyline,
            id: UuidUtil.generateUuid(),
            groupId: keepGroups ? instance.groupId : undefined,
            geometry: {
              points,
              color: color || instance.geometry.color,
              strokeStyle: instance.geometry.strokeStyle,
              strokeWidth: instance.geometry.strokeWidth,
            },
          });
        }
        if (instance.geometry.children) {
          for (const child of instance.geometry.children) {
            for (const segement of DrawingAnnotationUtils.iteratePoints(child, DrawingsInstanceType.Polygon)) {
              const points = remapLine(segement, false);
              geometries.push({
                ...instance,
                type: DrawingsInstanceType.Polyline,
                id: UuidUtil.generateUuid(),
                groupId: keepGroups ? instance.groupId : undefined,
                geometry: {
                  points,
                  color: color || instance.geometry.color,
                  strokeStyle: instance.geometry.strokeStyle,
                  strokeWidth: instance.geometry.strokeWidth,
                },
              });
            }
          }
        }
      }
    } else {
      for (const instanceId of this._instancesIds) {

        const instance = this._config.getInstance(instanceId) as DrawingsGeometryInstance<DrawingsPolygonGeometry>;
        const mainContour = remapLine(instance.geometry.points, true);

        geometries.push({
          ...instance,
          type: DrawingsInstanceType.Polyline,
          id: UuidUtil.generateUuid(),
          groupId: keepGroups ? instance.groupId : undefined,
          geometry: {
            points: mainContour,
            color: color || instance.geometry.color,
            strokeStyle: instance.geometry.strokeStyle,
            strokeWidth: instance.geometry.strokeWidth,
          },
        });

        if (instance.geometry.children) {
          for (const child of instance.geometry.children) {
            const childContour = remapLine(child, true);
            geometries.push({
              ...instance,
              type: DrawingsInstanceType.Polyline,
              id: UuidUtil.generateUuid(),
              groupId: keepGroups ? instance.groupId : undefined,
              geometry: {
                points: childContour,
                color: color || instance.geometry.color,
                strokeStyle: instance.geometry.strokeStyle,
                strokeWidth: instance.geometry.strokeWidth,
              },
            });
          }
        }
      }
    }
    this._config.onUpdate({
      addedInstances: geometries,
      newPoints,
      removedInstances: [],
      pageId: this._config.getCurrentDrawingInfo().drawingId,
    });
  }

  @autobind
  private enclose({ keepGroups }: DrawingsGeometryOperationSettings[DrawingsBooleanOperation.Enclose]): void {
    const geometries = new Array<DrawingsGeometryInstance>();
    const newPoints: Record<string, ShortPointDescription> = {};
    for (const instanceId of this._instancesIds) {
      const path = this._config.entityRenderHelper.getInstancePath(instanceId);
      if (!path || path.segments.length < 3) {
        continue;
      }
      const pathClone = path.clone() as paper.Path;
      if (!pathClone.firstSegment.point.equals(pathClone.lastSegment.point)) {
        pathClone.addSegments([pathClone.firstSegment]);
      }
      if (DrawingsPaperUtils.isPolygonSelfIntersected(pathClone)) {
        pathClone.remove();
        continue;
      }
      pathClone.removeSegment(pathClone.segments.length - 1);
      const instance = this._config.getInstance(instanceId) as DrawingsGeometryInstance<DrawingsPolylineGeometry>;
      const convertedResult = DrawingsGeometryConverters.convertPathToPolygons(
        pathClone as paper.Path,
        {},
        {
          strokeStyle: instance.geometry.strokeStyle,
          strokeWidth: instance.geometry.strokeWidth,
          color: instance.geometry.color,
        },
      );
      pathClone.remove();
      objectUtils.extend(newPoints, convertedResult.newPoints);
      for (const [isValidPolygon, geometry] of convertedResult.geometriesIterator) {
        if (isValidPolygon) {
          geometries.push({
            ...instance,
            groupId: keepGroups ? instance.groupId : undefined,
            type: DrawingsInstanceType.Polygon,
            id: UuidUtil.generateUuid(),
            geometry,
          });
        }
      }
    }
    if (geometries.length) {
      this._config.onUpdate(
        {
          addedInstances: geometries,
          newPoints,
          removedInstances: [],
          pageId: this._config.getCurrentDrawingInfo().drawingId,
        },
      );
    }
  }

  @autobind
  private unite(
    { keepOld }: DrawingsGeometryOperationSettings[DrawingsBooleanOperation.Unite],
  ): void {
    this.polygonsBoolean(DrawingsBooleanOperation.Unite, keepOld);
  }

  @autobind
  private subtract({
    keepOld,
    baseInstanceId,
  }: DrawingsGeometryOperationSettings[DrawingsBooleanOperation.Subtract]): void {
    this.polygonsBoolean(DrawingsBooleanOperation.Subtract, keepOld, baseInstanceId);
  }

  private polygonsBoolean(
    operation: DrawingsBooleanOperation,
    keepOld: boolean,
    baseInstanceId?: string,
  ): void {
    let geometries: DrawingsGeometryInstanceWithId[];
    const operationResult = this.execute(operation, this._instancesIds, keepOld, baseInstanceId);
    const { polygonsGeometry, removedInstances, newPoints, isValid } = operationResult;
    if (!isValid) {
      return;
    }
    this._config.addPointsToCached(newPoints);
    const currentDrawingId = this._config.getCurrentDrawingInfo().drawingId;

    if (operation === DrawingsBooleanOperation.Subtract && !keepOld) {
      const baseInstanceGroupId = this._config.getInstance(baseInstanceId).groupId;
      if (polygonsGeometry[baseInstanceGroupId]) {
        this.operationAsEdit(baseInstanceId, newPoints, polygonsGeometry[baseInstanceGroupId], removedInstances);
      }
      return;
    } else {
      const castedType = DrawingAnnotationNamingUtils.getDefaultGeometryName(DrawingsInstanceType.Polygon);
      geometries = [];
      for (const [groupId, groupGeometries] of Object.entries(polygonsGeometry)) {
        for (const geometry of groupGeometries) {
          const id = UuidUtil.generateUuid();
          geometries.push({
            id,
            drawingId: currentDrawingId,
            type: DrawingsInstanceType.Polygon,
            name: castedType,
            geometry,
            isAuto: false,
            groupId: groupId !== UNDEFINED_GROUP ? groupId : undefined,
          });
        }
      }
    }
    if (geometries.length || removedInstances.length) {
      this._config.onUpdate({
        removedInstances,
        newPoints,
        addedInstances: geometries,
        pageId: currentDrawingId,
      });
    }
  }

  @autobind
  private join({ keepOld }: DrawingsGeometryOperationSettings['join']): void {
    const drawingId = this._config.getCurrentDrawingInfo().drawingId;
    const instanceType = this._config.getInstance(this._instancesIds[0]).type;
    const currentDrawingInstancesIds =
      this._instancesIds.filter((id) => this._config.getInstance(id).drawingId === drawingId);
    const joinFunction = instanceType === DrawingsInstanceType.Count ? JoinUtils.joinCount : JoinUtils.joinPolyline;
    const { instance, newPoints } = joinFunction({
      instancesIds: currentDrawingInstancesIds,
      getInstance: this._config.getInstance,
      getPoint: this._config.getPointCoordinates,
      drawingId,
    });
    this._config.onUpdate(
      {
        addedInstances: [instance],
        newPoints,
        removedInstances: keepOld ? [] : currentDrawingInstancesIds,
        pageId: drawingId,
      },
    );
  }

  private operationAsEdit(
    baseInstanceId: string,
    newPoints: Record<string, ShortPointDescription>,
    polygonsGeometry: DrawingsPolygonGeometry[],
    removedInstances: string[],
  ): void {
    const instance =
      this._config.getInstance(baseInstanceId) as DrawingsGeometryInstanceWithId<DrawingsPolygonGeometry>;
    const {
      changes,
      oldMeasures,
      newMeasures,
    } = DrawingsOperationResultsPostProcessors.processStrokedGeometryResultAsEdit(
      baseInstanceId,
      instance,
      newPoints,
      polygonsGeometry,
      removedInstances,
      this._config.getCurrentDrawingInfo().drawingId,
      this._config.getPointCoordinates,
      this._config.getInstanceMeasures,
      this._config.textRenderParamsObserver.getContext(),
      this._config.getColorOfInstances,
    );
    this._config.addPointsToCached(changes.newPoints);
    this._config.onUpdate(changes, newMeasures, oldMeasures);
  }

  private execute(
    operation: DrawingsBooleanOperation,
    instancesIds: string[],
    keepOld: boolean,
    baseId?: string,
  ): DrawingsGeometryOperationResult {
    let intersectionGraph: IntersectionsGraph;
    if (baseId) {
      intersectionGraph = this.calculateIntersectionsById(baseId, instancesIds);
    } else {
      intersectionGraph = this.calculateIntersectionsGraphs(instancesIds);
    }

    const { nodes, rootIds, paperPolygons } = intersectionGraph;
    const coordinatesIds: Record<number, Record<number, string>> = {};
    let addPoints: Record<string, ShortPointDescription> = {};
    const newInstances: Record<string, DrawingsPolygonGeometry[]> = {};
    const removedInstances = [];

    let isValid = true;

    for (const rootId of rootIds) {
      if (!nodes[rootId].length) {
        continue;
      }
      const baseInstance = this._config.getInstance(rootId) as DrawingsGeometryInstance<DrawingsPolygonGeometry>;
      const unifier = new CollectionsUnifier<GeometryConfig & { groupId: string }>();
      unifier.add({
        ...baseInstance.geometry,
        groupId: baseInstance.groupId,
      });

      let polygonGeometry = paperPolygons[rootId].clone();
      removedInstances.push(rootId);
      const children = nodes[rootId].slice();
      const processedElements = {};
      while (children.length) {
        const id = children.pop();
        if (processedElements[id]) {
          continue;
        }

        const instance = this._config.getInstance(id) as DrawingsGeometryInstance<DrawingsPolygonGeometry>;
        if (!baseId) {
          unifier.add({
            ...instance.geometry,
            groupId: instance.groupId,
          });
        }

        removedInstances.push(id);
        processedElements[id] = true;
        const geometry = paperPolygons[id] as paper.Path;
        const newPolygon = polygonGeometry[operation](geometry);
        polygonGeometry.remove();
        polygonGeometry = newPolygon;
        if (nodes[id]) {
          arrayUtils.extendArray(children, nodes[id]);
        }
      }

      const { groupId, strokeStyle, strokeWidth, color, height } = unifier.getUnifiedOrDefault({
        groupId: UNDEFINED_GROUP,
        strokeStyle: DrawingStrokeStyles.Normal,
        strokeWidth: DrawingsCanvasConstants.lineStroke,
        color: DrawingsCanvasUtils.getColorFromList(),
      });
      const convertedResult = DrawingsGeometryConverters.convertPathToPolygons(
        polygonGeometry as paper.Path,
        coordinatesIds,
        { strokeStyle, strokeWidth, color },
      );
      if (convertedResult) {
        const { newPoints, geometriesIterator: iterator } = convertedResult;
        if (!newInstances[groupId]) {
          newInstances[groupId] = [];
        }
        for (const [isValidPolygon, geometry] of iterator) {
          if (!isValidPolygon) {
            isValid = false;
          }
          if (height) {
            geometry.height = height;
          }
          newInstances[groupId].push(geometry);
        }
        addPoints = { ...addPoints, ...newPoints };
      }
      polygonGeometry.remove();
    }
    return {
      removedInstances: keepOld ? [] : arrayUtils.uniq(removedInstances),
      polygonsGeometry: newInstances,
      newPoints: addPoints,
      isValid,
    };
  }

  private calculateIntersectionsById(
    baseId: string,
    selectedInstances: string[],
  ): IntersectionsGraph {
    const rootIds = [baseId];
    const rootPolygon = this._config.entityRenderHelper.getInstancePath(baseId) as paper.CompoundPath;
    const paperPolygons: Record<string, paper.CompoundPath> = { [baseId]: rootPolygon };
    const nodes: Record<string, string[]> = { [baseId]: [] };
    for (const polygon of DrawingsGeometryUtils.selectedPolygonsIterator(selectedInstances, this._config.getInstance)) {
      if (polygon.id === baseId || !this._config.entityRenderHelper.isInstanceRendered(polygon.id)) {
        continue;
      }
      const currentPolygonGeometry =
        this._config.entityRenderHelper.getInstancePath(polygon.id) as paper.CompoundPath;
      paperPolygons[polygon.id] = currentPolygonGeometry;
      if (DrawingsPaperUtils.arePolygonsIntersected(rootPolygon, currentPolygonGeometry)) {
        nodes[baseId].push(polygon.id);
      }
    }
    return {
      rootIds,
      nodes,
      paperPolygons,
    };
  }

  private calculateIntersectionsGraphs(
    selectedInstances: string[],
  ): IntersectionsGraph {
    const polygons = new Array<DrawingsGeometryInstanceWithId<DrawingsPolygonGeometry>>();
    const fromTo = new Map<string, string[]>();
    const ids = [];
    const paperPolygons: Record<string, paper.PathItem> = {};

    for (const polygon of DrawingsGeometryUtils.selectedPolygonsIterator(selectedInstances, this._config.getInstance)) {
      if (!this._config.entityRenderHelper.isInstanceRendered(polygon.id)) {
        continue;
      }

      const currentPolygonGeometry =
        this._config.entityRenderHelper.getInstancePath(polygon.id) as paper.CompoundPath;
      paperPolygons[polygon.id] = currentPolygonGeometry;
      const to = [];
      for (const checkPolygon of polygons) {
        const polygonVisual =
          this._config.entityRenderHelper.getInstancePath(checkPolygon.id) as paper.CompoundPath;
        paperPolygons[checkPolygon.id] = polygonVisual;
        if (DrawingsPaperUtils.intersectsOrInclude(currentPolygonGeometry, polygonVisual)) {
          to.push(checkPolygon.id);
        } else if (DrawingsPaperUtils.hasPointInside(polygonVisual, currentPolygonGeometry)) {
          fromTo.get(checkPolygon.id).push(polygon.id);
        }
      }
      fromTo.set(polygon.id, to);
      ids.push(polygon.id);
      polygons.push(polygon);
    }
    const nodes: Record<string, string[]> = {};
    const rootIds = new Array<string>();
    const idsForCheck: Array<{ id: string, parentId: string }> = [{ id: ids[0], parentId: null }];
    while (idsForCheck.length) {
      const { id, parentId } = idsForCheck.pop();
      ids.splice(ids.indexOf(id), 1);
      if (!fromTo.has(id)) {
        continue;
      }
      const incoming = [];
      for (const [key, value] of fromTo) {
        if (key === parentId) {
          continue;
        }
        if (value.includes(id)) {
          incoming.push(key);
          idsForCheck.push({ parentId: id, id: key });
        }
      }
      for (const cId of fromTo.get(id)) {
        if (cId === parentId) {
          continue;
        }
        idsForCheck.push({ parentId: id, id: cId });
      }
      nodes[id] = fromTo.get(id).concat(incoming);
      fromTo.delete(id);
      if (parentId === null) {
        rootIds.push(id);
      }
      if (idsForCheck.length === 0 && ids.length) {
        idsForCheck.push({ id: ids[0], parentId: null });
      }
    }

    return {
      rootIds,
      nodes,
      paperPolygons,
    };
  }
}
