import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { InitialFileSystemHighlightState } from 'common/components/drawings/constants';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import {
  DrawingsPdfBrowserContent,
} from 'common/components/drawings/drawings-pdf-browser/drawings-pdf-browser-content';
import {
  DrawingsFile,
  DrawingsFiles,
} from 'common/components/drawings/interfaces/drawings-file-info';
import { State } from 'common/interfaces/state';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { UndoRedoController } from 'common/undo-redo';
import { arrayUtils } from 'common/utils/array-utils';

interface StateProps {
  files: DrawingsFiles;
}

interface OwnProps {
  currentPage: string;
  selectedPages: string[];
  onSelectionChanged: (id: string[]) => void;
  onClose: () => void;
}

interface Props extends StateProps, OwnProps {
}

interface ComponentState {
  selectedFiles: DrawingsFile[];
  selectedFile: string;
}

class SelectPagesDialogComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedFiles: this.getSelectedPdfFiles(),
      selectedFile: null,
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.files !== this.props.files) {
      this.setState({ selectedFiles: this.getSelectedPdfFiles() });
    }
  }

  public render(): React.ReactNode {
    return (
      <DialogWrapper name={DrawingDialogs.SELECT_SCALE_PAGES}>
        <UndoRedoController>
          <DrawingsPdfBrowserContent
            files={this.props.files}
            isLoading={false}
            canEdit={false}
            multiSelect={true}
            selectAllText={'Select All'}
            deselectAllText={'Deselect All'}
            selectedPages={this.props.selectedPages}
            selectedFiles={this.state.selectedFiles}
            selectedFile={this.state.selectedFile}
            highlightState={InitialFileSystemHighlightState}
            onSelectFile={this.onSelectFile}
            getContextMenuItems={this.getContentMenuItems}
            onClose={this.props.onClose}
            onChangeDrawingSelection={this.onChangeDrawingSelection}
          />
        </UndoRedoController>
      </DialogWrapper>
    );
  }

  @autobind
  private onChangeDrawingSelection(ids: string[], selected: boolean): void {
    if (selected) {
      const uniq = arrayUtils.uniq(ids.concat(this.props.selectedPages));
      this.props.onSelectionChanged(uniq);
    } else {
      const idsSet = new Set(ids);
      const newSelection = this.props.selectedPages.filter(id => id === this.props.currentPage || !idsSet.has(id));
      this.props.onSelectionChanged(newSelection);
    }
  }

  private getContentMenuItems(): any {
    return [];
  }

  @autobind
  private onSelectFile(fileId: string): void {
    this.setState({
      selectedFile: fileId,
    });
  }

  @autobind
  private getSelectedPdfFiles(): DrawingsFile[] {
    return Object.values(this.props.files.entities).filter(x => !x.children) as DrawingsFile[];
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    files: state.drawings.files,
  };
}

export const SelectPagesDialog = connect(mapStateToProps)(SelectPagesDialogComponent);
