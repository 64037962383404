import React from 'react';

import { Fields } from 'unit-2d-database/components/side-panel/components/group-properties-list';
import { ReportTemplateVariable } from '../../../interfaces';
import { mapVariableToField } from './map-variable-to-field';

export interface TextFieldInput {
  onChange: (value: string) => void;
  value: string;
}

export const useMapVariablesToFieldMemo = (
  variables: ReportTemplateVariable[],
  onChange: (value: ReportTemplateVariable) => void,
): Fields => {
  const handleChange = (variable: ReportTemplateVariable) => (payload: TextFieldInput) => {
    const updatedVarible = {
      ...variable,
      value: payload.value,
    };
    onChange(updatedVarible);
  };
  return React.useMemo(() => {
    return variables.map((v) => mapVariableToField(v, handleChange(v)));
  }, [variables, onChange]);
};
