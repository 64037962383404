import styled from 'styled-components';

import { SpinnerStyled } from 'common/components/spinner';
import { KreoColors } from 'common/enums/kreo-colors';
import { AutoMoveToCellButtonStyled } from './auto-move-to-cell-button';
import { TwoDReportToolBarStyled } from './tool-bar';

export const TABS_HEIGHT = 36;
const FORMATTING_TOOLBAR_HEIGHT = 36;
const FORMAT_BUTTONS_SEPARATOR_WIDTH = 85;
const BORDER_SIZE = 2;
const TABLE_HEADER_HEIGHT_TOOLBARS = TwoDReportToolBarStyled.TOOLBAR_HEIGHT + FORMATTING_TOOLBAR_HEIGHT;
const QUICK_SEARCH_INPUT_HEIGHT = 50;
const MEASURE_VIEW_SCROLL_BAR = 11;

interface WrapperProps {
  isFullScreenTable: boolean;
  isFullScreenDrawings: boolean;
}

function getBorder({ isFullScreenDrawings }: WrapperProps, color: string): string {
  if (isFullScreenDrawings) {
    return '';
  }
  return `top: ${BORDER_SIZE}px solid ${color}`;
}


const Wrapper = styled.div<WrapperProps>`
  position: ${p => p.isFullScreenTable ? 'absolute' : 'relative'};
  top: ${p => p.isFullScreenTable ? 0 : 'unset'};
  right: ${p => p.isFullScreenTable ? 0 : 'unset'};
  bottom: ${p => p.isFullScreenTable ? 0 : 'unset'};
  left: ${p => p.isFullScreenTable ? 0 : 'unset'};
  z-index: ${p => p.isFullScreenTable ? 200 : 'unset'};
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.color.background};
  display: 'block';
  ${p => getBorder(p, p.theme.color.pale)};
`;

interface ContainerProps {
  isFullScreenTable?: boolean;
  isQuickSearchVisible?: boolean;
}

const Container = styled.div<ContainerProps>`
  height: calc(100% + ${p => p.isQuickSearchVisible ? 10 : -BORDER_SIZE}px);
  width: 100%;
  background-color: ${p => p.theme.color.background};
  display: block;
  grid-area: container;

  ${AutoMoveToCellButtonStyled.Container} {
    > div {
      > button {
        background-color: ${p => p.theme.color.backgroundRush};
      }
    }
  }
`;

const FormattingToolbar = styled.div`
  box-sizing: border-box;
  height: ${FORMATTING_TOOLBAR_HEIGHT}px;
  display: flex;
  align-items: center;
  background: ${p => p.theme.color.backgroundRush};

  > div {
    div {
      div {
        width: 36px;
      }
      :nth-child(5) {
        width: 90px;
        div {
          width: 90px;
        }
      }
    }
  }
`;

const TableHeaderMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 36px;
  height: ${TABS_HEIGHT}px;
  background-color: ${p => p.theme.color.backgroundRush};

  ${SpinnerStyled.SpinnerContainer} {
    width: auto;
    background: transparent;
  }

  ${SpinnerStyled.Spinner} {
    height: ${TABS_HEIGHT}px;
    width: 36px;
  }
`;

const Tabs = styled.div<{ isFullScreenTable: boolean }>`
  grid-area: tabs;
  display: flex;
  height: ${TABS_HEIGHT}px;
  border-top: 1px solid ${p => p.theme.color.background};
  width: 100%;
  transform-origin: left top;
  position: relative;
  z-index: 1;
  transition: all ${p => p.theme.duration.s} linear;
`;


const FormattingMenuContainer = styled.div`
  height: 100%;
  border-right: 1px solid ${p => p.theme.color.background};
`;

const FiltersButtonsContainer = styled.div`
  height: 100%;
  border-left: 1px solid ${p => p.theme.color.background};
  margin-left: auto;
`;

const FormatButtonsSeparator = styled(FormattingMenuContainer)<{ borderRight?: string }>`
  max-width: ${FORMAT_BUTTONS_SEPARATOR_WIDTH}px;
  flex: 1 1 auto;
  border-right: ${p => !p.borderRight && 'none'};
`;

const TableHeader = styled.div`
  position: relative;
  z-index: 2;
  border-bottom: 1px solid ${p => p.theme.color.background};
`;

interface GetTableHeaderHeightSettings {
  isFullScreenTable?: boolean;
  isShowTwoDReport?: boolean;
}

const getTableHeaderHeight = (p: GetTableHeaderHeightSettings): number => {
  const height = BORDER_SIZE + TABS_HEIGHT;

  return p.isShowTwoDReport
    ? height + TABLE_HEADER_HEIGHT_TOOLBARS
    : height;
};

interface GetTableBodySettings extends GetTableHeaderHeightSettings {
  isFullScreenDrawings?: boolean;
  isQuickSearchVisible?: boolean;
  isShowTwoDReport?: boolean;
}

const getTableBodyHeight = (p: GetTableBodySettings): number => {
  const height = getTableHeaderHeight(p);

  return !p.isFullScreenDrawings && p.isQuickSearchVisible && p.isShowTwoDReport
    ? height + QUICK_SEARCH_INPUT_HEIGHT
    : !p.isShowTwoDReport && p.isQuickSearchVisible
      ? height + MEASURE_VIEW_SCROLL_BAR
      : height;
};

interface TableBodyProps extends GetTableBodySettings {
  reportViewPermission: boolean;
}

const TableBody = styled.div<TableBodyProps>`
  height: calc(100% - ${getTableBodyHeight}px);
  display: block;
  pointer-events: ${p => p.reportViewPermission ? 'auto' : 'none'};

  .cell-border {
    border: 2px dashed ${KreoColors.nightSecondary};
  }

  .cell-invalid {
    &:before {
      content: '⚠️';
      position: absolute;
      right: 0px;
      font-size: 14px;
    }
  }

  .layout-splitter {
    z-index: 1;
  }
`;

const ColorsMenuContainer = styled.div<{ positionTop: boolean }>`
  position: absolute;
  left: -100px;
  ${p => p.positionTop ? 'bottom' : 'top'}: 0;
  min-width: 210px;
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.color.backgroundRush};
  border-radius: 15px;
  box-shadow: ${p => p.theme.shadow.xl};
  padding: 10px 0;
  > div {
    :not(:last-child) {
      border-bottom: 10px solid ${p => p.theme.color.pale};
    }
  }
  `;

interface FontSizeContainerProps {
  positionTop?: boolean;
  highlightOnHover?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
}

const FontSizeContainer = styled.div<FontSizeContainerProps>`
  width: 90px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    svg {
      fill: ${p => p.theme.color.turquoise};
    }
    p {
      color: ${p => p.highlightOnHover ? p.theme.color.turquoise : p.theme.color.mainFont}
    }
  }
  svg {
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.l};
    fill: ${p => p.isSelected
    ? p.theme.color.turquoise
    : p.disabled ? p.theme.color.disabled : p.theme.color.gray};
  }
  p {
    color: ${p => p.isSelected
    ? p.theme.color.turquoise
    : p.disabled && p.theme.color.disabled}
  }
`;

const FontSizeMenuContainer = styled.div`
  position: absolute;
  left: -47px;
  border: 1px solid ${p => p.theme.color.background};
  border-radius: 10px;
  width: 90px;
  height: 160px;
  overflow: hidden;
  box-sizing: border-box;
`;

const FontSizeListWrapper = styled.div`
  > div {
    display: block;
    > div {
      height: 40px;
      :not(:last-child) {
        border-right: none;
        border-bottom: 1px solid ${p => p.theme.color.background};
      }
    }
  }
`;

const FormulaMenuContainer = styled.div<{ positionTop: boolean }>`
  position: absolute;
  left: -120px;
  ${p => p.positionTop ? 'bottom' : 'top'}: 0;
`;

export const Styled = {
  Wrapper,
  Container,
  Tabs,
  TableHeader,
  TableBody,
  TableHeaderMenu,
  FormattingToolbar,
  FormatButtonsSeparator,
  ColorsMenuContainer,
  FormattingMenuContainer,
  FiltersButtonsContainer,
  FontSizeContainer,
  FontSizeMenuContainer,
  FontSizeListWrapper,
  FormulaMenuContainer,
};
