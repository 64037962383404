import { Action } from 'redux';
import { ActionWith } from 'common/interfaces/action-with';

function skipWaiting(): Action {
  return {
    type: 'SKIP_WAITING',
  };
}

function setToken(token: string): ActionWith<string> {
  return {
    type: 'SET_TOKEN',
    payload: token,
  };
}

export const ServiceWorkerActions = {
  skipWaiting,
  setToken,
};
