import styled from 'styled-components';

import { Styled as HeaderStyled } from '../page-header/styled';

export const PAGE_HEADER_HEIGHT = 60;
const PAGE_HEADER_BORDER_WIDTH = 1;

const Container = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;

  ${HeaderStyled.Container} {
    height: ${PAGE_HEADER_HEIGHT}px;
    border-bottom: ${PAGE_HEADER_BORDER_WIDTH}px solid ${p => p.theme.color.background};

    .page-header__logo {
      padding: 15px 0px 15px 20px;
      width: 40px;

      img {
        width: 115px;
      }

      > div {
        > svg {
          height: 30px;
        }
      }
    }
  }

  ${HeaderStyled.UserMenu} {
    height: 60px;

    > .page-header-avatar {
      margin: 0 25px 0 20px;
    }
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${PAGE_HEADER_HEIGHT + PAGE_HEADER_BORDER_WIDTH}px);
  transition: ${props => props.theme.duration.m};

  &:first-child {
    height: 100%;
  }
`;

export const Styled = {
  Container,
  Content,
};
