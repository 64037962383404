import { StringUtils } from 'common/utils/string-utils';

export const DrawingAnnotationNamingUtils = {
  getDefaultGeometryName(type: string): string {
    const castedType = this.getCastedType(type);
    return `${castedType}`;
  },
  getDuplicateGeometryName(name: string): string {
    return `${name} - Duplicate`;
  },
  getCopyGeometryName(name: string): string {
    return `${name} - Copy`;
  },
  getOffsetGeometryName(name: string): string {
    return `${name} - Offset`;
  },
  getCastedType(type: string): string {
    const castedType = StringUtils.firstUpperLetter(type);
    return castedType;
  },
};
