import * as React from 'react';

import { CompaniesList } from '../../components/companies-list';
import { AdminMasterPage } from '../../master';

export class AdminCompaniesPage extends React.Component {

  public render(): JSX.Element {
    return (
      <AdminMasterPage title='Companies'>
        <CompaniesList />
      </AdminMasterPage>
    );
  }
}
