import { Constants } from '@kreo/kreo-ui-components';
import * as paper from 'paper';
import { UuidUtil } from 'common/utils/uuid-utils';
import { DrawingsCanvasColors, DrawingsCanvasConstants } from '../constants/drawing-canvas-constants';
import { DrawingStrokeStyles } from '../constants/drawing-styles';
import { SnappingGuideInfo } from '../drawings-geometry/drawings-helpers/snapping/with-self-snapping';
import { DrawingsEngineOrientation } from '../drawings-geometry/interfaces/orientation';
import { DrawingsInstanceType } from '../enums/drawings-instance-type';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import { DrawingInstanceApi } from '../interfaces/drawings-canvas-context-props';
import { DrawingsCanvasRect } from '../interfaces/drawings-canvas-rect';
import {
  DrawingsAllowedPathType,
  DrawingsBasePolyGeometryNew,
  DrawingsGeometryPointCoordinates,
  DrawingsPaperPolygonPath,
  DrawingsPolygonGeometry,
  DrawingsPolylineGeometry,
} from '../interfaces/drawings-geometry';
import { DrawingsGeometryInstanceWithId } from '../interfaces/drawings-geometry-instance';
import { DrawingAnnotationUtils } from './drawing-annotation-utils';
import { DrawingsCanvasUtils } from './drawings-canvas-utils';
import { DrawingsGeometryUtils } from './drawings-geometry-utils';
import { PaperIntersectionUtils, PaperPointUtils } from './paper-utils';

function getGuidelineValue(points: Iterable<paper.Point>, { x, y }: paper.Point): SnappingGuideInfo {
  let minX = Infinity;
  let minY = Infinity;
  let xDistance = Infinity;
  let yDistance = Infinity;
  for (const point of points) {
    const currentXDistance = Math.abs(point.x - x);
    const currentYDistance = Math.abs(point.y - y);
    if (xDistance > currentXDistance) {
      minX = point.x;
      xDistance = currentXDistance;
    }
    if (yDistance > currentYDistance) {
      minY = point.y;
      yDistance = currentYDistance;
    }
  }
  if (xDistance < yDistance) {
    return {
      orientation: DrawingsEngineOrientation.Vertical,
      value: minX,
      distance: xDistance,
    };
  } else {
    return {
      orientation: DrawingsEngineOrientation.Horizontal,
      value: minY,
      distance: yDistance,
    };
  }
}

function convertPoint(point: { x: number, y: number }): ShortPointDescription {
  return point ? [point.x, point.y] : null;
}

function changeViewCenter(x1: number, y1: number, x2: number, y2: number, scope: paper.PaperScope): void {
  scope.view.center = new paper.Point(x2 + x1, y2 + y1).divide(2);
  const bounds = scope.view.bounds;
  if (bounds.x < 0) scope.view.center = scope.view.center.subtract(new paper.Point(bounds.x, 0));
  if (bounds.y < 0) scope.view.center = scope.view.center.subtract(new paper.Point(0, bounds.y));
}

function isPaperMouseEvent(e: unknown): e is PaperMouseEvent {
  return !!(e as any).event;
}

function getPathLength(line: paper.Path, scale: number): { pxLength: number, length: number } {
  const pxLength = line.length;
  return {
    pxLength,
    length: DrawingsCanvasUtils.pxToMetres(pxLength, scale),
  };
}

function isLineIntersectedPolyline(polyline: paper.Path, line: paper.Path): boolean {
  return polyline.getIntersections(line).length > 1;
}

function isPointInside(
  point: DrawingsGeometryPointCoordinates,
  segments: IterableIterator<[paper.Point, paper.Point]>,
): boolean {
  const { x, y } = point;
  let inside = false;
  for (const [{ x: xi, y: yi }, { x: xj, y: yj }] of segments) {
    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) {
      inside = !inside;
    }
  }
  return inside;
}

function* getPolygonSegmentsPointIterator(segments: paper.Segment[]): IterableIterator<[paper.Point, paper.Point]> {
  for (const [start, end] of DrawingAnnotationUtils.iteratePoints(segments, DrawingsInstanceType.Polygon)) {
    yield [start.point, end.point];
  }
}

function getPolygonPointsIterator(polygon: DrawingsPaperPolygonPath): IterableIterator<[paper.Point, paper.Point]> {
  return polygon.firstChild
    ? getPolygonSegmentsPointIterator(polygon.firstChild.segments)
    : getPolygonSegmentsPointIterator(polygon.segments);
}

function intersectsOrInclude(path1: paper.CompoundPath, path2: paper.CompoundPath): boolean {
  return path1.intersects(path2) || hasPointInside(path2, path1);
}

function* iterateSegments(path: paper.Path | paper.CompoundPath): IterableIterator<paper.Segment> {
  if (path.children) {
    for (const child of (path.children as paper.Path[])) {
      yield* iterateSegments(child);
    }
  } else {
    for (const segment of (path as paper.Path).segments) {
      yield segment;
    }
  }
}

function rectToPoints(rect: paper.Rectangle): paper.Point[] {
  return [
    rect.topLeft,
    rect.topRight,
    rect.bottomRight,
    rect.bottomLeft,
  ];
}

function isRectInsidePath(rect: paper.Rectangle, path: paper.Path | paper.CompoundPath): boolean {
  const rectPoints = rectToPoints(rect);

  const checkContour = (contour: paper.CompoundPath): boolean => {
    for (const point of rectPoints) {
      if (!isPointInside(point, getPolygonPointsIterator(contour as DrawingsPaperPolygonPath))) {
        return false;
      }
    }
    return true;
  };

  if (path.hasChildren()) {
    if (checkContour(path.firstChild as paper.Path)) {
      for (let i = 1; i < path.children.length; i++) {
        if (checkContour(path.children[i] as paper.Path)) {
          return false;
        }
      }
      return true;
    }
    return false;
  } else {
    return checkContour(path);
  }
}

(window as any).INTERSECTION_DEBUG = false;

function showIntersectionPoint(point: paper.Point): void {
  if (!(window as any).INTERSECTION_DEBUG) {
    return;
  }
  const circle = new paper.Path.Circle(point, 5);
  circle.strokeColor = DrawingsCanvasColors.warningStrokeColor;
  circle.strokeWidth = 3;
  setTimeout(() => {
    circle.remove();
  }, 3000);
}

function showIntersectedSegments(
  line1Start: paper.Point,
  line1End: paper.Point,
  line2Start: paper.Point,
  line2End: paper.Point,
): void {
  if (!(window as any).INTERSECTION_DEBUG) {
    return;
  }
  const line1 = new paper.Path.Line(line1Start, line1End);
  line1.strokeColor = DrawingsCanvasColors.warningStrokeColor;
  line1.strokeWidth = 1;
  const line2 = new paper.Path.Line(line2Start, line2End);
  line2.strokeColor = DrawingsCanvasColors.warningStrokeColor;
  line2.strokeWidth = 1;
  setTimeout(() => {
    line1.remove();
    line2.remove();
  }, 3000);
}


function hasPointInside(pathForCheck: paper.Path | paper.CompoundPath, path: paper.CompoundPath): boolean {
  for (const segment of iterateSegments(pathForCheck)) {
    if (isPointInside(segment.point, getPolygonPointsIterator(path as DrawingsPaperPolygonPath))) {
      showIntersectionPoint(segment.point);
      return true;
    }
  }
}


function arePathesEquals(
  source: DrawingsPaperPolygonPath | paper.Path,
  pathForCheck: DrawingsPaperPolygonPath | paper.Path,
): boolean {
  const sourceChildrenCount = source.children ? source.children.length : null;
  const pathForCheckChildrenCount = pathForCheck.children ? pathForCheck.children.length : null;
  if (sourceChildrenCount || pathForCheckChildrenCount) {
    if (sourceChildrenCount === pathForCheckChildrenCount) {
      return !(source.children as paper.Path[]).find(
        sourceChild => !(pathForCheck.children as paper.Path[]).find(x => arePathesEquals(x, sourceChild)),
      );
    }
  } else {
    const sourceSegmentsCount = source.segments ? source.segments.length : null;
    const pathForCheckSegmentsCount = pathForCheck.segments ? pathForCheck.segments.length : null;
    if (sourceSegmentsCount && sourceSegmentsCount === pathForCheckSegmentsCount) {
      return !source.segments.find(sourceChild => !pathForCheck.segments.find(x => sourceChild.point.equals(x.point)));
    }
  }
  return false;
}

function calculateIntersectionPairs(
  instances: Record<string, DrawingsGeometryInstanceWithId>,
  selectedInstances: string[],
  renderedInstancesMap: Map<string, DrawingInstanceApi>,
): Map<string, string[]> {
  const polygons = [];
  const fromTo = new Map<string, string[]>();
  for (const polygon of DrawingsGeometryUtils.selectedPolygonsIterator(selectedInstances, id => instances[id])) {
    if (!renderedInstancesMap.has(polygon.id)) {
      continue;
    }
    const currentPolygonGeometry = renderedInstancesMap.get(polygon.id).path as DrawingsPaperPolygonPath;
    const currentPolygonGeometryClone = currentPolygonGeometry.clone();
    currentPolygonGeometryClone.closePath();
    const to = [];
    for (const checkPolygon of polygons) {
      const polygonForCheck = renderedInstancesMap.get(checkPolygon.id).path as DrawingsPaperPolygonPath;
      if (arePathesEquals(currentPolygonGeometry, polygonForCheck)) {
        continue;
      }
      const intersets =
        currentPolygonGeometryClone.intersects(polygonForCheck);
      if (intersets || hasPointInside(polygonForCheck, currentPolygonGeometryClone)) {
        to.push(checkPolygon.id);
        if (intersets) {
          if (fromTo.has(checkPolygon.id)) {
            fromTo.get(checkPolygon.id).push(polygon.id);
          } else {
            fromTo.set(checkPolygon.id, [polygon.id]);
          }
        }
      } else if (hasPointInside(currentPolygonGeometryClone, polygonForCheck)) {
        if (fromTo.has(checkPolygon.id)) {
          fromTo.get(checkPolygon.id).push(polygon.id);
        } else {
          fromTo.set(checkPolygon.id, [polygon.id]);
        }
      }
    }
    currentPolygonGeometryClone.remove();
    if (to.length) {
      fromTo.set(polygon.id, to);
    }
    polygons.push(polygon);
  }
  return fromTo;
}


function fillPolygeometry(
  path: paper.Path,
  geometry: DrawingsBasePolyGeometryNew,
  points: Record<string, ShortPointDescription>,
  addLastPoint: boolean,
  keyPrefix: string = '',
): void {
  let prevPoint: paper.Point;
  for (let i = 0; i < path.segments.length - Number(!addLastPoint); i++) {
    const segment = path.segments[i];
    if (prevPoint && prevPoint.equals(segment.point)) {
      continue;
    }
    prevPoint = segment.point;
    points[`${keyPrefix}${UuidUtil.generateUuid()}`] = [segment.point.x, segment.point.y];
  }
  geometry.points = Object.keys(points);
}


function convertPathToPolyline(
  path: paper.Path,
  color: string,
  strokeWidth: number,
  strokeStyle: DrawingStrokeStyles,
  addLastPoint: boolean = true,
): {
  polyline: DrawingsPolylineGeometry,
  points: Record<string, ShortPointDescription>,
} {
  const points: Record<string, ShortPointDescription> = {};
  const polyline: DrawingsPolylineGeometry = {
    points: [],
    color,
    strokeStyle,
    strokeWidth,
  };
  fillPolygeometry(path, polyline, points, addLastPoint);
  if (!path.clockwise) {
    polyline.points.reverse();
  }
  return { polyline, points };
}


function convertPathToPolygon(
  path: paper.Path,
  color: string,
  strokeWidth: number,
  strokeStyle: DrawingStrokeStyles,
  addLastPoint: boolean = true,
  keyPrefix: string = '',
): {
  polygon: DrawingsPolygonGeometry,
  points: Record<string, ShortPointDescription>,
} {
  const points: Record<string, ShortPointDescription> = {};
  const polygon: DrawingsPolygonGeometry = {
    points: [],
    color,
    strokeStyle,
    strokeWidth,
  };
  if (path.children) {
    // todo rewrite when new subtract and unioun will be ready
    // const {points, lines} = convertPathToPolygon(path.children[0] as paper.Path, scale, color);
    // polygon.points = points;
    // polygon.lines = lines;
    // polygon.children = new Array(path.children.length - 1);
    // for (let i = 1; i < path.children.length; i++) {
    //   polygon.children[i - 1] = convertPathToPolygon(path.children[i] as paper.Path, scale, color);
    // }
    // if (removeAfterProcess) {
    //   path.remove();
    // }
  } else {
    fillPolygeometry(path, polygon, points, addLastPoint, keyPrefix);
    if (!path.clockwise) {
      polygon.points.reverse();
    }
  }
  return {
    polygon,
    points,
  };
}


function createSelectionArea(point: paper.Point, zoom: number): paper.Path.Rectangle {
  const currentSelectionArea = new paper.Path.Rectangle(point, point);
  currentSelectionArea.strokeColor = new paper.Color(Constants.Colors.GENERAL_COLORS.turquoiseDay);
  const colorWithAlpha = new paper.Color(Constants.Colors.GENERAL_COLORS.turquoiseDay);
  colorWithAlpha.alpha = 0.2;
  currentSelectionArea.fillColor = colorWithAlpha;
  currentSelectionArea.strokeWidth = DrawingsCanvasConstants.lineStroke / zoom;
  return currentSelectionArea;
}

function getPaperViewRect(): DrawingsCanvasRect {
  const { x, width, y, height } = paper.view.bounds;
  return {
    x1: x,
    x2: x + width,
    y1: y,
    y2: y + height,
  };
}


function getPaperAngle(startPoint: paper.Point, anglePoint: paper.Point, endPoint: paper.Point): number {
  const firstAngle = startPoint.subtract(anglePoint).angle;
  const secondAngle = endPoint ? endPoint.subtract(anglePoint).angle : 0;
  return firstAngle - secondAngle;
}

function getRightAngleSnappingTurn(angle: number): number {
  const remainder = angle % 90;
  let diffAngle: number;
  if (remainder !== 0) {
    if (remainder < 0) {
      diffAngle = -90 - remainder;
      if (remainder > diffAngle) {
        const changed = Math.abs(angle - remainder);
        return changed % 180 === 0 ? diffAngle : -remainder;
      } else {
        const changed = Math.abs(angle + diffAngle);
        return changed % 180 !== 0 ? diffAngle : -remainder;
      }
    } else {
      diffAngle = 90 - remainder;
      if (remainder < diffAngle) {
        const changed = Math.abs(angle - remainder);
        return changed % 180 === 0 ? diffAngle : -remainder;
      } else {
        const changed = Math.abs(angle + diffAngle);
        return changed % 180 !== 0 ? diffAngle : -remainder;
      }
    }
  }
  return 90;
}

function getAngleSnappingTurn(angle: number, stepAngle: number): number {
  const remainder = angle % stepAngle;
  if (remainder !== 0) {
    if (remainder < 0) {
      const diffAngle = -stepAngle - remainder;
      if (remainder > diffAngle) {
        return -remainder;
      } else {
        return diffAngle;
      }
    } else {
      const diffAngle = stepAngle - remainder;
      if (remainder < diffAngle) {
        return -remainder;
      } else {
        return diffAngle;
      }
    }
  }
  return 0;
}


function simplePolygonToPath(
  pointIds: string[],
  getPointInfo: (id: string) => paper.Point,
): paper.Path {
  const path = new paper.Path(pointIds.map(getPointInfo));
  path.add(path.firstSegment);
  return path;
}

function findLastPointOfRectangle(p1: paper.Point, p2: paper.Point, p3: paper.Point): paper.Point {
  const centerPoint = p1.add(p3).divide(2);
  return new paper.Point(centerPoint.x * 2 - p2.x, centerPoint.y * 2 - p2.y);
}

function checkIsPointInStartLine(
  line: [paper.Point, paper.Point],
  splittingPoint: paper.Point,
  targetPoint: paper.Point,
): boolean {
  const [start, end] = line;
  const targetPointOnLine = getClosestPointOnLine(start, end, targetPoint);
  return PaperPointUtils.pointOnLine(start, splittingPoint, targetPointOnLine);
}

function getClosestPointOnLine(startPoint: paper.Point, endPoint: paper.Point, targetPoint: paper.Point): paper.Point {
  const line = new paper.Path.Line(startPoint, endPoint);
  const point = line.getNearestPoint(targetPoint);
  line.remove();
  return point;
}

function updatePathStyles(path: paper.Path | paper.CompoundPath | paper.Group): void {
  path.strokeJoin = 'round';
  path.strokeCap = 'round';
}

interface Edge {
  points: [paper.Point, paper.Point];
  index: number;
}

function *iterateEdgesWithIndex(path: DrawingsAllowedPathType): IterableIterator<Edge> {
  if (path.children && path.children.length) {
    for (const child of path.children) {
      yield* iterateEdgesWithIndex(child as paper.Path);
    }
  } else {
    for (let i = 0; i < path.segments.length; i++) {
      if (i === path.segments.length - 1) {
        if (path.closed) {
          yield {
            points: [path.segments[0].point, path.segments[i].point],
            index: i,
          };
        }
      } else {
        yield {
          points: [path.segments[i].point, path.segments[i + 1].point],
          index: i,
        };
      }
    }
  }
}

function* iterateEdges(path: DrawingsAllowedPathType): IterableIterator<[paper.Point, paper.Point]> {
  for (const edge of iterateEdgesWithIndex(path)) {
    yield edge.points;
  }
}

type Segment = [paper.Point, paper.Point];

function shouldSkipSegmentInIntersection(segment: Segment): boolean {
  return segment[0].equals(segment[1]);
}

function* iterateEgdesFromPoints(points: paper.Point[]): IterableIterator<Edge> {
  for (const [
    start,
    end,
    index,
  ] of DrawingAnnotationUtils.iterateSegmentsWithIndex(points, DrawingsInstanceType.Polygon)) {
    yield {
      points: [start, end],
      index,
    };
  }
}

function allIntersectionInContour(
  path: paper.Point[],
): Array<{ point: paper.Point, segments: Edge[] } > {
  const result: Array<{ point: paper.Point, segments: Edge[] } >  = [];
  const edges: Edge[] = [];
  for (const currentEdge of iterateEgdesFromPoints(path)) {
    const [edgeStart, edgeEnd ] = currentEdge.points;
    if (shouldSkipSegmentInIntersection(currentEdge.points)) {
      continue;
    }
    for (const edge of edges) {
      const [ start, end ] = edge.points;
      const startEqualsEdge = currentEdge.points.find(x => x.equals(start));
      const endEqualsEdge = currentEdge.points.find(x => x.equals(end));
      if (startEqualsEdge && endEqualsEdge) {
        result.push({
          point: startEqualsEdge || endEqualsEdge,
          segments: [edge, currentEdge],
        });
      }
      if (
        !startEqualsEdge
        && !endEqualsEdge
      ) {
        const intersectionPoint = PaperIntersectionUtils.getLinesIntersection(start, end, edgeStart, edgeEnd);
        if (intersectionPoint) {
          showIntersectedSegments(start, end, edgeStart, edgeEnd);
          showIntersectionPoint(intersectionPoint);
          result.push({
            point: intersectionPoint,
            segments: [edge, currentEdge],
          });
        }
      }
    }
    edges.push(currentEdge);
  }
  return result;
}

function findIntersectionOfEdgesAndAddToCommon(
  path: paper.Path,
  segments: Segment[],
): [intersectionPoint: paper.Point, segments: Segment[]] {
  for (const edge of iterateEdges(path)) {
    if (shouldSkipSegmentInIntersection(edge)) {
      continue;
    }
    for (const [start, end] of segments) {
      const startEqualsEdge = edge.find(x => x.equals(start));
      const endEqualsEdge = edge.find(x => x.equals(end));
      if (startEqualsEdge && endEqualsEdge) {
        return [startEqualsEdge || endEqualsEdge, [edge, [start, end]]];
      }
      if (
        !startEqualsEdge
        && !endEqualsEdge
      ) {
        const intersetionPoint = PaperIntersectionUtils.getLinesIntersection(start, end, edge[0], edge[1]);
        if (intersetionPoint) {
          showIntersectedSegments(edge[0], edge[1], start, end);
          showIntersectionPoint(PaperIntersectionUtils.getLinesIntersection(start, end, edge[0], edge[1]));
          return [intersetionPoint, [edge, [start, end]]];
        }
      }
    }
    segments.push(edge);
  }
  return [null, []];
}


function checkEdgesAndAddToCommon(
  path: paper.Path,
  segments: Segment[],
): boolean {
  const [point] = findIntersectionOfEdgesAndAddToCommon(path, segments);
  return !!point;
}

function areContoursIntersected(source: paper.Path, target: paper.Path): boolean {
  if (source.intersects(target)) {
    source.getIntersections(target).forEach(intersection => {
      showIntersectionPoint(intersection.point);
    });
    return true;
  }

  for (const segment of source.segments) {
    if (target.segments.some(s => PaperPointUtils.arePointsEqual(s.point, segment.point))) {
      showIntersectionPoint(segment.point);
      return true;
    }
  }
  return false;
}

function checkAndCacheIntersectionChildResult(
  source: paper.Path,
  pathForCheck: paper.Path,
  index: number,
  segments: Segment[],
  checkedChildren: Record<number, boolean>,
): boolean {
  if (checkedChildren[index]) {
    return false;
  }
  if (
    !hasPointInside(source, pathForCheck)
    || areContoursIntersected(source, pathForCheck)
    || checkEdgesAndAddToCommon(source, segments)
  ) {
    return true;
  }
  checkedChildren[index] = true;
  return false;
}

function getSelfIntersectionPoint(path: paper.Path): [paper.Point, Segment[]] {
  const segments = [];
  return findIntersectionOfEdgesAndAddToCommon(path, segments);
}

function isPolygonSelfIntersected(path: DrawingsAllowedPathType): boolean {
  const segments = [];
  if (isCompoundPolygonPath(path)) {
    const checkedChildren = {};
    const parent = path.firstChild;

    if (checkEdgesAndAddToCommon(parent, segments)) {
      return true;
    }

    for (let i = 1; i < path.children.length; i++) {
      if (checkAndCacheIntersectionChildResult(
        path.children[i],
        parent,
        i,
        segments,
        checkedChildren,
      )) {
        return true;
      }
    }
  } else {
    return checkEdgesAndAddToCommon(path, segments);
  }
  return false;
}

function isCompoundPolygonPath(path: DrawingsAllowedPathType): path is DrawingsPaperPolygonPath {
  return !!path.children && !!path.children.length;
}

function getRoundZoom(radius: number, point: paper.Path): number {
  return point.bounds.width / (2 * radius);
}

function checkPolylinePathSelfIntersections(path: paper.Path): boolean {
  for (let i = 0; i < path.segments.length - 1; i++) {
    const start = path.segments[i].point;
    const end = path.segments[i + 1].point;
    for (let j = i + 2; j < path.segments.length - 1; j++) {
      const checkStart = path.segments[j].point;
      const checkEnd = path.segments[j + 1].point;
      if (PaperIntersectionUtils.getLinesIntersection(start, end, checkStart, checkEnd)) {
        return true;
      }
    }
  }
  return false;
}

function isPointOnDrawing(point: paper.Point, width: number, height: number): boolean {
  return point.x > 0 && point.x <= width && point.y > 0 && point.y <= height;
}

function arePolygonsIntersected(rootPolygon: paper.CompoundPath, currentPolygon: paper.CompoundPath): boolean {
  return intersectsOrInclude(rootPolygon, currentPolygon) || hasPointInside(currentPolygon, rootPolygon);
}

export const DrawingsPaperUtils = {
  createSelectionArea,
  changeViewCenter,
  getPaperViewRect,
  getPolygonSegmentsPointIterator,
  // calculations
  getPathLength,
  calculateIntersectionPairs,
  // is
  isPaperMouseEvent,
  isLineIntersectedPolyline,
  isPointInside,
  isRectInsidePath,

  intersectsOrInclude,
  hasPointInside,
  arePathesEquals,
  isPolygonSelfIntersected,
  // convertors
  convertPathToPolygon,
  convertPathToPolyline,
  convertPoint,
  getPaperAngle,
  getAngleSnappingTurn,
  getRightAngleSnappingTurn,
  findLastPointOfRectangle,
  simplePolygonToPath,
  // handlers
  getClosestPointOnLine,
  updatePathStyles,
  iterateEdges,
  iterateSegments,
  isCompoundPolygonPath,

  getRoundZoom,
  checkPolylinePathSelfIntersections,
  getGuidelineValue,

  isPointOnDrawing,
  checkIsPointInStartLine,

  arePolygonsIntersected,
  getSelfIntersectionPoint,
  allIntersectionInContour,
};
