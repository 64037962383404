import { ElementTooltip, IconButton, Icons } from '@kreo/kreo-ui-components';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useGlobalKeyboardEvents } from 'common/components/global-keyboard-events-controller';
import { RenderIf } from 'common/components/render-if';
import { ControlNames } from 'common/constants/control-names';
import { ConstantFunctions } from 'common/constants/functions';
import { Mood } from 'common/enums/mood';
import { State } from 'common/interfaces/state';
import { MultiViewSelectors } from 'common/utils/multi-view/multi-view-selector';
import { useCurrentProjectFullScreen } from 'persisted-storage/hooks';
import { TwoDFullScreenMode } from 'persisted-storage/interfaces/state';
import { ZoomHotkey } from '../../utils/hotkey-utils';
import { Styled } from './styled';
import { useCreateScreenshot } from './use-create-screenshot';
import { useToggle3d } from './use-toggle-3d';

interface Props {
  home: () => void;
  onToggleFullScreen: () => void;
  createScreenshot: () => Promise<void>;
  canToggleFullScreen?: boolean;
}

const ThreeDZoomControlComponent: React.FC<Props> = ({
  home,
  onToggleFullScreen,
  createScreenshot,
  canToggleFullScreen,
}) => {
  const isDrawingRendered = useSelector<State, boolean>(state => state.drawings.drawingRenderedStatus);
  const [fullscreenMode] = useCurrentProjectFullScreen();
  const isFullScreen = fullscreenMode === TwoDFullScreenMode.Drawings;
  const mood = !isDrawingRendered ? 'disabled' : 'default';
  const [is3d, toggle3dView] = useToggle3d();
  const keyboardEvents = useGlobalKeyboardEvents();
  const oldHome = useRef(home);

  useEffect(() => {
    if (isDrawingRendered) {
      if (oldHome.current) {
        keyboardEvents.removeKeyDownEventListener(ZoomHotkey.Home, oldHome.current);
      }
      keyboardEvents.addKeyDownEventListener(ZoomHotkey.Home, home);
    } else {
      keyboardEvents.removeKeyDownEventListener(ZoomHotkey.Home, home);
    }
    oldHome.current = home;
    return () => {
      keyboardEvents.removeKeyDownEventListener(ZoomHotkey.Home, home);
    };
  }, [isDrawingRendered, home]);

  const [createScreenshotHandler, screenshotInProgress] = useCreateScreenshot(createScreenshot);
  const canShowFullscreen = MultiViewSelectors.useIsMultiViewConnect();

  return (
    <Styled.Container onClick={ConstantFunctions.stopEvent}>
      <ElementTooltip text={'Screenshot'} position="top">
        <IconButton
          Icon={screenshotInProgress ? Icons.Loader : Icons.ScreenshotTwoD}
          height={40}
          mood={createScreenshot ? Mood.Default : Mood.Disabled}
          defaultColor="gray"
          onClick={createScreenshotHandler}
        />
      </ElementTooltip>
      <RenderIf condition={canShowFullscreen && canToggleFullScreen}>
        <ElementTooltip text={isFullScreen ? 'Exit Fullscreen' : 'Fullscreen'} position="top">
          <IconButton
            onClick={onToggleFullScreen}
            Icon={isFullScreen ? Icons.ExitFullscreen : Icons.FullScreen}
            height={40}
            defaultColor={isFullScreen ? 'turquoise' : 'gray'} />
        </ElementTooltip>
      </RenderIf>
      <ElementTooltip text={'Home'} position="top">
        <IconButton onClick={home} Icon={Icons.Home2D} height={40} mood={mood} />
      </ElementTooltip>
      <ElementTooltip text={'2d'} position="top">
        <IconButton
          onClick={toggle3dView}
          Icon={Icons.TwoDView}
          height={40}
          defaultColor={is3d ? 'turquoise' : 'gray'}
          controlName={ControlNames.twoDView}
        />
      </ElementTooltip>
    </Styled.Container>
  );
};

export const ThreeDZoomControl = React.memo(ThreeDZoomControlComponent);
