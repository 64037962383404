import * as React from 'react';

export const KreoIconSubtracts = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <path
      // eslint-disable-next-line max-len
      d='M18,7.75H16.25V5.91A1.91,1.91,0,0,0,14.34,4H5.91A1.91,1.91,0,0,0,4,5.91v8.43a1.92,1.92,0,0,0,1.91,1.91H7.75V18a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V9.75A2,2,0,0,0,18,7.75Zm-8.54,7.5,5.79-5.79v2.33l-3.46,3.46Zm5.08-6.5L8.75,14.54V12.21l3.46-3.46Zm-5.79,2v-1a1,1,0,0,1,1-1h1Zm6.5,2.42v1.13a.91.91,0,0,1-.91.91H13.21ZM5,14.34V5.91A.91.91,0,0,1,5.91,5h8.43a.91.91,0,0,1,.91.91V7.75H9.75a2,2,0,0,0-2,2v5.5H5.91A.91.91,0,0,1,5,14.34ZM19,18a1,1,0,0,1-1,1H9.75a1,1,0,0,1-1-1V16.25h5.59a1.92,1.92,0,0,0,1.91-1.91V8.75H18a1,1,0,0,1,1,1Z'
      fill='currentColor'
    />
  </svg>
);
