import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyE: React.FC = () => {
  return (
    <svg
      width='20px'
      height='22px'
      viewBox='0 0 20 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='hotkey_e' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='E'>
          <g id='background'>
            <g id='Group' transform='translate(0.000000, 2.000000)'>
              <g id='path-1-link' fill={KreoColors.f1}>
                <rect id='path-1' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
            <g id='Group'>
              <g id='path-2-link' fill={KreoColors.f1}>
                <rect id='path-2' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
          </g>
          <rect id='padding-left' x='0' y='6' width='6' height='10' />
          <rect id='padding-right' x='14' y='6' width='6' height='10' />
          <path
            // eslint-disable-next-line max-len
            d='M8.89725036,7.22399889 L8.89725036,9.52299927 L11.4822508,9.52299927 C11.7352508,9.52299927 11.8452508,9.53399927 11.9662509,9.59999928 C12.1092509,9.68249929 12.2082509,9.84199932 12.2082509,10.0509994 C12.2082509,10.2599994 12.1092509,10.4194994 11.9662509,10.5019994 C11.8452508,10.5679994 11.7352508,10.5789994 11.4822508,10.5789994 L8.89725036,10.5789994 L8.89725036,12.9439998 L12.0432509,12.9439998 C12.2962509,12.9439998 12.4062509,12.9549998 12.527251,13.0209998 C12.670251,13.1034999 12.769251,13.2629999 12.769251,13.4719999 C12.769251,13.6809999 12.670251,13.8405 12.527251,13.923 C12.4062509,13.989 12.2962509,14 12.0432509,14 L8.43525029,14 C8.25925026,14 8.05025022,14 7.87975019,13.8295 C7.70925017,13.6589999 7.70925017,13.4499999 7.70925017,13.2739999 L7.70925017,6.89399884 C7.70925017,6.71799881 7.70925017,6.50899877 7.87975019,6.33849874 C8.05025022,6.16799872 8.25925026,6.16799872 8.43525029,6.16799872 L12.0432509,6.16799872 C12.2962509,6.16799872 12.4062509,6.17899872 12.527251,6.24499873 C12.670251,6.32749874 12.769251,6.48699877 12.769251,6.6959988 C12.769251,6.90499884 12.670251,7.06449886 12.527251,7.14699888 C12.4062509,7.21299889 12.2962509,7.22399889 12.0432509,7.22399889 L8.89725036,7.22399889 Z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
};
