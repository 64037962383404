import { ContextMenuGroup, Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { InteractiveMenuContainer } from 'common/components/interactive-menu';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { AccountActions } from '../../../units/account/actions/creators';
import { CollapseColorPicker } from './collapse-color-picker';
import { CreateColorDialog } from './create-color-dialog';
import { Styled } from './styled';


export interface ColorControlOwnProps {
  colorList: string[];
  selectedColor: string;
  title?: string;
  usedColors?: string[];
  onColor?: (value: string) => void;
}

interface StateProps {
  userColors: string[];
}

interface DispatchProps {
  deleteColor: (color: string) => void;
}


interface Props extends StateProps, ColorControlOwnProps, DispatchProps {

}

interface ComponentState {
  isCreateNewOpened: boolean;
  color: string;
}


export class ColorControlComponent extends React.Component<Props, ComponentState> {
  private colorControl: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      isCreateNewOpened: false,
      color: null,
    };
  }

  public render(): React.ReactNode {
    const {
      colorList,
      selectedColor,
      onColor,
      title,
      userColors,
      usedColors,
    } = this.props;

    return (
      <Styled.ColorControlWrapper ref={this.saveRef} id='main color control'>
        {
          usedColors && usedColors.length > 0 && (
            <ContextMenuGroup>
              <Styled.WrapperWithScroll>
                <CollapseColorPicker
                  colorList={usedColors}
                  selectedColor={selectedColor}
                  onColor={onColor}
                  title={'Used colours'}
                  isBlocksOpen={true}
                  icon={Icons.RightSmall}
                />
              </Styled.WrapperWithScroll>
            </ContextMenuGroup>
          )
        }
        <Styled.WrapperWithScroll>
          <CollapseColorPicker
            colorList={userColors}
            selectedColor={selectedColor}
            onColor={onColor}
            openMenu={this.toggleOpenMenu}
            deleteColor={this.props.deleteColor}
            title={'Custom colours'}
            icon={Icons.RightSmall}
          />
        </Styled.WrapperWithScroll>
        {
          this.state.isCreateNewOpened && (
            <InteractiveMenuContainer
              bindVertical='top'
              parentRect={this.colorControl.getBoundingClientRect() as DOMRect}
            >
              <CreateColorDialog
                userColors={userColors}
                color={selectedColor}
                onChangeColor={this.onChangeColor}
              />
            </InteractiveMenuContainer>
          )
        }
        <Styled.DefaultColorPickerWrapper>
          <CollapseColorPicker
            colorList={colorList}
            selectedColor={selectedColor}
            onColor={onColor}
            title={typeof title === 'string' ? title : 'Default colours'}
            icon={Icons.RightSmall}
          />
        </Styled.DefaultColorPickerWrapper>
      </Styled.ColorControlWrapper>
    );
  }

  @autobind
  private onChangeColor(color: string): void {
    this.props.onColor(color);
    this.toggleOpenMenu();
  }

  @autobind
  private saveRef(ref: HTMLDivElement): void {
    this.colorControl = ref;
  }

  @autobind
  private toggleOpenMenu(e?: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.setState({ isCreateNewOpened: !this.state.isCreateNewOpened });
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    userColors: state.account.settings.colors || [],
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    deleteColor: color => dispatch(AccountActions.removeUserColor(color)),
  };
}

export const ColorControl = connect(mapStateToProps, mapDispatchToProps)(ColorControlComponent);
