import React, { useEffect } from 'react';

import { useDrawingsTextSearch } from 'common/components/drawings';
import { RoundSwitcher } from 'common/components/round-switcher';
import { useActionDispatch } from 'common/hooks';
import { ViewType } from './constants';
import { Settings } from './content';
import { MenuView } from './content/menu-view';
import { useCurrentView } from './hooks';
import { useActualizeNewInstanceName } from './hooks/use-actualize-new-instance-name';
import { useContent } from './hooks/use-content';
import { useCurrentMenu } from './hooks/use-current-menu';
import { TwoDInfoPanelActions } from './store-slice';
import { Styled } from './styled';


const TwoDInfoPanelComponent: React.FC = () => {
  const [view, setView, views] = useCurrentView();
  const PageContent = useContent(setView);
  const currentMenu = useCurrentMenu();
  useActualizeNewInstanceName();
  useDrawingsTextSearch();

  const dropState = useActionDispatch(TwoDInfoPanelActions.dropState);

  useEffect(() => {
    return () => {
      dropState();
    };
  }, []);

  if (currentMenu) {
    return (
      <Styled.Container>
        <MenuView
          title={currentMenu.title}
          infoIcon={currentMenu.infoIcon}
          menuComponent={currentMenu.menuComponent}
          onClose={currentMenu.onClose}
        />
      </Styled.Container>
    );
  }


  return (
    <Styled.Container>
      <Styled.Switcher>
        <RoundSwitcher
          modes={views}
          toggleMode={setView}
          currentMode={view}
          elementHeight={30}
        />
      </Styled.Switcher>
      {
        view === ViewType.Page ? (<PageContent/>) : (<Settings/>)
      }
    </Styled.Container>
  );
};


export const TwoDInfoPanel = React.memo(TwoDInfoPanelComponent);
