import { AnnotationLegendItem } from '../../interfaces/annotation-legend-tree';
import { AnnotationLegendItemTypeguards } from '../../utils/annotation-legend-item-typeguards';

const DRAWING_GEOMETRY_INSTANCE_HEIGHT = 32;
const DRAWING_GEOMETRY_GROUP_HEIGHT = 32;

export const getItemHeight = (item: AnnotationLegendItem): number => {
  if (AnnotationLegendItemTypeguards.isGeometry(item) || AnnotationLegendItemTypeguards.isPivoted(item)) {
    return DRAWING_GEOMETRY_INSTANCE_HEIGHT;
  }
  if (AnnotationLegendItemTypeguards.isGroup(item)) {
    return DRAWING_GEOMETRY_GROUP_HEIGHT;
  }

  throw new Error(`Unexpected item type. ${JSON.stringify(item)}`);
};
