import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  > button {
    > svg {
      fill: ${p => p.theme.color.gray};
      margin: 0;
      width: 20px;
      height: 20px;
      min-width: 20px;
      > circle {
        fill: ${p => p.theme.color.gray};
      }
    }
  }
`;

export const Styled = {
  Container,
};
