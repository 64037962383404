import React from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { AggregationGroup } from '../aggregation-group';
import { Styled } from './styled';

interface Props {
  instancesIds: string[];
  value: string | number;
}

const MeasureGroupComponent: React.FC<Props> = ({
  instancesIds,
  value,
}) => {
  const isImperial = useSelector<State, boolean>(s => s.account.settings.isImperial);
  const visibleValue = value === 'undefined'
    ? 'Empty'
    : UnitUtil.measureToString2d(Number(value), UnitTypes.M, isImperial);
  return (
    <AggregationGroup instancesIds={instancesIds}>
      <Styled.Value>
        {visibleValue}
      </Styled.Value>
    </AggregationGroup>
  );
};


export const MeasureGroup = React.memo(MeasureGroupComponent);
