import { H6 } from '@kreo/kreo-ui-components';
import * as React from 'react';

interface FaqItem {
  title: string;
  content: JSX.Element;
}

export function getFAQList(trialPeriod: number): FaqItem[] {
  return [
    {
      title: 'Can I have a free trial?',
      content: (
        <H6>Yes, you can have a free, full feature {trialPeriod} day trial of the software.</H6>
      ),
    },
    {
      title: 'Why is the trial period so short?',
      content: (
        // eslint-disable-next-line max-len
        <H6>We believe our product is very simple and easy-to-use and that {trialPeriod} days is sufficiently long period to decide if the product is a good value for money.</H6>
      ),
    },
    {
      title: 'Do I need to provide my credit card details?',
      content: (
        // eslint-disable-next-line max-len
        <H6>Yes, we ask our trial customers to provide their credit card details. Your credit card will NOT be charged until the end of the trial period or if you cancel your account before the end of the trial.</H6>
      ),
    },
    {
      title: 'Why do you ask for credit card details?',
      content: (
        // eslint-disable-next-line max-len
        <H6>We use your credit card details as a means of verifying your identity and making sure that our trial users have legitimate interest in our software.</H6>
      ),
    },
    {
      title: 'Would my credit card information be safe?',
      content: (
        // eslint-disable-next-line max-len
        <H6>Yes. We do NOT store your credit card information anywhere in our systems. All your credit card details are processed by Stripe, the global leader in online payment processing.</H6>
      ),
    },
    {
      title: 'Can I cancel my subscription?',
      content: (
        <H6>Yes, you can cancel your subscription any time.</H6>
      ),
    },
    {
      title: 'Can I add more users to my subscription?',
      content: (
        // eslint-disable-next-line max-len
        <H6>Yes. If you are the account owner you can add additional users to the account at any time. Payments for new users will be prorated based on the billing cycle.</H6>
      ),
    },
  ];
}
