import autobind from 'autobind-decorator';
import { UndoRedoAdd } from 'common/undo-redo';
import { DrawingsDrawMode, DrawingsGeometryEntityState, DrawingsInstanceType } from '../../enums';
import { DrawingGeometryUpdateEventHandler, DrawingsMeasurePolygon, DrawingsMeasureRectangle } from '../../interfaces';
import { DrawingRendererEngine } from '../drawing-renderer-engine';
import {
  GetDrawingInstanceCallback,
  GetInstanceMeasureCallback,
  SendMeasuresUpdateCallback,
  SetDrawModeCallback,
} from '../interfaces';

interface DrawingsGeometryConversionProcessorConfig {
  getEngine: () => DrawingRendererEngine;
  getInstanceById: GetDrawingInstanceCallback;
  getInstanceMeasures: GetInstanceMeasureCallback;
  onUpdateInstances: DrawingGeometryUpdateEventHandler;
  onUpdateMeasures: SendMeasuresUpdateCallback;
  addUndoRedo: UndoRedoAdd;
  changeDrawMode: SetDrawModeCallback;
}

export class DrawingsGeometryConversionProcessor {
  private _config: DrawingsGeometryConversionProcessorConfig;

  constructor(config: DrawingsGeometryConversionProcessorConfig) {
    this._config = config;
  }


  @autobind
  public convertRectangleToPolygon(id: string): void {
    const sourceInstance = this._config.getInstanceById(id);
    const sourceMeasures = this._config.getInstanceMeasures(id);
    const undo = (): void => {
      const engine = this._config.getEngine();
      if (engine) {
        engine.rerenderInstanceWithNewType(
          id,
          sourceInstance,
          state => state === DrawingsGeometryEntityState.Modify ? DrawingsGeometryEntityState.Selected : state,
        );
      }
      this._config.changeDrawMode(DrawingsDrawMode.Disabled);
      this._config.onUpdateMeasures([sourceMeasures]);
      this._config.onUpdateInstances({ instance: { ...sourceInstance, id } }, sourceInstance.drawingId);
    };

    const updatedInstance = { ...sourceInstance, type: DrawingsInstanceType.Polygon, id };


    const redo = (): void => {
      const engine = this._config.getEngine();
      if (engine) {
        engine.rerenderInstanceWithNewType(id, updatedInstance);
      }
      const updatedMeasures = { ...sourceMeasures, type: DrawingsInstanceType.Polygon };
      const measures: Partial<DrawingsMeasureRectangle>  = { ...updatedMeasures.measures } as DrawingsMeasureRectangle;
      delete measures.pxRectangleMin;
      delete measures.pxRectangleMax;
      delete measures.rectangleMin;
      delete measures.rectangleMax;
      updatedMeasures.measures = measures as DrawingsMeasurePolygon;
      this._config.onUpdateMeasures([ updatedMeasures]);
      this._config.onUpdateInstances({ instance: updatedInstance }, updatedInstance.drawingId);
    };

    this._config.addUndoRedo(undo, redo);
    redo();
  }
}
