import { Avatar, UsersList } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Company } from 'common/components/project-card';
import { ElementTooltip } from 'common/components/tooltip';
import { UserInfo } from '../project-row';
import { Styled } from './styled';


export interface Props {
  users: UserInfo[];
  isTranslucent: boolean;
  company?: Company;
  isShareWithCompany: boolean;
}

export const UsersListContainer: React.FC<Props> = React.memo(({
  users,
  isTranslucent,
  company,
  isShareWithCompany,
}: Props) => {
  return (
    <Styled.Container
      isTranslucent={isTranslucent}
      showList={!!users?.length}
    >
      {isShareWithCompany && (
        <ElementTooltip
          text={'Available to the whole company'}
          position='bottom'
          wordBreakAll={true}
        >
          <Avatar avatar={company.image} size={40} name={company.name} />
        </ElementTooltip>
      )}
      <UsersList
        maxUsers={4}
        users={users}
        avatarHeight={40}
        avatarShift={20}
        borderWidth={3}
        backgroundColor={'backgroundRush'}
      />
    </Styled.Container>
  );
});
