import React from 'react';

import { useCommentsApiContext } from './hooks';
import { CommentsProviderApi } from './interfaces';

export interface WithCommentsApiContext {
  commentsApi: CommentsProviderApi;
}

export function withCommentsApiContext<P>(
  Component: React.ComponentType<P & WithCommentsApiContext>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof WithCommentsApiContext>>> {
  return (props: P) => {
    const api = useCommentsApiContext();
    return <Component {...props} commentsApi={api} />;
  };
}
