import React from 'react';

import { NewInstanceSettings, NewInstancesFinish } from 'common/components/drawings/layout-components';
import { AdditionalSettings } from './additional-settings';
import { Previews } from './previews';

export const NewMeasureView: React.FC = () => {
  return (
    <>
      <NewInstanceSettings/>
      <AdditionalSettings/>
      <NewInstancesFinish/>
      <Previews/>
    </>
  );
};
