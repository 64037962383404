import { Icons } from '@kreo/kreo-ui-components';

const { AxisDataBase, BoxDataBase, BricksDataBase, BriefcaseDataBase, WoodDataBase,
  BrushDataBase, BucketDataBase, BulbDataBase, CementDataBase, CircularTubesDataBase,
  ColumnDataBase, ConstructionGridDataBase, CordDataBase, CraneDataBase, CranewaterDataBase,
  DimensionDataBase, DoorDataBase, ElbowDataBase, ElectricPlugDataBase, ElectricDataBase,
  FunctionsDataBase, GeomteryDataBase, GlassDataBase, GroupDataBase, HammerDataBase,
  HelmetDataBase, HomeDataBase, HookDataBase, IBeamDataBase, JellyDataBase, KeyDataBase,
  LayersDataBase, Layers2DataBase, LeafletDataBase, LockDataBase, PaintingDataBase,
  PaperListDataBase, ParquetDataBase, ReinforcmentDataBase, RollerDataBase, RoofDataBase,
  Roof2sidesDataBase, RulerDataBase, RulersDataBase, SawDataBase, ScrewDataBase,
  ScrewdriverDataBase, ShovelDataBase, SlabDataBase, SmileDataBase, SpatulaDataBase,
  StairDataBase, SteelplateDataBase, StoneDataBase, TilesDataBase, TubeDataBase,
  TubesDataBase, ValveDataBase, VenttubeDataBase, WallDataBase, WaterDataBase,
  WeldingDataBase, WheelbarrowDataBase, WindowDataBase } = Icons;

import { IconsTypeEnum } from './interfaces';

export const ICON_LIST_MAP = {
  [IconsTypeEnum.Axis]: AxisDataBase,
  [IconsTypeEnum.Box]: BoxDataBase,
  [IconsTypeEnum.Bricks]: BricksDataBase,
  [IconsTypeEnum.Briefcase]: BriefcaseDataBase,
  [IconsTypeEnum.Brush]: BrushDataBase,
  [IconsTypeEnum.Bucket]: BucketDataBase,
  [IconsTypeEnum.Bulb]: BulbDataBase,
  [IconsTypeEnum.Cement]: CementDataBase,
  [IconsTypeEnum.CircularTubes]: CircularTubesDataBase,
  [IconsTypeEnum.Column]: ColumnDataBase,
  [IconsTypeEnum.Constructiongrid]: ConstructionGridDataBase,
  [IconsTypeEnum.Cord]: CordDataBase,
  [IconsTypeEnum.Crane]: CraneDataBase,
  [IconsTypeEnum.Crane1]: CranewaterDataBase,
  [IconsTypeEnum.Dimension]: DimensionDataBase,
  [IconsTypeEnum.Door]: DoorDataBase,
  [IconsTypeEnum.Elbow]: ElbowDataBase,
  [IconsTypeEnum.ElectricPlug]: ElectricPlugDataBase,
  [IconsTypeEnum.Electric]: ElectricDataBase,
  [IconsTypeEnum.Functions]: FunctionsDataBase,
  [IconsTypeEnum.Geomtery]: GeomteryDataBase,
  [IconsTypeEnum.Glass]: GlassDataBase,
  [IconsTypeEnum.Group]: GroupDataBase,
  [IconsTypeEnum.Hammer]: HammerDataBase,
  [IconsTypeEnum.Helmet]: HelmetDataBase,
  [IconsTypeEnum.Home]: HomeDataBase,
  [IconsTypeEnum.Hook]: HookDataBase,
  [IconsTypeEnum.IBeam]: IBeamDataBase,
  [IconsTypeEnum.Jelly]: JellyDataBase,
  [IconsTypeEnum.Key]: KeyDataBase,
  [IconsTypeEnum.Layers]: LayersDataBase,
  [IconsTypeEnum.Layers2]: Layers2DataBase,
  [IconsTypeEnum.Leaflet]: LeafletDataBase,
  [IconsTypeEnum.Lock]: LockDataBase,
  [IconsTypeEnum.Painting]: PaintingDataBase,
  [IconsTypeEnum.Paperlist]: PaperListDataBase,
  [IconsTypeEnum.Parquet]: ParquetDataBase,
  [IconsTypeEnum.Reinforcment]: ReinforcmentDataBase,
  [IconsTypeEnum.Roller]: RollerDataBase,
  [IconsTypeEnum.Roof]: RoofDataBase,
  [IconsTypeEnum.Roof2Sides]: Roof2sidesDataBase,
  [IconsTypeEnum.Ruler]: RulerDataBase,
  [IconsTypeEnum.Rulers]: RulersDataBase,
  [IconsTypeEnum.Saw]: SawDataBase,
  [IconsTypeEnum.Screw]: ScrewDataBase,
  [IconsTypeEnum.Screwdriver]: ScrewdriverDataBase,
  [IconsTypeEnum.Shovel]: ShovelDataBase,
  [IconsTypeEnum.Slab]: SlabDataBase,
  [IconsTypeEnum.Smile]: SmileDataBase,
  [IconsTypeEnum.Spatula]: SpatulaDataBase,
  [IconsTypeEnum.Stair]: StairDataBase,
  [IconsTypeEnum.Steelplate]: SteelplateDataBase,
  [IconsTypeEnum.Stone]: StoneDataBase,
  [IconsTypeEnum.Tiles]: TilesDataBase,
  [IconsTypeEnum.Tube]: TubeDataBase,
  [IconsTypeEnum.Tubes]: TubesDataBase,
  [IconsTypeEnum.Valve]: ValveDataBase,
  [IconsTypeEnum.Venttube]: VenttubeDataBase,
  [IconsTypeEnum.Wall]: WallDataBase,
  [IconsTypeEnum.Water]: WaterDataBase,
  [IconsTypeEnum.Welding]: WeldingDataBase,
  [IconsTypeEnum.Wheelbarrow]: WheelbarrowDataBase,
  [IconsTypeEnum.Window]: WindowDataBase,
  [IconsTypeEnum.Wood]: WoodDataBase,
};
