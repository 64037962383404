import autobind from 'autobind-decorator';
import * as paper from 'paper';

import {
  DrawingsAnnotationsChangeColors,
  DrawingsAnnotationsPosition,
} from 'common/components/drawings/actions/payloads/user-annotation';
import { DrawingsSolidColorSVGUrls, DrawingsSVGIcons } from 'common/components/drawings/constants/drawings-svg-icons';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import { ShortPointDescription } from 'common/components/drawings/interfaces/drawing-ai-annotation';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsBounds } from 'common/components/drawings/interfaces/drawings-geometry';
import {
  DrawingRulerPoints,
  DrawingUserAnnotationImage,
  DrawingUserAnnotationRuler,
} from 'common/components/drawings/interfaces/drawings-user-annotation';
import { UuidUtil } from 'common/utils/uuid-utils';
import { DrawingsMouseEventHandler } from '../../drawings-geometry-entities';
import { DrawingsGeometrySelectionArea } from '../../drawings-geometry-entities/utility';
import { EditPermissions } from '../../interfaces';
import { DrawingsDragProcessingHelper } from '../drag-instances/drawings-drag-processing-helper';
import { DrawingsCursorTypeHelper } from '../visual-helpers';
import { DrawingsUserAnnotationDragHelper } from './drawings-user-annotation-drag-helper';
import { DrawingsUserAnnotationImageEntity } from './drawings-user-annotation-image-entity';
import { DrawingsUserAnnotationImageStore } from './drawings-user-annotation-image-store';
import { DrawingsUserAnnotationRulerEntity } from './drawings-user-annotation-ruler-entity';


export interface DrawingsUserAnnotationEvents {
  onChangeSelection: DrawingsMouseEventHandler;
  onAddImages: (image: DrawingUserAnnotationImage[]) => void;
  onAddRulers: (rulers: DrawingUserAnnotationRuler[]) => void;
  onUpdatePositions: (ids: string[], { x, y }: paper.Point) => void;
  onImageChangeParameter: (id: string, value: number, parameter: keyof DrawingUserAnnotationImage) => void;
  onRulerPointsChange: (id: string, points: DrawingRulerPoints) => void;
}

export interface DrawingsUserAnnotationsHelperConfig {
  cursorHelper: DrawingsCursorTypeHelper;
  dragProcessingHelper: DrawingsDragProcessingHelper;
  renderParametersContext: ContextObserverWithPrevious<DrawingsRenderParams>;
  textRenderParametersObserver: ContextObserver<MeasuresViewSettings>;
  editPermissionsObserver: ContextObserver<EditPermissions>;
  events: DrawingsUserAnnotationEvents;
  colorsCacheHelper: DrawingsColorCacheHelper;
  tempLayer: paper.Layer;
  getImageInfo: (id: string) => DrawingUserAnnotationImage;
  getRulerInfo: (id: string) => DrawingUserAnnotationRuler;
}

export class DrawingsUserAnnotationsHelper {
  private _layer: paper.Layer = new paper.Layer();
  private _config: DrawingsUserAnnotationsHelperConfig;
  private _images: Map<string, DrawingsUserAnnotationImageEntity>
    = new Map<string, DrawingsUserAnnotationImageEntity>();
  private _rulers: Map<string, DrawingsUserAnnotationRulerEntity>
    = new Map<string, DrawingsUserAnnotationRulerEntity>();
  private _imageStore: DrawingsUserAnnotationImageStore = new DrawingsUserAnnotationImageStore();
  private _dragHelper: DrawingsUserAnnotationDragHelper;
  private _selectedAnnotations: string[] = [];

  constructor(config: DrawingsUserAnnotationsHelperConfig) {
    this._config = config;
    this._dragHelper = new DrawingsUserAnnotationDragHelper(
      {
        dragHelper: config.dragProcessingHelper,
        cursorHelper: config.cursorHelper,
        enabled: config.editPermissionsObserver.getContext().canEditUserAnnotation,
        saveDragResult: this._config.events.onUpdatePositions,
      },
    );
    this._dragHelper.dragImages = this.dragImages;
    this._layer.sendToBack();
  }

  public get dragHelper(): DrawingsUserAnnotationDragHelper {
    return this._dragHelper;
  }

  public renderAnnotations(
    images: Record<string, DrawingUserAnnotationImage>,
    rulers: Record<string, DrawingUserAnnotationRuler>,
  ): void {
    for (const [ id, image ] of Object.entries(images)) {
      if (this._images.has(id)) {
        continue;
      }
      if (this._imageStore.isImageExist(image.type)) {
        this.renderImage(image);
      } else {
        this._imageStore.waitForLoadImage(image.type, () => this.renderImage(image));
      }
    }
    for (const [ id, ruler ] of Object.entries(rulers)) {
      if (this._rulers.has(id)) {
        continue;
      }
      this.renderRuler(ruler);
    }
  }

  public destroy(): void {
    this.clear();
    this._layer.remove();
    this._dragHelper.dragImages = null;
  }

  public clear(): void {
    this._images.forEach(x => x.destroy());
    this._images.clear();
    this._rulers.forEach(x => x.destroy());
    this._rulers.clear();
  }

  public changeColors(colorChanges: DrawingsAnnotationsChangeColors[]): void {
    for (const { ids, color } of colorChanges) {
      for (const id of ids) {
        if (this._images.has(id) && DrawingsSolidColorSVGUrls.includes(this._config.getImageInfo(id).type)) {
          this._images.get(id).color = this._config.colorsCacheHelper.getPaperColor(color).stroke;
        } else if (this._rulers.has(id)) {
          this._rulers.get(id).color = this._config.colorsCacheHelper.getPaperColor(color).stroke;
        }
      }
    }
  }

  public getAnnotationsBounds(ids: string[]): DrawingsBounds {
    const { xs, ys } = ids.reduce(
      (prevValue, currentValue) => {
        const  bounds = this.getEntityBounds(currentValue);
        if (bounds) {
          const { x, y, width: w, height: h } = bounds;
          prevValue.xs.push(x);
          prevValue.ys.push(y);
          prevValue.xs.push(x + w);
          prevValue.ys.push(y + h);
        }
        return prevValue;
      },
      { xs: [], ys: [] },
    );
    if (!xs.length) {
      return null;
    }
    const minX = Math.min(...xs);
    const minY = Math.min(...ys);
    const maxX = Math.max(...xs);
    const maxY = Math.max(...ys);
    const width = maxX - minX;
    const height = maxY - minY;
    return {
      x: minX,
      y: minY,
      width,
      height,
      center: { x: minX + width / 2, y: minY + height / 2 },
    };
  }

  public changeAnnotationSelected(selectedAnnotations: string[]): void {
    this.changeSelectionStatus(this._selectedAnnotations, false);
    this.changeSelectionStatus(selectedAnnotations, true);
    this._selectedAnnotations = selectedAnnotations;
  }

  public removeAnnotations(annotationsIds: string[]): void {
    for (const id of annotationsIds) {
      if (this._images.has(id)) {
        this.removeImage(id);
      } else if (this._rulers.has(id)) {
        this.removeRuler(id);
      }
    }
  }

  public addRuler(
    points: [ShortPointDescription, ShortPointDescription, ShortPointDescription],
    color: string,
  ): void {
    const id = UuidUtil.generateUuid();
    const ruler: DrawingUserAnnotationRuler = {
      id,
      positions: points,
      color,
      drawingId: '',
    };
    this.renderRuler(ruler);
    this._config.events.onAddRulers([ruler]);
  }

  public addAnnotationImage(position: paper.Point, img: DrawingsSVGIcons, color?: string): void {
    const id = UuidUtil.generateUuid();
    const imageData: DrawingUserAnnotationImage = {
      id,
      type: img,
      position: [position.x, position.y],
      scale: 1,
      color,
      drawingId: '',
      rotationAngle: -this._config.textRenderParametersObserver.getContext().rotation,
    };
    this.renderImage(imageData);
    this._config.events.onAddImages([imageData]);
  }

  public* imagesInRectIdsIterator(
    rect: paper.Rectangle,
    selectionArea: DrawingsGeometrySelectionArea,
  ): IterableIterator<string> {
    for (const [key, value] of this._images.entries()) {
      if (value.isInRect(rect, selectionArea)) {
        yield key;
      }
    }
    for (const [key, value] of this._rulers.entries()) {
      if (value.isInRect(rect, selectionArea)) {
        yield key;
      }
    }
  }

  public renderImage({ type: imageKey, position, scale, color, id, rotationAngle }: DrawingUserAnnotationImage): void {
    const image = new DrawingsUserAnnotationImageEntity({
      id,
      imageKey,
      position: new paper.Point(position),
      scale,
      layer: this._layer,
      tempLayer: this._config.tempLayer,
      renderParametersContext: this._config.renderParametersContext,
      dragProcessingHelper: this._config.dragProcessingHelper,
      editPermissionsObserver: this._config.editPermissionsObserver,
      onSelect: this._config.events.onChangeSelection,
      onStartDrag: this.startDrag,
      onRotationChange: this.onRotationChanged,
      onScaleChange: this.onScaleChanged,
      imageStore: this._imageStore,
      rotation: rotationAngle,
      cursorHelper: this._config.cursorHelper,
    });

    if (color) {
      image.color = this._config.colorsCacheHelper.getPaperColor(color).stroke;
    }
    this._images.set(id, image);
  }

  public setImageParameter(id: string, value: number, parameter: keyof DrawingUserAnnotationImage): void {
    const image = this._images.get(id);
    if (image) {
      image[parameter] = value;
    }
  }

  public moveImages(updates: DrawingsAnnotationsPosition[]): void {
    for (const { id, position } of updates) {
      this.moveImage(id, new paper.Point(position));
    }
  }

  public setRulerPoints(id: string, points: DrawingRulerPoints): void {
    const ruler = this._rulers.get(id);
    if (ruler) {
      ruler.points = points;
    }
  }

  private getEntityBounds(id: string): paper.Rectangle | null {
    if (this._images.has(id)) {
      return this._images.get(id).bounds;
    } else if (this._rulers.has(id)) {
      return this._rulers.get(id).bounds;
    }
    return null;
  }

  private changeSelectionStatus(annotations: string[], status: boolean): void {
    for (const annotationId of annotations) {
      if (this._images.has(annotationId)) {
        this._images.get(annotationId).selected = status;
      } else if (this._rulers.has(annotationId)) {
        this._rulers.get(annotationId).selected = status;
      }
    }
  }

  private renderRuler({ id, positions: points, color }: DrawingUserAnnotationRuler): void {
    const ruler = new DrawingsUserAnnotationRulerEntity({
      id,
      geometry: points,
      layer: this._layer,
      renderParamsContextObserver: this._config.renderParametersContext,
      dragProcessingHelper: this._config.dragProcessingHelper,
      textRenderParametersObserver: this._config.textRenderParametersObserver,
      color: this._config.colorsCacheHelper.getPaperColor(color).stroke,
      onChangePoints: this._config.events.onRulerPointsChange,
      onSelect: this._config.events.onChangeSelection,
      editPermissionsObserver: this._config.editPermissionsObserver,
    });
    this._rulers.set(id, ruler);
  }


  private removeRuler(id: string): void {
    this._rulers.get(id).destroy();
    this._rulers.delete(id);
  }

  private removeImage(id: string): void {
    this._images.get(id).destroy();
    this._images.delete(id);
  }

  @autobind
  private onRotationChanged(id: string, rotation: number): void {
    this._config.events.onImageChangeParameter(id, rotation, 'rotationAngle');
  }

  @autobind
  private onScaleChanged(id: string, scale: number): void {
    this._config.events.onImageChangeParameter(id, scale, 'scale');
  }

  @autobind
  private startDrag(id: string, point: paper.Point): void {
    const ids = this._selectedAnnotations.includes(id) ? this._selectedAnnotations : [id];
    this._dragHelper.startDrag(point, ids);
  }

  @autobind
  private moveImage(id: string, newPosition: paper.Point): void {
    if (this._images.has(id)) {
      this._images.get(id).position = newPosition;
    }
  }

  @autobind
  private dragImages(diff: paper.Point, ids: string[]): boolean {
    for (const id of ids) {
      if (this._images.has(id)) {
        const position = this._config.getImageInfo(id).position;
        const newPosition = new paper.Point(position).add(diff);
        this.moveImage(id, newPosition);
      }
    }
    return true;
  }
}
