interface Monitor {
  index: number;
}
interface GetMovingElement {
  dragElement: Monitor;
  dragIndex: number;
}

export function getMovingElement(
  hoverIndex: number,
  node: HTMLDivElement,
  monitor: any,
  isVertical?: boolean,
): GetMovingElement {
  if (!node) {
    return;
  }

  let hoverMiddle: number;
  let hoverClient: number;
  const clientOffset = monitor.getClientOffset();
  const hoverBoundingRect = node.getBoundingClientRect();
  const dragElement = monitor.getItem();
  const dragIndex = dragElement.index;

  if (dragIndex === hoverIndex) {
    return;
  }

  if (isVertical) {
    hoverMiddle = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    hoverClient = clientOffset.y - hoverBoundingRect.top;
  } else {
    hoverMiddle = (hoverBoundingRect.right  - hoverBoundingRect.left) / 2;
    hoverClient = clientOffset.x - hoverBoundingRect.left;
  }

  if (dragIndex < hoverIndex && hoverClient < hoverMiddle) {
    return;
  }
  if (dragIndex > hoverIndex && hoverClient > hoverMiddle) {
    return;
  }
  return { dragElement, dragIndex };
}
