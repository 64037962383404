import * as Ag from 'ag-grid-community';

import './tree-table-cell.scss';


const innerHTML = '<span class="tree-table-cell__value"></span>';

const getClassNames = (params: Ag.ICellRendererParams): string | null => {
  const cellClassRules = params.colDef.cellClassRules;
  return cellClassRules && Object.keys(cellClassRules)
    .filter(key => (cellClassRules[key] as (params: Ag.ICellRendererParams) => boolean)(params))
    .join(' ');
};

export class TreeTableCell implements Ag.ICellRendererComp {
  private eGui: HTMLDivElement;
  private eValue: HTMLSpanElement;


  public init(params: Ag.ICellRendererParams): void {
    this.eGui = document.createElement('div');
    this.eGui.className = `tree-table-cell ${getClassNames(params)}`;
    this.eGui.innerHTML = innerHTML;
    this.eValue = this.eGui.querySelector('.tree-table-cell__value');
    this.setValues(params);
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: Ag.ICellRendererParams): boolean {
    this.eGui.className = `tree-table-cell ${getClassNames(params)}`;
    this.setValues(params);
    return true;
  }

  public destroy(): void {
    return;
  }

  private setValues(params: Ag.ICellRendererParams): void {
    const value = params.formatValue(params.value);
    this.eValue.innerHTML = value !== undefined ? value : '';
  }
}
