import { CodecUtils } from 'common/utils/codec-utils';

export enum PermissionState {
  Blocked = 0,
  Disabled = 10,
  Enabled = 11,
  NoAccess = 20,
  View = 21,
  Edit = 22,
}

export const PermissionStateC = CodecUtils.createEnumCodec<PermissionState>(PermissionState, 'PermissionState');
