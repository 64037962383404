import { Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { getDynamicData } from 'unit-2d-database/helpers/get-dynamic-data';
import { AnalyticsProps, withAnalyticsContext, MetricNames } from 'utils/posthog';
import { FormatCreators } from '../../../../helpers';
import {
  PropertyTypeEnum,
  Property,
  AllPropertyValues,
  PropertyFormatEnum,
  BaseFormat,
  CreatePropertyPayload,
  SingleSelectPropertyValue,
  SidePanelExtraProps,
  FormulaPropertyValue,
  BreakDownPropertyValue,
} from '../../../../interfaces';
import { TwoDDatabaseActions } from '../../../../store-slice';
import { INVALIED_CHANGE_UNIT, RENAME_DIALOG_NAME } from '../../constants';
import { SidePanelContentProps } from '../../interfaces';
import { ConfirmDialogs, InvalidChangeUnitDialog } from '../confirm-dialogs';
import {
  RENAME_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG,
  RenameBreakDownPropertyConfirmationDialog,
} from '../confirm-dialogs/rename-break-down-property';
import { Header } from '../header';
import { TextForm, NumberForm, SingleSelectForm, FormulaForm, TreeForm } from './forms';
import { getFormData, getInitialFormData } from './forms/helper';
import { PropertyFormData } from './interfaces';
import { Styled } from './styled';

const TypePayload = {
  [PropertyTypeEnum.Formula]: {
    headerText: 'Formula',
    icon: Icons.Formula,
    component: FormulaForm,
  },
  [PropertyTypeEnum.Number]: {
    headerText: 'Number',
    icon: Icons.Number,
    component: NumberForm,
  },
  [PropertyTypeEnum.SingleSelect]: {
    headerText: 'Single Select',
    icon: Icons.SingleSelect,
    component: SingleSelectForm,
  },
  [PropertyTypeEnum.Text]: {
    headerText: 'Text',
    icon: Icons.Text,
    component: TextForm,
  },
  [PropertyTypeEnum.Breakdown]: {
    headerText: 'Breakdown',
    icon: Icons.Breakdown,
    component: TreeForm,
  },
};

interface StateProps {
  isRenameWarningOpened: boolean;
  type: PropertyTypeEnum;
  data: Property;
  properties: Property[];
  extraProps: SidePanelExtraProps;
}

interface DispatchProps {
  openDialog: (name: string) => void;
  onClose: () => void;
  onSubmit: (data: Property) => void;
  onCreate: (payload: CreatePropertyPayload) => void;
  setOpenCloseDialog: () => void;
}

interface Props extends DispatchProps, StateProps, SidePanelContentProps<Property, ComponentState>, AnalyticsProps {
}

interface ComponentState {
  property: PropertyFormData;
  isValid: boolean;
  groups: string[];
  units: string[];
}

class PropertyPanelComponent extends React.PureComponent<Props, ComponentState> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      property: null,
      isValid: !!props.data,
      groups: [],
      units: [],
    };
  }

  public componentDidMount(): void {
    const [groups, units] = getDynamicData(this.props.properties);
    const formData = this.props.data
      ? getFormData(this.props.data)
      : getInitialFormData(this.props.type, undefined, this.props.extraProps);
    this.setState({ groups, units, property: formData });
  }

  public render(): React.ReactNode {
    const { icon, headerText, component: Form } = TypePayload[this.props.type]
      ? TypePayload[this.props.type]
      : { icon: '', headerText: '', component: '' };
    const { groups, property, units } = this.state;
    const isEdit = !!this.props.data;

    return (
      <Styled.Container>
        <Header
          headerIcon={icon}
          headerText={headerText}
          isEdit={isEdit}
          onSubmit={this.onSubmit}
          isValid={this.state.isValid}
        />
        {property && <Form property={property} groups={groups} units={units} onChange={this.onFormChange} />}
        <ConfirmDialogs
          closeDialogTitle='Are you sure you want to exit the "Create property" Mode?'
          closeDialogOnClose={this.props.onClose}
          renameTitle='Property with the same name already exists'
          renameDialogText='Please rename your property'
        />
        <InvalidChangeUnitDialog />
        <RenameBreakDownPropertyConfirmationDialog
          onSubmit={this.submit}
        />
      </Styled.Container>
    );
  }

  @autobind
  private onSubmit(): void {
    if (this.isPropertyExist()) {
      this.props.openDialog(RENAME_DIALOG_NAME);
      return;
    }
    if (!this.isSingleSelectValid()) {
      this.props.openDialog(INVALIED_CHANGE_UNIT);
      return;
    }

    if (this.isBreakDownPropertyNameChange()) {
      this.props.openDialog(RENAME_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG);
      return;
    }

    this.submit();
  }

  @autobind
  private submit(): void {
    const isEdit = !!this.props.data;

    if (isEdit) {
      this.update();
    } else {
      this.create();
    }
  }

  private isSingleSelectValid(): boolean {
    if (this.props.type === PropertyTypeEnum.SingleSelect) {
      if (this.state.property.format !== PropertyFormatEnum.Text) {
        return this.state.property.available.every(a => !Number.isNaN(Number(a)));
      }
    }
    return true;
  }

  private update(): void {
    const data = { ...this.props.data };
    data.name = this.state.property.name;
    data.value = this.propertyValue;
    data.groupName = this.state.property.groupName;
    this.props.onSubmit(data);
    this.props.onClose();
  }

  private create(): void {
    const id = UuidUtil.generateUuid();
    const data: CreatePropertyPayload = {
      id,
      value: this.propertyValue,
      name: this.state.property.name,
      groupName: this.state.property.groupName,
    };
    this.props.onCreate(data);
    this.props.onClose();
    this.props.afterCreate(data);
    this.props.sendEvent(MetricNames.pia.createProperty, { name: this.state.property.name, type: this.props.type });
  }

  private isPropertyExist(): boolean {
    const { data, properties } = this.props;
    const needCheckName = data?.name !== this.state.property.name;
    return needCheckName && !!properties.find(({ name }) => name === this.state.property.name);
  }

  private isBreakDownPropertyNameChange(): boolean {
    const { data } = this.props;
    const changedName = data?.name && data.name !== this.state.property.name;
    return this.props.type === PropertyTypeEnum.Breakdown && changedName;
  }

  @autobind
  private onFormChange(form: PropertyFormData, isValid: boolean): void {
    const { property } = this.state;
    this.setState({ property: form, isValid: form.name && isValid });
    if (form !== property) {
      this.props.setOpenCloseDialog();
    }
  }

  private get propertyValue(): AllPropertyValues {
    switch (this.props.type) {
      case PropertyTypeEnum.SingleSelect:
        return this.singleSelectValue;
      case PropertyTypeEnum.Formula:
        return this.formulaValue;
      case PropertyTypeEnum.Breakdown:
        return this.breakDownValue;
      default:
        return this.defaultValue;
    }
  }

  private get singleSelectValue(): SingleSelectPropertyValue {
    const { available, value } = this.state.property;
    const format = this.getFormat(this.state.property.format, this.state.property.unit);

    const propertyValue: SingleSelectPropertyValue = {
      format,
      type: PropertyTypeEnum.SingleSelect,
      value: value.toString().trim(),
      available: arrayUtils.uniq(available.map(a => a.toString().trim())),
    };
    return propertyValue;
  }

  private get formulaValue(): FormulaPropertyValue {
    const { value } = this.state.property;
    const format = this.getFormat(this.state.property.format, this.state.property.unit);

    const propertyValue: FormulaPropertyValue = {
      format,
      type: PropertyTypeEnum.Formula,
      value: value as string,
    };
    return propertyValue;
  }

  private get defaultValue(): AllPropertyValues {
    const { value } = this.state.property;
    const format = this.getFormat(this.state.property.format, this.state.property.unit);
    const propertyValue = {
      format,
      type: this.props.type,
      value: value as string | number,
    };
    return propertyValue as AllPropertyValues;
  }

  private get breakDownValue(): BreakDownPropertyValue {
    const { root } = this.state.property;
    const format = this.getFormat(this.state.property.format, this.state.property.unit);
    const propertyValue: BreakDownPropertyValue = {
      format,
      type: PropertyTypeEnum.Breakdown,
      root,
      value: undefined,
    };
    return propertyValue;
  }

  private getFormat(type: PropertyFormatEnum, unit: string): BaseFormat {
    switch (type) {
      case PropertyFormatEnum.Number:
        const numeric = FormatCreators.numeric(unit);
        return numeric;
      case PropertyFormatEnum.Text:
        return FormatCreators.text();
      default:
        return FormatCreators.text();
    }
  }
}

function mapStateToProps(state: State): StateProps {
  const { propertyPanel, properties } = state.twoDDatabase;

  return {
    isRenameWarningOpened: RENAME_DIALOG_NAME in state.dialog,
    type: propertyPanel.type,
    data: propertyPanel.data,
    properties,
    extraProps: state.twoDDatabase.sidePanel.extraProps,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
    onClose: () => {
      dispatch(TwoDDatabaseActions.closeSidePanel());
      dispatch(TwoDDatabaseActions.closePropertyPanel());
    },
    onSubmit: (data) => dispatch(TwoDDatabaseActions.updatePropertyRequest(data)),
    onCreate: (payload) => dispatch(TwoDDatabaseActions.createPropertyRequest(payload)),
    setOpenCloseDialog: () => dispatch(TwoDDatabaseActions.setOpenCloseDialog()),
  };
};

export const PropertyPanel = connect(mapStateToProps, mapDispatchToProps)(withAnalyticsContext(PropertyPanelComponent));
