import { modalAppear } from '@kreo/kreo-ui-components/constants/animations';
import { GENERAL_COLORS } from '@kreo/kreo-ui-components/constants/colors';
import styled from 'styled-components';


const Container = styled.div<{
  containerWidth: number,
  containerHeight: number | string,
  autoHeight: boolean,
  padding: string,
}>`
  display: flex;;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: ${p => p.containerWidth}px;
  max-height: ${p => !p.autoHeight && p.containerHeight ? `${p.containerHeight}px!important` : 'calc(100% - 80px)'};
  min-height: ${p => !p.autoHeight && p.containerHeight ? `${p.containerHeight}` : '260'}px;
  overflow-y: auto;
  background-color: ${props => props.theme.color.backgroundRush};
  box-shadow: ${props => props.theme.shadow.xl};
  border-radius: 40px;
  animation: ${modalAppear} ${p => p.theme.duration.m} ease-in-out;
  padding: ${p => p.padding || '40px'};

  > h6 {
    margin-bottom: 10px;
  }

  > p {
    width: 100%;
  }
`;

const Body = styled.div`
  margin-bottom: 20px;
  word-break: break-all;
  color: ${GENERAL_COLORS.gray};
  font-size: 12px;
`;

const Buttons = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;

  button+button {
    margin-left: auto;
  }
`;

export const Styled = {
  Container,
  Body,
  Buttons,
};
