import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasConstants } from 'common/components/drawings/constants/drawing-canvas-constants';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { DrawingRulerPoints } from 'common/components/drawings/interfaces/drawings-user-annotation';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { DrawingsGeometryEntityMeasureLength, DrawingsGeometryEntityPoint } from '..';
import { SnappingGuideInfo } from '../../drawings-helpers/snapping/with-self-snapping';
import {
  DrawingsGeometryTempEntity,
  DrawingsGeometryTempEntityConfig,
  FinishedDrawingRuler,
} from './drawings-geometry-temp-entity';


export interface DrawingsGeometryTempRulerConfig extends DrawingsGeometryTempEntityConfig {
  color: DrawingsPaperColorInfo;
  layer: paper.Layer;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  textRenderParametersObserver: ContextObserver<MeasuresViewSettings>;
}

export class DrawingsGeometryTempRuler extends DrawingsGeometryTempEntity<DrawingsGeometryTempRulerConfig> {
  private _points: paper.Point[] = [];
  private _visualPoints:  DrawingsGeometryEntityPoint[] = [];
  private _measure: DrawingsGeometryEntityMeasureLength;


  public tryAddPoint(point: paper.Point): boolean {
    this._points.push(point);
    this._visualPoints.push(
      new DrawingsGeometryEntityPoint({
        renderParamsContextObserver: this._config.renderParametersContextObserver,
        id: `temp ${this._points.length}`,
        onStartDrag: null,
        onSelect: null,
        layer: this._config.layer,
        geometry: [point.x, point.y],
      }),
    );
    if (this._points.length === 1) {
      this._visualPoints.push(
        new DrawingsGeometryEntityPoint({
          renderParamsContextObserver: this._config.renderParametersContextObserver,
          id: `temp ${this._points.length}`,
          onStartDrag: null,
          onSelect: null,
          layer: this._config.layer,
          geometry: [point.x, point.y],
        }),
      );
      this._measure = new DrawingsGeometryEntityMeasureLength(
        {
          textLayer: this._config.layer,
          id: 'temp',
          points: [point, point] as [paper.Point, paper.Point],
          color: this._config.color.stroke,
          layer: this._config.layer,
          mustBeOutOfPath: false,
          renderParamsContextObserver: this._config.renderParametersContextObserver,
          textRenderParamsObserver: this._config.textRenderParametersObserver,
          pinnedBorderLength: true,
        },
      );
    }
    return true;
  }

  @autobind
  public updateTempPointPosition(point: paper.Point, strictAngle?: boolean): paper.Point {
    let currentPoint = point;
    if (this._points.length === 2) {
      currentPoint = this.processPositionChange([this._points[0], point, null], strictAngle) || point;
      this._visualPoints[1].paperPosition = currentPoint;
      this._points[1] = currentPoint;
      if (this._measure) {
        this._measure.changeLine([this._points[0], currentPoint]);
      }
    } else if (this._points.length === 3) {
      const [ start, end ] = this._points;
      this._points[2] = point;
      const offset = DrawingsCanvasUtils.getPointOfLineOffset(start, end, point);
      this._measure.changeOffset(offset);
    }
    return currentPoint;
  }

  public get lastStablePoint(): paper.Point {
    return this._points[this.pointsCount - 2];
  }

  public get lastPoint(): paper.Point {
    return this._points[this.pointsCount - 1];
  }

  public get pointsCount(): number {
    return this._points.length;
  }

  public get hasPoint(): boolean {
    return !!this._points.length;
  }

  public canComplete(): boolean {
    return this._points.length === 3;
  }

  public getPoint(index: number): paper.Point {
    return this._points[index];
  }

  public removeLastPoint(): paper.Point {
    if (this._points.length === 2) {
      this.destroy();
      const currentPoint = this._points[0];
      this._points = [];
      return currentPoint;
    } else {
      this._visualPoints.pop().destroy();
      return this._points.pop();
    }
  }

  public getSnappingGuideInfo(point: paper.Point): SnappingGuideInfo {
    if (this._visualPoints && this._visualPoints.length) {
      return DrawingsPaperUtils.getGuidelineValue([this._visualPoints[0].paperPosition], point);
    }
    return null;
  }

  public destroy(): void {
    this._visualPoints.forEach(x => x.destroy());
    if (this._measure) {
      this._measure.destroy();
    }
  }

  public updateColor(): void {
    // must be implemented but only color from config can affect to result
  }

  public convert(): FinishedDrawingRuler {
    const color = this._config.color.stroke.toCSS(true);
    return { points: this._points.map(({ x, y }) => [x, y]) as DrawingRulerPoints, color };
  }

  private processPositionChange(
    points: [paper.Point, paper.Point, paper.Point],
    strictAngle: boolean,
  ): paper.Point {
    if (strictAngle) {
      const angle = DrawingsPaperUtils.getAngleSnappingTurn(
        DrawingsPaperUtils.getPaperAngle(...points),
        DrawingsCanvasConstants.snappingAngleStep,
      );
      points[1] = points[1].rotate(angle, points[0]);
      return points[1];
    }
  }

}
