const prefix = '@CE-activity-assignment';

export const CEActivityAssignmentActionTypes = {
  CALCULATE: `${prefix}/CALCULATE`,
  PATCH: `${prefix}/PATCH`,
  LOAD_DATA: `${prefix}/LOAD_DATA`,
  LOAD_WORK: `${prefix}/LOAD_WORK`,
  LOAD_DATA_SUCCEEDED: `${prefix}/LOAD_DATA_SUCCEEDED`,
  LOAD_WORK_SUCCEEDED: `${prefix}/LOAD_WORK_SUCCEEDED`,
  LOAD_DATA_FAILED: `${prefix}/LOAD_DATA_FAILED`,
  EXPAND_ITEM: `${prefix}/EXPAND_ITEM`,
  SHOW_WORK_FROM_TYPE: `${prefix}/SHOW_WORK_FROM_TYPE`,
  SHOW_WORK_FROM_LAYER: `${prefix}/SHOW_WORK_FROM_LAYER`,
  START_SEARCH: `${prefix}/START_SEARCH`,
  ASSIGN_WORKS: `${prefix}/ASSIGN_WORKS`,
  ASSIGN_WORKS_SUCCEEDED: `${prefix}/ASSIGN_WORKS_SUCCEEDED`,
  DROP_STATE: `${prefix}/DROP_STATE`,
  GET_REVIT_DATA: `${prefix}/GET_REVIT_DATA`,
  SAVE_REVIT_DATA: `${prefix}/SAVE_REVIT_DATA`,
  SET_MODALITY: `${prefix}/SET_MODALITY`,
  SELECT_FROM_ENGINE: `${prefix}/SELECT_FROM_ENGINE`,
  SET_SAVE_DATA: `${prefix}/SET_SAVE_DATA`,
  OPEN_DATABASE_PAGE: `${prefix}/OPEN_DATABASE_PAGE`,
  ASSIGN_MODALITIES: `${prefix}/ASSIGN_MODALITIES`,
  UPDATE_WORK: `${prefix}/UPDATE_WORK`,
  SHOW_HIDE_TREE: `${prefix}/SHOW_HIDE_TREE`,
  SELECT_TREE_NODE: `${prefix}/SELECT_TREE_NODE`,
  SELECT_MATERIAL: `${prefix}/SELECT_MATERIAL`,
  APPROVE: `${prefix}/APPROVE`,
  DISAPPROVE: `${prefix}/DISAPPROVE`,
};
