import * as React from 'react';

import './user-activity-info-cell.scss';

import { NotificationRelatedArea } from 'common/enums/notification-related-area';
import { activityTypes } from '../../constants';

interface Props {
  text?: string;
  relatedArea?: NotificationRelatedArea;
}

export const UserActivityInfoCell: React.FC<Props> = (props: Props): JSX.Element => {
  const relatedArea = activityTypes[props.relatedArea];
  const text = relatedArea ? relatedArea.text : props.text;
  return (
    <div className='user-activity-info-cell'>
      {text && (
        <div className='user-activity-info-cell__text' title={text} style={relatedArea && relatedArea.styles}>
          {text}
        </div>
      )}
    </div>
  );
};
