import React from 'react';
import { useDispatch } from 'react-redux';

import { KreoDialogActions } from 'common/UIKit';
import { AccountActions } from '../../../../account/actions/creators';
import { SUBSCRIPTION__DIALOG } from '../../../../subscription/components/subscription-dialog';
import { UPGRADE_PRO_PLAN_CONSTANTS } from '../constants';

export const useHandleSubmitCallback: (planGroupName: string) => () => void = (planGroupName) => {
  const dispatch = useDispatch();
  return React.useCallback(() => {
    dispatch(AccountActions.setSubscriptionEditFormState({
      selectedPlanGroup: planGroupName,
      selectedAddonGroups: [],
    }));
    dispatch(KreoDialogActions.openDialog(SUBSCRIPTION__DIALOG));
    dispatch(KreoDialogActions.closeDialog(UPGRADE_PRO_PLAN_CONSTANTS.UPGRADE_PLAN_DIALOG));
  }, [planGroupName]);
};
