import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { MenuGroupContainerStyled } from 'common/components/menu-group-container';
import { OptionContainerStyled } from 'common/components/selection-menus';
import { PropertisWithInfoStyled } from '../../property-with-info';

const PopupContainer = styled.div`
  width: 100%;
`;

const StylesCheckbox = styled.div`
  margin-bottom: 5px;
  ${Text} {
    margin-left: 15px;
  }
`;

const MenusContainer = styled.div`
  ${PropertisWithInfoStyled.Container} {
    margin-bottom: 10px;
  }

  ${MenuGroupContainerStyled.Container} {
    padding: 0 10px;
    margin: 10px 0 10px;
    :not(:last-child) {
      border-bottom: 1px solid ${p => p.theme.color.background};
    }
    :last-child {
      margin-bottom: 0px;
    }
    > div {
      > p {
        font-size: 14px;
      }
    }
  }

  ${OptionContainerStyled.Container} {
    margin-bottom: 5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px 10px 10px;

  > button {
    width: 100%;
  }
`;

const ExportProgress = styled.div`
  padding: 10px;
`;

export const Styled = {
  PopupContainer,
  MenusContainer,
  ButtonContainer,
  StylesCheckbox,
  ExportProgress,
};
