import classNames from 'classnames';
import React from 'react';

import './permission.scss';

import { KreoIconTableYes } from 'common/UIKit';
import { PermissionActivityAccess } from '../../interfaces/permission-activity-access';

interface Props {
  access: PermissionActivityAccess;
  displayNoAccessText?: boolean;
  className?: string;
}

export class Permission extends React.Component<Props> {
  public render(): React.ReactNode {
    const { access, displayNoAccessText } = this.props;
    let content = null;
    let classType = null;
    switch (access) {
      case PermissionActivityAccess.Edit:
        content = 'Edit';
        classType = 'edit';
        break;
      case PermissionActivityAccess.View:
        content = 'View';
        classType = 'view';
        break;
      case PermissionActivityAccess.Enabled:
        content = <KreoIconTableYes />;
        classType = 'enabled';
        break;
      case PermissionActivityAccess.NoAccess:
        content = displayNoAccessText ? 'No Access' : null;
        break;
      default:
    }
    const className = classNames('permission', this.props.className, classType);
    return <div className={className}>{content}</div>;
  }
}
