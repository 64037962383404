import { DrawingsGeometryInstanceWithId, DrawingsShortInfo } from '../interfaces';
import { DrawingsFile } from '../interfaces/drawings-file-info';
import { DrawingsGeometryUtils } from './drawings-geometry-utils';


export interface CreateGeometrytAnaliticsGroups {
  height?: boolean;
  offset?: boolean;
  thickness?: boolean;
  count: number;
  fileName: string;
  pageNumber: number;
  pageName: string;
  pageId: string;
}

function addInstanceToGroup(
  acc: Record<string, CreateGeometrytAnaliticsGroups[]>,
  instance: DrawingsGeometryInstanceWithId,
  drawings: Record<string, DrawingsShortInfo>,
  files: Record<string, DrawingsFile>,
): void {
  const { type, geometry, drawingId } = instance;
  const drawing = drawings[drawingId];
  const pageName = drawing.name;
  const pageNumber = drawing.pageNumber + 1;
  const fileName = files[drawing.pdfId].properties.name;
  acc[type] = acc[type] || [];
  if (DrawingsGeometryUtils.isClosedContour(type, geometry)) {
    const searchParam = {
      height: geometry.height !== undefined && geometry.height !== null,
      offset: geometry.offset !== undefined && geometry.offset !== null,
    };
    let currentGroup = acc[type].find((item) => {
      return item.height === searchParam.height
        && item.offset === searchParam.offset
        && item.pageId === drawingId;
    });
    if (!currentGroup) {
      currentGroup = {
        ...searchParam,
        count: 0,
        pageName,
        pageNumber,
        fileName,
        pageId: drawingId,
      };
      acc[type].push(currentGroup);
    }
    currentGroup.count += 1;
  } else if (DrawingsGeometryUtils.isPolyline(type, geometry)) {
    const searchParam = {
      height: geometry.height !== undefined && geometry.height !== null,
      offset: geometry.offset !== undefined && geometry.offset !== null,
      thickness: geometry.thickness !== undefined && geometry.thickness !== null,
    };
    let currentGroup = acc[type].find((item) => {
      return item.height === searchParam.height
        && item.offset === searchParam.offset
        && item.thickness === searchParam.thickness;
    });
    if (!currentGroup) {
      currentGroup = {
        ...searchParam,
        count: 0,
        pageName,
        pageNumber,
        fileName,
        pageId: drawingId,
      };
      acc[type].push(currentGroup);
    }
    currentGroup.count += 1;
  } else {
    if (!acc[type][0]) {
      acc[type][0] = { count: 0, pageName, pageNumber, fileName, pageId: drawingId };
    }
    acc[type][0].count += 1;
  }
}

function groupNewInstancesByTypes(
  instances: DrawingsGeometryInstanceWithId[],
  drawings: Record<string, DrawingsShortInfo>,
  files: Record<string, DrawingsFile>,
): Record<string, CreateGeometrytAnaliticsGroups[]> {
  return instances.reduce((acc, instance) => {
    addInstanceToGroup(acc, instance, drawings, files);
    return acc;
  }, {});
}

export const DrawingsAnalyticsUtils = {
  groupNewInstancesByTypes,
  addInstanceToGroup,
};
