import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { MultiViewSelectors } from 'common/utils/multi-view/multi-view-selector';
import { DrawingContextMenuItems } from './drawings-context-menu-config';
import { DrawingsContextMenuContent } from './drawings-context-menu-content';
import { UseFiltersCachePayload, UseOptionsConfigParams, useFiltersCache, useOptionsConfig } from './hooks';
import { ActionEventHandlers } from './interfaces';

interface Props extends UseOptionsConfigParams, UseFiltersCachePayload {
  canEditMeasurement: boolean;
  onClose: () => void;
  canJoinCount: boolean;
  canJoinPolyline: boolean;
  canBoolWithPolygons: boolean;
  actionEventHandlers: Record<string, ActionEventHandlers>;
  canToggleFullScreen: boolean;
}

const DrawingsContextMenuOptionsComponent: React.FC<Props> = (props) => {
  const filtersCache = useFiltersCache(props);
  const optionsConfig = useOptionsConfig(props);
  const { actionEventHandlers, isFullScreen } = props;
  const isReportInWindow = !MultiViewSelectors.useIsMultiViewConnect();
  const isShow2dReport = useSelector<State, boolean>((state) => state.twoD.isShowTwoDReport);
  const { doActionAndClose, onClose, canBoolWithPolygons, canEditMeasurement, canJoinCount, canJoinPolyline } = props;
  const closeEmptyMenu = useCallback(() => {
    const joinEnabled = canEditMeasurement && (canJoinCount || canJoinPolyline || canBoolWithPolygons);
    if (!canBoolWithPolygons && !joinEnabled) {
      onClose();
    }
  }, [canBoolWithPolygons, canEditMeasurement, canJoinCount, canJoinPolyline, onClose]);

  return (
    <DrawingsContextMenuContent
      filters={filtersCache}
      items={DrawingContextMenuItems}
      optionsConfig={optionsConfig}
      onClose={closeEmptyMenu}
      actionEventHandlers={actionEventHandlers}
      isShowTwoDReport={isShow2dReport && (isReportInWindow || !isFullScreen)}
      doActionAndClose={doActionAndClose}
    />
  );
};

export const DrawingsContextMenuOptions = React.memo(DrawingsContextMenuOptionsComponent);
