import { Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AbilityRoute } from 'common/ability/ability-route';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { MetaTagUtils, MetaTitlePostfix } from 'common/utils/meta-tag-utils';
import { PageHeader } from '../page-header';
import { Styled } from './styled';

interface Props<TRouteParams> {
  metaTitle: string;
  component: React.ComponentType<RouteComponentProps<TRouteParams>>;
  path: string;
  exact?: boolean;
  subject?: Subject;
  redirectUrl?: string;
  operation?: Operation;
  logoSrc?: string;
  hideCommentButton?: boolean;
  showProjectMenu?: boolean;
  headerInnerContent?: React.ReactNode;
  saveContainerRef: (ref: HTMLDivElement) => void;
}

export class PageLayoutNew<TRouteParams> extends React.Component<Props<TRouteParams>> {

  public componentDidMount(): void {
    MetaTagUtils.setTitle(this.props.metaTitle, MetaTitlePostfix.Takeoff);
  }

  public componentDidUpdate(): void {
    MetaTagUtils.setTitle(this.props.metaTitle, MetaTitlePostfix.Takeoff);
  }

  public render(): React.ReactNode {
    const { exact, path, subject, operation, redirectUrl } = this.props;
    return (
      <AbilityRoute
        exact={exact}
        path={path}
        component={this.renderRouteComponent}
        subject={subject}
        operation={operation}
        redirectUrl={redirectUrl}
      />
    );
  }

  @autobind
  public renderRouteComponent(
    props: RouteComponentProps<TRouteParams>,
  ): JSX.Element {
    const {
      component: InnerComponent,
      redirectUrl,
      logoSrc,
      headerInnerContent,
      hideCommentButton,
      showProjectMenu,
      saveContainerRef,
    } = this.props;

    return (
      <Styled.Container ref={saveContainerRef}>
        {logoSrc && (
          <PageHeader
            redirectUrl={redirectUrl}
            logoSrc={logoSrc}
            Icon={Icons.KreoSmallLogo2D}
            size={40}
            hideButton={hideCommentButton}
            showProjectMenu={showProjectMenu}
          >
            {headerInnerContent}
          </PageHeader>
        )}
        <Styled.Content>
          <InnerComponent {...props} />
        </Styled.Content>
      </Styled.Container>
    );
  }
}
