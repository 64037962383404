import { useMemo } from 'react';

import { TablePreset } from '2d/interfaces';
import { MultilevelSelectOptionDataWithObjectValue } from 'common/components/multi-level-drop-down-menu';
import { MoveToCellOptionData } from '../../interfaces/options';
import { DrawingsContextMenuOptionsGetter } from '../drawings-context-menu-config';
import { OptionsConfig } from '../interfaces';
import { useDynamicMoveToCellOptionRenderer, useMoveToCellOptionsRenderer } from './use-move-to-cell-options-renderers';

export interface UseOptionsConfigParams {
  onDynamicMoveToCell: (preset: TablePreset) => void;
  getCurrentMeasureTypes: () => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>;
  getFolderToCellOptions: () => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>;
  color: string;
  doActionAndClose: (action: (...args: any) => void, ...args: any) => void;
}

export function useOptionsConfig(
  {
    onDynamicMoveToCell,
    getCurrentMeasureTypes,
    getFolderToCellOptions,
    color,
    doActionAndClose,
  }: UseOptionsConfigParams,
): Record<string, OptionsConfig> {
  const moveToCellOptionsRenderer = useMoveToCellOptionsRenderer(color);
  const dynamicMoveToCellOptionRenderer = useDynamicMoveToCellOptionRenderer(
    onDynamicMoveToCell,
    doActionAndClose,
  );
  return useMemo(
    () => ({
      [DrawingsContextMenuOptionsGetter.MoveToCell]: {
        getOptions: getCurrentMeasureTypes,
        optionsRenderer: moveToCellOptionsRenderer,
      },
      [DrawingsContextMenuOptionsGetter.DynamicMoveToCell]: {
        getOptions: () => [],
        childRenderer: dynamicMoveToCellOptionRenderer,
      },
      [DrawingsContextMenuOptionsGetter.FolderToCell]: {
        getOptions: getFolderToCellOptions,
        optionsRenderer: moveToCellOptionsRenderer,
      },
    }),
    [getCurrentMeasureTypes, getFolderToCellOptions, moveToCellOptionsRenderer, dynamicMoveToCellOptionRenderer],
  );
}
