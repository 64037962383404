import autobind from 'autobind-decorator';
import * as THREE from 'three';
import { HoverInstanceChangeEvent } from 'common/components/drawings/drawings-geometry/interfaces';

interface Config {
  root: HTMLElement;
  domElement: HTMLElement;
  scene: THREE.Scene;
  camera: THREE.Camera;
  onClick: (id: string, e: MouseEvent) => void;
  onMouseOver: (e: HoverInstanceChangeEvent) => void;
}

export class MouseController {
  private _config: Config;
  private _hoveredId: string;

  private _rayCaster: THREE.Raycaster;
  private _mouse: THREE.Vector2;

  constructor(config: Config) {
    this._config = config;
    this._rayCaster = new THREE.Raycaster();
    const { domElement } = config;
    domElement.addEventListener('click', this.onClick);
    domElement.addEventListener('mousemove', this.onMouseMove);
  }

  public destroy(): void {
    const { domElement } = this._config;
    domElement.removeEventListener('click', this.onClick);
    domElement.removeEventListener('mousemove', this.onMouseMove);
  }

  @autobind
  private updateMousePosition(e: MouseEvent): void {
    const { left, top, width, height } = this._config.domElement.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 2 - 1;
    const y = -((e.clientY - top) / height) * 2 + 1;
    if (this._mouse) {
      this._mouse.set(x, y);
    } else {
      this._mouse = new THREE.Vector2(x, y);
    }
  }

  @autobind
  private onMouseMove(event: MouseEvent): void {
    this.updateMousePosition(event);
    this._rayCaster.setFromCamera(this._mouse, this._config.camera);
    const intersects = this._rayCaster.intersectObject(this._config.scene, true);
    const hoveredId = intersects.length > 0 && intersects[0].object.userData.id
      ? intersects[0].object.userData.id : undefined;
    if (this._hoveredId === hoveredId) {
      return;
    }
    if (hoveredId) {
      this._config.domElement.style.cursor = 'pointer';
      this._config.onMouseOver({ id: hoveredId, hovered: true, event });
    } else {
      this._config.domElement.style.cursor = 'default';
      this._config.onMouseOver({ id: this._hoveredId, hovered: false, event });
    }
    this._hoveredId = hoveredId;
  }

  @autobind
  private onClick(e: MouseEvent): void {
    this._config.root.focus();
    this._config.onClick(this._hoveredId, e);
  }
}
