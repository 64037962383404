import autobind from 'autobind-decorator';
import * as React from 'react';

import { MapIdHelper } from '../../../../components/engine/map-id-helper';
import { SplitterLayout } from '../../../../components/splitter-layout';
import { PaneConfig } from '../../../../components/splitter-layout/interfaces';
import { CustomFilterList } from '../custom-filter-list';
import { QtoCustomFilterBuilder } from '../quantity-take-off-custom-filter-builder';
import { TableApi } from '../quantity-take-off-left-panel/interfaces';


interface State {
  selectedCustomFilterId: string;
  paneConfigs: Array<{ size: number, minSize: number, maxSize?: number }>;
}

interface Props {
  recordValuesMap: Record<string, Record<string, void>>;
  onCustomFiltersSelect: (ids: string[]) => void;
  sendTableSelectionApi?: (ref: TableApi) => void;
  mapIdHelper: MapIdHelper;
}

export class QtoCustomFilterFilterPanel extends React.Component<Props, State> {
  private refLayout: HTMLDivElement;
  private filterListTableApi: TableApi;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedCustomFilterId: null,
      paneConfigs: this.getHiddenEditorPaneConfig(),
    };
  }

  public componentDidUpdate(_prevProps: Props, prevState: State): void {
    if (this.state.selectedCustomFilterId !== prevState.selectedCustomFilterId
      && (!this.state.selectedCustomFilterId || !prevState.selectedCustomFilterId)
    )
      this.setState({ paneConfigs: this.getPaneConfig() });
  }

  public render(): React.ReactNode {
    return (
      <div ref={this.saveLayoutRef} style={{ height: '100%' }}>
        <SplitterLayout
          horizontal={true}
          paneConfigs={this.state.paneConfigs}
        >
          <CustomFilterList
            onSelectionChange={this.onFilterSelectChange}
            sendTableSelectionApi={this.saveTableSelectionApi}
          />
          <QtoCustomFilterBuilder
            filterId={this.state.selectedCustomFilterId}
            recordValuesMap={this.props.recordValuesMap}
            closeFilterEditor={this.closeFilterEditor}
            mapIdHelper={this.props.mapIdHelper}
          />
        </SplitterLayout>
      </div>
    );
  }

  @autobind
  private saveTableSelectionApi(api: TableApi): void {
    this.filterListTableApi = api;
    this.props.sendTableSelectionApi(api);
  }

  @autobind
  private onFilterSelectChange(ids: string[]): void {
    this.setState(
      {
        selectedCustomFilterId: ids.length === 1 ? ids[0] : null,
      },
      () => this.props.onCustomFiltersSelect(ids),
    );
  }

  @autobind
  private closeFilterEditor(): void {
    this.setState(
      { selectedCustomFilterId: null },
      () => this.filterListTableApi.setSelected([]),
    );
  }

  @autobind
  private saveLayoutRef(refLayout: HTMLDivElement): void {
    this.refLayout = refLayout;
  }

  private getPaneConfig(): PaneConfig[] {
    return this.state.selectedCustomFilterId
      ? this.withEditorPaneConfig()
      : this.getHiddenEditorPaneConfig();
  }

  private withEditorPaneConfig(): PaneConfig[] {
    const refLayoutHeightFiftyPercent = this.refLayout && this.refLayout.getBoundingClientRect().height / 2;
    return [{
      minSize: 50,
      size: this.refLayout
        ? refLayoutHeightFiftyPercent
        : window.document.body.getBoundingClientRect().height,
    },
    {
      minSize: 50,
      size: refLayoutHeightFiftyPercent,
    }];
  }

  private getHiddenEditorPaneConfig(): PaneConfig[] {
    return [{
      minSize: 150,
      size: this.refLayout
        ? this.refLayout.getBoundingClientRect().height
        : window.document.body.getBoundingClientRect().height,
    },
    {
      minSize: 0,
      maxSize: 0,
      size: 0,
    }];
  }
}
