import { ElementTooltip, IconButton, Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch  } from 'redux';

import { FilterDataInitialState } from 'common/components/drawings/constants/drawings-initial-state';
import { State } from 'common/interfaces/state';
import { DrawingsAnnotationLegendActions } from '../actions/creators/drawings-annotation-legend';
import {
  AnnotationFilters,
  FilterChangePayload,
  FilterChangePayloadElement,
  FilterData,
} from '../interfaces/drawing-filters';
import { DrawingsAnnotationLegendFiltersMenu } from './drawings-annotation-legend-filters-menu';
import { Styled } from './styled';


interface StateProps {
  filterData: FilterData;
}

interface DispatchProps {
  setFilterData: (filterData: FilterData) => void;
  changeFilterData: (changeFilterData: FilterChangePayload) => void;
}


interface OwnProps {
  geometryCount: number;
  isFiltersChanged: (prevData: FilterData, currentData: FilterData) => boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

interface ComponentState {
  filtersMenu: { isShow: boolean, x: number, y: number };
}

class DrawingsAnnotationLegendFilterComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filtersMenu: { isShow: false, x: 0, y: 0 },
    };
  }

  public render(): JSX.Element {
    const { isShow, x, y } = this.state.filtersMenu;
    const isFiltersChanged = this.props.isFiltersChanged(FilterDataInitialState, this.props.filterData);

    return (
      <>
        <ElementTooltip
          text='Filters'
          position='top'
        >
          <Styled.DotMarkWrapper active={isFiltersChanged}>
            <IconButton
              Icon={Icons.Filters2D}
              onClick={this.filtersButtonClick}
              width={20}
              height={20}
              defaultColor={isFiltersChanged && 'turquoise'}
            />
          </Styled.DotMarkWrapper>
        </ElementTooltip>
        {isShow && <DrawingsAnnotationLegendFiltersMenu
          x={x}
          y={y}
          onClose={this.filtersClose}
          filterData={this.props.filterData}
          onColorChange={this.onColorChange}
          isResetPossible={isFiltersChanged}
          resetFilters={this.resetFilterData}
          handleFilterChange={this.handleFilterChange}
          geometryCount={this.props.geometryCount}
        />}
      </>
    );
  }

  @autobind
  private handleFilterChange(filterType: AnnotationFilters, element: FilterChangePayloadElement): void {
    this.props.changeFilterData({ filterType, element });
  }

  @autobind
  private resetFilterData(): void {
    this.props.setFilterData({
      ...FilterDataInitialState,
      [AnnotationFilters.Name]: this.props.filterData[AnnotationFilters.Name],
      [AnnotationFilters.Pages]: this.props.filterData[AnnotationFilters.Pages],
    });
  }

  @autobind
  private filtersButtonClick(e: React.MouseEvent<HTMLButtonElement>): void {
    const { left, top } = e.currentTarget.getBoundingClientRect();
    this.setState({ filtersMenu: { isShow: true, x: left, y: top + 10 } });
  }

  @autobind
  private filtersClose(): void {
    this.setState({ filtersMenu: { isShow: false, x: 0, y: 0 } });
  }

  @autobind
  private onColorChange(color: string): void {
    this.props.changeFilterData({ filterType: AnnotationFilters.Color, element: color });
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    filterData: state.drawings.filterData,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setFilterData: (filterData) => dispatch(DrawingsAnnotationLegendActions.setFilterData(filterData)),
    changeFilterData: (changeFilterData) =>
      dispatch(DrawingsAnnotationLegendActions.changeFilterData(changeFilterData)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const DrawingsAnnotationLegendFilter = connector(DrawingsAnnotationLegendFilterComponent);
