import { DrawingsCanvasConstants } from 'common/components/drawings/constants/drawing-canvas-constants';
import { DrawingsInstanceType } from 'common/components/drawings/enums/drawings-instance-type';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { ThreeDParamsHelper } from '../../drawings-helpers/threed-params-helper';
import { DrawingsGeometryEntityAngleArc } from '../drawings-geometry-entity-angle-arc';
import { FinishedDrawingElement } from './drawings-geometry-temp-entity';
import { DrawingsGeometryTempEntityWithStrokeConfig } from './drawings-geometry-temp-entity-with-stroke';
import { DrawingsGeometryTempPolygonEntity } from './drawings-geometry-temp-polygon-entity';

export class DrawingsGeometryTempRectangleEntity extends DrawingsGeometryTempPolygonEntity {
  constructor(config: DrawingsGeometryTempEntityWithStrokeConfig) {
    super(config);
    this._path.fillColor = this._config.color.fill;
    this.updateStrokeParams();
  }

  public canComplete(): boolean {
    return this.pointsCount > 2;
  }

  public convert(): FinishedDrawingElement[] {
    const { color, strokeWidth, strokeStyle } = this._config;
    const {
      points,
      polyline,
    } = DrawingsPaperUtils.convertPathToPolyline(this._path, color.stroke.toCSS(true), strokeWidth, strokeStyle, true);
    const lastPointId = UuidUtil.generateUuid();
    polyline.points.push(lastPointId);
    const [p1, p2, p3] = this._path.segments.map(segment => segment.point);
    const { x, y } = DrawingsPaperUtils.findLastPointOfRectangle(p1, p2, p3);
    const { width, height } = this._config.getDrawingInfo();
    if (x < 0 || x > width || y < 0 || y > height) {
      return null;
    }
    points[lastPointId] = [x, y];
    ThreeDParamsHelper.addToPolygon(polyline, this._config.newDrawingStylesObserver.getContext());
    return [{
      type: DrawingsInstanceType.Rectangle,
      geometry: polyline,
      points,
    }];
  }

  protected processPositionChange(
    points: [paper.Point, paper.Point, paper.Point],
    strictAngle?: boolean,
  ): paper.Point {
    if (this.pointsCount === 2) {
      super.processPositionChange(points, strictAngle);
    } else if (this.pointsCount > 2) {
      const angle = DrawingsPaperUtils.getRightAngleSnappingTurn(DrawingsPaperUtils.getPaperAngle(...points));
      if (angle !== 90) {
        const point = DrawingsPaperUtils.getClosestPointOnLine(
          this._path.lastSegment.point.rotate(angle, points[1]),
          this._path.lastSegment.previous.point,
          this._path.lastSegment.point,
        );
        this._path.lastSegment.point = DrawingsCanvasUtils.getLineToCanvasIntersection(
          point,
          this._path.lastSegment.previous.point,
          this._config.getDrawingInfo(),
        ) || point;
      }
    }
    this.renderMeasures(points, this.pointsCount === 3 ? 1 : 0);
    return this._path.lastSegment.point;
  }

  protected renderOrUpdateArc(points: [paper.Point, paper.Point, paper.Point]): void {
    if (!this._arc) {
      this._arc = new DrawingsGeometryEntityAngleArc({
        id: 'temp',
        points,
        color: this._config.color.stroke,
        layer: this._config.layer,
        mustExistInPath: true,
        clampPath: this._path,
        specificViewForRightAngle: true,
        radius: DrawingsCanvasConstants.angleCircleRadius / 2,
        renderParamsContextObserver: this._config.renderParametersContextObserver,
        textRenderParamsObserver: this._config.textRenderParametersObserver,
      });
    } else {
      this._arc.updatePoints(points, this._path, true);
    }
  }
}
