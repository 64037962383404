import { Constants, VisibillityControlPanelStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { MenuPropertiesStyled } from 'unit-2d-database/components/menu-properties';
import { Styled  as CommonStyled } from '../styled';

const Container = styled.div`
  display: flex;
  height: 100%;
  position: relative;
`;

const PanelBlock = styled.div<{ showNotification: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${MenuPropertiesStyled.Container} {
    position: absolute;
    right: 0;
    z-index: 1;
  }
  ${VisibillityControlPanelStyled.ControlPanelContainer} {
    width: 100%;
  }
  ${VisibillityControlPanelStyled.ControlPanelContainer} {
    border-bottom: ${p => !p.showNotification && 'none'};
  }
`;

const AssemblyForm = styled.div`
  padding: 20px 25px;
`;

const ScrollBarContainer = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  >${CommonStyled.AssemblyGroup} {
    padding: 0;
    ${CommonStyled.ItemForm} {
      margin-bottom: 0;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: ${p => p.theme.color.gray} !important;
  }
  ::-webkit-scrollbar-track {
    background: ${p => p.theme.color.background};
  }
`;

const Navigation = styled.div`
  height: ${60}px;
  > div {
    margin-left: 20px;
  }
`;

const AddNewButtonContainer = styled.div`
  top: 20px;
  right: 20px;
  position: absolute;
`;

const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  padding: 0 10px;

  button {
    > p {
      color: ${Constants.Colors.GENERAL_COLORS.white}
    }
  }
  > p {
    text-align: center;
  }
`;

export const Styled = {
  Container,
  PanelBlock,
  AssemblyForm,
  ScrollBarContainer,
  Navigation,
  AddNewButtonContainer,
  EmptyList,
};
