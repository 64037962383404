import * as t from 'io-ts';
import { DrawingsGeometryGroupC } from '../drawings-geometry-group';

export const GeometryGroupsResponseC = t.intersection(
  [
    t.type(
      {
        groups: t.array(DrawingsGeometryGroupC),
        pinnedGroupIds: t.array(t.string),
      }),
    t.partial({
      color: t.string,
      parentId: t.string,
      creatorId: t.string,
      createdAt: t.string,
    }),
  ],
  'GeometryGroupResponse');


export type GeometryGroupsResponse = t.TypeOf<typeof GeometryGroupsResponseC>;
