import { FormatTypeGuards } from 'unit-2d-database/helpers/format-typeguards';
import { roundNumeric } from 'unit-2d-database/helpers/round-numeric';
import { formatData } from '../../../../../../helpers/format-data-getter';
import {
  AllPropertyValues,
  Property,
  PropertyFormatEnum,
  PropertyTypeEnum,
  PropertyValue,
  SingleSelect,
} from '../../../../../../interfaces';
import { Field, FieldType } from '../../interfaces';

export interface SingleSelectFieldInput {
  elements: Array<string | number>;
  activeElementIndex: number;
  onClick: (value: number) => void;
  onBlur: (value: string) => void;
  value: string | number;
  format: PropertyFormatEnum;
  createNewElement: boolean;
}

function getChangeHandler(
  onChange: (input: SingleSelectFieldInput) => void,
  elements: Array<string | number>,
  format: PropertyFormatEnum,
) {
  return (value: number) => {
    onChange({
      elements,
      activeElementIndex: value,
      onClick: getChangeHandler(onChange, elements, format),
      onBlur: getChangeBlurHandler(onChange, elements, value, format),
      value: elements[value],
      format,
      createNewElement: true,
    });
  };
}

function getChangeBlurHandler(
  onChange: (input: SingleSelectFieldInput) => void,
  elements: Array<string | number>,
  activeElementIndexInput: number,
  format: PropertyFormatEnum,
) {
  return (value: string) => {
    onChange({
      elements,
      activeElementIndex: activeElementIndexInput,
      onClick: getChangeHandler(onChange, elements, format),
      onBlur: getChangeBlurHandler(onChange, elements, activeElementIndexInput, format),
      value,
      format,
      createNewElement: true,
    });
  };
}

export const mapSingleSelectPropertyToField = (
  property: Property<PropertyValue<PropertyTypeEnum.SingleSelect>>,
  originProperty: Property<PropertyValue<PropertyTypeEnum.SingleSelect>>,
  units: string[],
  onChange: (value: SingleSelectFieldInput) => void,
  onDeleteClick: () => void,
  onClickUnit: (index: number) => void,
  onBlureUnit: (unit: string) => void,
  onVisibilityClick: () => void,
): Field<SingleSelectFieldInput> => {
  const formatType = property.value.format.type;
  const available = originProperty
    ? originProperty.value.available || []
    : [];
  const singleSelectValue: AllPropertyValues = {
    ...property.value,
    available,
  };
  const value = singleSelectValue as SingleSelect;
  const roundValue = !FormatTypeGuards.isNumeric(property.value.format)
    ? value
    : {
      value: roundNumeric(Number(value.value)),
      available: value.available.map(roundNumeric),
    };
  const activeElementIndexInput = roundValue.available.indexOf(roundValue.value);
  const dropDownProps = !FormatTypeGuards.isNumeric(property.value.format)
    ? undefined
    : {
      elements: units,
      activeElementIndex: units.indexOf(property.value.format.unit),
      onClick: onClickUnit,
      value: property.value.format.unit,
      onBlur: onBlureUnit,
    };
  return {
    id: property.id,
    originId: originProperty?.id || '',
    name: property.name,
    type: FieldType.SingleSelect,
    isHide: false,
    input: {
      elements: roundValue.available,
      activeElementIndex: activeElementIndexInput,
      onClick: getChangeHandler(onChange, roundValue.available, formatType),
      onBlur: getChangeBlurHandler(onChange, roundValue.available, activeElementIndexInput, formatType),
      value: roundValue.value,
      format: formatType,
      createNewElement: true,
    },
    dropDownProps,
    onDeleteClick,
    onVisibilityClick,
    getDataUpdate: (field: Field<SingleSelectFieldInput>): Property<PropertyValue<PropertyTypeEnum.SingleSelect>> => {
      const format = property.value.format;
      const updatedValue = {
        ...property.value,
        value: field.input.value,
        available,
      };
      if (!FormatTypeGuards.isText(format)) {
        const { activeElementIndex: currentIndex, elements, value: valueUnit } = field.dropDownProps;
        updatedValue.format = formatData.updateFormatUnit(format, valueUnit || elements[currentIndex]);
        const { value: newValue } = updatedValue as SingleSelect;
        updatedValue.value = newValue;
        updatedValue.available = undefined;
      }
      return {
        ...property,
        id: field.id,
        value: updatedValue,
      };
    },
  };
};
