import { Icons, Text } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { PaymentMethodActions } from '../../../actions/creators';
import { BillingPaymentModel } from '../../../interfaces/billing-payment-model';
import { CreateBillingCardForm } from '../../../interfaces/create-billing-card-form';
import { PaymentManager } from '../../payment-method-manager/payment-manager';
import { FillStepScaleSubscription } from '../fill-step-scale-subscription';
import { Styled } from './styled';

interface StateProps {
  paymentMethods: BillingPaymentModel[];
  primaryCardId: string;
  backupCardId: string;
}

interface DispatchProps {
  loadPaymentData: () => void;
  loadPaymentMethods: (force?: boolean) => void;
  setPrimaryCard: (id: string) => void;
  setBackupCard: (id: string) => void;
  removeCard: (id: string) => void;
  createCardLegacy: (form: CreateBillingCardForm) => void;
}

interface Props extends StateProps, DispatchProps {
  paymentCardId: string | null;
  onChangePaymentCardId: (id: string) => void;
}

const PaymentManagerBlockComponent: React.FC<Props> = (props) => {
  const {
    paymentMethods,
    primaryCardId,
    backupCardId,
    paymentCardId,
    removeCard,
    onChangePaymentCardId,
    setPrimaryCard,
    loadPaymentMethods,
    setBackupCard,
    loadPaymentData,
  } = props;

  useEffect(() => {
    loadPaymentData();
  }, []);

  const createCardLegacy = useCallback((card: { cardNumber: string, expiryDate: string, cvc: string }) => {
    const matches = card.expiryDate.match(/(\d+) \/ (\d+)/);
    props.createCardLegacy({
      cardNumber: card.cardNumber,
      cvv: card.cvc,
      expiryMonth: Number(matches[1]),
      expiryYear: Number(matches[2].padStart(4, '2000')),
      firstName: null,
      lastName: null,
    });
  }, [props.createCardLegacy]);

  const updatePaymentMethodToValid = useCallback(() => {
    if (!paymentMethods?.length || paymentMethods.some(x => x.id === paymentCardId)) {
      return;
    }

    if (primaryCardId && paymentMethods.some(x => x.id === primaryCardId)) {
      onChangePaymentCardId(primaryCardId);
      return;
    }

    if (backupCardId && paymentMethods.some(x => x.id === backupCardId)) {
      onChangePaymentCardId(backupCardId);
      return;
    }

    onChangePaymentCardId(paymentMethods[0].id);
  }, [paymentMethods, primaryCardId, backupCardId, paymentCardId, onChangePaymentCardId]);

  useEffect(() => {
    updatePaymentMethodToValid();
  }, [props]);

  const isOldUI = useAbility(Operation.Read, Subject.OldPaymentUI);

  return (
    <>
      <Styled.FillStepScaleContainer>
        <FillStepScaleSubscription
          numberStep={2}
          isValid={!!primaryCardId}
        />
      </Styled.FillStepScaleContainer>
      <Styled.ContentContainer headerMargin={26}>
        <Text>Enter your payment method</Text>
        <Styled.PaymentManagerWrapper isOldUI={isOldUI}>
          <PaymentManager
            loadBillings={loadPaymentMethods}
            cards={paymentMethods}
            primaryCardId={primaryCardId}
            backupCardId={backupCardId}
            subscriptionCardId={paymentCardId}
            setPrimaryCard={setPrimaryCard}
            setBackupCard={setBackupCard}
            setSubscriptionCard={onChangePaymentCardId}
            removeCard={removeCard}
            createCardLegacy={createCardLegacy}
          />
          <RenderIf condition={!paymentMethods?.length}>
            <Styled.PaymentManagerInfo isOldUI={isOldUI}>
              <RenderIf condition={!isOldUI}>
                <Styled.Info>
                  <Styled.IconContainer>
                    <Icons.ProtectedByStripe />
                  </Styled.IconContainer>
                  <Text
                    color={'turquoiseFont'}
                    fontSize={12}
                  >
                    Protected by Stripe
                  </Text>
                </Styled.Info>
              </RenderIf>
              <Styled.Info>
                <Styled.IconContainer>
                  <Icons.NoCharges />
                </Styled.IconContainer>
                <Text
                  color={'turquoiseFont'}
                  fontSize={12}
                >
                  No card debits during trial
                </Text>
              </Styled.Info>
            </Styled.PaymentManagerInfo>
          </RenderIf>
        </Styled.PaymentManagerWrapper>
      </Styled.ContentContainer>
    </>
  );
};

function mapStateToProps(state: State): StateProps {
  const payment = state.account.payment;

  return {
    paymentMethods: payment && payment.paymentSources,
    primaryCardId: payment && payment.primaryCardId,
    backupCardId: payment && payment.backupCardId,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    loadPaymentData: () => dispatch(PaymentMethodActions.getPaymentData()),
    loadPaymentMethods: force => dispatch(PaymentMethodActions.getPaymentMethods(force)),
    setPrimaryCard: id => dispatch(PaymentMethodActions.setPrimaryCard(id)),
    setBackupCard: id => dispatch(PaymentMethodActions.setBackupCard(id)),
    removeCard: id => dispatch(PaymentMethodActions.removeCard(id)),
    createCardLegacy: form => dispatch(PaymentMethodActions.createCardLegacy(form)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export const PaymentManagerBlock = connector(PaymentManagerBlockComponent);
