import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './stepper.scss';

import { KreoButton } from 'common/UIKit';
import { FaqLink } from 'common/UIKit/faq-link';
import { Stepper as StepperActions } from '../../actions';
import { State as ReduxState } from '../../common/interfaces/state';
import { TAllGood, TAttentionAlert, TWarning } from '../../icons';
import * as Status from './status';
import { StepProps } from './step';

interface ReduxProps {
  active: number;
}

interface ReduxActions {
  setCurrentStep: (step: number) => void;
}

interface Props extends ReduxProps, ReduxActions {
  children: Array<React.ReactElement<StepProps>>;
  nextButtonText?: string;
  prevButtonText?: string;
  disableCheckMark: boolean;
  onNext?: (active: number) => void;
  afterLastNext?: () => void;
  allowStepItemClick: boolean;
}

interface State {
  canBack: boolean;
  canNext: boolean;
}

class StepperComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      canBack: props.active !== 0,
      canNext: props.active <= props.children.length,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    const canBack = props.active > 0;
    if (state.canBack !== canBack) {
      return { ...state, canBack };
    }
    return null;
  }

  public render(): React.ReactNode {
    const steps = this.props.children;
    const nextButtonText = this.props.nextButtonText
      ? this.props.nextButtonText
      : 'Continue';
    const prevButtonText = this.props.prevButtonText
      ? this.props.prevButtonText
      : 'Previous Step';
    const activeStep = steps[this.props.active];

    return (
      steps && (
        <div className='stepper'>
          <div className='stepper__top'>
            <div className='stepper__progress'>
              {steps.map((x, i) => {
                const status = x.props.stepStatus || Status.OK;
                const currentIndex = this.props.active;
                const hasIcon =
                  i < currentIndex && !this.props.disableCheckMark;

                return (
                  <div
                    key={i}
                    className={classNames('stepper__item', {
                      'stepper__item--active': currentIndex === i,
                    })}
                    onClick={this.onClickStepItem.bind(this, x, i)} // eslint-disable-line react/jsx-no-bind
                  >
                    <div
                      className={classNames('stepper__item-icon', {
                        icon: hasIcon,
                      })}
                    >
                      {hasIcon ? <img src={this.getIcon(status)} /> : i + 1}
                    </div>
                    <b className='stepper__item-title'>{x.props.title}</b>
                  </div>
                );
              })}
            </div>
          </div>
          <FaqLink caption={activeStep.props.faqCaption} />
          <div className='stepper__btn-wrap'>
            {this.state.canBack && (
              <KreoButton
                onClick={this.back}
                size='medium'
                caption={prevButtonText}
                mode='ghost'
              />
            )}
            <KreoButton
              disabled={activeStep.props.blocked}
              mode='submit'
              size='medium'
              onClick={this.next}
            >
              {nextButtonText}
            </KreoButton>
          </div>
          <div className='stepper__content'>{activeStep}</div>
        </div>
      )
    );
  }

  private onClickStepItem(
    step: React.ReactElement<StepProps>,
    i: number,
  ): void {
    if (this.props.allowStepItemClick) {
      this.setCurrentStep(i);
      this.callStepAction(step);
    }
  }

  @autobind
  private back(): void {
    if (this.state.canBack) {
      const { children } = this.props;
      const index = this.props.active - 1;
      this.setCurrentStep(index);
      this.callStepAction(children[index]);
    }
  }

  @autobind
  private next(): void {
    if (this.props.onNext) {
      this.props.onNext(this.props.active);
    }

    if (this.state.canNext) {
      const { active, children } = this.props;

      const activeStep = this.props.children[active];
      if (activeStep.props.stepAction) {
        activeStep.props.stepAction();
      }

      const index =
        active < this.props.children.length - 1 ? active + 1 : active;
      this.setCurrentStep(index);
      this.callStepAction(children[index]);

      if (index === active && this.props.afterLastNext) {
        this.props.afterLastNext();
      }
    }
  }

  private getIcon(status: number): string | null {
    switch (status) {
      case Status.OK:
        return TAllGood;
      case Status.WARNING:
        return TAttentionAlert;
      case Status.ERROR:
        return TWarning;
      default:
        return null;
    }
  }

  @autobind
  private callStepAction(current: React.ReactElement<StepProps>): void {
    if (typeof current.props.action === 'function') {
      current.props.action();
    }
  }

  @autobind
  private setCurrentStep(index: number): void {
    this.props.setCurrentStep(index);
    this.setState({
      canNext: index <= this.props.children.length - 1,
      canBack: index > 0,
    });
  }
}

function mapStateToProps(state: ReduxState): ReduxProps {
  return {
    active: state.stepper.current,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): ReduxActions {
  return {
    setCurrentStep: (step: number) => dispatch(StepperActions.setStep(step)),
  };
}

export const Stepper = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StepperComponent);
