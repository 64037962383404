import {
  CustomElementFilterSimpleViewModel,
  CustomElementFilterViewModel,
} from 'common/interfaces/custom-element-filter-builder';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { CustomFiltersTypes } from '../actions/types/custom-filters';
import { QuantityTakeOffState } from '../interfaces/quantity-take-off';

export const customFiltersReducerMethods: ReducerMethods<QuantityTakeOffState> = {
  [CustomFiltersTypes.SET_CUSTOM_FILTERS_INFO]: (s, customFilters: CustomElementFilterSimpleViewModel[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.customFilters, customFilters)
      .get();
  },
  [CustomFiltersTypes.SET_CUSTOM_FILTER_EDIT_MODEL]: (s, filterModel: CustomElementFilterViewModel) => {
    return new MonoliteHelper(s)
      .set(_ => _.customFilterEditModel, filterModel)
      .get();
  },
  [CustomFiltersTypes.SET_APPLIED_CUSTOM_FILTER]: (s, filterModel: CustomElementFilterViewModel) => {
    return new MonoliteHelper(s)
      .set(_ => _.appliedCustomFilter, filterModel)
      .get();
  },
};
