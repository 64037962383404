import { createSlice } from '@reduxjs/toolkit';

import { ActionWith } from 'common/interfaces/action-with';
import { ContextMenuState } from './interfaces';

export const INITIAL_STATE: ContextMenuState = {
  contextMenuPositionX: 0,
  contextMenuPositionY: 0,
  menuItems: [],
  menuWidth: undefined,
  sizeItem: undefined,
};

const ContextMenu = createSlice({
  name: '@contextMenu',
  initialState: INITIAL_STATE,
  reducers: {
    set: (state, { payload }: ActionWith<ContextMenuState>) => {
      state = payload;
      return state;
    },
    drop: (state) => {
      state = INITIAL_STATE;
      return state;
    },
  },
});

export const ContextMenuActions = ContextMenu.actions;
export const contextMenuReducers = ContextMenu.reducer;
