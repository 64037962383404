import autobind from 'autobind-decorator';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  id: number;
  ambientText: string;
  searchText: string;
  caseSensitive: boolean;
  drawingId: string;
  focus: (id: number, drawingId: string) => void;
}

export class TextSearchResultItem extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { ambientText } = this.props;
    const [start, end] = this.getSearchTextBounds();
    return (
      <Styled.Container onClick={this.onClick}>
        <Styled.Text>
          {ambientText.slice(0, start)}
          <Styled.Highlighted>
            {ambientText.slice(start, end)}
          </Styled.Highlighted>
          {ambientText.slice(end)}
        </Styled.Text>
      </Styled.Container>
    );
  }

  @autobind
  private onClick(): void {
    this.props.focus(this.props.id, this.props.drawingId);
  }

  private getSearchTextBounds(): [number, number] {
    const { ambientText, searchText, caseSensitive } = this.props;
    const index = caseSensitive
      ? ambientText.indexOf(searchText)
      : ambientText.toLowerCase().indexOf(searchText.toLowerCase());
    return [index, index + searchText.length];
  }
}
