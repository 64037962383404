import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './work-packages.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { StepActions } from '../../actions';
import { ActivityGroupData } from '../../interfaces';
import { EntityCompareHelper } from '../../utils';
import { WorkPackageItem } from './work-package-item';

interface ReduxProps {
  workPackages: Record<number, ActivityGroupData.WorkPackage>;
}

interface ReduxActions {
  selectWorkPackage(workPackageId: number): void;
}

interface Props extends ReduxProps, ReduxActions {
  scenarioId: number;
}

class WorkPackagesComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const workPackages = Object.keys(this.props.workPackages)
      .map((workPackageId) => this.props.workPackages[workPackageId])
      .sort(EntityCompareHelper.compareNamedEntity);

    return (
      <div className="work-packages-area">
        <div className="work-packages-area__container">
          <div className="work-packages-area__content">
            <KreoScrollbars>{this.getWorkPackageItemComponent(workPackages)}</KreoScrollbars>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private getWorkPackageItemComponent(workPackages: ActivityGroupData.WorkPackage[]): JSX.Element[] {
    return workPackages
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((workPackage) => (
        <WorkPackageItem
          key={workPackage.id}
          id={workPackage.id}
          name={workPackage.name}
          ungroupedActivitiesCount={workPackage.ungroupedIds.length}
          onClick={this.props.selectWorkPackage}
        />
      ));
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    workPackages: state.activityGrouping.workPackages,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    selectWorkPackage: (workPackageId: number): void => {
      dispatch(StepActions.setActiveWorkPackage(workPackageId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const WorkPackages = connector(WorkPackagesComponent);
