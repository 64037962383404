import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import './kreo-scrollbars.scss';
import { ConstantFunctions } from 'common/constants/functions';

export interface KreoScrollbarsApi {
  getScrollTop: () => number;
  setScrollTop: (top: number) => void;
  setScrollLeft: (left: number) => void;
  getScrollLeft: () => number;
  getScrollHeight: () => number;
}
interface ScrollbarsWrapper {
  view: HTMLDivElement;
  trackHorizontal: HTMLDivElement;
  trackVertical: HTMLDivElement;
  getScrollTop: () => number;
  scrollTop: (top: number) => void;
  scrollLeft: (left: number) => void;
  getScrollLeft: () => number;
}

interface Props {
  showShadowTop?: boolean;
  smoothScroll?: boolean;
  themeWhite?: boolean;
  relative?: boolean;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  onSendRef?: (ref: HTMLDivElement) => void;
  sendApi?: (api: KreoScrollbarsApi) => void;
}
interface State {
  shadowTop: boolean;
}
export class KreoScrollbars extends React.Component<Props, State> {
  private container: ScrollbarsWrapper;

  constructor(props: Props) {
    super(props);
    this.state = { shadowTop: false };

    if (props.sendApi) {
      props.sendApi({
        setScrollTop: this.setScrollTop,
        getScrollTop: this.getScrollTop,
        getScrollLeft: this.getScrollLeft,
        setScrollLeft: this.setScrollLeft,
        getScrollHeight: this.getScrollHeight,
      });
    }
  }

  public render(): JSX.Element {
    const scrollBoxClasses = classNames('scroll-box', { 'scroll-box--shadow-top': this.state.shadowTop });
    return (
      <Scrollbars
        onScroll={this.props.showShadowTop ? this.onScroll : this.props.onScroll}
        className={scrollBoxClasses}
        renderView={this.renderView}
        renderThumbVertical={this.renderThumbVertical}
        renderTrackVertical={this.renderTrackVertical}
        renderThumbHorizontal={this.renderThumbHorizontal}
        renderTrackHorizontal={this.renderTrackHorizontal}
        ref={this.onSendRef}
        thumbMinSize={30}
        universal={true}
        hideTracksWhenNotNeeded={true}
      >
        {this.props.children}
      </Scrollbars>
    );
  }

  @autobind
  public getScrollTop(): number {
    if (this.container) {
      return this.container.getScrollTop();
    }
    return 0;
  }

  @autobind
  public setScrollTop(top: number): void {
    if (this.container) {
      if (this.props.smoothScroll) {
        this.container.view.scrollTo({ top, behavior: 'smooth' });
      } else {
        this.container.scrollTop(top);
      }
    }
  }

  @autobind
  public getScrollLeft(): number {
    if (this.container) {
      return this.container.getScrollLeft();
    }
    return 0;
  }

  public setScrollLeft(left: number): void {
    if (this.container) {
      if (this.props.smoothScroll) {
        this.container.view.scrollTo({ left, behavior: 'smooth' });
      } else {
        this.container.scrollTop(left);
      }
    }
  }

  @autobind
  public getScrollHeight(): number {
    if (this.container) {
      return this.container.view.getBoundingClientRect().height;
    }
    return 0;
  }

  @autobind
  private onSendRef(ref: ScrollbarsWrapper): void {
    if (this.props.onSendRef && ref !== null) {
      this.props.onSendRef(ref.view);
    }
    this.container = ref;
    if (this.container) {
      this.stopEvents(this.container.trackHorizontal);
      this.stopEvents(this.container.trackVertical);
    }
  }

  @autobind
  private onScroll(e: React.UIEvent<HTMLDivElement>): void {
    if (this.props.onScroll) {
      this.props.onScroll(e);
    }
    if (this.container && this.props.showShadowTop) {
      this.setState({ shadowTop: this.container.view.scrollTop > 0 });
    }
  }

  @autobind
  private renderView({ style }: { style: React.CSSProperties }): JSX.Element {
    const boxContentClasses = classNames(
      'scroll-box__content',
      { 'scroll-box__content--relative': this.props.relative },
    );
    return (
      <div
        className={boxContentClasses}
        style={{ ...style }}
      />
    );
  }
  private renderTrackHorizontal(): JSX.Element {
    return (
      <div className='scroll-box__track-horizontal' />
    );
  }
  private renderTrackVertical(): JSX.Element {
    return (
      <div className='scroll-box__track-vertical' />
    );
  }
  @autobind
  private renderThumbHorizontal(): JSX.Element {
    const boxThumbClasses = classNames(
      'scroll-box__thumb-horizontal',
      { 'scroll-box__thumb-horizontal--light': this.props.themeWhite },
    );
    return (
      <div className={boxThumbClasses} />
    );
  }
  @autobind
  private renderThumbVertical(): JSX.Element {
    const boxThumbClasses = classNames(
      'scroll-box__thumb-vertical',
      { 'scroll-box__thumb-vertical--light': this.props.themeWhite },
    );
    return (
      <div className={boxThumbClasses} />
    );
  }

  private stopEvents(track: HTMLDivElement): void {
    track.addEventListener('click', (e) => {
      ConstantFunctions.stopEvent(e);
    });
  }
}
