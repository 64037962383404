
import * as React from 'react';
import { ConnectDropTarget, DropTarget, DropTargetCollector } from 'react-dnd';

import { DrawingsGeometryGroupWithNesting } from '../../interfaces/drawings-geometry-instance';
import { DrawingsDragAndDropType, DrawingsDragAndDropUtils } from '../../utils/drawings-annotation-drag-utils';
import { Styled } from './styled';


interface OwnProps {
  group: DrawingsGeometryGroupWithNesting;
  bottomLine?: boolean;
  moveToGroup(groupId: string, groupIds: string[], measurementIds: string[], orderIndex: number): void;
}

interface DnDProps {
  connectDropTarget: (element: JSX.Element) => JSX.Element;
  hovering: boolean;
}

interface Props extends OwnProps, DnDProps {
}

class DrawingsAnnotationLegendLine extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { connectDropTarget, group, bottomLine, hovering } = this.props;

    return connectDropTarget(
      <div style={{ marginLeft: '20px' }}>
        <Styled.OrderLineContainer
          bottomLine={bottomLine}
          nestingCount={group.nestingCount}
        >
          <Styled.OrderLine
            hovering={hovering}
            nestingCount={group.nestingCount}
          />
        </Styled.OrderLineContainer>
      </div>,
    );
  }
}

const specDrop = {
  drop: (props: Props, monitor) => {
    const item = monitor.getItem();
    const newIndex = props.bottomLine ? props.group.orderIndex + 1 : props.group.orderIndex;
    const groupIds = item.groups.map(group => group.id);
    props.moveToGroup(props.group.parentId, groupIds, item.measurements, newIndex);

    return item;
  },
  canDrop: (props, monitor): boolean => {
    const item = monitor.getItem();

    if (item.measurements.length && !item.groups.length) {
      return false;
    }
    return !DrawingsDragAndDropUtils.isLoop(item.groups, props.group.id);
  },
};

interface CollectedProps {
  connectDropTarget: ConnectDropTarget;
  hovering: boolean;
}

const collectDrop: DropTargetCollector<CollectedProps, any> = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    hovering: monitor.isOver() && monitor.canDrop(),
  };
};

const dropConnector = DropTarget(DrawingsDragAndDropType.DrawingGroup, specDrop, collectDrop);

export const DrawingsAnnotationLegendGroupLine = dropConnector(DrawingsAnnotationLegendLine);
