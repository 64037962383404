import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { State } from 'common/interfaces/state';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { DrawingsUserAnnotationActions } from '../actions/creators/user-annotation';
import {
  DrawingsDeleteAnnotations,
  DrawingsDeleteAnnotationsRequest,
} from '../actions/payloads/user-annotation';
import { DrawingsUserAnnotationActionTypes } from '../actions/types/user-annotation';
import { DrawingsApi } from '../api/drawings-api';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import { DrawingsShortInfo } from '../interfaces/drawings-short-drawing-info';
import { userAnnotationUpdateSaga } from './user-annotation-update-saga';


function pdfIdSelector(state: State, drawingId: string): string {
  return state.drawings.drawingsInfo[drawingId].pdfId;
}


function* getAnnotations({ payload }: ActionWith<string>): SagaIterator {
  try {
    const pdfId = yield selectWrapper(x => pdfIdSelector(x, payload));
    const data = yield call(DrawingsApi.getAnnotations, pdfId, payload);
    yield put(DrawingsUserAnnotationActions.getAnnotationSuccess({
      ...data,
      currentDrawingId: payload,
    }));
  } catch (error) {
    console.error('get annotations error', error, payload);
  }
}

function* deleteAnnotation(
  {
    payload: { drawingId, annotationsIds },
  }: ActionWith<DrawingsDeleteAnnotationsRequest>,
): SagaIterator {
  try {
    const annotationsState = yield selectWrapper(x => x.drawings.userAnnotations);
    const deletePayload: DrawingsDeleteAnnotations = {
      deletedRulers: [],
      deletedSvgs: [],
      deletedStickers: [],
    };
    for (const entityId of annotationsIds) {
      if (entityId in annotationsState.rulers) {
        deletePayload.deletedRulers.push(entityId);
      } else if (entityId in annotationsState.stickers) {
        deletePayload.deletedStickers.push(entityId);
      } else if (entityId in annotationsState.images) {
        deletePayload.deletedSvgs.push(entityId);
      }
    }
    yield put(DrawingsUserAnnotationActions.removeAnnotationsFromState(deletePayload, drawingId));
  } catch (error) {
    console.error('error on delete annotations', error);
  }
}


function* updateLegend(action: ActionWith<ShortPointDescription>): SagaIterator {
  try {
    const { pdfId, drawingId }: DrawingsShortInfo = yield selectWrapper(state => state.drawings.currentDrawingInfo);
    yield call(DrawingsApi.updateLegend, pdfId, drawingId, action.payload);
  } catch (error) {
    console.error('update legend failed', error, action);
  }
}

// todo: https://kreosoftware.atlassian.net/browse/KREOP-13390
function* removeLegend(action: ActionWith<ShortPointDescription>): SagaIterator {
  try {
    const { pdfId, drawingId }: DrawingsShortInfo = yield selectWrapper(state => state.drawings.currentDrawingInfo);
    yield call(DrawingsApi.removeLegend, pdfId, drawingId);
  } catch (error) {
    console.error('update legend failed', error, action);
  }
}


export function* drawingsUserAnnotationSaga(): SagaIterator {
  yield fork(userAnnotationUpdateSaga);
  yield takeLatest(DrawingsUserAnnotationActionTypes.GET_ANNOTATIONS, getAnnotations);
  yield takeLatest(DrawingsUserAnnotationActionTypes.REMOVE_INSTANCES, deleteAnnotation);
  yield takeLatest(DrawingsUserAnnotationActionTypes.UPDATE_LEGEND_POSITION, updateLegend);
  yield takeLatest(DrawingsUserAnnotationActionTypes.REMOVE_LEGEND, removeLegend);
}
