import * as t from 'io-ts';

export const ActivityAssignmentTreeNodeResponseC = <T extends t.Mixed>(
  tValidator: T,
  tName: string = 'ActivityAssignmentTreeNodeResponse',
): t.Any =>
    t.strict(
      {
        name: t.string,
        children: t.array(tValidator),
      },
      tName);

export const ActivityAssignmentElementResponseC =
  ActivityAssignmentTreeNodeResponseC(t.number, 'ActivityAssignmentElementResponse');
export const ActivityAssignmentFamilyResponseC =
  ActivityAssignmentTreeNodeResponseC(ActivityAssignmentElementResponseC, 'ActivityAssignmentFamilyResponse');
export const ActivityAssignmentCategoryResponseC =
  ActivityAssignmentTreeNodeResponseC(ActivityAssignmentFamilyResponseC, 'ActivityAssignmentCategoryResponse');

export interface ActivityAssignmentTreeNodeResponse<T> {
  name: string;
  children: T[];
}

export type ActivityAssignmentElementResponse = t.TypeOf<typeof ActivityAssignmentElementResponseC>;
export type ActivityAssignmentFamilyResponse = t.TypeOf<typeof ActivityAssignmentFamilyResponseC>;
export type ActivityAssignmentCategoryResponse = t.TypeOf<typeof ActivityAssignmentCategoryResponseC>;
