import { arrayUtils } from 'common/utils/array-utils';
import { DrawingsGeometryGroupWithNesting, DrawingsInstanceMeasure } from '../../interfaces';
import { AnnotationLegendItem } from '../../interfaces/annotation-legend-tree';
import { AnnotationLegendItemTypeguards } from '../../utils/annotation-legend-item-typeguards';
import { DrawingsMeasureUtils } from '../../utils/drawings-measure-utils';
import { MeasureValue } from '../drawings-annotation-legend-item';
import { MeasureType, MeasureTypeTemplate, MeasuresUtils } from './measures-utils';

export function calculateGroupsMeasures(
  filteredElements: AnnotationLegendItem[],
  savedMeasures: Record<string, MeasureValue[]>,
  getInstanceMeasure: (instanceId: string) => DrawingsInstanceMeasure,
): Record<string, MeasureValue[]> {
  const groupsToProcess = filteredElements.filter((x) =>
    AnnotationLegendItemTypeguards.isGroup(x) && !x.parentId,
  ) as DrawingsGeometryGroupWithNesting[];

  const areMeasuresEqual = (a: Record<string, MeasureValue>, b: Record<string, MeasureValue>): boolean => {
    if (!b || !a) {
      return false;
    }
    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }
    for (const key of Object.keys(a)) {
      if (!b[key]) {
        return false;
      }
      if (a[key].value !== b[key].value || a[key].itemsCount !== b[key].itemsCount) {
        return false;
      }
    }
    return true;
  };

  const groupParents: Record<string, string> = {};

  const processedGroups = {};


  const result: Record<string, MeasureValue[]> = {};

  while (groupsToProcess.length) {
    const group = groupsToProcess.pop();
    if (processedGroups[group.id]) {
      continue;
    }
    const groupMeasures: Record<string, MeasureValue> = {};
    const updateGroupMeasuresValue = (key: string, value: number): void => {
      if (!groupMeasures[key]) {
        groupMeasures[key] = {
          ...MeasureTypeTemplate[key],
          title: key,
          value,
          itemsCount: 1,
        };
      } else {
        groupMeasures[key].value += value;
        groupMeasures[key].itemsCount += 1;
      }
    };
    groupParents[group.id] = group.parentId;
    processedGroups[group.id] = true;
    for (const inner of group.allInnerInstances) {
      if (AnnotationLegendItemTypeguards.isGroup(inner)) {
        groupsToProcess.push(inner);
        continue;
      }
      const measurements = getInstanceMeasure(inner.id);
      if (!measurements) {
        continue;
      }
      const measureValues = MeasuresUtils.getMeasureValues(measurements.measures);
      if (DrawingsMeasureUtils.isLengthMeasure(measurements.type, measurements.measures)) {
        updateGroupMeasuresValue(MeasureType.Length, measureValues[MeasureType.Length].value);
      } else if (DrawingsMeasureUtils.isCountMeasure(measurements.type, measurements.measures)) {
        updateGroupMeasuresValue(MeasureType.Count, measureValues[MeasureType.Count].value);
      } else {
        updateGroupMeasuresValue(MeasureType.Area, measureValues[MeasureType.Area].value);
      }
    }
    if (savedMeasures[group.id]) {
      const savedMeasuresDictionary = arrayUtils.toDictionaryByKey(savedMeasures[group.id], (x) => x.title);
      if (areMeasuresEqual(savedMeasuresDictionary, groupMeasures)) {
        result[group.id] = savedMeasures[group.id];
        continue;
      }
    }
    result[group.id] = Object.values(groupMeasures);
  }
  return result;
}
