export const Day = 1;
export const DaysPerWeek = Day * 7;
export const DaysPerHour = Day / 24;
export const DaysPerMinute = DaysPerHour / 60;

export const utils = {
  getSpeedValue: (normalizedValue: number): number => {
    return 2.480158729 * (10 ** -4) *
      (Math.E ** (10.24792796 * normalizedValue)) + 0.00044642857 * (1 - normalizedValue);
  },
  formatTimeValue: (valueInDays: number, upperLimit: number = Infinity): string => {
    if (valueInDays >= DaysPerWeek && DaysPerWeek <= upperLimit) {
      return `${Math.round(valueInDays / DaysPerWeek)} w`;
    }

    if (valueInDays >= Day && Day <= upperLimit) {
      return `${Math.ceil(valueInDays / Day)} d`;
    }

    if (valueInDays >= DaysPerHour && DaysPerHour <= upperLimit) {
      return `${Math.ceil(valueInDays / DaysPerHour)} h`;
    }

    return `${Math.round(valueInDays / DaysPerMinute)} min`;
  },
  daysToHoursAndMinutes: (days: number): { hours: number, minutes: number } => {
    const hours = days * 24;
    const completeHours = Math.floor(hours);
    const leftoverMinutes = Math.round((hours - completeHours) * 60);

    return {
      hours: completeHours,
      minutes: leftoverMinutes,
    };
  },
};
