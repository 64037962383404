import styled from 'styled-components';

const InputContainer = styled.label`
  display: flex;
  position: relative;
  margin-bottom: 20px;
  &, * {
    box-sizing: border-box;
  }

  &:not(:focus-within):after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
  }

  &:hover:not(:focus-within):after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
  }
  
  > div {
    display: flex;
    position: absolute;
    left: 20px;
    top: 20px;
    svg {
      transition: 0.2s ease all;
      height: 20px;
      width: 20px;
    }
  }
`;

const InputStyle = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: ${p => p.theme.color.mainFont};
  background-color: ${p => p.theme.color.background};
  border-radius: 10px;
  outline: none;
  border: none;
  position: relative;
  display: flex;

  &::placeholder {
    opacity: 0;
    color: ${p => p.theme.color.disabled};
  }

  &:focus {
    background-color: ${p => p.theme.color.backgroundRush};
    border: 1px solid ${p => p.theme.color.background};
    padding-right: 40px;

    &::placeholder {
      opacity: 1;
    }

    & ~ span {
      &:first-of-type {
        top: 12px;
        font-size: 12px;
        color: ${p => p.theme.color.gray};
      }

      &:last-of-type svg {
        fill: ${p => p.theme.color.gray};
      }
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputText = styled.span`
  position: absolute;
  transition: 0.2s ease all;
`;

export const Styled = {
  InputContainer,
  InputStyle,
  InputText,
};
