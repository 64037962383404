export const EMPTY_VALUE = 'undefined';

export const REPORT_FILTER_KEY = 'reportFilters';
export const ENGINE_FILTER_KEY = 'engineFiltersKey';
export const CUSTOM_FILTER = 'customFilter';

export const FilterKeys = {
  F_NRM1: 'f_nrm1',
  F_NRM1_0_LEVEL: 'f_nrm1_0_level',
  F_NRM1_1_LEVEL: 'f_nrm1_1_level',
  F_NRM1_2_LEVEL: 'f_nrm1_2_level',
  F_NRM1_3_LEVEL: 'f_nrm1_3_level',
  F_NRM2: 'f_nrm2',
  F_NRM2_0_LEVEL: 'f_nrm2_0_level',
  F_UNI_SYS: 'f_uni_sys',
  F_UNI_PROD: 'f_uni_prod',
  F_MAS_FORM: 'f_mas_form',
  F_MAS_FORM_0_LEVEL: 'f_mas_form_0_level',
  F_UNI_FORM: 'f_uni_form',
  F_UNI_FORM_0_LEVEL: 'f_uni_form_0_level',
  F_UNI_FORM_1_LEVEL: 'f_uni_form_1_level',
  F_SMM7_CLF: 'f_smm7_clf',
  F_SMM7_CLF_0_level: 'f_smm7_clf_0_level',
};

