import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './macro-sequence.scss';

import { NumberDictionary, StringDictionary } from 'common/interfaces/dictionary';
import { State as ReduxState } from 'common/interfaces/state';
import {
  KreoIconGroupingBig,
  KreoIconSelectToolCollapsed,
  KreoIconSequenceBig,
} from 'common/UIKit/icons';
import { Graph } from '../../../../components/graph-viewer';
import { Direction, GraphItemDisplayType, LimitationType } from '../../../../components/graph-viewer/enums';
import { GraphProcessor } from '../../../../components/graph-viewer/graph-processor';
import { EdgeMap, GraphData, GraphEdge, GraphForNode } from '../../../../components/graph-viewer/interfaces';
import { VertexDisplayTypeMapBuilder } from '../../../../components/graph-viewer/vertex-display-type-map-builder';
import { EngineActions, MacroSequenceActions, StepActions } from '../../actions';
import {
  ActivityGroup,
  ActivityGroupingStepPage,
  EdgeCreator,
  LimitationMap,
  MacroSequenceData,
  SequenceColor,
  UngroupedActivity,
  WorkPackage,
} from '../../interfaces';
import { BimElementIdsHelper } from '../../utils';
import { MacroSequenceFilter } from '../macro-sequence-filter';
import { SequenceEdgeCreator } from '../sequence-edge-creator';
import { EdgeControls } from './edge-controls';
import { Vertex } from './vertex';

interface ReduxActions {
  setColoredBimElements: (colorToBimIds: StringDictionary<number[]>) => void;
  setAvailableBimElements: (bimIds: number[]) => void;
  setDefaultAvailableBimElements: (bimIds: number[]) => void;
  setSelectedBimElements: (elements: number[]) => void;
  resetEngine: () => void;
  setActivityGroupingStepPage: (page: ActivityGroupingStepPage) => void;
  resetEdgeCreator: () => void;
  addEdge: (edge: GraphEdge) => void;
  setActiveActivityGroupOrUngroupedActivityById: (id: number) => void;
  setFilteredWorkPackageIds: (ids: Record<number, undefined> | null) => void;
  setFilteredActivityGroupIds: (ids: Record<number, undefined> | null) => void;
}

interface ReduxProps {
  graph: MacroSequenceData;
  highlightedItemId: number | null;
  edgeCreatorData: EdgeCreator;
  activeActivityGroupId: number | null;
  activityGroups: Record<number, ActivityGroup>;
  ungroupedActivities: Record<number, UngroupedActivity>;
  workPackages: Record<number, WorkPackage>;
  filteredWorkPackageIds: Record<number, undefined> | null;
  filteredActivityGroupIds: Record<number, undefined> | null;
  ungroupedActivityGroupIdToId: Record<number, number>;
}

interface Props extends ReduxProps, ReduxActions {
  scenarioId: number;
}

interface State {
  // graphByLevels: GraphMapData;
  graphData: GraphData;
  graphForNode: GraphForNode;
  vertexDisplayTypes: NumberDictionary<GraphItemDisplayType>;
  defaultEngineAvailableBimElementIds: number[] | null;
}

class MacroSequenceComponent extends React.PureComponent<Props, State> {
  private levels: 1 | 2 = 2;
  constructor(props: Props) {
    super(props);
    this.state = this.getUpdatedState(null, props);
  }

  public componentDidMount(): void {
    this.props.resetEngine();
    const defaultBimElementIds = this.getDefaultBimElements();
    this.props.setAvailableBimElements(defaultBimElementIds);
    this.props.setDefaultAvailableBimElements(defaultBimElementIds);
    this.updateBimElements(this.state);
  }

  public componentDidUpdate(previousProps: Props): void {
    const props = this.props;
    const newState = this.getUpdatedState(previousProps, props);
    this.setState(newState);
    if (
      !isEqual(props.filteredWorkPackageIds, previousProps.filteredWorkPackageIds) ||
      !isEqual(props.filteredActivityGroupIds, previousProps.filteredActivityGroupIds)
    ) {
      const defaultBimElementIds = this.getDefaultBimElements();
      props.setAvailableBimElements(defaultBimElementIds);
      props.setDefaultAvailableBimElements(defaultBimElementIds);
      this.updateBimElements(newState);
    } else if (!isEqual(newState.graphForNode, this.state.graphForNode)) {
      this.updateBimElements(newState);
    }

    if (!isEqual(props.edgeCreatorData, previousProps.edgeCreatorData)) {
      const filteredGroupIds = this.getFilteredGroupIds(newState, props.edgeCreatorData);
      props.setFilteredActivityGroupIds(filteredGroupIds);
    }
  }

  public render(): React.ReactFragment {
    const filterWorkPackages = Object.keys(this.props.workPackages)
      .map(id => ({ id: +id, name: this.props.workPackages[id].name }));
    const selectedWorkPackageIds = this.props.filteredWorkPackageIds
      ? Object.keys(this.props.filteredWorkPackageIds).map(id => +id)
      : null;

    return (
      <div className='macro-sequence'>
        <div className='macro-sequence__header'>
          <div className='grouping-page-toggle' tabIndex={0}>
            <div className='grouping-page-toggle__item'>
              <div className='grouping-page-toggle__icon'><KreoIconSequenceBig /></div>
              Sequence
              <div className='grouping-page-toggle__indicator'><KreoIconSelectToolCollapsed /></div>
            </div>
            <div onClick={this.setActivityGroupingStepPage} className='grouping-page-toggle__item'>
              <div className='grouping-page-toggle__icon'><KreoIconGroupingBig /></div>
              Grouping
            </div>
          </div>
          <MacroSequenceFilter
            workPackages={filterWorkPackages}
            selectedIds={selectedWorkPackageIds}
            onChange={this.props.setFilteredWorkPackageIds}
          />
          {/* TODO: fullScreen function
          <div className='macro-sequence-btn-round'>
            {true ? <KreoIconFullscreenNormal /> : <KreoIconFullscreenOn />}
          </div> */}
        </div>
        <div
          className={
            classNames('macro-sequence__container', { 'macro-sequence__container--full': !this.props.edgeCreatorData })
          }
        >
          <Graph
            // graphByLevels={this.state.graphByLevels}
            graphForNode={this.state.graphForNode}
            vertexDisplayTypes={this.state.vertexDisplayTypes}
            levels={this.levels}
            highlightedItemId={this.props.highlightedItemId}
            vertexComponent={Vertex}
            edgeControlsComponent={EdgeControls}
            vertexWidth={180}
            vertexMargin={25}
            readonly={!!this.props.edgeCreatorData}
          />
          {
            this.props.edgeCreatorData ? (
              <SequenceEdgeCreator
                rootVertexName={this.getGroupName(this.props.edgeCreatorData.rootId)}
                destinationVertexName={this.getGroupName(this.props.edgeCreatorData.destinationIds[0])}
                edgeDirection={this.props.edgeCreatorData.direction}
                onApprove={this.onEdgeCreation}
                onCancel={this.cancelSequenceEdgeCreator}
                getLoopVertexNames={this.getLoopVertexNames}
              />
            ) : null
          }
        </div>
      </div>
    );
  }

  private getUpdatedState(previousProps: Props, currentProps: Props): State {
    const newState = { ...this.state };
    const isActivityGroupIdChanged = (): boolean =>
      !previousProps || previousProps.activeActivityGroupId !== currentProps.activeActivityGroupId;
    const isWorkPackageFilterChanged = (): boolean => !previousProps ||
      !isEqual(previousProps.filteredWorkPackageIds, currentProps.filteredWorkPackageIds);
    const isGraphChanged = (): boolean =>
      !previousProps ||
      !previousProps.graph && !!currentProps.graph ||
      previousProps.graph && !currentProps.graph ||
      !isEqual(previousProps.graph.limitations, currentProps.graph.limitations);


    const edgeCreatorChanged = !previousProps ||
      !isEqual(currentProps.edgeCreatorData, previousProps.edgeCreatorData);
    const workPackageFilterChanged = isWorkPackageFilterChanged();
    const needFullRecalculate = workPackageFilterChanged || isGraphChanged();
    const activityGroupIdChanged = isActivityGroupIdChanged();

    if (needFullRecalculate || edgeCreatorChanged || activityGroupIdChanged) {
      newState.graphData = needFullRecalculate
        ? this.getFilteredGraphData(currentProps)
        : newState.graphData;
      if (!needFullRecalculate && edgeCreatorChanged || workPackageFilterChanged) {
        this.appendTempGraphChanges(newState.graphData, currentProps, previousProps);
      }
      const processor = new GraphProcessor(newState.graphData);
      if (
        Number.isInteger(currentProps.activeActivityGroupId) &&
        this.isActivityGroupInFilteredWorkPackages(currentProps.activeActivityGroupId, currentProps)
      ) {
        newState.graphForNode = processor.getGraphForRootNode(currentProps.activeActivityGroupId, this.levels);
      } else {
        newState.graphForNode = null;
      }

      newState.vertexDisplayTypes = newState.graphForNode
        ? VertexDisplayTypeMapBuilder.getVertexDisplayTypeMap(newState.graphForNode.rootNode)
        : {};
    }

    return newState;
  }

  private getDefaultBimElements(): number[] {
    const workPackageIds = this.props.filteredWorkPackageIds
      ? Object.keys(this.props.filteredWorkPackageIds).map(x => +x)
      : null;
    const activityGroupIds = this.props.filteredActivityGroupIds
      ? Object.keys(this.props.filteredActivityGroupIds).map(x => +x)
      : null;
    return BimElementIdsHelper.getFilteredWorkPackagesAndActivityGroupsBimIds(workPackageIds, activityGroupIds);
  }

  private getFilteredGraphData(props: Props): GraphData {
    let availableGroupsMap = null;
    if (props.filteredWorkPackageIds) {
      availableGroupsMap = {};
      for (const workPackageId in props.filteredWorkPackageIds) {
        for (const activityId of props.workPackages[workPackageId].activityGroupIds) {
          availableGroupsMap[activityId] = true;
        }
        for (const activityId of props.workPackages[workPackageId].ungroupedIds) {
          availableGroupsMap[activityId] = true;
        }
      }
    }

    return {
      edges: this.getFilteredLimitations(props.graph.limitations, availableGroupsMap),
      vertexes: availableGroupsMap
        ? props.graph.vertexIds.filter(x => availableGroupsMap[x])
        : props.graph.vertexIds,
    };
  }

  private isActivityGroupInFilteredWorkPackages(id: number, currentProps: Props): boolean {
    if (!currentProps.filteredWorkPackageIds) {
      return true;
    }
    const group = currentProps.activityGroups[id];
    if (group) {
      return group.workPackageId in currentProps.filteredWorkPackageIds;
    }
    const activityId = currentProps.ungroupedActivityGroupIdToId[id];
    const ungroupedActivity = currentProps.ungroupedActivities[activityId];
    const result = ungroupedActivity.workPackageId in currentProps.filteredWorkPackageIds;
    return result;
  }

  private appendTempGraphChanges(graph: GraphData, currentProps: Props | null, previousProps: Props | null): void {
    if (!currentProps.edgeCreatorData && (!previousProps || !previousProps.edgeCreatorData)) {
      return;
    }
    const { direction, rootId } = currentProps.edgeCreatorData || previousProps.edgeCreatorData;
    if (!this.isActivityGroupInFilteredWorkPackages(rootId, currentProps)) {
      return;
    }
    const currentDestinationIds = currentProps.edgeCreatorData
      ? currentProps.edgeCreatorData.destinationIds
      : [];
    const previousDestinationIds = previousProps && previousProps.edgeCreatorData
      ? previousProps.edgeCreatorData.destinationIds
      : [];

    const removingEdges = previousDestinationIds
      .filter(id => !currentDestinationIds.includes(id));
    for (const id of removingEdges) {
      const edge = this.getNewGraphEdge(rootId, +id, direction);
      this.removeEdgeFromGraph(graph.edges, edge);
    }

    for (const id of currentDestinationIds) {
      const edge = this.getNewGraphEdge(rootId, +id, direction);
      if (this.isActivityGroupInFilteredWorkPackages(id, currentProps)) {
        this.appendEdgeToGraph(graph.edges, edge);
      }
    }
  }

  private removeEdgeFromGraph(edges: EdgeMap, edge: GraphEdge): void {
    const { source, target } = edge;

    if (edges.sourceTarget[source] && edges.sourceTarget[source][target]) {
      if (Object.keys(edges.sourceTarget[source]).length > 1) {
        delete edges.sourceTarget[source][target];
      } else {
        delete edges.sourceTarget[source];
      }

      if (Object.keys(edges.targetSource[target]).length > 1) {
        delete edges.targetSource[target][source];
      } else {
        delete edges.sourceTarget[target];
      }
    }
  }

  private appendEdgeToGraph(edges: EdgeMap, edge: GraphEdge): void {
    const { source, target, type } = edge;
    edges.sourceTarget[source] = edges.sourceTarget[source]
      ? edges.sourceTarget[source]
      : {};
    edges.targetSource[target] = edges.targetSource[target]
      ? edges.targetSource[target]
      : [];
    edges.sourceTarget[source][target] = type;
    edges.targetSource[target][source] = type;
  }

  private getFilteredLimitations(
    limitations: LimitationMap, availableGroupsMap?: Record<number, boolean>,
  ): EdgeMap {
    const isAvailableVertex = (vertexId): boolean => !availableGroupsMap || vertexId in availableGroupsMap;
    const result: EdgeMap = {
      sourceTarget: {},
      targetSource: {},
    };
    for (const source in limitations) {
      if (isAvailableVertex(source)) {
        for (const target in limitations[source]) {
          if (isAvailableVertex(target) && limitations[source][target].isUsed) {
            this.appendEdgeToGraph(result, {
              source: +source,
              target: +target,
              type: limitations[source][target].type,
            });
          }
        }
      }
    }

    return result;
  }

  private updateBimElements(currentState: State): void {
    const highlightedBimElements = this.getHighlightedBimElementIdsAndMap(currentState.vertexDisplayTypes);
    this.props.setColoredBimElements(highlightedBimElements.map);

    if (currentState.graphForNode) {
      const selectedBimElements = BimElementIdsHelper.getActivityGroupBimIds(currentState.graphForNode.rootNode.id);
      this.props.setSelectedBimElements(selectedBimElements);
      // this.props.setAvailableBimElements(highlightedBimElements.ids);
    } else {
      this.props.setSelectedBimElements([]);
    }
  }

  private getNewGraphEdge(fromId: number, toId: number, direction: Direction): GraphEdge {

    return {
      source: direction === Direction.Forward ? fromId : toId,
      target: direction === Direction.Forward ? toId : fromId,
      type: LimitationType.FinishStart,
    };
  }

  @autobind
  private getLoopVertexNames(): string[] {
    const graph = { ...this.state.graphData };
    const { rootId, destinationIds, direction } = this.props.edgeCreatorData;
    const newEdge = this.getNewGraphEdge(rootId, destinationIds[0], direction);
    this.appendEdgeToGraph(graph.edges, newEdge);
    const processor = new GraphProcessor(graph);
    const path = processor.tryFindLoopForNode(rootId);

    if (!path) {
      return [];
    }

    const loopGroupIdToName: Record<number, string> = {};
    for (const id of path) {
      loopGroupIdToName[id] = (this.props.activityGroups[id] ||
        this.props.ungroupedActivities[this.props.ungroupedActivityGroupIdToId[id]]).name;
    }

    return path.map(x => loopGroupIdToName[x]);
  }

  @autobind
  private onEdgeCreation(): void {
    const { rootId, direction, destinationIds } = this.props.edgeCreatorData;
    const newEdge = this.getNewGraphEdge(rootId, destinationIds[0], direction);
    this.props.addEdge(newEdge);
    this.props.resetEdgeCreator();
    this.props.setFilteredActivityGroupIds(null);
  }

  @autobind
  private cancelSequenceEdgeCreator(): void {
    this.props.resetEdgeCreator();
    this.props.setFilteredActivityGroupIds(null);
  }

  @autobind
  private setActivityGroupingStepPage(): void {
    this.props.setActivityGroupingStepPage(ActivityGroupingStepPage.ActivityGrouping);
  }

  private getGroupName(groupId: number): string {
    const group = this.props.activityGroups[groupId];
    if (group) {
      return group.name;
    }

    const ungroupedActivity = this.props.ungroupedActivities[this.props.ungroupedActivityGroupIdToId[groupId]];
    return ungroupedActivity ? ungroupedActivity.name : null;
  }

  private getFilteredGroupIds(state: State, edgeCreator: EdgeCreator): Record<number, undefined> | null {
    if (!edgeCreator || !Number.isInteger(this.props.activeActivityGroupId)) {
      return null;
    }

    const disabledIdsMap = this.getDisabledGroups(state, edgeCreator);

    const result: Record<number, undefined> = [];
    for (const groupId in this.props.activityGroups) {
      if (!(groupId in disabledIdsMap)) {
        result[groupId] = undefined;
      }
    }

    for (const groupId in this.props.ungroupedActivityGroupIdToId) {
      if (!(groupId in disabledIdsMap)) {
        result[groupId] = undefined;
      }
    }

    return result;
  }

  private getDisabledGroups(state: State, edgeCreator: EdgeCreator): Record<number, undefined> {

    const disabledDirection = edgeCreator.direction === Direction.Forward
      ? Direction.Back
      : Direction.Forward;
    const processor = new GraphProcessor(state.graphData);
    const disabledIdsMap = processor.getDirectionNodes(edgeCreator.rootId, disabledDirection);
    const nextLevelNodes = processor.getDirectionNodes(edgeCreator.rootId, edgeCreator.direction, 1);
    for (const id in nextLevelNodes) {
      disabledIdsMap[id] = undefined;
    }
    disabledIdsMap[edgeCreator.rootId] = undefined;

    return disabledIdsMap;
  }

  private getHighlightedBimElementIdsAndMap(
    vertexDisplayTypes: NumberDictionary<GraphItemDisplayType>,
  ): { ids: number[], map: StringDictionary<number[]> } {
    const getActivityGroupsBimIds = (keys: string[], type: GraphItemDisplayType): number[] => {
      const groupIds = keys.filter(key => vertexDisplayTypes[key] === type).map(x => +x);
      return BimElementIdsHelper.getActivityGroupsBimIds(groupIds);
    };

    const highlightedBims = Object.keys(vertexDisplayTypes)
      .filter(key => vertexDisplayTypes[key] !== GraphItemDisplayType.Normal);

    const map = {};
    map[SequenceColor.ActiveVertex] = getActivityGroupsBimIds(highlightedBims, GraphItemDisplayType.Active);
    map[SequenceColor.NextVertex] = getActivityGroupsBimIds(highlightedBims, GraphItemDisplayType.Next);
    map[SequenceColor.PreviousVertex] = getActivityGroupsBimIds(highlightedBims, GraphItemDisplayType.Previous);
    map[SequenceColor.WhiteHighlight] = getActivityGroupsBimIds(highlightedBims, GraphItemDisplayType.Highlighted);

    const ids = map[SequenceColor.ActiveVertex]
      .concat(map[SequenceColor.NextVertex])
      .concat(map[SequenceColor.PreviousVertex])
      .concat(map[SequenceColor.WhiteHighlight]);

    return { ids, map };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    activeActivityGroupId: state.activityGrouping.activeActivityGroupId,
    graph: state.activityGrouping.macroSequence.graph,
    highlightedItemId: state.activityGrouping.macroSequence.highlightedGraphItemId,
    activityGroups: state.activityGrouping.activityGroups,
    ungroupedActivities: state.activityGrouping.ungroupedActivities,
    ungroupedActivityGroupIdToId: state.activityGrouping.ungroupedActivityGroupIdToId,
    edgeCreatorData: state.activityGrouping.macroSequence.edgeCreator,
    workPackages: state.activityGrouping.workPackages,
    filteredWorkPackageIds: state.activityGrouping.macroSequence.filteredWorkPackageIds,
    filteredActivityGroupIds: state.activityGrouping.macroSequence.filteredActivityGroupIds,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setColoredBimElements: colorToBimIds => dispatch(EngineActions.setColoredBimElements(colorToBimIds)),
    setAvailableBimElements: bimIds => dispatch(EngineActions.setAvailableBimElements(bimIds)),
    setDefaultAvailableBimElements: (bimIds) => dispatch(EngineActions.setDefaultAvailableBimElements(bimIds)),
    setActivityGroupingStepPage: page => dispatch(StepActions.setActivityGroupingStepPage(page)),
    setSelectedBimElements: elementIds => dispatch(EngineActions.setSelectedBimElements(elementIds)),
    resetEngine: () => dispatch(EngineActions.resetEngine()),
    resetEdgeCreator: () => dispatch(MacroSequenceActions.resetEdgeCreator()),
    addEdge: edge => dispatch(MacroSequenceActions.addEdge(edge)),
    setActiveActivityGroupOrUngroupedActivityById: id =>
      dispatch(MacroSequenceActions.setActiveActivityGroupOrUngroupedActivityById(id)),
    setFilteredWorkPackageIds: (ids) => dispatch(MacroSequenceActions.setFilteredWorkPackageIds(ids)),
    setFilteredActivityGroupIds: (ids) => dispatch(MacroSequenceActions.setFilteredActivityGroupIds(ids)),
  };
};


const connector = connect(mapStateToProps, mapDispatchToProps);
export const MacroSequence = connector(MacroSequenceComponent);
