import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { DatabaseAssignActivityRouteParams } from '../../../../routes/app-routes-params';
import {
  ActivityAssignmentDatabaseActivityListing,
} from '../../components/activity-assignment-database-activity-listing';


interface Props extends RouteComponentProps<DatabaseAssignActivityRouteParams> {}

export class ActivityAssignmentDatabaseActivityListingPage extends React.Component<Props> {
  public render(): JSX.Element {
    const databaseId = +this.props.match.params.dbId;
    const elementId = +this.props.match.params.endElementId;

    return (
      <ActivityAssignmentDatabaseActivityListing
        databaseId={databaseId}
        workId={elementId}
      />
    );
  }
}
