import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasConstants } from '../constants/drawing-canvas-constants';
import { DrawingsPaperColorInfo } from '../interfaces/drawings-canvas-context-props';

export class DrawingsColorCacheHelper {
  private cachedColors: Record<string, DrawingsPaperColorInfo> = {};

  @autobind
  public getPaperColor(color: string): DrawingsPaperColorInfo {
    if (!this.cachedColors[color]) {
      const stroke = new paper.Color(color);
      const fill = stroke.clone();
      fill.alpha = stroke.alpha * DrawingsCanvasConstants.backgroundOpacity;
      const thickness = stroke.clone();
      thickness.alpha = stroke.alpha * DrawingsCanvasConstants.thicknessOpacity;
      this.cachedColors[color] = { stroke, fill, thickness };
    }
    return this.cachedColors[color];
  }
}
