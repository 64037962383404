import { Accordion, ElementTooltip, Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { Text } from 'common/components/text';
import { SubscriptionPlanVariant } from '../../../interfaces/subscription-plan-variant';

import { Styled } from './styled';

const DEFAULT_FEATURE_VALUE = 'No';

interface Feature {
  title: string;
  content: JSX.Element;
}
interface FeatureGroup {
  name: string;
  description: string[];
  options: string[];
}

interface Props {
  planVariants: SubscriptionPlanVariant[];
}
export class SubscriptionFeatures extends React.Component<Props> {
  public render(): React.ReactNode {
    const features = this.getFeatures();
    return <Accordion content={features} isBlocksOpen={true} />;
  }

  private getFeatures(): Feature[] {
    const planVariants = this.props.planVariants;
    const featureToVariantToValueMap: Record<string, Record<string, string>> = {};
    const groupMap: Record<string, FeatureGroup> = {};
    for (const variant of planVariants) {
      for (const variantFature of variant.featureGroups) {
        groupMap[variantFature.name || DEFAULT_FEATURE_VALUE] = {
          name: variantFature.name || '',
          ...variantFature.features.reduce((acc, el) => {
            acc.options.push(el.name);
            acc.description.push(el.description || '');
            return acc;
          },                               {
            options: [],
            description: [],
          }),
        };
        variantFature.features.forEach(feature => {
          featureToVariantToValueMap[feature.name] = featureToVariantToValueMap[feature.name] || {};
          featureToVariantToValueMap[feature.name][variant.id] = feature.value;
        });
      }
    }

    const result: Feature[] = [];
    for (const group of Object.values(groupMap)) {
      result.push({
        title: group.name,
        content: this.renderFeature(group, featureToVariantToValueMap),
      });
    }

    return result;
  }

  private renderFeature(feature: FeatureGroup, variantFature: Record<string, Record<string, string>>): JSX.Element {
    return (
      <Styled.ContainerTable>
        {feature.options.map((optionName, i) => {
          const variantFatureTable = Object.values(variantFature[optionName]);
          const prompt = feature.description[i];

          return (
            <Styled.Row key={optionName} planLength={variantFatureTable.length}>
              <Styled.FeatureHead>
                <Text fontWeight={500} color='mainFont'>{optionName}</Text>
                {prompt &&
                  <ElementTooltip text={prompt} position='top' wordBreakAll={true}>
                    <Styled.QuestionIcon>
                      <Icons.QuestionS />
                    </Styled.QuestionIcon>
                  </ElementTooltip>
                }
              </Styled.FeatureHead>
              {
                variantFatureTable.map((value, index) => (
                  <Styled.FeatureValue key={index}>
                    <Text color='mainFont'>{value}</Text>
                  </Styled.FeatureValue>
                ))
              }
            </Styled.Row>
          );
        })}
      </Styled.ContainerTable>
    );
  }
}
