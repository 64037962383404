import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Action, Dispatch } from 'redux';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import {
  CostEstimateHeader,
  CostEstimateTable,
} from '../../../../components/cost-estimate-table';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { ProjectLayout } from '../../../project-dashbord';
import { CostsActions } from '../../actions/creators/costs';
import { CostEstimateGroupingType } from '../../enums/cost-estimate-grouping-type';
import { Project } from '../../interfaces/project';

interface CostsPageStateProps {
  data: any;
  calculationId: number;
  project: Project;
  status: any;
}

interface CostsPageDispatchProps {
  getData: (id: any, type?: any) => void;
  dropCosts: () => void;
}

interface CostsPageProps
  extends CostsPageStateProps,
    RouteComponentProps<PlanProjectRouteParams>,
    CostsPageDispatchProps {}

interface CostsPageState {
  groupingType: CostEstimateGroupingType;
  fixedHeader: boolean;
}

class CostsPageComponent extends React.Component<CostsPageProps, Readonly<CostsPageState>> {
  private scrollMasterContainer: HTMLDivElement;
  private tableContainer: HTMLDivElement;

  constructor(props: CostsPageProps) {
    super(props);
    this.state = {
      groupingType: CostEstimateGroupingType.Workpackages,
      fixedHeader: false,
    };
  }

  public componentDidUpdate(prevProps: CostsPageProps): void {
    if (this.props.calculationId && prevProps.calculationId !== this.props.calculationId) {
      this.loadData();
    }
  }

  public componentWillUnmount(): void {
    this.props.dropCosts();
  }

  public UNSAFE_componentWillMount(): void {
    this.loadData();
  }

  public render(): JSX.Element {
    const project = this.props.project ? this.props.project : null;
    return (
      <ProjectLayout
        waitingForAdditionalInfo={!this.props.calculationId}
        projectId={this.props.match.params && this.props.match.params.projectId}
      >
        <KreoScrollbars onSendRef={this.saveScrollRef} onScroll={this.onScroll}>
          <div className='cost-estimate-page'>
            <CostEstimateHeader
              projectStatus={!!project && project.status}
              calculation={this.props.calculationId}
              changeGroupingType={this.changeGroupingType}
              groupingType={this.state.groupingType}
              loadData={this.loadData}
            />
            {this.getContent(project)}
            {!this.props.data.length ? <SvgSpinner size='large' /> : null}
          </div>
        </KreoScrollbars>
      </ProjectLayout>
    );
  }

  @autobind
  private saveScrollRef(ref: HTMLDivElement): void {
    this.scrollMasterContainer = ref;
  }

  @autobind
  private saveContainerRef(ref: HTMLDivElement): void {
    this.tableContainer = ref;
  }

  @autobind
  private onScroll(): void {
    const shouldHaveFixedHeader = this.shouldHaveFixedHeader();
    if (this.state.fixedHeader !== shouldHaveFixedHeader) {
      this.setState({ fixedHeader: shouldHaveFixedHeader });
    }
  }

  @autobind
  private shouldHaveFixedHeader(): boolean {
    if (this.scrollMasterContainer && this.tableContainer) {
      return this.scrollMasterContainer.scrollTop >= this.tableContainer.offsetTop;
    }
  }

  @autobind
  private changeGroupingType(newType: CostEstimateGroupingType): void {
    this.setState({ groupingType: newType });
  }

  @autobind
  private loadData(type?: CostEstimateGroupingType): void {
    if (this.props.calculationId) {
      this.props.getData(this.props.calculationId, type);
    }
  }

  @autobind
  private getContent(project: Project): React.ReactNode {
    // [permissions-todo]
    // const permissions = this.props.permissions;
    // const inviteperm = (permissions.customPermissions) ?
    //   permissions.customPermissions.find((x) =>
    //     x.permissionType === PermissionTypes.MANAGE_SC && x.accessLevel !== AccessLevel.NONE) : undefined;
    if (this.props.data.length > 0) {
      if (this.props.status === RequestStatus.Loaded) {
        return (
          <div
            className='cost-estimate-page__table'
            ref={this.saveContainerRef}
          >
            <CostEstimateTable
              fixedHeader={this.state.fixedHeader}
              includeDuration={false}
              data={this.props.data}
              projectName={project.name}
              edited={false}
              groupingType={this.state.groupingType}
            />
          </div>
        );
      } else {
        return <SvgSpinner size='large' />;
      }
    }

    return <div />;
  }
}

const mapStateToProps = (state: State): CostsPageStateProps => {
  return {
    data: state.cost.get('costs').toJS(),
    calculationId: state.scenarios.active_calculation
      ? state.scenarios.active_calculation.id
      : null,
    project: state.projects.currentProject,
    status: state.cost.get('statuses').get('costEstimate'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): CostsPageDispatchProps => {
  return {
    getData: (id, type) => {
      dispatch(CostsActions.getCostEstimateRoots(id, type));
    },
    dropCosts: () => {
      dispatch(CostsActions.dropCostData());
    },
  };
};

export const CostsPage = connect(mapStateToProps, mapDispatchToProps)(CostsPageComponent);
