import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModesWithNewDrawingPanel } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { SwitcherMode } from 'common/components/round-switcher/interfaces';
import { ControlNames } from 'common/constants/control-names';
import { State } from 'common/interfaces/state';
import { ViewType } from 'unit-2d-info-panel/constants';
import { TwoDInfoPanelActions } from 'unit-2d-info-panel/store-slice';

export function useCurrentView(): [ViewType, (view: ViewType) => void, SwitcherMode[]] {
  const dispatch = useDispatch();

  const setView = useCallback((value: ViewType) => {
    dispatch(TwoDInfoPanelActions.setView(value));
  }, [dispatch]);

  const view = useSelector<State, ViewType>((state) => state.twoDInfoPanel.view);

  const { drawMode } = useDrawModeApi();

  const views = useMemo(() => {
    return [
      {
        text: ModesWithNewDrawingPanel.includes(drawMode) ? 'Tool' : 'Page',
        mode: ViewType.Page,
      },
      {
        text: 'Settings',
        mode: ViewType.Settings,
        controlName: ControlNames.settings,
      },
    ];
  }, [drawMode]);

  return [view, setView, views];
}
