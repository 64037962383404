import { ColorPicker, Icons, LinkComponent, TinyText, MovableContextMenu } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { AccordionElement } from 'common/components/accordion-element';
import { ColorList } from 'common/constants/color-list';
import { State } from 'common/interfaces/state';
import { AnnotationFilters, FilterData, SetType } from '../../interfaces/drawing-filters';
import { CheckboxFilter } from './checkbox-filter';
import { EntitiesSelectFilter } from './entities-select-filter';
import { FilterTypeguards } from './filter-typeguards';
import { FilterGroup, FilterSet } from './interfaces';
import { getProjectFilterList } from './project-filter-list';
import { RadioFilter } from './radio-filter';
import { SelectFilter } from './select-filter';
import { SetContainer } from './set-container';
import { Styled } from './styled';
import { UsersSelectFilter } from './users-select-filter';

interface OwnProps {
  x: number;
  y: number;
  onClose: () => void;
  onColorChange: (color: string) => void;
  filterData: FilterData;
  isResetPossible: boolean;
  resetFilters: () => void;
  geometryCount: number;
  handleFilterChange: (filterType: AnnotationFilters, element: string | Record<string, boolean>) => void;
}

interface StateProps {
  userColors: string[];
}

interface Props extends OwnProps, StateProps {
}

class DrawingsAnnotationLegendFiltersMenuComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { x, y, onClose, resetFilters } = this.props;
    const projectFilterList = getProjectFilterList(
      this.renderFilterSet,
      this.renderColorFilter,
      this.renderEntitiesFilter,
      this.renderUsersSelectFilter,
    );

    return (
      <MovableContextMenu
        x={x}
        y={y}
        onClose={onClose}
      >
        <Styled.Container>
          <Styled.AccordionContainer>
            {
              projectFilterList.map((value) => (
                <AccordionElement
                  isOpen={false}
                  key={value.title}
                  title={value.title}
                >
                  {value.content}
                </AccordionElement>
              ))
            }
          </Styled.AccordionContainer>
          <Styled.ResetButtonContainer>
            <Styled.Count>
              <Styled.Numeral>
                <TinyText color='white'>{this.props.geometryCount}</TinyText>
              </Styled.Numeral>
            </Styled.Count>
            <Styled.ResetButton>
              <LinkComponent
                text={'Reset Filter'}
                fontSize={14}
                onClick={resetFilters}
                Icon={Icons.Reset}
                rightIcon={true}
                mood={!this.props.isResetPossible && 'disabled'}
              />
            </Styled.ResetButton>
          </Styled.ResetButtonContainer>
        </Styled.Container>
      </MovableContextMenu>
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.userColors !== this.props.userColors) {
      this.setState({ colors: ColorList.concat(this.props.userColors) });
    }
  }

  @autobind
  private renderUsersSelectFilter(): React.ReactNode {
    return <UsersSelectFilter />;
  }

  @autobind
  private renderEntitiesFilter(): React.ReactNode {
    return <EntitiesSelectFilter />;
  }

  @autobind
  private renderColorFilter(): React.ReactNode {
    return (
      <Styled.ColorSet>
        <ColorPicker
          colorList={this.props.userColors}
          selectedColor={this.props.filterData[AnnotationFilters.Color]}
          onColor={this.props.onColorChange}
        />
      </Styled.ColorSet>
    );
  }

  @autobind
  private renderFilterSet(filter: FilterSet | FilterGroup): React.ReactNode {
    if (FilterTypeguards.isGroup(filter)) {
      return (
        <SetContainer>
          {filter.sets.map(this.renderFilter)}
        </SetContainer>
      );
    } else {
      return (
        <SetContainer>
          {this.renderFilter(filter)}
        </SetContainer>
      );
    }
  }

  @autobind
  private renderFilter({ selectType, filterType, elements }: FilterSet): React.ReactNode {
    if (selectType === SetType.List) {
      return (
        <CheckboxFilter
          filterType={filterType}
          value={this.props.filterData[filterType] as Record<string, boolean>}
          options={elements}
          onChangeFilter={this.props.handleFilterChange}
        />
      );
    } else if (selectType === SetType.RadioButton) {
      return (
        <RadioFilter
          filterType={filterType}
          value={this.props.filterData[filterType] as string}
          options={elements}
          onChangeFilter={this.props.handleFilterChange}
        />
      );
    } else if (selectType === SetType.Select) {
      return (
        <SelectFilter
          selected={this.props.filterData[filterType] as string}
          elements={elements}
          filterType={filterType}
          changeFilterData={this.props.handleFilterChange}
        />
      );
    }
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    userColors: state.drawings.usedColors,
  };
}

export const DrawingsAnnotationLegendFiltersMenu =
  connect(mapStateToProps)(DrawingsAnnotationLegendFiltersMenuComponent);
