import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { SvgSpinner } from 'common/components/svg-spinner';
import { AppUrls } from '../../../routes/app-urls';
import { ScenariosData } from '../interfaces';

interface Props {
  scenario: ScenariosData.Scenario;
  projectId: string;
  wait?: boolean;
}

export class Master extends React.Component<Props> {
  public render(): React.ReactNode {
    const { scenario, projectId, wait } = this.props;

    if (!scenario || wait) {
      return <SvgSpinner size='large' />;
    }


    const url = AppUrls.plan.project.scenario.listing.url({ projectId: projectId.toString() });
    if (!scenario.isOwner || scenario.isAnyUserActivated) {
      return <Redirect to={url} />;
    }

    return this.props.children;
  }
}
