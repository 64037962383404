export const ProjectNotificationKeys = {
  Created: 'ProjectCreated',
  Failed: 'ProjectFailed',
  ReportsGenerated: 'ProjectReportsGenerated',
  ReportsFailed: 'ProjectReportsFailed',
  BidPricingStarted: 'ProjectBidPricingStarted',
  BidPricingCanceled: 'ProjectBidPricingCanceled',
  BidPricingInviteSubcontractor: 'ProjectBidPricingInviteSubcontractor',
  BidPricingInvitationAccepted: 'ProjectBidPricingInvitationAccepted',
  BidPricingRemovedSubcontractor: 'ProjectBidPricingRemovedSubcontractor',
  BidPricingMakeBid: 'ProjectBidPricingMakeBid',
  BidPricingInviteMainContractor: 'ProjectBidPricingInviteMainContractor',
};
