import autobind from 'autobind-decorator';
import React from 'react';
import { Link } from 'react-router-dom';

import './page.scss';

import { RoutingContextProps, withRoutingContext } from 'common/routing/with-routing-context';
import { KreoButton } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { NotFoundImg } from '../../icons';

class NotFoundPageComponent extends React.Component<RoutingContextProps> {
  public render(): JSX.Element {
    const backUrl = this.props.urls
      ? this.props.urls.listing.url()
      : AppUrls.products.url();

    return (
      <div className='not-found-page'>
        <div className='not-found-page__logo'>
          <img src='/static/logo-blue-with-word.svg' width='96' height='32' alt='KREO' />
        </div>
        <div className='not-found-page__content'>
          <div className='not-found-page__desc'>
            <b>404.</b> That's an error.
          </div>
          <div className='not-found-page__title'>Oops, you've found a dead link</div>
          <img className='not-found-page__img' src={NotFoundImg} alt='404' />
          <Link to={backUrl}>
            <KreoButton size='medium' mode='submit'>Return To The Projects</KreoButton>
          </Link>
          <div className='not-found-page__back'>
            Go to the
            <div className='not-found-page__link' onClick={this.back}>
              previous page
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private back(): void {
    window.history.back();
  }
}

export const NotFoundPage = withRoutingContext(NotFoundPageComponent);
