import { useCallback } from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { useUndoRedoForToggle } from 'common/components/drawings/hooks/settings/use-undo-redo-for-toggle';
import { useActionDispatch } from 'common/hooks';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { usePersistedStorageValue } from 'persisted-storage/hooks';
import { MetricNames, useAnalytics } from 'utils/posthog';

export interface AiSuggestSettingApi {
  toggleAutocomplete: () => void;
  isAutoCompleteEnabled: boolean;
}

export function useAiSuggestSetting(): AiSuggestSettingApi {
  const isActive = usePersistedStorageValue('drawingAutocomplete');
  const canToggleAutocomplete = useAbility(Operation.Manage, Subject.Takeoff2dAiSuggest);
  const canToggleAutocompleteForLines = useAbility(Operation.Manage, Subject.Takeoff2dMeasurementAutocomplete);
  const toggleAutocompleteInState = useActionDispatch(PersistedStorageActions.toggleAutocomplete);
  const withUndoRedo = useUndoRedoForToggle(toggleAutocompleteInState);
  const { sendToggleEvent } = useAnalytics();
  const toggleAutocomplete = useCallback(() => {
    if ((canToggleAutocompleteForLines && canToggleAutocomplete) || isActive) {
      withUndoRedo();
      sendToggleEvent(MetricNames.measurements.aiSuggest, !isActive);
    }
  }, [
    canToggleAutocomplete,
    canToggleAutocompleteForLines,
    withUndoRedo,
    sendToggleEvent,
    isActive,
  ]);
  return {
    toggleAutocomplete,
    isAutoCompleteEnabled: canToggleAutocompleteForLines && canToggleAutocomplete && isActive,
  };
}
