import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './project-header.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { ImperialToggle } from 'common/components/imperial-toggle';
import { isProjectStatusLessThan, ProjectStatus } from 'common/enums/project-status';
import { State } from 'common/interfaces/state';
import { KreoButton, KreoDialogActions } from 'common/UIKit';
import { BidPricingDialogName } from '../../../components/dialog/bid-pricing-dialog';
import { DialogTypes } from '../../../constants';
import { AppUrls } from '../../../routes/app-urls';
import { BidPricingActions } from '../../../units/projects/actions/creators/bid-pricing';
import { Project } from '../../../units/projects/interfaces/project';
import { BidPricingDialog } from '../../dialog';

interface StateProps {
  project: Project;
  isCurrentProjectInOnBidPricingStatus: boolean;
  isCurrentScenarioPublic: boolean;
  location: string;
}

interface DispatchProps {
  startBidPricing: () => void;
  changeBidPricingData: () => void;
  openBidPricingDialog: (type: number) => void;
}

interface Props extends StateProps, DispatchProps, AbilityAwareProps {}

class ProjectHeaderComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { project, location, ability } = this.props;
    const isActiveBidPricing = project && project.status === ProjectStatus.OnBidPricing;
    const bidPricingCaption = isActiveBidPricing ? 'Active Bid Pricing' : 'Start Bid Pricing';
    const isImperialToggleVisible = project
      && (
        location.startsWith(AppUrls.plan.project.quantityTakeOff.url({ projectId: project.id.toString() }))
        || location.startsWith(AppUrls.qto3d.project.quantityTakeOff.url({ projectId: project.id.toString() }))
      );
    const isStartBidPricingButtonVisible = project
      && location.startsWith(AppUrls.plan.project.bidPricing.index.url({ projectId: project.id.toString() }))
      && ability.can(Operation.Create, Subject.BidPricing);

    return (
      <React.Fragment>
        {isImperialToggleVisible && <ImperialToggle />}
        {isStartBidPricingButtonVisible && (
          <KreoButton
            notFilled={!isActiveBidPricing}
            mode='success'
            size='medium'
            rounded={true}
            disabled={!isActiveBidPricing && !this.props.isCurrentScenarioPublic}
            className='bid-pricing-start-button'
            onClick={this.onClickBidPricing}
            controlName='bid-pricing-button'
          >
            {bidPricingCaption}
          </KreoButton>
        )}
        <BidPricingDialog onSubmit={this.onSubmitBidPricing} />
      </React.Fragment>
    );
  }

  @autobind
  private onSubmitBidPricing(): void {
    if (!this.props.isCurrentProjectInOnBidPricingStatus) {
      this.props.startBidPricing();
    } else {
      this.props.changeBidPricingData();
    }
  }

  @autobind
  private onClickBidPricing(): void {
    const status = this.props.project.status;
    if (isProjectStatusLessThan(status, ProjectStatus.OnBidPricing)) {
      this.props.openBidPricingDialog(DialogTypes.CREATE);
    } else {
      this.props.openBidPricingDialog(DialogTypes.EDIT);
    }
  }
}

const mapStateToProps = (state: State): StateProps => {
  const currentProject = state.projects.currentProject;
  return {
    project: currentProject,
    location: state.router.location.pathname.toString(),
    isCurrentProjectInOnBidPricingStatus: currentProject ? currentProject.status === ProjectStatus.OnBidPricing : false,
    isCurrentScenarioPublic:
      state.scenarios.active_scenario && state.scenarios.active_scenario.isPublic,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openBidPricingDialog: (type: number) => {
      dispatch(BidPricingActions.showBidPricingDialog(type));
      dispatch(KreoDialogActions.openDialog(BidPricingDialogName));
    },
    startBidPricing: () => {
      dispatch(BidPricingActions.bidPricingStartRequest());
      dispatch(KreoDialogActions.closeDialog(BidPricingDialogName));
    },
    changeBidPricingData: () => {
      dispatch(BidPricingActions.edit());
      dispatch(KreoDialogActions.closeDialog(BidPricingDialogName));
    },
  };
};

export const ProjectHeader = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(ProjectHeaderComponent),
);
