import * as React from 'react';

export const IconScenario: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M3.5,2 L12.5,2 C13.3284271,2 14,2.67157288 14,3.5 L14,11.5 C14,12.3284271 13.3284271,
          13 12.5,13 L3.5,13 C2.67157288,13 2,12.3284271 2,11.5 L2,3.5 C2,2.67157288 2.67157288,2 3.5,
          2 Z M3.5,3 C3.22385763,3 3,3.22385763 3,3.5 L3,11.5 C3,11.7761424 3.22385763,12 3.5,12 L12.5,
          12 C12.7761424,12 13,11.7761424 13,11.5 L13,3.5 C13,3.22385763 12.7761424,3 12.5,3 L3.5,
          3 Z M10.5,5 C10.7761424,5 11,5.22385763 11,5.5 C11,5.77614237 10.7761424,6 10.5,6 L4.5,
          6 C4.22385763,6 4,5.77614237 4,5.5 C4,5.22385763 4.22385763,5 4.5,5 L10.5,5 Z M11.5,
          7 C11.7761424,7 12,7.22385763 12,7.5 C12,7.77614237 11.7761424,8 11.5,8 L4.5,8 C4.22385763,
          8 4,7.77614237 4,7.5 C4,7.22385763 4.22385763,7 4.5,7 L11.5,7 Z M9.5,9 C9.77614237,9 10,
          9.22385763 10,9.5 C10,9.77614237 9.77614237,10 9.5,10 L4.5,10 C4.22385763,10 4,9.77614237 4,
          9.5 C4,9.22385763 4.22385763,9 4.5,9 L9.5,9 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
