import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { DrawingsRendererApiContextProvider } from 'common/components/drawings/drawings-contexts';
import { RoleCode } from 'common/enums/role-code';
import { State } from 'common/interfaces/state';
import { UndoRedoController } from 'common/undo-redo';
import { CommentsProvider } from 'unit-2d-comments/comments-context';
import { AccountSelectors } from '../../../units/account/selectors';
import { ReportRoutes } from '../routes/report';
import { Styled } from './styled';

interface StateProps {
  projectsToDelete: Record<string, boolean>;
  isRoleGuest: boolean;
}

interface Props extends RouteComponentProps<{ projectId: string }>, AbilityAwareProps, StateProps {}

class ReportRoutComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return (
      <CommentsProvider>
          <Styled.UndoRedoControllerWrapper>
            <DrawingsRendererApiContextProvider>
              <UndoRedoController>
                <ReportRoutes {...this.props as any} />
              </UndoRedoController>
            </DrawingsRendererApiContextProvider>
          </Styled.UndoRedoControllerWrapper>
      </CommentsProvider>
    );
  }
}


function mapStateToProps(state: State): StateProps {
  const isRoleGuest = AccountSelectors.currentSubscription(state).userRoleCode === RoleCode.Guest;
  return {
    projectsToDelete: state.projects.projectsToDelete,
    isRoleGuest,
  };
}

const ConnectedToRedux = connect(mapStateToProps)(ReportRoutComponent);

export const ReportRoute = withAbilityContext(ConnectedToRedux);
