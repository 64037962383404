import autobind from 'autobind-decorator';
import * as React from 'react';

import { EnhancedVirtualList } from 'common/UIKit';
import { GroupedUserActivity } from '../../interfaces';
import { UserActivityItem } from '../user-activity-item';

interface Props {
  projectId?: number;
  items: UserActivityRenderItem[];
}

interface State {
  expandedActivities: number[];
}

interface UserActivityRenderItem {
  header?: React.ReactNode;
  userActivity?: GroupedUserActivity;
}

export class VirtualUserActivities extends React.Component<Props, State> {
  public state: State = {
    expandedActivities: [],
  };

  public render(): JSX.Element {
    return (
      <EnhancedVirtualList
        objects={this.props.items}
        showShadowTop={true}
        itemHeight={100}
        renderedItemsCount={16}
        renderItem={this.renderActivity}
        customItemHeight={this.getItemSize}
      />
    );
  }

  @autobind
  private getItemSize(item: UserActivityRenderItem): number {
    const userActivityHeight = 100;
    const headerHeight = 40;

    if (item.header) {
      return headerHeight;
    }

    return (
      userActivityHeight +
      (item.userActivity.groupedActivities && this.isExpanded(item.userActivity.id)
        ? userActivityHeight * item.userActivity.groupedActivities.length
        : 0)
    );
  }

  @autobind
  private renderActivity(item: UserActivityRenderItem): React.ReactNode {
    const { projectId } = this.props;

    if (item.header) {
      return item.header;
    }

    const groupedActivities = item.userActivity.groupedActivities;
    const isExpanded = this.isExpanded(item.userActivity.id);

    return (
      <React.Fragment>
        <UserActivityItem
          activity={item.userActivity}
          showProjectInfo={!projectId}
          collapse={this.collapse}
          expand={this.expand}
          expanded={isExpanded}
        />
        {isExpanded &&
          groupedActivities &&
          groupedActivities.map(x => (
            <UserActivityItem
              activity={x}
              key={x.id}
              showProjectInfo={!projectId}
              collapse={this.collapse}
              expand={this.expand}
              expanded={false}
            />
          ))}
      </React.Fragment>
    );
  }

  @autobind
  private isExpanded(activityId: number): boolean {
    return this.state.expandedActivities.includes(activityId);
  }

  @autobind
  private expand(activityId: number): void {
    if (!this.isExpanded(activityId)) {
      this.setState({ expandedActivities: [...this.state.expandedActivities, activityId] });
    }
  }

  @autobind
  private collapse(activityId: number): void {
    if (this.isExpanded(activityId)) {
      this.setState({
        expandedActivities: this.state.expandedActivities.filter(x => x !== activityId),
      });
    }
  }
}
