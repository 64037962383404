import * as React from 'react';

export const KreoIconDiameter = (): JSX.Element => {
  return (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
  >
    <g
      id='Icons/24x24/Tools/Diameter'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <path
        className='icon__color-light'
        // eslint-disable-next-line max-len
        d='M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 Z'
        id='color2'
        fill='currentColor'
        fillRule='nonzero'
      />
      <path
        // eslint-disable-next-line max-len
        d='M18,4 C19.1045695,4 20,4.8954305 20,6 C20,7.1045695 19.1045695,8 18,8 C17.6290439,8 17.281676,7.89900711 16.9838916,7.72301675 L7.72301675,16.9838916 C7.89900711,17.281676 8,17.6290439 8,18 C8,19.1045695 7.1045695,20 6,20 C4.8954305,20 4,19.1045695 4,18 C4,16.8954305 4.8954305,16 6,16 C6.3709561,16 6.71832404,16.1009929 7.0161084,16.2769833 L16.2769833,7.0161084 C16.1009929,6.71832404 16,6.3709561 16,6 C16,4.8954305 16.8954305,4 18,4 Z'
        id='color1'
        fill='currentColor'
        fillRule='nonzero'
      />
    </g>
  </svg>);
};
