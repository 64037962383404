import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  show: boolean;
  withBackground?: boolean;
  fullScreen?: boolean;
}

export const Spinner: React.FC<Props> = (props: Props) => {
  if (props.show) {
    return (
      <Styled.SpinnerContainer fullScreen={props.fullScreen} withBackground={props.withBackground}>
        <Styled.Spinner withBackground={props.withBackground}>
          <Icons.Loader />
        </Styled.Spinner>
      </Styled.SpinnerContainer>
    );
  }
  return null;
};
