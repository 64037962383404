import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { State } from 'common/interfaces/state';
import { PlanProjectRouteParams } from '../../../../../routes/app-routes-params';
import { ProjectsApi } from '../../../api/common';

interface OwnProps {
  workPackageName: string;
  workPackageInnerId: number | null;
}

interface StateProps {
  activeScenarioId: number | null;
  companyId: number | null;
}

interface Props extends OwnProps, StateProps, RouteComponentProps<PlanProjectRouteParams> {}

class WorkPackagesComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { activeScenarioId, workPackageName, workPackageInnerId } = this.props;
    const projectId = this.props.match.params.projectId;
    const screenshotUrl =
      workPackageInnerId != null
        ? ProjectsApi.buildProjectWorkPackageScreenshotUrl(projectId, activeScenarioId, workPackageInnerId)
        : null;

    return (
      <div className='bid-pricing-invitations__work-packages'>
        <span>{workPackageName}</span>
        <div className='bid-pricing-invitations__image'>
          {!!screenshotUrl && <img src={screenshotUrl} width='210' height='140' />}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: State): StateProps {
  const project = state.projects.currentProject;
  return {
    companyId: project ? project.companyId : null,
    activeScenarioId: project ? project.activeScenarioId : null,
  };
}

export const WorkPackages = withRouter(connect(mapStateToProps)(WorkPackagesComponent));
