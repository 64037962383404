import { H5 } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { BillingPeriodUnit } from '../../../enums/billing-period-unit';
import { CurrencySelector } from '../../currency-selector';
import { ChangeBillingRadioMenu } from '../../subscription-details-new-user/change-billing-radio-menu';
import { Styled } from './styled';


interface Props {
  billingPeriod: BillingPeriodUnit;
  isFreePlan: boolean;
  setBillingPeriod: (billingPeriod: BillingPeriodUnit) => void;
}

export class SubscriptionListingHead extends React.Component<Props> {
  public render(): React.ReactNode {
    const { billingPeriod, isFreePlan } = this.props;

    return (
      <Styled.Container isFreePlan={isFreePlan}>
        <H5>Pricing</H5>
        <Styled.Period>
          <CurrencySelector />
          <ChangeBillingRadioMenu
            onSelectionChanged={this.changePeriod}
            billingPeriod={billingPeriod}
          />
        </Styled.Period>
      </Styled.Container>
    );
  }

  @autobind
  private changePeriod(): void {
    const billingPeriod =
      this.props.billingPeriod === BillingPeriodUnit.Month
        ? BillingPeriodUnit.Year
        : BillingPeriodUnit.Month;

    this.props.setBillingPeriod(billingPeriod);
  }
}
