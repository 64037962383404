import { Expression } from 'expr-eval';
import { BaseFormat, Property } from 'unit-2d-database/interfaces';

export enum Status {
  Ok,
  Error,
}

export interface Value {
  status: Status;
  format: BaseFormat;
  value: string | number;
}

export type PropertyValueGetter =
  (propertyName: string, formulaPath: Property[], withPercentageConversion?: boolean) => Value;

export interface PropertyCalculator {
  getPropertyValue: PropertyValueGetter;
}

export type MeasuredValueGetter = (measuredValueName: string) => number | string | null;

export interface CachedProperty {
  isRef: boolean;
  value: Value | PropertyCalculator;
}


export interface PrecompiledFormula {
  params: Record<string, string>;
  measuredParams: string[];
  expression: Expression;
  onlyConstants: boolean;
  constants: Record<string, number | string>;
  value?: string | number;
}


export interface PrecompiledFormulaApi {
  getFormula: (formulaValue: string) => PrecompiledFormula | undefined;
  setFormula: (formulaValue: string, formula: PrecompiledFormula) => void;
}
