import {
  ElementTooltip,
  IconButton,
  Icons,
  RectangleButton,
  SearchListItem,
  Text,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { Styled } from './styled';

interface Props<T> {
  itemData: T;
  onApply: (id: number) => void;
  onMenuClick: (e: React.MouseEvent<HTMLDivElement>, item: T) => void;
}

export interface TemplateData {
  id: number;
  name: string;
  isPublic: boolean;
  isDefault: boolean;
}

export class TemplatesMenuItem<T extends TemplateData> extends React.Component<Props<T>> {
  public render(): JSX.Element {
    const nameMaxLength = this.props.itemData.isPublic
      ? this.props.itemData.isDefault ? 16 : 20
      : 22;
    return (
      <SearchListItem>
        <Styled.MenuItem>
          <Icons.Templates />
          <Styled.MenuItemText>
            <ElementTooltip
              position={'top'}
              speed={'m'}
              text={this.props.itemData.name}
              disabled={this.props.itemData.name.length < nameMaxLength}
              wordBreakAll={true}
            >
              <Text
                withEllipsis={true}
                fontSize={14}
              >
                {this.props.itemData.name}
              </Text>
            </ElementTooltip>
            {this.props.itemData.isDefault &&
              <ElementTooltip
                position={'top'}
                text={'Default'}
              >
                <Styled.DefaultIcon>
                  <Icons.Done height={15} width={15} />
                </Styled.DefaultIcon>
              </ElementTooltip>
            }
          </Styled.MenuItemText>
          <Styled.MenuItemActions>
            {this.props.itemData.isPublic &&
              <ElementTooltip
                position={'top'}
                text={'Public'}
              >
                <Styled.ShareIcon>
                  <Icons.Share />
                </Styled.ShareIcon>
              </ElementTooltip>
            }
            <RectangleButton
              text={'Apply'}
              fontSize={12}
              height={20}
              width={60}
              mood={'secondary'}
              onClick={this.apply}
            />
            <IconButton
              Icon={Icons.Menu}
              width={20}
              height={20}
              onClick={this.openMenu}
            />
          </Styled.MenuItemActions>
        </Styled.MenuItem>
      </SearchListItem>
    );
  }

  @autobind
  private apply(): void {
    this.props.onApply(this.props.itemData.id);
  }

  @autobind
  private openMenu(e: React.MouseEvent<any>): void {
    this.props.onMenuClick(e, this.props.itemData);
  }
}
