export function searchEmoji(
  query: string,
  callback: (results: Array<{ id: string }>) => void,
): void {
  fetch('/static/emojis.json')
    .then(x => x.json())
    .then(emojis => {
      const queryLower = query.toLowerCase();
      const matches = [];
      for (const emoji of emojis) {
        if (
          emoji.description.includes(queryLower) ||
          emoji.tags.some(x => x.includes(queryLower)) ||
          emoji.aliases.some(x => x.includes(queryLower))
        ) {
          matches.push({ id: emoji.emoji });
          if (matches.length === 10) {
            break;
          }
        }
      }
      return matches;
    })
    .then(callback);
}
