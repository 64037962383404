import { RowData } from 'common/components/data-base-table/interfaces';
import {
  DrawingsFile,
  DrawingsFiles,
  DrawingsFolderViewInfo,
} from 'common/components/drawings/interfaces/drawings-file-info';
import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { arrayUtils } from 'common/utils/array-utils';
import { getFileRow } from '../get-file-row';
import { getGroupRow } from '../get-group-row/get-group-row';
import { getPath } from '../get-path';

export const getRows = (files: DrawingsFiles): Record<string, RowData> => {
  const rowData = [];
  const result = {};
  const childToParentMap = {};

  if (files && files.entities) {
    const fileList = Object.values(files.entities).sort((a, b) => {
      if (a.type === TreeTableRowType.Element && b.type !== TreeTableRowType.Element) {
        return 1;
      }
      if (b.type === TreeTableRowType.Element && a.type !== TreeTableRowType.Element) {
        return -1;
      }
      return arrayUtils.localCompareWithNumber(a.properties.name, b.properties.name);
    });
    fileList.forEach((f, i) => {
      if (f.parentId) {
        childToParentMap[f.id] = f.parentId;
      }
      if (f.type === TreeTableRowType.Element) {
        const fileData = getFileRow(f as DrawingsFile);
        fileData.rowIndex = i;
        rowData.push(fileData);
      } else {
        const groupData = getGroupRow(f as DrawingsFolderViewInfo);
        groupData.rowIndex = i;
        rowData.push(groupData);
      }
    });
  }

  const pathMap = getPath(childToParentMap);

  rowData.forEach(r => {
    if (pathMap[r.id]) {
      r.h_path = pathMap[r.id];
    }
    result[r.id] = r;
  });

  return result;
};
