import styled from 'styled-components';

const SpinnerContainer = styled.div<{ fullScreen?: boolean, withBackground?: boolean }>`
  position: ${p => p.fullScreen ? 'fixed' : 'absolute'};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: ${p => p.withBackground ? `${p.theme.color.background}85` : 'transparent'};
`;

const Spinner = styled.div<{ withBackground?: boolean }>`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.color[p.withBackground ? 'backgroundRush' : 'background']};
  height: 40px;
  width: 40px;
  svg {
    fill: ${p => p.theme.color.turquoise};
  }
`;

export const Styled = {
  Spinner,
  SpinnerContainer,
};
