import autobind from 'autobind-decorator';
import * as React from 'react';

import { Hotkey, hotkeys } from 'common/hotkeys';
import { GlobalKeyboardEventsControllerContextProvider } from './context';
import { Styled } from './styled';


enum ListenerType {
  keyDownListeners = 'keyDownListeners',
  keyUpListeners = 'keyUpListeners',
}

export class GlobalKeyboardEventsController extends React.PureComponent {
  private ref: React.RefObject<HTMLDivElement> = null;
  private notInitEventListeners: Array<{
    listenerType: ListenerType,
    hotkey:  Hotkey | Hotkey[],
    callback: (e: KeyboardEvent) => void,
  }> = [];

  constructor(props: null) {
    super(props);
    this.ref = React.createRef();
  }

  public render(): React.ReactNode {
    return (
      <Styled.Container ref={this.ref} style={{ width: '100%', height: '100%', outline: 'none' }} tabIndex={0}>
        <GlobalKeyboardEventsControllerContextProvider
          addKeyDownEventListener={this.addKeyDownEventListeners}
          addKeyUpEventListener={this.addKeyUpEventListeners}
          removeKeyDownEventListener={this.removeEventListeners}
          removeKeyUpEventListener={this.removeEventListeners}
        >
          {this.props.children}
        </GlobalKeyboardEventsControllerContextProvider>
      </Styled.Container>
    );
  }

  public componentDidMount(): void {
    if (this.ref.current && this.notInitEventListeners.length) {
      this.notInitEventListeners.forEach(x => this.addListeners(x.listenerType, x.hotkey, x.callback));
      this.notInitEventListeners = [];
    }
  }

  @autobind
  private addKeyUpEventListeners(key: Hotkey | Hotkey[], callback: (e: KeyboardEvent) => void): void {
    this.addListeners(ListenerType.keyUpListeners, key, callback);
  }

  @autobind
  private addKeyDownEventListeners(key: Hotkey | Hotkey[], callback: (e: KeyboardEvent) => void): void {
    this.addListeners(ListenerType.keyDownListeners, key, callback);
  }

  private addListeners(
    listenerType: ListenerType,
    hotkey: Hotkey | Hotkey[],
    callback: (e: KeyboardEvent) => void,
  ): void {
    if (Array.isArray(hotkey)) {
      for (const k of hotkey) {
        this.addListener(listenerType, k, callback);
      }
    } else {
      this.addListener(listenerType, hotkey, callback);
    }
  }

  @autobind
  private addListener(listenerType: ListenerType, hotkey: Hotkey, callback: (e: KeyboardEvent) => void): void {
    const ref = this.ref.current;
    if (ref) {
      const keyup = listenerType === ListenerType.keyUpListeners;
      hotkeys(hotkey.shortcut, { element: ref, keyup, keydown: !keyup }, callback);
    } else {
      this.notInitEventListeners.push({ listenerType, hotkey, callback });
    }
  }

  @autobind
  private removeEventListeners(
    key: Hotkey | Hotkey[],
    callback: (e: KeyboardEvent) => void,
  ): void {
    if (Array.isArray(key)) {
      for (const k of key) {
        this.unbind(k, callback);
      }
    } else {
      this.unbind(key, callback);
    }
  }

  private unbind(hotkey: Hotkey, callback: (e: KeyboardEvent) => void): void {
    hotkeys.unbind({
      key: hotkey.shortcut,
      method: callback,
    });
  }
}
