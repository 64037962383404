import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

function getLastItemStyle(isClickable: boolean, pale: string, gray: string): FlattenSimpleInterpolation {
  if (!isClickable) {
    return css`
      box-sizing: border-box;
      height: 30px;
      padding: 5px 10px 5px 5px;
      border-radius: 10px;
      background: ${pale} 0% 0% no-repeat padding-box;

      p {
        color: ${gray} !important;
      }
    `;
  } else {
    return null;
  }
}

const BreadcrumbsContainer = styled.div`
  display: flex;
`;

const Breadcrumb = styled.div`
  display: inline-flex;
  align-items: center;
  svg {
    fill: ${p => p.theme.color.gray};
  }
`;

const BreadcrumbContent = styled.div<{ isClickable: boolean }>`
  display: flex;
  align-items: center;
  min-width: fit-content;

  ${p => getLastItemStyle(p.isClickable,  p.theme.color.pale, p.theme.color.gray)};

  svg {
    margin-right: 10px;
  }
  p {
    display: contents;
    color: ${p => p.theme.color.gray};
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
  }
`;

const Separator = styled.div`
  display: flex;
  margin: 0 10px;

  svg {
    flex-shrink: 0;
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.l};
  }
`;

export const Styled = {
  BreadcrumbsContainer,
  Breadcrumb,
  BreadcrumbContent,
  Separator,
};
