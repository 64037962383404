import GUI from 'lil-gui';

export function addAmbientLightSettings(
  light: THREE.AmbientLight,
  gui: GUI,
): void {
  const folder = gui.addFolder('Ambient light');
  folder.addColor(light, 'color');
  folder.add(light, 'intensity', 0, 20, 0.1);
}
