import { MovableContextMenu, MovableContextMenuProps, ContextMenuGroup } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { DrawingMarkShapes, DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsStyleMenuType } from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { StylesHeader } from './styles-header';

/* eslint-disable @typescript-eslint/no-shadow */
export enum DrawingsContextMenuBodyHeaderType {
  Default,
  EditMeasurementStyle,
  ColorPicker,
  Hidden,
}
/* eslint-enable */


interface Props extends MovableContextMenuProps {
  selectedColor?: string;
  headerType?: DrawingsContextMenuBodyHeaderType;
  topPanelComponent?: JSX.Element;
  isColorPickerBottom?: boolean;
  colorTitle?: string;
  strokeWidth?: number;
  strokeStyle?: DrawingStrokeStyles;
  styleMenuType?: DrawingsStyleMenuType;
  zIndex?: number;
  shape?: DrawingMarkShapes;
  setColor?: (color: string) => void;
  setStrokeStyle?: (value: DrawingStrokeStyles) => void;
  setStrokeWidth?: (value: number) => void;
  setShape?: (value: DrawingMarkShapes) => void;
}


export class DrawingsContextMenuBody extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isColorPickerBottom, x, y, onClose, zIndex } = this.props;
    return (
      <MovableContextMenu
        x={x}
        y={y}
        onClose={onClose}
        zIndex={zIndex}
      >
        {!isColorPickerBottom && this.renderStylesHeader()}
        {this.props.topPanelComponent}
        {this.renderChildren()}
        {isColorPickerBottom && this.renderStylesHeader()}
      </MovableContextMenu>
    );
  }

  @autobind
  private renderChildren(): JSX.Element {
    if (!this.props.children) {
      return null;
    }
    return (
      <ContextMenuGroup>
        {this.props.children}
      </ContextMenuGroup>
    );
  }

  @autobind
  private renderStylesHeader(): JSX.Element {
    const {
      selectedColor,
      headerType,
      colorTitle,
      strokeStyle,
      strokeWidth,
      styleMenuType,
      shape,
      setColor,
      setStrokeStyle,
      setStrokeWidth,
      setShape,
    } = this.props;
    return (<StylesHeader
      headerType={headerType}
      selectedColor={selectedColor}
      shape={shape}
      colorTitle={colorTitle}
      strokeStyle={strokeStyle}
      styleMenuType={styleMenuType}
      strokeWidth={strokeWidth}
      setColor={setColor}
      setStrokeStyle={setStrokeStyle}
      setStrokeWidth={setStrokeWidth}
      setShape={setShape}
    />);
  }
}
