import { AgGridEvent, ColumnApi, GridApi, RowNode, ColumnState } from 'ag-grid-community';

export interface AgGridState {
  nodes: Record<number, RowNode>;
  columns: ColumnState[];
}

export interface StateHandlers {
  onRowGroupOpened?: (e: AgGridEvent) => void;
  onColumnRowGroupChanged?: (e: AgGridEvent) => void;
  onColumnVisible?: (e: AgGridEvent) => void;
  onColumnMoved?: (e: AgGridEvent) => void;
  onColumnResized?: (e: AgGridEvent) => void;
}

export interface SaveStateHelper {
  eventHandlers: StateHandlers;
  saveState: (gridOptions: TableApi) => void;
}

interface TableApi {
  api: GridApi;
  columnApi: ColumnApi;
}

interface StateHelperConfig extends StateHandlers {
  name: string;
  saveState?: (state: AgGridState) => void;
}

const eventHandlerKeys: Array<keyof StateHandlers> = [
  'onRowGroupOpened',
  'onColumnRowGroupChanged',
  'onColumnVisible',
  'onColumnMoved',
  'onColumnResized',
];

const getAgGridState = (gridOptions: TableApi): AgGridState => {
  const nodes = [];
  gridOptions.api.forEachNode((node: RowNode) => {
    nodes.push({ expanded: node.expanded });
  });
  const columns = gridOptions.columnApi.getColumnState();

  return { nodes, columns };
};

const saveState = (customSave: (state: AgGridState) => void) => (
  gridOptions: TableApi,
): void => {
  const state = getAgGridState(gridOptions);
  if (customSave) {
    customSave(state);
  }
};

const getTableEventHandlers = (config: StateHelperConfig): StateHandlers => {
  const handlers = {};
  const customSave = config.saveState;
  eventHandlerKeys.forEach(key => {
    handlers[key] = (e: AgGridEvent) => {
      if (config[key] && typeof config[key] === 'function') {
        config[key](e);
      }
      saveState(customSave)(e);
    };
  });

  return handlers;
};

export const gridStateHelper = (config: StateHelperConfig): SaveStateHelper => ({
  eventHandlers: getTableEventHandlers(config),
  saveState: saveState(config.saveState),
});
