import * as React from 'react';

import { Styled } from './styled';

interface Props {
  cellCoordinates: string;
}

export class ExcelTableCelCoordinates extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <Styled.CellCoardinates>{this.props.cellCoordinates}</Styled.CellCoardinates>
    );
  }
}
