import { Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { DropDownCustomItem } from './dropdown-custom-item';
import { Styled } from './styled';

interface Props {
  elements: string[];
  onClick: (id: string) => void;
  focusElement: number;
  activeElement: string;
  optionRenderer: (id: string) => React.ReactNode;
}

const DropdownListComponent: React.FC<Props> = (props) => {
  const {
    elements,
    onClick,
    activeElement,
    focusElement,
    optionRenderer,
  } = props;

  return (
    <>
      {elements.length ? (
        elements.map((element, index) => {
          const isActive = activeElement === element;
          const isFocus = index === focusElement;

          return (
            <>
              <DropDownCustomItem
                key={index}
                id={element}
                onClick={onClick}
                isActive={isActive}
                isFocus={isFocus}
                renderer={optionRenderer}
              />
            </>
          );
        })
      ) : (
        <Styled.NoResults>
          <Text>Empty list</Text>
        </Styled.NoResults>
      )}
    </>
  );
};

export const DropdownCustomList = React.memo(DropdownListComponent);
