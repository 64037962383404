import * as React from 'react';

import {
  WithMeasurementsEngineContextAwareProps,
} from '../../interfaces/measurements/with-measurements-engine-context-aware-props';
import { MeasurementsEngineLayoutApiContext } from '../measurements-engine-layout-api-context-provider';

export function withMeasurementEngineLayoutContext<P>(
  Component: React.ComponentType<P & WithMeasurementsEngineContextAwareProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof WithMeasurementsEngineContextAwareProps>>> {
  return class WithMeasurementEngineLayoutContext extends React.Component<P> {
    public render(): React.ReactNode {
      return (
        <MeasurementsEngineLayoutApiContext.Consumer>
          {contextProps => {
            return (
              <Component
                {...this.props}
                {...contextProps}
              />
            );
          }}
        </MeasurementsEngineLayoutApiContext.Consumer>
      );
    }
  };
}
