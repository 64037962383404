import React from 'react';

import './database-activity-modify-panel.scss';

import { DatabaseEntityModifyPanel } from '../database-entity-modify-panel';
import { CreateDatabaseActivity } from './create-database-activity';
import { CreateDatabaseActivityVariant } from './create-database-activity-variant';
import { EditDatabaseActivity } from './edit-database-activity';
import { EditDatabaseActivityVariant } from './edit-database-activity-variant';

export class DatabaseActivityModifyPanel extends React.Component {
  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyPanel
        createRootComponent={CreateDatabaseActivity}
        createVariantComponent={CreateDatabaseActivityVariant}
        editRootComponent={EditDatabaseActivity}
        editVariantComponent={EditDatabaseActivityVariant}
      />
    );
  }
}
