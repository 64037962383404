import { ICellRendererFunc } from 'ag-grid-community';

import './aggregation-cell.scss';

import { ExtractorConfig } from '../../../../../../units/projects/interfaces/graph-storage-records-config';
import { getAggregationCellExtractorValues, getAggregationCellValue } from './aggregation-cell-value-getter';
import { extractorDisplayValue } from './extractor-display-value';

export const AGG_HASH = 'aggHash';


export const aggregationCell = (extractorsDisplayNames: ExtractorConfig): ICellRendererFunc => (params) => {
  if (params.node.field !== AGG_HASH) {
    return '';
  }
  if (params.getValue() !== undefined) {
    const agg = getAggregationCellExtractorValues(params.node, extractorsDisplayNames, params.context.isImperialUnit);

    return (
      `<div class='aggregation-cell' >
        ${getAggregationCellValue(params.node)}
      </div>
      ${extractorDisplayValue(agg)}`
    );
  }
  return '';
};

