import { DrawingsShortInfo } from '../interfaces';
import { DrawingsFile } from '../interfaces/drawings-file-info';
import { PdfTextRectangle } from '../interfaces/pdf-text-rectangle';
import { DocsLoader } from '../interfaces/util';
import { prepareLoadSettings } from './pdf-current-doc-getter';

interface LoadPageTextOptions {
  drawing: DrawingsShortInfo;
  files: Record<string, DrawingsFile>;
  loader: DocsLoader;
}

export async function loadPageText({ drawing, files, loader }: LoadPageTextOptions): Promise<string> {
  const settings = prepareLoadSettings(drawing, files);
  settings.isOptimized = false;
  const doc = await loader.loadFileAsCore(settings);
  return doc.loadPageText(Number.isInteger(settings.pageNumber) ? 1 : settings.pageNumber + 1);
}

async function dumpAllLines(
  reader: Core.PDFNet.TextExtractor,
  matrix: Core.PDFNet.Matrix2D,
): Promise<PdfTextRectangle[]> {
  const result = new Array<PdfTextRectangle>();
  for (let line = await reader.getFirstLine(); await line.isValid(); line = await line.getNextLine()) {
    const box = await line.getBBox();
    let text = '';
    for (let word = await line.getFirstWord(); await word.isValid(); word = await word.getNextWord()) {
      text += text ? ` ${await word.getString()}` : await word.getString();
    }
    const p1 = await matrix.mult(box.x1, box.y1);
    const p2 = await matrix.mult(box.x2, box.y2);
    result.push({
      text,
      rect: [
        [p1.x, p1.y],
        [p2.x, p2.y],
      ],
    });
  }
  return result;
}

export async function getPageTextRects(doc: Core.PDFNet.PDFDoc, pageNumber: number): Promise<PdfTextRectangle[]> {
  doc.lock();
  const extactor = await Core.PDFNet.TextExtractor.create();
  const p = await doc.getPage(pageNumber + 1);
  await extactor.begin(p);
  const matrix = await p.getDefaultMatrix(true);
  const result = await dumpAllLines(extactor, matrix);
  extactor.destroy();
  doc.unlock();
  return result;
}

export function loadAndExtractPageTextRects(
  { drawing, files, loader }: LoadPageTextOptions,
): [ Promise<PdfTextRectangle[]>, () => void] {
  let isCanceled = false;
  const canceler = (): void => {
    isCanceled = true;
  };

  const promise = new Promise<PdfTextRectangle[]>((resolve, reject) => {
    const settings = prepareLoadSettings(drawing, files);
    settings.isOptimized = false;
    loader
      .loadFileAsDoc(settings)
      .then((doc) => {
        getPageTextRects(doc, settings.pageNumber === undefined ? drawing.pageNumber : 0)
          .then((result) => {
            if (!isCanceled) {
              resolve(result);
            }
          });
      })
      .catch(reject);
  });
  return [promise, canceler ];
}
