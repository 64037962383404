import * as paper from 'paper';

import { DrawingsCanvasConstants } from '../../constants';
import { DrawingMarkShapes } from '../../constants/drawing-styles';


const ShapeTypeToCreationMethod: Record<DrawingMarkShapes, (radius: number) => paper.Path> = {
  [DrawingMarkShapes.Circle]: (radius) => {
    return new paper.Path.Circle(new paper.Point(0, 0), radius);
  },
  [DrawingMarkShapes.Octagon]: (radius) => {
    return new paper.Path.RegularPolygon({
      center: [0, 0],
      sides: 8,
      radius,
    });
  },
  [DrawingMarkShapes.Hexagon]: (radius) => {
    return new paper.Path.RegularPolygon({
      center: [0, 0],
      sides: 6,
      rotation: 30,
      radius,
    });
  },
  [DrawingMarkShapes.Pentagon]: (radius) => {
    return new paper.Path.RegularPolygon({
      center: [0, 0],
      sides: 5,
      radius,
    });
  },
  [DrawingMarkShapes.Romb]: (radius) => {
    return new paper.Path.RegularPolygon({
      center: [0, 0],
      sides: 4,
      rotation: 45,
      radius,
    });
  },
  [DrawingMarkShapes.Triangle]: (radius) => {
    return new paper.Path.RegularPolygon({
      center: [0, 0],
      sides: 3,
      radius,
    });
  },
  [DrawingMarkShapes.Star]: (radius) => {
    return new paper.Path.Star({
      center: [0, 0],
      points: 5,
      radius1: radius,
      radius2: radius - DrawingsCanvasConstants.countMarkPadding / 2,
    });
  },
  [DrawingMarkShapes.RoundedSquare]: (radius) => {
    const cornerRadius = radius * 0.25;
    radius += (Math.sqrt(2) - 1) * cornerRadius;
    const halfSide = radius / Math.sqrt(2);
    const side = halfSide * 2.0;
    return new paper.Path.Rectangle({
      point: [0, 0],
      radius: cornerRadius,
      size: [side, side],
    });
  },
  [DrawingMarkShapes.Square]: (radius) => {
    return new paper.Path.RegularPolygon({
      center: [0, 0],
      sides: 4,
      radius,
    });
  },
};

export class ShapeCreator {
  private _cachedShapes: Record<string, Record<number, paper.Path>> = {};


  public createShape(shapeType: DrawingMarkShapes, radius: number): paper.Path {
    const resultRadius = Math.max(DrawingsCanvasConstants.countRadius, radius);
    this._cachedShapes[shapeType] = this._cachedShapes[shapeType] || {};
    if (!this._cachedShapes[shapeType][resultRadius]) {
      const method = ShapeTypeToCreationMethod[shapeType] || ShapeTypeToCreationMethod[DrawingMarkShapes.Circle];
      this._cachedShapes[shapeType][resultRadius] = method(resultRadius);
    }
    return this._cachedShapes[shapeType][resultRadius].clone();
  }
}
