import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import {
  ConfirmationDialog,
} from '../../../../../components/dialog/confirmation-dialog';
import { DrawingDialogs } from '../../constants/drawing-dialogs';

interface StateToProps {
  removeSelectedInstances: () => void;
}

interface Props extends StateToProps {
  isUsedInSpreadsheet: boolean;
}

const DeleteMeasuresConfirmationDialogComponent: React.FC<Props> = ({
  isUsedInSpreadsheet,
  removeSelectedInstances,
}) => {
  const title = isUsedInSpreadsheet
    ? 'Are you sure you want to delete it?'
    : 'Are you sure you want to delete all selected measurements?';
  const description = isUsedInSpreadsheet
    ? `This measurement is used in a spreadsheet.
      You can use the TraceLink feature to find out in which cells is it used.`
    : 'You have multiple measurements selected and you are trying to delete all of them. Are you sure?';

  return (
    <ConfirmationDialog
      name={DrawingDialogs.DELETE_MEASUREMENTS_DIALOG}
      title={title}
      text={description}
      confirmButtonText='Delete'
      cancelButtonText='Cancel'
      onConfirm={removeSelectedInstances}
    />
  );
};


function mapStateToProps({ dialog }: State): StateToProps {
  return {
    removeSelectedInstances: dialog[DrawingDialogs.DELETE_MEASUREMENTS_DIALOG],
  };
}

export const DrawingsDeleteMeasuresConfirmationDialog =
  connect(mapStateToProps)
  (DeleteMeasuresConfirmationDialogComponent);
