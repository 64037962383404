import * as React from 'react';

import { KreoIconKeyCombinePlus, KreoIconKeyCtrl, KreoIconKeyH } from 'common/UIKit';
import { MenuItem } from './menu-item';

interface Props {
  isTint: boolean;
  onShowAll: () => void;
  resetAllColor: () => void;
}

export const HasInvisible: React.FC<Props> = (props) => {
  const icons = [
    <KreoIconKeyCtrl key={0}/>,
    <KreoIconKeyH key={1}/>,
    <KreoIconKeyCombinePlus key={2}/>,
  ];
  return (
    <div>
      <MenuItem
        key={0}
        title='Show All'
        onClick={props.onShowAll}
        icons={icons}
      />
      {props.isTint &&
        <MenuItem
          key={1}
          title='Reset all colours'
          onClick={props.resetAllColor}
          icons={[]}
        />}
    </div>);
};
