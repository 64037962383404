import { CodecUtils } from 'common/utils/codec-utils';

export enum CommentType {
  Remarks = 'Remarks',
  Notes = 'Notes',
  Issues = 'Issues',
  Commentary = 'Commentary',
}

export const CommentTypeC = CodecUtils.createEnumCodec<CommentType>(CommentType, 'CommentType');
