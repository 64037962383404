import { DrawingModesWithoutSelectionBlock, DrawingsDrawMode } from '../enums/drawings-draw-mode';

function isShowFlyingMenu(drawMode: DrawingsDrawMode): boolean {
  return drawMode === DrawingsDrawMode.Disabled
    || drawMode === DrawingsDrawMode.SelectItems
    || drawMode === DrawingsDrawMode.CalibrateScale;
}

function isDrawEnabled(drawMode: DrawingsDrawMode): boolean {
  return drawMode !== DrawingsDrawMode.Disabled
    && drawMode !== DrawingsDrawMode.SelectItems
    && drawMode !== DrawingsDrawMode.CalibrateScale
    && drawMode !== DrawingsDrawMode.Union
    && drawMode !== DrawingsDrawMode.OpenPolygon
    && drawMode !== DrawingsDrawMode.Subtract
    && drawMode !== DrawingsDrawMode.Offset
    && drawMode !== DrawingsDrawMode.Join
    && drawMode !== DrawingsDrawMode.Modify
    && drawMode !== DrawingsDrawMode.Sticker
    && drawMode !== DrawingsDrawMode.Continue
    && drawMode !== DrawingsDrawMode.Legend
    && drawMode !== DrawingsDrawMode.Enclose
    && drawMode !== DrawingsDrawMode.MagicSearch
    && drawMode !== DrawingsDrawMode.AutoMeasure2
    && drawMode !== DrawingsDrawMode.SplitPolyline;
}

function isDraggableByDrawMode(drawMode: DrawingsDrawMode): boolean {
  return drawMode === DrawingsDrawMode.Disabled
    || drawMode === DrawingsDrawMode.SelectItems
    || drawMode === DrawingsDrawMode.CalibrateScale
    || drawMode === DrawingsDrawMode.Calibrate
    || drawMode === DrawingsDrawMode.Sticker
    || drawMode === DrawingsDrawMode.Dropper;
}

function isBooleanDrawMode(drawMode: DrawingsDrawMode): boolean {
  return drawMode === DrawingsDrawMode.Union
    || drawMode === DrawingsDrawMode.Subtract
    || drawMode === DrawingsDrawMode.Join
    || drawMode === DrawingsDrawMode.Enclose
    || drawMode === DrawingsDrawMode.OpenPolygon
    || drawMode === DrawingsDrawMode.SplitPolyline;
}

function isIsolativeDrawMode(drawMode: DrawingsDrawMode): boolean {
  return isBooleanDrawMode(drawMode)
    || drawMode === DrawingsDrawMode.Offset
    || drawMode === DrawingsDrawMode.Knife
    || drawMode === DrawingsDrawMode.MagicSearch
    || drawMode === DrawingsDrawMode.MagicSearchArea
    || drawMode === DrawingsDrawMode.AutoMeasure2;
}

function isSelectionEnabled(drawMode: DrawingsDrawMode): boolean {
  return DrawingModesWithoutSelectionBlock.includes(drawMode);
}

function isHoverEnabledDrawMode(drawMode: DrawingsDrawMode): boolean {
  return drawMode === DrawingsDrawMode.SelectItems
    || drawMode === DrawingsDrawMode.Disabled;
}

export const DrawingsCommonUtils = {
  isDrawEnabled,
  isDraggableByDrawMode,
  isShowFlyingMenu,
  isBooleanDrawMode,
  isIsolativeDrawMode,
  isSelectionEnabled,
  isHoverEnabledDrawMode,
};
