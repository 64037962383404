import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import Tour from 'reactour';
import { AnyAction, Dispatch } from 'redux';

import './ui-tour.scss';

import { State } from 'common/interfaces/state';
import { PersistedStorageActions } from '../units/persisted-storage/actions/creators';
import { UiTours } from './ui-tour-constants';

interface OwnProps {
  tourName: string;
}

interface PageStateProps {
  enableTour: boolean;
}

interface PageDispatchProps {
  onChangeShowCreateProjectTour: (name: string) => void;
}

interface PageProps extends OwnProps, PageStateProps, PageDispatchProps { }

class UiTourComponent extends React.Component<PageProps> {
  public render(): React.ReactNode {
    return (
      <Tour
        onRequestClose={this.closeTour}
        steps={UiTours[this.props.tourName]}
        isOpen={this.props.enableTour}
        closeWithMask={false}
        disableDotsNavigation={true}
        disableKeyboardNavigation={true}
        showButtons={false}
        rounded={5}
        startAt={0}
        className='ui-tour'
      />
    );
  }

  @autobind
  private closeTour(): void {
    if (this.props.enableTour) {
      this.props.onChangeShowCreateProjectTour(this.props.tourName);
    }
  }
}

const mapStateToProps = (state: State, props: OwnProps): PageStateProps => {
  const disableShowTourList = state.persistedStorage.disableShowTourList;

  return {
    enableTour: disableShowTourList ? !disableShowTourList[props.tourName] : true,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): PageDispatchProps => {
  return {
    onChangeShowCreateProjectTour: (name) =>
      dispatch(PersistedStorageActions.toggleDisableTour(name)),
  };
};

export const UiTour = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UiTourComponent);
