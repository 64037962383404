import Keycloak from 'keycloak-js';
import { ServiceWorkerConnector } from 'common/utils/service-worker/connect-service-worker';
import { ServiceWorkerActions } from 'common/utils/service-worker/service-worker-messaging';
import { AppUrls } from 'routes/app-urls';
import { resetAnalytics } from 'utils/posthog';
import { store } from '../../store';
import { AccountActions } from './actions/creators';

export class KeyCloakService {
  private static _keycloak: Keycloak;
  private static _isError: boolean;

  public static initInstance(authUrl: string): Keycloak {
    KeyCloakService._keycloak = new Keycloak({
      url: authUrl,
      realm: 'kreo',
      clientId: 'takeoff',
    });
    this._keycloak.onAuthLogout = () => {
      resetAnalytics();
    };
    return KeyCloakService._keycloak;

  }

  public static getKeycloak(): Keycloak {
    return KeyCloakService._keycloak;
  }

  public static isLoggedIn(): boolean {
    return !!KeyCloakService._keycloak?.token;
  }

  public static async getTokenAsync(): Promise<string> {
    if (KeyCloakService._keycloak.isTokenExpired(10)) {
      if (!await KeyCloakService.updateToken() && !KeyCloakService._keycloak.authenticated) {
        return '';
      }
    }
    return KeyCloakService.getToken();
  }

  public static getToken(): string {
    return KeyCloakService._keycloak?.token;
  }

  public static toAccount(): void {
    window.open(KeyCloakService._keycloak?.createAccountUrl({ redirectUri: window.location.href }));
  }

  public static async logIn(redirectUri: string = AppUrls.root.path): Promise<void> {
    await KeyCloakService._keycloak
      .login({ redirectUri: `${window.location.origin}${redirectUri}` });
  }

  public static async updateToken(): Promise<boolean> {
    if (!KeyCloakService._keycloak || KeyCloakService._isError) {
      return false;
    }
    const value = await KeyCloakService._keycloak.updateToken(1);
    ServiceWorkerConnector.sendMessage(ServiceWorkerActions.setToken(KeyCloakService.getToken()));
    return value;
  }

  public static async callLogout(redirectUri: string = AppUrls.root.path): Promise<void> {
    if (KeyCloakService._keycloak) {
      return KeyCloakService._keycloak.logout({ redirectUri: `${window.location.origin}${redirectUri}` });
    } else {
      store.dispatch(AccountActions.logOut());
    }
  }
}

