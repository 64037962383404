import classNames from 'classnames';
import * as React from 'react';

import './cost-estimate-table.scss';

import { CostEstimateGroupingType } from '../../units/projects/enums/cost-estimate-grouping-type';

import { TableBody } from './table-body';
import { TableHeader } from './table-header';

interface TableProps {
  data: any;
  projectName?: string;
  edited: boolean;
  groupingType: CostEstimateGroupingType;
  includeDuration: boolean;
  fixedHeader?: boolean;
}

export class CostEstimateTable extends React.Component<TableProps> {
  public render(): JSX.Element {
    return (
      <div className={classNames('cost-estimate-table', { 'editable': this.props.edited })}>
        <TableHeader
          fixed={this.props.fixedHeader}
          includeDuration={this.props.includeDuration}
        />
        <TableBody
          includeDuration={this.props.includeDuration}
          data={this.props.data}
          projectName={this.props.projectName}
          isEditable={this.props.edited}
          groupingType={this.props.groupingType}
        />
      </div>
    );
  }
}
