import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import './activity-grouping-header.scss';

import { KreoIconGroupingBig, KreoIconSelectToolCollapsed, KreoIconSequenceBig } from 'common/UIKit';
import { StepActions } from '../../actions';
import { ActivityGroupingStepPage } from '../../interfaces';


interface ReduxActions {
  setActivityGroupingStepPage: (page: ActivityGroupingStepPage) => void;
}

class ActivityGroupingHeaderComponent extends React.Component<ReduxActions> {
  public render(): JSX.Element {
    const component = (
      <div className='activity-grouping-header'>
        <div className='grouping-page-toggle' tabIndex={0}>
          <div className='grouping-page-toggle__item'>
            <div className='grouping-page-toggle__icon'><KreoIconGroupingBig /></div>
            Grouping
            <div className='grouping-page-toggle__indicator'><KreoIconSelectToolCollapsed /></div>
          </div>
          <div onClick={this.setMacroSequencePage} className='grouping-page-toggle__item'>
            <div className='grouping-page-toggle__icon'><KreoIconSequenceBig /></div>
            Sequence
          </div>
        </div>
      </div>
    );

    return component;
  }

  @autobind
  private setMacroSequencePage(): void {
    this.props.setActivityGroupingStepPage(ActivityGroupingStepPage.MacroSequence);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setActivityGroupingStepPage: page => dispatch(StepActions.setActivityGroupingStepPage(page)),
  };
};

const connector = connect(null, mapDispatchToProps);
export const ActivityGroupingHeader = connector(ActivityGroupingHeaderComponent);
