import { delay } from './delay';

async function* indexedIteratorWithDelayEveryNStep<T>(
  array: Iterable<T>,
  stepDelay: number,
  steps: number,
): AsyncIterableIterator<[T, number]> {
  let index = 0;
  for (const item of array) {
    yield [item, index++];
    if (index % steps === 0) {
      await delay(stepDelay);
    }
  }
}

async function* iteratorWithDelayEveryNStep<T>(
  array: Iterable<T>,
  stepDelay: number,
  steps: number,
): AsyncIterableIterator<T> {
  for await (const [item] of indexedIteratorWithDelayEveryNStep(array, stepDelay, steps)) {
    yield item;
  }
}

async function filterMap<T, K>(
  array: Iterable<T>,
  filter: (item: T) => boolean,
  map: (value: T, index: number) => K,
  stepDelay: number,
  steps: number,
): Promise<T[]> {
  const result = [];
  for await (const [item, index] of indexedIteratorWithDelayEveryNStep(array, stepDelay, steps)) {
    if (filter(item)) {
      const mappedItem = map(item, index);
      result.push(mappedItem);
    }
  }
  return result;
}

export const AsyncArrayUtils = {
  iteratorWithDelayEveryNStep,
  indexedIteratorWithDelayEveryNStep,
  filterMap,
};
