import { ElementTooltip, IconButton, SvgComponent } from '@kreo/kreo-ui-components';
import * as React from 'react';

interface Props {
  Icon: SvgComponent;
  tooltipText: string;
  onClick?: () => void;
  width?: number;
  height?: number;
}

export const HelpUserButton = ({
  Icon,
  tooltipText,
  onClick,
  width = 50,
  height = 50,
}: Props): JSX.Element => {
  return (
    <ElementTooltip
      text={tooltipText}
      position='top'
    >
      <IconButton
        onClick={onClick}
        Icon={Icon}
        iconHeight={20}
        width={width}
        height={height}
      />
    </ElementTooltip>
  );
};
