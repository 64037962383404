import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';

import './tree-table-column-header.scss';

import { TreeTableEditTitle } from './tree-table-edit-title';


interface Params extends Ag.IHeaderParams {
  onRename?: (id: string, name: string) => void;
  getColumnDisplayName?: (displayName: string, column: Ag.Column) => string;
}

export class TreeTableColumnHeader implements Ag.IHeader  {

  private params: Params;
  private eEditTitle: HTMLDivElement;
  private eGui: HTMLDivElement;
  private eMenu: HTMLSpanElement;


  public init(params: Params): void {
    this.params = params;
    this.eGui = document.createElement('div');
    this.eGui.className = 'ag-header-component tree-table-column-header';
    this.eMenu = document.createElement('span');
    this.eMenu.className = 'ag-icon ag-icon-menu';
    this.eMenu.addEventListener('click', this.openMenu);

    const editTitle = new TreeTableEditTitle();
    editTitle.init({
      value: params.displayName,
      getDisplayName: this.params.getColumnDisplayName ? this.getColumnDisplayName : null,
      onChange: this.params.onRename ? this.rename : null,
    });
    this.eEditTitle = editTitle.getGui();
    this.eGui.appendChild(this.eEditTitle);
    this.eGui.appendChild(this.eMenu);
  }

  public refresh(params: Ag.IHeaderParams): boolean {
    return this.params !== params;
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public destroy(): void {
    this.eMenu.removeEventListener('click', this.openMenu);
  }

  @autobind
  private rename(name: string): void {
    this.params.onRename(this.params.column.getId(), name);
  }

  @autobind
  private getColumnDisplayName(): string {
    const { getColumnDisplayName, column, displayName } = this.params;
    if (getColumnDisplayName) {
      return getColumnDisplayName(displayName, column);
    }
  }

  @autobind
  private openMenu(e: Event): void {
    e.stopPropagation();
    const { api, column } = this.params;
    api.showColumnMenuAfterButtonClick(column.getId(), this.eMenu);
  }
}
