import autobind from 'autobind-decorator';
import * as React from 'react';

import './subcontractor-table-item.scss';

import { Avatar, KreoButton } from 'common/UIKit';
import { ItemMenu, ItemMenuType } from '../../../../../components/controls/item-menu';
import { UserBidPricingStatus } from '../../../enums/user-bid-pricing-status';
import { BidPricingUserCost } from '../../../interfaces/bid-pricing/bid-pricing-user-cost';
import { Invitations } from './invitations';
import { ScNumber } from './sc-number';

interface InvitesButtonProps {
  status: UserBidPricingStatus;
  isBidPricingActive: boolean;
  onCancelRequest(e: React.MouseEvent<HTMLButtonElement>): void;
  onWin(e: React.MouseEvent<HTMLButtonElement>): void;
}

const InvitesButton: React.FC<InvitesButtonProps> = props => {
  switch (props.status) {
    case UserBidPricingStatus.AcceptedInvite:
      return (
        <KreoButton size='medium' className='table-btn default' disabled={!props.isBidPricingActive}>
          Invite Accepted
        </KreoButton>
      );
    case UserBidPricingStatus.Estimated:
      return (
        <KreoButton
          className='table-btn default'
          size='medium'
          disabled={!props.isBidPricingActive}
          onClick={props.onWin}
        >
          Confirm bid
        </KreoButton>
      );
    case UserBidPricingStatus.Winner:
      return (
        <KreoButton size='medium' className='table-btn' disabled={!props.isBidPricingActive} mode='success'>
          Selected
        </KreoButton>
      );
    case UserBidPricingStatus.Invited:
      return (
        <KreoButton
          className='table-btn'
          size='medium'
          disabled={!props.isBidPricingActive}
          onClick={props.onCancelRequest}
          mode={'cancel'}
        >
          Cancel Request
        </KreoButton>
      );
    default:
      return <React.Fragment />;
  }
};

interface Props {
  workpackageId: number;
  item: BidPricingUserCost;
  height?: number;
  labour: number;
  plant: number;
  material: number;
  total: number;
  duration: number;
  indirect: number;
  status: UserBidPricingStatus;
  sourceLabour: number;
  sourcePlant: number;
  sourceMaterial: number;
  sourceTotal: number;
  sourceDuration: number;
  sourceIndirect: number;
  onItemClick(item: BidPricingUserCost): void;
  onCancelRequest(email: string, workPackageId: number): void;
  onDeleteSubcontractorBid(constructionCostId: number, workPackageId: number): void;
  onWin(userId: string, workPackageId: number, constructionCostId: number): void;
}

export class SubcontractorTableItem extends React.Component<Props> {
  public render(): React.ReactNode {
    const InvitationContext = Invitations.getContext();
    const { item } = this.props;
    const username =
      item.user.lastName && item.user.firstName
        ? `${item.user.firstName} ${item.user.lastName}`
        : item.user.email;
    return (
      <div className='bid-pricing-invitations__inner-row' onClick={this.onItemClick}>
        <div className='bid-pricing-invitations__subcontractor'>
          <Avatar
            userName={username}
            lastName={item.user.lastName}
            firstName={item.user.firstName}
            userId={item.user.id}
          />
          <span className='bid-pricing-invitations__subcontractor-name' title={username}>
            {username}
          </span>
        </div>
        <div className='bid-pricing-invitations__data cell'>
          <ScNumber source={this.props.sourceLabour} scdata={this.props.labour} />
        </div>
        <div className='bid-pricing-invitations__data cell'>
          <ScNumber source={this.props.sourcePlant} scdata={this.props.plant} />
        </div>
        <div className='bid-pricing-invitations__data cell'>
          <ScNumber source={this.props.sourceMaterial} scdata={this.props.material} />
        </div>
        <div className='bid-pricing-invitations__data cell'>
          <ScNumber source={this.props.sourceIndirect} scdata={this.props.indirect} />
        </div>
        <div className='bid-pricing-invitations__data cell total'>
          <ScNumber source={this.props.sourceTotal} scdata={this.props.total} />
        </div>
        <div className='bid-pricing-invitations__data cell'>
          <ScNumber source={this.props.sourceDuration} scdata={this.props.duration} />
        </div>
        <div className='bid-pricing-invitations__action cell'>
          <div className='action-buttons'>
            <InvitationContext.Consumer>
              {value => (
                <InvitesButton
                  isBidPricingActive={value}
                  status={this.props.status}
                  onWin={this.estimatedButtonClick}
                  onCancelRequest={this.handleCancelClick}
                />
              )}
            </InvitationContext.Consumer>
            {this.props.status === UserBidPricingStatus.Winner ||
            this.props.status === UserBidPricingStatus.Estimated ||
            this.props.status === UserBidPricingStatus.AcceptedInvite ? (
              <ItemMenu
                className='sub-menu'
                menu={this.getActionMenu()}
                handleClick={this.handleActionMenuClick}
              />
              ) : null}
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private onItemClick(): void {
    this.props.onItemClick(this.props.item);
  }

  @autobind
  private estimatedButtonClick(e: React.MouseEvent<HTMLButtonElement>): void {
    e.stopPropagation();
    this.props.onWin(this.props.item.user.id, this.props.item.packageId, this.props.item.id);
  }

  @autobind
  private handleCancelClick(e: React.MouseEvent<HTMLButtonElement>): void {
    e.stopPropagation();
    this.props.onCancelRequest(this.props.item.user.email, this.props.workpackageId);
  }

  @autobind
  private getActionMenu(): ItemMenuType[] {
    return [
      {
        name: 'Delete',
        action: () => {
          this.props.onDeleteSubcontractorBid(this.props.item.id, this.props.workpackageId);
        },
        disabled: false,
      },
    ];
  }

  @autobind
  private handleActionMenuClick(e: React.MouseEvent<HTMLLabelElement>): void {
    e.stopPropagation();
  }
}
