import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { CEActivityAssignmentActions } from 'unit-cost-estimate/actions/creators';
import { CEActivityAssignmentDataTreeNode } from 'unit-cost-estimate/interfaces';
import {
  ActivityAssignmentActivityListContainer,
} from 'unit-projects/components/activity-assignment-activity-list-container';
import {
  ActivityAssignmentActivityTileBody,
  ActivityAssignmentActivityTileBodyTitle,
  ActivityAssignmentActivityTileContainer,
} from 'unit-projects/components/activity-assignment-activity-tile-containers';
import { ActivityAssignmentNoActivities } from 'unit-projects/components/activity-assignment-no-activities';
import { ValidationResizableEditor } from 'unit-projects/components/validation-resizable-editor';
import { DatabaseSelector } from 'unit-projects/pages/validation/steps/components';
import { ActivityAssignmentActivitiesHeader } from '../activity-assignment-activities-header';
import { ActivityAssignmentActivityTile } from './activity-assignment-activity-tile';

interface DispatchProps {
  runActivityAssignmentModule: (dbIds?: number[]) => void;
}

interface StateProps {
  activitiesCount: number;
  activities: CEActivityAssignmentDataTreeNode[];
  databaseIds?: number[];
}

interface Props extends StateProps, DispatchProps {
}

class ActivityAssignmentActivityLayoutEditComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
        <ValidationResizableEditor>
          <ActivityAssignmentActivitiesHeader edit={true} />
          <ActivityAssignmentActivityListContainer>
            <KreoScrollbars showShadowTop={true}>
              {this.props.activitiesCount > 0
                ? this.getActivityTiles()
                : (
                  <ActivityAssignmentNoActivities/>
                )}
              <ActivityAssignmentActivityTileContainer enabled={true}>
                <ActivityAssignmentActivityTileBody>
                  <div className='activity-assignment-activity-tile__commands'>
                    <ActivityAssignmentActivityTileBodyTitle>
                      Reassign from Database
                    </ActivityAssignmentActivityTileBodyTitle>
                    <DatabaseSelector
                      onCalculate={this.runActivityAssignmentModule}
                      canBeCalculated={true}
                      databasesCalculated={this.props.databaseIds}
                      allowSettings={false}
                    />
                  </div>
                </ActivityAssignmentActivityTileBody>
              </ActivityAssignmentActivityTileContainer>
            </KreoScrollbars>
          </ActivityAssignmentActivityListContainer>
        </ValidationResizableEditor>
    );
  }

  @autobind
  private runActivityAssignmentModule(ids?: number[]): void {
    this.props.runActivityAssignmentModule(ids);
  }

  private getActivityTiles(): React.ReactNode[] {
    const activities = new Array<React.ReactNode>();
    for (let i = 0; i < this.props.activitiesCount; i++) {
      activities[i] = (
        <ActivityAssignmentActivityTile
          key={i}
          index={i}
        />
      );
    }
    return activities;
  }
}

function mapStateToProps({ ceActivityAssignment }: State): StateProps {
  return {
    activities: ceActivityAssignment.selectedWorks,
    activitiesCount: ceActivityAssignment.selectedWorks.length,
    databaseIds: ceActivityAssignment.databases,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    runActivityAssignmentModule: (dbIds?: number[]) => {
      dispatch(CEActivityAssignmentActions.patchActivityAssignment(dbIds));
    },
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityAssignmentActivityLayoutEdit =
  connector(ActivityAssignmentActivityLayoutEditComponent);
