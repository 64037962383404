import React from 'react';

interface Props {
  style?: React.CSSProperties;
  top?: boolean;
  left?: boolean;
  floatVertical?: number;
  floatHorizontal?: number;
  color?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  controlName?: string;
}

import './flat-panel.scss';

class FlatPanel extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    style: {},
    floatVertical: 40,
    floatHorizontal: 40,
    color: 'white',
    onClick: () => {
      console.warn('please add event handler for onClick');
    },
  };

  public getStyle(): React.CSSProperties {
    const { style, top, left, floatVertical, floatHorizontal, color } = this.props;
    const ret: React.CSSProperties = {
      backgroundColor: color,
    };

    if (top) {
      ret.top = floatVertical;
    } else {
      ret.bottom = floatVertical;
    }

    if (left) {
      ret.left = floatHorizontal;
    } else {
      ret.right = floatHorizontal;
    }

    return {
      ...ret,
      ...style,
    };
  }

  public render(): JSX.Element {
    return (
      <div
        onClick={this.props.onClick}
        data-control-name={this.props.controlName}
        className='kreo-flat-panel'
        style={this.getStyle()}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FlatPanel;
