import { Text } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { AnyAction, Dispatch } from 'redux';

import { CustomDialog } from 'common/components/custom-dialog';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { useOpenDialogEffect } from './hooks';
import { useCheckValidState } from './hooks/use-check-valid-state';
import { Styled } from './styled';

interface StateProps {
  hideSmallScreeDisplayDialog: boolean;
}

interface DispatchProps {
  openDialog: () => void;
  onCloseDialog: () => void;
}

type Props = StateProps & DispatchProps;

const isMac = (): boolean => navigator.platform === 'MacIntel';

const isShowCommand = isMac();

const commadButton = isShowCommand
  ? 'Cmd'
  : 'Ctrl';

const SMALL_SCREEN_DIALOG_NAME = 'SMALL_SCREEN_DIALOG_NAME';
const TITLE_VALID = '👍 All looks good now!';
const TITLE_WARNING = '🛑 Screen Size Warning';
const TEXT_VALID = 'The issue is resolved. You can continue working';
const TEXT_WARNING = `Please, note that a small screen size may
  negatively affect your efficient work with the product.`;
const TEXT_DESCRIPTION = `To optimize your experience with Kreo, 
  hold down the ${commadButton} key and press the - button until the button turns`;
const CANCEL_BUTTON_INVALID_TEXT = 'Skip';
const CANCEL_BUTTON_VALID_TEXT = 'Continue';

const SmallScreenDialogComponent: React.FC<Props> = ({ openDialog, onCloseDialog, hideSmallScreeDisplayDialog }) => {
  const [isValid, setIsValid, isOpen, close] = useCheckValidState();

  const closeDialog = useCallback(() => {
    close();
    onCloseDialog();
  }, [close]);

  useOpenDialogEffect(openDialog, isValid);
  const cancelButton = isValid ? CANCEL_BUTTON_VALID_TEXT : CANCEL_BUTTON_INVALID_TEXT;
  const title = isValid ? TITLE_VALID : TITLE_WARNING;
  const text = isValid ? TEXT_VALID : TEXT_WARNING;

  if (hideSmallScreeDisplayDialog) {
    return null;
  }

  return (
    <>
      {isOpen && <Styled.Container/>}
      <ReactResizeDetector
        handleHeight={true}
        handleWidth={true}
        onResize={setIsValid}
      />
      <DialogWrapper name={SMALL_SCREEN_DIALOG_NAME}>
        <Styled.ConfirmationDialog isValid={isValid}>
          <CustomDialog
            mainText={title}
            secondaryText={text}
            rejectText={cancelButton}
            onReject={closeDialog}
            zIndex={1000}
          >
            <Styled.HotKeysContainer>
              <Styled.CtrlContainer>
                <Text fontSize={14}>{commadButton.toUpperCase()}</Text>
              </Styled.CtrlContainer>
              <Text>+</Text>
              <Styled.MinusContainer>
                <Text fontSize={14}>-</Text>
              </Styled.MinusContainer>
            </Styled.HotKeysContainer>
            <Styled.DescriptionContainer>
              <Text fontSize={14}>{TEXT_DESCRIPTION}</Text>
            </Styled.DescriptionContainer>
          </CustomDialog>
        </Styled.ConfirmationDialog>
      </DialogWrapper>
    </>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    hideSmallScreeDisplayDialog: state.persistedStorage.hideSmallScreenDialog,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openDialog: () => dispatch(KreoDialogActions.openDialog(SMALL_SCREEN_DIALOG_NAME)),
    onCloseDialog: () => {
      dispatch(KreoDialogActions.closeDialog(SMALL_SCREEN_DIALOG_NAME));
      dispatch(PersistedStorageActions.hideSmallScreenDisplayDialog());
    },
  };
};

export const SmallScreenDialog = connect(mapStateToProps, mapDispatchToProps)(SmallScreenDialogComponent);
