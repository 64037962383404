import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import { DrawingsGeometryInstance, ShortPointDescription } from 'common/components/drawings/interfaces';
import { PdfGeometry } from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { UuidUtil } from 'common/utils/uuid-utils';
import { VisibleEntity, VisibleEntityConfig } from '../../common';
import { NewDrawingSettings } from '../../interfaces';
import { WizzardPreview } from './wizzard-preview';

export type AllowedFinderGeometry = PdfGeometry[];


interface Config extends VisibleEntityConfig<AllowedFinderGeometry> {
  fill?: boolean;
  newDrawingsStylesObserver: ContextObserver<NewDrawingSettings>;
  viewSettingsObserver: ContextObserver<MeasuresViewSettings>;
  colorCache: DrawingsColorCacheHelper;
  layer: paper.Layer;
  isCount: boolean;
  similarity: number;
  geometriesToHide: Record<number, boolean>;
}

export class FinderPreview extends VisibleEntity<AllowedFinderGeometry, Config> {
  private _preview: WizzardPreview;

  public updateFilters(similarity: number, geometriesToHide: Record<number, boolean>): void {
    this._preview.updateFilters(similarity, geometriesToHide);
    this._config.similarity = similarity;
    this._config.geometriesToHide = geometriesToHide;
  }

  public destroy(): void {
    this._preview.destroy();
  }

  public getInstances(
    drawingId: string,
  ): Promise<{ instances: DrawingsGeometryInstance[], newPoints: Record<string, ShortPointDescription> }> {
    const geometry = this._preview.geometry;
    const { shape, name, color } = this._config.newDrawingsStylesObserver.getContext();
    const points = [];
    const pointsDescription = {};
    for (const rect of geometry) {
      if (rect.similarity < this._config.similarity || this._config.geometriesToHide[rect.id]) {
        continue;
      }
      const point = rect.points.reduce((aggregated, currentvalue, _i, array) => {
        aggregated[0] += currentvalue[0] / array.length;
        aggregated[1] += currentvalue[1] / array.length;
        return aggregated;
      }, [0, 0]);
      const id = UuidUtil.generateUuid();
      pointsDescription[id] = point;
      points.push(id);
    }
    const instanceId = UuidUtil.generateUuid();
    const instance = {
      drawingId,
      id: instanceId,
      name,
      isAuto: false,
      type: DrawingsInstanceType.Count,
      geometry: {
        color,
        points,
        shape,
      },
    };
    return Promise.resolve({
      instances: [instance],
      newPoints: pointsDescription,
    });
  }

  protected render(geometries: AllowedFinderGeometry): void {
    if (this._preview) {
      this._preview.destroy();
    }
    this._preview = new WizzardPreview({
      similarity: this._config.similarity,
      geometry: geometries,
      colorCache: this._config.colorCache,
      layer: this._config.layer,
      newDrawingsStylesObserver: this._config.newDrawingsStylesObserver,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      fill: true,
      geometriesToHide: {},
      viewSettingsObserver: this._config.viewSettingsObserver,
    });
  }
}
