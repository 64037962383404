import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > ${Text} {
    font-weight: 500;
    font-size: 14px;
  }
`;

export const Styled = {
  Container,
};
