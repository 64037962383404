import { Text } from '@kreo/kreo-ui-components';
import Rive from '@rive-app/react-canvas';
import React from 'react';

import { Styled } from './styled';

const LoadingPopupComponent: React.FC = () => {
  return (
    <Styled.Container>
      <Rive
        width={140}
        height={80}
        src='https://public.rive.app/hosted/280597/99846/2h8R2_WBtkiMtXHGBLLraQ.riv'
        animations={decodeURIComponent('Search')}
        artboard={decodeURIComponent('Search')}
      />
      <Text fontSize={12}>Searching...</Text>
    </Styled.Container>
  );
};

export const LoadingPopup = React.memo(LoadingPopupComponent);
