export enum MentionTextPartType {
  Text,
  Mention,
  NextLineText,
}

export interface MentionedTextPart {
  value: string;
  type: MentionTextPartType;
}

