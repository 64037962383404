import styled from 'styled-components';
import {
  DrawingsStrokeStypeSwitcherStyled,
} from 'common/components/drawings/drawings-controls/drawings-instances-styles';

const OptionContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  svg {
    margin-top: auto;
    margin-bottom: auto;
    width: 30px;
    height: 20px;
    fill: ${p => p.theme.color.turquoise};
  }
`;

const SelectedOptionContainer = styled(OptionContainer)`
  position: absolute;
  top: 0px;
`;

const Switcher = styled.div`
  height: 20px;
  width: 160px;
  ${DrawingsStrokeStypeSwitcherStyled.Container} {
    > div {
      margin: unset;
    }
  }
`;

export const Styled = {
  OptionContainer,
  SelectedOptionContainer,
  Switcher,
};
