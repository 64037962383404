import autobind from 'autobind-decorator';
import * as React from 'react';

import { DrawingGeometryOperationType } from '../../enums/drawing-geometry-operation-type';
import { DrawingsDrawMode } from '../../enums/drawings-draw-mode';
import { DrawingsRendererApiContextProps, withRendererApiContext } from '../renderer-api-context';
import {
  DrawingsGeometryOperationsApiContext,
  DrawingsGeometryOperationsContext,
  DrawingsGeometryOperationsContextApi,
  DrawingsGeometryOperationsContextState,
} from './geometry-operations-context';


interface Props extends DrawingsRendererApiContextProps {
  drawMode: DrawingsDrawMode;
  selectedInstances: string[];
  setDrawMode: (drawMode: DrawingsDrawMode) => void;
}

export class DrawingsGeometryOperationContextProviderComponent
  extends React.PureComponent<Props, DrawingsGeometryOperationsContextState> {
  private updateApi: DrawingsGeometryOperationsContextApi = {
    setSelectedIdForSubtract: instanceId => this.setState({ selectedIdForSubtract: instanceId }),
    setOffsetValue: value => this.setState({ offsetValue: value }),
    startSubtract: this.startSubtract,
    startUnite: this.startUnite,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      offsetValue: null,
      selectedIdForSubtract: null,
    };
  }

  public render(): React.ReactNode {
    return (
      <DrawingsGeometryOperationsApiContext.Provider value={this.updateApi}>
        <DrawingsGeometryOperationsContext.Provider value={this.state}>
          {this.props.children}
        </DrawingsGeometryOperationsContext.Provider>
      </DrawingsGeometryOperationsApiContext.Provider>
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.drawMode !== DrawingsDrawMode.Offset && prevProps.drawMode === DrawingsDrawMode.Offset) {
      this.setState({ offsetValue: null });
    }
  }

  @autobind
  private startUnite(): void {
    this.props.rendererApi.setIdsForOperation(this.props.selectedInstances, DrawingGeometryOperationType.Boolean);
    this.props.setDrawMode(DrawingsDrawMode.Union);
  }

  @autobind
  private startSubtract(): void {
    this.props.rendererApi.engine.changeSelection([]);
    this.props.rendererApi.setIdsForOperation(this.props.selectedInstances, DrawingGeometryOperationType.Boolean);
    this.props.setDrawMode(DrawingsDrawMode.Subtract);
  }
}


export const DrawingsGeometryOperationContextProvider =
  withRendererApiContext(DrawingsGeometryOperationContextProviderComponent);
