import { ElementTooltip, Text, TinyText } from '@kreo/kreo-ui-components';
import moment from 'moment';
import React, { useCallback } from 'react';

import { DateFormatConstants } from 'common/constants/date-formats';
import { Styled } from './styled';

interface Props {
  id: string;
  total: number;
  date: Date;
  description?: string;
  onDownload: (invoiceId: string) => void;
}

export const Billed: React.FC<Props> = ({ id, total, date, description, onDownload }) => {
  const onClickDownload = useCallback(() => {
    onDownload(id);
  }, [id, onDownload]);

  return (
    <>
      <Styled.ContentContainer>
        <Styled.Title>
          <ElementTooltip
            text={description}
            position='top'
            speed='l'
          >
            <Text withEllipsis={true}>{description}</Text>
          </ElementTooltip>
        </Styled.Title>
        <Styled.PriceAndDataContainer>
          <Styled.Price>
            <Text>£ {total / 100}</Text>
          </Styled.Price>
          <Styled.Data>
            <TinyText color={'gray'}>
              {moment(date).format(DateFormatConstants.FULL_DATE_FORMAT)}
            </TinyText>
          </Styled.Data>
        </Styled.PriceAndDataContainer>
      </Styled.ContentContainer>
      <Styled.Download onClick={onClickDownload}>
        <Text>Download</Text>
      </Styled.Download>
    </>
  );
};
