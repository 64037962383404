import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { Notification } from 'common/interfaces/realtime-messages';
import { State as ReduxState } from 'common/interfaces/state';
import { NotificationActions } from '../../actions';

interface ReduxProps {
  notifications: Notification[];
}

interface ReduxActions {
  readNotifications(notificationIds: number[], value: boolean): void;
  deleteNotifications(notificationIds: number[]): void;
}

interface Props extends ReduxProps, ReduxActions {
  isTodayHeader: boolean;
  showActions: boolean;
}

class BellNotificationHeaderComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { isTodayHeader, notifications, showActions } = this.props;
    const hasUnread = notifications.filter(x => !x.isRead).length > 0;
    return (
      <div className='bell-notification-panel__sub-header'>
        <div className='bell-notification-panel__sub-header-title'>{isTodayHeader ? 'Today' : 'Older'}</div>
        {showActions ? (
          hasUnread ? (
            <div className='bell-notification-panel__sub-header-action' onClick={this.readAll}>Mark all as read</div>
          ) : (
            <div className='bell-notification-panel__sub-header-action' onClick={this.deleteAll}>Clear All</div>
          )
        ) : null}
      </div>
    );
  }

  @autobind
  private readAll(): void {
    this.props.readNotifications(this.props.notifications.map(x => x.id), true);
  }

  @autobind
  private deleteAll(): void {
    this.props.deleteNotifications(this.props.notifications.map(x => x.id));
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    notifications: state.notifications.notifications,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    readNotifications: (notificationIds, value) => {
      dispatch(NotificationActions.readNotifications(notificationIds, value));
    },
    deleteNotifications: notificationIds => {
      dispatch(NotificationActions.deleteNotifications(notificationIds));
    },
  };
};

export const BellNotificationHeader = connect(mapStateToProps, mapDispatchToProps)(
  BellNotificationHeaderComponent,
);
