import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const RemoveSelectionButton = styled.div`
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  padding: 0 5px;
  border-radius: 50%;
  background-color: ${p => p.theme.color.red};
  cursor: pointer;
  margin-left: auto;
  display: none;
`;

const Count = styled.div`
  box-sizing: border-box;
  min-width: 20px;
  height: 20px;
  max-width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${p => p.theme.color.lightGray};
  border-radius: 5px;
  background-color: ${p => p.theme.color.turquoise};
  box-shadow: 0px 3px 6px ${p => p.theme.color.background};
`;

const Container = styled.div`
  display: flex;
  height: 20px;
  align-items: center;

  ${Text} {
    color: ${p => p.theme.color.gray};
    line-height: 14px;
    margin-left: 15px;
  }

  &:hover {
    cursor: pointer;
    ${Text} {
      color: ${p => p.theme.color.turquoise}
    }
    ${RemoveSelectionButton} {
      display: flex;
    }
  }
`;

export const Styled = {
  Container,
  Count,
  RemoveSelectionButton,
};
