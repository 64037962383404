import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { EntityHeader } from 'common/components/entity-header';
import { UnitsSwitcher } from 'unit-2d-info-panel/content/settings/units-switcher';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { useCurrentDrawingInfo } from '../hooks';
import { useApplySettedCalibrationFromState } from '../hooks/calibration/use-apply-scale';
import { DrawingsUtils } from '../utils/drawings-utils';
import { AutoScaleButton } from './auto-scale-button';
import { TabInputs } from './drawings-calibrate-input';
import { TabNames } from './enums';
import { CalibrateHeader } from './header';
import { useSelectPages } from './hooks';
import { useTabsState } from './hooks/use-tabs-state';
import { SelectPages } from './select-pages';
import { Styled } from './styled';

const MENU_NAMES = Object.values(TabNames);

interface Props {
  onCalibrated: () => void;
}

const DrawingsCalibrateMenuComponent: React.FC<Props> = ({ onCalibrated }) => {
  const [currentTab, setCurrentTab] = useTabsState();
  const currentDrawing = useCurrentDrawingInfo();
  const isCalibrated = currentDrawing && DrawingsUtils.isCalibrated(currentDrawing);
  const Input = TabInputs[currentTab];
  const [ selectedPages, changePagesSelection ] = useSelectPages(currentDrawing.drawingId);

  const { sendEvent } = useAnalytics();

  const apply = useApplySettedCalibrationFromState();

  const onApply = useCallback(() => {
    apply(selectedPages);
    onCalibrated();
    if (currentTab === TabNames.Calibrate) {
      sendEvent(MetricNames.measurements.setUpCalibrateScale);
    } else {
      sendEvent(MetricNames.measurements.setUpStandartScale);
    }
  }, [apply, selectedPages, currentTab, sendEvent]);

  return (
    <Styled.Container>
      <Styled.GroupBlock>
        <CalibrateHeader menuNames={MENU_NAMES} currentTab={currentTab} onSelectMenuItem={setCurrentTab} />
        <Input />
      </Styled.GroupBlock>
      <Styled.AutoScale>
        {currentDrawing?.scaleRecognitionStatus === ViewModelStatus.Ready && !isCalibrated ? (
          <AutoScaleButton currentDrawing={currentDrawing} selectedPages={selectedPages} />
        ) : null}
      </Styled.AutoScale>
      <Styled.GroupBlock>
        <SelectPages
          currentPageId={currentDrawing.drawingId}
          selectedPages={selectedPages}
          onSelectionChanged={changePagesSelection}
        />
      </Styled.GroupBlock>
      <Styled.GroupBlock>
        <EntityHeader icon={Icons.UnitSystem} title='Unit System'/>
        <UnitsSwitcher/>
      </Styled.GroupBlock>
      <Styled.GroupBlock>
        <RectangleButton
          size="m"
          mood="positive"
          onClick={onApply}
          Icon={Icons.Apply}
          text="Apply"
          height={40}
        />
      </Styled.GroupBlock>
    </Styled.Container>
  );
};

export const DrawingsCalibrateMenu = React.memo(DrawingsCalibrateMenuComponent);
