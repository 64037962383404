import { BaseFormat, NumericFormat, PropertyFormatEnum } from '../../interfaces';
import { FormatTypeGuards } from '../format-typeguards';

const getUnit = (format: BaseFormat): string | undefined => {
  switch (format.type) {
    case PropertyFormatEnum.Number:
      return (format as NumericFormat).unit;
    default:
      return;
  }
};

const Format = {
  [PropertyFormatEnum.Number]: 'Number',
  [PropertyFormatEnum.Text]: 'Text',
};

const getFormat = (format: BaseFormat): string => {
  return Format[format.type];
};

const updateFormatUnit = <T extends BaseFormat = BaseFormat>(format: T, unit: string): T => {
  if (FormatTypeGuards.isNumeric(format)) {
    return { ...format, unit };
  }
  return format;
};

export const formatData = {
  getUnit,
  getFormat,
  updateFormatUnit,
};
