import * as React from 'react';

interface Props {
  className?: string;
  color?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void ;
  controlName?: string;
}

export class KreoIconAddScreen extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    color: '#D9DADF',
  };

  public render(): React.ReactNode {
    return (
      <svg
        onClick={this.props.onClick}
        className={this.props.className}
        data-control-name={this.props.controlName}
        width='16px'
        height='16px'
        viewBox='0 0 16 16'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <defs />
        <g id='Inputs/icons/16x16_1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <rect id='Rectangle-6' x='0' y='0' width='16' height='16' />
            <path
              d='M3,2 L13,2 C14.6568542,2 16,3.34314575 16,5 L16,11
                C16,12.6568542 14.6568542,14 13,14 L3,14 C1.34314575,14
                2.02906125e-16,12.6568542 0,11 L0,5 C-2.02906125e-16,3.34314575
                1.34314575,2 3,2 Z M8,12 C10.209139,12 12,10.209139 12,8 C12,5.790861
                10.209139,4 8,4 C5.790861,4 4,5.790861 4,8 C4,10.209139 5.790861,12
                8,12 Z M13.5,5.5 C14.0522847,5.5 14.5,5.05228475 14.5,4.5
                C14.5,3.94771525 14.0522847,3.5 13.5,3.5 C12.9477153,3.5 12.5,3.94771525
                12.5,4.5 C12.5,5.05228475 12.9477153,5.5 13.5,5.5 Z M8,11 C6.34314575,11
                5,9.65685425 5,8 C5,6.34314575 6.34314575,5 8,5 C9.65685425,5
                11,6.34314575 11,8 C11,9.65685425 9.65685425,11 8,11 Z'
              id='Combined-Shape'
              fill={this.props.color}
            />
        </g>
      </svg>
    );
  }
}
