import { slowAppear } from '@kreo/kreo-ui-components/constants/animations';
import styled from 'styled-components';

import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';

const SIZE = {
  big: {
    containerSize: 90,
    padding: 15,
    instrumentSize: 70,
    iconSize: 30,
  },
  small: {
    containerSize: 50,
    padding: 8,
    instrumentSize: 34,
    iconSize: 20,
  },
};

const GROUP_MARGIN = 25;

const Container = styled.div<{ size: string, horizontalMode: boolean }>`
  display: flex;
  flex-direction: ${p => p.horizontalMode ? 'row' : 'column'};
  background: ${p => p.theme.color.backgroundRush};
  border-radius: ${p => SIZE[p.size].containerSize / 2}px;
  padding: ${p => p.horizontalMode ? `0 ${SIZE[p.size].padding}px` : `${SIZE[p.size].padding}px 0`};
  box-shadow: ${p => p.theme.shadow.xl};
  position: relative;
  gap: 6px;
  align-items: center;
  ${UpgradeWrapperStyled.StarsContainer} {
    right: -5px;
    top: -3px;
  }
`;

const SubMenuContainer = styled.div<{
  size: string,
  horizontalMode: boolean,
  tooltipPosition: string,
  itemsAmount: number,
}>`
  display: flex;
  flex-direction: ${p => p.horizontalMode ? 'row' : 'column'};
  background: ${p => p.theme.color.backgroundRush};
  border-radius: ${p => SIZE[p.size].containerSize / 2}px;
  padding: ${p => p.horizontalMode ? `0 ${SIZE[p.size].padding}px` : `${SIZE[p.size].padding}px 0`};
  box-shadow: ${p => p.theme.shadow.xl};
  animation: ${p => p.horizontalMode
    ? slowAppear({ yStart: p.tooltipPosition === 'top' ? 20 : -20, yEnd: 0 })
    : slowAppear({ xStart: p.tooltipPosition === 'left' ? 20 : -20, xEnd: 0 })}
    ${p => p.theme.duration.s} ease;
  position: absolute;
  cursor: default;
  z-index: 2;
  top: ${p => p.horizontalMode
    ? `calc(${p.tooltipPosition === 'top' ? '-100%' : '100%'} - ${SIZE[p.size].padding}px)`
    : `calc(50% - ${(p.itemsAmount * SIZE[p.size].containerSize + SIZE[p.size].padding * 2) / 2}px)`};
  left: ${p => p.horizontalMode
    ? `calc(50% - ${(p.itemsAmount * SIZE[p.size].containerSize + SIZE[p.size].padding * 2) / 2}px)`
    : `calc(${p.tooltipPosition === 'left' ? '-100%' : '100%'} - ${SIZE[p.size].padding}px)`};
`;

const Badge = styled.div<{ isBadge: boolean }>`
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  background: ${p => p.theme.color.turquoise} !important;
  animation: ${p => p.isBadge ? 'pulse' : 'none'} 0.75s ease both;


  @keyframes pulse {
  0% {
    transform: scale(0);
    background: ${p => p.theme.color.gray};
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
`;

const InstrumentContainer = styled.div<{
  disabled?: boolean,
  active?: boolean,
  addSeparationBefore?: boolean,
}>`
  position: relative;
  border-radius: 8px;
  background-color: ${p => p.theme.color.background};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  pointer-events: ${p => p.disabled ? 'none' : 'auto'};
  cursor: ${p => p.disabled ? 'default' : 'pointer'};
  margin-left: ${p => p.addSeparationBefore ? GROUP_MARGIN : 0}px;
  padding-right: 1px;
  padding-left: 1px;
  > div {
    display: flex;
  }
`;

const Instrument = styled.div<{
  size?: string,
  disabled?: boolean,
  active?: boolean,
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}>`
  width: 30px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ${p => p.theme.duration.s} linear;
  border-radius: 7px;
  pointer-events: ${p => p.disabled ? 'none' : 'auto'};
  background: ${p => (p.active && !p.disabled ? p.theme.color.turquoise : p.theme.color.backgroundRush)};
  box-sizing: border-box;
  border: 1px solid transparent;
  > svg {
    height: 20px;
    width: 20px;
    color: ${p => p.theme.color[p.disabled ? 'disabled' : p.active ? 'white' : 'gray']};
    transition: all ${p => p.theme.duration.s} linear;
    fill: ${p => p.theme.color[p.disabled ? 'disabled' : p.active ? 'white' : 'gray']};
  }

  :hover {
    border: 1px solid ${p => p.theme.color.turquoise};
    > svg {
      color: ${p => p.theme.color[p.active ? 'white' : 'turquoise']};
      fill: ${p => p.theme.color[p.disabled ? 'disabled' : p.active ? 'white' : 'turquoise']};
    }
  }

  @keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.4);
    fill: rgb(41, 116, 173);
  }
  100% {
    transform: scale(1);
  }
}`;

const NameBadge = styled.div`
  display: flex;
  margin-left: 10px;
  margin-right: 6px;
  gap: 10px;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    fill: ${(p) => p.theme.color.turquoise};
  }
`;

export const Styled = {
  Container,
  SubMenuContainer,
  InstrumentContainer,
  Instrument,
  Badge,
  GROUP_MARGIN,
  NameBadge,
};
