import styled, { StyledComponent } from 'styled-components';

import { DataControlNameAttribute } from 'common/constants/control-names';


const IconGift: StyledComponent<'div', any, { controlName: string }> =
styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid ${props => props.theme.color.background};
  cursor: pointer;

  > div {
    padding-left: 20px;

    > svg {
      margin-right: 20px;
    }

    :hover {
      transform: none;
    }

    :active {
      transform: none;
    }
  }

  p {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.color.mainFont} !important;
  }

  :hover {
    background: ${props => props.theme.color.pale};
    border-radius: 15px;

    p {
      color: ${props => props.theme.color.turquoise} !important;
    }

    svg {
      fill: ${props => props.theme.color.turquoise} !important;
    }
  }
`;

export const Styled = {
  IconGift,
};
