import { ModalWrapper } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import { UndoRedoListenerWrapper } from 'common/undo-redo/undo-redo-listener';
import { DrawingsActions } from '../../actions/creators/common';
import { DrawingDialogs } from '../../constants/drawing-dialogs';
import { DrawingsPdfLinkPlansWrapper } from '../drawings-pdf-browser-content/drawings-pdf';

interface DispatchProps {
  closePdfManagerDialog: () => void;
  closeAllFolders: () => void;
}

interface Props extends DispatchProps {}

class DrawingsPdfBrowserUndoWrapperComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <UndoRedoListenerWrapper>
        <ModalWrapper onExit={this.onCloseModal} zIndex={1001}>
          <DrawingsPdfLinkPlansWrapper isShowFilter={true} />
        </ModalWrapper>
      </UndoRedoListenerWrapper>
    );
  }

  @autobind
  private onCloseModal(): void {
    this.props.closePdfManagerDialog();
    this.props.closeAllFolders();
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    closePdfManagerDialog: () => dispatch(KreoDialogActions.closeDialog(DrawingDialogs.PDF_MANAGER_DIALOG)),
    closeAllFolders: () => dispatch(DrawingsActions.closeAllFolders()),
  };
}

export const DrawingsPdfBrowserUndoWrapper = connect(null, mapDispatchToProps)(DrawingsPdfBrowserUndoWrapperComponent);
