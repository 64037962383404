import * as Ag from 'ag-grid-community';
import { AssignedPia } from '2d/interfaces';
import { DrawingsGeometryGroup, DrawingsInstanceMeasure } from 'common/components/drawings';
import { DrawingsGeometryState, DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { DrawingsFiles } from 'common/components/drawings/interfaces/drawings-file-info';
import { UnitCategory, UnitTypes } from 'common/utils/unit-util';
import { Property } from 'unit-2d-database/interfaces';

export type Column = Ag.ColDef & { orderGroup?: number };

export interface AssignInfo {
  drawingElementAssign: Record<string, AssignedPia>;
  elementMeasurement: Record<string, DrawingsInstanceMeasure>;
  isImperial: boolean;
  withUnit: boolean;
  originProperties: Property[];
  showCode: boolean;
}

export interface DrawingInfo {
  groupsMap: Record<string, DrawingsGeometryGroup>;
  files: DrawingsFiles;
  drawingsInfo: Record<string, DrawingsShortInfo>;
  aiAnnotation: DrawingsGeometryState;
  groups: DrawingsGeometryGroup[];
  getInstancesMeasures: (
    instancesIds: Array<string | string[]>,
  ) => DrawingsInstanceMeasure[];
}

export enum ColumnType {
  Number,
  String,
}

export interface PropertyColumn {
  colType: ColumnType;
  colDef: Ag.ColDef;
  unitCategory: UnitCategory | null;
  unitType: UnitTypes | null;
}

export interface AssignNameInfo {
  items: string[];
  assembly: Record<string, string[]>;
}

export interface MetaGroupInfo {
  groupId: string;
  fields: string[];
  rowId: string;
  elementId: string;
}

export interface GroupRowData {
  parentGroupId?: string;
  parentGroup?: string;
  [key: string]: string;
}

export interface HandleMoveElementPayload {
  parentGroupId?: string | null;
  measurements: string[];
}

export interface HandleMoveGroupPayload {
  id: string;
}

export type DrawingInfoGetter = () => DrawingInfo;
export type AssignInfoGetter = () => AssignInfo;
export type UpdateTable = (
  p: { add?: any[], update?: any[], remove?: any[] },
  columns?: Ag.ColDef[],
  isFirst?: boolean,
) => void;

