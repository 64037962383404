import { useCallback, useState } from 'react';
import { MetricNames, useAnalytics } from 'utils/posthog';

export function useCreateScreenshot(
  create: () => Promise<void>,
): [() => void, boolean] {
  const [ inProgress, setInProgress ] = useState(false);
  const { sendEvent } = useAnalytics();

  const createCallback = useCallback(() => {
    setInProgress(true);
    sendEvent(MetricNames.threeDMeasures.screenshotCaptured);
    create().then(() => setInProgress(false));
  }, [ create, sendEvent]);

  return [createCallback, inProgress];
}
