import { H6 } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { QuestionnaireContent, ReplyType } from '../../../questionnaire';
import { Reply } from '../../reply';
import { OtherInputReply } from '../../reply/other-input-reply';
import { Styled } from './styled';


interface Props {
  id: number;
  question: string;
  answers: ReplyType[];
  isOther: boolean;
  replies: string[];
  questionContent: QuestionnaireContent[];
  setReplies: (value: string[]) => void;
  setQuestionContent: (value: QuestionnaireContent[]) => void;
}

export const RepliesBlock: React.FC<Props> = ({
  id,
  question,
  answers,
  isOther,
  replies,
  questionContent,
  setReplies,
  setQuestionContent,
}) => {
  return (
    <Styled.Wrapper>
      <H6>{question}</H6>
      <Styled.RepliesBlockContainer>
        {answers.map((answer, index) => {
          return (
            <Reply
              key={index}
              content={answer}
              onClick={setReplies}
              replies={replies}
              setQuestionContent={setQuestionContent}
              questionContent={questionContent}
              id={id}
            />
          );
        })}
        {isOther && (
          <OtherInputReply
            onClick={setReplies}
            setQuestionContent={setQuestionContent}
            questionContent={questionContent}
            replies={replies}
            id={id}
          />
        )}
      </Styled.RepliesBlockContainer>
    </Styled.Wrapper>
  );
};
