import autobind from 'autobind-decorator';
import * as classNames from 'classnames';
import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { Field, FieldArray, FieldsProps, GenericFieldArray, Validator, WrappedFieldArrayProps } from 'redux-form';
import { DrawingsUtils } from 'common/components/drawings/utils/drawings-utils';

import './line-file.scss';

import { KreoDialogActions } from 'common/UIKit';
import { Common } from '../../../actions';
import { UploadingFile } from '../../../common/interfaces/common-state';
import { State as ReduxState } from '../../../common/interfaces/state';
import { FileFormatSupportedDialog } from './file-format-supported-dialog/file-format-supported-dialog';
import { FileIndicator } from './file/file-indicator';

const FILE_FORMAT_SUPPORTED_DIALOG = 'FILE_FORMAT_SUPPORTED_DIALOG';

interface ReduxProps {
  files: UploadingFile[];
}

interface ReduxActions {
  uploadFiles: (files: File[], form: string, field: string) => void;
  clearFiles: () => void;
  openDialog: () => void;
  closeDialog: () => void;
}

interface Props extends ReduxProps, ReduxActions {}

interface Props {
  title: React.ReactNode;
  accept: string;
  validate: Validator[];
  form: string;
  name: string;
  disabled: boolean;
  extensionsToShow: string[];
  onUploadStarted?: (files: File[]) => void;
  onDeleteFile?: (file: UploadingFile) => void;
}

interface CustomFieldsProps extends FieldsProps<{}> {
  removeAll?: () => void;
}

interface InnerComponentProps {
  files: UploadingFile[];
}

interface FieldInnerComponentProps extends WrappedFieldArrayProps<any>, InnerComponentProps {}

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, InnerComponentProps>;

class LineFileComponent extends React.Component<Props> {
  private fields: CustomFieldsProps = null;

  public UNSAFE_componentWillUpdate(nextProps: Props): void {
    const nextFiles = nextProps.files.filter((x) => {
      return x.uploadedFileName.length > 0;
    });
    if (
      this.props.files.filter((x) => {
        return x.uploadedFileName.length > 0;
      }).length !== nextFiles.length ||
      this.fields.length !== nextFiles.length
    ) {
      this.fields.removeAll();
      nextFiles.map((x) => {
        this.fields.push({ key: x.key, uploadedFileName: x.uploadedFileName });
      });
    }
  }

  public componentWillUnmount(): void {
    this.props.clearFiles();
  }

  public render(): React.ReactNode {
    return (
      <div className="line-file">
        <FieldArrayCustom
          name="files"
          component={this.renderFiles}
          validate={this.props.validate}
          files={this.props.files}
        />
        <FileFormatSupportedDialog
          extensions={this.props.extensionsToShow}
        />
      </div>
    );
  }

  @autobind
  private renderFiles(props: FieldInnerComponentProps): JSX.Element {
    const {
      fields,
      meta: { error, submitFailed },
      files,
    } = props;
    this.fields = fields;
    const dropzoneClassNames = classNames('line-file__dropzone', {
      'line-file__dropzone--has-error': error && submitFailed,
      'line-file__dropzone--upload': !!files.length,
    });
    return (
      <Dropzone
        onDrop={this.onDrop}
        disabled={this.props.disabled}
        multiple={true}
        accept={this.props.accept}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: dropzoneClassNames })}>
            <input {...getInputProps()}/>
              {files.length === 0 ? (
                <span className="line-file__dropzone-label">{this.props.title}</span>
              ) : (
                <div className="line-file__file-indicator-container">
                  {files.map((file, index) => {
                    return <FileIndicator key={index} file={file} index={index} removeFromFields={this.onDeleteFile} />;
                  })}
                </div>
              )}
          </div>
        )}
      </Dropzone>
    );
  }

  @autobind
  private onDrop(acceptedFiles: File[]): void {
    if (!acceptedFiles.length) {
      this.props.openDialog();
      return;
    }
    const { files, form, name, uploadFiles, onUploadStarted } = this.props;
    const filesForUpload = [];
    for (const file of acceptedFiles) {
      if (!files.find((x) => x.key === DrawingsUtils.getFileKey(file))) {
        filesForUpload.push(file);
      }
    }

    uploadFiles(filesForUpload, form, name);
    if (onUploadStarted) {
      onUploadStarted(filesForUpload);
    }
  }

  @autobind
  private onDeleteFile(file: UploadingFile, index: number): void {
    this.fields.remove(index);
    if (this.props.onDeleteFile) {
      this.props.onDeleteFile(file);
    }
  }
}

function mapStateToProps(state: ReduxState): ReduxProps {
  return {
    files: state.common.files,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): ReduxActions {
  return {
    uploadFiles: (files, form, field) => dispatch(Common.uploadFiles(files, form, field)),
    clearFiles: () => dispatch(Common.commonClearFiles()),
    openDialog: () => dispatch(KreoDialogActions.openDialog(FILE_FORMAT_SUPPORTED_DIALOG)),
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(FILE_FORMAT_SUPPORTED_DIALOG)),
  };
}

export const LineFile = connect(mapStateToProps, mapDispatchToProps)(LineFileComponent);
