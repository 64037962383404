import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';
import { CheckboxWithTextStyled } from 'common/components/checkbox-with-text';

const ModalWrapper =styled.div`
  z-index: 9999;
  > div {
    backdrop-filter: none;
  }
`;

const Container = styled.div<{ withAdditional: boolean }>`
  display: flex;
  width: 640px;
  height: ${p => p.withAdditional ? 360 + 20 : 360}px;
  box-shadow: ${p => p.theme.shadow.xl};
  border-radius: 20px;
  flex-direction: column;
  background-color: ${p => p.theme.color.backgroundRush};
  box-sizing: border-box;
  animation: ${Constants.Animations.slowTopAppear()} ${p => p.theme.duration.s} linear;
  overflow: hidden;
  *,& {
    outline: none;
  }

  & ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }
  ${CheckboxWithTextStyled.Container} {
    height: 20px;
  }
`;

const PropertyName = styled.div`
  svg {
    min-width: 20px;
    margin-right: 15px;
    fill: ${p => p.theme.color.turquoise};
  }

  overflow: hidden;
  margin-right: 5px;
  align-items: center;
  display: flex;
  color: ${p => p.theme.color.mainFont};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const FormulaContainer = styled.div`
  max-height: 100%;
`;

export const Styled = {
  ModalWrapper,
  Container,
  HeaderContainer,
  PropertyName,
  FormulaContainer,
};
