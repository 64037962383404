export enum DrawingsDrawMode {
  Disabled = 1,

  Polyline,
  Polygon,
  Rectangle2Point,
  Rectangle3Point,

  Count,

  Calibrate,
  CalibrateScale,
  Diameter,
  Line,
  Perimeter,
  Radius,

  SelectItems,
  Modify,

  Subtract,
  Join,
  Union,
  Enclose,
  Offset,
  Knife,
  OpenPolygon,
  SplitPolyline,

  PasteWithPoint,

  Sticker,
  Image,
  Ruler,
  Legend,

  Continue,

  Wand,
  BucketFill,
  AILine,

  Dropper,
  WizzardSearchArea,

  Finder,
  FinderSelectionArea,

  MagicSearch,
  MagicSearchArea,
  AutoMeasure2,

  PdfFilterArea,
}


export const DrawingsUserAnnotationsModes = [
  DrawingsDrawMode.Sticker,
  DrawingsDrawMode.Image,
  DrawingsDrawMode.Ruler,
  DrawingsDrawMode.Legend,
];

export const ModesWithNewDrawingPanel = [
  DrawingsDrawMode.Polyline,
  DrawingsDrawMode.Wand,
  DrawingsDrawMode.BucketFill,
  DrawingsDrawMode.Polygon,
  DrawingsDrawMode.Rectangle2Point,
  DrawingsDrawMode.Rectangle3Point,
  DrawingsDrawMode.Count,
  DrawingsDrawMode.Dropper,
  DrawingsDrawMode.Finder,
  DrawingsDrawMode.MagicSearch,
];


export const ModesWithFinishByDoubleClick = [
  DrawingsDrawMode.Polygon,
  DrawingsDrawMode.Polyline,
  DrawingsDrawMode.Count,
  DrawingsDrawMode.Wand,
  DrawingsDrawMode.BucketFill,
];

export const DrawingsModesWithEnabledDelete = [
  DrawingsDrawMode.Wand,
  DrawingsDrawMode.BucketFill,
  DrawingsDrawMode.Polyline,
  DrawingsDrawMode.Polygon,
  DrawingsDrawMode.Rectangle2Point,
  DrawingsDrawMode.Rectangle3Point,
  DrawingsDrawMode.Count,
  DrawingsDrawMode.Disabled,
  DrawingsDrawMode.Continue,
];

export const DrawingsModesWithSnapping = [
  ...ModesWithNewDrawingPanel,
  DrawingsDrawMode.Calibrate,
  DrawingsDrawMode.Ruler,
  DrawingsDrawMode.Knife,
  DrawingsDrawMode.WizzardSearchArea,
  DrawingsDrawMode.FinderSelectionArea,
  DrawingsDrawMode.MagicSearchArea,
  DrawingsDrawMode.PdfFilterArea,
];


export const DrawingsModesWithCrosshair = [
  ...DrawingsModesWithSnapping,
  DrawingsDrawMode.Sticker,
  DrawingsDrawMode.Image,
  DrawingsDrawMode.Legend,
  DrawingsDrawMode.PasteWithPoint,
  DrawingsDrawMode.Continue,
];


export const DrawingsModesWithCloseAtStart = [
  DrawingsDrawMode.Polygon,
];

export const DrawingModesWithoutSelectionBlock = [
  ...DrawingsUserAnnotationsModes,
  DrawingsDrawMode.SelectItems,
  DrawingsDrawMode.Disabled,
  DrawingsDrawMode.Calibrate,
  DrawingsDrawMode.CalibrateScale,
  DrawingsDrawMode.PasteWithPoint,
  DrawingsDrawMode.Modify,
  DrawingsDrawMode.Continue,
];

export const DrawingsModesWithFullSelectionBlock = [
  DrawingsDrawMode.Subtract,
  DrawingsDrawMode.Join,
  DrawingsDrawMode.Union,
  DrawingsDrawMode.Offset,
  DrawingsDrawMode.Knife,
  DrawingsDrawMode.Enclose,
  DrawingsDrawMode.OpenPolygon,
  DrawingsDrawMode.SplitPolyline,
];


export const DropperDrawModes = [
  DrawingsDrawMode.Dropper,
  DrawingsDrawMode.WizzardSearchArea,
];


export const DragDrawModes = [
  DrawingsDrawMode.FinderSelectionArea,
  DrawingsDrawMode.WizzardSearchArea,
  DrawingsDrawMode.MagicSearchArea,
  DrawingsDrawMode.Rectangle2Point,
  DrawingsDrawMode.PdfFilterArea,
];


export const AIDrawModes = [
  DrawingsDrawMode.BucketFill,
  DrawingsDrawMode.Wand,
];

export const WizzardDrawModes = [
  DrawingsDrawMode.Wand,
  DrawingsDrawMode.BucketFill,
  DrawingsDrawMode.Dropper,
  DrawingsDrawMode.WizzardSearchArea,
  DrawingsDrawMode.Finder,
  DrawingsDrawMode.FinderSelectionArea,
];


export const CalibrationDrawModes = [
  DrawingsDrawMode.Calibrate,
  DrawingsDrawMode.CalibrateScale,
];


export const MagicSearchDrawModes = [
  DrawingsDrawMode.MagicSearch,
  DrawingsDrawMode.MagicSearchArea,
  DrawingsDrawMode.AutoMeasure2,
];


export const ClosedContoursDrawModes = [
  DrawingsDrawMode.Polygon,
  DrawingsDrawMode.Rectangle2Point,
  DrawingsDrawMode.Rectangle3Point,
  DrawingsDrawMode.BucketFill,
];
