import styled, { css } from 'styled-components';

const Container = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.div`
  padding: 0 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  margin: auto;
  padding: 20px;
  button {
    p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0px;
    }
  }
`;

const Active = css`
  position: relative;
  border-color: ${(p) => p.theme.color.turquoise};
  &:after {
    content: 'Drop your files here';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: ${(p) => p.theme.color.turquoise};
    font-weight: 500;
    background-color: rgba(#fff, 0.2);
  }
  ${Label} {
    opacity: 0;
  }
`;


const Dropzone = styled.div<{ isDragActive: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px dashed ${(p) => p.theme.color.turquoise};
  background-color: ${(p) => p.theme.color.turquoise}1A;
  flex: 1;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  ${p => p.isDragActive ? Active : ''} 
  &:focus-visible {
    outline: none;
  }
`;

const ProgressLine = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
`;


export const Styled = {
  Container,
  Dropzone,
  ProgressLine,
  Label,
};
