import classNames from 'classnames';
import * as React from 'react';

import './issue-tile.scss';

import { ModelCheckPriority } from '../../../enums/mode-check-priority';

interface Props {
  title: string;
  errorType: ModelCheckPriority;
  selected: boolean;
  errorCount: number;
  order: number;
  onSelect: (e: number) => void;
}

export class IssueTile extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
  }

  public render(): any {
    return (
      <div
        className={classNames('model-check-issue-tile', { 'selected': this.props.selected })}
        onClick={this.onSelect}
        data-control-name='model-check-issue'
      >
        <span className='model-check-issue-tile__title'>
          {this.props.title}
        </span>
        <span
          className={
            classNames(
              'model-check-issue-tile__error-count',
              { 'warning': this.props.errorType !== ModelCheckPriority.Low },
            )
          }
        >
          {this.props.errorCount}
        </span>
      </div>);
  }

  private onSelect(): void {
    this.props.onSelect(this.props.order);
  }
}
