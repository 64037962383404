import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import { change } from 'redux-form';

import { SvgSpinner } from 'common/components/svg-spinner';
import { StringDictionary } from 'common/interfaces/dictionary';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseActivityActions } from '../../actions/creators/database-activity';
import { DatabaseActivityListingActions } from '../../actions/creators/database-activity-listing';
import { ActivityCategoryType, ModifyDatabaseEntityMode } from '../../enums';
import {
  ActivityCategory,
  ActivityModel,
  ActivityVariantModel,
  ActivityVariantType,
  CodeGroupModel,
  ExtractorFunctionModel,
  UnitModel,
} from '../../interfaces/data';
import { ActivityForm } from '../../interfaces/rest-data';
import { ChangeFormHelper } from '../../utils/change-form-helper';
import { RestFormMapper } from '../../utils/rest-form-mapper';
import { DatabaseActivity } from '../database-activity';
import { DatabaseActivityVariant } from '../database-activity-variant';
import { DatabaseActivityVariantTotalCost } from '../database-activity-variant-total-cost';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';

interface ReduxProps {
  activity: ActivityModel;
  activityVariant: ActivityVariantModel;
  databaseId: number;
  selectedCategory: ActivityCategory;
  selectedCategoryType: ActivityCategoryType;
  defaultCategories: StringDictionary<ActivityCategory>;
  lastSelectedExtractorFunction: string;
  lastSelectedUnitId: number | null;
  functions: ExtractorFunctionModel[];
  form: ActivityForm;
  unitMap: Record<number, UnitModel>;
}

interface ReduxActions {
  setEditActivityModel: (activity: ActivityModel) => void;
  setEditActivityVariantModel: (activityVariant: ActivityVariantModel) => void;
  createDatabaseActivity: (databaseId: number, activity: ActivityForm) => void;
  setLastSelectedExtractorFunction: (extractorFunction: string) => void;
  setLastSelectedUnitId: (unitId: number) => void;
  applyFieldChanges: (field: string, value: any) => void;
}

interface Props extends DatabaseEntityModifyWrapProps, ReduxProps, ReduxActions {
}


const formId: string = 'create-activity';
class CreateDatabaseActivityComponent extends React.Component<Props> {
  private initialValue: ActivityForm = null;

  public componentDidMount(): void {
    const activity = this.getDefaultActivityModel();
    const variant = this.getDefaultActivityVariantModel();
    this.props.setEditActivityModel(this.getDefaultActivityModel());
    this.props.setEditActivityVariantModel(variant);

    activity.variants.push(variant);
    this.initialValue = RestFormMapper.GetActivityForm(activity, this.props.functions);
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Activity'
        isApplyButtonDisabled={this.isCreateButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Create}
        onSubmit={this.create}
        className='database-entity-modify-panel--create-activity database-entity-modify-panel--has-total-cost'
        readonly={this.props.readonly}
        form={formId}
        initialValues={this.initialValue}
      >
      {
        this.props.activity && this.props.activityVariant ? (
          <React.Fragment>
            <KreoScrollbars>
              <DatabaseActivity
                activity={this.props.activity}
                onChange={this.setEditActivityModel}
                readonly={this.props.readonly}
              />
              <DatabaseActivityVariant
                fieldName='variants[0]'
                activityVariant={this.props.activityVariant}
                onChange={this.setEditActivityVariantModel}
                readonly={this.props.readonly}
              />
            </KreoScrollbars>
            <DatabaseActivityVariantTotalCost
              databaseId={this.props.databaseId}
              activity={this.props.activity}
              activityVariant={this.props.activityVariant}
              unitMap={this.props.unitMap}
            />
          </React.Fragment>
        ) : <SvgSpinner size='large'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }

  @autobind
  private setEditActivityModel(model: ActivityModel): void {
    this.props.setEditActivityModel(model);
    model.variants[0] = this.props.activityVariant;
    const form = RestFormMapper.GetActivityForm(model, this.props.functions);
    ChangeFormHelper.applyChanges(this.props.form, form, this.props.applyFieldChanges);
  }

  @autobind
  private setEditActivityVariantModel(variantModel: ActivityVariantModel): void {
    this.props.setEditActivityVariantModel(variantModel);
    const model = this.props.activity;
    model.variants[0] = variantModel;
    const form = RestFormMapper.GetActivityForm(model, this.props.functions);
    ChangeFormHelper.applyChanges(this.props.form, form, this.props.applyFieldChanges);
  }

  @autobind
  private create(values: ActivityForm): void {
    const activity = { ...values };
    if (!activity.variants[0].name) {
      activity.variants[0].name = 'Variant 1';
    }
    this.props.createDatabaseActivity(this.props.databaseId, activity);
    this.props.setLastSelectedExtractorFunction(this.props.activityVariant.extractorFunction);
    this.props.setLastSelectedUnitId(this.props.activityVariant.unitId);
  }

  @autobind
  private isCreateButtonDisabled(): boolean {
    const { activity, activityVariant } = this.props;
    return !activity ||
      !activityVariant ||
      !activity.name ||
      !activity.name.trim() ||
      !activityVariant.extractorFunction ||
      !activityVariant.unitId;
  }

  private getDefaultActivityModel(): ActivityModel {
    return  {
      id: 0,
      name: '',
      description: null,
      variants: [],
      plants: [],
      labors: [],
      codeGroups: this.getDefaultCodeGroups(),
    };

  }

  private getDefaultCodeGroups(): CodeGroupModel[] {
    const selectedCodeGroup = this.getDefaultSelectedCodeGroup();
    const defaultCodeGroup = this.getDefaultCodeGroup();
    const result = [];

    if (defaultCodeGroup) {
      result.push(defaultCodeGroup);
    } else if (selectedCodeGroup) {
      result.push(selectedCodeGroup);
    }

    return result;
  }

  private getDefaultSelectedCodeGroup(): CodeGroupModel | null {
    const selectedCategory = this.props.selectedCategory;
    if (!this.props.selectedCategory || !this.props.selectedCategory.id) {
      return null;
    }

    return {
      id: 0,
      [this.props.selectedCategoryType]: {
        id: selectedCategory.id,
        code: selectedCategory.code,
        title: selectedCategory.title,
      },
    };
  }

  private getDefaultCodeGroup(): CodeGroupModel | null {
    const defaultCategories = this.props.defaultCategories;
    const defaultCategoriesKeys = Object.keys(defaultCategories);
    if (!defaultCategoriesKeys.length) {
      return null;
    }

    const codeGroup = {
      id: 0,
    };
    defaultCategoriesKeys.forEach(key => {
      codeGroup[key] = {
        id: defaultCategories[key].id,
        code: defaultCategories[key].code,
        title: defaultCategories[key].title,
      };
    });

    return (Object.keys(codeGroup).some(key => codeGroup[key]))
      ? codeGroup
      : null;
  }

  private getDefaultActivityVariantModel(): ActivityVariantModel {
    const { functions, lastSelectedExtractorFunction } = this.props;
    const lastSelectedExtractor = functions.find(x => x.id === lastSelectedExtractorFunction);

    return {
      id: null,
      name: null,
      materials: [],
      constraints: [],
      crewHours: null,
      unitId: this.props.lastSelectedUnitId,
      code: null,
      extractorFunction: this.props.lastSelectedExtractorFunction,
      extractorParameters: lastSelectedExtractor ? lastSelectedExtractor.extractorParameters : [],
      type: ActivityVariantType.FixedCrewHours,
      materialBasedCrewHours: null,
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  const activityListing = state.database.currentDatabase.activityListing;
  return {
    activityVariant: editModel.variant,
    activity: editModel.root,
    databaseId: state.database.currentDatabase.database.id,
    selectedCategory: activityListing.categories.selectedCategory,
    selectedCategoryType: activityListing.categories.selectedCategoryType,
    defaultCategories: activityListing.defaultValues.categories,
    lastSelectedExtractorFunction: activityListing.defaultValues.lastSelectedExtractorFunction,
    lastSelectedUnitId: activityListing.defaultValues.lastSelectedUnitId,
    functions: state.database.currentDatabase.functions,
    form: state.form[formId] && state.form[formId].values,
    unitMap: state.database.currentDatabase.unitMap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditActivityModel: activity =>
      dispatch(DatabaseActivityListingActions.setEditActivityModel(activity)),
    setEditActivityVariantModel: variant =>
      dispatch(DatabaseActivityListingActions.setEditActivityVariantModel(variant)),
    createDatabaseActivity: (databaseId, activity) =>
      dispatch(DatabaseActivityActions.createActivity(databaseId, activity, formId)),
    setLastSelectedExtractorFunction: extractorFunction =>
      dispatch(DatabaseActivityListingActions.setLastSelectedExtractorFunction(extractorFunction)),
    setLastSelectedUnitId: unitId =>
      dispatch(DatabaseActivityListingActions.setLastSelectedUnit(unitId)),
    applyFieldChanges: (field: string, value: any) => dispatch(change(formId, field, value)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const CreateDatabaseActivity = connector(CreateDatabaseActivityComponent);
