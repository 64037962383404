import { ElementTooltip, SvgComponent } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  onClick: () => void;
  title: string;
  description: string;
  hotkey?: string;
  iconType: SvgComponent;
}


export class DrawingsBooleanOperationButton extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    const Icon = this.props.iconType;
    return (
      <ElementTooltip
        position='top'
        size='small'
        tooltipWidth={210}
        text={this.props.title}
        hotKey={this.props.hotkey}
        description={this.props.description}
        speed='xl'
      >
        <Styled.OperationButton
          onClick={this.props.onClick}
        >
          <Icon/>
        </Styled.OperationButton>
      </ElementTooltip>
    );
  }
}

