import styled from 'styled-components';


const Container = styled.div`
  width: 450px;
  padding: 20px;
  margin: 20px 20px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.color.turquoise};
  border-radius: 20px;
  overflow: hidden;
`;

const IframeContainer = styled.div`
  padding-right: 32px;

  > svg {
    > g {
      fill: ${p => p.theme.color.white};
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    color: ${p => p.theme.color.white};
  }
`;


export const Styled = {
  Container,
  IframeContainer,
  TextContainer,
};
