import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  margin: 5px;
  width: 30px;
  height: 30px;
  background-color: ${p => p.theme.color.turquoise};
  border-radius: 10px;

  svg {
    height: 10px;
    width: 10px;
    fill: ${(p) => p.theme.color.white};
    transition: transform ${(p) => p.theme.duration.m} ease-in-out;
    transform: rotate(270deg);
  }
`;

export const Styled = {
  Container,
};
