import React from 'react';

import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { DrawingsActions } from 'common/components/drawings/actions/creators/common';
import { DrawingsFile } from 'common/components/drawings/interfaces/drawings-file-info';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { DrawingsPdfBrowserFileTitle } from '../drawings-pdf-browser-file-title';
import { DrawingsPdfBrowserPage } from '../drawings-pdf-browser-page';
import { Styled } from '../styled';


interface DispatchProps {
  onOptimize: (value: boolean) => void;
}

interface OwnProps {
  file: DrawingsFile;
  multiselect: boolean;
  canEdit: boolean;
  onChangeDrawingSelection: (id: string[], select: boolean, openLast: boolean) => void;
  selectedPages: string[];
  pageNames: string[];
  onChangePageSelection: (id: string, selected: boolean) => void;
  onParameterUpdate: (drawingId: string, parameter: string, value: string | number) => void;
  copyDrawingGeometriesToPage: (pdfId: string, drawingId: string, targetDrawingId: string) => void;
}

interface Props extends OwnProps, DispatchProps {}


const DrawingsPdfBrowserFileComponent: React.FC<Props> = ({
  file,
  multiselect,
  canEdit,
  selectedPages,
  pageNames,
  onChangeDrawingSelection,
  onChangePageSelection,
  onParameterUpdate,
  copyDrawingGeometriesToPage,
}) => {
  const fileIsReady =
    file.cadFileConvertingStatus === ViewModelStatus.Empty
    || file.cadFileConvertingStatus === ViewModelStatus.Ready;

  if (fileIsReady && !file.pages.length) {
    return null;
  }

  return (
    <Styled.WrapperContent id={file.id}>
      <DrawingsPdfBrowserFileTitle
        file={file}
        multiselect={multiselect}
        canEdit={canEdit}
        onChangeDrawingSelection={onChangeDrawingSelection}
      />
      {
        file.pages.length ? (
          <Styled.PagesContainer>
            {
              file.pages.map((page) => (
                <DrawingsPdfBrowserPage
                  page={page}
                  key={page.drawingId}
                  selected={selectedPages.includes(page.drawingId)}
                  pageNames={pageNames}
                  onChangeDrawingSelection={onChangePageSelection}
                  canEdit={canEdit}
                  onParameterUpdate={onParameterUpdate}
                  copyDrawingGeometriesToPage={copyDrawingGeometriesToPage}
                />
              ))
            }
          </Styled.PagesContainer>
        ) : null
      }
    </Styled.WrapperContent>
  );
};


function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { file }: OwnProps): DispatchProps  {
  return {
    onOptimize: (value) => dispatch(DrawingsActions.toggleFileOptimize(file.id, value)),
  };
}


export const DrawingsPdfBrowserFile = connect(null, mapDispatchToProps)(DrawingsPdfBrowserFileComponent);
