import { Text } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { RadioButton } from 'common/components/radion-button';
import { SortOptions, SortValue } from '../../interfaces/drawing-measurement-sort';
import { SortElement } from './interfaces';
import { Styled } from './styled';

interface Props {
  sort: SortElement;
  sortOptionName: SortOptions;
  selected: boolean;
  handleSortChange: (sortOption: SortOptions, value: SortValue) => void;
}

export const Element: React.FC<Props> = ({ sort, sortOptionName, handleSortChange, selected }) => {

  const onSortChange = useCallback(() => {
    handleSortChange(sortOptionName, sort.type);
  }, [sort, sortOptionName]);

  return (
    <Styled.SetElementStyle
      key={sort.name}
      onClick={onSortChange}
    >
      <RadioButton checked={selected} />
      <Text>{sort.name}</Text>
    </Styled.SetElementStyle>
  );
};
