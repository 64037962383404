const prefix = '@database-activity';

export const DatabaseActivityActionTypes = {
  GET_ACTIVITIES_REQUEST: `${prefix}/GET_ACTIVITIES_REQUEST`,
  GET_ACTIVITIES_SUCCEEDED: `${prefix}/GET_ACTIVITIES_SUCCEEDED`,
  GET_ACTIVITY_REQUEST: `${prefix}/GET_ACTIVITY_REQUEST`,
  GET_ACTIVITY_SUCCEEDED: `${prefix}/GET_ACTIVITY_SUCCEEDED`,
  CREATE_ACTIVITY_REQUEST: `${prefix}/CREATE_ACTIVITY_REQUEST`,
  CREATE_ACTIVITY_SUCCEEDED: `${prefix}/CREATE_ACTIVITY_SUCCEEDED`,
  UPDATE_ACTIVITY_REQUEST: `${prefix}/UPDATE_ACTIVITY_REQUEST`,
  UPDATE_ACTIVITY_SUCCEEDED: `${prefix}/UPDATE_ACTIVITY_SUCCEEDED`,
  DELETE_ACTIVITY_REQUEST: `${prefix}/DELETE_ACTIVITY_REQUEST`,
  DELETE_ACTIVITY_SUCCEEDED: `${prefix}/DELETE_ACTIVITY_SUCCEEDED`,
  DUPLICATE_ACTIVITY: `${prefix}/DUPLICATE_ACTIVITY`,
};
