import * as React from 'react';
import { RoutingContext } from './routing-context';


export interface RoutingContextProps {
  urls: any;
}

export function withRoutingContext<P extends RoutingContextProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof RoutingContextProps>>> {
  return class AbilityAwareComponent extends React.Component<Pick<P, Exclude<keyof P, keyof RoutingContextProps>>> {
    public render(): React.ReactNode {
      return (
        <RoutingContext.Consumer>
          {
            (context) => {
              return <Component {...this.props as P} urls={context && context.urls}/>;
            }
          }
        </RoutingContext.Consumer>
      );
    }
  };
}
