import autobind from 'autobind-decorator';
import * as React from 'react';

import { MenuItemProps } from './menu-item';

import './index.scss';

interface Props extends React.Props<KreoDropDownMenu> {
  children: Array<React.ReactElement<MenuItemProps>>;
  value: number | string;
  onChange?: (value: number | string) => void;
}

interface State {
  opened: boolean;
  selectedIndex: number;
}

const classPrefix = 'kreo-drop-down-menu';

export class KreoDropDownMenu extends React.PureComponent<Props, State> {


  constructor(props: Props) {
    super(props);
    const selectedIndex = props.children.findIndex((v) => v.props.value === props.value);
    this.state = {
      opened: false,
      selectedIndex,
    };
  }

  public render(): React.ReactNode {
    const childs = this.props.children.map((value) => {
      return React.cloneElement(value, { onSelect: this.onSelect });
    });
    if (this.state.opened) {
      return (
        <div className={`${classPrefix}__opened`}>
          <div className={`${classPrefix}__opened__list`}>
            {childs}
          </div>
        </div>);
    }
    return (
      <div
        className={`${classPrefix}__closed`}
        onClick={this.open}
      >
        {childs.length > 0 && childs[this.state.selectedIndex].props.children}
      </div>);
  }

  @autobind
  private open(): void {
    this.setState({
      opened: !this.state.opened,
    });
  }

  @autobind
  private onSelect(value: string | number): void {
    const selectedIndex = this.props.children.findIndex((v) => v.props.value === value);
    this.props.onChange(value);
    this.setState({
      selectedIndex,
      opened: false,
    });
  }
}


export * from './menu-item';
