import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyQ: React.FC = () => {
  return (
    <svg
      width='20px'
      height='22px'
      viewBox='0 0 20 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='hotkey_q' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Q'>
          <g id='background'>
            <g id='Group' transform='translate(0.000000, 2.000000)'>
              <g id='path-1-link' fill={KreoColors.f1}>
                <rect id='path-1' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
            <g id='Group'>
              <g id='path-2-link' fill={KreoColors.f1}>
                <rect id='path-2' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
          </g>
          <rect id='padding-left' x='0' y='6' width='6' height='10' />
          <rect id='padding-right' x='14' y='6' width='6' height='10' />
          <path
            // eslint-disable-next-line max-len
            d='M7.97050025,13.2684999 C7.21150013,12.5094998 7.18400012,11.7284996 7.18400012,10.0839994 C7.18400012,8.43949909 7.21150013,7.65849896 7.97050025,6.89949884 C8.47650033,6.39349875 9.13650044,6.10199871 10.0000006,6.10199871 C10.8580007,6.10199871 11.5180008,6.39349875 12.0240009,6.89949884 C12.783001,7.65849896 12.816001,8.43949909 12.816001,10.0839994 C12.816001,11.4919996 12.794001,12.2674997 12.310001,12.9384998 L12.706001,13.3344999 C12.8710011,13.4994999 12.9205011,13.5654999 12.9535011,13.6809999 C12.9920011,13.824 12.9480011,13.9835 12.821501,14.11 C12.695001,14.2365 12.535501,14.2805 12.392501,14.242 C12.277001,14.209 12.2110009,14.1595 12.0460009,13.9945 L11.6390009,13.5874999 C11.1935008,13.8955 10.6545007,14.066 10.0000006,14.066 C9.13650044,14.066 8.47650033,13.7745 7.97050025,13.2684999 Z M10.2750006,12.2179997 C10.1045006,12.0529997 10.0550006,11.9869997 10.0220006,11.8714997 C9.98350058,11.7284996 10.0275006,11.5689996 10.1540006,11.4424996 C10.2805006,11.3159996 10.4400007,11.2719996 10.5830007,11.3104996 C10.6985007,11.3434996 10.7645007,11.3929996 10.9295007,11.5579996 L11.3970008,12.0254997 C11.6005008,11.6239996 11.6280009,11.0849995 11.6280009,10.0839994 C11.6280009,8.5329991 11.5510008,8.05999903 11.1660008,7.64749896 C10.8745007,7.33949891 10.4730007,7.15799888 10.0000006,7.15799888 C9.5215005,7.15799888 9.12000044,7.33949891 8.83400039,7.64749896 C8.44350033,8.05999903 8.37200032,8.5329991 8.37200032,10.0839994 C8.37200032,11.6349996 8.44350033,12.1024997 8.83400039,12.5149998 C9.12000044,12.8229998 9.5215005,13.0099998 10.0000006,13.0099998 C10.2970006,13.0099998 10.5720007,12.9274998 10.8030007,12.7514998 L10.2750006,12.2179997 Z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
};
