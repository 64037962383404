const prefix = '@activity-grouping-step';

export const StepActionTypes = {
  SET_ACTIVITY_GROUPING_STEP_PAGE: `${prefix}/SET_ACTIVITY_GROUPING_STEP_PAGE`,
  LOAD_ACTIVITY_GROUPING: `${prefix}/LOAD_ACTIVITY_GROUPING`,
  LOAD_ACTIVITY_GROUPING_SUCCEEDED: `${prefix}/LOAD_ACTIVITY_GROUPING_SUCCEEDED`,
  LOAD_MACRO_SEQUENCE: `${prefix}/LOAD_MACRO_SEQUENCE`,
  LOAD_MACRO_SEQUENCE_SUCCEEDED: `${prefix}/LOAD_MACRO_SEQUENCE_SUCCEEDED`,
  UPDATE_ACTIVITY_GROUPING: `${prefix}UPDATE_ACTIVITY_GROUPING`,
  UPDATE_ACTIVITY_GROUPING_SUCCEEDED: `${prefix}/UPDATE_ACTIVITY_GROUPING_SUCCEEDED`,
  UPDATE_MACRO_SEQUENCE: `${prefix}UPDATE_MACRO_SEQUENCE`,
  UPDATE_MACRO_SEQUENCE_SUCCEEDED: `${prefix}/UPDATE_MACRO_SEQUENCE_SUCCEEDED`,
  DROP_ACTIVITY_GROUPING_STATE: `${prefix}/DROP_ACTIVITY_GROUPING_STATE`,
  SET_ACTIVE_WORK_PACKAGE: `${prefix}/SET_ACTIVE_WORK_PACKAGE`,
  SET_ACTIVE_ACTIVITY_GROUP_OR_UNGROUPED_ACTIVITY: `${prefix}/SET_ACTIVE_ACTIVITY_GROUP_OR_UNGROUPED_ACTIVITY`,
  SET_ACTIVE_CATEGORY: `${prefix}/SET_ACTIVE_CATEGORY`,
  SET_ACTIVE_ACTIVITY_OR_UNGROUPED_ACTIVITY: `${prefix}/SET_ACTIVE_ACTIVITY_OR_UNGROUPED_ACTIVITY`,
};
