import React from 'react';
import { QueryItem } from './query-item';
import { Styled } from './styled';

interface Props {
  queries: string[];
  runQuery: (query: string) => void;
}

const QueryLineComponent: React.FC<Props> = ({
  queries,
  runQuery,
}) => {
  return (
    <Styled.Container>
      {
        queries.map((query) => (
          <QueryItem
            key={query}
            value={query}
            onClick={runQuery}
          />
        ))
      }
    </Styled.Container>
  );
};

export const QueryLine = React.memo(QueryLineComponent);
