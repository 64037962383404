import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';

import './graph.scss';

import { DragScroll } from '../drag-scroll';
import { GraphItemDisplayType } from './enums';
import { GraphEdgeControls } from './graph-edge-controls';
import { GraphEdges } from './graph-edges';
import { EdgeIds, GraphPositions, PositionHelper } from './graph-position-helper';
import { GraphVertexes } from './graph-vertexes';
import {
  Coordinate,
  EdgeControlsComponentProps,
  EdgeWithDisplayType,
  GraphForNode,
  VertexComponentProps,
} from './interfaces';
import { PositionPxHelper, PositionPxHelperProvider } from './position-px-helper-provider';

export type AvailableLevels = 1 | 2;

interface Props {
  graphForNode: GraphForNode | null;
  vertexDisplayTypes: Record<number, GraphItemDisplayType> | null;
  highlightedItemId: number | null;
  levels: AvailableLevels;
  vertexComponent: React.ComponentType<VertexComponentProps>;
  edgeControlsComponent: React.ComponentType<EdgeControlsComponentProps>;
  vertexWidth: number;
  vertexMargin: number;
  readonly: boolean;
}

interface DragScrollPositionState {
  readonly position: Coordinate;
  readonly height: number;
  readonly width: number;
}

interface State {
  readonly positions: GraphPositions;
  readonly graphWidth: number;
  readonly graphHeight: number;
  readonly isCentered: boolean;
  readonly isGraphFreezed: boolean;
  readonly positionPxHelper: PositionPxHelper;
  readonly dragScroll: DragScrollPositionState;
}

export class Graph extends React.Component<Props, State> {
  private readonly edgeWidth: number = 80;
  private readonly yStep: number = 50;
  private dragScroll: DragScroll;

  constructor(props: Props) {
    super(props);

    const newState = this.getUpdatedState(null, this.props);
    this.state = {
      ...newState,
      isGraphFreezed: false,
    };
  }

  public componentDidMount(): void {
    this.centerGraph();
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    const canUpdate = !this.state.isGraphFreezed && this.state.isGraphFreezed === prevState.isGraphFreezed;
    const graphChanged = !isEqual(this.props.graphForNode, prevProps.graphForNode);
    if (
      canUpdate && (graphChanged || !isEqual(this.state, prevState))
    ) {
      const newState = this.getUpdatedState(prevProps, this.props);
      this.setState(newState);
      if (!isEqual(this.state.positions, prevState.positions)) {
        this.centerGraph();
      }
    }
  }

  public render(): JSX.Element {
    if (!this.props.graphForNode || !this.props.graphForNode.rootNode) {
      return null;
    }
    const windowPosition = this.getWindowPositions();
    const elementsForRender = PositionHelper.getWindowElements(
      this.state.positions, windowPosition.from, windowPosition.to,
    );
    const appendEdgeDisplayType = this.getAppendEdgeDisplayTypeFunction();
    const readonly = this.props.readonly;
    const edges = elementsForRender.edges.map(appendEdgeDisplayType);

    const result = (
      <div className='graph-viewer'>
        <DragScroll
          className='graph-viewer__graph-container'
          ref={this.makeDragScrollRef}
          block={this.state.isGraphFreezed}
          onPositionChanged={this.onPositionChanged}
          onResize={this.onResize}
        >
        {
          this.state.positions && this.props.graphForNode && this.props.graphForNode.rootNode ? (
            <div className='graph-viewer__graph'>
              <svg height={this.state.graphHeight} width={this.state.graphWidth}>
                <GraphEdges
                  edges={edges}
                  vertexPositions={this.state.positions.positions}
                  positionPxHelper={this.state.positionPxHelper}
                  vertexWidth={this.props.vertexWidth}
                />
              </svg>
              <GraphVertexes
                vertexIds={elementsForRender.vertexIds}
                vertexPositions={this.state.positions.positions}
                positionPxHelper={this.state.positionPxHelper}
                vertexWidth={this.props.vertexWidth}
                vertexMargin={this.props.vertexMargin}
                vertexComponent={this.props.vertexComponent}
                vertexDisplayTypes={this.props.vertexDisplayTypes}
                readonly={readonly}
              />
              {
                !readonly ? (
                  <GraphEdgeControls
                    edges={edges.filter(x => x.displayType === GraphItemDisplayType.Active)}
                    vertexPositions={this.state.positions.positions}
                    positionPxHelper={this.state.positionPxHelper}
                    vertexWidth={this.props.vertexWidth}
                    edgeControlsComponent={this.props.edgeControlsComponent}
                    onChangeEditStatus={this.onChangeEditStatus}
                  />
                ) : null
              }
            </div>
          ) : null
        }
        </DragScroll>
      </div>
    );

    return result;
  }

  @autobind
  private onChangeEditStatus(isEdit: boolean): void {
    this.setState({ isGraphFreezed: isEdit });
  }

  private getWindowPositions(): { from: Coordinate, to: Coordinate } {
    return {
      from: this.state.dragScroll.position,
      to: {
        x: this.state.dragScroll.position.x + this.state.dragScroll.width,
        y: this.state.dragScroll.position.y + this.state.dragScroll.height,
      },
    };
  }

  @autobind
  private makeDragScrollRef(component: DragScroll): void {
    this.dragScroll = component;
  }

  @autobind
  private onPositionChanged(xPx: number, yPx: number): void {
    const { x, y } = this.state.dragScroll.position;
    const newDragScrollState = { ...this.state.dragScroll };
    const positionPxHelper = this.state.positionPxHelper;
    newDragScrollState.position = {
      x: Math.floor(positionPxHelper.getX(xPx)),
      y: Math.floor(positionPxHelper.getY(yPx)),
    };

    if (x !== newDragScrollState.position.x || y !== newDragScrollState.position.y) {
      this.setState({ dragScroll: newDragScrollState });
    }
  }

  @autobind
  private onResize(heightPx: number, widthPx: number): void {
    const { height, width } = this.state.dragScroll;
    const newDragScrollState = { ...this.state.dragScroll };
    const positionPxHelper = this.state.positionPxHelper;
    newDragScrollState.height = Math.ceil(positionPxHelper.getY(heightPx) + 1);
    newDragScrollState.width = Math.ceil(positionPxHelper.getX(widthPx + positionPxHelper.getXPxPadding()));

    if (height !== newDragScrollState.height || width !== newDragScrollState.width) {
      this.setState({ dragScroll: newDragScrollState });
    }
  }

  private centerGraph(): void {
    if (this.dragScroll && this.dragScroll.center) {
      this.dragScroll.center();
    }
  }

  private getUpdatedState(previousProps: Props, currentProps: Props): State {
    const newState = { ...this.state };
    if (!previousProps ||
      this.props.vertexWidth !== previousProps.vertexWidth ||
      this.props.levels !== previousProps.levels
    ) {
      newState.positionPxHelper = PositionPxHelperProvider.getPositionPxHelper(
        this.edgeWidth,
        this.props.vertexWidth,
        this.yStep,
        this.props.levels,
      );
    }

    newState.isCentered = true;
    if (!previousProps || !isEqual(previousProps.graphForNode, currentProps.graphForNode)) {
      const graphForNode =  currentProps.graphForNode;
      if (!graphForNode || !graphForNode.rootNode) {
        newState.positions = null;
        newState.graphWidth = 0;
        newState.graphHeight = 0;
      } else {
        newState.positions = PositionHelper.getPositionsInfo(currentProps.graphForNode);
        newState.graphWidth = this.edgeWidth * (currentProps.levels * 2 + 2)
          + currentProps.vertexWidth * (currentProps.levels * 2 + 1);
        const pxHelper = newState.positionPxHelper;
        newState.graphHeight = pxHelper.getYPx(newState.positions.positions[graphForNode.rootNode.id].y + 1) * 2;
      }
    }
    if (!newState.dragScroll) {
      newState.dragScroll = {
        height: 0,
        width: 0,
        position: {
          x: 0,
          y: 0,
        },
      };
    }
    return newState;
  }

  private getEdgeDisplayTypeFunction(): (edge: EdgeIds) => GraphItemDisplayType {
    const {
      graphForNode: { rootNode: { id: rootId } },
      highlightedItemId: highlightedId,
    } = this.props;

    return (position: EdgeIds) => {
      const fromId = position.fromId;
      const toId = position.toId;

      if (fromId === rootId || toId === rootId) {
        return GraphItemDisplayType.Active;
      }
      if (fromId === highlightedId || toId === highlightedId) {
        return GraphItemDisplayType.Highlighted;
      }
      if (fromId === highlightedId && toId === rootId || fromId === rootId && toId === highlightedId) {
        return GraphItemDisplayType.Highlighted;
      }
      return GraphItemDisplayType.Normal;
    };
  }

  private getAppendEdgeDisplayTypeFunction(): (edge: EdgeIds) => EdgeWithDisplayType {
    const getDisplayType = this.getEdgeDisplayTypeFunction();
    return edge => ({
      ...edge,
      displayType: getDisplayType(edge),
    });
  }
}
