import { PiaCalculatedItems } from '2d/index';
import * as Pia from 'unit-2d-database/interfaces';
import { NOT_DEFINED_PROPERTY } from './constants';
import { CachedProperty, MeasuredValueGetter, PrecompiledFormulaApi, PropertyCalculator, Value } from './interfaces';
import { Item } from './item';

interface AssemblyConfig {
  items: Pia.Item[];
  name?: string;
  parent: PropertyCalculator;
  readonly originProperties: Record<string, Pia.Property>;
  getMeasuredValue: MeasuredValueGetter;
  precompiledFormulas?: PrecompiledFormulaApi;
}

export class Assembly implements PropertyCalculator {
  private _cachedProperties: Map<string, CachedProperty>;
  private _originProperties: Record<string, Pia.Property>;
  private _getMeasuredValue: MeasuredValueGetter;
  private _parent: PropertyCalculator;
  private _items: Item[];
  private _name: string;

  constructor({ items, name, parent, originProperties, getMeasuredValue, precompiledFormulas }: AssemblyConfig) {
    this._originProperties = originProperties;
    this._getMeasuredValue = getMeasuredValue;
    this._parent = parent;
    this._name = name;


    this._items = new Array<Item>(items.length);
    for (let i = 0; i < items.length; i++) {
      this._items[i] = new Item({
        item: items[i],
        parent: this,
        originProperties: this._originProperties,
        getMeasuredValue: this._getMeasuredValue,
        precompiledFormulas,
      });
    }
  }

  public getName(): string {
    return this._name;
  }

  public getPropertyNames(): IterableIterator<string> {
    this.initCache();
    return this._cachedProperties.keys();
  }

  public getPropertyValue(
    propertyName: string,
    formulaPath: Pia.Property[],
    withPercentageConversion: boolean,
  ): Value {
    this.initCache();
    if (this._cachedProperties.has(propertyName)) {
      const { isRef, value } = this._cachedProperties.get(propertyName);
      return isRef
        ? (value as Item).getPropertyValue(propertyName, formulaPath, withPercentageConversion)
        : value as Value;
    }
    return this._parent.getPropertyValue(propertyName, formulaPath, withPercentageConversion);
  }

  public calculate(): PiaCalculatedItems {
    const calculatedItems: PiaCalculatedItems = {};

    for (const item of this._items) {
      const calculatedItem = item.calculate();
      calculatedItems[item.getName()] = calculatedItem;
    }
    return calculatedItems;
  }

  private initCache(): void {
    if (this._cachedProperties) {
      return;
    }

    this._cachedProperties = new Map();
    for (const item of this._items) {
      for (const propertyName of item.getPropertyNames()) {
        if (!this._cachedProperties.has(propertyName)) {
          this._cachedProperties.set(propertyName, { isRef: true, value: item });
        } else {
          this._cachedProperties.set(propertyName, { isRef: false, value: NOT_DEFINED_PROPERTY });
        }
      }
    }
  }
}
