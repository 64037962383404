export type ValueStore =  Record<string, string | number>;
export class ReportCellValueStore {
  private store: ValueStore = {};

  public updateCell(id: string, value: string | number): void {
    const formatValue = typeof value === 'string'
      ? value.replace(new RegExp('\\r?\\n', 'g'), '')
      : value;
    this.store[id] = formatValue;
  }

  public selectCell(id: string): string | number {
    return this.store[id];
  }

  public setStore(store: ValueStore): void {
    this.store = store;
  }

  public getStore(): ValueStore {
    return this.store;
  }

  public deleteStore(id: string): string[] {
    const deleteCells = Object.keys(this.store).filter(k => k.startsWith(id));
    delete this.store[id];
    return deleteCells;
  }
}
