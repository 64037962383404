import { RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

import { RenderIf } from 'common/components/render-if';
import { Text } from 'common/components/text';
import { Mood } from 'common/enums/mood';
import { multiViewActions } from 'common/utils/multi-view/multi-view-reducers';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { Styled } from './styled';

interface Props {
  setShowMessage: (showMessage: boolean) => void;
  openInNewWindow: () => void;
  canReopen: boolean;
}

export const LostConnectionDialog: React.FC<Props> = ({ setShowMessage, openInNewWindow, canReopen }) => {
  const dispatch = useDispatch();
  const { sendEvent } = useAnalytics();
  const canvasContainerEl = document.getElementById('canvas-container');

  const handleClickReopen = useCallback(() => {
    sendEvent(MetricNames.reports.openReportInNewWindow, { status: 'Re-open' });
    openInNewWindow();
    setShowMessage(false);
  }, [openInNewWindow, sendEvent, setShowMessage]);

  const handleClickCancel = useCallback(() => {
    sendEvent(MetricNames.reports.openReportInNewWindow, { status: 'Close' });
    dispatch(multiViewActions.setConnection(null));
  }, [dispatch, sendEvent]);

  return createPortal(
    <Styled.Message>
      <Text fontSize={14}>The window has lost connection</Text>
      <RenderIf condition={canReopen}>
        <RectangleButton
          text={'Re-open'}
          fontSize={14}
          height={30}
          mood={Mood.Positive}
          onClick={handleClickReopen}
        />
      </RenderIf>
      <RectangleButton
        text={'Cancel'}
        fontSize={14}
        height={30}
        onClick={handleClickCancel}
      />
    </Styled.Message>,
    canvasContainerEl,
  );
};
