import { Text } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';

import { RenderIf } from 'common/components/render-if';
import { ProgressBarState, ProgressBarType } from '../../interfaces';
import { InfinityLoader } from '../infinity-loader';
import { ProgressLoader } from '../progress-loader';
import { Styled } from './styled';

interface Props {
  progressBar: ProgressBarState;
  customProgressTextGetter: (progressBar: ProgressBarState) => string;
}

const getLoader = (progressBar: ProgressBarState, customProgressText: string): React.ReactNode => {
  switch (progressBar.type) {
    case ProgressBarType.Indeterminate:
      return (<InfinityLoader title={progressBar.progressTitle}/>);
    case ProgressBarType.Percentage:
      return (
        <ProgressLoader
          percentage={true}
          finished={progressBar.finished}
          total={progressBar.total}
          progressTitle={progressBar.progressTitle}
          customProgressText={customProgressText}
        />
      );
    case ProgressBarType.Count:
      return (
        <ProgressLoader
          percentage={false}
          finished={progressBar.finished}
          total={progressBar.total}
          progressTitle={progressBar.progressTitle}
          customProgressText={customProgressText}
        />
      );
    default:
      return 0;
  }
};

const LoaderComponent: React.FC<Props> = ({ progressBar, customProgressTextGetter }) => {
  const customText = useMemo(() => {
    return customProgressTextGetter ? customProgressTextGetter(progressBar) : null;
  }, [progressBar, customProgressTextGetter]);

  const loader = getLoader(progressBar, customText);

  return (
    <Styled.Container>
      <RenderIf condition={!!progressBar.title}>
        <Text
          fontSize={12}
        >
          {progressBar.title}
        </Text>
      </RenderIf>
      { loader }
    </Styled.Container>
  );
};


export const Loader = React.memo(LoaderComponent);
