import styled from 'styled-components';

import { BadgeStyled } from 'common/components/badge';
import { FormFieldStyled } from 'common/components/form-fields/form-field';
import { Text } from 'common/components/text';

const Tooltip = styled.div`
  display: none;
  width: auto;
  max-width: 188px;
  position: absolute;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border-radius: 10px;
  background: ${p => p.theme.color.backgroundRush};
  box-shadow: 0 0 0 1px ${p => p.theme.color.background};
  padding: 8px;
  left: -4px;
  z-index: 3;
  transform: translate(0, -100%);
`;

const TooltipText = styled.div`
  height: fit-content;
  max-width: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;

  ${BadgeStyled.BadgeContainer} {
    height: 100%;
    max-width: 100%;
    border-radius: 4px;
    box-shadow: none;
    margin-left: 2px;
    padding: 0 4px;

    ${Text} {
      max-width: 100%;
      font-size: 12px;
      line-height: 18px;
      color: ${p => p.theme.color.white} !important;
    }
  }

  ${Text} {
    color: ${p => p.theme.color.mainFont} !important;
    background: unset !important;
    border-radius: unset !important;
  }
`;

const TooltipButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    > svg {
      margin-right: 10px;
    }

    > p {
      font-size: 12px;
      line-height: 1px;
    }
  }
`;

const Container = styled.div<{ colored: boolean }>`
  position: relative;

  ${FormFieldStyled.Container} {
    > p {
      width: fit-content;
      background: ${p => p.theme.color.pale};
      border-radius: 4px;
      margin-left: -4px;
      padding: 0 4px;
      transition: all .1s ease-in;
    }
  }

  :hover {
    ${Tooltip} {
      display: grid;
    }

    ${FormFieldStyled.Container} {
      > p {
        color: ${p => p.theme.color.turquoise};
      }
    }
  }
`;

export const Styled = {
  Container,
  Tooltip,
  TooltipText,
  TooltipButton,
};
