import autobind from 'autobind-decorator';
import * as React from 'react';


import { IconButton } from '../buttons';
import { KreoIconCancel } from '../icons';


import './card-info.scss';


class CardInfo extends React.Component<any> {
  public static defaultProps = {
    hasRemoved: false,
    onRemove: () => {console.warn();},
  };

  public render(): any {
    return (
      <div className="kreo-card-info">
        {this.props.hasRemoved &&
          <IconButton
            className="remove-button"
            icon={<KreoIconCancel/>}
            onClick={this.handleClick}/>
        }
        <div className="last">**** **** **** {this.props.card.last4}</div>
      </div>
    );
  }

  @autobind
  private handleClick(): void {
    this.props.onRemove(this.props.card.id);
  }
}

export default CardInfo;
