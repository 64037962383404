import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './resource-selector.scss';

import { State } from 'common/interfaces/state';
import { KreoIconExpand } from 'common/UIKit/icons';
import { ResourceDescription } from '../../../../utils/gantt-chart';
import { actions } from './actions';

interface SelectorOwnProps {
  chartId: number;
}

interface SelectorStateProps {
  resource: ResourceDescription | null;
  isPreviewing: boolean;
}

interface SelectorDispatchProps {
  openPopup: (clientX: number, clientY: number) => void;
}

interface SelectorProps extends SelectorOwnProps, SelectorStateProps, SelectorDispatchProps { }

class ResourceSelectorComponent extends React.Component<SelectorProps> {
  private selectorRef: HTMLDivElement = null;

  public render(): React.ReactNode {
    const selectorName = this.props.resource ? this.props.resource.displayName : 'Select resource...';
    return (
      <div
        className='resource-selector'
        onClick={this.onSelectorClick}
        ref={this.setSelectorRef}
      >
        <span className='resource-selector__name' title={selectorName}>
          { this.props.isPreviewing ? 'Preview: ' : '' }
          { selectorName }
        </span>
        <KreoIconExpand />
      </div>
    );
  }

  @autobind
  private setSelectorRef(ref: HTMLDivElement): void {
    this.selectorRef = ref;
  }

  @autobind
  private onSelectorClick(event: React.MouseEvent<HTMLDivElement>): void {
    if (event.button !== 0) {
      return;
    }

    event.stopPropagation();

    const selectorRect = this.selectorRef.getBoundingClientRect();
    this.props.openPopup(selectorRect.left + selectorRect.width, selectorRect.top);
  }
}

const mapStateToProps = (state: State, ownProps: SelectorOwnProps): SelectorStateProps => {
  const chartState = state.fourDVisualisation.sidePanel.resourcesTab.charts[ownProps.chartId];
  const isPreviewing = chartState ? !!chartState.previewedResource : false;
  const resourceToRenderIdentifier = chartState
    ? chartState.previewedResource || chartState.selectedResource
    : null;
  const resource = resourceToRenderIdentifier
    ? state.fourDVisualisation.resources[resourceToRenderIdentifier.type].get(resourceToRenderIdentifier.id)
    : null;

  return {
    resource,
    isPreviewing,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: SelectorOwnProps): SelectorDispatchProps => {
  return {
    openPopup: (clientX: number, clientY: number) => {
      dispatch(actions.openPopup(ownProps.chartId, clientX, clientY));
    },
  };
};

export const ResourceSelector = connect(mapStateToProps, mapDispatchToProps)(ResourceSelectorComponent);
