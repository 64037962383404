import { GridApi } from 'ag-grid-community';

import { AgGridHelper } from 'common/ag-grid';
import { GraphStorageRecordsConfig } from '../../interfaces/graph-storage-records-config';
import { QTOLeftTableTabMapper } from './quantity-take-off-left-table-tab-mapper';


export class QtoLocationTabMapper extends QTOLeftTableTabMapper {
  constructor(columnConfig: GraphStorageRecordsConfig) {
    super(columnConfig);
  }

  protected getAggFields(api: GridApi): Record<string, boolean> {
    const result = {};
    const rowGroupColumns = AgGridHelper.getColumnController(api).getRowGroupColumns();

    rowGroupColumns.forEach(col => {
      const columnId = col.getColId();
      result[columnId] = true;
    });

    return result;
  }
}
