import { push, RouterLocation } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { AbilityRoute } from 'common/ability/ability-route';
import { Subject } from 'common/ability/subject';
import { ReconnectDialogWrapper } from 'common/components/reconnect-dialog-wrapper';
import { SubscriptionType } from 'common/constants/subscription';
import { State } from 'common/interfaces/state';
import { RoutingContextProvider } from 'common/routing/routing-context';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { UpgradePageRouter } from 'routes/common/subscription-routes-wrap/upgrade-page-router';
import { SpinnerPage } from 'routes/spinner-page';
import { TwoDDatabasePage } from 'unit-2d-database/2d-database-page';
import { TwoDReportTemplatePage } from 'unit-2d-report-template/2d-report-template-page';
import { TwoDProjectsPage } from '../../units/2d-projects-page';
import { TwoDTemplatesPage } from '../../units/2d-templates-page';
import { Company } from '../../units/account/interfaces/company';
import { PageNotFound2D } from '../../units/page-not-found-2d';
import { QuestionnairePage } from '../../units/questionnaire-page';
import { CancellationSurveyPage } from '../../units/subscription/cancellation-survey-page';
import { ThankYouForTrialPage } from '../../units/subscription/pages/thank-you-for-trial-page';
import * as Subscription from '../common/subscription-routes-wrap';
import { TwoDProjectFastNavigation } from './route-fast-navigation';
import { ReportRoute } from './route-fast-navigation/report';
import { Qto2dProjectRouteParams } from './route-params';
import { Urls } from './urls';

interface StateProps {
  showQuestionnaire: boolean;
  currentLocation: RouterLocation<any>;
  companies: Company[];
}

interface DispatchProps {
  setLastCurrentPathname: (path: string) => void;
  setQuestionnairePath: (path: string) => void;
}

interface RoutesProps extends RouteComponentProps<Qto2dProjectRouteParams>, StateProps, DispatchProps {

}

const InnerSwitch = (): JSX.Element => (
  <Switch>
    <Redirect exact={true} from={Urls.index.path} to={Urls.listing.path} />
    <Route exact={true} path={Urls.questionnaire.path} component={QuestionnairePage} />
    <Route exact={true} path={Urls.thankYou.path} component={ThankYouForTrialPage} />
    <Route exact={true} path={Urls.pageNotFound.path} component={PageNotFound2D} />
    <Route exact={true} path={Urls.listing.path} component={TwoDProjectsPage} />
    <Route exact={true} path={Urls.templates.path} component={TwoDTemplatesPage}/>
    <Route exact={true} path={Urls.database.path} component={TwoDDatabasePage} />
    <Route exact={true} path={Urls.reportTemplates.path} component={TwoDReportTemplatePage} />
    <Route exact={true} path={Urls.project.report.path} component={ReportRoute} />
    <Redirect exact={true} from={Urls.project.index.path} to={Urls.project.quantityTakeOff.path} />
    <Route path={Urls.project.index.path} component={TwoDProjectFastNavigation} />
    <Route exact={true} path={Urls.cancellationSurvey.path} component={CancellationSurveyPage} />
    <UpgradePageRouter urls={Urls}/>
  </Switch>
);

class RoutesComponent extends React.Component<RoutesProps> {
  public componentDidMount(): void {
    if (this.props.showQuestionnaire) {
      this.props.setQuestionnairePath(Urls.questionnaire.path);
    }
    // this.props.setLastCurrentPathname(this.props.currentLocation.pathname);
  }

  public render(): React.ReactNode {
    if (!this.props.companies) {
      return (<SpinnerPage />);
    }
    return (
      <RoutingContextProvider urls={Urls}>
        <Subscription.SubscriptionWrap
          urls={Urls}
          subscriptionType={SubscriptionType.Takeoff2d}
          canShowQuestionnaire={true}
        >
          <ReconnectDialogWrapper>
            <AbilityRoute
              path={Urls.index.path}
              subject={Subject.QuantityTakeOff2d}
              component={InnerSwitch}
            />
          </ReconnectDialogWrapper>
        </Subscription.SubscriptionWrap>
      </RoutingContextProvider>
    );
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    showQuestionnaire: state.account.showQuestionnaire,
    currentLocation: state.router.location,
    companies: state.account.companies,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setLastCurrentPathname: (path) => dispatch(PersistedStorageActions.setCurrentPathname(path)),
    setQuestionnairePath: (path) => dispatch(push(path)),
  };
}

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const Routes = withRedux(RoutesComponent);
