import styled from 'styled-components';

import { OptionContainerStyled } from 'common/components/selection-menus';
import { TagsInputStyled } from 'common/components/tag-input';
import { TRANSITION_POINT } from '../../../../constants';
import { BillingAddressStyled } from '../payment-method-manager/billing-address';
import { ApplyButtonStyled } from '../subscription-details/apply-button';
import { CouponsStyled } from '../subscription-details/coupons';
import { SubscriptionPriceStyled } from '../subscription-details/subscription-price';
import { TeamSizeStyled } from '../subscription-details/team-size';
import { BillingAddressBlockStyled } from './billing-address-block';
import { PaymentManagerBlockStyled } from './payment-manager-block';
import { TeamSizeBlockStyled } from './team-size-block';


const Container = styled.div`
  display: flex;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar-thumb {
      background: ${p => p.theme.color.disabled};
    }
    ::-webkit-scrollbar-thumb:active {
      background: ${p => p.theme.color.gray} !important;
    }
    ::-webkit-scrollbar-track {
      background: ${p => p.theme.color.background};
    }
  }
`;

const ReviewCard = styled.div`
  width: 590px;
  box-sizing: border-box;
  padding: 40px 25px 37px 25px;
  border-radius: 30px;
  align-self: flex-start;
  background-color: ${p => p.theme.color.backgroundRush};

  h5 {
    margin-bottom: 25px;
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 10px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    display: none;
  }
`;

const PlanCard = styled.div<{ isActiveButton: boolean }>`
  width: 270px;
  margin-left: 30px;
  padding: 25px 20px;
  border-radius: 30px;
  align-self: flex-start;
  box-sizing: border-box;
  background-color: ${p => p.theme.color.backgroundRush};

  ${SubscriptionPriceStyled.Price} {
    margin-top: 0;
    margin-bottom: 5px;
  }

  ${OptionContainerStyled.Container} {
    display: flex;
    margin-bottom: 10px;
  }

  ${ApplyButtonStyled.Container} {
    display: flex;
    padding-top: 10px;
    padding-bottom: 15px;

    > button {
      background-color: ${p => p.isActiveButton && p.theme.color.green};
    }
  }

  ${TagsInputStyled.Container} {
    border-radius: 10px;
  }

  ${CouponsStyled.Container} {
    margin-top: 0;
  }

  button {
    width: 100%;
  }

  button + p {
    margin-top: 20px;
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin-left: 0;
    padding: 10px;

    ${ApplyButtonStyled.Container} {
      padding-top: 15px;
    }
  }
`;

const PaymentDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  > p {
    display: inline-flex;
    flex-direction: row;
    margin-top: 10px;
  }
`;

const ProgressBarContainer = styled.div`
  > div {
    background-color: ${p => p.theme.color.background};
    border-radius: 3px;
    margin-bottom: 15px;

    ::after {
      content: '';
      background-color: ${p => p.theme.color.green};
      border-radius: 3px;
    }
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 530px;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    width: 100%;

    ${BillingAddressBlockStyled.ContentContainer} {
      width: 100%;

      ${BillingAddressStyled.Column} {
        width: 100%;
      }

      ${BillingAddressStyled.Container} {
        flex-direction: column;
        gap: 10px;
      }

      ${BillingAddressStyled.VatCountryLabel} {
        margin-left: 0;
        font-size: 14px;
      }

      ${BillingAddressStyled.InputContainer} {
        width: 100%;

        > label {
          width: 100%;
        }
      }
    }

    ${PaymentManagerBlockStyled.ContentContainer} {
      width: 100%;

      > p {
        margin-bottom: 10px;
      }
    }

    ${PaymentManagerBlockStyled.PaymentManagerWrapper} {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      > div {
        > div:first-child {
          flex-direction: column;

          > p {
            position: absolute;
            left: 80px;
          }

          > div:last-child {
            margin: 0;
            margin-top: 5px;
          }
        }

        > div:last-child {
          flex-direction: column-reverse;
          align-items: flex-start;
          margin-top: 10px;

          > div:last-child {
            margin: 0;
            width: 100%;
            min-width: 100%;
          }
        }
      }

      
      ${PaymentManagerBlockStyled.Info} {
        flex-direction: row !important;
      }
    }

    ${PaymentManagerBlockStyled.PaymentManagerInfo} {
      margin: 0;
      gap: 10px;
    }

    ${TeamSizeBlockStyled.ContentContainer} {
      width: 100%;

      ${TeamSizeStyled.CounterText} {
        width: 100%;
      }
    }
  }
`;

const Line = styled.div<{ isCutLine: boolean }>`
  position: absolute;
  top: 15px;
  left: 14px;
  width: 3px;
  height: ${p => p.isCutLine ? 70 : 60}%;
  background-color: ${p => p.theme.color.background};
  z-index: 0;
`;

const Block = styled.div`
  width: 100%;
  display: flex;
`;

const CloseButtonContainer = styled.div`
  height: 30px;
  position: relative;
  top: -30px;
  border-radius: 50%;
  background-color: ${p => p.theme.color.backgroundRush};

  @media all and (max-width: ${TRANSITION_POINT}px) {
    display: none;
  }
`;

const MobileHeader = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    display: flex;
  }
`;

const MobileCloseButton = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${p => p.theme.color.backgroundRush};
`;

const MobileHeaderWrapper = styled.div`
  h5 {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const Styled = {
  Container,
  ReviewCard,
  HeaderWrapper,
  ContentWrapper,
  Line,
  Block,
  PlanCard,
  ProgressBarContainer,
  PaymentDetails,
  CloseButtonContainer,
  MobileHeader,
  MobileCloseButton,
  MobileHeaderWrapper,
};
