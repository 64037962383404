import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import {
  AiRequestQuality,
  DrawingsShortInfo,
  ShortPointDescription,
  WizzardToolsState,
} from 'common/components/drawings/interfaces';
import {
  PdfGeometryResponse,
  PdfGeometryStatus,
} from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { CursorHintType } from 'common/components/drawings/layout-components/drawings-cursor-hint';
import { FinishedDrawingElement } from '../../drawings-geometry-entities/temporary';
import { BatchedUpdateCallback, SetCursorHintCallback } from '../../interfaces';
import { QualityDPI } from './bucket-ai-constants';
import { BucketAIHoverPreview } from './bucket-ai-hover-preview';
import { BucketAIPreview } from './bucket-ai-preview';
import { BucketAIBase, BucketAIWithProcessingConfig, PointsInfo } from './bucket-ai-with-processing';

interface BucketAIConfig extends BucketAIWithProcessingConfig {
  wizzardSettingsObserver: ContextObserver<WizzardToolsState>;
  onBatchUpdateGeometries: BatchedUpdateCallback;
  setCursorMessage: SetCursorHintCallback;
  getCurrentDrawingInfo: () => DrawingsShortInfo;
  oneClickAreaHoverContextObserver: ContextObserver<boolean>;
}

interface Snapshot extends PointsInfo {
  result?: PdfGeometryResponse;
  window?: ShortPointDescription[];
}

export class BucketAI extends BucketAIBase<BucketAIConfig> {
  private _preview: BucketAIPreview;
  private _snapshots: Snapshot[] = [];
  private _hoverPreview: BucketAIHoverPreview;
  private _lastWindow: ShortPointDescription[][];

  constructor(config: BucketAIConfig) {
    super(config);
    this._hoverPreview = new BucketAIHoverPreview(config);
  }

  public isValid(): boolean {
    if ((window as any).BUCKET_POLYLINE) {
      return true;
    }
    return this._preview?.validate();
  }

  public clear(): void {
    this._points.forEach((point) => point.destroy());
    this._points = [];
    this._preview?.destroy();
  }

  public override destroy(): void {
    super.destroy();
    this._preview?.destroy();
    this._hoverPreview.destroy();
    this._windowsHelper?.destroy();
  }

  public hasGeometies(): boolean {
    return this._points.length > 0;
  }

  public getGeometry(): FinishedDrawingElement[] {
    return this._preview.getGeometry();
  }

  public onHover(point: paper.Point): void {
    if (!this._config.oneClickAreaHoverContextObserver.getContext() || this._snapshots.length) {
      return;
    }
    this._hoverPreview.findMask(point);
  }

  public removeLastPoint(): [paper.Point, Promise<PdfGeometryResponse>] {
    if (this._points.length > 0) {
      const lastPoint = this._points[this._points.length - 1].paperPosition;
      if (this._preview) {
        this._preview.destroy();
        this._preview = null;
      }
      if (this._canceller) {
        this._canceller();
      }
      this._points.pop().destroy();
      this._snapshots.pop();
      const lastSnapshot = this._snapshots[this._snapshots.length - 1];
      return [lastPoint, this.applySnapshot(lastSnapshot)];
    }
    return null;
  }

  public async addPoint(point: paper.Point): Promise<PdfGeometryResponse> {
    let isPositive = false;
    let isValid = true;
    this._hoverPreview.clean();

    if (this._snapshots.length > 0) {
      if (this._masks?.masksCount) {
        isPositive = !this._masks.isInsideMasks(point);
        if (isPositive) {
          isValid = !this._windowsHelper
            || (await this._windowsHelper.isPointInWindow(point, this._lastWindow) ?? true);
        }
      } else {
        isPositive = this._preview?.isPointOutsideLastPolygon(point) ?? true;
        if (isPositive && this._windowsHelper && this._preview) {
          isValid = await this._windowsHelper.isPointInWindow(
            point,
            this._lastWindow,
          );
        }
      }
    } else {
      isPositive = true;
    }

    if (!isValid) {
      this._config.setCursorMessage(CursorHintType.BucketAIOutOffArea);
      return {
        status: PdfGeometryStatus.IGNORE,
        geometries: [],
        response: null,
      };
    }

    if (this._canceller) {
      this._canceller();
    }

    this._points.push(this.createPoint(point, isPositive));
    const snapshot: Snapshot = this.getPoints();
    this._snapshots.push(snapshot);
    const result = await this.runForCurrentPoints();
    if (result.status === PdfGeometryStatus.Empty) {
      this._snapshots.pop();
      return result;
    } else {
      snapshot.result = result;
      this._lastWindow = result.response.output.windowPolygon;
    }
    return result;
  }


  protected override async showMask(countours: ShortPointDescription[][]): Promise<void> {
    await super.showMask(countours);
    if (this._preview) {
      this._preview.destroy();
      this._preview = null;
    }
  }

  protected async runForCurrentPoints(): Promise<PdfGeometryResponse> {
    const drawingInfo = this._config.getCurrentDrawingInfo();
    const { quality = AiRequestQuality.Auto } = this._config.wizzardSettingsObserver.getContext();
    const result = await this.getBucketResults({
      isAuto: quality === AiRequestQuality.Auto,
      drawingInfo,
      dpi: QualityDPI[quality],
    });
    if (this._destroyed) {
      return;
    }
    if (result.status === PdfGeometryStatus.Empty) {
      this._points.pop().destroy();
      return result;
    }
    this._snapshots[this._snapshots.length - 1].result = result;
    this._lastWindow = result.response.output.windowPolygon;
    this.renderPreview(result);
    return result;
  }

  private async applySnapshot(snapshot: Snapshot): Promise<PdfGeometryResponse> {
    this._points.forEach((point) => point.destroy());
    if (!snapshot) {
      this._lastWindow = null;
      return Promise.resolve(null);
    }
    this._points = snapshot.points.map((point, index) => this.createPoint(point, snapshot.statuses[index].positive));
    if (snapshot.result) {
      this.renderPreview(snapshot.result);
      this._lastWindow = snapshot.result.response.output.windowPolygon;
      return snapshot.result;
    } else {
      const withResult = this._snapshots.reduce((acc, v) => v.result ? v : acc, null);
      if (withResult) {
        this._lastWindow = withResult.result.response.output.windowPolygon;
      }
      return this.runForCurrentPoints();
    }
  }

  private renderPreview(result: PdfGeometryResponse): void {
    if (!this._preview) {
      this._preview = new BucketAIPreview({
        layer: this._resultGroup,
        newDrawingStylesObserver: this._config.newDrawingStylesObserver,
        renderParamsContextObserver: this._config.renderParamsContextObserver,
        colorCache: this._config.colorCache,
      });
    }

    this._preview.setGeometry({
      contours: result.geometries.map((x) => [x.points, ...(x.holes || [])]),
    });
  }
}
