export enum FilterByReportData {
  UnusedElements = 'UnusedElements',
  DuplicateElements = 'DuplicateElements',
}

const filterUnusedElements = (id: number, elementIdUsedInReport: Record<string, boolean>): boolean => {
  return !elementIdUsedInReport[id];
};

const filterDuplicateElements = (
  id: number,
  _: Record<string, boolean>,
  duplicatedNodeIds: Record<string, boolean>,
): boolean => {
  return duplicatedNodeIds[id];
};

export const filterByReportData: Record<
  FilterByReportData,
  (id: number, elementIdUsedInReport: Record<string, boolean>, duplicatedNodeIds: Record<string, boolean>) => boolean
> = {
  [FilterByReportData.UnusedElements]: filterUnusedElements,
  [FilterByReportData.DuplicateElements]: filterDuplicateElements,
};
