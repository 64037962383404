import classNames from 'classnames';
import React, { useMemo } from 'react';

import './file-upload-wrap.scss';

import { UploadingFile } from 'common/interfaces/common-state';
import { LineFile } from '../../../components/dialog/fields';
import { SvgSpinner } from '../svg-spinner';
import { FileTitle } from './file-title';

interface Props {
  isDownloadFileInProgress?: boolean;
  formName: string;
  validate?: Array<(value: string) => string>;
  onUploadStarted?: (file: File[]) => void;
  onDeleteFile?: (file: UploadingFile) => void;
  extensions: string[];
  extensionsToShow: string[];
  children?: React.ReactNode;
}

const FileUploadWrapComponent: React.FC<Props> = ({
  isDownloadFileInProgress,
  onUploadStarted,
  formName,
  validate,
  onDeleteFile,
  extensions,
  extensionsToShow,
}) => {
  const projectFileExtension = useMemo(() => extensions.map((x) => `.${x}`).join(', '), [extensions]);

  const fileTitle = useMemo(() => {
    if (isDownloadFileInProgress) {
      return 'downloading project...';
    } else {
      return (
        <FileTitle extensionsToShow={extensionsToShow} />
      );
    }
  }, [isDownloadFileInProgress, extensionsToShow]);

  return (
    <div className={classNames('file-upload-wrap', { 'file-upload-wrap--download': isDownloadFileInProgress })}>
      <LineFile
        name="fileName"
        form={formName}
        title={fileTitle}
        validate={validate}
        accept={projectFileExtension}
        onUploadStarted={onUploadStarted}
        onDeleteFile={onDeleteFile}
        disabled={isDownloadFileInProgress}
        extensionsToShow={extensionsToShow}
      />
      {isDownloadFileInProgress ? <SvgSpinner size="small" /> : null}
    </div>
  );
};

export const FileUploadWrap = React.memo(FileUploadWrapComponent);
