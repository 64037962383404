import { ProjectResourcesApi } from '../../../../api/server';
import { MagicSearchPayload } from '../interfaces/api-payloads';
import { AISnappingForm } from '../interfaces/api-payloads/ai-snapping';
import { AISnappingResponse } from '../interfaces/api-responses/ai-snapping';
import { MagicSearchResponse } from '../interfaces/api-responses/magic-search-responses';

function run(
  fileId: string,
  pageId: string,
  request: MagicSearchPayload,
): Promise<string> {
  return ProjectResourcesApi.post(
    `/drawings/files/${fileId}/pages/${pageId}/geometries/ai-search`,
    { input: request },
  ).then(({ id }: { id: string }) => id);
}

function stream(
  fileId: string,
  pageId: string,
  streamId: string,
  confidence: number,
): Promise<MagicSearchResponse> {
  return ProjectResourcesApi.get(
    // eslint-disable-next-line max-len
    `/drawings/files/${fileId}/pages/${pageId}/geometries/ai-search/${streamId}/stream?snappingConfidence=${confidence}`,
  );
}

function snapping(
  fileId: string,
  pageId: string,
  form: AISnappingForm,
): Promise<AISnappingResponse> {
  return ProjectResourcesApi.post(
    `/drawings/files/${fileId}/pages/${pageId}/geometries/snapping`,
    form,
  );
}

function cancelStream(
  fileId: string,
  pageId: string,
  streamId: string,
): Promise<void> {
  return ProjectResourcesApi.post(
    `/drawings/files/${fileId}/pages/${pageId}/geometries/ai-search/${streamId}/cancel`,
  );
}

export const MagicSearchApi = {
  run,
  stream,
  snapping,
  cancelStream,
};
