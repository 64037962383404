import { numberUtils } from 'common/utils/number-utils';
import { UserBidPricingStatus } from '../../../enums/user-bid-pricing-status';
import { BidPricingUserCost } from '../../../interfaces/bid-pricing/bid-pricing-user-cost';

export function getWinner(subcontractors: BidPricingUserCost[]): BidPricingUserCost {
  const winner = subcontractors.filter(subcontractor => {
    return subcontractor.status === UserBidPricingStatus.Winner;
  });
  if (winner.length > 0) {
    return winner[0];
  }
  return null;
}

export function formatCostValue(value: number): string {
  if (!value) {
    return '';
  }

  return `${numberUtils.getNumeralFormatter(value)
    .format('0,0')
    .replace(/,/g, ' ')}`;
}
