const POINT_COMPARISON_THRESHOLD = 1e-10;

function arePointsEqual(point1: paper.Point, point2: paper.Point): boolean {
  return Math.abs(point1.subtract(point2).length) < POINT_COMPARISON_THRESHOLD;
}

function isPointOnLine(lineStart: paper.Point, lineEnd: paper.Point, point: paper.Point): boolean {
  const isBetweenX = (point.x >= Math.min(lineStart.x, lineEnd.x) && point.x <= Math.max(lineStart.x, lineEnd.x));
  const isBetweenY = (point.y >= Math.min(lineStart.y, lineEnd.y) && point.y <= Math.max(lineStart.y, lineEnd.y));

  const crossProduct = (point.y - lineStart.y) * (lineEnd.x - lineStart.x)
    - (point.x - lineStart.x) * (lineEnd.y - lineStart.y);
  const isCollinear = Math.abs(crossProduct) < POINT_COMPARISON_THRESHOLD;

  return isBetweenX && isBetweenY && isCollinear;
}


function sortPointsByDestination(anchorPoint: paper.Point, points: paper.Point[]): paper.Point[] {
  return points.sort((p1, p2) => anchorPoint.subtract(p1).length - anchorPoint.subtract(p2).length);
}

function sortEntityWithPointByDestination<T>(
  anchorPoint: paper.Point,
  entities: T[],
  pointGetter: (entity: T) => paper.Point,
): T[] {
  return entities.sort(
    (p1, p2) => anchorPoint.subtract(pointGetter(p1)).length - anchorPoint.subtract(pointGetter(p2)).length);
}

export const PaperPointUtils = {
  arePointsEqual,
  pointOnLine: isPointOnLine,
  isPointOnLine,
  sortPointsByDestination,
  sortEntityWithPointByDestination,
  POINT_COMPARISON_THRESHOLD,
};
