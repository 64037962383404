import { ContextMenu } from '@kreo/kreo-ui-components';
import classNames from 'classnames';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOut?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
  getDropDownContainerRef?: (ref: HTMLDivElement) => void;
  style?: React.CSSProperties;
  themeStyle?: boolean;
  displayModeSelect?: boolean;
  zIndex?: number;
}

export class MultilevelDropDownContainer extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      className,
      displayModeSelect,
      children,
      onClick,
      style,
      onMouseOut,
      onMouseOver,
      getDropDownContainerRef,
      onMouseDown,
      themeStyle,
      zIndex,
    } = this.props;
    return (
      <Styled.Container
        themeStyle={themeStyle}
        displayModeSelect={displayModeSelect}
        // TODO: KREOP-11569 - remove classNames
        className={classNames('multi-level-select-drop-down-container', className)}
        ref={getDropDownContainerRef}
        onClick={onClick}
        style={style}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onMouseDown={onMouseDown}
        zIndex={zIndex}
      >
        {themeStyle ? <ContextMenu optionContainer={true}>{children}</ContextMenu> : children}
      </Styled.Container>
    );
  }
}
