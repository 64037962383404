import { useCallback, useEffect, useRef, useState } from 'react';
import { Message } from 'unit-2d-copilot/interfaces';

const SCROLL_THRESHOLD = 50;

export function useScroll(
  history: Message[],
  tempMessage: string,
): {
  lastScroll: number,
  onScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void,
  containerRef: React.RefObject<HTMLDivElement>,
} {
  const containerRef = useRef<HTMLDivElement>(null);

  const [lastScroll, setScroll] = useState<number>(null);

  useEffect(() => {
    if (containerRef?.current && lastScroll === null) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [history, tempMessage, lastScroll]);

  const onScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = e.target as HTMLDivElement;
      if (target.scrollTop + target.clientHeight > target.scrollHeight - SCROLL_THRESHOLD) {
        setScroll(null);
      } else {
        setScroll(target.scrollTop);
      }
    },
    [],
  );
  return {
    lastScroll,
    onScroll,
    containerRef,
  };
}
