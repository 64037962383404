import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { RenderIf } from 'common/components/render-if';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';


import { Styled } from './styled';

export interface DrawingsUserLegendHeaderConfig {
  area: boolean;
  perimeter: boolean;
  length: boolean;
}

interface Props {
  isImperial: boolean;
  config: DrawingsUserLegendHeaderConfig;
}

export const DrawingsUserAnnotationLegendHeader= React.forwardRef<HTMLTableSectionElement, Props>((
  { isImperial, config },
  forwardRef,
) => {
  const lengthParamPostfix = isImperial ? '' : `, m`;
  return (
    <Styled.HeaderContainer ref={forwardRef}>
      <Styled.HeaderName>
        <Text color='gray' fontSize='12'>
          Folder Name
        </Text>
      </Styled.HeaderName>
      <RenderIf condition={config.area}>
        <Styled.HeaderValue>
          <Text color='gray' fontSize='12'>
            Area, {UnitUtil.unitToString(UnitTypes.M2, isImperial)}
          </Text>
        </Styled.HeaderValue>
      </RenderIf>
      <RenderIf condition={config.perimeter}>
        <Styled.HeaderValue>
          <Text color='gray' fontSize='12'>
            Perimeter{lengthParamPostfix}
          </Text>
        </Styled.HeaderValue>
      </RenderIf>
      <RenderIf condition={config.length}>
        <Styled.HeaderValue>
          <Text color='gray' fontSize='12'>
            Length{lengthParamPostfix}
          </Text>
        </Styled.HeaderValue>
      </RenderIf>
      <Styled.HeaderValue>
        <Text color='gray' fontSize='12'>
          Count, Nr
        </Text>
      </Styled.HeaderValue>
    </Styled.HeaderContainer>
  );
});
