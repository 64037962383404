import { Icons, IconButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


interface Props {
  onToggleMenu: (e: React.MouseEvent<HTMLButtonElement>) => void;
}


export const ButtonQuestions: React.FC<Props> = ({ onToggleMenu }) => {
  return (
    <Styled.Container>
      <IconButton
        Icon={Icons.Question}
        onClick={onToggleMenu}
        width={20}
        height={20}
      />
    </Styled.Container>
  );
};
