import { isCommentData } from 'unit-2d-notification/store-slice';
import { CompanyNotification } from 'unit-2d-notification/store-slice/interfaces';
import { CommentData } from 'unit-2d-notification/store-slice/interfaces/notification-data';
import { getCommentText } from './get-comment-text';

export interface Params {
  projectNameMap: Record<number, string>;
}

export const getText = (notification: CompanyNotification): string => {
  if (isCommentData(notification.data)) {
    return getCommentText(notification as CompanyNotification<CommentData>);
  }

  throw new Error('Invalid Data type');
};
