import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import { change } from 'redux-form';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { DatabaseResourceVariantActions } from '../../actions/creators/database-resource-variant';
import { ModifyDatabaseEntityMode, ResourceType } from '../../enums';
import { PlantVariantModel } from '../../interfaces/resources-data';
import { PlantVariantForm } from '../../interfaces/resources-rest-data';
import { ChangeFormHelper } from '../../utils/change-form-helper';
import { ResourcesRestFormMapper } from '../../utils/resources-rest-form-mapper';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';
import { DatabaseResourcePlantVariant } from './database-resource-plant-variant';

interface ReduxProps {
  variant: PlantVariantModel;
  resourceId: number;
  variantId: number;
  databaseId: number;
  form: PlantVariantForm;
}

interface ReduxActions {
  setEditResourceVariantModel: (variant: PlantVariantModel) => void;
  loadDatabaseResourceVariant: (databaseId: number, resourceId: number, variantId: number, type: ResourceType) => void;
  updateDatabaseResourceVariant: (
    databaseId: number, resourceId: number, variantId: number,
    type: ResourceType, model: PlantVariantForm,
  ) => void;
  applyFieldChanges: (field: string, value: any) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps {
}

interface State {
  initialValues: PlantVariantForm;
}

const formId: string = 'edit-plant-variant';
class EditDatabaseResourcePlantVariantComponent extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { initialValues: null };
  }

  public componentDidMount(): void {
    const { databaseId, resourceId, variantId } = this.props;
    this.props.loadDatabaseResourceVariant(databaseId, resourceId, variantId, ResourceType.Plant);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.variant === null && this.props.variant !== null) {
      this.setState({ initialValues: ResourcesRestFormMapper.GetPlantVariantForm(this.props.variant) });
    }
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Plant Variant'
        isApplyButtonDisabled={this.isSaveButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Edit}
        readonly={this.props.readonly}
        form={formId}
        onSubmit={this.save}
        initialValues={this.state.initialValues}
      >
      {
        this.props.variant ? (
          <KreoScrollbars>
            <DatabaseResourcePlantVariant
              variant={this.props.variant}
              onChange={this.onChange}
              readonly={this.props.readonly}
            />
          </KreoScrollbars>
        ) : <SvgSpinner size='middle'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }


  @autobind
  private save(values: PlantVariantForm): void {
    const { databaseId, resourceId, variantId } = this.props;
    this.props.updateDatabaseResourceVariant(
      databaseId, resourceId, variantId, ResourceType.Plant, values);
  }

  @autobind
  private onChange(model: PlantVariantModel): void {
    const form = ResourcesRestFormMapper.GetPlantVariantForm(model);
    ChangeFormHelper.applyChanges(this.props.form, form, this.props.applyFieldChanges);
    this.props.setEditResourceVariantModel(model);
  }

  private isSaveButtonDisabled(): boolean {
    const { variant } = this.props;
    return !variant ||
      !variant.name ||
      !variant.name.trim() ||
      !(variant.cost >= 0);
  }

}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    variant: editModel.variant,
    resourceId: editModel.rootId,
    variantId: editModel.variantId,
    databaseId: state.database.currentDatabase.database.id,
    form: state.form[formId] && state.form[formId].values,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditResourceVariantModel: variant =>
      dispatch(DatabaseResourceListingActions.setEditResourceVariantModel(variant)),
    updateDatabaseResourceVariant: (databaseId, resourceId, variantId, type, model) =>
      dispatch(DatabaseResourceVariantActions.updateResourceVariant(
        databaseId, resourceId, variantId, type, model, formId,
      )),
    loadDatabaseResourceVariant: (databaseId, resourceId, variantId, type) =>
      dispatch(DatabaseResourceVariantActions.loadResourceVariant(databaseId, resourceId, variantId, type)),
    applyFieldChanges: (field: string, value: any) => dispatch(change(formId, field, value)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const EditDatabaseResourcePlantVariant = connector(EditDatabaseResourcePlantVariantComponent);
