import React from 'react';

import { DrawingsInstanceMeasure } from 'common/components/drawings/interfaces';
import { GroupMeasuresContext } from './group-measure-context';
import { useGroupMeasures } from './use-group-measures';

interface Props {
  instancesMeasures: Record<string, DrawingsInstanceMeasure>;
  getInstancesMeasures: (instanceId: string[]) => DrawingsInstanceMeasure[];
}

export const GroupMeasureContextProvider: React.FC<Props> = ({ children, instancesMeasures, getInstancesMeasures }) => {
  const groupMeasures = useGroupMeasures(instancesMeasures, getInstancesMeasures);
  return (
    <GroupMeasuresContext.Provider value={groupMeasures}>
      {children}
    </GroupMeasuresContext.Provider>
  );
};
