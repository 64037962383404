import styled from 'styled-components';

const CONTAINER_WIDTH = 420;

const CustomDialogWrapper = styled.div`
  > div {
    > div {
      > div {
        overflow-y: initial;
      }
    }
  }
`;

const Container = styled.div<{ isFilesLoaded: boolean }>`
  width: ${CONTAINER_WIDTH}px;
  display: flex;
  justify-content: center;
  padding-top: 20px;

  .custom-material-input__wrap {
    display: none;
  }

  .line-file {
    width: ${CONTAINER_WIDTH - 80}px;
  }

  .line-file__dropzone {
    border-radius: 15px;
    color: ${p => p.theme.color.mainFont};
    border-color: ${p => p.theme.color.turquoise};
    background-color: ${p => p.theme.color.mainFont}0d;
    max-height: 200px;
    overflow-y: scroll;
    align-items: ${p => p.isFilesLoaded && 'flex-start'};
  }
`;

export const Styled = {
  CustomDialogWrapper,
  Container,
};
