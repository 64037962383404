import * as t from 'io-ts';

import { QtoTreeRowPropertyC } from './quantity-take-off-tree-row-property';


export const QtoTemplateColumnC = t.intersection([
  t.type({
    properties: t.dictionary(t.string, QtoTreeRowPropertyC),
  }),
  t.partial({
    parentId: t.string,
    children: t.array(t.string),
  }),
]);


export type QtoTemplateColumn = t.TypeOf<typeof QtoTemplateColumnC>;
export interface QtoTemplateColumnWithId extends QtoTemplateColumn {
  id: string;
}
