import styled from 'styled-components';

const FOLDER_HEIGHT = 40;
const FOLDER_MARGIN = 5;
const FOLDERS_MAX_COUNT = 7;

const DialogContainer = styled.div`
  width: 480px;
  border-radius: 20px;
  background: ${p => p.theme.color.backgroundRush};
  padding: 30px;
  box-sizing: border-box;
`;

const DialogHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 20px minmax(auto, 70%) 75px;
  gap: 15px;
  margin-bottom: 30px;
  > svg {
    min-width: 20px;
    min-height: 20px;
    fill: ${p => p.theme.color.gray};
  }
  p {
    line-height: initial;
  }
`;

const DialogButton = styled.div`
  margin-left: auto;
  height: 20px;
`;

const Navigation = styled.div`
  margin-bottom: 20px;
  p {
    line-height: initial;
  }
`;

const DialogFolder = styled.div`
  border: 1px solid ${props => props.theme.color.background};
  border-radius: 10px;
  display: flex;
  height: ${FOLDER_HEIGHT}px;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 10px;
  gap: 10px;
  cursor: pointer;
  margin-top: ${FOLDER_MARGIN}px;

  svg {
    flex-shrink: 0;
    fill: ${p => p.theme.color.gray};
  }
  svg, p {
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.l};
  }
  &:hover {
    p {
      color: ${p => p.theme.color.turquoise};
    }

    svg {
      fill: ${p => p.theme.color.turquoise};
    }
  }
`;

const FoldersList = styled.div`
  max-height: ${(FOLDER_HEIGHT + FOLDER_MARGIN) * FOLDERS_MAX_COUNT}px;
  overflow-y: scroll;
`;

export const Styled = {
  DialogContainer,
  DialogHeader,
  DialogButton,
  Navigation,
  DialogFolder,
  FoldersList,
};
