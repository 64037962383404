
import { DrawingsInstanceType } from '../enums/drawings-instance-type';
import { DrawingsGeometryInstance } from '../interfaces/drawings-geometry-instance';
import { DrawingsGeometryUtils } from './drawings-geometry-utils';

interface CanBoolWithPolygonsParams {
  hiddenInstances: string[];
  currentDrawingId: string;
  selectedInstances: string[];
  getInstance: (instanceId: string) => DrawingsGeometryInstance;
}

function canBoolWithPolygons(
  {
    hiddenInstances,
    currentDrawingId,
    selectedInstances,
    getInstance,
  }: CanBoolWithPolygonsParams,
): boolean {
  let count = 0;
  const selectedPolygon = DrawingsGeometryUtils.selectedPolygonsIterator(selectedInstances, getInstance);
  for (const polygon of selectedPolygon) {
    if (hiddenInstances.includes(polygon.id) || currentDrawingId !== polygon.drawingId) {
      continue;
    }
    count++;
    if (count === 2) {
      return true;
    }
  }
  return false;
}

interface CanJoinParams {
  hiddenInstances: string[];
  currentDrawingId: string;
  selectedInstances: string[];
  instanceType: DrawingsInstanceType;
  getInstance: (instanceId: string) => DrawingsGeometryInstance;
}

function canJoin(
  {
    hiddenInstances,
    currentDrawingId,
    selectedInstances,
    instanceType,
    getInstance,
  }: CanJoinParams,
): boolean {
  let count = 0;
  for (const instanceId of selectedInstances) {
    const instance = getInstance(instanceId);
    if (
      !instance
      || hiddenInstances.includes(instanceId)
      || currentDrawingId !== instance.drawingId
      || instance.type !== instanceType
    ) {
      continue;
    }
    count++;
    if (count === 2) {
      return true;
    }
  }
}

export const DrawingsBooleanOperationsUtils = {
  canBoolWithPolygons,
  canJoin,
};
