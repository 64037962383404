import { ColDef } from 'ag-grid-community';

import { GraphStorageRecordsConfig, RecordConfig } from '../../interfaces/graph-storage-records-config';

const getNRMNamesByLevel =
  (value: string[], fieldName: string, displayFieldName: GraphStorageRecordsConfig): Record<string, string> => {
    const levels = value[0].split('.');
    const result = {};
    for (let i = 0; i < levels.length - 1; i++) {
      const key = `${fieldName}_${i}_level`;
      result[key] = (displayFieldName[key] as RecordConfig).columnName || (displayFieldName[key] as RecordConfig).name;
    }

    return result;
  };

export const getNRMCodeByLevel = (value: string, fieldName: string): Record<string, string> => {
  const levels = value.split('.');
  const result = {};
  for (let i = 0; i < levels.length - 1; i++) {
    const key = `${fieldName}_${i}_level`;
    result[key] = `${levels.slice(0, i + 1).join('.')}`;
  }

  return result;
};

export const getNRMColumns = (
  fieldName: string,
  fullCode: string[],
  displayFieldName: GraphStorageRecordsConfig,
): ColDef[] => {
  const additionalProps = getNRMNamesByLevel(fullCode, fieldName, displayFieldName);
  const result = [];
  for (const [key, value] of Object.entries(additionalProps)) {
    result.push({
      field: key,
      headerName: value,
      rowGroup: false,
      hide: true,
      enableRowGroup: true,
      sortable: true,
    });
  }

  return result;
};
