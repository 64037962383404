import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import { change } from 'redux-form';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseActivityActions } from '../../actions/creators/database-activity';
import { DatabaseActivityListingActions } from '../../actions/creators/database-activity-listing';
import { DatabaseActivityVariantActions } from '../../actions/creators/database-activity-variant';
import { ModifyDatabaseEntityMode } from '../../enums';
import {
  ActivityModel,
  ActivityVariantModel,
  ActivityVariantType,
  ExtractorFunctionModel,
  UnitModel,
} from '../../interfaces/data';
import { ActivityVariantForm } from '../../interfaces/rest-data';
import { ChangeFormHelper } from '../../utils/change-form-helper';
import { RestFormMapper } from '../../utils/rest-form-mapper';
import { DatabaseActivityVariant } from '../database-activity-variant';
import { DatabaseActivityVariantTotalCost } from '../database-activity-variant-total-cost';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';

interface ReduxProps {
  activity: ActivityModel;
  activityId: number;
  activityVariant: ActivityVariantModel;
  databaseId: number;
  lastSelectedExtractorFunction: string;
  functions: ExtractorFunctionModel[];
  lastSelectedUnitId: number;
  form: ActivityVariantForm;
  unitMap: Record<number, UnitModel>;
}

interface ReduxActions {
  setEditActivityVariantModel: (activityVariant: ActivityVariantModel) => void;
  loadActivity: (databaseId: number, activityId: number) => void;
  createActivityVariant: (
    databaseId: number, activityId: number, activityVariant: ActivityVariantForm, formId: string,
  ) => void;
  setLastSelectedExtractorFunction: (extractorFunction: string) => void;
  setLastSelectedUnit: (unit: number) => void;
  applyFieldChanges: (field: string, value: any) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps {
}


const formId: string = 'create-activity-variant';
class CreateDatabaseActivityVariantComponent extends React.Component<Props> {
  private initialValues: ActivityVariantForm = null;

  public componentDidMount(): void {
    this.props.loadActivity(this.props.databaseId, this.props.activityId);
    const variant = this.getDefaultActivityVariantModel();
    this.props.setEditActivityVariantModel(variant);
    this.initialValues = RestFormMapper.GetActivityVariantForm(variant, this.props.functions);
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Activity Variant'
        isApplyButtonDisabled={this.isCreateButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Create}
        onSubmit={this.create}
        className='database-entity-modify-panel--has-total-cost'
        readonly={this.props.readonly}
        form={formId}
        initialValues={this.initialValues}
      >
      {
        this.props.activity && this.props.activityVariant ? (
          <React.Fragment>
            <KreoScrollbars>
              <DatabaseActivityVariant
                activityVariant={this.props.activityVariant}
                onChange={this.onModalityChange}
                readonly={this.props.readonly}
              />
            </KreoScrollbars>
            <DatabaseActivityVariantTotalCost
              databaseId={this.props.databaseId}
              activity={this.props.activity}
              activityVariant={this.props.activityVariant}
              unitMap={this.props.unitMap}
            />
          </React.Fragment>
        ) : <SvgSpinner size='large'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }

  @autobind
  private create(values: ActivityVariantForm): void {
    this.props.createActivityVariant(this.props.databaseId, this.props.activityId, values, formId);
    this.props.setLastSelectedExtractorFunction(this.props.activityVariant.extractorFunction);
    this.props.setLastSelectedUnit(this.props.activityVariant.unitId);
  }

  private isCreateButtonDisabled(): boolean {
    const { activityVariant } = this.props;
    return !activityVariant ||
      !activityVariant.name ||
      !activityVariant.name.trim() ||
      !activityVariant.extractorFunction ||
      !activityVariant.unitId;
  }

  @autobind
  private onModalityChange(activityVariant: ActivityVariantModel): void {
    this.props.setEditActivityVariantModel(activityVariant);
    const form = RestFormMapper.GetActivityVariantForm(activityVariant, this.props.functions);
    ChangeFormHelper.applyChanges(this.props.form, form, this.props.applyFieldChanges);
  }

  private getDefaultActivityVariantModel(): ActivityVariantModel {
    const { functions, lastSelectedExtractorFunction } = this.props;
    const lastSelectedExtractor = functions.find(x => x.id === lastSelectedExtractorFunction);

    return {
      id: null,
      name: null,
      materials: [],
      constraints: [],
      crewHours: null,
      unitId: this.props.lastSelectedUnitId,
      code: null,
      extractorFunction: this.props.lastSelectedExtractorFunction,
      extractorParameters: lastSelectedExtractor ? lastSelectedExtractor.extractorParameters : [],
      type: ActivityVariantType.FixedCrewHours,
      materialBasedCrewHours: null,
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  const currentDatabase = state.database.currentDatabase;
  return {
    activity: editModel.root,
    activityId: editModel.rootId,
    activityVariant: editModel.variant,
    databaseId: currentDatabase.database.id,
    lastSelectedExtractorFunction: currentDatabase.activityListing.defaultValues.lastSelectedExtractorFunction,
    lastSelectedUnitId: currentDatabase.activityListing.defaultValues.lastSelectedUnitId,
    functions: currentDatabase.functions,
    form: state.form[formId] && state.form[formId].values,
    unitMap: currentDatabase.unitMap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditActivityVariantModel: variant =>
      dispatch(DatabaseActivityListingActions.setEditActivityVariantModel(variant)),
    loadActivity: (databaseId, activityId) => dispatch(DatabaseActivityActions.loadActivity(databaseId, activityId)),
    createActivityVariant: (databaseId, activityId, form) =>
      dispatch(DatabaseActivityVariantActions.createActivityVariant(databaseId, activityId, form, formId)),
    setLastSelectedExtractorFunction: extractorFunction =>
      dispatch(DatabaseActivityListingActions.setLastSelectedExtractorFunction(extractorFunction)),
    setLastSelectedUnit: unitId =>
      dispatch(DatabaseActivityListingActions.setLastSelectedUnit(unitId)),
    applyFieldChanges: (field: string, value: any) => dispatch(change(formId, field, value)),
  };
};


const connector = connect(mapStateToProps, mapDispatchToProps);
export const CreateDatabaseActivityVariant = connector(CreateDatabaseActivityVariantComponent);
