import QueryString from 'query-string';

import { NumberDictionary } from 'common/interfaces/dictionary';
import { CommonApi, ProjectResourcesApi } from '../../../api/server';
import { QtoRecordConfig } from '../actions/payloads/quantity-take-off';
import { ValidationStepStatisticDataPayload } from '../actions/payloads/validation';
import {
  ClassificationAssignAssemblyRequestBody,
  ClassificationAssignSimpleRequestBody,
} from '../interfaces/classification/classification-api-requests';
import { ElementClassificationResponse } from '../interfaces/classification/element-classification-response';
import {
  ClassificationOntologyGraphResponse,
  ClassificationOntologyNode,
  ClassificationSaveGraphPayload,
} from '../interfaces/classification/ontology-data';
import { UniClassCategories } from '../interfaces/classification/uniclass-categories';

function classificationUrl(url: string = ''): string {
  return `/classification${url}`;
}


function getClassificationByMultipleIds(
  bimHandleIds: number[],
): Promise<NumberDictionary<ClassificationOntologyGraphResponse>> {
  const queryString = QueryString.stringify({ bimHandleIds });
  return ProjectResourcesApi.get(classificationUrl(`/graphs/?${queryString}`));
}

function selectVariant(
  graph: NumberDictionary<ClassificationOntologyNode>,
): Promise<NumberDictionary<ClassificationOntologyGraphResponse>> {
  return ProjectResourcesApi.post(classificationUrl('/graphs/suggestions'), graph);
}

function saveGraph(
  graphs: ClassificationSaveGraphPayload[],
): Promise<ClassificationOntologyNode> {
  return ProjectResourcesApi.put(classificationUrl('/graphs/suggestions'), graphs);
}

function getClassificationByElements(bimHandleIds: number[]):
 Promise<NumberDictionary<ElementClassificationResponse>> {
  const queryString = QueryString.stringify({ bimHandleIds });
  return ProjectResourcesApi.get(`/classification/elements-classification?${queryString}`);
}

function setSimpleObjectClassification(data: ClassificationAssignSimpleRequestBody): Promise<boolean> {
  return ProjectResourcesApi.post(`/classification/assign`, data);
}

function setLayersClassification(data: ClassificationAssignAssemblyRequestBody): Promise<boolean> {
  return ProjectResourcesApi.post(`/classification/assign-assembly`, data);
}

function getStatistic(): Promise<ValidationStepStatisticDataPayload> {
  return ProjectResourcesApi.get(`/classification/statistic`);
}

function getClassificationGroups(): Promise<any> {
  return ProjectResourcesApi.get(`/classification/graphs/groups`);
}

// TODO add validation (https://kreosoftware.atlassian.net/browse/KREOP-6878)
function getUniClassCategories(): Promise<UniClassCategories>  {
  return CommonApi.get('/cmtdb/uniclass-categories');
}

function getQtoRecordsConfig(): Promise<QtoRecordConfig> {
  return ProjectResourcesApi.get(`/classification/qto-config`);
}


export const ClassificationApi = {
  getStatistic,
  getClassificationByMultipleIds,
  selectVariant,
  getClassificationByElements,
  setLayersClassification,
  setSimpleObjectClassification,
  saveGraph,
  getClassificationGroups,
  getUniClassCategories,
  getQtoRecordsConfig,
};
