import { Icons } from '@kreo/kreo-ui-components';
import { useMemo } from 'react';

import { DrawingsInstanceType } from 'common/components/drawings/enums';
import {
  DrawingsInstanceMeasure,
  DrawingsMeasureCount,
  DrawingsMeasureLength,
  DrawingsMeasurePolygon,
} from 'common/components/drawings/interfaces';
import { UnitTypes } from 'common/utils/unit-util';
import { MeasureValue } from '../item-measure';


interface MeasureItem {
  firstValue: MeasureValue;
  secondValue?: MeasureValue;
}

export function useMeasureItem(measure: DrawingsInstanceMeasure, enableTooltip: boolean): MeasureItem | null {
  return useMemo(() => {
    if (!measure) {
      return null;
    }

    switch (measure.type) {
      case DrawingsInstanceType.Polygon:
      case DrawingsInstanceType.Rectangle: return {
        firstValue: {
          icon: enableTooltip ? Icons.AreaSmallIcon : Icons.Area2D,
          value: (measure.measures as DrawingsMeasurePolygon).area,
          unit: UnitTypes.M2,
        },
      };
      case DrawingsInstanceType.Line:
      case DrawingsInstanceType.Polyline: return {
        firstValue: {
          icon: enableTooltip ? Icons.LinearSmallIcon : Icons.Line,
          value: (measure.measures as DrawingsMeasureLength).length,
          unit: UnitTypes.M,
        },
      };
      case DrawingsInstanceType.Count: return {
        firstValue: {
          icon: enableTooltip ? Icons.CountSmallIcon : Icons.Dot,
          value: (measure.measures as DrawingsMeasureCount).count,
          unit: UnitTypes.Nr,
        },
      };
      default: return null;
    }
  }, [measure, enableTooltip]);
}
