import { calcYPos } from './utils'

export default function ContentBox(el: any, items: any, itemHeight: any) {
  let size;
  this.el = el;

  const calculateSize = (): any => {
      return size = {
          top: calcYPos(this.el),
          height: items.length * itemHeight
      };
  };

  this.size = function(): any {
      return size || calculateSize();
  };

  this.reset = function() {
      size = null;
  };
}