import { H6, Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { TRANSITION_POINT } from '../../../../constants';


const BlockQuestionnaire = styled.div`
  width: 100%;
`;

const ClosedQuestion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;

  ${H6} {
    min-width: fit-content;
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
`;

const CurrentReply = styled.div`
  padding-left: 10px;
  max-width: 100%;

  ${Text} {
    color: ${(p) => p.theme.color.turquoise};
    word-break: break-all;
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    padding-left: 0;
  }
`;

export const Styled = {
  BlockQuestionnaire,
  ClosedQuestion,
  CurrentReply,
};
