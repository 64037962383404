import {
  FormattingMenu,
  Icons,
} from '@kreo/kreo-ui-components';
import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { ColorControl } from 'common/components/color-control';
import { FilterChangePayload } from 'common/components/drawings/interfaces/drawing-filters';
import { FormatPanelHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { ExcelTableApi, UpdateCellData } from 'common/components/excel-table';
import {
  GlobalKeyboardEventsControllerContextProps,
  withGlobalKeyboardEventsController,
} from 'common/components/global-keyboard-events-controller';
import { ColorList, ExtendColorList } from 'common/constants/color-list';
import { ConstantFunctions } from 'common/constants/functions';
import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import {
  ExcelTableCellFormatter,
  FontStyle,
  HorizontalAlign,
  StyleFields,
} from '../units/excel-table-cell-formatter';
import { FontSizeComponent, FontSizeMenu } from './2d-report-font-size-menu';
import { TwoDReportFormatFormulaMenu } from './2d-report-format-panel-formula-menu';
import { Styled } from './styled';

interface Props extends GlobalKeyboardEventsControllerContextProps {
  gridApiRef: ExcelTableApi;
  cellRanges: Ag.CellRange[];
  updateCells: (cells: UpdateCellData[]) => void;
  insertFunction: (value: string) => void;
  toggleQuickSearch: () => void;
  setQuickSearchValue: (value: string) => void;
  changeFilterData: (changeFilterData: FilterChangePayload) => void;
  isQuickSearchVisible: boolean;
  isCellRangeActive: boolean;
  reportEditPermission: boolean;
  reportViewPermission: boolean;
  isFullScreenTable: boolean;
}

/* eslint-disable @typescript-eslint/no-shadow */
enum FormattingItems {
  FontStyle = 'FontStyle',
  Bold = 'Bold',
  Italic = 'Italic',
  Underline = 'Underline',
  StrikeThrough = 'StrikeThrough',
  HorizontalAlign = 'HorizontalAlign',
  HorizontalAlignLeft = 'HorizontalAlignLeft',
  HorizontalAlignCenter = 'HorizontalAlignCenter',
  HorizontalAlignRight = 'HorizontalAlignRight',
  Fill = 'Fill',
  TextColor = 'TextColor',
  FontSize = 'FontSize',
  Merge = 'Merge',
  Formula = 'Formula',
  FormulaSum = 'FormulaSum',
  FormulaAverage = 'FormulaAverage',
  FormulaCount = 'FormulaCount',
  FormulaMin = 'FormulaMin',
  FormulaMax = 'FormulaMax',
}
/* eslint-enable */

interface State {
  values: Partial<Record<FormattingItems, any>>;
}

class TwoDReportFormatPanelComponent extends React.Component<Props, State> {
  private clearFormatItems: any[] = [{
    name: 'ClearFormat',
    icon: Icons.ClearFormat1,
    tooltip: { text: 'Clear format', description: 'Clear formatting of the selected cell' },
    onClick: this.resetStyles,
    disabled: !this.props.reportEditPermission,
  }];

  constructor(props: Props) {
    super(props);
    this.state = {
      values: {},
    };
  }

  public static getDerivedStateFromProps(props: Props): State {
    const api = props.gridApiRef && props.gridApiRef.api;
    const properties = api && ExcelTableCellFormatter.getCellsStyleProperty(api, props.cellRanges);
    const values = properties
      ? {
        /* eslint-disable no-bitwise */
        FontStyle: properties[StyleFields.FontStyle],
        Bold: properties[StyleFields.FontStyle] & FontStyle.Bold,
        Italic: properties[StyleFields.FontStyle] & FontStyle.Italic,
        StrikeThrough: properties[StyleFields.FontStyle] & FontStyle.StrikeThrough,
        Underline: properties[StyleFields.FontStyle] & FontStyle.Underline,
        /* eslint-enable */
        HorizontalAlign: properties[StyleFields.HorizontalAlign],
        HorizontalAlignLeft: properties[StyleFields.HorizontalAlign] === HorizontalAlign.Left,
        HorizontalAlignCenter: properties[StyleFields.HorizontalAlign] === HorizontalAlign.Center,
        HorizontalAlignRight: properties[StyleFields.HorizontalAlign] === HorizontalAlign.Right,
        Fill: properties[StyleFields.BackgroundColor],
        TextColor: properties[StyleFields.FontColor],
        FontSize: properties[StyleFields.FontSize],
        Merge: false,
        Formula: false,
        FormulaSum: false,
        FormulaAverage: false,
        FormulaCount: false,
        FormulaMin: false,
        FormulaMax: false,
      }
      : {};

    return { values };
  }

  public componentDidMount(): void {
    if (this.props.reportEditPermission) {
      this.props.addKeyDownEventListener(FormatPanelHotkey.Underline, this.underlineCallback);
      this.props.addKeyDownEventListener(FormatPanelHotkey.Italic, this.italicCallback);
      this.props.addKeyDownEventListener(FormatPanelHotkey.Bold, this.boldCallback);
      this.props.addKeyDownEventListener(FormatPanelHotkey.StrikeThrough, this.strikeThroughCallback);
    }
  }

  public render(): JSX.Element {
    return (
      <Styled.FormattingToolbar>
        <Styled.FormattingMenuContainer>
          <FormattingMenu
            menuItems={this.getCellFormattingMenuItems()}
            popUpsPosition={this.props.isFullScreenTable ? 'bottom' : 'top'}
            height={36}
          />
        </Styled.FormattingMenuContainer>
        <Styled.FormattingMenuContainer>
          <FormattingMenu menuItems={this.clearFormatItems} height={36}/>
        </Styled.FormattingMenuContainer>
        <Styled.FormatButtonsSeparator />
        <Styled.FiltersButtonsContainer>
          <FormattingMenu menuItems={this.getFiltersButtons()} height={36}/>
        </Styled.FiltersButtonsContainer>
      </Styled.FormattingToolbar>
    );
  }

  public componentWillUnmount(): void {
    if (this.props.reportEditPermission) {
      this.props.removeKeyUpEventListener(FormatPanelHotkey.Underline, this.keyUDownCallback);
      this.props.removeKeyUpEventListener(FormatPanelHotkey.Underline, this.underlineCallback);
      this.props.removeKeyUpEventListener(FormatPanelHotkey.Italic, this.italicCallback);
      this.props.removeKeyUpEventListener(FormatPanelHotkey.Bold, this.boldCallback);
      this.props.removeKeyUpEventListener(FormatPanelHotkey.StrikeThrough, this.strikeThroughCallback);
    }
  }

  private getCellFormattingMenuItems(): any[] {
    const values = this.state.values;
    const { reportEditPermission } = this.props;

    return [
      {
        name: FormattingItems.Bold,
        icon: Icons.TextBold,
        tooltip: { text: 'Bold', hotKey: 'Ctrl+B', description: 'Make the selected text bold' },
        onClick: this.setBold,
        isActive: values[FormattingItems.Bold],
        disabled: !reportEditPermission,
      },
      {
        name: FormattingItems.Italic,
        icon: Icons.TextItalic,
        tooltip: { text: 'Italic', hotKey: 'Ctrl+I', description: 'Italicize the selected text' },
        onClick: this.setItalic,
        isActive: values[FormattingItems.Italic],
        disabled: !reportEditPermission,
      },
      {
        name: FormattingItems.Underline,
        icon: Icons.TextUnderline,
        tooltip: { text: 'Underline', hotKey: 'Ctrl+U', description: 'Underline the selected text' },
        onClick: this.setUnderline,
        isActive: values[FormattingItems.Underline],
        disabled: !reportEditPermission,
      },
      {
        name: FormattingItems.StrikeThrough,
        icon: Icons.TextStrikethrough,
        tooltip: { text: 'Strikethrough', hotKey: 'Ctrl+Shift+S', description: 'Strike through the selected text' },
        onClick: this.setStrikeThrough,
        isActive: values[FormattingItems.StrikeThrough],
        disabled: !reportEditPermission,
      },
      {
        name: FormattingItems.FontSize,
        tooltip: { text: 'Font size' },
        disabled: !reportEditPermission,
        component: () => (
          <FontSizeComponent value={values[FormattingItems.FontSize]} disabled={!reportEditPermission}/>
        ),
        subMenu: <FontSizeMenu value={values[FormattingItems.FontSize]} setValue={this.setValue}/>,
      },
      {
        name: FormattingItems.HorizontalAlign,
        icon: Icons.TextAlignLeft,
        tooltip: { text: 'Alignment', description: 'Align the text in the selected cell' },
        isActive: values[FormattingItems.HorizontalAlign],
        disabled: !reportEditPermission,
        subMenu: (
          <FormattingMenu
            borderRadius={15}
            menuItems={[
              {
                name: FormattingItems.HorizontalAlignLeft,
                icon: Icons.TextAlignLeft,
                onClick: this.setHorizontalAlignLeft,
                isActive: values[FormattingItems.HorizontalAlignLeft],
              },
              {
                name: FormattingItems.HorizontalAlignCenter,
                icon: Icons.TextAlignCenter,
                onClick: this.setHorizontalAlignCenter,
                isActive: values[FormattingItems.HorizontalAlignCenter],
              },
              {
                name: FormattingItems.HorizontalAlignRight,
                icon: Icons.TextAlignRigth,
                onClick: this.setHorizontalAlignRight,
                isActive: values[FormattingItems.HorizontalAlignRight],
              },
            ]}
          />
        ),
      },
      {
        name: FormattingItems.Fill,
        icon: Icons.CellBackgroundColor,
        tooltip: { text: 'Fill colour', description: 'Set background colour of the selected cell' },
        iconColor: values[FormattingItems.Fill],
        onClick: null,
        isActive: values[FormattingItems.Fill],
        disabled: !reportEditPermission,
        subMenu: (
          <Styled.ColorsMenuContainer
            positionTop={!this.props.isFullScreenTable}
          >
            <ColorControl
              title='colours:'
              colorList={ColorList}
              selectedColor={values[FormattingItems.Fill]}
              onColor={this.setBackgroundColor}
            />
          </Styled.ColorsMenuContainer>
        ),
      },
      {
        name: FormattingItems.TextColor,
        icon: Icons.FontColor,
        tooltip: { text: 'Text colour', description: 'Set colour of the selected text' },
        iconColor: values[FormattingItems.TextColor],
        onClick: null,
        isActive: values[FormattingItems.TextColor],
        disabled: !reportEditPermission,
        subMenu: (
          <Styled.ColorsMenuContainer
            positionTop={!this.props.isFullScreenTable}
          >
            <ColorControl
              title='colours:'
              colorList={ExtendColorList}
              selectedColor={values[FormattingItems.TextColor]}
              onColor={this.setTextColor}
            />
          </Styled.ColorsMenuContainer>
        ),
      },
      {
        name: FormattingItems.Formula,
        icon: Icons.Functions,
        tooltip: { text: 'Formulae', description: 'Insert the relevant function to the selected cell' },
        disabled: !reportEditPermission,
        subMenu: (
          <Styled.FormulaMenuContainer
            positionTop={!this.props.isFullScreenTable}
          >
            <TwoDReportFormatFormulaMenu
              onClick={this.props.insertFunction}
            />
          </Styled.FormulaMenuContainer>
        ),
      },
    ];
  }

  private getFiltersButtons(): any[] {
    const onQuickSearchClick = (): void => {
      this.props.toggleQuickSearch();
      this.props.setQuickSearchValue('');
    };
    return [
      {
        name: 'quickSearch',
        icon: Icons.Search,
        tooltip: { text: 'Quick Search' },
        onClick: onQuickSearchClick,
        isActive: this.props.isQuickSearchVisible,
        disabled: !this.props.reportViewPermission,
      },
    ];
  }

  @autobind
  private updateState(): void {
    this.setState(TwoDReportFormatPanelComponent.getDerivedStateFromProps(this.props));
  }

  @autobind
  private resetStyles(): void {
    const { gridApiRef, cellRanges, updateCells } = this.props;
    ExcelTableCellFormatter.setCellsStyleProperty(gridApiRef.api, cellRanges, updateCells, {});
    this.updateState();
  }

  @autobind
  private setValue(field: StyleFields, value: any): void {
    const { gridApiRef, cellRanges, updateCells } = this.props;
    ExcelTableCellFormatter.setCellsStyleProperty(gridApiRef.api, cellRanges, updateCells, {
      [field]: value,
    });
    this.updateState();
  }

  @autobind
  private setHorizontalAlign(align: HorizontalAlign): void {
    const value = align === this.state.values[FormattingItems.HorizontalAlign]
      ? undefined
      : align;
    this.setValue(StyleFields.HorizontalAlign, value);
  }

  @autobind
  private setHorizontalAlignLeft(): void {
    this.setHorizontalAlign(HorizontalAlign.Left);
  }

  @autobind
  private setHorizontalAlignRight(): void {
    this.setHorizontalAlign(HorizontalAlign.Right);
  }

  @autobind
  private setHorizontalAlignCenter(): void {
    this.setHorizontalAlign(HorizontalAlign.Center);
  }

  @autobind
  private setFontStyle(fontStyle: FontStyle): void {
    /* eslint-disable no-bitwise */
    const value = fontStyle & this.state.values[FormattingItems.FontStyle]
      ? ~fontStyle
      : fontStyle;
    /* eslint-enable */
    this.setValue(StyleFields.FontStyle, value);
  }

  @autobind
  private setBold(): void {
    this.setFontStyle(FontStyle.Bold);
  }

  @autobind
  private setItalic(): void {
    this.setFontStyle(FontStyle.Italic);
  }

  @autobind
  private setUnderline(): void {
    this.setFontStyle(FontStyle.Underline);
  }

  @autobind
  private setStrikeThrough(): void {
    this.setFontStyle(FontStyle.StrikeThrough);
  }

  @autobind
  private setTextColor(color: string): void {
    const value = color === this.state.values[FormattingItems.TextColor]
      ? undefined
      : color;
    this.setValue(StyleFields.FontColor, value);
  }

  @autobind
  private setBackgroundColor(color: string): void {
    const value = color === this.state.values[FormattingItems.Fill]
      ? undefined
      : color;
    this.setValue(StyleFields.BackgroundColor, value);
  }

  @autobind
  private keyUDownCallback(e: KeyboardEvent): void {
    if (HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e)) {
      ConstantFunctions.stopEvent(e);
    }
  }

  @autobind
  private underlineCallback(e: KeyboardEvent): void {
    this.setUnderline();
    ConstantFunctions.stopEvent(e);
  }

  @autobind
  private italicCallback(e: KeyboardEvent): void {
    this.setItalic();
    ConstantFunctions.stopEvent(e);
  }

  @autobind
  private boldCallback(e: KeyboardEvent): void {
    this.setBold();
    ConstantFunctions.stopEvent(e);
  }

  @autobind
  private strikeThroughCallback(e: KeyboardEvent): void {
    this.setStrikeThrough();
    ConstantFunctions.stopEvent(e);
  }
}

export const TwoDReportFormatPanel = withGlobalKeyboardEventsController(TwoDReportFormatPanelComponent);
