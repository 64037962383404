import * as React from 'react';

export const KreoIconSelectToolCollapsed: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/16x16/SelectTool_Collapsed'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M4.94318932,5 L11.0568107,5 C11.6090954,5 12.0568107,5.44771525 12.0568107,6 C12.0568107,6.20842298
          11.9916875,6.41163744 11.8705441,6.58123819 L8.81373347,10.8607731 C8.49272448,11.3101857
          7.86817439,11.4142774 7.41876181,11.0932684 C7.32896628,11.0291288 7.25040619,10.9505687
          7.18626653,10.8607731 L4.12945585,6.58123819 C3.80844686,6.13182561 3.91253854,5.50727552
          4.36195113,5.18626653 C4.53155188,5.06512313 4.73476635,5 4.94318932,5 Z'
          id='Rectangle-5'
          fill='#BACEDE'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
