import { Text, TinyText } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { PdfPagePreview } from 'common/components/drawings';
import { ConstantFunctions } from 'common/constants/functions';

import { Styled } from './styled';

interface Props {
  name: string;
  color: string;
  pdfName: string;
  fileExtension?: string;
  pageNumber: number;
  pdfId: string;
  pageId: string;
  screenshotCreated: boolean;
}

class TwoDHeaderPageTabHoverMenuComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const {
      name,
      pdfName,
      pageNumber,
      color,
      fileExtension = 'PDF',
      screenshotCreated,
      pageId,
      pdfId,
    } = this.props;
    return (
      <Styled.Container onMouseDown={ConstantFunctions.stopEvent}>
        <Styled.ImageContainer>
          <PdfPagePreview
            created={screenshotCreated}
            pdfId={pdfId}
            pageId={pageId}
          />
        </Styled.ImageContainer>
        <Styled.InfoGroup>
          <Styled.InfoGroupHead>
            <TinyText color={'turquoiseFont'}>PAGE</TinyText>
            <Styled.InfoGroupHeadBubble color={color}>
              <TinyText color={'white'}>{pageNumber}</TinyText>
            </Styled.InfoGroupHeadBubble>
          </Styled.InfoGroupHead>
          <Text withEllipsis={true}>{name}</Text>
        </Styled.InfoGroup>
        <Styled.InfoGroup>
          <Styled.InfoGroupHead>
            <TinyText color={'turquoiseFont'}>FILE</TinyText>
            <Styled.InfoGroupHeadBubble color={color}>
              <TinyText color={'white'}>{fileExtension}</TinyText>
            </Styled.InfoGroupHeadBubble>
          </Styled.InfoGroupHead>
          <Text>{pdfName}</Text>
        </Styled.InfoGroup>
      </Styled.Container>
    );
  }
}


export const TwoDHeaderPageTabHoverMenu = TwoDHeaderPageTabHoverMenuComponent;
