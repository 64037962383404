export const persistedStoragePrefix = '@persisted-storage';

export const PersistedStorageActionTypes = {
  SET_DRAWING_SNAPPING_MODES: `${persistedStoragePrefix}/SET_DRAWING_SNAPPING_MODES`,
  PERSIST_SELECTED_COMPANY_ID: `${persistedStoragePrefix}/PERSIST_SELECTED_COMPANY_ID`,
  PERSIST_SELECTED_PROJECT_REVISION_ID: `${persistedStoragePrefix}/PERSIST_SELECTED_PROJECT_REVISION_ID`,
  DROP_SELECTED_COMPANY_ID: `${persistedStoragePrefix}/DROP_SELECTED_COMPANY_ID`,
  PERSIST_CHAT_CLOUD_DISMISSED: `${persistedStoragePrefix}/PERSIST_CHAT_CLOUD_DISMISSED`,
  TOGGLE_ENGINE_AUTO_FOCUS: `${persistedStoragePrefix}/TOGGLE_ENGINE_AUTO_FOCUS`,
  TOGGLE_DISABLE_SHOW_DIALOG: `${persistedStoragePrefix}/TOGGLE_DISABLE_SHOW_DIALOG`,
  TOGGLE_DISABLE_SHOW_TOUR: `${persistedStoragePrefix}/TOGGLE_DISABLE_SHOW_TOUR`,
  UPDATE_VIEWED_RELEASE_NOTES_VERSION: `${persistedStoragePrefix}/UPDATE_VIEWED_RELEASE_NOTES_VERSION`,
  SAVE_SPLITTER_SIZE: `${persistedStoragePrefix}/SAVE_SPLITTER_SIZE`,
  SET_TWOD_DRAWING_OPACITY: `${persistedStoragePrefix}/SET_TWOD_DRAWING_OPACITY`,
  TOGGLE_TWOD_DRAWING_SNAPPING: `${persistedStoragePrefix}/TOGGLE_TWOD_DRAWING_SNAPPING`,
  TOGGLE_TWOD_DRAWING_BLACK_AND_WHITE: `${persistedStoragePrefix}/TOGGLE_TWOD_DRAWING_BLACK_AND_WHITE`,
  TOGGLE_TWOD_DRAWING_NIGHT_MODE: `${persistedStoragePrefix}/TOGGLE_TWOD_DRAWING_NIGHT_MODE`,
  TOGGLE_TWOD_DRAWING_MINIMAP_OPENED: `${persistedStoragePrefix}/TOGGLE_TWOD_DRAWING_MINIMAP_OPENED`,
  TOGGLE_TWOD_DRAWING_SHOW_LABEL: `${persistedStoragePrefix}/TOGGLE_TWOD_DRAWING_SHOW_LABEL`,
  TOGGLE_PAGES_TABS_MINIFIED: `${persistedStoragePrefix}/TOGGLE_PAGES_TABS_MINIFIED`,
  SET_THEME: `${persistedStoragePrefix}/SET_THEME`,
  SET_THEME_TONE: `${persistedStoragePrefix}/SET_THEME_TONE`,
  TOGGLE_QUICK_SEARCH: `${persistedStoragePrefix}/TOGGLE_QUICK_SEARCH`,
  TOGGLE_MEASUREMENTS_SEARCH: `${persistedStoragePrefix}/TOGGLE_MEASUREMENTS_SEARCH`,
  TOGGLE_AUTO_MOVE_TO_CELL: `${persistedStoragePrefix}/TOGGLE_AUTO_MOVE_TO_CELL`,
  SET_2D_FULLSCREEN_MODE: `${persistedStoragePrefix}/SET_2D_FULLSCREEN_MODE`,
  TOGGLE_DRAWINGS_OFFSET_IS_STROKE: `${persistedStoragePrefix}/TOGGLE_DRAWINGS_OFFSET_IS_STROKE`,
  TOGGLE_DRAWING_KEEP_OLD_POLYGON: `${persistedStoragePrefix}/TOGGLE_DRAWING_KEEP_OLD_POLYGON`,
  TOGGLE_DRAWING_KEEP_GROUP: `${persistedStoragePrefix}/TOGGLE_DRAWING_KEEP_GROUP`,
  TOGGLE_DRAWING_KEEP_MEASURE_NAME: `${persistedStoragePrefix}/TOGGLE_DRAWING_KEEP_MEASURE_NAME`,
  SET_TWO_D_TABLE_INNER_CLIPBOARD: `${persistedStoragePrefix}/SET_TWO_D_TABLE_INNER_CLIPBOARD`,
  TOGGLE_PROJECT_MENU: `${persistedStoragePrefix}/TOGGLE_PROJECT_MENU`,
  TOGGLE_OPEN_DRAWINGS_ANNOTATION_LEGEND_PANEL:
    `${persistedStoragePrefix}/TOGGLE_OPEN_DRAWINGS_ANNOTATION_LEGEND_PANEL`,
  TOGGLE_IS_DEMO_PROJECTS_COLLAPSED: `${persistedStoragePrefix}/TOGGLE_IS_DEMO_PROJECTS_OPEN`,
  TOGGLE_FOLDERS_SECTION: `${persistedStoragePrefix}/TOGGLE_FOLDERS_SECTION`,
  TOGGLE_DRAWINGS_AUTO_FOCUS: `${persistedStoragePrefix}/TOGGLE_DRAWINGS_AUTO_FOCUS`,
  SET_FILTERS_PAGES: `${persistedStoragePrefix}/SET_FILTERS_PAGES`,
  TOGGLE_KEEP_STRUCTURE: `${persistedStoragePrefix}/TOGGLE_KEEP_STRUCTURE`,
  SET_TWOD_DATA_BASE_COLUMNS_STATE: `${persistedStoragePrefix}/SET_TWOD_DATA_BASE_COLUMNS_STATE`,
  SET_SORT_DATA: `${persistedStoragePrefix}/SET_SORT_DATA`,
  TOGGLE_TWOD_ORTHOGONAL_MODE: `${persistedStoragePrefix}/TOGGLE_TWOD_ORTHOGONAL_MODE`,
  TOGGLE_HIDE_GROUPS: `${persistedStoragePrefix}/TOGGLE_HIDE_GROUPS`,
  TOGGLE_DRAWINGS_AUTOCOMPLETE: `${persistedStoragePrefix}/TOGGLE_DRAWINGS_AUTOCOMPLETE`,
  SET_ENTITIES_PANEL_POSITION: `${persistedStoragePrefix}/SET_ENTITIES_PANEL_POSITION`,
  TOGGLE_ENTITIES_PANEL: `TOGGLE_ENTITIES_PANEL`,
  OPEN_ENTITIES_PANEL: `${persistedStoragePrefix}/OPEN_ENTITIES_PANEL`,
  SHOW_UNITS_TOGGLE: `${persistedStoragePrefix}/SHOW_UNITS_TOGGLE`,
  SHOW_TABLE_TOTAL_TOGGLE: `${persistedStoragePrefix}/SHOW_TABLE_TOTAL_TOGGLE`,
  SET_SELECTED_CURRENCY: `${persistedStoragePrefix}/SET_SELECTED_CURRENCY`,
  SET_NEW_DRAWING_INSTANCE_STROKE_WIDTH: `${persistedStoragePrefix}/SET_NEW_DRAWING_INSTANCE_STROKE_WIDTH`,
  SET_DRAWING_EXPORT_FONT_SIZE: `${persistedStoragePrefix}/SET_DRAWING_EXPORT_FONT_SIZE`,
  SET_DRAWING_EXPORT_SCALE_FACTOR: `${persistedStoragePrefix}/SET_DRAWING_EXPORT_SCALE_FACTOR`,
  SET_PROJECTS_ACCESS_FILTER: `${persistedStoragePrefix}/SET_PROJECTS_ACCESS_FILTER`,
  SET_LAST_USED_PRODUCT_TYPE: `${persistedStoragePrefix}/SET_LAST_USED_PRODUCT_TYPE`,
  TOGGLE_CLASS_NAME: `${persistedStoragePrefix}/TOGGLE_CLASS_NAME`,
  TOGGLE_DRAWING_USE_GROUP_NAME_FOR_NEW_GEOMETRY:
    `${persistedStoragePrefix}/TOGGLE_DRAWING_USE_GROUP_NAME_FOR_NEW_GEOMETRY`,
  SET_CURRENT_PATH_NAME: `${persistedStoragePrefix}/SET_CURRENT_PATH_NAME`,
  HIDE_SMALL_SCREEN_DISPLAY_WARNING: `${persistedStoragePrefix}/HIDE_SMALL_SCREEN_DISPLAY_WARNING`,
  CLOSE_TRY_AI_PANEL: `${persistedStoragePrefix}/CLOSE_TRY_AI_PANEL`,
  CHANGE_VIEW_PROJECTS: `${persistedStoragePrefix}/CHANGE_VIEW_PROJECTS`,

  UPDATE_VALUE: `${persistedStoragePrefix}/UPDATE_VALUE`,
  TOGGLE_VALUE: `${persistedStoragePrefix}/TOGGLE_VALUE`,

  SET_DRAWING_3D_SETTING: `${persistedStoragePrefix}/SET_DRAWING_3D_SETTING`,
  TOGGLE_DRAWING_3D_SETTING: `${persistedStoragePrefix}/TOGGLE_DRAWING_3D_SETTING`,
};
