import * as React from 'react';

import './index.scss';

interface PanelItem {
  name: string;
  value: React.ReactText;
}

interface Props {
  items: PanelItem[];
  onChange?: (value: React.ReactText) => void;
}

interface State {
  value: React.ReactText;
}

export class RadioBtnPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.items[0].value,
    };
  }

  public render(): JSX.Element {
    return (
      <div className='dashboard-radio-btn-panel'>
        {this.props.items.map((x) => {
          return (
            <div
              key={x.value}
              onClick={this.onChange.bind(this, x.value)} // eslint-disable-line react/jsx-no-bind
              className={x.value === this.state.value ? 'active' : ''}
            >
              {x.name}
            </div>
          );
        })}
      </div>
    );
  }

  private onChange(value: number): void {
    if (this.state.value !== value) {
      this.setState({ value });
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }
  }
}
