import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';

export const FAILED_UPDATE_REPORT_DIALOG = 'FAILED_UPDATE_REPORT_DIALOG';

interface StateProps {
  message: string;
  payload: any;
}

interface DispatchProps {
  close: () => void;
}

type Props = DispatchProps & StateProps;

const TEXT =
  'Press "Reload page" to get up-to-date report or "Cancel" to copy your latest edits and reload your page manually.';

const FailedUpdateReportDialogComponent: React.FC<Props> = ({ message, close }) => {
  const text = `${message || ''} ${TEXT}`;
  const reloadPage = useCallback(() => location.reload(), []);
  useEffect(() => {
    close();
  }, []);

  return (
    <ConfirmationDialog
      name={FAILED_UPDATE_REPORT_DIALOG}
      title={'Your report is outdated'}
      text={text}
      confirmButtonText="Reload page"
      onConfirm={reloadPage}
      cancelButtonText="Cancel"
      zIndex={99999}
    />
  );
};

const mapStateToProps = (state: State): StateProps => {
  const dialogData = state.dialog[FAILED_UPDATE_REPORT_DIALOG];
  return {
    message: dialogData?.message,
    payload: dialogData?.paylod,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    close: () => dispatch(KreoDialogActions.closeDialog(FAILED_UPDATE_REPORT_DIALOG)),
  };
};

export const FailedUpdateReportDialog = connect(mapStateToProps, mapDispatchToProps)(FailedUpdateReportDialogComponent);
