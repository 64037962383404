import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { ActivityCategoryType } from '../../enums';
import {
  ActivityCategory,
  ActivityCategoryCounts,
  ExtractorFunctionModel,
  MeasurementModel,
  MeasurementType,
  MeasurementTypeModel,
  UnitModel,
} from '../../interfaces/data';
import {
  LoadActivityCategoryCountsPayload,
  LoadActivityCategoryCountsResponsePayload,
  LoadFunctionsWithUnitsPayload,
} from '../payloads/database-common';
import  { DatabaseCommonActionTypes } from '../types/database-common';

/**
 * @description drop all databases, current database and common databases data
 */
const dropState = (): Action => {
  return {
    type: DatabaseCommonActionTypes.DROP_STATE,
  };
};


/**
 * @description drop current database date (activity, resources, category counts, ....)
 */
const dropCurrentDatabaseState = (): Action => {
  return {
    type: DatabaseCommonActionTypes.DROP_CURRENT_DATABASE_STATE,
  };
};

const loadActivityCategories = (): Action => {
  return {
    type: DatabaseCommonActionTypes.GET_ACTIVITY_CATEGORIES,
  };
};

const loadActivityCategoriesResponse = (
  data: ActivityCategory[],
): ActionWith<ActivityCategory[]> => {
  return {
    type: DatabaseCommonActionTypes.GET_ACTIVITY_CATEGORIES_SUCCEEDED,
    payload: data,
  };
};

const loadActivityCategoryCounts = (
  databaseId: number,
  type: ActivityCategoryType,
): ActionWith<LoadActivityCategoryCountsPayload> => {
  return {
    type: DatabaseCommonActionTypes.GET_ACTIVITY_CATEGORY_COUNTS,
    payload: { type, databaseId },
  };
};

const loadActivityCategoryCountsResponse = (
  type: ActivityCategoryType,
  counts: ActivityCategoryCounts,
): ActionWith<LoadActivityCategoryCountsResponsePayload> => {
  return {
    type: DatabaseCommonActionTypes.GET_ACTIVITY_CATEGORY_COUNTS_SUCCEEDED,
    payload: { type, counts },
  };
};

function loadFunctionsWithUnits(databaseId: number): ActionWith<number> {
  return {
    type: DatabaseCommonActionTypes.LOAD_FUNCTIONS_WITH_UNITS,
    payload: databaseId,
  };
}


function loadFunctionsWithUnitsResponse(
  units: UnitModel[],
  measurements: MeasurementModel[],
  measurementTypes: Record<MeasurementType, MeasurementTypeModel>,
  extractorFunctions: ExtractorFunctionModel[],
): ActionWith<LoadFunctionsWithUnitsPayload> {
  return {
    type: DatabaseCommonActionTypes.LOAD_FUNCTIONS_WITH_UNITS_SUCCEEDED,
    payload: {
      units,
      measurements,
      measurementTypes,
      extractorFunctions,
    },
  };
}

export const DatabaseCommonActions = {
  dropState,
  dropCurrentDatabaseState,
  loadActivityCategories,
  loadActivityCategoriesResponse,
  loadActivityCategoryCounts,
  loadActivityCategoryCountsResponse,

  loadFunctionsWithUnits,
  loadFunctionsWithUnitsResponse,
};
