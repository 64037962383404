import * as React from 'react';

import { MoveToCellOptionType } from 'common/components/drawings/drawings-canvas-menus';
import { PuponContextData } from 'common/components/formula-toolbar';
import { CellContextMenu } from './cell-context-menu';
import { DrawingsContextMenu } from './drawings-context-menu';

interface Props {
  puponContextData: PuponContextData;
  closePuponContext: (e: React.MouseEvent<HTMLDivElement>) => void;
  onColorChange: (color: string, isGroup: boolean) => void;
  onClickLinkType: (childIndex: number, newValue: string) => void;
  replaceType: (type: MoveToCellOptionType) => void;
}

export class PuponContextMenu extends React.Component<Props> {
  public render(): JSX.Element {
    const { puponContextData, closePuponContext, onColorChange, onClickLinkType, replaceType } = this.props;
    const { drawingData } = puponContextData;
    if (drawingData) {
      return (
        <DrawingsContextMenu
          replaceType={replaceType}
          puponContextData={puponContextData}
          closePuponContext={closePuponContext}
          onColorChange={onColorChange}
        />
      );
    } else {
      return (
        <CellContextMenu
          puponContextData={puponContextData}
          closePuponContext={closePuponContext}
          onClickLinkType={onClickLinkType}
        />
      );
    }
  }
}
