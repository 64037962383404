import autobind from 'autobind-decorator';
import _ from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import rafDebounce from './debounce';
import VirtualState from './state';


export class VirtualList extends Component {
  static defaultProps = {
    viewport: window,
    items: [],
    bufferSize: 0,
    tagName: 'div'
  }

  dangerousUpdate = true;
  displayName = 'VerticalList'
  scrollDebounce = null
  virtualState = null

  state = {
    contentHeight: 0,
    topOffset: 0,
    items: [],
    startIdx: 0,
  }

  UNSAFE_componentWillMount() {
    this.rafDebounce = rafDebounce(this.update);
  }

  componentDidMount() {
    this.initVirtualState(this.props);
    this.bindEvents(this.props);
    this.update();
  }

  componentWillUnmount() {
    this.unbindEvents(this.props);
  }

  shouldComponentUpdate(nextProps: any, nextState: any): boolean {
    if (this.dangerousUpdate) {
      this.dangerousUpdate = false;
      return true;
    }
    if (this.state.contentHeight !== nextState.contentHeight) {
      return true;
    }

    if (this.scrollafterupdate) {
      this.scrollafterupdate = false;
      this.scrollTo(this.affterUpdateScroll)
      this.scrollpro = true;
      this.noresize = true;
    }

    if (nextProps.updatelist || this.state.items.lenght !== nextState.items.lenght) {
      this.props.updated();
      return true;
    }

    if (!_.isEqual(this.props.items, nextProps.items)) {
      return true;
    }

    return this.state.topOffset !== nextState.topOffset;
  }


  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.initVirtualState(nextProps);
    this.unbindEvents(nextProps);
    this.bindEvents(nextProps);
    this.update();
  }

  initVirtualState(props: any) {
    this.virtualState = new VirtualState(
      props.viewport,
      ReactDOM.findDOMNode(this),
      props.items,
      props.itemHeight,
      props.bufferSize
    );
  }

  /**
   *
   * @param {boolean} dangerouslyUpdate default false
   */
  scrollAffterResize(dangerouslyUpdate: boolean = false): any {
    if (this.noresize || dangerouslyUpdate) {
      if (this.affterUpdateScroll) {
        this.scrollTo(this.affterUpdateScroll);
        this.noresize = false;
      }
    }
  }

  scrollTo(item: any, afterupdate: boolean = false) {
    const height = item * this.props.itemHeight;
    if (this.virtualState !== null && !afterupdate) {
      this.virtualState.viewport.setScrollTop(height);
    } else if (afterupdate) {
      this.affterUpdateScroll = item;
    }
    this.scrollafterupdate = afterupdate;
  }

  bindEvents(props: any) {

    props.viewport.addEventListener('scroll', this.handleScroll);


    if (props.viewport === window) {
      props.viewport.addEventListener('resize', this.handleResize);
    }
  }

  unbindEvents(props: any) {
    if (props.viewport === null) return;
    props.viewport.removeEventListener('scroll', this.handleScroll);
    props.viewport.removeEventListener('resize', this.handleResize);
  }

  @autobind
  update(force: boolean) {
    if (force) {
      this.virtualState.reset();
    }
    this.setState(this.virtualState.calculate());
  }

  @autobind
  dangerouslyUpdate() {
    this.dangerousUpdate = true;
    this.forceUpdate();
  }

  @autobind
  handleScroll() {
    if (this.scrollpro) {
      this.scrollpro = false;
    } else {
      this.scrollbeforeresize = false;
      this.scrollpro = false;
    }
    this.rafDebounce.request();
  }

  @autobind
  handleResize() {
    this.virtualState.reset();

    this.scrollTo(this.affterUpdateScroll);
  }

  renderItems(): any {
    if (!this.props.itemFactory || !this.state.items.length) {
      return <></>;
    }
    return this.state.items.map((item: any, index: number): any =>
      this.props.itemFactory(item, this.props, this.state, index + this.state.startIdx));
  }

  render(): any {
    const css = {
      boxSizing: 'border-box',
      height: this.state.contentHeight,
      paddingTop: this.state.topOffset ? this.state.topOffset : 0,
    };
    // eslint-disable-next-line no-undef
    return (<this.props.tagName ref={
      (ref: any): any => this.props.getRef !== undefined
      ? this.props.getRef(ref)
      : this.ref = ref} style={css}>{this.renderItems()
    }</this.props.tagName>)
  }
}
