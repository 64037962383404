import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './database-select-dialog.scss';

import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialog } from 'common/UIKit';
import { AdminCompaniesActions } from '../actions/creators/companies';
import { AdminDatabaseVm } from '../interfaces/admin-database-vm';
import { DialogDatabaseItem } from './items';
import { Feed } from '.';

export const ATTACH_DATABASES_TO_COMPANY_DIALOG_NAME = 'attachDatabasesToCompany';

const DatabasesFeedPageSize = 10;

interface ReduxProps {
  count: number;
  status: RequestStatus;
  data: AdminDatabaseVm[];
  companyId: number;
  isOpen: boolean;
  companyDatabasesIds: number[];
}

interface ReduxActions {
  loadMore: (excpetIds: number[], skip: number, take: number, search: string) => void;
  attach: (companyId: number, databaseId: number) => void;
}

interface Props extends ReduxProps, ReduxActions {
}

class DatabaseSelectDialogComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <KreoDialog
        name={ATTACH_DATABASES_TO_COMPANY_DIALOG_NAME}
        title='Attach Databases to Company'
        isModal={true}
      >
        <div className='database-select-dialog'>
          {
            this.props.isOpen &&
            <Feed
              className='database-select-dialog__feed'
              searchPlaceholder='Database name/id...'
              data={this.props.data}
              count={this.props.count}
              status={this.props.status}
              renderItem={this.renderItem}
              loadMore={this.loadMore}
            />
          }
        </div>
      </KreoDialog>
    );
  }

  @autobind
  private renderItem(item: AdminDatabaseVm): React.ReactNode {
    return <DialogDatabaseItem key={item.id} database={item} onAttach={this.attach} />;
  }

  @autobind
  private loadMore(skip: number, search: string): void {
    const { companyDatabasesIds } = this.props;

    this.props.loadMore(companyDatabasesIds, skip, DatabasesFeedPageSize, search);
  }

  @autobind
  private attach(databaseId: number): void {
    this.props.attach(this.props.companyId, databaseId);
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const companiesState = state.admin.companies;
  const dialogData = state.dialog[ATTACH_DATABASES_TO_COMPANY_DIALOG_NAME];
  const companyId = dialogData && dialogData.companyId;
  const selectedCompany = companiesState.data && Number.isInteger(companyId)
    ? companiesState.data.find(c => c.id === companyId)
    : null;

  return {
    count: companiesState.databaseFeed.count,
    data: companiesState.databaseFeed.data,
    status: companiesState.databaseFeed.status,
    companyId,
    isOpen: ATTACH_DATABASES_TO_COMPANY_DIALOG_NAME in state.dialog,
    companyDatabasesIds: selectedCompany ? selectedCompany.databases.map(d => d.id) : [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadMore: (exceptIds: number[], skip: number, take: number, search: string) =>
      dispatch(AdminCompaniesActions.loadDatabases({ exceptId: exceptIds, skip, take, search })),
    attach: (companyId: number, databaseId: number) =>
      dispatch(AdminCompaniesActions.attachDatabases({
        companyId,
        // TODO: Можно реализовать выбор нескольких БД и сделать атач одним запросом.
        databaseIds: [databaseId],
      })),
  };
};

export const DatabaseSelectDialog = connect(mapStateToProps, mapDispatchToProps)(DatabaseSelectDialogComponent);
