import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { ViewModelStatus } from '../../../../../units/projects/enums/view-model-status';
import { FinishDrawApi } from '../../drawings-geometry';
import { DrawingsDrawMode } from '../../enums';
import { DrawingComparisonSetting, DrawingContextMenuPosition, HoverState } from '../../interfaces';
import { DrawingSnapping } from '../../interfaces/drawing';
import { DrawingsCreateFile, DrawingsFileSystem, DrawingsPageResponse } from '../../interfaces/drawings-api-payload';
import { DrawingsFiles } from '../../interfaces/drawings-file-info';
import { DrawingsInstanceMeasure } from '../../interfaces/drawings-instance-measure';
import { DrawingsPdfExport } from '../../interfaces/drawings-pdf';
import { DrawingsScaleInfo } from '../../interfaces/drawings-short-drawing-info';
import { DrawingsUploadPdfResult } from '../../interfaces/drawings-upload-pdf-result';
import { CursorHintType } from '../../layout-components/drawings-cursor-hint';
import { PdfScaleFinishPatch } from '../../notifications/interfaces';
import {
  AddPagePayload,
  AddTabs,
  DrawingsDeleteFromLocal,
  DrawingsDeletePage,
  DrawingsEditFileTreeItem,
  DrawingsToggleFileOptimize,
  DuplicatePagePayload,
  ExportPdfPayload,
  LoadFilePayload,
  MoveTab,
  PageMetaParameterUpdate,
  SetComparisonPagePayload,
  SetStatusDrawingConversionPayload,
  SetStatusReadyDrawingRecognitionPayload,
  ToggleDrawingOptimizePayload,
  UpdateComparisonSettingPayload,
  UpdateSplittingStatusPayload,
} from '../payloads/common';
import { DrawingsSelectedPagesUpdates } from '../payloads/update';
import { DrawingsActionTypes } from '../types/common';


function pdfUploaded(uploadResult: DrawingsUploadPdfResult[]): ActionWith<DrawingsUploadPdfResult[]> {
  return {
    type: DrawingsActionTypes.UPLOAD_PDF,
    payload: uploadResult,
  };
}

function selectDrawing(drawingId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.SELECT_DRAWING,
    payload: drawingId,
  };
}

function saveSelectedDrawingsState(drawingId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.SAVE_SELECTED_DRAWINGS_STATE,
    payload: drawingId,
  };
}


function deleteDrawing(drawingsId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.DELETE_DRAWING,
    payload: drawingsId,
  };
}

function deletePage(drawingId: string, pdfId: string): ActionWith<DrawingsDeletePage> {
  return {
    type: DrawingsActionTypes.DELETE_PAGE,
    payload: { drawingId, pdfId },
  };
}

function deleteDrawingFromLocal(
  fileSystem: DrawingsFiles,
  drawings: string[],
  files: string[],
  selectedPages: string[],
): ActionWith<DrawingsDeleteFromLocal> {
  return {
    type: DrawingsActionTypes.DELETE_DRAWING_FROM_LOCAL,
    payload: {
      fileSystem,
      drawings,
      files,
      selectedPages,
    },
  };
}


function pageMetaParameterUpdate(
  drawingId: string,
  parameter: string,
  value: string | number,
): ActionWith<PageMetaParameterUpdate> {
  return {
    type: DrawingsActionTypes.PAGE_META_PARAMETER_UPDATE,
    payload: { drawingId, parameter, value },
  };
}


function pagesRescale(payload: DrawingsScaleInfo[]): ActionWith<DrawingsScaleInfo[]> {
  return {
    type: DrawingsActionTypes.PAGES_RESCALE,
    payload,
  };
}

function updateSelectedPages(payload: DrawingsSelectedPagesUpdates): ActionWith<DrawingsSelectedPagesUpdates> {
  return {
    type: DrawingsActionTypes.UPDATE_SELECTED_PAGES,
    payload,
  };
}

function addFolder(parentFolderId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.ADD_FOLDER,
    payload: parentFolderId,
  };
}

function loadFileData(projectId: number, fileId: string): ActionWith<LoadFilePayload> {
  return {
    type: DrawingsActionTypes.LOAD_FILE_DATA,
    payload: {
      projectId,
      fileId,
    },
  };
}

function loadFileDataSuccess(fileData: DrawingsCreateFile): ActionWith<DrawingsCreateFile> {
  return {
    type: DrawingsActionTypes.LOAD_FILE_DATA_SUCCESS,
    payload: fileData,
  };
}

function setFilesData(files: DrawingsCreateFile[]): ActionWith<DrawingsCreateFile[]> {
  return {
    type: DrawingsActionTypes.SET_FILES_DATA,
    payload: files,
  };
}

function setFileSystem(
  fs: DrawingsFileSystem,
): ActionWith<DrawingsFileSystem> {
  return {
    type: DrawingsActionTypes.LOAD_DRAWINGS_DATA_SUCCESS,
    payload: fs,
  };
}

function loadDrawingsData(): Action {
  return {
    type: DrawingsActionTypes.LOAD_DRAWINGS_DATA,
  };
}

function editFileTreeItem(
  id: string,
  name: string,
  parentId: string,
): ActionWith<DrawingsEditFileTreeItem> {
  return {
    type: DrawingsActionTypes.EDIT_FILE_TREE_ITEM,
    payload: {
      id,
      name,
      parentFolderId: parentId,
    },
  };
}

function dropState(): Action {
  return {
    type: DrawingsActionTypes.DROP_STATE,
  };
}

function expandFolder(folderId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.EXPAND_FOLDER,
    payload: folderId,
  };
}

function closeAllFolders(): Action {
  return {
    type: DrawingsActionTypes.CLOSE_ALL_FOLDERS,
  };
}

function pdfScreenshotGenerated(pdfId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.PDF_SCREENSHOTS_GENERATED,
    payload: pdfId,
  };
}

function saveMeasurements(measurements: DrawingsInstanceMeasure[]): ActionWith<DrawingsInstanceMeasure[]> {
  return {
    type: DrawingsActionTypes.SAVE_MEASUREMENTS,
    payload: measurements,
  };
}

function loadDataFailed(): Action {
  return {
    type: DrawingsActionTypes.LOAD_DATA_FAILED,
  };
}

function fullDataLoaded(): Action {
  return {
    type: DrawingsActionTypes.FULL_DATA_LOADED,
  };
}

function selectFile(entityId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.SELECT_FILE,
    payload: entityId,
  };
}

function addTabs(payload: AddTabs): ActionWith<AddTabs> {
  return {
    type: DrawingsActionTypes.ADD_TABS,
    payload,
  };
}

function removeTabs(payload: string[]): ActionWith<string[]> {
  return {
    type: DrawingsActionTypes.REMOVE_TABS,
    payload,
  };
}

function setTabs(payload: string[]): ActionWith<string[]> {
  return {
    type: DrawingsActionTypes.SET_TABS,
    payload,
  };
}

function moveTab(payload: MoveTab): ActionWith<MoveTab> {
  return {
    type: DrawingsActionTypes.MOVE_TAB,
    payload,
  };
}

function setStatusDrawingRecognition(
  pageId: string,
  status: ViewModelStatus,
): ActionWith<SetStatusReadyDrawingRecognitionPayload> {
  return {
    type: DrawingsActionTypes.SET_STATUS_DRAWING_RECOGNITION,
    payload: { pageId, status },
  };
}

function setDrawingConversionStatus(
  pdfId: string,
  status: ViewModelStatus,
): ActionWith<SetStatusDrawingConversionPayload> {
  return {
    type: DrawingsActionTypes.SET_DRAWING_CONVERSION_STATUS,
    payload: { pdfId, status },
  };
}

function exportPdf(
  projectId: number,
  pdfId: string,
  requestPayload: DrawingsPdfExport,
  currentFileOnly: boolean,
): ActionWith<ExportPdfPayload> {
  return {
    type: DrawingsActionTypes.EXPORT_PDF,
    payload: { projectId, pdfId, requestPayload, currentFileOnly },
  };
}

function duplicatePage(
  payload: DuplicatePagePayload,
): ActionWith<DuplicatePagePayload> {
  return {
    type: DrawingsActionTypes.DUPLICATE_PAGE,
    payload,
  };
}

function duplicatePageSuccess(pdfId: string, page: DrawingsPageResponse): ActionWith<AddPagePayload> {
  return {
    type: DrawingsActionTypes.DUPLICATE_PAGE_SUCCESS,
    payload: { pdfId, page },
  };
}

function updateComparisonSetting<T extends keyof Omit<DrawingComparisonSetting, 'currentId'>>(
  currentId: string,
  paramName: T,
  value: DrawingComparisonSetting[T],
): ActionWith<UpdateComparisonSettingPayload<T>> {
  return {
    type: DrawingsActionTypes.UPDATE_COMPARE_SETTING,
    payload: {
      currentId,
      paramName,
      value,
    },
  };
}

function resetComparisonSettings(
  currentId: string,
): ActionWith<string> {
  return {
    type: DrawingsActionTypes.REMOVE_COMPARISON,
    payload: currentId,
  };
}

function setPageToCompare(currentId: string, pageToCompareId: string): ActionWith<SetComparisonPagePayload> {
  return {
    type: DrawingsActionTypes.SET_PAGE_TO_COMPARE,
    payload: { currentId, pageToCompareId },
  };
}


function markDrawingWithoutSnapping(drawingId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.MARK_DRAWING_WITHOUT_SNAPPING,
    payload: drawingId,
  };
}

function markDrawingWithRenderProblems(drawingId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.MARK_DRAWING_WITH_RENDER_PROBLEMS,
    payload: drawingId,
  };
}

function toggleDrawingOptimize(
  drawingId: string,
  withReload?: boolean,
): ActionWith<ToggleDrawingOptimizePayload> {
  return {
    type: DrawingsActionTypes.TOGGLE_DRAWING_OPTIMIZE,
    payload: { drawingId, withReload },
  };
}

function toggleFileOptimize(fileId: string, value: boolean): ActionWith<DrawingsToggleFileOptimize> {
  return {
    type: DrawingsActionTypes.TOGGLE_FILE_OPTIMIZE,
    payload: { fileId, status: value },
  };
}

function updateDrawingRasterizationStatus(
  drawingsRasterizationPatch: Record<string, ViewModelStatus>,
): ActionWith<Record<string, ViewModelStatus>> {
  return {
    type: DrawingsActionTypes.UPDATE_DRAWINGS_RASTERIZATION,
    payload: drawingsRasterizationPatch,
  };
}

function patchPageScale(pageId: string, patch: PdfScaleFinishPatch):
  ActionWith<{ pageId: string, patch: PdfScaleFinishPatch }> {
  return {
    type: DrawingsActionTypes.PATCH_PAGE_SCALE,
    payload: { pageId, patch },
  };
}

function updateDrawingSplittingStatus(
  fileId: string,
  splittingStatus: ViewModelStatus,
): ActionWith<UpdateSplittingStatusPayload> {
  return {
    type: DrawingsActionTypes.UPDATE_PDF_SPLITTING,
    payload: { fileId, splittingStatus },
  };
}

function changeGroupPinStatus(groupId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.CHANGE_GROUP_PIN_STATUS,
    payload: groupId,
  };
}

function unpinGroups(groupIds: string[]): ActionWith<string[]> {
  return {
    type: DrawingsActionTypes.UNPIN_GROUPS,
    payload: groupIds,
  };
}

function pinGroups(groupIds: string[]): ActionWith<string[]> {
  return {
    type: DrawingsActionTypes.PIN_GROUPS,
    payload: groupIds,
  };
}

function setContextMenuPosition(
  contextMenuPosition: DrawingContextMenuPosition,
): ActionWith<DrawingContextMenuPosition> {
  return {
    type: DrawingsActionTypes.SET_CONTEXT_MENU_POSITION,
    payload: contextMenuPosition,
  };
}

function setHoverState(payload: HoverState): ActionWith<HoverState> {
  return {
    type: DrawingsActionTypes.SET_DRAWING_HOVER_STATE,
    payload,
  };
}

function removeHoverState(instanceId: string): ActionWith<string> {
  return {
    type: DrawingsActionTypes.REMOVE_DRAWING_HOVER_STATE,
    payload: instanceId,
  };
}

function setCurrentDrawingSnappings(snapping: DrawingSnapping): ActionWith<DrawingSnapping> {
  return {
    type: DrawingsActionTypes.SET_CURRENT_DRAWING_SNAPPING,
    payload: snapping,
  };
}

function showOptimizeMessage(show: boolean): ActionWith<boolean> {
  return {
    type: DrawingsActionTypes.SHOW_OPTIMIZE_MESSAGE,
    payload: show,
  };
}

function setDrawMode(drawMode: DrawingsDrawMode): ActionWith<DrawingsDrawMode> {
  return {
    type: DrawingsActionTypes.SET_DRAW_MODE,
    payload: drawMode,
  };
}

function setDrawingRendered(rendered: boolean): ActionWith<boolean> {
  return {
    type: DrawingsActionTypes.SET_DRAWING_RENDERED,
    payload: rendered,
  };
}

function setCursorHint(hint: CursorHintType): ActionWith<CursorHintType> {
  return {
    type: DrawingsActionTypes.SET_CURSOR_HINT,
    payload: hint,
  };
}

function toggle3d(): Action {
  return {
    type: DrawingsActionTypes.TOGGLE_IS_3D,
  };
}

function setGeometryFinishApi(finishApi: FinishDrawApi): ActionWith<FinishDrawApi> {
  return {
    type: DrawingsActionTypes.SET_FINISH_GEOMETRY_API,
    payload: finishApi,
  };
}

function setOperationIds(ids: string[]): ActionWith<string[]> {
  return {
    type: DrawingsActionTypes.SET_OPERATION_IDS,
    payload: ids,
  };
}

export const DrawingsActions = {
  pdfUploaded,
  selectDrawing,
  deleteDrawing,
  deletePage,
  pageMetaParameterUpdate,
  updateDrawingRasterizationStatus,
  exportPdf,
  addFolder,
  setFileSystem,
  setFilesData,
  loadDrawingsData,
  editFileTreeItem,
  dropState,
  deleteDrawingFromLocal,
  expandFolder,
  pdfScreenshotGenerated,
  saveMeasurements,
  fullDataLoaded,
  loadDataFailed,
  selectFile,
  addTabs,
  removeTabs,
  setTabs,
  moveTab,
  setStatusDrawingRecognition,
  saveSelectedDrawingsState,
  setDrawingConversionStatus,
  loadFileData,
  loadFileDataSuccess,
  pagesRescale,
  updateSelectedPages,
  closeAllFolders,
  duplicatePage,
  duplicatePageSuccess,

  updateComparisonSetting,
  resetComparisonSettings,
  setPageToCompare,

  updateDrawingSplittingStatus,

  toggleDrawingOptimize,
  toggleFileOptimize,

  setCurrentDrawingSnappings,
  markDrawingWithoutSnapping,
  markDrawingWithRenderProblems,

  patchPageScale,

  changeGroupPinStatus,
  unpinGroups,
  pinGroups,

  setContextMenuPosition,

  setHoverState,
  removeHoverState,

  showOptimizeMessage,
  setDrawMode,
  setDrawingRendered,
  setCursorHint,

  toggle3d,
  setGeometryFinishApi,
  setOperationIds,
};
