import React, { useMemo } from 'react';

import { ContextMenuUtils } from '../item-context-menu';
import { Item } from '../item-context-menu/menu-item-container';
import { ContentCard } from './content-card';
import { Styled } from './styled';


export interface Company {
  name: string;
  image: string;
}

export interface UserInfo {
  name: string;
  email: string;
  avatar: string;
}

interface Props {
  image: string;
  isShareWithCompany: boolean;
  projectName: string;
  menuItems: Item[];
  users: UserInfo[];
  url: string;
  RouterLink: any;
  sizeItem: string;
  menuWidth: number;
  isCalculating: boolean;
  CalculatingComponent: () => JSX.Element;
  isInaccessibleProject: boolean;
  company?: Company;
  isBlocked?: boolean;
  ErrorComponent?: () => JSX.Element;
}

export const ProjectCard = React.memo<Props>(
  ({
    image,
    company,
    isShareWithCompany,
    projectName,
    menuItems,
    users,
    url,
    RouterLink,
    ErrorComponent,
    sizeItem,
    menuWidth,
    isCalculating,
    CalculatingComponent,
    isBlocked,
    isInaccessibleProject,
  }) => {
    const Link = !isBlocked && url ? RouterLink : ({ children }) => <div>{children}</div>;
    const error = !url && !isCalculating;
    const extraProps = useMemo(() => ({ menuWidth, sizeItem }), [menuWidth, sizeItem]);
    const onOpenClick = ContextMenuUtils.useOpenContextMenu(() => menuItems, extraProps);

    return (
      <Styled.Wrapper>
        <Link to={url}>
          <Styled.Container
            image={image}
            error={error}
            isInaccessibleProject={isInaccessibleProject}
            onContextMenu={onOpenClick}
          >
            {isCalculating ? (
              <CalculatingComponent />
            ) : (
              <ContentCard
                company={company}
                isShareWithCompany={isShareWithCompany}
                projectName={projectName}
                menuItems={menuItems}
                users={users}
                url={url}
                ErrorComponent={ErrorComponent}
                sizeItem={sizeItem}
                menuWidth={menuWidth}
                isInaccessibleProject={isInaccessibleProject}
              />
            )}
          </Styled.Container>
        </Link>
      </Styled.Wrapper>
    );
  },
);
