import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';

import { TreeTableDefaultGroupRules } from 'common/components/tree-table';
import {
  QuantityTakeOffReportRowDiffStatusUtils,
} from '../../utils/quantity-take-off-tree-table/row-diff-status-utils';

export class QtoReportTableGroupRules extends TreeTableDefaultGroupRules {
  @autobind
  public availableInsertAfter(node: Ag.RowNode, overNode: Ag.RowNode): boolean {
    const parentData = overNode && overNode.parent && overNode.parent.data;

    return super.availableInsertAfter(node, overNode)
      && QuantityTakeOffReportRowDiffStatusUtils.isRowNotDeleted(parentData);
  }

  @autobind
  public availableInsertInto(node: Ag.RowNode, overNode: Ag.RowNode): boolean {
    return super.availableInsertInto(node, overNode)
      && QuantityTakeOffReportRowDiffStatusUtils.isRowNotDeleted(overNode.data);
  }
}
