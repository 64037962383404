import { ColDef, IAggFunc } from 'ag-grid-community';

import { UnitUtil } from 'common/utils/unit-util';
import { GraphStorageRecordsConfig, RecordConfig } from '../../interfaces/graph-storage-records-config';
import { SharedParameterKeyHelper } from '../../utils/shared-parameter-key-helper';
import { CustomCell } from '../quantity-take-off-common-table/custom-cell';
import { roundExtractorValue } from '../quantity-take-off-common-table/custom-cell/extractor-cell';
import { QtoLeftPanelConstants } from './constants';
import { isAdditionalProperties } from './is-additional-properties';
import { isEFPrefix } from './is-ef-prefix';
import { isExtraInfo } from './is-extra-info';


const customColumnDef: Record<string, Partial<ColDef>> = {
  ef_quantity: {
    hide: false,
  },
};

const DEFAULT_COLUMN_CHAR_WIDTH: number = 7;

const getEfProps = (columnConfig: Record<string, string>): ColDef => {
  const headerName = columnConfig.name;
  const metricUnit = columnConfig.unit;
  const imperialUnit = columnConfig.imerialUnit;

  return {
    headerName,
    enableRowGroup: false,
    cellRenderer: CustomCell.extractorCell(metricUnit, imperialUnit),
    filter: 'agNumberColumnFilter',
  };
};

const getExtraInfoProps = (): ColDef => {
  return {
    valueGetter: (params) => {
      const isImperialUnit = params.context.isImperialUnit;
      if (!params.data) {
        return '';
      }
      return isImperialUnit
        ? params.data[QtoLeftPanelConstants.EXTRA_INFO_IMPERIAL]
        : params.data[QtoLeftPanelConstants.EXTRA_INFO];
    },
  };
};

export const locationAggFunction: IAggFunc = ({ values }): number => {
  return values && values.length
    ? values.reduce((result, value) => result + Number(value), 0)
    : 0;
};

const getLocationEfProps = (columnConfig: Record<string, string>): ColDef => {
  const headerName = columnConfig.name;
  const metricUnit = columnConfig.unit;
  const imperialUnit = columnConfig.imerialUnit;

  return {
    headerName,
    enableRowGroup: false,
    aggFunc: 'locationAggFunction',
    valueFormatter: (params) => {
      const isImperialUnit = params.context.isImperialUnit;
      const { value, unit } = isImperialUnit
        ? UnitUtil.convertUnit(params.value, metricUnit, imperialUnit)
        : { value: params.value, unit: metricUnit };
      return roundExtractorValue(value, UnitUtil.getSupUnit(unit));
    },
    filter: 'agNumberColumnFilter',
    enableValue: true,
  };
};

type ApProps = { headerName: string, width: number };

const getApProps = (fieldName: string): ApProps => {
  const prefix = QtoLeftPanelConstants.ADDITIONAL_PROPERTIES_PREFIX;
  const headerName = SharedParameterKeyHelper.getNameFormKey(fieldName, prefix);
  const width = fieldName.length * DEFAULT_COLUMN_CHAR_WIDTH + 24;
  return {
    headerName,
    width,
  };
};

const getAdditionalProps = (
  fieldName: string,
  columnConfig: GraphStorageRecordsConfig,
  isLocation: boolean,
): ColDef => {
  const isEfField = isEFPrefix(fieldName);
  const isExtraInfoField = isExtraInfo(fieldName);
  const config = columnConfig.extractors[fieldName];
  if (isEfField && !config) {
    console.error(`Invalid fieldName ${fieldName}`);
  }
  if (isLocation && isEfField) {
    return getLocationEfProps(config ? config : { name: fieldName, unit: '' });
  }
  if (isEfField) {
    return getEfProps(config ? config : { name: fieldName, unit: '' });
  }
  if (isExtraInfoField) {
    return getExtraInfoProps();
  }

  const isApField = isAdditionalProperties(fieldName);
  if (isApField) {
    return getApProps(fieldName);
  }

  return {};
};

export const getColumnDef = (
  fieldName: string,
  columnConfig: GraphStorageRecordsConfig,
  isLocation: boolean,
  rowGroupIndex?: number,
): ColDef => {
  const customDefByFieldName = customColumnDef[fieldName];
  const width = fieldName.length * DEFAULT_COLUMN_CHAR_WIDTH + 24;
  const config = columnConfig[fieldName] as RecordConfig;
  const isEfField = isEFPrefix(fieldName);
  const isAdditionalField = isAdditionalProperties(fieldName);
  if (!isEfField && !isAdditionalField && (!config || !(config.columnName || config.name))) {
    console.error('Invalid filedName', fieldName);
  }
  const displayName = config && (config.columnName || config.name);

  return {
    field: fieldName,
    headerName: displayName ? displayName : fieldName,
    rowGroup: rowGroupIndex !== undefined,
    hide: true,
    enableRowGroup: !isLocation,
    rowGroupIndex,
    width,
    sortable: true,
    filter: 'agTextColumnFilter',
    ...getAdditionalProps(fieldName, columnConfig, isLocation),
    ...customDefByFieldName,
  };
};
