import React from 'react';

import { Category } from '../category';
import { MeasureGroup } from './measure-group';

interface Props {
  title: string;
  measureInstances: Record<string, string[]>;
}

const MeasureCategoryComponent: React.FC<Props> = ({
  measureInstances,
  title,
}) => {
  return (
    <Category title={title}>
      {
        Object.entries(measureInstances).map(([measure, instances]) => {
          return (
            <MeasureGroup
              key={measure}
              instancesIds={instances}
              value={measure}
            />
          );
        })
      }
    </Category>
  );
};


export const MeasureCategory = React.memo(MeasureCategoryComponent);
