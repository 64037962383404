import { MenuItem } from '@material-ui/core';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import * as React from 'react';

import './material-menu-item.scss';

const touchRippleProps = {
  className: 'custom-menu-item__ripple',
};

export const MaterialMenuItem: React.FC<MenuItemProps> = (props): JSX.Element => {
  const { className, ...rest } = props;
  return (
    <MenuItem
      classes={{
        root: classNames('custom-menu-item', className),
        selected: 'custom-menu-item--selected',
      }}
      TouchRippleProps={touchRippleProps}
      {...rest}
    >
      {props.children}
    </MenuItem>
  );
};
