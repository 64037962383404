import styled from 'styled-components';

import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';


const IconContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 10px;

  ${UpgradeWrapperStyled.StarsContainer} {
    top: -10px;
    right: -10px;
  }
`;

export const Styled = {
  IconContainer,
};
