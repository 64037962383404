import { Icons, LinkComponent, Text } from '@kreo/kreo-ui-components';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { TwoDCopilotActions } from 'unit-2d-copilot/store-slice';
import { Styled } from './styled';


interface DispatchProps {
  reset: () => void;
}

interface OwnProps {
  canReset: boolean;
}

interface Props extends DispatchProps, OwnProps {
}

const HeaderComponent: React.FC<Props> = ({
  reset,
  canReset,
}) => {
  return (
    <Styled.Container>
      <Styled.TitleContainer>
        <Icons.Caddy/>
        <Text>
          Caddie
        </Text>
      </Styled.TitleContainer>
      <LinkComponent
        text={'Reset'}
        Icon={Icons.ResetAi}
        mood={!canReset && 'disabled'}
        onClick={reset}
        rightIcon={true}
      />
    </Styled.Container>
  );
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    reset: () => {
      dispatch(TwoDCopilotActions.reset());
    },
  };
}

export const Header = connect(null, mapDispatchToProps)(HeaderComponent);
