// @flow
/* eslint-disable react/jsx-curly-spacing, react/jsx-no-bind */
import { Switch } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';

import './styles.scss';

import { MaterialSelect, MaterialMenuItem } from 'common/UIKit';

export default class Permission extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { value: this.props.value };
    this.onChange = this.onChange.bind(this);

    const TRACK_HEIGHT = '26px';
    const TRACK_WIDTH = '44px';
    const THUMB_TOP = '7px';

    this.toggleStyles = {
      thumbOff: {
        backgroundColor: '#fff',
        top: THUMB_TOP,
        left: '4px'
      },
      trackOff: {
        backgroundColor: '#a7acbd',
        height: TRACK_HEIGHT,
        width: TRACK_WIDTH
      },
      thumbSwitched: {
        backgroundColor: '#fff',
        top: THUMB_TOP,
        left: 'calc(100% + 4px)'
      },
      trackSwitched: {
        backgroundColor: '#44c74dd6',
        height: TRACK_HEIGHT,
        width: TRACK_WIDTH
      }
    };
  }

  isPrivilegesListAvailable(): any {
    return this.state.value && this.props.permission.hasOwnProperty('privileges')
      && (_.keys(this.props.permission.privileges).length > 0);
  }

  onChange(privilegeMask: number): any {
    this.setState({ value: privilegeMask });
    this.props.onChange(this.props.permission.id, privilegeMask);
  }

  render(): any {
    const PRIVILEGES_AVAILABLE = this.isPrivilegesListAvailable();
    const MAIN_INFO_CLASSES = ['permission-main-info'];
    const TITLE_CLASSES = ['permission-title'];
    let privileges = null;

    if (!this.state.value) {
      TITLE_CLASSES.push('inactive');
    }

    if (PRIVILEGES_AVAILABLE) {
      MAIN_INFO_CLASSES.push('with-privileges');

      privileges = (
        <div className="permission-privileges">
          <MaterialSelect
            value={ this.state.value }
            onChange={(e: Object, key: number, value: any): any => this.onChange(value)}
            className='permission-details__material-select'
          >
            { _.keys(this.props.permission.privileges).map((privilegeTitle: string): any => {
              const privilegeMask = this.props.permission.privileges[privilegeTitle];
              return (
                <MaterialMenuItem key={privilegeMask} value={privilegeMask}>{privilegeTitle}</MaterialMenuItem>
              );
            }) }
          </MaterialSelect>
        </div>
      );
    }

    return (
      <div className="permission-details">
        <div className={ MAIN_INFO_CLASSES.join(' ') }>
          <div className={ TITLE_CLASSES.join(' ') }>{ this.props.permission.title }</div>
          <div className="permission-description">{ this.props.permission.description }</div>
        </div>
        <div className="permission-toggle">
          <Switch thumbStyle={ this.toggleStyles.thumbOff }
                  thumbSwitchedStyle={ this.toggleStyles.thumbSwitched }
                  trackStyle={ this.toggleStyles.trackOff }
                  trackSwitchedStyle={ this.toggleStyles.trackSwitched }
                  onToggle={ (event: Object, enabled: boolean): any => this.onChange(enabled ? 1 : 0) }
          />
        </div>
        { privileges }
      </div>
    );
  }
}
