import * as React from 'react';

import './drop-menu.scss';

export class DropMenu extends React.Component {
  public render(): JSX.Element {
    return (
      <div className='drop-menu'>
        <div className='drop-menu__content'>
          {this.props.children}
        </div>
      </div>
    );
  }
}

