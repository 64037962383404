import styled from 'styled-components';


const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 8px 0;
  div {
    flex-shrink: 0;
  }
  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const InstanceInfoContainer = styled.div`
  padding: 10px 15px;
  :not(:last-child) {
    border-bottom: 10px solid ${p => p.theme.color.pale};
  }
`;

export const Styled = {
  InstanceInfoContainer,
  UserInfo,
};
