import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { MenuName, Property, PropertyTypeEnum } from '../../interfaces';
import { TwoDDatabaseActions } from '../../store-slice';
import {
  FormulaDialog,
  FORMULA_DIALOG_NAME,
  SidePanelContainer,
  ResetAssemblyOverrideDialog,
} from './components';
import { AssemblyPanelDatabese } from './components/assembly-panel-database/assembly-panel-database';
import { AssemblyPanelPage } from './components/assembly-panel-page/assembly-panel-page';
import { BreakdownDialog } from './components/breakdown-dialog';
import { ItemPanelDatabase } from './components/item-panel-database/item-panel-database';
import { ItemPanelPage } from './components/item-panel-page';
import { MeasurePanelType } from './components/measure-panel/interfaces';
import { PropertyPanel } from './components/property-panel/property-panel';
import { CLOSE_CREATE_DIALOG_NAME, RENAME_DIALOG_NAME } from './constants';
import { cashState } from './helpers';
import { SidePanelContentProps } from './interfaces';

interface StateToProps {
  isOpen: boolean;
  entityType: MenuName | MeasurePanelType;
  isRenameWarningOpened: boolean;
  isFormulaEditorOpened: boolean;
  openCloseDialog: boolean;
  withSelectBlock: boolean;
  propertyPanelType: PropertyTypeEnum;
}

interface DispatchToProps {
  onClose: (type: MenuName | MeasurePanelType) => void;
  openDialog: (name: string) => void;
}

type Props = StateToProps & DispatchToProps;

const Contents = {
  [MenuName.Properties]: PropertyPanel,
  [MenuName.Items]: ItemPanelDatabase,
  [MenuName.Assemblies]: AssemblyPanelDatabese,
  [MeasurePanelType.AssemblyMeasure]: AssemblyPanelPage,
  [MeasurePanelType.ItemMeasure]: ItemPanelPage,
};

class SidePanelComponent extends React.PureComponent<Props> {
  private cashedState: () => any = null;
  private patchData: Property = null;
  private afterCreateContentHandler: () => any = null;
  private contentStateToApply: any = null;
  private afterClose: () => void = null;

  public render(): JSX.Element {
    const {
      isOpen,
      withSelectBlock,
      isFormulaEditorOpened,
      isRenameWarningOpened,
      entityType,
      propertyPanelType,
    } = this.props;

    const Content = this.getContent();
    const isBreakdownPanel = propertyPanelType === PropertyTypeEnum.Breakdown;

    return (
      <>
        <FormulaDialog />
        <BreakdownDialog />
        <SidePanelContainer
          isOpen={isOpen}
          onClose={this.onClickOutSide}
          isClickOutsideActive={!isFormulaEditorOpened && !isRenameWarningOpened && isOpen}
          withSidePanel={withSelectBlock}
          isBreakdownPanel={isBreakdownPanel}
        >
          {Content && <Content
            afterCreate={this.handleForm}
            switchContent={this.switchContent}
            stateToApply={this.contentStateToApply}
            patchState={this.patchState}
            panelType={entityType}
            setAfterClose={this.setAfterClose}
          />}
        </SidePanelContainer>
        <ResetAssemblyOverrideDialog />
      </>
    );
  }

  @autobind
  private handleForm(data: Property): void {
    if (this.afterCreateContentHandler) {
      this.afterCreateContentHandler();
      this.contentStateToApply = this.cashedState && this.cashedState();
      this.patchData = data;
      this.afterCreateContentHandler = null;
      this.cashedState = null;
    }
  }

  @autobind
  private patchState(): Property {
    const data = this.patchData;
    this.patchData = null;
    this.cashedState = null;
    this.contentStateToApply = null;
    return data;
  }

  @autobind
  private switchContent(state: any, afterCreate: () => void): void {
    this.cashedState = cashState(state);
    this.afterCreateContentHandler = afterCreate;
  }

  @autobind
  private getContent(): React.ComponentType<SidePanelContentProps<any, any>> {
    const entityType = this.props.entityType;
    return Contents[entityType];
  }

  @autobind
  private onClickOutSide(): void {
    const { isOpen, openDialog, openCloseDialog } = this.props;
    if (isOpen) {
      if (openCloseDialog) {
        openDialog(CLOSE_CREATE_DIALOG_NAME);
      } else {
        this.close();
        this.handleForm(null);
      }
    }
  }

  @autobind
  private close(): void {
    const { onClose, entityType } = this.props;
    onClose(entityType);
    if (this.afterClose) {
      this.afterClose();
    }
  }

  @autobind
  private setAfterClose(callback: () => void): void {
    this.afterClose = callback;
  }
}

const mapStateToProps = (state: State): StateToProps => {
  return {
    isOpen: state.twoDDatabase.sidePanel.isOpen,
    entityType: state.twoDDatabase.sidePanel.entityType,
    isRenameWarningOpened: RENAME_DIALOG_NAME in state.dialog,
    isFormulaEditorOpened: FORMULA_DIALOG_NAME in state.dialog,
    openCloseDialog: state.twoDDatabase.sidePanel.openCloseDialog,
    withSelectBlock: state.twoDDatabase.sidePanel.withSelectBlock,
    propertyPanelType: state.twoDDatabase.propertyPanel.type,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchToProps => {
  return {
    onClose: (type) => {
      dispatch(TwoDDatabaseActions.closeSidePanel());
      switch (type) {
        case MenuName.Properties:
          dispatch(TwoDDatabaseActions.closePropertyPanel());
          break;
        case MenuName.Items:
        case MeasurePanelType.ItemMeasure:
          dispatch(TwoDDatabaseActions.closeItemPanel());
          break;
        case MenuName.Assemblies:
        case MeasurePanelType.AssemblyMeasure:
          dispatch(TwoDDatabaseActions.closeAssemblyPanel());
          break;
        default:
      }
    },
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
  };
};

export const SidePanel = connect(mapStateToProps, mapDispatchToProps)(SidePanelComponent);
