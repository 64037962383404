import * as paper from 'paper';

function convertColorWithAlpha(color: string, alpha: number): paper.Color {
  const paperColor = new paper.Color(color);
  paperColor.alpha = alpha;
  return paperColor;
}

export const DrawingsColorUtils = {
  convertColorWithAlpha,
};
