import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-top: auto;
  height: 40px;
  min-height: 40px;
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 10px;
  position: relative;
  padding: 10px 0px 10px 50px;
  box-sizing: border-box;
  align-items: center;
`;

const TextArea = styled.input`
  width: 100%;
  color: ${p => p.theme.color.mainFont};
  font-size: 14px;
  line-height: 10px;
  box-sizing: border-box;
  background-color: inherit;
  outline: none;
  border: none;
  resize: none;
  height: 16px;
  padding-right: 50px;

  &::placeholder {
    color: ${p => p.theme.color.disabled};
  }
`;


const Icon = styled.div`
  bottom: 10px;
  left: 20px;
  position: absolute;
  width: 20px;
  height: 20px;
  svg {
    fill: ${p => p.theme.color.gray};
  }
`;

const Button = styled.div`
  bottom: 10px;
  right: 20px;
  position: absolute;
`;

export const Styled = {
  Container,
  TextArea,
  Icon,
  Button,
};
