import { RowNode, RowSelectedEvent } from 'ag-grid-community';
import { useCallback, useRef } from 'react';
import { selectChildren } from '../../helpers/select-children';

type UseRowSelectedCallback = () => [
  (event: RowSelectedEvent) => void,
];

export const useRowSelectedCallback: UseRowSelectedCallback = () => {
  const prevSelectedStatusRef = useRef({});
  const rowSelectedCallback = useCallback((event: RowSelectedEvent) => {
    const selectDiff: RowNode[] = [];
    const selectedStatus = {};
    event.api.forEachNode(n => {
      selectedStatus[n.id] = n.isSelected();
      const prevValue = prevSelectedStatusRef.current[n.id];
      if (!!prevValue !== selectedStatus[n.id]) {
        selectDiff.push(n);
      }
    });
    selectDiff.forEach(s => selectChildren(s, selectedStatus, s.isSelected()));
    prevSelectedStatusRef.current = selectedStatus;
  }, []);

  return [rowSelectedCallback];
};
