import autobind from 'autobind-decorator';
import * as paper from 'paper';

import {
  DrawingsCanvasColors,
  DrawingsCanvasConstants,
} from 'common/components/drawings/constants/drawing-canvas-constants';
import { ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';


interface DrawingsGeometryOrthoLineConfig {
  startPoint: paper.Point;
  geometryRenderParamsObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
}

export class DrawingsGeometryOrthoLine {
  private config: DrawingsGeometryOrthoLineConfig;
  private line: paper.Path.Line;

  constructor(config: DrawingsGeometryOrthoLineConfig) {
    this.config = config;

    this.line = new paper.Path.Line(config.startPoint, config.startPoint);
    this.line.strokeColor = DrawingsCanvasColors.pointColor;
    this.config.geometryRenderParamsObserver.subscribe(this.changeRenderParams);
  }

  public changePointPosition(point: paper.Point): void {
    this.line.lastSegment.point = point;
  }

  public destroy(): void {
    this.config.geometryRenderParamsObserver.unsubscribe(this.changeRenderParams);
    this.line.remove();
  }

  @autobind
  private changeRenderParams({ zoom }: DrawingsRenderParams): void {
    this.line.strokeWidth = DrawingsCanvasConstants.infoLinesStroke / zoom;
    const dashArray = DrawingsCanvasConstants.dashArray / zoom;
    this.line.dashArray = [dashArray, dashArray];
  }
}
