import * as t from 'io-ts';

import { SubscriptionTypeC } from 'common/constants/subscription';
import { SubscriptionPlanVariantC } from './subscription-plan-variant';

export const SubscriptionPlanC =  t.type(
  {
    name: t.string,
    subscriptionType: SubscriptionTypeC,
    order: t.number,
    variants: t.array(SubscriptionPlanVariantC),
    isFree: t.boolean,
  },
  'SubscriptionPlan');

export const SubscriptionPlansC = t.array(SubscriptionPlanC);

export type SubscriptionPlan = t.TypeOf<typeof SubscriptionPlanC>;
