import { Text, Utils } from '@kreo/kreo-ui-components';

import * as React from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { MeasureUtil } from 'common/utils/measure-util';
import { UnitUtil } from 'common/utils/unit-util';
import { MoveToCellOptionType } from '../interfaces/options';
import { Styled } from './styled';

const ColorUtils = Utils.ColorUtils;

interface Props {
  name: React.ReactText;
  value: number;
  valueColor: string;
  type: MoveToCellOptionType;
  isImperial: boolean;
}

export class ContextMenuMoveToCellItem extends React.Component<Props> {
  public render(): React.ReactNode {
    const { name, value, valueColor, type, isImperial } = this.props;
    const unit = MeasureUtil.measureUnit[type];
    const Icon = MeasureUtil.measureIcon[type];
    return (
      <Styled.Container
        onMouseDown={ConstantFunctions.stopEvent}
      >
        {Icon ? <Icon /> : null}
        <Text fontSize={14}>{name}</Text>
        {value !== null
          && (
            <Styled.Value
              valueColor={valueColor}
              needReverseColor={ColorUtils.needReverseTextColorByBackgroundHex(valueColor)}
            >
              {unit ? UnitUtil.measureToString2d(value, unit, isImperial) : unit}
            </Styled.Value>
          )
        }

      </Styled.Container>
    );
  }
}
