import { CodecUtils } from 'common/utils/codec-utils';

export enum ProjectType {
  Project2d = 'Project2D',
  Project3d = 'Project3D',
  Project2dTemplate = 'Project2DTemplate',
  Database = 'Database',
}

export enum DemoProjectType {
  Project2d = 'Project2D',
  PiaDb = 'PiaDb',
}

export const ProjectTypeC = CodecUtils.createEnumCodec<ProjectType>(ProjectType, 'ProjectType');

export const DemoProjectTypeC = CodecUtils.createEnumCodec<DemoProjectType>(DemoProjectType, 'DemoProjectType');
