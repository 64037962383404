import styled from 'styled-components';
import { TooltipStyled } from 'common/components/tooltip';

const AggregationItemContainer = styled.div`
  padding: 0px 10px;
  height: 35px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.color.background};
`;

const AggreationItemTitle = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    min-width: 20px;
  }
`;

const Title = styled.div`
  display: flex;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 5px;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    fill: ${(p) => p.theme.color.gray};
  }

  ${TooltipStyled.TooltipElementWrapper} {
    margin-left: auto;
  }
`;

const ActionButtonsContainer = styled.div`
  border-top: 1px solid ${p => p.theme.color.background};
  padding: 7px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Styled = {
  ActionButtonsContainer,
  AggregationItemContainer,
  ButtonsContainer,
  Title,
  AggreationItemTitle,
};
