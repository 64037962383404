import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './activity-assignment-activity-tile.scss';

import { AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';
import {
  IconButton,
  KreoIconAction,
  MaterialIconMenu,
  MaterialMenuItem,
  MaterialMenuItemProps,
  MaterialSelect,
} from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { Toggle } from '../../../../components/toggle';
import { ActivityAssignmentActions } from '../../actions/creators/activity-assignment';
import {
  ActivityAssignmentMaterialVariant,
} from '../../interfaces/activity-assignment/activity-assignment-material-variant';
import { ActivityAssignmentSubVariant } from '../../interfaces/activity-assignment/activity-assignment-sub-variant';
import {
  ActivityAssignmentActivityTileBody,
  ActivityAssignmentActivityTileBodyTitle,
  ActivityAssignmentActivityTileContainer,
} from '../activity-assignment-activity-tile-containers';
import { ActivityAssignmentSelectMaterialVariant } from '../activity-assignment-select-material-variant';

interface OwnProps {
  index: number;
}

interface StateProps {
  materials: ActivityAssignmentMaterialVariant[];
  subVariants: ActivityAssignmentSubVariant[];
  selectedDbModalityId: number;
  isUsed: boolean;
}

interface DispatchProps {
  toggleUsedStatus: () => void;
  deleteActivity: () => void;
  selectActivity: (activity: ActivityAssignmentSubVariant) => void;
  selectMaterial: (materialSelectIndex: number, activitySubVariantId: number, materialId: number) => void;
}

interface Props extends StateProps, DispatchProps, OwnProps {
}


class ActivityAssignmnentActivityTileComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isUsed, selectedDbModalityId, subVariants, materials } = this.props;
    const renderMaterials = materials && materials.filter(x => x.subVariants.length);
    return (
      <ActivityAssignmentActivityTileContainer enabled={isUsed}>
        <div className='activity-assignment-activity-tile__header'>
          <div className='activity-assignment-activity-tile__check'>
            <Toggle
              checkedColor='#5C8AE6'
              checked={isUsed}
              onCheck={this.props.toggleUsedStatus}
              controlName='tile-check-toggle'
            />
          </div>
          <MaterialIconMenu
            onClick={this.onIconMenuClick}
            iconButtonElement={this.getIconButtonComponent()}
            className='activity-assignment-activity-tile__menu'
            controlName='activity-tile-menu'
          >
            <MaterialMenuItem onClick={this.props.deleteActivity}>Delete</MaterialMenuItem>
          </MaterialIconMenu>
        </div>
        <ActivityAssignmentActivityTileBody>
          <div className='activity-assignment-activity-tile__variant'>
            <ActivityAssignmentActivityTileBodyTitle>
                Activity Variant
            </ActivityAssignmentActivityTileBodyTitle>
            <MaterialSelect
              displayedType={MaterialComponentType.Native}
              dropdownClassName='activity-assignment-activity-tile__dropdown'
              value={selectedDbModalityId}
              onChange={this.onChangeActivity}
            >
              {subVariants.map(this.renderSubVariant)}
            </MaterialSelect>
          </div>
          {
            renderMaterials && !!renderMaterials.length && (
              <div className='activity-assignment-activity-tile__material'>
                <ActivityAssignmentActivityTileBodyTitle>
                  Materials
                </ActivityAssignmentActivityTileBodyTitle>
                {this.props.materials.map(this.renderMaterialSelect)}
              </div>
            )
          }
        </ActivityAssignmentActivityTileBody>
      </ActivityAssignmentActivityTileContainer>
    );
  }

  @autobind
  private onChangeActivity(_e: React.SyntheticEvent<Element>, value: number): void {
    const selectedSubVariant = this.props.subVariants.find(x => x.dbModalityId === value);
    this.props.selectActivity(selectedSubVariant);
  }

  @autobind
  private renderMaterialSelect(materialSelect: ActivityAssignmentMaterialVariant, index: number): React.ReactNode {
    const { selectedMaterialId, subVariants } = materialSelect;
    return (
      <ActivityAssignmentSelectMaterialVariant
        key={materialSelect.rootMaterialId}
        index={index}
        selectedMaterialId={selectedMaterialId}
        activitySubVariantId={this.props.selectedDbModalityId}
        subVariants={subVariants}
        selectMaterial={this.props.selectMaterial}
      />
    );
  }


  private renderSubVariant(subVariant: ActivityAssignmentSubVariant): React.ReactElement<MaterialMenuItemProps> {
    return (
      <MaterialMenuItem
        key={subVariant.dbModalityId}
        value={subVariant.dbModalityId}
      >
        {subVariant.name}
      </MaterialMenuItem>
    );
  }

  private getIconButtonComponent(): React.ReactNode {
    return (
      <IconButton
        size='small'
        className={'activity-assignment-activity-tile__menu-button'}
      >
        <KreoIconAction />
      </IconButton>
    );
  }

  private onIconMenuClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }
}


function mapStateToProps(state: State, { index }: OwnProps): StateProps {
  const { subVariants, selectedDbModalityId, isUsed } = state.activityAssignment.selectedWorks[index];
  const selectedVariant = subVariants.find(x => x.dbModalityId === selectedDbModalityId);

  return { subVariants, selectedDbModalityId, isUsed, materials: selectedVariant.materials };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { index }: OwnProps): DispatchProps {
  return {
    deleteActivity: () => {
      dispatch(ActivityAssignmentActions.removeWork(index));
      dispatch(ActivityAssignmentActions.assignActivity());
    },
    toggleUsedStatus: () => {
      dispatch(ActivityAssignmentActions.checkActivity(index));
      dispatch(ActivityAssignmentActions.assignActivity());
    },
    selectActivity: (value: ActivityAssignmentSubVariant) => {
      dispatch(ActivityAssignmentActions.setModality({ index, subVariantData: value }));
      dispatch(ActivityAssignmentActions.assignActivity());
    },
    selectMaterial: (materialSelectIndex, activitySubVariantId, materialId) => {
      dispatch(ActivityAssignmentActions.selectMaterial(materialSelectIndex, activitySubVariantId, materialId, index));
      dispatch(ActivityAssignmentActions.assignActivity());
    },
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityAssignmentActivityTile = connector(ActivityAssignmnentActivityTileComponent);
