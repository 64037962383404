import * as t from 'io-ts';

import { IsoDateStringC } from 'common/codecs/iso-date-string';

export const BidPricingInfoC = t.strict(
  {
    id: t.number,
    isPrivate: t.boolean,
    startDate: IsoDateStringC,
    endDate: IsoDateStringC,
    calculationId: t.number,
    description: t.string,
  },
  'BidPricingInfo');

export type BidPricingInfo = t.TypeOf<typeof BidPricingInfoC>;
