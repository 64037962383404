import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { AssignedPia } from '../../../../../units/2d';
import { DrawingsAnnotationActions } from '../../actions/creators/annotation';
import { DrawingsAnnotationLegendActions } from '../../actions/creators/drawings-annotation-legend';
import {
  GroupPayloadForInstanceCreate,
  useRendererApiContext,
} from '../../drawings-contexts';
import { useDrawingsLayoutApi } from '../../drawings-layout-api-context';
import { DrawingsDrawMode } from '../../enums';
import {
  DrawingsGeometryInstanceWithId,
  DrawingsShortInfo,
  ShortPointDescription,
} from '../../interfaces';
import { DrawingsFile } from '../../interfaces/drawings-file-info';
import { CreateGeometrytAnaliticsGroups, DrawingsAnalyticsUtils } from '../../utils/drawings-analytics-utils';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';
import { useSendCreateMeasure } from '../analytics/use-send-create-measure';
import { useDrawingMode } from '../use-draw-mode-api';
import { useGroupsForInstanceCreate } from './use-groups-for-instance-create';

export interface AddInstancesPayload {
  instances: DrawingsGeometryInstanceWithId[];
  points: Record<string, ShortPointDescription>;
  initiator?: string;
  pia?: Record<string, AssignedPia>;
  ignoreSaveMeasuresOnCreate?: boolean;
  analyticsParams?: Record<string, string | number>;
}

export interface AddInstancesWithGroupPayload extends AddInstancesPayload {
  forceSave?: boolean;
  moveToSelectedGroup?: boolean;
}

export type AddInstanceCallback = (payload: AddInstancesPayload) => void;
export type AddInstanceWithGroupCallback = (
  payload: AddInstancesWithGroupPayload,
  groupsPayload?: GroupPayloadForInstanceCreate,
) => void;

function remove3dProperties(instance: DrawingsGeometryInstanceWithId): void {
  if (DrawingsGeometryUtils.isPolyline(instance.type, instance.geometry)) {
    delete instance.geometry.height;
    delete instance.geometry.offset;
    delete instance.geometry.thickness;
  } else if (DrawingsGeometryUtils.isClosedContour(instance.type, instance.geometry)) {
    delete instance.geometry.height;
    delete instance.geometry.offset;
  }
}

export function useAddInstancesToState(): AddInstanceCallback {
  const dispatch = useDispatch();
  const sendCreateMeasureEvent = useSendCreateMeasure();
  const drawMode = useDrawingMode();
  const canEdit3d = useAbility(Operation.Update, Subject.Takeoff2dMeasurement3d);
  const drawingLayoutApi = useDrawingsLayoutApi();

  const drawings = useSelector<State, Record<string, DrawingsShortInfo>>(s => s.drawings.drawingsInfo);
  const files = useSelector<State, Record<string, DrawingsFile>>(
    s => s.drawings.files.entities as Record<string, DrawingsFile>,
  );

  return useCallback((payload: AddInstancesPayload) => {
    const { instances, points, pia, ignoreSaveMeasuresOnCreate, initiator, analyticsParams } = payload;
    const groupToInstancesMap: Record<string, string[]> = {};
    const groupedForAnalitics: Record<string, CreateGeometrytAnaliticsGroups[]> = {};

    const remove3dProps = canEdit3d ? ConstantFunctions.doNothing : remove3dProperties;
    instances.forEach(instance => {
      const { groupId } = instance;
      if (groupId) {
        groupToInstancesMap[groupId] = groupToInstancesMap[groupId] || [];
        groupToInstancesMap[groupId].push(instance.id);
      }
      DrawingsAnalyticsUtils.addInstanceToGroup(groupedForAnalitics, instance, drawings, files);
      remove3dProps(instance);
    });

    const groupToInstancesList = Object.entries(groupToInstancesMap);
    if (groupToInstancesList.length) {
      dispatch(DrawingsAnnotationLegendActions.addInstanceToGroup(groupToInstancesList));
    }
    dispatch(DrawingsAnnotationActions.addInstance(instances, points, pia));
    const modeName = initiator
      || Object.entries(DrawingsDrawMode).find(([, value]) => value === drawMode)[0];
    sendCreateMeasureEvent(groupedForAnalitics, modeName, analyticsParams || {});
    if (!ignoreSaveMeasuresOnCreate && drawingLayoutApi?.saveMeasuresOnCreate) {
      drawingLayoutApi.saveMeasuresOnCreate(instances, points);
    }
  }, [dispatch, sendCreateMeasureEvent, drawMode, canEdit3d, drawingLayoutApi, drawings, files]);
}

export function useAddInstances(): AddInstanceWithGroupCallback {
  const addInstances = useAddInstancesToState();
  const groupsPayloadFromState = useGroupsForInstanceCreate();
  const { rendererApi } = useRendererApiContext();
  return useCallback((payload: AddInstancesWithGroupPayload, groups?: GroupPayloadForInstanceCreate) => {
    const { forceSave, moveToSelectedGroup } = payload;
    const { groupForInstanceCreation, useGroupNameForNewGeometry } = groups || groupsPayloadFromState;
    const wrappedName = useGroupNameForNewGeometry
      ? groupForInstanceCreation?.name
      : null;
    payload.instances = moveToSelectedGroup || wrappedName
      ? payload.instances.map(instance => {
        if (moveToSelectedGroup) {
          instance.groupId = groupForInstanceCreation?.id;
        }
        if (wrappedName) {
          instance.name = wrappedName;
        }
        return instance;
      })
      : payload.instances;
    if (rendererApi?.engine && !forceSave) {
      rendererApi.engine.forceRenderInstances(payload).then(() => {
        setTimeout(() => addInstances(payload), 0);
      });
    } else {
      addInstances(payload);
    }
  }, [addInstances, groupsPayloadFromState, rendererApi]);
}
