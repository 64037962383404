import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { ShortPointDescription } from 'common/components/drawings/interfaces';
import { VisibleEntity, VisibleEntityConfig } from '../../common';

interface Config extends VisibleEntityConfig<ShortPointDescription[]> {
  color?: paper.Color;
  layer: paper.Layer | paper.Group;
  onMouseEnter?: (e: PaperMouseEvent) => void;
}


export class DrawingsUtilityPolyline extends VisibleEntity<ShortPointDescription[], Config> {
  private _path: paper.Path;

  public set strokeWidth(value: number) {
    this._path.strokeWidth = value;
  }

  public destroy(): void {
    this._path.remove();
  }

  protected render(geometry: ShortPointDescription[]): void {
    this._path = new paper.Path(geometry.map(x => new paper.Point(x)));
    this._path.strokeColor = this._config.color || DrawingsCanvasColors.utility;
    this._path.addTo(this._config.layer);
    this._path.shadowColor = this._path.strokeColor;
    this._path.onMouseEnter = this._config.onMouseEnter;
  }
}
