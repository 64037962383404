import { Icons, IconInput } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { detailedDiff } from 'deep-object-diff';
import produce from 'immer';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { getNameById } from 'unit-2d-database/components/breakdown-property/utils';
import { getDynamicData } from 'unit-2d-database/helpers/get-dynamic-data';
import { isDiffEmpty } from 'utils/object-diff';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import {
  AllPropertyValues,
  Assembly,
  Item,
  Property,
  UpdateAssemblyPayload,
  UpdateItemRequest,
  VisibilityFilterType,
} from '../../../../interfaces';
import { TwoDDatabaseActions } from '../../../../store-slice';
import { RENAME_DIALOG_NAME } from '../../constants';
import { convertPropertiesIterator } from '../../helpers/convert-properties-iterator';
import { PropertyGroupForm } from '../../helpers/update-property-form';
import { validateName } from '../../helpers/validate-name';
import { SidePanelContentProps } from '../../interfaces';
import { BREAKDOWN_DIALOG_NAME } from '../breakdown-dialog';
import { ConfirmDialogs } from '../confirm-dialogs';
import { DatabaseEmptyList } from '../empty-list-notification';
import { EmptyListName } from '../empty-list-notification/constants';
import { FormulaDialogData, FORMULA_DIALOG_NAME } from '../formula-dialog';
import { ItemGroupForm } from '../group-item-form';
import { Header } from '../header';
import { FormulaFieldInput } from '../item-panel/helpers';
import { mapPropertyGroupForm } from '../item-panel/helpers/map-property-group-form';
import { SelectItemPanel } from '../select-item-panel';
import { VisibilityPanel } from '../visibility-panel';
import { isChangePrevValue } from './helpers';
import { AssemblyForm, ItemGroupFormData, ItemUpdater } from './item-form-updater';
import { GroupForm, mapItemGroupForm } from './map-items-to-form';

import { Styled } from './styled';

interface StateToProps {
  isEdit: boolean;
  assembliesDatabase: Assembly[];
  data: Assembly;
  itemsDatabase: Item[];
  propertiesDatabase: Property[];
  visibilityMode: VisibilityFilterType;
  collapsedGroups: Set<string>;
}

interface DispatchToProps {
  openFormulaDialog: (data: FormulaDialogData) => void;
  openBreakdownDialog: (data: any) => void;
  openRenameDialog: () => void;
  close: () => void;
  open: (type: string) => void;
  setCollapsedGroups: (ids: Set<string>) => void;
}

export interface OwnState {
  name: string;
  form: AssemblyForm;
  selectedIdsMap: Record<string, Record<string, boolean>>;
  formUpdater: ItemUpdater;
  addPropertyToItem: string;
  addPropertyToBaseItem: string;
  isValid: boolean;
  units: string[];
  showSelectionPanel: boolean;
}

interface OwnProps {
  isShowRenameDialog: (assemblies: Assembly[], data: Assembly, name: string) => boolean;
  update: (updateAssembly: UpdateAssemblyPayload, data: Assembly) => void;
  create: (name: string, items: Item[]) => void;
  skipCloseAfterSubmit?: boolean;
  disableHeaderEdit?: boolean;
  afterClose?: () => void;
  isSkipBaseItem?: boolean;
}

interface Props extends StateToProps,
  DispatchToProps,
  SidePanelContentProps<Property, OwnState>,
  OwnProps,
  AnalyticsProps {
}

type PropertyMap = Record<string, Property>;
type ItemMapElement = Item & { propertyMap: PropertyMap };

const itemGroupFormKey = 'itemGroup';

class AssemblyPanelComponent extends React.PureComponent<Props, OwnState> {
  private updaterParams = {
    baseParams: {
      getForm: this.getForm,
      afterEffects: {
        afterChange: this.changeState,
        afterDelete: this.afterDeleteProperty,
        afterUnitChange: this.changeState,
        afterVisibilityChange: this.changeState,
      },
    },
    propertyGroupParams: {
      afterDeletePropertyGroup: this.afterDeletePropertyGroup,
      afterAddProperty: this.afterAddProperty,
      afterDeleteItem: this.afterDeleteItemGroup,
      afterAddItem: this.afterAddItem,
      afterChangeFormula: this.changeState,
    },
  };

  private dataBaseItemMap: Record<string, ItemMapElement> = {};
  private assemblyItemMap: Record<string, ItemMapElement> = {};

  public constructor(props: Props) {
    super(props);

    this.state = props.stateToApply
      ? this.getApplyState()
      : this.getInitialState();
  }

  public componentDidMount(): void {
    const patchData = this.props.patchState();
    if (patchData) {
      this.patchState(patchData);
    }
    setTimeout(() => {
      this.setState({ showSelectionPanel: true });
    }, 450);
    this.dataBaseItemMap = this.geItemMap(this.props.itemsDatabase);
    this.assemblyItemMap = this.geItemMap(this.props.data?.items);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.itemsDatabase !== this.props.itemsDatabase) {
      this.dataBaseItemMap = this.geItemMap(this.props.itemsDatabase);
    }
  }

  public render(): JSX.Element {
    const { isEdit, visibilityMode, collapsedGroups, disableHeaderEdit } = this.props;
    const { form, selectedIdsMap, name, isValid, addPropertyToBaseItem } = this.state;
    const showNotification = Object.keys(form.itemGroupForm).length === 0;

    return (
      <Styled.Container>
        <Styled.PanelBlock>
          <Styled.PanelContainer>
            <Styled.AssemblyForm>
              <Header
                headerIcon={Icons.AssembliesDataBase}
                headerText={'Assembly'}
                isValid={isValid}
                isEdit={isEdit}
                onSubmit={this.onSubmit}
              />
              <IconInput
                name={'Assembly name'}
                value={name}
                onChangeValue={this.changeName}
                disabled={disableHeaderEdit}
              />
            </Styled.AssemblyForm>
            <VisibilityPanel groups={this.state.form.itemGroupForm} getGroupKeys={this.getCommonGroupKeys} />
            <Styled.ScrollBarContainer>
              <ItemGroupForm
                groups={form.itemGroupForm}
                visibilityMode={visibilityMode}
                collapsedGroups={collapsedGroups}
                toggleGroup={this.toggleGroup}
                switchPanel={this.switchPanel}
                onClickSelect={this.onClickSelect}
                parentId={itemGroupFormKey}
                withInhered={false}
              />
              <RenderIf condition={showNotification}>
                <DatabaseEmptyList type={EmptyListName.Assemblies} />
              </RenderIf>
            </Styled.ScrollBarContainer>
          </Styled.PanelContainer>
        </Styled.PanelBlock>
        <Styled.PanelBlock>
          <Styled.TableContainer>
            <SelectItemPanel
              setUsedItems={this.setUsedItems}
              selectedIdsMap={selectedIdsMap}
              selectItemId={addPropertyToBaseItem}
              setUsedProperties={this.setUsedProperties}
              hideTable={!this.state.showSelectionPanel}
            />
          </Styled.TableContainer>
        </Styled.PanelBlock>
        <ConfirmDialogs
          closeDialogTitle='Are you sure you want to exit the "Create Assembly" Mode?'
          closeDialogOnClose={this.close}
          renameTitle='Assembly with the same name already exists'
          renameDialogText='Please rename your assembly'
        />
      </Styled.Container>
    );
  }

  private geItemMap(items?: Item[]): Record<string, ItemMapElement> {
    const map: Record<string, ItemMapElement> = {};

    items?.forEach(i => {
      map[i.id] = { ...i, propertyMap: this.getPropertiesMap(i.properties) };
    });

    return map;
  }

  private getPropertiesMap(properties: Property[]): PropertyMap {
    const map = {};
    properties.forEach(p => {
      map[p.name] = p;
    });
    return map;
  }

  @autobind
  private getCommonGroupKeys(): Set<string> {
    const { form } = this.state;
    const totalSet = new Set<string>();
    Object.entries(form.itemGroupForm).forEach(([key, value]) => {
      totalSet.add(`${itemGroupFormKey}_${key}`);
      Object.keys(value.itemForm.groupForm).forEach(propertyGroupKey => {
        totalSet.add(`${itemGroupFormKey}_${key}_${propertyGroupKey}`);
      });
    });
    return totalSet;
  }

  @autobind
  private patchState(property: Property): void {
    const units = this.getUnits(property);
    this.updateUnits(units);
    const itemId = this.state.addPropertyToItem;
    const groupForm = mapPropertyGroupForm(
      [[{ ...property, id: UuidUtil.generateUuid() }, property]],
      units,
      this.state.formUpdater.getPropertyGroupUpdater(itemId),
      (id, groupName) => this.onFormulaFieldClick(itemId, id, groupName),
      (id, groupName) => this.onBreakdownFieldClick(itemId, id, groupName),
    );
    this.state.formUpdater.addPropertyGroup(itemId, groupForm);
  }

  private updateUnits(units: string[]): void {
    const newState = produce(this.state, (s) => {
      s.units = units;
    });
    this.setState(newState);
  }

  private getApplyState(): OwnState {
    const applyState = this.props.stateToApply;
    applyState.units = this.getUnits();
    applyState.formUpdater.applyNewAfterEffects(
      this.updaterParams.baseParams,
      this.updaterParams.propertyGroupParams,
    );
    return applyState;
  }

  private getInitialState(): OwnState {
    const items = this.props.data ? this.props.data.items : [];
    const units = this.getUnits();

    const map = {};
    items.forEach(item => {
      map[item.baseItemId] = {};
      this.extendSelectedIdsByProperties(map, item.baseItemId, item.properties);
      const baseItem = this.props.itemsDatabase.find(i => item.baseItemId === i.id);
      if (baseItem) {
        this.extendSelectedIdsByProperties(map, baseItem.id, baseItem.properties);
      }
    });

    const formUpdater = new ItemUpdater(
      this.updaterParams.baseParams,
      this.updaterParams.propertyGroupParams,
    );
    const itemGroupFrom = mapItemGroupForm(
      items,
      formUpdater,
      units,
      this.onFormulaFieldClick,
      this.onBreakdownFieldClick,
      this.props.propertiesDatabase,
      this.props.itemsDatabase,
      undefined,
      undefined,
      this.props.isSkipBaseItem,
    );
    return {
      selectedIdsMap: map,
      name: this.props.data ? this.props.data.name : '',
      form: {
        addedItems: {},
        removedItems: {},
        itemGroupForm: itemGroupFrom,
      },
      formUpdater,
      addPropertyToItem: null,
      addPropertyToBaseItem: null,
      isValid: this.props.isEdit,
      units,
      showSelectionPanel: false,
    };
  }

  private extendSelectedIdsByProperties(
    map: Record<string, Record<string, boolean>>,
    itemId: string,
    properties: Property[],
  ): void {
    properties.forEach(prop => {
      if (!prop.value) {
        return;
      }
      const dataBaseProperty = this.props.propertiesDatabase.find(p => p.name === prop.name);
      if (dataBaseProperty) {
        map[itemId][dataBaseProperty.id] = true;
      }
    });
  }

  private getUnits(property?: Property): string[] {
    const list = property
      ? [...this.props.propertiesDatabase, property]
      : this.props.propertiesDatabase;
    const [, units] = getDynamicData(list);
    return units;
  }

  @autobind
  private getForm(): AssemblyForm {
    return this.state.form;
  }

  @autobind
  private changeState(newForm: AssemblyForm): void {
    this.setState({ form: newForm });
  }

  @autobind
  private afterAddItem(newForm: AssemblyForm, _: string, form: GroupForm): void {
    this.setState((state) => {
      const newState = produce(state, s => {
        Object.values(form).forEach(itemForm => {
          s.selectedIdsMap[itemForm.originId] = {};
          for (const propertyId of this.getDataBasePropertyIdsIterator(itemForm)) {
            s.selectedIdsMap[itemForm.originId][propertyId] = true;
          }
        });
        s.form = newForm;
      });
      return newState;
    });
  }

  @autobind
  private afterAddProperty(newForm: AssemblyForm, itemId: string): void {
    this.setState((state) => {
      const newFormWithExtendsUnits = produce(newForm, (s) => {
        Object.values(s.itemGroupForm[itemId].itemForm.groupForm).forEach(p => {
          p.fields.forEach(f => {
            if (f.dropDownProps) {
              const activeElement = f.dropDownProps.elements[f.dropDownProps.activeElementIndex];
              f.dropDownProps.elements = state.units;
              f.dropDownProps.activeElementIndex = f.dropDownProps.elements.indexOf(activeElement);
            }
          });
        });
      });
      const newState = produce(state, s => {
        s.form = newFormWithExtendsUnits;
        const itemForm = newForm.itemGroupForm[itemId];
        s.selectedIdsMap[itemForm.originId] = {};
        for (const propertyId of this.getDataBasePropertyIdsIterator(itemForm)) {
          s.selectedIdsMap[itemForm.originId][propertyId] = true;
        }
        s.addPropertyToBaseItem = null;
        s.addPropertyToItem = null;
      });
      return newState;
    });
  }

  private *getDataBasePropertyIdsIterator(itemForm: ItemGroupFormData): IterableIterator<string> {
    for (const groupForm of Object.values(itemForm.itemForm.groupForm)) {
      for (const field of groupForm.fields) {
        yield field.originId;
      }
    }
  }

  @autobind
  private afterDeleteItemGroup(newForm: AssemblyForm, itemId: string): void {
    this.setState((state) => {
      const newState = produce(state, s => {
        const itemForm = s.form.itemGroupForm[itemId];
        s.selectedIdsMap[itemForm.originId] = undefined;
        s.form = newForm;
      });
      return newState;
    });
  }

  @autobind
  private afterDeleteProperty(
    newForm: AssemblyForm,
    itemId: string,
    _f: GroupForm,
    g: string,
    _fields: PropertyGroupForm,
    fieldId: string,
  ): void {
    this.setState((state) => {
      const newState = produce(state, s => {
        const itemForm = s.form.itemGroupForm[itemId];
        const field = s.form.itemGroupForm[itemId].itemForm.groupForm[g].fields.find(f => f.id === fieldId);
        s.selectedIdsMap[itemForm.originId][field.originId] = false;
        s.form = newForm;
      });
      return newState;
    });
  }

  @autobind
  private afterDeletePropertyGroup(
    newForm: AssemblyForm,
    itemId: string,
    _f: GroupForm,
    groupName: string,
  ): void {
    this.setState((state) => {
      const newState = produce(state, s => {
        const itemForm = s.form.itemGroupForm[itemId];
        itemForm.itemForm.groupForm[groupName].fields.forEach(f => {
          s.selectedIdsMap[itemForm.originId][f.originId] = false;
        });
        s.form = newForm;
      });
      return newState;
    });
  }

  @autobind
  private switchPanel(itemId: string): void {
    const stateToSave: OwnState = { ...this.state, addPropertyToItem: itemId };
    this.props.switchContent(stateToSave, this.afterCreateProperty);
  }

  @autobind
  private onClickSelect(itemId: string, baseId: string): void {
    this.setState({ addPropertyToItem: itemId, addPropertyToBaseItem: baseId });
  }

  @autobind
  private afterCreateProperty(): void {
    this.props.open(this.props.panelType);
  }

  @autobind
  private changeName(event: React.ChangeEvent<HTMLInputElement>): void {
    const isValid = validateName(event.currentTarget.value);
    this.setState({ name: event.currentTarget.value, isValid });
  }

  private mapUsedItem(item: Item): Item {
    return {
      ...item,
      baseItemId: item.id,
      id: UuidUtil.generateUuid(),
      properties: item.properties.map(property => ({ ...property, id: UuidUtil.generateUuid() })),
    };
  }

  @autobind
  private setUsedItems(ids: string[]): void {
    const idsSet = new Set(ids);
    const { itemsDatabase, propertiesDatabase } = this.props;
    const { formUpdater, units } = this.state;
    const items = arrayUtils.filterMap(
      itemsDatabase,
      i => idsSet.has(i.id),
      this.mapUsedItem,
    );

    const form = mapItemGroupForm(
      items,
      formUpdater,
      units,
      this.onFormulaFieldClick,
      this.onBreakdownFieldClick,
      propertiesDatabase,
      this.props.itemsDatabase,
    );

    formUpdater.updateAssemblyForm(form);
  }

  @autobind
  private setUsedProperties(ids: string[]): void {
    const { formUpdater, addPropertyToItem, units } = this.state;
    const { propertiesDatabase } = this.props;
    const propertiesGroupFrom = mapPropertyGroupForm(
      convertPropertiesIterator(ids, propertiesDatabase),
      units,
      formUpdater.getPropertyGroupUpdater(addPropertyToItem),
      (id, groupName) => this.onFormulaFieldClick(addPropertyToItem, id, groupName),
      (id, groupName) => this.onBreakdownFieldClick(addPropertyToItem, id, groupName),
    );
    this.state.formUpdater.addPropertyGroup(addPropertyToItem, propertiesGroupFrom);
  }

  @autobind
  private onFormulaFieldClick(itemId: string, propertyId: string, groupName: string): void {
    const field = this.state.form.itemGroupForm[itemId]
      .itemForm.groupForm[groupName].fields.find(f => f.id === propertyId);
    const usedPropertyNames = [];
    for (const { itemForm: { groupForm } } of Object.values(this.state.form.itemGroupForm)) {
      for (const { fields } of Object.values(groupForm)) {
        for (const { name } of fields) {
          usedPropertyNames.push(name);
        }
      }
    }
    this.props.openFormulaDialog({
      canAddProperties: true,
      propertyName: field.name,
      itemProperties: usedPropertyNames,
      formulaValue: (field.input as FormulaFieldInput).value,
      onChangeValue: (value: string, propertiesToAdd: string[]) => {
        const groupsForm = mapPropertyGroupForm(
          convertPropertiesIterator(
            this.getNotAddedPropertiesIdByNamesIterator(propertiesToAdd, itemId),
            this.props.propertiesDatabase,
          ),
          this.state.units,
          this.state.formUpdater.getPropertyGroupUpdater(itemId),
          (id, propertyGroupName) => this.onFormulaFieldClick(itemId, id, propertyGroupName),
          (id, propertyGroupName) => this.onBreakdownFieldClick(itemId, id, propertyGroupName),
        );
        const newField = {
          ...field,
          input: {
            ...(field.input as FormulaFieldInput),
            value,
          },
        };
        this.state.formUpdater.changeFormula(itemId, groupsForm, newField, groupName);
      },
    });
  }

  @autobind
  private onBreakdownFieldClick(itemId: string, propertyId: string, groupName: string): void {
    const field: any = this.state.form.itemGroupForm[itemId]
      .itemForm.groupForm[groupName].fields.find(f => f.id === propertyId);
    this.props.openBreakdownDialog({
      propertyName: field.name,
      root: field.input.root,
      selectedId: field.input.selectedId,
      onSelect: (breakdownId: string) => {
        const newField: any = produce(field, (s) => {
          const name = getNameById(field.input.root, breakdownId);
          s.input.value = name;
          s.input.selectedId = breakdownId;
        });
        const [change] = this.state.formUpdater.getPropertyGroupUpdater(itemId).getFieldHandlers(propertyId, groupName);
        change(newField.input);
      },
    });
  }

  private *getNotAddedPropertiesIdByNamesIterator(names: string[], itemId: string): IterableIterator<string> {
    const propertyGroups = this.state.form.itemGroupForm[itemId].itemForm.groupForm;
    for (const name of names) {
      const property = this.props.propertiesDatabase.find(p => p.name === name);
      if (property && !(propertyGroups[property.groupName]?.fields.find(f => f.name === name))) {
        yield property.id;
      }
    }
  }

  @autobind
  private onSubmit(): void {
    const { assembliesDatabase, openRenameDialog, isEdit, data, isShowRenameDialog } = this.props;
    const isAssemblyExist = isShowRenameDialog(assembliesDatabase, data, this.state.name);
    if (isAssemblyExist) {
      openRenameDialog();
    } else if (isEdit) {
      this.update();
    } else {
      this.create();
    }
  }

  private update(): void {
    const { skipCloseAfterSubmit: isCloseAfterSubmit, data, update } = this.props;
    const payload = this.getUpdateAssemblyPayload();
    update(payload, data);
    if (!isCloseAfterSubmit) {
      this.close();
    }
  }

  private getUpdateAssemblyPayload(): UpdateAssemblyPayload {
    const { addedItems, deletedItems, updatedItems } = ItemUpdater.getData(this.state.form);
    const { data } = this.props;

    return {
      id: data.id,
      name: this.state.name,
      addedItems: addedItems.map(this.mapAddedItem),
      deletedItems,
      updatedItems: updatedItems.map(this.mapUpdatedItems),
    };
  }

  @autobind
  private mapUpdatedItems(item: UpdateItemRequest): UpdateItemRequest {
    if (!this.isItemExistInDb(item.baseItemId)) {
      return item;
    }

    const baseItem = this.dataBaseItemMap[item.baseItemId];
    const assemblyItem = this.assemblyItemMap[item.id];
    const updatedProperties = [];
    const addedProperties = [];
    const deletedProperties = [];
    item.updatedProperties.forEach(p => {
      if (!this.isOverride(baseItem, p)) {
        const prevItem = this.props.data.items.find(i => i.id === item.id);
        if (isChangePrevValue(p, prevItem)) {
          updatedProperties.push(p);
        }
        return;
      }
      const property = assemblyItem.propertyMap[p.name];
      if (property) {
        updatedProperties.push(p);
      } else {
        addedProperties.push({ ...p, id: UuidUtil.generateUuid() });
      }
    });

    item.deletedProperties.forEach(propertyId => {
      const property = assemblyItem.properties.find(p => p.id === propertyId);
      if (property) {
        if (baseItem.propertyMap[property.name]) {
          updatedProperties.push({ ...property, value: null });
        } else {
          deletedProperties.push(propertyId);
        }
      } else {
        const baseProperty = baseItem.properties.find(p => p.id === propertyId);
        addedProperties.push(this.getDeletedProperty(baseProperty));
      }
    });

    item.updatedProperties = updatedProperties;
    item.addedProperties.push(...addedProperties);
    item.deletedProperties = deletedProperties;
    return item;
  }

  private create(): void {
    const { addedItems } = ItemUpdater.getData(this.state.form);
    const items = addedItems.map(this.mapAddedItem);
    this.props.create(this.state.name, items);
    this.close();
    this.props.sendEvent(MetricNames.pia.createAssembly, { name: this.state.name });
  }

  private close(): void {
    const { close, afterClose } = this.props;
    close();
    if (afterClose) {
      afterClose();
    }
  }

  @autobind
  private mapAddedItem(item: Item): Item {
    if (!this.isItemExistInDb(item.baseItemId)) {
      return item;
    }

    const baseItem = this.dataBaseItemMap[item.baseItemId];
    const handleBaseProperties = new Set<string>();
    const properties = [];
    item.properties.forEach(p => {
      handleBaseProperties.add(p.name);
      if (this.isOverride(baseItem, p)) {
        properties.push(p);
      }
    });
    baseItem.properties.forEach(p => {
      if (!handleBaseProperties.has(p.name)) {
        properties.push(this.getDeletedProperty(p));
      }
    });
    return { ...item, properties };
  }

  @autobind
  private isItemExistInDb(itemId: string): boolean {
    return !!this.dataBaseItemMap[itemId];
  }

  @autobind
  private isOverride(baseItem: ItemMapElement, property: Property): boolean {
    return !this.isItemHasProperty(baseItem, property.name)
      || !this.isPropertyEqual(baseItem.propertyMap[property.name].value, property.value);
  }

  @autobind
  private isItemHasProperty(item: Item, propertyName: string): boolean {
    return !!item.properties.find(p => p.name === propertyName);
  }

  @autobind
  private isPropertyEqual(valueA: AllPropertyValues, valueB: AllPropertyValues): boolean {
    const diff = detailedDiff(valueA, valueB) as any;
    return isDiffEmpty(diff);
  }

  private getDeletedProperty(property: Property): Property {
    return { ...property, id: UuidUtil.generateUuid(), value: null };
  }

  @autobind
  private toggleGroup(id: string, isOpen: boolean): void {
    const groupsCopy = new Set(this.props.collapsedGroups);
    if (isOpen) {
      groupsCopy.delete(id);
    } else {
      groupsCopy.add(id);
    }
    this.props.setCollapsedGroups(groupsCopy);
  }
}

const mapStateToProps = (state: State): StateToProps => {
  const { data } = state.twoDDatabase.assemblyPanel;
  const { visibilityMode, collapsedGroups } = state.twoDDatabase.sidePanel;

  return {
    isEdit: !!data,
    assembliesDatabase: state.twoDDatabase.assemblies,
    data,
    itemsDatabase: state.twoDDatabase.items,
    propertiesDatabase: state.twoDDatabase.properties,
    visibilityMode,
    collapsedGroups,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchToProps => {
  return {
    close: () => {
      dispatch(TwoDDatabaseActions.closeSidePanel());
      dispatch(TwoDDatabaseActions.closeAssemblyPanel());
    },
    open: (type) => dispatch(TwoDDatabaseActions.openSidePanel({ type: type as any, withSideBlock: true })),
    openFormulaDialog: (data) => dispatch(KreoDialogActions.openDialog(FORMULA_DIALOG_NAME, data)),
    openBreakdownDialog: (data) => dispatch(KreoDialogActions.openDialog(BREAKDOWN_DIALOG_NAME, data)),
    openRenameDialog: () => dispatch(KreoDialogActions.openDialog(RENAME_DIALOG_NAME)),
    setCollapsedGroups: (ids) => dispatch(TwoDDatabaseActions.setCollapsedGroups(ids)),
  };
};

export const AssemblyPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAnalyticsContext(AssemblyPanelComponent));
