export const enum CrewMemberType {
  Labor = 0,
  Plant = 1,
}

export interface AnimationDataResponse {
  readonly startDatetime: string;
  readonly resources: CrewResource[];
  readonly materials: MaterialResource[];
  readonly workPackages: WorkPackage[];
  readonly group_links: LinkData[];
  readonly minkro_links: LinkData[];
}

export interface MaterialResource {
  id: number;
  name: string;
  unit: string;
}

export interface CrewResource {
  readonly id: number;
  readonly name: string;
  readonly type: CrewMemberType;
  readonly template_id: number;
  readonly skill_rate: string;
  readonly workpackage_ids: number[] | null;
}

/**
 * timeStart, timeEnd, ...
 */
export type TimesCouples = number[];

/**
 * timeStart, timeEnd, multiplier, ...
 */
export type TimesTriples = number[];

export interface WorkPackage {
  readonly id: number;
  readonly name: string;
  readonly activityGroups: ActivityGroup[];
}

export interface ActivityGroup {
  readonly id: number;
  readonly name: string;
  readonly activities: Activity[];
  readonly times: TimesCouples;
  readonly resource_ids: number[];
  readonly resource_amount: number[];
  readonly free_slack: number;
  readonly free_back_slack: number;
  readonly total_back_slack: number;
  readonly total_slack: number;
}

export interface Activity {
  readonly id: number;
  readonly geoId: number;
  readonly name: string;
  readonly shortName: string;
  /**
   * @description UniSystem code.
   */
  readonly us: string;
  /**
   * @description UniProduct code.
   */
  readonly up: string;
  readonly nrm1: string;
  readonly nrm2: string;
  readonly times: TimesTriples;
  readonly resourceIds: number[];
  readonly resourceAmount: number[]; // multiplier for each crew type for times (name is wrong)
  readonly materialIds: number[];
  readonly materialAmount: number[]; // total amounts of materials
}


export interface LinkData {
  source_id: number;
  target_id: number;
  time_lag: number;
}
