import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { WizzardToolsActions } from '../../../actions/creators';

export function useDropFinderArea(): () => void {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(WizzardToolsActions.dropFinderSelectionArea());
  }, []);
}


export function useSearch(): () => void {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(WizzardToolsActions.runFinderSearch());
  }, []);
}


export function useResetResult(): () => void {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(WizzardToolsActions.setResult(null));
  }, []);
}

export function useResultCount(): { count: number, visibleCount: number } {
  const result = useSelector<State, Array<{ similarity: number, id?: number }>>(x => x.drawings.wizzardTools.result);
  const similarity = useSelector<State, number>(x => x.drawings.wizzardTools.finderSimilarity);
  const hidden = useSelector<State, Record<number, boolean>>(x => x.drawings.wizzardTools.finderResultToHide);
  return useMemo(() => {
    return result.reduce((acc, r) => {
      if (r.similarity >= similarity) {
        acc.count++;
        if (!hidden[r.id]) {
          acc.visibleCount++;
        }
      }
      return acc;
    }, { count: 0, visibleCount: 0 });
  }, [result, similarity, hidden]);
}
