import { useCallback, useEffect, useRef } from 'react';

export const useEnterEffect = (change: () => void): void => {
  const prevCallback = useRef<(e) => void>();
  const handleEnterDown = useCallback((e) => {
    if (e.keyCode === 13) {
      change();
    }
  }, [change]);
  useEffect(() => {
    document.addEventListener('keydown', handleEnterDown);
    prevCallback.current = handleEnterDown;
    return () => {
      document.removeEventListener('keydown', prevCallback.current);
    };
  }, [handleEnterDown]);
};
