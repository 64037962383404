import { ICellEditorParams } from 'ag-grid-community';

import {
  Editors,
  TreeTableCellEditorCreator,
} from '../../quantity-take-off-tree-table-cell-edit/tree-table-cell-editor-creator';
import { ReportTableCellDefaultEditor } from './report-table-cell-default-editor';
import { ReportTableFormulaCellEditor } from './report-table-formula-cell-editor';


export class ReportTableCellEditorCreator
  extends TreeTableCellEditorCreator<ReportTableCellDefaultEditor, ReportTableFormulaCellEditor> {
  public getCellEditors(
    params: ICellEditorParams,
    eGui: HTMLDivElement,
  ): Editors<ReportTableCellDefaultEditor, ReportTableFormulaCellEditor> {
    return {
      defaultCellEditor: new ReportTableCellDefaultEditor(params, eGui),
      formulaCellEditor: new ReportTableFormulaCellEditor(params, eGui),
    };
  }
}
