import { ICellEditorParams } from 'ag-grid-community';
import autobind from 'autobind-decorator';

import { QtoTreeRowProperty } from '../../../interfaces/quantity-take-off';
import { PropertyHelper } from '../../../utils/quantity-take-off-tree-table';
import { QuantityTakeOffFormulaHelper } from '../../../utils/quantity-take-off-tree-table/formula-helper';
import { CellEditorType } from '../../quantity-take-off-tree-table-cell-edit/cell-editor-type';
import { FormulaCellEditorHelper } from '../../quantity-take-off-tree-table-cell-edit/formula-cell-editor-helper';
import { ReportCellEditor } from './report-table-cell-editor';

export class ReportTableFormulaCellEditor extends ReportCellEditor<HTMLSpanElement> {
  public constructor(params: ICellEditorParams, eGui: HTMLDivElement) {
    super(params, eGui);
    this.cellEditorType = CellEditorType.FormulaCellEditor;
  }

  public static getInnerHtml(): string {
    return FormulaCellEditorHelper.getInnerHtml(this.className);
  }

  public getNode(value: string): Node {
    const span = FormulaCellEditorHelper.getValueElement(value, ReportCellEditor.className, this.params);
    this.eValue = span;
    return span;
  }

  public initEvents(): void {
    this.eValue.addEventListener('input', this.handelInput);
  }

  public destroyEvents(): void {
    if (this.eValue) {
      this.eValue.removeEventListener('input', this.handelInput);
    }
  }

  public getCurrentValue(): string {
    return this.eValue.textContent;
  }

  public setFocus(): void {
    document.getSelection().setPosition(this.eValue, this.eValue.children.length);
  }

  protected setEValue(value: string): void {
    this.eValue.textContent = value;
  }

  protected getNewValue(): QtoTreeRowProperty {
    const properties = this.params.data.properties;
    const colId = this.params.column.getId();
    if (!properties[colId]) {
      properties[colId] = {};
    }

    const formula = this.getCurrentValue()
      .replace(
        QuantityTakeOffFormulaHelper.formulaVariableRegex,
        (_match, header) => {
          const colDef = this.params.columnApi.getAllColumns().find((column) => {
            return column.getColDef().headerName.toUpperCase() === header.toUpperCase();
          });
          return colDef
            ? `[${colDef.getColId()}]`
            : `[${header}]`;
        });

    properties[colId].override = formula;

    return properties[colId];
  }

  protected isEmpty(): boolean {
    return !this.eValue.innerText;
  }

  protected currentInitEValue(): void {
    const data = this.params.data;
    const columnId = this.params.column.getColId();
    const formula = PropertyHelper.getActualValue<string>(data.properties[columnId]);
    this.eValue.innerHTML = this.getColoredText(
      QuantityTakeOffFormulaHelper.replaceKeyToHeader(formula, this.params.columnApi),
    );
  }

  private getColoredText(data: string): string {
    return FormulaCellEditorHelper.getColoredText(data, this.params);
  }

  @autobind
  private handelInput(ev: Event): void {
    FormulaCellEditorHelper.handelInput(ev, this.params);
  }
}
