import styled from 'styled-components';


const Container = styled.div`
  margin: 10px 15px 10px 0;
  width: 595px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 510px;
  background-color: ${props => props.theme.color.background};
  border-radius: 25px 25px 25px 0px;

  > p {
    margin: 15px;
  }
`;

export const Styled = {
  Container,
  Avatar,
  Message,
};
