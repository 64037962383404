import autobind from 'autobind-decorator';
import React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import './database-activity-variant-measurement-parameters.scss';

import {
  ExtractorFunctionParameter,
  ExtractorFunctionParameterOption,
  UnitModel,
} from '../../interfaces/data';
import {
  DatabaseActivityVariantMeasurementParameterItem,
} from './database-activity-variant-measurement-parameter-item';

interface Props {
  name: string;
  error?: string;
  readonly: boolean;
  values: ExtractorFunctionParameter[];
  parameters: ExtractorFunctionParameterOption[];
  onChange: (values: ExtractorFunctionParameter[]) => void;
  unitMap: Record<string, UnitModel>;
}

export type DatabaseActivityVariantMeasurementParametersProps = Props;

export class DatabaseActivityVariantMeasurementParameters
  extends React.Component<Props & WrappedFieldArrayProps<ExtractorFunctionParameter>> {
  public render(): JSX.Element {
    const { parameters, unitMap } = this.props;
    if (!parameters || !parameters.length) {
      return null;
    }

    return (
      <div className='database-activity-variant-measurement-parameters'>
        { this.props.fields.map((fieldKey, index, fields) => {
          const unit = unitMap[parameters[index].unitId];
          return parameters[index] ? (
            <DatabaseActivityVariantMeasurementParameterItem
              key={fieldKey}
              fieldName={`${fieldKey}.value`}
              index={index}
              readonly={this.props.readonly}
              id={parameters[index].id}
              onChange={this.onChange}
              unitAcronym={unit.acronym}
              value={fields.get(index).value}
            />
          ) : null;
        }) }
      </div>
    );
  }

  @autobind
  private onChange(index: number, value: number): void {
    const values = this.props.values.slice();
    values[index] = { ...values[index], value };
    this.props.onChange(values);
  }
}
