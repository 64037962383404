import styled from 'styled-components';

const Main = styled.div`
  box-sizing: border-box;
  overflow: overlay;
  height: 100%;
  padding: 40px 20px;
`;

export const Styled = {
  Main,
};
