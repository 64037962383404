import * as React from 'react';

export const IconCost: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M7.52738627,5 L7.52738627,4 C7.52738627,3.72385763 7.75124389,3.5 8.02738627,3.5 C8.30352864,
          3.5 8.52738627,3.72385763 8.52738627,4 L8.52738627,5 L10.0273863,5 C10.3035286,5 10.5273863,
          5.22385763 10.5273863,5.5 C10.5273863,5.77614237 10.3035286,6 10.0273863,6 L7.27746126,6 C6.8588658,
          6 6.51824837,6.33690657 6.51366314,6.75547692 C6.5091498,7.16748429 6.83948898,7.50514104 7.25149635,
          7.50965438 L8.79685211,7.52160277 C9.75475574,7.5290067 10.5273863,8.3076322 10.5273863,
          9.26556444 C10.5273863,10.2234667 9.75085301,11 8.7929507,11 L8.5,11 L8.5,11.9719596 C8.5,
          12.248102 8.27614237,12.4719596 8,12.4719596 C7.72385763,12.4719596 7.5,12.248102 7.5,
          11.9719596 L7.5,11 L6,11 C5.72385763,11 5.5,10.7761424 5.5,10.5 C5.5,10.2238576 5.72385763,
          10 6,10 L7.83449951,10 C7.88629943,9.98183642 7.94199629,9.9719596 8,9.9719596 C8.05800371,
          9.9719596 8.11370057,9.98183642 8.16550049,10 L8.7929507,10 C9.19856826,10 9.52738627,
          9.671182 9.52738627,9.26556444 C9.52738627,8.85690094 9.19777433,8.52473149 8.78912304,
          8.5215729 L7.24617312,8.50964698 C6.27628351,8.4990314 5.50316015,7.70878206 5.51372313,
          6.74452308 C5.5243112,5.77797384 6.31085404,5 7.27746126,5 L7.52738627,5 Z M8,
          15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 C11.8659932,
          1 15,4.13400675 15,8 C15,11.8659932 11.8659932,15 8,15 Z M8,14 C11.3137085,
          14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 C4.6862915,2 2,4.6862915 2,
          8 C2,11.3137085 4.6862915,14 8,14 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
