import autobind from 'autobind-decorator';
import * as React from 'react';

import './custom-element-filter-builder.scss';

import {
  CustomElementFilterBuilderConfig,
  CustomElementFilterBuilderModel,
  CustomElementFilterNumber,
  CustomElementFilterString,
} from 'common/interfaces/custom-element-filter-builder';
import { IconButton, KreoButton, KreoIconCancel, KreoIconShowHidden } from 'common/UIKit';
import { SharedParameterKeyHelper } from '../.../../../../units/projects/utils/shared-parameter-key-helper';
import { QtoLeftPanelConstants } from '../../../units/projects/components/quantity-take-off-left-panel/constants';
import {
  CustomElementFilterConditionVlidatationResult,
} from '../../../units/projects/utils/quantity-take-off-tree-table/custom-element-filter-condition-validator';
import {
  CustomElementFilterBuilderMapper,
  CustomElementFilterBuilderTypeGuards,
  CustomElementFilterBuilderValidator,
} from '../../utils/custom-element-filter-builder';
import { KreoToolbarButton } from '../kreo-toolbar/kreo-toolbar-button';
import { TitleEditable } from '../title-editable';
import { CustomElementFilterBuilderTreeNode } from './custom-element-filter-builder-tree-node';
import { CustomFilterBuilderAutocompleteOption, FilterNode } from './inner-interfaces';


interface Props {
  config: CustomElementFilterBuilderConfig;
  filter: CustomElementFilterBuilderModel;
  onFilterChange: (model: CustomElementFilterBuilderModel) => void;
  closeFilterEditor: () => void;
  validateModelUsing?: (filterConditionsMap: Record<string, CustomElementFilterNumber | CustomElementFilterString>)
    => CustomElementFilterConditionVlidatationResult;
}

interface State {
  hasChanges: boolean;
  error: string;
  name: string;
  rootNode: FilterNode;
  filterKeyOptions: CustomFilterBuilderAutocompleteOption[];
  predefineValues: Record<string, CustomFilterBuilderAutocompleteOption[]>;
  isOnlyModelValues: boolean;
  conditionsValidationResult: CustomElementFilterConditionVlidatationResult;
}

export class CustomElementFilterBuilder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  public componentDidMount(): void {
    this.validateFilterConditions();
  }

  public componentDidUpdate(prevProps: Props): void {
    const prevFilterId = prevProps.filter && prevProps.filter.id;
    const filterId = this.props.filter && this.props.filter.id;
    if (prevFilterId !== filterId && filterId) {
      this.setState(this.getInitialState(this.props), this.validateFilterConditions);
    }
  }

  public render(): JSX.Element {
    const { rootNode, name, filterKeyOptions, predefineValues, isOnlyModelValues } = this.state;
    const visibilityTooltipText = isOnlyModelValues
      ? `Show all available filter's conditions`
      : 'Show conditions selected in the model';

    return (
      <div className='custom-element-filter-builder'>
        <div className='custom-element-filter-builder__header'>
          <span className='custom-element-filter-builder__header-title'>
            Filter Editor
          </span>
          <TitleEditable
            className='custom-element-filter-builder__title'
            canEdit={true}
            label='name'
            text={name}
            onChange={this.onFilterNameChange}
          />
          <KreoToolbarButton
            controlName={'filter-option-model-visibility-toggle'}
            className='custom-element-filter-builder__visibility-toggle'
            active={isOnlyModelValues}
            defaultTooltipText={visibilityTooltipText}
            onClick={this.toggleOptionVisibility}
          >
            <KreoIconShowHidden />
          </KreoToolbarButton>
          <KreoButton
            className='custom-element-filter-builder__save-button'
            size='medium'
            onClick={this.saveChanges}
            disabled={!this.state.hasChanges}
          >
            Save Filter
          </KreoButton>
          <IconButton
            size='medium'
            rounded={false}
            tooltip='Close Filter Editor'
            onClick={this.closeFilterEditor}
            className='custom-element-filter-builder__close-button'
          >
            <KreoIconCancel />
          </IconButton>
          {
            this.state.error ? (
              <div className='custom-element-filter-builder__error'>
                {this.state.error}
              </div>
            ) : null
          }
        </div>
        <div className='custom-element-filter-builder__content'>
          <div className='custom-element-filter-builder__content-inner'>
            <CustomElementFilterBuilderTreeNode
              isOnlyModelValues={isOnlyModelValues}
              node={rootNode}
              onChange={this.onFilterRootNodeChange}
              filterKeyOptions={filterKeyOptions}
              filterKeyToPredefineValues={predefineValues}
              getCustomKeyFromName={this.getCustomKeyFromName}
              getCustomNameFromKey={this.getCustomNameFromKey}
              conditionsValidationResult={this.state.conditionsValidationResult}
            />
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private toggleOptionVisibility(): void {
    this.setState({ isOnlyModelValues: !this.state.isOnlyModelValues });
  }

  private getInitialState(props: Props): State {
    const { filterKeyOptions, predefineValues } = CustomElementFilterBuilderMapper.mapConfig(props.config);

    return {
      name: props.filter.name,
      rootNode: CustomElementFilterBuilderMapper.mapToFilterNode(props.filter.rootNode),
      filterKeyOptions,
      predefineValues,
      hasChanges: false,
      error: null,
      isOnlyModelValues: true,
      conditionsValidationResult: {
        trueConditionIdsMap: {},
        usedKeysMap: {},
      },
    };
  }

  @autobind
  private saveChanges(): void {
    const { onFilterChange, filter } = this.props;
    const error = CustomElementFilterBuilderValidator.validate(this.state.rootNode);
    this.setState({ hasChanges: !!error, error });

    if (error) {
      return;
    }

    this.validateFilterConditions();
    onFilterChange({
      id: filter.id,
      name: this.state.name,
      rootNode: CustomElementFilterBuilderMapper.mapToCustomElementFilterTreeNode(this.state.rootNode),
    });
  }

  @autobind
  private closeFilterEditor(): void {
    this.props.closeFilterEditor();
  }

  @autobind
  private onFilterNameChange(newName: string): void {
    this.setState({ name: newName });
    this.props.onFilterChange({
      ...this.props.filter,
      name: newName,
    });
  }

  @autobind
  private onFilterRootNodeChange(rootNode: FilterNode): void {
    this.setState({ rootNode, hasChanges: true });
  }

  private validateFilterConditions(): void {
    const { validateModelUsing } = this.props;
    if (!validateModelUsing) {
      return;
    }

    const conditionsMap = this.getConditionsMap(this.state.rootNode);

    const conditionsValidationResult = validateModelUsing(conditionsMap);
    this.setState({ conditionsValidationResult });
  }

  private getConditionsMap(
    node: FilterNode,
  ): Record<string, CustomElementFilterNumber | CustomElementFilterString> {
    let result = {};
    node.innerNodes.forEach(x => {
      if (CustomElementFilterBuilderTypeGuards.isStringBaseFilter(x)
        || CustomElementFilterBuilderTypeGuards.isNumberBaseFilter(x)
      ) {
        result[x.uniqueKey] = x;
      } else if (CustomElementFilterBuilderTypeGuards.isFilterNode(x)) {
        const innerResult = this.getConditionsMap(x);
        result = { ...result, ...innerResult };
      }
    });

    return result;
  }

  private getCustomKeyFromName(name: string): string {
    return SharedParameterKeyHelper.getKeyFromName(name, QtoLeftPanelConstants.ADDITIONAL_PROPERTIES_PREFIX);
  }

  private getCustomNameFromKey(key: string): string {
    return SharedParameterKeyHelper.getNameFormKey(key, QtoLeftPanelConstants.ADDITIONAL_PROPERTIES_PREFIX);
  }
}
