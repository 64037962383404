import { RectangleButton } from '@kreo/kreo-ui-components';
import React from 'react';

import { Mood } from 'common/enums/mood';
import { Styled } from './styled';

interface Props {
  onSaveClick: () => void;
}

const ButtonsComponent: React.FC<Props> = ({ onSaveClick }) => {
  return (
    <Styled.Container>
      <RectangleButton
        onClick={onSaveClick}
        text='Save'
        height={32}
        fontSize={14}
        mood={onSaveClick ? Mood.Secondary : Mood.Disabled}
      />
    </Styled.Container>
  );
};

export const Buttons = React.memo(ButtonsComponent);
