import React from 'react';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { DrawingDialogs } from '../../constants/drawing-dialogs';

interface Props {
  onSubmit: () => void;
}

const TooManyGeometriesDialogComponent: React.FC<Props> = ({ onSubmit }) => {
  return (
    <ConfirmationDialog
      name={DrawingDialogs.TOO_MANY_GEOMETRIES_DIALOG}
      title='Too many measurements'
      // eslint-disable-next-line max-len
      text={`You're saving too many measurements, risking performance issues or project opening problems. We advise against it for real projects. Still want to save these measurements?`}
      confirmButtonText="Save"
      onConfirm={onSubmit}
      cancelButtonText="Cancel"
      zIndex={1003}
    />
  );
};

export const TooManyGeometriesDialog = React.memo(TooManyGeometriesDialogComponent);

