import * as Ag from 'ag-grid-community';
import { useMemo } from 'react';
import {
  DataBaseContext,
  TreePropertyRowData,
} from '../../side-panel/components/property-panel/forms/tree-form/interfaces';

const DEFAULT_COL_DEF: Ag.ColDef = {
  resizable: false,
  filter: true,
  wrapText: true,
  flex: undefined,
};

export const useDefaultColumnsMemo = (editable: boolean): Ag.ColDef[] => {
  return useMemo<Ag.ColDef[]>(() => {
    const columns: Ag.ColDef[] = [
      {
        field: 'order',
        colId: 'order',
        sort: 'asc',
        hide: true,
        resizable: false,
      },
      {
        ...DEFAULT_COL_DEF,
        field: 'code',
        colId: 'code',
        headerName: `Code`,
        cellEditor: 'codeInputCellEdit',
        lockVisible: true,
        autoHeight: false,
        editable,
        maxWidth: 130,
        valueGetter: (params: Ag.ValueGetterParams) => {
          const context: DataBaseContext = params.context;
          if (context.isStopEditing) {
            const optionId = params.node.id;
            const code = context.getCodeValue(optionId);
            context.isStopEditing = false;
            return code;
          }
          const field = params.colDef.field;
          return params.data[field];
        },
        valueSetter: (params: Ag.ValueSetterParams) => {
          const isValid = !Number.isNaN(Number(params.newValue));
          if (!isValid) {
            return false;
          }
          const cleanValue = params.newValue.trim();
          const newCode = cleanValue === ''
            ? null
            : cleanValue;
          if (params.context.updateDescription) {
            const rowData: TreePropertyRowData = params.data;
            const option = {
              id: rowData.id,
              code: newCode,
            };
            params.context.updateDescription(option);
          }
          return true;
        },
      },
      {
        ...DEFAULT_COL_DEF,
        field: 'desc',
        colId: 'name',
        headerName: `Name`,
        editable,
        lockVisible: true,
        maxWidth: 200,
        wrapText: true,
        autoHeight: true,
        cellEditor: 'nameTextareaCellEditor',
        cellRenderer: 'nameTextareaCellRenderer',
        onCellValueChanged: (event: Ag.CellValueChangedEvent) => {
          if (event.context.updateDescription) {
            const rowData: TreePropertyRowData = event.data;
            const option = {
              id: rowData.id,
              name: rowData.desc,
            };
            event.context.updateDescription(option);
          }
        },
      },
    ];
    if (editable) {
      columns.push({
        ...DEFAULT_COL_DEF,
        field: 'action',
        colId: 'action',
        headerName: `Action`,
        cellRenderer: 'plusButtonCell',
        maxWidth: 30,
        onCellClicked: (event: Ag.CellClickedEvent) => {
          if (event.context.onAddedClick) {
            event.context.onAddedClick(event.node.data.id);
          }
        },
      });
    }
    return columns;
  }, []);
};
