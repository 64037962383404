import autobind from 'autobind-decorator';
import React from 'react';


import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { EnhancedVirtualList, EnhancedVirtualListProps, EnhancedVirtualListState } from '../enhanced-virtual-list';


interface LazyLoadingVirtualListProps<T> extends EnhancedVirtualListProps<T> {
  total: number;
  batch: number;
  bufferCount: number;
  loadStatus: RequestStatus;
  load: (skip: number, take: number) => void;
}

export class LazyLoadingVirtualList<T> extends React.Component<LazyLoadingVirtualListProps<T>> {
  constructor(props: LazyLoadingVirtualListProps<T>) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  public componentDidMount(): void {
    if (this.props.loadStatus === RequestStatus.NotRequested) {
      this.props.load(0, this.props.batch);
    }
  }

  public componentDidUpdate(): void {
    if (this.props.loadStatus === RequestStatus.NotRequested) {
      this.props.load(0, this.props.batch);
    }
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        <EnhancedVirtualList
          {...this.props}
          onStateUpdate={this.onStateUpdate}
        />
        {
          this.props.loadStatus === RequestStatus.Loading ? (
            <SvgSpinner size='large'/>
          ) : null
        }
      </React.Fragment>
    );
  }

  @autobind
  private onStateUpdate(state: EnhancedVirtualListState): void {
    const loadedCount = this.props.objects.length;
    if (
      this.props.loadStatus !== RequestStatus.Loading &&
      state.endIndex + this.props.bufferCount >= loadedCount &&
      loadedCount < this.props.total
    ) {
      this.props.load(loadedCount, this.props.batch);
      this.setState({ isLoading: true });
    }
  }
}
