import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawingsMeasureUtils } from 'common/components/drawings';
import { DrawingsAnnotationActions } from 'common/components/drawings/actions/creators/annotation';
import { DrawingsUserAnnotationActions } from 'common/components/drawings/actions/creators/user-annotation';
import { useRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { useDrawingsLayoutApi } from 'common/components/drawings/drawings-layout-api-context';
import {
  DrawingsGeometryInstance,
  DrawingsGroupMeasure,
  DrawingsInstanceMeasure,
} from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';
import { useUndoRedoFullApi } from 'common/undo-redo';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { AllowedKeys } from './interfaces';

export function useChangeProperty(
  key: AllowedKeys,
  values: Record<string, string[]>,
  ingoreCalculation?: boolean,
): (propertyValue: number) => void {
  const instances = useSelector<State, Record<string, DrawingsGeometryInstance>>(x => x.drawings.aiAnnotation.geometry);
  const drawingsLayoutApi = useDrawingsLayoutApi();
  const { rendererApi } = useRendererApiContext();
  const dispatch = useDispatch();

  const { addUndoRedo } = useUndoRedoFullApi();
  const { sendEvent } = useAnalytics();

  const groupsMeasuresCache = useSelector<State, Record<string, DrawingsGroupMeasure>>(
    s => s.drawings.userAnnotations.groupsMeasuresCache,
  );

  const onChangeProperty = useCallback((propertyValue) => {
    const sourceGroupMeasures: Record<string, DrawingsGroupMeasure> = {};
    const updatedGroups: Record<string, DrawingsGroupMeasure> = {};

    const getFolderToUpdate = (groupId: string): DrawingsGroupMeasure => {
      if (groupId in updatedGroups) {
        return updatedGroups[groupId];
      }
      sourceGroupMeasures[groupId] = groupsMeasuresCache[groupId];
      return { ...groupsMeasuresCache[groupId] };
    };

    const updatedMeasures = new Array<DrawingsInstanceMeasure>();
    const oldMeasures = new Array<DrawingsInstanceMeasure>();

    const ids = [];
    if (!ingoreCalculation) {
      for (const instancesIds of Object.values(values)) {
        for (const instanceId of instancesIds) {
          const measures = drawingsLayoutApi.getInstancesMeasuresSimple([instanceId])[0];
          const newMeasures = { ...measures, measures: { ...measures.measures, [key]: propertyValue || 0 } };
          DrawingsMeasureUtils.updateMeasuresByConstantParams(newMeasures);
          const groupId = instances[instanceId].groupId;
          if (groupId && groupId in groupsMeasuresCache) {
            const negativizedCurrent = DrawingsMeasureUtils.negativizeMeasure(measures);
            const group = DrawingsMeasureUtils.addToGroupMeasures(getFolderToUpdate(groupId), negativizedCurrent);
            updatedGroups[groupId] = DrawingsMeasureUtils.addToGroupMeasures(group, newMeasures);
          }
          updatedMeasures.push(newMeasures);
          oldMeasures.push(measures);
          ids.push(instanceId);
        }
      }
    } else {
      for (const instancesIds of Object.values(values)) {
        for (const instanceId of instancesIds) {
          ids.push(instanceId);
        }
      }
    }

    const redo = (): void => {
      drawingsLayoutApi.onInstancesMeasuresUpdated(updatedMeasures);
      rendererApi?.engine.changeEntitiesGeometryParams(ids, key, propertyValue);
      sendEvent(MetricNames.threeDMeasures.propertiesModified, { count: ids.length, [key]: `${propertyValue}` });
      dispatch(DrawingsAnnotationActions.updateGeometryParams(ids, key, propertyValue));
      dispatch(DrawingsUserAnnotationActions.saveGroupMeasure(updatedGroups));
    };
    const undo = (): void => {
      drawingsLayoutApi.onInstancesMeasuresUpdated(oldMeasures);
      for (const [value, currentIds] of Object.entries(values)) {
        if (value === 'undefined') {
          sendEvent(MetricNames.threeDMeasures.propertiesModified, { count: ids.length, [key]: 'undefined' });
          dispatch(DrawingsAnnotationActions.updateGeometryParams(currentIds, key, undefined));
        } else {
          sendEvent(MetricNames.threeDMeasures.propertiesModified, { count: ids.length, [key]: Number(value) });
          dispatch(DrawingsAnnotationActions.updateGeometryParams(currentIds, key, Number(value)));
        }
      }
      dispatch(DrawingsUserAnnotationActions.saveGroupMeasure(sourceGroupMeasures));
    };
    addUndoRedo(undo, redo);
    redo();
  }, [
    key,
    values,
    instances,
    drawingsLayoutApi,
    addUndoRedo,
    ingoreCalculation,
    sendEvent,
    rendererApi,
    groupsMeasuresCache,
    dispatch,
  ]);

  return onChangeProperty;
}
