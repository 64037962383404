import {
  IconButton,
  Icons,
} from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ElementTooltip } from 'common/components/tooltip';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DrawingDialogs } from '../../constants/drawing-dialogs';

interface StateProps {
  selectedInstancesCount: number;
}

interface DispatchProps {
  openRenameToolDialog: () => void;
}

interface OwnProps {
  disabled: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;


class DrawingsAnnotationLegendRenameToolComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const isToolEnabled = !this.props.disabled && this.props.selectedInstancesCount;
    return (
      <ElementTooltip text='Rename tool' position='top'>
        <IconButton
          Icon={Icons.RenameToolSmall}
          onClick={this.props.openRenameToolDialog}
          width={20}
          height={20}
          mood={isToolEnabled ? 'default' : 'disabled'}
        />
      </ElementTooltip>
    );
  }
}

function mapStateToProps({ drawings }: State): StateProps {
  return {
    selectedInstancesCount: drawings.selectedInstances.length,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    openRenameToolDialog: () => dispatch(KreoDialogActions.openDialog(DrawingDialogs.RENAME_TOOL_DIALOG)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const DrawingsAnnotationLegendRenameTool = connector(DrawingsAnnotationLegendRenameToolComponent);
