import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import { IconButton } from 'common/UIKit';
import { AppSlider } from 'common/UIKit/app-slider';
import {
  KreoIconBack,
  KreoIconControlsSkipping,
  KreoIconControlsSkippingActive,
  KreoIconForward,
  KreoIconPause,
  KreoIconPlay,
} from 'common/UIKit/icons';
import { utils } from '../../../../../utils';

interface PanelProps {
  isPlaying: boolean;
  /**
   * for display only, does not affect slider value
   */
  speed: number;
  skipOffTime: boolean;
  play: () => void;
  pause: () => void;
  moveForward: () => void;
  moveBackward: () => void;
  changeSpeed: (speed: number) => void;
  setSkipOffTime: (skipOffTime: boolean) => void;
}

interface PanelState {
  sliderValue: number;
}

export class PlaybackControlPanel extends React.PureComponent<PanelProps, PanelState> {
  constructor(props: PanelProps) {
    super(props);

    this.state = {
      sliderValue: 0.5,
    };
  }

  public render(): JSX.Element {
    const playbackToggleClassName = classNames('playback-toggle', {
      pause: this.props.isPlaying,
    });

    return (
      <div
        className='playback-control-panel'
      >
        <IconButton
          size='medium'
          className='move-backward'
          onClick={this.props.moveBackward}
        >
          <KreoIconBack />
        </IconButton>
        <IconButton
          size='medium'
          className={playbackToggleClassName}
          onClick={this.props.isPlaying ? this.props.pause : this.props.play}
        >
          {
            this.props.isPlaying
              ? <KreoIconPause />
              : <KreoIconPlay />
          }
        </IconButton>
        <IconButton
          size='medium'
          className='move-forward'
          onClick={this.props.moveForward}
        >
          <KreoIconForward />
        </IconButton>
        <IconButton
          size='medium'
          className='playback-control-panel__skip-off-time-button'
          onClick={this.toggleSkipOffTime}
        >
          {
            this.props.skipOffTime
              ? <KreoIconControlsSkippingActive />
              : <KreoIconControlsSkipping />
          }
        </IconButton>
        <div className='playback-control-panel__speed-slider-wrapper'>
          <div className='playback-control-panel__speed-marks top' >
            <div
              key={0}
              className='playback-control-panel__speed-mark'
            />
            <div
              key={1}
              className='playback-control-panel__speed-mark'
            />
          </div>
          <AppSlider
            className='speed'
            value={this.state.sliderValue}
            onChange={this.onSliderChange}
          />
          <div className='playback-control-panel__speed-marks bottom' >
            <div
              key={0}
              className='playback-control-panel__speed-mark'
            />
            <div
              key={1}
              className='playback-control-panel__speed-mark'
            />
          </div>
        </div>
        <div className='playback-control-panel__speed-label'>
          {utils.formatTimeValue(this.props.speed)}/s
        </div>
        <div className='playback-control-panel__time-title'>
          h
          <span className='playback-control-panel__time-title-divider'>:</span>
          min
        </div>
      </div>
    );
  }

  @autobind
  private onSliderChange(normalizedValue: number): void {
    this.setState({
      sliderValue: normalizedValue,
    });

    this.props.changeSpeed(utils.getSpeedValue(normalizedValue));
  }

  @autobind
  private toggleSkipOffTime(): void {
    this.props.setSkipOffTime(!this.props.skipOffTime);
  }
}
