import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RoleCode } from 'common/enums/role-code';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { INVITE_PEOPLE_DIALOG } from 'components/dialog';
import { AccountSelectors } from '../../../../units/account/selectors';
import { Styled } from './styled';


interface DispatchProps {
  showDialog: () => void;
}

interface StateProps {
  isRoleGuest: boolean;
}

interface Props extends DispatchProps, StateProps {

}

class ButtonShareFastNavigationComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { isRoleGuest, showDialog } = this.props;

    return (
      <Styled.ShareButtonContainer>
        <RectangleButton
          height={20}
          width={66}
          text={'Share'}
          Icon={Icons.ShareButton}
          mood={isRoleGuest ? 'disabled' : 'secondary'}
          onClick={showDialog}
        />
      </Styled.ShareButtonContainer>
    );
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    isRoleGuest: AccountSelectors.currentSubscription(state).userRoleCode === RoleCode.Guest,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    showDialog: () => dispatch(KreoDialogActions.openDialog(INVITE_PEOPLE_DIALOG)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ButtonShareFastNavigation = connector(ButtonShareFastNavigationComponent);
