import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';


import './feed.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { KreoButton } from 'common/UIKit';
import { MaterialInputSearch } from '..';

interface Props {
  className?: string;
  searchPlaceholder?: string;
  data: any[];
  count: number;
  status: RequestStatus;
  initialSearch?: string;
  loadMore: (skip: number, search?: string) => void;
  renderItem: (item: any) => React.ReactNode;
}

interface State {
  searchText: string;
}

// TODO: remove it to components/UIKit
export class Feed extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      searchText: props.initialSearch ? props.initialSearch : '',
    };
  }

  public componentDidMount(): void {
    this.props.loadMore(0, this.state.searchText);
  }

  public render(): React.ReactNode {
    const rest = this.props.data ? this.props.count - this.props.data.length : this.props.count;

    return (
      <div className={classNames('feed', this.props.className)}>
        <div className='feed__header'>
          <MaterialInputSearch
            placeholder={this.props.searchPlaceholder}
            onChange={this.onSearchTextChange}
            initialText={this.props.initialSearch}
          />
          <div className='feed__count-info'>
            <span>Total: {this.props.count}</span>
            <span>Loaded: {this.props.data ? this.props.data.length : 0}</span>
          </div>
        </div>
        <div className='feed__content'>
          {
            this.props.data &&
            this.props.data.map((item) => {
              if (this.props.renderItem) {
                return this.props.renderItem(item);
              }
            })
          }
          <div className='feed__actions'>
            {
              this.props.status === RequestStatus.Loading ?
              <div className='feed__spinner'>
                <SvgSpinner size='large' />
              </div>
                :
              <KreoButton
                disabled={rest <= 0}
                className='feed__button'
                onClick={this.onClick}
                mode='toaction'
                size='medium'
              >
                Load more
              </KreoButton>
            }
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private onClick(): void {
    this.props.loadMore(this.props.data ? this.props.data.length : 0, this.state.searchText);
  }

  @autobind
  private onSearchTextChange(value: string): void {
    this.setState({
      searchText: value,
    },            () => {
      this.props.loadMore(0, value);
    });
  }
}
