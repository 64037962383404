import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TablePreset } from '2d/index';
import { getSortingMeasureTypes } from '2d/units/get-sorting-measure-types';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { MultilevelSelectOptionDataWithObjectValue } from 'common/components/multi-level-drop-down-menu';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { MeasureUtil } from 'common/utils/measure-util';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { MoveToCellOptionData, MoveToCellOptionType } from '..';
import { getMeasureMap } from '../../drawings-canvas/get-folder-to-cell-options';
import { DrawingsInstanceType } from '../../enums';
import { useFilterMeasure3d } from '../../hooks';
import { DrawingsGeometryState, DrawingsInstanceMeasure, DrawingsShortInfo } from '../../interfaces';
import { DrawingsMenuUtils } from '../../utils/drawings-menu-utils';
import { DrawingsInstanceMeasureHelper } from '../../utils/measures/get-drawings-instance-measure-helper';
import { PresetMenuShortWrap } from '../preset-menu';
import { useGetInstancesMeasures } from './use-get-instances-measures';

function addGroupNameOption(result: Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>): void {
  const tableData = {
    type: MoveToCellOptionType.groupName,
    value: null,
  };
  result.push(DrawingsMenuUtils.makeMenuItem('Group Name', tableData));
}

function addCountOption(
  result: Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>,
  count: number,
): void {
  result.push(DrawingsMenuUtils.makeMenuOption(MoveToCellOptionType.count, count));
}

interface UseMoveTableToCellParams {
  afterAction?: () => void;
  onMoveTableToCell: (instancesIds: string[], preset: TablePreset) => void;
}

function addTableOption(
  result: Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>,
  onMoveTableToCell: (instancesIds: string[], preset: TablePreset) => void,
  instancesIds: string[],
): void {
  result.push(
    DrawingsMenuUtils.makeMenuItem('table', {
      type: MoveToCellOptionType.table,
      value: null,
      renderMenu: () => (
        <PresetMenuShortWrap
          onSelectPreset={onMoveTableToCell}
          instancesIds={instancesIds}
        />
      ),
    }),
  );
}


interface UseGetSignleMeasureTypesParams extends UseMoveTableToCellParams {
  getInstanceMeasureHelper: () => DrawingsInstanceMeasureHelper;
}

function useGetSingleMeasureTypes(
  params: UseGetSignleMeasureTypesParams,
): (selectedInstance: string) => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>> {
  const filterMeasure3d = useFilterMeasure3d();
  const aiAnnotation = useSelector<State, DrawingsGeometryState>((x) => x.drawings.aiAnnotation);
  const elementMeasurement = useSelector<State, Record<string, DrawingsInstanceMeasure>>(
    (x) => x.drawings.elementMeasurement,
  );
  const drawings = useSelector<State, Record<string, DrawingsShortInfo>>((x) => x.drawings.drawingsInfo);
  const { getInstanceMeasureHelper, onMoveTableToCell } = params;

  return useCallback(
    (selectedInstance: string) => {
      if (!(selectedInstance in aiAnnotation.geometry)) {
        return [];
      }
      const helper = getInstanceMeasureHelper();
      if (!helper) {
        return [];
      }
      const { measure: firstInstance } = helper.getInstanceMeasures(
        selectedInstance,
        drawings,
        aiAnnotation,
        (id) => elementMeasurement[id],
      );

      const typesFilter =
        aiAnnotation.geometry[selectedInstance].type === DrawingsInstanceType.Count
          ? (type: string) => type !== 'pointsCount'
          : (type: string) => !type.includes('px') && !type.includes('Max') && !type.includes('Min');

      const measures = arrayUtils.filterMap(
        Object.keys(firstInstance.measures),
        (type) => filterMeasure3d(type) && (typesFilter(type) || !!MoveToCellOptionType[type]),
        (key) =>
          DrawingsMenuUtils.makeMenuItem(MeasureUtil.measureVisibleName[key] || key, {
            type: MoveToCellOptionType[key],
            value: firstInstance.measures[key],
          }),
      );

      if (!(MoveToCellOptionType.count in firstInstance.measures)) {
        addCountOption(measures, 1);
      }
      measures.push(DrawingsMenuUtils.makeMenuOption(MoveToCellOptionType.name));
      addGroupNameOption(measures);
      addTableOption(measures, onMoveTableToCell, [selectedInstance]);

      return getSortingMeasureTypes(measures);
    },
    [
      filterMeasure3d,
      aiAnnotation,
      drawings,
      getInstanceMeasureHelper,
      elementMeasurement,
      onMoveTableToCell,
    ],
  );
}


function useGetMultiMeasureTypes(
  params: UseGetSignleMeasureTypesParams,
): (selectedInstances: string[]) => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>> {
  const aiAnnotation = useSelector<State, DrawingsGeometryState>((x) => x.drawings.aiAnnotation);
  const elementMeasurement = useSelector<State, Record<string, DrawingsInstanceMeasure>>(
    (x) => x.drawings.elementMeasurement,
  );
  const drawings = useSelector<State, Record<string, DrawingsShortInfo>>((x) => x.drawings.drawingsInfo);
  const canShowMeasure3d = useAbility(Operation.Read, Subject.Takeoff2dMeasurement3d);
  const { getInstanceMeasureHelper, onMoveTableToCell } = params;

  const getInstanceMeasures = useGetInstancesMeasures(getInstanceMeasureHelper);

  return useCallback((selectedInstances: string[]) => {
    const measuresMap = getMeasureMap(
      selectedInstances,
      elementMeasurement,
      drawings,
      aiAnnotation,
      getInstanceMeasures,
    );
    const result = [];
    const types = new Set(selectedInstances.map((x) => aiAnnotation.geometry[x].type));
    const type = types.values().next().value;
    const typePolygon = type === DrawingsInstanceType.Polygon;

    const isOnlyLine = measuresMap.length && types.size === 1;
    const hasCountOrPolyline = types.has(DrawingsInstanceType.Count) || types.has(DrawingsInstanceType.Polyline);

    const isOtherMeasures =
      (measuresMap.perimeter && measuresMap.area && types.size === 1) ||
      (measuresMap.height && typePolygon && !hasCountOrPolyline) ||
      (!hasCountOrPolyline && !isOnlyLine);

    result.push(
      DrawingsMenuUtils.makeMenuItem('count', {
        type: MoveToCellOptionType.count,
        value: measuresMap.count,
      }),
    );

    if (isOtherMeasures) {
      result.push(
        DrawingsMenuUtils.makeMenuItem('perimeter', {
          type: MoveToCellOptionType.perimeter,
          value: measuresMap.perimeter,
        }),
        DrawingsMenuUtils.makeMenuItem('area', {
          type: MoveToCellOptionType.area,
          value: measuresMap.area,
        }),
      );
      if (canShowMeasure3d) {
        result.push(
          DrawingsMenuUtils.makeMenuItem('vertical Area', {
            type: MoveToCellOptionType.verticalArea,
            value: measuresMap.verticalArea,
          }),
          DrawingsMenuUtils.makeMenuItem('volume', {
            type: MoveToCellOptionType.volume,
            value: measuresMap.volume,
          }),
        );
      }
    } else {
      if (isOnlyLine) {
        result.push(
          DrawingsMenuUtils.makeMenuItem('length', {
            type: MoveToCellOptionType.length,
            value: measuresMap.length,
          }),
        );
        if (canShowMeasure3d) {
          result.push(
            DrawingsMenuUtils.makeMenuItem('area', {
              type: MoveToCellOptionType.area,
              value: measuresMap.area,
            }),
            DrawingsMenuUtils.makeMenuItem('vertical Area', {
              type: MoveToCellOptionType.verticalArea,
              value: measuresMap.verticalArea,
            }),
            DrawingsMenuUtils.makeMenuItem('volume', {
              type: MoveToCellOptionType.volume,
              value: measuresMap.volume,
            }),
          );
        }
      } else if (!types.has(DrawingsInstanceType.Count)) {
        if (canShowMeasure3d) {
          result.push(
            DrawingsMenuUtils.makeMenuItem('area', {
              type: MoveToCellOptionType.area,
              value: measuresMap.area,
            }),
            DrawingsMenuUtils.makeMenuItem('vertical Area', {
              type: MoveToCellOptionType.verticalArea,
              value: measuresMap.verticalArea,
            }),
            DrawingsMenuUtils.makeMenuItem('volume', {
              type: MoveToCellOptionType.volume,
              value: measuresMap.volume,
            }),
          );
        }
      }
    }

    if (measuresMap.pointsCount) {
      result.push(
        DrawingsMenuUtils.makeMenuItem('points count', {
          type: MoveToCellOptionType.pointsCount,
          value: measuresMap.pointsCount,
        }),
      );
    }

    if (!types.has(DrawingsInstanceType.Count) && measuresMap.segmentsCount) {
      result.push(
        DrawingsMenuUtils.makeMenuItem('segments count', {
          type: MoveToCellOptionType.segmentsCount,
          value: measuresMap.segmentsCount,
        }),
      );
    }

    result.push(DrawingsMenuUtils.makeMenuOption(MoveToCellOptionType.name));
    addGroupNameOption(result);
    addTableOption(result, onMoveTableToCell, selectedInstances);

    return getSortingMeasureTypes(result);
  }, [canShowMeasure3d, getInstanceMeasures, aiAnnotation, elementMeasurement, drawings, onMoveTableToCell]);
}


export function useGetCurrentMeasureTypes(
  getInstanceMeasureHelper: () => DrawingsInstanceMeasureHelper,
  onMoveTableToCell: (instancesIds: string[], preset: TablePreset) => void,
): (instancesIds: string[]) => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>> {
  const getSingleMeasureType = useGetSingleMeasureTypes({ getInstanceMeasureHelper, onMoveTableToCell });
  const getMultiMeasureTypes = useGetMultiMeasureTypes({ getInstanceMeasureHelper, onMoveTableToCell });

  return useCallback((instancesIds: string[]) => {
    if (instancesIds.length === 1) {
      return getSingleMeasureType(instancesIds[0]);
    }
    return getMultiMeasureTypes(instancesIds);
  }, [getSingleMeasureType, getMultiMeasureTypes]);
}

export function useGetCurrentMeasureMenu(
  instancesIds: string[],
  getInstanceMeasureHelper: () => DrawingsInstanceMeasureHelper,
  onMoveTableToCell: (instancesIds: string[], preset: TablePreset) => void,
  onCloseMenu: () => void,
): () => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>> {
  const { sendEvent } = useAnalytics();
  const onMoveTableToCellCallback = useCallback((instances: string[], preset: TablePreset) => {
    onMoveTableToCell(instances, preset);
    onCloseMenu();
    sendEvent(MetricNames.transferMeasurements.moveToCellTable, { name: preset.name });
  }, [onCloseMenu, onMoveTableToCell, sendEvent]);
  const getCurrentMeasureTypes = useGetCurrentMeasureTypes(getInstanceMeasureHelper, onMoveTableToCellCallback);
  return useCallback(() => getCurrentMeasureTypes(instancesIds), [getCurrentMeasureTypes, instancesIds]);
}


export function useHasMeasures(
  instancesIds: string[],
  getInstanceMeasureHelper: () => DrawingsInstanceMeasureHelper,
): boolean {
  const getCurrentMeasureTypes = useGetCurrentMeasureMenu(
    instancesIds,
    getInstanceMeasureHelper,
    ConstantFunctions.doNothing,
    ConstantFunctions.doNothing,
  );

  return useMemo(() => {
    return getCurrentMeasureTypes().length > 0;
  }, [getCurrentMeasureTypes]);
}
