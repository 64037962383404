import * as React from 'react';

import { ColorControlOwnProps } from 'common/components/color-control';
import { DrawingMarkShapes, DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import { ColorList } from 'common/constants/color-list';
import { DrawingsColorControl } from '../../color-control';
import { DrawingsStyleMenuType } from './enums';
import { MenuRenderer } from './menu-renderer/menu-renderer';
import { Styled } from './styled';


interface Props {
  colorTitle?: string;
  color: string;
  setColor?: (color: string) => void;
  setStrokeStyle?: (value: DrawingStrokeStyles) => void;
  strokeWidth?: number;
  setStrokeWidth?: (value: number) => void;
  strokeStyle?: DrawingStrokeStyles;
  menuType: DrawingsStyleMenuType;
  setShape: (value: DrawingMarkShapes) => void;
  shape: DrawingMarkShapes;
  customColorControl?: React.ComponentType<Partial<ColorControlOwnProps>>;
}


const DrawingsStylesMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    setColor,
    setStrokeStyle,
    setStrokeWidth,
    colorTitle,
    color,
    menuType,
    strokeStyle,
    strokeWidth,
    setShape,
    shape,
    customColorControl,
  } = props;
  const ColorControl = customColorControl || DrawingsColorControl;
  return (
    <Styled.Container>
      <ColorControl
        colorList={ColorList}
        onColor={setColor}
        title={colorTitle}
        selectedColor={color}
      />
      <MenuRenderer
        setShape={setShape}
        shape={shape}
        menuType={menuType}
        setStrokeStyle={setStrokeStyle}
        setStrokeWidth={setStrokeWidth}
        strokeStyle={strokeStyle}
        strokeWidth={strokeWidth}
      />
    </Styled.Container>
  );
};

export const DrawingsStylesMenu = React.memo(DrawingsStylesMenuComponent);
