import autobind from 'autobind-decorator';
import React from 'react';
import { Field } from 'redux-form';

import './database-resource-labor.scss';

import { MaterialInputField, MaterialInputProps } from 'common/UIKit';
import { LaborModel } from '../../interfaces/resources-data';


interface Props {
  readonly: boolean;
  resource: LaborModel;
  onChange: (root: LaborModel) => void;
}

export class DatabaseResourceLabor extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <div className='database-resource-labor'>
        <div className='database-resource-labor__name'>
          <Field<MaterialInputProps>
            name='name'
            component={MaterialInputField}
            label='Name'
            displayBottomInformation={true}
            disabled={this.props.readonly}
            value={this.props.resource.name}
            onChange={this.onNameChange}
            onBlur={this.onNameBlur}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onNameChange(_: React.ChangeEvent, name: string): void {
    this.props.onChange({
      ...this.props.resource,
      name,
    });
  }

  @autobind
  private onNameBlur(_: React.ChangeEvent, value: string): void {
    const name = value && value.trim();
    this.props.onChange({
      ...this.props.resource,
      name,
    });
  }
}
