import { DrawingsGroupMeasure } from '..';
import { DrawingsUserLegendHeaderConfig } from '../user-annotations-components/legend/header';


function calculateHeaderConfig(measures: Record<string, DrawingsGroupMeasure>): DrawingsUserLegendHeaderConfig {
  const config: DrawingsUserLegendHeaderConfig = {
    area: false,
    length: false,
    perimeter: false,
  };
  for (const measure of Object.values(measures)) {
    for (const [key, value] of Object.entries(measure)) {
      if (key in config && value) {
        config[key] = true;
      }
    }
  }
  return config;
}

export const DrawingsUserAnnotationLegendUtils = {
  calculateHeaderConfig,
};
