import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams, ShortPointDescription } from 'common/components/drawings/interfaces';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { Vector2Utils } from 'common/components/drawings/utils/math-utils/vector2-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DestroyableObject, EngineObjectConfig } from '../../common';

interface Config extends EngineObjectConfig{
  layer: paper.Group | paper.Layer;
  size: number;
  renderParamsContextObserver: ContextObserver<DrawingsRenderParams>;
}

(window as any).PART_OF_WINDOW = 4;

const ZONE_STYLES = {
  dashArray: [4, 2],
  strokeWidth: 2,
};

export class BucketAIWindowsHelper extends DestroyableObject<Config> {
  protected _windowsGroups: paper.Group;

  private _removeOldPath: () => void;

  constructor(config: Config) {
    super(config);
    this._windowsGroups = new paper.Group();
    this._windowsGroups.addTo(config.layer);
  }

  public destroy(): void {
    if (this._removeOldPath) {
      this._removeOldPath();
    }
    this._windowsGroups.remove();
  }

  public async isPointInWindow(point: paper.Point, contours: ShortPointDescription[][]): Promise<boolean> {
    if (this._removeOldPath) {
      this._removeOldPath();
      this._removeOldPath = null;
    }
    const inPolygon = contours.some(contour => Vector2Utils.isPointInPolygon([point.x, point.y], contour));
    if (!inPolygon) {
      this.createTargetZone(contours);
    }
    return inPolygon;
  }

  private createTargetZone(paths: ShortPointDescription[][]): void {
    const pathGroup = new paper.Group();
    pathGroup.addTo(this._windowsGroups);
    const paperPaths = paths.map((path) => {
      const { zoom } = this._config.renderParamsContextObserver.getContext();
      const paperPath = new paper.Path({
        segments: path.map((p) => new paper.Point(p)),
        closed: true,
        strokeWidth: ZONE_STYLES.strokeWidth / zoom,
        strokeColor: DrawingsCanvasColors.warningStrokeColor,
        fillColor: DrawingsCanvasColors.warningFillColor,
        strokeCap: 'round',
        dashArray: DrawingsCanvasUtils.scaleDashArray(ZONE_STYLES.dashArray, ZONE_STYLES.strokeWidth, zoom),
      });
      paperPath.addTo(pathGroup);
      return paperPath;
    });
    const updatePathZoom = ({ zoom }: DrawingsRenderParams): void => {
      paperPaths.forEach((p) => {
        p.strokeWidth = ZONE_STYLES.strokeWidth / zoom;
        p.dashArray = DrawingsCanvasUtils.scaleDashArray(ZONE_STYLES.dashArray, ZONE_STYLES.strokeWidth, zoom);
      });
    };
    this._config.renderParamsContextObserver.subscribe(updatePathZoom);
    const executor = new DeferredExecutor(3000);
    executor.execute(() => {
      this._config.renderParamsContextObserver.unsubscribe(updatePathZoom);
      pathGroup.remove();
    });
    this._removeOldPath = () => {
      executor.reset();
      this._config.renderParamsContextObserver.unsubscribe(updatePathZoom);
      pathGroup.remove();
    };
  }
}
