const prefix = '@quantity-take-off';

const GET_MODEL_REQUEST = `${prefix}/GET_MODEL_REQUEST`;
const GET_MODEL_SUCCEEDED = `${prefix}/GET_MODEL_SUCCEEDED`;
const GET_MODEL_FAILED = `${prefix}/GET_MODEL_FAILED`;
const TOGGLE_NODE_EXPANSION = `${prefix}/TOGGLE_NODE_EXPANSION`;
const HIGHLIGHT_TREE_NODE = `${prefix}/HIGHLIGHT_TREE_NODE`;
const SELECT_BIM_ELEMENTS = `${prefix}/SELECT_BIM_ELEMENTS`;
const DOWNLOAD_GENERAL_REPORT = `${prefix}/DOWNLOAD_GENERAL_REPORT`;
const BUILD_APPROXIMATE_COST_REPORT = `${prefix}/BUILD_APPROXIMATE_COST_REPORT`;
const DOWNLOAD_APPROXIMATE_COST_REPORT = `${prefix}/DOWNLOAD_APPROXIMATE_COST_REPORT`;
const DOWNLOAD_RAW_DATA_FILE = `${prefix}/DOWNLOAD_RAW_DATA_FILE`;
const CHANGE_EXPAND_STATUS = `${prefix}/CHANGE_EXPAND_STATUS`;
const DROP_STATE = `${prefix}/DROP_STATE`;
const OPEN_ELEMENTAL_VIEW = `${prefix}/OPEN_ELEMENTAL_VIEW`;
const CLOSE_ELEMENTAL_VIEW = `${prefix}/CLOSE_ELEMENTAL_VIEW`;
const CHANGE_ELEMENTAL_VIEW_BREAKDOWN = `${prefix}/CHANGE_ELEMENTAL_VIEW_BREAKDOWN`;
const HIGHLIGHT_ELEMENTAL_VIEW_ELEMENTS = `${prefix}/HIGHLIGHT_ELEMENTAL_VIEW_ELEMENTS`;
const GET_ELEMENT_RECORDS = `${prefix}/GET_ELEMENT_RECORDS`;
const UPLOAD_QTO_PAGE_DATA = `${prefix}/UPLOAD_QTO_PAGE_DATA`;
const GET_LOCATION_RECORDS = `${prefix}/GET_LOCATION_RECORDS`;
const LOAD_LOCATION_RECORDS = `${prefix}/LOAD_LOCATION_RECORDS`;
const FILTER_TREE = `${prefix}/FILTER_TREE`;
const LOAD_RECORDS_CONFIG = `${prefix}/LOAD_RECORDS_CONFIG`;
const GET_RECORDS_CONFIG = `${prefix}/GET_RECORDS_CONFIG`;
const GET_QTO_PAGE_DATA = `${prefix}/GET_QTO_PAGE_DATA`;
const GET_LEFT_PANEL_RECORDS = `${prefix}/GET_LEFT_PANEL_RECORDS`;
const UPDATE_STATE_AFTER_ADD_NEW_PROPS = `${prefix}/UPDATE_STATE_AFTER_ADD_NEW_PROPS`;
const SET_GLOBAL_CONFIG = `${prefix}/SET_GLOBAL_CONFIG`;

export const QuantityTakeOffActionTypes = {
  GET_MODEL_REQUEST,
  GET_MODEL_SUCCEEDED,
  GET_MODEL_FAILED,
  TOGGLE_NODE_EXPANSION,
  HIGHLIGHT_TREE_NODE,
  SELECT_BIM_ELEMENTS,
  DOWNLOAD_GENERAL_REPORT,
  BUILD_APPROXIMATE_COST_REPORT,
  DOWNLOAD_APPROXIMATE_COST_REPORT,
  DOWNLOAD_RAW_DATA_FILE,
  CHANGE_EXPAND_STATUS,
  DROP_STATE,
  OPEN_ELEMENTAL_VIEW,
  CLOSE_ELEMENTAL_VIEW,
  CHANGE_ELEMENTAL_VIEW_BREAKDOWN,
  HIGHLIGHT_ELEMENTAL_VIEW_ELEMENTS,
  GET_ELEMENT_RECORDS,
  UPLOAD_QTO_PAGE_DATA,
  FILTER_TREE,
  GET_RECORDS_CONFIG,
  LOAD_RECORDS_CONFIG,
  GET_LOCATION_RECORDS,
  LOAD_LOCATION_RECORDS,
  GET_QTO_PAGE_DATA,
  GET_LEFT_PANEL_RECORDS,
  UPDATE_STATE_AFTER_ADD_NEW_PROPS,
  SET_GLOBAL_CONFIG,
};
