import * as React from 'react';

export const KreoIconFilters: React.FC = () => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/20x20/Filters_normal'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          // eslint-disable-next-line max-len
          d='M11.0046634,15.3058321 L11.0046634,10.5 C11.0046634,10.3948661 11.0378033,10.2924072 11.0993724,10.2071875 L14.5001963,5.5 L5.49983763,5.5 L8.91552829,10.2063126 C8.97749809,10.2916977 9.01087079,10.394497 9.01087079,10.5 L9.01087079,14.6398566 L11.0046634,15.3058321 Z M12.0046634,10.6617229 L12.0046634,16 C12.0046634,16.3414097 11.6700771,16.5824079 11.3462546,16.4742432 L8.35246195,15.4742432 C8.14844179,15.4060955 8.01087079,15.2151008 8.01087079,15 L8.01087079,10.6623197 L4.69052263,6.08737486 C4.36612445,5.64040253 4.46549043,5.01508318 4.91246276,4.690685 C5.08323305,4.5667454 5.28883167,4.5 5.49983763,4.5 L14.5001963,4.5 C15.052481,4.5 15.5001963,4.94771525 15.5001963,5.5 C15.5001963,5.71026785 15.4339165,5.91518569 15.3107783,6.08562504 L12.0046634,10.6617229 Z'
          id='link'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
