
export interface CurrentCountry {
  country: string;
}

export class CurrentCountryService {
  private static _key: string;

  public static init(key: string): void {
    CurrentCountryService._key = key;
  }

  public static async getCurrentCountry(): Promise<CurrentCountry | null> {
    try {
      if (!CurrentCountryService._key) {
        return null;
      }
      const response = await fetch(`https://ipinfo.io/?token=${CurrentCountryService._key}`);
      const country: CurrentCountry = await response.json();
      return country;
    } catch (e) {
      console.error('cannot get country', e);
      return null;
    }
  }
}
