import { DrawingsCanvasConstants } from '../constants/drawing-canvas-constants';

function getZoomInCoefficientDegree(zoom: number): number {
  return Math.floor(Math.log(zoom) / Math.log(DrawingsCanvasConstants.zoomInCoefficient));
}

function calculateMaxZoomDegree(view: number, bound: number): number {
  return Math.floor(Math.log(view / bound) / Math.log(DrawingsCanvasConstants.zoomInCoefficient));
}

export const DrawingsZoomUtils = {
  getZoomInCoefficientDegree,
  calculateMaxZoomDegree,
};
