import { State } from 'common/interfaces/state';
import { store } from '../../store';
import { BaseResourcesApi } from './base-resource-api';
import { ProjectResourcesApi } from './project-resources-api';

const getBaseUrl = (url: string): string => {
  const state: State = store.getState();
  const project = state.projects.currentProject;
  const projectId = project && project.id;
  const revisionId = state.persistedStorage.projectIdToRevisionId[projectId];

  if (!revisionId) {
    throw new Error('RevisionResourcesApi: revisionId is empty');
  }

  return ProjectResourcesApi.getUrl(`/revisions/${revisionId}${url}`);
};

export const RevisionResourcesApi = new BaseResourcesApi(getBaseUrl);
