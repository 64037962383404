import * as t from 'io-ts';

import { CustomElementFilterNumberOperationC } from 'common/enums/custom-element-filter-number-operation';
import { CustomElementFilterBaseC } from '.';


export const CustomElementFilterNumberC = t.intersection([
  t.type({
    propertyKey: t.string,
    operation: CustomElementFilterNumberOperationC,
    operationArg: t.number,
  }),
  CustomElementFilterBaseC,
]);

export type CustomElementFilterNumber = t.TypeOf<typeof CustomElementFilterNumberC>;
