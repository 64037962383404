import * as React from 'react';

export const KreoIconCloseSmall: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Icons/14x14/Close_Small_Dark' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M7.70710678,7 L10.3535534,9.64644661 C10.5488155,9.84170876 10.5488155,10.1582912 10.3535534,10.3535534
          C10.1582912,10.5488155 9.84170876,10.5488155 9.64644661,10.3535534 L7,7.70710678 L4.35355339,10.3535534
          C4.15829124,10.5488155 3.84170876,10.5488155 3.64644661,10.3535534 C3.45118446,10.1582912
          3.45118446,9.84170876 3.64644661,9.64644661 L6.29289322,7 L3.64644661,4.35355339 C3.45118446,4.15829124
          3.45118446,3.84170876 3.64644661,3.64644661 C3.84170876,3.45118446 4.15829124,3.45118446
          4.35355339,3.64644661 L7,6.29289322 L9.64644661,3.64644661 C9.84170876,3.45118446 10.1582912,3.45118446
          10.3535534,3.64644661 C10.5488155,3.84170876 10.5488155,4.15829124 10.3535534,4.35355339 L7.70710678,7 Z'
          id='Combined-Shape'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
