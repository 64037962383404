const prefix = `@projects-folder`;

export const ProjectsFolderActionTypes = {
  GET_FOLDERS: `${prefix}/GET_FOLDERS`,
  SET_FOLDERS: `${prefix}/SET_FOLDERS`,
  CREATE_FOLDER: `${prefix}/CREATE_FOLDER`,
  CREATE_FOLDER_SUCCESS: `${prefix}/CREATE_FOLDER_SUCCESS`,
  REMOVE_FOLDER: `${prefix}/REMOVE_FOLDER`,
  REMOVE_FOLDER_SUCCESS: `${prefix}/REMOVE_FOLDER_SUCCESS`,
  SET_REMOVING_FOLDER_INFO: `${prefix}/SET_REMOVING_FOLDER_INFO`,
  UPDATE_FOLDER: `${prefix}/UPDATE_FOLDER`,
  UPDATE_FOLDER_SUCCESS: `${prefix}/UPDATE_FOLDER_SUCCESS`,
  SET_CURRENT_FOLDER: `${prefix}/SET_CURRENT_FOLDER`,
};
