import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  min-width: 200px;
  padding: 10px 20px;

  &:hover {
    p {
      color: ${p => p.theme.color.turquoise};
    }

    svg {
      fill: ${p => p.theme.color.turquoise};
    }
  }

  p {
    font-weight: 400;
    line-height: 20px;
    margin-right: 15px;
    text-transform: capitalize;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    fill: ${props => props.theme.color.gray};
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  }
`;

const Value = styled.div<{ valueColor: string, needReverseColor: boolean }>`
  box-sizing: border-box;
  height: 20px;
  margin-left: auto;
  padding: 4px 0;
  padding-right: 7px;
  padding-left: 8px;
  color: ${p => p.needReverseColor ? Constants.Colors.GENERAL_COLORS.darkBlue : '#fff'};
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  background-color: ${props => props.valueColor};
  white-space: nowrap;
`;

export const Styled = {
  Container,
  Value,
};

