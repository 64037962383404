import React from 'react';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { ANNOTATIONS_ADV_DIALOG_NAME } from '../../../../../units/2d/components/annotations-adv-dialog';
import { AUTO_MEASURE_ADV_DIALOG_NAME } from '../../../../../units/2d/components/auto-measure-adv-dialog';
import { TEMPLATES_ADV_DIALOG_NAME } from '../../../../../units/2d/components/templates-adv-dialog';
import { BillingPeriodUnit } from '../../../../../units/subscription/enums/billing-period-unit';
import { SubscriptionAddon } from '../../../interfaces/subscription-addon';
import { Addon } from './addon/addon';
import { useToggleSelectionCallback } from './hooks';
import { Styled } from './styled';

type AddonGroupName = 'Auto Measure' | 'Annotation Tools' | 'Templates for Projects';
export type AddonAdvDialogName = typeof AUTO_MEASURE_ADV_DIALOG_NAME |
typeof ANNOTATIONS_ADV_DIALOG_NAME |
typeof TEMPLATES_ADV_DIALOG_NAME;

const addonGroupNameToAdvDialogName: Record<AddonGroupName, AddonAdvDialogName> = {
  'Auto Measure': AUTO_MEASURE_ADV_DIALOG_NAME,
  'Annotation Tools': ANNOTATIONS_ADV_DIALOG_NAME,
  'Templates for Projects': TEMPLATES_ADV_DIALOG_NAME,
};

interface Props {
  availableAddons: SubscriptionAddon[];
  selectedAddonIds: string[];
  onSelectedAddonIdsChange: (selectedAddons: string[]) => void;
  isTeamPlan: boolean;
  billingPeriod: BillingPeriodUnit;
  fontWeight?: string;
  switcherSize?: string;
  canAddAddons?: boolean;
  isPlanArhived?: boolean;
}

export const AddonList: React.FC<Props> = ({
  availableAddons,
  selectedAddonIds,
  isTeamPlan,
  billingPeriod,
  fontWeight,
  switcherSize,
  canAddAddons,
  isPlanArhived,
  onSelectedAddonIdsChange,
}) => {
  const selectedAddonIdsMap = new Set(selectedAddonIds);
  const selfServiceAddons = React.useMemo(() => {
    return availableAddons.filter(a => a.isSelfService);
  }, [availableAddons]);
  const handleToggleSelection = useToggleSelectionCallback(selectedAddonIds, onSelectedAddonIdsChange, canAddAddons);

  return (
    <Styled.Container>
      {
        selfServiceAddons
          .map(addon => {
            const isSelected = selectedAddonIdsMap.has(addon.id);
            const isDisabled = !isSelected && isPlanArhived && !canAddAddons;

            return (
              <UpgradeWrapper key={addon.name} isNeedUpdate={isDisabled}>
                <Addon
                  addon={addon}
                  isSelected={isSelected}
                  isTeamPlan={isTeamPlan}
                  advDialogName={addonGroupNameToAdvDialogName[addon.groupName]}
                  toggleSelection={handleToggleSelection}
                  billingPeriod={billingPeriod}
                  fontWeight={fontWeight}
                  switcherSize={switcherSize}
                  disable={isDisabled}
                />
              </UpgradeWrapper>
            );
          })
      }
    </Styled.Container>
  );
};
