import { IconButton, Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';


import { RenderIf } from 'common/components/render-if';
import { Mood } from 'common/enums/mood';
import { useCurrentProjectFullScreen } from 'persisted-storage/hooks';
import { TwoDFullScreenMode } from 'persisted-storage/interfaces/state';
import { useRendererApiContext } from '../drawings-contexts';
import { DrawingsUndoRedoButtons } from '../drawings-undo-redo-buttons';
import { DrawingsDrawMode } from '../enums';
import { useDrawModeApi, useStartWizzardWithTargetType } from '../hooks';
import { useNewInstanceAggregationType } from '../hooks/use-new-instance-type';
import { Counter } from './counter/counter';
import { useInstrumentsListeners, useToggleDrawMode } from './hooks';
import { useDrawingsControlReduxState } from './hooks/use-drawings-controls-redux-state';
import { useOptions } from './hooks/use-options';
import { InstrumentMenu, SelectModeSettings } from './instruments-menu';
import { Styled } from './styled';
import { wrappWithHotkeys } from './wrap-with-hotkeys';

const DrawingsControlsNewComponent: React.FC = () => {
  const { drawMode } = useDrawModeApi();
  const setDrawMode = useToggleDrawMode();
  const { rendererApi } = useRendererApiContext();

  const startWizzard = useStartWizzardWithTargetType();

  const instanceType = useNewInstanceAggregationType();

  const onSelectItem = useCallback(({ mode, resultType, image }: SelectModeSettings) => {
    if (drawMode === DrawingsDrawMode.Image && mode === DrawingsDrawMode.Image) {
      rendererApi.engine.tempImageId = image;
      return;
    }
    if (mode === drawMode && instanceType !== resultType) {
      if (mode === DrawingsDrawMode.Wand || mode === DrawingsDrawMode.Dropper) {
        startWizzard(mode, resultType);
        return;
      }
    }
    setDrawMode(mode, {
      afterSave: () => {
        if (mode === DrawingsDrawMode.Image) {
          rendererApi.engine.tempImageId = image;
        } else {
          startWizzard(mode, resultType);
        }
      },
    });
  }, [setDrawMode, startWizzard, drawMode, rendererApi, instanceType]);

  const options = useOptions();

  const {
    geometryCount,
    drawingRendered,
    toggleOpenMeasuremetsPanel,
  } = useDrawingsControlReduxState();

  useInstrumentsListeners(options);

  const [ mode ] = useCurrentProjectFullScreen();

  return (
    <Styled.Container>
      <DrawingsUndoRedoButtons />
      <InstrumentMenu
        size="small"
        horizontalMode={true}
        tooltipPosition='bottom'
        tooltipDelay='xl'
        activeMenuItem={drawMode}
        onClick={onSelectItem}
        tools={options}
        instanceType={instanceType}
      />
      <Styled.LegendPanelToggle>
        <Counter tooltipPosition='bottom' count={geometryCount} />
        <IconButton
          mood={mode === TwoDFullScreenMode.Report ? Mood.Disabled : Mood.Default}
          height={40}
          Icon={Icons.Layers}
          onClick={toggleOpenMeasuremetsPanel}
        />
      </Styled.LegendPanelToggle>
      <RenderIf condition={!drawingRendered}>
        <Styled.Blanket />
      </RenderIf>
    </Styled.Container>
  );
};


export const DrawingsControls = wrappWithHotkeys(React.memo(DrawingsControlsNewComponent));
