import autobind from 'autobind-decorator';
import moment from 'moment';
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import './index.scss';

import { DATE_FORMAT, MaterialDatePickerField, MaterialInputProps } from 'common/UIKit';
import { EDIT_SCENARIO } from '../../../constants/forms';
import { Scenario } from '../../../units/scenarios/interfaces/scenarios-data';
import { KreoFormDialog } from '../base/kreo-form-dialog';
import { LocationMenu } from '../fields';
import { Required } from '../validators';

export const UPDATE_SCENARIO_DIALOG_NAME = 'updateScenario';

interface OwnProps {
  scenario: Scenario;
  close: () => void;
}

interface Props extends OwnProps, InjectedFormProps<{}, OwnProps> {}

const validateFields = {
  location: 'Required',
};

const validateRequired = [Required];

class ScenarioDialogComponent extends React.Component<Props> {
  public componentDidMount(): void {
    if (this.props.scenario) {
      this.initByScenario();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.scenario && this.props.scenario && prevProps.scenario.id !== this.props.scenario.id
    ) {
      this.initByScenario();
    }
  }

  public render(): React.ReactNode {
    const { handleSubmit, scenario } = this.props;

    return (
      <KreoFormDialog
        name={UPDATE_SCENARIO_DIALOG_NAME}
        title={scenario && scenario.name}
        submitButtonText={'Recalculate'}
        handleSubmit={handleSubmit}
        onDialogClose={this.onDialogClose}
        formName={EDIT_SCENARIO}
        validateFields={validateFields}
        bodyClassName='scenario-dialog'
        modal={true}
      >
        <div className='scenario-dialog__fields'>
          <Field<MaterialInputProps>
            name='startDate'
            label='Start Date'
            className='scenario-dialog__date-selector'
            validate={validateRequired}
            component={MaterialDatePickerField}
            type='text'
            isFloatingLabel={false}
            min={moment().add(-1, 'days').format(DATE_FORMAT)}
          />
          <LocationMenu validate={validateRequired} />
        </div>
      </KreoFormDialog>
    );
  }

  @autobind
  private onDialogClose(): void {
    this.props.reset();
    this.props.close();
  }

  private initByScenario(): void {
    this.props.initialize({
      startDate: moment(this.props.scenario.startDate).format(DATE_FORMAT),
      locationId: this.props.scenario.locationId,
    });
  }
}

export const ScenarioDialog = reduxForm<{}, OwnProps>({
  form: EDIT_SCENARIO,
})(ScenarioDialogComponent);
