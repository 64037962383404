import {
  AllPropertyValues,
  BreakdownProperty,
  FormulaProperty,
  FormulaPropertyValue,
  NumericProperty,
  NumericPropertyValue,
  Property,
  PropertyTypeEnum,
  SingleSelectProperty,
  SingleSelectPropertyValue,
  TextProperty,
  TextPropertyValue,
} from 'unit-2d-database/interfaces';

function isNumber(property: Property): property is NumericProperty  {
  return property.value.type === PropertyTypeEnum.Number;
}

function isText(property: Property): property is TextProperty {
  return property.value.type === PropertyTypeEnum.Text;
}

function isFormula(property: Property): property is FormulaProperty {
  return property.value.type === PropertyTypeEnum.Formula;
}

function isBreakdown(property: Property): property is BreakdownProperty {
  return property.value.type === PropertyTypeEnum.Breakdown
  || property.value.type === PropertyTypeEnum.BreakdownLink;
}

function isSingleSelect(property: Property): property is SingleSelectProperty {
  return property.value.type === PropertyTypeEnum.SingleSelect
  || property.value.type === PropertyTypeEnum.SingleSelectLink;
}

function isTextValue(
  propertyValue: AllPropertyValues,
): propertyValue is TextPropertyValue {
  return propertyValue.type === PropertyTypeEnum.Text;
}

function isNumberValue(
  propertyValue: AllPropertyValues,
): propertyValue is NumericPropertyValue {
  return propertyValue.type === PropertyTypeEnum.Number;
}

function isFormulaValue(
  propertyValue: AllPropertyValues,
): propertyValue is FormulaPropertyValue  {
  return propertyValue.type === PropertyTypeEnum.Formula;
}

function isSingleSelectValue(
  propertyValue: AllPropertyValues,
): propertyValue is SingleSelectPropertyValue {
  return propertyValue.type === PropertyTypeEnum.SingleSelect
   || propertyValue.type === PropertyTypeEnum.SingleSelectLink;
}

export const PropertiesTypeGuards = {
  isNumber,
  isText,
  isFormula,
  isSingleSelect,
  isBreakdown,
  isTextValue,
  isNumberValue,
  isFormulaValue,
  isSingleSelectValue,
};
