import * as t from 'io-ts';

import { CustomElementFilterBaseC } from '.';


export const CustomElementFilterReferenceC = t.intersection([
  t.type({
    elementFilterId: t.string,
  }),
  CustomElementFilterBaseC,
]);

export type CustomElementFilterReference = t.TypeOf<typeof CustomElementFilterReferenceC>;
