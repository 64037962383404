import { Text } from '@kreo/kreo-ui-components';
import React, { PropsWithChildren } from 'react';
import { InputSelect } from 'common/components/inputs';
import { ValueWithEditIcon } from 'common/components/values-with-edit';
import { DropDownProps } from 'unit-2d-database/components/side-panel/components/item-panel/interfaces';
import { Styled } from './styled';

export interface Props {
  label: string;
  dropDownProps?: DropDownProps;
  inputId?: string;
  startEditing?: (e: React.MouseEvent) => void;
  editIcon?: React.ComponentType;
}

export const FormField = (props: PropsWithChildren<Props>): any => {
  const {
    label,
    dropDownProps,
    children,
    startEditing,
    editIcon,
  } = props;
  return (
    <Styled.Container>
      <Text>{label}</Text>
      <Styled.InputContainer hasUnit={!!dropDownProps}>
        <ValueWithEditIcon Icon={editIcon} startEditing={startEditing}>
          {children}
        </ValueWithEditIcon>
        {dropDownProps && (
          <Styled.UnitWrap>
            <InputSelect
              elements={dropDownProps.elements}
              activeElementIndex={dropDownProps.activeElementIndex}
              onClick={dropDownProps.onClick}
              isCollaps={true}
              createNewElement={true}
              value={dropDownProps.value}
              onBlur={dropDownProps.onBlur} />
          </Styled.UnitWrap>
        )}
      </Styled.InputContainer>
    </Styled.Container>
  );
};
