import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { actions } from '../../actions';
import { SidePanelTab, SidePanelTabKeys } from '../../enums/side-panel-tab';
import { ModeSwitcher } from '../mode-switcher';

interface HeaderStateProps {
  activeTab: SidePanelTab;
}

interface HeaderDispatchProps {
  setActiveTab: (activeTab: SidePanelTab) => void;
}

interface HeaderProps extends HeaderStateProps, HeaderDispatchProps {}

class FourDSidePanelHeaderComponent extends React.Component<HeaderProps> {
  public render(): React.ReactNode {
    return (
      <div
        className='four-d-side-panel__header'
      >
        <ModeSwitcher
          modes={SidePanelTabKeys}
          activeMode={SidePanelTab[this.props.activeTab]}
          setActiveMode={this.setActiveMode}
          large={true}
        />
      </div>
    );
  }

  @autobind
  private setActiveMode(tabName: string): void {
    this.props.setActiveTab(SidePanelTab[tabName]);
  }
}

const mapStateToProps = (state: State): HeaderStateProps => {
  return {
    activeTab: state.fourDVisualisation.sidePanel.activeTab,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): HeaderDispatchProps => {
  return {
    setActiveTab: (tab: SidePanelTab): void => {
      dispatch(actions.setSidePanelTab(tab));
    },
  };
};

export const FourDSidePanelHeader = connect(mapStateToProps, mapDispatchToProps)(FourDSidePanelHeaderComponent);
