import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { KreoDialogActions } from 'common/UIKit';
import { SUBSCRIBE_PAGE_APPROVE_DIALOG } from '../../subscribe-page-approve-dialog';

type UseHandleSelectPlanGroup = (setSelectedPlanGroup: (planGroup: string) => void) => (planGroup: string) => void;

export const useHandleSelectPlanGroup: UseHandleSelectPlanGroup = (setSelectedPlanGroup) => {
  const dispatch = useDispatch();
  return useCallback((planGroup) => {
    setSelectedPlanGroup(planGroup);
    dispatch(KreoDialogActions.openDialog(SUBSCRIBE_PAGE_APPROVE_DIALOG));
  }, [dispatch, setSelectedPlanGroup]);
};
