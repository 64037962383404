import styled from 'styled-components';

import { CustomDialogStyled } from 'common/components/custom-dialog';

const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  opacity: 0.1;
`;

const ConfirmationDialog = styled.div<{ isValid: boolean }>`
  ${CustomDialogStyled.Buttons} {
    margin-top: 30px;
    height: 40px;

    > button {
      width: 290px;
      height: 40px;
      background-color: ${p => p.isValid ? p.theme.color.green : p.theme.color.red};
      box-shadow: 0px 1px 4px #0000001F;

      > p {
        color: ${p => p.theme.color.mainFont};
      }

      &:hover {
        > P {
          color: ${p => p.theme.color.mainFont};
        }
      }
    }
  }

  ${CustomDialogStyled.Container} {
    > h6 {
      margin-bottom: 30px;
    }

    > p {
      width: ${p => p.isValid ? 158 : 290}px;
      height: 70px;
      font-size: 14px;
      color: ${p => p.theme.color.mainFont};
    }
  }

  ${CustomDialogStyled.Body} {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const HotKeysContainer = styled.div`
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const CtrlContainer = styled.div`
  width: 70px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 5px 0px #00000029;
  border: 1px solid ${p => p.theme.color.background};
  background-color: ${p => p.theme.color.turquoise};
`;

const MinusContainer = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 5px 0px #00000029;
  border: 1px solid ${p => p.theme.color.background};
  background-color: ${p => p.theme.color.turquoise};
`;

const DescriptionContainer = styled.div`
  width: 290px;
  height: 60px;
  text-align: center;

  p {
    text-align: center;
  }

  p::after {
    position: absolute;
    content: 'green';
    padding-left: 3px;
    color: ${p => p.theme.color.green};
  }
`;

export const Styled = {
  Container,
  ConfirmationDialog,
  HotKeysContainer,
  CtrlContainer,
  MinusContainer,
  DescriptionContainer,
};
