import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';

import { FolderContainer, GroupFolders } from 'common/components/folder-container';
import { State } from 'common/interfaces/state';
import { ProjectsFolder } from 'unit-projects/interfaces/projects-folder';


interface GroupFolder {
  id: number;
  name: string;
  parentFolderId?: number;
}

export interface StateProps {
  groups: ProjectsFolder[];
}

interface Props extends StateProps {
  id: string;
  withoutArrowContainer: boolean;
}

class FolderProjectsContainerWrapper extends React.Component<Props> {
  public render(): JSX.Element {
    const groups = this.props.groups.map(this.addNewField);

    return (
      <FolderContainer {...this.props} groups={groups} />
    );
  }

  @autobind
  private addNewField(folder: GroupFolder): GroupFolders {
    if (folder.parentFolderId) {
      return { ...folder, id: String(folder.id), parentId: String(folder.parentFolderId) };
    } else {
      return { ...folder, id: String(folder.id) };
    }
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    groups: state.projects.folders,
  };
}

export const FolderProjectsContainer = connect(mapStateToProps)(FolderProjectsContainerWrapper);
