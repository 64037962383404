import React from 'react';

import { Mood } from 'common/enums/mood';
import { useDialogState } from 'common/UIKit/dialogs/hooks';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { useStartScaling } from 'unit-2d-info-panel/hooks';
import { DrawingDialogs } from '../../constants/drawing-dialogs';
import { NoScaleDescriptionType, Descriptions } from './descriptions';


export interface NoScaleDialogState {
  onSkip: () => void;
  descriptionType: NoScaleDescriptionType;
}

const NotScaledDialogComponent: React.FC = () => {
  const dialogState = useDialogState<NoScaleDialogState>(DrawingDialogs.NOT_SCALED_DIALOG);
  const startScale = useStartScaling();
  if (!dialogState) {
    return null;
  }
  const { descriptionType, onSkip } = dialogState;
  return (
    <ConfirmationDialog
      name={DrawingDialogs.NOT_SCALED_DIALOG}
      title={`The page isn't scaled`}
      text={Descriptions[descriptionType]}
      confirmButtonText='Set Scale'
      submitButtonMood={Mood.Secondary}
      onConfirm={startScale}
      onCancel={onSkip}
      cancelButtonText='Skip'
      zIndex={1003}
    />
  );
};


export const NotScaledDialog = React.memo(NotScaledDialogComponent);
