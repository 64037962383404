import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DeferredExecutor } from 'common/utils/deferred-executer';
import { ShortPointDescription } from '../../../../interfaces/drawing-ai-annotation';
import { DrawingsRenderParams } from '../../../../interfaces/drawing-render-parameters';
import { StrokeParams } from '../../../../interfaces/stroke-params';
import { DrawingsCanvasUtils } from '../../../../utils/drawings-canvas-utils';
import { DrawingsPaperUtils } from '../../../../utils/drawings-paper-utils';
import { DrawingsGeometryDragEventHelper } from '../../../drawings-helpers/drawings-geometry-drag-event-helper';
import { StarDragSegmentHandler } from '../../../interfaces';
import { PointsManager } from '../../../interfaces/helpers/points-managing';
import { DrawingsGeometryEntity, DrawingsMouseEventHandler } from '../../base';
import { DrawingsGeometryEntityBase } from '../../base/drawings-geometry-entity-base';
import { DrawingsGeometryEntityConfig } from '../../drawings-geometry-entity-config';
import { DrawingsGeometryEntityPoint } from '../../drawings-geometry-entity-point';
import { DrawingsGeometrySegmentSlider } from './segment-slider';


export interface SegmentDragHelper {
  startDragSegment: StarDragSegmentHandler;
  canDrag: () => boolean;
}

export interface DrawingsGeometryEntityLineConfig extends DrawingsGeometryEntityConfig {
  points: [string, string];
  layer: paper.Layer | paper.Group;
  color: paper.Color;
  modifyEnabled?: boolean;
  opacity?: number;
  onStartDrag?:  DrawingsMouseEventHandler;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
  onMouseEnter?: DrawingsMouseEventHandler;
  onMouseLeave?: DrawingsMouseEventHandler;
  strokeParams: StrokeParams;
  pointsManager: PointsManager;
  segmentDragHelper: SegmentDragHelper;
}

export class DrawingsGeometryEntityLine
  extends DrawingsGeometryEntityBase<DrawingsGeometryEntityLineConfig>
  implements DrawingsGeometryEntity {

  /* paper.js data */
  private _line: paper.Path.Line;
  private _slider: DrawingsGeometrySegmentSlider;

  /* state data */
  private _modifyEnabled: boolean;
  private _canMove: boolean;

  /* entity */
  private _point: DrawingsGeometryEntityPoint;

  /* other */
  private _hidePointExecutor: DeferredExecutor = new DeferredExecutor(300);

  constructor(config: DrawingsGeometryEntityLineConfig) {
    super(config);
    this._config = config;
    this._line = new paper.Path.Line(
      this._config.pointsManager.getPointInfo(config.points[0]),
      this._config.pointsManager.getPointInfo(config.points[1]),
    );
    DrawingsPaperUtils.updatePathStyles(this._line);
    this._line.strokeColor = config.color;
    this._line.addTo(config.layer);
    this.modifyEnabled = config.modifyEnabled;
    this._eventsApplier.setExtraInfo(this.id);
    this._eventsApplier.setHandlers(this._line);
    this._line.onMouseEnter = this.mouseEnter;
    this._line.onMouseLeave = this.mouseLeave;
    if (config.opacity) {
      this.opacity = config.opacity;
    }
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    this._line.strokeWidth = config.strokeParams.strokeWidth / zoom;
    this._line.dashArray = DrawingsCanvasUtils.scaleStroke(config.strokeParams, zoom);
    this._config.renderParamsContextObserver.subscribe(this.changeVisualData);
  }

  public set canDragMode(value: boolean) {
    this._eventsApplier.enableDrag(value);
  }

  public set opacity(value: number) {
    this._line.opacity = value;
  }

  public get opacity(): number {
    return this._line.opacity;
  }

  public set color(color: paper.Color) {
    if (color !== this._config.color) {
      this._line.strokeColor = color;
      this._config.color = color;
    }
  }

  public set modifyEnabled(value: boolean) {
    if (value === this._modifyEnabled) {
      return;
    }
    if (value) {
      this._line.onMouseMove = this.onMouseMove;
      if (this._slider) {
        this._slider.destroy();
        this._slider = null;
      }
    } else {
      this.removePoint();
      this._line.onMouseMove = null;
      if (this._canMove) {
        this.renderSlider();
      }
    }
    this._modifyEnabled = value;
  }

  public set canMove(value: boolean) {
    if (this._canMove === value) {
      return;
    }
    this._canMove = value;
    if (this._modifyEnabled) {
      return;
    }
    if (value) {
      this.renderSlider();
    } else if (this._slider) {
      this._slider.destroy();
      this._slider = null;
    }
  }

  public changeStrokeStyles(strokeStyle: StrokeParams): void {
    this._config.strokeParams = strokeStyle;
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    this._line.strokeWidth = strokeStyle.strokeWidth / zoom;
    this._line.dashArray = DrawingsCanvasUtils.scaleStroke(
      strokeStyle,
      zoom,
    );
  }

  public destroy(): void {
    this._config.renderParamsContextObserver.unsubscribe(this.changeVisualData);
    this._hidePointExecutor.reset();
    this.removePoint();
    this._line.remove();
    if (this._slider) {
      this._slider.destroy();
    }
  }

  public changePointPosition(id: string): void {
    const index = this._config.points.indexOf(id);
    this._line.segments[index].point = this._config.pointsManager.getPointInfo(id);
    if (this._slider) {
      this._slider.updatePoints([this._line.firstSegment.point, this._line.lastSegment.point]);
    }
  }

  public getCenter(): ShortPointDescription {
    const { x, y } = this._line.bounds.center;
    return [x, y];
  }

  public getColor(): paper.Color {
    return this._line.strokeColor;
  }

  @autobind
  public mouseLeave(_id: string, e: PaperMouseEvent): void {
    if (this._config.onMouseLeave) {
      this._config.onMouseLeave(this.id, e);
    }
    if (this._modifyEnabled) {
      this._hidePointExecutor.execute(this.removePoint);
    }
  }

  @autobind
  protected override onClick(e: PaperMouseEvent): void {
    this._config.onSelect(this.id, e);
  }

  private renderSlider(): void {
    if (!this._config.segmentDragHelper.canDrag()) {
      return;
    }
    this._slider = new DrawingsGeometrySegmentSlider({
      id: this.id,
      layer: this._config.layer,
      geometry: [this._line.firstSegment.point, this._line.lastSegment.point],
      startDragSegment: this._config.segmentDragHelper.startDragSegment,
      mouseEnter: this.mouseEnter,
      mouseLeave: this.mouseLeave,
      cursorHelper: this._config.cursorHelper,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
    });
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    this._slider.scale(1 / zoom);
  }

  @autobind
  private changeVisualData(renderParameters: DrawingsRenderParams): void {
    this._line.strokeWidth = this._config.strokeParams.strokeWidth / renderParameters.zoom;
    this._line.dashArray = DrawingsCanvasUtils.scaleStroke(
      this._config.strokeParams,
      renderParameters.zoom,
    );
    if (this._slider) {
      this._slider.scale(this._config.renderParamsContextObserver.getPrevContext().zoom / renderParameters.zoom);
    }
  }

  @autobind
  private removePoint(): void {
    if (this._point) {
      this._point.destroy();
      this._point = null;
    }
  }

  @autobind
  private onAddPoint(_id: string, e: PaperMouseEvent): void {
    e.stopPropagation();
    this._config.pointsManager.addPoint(this.id, this._point.position);
  }

  @autobind
  private mouseEnter(e: PaperMouseEvent): void {
    if (this._config.onMouseEnter) {
      this._config.onMouseEnter(this.id, e);
    } else {
      this._config.cursorHelper.hovered = true;
    }
    if (this._modifyEnabled) {
      const point = this._line.getNearestPoint(e.point);
      if (this._point) {
        this.updatePointPosition(point);
      } else {
        this._point = new DrawingsGeometryEntityPoint({
          renderParamsContextObserver: this._config.renderParamsContextObserver,
          id: 'temp',
          layer: this._config.layer,
          geometry: [point.x, point.y],
          onMouseEnter: this.onPointMouseEnter,
          onMouseLeave: this.onPointMouseLeave,
          onSelect: this.onAddPoint,
          onMouseMove: this.onPointMouseMove,
          cursorHelper: this._config.cursorHelper,
        });
      }
    }
  }

  private updatePointPosition(point: paper.Point): void {
    this._hidePointExecutor.reset();
    this._point.changePosition(point.x, point.y);
  }

  private onPointMouseMove(_id: string, e: PaperMouseEvent): void {
    this.updatePointPosition(this._line.getNearestPoint(e.point));
  }

  @autobind
  private onPointMouseLeave(_id: string, e: PaperMouseEvent): void {
    this.mouseLeave(this.id, e);
  }

  @autobind
  private onPointMouseEnter(_id: string, e: PaperMouseEvent): void {
    this._hidePointExecutor.reset();
    this.mouseEnter(e);
  }

  @autobind
  private onMouseMove(e: PaperMouseEvent): void {
    e.stopPropagation();
    if (this._point) {
      this._point.changePosition(e.point.x, e.point.y);
    } else {
      this.mouseEnter(e);
    }
  }
}
