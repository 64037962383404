import { PiaBreakdownNode } from 'unit-2d-database/interfaces';

export const getMaxNesting = (nodes: PiaBreakdownNode[]): number => {
  let maxNesting = 0;
  if (!nodes.length) {
    return maxNesting;
  }

  for (const node of nodes) {
    const nesting = getMaxNesting(node.children);
    if (nesting > maxNesting) {
      maxNesting = nesting;
    }
  }

  return maxNesting + 1;
};
