import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { CollapseExpandButtonStyled } from './collapse-expand-button';

const SEPARATOR_SIZE = 4;

interface StylesCollapseExpandButton {
  separatorLength: number;
  isHorizontal: boolean;
  isCollapseOpen: boolean;
}

function getStylesCollapseExpandButton(
  { separatorLength, isHorizontal, isCollapseOpen }: StylesCollapseExpandButton,
): FlattenSimpleInterpolation {
  if (isHorizontal) {
    return css`
      top: -8px;
      left: ${separatorLength - (separatorLength / 5)}px;
      > svg {
        transform: rotate(${isCollapseOpen ? 180 : 0}deg);
      }
    `;
  } else {
    return css`
      top: ${separatorLength - (separatorLength / 5)}px;
      right: 8px;
      > svg {
        transform: rotate(${isCollapseOpen ? 90 : 270}deg);
      }
    `;
  }
}

interface ContainerProps extends StylesCollapseExpandButton{
  showCollapseExpandButton: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .layout-pane {
    flex-grow: 1;
    flex-basis: auto;
    overflow: hidden;
  }

  >.layout-splitter {
    position: relative;
    z-index: 3;
    flex: 0 0 auto;
    height:  ${props => props.isHorizontal ? `${SEPARATOR_SIZE}px` : '100%'};
    width:  ${props => props.isHorizontal ? '100%' : `${SEPARATOR_SIZE}px`};
    margin-right: ${props => props.isHorizontal ? 0 : `-${SEPARATOR_SIZE}px`};
    cursor: ${props => props.isHorizontal ? 'row-resize' : 'col-resize'};
    background-color: ${props => props.theme.color.pale};
    transition: ${p => p.theme.duration.s} ease-in-out;

    &:hover,
    &:active {
      background-color: ${props => props.theme.color.turquoise};
      z-index: 3;

      ${CollapseExpandButtonStyled.Container} {
        display: ${p => p.showCollapseExpandButton ? 'flex' : 'none'};
        transition: ${p => p.theme.duration.s} ease-in-out;
        ${p => getStylesCollapseExpandButton(p)};
      }

      .layout-splitter__toggle {
        width: 26px;
        border-radius: 50%;
      }
    }

    .layout-splitter__toggle {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 16px;
      height: 26px;
      left: 2px;
      transform: translateX(-50%) rotate(180deg);
      color: #fff;
      background-color: inherit;
      border-radius: 6px;
      transition: width 0.2s ease-in-out;
      cursor: pointer;

      svg {
        pointer-events: none;
      }
    }
  }

  &.splitter-layout--separator-hide > .layout-splitter {
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  &.layout-changing * {
    cursor: ${props => props.isHorizontal ? 'row-resize' : 'col-resize'} !important;
  }

  &.splitter-layout-vertical {
    flex-direction: column;
  }

  &.splitter-layout--primary-collapse {
    >.layout-splitter {

      &:hover {
        cursor: default;
      }

      &:hover .layout-splitter__toggle {
        border-radius: 0 0 6px 0;
      }

      .layout-splitter__toggle {
        left: 0;
        transform: translateX(0) rotate(0);
        border-radius: 0 0 6px 0;
      }
    }
  }
`;

export const Styled = {
  Container,
};
