import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Qto3dProjectRouteParams } from 'routes/app-routes-params';
import { PlanProjectRouteParams } from 'routes/plan';
import { InformationModeling } from 'unit-cost-estimate/components/information-modeling';
import { ProjectLayout } from '../../../units/project-dashbord';

interface PageProps extends RouteComponentProps<PlanProjectRouteParams | Qto3dProjectRouteParams> {
}

export const InformationModellingPage = (props: PageProps): JSX.Element => {
  const projectIdString = props.match.params.projectId;
  const projectId = parseInt(projectIdString);

  return (
    <ProjectLayout
      // waitingForAdditionalInfo={!isLoaded}
      projectId={projectIdString}
    >
      <InformationModeling
        projectId={projectId}
      />
    </ProjectLayout>
  );
};
