import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './measurements-general-input.scss';

import { State } from 'common/interfaces/state';
import { CEMeasurementsActions } from 'unit-cost-estimate/actions/creators';
import {
  CEMeasurementExtractorFunctionTemplate,
  CEMeasurementsExtractorEditorVisualRow,
} from 'unit-cost-estimate/interfaces';
import { CEMeasurementsUtils } from 'unit-cost-estimate/utils';
import { MeasurementsExtractorItemCheckbox } from 'unit-projects/components/measurements-extractor-item-checkbox';
import { MeasurementsExtractorItemContainer } from 'unit-projects/components/measurements-extractor-item-container';
import {
  MeasurementsExtractorItemInnerContainer,
} from 'unit-projects/components/measurements-extractor-item-inner-container';
import { withMeasurementEngineLayoutContext } from 'unit-projects/components/with-measurement-engine-layout-context';
import { MeasurementsExtractorEditorRowType } from 'unit-projects/enums/measurements-extractor-editor-row-type';
import {
  MeasurementGeneralExtractorEditor,
} from 'unit-projects/interfaces/measurements/measurement-general-extractor-editor';
import {
  WithMeasurementsEngineContextAwareProps,
} from 'unit-projects/interfaces/measurements/with-measurements-engine-context-aware-props';
import { MeasurementsExtractorInput } from '../measurements-extractor-input';


interface OwnProps {
  disabledEdit: boolean;
}

interface StateProps {
  selected: boolean;
  generalExtractorEditors: Record<string, MeasurementGeneralExtractorEditor>;
  extractorTemplates: Record<string, CEMeasurementExtractorFunctionTemplate>;
  indeterminate: boolean;
  editorRows: CEMeasurementsExtractorEditorVisualRow[];
}

interface DispatchProps {
  toggleCheckStatus: (value: boolean) => void;
}

interface Props extends OwnProps, StateProps, WithMeasurementsEngineContextAwareProps, DispatchProps {

}


class MeasurementsGeneralInputComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { selected, disabledEdit, indeterminate } = this.props;
    return (
      <MeasurementsExtractorItemContainer
        className='measurements-general-input'
        onClick={this.onClick}
      >
        <MeasurementsExtractorItemCheckbox
          label={selected ? 'All selected' : ''}
          checked={selected}
          onChange={this.toggleSelectStatus}
          disabled={disabledEdit}
          indeterminate={indeterminate}
        />
        {
          this.renderGeneralInputs()
        }
      </MeasurementsExtractorItemContainer>
    );
  }

  private renderGeneralInputs(): React.ReactNode {
    const { disabledEdit, indeterminate } = this.props;

    return Object.entries(this.props.extractorTemplates).map(([templateKey, template]) => (
      <MeasurementsExtractorItemInnerContainer
        className='measurements-general-input__inputs'
        doubleInputs={template.extractors.length > 1}
        key={templateKey}
      >
        {template.extractors.map(extractor => (
          <MeasurementsExtractorInput
            key={extractor.extractorFunctionId || 'undefined'}
            disabled={disabledEdit || !indeterminate}
            isTotal={true}
            functionId={extractor.extractorFunctionId}
            generalFunctionId={template.generalFunctionId}
            onItemSelect={this.onGeneralFocus}
          />
        ))}
      </MeasurementsExtractorItemInnerContainer>
    ));
  }

  private getExtractorElementsIds(): number[] {
    const ids = new Array<number>();
    for (const row of this.props.editorRows) {
      if (row.type === MeasurementsExtractorEditorRowType.Activity) {
        ids.push(row.data.engineId);
      }
    }
    return ids;
  }

  @autobind
  private toggleSelectStatus(value: boolean): void {
    this.props.toggleCheckStatus(value);
    const elementsIds = this.getExtractorElementsIds();
    if (value) {
      this.props.onSelectElements(elementsIds);
    } else {
      this.props.onSelectElements([]);
    }
    this.props.onShowProperties(this.getExtractorElementsIds());
  }

  @autobind
  private onClick(): void {
    this.props.onShowProperties(this.getExtractorElementsIds());
  }

  @autobind
  private onGeneralFocus(): void {
    this.props.onSelectElements(this.getExtractorElementsIds());
  }
}


function mapStateToProps({ ceMeasurements }: State): StateProps {
  const {
    selected,
    indeterminate,
  } = CEMeasurementsUtils.extractorsGroupSelectionStatus(0, ceMeasurements, MeasurementsExtractorEditorRowType.General);
  return {
    generalExtractorEditors: ceMeasurements.generalExtractorEditors,
    extractorTemplates: ceMeasurements.extractorFunctions,
    selected,
    indeterminate,
    editorRows: ceMeasurements.extractorEditorRows,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    toggleCheckStatus: value => {
      dispatch(CEMeasurementsActions.toggleGeneralSelectStatus(value));
    },
  };
}

const WithEngineApi = withMeasurementEngineLayoutContext(MeasurementsGeneralInputComponent);
const connector = connect(mapStateToProps, mapDispatchToProps);
export const MeasurementsGeneralInput = connector(WithEngineApi);
