import { fromJS }  from 'immutable';

import { Actions } from '../constants';

const INITIAL_STATE = fromJS({
  allocationData: [],
  humans: {
    status: 'not_loaded',
    resources: []
  },
  plant: {
    status: 'not_loaded',
    resources: []
  },
  equipment: {
    status: 'not_loaded',
    resources: []
  },
  selected_humans: {

  },
  selected_plants: {

  }
});


 export default function resources(
  state: any = INITIAL_STATE,
  action: any = { type: '', payload: null }): any {

  switch (action.type) {
    case Actions.Resources.RESOURCES_ALLOCATION_DATA_SUCCESSED:
      return state.merge(fromJS({
        allocationData: action.payload
      }));
    case Actions.Resources.RESOURCES_HUMANS_SUCCESSED:
      return state.merge(fromJS(
        { humans:
        {
          status: Actions.Resources.RESOURCES_HUMANS_SUCCESSED,
          resources: action.payload
        }
        }
      ));
    case Actions.Resources.RESOURCES_PLANT_SUCCESSED:
      return state.merge(fromJS(
        { plant:
          {
            status: Actions.Resources.RESOURCES_HUMANS_SUCCESSED,
            resources: action.payload
          }
        }
      ));
    case Actions.Resources.RESOURCES_HUMANS_SELECT:
    default:
      return state;
  }
}
