/* eslint-disable react/jsx-no-bind */
import { SearchList, SearchListItem, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { ExcelExpressionEvaluator } from 'common/excel-expression-evaluator';


interface Props {
  onClick: (value: string) => void;
}

interface Formula {
  name: string;
  code: string;
}

export const TwoDReportFormatFormulaMenu = (props: Props): JSX.Element => {
  const formulasData: Formula[] = Object.keys(ExcelExpressionEvaluator.functions)
    .map(formulaKey => ({
      name: formulaKey.toUpperCase(),
      code: formulaKey.toUpperCase(),
    }));

  const onClickItem = (formula: Formula): void => props.onClick(`${formula.code}()`);
  const getFormulaKey = (formula: Formula): string => formula.code;
  const getFormulaName = (formula: Formula): string => formula.name;
  const formulaComponent = (formula: Formula): JSX.Element => (
    <SearchListItem><Text>{formula.name}</Text></SearchListItem>
  );

  return (
    <SearchList
      items={formulasData}
      width={240}
      maxItemsCount={6}
      itemComponent={formulaComponent}
      getKeyValue={getFormulaKey}
      getStringToCompare={getFormulaName}
      onClick={onClickItem}
      inputAutoFocus={true}
    />);
};
