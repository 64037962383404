import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ClassificationHeaderBreadcrumbs } from '../classification-header-breadcrumbs';
import { ClassificationHeaderTabsContainer } from '../classification-header-tabs-container';


interface Props {
  breadCrumbsCount: number | null;
}

const ClassificationHeaderComponent: React.FC<Props> = (props) => {
  if (props.breadCrumbsCount) {
    return <ClassificationHeaderBreadcrumbs breadCrumbsCount={props.breadCrumbsCount}/>;
  } else {
    return <ClassificationHeaderTabsContainer/>;
  }
};


function mapStateToProps(state: State): Props {
  const { breadCrumbs } = state.classification.modelBrowserFilters;
  return {
    breadCrumbsCount: breadCrumbs ? breadCrumbs.length : 0,
  };
}

const connecter = connect(mapStateToProps);
export const ClassificationHeader = connecter(ClassificationHeaderComponent);
