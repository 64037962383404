import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { openLinkInNewTab } from 'common/utils/open-link-in-new-tab';
import { AppUrls } from 'routes/app-urls';
import { CompanyNotification, TwoDNotificationsActions } from 'unit-2d-notification/store-slice';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { Person } from '../../../people/interfaces/person';
import { CommentNotification } from '../comment-notification';
import { useLastAccessMemo, useNotificationMemo } from './hooks';
import { Styled } from './styled';

interface StateProps {
  notificationsModel: CompanyNotification[];
  lastAccess: Date;
  persons: Person[];
}

interface DispatchProps {
  loadNotification: (id: number) => void;
}

export interface Props extends StateProps, DispatchProps {}

export const TwoDNotificationsListComponent: React.FC<Props> = ({
  notificationsModel,
  lastAccess,
  persons,
  loadNotification,
}) => {
  const { sendEvent } = useAnalytics();
  const [lastAccessMemo] = useLastAccessMemo(lastAccess);

  const openProject = useCallback((projectId: string) => {
    openLinkInNewTab(AppUrls.qto2d.project.index.url({ projectId }));
  }, []);

  const [notifications] = useNotificationMemo(notificationsModel, lastAccessMemo, openProject, persons);

  const handleScroll = useCallback(e => {
    const container = e.target;
    if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
      loadNotification(notifications[notifications.length - 1].id);
      sendEvent(MetricNames.notification.scrollEnd);
    }
  }, [notifications]);
  return (
    <Styled.Container onScroll={handleScroll}>
      {notifications.map(n => (
        <CommentNotification
          key={n.id}
          isHightLight={n.isHightLight}
          name={n.userName}
          date={n.date}
          text={n.text}
          projectName={n.projectName}
          isDeleted={n.isDeleted}
          accessReason={n.accessReason}
          link={n.avatarLink}
          goToLink={n.goTo}
        />
      ))}
    </Styled.Container>
  );
};

const mapStateToProps = (state: State): StateProps => {
  const twoDNotifications = state.twoDNotifications;

  return {
    notificationsModel: twoDNotifications.notifications,
    lastAccess: twoDNotifications.lastAccess,
    persons: state.people.companiesUsers,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    loadNotification: (id: number) => dispatch(TwoDNotificationsActions.loadNotifications({ beforeId: id, limit: 10 })),
  };
};

export const TwoDNotificationsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TwoDNotificationsListComponent);
