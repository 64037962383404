import { Alignment } from './alignment';
import { ComparableDocumentType, ComparisonLine } from './interfaces';
import { loadCanvas } from './load-canvas-override';

function cancelLoadCanvas(id: string | number): void {
  if (!this.isComparisonEnabled) {
    return this.originalCancelLoadCanvas(id);
  }
  if (this.loadAsyncCallsToCancel[id]) {
    this.loadAsyncCallsToCancel[id].forEach((renderAction) => renderAction.cancel());
    delete this.loadAsyncCallsToCancel[id];
  }
}

export function extendDocumentToComparable(
  document: Core.Document,
  documentToCompareWith: Core.Document,
  lines: {
    sourceLine?: ComparisonLine,
    targetLine?: ComparisonLine,
  },
): ComparableDocumentType {
  const transform = lines.sourceLine && lines.targetLine
    ? Alignment.getLinesTransform(lines.sourceLine, lines.targetLine)
    : {
      scale: 1,
      rotation: 0,
      translation: { x: 0, y: 0 },
    };
  const comparableDocument = document as ComparableDocumentType;
  comparableDocument.documentToCompareWith = documentToCompareWith;
  comparableDocument.compareTransform = transform;
  comparableDocument.originalLoadCanvas = comparableDocument.loadCanvas.bind(comparableDocument);
  comparableDocument.originalCancelLoadCanvas = comparableDocument.cancelLoadCanvas.bind(comparableDocument);
  comparableDocument.cancelLoadCanvas = cancelLoadCanvas.bind(comparableDocument);
  comparableDocument.loadCanvas = loadCanvas.bind(comparableDocument);
  comparableDocument.loadAsyncCallsToCancel = {};
  comparableDocument.opacities = [1, 1];
  comparableDocument.isComparisonEnabled = true;
  return comparableDocument;
}
