import React from 'react';

import { Text } from 'common/components/text';
import { Styled } from './styled';

interface Props {
  title: string;
}

export const Category: React.FC<Props> = ({ title, children }) => {
  return (
    <Styled.Category>
      <Text
        fontSize={10}
        textTransform="uppercase"
        fontWeight={500}
        color="gray"
        letterSpacing={0.5}
        lineHeight={10}
      >
        {title}
      </Text>
      <Styled.CategoryItems>{children}</Styled.CategoryItems>
    </Styled.Category>
  );
};
