import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';

import { KreoIconInvitePeople } from 'common/UIKit';
import { BidPricingWorkPackage } from '../../../interfaces/bid-pricing/bid-pricing-work-package';

interface Props {
  isBidPricingActive: boolean;
  workPackage: BidPricingWorkPackage;
  openWorkpackageDialog(workPackage: BidPricingWorkPackage): void;
}

export class InviteLine extends React.Component<Props> {
  public render(): React.ReactNode {
    const inviteButtonClassName = classNames('bid-pricing-invitations__invite-icon', {
      disabled: !this.props.isBidPricingActive,
    });
    return (
      <div className='bid-pricing-invitations__inner-row'>
        <div onClick={this.openDialog} className={inviteButtonClassName}>
          <KreoIconInvitePeople />
        </div>
      </div>
    );
  }

  @autobind
  private openDialog(): void {
    this.props.openWorkpackageDialog(this.props.workPackage);
  }
}
