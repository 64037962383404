import React from 'react';

import {
  DrawingsStrokeStyleSwitcher,
} from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { useNewInstancesSetting } from 'common/components/drawings/hooks';
import { Property, PropertyWithSlider } from 'common/components/property';
import { usePersistedStorageValueWithChange } from 'persisted-storage/hooks';


const StrokeStylesComponent: React.FC = () => {
  const [ strokeStyle, changeStrokeStyle ] = useNewInstancesSetting('strokeStyle');
  const [ strokeWidth, setStrokeWidth ] = usePersistedStorageValueWithChange('drawingNewInstanceStrokeWidth');

  return (
    <>
      <PropertyWithSlider
        title='Stroke Width'
        value={strokeWidth}
        onChange={setStrokeWidth}
        postfix='px'
        max={10}
        min={1}
        sliderTextFontSize={14}
      />
      <Property title='Stroke Style'>
        <DrawingsStrokeStyleSwitcher
          setStrokeStyle={changeStrokeStyle}
          strokeStyle={strokeStyle}
          elementWidth={43}
        />
      </Property>
    </>
  );
};

export const StrokeStyles = React.memo(StrokeStylesComponent);
