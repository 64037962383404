export enum ModifyDatabaseEntityMode {
  Create = 'Create',
  Edit = 'Edit',
}

export enum ActivityCategoryType {
  Nrm1 = 'nrm1',
  Nrm2 = 'nrm2',
  UniProduct = 'uniProduct',
  UniSystem = 'uniSystem',
}

export enum ResourceType {
  Material = 'Material',
  Labour = 'Labour',
  Plant = 'Plant',
}

