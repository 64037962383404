import { Icons, MenuItem } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { DrawingMarkShapes, DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import {
  DrawingsStyleMenuType,
  DrawingsStylesMenu,
} from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { MultiLevelSelectOption } from 'common/components/multi-level-drop-down-menu/multi-level-select-option';
import { ConstantFunctions } from 'common/constants/functions';
import { ColorsName } from 'common/enums/kreo-colors';

interface Props {
  colorTitle: string;
  selectedColor: string;
  setColor: (color: string) => void;
  setStrokeStyle: (value: DrawingStrokeStyles) => void;
  strokeWidth: number;
  setStrokeWidth: (value: number) => void;
  styleMenuType: DrawingsStyleMenuType;
  strokeStyle: DrawingStrokeStyles;
  setShape: (value: DrawingMarkShapes) => void;
  shape: DrawingMarkShapes;
}

interface ComponentState {
  color: string;
  strokeStyle: DrawingStrokeStyles;
  strokeWidth: number;
  shape: DrawingMarkShapes;
}

export class DrawingsContextMenuGeometryStyles extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      color: props.selectedColor,
      strokeStyle: props.strokeStyle,
      strokeWidth: props.strokeWidth,
      shape: props.shape,
    };
  }

  public render(): React.ReactNode {
    return (
      <MultiLevelSelectOption
        isSelected={false}
        isSelectable={false}
        selectedValue={null}
        value={null}
        childRenderer={this.childRenderer}
        optionData={null}
        themeStyle={true}
        subvariants={null}
        onSelectClick={null}
      >
        <MenuItem
          text={'Styles'}
          textColor={ColorsName.mainFont}
          fontSize={14}
          onClick={ConstantFunctions.doNothing}
          withBorder={true}
          size='m'
          Icon={Icons.BrushSmall}
        />
      </MultiLevelSelectOption>
    );
  }

  @autobind
  private childRenderer(): React.ReactNode {
    return (
      <DrawingsStylesMenu
        shape={this.state.shape}
        setShape={this.setShape}
        color={this.state.color}
        strokeStyle={this.state.strokeStyle}
        strokeWidth={this.state.strokeWidth}
        setColor={this.setColor}
        setStrokeStyle={this.setStrokeStyle}
        setStrokeWidth={this.setStrokeWidth}
        menuType={this.props.styleMenuType}
      />
    );
  }

  @autobind
  private setStrokeWidth(strokeWidth: number): void {
    this.setState({ strokeWidth }, () => this.props.setStrokeWidth(this.state.strokeWidth));
  }

  @autobind
  private setStrokeStyle(strokeStyle: DrawingStrokeStyles): void {
    this.setState({ strokeStyle }, () => this.props.setStrokeStyle(this.state.strokeStyle));
  }

  @autobind
  private setShape(shape: DrawingMarkShapes): void {
    this.setState({ shape }, () => this.props.setShape(this.state.shape));
  }

  @autobind
  private setColor(color: string): void {
    this.setState({ color }, () => this.props.setColor(this.state.color));
  }
}
