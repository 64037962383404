import * as React from 'react';

import { ModelCheckPriority } from '../../../../enums/mode-check-priority';
import { ModelCheckType } from '../../../../enums/model-check-type';
import { PropLine } from '../../../validation/components/properties';
import { Header } from './header';

interface Props {
  family: string;
  id: string;
  category: string;
  type: string;
  priority?: ModelCheckPriority;
  modelCheckType?: ModelCheckType;
  order?: number;
  onClose: (e: React.MouseEvent<HTMLDivElement>) => void;
  canClose: boolean;
  hasBottom?: boolean;
  bottomId?: string;
}

export class HandlePropertyBlock extends React.Component<Props> {
  public render(): JSX.Element[] {
    return [
      (
        <Header
          key={0}
          canCollapse={this.props.canClose}
          family={this.props.family}
          id={this.props.id}
          priority={this.props.priority}
          type={this.props.modelCheckType}
          closeClick={this.props.onClose}
          bottomId={this.props.bottomId}
          hasBottom={this.props.hasBottom}
        />),
      (<PropLine key={1} name='Category' value={this.props.category} />),
      (<PropLine key={2} name='Family' value={this.props.family} />),
      (<PropLine key={3} name='Element type' value={this.props.type} />),
    ];
  }
}
