import { IconButton, Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { LinkComponent } from 'common/components/link-component';
import { Mood } from 'common/enums/mood';
import { RequestStatus } from 'common/enums/request-status';
import { useActionDispatch } from 'common/hooks';
import { MagicSearchActions } from '../../actions/creators/magic-search';
import { DrawingsCanvasConstants } from '../../constants';
import { ContourToFixType, FixContour } from '../../interfaces';
import { DrawingsFlyingMenuContainer } from '../drawings-flying-menu';

interface Props {
  sendRef: (ref: HTMLDivElement) => void;
  fixContourAPI: FixContour;
}

const AiSearchFixMenuComponent: React.FC<Props> = ({
  sendRef,
  fixContourAPI,
}) => {
  const { api: { tryFix, apply, setAsDeleted }, state, contourIndex, contourType } = fixContourAPI;

  const deleteContoursToFix = useActionDispatch(MagicSearchActions.deleteFixContour);

  const deleteCallback = useCallback(() => {
    if (contourType === ContourToFixType.Fixed) {
      deleteContoursToFix(contourIndex);
    } else {
      setAsDeleted();
    }
  }, [setAsDeleted, contourIndex, contourType, deleteContoursToFix]);

  switch (state) {
    case RequestStatus.Loading:
      return (
        <DrawingsFlyingMenuContainer ref={sendRef}>
          <LinkComponent
            icon={Icons.Loader}
          />
        </DrawingsFlyingMenuContainer>
      );
    case RequestStatus.Loaded:
      return (
        <DrawingsFlyingMenuContainer ref={sendRef}>
          <IconButton
            onClick={apply}
            Icon={Icons.Apply}
            defaultColor='green'
            height={DrawingsCanvasConstants.menuButtonSize}
            width={DrawingsCanvasConstants.menuButtonSize}
          />
          <LinkComponent
            icon={Icons.Reset}
            text='Again'
            onClick={tryFix}
            fontColor='mainFont'
          />
          <IconButton
            defaultColor='red'
            onClick={deleteCallback}
            Icon={Icons.Delete}
            height={DrawingsCanvasConstants.menuButtonSize}
            width={DrawingsCanvasConstants.menuButtonSize}
          />
        </DrawingsFlyingMenuContainer>
      );
    case RequestStatus.Failed:
      return (
        <DrawingsFlyingMenuContainer ref={sendRef}>
          <LinkComponent
            icon={Icons.Reset}
            text='Again'
            onClick={tryFix}
            mood='negative'
            fontColor='mainFont'
          />
          <IconButton
            defaultColor='red'
            onClick={deleteCallback}
            Icon={Icons.Delete}
            height={DrawingsCanvasConstants.menuButtonSize}
            width={DrawingsCanvasConstants.menuButtonSize}
          />
        </DrawingsFlyingMenuContainer>
      );
    default:
      return (
        <DrawingsFlyingMenuContainer ref={sendRef}>
          <LinkComponent
            icon={Icons.AITool}
            text='Fix with AI'
            onClick={tryFix}
            mood={Mood.Secondary}
            fontColor='mainFont'
          />
          <IconButton
            defaultColor='red'
            onClick={deleteCallback}
            Icon={Icons.Delete}
            height={DrawingsCanvasConstants.menuButtonSize}
            width={DrawingsCanvasConstants.menuButtonSize}
          />
        </DrawingsFlyingMenuContainer>
      );
  }
};

export const AiSearchFixMenu = React.memo(AiSearchFixMenuComponent);
