import { Avatar, Text, TinyText } from '@kreo/kreo-ui-components';
import moment from 'moment';
import * as React from 'react';

import { DateFormatConstants } from 'common/constants/date-formats';
import { AccountApi } from '../../../../../units/account/api';
import { Person } from '../../../../../units/people/interfaces/person';
import { Styled } from './styled';


interface Props {
  title: string;
  user: Person;
  timestamp: string | Date;
}

export const UserInfoHint = ({ title, user, timestamp }: Props): JSX.Element => {
  const fullName = `${user.firstName} ${user.lastName}`;
  return (
    <Styled.InstanceInfoContainer>
      <TinyText textTransform={'uppercase'} color={'gray'}>{title}:</TinyText>
      <Styled.UserInfo>
        <Avatar
          name={fullName}
          size={40}
          avatar={AccountApi.getAvatarLink({ id: user.id, hasAvatar: user.hasAvatar })}
        />
        <Text fontSize={14}>{fullName}</Text>
      </Styled.UserInfo>
      <TinyText color={'gray'}>{moment(timestamp).format(DateFormatConstants.FULL_DATE_TIME_FORMAT)}</TinyText>
    </Styled.InstanceInfoContainer>
  );
};
