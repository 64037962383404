import React, { useState } from 'react';

import { FormulaField } from '../formula-field';
import { FormulaAPI } from '../input';
import { InsertionMenu } from '../insertion-menu/insertion-menu';
import { Buttons } from './buttons';
import { Header } from './header';
import { Styled } from './styled';

interface Props {
  properties?: string[];
  value: string;
  onChangeValue: (value: string) => void;
  onChangeValidation: (isValid: boolean) => void;
  formulaName?: string;
  onSaveClick: () => void;
  renderFormulaOptions?: () => React.ReactNode;
}

const FormulaEditorComponent: React.FC<Props> = ({
  value,
  properties,
  onChangeValidation,
  onChangeValue,
  onSaveClick,
  formulaName = 'Formula',
  renderFormulaOptions,
}) => {
  const [formulaApi, setFormulaApi] = useState<FormulaAPI>();
  return (
    <Styled.Container>
      <InsertionMenu formulaApi={formulaApi} properties={properties} />
      <Styled.FormulaPart>
        <Header formulaName={formulaName} />
        <FormulaField
          value={value}
          onChangeValue={onChangeValue}
          onChangeValidation={onChangeValidation}
          properties={properties}
          sendApi={setFormulaApi}
        />
        {
          renderFormulaOptions ? renderFormulaOptions() : null
        }
        <Buttons
          onSaveClick={onSaveClick}
        />
      </Styled.FormulaPart>
    </Styled.Container>
  );
};

export const FormulaEditor = FormulaEditorComponent;
