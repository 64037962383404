import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './classification-ontology-tree-element-body.scss';

import { MultiLevelDropDownMenu } from 'common/components/multi-level-drop-down-menu';
import { TooltipContainer } from 'common/components/tooltip-container';
import { StringDictionary } from 'common/interfaces/dictionary';
import { KreoButton } from 'common/UIKit';
import { PreparedSearchQuery } from 'common/utils/string-utils';
import { ClassificationObjectType } from '../../enums/classification-object-type';
import {
  ClassificationOntologyUniclassInfo,
  ClassificationOntologyVariantTree,
} from '../../interfaces/classification/ontology-data';
import { ClassificationUtils } from '../../utils/classification-utils';
import {
  ClassificationOntologyAppropriateUniclass,
} from '../classification-ontology-appropriate-uniclass';
import { ClassificationOntologyTreeElementType } from '../classification-ontology-tree-element-type';


interface Props {
  readableName: string;
  canonicalName: string;
  type: ClassificationObjectType;
  elementInfo: string;
  isError: boolean;
  isEdit: boolean;
  isMultilayer: boolean;
  index: number;
  uniclass: ClassificationOntologyUniclassInfo;
  variants: ClassificationOntologyVariantTree[];
  mainLayer: boolean;
  onSelect: (variant: ClassificationOntologyVariantTree) => void;
}

interface State {
  onShow: boolean;
  variants: StringDictionary<ClassificationOntologyVariantTree>;
  dropDownStyle: React.CSSProperties;
}

export class ClassificationOntologyTreeElementBody extends React.PureComponent<Props, State> {
  private readonly objectCanonicalName: string = 'object';

  constructor(props: Props) {
    super(props);
    this.state = {
      onShow: false,
      variants: {},
      dropDownStyle: {},
    };
  }

  public static getDerivedStateFromProps(props: Props): Partial<State> {
    return {
      variants: ClassificationUtils.mapValueVariantTree(props.variants),
    };
  }

  public render(): React.ReactNode {
    const {
      index, variants, type, readableName, isError, uniclass, isMultilayer, canonicalName, mainLayer,
    } = this.props;
    const isLayer = index !== 0;
    const changeButtonEnabled =  !isLayer && variants && !!variants.length;
    const isObjectCanonicalName = canonicalName === this.objectCanonicalName;
    const elementIsError = changeButtonEnabled && isObjectCanonicalName;
    const elementTitle = isObjectCanonicalName ? 'Undefined' : readableName;
    const className = classNames(
      'classification-ontology-tree-element-body',
      {
        'classification-ontology-tree-element-body--error': elementIsError,
      });
    return (
      <div
        className={className}
      >
        {mainLayer
          ? (
            <div className='classification-ontology-tree-element-body__badge'>
              Main layer
              <TooltipContainer
                className='classification-ontology-tree-element-body__badge-tooltip'
              >
                Main Layer defines the UniSystem for the object.
              </TooltipContainer>
            </div>

          )
          : null
        }
        <div className='classification-ontology-tree-element-body__wrap-left'>
          <div className='classification-ontology-tree-element-body__element-type'>
            <ClassificationOntologyTreeElementType type={type}/>
          </div>
          <div className='classification-ontology-tree-element-body__content'>
            {this.getTypeDescription()}
            <div
              key='title'
              className='classification-ontology-tree-element-body__title'
              title={elementTitle}
            >
              {elementTitle}
            </div>
          </div>
          {changeButtonEnabled && this.props.isEdit &&
            <MultiLevelDropDownMenu
              compare={MultiLevelDropDownMenu.defaultSearchComparer}
              value={canonicalName}
              options={variants}
              onSelect={this.selectValue}
              optionContentRenderer={this.renderDropdownOption}
              className='classification-ontology-tree-element-body__change-wrap'
            >
              <KreoButton
                rounded={true}
                size='medium'
                className={classNames('classification-ontology-tree-element-body__change', {
                  'classification-ontology-tree-element-body__change--active': this.state.onShow,
                })}
              >
                Change
              </KreoButton>
            </MultiLevelDropDownMenu>
          }
        </div>
        <div className='classification-ontology-tree-element-body__wrap-right'>
          <ClassificationOntologyAppropriateUniclass
            isError={isError}
            uniclass={uniclass}
            isLayer={isLayer}
            isMultilayer={isMultilayer}
          />
        </div>
      </div>
    );
  }

  private getTypeDescription(): React.ReactNode {
    return (
      <div
        className='classification-ontology-tree-element-body__type'
      >
        {
          this.props.type === ClassificationObjectType.Object
            ? 'Object'
            : [
            `${this.props.type} `,
            <span key='1' className='classification-ontology-tree-element-body__elements-count'>
              {this.props.elementInfo}
            </span>,
            ]
        }
      </div>
    );
  }

  private renderDropdownOption(
    option: ClassificationOntologyVariantTree,
    query: PreparedSearchQuery,
  ): React.ReactNode {
    const name = MultiLevelDropDownMenu.defaultSearchQueryHighlighter(option.name, query);
    return <div className='classification-ontology-vertex__option-name' dangerouslySetInnerHTML={{ __html: name }}/>;
  }

  @autobind
  private selectValue(value: string): void {
    const currentValue = this.state.variants[value];
    this.props.onSelect(currentValue);
  }
}
