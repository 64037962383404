import { Icons } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';

import { TinyText } from 'common/components/text';
import { usePersistedStorageValueToggle } from 'persisted-storage/hooks';
import { DrawingsColorButton } from '../drawings-controls/color-control/drawings-color-button';
import { DrawingsBooleanOperation } from '../drawings-geometry/drawings-helpers';
import { Apply, DialogContainerStyled, Header, KeepOld } from './components';
import { useApplyOperation, useCancelBoolean, useColor } from './hooks';
import { Styled } from './styled';

interface Props {
  sendRef: (ref: HTMLDivElement) => void;
}


const SplitPolylineDialogComponent: React.FC<Props> = ({
  sendRef,
}) => {
  const [shouldKeepGroups, toggleKeepGroups] = usePersistedStorageValueToggle('drawingIsKeepGroup');
  const [ color, setColor ] = useColor();

  const applySettings = useMemo(() => ({
    keepGroups: shouldKeepGroups,
    color,
  }), [shouldKeepGroups, color]);

  const apply = useApplyOperation(DrawingsBooleanOperation.SplitPolyline, applySettings);
  const cancel = useCancelBoolean();

  return (
    <Styled.Container ref={sendRef}>
      <Styled.OpenPolygonDialog>
        <Header title='Split polyline into segments' Icon={Icons.PolylineToSegments}/>
        <Styled.OpenDialogBody>
          <KeepOld toggleKeepOld={toggleKeepGroups} isKeepOld={shouldKeepGroups}>
            <TinyText color={'gray'}>Save in the same group</TinyText>
          </KeepOld>
          <DialogContainerStyled.Switcher>
            <TinyText color={'gray'}>Colour</TinyText>
            <DrawingsColorButton
              color={color}
              changeColor={setColor}
              icon={color ? Icons.CircleFilled : Icons.Circle2D}
            />
          </DialogContainerStyled.Switcher>
        </Styled.OpenDialogBody>
        <Apply apply={apply} cancel={cancel}/>
      </Styled.OpenPolygonDialog>
    </Styled.Container>
  );
};


export const SplitPolylineDialog = React.memo(SplitPolylineDialogComponent);
