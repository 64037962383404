import * as React from 'react';

export const IconComment: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M5,13.6590869 L5,12.0222866 L4,12.0222866 C2.8954305,12.0222866 2,11.1268561 2,10.0222866 L2,
          5 C2,3.8954305 2.8954305,3 4,3 L12,3 C13.1045695,3 14,3.8954305 14,5 L14,10.0222866 C14,11.1268561 13.1045695,
          12.0222866 12,12.0222866 L9,12.0222866 L6.71485603,14.3583586 C6.32865896,14.7531627 5.69553242,
          14.76014 5.30072834,14.373943 C5.10840542,14.1858128 5,13.9281242 5,13.6590869 Z M6,13.6590869 L8.57931622,
          11.0222866 L12,11.0222866 C12.5522847,11.0222866 13,10.5745713 13,10.0222866 L13,5 C13,4.44771525 12.5522847,
          4 12,4 L4,4 C3.44771525,4 3,4.44771525 3,5 L3,10.0222866 C3,10.5745713 3.44771525,11.0222866 4,11.0222866 L6,
          11.0222866 L6,13.6590869 Z M5,7 L6,7 L6,8 L5,8 L5,7 Z M7,7 L8,7 L8,8 L7,8 L7,7 Z M9,7 L10,7 L10,8 L9,8 L9,7 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
