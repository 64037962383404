import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './resources-tab.scss';

import { State } from 'common/interfaces/state';
import { ChartDataProvider } from '../../../../utils/gantt-chart';
import { actions } from '../../actions';
import { datesFormatter } from '../../dates-formatter';
import { ResourcesTabMode, ResourcesTabModeKeys } from '../../enums/resources-tab-mode';
import { ChartsList } from '../charts-list';
import { LaboursTab } from '../labours-tab';
import { ModeSwitcher } from '../mode-switcher';

interface TabOwnProps {
  height: number;
  width: number;
  currentMoment: number;
  nextCurrentMoment: number | null;
  dataProvider: ChartDataProvider;
  timeframeStartDay: number;
  timeframeDuration: number;
}

interface TabStateProps {
  activeMode: ResourcesTabMode;
  projectStartDate: Date;
}

interface TabDispatchProps {
  setActiveMode: (mode: ResourcesTabMode) => void;
}

interface TabProps extends TabOwnProps, TabStateProps, TabDispatchProps { }

class ResourcesTabComponent extends React.Component<TabProps> {
  private readonly controlsLineHeight: number = 50;

  public render(): React.ReactNode {
    const contentHeight = this.props.height - this.controlsLineHeight;
    const timeframeSpan = datesFormatter.formatDateSpan(
      this.props.timeframeStartDay,
      this.props.timeframeStartDay + this.props.timeframeDuration,
      this.props.projectStartDate,
    );

    return (
      <div className='resources-tab'>
        <div className='resources-tab__controls-line'>
          <div className='resources-tab__dates'>
            {timeframeSpan.start} - {timeframeSpan.end}
          </div>
          <ModeSwitcher
            modes={ResourcesTabModeKeys}
            activeMode={ResourcesTabMode[this.props.activeMode]}
            setActiveMode={this.setActiveMode}
          />
        </div>
        {
          this.props.activeMode === ResourcesTabMode.All &&
          (
            <ChartsList
              height={contentHeight}
              width={this.props.width}
              currentMoment={this.props.currentMoment}
              nextCurrentMoment={this.props.nextCurrentMoment}
              dataProvider={this.props.dataProvider}
              timeframeStartDay={this.props.timeframeStartDay}
              timeframeDuration={this.props.timeframeDuration}
            />
          )
        }
        {
          this.props.activeMode === ResourcesTabMode.Labours &&
          (
            <LaboursTab
              height={contentHeight}
              width={this.props.width}
              currentMoment={this.props.currentMoment}
              nextCurrentMoment={this.props.nextCurrentMoment}
              dataProvider={this.props.dataProvider}
              timeframeStartDay={this.props.timeframeStartDay}
              timeframeDuration={this.props.timeframeDuration}
            />
          )
        }
      </div>
    );
  }

  @autobind
  private setActiveMode(modeKey: string): void {
    this.props.setActiveMode(ResourcesTabMode[modeKey]);
  }
}

const mapStateToProps = (state: State): TabStateProps => {
  return {
    activeMode: state.fourDVisualisation.sidePanel.resourcesTab.activeMode,
    projectStartDate: state.fourDVisualisation.projectStartDate,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): TabDispatchProps => {
  return {
    setActiveMode: (mode: ResourcesTabMode) => {
      dispatch(actions.setResourcesTabMode(mode));
    },
  };
};

export const ResourcesTab = connect(mapStateToProps, mapDispatchToProps)(ResourcesTabComponent);
