import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import { ConstantFunctions } from 'common/constants/functions';
import { isValidFormulaQuote } from '../../../units/2d/components/constants';
import { ExcelTableContext, UpdateCellData } from './interfaces';
import { ExcelFormulaHelper, RangeHelper } from './utils';

const BackspaceKey = 8;
const DeleteKey = 46;
const EnterKey = 13;

const LeftArrow = 'ArrowLeft';
const UpArrow = 'ArrowUp';
const RightArrow = 'ArrowRight';
const DownArrow = 'ArrowDown';
const Enter = 'Enter';

export interface ExcelTableCellEditParams extends Ag.ICellEditorParams {
  onInput: (value: string) => void;
  onSelect: () => void;
  onDelete: (updateCells: UpdateCellData[]) => void;
  context: ExcelTableContext;
  onEditCellStart: () => void;
  onEditCellEnd: () => void;
  cellEditorValueFormatter: (value: string) => string;
  showInvalidDialog: (callBack: () => void) => void;
}

export class ExcelTableCellEdit implements Ag.ICellEditorComp {
  private eGui: HTMLDivElement;
  private eInput: HTMLInputElement;
  private params: ExcelTableCellEditParams;
  private isArrowMove: boolean;
  private isDestroy: boolean = false;
  private isStopEditOnBlur: boolean = true;
  private mover: Record<string, () => void> = {
    [LeftArrow]: () => this.params.api.tabToPreviousCell(),
    [RightArrow]: () => this.params.api.tabToNextCell(),
    [DownArrow]: () => this.params.api.setFocusedCell(
      this.params.rowIndex + 1,
      this.params.column.getColId(),
    ),
    [UpArrow]: () => this.params.api.setFocusedCell(
      this.params.rowIndex - 1,
      this.params.column.getColId(),
    ),
  };

  public init(params: ExcelTableCellEditParams): void {
    this.params = params;

    this.eGui = document.createElement('div');
    this.eGui.className = 'excel-table-cell-edit';
    this.eInput = document.createElement('input');
    if (params.eGridCell.style.color) {
      this.eInput.style.color = params.eGridCell.style.color;
    }
    if (params.keyPress === BackspaceKey || params.keyPress === DeleteKey) {
      this.clearRange();
    } else {
      if (params.charPress) {
        this.eInput.value = params.charPress;
        this.params.onInput(params.charPress);
      } else {
        const value = this.params.data[this.params.colDef.field] || '';
        this.eInput.value = params.cellEditorValueFormatter(value);
      }
    }

    this.isArrowMove = params.charPress && params.keyPress !== EnterKey;

    this.eGui.append(this.eInput);
    setTimeout(() => this.eInput.focus(), 1);

    params.context.referenceReader.setConfig(this.setValue, this.isCanUpdate);

    if (this.params.onEditCellStart) {
      this.params.onEditCellStart();
    }
    this.initEvents();
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public destroy(): void {
    this.destroyEvents();
    this.isDestroy = true;
    if (this.params.onEditCellEnd) {
      this.params.onEditCellEnd();
    }
  }

  @autobind
  public getValue(): React.ReactText {
    const value = this.eInput.value;
    return ExcelFormulaHelper.isFormula(value)
      ? value.replace(/;/g, ',')
      : value;
  }

  public afterGuiAttached(): void {
    // this.eInput.setFocus();
  }

  @autobind
  private clearRange(): void {
    const updatedCells = RangeHelper.clearRange(this.params.api);

    this.eInput.value = '';

    this.params.onDelete(updatedCells);
    this.params.stopEditing(true);
    this.params.api.refreshCells({ force: true });
  }

  private destroyEvents(): void {
    if (this.isArrowMove) {
      this.eInput.removeEventListener('keydown', this.handleArrowMove);
    }
    this.eGui.removeEventListener('input', this.onInput);
    this.eInput.removeEventListener('blur', this.blur);
    this.eInput.removeEventListener('keydown', this.handleEnter);
  }

  private initEvents(): void {
    if (this.isArrowMove) {
      this.eInput.addEventListener('keydown', this.handleArrowMove);
    }
    this.eGui.addEventListener('input', this.onInput);
    this.eInput.addEventListener('blur', this.blur);
    this.eInput.addEventListener('keydown', this.handleEnter);
  }

  @autobind
  private onInput(): void {
    const value = this.eInput.value;
    if (this.params.onInput) {
      this.params.onInput(value);
    }
  }

  @autobind
  private setValue(value: string): void {
    const valueToInsert = ExcelFormulaHelper.getValueToInsert(this.eInput.value, value);
    this.eInput.value = this.params.cellEditorValueFormatter(valueToInsert);
    this.onInput();
  }

  @autobind
  private isCanUpdate(): boolean {
    return !this.isDestroy && (!this.eInput.value || ExcelFormulaHelper.isFormula(this.eInput.value));
  }

  @autobind
  private handleEnter(e: KeyboardEvent): void {
    if (e.key !== Enter) {
      return;
    }

    if (this.isFormulaValid()) {
      this.handleInvalidFormula(e);
    }
  }

  @autobind
  private handleArrowMove(e: KeyboardEvent): void {
    if (e.key === LeftArrow
      || e.key === RightArrow
      || e.key === DownArrow
      || e.key === UpArrow) {
      this.stopEdit(e);
    }
  }

  @autobind
  private stopEdit(e: KeyboardEvent): void {
    if (this.isFormulaValid()) {
      this.handleInvalidFormula(e);
    } else {
      this.params.stopEditing(true);
      this.move(e.key);
    }
  }

  @autobind
  private handleInvalidFormula(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    this.isStopEditOnBlur = false;
    this.params.showInvalidDialog(() => this.eInput.focus());
  }

  @autobind
  private isFormulaValid(): boolean {
    return ExcelFormulaHelper.isFormula(this.eInput.value) && !isValidFormulaQuote(this.eInput.value);
  }

  @autobind
  private move(key: string): void {
    this.mover[key]();
  }

  @autobind
  private blur(): void {
    if (this.isStopEditOnBlur) {
      this.params.stopEditing(true);
    }
  }
}
