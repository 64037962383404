import { ModuleStatus, ScenarioModule, ScenarioState } from '../interfaces/scenario-state';

export class ScenarioStateHelper {
  public static shouldLoadModel(
    currentState: ScenarioState, previousState: ScenarioState, moduleName: ScenarioModule,
  ): boolean {
    return (
      currentState && currentState.moduleStatuses[moduleName] === ModuleStatus.Ready
    ) && (
      !previousState || previousState.moduleStatuses[moduleName] !== ModuleStatus.Ready
    );
  }
}
