import { xor } from 'lodash';

import { EMPTY_VALUE } from 'common/components/tree-filter-panel/constants';

const swap = <U>(items: U[], firstIndex: number, lastIndex: number): void => {
  [items[firstIndex], items[lastIndex]] = [items[lastIndex], items[firstIndex]];
};

const defaultValueGetterCallback = <T>(item: T): T => item;

const sortByFieldPartition = <T, K>(
  tree: T[],
  left: number,
  right: number,
  valueGetterCallback?: (item: T) => K,
): number => {
  const pivot = valueGetterCallback(tree[Math.floor((right + left) / 2)]);
  let i = left;
  let j = right;
  while (i <= j) {
    while (valueGetterCallback(tree[i]) < pivot) {
      i++;
    }
    while (valueGetterCallback(tree[j]) > pivot) {
      j--;
    }
    if (i <= j) {
      swap(tree, i, j);
      i++;
      j--;
    }
  }
  return i;
};

function sort<T, K>(
  items: T[],
  left: number = 0,
  right?: number,
  valueGetterCallback: ((item: T) => K | T) = defaultValueGetterCallback,
): T[] {
  if (items.length > 1) {
    right = right || items.length;
    const index = sortByFieldPartition(items, left, right, valueGetterCallback);
    if (left < index - 1) {
      sort(items, left, index - 1, valueGetterCallback);
    }
    if (index < right) {
      sort(items, index, right, valueGetterCallback);
    }
  }
  return items;
}

const sortByField = <U, K extends keyof U>(
  items: U[],
  left: number,
  right: number,
  field: K,
): U[] => {
  if (items.length > 1) {
    return sort(items, left, right, (item) => item[field]);
  }
  return items;
};

function extendArray<T>(sourceArray: T[], arrayToExtendWith: Iterable<T>): void {
  for (const item of arrayToExtendWith) {
    sourceArray.push(item);
  }
}

function findInsertPoint<T>(
  /**
   * @description Should be ordered in ASC direction according to comparator supplied
   */
  orderedArray: T[],
  comparator: (t1: T, t2: T) => number,
  itemToInsert: T,
): number {
  if (!orderedArray.length) {
    return 0;
  }

  let i = 0;
  while (orderedArray[i] && comparator(itemToInsert, orderedArray[i]) > 0) {
    i++;
  }

  return i;
}

function areSetsEqual<T>(set1: T[], set2: T[]): boolean {
  return !xor(set1, set2).length;
}

const localCompareWithNumber = (a: string, b: string): number =>
  a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });

function uniq<T>(array: T[]): T[] {
  return [...new Set(array)];
}

function* filterIterator<T>(array: Iterable<T>, filter: (value: T) => boolean): IterableIterator<T> {
  for (const item of array) {
    if (filter(item)) {
      yield item;
    }
  }
}

function* mapIterator<T, K>(array: Iterable<T>, map: (value: T) => K): IterableIterator<K> {
  for (const item of array) {
    yield map(item);
  }
}
function *filterMapIterator<T, K>(
  array: Iterable<T>,
  filter: (value: T) => boolean,
  map: (value: T) => K,
): IterableIterator<K> {
  for (const item of filterIterator(array, filter)) {
    yield map(item);
  }
}

function filterMap<T, K>(array: Iterable<T>, filter: (value: T) => boolean, map: (value: T, index: number) => K): K[] {
  const result = [];
  for (const item of filterIterator(array, filter)) {
    const mappedItem = map(item, result.length);
    result.push(mappedItem);
  }
  return result;
}

function *flatArrayIterator<T>(array: Iterable<T[]>): IterableIterator<T> {
  for (const item of array) {
    for (const subItem of item) {
      yield subItem;
    }
  }
}

function flatArray<T>(array: Iterable<T[]>): T[] {
  const result = [];
  for  (const item of array) {
    extendArray(result, item);
  }
  return result;
}


function uniqWith<T>(array: T[], getHashKey: (item: T) => string | number): T[] {
  const existedHashes = {};
  const result = [];
  for (const item of array) {
    const key = getHashKey(item);
    if (!existedHashes[key]) {
      existedHashes[key] = true;
      result.push(item);
    }
  }
  return result;
}

function toDictionary<T, K extends number | string | symbol, V>(
  array: Iterable<T>,
  keyGetter: (item: T) => K,
  valueGetter: (item: T) => V,
): Record<K, V> {
  const result: Record<K, V> = {} as any;
  for (const item of array) {
    result[keyGetter(item)] = valueGetter(item);
  }
  return result;
}

function toDictionaryByKey<T, K extends number | string | symbol>(
  array: Iterable<T>,
  keyGetter: (item: T) => K,
): Record<K, T> {
  return toDictionary(array, keyGetter, i => i);
}

function groupBy<T, K extends number | string | symbol>(
  array: Iterable<T>,
  keyGetter: (item: T) => K,
): Record<K, T[]> {
  const result: Record<K,  T[]> = {} as any;
  for (const item of array) {
    const key = keyGetter(item);
    result[key] = result[key] || [];
    result[key].push(item);
  }
  return result;
}

function extendGroupBy<T, K extends number | string | symbol>(
  groupToExtend: Record<K, T[]>,
  array: Iterable<T>,
  keyGetter: (item: T) => K,
): void {
  for (const item of array) {
    const key = keyGetter(item);
    groupToExtend[key] = groupToExtend[key] || [];
    groupToExtend[key].push(item);
  }
}

function groupByWithMap<T, K extends number | string | symbol, R>(
  array: Iterable<T>,
  keyGetter: (item: T) => K,
  mapValue: (item: T) => R,
): Record<K, R[]> {
  const result: Record<K, R[]> = {} as any;
  for (const item of array) {
    const key = keyGetter(item);
    result[key] = result[key] || [];
    result[key].push(mapValue(item));
  }
  return result;
}

function getMinIndex<T>(array: T[], valueGetter: (item: T) => number): number {
  let minIndex = 0;
  for (let i = 1; i < array.length; i++) {
    if (valueGetter(array[i]) < valueGetter(array[minIndex])) {
      minIndex = i;
    }
  }
  return minIndex;
}

function getMinByField<T>(arr: T[], fieldGetter: (item: T) => number | string): T {
  if (!arr.length) {
    return null;
  }
  let minItem = arr[0];
  let minValue = fieldGetter(arr[0]);
  for (let i = 1; i < arr.length; i++) {
    const item = arr[i];
    const value = fieldGetter(item);
    if (value < minValue) {
      minItem = item;
      minValue = value;
    }
  }
  return minItem;
}

function symDiff<T extends number | string>(arr1: T[], ...arrays: T[][]): T[] {
  const mainDict = toDictionary(arr1, x => x, () => true);
  for (const array of arrays) {
    for (const v of array) {
      if (mainDict[v]) {
        delete mainDict[v];
      } else {
        mainDict[v] = true;
      }
    }
  }
  return Object.keys(mainDict) as T[];
}

const sortStringArrayWithEmptyValue = (a: string, b: string): number => {
  if (a.toLowerCase() === EMPTY_VALUE) {
    return 1;
  }

  if (b.toLowerCase() === EMPTY_VALUE) {
    return -1;
  }
  return localCompareWithNumber(a, b);
};

const setKeyArrayValue = <T>(source: Record<string, T[]>, key: string, value: T): void => {
  if (!source[key]) {
    source[key] = [value];
  } else {
    source[key].push(value);
  }
};


function splitByCondition<T>(source: Iterable<T>, filter: (item: T) => boolean): [T[], T[]] {
  const truePart = [];
  const falsePart = [];
  for (const item of source) {
    if (filter(item)) {
      truePart.push(item);
    } else {
      falsePart.push(item);
    }
  }
  return [truePart, falsePart];
}

function getAndRemoveItem<T>(source: Iterable<T>, filter: (item: T) => boolean): { item: T, array: T[] } {
  let item: T = null;
  const array = [];
  for (const i of source) {
    if (filter(i)) {
      item = i;
    } else {
      array.push(i);
    }
  }
  return { item, array };
}

export const arrayUtils = {
  shallowEqualByValues: (first: any[], second: any[]): boolean => {
    if (first === null && second === null) {
      return true;
    }

    if (first === null || second === null) {
      return false;
    }

    if (first.length !== second.length) {
      return false;
    }

    const count = first.length;
    for (let i = 0; i < count; i++) {
      if (first[i] !== second[i]) {
        return false;
      }
    }

    return true;
  },
  symDiff,
  sortByField,
  extendArray,
  findInsertPoint,
  areSetsEqual,
  localCompareWithNumber,
  sort,
  uniq,
  uniqWith,
  filterMap,
  toDictionary,
  toDictionaryByKey,
  filterMapIterator,
  groupBy,
  groupByWithMap,
  extendGroupBy,
  sortStringArrayWithEmptyValue,
  flatArray,
  flatArrayIterator,
  setKeyArrayValue,
  filterIterator,
  mapIterator,
  getMinByField,
  splitByCondition,
  getMinIndex,
  getAndRemoveItem,
};
