import { objectUtils } from 'common/utils/object-utils';
import { CompanyResourcesApi } from '../../../api/server';
import {
  ModelType,
  QtoReportForm,
  QtoReportInfo,
  QtoReportInfoC,
  QtoTemplate,
  QtoTemplateC,
  QtoTemplateColumnsForm,
  QtoTemplateInfo,
  QtoTemplateInfoArrayC,
  QtoTemplateInfoC,
  QtoTemplateInfoForm,
  QtoTemplateRowForm,
} from '../interfaces/quantity-take-off';


function createTemplate(model: QtoReportForm, modelType: ModelType): Promise<QtoTemplateInfo> {
  return CompanyResourcesApi.postV(`/${modelType}/templates`, model, QtoTemplateInfoC);
}

function getTemplates(modelType: ModelType): Promise<QtoTemplateInfo[]> {
  return CompanyResourcesApi.getV(`/${modelType}/templates`, QtoTemplateInfoArrayC);
}

function updateTemplateInfo(id: number, modelType: ModelType, model: QtoTemplateInfoForm): Promise<QtoTemplateInfo> {
  return CompanyResourcesApi.putV(`/${modelType}/templates/${id}`, model, QtoTemplateInfoC);
}

function deleteTemplate(id: number, modelType: ModelType): Promise<void> {
  return CompanyResourcesApi.delete(`/${modelType}/templates/${id}`, null);
}

function loadTemplate(id: number, modelType: ModelType): Promise<QtoTemplate> {
  return CompanyResourcesApi.getV(`/${modelType}/templates/${id}`, QtoTemplateC);
}

function updateBasicColumns(templateId: number, modelType: ModelType, form: QtoTemplateColumnsForm): Promise<void> {
  return CompanyResourcesApi.put(`/${modelType}/templates/${templateId}/columns`, form);
}

function updateRows(templateId: number, modelType: ModelType, form: QtoTemplateRowForm[]): Promise<void> {
  return CompanyResourcesApi.put(`/${modelType}/templates/${templateId}/rows`, form);
}

function addRows(templateId: number, modelType: ModelType, form: QtoTemplateRowForm): Promise<void> {
  return CompanyResourcesApi.post(`/${modelType}/templates/${templateId}/rows`, form);
}

function removeRows(templateId: number, modelType: ModelType, ids: string[]): Promise<void> {
  const idsParams = objectUtils.toQueryString({ id: ids });
  return CompanyResourcesApi.delete(`/${modelType}/templates/${templateId}/rows?${idsParams}`, {});
}

function reorderRows(templateId: number, modelType: ModelType, form: QtoTemplateRowForm): Promise<void> {
  return CompanyResourcesApi.put(`/${modelType}/templates/${templateId}/reorder`, form);
}

function createReportFromTemplate(projectId: number, templateId: number, modelType: ModelType): Promise<QtoReportInfo> {
  return CompanyResourcesApi.postV(
    `/projects/${projectId}/${modelType}/templates/${templateId}/reports`,
    null,
    QtoReportInfoC,
  );
}

export const QuantityTakeOffTemplateApi = {
  reorderRows,
  removeRows,
  addRows,
  updateRows,
  updateBasicColumns,
  createTemplate,
  getTemplates,
  updateTemplateInfo,
  deleteTemplate,
  loadTemplate,
  createReportFromTemplate,
};
