import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { MeasurementsEngineLayout } from '../../components/measurements-engine-layout';
import { MeasurementsExtractorLayout } from '../../components/measurements-extractor-layout';

export class MeasurementsViewPage extends React.PureComponent<RouteComponentProps<PlanProjectRouteParams>> {
  public render(): React.ReactNode {
    return (
      <MeasurementsEngineLayout projectId={this.props.match.params.projectId}>
        <MeasurementsExtractorLayout disabled={true} />
      </MeasurementsEngineLayout>
    );
  }
}
