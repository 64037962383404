import { AnyAction } from 'redux';

import {  RequestStatus } from 'common/enums/request-status';
import { AccountActionTypes } from '../units/account/actions/types';
import { ScenariosActionTypes } from '../units/scenarios/actions';
import { ScenariosReduxState } from '../units/scenarios/interfaces';
import { ScenariosReducerMethods } from '../units/scenarios/reducers';

const INITIAL_STATE: ScenariosReduxState = {
  active_calculation: null,
  active_scenario: null,
  scenarios: [],
  indirectCost: [],
  resourceLimitations: [],
  totalLimitation: {
    calendarIds: 0,
    useAmdahl: false,
    useAmdahlIndeterminate: false,
    crewProductivity: 0,
  },
  statuses: {
    scenarios: RequestStatus.Loaded,
    calculations: [],
    indirectCost: RequestStatus.Loaded,
    resourceLimitations: RequestStatus.Loaded,
    savePackages: RequestStatus.Loaded,
    activeScenario: RequestStatus.Loaded,
  },
  scenariosToCopy: [],
  editScenario: null,
};

export default function(
  state: ScenariosReduxState = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): ScenariosReduxState {
  if (action.type in ScenariosReducerMethods) {
    return ScenariosReducerMethods[action.type](state, action.payload);
  } else {
    if (
      action.type === AccountActionTypes.LOG_OUT ||
      action.type === ScenariosActionTypes.DROP_INFO
    ) {
      return INITIAL_STATE;
    }
    return state;
  }
}
