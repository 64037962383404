import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import {
  EstimateSubscriptionModel,
} from '../../../../../../units/subscription/interfaces/estimation-subscription-model';
import { Styled } from './styled';
import { TableCell, TableFooter, TableHeader } from './table-cell';


interface StateProps {
  billingEstimation: EstimateSubscriptionModel;
}

interface Props extends StateProps { }


const  PaymentMoreDetailsComponent: React.FC<Props> = ({ billingEstimation }: Props) => {
  const invoice = billingEstimation?.nextInvoice ? billingEstimation.nextInvoice : billingEstimation.immediateInvoice;

  return (
    <Styled.Container>
      <TableHeader />
      {invoice?.items.map((item, index) => {
        return (
          <TableCell
            key={index}
            item={item.description}
            unit={item.quantity}
            unitPrice={item.amount / item.quantity / 100}
            amount={item.amount / 100}
          />
        );
      })}
      <TableFooter
        total={invoice?.total / 100}
        discounts={invoice?.discounts}
        taxes={invoice?.taxes}
      />
    </Styled.Container>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    billingEstimation: state.account.billingEstimation,
  };
}

const connector = connect(mapStateToProps);
export const PaymentMoreDetails = connector(PaymentMoreDetailsComponent);
