import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { Notification, NotificationProcessor } from 'common/interfaces/realtime-messages';
import { AppUrls } from '../../../../routes/app-urls';
import { CommonNotificationProcessor } from '../../../../units/notifications/common-notification-processor';
import { ActivityAssignmentActions } from '../../actions/creators/activity-assignment';
import * as ModelCheckActions from '../../pages/model-check-view/actions';
import { ProjectSelectors } from '../../selectors';
import { ModelCheckNotificationKeys } from '../keys/model-check';

function userIsNotOnPage(projectId: number): boolean {
  const stepUrl = AppUrls.plan.project.modelCheck.index.url({ projectId: projectId.toString() });
  return !window.location.pathname.startsWith(stepUrl);
}


function* processFailureMessage(notification: Notification): SagaIterator {
  try {
    yield put(ActivityAssignmentActions.dropState());
    yield call(CommonNotificationProcessor.addErrorAlert, notification);
  } catch (error) {
    console.error(error);
  }
}

function* modelCheckPassed(notification: Notification): SagaIterator {
  try {
    if (yield select(ProjectSelectors.projectIsCurrent, notification.data.documentId)) {
      yield put(ModelCheckActions.getData());
    }

    if (notification.isAlert
      && userIsNotOnPage(notification.data.documentId)) {
      yield call(CommonNotificationProcessor.addInfoAlert, notification);
    }
  } catch (error) {
    console.error(error);
  }
}


export const ModelCheckNotificationsProcessor: NotificationProcessor = {
  [ModelCheckNotificationKeys.ModelCheckPassed]: modelCheckPassed,
  [ModelCheckNotificationKeys.ModelCheckFailed]: processFailureMessage,
};
