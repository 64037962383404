import React from 'react';
import { useSelector } from 'react-redux';

import { useWizzardSetting } from 'common/components/drawings/hooks';
import { State } from 'common/interfaces/state';
import { useRendererApiContext } from '../../../drawings-contexts';
import { ResultsMenu } from '../results-menu';

const DropperResultMenuComponent: React.FC = () => {
  const { rendererApi } = useRendererApiContext();
  const foundedCount = useSelector<State, number>(x => {
    const { result, dropperSimilarity: similarity, dropperSameGeometry } = x.drawings.wizzardTools;
    return dropperSameGeometry
      ? (result as Array<{ similarity: number }>)?.filter(r => r.similarity >= similarity).length
      : result?.length;
  });
  const [ sameGeometry ] = useWizzardSetting('dropperSameGeometry');
  const [ similarity, onChangeSimilarity ] = useWizzardSetting('dropperSimilarity');
  return (
    <ResultsMenu
      apply={rendererApi?.engine?.applyDropperResult}
      count={foundedCount}
      similarity={similarity}
      onSimilarityChange={onChangeSimilarity}
      withSimilaritySlider={sameGeometry}
      minSimilarity={0}
      maxSimilarity={100}
    />
  );
};

export const DropperResultMenu = React.memo(DropperResultMenuComponent);
