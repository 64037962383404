import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PickOnlyFieldsOfType } from 'common/interfaces/omiters';
import { State } from 'common/interfaces/state';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { THREE_D_SETTINGS_INITIAL_STATE } from 'persisted-storage/constants/initial-state';
import { ThreeDSettings } from 'persisted-storage/interfaces/state';

export function useThreeDSetting<
  T extends keyof ThreeDSettings
>(settingKey: T): ThreeDSettings[T] {
  const value = useSelector<State, ThreeDSettings[T]>(x => {
    if (x.persistedStorage.drawingThreeDSettings) {
      return x.persistedStorage.drawingThreeDSettings[settingKey] ?? THREE_D_SETTINGS_INITIAL_STATE[settingKey];
    } else {
      return THREE_D_SETTINGS_INITIAL_STATE[settingKey];
    }
  });
  return value;
}

export function useThreeDSettingToggle<
  T extends keyof PickOnlyFieldsOfType<ThreeDSettings, boolean>
>(settingKey: T): () => void {
  const dispatch = useDispatch();
  const toggle = useCallback(() => {
    dispatch(PersistedStorageActions.toggleDrawingThreeDSetting(settingKey));
  }, [dispatch, settingKey]);
  return toggle;
}

export function useThreeDSettingWithToggle<
  T extends keyof PickOnlyFieldsOfType<ThreeDSettings, boolean>
>(settingKey: T): [boolean, () => void] {
  const value = useThreeDSetting(settingKey);
  const toggle = useThreeDSettingToggle(settingKey);
  return [value as boolean, toggle];
}

export function useThreeDSettingSetter<
  T extends keyof ThreeDSettings
>(settingKey: T): (value: ThreeDSettings[T]) => void {
  const dispatch = useDispatch();
  return useCallback((value: ThreeDSettings[T]) => {
    dispatch(PersistedStorageActions.setDrawingThreeDSetting(settingKey, value));
  }, [dispatch, settingKey]);
}

export function useThreeDSettingUpdate<
  T extends keyof ThreeDSettings
>(settingKey: T): [ThreeDSettings[T], (value: ThreeDSettings[T]) => void] {
  const value = useThreeDSetting(settingKey);
  const updateSetting = useThreeDSettingSetter(settingKey);
  return [value, updateSetting];
}


export function useThreeDSettings(): ThreeDSettings {
  const isCameraOrthographic = useThreeDSetting('isCameraOrthographic');
  const fillOpacity = useThreeDSetting('fillOpacity');
  const showDrawing = useThreeDSetting('showDrawing');
  const drawingOpacity = useThreeDSetting('drawingOpacity');
  const drawingOffset = useThreeDSetting('drawingOffset');
  return useMemo(() => ({
    isCameraOrthographic,
    fillOpacity,
    showDrawing,
    drawingOpacity,
    drawingOffset,
  }), [isCameraOrthographic, fillOpacity, showDrawing, drawingOpacity, drawingOffset]);
}

interface ThreeDSettingsUpdates {
  toggleIsOrtho: () => void;
  setFillOpacity: (value: number) => void;
  toggleShowDrawing: () => void;
  setDrawingOpacity: (value: number) => void;
  setDrawingOffset: (value: number) => void;
}

export function useThreeDSettingsUpdates(): ThreeDSettingsUpdates {
  const toggleIsOrtho = useThreeDSettingToggle('isCameraOrthographic');
  const setFillOpacity = useThreeDSettingSetter('fillOpacity');
  const toggleShowDrawing = useThreeDSettingToggle('showDrawing');
  const setDrawingOpacity = useThreeDSettingSetter('drawingOpacity');
  const setDrawingOffset = useThreeDSettingSetter('drawingOffset');
  return {
    toggleIsOrtho,
    setFillOpacity,
    toggleShowDrawing,
    setDrawingOpacity,
    setDrawingOffset,
  };
}

