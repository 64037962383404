import { Text, InputSelectStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { MenuGroupContainerStyled } from 'common/components/menu-group-container';
import { OptionContainerStyled } from 'common/components/selection-menus';
import { SelectFilterPagesStyled } from '../header/select-filter-pages';


const Container = styled.div`
  width: 240px;
  *, & {
    box-sizing: border-box;
  }
  ${SelectFilterPagesStyled.SelectFilterContainer} {
    margin-bottom: 0px;
    border-bottom: 0px;
  }
  ${MenuGroupContainerStyled.Container} {
    margin: 0px;
    padding: 0px;
  }
  ${OptionContainerStyled.Container} {
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      line-height: 24px;
      font-size: 14px;
    }
    svg {
      height: 20px;
    }
    > svg {
      fill: ${p => p.theme.color.turquoise};
    }
  }
`;

const AccordionContainer = styled.div`
  border-bottom: 1px solid ${p => p.theme.color.background};
`;

const ResetButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px 10px 15px;
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

const Numeral = styled.div`
  background: ${p => p.theme.color.turquoise};
  border-radius: 5px;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  padding: 0 5px;
`;

const ResetButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 20px;
  padding: 0 15px;
`;

const Set = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${p => p.theme.color.backgroundRush};

  ${InputSelectStyled.DropDownMenuContainer} {
    position: fixed;
    top: initial;
    right: initial;
    max-width: 180px;
    width: 100%;
  }
`;

const ColorSet = styled(Set)`
  > div {
    padding: 0;

    > div {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
`;

const SetElementIconWithText = styled.div`
  display: inline-flex;
  align-items: center;
  svg {
    fill: ${p => p.theme.color.gray};
    margin-right: 10px;
  }
  > p {
    font-size: 14px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  > div {
    width: 100%;
  }

  ${Text} {
    text-transform: capitalize;
  }
`;

export const Styled = {
  Container,
  AccordionContainer,
  ResetButtonContainer,
  Count,
  Numeral,
  ResetButton,
  Set,
  SetElementIconWithText,
  ColorSet,
  SelectContainer,
};
