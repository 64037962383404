
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { MaterialComponentProps } from './interfaces';
import { MaterialInput } from './material-input';

export interface MaterialNumberInputProps extends MaterialComponentProps<React.ReactText> {
  value?: number | null;
  defaultValue?: number;
  valueType?: 'float' | 'integer';
  precision?: number;
  required?: boolean;
  positive?: boolean;
  min?: React.ReactText;
  max?: React.ReactText;
  maxLength?: number;
  autoFocus?: boolean;
  inputComponent?: React.ReactType<InputBaseComponentProps>;
  displayCharactersLeft?: boolean;
  isBlocked?: boolean;
  showClearButton?: boolean;
  searchType?: boolean;
  textAlignRight?: boolean;
  sizeSmall?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onFocus?: (event: React.FocusEvent, newValue?: number | null) => void;
  onChange?: (event: React.ChangeEvent, newValue?: number | null) => void;
  onBlur?: (event: React.FocusEvent, newValue?: number | null) => void;
  onKeyPress?: (event: React.KeyboardEvent<{}>) => void;
}

interface State {
  isFocused: boolean;
  value: string;
}

export class MaterialNumberInput extends React.Component<MaterialNumberInputProps, State> {
  constructor(props: MaterialNumberInputProps) {
    super(props);
    this.state = {
      isFocused: false,
      value: this.getPreciseStringValue(props.value),
    };
  }

  // TODO: consider replacing with getDerivedStateFromProps
  public componentDidUpdate(prevProps: MaterialNumberInputProps): void {
    if (
      this.props.value !== prevProps.value &&
      this.getPreciseStringValue(this.props.value) !== this.getPreciseStringValue(+this.state.value)
    ) {
      this.setState({
        value: this.getPreciseStringValue(this.props.value),
      });
    }
  }

  public render(): JSX.Element {
    return (
      <MaterialInput
        {...this.props}
        value={this.state.value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        isCleanZero={false}
      />
    );
  }

  @autobind
  private onChange(event: React.ChangeEvent, newValue: string): void {
    const newNumberValue = this.updateStateAndGetNewNumberValue(newValue);
    if (this.props.onChange) {
      this.props.onChange(event, newNumberValue);
    }
  }

  @autobind
  private onFocus(event: React.FocusEvent, value: string): void {
    this.setState({ isFocused: true });
    const newNumberValue = this.updateStateAndGetNewNumberValue(value);
    if (this.props.onFocus) {
      this.props.onFocus(event, newNumberValue);
    }
  }

  @autobind
  private onBlur(event: React.FocusEvent, newValue: string): void {
    const value = newValue === '' && (this.props.defaultValue || this.props.defaultValue === 0)
      ? this.getPreciseStringValue(this.props.defaultValue)
      : newValue;

    this.setState({ isFocused: false });
    const newNumberValue = this.updateStateAndGetNewNumberValue(value);
    if (this.props.onBlur) {
      this.props.onBlur(event, newNumberValue);
    }
  }

  @autobind
  private updateStateAndGetNewNumberValue(newValue: string): number | null {
    const number = parseFloat(newValue);
    const newNumberValue = Number.isNaN(number) ? null : number;
    this.setState({
      value: newValue,
    });

    return newNumberValue;
  }

  private getPreciseStringValue(value: number): string {
    return value || value === 0
      ? this.props.precision && (!this.state || !this.state.isFocused)
        ? value.toFixed(this.props.precision)
        : value.toString()
      : '';
  }
}

export const MaterialNumberInputField: React.FC<MaterialNumberInputProps> = (
  props: WrappedFieldProps & MaterialNumberInputProps,
): JSX.Element => {
  const {
    input,
    meta: { submitFailed, error },
    ...rest } = props;

  const onChange = (_event: React.ChangeEvent<{}>, value: number): void => {
    input.onChange(value);
  };

  const onBlur = (_event: React.ChangeEvent<{}>, value: number): void => {
    input.onBlur(value);
  };

  const onFocus = (event: React.ChangeEvent<{}>): void => {
    input.onFocus(event as any);
  };

  return (
    <MaterialNumberInput
      {...input}
      error={submitFailed && error}
      onChange={onChange} // eslint-disable-line react/jsx-no-bind
      onBlur={onBlur} // eslint-disable-line react/jsx-no-bind
      onFocus={onFocus} // eslint-disable-line react/jsx-no-bind
      {...rest}
    />
  );
};
