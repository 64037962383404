import * as React from 'react';

export const KreoIconDoubleRows: React.FC = () => {
  return (
    <svg
      width='8px'
      height='6px'
      viewBox='0 0 8 6'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Desktop' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GenC_SharedProject_Schedule_AllPeriod' transform='translate(-163.000000, -87.000000)'>
          <g id='Content' transform='translate(100.000000, 60.000000)'>
            <g id='ControlPanel'>
              <g id='Exp-Col-All' transform='translate(60.000000, 23.000000)'>
                <g id='icon_collapse-all'>
                  <g id='icon'>
                    <rect id='Rectangle-7' x='0' y='0' width='14' height='14'/>
                    <g
                      id='Group-5'
                      transform='translate(4.000000, 4.000000)'
                      strokeLinecap='round'
                      stroke='currentColor'
                    >
                      <polyline id='Rectangle-5' points='6 3 3 6 0 3'/>
                      <polyline id='Rectangle-5' points='6 0 3 3 0 0'/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
