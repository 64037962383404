import autobind from 'autobind-decorator';
import * as React from 'react';

import './database-table.scss';

import { FilterPanel } from 'common/components/filter-panel';
import { FilterValue } from 'common/components/filter-select/interfaces';
import { TableHeaderColumn } from 'common/components/table-header-column';
import { RequestStatus } from 'common/enums/request-status';
import { KreoButton, KreoIconDbCreate } from 'common/UIKit';
import { LazyLoadingVirtualList } from 'common/UIKit/lazy-loading-virtual-list';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { SearchInput } from '../../../../components/controls/inputs';
import { ResourceType } from '../../enums';
import { DatabaseTableRootModel } from '../../interfaces/data';
import {
  DatabaseTableElementSize,
  DatabaseTableRoot,
} from './database-table-root-item';
import { DatabaseTableColumn, DatabaseTableRootMenuItem, DatabaseTableVariantMenuItem } from './interfaces';


interface Props {
  entityName: string;
  title: string;
  loadStatus: RequestStatus;
  items: DatabaseTableRootModel[];
  columns: Array<DatabaseTableColumn<any>>;
  variantMenuItems?: DatabaseTableVariantMenuItem[];
  getVariantMenuItems?: (root: DatabaseTableRootModel) => DatabaseTableVariantMenuItem[];
  rootMenuItems?: DatabaseTableRootMenuItem[];
  loadItems: (skip: number, take: number, search: string, filters?: Record<string, string[]>) => void;
  openEditRoot: (rootId: number) => void;
  openEditVariant: (rootId: number, variantId: number) => void;

  disabledRootIds: number[];
  selectedRootIds: number[];
  disabledVariantIds: number[];
  selectedVariantIds: number[];

  openCreateRoot?: () => void;
  openCreateVariant?: (rootId: number) => void;
  selectVariant?: (rootId: number, variantId: number, value: boolean) => void;
  selectRoot?: (rootId: number, value: boolean) => void;

  totalItemsCount: number;
  batch: number;
  bufferItemCounts: number;
}

interface State {
  searchQuery: string;
  filters: Record<string, FilterValue[]>;
}

export class DatabaseTable extends React.Component<Props, State> {
  private searchDeferredExecutor: DeferredExecutor = new DeferredExecutor(300);
  constructor(props: Props) {
    super(props);

    this.state = {
      searchQuery: '',
      filters: {},
    };
  }

  public render(): JSX.Element {
    const { items, columns } = this.props;

    return (
      <div className={'database-table-block'}>
        <div className={'database-table-block__header'}>
          <b className={`database-table-block__header-title`}>
            {this.props.title}{this.props.title === ResourceType.Material && 's'}
          </b>
          <div className={'database-table-block__header-actions'}>
            <SearchInput value={this.state.searchQuery} onSearch={this.search} />
            {this.props.openCreateRoot ? (
              <KreoButton
                onClick={this.props.openCreateRoot}
                mode='submit'
                size='medium'
              >
                Create {this.props.entityName}
              </KreoButton>
            ) : null}
          </div>
        </div>
        <div className={'database-table-block__list'}>
          <div className={'database-table-block__list-header'}>
            {this.getHeaderColumns(columns)}
          </div>
          <FilterPanel
            columnNames={columns.map(x => x.name)}
            filters={this.state.filters}
            filterValueChanged={this.onFilterValueChange}
          />
          <div className={'database-table-block__list-container'}>
            {this.props.loadStatus === RequestStatus.Loaded && !items.length ? (
              <div className={'database-table-block__list-empty'}>
                <div className={'database-table-block__list-empty-icon'}>
                  <KreoIconDbCreate />
                  No {this.props.entityName}
                </div>
              </div>
            ) : null}
            <LazyLoadingVirtualList
              objects={items}
              showShadowTop={true}
              itemHeight={DatabaseTableElementSize.rootDefaultHeight}
              renderedItemsCount={16}
              renderItem={this.renderRoot}
              customItemHeight={this.getItemSize}
              total={this.props.totalItemsCount}
              batch={this.props.batch}
              load={this.lazyLoad}
              bufferCount={this.props.bufferItemCounts}
              loadStatus={this.props.loadStatus}
            />
          </div>
        </div>
      </div>
    );
  }

  private getHeaderColumns(columns: Array<DatabaseTableColumn<any>>): React.ReactNode[] {
    return columns.map(x => {
      const filterProps = x.filterProps
        ? {
          load: x.filterProps.load,
          mapToFilterOption: x.filterProps.mapToFilterOption,
          onFilterValueChange: this.onFilterValueChange,
          value: this.state.filters[x.name] || [],
        }
        : undefined;

      return (
        <TableHeaderColumn
          name={x.name}
          key={x.key}
          className={x.className}
          helpText={x.info}
          subText={x.subText}
          filterProps={filterProps}
        />
      );
    });
  }

  @autobind
  private getItemSize(item: DatabaseTableRootModel, index: number): number {
    const canCreateVariant = this.props.openCreateVariant;
    const modalitiesHeight =
      DatabaseTableElementSize.variantDefaultHeight * (item && item.variants ? item.variants.length : 0);
    return (
      (canCreateVariant
        ? DatabaseTableElementSize.rootDefaultHeight
        : DatabaseTableElementSize.readonlyRootDefaultHeight)
      +
      (index === (this.props.items || []).length - 1
        ? DatabaseTableElementSize.rootMarginLastBottom
        : DatabaseTableElementSize.rootMarginBottom)
      +
      (modalitiesHeight > DatabaseTableElementSize.variantsMaxHeight
        ? DatabaseTableElementSize.variantsMaxHeight
        : modalitiesHeight)
    );
  }

  @autobind
  private renderRoot(item: DatabaseTableRootModel): JSX.Element {
    const isDisabled = this.props.disabledRootIds.includes(item.id);
    const isSelected = this.props.selectedRootIds.includes(item.id);

    const variantMenuItems = this.props.variantMenuItems ||
      this.props.getVariantMenuItems && this.props.getVariantMenuItems(item);

    return (
      <DatabaseTableRoot
        rootItem={item}
        key={item.id}
        tableName={this.props.entityName}
        columns={this.props.columns}
        rootMenuItems={this.props.rootMenuItems}
        variantMenuItems={variantMenuItems}
        isSelectDisabled={isDisabled}
        isSelected={isSelected}
        disabledVariantIds={this.props.disabledVariantIds}
        selectedVariantIds={this.props.selectedVariantIds}

        editRoot={this.props.openEditRoot}
        editVariant={this.props.openEditVariant}
        createVariant={this.props.openCreateVariant}
        selectRoot={this.props.selectRoot}
        selectVariant={this.props.selectVariant}
      />
    );
  }

  private getFilterStringValues(filters: Record<string, FilterValue[]>): Record<string, string[]> {
    const result = {};
    Object.keys(filters).forEach(key => {
      result[key] = filters[key].map(x => x.value);
    });

    return result;
  }

  @autobind
  private search(value: string): void {
    this.setState({ searchQuery: value }, () => {
      this.searchDeferredExecutor.execute(() => {
        const filters = this.getFilterStringValues(this.state.filters);
        this.props.loadItems(0, this.props.batch, value, filters);
      });
    });
  }

  @autobind
  private lazyLoad(skip: number, take: number): void {
    const filters = this.getFilterStringValues(this.state.filters);
    this.props.loadItems(skip, take, this.state.searchQuery, filters);
  }

  @autobind
  private onFilterValueChange(filterName: string, value: FilterValue[]): void {
    const filters = {
      ...this.state.filters,
      [filterName]: value,
    };
    this.setState({ filters });

    const searchFilters = this.getFilterStringValues(filters);
    this.props.loadItems(0, this.props.batch, this.state.searchQuery, searchFilters);
  }
}
