import * as React from 'react';

import { FILTER_SETS } from './filter-sets';
import { FilterGroup, FilterSet } from './interfaces';

interface ProjectFilterList {
  title: string;
  content: React.ReactNode;
}

export function getProjectFilterList(
  renderFilterSet: (filter: FilterSet | FilterGroup) => React.ReactNode,
  renderColorFilter: () => React.ReactNode,
  renderEntitiesFilter: () => React.ReactNode,
  renderUsersSelectFilter: () => React.ReactNode,
): ProjectFilterList[] {
  return [
    {
      title: 'BY TYPES',
      content: (renderFilterSet(FILTER_SETS[0])),
    },
    {
      title: 'BY REPORTS',
      content: (renderFilterSet(FILTER_SETS[2])),
    },
    {
      title: 'BY COLOR',
      content: (renderColorFilter()),
    },
    {
      title: 'BY ENTITIES',
      content: (renderEntitiesFilter()),
    },
    {
      title: 'BY CREATORS',
      content: (renderUsersSelectFilter()),
    },
  ];
}
