import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AssignedPia } from '2d/interfaces';
import { DrawingsGeometryGroup } from 'common/components/drawings';
import { State } from 'common/interfaces/state';

export enum InheritType {
  Assembly,
  Item,
}

const find = (
  assignPia: Record<string, AssignedPia>,
  id: string,
  type: InheritType,
  entityName: string,
  groups: DrawingsGeometryGroup[],
): { id: string, name: string } => {
  const assign = assignPia[id];
  const group = groups.find(g => g.id === id);
  if (!group) {
    return null;
  }
  if (assign) {
    const entity = type === InheritType.Assembly
      ? assign.assemblies?.find(a => a.name === entityName)
      : assign.items?.find(a => a.name === entityName);
    if (entity) {
      return { id, name: group.name };
    }
  }
  return find(assignPia, group.parentId, type, entityName, groups);
};

export const useParentAssign = (type: InheritType, id: string, entityName: string): { id: string, name: string } => {
  const groups = useSelector<State, DrawingsGeometryGroup[]>(s => s.drawings.drawingGeometryGroups);
  const assignPia = useSelector<State, Record<string, AssignedPia>>(s => s.twoD.assignPia);

  return useMemo(() => find(assignPia, id, type, entityName, groups), [assignPia, id, type, entityName, groups]);
};
