import { DeleteButtonStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  width: 360px;

  ${DeleteButtonStyled.Container} {
    display: none;
  }
`;

export const Styled = {
  Container,
};
