import { isEqual } from 'lodash';
import React from 'react';

import './database-activity-variant-total-cost.scss';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { ActivityModel, ActivityVariantModel, ActivityVariantType, UnitModel } from '../../interfaces/data';
import { DatabaseActivityVariantCost, DatabaseCostCalculator } from '../../utils/database-cost-calculator';

interface Props extends I18nAwareProps {
  databaseId: number;
  activity: ActivityModel;
  activityVariant: ActivityVariantModel;
  unitMap: Record<number, UnitModel>;
}

interface State {
  cost: DatabaseActivityVariantCost;
}

const CURRENCY_FORMAT = '0,0.00';

class DatabaseActivityVariantTotalCostComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cost: {
        laborCost: 0,
        materialsCost: 0,
        plantCost: 0,
        totalCost: 0,
      },
    };
  }

  public componentDidMount(): void {
    if (this.props.activity && this.props.activityVariant) {
      this.updateCosts();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.activity && this.props.activityVariant && this.isChanged(prevProps, this.props)) {
      this.updateCosts();
    }
  }

  public render(): React.ReactNode {
    const cost = this.state.cost;
    const { i18n } = this.props;

    return (
      <div className='activity-variant-total-cost'>
        <div className='activity-variant-total-cost__item'>
          <div className='activity-variant-total-cost__label'>LABOUR {i18n.currency.symbol}</div>
          <div className='activity-variant-total-cost__value'>{i18n.c(cost.laborCost, CURRENCY_FORMAT)}</div>
        </div>
        <div className='activity-variant-total-cost__item'>
          <div className='activity-variant-total-cost__label'>PLANT {i18n.currency.symbol}</div>
          <div className='activity-variant-total-cost__value'>{i18n.c(cost.plantCost, CURRENCY_FORMAT)}</div>
        </div>
        <div className='activity-variant-total-cost__item'>
          <div className='activity-variant-total-cost__label'>MATERIALS {i18n.currency.symbol}</div>
          <div className='activity-variant-total-cost__value'>{i18n.c(cost.materialsCost, CURRENCY_FORMAT)}</div>
        </div>
        <div className='activity-variant-total-cost__item'>
          <div className='activity-variant-total-cost__label'>TOTAL {i18n.currency.symbol}</div>
          <div className='activity-variant-total-cost__value'><b>{i18n.c(cost.totalCost, CURRENCY_FORMAT)}</b></div>
        </div>
      </div>
    );
  }

  private isChanged(prevProps: Props, currentProps: Props): boolean {
    const isPlantsChanged = (): boolean => !isEqual(prevProps.activity.plants, currentProps.activity.plants);
    const isLaborsChanged = (): boolean => !isEqual(prevProps.activity.labors, currentProps.activity.labors);
    const isMaterialsChanged =
      (): boolean => !isEqual(prevProps.activityVariant.materials, currentProps.activityVariant.materials);
    const isCrewHoursChanged =
      (): boolean => !isEqual(prevProps.activityVariant.crewHours, currentProps.activityVariant.crewHours);
    const isMaterialCrewHoursChanged =
      (): boolean => prevProps.activityVariant.type === ActivityVariantType.ResourceBasedCrewHours && !isEqual(
        prevProps.activityVariant.materialBasedCrewHours,
        currentProps.activityVariant.materialBasedCrewHours,
      );

    return isPlantsChanged()
      || isLaborsChanged()
      || isMaterialsChanged()
      || isCrewHoursChanged()
      || isMaterialCrewHoursChanged();
  }

  private updateCosts(): void {
    const cost = DatabaseCostCalculator.getCosts(this.props.activity, this.props.activityVariant, this.props.unitMap);
    this.setState({ cost });
  }
}

export const DatabaseActivityVariantTotalCost = withI18n(DatabaseActivityVariantTotalCostComponent);
