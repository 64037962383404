import * as t from 'io-ts';
import { CEActivityAssignmentTreeNodeActivityResponseC } from './ce-activity-assignment-tree-node-activity-response';


export const CEActivityAssignmentActivityC = t.intersection(
  [
    t.type({
      idsStart: t.number,
      idsEnd: t.number,
    }),
    CEActivityAssignmentTreeNodeActivityResponseC,
  ],
  'ActivityAssignmentActivity',
);

export type CEActivityAssignmentActivity = t.TypeOf<typeof CEActivityAssignmentActivityC>;
