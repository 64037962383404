import { Icons, IconButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { InteractiveMenu } from 'common/components/interactive-menu';
import { ColorList } from 'common/constants/color-list';
import { DrawingsColorControl } from './color-control';
import { Styled } from './styled';

interface Props {
  color: string;
  changeColor: (color: string) => void;
  icon?: React.ComponentType;
}


const DrawingsColorButtonComponent: React.FC<Props> = ({ color, changeColor, icon = Icons.CircleFilled }) => {
  const renderMenu = useCallback(() => {
    return (
      <Styled.DrawingsColorControl>
        <DrawingsColorControl
          colorList={ColorList}
          selectedColor={color}
          onColor={changeColor}
        />
      </Styled.DrawingsColorControl>
    );
  }, [color, changeColor]);

  return (
    <InteractiveMenu
      renderMenuContent={renderMenu}
      closeOnContainerClick={false}
      marginTop={0}
    >
      <Styled.Container color={color}>
        <IconButton Icon={icon} height={20} />
      </Styled.Container>
    </InteractiveMenu>
  );
};


export const DrawingsColorButton = React.memo(DrawingsColorButtonComponent);
