import {
  ActivityModel,
  ActivityResourceVariant,
  ActivityVariantMaterialBasedCrewHoursModel,
  ActivityVariantModel,
  ActivityVariantResource,
  CodeGroupModel,
  ConstraintModel,
  ExtractorFunctionModel,
  ExtractorFunctionParameter,
  MaterialBasedCrewHoursModel,
} from '../interfaces/data';
import { LaborVariantModel, MaterialModel, PlantVariantModel } from '../interfaces/resources-data';
import {
  ActivityForm,
  ActivityVariantForm,
  ActivityVariantMaterialBasedCrewHoursForm,
  CodeGroupForm,
  ConstraintForm,
  LaborForm,
  MaterialBasedCrewHoursForm,
  MaterialForm,
  PlantForm,
} from '../interfaces/rest-data';


export class RestFormMapper {
  public static GetActivityForm(activity: ActivityModel, extractorFunctions: ExtractorFunctionModel[]): ActivityForm {
    return {
      name: activity.name,
      codeGroups: activity.codeGroups.map(RestFormMapper.GetCodeGroupForm),
      labors: activity.labors.map(RestFormMapper.GetLaborForm),
      variants: activity.variants.map(x => RestFormMapper.GetActivityVariantForm(x, extractorFunctions)),
      plants: activity.plants.map(RestFormMapper.GetPlantForm),
    };
  }

  public static GetActivityVariantForm(
    variant: ActivityVariantModel,
    extractorFunctions: ExtractorFunctionModel[],
  ): ActivityVariantForm {
    const extractorFunction = extractorFunctions.find(x => x.id === variant.extractorFunction);
    const defaultParameters = extractorFunction ? extractorFunction.extractorParameters : [];

    return {
      name: variant.name,
      id: variant.id,
      extractorFunctionId: variant.extractorFunction,
      unitId: variant.unitId,
      constraints: variant.constraints.map(RestFormMapper.GetConstraintForm),
      materials: variant.materials.map(RestFormMapper.GetMaterialForm),
      parameters: RestFormMapper.GetParameters(variant.extractorParameters, defaultParameters),

      type: variant.type,
      crewHours: variant.crewHours,
      materialBasedCrewHours: RestFormMapper.GetMaterialBasedCrewHours(variant.materialBasedCrewHours),
    };
  }

  public static GetCodeGroupForm(codeGroup: CodeGroupModel): CodeGroupForm {
    return {
      id: codeGroup.id,
      nrm1Id: codeGroup.nrm1 && codeGroup.nrm1.id,
      nrm2Id: codeGroup.nrm2 && codeGroup.nrm2.id,
      uniProductId: codeGroup.uniProduct && codeGroup.uniProduct.id,
      uniSystemId: codeGroup.uniSystem && codeGroup.uniSystem.id,
      category: codeGroup.category,
      family: codeGroup.family,
      elementType: codeGroup.elementType,
      comment: codeGroup.comment,
    };
  }

  public static GetLaborForm(labor: ActivityResourceVariant<LaborVariantModel>): LaborForm {
    return {
      id: labor.id,
      amount: labor.amount,
      laborVariantId: labor.resourceVariant.id,
    };
  }

  public static GetPlantForm(plant: ActivityResourceVariant<PlantVariantModel>): PlantForm {
    return {
      id: plant.id,
      amount: plant.amount,
      plantVariantId: plant.resourceVariant.id,
    };
  }

  public static GetConstraintForm(constraint: ConstraintModel): ConstraintForm {
    return {
      id: constraint.id,
      value: constraint.value,
      unitId: constraint.unitId,
      extractorFunctionId: constraint.extractorFunction,
      operation: constraint.operation,
      stringValue: constraint.stringValue,
    };
  }

  public static GetMaterialForm(material: ActivityVariantResource<MaterialModel>): MaterialForm {
    return {
      id: material.id,
      amount: material.amount,
      materialId: material.resource && material.resource.id,
      unitId: material.unitId,
    };
  }

  public static GetParameters(
    parameters: ExtractorFunctionParameter[],
    defaultParameters: ExtractorFunctionParameter[],
  ): ExtractorFunctionParameter[] {
    const valueMap = {};
    if (parameters) {
      parameters.forEach(x => valueMap[x.parameterExtractorFunctionId] = x.value);
    }

    return defaultParameters.map(x => ({
      parameterExtractorFunctionId: x.parameterExtractorFunctionId,
      value: valueMap[x.parameterExtractorFunctionId] || x.value,
    }));
  }

  public static GetMaterialBasedCrewHours(
    resourceCrewHours: ActivityVariantMaterialBasedCrewHoursModel,
  ): ActivityVariantMaterialBasedCrewHoursForm {
    if (!resourceCrewHours) {
      return null;
    }

    return {
      primaryMaterial: RestFormMapper.GetMaterialBasedCrewHoursMaterial(resourceCrewHours.primaryMaterial),
      secondaryMaterial: RestFormMapper.GetMaterialBasedCrewHoursMaterial(resourceCrewHours.secondaryMaterial),
      primaryPercentage: resourceCrewHours.primaryPercentage,
    };
  }

  public static GetMaterialBasedCrewHoursMaterial(material: MaterialBasedCrewHoursModel): MaterialBasedCrewHoursForm {
    return {
      amount: material.amount,
      crewHours: material.crewHours,
      materialId: material.resource && material.resource.id,
      unitId: material.unitId,
    };
  }
}
