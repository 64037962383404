import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './quantity-take-off-report-pivot-table.scss';

import { AgGridHelper } from 'common/ag-grid';
import { SvgSpinner } from 'common/components/svg-spinner';
import { TreeTable } from 'common/components/tree-table';
import { HighlightFormulaHelper } from 'common/components/tree-table/highlight-formula-helper';
import {
  TreeTableData,
  TreeTableGroupRules,
  TreeTableRow,
  TreeTableRowType,
  TreeTableValueGetterParams,
  TreeTableValueSetterParams,
} from 'common/components/tree-table/interfaces';
import { TreeTableDefaultGroupRules } from 'common/components/tree-table/tree-table-default-group-rules';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { ValueHelper } from 'common/utils/value-helper';
import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';
import { QuantityTakeOffReportActions } from '../../actions/creators/quantity-take-off-report';
import { DefaultPivotColumnKey } from '../../enums/default-pivot-column-key';
import { QtoReportRowUpdateType } from '../../enums/qto-report-row-update-type';
import {
  ModelType,
  QtoReport,
  QtoTreeColumnsForm,
  QtoTreeRowForm,
  QtoTreeRowProperty,
} from '../../interfaces/quantity-take-off';
import {
  PropertyHelper,
  QtoReportPivotTableIdHelper,
  QtoReportPivotTableMapper,
  QtoTreeTableHelper,
} from '../../utils/quantity-take-off-tree-table';
import { QuantityTakeOffFormulaHelper } from '../../utils/quantity-take-off-tree-table/formula-helper';
import {
  pivotPropertyGetter,
  pivotValueGetter,
} from '../../utils/quantity-take-off-tree-table/pivot-table/value-getter';
import {
  QuantityTakeOffReportRowDiffStatusUtils,
} from '../../utils/quantity-take-off-tree-table/row-diff-status-utils';
import { QtoTreeTableCellNonEdit } from '../quantity-take-off-report-table/quantity-take-off-tree-table-cell-non-edit';
import { QtoTreeTableCommon } from '../quantity-take-off-report-table/quantity-take-off-tree-table-common';
import {
  QuantityTakeOffTreeTableNameCell,
} from '../quantity-take-off-report-table/quantity-take-off-tree-table-name-cell';
import { QtoReportPivotTableCellEdit } from './quantity-take-off-report-pivot-table-cell-edit';
import { qtoReportPivotTableCellValueFormatter } from './quantity-take-off-report-pivot-table-cell-value-formatter';
import { QtoReportPivotTableContextMenuService } from './quantity-take-off-report-pivot-table-context-menu-service';
import { QtoReportPivotTableUpdater } from './quantity-take-off-report-pivot-table-updater';


interface OwnProps {
  readonly: boolean;
  projectId: number;
  modelType: ModelType;
  saveTableRef: (ref: TreeTable<QtoTreeRowProperty, QtoTreeRowProperty>) => void;
}

interface ReduxProps {
  isSync: boolean;
  selectedReportId: number | null;
  loadReportStatus: RequestStatus;
  reportModel: QtoReport;
  isImperial: boolean;
  disableShowDialogList: string[];
}

interface DispatchProps {
  loadReport: (reportId: number) => void;
  updateColumns: (reportId: number, columnsForm: QtoTreeColumnsForm) => void;
  updateRows: (reportId: number, rows: QtoTreeRowForm[]) => void;
  onChangeDisableShowDialog: () => void;
  closeConfirmDialog: () => void;
  openConfirmDialog: () => void;
}

interface OwnState {
  treeTableData: TreeTableData<QtoTreeRowProperty, QtoTreeRowProperty>;
  rows: Array<TreeTableRow<QtoTreeRowProperty>>;
}

interface Props extends OwnProps, DispatchProps, ReduxProps { }


const GROUPED_COLUMN_KEY = 'name';
const TABLE_UPDATE_DELAY = 1000;
const PIVOT_TABLE_PASTE_NOT_SUPPORT_DIALOG = 'PIVOT_TABLE_PASTE_NOT_SUPPORT_DIALOG';


class QtoReportPivotTableComponent extends React.Component<Props, OwnState> {
  private nonRemovableIds: string[] = [
    DefaultPivotColumnKey.Quantity,
    DefaultPivotColumnKey.Unit,
    DefaultPivotColumnKey.QuantityPrev,
    DefaultPivotColumnKey.Total,
    DefaultPivotColumnKey.Rate,
  ];

  private tableRef: TreeTable<QtoTreeRowProperty, QtoTreeRowProperty>;
  private groupRules: TreeTableGroupRules = new TreeTableDefaultGroupRules();
  private tableUpdater: QtoReportPivotTableUpdater;
  private highlighter: HighlightFormulaHelper = new HighlightFormulaHelper((event: Ag.CellEditingStartedEvent) => {
    const parent = event.node.parent;
    const colId = event.column.getColId();
    const { columnId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(event.node.data.id);
    const id = QtoReportPivotTableIdHelper.getPivotRowId(columnId, colId);
    return parent.data.properties[id];
  });


  private quantityValues: Record<string, QtoTreeRowProperty>;

  private cellClassRules: Record<string, (params: Ag.ICellRendererParams) => boolean> = {
    'ag-cell--group': this.groupCellClassRule,
    'ag-cell--error': this.errorCellClassRule,
    'ag-cell--edited': this.editedCellClassRule,
  };
  private defaultColumnsDef: Partial<Ag.ColDef | Ag.ColGroupDef> = {
    cellClassRules: this.cellClassRules,
    editable: true,
    menuTabs: [AgGridHelper.constants.columnMenuTab.generalMenuTab],
    valueFormatter: this.cellValueFormatter,
    cellEditorSelector: (params: Ag.ICellEditorParams) => {
      return {
        component: this.isCellEditable(params)
          ? 'qtoReportPivotTableCellEdit'
          : 'qtoReportTableCellNonEdit',
        params: {
          ...params,
          propertyGetter: this.propertyGetter,
        },
      };
    },
    toolPanelClass: (params) => {
      const colId = params.column && params.column.getColId();
      return QtoTreeTableHelper.isColumnIdHasDiffPostfix(colId) ? 'quantity-take-off-report-table__column-diff' : '';
    },
  };
  private rowClassRules: Record<string, string | ((data: any) => boolean)> = {
    'quantity-take-off-report-table__row--added': (row) => {
      return QuantityTakeOffReportRowDiffStatusUtils.getDiffStatus(row.data) === QtoReportRowUpdateType.Added;
    },
    'quantity-take-off-report-table__row--deleted': (row) => {
      return QuantityTakeOffReportRowDiffStatusUtils.getDiffStatus(row.data) === QtoReportRowUpdateType.Deleted;
    },
  };

  constructor(props: Props) {
    super(props);
    if (!props.reportModel) {
      this.state = {
        treeTableData: null,
        rows: [],
      };
      return;
    }
    const { treeTableData, defaultValues } = QtoReportPivotTableMapper.map(props.reportModel);
    this.quantityValues = defaultValues;

    this.state = {
      treeTableData,
      rows: [],
    };
  }

  public static getDerivedStateFromProps(props: Props, state: OwnState): Partial<OwnState> {
    if (!state.treeTableData && props.reportModel) {
      const { treeTableData } = QtoReportPivotTableMapper.map(props.reportModel);
      return { treeTableData };
    }
  }

  public executeAllChanges(): void {
    if (this.tableUpdater) {
      this.tableUpdater.executeImmediatelyAll();
    }
  }

  public componentDidMount(): void {
    const { selectedReportId } = this.props;
    if (selectedReportId) {
      this.props.loadReport(selectedReportId);
      this.createTableUpdater();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    const currentShowDiff = this.props.reportModel && this.props.reportModel.showDiff;
    const prevShowDiff = prevProps.reportModel && prevProps.reportModel.showDiff;
    const showDiffUpdated = currentShowDiff !== prevShowDiff;
    if (this.props.selectedReportId) {
      if (this.props.selectedReportId !== prevProps.selectedReportId) {
        this.createTableUpdater();
        this.props.loadReport(this.props.selectedReportId);
      } else if (showDiffUpdated) {
        this.updateColumnsVisibility(currentShowDiff);
      }
      if (this.props.reportModel) {
        if (this.props.reportModel !== prevProps.reportModel) {
          this.mapReportModel(currentShowDiff);
        } else if (showDiffUpdated) {
          this.updateRowsVisibility(currentShowDiff);
        }
      }
    }
    if (this.props.isImperial !== prevProps.isImperial || showDiffUpdated) {
      QtoTreeTableCommon.refreshTable(this.tableRef);
    }
  }

  public render(): JSX.Element {
    if (this.props.loadReportStatus !== RequestStatus.Loaded) {
      return (
        <div className='quantity-take-off-report-table'>
          {
            this.props.loadReportStatus === RequestStatus.Loading
              ? (
                <SvgSpinner size='middle' />
              ) : (
                <span className='quantity-take-off-report-table__placeholder'>
                  You will see the Selected Report here
                </span>
              )
          }
        </div>
      );
    }
    const hideDiff = !this.props.reportModel || !this.props.reportModel.showDiff;
    const className = classNames(
      'quantity-take-off-report-table',
      {
        'quantity-take-off-report-table__hide-diff': hideDiff,
      },
    );
    return (
      <div className={className}>
        <TreeTable
          tableId='qtoPivotReportTable'
          rowDrag={false}
          withSideBar={true}
          groupRules={this.groupRules}
          groupedColumnKey={GROUPED_COLUMN_KEY}
          treeTableData={this.state.treeTableData}
          onColumnUpdate={this.onColumnsUpdate}
          onReorderRows={this.doNothing}
          onRowsUpdate={this.onRowsUpdate}
          onAddNewRows={this.doNothing}
          onRemoveRows={this.doNothing}
          cellValueGetter={this.cellValueGetter}
          cellValueSetter={this.cellValueSetter}
          ref={this.saveTableRef}
          defaultColumnsDef={this.defaultColumnsDef}
          getColumnDef={QtoTreeTableCommon.getColumnDef}
          getColumnProperties={QtoTreeTableCommon.getColumnProperties}
          onColumnNameChanged={this.onColumnHeaderRename}
          onGridReady={this.onGridReady}
          context={{ isImperial: this.props.isImperial, hideDiff, highlight: this.highlighter }}
          overrideGridOptions={{
            enableRangeSelection: true,
            enableRangeHandle: false,
            rowClassRules: this.rowClassRules,
            getMainMenuItems: this.getMainMenuItems,
            getContextMenuItems: this.getContextMenuItems,
            components: {
              qtoReportPivotTableCellEdit: QtoReportPivotTableCellEdit,
              qtoReportTableCellNonEdit: QtoTreeTableCellNonEdit,
            },
            autoGroupColumnDef: {
              headerCheckboxSelection: true,
              cellRendererParams: {
                innerRenderer: QuantityTakeOffTreeTableNameCell,
              },
            },
            suppressClipboardPaste: false,
            processCellForClipboard: this.processCellForClipboard,
            processCellFromClipboard: this.processCellFromClipboard,
          }}
        />
        <KreoConfirmationDialog
          name={PIVOT_TABLE_PASTE_NOT_SUPPORT_DIALOG}
          title='Pasting is not supported'
          yesText='Ok'
          noText='Cancel'
          onYes={this.props.closeConfirmDialog}
          onDisableShowDialogChange={this.props.onChangeDisableShowDialog}
        >
          You can't paste the value into a group's cell
        </KreoConfirmationDialog>
      </div>
    );
  }

  public componentWillUnmount(): void {
    if (this.props.saveTableRef) {
      this.props.saveTableRef(null);
    }
    this.executeAllChanges();
  }

  @autobind
  private processCellForClipboard(params: Ag.ProcessCellForExportParams): string | number {
    const parent = params.node.parent;
    const colId = params.column && params.column.getColId();
    if (colId === AgGridHelper.constants.autoColumnId) {
      return params.value;
    }
    const { columnId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(params.node.data.id);
    if (colId === DefaultPivotColumnKey.Quantity) {
      const quantityProperty = parent.data.properties[columnId];
      if (PropertyHelper.isAggregation(quantityProperty) || PropertyHelper.isFormula(quantityProperty)) {
        return params.value;
      }
      return PropertyHelper.getActualValue<string>(parent.data.properties[columnId]);
    }
    const id = QtoReportPivotTableIdHelper.getPivotRowId(columnId, colId);
    const property = parent.data.properties[id];
    const value = PropertyHelper.getActualValue<string>(property);
    return value;
  }

  private getCopyFormula(params: Ag.ProcessCellForExportParams, value: string): string {
    const { columnId: rowId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(params.node.data.id);
    const formula = value.replace(
      QuantityTakeOffFormulaHelper.formulaVariableRegex,
      (_match, oldKey) => {
        const ids = QtoReportPivotTableIdHelper.getIdsFromPivotPropertyId(oldKey);
        const oldRowId = ids
          ? ids.columnId
          : oldKey;

        return `[${oldKey.replace(oldRowId, rowId)}]`;
      });

    return formula;
  }

  @autobind
  private processCellFromClipboard(params: Ag.ProcessCellForExportParams): QtoTreeRowProperty {
    const dialogIsDisabled = this.props.disableShowDialogList
      && this.props.disableShowDialogList.includes(PIVOT_TABLE_PASTE_NOT_SUPPORT_DIALOG);
    if (params.node.data.type === TreeTableRowType.Group) {
      if (!dialogIsDisabled) {
        this.props.openConfirmDialog();
      }
      return;
    }

    const property = params.column.getColId() === DefaultPivotColumnKey.Quantity
      ? this.quantityValues[params.node.id]
      : { override: params.value };

    const value = ValueHelper.isNumberValue(params.value)
      ? Number(params.value)
      : params.value;
    property.override = value;

    if (PropertyHelper.isFormula(property)) {
      return {
        override: this.getCopyFormula(params, PropertyHelper.getActualValue<string>(property)),
      };
    }

    return property;
  }

  @autobind
  private onGridReady(): void {
    this.updateColumnsVisibility(this.props.reportModel && this.props.reportModel.showDiff);
  }

  private mapReportModel(currentShowDiff: boolean): void {
    const { treeTableData, defaultValues } = QtoReportPivotTableMapper.map(this.props.reportModel);
    this.quantityValues = defaultValues;
    const rows = treeTableData.rows;
    if (!currentShowDiff) {
      treeTableData.rows = rows.filter(QuantityTakeOffReportRowDiffStatusUtils.isRowNotDeleted);
    }
    this.setState({ treeTableData, rows });
  }

  private updateRowsVisibility(currentShowDiff: boolean): void {
    this.setState({
      treeTableData: {
        ...this.state.treeTableData,
        rows: currentShowDiff
          ? this.state.rows
          : this.state.rows.filter(QuantityTakeOffReportRowDiffStatusUtils.isRowNotDeleted),
      },
    });
  }

  private updateColumnsVisibility(currentShowDiff: boolean): void {
    if (this.props.loadReportStatus === RequestStatus.Loaded && this.tableRef.gridColumnApi) {
      const columnsForChange = [];
      for (const column of this.tableRef.gridColumnApi.getAllColumns()) {
        const id = column.getColId();
        if (QtoTreeTableHelper.isColumnIdHasDiffPostfix(id) && currentShowDiff !== column.isVisible()) {
          columnsForChange.push(column);
        }
      }
      this.tableRef.gridColumnApi.setColumnsVisible(columnsForChange, currentShowDiff);
    }
  }

  @autobind
  private isCellEditable(params: Ag.ICellEditorParams): boolean {
    const colId = params.column && params.column.getColId();
    return this.groupRules.isItem(params.node)
      && colId !== DefaultPivotColumnKey.Unit
      && !QtoReportPivotTableIdHelper.isIdContainDiffPostfix(colId);
  }

  private createTableUpdater(): void {
    const reportId = this.props.selectedReportId;
    const callBacks = {
      onColumnsUpdate: this.props.updateColumns,
      onRowsUpdate: this.props.updateRows,
    };
    if (this.tableUpdater) {
      this.tableUpdater.executeImmediatelyAll();
    }

    this.tableUpdater = new QtoReportPivotTableUpdater(reportId, TABLE_UPDATE_DELAY, callBacks);
  }

  @autobind
  private saveTableRef(ref: TreeTable<QtoTreeRowProperty, QtoTreeRowProperty>): void {
    this.tableRef = ref;
    this.props.saveTableRef(ref);
  }

  private doNothing(): void {
    return;
  }

  @autobind
  private propertyGetter(params: TreeTableValueGetterParams): QtoTreeRowProperty {
    const basicColumns = this.props.reportModel.basicColumns.columns;
    return pivotPropertyGetter(params, basicColumns, this.quantityValues);
  }

  @autobind
  private cellValueGetter(params: TreeTableValueGetterParams): string {
    const { node, columnId } = params;
    const data = node && node.data;
    if (!data) {
      return;
    }

    if (data.type === TreeTableRowType.Group) {
      if (columnId === GROUPED_COLUMN_KEY) {
        return PropertyHelper.getActualValue(node.data.properties[columnId]);
      }

      if (columnId === DefaultPivotColumnKey.Total) {
        const sum = node.childrenAfterGroup.reduce(
          (result, n) => {
            const value = params.api.getValue(columnId, n);
            return result + Number(value);
          },
          0);

        return sum
          ? sum.toString()
          : '';
      }

      return;
    }

    return this.pivotValueGetter(params);
  }

  @autobind
  private pivotValueGetter(params: TreeTableValueGetterParams): string {
    const basicColumns = this.props.reportModel.basicColumns.columns;
    return pivotValueGetter(params, basicColumns, this.quantityValues);
  }

  @autobind
  private cellValueFormatter(params: Ag.ValueFormatterParams): string {
    return qtoReportPivotTableCellValueFormatter(params, this.pivotValueGetter);
  }

  @autobind
  private cellValueSetter(params: TreeTableValueSetterParams<QtoTreeRowProperty>): boolean {
    if (!this.tableRef) {
      return false;
    }

    const pivotRowId = params.data.id;
    const { columnId, groupId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(pivotRowId);
    const gridApi = this.tableRef.gridApi;
    const propertyId = params.columnId === DefaultPivotColumnKey.Quantity
      ? columnId
      : QtoReportPivotTableIdHelper.getPivotPropertyIdFromPivotRowId(pivotRowId, params.columnId);

    if (propertyId in this.quantityValues) {
      this.quantityValues[propertyId] = params.newValue;
    }
    const data = gridApi.getRowNode(groupId).data;
    if (params.newValue) {
      data.properties[propertyId] = params.newValue;
    } else {
      delete data.properties[propertyId];
    }

    this.tableRef.rowController.updateRows([{
      id: data.id,
      properties: data.properties,
    }]);
    return true;
  }

  @autobind
  private onColumnHeaderRename(id: string, name: string): void {
    QtoTreeTableCommon.onColumnHeaderRename(this.tableRef.columnController, id, name);
  }

  @autobind
  private getMainMenuItems(params: Ag.GetMainMenuItemsParams): Array<string | Ag.MenuItemDef> {
    const columnId = params.column.getId();
    if (columnId === Ag.Constants.GROUP_AUTO_COLUMN_ID) {
      return [
        {
          name: 'Add new column',
          action: () => this.addNewColumn(0),
        },
        AgGridHelper.constants.columnMainMenu.separator,
      ].concat(params.defaultItems);
    } else {
      const disabled = this.nonRemovableIds.includes(columnId);

      return [
        {
          name: 'Add new column',
          action: () => {
            this.addNewColumn(params.columnApi.getAllColumns().findIndex(x => x.getColId() === columnId) + 2);
          },
        },
        {
          name: 'Remove',
          action: () => this.tableRef.columnController.removeColumns([columnId]),
          disabled,
          tooltip: disabled
            ? 'You can\'t remove default column'
            : null,
        },
        AgGridHelper.constants.columnMainMenu.separator,
        AgGridHelper.constants.columnMainMenu.autoSizeThis,
      ];
    }
  }

  @autobind
  private getContextMenuItems(params: Ag.GetContextMenuItemsParams): Array<string | Ag.MenuItemDef> {
    const { column, node } = params;
    const groupRules = this.groupRules;
    if (!column) {
      return null;
    }

    if (groupRules.isItem(node)) {
      const hideRow = (): void => this.hidePivotRow(node);
      const setDefaultVisibility = (): void => this.setDefaultPivotRowVisibility(node);
      return QtoReportPivotTableContextMenuService.getPivotRowContextMenuItems(node, hideRow, setDefaultVisibility);
    } else {
      return QtoReportPivotTableContextMenuService.getGroupContextMenuItems(
        this.props.reportModel.basicColumns,
        (id) => this.addNewPivotRow(id, node.id),
        node,
      );
    }
  }

  @autobind
  private addNewColumn(index: number): void {
    this.tableRef.columnController.addColumns(
      [{
        properties: {
          [PropertyHelper.columnProperties.header]: { default: 'New Column' },
          [PropertyHelper.columnProperties.isVisible]: { default: true },
        },
      }],
      index,
    );
  }

  @autobind
  private hidePivotRow(pivotRow: Ag.RowNode): void {
    this.tableRef.rowController.removeRows([pivotRow.id]);

    const parent = pivotRow.parent;
    const { columnId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(pivotRow.id);
    const pivotPropertyId = QtoReportPivotTableIdHelper.getPivotPropertyId(columnId, DefaultPivotColumnKey.IsVisible);
    this.onRowsUpdate([{
      id: parent.id,
      properties: {
        ...parent.data.properties,
        [pivotPropertyId]: { default: false },
      },
    }]);
  }

  @autobind
  private setDefaultPivotRowVisibility(pivotRow: Ag.RowNode): void {
    const parent = pivotRow.parent;
    const parentProperties = parent.data.properties;
    const { columnId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(pivotRow.id);
    const pivotPropertyId = QtoReportPivotTableIdHelper.getPivotPropertyId(columnId, DefaultPivotColumnKey.IsVisible);
    delete parentProperties[pivotPropertyId];

    this.onRowsUpdate([{
      id: parent.id,
      properties: parentProperties,
    }]);

    if (!this.isBasicColumnVisible(columnId)) {
      this.tableRef.rowController.removeRows([pivotRow.id]);
    }
  }

  private isBasicColumnVisible(columnId: string): boolean {
    const columnProperties = this.props.reportModel.basicColumns.columns[columnId].properties;
    const visibilityProperty = columnProperties[PropertyHelper.columnProperties.isVisible];
    return !!PropertyHelper.getActualValue(visibilityProperty);
  }

  @autobind
  private addNewPivotRow(columnId: string, rowId: string): void {
    const isVisibleKey = QtoReportPivotTableIdHelper.getPivotPropertyId(columnId, DefaultPivotColumnKey.IsVisible);
    const nodesToUpdate = this.tableRef.gridApi.getRowNode(rowId).allLeafChildren
      .filter(x => x.data.type === TreeTableRowType.Group);
    const updateForms = nodesToUpdate
      .map(row => {
        const form = {
          id: row.id,
          properties: {
            ...row.data.properties,
            [columnId]: row.data.properties[columnId] || PropertyHelper.getAggregationProperty(),
          },
        };

        if (form.id === rowId) {
          form.properties[isVisibleKey] = { default: true };
        }

        return form;
      });
    this.onRowsUpdate(updateForms);

    nodesToUpdate.forEach(row => {
      const pivotRowId = QtoReportPivotTableIdHelper.getPivotRowId(row.id, columnId);
      if (!row.childrenMapped[pivotRowId]) {
        const pivotRow = QtoReportPivotTableMapper.formNewPivotRow(pivotRowId);
        const index = this.getPivotRowIndex(row, columnId);
        this.tableRef.rowController.addRows([pivotRow], row.id, index);
      }
    });
  }

  @autobind
  private getPivotRowIndex(row: Ag.RowNode, insertedColumnId: string): number {
    let index = 0;
    const columnIds = this.props.reportModel.basicColumns.firstLevelColumns;
    for (const columnId of columnIds) {
      if (columnId === insertedColumnId) {
        return index;
      }
      if (row.childrenMapped[QtoReportPivotTableIdHelper.getPivotRowId(row.id, columnId)]) {
        index++;
      }
    }

    return index;
  }

  @autobind
  private onColumnsUpdate(columnsUpdateModel: QtoTreeColumnsForm): void {
    this.tableUpdater.onColumnsUpdate(columnsUpdateModel);
  }

  @autobind
  private onRowsUpdate(rowsToUpdate: QtoTreeRowForm[]): void {
    rowsToUpdate.forEach(x => {
      const node = this.tableRef.gridApi.getRowNode(x.id);
      node.data.properties = x.properties;
      node.setData(node.data);
    });

    this.tableUpdater.onRowsUpdate(rowsToUpdate);
  }

  private groupCellClassRule(params: Ag.ICellRendererParams): boolean {
    return params.data.type === TreeTableRowType.Group;
  }

  @autobind
  private editedCellClassRule(params: Ag.ICellRendererParams): boolean {
    if (this.groupCellClassRule(params)) {
      return false;
    }
    const basicColumns = this.props.reportModel.basicColumns.columns;
    const property = pivotPropertyGetter(
      {
        api: params.api,
        columnApi: params.columnApi,
        node: params.node,
        columnId: params.colDef.colId,
        context: params.context,
      },
      basicColumns,
      this.quantityValues,
    );
    return PropertyHelper.isOverrideValue(property);
  }

  @autobind
  private errorCellClassRule(params: Ag.ICellRendererParams): boolean {
    if (this.groupCellClassRule(params)) {
      return false;
    }

    const basicColumns = this.props.reportModel.basicColumns.columns;
    const values = pivotPropertyGetter(
      {
        api: params.api,
        columnApi: params.columnApi,
        node: params.node,
        columnId: params.colDef.colId,
        context: params.context,
      },
      basicColumns,
      this.quantityValues,
    );
    return values && !values.override && !values.default && !params.value && params.value !== 0;
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const reportState = state.quantityTakeOff.report;
  return {
    loadReportStatus: reportState.statuses.loadReport,
    reportModel: reportState.reportModel,
    selectedReportId: reportState.selectedReportId,
    isSync: reportState.isSync,
    isImperial: state.account.settings.isImperial,
    disableShowDialogList: state.persistedStorage.disableShowDialogList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  const { projectId, modelType } = props;

  return {
    loadReport: (reportId) => dispatch(QuantityTakeOffReportActions.loadReport(projectId, modelType, reportId)),
    updateColumns: (reportId, columnsForm) =>
      dispatch(QuantityTakeOffReportActions.updatePivotColumns(projectId, modelType, reportId, columnsForm)),
    updateRows: (reportId, rows) =>
      dispatch(QuantityTakeOffReportActions.updateRows(projectId, modelType, reportId, rows)),
    onChangeDisableShowDialog: () =>
      dispatch(PersistedStorageActions.toggleDisableShowDialog(PIVOT_TABLE_PASTE_NOT_SUPPORT_DIALOG)),
    closeConfirmDialog: () => dispatch(KreoDialogActions.closeDialog(PIVOT_TABLE_PASTE_NOT_SUPPORT_DIALOG)),
    openConfirmDialog: () => dispatch(KreoDialogActions.openDialog(PIVOT_TABLE_PASTE_NOT_SUPPORT_DIALOG)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const QtoReportPivotTable = connector(QtoReportPivotTableComponent);
