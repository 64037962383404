import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  padding-right: 15px;
`;

const Icon = styled.div`
  padding-right: 20px;

  svg {
    width: 20px;
    height: 20px;
    fill: ${props => props.theme.color.turquoise};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueContainer = styled.div`
  p {
    color: ${props => props.theme.color.gray};
  }
`;

export const Styled = {
  Container,
  Icon,
  TextContainer,
  ValueContainer,
};
