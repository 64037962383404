import React from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { CompanySubscriptionModel } from '../../../../account/interfaces/company-subscription-model';
import { BillingEntityStatus } from '../../../enums/billing-entity-status';
import { SubscriptionPlanListingModel } from '../../../interfaces/subscription-plan-listing-model';
import { SubscriptionPlanVariant } from '../../../interfaces/subscription-plan-variant';

const getPlan = (planModel: SubscriptionPlanListingModel, id: string): SubscriptionPlanVariant | null => {
  for (const plan of planModel.plans) {
    for (const variant of plan.variants) {
      if (variant.id === id) {
        return variant;
      }
    }
  }
  return null;
};

export const useIsArchivedMemo = (
  subscription: CompanySubscriptionModel,
): boolean => {
  const planModel = useSelector<State, SubscriptionPlanListingModel>(s => s.account.subscriptionPlans);
  return React.useMemo(() => {
    const plan = getPlan(planModel, subscription.planId);
    return plan?.status === BillingEntityStatus.Archived;
  }, [subscription, planModel]);
};
