import styled from 'styled-components';

import { QuickSearchStyled } from 'common/components/quick-search';

export const QuickSearchHeight = 50;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${QuickSearchStyled.Input} {
    width: 90%;
  }
  ${QuickSearchStyled.Container} {
    border-top: 1px solid ${p => p.theme.color.background};
  }
`;

export const Styled = {
  Container,
};
