import { ThemesDialog } from '@kreo/kreo-ui-components';

import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { ThemeType } from '../../../components/theme-provider/interfaces';
import { PersistedStorageActions } from '../../../units/persisted-storage/actions/creators';

export const THEMES_DIALOG_NAME = 'THEMES_DIALOG';

interface DispatchProps {
  closeThemeDialog: () => void;
  onChangeThemeTone: (theme: ThemeType, tone: string) => void;
}

interface StateProps {
  darkTone: string;
  lightTone: string;
}

interface Props extends DispatchProps, StateProps {
}

class ThemesDialogModalComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <DialogWrapper name={THEMES_DIALOG_NAME}>
        <ThemesDialog
          darkTone={this.props.darkTone}
          lightTone={this.props.lightTone}
          changeTone={this.props.onChangeThemeTone}
          onClose={this.props.closeThemeDialog}
        />
      </DialogWrapper>
    );
  }
}


const mapStateTopProps = (state: State): StateProps => {
  return {
    darkTone: state.persistedStorage.darkTone,
    lightTone: state.persistedStorage.lightTone,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    onChangeThemeTone: (theme, tone) => dispatch(PersistedStorageActions.setThemeTone({ theme, tone })),
    closeThemeDialog: () => dispatch(KreoDialogActions.closeDialog(THEMES_DIALOG_NAME)),
  };
};

export const ThemesDialogModal = connect(mapStateTopProps, mapDispatchToProps)(ThemesDialogModalComponent);
