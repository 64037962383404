import { ProjectResourcesApi } from '../../../api/server';
import { ValidationStepStatisticDataPayload } from '../actions/payloads/validation';
import { MeasurementUpdateForm } from '../interfaces/measurements/measurement-update-form';
import { MeasurementValue } from '../interfaces/measurements/measurement-value';
import { MeasurementsResponseTreeNode } from '../interfaces/measurements/measurements-response-tree-node';

function measurementsUrl(
  scenarioId: number,
  url: string = '',
): string {
  return `/scenarios/${scenarioId}/measurements${url}`;
}

function getData(scenarioId: number): Promise<MeasurementsResponseTreeNode[]> {
  return ProjectResourcesApi.get(measurementsUrl(scenarioId));
}

function updateMeasurement(
  scenarioId: number,
  rootId: number,
  activityValues: MeasurementValue[],
): Promise<void> {
  return ProjectResourcesApi.put(
    measurementsUrl(scenarioId, `/${rootId}`),
    activityValues,
  );
}

function bulkUpdateMeasurement(
  scenarioId: number,
  changes: MeasurementUpdateForm[],
): Promise<void> {
  return ProjectResourcesApi.put(measurementsUrl(scenarioId), changes);
}

function getStatistic(
  scenarioId: number,
): Promise<ValidationStepStatisticDataPayload> {
  return ProjectResourcesApi.get(measurementsUrl(scenarioId, '/statistic'));
}

export const MeasurementsApi = {
  getData,
  updateMeasurement,
  bulkUpdateMeasurement,
  getStatistic,
};
