import * as React from 'react';

import { TimeframeAnimationTarget } from '../../../../interfaces/gantt-chart';
import { TimelineCell } from './components/timeline-cell';
import { TimelineCellData } from './timeline-cell-data';
import { timelineDataProvider } from './timeline-data-provider';
import { TimelineScale } from './timeline-scale';

const averageDaysInAMonth = 30.4375;

const hourMs = 60 * 60 * 1000;
const dayMs = 24 * hourMs;

const hourDays = 1 / 24;
const day = 1;
const weekDays = 7;
const monthDays = averageDaysInAMonth;
const quarterDays = 3 * monthDays;
const yearDays = 365;

interface UnitDuration {
  unit: TimelineScale;
  duration: number;
}

const unitDurations: UnitDuration[] = [
  { unit: TimelineScale.Hour, duration: hourDays },
  { unit: TimelineScale.Day, duration: day },
  { unit: TimelineScale.Week, duration: weekDays },
  { unit: TimelineScale.Month, duration: monthDays },
  { unit: TimelineScale.Quarter, duration: quarterDays },
  { unit: TimelineScale.Year, duration: yearDays },
];

interface ChartTimelineProps {
  width: number;
  projectStartDate: Date;
  timeframeStartDay: number;
  /**
   * @description User-selected timeframe duration in days.
   */
  timeframeDuration: number;
  timelineDuration: number;
  triggerTimeframeChangeAnimation: (target: TimeframeAnimationTarget) => void;
}

interface ChartTimelineState {
  frameStartDate: Date;
  frameEndDate: Date;
  scale: TimelineScale;
  pixelsPerDay: number;
  cellsData: TimelineCellData[];
}

export class ChartTimeline extends React.PureComponent<ChartTimelineProps, ChartTimelineState> {
  private static readonly minimalCellWidth: number = 100;

  constructor(props: ChartTimelineProps) {
    super(props);
    this.state = ChartTimeline.getDerivedStateFromProps(props);
  }

  public static getDerivedStateFromProps(
    nextProps: ChartTimelineProps,
  ): ChartTimelineState {
    const frameStartDate = new Date(nextProps.projectStartDate.getTime() + nextProps.timeframeStartDay * dayMs);
    const frameEndDate = new Date(frameStartDate.getTime() + nextProps.timeframeDuration * dayMs);
    const scale = ChartTimeline.determineScale(nextProps.timeframeDuration, nextProps.width);
    const pixelsPerDay = nextProps.width / nextProps.timeframeDuration;
    const cellsData = timelineDataProvider[scale](frameStartDate, frameEndDate);

    return {
      frameStartDate,
      frameEndDate,
      scale,
      pixelsPerDay,
      cellsData,
    };
  }

  public render(): JSX.Element {
    return (
      <div className='chart-timeline'>
        {this.state.cellsData.map((cellData) => {
          return (
            <TimelineCell
              key={cellData.id}
              width={cellData.duration * this.state.pixelsPerDay}
              projectStartDate={this.props.projectStartDate}
              duration={cellData.duration}
              scale={this.state.scale}
              startDate={cellData.start}
              triggerTimeframeChangeAnimation={this.props.triggerTimeframeChangeAnimation}
              timeframeDuration={this.props.timeframeDuration}
              timelineDuration={this.props.timelineDuration}
            />
          );
        })}
      </div>
    );
  }

  private static determineScale(timeframeDuration: number, viewportWidth: number): TimelineScale {
    for (const pair of unitDurations) {
      const possibleCellsCount = Math.floor(timeframeDuration / pair.duration);
      const possibleCellWidth =  viewportWidth / possibleCellsCount;
      if (possibleCellWidth > ChartTimeline.minimalCellWidth) {
        return pair.unit;
      }
    }

    return TimelineScale.Year;
  }
}
