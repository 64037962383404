import * as React from 'react';

import './popup-container.scss';

export const PopupSeparator: React.FC = () => {
  return <div className='validation-properties-popup-separator' />;
};


export const PopupContainer: React.FC = (props) => {
  return (<div className='validation-properties-popup'>{props.children}</div>);
};
