import { useMemo } from 'react';
import { CompanySubscriptionModel } from '../../../../account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../account/selectors';

export const useHideStatus = (
  subscription: CompanySubscriptionModel,
  isArhived: boolean,
  isPlanChanged: boolean,
): [boolean, boolean, boolean] => {
  return useMemo(() => {
    const isPaused = AccountSelectors.isPausedSubscription(subscription);
    const hideCancel = !isPaused;
    const hidePause = true;
    const isSkipSubscription = isPaused || isArhived && !isPlanChanged;
    return [hideCancel, hidePause, isSkipSubscription];
  }, [subscription, isArhived, isPlanChanged]);
};
