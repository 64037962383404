import { Action } from 'redux';

import { DemoProjectType } from 'common/constants/project-type';
import { ActionWith } from 'common/interfaces/action-with';
import { DemoProject } from '../../pages/demo-projects/interfaces/demo-project';
import { DeleteDemoProjectPayload } from '../payloads/demo-projects';
import { AdminDemoProjectsActionTypes } from '../types/demo-projects';

function loadDemoProjects(projectType: DemoProjectType): ActionWith<DemoProjectType> {
  return {
    type: AdminDemoProjectsActionTypes.LOAD_DEMO_PROJECTS,
    payload: projectType,
  };
}

function loadDemoProjectsSucceeded(projects: DemoProject[]): ActionWith<DemoProject[]> {
  return {
    type: AdminDemoProjectsActionTypes.LOAD_DEMO_PROJECTS_SUCCEEDED,
    payload: projects,
  };
}

function deleteDemoProject(type: DemoProjectType, id: number): ActionWith<DeleteDemoProjectPayload> {
  return {
    type: AdminDemoProjectsActionTypes.DELETE_DEMO_PROJECT,
    payload: { type, id },
  };
}

function createDemoProject(): Action {
  return {
    type: AdminDemoProjectsActionTypes.CREATE_DEMO_PROJECT,
  };
}

function updateDemoProject(): Action {
  return {
    type: AdminDemoProjectsActionTypes.UPDATE_DEMO_PROJECT,
  };
}

function dropStore(): Action {
  return {
    type: AdminDemoProjectsActionTypes.DROP_STORE,
  };
}

export const AdminDemoProjectsActions = {
  loadDemoProjects,
  loadDemoProjectsSucceeded,
  createDemoProject,
  deleteDemoProject,
  updateDemoProject,
  dropStore,
};
