import React from 'react';
import { Styled } from './styled';
import { useDownloadImage } from './use-download-image';

type ImgProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

interface Props extends ImgProps {
  WrapComponent?: React.ComponentType<ImgProps>;
  src: string;
}

const ImageComponent: React.FC<Props> = (props) => {
  const image = useDownloadImage(props.src);
  const Component = props.WrapComponent || Styled.Img;
  if (!image) {
    return null;
  }
  return <Component {...props as any} src={image} />;
};

export const AuthImage = React.memo(ImageComponent);
