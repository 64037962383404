import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';

import { RowData, UserInfo } from 'common/components/data-base-table';
import { DataBaseSelectorTable } from 'common/components/database-selector-table';
import { State } from 'common/interfaces/state';
import { mapAssemblies, mapUser } from 'unit-2d-database/helpers';
import { Assembly, Group, Item, User } from 'unit-2d-database/interfaces';

interface StateToProps {
  assembliesDataBase: Assembly[];
  itemsDataBase: Item[];
  groups: Group[];
  users: User[];
}

interface Props extends StateToProps {
  setUsedAssembly: (ids: string[]) => void;
  selectedIdsMap: Record<string, boolean>;
  hideEmptyEntity?: boolean;
  autoGroupColumnMinWidth?: number;
  hideTable?: boolean;
}

interface OwnState {
  rowData: RowData[];
}

class SelectAssemblyPanelComponent extends React.PureComponent<Props, OwnState> {
  private userInfo: UserInfo[] = [];
  public constructor(props: Props) {
    super(props);
    this.state = {
      rowData: [],
    };
  }

  public componentDidMount(): void {
    this.updateRowData();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.users !== prevProps.users) {
      this.updateUsersInfo();
    }

    if (prevProps.assembliesDataBase !== this.props.assembliesDataBase
      || this.props.users !== prevProps.users) {
      this.updateRowData();
    }
  }

  public render(): JSX.Element {
    const { selectedIdsMap, autoGroupColumnMinWidth, hideTable } = this.props;
    return (
    <DataBaseSelectorTable
      rowData={this.state.rowData}
      autoGroupName={'Assembly'}
      onSelect={this.onSelect}
      selectedEntityMap={selectedIdsMap}
      fieldForQuickSearch={[]}
      autoGroupColumnMinWidth={autoGroupColumnMinWidth}
      hideTable={hideTable}
    />);
  }

  @autobind
  private updateUsersInfo(): void {
    this.userInfo = this.props.users.map(mapUser);
  }

  private updateRowData(): void {
    const { groups, itemsDataBase } = this.props;
    const assemblies = this.getAssemblies();
    const { rowData } = mapAssemblies(assemblies, groups, itemsDataBase, this.userInfo, true);
    this.setState({ rowData });
  }

  private getAssemblies(): Assembly[] {
    const { assembliesDataBase, hideEmptyEntity } = this.props;
    return hideEmptyEntity
      ? assembliesDataBase.filter(a => a.items.length)
      : assembliesDataBase;
  }

  @autobind
  private onSelect(ids: string[]): void {
    this.props.setUsedAssembly(ids);
  }
}

const mapStateToProps = (state: State): StateToProps => {
  return {
    assembliesDataBase: state.twoDDatabase.assemblies,
    itemsDataBase: state.twoDDatabase.items,
    groups: state.twoDDatabase.assemblyGroups,
    users: state.people.companiesUsers as User[],
  };
};

export const SelectAssemblyPanel = connect(mapStateToProps)(SelectAssemblyPanelComponent);
