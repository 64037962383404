import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoButton, KreoDialog, KreoDialogActions } from 'common/UIKit';
import { StringUtils } from 'common/utils/string-utils';

import './result-dialog.scss';

interface OwnProps {
  name: string;
  title: string;
  onDone?: () => void;
  children?: React.ReactNode;
}

interface DispatchProps {
  close: (name: string) => void;
}

interface Props extends OwnProps, DispatchProps {

}


class ResultDialogComponent extends React.Component<Props> {

  public render(): React.ReactNode {
    return (
      <KreoDialog
        name={this.props.name}
        title={this.props.title}
        bodyClassName='result-dialog'
      >
        <div className='result-dialog__content'>
          {this.props.children}
        </div>
        <div className='kreo-dialog__actions with-border center-btn'>
          <KreoButton
            mode='submit'
            size='large'
            caption='Done'
            onClick={this.onDone}
            controlName={`${StringUtils.titleCaseToSnakeCase(this.props.title)}-done-button`}
          />
        </div>
      </KreoDialog>
    );
  }

  @autobind
  private onDone(): void {
    this.props.close(this.props.name);
    if (this.props.onDone) {
      this.props.onDone();
    }
  }
}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    close: (name: string) => {
      dispatch(KreoDialogActions.closeDialog(name));
    },
  };
};


export const ResultDialog = connect(null, mapDispatchToProps)(ResultDialogComponent);
