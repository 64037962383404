import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './measurements-extractor-editor-row.scss';

import { MeasurementsActions } from '../../actions/creators/measurements';
import { MeasurementsExtractorItemCheckbox } from '../measurements-extractor-item-checkbox';
import { MeasurementsExtractorItemContainer } from '../measurements-extractor-item-container';

interface DispatchProps {
  toggleCheckStatus: (value: boolean) => void;
}

interface OwnProps {
  index: number;
  isError: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  isGroup?: boolean;
  title: string;
  checkBoxClassName?: string;
  indeterminate?: boolean;
  children?: React.ReactNode;
  onCheck: (value: boolean) => void;
  onDisabledItemClick: () => void;
}

interface Props extends DispatchProps, OwnProps {
}

class MeasurementExtractorEditorRowComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isError, isSelected, isDisabled, isGroup, children, title, checkBoxClassName, indeterminate } = this.props;
    const className = classNames(
      'measurements-extractor-editor-row',
      {
        'measurements-extractor-editor-row--selected': isSelected,
        'measurements-extractor-editor-row--disabled': isDisabled,
        'measurements-extractor-editor-row--group': isGroup,
      },
    );
    const wrappedCheckBoxClassName = classNames(
      {
        'measurements-extractor-editor-row__checkbox--error': isError,
      },
      checkBoxClassName);
    return (
      <MeasurementsExtractorItemContainer
        className={className}
        onClick={this.onItemClick}
      >
        <MeasurementsExtractorItemCheckbox
          /**
           * !!this.props.selected - т.к. если наш-чекбокс получит undefined галочка не обновится
           * если поменят логику чекбокса, то возможны поломки в некоторых частях UI
           */
          checked={isDisabled ? false : !!isSelected}
          label={title}
          onChange={this.onChangeCheckStatusClick}
          className={wrappedCheckBoxClassName}
          disabled={isDisabled}
          indeterminate={indeterminate}
        />
        {children}
      </MeasurementsExtractorItemContainer>
    );
  }


  @autobind
  private onItemClick(): void {
    if (this.props.isDisabled) {
      this.props.onDisabledItemClick();
    }
  }

  @autobind
  private onChangeCheckStatusClick(value: boolean): void {
    this.props.onCheck(value);
    this.props.toggleCheckStatus(value);
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { index }: OwnProps): DispatchProps {
  return {
    toggleCheckStatus: value => dispatch(MeasurementsActions.toggleItemSelectStatus(value, index)),
  };
}

export const MeasurementExtractorEditorRow = connect(null, mapDispatchToProps)(MeasurementExtractorEditorRowComponent);
