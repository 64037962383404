import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './floating-tree-panel.scss';

import { IconButton, KreoIconFilter } from 'common/UIKit';
import { MapIdHelper } from '../../../components/engine/map-id-helper';
import { QtoRecord } from '../../../units/projects/components/quantity-take-off-left-panel/interfaces';
import { FilterPanelState } from '../../../units/projects/components/quantity-take-off-left-table-tab/use-save-state';
import { GraphStorageRecordsConfig } from '../../../units/projects/interfaces/graph-storage-records-config';
import { FloatingTreePanelPopup } from './floating-tree-panel-popup';
import { TreeFilterPanelState } from './interfaces';

export interface FloatingTreePanelProps {
  displayNames: GraphStorageRecordsConfig;
  currentProjectId: number;
  className?: string;
  onFilter: (bimIds: number[], bimIdsWithoutEngineFilter: number[]) => void;
  isLocation: boolean;
  filterName: string;
  records: QtoRecord[];
  isEngineFilterEnabled: boolean;
  mapIdHelper: MapIdHelper;
  saveState: (filterPanelState: TreeFilterPanelState, selectedIds: number[]) => void;
  getSaveState: () => FilterPanelState;
  getReportElementIds: () => { nodeIds: Record<string, boolean>, duplicatedNodeIds: Record<string, boolean> };
  disableEngineFilter: () => void;
  engineFilter: (id: number) => boolean;
}

interface State {
  isOpen: boolean;
  resetFilter: boolean;
  isBadgeActive: boolean;
}

export class FloatingTreePanel extends React.PureComponent<FloatingTreePanelProps, State> {
  public constructor(props: FloatingTreePanelProps) {
    super(props);
    this.state = {
      isOpen: false,
      resetFilter: false,
      isBadgeActive: false,
    };
  }

  public render(): JSX.Element {
    const { isOpen, isBadgeActive, resetFilter } = this.state;
    const { displayNames, currentProjectId, className, isLocation, isEngineFilterEnabled } = this.props;
    return (
      <>
        <IconButton
          size='medium'
          onClick={this.onPanelButton}
          controlName='floating-tree-panel-button'
          className={classNames('floating-tree-panel__button', className)}
          badgeActive={isBadgeActive}
        >
          <KreoIconFilter />
        </IconButton>
        <div
          className={classNames(
            'floating-tree-panel__overlay',
            { 'floating-tree-panel__overlay--open': isOpen },
          )}
          onClick={this.onClose}
        >
          <div
            className='floating-tree-panel__popup-wrap'
            onClick={this.stopPropagation}
          >
            <FloatingTreePanelPopup
              useEngineFilter={isEngineFilterEnabled}
              projectId={currentProjectId}
              onFilter={this.onFilter}
              displayNames={displayNames}
              onClose={this.onClose}
              onClickResetButton={this.onClickResetButton}
              handleResetFilter={this.handleFilterReset}
              resetFilter={resetFilter}
              isLocation={isLocation}
              filterName={this.props.filterName}
              records={this.props.records}
              saveState={this.props.saveState}
              getSaveState={this.props.getSaveState}
              getReportElementIds={this.props.getReportElementIds}
              engineFilter={this.props.engineFilter}
              disableEngineFilter={this.props.disableEngineFilter}
              mapIdHelper={this.props.mapIdHelper}
            />
          </div>
        </div>
      </>
    );
  }

  public componentDidMount(): void {
    this.setBadgeActiveFromLocalStorage();
  }

  @autobind
  private setBadgeActiveFromLocalStorage(): void {
    const state = this.props.getSaveState();
    if (state) {
      this.setState({
        isBadgeActive: Object.keys(state.selectedDisplayName).length !== 0,
      });
    }
  }

  private stopPropagation(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }

  @autobind
  private onFilter(bimIds: number[], bimIdsWithoutEngineFilter: number[], value?: boolean): void {
    this.setState({ isBadgeActive: value });
    this.props.onFilter(bimIds, bimIdsWithoutEngineFilter);
  }

  @autobind
  private onClickResetButton(): void {
    this.setState({ resetFilter: true }, this.props.disableEngineFilter);
  }

  @autobind
  private handleFilterReset(value: boolean): void {
    this.setState({ resetFilter: value });
  }

  @autobind
  private onPanelButton(): void {
    this.setState({ isOpen: true });
  }

  @autobind
  private onClose(): void {
    this.setState({ isOpen: false });
  }
}
