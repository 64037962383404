import autobind from 'autobind-decorator';
import { Communicator, CommunicatorMessage } from './communicator';

export interface BroadcastConfig {
  chanelName: string;
  messageReceiver: (message: CommunicatorMessage) => void;
}

export class BroadcastCommunicator extends Communicator<BroadcastConfig> {
  private channel: BroadcastChannel = null;
  private messageReceiver: (message: CommunicatorMessage) => void = null;

  public createChannel(config: BroadcastConfig): void {
    this.channel = new BroadcastChannel(config.chanelName);
    this.messageReceiver = config.messageReceiver;
  }

  public sendMessage(message: CommunicatorMessage): void {
    try {
      this.channel.postMessage(message);
    } catch (error) {
      console.error(`Ups ${error} ${JSON.stringify(message)}`);
    }
  }

  public setMessageReceiver(): void {
    this.channel.onmessage = this.onMessage;
  }
  public closeChannel(): void {
    this.channel.close();
  }

  @autobind
  private onMessage(event: MessageEvent): void {
    const message: CommunicatorMessage = event.data;
    this.messageReceiver(message);
  }

}
