import {
  IconButton,
  Icons,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { DrawingMarkShapes, DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import {
  DrawingsSubmenuContainer,
} from 'common/components/drawings/drawings-canvas-menus/drawings-flying-menu/submenu-containers';
import {
  DrawingsStyleMenuType,
  DrawingsStylesMenu,
} from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { InteractiveMenu } from 'common/components/interactive-menu';
import { ColorControl } from './color-control';
import { Styled } from './styled';

interface Props {
  color: string;
  setColor: (color: string) => void;
  setStrokeStyle: (value: DrawingStrokeStyles) => void;
  strokeWidth: number;
  setStrokeWidth: (value: number) => void;
  setShape: (shape: DrawingMarkShapes) => void;
  shape: DrawingMarkShapes;
  strokeStyle: DrawingStrokeStyles;
  menuType: DrawingsStyleMenuType;
}

export class InteractiveStylesMenu extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <InteractiveMenu
        renderMenuContent={this.renderMenu}
        closeOnContainerClick={false}
      >
        <Styled.NewDrawingColor newDrawingColor={this.props.color}>
          <IconButton Icon={Icons.CircleFilled} height={20} />
        </Styled.NewDrawingColor>
      </InteractiveMenu>
    );
  }

  @autobind
  private renderMenu(): React.ReactNode {
    return (
      <DrawingsSubmenuContainer>
        <DrawingsStylesMenu
          customColorControl={ColorControl}
          menuType={this.props.menuType}
          color={this.props.color}
          setColor={this.props.setColor}
          colorTitle={'Colour'}
          setStrokeStyle={this.props.setStrokeStyle}
          strokeStyle={this.props.strokeStyle}
          setStrokeWidth={this.props.setStrokeWidth}
          strokeWidth={this.props.strokeWidth}
          shape={this.props.shape}
          setShape={this.props.setShape}
        />
      </DrawingsSubmenuContainer>
    );
  }
}
