import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './database-activity-table.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RequestStatus } from 'common/enums/request-status';
import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { State as ReduxState } from 'common/interfaces/state';
import { DatabaseActivityActions } from '../../actions/creators/database-activity';
import { DatabaseActivityListingActions } from '../../actions/creators/database-activity-listing';
import { DatabaseActivityVariantActions } from '../../actions/creators/database-activity-variant';
import { ActivityCategoryType } from '../../enums';
import { ActivitiesRequestFilterModel, ActivityCategory, DatabaseTableRootModel } from '../../interfaces/data';
import { DatabaseTable } from '../database-table';
import {
  DatabaseTableColumn,
  DatabaseTableRootMenuItem,
  DatabaseTableVariantMenuItem,
} from '../database-table/interfaces';
import { DatabaseActivityTableColumnFilterPropsProvider } from './database-activity-table-column-filter-props-provider';


interface ReduxProps {
  selectedCategory: ActivityCategory;
  selectedCategoryType: ActivityCategoryType;
  activitiesLoadStatus: RequestStatus;
  isReadOnly: boolean;
  activities: DatabaseTableRootModel[];
  totalCount: number;
  selectedActivityVariantIds: number[];
  disabledActivityVariantIds: number[];
}

interface ReduxActions {
  selectActivityVariant: (activityId: number, variantId: number, value: boolean) => void;
  removeActivityVariant: (databaseId: number, activityId: number, activityVariantId: number) => void;
  removeActivity: (databaseId: number, activityId: number) => void;
  duplicateActivityVariant: (databaseId: number, activityId: number, activityVariantId: number) => void;
  duplicateActivity: (databaseId: number, activityId: number) => void;
  loadActivities: (databaseId: number, filter: ActivitiesRequestFilterModel) => void;
  resetLoadActivitiesRequestStatus: () => void;

  openCreateActivity: () => void;
  openCreateActivityVariant: (activityId: number) => void;
  openEditActivity: (activityId: number) => void;
  openEditActivityVariant: (activityId: number, variantId: number) => void;
}

interface Props extends ReduxProps, ReduxActions, AbilityAwareProps, I18nAwareProps {
  databaseId: number;
  isCheckboxesVisible: boolean;
}

class DatabaseActivityTableComponent extends React.Component<Props> {
  private filterPropsProvider: DatabaseActivityTableColumnFilterPropsProvider;
  private materialColumnName: string = 'Material';
  private plantColumnName: string = 'Plant';
  private labourColumnName: string = 'Labour';


  constructor(props: Props) {
    super(props);
    this.state = {
      shouldReloadActivities: false,
    };

    this.filterPropsProvider = new DatabaseActivityTableColumnFilterPropsProvider(props.databaseId);
  }

  public componentDidUpdate(prevProps: Props): void {
    const isDatabaseChanged = prevProps.databaseId !== this.props.databaseId;
    const isSelectedCategoryChanged = prevProps.selectedCategory !== this.props.selectedCategory;

    if (isDatabaseChanged || isSelectedCategoryChanged) {
      this.props.resetLoadActivitiesRequestStatus();
    }

    if (isDatabaseChanged) {
      this.filterPropsProvider = new DatabaseActivityTableColumnFilterPropsProvider(this.props.databaseId);
    }
  }

  public render(): JSX.Element {
    const canCreate = !this.props.isReadOnly && this.props.ability.can(Operation.Create, Subject.Database);
    const openCreateActivity = canCreate ? this.props.openCreateActivity : null;
    const openCreateActivityVariant = canCreate ? this.props.openCreateActivityVariant : null;

    const selectActivityVariant = this.props.isCheckboxesVisible ? this.props.selectActivityVariant : null;
    const title = this.props.selectedCategory ? this.props.selectedCategory.title : 'Category Not Selected';

    return (
      <DatabaseTable
        entityName='Activity'
        title={title}
        items={this.props.activities}
        loadStatus={this.props.activitiesLoadStatus}
        columns={this.getColumns()}
        rootMenuItems={this.getActivityMenuItems()}
        getVariantMenuItems={this.getActivityVariantMenuItems}

        disabledRootIds={[]}
        selectedRootIds={[]}
        disabledVariantIds={this.props.disabledActivityVariantIds}
        selectedVariantIds={this.props.selectedActivityVariantIds}

        loadItems={this.loadActivities}
        openCreateRoot={openCreateActivity}
        openCreateVariant={openCreateActivityVariant}
        openEditRoot={this.props.openEditActivity}
        openEditVariant={this.props.openEditActivityVariant}
        selectRoot={null}
        selectVariant={selectActivityVariant}

        totalItemsCount={this.props.totalCount}
        batch={25}
        bufferItemCounts={5}
      />
    );
  }

  private getColumns(): Array<DatabaseTableColumn<any>> {
    const currencySymbol = this.props.i18n.currency.symbol;
    return [
      {
        name: 'Name',
        key: 'name',
        className: 'database-table__column--name',
      },
      {
        name: 'Unit',
        key: 'unit',
        className: 'database-table__column database-table__column--unit',
      },
      {
        name: 'G.H.',
        key: 'crewHours',
        className: 'database-table__column database-table__column--ghours',
      },
      {
        name: this.labourColumnName,
        key: 'laborCost',
        subText: currencySymbol,
        className: 'database-table__column database-table__column--labor',
        filterProps: this.filterPropsProvider.laborFilterProps,
      },
      {
        name: this.plantColumnName,
        key: 'plantCost',
        subText: currencySymbol,
        className: 'database-table__column database-table__column--plant',
        filterProps: this.filterPropsProvider.plantFilterProps,
      },
      {
        name: this.materialColumnName,
        key: 'materialCost',
        subText: currencySymbol,
        className: 'database-table__column database-table__column--material',
        filterProps: this.filterPropsProvider.materialFilterProps,
      },
      {
        name: 'Total',
        key: 'totalCost',
        subText: currencySymbol,
        className: 'database-table__column database-table__column--total',
      },
    ];
  }

  @autobind
  private getActivityVariantMenuItems(activity: DatabaseTableRootModel): DatabaseTableVariantMenuItem[] | null {
    if (this.props.isReadOnly || !this.props.ability.can(Operation.Create, Subject.Database)) {
      return null;
    }

    return [
      {
        name: 'Edit',
        action: this.props.openEditActivityVariant,
      },
      {
        name: 'Duplicate',
        action: this.duplicateActivityVariant,
      },
      {
        name: 'Delete',
        action: activity.variants.length > 1 ? this.removeActivityVariant : null,
      },
    ];
  }

  private getActivityMenuItems(): DatabaseTableRootMenuItem[] | null {
    if (this.props.isReadOnly || !this.props.ability.can(Operation.Create, Subject.Database)) {
      return null;
    }

    return [
      {
        name: 'Edit',
        action: this.props.openEditActivity,
      },
      {
        name: 'Duplicate',
        action: this.duplicateActivity,
      },
      {
        name: 'Delete',
        action: this.removeActivity,
      },
    ];
  }

  private getFilter(
    skip: number, take: number, search: string, filters: Record<string, string[]>,
  ): ActivitiesRequestFilterModel {
    const selectedCategoryCode = this.props.selectedCategory && this.props.selectedCategory.code;
    return {
      skip,
      take,
      search,
      nrm1: this.props.selectedCategoryType === ActivityCategoryType.Nrm1 ? selectedCategoryCode : null,
      nrm2: this.props.selectedCategoryType === ActivityCategoryType.Nrm2 ? selectedCategoryCode : null,
      labours: filters[this.labourColumnName],
      plants: filters[this.plantColumnName],
      materials: filters[this.materialColumnName],
    };
  }

  @autobind
  private loadActivities(skip: number, take: number, search: string, filters: Record<string, string[]>): void {
    this.props.loadActivities(this.props.databaseId, this.getFilter(skip, take, search, filters));
  }

  @autobind
  private removeActivityVariant(activityId: number, variantId: number): void {
    this.props.removeActivityVariant(this.props.databaseId, activityId, variantId);
  }

  @autobind
  private removeActivity(activityId: number): void {
    this.props.removeActivity(this.props.databaseId, activityId);
  }

  @autobind
  private duplicateActivityVariant(activityId: number, activityVariantId: number): void {
    this.props.duplicateActivityVariant(this.props.databaseId, activityId, activityVariantId);
  }

  @autobind
  private duplicateActivity(activityId: number): void {
    this.props.duplicateActivity(this.props.databaseId, activityId);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const activityListing = state.database.currentDatabase.activityListing;
  return {
    activities: activityListing.activities,
    selectedCategory: activityListing.categories.selectedCategory,
    selectedCategoryType: activityListing.categories.selectedCategoryType,
    activitiesLoadStatus: state.database.statuses.activityListing,
    isReadOnly: state.database.currentDatabase ? state.database.currentDatabase.database.isReadOnly : false,
    selectedActivityVariantIds: activityListing.selectedActivityVariantIds,
    disabledActivityVariantIds: activityListing.disabledActivityVariantIds,
    totalCount: activityListing.totalCount,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    selectActivityVariant: (activityId, variantId, value) =>
      dispatch(DatabaseActivityListingActions.selectActivityVariant(activityId, variantId, value)),
    removeActivityVariant: (databaseId, activityId, activityVariantId) =>
      dispatch(DatabaseActivityVariantActions.deleteActivityVariant(databaseId, activityId, activityVariantId)),
    removeActivity: (databaseId, activityId) =>
      dispatch(DatabaseActivityActions.deleteActivity(databaseId, activityId)),
    duplicateActivityVariant: (databaseId, activityId, activityVariantId) =>
      dispatch(DatabaseActivityVariantActions.duplicateActivityVariant(databaseId, activityId, activityVariantId)),
    duplicateActivity: (databaseId, activityId) =>
      dispatch(DatabaseActivityActions.duplicateActivity(databaseId, activityId)),
    loadActivities: (databaseId, filter) => dispatch(DatabaseActivityActions.loadActivities(databaseId, filter)),
    openCreateActivity: () => dispatch(DatabaseActivityListingActions.openCreateActivity()),
    openCreateActivityVariant: (activityId) =>
      dispatch(DatabaseActivityListingActions.openCreateActivityVariant(activityId)),
    openEditActivity: (activityId) => dispatch(DatabaseActivityListingActions.openEditActivity(activityId)),
    openEditActivityVariant: (activityId, variantId) =>
      dispatch(DatabaseActivityListingActions.openEditActivityVariant(activityId, variantId)),
    resetLoadActivitiesRequestStatus: () =>
      dispatch(DatabaseActivityListingActions.resetLoadActivitiesRequestStatus()),
  };
};

export const DatabaseActivityTable = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(withI18n(DatabaseActivityTableComponent)),
);
