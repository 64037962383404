import React from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
} from 'react-dnd';
import { DndItem } from 'common/enums/dnd-item';

import './selected-activities.scss';

import { KreoButton, KreoIconDragAndDrop } from 'common/UIKit';


export interface DndProps {
  connectDragSource: ConnectDragSource;
  connectDragPreview: ConnectDragPreview;
  isDragging: boolean;
}

interface OwnProps {
  selectedActivitiesCount: number;
  onCancel: () => void;
}

interface Props extends OwnProps, DndProps {}

class SelectedActivitiesComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    return this.props.connectDragPreview ((
      <div
        className='activities-area-selected-activities'
        style={{
          opacity: this.props.isDragging ? 0.4 : 1,
        }}
      >
        {this.props.connectDragSource(
          <span className='activities-area-selected-activities__draggable-indicator'>
            <KreoIconDragAndDrop/>
          </span>,
        )}
        <b className='activities-area-selected-activities__title'>
          {this.getTitle()}
        </b>
        <KreoButton size='medium' onClick={this.props.onCancel}>Cancel</KreoButton>
      </div>
    ));
  }

  private getTitle(): string {
    const count = this.props.selectedActivitiesCount;
    return `${count} Activit${(count === 1 ? 'y' : 'ies')} selected`;
  }
}

const sourceSpec: DragSourceSpec<OwnProps> = {
  beginDrag: (props: OwnProps): OwnProps => {
    return props;
  },
};

const sourceCollect =
  (conn: DragSourceConnector, monitor: DragSourceMonitor): DndProps => {
    return {
      connectDragSource: conn.dragSource(),
      isDragging: monitor.isDragging(),
      connectDragPreview: conn.dragPreview(),
    };
  };

const dragSource =  DragSource(DndItem.SelectedActivities, sourceSpec, sourceCollect);
export const SelectedActivities = dragSource(SelectedActivitiesComponent);
