import styled from 'styled-components';

const Container = styled.div`
  padding: 20px 25px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

export const Styled = {
  Container,
};
