import { CostField, CostFieldNames } from '../../constants';
import {
  CostsTreeNode,
} from '../../units/projects/interfaces/bid-pricing/costs-tree-node';
import {
  CostsTreeNodeVm,
} from '../../units/projects/interfaces/bid-pricing/costs-tree-node-vm';

function isExpandable(item: CostsTreeNodeVm): boolean {
  const ln = item.levelOfNesting;
  if (ln === 0) {
    return false;
  } else if (ln === 1) {
    return true;
  }
  return item.childrens !== undefined && item.childrens.length > 0;
}

/**
 * prepare item
 *
 * @param {*} item
 * @param {*} index
 * @param {*} parentAccessor
 */
function processItem(
  item: CostsTreeNodeVm,
  index: number,
  parentAccessor: string,
): CostsTreeNode {

  const processedItem: CostsTreeNode = {
    ...item,
    expandable: isExpandable(item),
    expanded: false,
    identificator: parentAccessor ? `${parentAccessor}.childrens.${index}` : `${index}`,
    processed: true,
    childrenProcessed: false,
  };

  return processedItem;
}

/**
 * prepare items before saving to redux
 *
 * @param {*} costs
 * @param {*} parentIdentificator
 */
export function processCost(
  costs: CostsTreeNodeVm[],
  parentIdentificator?: string,
): CostsTreeNode[] {
  return costs.map((value, index) => {
    return processItem(value, index, parentIdentificator);
  });
}


export function buildUpdatePath(value: any, path: string[]): { value: any, path: string[] } {
  return {
    value,
    path,
  };
}


function getSourceType(field: any): any {
  const lower = field.toLowerCase();
  if (lower.match('extra') !== null) {
    return CostField.OVERHEAD;
  } else if (lower.match(CostFieldNames.OVERHEAD_PERCENTAGE.toLowerCase()) !== null) {
    return CostField.OVERHEAD_PERC;
  } else if (lower.match('per') !== null) {
    return CostField.RATE;
  } else if (lower.match('dur') !== null) {
    return CostField.DURATION;
  } else {
    return CostField.TOTAL;
  }
}

function getRateName(field: any): any {
  const lower = field.toLowerCase();
  if (lower.match('labor') !== null) {
    return CostFieldNames.LABOR_RATE;
  } else if (lower.match('plant') !== null) {
    return CostFieldNames.PLANT_RATE;
  } else if (lower.match('material') !== null) {
    return CostFieldNames.MATERIAL_RATE;
  } else if (lower.match('direct') !== null) {
    return CostFieldNames.DIRECT_RATE;
  }
}

const getFieldPath = (rowPath: string[], fieldName: string): string[] => {
  const path = rowPath.slice();
  path.push(fieldName);
  return path;
};

const handleOverheadChange = (
  costs: any,
  overheadPath: string[],
  value: number,
  push: (pair: { value: any, path: string[] }) => void,
): void => {
  push({ value, path: overheadPath });
  const rowPath = overheadPath.slice(0, overheadPath.length - 1);
  const totalCostPath = getFieldPath(rowPath, CostFieldNames.TOTAL_COST);
  const directCostPath = getFieldPath(rowPath, CostFieldNames.DIRECT_COST);
  const directCostValue: number = costs.getIn(directCostPath);
  const newOverheadCost = value;
  const newTotalCost: number = value +  directCostValue;
  push({ value: newTotalCost, path: totalCostPath });
  push({ value: newOverheadCost, path: getFieldPath(rowPath, CostFieldNames.EXTRA_PAYMENT) });
};

function getCostFieldName(rateFieldName: string): string {
  const lower = rateFieldName.toLowerCase();
  if (lower.match('labor') !== null) {
    return CostFieldNames.LABOR_COST;
  } else if (lower.match('plant') !== null) {
    return CostFieldNames.PLANT_COST;
  } else if (lower.match('material') !== null) {
    return CostFieldNames.MATERIAL_COST;
  }
}

const handleCostChange = (
  costs: any,
  fieldPath: string[],
  fieldName: string,
  sourceValue: number,
  newValue: number,
  push: (pair: { value: any, path: string[] }) => void,
): void => {
  const delta = newValue - sourceValue;
  const rowPath = fieldPath.slice(0, fieldPath.length - 1);
  const row = costs.getIn(rowPath).toJS();
  const quantity = row[CostFieldNames.QUANTITY];
  const accordingRateFieldName = getRateName(fieldName);

  row[fieldName] = newValue;
  row[accordingRateFieldName] = newValue / quantity;
  row[CostFieldNames.DIRECT_COST] += delta;
  row[CostFieldNames.DIRECT_RATE] = row[CostFieldNames.DIRECT_COST] / quantity;
  row[CostFieldNames.TOTAL_COST] += delta;
  push({ value: row, path: rowPath });

  const packageRowPath = rowPath.slice(0, rowPath.length - 2);
  const packageRow = costs.getIn(packageRowPath).toJS();

  const packageOverhead = packageRow[CostFieldNames.EXTRA_PAYMENT] || 0;

  packageRow[fieldName] += delta;
  packageRow[CostFieldNames.DIRECT_COST] += delta;
  packageRow[CostFieldNames.EXTRA_PAYMENT] = packageOverhead;
  packageRow[CostFieldNames.TOTAL_COST] =
    packageRow[CostFieldNames.DIRECT_COST] +
    packageRow[CostFieldNames.EXTRA_PAYMENT];
  push({ value: packageRow, path: packageRowPath });
};

const handleRateChange = (
  costs: any,
  fieldPath: string[],
  newRateValue: number,
  rateFieldName: string,
  push: (pair: { value: any, path: string[] }) => void,
): void => {
  const accordingCostFieldName = getCostFieldName(rateFieldName);
  const rowPath = fieldPath.slice(0, fieldPath.length - 1);
  const quantity: number = costs.getIn(getFieldPath(rowPath, CostFieldNames.QUANTITY));
  const accordingCostPath = getFieldPath(rowPath, accordingCostFieldName);
  const oldCostValue: number = costs.getIn(accordingCostPath);
  const newCostValue = newRateValue * quantity;
  handleCostChange(costs, accordingCostPath, accordingCostFieldName, oldCostValue, newCostValue, push);
};

export function recalc(
  costs: any,
  path: string[],
  value: any,
  push: any,
): void {
  let sourceValue = costs.getIn(path);
  const sourceField = path[path.length - 1];
  if (value === undefined || isNaN(value)) {
    value = 0;
  }

  if (sourceValue === undefined || isNaN(sourceValue)) {
    sourceValue = 0;
  }

  switch (getSourceType(sourceField)) {
    case CostField.RATE:
      handleRateChange(costs, path, value, sourceField, push);
      break;
    case CostField.TOTAL:
      handleCostChange(costs, path, sourceField, sourceValue, value, push);
      break;
    case CostField.OVERHEAD_PERC:
      handleOverheadChange(costs, path, value, push);
      break;
    case CostField.DURATION:
      push({ value, path });
      break;
    default:
      throw new Error('Unexpected field updated');
  }
}
