import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PdfLoader } from 'common/components/drawings/helpers';
import { loadPageAsDocument } from 'common/components/drawings/helpers/pdf-current-doc-getter';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { DrawingsFile, DrawingsFiles } from 'common/components/drawings/interfaces/drawings-file-info';
import { State } from 'common/interfaces/state';
import { ComparableDocumentType } from 'common/pdf/compare-document';

export function useDrawing(
  drawing: DrawingsShortInfo,
  doc: Core.Document | ComparableDocumentType,
): Core.Document | ComparableDocumentType {
  const [currentDoc, setCurrentDoc] = useState<Core.Document | ComparableDocumentType>(doc);
  const fileSystem = useSelector<State, DrawingsFiles>(x => x.drawings.files);
  useEffect(() => {
    if (!currentDoc) {
      const loader = new PdfLoader();
      loadPageAsDocument(loader, drawing, fileSystem.entities as Record<string, DrawingsFile>).then((document) => {
        setCurrentDoc(document);
      });
      setCurrentDoc(doc);
    }
  }, [currentDoc]);

  return currentDoc;
}
