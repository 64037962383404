import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { VideoUrl } from 'common/constants/video-url';
import { FaqCaption } from 'common/enums/faq-caption';
import { HeaderContext } from 'common/enums/header-context';
import { PageHeader } from 'common/interfaces/page-header';
import { State } from 'common/interfaces/state';
import { PlanProjectRouteParams } from 'routes/plan';
import { RevisionsActions } from 'unit-cost-estimate/actions/creators';
import { RevisionInfoModel } from 'unit-cost-estimate/interfaces';
import { PublishedReportsPage } from 'unit-cost-estimate/pages/published-reports';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { Project } from 'unit-projects/interfaces/project';
import { ModelCheckPage, ModelCheckRoutes } from 'unit-projects/pages/model-check';
import { ModelManagementPage } from 'unit-projects/pages/model-management';
import { QuantityTakeOffPage } from 'unit-projects/pages/quantity-take-off/page';
import { PageLayout } from '../../../layouts/page-layout';
import { NotFoundPage } from '../../../units/notfound/page';
import { PersistedStorageActions } from '../../../units/persisted-storage/actions/creators';
import { ViewerPage } from '../../../units/viewer';
import { Urls } from '../urls';
import { InformationModelingRoutes } from './information-modeling';
import { RevisionRoutes } from './revision';


interface RoutesStateProps {
  currentProject: Project | null;
  isValidationCompleted: boolean;
  projectLoaded: boolean;
  revisionInfo: RevisionInfoModel[];
  selectedRevisionId: number;
}

interface RoutesDispatchProps {
  changeProjectName: (name: string) => void;
  setCurrentProject: (projectId: number) => void;
  dropCurrentProjectInfo: () => void;
  fetchProjectRevisionsInfo: () => void;
  fetchRevisionState: (revisionId: number) => void;
  setProjectRevisionId: (projectId: number, revisionId: number) => void;
}

interface RoutesProps
  extends RoutesStateProps,
    RoutesDispatchProps,
    RouteComponentProps<PlanProjectRouteParams>,
    AbilityAwareProps {}

const SpinnerPage: React.FC = () => <SvgSpinner size='large' />;

class ProjectRoutesComponent extends React.Component<RoutesProps> {
  public componentDidMount(): void {
    this.fetchProjectIfNeeded();
    this.fetchRevisionIfNeeded();
  }

  public componentDidUpdate(prevProps: RoutesProps): void {
    this.fetchProjectIfNeeded();
    this.fetchRevisionIfNeeded(prevProps);
  }

  public render(): React.ReactNode {
    const { match, projectLoaded, revisionInfo } = this.props;

    if (!projectLoaded || !revisionInfo) {
      return <Route component={SpinnerPage} />;
    }

    const indexProjectPageUrl = Urls.project.index.url(match.params);

    return (
      <Switch>
        <Redirect exact={true} path={Urls.project.index.path} to={this.getIndexRedirectUrl()} />
        <Route path={Urls.project.informationModeling.index.path} component={InformationModelingRoutes}/>
        <Route path={Urls.project.revision.index.path} component={RevisionRoutes}/>
        <PageLayout
          exact={true}
          metaTitle='Collaboration'
          path={Urls.project.viewer.path}
          component={ViewerPage}
          header={this.getProjectHeader()}
          subject={Subject.Viewer}
          redirectUrl={indexProjectPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle='Quantity Take Off'
          path={Urls.project.quantityTakeOff.path}
          backgroundColor='white'
          component={QuantityTakeOffPage}
          header={this.getProjectHeader(FaqCaption.QuantityTakeOff, VideoUrl.fullDemo)}
          subject={Subject.QuantityTakeOff3d}
          redirectUrl={indexProjectPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle='Published Reports'
          path={Urls.project.publishedReports.path}
          backgroundColor='white'
          component={PublishedReportsPage}
          header={this.getProjectHeader(FaqCaption.QuantityTakeOff, VideoUrl.fullDemo)}
          subject={Subject.CostEstimate}
          redirectUrl={indexProjectPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle='Model Management'
          path={Urls.project.modelManagement.path}
          backgroundColor='white'
          component={ModelManagementPage}
          header={this.getProjectHeader()}
          subject={Subject.ModelManagement}
          redirectUrl={indexProjectPageUrl}
        />
        <Route
          path={Urls.project.modelCheck.index.path}
          component={ModelCheckRoutes}
        />
        <PageLayout
          exact={true}
          metaTitle='Model Check'
          path={Urls.project.modelCheck.index.path}
          backgroundColor='white'
          subject={Subject.ValidationModelCheck}
          component={ModelCheckPage}
          redirectUrl={Urls.project.modelCheck.index.path}
          header={this.getProjectHeader()}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  public componentWillUnmount(): void {
    this.props.dropCurrentProjectInfo();
  }

  private fetchProjectIfNeeded(): void {
    const parsedProjectId = parseInt(this.props.match.params.projectId, 10);
    if (
      !isNaN(parsedProjectId) &&
      (!this.props.currentProject || this.props.currentProject.id !== parsedProjectId)
    ) {
      this.props.setCurrentProject(parsedProjectId);
    }
  }

  private fetchRevisionIfNeeded(prevProps?: RoutesProps): void {
    const { selectedRevisionId, revisionInfo } = this.props;
    const currentProjectId = this.props.currentProject && this.props.currentProject.id;
    const previousProjectId = prevProps && prevProps.currentProject && prevProps.currentProject.id;
    if (currentProjectId && currentProjectId !== previousProjectId) {
      this.props.fetchProjectRevisionsInfo();
      if (selectedRevisionId) {
        this.props.fetchRevisionState(selectedRevisionId);
      }
    }

    if (!selectedRevisionId && revisionInfo && revisionInfo.length) {
      const lastRevision = revisionInfo[revisionInfo.length - 1];
      this.props.setProjectRevisionId(currentProjectId, lastRevision.id);
      this.props.fetchRevisionState(lastRevision.id);
    }
  }

  private getIndexRedirectUrl(): string {
    const { revisionInfo, match, selectedRevisionId } = this.props;
    return revisionInfo && revisionInfo.length && selectedRevisionId
      ? Urls.project.revision.index.url({ ...match.params, revisionId: selectedRevisionId.toString() })
      : Urls.project.informationModeling.index.url(match.params);
  }

  private getProjectHeader(faqCaption?: FaqCaption, videoUrl?: string): PageHeader {
    return {
      context: HeaderContext.Project,
      backUrl: Urls.listing.url(),
      title: this.props.currentProject ? this.props.currentProject.name : '',
      faqCaption,
      videoUrl,
      onEditTitle: this.props.ability.can(Operation.Update, Subject.Project)
        ? this.props.changeProjectName
        : null,
      showLeftMenu: true,
    };
  }
}

function mapStateToProps(state: State, ownProps: RoutesProps): RoutesStateProps {
  const currentProject = state.projects.currentProject;
  const isValidationCompleted = currentProject ? currentProject.isValidationCompleted : false;
  const projectLoaded =
    !!currentProject &&
    currentProject.id === parseInt(ownProps.match.params.projectId, 10) &&
    !!state.account.selectedCompany &&
    currentProject.companyId === state.account.selectedCompany.id;
  const selectedRevisionId = currentProject && state.persistedStorage.projectIdToRevisionId
    && state.persistedStorage.projectIdToRevisionId[currentProject.id];

  return {
    currentProject,
    isValidationCompleted,
    projectLoaded,
    revisionInfo: currentProject && currentProject.revisions,
    selectedRevisionId,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): RoutesDispatchProps {
  return {
    fetchProjectRevisionsInfo: () => dispatch(RevisionsActions.fetchProjectRevisionsInfo()),
    fetchRevisionState: revisionId => dispatch(RevisionsActions.fetchRevisionsState(revisionId)),
    changeProjectName: (name: string) => dispatch(ProjectsActions.updateProjectName(name)),
    setCurrentProject: id => dispatch(ProjectsActions.setCurrentProject(id)),
    dropCurrentProjectInfo: () => dispatch(ProjectsActions.dropCurrentProjectInfo()),
    setProjectRevisionId: (projectId, revisionId) =>
      dispatch(PersistedStorageActions.persistSelectedProjectRevisionId(projectId, revisionId)),
  };
}

export const ProjectRoutes = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProjectRoutesComponent),
);
