import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconMenuExpanded = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <g
      id='Icons/24x24/Menu_Expanded'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M8,8 L16,16' id='Rectangle' stroke={KreoColors.f4} />
      <path d='M16,8 L8,16' id='Rectangle' stroke={KreoColors.f4} />
    </g>
  </svg>
);
