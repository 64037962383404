import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseResourceActions } from '../../actions/creators/database-resource';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { ModifyDatabaseEntityMode, ResourceType } from '../../enums';
import { LaborModel } from '../../interfaces/resources-data';
import { LaborForm } from '../../interfaces/resources-rest-data';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';
import { DatabaseResourceLabor } from './database-resource-labor';

interface ReduxProps {
  resource: LaborModel;
  databaseId: number;
}

interface ReduxActions {
  setEditResourceModel: (variant: LaborModel) => void;
  createDatabaseResource: (databaseId: number, type: ResourceType, model: LaborForm, formId: string) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps {}

class CreateDatabaseResourceLaborComponent extends React.Component<Props> {
  private readonly formId: string = 'create-labor';

  public componentDidMount(): void {
    this.props.setEditResourceModel(this.getDefaultResourceModel());
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Labour'
        isApplyButtonDisabled={this.isCreateButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Create}
        readonly={this.props.readonly}
        onSubmit={this.create}
        form={this.formId}
        initialValues={this.getDefaultResourceModel()}
      >
      {
        this.props.resource ? (
          <KreoScrollbars>
            <DatabaseResourceLabor
              resource={this.props.resource}
              onChange={this.props.setEditResourceModel}
              readonly={this.props.readonly}
            />
          </KreoScrollbars>
        ) : <SvgSpinner size='middle'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }


  @autobind
  private create(values: LaborForm): void {
    this.props.createDatabaseResource(this.props.databaseId, ResourceType.Labour, values, this.formId);
  }

  private isCreateButtonDisabled(): boolean {
    const { resource } = this.props;
    return !resource ||
      !resource.name ||
      !resource.name.trim();
  }

  private getDefaultResourceModel(): LaborModel {
    return {
      id: 0,
      name: '',
      variants: [],
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    resource: editModel.root,
    databaseId: state.database.currentDatabase.database.id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditResourceModel: variant =>
      dispatch(DatabaseResourceListingActions.setEditResourceModel(variant)),
    createDatabaseResource: (databaseId, type, model, formId) =>
      dispatch(DatabaseResourceActions.createResource(databaseId, type, model, formId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const CreateDatabaseResourceLabor =  connector(CreateDatabaseResourceLaborComponent);
