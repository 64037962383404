import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { SubscriptionType } from 'common/constants/subscription';
import { State } from 'common/interfaces/state';
import { KreoButton, MaterialInput, MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { AdminCompaniesActions } from '../../actions/creators/companies';
import { SubscriptionPlanOption } from '../../interfaces/subscription-plan-option';

interface OwnProps {
  companyId: number;
  subscriptionType: SubscriptionType;
}

interface StateProps {
  planId: string | null;
  planQuantity: number | null;
  subscriptionPlanOptions: SubscriptionPlanOption[];
}

interface DispatchProps {
  setMockSubscription(planId: string, planQuantity: number): void;
  resetMockSubscription(): void;
}

interface ControlProps extends OwnProps, StateProps, DispatchProps { }

interface ControlState {
  planId: string | null;
  planQuantity: number | null;
  hasUnsavedChanges: boolean;
}

class CompanyMockSubscriptionControlItemComponent extends React.Component<ControlProps, ControlState> {
  constructor(props: ControlProps) {
    super(props);

    this.state = CompanyMockSubscriptionControlItemComponent.getDerivedStateFromProps(props, null) as ControlState;
  }

  public static getDerivedStateFromProps(props: ControlProps, state: ControlState): Partial<ControlState> | null {
    const { planId: planIdFromProps, planQuantity: planQuantityFromProps } = props;

    if (state === null) {
      return {
        planId: planIdFromProps,
        planQuantity: planQuantityFromProps,
        hasUnsavedChanges: false,
      };
    }

    if (!state.hasUnsavedChanges) {
      const updates: Partial<ControlState> = {};
      let shouldUpdateState = false;

      if (planIdFromProps !== state.planId) {
        updates.planId = planIdFromProps;
        shouldUpdateState = true;
      }

      if (planQuantityFromProps !== state.planQuantity) {
        updates.planQuantity = planQuantityFromProps;
        shouldUpdateState = true;
      }

      if (shouldUpdateState) {
        return updates;
      }
    }

    return null;
  }

  public render(): React.ReactNode {
    const isSaveButtonDisabled = !this.state.hasUnsavedChanges ||
      this.state.hasUnsavedChanges && (!this.state.planId || !this.state.planQuantity);
    const isResetButtonDisabled = !this.props.planId && !this.props.planQuantity;

    const plans = this.props.subscriptionPlanOptions.filter((x) => x.subscriptionType === this.props.subscriptionType);

    return (
      <div className='company-subscription-control__body'>
        <div className='company-subscription-control__plan-select-wrap'>
          <div className='company-subscription-control__plan-select-title'>
            {this.props.subscriptionType}
          </div>
          <MaterialSelect
            className='company-subscription-control__plan-select'
            autoWidth={true}
            value={this.state.planId}
            displayedType={MaterialComponentType.Native}
            onChange={this.onPlanChange}
          >
            {plans && plans.map(option => (
              <MaterialMenuItem key={option.id} value={option.id}>
                {option.id}
              </MaterialMenuItem>
            ))}
          </MaterialSelect>
        </div>
        <div className='company-subscription-control__plan-quantity-input-wrap'>
          <MaterialInput
            label='Number of licenses'
            type='text'
            valueType='integer'
            positive={true}
            value={this.state.planQuantity}
            onChange={this.onPlanQuantityChange}
          />
        </div>
        <KreoButton
          size='medium'
          mode='action'
          caption='Save Changes'
          disabled={isSaveButtonDisabled}
          className='company-subscription-control__save-changes-button'
          onClick={this.onSaveButtonClick}
        />
        <KreoButton
          size='medium'
          mode='delete'
          caption='Reset'
          disabled={isResetButtonDisabled}
          className='company-subscription-control__reset-button'
          onClick={this.onResetButtonClick}
        />
      </div>
    );
  }

  @autobind
  private onPlanChange(_: React.SyntheticEvent<Element>, planId: string): void {
    this.setState({
      planId,
      hasUnsavedChanges: true,
    });
  }

  @autobind
  private onPlanQuantityChange(_: React.SyntheticEvent<Element>, planQuantity: string): void {
    this.setState({
      planQuantity: parseInt(planQuantity, 10),
      hasUnsavedChanges: true,
    });
  }

  @autobind
  private onSaveButtonClick(): void {
    this.props.setMockSubscription(this.state.planId, this.state.planQuantity);

    this.setState({ hasUnsavedChanges: false });
  }

  @autobind
  private onResetButtonClick(): void {
    this.setState(
      {
        hasUnsavedChanges: false,
        planId: null,
        planQuantity: null,
      },
      this.props.resetMockSubscription);
  }
}

function mapStateToProps(state: State, { companyId, subscriptionType }: OwnProps): StateProps {
  const company = state.admin.companies.data.find(c => c.id === companyId);
  const subscription = company.subscriptions[subscriptionType];

  return {
    planId: subscription ? subscription.mockSubscriptionPlanId : null,
    planQuantity: subscription ? subscription.mockSubscriptionPlanQuantity : null,
    subscriptionPlanOptions: state.admin.companies.subscriptionPlanOptions,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { companyId, subscriptionType }: OwnProps): DispatchProps {
  return {
    setMockSubscription: (planId, planQuantity) =>
      dispatch(AdminCompaniesActions.setMockSubscription(companyId, planId, subscriptionType, planQuantity)),
    resetMockSubscription: () => dispatch(AdminCompaniesActions.resetMockSubscription(companyId, subscriptionType)),
  };
}

export const CompanyMockSubscriptionControlItem =
  connect(mapStateToProps, mapDispatchToProps)(CompanyMockSubscriptionControlItemComponent);
