import autobind from 'autobind-decorator';
import * as React from 'react';

import './filter-panel-option.scss';

import { KreoIconCloseSmall } from 'common/UIKit';
interface Props {
  columnName: string;
  value: string;
  name: string;
  deleteFilterItem: (columnName: string, value: string) => void;
}

export class FilterPanelOption extends React.Component<Props> {
  public render(): JSX.Element {
    const { name, value } = this.props;
    return (
      <div className='filter-panel-option' key={value}>
        {name}
        <div className='filter-panel-option__close' onClick={this.onDelete}>
          <KreoIconCloseSmall />
        </div>
      </div>
    );
  }

  @autobind
  private onDelete(): void {
    this.props.deleteFilterItem(this.props.columnName, this.props.value);
  }
}
