import * as t from 'io-ts';


export const FeedC = <T extends t.Mixed>(
  tValidator: T,
  tName: string = 'Feed',
): t.Any => t.strict(
    {
      skip: t.number,
      count: t.number,
      data: t.array(tValidator),
    },
    tName);

export interface Feed<T> {
  skip: number;
  count: number;
  data: T[];
}
