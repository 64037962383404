import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconLogOut: React.FC = (_props) => {
  return (
    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='icon_sign-out' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect id='Rectangle-3' x='0' y='0' width='14' height='14'/>
        <path
          d='M10.7928932,6.5 L8.64644661,4.35355339 C8.45118446,4.15829124 8.45118446,3.84170876
          8.64644661,3.64644661 C8.84170876,3.45118446 9.15829124,3.45118446 9.35355339,3.64644661
          L12.2873027,6.58019588 C12.4288523,6.67372428 12.4997511,6.83637267 12.4999993,6.99914227
          C12.5000002,6.99971409 12.5000002,7.00028591 12.4999993,7.00085773 C12.4997511,7.16362733
          12.4288523,7.32627572 12.2873027,7.41980412 L9.35355339,10.3535534 C9.15829124,10.5488155
          8.84170876,10.5488155 8.64644661,10.3535534 C8.45118446,10.1582912 8.45118446,9.84170876
          8.64644661,9.64644661 L10.7928932,7.5 L6,7.5 C5.33333333,7.5 5.33333333,6.5 6,6.5
          L10.7928932,6.5 Z M4,11 L5,11 C5.27614237,11 5.5,11.2238576 5.5,11.5 C5.5,11.7761424
          5.27614237,12 5,12 L3.5,12 C3.22385763,12 3,11.7761424 3,11.5 L3,2.5 C3,2.22385763
          3.22385763,2 3.5,2 L5,2 C5.27614237,2 5.5,2.22385763 5.5,2.5 C5.5,2.77614237 5.27614237,3 5,3
          L4,3 L4,11 Z'
          id='Combined-Shape'
          fill={KreoColors.f2}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
