import * as React from 'react';

import { FooterRow } from './footer-row';
import { Styled } from './styled';


interface Tax {
  amount: number;
  description: string;
}

interface Discount {
  amount: number;
  description: string;
}

interface Props {
  total: number;
  discounts?: Discount[];
  taxes?: Tax[];
}


export const  TableFooter: React.FC<Props> = ({ total, discounts, taxes }: Props) => {
  return (
    <Styled.FooterBlock>
      {
        taxes?.map((tax) => {
          return (
            <FooterRow
              key={tax.description}
              description={tax.description}
              value={tax.amount / 100}
            />
          );
        })
      }
      {
        discounts?.map((el) => {
          return (
            <FooterRow
              key={el.description}
              description={el.description}
              value={el.amount / 100}
              unit={'-£'}
            />
          );
        })
      }
      <FooterRow
        description={'Total'}
        value={total}
      />
    </Styled.FooterBlock>
  );
};
