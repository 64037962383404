import autobind from 'autobind-decorator';

import { arrayUtils } from 'common/utils/array-utils';
import {
  Updater,
  Params,
} from 'unit-2d-database/components/side-panel/helpers/update-form-state';
import {
  FieldHandlers,
  IPropertyFormUpdater,
  PropertyField,
  PropertyGroupForm,
  PropertyUpdater,
} from 'unit-2d-database/components/side-panel/helpers/update-property-form';
import { Property } from 'unit-2d-database/interfaces';
import { PropertyGroup } from '../../group-properties-list';

type GroupForm = Record<string, PropertyGroup>;

export interface ItemForm {
  addedProperty: Record<string, PropertyField>;
  removedProperty: Record<string, PropertyField>;
  groupForm: GroupForm;
}

export interface PropertyGroupData {
  addedProperties: Property[];
  updatedProperties: Property[];
  deletedProperties: string[];
}

export type PropertyGroupHandlers = [
  () => void,
];

export interface PropertyGroupUpdaterParams<TFrom> {
  afterDeletePropertyGroup: (form: TFrom, groupName: string, ...args: any) => void;
  afterAddProperty: (form: TFrom, groupName: string, ...args: any) => void;
  afterChangeFormula: (form: TFrom, groupName: string, ...args: any) => void;
}

export interface IPropertyGroupUpdater extends IPropertyFormUpdater {
  getPropertyGroupHandlers(groupName: string): PropertyGroupHandlers;
  updateItemForm(property: GroupForm): void;
  applyNewAfterEffects(baseParams: Params<ItemForm>, params: PropertyGroupUpdaterParams<ItemForm>): void;
  changeFormula(
    updatedForm: GroupForm,
    field: PropertyField,
    groupName: string,
  ): void;
}

export class PropertyGroupUpdater
  extends Updater<ItemForm, PropertyGroupForm>
  implements IPropertyGroupUpdater {
  private afterDeleteGroup: (form: ItemForm, groupName: string) => void;
  private afterAddProperty: (form: ItemForm, groupName: string, updatedForm: GroupForm) => void;
  private afterChangeFormula: (form: ItemForm, groupForm: string) => void;
  public constructor(
    baseParams: Params<ItemForm>,
    params: PropertyGroupUpdaterParams<ItemForm>,
  ) {
    super(baseParams);

    this.afterDeleteGroup = params.afterDeletePropertyGroup;
    this.afterAddProperty = params.afterAddProperty;
    this.afterChangeFormula = params.afterChangeFormula;
  }

  public static getData(form: ItemForm): PropertyGroupData {
    const { addedProperty, removedProperty, groupForm  } = form;

    const addedProperties: Property[] = [];
    const updatedProperties: Property[] = [];
    const deletedProperties: string[] = Object.values(removedProperty).map(p => p.id);

    Object.values(groupForm).forEach(properties => {
      properties.fields.forEach(field => {
        const fieldId = field.id;
        const property = PropertyUpdater.getData(field);
        if (addedProperty[fieldId]) {
          addedProperties.push(property);
          return;
        }

        updatedProperties.push(property);
      });
    });

    return {
      addedProperties,
      updatedProperties,
      deletedProperties,
    };
  }

  public applyNewAfterEffects(baseParams: Params<ItemForm>, params: PropertyGroupUpdaterParams<ItemForm>): void {
    super.updateParams(baseParams);
    this.afterDeleteGroup = params.afterDeletePropertyGroup;
    this.afterAddProperty = params.afterAddProperty;
    this.afterChangeFormula = params.afterChangeFormula;
  }


  public updateItemForm(groupForm: GroupForm): void {
    return this.bindHandler(undefined, this.applyAddItemForm, () => this.afterAddProperty)(groupForm);
  }

  @autobind
  public changeFormula(updatedForm: GroupForm, field: PropertyField, groupName: string): void {
    this.bindHandler(
      groupName,
      this.applyFormulaChangeItemForm,
      () => this.afterChangeFormula,
    )(updatedForm, field, groupName);
  }

  public getFieldHandlers(propertyId: string, groupName: string): FieldHandlers {
    return new PropertyUpdater(
      {
        getForm: () => this.getElement(this.getForm(), groupName),
        afterEffects: {
          afterChange: this.bindHandler(groupName, this.applyChanges, () => this.afterChange),
          afterDelete: this.bindHandler(groupName, this.applyDelete, () => this.afterDelete),
          afterUnitChange: this.bindHandler(groupName, this.applyUnitChange, () => this.afterUnitChange),
          afterVisibilityChange:
            this.bindHandler(groupName, this.applyVisibilityChange, () => this.afterVisibilityChange),
        },
      },
    ).getFieldHandlers(propertyId);
  }

  public getPropertyGroupHandlers(groupName: string): PropertyGroupHandlers {
    return [
      this.bindHandler(groupName, this.applyDeleteGroup, () => this.afterDeleteGroup),
    ];
  }

  @autobind
  protected override getElement(form: ItemForm, propertyGroupName: string): PropertyGroupForm {
    return form.groupForm[propertyGroupName].fields;
  }

  @autobind
  private applyChanges(itemForm: ItemForm, propertyGroupName: string, prop: PropertyGroupForm): void {
    itemForm.groupForm[propertyGroupName].fields = prop;
  }

  @autobind
  private applyUnitChange(itemForm: ItemForm, propertyGroupName: string, prop: PropertyGroupForm): void {
    itemForm.groupForm[propertyGroupName].fields = prop;
  }

  @autobind
  private applyVisibilityChange(itemForm: ItemForm, propertyGroupName: string, prop: PropertyGroupForm): void {
    itemForm.groupForm[propertyGroupName].fields = prop;
  }

  @autobind
  private applyDeleteGroup(itemFrom: ItemForm, propertyGroupName: string): void {
    itemFrom.groupForm[propertyGroupName].fields.forEach(f => {
      this.applyDelete(itemFrom, propertyGroupName, itemFrom.groupForm[propertyGroupName].fields, f.id);
    });
    delete itemFrom.groupForm[propertyGroupName];
  }

  @autobind
  private applyDelete(itemForm: ItemForm, propertyGroupName: string,  _: PropertyGroupForm, propertyId: string): void {
    const { item: field, array: fields } = arrayUtils
      .getAndRemoveItem(itemForm.groupForm[propertyGroupName].fields, f => f.id === propertyId);
    itemForm.groupForm[propertyGroupName].fields = fields;
    if (itemForm.addedProperty[propertyId]) {
      delete itemForm.addedProperty[propertyId];
    } else {
      itemForm.removedProperty[propertyId] = field;
    }
  }

  @autobind
  private applyAddItemForm(itemForm: ItemForm, _: string, updatedForm: GroupForm): void {
    for (const form of Object.values(updatedForm)) {
      form.fields.forEach(f => {
        if ((itemForm.removedProperty[f.name]) && f.originId === itemForm.removedProperty[f.name].originId) {
          delete itemForm.removedProperty[f.name];
        }
        itemForm.addedProperty[f.id] = f;
      });
      const groupForm = itemForm.groupForm[form.id];
      if (!groupForm) {
        itemForm.groupForm[form.id] = form;
        continue;
      }
      groupForm.fields = groupForm.fields.concat(form.fields);
    }
  }

  @autobind
  private applyFormulaChangeItemForm(
    itemForm: ItemForm,
    _: string,
    updatedForm: GroupForm,
    field: PropertyField,
    groupName: string,
  ): void {
    this.applyAddItemForm(itemForm, undefined, updatedForm);
    const index = itemForm.groupForm[groupName].fields.findIndex(f => f.id === field.id);
    itemForm.groupForm[groupName].fields[index] = field;
    this.applyChanges(itemForm, groupName, itemForm.groupForm[groupName].fields);
  }
}
