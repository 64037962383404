import styled, { StyledComponent } from 'styled-components';

import { DataControlNameAttribute } from 'common/constants/control-names';

const Wrapper: StyledComponent<'div', any, { controlName: string }> =
styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  max-height: 410px;
  max-width: 1265px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 25px;
  box-shadow: 0px 15px 40px #0000001F;
  border-radius: 40px;
  background-color: ${props => props.theme.color.backgroundRush};
`;

const TutorialVideo: StyledComponent<'div', any, { controlName: string }> =
styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  max-height: 360px;
  max-width: 640px;
`;

const Information: StyledComponent<'div', any, { controlName: string }>
= styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  align-content: space-between;
  height: 360px;
  width: 100%;
  box-sizing: border-box;
  margin-left: 40px;
  p {
    line-height: 21px;
  }
`;

const AdvDialogHeader: StyledComponent<'div', any, { controlName: string }> =
styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  min-height: 20px;
  text-align: left;
  margin-bottom: 10px;

  p {
    letter-spacing: 0px;
    font-size: 18px;
  }
`;

const AdvDialogDescription: StyledComponent<'div', any, { controlName: string }> =
styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  min-height: 60px;
  min-width: 535px;
  flex-grow: 1;
  text-align: left;

  p {
    font-size: 14px;
    letter-spacing: 0px;
  }
`;

const FeatureList: StyledComponent<'div', any, { controlName: string }> =
styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  display: flex;
  flex-flow: column wrap;
  min-height: 100px;
  min-width: 535px;
  flex-grow: 2;
`;

const ButtonAndChatButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 50px;
  min-width: 395px;
`;

const ConfirmButton: StyledComponent<'div', any, { controlName: string }> =
styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  height: 100%;
  min-width: 225px;
`;

const ChatButton: StyledComponent<'div', any, { controlName: string }> =
styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  min-width: 130px;
  min-height: 20px;
  margin-left: 40px;

  p {
    font-size: 16px;
    letter-spacing: 0px;
  }

  svg {
    height: 20px;
  }
`;

export const Styled = {
  Wrapper,
  Information,
  TutorialVideo,
  AdvDialogHeader,
  AdvDialogDescription,
  FeatureList,
  ButtonAndChatButton,
  ConfirmButton,
  ChatButton,
};
