import {
  Constants,
  ElementTooltip,
  HotKeys,
  Icons,
  LinkComponent,
  ModalWrapper,
  Text,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { Styled } from './styled';

interface OwnProps<T> {
  name: string;
  title: string;
  icon: React.ComponentType<any>;
  tooltipText: string;
  submitDisabled: boolean;
  setInitialState: (data: T, editMode: boolean) => void;
  onSubmit: (data: T, editMode: boolean) => void;
  onCloseDialog: () => void;
  children?: React.ReactNode;
}

interface StateProps<T> {
  data: T;
  editMode: boolean;
}

interface Props<T> extends OwnProps<T>, StateProps<T> {
}

class CreationDialogComponent<T> extends React.Component<Props<T>> {
  public componentDidMount(): void {
    this.props.setInitialState(this.props.data, this.props.editMode);
  }

  public render(): React.ReactNode {
    const { icon: Icon, title, children, tooltipText, submitDisabled } = this.props;
    return (
      <ModalWrapper onExit={this.props.onCloseDialog}>
        <HotKeys
          keyMap={Constants.KeyMaps.ENTER_ESCAPE}
          handlers={{ escape: this.props.onCloseDialog, enter: this.onSubmit }}
          autofocus={true}
        >
          <Styled.DialogContainer>
            <Styled.DialogHeader>
              <Icon height={20} width={20} />
              <Text fontSize={14}>{title}</Text>
              <Styled.DialogButton>
                <ElementTooltip
                  text={tooltipText}
                  position='bottom'
                  speed='m'
                  disabled={!submitDisabled}
                >
                  <LinkComponent
                    text={this.props.editMode ? 'Save' : 'Create'}
                    onClick={this.onSubmit}
                    Icon={this.props.editMode ? Icons.Save : Icons.PlusBig}
                    mood={submitDisabled ? 'disabled' : 'secondary'}
                  />
                </ElementTooltip>
              </Styled.DialogButton>
            </Styled.DialogHeader>
            {children}
          </Styled.DialogContainer>
        </HotKeys>
      </ModalWrapper>
    );
  }

  @autobind
  private onSubmit(): void {
    const { onSubmit, data, editMode } = this.props;
    onSubmit(data, editMode);
  }
}

function mapStateToProps<T>(state: State, ownProps: OwnProps<T>): StateProps<T> {
  const dialogData = state.dialog[ownProps.name];
  return {
    data: dialogData,
    editMode: !!(dialogData && dialogData.id),
  };
}

export const CreationDialog = connect(mapStateToProps)(CreationDialogComponent);
