import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './revit-properties-popup-item.scss';

import {
  ModelBrowserPropertyLine,
  ModelBrowserPropertyLineLayer,
} from '../../interfaces/properties-provider-types';
import { PropertiesUtils } from '../../utils/properties-data-provider/properties-utils';

interface Props {
  title: string;
  items: Array<ModelBrowserPropertyLine | ModelBrowserPropertyLineLayer>;
  open?: boolean;
  onClose?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onOpen?: (key: string, value: boolean) => void;
}

const noop = (): void => {/** */};


export class RevitPropertiesPopupItem extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    open: true,
  };

  public render(): React.ReactNode {
    if (!this.props.items.length) return null;
    return (
      <div
        style={
          { maxHeight: (this.props.open ? this.props.items.length * 40 : 4) + 26 }
        }
        className={classNames('revit-properties-popup-item', { open: this.props.open })}
        onClick={this.props.open ? noop : this.open}
      >
        <div className='revit-properties-popup-item__title' onClick={this.open}>
          {this.props.title}
        </div>
        {this.props.open && this.props.items.map(this.renderItem)}
      </div>
    );
  }

  private renderItem(item: ModelBrowserPropertyLine | ModelBrowserPropertyLineLayer, index: number): React.ReactNode {
    if (PropertiesUtils.lineIsLayer(item)) {
      return (
        <div className='revit-properties-popup-item__line' key={index}>
          <div className='revit-properties-popup-item__line-material' title={item.material}>
            {item.material}
          </div>
          <div className='revit-properties-popup-item__line-layer-function' title={item.layerFunction}>
            {item.layerFunction}
          </div>
          <div className='revit-properties-popup-item__line-thickness' title={item.thickness}>
            {item.thickness}
          </div>
        </div>
      );
    } else {
      return (
        <div className='revit-properties-popup-item__line' key={index}>
          <div className='revit-properties-popup-item__line-name' title={item.name}>
            {item.name}
          </div>
          <div className='revit-properties-popup-item__line-value' title={item.value}>
            {item.value}
          </div>
        </div>
      );
    }
  }

  @autobind
  private open(): void {
    if (this.props.onOpen) {
      this.props.onOpen(this.props.title, !this.props.open);
    }
  }
}
