export const enum EdgesRenderMode {
  Auto = 0,
  Enabled = 1,
  Disabled = 2,
}

export const enum EngineElementType {
  None = 0,
  BimElement = 1,
  FEMGeometry = 2,
}

export const enum Unit {
  Meter,
  FootInch,

  MeterPow2,
  FootPow2,
}

export const enum UnitType {
  Length = 1,
  Area = 2,
  Volume = 3,
}

export const enum PaletteInterpolationType {
  Linear = 0,
  Nearest = 1,
}

export const enum AssemblyRotation {
  Vertical = 0,
  Horizontal = 1,
}

export const enum RulerSnappingMode {
  None = 0,
  Vertex = 1,
}
