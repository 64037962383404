
import { ModelBrowserTreeItem } from '../../viewer';
import { SourceRevitTreeNode } from '../interfaces/classification/source-revit-tree-node';
import { CollaborationElementInfo } from '../interfaces/collaboration/element-info';

interface SearchedResult {
  path: string;
  isMatched?: boolean;
  expanded?: boolean;
  hasMatchedChild?: boolean;
}

function* search(
  items: ModelBrowserTreeItem[],
  words: string[],
  identifier: string = '',
  properties: Set<number>,
): IterableIterator<SearchedResult> {
  for (let i = 0; i < items.length; i++) {
    const currentItem = items[i];
    const currentItemPath = `${identifier}${i}`;
    const children = currentItem.v;
    const itemName = currentItem.n.toLowerCase();
    if (children) {
      const item: SearchedResult = { path: currentItemPath };
      if (words.every(x => itemName.includes(x))) {
        item.isMatched = true;
        item.expanded = false;
      }
      if (!item.isMatched) {
        let hasMatchedChild = false;
        for (const child of search(children, words, `${currentItemPath}.v.`, properties)) {
          if (child.isMatched) {
            hasMatchedChild = true;
          }
          yield child;
        }

        if (hasMatchedChild) {
          item.expanded = true;
          item.hasMatchedChild = true;
        } else if ((!currentItem.search && !item.isMatched) || currentItem.expanded) {
          item.expanded = false;
          item.hasMatchedChild = false;
        }
      }
      yield item;
    } else {
      yield {
        path: currentItemPath,
        isMatched: words.every(x => itemName.includes(x)),
      };
    }
  }
}


function prepareMaterials(materials: string[][]): string[] {
  let result = [];
  for (const mat of materials) {
    result = result.concat(mat);
  }
  return result;
}

function filterMaterials(materails: string[], material: string, count: number): boolean {
  const filtered = materails.filter((value) => material === value);
  return filtered.length === count;
}

function mergeMaterials(materials: string[][]): string[] {
  let result = [];
  if (materials.length > 0) {
    result = materials.pop().slice();
    if (materials.length > 0) {
      const prepared = prepareMaterials(materials);
      result = result.filter((value) => filterMaterials(prepared, value, materials.length));
    }
  }
  return result;
}


function prepareTreeAndMapElements(tree: SourceRevitTreeNode[]): CollaborationElementInfo[] {
  const ret = new Array<CollaborationElementInfo>();
  for (let categoryIndex = 0; categoryIndex < tree.length; categoryIndex++) {
    const category = tree[categoryIndex];
    category.start = ret.length;
    for (let familyIndex = 0; familyIndex < category.v.length; familyIndex++) {
      const family = category.v[familyIndex];
      family.start = ret.length;
      for (let elementTypeIndex = 0; elementTypeIndex < family.v.length; elementTypeIndex++) {
        const elementType = family.v[elementTypeIndex];
        elementType.start = ret.length;
        for (let elementIndex = 0; elementIndex < elementType.v.length; elementIndex++) {
          const element = elementType.v[elementIndex];
          const elementInfo: CollaborationElementInfo = {
            id: element.id,
            category: categoryIndex,
            path: [categoryIndex, 'v', familyIndex, 'v', elementTypeIndex, 'v', elementIndex],
            family: familyIndex,
            type: elementTypeIndex,
            index: elementIndex,
            sourceId: element.sId,
          };
          ret.push(elementInfo);
        }
        elementType.end = ret.length;
        delete elementType.v;
      }
      family.end = ret.length;
    }
    category.end = ret.length;
  }
  return ret;
}

export const ViewerUtilsNew = {
  search,
  mergeMaterials,
  prepareTreeAndMapElements,
};
