import * as t from 'io-ts';

export const ElementClassificationC = t.type(
  {
    code: t.string,
    name: t.string,
  },
  'ElementClassification');

export type ElementClassification = t.TypeOf<typeof ElementClassificationC>;
