import {
  ElementTooltip,
  Icons,
  SearchListItem,
  Text,
} from '@kreo/kreo-ui-components';
import * as React from 'react';
import { TablePreset } from '../../../../../units/2d/interfaces';
import { ItemContextMenu } from './item-context-menu-button';
import { Styled } from './styled';

interface Props {
  itemData: TablePreset;
  openCreateDialog: (preset?: TablePreset) => void;
  deletePreset: (id: string) => void;
}

export class PresetMenuItem extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <SearchListItem itemHeight={40}>
        <Styled.MenuItem>
          <Icons.Templates />
          <Styled.MenuItemText>
            <ElementTooltip
              position={'top'}
              speed={'m'}
              text={this.props.itemData.name}
              wordBreakAll={true}
            >
              <Text withEllipsis={true} fontSize={14}>
                {this.props.itemData.name}
              </Text>
            </ElementTooltip>
          </Styled.MenuItemText>
          <ItemContextMenu
            itemData={this.props.itemData}
            openCreateDialog={this.props.openCreateDialog}
            deletePreset={this.props.deletePreset}
          />
        </Styled.MenuItem>
      </SearchListItem>
    );
  }
}
