import * as t from 'io-ts';

export const CEActivityAssignmentDataVariantC = t.intersection(
  [
    t.type({
      name: t.string,
      materials: t.array(t.number),
    }),
    t.partial({
      primaryMaterial: t.number,
      secondaryMaterial: t.number,
    }),
  ],
  'CEActivityAssignmentDataVariant',
);


export type CEActivityAssignmentDataVariant = t.TypeOf<typeof CEActivityAssignmentDataVariantC>;
