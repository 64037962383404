import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './quantity-take-off-report-panel-controls.scss';

import { ElementTooltip, TooltipPosition } from 'common/components/element-tooltip';
import { ExpandCollapseControls } from 'common/components/expand-collapse-controls';
import { TitleEditable } from 'common/components/title-editable';
import { ConstantFunctions } from 'common/constants/functions';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import {
  KreoDialogActions,
  MaterialMenuItem,
  MaterialSelect,
} from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { KreoConfirmationDialog } from 'components/dialog/kreo-confirmation-dialog';
import { QuantityTakeOffReportActions } from 'unit-projects/actions/creators/quantity-take-off-report';
import { ModelType, QtoReportInfo } from 'unit-projects/interfaces/quantity-take-off';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';


const DELETE_REPORT_CONFIRMATION_DIALOG_NAME = 'DELETE_REPORT_CONFIRMATION_DIALOG_NAME';

interface ReduxProps {
  selectedReportId: number | null;
  loadReportsInfoStatus: RequestStatus;
  reportsInfo: QtoReportInfo[];
  isSync: boolean;
}

interface DispatchProps {
  deleteReport: (reportId: number) => void;
  openDialog: (name: string) => void;
  loadReportsInfo: () => void;
  exportReportToExcel: (reportId: number) => void;
  setSelectedReportId: (id: number) => void;
  loadInitialState: () => void;
}

interface OwnProps {
  onReportExport: () => void;
  projectId: number;
  modelType: ModelType;
  collapseAll: () => void;
  expandAll: () => void;
}

interface Props extends OwnProps, DispatchProps, ReduxProps {
}
class PublishedReportsPanelControlsComponent extends React.Component<Props> {
  private reportMenu: ItemMenuType[] = [
    {
      name: 'Export To Excel',
      action: this.exportToExcel,
    },
    {
      name: 'Remove',
      action: this.openRemoveReportDialog,
    },
  ];

  public componentDidMount(): void {
    this.props.loadInitialState();
  }

  public render(): JSX.Element {
    const {
      reportsInfo,
      selectedReportId,
    } = this.props;
    const selectedReportInfo = reportsInfo.find(x => x.id === selectedReportId);

    return (
      <div className='quantity-take-off-report-panel-controls'>
        <div className='quantity-take-off-report-panel-controls__container-left'>
          <ElementTooltip
            size='medium'
            tooltipPosition={TooltipPosition.CenterTop}
            className={classNames(
              'quantity-take-off-report-panel-controls__report-menu',
              { 'quantity-take-off-report-panel-controls__report-menu--disabled': !selectedReportInfo },
            )}
            targetElement={
              <ItemMenu
                buttonSize='small'
                menu={this.reportMenu}
              />
            }
            disabled={!!selectedReportInfo}
          >
            You have to select a report firstly
          </ElementTooltip>
          <ExpandCollapseControls
            className='quantity-take-off-report-panel-controls__expand-collapse-controls'
            onExpand={this.props.expandAll}
            onCollapse={this.props.collapseAll}
          />
          <div className='quantity-take-off-report-panel-controls__report-name'>
            {
              selectedReportInfo ? (
                <ElementTooltip
                  size='small'
                  tooltipPosition={TooltipPosition.CenterTop}
                  className='quantity-take-off-report-panel-controls__report-title'
                  targetElement={
                    <TitleEditable
                      isEditByIconClick={true}
                      canEdit={false}
                      onChange={ConstantFunctions.doNothing}
                      text={selectedReportInfo.name}
                      label='Report Name'
                    />
                  }
                >
                  <b className='quantity-take-off-report-panel-controls__tooltip-title-name'>
                    {selectedReportInfo.name}
                  </b>
                  {
                    selectedReportInfo.description &&
                    <div className='quantity-take-off-report-panel-controls__tooltip-title-description'>
                      {selectedReportInfo.description}
                    </div>
                  }
                </ElementTooltip>
              ) : (
                <span className='quantity-take-off-report-panel-controls__report-label'>
                  Please, Select Report
                </span>
              )
            }
          </div>
        </div>
        <div className='quantity-take-off-report-panel-controls__container-right'>
          <MaterialSelect
            value={selectedReportId}
            className='quantity-take-off-report-panel-controls__material-select'
            dropdownClassName='quantity-take-off-report-panel-controls__material-paper'
            onChange={this.selectReport}
            noDataText='No Reports'
            placeholder={reportsInfo && reportsInfo.length ? 'Select Report' : 'Create New Report'}
            displayedType={MaterialComponentType.Native}
            autoWidth={true}
          >
            {reportsInfo &&
              reportsInfo.map(report => {
                return (
                  <MaterialMenuItem
                    key={report.id}
                    value={report.id}
                  >
                    <ElementTooltip
                      size='small'
                      tooltipPosition={TooltipPosition.CenterTop}
                      className='quantity-take-off-report-panel-controls__material-select-item'
                      tooltipClassName='quantity-take-off-report-panel-controls__material-select-tooltip'
                      targetElement={report.name}
                    >
                      <b className='quantity-take-off-report-panel-controls__tooltip-title-name'>
                        {report.name}
                      </b>
                      {
                        report.description &&
                        <div className='quantity-take-off-report-panel-controls__tooltip-title-description'>
                          {report.description}
                        </div>
                      }
                    </ElementTooltip>
                  </MaterialMenuItem>
                );
              })}
          </MaterialSelect>
        </div>
        <KreoConfirmationDialog
          name={DELETE_REPORT_CONFIRMATION_DIALOG_NAME}
          onYes={this.deleteReport}
          yesText='Remove'
          noText='Cancel'
          title='Remove Report'
        >
          Are you sure you want to remove <b>{selectedReportInfo ? selectedReportInfo.name : ''}</b> report?
        </KreoConfirmationDialog>
      </div>
    );
  }

  @autobind
  private exportToExcel(): void {
    this.props.onReportExport();
    this.props.exportReportToExcel(this.props.selectedReportId);
  }

  @autobind
  private selectReport(_: React.SyntheticEvent, reportId: number): void {
    if (reportId !== this.props.selectedReportId) {
      this.props.setSelectedReportId(reportId);
    }
  }

  @autobind
  private openRemoveReportDialog(): void {
    this.props.openDialog(DELETE_REPORT_CONFIRMATION_DIALOG_NAME);
  }

  @autobind
  private deleteReport(): void {
    this.props.deleteReport(this.props.selectedReportId);
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const reportState = state.quantityTakeOff.report;
  return {
    loadReportsInfoStatus: reportState.statuses.loadReportsInfo,
    reportsInfo: reportState.reportsInfo,
    selectedReportId: reportState.selectedReportId,
    isSync: reportState.isSync,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  const { projectId, modelType } = props;

  return {
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
    deleteReport: (id) => dispatch(QuantityTakeOffReportActions.deleteReport(projectId, modelType, id)),
    loadReportsInfo: () => dispatch(QuantityTakeOffReportActions.loadReportsInfo(projectId, modelType)),
    loadInitialState: () => dispatch(QuantityTakeOffReportActions.loadInitialState(projectId, modelType)),
    exportReportToExcel: (id) =>
      dispatch(QuantityTakeOffReportActions.exportReportToExcel(projectId, modelType, id)),
    setSelectedReportId: (id) => dispatch(QuantityTakeOffReportActions.setSelectedReportId(id)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const PublishedReportsPanelControls = connector(PublishedReportsPanelControlsComponent);
