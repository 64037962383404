import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { DrawingsEditFileTreeItem } from '../actions/payloads/common';
import { DrawingsFile, DrawingsFolderViewInfo } from '../interfaces/drawings-file-info';

function isFile(entity: DrawingsFile | DrawingsFolderViewInfo): entity is DrawingsFile {
  return entity && entity.type === TreeTableRowType.Element;
}

function isFolder(entity: DrawingsFile | DrawingsFolderViewInfo): entity is DrawingsFolderViewInfo {
  return entity && entity.type === TreeTableRowType.Group;
}

function extractChangesFromEntityInfo(entity: DrawingsFile | DrawingsFolderViewInfo): DrawingsEditFileTreeItem {
  return {
    id: entity.id,
    name: entity.properties.name,
    parentFolderId: entity.parentId,
  };
}

export const DrawingsPdfBrowserUtils = {
  isFile,
  isFolder,
  extractChangesFromEntityInfo,
};
