import autobind from 'autobind-decorator';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { ContextObserver } from '../../../../drawings-contexts';
import { ShortPointDescription } from '../../../../interfaces/drawing-ai-annotation';
import { MeasuresViewSettings } from '../../../../interfaces/drawing-text-render-parameters';
import { DrawingsAllowedPathType } from '../../../../interfaces/drawings-geometry';
import { StrokeParams } from '../../../../interfaces/stroke-params';
import { DrawingsGeometryDragEventHelper } from '../../../drawings-helpers/drawings-geometry-drag-event-helper';
import { PointsManager } from '../../../interfaces/helpers/points-managing';
import {
  DrawingsGeometryEntity,
  DrawingsGeometryEntityBase,
  DrawingsMouseEventHandler,
} from '../../base';
import { DrawingsGeometryEntityConfig } from '../../drawings-geometry-entity-config';
import { DrawingsGeometryEntityMeasureLength } from '../measure-length/drawings-geometry-entity-measure-length';
import { DrawingsGeometryEntityLine, SegmentDragHelper } from './segment-base-line';
import { SegmentHoverLineEntity } from './segment-hover-line-entity';
import { SegmentUtilLineEntity } from './segment-util-line-entity';


export interface DrawingsGeometryEntityLineWithMeasureConfig extends DrawingsGeometryEntityConfig {
  points: [string, string];
  layer: paper.Layer | paper.Group;
  textLayer: paper.Layer | paper.Group;
  color: paper.Color;
  modifyEnabled?: boolean;
  dashed?: boolean;
  opacity?: number;
  selectedHighlight?: boolean;
  canMove?: boolean;
  clampPath?: DrawingsAllowedPathType;
  mustBeOutOfPath?: boolean;
  textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
  pointsManager: PointsManager;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
  strokeStyle: StrokeParams;
  onMouseEnter?: DrawingsMouseEventHandler;
  onMouseLeave?: DrawingsMouseEventHandler;
  onStartDrag?: DrawingsMouseEventHandler;
  onSelectSegmentMeasure: DrawingsMouseEventHandler;
  segmentDragHelper: SegmentDragHelper;
}

export class DrawingsGeometryEntityLineWithMeasure
  extends DrawingsGeometryEntityBase<DrawingsGeometryEntityLineWithMeasureConfig>
  implements DrawingsGeometryEntity {

  private _line: DrawingsGeometryEntityLine;
  private _hoverLine: SegmentHoverLineEntity;
  private _selectedLine: SegmentUtilLineEntity;
  private _measure: DrawingsGeometryEntityMeasureLength;

  /* other */
  private _mouseLeaveExecutor: DeferredExecutor = new DeferredExecutor(1000);

  constructor(config: DrawingsGeometryEntityLineWithMeasureConfig) {
    super(config);

    this._line = new DrawingsGeometryEntityLine(
      {
        id: this.id,
        points: config.points,
        layer: config.layer,
        color: config.color,
        opacity: config.opacity,
        modifyEnabled: config.modifyEnabled,
        onMouseEnter: this.mouseEnter,
        onMouseLeave: this.mouseLeave,
        onSelect: config.onSelect,
        onDoubleClick: config.onDoubleClick,
        onStartDrag: config.onStartDrag,
        dragEventsHelper: config.dragEventsHelper,
        renderParamsContextObserver: config.renderParamsContextObserver,
        strokeParams: config.strokeStyle,
        cursorHelper: this._config.cursorHelper,
        pointsManager: this._config.pointsManager,
        segmentDragHelper: this._config.segmentDragHelper,
      },
    );
    this.modifyEnabled = config.modifyEnabled;
    if (config.opacity) {
      this.opacity = config.opacity;
    }
  }

  public set canDragMode(value: boolean) {
    this._line.canDragMode = value;
  }

  public set opacity(value: number) {
    this._line.opacity = value;
  }

  public get opacity(): number {
    return this._line.opacity;
  }

  public enableSelectedStyle(): void {
    if (this._selectedLine) {
      return;
    }
    this._selectedLine = new SegmentUtilLineEntity({
      geometry: this._config.points.map(this._config.pointsManager.getPointInfo) as [paper.Point, paper.Point],
      layer: this._config.layer,
      color: DrawingsCanvasColors.utility,
      strokeStyled: this._config.strokeStyle,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      onMouseEnter: this.mouseEnter,
      onMouseLeave: this.mouseLeave,
    });
  }

  public disableSelectedStyle(): void {
    if (this._selectedLine) {
      this._selectedLine.destroy();
    }
  }

  public enableHoverStyle(): void {
    if (this._hoverLine) {
      return;
    }
    this._hoverLine = new SegmentHoverLineEntity({
      geometry: this._config.points.map(this._config.pointsManager.getPointInfo) as [paper.Point, paper.Point],
      layer: this._config.layer,
      color: this._config.color,
      strokeStyled: this._config.strokeStyle,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      onMouseEnter: this.mouseEnter,
      onMouseLeave: this.mouseLeave,
    });
  }

  public disableHoverStyle(): void {
    if (this._hoverLine) {
      this._hoverLine.destroy();
    }
  }

  public changeStrokeStyles(strokeStyle: StrokeParams): void {
    this._config.strokeStyle = strokeStyle;
    this._line.changeStrokeStyles(strokeStyle);
    this._hoverLine?.changeStrokeStyle(strokeStyle);
  }

  public changeColor(color: paper.Color): void {
    if (color !== this._config.color) {
      this._line.color = color;
      if (this._measure) {
        this._measure.changeColor(color);
      }
      this._config.color = color;
      if (this._hoverLine) {
        this._hoverLine.changeColor(color);
      }
    }
  }

  public set modifyEnabled(value: boolean) {
    this._line.modifyEnabled = value;
  }

  public destroy(): void {
    this._line.destroy();
    if (this._measure) {
      this._measure.destroy();
    }
    if (this._hoverLine) {
      this._hoverLine.destroy();
    }
    if (this._selectedLine) {
      this._selectedLine.destroy();
    }
  }

  public changeLabel(): void {
    if (this._measure) {
      this._measure.changeLabel();
    }
  }

  public changePointPosition(id: string): void {
    this._line.changePointPosition(id);
    const points = this.getPaperPoints();
    if (this._measure) {
      this._measure.changeLine(points);
    }
    if (this._hoverLine) {
      this._hoverLine.updateGeometry(points);
    }
    if (this._selectedLine) {
      this._selectedLine.updateGeometry(points);
    }
  }

  public renderOnOtherSide(value: boolean): void {
    if (this._measure) {
      this._measure.renderOnOtherSide(value);
    }
  }

  public getCenter(): ShortPointDescription {
    return this._line.getCenter();
  }

  @autobind
  public mouseLeave(_id: string, e: PaperMouseEvent): void {
    if (this._measure) {
      this._mouseLeaveExecutor.execute(() => this.removeMeasure(e));
    } else {
      this._config.onMouseLeave(this.id, e);
    }
  }

  @autobind
  private mouseEnter(_id: string, e: PaperMouseEvent): void {
    this._config.onMouseEnter(this.id, e);
    this._mouseLeaveExecutor.reset();
    if (!this._measure) {
      this._line.canMove = this._config.canMove;
      this._measure = new DrawingsGeometryEntityMeasureLength({
        id: this.id,
        points: this.getPaperPoints(),
        layer: this._config.layer,
        textLayer: this._config.textLayer,
        color: this._config.color,
        clampPath: this._config.clampPath,
        mustBeOutOfPath: this._config.mustBeOutOfPath,
        onMouseDown: this._config.onSelectSegmentMeasure,
        onMouseEnter: this.measureMouseEnter,
        onMouseLeave: this.mouseLeave,
        cursorHelper: this._config.cursorHelper,
        renderParamsContextObserver: this._config.renderParamsContextObserver,
        textRenderParamsObserver: this._config.textRenderParamsObserver,
      });
    }
  }

  @autobind
  private measureMouseEnter(id: string, e: PaperMouseEvent): void {
    this._config.onMouseEnter(id, e);
    this._mouseLeaveExecutor.reset();
  }

  @autobind
  private removeMeasure(e: PaperMouseEvent): void {
    this._measure.destroy();
    this._measure = null;
    this._line.canMove = false;
    this._config.onMouseLeave(this.id, e);
  }

  private getPaperPoints(): [paper.Point, paper.Point] {
    const { pointsManager: callbacks } = this._config;
    return this._config.points.map(callbacks.getPointInfo) as [paper.Point, paper.Point];
  }
}
