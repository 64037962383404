import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './model-check-start-calculation-control-check.scss';

import { ElementTooltip, TooltipPosition } from 'common/components/element-tooltip';
import { Checkbox, KreoIconAttention, KreoIconSuccessSmall } from 'common/UIKit';
import { ModelCheckAvailableCheck } from '../../interfaces/model-check/model-check-available-checks';
import { TipPositions } from '../../pages/model-check-view/components/tip-positions';
import { TooltipIcon } from '../../pages/model-check-view/components/tooltip-icon';

interface Props {
  checked: boolean;
  disabled: boolean;
  description: ModelCheckAvailableCheck;
  onChange: (variant: string) => void;
  count: number;
}

export class ModelCheckStartCalculationControlCheck extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const issueChecked = this.props.checked && this.props.disabled;
    const issueWithError = this.props.disabled && !!this.props.count;
    const className = classNames(
      'model-check-start-calculation-control-check',
      {
        'model-check-start-calculation-control-check--issue-checked': issueChecked,
        'model-check-start-calculation-control-check--issue-error': issueWithError,
      },
    );
    return (
      <div className={className}>
        <Checkbox
          checked={this.props.checked}
          onChange={this.onChange}
          disabled={this.props.disabled}
          label={this.props.description.name}
        />
        <TooltipIcon
          modelCheckType={this.props.description.type}
          tipPosition={TipPositions.BOTTOM}
        />
        {this.getCheckedIconWithTooltip(issueChecked)}
      </div>
    );
  }

  private getCheckedIconWithTooltip(issueChecked: boolean): React.ReactNode {
    let checkedIcon = this.props.count ? <i>{this.props.count}</i> : <KreoIconSuccessSmall />;
    let checkedIconTooltip =
      <b>{this.props.count ? 'Click "View issues" for details' : 'All is The model meets all the requirements'}</b>;

    if (!issueChecked) {
      checkedIcon = <KreoIconAttention />;
      checkedIconTooltip = <><b>The issue hasn't been checked yet</b> Calculation may take some time</>;
    }

    return (
      <ElementTooltip
        size='small'
        tooltipPosition={TooltipPosition.CenterTop}
        className='model-check-start-calculation-control-check__icon'
        targetElement={checkedIcon}
      >
        <div className='model-check-start-calculation-control-check__icon-tooltip'>
          {checkedIconTooltip}
        </div>
      </ElementTooltip>
    );
  }
  @autobind
  private onChange(): void {
    this.props.onChange(this.props.description.type);
  }
}
