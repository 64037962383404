import { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { useCallback } from 'react';

type UseExtendContextMenuItem = (
  canEdit: boolean,
  handleDelete: (id: string) => void,
  addFolder: (parentId: string) => void,
  addFile: (id: string) => void,
) => [(
  items: Array<MenuItemDef | string>,
  params: GetContextMenuItemsParams
) => Array<MenuItemDef | string>];

const getDeleteItem = (handleDelete: (id: string) => void, params: GetContextMenuItemsParams): MenuItemDef => {
  if (!params.node?.id) {
    return;
  }
  return {
    name: 'Delete',
    action: () => handleDelete(params.node.id),
  };
};

const getAddedFolderItem = (addFolder: (id: string) => void, params: GetContextMenuItemsParams): MenuItemDef => {
  if (!params.node?.data.h_isDragTarget) {
    return;
  }
  return {
    name: 'Create folder',
    action: () => addFolder(params.node.id),
  };
};

const getAddFileItem = (addFile: (id: string) => void, params: GetContextMenuItemsParams): MenuItemDef => {
  if (!params.node?.data.h_isDragTarget) {
    return;
  }
  return {
    name: 'Load files in this folder',
    action: () => addFile(params.node.id),
  };
};

const hideDefaultItemsSet = new Set(['autoSizeAll', 'resetColumns']);

export const useExtendContextMenuItem: UseExtendContextMenuItem = (canEdit, handleDelete, addFolder, addFile) => {
  return [
    useCallback((items, params) => {
      if (canEdit) {
        items.push(getDeleteItem(handleDelete, params));
        const addedFolderItem = getAddedFolderItem(addFolder, params);
        if (addedFolderItem) {
          items.push(addedFolderItem);
        }
        const addedFileItem = getAddFileItem(addFile, params);
        if (addedFileItem) {
          items.push(addedFileItem);
        }
      }
      return items.filter(i => !hideDefaultItemsSet.has(i as string));
    }, [canEdit, handleDelete, addFolder, addFile]),
  ];
};
