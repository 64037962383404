import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { useHandleClickCallback } from './hooks';
import { Styled } from './styled';

interface Props {
  isNeedUpdate: boolean;
  id?: string;
}

export const UpgradeWrapper: React.FC<Props> = ({ id, isNeedUpdate, children }) => {
  const canManageBilling = useAbility(Operation.Manage, Subject.Billing);
  const canProposeUpdate = useAbility(Operation.Read, Subject.ProposeExtendSubscription);
  const handleClick = useHandleClickCallback(canManageBilling);

  if (!isNeedUpdate || !canProposeUpdate) {
    return <>{children}</>;
  }

  return (
    <Styled.Container id={id} onClick={handleClick}>
      {children}
      <Styled.StarsContainer>
        <Icons.Stars2D />
      </Styled.StarsContainer>
    </Styled.Container>
  );
};
