import { CustomElementFilterCollectionOperation } from 'common/enums/custom-element-filter-collection-operation';
import { CustomElementFilterNumberOperation } from 'common/enums/custom-element-filter-number-operation';
import { CustomElementFilterStringOperation } from 'common/enums/custom-element-filter-string-operation';
import { CustomElementFilterNumber, CustomElementFilterString } from 'common/interfaces/custom-element-filter-builder';


const resolveInversion = (result: boolean, inverse: boolean): boolean => {
  return result !== inverse; // XOR
};

const castStringValue = (value: string, isCaseSensitive: boolean): string | null => {
  if (typeof value === 'string') {
    return !isCaseSensitive
      ? value.toLowerCase()
      : value;
  }

  return null;
};

const applyStringOperation = (
  castedValue: string | null,
  castedArg: string,
  operation: CustomElementFilterStringOperation,
): boolean => {
  if (castedValue === null) {
    return false;
  }

  switch (operation) {
    case CustomElementFilterStringOperation.Contains:
      return castedValue.includes(castedArg);
    case CustomElementFilterStringOperation.EndsWith:
      return castedValue.endsWith(castedArg);
    case CustomElementFilterStringOperation.StartsWith:
      return castedValue.startsWith(castedArg);
    case CustomElementFilterStringOperation.Equals:
      return castedValue === castedArg;
    default: return false;
  }
};

const applyCollectionStringOperation = (
  values: string | string[],
  castedArg: string,
  operation: CustomElementFilterStringOperation,
  collectionOperation: CustomElementFilterCollectionOperation,
  isCaseSensitive: boolean,
): boolean => {
  const valuesToApplyFilter = Array.isArray(values)
    ? values
    : [values];

  switch (collectionOperation) {
    case CustomElementFilterCollectionOperation.All:
      return valuesToApplyFilter
        .every(x => applyStringOperation(castStringValue(x, isCaseSensitive), castedArg, operation));
    case CustomElementFilterCollectionOperation.Any:
      return valuesToApplyFilter
        .some(x => applyStringOperation(castStringValue(x, isCaseSensitive), castedArg, operation));
    default: return false;
  }
};

const applyNumberOperation = (
  value: number,
  arg: number,
  operation: CustomElementFilterNumberOperation,
): boolean => {
  if (typeof value !== 'number') {
    return false;
  }

  switch (operation) {
    case CustomElementFilterNumberOperation.Equal:
      return value === arg;
    case CustomElementFilterNumberOperation.Greater:
      return value > arg;
    case CustomElementFilterNumberOperation.GreaterOrEqual:
      return value >= arg;
    case CustomElementFilterNumberOperation.Less:
      return value < arg;
    case CustomElementFilterNumberOperation.LessOrEqual:
      return value <= arg;
    default: return false;
  }
};

const resolveStringFilter = (value: string | string[], filter: CustomElementFilterString): boolean => {
  const { collectionOperation, operation, operationArg, isCaseSensitive, inverse } = filter;
  const castedArg = castStringValue(operationArg, isCaseSensitive);
  let result = false;

  if (Array.isArray(value)) {
    result = applyCollectionStringOperation(value, castedArg, operation, collectionOperation, isCaseSensitive);
  } else {
    const castedValue = castStringValue(value, isCaseSensitive);
    result = applyStringOperation(castedValue, castedArg, operation);
  }

  return resolveInversion(result, inverse);
};

const resolveNumberFilter = (value: number | number [], filter: CustomElementFilterNumber): boolean => {
  const { operation, operationArg, inverse } = filter;
  const numberValue = Array.isArray(value) ? value[0] : value;
  const result = applyNumberOperation(numberValue, operationArg, operation);

  return resolveInversion(result, inverse);
};

export const CustomElementFilterResolvers = {
  resolveStringFilter,
  resolveNumberFilter,
  resolveInversion,
};
