import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { StringDictionary } from 'common/interfaces/dictionary';
import { State } from 'common/interfaces/state';
import { GanttChartActions } from '../../../actions/creators/gantt-chart';
import { LineData } from '../../../interfaces/gantt-chart';
import { SelectBody } from './select-body';
import { SelectItem } from './select-item';


interface OwnProps {
  isAllSelected: boolean;
  isExpanded: boolean;
  onCollapseExpand: () => void;
  selectAll: () => void;
}


interface StateProps {
  workPackages: LineData[];
  selections: StringDictionary<boolean>;
}

interface DispatchProps {
  selectionChange: (lineId: string, checked: boolean) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {
}

class WorkPackageSelectionControlBodyComponent extends React.Component<Props> {
  private selectItemMethods: StringDictionary<(selected: boolean) => void>  = {};

  public render(): React.ReactNode {
    return (
      <SelectBody
        contentContainerClassName={'gantt-work-package-selection-control-panel__content'}
        open={this.props.isExpanded}
        onCollapseExpand={this.props.onCollapseExpand}
      >
        <SelectItem
          onCheck={this.props.selectAll}
          level={0}
          selected={this.props.isAllSelected}
          title='Entire Project'
        />
        {
          this.props.workPackages.map((workPackage) => {
            return (
              <SelectItem
                onCheck={this.getCheckMethodByLineId(workPackage.lineId)}
                level={1}
                selected={this.props.selections && this.props.selections[workPackage.lineId]}
                key={workPackage.lineId}
                title={workPackage.name}
              />
            );
          })
        }
      </SelectBody>
    );
  }

  private getCheckMethodByLineId(lineId: string): (selected: boolean) => void {
    if (!this.selectItemMethods[lineId]) {
      this.selectItemMethods[lineId] = (selected: boolean) => {
        this.props.selectionChange(lineId, selected);
      };
    }
    return this.selectItemMethods[lineId];
  }
}

const mapStateToProps = (state: State): StateProps =>  {
  return {
    workPackages: state.fourDVisualisation.ganttData ? state.fourDVisualisation.ganttData.workPackages : [],
    selections: state.fourDVisualisation.selectedPackages,
  };
};


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    selectionChange: (lineId: string, checked: boolean) =>
      dispatch(GanttChartActions.changePackageSelection(lineId, checked)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const WorkPackageSelectionControlBody = connector(WorkPackageSelectionControlBodyComponent);
