import React, { Component } from 'react';

interface Props {
  width?: number;
  height?: number;
}

/* eslint-disable max-len */
class KreoIconChat extends Component<Props> {
  public static defaultProps: Partial<Props> = {
    width: 40,
    height: 40,
  };

  public render(): React.ReactNode {
    const { width, height } = this.props;
    return (
    <svg width={width} height={height} viewBox='0 0 40 40' version='1.1'>
    <g stroke='none' fill='none' fillRule='evenodd'>
        <path d='M29,40 L29,38 L28,38 L28,40 L12,40 L12,38 L11,38 L11,40 L6.66398982,40 C6.49381972,39.7058266 6.39642543,39.3642871 6.39642543,39 C6.39642543,38.8800168 6.40722237,38.760277 6.42868561,38.6422291 L7.02959188,35.3372447 C7.59153543,32.2465551 10.2833864,30 13.4247463,30 L26.5752537,30 C29.7166136,30 32.4084646,32.2465551 32.9704081,35.3372447 L33.5713144,38.6422291 C33.6595659,39.1276126 33.5646114,39.6039023 33.3356727,40 L29,40 Z' id='Path' fill='#4071A2' />
        <path d='M14,30 L26,30 C26,33.3137085 23.3137085,36 20,36 C16.6862915,36 14,33.3137085 14,30 Z' id='Rectangle' fill='#89BCE3' />
        <path d='M15,40 L13,40 L13,30 L15,30 L15,40 Z M27,40 L25,40 L25,30 L27,30 L27,40 Z' id='Shape' fill='#FFBC57' />
        <path d='M13,35 L15,35 L15,37 L13,37 L13,35 Z M25,35 L27,35 L27,37 L25,37 L25,35 Z' id='Shape' fill='#152A42' />
        <path d='M16,27 L24,27 L24,30 C24,32.209139 22.209139,34 20,34 C17.790861,34 16,32.209139 16,30 L16,27 Z' id='Rectangle' fill='#F7D2B7' />
        <path d='M13.1231526,21.201569 C12.4451379,19.7333037 11.8770949,18.2791621 12.0514247,16.5404579 C12.1699186,15.35864 27.621911,13.4552014 27.9559405,16.0403466 C28.2802466,18.6473384 27.2799196,21.9573237 26.8854969,22.260336' id='Path' fill='#152A42' />
        <path d='M14,19 C14,19 14,23 14,23 C12.8954305,23 12,22.1045695 12,21 C12,19.8954305 12.8954305,19 14,19 Z M26,19 C27.1045695,19 28,19.8954305 28,21 C28,22.1045695 27.1045695,23 26,23 C26,23 26,19 26,19 Z' id='Shape' fill='#F7D2B7' />
        <path d='M13,17 L27,17 L27,22 C27,25.8659932 23.8659932,29 20,29 C16.1340068,29 13,25.8659932 13,22 L13,17 Z' id='Path' fill='#FFE3C7' />
        <path d='M18.5,24 L21.5,24 C21.7761424,24 22,24.2238576 22,24.5 C22,24.7938534 21.8000084,25.0499979 21.5149287,25.1212678 L20.4850713,25.3787322 C20.1665913,25.4583522 19.8334087,25.4583522 19.5149287,25.3787322 L18.4850713,25.1212678 C18.1999916,25.0499979 18,24.7938534 18,24.5 C18,24.2238576 18.2238576,24 18.5,24 Z' id='Rectangle' fill='#F7D2B7' />
        <path d='M17.25,20.5 C16.8357864,20.5 16.5,20.1642136 16.5,19.75 C16.5,19.3357864 16.8357864,19 17.25,19 C17.6642136,19 18,19.3357864 18,19.75 C18,20.1642136 17.6642136,20.5 17.25,20.5 Z' id='Oval' fill='#152A42' />
        <path d='M22.75,20.5 C22.3357864,20.5 22,20.1642136 22,19.75 C22,19.3357864 22.3357864,19 22.75,19 C23.1642136,19 23.5,19.3357864 23.5,19.75 C23.5,20.1642136 23.1642136,20.5 22.75,20.5 Z' id='Oval-Copy' fill='#152A42' />
        <path d='M20,7 C24.418278,7 28,10.581722 28,15 L12,15 C12,10.581722 15.581722,7 20,7 Z' id='Mask' fill='#FFBC57' />
        <polygon id='Rectangle' fill='#FFBC57' points='10 15 30 15 30 18 10 18' />
        <polygon id='Rectangle' fill='#E6A94E' points='18 6 22 6 22 15 18 15' />
    </g>
</svg>
    );
  }
}

export { KreoIconChat };
