import { DrawingsGeometryGroupWithNesting } from '../../interfaces';
import { AnnotationLegendItem } from '../../interfaces/annotation-legend-tree';
import { AnnotationLegendItemTypeguards } from '../../utils/annotation-legend-item-typeguards';

export function leaveOnlyGroups(
  filterResultItems: AnnotationLegendItem[],
): DrawingsGeometryGroupWithNesting[] {
  const result = new Array<DrawingsGeometryGroupWithNesting>();
  for (const item of filterResultItems) {
    if (AnnotationLegendItemTypeguards.isGroup(item)) {
      result.push({
        ...item,
        allInnerInstances: leaveOnlyGroups(item.allInnerInstances),
      });
    }
  }
  return result;
}
