import styled from 'styled-components';

const Button = styled.div`
  > button {
    width: 100%;
  }
`;

const RemovedInstancesContainer = styled.div`
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.color.gray};
  }

  div:last-child {
    height: 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Styled = {
  Button,
  RemovedInstancesContainer,
  ContentWrapper,
};
