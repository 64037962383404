import { PartialDeep, pick } from 'lodash';

import { DrawingLayoutApi } from '../../drawings-canvas/drawings-canvas';
import { DrawingsInstanceType } from '../../enums/drawings-instance-type';
import { DrawingsInstanceMeasure } from '../../interfaces/drawings-instance-measure';
import { DrawingsMeasureType } from '../../interfaces/drawings-measures';
import { DrawingsShortInfo } from '../../interfaces/drawings-short-drawing-info';
import { DrawingsGeometryState } from '../../interfaces/drawings-state';
import { DrawingAnnotationUtils } from '../drawing-annotation-utils';
import { DrawingsGeometryUtils } from '../drawings-geometry-utils';
import { DrawingsUtils } from '../drawings-utils';

type CurrentInstanceMeasureGetter = (id: string) => DrawingsInstanceMeasure;

type InstancesMeasuresGetter = (
  instancesIds: Iterable<string | string[]>,
  drawingsInfo: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  getCurrentMeasurement: CurrentInstanceMeasureGetter,
) => {
  measures: DrawingsInstanceMeasure[],
  measuresForSave: DrawingsInstanceMeasure[],
};
type SegmentMeasuresGetter = (
  pointIds: [string, string],
  drawingsInfo: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  getCurrentMeasurement: CurrentInstanceMeasureGetter,
) => { shouldSave: boolean, measure: DrawingsInstanceMeasure | null };

type InstanceMeasuresGetter = (
  instanceId: string,
  drawingsInfo: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  getCurrentMeasurement: CurrentInstanceMeasureGetter,
) => { shouldSave: boolean, measure: DrawingsInstanceMeasure | null };


function getSegmentMeasures(
  pointIds: [string, string],
  drawingsInfo: Record<string, DrawingsShortInfo>,
  { pointsInfo, geometry, points }: DrawingsGeometryState,
  getCurrentMeasure: CurrentInstanceMeasureGetter,
): { shouldSave: boolean, measure: DrawingsInstanceMeasure | null } {
  const [start, end] = pointIds;
  const lineKey = DrawingAnnotationUtils.getLineKey(start, end);
  const currentMeasure = getCurrentMeasure(lineKey);
  if (currentMeasure) {
    return { shouldSave: false, measure: currentMeasure };
  }
  if (start in pointsInfo && pointsInfo[start].lines && pointsInfo[start].lines.includes(lineKey)) {
    const instanceId = pointsInfo[start].instanceId;
    const instance = geometry[instanceId];
    const drawingInfo = drawingsInfo[instance.drawingId];
    const { scale, paperSize: paperSize } = DrawingsUtils.getCurrentPageScales(drawingInfo);
    const metersPerPx = DrawingsUtils.getMetersPerPx(drawingInfo.width, drawingInfo.height, paperSize);
    const measure = {
      type: DrawingsInstanceType.Segment,
      id: pointIds,
      measures: DrawingsGeometryUtils.calculateSegmentMeasurements(
        pointIds,
        points,
        scale,
        metersPerPx,
      ),
      color: instance.geometry.color,
    };
    return { shouldSave: true, measure };
  } else {
    return { shouldSave: true, measure: null };
  }
}


function getInstanceMeasure(
  drawingLayoutApi: DrawingLayoutApi,
  id: string,
  drawingsInfo: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  getCurrentMeasurement: CurrentInstanceMeasureGetter,
): { shouldSave: boolean, measure: DrawingsInstanceMeasure | null } {
  const measurement = getCurrentMeasurement(id);
  if (measurement) {
    if (measurement.type === DrawingsInstanceType.Count) {
      const measuresKeys = Object.keys(measurement.measures);
      const filteredMeasures: PartialDeep<DrawingsMeasureType> = pick(measurement.measures, measuresKeys);

      return {
        measure: { ...measurement, measures: (filteredMeasures as DrawingsMeasureType) },
        shouldSave: false };
    }
    return { measure: measurement, shouldSave: false };
  } else {
    const fromAnnotation = drawingLayoutApi && drawingLayoutApi.getInstanceMeasures(id, aiAnnotation.geometry);
    if (fromAnnotation) {
      return { measure: fromAnnotation, shouldSave: true };
    } else if (id in aiAnnotation.geometry) {
      const instance = aiAnnotation.geometry[id];
      const drawingInfo = drawingsInfo[instance.drawingId];
      const { scale, paperSize: paperSize } = DrawingsUtils.getCurrentPageScales(drawingInfo);
      const metersPerPx = DrawingsUtils.getMetersPerPx(drawingInfo.width, drawingInfo.height, paperSize);
      const measures =
        DrawingsGeometryUtils.calculateInstanceMeasurementsWithSavedPoints(
          instance,
          aiAnnotation.points,
          scale,
          metersPerPx,
        );
      return {
        measure: {
          type: instance.type,
          id,
          measures,
          color: instance.geometry.color,
        },
        shouldSave: true,
      };
    }
  }
  return { measure: null, shouldSave: false };
}

function getInstanceMeasuresGetter(drawingLayoutApi: DrawingLayoutApi): InstanceMeasuresGetter {
  return (id: string,
    drawingsInfo: Record<string, DrawingsShortInfo>,
    aiAnnotation: DrawingsGeometryState,
    getCurrentMeasurement: CurrentInstanceMeasureGetter,
  ) => getInstanceMeasure(drawingLayoutApi, id, drawingsInfo, aiAnnotation, getCurrentMeasurement);
}


function getInstanceMeasures(
  drawingLayoutApi: DrawingLayoutApi,
  instancesId: Iterable<string | string[]>,
  drawingsInfo: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  getCurrentMeasurement: CurrentInstanceMeasureGetter,
): { measures: DrawingsInstanceMeasure[], measuresForSave: DrawingsInstanceMeasure[] } {
  const measures = [];
  const measuresForSave = [];
  const addMeasureForSave = (measure: DrawingsInstanceMeasure, shouldSave: boolean): void => {
    if (measure) {
      measures.push(measure);
      if (shouldSave) {
        measuresForSave.push(measure);
      }
    }
  };
  for (const id of instancesId) {
    if (Array.isArray(id)) {
      const { measure, shouldSave } = getSegmentMeasures(
        id as [string, string],
        drawingsInfo,
        aiAnnotation,
        getCurrentMeasurement,
      );
      addMeasureForSave(measure, shouldSave);
    } else {
      const { measure, shouldSave } = getInstanceMeasure(
        drawingLayoutApi,
        id,
        drawingsInfo,
        aiAnnotation,
        getCurrentMeasurement,
      );
      addMeasureForSave(measure, shouldSave);
    }
  }
  return { measures, measuresForSave };
}

function getInstancesMeasuresGetter(drawingLayoutApi: DrawingLayoutApi): InstancesMeasuresGetter {
  return (
    instancesId: Iterable<string | string[]>,
    drawingsInfo: Record<string, DrawingsShortInfo>,
    aiAnnotation: DrawingsGeometryState,
    getCurrentMeasurement: CurrentInstanceMeasureGetter,
  ) => getInstanceMeasures(drawingLayoutApi, instancesId, drawingsInfo, aiAnnotation, getCurrentMeasurement);
}

export interface DrawingsInstanceMeasureHelper {
  getInstancesMeasures: InstancesMeasuresGetter;
  getSegmentMeasures: SegmentMeasuresGetter;
  getInstanceMeasures: InstanceMeasuresGetter;
}

export const getDrawingsInstanceMeasureHelper = (
  drawingLayoutApi: DrawingLayoutApi,
): DrawingsInstanceMeasureHelper => ({
  getInstancesMeasures: getInstancesMeasuresGetter(drawingLayoutApi),
  getSegmentMeasures,
  getInstanceMeasures: getInstanceMeasuresGetter(drawingLayoutApi),
});
