import { Icons } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';

import { MultilevelSelectOptionDataWithObjectValue } from 'common/components/multi-level-drop-down-menu';
import { usePersistedStorageValue } from 'persisted-storage/hooks';
import { useInstancesStyles, useSelectedGroupRoots } from '../../hooks';
import { DrawingsSelectAggregationGroup } from '../../interfaces/drawings-geometry';
import { ActionEventHandlers } from '../drawings-context-menu/interfaces';
import { MoveToCellOptionData } from '../interfaces/options';
import { ContinueButton } from './continue-button';
import { DrawingsFlyingMenuIconButton } from './drawings-flying-menu-icon-button';
import { useAggregatedSelection } from './hooks/use-aggregated-selection';
import { useFlyingMenuCallbacks } from './hooks/use-flying-menu-callbacks';
import { useFlyingMenuItems } from './hooks/use-flying-menu-items';
import { InteractiveContextMenu } from './interactive-context-menu';
import { MoveToCellMenu } from './move-to-cell-menu/move-to-cell-menu';
import { Styled } from './styled';


export const DrawingsFlyingMenuContainer = Styled.Container;


interface NewProps {
  contextMenuActions: Record<string, ActionEventHandlers>;
  selectedInstances: string[];
  sendRef: (ref: HTMLDivElement) => void;
  traceLink: (instanceId: string) => void;
  canTraceLink: boolean;
  moveToGroup: () => void;
  startContinue: () => void;
  moveToCell: (type: string) => void;
  folderToCell: (type: string) => void;
  hasMeasures: boolean;
  getCurrentMeasureTypes: () => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>;
  canEditMeasurement: boolean;
  canViewMeasurement: boolean;
  canEditReport: boolean;
  getCurrentFolderToCell: () => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>;
}

export const DrawingsFlyingMenuNew: React.FC<NewProps> = ({
  contextMenuActions,
  selectedInstances,
  sendRef,
  traceLink,
  canTraceLink,
  moveToGroup,
  startContinue,
  moveToCell,
  folderToCell,
  hasMeasures,
  getCurrentMeasureTypes,
  canEditMeasurement,
  canViewMeasurement,
  canEditReport,
  getCurrentFolderToCell,
}) => {

  const { aggregatedSelection, currentType } = useAggregatedSelection(selectedInstances);
  const selectedGroupRoots = useSelectedGroupRoots();
  const callbacks = useFlyingMenuCallbacks({
    instancesIds: selectedInstances,
    moveToGroup,
    traceLink,
  });
  const items = useFlyingMenuItems({
    selectedType: currentType,
    aggregatedSelection,
    selectedInstances,
    canTraceLink,
  });

  const { color } = useInstancesStyles(selectedInstances);

  const canContinue = useMemo(() => {
    return canEditMeasurement
      && (currentType === DrawingsSelectAggregationGroup.Count || currentType === DrawingsSelectAggregationGroup.Line)
      && aggregatedSelection[currentType].count === 1;
  }, [aggregatedSelection, canEditMeasurement]);

  const isFlyingMenuPinned = usePersistedStorageValue('drawingsPinFlyingMenu');

  if (!canViewMeasurement || !Object.keys(aggregatedSelection).length) {
    return null;
  }

  return (
    <DrawingsFlyingMenuContainer ref={sendRef} pinned={isFlyingMenuPinned}>
      {items.map((x) => (
        <DrawingsFlyingMenuIconButton
          key={x.actionKey}
          tooltip={x.tooltip}
          icon={x.icon}
          onClick={callbacks[x.actionKey]}
        />
      ))}
      {selectedGroupRoots.length === 1 && canEditReport && (
        <MoveToCellMenu
          getVariants={getCurrentFolderToCell}
          moveToCell={folderToCell}
          color={color}
          icon={Icons.LinkOnGroup}
          tooltip='Group to Cell'
        />
      )}
      {hasMeasures && canEditReport && (
        <MoveToCellMenu
          getVariants={getCurrentMeasureTypes}
          moveToCell={moveToCell}
          color={color}
          icon={Icons.MoveToCell}
          tooltip='Move to Cell'
        />
      )}
      {canContinue ? <ContinueButton startContinue={startContinue} /> : null}
      <InteractiveContextMenu
        selectedInstancesIds={selectedInstances}
        contextMenuActions={contextMenuActions}
      />
    </DrawingsFlyingMenuContainer>
  );
};

export const DrawingsFlyingMenu = DrawingsFlyingMenuNew;
