/* eslint-disable indent */
import { AssignedPia } from '2d/index';
import { arrayUtils } from 'common/utils/array-utils';
import {
  DrawingsBatchUpdateGeometries,
  DrawingsUpdateAnnotationGeometry,
  DrawingsUpdateAnnotationLines,
  DrawingsUpdateGeometry,
} from '../actions/payloads/annotation';
import { DrawingsElementChanges } from '../actions/payloads/update';
import { GetDrawingInstanceCallback, GetPointCoordinatesCallback } from '../drawings-geometry/interfaces';
import { DrawingsInstancesUpdateType } from '../enums';
import { DrawingChangeOperation } from '../enums/drawing-change-operation';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import { DrawingsGeometryInstance } from '../interfaces/drawings-geometry-instance';
import { DrawingAnnotationUtils } from './drawing-annotation-utils';
import { DrawingsGeometryUtils } from './drawings-geometry-utils';


function createLinesUpdate(
  removedLines: string[],
  addLines: Record<string, [string, string]>,
): DrawingsUpdateAnnotationLines {
  return { removedLines, addLines };
}

function createUpdatePayload(
  instancesUpdates: DrawingsUpdateGeometry,
  updatedPoints?: Record<string, ShortPointDescription>,
  removePoints?: string[],
  addPoints?: Record<string, ShortPointDescription>,
  lines?: DrawingsUpdateAnnotationLines,
  ): DrawingsUpdateAnnotationGeometry {
  return {
    lines,
    instance: instancesUpdates,
    updatePoints: updatedPoints,
    newPoints: addPoints,
    removePoints,
  };
}

function convertLinesUpdate(payload: DrawingsUpdateAnnotationLines): DrawingsUpdateAnnotationLines {
  return {
    removedLines: Object.keys(payload.addLines),
    addLines: arrayUtils.toDictionary(
      payload.removedLines,
      x => x,
      x => DrawingAnnotationUtils.getPointsIdsFromLineKey(x),
    ),
  };
}

function convertUpdateForRestore(
  payload: DrawingsUpdateAnnotationGeometry,
  getPoint: (pointId: string) => ShortPointDescription,
  getInstance: (instanceId: string) => DrawingsGeometryInstance,
): DrawingsUpdateAnnotationGeometry {
  const { removePoints, lines, instance } = payload;
  return {
    newPoints: removePoints ? arrayUtils.toDictionary(removePoints, x => x, getPoint) : undefined,
    lines: lines ? convertLinesUpdate(lines) : undefined,
    instance: { id: instance.id, geometry: getInstance(instance.id).geometry, type: instance.type },
  };
}

function getInstancesForRestore(
  instancesIds: Iterable<string>,
  getPointCoordinates: (id: string) => ShortPointDescription,
  getInstance: (id: string) => DrawingsGeometryInstance,
  getPia: (id: string) => AssignedPia,
): {
  newPoints: Record<string, ShortPointDescription>,
  addedInstances: DrawingsGeometryInstance[],
  pia: Record<string, AssignedPia>,
} {
  const newPoints = {};
  const addedInstances = [];
  const pia = {};
  for (const instanceId of instancesIds) {
    const instance = getInstance(instanceId);
    const instancePia = getPia(instanceId);
    if (instancePia) {
      pia[instanceId] = instancePia;
    }
    if (DrawingsGeometryUtils.isPolyGeometry(instance.type, instance.geometry)
      || DrawingsGeometryUtils.isCount(instance.type, instance.geometry)) {
        for (const pointId of instance.geometry.points) {
          newPoints[pointId] = getPointCoordinates(pointId);
        }
        if (DrawingsGeometryUtils.isPolygon(instance.type, instance.geometry) && instance.geometry.children) {
          for (const child of instance.geometry.children) {
            for (const pointId of child) {
              newPoints[pointId] = getPointCoordinates(pointId);
            }
          }
        }
      }
    addedInstances.push({ id: instanceId, ...instance });
  }
  return { newPoints, addedInstances, pia };
}


function convertInstancesRestore(
  payload: DrawingsBatchUpdateGeometries,
  getPointCoordinates: (id: string) => ShortPointDescription,
  getInstance: (id: string) => DrawingsGeometryInstance,
  getPia: (id: string) => AssignedPia,
): DrawingsBatchUpdateGeometries {
  const {
    newPoints,
    addedInstances,
  } = getInstancesForRestore(payload.removedInstances, getPointCoordinates, getInstance, getPia);
  return {
    removedInstances: payload.addedInstances.map(x => x.id),
    addedInstances,
    newPoints,
    pageId: payload.pageId,
  };
}

interface MakeElementUpdatesByPagesPayload<T> {
  targetInstances: Iterable<T>;
  instances: Record<string, DrawingsGeometryInstance>;
  operation: DrawingChangeOperation;
  idGetter: (instance: T) => string;
  updateType?: DrawingsInstancesUpdateType;
  pia?: Record<string, AssignedPia>;
}

function makeElementUpdatesByPages<T>(
  {
    targetInstances,
    instances,
    operation,
    idGetter,
    updateType = DrawingsInstancesUpdateType.Full,
    pia,
  }: MakeElementUpdatesByPagesPayload<T>,
): DrawingsElementChanges[] {
  const changesByPage: Record<string, DrawingsElementChanges> = {};
  for (const instance of targetInstances) {
    const id = idGetter(instance);
    const pageId = instances[id]?.drawingId;
    if (!changesByPage[pageId]) {
      changesByPage[pageId] = {
        pageId,
        operation,
        elementIds: [],
        updateType,
        pia: {},
      };
    }
    changesByPage[pageId].elementIds.push(id);
    if (pia && pia[id]) {
      changesByPage[pageId].pia[id] = pia[id];
    }
  }
  return Object.values(changesByPage);
}

function convertBatchedUpdate(
  payload: DrawingsBatchUpdateGeometries,
  getPointCoordinates: GetPointCoordinatesCallback,
  getInstance: GetDrawingInstanceCallback,
  getPia: (id: string) => AssignedPia,
): DrawingsBatchUpdateGeometries {
  const restoreData: DrawingsBatchUpdateGeometries = {
    pageId: payload.pageId,
  };
  if (payload.updated) {
    restoreData.updated = convertUpdateForRestore(payload.updated, getPointCoordinates, getInstance);
  }
  if (payload.removedInstances) {
    const {
      newPoints,
      addedInstances,
      pia,
    } = getInstancesForRestore(payload.removedInstances, getPointCoordinates, getInstance, getPia);
    restoreData.newPoints = newPoints;
    restoreData.addedInstances = addedInstances;
    restoreData.pia = pia;
  }
  if (payload.addedInstances) {
    restoreData.removedInstances = payload.addedInstances.map(x => x.id);
  }
  return restoreData;
}

export const DrawingsUpdateUtils = {
  createUpdatePayload,
  createLinesUpdate,
  convertUpdateForRestore,
  convertInstancesRestore,
  getInstancesForRestore,
  makeElementUpdatesByPages,
  convertBatchedUpdate,
};

