import { RectangleButton, Text } from '@kreo/kreo-ui-components';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

import { RenderIf } from 'common/components/render-if';
import { DateFormatConstants } from 'common/constants/date-formats';
import { CompanySubscriptionModel } from '../../../../../units/account/interfaces/company-subscription-model';
import { SubscriptionActivityStatus } from '../../../../../units/subscription/enums/subscription-activity-status';
import { EstimateSubscriptionModel } from '../../../../../units/subscription/interfaces/estimation-subscription-model';
import { PAYMENTS_DETAILS_DIALOG } from '../../payment-details-dialog';
import { Styled } from './styled';


interface Props {
  subscription: CompanySubscriptionModel;
  billingEstimation: EstimateSubscriptionModel;
  openDialog: (name: string) => void;
}

export const PaymentDetailsBlock: React.FC<Props> = ({ subscription, billingEstimation, openDialog }: Props) => {
  const isTrialActive = subscription?.trialEnd && subscription?.billingStatus === SubscriptionActivityStatus.Trial;

  const total = useMemo(() => {
    return billingEstimation?.nextInvoice && billingEstimation.nextInvoice?.total
      ? billingEstimation?.nextInvoice.total / 100
      : billingEstimation?.immediateInvoice && billingEstimation.immediateInvoice?.total
        ? billingEstimation?.immediateInvoice.total / 100
        : null;
  }, [billingEstimation]);

  const nextBillingDate = useMemo(() => {
    return billingEstimation?.nextInvoice && billingEstimation?.nextBillingAt
      ? moment(billingEstimation.nextBillingAt).format(DateFormatConstants.SUBSCRIPTION_DATE_FORMAT)
      : subscription?.nextBillingAt
        ? moment(subscription.nextBillingAt).format(DateFormatConstants.SUBSCRIPTION_DATE_FORMAT)
        : null;
  }, [billingEstimation, subscription]);

  const handleOnDetailsClick = useCallback(() => {
    openDialog(PAYMENTS_DETAILS_DIALOG);
  }, [openDialog]);

  if (!nextBillingDate && !total && !isTrialActive) {
    return null;
  }

  return (
    <Styled.PaymentDetails>
      <RenderIf condition={isTrialActive}>
        <Text fontSize={14}>
          <Text fontSize={14} color="additionalFont">
            <pre>{'Trial end at '}</pre>
          </Text>
          <pre>
            {moment(subscription?.trialEnd).format(DateFormatConstants.SUBSCRIPTION_DATE_FORMAT)}
          </pre>
        </Text>
      </RenderIf>
      <RenderIf condition={!!nextBillingDate}>
        <Text fontSize={14}>
          <Text fontSize={14} color="additionalFont">
            <pre>{'Next billing at '}</pre>
          </Text>
          <pre>{nextBillingDate}</pre>
        </Text>
      </RenderIf>
      <RenderIf condition={!!total}>
        <Text fontSize={14}>
          <Text fontSize={14} color="additionalFont">
            <pre>{'Total: '}</pre>
          </Text>
          £{total}
        </Text>
        <RenderIf condition={!!billingEstimation}>
          <Styled.DetailsButton>
            <RectangleButton
              size="s"
              height={18}
              width={72}
              onClick={handleOnDetailsClick}
              text={'More details'}
            />
          </Styled.DetailsButton>
        </RenderIf>
      </RenderIf>
    </Styled.PaymentDetails>
  );
};
