import autobind from 'autobind-decorator';
import React from 'react';
import { createPortal } from 'react-dom';
import onClickOutside from 'react-onclickoutside';

import { RenderIf } from 'common/components/render-if';
import { Blanket, getOrCreateRoot } from 'common/UIKit';
import { MovableContainer, MovableContainerProps } from './movable-container';

export interface MovalbeDialogProps extends MovableContainerProps {
  blockEvents?: boolean;
  onClose: () => void;
}

class MovableDialogComponent extends React.Component<MovalbeDialogProps> {
  public render(): React.ReactNode {
    const { children, blockEvents, onClose, ...movableContainerProps } = this.props;
    return createPortal(
      <>
        <RenderIf condition={blockEvents}>
          <Blanket onClick={onClose} zIndex={1002}/>
        </RenderIf>
        <MovableContainer
          {...movableContainerProps}
        >
          {children}
        </MovableContainer>
      </>,
      getOrCreateRoot(),
    );
  }

  @autobind
  public handleClickOutside(): void {
    if (!this.props.blockEvents) {
      this.props.onClose();
    }
  }
}


export const DialogContainer = onClickOutside(MovableDialogComponent);
