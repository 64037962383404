import autobind from 'autobind-decorator';
import * as React from 'react';

import './custom-element-filter-builder-string-item.scss';

import { CustomElementFilterStringOperation } from 'common/enums/custom-element-filter-string-operation';
import { Checkbox } from 'common/UIKit';
import { Autocomplete, AutocompleteOption, AutocompleteOptionWithGroupName } from '../autocomplete';
import { CustomElementFilterBuilderOperationSelect } from './custom-element-filter-builder-operation-select';
import { CustomFilterBuilderAutocompleteOption, InnerCustomElementFilterString } from './inner-interfaces';


interface Props {
  filterItem: InnerCustomElementFilterString;
  onChange: (item: InnerCustomElementFilterString) => void;
  predefinedValues: CustomFilterBuilderAutocompleteOption[];
  isOnlyModelValues: boolean;
}

interface State {
  filteredPredefinedValues: AutocompleteOption[];
}


export class CustomElementFilterBuilderStringItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { predefinedValues, isOnlyModelValues, filterItem } = props;
    const value = filterItem && filterItem.operationArg;
    this.state = {
      filteredPredefinedValues: this.getFilteredPredefinedValues(predefinedValues, isOnlyModelValues, value),
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    const { predefinedValues, isOnlyModelValues, filterItem } = this.props;
    if (prevProps.predefinedValues !== predefinedValues || prevProps.isOnlyModelValues !== isOnlyModelValues) {
      const value = filterItem && filterItem.operationArg;
      const filteredPredefinedValues = this.getFilteredPredefinedValues(predefinedValues, isOnlyModelValues, value);
      this.setState({ filteredPredefinedValues });
    }
  }

  public render(): JSX.Element {
    const { filterItem } = this.props;
    const { filteredPredefinedValues } = this.state;

    return (
      <div className='custom-element-filter-builder-string-item'>
        <CustomElementFilterBuilderOperationSelect
          value={filterItem.operation}
          onChange={this.onOperationChange}
          values={CustomElementFilterStringOperation}
          className='custom-element-filter-builder-string-item__operation-select'
        />
        {/* <CustomElementFilterBuilderOperationSelect
          value={filterItem.collectionOperation}
          onChange={this.onCollectionOperationChange}
          values={CustomElementFilterCollectionOperation}
          className='custom-element-filter-builder-string-item__collection-select'
        /> */}
        <Autocomplete
          options={filteredPredefinedValues}
          onChange={this.onOperationArgChange}
          value={filterItem.operationArg}
          getCustomValuesFromName={this.getCustomValuesFromName}
          getCustomValuesFromKey={this.getCustomValueFromNameOrKey}
        />
        <div className='custom-element-filter-builder-string-item__checkbox'>
          <Checkbox
            label='case sensitive'
            onChange={this.onIsCaseSensitiveChange}
            checked={filterItem.isCaseSensitive}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onOperationChange(operation: CustomElementFilterStringOperation): void {
    const { filterItem, onChange } = this.props;
    onChange({
      ...filterItem,
      operation,
    });
  }

  @autobind
  private onIsCaseSensitiveChange(isCaseSensitive: boolean): void {
    const { filterItem, onChange } = this.props;
    onChange({
      ...filterItem,
      isCaseSensitive,
    });
  }

  @autobind
  private onOperationArgChange(operation: AutocompleteOption): void {
    const { filterItem, onChange } = this.props;
    onChange({
      ...filterItem,
      operationArg: operation && operation.value,
    });
  }

  @autobind
  private getCustomValuesFromName(name: string): AutocompleteOption[] {
    const value = this.getCustomValueFromNameOrKey(name);
    return value
      ? [value]
      : [];
  }

  private getCustomValueFromNameOrKey(nameOrKey: string): AutocompleteOptionWithGroupName {
    if (!nameOrKey) {
      return null;
    }

    return {
      name: nameOrKey,
      value: nameOrKey,
      groupName: 'add custom value',
      type: null,
      isSelectable: true,
    };
  }

  private getFilteredPredefinedValues(
    options: CustomFilterBuilderAutocompleteOption[],
    isOnlyModelValues: boolean,
    value: string,
  ): AutocompleteOption[] {
    if (!options) {
      return null;
    }

    if (!isOnlyModelValues) {
      return options;
    }

    const result = [];
    for (const option of options) {
      const children = this.getFilteredPredefinedValues(option.children, isOnlyModelValues, value);
      const isSelectedValue = option.value === value;
      const isVisible = ((children && children.length) ||  option.isSelectable) && option.isContainsInModel;
      if (isVisible || isSelectedValue) {
        result.push(option);
      }
    }

    return result;
  }
}
