import { UsersList } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';


interface Props {
  invitedPeople: string[];
}

const InvitedPeopleListComponent: React.FC<Props> = ({
  invitedPeople,
}) => {
  if (invitedPeople.length === 0) {
    return null;
  }

  return (
    <Styled.UsersListContainer>
      <UsersList
        maxUsers={10}
        users={invitedPeople.map(p => ({ name: p, email: p }))}
      />
    </Styled.UsersListContainer>
  );
};

export const InvitedPeopleList = React.memo(InvitedPeopleListComponent);
