import { IconBidPricing } from './bid-pricing';
import { IconComment } from './comment';
import { IconCost } from './cost';
import { IconDatabase } from './database';
import { IconGrouping } from './grouping';
import { IconResources } from './resources';
import { IconScenario } from './scenario';
import { IconSequence } from './sequence';
import { IconSettings } from './settings';
import { IconUserDelete } from './user-delete';
import { IconUserInvite } from './user-invite';
import { IconUserSettings } from './user-settings';
import { IconValidation } from './validation';
import { IconWorkPackage } from './work-package';

export const notificationIcons = {
  IconBidPricing,
  IconComment,
  IconCost,
  IconDatabase,
  IconGrouping,
  IconResources,
  IconScenario,
  IconSequence,
  IconSettings,
  IconUserDelete,
  IconUserInvite,
  IconUserSettings,
  IconValidation,
  IconWorkPackage,
};
