import React from 'react';

interface Props {
  vertical: boolean;
  size: number;
  percentage: boolean;
  children: any;
  childRef: React.RefObject<HTMLDivElement>;
  minSize: number;
}

export class Pane extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    vertical: false,
    size: 0,
    percentage: false,
    children: [],
  };

  public render(): React.ReactNode {
    const size = this.props.size || 0;
    const minSize = this.props.minSize;
    const unit = this.props.percentage ? '%' : 'px';
    const classes = 'layout-pane';
    const style: React.CSSProperties = {};
    if (this.props.vertical) {
      style.height = `${size}${unit}`;
      style.minHeight = `${minSize}${unit}`;
    } else {
      style.width = `${size}${unit}`;
      style.minWidth = `${minSize}${unit}`;
    }
    return (
      <div
        className={classes}
        style={style}
        ref={this.props.childRef}
      >
        {this.props.children}
      </div>
    );
  }
}
