import autobind from 'autobind-decorator';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsPolylineGeometry } from 'common/components/drawings/interfaces';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { EngineObject } from '../../../common';
import {
  DrawingsGeometryEntityPolyline,
  DrawingsGeometryEntityPolylineConfig,
} from '../../../drawings-geometry-entities';
import { ModifiableInstanceType } from '../../drawings-geometry-modify';
import { GeometryFactory } from './geometry-factory';

export class PolylineFactory extends EngineObject<Partial<DrawingsGeometryEntityPolylineConfig>>
  implements GeometryFactory<DrawingsPolylineGeometry, DrawingsGeometryEntityPolylineConfig>  {

  @autobind
  public render(
    instanceId: string,
    geometry: DrawingsPolylineGeometry,
    color: DrawingsPaperColorInfo,
    overrodeProps: Partial<DrawingsGeometryEntityPolylineConfig>,
  ): ModifiableInstanceType {
    const config = {
      ...this._config,
      id: instanceId,
      geometry,
      color,
      instanceType: DrawingsInstanceType.Polyline,
      ...overrodeProps,
    };

    return new DrawingsGeometryEntityPolyline(config as DrawingsGeometryEntityPolylineConfig);
  }
}

