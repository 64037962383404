import * as React from 'react';

import { SpliterPaneConfig, SplitterLayout } from '../../../../../components/splitter-layout';
import { ViewProps } from './interfaces';

export class FourViewsLeftSplit extends React.Component<ViewProps> {
  private horizontalSize: SpliterPaneConfig[] = [];
  private verticalSize: SpliterPaneConfig[] = [];
  constructor(props: ViewProps) {
    super(props);
    const widthSize = props.width / 2;
    const heighSize = props.height / 3;
    this.verticalSize = [
      { size: widthSize, minSize: 50 },
      { size: widthSize, minSize: 50 },
    ];
    this.horizontalSize = [
      { size: heighSize, minSize: 50 },
      { size: heighSize, minSize: 50 },
      { size: heighSize, minSize: 50 },
    ];

  }

  public render(): JSX.Element {
    return (
      <SplitterLayout
        primaryIndex={0}
        horizontal={false}
        paneConfigs={this.verticalSize}
      >
        <SplitterLayout
          primaryIndex={0}
          horizontal={true}
          paneConfigs={this.horizontalSize}
        >
          {this.props.children[1]}
          {this.props.children[2]}
          {this.props.children[3]}
        </SplitterLayout>
        {this.props.children[0]}
      </SplitterLayout>);
  }
}
