import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './model-management-file.scss';

import { IconButton, KreoIconDelBasket } from 'common/UIKit';

interface Props {
  id: number | string;
  name: string;
  isNew?: boolean;
  bimElementIds?: number[];
  selected?: boolean;
  onDeleteFile: (id: number | string) => void;
  onSelectFile?: (id: number, bimIds: number[]) => void;
}

export class ModelManagementFile extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <div
        className={
          classNames(
            'model-management-file',
            {
              'model-management-file--selected': this.props.selected,
              'model-management-file--new': this.props.isNew,
            },
          )
        }
        onClick={this.onSelect}
      >
        <span
          className='model-management-file__name'
          title={this.props.name}
        >
          {this.props.name}
        </span>
        <IconButton
          className='model-management-file__del-btn'
          rounded={false}
          size='medium'
          onClick={this.onDelete}
          controlName='file-delete-button'
        >
          <KreoIconDelBasket />
        </IconButton>
      </div>
    );
  }

  @autobind
  private onSelect(): void {
    if (!this.props.isNew) {
      this.props.onSelectFile(this.props.id as number, this.props.bimElementIds);
    }
  }

  @autobind
  private onDelete(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.props.onDeleteFile(this.props.id);
  }
}
