import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${p => p.theme.color.backgroundRush};
  padding: 10px 15px;
  border-radius: 10px;
  height: 40px;
  box-sizing: border-box;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg { 
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }
`;

export const Styled = {
  Container,
  TitleContainer,
};
