import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import { CollapseIcon } from './collapse-icon';

import './name.scss';

interface Props {
  level_of_nesting: number;
  collapsed: boolean;
  isExpandable: boolean;
  name: string;
  innerId: string;
  onCollapse: () => void;
}


class Name extends React.Component<Props> {
  public render(): React.ReactNode {
    const { level_of_nesting, collapsed, name } = this.props;
    return (
      <div
        className={classNames('cost-estimate-table__name', { expandable: this.props.isExpandable })}
        onClick={this.onCollapse}
        data-control-name='expand-cost-estimate-row'
      >
        <div
          className='cost-estimate-table__name-inner'
          title={name}
        >
          {this.getImage(level_of_nesting, collapsed)}
          <span>{name}</span>
        </div>
        {this.props.children}
      </div>
    );
  }

  @autobind
  private onCollapse(): void {
    if (this.props.isExpandable) {
      this.props.onCollapse();
    }
  }

  @autobind
  private getImage(level: number, collapsed: boolean): React.ReactNode {
    if (level === 0) {
      return null;
    }

    if (this.props.isExpandable) {
      return <CollapseIcon collapsed={collapsed} level={level} />;
    }

    return <div className={'cost-estimate-table__collapse-icon'} />;
  }

}


export default Name;
