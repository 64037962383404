import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './measurements-revit-tree-layout.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State } from 'common/interfaces/state';
import { KreoInput } from 'common/UIKit';
import { CEMeasurementsActions } from 'unit-cost-estimate/actions/creators';
import { ValidationHidableLayout } from 'unit-projects/components/validation-hidable-layout';
import { MeasurementsRevitTreeBody } from './measurements-revit-tree-body';


interface StateProps {
  showTree: boolean;
  loaded: boolean;
}

interface DispatchProps {
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onShowHide: () => void;
}

interface Props extends StateProps, DispatchProps {
}

const MeasurementsRevitTreeLayoutComponent: React.FC<Props> = props => {
  if (!props.loaded) {
    return (
      <ValidationHidableLayout
        toggleShowStatus={props.onShowHide}
        isShow={props.showTree}
      >
        <SvgSpinner size='middle' />
      </ValidationHidableLayout>);
  }
  return (
    <ValidationHidableLayout
      toggleShowStatus={props.onShowHide}
      isShow={props.showTree}
    >
      <div className='measurements-revit-tree-layout__search'>
        {props.showTree && <KreoInput placeholder='Search' type='text' onChange={props.onSearch} />}
      </div>
      <MeasurementsRevitTreeBody/>
    </ValidationHidableLayout>
  );
};

function mapStateToProps({ ceMeasurements }: State): StateProps {
  return {
    showTree: ceMeasurements.viewTree,
    loaded: ceMeasurements.dataLoaded,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onSearch: (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(CEMeasurementsActions.runSearch(e.target.value));
    },
    onShowHide: () => {
      dispatch(CEMeasurementsActions.showHideTree());
    },
  };
}


const connector = connect(mapStateToProps, mapDispatchToProps);
export const MeasurementsRevitTreeLayout =
  connector(MeasurementsRevitTreeLayoutComponent);
