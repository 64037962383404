import * as React from 'react';

export const KreoIconPlay: React.FC = () => {
  return (
    <svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-161.000000, -568.000000)'>
        <g id='Timeline' transform='translate(60.000000, 556.000000)'>
          <g id='Top-panel'>
            <g id='Controls'>
              <g transform='translate(65.000000, 12.000000)' id='4D_controls_play'>
                <g transform='translate(36.000000, 0.000000)'>
                  <g id='play'>
                    <circle id='Oval' strokeOpacity='0.2' stroke='#FFFFFF' cx='16' cy='16' r='15.5' />
                    <path
                      // eslint-disable-next-line max-len
                      d='M15.5812382,12.1294559 L19.8607731,15.1862665 C20.3101857,15.5072755 20.4142774,16.1318256 20.0932684,16.5812382 C20.0291288,16.6710337 19.9505687,16.7495938 19.8607731,16.8137335 L15.5812382,19.8705441 C15.1318256,20.1915531 14.5072755,20.0874615 14.1862665,19.6380489 C14.0651231,19.4684481 14,19.2652337 14,19.0568107 L14,12.9431893 C14,12.3909046 14.4477153,11.9431893 15,11.9431893 C15.208423,11.9431893 15.4116374,12.0083125 15.5812382,12.1294559 Z'
                      id='Rectangle-2'
                      fill='#FFFFFF'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
