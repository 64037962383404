import * as React from 'react';
import { Link } from 'react-router-dom';

import { CommentsButton } from 'unit-2d-comments/comments-button';
import { NotificationBell } from 'unit-2d-notification/components';
import { PageHeaderAvatar } from '../page-header-avatar';
import { ProjectMenu } from './project-menu';
import { Styled } from './styled';

interface Props {
  redirectUrl: string;
  logoSrc: string;
  hideButton?: boolean;
  showProjectMenu?: boolean;
  size?: number;
  Icon?: React.ComponentType;
  onClick?: () => void;
}

export const PageHeader: React.FC<Props> = ({
  redirectUrl,
  logoSrc,
  hideButton,
  size,
  onClick,
  children,
  Icon,
  showProjectMenu,
}) => {
  return (
    <Styled.Container className='page-header'>
      <Link to={redirectUrl} className='page-header__logo'>
      {
        Icon ?
        <Styled.IconContainer onClick={onClick}>
          <Icon />
        </Styled.IconContainer>
          : <img src={logoSrc} alt='Logo' onClick={onClick} />
      }
      </Link>
      {showProjectMenu && <ProjectMenu/>}
      <Styled.Content>
        {children}
      </Styled.Content>
      <Styled.UserMenu>
        {!hideButton && <NotificationBell target='project' />}
        {!hideButton && <CommentsButton/>}
        <PageHeaderAvatar size={size}/>
      </Styled.UserMenu>
    </Styled.Container>
  );
};
