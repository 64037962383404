import classNames from 'classnames';
import * as React from 'react';

import './expand-button.scss';

import { KreoIconExpandTriangle } from '../icons';

interface ExpandIconProps {
  expandable: boolean;
  expanded: boolean;
  onExpand: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const ExpandButton: React.FC<ExpandIconProps> = (props): JSX.Element => {
  let icon: JSX.Element = null;
  if (props.expandable) {
    icon = (
        <div
          onClick={props.onExpand}
          data-control-name='collapse-button'
          className={classNames('exp-icon', { 'expanded': props.expanded })}
        >
          <KreoIconExpandTriangle />
        </div>
    );
  }
  return icon;
};
