import * as Ag from 'ag-grid-community';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';

type Data = string | number;
export type RowDataStore = Record<string, Data> & { id: number, index: number };
export interface SheetDataStore { rows: RowDataStore[]; columns: Ag.ColDef[] }
export type CellDataStore = Record<string, SheetDataStore>;

export interface CellDataPath {
  sheetId: string;
  columnId: string;
  rowIndex: number;
}

export class ReportCellDataStore {
  private store: CellDataStore = {};

  public clearStore(): void {
    this.store = {};
  }

  public duplicateSheetStore(sourceSheetId: string, targetSheetId: string): void {
    const sourceStore = this.selectSheetDataStore(sourceSheetId);
    const targetStore = this.cloneStore(sourceStore);
    this.setSheetStore(targetSheetId, targetStore);
  }

  public setSheetStore(sheetId: string, sheetStore: SheetDataStore): void {
    this.store[sheetId] = sheetStore;
  }

  public deleteSheetStore(sheetId: string): void {
    delete this.store[sheetId];
  }

  public selectSheetDataStore(sheetId: string): SheetDataStore {
    const sheetStore = this.store[sheetId];
    if (sheetStore) {
      return sheetStore;
    }
    this.store[sheetId] = { rows: [], columns: [] };
    return this.store[sheetId];
  }

  public updateCellDataStore(path: CellDataPath, data: Data): void {
    const rowStore = this.getRowDataStore(path.sheetId, path.rowIndex);
    rowStore[path.columnId] = data;
  }

  public selectCellData(path: CellDataPath): Data {
    return this.getRowDataStore(path.sheetId, path.rowIndex)[path.columnId];
  }

  public getRowDataStore(sheetId: string, rowIndex: number): RowDataStore {
    const sheetStore = this.selectSheetDataStore(sheetId);
    const rowStore = sheetStore?.rows[rowIndex];
    if (rowStore) {
      return rowStore;
    }

    sheetStore.rows[rowIndex] = { id: rowIndex + 1, index: rowIndex + 1 };
    return sheetStore.rows[rowIndex];
  }

  public getSheetsDataStore(): CellDataStore {
    return this.store;
  }

  public addRows(sheetId: string, count: number): RowDataStore[] {
    const newRows = new Array<RowDataStore>(count);
    const lastRowIndex = this.store[sheetId].rows.length;
    for (let i = 0; i < newRows.length; i++) {
      const newRow = { index: lastRowIndex + i + 1, id: lastRowIndex + i + 1 };
      this.store[sheetId].rows.push(newRow);
      newRows[i] = newRow;
    }

    return newRows;
  }

  public setColumnWidth(sheetId: string, columnId: string, width: number): void {
    const columns = this.store[sheetId].columns;
    const columnIndex = ExcelColumnHelper.excelColNameToIndex(columnId);
    columns[columnIndex].width = width;
  }

  public addNewColumns(sheetId: string, count: number): void {
    const columns = this.store[sheetId].columns;
    const newColumns = [];
    for (let i = 0; i < count; i++) {
      newColumns.push(this.getDefaultColumnDef({}, i + columns.length - 1));
    }
    this.store[sheetId].columns = columns.concat(newColumns);
  }

  public getDefaultColumnDef(column: Ag.ColDef, index: number): Ag.ColDef {
    const alphabetKey = ExcelColumnHelper.indexToExcelColName(index + 1);
    column.headerName = alphabetKey;
    column.colId = alphabetKey;
    column.field = alphabetKey;
    return column;
  }

  public setStore(store: CellDataStore): void {
    this.store = store;
  }

  private cloneStore(target: SheetDataStore): SheetDataStore {
    const copyStore: SheetDataStore = { rows: [], columns: [] };
    target.rows.forEach((row, index) => {
      copyStore[index] = { ...row };
    });
    target.columns.forEach((column, index) => {
      copyStore[index] = { ...column };
    });

    return copyStore;
  }
}
