import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { Feed } from 'common/interfaces/feed';
import { AdminPeopleActions } from '../actions/creators/people';
import { ChangeUserEmail, EnableUserParams, UpdateAttribute } from '../actions/payloads/people';
import { AdminPeopleActionTypes } from '../actions/types/people';
import { AdminPeopleApi } from '../api/people';
import { FeedParams } from '../interfaces/feed';
import { User } from '../interfaces/user';


function* loadFeed(
  action: ActionWith<FeedParams>,
): SagaIterator {
  try {
    const data: Feed<User> = yield call(AdminPeopleApi.getUserFeed, action.payload);
    yield put(AdminPeopleActions.loadUsersSucceeded(data));
  } catch (error) {
    console.error('admin people: load feed failed', error, action.payload);
  }
}

function* deleteUser({ payload: userId }: ActionWith<string>): SagaIterator {
  try {
    yield call(AdminPeopleApi.deleteUser, userId);
    yield put(AdminPeopleActions.deleteUserSucceeded(userId));
  } catch (error) {
    console.error('admin people: delete user failed', error, { userId });
  }
}

function* setUserEnabled(
  { payload }: ActionWith<EnableUserParams>,
): SagaIterator {
  try {
    const { guid: userId, enable: isEnabled } = payload;
    yield call(AdminPeopleApi.setUserEnabled, userId, isEnabled);
    yield put(AdminPeopleActions.enableUserSucceeded(payload));
  } catch (error) {
    console.error('admin people: set user enabled failed', error, payload);
  }
}

function* updateUserAttribute({ payload }: ActionWith<UpdateAttribute>): SagaIterator {
  try {
    const { key, value, userId } = payload;
    yield call(AdminPeopleApi.updateUserAttribute, userId, key, value);
    yield put(AdminPeopleActions.updateUserAttributeSucceed(key, value, userId));
  } catch (error) {
    console.error('admin people: update user attribute failed', error, payload);
  }
}

function* deleteOwnedCompany({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(AdminPeopleApi.deleteOwnedCompany, payload);
  } catch (error) {
    console.error('admin people: delete owned company failed', error, payload);
  }
}

function* changeUserEmail({ payload }: ActionWith<ChangeUserEmail>): SagaIterator {
  try {
    const { userId, email } = payload;
    yield call(AdminPeopleApi.setNewUserEmail, userId, email);
  } catch (error) {
    console.error('admin people: set user email failed', error, payload);
  }
}

export function* adminPeopleSagas(): SagaIterator {
  yield takeLatest(AdminPeopleActionTypes.LOAD_REQUEST, loadFeed);
  yield takeEvery(AdminPeopleActionTypes.DELETE_REQUEST, deleteUser);
  yield takeEvery(AdminPeopleActionTypes.ENABLE_USER_REQUEST, setUserEnabled);
  yield takeEvery(AdminPeopleActionTypes.UPDATE_USER_ATTRIBUTE_REQUEST, updateUserAttribute);
  yield takeLatest(AdminPeopleActionTypes.DELETE_OWNED_COMPANY, deleteOwnedCompany);
  yield takeLatest(AdminPeopleActionTypes.CHANGE_USER_EMAIL, changeUserEmail);
}
