import { Text, H2 } from '@kreo/kreo-ui-components';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Questionnaire } from 'common/components/questionnaire';
import { Spinner } from 'common/components/spinner';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { TRANSITION_POINT } from '../../constants';
import { AccountActions } from '../account/actions/creators';
import { CompanySubscriptionModel } from '../account/interfaces/company-subscription-model';
import { AccountSelectors } from '../account/selectors';
import { SegmentContextProps, withSegmentContext } from '../analytics/components/segment-context';
import { TrackerProvider } from '../analytics/components/utils/tracker-provider';
import { SubscriptionStatus } from '../subscription/enums/subscription-status';
import { EXTRA_TEXT, HEADER_NEW_USER, HEADER_OTHER_USER, LIST_QUESTION } from './interfaces';
import { Styled } from './styled';


interface StateProps {
  subscription: CompanySubscriptionModel;
  userId: string | null;
  userHash: string | null;
  ownCompanyId: number | null;
  userFirstName: string | null;
  userLastName: string | null;
  userEmail: string | null;
  companyId: number | null;
  companyName: string | null;
  currentPathname: string;
}

interface DispatchProps {
  setQuestionnaireCompleted: (isComplete: boolean) => void;
  onChangeUrl: (url: string) => void;
}

interface PageProps extends StateProps, DispatchProps, SegmentContextProps { }

const QuestionnairePageComponent: React.FC<PageProps>  = ({
  userId,
  userEmail,
  userFirstName,
  userLastName,
  userHash,
  ownCompanyId,
  companyId,
  companyName,
  subscription,
  currentPathname,
  setQuestionnaireCompleted,
  onChangeUrl,
}: PageProps) => {
  const isNone = !subscription || subscription.status === SubscriptionStatus.None;
  const headerText = isNone ? HEADER_NEW_USER : HEADER_OTHER_USER;
  const [isLoading, setIsLoading] = useState(false);

  const sendQuestionnaireForm = useCallback((form: Record<string, string>) => {
    setIsLoading(true);

    TrackerProvider.segmentTracker().identify(
      userEmail,
      {
        email: userEmail,
        userId,
        name: `${userFirstName} ${userLastName}`,
        company: {
          id: companyId,
          name: companyName,
        },
        ['Own Company Id']: ownCompanyId,
        ['Mobile Sign Up']: window.innerWidth < TRANSITION_POINT,
        ...form,
      },
      {
        integrations: {
          Intercom: {
            user_hash: userHash,
          },
        },
      },
      ConstantFunctions.doNothing,
    );
    setQuestionnaireCompleted(true);
    if (currentPathname) {
      if (currentPathname === AppUrls.qto2d.questionnaire.path) {
        onChangeUrl(AppUrls.qto2d.thankYou.path);
      } else {
        onChangeUrl(currentPathname);
      }
    } else {
      onChangeUrl(AppUrls.root.path);
    }
  }, [
    userEmail,
    userId,
    userFirstName,
    userLastName,
    companyId,
    companyName,
    ownCompanyId,
    userHash,
    currentPathname,
    setQuestionnaireCompleted,
    onChangeUrl,
  ]);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, [isLoading, setIsLoading]);

  return (
    <Styled.Container>
      <Spinner show={isLoading} withBackground={true} />
      <Styled.HeaderContainer>
        <H2>{headerText}</H2>
      </Styled.HeaderContainer>
      <Styled.ExtraText>
        <Text color={'gray'}>
          {EXTRA_TEXT}
        </Text>
      </Styled.ExtraText>
      <Styled.QuestionnaireContainer>
        <Questionnaire
          content={LIST_QUESTION}
          onClickContinue={sendQuestionnaireForm}
        />
      </Styled.QuestionnaireContainer>
      <Styled.ImageBackground>
        <img src='/static/img/questionnaireImg.png' />
      </Styled.ImageBackground>
    </Styled.Container>
  );
};


const mapStateToProps = (state: State): StateProps => {
  const account = state.account;

  const company = account && account.selectedCompany ? account.selectedCompany : null;
  const userId = account && account.id ? account.id : null;
  const userEmail = account && account.email ? account.email : null;
  const userHash = account && account.intercomUserHash ? account.intercomUserHash : null;
  const userFirstName = account && account.firstName ? account.firstName : null;
  const userLastName = account && account.lastName ? account.lastName : null;
  const ownCompanyId = account && account.ownedCompany && account.ownedCompany.id || null;
  const companyId = company && company.id || null;
  const companyName = company && company.name || null;

  return {
    subscription: AccountSelectors.currentSubscription(state),
    currentPathname: state.persistedStorage.currentPathname,
    userId,
    userEmail,
    userHash,
    userFirstName,
    userLastName,
    ownCompanyId,
    companyId,
    companyName,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    setQuestionnaireCompleted: (isComplete) => dispatch(AccountActions.setQuestionnaireCompleted(isComplete)),
    onChangeUrl: (url) => dispatch(push(url)),
  };
};

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const QuestionnairePage = withSegmentContext(
  reduxConnector(QuestionnairePageComponent),
);
