import { ResourceType } from '../enums';

export class ResourcesBaseUrlBuilder {
  public static getResourcesBaseUrl(databaseId: number, type: ResourceType): string {
    const resourceNameInUrl = this.getResourceNameInUrl(type);
    return `/databases/${databaseId}/${resourceNameInUrl}`;
  }

  private static getResourceNameInUrl(type: ResourceType): string {
    switch (type) {
      case ResourceType.Labour: return 'labors';
      case ResourceType.Material: return 'materials';
      case ResourceType.Plant: return 'plants';
      default: throw new Error(`getResourceNameInUrl(): unknown type ${type}`);
    }
  }
}
