import { Utils } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingMarkShapes } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsCanvasColors, DrawingsCanvasConstants } from '../../../constants/drawing-canvas-constants';
import { ShortPointDescription } from '../../../interfaces/drawing-ai-annotation';
import { MeasuresViewSettings } from '../../../interfaces/drawing-text-render-parameters';
import { DrawingsGeometryDragEventHelper } from '../../drawings-helpers/drawings-geometry-drag-event-helper';
import { ShapeFactory } from '../../interfaces';
import {
  DrawingsGeometryEntity,
  DrawingsGeometryEntityPointBase,
  DrawingsGeometryEntityPointBaseConfig,
} from '../base';
import { MouseEventsApplier } from './mouse-events-applier';

const ColorUtils = Utils.ColorUtils;

export interface DrawingsGeometryEntityCountMarkConfig  extends DrawingsGeometryEntityPointBaseConfig {
  geometry: paper.Point;
  order?: number;
  shape: DrawingMarkShapes;
  shapeCreator: ShapeFactory;
  color: paper.Color;
  rotation?: number;
  getPointInfo?: (id: string) => paper.Point;
  dragEventsHelper?: DrawingsGeometryDragEventHelper;
}

const COUNT_MARK_PADDING = 10;
export class DrawingsGeometryEntityCountMark
  extends DrawingsGeometryEntityPointBase<DrawingsGeometryEntityCountMarkConfig>
  implements DrawingsGeometryEntity {
  /* paper.js data */
  protected _point: paper.Group;
  private _circle: paper.Path.Circle;
  private _text: paper.PointText;
  private _bounds: number = 0;

  constructor(config: DrawingsGeometryEntityCountMarkConfig) {
    super(config);
    this._eventsApplier = new MouseEventsApplier({
      onClick: this.onClick,
      getDoubleClickHandler: this.getDoubleClickEventHandler,
      onMouseLeave: this.onMouseLeave,
      onStartDrag: this.onStartDrag,
      dragEventsHelper: this._config.dragEventsHelper,
    });
    this._eventsApplier.enableDrag(true);

    this._point = new paper.Group();
    if (config.order) {
      this._bounds = this.createTextAndGetBound(config.geometry, config.order);
    }
    this.createCircle(config.geometry, config.color, config.renderParamsContextObserver.getPrevContext().zoom);
    this._eventsApplier.setExtraInfo(this.id);
    this._eventsApplier.setHandlers(this._point);
    this._point.onMouseEnter = this.onMouseEnter;
    if (config.rotation) {
      this._point.rotate(-config.rotation, this._point.bounds.center);
    }
  }

  public destroy(): void {
    this._point.remove();
  }

  public get position(): ShortPointDescription {
    return [this._point.position.x, this._point.position.y];
  }

  public get paperPosition(): paper.Point {
    return this._point.position;
  }

  public set paperPosition(value: paper.Point) {
    this._point.position = value;
  }

  public set shape(shape: DrawingMarkShapes) {
    if (this._text) {
      this._text.remove();
    }
    this._circle.remove();
    this._config.shape = shape;
    this.render();
  }


  public get order(): number {
    return this._config.order;
  }

  public set order(order: number) {
    let rotation;
    if (this._text) {
      rotation = this._text.rotation;
      this._text.remove();
    }
    this._circle.remove();
    this._config.order = order;
    this.render();
    if (this._text) {
      this._text.rotate(rotation, this._point.bounds.center);
    }
  }

  @autobind
  public render(): void {
    this._bounds = this.createTextAndGetBound(this._config.geometry, this._config.order);
    this.createCircle(
      this._config.geometry,
      this._circle.strokeColor,
      this._config.renderParamsContextObserver.getContext().zoom,
    );
    this.updateStylesBySelection(this._selected);
  }

  private get radius(): number {
    return (this._bounds + COUNT_MARK_PADDING) / 2;
  }

  public changeColor(value: paper.Color): void {
    this._circle.fillColor = value;
    this._circle.strokeColor = value;
    this._config.color = value;
    this.updateTextColor(value);
  }

  public isExistsInRect(rect: paper.Rectangle): boolean {
    const { x, y } = this._point.position;
    return rect.left < x && x < rect.right && rect.top < y && y < rect.bottom;
  }

  @autobind
  public updateRenderParams({ rotation }: MeasuresViewSettings): void {
    this._text.rotate(-this._text.rotation - rotation, this._point.bounds.center);
  }

  @autobind
  public changeVisualData(zoom: number): void {
    const scale = this._config.renderParamsContextObserver.getPrevContext().zoom / zoom;
    if (this._text) {
      this._text.scale(scale);
    }
    this._circle.scale(scale);
    this._circle.strokeWidth = DrawingsCanvasConstants.lineStroke / zoom;
  }

  public mouseLeave(id: string, e: PaperMouseEvent): void {
    if (this._config.onMouseLeave) {
      this._config.onMouseLeave(id, e);
    }
  }

  protected updateStylesBySelection(value: boolean): void {
    this._selected = value;
    this._circle.fillColor = value ? DrawingsCanvasColors.nightDeepBackground : this._circle.strokeColor;
    this.updateTextColor(this._circle.fillColor);
  }

  @autobind
  protected onStartDrag(e: PaperMouseEvent): void {
    if (this._selected) {
      super.onStartDrag(e);
    }
  }

  @autobind
  private createTextAndGetBound(point: paper.Point, order: number): number {
    this._text = new paper.PointText(point);
    this._text.content = order.toString();
    this._text.fontSize = DrawingsCanvasConstants.fontSize;
    this._text.fontFamily = 'ProximaSoft';
    this.updateTextColor(this._circle ? this._circle.fillColor : this._config.color);
    this._text.position = point;
    this._text.addTo(this._point);
    const width = this._text.bounds.width;
    return width;
  }

  private createCircle(point: paper.Point, color: paper.Color, zoom: number): void {
    this._circle = this._config.shapeCreator.createShape(this._config.shape, this.radius);
    this._circle.position = this._config.geometry;
    this._circle.addTo(this._point);
    this._circle.sendToBack();
    this._circle.fillColor = color;
    this._circle.strokeColor = color;
    this._circle.strokeWidth = DrawingsCanvasConstants.lineStroke / zoom;
    this._point.addTo(this._config.layer);
    this._point.position = point;
    this._point.scale(1 / zoom);
  }

  @autobind
  private onMouseEnter(e: PaperMouseEvent): void {
    if (this._config.onMouseEnter) {
      this._config.onMouseEnter(this._config.id, e);
    }
  }

  private updateTextColor(color: paper.Color): void {
    this._text.fillColor = ColorUtils.needReverseTextColorByBackground(color, true)
      ? DrawingsCanvasColors.darkBlue
      : DrawingsCanvasColors.white;
  }
}
