import * as React from 'react';

import './info-item.scss';

import { HelpTooltip } from '../../../../components/controls/tooltips';


interface Props {
  title: string;
  text: string;
  helpText?: string;
}

export class InfoItem extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    helpText: '',
  };

  public render(): JSX.Element {
    const {
      title,
      text,
      helpText,
    } = this.props;

    return (
      <div className='info-item'>
        <div className='title'>
          <div>
            {title}
            {helpText && <HelpTooltip id={helpText} text={helpText} />}
          </div>
        </div>
        <div className='value' title={text}>
          {text}
        </div>
      </div>
    );
  }
}
