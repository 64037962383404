import * as React from 'react';

import './tree-view.scss';

import { ActivityCategory } from '../../units/databases/interfaces/data';
import { Item, TreeViewItem } from './tree-view-item';

interface Props {
  filter?: string;
  items: Item[];
  onClick?: (data: ActivityCategory) => void;
  onDoubleClick?: () => void;
  isOnlyLeavesSelect?: boolean;
  isOpenChildrenRecursive?: boolean;
  selectedCode: string;
}

export { Item };

export class TreeView extends React.Component<Props> {
  public render(): JSX.Element {
    const { filter, items, onClick, selectedCode } = this.props;
    return (
      items.length > 0 && (
        <div className='tree-view'>
          {items.map((x, i) => (
            <TreeViewItem
              key={x.code}
              number={i}
              filter={filter}
              item={x}
              level={0}
              onClick={onClick}
              isLastItem={items.length === i + 1}
              isParentLastItem={false}
              isOnlyLeavesSelect={this.props.isOnlyLeavesSelect}
              onDoubleClick={this.props.onDoubleClick}
              selectedCode={selectedCode}
            />
          ))}
        </div>
      )
    );
  }
}
