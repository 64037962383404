import * as Ag from 'ag-grid-community';
// eslint-disable-next-line import/no-unresolved
import { FillOperationParams } from 'ag-grid-community/dist/lib/entities/gridOptions';

import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { ExcelFormulaHelper } from '..';
import { TwoDRegex } from '../../../../../units/2d/units/2d-regex';
import { CellFillHelper } from './interfaces';


const getReferenceColumnId = (initialRange: Ag.CellRange, multiplier: -1 | 1, offset: number): string => {
  const startColumn = multiplier === 1
    ? initialRange.columns[0]
    : Ag._.last(initialRange.columns);
  const startColumnId = startColumn.getColId();
  const startColumnIndex = ExcelColumnHelper.excelColNameToIndex(startColumnId);
  const referenceColumnIndex = startColumnIndex + offset * multiplier;

  return ExcelColumnHelper.indexToExcelColName(referenceColumnIndex);
};

const getReferenceRow = (
  api: Ag.GridApi, initialRange: Ag.CellRange, multiplier: -1 | 1, offset: number,
): Ag.RowNode => {
  const startRowIndex = (multiplier === 1) === (initialRange.startRow.rowIndex <= initialRange.endRow.rowIndex)
    ? initialRange.startRow.rowIndex
    : initialRange.endRow.rowIndex;
  const referenceRowIndex = startRowIndex + offset * multiplier;

  return api.getDisplayedRowAtIndex(referenceRowIndex);
};

const getReferenceValue = (params: FillOperationParams): any => {
  const { direction, currentIndex, api, rowNode, column } = params;
  const offset = currentIndex;
  const multiplier = direction === 'down' || direction === 'right' ? 1 : -1;
  const initialRange = api.getCellRanges()[0];

  if (rowNode) {
    const referenceColumnId = getReferenceColumnId(initialRange, multiplier, offset);
    return rowNode.data[referenceColumnId];
  }

  if (column) {
    const row = getReferenceRow(api, initialRange, multiplier, offset);
    return row.data[column.getColId()];
  }
};

const getReferenceValues = (params: FillOperationParams): string[] | null => {
  const value = getReferenceValue(params);
  if (ExcelFormulaHelper.isFormula(value)) {
    return [value];
  }

  return null;
};

const getOffset = (params: FillOperationParams, isVerticalDirection: boolean): number => {
  const { direction, initialValues, api, currentIndex } = params;

  const multiplier = direction === 'down' || direction === 'right' ? 1 : -1;
  if (!isVerticalDirection) {
    return initialValues.length * multiplier;
  } else {
    const initialRange = api.getCellRanges()[0];
    const row = getReferenceRow(api, initialRange, multiplier, currentIndex);
    const nextRow = api.getDisplayedRowAtIndex(row.rowIndex + multiplier);
    return nextRow.data.id - row.data.id;
  }
};

const getNextValue = (params: FillOperationParams, referencedValues: string[]): string => {
  const { direction } = params;

  const referencedValue = referencedValues[0];

  const isVerticalDirection = direction === 'down' || direction === 'up';
  const offset = getOffset(params, isVerticalDirection);

  return referencedValue.replace(TwoDRegex.formulaPartGlobal, (...args) => {
    const columnOffset = !isVerticalDirection ? offset : 0;
    const rowOffset = isVerticalDirection ? offset : 0;
    return ExcelFormulaHelper.updateCellLinkByOffset(args, columnOffset, rowOffset, '');
  });
};

export const FormulaCellFill: CellFillHelper<string> = {
  getReferenceValues,
  getNextValue,
};
