import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { DrawingsTextSearchActionTypes } from '../actions/types';
import { InitialSearchResults } from '../constants';
import { TextSearchPagesType, TextSearchResultsInfo } from '../interfaces';
import { DrawingsState } from '../interfaces/drawings-state';

export const DrawingsTextSearchReducerMethods: ReducerMethods<DrawingsState> = {
  [DrawingsTextSearchActionTypes.SET_QUERY]: (s, query: string) => {
    return new MonoliteHelper(s)
      .set(_ => _.textSearch.settings.query, query)
      .set(_ => _.textSearch.results, InitialSearchResults)
      .get();
  },
  [DrawingsTextSearchActionTypes.TOGGLE_CASE_SENSITIVE]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.textSearch.settings.caseSensitive, !s.textSearch.settings.caseSensitive)
      .set(_ => _.textSearch.results, InitialSearchResults)
      .get();
  },
  [DrawingsTextSearchActionTypes.TOGGLE_WHOLE_WORD]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.textSearch.settings.wholeWord, !s.textSearch.settings.wholeWord)
      .set(_ => _.textSearch.results, InitialSearchResults)
      .get();
  },
  [DrawingsTextSearchActionTypes.TOGGLE_REG_EXP]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.textSearch.settings.regExp, !s.textSearch.settings.regExp)
      .set(_ => _.textSearch.results, InitialSearchResults)
      .get();
  },
  [DrawingsTextSearchActionTypes.SAVE_RESULTS]: (s, results: TextSearchResultsInfo) => {
    return new MonoliteHelper(s)
      .set(_ => _.textSearch.isLoading, false)
      .set(_ => _.textSearch.results, results)
      .get();
  },
  [DrawingsTextSearchActionTypes.SET_IS_LOADING]: (s, isLoading: boolean) => {
    const helper = new MonoliteHelper(s);
    if (isLoading) {
      helper
        .set(_ => _.textSearch.isError, false)
        .set(_ => _.textSearch.results, InitialSearchResults);
    }
    return helper
      .set(_ => _.textSearch.isLoading, isLoading)
      .get();
  },
  [DrawingsTextSearchActionTypes.SET_IS_ERROR]: (s, isError: boolean) => {
    return new MonoliteHelper(s)
      .set(_ => _.textSearch.isError, isError)
      .get();
  },
  [DrawingsTextSearchActionTypes.UPDATE_PAGES_SETTING]: (s, pagesSetting: TextSearchPagesType) => {
    const helper = new MonoliteHelper(s);
    if (pagesSetting === TextSearchPagesType.All) {
      helper
        .set(_ => _.textSearch.results, InitialSearchResults);
    }
    return helper
      .set(_ => _.textSearch.settings.pages, pagesSetting)
      .get();
  },
  [DrawingsTextSearchActionTypes.SET_PAGE_TEXT_RECTS]: (s, pageTextRects) => {
    return new MonoliteHelper(s)
      .set(_ => _.textSearch.textRectangles, pageTextRects)
      .get();
  },
  [DrawingsTextSearchActionTypes.SET_TEXT_SEARCH_ACTIVE]: (s, isActive: boolean) => {
    return new MonoliteHelper(s)
      .set(_ => _.textSearch.isActive, isActive)
      .get();
  },
  [DrawingsTextSearchActionTypes.SET_IS_TEXT_LOADING]: (s, isTextLoading: boolean) => {
    return new MonoliteHelper(s)
      .set(_ => _.textSearch.isTextLoading, isTextLoading)
      .get();
  },
};
