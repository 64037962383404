import { RequestStatus } from 'common/enums/request-status';
import { QtoGenerateReportStatus } from '../enums/qto-generate-report-status';
import { QuantityTakeOffState } from '../interfaces/quantity-take-off/quantity-take-off-state';


export const QuantityTakeOffInitialState: QuantityTakeOffState = {
  model: null,
  tree: null,
  getModelRequestStatus: RequestStatus.NotRequested,
  loadedProjectId: 0,
  selectedBimElementsIds: [],
  elementRecords: [],
  selectElementsEventSource: null,
  needToUpdateFilter: false,
  expandTable: false,
  filteredIds: [],
  recordsConfig: {},
  globalConfig: null,
  report: {
    reportCreationStatus: QtoGenerateReportStatus.NoActions,
    isSync: true,
    availableReportGenerators: [],
    reportModel: null,
    reportsInfo: [],
    selectedReportId: null,
    statuses: {
      loadReport: RequestStatus.NotRequested,
      loadReportsInfo: RequestStatus.NotRequested,
    },
    reportsForCopy: [],
  },
  template: {
    templateCreationStatus: QtoGenerateReportStatus.NoActions,
    statuses: {
      loadTemplate: RequestStatus.NotRequested,
      loadTemplatesInfo: RequestStatus.NotRequested,
    },
    templateModel: null,
    templatesInfo: [],
    selectedTemplateId: null,
  },
  isAddNewPropsInRecords: false,
  customFilters: [],
  customFilterEditModel: null,
  appliedCustomFilter: null,
};
