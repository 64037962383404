import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasConstants } from 'common/components/drawings/constants/drawing-canvas-constants';
import {
  ContextObserver,
  ContextObserverCallbackGroup,
  ContextObserverCallbackGroupWithPrev,
} from 'common/components/drawings/drawings-contexts';
import { DrawingsGeometryEntityState } from 'common/components/drawings/enums/drawings-geometry-entity-state';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import {
  DrawingsAllowedPathType,
  DrawingsGeometryStrokedType,
  DrawingsSimplifiedBoundingRect,
} from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingsGeometryStyle } from 'common/components/drawings/interfaces/drawings-geometry-style';
import { DrawingAnnotationUtils } from 'common/components/drawings/utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DrawingsGeometryEntityPoint } from '..';
import { EditPermissions } from '../../interfaces';
import { DrawingsGeometryEntityLineWithMeasure, SegmentDragHelper } from '../utility';
import { DrawingsGeometryEntityModifiable, ModifiableEntityConfig } from './drawings-geometry-entity-modifiable';


export interface RenderEntitiesParams {
  modifyEnabled: boolean;
  skipPointsRender?: boolean;
  showHoverLineStyle?: boolean;
}

export interface DrawingsGeometryEntityStrokedConfig extends ModifiableEntityConfig {
  editPermissionsObserver: ContextObserver<EditPermissions>;
  geometry: DrawingsGeometryStrokedType;
  segmentDragHelper?: SegmentDragHelper;
  pointLayer: paper.Layer;
  lineLayer: paper.Layer;
  textLayer: paper.Layer;
  textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
}

export class DrawingsGeometryEntityStroked
  <T extends DrawingsGeometryEntityStrokedConfig = DrawingsGeometryEntityStrokedConfig>
  extends DrawingsGeometryEntityModifiable<T, DrawingsGeometryEntityPoint> {
  protected _path: DrawingsAllowedPathType;
  protected _lines: Map<string, DrawingsGeometryEntityLineWithMeasure>;
  protected _linesGroup: paper.Group;
  protected _pointsGroup: paper.Group;
  protected _hoverLeaveExecutor: DeferredExecutor = new DeferredExecutor(DrawingsCanvasConstants.mouseOutDelay);
  protected _hoveredEntityId: string;
  protected _renderParamsObserverGroup: ContextObserverCallbackGroupWithPrev<DrawingsRenderParams>;
  protected _textObserverGroup: ContextObserverCallbackGroup<MeasuresViewSettings>;
  protected _segmentTextGroup: paper.Group;

  constructor(config: T) {
    super(config);
    this._segmentTextGroup = new paper.Group();
    this._segmentTextGroup.addTo(config.textLayer);
    this._linesGroup = new paper.Group();
    this._linesGroup.addTo(this._config.lineLayer);
    this._pointsGroup = new paper.Group();
    this._pointsGroup.addTo(this._config.pointLayer);
    this._renderParamsObserverGroup = new ContextObserverCallbackGroupWithPrev<DrawingsRenderParams>(
      config.renderParamsContextObserver,
    );
    this._textObserverGroup = new ContextObserverCallbackGroup<MeasuresViewSettings>(
      config.textRenderParamsObserver,
    );
  }

  public destroy(): void {
    this.removeEntities();
    this._path.remove();
    this._renderParamsObserverGroup.destroy();
    this._textObserverGroup.destroy();
    this._segmentTextGroup.remove();
    if (this._linesGroup) {
      this._linesGroup.remove();
      this._linesGroup = undefined;
    }
    if (this._pointsGroup) {
      this._pointsGroup.remove();
      this._pointsGroup = undefined;
    }
  }

  public get bounds(): DrawingsSimplifiedBoundingRect {
    return this._path.bounds;
  }

  @autobind
  public mouseEnter(id: string, e: PaperMouseEvent): void {
    this._hoveredEntityId = id;
    if (this._config.onMouseEnter) {
      this._config.onMouseEnter(this._config.id, e);
    }
    if (this.selected) {
      this._config.cursorHelper.hoveredSelected = true;
    } else {
      this._config.cursorHelper.hovered = true;
    }
    this._hoverLeaveExecutor.reset();
    if (this.state !== DrawingsGeometryEntityState.Default) {
      return;
    }
    this.state = DrawingsGeometryEntityState.Hover;
  }

  @autobind
  public mouseLeave(id: string, e: PaperMouseEvent): void {
    if (this._hoveredEntityId !== id) {
      return;
    }
    if (this._config.onMouseLeave) {
      this._config.onMouseLeave(this._config.id, e);
    }
    this._config.cursorHelper.hovered = false;
    this._hoverLeaveExecutor.execute(this.removeHover);
  }

  public getOrderedSegmentPoints(segmentId: string): [string, string] {
    const segmentPoints = DrawingAnnotationUtils.getPointsIdsFromLineKey(segmentId);
    const startIndex = this._config.geometry.points.indexOf(segmentPoints[0]);
    const endIndex = this._config.geometry.points.indexOf(segmentPoints[1]);
    return startIndex < endIndex ? segmentPoints : segmentPoints.reverse() as [string, string];
  }

  protected renderEntities?(params: RenderEntitiesParams): void;
  protected enableSelectMode?(): void;

  protected applyState(state: DrawingsGeometryEntityState): void {
    const isHover = state === DrawingsGeometryEntityState.Hover;
    if (state === DrawingsGeometryEntityState.Hover || state === DrawingsGeometryEntityState.Selected) {
      this.removeEntities();
      if (this.renderEntities) {
        this.renderEntities({ modifyEnabled: false, skipPointsRender: true, showHoverLineStyle: isHover });
      }
    } else if (state === DrawingsGeometryEntityState.Modify) {
      if (this.renderEntities) {
        this.renderEntities({ modifyEnabled: true });
      }
    } else {
      this.removeEntities();
    }
    if (state === DrawingsGeometryEntityState.Selected) {
      this._eventsApplier.enableDrag(true);
      if (this.enableSelectMode) {
        this.enableSelectMode();
      }
    } else {
      const { zoom } = this._config.renderParamsContextObserver.getContext();
      this._path.opacity = 1;
      if (state !== DrawingsGeometryEntityState.Default) {
        this._path.strokeWidth = 0;
      } else {
        this._path.strokeWidth = this._config.geometry.strokeWidth / zoom;
        this._path.dashArray = DrawingsCanvasUtils.scaleStroke(this._config.geometry, zoom);
      }
      if (this._lines) {
        this._lines.forEach(x => x.disableSelectedStyle());
      }
      this._eventsApplier.enableDrag(false);
    }
  }

  @autobind
  protected removeHover(): void {
    if (this.state !== DrawingsGeometryEntityState.Hover) {
      return;
    }
    this.state = DrawingsGeometryEntityState.Default;
  }

  @autobind
  protected removeEntities(): void {
    if (this._points) {
      this._pointsGroup.removeChildren();
      this._points = undefined;
    }
    if (this._linesGroup) {
      this._linesGroup.removeChildren();
      this._lines = undefined;
    }

    if (this._segmentTextGroup) {
      this._segmentTextGroup.removeChildren();
    }
  }

  protected initEntityGroups(): void {
    if (!this._points) {
      this._points = new Map();
    }
    if (!this._lines) {
      this._lines = new Map();
    }
  }

  protected updatePointInLine(lineId: string, pointId: string): void {
    if (this._lines.has(lineId)) {
      this._lines.get(lineId).changePointPosition(pointId);
    }
  }

  protected canEditSegment(): boolean {
    return this.state !== DrawingsGeometryEntityState.Modify
      && this._config.editPermissionsObserver.getContext().canEditMeasure;
  }


  protected override changeColor(value: DrawingsPaperColorInfo, colorCode: string): void {
    super.changeColor(value, colorCode);
    this._path.strokeColor = value.stroke;
  }

  protected override applyStyle<P extends keyof DrawingsGeometryStyle>(
    field: P,
    value: DrawingsGeometryStyle[P],
  ): void {
    if (field === 'strokeWidth' || field === 'strokeStyle') {
      this._config.geometry[field as keyof DrawingsGeometryStyle] = value;
      this.updateStrokeStyle();
    }
  }

  private updateStrokeStyle(): void {
    const { strokeWidth } = this._config.geometry;
    if (this.state === DrawingsGeometryEntityState.Default) {
      const { zoom } = this._config.renderParamsContextObserver.getContext();
      this._path.strokeWidth = strokeWidth / zoom;
      this._path.dashArray = DrawingsCanvasUtils.scaleStroke(this._config.geometry, zoom);
    } else {
      if (this._lines) {
        this._lines.forEach(x => x.changeStrokeStyles(this._config.geometry));
      }
    }
  }
}
