import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { KreoButton, KreoDialogActions } from 'common/UIKit';
import { KreoConfirmationDialog } from '../../../../../components/dialog/kreo-confirmation-dialog';

interface DispatchProps {
  openRevertDialog: () => void;
}

interface Props extends DispatchProps {
  onRevert: () => void;
}


const REVERT_DIALOG_NAME = 'REVERT_DIALOG_NAME';


class RevertButtonComponent extends React.Component<Props> {

  public render(): JSX.Element {
    return (
      <>
        <KreoButton
          disabled={!this.props.onRevert}
          size='medium'
          onClick={this.props.openRevertDialog}
          mode='error'
          controlName='revert-to-previous-step-button'
        >
          Revert
        </KreoButton>
        <KreoConfirmationDialog
          name={REVERT_DIALOG_NAME}
          onYes={this.props.onRevert}
          yesText='Revert'
          noText='Cancel'
          title={`Revert`}
        >
          You will lose all the changes made at the following steps
        </KreoConfirmationDialog>
      </>
    );
  }
}


const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    openRevertDialog: () => dispatch(KreoDialogActions.openDialog(REVERT_DIALOG_NAME)),
  };
};

const connector = connect(null, mapDispatchToProps, null, { forwardRef: true });
export const RevertButton = connector(RevertButtonComponent);
