import styled, { FlattenInterpolation } from 'styled-components';

export const Container = styled.div<{ styles?: FlattenInterpolation<any>, optionContainer?: boolean, zIndex?: number }>`
  ${p => p.styles}
  position: ${props => props.optionContainer ? 'relative' : 'absolute'};
  z-index: ${p => p.zIndex ? p.zIndex : 1002};
  box-sizing: border-box;
  max-width: 100%;

  p {
    font-weight: 400;
    white-space: normal;
  }

  &::-webkit-scrollbar-track {
    margin: 15px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }

  @media all and (max-height: 555px) {
    height: 200px;
    top: 100px !important;
  }
`;


export const Styled = {
  Container,
};
