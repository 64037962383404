import * as React from 'react';

export const IconBidPricing: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M8.5,11 L8.5,14.5 C8.5,14.7761424 8.27614237,15 8,15 C7.72385763,15 7.5,14.7761424 7.5,
          14.5 L7.5,11 L3.5,11 C2.67157288,11 2,10.3284271 2,9.5 L2,3.5 C2,2.67157288 2.67157288,
          2 3.5,2 L12.5,2 C13.3284271,2 14,2.67157288 14,3.5 L14,9.5 C14,10.3284271 13.3284271,
          11 12.5,11 L8.5,11 Z M3.5,3 C3.22385763,3 3,3.22385763 3,3.5 L3,9.5 C3,9.77614237 3.22385763,
          10 3.5,10 L12.5,10 C12.7761424,10 13,9.77614237 13,9.5 L13,3.5 C13,3.22385763 12.7761424,
          3 12.5,3 L3.5,3 Z M9.64644661,4.64644661 C9.84170876,4.45118446 10.1582912,4.45118446 10.3535534,
          4.64644661 C10.5488155,4.84170876 10.5488155,5.15829124 10.3535534,5.35355339 L7.85355339,
          7.85355339 C7.65829124,8.04881554 7.34170876,8.04881554 7.14644661,7.85355339 L5.64644661,
          6.35355339 C5.45118446,6.15829124 5.45118446,5.84170876 5.64644661,5.64644661 C5.84170876,
          5.45118446 6.15829124,5.45118446 6.35355339,5.64644661 L7.5,6.79289322 L9.64644661,4.64644661 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
