import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Action, Dispatch } from 'redux';


import { Subject } from 'common/ability/subject';
import { FaqCaption } from 'common/enums/faq-caption';
import { HeaderContext } from 'common/enums/header-context';
import { LogoType } from 'common/enums/logo-type';
import { PageHeader } from 'common/interfaces/page-header';
import { AppUrls } from 'routes/app-urls';
import { PageLayout } from '../../../layouts/page-layout';
import { DatabaseAssignActivityRouteParams } from '../../../routes/app-routes-params';
import { DatabaseCommonActions } from '../../../units/databases/actions/creators/database-common';
import { DatabaseResourceListingPage } from '../../databases/pages';
import { NotFoundPage } from '../../notfound/page';
import { ActivityAssignmentActions } from '../actions/creators/activity-assignment';
import { ActivityAssignmentDatabaseActivityListingPage } from '../pages/activity-assignment-database-activity-listing';


interface State {
  header: PageHeader;
}

interface ReduxActions {
  dropCurrentDatabaseState: () => void;
}

interface Props extends RouteComponentProps<DatabaseAssignActivityRouteParams>, ReduxActions {}

class ActivityAssignmentRoutesDatabaseComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      header: this.getDatabaseHeader(),
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.match.params.projectId !== prevProps.match.params.projectId) {
      this.setState({
        header: this.getDatabaseHeader(),
      });
    }

    const activitiesPath = AppUrls.plan.project.validation.activityAssignmentDbActivities.path;
    const resourcesPath = AppUrls.plan.project.validation.activityAssignmentDbResources.path;
    const isDatabaseUrl = (path: string): boolean => path === activitiesPath || path === resourcesPath;

    if (!isDatabaseUrl(this.props.match.path) && isDatabaseUrl(prevProps.match.path)) {
      this.props.dropCurrentDatabaseState();
    }
  }

  public render(): JSX.Element {
    const projectId = this.props.match.params.projectId;
    const activityAssignmentEditUrl = AppUrls.plan.project.validation.editActivityAssignment.url({ projectId });
    return (
      <Switch>
        <PageLayout
          exact={true}
          metaTitle='Assign Activity'
          path={AppUrls.plan.project.validation.activityAssignmentDbActivities.path}
          component={ActivityAssignmentDatabaseActivityListingPage}
          header={this.state.header}
          subject={Subject.ValidationActivityAssignment}
          redirectUrl={activityAssignmentEditUrl}
        />
        <PageLayout
          exact={true}
          metaTitle='Assign Resources'
          path={AppUrls.plan.project.validation.activityAssignmentDbResources.path}
          component={DatabaseResourceListingPage}
          header={this.state.header}
          subject={Subject.ValidationActivityAssignment}
          redirectUrl={activityAssignmentEditUrl}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  public componentWillUnmount(): void {
    this.props.dropCurrentDatabaseState();
  }

  private getDatabaseHeader(): PageHeader {
    return {
      context: HeaderContext.DatabaseActivityAssignment,
      color: 'white',
      logoType: LogoType.LogoBlackLight,
      disableToggle: true,
      faqCaption: FaqCaption.ActivityAssignment,
      backUrl: AppUrls.plan.project.validation.editActivityAssignment.url({
        projectId: this.props.match.params.projectId,
      }),
    };
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    dropCurrentDatabaseState: () => {
      dispatch(ActivityAssignmentActions.setSaveData(false));
      dispatch(DatabaseCommonActions.dropCurrentDatabaseState());
    },
  };
};

const connector = connect(null, mapDispatchToProps);
export const ActivityAssignmentDatabaseRoutes = connector(ActivityAssignmentRoutesDatabaseComponent);
