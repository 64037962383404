import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  link?: string;
  icon?: JSX.Element;
  active?: boolean;
  clickEvent?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export class Item extends React.Component<Props> {
  public render(): JSX.Element {
    const { title, link, icon, active } = this.props;

    return (
      <div
        className={classNames('drop-menu__item', { 'drop-menu__item--active': active })}
        onClick={this.clickHandler}
      >
        {link ? (
          <Link to={link} className='drop-menu__item-title'>
            {icon}
            {title}
          </Link>
        ) : (
          <span className='drop-menu__item-title'>
            {icon}
            {title}
          </span>
        )}
      </div>
    );
  }

  @autobind
  private clickHandler(event: React.MouseEvent<HTMLDivElement>): void {
    if (this.props.clickEvent) {
      this.props.clickEvent(event);
    }
  }
}
