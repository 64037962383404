import React, { useCallback } from 'react';

import { Styled } from './styled';

interface Props {
  value: number;
  min: number;
  max: number;
  step?: number;
  disabled?: boolean;
  onChange: (value: number) => void;
}

const SliderHorizontalComponent: React.FC<Props> = ({
  value,
  onChange,
  min,
  max,
  step = 1,
  disabled,
}) => {

  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.valueAsNumber),
    [ onChange ],
  );

  return (
    <Styled.Container disabled={disabled}>
      <Styled.Slider>
        <Styled.SliderInput
          type="range"
          step={step}
          onChange={onChangeCallback}
          value={value}
          min={min}
          max={max}
        />
        <Styled.SliderProgress>
          <Styled.ProgressBar value={value} min={min} max={max} />
        </Styled.SliderProgress>
      </Styled.Slider>
    </Styled.Container>
  );
};

export const SliderHorizontal = React.memo(SliderHorizontalComponent);
