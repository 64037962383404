import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoleCode } from 'common/enums/role-code';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { InvitationCompanyUsers } from 'common/interfaces/people';
import { State } from 'common/interfaces/state';
import { OwnCompany } from '../../../../account/interfaces/own-company';
import { PeopleActions } from '../../../../people/actions/actions';
import { SubscriptionActions as SubscriptionFormAction } from '../../../store';
import { TempInvitePeople } from '../../../store/interface';

type InvitePeopleCallback = () => void;

export const useInvitePeopleCallback = (): InvitePeopleCallback => {
  const dispatch = useDispatch();
  const company = useSelector<State, OwnCompany>(s => s.account.ownedCompany);
  const subscriptionRoles = useSelector<State, CompanyRoles>(s => s.account.subscriptionRoles);
  const invitedPeople = useSelector<State, TempInvitePeople[]>(s => s.subscription.tempInvitePeople);
  const roleIdMap = useMemo(() => {
    const result: Record<string, number> = {};
    if (subscriptionRoles) {
      subscriptionRoles.roles.forEach(r => {
        result[r.name] = r.id;
      });
    }

    return result;
  }, [subscriptionRoles]);
  return useCallback(
    () => {
      const invites: InvitationCompanyUsers[] = [];
      const adminEmails: string[] = [];
      const editorEmails: string[] = [];
      if (!invitedPeople) {
        return;
      }
      for (const people of invitedPeople) {
        if (!people.email) {
          continue;
        }
        if (people.role === RoleCode.Administrator) {
          adminEmails.push(people.email);
        }
        if (people.role === RoleCode.Editor) {
          editorEmails.push(people.email);
        }
      }
      if (adminEmails.length) {
        invites.push({
          companyId: company.id,
          host: window.location.host,
          emails: adminEmails,
          roleId: roleIdMap[RoleCode.Administrator],
        });
      }
      if (editorEmails.length) {
        invites.push({
          companyId: company.id,
          host: window.location.host,
          emails: editorEmails,
          roleId: roleIdMap[RoleCode.Editor],
        });
      }
      if (invites.length) {
        dispatch(PeopleActions.invitePeopleToCompanyNew(invites));
      }
      dispatch(SubscriptionFormAction.setTempInvitePeople(null));
    },
    [company, dispatch, roleIdMap, invitedPeople],
  );
};
