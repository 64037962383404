import { Avatar, Text } from '@kreo/kreo-ui-components';
import React from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'components/theme-provider';
import { store } from '../../../../store';
import { Styled } from './styled';

export const CellRendererUserAvatar = ({
  name,
  link,
}: {
  name: string,
  link: string,
}): JSX.Element => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Styled.UserInfo>
          <Avatar name={name} size={30} avatar={link} />
          <Text fontSize={14}>{name}</Text>
        </Styled.UserInfo>
      </ThemeProvider>
    </Provider>
  );
};

export const getUserAvatarElement = (name: string, link: string): JSX.Element => {
  return <CellRendererUserAvatar name={name} link={link} />;
};
