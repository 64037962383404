import { GetContextMenuItemsParams, MenuItemDef, RowNode } from 'ag-grid-community';

import { ModelBrowserPropertyGroupView } from '../../../interfaces/properties-provider-types';
import { PropertiesUtils } from '../../../utils/properties-data-provider/properties-utils';
import { SelectSharedParametersResult, SelectUserExtractorResult } from '../interfaces';
import { getDescriptionIcon } from './description-tooltip/get-description-icon';

interface ExtractorsMenuItemsConfig {
  name: string;
  id: number;
  fullName?: string;
  description?: string;
  extractorKey?: string;
}

const getExtractors = (
  name: string,
  id: number,
  _projectId: number,
  callBack: (selectedBimIds: number[], result: SelectUserExtractorResult) => void,
  fullName: string,
  description: string,
  extractorKey: string,
  selectElementIds: number[],
): MenuItemDef => {
  const extractorValue = { extractorId: id, extractorName: name, extractorKey };
  return {
    name,
    action: async () => {
      callBack(selectElementIds, extractorValue);
    },
    icon: getDescriptionIcon(fullName, description),
  };
};

const mapConfigToMenuItems = (
  selectedElementsIds: number[],
  projectId: number,
  callBack: (selectedBimIds: number[], result: SelectUserExtractorResult) => void,
) =>
  (result: MenuItemDef[], config: ExtractorsMenuItemsConfig) => {
    const [groupName, extractorName] = config.name.split('.');
    const groupMenuItem = result.find(r => r.name === groupName);
    if (groupMenuItem) {
      (groupMenuItem.subMenu as MenuItemDef[]).push(getExtractors(
        extractorName,
        config.id,
        projectId,
        callBack,
        config.name,
        config.description,
        config.extractorKey,
        selectedElementsIds,
      ));
    } else {
      result.push({
        name: groupName,
        subMenu: [getExtractors(
          extractorName,
          config.id,
          projectId,
          callBack,
          config.name,
          config.description,
          config.extractorKey,
          selectedElementsIds,
        )],
      });
    }

    return result;
  };

type MenuItem = { name: string, subMenu: any[] };

const mapPropertiesToMenuItems = (
  properties: ModelBrowserPropertyGroupView[],
  selectIds: number[],
  callBack: (selectedBimIds: number[], result: SelectSharedParametersResult) => void,
): MenuItem[] => {
  const group = properties.map(p => {
    const groupName = p.name.replace(/\b\w/g, m => m.toUpperCase());
    const subMenu = [];
    for (const sp of p.properties) {
      if (!PropertiesUtils.lineIsLayer(sp) && sp.value) {
        subMenu.push({
          name: sp.name,
          action: () => callBack(
            selectIds,
            {
              groupName,
              name: sp.name,
              value: sp.value,
            }),
        });
      }
    }

    return {
      name: groupName,
      subMenu,
    };
  });

  return group.filter(g => g.subMenu.length);
};

export const getElementContextItems = (
  configs: ExtractorsMenuItemsConfig[],
  projectId: number,
  pivot: boolean,
  getCommonElementProperties: (ids: number[]) => ModelBrowserPropertyGroupView[],
  setExtractor: (selectedBimIds: number[], result: SelectUserExtractorResult) => void,
  setSharedParameters: (selectedBimIds: number[], result: SelectSharedParametersResult) => void,
  syncWithReport: (nodes: RowNode[]) => void,
) =>
  (params: GetContextMenuItemsParams) => {
    const selectedIds = [];
    const nodes = params.api.getSelectedNodes();
    for (const node of nodes) {
      if (node.data) {
        selectedIds.push(Number(node.data.id));
      }
    }
    const properties = getCommonElementProperties(selectedIds);
    const isElementSelected = !!selectedIds.length;
    const tooltip = !isElementSelected
      ? 'Select Element'
      : undefined;
    return [
      {
        name: 'Add Base Extractors',
        disabled: !isElementSelected,
        tooltip,
        subMenu: configs.reduce(mapConfigToMenuItems(selectedIds, projectId, setExtractor), []),
      },
      {
        name: 'Add new parameter for grouping',
        disabled: !isElementSelected,
        tooltip,
        subMenu: mapPropertiesToMenuItems(properties, selectedIds, setSharedParameters),
      },
      {
        name: 'Sync elements with Report',
        disabled: !isElementSelected && !pivot,
        tooltip: tooltip ? tooltip : 'The properties will be synced with the report table on the right side',
        action: () => syncWithReport(nodes),
      },
    ];
  };
