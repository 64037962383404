import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { SubscriptionListingStyled } from '../../subscription-listing';

function getSubscriptionListingStyle(isPinBlock: boolean, planLength: number): FlattenSimpleInterpolation {
  if (isPinBlock) {
    return css`
      ${SubscriptionListingStyled.Offers} {
        height: ${planLength < 5 ? '180px' : 'fit-content'};
        top: 40px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    `;
  } else {
    return null;
  }
}

const Container = styled.div<{ isPinBlock: boolean, planLength: number }>`
  box-sizing: border-box;
  overflow: overlay;
  max-height: calc(100vh - 80px);
  padding: 40px 20px;
  background-color: ${props => props.theme.color.background};
  border-radius: 40px;
  width: 1244px;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    margin: 25px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }

  ${p => getSubscriptionListingStyle(p.isPinBlock, p.planLength)};
`;

export const Styled = {
  Container,
};
