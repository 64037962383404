import { DrawingsDrawMode } from 'common/components/drawings/enums';

export const DrawModes = [
  DrawingsDrawMode.Polyline,
  DrawingsDrawMode.Polygon,
  DrawingsDrawMode.Count,
  DrawingsDrawMode.Finder,
  DrawingsDrawMode.BucketFill,
  DrawingsDrawMode.Rectangle3Point,
  DrawingsDrawMode.Rectangle2Point,
];

