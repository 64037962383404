import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssignedPia } from '2d/index';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { State } from 'common/interfaces/state';
import { useOpenCloseDialog } from 'common/UIKit';
import { usePersistedStorageValue } from 'persisted-storage/hooks';
import { DrawingDialogs } from '../../constants/drawing-dialogs';
import { NoScaleDescriptionType } from '../../dialogs';
import { useElementAndGroupsOperationContext } from '../../drawings-contexts';
import { DrawingsCopyPasteBuffer } from '../../drawings-geometry';
import { DrawingsSourceOfBuffer } from '../../drawings-geometry/drawings-helpers';
import { useCurrentDrawingInfo, useInstanceAndPoints } from '../../hooks';
import { DrawingsGeometryGroup, DrawingsGeometryInstanceWithId, ShortPointDescription } from '../../interfaces';

export function useCopyPasteBuffer(): DrawingsCopyPasteBuffer {
  const [ instances, points ] = useInstanceAndPoints();
  const groups = useSelector<State, DrawingsGeometryGroup[]>(state => state.drawings.drawingGeometryGroups);
  const isKeepOriginName = usePersistedStorageValue('drawingKeepMeasuresNames');
  const isKeepStructure = usePersistedStorageValue('drawingKeepStructureEnabled');
  const targetGroupId = useSelector<State, string>(state => state.drawings.selectGeometryGroup[0]);
  const canEdit3d = useAbility(Operation.Update, Subject.Takeoff2dMeasurement3d);
  const currentDrawingInfo = useCurrentDrawingInfo();
  const operationContext = useElementAndGroupsOperationContext();
  const piaBuffer = useSelector<State>(x => x.twoD.assignPiaBuffer);
  const paste = useCallback((
    newInstancesNewPoints: DrawingsGeometryInstanceWithId[],
    newPoints: Record<string, ShortPointDescription>,
    source: DrawingsSourceOfBuffer,
    newToSourceIds: Record<string, string>,
  ) => {
    if (!currentDrawingInfo) {
      return;
    }

    const pia = Object.entries(newToSourceIds).reduce((acc, [newId, sourceId]) => {
      if (piaBuffer[sourceId]) {
        acc[newId] = piaBuffer[sourceId];
      }
      return acc;
    }, {} as Record<string, AssignedPia>);

    operationContext.addInstancesWithUndo({
      instances: newInstancesNewPoints,
      points: newPoints,
      moveToSelectedGroup: false,
      ignoreSaveMeasuresOnCreate: source === DrawingsSourceOfBuffer.Cut,
      pia,
    });
  }, [currentDrawingInfo, operationContext, piaBuffer]);


  const [ openDialog ] = useOpenCloseDialog(DrawingDialogs.NOT_SCALED_DIALOG);
  const dispatch = useDispatch();

  const pasteWithScale = useCallback((apply: () => void) => {
    if (!currentDrawingInfo.drawingCalibrationLineLength && !currentDrawingInfo.originalCalibrationLineLength) {
      openDialog({
        onSkip: () => { apply(); },
        descriptionType: NoScaleDescriptionType.Default,
      });
    } else {
      apply();
    }
  }, [currentDrawingInfo, openDialog, dispatch]);

  const copyPasteBuffer = useMemo(() => {
    return new DrawingsCopyPasteBuffer({
      getInstance: id => instances[id],
      getPoint: id => points[id],
      getGroups: () => groups,
      rotation: currentDrawingInfo?.rotationAngle,
      keepOriginName: isKeepOriginName,
      targetGroupId,
      keepStructure: isKeepStructure,
      paste,
      canEdit3d,
      pasteWithScale,
    });
  }, []);

  useEffect(() => {
    copyPasteBuffer.config = {
      getInstance: id => instances[id],
      getPoint: id => points[id],
      getGroups: () => groups,
      rotation: currentDrawingInfo?.rotationAngle,
      keepOriginName: isKeepOriginName,
      targetGroupId,
      keepStructure: isKeepStructure,
      paste,
      canEdit3d,
      pasteWithScale,
    };
  }, [
    isKeepOriginName,
    isKeepStructure,
    targetGroupId,
    canEdit3d,
    pasteWithScale,
    paste,
    groups,
    points,
    instances,
    currentDrawingInfo?.rotationAngle,
  ]);
  return copyPasteBuffer;
}
