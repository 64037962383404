import * as React from 'react';

import './index.scss';

import { HelpTooltip } from '../../../../components/controls/tooltips';


interface Props {
  tooltipId: string;
  tooltipTitleRow: string;
  tooltipSubRow: string;
  icon: JSX.Element;
}

export const ScenarioItemIcon: React.FC<Props> = (props) => {
  return (
    <div className={'scenario-item-icon'}>
      <HelpTooltip
        id={props.tooltipId}
        text={
          <React.Fragment>
            <span className='scenario-item-icon__tooltip-text'>
              <b>{props.tooltipTitleRow}</b>
            </span>
            <span className='scenario-item-icon__tooltip-text'>
              {props.tooltipSubRow}
            </span>
          </React.Fragment>
        }
        place='bottom'
        className='scenario-item-icon__tooltip'
        icon={props.icon}
      />
    </div>
  );
};
