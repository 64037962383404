import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Badge } from 'common/components/badge';
import { DrawingsAnnotationLegendActions } from 'common/components/drawings';
import { Text } from 'common/components/text';
import { Styled } from './styled';
import { InheritType, useParentAssign } from './use-parent-assign';
import { useParentId } from './use-parent-id';

interface Props {
  type: InheritType;
  entityName: string;
}

export const InheritLabel: React.FC<Props> = ({ type, entityName }) => {
  const parentId = useParentId();
  const data = useParentAssign(type, parentId, entityName);
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(DrawingsAnnotationLegendActions.updateSelection({
      instanceIds: [],
      groupIds: [data.id],
    }));
  }, [dispatch, data]);

  if (data === null) {
    return null;
  }

  return (
    <Styled.Container>
      <Text fontSize={12}>Inherited from </Text>
      <Styled.BadgeContainer onClick={handleClick}>
        <Badge
          badgeText={data.name}
        />
      </Styled.BadgeContainer>
    </Styled.Container>
  );
};
