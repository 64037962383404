import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './index.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { KreoEngine } from '../../../../components/engine/KreoEngine';
import { WorkPackageAction } from '../../actions';
import { WorkPackagesData } from '../../interfaces';
import { LeftArea } from '../left-area';
import { PopoverActivity } from '../popover-activity';
import { Layout } from '../work-packages-layout';


interface ReduxProps {
  activities: WorkPackagesData.Activity[];
  packages: WorkPackagesData.WorkPackage[];
  activePackage: WorkPackagesData.GropedWorkPackage;
  activeGroup: WorkPackagesData.GroupOfActivity;
  selectedGroup: number[];
  selectedActivity: number[];
}

interface ReduxActions {
  changeActivePackById: (packId: number) => void;
  deselectPackages: () => void;
  dropState: () => void;
}

interface Props extends ReduxActions, ReduxProps {
  projectId: number;
  scenarioId: number;
}

interface State {
  selectedActivities: WorkPackagesData.Activity[];
  selectedPackages: WorkPackagesData.WorkPackage[];
  anchor: HTMLElement;
}

class PackagesPage extends React.PureComponent<Props, State> {
  private engine: KreoEngine = null;
  private lastSelectFromCode: boolean = false;

  public constructor(props: Props) {
    super(props);

    this.state = {
      selectedActivities: null,
      selectedPackages: null,
      anchor: null,
    };
  }

  public componentWillUnmount(): void {
    this.props.dropState();
  }

  public componentDidUpdate(): void {
    this.updateSelection();
  }

  public render(): JSX.Element {
    return (
      <div className='packages-container'>
        <Layout
          projectId={this.props.projectId}
          sendEngineApi={this.sendEngineApi}
          engineHandleClick={this.engineHandleClick}
          affterLoadEngineAction={this.updateSelection}
        >
          {this.props.scenarioId ?
          <LeftArea
            scenarioId={this.props.scenarioId}
            getIdsByHover={this.getIdsByHover}
          /> : null}
        </Layout>
        {this.state.selectedActivities ?
          <PopoverActivity
            activities={this.state.selectedActivities}
            packages={this.state.selectedPackages}
            anchor={this.state.anchor}
          /> : null }
      </div>
    );
  }


  @autobind
  private updateSelection(): void {
    if (this.engine) {
      if (this.state.selectedActivities && this.state.selectedActivities.length !== 0) {
        const selectionIds = [];
        for (const activity of this.state.selectedActivities) {
          arrayUtils.extendArray(selectionIds, activity.engineids);
        }
        this.lastSelectFromCode = true;
        this.engine.setSelected(selectionIds);

        const ids = [];

        if (this.props.activeGroup) {
          for (const activity of this.props.activeGroup.activities) {
            arrayUtils.extendArray(ids, activity.engineids);
          }
        } else if (this.props.activePackage) {
          for (const group of this.props.activePackage.activitiesGroups) {
            for (const activity of group.activities) {
              arrayUtils.extendArray(ids, activity.engineids);
            }
          }
        }

        const hasIds = !!ids.length;
        this.engine.toggleVisibility(hasIds, ids, !hasIds);
      } else if (this.props.activeGroup) {
        const ids = [];
        const selectedIds = [];
        for (const activity of this.props.activeGroup.activities) {
          arrayUtils.extendArray(ids, activity.engineids);
          if (this.props.selectedActivity.includes(activity.id)) {
            arrayUtils.extendArray(selectedIds, activity.engineids);
          }
        }

        this.lastSelectFromCode = true;
        this.engine.toggleVisibility(true, ids, false);
        this.engine.setSelected(selectedIds);
      } else if (this.props.activePackage) {
        const ids = [];
        const selectionIds = [];

        for (const group of this.props.activePackage.activitiesGroups) {
          for (const activity of group.activities) {
            arrayUtils.extendArray(ids, activity.engineids);
            if (this.props.selectedGroup.includes(group.idGroup)) {
              arrayUtils.extendArray(selectionIds, activity.engineids);
            }
          }
        }
        this.lastSelectFromCode = true;
        this.engine.toggleVisibility(true, ids, false);
        this.engine.setSelected(selectionIds);
      } else {
        this.engine.toggleVisibility(true);
        this.engine.setSelected([]);
      }
    }
    this.lastSelectFromCode = false;
  }

  @autobind
  private onSelectActivity(selectedPackageId: number): void {
    this.props.changeActivePackById(selectedPackageId);
  }

  @autobind
  private getIdsByHover(ids: number[]): void {
    if (this.engine) {
      if (ids) {
        this.engine.setColorTint('#FFFFFF', ids);
      } else {
        this.engine.setColorTint(null);
      }
    }
  }

  @autobind
  private sendEngineApi(engine: any): void {
    this.engine = engine;
    this.engine.showInvisibleAsGhost(true);
  }

  @autobind
  private engineHandleClick(ids: number[], anchorElement: any): void {

    if (this.lastSelectFromCode) {
      return;
    }

    const { activities, packages } = this.props;
    if (activities) {
      const activityList = activities.filter((act) => isEqual(act.engineids, ids));
      const packIds = activityList.map((a) => a.workpackageid);
      const packagesBySelectedActivity = packages.filter((p) => packIds.includes(p.id));

      if (activityList.length > 1) {
        this.setState({
          selectedActivities: activityList,
          selectedPackages: packagesBySelectedActivity,
          anchor: anchorElement,
        });
      } else {
        if (activityList.length === 1) {
          this.onSelectActivity(activityList[0].workpackageid);
        } else {
          if ((!this.props.selectedGroup || this.props.selectedGroup.length === 0) &&
              (!this.props.selectedActivity || this.props.selectedActivity.length === 0) &&
              (!this.state.selectedActivities || this.state.selectedActivities.length === 0)) {
            this.props.deselectPackages();
          }
        }

        this.setState({
          selectedActivities: null,
          selectedPackages: null,
          anchor: null,
        });
      }
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    activePackage: state.workpackages.activePackage,
    packages: state.workpackages.packages,
    activeGroup: state.workpackages.activeGroup,
    selectedGroup: state.workpackages.selectedGroup,
    selectedActivity: state.workpackages.selectedActivities,
    activities: state.workpackages.activities,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    changeActivePackById: (packId: number): void => {
      dispatch(WorkPackageAction.setActiveWorkPackageById(packId));
    },
    deselectPackages: (): void => {
      dispatch(WorkPackageAction.setActive(null));
    },
    dropState: () => {
      dispatch(WorkPackageAction.dropWorkPackagesState());
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const Packages = connector(PackagesPage);
