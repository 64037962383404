import styled from 'styled-components';


const HEADER_CONTAINER_HEIGHT = 167;
const BILLING_PERIOD_CONTAINER_HEIGHT = 40;

const Container = styled.div<{ isBillingPeriod: boolean }>`
  padding-top: 15px;
  margin: 0px 5px 0 20px;
  overflow-y: auto;

  height: calc(100% - ${
  p => p.isBillingPeriod ? HEADER_CONTAINER_HEIGHT + BILLING_PERIOD_CONTAINER_HEIGHT : HEADER_CONTAINER_HEIGHT
  }px);

  :hover {
    ::-webkit-scrollbar-thumb {
      visibility: initial;
    }

    ::-webkit-scrollbar-thumb {
      background: ${p => p.theme.color.disabled};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${p => p.theme.color.gray};
    }
  }
`;

const BorderLine = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${p => p.theme.color.pale};
`;


export const Styled = {
  Container,
  BorderLine,
};
