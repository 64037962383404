import { TinyText } from '@kreo/kreo-ui-components';
import styled, { keyframes } from 'styled-components';

import { PAGE_HEADER_HEIGHT } from 'common/components/page-layout/styled';
import { CommentCountMarkStyled } from 'unit-2d-comments/comment-count-mark';

const CloseButton = styled.div`
  position: absolute;
  top: -4px;
  right: 13px;
  display: none;
`;

const Wrapper = styled.div<{ isDragging: boolean }>`
  opacity: ${p => p.isDragging ? 0 : 1};
`;

const Container = styled.div<any>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${p => p.minified ? 0 : 5}px;
  min-width: ${p => p.minified ? 90 : 130}px;
  max-width: ${p => p.minified ? 90 : 200}px;
  height: ${PAGE_HEADER_HEIGHT}px;
  padding-left: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: ${p => p.isActive ? p.theme.color.mainFont : p.theme.color.turquoiseFont};
  border: 0;
  border-style: solid;
  border-color: ${p => p.theme.color.background};
  border-left-width: 1px;
  cursor: grab;
  transition: all ${p => p.theme.duration.s} linear;

  &:hover {
    border-radius: 15px;
    background: ${p => p.theme.color.pale};
  }

  &:hover ${CloseButton} {
    display: initial;
  }

  &:last-child {
    padding: 0 10px;
    min-width: ${p => p.minified ? 60 : 150}px;
    max-width: 250px;
    border-right-width: 1px;
  }

  &::before {
    display: ${props => (props.isActive ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${p => p.color || p.theme.color.red};
    border-radius: 1px 1px 4px 4px;
  }

  p {
    max-width: 100%;
  }
`;

const DropdownAppearAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const MenuContainer = styled.div`
  transform-origin: left top;
  animation: ${DropdownAppearAnimation} ${p => p.theme.duration.s} ${p => p.theme.function.oq};
`;

const PreviewImage = styled.div`
  position: relative;
  height: 40px;
  width: 60px;
  border-radius: 10px;

  ${CommentCountMarkStyled.Container} {
    left: 4px;
    top: 4px;
    border-radius: 8px 8px 8px 2px;
  }
`;

const TextContainer = styled.div<{ minified: boolean }>`
  max-width: 145px;
  display: ${p => p.minified ? 'none' : 'flex'};
  flex-direction: column;
  padding-left: 5px;
  z-index: 2;
  cursor: text;

  .pdf-tabs-item__input {
    min-width: 80px;
    padding: 5px 3px;
    font-size: inherit;
    line-height: inherit;
    border-radius: 4px;
    outline: 0;
    color: ${p => p.theme.color.mainFont};
    background-color: ${p => p.theme.color.background};
    border: 1px solid ${p => p.theme.color.turquoise};
  }

  div {
    padding-left: 0;
    box-sizing: border-box;
    cursor: text;
  }

  ${TinyText} {
    cursor: grab;
  }
`;

const HoverMenu = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 240px;
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: ${p => p.theme.shadow.xl};
`;

const HoverMenuImage = styled.div<{ imageUrl: string }>`
  width: 100%;
  height: 135px;
  background: ${p => p.imageUrl && `url(${p.imageUrl}) center/cover`};
`;

const MinifyItem = styled.div`
  width: 100%;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 10px solid ${p => p.theme.color.pale};
`;

export const Styled = {
  Wrapper,
  Container,
  MenuContainer,
  PreviewImage,
  TextContainer,
  HoverMenu,
  HoverMenuImage,
  MinifyItem,
  CloseButton,
};
