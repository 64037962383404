import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { DrawingChangeSource } from '../../enums/drawing-change-source';
import { DrawingsChange, DrawingsChanges } from '../payloads/update';
import { DrawingsUpdateActionTypes } from '../types/update';

function runNextCommitOperation(projectId: number): ActionWith<number> {
  return {
    type: DrawingsUpdateActionTypes.RUN_NEXT_COMMIT_OPERATION,
    payload: projectId,
  };
}

function clearUpdates(projectId: number): ActionWith<number> {
  return {
    type: DrawingsUpdateActionTypes.CLEAR_UPDATES,
    payload: projectId,
  };
}

function commitUpdates(
  changes: DrawingsChanges[],
  source: DrawingChangeSource,
): ActionWith<DrawingsChange> {
  return {
    type: DrawingsUpdateActionTypes.COMMIT_UPDATES,
    payload: {
      changes,
      source,
    },
  };
}

function forceSave(): Action {
  return {
    type: DrawingsUpdateActionTypes.FORCE_SAVE,
  };
}

export const DrawingsUpdateActions = {
  runNextCommitOperation,
  clearUpdates,
  commitUpdates,
  forceSave,
};
