import autobind from 'autobind-decorator';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';

import './index.scss';

import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { ReactTooltipMod } from 'common/components/react-tooltip-mod';
import { TitleEditable } from 'common/components/title-editable';
import { Location } from 'common/interfaces/common-state';
import { DATE_FORMAT, KreoButton, KreoIconDate, KreoIconEditPen, KreoIconLocation } from 'common/UIKit';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { ScenariosData } from '../../interfaces';
import { ScenarioStatusSelect } from '../scenario-status-select';

interface Props extends AbilityAwareProps {
  item: ScenariosData.Scenario;
  className: string;
  hasChangeScenarioStatus: boolean;
  hasOneCalculation: boolean;
  locations: Location[];
  expanded: boolean;
  scenarioFailed: boolean;
  canUpdate: boolean;
  changeScenarioName: (id: number, name: string) => void;
  deleteScenario: (scenario: ScenariosData.Scenario) => void;
  copyScenario: (id: number) => void;
  publishScenario: (id: number, value: boolean) => void;
  onEditScenario: (scenario: ScenariosData.Scenario) => void;
  openUpdateScenarioDialog: (scenario: ScenariosData.Scenario) => void;
}

class ScenarioItemControlComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      item,
      hasOneCalculation,
      hasChangeScenarioStatus,
      locations,
      expanded,
      className,
      canUpdate,
      scenarioFailed,
    } = this.props;
    const location = locations.find(x => x.id === item.locationId);
    const scenarioStatusChangeDisabled =
      !item.isOwner ||
      item.isAnyUserActivated ||
      (item.isPublic && !hasChangeScenarioStatus) ||
      !canUpdate;
    return (
      <div className={classNames('scenario-item-control', className)}>
        <div className='scenario-item-control__name'>
          <ScenarioStatusSelect
            statusChanged={this.changeScenarioStatus}
            className='scenario-item-control__status-select'
            isPublic={item.isPublic}
            disabled={scenarioStatusChangeDisabled}
          />
          <div className='scenario-item-control__title-row'>
            <ItemMenu
              buttonSize='small'
              menu={this.getScenarioMenu(scenarioFailed, canUpdate)}
              className={'scenario-item-control__name-menu'}
            />
            <TitleEditable
              label='Scenario Name'
              text={item.name}
              onChange={this.changeScenarioName}
              withoutSaveBtn={true}
              sizeSmall={true}
              isEditByIconClick={true}
              className='scenario-item-control__title-editable'
              canEdit={canUpdate && item.isOwner}
            />
          </div>
        </div>
        {!scenarioFailed ? (
          <>
            {item.isOwner && canUpdate ? (
              <div
                className='scenario-item-control__btn-wrap'
                data-tip=''
                data-for={`edit${item.id}`}
              >
                <KreoButton
                  disabled={item.isBidPricingScenario || item.isAnyUserActivated}
                  caption='Edit'
                  size='medium'
                  onClick={this.editScenario}
                />
                {item.isAnyUserActivated && this.getTooltip(`edit${item.id}`)}
              </div>
            ) : null}
            {(expanded || hasOneCalculation) && (
              <div
                className={classNames('scenario-item-control__data', {
                  'scenario-item-control__data--disabled': !item.isOwner,
                  'scenario-item-control__data--activated': item.isAnyUserActivated,
                })}
                onClick={canUpdate ? this.openUpdateScenarioDialog : null}
                data-tip=''
                data-for={`date${item.id}`}
              >
                <div className='scenario-item-control__data-edit-icon'>
                  <KreoIconEditPen />
                </div>
                <div className='scenario-item-control__data-row'>
                  <KreoIconDate />
                  {moment(item.startDate).format(DATE_FORMAT)}
                </div>
                <div className='scenario-item-control__data-row'>
                  <KreoIconLocation />
                  {location ? location.name : 'Other'}
                </div>
                {item.isOwner && item.isAnyUserActivated && this.getTooltip(`date${item.id}`)}
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  }

  private getTooltip(id: string): any {
    return (
      <ReactTooltipMod id={id} offset={{ left: -170 }}>
        Can`t be edited because another user has it activated
      </ReactTooltipMod>
    );
  }

  private getScenarioMenu(scenarioFailed: boolean, canUpdate: boolean): ItemMenuType[] {
    const { item } = this.props;
    let menu = [];
    if (item.isOwner) {
      menu = [
        {
          name: 'Duplicate',
          action: this.copyScenario,
          disabled: scenarioFailed || !canUpdate,
        },
        {
          name: 'Delete',
          action: this.deleteScenario,
          disabled: item.isActivated ||
            !item.isOwner ||
            item.isAnyUserActivated ||
            item.isBidPricingScenario ||
            !canUpdate,
        },
      ];
    } else {
      menu = [
        {
          name: 'Duplicate',
          action: this.copyScenario,
          disabled: scenarioFailed || !canUpdate,
        },
      ];
    }
    return menu;
  }

  @autobind
  private changeScenarioStatus(): void {
    const item = this.props.item;
    if (item.isOwner) {
      this.props.publishScenario(item.id, !item.isPublic);
    }
  }

  @autobind
  private openUpdateScenarioDialog(): void {
    const item = this.props.item;
    if (item.isOwner && !item.isAnyUserActivated) {
      this.props.openUpdateScenarioDialog(this.props.item);
    }
  }

  @autobind
  private editScenario(): void {
    const item = this.props.item;
    if (item.isOwner && !item.isAnyUserActivated) {
      this.props.onEditScenario(item);
    }
  }

  @autobind
  private changeScenarioName(value: string): void {
    this.props.changeScenarioName(this.props.item.id, value);
  }

  @autobind
  private copyScenario(): void {
    this.props.copyScenario(this.props.item.id);
  }

  @autobind
  private deleteScenario(): void {
    this.props.deleteScenario(this.props.item);
  }
}

export const ScenarioItemControl = withAbilityContext(ScenarioItemControlComponent);
