import React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { AssignedPia } from '../../../../../units/2d';
import { Person } from '../../../../../units/people/interfaces/person';
import {
  DrawingsGeometryGroup,
  DrawingsGeometryGroupWithNesting,
} from '../../interfaces';
import { AnnotationLegendItem } from '../../interfaces/annotation-legend-tree';
import { DrawingsDragAndDropData } from '../../utils/drawings-annotation-drag-utils';
import { DrawingsAnnotationLegendGroup } from '../drawings-annotation-legend-group';
import { GroupsMenuType } from '../groups-menu';
import { LegendItemAPI } from '../interfaces';
import { DrawingsAssign } from '../utils/assing-utils';
import { NotPinnedFoldersHeader } from './not-pinned-folders-header';
import { PinnedFoldersHeader } from './pinned-folders-header';
import { Styled } from './styled';
import { useGroups } from './use-groups';

interface StateProps {
  pinnedGroups: string[];
}

interface OwnProps {
  groups: AnnotationLegendItem[];
  selectedGroups: string[];
  groupsOpenMap: Record<string, boolean>;
  canEditMeasurement: boolean;
  users: Person[];
  onGroupClick: (event: React.MouseEvent<HTMLDivElement>, group: DrawingsGeometryGroupWithNesting) => void;
  changeGroupPinStatus: (groupId: string) => void;
  onGroupNameChanged: (groupId: string, name: string) => void;
  onHiddenChanged: (ids: string[], isHidden: boolean) => void;
  onGroupOpenChange: (id: string) => void;
  onGroupDoubleClick: (instancesIds: string[]) => void;
  onGroupContextMenu: (e: React.MouseEvent<HTMLDivElement>, group: DrawingsGeometryGroupWithNesting) => boolean;
  moveToGroup(groupId: string, groupIds: string[], measurementIds: string[]): void;
  assignPia: Record<string, AssignedPia>;
  groupsMap: Record<string, DrawingsGeometryGroup>;
  openGroupMenu: (
    e: React.MouseEvent<HTMLDivElement>,
    group: DrawingsGeometryGroupWithNesting,
    menuType: GroupsMenuType,
  ) => void;
  getItemsToMove: () => DrawingsDragAndDropData;
  sendUpdateApi: (api: LegendItemAPI, id: string) => void;
  isOpenFilterPages: boolean;
}

interface Props extends OwnProps, StateProps {

}

const PinnedGroupsComponent: React.FC<Props> = (props: Props) => {
  const {
    groups,
    pinnedGroups,
    selectedGroups,
    groupsOpenMap,
    changeGroupPinStatus,
    onGroupNameChanged,
    onHiddenChanged,
    onGroupClick,
    onGroupOpenChange,
    onGroupContextMenu,
    onGroupDoubleClick,
    moveToGroup,
    assignPia,
    groupsMap,
    openGroupMenu,
    getItemsToMove,
    canEditMeasurement,
    sendUpdateApi,
    isOpenFilterPages,
  } = props;


  const groupsToShow = useGroups(groups, pinnedGroups);

  const sendUpdateApiCallback = React.useCallback((api: LegendItemAPI, id: string) => {
    sendUpdateApi(api, `pinned-${id}`);
  }, [sendUpdateApi]);

  if (!groupsToShow.length) {
    return null;
  }
  return (
    <>
      <Styled.Container>
        <PinnedFoldersHeader
          pinnedGroups={groupsToShow}
          canEditMeasurement={canEditMeasurement}
        />
        <Styled.List elements={groupsToShow.length}>
          <KreoScrollbars>
            {groupsToShow.map(group => {
              const isSelected = selectedGroups.includes(group.id);
              const hasAssignedPia = DrawingsAssign.hasAssignedPia(group.id, assignPia);
              const hasInheritedPia = !hasAssignedPia
                && DrawingsAssign.hasInheritedPiaSimple(group.groupId, assignPia, groupsMap);
              return (
                <DrawingsAnnotationLegendGroup
                  key={group.id}
                  group={group}
                  groupId={group.id}
                  groupName={groupsMap[group.groupId]?.name || group.name}
                  isOpen={groupsOpenMap[group.id]}
                  onPinClick={changeGroupPinStatus}
                  isSelected={isSelected}
                  isHidden={false}
                  canChangeVisibility={false}
                  onNameChange={onGroupNameChanged}
                  onHiddenChange={onHiddenChanged}
                  onClick={onGroupClick}
                  onToggleOpen={onGroupOpenChange}
                  onContextMenu={onGroupContextMenu}
                  onDoubleClick={onGroupDoubleClick}
                  moveToGroup={moveToGroup}
                  getItemsToMove={getItemsToMove}
                  nestingCount={0}
                  canEditMeasurement={canEditMeasurement}
                  openGroupMenu={openGroupMenu}
                  hasAssignedPia={hasAssignedPia}
                  hasInheritedPia={hasInheritedPia}
                  isPinned={true}
                  sendUpdateApi={sendUpdateApiCallback}
                  isOpenFilterPages={isOpenFilterPages}
                  isHighlighted={false}
                />
              );
            })}
          </KreoScrollbars>
        </Styled.List>
      </Styled.Container>
      <NotPinnedFoldersHeader />
    </>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    pinnedGroups: state.drawings.pinnedGroupIds,
  };
}

export const PinnedGroups = connect(mapStateToProps)(PinnedGroupsComponent);
