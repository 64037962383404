import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { State as ReduxState } from 'common/interfaces/state';
import { Stepper } from '../../../actions';
import { ScenarioRouteParams } from '../../../routes/app-routes-params';
import { ScenarioEditPage, ScenariosData } from '../interfaces';
import { ActivityGroupingStep } from './activity-grouping-step';
import { Master } from './master';

interface ReduxProps {
  scenario: ScenariosData.Scenario;
}

interface ReduxActions {
  setStep: (step: number) => void;
}

interface Props
  extends ReduxProps,
    ReduxActions,
    RouteComponentProps<ScenarioRouteParams> {}

class ActivityGroupsPage extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
    this.props.setStep(ScenarioEditPage.Sequence);
  }

  public render(): JSX.Element {
    const { scenario, match } = this.props;
    return (
      <Master scenario={scenario} projectId={match.params.projectId}>
        <div className='activity-grouping-step-container'>
          <ActivityGroupingStep projectId={+match.params.projectId} scenarioId={+match.params.scenarioId} />
        </div>
      </Master>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    scenario: state.scenarios.editScenario,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    setStep: (step) => dispatch(Stepper.setStep(step)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityGroupingPage = connector(ActivityGroupsPage);
