import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { ClassificationChart } from '../../../../../components/charts';
import { ObjectStatisticType } from '../../../../../components/charts/interfaces/object-statistic-type';
import { ModuleStatus, ScenarioModule, ScenarioState } from '../../../../projects/interfaces/scenario-state';
import { ScenarioStateHelper } from '../../../../projects/utils/scenario-state-helper';
import { ScenariosActions, ScenariosData } from '../../../../scenarios';
import { ActivityAssignmentActions } from '../../../actions/creators/activity-assignment';
import { ValidationActions } from '../../../actions/creators/validation';
import { isValidationStepGreaterThan, ValidationStep } from '../../../enums/validation-step';
import { ValidationStepState } from '../../../enums/validation-step-state';
import { StepProps, ValidationStepWrapper } from '../components/validation-stepper';
import { DatabaseSelector } from './components';

interface DispatchProps {
  runActivityAssignmentModule: (scenarioId: number, documentId: number, dbIds: number[]) => void;
  loadData: () => void;
  dropStatistics: () => void;
  getActiveScenarioByProject: (projectId: number) => void;
}

interface StateProps {
  projectId: number;
  statistic: ObjectStatisticType[];
  dataLoadingStatus: RequestStatus;
  errors: number[];
  isStepPassed: boolean;
  isEditable: boolean;
  canBeCalculated: boolean;
  isCalculating: boolean;
  scenario: ScenariosData.Scenario;
  scenarioState: ScenarioState;
  databaseIds: number[];
}

interface OwnProps extends StepProps, AbilityAwareProps {}

interface Props extends OwnProps, StateProps, DispatchProps {}

class ActivityAssignment extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const scenarioState = this.props.scenarioState;
    const scenarioInCalculation = scenarioState && (
      scenarioState.moduleStatuses[ScenarioModule.ActivityVariants] === ModuleStatus.InCalculating || (
        scenarioState.inCalculation &&
        scenarioState.moduleStatuses[ScenarioModule.ActivityVariants] === ModuleStatus.Empty
      )
    );
    const shouldDisplayLoader = scenarioInCalculation || this.props.dataLoadingStatus === RequestStatus.Loading;

    const urlParams = { projectId: this.props.projectId.toString() };
    const viewLink = AppUrls.plan.project.validation.viewActivityAssignment.url(urlParams);
    const fixLink = AppUrls.plan.project.validation.editActivityAssignment.url(urlParams);

    return (
      <ValidationStepWrapper>
        <h3>{this.props.title}</h3>
        <div className='validation-step-content__inner-wrap'>
          <React.Fragment>
            <DatabaseSelector
              onCalculate={this.runActivityAssignmentModule}
              canBeCalculated={this.props.canBeCalculated}
              databasesCalculated={this.props.databaseIds}
              allowSettings={false}
            />
            <ClassificationChart
              fixLink={fixLink}
              viewLink={viewLink}
              isFixable={this.props.isEditable}
              data={this.props.statistic || []}
              isLoading={shouldDisplayLoader}
              noDataMessage='No calculation yet'
              onSelectIds={this.isolate}
              onDeselect={this.showAll}
              chartName={ValidationStep.ActivityAssignment}
              attentionStatus={this.hasUndefinedStatistic()}
            />
          </React.Fragment>
        </div>
      </ValidationStepWrapper>
    );
  }

  public componentDidMount(): void {
    const { scenario, isStepPassed: isPassed, dropStatistics } = this.props;
    this.updateEngineColors();
    if (scenario && scenario.id && isPassed) {
      this.loadDataIfNeeded(this.props, null);
    } else {
      dropStatistics();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    const needLoadDataByStatusChange = this.props.isStepPassed && !prevProps.isStepPassed;
    if (needLoadDataByStatusChange && !this.props.scenario) {
      this.props.getActiveScenarioByProject(this.props.projectId);
      return;
    }

    this.updateEngineColors();
    this.loadDataIfNeeded(this.props, prevProps);
  }

  private updateEngineColors(): void {
    const { stepperRef, errors, statistic, dataLoadingStatus } = this.props;
    if (!stepperRef) {
      return;
    }

    if (
      dataLoadingStatus === RequestStatus.Loading
      && Array.isArray(errors)
      && errors.length
    ) {
      stepperRef.paintEngine([], errors);
    }

    if (!statistic || !statistic.length) {
      stepperRef.clearEngine();
    }
  }

  private loadDataIfNeeded(currentProps: Props, prevProps: Props): void {
    const currentState = currentProps.scenarioState;
    const prevState = prevProps && prevProps.scenarioState;
    const needToLoad = ScenarioStateHelper.shouldLoadModel(currentState, prevState, ScenarioModule.ActivityVariants);

    if (needToLoad) {
      this.props.loadData();
    }
  }

  @autobind
  private hasUndefinedStatistic(): boolean {
    const undefinedStat = this.props.statistic ? this.props.statistic.find(s => s.name === 'Undefined') : null;
    return undefinedStat && undefinedStat.amount > 0;
  }


  @autobind
  private runActivityAssignmentModule(ids: number[]): void {
    if (this.props.canBeCalculated) {
      const scenarioId = this.props.scenario ? this.props.scenario.id : 0;
      this.props.runActivityAssignmentModule(scenarioId, this.props.projectId, ids);

      if (this.props.stepperRef) {
        this.props.stepperRef.clearEngine();
      }
    }
  }

  @autobind
  private isolate(ids: number[]): void {
    if (this.props.stepperRef) {
      this.props.stepperRef.isolate(ids);
    }
  }

  @autobind
  private showAll(): void {
    if (this.props.stepperRef) {
      this.props.stepperRef.showAll();
    }
  }
}

const mapStateToProps = (state: State, { ability }: OwnProps): StateProps => {
  const project = state.projects.currentProject;
  const validationState = project ? project.validationState : null;

  const canUpdate = ability.can(Operation.Update, Subject.ValidationActivityAssignment);

  const isEditable =
    validationState &&
    validationState.validationStep === ValidationStep.ActivityAssignment &&
    validationState.stepState === ValidationStepState.Approvable &&
    canUpdate;

  const isStepPassed =
    validationState &&
    (isValidationStepGreaterThan(validationState.validationStep, ValidationStep.ActivityAssignment) || isEditable);

  const canBeCalculated =
    validationState &&
    validationState.validationStep === ValidationStep.ActivityAssignment &&
    (validationState.stepState === ValidationStepState.NeedsCalculation ||
      validationState.stepState === ValidationStepState.Approvable) &&
    canUpdate;

  const isCalculating =
    validationState &&
    validationState.validationStep === ValidationStep.ActivityAssignment &&
    validationState.stepState === ValidationStepState.Calculating;
  const scenario = state.scenarios.active_scenario;
  const scenarioState = scenario && state.projects.scenarioStates[scenario.scenarioGuid];

  return {
    databaseIds: state.ceActivityAssignment.databases,
    projectId: project ? project.id : null,
    statistic: state.activityAssignment.statistic,
    dataLoadingStatus: state.activityAssignment.statuses.loadData,
    errors: state.activityAssignment.badIds,
    isStepPassed,
    isEditable,
    canBeCalculated,
    isCalculating,
    scenario,
    scenarioState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    runActivityAssignmentModule: (scenarioId: number, documentId: number, dbIds: number[]) => {
      dispatch(ValidationActions.calculateActivityAssignment(documentId, scenarioId, dbIds));
    },
    loadData: () => {
      dispatch(ActivityAssignmentActions.loadData());
    },
    dropStatistics: () => dispatch(ActivityAssignmentActions.dropStatistics()),
    getActiveScenarioByProject: projectId =>
      dispatch(ScenariosActions.getActiveScenarioByProject(projectId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityAssignmentStep = withAbilityContext(connector(ActivityAssignment));
