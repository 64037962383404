import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { CostEstimateProjectRevisionRouteParams } from 'routes/app-routes-params';
import { ActivityAssignment } from 'unit-cost-estimate/components/activity-assignment';
import { ProjectLayout } from '../../../units/project-dashbord';

interface PageProps extends RouteComponentProps<CostEstimateProjectRevisionRouteParams> {
}

export const ActivityAssignmentPage = (props: PageProps): JSX.Element => {
  const { projectId, revisionId } = props.match.params;

  return (
    <ProjectLayout
      // waitingForAdditionalInfo={!isLoaded}
      projectId={projectId}
    >
      <ActivityAssignment
        projectId={parseInt(projectId)}
        revisionId={parseInt(revisionId)}
      />
    </ProjectLayout>
  );
};
