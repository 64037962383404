import {
  AddCommentPayload,
  AddSubCommentRequestPayload,
  CommentarySpreadsheetReportPageTarget,
  CommentaryThread,
  EditCommentTextPayload,
  EditSubCommentTextPayload,
  RemoveSubCommentPayload,
} from 'unit-2d-comments/interfaces';
import { CompanyResourcesApi } from '../../../api/server';


function getCommentaries(projectId: number): Promise<CommentaryThread[]> {
  return CompanyResourcesApi.get(`/projects/${projectId}/comment-threads`);
}

function createComment(projectId: number, payload: AddCommentPayload): Promise<CommentaryThread> {
  return CompanyResourcesApi.post(
    `/projects/${projectId}/comment-threads`,
    { ...payload, target: { ...payload.target, projectId } },
  );
}

function removeComment(projectId: number, commentId: number): Promise<void> {
  return CompanyResourcesApi.delete(`/projects/${projectId}/comment-threads/${commentId}`, null);
}

function updateComment(projectId: number, { commentId, text }: EditCommentTextPayload): Promise<CommentaryThread> {
  return CompanyResourcesApi.put(`/projects/${projectId}/comment-threads/${commentId}`, { text });
}

function createMessage(projectId: number, { commentId, text }: AddSubCommentRequestPayload): Promise<CommentaryThread> {
  return CompanyResourcesApi.post(`/projects/${projectId}/comment-threads/${commentId}/messages`, { text });
}

function removeMessage(projectId: number, { commentId, subCommentId }: RemoveSubCommentPayload): Promise<void> {
  return CompanyResourcesApi
    .delete(`/projects/${projectId}/comment-threads/${commentId}/messages/${subCommentId}`, null);
}

function editMessage(
  projectId: number,
  { commentId, subCommentId, text }: EditSubCommentTextPayload,
): Promise<CommentaryThread> {
  return CompanyResourcesApi
    .put(`/projects/${projectId}/comment-threads/${commentId}/messages/${subCommentId}`, { text });
}

function resolveComment(projectId: number, commentId: number): Promise<void> {
  return CompanyResourcesApi.post(`/projects/${projectId}/comment-threads/${commentId}/resolve`);
}

function openComment(projectId: number, commentId: number): Promise<void> {
  return CompanyResourcesApi.post(`/projects/${projectId}/comment-threads/${commentId}/open`);
}

function moveSpreadSheetComments(
  projectId: string,
  commentId: number,
  target?: CommentarySpreadsheetReportPageTarget,
): Promise<void> {
  return CompanyResourcesApi.put(`/projects/${projectId}/comment-threads/${commentId}`, { target });
}

export const TwoDCommentsApi = {
  getCommentaries,
  createComment,
  removeComment,
  updateComment,
  createMessage,
  editMessage,
  removeMessage,
  resolveComment,
  openComment,
  moveSpreadSheetComments,
};
