import * as React from 'react';

import './classification-revit-tree-item-buttons-container.scss';

import { KreoIconIsolation } from 'common/UIKit';
import { ClassificationRevitTreeItemRoundedButton } from '../classification-revit-tree-item-rounded-button';


interface Props {
  showIsolationButton: boolean;
  onIsolateClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export class ClassificationRevitTreeItemButtonsContainer extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { showIsolationButton, children } = this.props;
    if (!showIsolationButton) {
      return children;
    }
    return (
      <ClassificationRevitTreeItemRoundedButton
        controlName='isolate-group'
        onClick={this.props.onIsolateClick}
        tootlipText='Isolate'
      >
        <KreoIconIsolation/>
      </ClassificationRevitTreeItemRoundedButton>
    );
  }
}
