import React from 'react';

import './index.scss';

import { MacroSequence } from '../macro-sequence/macro-sequence';


interface Props {
  scenarioId: number;
}

export class MacroSequencePage extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='macro-sequence-page'>
        <div className='macro-sequence-page__content'>
          <MacroSequence
            scenarioId={this.props.scenarioId}
          />
        </div>
      </div>
    );
  }
}
