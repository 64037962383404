import styled from 'styled-components';

import { UpgradeWrapperStyled } from '../upgrade-plan';


const ImportItemWrapper = styled.div`
  ${UpgradeWrapperStyled.StarsContainer} {
    right: 5px;
  }
`;

const Tabs = styled.div<{ isFullScreenTable?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 60px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 10px;
  box-sizing: border-box;
  width: 200px;
  :not(:first-of-type) {
    border-top: 1px solid ${p => p.theme.color.background};
  }

  button {
    pointer-events: ${p => p.isFullScreenTable ? 'none' : 'auto'}
  }

  p {
    margin-left: 5px;
  }
`;

const Icons = styled.div<{ isDisabledButton?: boolean }>`
  display: grid;
  grid-template-columns: repeat(5, 35px);
  margin-top: 10px;

  > button {
    pointer-events: ${p => p.isDisabledButton ? 'none' : 'auto'};
  }
`;

const ItemDisabled = styled.div`
  width: 100%;
  pointer-events: none;
  cursor: default;
  border-bottom: 1px solid ${p => p.theme.color.background};

  p {
    color: ${p => p.theme.color.disabled};
  }

  svg {
    fill:  ${p => p.theme.color.disabled};
  }
`;

const SwitchWithLabel = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid ${p => p.theme.color.background};
  cursor: pointer;

  &:last-of-type {
    padding: 10px 20px 20px 20px;
  }

  p {
    width: calc(100% - 50px);
  }
`;

export const Styled = {
  ImportItemWrapper,
  Tabs,
  ItemDisabled,
  Icons,
  SwitchWithLabel,
};
