import { FlowNavigation, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import {
  DrawingsColoredSVGUrls,
  DrawingsSolidColorSVGUrls,
  DrawingsSVGIcons,
} from '../../constants/drawings-svg-icons';
import { DrawingsSvgMenuItem } from './drawings-svg-menu-item';
import { Styled } from './drawings-svg-menu-styled';


enum MenuNames {
  SolidColor = 'Solid Color',
  Multicolored = 'Multicolored',
}

interface State {
  currentTab: MenuNames;
}

interface Props {
  onSelect: (iconId: DrawingsSVGIcons) => void;
}

export class DrawingsSvgMenu extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentTab: MenuNames.SolidColor,
    };
  }

  public render(): React.ReactNode {
    const menuItems = Object.values(MenuNames).map(name => {
      return {
        name,
        current: name === this.state.currentTab,
        onClick: () => this.setTab(name),
      };
    });
    return (
      <Styled.Container>
        <Styled.Header>
          <FlowNavigation
            items={menuItems}
            TextComponent={Text}
            borderRadius={5}
            borderOverhang={15}
            height={5}
            marginRight={35}
          />
        </Styled.Header>
        <Styled.IconListContainer>
          <Styled.IconsList>
            { this.state.currentTab === 'Solid Color' ? (
              DrawingsSolidColorSVGUrls.map(x => (
                <DrawingsSvgMenuItem
                  key={x}
                  iconId={x}
                  multicolor={false}
                  onSelect={this.props.onSelect}
                />
              ))
            ) :
              DrawingsColoredSVGUrls.map(x => (
                <DrawingsSvgMenuItem
                  key={x}
                  iconId={x}
                  multicolor={true}
                  onSelect={this.props.onSelect}
                />))
            }
          </Styled.IconsList>
        </Styled.IconListContainer>
      </Styled.Container>
    );
  }

  private setTab(name: MenuNames): void {
    this.setState({ currentTab: name });
  }
}

