import autobind from 'autobind-decorator';
import * as React from 'react';

import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';

interface Props {
  elementName: string;
  isReadOnly: boolean;
  onAlwaysClick: () => void;
  onJustOnceClick: () => void;
}

export class ActivityAssignmentDatabaseActivityListingDialog extends React.Component<Props> {
  public static dialogName: string = 'assignDialog';

  public render(): JSX.Element {
    const { elementName: selectedEndElementName } = this.props;

    return (
      <KreoConfirmationDialog
        name={ActivityAssignmentDatabaseActivityListingDialog.dialogName}
        onOpen={this.onOpen}
        yesText='Just Once'
        onYes={this.props.onJustOnceClick}
        noText='Always'
        onNo={this.props.onAlwaysClick}
        title='Assign Activity Variant'
      >
        <p>{`Would you like always use these Activity Variants for
          ${selectedEndElementName} or only for the current project`}</p>
      </KreoConfirmationDialog>
    );
  }

  @autobind
  private onOpen(): void {
    if (this.props.isReadOnly) {
      this.props.onJustOnceClick();
    }
  }
}
