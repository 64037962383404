import autobind from 'autobind-decorator';
import * as React from 'react';

import './indirect-cost-tile-item.scss';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { MaterialInput } from 'common/UIKit/material/material-input';
import { TextFormatting, WorkPackagesData } from '../../../scenarios/interfaces';

interface Props extends React.Props<IndirectCostTileItemComponent>, I18nAwareProps {
  item: WorkPackagesData.IndirectCost;
  children?: Array<React.ReactElement<IndirectCostTileItemComponent>>;
  onEditParameter: (id: number, value: string) => void;
}

export class IndirectCostTileItemComponent extends React.Component<Props> {

  public render(): JSX.Element {
    const { item } = this.props;

    return (
        <div className='indirect-cost-tile-item'>
          <div className='indirect-cost-tile-item__title'>
            {item.name}
          </div>
          {item.parameters ? (
            <div className='indirect-cost-tile-item__options'>
              {item.parameters.map((param, index) => {
                const content = this.getParamContent(param, item.innerGuid);
                return <React.Fragment key={index}>{content}</React.Fragment>;
              })}
            </div>
          ) : null}
        </div>
    );
  }

  @autobind
  private getParamContent(
    param: WorkPackagesData.IndirectCostParameter,
    fieldGuid: string,
  ): JSX.Element {
    const { onEditParameter, i18n } = this.props;

    const formatting = param.formating;
    const materialInputOnChange = (_event: React.ChangeEvent, value: string): void => {
      onEditParameter(param.id, value);
    };

    let pattern: (value: string) => string;

    switch (formatting) {
      case TextFormatting.Currency:
        pattern = (value: string): string => `${i18n.currency.symbol}${value}`;
        break;
      case TextFormatting.Percentage:
        pattern = (value: string): string => `${value} %`;
        break;
      case TextFormatting.Time:
        pattern = (value: string): string => `${value} days`;
        break;
      default:
        pattern = null;
    }

    return (
      <MaterialInput
        displayedType={MaterialComponentType.Native}
        name={fieldGuid}
        className='indirect-cost-tile-item__text-field'
        onChange={materialInputOnChange} // eslint-disable-line react/jsx-no-bind
        value={param.value}
        valueType={formatting === TextFormatting.Time ? 'integer' : 'float'}
        displayPattern={pattern}
      />
    );
  }
}

export const IndirectCostTileItem = withI18n(IndirectCostTileItemComponent);
