import { AnyAction } from 'redux';

import { USER_ACTIVITIES_INITIAL_STATE } from '../units/activity/initial-state';
import { UserActivitiesState } from '../units/activity/interfaces/redux-state';
import { userActivitiesReducerMethods } from '../units/activity/reducer';

export default function userActivities(
  state: UserActivitiesState = USER_ACTIVITIES_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): UserActivitiesState {
  if (action.type in userActivitiesReducerMethods) {
    return userActivitiesReducerMethods[action.type](state, action.payload);
  }
  return state;
}
