import { DrawingsCanvasConstants } from '../../../constants/drawing-canvas-constants';
import { DrawingsInstanceType } from '../../../enums/drawings-instance-type';
import { DrawingsCanvasUtils } from '../../../utils/drawings-canvas-utils';
import { FinishedDrawingElement } from './drawings-geometry-temp-entity';
import { DrawingsGeometryTempEntityWithStroke } from './drawings-geometry-temp-entity-with-stroke';

export class DrawingsGeometryTempCalibrateEntity extends DrawingsGeometryTempEntityWithStroke {
  public override convert(): FinishedDrawingElement[] {
    const { x: x1, y: y1 } = this._path.firstSegment.point;
    const { x: x2, y: y2 } = this._path.lastSegment.point;
    const startPoint = `${DrawingsCanvasConstants.calibrateLineId}-start`;
    const endPoint = `${DrawingsCanvasConstants.calibrateLineId}-end`;
    const { scale } = this._config.textRenderParametersObserver.getContext();
    return [{
      type: DrawingsInstanceType.CalibrationLine,
      points: { [startPoint]: [x1, y1], [endPoint]: [x2, y2] },
      geometry: {
        points: [startPoint, endPoint],
        color: this._config.color.stroke.toCSS(true),
        pxLength: this._path.length,
        length: DrawingsCanvasUtils.pxToMetres(this._path.length, scale),
        strokeStyle: this._config.strokeStyle,
        strokeWidth: this._config.strokeWidth,
      },
    }];
  }
}
