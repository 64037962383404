import { Icons, MovableContextMenu } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { TwoDActions } from '2d/actions/creators';
import { UPGRADE_PRO_PLAN_CONSTANTS, UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RenderIf } from 'common/components/render-if';
import { ControlNames } from 'common/constants/control-names';
import { KreoDialogActions } from 'common/UIKit';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { CreateTab } from './create-tab';
import { Styled } from './styled';


interface DispatchProps {
  setIsGetPageData: () => void;
  openUpgadePlanDialog: () => void;
}

interface Props extends AnalyticsProps, DispatchProps, AbilityAwareProps {
  isOpen: boolean;
  positionX: number;
  positionY: number;
  onClose: () => void;
  createSpreadSheet: () => void;
  createMeasurementsView: () => void;
  createItemView: () => void;
}

class ContextMenuComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { isOpen, positionX, positionY, onClose, ability, openUpgadePlanDialog } = this.props;
    const isNeedUpdate = ability.cannot(Operation.Read, Subject.PiaDatabase);
    const onClickCreateItemReport = isNeedUpdate
      ? openUpgadePlanDialog
      : this.onCreateItemView;
    return (
      <RenderIf condition={isOpen}>
        <MovableContextMenu
          onClose={onClose}
          x={positionX}
          y={positionY}
        >
          <CreateTab
            onClick={this.onCreateSpreadSheet}
            name={'Spreadsheet Page'}
            Icon={Icons.Table2D}
            controlName={ControlNames.spreadsheetPage}
          />
          <Styled.CreateTabWrapper>
            <UpgradeWrapper isNeedUpdate={isNeedUpdate}>
              <CreateTab
                onClick={onClickCreateItemReport}
                name={'Items Report'}
                Icon={Icons.ItemsList}
                disabled={isNeedUpdate}
                controlName={ControlNames.itemReport}
              />
            </UpgradeWrapper>
          </Styled.CreateTabWrapper>
          <CreateTab
            onClick={this.onCreateTakeoffView}
            name={'Takeoffs Report'}
            Icon={Icons.MeasurementsList}
            controlName={ControlNames.takeoffReport}
          />
        </MovableContextMenu>
      </RenderIf>
    );
  }

  @autobind
  private onCreateSpreadSheet(): void {
    const { createSpreadSheet, onClose, setIsGetPageData } = this.props;
    setIsGetPageData();
    createSpreadSheet();
    onClose();
    this.props.sendEvent(MetricNames.reports.createSpreadSheet);
  }

  @autobind
  private onCreateTakeoffView(): void {
    const { createMeasurementsView, onClose, setIsGetPageData } = this.props;
    setIsGetPageData();
    createMeasurementsView();
    onClose();
    this.props.sendEvent(MetricNames.reports.createTakeoffReport);
  }

  @autobind
  private onCreateItemView(): void {
    const { createItemView, onClose, setIsGetPageData } = this.props;
    setIsGetPageData();
    createItemView();
    onClose();
    this.props.sendEvent(MetricNames.reports.createItemsReport);
  }
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setIsGetPageData: () => dispatch(TwoDActions.setIsGetPageData(false)),
    openUpgadePlanDialog: () => dispatch(KreoDialogActions.openDialog(UPGRADE_PRO_PLAN_CONSTANTS.UPGRADE_PLAN_DIALOG)),
  };
}

export const ContextMenu = connect(null, mapDispatchToProps)(
  withAnalyticsContext(withAbilityContext(ContextMenuComponent)));
