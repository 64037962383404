import { RowNode } from 'ag-grid-community';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './quantity-take-off-report-panel.scss';

import { TreeTable } from 'common/components/tree-table';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { ModelType, QtoTreeRowProperty } from '../../interfaces/quantity-take-off';
import { QtoReport } from '../../interfaces/quantity-take-off/quantity-take-off-report';
import { QtoSyncBreakdownData } from '../../interfaces/quantity-take-off/sync-breakdown-data';
import { TableApi } from '../quantity-take-off-left-panel/interfaces';
import { QtoReportPivotTable } from '../quantity-take-off-report-pivot-table/quantity-take-off-report-pivot-table';
import { QtoReportTable } from '../quantity-take-off-report-table';
import { QtoReportTableComponent } from '../quantity-take-off-report-table/quantity-take-off-report-table';

export interface QtoReportTableApi {
  onReportExport: () => void;
  expandAll: () => void;
  collapseAll: () => void;
  syncWithElements: (syncData: QtoSyncBreakdownData) => void;
}

interface ReduxProps {
  selectedReportId: number | null;
  loadReportStatus: RequestStatus;
  reportModel: QtoReport;
}

interface OwnProps {
  projectId: number;
  modelType: ModelType;
  isPivotMode: boolean;
  findInElements: (ids: Array<string | number>) => void;
  onSelectionChange: (ids: number[]) => void;
  sendTableSelectionApi: (ref: TableApi) => void;
  sendTableApi: (api: QtoReportTableApi) =>  void;
  getSyncBreakdownData: (nodes: RowNode[]) => QtoSyncBreakdownData;
  onReportReady: () => void;
  readonly: boolean;
}

interface Props extends OwnProps, ReduxProps {
}

class QtoReportPanelComponent extends React.Component<Props> {
  private reportTableRef: QtoReportTableComponent;
  private pivotTableRef: TreeTable<QtoTreeRowProperty, QtoTreeRowProperty>;

  public render(): JSX.Element {
    return (
      <div className='quantity-take-off-report-panel'>
        {
          this.props.isPivotMode && this.props.reportModel.showPivot ? (
            <QtoReportPivotTable
              saveTableRef={this.savePivotTableRef}
              projectId={this.props.projectId}
              modelType={this.props.modelType}
              readonly={this.props.readonly}
            />
          ) : (
              <QtoReportTable
                findInElements={this.props.findInElements}
                saveRef={this.saveTableRef}
                projectId={this.props.projectId}
                modelType={this.props.modelType}
                onSelectionChange={this.props.onSelectionChange}
                sendTableSelectionApi={this.sendTableSelectionApi}
                getSyncBreakdownData={this.props.getSyncBreakdownData}
                onReportReady={this.props.onReportReady}
                readonly={this.props.readonly}
              />
          )
        }
      </div>
    );
  }

  public componentDidMount(): void {
    if (this.props.isPivotMode) {
      this.sendTableSelectionApi({});
    }
    this.props.sendTableApi({
      onReportExport: this.onReportExport,
      expandAll: this.expandAll,
      collapseAll: this.collapseAll,
      syncWithElements: this.syncWithElements,
    });
  }

  public componentDidUpdate(): void {
    if (this.pivotTableRef && !this.props.isPivotMode) {
      this.pivotTableRef = null;
    }
  }

  @autobind
  public sendTableSelectionApi(ref: TableApi): void {
    this.props.sendTableSelectionApi({ ...ref });
  }

  @autobind
  private savePivotTableRef(ref: TreeTable<QtoTreeRowProperty, QtoTreeRowProperty>): void  {
    this.pivotTableRef = ref;
  }

  @autobind
  private collapseAll(): void {
    if (this.props.isPivotMode) {
      if (this.pivotTableRef && this.pivotTableRef.gridApi) {
        this.pivotTableRef.gridApi.collapseAll();
      }
    } else if (this.reportTableRef && this.reportTableRef.tableRef && this.reportTableRef.tableRef.gridApi) {
      this.reportTableRef.tableRef.gridApi.collapseAll();
    }
  }


  @autobind
  private syncWithElements(syncData: QtoSyncBreakdownData): void {
    if (this.reportTableRef) {
      this.reportTableRef.syncWithElementNodes(syncData);
    }
  }

  @autobind
  private expandAll(): void {
    if (this.props.isPivotMode) {
      if (this.pivotTableRef && this.pivotTableRef.gridApi) {
        this.pivotTableRef.gridApi.expandAll();
      }
    } else if (this.reportTableRef && this.reportTableRef.tableRef && this.reportTableRef.tableRef.gridApi) {
      this.reportTableRef.tableRef.gridApi.expandAll();
    }
  }

  @autobind
  private saveTableRef(ref: QtoReportTableComponent): void {
    this.reportTableRef = ref;
  }

  @autobind
  private onReportExport(): void {
    if (this.reportTableRef) {
      this.reportTableRef.executeAllChanges();
    }
  }

}

const mapStateToProps = (state: State): ReduxProps => {
  const reportState = state.quantityTakeOff.report;
  return {
    loadReportStatus: reportState.statuses.loadReport,
    reportModel: reportState.reportModel,
    selectedReportId: reportState.selectedReportId,
  };
};


const connector = connect(mapStateToProps);
export const QtoReportPanel = connector(QtoReportPanelComponent);

