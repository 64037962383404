import { CompanyInfoStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';
import { SpinnerStyled } from 'common/components/spinner';

const CompanyInfoWrapper = styled.div<{ canManage: boolean }>`
  width: fit-content;
  height: fit-content;
  position: relative;

  ${SpinnerStyled.SpinnerContainer} {
    border-radius: 40px;
  }

  ${CompanyInfoStyled.UserMenu} {
    display: ${p => p.canManage ? 'block' : 'none'};
  }
`;

export const Styled = {
  CompanyInfoWrapper,
};
