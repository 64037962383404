import { H6, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { BillingPeriodUnit } from '../../../enums/billing-period-unit';
import { BillingPricingModel } from '../../../enums/billing-pricing-model';
import { SubscriptionAddon } from '../../../interfaces/subscription-addon';
import { SubscriptionDetailsModel } from '../../../interfaces/subscription-details';
import { PriceField } from '../../price-field';
import { Styled } from './styled';

interface StateProps {
  rates: Record<string, number>;
}

interface Props extends StateProps {
  currentSubscription: SubscriptionDetailsModel;
  addons: SubscriptionAddon[];
  isViewMode?: boolean;
}

class SubscriptionPriceComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const variant = this.props.currentSubscription.planVariant;
    const price = this.getPrice();

    return (
      <Styled.Container isViewMode={this.props.isViewMode}>
        <H6>{variant.name}</H6>
        <Styled.Price>
          <Styled.PriceCost>
            <Styled.PrimaryPrice>
              <PriceField GBPPrice={price} />
            </Styled.PrimaryPrice>
          </Styled.PriceCost>
          <Styled.PriceDescription>
            <Text fontSize={14} color='additionalFont'>
              {variant.pricingModel === BillingPricingModel.PerUnit
                ? 'per user, per month'
                : 'per month'}
            </Text>
            {
              variant.billingPeriodUnit === BillingPeriodUnit.Year && (
                <Text fontSize={12} color='additionalFont'>{'(paid annually)'}</Text>
              )
            }
          </Styled.PriceDescription>
        </Styled.Price>
      </Styled.Container>
    );
  }

  private getMonthCount(period: BillingPeriodUnit): number {
    switch (period) {
      case BillingPeriodUnit.Month: return 1;
      case BillingPeriodUnit.Year: return 12;
      default: return 1;
    }
  }

  private getPrice(): number {
    const { addons, currentSubscription } = this.props;

    const addonsPrice = addons
      .filter(x => currentSubscription.selectedAddonIds.includes(x.id))
      .reduce(((acc, addon) => acc + addon.price), 0);
    const monthCount = this.getMonthCount(currentSubscription.billingPeriod);
    return Math.round((currentSubscription.planVariant.price + addonsPrice) / monthCount) / 100;
  }
}

const stateToProps = (state: State): StateProps => {
  return {
    rates: state.account.currencyRates,
  };
};

export const SubscriptionPrice = connect(stateToProps)(SubscriptionPriceComponent);
