import { useCallback } from 'react';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { CreateGeometrytAnaliticsGroups } from '../../utils/drawings-analytics-utils';


export function useSendCreateMeasure():
  (
    groupedMeasures: Record<string, CreateGeometrytAnaliticsGroups[]>,
    mode: string,
    aditionalParams?: Record<string, any>,
  ) => void {
  const { sendEvent } = useAnalytics();
  return useCallback((groupedMeasures, mode, aditionalParams: Record<string, any> = {}) => {
    for (const [type, groups] of Object.entries(groupedMeasures)) {
      for (const group of groups) {
        sendEvent(MetricNames.measurements.createMeasurements,
          {
            ...group,
            type,
            mode,
            ...aditionalParams,
          });
      }
    }
  }, [sendEvent]);
}
