import { DemoProjectType } from 'common/constants/project-type';
import { RequestStatus } from 'common/enums/request-status';
import { DialogTypes } from '../../constants';
import { ProjectReduxState } from './interfaces/project-redux-state';

export const PROJECT_INITIAL_STATE: ProjectReduxState = {
  projectHeadersByCompanyId: {},
  demoProjects: {
    [DemoProjectType.Project2d]: [],
    [DemoProjectType.PiaDb]: [],
  },
  status: RequestStatus.Loaded,
  currentProject: null,
  bidPricing: {
    calculationId: 0,
    description: '',
    endDate: null,
    startDate: null,
    id: 0,
    isPrivate: true,
  },
  bidPricingDialogType: DialogTypes.CREATE,
  scenarioStates: {},
  scenarioStatesRequestStatus: RequestStatus.NotRequested,
  revisionStateRequestStatus: RequestStatus.NotRequested,
  revisionsInfoRequestStatus: RequestStatus.NotRequested,
  revisionStates: {},
  projectsEngineState: {},
  requests: {
    projectHeadersByCompanyId: {},
    demoProjects: {
      [DemoProjectType.Project2d]: RequestStatus.NotRequested,
      [DemoProjectType.PiaDb]: RequestStatus.NotRequested,
    },
    applyDemoProject: RequestStatus.NotRequested,
  },
  foldersLoadingStatus: RequestStatus.NotRequested,
  folders: [],
  currentFolder: null,
  removingFolderInfo: [],
  templates: [],
  projectInvitedUsers: [],
  isProjectNotFound: false,
  projectsToDelete: {},
};
