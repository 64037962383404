import React, { useMemo } from 'react';

import { ConfirmationDialog } from '../../../../../components/dialog/confirmation-dialog';
import { DialogText } from '../../dialog-text';
import { Option } from './option';
import { Styled } from './styled';

export const CONFIRM_DELETE_SUBSCRIPTION_TRIAL_DIALOG = 'CONFIRM_DELETE_SUBSCRIPTION_TRIAL_DIALOG';
const TITLE = 'Don\'t miss your chance for more opportunities!';
const CANCEL_SUBSCRIPTION_WARNING = `Your trial period is almost over,
  but we have special offers to help you learn more about all the benefits of our product`;

interface Props {
  onCancelSubscriptionClick?: () => void;
  handleExtendTrial: () => void;
  canExtendTrial: boolean;
  handleBookDemo: () => void;
  handleWriteUs: () => void;
}

interface OptionParam {
  name: string;
  description: string;
  icon: string;
  buttonText: string;
  handleClick: () => void;
  targetRef?: string;
}

const getOptionList = (
  handleExtendTrial: () => void,
  canExtendTrial: boolean,
  handleBookDemo: () => void,
  handleWriteUs: () => void,
): OptionParam[]  => [
  {
    name: 'Extend Trial by 7 Days',
    description: 'Still undecided? Enjoy 7 more days of free access to evaluate our product for your business',
    icon: 'extend-trial',
    buttonText: 'Get +7 Days',
    handleClick: canExtendTrial && handleExtendTrial,
  },
  {
    name: 'Contact Us',
    description: `Any queries or feedback? Reach out to us for assistance with your product inquiries`,
    icon: 'contact-us',
    buttonText: 'Contact Support',
    handleClick: handleWriteUs,
  },
  {
    name: 'Book a Demo',
    description: `Curious about our product? Schedule a free demo to discover its key features`,
    icon: 'book-demo',
    buttonText: 'Book a Demo',
    targetRef: 'https://www.kreo.net/book-a-demo',
    handleClick: handleBookDemo,
  },
];

export const CancelSubscriptionTrialDialog: React.FC<Props> = ({
  onCancelSubscriptionClick,
  handleExtendTrial,
  canExtendTrial,
  handleBookDemo,
  handleWriteUs,
}) => {
  const optionList = useMemo(() => getOptionList(handleExtendTrial, canExtendTrial, handleBookDemo, handleWriteUs), [
    canExtendTrial,
    handleBookDemo,
    handleExtendTrial,
    handleWriteUs,
  ]);

  return (
    <ConfirmationDialog
      name={CONFIRM_DELETE_SUBSCRIPTION_TRIAL_DIALOG}
      title={TITLE}
      text={''}
      confirmButtonText="Cancel Subscription"
      onConfirm={onCancelSubscriptionClick}
      ExtraStyled={Styled.Container}
      className={'cancel-subscription-trial-dialog'}
    >
      <>
        <DialogText
          text={CANCEL_SUBSCRIPTION_WARNING}
        />
        {optionList.map(o => {
          return (
            <Option
              key={o.name}
              name={o.name}
              description={o.description}
              icon={o.icon}
              buttonText={o.buttonText}
              targetRef={o.targetRef}
              handleClick={o.handleClick}
            />
          );
        })}
      </>
    </ConfirmationDialog>
  );
};
