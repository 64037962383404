import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';

export const useProjectIdSelector = (): number => {
  return useSelector<State, number>((s) => {
    const project = s.projects.currentProject;
    const projectId = project && project.id;

    return projectId;
  });
};
