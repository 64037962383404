import React, { useMemo } from 'react';

import { DrawingsControls } from 'common/components/drawings/drawings-controls';
import { SpliterPaneConfig, SplitterLayout } from 'components/splitter-layout';
import { usePersistedStoragePaneSize } from 'persisted-storage/hooks';
import { TwoDInfoPanel } from 'unit-2d-info-panel/2d-info-panel';
import { FailedUpdateReportDialog } from '../failed-update-report-dialog';


export const ManagePaneSize = 'ManagePaneSize';
export const DefaultSizeManagePane = 320;

interface Props {
  paneConfigs: SpliterPaneConfig[];
  isFullScreenDrawings: boolean;
  showCollapseExpandButton: boolean;
  saveSplitterSize: (size: number) => void;
  toggleFullScreenDrawings: () => void;
  renderDrawingsLayout: () => JSX.Element;
  renderReportPanel: () => JSX.Element;
}


export const PageLayout: React.FC<Props> = ({
  paneConfigs,
  isFullScreenDrawings,
  showCollapseExpandButton,
  saveSplitterSize,
  toggleFullScreenDrawings,
  renderDrawingsLayout,
  renderReportPanel,
}) => {
  const [paneSize, saveSize] = usePersistedStoragePaneSize(ManagePaneSize, DefaultSizeManagePane);
  const pageConfig = useMemo(() => {
    return [
      {
        minSize: 300,
        size: window.innerWidth,
      },
      {
        minSize: 280,
        maxSize: 480,
        size: paneSize,
      },
    ];
  }, [window.innerWidth, paneSize]);

  return (
    <>
      <DrawingsControls/>
      <SplitterLayout
        horizontal={false}
        showCollapseExpandButton={false}
        paneConfigs={pageConfig}
        onSecondaryPaneSizeChange={saveSize}
      >
        <SplitterLayout
          horizontal={true}
          paneConfigs={paneConfigs}
          onSecondaryPaneSizeChange={
            !isFullScreenDrawings && saveSplitterSize
          }
          isCollapseOpen={isFullScreenDrawings}
          showCollapseExpandButton={showCollapseExpandButton}
          onClickCollapseExpandButton={toggleFullScreenDrawings}
        >
          {renderDrawingsLayout()}
          {renderReportPanel()}
        </SplitterLayout>
        <TwoDInfoPanel/>
      </SplitterLayout>
      <FailedUpdateReportDialog />
    </>
  );
};

