import autobind from 'autobind-decorator';
import * as React from 'react';
import { Route } from 'react-router';

import './page-layout-content.scss';

import { MetaTagUtils } from 'common/utils/meta-tag-utils';

interface Props {
  backgroundColor: string;
  exact: boolean;
  path: string;
  metaTitle: string;
}

export class HeadlessPageLayout extends React.Component<Props> {
  private pageLayoutStyles: React.CSSProperties = null;

  constructor(props: Props) {
    super(props);

    this.pageLayoutStyles = {
      backgroundColor: props.backgroundColor,
    };
  }

  public componentDidMount(): void {
    MetaTagUtils.setTitle(this.props.metaTitle);
  }

  public render(): React.ReactNode {
    return (
      <Route
        render={this.renderContent}
        exact={this.props.exact}
        path={this.props.path}
      />
    );
  }

  @autobind
  private renderContent(): React.ReactNode {
    return (
      <div
        style={this.pageLayoutStyles}
        className='page-layout headless'
      >
        {this.props.children}
      </div>
    );
  }
}
