import * as React from 'react';

export const KreoIconOrtho = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <polyline
      points='5 5 5 19 19 19'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <polyline
      points='11 19 11 13 5 13'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
