import { arrayUtils } from 'common/utils/array-utils';
import { delay } from 'common/utils/delay';
import { MagicSearchSaveResultPayload } from '../../actions/payloads/magic-search';
import { WizzardStatus } from '../../enums/dropper-state';
import { MagicSearchUtils } from '../../helpers/geometry/magic-search';
import { ShortPointDescription } from '../../interfaces';
import { MagicSearchPayload } from '../../interfaces/api-payloads';
import { MagicSearchResponse } from '../../interfaces/api-responses/magic-search-responses';
import { MagicSearchApi } from '../magic-search-api';
import { BaseStreamRunConfig, Stream } from '../streams';
import { StreamStatuses } from '../streams/stream-statuses';

export interface MagicSearchStreamRunConfig
  extends BaseStreamRunConfig<MagicSearchPayload, MagicSearchSaveResultPayload> {
  sourceContours: ShortPointDescription[][];
  snappingConfidence: number;
}

export class MagicSearchStream
  extends Stream<MagicSearchPayload, MagicSearchSaveResultPayload, MagicSearchStreamRunConfig> {
  public override async cancel(): Promise<void> {
    super.cancel();
    if (this._config) {
      await MagicSearchApi.cancelStream(this._config.fileId, this._config.drawingId, this._jobId);
    }
  }

  protected runRequest(config: MagicSearchStreamRunConfig): Promise<string> {
    return MagicSearchApi.run(config.fileId, config.drawingId, config.request);
  }

  protected async streamRequest(config: MagicSearchStreamRunConfig): Promise<void> {
    await super.streamRequest(config);
    const {
      fileId,
      drawingId,
      request,
      onProgress,
      sourceContours,
      snappingConfidence,
    } = config;
    onProgress({
      value: {
        contours: [],
        geometries: [],
      },
      status: WizzardStatus.Loading,
    });
    let status = StreamStatuses.IN_PROGRESS;
    let contours = [];
    let geometries = [];
    while (status === StreamStatuses.IN_PROGRESS && !this._cancelled) {
      const result: MagicSearchResponse = await MagicSearchApi.stream(
        fileId,
        drawingId,
        this._jobId,
        snappingConfidence,
      );
      status = result.status as StreamStatuses;
      if (status === StreamStatuses.IN_QUEUE) {
        status = StreamStatuses.IN_PROGRESS;
        await delay(1000);
        continue;
      }
      for (const stream of result.stream) {
        if (stream.output.type === 'error') {
          onProgress({
            value: null,
            status: WizzardStatus.Error,
          });
          status = StreamStatuses.FAILED;
          console.error(stream.output.error, config.request);
        } else {
          if (stream.output.type === 'snapshot') {
            contours = [];
            geometries = [];
          }
          const filteredContours = await MagicSearchUtils.filterContoursBySource(
            stream.output.contours,
            sourceContours,
            request.cropBox as [number, number, number, number],
            contour => contour.points,
          );
          arrayUtils.extendArray(contours, filteredContours);
          if (stream.geometries) {
            const filteredGeometries = await MagicSearchUtils.filterContoursBySource(
              stream.geometries,
              sourceContours,
              request.cropBox as [number, number, number, number],
              geometry => geometry.value.points,
            );
            arrayUtils.extendArray(geometries, filteredGeometries);
          }
        }
      }
      if (!this._cancelled) {
        onProgress({
          value: {
            contours,
            geometries,
          },
          status: status === StreamStatuses.IN_PROGRESS ? WizzardStatus.Loading : WizzardStatus.Preview,
        });
      }
    }
  }
}
