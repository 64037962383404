import styled from 'styled-components';

import { AccordionStyled } from 'common/components/accordion-element';

export const SETTING_HEIGHT = 24;

const Container = styled.div`
  height: 100%;
  overflow: auto;

  ${AccordionStyled.DescriptionTextContainer} {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: ${p => p.theme.color.gray} !important;
  }
  ::-webkit-scrollbar-track {
    background: ${p => p.theme.color.background};
  }
`;

const UnitsSwitcherContainer = styled.div`
  margin: 5px 0 10px;
`;

export const Styled = {
  Container,
  UnitsSwitcherContainer,
};
