import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { LinkComponent } from 'common/components/link-component';
import { RenderIf } from 'common/components/render-if';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { DrawingsActions } from '../../actions/creators/common';
import { FinishDrawApi } from '../../drawings-geometry';
import { WizzardStatus } from '../../enums/dropper-state';
import { AiButtonWithStatus } from '../ai-button-with-status';
import { WizzardMainContainer } from '../wizzard-tools';

const Messages = {
  [WizzardStatus.Error]: 'Invalid geometry',
};

export const NewInstancesFinish: React.FC = () => {
  const finishApi = useSelector<State, FinishDrawApi>(state => state.drawings.finishApi);
  const setFinishApi = useActionDispatch(DrawingsActions.setGeometryFinishApi);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
  }, [finishApi]);

  const create = useCallback(() => {
    if (finishApi.finish()) {
      setFinishApi(null);
    } else {
      setIsError(true);
    }
  }, [ finishApi, setFinishApi]);

  const reset = useCallback(() => {
    finishApi.clear();
    setFinishApi(null);
  }, [ setFinishApi, finishApi]);

  const finishWithAi = useCallback(() => {
    finishApi.finishWithAi();
    setFinishApi(null);
  }, [ finishApi, setFinishApi]);

  if (!finishApi) {
    return null;
  }

  return (
    <WizzardMainContainer>
      <LinkComponent
        text='Reset'
        icon={Icons.UndoClassic}
        onClick={reset}
        fontColor='mainFont'
        size='m'
      />
      <RenderIf condition={!!finishApi.finishWithAi}>
        <LinkComponent
          text='Finish With AI'
          icon={Icons.PlusBig}
          onClick={finishWithAi}
          fontColor='mainFont'
          size='m'
        />
      </RenderIf>
      <AiButtonWithStatus
        status={isError ? WizzardStatus.Error : WizzardStatus.Start}
        icon={Icons.PlusBig}
        onClick={create}
        text='Create'
        disabled={!finishApi.finish}
        textOverrides={Messages}
      />
    </WizzardMainContainer>
  );
};
