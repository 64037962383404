import * as t from 'io-ts';
import { ActivityAssignmentSubVariantMaterialC } from './activity-assignmnet-subvariant-material';

export const ActivityAssignmentMaterialVariantC = t.exact(
  t.intersection([
    t.type({
      rootMaterialId: t.number,
      selectedMaterialId: t.number,
      subVariants: t.array(ActivityAssignmentSubVariantMaterialC),
    }),
    t.partial({
    }),
  ]),
  'ActivityAssignmentSubVariantMaterial');

export type ActivityAssignmentMaterialVariant = t.TypeOf<typeof ActivityAssignmentMaterialVariantC>;
