import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { SetRevisionStatePayload, UpdateRevisionPayload } from 'unit-cost-estimate/actions/payloads';
import { RevisionsActionTypes } from 'unit-cost-estimate/actions/types';
import { RevisionInfoModel } from 'unit-cost-estimate/interfaces';
import { ProjectReduxState } from 'unit-projects/interfaces/project-redux-state';


export const RevisionsReducerMethods: ReducerMethods<ProjectReduxState> = {
  [RevisionsActionTypes.FETCH_PROJECT_REVISIONS_INFO]: (state) => {
    const helper = new MonoliteHelper(state);
    if (state.currentProject) {
      helper.set(_ => _.currentProject.revisions, null);
    }
    return helper
      .set(_ => _.revisionsInfoRequestStatus, RequestStatus.Loading)
      .get();
  },
  [RevisionsActionTypes.SET_PROJECT_REVISIONS_INFO]: (state, payload: RevisionInfoModel[]) => {
    if (!state.currentProject) {
      return state;
    }

    return new MonoliteHelper(state)
      .set(_ => _.currentProject.revisions, payload)
      .set(_ => _.revisionsInfoRequestStatus, RequestStatus.Loaded)
      .get();
  },
  [RevisionsActionTypes.DELETE_REVISION]: (state, revisionId: number) => {
    return new MonoliteHelper(state)
      .setFilter(_ => _.currentProject.revisions, x => x.id !== revisionId)
      .removeKey(_ => _.revisionStates, revisionId)
      .get();
  },
  [RevisionsActionTypes.UPDATE_REVISION]: (state, payload: UpdateRevisionPayload) => {
    const revisions = state.currentProject && state.currentProject.revisions;
    const revisionIndex = revisions && revisions.findIndex(x => x.id === payload.revisionId);
    if (revisionIndex === null || revisionIndex === undefined) {
      return state;
    }

    return new MonoliteHelper(state)
      .set(_ => _.currentProject.revisions[revisionIndex].name, payload.form.name)
      .get();
  },
  [RevisionsActionTypes.FETCH_REVISION_STATE]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.revisionStateRequestStatus, RequestStatus.Loading)
      .get();
  },
  [RevisionsActionTypes.SET_REVISION_STATE]: (state, payload: SetRevisionStatePayload) => {
    return new MonoliteHelper(state)
      .set(_ => _.revisionStates[payload.revisionId], payload.revisionState)
      .set(_ => _.revisionStateRequestStatus, RequestStatus.Loaded)
      .get();
  },
};
