import { CEMeasurementsState } from 'unit-cost-estimate/interfaces';
import { MeasurementsBimElementInfo } from 'unit-projects/interfaces/measurements/measurements-bim-element-info';

export const CE_MEASUREMENT_INITIAL_STATE: CEMeasurementsState = {
  centralEditorRowPosition: null,
  centralTreeElement: null,
  elementSelectStatuses: {},
  statistic: [],
  activityMeasurement: [],
  searchedNodes: [],
  searchMode: false,
  dataLoaded: false,
  badIds: [],
  engineIds: new Array<MeasurementsBimElementInfo>(),
  viewTree: true,
  extractorFunctions: {},
  changedValues: [],
  rootIds: [],
  extractorEditorRows: [],
  showMeasurementEditor: false,
  modelBrowser: [],
  layerIds: [],
  extractors: {},
  selectedNodeId: null,
  errorLinks: [],
  manualChangedLinks: [],
  hasChanges: false,
  statisticLoaded: false,
  generalExtractorEditors: {},
  isGeneralSelected: false,
  uncommittedChanges: {},
  cachedMeasurementsValues: [],
  cachedErrorLinks: [],
  areLevelsShown: false,
};
