import { InputSelectStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const Container = styled.div`
  > div {
    width: 165px;
    height: 30px;
    border-radius: 10px;
    padding-left: 15px;
    background: ${p => p.theme.color.backgroundRush};
    border: 1px solid ${p => p.theme.color.background};
  }

  ${InputSelectStyled.DropDownMenuContainer} {
    z-index: 100;
    min-width: 165px;
    height: auto;
    > div {
      :first-child {
        div {
          border-bottom: 1px solid ${p => p.theme.color.background};
        }
      }
    }
  }

  ${InputSelectStyled.DropDownItem} {
    height: 25px;
    width: 165px;

    > p {
      font-size: 14px;
      line-height: 21px;
    }
  }

  ${InputSelectStyled.TextsContainer} {
    > p {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const Styled = {
  Container,
};
