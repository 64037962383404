import { ModelBrowserPropertyLine, ModelBrowserPropertyLineLayer } from '../../interfaces/properties-provider-types';

function lineIsLayer(
  item: ModelBrowserPropertyLine | ModelBrowserPropertyLineLayer,
): item is ModelBrowserPropertyLineLayer {
  return (item as ModelBrowserPropertyLineLayer).layerFunction !== undefined;
}

export const PropertiesUtils = {
  lineIsLayer,
};
