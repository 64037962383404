import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './collapse-tab.scss';

import { KreoIconSelectArrow } from 'common/UIKit';

interface Props {
  tabName: string;
  isOpenTab: boolean;
  index: number;
  onClickCollapseButton: (index: number) => void;
  className?: string;
  tabElement?: JSX.Element;
}

export class CollapseTab extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <>
        <div className='collapse-tab'>
          <div
            className={classNames(
              'collapse-tab__name',
              this.props.className,
              { 'collapse-tab__name--collapse': !this.props.isOpenTab },
            )}
            onClick={this.onClickCollapseButton}
          >
            <KreoIconSelectArrow />
            {this.props.tabName}
          </div>
          <div
            className={classNames(
              'collapse-tab__action-control',
              { 'collapse-tab__action-control--collapse': !this.props.isOpenTab },
            )}
          >
            {this.props.tabElement}
          </div>
        </div>
        <div
          className={
            classNames(
              'collapse-tab__content-container',
              { 'collapse-tab__content-container--collapse': !this.props.isOpenTab },
            )
          }
        >
          {this.props.children}
        </div>
      </>
    );
  }

  @autobind
  private onClickCollapseButton(): void {
    this.props.onClickCollapseButton(this.props.index);
  }
}
