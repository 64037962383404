import { CancelableAction, CancelableResult } from 'common/components/drawings/interfaces/util';
import { UuidUtil } from 'common/utils/uuid-utils';
import { CanvasHelper } from './canvas-helper';
import { CompareConstants } from './constants';
import {
  calculateImageDataForViewport,
  constructRenderSettingsForDocuments,
} from './pdf-tron-utils';
import { Util } from './util';

export function getDocumentCanvas(
  loadCanvasFunction: (options: Core.LoadCanvasAsyncOptions) => string | number,
  pageRotation: number,
  zoomLevel: number,
  viewportRect: Core.ViewportRect,
  cancelLoadCanvas: (id: string | number) => void,
): CancelableAction<HTMLCanvasElement> {
  let id;
  let canceled = false;
  const promise = new Promise<CancelableResult<HTMLCanvasElement>>((resolve, reject) => {
    try {
      id = loadCanvasFunction({
        pageNumber: 1,
        pageRotation,
        zoom: zoomLevel,
        renderRect: viewportRect,
        drawComplete: async (pageCanvas) => {
          if (!canceled) {
            resolve({
              data: pageCanvas,
              isFinished: true,
            });
          }
        },
      });
    } catch (e) {
      console.error(e);
      reject(`Error loading canvas for page to compare`);
    }
  });
  return {
    promise,
    cancel() {
      cancelLoadCanvas(id);
      canceled = true;
    },
  };
}


function cleanUpParamsFromLoadCanvasAsync(loadAsyncParams: Core.LoadCanvasAsyncOptions): Core.LoadCanvasAsyncOptions {
  return {
    ...loadAsyncParams,
    pageRotation: 0,
  };
}


interface LoadCanvasDocumentParams {
  loadCanvasFunction: (options: Core.LoadCanvasAsyncOptions) => string | number;
  document: Core.Document;
  options: Core.LoadCanvasAsyncOptions;
  cancel: (id: string | number) => void;
}

function loadCanvasesToRender(documents: LoadCanvasDocumentParams[]): Array<CancelableAction<HTMLCanvasElement>> {
  return documents.map(({ options, cancel, loadCanvasFunction }) => {
    return getDocumentCanvas(loadCanvasFunction, options.pageRotation, options.zoom, options.renderRect, cancel);
  });
}

export function loadCanvas(options: Core.LoadCanvasAsyncOptions): string {
  if (!this.isComparisonEnabled) {
    return this.originalLoadCanvas(options);
  }
  if (options.renderRect) {
    return;
  }
  const idToReturn = UuidUtil.generateUuid();
  const cleanedUpParams = cleanUpParamsFromLoadCanvasAsync(options);
  const renderSettings = constructRenderSettingsForDocuments(
    this,
    CompareConstants.DEFAULT_ZOOM,
    0,
    cleanedUpParams.renderRect,
    this.compareTransform,
    this.documentToCompareWith,
  );
  const canvasesToRender = loadCanvasesToRender([
    {
      document: this,
      options: renderSettings[0],
      cancel: this.originalCancelLoadCanvas,
      loadCanvasFunction: this.originalLoadCanvas,
    },
    {
      document: this.documentToCompareWith,
      options: renderSettings[1],
      cancel: this.documentToCompareWith.cancelLoadCanvas.bind(this.documentToCompareWith),
      loadCanvasFunction: this.documentToCompareWith.loadCanvas.bind(this.documentToCompareWith),
    },
  ]);
  this.loadAsyncCallsToCancel[idToReturn] = canvasesToRender;
  Promise
    .all(canvasesToRender.map((renderAction) => renderAction.promise.then(x => x.data)))
    .then(async (canvasesToCompare) => {
      const transformationMatriciesToApply = renderSettings.map(x => x.transformationMatrixToApply);
      const canvasesImageData = calculateImageDataForViewport(
        canvasesToCompare,
        transformationMatriciesToApply,
        canvasesToCompare[0],
      );

      const canvasMultiplierForApplingViewerRotation = 1;
      const canvasesImageDataWithViewerRotation = canvasesImageData.map((imageData) => {
        const canvas = Util.createCanvas(imageData.width, imageData.height, canvasMultiplierForApplingViewerRotation);
        canvas.getContext('2d').putImageData(imageData, 0, 0);
        const matrix = Core.getPageMatrix(
          1,
          0,
          { width: canvas.width, height: canvas.height },
          0,
          true,
          1,
        );
        const pageMatrix = {
          a: matrix.m_a,
          b: matrix.m_b,
          c: matrix.m_c,
          d: matrix.m_d,
          e: matrix.m_h,
          f: matrix.m_v,
        };
        const { width, height } = canvas;
        const canvasWithViewerRotationApplied = Util.createCanvas(
          width,
          height,
          canvasMultiplierForApplingViewerRotation,
        );
        const ctx = canvasWithViewerRotationApplied.getContext('2d');
        ctx.setTransform(pageMatrix.a, pageMatrix.b, pageMatrix.c, pageMatrix.d, pageMatrix.e, pageMatrix.f);
        ctx.drawImage(canvas, 0, 0);
        return ctx.getImageData(0, 0, canvasWithViewerRotationApplied.width, canvasWithViewerRotationApplied.height);
      });
      let canvasToDisplay = CanvasHelper.combinePixels(
        canvasesImageDataWithViewerRotation[0],
        canvasesImageDataWithViewerRotation[1],
        this.opacities[0],
        this.opacities[1],
        1,
        true,
      );
      if (options.pageRotation !== 0) {
        const matrix = Core.getPageMatrix(
          1,
          options.pageRotation,
          { width: canvasToDisplay.width, height: canvasToDisplay.height },
          0,
          true,
          1,
        );
        const pageMatrix = {
          a: matrix.m_a,
          b: matrix.m_b,
          c: matrix.m_c,
          d: matrix.m_d,
          e: matrix.m_h,
          f: matrix.m_v,
        };
        let width = 0;
        let height = 0;
        if (options.pageRotation === 1 || options.pageRotation === 3) {
          width = canvasToDisplay.height;
          height = canvasToDisplay.width;
        } else {
          width = canvasToDisplay.width;
          height = canvasToDisplay.height;
        }
        const canvasWithViewerRotationApplied = Util.createCanvas(
          width,
          height,
          canvasMultiplierForApplingViewerRotation,
        );
        const ctx = canvasWithViewerRotationApplied.getContext('2d');
        ctx.setTransform(pageMatrix.a, pageMatrix.b, pageMatrix.c, pageMatrix.d, pageMatrix.e, pageMatrix.f);
        ctx.drawImage(canvasToDisplay, 0, 0);
        canvasToDisplay = canvasWithViewerRotationApplied;
      }
      canvasToDisplay.style.width = `${canvasToDisplay.width / 2}px`;
      canvasToDisplay.style.height = `${canvasToDisplay.height / 2}px`;
      this.renderedCanvas = canvasToDisplay;
      options.drawComplete(canvasToDisplay, 1);
    });

  this.originalLoadCanvas(cleanedUpParams);
  return idToReturn;
}
