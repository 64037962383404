import autobind from 'autobind-decorator';
import * as monolite from 'monolite';
import * as React from 'react';

import { mathUtils } from 'common/utils/math-utils';
import {
  TimeframeHandle,
} from '../../four-d-visualisation/components/new-gantt-chart/components/project-timeline/components/timeframe-handle';
import { ganttChartContants } from '../utils/constants';
import { realOffsetCalculate } from '../utils/real-offset-calculate';

interface Props {
  timeframeStartDay: number;
  timeframeDuration: number;
  timelineStartDay: number;
  timelineDuration: number;
  horizontalMargin: number;
  minimalFrameDuration: number;
  handleWidth: number;
  pixelsPerDay: number;
  updateTimeframe: (daysTillStart: number, endDay: number) => void;
  setCursorHandleStatus: (handled: boolean) => void;
}


interface State {
  left: number;
  width: number;
  isDragged: boolean;
  dragDelta: number;
  pointerOffsetPercentage: number;
  pointerStyle: React.CSSProperties;
}

export class Timeframe extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const derivedState = Timeframe.getDerivedStateFromProps(props, null);

    this.state = {
      isDragged: false,
      dragDelta: null,
      pointerOffsetPercentage: null,
      left: derivedState.left,
      width: derivedState.width,
      pointerStyle: derivedState.pointerStyle,
    };
  }

  public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> {
    let newState: Partial<State> | null = null;
    const left = nextProps.timeframeStartDay * nextProps.pixelsPerDay +
      ganttChartContants.leftPanelWidth - ganttChartContants.timeframeHandlerWidth * 2;
    if (!prevState || left !== prevState.left) {
      newState = monolite.set(newState || {}, (s) => s.left)(left);
    }

    const width = nextProps.timeframeDuration * nextProps.pixelsPerDay + ganttChartContants.timeframeHandlerWidth * 4;
    if (!prevState || width !== prevState.width) {
      newState = monolite.set(newState || {}, (s) => s.width)(width);
    }
    return newState;
  }

  public render(): React.ReactNode {
    return (
      <div
        className='timeframe'
        style={{
          width: this.state.width,
          transform: `translateX(${this.state.left}px)`,
        }}
      >
        <TimeframeHandle
          offset={this.state.left}
          className='left'
          key='left-handle'
          onDrag={this.onLeftDrag}
          setCursorOnHandleStatus={this.props.setCursorHandleStatus}
          onDragEnd={this.dragEnd}
          onDragStart={this.dragStart}
        />
        <div className='border left' key='left-border' />
        <div className='border right' key='right-border' />
        <TimeframeHandle
          offset={this.state.left + this.state.width}
          className='right'
          key='right-handle'
          onDrag={this.onRightDrag}
          setCursorOnHandleStatus={this.props.setCursorHandleStatus}
          onDragEnd={this.dragEnd}
          onDragStart={this.dragStart}
        />
      </div>
    );
  }

  @autobind
  private onRightDrag(newRightBorderOffset: number): void {
    const realOffset = realOffsetCalculate(newRightBorderOffset - ganttChartContants.timeframeHandlerWidth * 2);

    const newDaysTillFrameEnd = mathUtils.clamp(
      this.props.timelineStartDay + realOffset / this.props.pixelsPerDay,
      this.props.timeframeStartDay + this.props.minimalFrameDuration,
      this.props.timelineStartDay + this.props.timelineDuration);

    const newDuration = newDaysTillFrameEnd - this.props.timeframeStartDay;

    this.props.updateTimeframe(this.props.timeframeStartDay, newDuration);
  }

  @autobind
  private onLeftDrag(newLeftBorderOffset: number): void {
    const realOffset = realOffsetCalculate(newLeftBorderOffset + ganttChartContants.timeframeHandlerWidth * 2);

    const daysTillFrameEnd = this.props.timeframeStartDay + this.props.timeframeDuration;

    const newDaysTillFrameStart = mathUtils.clamp(
      this.props.timelineStartDay + realOffset / this.props.pixelsPerDay,
      this.props.timelineStartDay,
      daysTillFrameEnd - this.props.minimalFrameDuration);

    const newDuration = daysTillFrameEnd - newDaysTillFrameStart;
    this.props.updateTimeframe(newDaysTillFrameStart, newDuration);
  }

  @autobind
  private dragStart(): void {
    this.props.setCursorHandleStatus(true);
  }

  @autobind
  private dragEnd(): void {
    this.props.setCursorHandleStatus(false);
  }
}
