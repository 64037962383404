import React, { useEffect, useState } from 'react';

import { GroupFolders } from '../folder-container';
import { TargetFolder } from '../target-folder';
import { Styled } from './styled';
import { createPath } from './utils';


interface Props {
  targetId: string;
  groups: GroupFolders[];
  onClickFolder: (folderId: string) => void;
}

function TargetPathComponent({ targetId, groups, onClickFolder }: Props): JSX.Element {
  const [path, setPath] = useState<GroupFolders[]>([]);

  useEffect(
    () => {
      setPath(createPath(groups, targetId));
    },
    [targetId, groups],
  );

  return (
    <Styled.Container>
      {
        path.map((x, index) => (
          <Styled.FolderContainer
            key={x.id}
            nestedLevel={index}
          >
            <TargetFolder
              title={x.name}
              folderId={x.id}
              onClick={onClickFolder}
            />
          </Styled.FolderContainer>
        ))
      }
    </Styled.Container>
  );
}

export const TargetPath = React.memo(TargetPathComponent);
