const COST = 'Cost';
const TOTAL_COST = `total${COST}`;
const DIRECT_COST = `direct${COST}`;
const EXTRA_PAYMENT = 'extraPaymentsPercentage';
const RATE_TEMPLATE = 'CostPerOffSiUnit'
const LABOR_RATE = `labor${RATE_TEMPLATE}`;
const MATERIAL_RATE = `material${RATE_TEMPLATE}`;
const PLANT_RATE = `plant${RATE_TEMPLATE}`;
const DIRECT_RATE = `direct${RATE_TEMPLATE}`
const TOTAL_RATE = `total${RATE_TEMPLATE}`
const QUANTITY = 'quantity';
const LON = 'levelOfNesting';
const OVERHEAD_PERCENTAGE = 'overheadPercentage';

const LABOR_COST = `labor${COST}`;
const PLANT_COST = `plant${COST}`;
const MATERIAL_COST = `material${COST}`;

const DURATION = 'duration';

export {
  TOTAL_COST,
  DIRECT_COST,
  EXTRA_PAYMENT,
  LABOR_RATE,
  MATERIAL_RATE,
  PLANT_RATE,
  QUANTITY,
  LON,
  DIRECT_RATE,
  LABOR_COST,
  PLANT_COST,
  MATERIAL_COST,
  TOTAL_RATE,
  OVERHEAD_PERCENTAGE,
  DURATION,
}