import React from 'react';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { SectionMenuItems } from 'common/components/section-menu-items/section-menu-items';
import { InstancesAggregation } from './instances-selection';
import { Styled } from './styled';
import { useDefaultViewSection } from './use-default-view-section';

export const DefaultView: React.FC = () => {
  const sections = useDefaultViewSection();
  return (
    <>
      {sections.map((section) => {
        if (!section.show) {
          return null;
        }

        return (
          <Styled.SectionMenuItemsWrapper
            key={section.title}
            data-control-name={section.dataControlName}
          >
            <UpgradeWrapper isNeedUpdate={section?.isNeedUpdate}>
              <SectionMenuItems
                title={section.title}
                manageIcon={section.ManageIcon}
                infoIcon={section.infoIcon}
                badge={section.badge}
                onSelectionMenuClick={section.onSelectionMenuClick}
                onIconClick={section.onIconClick}
                disabled={section?.isNeedUpdate || section?.disabled}
              >
                {section.children ? section.children() : null}
              </SectionMenuItems>
            </UpgradeWrapper>
          </Styled.SectionMenuItemsWrapper>
        );
      })}
      <InstancesAggregation />
    </>
  );
};
