import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { RenderIf } from 'common/components/render-if';
import { getDataUrlFromArr } from './get-data-url-from-arr';
import { Styled } from './styled';

interface Props {
  similarity: number;
  centerX: number;
  centerY: number;
  zoom: number;
  browserScale: number;
  context: CanvasRenderingContext2D;
  showSimilarity: boolean;
  visible: boolean;
  showSelectedMark?: boolean;
  onFocus: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}


const PreviewItemComponent: React.FC<Props> = ({
  similarity,
  zoom,
  browserScale,
  context,
  showSimilarity,
  visible,
  onClick,
  onFocus,
  centerX,
  centerY,
  showSelectedMark = true,
}) => {
  const y = centerY * zoom - Styled.PREVIEW_SIZE / 2;
  const x = centerX * zoom - Styled.PREVIEW_SIZE / 2;
  const imageData = context.getImageData(
    x * browserScale,
    y * browserScale,
    Styled.PREVIEW_SIZE * browserScale,
    Styled.PREVIEW_SIZE * browserScale,
  );
  return (
    <Styled.Container visible={visible} onClick={onClick}>
      <img src={getDataUrlFromArr(imageData.data, imageData.width, imageData.height)} />
      <Styled.FocusButton onClick={onFocus}>
        <Icons.Focus/>
      </Styled.FocusButton>
      <RenderIf condition={showSelectedMark}>
        <Styled.SelectedMark>
          <Icons.DoneSmall />
        </Styled.SelectedMark>
      </RenderIf>
      <RenderIf condition={showSimilarity}>
        <Styled.Similarity>
          {Math.floor(similarity * 100)}%
        </Styled.Similarity>
      </RenderIf>
    </Styled.Container>
  );
};


export const PreviewItem = React.memo(PreviewItemComponent);
