import { useMemo } from 'react';
import { ButtonInput, InputSelect, NumberInput, TextInput } from 'common/components/inputs';
import { useButtonFunctionState } from './use-button-function-state';
import { useNumberState } from './use-number-state';
import { useSelectState } from './use-select-state';
import { useTextState } from './use-text-state';

export enum InputType {
  button,
  text,
  number,
  select,
}

export type InputProps = { type: InputType } & any;

export const useInputOverrideMemo = (inputElement: React.FC, input: any): InputProps => {
  const textInputState = useTextState();
  const numberInputState = useNumberState();
  const selectInputState = useSelectState();
  const buttonFunctionState = useButtonFunctionState(input);
  const states = [textInputState, numberInputState, selectInputState, buttonFunctionState];
  return useMemo(
    () => {
      if (inputElement === ButtonInput) {
        return {
          ...input,
          ...buttonFunctionState,
          type: InputType.button,
        };
      }
      if (inputElement === TextInput) {
        return {
          ...input,
          ...textInputState,
          type: InputType.text,
        };
      }
      if (inputElement === NumberInput) {
        return {
          ...input,
          ...numberInputState,
          type: InputType.number,
        };
      }
      if (inputElement === InputSelect) {
        return {
          ...input,
          ...selectInputState,
          isShowArrow: true,
          type: InputType.select,
        };
      }
    },
    [input, ...states],
  );
};

