import {
  IconButton,
  Icons,
} from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { usePersistedStorageValueToggle } from 'persisted-storage/hooks';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { useGeometryOperationContext, useRendererApiContext } from '../../drawings-contexts';
import { DrawingsDrawMode } from '../../enums';
import { useDrawModeApi } from '../../hooks';
import { DrawingsFlyingMenuContainer } from '../drawings-flying-menu';
import { Styled } from './styled';

interface OwnProps {
  sendRef: (ref: HTMLDivElement) => void;
}

const DrawingsOffsetMenuComponent: React.FC<OwnProps> = (
  {
    sendRef,
  },
) => {

  const { offsetValue: offsetInMeters } = useGeometryOperationContext();
  const { rendererApi } = useRendererApiContext();
  const { setDrawMode } = useDrawModeApi();
  const applyOffset = useCallback(() => {
    rendererApi.engine.applyOffset(() => {
      setDrawMode(DrawingsDrawMode.Disabled, { ignoreCancelMessage: true });
    });
  }, [setDrawMode, rendererApi.engine]);

  const [ offsetIsStroke, toggleOffsetType ] = usePersistedStorageValueToggle('drawingOffsetIsStroke');
  const isImperialUnit = useSelector<State, boolean>(state => state.account.settings.isImperial);
  if (!offsetInMeters) {
    return null;
  }
  return (
    <DrawingsFlyingMenuContainer ref={sendRef}>
      <Styled.Value>
        { UnitUtil.measureToString2d(offsetInMeters, UnitTypes.M, isImperialUnit) }
      </Styled.Value>
      <Styled.StrokeToggle isActive={offsetIsStroke}>
        <IconButton
          Icon={Icons.StrokeOffset}
          height={DrawingsCanvasConstants.menuButtonSize}
          width={DrawingsCanvasConstants.menuButtonSize}
          onClick={toggleOffsetType}
        />
      </Styled.StrokeToggle>
      <IconButton
        Icon={Icons.Apply}
        height={DrawingsCanvasConstants.menuButtonSize}
        width={DrawingsCanvasConstants.menuButtonSize}
        onClick={applyOffset}
      />
    </DrawingsFlyingMenuContainer>
  );
};


export const DrawingsOffsetMenu = React.memo(DrawingsOffsetMenuComponent);
