import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { DrawingDialogs } from '../../constants/drawing-dialogs';

interface Props {
  duplicate: (duplicateMeasurements: boolean) => void;
}

const DupliсationPageDialogComponent: React.FC<Props> = ({ duplicate }) => {
  const confirmCallback = useCallback(() => duplicate(true), [duplicate]);
  const cancelCallback = useCallback(() => duplicate(false), [duplicate]);

  return (
    <ConfirmationDialog
      name={DrawingDialogs.DUPLICATE_DRAWING_DIALOG}
      title='Duplicate the measurements created in this drawing?'
      text={'If you want the measurements to be duplicated, click "Yes", if you do not need them, click "No"'}
      confirmButtonText='Yes'
      cancelButtonMood={null}
      onConfirm={confirmCallback}
      cancelButtonText='No'
      submitButtonMood='positive'
      onCancel={cancelCallback}
      zIndex={1003}
    />
  );
};

function mapStateToProps(state: State): Props {
  return {
    duplicate: state.dialog[DrawingDialogs.DUPLICATE_DRAWING_DIALOG],
  };
}

export const DuplicationPageDialog = connect(mapStateToProps)(DupliсationPageDialogComponent);
