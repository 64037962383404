import { Constants } from '../constants';
import { AssignNameInfo } from '../interfaces';

const getRowId = (...value: string[]): string => value.join(Constants.ROW_ID_SEPARATOR);

export const getRowsIds = (drawingElementId: string, assign: AssignNameInfo): string[] | null => {
  if (!assign) {
    return null;
  }

  const rowIds = [];

  for (const itemName of assign.items) {
    const rowId = getRowId(drawingElementId, itemName, '');
    rowIds.push(rowId);
  }

  for (const assemblyName of Object.keys(assign.assembly)) {
    for (const itemName of assign.assembly[assemblyName]) {
      const rowId = getRowId(drawingElementId, itemName, assemblyName);
      rowIds.push(rowId);
    }
  }

  if (!rowIds.length) {
    return null;
  }

  return rowIds;
};
