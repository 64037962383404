import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-revit-tree-search-input.scss';

import { State } from 'common/interfaces/state';
import { IconButton, KreoIconCancel, MaterialInput } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { ClassificationActions } from '../../actions/creators/classification';

interface StateComponent {
  searchQuery: string;
}

interface StateProps {
  enabled: boolean;
  value: string;
}

interface DispatchProps {
  onClose: () => void;
  onChange: (value: string) => void;
  selectAll: () => void;
}

interface Props extends StateProps, DispatchProps {

}

class ClassificationRevitTreeSearchInputComponent extends React.PureComponent<Props, StateComponent> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchQuery: '',
    };
  }

  public render(): React.ReactNode {
    if (!this.props.enabled) {
      return null;
    }
    return (
      <div className='classification-revit-tree-search-input'>
        <div className='classification-revit-tree-search-input__field'>
          <MaterialInput
            autoFocus={true}
            showClearButton={true}
            searchType={true}
            placeholder='Search'
            displayedType={MaterialComponentType.Native}
            onChange={this.onSearchChanged}
            value={this.state.searchQuery}
          />
        </div>
        <div
          className='classification-revit-tree-search-input__select-all'
          data-control-name='select-all-element-types'
          onClick={this.props.selectAll}
        >
          Select All Element Types
        </div>
        <IconButton
          size='medium'
          className='classification-revit-tree-search-input__close-button'
          onClick={this.props.onClose}
        >
          <KreoIconCancel/>
        </IconButton>
      </div>
    );
  }

  @autobind
  private onSearchChanged(event: React.ChangeEvent, value: string): void {
    event.preventDefault();
    this.props.onChange(value);
    this.setState({ searchQuery: value });
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    enabled: state.classification.isSearchModeEnabled,
    value: state.classification.modelBrowserFilters.searchQuery,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onClose: () => dispatch(ClassificationActions.toggleSearchMode()),
    onChange: value => dispatch(ClassificationActions.changeSearchQuery(value)),
    selectAll: () => dispatch(ClassificationActions.onSelectAll(true)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationRevitTreeSearchInput = connector(ClassificationRevitTreeSearchInputComponent);
