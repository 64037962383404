import { CodecUtils } from 'common/utils/codec-utils';

export enum CustomElementFilterStringOperation {
  Equals = 'Equals',
  Contains = 'Contains',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
}

export const CustomElementFilterStringOperationC = CodecUtils.createEnumCodec<CustomElementFilterStringOperation>(
  CustomElementFilterStringOperation,
  'CustomElementFilterStringOperation',
);
