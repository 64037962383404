import { reloadWrapper } from '../reload-wrapper';
import { ServiceWorkerActions } from './service-worker-messaging';

export class ServiceWorkerConnector {
  private static _initialized = false;
  private static _registration: ServiceWorkerRegistration;

  private static _messages: any[] = [];

  public static async init(): Promise<void> {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(
          '/service-worker.js',
          {
            scope: '/',
          },
        );
        this._registration = registration;
        this._initialized = true;
        if (registration.waiting && navigator.serviceWorker.controller) {
          registration.waiting.postMessage(ServiceWorkerActions.skipWaiting());
        }

        let refreshing = false;
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (!refreshing) {
            reloadWrapper('service worker initialization');
            refreshing = true;
          }
        });

        navigator.serviceWorker.ready.then((reg) => {
          this._registration = reg;
          this._messages.forEach((message) => this.sendMessage(message));
          this._messages = [];
        });

      } catch (error) {
        console.error(`Registration failed with ${error}`);
      }
    }
  }
  public static sendMessage(message: any): void {
    if (ServiceWorkerConnector._initialized) {
      ServiceWorkerConnector._registration.active.postMessage(message);
    } else {
      ServiceWorkerConnector._messages.push(message);
    }
  }
}
