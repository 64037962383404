import { ActionWith } from '../../../common/interfaces/action-with';
import * as actionTypes from './action-types';

/**
 *
 * open dialog by name
 *
 * @param name - name of dialog
 */
const openDialog = (name: string, data?: any): ActionWith<{ name: string, data: any }> => {
  return {
    type: actionTypes.OPEN_DIALOG,
    payload: { name, data },
  };
};


/**
 * close dialog by name
 *
 * @param name -name of dialog
 */
const closeDialog = (name: string): ActionWith<string> => {
  return {
    type: actionTypes.CLOSE_DIALOG,
    payload: name,
  };
};


/**
 * close dialog by name
 *
 * @param name -name of dialog
 */
const updateDialogData = (name: string, data?: any): ActionWith<{ name: string, data: any }> => {
  return {
    type: actionTypes.UPDATE_DIALOG_DATA,
    payload: { name, data },
  };
};


export const KreoDialogActions = {
  openDialog,
  closeDialog,
  updateDialogData,
};
