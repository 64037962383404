import * as React from 'react';
import { Responsive } from 'react-grid-layout';
import { WidthProvider } from './width-porovider';

import './card-container.scss';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface Props {
  children: any;
}

const BREAKPOINTS = { lg: 1200, md: 996, sm: 600, xs: 180, xxs: 0 };
const COLS = { lg: 4, md: 4, sm: 2, xs: 1, xxs: 1 };
const MARGIN: [number, number] = [30, 30];
const PADDING: [number, number] = [0, 0];

export class CardContainer extends React.Component<Props> {
  public render(): JSX.Element {
    const maxX = 4;
    let lastX = -1;
    let lastY = 0;
    let lastW = 1;
    return (
      <div className='card-container'>
        <ResponsiveReactGridLayout
          className='card-container__grid-layout'
          breakpoints={BREAKPOINTS}
          cols={COLS}
          autoSize={true}
          rowHeight={400}
          verticalCompact={true}
          useCSSTransforms={true}
          isDraggable={false}
          margin={MARGIN}
          containerPadding={PADDING}
        >
          {this.props.children.map((card, index) => {
            lastX = lastX + lastW;
            if (lastX >= maxX) {
              lastX = 0;
              lastY++;
            }

            lastW = card.props.width || 1;
            const h = card.props.height || 1;
            const dataGrid = { w: lastW, h, x: lastX, y: lastY };

            return (
              <div key={index} data-grid={dataGrid}>
                {card}
              </div>
            );
          })}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}

