import React from 'react';

import { useInputHandler } from './hooks';
import { SearchItemsWithDropdownList } from './search-items-with-dropdown-list';

interface CommonProps {
  elements: string[];
  activeElementIndex: number;
  onClick: (index: number) => void;
  isCollaps?: boolean;
  withSearch?: boolean;
  inputId?: string;
  isShowArrow?: boolean;
  searchType?: 'Number' | 'Text';
}

export interface WithCreateNew extends CommonProps {
  createNewElement: true;
  onBlur: (element: string) => void;
  value: string | undefined;
  createEmptyElement?: boolean;
}

export interface SingleSelectWithSearch extends CommonProps {
  createNewElement: false;
  onBlur?: undefined;
  value?: undefined;
  createEmptyElement?: undefined;
}

type Props = WithCreateNew | SingleSelectWithSearch;

export const InputSelect: React.FC<Props> = ({
  elements,
  activeElementIndex,
  value,
  isCollaps,
  inputId,
  createNewElement,
  isShowArrow,
  searchType = 'Text',
  onClick,
  onBlur,
  createEmptyElement,
}) => {
  const handleSelect = React.useCallback((element: string) => {
    const elementIndex = elements.indexOf(element);
    if (elementIndex === -1) {
      if (createNewElement) {
        onBlur(element);
      }
      return;
    }
    onClick(elementIndex);
  }, [onClick, elements, onBlur, createNewElement]);

  const {
    isDropdownOpen,
    openDropdown,
    closeDropdown,
    onClickDropDownTextItem,
  } = useInputHandler(handleSelect, searchType === 'Number');
  const activeElement = elements[activeElementIndex];

  const activeElementValue = value === undefined || value === null
    ? activeElement
    : value;

  return (
    <SearchItemsWithDropdownList
      isDropdownOpen={isDropdownOpen}
      openDropdown={openDropdown}
      closeDropdown={closeDropdown}
      activeElement={activeElementValue}
      elements={elements}
      onClickDropDownTextItem={onClickDropDownTextItem}

      searchType={searchType}
      createNewElement={createNewElement}
      withSearch={true}
      isCollaps={isCollaps}
      isShowArrow={isShowArrow}

      inputId={inputId}

      createEmptyElement={createEmptyElement}
    />
  );
};
