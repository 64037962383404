export class DrawingsOrthogonalModeStatusController {
  private _settingsEnabled: boolean;
  private _hotkeyEnabled: boolean;

  constructor(backendEnabled: boolean) {
    this._settingsEnabled = backendEnabled;
  }

  public get enabled(): boolean {
    return this._settingsEnabled || this._hotkeyEnabled;
  }

  public set settingsEnabled(value: boolean) {
    this._settingsEnabled = value;
  }

  public set hotkeyEnabled(value: boolean) {
    this._hotkeyEnabled = value;
  }
}
