import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'common/enums/request-status';
import { ActionWith } from 'common/interfaces/action-with';
import {
  CreateProjectReportTemplate,
  ReportTemplateInfo,
  ReportTemplates,
  TwoDReportTemplateState,
  UpdateReportTemplate,
  UpdateReportVariables,
} from '../interfaces';

export const TWO_D_REPORT_TEMPLATE_INITIAL_STATE: TwoDReportTemplateState = {
  infoTemplates: [],
  projectReportTemplates: {
    templates: [],
    variables: [],
  },
  requestStatus: RequestStatus.NotRequested,
};

const TwoDReportTemplate = createSlice({
  name: '@twoDReportTemplate',
  initialState: TWO_D_REPORT_TEMPLATE_INITIAL_STATE,
  reducers: {
    createTeplate: (state) => {
      state.requestStatus = RequestStatus.Loading;
    },
    createTemplateSucced: (state) => {
      state.requestStatus = RequestStatus.Loaded;
    },
    addeTempalte: (state, { payload }: ActionWith<ReportTemplateInfo>) => {
      state.infoTemplates.push(payload);
    },
    setTemplates: (state, { payload }: ActionWith<ReportTemplateInfo[]>) => {
      state.infoTemplates = payload;
    },
    loadInfoReportTemplates: (state) => {
      state.requestStatus = RequestStatus.Loading;
    },
    deleteReportTemplate: (state, { payload }: ActionWith<number>) => {
      state.requestStatus = RequestStatus.Loading;
      state.infoTemplates = state.infoTemplates.filter(t => t.id !== payload);
    },
    updateReportTemplate: (state, { payload }: ActionWith<UpdateReportTemplate>) => {
      state.requestStatus = RequestStatus.Loading;
      const reportTemplate = state.infoTemplates.find(t => t.id === payload.id);
      reportTemplate.name = payload.name;
    },
    loadProjectReportTemplate: (state, _: ActionWith<number>) => {
      state.requestStatus = RequestStatus.Loading;
    },
    setProjectReportTemplates: (state, { payload }: ActionWith<ReportTemplates>) => {
      state.projectReportTemplates = payload;
    },
    applyProjectReportTemplate: (state, _: ActionWith<CreateProjectReportTemplate>) => {
      state.requestStatus = RequestStatus.Loading;
    },
    addProjectReportTemplate: (state, { payload }: ActionWith<ReportTemplateInfo>) => {
      state.projectReportTemplates.templates.push(payload);
    },
    deleteProjectReport: (state, { payload }: ActionWith<CreateProjectReportTemplate>) => {
      state.requestStatus = RequestStatus.Loading;
      state.projectReportTemplates.templates =
        state.projectReportTemplates.templates.filter(
          t => t.id !== payload.form.companyTemplateId,
        );
    },
    exportTemplate: (state, _: ActionWith<CreateProjectReportTemplate>) => {
      state.requestStatus = RequestStatus.Loading;
    },
    updateReportVariables: (state, { payload }: ActionWith<UpdateReportVariables>) => {
      state.requestStatus = RequestStatus.Loading;
      payload.form.forEach(p => {
        const variable = state.projectReportTemplates.variables
          .find(v => v.name === p.name);
        variable.value = p.value;
      });
    },
    clearProjectState: (state) => {
      state.projectReportTemplates = TWO_D_REPORT_TEMPLATE_INITIAL_STATE.projectReportTemplates;
    },
  },
});

export const TwoDReportTemplateActions = TwoDReportTemplate.actions;
export const twoDReportTemplateReducer = TwoDReportTemplate.reducer;
