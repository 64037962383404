import { FormulaInputValidationStatus } from '../enums';
import { FormulaInputValidationResult } from '../interfaces';


function ok(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Ok,
  };
}

function emptyExpression(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: 'Empty expression',
  };
}

function cannotFindClosingBracer(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: `Cannot find closing symbol ')'`,
  };
}

function cannotFindTextCloser(closingSymbol: string): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: `Cannot Find Text Closing Symbol '${closingSymbol}'`,
  };
}

function invalidNumberFormat(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: 'Invalid Number format',
  };
}

function divisionByZero(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: 'Division by zero',
  };
}

function operationWontWork(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: `Operation won't work`,
  };
}

function unexpectedToken(text: string): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: `Unexpected token "${text}"`,
  };
}

function parameterIsNotClosed(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: `Cannot find parameter closing bracer ']'`,
  };
}

function unexpectedParameterSymbols(symbols: string[]): FormulaInputValidationResult {
  const symbolsStr = symbols.join('\', \'');
  return {
    status: FormulaInputValidationStatus.Error,
    text: `Unexpected symbol${symbols.length > 1 ? 's' : ''} '${symbolsStr}' in property name`,
  };
}

function emptyPropertyName(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: 'Empty property name is forbidden',
  };
}

function propertyIsNotDefined(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Warning,
    text: 'Property is not defined',
  };
}

function functionArgumentCount(expected: number, passed: number): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Warning,
    text: `Expected ${expected} arguments, but got ${passed}`,
  };
}

function functionEmptyArgument(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: `Empty function argument`,
  };
}

function functionDoesntExist(name: string): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Warning,
    text: `Function "${name}" doesn't exist`,
  };
}

function cannotFinishWithOperation(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: 'Formula cannot finish with operation symbol',
  };
}

function operationIsNotDefined(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: 'Operation is not defined',
  };
}

function unexpectedSymbolsBetweenBracers(): FormulaInputValidationResult {
  return {
    status: FormulaInputValidationStatus.Error,
    text: 'Unexpected symbols between bracers "(" and ")"',
  };
}

export const FormulaValidationStatusBuilder = {
  ok,
  emptyExpression,
  cannotFindClosingBracer,
  cannotFindTextCloser,
  invalidNumberFormat,
  divisionByZero,
  operationWontWork,
  unexpectedToken,
  parameterIsNotClosed,
  unexpectedParameterSymbols,
  emptyPropertyName,
  propertyIsNotDefined,
  functionArgumentCount,
  functionEmptyArgument,
  functionDoesntExist,
  cannotFinishWithOperation,
  operationIsNotDefined,
  unexpectedSymbolsBetweenBracers,
};
