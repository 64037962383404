import * as React from 'react';

import './filter-header.scss';

import { FilterType } from '../../filter-type';
import { DateFilter } from '../date-filter';
import { FilterSelect } from '../filter-select';

interface Props {
  showProjectInfo: boolean;
}

export const FilterHeader: React.FC<Props> = (props: Props) => {
  return (
    <div className='activity-filter-header'>
      <div className='activity-filter-header__first-column'>
        <DateFilter />
      </div>
      {props.showProjectInfo && (
        <div className='activity-filter-header__column'>
          <FilterSelect type={FilterType.Project} />
        </div>
      )}
      <div className='activity-filter-header__column'>
        <FilterSelect type={FilterType.People} />
      </div>
      <div className='activity-filter-header__column'>
        <FilterSelect type={FilterType.Role} />
      </div>
      <div className='activity-filter-header__column'>
        <FilterSelect type={FilterType.Type} />
      </div>
    </div>
  );
};
