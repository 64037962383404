import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';


import { RowData, UserInfo } from 'common/components/data-base-table';
import { DataBaseSelectorTable } from 'common/components/database-selector-table';
import { State } from 'common/interfaces/state';
import { mapItems, mapUser } from '../../../../helpers';
import { Group, Item, User } from '../../../../interfaces';
import { SelectPropertyPanel } from '../select-property-panel';

interface StateToProps {
  items: Item[];
  groups: Group[];
  users: User[];
}

interface Props extends StateToProps {
  setUsedItems: (ids: string[]) => void;
  selectedIdsMap: Record<string, Record<string, boolean>> | Record<string, boolean>;
  selectItemId?: string;
  setUsedProperties?: (ids: string[]) => void;
  hideEmptyEntity?: boolean;
  autoGroupColumnMinWidth?: number;
  hideTable?: boolean;
}

interface OwnState {
  rowData: RowData[];
}

class SelectItemPanelComponent extends React.PureComponent<Props, OwnState> {
  private userInfo: UserInfo[] = [];
  public constructor(props: Props) {
    super(props);
    this.state = {
      rowData: [],
    };
  }

  public componentDidMount(): void {
    this.updateRowData();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.users !== prevProps.users) {
      this.updateUsersInfo();
    }

    if (prevProps.items !== this.props.items || this.props.users !== prevProps.users) {
      this.updateRowData();
    }
  }

  public render(): JSX.Element {
    const { selectItemId, selectedIdsMap, autoGroupColumnMinWidth, hideTable } = this.props;
    return !selectItemId
      ? (<DataBaseSelectorTable
        rowData={this.state.rowData}
        autoGroupName={'Items'}
        onSelect={this.onSelect}
        selectedEntityMap={selectedIdsMap}
        fieldForQuickSearch={[]}
        autoGroupColumnMinWidth={autoGroupColumnMinWidth}
        hideTable={hideTable}
      />)
      : (
        <SelectPropertyPanel
          selectedIdsMap={selectedIdsMap[selectItemId] as  Record<string, boolean>}
          onSelectProperties={this.onSelectProperties}
          showCollapseButton={true}
          autoGroupColumnMinWidth={autoGroupColumnMinWidth}
          hideTable={hideTable}
        />
      );
  }

  @autobind
  private updateUsersInfo(): void {
    this.userInfo = this.props.users.map(mapUser);
  }

  private updateRowData(): void {
    const { groups } = this.props;
    const items = this.getItems();
    const rowData = mapItems(items, groups, this.userInfo);
    this.setState({ rowData });
  }

  private getItems(): Item[] {
    const { items, hideEmptyEntity } = this.props;

    return hideEmptyEntity
      ? items.filter(i => i.properties.length)
      : items;
  }

  @autobind
  private onSelect(ids: string[]): void {
    this.props.setUsedItems(ids);
  }

  @autobind
  private onSelectProperties(ids: string[]): void {
    this.props.setUsedProperties(ids);
  }
}

const mapStateToProps = (state: State): StateToProps => {
  return {
    items: state.twoDDatabase.items,
    groups: state.twoDDatabase.itemsGroups,
    users: state.people.companiesUsers as User[],
  };
};

export const SelectItemPanel = connect(mapStateToProps)(SelectItemPanelComponent);
