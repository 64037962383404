import * as React from 'react';

import { TextSearchResult } from 'common/components/drawings/interfaces';
import { PageInfo } from './page-info';
import { TextSearchResultItem } from './text-search-result-item';

interface Props {
  query: string;
  caseSensitive: boolean;
  results: Record<string, TextSearchResult[]>;
  onFocus: (id: number, drawingId: string) => void;
}

export class TextSearchResultBody extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return this.renderItems();
  }

  private renderItems(): React.ReactNode {
    const results = [];
    for (const [drawingId, searchResults] of Object.entries(this.props.results)) {
      results.push(
        <PageInfo key={drawingId} drawingId={drawingId} />,
      );
      for (const { id, ambient, output } of searchResults) {
        results.push(
          <TextSearchResultItem
            key={id}
            id={id}
            drawingId={drawingId}
            focus={this.props.onFocus}
            ambientText={ambient}
            searchText={output || this.props.query}
            caseSensitive={this.props.caseSensitive}
          />,
        );
      }
    }
    return results;
  }
}

