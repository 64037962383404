import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { DrawingsUndoRedoHelper } from 'common/components/drawings/utils/drawings-undo-redo-helper';
import { State } from 'common/interfaces/state';
import { UndoRedoAdd, UndoRedoContextApiProps, withUndoRedoApiProps } from 'common/undo-redo';
import { arrayUtils } from 'common/utils/array-utils';
import { DrawingsDeletePage, DrawingsDeletePageConfirmationDialog, DuplicationPageDialog } from '../../../dialogs';
import { DrawingsFile, DrawingsPage } from '../../../interfaces/drawings-file-info';
import { DrawingsPdfBrowserFile } from './drawings-pdf-browser-file';
import { FullscreenButton } from './fullscreen-button';
import { DrawingsPdfBrowserPagesHeader } from './header';
import { Styled } from './styled';

interface OwnProps {
  selectedPages: string[];
  canEditMeasurement: boolean;
  multiSelect: boolean;
  selectAllText?: string;
  deselectAllText?: string;
  saveUndoRedoAdd?: (undoRedoAdd: UndoRedoAdd) => void;
  onToggleFullScreen: () => void;
  isFullScreen: boolean;
  sortedFiles: DrawingsFile[];
  onChangeDrawingSelection: (id: string[], select: boolean, openLast: boolean) => void;
  onParameterUpdate: (drawingId: string, parameter: string, value: string | number) => void;
  scrollRef: { scrollTo: (id: string) => void };
  copyDrawingGeometriesToPage: (pdfId: string, drawingId: string, targetDrawingId: string) => void;
  onDeletePage: (drawingId: string, pdfId: string) => void;
}

interface StateProps {
  drawingInfo: Record<string, DrawingsShortInfo>;
}

interface ComponentState {
  open: boolean;
  pageNames: string[];
  fileList: DrawingsFile[];
}

interface Props extends OwnProps, StateProps, UndoRedoContextApiProps {}

class DrawingsPdfBrowserPagesComponent extends React.PureComponent<Props, ComponentState> {
  private filesContainerRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      pageNames: this.getPageNames(),
      fileList: props.sortedFiles,
    };
    props.scrollRef.scrollTo = this.scrollTo;
  }

  public render(): React.ReactNode {
    return (
      <Styled.Container className='drawings-pdf-browser-pages'>
        <Styled.ContainerFullScreen>
          <FullscreenButton
            active={this.props.isFullScreen}
            toggle={this.props.onToggleFullScreen}
          />
        </Styled.ContainerFullScreen>
        {this.state.fileList?.length
          ? this.renderContent()
          : <Styled.Placeholder>Select the relevant file or folder to display the pages available</Styled.Placeholder>
        }
      </Styled.Container>
    );
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.drawingInfo !== this.props.drawingInfo) {
      this.setState({ pageNames: this.getPageNames() });
    }
    if (prevProps.sortedFiles !== this.props.sortedFiles) {
      this.setState({ fileList: this.props.sortedFiles });
    }
  }

  private renderContent(): React.ReactNode {
    const { multiSelect } = this.props;
    const { fileList } = this.state;

    return (
      <>
        {this.renderHeader(multiSelect)}
        <Styled.FilesContainer ref={this.filesContainerRef}>
          {fileList.map(this.renderFile)}
        </Styled.FilesContainer>
        <DuplicationPageDialog/>
        <DrawingsDeletePageConfirmationDialog onSubmit={this.deletePage} />
      </>
    );
  }

  @autobind
  private renderHeader(show: boolean): React.ReactNode {
    if (!show) {
      return;
    }
    const { selectedPages, selectAllText, deselectAllText } = this.props;
    const pages = this.getPdfPages();
    const pagesIdsSet = new Set(pages.map(page => page.drawingId));
    const selectedPagesCount = selectedPages.filter(id => pagesIdsSet.has(id)).length;
    return (
      <DrawingsPdfBrowserPagesHeader
        pagesCount={pages.length}
        selectedPagesCount={selectedPagesCount}
        onDeselectAll={this.deselectAll}
        onSelectAll={this.selectAll}
        selectAllText={selectAllText}
        deselectAllText={deselectAllText}
      />
    );
  }

  @autobind
  private renderFile(file: DrawingsFile): React.ReactNode {
    return (
      <DrawingsPdfBrowserFile
        file={file}
        key={file.id}
        multiselect={this.props.multiSelect}
        canEdit={this.props.canEditMeasurement}
        onChangeDrawingSelection={this.props.onChangeDrawingSelection}
        selectedPages={this.props.selectedPages}
        pageNames={this.state.pageNames}
        onChangePageSelection={this.onChangePageSelection}
        onParameterUpdate={this.props.onParameterUpdate}
        copyDrawingGeometriesToPage={this.props.copyDrawingGeometriesToPage}
      />
    );
  }

  private getPageNames(): string[] {
    return Object.values(this.props.drawingInfo).map(({ name }) => name);
  }

  @autobind
  private scrollTo(id: string): void {
    const element = document.getElementById(id);
    if (!element || !this.props.sortedFiles.length) {
      return;
    }

    const topPosition = element.offsetTop;
    this.filesContainerRef.current.scrollTo({ top: topPosition - 50, behavior: 'smooth' });
  }

  @autobind
  private onChangePageSelection(id: string, selected: boolean): void {
    this.props.onChangeDrawingSelection([id], selected, true);
    const { undo, redo } = DrawingsUndoRedoHelper.getSelectDeselectPagesUndoRedo(
      [id],
      selected,
      true,
      this.props.onChangeDrawingSelection,
    );
    this.props.addUndoRedo(undo, redo);
  }

  @autobind
  private deletePage(data: DrawingsDeletePage): void {
    this.props.onDeletePage(data.pageId, data.pdfId);
    this.props.cleanUndoRedo();
  }

  @autobind
  private selectAll(): void {
    const selectedPageIdsMap = new Set(this.props.selectedPages);
    const unselectedIds = arrayUtils.filterMap(
      this.getPdfPages(),
      page => !selectedPageIdsMap.has(page.drawingId),
      page => page.drawingId,
    );
    const { undo, redo } = DrawingsUndoRedoHelper.getSelectDeselectPagesUndoRedo(
      unselectedIds,
      true,
      false,
      this.props.onChangeDrawingSelection,
    );
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private getPdfPages(): DrawingsPage[] {
    return arrayUtils.flatArray(arrayUtils.mapIterator(this.props.sortedFiles, file => file.pages));
  }

  @autobind
  private deselectAll(): void {
    const { selectedPages } = this.props;
    const selectedPageIdsSet = new Set(selectedPages);
    const filteredPageIds = this.getPdfPages()
      .filter(page => selectedPageIdsSet.has(page.drawingId))
      .map(page => page.drawingId);
    const { undo, redo } = DrawingsUndoRedoHelper.getSelectDeselectPagesUndoRedo(
      filteredPageIds,
      false,
      false,
      this.props.onChangeDrawingSelection,
    );
    this.props.addUndoRedo(undo, redo);
    redo();
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    drawingInfo: state.drawings.drawingsInfo,
  };
}

export const DrawingsPdfBrowserPages = connect(
  mapStateToProps,
)(withUndoRedoApiProps(DrawingsPdfBrowserPagesComponent));
