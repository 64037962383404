import { Action, AnyAction } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { ActivityVariantActivityAssignmentInfoModel } from '../../../../units/databases/interfaces/data';
import {
  ActivityAssignmentAgregatedBimInfo,
} from '../../interfaces/activity-assignment/activity-assignment-agregated-bim-info';
import {
  ActivityAssignmentAgregatedData,
} from '../../interfaces/activity-assignment/activity-assignment-agregated-data';
import { ActivityAssignmentActivity } from '../../interfaces/activity-assignment/activity-assignmnet-activity';
import {
  ActivityAssignmentSelectMaterialPayload,
  ActivityAssignmentSetModalityPayload,
  PathAndEndElementTitlePayload,
} from '../payloads/activity-assignment';
import { ActivityAssignmentActionTypes } from '../types/activity-assignment';

const loadData = (): Action => {
  return {
    type: ActivityAssignmentActionTypes.LOAD_DATA,
  };
};

const loadDataSucceeded = (data: ActivityAssignmentAgregatedData): ActionWith<ActivityAssignmentAgregatedData> => {
  return {
    type: ActivityAssignmentActionTypes.LOAD_DATA_SUCCEEDED,
    payload: data,
  };
};

function loadDataFailed(): Action {
  return {
    type: ActivityAssignmentActionTypes.LOAD_DATA_FAILED,
  };
}

const loadWork = (workId: number): ActionWith<number> => {
  return {
    type: ActivityAssignmentActionTypes.LOAD_WORK,
    payload: workId,
  };
};

const loadWorkSucceeded = (work: ActivityAssignmentActivity): ActionWith<ActivityAssignmentActivity> => {
  return {
    type: ActivityAssignmentActionTypes.LOAD_WORK_SUCCEEDED,
    payload: work,
  };
};

const removeWork = (index: number): ActionWith<number> => {
  return {
    type: ActivityAssignmentActionTypes.REMOVE_WORK,
    payload: index,
  };
};


const expandItem = (path: string): ActionWith<string> => {
  return {
    type: ActivityAssignmentActionTypes.EXPAND_ITEM,
    payload: path,
  };
};


const showWorksFromType = (path: string): ActionWith<string> => {
  return {
    type: ActivityAssignmentActionTypes.SHOW_WORK_FROM_TYPE,
    payload: path,
  };
};


const showWorksFromLayer = (
  data: PathAndEndElementTitlePayload,
): ActionWith<PathAndEndElementTitlePayload> => {
  return {
    type: ActivityAssignmentActionTypes.SHOW_WORK_FROM_LAYER,
    payload: data,
  };
};


const runSearch = (search: string): ActionWith<string> => {
  return {
    type: ActivityAssignmentActionTypes.START_SEARCH,
    payload: search,
  };
};


const checkActivity = (index: number): ActionWith<number> => {
  return {
    type: ActivityAssignmentActionTypes.CHECK_WORK,
    payload: index,
  };
};

const assignActivity = (): AnyAction => {
  return {
    type: ActivityAssignmentActionTypes.ASSIGN_WORKS,
  };
};

const assignActivitySucceeded = (work: ActivityAssignmentActivity): ActionWith<ActivityAssignmentActivity> => {
  return {
    type: ActivityAssignmentActionTypes.ASSIGN_WORKS_SUCCEEDED,
    payload: work,
  };
};


const dropState = (): AnyAction => {
  return {
    type: ActivityAssignmentActionTypes.DROP_STATE,
  };
};

const saveRevitData = (data: ActivityAssignmentAgregatedBimInfo): ActionWith<ActivityAssignmentAgregatedBimInfo> => {
  return {
    type: ActivityAssignmentActionTypes.SAVE_REVIT_DATA,
    payload: data,
  };
};


const getRevitData = (): AnyAction => {
  return {
    type: ActivityAssignmentActionTypes.GET_REVIT_DATA,
  };
};

const getRevitDataByProjectId = (
  projectId: number,
): ActionWith<number> => {
  return {
    type: ActivityAssignmentActionTypes.GET_REVIT_DATA_BY_PROJECT_ID,
    payload: projectId,
  };
};


const setModality = (data: ActivityAssignmentSetModalityPayload): ActionWith<ActivityAssignmentSetModalityPayload> => {
  return {
    type: ActivityAssignmentActionTypes.SET_MODALITY,
    payload: data,
  };
};


const selectFromEngine = (
  bimHandleId: number,
): ActionWith<number> => {
  return {
    type: ActivityAssignmentActionTypes.SELECT_FROM_ENGINE,
    payload: bimHandleId,
  };
};


const setSaveData = (isSave: boolean): ActionWith<boolean> => {
  return {
    type: ActivityAssignmentActionTypes.SET_SAVE_DATA,
    payload: isSave,
  };
};


const openDataBasePage = (
  projectId: number,
): ActionWith<number> => {
  return {
    type: ActivityAssignmentActionTypes.OPEN_DATABASE_PAGE,
    payload: projectId,
  };
};


const assignModalities = (
  work: ActivityAssignmentActivity,
): ActionWith<ActivityAssignmentActivity> => {
  return {
    type: ActivityAssignmentActionTypes.ASSIGN_MODALITIES,
    payload: work,
  };
};

const createActivitiesCodeGroup = (
  variants: ActivityVariantActivityAssignmentInfoModel[],
): ActionWith<ActivityVariantActivityAssignmentInfoModel[]> => {
  return {
    type: ActivityAssignmentActionTypes.CREATE_ACTIVITIES_CODE_GROUP,
    payload: variants,
  };
};

const updateWork = (
  work: ActivityAssignmentActivity,
): ActionWith<ActivityAssignmentActivity> => {
  return {
    type: ActivityAssignmentActionTypes.UPDATE_WORK,
    payload: work,
  };
};

const showHideTree = (): Action => {
  return {
    type: ActivityAssignmentActionTypes.SHOW_HIDE_TREE,
  };
};

const selectWorkById = (
  workId: number,
): ActionWith<number> => {
  return {
    type: ActivityAssignmentActionTypes.SELECT_WORK_BY_ID,
    payload: workId,
  };
};

const dropStatistics = (): Action => {
  return {
    type: ActivityAssignmentActionTypes.DROP_STATISTIC,
  };
};


const selectExpandableTreeNode = (path: string): ActionWith<string> => {
  return {
    type: ActivityAssignmentActionTypes.SELECT_TREE_NODE,
    payload: path,
  };
};

function selectMaterial(
  materialSelectIndex: number,
  activitySubVariantId: number,
  materialId: number,
  activityIndex: number,
): ActionWith<ActivityAssignmentSelectMaterialPayload> {
  return {
    type: ActivityAssignmentActionTypes.SELECT_MATERIAL,
    payload: { materialSelectIndex, activitySubVariantId, materialId, activityIndex },
  };
}

export const ActivityAssignmentActions = {
  loadData,
  loadDataSucceeded,
  loadDataFailed,
  loadWork,
  loadWorkSucceeded,
  removeWork,
  expandItem,
  showWorksFromType,
  showWorksFromLayer,
  runSearch,
  checkActivity,
  assignActivity,
  assignActivitySucceeded,
  dropState,
  saveRevitData,
  getRevitData,
  getRevitDataByProjectId,
  setModality,
  selectFromEngine,
  setSaveData,
  openDataBasePage,
  assignModalities,
  createActivitiesCodeGroup,
  updateWork,
  showHideTree,
  selectWorkById,
  dropStatistics,
  selectExpandableTreeNode,
  selectMaterial,
};
