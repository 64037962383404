import * as React from 'react';
import { connect } from 'react-redux';


import { State } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { ActivityAssignmentActivitiesHeader } from '../activity-assignment-activities-header';
import { ActivityAssignmentActivityListContainer } from '../activity-assignment-activity-list-container';
import {
  ActivityAssignmentActivityTileView,
} from '../activity-assignment-activity-tile-view';
import { ActivityAssignmentNoActivities } from '../activity-assignment-no-activities';
import { ValidationResizableEditor } from '../validation-resizable-editor';


interface Props {
  activitiesCount: number;
}


class ActivityAssignmentActivityLayoutViewComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <ValidationResizableEditor>
        <ActivityAssignmentActivitiesHeader />
        <ActivityAssignmentActivityListContainer>
          <KreoScrollbars>
            {this.props.activitiesCount > 0 ?
              this.getActivityTiles() : (
                <ActivityAssignmentNoActivities />
              )}
          </KreoScrollbars>
        </ActivityAssignmentActivityListContainer>
      </ValidationResizableEditor>
    );
  }


  private getActivityTiles(): React.ReactNode[] {
    const activities = new Array<React.ReactNode>();
    for (let i = 0; i < this.props.activitiesCount; i++) {
      activities[i] = (
        <ActivityAssignmentActivityTileView
          key={i}
          index={i}
        />
      );
    }
    return activities;
  }
}

function mapStateToProps({ activityAssignment }: State): Props {
  return {
    activitiesCount: activityAssignment.selectedWorks.length,
  };
}

const connector = connect(mapStateToProps);
export const ActivityAssignmentActivityLayoutView = connector(ActivityAssignmentActivityLayoutViewComponent);
