import { Icons, RoundButton } from '@kreo/kreo-ui-components';
import React from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'components/theme-provider';
import { store } from '../../../../store';

export const CellRendererPlusButton = (): JSX.Element => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <RoundButton
          Icon={Icons.PlusSmall}
          size="s"
          mood={'secondary'}
          iconHeight={10}
        />
      </ThemeProvider>
    </Provider>
  );
};

export const getPlusButtonElement = (): JSX.Element => {
  return <CellRendererPlusButton />;
};
