import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { DatabaseActionTypes } from '../actions/types/database';
import { DatabaseModel } from '../interfaces/data';
import { DatabaseReduxState } from '../interfaces/redux-state';


export const DatabaseReducerMethods: ReducerMethods<DatabaseReduxState> = {
  [DatabaseActionTypes.GET_DATABASES_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.databases, [])
      .set((_) => _.statuses.databases, RequestStatus.Loading)
      .get();
  },
  [DatabaseActionTypes.GET_DATABASES_SUCCEEDED]: (state, payload: DatabaseModel[]) => {
    return new MonoliteHelper(state)
      .set((_) => _.databases, payload)
      .set((_) => _.statuses.databases, RequestStatus.Loaded)
      .get();
  },
  [DatabaseActionTypes.UPDATE_DATABASE_SUCCEEDED]: (state, payload: DatabaseModel) => {
    return new MonoliteHelper(state)
      .set((_) => _.currentDatabase.database, payload)
      .get();
  },
  [DatabaseActionTypes.SET_CURRENT_DATABASE]: (state, payload: DatabaseModel) => {
    return new MonoliteHelper(state)
      .set((_) => _.currentDatabase.database, payload)
      .set((_) => _.statuses.databaseResources, RequestStatus.Loading)
      .get();
  },
  [DatabaseActionTypes.CREATE_DATABASE_SUCCEEDED]: (state, payload: DatabaseModel) => {
    return new MonoliteHelper(state)
      .setAppend((_) => _.databases, payload)
      .get();
  },
  [DatabaseActionTypes.DELETE_DATABASE_SUCCEEDED]: (state, payload: number) => {
    return new MonoliteHelper(state)
      .setFilter((_) => _.databases, (x) => x.id !== payload)
      .get();
  },
  [DatabaseActionTypes.SET_DEFAULT_DATABASE_SUCCEEDED]: (state, payload: number) => {
    const databases = state.databases.map(x => ({
      ...x,
      isDefault: x.id === payload,
    }));
    return new MonoliteHelper(state)
      .set((_) => _.databases, databases)
      .get();
  },
};
