import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { State as ReduxState } from 'common/interfaces/state';
import {
  MaterialInputField,
  MaterialInputProps,
  MaterialMenuItem,
  MaterialNumberInputField,
  MaterialNumberInputProps,
  MaterialSelectField,
  MaterialSelectProps,
} from 'common/UIKit';
import { FieldRequired, GreaterThan } from '../../../../components/dialog/validators';
import { UnitModel } from '../../interfaces/data';
import { MaterialModel, MaterialVariantModel } from '../../interfaces/resources-data';
import { MaterialUnitHelper } from './material-unit-helper';

const quantityGreaterThan0 = GreaterThan(0, 'Quantity');
const unitRequired = FieldRequired('Unit');

interface ReduxProps {
  unitMap: Record<number, UnitModel>;
  measurementToUnits: Record<string, UnitModel[]>;
}

interface Props extends ReduxProps, I18nAwareProps {
  readonly: boolean;
  resource: MaterialModel;
  variant: MaterialVariantModel;
  onChange: (root: MaterialVariantModel) => void;
}

export class DatabaseResourceMaterialVariantComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const materialUnitValidator = MaterialUnitHelper.getMaterialUnitInAvailableMeasurementValidator(this.props.unitMap);

    return (
      <div className='database-resource-material'>
        <div className='database-resource-material__name'>
          <Field<MaterialInputProps>
            name='name'
            component={MaterialInputField}
            label='Name'
            placeholder='Name'
            multiLine={true}
            value={this.props.variant.name}
            onChange={this.onNameChange}
            onBlur={this.onNameBlur}
            displayBottomInformation={true}
            disabled={this.props.readonly}
          />
        </div>
        <div className='database-resource-material__row'>
          <div className='database-resource-material__package'>
            <Field<MaterialNumberInputProps>
              name='amount'
              component={MaterialNumberInputField}
              label='Package'
              isFloatingLabel={false}
              value={this.props.variant.amount}
              onChange={this.onAmountChange}
              placeholder='0.00'
              precision={2}
              valueType='float'
              disabled={this.props.readonly}
              displayBottomInformation={true}
              validate={quantityGreaterThan0}
            />
          </div>
          <div className='database-resource-material__unit'>
            <Field<MaterialSelectProps>
              name='unitId'
              component={MaterialSelectField}
              value={this.props.variant.unitId}
              placeholder='Select Unit'
              label='Unit'
              onChange={this.onUnitChange}
              disabled={this.props.readonly}
              displayBottomInformation={true}
              validate={[unitRequired, materialUnitValidator]}
            >
              {this.getUnitVariants()}
            </Field>
          </div>
        </div>
        <div className='database-resource-material__cost-per-package'>
          <Field<MaterialNumberInputProps>
            name='cost'
            component={MaterialNumberInputField}
            label={`Cost Per Package (${this.props.i18n.currency.symbol})`}
            isFloatingLabel={false}
            value={this.props.variant.cost}
            onChange={this.onCostPerPackageChange}
            placeholder='0.00'
            precision={2}
            valueType='float'
            disabled={this.props.readonly}
            displayBottomInformation={true}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onNameChange(_: React.ChangeEvent, name: string): void {
    this.props.onChange({
      ...this.props.variant,
      name,
    });
  }

  @autobind
  private onNameBlur(_: React.ChangeEvent, value: string): void {
    const name = value.trim();
    this.props.onChange({
      ...this.props.variant,
      name,
    });
  }

  @autobind
  private onAmountChange(_: React.ChangeEvent, amount: number): void {
    this.props.onChange({
      ...this.props.variant,
      amount,
    });
  }

  @autobind
  private onCostPerPackageChange(_: React.ChangeEvent, cost: number): void {
    this.props.onChange({
      ...this.props.variant,
      cost,
    });
  }

  @autobind
  private onUnitChange(_: React.SyntheticEvent, unitId: number): void {
    this.props.onChange({
      ...this.props.variant,
      unitId,
    });
  }

  private getUnitVariants(): JSX.Element[] {
    const { resource, unitMap, measurementToUnits } = this.props;
    const defaultUnit = unitMap[resource.defaultUnitId];
    const resourceMeasurement = defaultUnit.measurement;
    return measurementToUnits[resourceMeasurement]
      .sort((a, b) => a.acronym.toLowerCase().localeCompare(b.acronym.toLowerCase()))
      .map(x => (<MaterialMenuItem key={x.id} value={x.id}>{x.acronym}</MaterialMenuItem>));
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => ({
  unitMap: state.database.currentDatabase.unitMap,
  measurementToUnits: state.database.currentDatabase.measurementToUnits,
});

const connector = connect(mapStateToProps);

export const DatabaseResourceMaterialVariant = connector(withI18n(DatabaseResourceMaterialVariantComponent));
