import { Popover } from '@material-ui/core';
import autobind from 'autobind-decorator';
import React from 'react';

import './database-activity-code-group-element.scss';

import { KreoIconCloseSmall } from 'common/UIKit';
import { StringUtils } from 'common/utils/string-utils';
import { TreeSelector } from '../../../../components/tree-selector/tree-selector';
import { Item } from '../../../../components/treeview/tree-view';

interface CodeGroupElementProps {
  itemId: number;
  items: Item[];
  className: string;
  onSelect: (item: Item) => void;
  isOnlyLeavesSelect: boolean;
  name: string;
  readonly: boolean;
}

interface State {
  isPopoverOpen: boolean;
}

export class CodeGroupElement extends React.Component<CodeGroupElementProps, State> {
  private rootDiv: HTMLDivElement;

  constructor(props: CodeGroupElementProps) {
    super(props);
    this.state = {
      isPopoverOpen: false,
    };
  }

  public render(): JSX.Element {
    const item = this.getItemById(this.props.items, this.props.itemId);

    return (
      <div className={`database-activity-code-group-element ${this.props.className}`}>
        <div
          className='database-activity-code-group-element__content'
          onClick={this.openPopover}
          data-control-name={StringUtils.titleCaseToSnakeCase(this.props.name)}
          ref={this.makeRef}
        >
          {
            item ? (
              <React.Fragment>
                <div className='database-activity-code-group-element__code'>{item.code}</div>
                <div className='database-activity-code-group-element__title' title={item.title}>{item.title}</div>
                {
                  !this.props.readonly ? (
                    <div className='database-activity-code-group-element__del-btn' onClick={this.onDelete}>
                      <KreoIconCloseSmall />
                    </div>
                  ) : null
                }
              </React.Fragment>
            ) : (
              <b className='database-activity-code-group-element__name'>
              {
                this.props.readonly
                  ? `${this.props.name} is not selected`
                  : `Select ${this.props.name}`
              }
              </b>
            )
          }
        </div>
        {
          !this.props.readonly ? (
            <Popover
              open={this.state.isPopoverOpen}
              anchorEl={this.rootDiv}
              onClose={this.onPopoverClose}
              PaperProps={{
                classes: { root: 'tree-selector__paper' },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <TreeSelector
                name={this.props.name}
                items={this.props.items}
                item={item}
                filter={null}
                isOnlyLeavesSelect={this.props.isOnlyLeavesSelect}
                onSelect={this.onSelect}
                onCancel={this.onPopoverClose}
              />
            </Popover>
          ) : null
        }
      </div>
    );
  }

  @autobind
  private onSelect(item: Item): void {
    this.props.onSelect(item);
    this.onPopoverClose();
  }

  @autobind
  private makeRef(ref: HTMLDivElement): void {
    this.rootDiv = ref;
  }

  @autobind
  private onPopoverClose(): void {
    this.setState({
      isPopoverOpen: false,
    });
  }

  @autobind
  private openPopover(): void {
    this.setState({
      isPopoverOpen: true,
    });
  }

  @autobind
  private onDelete(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.props.onSelect(null);
  }

  private getItemById(items: Item[], id: number): Item {
    if (!items || !id) {
      return null;
    }

    for (const item of items) {
      const result = item.id === id
        ? item
        : this.getItemById(item.children, id);

      if (result) {
        return result;
      }
    }

    return null;
  }
}
