import { DrawingsPolygonGeometry, DrawingsPolylineGeometry } from '../../interfaces';

interface PolylineSettings {
  polylineHeight: number;
  polylineThickness: number;
  offset: number;
}

function shouldAddToPolyline({
  polylineHeight,
  polylineThickness,
  offset,
}: PolylineSettings): boolean {
  return polylineHeight !== undefined && polylineHeight !== null
    || polylineThickness !== undefined && polylineThickness !== null
    || offset !== undefined && offset !== null;
}

function addToPolyline(
  geometry: DrawingsPolylineGeometry,
  { polylineHeight, polylineThickness, offset }: PolylineSettings,
): void {
  if (polylineHeight !== undefined && polylineHeight !== null) {
    geometry.height = polylineHeight;
  }
  if (polylineThickness !== undefined && polylineThickness !== null) {
    geometry.thickness = polylineThickness;
  }
  if (offset !== undefined && offset !== null) {
    geometry.offset = offset;
  }
}

interface PolygonSettings {
  polygonHeight: number;
  offset: number;
}

function shouldAddToPolygon({
  polygonHeight,
  offset,
}: PolygonSettings): boolean {
  return polygonHeight !== undefined && polygonHeight !== null || offset !== undefined && offset !== null;
}

function addToPolygon(
  geometry: DrawingsPolygonGeometry,
  { polygonHeight, offset }: PolygonSettings,
): void {
  if (polygonHeight !== undefined && polygonHeight !== null) {
    geometry.height = polygonHeight;
  }
  if (offset !== undefined && offset !== null) {
    geometry.offset = offset;
  }
}

export const ThreeDParamsHelper = {
  shouldAddToPolyline,
  shouldAddToPolygon,
  addToPolyline,
  addToPolygon,
};
