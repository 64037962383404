import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 15px;
  display: inline-flex;
  gap: 15px;
  cursor: pointer;

  p {
    line-height: 20px;
  }
`;

export const Styled = {
  Container,
};
