import React from 'react';
import { ButtonInput, InputSelectNoIndex } from 'common/components/inputs';
import { useValues } from './use-values';
import { ValueWithEdit } from './values-with-edit';

interface Props{
  elements: Record<string, string[]>;
  getElementLabel?: (value: string) => string;
  defaultValue?: string;
}

export const SelectValue: React.FC<Props> = ({ elements, getElementLabel, defaultValue }) => {
  const { values, onChange, activeElement } = useValues(elements);

  return (
    <ValueWithEdit>
      { activeElement
        ? <ButtonInput value={getElementLabel ? getElementLabel(activeElement) : activeElement}/>
        : (
          <InputSelectNoIndex
            elements={values}
            createNewElement={false}
            onClick={onChange}
            activeElement={activeElement}
            getElementLabel={getElementLabel}
            placeholder={defaultValue}
            placeholderAsValue={true}
            isShowArrow={true}
          />
        )
      }
    </ValueWithEdit>
  );
};
