import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Menu } from 'unit-2d-info-panel/constants/menus';
import { TwoDInfoPanelActions } from 'unit-2d-info-panel/store-slice';

export function useOpenMenu(menu: Menu): () => void {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(TwoDInfoPanelActions.setMenu(menu));
  }, [dispatch, menu]);
}
