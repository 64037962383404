import * as React from 'react';

export const KreoIconClipBoxNew: React.FC = () => {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
      <g
        className='icon'
        id='Icons/24x24/ClipBox_Normal'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          // eslint-disable-next-line max-len
          d='M18.4411765,6.43529412 L12.9411765,3.50196078 C12.3529412,3.18823529 11.6470588,3.18823529 11.0588235,3.50196078 L5.55882353,6.43529412 C4.90709655,6.78288184 4.5,7.46137609 4.5,8.2 L4.5,15.8 C4.5,16.5386239 4.90709655,17.2171182 5.55882353,17.5647059 L11.0588235,20.4980392 C11.6470588,20.8117647 12.3529412,20.8117647 12.9411765,20.4980392 L18.4411765,17.5647059 C19.0929034,17.2171182 19.5,16.5386239 19.5,15.8 L19.5,8.2 C19.5,7.46137609 19.0929034,6.78288184 18.4411765,6.43529412 Z'
          className='icon__color-light'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          fillRule='nonzero'
        />
        <path
          d='M12.0000136,20.5 L12.0000136,11'
          className='icon__color-light'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          fillRule='nonzero'
        />
        <path
          className='icon__color-light'
          d='M5.5,7.5 L11.0518004,10.489431 C11.643753,10.8081747 12.356247,10.8081747 12.9481996,10.489431 L18.5,7.5'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          fillRule='nonzero'
        />
        <path
          // eslint-disable-next-line max-len
          d='M20,10.9214009 L20,9.19986345 C20.1348081,9.19973822 20.2714765,9.23372853 20.3969112,9.30540549 L22.9433639,10.7605214 C23.1762082,10.8935752 23.3659379,11.0907063 23.4899882,11.3284694 C23.8476436,12.0139756 23.581868,12.8596251 22.8963618,13.2172806 L13.2951848,18.2265903 C12.4836364,18.6500069 11.5163636,18.6500069 10.7048152,18.2265903 L1.10363816,13.2172806 C0.865875069,13.0932302 0.668744017,12.9035005 0.535690168,12.6706563 C0.152076211,11.9993319 0.385311628,11.1441353 1.05663605,10.7605214 L3.60308885,9.30540549 C3.72852352,9.23372853 3.86519192,9.19973822 4,9.19986345 L4,10.9214009 L2.16830742,11.9680824 L11.4449208,16.8080546 C11.7927273,16.9895188 12.2072727,16.9895188 12.5550792,16.8080546 L21.8316926,11.9680824 L20,10.9214009 Z'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
