import React from 'react';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { WizzardPreview } from 'common/components/drawings/layout-components/wizzard-preview/wizzard-preview';

export const Previews: React.FC = () => {
  const { drawMode } = useDrawModeApi();

  if (drawMode === DrawingsDrawMode.Finder) {
    return <WizzardPreview />;
  } else {
    return null;
  }
};
