import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  DrawingsGeometryGroup,
  DrawingsGeometryInstance,
  DrawingsInstanceMeasure,
  DrawingsAnnotationLegendActions,
} from 'common/components/drawings';
import { DrawingsFile, DrawingsFolderViewInfo } from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces/drawings-short-drawing-info';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import { State } from 'common/interfaces/state';
import { TwoDActions } from '../../actions/creators';
import { FormulaBarPayload } from '../../actions/payloads';
import { FocusedCell, ReportPage } from '../../interfaces';

export interface StateToProps {
  focusedCell: FocusedCell;
  formulaBarValue: string;
  isFormulaBarFocused: boolean;
  isImperial: boolean;
  selectedSheetId: string;
  reportPages: ReportPage[];
  editFullCellId: string;
  geometry: Record<string, DrawingsGeometryInstance>;
  drawingsInfo: Record<string, DrawingsShortInfo>;
  drawingsGroups: DrawingsGeometryGroup[];
  entities: Record<string, DrawingsFile | DrawingsFolderViewInfo>;
  currentDrawing: DrawingsShortInfo;
  drawings: Record<string, DrawingsShortInfo>;
  aiAnnotation: DrawingsGeometryState;
  elementMeasurement: Record<string, DrawingsInstanceMeasure>;
}

export interface DispatchProps {
  setRead: (value: boolean) => void;
  setWrite: (value: boolean) => void;
  setSelectedInstances: (instanceIds: string[]) => void;
  setCellWithBoarder: (cellId: string) => void;
  setFormulaBar: (formulaBar: FormulaBarPayload) => void;
  setSelectGeometryGroup: (groupIds: string[]) => void;
}

const mapStateToProps = (state: State): StateToProps => {
  const {
    focusedCell,
    formulaBar: {
      value: formulaBarValue,
      isFocused: isFormulaBarFocused,
    },
    selectedSheetId,
    reportPages,
    editFullCellId,
  } = state.twoD;
  const drawings = state.drawings;
  return {
    focusedCell,
    formulaBarValue: formulaBarValue || '',
    isFormulaBarFocused,
    isImperial: state.account.settings.isImperial,
    selectedSheetId,
    reportPages,
    editFullCellId,
    geometry: state.drawings.aiAnnotation.geometry,
    drawingsInfo: state.drawings.drawingsInfo,
    drawingsGroups: state.drawings.drawingGeometryGroups,
    entities: state.drawings.files.entities,
    elementMeasurement: drawings.elementMeasurement,
    drawings: drawings.drawingsInfo,
    aiAnnotation: drawings.aiAnnotation,
    currentDrawing: drawings.currentDrawingInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    setRead: (value) => dispatch(TwoDActions.setRead(value)),
    setWrite: (value) => dispatch(TwoDActions.setWrite(value)),
    setSelectedInstances: (instanceIds) => dispatch(DrawingsAnnotationLegendActions.updateSelection({ instanceIds })),
    setCellWithBoarder: (cellId) => dispatch(TwoDActions.setCellWithBoarder(cellId)),
    setFormulaBar: (formulaBar) => dispatch(TwoDActions.setFormulaBar(formulaBar)),
    setSelectGeometryGroup: (groupIds) => dispatch(DrawingsAnnotationLegendActions.updateSelection({ groupIds })),
  };
};

export const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
