import { AsyncArrayUtils } from 'common/utils/async-array-util';

const getElementPropertiesValueAsync = async (
  ids: number[],
  mapIdHelper: (bimIds: number) => number,
  getElementProrertyValue: (id: number, group: string, propertyKey: string) => string,
  key: string,
  groupName: string,
): Promise<Record<number, string>> => {
  const result: Record<number, string> = {};
  const trimedkey = key;
  const trimedGroupName = groupName.toLowerCase();
  for await (const id of AsyncArrayUtils.iteratorWithDelayEveryNStep(ids, 0.001, 1000)) {
    result[id] = getElementProrertyValue(mapIdHelper(id), trimedGroupName, trimedkey) || '';
  }
  return result;
};

export const PropertiesDataProviderHelper = {
  getElementPropertiesValueAsync,
};
