import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';


import './tree-item.scss';

import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { RevitTreeBaseItem } from '../../../projects/components/revit-tree-base-item';
import { RevitTreeLevel } from '../../../projects/enums/revit-tree-level';
import { ModelBrowserTreeItem } from '../../interfaces';
import { VisibilityIcon } from './visibility-icon';


interface Props {
  expandable: boolean;
  level: RevitTreeLevel;
  matched: boolean;
  item: ModelBrowserTreeItem;
  identifier: string;
  hide: (item: ModelBrowserTreeItem) => void;
  toggleCollapseExpand: (identifier: string) => void;
  show: (item: ModelBrowserTreeItem) => void;
  ghost: (item: ModelBrowserTreeItem) => void;
  onSelect: (item: ModelBrowserTreeItem, identifier: string) => void;
  toggleSelectStatus: (item: ModelBrowserTreeItem, identifier: string) => void;
}

export class TreeItem extends React.Component<Props> {

  public render(): React.ReactNode {
    const {
      item: {
        disvisible: visibilityMode,
        expanded,
        name,
        selected,
      },
      matched,
      level,
      expandable,
    } = this.props;
    const className = classNames(
      'viewer-model-browser-item',
      {
        [`viewer-model-browser-item--${visibilityMode}`]: !!visibilityMode,
        'viewer-model-browser-item--matched': matched,
        'viewer-model-browser-item--selected': selected,
      });
    return (
      <RevitTreeBaseItem
        expandable={expandable}
        expanded={expanded}
        className={className}
        title={name}
        level={level}
        onExpand={this.onExpand}
        onClick={this.onSelect}
      >
        <VisibilityIcon
          visibility={visibilityMode}
          onGhost={this.onGhostIconClick}
          onHide={this.onHideIconClick}
          onNormal={this.onStandardIconClick}
        />
      </RevitTreeBaseItem>
    );
  }


  @autobind
  private onExpand(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.props.toggleCollapseExpand(this.props.identifier);
  }

  @autobind
  private onSelect(e: React.MouseEvent<HTMLDivElement>): void {
    const { item, identifier } = this.props;
    if (HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e)) {
      this.props.toggleSelectStatus(item, identifier);
    } else {
      this.props.onSelect(item, identifier);
    }
  }

  @autobind
  private onGhostIconClick(e: React.MouseEvent<SVGElement>): void {
    e.stopPropagation();
    this.props.hide(this.props.item);
  }

  @autobind
  private onStandardIconClick(e: React.MouseEvent<SVGElement>): void {
    e.stopPropagation();
    this.props.ghost(this.props.item);
  }

  @autobind
  private onHideIconClick(e: React.MouseEvent<SVGElement>): void {
    e.stopPropagation();
    this.props.show(this.props.item);
  }
}
