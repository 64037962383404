import { IconButton, Icons, MenuItem, ModalWrapper, SearchList } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { DrawingsActions } from 'common/components/drawings';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { DrawingsRendererApiContextProps, withRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { DrawModeContextProps, withDrawingModeApi } from 'common/components/drawings/hooks';
import { DrawingsFiles } from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces/drawings-short-drawing-info';
import { DrawingsPdfBrowserUtils } from 'common/components/drawings/utils/drawings-pdf-browser-utils';
import { DialogsMenu } from 'common/constants/dialogs-menu';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { UndoRedoContextApiProps, withUndoRedoApiProps } from 'common/undo-redo';
import { MenuFileManagerPageTab } from './menu-file-manager-page-tab/menu-file-manager-page-tab';
import { Styled } from './styled';

interface StateProps {
  selectedPages: string[];
  drawingsInfo: Record<string, DrawingsShortInfo>;
  activeId: string;
  files: DrawingsFiles;
  currentDrawingInfo: DrawingsShortInfo;
}

interface DispatchProps {
  onSelectDrawing: (id: string) => void;
  saveSelectedDrawingsState: (id: string) => void;
  openPdfManagerDialog: () => void;
  closeMenuFileManagerDialog: () => void;
  openMenuFileManagerDialog: () => void;
  setTabs: (id: string[]) => void;
}

interface Props extends
  StateProps,
  DispatchProps,
  UndoRedoContextApiProps,
  AbilityAwareProps,
  DrawingsRendererApiContextProps,
  DrawModeContextProps { }

interface ComponentState {
  tabList: string[];
}

const MAX_ITEMS_COUNT = 7;
const CONTAINER_HEIGHT_ITEM = 90;
const CONTAINER_HEIGHT_EMPTY = 50;
class MenuFileManagerComponent extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabList: [],
    };
  }

  public componentDidUpdate(prevProps: StateProps): void {
    const { files, drawingsInfo } = this.props;
    const tabList = [];

    if (!isEqual(prevProps.drawingsInfo, drawingsInfo)) {
      this.setState({ tabList });
    }

    for (const entity of Object.values(files.entities)) {
      if (DrawingsPdfBrowserUtils.isFile(entity)) {
        for (const page of entity.pages) {
          tabList.push(this.props.drawingsInfo[page.drawingId]);
        }
      }
    }
    if (tabList.length !== this.state.tabList.length) {
      this.setState({ tabList });
    }
  }

  public render(): JSX.Element {
    const itemHeight = this.state.tabList.length !== 0 ? CONTAINER_HEIGHT_ITEM : CONTAINER_HEIGHT_EMPTY;

    return (
      <>
        <Styled.MenuFileManagerButton>
          <IconButton Icon={Icons.MenuFileManager} onClick={this.props.openMenuFileManagerDialog}/>
        </Styled.MenuFileManagerButton>
        <DialogWrapper name={DialogsMenu.MENU_FILE_MANAGER}>
          <Styled.Wrapper>
            <ModalWrapper onExit={this.props.closeMenuFileManagerDialog}>
              <Styled.MenuFileManager>
                <SearchList
                  items={this.state.tabList}
                  searchPlaceholder={'Start typing'}
                  width={265}
                  itemHeight={itemHeight}
                  maxItemsCount={MAX_ITEMS_COUNT}
                  itemComponent={this.getItemComponent}
                  getKeyValue={this.getTabKey}
                  getStringToCompare={this.getTabNameAndPdfName}
                  inputAutoFocus={true}
                  onClick={ConstantFunctions.doNothing}
                >
                  <MenuItem
                    fontSize={14}
                    text={'Open the File Manager'}
                    Icon={Icons.Right}
                    onClick={this.onCloseModal}
                  />
                </SearchList>
              </Styled.MenuFileManager>
            </ModalWrapper>
          </Styled.Wrapper>
        </DialogWrapper>
      </>
    );
  }

  private getTabKey(item: DrawingsShortInfo): string {
    return item.drawingId;
  }

  @autobind
  private getTabNameAndPdfName(item: DrawingsShortInfo): string {
    const pdfName = this.props.files.entities[item.pdfId].properties.name;
    const tabAndPdfName = `${item.name}/${pdfName}`;

    return tabAndPdfName;
  }

  @autobind
  private onCloseModal(): void {
    this.props.openPdfManagerDialog();
    this.props.closeMenuFileManagerDialog();
  }

  @autobind
  private getItemComponent(item: DrawingsShortInfo): JSX.Element {
    const canEditMeasurement = this.props.ability.can(Operation.Update, Subject.Takeoff2DMeasurement);

    return (
      <MenuFileManagerPageTab
        key={item.drawingId}
        item={item}
        onClickTab={this.onSelectDrawingFile}
        canEditMeasurement={canEditMeasurement}
      />
    );
  }

  @autobind
  private onSelectDrawing(drawingId: string): void {
    if (this.props.currentDrawingInfo?.drawingId !== drawingId) {
      this.props.onSelectDrawing(drawingId);
      this.props.saveSelectedDrawingsState(drawingId);
    }
  }

  @autobind
  private onSelectDrawingFile(drawingId: string): void {
    if (this.props.currentDrawingInfo?.drawingId !== drawingId) {
      this.props.setDrawMode(DrawingsDrawMode.Disabled, { afterSave: () => this.selectFile(drawingId) });
    }
  }

  private selectFile(drawingId: string): void {
    const { selectedPages, setTabs } = this.props;
    const isAddDrawingId = selectedPages.includes(drawingId);

    if (!isAddDrawingId) {
      const newSelectedPages = selectedPages.concat(drawingId);
      setTabs(newSelectedPages);
    }
    this.onSelectDrawing(drawingId);
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    currentDrawingInfo: state.drawings.currentDrawingInfo,
    selectedPages: state.drawings.selectedPages,
    drawingsInfo: state.drawings.drawingsInfo,
    activeId: state.drawings.currentDrawingInfo && state.drawings.currentDrawingInfo.drawingId,
    files: state.drawings.files,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onSelectDrawing: (drawingId) => dispatch(DrawingsActions.selectDrawing(drawingId)),
    saveSelectedDrawingsState: (drawingId) => dispatch(DrawingsActions.saveSelectedDrawingsState(drawingId)),
    openPdfManagerDialog: () => dispatch(KreoDialogActions.openDialog(DrawingDialogs.PDF_MANAGER_DIALOG)),
    openMenuFileManagerDialog: () => dispatch(KreoDialogActions.openDialog(DialogsMenu.MENU_FILE_MANAGER)),
    closeMenuFileManagerDialog: () => dispatch(KreoDialogActions.closeDialog(DialogsMenu.MENU_FILE_MANAGER)),
    setTabs: (selectedPages) => dispatch(DrawingsActions.setTabs(selectedPages)),
  };
}

export const MenuFileManager = connect(mapStateToProps, mapDispatchToProps)(
  withAbilityContext(withUndoRedoApiProps(withRendererApiContext(withDrawingModeApi(MenuFileManagerComponent)))),
);
