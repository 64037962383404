import * as React from 'react';

import './prop-line.scss';

interface Props {
  name: string;
  value: string;
}

export const PropLine: React.FC<Props> = (props) => {
  return (
    <div className='validation-props-line'>
      <div className='validation-props-line-content'>
        <div title={props.name} className='validation-props-line-name'>
          {props.name}
        </div>
        <div title={props.value} className='validation-props-line-value'>
          {props.value}
        </div>
      </div>
    </div>
  );
};
