import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { ProjectsFolderActions } from 'unit-projects/actions/creators/projects-folder';
import { CompanyProjectHeader } from 'unit-projects/interfaces/company-project-header';
import { ProjectsFolder, ProjectsFolderFormData } from 'unit-projects/interfaces/projects-folder';
import {
  CreateFolderDialog,
  CREATE_FOLDER_DIALOG_NAME,
  FolderData,
} from '../../../../../units/2d-projects-page/2d-projects-content/folder-list';
import {
  MoveToFolderDialog,
  MOVE_TO_FOLDER_DIALOG_NAME,
} from '../../../../../units/2d-projects-page/2d-projects-content/move-to-folder-dialog';
import { PeopleActions } from '../../../../../units/people/actions/actions';
import { UpdateInvitationToProjectParams } from '../../../../../units/people/actions/payloads';

interface DispatchProps {
  updateFolder: (folder: ProjectsFolder) => void;
  createFolder: (folder: ProjectsFolderFormData) => void;
  showDialog: (name: string, data?: any) => void;
  closeDialog: (dialogName: string) => void;
  moveProjectToFolder: (projectId: number, folderId: number) => void;
  updateInvitedPeoples: (payload: UpdateInvitationToProjectParams) => void;
}

interface Props extends DispatchProps {
  projectHeader: CompanyProjectHeader;
  saveParentFolderId: (id: number) => void;
}

class FolderDialogsComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <>
        <CreateFolderDialog
          onFormSubmit={this.createFolder}
          onCloseDialog={this.closeCreateFolderDialog}
        />
        <MoveToFolderDialog
          onSubmit={this.moveToFolder}
          onCloseDialog={this.closeMoveToFolderDialog}
          createFolder={this.openCreateFolderDialog}
          projectHeader={this.props.projectHeader}
          isFolderDialog={true}
        />
      </>
    );
  }

  @autobind
  private createFolder(id: number, data: ProjectsFolderFormData): void {
    const isEdit = !!id;
    if (isEdit) {
      this.props.updateFolder({ id, ...data });
    } else {
      this.props.createFolder(data);
    }
  }

  @autobind
  private openCreateFolderDialog(data: FolderData): void {
    this.props.showDialog(CREATE_FOLDER_DIALOG_NAME, data);
  }

  @autobind
  private closeCreateFolderDialog(): void {
    this.props.closeDialog(CREATE_FOLDER_DIALOG_NAME);
  }

  @autobind
  private moveToFolder(folder: ProjectsFolder, projectId: number, parentFolderId: number): void {
    if (folder) {
      this.props.updateFolder({ ...folder, parentFolderId });
    }
    if (projectId) {
      this.props.moveProjectToFolder(projectId, parentFolderId);
    }
    this.props.saveParentFolderId(parentFolderId);
  }

  @autobind
  private closeMoveToFolderDialog(): void {
    this.props.closeDialog(MOVE_TO_FOLDER_DIALOG_NAME);
  }
}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    updateFolder: (folder) => dispatch(ProjectsFolderActions.updateFolder(folder)),
    createFolder: (folder) => dispatch(ProjectsFolderActions.createFolder(folder)),
    showDialog: (dialogName, data) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    closeDialog: (dialogName) => dispatch(KreoDialogActions.closeDialog(dialogName)),
    moveProjectToFolder: (projectId, folderId) =>
      dispatch(ProjectsActions.moveProjectToFolder(projectId, folderId, false)),
    updateInvitedPeoples: (payload) => dispatch(PeopleActions.updateInvitedPeoples(payload)),
  };
};


const connector = connect(null, mapDispatchToProps);

export const FolderDialogs = connector(FolderDialogsComponent);
