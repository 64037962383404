export interface CommunicatorMessage {
  type: string;
  source: string;
  target: string;
  value: any;
}

export abstract class Communicator<TCreateConfig> {
  public abstract createChannel(config: TCreateConfig): void;
  public abstract sendMessage(message: CommunicatorMessage): void;
  public abstract setMessageReceiver(): void;
  public abstract closeChannel(): void;
}
