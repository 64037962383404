import * as Ag from 'ag-grid-community';
import classNames from 'classnames';
import * as uuidv4 from 'uuid/v4';
import { AgGridTooltipUtils } from 'common/UIKit/ag-grid-tooltip-utils';

import { ButtonSize } from '../icon-button';

interface Params extends Ag.ICellRendererParams {
  className?: string;
  tooltip?: string;
  onClick: (e: MouseEvent) => void;
  size: ButtonSize;
}

export class IconButtonAgGrid implements Ag.ICellRendererComp {
  private params: Params;
  private eGui: HTMLDivElement;

  public init(params: Params): void {
    this.params = params;
    this.eGui = document.createElement('div');
    this.eGui.className = classNames(
      'kreo-icon-btn',
      'kreo-icon-btn--rounded',
      `kreo-icon-btn--${this.params.size}`,
      this.params.className,
    );
    this.eGui.addEventListener('click', this.params.onClick);
    if (this.params.tooltip) {
      const id = uuidv4();
      this.eGui.id = id;
      this.eGui.setAttribute(
        'onmouseover',
        `buttonCallbacks.appendTooltip('${id}', '${this.params.tooltip}')`,
      );
      this.eGui.setAttribute('onmouseleave', `buttonCallbacks.removeTooltip('${id}')`);
      this.eGui.setAttribute('onclick', `buttonCallbacks.removeTooltip('${id}')`);
    }
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(): boolean {
    return true;
  }

  public destroy(): void {
    AgGridTooltipUtils.removeTooltip(this.eGui.id);
    return;
  }
}
