import { ElementTooltip, Icons, RoundButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

interface Props {
  active: boolean;
  toggle: () => void;
}

export const FullscreenButton: React.FC<Props> = ({ active, toggle }) => {
  return (
    <ElementTooltip
      text='Fullscreen'
      position='bottom'
      speed='l'
    >
      <RoundButton
        Icon={active ? Icons.ExitFullscreen : Icons.FullScreen}
        size='s'
        onClick={toggle}
        mood={active ? 'secondary' : 'default'}
      />
    </ElementTooltip>
  );
};
