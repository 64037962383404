import { RectangleButton, Constants } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { withTheme } from 'styled-components';

import { CheckboxWithText } from 'common/components/checkbox-with-text';
import { ExportSettingsActions } from 'common/components/drawings/actions/creators/export-settings';
import {
  DrawingDialogs,
} from 'common/components/drawings/constants/drawing-dialogs';
import { DrawingsExportConstants } from 'common/components/drawings/constants/export-constants';
import { DrawingSettingsApi } from 'common/components/drawings/hooks/settings/use-drawing-settings';
import { DrawingsGeometryGroup, DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import { FilterData } from 'common/components/drawings/interfaces/drawing-filters';
import { MenuGroupContainer, MenuGroupContainerWithTitle } from 'common/components/menu-group-container';
import { RenderIf } from 'common/components/render-if';
import { RadioMenu, MenuOptionInfo, CheckboxGroupMenu } from 'common/components/selection-menus';
import { METRIC_IDS } from 'common/constants/id-maps';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { Menu } from 'unit-2d-info-panel/constants/menus';
import { TwoDInfoPanelActions } from 'unit-2d-info-panel/store-slice';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { AssignedPia } from '../../../../../../units/2d';
import { PersistedStorageActions } from '../../../../../../units/persisted-storage/actions/creators';
import { DrawingsFilesData } from '../../../interfaces/drawing-ai-annotation';
import { DrawingsPdfExport } from '../../../interfaces/drawings-pdf';
import { DrawingsShortInfo } from '../../../interfaces/drawings-short-drawing-info';
import { withDrawingsSettingsContextApiProps } from '../drawings-settings';
import {
  PAGE_OPTIONS,
  MEASUREMENT_OPTIONS,
  ANNOTATION_OPTIONS,
  FONT_SIZE_OPTIONS,
  LINE_THICKNESS_OPTIONS,
} from './constants';
import { AnnotationOptions, MeasurementOptions, PageOptions } from './drawings-export-options';
import { FieldSettingDropDownList } from './field-setting-drop-down-list';
import { Styled } from './styled';
import {
  applyLegendFilters,
  filterAllFilesInstances,
  filterFileInstances,
  filterOpenedDrawingInstances,
} from './utils';

interface OwnProps extends DrawingSettingsApi {
  theme: { color: Record<string, string> };
}

interface StateProps {
  projectId: number;
  currentDrawing: DrawingsShortInfo;
  hiddenInstances: string[];
  fileData: DrawingsFilesData;
  filterData: FilterData;
  instances: Record<string, DrawingsGeometryInstance>;
  selectedPages: string[];
  groups: DrawingsGeometryGroup[];
  assignPia: Record<string, AssignedPia>;
  isShowTwoDReport: boolean;
  viewMeasureId: Record<string, boolean>;
  dynamicGroupsToCell: Record<string, string[]>;
  drawingInstanceInCellRange: Record<string, boolean>;
  selectedMeasureIdFromView: Record<string, boolean>;
  instanceToCells: Record<string, string[]>;
  selectedSheetId: string;
  filteredNodeIds: Record<string, boolean>;
  exportPages: PageOptions;
  exportMeasurements: MeasurementOptions;
  fontSize: number;
  lineThickness: number;
  exportAnnotations: AnnotationOptions[];
  drawingStylesEnabled: boolean;
  isNightTheme: boolean;
  segmentLength: boolean;
  segmentLengthOnOtherLayer: boolean;
}

interface DispatchProps {
  exportPdf: (projectId: number, pdfId: string, exportPayload: DrawingsPdfExport, currentFileOnly: boolean) => void;
  openDialog: () => void;
  setFontSize: (fontSize: number) => void;
  setScaleFactor: (fontSize: number) => void;
  setExportPages: (exportPages: PageOptions) => void;
  setExportMeasurements: (exportMeasurements: MeasurementOptions) => void;
  setExportAnnotations: (exportAnnotations: AnnotationOptions[]) => void;
  toggleSegmentLength: () => void;
  toggleSegmentLengthOnLayer: () => void;
  toggleDrawingStyles: () => void;
  closeExportMenu: () => void;
}

interface Props extends StateProps, DispatchProps, OwnProps, AnalyticsProps { }

interface ComponentState {
  currentPageOptions: Array<MenuOptionInfo<PageOptions>>;
}

class DrawingsExportComponent extends React.PureComponent<Props, ComponentState> {
  private preparePayloadByType: Record<PageOptions, (measurement: MeasurementOptions) => DrawingsPdfExport> = {
    [PageOptions.All]: (measurement) => {
      const exportPayload: DrawingsPdfExport = {};
      if (measurement === MeasurementOptions.Visible) {
        const filtered = filterAllFilesInstances(new Set(this.props.hiddenInstances), this.props.fileData);
        exportPayload.drawingIds = this.applyLegendFilters(filtered);
      }
      return exportPayload;
    },
    [PageOptions.Current]: (measurement) => {
      const exportPayload: DrawingsPdfExport = { pageIds: [this.props.currentDrawing.drawingId] };
      if (measurement === MeasurementOptions.Visible) {
        const { drawingId, pdfId } = this.props.currentDrawing;
        const pageData = this.props.fileData[pdfId] && this.props.fileData[pdfId][drawingId];
        if (pageData?.instances.length) {
          const hiddenInstances = new Set(this.props.hiddenInstances);
          exportPayload.drawingIds = this.applyLegendFilters(pageData.instances.filter(id => !hiddenInstances.has(id)));
        }
      }
      return exportPayload;
    },
    [PageOptions.CurrentFile]: (measurement) => {
      const exportPayload: DrawingsPdfExport = {};
      if (measurement === MeasurementOptions.Visible) {
        const { currentDrawing: { pdfId }, hiddenInstances, fileData } = this.props;
        if (fileData[pdfId]) {
          exportPayload.drawingIds = this.applyLegendFilters(
            filterFileInstances(new Set(hiddenInstances), fileData[pdfId]),
          );
        }
      }
      return exportPayload;
    },
    [PageOptions.OpenedPages]: (measurement) => {
      const { hiddenInstances, instances, selectedPages } = this.props;
      const exportPayload: DrawingsPdfExport = { pageIds: selectedPages };
      if (measurement === MeasurementOptions.Visible) {
        exportPayload.drawingIds = this.applyLegendFilters(filterOpenedDrawingInstances(
          new Set(hiddenInstances),
          instances,
          new Set(selectedPages),
        ));
      }
      return exportPayload;
    },
    [PageOptions.WithMeasurements]: (measurement) => {
      const { hiddenInstances, instances } = this.props;
      const hidden = new Set(measurement === MeasurementOptions.Visible ? hiddenInstances : []);
      const exportPayload: DrawingsPdfExport = { pageIds: [], drawingIds: [] };
      const filter = arrayUtils.filterIterator(Object.keys(instances), id => !hidden.has(id));
      if (measurement === MeasurementOptions.Visible) {
        exportPayload.drawingIds = this.applyLegendFilters(filter);
        for (const instanceId of exportPayload.drawingIds) {
          exportPayload.pageIds.push(instances[instanceId].drawingId);
        }
      } else {
        for (const [id, instance] of Object.entries(instances)) {
          if (!hidden.has(id)) {
            exportPayload.pageIds.push(instance.drawingId);
            exportPayload.drawingIds.push(id);
          }
        }
      }

      exportPayload.pageIds = arrayUtils.uniq(exportPayload.pageIds);
      return exportPayload;
    },
  };


  constructor(props: Props) {
    super(props);
    this.state = {
      currentPageOptions: this.getPageOptions(
        !Object.keys(this.props.instances).length,
        !!this.props.currentDrawing,
        this.props.selectedPages.length > 0,
      ),
    };
  }

  public render(): React.ReactNode {
    return (
      <Styled.PopupContainer>
        <Styled.MenusContainer>
          <MenuGroupContainerWithTitle title='Pages:'>
            <RadioMenu
              options={this.state.currentPageOptions}
              value={this.props.exportPages}
              onSelectionChanged={this.props.setExportPages}
            />
          </MenuGroupContainerWithTitle>
          <MenuGroupContainer>
            <FieldSettingDropDownList
              data={FONT_SIZE_OPTIONS}
              value={this.props.fontSize}
              onChange={this.props.setFontSize}
            />
            <FieldSettingDropDownList
              data={LINE_THICKNESS_OPTIONS}
              value={this.props.lineThickness}
              onChange={this.props.setScaleFactor}
            />
            <Styled.StylesCheckbox>
              <CheckboxWithText
                text='Drawing Styles'
                checked={this.props.drawingStylesEnabled}
                onToggle={this.props.toggleDrawingStyles}
              />
            </Styled.StylesCheckbox>
            <Styled.StylesCheckbox>
              <CheckboxWithText
                text='Segments Length'
                checked={this.props.segmentLength}
                onToggle={this.props.toggleSegmentLength}
              />
            </Styled.StylesCheckbox>
            <RenderIf condition={this.props.segmentLength}>
              <Styled.StylesCheckbox>
                <CheckboxWithText
                  text='Segments Length on Layer'
                  checked={this.props.segmentLengthOnOtherLayer}
                  onToggle={this.props.toggleSegmentLengthOnLayer}
                />
              </Styled.StylesCheckbox>
            </RenderIf>
          </MenuGroupContainer>
          <MenuGroupContainerWithTitle title='Measurements:'>
            <RadioMenu
              options={MEASUREMENT_OPTIONS}
              value={this.props.exportMeasurements}
              onSelectionChanged={this.props.setExportMeasurements}
            />
          </MenuGroupContainerWithTitle>
          <MenuGroupContainerWithTitle title='Annotations:'>
            <CheckboxGroupMenu
              onSelectionChanged={this.props.setExportAnnotations}
              options={ANNOTATION_OPTIONS}
              value={this.props.exportAnnotations}
            />
          </MenuGroupContainerWithTitle>
        </Styled.MenusContainer>
        <Styled.ButtonContainer id={METRIC_IDS.exportPdf}>
          <RectangleButton
            fontSize={14}
            text={'Export PDF'}
            height={40}
            mood={'secondary'}
            type={'button'}
            onClick={this.export}
          />
        </Styled.ButtonContainer>
        <ConfirmationDialog
          name={DrawingDialogs.EXPORT_NO_PAGES_DIALOG}
          title='No pages to export'
          text='There are no pages suitable for the selected filters.'
          cancelButtonText='Okay'
          zIndex={1003}
        />
      </Styled.PopupContainer>
    );
  }

  public componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<ComponentState>): void {
    const {
      hiddenInstances,
      instances,
      currentDrawing,
      selectedPages,
      exportMeasurements,
      exportPages,
      setExportPages,
    } = this.props;
    const instancesCount = Object.keys(instances).length;
    const allInstancesHidden = exportMeasurements === MeasurementOptions.All
      ? !instancesCount
      : instancesCount === hiddenInstances.length;
    const options = this.getPageOptions(
      allInstancesHidden,
      !!currentDrawing,
      selectedPages.length > 0,
    );
    if (!arrayUtils.areSetsEqual(options, prevState.currentPageOptions)) {
      const availableOptions = options.map(x => x.value);
      const exportPage = availableOptions.includes(exportPages) ? exportPages : PageOptions.All;
      setExportPages(exportPage);
      this.setState({ currentPageOptions: options });
    }
  }

  private applyLegendFilters(instancesIds: Iterable<string>): string[] {
    return applyLegendFilters(
      instancesIds,
      this.props.filterData,
      this.props.groups,
      this.props.instances,
      this.props.assignPia,
      this.props.isShowTwoDReport,
      this.props.viewMeasureId,
      this.props.dynamicGroupsToCell,
      this.props.drawingInstanceInCellRange,
      this.props.selectedMeasureIdFromView,
      this.props.instanceToCells,
      this.props.selectedSheetId,
      this.props.filteredNodeIds,
    );
  }

  private getPageOptions(
    allInstancesHidden: boolean,
    hasCurrentPage: boolean,
    hasSelectedPages: boolean,
  ): Array<MenuOptionInfo<PageOptions>> {
    return PAGE_OPTIONS.filter(({ value }) => {
      const isAllowedByMeasures = !allInstancesHidden || value !== PageOptions.WithMeasurements;
      const isAllowedOpenedPages = hasSelectedPages || value !== PageOptions.OpenedPages;
      const isAllowedByPage = hasCurrentPage || (value !== PageOptions.CurrentFile && value !== PageOptions.Current);
      return isAllowedByMeasures && isAllowedByPage && isAllowedOpenedPages;
    });
  }

  @autobind
  private export(): void {
    const { projectId, currentDrawing, exportPages, exportMeasurements, drawingStylesEnabled } = this.props;
    const exportPayload: DrawingsPdfExport = this.preparePayloadByType[exportPages](exportMeasurements);
    if (exportPayload.pageIds && exportPayload.pageIds.length === 0) {
      this.props.openDialog();
    } else {
      if (drawingStylesEnabled) {
        this.fillDrawingStyles(exportPayload);
      }
      this.props.exportPdf(projectId, currentDrawing?.pdfId, exportPayload, this.isCurrentFileOnly());
      this.props.sendEvent(MetricNames.reports.exportPdf);
      this.props.closeExportMenu();
    }
  }

  private fillDrawingStyles(payload: DrawingsPdfExport): void {
    const { backgroundRush, gray, background } = this.props.theme.color;
    const { isNightTheme, isNightModeEnabled, isBlackAndWhiteEnabled } = this.props;
    payload.nightModeColor = isNightModeEnabled && isNightTheme ? backgroundRush : null;
    payload.isGrayscale = isBlackAndWhiteEnabled;
    payload.legendSettings = {
      backgroundColor: background,
      alternativeColor: gray,
    };
  }

  private isCurrentFileOnly(): boolean {
    const { exportPages } = this.props;
    return exportPages === PageOptions.Current || exportPages === PageOptions.CurrentFile;
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    instances: state.drawings.aiAnnotation.geometry,
    projectId: state.projects.currentProject.id,
    currentDrawing: state.drawings.currentDrawingInfo,
    hiddenInstances: state.drawings.hiddenInstances,
    fileData: state.drawings.aiAnnotation.fileData,
    selectedPages: state.drawings.selectedPages,
    filterData: state.drawings.filterData,
    groups: state.drawings.drawingGeometryGroups,
    viewMeasureId: state.twoD.usedViewMeasureId,
    isShowTwoDReport: state.twoD.isShowTwoDReport,
    selectedMeasureIdFromView: state.twoD.selectedMeasureIdView,
    assignPia: state.twoD.assignPia,
    instanceToCells: state.twoD.drawingInstanceToCell,
    dynamicGroupsToCell: state.twoD.dynamicGroupsToCell,
    selectedSheetId: state.twoD.selectedSheetId,
    drawingInstanceInCellRange: state.twoD.drawingInstanceInCellRange,
    filteredNodeIds: state.twoD.filteredNodeIds,
    exportPages: state.drawings.exportSettings.exportPages,
    exportMeasurements: state.drawings.exportSettings.exportMeasurements,
    exportAnnotations: state.drawings.exportSettings.exportAnnotations,
    drawingStylesEnabled: state.drawings.exportSettings.drawingStylesEnabled,
    isNightTheme: state.persistedStorage.theme === Constants.Theme.DARK_THEME.name,
    fontSize: state.persistedStorage.drawingExportFontSize || DrawingsExportConstants.DEFAULT_FONT_SIZE,
    lineThickness: state.persistedStorage.drawingExportLineThickness || DrawingsExportConstants.DEFAULT_LINE_THICKNESS,
    segmentLength: state.drawings.exportSettings.segmentLength,
    segmentLengthOnOtherLayer: state.drawings.exportSettings.segmentLengthOnOtherLayer,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    exportPdf: (projectId, pdfId, exportPayload, currentFileOnly) =>
      dispatch(ExportSettingsActions.exportPdf(projectId, pdfId, exportPayload, currentFileOnly)),
    openDialog: () => {
      dispatch(KreoDialogActions.openDialog(DrawingDialogs.EXPORT_NO_PAGES_DIALOG));
    },
    setExportPages: (value) => dispatch(ExportSettingsActions.setExportPages(value)),
    setExportMeasurements: (value) => dispatch(ExportSettingsActions.setExportMeasurements(value)),
    setExportAnnotations: (value) => dispatch(ExportSettingsActions.setExportAnnotations(value)),
    setFontSize: (value) => dispatch(PersistedStorageActions.setDrawingExportFontSize(value)),
    setScaleFactor: (value) => dispatch(PersistedStorageActions.setDrawingExportScaleFactor(value)),
    toggleDrawingStyles: () => dispatch(ExportSettingsActions.toggleDrawingStylesEnabled()),
    toggleSegmentLength: () => dispatch(ExportSettingsActions.toggleSegmentLength()),
    toggleSegmentLengthOnLayer: () => dispatch(ExportSettingsActions.toggleSegmentLengthOnLayer()),
    closeExportMenu: () => dispatch(TwoDInfoPanelActions.setMenu(Menu.Default)),
  };
};

export const DrawingsExport =
  connect(mapStateToProps, mapDispatchToProps)(
    withDrawingsSettingsContextApiProps(withAnalyticsContext(withTheme(DrawingsExportComponent))),
  );
