import React from 'react';

import { DrawingMarkShapes, DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsStyleMenuType } from '../enums';
import { ShapeMenu } from '../shape-menu';
import { StrokeMenu } from '../stroked-menu';


interface Props {
  setStrokeStyle?: (value: DrawingStrokeStyles) => void;
  strokeWidth?: number;
  setShape: (value: DrawingMarkShapes) => void;
  shape: DrawingMarkShapes;
  setStrokeWidth?: (value: number) => void;
  strokeStyle?: DrawingStrokeStyles;
  menuType: DrawingsStyleMenuType;
}

const MenuRendererComponent: React.FC<Props> = (props) => {
  const { menuType, setStrokeStyle, setStrokeWidth, strokeStyle, strokeWidth, setShape, shape } = props;
  switch (menuType) {
    case DrawingsStyleMenuType.Count:
      return (
        <ShapeMenu
          setShape={setShape}
          shape={shape}
        />
      );
    case DrawingsStyleMenuType.Stroked:
      return (
        <StrokeMenu
          setStrokeStyle={setStrokeStyle}
          setStrokeWidth={setStrokeWidth}
          strokeStyle={strokeStyle}
          strokeWidth={strokeWidth}
        />
      );
    case DrawingsStyleMenuType.OnlyColors:
    default:
      return null;
  }
};


export const MenuRenderer = React.memo(MenuRendererComponent);
