import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { change, Field, formValueSelector, InjectedFormProps, isValid, reduxForm } from 'redux-form';

import './index.scss';

import { Operation } from 'common/ability/operation';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { HorizontalSelector } from 'common/components/horizontal-tabs-container';
import { RoleGroup } from 'common/enums/role-group';
import { Role } from 'common/interfaces/account/role';
import {
  KreoButton,
  KreoDialogActions,
  KreoIconSubnavBack,
  MaterialChipsInputField,
  MaterialChipsInputProps,
  MaterialInputField,
  MaterialInputProps,
  MaterialMenuItem,
  MaterialSelectField,
  MaterialSelectProps,
} from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { State as ReduxState } from '../../../common/interfaces/state';
import { INVITE_TO_COMPANY } from '../../../constants/forms';
import { EMAIL } from '../../../constants/regex-templates';
import { Company } from '../../../units/account/interfaces/company';
import { CompanySubscriptionModel } from '../../../units/account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../units/account/selectors';
import { MappedRolesPermissions } from '../../../units/people/interfaces/mapped-roles-permissions';
import { PeopleUtil } from '../../../units/people/people-util';
import { PermissionUtil } from '../../../units/people/utils/permissions-util';
import { KreoFormDialog } from '../base/kreo-form-dialog';
import { Email, Required } from '../validators';
import { PermissionTable } from './permission-table';

interface ReduxProps {
  emails: string[];
  host: string;
  message: string;
  roleId: number;
  open: boolean;
  rolesPermissions: MappedRolesPermissions;
  isFormValid: boolean;
  company: Company | null;
  subscription: CompanySubscriptionModel;
  paidEmployeesCount: number;
}

interface ReduxActions {
  dialogClose: () => void;
  updateRoleId: (roleId: number) => void;
}

interface Props extends ReduxProps, ReduxActions, InjectedFormProps<{}, {}>, AbilityAwareProps { }

const valueSelector = formValueSelector(INVITE_TO_COMPANY);
const isValidSelector = isValid(INVITE_TO_COMPANY);
const validateRequired = [Required];
const validateFields = { emails: 'Required' };
const initialValues = {
  emails: [],
  host: window.location.host,
};

export const INVITE_TO_COMPANY_DIALOG_NAME = 'invitePeopleToCompany';

enum Step {
  SelectPeople,
  SelectRole,
}

interface State {
  selectedRoleGroup: RoleGroup;
  currentStep: Step;
}

type EmailFieldValidator = (value: string[]) => string | undefined;

class InvitePeopleToCompanyComponent extends React.Component<Props, State> {
  private isInit: boolean = false;

  private readonly emailFieldValidators: EmailFieldValidator[] = [
    Required,
    this.validateEmail,
    this.validateCompanyUsersCount,
  ];

  constructor(props: Props) {
    super(props);

    const groups = this.getRoleGroups();
    this.state = {
      selectedRoleGroup: groups.length > 0 ? groups[0] : RoleGroup.Employee,
      currentStep: Step.SelectPeople,
    };
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    const { rolesPermissions } = this.props;
    if (
      rolesPermissions &&
      rolesPermissions.roles.length &&
      ((this.props.open && !prevProps.open && !initialValues.hasOwnProperty('roleId')) ||
        this.state.selectedRoleGroup !== prevState.selectedRoleGroup)
    ) {
      const roleIds = rolesPermissions.roles
        .filter(r => r.group === this.state.selectedRoleGroup)
        .map(r => r.id);

      if (this.isInit) {
        this.props.updateRoleId(roleIds[0]);
      } else {
        this.props.initialize({ ...initialValues, roleId: roleIds[0] });
        this.isInit = true;
      }
    }
  }

  public render(): React.ReactNode {
    const { rolesPermissions } = this.props;

    let content: React.ReactNode;

    if (rolesPermissions) {
      const items = this.getRoleGroups();
      let role: Role = null;

      if (rolesPermissions.roles && this.props.roleId) {
        role = rolesPermissions.roles.find(r => r.id === this.props.roleId);
      }

      switch (this.state.currentStep) {
        case Step.SelectPeople:
          content = (
            <React.Fragment>
              <HorizontalSelector
                activeItem={this.state.selectedRoleGroup}
                items={items}
                renderer={this.itemRenderer}
                onItemClick={this.onRoleGroupChange}
              />
              <div className='invite-people-to-company-dialog__form'>
                <Field<MaterialChipsInputProps>
                  name='emails'
                  component={MaterialChipsInputField}
                  label='Invite by email address'
                  validate={this.emailFieldValidators}
                  validateValue={this.emailItemValidate}
                />
                <div>
                  <Field<MaterialInputProps>
                    className='invite-people-to-company-dialog__text-area'
                    component={MaterialInputField}
                    name='message'
                    label='Include a personal message?'
                    isFloatingLabel={false}
                    multiLine={true}
                    rows={3}
                    maxLength={1024}
                  />
                  <Field style={{ display: 'none' }} type='text' name='host' component='input' />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case Step.SelectRole:
          content = (
            <React.Fragment>
              <div className='invite-people-to-company-dialog__values-wrap'>
                <div className='invite-people-to-company-dialog__values-title'>Selected people</div>
                <div className='invite-people-to-company-dialog__values'>
                  {this.props.emails &&
                    this.props.emails.map((email, index) => {
                      return (
                        <div key={index} className='invite-people-to-company-dialog__chip'>
                          {email}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className='invite-people-to-company-dialog__role-wrap'>
                <div className='invite-people-to-company-dialog__role-title'>Role</div>
                <Field<MaterialSelectProps>
                  name='roleId'
                  component={MaterialSelectField}
                  validate={validateRequired}
                  displayUnderline={false}
                  displayedType={MaterialComponentType.Native}
                  placeholder='Select Role'
                  className='invite-people-to-company-dialog__material-select'
                >
                  {rolesPermissions.roles
                    ? rolesPermissions.roles
                      .filter(r => r.group === this.state.selectedRoleGroup)
                      .map((r, index) => {
                        return (
                          <MaterialMenuItem key={index} value={r.id}>
                            {r.name}
                          </MaterialMenuItem>
                        );
                      })
                    : null}
                </Field>
              </div>
              {role && (
                <PermissionTable
                  permissions={rolesPermissions.permissions[role.id]}
                  activities={rolesPermissions.activities}
                />
              )}
            </React.Fragment>
          );
          break;
        default:
      }
    }

    return (
      <KreoFormDialog
        name={INVITE_TO_COMPANY_DIALOG_NAME}
        title='Invite people'
        submitButtonText='Invite'
        handleSubmit={this.submit}
        onDialogClose={this.dropDialogState}
        modal={false}
        formName={INVITE_TO_COMPANY}
        validateFields={validateFields}
        bodyClassName='invite-people-to-company-dialog'
        actions={this.getActions()}
      >
        <div className='invite-people-to-company-dialog__inner-wrap'>
          <div className='invite-people-to-company-dialog__description'>
            Invite people to your team, to collaborate and get feedback.
          </div>
          {content}
        </div>
      </KreoFormDialog>
    );
  }

  private getActions(): React.ReactNodeArray {
    const peopleCount = this.props.emails.length;

    switch (this.state.currentStep) {
      case Step.SelectRole:
        return [
          (
            <KreoButton
              size='large'
              key={0}
              onClick={this.onBack}
              mode='ghost'
              icon={<KreoIconSubnavBack />}
            >
              Back
            </KreoButton>
          ),
          (
            <KreoButton
              key={1}
              htmlButtonType='submit'
              mode='submit'
              size='large'
              caption={`Invite ${peopleCount} ${peopleCount > 1 ? 'People' : 'Person'}`}
            />
          ),
        ];
      case Step.SelectPeople:
        return [
          (
            <KreoButton
              key={2}
              caption='Cancel'
              size='large'
              htmlButtonType='reset'
              onClick={this.dialogClose}
              mode='ghost'
            />
          ),
          (
            <KreoButton
              key={3}
              size='large'
              caption='Continue'
              mode='submit'
              onClick={this.onContinue}
              disabled={peopleCount === 0 || !this.props.isFormValid}
            />
          ),
        ];
      default:
    }

    return null;
  }


  @autobind
  private submit(e: React.FormEvent<HTMLFormElement>): void {
    if (this.props.isFormValid) {
      this.props.handleSubmit(e);
      this.dialogClose();
    }
  }

  @autobind
  private dialogClose(): void {
    this.dropDialogState();
    this.props.dialogClose();
    this.isInit = false;
  }

  @autobind
  private onContinue(): void {
    this.setState({
      currentStep: Step.SelectRole,
    });
  }

  @autobind
  private onBack(): void {
    this.setState({
      currentStep: Step.SelectPeople,
    });
  }

  @autobind
  private dropDialogState(): void {
    this.props.reset();
    const groups = this.getRoleGroups();
    this.setState({
      currentStep: Step.SelectPeople,
      selectedRoleGroup: groups.length > 0 ? groups[0] : null,
    });
  }

  @autobind
  private itemRenderer(item: React.ReactText): React.ReactNode {
    return this.props.rolesPermissions.roleGroups[item.toString().toLowerCase()];
  }

  @autobind
  private onRoleGroupChange(item: RoleGroup): void {
    this.setState({
      selectedRoleGroup: item,
    });
  }

  private getRoleGroups(): RoleGroup[] {
    return Object.keys(RoleGroup).map(x => RoleGroup[x]).filter(rg =>
      PeopleUtil.canByRoleGroup(this.props.ability, rg, Operation.Create),
    );
  }

  @autobind
  private validateEmail(value: string[]): string | undefined {
    if (!Array.isArray(value)) {
      return;
    }

    const validationResult = value.map(Email).find(result => !!result);
    return validationResult;
  }

  @autobind
  private emailItemValidate(value: string): boolean {
    return EMAIL.test(value);
  }

  @autobind
  private validateCompanyUsersCount(value: string[]): string | undefined {
    const { company, subscription, paidEmployeesCount } = this.props;
    const { selectedRoleGroup } = this.state;

    if (!company || !subscription || value.length === 0 || selectedRoleGroup !== RoleGroup.Employee) {
      return;
    }

    const maxEmployees = subscription && subscription.employeesMaxCount;

    const validationResult = paidEmployeesCount > maxEmployees
      ? `Your subscription does not support adding more than ${maxEmployees} Employee${maxEmployees > 1 ? 's' : ''}`
      : undefined;

    return validationResult;
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const company = state.account.selectedCompany;
  const companyRoles = state.account.subscriptionRoles;

  return {
    emails: valueSelector(state, 'emails'),
    host: valueSelector(state, 'host'),
    message: valueSelector(state, 'message'),
    roleId: valueSelector(state, 'roleId'),
    company,
    subscription: AccountSelectors.currentSubscription(state),
    open: INVITE_TO_COMPANY_DIALOG_NAME in state.dialog,
    rolesPermissions: PermissionUtil.getRolesPermissions(companyRoles),
    isFormValid: isValidSelector(state),
    paidEmployeesCount: AccountSelectors.getPaidEmployeesCount(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    dialogClose: () => dispatch(KreoDialogActions.closeDialog(INVITE_TO_COMPANY_DIALOG_NAME)),
    updateRoleId: (roleId: number) => dispatch(change(INVITE_TO_COMPANY, 'roleId', roleId)),
  };
};

const InvitePeopleToCompanyDialogRedux = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(InvitePeopleToCompanyComponent),
);

export const InvitePeopleToCompanyDialog = reduxForm<{}, {}>({
  form: INVITE_TO_COMPANY,
  destroyOnUnmount: true,
  initialValues,
})(InvitePeopleToCompanyDialogRedux);
