import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';
import { Text } from 'common/components/text';
import { Styled } from './styled';

interface Props {
  extensionsToShow: string[];
}

const FileTitleComponent: React.FC<Props> = ({
  extensionsToShow,
}) => {
  const formats = useMemo(() => extensionsToShow.join(', ').toUpperCase(), [extensionsToShow]);

  return (
    <Styled.DragAndDropField>
      <Icons.Export />
      <Text fontSize={14} lineHeight={18}>Drag & Drop your files here</Text>
      <Text fontSize={14} lineHeight={21} letterSpacing={0} color={'additionalFont'} textAlign={'center'}>
        {`Supported file formats : ${formats}`}
      </Text>
      <RectangleButton
        size={'s'}
        text='Upload'
        mood={'secondary'}
        type={'button'}
      />
    </Styled.DragAndDropField>
  );
};


export const FileTitle = React.memo(FileTitleComponent);
