import classNames from 'classnames';
import * as React from 'react';

import { Checkbox } from 'common/UIKit';
import { MeasurementsExtractorItemInnerContainer } from '../measurements-extractor-item-inner-container';

interface Props {
  className?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  label: string;
  disabled: boolean;
  indeterminate?: boolean;
}

export const MeasurementsExtractorItemCheckbox: React.FC<Props> = props => {
  const className = classNames(props.className);
  return (
    <MeasurementsExtractorItemInnerContainer className={className}>
      <Checkbox
        checked={props.checked}
        onChange={props.onChange}
        label={props.label}
        controlName='extractor-item-checkbox'
        disabled={props.disabled}
        indeterminate={props.indeterminate}
        color='blue'
      />
    </MeasurementsExtractorItemInnerContainer>
  );
};
