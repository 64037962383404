import { Text, Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ConsequencesBody } from './consequences-body';
import { Styled } from './styled';


const TITLE = 'Subscription Cancellation';
const DESCRIPTION = `We're sad to see you go,
  but we want you to know what you'll be missing out on if you cancel your subscription`;

export const ConsequencesCancelSubscriptionComponent: React.FC = () => {
  return (
    <>
      <Styled.Header>
          <Styled.Icon>
            <Icons.CancelSubscription />
          </Styled.Icon>
          <Styled.Text>
            <Text fontSize={18}>
              {TITLE}
            </Text>
            <Text fontSize={14}>
              {DESCRIPTION}
            </Text>
          </Styled.Text>
      </Styled.Header>
      <ConsequencesBody />
    </>
  );
};

export const ConsequencesCancelSubscription = React.memo(ConsequencesCancelSubscriptionComponent);
