import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import './database-activity-variant-resource-based-crew-hours-material-selector.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { Checkbox, KreoDialog, KreoDialogActions } from 'common/UIKit';
import { ResourceType } from '../../enums';
import { UnitModel } from '../../interfaces/data';
import { MaterialModel } from '../../interfaces/resources-data';
import { ResourceSelector } from '../resource-selector';

export const CREW_HOURS_MATERIAL_SELECTOR_DIALOG = 'CREW_HOURS_MATERIAL_SELECTOR_DIALOG';

export interface DialogData {
  title: string;
  selectedMaterial: MaterialModel;
  onSelect: (material: MaterialModel) => void;
  unitId?: number;
}

interface DispatchProps {
  closeDialog: () => void;
}

interface StateProps {
  databaseId: number;
  isDialogOpen: boolean;
  dialogData: DialogData;
  unitMap: Record<number, UnitModel>;
}

interface Props extends StateProps, DispatchProps {
}

interface State {
  withUnitFilter: boolean;
}

export class DatabaseActivityVariantResourceBasedCrewHoursMaterialSelectorComponent
  extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { withUnitFilter: true };
  }

  public render(): JSX.Element {
    const dialogData = this.props.dialogData;
    const selectedResources = dialogData && dialogData.selectedMaterial
      ? [dialogData.selectedMaterial]
      : [];

    const filterFields = this.state.withUnitFilter && dialogData && dialogData.unitId
      ? { unitId: dialogData.unitId }
      : null;

    return (
      <KreoDialog
        name={CREW_HOURS_MATERIAL_SELECTOR_DIALOG}
        title={dialogData && dialogData.title}
      >
        {
          dialogData && dialogData.unitId && (
          <div className='database-activity-variant-resource-based-crew-hours-material-selector__unit-filter'>
            <Checkbox
              onChange={this.setWithUnitFilter}
              checked={this.state.withUnitFilter}
              label='The same unit as primary'
            />
          </div>)
        }
        <ResourceSelector
          databaseId={this.props.databaseId}
          name='Material'
          type={ResourceType.Material}
          selectedResources={selectedResources}
          onClose={this.props.closeDialog}
          onSave={this.onSave}
          isMultiselect={false}
          isOpen={this.props.isDialogOpen}
          filterFields={filterFields}
          unitMap={this.props.unitMap}
        />
      </KreoDialog>
    );
  }

  @autobind
  private setWithUnitFilter(withUnitFilter: boolean): void {
    this.setState({ withUnitFilter });
  }

  @autobind
  private getSelectedMaterialModel(resource: any): MaterialModel {
    const selectedMaterial = this.props.dialogData && this.props.dialogData.selectedMaterial;
    if (selectedMaterial && selectedMaterial.id === resource.id) {
      return selectedMaterial;
    }

    return this.getMaterialFromResource(resource);
  }

  @autobind
  private onSave(resources: any[]): void {
    const material = this.getSelectedMaterialModel(resources[0]);
    this.props.dialogData.onSelect(material);
    this.props.closeDialog();
  }

  private getMaterialFromResource(resource: any): MaterialModel {
    return {
      id: resource.id,
      name: resource.name,
      defaultUnitId: resource.defaultUnitId,
      variants: resource.variants,
    };
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    databaseId: state.database.currentDatabase.database.id,
    isDialogOpen: CREW_HOURS_MATERIAL_SELECTOR_DIALOG in state.dialog,
    dialogData: state.dialog[CREW_HOURS_MATERIAL_SELECTOR_DIALOG],
    unitMap: state.database.currentDatabase.unitMap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(CREW_HOURS_MATERIAL_SELECTOR_DIALOG)),
  };
};

export const DatabaseActivityVariantResourceBasedCrewHoursMaterialSelector =
  connect(mapStateToProps, mapDispatchToProps)(DatabaseActivityVariantResourceBasedCrewHoursMaterialSelectorComponent);
