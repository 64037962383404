import styled from 'styled-components';

import { QuickSearchStyled } from '../quick-search';
import { SpinnerStyled } from '../spinner';

export const QuickSearchHeight = 50;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;

  ${QuickSearchStyled.Input} {
    width: 100%;
  }

  .ag-drag-handle {
    display: none !important;
  }

  ${SpinnerStyled.SpinnerContainer} {
    height: 100%;
    left: 0;
  }
`;

export const Styled = {
  Container,
};
