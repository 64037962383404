import * as React from 'react';

import './floating-tree-panel-popup.scss';

import { IconButton, KreoButton, KreoIconCloseSmall } from 'common/UIKit';
import { MapIdHelper } from '../../../components/engine/map-id-helper';
import { QtoRecord } from '../../../units/projects/components/quantity-take-off-left-panel/interfaces';
import { FilterPanelState } from '../../../units/projects/components/quantity-take-off-left-table-tab/use-save-state';
import { GraphStorageRecordsConfig } from '../../../units/projects/interfaces/graph-storage-records-config';
import { TreeFilterPanelState } from './interfaces';
import { TreeFilterPanel } from './tree-filter-panel';

interface Props {
  displayNames: GraphStorageRecordsConfig;
  projectId: number;
  resetFilter: boolean;
  onFilter: (bimIds: number[], bimIdsWithoutEngineFilter: number[], value: boolean) => void;
  onClose: () => void;
  onClickResetButton: () => void;
  handleResetFilter: (value: boolean) => void;
  isLocation: boolean;
  filterName: string;
  records: QtoRecord[];
  useEngineFilter: boolean;
  saveState: (filterPanelState: TreeFilterPanelState, selectedIds: number[]) => void;
  getSaveState: () => FilterPanelState;
  getReportElementIds: () => { nodeIds: Record<string, boolean>, duplicatedNodeIds: Record<string, boolean> };
  disableEngineFilter: () => void;
  engineFilter: (id: number) => boolean;
  mapIdHelper: MapIdHelper;
}

export const FloatingTreePanelPopup: React.FC<Props> = (props) => {
  return (
    <div className='floating-tree-panel-popup'>
      <div className='floating-tree-panel-popup__header'>
        <div className='floating-tree-panel-popup__title'>
          Filter {props.isLocation ? 'Locations' : 'Elements'}
        </div>
        <IconButton
          size='small'
          className='floating-tree-panel-popup__header-close-button'
          controlName='floating-tree-panel-popup-close-button'
          onClick={props.onClose}
        >
          <KreoIconCloseSmall />
        </IconButton>
        <KreoButton
          size='small'
          rounded={true}
          caption='Reset filters'
          className='floating-tree-panel-popup__reset-button'
          controlName='reset-filters-button'
          onClick={props.onClickResetButton}
        />
      </div>
      <div className='floating-tree-panel-popup__body-wrap'>
        <div className='floating-tree-panel-popup__body'>
          {props.records.length > 0
            && <TreeFilterPanel
              engineFilter={props.engineFilter}
              useEngineFilter={props.useEngineFilter}
              onFilter={props.onFilter}
              projectId={props.projectId}
              displayNames={props.displayNames}
              resetFilter={{ value: props.resetFilter, callBack: props.handleResetFilter }}
              isLocation={props.isLocation}
              filterName={props.filterName}
              records={props.records}
              saveState={props.saveState}
              getSaveState={props.getSaveState}
              getReportElementIds={props.getReportElementIds}
              disableEngineFilter={props.disableEngineFilter}
              mapIdHelper={props.mapIdHelper}
            />}
        </div>
      </div>
    </div>

  );
};
