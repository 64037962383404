export const QUERIES = [
  'space',
  'GIA',
  'kitchen',
  'bedroom',
  'bathroom',
  'living room',
  'roof',
  'windows',
];

export const QUERIES_FULL_LIST = [
  'bathroom',
  'gia',
  'bedroom',
  'door',
  'window',
  'living room',
  'gea',
  'kitchen',
  'boh',
  'windows',
  'foh',
  'corridor',
  'stairs',
  'ceiling',
  'ensuite',
  'roof',
  'nia',
  'office',
  'store',
  'bed',
  'utility',
  'amenity',
  'plant',
  'circulation',
  'eifs',
  'carpet',
  'hall',
  'landing',
  'slab',
  'studio',
  'ceilings',
  'storage',
  'terrace',
  'gym',
  'render',
  'paving',
  'garage',
  'gifa',
  'lobby',
  'stair',
  'hallway',
  'siding',
  'lounge',
  'brick',
  'cupboard',
  'laundry',
  'reception',
  'balconies',
  'balcony',
  'cycle store',
  'riser',
  'brickwork',
  'lift',
  'study',
  'commercial',
  'bin store',
  'vinyl',
  'roof area',
  'cladding',
  'car park',
  'site area',
  'bath',
  'flat roof',
  'unit',
  'lift lobby',
  'lifts',
  'green roof',
  'floor finishes',
  'shower',
  'substation',
  'master bedroom',
  'wall',
  'female',
  'male',
  'retail',
  'room areas',
  'external walls',
  'flat',
  'floor area',
  'living',
  'stone',
  'pantry',
  'corridors',
  'slabs',
  'entrance',
  'access',
  'risers',
  'tile',
  'basement',
  'core',
  'glazing',
  'bathrooms',
  'soffit',
  'elevation',
  'meeting room',
  'doors',
  'room',
  'stair core',
  'dining',
  'soft landscaping',
  'entrance lobby',
  'planting',
  'flooring',
  'communal',
  'entrance hall',
  'main roof',
  'area pitch',
  'ballast',
  'bedrooms',
  'restaurant',
  'roof terrace',
  'external',
  'staircase',
  'bike store',
  'facade',
  'dining room',
  'ceiling finishes',
  'hard landscaping',
  'ramp',
  'plant room',
  'staff',
  'ext',
  'void',
  'tiles',
  'bar',
  'dda',
  'sidewalk',
  'patio',
  'cinema',
  'top out',
  'deduct',
  'lap',
  'screed',
  'kitchen dining',
  'resi',
  'paint',
  'badkamer',
  'total area',
  'ground',
  'duplicate',
  'landscaping',
  'walls',
  'apartments',
  'dressing',
  'decking',
  'refuse',
  'courtyard',
  'master bed',
  'airlock',
  'master ensuite',
  'column',
  'toilet',
  'panel',
  'circ',
  'road',
  'slaapkamer',
  'cafe',
  'parking',
  'porch',
  'dressing room',
  'surface bed',
  'rdc',
  'snug',
  'sol',
  'areas',
  'ees',
  'staff room',
  'guest bedroom',
  'kitchen/dining',
  'acc',
  'shower room',
  'entry',
  'private dining',
  'pool',
  'subtractions',
  'stairwell',
  'foyer',
  'total',
  'polished concrete',
  'new',
  'living area',
  'demolition',
  'library',
  'store room',
  'timber cladding',
  'sauna',
  'timber',
  'elevator',
  'refuse store',
  'drywall ceiling',
  'lgf',
  'act',
  'terraces',
  'floors',
  'grass',
  'bedroom ensuite',
  'panels',
  'detailing',
  'sitting room',
  'switch room',
  'comms',
  'mezz',
  'palier',
  'nichiha',
  'tbar',
  'plafond',
  'roofs',
  'powder room',
  'suite',
  'clg',
  'pavement',
  'changing',
  'deck',
  'stucco',
  'side',
  'linens',
  'tarmac',
  'foh circulation',
  'int stud',
  'insulation',
  'cleaners store',
  'curtain walling',
  'perimeter',
  'green',
  'external works',
  'driveway',
  'footpath',
  'loading bay',
  'walkway',
  'tiling',
  'concrete sidewalk',
  'rear gea',
  'demo',
  'wcs',
  'phase',
  'cycle storage',
  'master bath',
  'existing',
  'floor finish',
  'wardrobe',
  'keuken/leefruimte',
  'rooflight',
  'rear',
  'rooms',
  'games room',
  'floor tile',
  'turf',
  'carpark',
  'wellness',
  'total sqft',
  'area sqm',
  'pile cap',
  'master bathroom',
  'units',
  'unknown',
  'substructure',
  'amenities',
  'skirting',
  'roof light',
  'changing room',
  'carpet tile',
  'metal panel',
  'hard',
  'bureau',
  'sealed concrete',
  'concrete',
  'new paving',
  'dalles',
  'cloakroom',
  'side gia',
  'thk',
  'site',
  'salon',
  'blockwork',
  'cleaners',
  'aving',
  'family room',
  'toothing',
  'treatment room',
  'gravel',
  'side gea',
  'lobbies',
  'timber flooring',
  'mep',
  'bulkhead',
  'utility cupboard',
  'copro',
  'entrance matting',
  'accessible',
  'powder',
  'sho',
  'topsoil',
  'paving type',
  'cloaks',
  'master',
  'partie courante',
  'cta',
  'gfa',
  'columns',
  'rear gia',
  'pitched roof',
  'surface courante',
  'kitchen living dining',
];
