import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './help-center.scss';

import { EnvironmentConfigurationProps } from 'common/environment/environment-configuration-props';
import { withEnvironmentConfiguration } from 'common/environment/with-environment-configuration';
import { State } from 'common/interfaces/state';
import { KreoDialogActions, KreoIconRelease, KreoIconTooltipHelp, KreoIconVideo } from 'common/UIKit';
import { HelpDialog } from '../../../../components/dialog/help-dialog';
import { DropMenu } from '../../../../components/drop-menu';
import { Item } from '../../../../components/drop-menu/item';
import { ItemWithCheckbox } from '../../../../components/drop-menu/item-with-checkbox';
import { UiToursName } from '../../../../ui-tour';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';

interface OwnProps {
  url: string;
}

interface DispatchState {
  companiesEmpty: boolean;
  disabledShowDialogList: string[];
  viewedReleaseNotesVersion: string;
  disableShowTourList: Record<string, boolean>;
}

interface DispatchProps {
  openDialog: () => void;
  onChangeShowStartup: () => void;
  updateViewedReleaseNotesVersion: (currentRelease: string) => void;
  onChangeShowCreateProjectTour: (name: string) => void;
}

interface Props extends DispatchState, DispatchProps, OwnProps, EnvironmentConfigurationProps { }

export const HELP_VIDEO_DIALOG_NAME = 'HELP_VIDEO_DIALOG_NAME';

class HelpCenterComponent extends Component<Props> {
  public render(): React.ReactNode {

    const { disabledShowDialogList, disableShowTourList } = this.props;

    if (this.props.companiesEmpty) {
      return null;
    }
    const disableShowDialog = this.props.onChangeShowStartup
      && disabledShowDialogList
      && disabledShowDialogList.includes(HELP_VIDEO_DIALOG_NAME);
    const isUnreadReleaseNotes = this.props.environmentConfiguration.release !== this.props.viewedReleaseNotesVersion;

    return (
      <div className={classNames('help-center', { 'help-center--active': isUnreadReleaseNotes })}>
        <div className='help-center__icon'>
          <KreoIconTooltipHelp />
        </div>
        <DropMenu>
          <Item
            title='Video Guide'
            clickEvent={this.props.openDialog}
            icon={<KreoIconVideo />}
          />
          <a href='https://www.kreo.net/news-takeoff' target='_blank'>
            <Item
              active={isUnreadReleaseNotes}
              title={`What's New`}
              clickEvent={this.onWhatsNew}
              icon={<KreoIconRelease />}
            />
          </a>
          <div className='drop-menu__item drop-menu__item--group'>
            <span className='drop-menu__item-title'>Demo Tours</span>
            <div className='drop-menu__item-container'>
              {Object.values(UiToursName).map(item => {
                const disabled = disableShowTourList && disableShowTourList[item];
                return (
                  <ItemWithCheckbox
                    key={item}
                    checked={!disabled}
                    title={item}
                    onCheckbox={this.onChangeShowCreateProjectTour}
                  />
                );
              })}
            </div>
          </div>
        </DropMenu>
        <HelpDialog
          title='Video Guide'
          src={this.props.url}
          video={true}
          name={HELP_VIDEO_DIALOG_NAME}
          onChangeShowStartup={this.props.onChangeShowStartup}
          disabledShowStartup={disableShowDialog}
          labelCheckbox='Do not show this again "startup QTO"'
        />
      </div>
    );
  }

  @autobind
  private onChangeShowCreateProjectTour(title: string): void {
    this.props.onChangeShowCreateProjectTour(title);
  }

  @autobind
  private onWhatsNew(): void {
    const currentRelease = this.props.environmentConfiguration.release;
    if (currentRelease !== this.props.viewedReleaseNotesVersion) {
      this.props.updateViewedReleaseNotesVersion(currentRelease);
    }
  }
}

const mapStateToProps = (state: State): DispatchState => {
  return {
    companiesEmpty: !state.account.companies,
    disabledShowDialogList: state.persistedStorage.disableShowDialogList,
    viewedReleaseNotesVersion: state.persistedStorage.viewedReleaseNotesVersion,
    disableShowTourList: state.persistedStorage.disableShowTourList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openDialog: () => dispatch(KreoDialogActions.openDialog(HELP_VIDEO_DIALOG_NAME)),
    onChangeShowStartup: () => dispatch(PersistedStorageActions.toggleDisableShowDialog(HELP_VIDEO_DIALOG_NAME)),
    updateViewedReleaseNotesVersion: (currentRelease) => dispatch(
      PersistedStorageActions.updateViewedReleaseNotesVersion(currentRelease),
    ),
    onChangeShowCreateProjectTour: (name) =>
      dispatch(PersistedStorageActions.toggleDisableTour(name)),
  };
};

export const HelpCenter = withEnvironmentConfiguration(
  connect(mapStateToProps, mapDispatchToProps)(HelpCenterComponent),
);
