import classNames from 'classnames';
import * as React from 'react';

import './checkable-entity.scss';

import { Toggle, ToggleSize } from '../../../../../components/toggle';

interface Props {
  checked: boolean;
  onCheck: (value: boolean) => void;
}

export class CheckableEntity extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    return (
      <div
        className={
          classNames('gantt-chart-page-header-checkable-entity', {
            checked: this.props.checked,
          })
        }
      >
        <Toggle
          toggleSize={ToggleSize.Small}
          checked={this.props.checked}
          onCheck={this.props.onCheck}
          checkedColor={'#5C8AE6'}
        />
        <span className='gantt-chart-page-header-checkable-entity__title'>
          {this.props.children}
        </span>
      </div>);
  }
}
