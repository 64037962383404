import { Icons, RoundButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { AVATAR_HEIGHT, Company } from './company';
import { Styled } from './styled';


interface CompanyItem {
  id: number;
  name: string;
  logo: string;
}

interface Props {
  companiesList: CompanyItem[];
  activeCompanyId: number;
  onCompanyClick: (companyId: number) => void;
  addCompanyClick?: () => void;
}

export const CompaniesMenu = React.memo<Props>(({
  companiesList,
  activeCompanyId,
  onCompanyClick,
  addCompanyClick,
}) => {
  const activeCompanyIndex = companiesList.findIndex(company => company.id === activeCompanyId);
  const borderTop = (Styled.GAP + AVATAR_HEIGHT) * activeCompanyIndex + Styled.CONTAINER_PADDING - 10;

  return (
    <Styled.Container>
      {companiesList.map(company => (
        <Company
          key={company.id}
          id={company.id}
          name={company.name}
          logo={company.logo}
          onCompanyClick={onCompanyClick}
        />
      ))}
      {addCompanyClick &&
        <RoundButton
          diameter={'30'}
          backgroundColor={'pale'}
          Icon={Icons.PlusSmall}
          onClick={addCompanyClick}
          iconHeight={10}
        />
      }
      <Styled.Border top={borderTop} />
    </Styled.Container>
  );
},
);
