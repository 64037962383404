import React, { useMemo } from 'react';

import { DataBaseTable, RowData, useGridApiState } from 'common/components/data-base-table';
import { QuickSearchInput } from 'common/components/quick-search';
import { getRowNodeId } from './helpers/get-row-node-id';
import {
  useDragEndCallback,
  useTransactionEffect,
  useRowSelectedCallback,
  useChangeNameCallback,
  useRowClickedCallback,
  useExtendContextMenuItem,
  useDeleteCallback,
  useQuickSearch,
} from './hooks';
import { Props } from './interface';

const columns = [];
const autoGroupName = 'Files';
const emptyRowData: RowData[] = [];
const COLUMNS_MENU = [
  'expandAll',
  'contractAll',
];

export const Body = ({
  files,
  handleDragRow,
  changeName,
  handleRowClicked,
  canEdit,
  handleDelete,
  addFolder,
  addFile,
}: Props): JSX.Element => {
  const [saveGridApiRef, gridApi] = useGridApiState();
  const [handleRowSelected] = useRowSelectedCallback();
  const [handleDragEnd] = useDragEndCallback(handleDragRow, gridApi, canEdit);
  const [handleChageName] = useChangeNameCallback(changeName, canEdit);
  const [handleRowClick] = useRowClickedCallback(handleRowClicked);
  const [handleDeleteCallback] = useDeleteCallback(gridApi, handleDelete);
  const [extendContextMenuItems] = useExtendContextMenuItem(
    canEdit,
    handleDeleteCallback,
    addFolder,
    addFile,
  );
  const [handleChangeSearchValue] = useQuickSearch(gridApi);
  useTransactionEffect(files, gridApi);
  const defaultColumns = useMemo(() => {
    return [{
      field: 'rowIndex',
      sort: 'asc',
      hide: true,
    }];
  }, []);

  return (
    <>
      <QuickSearchInput
        placeholder={'Start typing...'}
        isQuickSearchVisible={true}
        setQuickSearchValue={handleChangeSearchValue}
        tooltipText={'Clear'}
      />
      <DataBaseTable
        rowData={emptyRowData}
        columns={columns}
        selectByCheckbox={canEdit}
        suppressRowClickSelection={canEdit}
        autoGroupName={autoGroupName}
        onRowSelected={handleRowSelected}
        enableRowDrag={canEdit}
        onGridReady={saveGridApiRef}
        enableMultiSelect={canEdit}
        onDragEnd={handleDragEnd}
        getRowNodeId={getRowNodeId}
        onChangeElementName={handleChageName}
        isEditElement={canEdit}
        onRowClicked={handleRowClick}
        extendContextMenuItem={extendContextMenuItems}
        hideColumnsMenuTab={true}
        autoGroupColumnMaxWidth={390}
        autoGroupColumnMinWidth={390}
        columnsMenu={COLUMNS_MENU}
        defaultColumns={defaultColumns}
      />
    </>
  );
};
