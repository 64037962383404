import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { ShortPointDescription } from '../../interfaces';
import { useRun } from './use-run';

export function useRerun(query: string): void {
  const runSearch = useRun();
  const searchZone = useSelector<State, ShortPointDescription[]>(state => state.drawings.magicSearch.searchArea);
  const dpi = useSelector<State, number>(state => state.drawings.magicSearch.dpi);
  useEffect(() => {
    if (query) {
      runSearch(query, dpi);
    }
  }, [dpi, searchZone, query, runSearch]);
}
