import { DrawingsMeasureType } from 'common/components/drawings';
import { DrawingsGeometryType } from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingsGeometryHighOrderEntity } from './drawings-geometry-entity';

export enum StartPoint {
  Start = 'start',
  End = 'end',
}

export interface DrawingsGeometryEntityContinuable
  <
    T extends DrawingsGeometryType = DrawingsGeometryType,
    K extends DrawingsMeasureType = DrawingsMeasureType
  > extends DrawingsGeometryHighOrderEntity<T, K> {
  continueEnabled: boolean;
  updateContinueTempPoint(p: paper.Point, startPoint?: StartPoint): void;
}
