import { ActionWith } from 'common/interfaces/action-with';
import { UpdateVisibilityPayload } from '../payloads/instances-visibility';
import { DrawingsInstancesVisibilityActionTypes } from '../types/instances-visibility';


function updateVisibility(
  instancesToShow: string[],
  instancesToHide: string[],
  shouldSave: boolean,
): ActionWith<UpdateVisibilityPayload> {
  return {
    type: DrawingsInstancesVisibilityActionTypes.UPDATE_VISIBILITY,
    payload: {
      instancesToHide,
      instancesToShow,
      shouldSave,
    },
  };
}

export const InstancesVisibilityActions = {
  updateVisibility,
};
