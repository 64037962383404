import * as paper from 'paper';
import { Transform } from 'common/pdf/compare-document';
import { Point, ComparisonLine } from './interfaces';


function getLineLength(startPointX: number, startPointY: number, endPointX: number, endPointY: number): number {
  const deltaX = endPointX - startPointX;
  const deltaY = endPointY - startPointY;
  return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
}

// in viewer coordinates
function createMatrixForLineAlignment(
  sourceLineStartPoint: Point,
  sourceLineEndPoint: Point,
  targetLineStartPoint: Point,
  targetLineEndPoint: Point,
): Core.Math.Matrix {
  // https://math.stackexchange.com/questions/1544147/find-transform-matrix-that-transforms-one-line-segment-to-another
  // Observe order of operations in the visual diagram for accepted answer
  const sourceLineAngleToOrigin = Math.atan2(
    sourceLineEndPoint.y - sourceLineStartPoint.y,
    sourceLineEndPoint.x - sourceLineStartPoint.x,
  );
  const targetLineAngleToOrigin = Math.atan2(
    targetLineEndPoint.y - targetLineStartPoint.y,
    targetLineEndPoint.x - targetLineStartPoint.x,
  );

  // get line lengths so we know how much to scale source line by to match line length of target line
  let sourceLineLength = getLineLength(
    sourceLineStartPoint.x,
    sourceLineStartPoint.y,
    sourceLineEndPoint.x,
    sourceLineEndPoint.y,
  );
  sourceLineLength = sourceLineLength === 0 ? 1 : sourceLineLength;
  const targetLineLength = getLineLength(
    targetLineStartPoint.x,
    targetLineStartPoint.y,
    targetLineEndPoint.x,
    targetLineEndPoint.y,
  );
  let scaleFactor = targetLineLength / sourceLineLength;
  scaleFactor = scaleFactor === 0 ? 1 : scaleFactor;

  // order matters
  const transformationBuilder = new Core.Math.TransformationBuilder();
  transformationBuilder
    .translate(-sourceLineStartPoint.x, -sourceLineStartPoint.y)
    .scale(scaleFactor, scaleFactor)
    .rotate(targetLineAngleToOrigin - sourceLineAngleToOrigin, true)
    .translate(targetLineStartPoint.x, targetLineStartPoint.y);

  const matrix = transformationBuilder.getFinalTransform() as Core.Math.Matrix;
  return matrix;
}


const DEFAULT_ZOOM = 2;

function getLinesTransform(source: ComparisonLine, target: ComparisonLine): Transform {
  const matrix = createMatrixForLineAlignment(
    {
      x: target.x1 * DEFAULT_ZOOM * window.devicePixelRatio,
      y: target.y1 * DEFAULT_ZOOM * window.devicePixelRatio,
    },
    {
      x: target.x2 * DEFAULT_ZOOM * window.devicePixelRatio,
      y: target.y2 * DEFAULT_ZOOM * window.devicePixelRatio,
    },
    {
      x: source.x1 * DEFAULT_ZOOM * window.devicePixelRatio,
      y: source.y1 * DEFAULT_ZOOM * window.devicePixelRatio,
    },
    {
      x: source.x2 * DEFAULT_ZOOM * window.devicePixelRatio,
      y: source.y2 * DEFAULT_ZOOM * window.devicePixelRatio,
    },
  );
  const matrixArray = matrix.toTransform();
  const paperMatrix = new paper.Matrix(
    matrixArray[0],
    matrixArray[1],
    matrixArray[2],
    matrixArray[3],
    matrixArray[4],
    matrixArray[5],
  );

  const decomposedMatrix = paperMatrix.decompose() as any;
  return {
    translation: decomposedMatrix.translation,
    rotation: (decomposedMatrix.rotation * Math.PI) / 180,
    scale: decomposedMatrix.scaling.x,
  };
}

export const Alignment = {
  createMatrixForLineAlignment,
  getLinesTransform,
};
