import * as Ag from 'ag-grid-community';
import { arrayUtils } from 'common/utils/array-utils';
import { Column } from '../interfaces';
import { defaultColumnOrder } from './get-col-def';

const getSortValue = (column: Column): string => {
  const columnOrder = defaultColumnOrder[column.field];

  if (columnOrder) {
    return `0_${columnOrder}`;
  }

  return `${column.orderGroup}_${column.headerName}`;
};

export const sortColumn = (columns: Column[]): Ag.ColDef[] => {
  const result = columns.sort((a, b) => {
    const value = arrayUtils.localCompareWithNumber(getSortValue(a), getSortValue(b));
    return value;
  });

  return result;
};
