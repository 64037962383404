import { DrawingsGeometryGroup } from 'common/components/drawings';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import { calcAssign } from 'unit-2d-database/components/side-panel/components/measure-panel/helpers/calc-assign';
import { AssignedPia } from '../../interfaces';


export const getElementTotalAssign = (
  elementId: string,
  assign: Record<string, AssignedPia>,
  groups: DrawingsGeometryGroup[],
  aiAnnotation: DrawingsGeometryState,
): AssignedPia => {
  const element = aiAnnotation.geometry[elementId];
  const assignList = element.groupId
    ? getParentAssign(element.groupId, assign, groups)
    : [];


  if (assign[elementId]) {
    assignList.push(assign[elementId]);
  }

  return calcAssign(assignList);
};


export function getParentAssign(
  groupId: string,
  assignPia: Record<string, AssignedPia>,
  groups: DrawingsGeometryGroup[],
): AssignedPia[] {
  const assign = assignPia[groupId];
  const result = assign
    ? [assign]
    : [];

  const group = groups.find(g => g.id === groupId);

  if (!group) {
    return result;
  }

  return group.parentId
    ? [...getParentAssign(group.parentId, assignPia, groups), ...result]
    : result;
}
