import autobind from 'autobind-decorator';

export class RenewableExecutor {
  private func: () => void;
  private ms: number;
  private timer: number = null;
  private isRunning: boolean;

  constructor(func: () => void, ms: number) {
    this.func = func;
    this.ms = ms;
  }

  public start(): void {
    if (this.isRunning) {
      console.warn('Executor already started');
      return;
    }
    this.isRunning = true;
    this.setNewTimeOut();
  }

  public cancel(): void {
    if (this.isRunning) {
      this.isRunning = false;
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  public updateTime(ms: number): void {
    if (this.isRunning) {
      clearTimeout(this.timer);
    }
    this.ms = ms;
    this.setNewTimeOut();
  }


  @autobind
  private setNewTimeOut(): void {
    this.timer = setTimeout(this.execute, this.ms) as any;
  }

  @autobind
  private execute(): void {
    this.func();
    this.setNewTimeOut();
  }
}
