import * as React from 'react';

export const KreoIconSequenceBig: React.FC = () => {
  return (
    <svg
      width='40px'
      height='40px'
      viewBox='0 0 40 40'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons/40x40/Sequence' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          // eslint-disable-next-line max-len
          d='M21,21.7324356 L21,25.1707057 C22.1651924,25.5825421 23,26.6937812 23,28 C23,29.6568542 21.6568542,31 20,31 C18.3431458,31 17,29.6568542 17,28 C17,26.6937812 17.8348076,25.5825421 19,25.1707057 L19,21.7324356 C19.2941734,21.9026057 19.6357129,22 20,22 C20.3642871,22 20.7058266,21.9026057 21,21.7324356 Z M21.8479866,20.7661839 C21.9459348,20.5302049 22,20.2714163 22,20 C22,19.5063732 21.8211688,19.0545147 21.5247592,18.7056771 C22.2261825,18.309502 22.576376,17.4515033 23.0025767,15.7728008 C23.8670233,12.3680824 24.9019701,11 28,11 L28.1707057,11 C28.5825421,9.83480763 29.6937812,9 31,9 C32.6568542,9 34,10.3431458 34,12 C34,13.6568542 32.6568542,15 31,15 C29.6937812,15 28.5825421,14.1651924 28.1707057,13 L28,13 C26.0954321,13 25.6056596,13.6474238 24.9458671,16.2460887 C24.285895,18.8455609 23.5359844,20.2495645 21.8479866,20.7661839 Z M31,11 C30.4477153,11 30,11.4477153 30,12 C30,12.5522847 30.4477153,13 31,13 C31.5522847,13 32,12.5522847 32,12 C32,11.4477153 31.5522847,11 31,11 Z M20,27 C19.4477153,27 19,27.4477153 19,28 C19,28.5522847 19.4477153,29 20,29 C20.5522847,29 21,28.5522847 21,28 C21,27.4477153 20.5522847,27 20,27 Z'
          id='Combined-Shape'
          fill='#93ACC7'
          fillRule='nonzero'
        />
        <path
          // eslint-disable-next-line max-len
          d='M17.1707057,19 C17.5825421,17.8348076 18.6937812,17 20,17 C21.3062188,17 22.4174579,17.8348076 22.8292943,19 L28.1707057,19 C28.5825421,17.8348076 29.6937812,17 31,17 C32.6568542,17 34,18.3431458 34,20 C34,21.6568542 32.6568542,23 31,23 C29.6937812,23 28.5825421,22.1651924 28.1707057,21 L22.8292943,21 C22.4174579,22.1651924 21.3062188,23 20,23 C18.6937812,23 17.5825421,22.1651924 17.1707057,21 L11.8292943,21 C11.4174579,22.1651924 10.3062188,23 9,23 C7.34314575,23 6,21.6568542 6,20 C6,18.3431458 7.34314575,17 9,17 C10.3062188,17 11.4174579,17.8348076 11.8292943,19 L17.1707057,19 Z M31,19 C30.4477153,19 30,19.4477153 30,20 C30,20.5522847 30.4477153,21 31,21 C31.5522847,21 32,20.5522847 32,20 C32,19.4477153 31.5522847,19 31,19 Z M9,19 C8.44771525,19 8,19.4477153 8,20 C8,20.5522847 8.44771525,21 9,21 C9.55228475,21 10,20.5522847 10,20 C10,19.4477153 9.55228475,19 9,19 Z M20,19 C19.4477153,19 19,19.4477153 19,20 C19,20.5522847 19.4477153,21 20,21 C20.5522847,21 21,20.5522847 21,20 C21,19.4477153 20.5522847,19 20,19 Z'
          id='Combined-Shape'
          fill='#4D6B8C'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
