import * as React from 'react';

import { Text } from '../text';
import { ContainerProps, LINK_COLORS, Styled } from './styled';

export interface Props {
  id?: string;
  icon?: React.ComponentType;
  rightIcon?: boolean;
  text?: string;
  size?: 's' | 'm' | 'l';
  mood?: keyof typeof LINK_COLORS;
  width?: number;
  link?: string;
  onClick?: (e: React.MouseEvent) => void;
  fontSize?: number;
  fontColor?: string;
  fontWeight?: number;
}

const FONTS_BY_SIZE = {
  s: 12,
  m: 14,
  l: 18,
};

export const LinkComponent: React.FC<Props> = (props) => {
  const {
    id,
    icon: Icon,
    rightIcon,
    text,
    size,
    mood,
    width,
    link,
    onClick,
    fontSize,
    fontColor,
    fontWeight = 500,
  } = props;
  const MainContainer: React.ComponentType<ContainerProps & React.AnchorHTMLAttributes<any>> = link
    ? Styled.AContainer : Styled.DivContainer;
  const fontSizeToRender = fontSize
    ? fontSize
    : FONTS_BY_SIZE[size || 'm'];
  const fontColorToRender = fontColor || LINK_COLORS[mood] || 'gray';
  return (
    <MainContainer
      id={id}
      size={size}
      mood={mood}
      width={width}
      rightIcon={rightIcon}
      text={!!text}
      href={link}
      onClick={onClick}
    >
      {Icon && !rightIcon && <Icon />}
      {text && (
        <Text
          fontSize={fontSizeToRender}
          color={fontColorToRender}
          fontWeight={fontWeight}
          lineHeight={16}
        >
          {text}
        </Text>
      )}
      {Icon && rightIcon && <Icon />}
    </MainContainer>
  );
};

