import * as React from 'react';

import './database-table-add-variant-button.scss';

import { KreoIconAddPlus } from 'common/UIKit/icons';

interface Props {
  variantName: string;
}

export class DatabaseTableAddVariantButton extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='database-table-add-variant-button'>
        <div className='database-table-add-variant-button__icon'>
          <KreoIconAddPlus />
        </div>
        <div className='database-table-add-variant-button__text'>
          Create {this.props.variantName} Variant
        </div>
      </div>
    );
  }
}
