// eslint-disable-next-line import/no-unresolved
import { FillOperationParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';

import { ValueHelper } from 'common/utils/value-helper';
import { CellFillPatternHelperFactory } from './cell-fill-pattern-heleper-factory';
import { CellFillHelper } from './interfaces';

const getReferenceValues = (params: FillOperationParams): Array<string | number> | null => {
  const { event, initialValues } = params;
  const isNumberFirstValue = ValueHelper.isNumberValue(initialValues[0]);

  return initialValues.length === 1
    && (HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(event) && isNumberFirstValue || !isNumberFirstValue)
    ? [initialValues[0]?.type ? initialValues[0].data : initialValues[0]]
    : null;
};

const getIteratedReferenceNumber = (value: number, params: FillOperationParams): number => {
  const { direction, currentIndex } = params;
  const multiplier = direction === 'down' || direction === 'right' ? 1 : -1;
  const offset = 1 + currentIndex;

  return value + offset * multiplier;
};

const getNextValue = (params: FillOperationParams, referencedValues: Array<string | number>): number | string => {
  if (ValueHelper.isNumberValue(referencedValues[0])) {
    return getIteratedReferenceNumber(referencedValues[0] as number, params);
  }

  const referencedValue = referencedValues[0] as string;
  const patternHelper = CellFillPatternHelperFactory.getFillPatternHelper(referencedValue);
  const numberValue = patternHelper.getSequenceNumberFromPattern(referencedValue);
  const nextNumberValue = getIteratedReferenceNumber(numberValue, params);

  return patternHelper.getPatternValue(nextNumberValue);
};

export const IterateCellFill: CellFillHelper<string | number> = {
  getReferenceValues,
  getNextValue,
};
