import * as React from 'react';

import './validation-statistic-tab.scss';

import { DoughnutChart } from 'common/components/doughnut-chart';
import { KreoColors } from 'common/enums/kreo-colors';
import { ProjectValidationResultsStatistic } from '../../interfaces/project-validation-results';


interface Props {
  name: string;
  legendDescription: string;
  statistic: ProjectValidationResultsStatistic;
}


export const ValidationStatisticTab: React.FC<Props> = props => {
  let bad = 0;
  let good = 0;
  if (props.statistic) {
    bad = props.statistic.bad;
    good = props.statistic.good;
  }
  return (
    <div className='validation-statistic-tab'>
      <div className='validation-statistic-tab__description'>
        <div className='validation-statistic-tab__name'>
          {props.name}
        </div>
        <div className='validation-statistic-tab__legend'>
          <div className='validation-statistic-tab__legend-indicator'/>
          {props.legendDescription}
        </div>
      </div>
      <div className='validation-statistic-tab__chart'>
        <DoughnutChart
          size={100}
          indicatorColor={'#FFC642'}
          secondaryColor={KreoColors.f3}
          strokeWidth={10}
          full={bad + good}
          value={bad}
        />
      </div>
    </div>
  );
};
