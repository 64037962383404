import { calcYPos } from './utils';

export default function Viewport(el: any) {
	let size;
	this.el = el;

	const calculateSize = (): any => {
		return size = {
			top: calcYPos(el),
			height: el.clientHeight || el.innerHeight
		};
	};

	this.size = function(): any {
		return size || calculateSize();
	};

	this.getScrollTop = function(): any {
		let scrollTop = el.scrollTop;

		if (typeof scrollTop === 'undefined') {
			scrollTop = el.scrollY || el.pageYOffset;
		}

		return scrollTop;
	};

	this.reset = function() {
		size = null;
	};

	this.setScrollTop = (top: any): any => {
		el.scrollTop = top;
	}
}