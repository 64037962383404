import React from 'react';

import { RenderIf } from '../render-if';
import { Styled } from './styled';


interface Props {
  title?: string;
}

export const MenuGroupContainerWithTitle: React.FC<Props> = ({ children, title }) => {
  return (
    <Styled.Container>
      <RenderIf condition={!!title}>
        <Styled.Title
          color='turquoiseFont'
          textTransform='uppercase'
        >
          {title}
        </Styled.Title>
      </RenderIf>
      {children}
    </Styled.Container>
  );
};
