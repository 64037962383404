import { SubscriptionType } from 'common/constants/subscription';
import { AppUrls } from '../../routes/app-urls';
import { QuantityTakeOffNotificationKeys } from '../projects/notifications/keys/quantity-take-off';

const qtoUrlBuilderProvider = (data) => {
  return (subscriptionType: SubscriptionType) => {
    const projectId = data.projectId;
    const productUrls = subscriptionType === SubscriptionType.KreoPlan
      ? AppUrls.plan
      : AppUrls.qto3d;

    return productUrls.project.quantityTakeOff && productUrls.project.quantityTakeOff.url({ projectId });
  };
};

export const NotificationsUrlsBuilderProvider: Record<string, (data: any) => (productUrls: any) => string> = {
  [QuantityTakeOffNotificationKeys.QuantityTakeOffCalculationSuccess]: qtoUrlBuilderProvider,
  [QuantityTakeOffNotificationKeys.QuantityTakeOffReportReady]: qtoUrlBuilderProvider,
  [QuantityTakeOffNotificationKeys.QuantityTakeOffReportFailed]: qtoUrlBuilderProvider,
};
