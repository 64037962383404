import autobind from 'autobind-decorator';

import { UpdateCellData } from 'common/components/excel-table';
import { SheetUpdateCells } from '../../actions/payloads';
import { ReportPage } from '../../interfaces';
import { UpdatedCell } from '../get-ref-map';
import { cellStoreActionEventType, cellStoreActions } from './exted-with-cell-store';
import { CellDataController, ReportCellController } from './report-cell-controller';
import { CellDataStore, SheetDataStore } from './report-cell-data-store';

export class ChildReportCellController implements CellDataController {
  // private sendEvent: (type: string, value: any) => void = null;
  private cellStoreController: ReportCellController = null;

  constructor(
    _sendEvent: (type: string, value: any) => void,
    addEventHandler: (type: string, handler: (data: any) => void) => void,
    cellStoreController: ReportCellController,
  ) {
    // this.sendEvent = sendEvent;
    this.cellStoreController = cellStoreController;
    addEventHandler(cellStoreActionEventType, this.handleEvents);
  }

  public async initReportCellStores(projectId: string): Promise<void> {
    this.cellStoreController.initReportCellStores(projectId);
  }

  public async loadData(pages: ReportPage[], projectId: string, onFinish: (id: string) => void): Promise<void> {
    this.cellStoreController.loadData(pages, projectId, onFinish);
  }

  public updateCells(payload: Array<SheetUpdateCells<UpdateCellData[]>>, skipRefresh?: boolean): void {
    this.cellStoreController.updateCells(payload, skipRefresh);
    // this.sendUpdate(cellStoreActions.updateCells);
  }

  public updateCellDataOnFill(sheetId: string, columnId: string, rowId: string, data: string | number): void {
    this.cellStoreController.updateCellDataOnFill(sheetId, columnId, rowId, data);
  }

  public updateCellsByMeasure(): void {
    // this.sendUpdate(cellStoreActions.updateCellsByMeasure);
  }

  public updateCellsByRemoveInstancesIds(): void {
    // this.sendUpdate(cellStoreActions.updateCellsByRemoveInstancesIds);
  }

  public addNewRows(sheetId: string, count: number): void {
    this.cellStoreController.addNewRows(sheetId, count);
    // this.sendUpdate(cellStoreActions.addNewRows, sheetId);
  }

  public setColumnWidthBulck(sheetId: string, payload: Array<{ columnId: string, width: number }>): void {
    this.cellStoreController.setColumnWidthBulck(sheetId, payload);
    // this.sendUpdate(cellStoreActions.setColumnWidthBulck, sheetId);
  }

  public addNewColumns(sheetId: string, count: number): void {
    this.cellStoreController.addNewColumns(sheetId, count);
    // this.sendUpdate(cellStoreActions.addNewColumns, sheetId);
  }

  public updateCellValueWithDrawings(): void {
    // this.sendUpdate(cellStoreActions.updateCellsByMeasure);
  }

  public updateDynamicCells(dynamicGroups: Record<string, string[]>): void {
    this.cellStoreController.updateDynamicCells(dynamicGroups, true);
  }

  public onDrawingsLoaded(drawingsIds: string[], dynamicGroups: Record<string, string[]>): void {
    this.cellStoreController.onDrawingsLoaded(drawingsIds, dynamicGroups);
  }

  public selectSheetValue(sheetId: string, columnId: string, rowId: string): string | number {
    return this.cellStoreController.selectSheetValue(sheetId, columnId, rowId);
  }

  public selectCellData(sheetId: string, columnId: string, rowId: string): string {
    return this.cellStoreController.selectCellData(sheetId, columnId, rowId);
  }

  public selectSheetData(reportId: string): SheetDataStore {
    return this.cellStoreController.selectSheetData(reportId);
  }

  public getUpdatedCells(sheetId: string): UpdatedCell[] {
    return this.cellStoreController.getUpdatedCells(sheetId);
  }

  public getReportData(): CellDataStore {
    return this.cellStoreController.getReportData();
  }

  public isValidCell(cellId: string): boolean {
    return this.cellStoreController.isValidCell(cellId);
  }

  public deleteCellDataStore(sheetId: string): void {
    return this.cellStoreController.deleteCellDataStore(sheetId);
  }

  public dropState(): void {
    return this.cellStoreController.dropState();
  }

  public clearStore(): void {
    return this.cellStoreController.clearStore();
  }

  @autobind
  private handleEvents(data: any): void {
    const type = data.type;
    switch (type) {
      case cellStoreActions.updateCells: {
        this.cellStoreController.updateCellStore(data.value);
        if (data.payload[0]) {
          this.cellStoreController.refreshData(data.payload[1]);
        }
        break;
      }
      case cellStoreActions.updateCellsByMeasure: {
        this.cellStoreController.updateCellsByMeasure(data.payload[0]);
        break;
      }
      case cellStoreActions.updateCellsByRemoveInstancesIds: {
        this.cellStoreController.updateCellStore(data.value);
        break;
      }
      case cellStoreActions.updateCellValueWithDrawings: {
        this.cellStoreController.updateCellStore(data.value);
        break;
      }
      case cellStoreActions.updateDynamicCells: {
        this.cellStoreController.updateCellStore(data.value);
        break;
      }
      case cellStoreActions.onDrawingsLoaded: {
        this.cellStoreController.updateCellStore(data.value);
        break;
      }
      default: {
        console.error(`Wrong cell store event type ${type}`);
      }
    }
  }

  // private sendUpdate(type: string, sheetId?: string): void {
  //   const dataPayload = this.cellStoreController.getReportData();
  //   const valuePayload = this.cellStoreController.getValueStore();
  //   const dynamicPayload = this.cellStoreController.getDynamicStore();
  //   this.sendEvent(
  //     cellStoreActionEventType,
  //     {
  //       type,
  //       value: { dataPayload, valuePayload, dynamicPayload, sheetId },
  //     });
  // }
}
