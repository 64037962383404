import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';

import './measurements-revit-tree-item.scss';

import { MeasurementsActions } from '../../actions/creators/measurements';
import { RevitTreeBaseItem } from '../revit-tree-base-item';
import { RevitTreeErrorAmount } from '../revit-tree-error-amount';

interface OwnProps {
  id: number;
}

interface StateProps {
  searched: boolean;
  error: boolean;
  expandable: boolean;
  expanded: boolean;
  title: string;
  level: number;
  selected: boolean;
  errors: number;
  all: number;
  start: number;
  end: number;
}

interface DispatchProps {
  onExpand: (id: number) => void;
  showExtractor: (id: number) => void;
  selectNodeById: (id: number) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {
}

class MeasurementsRevitTreeItemComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const className = classNames('measurements-revit-tree-item', {
      'measurements-revit-tree-item--selected': this.props.selected,
      'measurements-revit-tree-item--searched': this.props.searched,
      'measurements-revit-tree-item--error': this.props.error && !this.props.expandable,
    });
    return (
      <RevitTreeBaseItem
        className={className}
        expandable={this.props.expandable}
        expanded={this.props.expanded}
        onExpand={this.onExpand}
        onDoubleClick={this.onExpand}
        title={this.props.title}
        level={this.props.level + 1}
        onClick={this.onClick}
      >
        {
          this.props.expandable &&
            <RevitTreeErrorAmount
              error={this.props.errors}
              all={this.props.all}
            />
        }
      </RevitTreeBaseItem>
    );
  }

  @autobind
  private onExpand(e: React.MouseEvent<HTMLDivElement> = null): void {
    if (e) {
      e.stopPropagation();
    }
    this.props.onExpand(this.props.id);
  }

  @autobind
  private onClick(): void {
    this.props.showExtractor(this.props.id);
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const item = state.measurements.modelBrowser[ownProps.id];
  const searchedItems = state.measurements.searchMode ? new Set(state.measurements.searchedNodes) : new Set();
  return {
    expandable: item.isExpandable,
    expanded: item.isExpanded,
    title: item.data.name,
    all: item.bimIds.end - item.bimIds.start,
    errors: state.measurements.errorLinks[ownProps.id],
    error: state.measurements.errorLinks[ownProps.id] > 0,
    selected: ownProps.id === state.measurements.selectedNodeId,
    searched: searchedItems.has(ownProps.id),
    level: item.level,
    ...item.bimIds,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    onExpand: id => dispatch(MeasurementsActions.expandItemById(id)),
    showExtractor: id => dispatch(MeasurementsActions.showExtractorByTreeId(id)),
    selectNodeById: id => dispatch(MeasurementsActions.selectTreeNode(id)),
  };
};


export const MeasurementsRevitTreeItem =
  connect(mapStateToProps, mapDispatchToProps)(MeasurementsRevitTreeItemComponent);
