import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { DrawingsActions } from '../../actions/creators/common';
import { useRendererApiContext } from '../../drawings-contexts';
import { DrawingsDrawMode } from '../../enums';
import { DrawingGeometryOperationType } from '../../enums/drawing-geometry-operation-type';
import { DrawingsGeometryInstance, DrawingsSelectAggregationGroup } from '../../interfaces';
import { DrawingsMenuUtils } from '../../utils/drawings-menu-utils';
import { useDrawModeApi } from '../use-draw-mode-api';

export function useStartBooleanOperation(
  instancesIds: string[],
  aggregationType: DrawingsSelectAggregationGroup,
  drawMode: DrawingsDrawMode,
): () => void {
  const { setDrawMode } = useDrawModeApi();
  const { rendererApi } = useRendererApiContext();
  const geometry = useSelector<State, Record<string, DrawingsGeometryInstance>>(x => x.drawings.aiAnnotation.geometry);
  const setOperationIds = useActionDispatch(DrawingsActions.setOperationIds);

  return useCallback(() => {
    setDrawMode(drawMode, {
      afterSave: () => {
        const ids = instancesIds.filter(
          x => DrawingsMenuUtils.getAggregationType(geometry[x]) === aggregationType,
        );
        setOperationIds(ids);
        rendererApi.setIdsForOperation(ids, DrawingGeometryOperationType.Boolean);
      },
    });
  }, [instancesIds, aggregationType, drawMode, rendererApi, geometry, setDrawMode, setOperationIds]);
}
