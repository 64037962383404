import React, { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { MagicSearchActions } from 'common/components/drawings/actions/creators/magic-search';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { WizzardStatus } from 'common/components/drawings/enums/dropper-state';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { AutoMeasureAiMenu } from 'common/components/drawings/layout-components/auto-measure-ai';
import { MagicSearchResults } from 'common/components/drawings/layout-components/magic-search-results';
import { RenderIf } from 'common/components/render-if';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { Menu } from 'unit-2d-info-panel/constants';
import { useOpenMenu } from 'unit-2d-info-panel/hooks/use-open-menu';


const AutoMeasureAIViewComponent: React.FC = () => {
  const { setDrawMode, drawMode } = useDrawModeApi();
  const changeView = useOpenMenu(Menu.Default);
  const onCloseMenu = useCallback(() => {
    setDrawMode(DrawingsDrawMode.Disabled, { afterSave: changeView });
  }, [ setDrawMode, changeView ]);
  const isAutoMeasure = drawMode === DrawingsDrawMode.AutoMeasure2 || drawMode === DrawingsDrawMode.MagicSearchArea;

  useEffect(() => {
    if (!isAutoMeasure) {
      changeView();
    }
  }, [isAutoMeasure, changeView]);

  const status = useSelector<State, WizzardStatus>(state => state.drawings.magicSearch.status);
  const specifySearchZone = useSelector<State, boolean>(state => state.drawings.magicSearch.searchAreaSpecifyMode);
  const query = useSelector<State, string>(state => state.drawings.magicSearch.query);
  const setQuery = useActionDispatch(MagicSearchActions.setQuery);
  useEffect(() => {
    return () => {
      setQuery('');
    };
  }, []);

  if (specifySearchZone) {
    return null;
  }

  return (
    <>
      <AutoMeasureAiMenu
        reset={onCloseMenu}
        status={status}
        query={query}
        setQuery={setQuery}
      />
      <RenderIf condition={status === WizzardStatus.Preview}>
        <MagicSearchResults query={query}/>
      </RenderIf>
    </>
  );
};

export const AutoMeasureAIView = React.memo(AutoMeasureAIViewComponent);
