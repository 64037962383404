import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { DrawingsAnnotationLegendActions } from '../../actions/creators/drawings-annotation-legend';
import { DrawingsUpdateActions } from '../../actions/creators/update';
import { CreateDrawingGroupsTree } from '../../actions/payloads/drawings-annotation-legend';
import { DrawingGroupChanges } from '../../actions/payloads/update';
import { DrawingChangeOperation } from '../../enums/drawing-change-operation';
import { DrawingChangeSource } from '../../enums/drawing-change-source';

export function useAddGroups(): (data: CreateDrawingGroupsTree) => void {
  const dispatch = useDispatch();

  return useCallback((groups: CreateDrawingGroupsTree) => {
    dispatch(DrawingsAnnotationLegendActions.addGroups(groups));
    const groupChange: DrawingGroupChanges = {
      operation: DrawingChangeOperation.Create,
      data: { groups: groups.groups, measurements: [] },
      pia: groups.pia,
    };
    dispatch(DrawingsUpdateActions.commitUpdates([groupChange], DrawingChangeSource.Groups));
  }, [dispatch]);
}
