import * as React from 'react';

export const KreoIconTopPeople: React.FC = () => {
  return (
    <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='icon_databases' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect id='Rectangle' x='0' y='0' width='20' height='20' />
        <path
          // eslint-disable-next-line max-len
          d='M10.1342121,10.3506591 C9.09232146,10.792421 7.90767854,10.792421 6.86578787,10.3506591 L6.4,10.7 C4.88916494,11.8331263 4,13.6114562 4,15.5 C4,16.0522847 3.55228475,16.5 3,16.5 C2.44771525,16.5 2,16.0522847 2,15.5 C2,12.9819416 3.18555326,10.6108351 5.2,9.1 L5.2150492,9.0887131 C3.91822147,7.4473182 4.02750281,5.05828362 5.54289322,3.54289322 C7.1760582,1.90972824 9.8239418,1.90972824 11.4571068,3.54289322 C11.6991872,3.78497368 11.9053847,4.04934902 12.075699,4.3294098 C13.3537727,3.75555562 14.907597,3.99338343 15.9571068,5.04289322 C17.2656356,6.351422 17.3123964,8.44391285 16.0973894,9.80847212 C17.9083301,11.1216386 19,13.2327181 19,15.5 C19,16.0522847 18.5522847,16.5 18,16.5 C17.4477153,16.5 17,16.0522847 17,15.5 C17,13.694545 16.032584,12.0275134 14.4650101,11.1317569 L14.0997314,10.9230262 C13.9331718,10.9520594 13.7649022,10.9688993 13.5963589,10.9735458 C14.4995101,12.289889 15,13.8639261 15,15.5 C15,16.0522847 14.5522847,16.5 14,16.5 C13.4477153,16.5 13,16.0522847 13,15.5 C13,13.6114562 12.1108351,11.8331263 10.6,10.7 L10.1342121,10.3506591 Z M12.6760366,6.27641859 C12.7130089,6.97124368 12.5775005,7.67353407 12.2695115,8.31363655 C12.3231979,8.39464316 12.3857296,8.47151609 12.4571068,8.54289322 C13.0330808,9.11886724 13.9669192,9.11886724 14.5428932,8.54289322 C15.1188672,7.9669192 15.1188672,7.0330808 14.5428932,6.45710678 C14.0352566,5.94947016 13.2496368,5.88924077 12.6760366,6.27641859 Z M6.95710678,8.04289322 C7.80922318,8.89500961 9.19077682,8.89500961 10.0428932,8.04289322 C10.8950096,7.19077682 10.8950096,5.80922318 10.0428932,4.95710678 C9.19077682,4.10499039 7.80922318,4.10499039 6.95710678,4.95710678 C6.10499039,5.80922318 6.10499039,7.19077682 6.95710678,8.04289322 Z'
          id='icon'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
