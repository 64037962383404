import { flatten } from './flatten';

export function SUMIF(
  range: Array<string | number>,
  criteria: string | number,
  sumRange?: Array<string | number>,
): number {
  let sum = 0;
  const arrCriteria = flatten(range);
  const arrValue = sumRange
    ? flatten(sumRange)
    : undefined;
  for (let i = 0; i < range.length; i++) {
    const cellValue = arrCriteria[i];
    if (evaluateCriteria(cellValue, criteria)) {
      const valueToAdd = Number(arrValue ? arrValue[i] : cellValue);
      sum += Number.isNaN(valueToAdd)
        ? 0
        : valueToAdd;
    }
  }
  return sum;
}

function evaluateCriteria(cellValue: string | number, criteria: string | number): boolean {
  if (typeof criteria === 'string') {
    const matches = criteria.match(/^(=|>|<|>=|<=|<>)(&?[\d\.]+|".*")$/);
    if (matches) {
      const [operator, operand] = matches.slice(1);
      const parsedOperand = isNaN(Number(operand)) ? operand.replace(/(^"|"$)/g, '') : Number(operand.replace('&', ''));
      switch (operator) {
        case '=':
          return cellValue === parsedOperand;
        case '>':
          return cellValue > parsedOperand;
        case '<':
          return cellValue < parsedOperand;
        case '>=':
          return cellValue >= parsedOperand;
        case '<=':
          return cellValue <= parsedOperand;
        case '<>':
          return cellValue !== parsedOperand;
        default:
          return false;
      }
    } else {
      const hasStarSymbol = criteria.includes('*');
      if (hasStarSymbol) {
        const [first, second] = criteria.split('*');
        const stringCellValue = cellValue.toString();
        return first && stringCellValue.startsWith(first) || second && stringCellValue.endsWith(second);
      } else {
        return cellValue === criteria;
      }
    }
  } else {
    return cellValue === criteria;
  }
}
