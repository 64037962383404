import { Icons, MenuItem, SmallMenuList } from '@kreo/kreo-ui-components';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RenderIf } from 'common/components/render-if';
import { SubscriptionType } from 'common/constants/subscription';
import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { Company } from '../../../units/account/interfaces/company';
import { Styled } from './styled';


interface StateProps {
  companies: Company[];
}

interface DispatchProps {
  goTo3DTakeoff: () => void;
  goToPlan: () => void;
  goToCostEstimate: () => void;
}

interface Props extends DispatchProps, StateProps, AbilityAwareProps {
  closeMenu: () => void;
}

class MenuSelectProductComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      companies,
      closeMenu,
      goTo3DTakeoff,
      goToPlan,
      goToCostEstimate,
      ability,
    } = this.props;
    let canGoToAnotherProject = false;
    const subscriptionsAvailability = {};
    if (companies) {
      for (const subscriptionType of Object.values(SubscriptionType)) {
        const isSubscriptionAvailable = companies.some(c => subscriptionType in c.subscriptions);
        subscriptionsAvailability[subscriptionType] = isSubscriptionAvailable;
        if (isSubscriptionAvailable) {
          canGoToAnotherProject = true;
        }
      }
    }
    const canEditSubscriptions = ability.can(Operation.Manage, Subject.Subscriptions);
    if (!canGoToAnotherProject && !canEditSubscriptions) {
      return null;
    }
    return (
      <Styled.Container>
        <SmallMenuList
          emergencePlace={'right top'}
          onExit={closeMenu}
        >
          <RenderIf condition={subscriptionsAvailability[SubscriptionType.Takeoff3d] || canEditSubscriptions}>
            <MenuItem
              Icon={Icons.OpenNewTab}
              text='Go to 3D Takeoff'
              onClick={goTo3DTakeoff}
              fontSize={16}
              withBorder={true}
            />
          </RenderIf>
          <RenderIf condition={subscriptionsAvailability[SubscriptionType.KreoPlan]}>
            <MenuItem
              Icon={Icons.OpenNewTab}
              text='Go to Plan'
              onClick={goToPlan}
              fontSize={16}
              withBorder={true}
            />
          </RenderIf>
          <RenderIf condition={subscriptionsAvailability[SubscriptionType.CostEstimate]}>
            <MenuItem
              Icon={Icons.OpenNewTab}
              text='Go to Cost estimate'
              onClick={goToCostEstimate}
              fontSize={16}
              withBorder={true}
            />
          </RenderIf>
        </SmallMenuList>
      </Styled.Container>
    );
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    companies: state.account.companies,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    goTo3DTakeoff: () => dispatch(push(AppUrls.qto3d.index.path)),
    goToPlan: () => dispatch(push(AppUrls.plan.index.path)),
    goToCostEstimate: () => dispatch(push(AppUrls.costEstimate.index.path)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const MenuSelectProduct = connector(withAbilityContext(MenuSelectProductComponent));
