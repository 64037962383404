import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import React from 'react';

import { arrayUtils } from 'common/utils/array-utils';
import { getTransaction } from 'unit-2d-database/helpers/with-row-data';
import { DataBaseTable, RowData } from '../data-base-table';
import { getDataPath } from '../data-base-table/data-base-table';
import { QuickSearchInput } from '../quick-search';
import { RenderIf } from '../render-if';
import { Spinner } from '../spinner';
import { Styled } from './styled';

type SelectedEntityMap = Record<string, boolean> | Record<string, Record<string, boolean>>;

export interface Props {
  rowData: RowData[];
  autoGroupName: string;
  selectedEntityMap: SelectedEntityMap;
  onSelect: (ids: string[]) => void;
  fieldForQuickSearch: string[];
  autoGroupColumnMinWidth?: number;
  hideTable?: boolean;
}

const COLUMNS_MENU = [
  'autoSizeThis',
  'autoSizeAll',
  'separator',
  'resetColumns',
  'expandAll',
  'contractAll',
];

export class DataBaseSelectorTable extends React.PureComponent<Props> {
  private gridApi: Ag.GridApi;
  private COLUMNS: Ag.ColDef[] = [{
    colId: 'id',
    field: 'id',
    hide: true,
    getQuickFilterText: () => '',
  },
  ...this.props.fieldForQuickSearch.map(f => ({
    colId: f,
    field: f,
    hide: true,
  })),
  ];
  private quickSearchValue: string = '';
  private rowDataMap: Record<string, RowData> = {};

  public componentDidUpdate(prevProps: Props): void {
    if (this.gridApi
      && (prevProps.selectedEntityMap !== this.props.selectedEntityMap
        || prevProps.rowData !== this.props.rowData)
    ) {
      this.applyAndFilterRowData();
    }
  }

  public render(): React.ReactNode {
    const { autoGroupName, autoGroupColumnMinWidth = 357, hideTable } = this.props;
    return (
      <Styled.Container>
        <QuickSearchInput
          placeholder={'Start typing...'}
          isQuickSearchVisible={true}
          setQuickSearchValue={this.setQuickSearchValue}
          tooltipText={'Clear'}
        />
        <RenderIf condition={!hideTable}>
          <DataBaseTable
            columns={[]}
            defaultColumns={this.COLUMNS}
            rowData={[]}
            selectByCheckbox={true}
            suppressRowClickSelection={true}
            onRowSelected={this.onRowSelected}
            autoGroupName={autoGroupName}
            onGridReady={this.saveGridApi}
            hideColumnsMenuTab={true}
            columnsMenu={COLUMNS_MENU}
            sort={'asc'}
            autoGroupColumnMinWidth={autoGroupColumnMinWidth}
          />
        </RenderIf>
        <RenderIf condition={hideTable}>
          <Spinner show={hideTable} />
        </RenderIf>
      </Styled.Container>);
  }

  @autobind
  private setQuickSearchValue(value: string): void {
    const filterModel = this.gridApi.getFilterModel();
    const entityMap = this.props.selectedEntityMap;
    const emptyGroupIds = this.getEmptyGroupId(entityMap);
    const withoutEmpty = arrayUtils.filterIterator(
      this.props.rowData,
      d => !entityMap[d.id as string] && !emptyGroupIds.has(d.id as string),
    );
    const newFilterModel = {
      ...filterModel,
      ['id']: {
        type: 'set',
        values: this.applyFilterQuickSearch(withoutEmpty, value),
      },
    };
    this.gridApi.setFilterModel(newFilterModel);
    this.gridApi.deselectAll();
    this.quickSearchValue = value;
  }

  private applyFilterQuickSearch(rowData: Iterable<RowData>, value: string): string[] {
    const passedByFilterIds = new Set<string>();
    const ids = [];
    for (const row of rowData) {
      const path = getDataPath(row);
      if (path.some(p => passedByFilterIds.has(p))) {
        passedByFilterIds.add(row.id as string);
        ids.push(row.id as string);
        continue;
      }
      if (this.filterRowData(row, value.toLowerCase().split(' '))) {
        passedByFilterIds.add(row.id as string);
        ids.push(row.id as string);
      }
    }
    return ids;
  }

  private filterRowData(rowData: RowData, filterValue: string[]): boolean {
    const lowerCasedName = (rowData.name as string).toLowerCase();

    return filterValue.every(v => lowerCasedName.includes(v.toLowerCase()))
      || this.props.fieldForQuickSearch.length && this.props.fieldForQuickSearch.every(f => {
        const value = (rowData[f] as string)?.toLowerCase();
        return filterValue.every(v => value?.includes(v.toLowerCase()));
      });
  }

  @autobind
  private applyAndFilterRowData(): void {
    const { transaction, rowDataMap } = getTransaction(this.rowDataMap, this.props.rowData);
    this.rowDataMap = rowDataMap;
    this.gridApi.applyTransaction(transaction);
    this.setQuickSearchValue(this.quickSearchValue);
  }

  private getEmptyGroupId(filteredIds: SelectedEntityMap): Set<string> {
    const ids = new Set<string>();
    this.gridApi.forEachLeafNode(n => {
      if (n.data
        && n.data.h_isDragTarget
        && !n.allLeafChildren.some(c => {
          return !c.data.h_isDragTarget && !filteredIds[c.data.id];
        })
      ) {
        ids.add(n.data.id);
      }
    });

    return ids;
  }

  @autobind
  private onRowSelected(): void {
    const { selectedEntityMap, onSelect } = this.props;
    const node = this.gridApi.getSelectedNodes()[0];
    if (node) {
      const ids = node.data && !selectedEntityMap[node.data.id]
        ? [node.data.id]
        : [];
      const childNodes = node.childrenAfterFilter;
      const extendIds = (nodes: Ag.RowNode[]): void => {
        nodes.forEach(n => {
          if (n.data && !selectedEntityMap[n.data.id]) {
            ids.push(n.data.id);
          }
          extendIds(n.childrenAfterFilter);
        });
      };
      if (childNodes.length) {
        extendIds(childNodes);
      }
      onSelect(ids);
    }
  }

  @autobind
  private saveGridApi(api: Ag.GridApi): void {
    this.gridApi = api;
    setTimeout(this.applyAndFilterRowData, 0);
  }
}
