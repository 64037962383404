import autobind from 'autobind-decorator';

import { Property } from 'unit-2d-database/interfaces';
import { FieldInput } from '../components/item-panel/helpers';
import { Field } from '../components/item-panel/interfaces';
import { Updater } from './update-form-state';


export type PropertyField = Field<FieldInput>;
export type PropertyGroupForm = PropertyField[];

export type FieldHandlers = [
  (input: FieldInput) => void,
  () => void,
  (unitIndex: number) => void,
  () => void,
  (unitValue: string) => void,
];

export interface IPropertyFormUpdater {
  getFieldHandlers(propertyId: string, groupName: string): FieldHandlers;
}

export class PropertyUpdater extends Updater<PropertyGroupForm, PropertyField> implements IPropertyFormUpdater {
  public static getData(field: PropertyField): Property {
    return field.getDataUpdate(field);
  }

  public getFieldHandlers(propertyId: string): FieldHandlers {
    return [
      this.bindHandler(propertyId, this.applyChange, () => this.afterChange),
      this.bindHandler(propertyId, this.applyDelete, () => this.afterDelete),
      this.bindHandler(propertyId, this.applyUnitChange, () => this.afterUnitChange),
      this.bindHandler(propertyId, this.applyVisibilityChange, () => this.afterVisibilityChange),
      this.bindHandler(propertyId, this.applyUnitBlur, () => this.afterUnitChange),
    ];
  }

  @autobind
  protected override getElement(form: PropertyGroupForm, propertyId: string): PropertyField {
    return form.find(f => f.id === propertyId);
  }

  @autobind
  private applyChange(form: PropertyGroupForm, id: string, input: FieldInput): void {
    const field = this.getElement(form, id);
    field.input = input;
  }

  @autobind
  private applyDelete(form: PropertyGroupForm, id: string): void {
    const element = form.find(f => f.id === id);
    const index = form.indexOf(element);
    form.splice(index, 1);
  }

  @autobind
  private applyUnitChange(form: PropertyGroupForm, id: string, unitIndex: number): void {
    const field = this.getElement(form, id);
    field.dropDownProps.activeElementIndex = unitIndex;
    field.dropDownProps.value = field.dropDownProps.elements[unitIndex];
  }

  @autobind
  private applyUnitBlur(form: PropertyGroupForm, id: string, unitValue: string): void {
    const field = this.getElement(form, id);
    const index = field.dropDownProps.elements.indexOf(unitValue);
    if (index !== -1) {
      field.dropDownProps.activeElementIndex = index;
    }
    const cleanValue = unitValue.trim();
    if (!cleanValue) {
      return;
    }
    field.dropDownProps.value = cleanValue;
  }

  @autobind
  private applyVisibilityChange(form: PropertyGroupForm, id: string): void {
    const field = this.getElement(form, id);
    field.isHide = !field.isHide;
  }
}
