import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './elements-content.scss';

import { State } from 'common/interfaces/state';
import { Clipboard } from '../../../../../utils/clipboard';
import { ModelCheckPriority } from '../../../enums/mode-check-priority';
import { ModelCheckType } from '../../../enums/model-check-type';
import { BimHandeIdToRevitId, ErrorHandle, SelectedHandle } from '../interfaces/types';
import { BimHandleTile } from './bim-handle-tile';
import { ContentContainer, ScrollToType } from './content-container';
import { DoubleContent } from './double-content';


interface StateProps {
  errorHandles: ErrorHandle[];
  selectedHandle: SelectedHandle;
  secondHandle: SelectedHandle;
  categoryCritical: ModelCheckPriority;
  modelCheckType: ModelCheckType;
}

interface OwnProps {
  onSelectBimHandle: (id: number) => void;
  onSecondHandleSelect: (id: number) => void;
  saveScrollApi: (scrollTo: ScrollToType) => void;
}

interface Props extends StateProps, OwnProps {
}

class ElementsContentComp extends React.PureComponent<Props> {
  private type: boolean = true;

  public render(): any {
    const handles = this.props.errorHandles ? this.props.errorHandles : [];
    this.type = true;
    if (handles.length > 0 && handles[0].bimHandleId !== undefined) {
      this.type = false;
    }
    return (
      <div className='checker-elements-content'>
        {
          this.getHandles(this.type, handles)
        }
      </div>);
  }

  private getHandles(type: boolean, handles: any[], name: string = 'elements', second: boolean = false): JSX.Element {
    if (type) {
      let select = this.props.onSelectBimHandle;
      let selectedHandle = this.props.selectedHandle;
      if (second) {
        select = this.props.onSecondHandleSelect;
        selectedHandle = this.props.secondHandle;
      }
      const handleErrors = [];
      handles.forEach((handleError: ErrorHandle) => {
        (handleError.bimHandleIds as BimHandeIdToRevitId[]).forEach((el): void => {
          handleErrors.push(
            <BimHandleTile
              title={handleError.family}
              key={el.bimHandleId}
              id={el.bimHandleId}
              revitId={el.revitId}
              selected={selectedHandle && selectedHandle.bimHandleId === el.bimHandleId}
              onSelect={select}
            />);
        });
      });

      return (
        <ContentContainer
          getScrollApi={!second ? this.props.saveScrollApi : undefined}
          title={name}
          main={!second}
          categoryCritical={this.props.categoryCritical}
          onCopyClick={this.copyToClipBoard}
          modelCheckType={this.props.modelCheckType}
        >
          {handleErrors}
        </ContentContainer>
      );
    } else {
      if (this.props.selectedHandle !== null) {
        return (
          <DoubleContent
            top={
              <ContentContainer
                key={0}
                title={name}
                categoryCritical={this.props.categoryCritical}
                onCopyClick={this.copyToClipBoard}
                modelCheckType={this.props.modelCheckType}
                main={true}
                getScrollApi={this.props.saveScrollApi}
              >
                {
                  this.props.errorHandles.map((value, index) => {
                    return (
                      <BimHandleTile
                        key={index}
                        revitId={value.revitId}
                        selected={this.props.selectedHandle
                          && this.props.selectedHandle.bimHandleId === value.bimHandleId}
                        title={value.firstFamily}
                        id={value.bimHandleId}
                        onSelect={this.props.onSelectBimHandle}
                      />);
                  })
                }
              </ContentContainer>
            }
            bottom={
              this.getHandles(true, this.props.selectedHandle.bimHandleIds, 'Intersected elements', true)
            }
          />
        );
      }
      return (
        <ContentContainer
          getScrollApi={this.props.saveScrollApi}
          title={name}
          categoryCritical={this.props.categoryCritical}
          main={true}
          modelCheckType={this.props.modelCheckType}
          onCopyClick={this.copyToClipBoard}
        >
          {
            this.props.errorHandles.map((value, index) => {
              return (
                <BimHandleTile
                  key={index}
                  revitId={value.revitId}
                  selected={this.props.selectedHandle && this.props.selectedHandle.bimHandleId === value.bimHandleId}
                  title={value.firstFamily}
                  id={value.bimHandleId}
                  onSelect={this.props.onSelectBimHandle}
                />);
            })
          }
        </ContentContainer>
      );
    }
  }

  @autobind
  private copyToClipBoard(): void {
    const errorHandles = this.props.errorHandles;
    let revitIds: string[] = [];
    if (this.type) {
      errorHandles.forEach((value) => {
        const ids = (value.bimHandleIds as BimHandeIdToRevitId[]).map((pair) => pair.revitId);
        revitIds = revitIds.concat(ids);
      });
    } else {
      if (this.props.selectedHandle === null) {
        errorHandles.forEach((value) => {
          revitIds.push(value.revitId);
        });
      } else {
        const selected = this.props.selectedHandle;
        revitIds.push(selected.revitId);
        for (const secondId of (selected.bimHandleIds as ErrorHandle[])) {
          const ids = (secondId.bimHandleIds as BimHandeIdToRevitId[]).map((pair) => pair.revitId);
          revitIds = revitIds.concat(ids);
        }
      }
    }
    Clipboard.copy(revitIds.join(' '));
  }
}


function mapStateToProps(state: State): StateProps {
  const selected = state.modelcheck.selectedErrorCategory;
  return {
    errorHandles: state.modelcheck.errors[selected - 1],
    selectedHandle: state.modelcheck.selectedHandle,
    secondHandle: state.modelcheck.secondHandle,
    categoryCritical: state.modelcheck.issues[selected - 1].priority,
    modelCheckType: state.modelcheck.issues[selected - 1].modelCheckType,
  };
}


export const ElementsContent =
  connect<StateProps, {}, OwnProps>(mapStateToProps, null)
  (ElementsContentComp);
