import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { ConstantFunctions } from 'common/constants/functions';
import { KreoColors } from 'common/enums/kreo-colors';
import { ViewerApi } from '../../units/projects/api/viewer';
import { AnimationDataResponse } from '../../units/projects/interfaces/gantt-chart';
import { ChartDataProvider, GroupType } from '../../units/projects/utils/gantt-chart';
import { KreoEngine } from './KreoEngine';

export interface Props {
  projectId: number;
  ghostColor?: string;
  textures: any;
  onHandleClick?: (ids: number[], eventSource: Event | null) => void;
  sendEngineApi?: (engine: KreoEngine) => void;
  saveContainerRef?: (ref: HTMLDivElement) => void;
  animationData?: AnimationDataResponse;
  dataProvider?: ChartDataProvider;
  backgroundColor?: string;
  topLeftControl?: React.Component;
  initialSpeed?: number;
  geometry?: ArrayBuffer;
  onChangeTime?: (time: any) => void;
  onBimMenuClick?: (event: any) => void;
  onChangeRenderMode?: (renderModeString: any, idsArray: any) => void;
  onToggleClipBox?: (enabledBoolean: any, renderModeOutside: any) => void;
  onToggleRuler?: (enabledBoolean: boolean) => void;
  onChangeVisibility?: (value: boolean, ids: number[]) => void;
  onCameraMove?: () => void;
  onResize?: () => void;
  affterLoadAction?: () => void;
  onHover?: (ids: number[]) => void;
  toggleCameraParallel?: (value: boolean) => void;
}

export class Engine extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    onHandleClick: ConstantFunctions.doNothing,
    sendEngineApi: ConstantFunctions.doNothing,
    onChangeTime: ConstantFunctions.doNothing,
    onChangeVisibility: ConstantFunctions.doNothing,
    onChangeRenderMode: ConstantFunctions.doNothing,
    onBimMenuClick: ConstantFunctions.doNothing,
    onToggleClipBox: ConstantFunctions.doNothing,
    onToggleRuler: ConstantFunctions.doNothing,
    onCameraMove: ConstantFunctions.doNothing,
    onResize: ConstantFunctions.doNothing,
    affterLoadAction: ConstantFunctions.doNothing,
    backgroundColor: KreoColors.gray2,
    toggleCameraParallel: ConstantFunctions.doNothing,
  };

  private engineContainer: HTMLDivElement;
  private engine: KreoEngine;

  public componentDidMount(): void {

    const engine: KreoEngine = new KreoEngine(this.engineContainer, this.props.textures);
    this.props.sendEngineApi(engine);

    if (this.props.ghostColor) {
      engine.setGhostColor(this.props.ghostColor);
    }

    let geometryPromise: Promise<any> | null = null;
    if (this.props.geometry) {
      geometryPromise = Promise.resolve(this.props.geometry);
    } else {
      geometryPromise = ViewerApi.getBimGeometry();
    }

    geometryPromise.then(geometry => {
      engine.setBuilding(geometry).then(() => {
        this.props.affterLoadAction();
      });

      if (this.props.animationData) {
        engine.setRenderMode('animated');
        engine.togglePlaying(false);
      }

      engine.resize();

      if (this.props.animationData) {
        engine.setAnimationData(
          this.props.animationData,
          // should pass clors mapper from chart data provider
          id => this.props.dataProvider.getWorkGroupColors(GroupType.Activity, id).main);
        engine.addListener('timeline', this.props.onChangeTime);
        engine.toggleSkippingOffTime(false);
        if (this.props.initialSpeed) {
          engine.setAnimationSpeed(this.props.initialSpeed);
        }
      }
    });

    engine.addListener('bimMenuClick', this.props.onBimMenuClick);

    engine.addListener('rendermode', this.props.onChangeRenderMode);

    engine.addListener('selection', this.props.onHandleClick);
    engine.addListener('visibility', this.props.onChangeVisibility);
    engine.addListener('clipbox', this.props.onToggleClipBox);
    engine.addListener('rulerEnabled', this.props.onToggleRuler);
    engine.addListener('cameraMoved', this.props.onCameraMove);
    engine.addListener('isCameraParallel', this.props.toggleCameraParallel);

    if (this.props.onHover) {
      engine.addListener('hover', this.props.onHover);
    }

    if (this.props.backgroundColor) {
      engine.setBackgroundColor(this.props.backgroundColor);
    }

    this.engine = engine;
  }

  public shouldComponentUpdate(): boolean {
    return false;
  }

  public render(): React.ReactNode {
    return (
      <div
        className='engine'
        style={{ width: '100%', height: '100%', overflow: 'hidden' }}
      >
        <ReactResizeDetector
          handleHeight={true}
          handleWidth={true}
          onResize={this.onResize}
        />
        {
          this.props.topLeftControl !== undefined && this.props.topLeftControl
        }
        <div
          className='engine__container'
          style={{ width: '100%', height: '100%' }}
          ref={this.saveEngineContainerRef}
        />
      </div>);
  }

  public componentWillUnmount(): void {
    if (this.engine) {
      this.engine.dispose();
    }
  }

  @autobind
  private saveEngineContainerRef(ref: HTMLDivElement): void {
    this.engineContainer = ref;
    if (this.props.saveContainerRef) {
      this.props.saveContainerRef(ref);
    }
  }

  @autobind
  private onResize(): void {
    if (this.engine) {
      this.engine.resize();
      this.props.onResize();
    }
  }
}
