import { ShortPointDescription } from 'common/components/drawings/interfaces';
import { PdfRect } from 'common/components/drawings/interfaces/api-payloads';

function getPdfRect(area: ShortPointDescription[]): PdfRect {
  if (!area || !area.length) {
    return null;
  }
  return {
    positionA: area[0],
    positionB: area[2],
    fullyInside: true,
  };
}

export const WizzardUtils = {
  getPdfRect,
};
