import { isCommentData } from 'unit-2d-notification/store-slice/helpers';
import { CompanyNotification } from 'unit-2d-notification/store-slice/interfaces';

export const getGoToAction = (model: CompanyNotification, openProject: (projectId: string) => void): () => void => {
  const projectId = isCommentData(model.data)
    ? model.data.projectId.toString()
    : null;
  return () => {
    if (projectId) {
      openProject(projectId);
    }
  };
};
