import styled from 'styled-components';

const TOOLBAR_HEIGHT = 36;

const Container = styled.div`
  height: ${TOOLBAR_HEIGHT}px;
  display: flex;
  align-items: center;
  border-top: 1px solid ${p => p.theme.color.background};
  border-bottom: 1px solid ${p => p.theme.color.background};
`;

export const Styled = {
  Container,
  TOOLBAR_HEIGHT,
};
