import styled from 'styled-components';

const Container = styled.span<{ mood: string }>`
  position: relative;

  > button {
    > p {
      color: ${p => p.mood === 'disabled' && p.theme.color.background};
    }
  }
`;

export const Styled = {
  Container,
};
