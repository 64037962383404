import autobind from 'autobind-decorator';
import React from 'react';

import './work-package-item.scss';

import { KreoIconSubnavForward } from 'common/UIKit';


interface Props {
  id: number;
  name: string;
  ungroupedActivitiesCount: number;
  onClick: (workPackageId: number) => void;
}

export class WorkPackageItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div
        className='work-package-item'
        onClick={this.clickOnWorkPackage}
        data-id={this.props.id}
      >
        <div className='work-package-item__content'>
          <div className='work-package-item__name'>
            {this.props.name}
          </div>
          {
            this.props.ungroupedActivitiesCount ? (
              <div className='work-package-item__ungrouped-activities-count-container'>
              <label className='work-package-item__ungrouped-activities-count'>
                {this.props.ungroupedActivitiesCount}
              </label>
            </div>
            ) : null
          }
        </div>
        <div className='work-package-item__forward-icon'>
            <KreoIconSubnavForward />
          </div>
      </div>
    );
  }

  @autobind
  private clickOnWorkPackage(): void {
    this.props.onClick(this.props.id);
  }
}
