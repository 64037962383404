import * as React from 'react';

export const KreoSnappingIcon = (): JSX.Element => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 640 640'
      width='24'
      height='24'
    >
      <defs>
        <path
          // eslint-disable-next-line max-len
          d='M131.46 181.29L163.1 212.93L194.61 244.44L225.88 213.17L257.15 181.9L225.52 150.27L194 118.75L162.73 150.02L131.46 181.29ZM194 208.27C193.39 208.27 186.89 202.38 179.65 195.15C178.77 194.27 174.36 189.85 166.41 181.91L180.15 168.17L194 154.32L207.74 168.05L221.59 181.91L208.47 195.03C199.32 203.85 194.49 208.27 194 208.27Z'
          id='m80HZRI1r'
        />
        <path
          // eslint-disable-next-line max-len
          d='M248.45 253.27L305.96 311.4L363.47 369.52L347.77 369.89L331.95 370.26L331.95 382.4L331.95 394.66L369.96 394.66L407.98 394.66L407.98 357.26L407.98 319.86L395.1 319.86L382.23 319.86L382.23 336.04L382.23 352.35C347.43 317.55 328.09 298.22 324.22 294.35C292.46 262.59 266.1 236.47 265.73 236.47C265.37 236.47 261.32 240.28 256.79 244.81C255.68 245.94 252.9 248.76 248.45 253.27Z'
          id='hUxCSkoJ2'
        />
        <path
          // eslint-disable-next-line max-len
          d='M407.98 457.82L439.25 489.09L470.52 520.36L501.79 489.09L533.06 457.82C514.52 439.27 504.22 428.97 502.16 426.91C485.12 409.87 470.89 395.89 470.53 395.89C470.16 395.89 455.93 409.87 438.89 426.91C434.77 431.03 424.46 441.33 407.98 457.82ZM497.5 457.81L483.65 471.67L469.91 485.41L456.42 471.92L442.93 458.43C451.03 450.33 455.52 445.83 456.42 444.93C463.78 437.45 470.16 431.44 470.52 431.44C470.89 431.44 477.15 437.34 484.38 444.69C486.13 446.44 490.5 450.81 497.5 457.81Z'
          id='ao3QxXfiG'
        />
      </defs>
      <g>
        <g>
          <g>
            <use
              xlinkHref='#m80HZRI1r'
              opacity='1'
              fill='currentColor'
              fillOpacity='1'
            />
            <g>
              <use
                xlinkHref='#m80HZRI1r'
                opacity='1'
                fillOpacity='0'
                stroke='currentColor'
                strokeWidth='1'
                strokeOpacity='0'
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref='#hUxCSkoJ2'
              opacity='1'
              fill='currentColor'
              fillOpacity='1'
            />
            <g>
              <use
                xlinkHref='#hUxCSkoJ2'
                opacity='1'
                fillOpacity='0'
                stroke='currentColor'
                strokeWidth='1'
                strokeOpacity='0'
              />
            </g>
          </g >
          <g>
            <use
              xlinkHref='#ao3QxXfiG'
              opacity='1'
              fill='currentColor'
              fillOpacity='1'
            />
            <g>
              <use
                xlinkHref='#ao3QxXfiG'
                opacity='1'
                fillOpacity='0'
                stroke='currentColor'
                strokeWidth='1'
                strokeOpacity='0'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
