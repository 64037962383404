export enum Filters {
  Mention = 'mention',
  Status = 'status',
}

export enum MentionFilterValues {
  All = 'all',
  ForYou = 'foryou',
}

export enum StatusFiltersValue {
  Open = 'open',
  Resolved = 'resolved',
}
