import * as React from 'react';

export const KreoIconPaste: React.FC = () => {
  return (
    <svg
      width='12px'
      height='14px'
      viewBox='0 0 12 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='paste_12x14' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='CreateAssembly'
          transform='translate(-268.000000, -182.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <g id='Actions' transform='translate(20.000000, 160.000000)'>
            <g
              id='Buttons/Medium/Primary/Round/Round-Copy'
              transform='translate(238.000000, 13.000000)'
            >
              <g id='Icons/16x16/Copy/Copy' transform='translate(8.000000, 8.000000)'>
                <path
                  // eslint-disable-next-line max-len
                  d='M9,4 C10.1045695,4 11,4.8954305 11,6 L11,13 C11,14.1045695 10.1045695,15 9,15 L4,15 C2.8954305,15 2,14.1045695 2,13 L2,6 C2,4.8954305 2.8954305,4 4,4 L9,4 Z M12,1 C13.1045695,1 14,1.8954305 14,3 L14,10 C14,11.1045695 13.1045695,12 12,12 L12,5 C12,3.9456382 11.1841222,3.08183488 10.1492623,3.00548574 L10,3 L5,3 C5,1.8954305 5.8954305,1 7,1 L12,1 Z M8.5,10 L4.5,10 C4.22385763,10 4,10.2238576 4,10.5 C4,10.7454599 4.17687516,10.9496084 4.41012437,10.9919443 L4.5,11 L8.5,11 C8.77614237,11 9,10.7761424 9,10.5 C9,10.2238576 8.77614237,10 8.5,10 Z M8.5,7 L4.5,7 C4.22385763,7 4,7.22385763 4,7.5 C4,7.77614237 4.22385763,8 4.5,8 L8.5,8 C8.77614237,8 9,7.77614237 9,7.5 C9,7.22385763 8.77614237,7 8.5,7 Z'
                  id='Shape'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
