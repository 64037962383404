import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './resource-limitations-row.scss';

import { KreoIconSelectArrow } from 'common/UIKit';
import { ResourcesTypes } from '../../../../constants';
import { ResourceLimitationsMenuType, WorkPackagesData } from '../../../scenarios';
import { ResourceLimitationsFieldsArea } from '../resource-limitations-fields-area';
import { ResourceLimitationsRowMenu } from '../resource-limitations-row-menu';
import { ResourceLimitationsRowSubContent } from '../resource-limitations-row-sub-content';

interface Props {
  item: WorkPackagesData.Management;
}

interface State {
  collapsed: boolean;
  activated: ResourceLimitationsMenuType;
}

export class ResourceLimitationsRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const hasLabour = this.props.item.limitations.some(l => l.type === ResourcesTypes.LABOR);

    this.state = {
      collapsed: true,
      activated: hasLabour ? ResourceLimitationsMenuType.Labour : ResourceLimitationsMenuType.Plant,
    };
  }

  public render(): JSX.Element {
    const { item } = this.props;
    const plant = this.props.item.limitations.filter(l => l.type === ResourcesTypes.PLANT);
    const labor = this.props.item.limitations.filter(l => l.type === ResourcesTypes.LABOR);
    const items: WorkPackagesData.ResourceLimitation[] = [];

    if (labor.length !== 0) {
      items.push({
        limitations: labor,
        name: 'Labour',
        index: 0,
        activated: this.state.activated === ResourceLimitationsMenuType.Labour,
      });
    }

    if (plant.length !== 0) {
      items.push({
        limitations: plant,
        name: 'Plant',
        index: 1,
        activated: this.state.activated === ResourceLimitationsMenuType.Plant,
      });
    }

    return (
      <div className={classNames('resource-limitations-row', { 'collapsed': this.state.collapsed })}>
        <div className='resource-limitations-row__line-container' onClick={this.collapseExpand}>
          <div className='resource-limitations-row__title'>
            <KreoIconSelectArrow />
            {item.name}
          </div>
          <ResourceLimitationsFieldsArea
            itemId={item.id}
            productivity={item.crewProductivity}
            shift={item.calendarIds}
            useAmdahl={item.useAmdahl}
          />
        </div>
        <ResourceLimitationsRowMenu
          items={items}
          collapsed={this.state.collapsed}
          setActivated={this.setActivated}
          activated={this.state.activated}
        />
        <ResourceLimitationsRowSubContent
          collapsed={this.state.collapsed}
          items={items}
          activeMenu={this.state.activated}
        />
      </div>
    );
  }

  @autobind
  private collapseExpand(): void {
    this.setState({ collapsed: !this.state.collapsed });
  }

  @autobind
  private setActivated(index: number): void {
    this.setState({ activated: index });
  }
}
