import React from 'react';

import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { WizzardStatus } from '../../../enums/dropper-state';
import { LoadingPopup } from '../loading-popup';
import { DropperResultMenu } from './dropper-result-menu';
import { DropperSettings } from './dropper-settings';

const DropperMenuComponent: React.FC = () => {
  const status = useSelector<State, WizzardStatus>(x => x.drawings.wizzardTools.wizzardState?.status);
  switch (status) {
    case WizzardStatus.Start:
      return (
        <DropperSettings/>
      );
    case WizzardStatus.Preview:
      return (
        <DropperResultMenu/>
      );
    case WizzardStatus.Loading:
      return (<LoadingPopup/>);
    default:
      return null;
  }
};

export const DropperMenu = React.memo(DropperMenuComponent);
