import { select } from 'd3-selection';

export const toggleTooltip = (event: MouseEvent, show: boolean): void => {
  const modal = document.querySelector('#modal-root');
  const tooltip = modal.querySelector('.dashboard-tooltip');
  if (!show && tooltip) {
    tooltip.remove();
    return;
  }
  const tipRect = tooltip.getBoundingClientRect();
  const gap = 20;
  const left = window.innerWidth - event.x < tipRect.width + gap
    ? event.x - (tipRect.width + 5)
    : event.x + gap;
  const top = window.innerHeight - event.y < tipRect.height + gap
    ? event.y - (tipRect.height + 5)
    : event.y + gap;
  select('.dashboard-tooltip').style('left', `${left}px`).style('top', `${top}px`);
};

export const createTooltip = (modal: any, title: string, text: string): void => {
  const content = `
            <div class="dashboard-tooltip__title">${title}</div>
            <div class="dashboard-tooltip__text">${text}</div>
          `;
  modal
    .append('div')
    .attr('class', 'dashboard-tooltip')
    .html(content);
};
