import { ElementTooltip, Text, Waves } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { ColorsName } from 'common/enums/kreo-colors';
import { Styled } from './styled';


interface Props {
  onClick: (sheetId: string, cellId: string) => void;
  sheetId: string;
  sheetName: string;
  cellId: string;
  isSelected: boolean;
}


export class TraceLinkCell extends React.PureComponent<Props> {

  public render(): JSX.Element {
    const { sheetName, cellId, isSelected } = this.props;

    return (
      <ElementTooltip
        text={sheetName}
        position='top'
        speed='l'
        disabled={sheetName.length < 12}
      >
        <Styled.Container onClick={this.onClick}>
          <Styled.Cell
            isSelected={isSelected}
          >
            {cellId}
          </Styled.Cell>
          <Text
            fontSize={12}
            withEllipsis={true}
          >
            {sheetName}
          </Text>
          <Waves color={ColorsName.turquoise} />
        </Styled.Container>
      </ElementTooltip>
    );
  }

  @autobind
  private onClick(): void {
    const { sheetId, cellId, onClick } = this.props;
    onClick(sheetId, cellId);
  }
}
