import React from 'react';
import { KreoIconAddPlus } from 'common/UIKit';

export const HeaderAddContainer: React.FC<{ action?: () => void }> = ({ action }) => {
  return (
    <div className="information-item__action" onClick={action}>
      <KreoIconAddPlus />
    </div>
  );
};
