import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';

import './permission-table-cell.scss';

import { PermissionActivity } from 'common/enums/permission-activity';
import { PermissionActivityDescription } from 'common/interfaces/account/permission-activity-description';
import { NumberDictionary } from 'common/interfaces/dictionary';
import { ExtendedPermission } from '../../interfaces/extended-permission';
import { PermissionActivityAccess } from '../../interfaces/permission-activity-access';
import { PermissionSelect } from '../permission-select';

interface Props {
  roleId: number;
  activity: PermissionActivity;
  permissions: NumberDictionary<NumberDictionary<ExtendedPermission>>;
  activities: PermissionActivityDescription[];
  onChange(
    access: PermissionActivityAccess,
    activity: PermissionActivity,
    roleId: number,
  ): void;
}

export class PermissionTableCell extends React.Component<Props> {
  public render(): React.ReactNode {
    const { permissions, roleId, activity, activities } = this.props;
    const permission: ExtendedPermission = permissions[roleId][activity];
    const className = classNames('permission-table-cell', {
      disabled: permission.isBlocked,
    });

    const dependantFromActivities: string[] = [];
    Object.keys(permissions[roleId]).forEach((activityKey: PermissionActivity) => {
      if (activityKey !== activity) {
        const extendedPermission: ExtendedPermission = permissions[roleId][activityKey];
        if (extendedPermission.activityAccess !== PermissionActivityAccess.NoAccess) {
          extendedPermission.relatedActivitiesAccess.forEach(x => {
            if (x.activity === activity) {
              const activityDescription = activities.find(act => act.activity === activityKey);
              if (activityDescription && !dependantFromActivities.includes(activityDescription.name)) {
                dependantFromActivities.push(activityDescription.name);
              }
            }
          });
        }
      }
    });

    return (
      <td className={className}>
        {!permission.isBlocked && (
          <PermissionSelect
            permission={permission}
            onChange={this.onChange}
            dependantFromActivities={dependantFromActivities}
          />
        )}
      </td>
    );
  }

  @autobind
  private onChange(access: PermissionActivityAccess): void {
    this.props.onChange(access, this.props.activity, this.props.roleId);
  }
}
