import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { getActiveNavigationType } from './get-fast-navigation-type-helpers';


interface OwnProps<T> {
  activeNavigationType: T;
}

interface Props<T> extends OwnProps<T>, RouteComponentProps<{}> { }

export const withActiveNavigationType = (
  Component: React.ComponentType<OwnProps<any>>): React.ComponentType<OwnProps<any>> => {
  class WithActiveNavigation<T> extends React.PureComponent<Props<T>> {
    public render(): JSX.Element {
      const activeNavigationType = getActiveNavigationType(this.props.location.search);

      return <Component {...this.props} activeNavigationType={activeNavigationType} />;
    }
  }
  return withRouter(WithActiveNavigation);
};
