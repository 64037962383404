import * as Ag from 'ag-grid-community';

import { Constants } from 'unit-2d-database/components/side-panel/components/property-panel/forms/tree-form/constants';


export class CodeInputCellEdit implements Ag.ICellEditorComp {
  private eGui: HTMLDivElement;
  private params: Ag.ICellEditorParams;
  private codeContainer: HTMLDivElement;
  private inputContainer: HTMLInputElement;

  public getValue(): string {
    return this.inputContainer.value;
  }

  public init(params: Ag.ICellEditorParams): void {
    this.params = params;

    this.eGui = document.createElement('div');
    this.eGui.className = 'code-cell-edit';

    this.createCode();
    if (this.codeContainer) {
      this.eGui.append(this.codeContainer);
    }
    this.createValue();
    this.eGui.append(this.inputContainer);

    setTimeout(() => this.inputContainer.select(), 1);
    this.inputContainer.setSelectionRange(params.value.length, params.value.length);
  }

  public getGui(): HTMLElement {
    this.params.context.isStopEditing = true;
    return this.eGui;
  }

  private createCode(): void {
    const container = document.createElement('div');
    container.className = 'code-cell-not-edit';
    const codeParts = this.params.data.code.split(Constants.codeSeparator);
    if (codeParts.length > 1) {
      codeParts.pop();
      const code = codeParts.join(Constants.codeSeparator);
      container.innerText = `${code + Constants.codeSeparator}`;
    } else {
      return;
    }

    this.codeContainer = container;
  }

  private createValue(): void {
    const input = document.createElement('input');
    input.className = 'input-cell-edit-value';

    const codeParts = this.params.value.split(Constants.codeSeparator);
    input.value = codeParts[codeParts.length - 1];
    this.inputContainer = input;
  }
}
