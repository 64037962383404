import * as React from 'react';
import { StaticContext } from 'react-router';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { AbilityRoute } from 'common/ability/ability-route';
import { Subject } from 'common/ability/subject';
import { NotFoundPage } from '../../../units/notfound/page';
import { PeoplePage } from '../../../units/people/page';
import { PermissionsPage } from '../../../units/people/pages/permissions-page';

interface Props extends RouteComponentProps<any, StaticContext> {
  urls: any;
}

export class Routes extends React.Component<Props> {
  public render(): React.ReactNode {
    const peopleUrls = this.props.urls;

    return (
      <Switch>
        <Route exact={true} path={peopleUrls.index.path} component={PeoplePage} />
        <AbilityRoute
          exact={true}
          path={peopleUrls.permissions.path}
          component={PermissionsPage}
          subject={Subject.Role}
          redirectUrl={peopleUrls.index.url()}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}
