import { Action } from 'redux';

import { FilterChangePayload, FilterData } from 'common/components/drawings/interfaces/drawing-filters';
import {
  DrawingsGroupTemplate,
  GroupTemplateShortInfo,
} from 'common/components/drawings/interfaces/drawings-group-template';
import { ActionWith } from 'common/interfaces/action-with';
import { GeometryGroupsResponse } from '../../interfaces';
import { AnnotationLegendItem } from '../../interfaces/annotation-legend-tree';
import {
  BulkGroupsChangePayload,
  CreateDrawingGroup,
  CreateDrawingGroupsResponse,
  CreateDrawingGroupsTree,
  DrawingsGroupUpdateUser,
  GroupTemplatePayload,
  MoveDrawingGroupTree,
  UpdateDrawingGroupTree,
  UpdateSelectionPayload,
} from '../payloads/drawings-annotation-legend';
import { DrawingsAnnotationLegendActionTypes } from '../types/drawings-annotation-legend';

function loadGroups(projectId: number): ActionWith<number> {
  return {
    type: DrawingsAnnotationLegendActionTypes.LOAD_GROUPS,
    payload: projectId,
  };
}

function setGroups(groups: GeometryGroupsResponse): ActionWith<GeometryGroupsResponse> {
  return {
    type: DrawingsAnnotationLegendActionTypes.SET_GROUPS,
    payload: groups,
  };
}

function setGroupLoadSuccess(): Action {
  return {
    type: DrawingsAnnotationLegendActionTypes.SET_GROUP_LOAD_SUCCESS,
  };
}

function updateGroups(
  data: UpdateDrawingGroupTree,
): ActionWith<UpdateDrawingGroupTree> {
  return {
    type: DrawingsAnnotationLegendActionTypes.UPDATE_GROUPS,
    payload: data,
  };
}

function moveGroups(
  data: MoveDrawingGroupTree,
): ActionWith<MoveDrawingGroupTree> {
  return {
    type: DrawingsAnnotationLegendActionTypes.MOVE_GROUPS,
    payload: data,
  };
}

function deleteGroups(ids: string[]): ActionWith<string[]> {
  return {
    type: DrawingsAnnotationLegendActionTypes.DELETE_GROUPS,
    payload: ids,
  };
}

function removeDrawingsFromGroups(ids: string[]): ActionWith<string[]> {
  return {
    type: DrawingsAnnotationLegendActionTypes.REMOVE_DRAWINGS_FROM_GROUPS,
    payload: ids,
  };
}

function addGroups(
  data: CreateDrawingGroupsTree,
): ActionWith<CreateDrawingGroupsTree> {
  return {
    type: DrawingsAnnotationLegendActionTypes.ADD_GROUPS,
    payload: data,
  };
}

function toggleOpen(id: string[]): ActionWith<string[]> {
  return {
    type: DrawingsAnnotationLegendActionTypes.TOGGLE_GROUP_OPEN,
    payload: id,
  };
}

function toggleAllOpen(isOpen: boolean): ActionWith<boolean> {
  return {
    type: DrawingsAnnotationLegendActionTypes.TOGGLE_ALL_GROUPS_OPEN,
    payload: isOpen,
  };
}

function saveFiltersId(ids: string[]): ActionWith<string[]> {
  return {
    type: DrawingsAnnotationLegendActionTypes.SAVE_FILTERED_ELEMENT_IDS,
    payload: ids,
  };
}

function setFilterData(payload: FilterData): ActionWith<FilterData> {
  return {
    type: DrawingsAnnotationLegendActionTypes.SET_FILTER_DATA,
    payload,
  };
}

function changeFilterData(payload: FilterChangePayload): ActionWith<FilterChangePayload> {
  return {
    type: DrawingsAnnotationLegendActionTypes.CHANGE_FILTER_DATA,
    payload,
  };
}


function loadGroupTemplates(): Action {
  return {
    type: DrawingsAnnotationLegendActionTypes.LOAD_GROUP_TEMPLATES,
  };
}

function setGroupTemplates(templates: DrawingsGroupTemplate[]): ActionWith<DrawingsGroupTemplate[]> {
  return {
    type: DrawingsAnnotationLegendActionTypes.SET_GROUP_TEMPLATES,
    payload: templates,
  };
}

function createGroupTemplate(
  templateInfo: GroupTemplateShortInfo,
): ActionWith<GroupTemplateShortInfo> {
  return {
    type: DrawingsAnnotationLegendActionTypes.CREATE_GROUP_TEMPLATE,
    payload: templateInfo,
  };
}

function applyGroupTemplate(templateId: number): ActionWith<number> {
  return {
    type: DrawingsAnnotationLegendActionTypes.APPLY_GROUP_TEMPLATE,
    payload: templateId,
  };
}

function updateGroupTemplate(
  templateId: number,
  templateInfo: GroupTemplateShortInfo,
): ActionWith<GroupTemplatePayload> {
  return {
    type: DrawingsAnnotationLegendActionTypes.UPDATE_GROUP_TEMPLATE,
    payload: { templateId, templateInfo },
  };
}

function updateGroupTemplateSuccess(
  templateId: number,
  templateInfo: GroupTemplateShortInfo,
): ActionWith<GroupTemplatePayload> {
  return {
    type: DrawingsAnnotationLegendActionTypes.UPDATE_GROUP_TEMPLATE_SUCCESS,
    payload: { templateId, templateInfo },
  };
}

function duplicateGroupTemplate(
  templateId: number,
  templateInfo: GroupTemplateShortInfo,
): ActionWith<any> {
  return {
    type: DrawingsAnnotationLegendActionTypes.DUPLICATE_GROUP_TEMPLATE,
    payload: { templateId, templateInfo },
  };
}

function addGroupTemplate(
  template: DrawingsGroupTemplate,
): ActionWith<DrawingsGroupTemplate> {
  return {
    type: DrawingsAnnotationLegendActionTypes.ADD_GROUP_TEMPLATE,
    payload: template,
  };
}

function deleteGroupTemplate(
  templateId: number,
): ActionWith<number> {
  return {
    type: DrawingsAnnotationLegendActionTypes.DELETE_GROUP_TEMPLATE,
    payload: templateId,
  };
}

function deleteGroupTemplateSuccess(
  templateId: number,
): ActionWith<number> {
  return {
    type: DrawingsAnnotationLegendActionTypes.DELETE_GROUP_TEMPLATE_SUCCESS,
    payload: templateId,
  };
}

function updateUserInGroups(
  creationInfo: CreateDrawingGroupsResponse,
  groups: CreateDrawingGroup[],
): ActionWith<DrawingsGroupUpdateUser> {
  return {
    type: DrawingsAnnotationLegendActionTypes.UPDATE_USER_IN_GROUPS,
    payload: { creationInfo, groups },
  };
}

function addInstanceToGroup(
  groupToInstancesList: Array<[string, string[]]>,
): ActionWith<Array<[string, string[]]>> {
  return {
    type: DrawingsAnnotationLegendActionTypes.ADD_INSTANCE_TO_GROUP,
    payload: groupToInstancesList,
  };
}

function updateSelection(payload: UpdateSelectionPayload): ActionWith<UpdateSelectionPayload> {
  return {
    type: DrawingsAnnotationLegendActionTypes.UPDATE_SELECTION,
    payload,
  };
}

function toggleShowOnlyGroups(): Action {
  return {
    type: DrawingsAnnotationLegendActionTypes.TOGGLE_SHOW_ONLY_GROUPS,
  };
}

function setFilteredResultItems(items: AnnotationLegendItem[]): ActionWith<AnnotationLegendItem[]> {
  return {
    type: DrawingsAnnotationLegendActionTypes.SET_FILTERED_RESULT_ITEMS,
    payload: items,
  };
}


function bulkGroupsUpdate(payload: BulkGroupsChangePayload): ActionWith<BulkGroupsChangePayload> {
  return {
    type: DrawingsAnnotationLegendActionTypes.BULK_GROUPS_UPDATE,
    payload,
  };
}

export const DrawingsAnnotationLegendActions = {
  loadGroups,
  setGroups,
  updateGroups,
  moveGroups,
  deleteGroups,
  removeDrawingsFromGroups,
  addGroups,
  toggleOpen,
  toggleAllOpen,
  saveFiltersId,
  setFilterData,
  changeFilterData,
  loadGroupTemplates,
  setGroupTemplates,
  createGroupTemplate,
  applyGroupTemplate,
  updateGroupTemplate,
  updateGroupTemplateSuccess,
  duplicateGroupTemplate,
  addGroupTemplate,
  deleteGroupTemplate,
  deleteGroupTemplateSuccess,
  updateUserInGroups,
  addInstanceToGroup,
  setGroupLoadSuccess,
  updateSelection,
  toggleShowOnlyGroups,
  setFilteredResultItems,
  bulkGroupsUpdate,
};
