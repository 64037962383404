import { IconButton, Icons, MovableContextMenu, SearchList } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { ItemData } from './interfaces';
import { MENU_ITEM_HEIGHT, Styled } from './styled';
import { TabInfo, TabsMenuItem } from './tabs-menu-item';

interface Props<T extends ItemData> {
  tabList: T[];
  onClickItem: (id: string) => void;
  activeId: string;
  itemTooltipPosition: string;
  defaultColor: string;
}

interface State {
  isMenuOpened: boolean;
  menuPosition: { x: number, y: number };
  isMenuOnTop: boolean;
}

const MAX_ITEMS_COUNT = 5;
export class TabsMenu<T extends ItemData> extends React.Component<Props<T>, State> {
  private buttonRef: HTMLDivElement;

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      isMenuOpened: false,
      menuPosition: null,
      isMenuOnTop: false,
    };
  }

  public render(): JSX.Element {
    if (this.props.tabList.length < 2) {
      return null;
    }
    const tabsList: TabInfo[] = this.props.tabList.map((item) => ({
      ...item,
      isActive: this.props.activeId === item.id,
    }));

    return (
      <Styled.TabsMenuContainer>
        <div onMouseDown={this.toggleMenu} ref={this.makeRef}>
          <IconButton
            Icon={Icons.MenuBurgerVariable}
            height={20}
          />
        </div>
        {this.state.isMenuOpened &&
          <MovableContextMenu
            x={this.state.menuPosition.x}
            y={this.state.menuPosition.y}
            onClose={this.toggleMenu}
          >
            <Styled.TabsMenu isMenuOnTop={this.state.isMenuOnTop}>
              <SearchList
                items={tabsList}
                searchPlaceholder={'Type something'}
                itemComponent={this.getItemComponent}
                maxItemsCount={MAX_ITEMS_COUNT}
                width={240}
                getKeyValue={this.getTabKey}
                getStringToCompare={this.getTabName}
                onClick={this.onClickItem}
              />
            </Styled.TabsMenu>
          </MovableContextMenu>
        }
      </Styled.TabsMenuContainer>
    );
  }

  private getTabKey(item: TabInfo): string {
    return item.id;
  }

  private getTabName(item: TabInfo): string {
    return item.name;
  }

  @autobind
  private onClickItem(item: TabInfo): void {
    this.props.onClickItem(item.id);
  }

  @autobind
  private getItemComponent(item: TabInfo): JSX.Element {
    return (
      <TabsMenuItem
        itemData={item}
        tooltipPosition={this.props.itemTooltipPosition}
        defaultColor={this.props.defaultColor}
      />
    );
  }

  @autobind
  private makeRef(ref: HTMLDivElement): void {
    this.buttonRef = ref;
  }

  @autobind
  private toggleMenu(e: React.MouseEvent<HTMLDivElement>): void {
    const isMenuOnTop = this.isMenuOnTop();
    const { x, y } = this.getMenuPosition(e, isMenuOnTop);

    this.setState({
      isMenuOpened: !this.state.isMenuOpened,
      menuPosition: this.state.isMenuOpened ? null : { x, y },
      isMenuOnTop,
    });
  }


  private isMenuOnTop(): boolean {
    const { bottom, height } = this.buttonRef.getBoundingClientRect();
    const maxHeightBorders = window.innerHeight - bottom + height / 2;
    const maxHeightCount = (Math.min(MAX_ITEMS_COUNT, this.props.tabList.length) + 1) * MENU_ITEM_HEIGHT;
    return maxHeightBorders <= maxHeightCount;
  }

  @autobind
  private getMenuPosition(e: React.MouseEvent<HTMLDivElement>, isMenuTop: boolean): { x: number, y: number } {
    const { bottom, height, width, left } = e.currentTarget.getBoundingClientRect();
    const x = left + width / 2;
    const y = isMenuTop ? bottom - height : bottom;
    return { x, y };
  }
}
