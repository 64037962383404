import * as t from 'io-ts';

import { RoleCodeC } from 'common/enums/role-code';

export const ShortUserC = t.intersection(
  [
    t.type(
      {
        guid: t.string,
        email: t.string,
      }),
    t.partial({
      firstName: t.string,
      lastName: t.string,
    }),
  ], 'ShortUser');

export const ShortUserWithRoleC = t.exact(
  t.intersection([
    ShortUserC,
    t.partial({
      roleName: t.string,
      roleCode: RoleCodeC,
    }),
  ]),
  'ShortUserWithRole');

export const ShortUsersC = t.array(ShortUserWithRoleC);

export type ShortUser = t.TypeOf<typeof ShortUserC>;
export type ShortUserWithRole = t.TypeOf<typeof ShortUserWithRoleC>;
