import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams, FinderSelectedVisiblity } from 'common/components/drawings/interfaces';
import { PdfGeometry } from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { VisibleEntity, VisibleEntityConfig } from '../../common';
import { DrawingsGeometryUtilityPolygon, UTILITY_STYLES } from '../../drawings-geometry-entities';
import { DrawingsUtilityPolyline } from '../../drawings-geometry-entities/utility/drawings-utility-polyline';
import { DrawingsViewHelper } from '../drawings-view-helper';
import { PaperMouseEventUtils } from '../paper-mouse-event-utils';
import { UtilityGeometry } from './interfaces';

interface Config extends VisibleEntityConfig<PdfGeometry[]> {
  layer: paper.Layer | paper.Group;
  renderParamsContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  viewHelper: DrawingsViewHelper;
  geometriesToRemove: number[];
  visibilityState: FinderSelectedVisiblity;
  addGeometryToRemove: (geometryId: number) => void;
}

export class FinderSelectedGeometries extends VisibleEntity<PdfGeometry[], Config> {
  private _geometries: Map<number, UtilityGeometry>;
  private _group: paper.Group;
  private _erasing: boolean = false;

  constructor(config: Config) {
    super(config);
    config.renderParamsContextObserver.subscribe(this.onUpdateZoom);
  }

  public get geometriesIds(): number[] {
    return this._config.geometry.map(x => x.id);
  }

  public set geometry(geometry: PdfGeometry[]) {
    this._config.geometry = geometry;
    this._geometries.forEach(x => x.destroy());
    this._geometries.clear();
    this.render(geometry);
  }

  public set erasing(enable: boolean) {
    this._erasing = enable;
  }

  public set visibilityState(state: FinderSelectedVisiblity) {
    if (this._config.visibilityState === state) {
      return;
    }
    this._config.visibilityState = state;
    this._geometries.forEach(x => x.destroy());
    this._geometries.clear();
    this.render(this._config.geometry);
  }

  public destroy(): void {
    this._geometries.forEach(x => x.destroy());
    this._geometries.clear();
  }

  protected render(geometries: PdfGeometry[]): void {
    this._geometries = new Map();
    this._group = new paper.Group();
    const geometriesToRemoveSet = new Set(this._config.geometriesToRemove);
    for (const geometry of geometries) {
      if (geometriesToRemoveSet.has(geometry.id)) {
        continue;
      }
      if (this._geometries.has(geometry.id)) {
        this._geometries.get(geometry.id).destroy();
      }
      if (geometry.isClosed) {
        if (!this._config.visibilityState.area) {
          continue;
        }
        const polygon = new DrawingsGeometryUtilityPolygon({
          geometry: geometry.points,
          layer: this._group,
          color: DrawingsCanvasColors.secondaryColorInfo,
          onMouseEnter: (e) => {
            this.onMouseEnter(e, geometry.id);
          },
          renderParamsContextObserver: this._config.renderParamsContextObserver,
        });
        this._geometries.set(geometry.id, polygon);
      } else {
        if (!this._config.visibilityState.line) {
          continue;
        }
        const polyline = new DrawingsUtilityPolyline({
          geometry: geometry.points,
          layer: this._group,
          color: DrawingsCanvasColors.secondaryFigure,
          renderParamsContextObserver: this._config.renderParamsContextObserver,
          onMouseEnter: (e) => {
            this.onMouseEnter(e, geometry.id);
          },
        });
        this._geometries.set(geometry.id, polyline);
      }
    }
    this._group.addTo(this._config.layer);
  }

  @autobind
  private onUpdateZoom({ zoom }: DrawingsRenderParams): void {
    this._group.strokeWidth = UTILITY_STYLES.strokeWidth / zoom;
    this._group.shadowBlur = UTILITY_STYLES.shadowBlur / zoom;
  }

  @autobind
  private onMouseEnter(e: PaperMouseEvent, id: number): void {
    if (!this._erasing) {
      return;
    }
    if (
      PaperMouseEventUtils.hasPressed(e)
      && (PaperMouseEventUtils.isTouch(e.event) ? e.event.touches.length === 1 : e.event.button === 0)
    ) {
      this._geometries.get(id).destroy();
      this._geometries.delete(id);
      this._config.addGeometryToRemove(id);
    }
  }
}
