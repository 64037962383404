import * as paper from 'paper';
import { DrawingPdfSnappingResult, DrawingSnapping } from 'common/components/drawings/interfaces/drawing';

const PDF_SNAPPING_MODES = new Set([
  Core.PDFNet.GeometryCollection.SnappingMode.e_LineIntersection,
  Core.PDFNet.GeometryCollection.SnappingMode.e_LineMidpoint,
  Core.PDFNet.GeometryCollection.SnappingMode.e_PathEndpoint,
  Core.PDFNet.GeometryCollection.SnappingMode.e_PointOnLine,
  Core.PDFNet.GeometryCollection.SnappingMode.e_DefaultSnapMode,
]);

export class PdfSnappingReader {

  public static async getPDFTronSnapping(
    snapping: DrawingSnapping,
    point: paper.Point,
    threshold: number,
    snappingModes: Core.PDFNet.GeometryCollection.SnappingMode[],
  ): Promise<DrawingPdfSnappingResult | null> {
    const pdfPoint = await snapping.toPdf.mult(point.x, point.y);
    let resultPoint: paper.Point = null;
    let mode: Core.PDFNet.GeometryCollection.SnappingMode = null;
    for (const snappingMode of snappingModes) {
      if (!PDF_SNAPPING_MODES.has(snappingMode)) {
        continue;
      }
      const pointByMode = await PdfSnappingReader.snappingByType(
        snapping,
        point,
        pdfPoint,
        threshold,
        snappingMode,
      );
      if (pointByMode && (!resultPoint || resultPoint.subtract(point).length > pointByMode.subtract(point).length)) {
        resultPoint = pointByMode;
        mode = snappingMode;
      }
    }
    return { mode, point: resultPoint };
  }

  private static async snappingByType(
    snapping: DrawingSnapping,
    point: paper.Point,
    pdfPoint: Core.PDFNet.Point,
    threshold: number,
    snappingMode: Core.PDFNet.GeometryCollection.SnappingMode,
  ): Promise<paper.Point | null> {
    pdfPoint = await snapping.geometry.snapToNearest(
      pdfPoint.x,
      pdfPoint.y,
      snappingMode,
    );
    pdfPoint = await snapping.fromPdf.mult(pdfPoint.x, pdfPoint.y);
    const paperPoint = new paper.Point(pdfPoint);
    return paperPoint.subtract(point).length < threshold ? paperPoint : null;
  }
}
