import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useOpenMenuWithPosition } from 'common/hooks';
import { KreoDialogActions } from 'common/UIKit';
import { BREAKDOWN_DIALOG_NAME } from 'unit-2d-database/components/side-panel/components/breakdown-dialog';
import { FormButtonField, FormSingleSelectField } from '../form-fields';
import { EditContext } from '../form-fields/use-edit-context';
import { EditDialog } from './edit-dialog';
import { MixedPropertyContext } from './mixed-property-context-provider';
import { Styled } from './styled';

const MixedSelectWith: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const selectOption = useContext(MixedPropertyContext);
  const { onOpen, onClose, isOpen } = useOpenMenuWithPosition(true);
  const handleClick = useCallback((e) => {
    if (props.fieldElement === FormButtonField) {
      if (props.input.root) {
        props.input.onClick();
        dispatch(KreoDialogActions.updateDialogData(BREAKDOWN_DIALOG_NAME, { selectedId: null }));
        return;
      }
    }
    onOpen(e);
  }, [onOpen]);
  const Element = props.fieldElement;
  const editContextValue = useMemo(() => ({
    editIcon: Icons.RenameSmall,
    startEditing: handleClick,
  }), [handleClick]);
  if (!selectOption) {
    return <Element {...props} />;
  }

  const input = selectOption.getInput(props.id);
  const showEditElement = input === null;

  if (showEditElement) {
    return (
      <EditContext.Provider value={{
        editIcon: null,
        startEditing: null,
      }}>
        <Element {...props} />
      </EditContext.Provider>
    );
  }

  return (
    <>
      {isOpen && (
        <EditDialog
          isOpen={isOpen}
          onClose={onClose}
          inputElement={props.inputElement}
          input={props.input}
          dropDownProps={props.dropDownProps}
        />
      )}
      <EditContext.Provider value={editContextValue}>
        <Styled.MixedWrapper>
          <FormSingleSelectField
            {...props}
            input={input}
            dropDownProps={undefined}
          />
        </Styled.MixedWrapper>
      </EditContext.Provider>
    </>

  );
};

export const wrapMixedSelect = (fieldElement: React.FC, inputElement: React.FC): React.FC<any> => {
  return (props) => <MixedSelectWith {...props} fieldElement={fieldElement} inputElement={inputElement} />;
};
