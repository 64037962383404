import * as React from 'react';
import { UndoRedoContext } from './undo-redo-context';
import { UndoRedoContextApiProps } from './undo-redo-context-props';
import { UndoRedoController } from './undo-redo-controller';

export function connectToUndoRedoContext<P>(
  Component: React.ComponentType<P & UndoRedoContextApiProps>,
): React.ComponentType<
  Pick<P, Exclude<keyof P, keyof UndoRedoContextApiProps>>
> {
  return class WrappedComponent extends React.PureComponent<P> {
    public render(): React.ReactNode {
      return (
        <UndoRedoController>
          <UndoRedoContext.Consumer>
            {
              contextProps => <Component {...this.props} {...contextProps}/>
            }
          </UndoRedoContext.Consumer>
        </UndoRedoController>
      );
    }
  };
}
