import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: ${p => p.theme.color[p.color]};
  font-size: 14px;
  cursor: pointer;

  svg {
    height: 20px;
    margin-right: 15px;
    fill: currentColor;
  }
`;

export const Styled = {
  Container,
};
