import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { ShortPointDescription } from '../../interfaces';
import { InstancesUtils } from '../../utils';
import { useAddInstancesWithUndo } from './use-add-instances-with-undo';

export function useDuplicateInstancesToPage(): (instancesIds: string[], pageId: string) => void {
  const instances = useSelector<State>(s => s.drawings.aiAnnotation.geometry);
  const points = useSelector<State, Record<string, ShortPointDescription>>(s => s.drawings.aiAnnotation.points);
  const addInstances = useAddInstancesWithUndo();
  return useCallback((instancesIds: string[], pageId: string) => {
    const instancesIter = arrayUtils.mapIterator(instancesIds, (x) => instances[x]);
    const { points: newPoints, instances: newInstances } = InstancesUtils.copyInstancesToPage(
      instancesIter,
      points,
      pageId,
    );
    addInstances({ instances: newInstances, points: newPoints, initiator: 'DuplicatedWithPage' });
  }, [addInstances, instances, points]);
}
