import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';

export function useSelectPages(currentDrawingId: string): [string[], (value: string[]) => void] {
  const drawingsInfo = useSelector<State, Record<string, DrawingsShortInfo>>(x => x.drawings.drawingsInfo);
  const [selectedPages, setSelectedPages] = useState<string[]>([currentDrawingId]);

  useEffect(() => {
    setSelectedPages(selectedPages.filter(x => !!drawingsInfo[x]));
  }, [drawingsInfo]);

  return [selectedPages, setSelectedPages];
}
