// TODO: replace file to the notification uint; replace all notification logic to the notification unit;
import * as t from 'io-ts';
import { SagaIterator } from 'redux-saga';

import { IsoDateStringC } from 'common/codecs/iso-date-string';
import { NotificationIconC } from 'common/enums/notification-icon';
import { NotificationRelatedAreaC } from 'common/enums/notification-related-area';
import { StringDictionary } from './dictionary';

const NotificationSenderC = t.intersection(
  [
    t.type(
      {
        id: t.string,
        firstName: t.string,
        lastName: t.string,
      }),
    t.partial({
      image: t.number,
    }),
  ],
  'NotificationSender',
);

const NotificationOptionalC = t.partial(
  {
    id: t.number,
    relatedArea: NotificationRelatedAreaC,
    isRead: t.boolean,
    icon: NotificationIconC,
    sender: NotificationSenderC,
    created: IsoDateStringC,
    isAlert: t.boolean,
    message: t.string,
    type: t.string, // TODO: NotificationType
  },
);

const NotificationRequiredC = t.type(
  {
    data: t.any,
  });

export const NotificationC = t.intersection([NotificationRequiredC, NotificationOptionalC], 'Notification');
export const NotificationsC = t.array(NotificationC);
export type Notification = t.TypeOf<typeof NotificationC>;

type NotificationHandler = (notification: Notification) => SagaIterator;

export type NotificationProcessor = StringDictionary<NotificationHandler>;
