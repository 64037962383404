import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { WizzardToolsState } from 'common/components/drawings/interfaces';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { WizzardToolsActions } from '../../actions/creators';

export function useWizzardSetting<K extends keyof WizzardToolsState>(key: K):
[WizzardToolsState[K], (value: WizzardToolsState[K]) => void] {
  const value = useSelector<State, WizzardToolsState[K]>((x) => x.drawings.wizzardTools[key]);

  const onChange = useActionDispatch(WizzardToolsActions.setSettings);

  const onChangeCallback = useCallback(
    (newSelectedType: WizzardToolsState[K]) => {
      onChange(key, newSelectedType);
    },
    [onChange, key],
  );
  return [value, onChangeCallback];
}
