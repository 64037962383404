import React, { useCallback } from 'react';

import { Styled } from './styled';

interface Props {
  placeholder?: string;
  value: string;
  id?: string;
  onClick?: () => void;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const ButtonInput: React.FC<Props> = (props) => {
  const {
    placeholder='Enter a value',
    value = '',
    onClick,
    id,
    inputRef,
  } = props;

  const handleArrowKeys = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClick();
    }
  }, []);

  return (
    <Styled.InputStyle
      placeholder={placeholder}
      type='text'
      value={value}
      onClick={onClick}
      onKeyDown={handleArrowKeys}
      id={id}
      readOnly={true}
      ref={inputRef}
    />
  );
};
