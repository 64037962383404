import { Icons, LinkComponent, TinyText } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import uuid from 'uuid';
import { FormSingleSelectField } from 'common/components/form-fields';
import { RenderIf } from 'common/components/render-if';
import { PropertyFormData } from '../../interfaces';
import { GroupsDropdown, UnitInput, PropertyNameInput } from '../fields';
import { validatePropertyNames, validatePropertyUnit, validatePropertyValue } from '../helper';
import { Styled } from '../styled';
import { OptionList } from './option-list';

export interface SingleSelectOption { id: string; value: string | number }

interface Props {
  property: PropertyFormData;
  groups: string[];
  units: string[];
  onChange: (data: PropertyFormData, isValid: boolean) => void;
}

interface StateComponent {
  isAutoFocus: boolean;
  units: string[];
  options: SingleSelectOption[];
}

const ExtraUnits = [
  'Text',
];

export class SingleSelectForm extends React.PureComponent<Props, StateComponent> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isAutoFocus: false,
      units: [...ExtraUnits, ...props.units],
      options: props.property.available.map(o => ({ id: uuid.v4(), value: o })),
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.units !== this.props.units) {
      this.setState({ units: [...ExtraUnits, ...this.props.units] });
    }
  }

  public render(): JSX.Element {
    const { available: list, value } = this.props.property;
    return (
      <>
        <PropertyNameInput
          value={this.props.property.name}
          onChange={this.triggerChange}
        />
        <UnitInput
          selectedUnit={this.props.property.unit}
          onChange={this.triggerChange}
          extraUnits={this.state.units}
        />
        <GroupsDropdown
          groups={this.props.groups}
          value={this.props.property.groupName}
          onChange={this.triggerChange}
        />
        <Styled.FieldLabel>
          <TinyText color={'gray'}>List of values</TinyText>
        </Styled.FieldLabel>
        <OptionList
          list={list}
          isAutoFocus={this.state.isAutoFocus}
          format={this.props.property.format}
          changeListValue={this.changeListValue}
          addValue={this.addValue}
          removeValue={this.removeValue}
          moveTo={this.moveTo}
        />
        <Styled.ButtonWrapper>
          <LinkComponent
            text={'Add value'}
            onClick={this.addValue}
            Icon={Icons.PlusBig}
          />
        </Styled.ButtonWrapper>
        <RenderIf condition={!!list.length}>
          <FormSingleSelectField
            label={'Default value'}
            input={{
              elements: list.map(l => l.toString()),
              activeElementIndex: list.indexOf(value),
              onClick: this.changeDefaultValue,
              createNewElement: false,
              isShowArrow: true,
            }}
          />
        </RenderIf>
      </>
    );
  }

  @autobind
  private moveTo(target: number, source: number): void {
    const { available: list } = this.props.property;
    const updatedList = list.slice();
    const moveValue = updatedList[source];
    if (target < source) {
      updatedList.splice(source, 1);
    }
    updatedList.splice(target, 0, moveValue);
    if (source < target) {
      updatedList.splice(source, 1);
    }
    this.triggerChange({ available: updatedList });
  }

  @autobind
  private changeListValue(index: number, newValue: string | number): void {
    const { available: list, value } = this.props.property;
    const updatedList = list.slice();
    updatedList[index] = newValue;
    const selectedValue = list.indexOf(value) === index ? newValue : value;
    this.triggerChange({ value: selectedValue, available: updatedList });
  }

  @autobind
  private addValue(): void {
    const { available: list } = this.props.property;
    const updatedList = [...list, ''];
    this.setState({ isAutoFocus: true });
    this.triggerChange({ available: updatedList });
  }

  @autobind
  private removeValue(index: number): void {
    const { available: list, value } = this.props.property;
    const updatedList = list.slice();
    updatedList.splice(index, 1);
    const selectedValue = list[index] === value ? updatedList[0] || '' : value;
    this.triggerChange({ value: selectedValue, available: updatedList });
  }

  @autobind
  private changeDefaultValue(index: number): void {
    const { available: list } = this.props.property;
    this.triggerChange({ value: list[index] });
  }

  @autobind
  private triggerChange(updates: Record<string, string | number | Array<string | number>>): void {
    const updatedProperty = { ...this.props.property, ...updates };
    const isValidValueList = !updatedProperty.available.some(a => !a.toString().trim());
    const isValid = validatePropertyNames(updatedProperty)
      && isValidValueList
      && validatePropertyUnit(updatedProperty)
      && validatePropertyValue(updatedProperty);
    this.props.onChange(updatedProperty, isValid);
  }
}
