import * as React from 'react';

import './step.scss';

import { FaqCaption } from 'common/enums/faq-caption';

export interface StepProps {
  title: string;
  faqCaption: FaqCaption;
  action: () => void;
  stepAction?: () => void;
  blocked?: boolean;
  stepStatus?: number;
}


export const Step: React.FC<StepProps> = (props) => {
  return (
    <div className='step-component'>
      {props.children}
    </div>);
};
