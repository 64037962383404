import React, { useCallback } from 'react';
import { DrawingsSettingsSwitcherWithLabel } from 'common/components/drawings/components/setting-parameter';
import {
  useThreeDSettings,
  useThreeDSettingsUpdates,
} from 'common/components/drawings/hooks/settings/use-three-d-settings';
import { PropertyWithSlider } from 'common/components/property';


const ThreeDSettingsComponent: React.FC = () => {
  const {
    isCameraOrthographic: isCameraOrtographic,
    fillOpacity,
    showDrawing,
    drawingOffset,
    drawingOpacity,
  } = useThreeDSettings();

  const {
    toggleIsOrtho,
    toggleShowDrawing,
    setFillOpacity,
    setDrawingOffset,
    setDrawingOpacity,
  } = useThreeDSettingsUpdates();

  const changeFillOpacity = useCallback((value: number) => {
    setFillOpacity(value / 100);
  }, [setFillOpacity]);

  const changeDrawingOpacity = useCallback((value: number) => {
    setDrawingOpacity(value / 100);
  }, [setDrawingOpacity]);

  return (
    <>
      <DrawingsSettingsSwitcherWithLabel
        toggle={toggleIsOrtho}
        label='Orthographic'
        checked={isCameraOrtographic}
      />
      <PropertyWithSlider
        title='Fill Opacity'
        value={fillOpacity * 100}
        onChange={changeFillOpacity}
        min={0}
        max={100}
        postfix='%'
      />
      <DrawingsSettingsSwitcherWithLabel
        toggle={toggleShowDrawing}
        label='Show Drawing'
        checked={showDrawing}
      />
      <PropertyWithSlider
        title='Drawing Opacity'
        value={drawingOpacity * 100}
        onChange={changeDrawingOpacity}
        min={0}
        max={100}
        postfix='%'
      />
      <PropertyWithSlider
        title='Drawing Offset'
        value={drawingOffset}
        onChange={setDrawingOffset}
        min={-10}
        max={10}
      />
    </>
  );
};

export const ThreeDSettings = React.memo(ThreeDSettingsComponent);
