export interface Reply {
  reply: string;
}

export interface QuestionnaireContent {
  question: string;
  replies: Reply[];
  isChecked: boolean;
  isOther: boolean;
}

export const HEADER_TEXT = 'Cancel subscription';
export const EXTRA_TEXT = 'Before your subscription is canceled, please give a small feedback';

export const LIST_QUESTION: QuestionnaireContent[] = [
  {
    question: 'Why did you decide to cancel your subscription to our service?',
    replies: [
      { reply: 'Does not meet my needs' },
      { reply: 'Too expensive' },
      { reply: 'Found a better product' },
      { reply: 'Insufficient features' },
      { reply: 'Performance or reliability issues' },
      { reply: 'Customer support issues' },
    ],
    isChecked: false,
    isOther: true,
  },
  {
    question: 'What do you think we need to improve?',
    replies: [
      { reply: 'Pricing' },
      { reply: 'Features' },
      { reply: 'Performance' },
      { reply: 'Reliability' },
      { reply: 'Customer support' },
      { reply: 'Ease of use' },
    ],
    isChecked: false,
    isOther: true,
  },
  {
    question: 'Which aspects of our service would you be willing to spend more on if they were improved?',
    replies: [
      { reply: 'Pricing' },
      { reply: 'Features' },
      { reply: 'Performance' },
      { reply: 'Reliability' },
      { reply: 'Customer support' },
      { reply: 'Ease of use' },
    ],
    isChecked: false,
    isOther: true,
  },
  {
    question: 'How would you rate the quality of our customer support service?',
    replies: [
      { reply: 'Excellent' },
      { reply: 'Good' },
      { reply: 'Satisfactory' },
      { reply: 'Poor' },
      { reply: 'Did not use' },
    ],
    isChecked: false,
    isOther: true,
  },
  {
    question: 'Would you be interested in returning if we took your feedback into account and improved our service?',
    replies: [
      { reply: 'Yes' },
      { reply: 'Maybe' },
      { reply: 'No' },
    ],
    isChecked: false,
    isOther: true,
  },
];
