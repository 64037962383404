import { MIN_COLUMN_WIDTH } from '../quantity-take-off-common-table/constants';
import { groupRowInnerCell } from '../quantity-take-off-common-table/custom-cell/group-row-inner-cell';
import { QtoLeftPanelConstants } from './constants';

import { QtoLeftPanelProps, QtoLeftPanelState } from './interfaces';

export const initialState = (props: QtoLeftPanelProps): QtoLeftPanelState => ({
  autoGroupColumnDef: {
    headerName: 'Group By',
    width: 420,
    headerClass: 'ag-header-cell--name',
    field: QtoLeftPanelConstants.ELEMENT_TABLE_NAME_KEY,
    pinned: 'left',
    cellRendererParams: {
      suppressCount: true,
      checkbox: true,
      innerRenderer: groupRowInnerCell(props.recordsConfig),
    },
    resizable: true,
    minWidth: MIN_COLUMN_WIDTH,
    headerCheckboxSelection: true,
  },
  locationColumnConfig: [],
  elementColumnConfig: [],
  elementRowData: [],
  locationRowData: [],
  elementContext: {
    isImperialUnit: props.isImperialUnit,
  },
  locationContext: {
    isImperialUnit: props.isImperialUnit,
  },
  isElementOpen: true,
  isLocationOpen: false,
  tableLocation: null,
  tableElement: null,
  elementFilteredIds: [],
  locationFilteredIds: [],
  showElementData: [],
  showLocationData: [],
  elementRecords: [],
  locationRecords: [],
  isLoading: true,
  className: 'ag-menu-list__middle',
  secondaryPaneSize: 40,
  elementExpandRowsStatus: undefined,
  isUpdating: false,
  useEngineFilter: true,
});
