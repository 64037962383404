import * as Ag from 'ag-grid-community';


export class QtoTreeTableCellNonEdit implements Ag.ICellEditorComp {
  private eGui: HTMLDivElement;
  private params: Ag.ICellEditorParams;

  public init(params: Ag.ICellEditorParams): void {
    this.params = params;
    this.eGui = document.createElement('div');
    this.eGui.className = 'qto-report-pivot-cell-non-edit';
    this.renderInnerHTML();
    this.params.api.stopEditing(true);
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: Ag.ICellEditorParams): boolean {
    this.params = params;
    this.renderInnerHTML();
    this.params.api.stopEditing(true);
    return true;
  }

  public destroy(): void {
    return;
  }

  public getValue(): any {
    return;
  }

  private renderInnerHTML(): void {
    this.eGui.innerHTML = this.params.value;
  }
}
