
import React from 'react';
import { Text } from 'common/components/text';

import { SliderHorizontal } from '../slider-horizontal';
import { Styled } from './styled';

interface Props {
  value: number;
  min: number;
  max: number;
  title: string;
  step?: number;
  onChange: (value: number) => void;
  postfix?: string;
  sliderTextFontSize?: number;
}


const PropertyWithSliderComponent: React.FC<Props> = ({
  value,
  onChange,
  min,
  max,
  title,
  step,
  postfix = '',
  sliderTextFontSize = 10,
}) => {
  return (
    <Styled.PropertyWithSlider>
      <Text
        fontSize={14}
        lineHeight={16}
      >
        {title}
      </Text>
      <Text
        fontSize={sliderTextFontSize}
        lineHeight={12}
        textAlign='end'
        color='turquoiseInversed'
      >
        {Math.round(value)}{postfix}
      </Text>
      <SliderHorizontal
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        step={step}
      />
    </Styled.PropertyWithSlider>
  );
};

export const PropertyWithSlider = React.memo(PropertyWithSliderComponent);
