import { RectangleButton } from '@kreo/kreo-ui-components';
import React from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'components/theme-provider';
import { store } from '../../../../store';

interface Props {
  text: string;
}

export const CellRendererButton = (props: Props): JSX.Element => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <RectangleButton
          size="s"
          mood={'secondary'}
          text={props.text}
        />
      </ThemeProvider>
    </Provider>
  );
};

export const getButtonElement = (text: string): JSX.Element => {
  return <CellRendererButton text={text}/>;
};
