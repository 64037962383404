import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ModelCheckPriority } from '../../../../enums/mode-check-priority';
import { ModelCheckType } from '../../../../enums/model-check-type';
import { PopupContainer, PopupSeparator } from '../../../validation/components/properties';
import * as Types from '../../interfaces/types';
import { HandlePropertyBlock } from './handle-property-block';


interface StateProps {
  order: number;
  priority: ModelCheckPriority;
  modelCheckType: ModelCheckType;
}

interface OwnProps {
  top: Types.SelectedHandle;
  bottom: Types.SelectedHandle;
  onClose: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface Props extends OwnProps, StateProps { }

export class PropertiesPopupComponent extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <PopupContainer>
        <HandlePropertyBlock
          hasBottom={!!this.props.bottom}
          onClose={this.props.onClose}
          family={this.props.top.family}
          category={this.props.top.category}
          id={this.props.top.revitId}
          type={this.props.top.type}
          order={this.props.order}
          priority={this.props.priority}
          modelCheckType={this.props.modelCheckType}
          canClose={true}
          bottomId={this.props.bottom ? this.props.bottom.revitId : ''}
        />
        {
          this.props.bottom !== null &&
          [
            <PopupSeparator key={0}/>,
            <HandlePropertyBlock
              key={1}
              onClose={this.props.onClose}
              canClose={false}
              family={this.props.bottom.family}
              id={this.props.bottom.revitId}
              category={this.props.bottom.category}
              type={this.props.bottom.type}
            />,
          ]
        }
      </PopupContainer>);
  }
}

const mapStateToProps = (state: State): StateProps => {
  const selectedIssue = state.modelcheck.issues[state.modelcheck.selectedErrorCategory - 1];
  return {
    order: selectedIssue.order,
    priority: selectedIssue.priority,
    modelCheckType: selectedIssue.modelCheckType,
  };
};


const connecter = connect<StateProps, {}, OwnProps>(mapStateToProps, null);
export const PropertiesPopup = connecter(PropertiesPopupComponent);
