import * as React from 'react';

import './activity-assignment-no-activities.scss';

export const ActivityAssignmentNoActivities: React.FC = () => {
  return (
    <div className='activity-assignment-no-activities'>
      No suitable Activities found
    </div>
  );
};
