import * as React from 'react';

import { GlobalKeyboardEventsControllerContext, GlobalKeyboardEventsControllerContextProps } from './context';

export function withGlobalKeyboardEventsController<P>(
  Component: React.ComponentType<P & GlobalKeyboardEventsControllerContextProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof GlobalKeyboardEventsControllerContextProps>>> {
  return class WithGlobalKeyboardEventsController extends React.PureComponent<P> {
    public render(): React.ReactNode {
      return (
        <GlobalKeyboardEventsControllerContext.Consumer>
          {
            contextProps => {
              return (<Component
                {...this.props}
                {...contextProps}
              />);
            }
          }
        </GlobalKeyboardEventsControllerContext.Consumer>
      );
    }
  };
}
