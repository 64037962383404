import { Text } from '@kreo/kreo-ui-components';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import { useSendPromtsAnalitics } from 'unit-2d-copilot/hooks';
import { PromptsList } from 'unit-2d-copilot/prompts-list';
import { TwoDCopilotActions } from 'unit-2d-copilot/store-slice';
import { BotAvatar } from '../bot-avatar';
import { Styled } from './styled';

interface OwnProps {
  getPdfText: () => Promise<string>;
}

interface DispatchProps {
  sendMessage: (message: string) => void;
}

interface Props extends DispatchProps, OwnProps {
}

// eslint-disable-next-line max-len
const Message = 'Hi! I can help you with some tasks. Ask me a question about your drawing and I’ll provide you with a reply. Below you can find samples of possible requests';

const NoMessagesComponent: React.FC<Props> = ({
  sendMessage,
}) => {
  const sendQuery = useSendPromtsAnalitics(sendMessage);

  return (
    <Styled.Container>
      <Styled.Message>
        <BotAvatar/>
        <Styled.MessageText>
          <Text>
            {Message}
          </Text>
        </Styled.MessageText>
      </Styled.Message>
      <Styled.Prompts>
        <PromptsList
          onSelectPrompt={sendQuery}
        />
      </Styled.Prompts>
    </Styled.Container>
  );
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { getPdfText }: OwnProps): DispatchProps {
  return {
    sendMessage: (query) => {
      dispatch(TwoDCopilotActions.sendMessage({ query, getPdfText }));
    },
  };
}

export const NoMessages = connect(null, mapDispatchToProps)(NoMessagesComponent);
