import styled from 'styled-components';
import { CanvasStyled } from 'common/components/drawings/drawings-canvas';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${p => p.theme.color.background};
  canvas {
    margin: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 20px;
  ${CanvasStyled.MainLayout} {
    canvas {
      opacity: 0.5;
    }
  }
`;

const Header = styled.div`
  height: 56px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  top: 0;
  z-index: 1;
  pointer-events: none;
  margin-top: 22px;
  padding: 0 20px 0 30px;
  box-sizing: border-box;
  align-items: center;
  * {
    pointer-events: all;
  }
`;

const HeaderActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

export const Styled = {
  Container,
  Header,
  Content,
  HeaderActionButtons,
};
