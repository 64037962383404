import * as React from 'react';

import { connect } from 'react-redux';
import { State } from 'common/interfaces/state';
import { ConfirmationDialog } from '../../../../components/dialog/confirmation-dialog';

export interface DeleteExcelTab {
  pageName: string;
  pageId: string;
}

export const DELETE_EXCEL_TAB_CONFIRM_DIALOG = 'DELETE_EXCEL_TAB_CONFIRM_DIALOG';

interface StateProps {
  data: DeleteExcelTab;
}

interface OwnProps {
  onSubmit: (data: DeleteExcelTab) => void;
}

type Props = OwnProps & StateProps;

class TwoDReportDeleteTabDialogComponent extends React.Component<Props> {

  public render(): React.ReactNode {
    const { onSubmit, data } = this.props;
    const dialogText = `Sheet "${data && data.pageName}" will be removed immediately.
      This action can’t be undone.`;

    return (
      <ConfirmationDialog
        name={DELETE_EXCEL_TAB_CONFIRM_DIALOG}
        title='Are you sure you want to delete this sheet?'
        text={dialogText}
        confirmButtonText='Delete'
        onConfirm={onSubmit}
        cancelButtonText='Cancel'
        zIndex={1003}
      />
    );
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    data: state.dialog[DELETE_EXCEL_TAB_CONFIRM_DIALOG],
  };
}

export const TwoDReportDeleteTabDialog = connect(mapStateToProps)(TwoDReportDeleteTabDialogComponent);
