import { Feed } from 'common/interfaces/feed';
import { objectUtils } from 'common/utils/object-utils';
import { CompanyResourcesApi } from '../../../api/server';
import { ActivitiesRequestFilterModel, ActivityModel } from '../interfaces/data';
import { ActivityForm } from '../interfaces/rest-data';


function getActivity(databaseId: number, activityId: number): Promise<ActivityModel> {
  return CompanyResourcesApi.get(
    `/databases/${databaseId}/activities/${activityId}`,
    `api get database(${databaseId}) activity(${activityId}) error`,
  );
}

function getActivities(databaseId: number, filter: ActivitiesRequestFilterModel): Promise<Feed<ActivityModel>> {
  return CompanyResourcesApi.get(
    `/databases/${databaseId}/activities?${objectUtils.toQueryString(filter)}`,
    `api get database(${databaseId}) activities error`,
  );
}

function updateActivity(databaseId: number, activityId: number, data: ActivityForm): Promise<ActivityModel> {
  return CompanyResourcesApi.put(`/databases/${databaseId}/activities/${activityId}`, data);
}

function createActivity(databaseId: number, data: ActivityForm): Promise<ActivityModel> {
  return CompanyResourcesApi.post(`/databases/${databaseId}/activities`, data);
}

function deleteActivity(databaseId: number, activityId: number): Promise<void> {
  return CompanyResourcesApi.delete(`/databases/${databaseId}/activities/${activityId}`, null);
}


export const ActivitiesApi = {
  updateActivity,
  createActivity,
  deleteActivity,
  getActivity,
  getActivities,
};
