import { Text, Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { Apply, DialogContainerStyled, Header, KeepOld } from './components';

interface Props {
  toggleKeepPolygons: () => void;
  isKeepPolygons: boolean;
  apply: () => void;
  cancel: () => void;
}

export const UnionDialog = React.memo<Props>(({ toggleKeepPolygons, isKeepPolygons, apply, cancel }) => {
  return (
    <DialogContainerStyled.Container>
      <Header title="Merge" Icon={Icons.Union} />
      <KeepOld toggleKeepOld={toggleKeepPolygons} isKeepOld={isKeepPolygons}>
        <Text fontSize={12} color={'gray'}>
          Keep polygons
        </Text>
      </KeepOld>
      <Apply apply={apply} cancel={cancel} />
    </DialogContainerStyled.Container>
  );
});
