import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { SectionMenuItems, SectionMenuItemsProps, useAccordion } from '../section-menu-items';
import { Styled } from './styled';

interface Props extends Omit<SectionMenuItemsProps, 'infoIcon' | 'manageIcon'> {
  title: string;
  isOpen: boolean;
  infoIcon?: React.ComponentType;
  manageIcon?: React.ComponentType;
}

export const AccordionElement: React.FC<Props> = ({
  infoIcon,
  children,
  isOpen,
  title,
  ...sectionProps
}) => {
  const { icon: expandIcon, isOpen: open, toggleOpen } = useAccordion(Icons.RightSmall, Icons.DownSmall, isOpen);
  return (
    <Styled.Block>
      <SectionMenuItems
        onSelectionMenuClick={toggleOpen}
        infoIcon={infoIcon}
        title={title}
        manageIcon={expandIcon}
        {...sectionProps}
      />
      <Styled.BlockDescription isOpen={open}>
        <Styled.DescriptionTextContainer isOpen={open}>{children}</Styled.DescriptionTextContainer>
      </Styled.BlockDescription>
    </Styled.Block>
  );
};
