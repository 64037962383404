import styled from 'styled-components';

const Container = styled.div`
  width: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(p) => p.theme.color.backgroundRush};
  border-radius: 20px;
  padding: 10px 15px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.05);
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: ${(p) => `${p.theme.color.background}85`};
`;

export const Styled = {
  Container,
  Background,
};
