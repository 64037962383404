import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { END, eventChannel, SagaIterator } from 'redux-saga';
import { all, call, put, take, takeLatest } from 'redux-saga/effects';

import { ConstantFunctions } from 'common/constants/functions';
import { RequestStatus } from 'common/enums/request-status';
import { DatadogLogger } from 'common/environment/datadog-logger';
import { ExceptionHandlingUtils } from 'common/environment/exception-handling-utils';
import { ActionWith } from 'common/interfaces/action-with';
import { RealtimeConnectionProvider } from 'common/realtime/realtime-connection-provider';
import { KreoDialogActions } from 'common/UIKit';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { trimServerErrorString } from 'common/utils/trim-server-error';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { CommonApi } from '../../api/server';
import { PROFILE } from '../../constants/forms';
import { AppUrls } from '../../routes/app-urls';
import { KeyCloakService } from '../../units/account/keycloak';
import { PeopleActions } from '../people/actions/actions';
import { PersistedStorageActions } from '../persisted-storage/actions/creators';
import { AccountActions } from './actions/creators';
import {
  AccountDataPayload,
  CreateCompanyPayload,
  MotivationPopupSettingsPayload,
  UpdateCompanyPayload,
} from './actions/payloads';
import { AccountActionTypes } from './actions/types';
import { AccountApi } from './api';
import { ProfileDialogName } from './components/profile-dialog';
import { Company } from './interfaces/company';
import { UserSettings } from './interfaces/user-settings';

function* userInfo(): SagaIterator {
  try {
    const data = yield call(AccountApi.getUserInfo);
    yield put(AccountActions.userInfoSucceeded(data));
    ExceptionHandlingUtils.addUserContext(data);
    DatadogLogger.setUser(data);
  } catch (error) {
    console.error('account: user info failed', error);
    yield put(AccountActions.signInFailed(trimServerErrorString(error.message)));
  }
}

function* saveUserInfo(account: AccountDataPayload): SagaIterator {
  const subscriptionType = yield selectWrapper(state => state.account.selectedSubscriptionType);

  const data = yield call(AccountApi.setUserInfo, { ...account, subscriptionType });
  yield put(AccountActions.userInfoSucceeded(data));
  yield put(AccountActions.fetchCompanies());
}

function* setUserInfo(): SagaIterator {
  try {
    const formValues = yield selectWrapper(state => state.form[PROFILE].values);
    yield call(saveUserInfo, formValues);
    yield put(KreoDialogActions.closeDialog(ProfileDialogName));
  } catch (error) {
    yield put(AccountActions.signInFailed(trimServerErrorString(error.message)));
  }
}

function* updateCompany({ payload }: ActionWith<UpdateCompanyPayload>): SagaIterator {
  try {
    const companies = yield call(AccountApi.updateCompany, payload.id, payload.data);
    yield put(AccountActions.fetchCompaniesSuccess(companies));
  } catch (error) {
    console.error('account: update company failed:', error, payload);
  }
}

function* logOut({ payload: withReturnUrl }: ActionWith<boolean>): SagaIterator {
  try {
    RealtimeConnectionProvider.disconnectIfConnectionExists();
    ExceptionHandlingUtils.addUserContext(null);
    DatadogLogger.setUser(null);
    const location = yield selectWrapper(state => state.router.location);
    const pathName = `${location.pathname}${location.search}`;
    const isInvitation: boolean = yield selectWrapper(state => state.account.isInvitation);
    if (!isInvitation) {
      const redirectUri = withReturnUrl ? pathName : AppUrls.root.path;
      yield call(KeyCloakService.callLogout, redirectUri);
      yield call(KeyCloakService.logIn, redirectUri);
    }
    yield put(PersistedStorageActions.dropSelectedCompanyId());
  } catch (error) {
    console.error('account: log out failed', error);
  }
}

function* fetchCompanies(): SagaIterator {
  try {
    const companies: Company[] = yield call(AccountApi.fetchCompanies);

    if (!companies || companies.length === 0) {
      const showQuestionnaire: boolean = yield selectWrapper(state => state.account.showQuestionnaire);
      if (showQuestionnaire) {
        yield put(push(AppUrls.qto2d.questionnaire.url()));
      } else {
        yield put(push(AppUrls.emptyAccount.url()));
      }
      return;
    }

    yield put(AccountActions.fetchCompaniesSuccess(companies));

  } catch (error) {
    console.error('account: fetch companies failed', error);
  }
}

function* tryRefreshSession(): SagaIterator {
  try {
    yield call(CommonApi.refreshSession);
    yield call(initializeAblyConnection);
  } catch (error) {
    yield put(AccountActions.logOut());
  }
}

function* questionnaireCompleted({ payload }: ActionWith<boolean>): SagaIterator {
  try {
    yield put(AccountActions.setShowQuestionnaire(false));
    yield call(AccountApi.setQuestionnaireCompleted, payload);
    yield call(userInfo);
  } catch (error) {
    console.error('questionnaire: initialize ably connection failed', error);
  }
}

function* initializeAblyConnection(): SagaIterator {
  try {
    const token: string = yield call(AccountApi.getAblyToken);
    RealtimeConnectionProvider.initializeClient(token, AccountApi.ablyGetTokenUrl);
  } catch (error) {
    console.error('account: initialize ably connection failed', error);
  }
}

function* createCompany({ payload }: ActionWith<CreateCompanyPayload>): SagaIterator {
  try {
    let companies: Company[] = yield call(AccountApi.createCompany, payload.data);
    const userEmail = yield selectWrapper<string>(s => s.account.email);
    const ownCompany = companies.find(c => c.email === userEmail);
    if (companies && companies.length) {
      if (payload.logoTemporaryKey) {
        companies = yield call(
          AccountApi.updateCompany, ownCompany.id,
          { logoTemporaryKey: payload.logoTemporaryKey },
        );
      }
    }
    yield put(AccountActions.fetchCompaniesSuccess(companies));
    yield put(AccountActions.createCompanySucceeded());
    if (companies.length === 1) {
      yield put(push(AppUrls.qto2d.index.path));
    }
    yield call(userInfo);
  } catch (error) {
    console.error('account: create company failed', error, payload);
  }
}

function* initializeAppData(): SagaIterator {
  try {
    yield call(tryRefreshSession);
    yield all([call(fetchCompanies), call(userInfo)]);
    yield put(AccountActions.setInitialAppDataStatus(RequestStatus.Loaded));
  } catch (error) {
    yield put(AccountActions.setInitialAppDataStatus(RequestStatus.Failed));
    console.error('account: initialize app data failed', error);
  }
}

function* checkoutNewSubscription({ payload: planId }: ActionWith<string>): SagaIterator {
  try {
    const chargebee = Chargebee.getInstance();

    const openCheckoutConfig: OpenChargebeeCheckoutHostedPageConfig = {
      hostedPage: () => AccountApi.getCheckoutNewSubscriptionHostedPage(planId),
    };

    const channel = eventChannel<AnyAction>(emit => {
      openCheckoutConfig.success = () => {
        emit(AccountActions.acknowledgeSubscriptionChanges());
        emit(END);
      };

      openCheckoutConfig.close = openCheckoutConfig.error = () => emit(END);

      return ConstantFunctions.doNothing;
    });

    chargebee.openCheckout(openCheckoutConfig);

    while (true) {
      const channeledAction: AnyAction = yield take(channel);
      if (channeledAction === END) {
        break;
      }

      yield put(channeledAction);
    }

  } catch (error) {
    console.error('account: checkout new subscription failed', error, { planId });
  }
}

function* openSelfServePortal(): SagaIterator {
  try {
    const chargebee = Chargebee.getInstance();
    chargebee.setPortalSession(AccountApi.getPortalSession);
    const portal = chargebee.createChargebeePortal();

    let config: OpenChargebeePortalConfig = null;
    const channel = eventChannel<AnyAction>(emit => {
      config = {
        close: () => {
          emit(AccountActions.acknowledgeSubscriptionChanges());
          chargebee.logout();
          emit(END);
        },
      };

      return ConstantFunctions.doNothing;
    });

    portal.open(config);

    while (true) {
      const channeledAction: AnyAction = yield take(channel);
      if (channeledAction === END) {
        break;
      }

      yield put(channeledAction);
    }
  } catch (error) {
    console.error('account: open self serve portal failed', error);
  }
}

function* persistSelectedCompanyId({ payload: selectedCompany }: ActionWith<Company>): SagaIterator {
  try {
    yield put(ProjectsActions.dropStore());
    if (selectedCompany) {
      yield put(PersistedStorageActions.persistSelectedCompanyId(selectedCompany.id));
      yield put(PeopleActions.getCompanyPeopleSucceeded(selectedCompany.id, []));
    }
  } catch (error) {
    console.error('account: persist selected company id failed', error, { selectedCompany });
  }
}

function* acknowledgeSubscriptionChanges(): SagaIterator {
  try {
    yield call(AccountApi.acknowledgeSubscriptionChanges);
  } catch (error) {
    console.error('account: acknowledge subscription changes failed', error);
  }
}

function* loadMotivationPopupSettings(): SagaIterator {
  try {
    const settings: MotivationPopupSettingsPayload = yield call(AccountApi.getMotivationPopupSettings);
    yield put(AccountActions.loadMotivationPopupSettingsSucceed(settings));
  } catch (error) {
    console.error('admin motivation popup settings: load feed failed', error);
  }
}

function* setMotivationPopupSettings({ payload: settings }: ActionWith<MotivationPopupSettingsPayload>): SagaIterator {
  try {
    yield call(AccountApi.setMotivationPopupSettings, settings);
    yield put(AccountActions.loadMotivationPopupSettingsSucceed(settings));
  } catch (error) {
    console.error('admin motivation popup settings: set failed', error);
  }
}

function* deleteMotivationPopupSettings(): SagaIterator {
  try {
    const settings: MotivationPopupSettingsPayload = yield call(AccountApi.deleteMotivationPopupSettings);
    yield put(AccountActions.loadMotivationPopupSettingsSucceed(settings));
  } catch (error) {
    console.error('admin motivation popup settings: delete failed', error);
  }
}


function* saveUserSettings(): SagaIterator {
  try {
    const userSettings: UserSettings = yield selectWrapper(
      ({ account }) => ({
        isImperial: account.settings.isImperial,
        colors: account.settings.colors,
      }),
    );
    yield call(AccountApi.changeSettings, userSettings);
  } catch (error) {
    console.error('user settings: toggle imperial unit', error);
  }
}

function* setAvatar({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(AccountApi.setAvatar, payload);
    yield put(AccountActions.setAvatarSucceed());
  } catch (error) {
    console.error('save user avatar', error);
  }
}

function* removeAvatar(): SagaIterator {
  try {
    yield call(AccountApi.removeAvatar);
  } catch (error) {
    console.error('remove avatar', error);
  }
}

export function* accountSagas(): SagaIterator {
  yield takeLatest(AccountActionTypes.SET_INFO, setUserInfo);
  yield takeLatest(AccountActionTypes.GET_INFO, userInfo);
  yield takeLatest(AccountActionTypes.LOG_OUT, logOut);
  yield takeLatest(AccountActionTypes.FETCH_COMPANIES, fetchCompanies);
  yield takeLatest(AccountActionTypes.TRY_REFRESH_SESSION, tryRefreshSession);
  yield takeLatest(AccountActionTypes.SET_QUESTIONNAIRE_COMPLETED, questionnaireCompleted);
  yield takeLatest(AccountActionTypes.CREATE_COMPANY_REQUEST, createCompany);
  yield takeLatest(AccountActionTypes.GET_INITIAL_APP_DATA, initializeAppData);
  yield takeLatest(AccountActionTypes.CHECKOUT_NEW_SUBSCRIPTION, checkoutNewSubscription);
  yield takeLatest(AccountActionTypes.OPEN_SELF_SERVE_PORTAL, openSelfServePortal);
  yield takeLatest(AccountActionTypes.SELECT_COMPANY, persistSelectedCompanyId);
  yield takeLatest(AccountActionTypes.ACKNOWLEDGE_SUBSCRIRPTION_CHANGES, acknowledgeSubscriptionChanges);
  yield takeLatest(AccountActionTypes.LOAD_MOTIVATION_SETTINGS_REQUEST, loadMotivationPopupSettings);
  yield takeLatest(AccountActionTypes.SET_MOTIVATION_SETTINGS, setMotivationPopupSettings);
  yield takeLatest(AccountActionTypes.DELETE_MOTIVATION_SETTINGS, deleteMotivationPopupSettings);
  yield takeLatest(AccountActionTypes.UPDATE_COMPANY, updateCompany);
  yield takeLatest(AccountActionTypes.TOGGLE_IMPERIAL_METRIC, saveUserSettings);
  yield takeLatest(AccountActionTypes.REMOVE_USER_COLOR, saveUserSettings);
  yield takeLatest(AccountActionTypes.SET_CUSTOM_COLORS, saveUserSettings);
  yield takeLatest(AccountActionTypes.SET_AVATAR, setAvatar);
  yield takeLatest(AccountActionTypes.REMOVE_AVATAR, removeAvatar);
}
