import * as Ag from 'ag-grid-community';

import {
  TreeTableColumn,
  TreeTableColumnsData,
} from './interfaces';


const colDefTreeToFlatArray = (
  columns: Array<Ag.ColDef | Ag.ColGroupDef>,
): Array<Ag.ColDef | Ag.ColGroupDef> => {
  if (!columns) {
    return [];
  }
  const result = [];

  const appendColumnOrGroup = (columnOrGroup: Ag.ColDef | Ag.ColGroupDef, parentId: string): void => {
    const appendColumn = (column: Ag.ColDef, columnParentId: string): void => {
      result.push({
        id: column.colId,
        parentId: columnParentId,
        properties: column,
      });
    };

    const appendGroup = (group: Ag.ColGroupDef, groupParentId: string): void => {
      result.push({
        id: group.groupId,
        parentId: groupParentId,
        children: group.children && group.children
          .map(x => (x as Ag.ColDef).colId || (x as Ag.ColGroupDef).groupId),
        properties: group,
      });

      if (group.children) {
        for (const child of group.children) {
          appendColumnOrGroup(child, group.groupId);
        }
      }
    };

    if ((columnOrGroup as Ag.ColDef).colId) {
      appendColumn(columnOrGroup, parentId);
    } else {
      appendGroup(columnOrGroup as Ag.ColGroupDef, parentId);
    }
  };

  for (const column of columns) {
    appendColumnOrGroup(column, null);
  }

  return result;
};


type ColumnType = Ag.ColDef | Ag.ColGroupDef;
export const treeColumnsToFlatArray = <T>(
  tree: TreeTableColumnsData<T>,
  defaultColumnDefs: ColumnType,
  getColumnDef: (column: TreeTableColumn<T>) => ColumnType,
): ColumnType[] => {
  const getColumnOrGroup = (id: string): ColumnType => {
    const getColumn = (colId: string, column: TreeTableColumn<T>): ColumnType => {
      return {
        ...defaultColumnDefs,
        ...getColumnDef(column),
        colId,
      };
    };

    const getGroup = (groupId: string, group: TreeTableColumn<T>): ColumnType => {
      return {
        ...defaultColumnDefs,
        ...getColumnDef(group),
        groupId,
        resizable: false,
        children: group.childColumnKeys.map(getColumnOrGroup),
      };
    };

    const columnOrGroup = tree.columns[id];
    return columnOrGroup.childColumnKeys
      ? getGroup(id, columnOrGroup)
      : getColumn(id, columnOrGroup);
  };
  return tree.firstLevelColumns.map(getColumnOrGroup);
};

export const TreeToFlatArrayHelper = {
  treeColumnsToFlatArray,
  colDefTreeToFlatArray,
};
