import classNames from 'classnames';
import * as React from 'react';

import './revit-tree-icon-status.scss';

import { TooltipContainer } from 'common/components/tooltip-container';
import { KreoIconAiMaterial, KreoIconAttention, KreoIconSuccessSmall } from 'common/UIKit';

interface Props {
  error: boolean;
  isAugmentation: boolean;
  className?: string;
}

export const RevitTreeIconStatus: React.FC<Props> = props => {
  const augmentationBadge = props.isAugmentation && !props.error;
  const classifiedTooltip = props.isAugmentation
    ? <><b>Classified.</b> Material is not specified for the object, but Kreo picked the most likely one.</>
    : 'Classified';
  return (
    <div
      className={classNames('revit-tree-icon-status', props.className, {
        'revit-tree-icon-status--error': props.error,
      })}
    >
      {props.error ? <KreoIconAttention /> : <KreoIconSuccessSmall />}
      {augmentationBadge ? (
        <i className='revit-tree-icon-status__augmentation-badge'><KreoIconAiMaterial /></i>
      ) : null}
      <TooltipContainer
        className={classNames('revit-tree-icon-status__tooltip', {
          'revit-tree-icon-status__tooltip--augmentation': augmentationBadge,
        })}
      >
        {props.error ? 'Unclassified' : classifiedTooltip}
      </TooltipContainer>
    </div>
  );
};
