import styled, { css } from 'styled-components';
import { InstanceInformationStyled } from '../../../instance-information';


const ColorControlWrapper = styled.div`
`;

const ColorIndicatorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  ${InstanceInformationStyled.ColorIndicator} {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const DialogStyles = css`
  padding: 0px;
  border-radius: 10px;
  overflow: hidden;
`;

export const Styled = {
  ColorControlWrapper,
  ColorIndicatorWrapper,
  DialogStyles,
};
