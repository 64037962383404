import React from 'react';
import { getDynamicData } from 'unit-2d-database/helpers/get-dynamic-data';
import { Property } from 'unit-2d-database/interfaces';

export const useUnits = (propertiesDatabase: Property[]): string[] => {
  return React.useMemo(() => {
    const [, units] = getDynamicData(propertiesDatabase);
    return units;
  }, [propertiesDatabase]);
};
