import { Avatar } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './profile.scss';

import { State } from 'common/interfaces/state';
import { MdExpandMore } from '../../../icons';
import { AccountActions } from '../../../units/account/actions/creators';
import { AccountApi } from '../../../units/account/api';
import { ProfileDropMenu } from './profile-drop-menu';


interface DispatchProps {
  logOut: () => void;
}

interface StateProps {
  firstName: string;
  lastName: string;
  userId: string;
  email: string;
  isAdminPage?: boolean;
  hasAvatar: boolean;
}

interface OwnProps {
  showExpandIcon: boolean;
}

interface Props extends OwnProps, StateProps, DispatchProps {

}

class ProfileIconComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { firstName, lastName, email, showExpandIcon, isAdminPage, userId, hasAvatar } = this.props;
    const avatarUrl = AccountApi.getAvatarLink({ id: userId, hasAvatar });
    const fullName = `${firstName} ${lastName}`;
    return (
      <div className='profile-icon'>
        <Avatar
          name={fullName}
          size={30}
          avatar={avatarUrl}
        />

        {showExpandIcon
          ? (
            <div className='profile-icon__expand-icon'>
              <img src={MdExpandMore} />
            </div>
          )
          : null
        }

        <ProfileDropMenu
          isAdminPage={isAdminPage}
          firstName={firstName}
          lastName={lastName}
          userName={email}
          logOut={this.props.logOut}
        />
      </div>
    );
  }
}

const mapStateTopProps = (state: State): StateProps => {
  const { firstName, lastName, id, email, hasAvatar } = state.account;
  return {
    firstName,
    lastName,
    userId: id,
    hasAvatar,
    email,
    isAdminPage: state.router.location.pathname.startsWith('/admin'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    logOut: () => {
      dispatch(AccountActions.logOut(false));
    },
  };
};

export const ProfileIcon = connect(mapStateTopProps, mapDispatchToProps)(ProfileIconComponent);
