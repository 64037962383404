import { useCallback, useState } from 'react';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { TabNames } from '../enums';

export function useTabsState(): [currentTab: TabNames, setCurrentTab: (tab: TabNames) => void] {
  const [currentTab, setCurrentTab] = useState(TabNames.Calibrate);
  const { setDrawMode } = useDrawModeApi();

  const onChangeCurrentTab= useCallback((tabName: TabNames) => {
    if (tabName !== currentTab) {
      setDrawMode(
        tabName === TabNames.Calibrate ? DrawingsDrawMode.Calibrate : DrawingsDrawMode.CalibrateScale,
      );
      setCurrentTab(tabName);
    }
  }, [currentTab, setDrawMode]);

  return [currentTab, onChangeCurrentTab];
}
