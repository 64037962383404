import { Constants, GridApi, RowNode } from 'ag-grid-community';
import autobind from 'autobind-decorator';

import { AgGridHelper } from 'common/ag-grid';
import {
  TreeTableRowAddModel,
  TreeTableRowType,
} from 'common/components/tree-table/interfaces';
import { TreeTableColumnWithId } from 'common/components/tree-table/tree-table-column-controller';
import { State } from 'common/interfaces/state';
import { store } from '../../../../store';
import {
  ExtractorConfig,
  GraphStorageRecordsConfig,
  RecordConfig,
} from '../../interfaces/graph-storage-records-config';
import { QtoTreeRowProperty } from '../../interfaces/quantity-take-off/quantity-take-off-tree-row-property';
import { BreakdownPropertiesUtils, PropertyHelper, TreeTableAgg } from '../../utils/quantity-take-off-tree-table';
import { AGG_HASH } from '../quantity-take-off-common-table/custom-cell/aggregation-cell/aggregation-cell';
import {
  getAggregationCellExtractorValues,
  getAggregationCellValue,
} from '../quantity-take-off-common-table/custom-cell/aggregation-cell/aggregation-cell-value-getter';
import { QtoLeftPanelConstants } from '../quantity-take-off-left-panel/constants';
import { TreeTableTransferData } from '../quantity-take-off-report-table/report-table-data-transfer';

export abstract class QTOLeftTableTabMapper {
  private columnConfig: GraphStorageRecordsConfig;
  constructor(columnConfig: GraphStorageRecordsConfig) {
    this.columnConfig = columnConfig;
  }

  @autobind
  public elementTableMapper(row: RowNode, api: GridApi): TreeTableTransferData {
    const isImperialUnit = (store.getState() as State).account.settings.isImperial;
    if (!this.isRowSelectedOrPartiallySelected(row)) {
      const rows = {};
      api.forEachNodeAfterFilter(n => {
        rows[n.id] = true;
      });
      return this.formMapResult([row], (n: RowNode) => rows[n.id], api, isImperialUnit);
    } else {
      const dragRows = row.parent
        ? row.parent.childrenAfterGroup.filter(this.isRowSelectedOrPartiallySelected)
        : [row];

      return this.formMapResult(
        dragRows,
        this.isRowSelectedOrPartiallySelected,
        api,
        isImperialUnit,
      );
    }
  }

  protected abstract getAggFields(api: GridApi): Record<string, boolean>;

  private isRowSelectedOrPartiallySelected(row: RowNode): boolean {
    return row.isSelected() !== false;
  }

  private formMapResult(
    rows: RowNode[],
    filter: (row: RowNode) => boolean,
    api: GridApi,
    isImperialUnit: boolean,
  ): TreeTableTransferData {
    const usedColumns = {};
    const aggFields = this.getAggFields(api);
    const mappedRows = rows.map(
      row => this.mapRow(row, filter, api, usedColumns, aggFields, isImperialUnit));
    const columns = this.getColumns(usedColumns, api);

    return { rows: mappedRows, columns };
  }

  private mapRow(
    row: RowNode,
    filter: (row: RowNode) => boolean,
    api: GridApi,
    usedColumns: Record<string, boolean>,
    aggFields: Record<string, boolean>,
    isImperialUnit: boolean,
  ): TreeTableRowAddModel<QtoTreeRowProperty> {
    return {
      type: row.group ? TreeTableRowType.Group : TreeTableRowType.Element,
      properties: this.mapRowProperties(row, api, usedColumns, aggFields, isImperialUnit),
      children: row.childrenAfterGroup
        ? row.childrenAfterGroup
          .filter(filter)
          .map(r => this.mapRow(r, filter, api, usedColumns, aggFields, isImperialUnit))
        : undefined,
    };
  }

  private mapRowProperties(
    row: RowNode, api: GridApi,
    usedColumns: Record<string, boolean>,
    aggFields: Record<string, boolean>,
    isImperialUnit: boolean,
  ): Record<string, QtoTreeRowProperty> {
    const result: Record<string, QtoTreeRowProperty> = {};
    this.appendNameToProperties(row, api, result, isImperialUnit);
    this.appendBimIdToProperties(row, result);
    this.setAggregationIfNeeded(row, result, aggFields, isImperialUnit);

    for (const key in row.data) {
      if (BreakdownPropertiesUtils.isValidProperty(key, row.data[key])) {
        result[key] = BreakdownPropertiesUtils.convertToTreeRowProperty(row.data[key]);
        usedColumns[key] = true;
      }
    }

    return result;
  }

  private appendNameToProperties(
    row: RowNode,
    api: GridApi,
    properties: Record<string, QtoTreeRowProperty>,
    isImperialUnit: boolean,
  ): void {
    let name = null;
    if (row.field === AGG_HASH) {
      name = getAggregationCellValue(row);
    } else {
      name = row.data
        ? row.data[QtoLeftPanelConstants.ELEMENT_TABLE_NAME_KEY]
        : row.groupData[Constants.GROUP_AUTO_COLUMN_ID];
    }

    if (row.field === QtoLeftPanelConstants.EXTRA_INFO) {
      const firstChild = row.allLeafChildren[0];
      name = isImperialUnit
        ? firstChild.data[QtoLeftPanelConstants.EXTRA_INFO_IMPERIAL]
        : firstChild.data[QtoLeftPanelConstants.EXTRA_INFO];
    }

    name = BreakdownPropertiesUtils.isValueEmpty(name)
      ? `${name} ${api.getColumnDef(row.field).headerName}`
      : name;

    properties[QtoLeftPanelConstants.TREE_TABLE_NAME_KEY] = { default: name };
  }

  private appendBimIdToProperties(row: RowNode, properties: Record<string, QtoTreeRowProperty>): void {
    if (row.data) {
      properties[QtoLeftPanelConstants.TREE_TABLE_BIM_ELEMENT_ID_KEY] = {
        default: Number(row.data[QtoLeftPanelConstants.ELEMENT_TABLE_BIM_ELEMENT_ID_KEY]),
      };
    }
  }

  private setAggregationIfNeeded(
    row: RowNode,
    properties: Record<string, QtoTreeRowProperty>,
    aggFields: Record<string, boolean>,
    isImperialUnit: boolean,
  ): void {
    if (row.field in aggFields) {
      const extractorConfig = this.columnConfig.extractors as ExtractorConfig;
      const agg = getAggregationCellExtractorValues(row, extractorConfig, isImperialUnit);
      for (const aggKey in agg) {
        properties[aggKey] = { default: null };
      }
    }
  }

  private getColumns(
    usedColumns: Record<string, boolean>,
    api: GridApi,
  ): Array<TreeTableColumnWithId<QtoTreeRowProperty>> {
    let extraInfoIndex;
    const columns = AgGridHelper.getColumnController(api).getColumnState()
      .filter(col => usedColumns[col.colId])
      .map((col, index) => {
        const name = api.getColumnDef(col.colId).headerName;
        const extractorConfig = this.columnConfig.extractors as ExtractorConfig;
        const unit = extractorConfig[col.colId] && extractorConfig[col.colId].unit;
        const result: TreeTableColumnWithId<QtoTreeRowProperty> = {
          id: col.colId,
          properties: {
            [PropertyHelper.columnProperties.header]: { default: name },
            [PropertyHelper.columnProperties.aggregationStrategy]: { default: TreeTableAgg.Sum },
          },
        };

        if (unit) {
          result.properties[PropertyHelper.columnProperties.unit] = { default: unit };
        }

        if (col.colId === QtoLeftPanelConstants.EXTRA_INFO) {
          extraInfoIndex = index;
        }

        return result;
      });

    columns.splice(extraInfoIndex + 1, 0, this.getExtraInfoImperialColumn());

    return columns;
  }

  private getExtraInfoImperialColumn(): TreeTableColumnWithId<QtoTreeRowProperty> {
    const id = QtoLeftPanelConstants.EXTRA_INFO_IMPERIAL;
    const name = (this.columnConfig[id] as RecordConfig).name;

    return {
      id,
      properties: {
        [PropertyHelper.columnProperties.header]: { default: name },
        [PropertyHelper.columnProperties.aggregationStrategy]: { default: TreeTableAgg.Sum },
      },
    };
  }
}
