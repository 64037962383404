import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './index.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { FaqCaption } from 'common/enums/faq-caption';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { Step, Stepper } from '../../../../components/stepper';
import { StepProps } from '../../../../components/stepper/step';
import { ScenarioStatus } from '../../../../constants';
import { ScenarioRouteParams } from '../../../../routes/app-routes-params';
import { ScenariosActions, WorkPackageAction } from '../../actions';
import { ScenarioEditPage } from '../../interfaces';


interface ReduxProps {
  scenarioId: number;
  workpackagesLoaded: boolean;
  resourceLimitationsLoaded: boolean;
  indirectCostLoaded: boolean;
  activitiesGroupsLoaded: boolean;
  scenarioStatus: ScenarioStatus;
}

interface StepInfo {
  title: string;
  faqCaption: FaqCaption;
  action: () => void;
  blocked: boolean;
}

interface ReduxActions {
  navigateTo: (link: string) => void;
  saveWorkPackages: (projectId: number, scenarioId: number) => void;
  /**
   * @description Save resources
   */
  saveLimitations: () => void;
  setIndirectSettings: (scenarioId: number) => void;
}

interface Props extends ReduxProps, ReduxActions, AbilityAwareProps, RouteComponentProps<ScenarioRouteParams> {}

class ScenarioHeaderComponent extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='scenario-header'>
        <Stepper
          nextButtonText='Next'
          prevButtonText='Back'
          onNext={this.saveAction}
          afterLastNext={this.onLastNext}
          disableCheckMark={true}
          allowStepItemClick={true}
        >
        {
          this.getStepComponents()
        }
        </Stepper>
      </div>
    );
  }

  @autobind
  private getSteps(): StepInfo[] {
    const steps: StepInfo[] = [];
    steps.push({
      title: 'Work Packages',
      faqCaption: FaqCaption.Scenarios,
      action: this.workPackageAction,
      blocked: !this.props.workpackagesLoaded,
    });
    steps.push({
      title: 'Resources',
      faqCaption: FaqCaption.Scenarios,
      action: this.limitationsAction,
      blocked: !this.props.resourceLimitationsLoaded,
    });
    if (this.props.ability.can(Operation.Read, Subject.SequenceStep)) {
      steps.push({
        title: 'Sequence',
        faqCaption: FaqCaption.Sequence,
        action: this.sequenceAction,
        blocked: !this.props.activitiesGroupsLoaded,
      });
    }
    steps.push({
      title: 'Main Contractor\'s Cost',
      faqCaption: FaqCaption.Scenarios,
      action: this.mainConsCostAction,
      blocked: !this.props.indirectCostLoaded || this.props.scenarioStatus === ScenarioStatus.Calculating,
    });

    return steps;
  }

  private getStepComponents(): Array<React.ReactElement<StepProps>> {
    return this.getSteps().map((x, index) => (
      <Step
        key={index}
        title={x.title}
        action={x.action}
        blocked={x.blocked}
        faqCaption={x.faqCaption}
      />
    ) as React.ReactElement<StepProps>);
  }

  @autobind
  private onLastNext(): void {
    const { projectId } = this.props.match.params;
    const url = AppUrls.plan.project.scenario.listing.url({ projectId });
    this.props.navigateTo(url);
  }

  @autobind
  private saveAction(step: ScenarioEditPage): void {
    const { projectId, scenarioId } = this.props.match.params;
    // Optimizate: check if is it changed
    switch (step) {
      case ScenarioEditPage.WorkPackages:
        this.props.saveWorkPackages(parseInt(projectId, 10), parseInt(scenarioId, 10));
        break;
      case ScenarioEditPage.Resources:
        this.props.saveLimitations();
        break;
      case ScenarioEditPage.Sequence:
        if (!this.props.ability.can(Operation.Read, Subject.SequenceStep)) {
          this.props.setIndirectSettings(this.props.scenarioId);
        }
        break;
      case ScenarioEditPage.Contractor:
        this.props.setIndirectSettings(this.props.scenarioId);
        break;
      default:
    }
  }

  @autobind
  private workPackageAction(): void {
    const url = AppUrls.plan.project.scenario.workpackages.url(this.props.match.params);
    this.props.navigateTo(url);
  }

  @autobind
  private limitationsAction(): void {
    const url = AppUrls.plan.project.scenario.resourceLimitations.url(this.props.match.params);
    this.props.navigateTo(url);
  }

  @autobind
  private sequenceAction(): void {
    const url = AppUrls.plan.project.scenario.sequence.url(this.props.match.params);
    this.props.navigateTo(url);
  }

  @autobind
  private mainConsCostAction(): void {
    const url = AppUrls.plan.project.scenario.indirectCosts.url(this.props.match.params);
    this.props.navigateTo(url);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editScenario = state.scenarios.editScenario;

  return {
    scenarioId: editScenario ? editScenario.id : null,
    workpackagesLoaded:
      state.workpackages.statuses.packages === RequestStatus.Loaded &&
      editScenario != null,
    resourceLimitationsLoaded:
      state.scenarios.statuses.resourceLimitations === RequestStatus.Loaded &&
      state.scenarios.statuses.savePackages === RequestStatus.Loaded &&
      editScenario != null,
    activitiesGroupsLoaded:
      true ||
      state.activityGrouping.statuses.activityGrouping === RequestStatus.Loaded &&
      editScenario != null,
    indirectCostLoaded:
      state.scenarios.statuses.indirectCost === RequestStatus.Loaded &&
      editScenario != null,
    scenarioStatus: editScenario && editScenario.status,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    navigateTo: (link: string): void => {
      dispatch(push(link));
    },
    saveWorkPackages: (projectId: number, scenarioId: number): void => {
      dispatch(WorkPackageAction.setRequest(projectId, scenarioId));
      dispatch(ScenariosActions.setChangeWPRequest());
    },
    saveLimitations: (): void => {
      dispatch(ScenariosActions.setResourceLimitationRequest());
    },
    setIndirectSettings: scenarioId => {
      dispatch(ScenariosActions.setIndirectSettings(scenarioId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ScenarioHeader = withRouter(withAbilityContext(connector(ScenarioHeaderComponent)));
