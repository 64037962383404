import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './database-resource-type-navigation-list-item.scss';

import { ResourceType } from '../../enums';


interface Props {
  title: string;
  type: ResourceType;
  onSelect: (type: ResourceType) => void;
  isSelected: boolean;
}

export class DatabaseResourceTypeNavigationListItem extends React.Component<Props> {
  public render(): JSX.Element {
    const className = classNames('database-resource-type-navigation-list-item', {
      'database-resource-type-navigation-list-item--selected': this.props.isSelected,
    });

    return (
      <div className={className} onClick={this.selectType}>
        {this.props.title}
        {/* <span className='database-resource-type-navigation-list-item__count'>22</span> */}
      </div>
    );
  }

  @autobind
  private selectType(): void {
    this.props.onSelect(this.props.type);
  }
}

