import { Alignment } from './alignment';
import { ComparableDocumentType, ComparisonLine } from './interfaces';

export function updateCompareDocumentProperties(
  document: ComparableDocumentType,
  settings: {
    sourceLine?: ComparisonLine,
    targetLine?: ComparisonLine,
    isActive?: boolean,
  },
): void {
  document.isComparisonEnabled = settings.isActive;
  if (settings.isActive) {
    const transform = settings.sourceLine && settings.targetLine
      ? Alignment.getLinesTransform(settings.sourceLine, settings.targetLine)
      : {
        scale: 1,
        rotation: 0,
        translation: { x: 0, y: 0 },
      };
    document.compareTransform = transform;
  }
}
