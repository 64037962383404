import React from 'react';

import { CommentaryThread } from '../interfaces';
import { useCommentsContext } from './hooks';

export interface WithCommentsContext {
  comments: CommentaryThread[];
}

export function withCommentsContext<P>(
  Component: React.ComponentType<P & WithCommentsContext>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof WithCommentsContext>>> {
  return (props: P) => {
    const comments = useCommentsContext();
    return <Component {...props} comments={comments} />;
  };
}
