import { SvgComponent } from '@kreo/kreo-ui-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import {
  DrawingsAggregatedSelection,
  DrawingsGeometryInstance,
  DrawingsSelectAggregationGroup,
  ShortPointDescription,
} from 'common/components/drawings/interfaces';
import { DrawingsMenuUtils } from 'common/components/drawings/utils/drawings-menu-utils';
import { State } from 'common/interfaces/state';
import { useCanBoolWithPolygons, useCanMagicSearch, useHasRendered } from '../../hooks';
import { useGetCanJoin } from '../../hooks/use-can-join';
import { FlyingMenuActionKeys } from '../constants/action-keys';
import { AvailabilityKeys } from '../constants/availability-keys';
import { MENU_ITEMS } from '../constants/menu-items';

interface AvailabilityPayload {
  aggregatedSelection: Record<DrawingsSelectAggregationGroup, DrawingsAggregatedSelection>;
  selectedType: DrawingsSelectAggregationGroup;
  selectedInstances: string[];
  canTraceLink: boolean;
}

function useNotIntersectedAtStart(selectedInstances: string[]): boolean {
  const geometries = useSelector<State, Record<string, DrawingsGeometryInstance>>(
    (x) => x.drawings.aiAnnotation.geometry,
  );
  const points = useSelector<State, Record<string, ShortPointDescription>>((x) => x.drawings.aiAnnotation.points);

  return useMemo(() => {
    const instance = geometries[selectedInstances[0]];
    // todo: remove this in https://kreosoftware.atlassian.net/browse/KREOP-15451
    if (!instance) {
      return false;
    }
    const firstPoint = points[instance.geometry.points[0]];
    return (
      firstPoint &&
      instance.geometry.points.some((pointId) => {
        if (!points[pointId]) {
          return false;
        }
        const [x, y] = points[pointId];
        return firstPoint[0] !== x || firstPoint[1] !== y;
      })
    );
  }, [geometries, selectedInstances, points]);
}

function useAvailability({
  aggregatedSelection,
  selectedType,
  selectedInstances,
  canTraceLink,
}: AvailabilityPayload): Record<AvailabilityKeys, boolean> {
  const canEditMeasurements = useAbility(Operation.Update, Subject.Takeoff2DMeasurement);
  const isVisible = useHasRendered(selectedInstances);
  const notIntersectedAtStart = useNotIntersectedAtStart(selectedInstances);
  const canReadAutoCount = useAbility(Operation.Read, Subject.Takeoff2dAutoCount);
  const canUseAutoCount = useMemo(
    () => canReadAutoCount && aggregatedSelection.Area?.count === 1,
    [aggregatedSelection, canReadAutoCount],
  );

  const getCanJoin = useGetCanJoin(selectedInstances);

  const canJoin = useMemo(() => {
    if (!selectedType) {
      return false;
    }
    const types = DrawingsMenuUtils.AGGREGATION_TO_INSTANCE_TYPES[selectedType];
    return getCanJoin(types[0]);
  }, [getCanJoin, selectedType]);

  const canBoolWithPolygons = useCanBoolWithPolygons(selectedInstances);
  const canMagicSearch = useCanMagicSearch(selectedInstances);

  return useMemo(() => {
    return {
      [AvailabilityKeys.EditMeasurements]: canEditMeasurements,
      [AvailabilityKeys.OnlyOneSelected]: aggregatedSelection[selectedType]?.count === 1,
      [AvailabilityKeys.MoreThanOneSelected]: aggregatedSelection[selectedType]?.count > 1,
      [AvailabilityKeys.Rendered]: isVisible,
      [AvailabilityKeys.NotIntersectedAtStart]: notIntersectedAtStart,
      [AvailabilityKeys.IsArea]: selectedType === DrawingsSelectAggregationGroup.Area,
      [AvailabilityKeys.IsLine]: selectedType === DrawingsSelectAggregationGroup.Line,
      [AvailabilityKeys.IsCount]: selectedType === DrawingsSelectAggregationGroup.Count,
      [AvailabilityKeys.NotCount]: selectedType && selectedType !== DrawingsSelectAggregationGroup.Count,
      [AvailabilityKeys.NotArea]: selectedType && selectedType !== DrawingsSelectAggregationGroup.Area,
      [AvailabilityKeys.CanBoolWithPolygons]: canBoolWithPolygons,
      [AvailabilityKeys.CanJoin]: canJoin,
      [AvailabilityKeys.CanUseAutoCount]: canUseAutoCount,
      [AvailabilityKeys.CanTraceLink]: canTraceLink,
      [AvailabilityKeys.CanMagicSearch]: canMagicSearch,
    };
  }, [
    canEditMeasurements,
    aggregatedSelection,
    selectedType,
    selectedInstances,
    isVisible,
    notIntersectedAtStart,
    canJoin,
    canTraceLink,
    canMagicSearch,
    canUseAutoCount,
    canBoolWithPolygons,
  ]);
}


export interface FlyingMenuOption {
  tooltip: string;
  actionKey: FlyingMenuActionKeys;
  icon: SvgComponent;
}

export function useFlyingMenuItems(availabilityPayload: AvailabilityPayload): FlyingMenuOption[] {
  const availability = useAvailability(availabilityPayload);

  return useMemo(() => {
    return MENU_ITEMS.filter((item) => item.availability.every((key) => availability[key]));
  }, [ availability]);
}
