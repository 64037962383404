const locationsTypes = [
  'Site',
  'Building',
  'Block',
  'Level',
  'Functional_zone',
  'Area',
  'Sub_area',
  'Room',
  'HVAC_zone',
  'MEP_space',
  'Undefined location',
];

export const isLocation = (objComplex: string): boolean => objComplex !== 'Element'
  && objComplex !== 'Layer'
  && locationsTypes.includes(objComplex);
