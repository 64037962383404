import styled, { StyledComponent } from 'styled-components';

import { DataControlNameAttribute } from 'common/constants/control-names';

const Wrapper: StyledComponent<'div', any, { controlName: string }>
= styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  top: 445px;
  left: 775px;
  width: 370px;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 15px 40px #0000001F;
  border-radius: 40px;
  background-color: ${props => props.theme.color.backgroundRush};
  box-sizing: border-box;
  padding: 25px;
`;

const TextDialog: StyledComponent<'div', any, { controlName: string }>
= styled.div.attrs<{ controlName: string }>(props => ({
  [DataControlNameAttribute]: props.controlName,
}))`
  display: contents;
  min-width: 290px;
  min-height: 20px;
  flex-grow: 1;
  text-align: center;

  p {
    font-size: 18px;
    letter-spacing: 0px;
  }
`;


const ReconnectButton: StyledComponent<'div', any, { controlName: string }> =
  styled.div.attrs<{ controlName: string }>(props => ({ [DataControlNameAttribute]: props.controlName }))`
  display: contents;
  height: 100%;
  width: 100%;
`;

export const Styled = {
  Wrapper,
  TextDialog,
  ReconnectButton,
};
