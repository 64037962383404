import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { useRendererApiContext } from '../../drawings-contexts';
import { useCurrentDrawingInfo, useInstances } from '../../hooks';
import { DrawingsBooleanOperationsUtils } from '../../utils/drawings-boolean-operations-utils';

export function useCanBoolWithPolygons(instancesIds: string[]): boolean {
  const { rendererApi } = useRendererApiContext();
  const instances = useInstances();
  const hiddenIds = useSelector<State, string[]>((s) => s.drawings.hiddenInstances);
  const currentDrawing = useCurrentDrawingInfo();
  const currentDrawingId = currentDrawing?.drawingId;
  const hasRendererApi = !!rendererApi;

  return useMemo(
    () => {
      return (
        hasRendererApi &&
        DrawingsBooleanOperationsUtils.canBoolWithPolygons({
          hiddenInstances: hiddenIds,
          currentDrawingId,
          selectedInstances: instancesIds,
          getInstance: (instanceId: string) => {
            return instances[instanceId];
          },
        })
      );
    },
    [instancesIds, currentDrawingId, instances, hiddenIds, hasRendererApi],
  );
}
