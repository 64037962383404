import * as React from 'react';

import { CanvasStatuses } from './canvas-statuses';
import { DrawingsLoadingFailedMessage } from './drawings-loading-failed-message';
import { FailedOptimizeMessage } from './failed-optimize-message';
import { FailedRenderMessage } from './failed-render-message/failed-render-message';
import { SpinnerWrapper } from './spinner-wrapper';

interface Props {
  status: CanvasStatuses;
}

export const Statuses: Record<CanvasStatuses, React.ComponentType> = {
  [CanvasStatuses.Loaded]: null,
  [CanvasStatuses.OptimizationFailed]: FailedOptimizeMessage,
  [CanvasStatuses.Loading]: SpinnerWrapper,
  [CanvasStatuses.LoadingFailed]: DrawingsLoadingFailedMessage,
  [CanvasStatuses.RenderFailed]: FailedRenderMessage,
};


export const DrawingsLoadingStatus: React.FC<Props> = ({ status }) => {
  const Component = Statuses[status];
  return Component ? <Component /> : null;
};
