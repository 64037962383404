import { UploadingFile } from 'common/interfaces/common-state';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import { ModelManagementActionTypes } from '../actions/types/model-management';
import { MODEL_MANAGEMENT_INITIAL_STATE } from '../constants/model-management-initial-state';
import { ModelManagementFileData } from '../interfaces/model-management/model-management-data';
import { ModelManagementState } from '../interfaces/model-management/state';

export const modelManagementReducerMethods: ReducerMethods<ModelManagementState> = {
  [ModelManagementActionTypes.LOAD_DATA]: (state) => new MonoliteHelper(state).set(_ => _.isLoading, true).get(),
  [ModelManagementActionTypes.LOAD_DATA_SUCCESS]: (
    state,
    models: ModelManagementFileData[],
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.models, models)
      .set(_ => _.isLoading, false)
      .get();
  },
  [ModelManagementActionTypes.ADD_FILES]: (state, files: UploadingFile[]) => {
    return new MonoliteHelper(state)
      .setConcat(_ => _.temporaryModels, files)
      .set(_ => _.isApplyFailed, false)
      .get();
  },
  [ModelManagementActionTypes.DELETE_FILE]: (state, id: number | string) => {
    const model = state.models.find(x => x.id === id);
    const helper = new MonoliteHelper(state);
    if (model) {
      helper
        .setFilter(_ => _.models, _ => _.id !== id)
        .setAppend(_ => _.modelsForRemove, model);
    }
    return helper
      .setFilter(_ => _.temporaryModels, _ => _.uploadedFileName !== id)
      .set(_ => _.isApplyFailed, false)
      .get();
  },
  [ModelManagementActionTypes.DROP_PAGE_STATE]: () => MODEL_MANAGEMENT_INITIAL_STATE,
  [ModelManagementActionTypes.SELECT_FROM_ENGINE]: (state, ids: number[]) => {
    return new MonoliteHelper(state)
      .set(
        _ => _.selectedFileIds,
        arrayUtils.filterMap(state.models, x => x.bimElementIds.some(id => ids.includes(id)), x => x.id),
      )
      .get();
  },
  [ModelManagementActionTypes.APPLY_CHANGES_FAILED]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.isApplyFailed, true)
      .get();
  },
  [ModelManagementActionTypes.APPLY_CHANGES]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.isApplyFailed, false)
      .get();
  },
  [ModelManagementActionTypes.SELECT_FILE]: (state, id: number) => {
    return new MonoliteHelper(state)
      .set(
        _ => _.selectedFileIds,
        [id],
      )
      .get();
  },
};
