import * as React from 'react';

import { Avatar as Ava } from 'common/UIKit';
import { TimeStamp } from '../time-stamp';

const Avatar: any = Ava;

import './sender.scss';

interface Props {
  firstName: string;
  lastName: string;
  avatar: number;
  timeStamp: Date;
}

export const Sender: React.FC<Props> = (props) => {
  return (
    <div className='viewer-sender-container'>
      <Avatar
        firstName={props.firstName}
        lastName={props.lastName}
        avatar={props.avatar}
      />
      <span
        className='sender-username'
        title={`${props.firstName} ${props.lastName}`}
      >
        {props.firstName} {props.lastName}
      </span>
      <TimeStamp stamp={props.timeStamp}/>
    </div>
  );
};
