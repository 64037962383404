import { QtoRecord } from '../../../units/projects/components/quantity-take-off-left-panel/interfaces';

export enum FilterByEngineFilter {
  UseEngineFilter = 'UseEngineFilter',
}

const useActiveCustomFilter = (record: QtoRecord, filtrationFunction: (record: QtoRecord) => boolean): boolean => {
  if (!filtrationFunction) {
    return true;
  }
  return filtrationFunction(record);
};

export const filterByCustomFilter: Record<
  FilterByEngineFilter,
  (record: QtoRecord, filtrationFunction: (record: QtoRecord) => boolean) => boolean
> = {
  [FilterByEngineFilter.UseEngineFilter]: useActiveCustomFilter,
};
