import { IconButton, SvgComponent, ElementTooltip } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { DeferredExecutor } from 'common/utils/deferred-executer';

import { Styled } from './styled';

const DOUBLE_CLICK_DELAY = 200;

interface Props {
  active: boolean;
  tooltipId?: string;
  activeIcon: SvgComponent;
  icon: SvgComponent;
  activeTooltip: string;
  tooltip: string;
  size?: number;
  onToggle: () => void;
  onDoubleClick?: () => void;
}

export class ToggleButton extends React.PureComponent<Props> {
  private executor: DeferredExecutor = new DeferredExecutor(DOUBLE_CLICK_DELAY);
  private prevent: boolean = false;

  public render(): JSX.Element {
    const { active, tooltipId, activeIcon, tooltip, activeTooltip, icon, size } = this.props;
    return (
      <Styled.VisibleToggle
        onDoubleClick={this.onDoubleClick}
        onClick={this.onVisibleToggle}
      >
        <ElementTooltip
          id={tooltipId}
          text={active ? activeTooltip : tooltip}
          position='top'
          speed='l'
        >
          <IconButton
            Icon={active ? activeIcon : icon}
            width={size}
            height={size}
            iconHeight={size}
          />
        </ElementTooltip>
      </Styled.VisibleToggle>
    );
  }

  @autobind
  private execChange(): void {
    if (!this.prevent) {
      this.props.onToggle();
    } else {
      this.prevent = false;
    }
  }

  @autobind
  private onVisibleToggle(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.executor.execute(this.execChange);
  }

  @autobind
  private onDoubleClick(): void {
    this.prevent = true;
    if (this.props.onDoubleClick) {
      this.props.onDoubleClick();
    }
  }
}
