import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './styles.scss';

import { RequestStatus } from 'common/enums/request-status';
import { RoleCode } from 'common/enums/role-code';
import { State as ReduxState } from 'common/interfaces/state';
import { MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { ShortRole } from '../../interfaces/short-role';
import { ShortUserWithRole } from '../../interfaces/short-user';


export interface AttachedUser {
  guid: string;
  roleCode: RoleCode;
}

interface ReduxProps {
  roles: ShortRole[];
  loadRolesStatus: RequestStatus;
}

interface DialogUserItemProps extends ReduxProps {
  shortUser: ShortUserWithRole;
  onAttach?: (userGuid: string, roleId: number) => void;
  onDetach?: (userGuid: string) => void;
  onChangeRole?: (userGuid: string, roleId: number) => void;
  attached?: AttachedUser;
}

interface State {
  selectedRoleId: number;
}

class DialogUserItemComponent extends React.Component<DialogUserItemProps, State>  {
  constructor(props: DialogUserItemProps) {
    super(props);

    let role: ShortRole;
    if (props.attached) {
      role = props.roles.find((r) => r.code === props.attached.roleCode);
      this.state = {
        selectedRoleId: role ? role.id : (props.attached.roleCode === RoleCode.Administrator ? -1 : null),
      };
    } else {
      this.state = {
        selectedRoleId: null,
      };
    }
  }

  public componentDidUpdate(prevProps: DialogUserItemProps): void {
    if (
      prevProps.loadRolesStatus === RequestStatus.Loaded &&
      this.props.loadRolesStatus === RequestStatus.Loading
    ) {
      if (this.props.attached) {
        const role = this.props.roles.find((r) => r.code === this.props.attached.roleCode);

        this.setState({
          selectedRoleId: role ? role.id : (this.props.attached.roleCode === RoleCode.Administrator ? -1 : null),
        });
      }
    }
  }

  public render(): React.ReactNode {
    return (
      <div className='admin-item with-select'>
        <div className='admin-item__information'>
          <span className='admin-item__head two-lines'>
            {`${this.props.shortUser.firstName} ${this.props.shortUser.lastName}`}
            <span className='admin-item__secondary'> (
              {`${this.props.shortUser.email}`})
            </span>
          </span>
          <span className='admin-item__select-wrapper'>
            <MaterialSelect
              label='Role for employee'
              value={this.state.selectedRoleId}
              onChange={this.onChangeRole}
              autoWidth={true}
              disabled={this.props.attached && this.props.attached.roleCode === RoleCode.Administrator}
            >
              {this.props.attached && this.props.attached.roleCode === RoleCode.Administrator ? [
              <MaterialMenuItem key='admin-option' value={-1}>Administrator</MaterialMenuItem>] :
                this.props.roles &&
              this.props.roles.map((r) => (
                <MaterialMenuItem key={r.id} value={r.id}>{r.name}</MaterialMenuItem>
              ))}
            </MaterialSelect>
          </span>
        </div>
      </div>
    );
  }

  @autobind
  private onChangeRole(_e: React.SyntheticEvent<{}>, newRoleId: number): void {
    this.setState({
      selectedRoleId: newRoleId,
    },            () => {
      if (this.state.selectedRoleId) {
        if (this.props.attached) {
          if (this.props.onChangeRole) {
            this.props.onChangeRole(
              this.props.shortUser.guid,
              this.state.selectedRoleId,
            );
          }
        } else {
          this.props.onAttach(this.props.shortUser.guid, this.state.selectedRoleId);
        }
      } else if (this.props.attached) {
        this.props.onDetach(this.props.shortUser.guid);
      }
    });
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const companiesState = state.admin.companies;
  const detachOption: ShortRole = { id: null, name: '', code: null };
  return {
    roles: [detachOption, ...companiesState.selectedCompanyRoles],
    loadRolesStatus: companiesState.statuses.loadCompanyRoles,
  };
};

export const DialogUserItem = connect(mapStateToProps)(DialogUserItemComponent);
