import { createSlice } from '@reduxjs/toolkit';

import { ActionWith } from 'common/interfaces/action-with';
import { ProgressBarState, ProgressReduxState } from '../interfaces';

export const INITIAL_STATE: ProgressReduxState = {};
export const PROGRESS_STORE_PREFIX = '@progress';

const Progress = createSlice({
  name: PROGRESS_STORE_PREFIX,
  initialState: INITIAL_STATE,
  reducers: {
    addOrUpdateProgress: (state, action: ActionWith<{ progressKey: string, progressBar: ProgressBarState }>) => {
      const { payload } = action;
      state[payload.progressKey] = state[payload.progressKey] || { progressBars: [] };
      const progressBarIndex =
        state[payload.progressKey].progressBars.findIndex(p => p.title === payload.progressBar.title);
      if (progressBarIndex !== -1) {
        state[payload.progressKey].progressBars[progressBarIndex] = payload.progressBar;
      } else {
        state[payload.progressKey].progressBars.push(payload.progressBar);
      }
      return state;
    },
    increaseFinishedAmout: (
      state,
      action: ActionWith<{ progressKey: string, progressBarTitle: string, amount: number }>,
    ) => {
      const { payload } = action;
      const progressState = state[payload.progressKey] || { progressBars: [] };
      const progressBarIndex =
        progressState.progressBars.findIndex(p => p.title === payload.progressBarTitle);
      if (progressBarIndex !== -1) {
        const finished = progressState.progressBars[progressBarIndex].finished || 0;
        progressState.progressBars[progressBarIndex].finished = finished + payload.amount;
      }
      return state;
    },
    updateProgress: (
      state,
      action: ActionWith<{
        progressKey: string,
        progressBar: { title: string } & Partial<ProgressBarState>,
      }>,
    ) => {
      const { payload } = action;
      const progressState = state[payload.progressKey] || { progressBars: [] };
      const progressBarIndex =
        progressState.progressBars.findIndex(p => p.title === payload.progressBar.title);
      if (progressBarIndex !== -1) {
        progressState.progressBars[progressBarIndex] = {
          ...state[payload.progressKey].progressBars[progressBarIndex],
          ...payload.progressBar,
        };
        state[payload.progressKey] = progressState;
      }
      return state;
    },
    removeProgress: (state, { payload }: ActionWith<string>) => {
      delete state[payload];
      return state;
    },
    removeProgressBar: (state, { payload }: ActionWith<{ progressKey: string, progressBarTitle: string }>) => {
      const progressBarIndex = state[payload.progressKey]
        ?.progressBars.findIndex(p => p.title === payload.progressBarTitle);
      if (progressBarIndex !== undefined && progressBarIndex !== -1) {
        state[payload.progressKey].progressBars.splice(progressBarIndex, 1);
      }
      return state;
    },
  },
});

export const ProgressActions = Progress.actions;
export const progressReducers = Progress.reducer;
