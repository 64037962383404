import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const FooterContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  padding: 0 15px 15px 0;
  background-color: ${p => p.theme.color.background};
`;

const FooterCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  ${Text} {
    font-size: 14px;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 140px;
`;

export const Styled = {
  FooterContainer,
  FooterCell,
  Description,
  Value,
};
