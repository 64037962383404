import { useCallback } from 'react';

type ToggleGroupsCallback = (groups: Set<string>, setGroups: (groups: Set<string>) => void) =>
(id: string, isOpen: boolean) => void;

export const useToggleGroupsCallBack: ToggleGroupsCallback = (groups, setGroups) => {
  return useCallback((id, isOpen) => {
    const groupsCopy = new Set(groups);
    if (isOpen) {
      groupsCopy.delete(id);
    } else {
      groupsCopy.add(id);
    }
    setGroups(groupsCopy);
  }, [groups, setGroups]);
};
