import { MultilevelSelectOptionDataWithObjectValue } from 'common/components/multi-level-drop-down-menu';
import { arrayUtils } from 'common/utils/array-utils';
import { MoveToCellOptionData, MoveToCellOptionType } from '../drawings-canvas-menus';
import { DrawingsInstanceType } from '../enums';
import { DrawingsGeometryGroup, DrawingsInstanceMeasure, DrawingsShortInfo } from '../interfaces';
import { DrawingsGeometryState } from '../interfaces/drawings-state';
import { DrawingsMenuUtils } from '../utils/drawings-menu-utils';

type GetInstanceMeasuresWithElementMeasurement = (
  instancesIds: Array<string | string[]>,
  drawings: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  elementMeasurement: Record<string, DrawingsInstanceMeasure>,
) => DrawingsInstanceMeasure[];

export const getMeasureMap = (
  selectedInstances: Iterable<string>,
  elementMeasurement: Record<string, DrawingsInstanceMeasure>,
  drawings: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  getInstanceMeasures: GetInstanceMeasuresWithElementMeasurement,
): Record<string, number> => {
  const measuresMap: Record<string, number> = {};
  for (const instanceId of selectedInstances) {
    const instances = getInstanceMeasures(
      [instanceId],
      drawings,
      aiAnnotation,
      elementMeasurement,
    );
    if (!instances || !instances.length) {
      continue;
    }
    const instance = instances[0];
    for (const [key, value] of Object.entries(instance.measures)) {
      if (measuresMap[key]) {
        measuresMap[key] += value;
      } else {
        measuresMap[key] = value;
      }
    }
    if (instance.type !== DrawingsInstanceType.Count) {
      measuresMap.count = (measuresMap.count || 0) + 1;
    }
  }

  return measuresMap;
};

const getMeasureFolderToCell = (
  selectedInstances: Iterable<string>,
  elementMeasurement: Record<string, DrawingsInstanceMeasure>,
  drawings: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  getInstanceMeasures: GetInstanceMeasuresWithElementMeasurement,
): Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>> => {
  const measuresMap = getMeasureMap(
    selectedInstances,
    elementMeasurement,
    drawings,
    aiAnnotation,
    getInstanceMeasures,
  );
  const result = [];
  result.push(
    DrawingsMenuUtils.makeMenuItem('count', {
      type: MoveToCellOptionType.count,
      value: measuresMap.count || 0,
    }),
    DrawingsMenuUtils.makeMenuItem('length', {
      type: MoveToCellOptionType.length,
      value: measuresMap.length || 0,
    }),
    DrawingsMenuUtils.makeMenuItem('perimeter', {
      type: MoveToCellOptionType.perimeter,
      value: measuresMap.perimeter || 0,
    }),
    DrawingsMenuUtils.makeMenuItem('area', {
      type: MoveToCellOptionType.area,
      value: measuresMap.area || 0,
    }),
    DrawingsMenuUtils.makeMenuItem('vertical area', {
      type: MoveToCellOptionType.verticalArea,
      value: measuresMap.verticalArea || 0,
    }),
    DrawingsMenuUtils.makeMenuItem('volume', {
      type: MoveToCellOptionType.volume,
      value: measuresMap.volume || 0,
    }),
    DrawingsMenuUtils.makeMenuItem('points count', {
      type: MoveToCellOptionType.pointsCount,
      value: measuresMap.pointsCount || 0,
    }),
    DrawingsMenuUtils.makeMenuItem('segments count', {
      type: MoveToCellOptionType.segmentsCount,
      value: measuresMap.segmentsCount || 0,
    }),
  );
  return result;
};

export const getFolderToCellOptions = (
  drawingsGroups: DrawingsGeometryGroup[],
  selectedGroups: string[],
  elementMeasurement: Record<string, DrawingsInstanceMeasure>,
  drawings: Record<string, DrawingsShortInfo>,
  aiAnnotation: DrawingsGeometryState,
  getInstanceMeasures: GetInstanceMeasuresWithElementMeasurement,
): Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>> => {
  const selectedGroup = drawingsGroups.find((x) => selectedGroups[0] === x.id);
  if (!selectedGroup) {
    return getMeasureFolderToCell([], elementMeasurement, drawings, aiAnnotation, getInstanceMeasures);
  }
  const measuresIterator = getGroupMeasuresIterator(selectedGroup, drawingsGroups);
  return getMeasureFolderToCell(measuresIterator, elementMeasurement, drawings, aiAnnotation, getInstanceMeasures);
};

function *getGroupMeasuresIterator(
  group: DrawingsGeometryGroup,
  drawingsGroups: DrawingsGeometryGroup[],
): IterableIterator<string> {
  const groups = [group];
  while (groups.length) {
    const currentGroup = groups.pop();
    for (const measureId of currentGroup.measurements) {
      yield measureId;
    }
    const childIter = arrayUtils.filterIterator(drawingsGroups, x => x.parentId === currentGroup.id);
    arrayUtils.extendArray(groups, childIter);
  }
}
