import { DrawingContextObserver } from './drawing-context-observer';

export class DrawingContextObserverWithPrev<T> extends DrawingContextObserver<T> {
  private prevContext: T;

  public updateContext(context: T): void {
    this.prevContext = this.context;
    this.context = context;
    this.updateView();
  }

  public getPrevContext(): T {
    return this.prevContext || this.context;
  }

  public resetWithContext(context: T): void {
    this.updateContext(context);
    this.prevContext = context;
  }
}
