import { BreakdownProperty, PiaBreakdownNode, PropertyTypeEnum } from '../../../../../../interfaces';
import { Field, FieldType } from '../../interfaces';

export interface FormulaFieldInput {
  onClick: () => void;
  value: string;
}

export const mapBreakdownPropertyToField = (
  property: BreakdownProperty,
  originId: string,
  onChange: () => void,
  onDeleteClick: () => void,
  onVisibilityClick: () => void,
  name: string,
  root: PiaBreakdownNode[],
): Field<any> => {
  return {
    id: property.id,
    originId,
    name: property.name,
    type: FieldType.Button,
    isHide: false,
    input: {
      value: name,
      selectedId: property.value.value?.toString(),
      onClick: onChange,
      root,
    },
    onDeleteClick,
    onVisibilityClick,
    getDataUpdate: (field: Field<any>) => {
      return {
        ...property,
        id: field.id,
        value: {
          format: property.value.format,
          value: field.input.selectedId,
          root: [],
          type: PropertyTypeEnum.BreakdownLink,
        },
      };
    },
  };
};
