import { RequestStatus } from 'common/enums/request-status';
import { TwoDDataBaseState } from 'unit-2d-database/interfaces';

export const setLoadingStatus = (s: TwoDDataBaseState): void => {
  s.requestStatus = RequestStatus.Loading;
};

export const setLoadedStatus = (s: TwoDDataBaseState): void => {
  s.requestStatus = RequestStatus.Loaded;
};
