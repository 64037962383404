import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { State } from 'common/interfaces/state';
import * as creators from 'unit-cost-estimate/actions/creators';
import { RevisionsActions } from 'unit-cost-estimate/actions/creators';
import { CEMeasurementsActionTypes } from 'unit-cost-estimate/actions/types';
import { MeasurementsApi as CEMeasurementsApi } from 'unit-cost-estimate/api/measurements';
import { CEMeasurementsState } from 'unit-cost-estimate/interfaces';
import { CEMeasurementsUtils } from 'unit-cost-estimate/utils';


function* getData(): SagaIterator {
  try {
    const data = yield call(CEMeasurementsApi.getData);
    const preparedData = CEMeasurementsUtils.extractData(data);
    yield put(creators.CEMeasurementsActions.loadDataSuccess(preparedData));
  } catch (error) {
    console.error('measurement: get data failed', error);
  }
}

function* getStatistic(action: ActionWith<number>): SagaIterator {
  try {
    const statistic = yield call(CEMeasurementsApi.getStatistic);
    yield put(creators.CEMeasurementsActions.getStatisticSuccess(statistic));
  } catch (error) {
    console.error('measurement: get statistic failed', error, action.payload);
  }
}

function selectMeasurementsState(state: State): CEMeasurementsState {
  return state.ceMeasurements;
}

function* onEditBlur(): SagaIterator {
  try {
    const measurementsState: CEMeasurementsState = yield select(selectMeasurementsState);
    const { uncommittedChanges, modelBrowser, rootIds, extractors } = measurementsState;
    if (!Object.values(uncommittedChanges).length) {
      return;
    }
    const changes = CEMeasurementsUtils.getChangesForm(uncommittedChanges, extractors, modelBrowser, rootIds);
    if (changes && changes.length) {
      yield call(CEMeasurementsApi.bulkUpdateMeasurement, changes);
    }
    yield put(creators.CEMeasurementsActions.commitChangesSuccess());
  } catch (error) {
    console.error('measurement: on edit blur failed', error);
  }
}

function* cancel(): SagaIterator {
  try {
    const measurementsState: CEMeasurementsState = yield select(selectMeasurementsState);
    const { cachedMeasurementsValues, modelBrowser, rootIds } = measurementsState;
    if (measurementsState.hasChanges) {
      const changes = CEMeasurementsUtils.getRevertChangesForm(cachedMeasurementsValues, modelBrowser, rootIds);
      yield call(CEMeasurementsApi.bulkUpdateMeasurement, changes);
    }
    yield put(creators.CEMeasurementsActions.cancelEditSuccess());
  } catch (error) {
    console.error('measurement: cancel failed', error);
  }
}

function* approve(): SagaIterator {
  try {
    const revisionState = yield call(CEMeasurementsApi.approve);
    yield put(RevisionsActions.setCurrentRevisionState(revisionState));
  } catch (error) {
    console.error('CE Activity Assignment: approve', error);
  }
}

function* disapprove(): SagaIterator {
  try {
    const revisionState = yield call(CEMeasurementsApi.disapprove);
    yield put(RevisionsActions.setCurrentRevisionState(revisionState));
  } catch (error) {
    console.error('CE Activity Assignment: disapprove', error);
  }
}


export function* ceMeasurementsSagas(): SagaIterator {
  yield takeLatest(CEMeasurementsActionTypes.LOAD_DATA_REQUEST, getData);
  yield takeLatest(CEMeasurementsActionTypes.GET_STATISTIC_REQUEST, getStatistic);
  yield takeLatest(CEMeasurementsActionTypes.ON_BLUR_EDIT, onEditBlur);
  yield takeLatest(CEMeasurementsActionTypes.CANCEL_EDIT, cancel);
  yield takeLatest(CEMeasurementsActionTypes.APPROVE, approve);
  yield takeLatest(CEMeasurementsActionTypes.DISAPPROVE, disapprove);
}
