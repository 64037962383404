import { RequestStatus } from 'common/enums/request-status';
import { StringDictionary } from 'common/interfaces/dictionary';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import {
  OpenEditActivityVariantPayload, SelectActivityVariantPayload,
} from '../actions/payloads/database-activity-listing';
import { DatabaseActivityListingActionTypes } from '../actions/types/database-activity-listing';
import { ActivityCategoryType, ModifyDatabaseEntityMode } from '../enums';
import { ActivityCategory, ActivityModel, ActivityVariantModel } from '../interfaces/data';
import { DatabaseReduxState } from '../interfaces/redux-state';


export const DatabaseActivityListingReducerMethods: ReducerMethods<DatabaseReduxState> = {
  [DatabaseActivityListingActionTypes.OPEN_CREATE_ACTIVITY]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.root, null)
      .set(_ => _.currentDatabase.editModel.variant, null)
      .set(_ => _.currentDatabase.editModel.rootId, null)
      .set(_ => _.currentDatabase.editModel.variantId, null)
      .set(_ => _.currentDatabase.editModel.mode, ModifyDatabaseEntityMode.Create)
      .set(_ => _.currentDatabase.editModel.isOpen, true)
      .get();
  },
  [DatabaseActivityListingActionTypes.OPEN_CREATE_ACTIVITY_VARIANT]: (state, payload: number) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.root, null)
      .set(_ => _.currentDatabase.editModel.variant, null)
      .set(_ => _.currentDatabase.editModel.rootId, payload)
      .set(_ => _.currentDatabase.editModel.variantId, null)
      .set(_ => _.currentDatabase.editModel.mode, ModifyDatabaseEntityMode.Create)
      .set(_ => _.currentDatabase.editModel.isOpen, true)
      .get();
  },
  [DatabaseActivityListingActionTypes.OPEN_EDIT_ACTIVITY]: (state, payload: number) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.root, null)
      .set(_ => _.currentDatabase.editModel.variant, null)
      .set(_ => _.currentDatabase.editModel.rootId, payload)
      .set(_ => _.currentDatabase.editModel.variantId, null)
      .set(_ => _.currentDatabase.editModel.mode, ModifyDatabaseEntityMode.Edit)
      .set(_ => _.currentDatabase.editModel.isOpen, true)
      .get();
  },
  [DatabaseActivityListingActionTypes.OPEN_EDIT_ACTIVITY_VARIANT]: (state, payload: OpenEditActivityVariantPayload) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.root, null)
      .set(_ => _.currentDatabase.editModel.variant, null)
      .set(_ => _.currentDatabase.editModel.rootId, payload.activityId)
      .set(_ => _.currentDatabase.editModel.variantId, payload.activityVariantId)
      .set(_ => _.currentDatabase.editModel.mode, ModifyDatabaseEntityMode.Edit)
      .set(_ => _.currentDatabase.editModel.isOpen, true)
      .get();
  },
  [DatabaseActivityListingActionTypes.CLOSE_EDIT_PANEL]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.isOpen, false)
      .get();
  },
  [DatabaseActivityListingActionTypes.SET_SELECTED_CATEGORY]: (state, payload: ActivityCategory) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.activityListing.categories.selectedCategory, payload)
      .get();
  },
  [DatabaseActivityListingActionTypes.SET_SELECTED_CATEGORY_TYPE]: (state, payload: ActivityCategoryType) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.activityListing.categories.selectedCategoryType, payload)
      .get();
  },
  [DatabaseActivityListingActionTypes.RESET_REQUEST_STATUSES]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.statuses.activityListing, RequestStatus.NotRequested)
      .set(_ => _.statuses.categoriesCounts, {
        [ActivityCategoryType.Nrm1]: RequestStatus.NotRequested,
        [ActivityCategoryType.Nrm2]: RequestStatus.NotRequested,
        [ActivityCategoryType.UniProduct]: RequestStatus.NotRequested,
        [ActivityCategoryType.UniSystem]: RequestStatus.NotRequested,
      })
      .get();
  },
  [DatabaseActivityListingActionTypes.RESET_LOAD_ACTIVITY_REQUEST_STATUS]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.statuses.activityListing, RequestStatus.NotRequested)
      .get();
  },
  [DatabaseActivityListingActionTypes.SET_EDIT_ACTIVITY_MODEL]: (state, payload: ActivityModel) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.root, payload)
      .get();
  },
  [DatabaseActivityListingActionTypes.SET_EDIT_ACTIVITY_VARIANT_MODEL]: (state, payload: ActivityVariantModel) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.variant, payload)
      .get();
  },
  [DatabaseActivityListingActionTypes.SELECT_ACTIVITY_VARIANT]: (state, payload: SelectActivityVariantPayload) => {
    const helper = new MonoliteHelper(state);
    if (payload.value) {
      helper
        .setAppend(_ => _.currentDatabase.activityListing.selectedActivityVariantIds, payload.variantId);
    } else {
      helper
        .setFilter(_ => _.currentDatabase.activityListing.selectedActivityVariantIds, x => x !== payload.variantId);
    }
    return helper.get();
  },
  [DatabaseActivityListingActionTypes.SET_DISABLED_ACTIVITY_VARIANT_IDS]: (state, payload: number[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.activityListing.disabledActivityVariantIds, payload)
      .get();
  },
  [DatabaseActivityListingActionTypes.SET_SELECTED_ACTIVITY_VARIANT_IDS]: (state, payload: number[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.activityListing.selectedActivityVariantIds, payload)
      .get();
  },
  [DatabaseActivityListingActionTypes.SET_DEFAULT_CATEGORIES]: (state, payload: StringDictionary<ActivityCategory>) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.activityListing.defaultValues.categories, payload)
      .get();
  },
  [DatabaseActivityListingActionTypes.SET_LAST_SELECTED_EXTRACTOR_FUNCTION]: (state, payload: string) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.activityListing.defaultValues.lastSelectedExtractorFunction, payload)
      .get();
  },
  [DatabaseActivityListingActionTypes.SET_LAST_SELECTED_UNIT]: (state, payload: number) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.activityListing.defaultValues.lastSelectedUnitId, payload)
      .get();
  },
};
