import * as React from 'react';

interface Props {
  title: string;
  subTitle: string;
}

export class HeaderItem extends React.Component<Props> {
  public render(): JSX.Element {
    const { title, subTitle } = this.props;
    return (
      <div className='drop-menu__header'>
        <span className='drop-menu__header-title' title={title}>
          {title.toLowerCase()}
        </span>
        <span className='drop-menu__header-sub-title' title={subTitle}>
          {subTitle}
        </span>
      </div>);
  }
}
