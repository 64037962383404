import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './model-check-start-calculation-control.scss';

import { State } from 'common/interfaces/state';
import { KreoButton } from 'common/UIKit';
import { ProjectsActions } from '../../actions/creators/common';
import { KnownViewModel } from '../../enums/known-view-model';
import { ViewModelStatus } from '../../enums/view-model-status';
import { ModelCheckAvailableCheck } from '../../interfaces/model-check/model-check-available-checks';
import { ModelCheckActions } from '../../pages/model-check-view/actions';
import { SourceIssue } from '../../pages/model-check-view/interfaces/types';
import { ModelCheckStartCalculationControlCheck } from './model-check-start-calculation-control-check';

interface OwnProps {
  issues: SourceIssue[];
  projectId: number;
}

interface DispatchProps {
  getAvailableChecks: () => void;
  calculate: (values: string[]) => void;
  toggleCheckSelected: (value: string) => void;
}

interface StateProps {
  availableChecks: ModelCheckAvailableCheck[];
  selectedChecks: Record<string, boolean>;
}

interface Props extends DispatchProps, StateProps, OwnProps {
}

class ModelCheckStartCalculationComponent extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    const { issues, selectedChecks } = this.props;
    const checkedIssuesCount = issues && Object.values(issues).length;
    const selectedChecksCount = Object.values(selectedChecks).filter(x => x).length;
    const disabledCalculate = checkedIssuesCount >= selectedChecksCount;
    return (
      <div className='model-check-start-calculation-control'>
        <div className='model-check-start-calculation-control__body'>
          {this.renderIssues()}
        </div>
        <div className='model-check-start-calculation-control__actions'>
          <KreoButton
            size={'medium'}
            mode='submit'
            onClick={this.calculate}
            tooltip={disabledCalculate ? 'Select an item to check' : null}
            caption='Calculate'
            disabled={disabledCalculate}
          />
        </div>
      </div>
    );
  }

  public componentDidMount(): void {
    if (!this.props.availableChecks) {
      this.props.getAvailableChecks();
    }
  }

  @autobind
  private renderIssues(): React.ReactNode {
    if (this.props.availableChecks) {
      return this.props.availableChecks.map(x => {
        const issue = this.props.issues.find(item => item && item.modelCheckType === x.type);
        const count = issue && issue.count;
        return (
          <ModelCheckStartCalculationControlCheck
            disabled={!!issue}
            key={x.type}
            description={x}
            onChange={this.onChangeCheck}
            checked={this.props.selectedChecks[x.type]}
            count={count}
          />
        );
      });
    }
  }

  @autobind
  private calculate(): void {
    const keys = [];
    for (const [key, value] of Object.entries(this.props.selectedChecks)) {
      if (value) {
        keys.push(key);
      }
    }
    this.props.calculate(keys);
  }

  @autobind
  private onChangeCheck(type: string): void {
    this.props.toggleCheckSelected(type);
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    availableChecks: state.modelcheck.availableChecks,
    selectedChecks: state.modelcheck.selectedChecks,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
  return {
    getAvailableChecks: () => dispatch(ModelCheckActions.getAvailableChecks()),
    calculate: values => {
      dispatch(ProjectsActions.updateViewModelStatus({
        projectId: ownProps.projectId,
        viewModelType: KnownViewModel.ModelCheck,
        viewModelStatus: ViewModelStatus.Calculating,
      }));
      dispatch(ModelCheckActions.runModelCheck(values));
    },
    toggleCheckSelected: (value) => dispatch(ModelCheckActions.toggleCheckSelected(value)),
  };
}

export const ModelCheckStartCalculationControl =
  connect(mapStateToProps, mapDispatchToProps)(ModelCheckStartCalculationComponent);
