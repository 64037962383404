import autobind from 'autobind-decorator';
import * as React from 'react';

import './no-bid-pricing-first-page-menu.scss';

import { MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import {
  CostEstimateGroupingType,
  CostEstimateGroupingTypes,
} from '../../../units/projects/enums/cost-estimate-grouping-type';

interface Props {
  groupingType: CostEstimateGroupingType;
  onChangeType: (value: CostEstimateGroupingType) => void;
}

export class NoBidPricingFirstPage extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <MaterialSelect
        autoWidth={true}
        value={this.props.groupingType}
        onChange={this.onChangeSelect}
        displayedType={MaterialComponentType.Native}
        className='no-bid-pricing__material-select'
      >
        {CostEstimateGroupingTypes.map((type) => (
          <MaterialMenuItem key={type.value} value={type.value}>{type.name}</MaterialMenuItem>
        ))}
      </MaterialSelect>
    );
  }

  @autobind
  private onChangeSelect(_e: any, v: CostEstimateGroupingType): void {
    this.props.onChangeType(v);
  }
}
