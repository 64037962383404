import { Action, AnyAction } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { ScenariosActionsType, ScenariosData } from '../../interfaces';
import { ScenariosActionTypes } from '../types/scenarios';

function activate_calculation(
  calculation: ScenariosData.Calculation,
): ActionWith<ScenariosData.Calculation> {
  return {
    type: ScenariosActionTypes.SET_ACTIVE_CALCULATION,
    payload: calculation,
  };
}

function setActiveCalculationRequest(
  data: ScenariosActionsType.ScenarioCalculationIds,
): ActionWith<ScenariosActionsType.ScenarioCalculationIds> {
  return {
    type: ScenariosActionTypes.SET_ACTIVE_CALCULATION_REQUEST,
    payload: data,
  };
}

function setActiveCalculationSucceeded(
  data: ScenariosActionsType.ScenarioCalculationIds,
): ActionWith<ScenariosActionsType.ScenarioCalculationIds> {
  return {
    type: ScenariosActionTypes.SET_ACTIVE_CALCULATION_SUCCEEDED,
    payload: data,
  };
}

function getScenarios(id: number): ActionWith<number> {
  return {
    type: ScenariosActionTypes.GET_REQUEST,
    payload: id,
  };
}

function getScenariosSucceeded(
  scenarios: ScenariosData.Scenario[],
): ActionWith<ScenariosData.Scenario[]> {
  return {
    type: ScenariosActionTypes.GET_SUCCEEDED,
    payload: scenarios,
  };
}

function setActiveScenario(
  scenario: ScenariosData.Scenario,
): ActionWith<ScenariosData.Scenario> {
  return {
    type: ScenariosActionTypes.SET_ACTIVE,
    payload: scenario,
  };
}

function getCalculations(id: number): ActionWith<number> {
  return {
    type: ScenariosActionTypes.GET_CALCULATIONS_REQUEST,
    payload: id,
  };
}

function getCalculationsSucceeded(
  data: ScenariosActionsType.CalculationsWithScenario,
): ActionWith<ScenariosActionsType.CalculationsWithScenario> {
  return {
    type: ScenariosActionTypes.GET_CALCULATIONS_SUCCEEDED,
    payload: data,
  };
}

function getActiveScenarioByProject(projectId: number): ActionWith<number> {
  return {
    type: ScenariosActionTypes.GET_ACTIVE_SCENARIO_REQUEST,
    payload: projectId,
  };
}

function reloadActiveScenarioByProject(projectId: number): ActionWith<number> {
  return {
    type: ScenariosActionTypes.RELOAD_ACTIVE_CALCULATION_REQUEST,
    payload: projectId,
  };
}

function getResourceLimitations(guid: string): ActionWith<string> {
  return {
    type: ScenariosActionTypes.EDITING_GET_BASE_RESOURCE_LIMITATIONS_REQUEST,
    payload: guid,
  };
}

function getResourceLimitationsSucceeded(data: any): ActionWith<any> {
  return {
    type: ScenariosActionTypes.EDITING_GET_BASE_RESOURCE_LIMITATIONS_SUCCEEDED,
    payload: data,
  };
}

function dropLimitationsState(): Action {
  return {
    type: ScenariosActionTypes.DROP_LIMITATIONS_STATE,
  };
}

function changeLimitationValue(
  data: ScenariosActionsType.ChangeLimitationParameter,
): ActionWith<ScenariosActionsType.ChangeLimitationParameter> {
  return {
    type: ScenariosActionTypes.EDITING_CHANGE_LIMITATION_VALUE,
    payload: data,
  };
}

function changeResourceValue(
  data: ScenariosActionsType.ChangeResourceParameter,
): ActionWith<ScenariosActionsType.ChangeResourceParameter> {
  return {
    type: ScenariosActionTypes.EDITING_CHANGE_RESOURCE_VALUE,
    payload: data,
  };
}

function changeTotalResourceValue(
  data: ScenariosActionsType.ChangeTotalResourceParameter,
): ActionWith<ScenariosActionsType.ChangeTotalResourceParameter> {
  return {
    type: ScenariosActionTypes.EDITING_CHANGE_TOTAL_RESOURCE_VALUE,
    payload: data,
  };
}

function setResourceLimitationRequest(): Action {
  return {
    type: ScenariosActionTypes.EDITING_SET_BASE_RESOURCE_LIMITATIONS_REQUEST,
  };
}

function setActiveScenarioRequest(
  data: ScenariosActionsType.ScenarioCalculationIds,
): ActionWith<ScenariosActionsType.ScenarioCalculationIds> {
  return {
    type: ScenariosActionTypes.SET_ACTIVE_SCENARIO_REQUEST,
    payload: data,
  };
}

function setActiveScenarioSucceeded(id: number): ActionWith<number> {
  return {
    type: ScenariosActionTypes.SET_ACTIVE_SCENARIO_SUCCEEDED,
    payload: id,
  };
}

function deleteScenario(id: number): ActionWith<number> {
  return {
    type: ScenariosActionTypes.REMOVE_SCENARIO_REQUEST,
    payload: id,
  };
}

function deleteScenarioSucceeded(scenarioId: number): ActionWith<number> {
  return {
    type: ScenariosActionTypes.REMOVE_SCENARIO_SUCCEEDED,
    payload: scenarioId,
  };
}

function dropScenarioEditing(): Action {
  return {
    type: ScenariosActionTypes.DROP_VALUES,
  };
}

function getIndirectSettings(id: number): ActionWith<number> {
  return {
    type: ScenariosActionTypes.GET_INDIRECT_SETTINGS,
    payload: id,
  };
}

function getIndirectSettingsSucceeded(data: any): ActionWith<any> {
  return {
    type: ScenariosActionTypes.GET_INDIRECT_SETTINGS_SUCCEEDED,
    payload: data,
  };
}

function setIndirectSettings(scenarioId: number): ActionWith<number> {
  return {
    type: ScenariosActionTypes.SET_INDIRECT_SETTINGS,
    payload: scenarioId,
  };
}

function changeIndirectSettingsParameter(
  data: ScenariosActionsType.ChangeIndirectSettingsParameter,
): ActionWith<ScenariosActionsType.ChangeIndirectSettingsParameter> {
  return {
    type: ScenariosActionTypes.CHANGE_INDIRECT_SETTINGS_PARAMETER,
    payload: data,
  };
}

function updateScenarioName(
  data: ScenariosActionsType.ChangeName,
): ActionWith<ScenariosActionsType.ChangeName> {
  return {
    type: ScenariosActionTypes.UPDATE_SCENARIO_NAME_REQUEST,
    payload: data,
  };
}

function updateScenarioNameSucceeded(
  data: ScenariosActionsType.ChangeName,
): ActionWith<ScenariosActionsType.ChangeName> {
  return {
    type: ScenariosActionTypes.UPDATE_SCENARIO_NAME_SUCCEEDED,
    payload: data,
  };
}

function updateCalculationName(
  data: ScenariosActionsType.ChangeName,
  scenarioId: number,
): ActionWith<ScenariosActionsType.CalculationChangeName> {
  return {
    type: ScenariosActionTypes.UPDATE_CALCULATION_NAME_REQUEST,
    payload: { data, scenarioId },
  };
}

function updateCalculationNameSucceeded(
  data: ScenariosActionsType.ChangeName,
  scenarioId: number,
): ActionWith<ScenariosActionsType.CalculationChangeName> {
  return {
    type: ScenariosActionTypes.UPDATE_CALCULATION_NAME_SUCCEEDED,
    payload: { data, scenarioId },
  };
}

function removeCalculation(
  data: ScenariosActionsType.ScenarioCalculationIds,
): ActionWith<ScenariosActionsType.ScenarioCalculationIds> {
  return {
    type: ScenariosActionTypes.REMOVE_CALCULATION_REQUEST,
    payload: data,
  };
}

function removeCalculationSucceeded(
  data: ScenariosActionsType.ScenarioCalculationIds,
): ActionWith<ScenariosActionsType.ScenarioCalculationIds> {
  return {
    type: ScenariosActionTypes.REMOVE_CALCULATION_SUCCEEDED,
    payload: data,
  };
}

function copyScenario(id: number): ActionWith<number> {
  return {
    type: ScenariosActionTypes.COPY_SCENARIO_REQUEST,
    payload: id,
  };
}

function copyScenarioSucceeded(
  data: ScenariosActionsType.CopyScenarioSucceeded,
): ActionWith<ScenariosActionsType.CopyScenarioSucceeded> {
  return {
    type: ScenariosActionTypes.COPY_SCENARIO_SUCCEEDED,
    payload: data,
  };
}

function copyScenarioFailed(
  scenarioId: number,
): ActionWith<number> {
  return {
    type: ScenariosActionTypes.COPY_SCENARIO_FAILED,
    payload: scenarioId,
  };
}

function dropScenarioInfo(): Action {
  return {
    type: ScenariosActionTypes.DROP_INFO,
  };
}

function publishScenario(
  data: ScenariosActionsType.Publish,
): ActionWith<ScenariosActionsType.Publish> {
  return {
    type: ScenariosActionTypes.PUBLISH_REQUEST,
    payload: data,
  };
}

function publishScenarioSucceeded(
  data: ScenariosActionsType.Publish,
): ActionWith<ScenariosActionsType.Publish> {
  return {
    type: ScenariosActionTypes.PUBLISH_SUCCEEDED,
    payload: data,
  };
}

function deleteAllScenariosExceptActive(
  id: number,
): ActionWith<number> {
  return {
    type: ScenariosActionTypes.DELETE_ALL_SCENARIOS_EXCEPT_ACTIVE,
    payload: id,
  };
}

function deleteAllScenariosExceptActiveSucceeded(
  canEdit: boolean,
): ActionWith<boolean> {
  return {
    type: ScenariosActionTypes.DELETE_ALL_SCENARIOS_EXCEPT_ACTIVE_SUCCEEDED,
    payload: canEdit,
  };
}

function setEditScenario(
  scenario: ScenariosData.Scenario,
): ActionWith<ScenariosData.Scenario> {
  return {
    type: ScenariosActionTypes.SET_EDIT_SCENARIO,
    payload: scenario,
  };
}

function setChangeWPRequest(): AnyAction {
  return {
    type: ScenariosActionTypes.SET_CHANGE_WP_REQUEST,
  };
}

function setChangeWPSucceeded(): AnyAction {
  return {
    type: ScenariosActionTypes.SET_CHANGE_WP_SUCCEEDED,
  };
}

function updateScenario(
  data: ScenariosActionsType.UpdateScenario,
): ActionWith<ScenariosActionsType.UpdateScenario> {
  return {
    type: ScenariosActionTypes.UPDATE_SCENARIO_REQUEST,
    payload: data,
  };
}

function updateScenarioSucceeded(
  data: ScenariosActionsType.UpdateScenario,
): ActionWith<ScenariosActionsType.UpdateScenario> {
  return {
    type: ScenariosActionTypes.UPDATE_SCENARIO_SUCCEEDED,
    payload: data,
  };
}

function updateScenarioStatus(
  data: ScenariosActionsType.UpdateStatus,
): ActionWith<ScenariosActionsType.UpdateStatus> {
  return {
    type: ScenariosActionTypes.UPDATE_SCENARIO_STATUS,
    payload: data,
  };
}


export const ScenariosActions = {
  activate_calculation,
  setActiveCalculationRequest,
  setActiveCalculationSucceeded,
  getScenarios,
  getScenariosSucceeded,
  setActiveScenario,
  getCalculations,
  getCalculationsSucceeded,
  getActiveScenarioByProject,
  reloadActiveScenarioByProject,
  getResourceLimitations,
  getResourceLimitationsSucceeded,
  dropLimitationsState,
  changeLimitationValue,
  changeResourceValue,
  changeTotalResourceValue,
  setResourceLimitationRequest,
  setActiveScenarioRequest,
  setActiveScenarioSucceeded,
  deleteScenario,
  deleteScenarioSucceeded,
  dropScenarioEditing,
  getIndirectSettings,
  getIndirectSettingsSucceeded,
  setIndirectSettings,
  changeIndirectSettingsParameter,
  updateScenarioName,
  updateScenarioNameSucceeded,
  updateCalculationName,
  updateCalculationNameSucceeded,
  removeCalculation,
  removeCalculationSucceeded,
  copyScenario,
  copyScenarioSucceeded,
  copyScenarioFailed,
  dropScenarioInfo,
  publishScenario,
  publishScenarioSucceeded,
  deleteAllScenariosExceptActive,
  deleteAllScenariosExceptActiveSucceeded,
  setEditScenario,
  setChangeWPRequest,
  setChangeWPSucceeded,
  updateScenario,
  updateScenarioSucceeded,
  updateScenarioStatus,
};
