import * as React from 'react';

import './tab-nav.scss';

interface Props {
  page: number;
  onChangePage: (page: number) => void;
}

export class TabNav extends React.Component<Props> {
  private static context: React.Context<Props> = null;

  public static getContext(): React.Context<Props> {
    if (this.context === null) {
      this.context = React.createContext(null);
    }
    return this.context;
  }

  public render(): React.ReactNode {
    const Context = TabNav.getContext();
    return (
      <div className='tab-nav__header'>
        <div className='tab-nav__header-container'>
          <Context.Provider value={this.props}>
            {this.props.children}
          </Context.Provider>
        </div>
      </div>
    );
  }


}
