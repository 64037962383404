import * as React from 'react';

export const IconWorkPackage: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M3.5,3.00000011 L12.5,3.00000011 C13.3284271,3.00000011 14,3.67157299 14,4.50000011 L14,
      5.50000011 C14,6.32842724 13.3284271,7.00000011 12.5,7.00000011 L3.5,7.00000011 C2.67157288
      ,7.00000011 2,6.32842724 2,5.50000011 L2,4.50000011 C2,3.67157299 2.67157288,3.00000011 3.5,
      3.00000011 Z M3.5,4.00000011 C3.22385763,4.00000011 3,4.22385774 3,4.50000011 L3,5.50000011 C3,
      5.77614249 3.22385763,6.00000011 3.5,6.00000011 L12.5,6.00000011 C12.7761424,6.00000011 13,
      5.77614249 13,5.50000011 L13,4.50000011 C13,4.22385774 12.7761424,4.00000011 12.5,
      4.00000011 L3.5,4.00000011 Z M8.465,7.7275 C8.5850006,7.7275 8.69624949,7.77374954 8.79875,
      7.86625 C8.90125051,7.95875046 8.9525,8.0749993 8.9525,8.215 C8.9525,8.26000022 8.94500007,
      8.30999972 8.93,8.365 L7.4525,12.715 C7.42249985,12.8000004 7.37000037,12.8674997 7.295,
      12.9175 C7.21999962,12.9675002 7.13750045,12.995 7.0475,13 C6.95749955,13 6.8725004,
      12.9750002 6.7925,12.925 C6.7124996,12.8749997 6.65000022,12.8025005 6.605,12.7075 L5.54,
      10.285 L4.4675,12.7075 C4.42249977,12.8025005 4.3600004,12.8749997 4.28,12.925 C4.1999996,
      12.9750002 4.11500045,13 4.025,13 C3.93499955,12.995 3.85250037,12.9675002 3.7775,
      12.9175 C3.70249962,12.8674997 3.65000015,12.8000004 3.62,12.715 L2.1425,8.365 C2.12749992,
      8.30999972 2.12,8.26000022 2.12,8.215 C2.12,8.0749993 2.17124949,7.95875046 2.27375,
      7.86625 C2.37625051,7.77374954 2.48999937,7.7275 2.615,7.7275 C2.7150005,7.7275 2.8049996,
      7.75374974 2.885,7.80625 C2.9650004,7.85875026 3.01999985,7.92999955 3.05,8.02 L4.1675,
      11.41 L5.1425,9.07 C5.1825002,8.97999955 5.23999962,8.90875026 5.315,8.85625 C5.39000037,
      8.80374974 5.47499952,8.77999997 5.57,8.785 C5.66500047,8.77999997 5.74874964,8.80374974 5.82125,
      8.85625 C5.89375036,8.90875026 5.9499998,8.97999955 5.99,9.07 L6.9125,11.3875 L8.0225,8.02 C8.05250015,
      7.92999955 8.10874959,7.85875026 8.19125,7.80625 C8.27375041,7.75374974 8.3649995,7.7275 8.465,
      7.7275 Z M12.2310714,7.75 C12.4860727,7.75 12.7285703,7.82624924 12.9585714,7.97875 C13.1885725,
      8.13125076 13.3735707,8.33624871 13.5135714,8.59375 C13.6535721,8.85125129 13.7235714,
      9.13249848 13.7235714,9.4375 C13.7235714,9.7375015 13.6535721,10.0174987 13.5135714,
      10.2775 C13.3735707,10.5375013 13.1885725,10.7437492 12.9585714,10.89625 C12.7285703,
      11.0487508 12.4860727,11.125 12.2310714,11.125 L10.9260714,11.125 L10.9260714,
      12.5425 C10.9260714,12.6775007 10.8860718,12.7874996 10.8060714,12.8725 C10.726071,
      12.9575004 10.621072,13 10.4910714,13 C10.3660708,13 10.2635718,12.9562504 10.1835714,
      12.86875 C10.103571,12.7812496 10.0635714,12.6725006 10.0635714,12.5425 L10.0635714,
      8.2075 C10.0635714,8.07749935 10.107321,7.96875044 10.1948214,7.88125 C10.2823218,
      7.79374956 10.3910708,7.75 10.5210714,7.75 L12.2310714,7.75 Z M12.2310714,10.27 C12.3260719,
      10.27 12.4198209,10.2300004 12.5123214,10.15 C12.6048219,10.0699996 12.6798211,9.96625064 12.7373214,
      9.83875 C12.7948217,9.71124936 12.8235714,9.5775007 12.8235714,9.4375 C12.8235714,9.2974993 12.7948217,
      9.16375064 12.7373214,9.03625 C12.6798211,8.90874936 12.6048219,8.80625039 12.5123214,8.72875 C12.4198209,
      8.65124961 12.3260719,8.6125 12.2310714,8.6125 L10.9260714,8.6125 L10.9260714,10.27 L12.2310714,10.27 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
