import styled from 'styled-components';

import { TABS_HEIGHT } from '2d/components/styled';
import { Styled as UpgradeWrapperStyled } from '2d/components/upgrade-plan/styled';

export const MENU_ITEM_HEIGHT = 50;

const Container = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  height: ${TABS_HEIGHT}px;
  background-color: ${p => p.theme.color.backgroundRush};
`;

const TabsList = styled.div<{ fullContainer?: boolean }>`
  position: relative;
  display: flex;
  overflow: hidden;
`;

const TabItem = styled.div<{
  isOpenMenu: boolean,
  isActive: boolean,
  activeColor: string,
} & React.DetailedHTMLProps<any, any>>`
  position: relative;
  box-sizing: border-box;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  max-width: 250px;
  height: 36px;
  padding: 0 15px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  border: 0;
  border-style: solid;
  border-color: ${p => p.theme.color.background};
  border-left-width: 1px;
  box-shadow: ${p => p.isOpenMenu ? p.theme.insetShadow.xl : 'none'};
  transition: ${p => p.theme.duration.s} linear;

  &:hover {
    border-radius: 15px;
    background: ${p => p.theme.color.pale};
  }

  &:last-child {
    border-right-width: 1px;
  }

  &::before {
    opacity: ${p => (p.isActive ? 1 : 0)};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: ${p => p.activeColor};
    border-radius: 1px 1px 3px 3px;
  }

  span {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    color: ${p => p.activeColor};
  }
`;

const Name = styled.div`
  position: relative;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
`;

const Postfix = styled.div`
  margin-left: 13px;
`;

const TabItemChangeNameInput = styled.input`
  min-width: 80px;
  padding: 5px 3px;
  font-size: inherit;
  line-height: inherit;
  border-radius: 4px;
  outline: 0;
  color: ${p => p.theme.color.mainFont};
  background-color: ${p => p.theme.color.background};
  border: 1px solid ${p => p.theme.color.turquoise};
`;

const TabsMenuContainer = styled.div`
  width: 36px;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;
`;

const TabsMenu = styled.div<{ isMenuOnTop: boolean }>`
  > div {
    margin: -10px 0;
    box-shadow: none;
    display: flex;
    flex-direction: ${p => p.isMenuOnTop ? 'column-reverse' : 'column' };
    ${p => p.isMenuOnTop &&
      `> div:last-child {
        border-top: none;
        border-bottom: 1px solid ${p.theme.color.background};
      }`
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }
`;

const TabsMenuItem = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  svg {
    :first-child {
      fill: ${p => p.color};
    }
    :last-child {
      fill: ${p => p.theme.color.turquoise};
    }
  }
`;

const TabsMenuItemText = styled.div<{ isActive: boolean }>`
  flex-grow: 1;
  max-width: calc(100% - ${p => p.isActive ? 70 : 30}px);
`;

const TabItemContainer = styled.div`
  ${UpgradeWrapperStyled.StarsContainer} {
    top: 5px;
    right: 5px;
  }
`;

export const Styled = {
  Container,
  TabItem,
  Name,
  Postfix,
  TabsList,
  TabsMenuContainer,
  TabsMenu,
  TabsMenuItem,
  TabsMenuItemText,
  TabItemChangeNameInput,
  TabItemContainer,
};
