import * as t from 'io-ts';

import { SubscriptionTypeC } from 'common/constants/subscription';
import { PeopleV } from '../../people/interfaces/person';

export const CompanySubscriptionUsersChangedModelC = t.type(
  {
    companyId: t.number,
    users: PeopleV,
    subscriptionType: SubscriptionTypeC,
  },
  'CompanySubscriptionUsersChangedModel');

export type CompanySubscriptionUsersChangedModel = t.TypeOf<typeof CompanySubscriptionUsersChangedModelC>;
