import { Icons, ModalWrapper, LinkComponent, Text } from '@kreo/kreo-ui-components';
import * as Ag from 'ag-grid-community';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { BreakdownProperty } from 'unit-2d-database/components/breakdown-property';
import { BREAKDOWN_DIALOG_NAME } from './constants';
import { Styled } from './styled';

const BreakdownDialogComponent: React.FC = () => {
  const dispatch = useDispatch();
  const close = useCallback(() => dispatch(KreoDialogActions.closeDialog(BREAKDOWN_DIALOG_NAME)), []);
  const data = useSelector<State, any>((state) => state.dialog[BREAKDOWN_DIALOG_NAME]);
  const [selectedNode, setSelectedNode] = useState<string>(null);
  const [isSelectedNode, setIsSelectedNode] = useState<boolean>(false);
  const options = useMemo(() => data ? data.root : [],  [data]);

  const exit = useCallback(() => {
    setIsSelectedNode(false);
    close();
  }, [close]);

  const handleRowSelect = useCallback((event: Ag.RowSelectedEvent) => {
    const selectedNodes = event.api.getSelectedNodes();
    setIsSelectedNode(true);

    if (selectedNodes && selectedNodes.length && selectedNodes[0]) {
      setSelectedNode(selectedNodes[0].data.id);
    } else if (selectedNodes.length === 0) {
      setSelectedNode(null);
    }
  }, []);

  const apply = useCallback(() => {
    data.onSelect(selectedNode);
    exit();
  }, [data, selectedNode, exit]);

  return (
    <DialogWrapper name={BREAKDOWN_DIALOG_NAME}>
      <Styled.ModalWrapper>
        <ModalWrapper onExit={exit} zIndex={99999}>
          <Styled.Container>
            <Styled.HeaderContainer>
              <Styled.PropertyName>
                <Icons.Breakdown />
                <Text withEllipsis={true}>
                  {data?.propertyName}
                </Text>
              </Styled.PropertyName>
              <Styled.ApplyContainer>
                <LinkComponent
                  text={'Apply'}
                  onClick={apply}
                  Icon={Icons.Apply}
                  mood={isSelectedNode ? 'secondary' : 'disabled'}
                />
              </Styled.ApplyContainer>
            </Styled.HeaderContainer>
            <Styled.BreakdownContainer>
              <BreakdownProperty
                options={options}
                onRowSelected={handleRowSelect}
                editable={false}
                selectedNodeId={data?.selectedId}
                suppressRowClickSelection={false}
                enableMultiSelect={false}
              />
            </Styled.BreakdownContainer>
          </Styled.Container>
        </ModalWrapper>
      </Styled.ModalWrapper>
    </DialogWrapper>
  );
};

export const BreakdownDialog = React.memo(BreakdownDialogComponent);
