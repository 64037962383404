import { AnyAction } from 'redux';
import { commonReducerMethods } from '../actions/common/reducer-methods';
import { CommonState } from '../common/interfaces/common-state';

const INITIAL_STATE: CommonState = {
  files: [],
  locations: [],
  reportsSentDialogText: '',
  downloadFileInfo: null,
};

export default function common(
  state: CommonState = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null }): CommonState {
  const { payload } = action;
  if (action.type in commonReducerMethods) {
    return commonReducerMethods[action.type](state, payload);
  } else {
    return state;
  }
}
