import * as React from 'react';

export const KreoIconCreateNew: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
    <g id='main-page_create-new_light' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M6,6 L6,2 C6,1.44771525 6.44771525,1 7,1 C7.55228475,1 8,1.44771525 8,2 L8,6 L12,6 C12.5522847,6
          13,6.44771525 13,7 C13,7.55228475 12.5522847,8 12,8 L8,8 L8,12 C8,12.5522847 7.55228475,13 7,13
          C6.44771525,13 6,12.5522847 6,12 L6,8 L2,8 C1.44771525,8 1,7.55228475 1,7 C1,6.44771525 1.44771525,6
          2,6 L6,6 Z'
          id='Combined-Shape'
          fill='currentColor'
          fillRule='nonzero'
        />
    </g>
    </svg>
  );
};
