import * as React from 'react';

import { EnhancedVirtualList } from 'common/UIKit';
import { KreoScrollbarsApi } from 'common/UIKit/scrollbars/kreo-scrollbars';
import { AnnotationLegendItem } from '../interfaces/annotation-legend-tree';
import { getItemHeight } from './get-item-height';
import { Styled } from './styled';

interface OwnProps {
  itemHeight: number;
  filterResultItems: AnnotationLegendItem[];
  saveListApi(api: KreoScrollbarsApi): void;
  renderItemFunction(item: AnnotationLegendItem): React.ReactNode;
}

export class DrawingsAnnotationList extends React.PureComponent<OwnProps> {
  public render(): JSX.Element {
    return (
      <Styled.List>
        <EnhancedVirtualList
          objects={this.props.filterResultItems}
          showShadowTop={true}
          smoothScroll={true}
          itemHeight={this.props.itemHeight}
          renderedItemsCount={100}
          totalHeight={5}
          renderItem={this.props.renderItemFunction}
          sendScrollBarApi={this.props.saveListApi}
          customItemHeight={getItemHeight}
        />
      </Styled.List>
    );
  }
}
