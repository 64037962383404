import * as React from 'react';
import { ReactourStep } from 'reactour';

import { ControlNames } from 'common/constants/control-names';
import { CREATE_3D_PROJECT_DIALOG_NAME } from '../../components/dialog';
import {
  getSelector,
  tourStepLast,
  tourStepNext,
  tourStepProjectCreateDialog,
  tourStepProjectCreating,
} from '../steps-actions';

export const CreateProjectSteps: ReactourStep[] = [
  {
    selector: getSelector(ControlNames.createDemoProjectDialogButton),
    content: (stepData) => {
      tourStepNext(stepData, ControlNames.createDemoProjectDialogButton, 400);
      return (
        <div className='ui-tour__container'>
          <p className='ui-tour__text'>Click on the button "Create Demo Project" for the beginning.</p>
        </div>
      );
    },
  },
  {
    selector: `#${CREATE_3D_PROJECT_DIALOG_NAME}`,
    content: (stepData) => {
      tourStepProjectCreateDialog(stepData);
      return (
        <div className='ui-tour__container'>
          <p className='ui-tour__text'>When the project is uploaded, click on the button.</p>
          <button className='ui-tour__demo-btn kreo-btn kreo-btn--small submit'>Create Project</button>
        </div>
      );
    },
  },
  {
    selector: getSelector(ControlNames.projectTileContent),
    content: (stepData) => {
      tourStepProjectCreating(stepData);
      return (
        <div className='ui-tour__container'>
          <p className='ui-tour__text'>
            Now you need to wait a few seconds for the project to be processed by the system.
            Then open your project.
              </p>
        </div>
      );
    },
  },
  {
    selector: getSelector(ControlNames.projectTile),
    content: (stepData) => {
      tourStepLast(stepData, [ControlNames.projectTileLink, ControlNames.projectTileDeleteButton]);
      return (
        <div className='ui-tour__container'>
          <p className='ui-tour__text'>
            <b>Congratulation!</b> Your first project has just been created!
              </p>
        </div>
      );
    },
  },
];
