import autobind from 'autobind-decorator';

import { DrawOptions, PdfRenderer } from 'common/components/drawings/helpers/viewport';
import { ComparableDocumentType } from 'common/pdf/compare-document';

export class OriginalDocumentRenderer extends PdfRenderer {
  @autobind
  protected override drawPdfCoreControls(
    pdfDocument: ComparableDocumentType,
    options: DrawOptions,
    completeCallBack: (canvas: HTMLCanvasElement, options: DrawOptions) => void,
  ): void {
    this.cancelAndUnload(pdfDocument);
    if (!this._config.getCurrentDrawingInfo()) {
      return;
    }
    this._config.syncPageViewportRect(options);
    this._currentRenderId = pdfDocument.originalLoadCanvas({
      ...options,
      drawComplete: (canvas: HTMLCanvasElement) => {
        this._currentRenderId = null;
        pdfDocument.unloadCanvasResources(this._currentRenderId as number);
        completeCallBack(canvas, options);
      },
    });
  }
}
