import React from 'react';
import { Styled } from './styled';
import { Tab } from './tab';

interface Props {
  selectedDrawing: string;
  drawings: string[];
  selectDrawing: (drawingId: string) => void;
}

export const PageTabs: React.FC<Props> = ({
  selectedDrawing,
  drawings,
  selectDrawing,
}) => {
  return (
    <Styled.Container>
      {
        drawings.map((drawingId) => {
          return (
            <Tab
              key={drawingId}
              drawingId={drawingId}
              selected={selectedDrawing === drawingId}
              onSelect={selectDrawing}
            />
          );
        })
      }
    </Styled.Container>
  );
};
