import { useCallback } from 'react';
import { ConstantFunctions } from 'common/constants/functions';

export function useStopEvent(
  func: (e?: Event | React.SyntheticEvent | PaperMouseEvent) => void,
  deps: React.DependencyList,
): (e?: Event | React.SyntheticEvent | PaperMouseEvent) => void {
  return useCallback(
    (e?: Event | React.SyntheticEvent | PaperMouseEvent) => {
      ConstantFunctions.stopEvent(e);
      func(e);
    },
    deps,
  );
}
