import { Waves, Text } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { ElementTooltip } from '../tooltip';
import { ModeType, SwitcherMode } from './interfaces';
import { Styled } from './styled';


interface Props{
  element: SwitcherMode;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  tooltipSize?: 'small' | 'big';
  tooltipDelay?: 's' | 'm' | 'l' | 'xl';
  toggleMode: (mode: ModeType) => void;
  selected: boolean;
  elementHeight?: number;
  controlName?: string;
}

const SwitcherItemComponent: React.FC<Props> = ({
  element: {
    mode,
    text,
    description,
    icon: Icon,
  },
  tooltipDelay,
  tooltipPosition,
  tooltipSize,
  selected,
  toggleMode,
  elementHeight,
  controlName,
}) => {

  const toggle = useCallback(() => {
    toggleMode(mode);
  }, [mode]);

  return (
    <ElementTooltip
      position={tooltipPosition}
      size={tooltipSize}
      speed={tooltipDelay}
      text={description}
      disabled={!description}
    >
      <Styled.Round
        key={text}
        onClick={toggle}
        active={selected}
        id={text}
        elementHeight={elementHeight}
        data-control-name={controlName}
      >
        <Waves color="turquoise" />
        {Icon && <Icon fill={selected ? 'white' : 'gray'} />}
        {text && (
          <Text
            color={selected ? 'white' : 'gray'}
            fontSize={14}
            lineHeight={12}
            fontWeight={500}
          >
            {text}
          </Text>
        )}
      </Styled.Round>
    </ElementTooltip>
  );
};

export const SwitcherItem = React.memo(SwitcherItemComponent);
