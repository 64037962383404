import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';


import { ProjectType } from 'common/constants/project-type';
import { ProjectTypeToFileExtension } from 'common/constants/project-type-to-file-extensions';
import { CREATE_PROJECT } from '../../../constants/forms';
import { CreateProjectCommonDialog } from './create-project-common-dialog';


export const CREATE_3D_PROJECT_DIALOG_NAME = 'create3dProject';

interface Props extends InjectedFormProps<{}, {}> {}

class Create3dProjectDialogComponent extends React.Component<Props> {

  public render(): React.ReactNode {
    const { handleSubmit, reset } = this.props;

    return (
      <CreateProjectCommonDialog
        dialogName={CREATE_3D_PROJECT_DIALOG_NAME}
        extensions={ProjectTypeToFileExtension[ProjectType.Project3d].default}
        extensionsToShow={ProjectTypeToFileExtension[ProjectType.Project3d].toShow}
        projectType={ProjectType.Project3d}
        handleSubmit={handleSubmit}
        reset={reset}
      >
        <div className='create-project-dialog__plugin'>
          Export Revit model to Kreo
          <a
            // eslint-disable-next-line max-len
            href='https://apps.autodesk.com/RVT/en/Detail/Index?id=5474822680161665010&appLang=en&os=Win64&autostart=true'
            target='_blank'
          >
            Download Plugin
          </a>
        </div>
      </CreateProjectCommonDialog>
    );
  }
}

export const Create3dProjectDialog = reduxForm<{}, {}>({
  form: CREATE_PROJECT,
  destroyOnUnmount: true,
})(Create3dProjectDialogComponent);
