import React from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { RenderIf } from 'common/components/render-if';
import { useSelectedInstancesParameters } from './hooks/use-selected-instances-parameters';
import {
  Measurements,
  StrokeWidthProperty,
  ColorProperty,
  StrokeStyleProperty,
  ShapeProperty,
  NameProperty,
  MeasureProperty,
} from './properties';
import { GroupProperty } from './properties/group-property/group-property';
import { PropertyWithMenuEditor } from './properties/property-menu-editor';
import { Styled } from './styled';


const InstancesPropertiesEditorComponent: React.FC = () => {
  const { aggregatedProperties, selectedInstances, instances } = useSelectedInstancesParameters();
  const canEdit = useAbility(Operation.Update, Subject.Takeoff2DMeasurement);
  const canEdit3dMeasurements = useAbility(Operation.Update, Subject.Takeoff2dMeasurement3d);
  if (!selectedInstances.length) {
    return null;
  }
  return (
    <Styled.Container>
      <NameProperty
        canEdit={canEdit}
        value={aggregatedProperties.name}
      />
      <PropertyWithMenuEditor
        name='Type'
        defaultValue='Mixed'
        elements={aggregatedProperties.type}
      />
      <RenderIf condition={aggregatedProperties.hasStroke}>
        <StrokeWidthProperty
          canEdit={canEdit}
          values={aggregatedProperties.strokeWidth}
          instances={instances}
        />
        <StrokeStyleProperty
          values={aggregatedProperties.strokeStyle}
          instances={instances}
          canEdit={canEdit}
        />
      </RenderIf>
      <ColorProperty
        values={aggregatedProperties.color}
        instances={instances}
        selectedInstancesIds={selectedInstances}
        canEdit={canEdit}
      />
      <GroupProperty
        values={aggregatedProperties.group}
        canEdit={canEdit}
      />
      <RenderIf condition={aggregatedProperties.hasCount}>
        <ShapeProperty
          values={aggregatedProperties.shape}
          instances={instances}
          selectedInstancesIds={selectedInstances}
          canEdit={canEdit}
        />
      </RenderIf>
      <RenderIf condition={aggregatedProperties.hasStroke && canEdit3dMeasurements}>
        <MeasureProperty
          values={aggregatedProperties.height}
          valueKey='height'
          name='Height'
          canEdit={canEdit}
        />
        <RenderIf condition={!!Object.keys(aggregatedProperties.thickness).length}>
          <MeasureProperty
            values={aggregatedProperties.thickness}
            valueKey='thickness'
            name='Thickness'
            canEdit={canEdit}
          />
        </RenderIf>
        <MeasureProperty
          values={aggregatedProperties.offsets}
          valueKey='offset'
          name='Offset'
          canEdit={canEdit}
          ignoreCalculation={true}
        />
      </RenderIf>
      <Measurements
        measurements={aggregatedProperties.measures}
      />
    </Styled.Container>
  );
};


export const InstancesPropertiesEditor = React.memo(InstancesPropertiesEditorComponent);
