import autobind from 'autobind-decorator';
import * as React from 'react';

import { ResourceType } from '../../../utils/gantt-chart';
import { ResourceSelectorContentBody } from './resource-selector-content-body';
import { SelectName } from './select-name';


interface Props {
  selectResources: (resourceId: number, selectedResourceType: ResourceType) => void;
  selectedResourceId: number;
}
interface State {
  open: boolean;
  selectedResourceName: string;
}

class ResourcesChartLeftSide extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { open: false, selectedResourceName: 'All Labours' };
  }

  public render(): React.ReactNode {
    return (
      <div className='gantt-resources-chart-container__chart__left-side'>
        <SelectName
          onClick={this.onOpenClose}
          isExpanded={false}
          className='gantt-resources-chart-container-resource-selector__name'
        >
          {this.state.selectedResourceName}
        </SelectName>
        <ResourceSelectorContentBody
          selectedResourceId={this.props.selectedResourceId}
          onSelectResources={this.selectResource}
          onClose={this.onOpenClose}
          open={this.state.open}
        />
      </div>
    );
  }

  @autobind
  private selectResource(id: number, selectedResourceType: ResourceType, name: string): void {
    this.props.selectResources(id, selectedResourceType);
    this.setState({ selectedResourceName: name });
  }

  @autobind
  private onOpenClose(): void {
    this.setState({ open: !this.state.open });
  }
}


export { ResourcesChartLeftSide };
