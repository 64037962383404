import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { PersistedStorageActions } from '../../../persisted-storage/actions/creators';

export interface ConfirmationDialogNames {
  removeElementsName: string;
  importsElementsName: string;
  moveElementsName: string;
  importsElementsDisableName: string;
}

interface OwnProps {
  confirmationDialogActions: Record<string, (cancel?: boolean) => void>;
  confirmationDialogNames: ConfirmationDialogNames;
  name: string;
}

interface ReduxProps {
  disableShowDialogList: string[];
}

interface DispatchProps {
  onChangeDisableShowDialog: (name: string) => void;
}

interface Props extends OwnProps, DispatchProps, ReduxProps { }


class QtoTreeTableConfirmationDialogsComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { confirmationDialogNames, name } = this.props;
    const nameLowerCase = name.toLowerCase();
    return (
      <>
        <KreoConfirmationDialog
          name={confirmationDialogNames.removeElementsName}
          title={`Remove ${name}`}
          yesText='Remove'
          noText='Cancel'
          onYes={this.onYesRemoveConfirmDialog}
          onDisableShowDialogChange={this.onChangeDisableShowDialog}
        >
          Are you sure you want to <b>remove</b> the selected {nameLowerCase}?
        </KreoConfirmationDialog>
        <KreoConfirmationDialog
          name={confirmationDialogNames.importsElementsName}
          title={`Import ${name}`}
          yesText='Import'
          noText='Cancel'
          onYes={this.onYesImportConfirmDialog}
          onDisableShowDialogChange={this.onChangeDisableShowDialog}
        >
          Are you sure you want to <b>import</b> the selected {nameLowerCase}?
        </KreoConfirmationDialog>
        <KreoConfirmationDialog
          name={confirmationDialogNames.moveElementsName}
          title={`Move  ${name}`}
          yesText='Drop'
          noText='Cancel'
          onYes={this.onYesDropConfirmDialog}
          onNo={this.onNoDropConfirmDialog}
          onClose={this.onNoDropConfirmDialog}
          onDisableShowDialogChange={this.onChangeDisableShowDialog}
        >
          Are you sure you want to move the selected {nameLowerCase} into this group?
        </KreoConfirmationDialog>
        <KreoConfirmationDialog
          name={confirmationDialogNames.importsElementsDisableName}
          title={`You can't drop the ${nameLowerCase}/group to this group`}
          yesText='Ok'
          noText='Cancel'
          onYes={ConstantFunctions.doNothing}
          onNo={ConstantFunctions.doNothing}
          onDisableShowDialogChange={this.onChangeDisableShowDialog}
        >
          <b>N.B!</b>
          <ol>
            <li>A group can be added to any position except for a group with {nameLowerCase}s</li>
            <li>{name}s can be added only to the lower level</li>
          </ol>
        </KreoConfirmationDialog>
      </>
    );
  }

  @autobind
  private onYesRemoveConfirmDialog(): void {
    this.props.confirmationDialogActions[this.props.confirmationDialogNames.removeElementsName]();
  }

  @autobind
  private onYesImportConfirmDialog(): void {
    this.props.confirmationDialogActions[this.props.confirmationDialogNames.importsElementsName]();
  }

  @autobind
  private onYesDropConfirmDialog(): void {
    this.props.confirmationDialogActions[this.props.confirmationDialogNames.moveElementsName]();
  }

  @autobind
  private onNoDropConfirmDialog(): void {
    this.props.confirmationDialogActions[this.props.confirmationDialogNames.moveElementsName](true);
  }

  @autobind
  private onChangeDisableShowDialog(dialogName: string): void {
    this.props.onChangeDisableShowDialog(dialogName);
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  return {
    disableShowDialogList: state.persistedStorage.disableShowDialogList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    onChangeDisableShowDialog: (name) => dispatch(PersistedStorageActions.toggleDisableShowDialog(name)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const QtoTreeTableConfirmationDialogs = connector(QtoTreeTableConfirmationDialogsComponent);
