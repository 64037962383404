import { CommentaryTargetTypeGuards } from '..';
import { CommentaryThread, TwoDCommentsState } from '../interfaces';

function countComments(comments: CommentaryThread[]): { byDrawings: Record<string, number>, all: number } {
  const byDrawings: Record<string, number>  = {};
  let all = 0;
  for (const { target, resolved } of comments) {
    if (resolved) {
      continue;
    }
    all++;
    if (CommentaryTargetTypeGuards.isDrawing(target)) {
      byDrawings[target.pageId] = byDrawings[target.pageId] || 0;
      byDrawings[target.pageId]++;
    }
  }
  return { byDrawings, all };
}

function increase({ target }: CommentaryThread, state: TwoDCommentsState): void {
  if (CommentaryTargetTypeGuards.isDrawing(target)) {
    state.drawingCommentsCount[target.pageId] = state.drawingCommentsCount[target.pageId] || 0;
    state.drawingCommentsCount[target.pageId]++;
  }
  state.openedCommentsCount++;
}

function decrease({ target }: CommentaryThread, state: TwoDCommentsState): void {
  if (CommentaryTargetTypeGuards.isDrawing(target)) {
    state.drawingCommentsCount[target.pageId]--;
  }
  state.openedCommentsCount--;
}

export const TargetCountingUtils = {
  increase,
  decrease,
  countComments,
};
