import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { ConstantFunctions } from 'common/constants/functions';
import { CopyPasteHotkey, DrawingHotkey } from '../utils/hotkey-utils';


interface DrawingSelectMeasurementsListenersConfig extends GlobalKeyboardEventsControllerContextProps {
  copyCurrentSelectedInstances: () => void;
  cut: () => void;
  pasteInstances: (center: paper.Point) => void;
  removeDrawingsFromGroups: () => void;
  groupDrawings: () => void;
  onRemoveInstancesHandler: () => void;
  subtract: () => void;
  unite: () => void;
  duplicate: () => void;
  pasteWithPoint: () => void;
}

export class DrawingSelectMeasurementsListeners {
  private _config: DrawingSelectMeasurementsListenersConfig;

  private _isActive: boolean;

  constructor(config: DrawingSelectMeasurementsListenersConfig) {
    this._config = config;
  }

  public addEventListeners(canEditMeasurement: boolean): void {
    if (this._isActive) {
      return;
    }
    this._isActive = true;
    if (canEditMeasurement) {
      this._config.addKeyDownEventListener(CopyPasteHotkey.PasteWithPoint, this._config.pasteWithPoint);
      this._config.addKeyDownEventListener(CopyPasteHotkey.Copy, this.copyCurrentSelectedInstancesListener);
      this._config.addKeyDownEventListener(CopyPasteHotkey.Paste, this.pasteEventHandler);
      this._config.addKeyDownEventListener(CopyPasteHotkey.Cut, this.cutEventListener);
      this._config.addKeyDownEventListener(DrawingHotkey.Unite, this.unite);
      this._config.addKeyDownEventListener(DrawingHotkey.Subtract, this._config.subtract);
      this._config.addKeyDownEventListener(DrawingHotkey.RemoveInstance, this.onRemoveInstancesHandler);
      this._config.addKeyDownEventListener(DrawingHotkey.GroupDrawings, this.groupDrawings);
      this._config.addKeyDownEventListener(DrawingHotkey.RemoveFromGroupGroup, this.removeDrawingsFromGroups);
      this._config.addKeyDownEventListener(DrawingHotkey.Duplicate, this.duplicateEventListener);
    }
  }

  public removeEventListeners(canEditMeasurement: boolean): void {
    this._isActive = false;
    if (canEditMeasurement) {
      this._config.removeKeyDownEventListener(CopyPasteHotkey.PasteWithPoint, this._config.pasteWithPoint);
      this._config.removeKeyDownEventListener(CopyPasteHotkey.Copy, this.copyCurrentSelectedInstancesListener);
      this._config.removeKeyDownEventListener(CopyPasteHotkey.Paste, this.pasteEventHandler);
      this._config.removeKeyDownEventListener(CopyPasteHotkey.Cut, this.cutEventListener);
      this._config.removeKeyDownEventListener(DrawingHotkey.Unite, this.unite);
      this._config.removeKeyDownEventListener(DrawingHotkey.Subtract, this._config.subtract);
      this._config.removeKeyDownEventListener(DrawingHotkey.RemoveInstance, this.onRemoveInstancesHandler);
      this._config.removeKeyDownEventListener(DrawingHotkey.GroupDrawings, this.groupDrawings);
      this._config.removeKeyDownEventListener(DrawingHotkey.RemoveFromGroupGroup, this.removeDrawingsFromGroups);
      this._config.removeKeyDownEventListener(DrawingHotkey.Duplicate, this.duplicateEventListener);
    }
  }

  @autobind
  private onRemoveInstancesHandler(): void {
    this._config.onRemoveInstancesHandler();
  }

  @autobind
  private duplicateEventListener(e: KeyboardEvent): void {
    if (e.ctrlKey) {
      ConstantFunctions.stopEvent(e);
      this._config.duplicate();
    }
  }


  @autobind
  private copyCurrentSelectedInstancesListener(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    this._config.copyCurrentSelectedInstances();
  }

  @autobind
  private pasteEventHandler(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    this._config.pasteInstances(paper.view.center);
  }

  @autobind
  private cutEventListener(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    this._config.cut();
  }

  @autobind
  private removeDrawingsFromGroups(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    this._config.removeDrawingsFromGroups();
  }

  @autobind
  private unite(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    this._config.unite();
  }

  @autobind
  private groupDrawings(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    this._config.groupDrawings();
  }
}
