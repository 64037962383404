import { RequestStatus } from 'common/enums/request-status';
import { ActivityCategoryType, ResourceType } from '../enums';
import { MeasurementType } from '../interfaces/data';
import { DatabaseReduxState } from '../interfaces/redux-state';

export const DATABASE_INITIAL_STATE: DatabaseReduxState = {
  databases: [],
  currentDatabase: {
    database: null,
    activityListing: {
      activities: [],
      activityVariantActivityAssignmentInfo: [],
      categories: {
        counts: {
          [ActivityCategoryType.Nrm1]: null,
          [ActivityCategoryType.Nrm2]: null,
          [ActivityCategoryType.UniProduct]: null,
          [ActivityCategoryType.UniSystem]: null,
        },
        selectedCategory: null,
        selectedCategoryType: ActivityCategoryType.Nrm1,
      },
      defaultValues: {
        categories: {},
        lastSelectedExtractorFunction: null,
        lastSelectedUnitId: null,
      },
      totalCount: 0,
      disabledActivityVariantIds: [],
      selectedActivityVariantIds: [],
    },
    resourceListing: {
      resources: [],
      resourceType: ResourceType.Labour,
      totalCount: 0,
    },
    functions: [],
    units: [],
    measurementToUnits: {},
    measurementsMap: {},
    unitMap: {},
    measurementTypes: {
      [MeasurementType.Numeric]: { availableConstraintOperations: [] },
      [MeasurementType.String]: { availableConstraintOperations: [] },
    },
    editModel: {
      rootId: null,
      variantId: null,
      root: null,
      variant: null,
      mode: null,
      isOpen: false,
    },
  },
  statuses: {
    databaseResources: RequestStatus.NotRequested,
    databases: RequestStatus.NotRequested,
    activityListing: RequestStatus.NotRequested,
    resourceListing: RequestStatus.NotRequested,
    categories: RequestStatus.NotRequested,
    categoriesCounts: {
      [ActivityCategoryType.Nrm1]: RequestStatus.NotRequested,
      [ActivityCategoryType.Nrm2]: RequestStatus.NotRequested,
      [ActivityCategoryType.UniProduct]: RequestStatus.NotRequested,
      [ActivityCategoryType.UniSystem]: RequestStatus.NotRequested,
    },
  },
  categories: {
    [ActivityCategoryType.Nrm1]: [],
    [ActivityCategoryType.Nrm2]: [],
    [ActivityCategoryType.UniProduct]: [],
    [ActivityCategoryType.UniSystem]: [],
  },
};
