import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { CustomElementFilterForm, CustomElementFilterViewModel } from 'common/interfaces/custom-element-filter-builder';
import { CustomElementFilterBuilderCopyHelper } from 'common/utils/custom-element-filter-builder';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { CustomFiltersActions } from '../actions/creators/custom-filters-actions';
import { CustomFiltersTypes } from '../actions/types/custom-filters';
import { CustomFiltersApi } from '../api/custom-filters';

function* getCustomFiltersInfo(): SagaIterator {
  try {
    const filters = yield call(CustomFiltersApi.getFilters);
    yield put(CustomFiltersActions.setCustomFiltersInfo(filters));
  } catch (error) {
    console.error('custom filters: get custom filters info failed', error);
  }
}

function* getCustomFiltersByIds({ payload }: ActionWith<string[]>): SagaIterator {
  try {
    const filterModel = yield call(CustomFiltersApi.getFiltersByIds, payload);
    yield put(CustomFiltersActions.setCustomFilterEditModel(filterModel));
  } catch (error) {
    console.error('custom filters: get custom filters by ids failed', error);
  }
}

function* applyCustomFilter({ payload }: ActionWith<string[] | null>): SagaIterator {
  try {
    const filterModel = payload
      ? yield call(CustomFiltersApi.getFiltersByIds, payload)
      : null;
    yield put(CustomFiltersActions.setAppliedCustomFilter(filterModel));
  } catch (error) {
    console.error('custom filters: apply custom filter failed', error);
  }
}

function* createCustomFilters({ payload }: ActionWith<CustomElementFilterForm[]>): SagaIterator {
  try {
    yield call(CustomFiltersApi.addFilters, payload);
    yield put(CustomFiltersActions.getCustomFiltersInfo());
  } catch (error) {
    console.error('custom filters: create filters failed', error);
  }
}

function* updateCustomFilters({ payload }: ActionWith<CustomElementFilterForm[]>): SagaIterator {
  try {
    yield call(CustomFiltersApi.updateFilters, payload);
    const appliedCustomFilter = yield selectWrapper(state => state.quantityTakeOff.appliedCustomFilter);
    if (appliedCustomFilter) {
      yield put(CustomFiltersActions.applyCustomFilter(appliedCustomFilter.rootFilterIds));
    }
    yield put(CustomFiltersActions.getCustomFiltersInfo());
  } catch (error) {
    console.error('custom filters: update filters failed', error);
  }
}

function* deleteCustomFilters({ payload }: ActionWith<string[]>): SagaIterator {
  try {
    yield call(CustomFiltersApi.deleteFilters, payload);
    yield put(CustomFiltersActions.getCustomFiltersInfo());
  } catch (error) {
    console.error('custom filters: delete filters failed', error);
  }
}

function* copyCustomFilter({ payload }: ActionWith<{ id: string, name: string }>): SagaIterator {
  try {
    const filter: CustomElementFilterViewModel = yield call(CustomFiltersApi.getFiltersByIds, [payload.id]);
    const form = CustomElementFilterBuilderCopyHelper.getCopyForm(filter.filters[payload.id], payload.name);
    yield put(CustomFiltersActions.createCustomFilters([form]));
  } catch (error) {
    console.error('custom filters: copy custom filters', error);
  }
}

export function* customFilters(): SagaIterator {
  yield takeLatest(CustomFiltersTypes.GET_CUSTOM_FILTERS_INFO, getCustomFiltersInfo);
  yield takeLatest(CustomFiltersTypes.GET_CUSTOM_FILTERS_BY_IDS, getCustomFiltersByIds);
  yield takeLatest(CustomFiltersTypes.APPLY_CUSTOM_FILTER, applyCustomFilter);
  yield takeLatest(CustomFiltersTypes.CREATE_CUSTOM_FILTERS, createCustomFilters);
  yield takeLatest(CustomFiltersTypes.UPDATE_CUSTOM_FILTERS, updateCustomFilters);
  yield takeLatest(CustomFiltersTypes.DELETE_CUSTOM_FILTERS, deleteCustomFilters);
  yield takeLatest(CustomFiltersTypes.COPY_CUSTOM_FILTER, copyCustomFilter);
}
