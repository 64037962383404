import { QtoTreeRowProperty } from '../../interfaces/quantity-take-off/quantity-take-off-tree-row-property';


const isEmptyValue = (value: any): boolean => value === null || value === undefined;

export const PropertyHelper = {
  getActualValue<T>(property: QtoTreeRowProperty): T {
    if (!property) {
      return property as undefined;
    }

    return isEmptyValue(property.override)
      ? property.default
      : property.override;
  },

  isDefaultValue(property: QtoTreeRowProperty): boolean {
    return !!property
      && isEmptyValue(property.default)
      && !isEmptyValue(property.override);
  },

  isOverrideValue(property: QtoTreeRowProperty): boolean {
    return !!property
      && !isEmptyValue(property.override);
  },

  isAggregation(property: QtoTreeRowProperty): boolean {
    return property
      && isEmptyValue(property.default)
      && isEmptyValue(property.override);
  },

  isFormula(property: QtoTreeRowProperty): boolean {
    const isDefaultFormula = (): boolean => !property.override
      && property.default
      && typeof (property.default) === 'string'
      && property.default.startsWith('=');
    const isOverrideFormula = (): boolean => property.override
      && typeof (property.override) === 'string'
      && property.override.startsWith('=');
    return property && (isOverrideFormula() || isDefaultFormula());
  },

  getAggregationProperty(): QtoTreeRowProperty {
    return {
      default: null,
      override: null,
    };
  },

  columnProperties: {
    header: 'header',
    width: 'width',
    aggregationStrategy: 'aggregationStrategy',
    pinned: 'pinned',
    isVisible: 'isVisible',
    unit: 'unit',
  },
};
