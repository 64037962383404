const prefix = '@drawings-export-setting';

export const ExportSettingsActionTypes = {
  SET_EXPORT_PAGES: `${prefix}/SET_EXPORT_PAGES`,
  SET_EXPORT_MEASUREMENTS: `${prefix}/SET_EXPORT_MEASUREMENTS`,
  SET_EXPORT_ANNOTATIONS: `${prefix}/SET_EXPORT_ANNOTATIONS`,
  TOGGLE_DRAWING_STYLES_ENABLED: `${prefix}/TOGGLE_DRAWING_STYLES_ENABLED`,
  TOGGLE_SEGMENT_LENGTH: `${prefix}/TOGGLE_SEGMENT_LENGTH`,
  TOGGLE_SEGMENT_LENGTH_ON_LAYER: `${prefix}/TOGGLE_SEGMENT_LENGTH_ON_LAYER`,
  EXPORT_PDF: `${prefix}/EXPORT_PDF`,
  SET_LOADING_EXPORT: `${prefix}/SET_LOADING_EXPORT`,
};
