import { H5, RectangleButton, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { RenderIf } from 'common/components/render-if';
import { BillingPeriodUnit } from '../../../enums/billing-period-unit';
import { BillingPricingModel } from '../../../enums/billing-pricing-model';
import { SubscriptionAddon } from '../../../interfaces/subscription-addon';
import { SubscriptionPlanVariantWithName } from '../../../interfaces/subscription-plan-variant-with-name';
import { PriceField } from '../../price-field';
import { Styled } from './styled';

interface Props {
  selectedAddons: SubscriptionAddon[];
  ghostButton: boolean;
  variant: SubscriptionPlanVariantWithName;
  onSelectPlanGroup: (planId: string) => void;
  buttonText?: string;
  hideDescription?: boolean;
  isPinBlock?: boolean;
}

export class SubscriptionOfferCard extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      variant,
      ghostButton,
      buttonText,
      hideDescription,
      isPinBlock,
    } = this.props;
    const isShowLabel = variant.name === 'Team';

    return (
      <Styled.Container isShowLabel={isShowLabel && !isPinBlock}>
        <RenderIf condition={isShowLabel && !isPinBlock}>
          <Styled.PopularLabel>
            <Text fontSize={12}>🌟 Most popular for Teams</Text>
          </Styled.PopularLabel>
        </RenderIf>
        <H5>{variant.name}</H5>
        <Styled.Price>
          <Styled.PriceCost>
            <PriceField GBPPrice={this.getPrice()}/>
          </Styled.PriceCost>
          <Styled.PriceDescription>
            <Text fontSize={12} color='additionalFont'>
              {variant.pricingModel === BillingPricingModel.PerUnit
                ? 'per user, per month'
                : 'per month'}
            </Text>
            {
              variant.billingPeriodUnit === BillingPeriodUnit.Year && (
                <Text fontSize={12} color='additionalFont'>{'(paid annually)'}</Text>
              )
            }
          </Styled.PriceDescription>
        </Styled.Price>
        <RectangleButton
          size='m'
          mood='secondary'
          ghost={ghostButton}
          onClick={this.onClick}
          text={buttonText || variant.buttonCaption}
        />
        {!hideDescription && variant.description && <Styled.Description>{variant.description}</Styled.Description>}
      </Styled.Container>
    );
  }

  private getMonthCount(period: BillingPeriodUnit): number {
    switch (period) {
      case BillingPeriodUnit.Month: return 1;
      case BillingPeriodUnit.Year: return 12;
      default: return 1;
    }
  }

  private getPrice(): number {
    const { selectedAddons, variant } = this.props;

    const addonsPrice = selectedAddons.reduce(((acc, addon) => acc + addon.price), 0);
    const monthCount = this.getMonthCount(variant.billingPeriodUnit);
    return variant.isFree
      ? 0
      : Math.round((variant.price + addonsPrice) / monthCount) / 100;
  }

  @autobind
  private onClick(): void {
    this.props.onSelectPlanGroup(this.props.variant.name);
  }
}
