import styled from 'styled-components';

const Container = styled.div`
  width: 210px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  *, & {
    box-sizing: border-box;
  }
  > p {
    width: 100%;
  }
`;

const Measure = styled.div<{ isActive: boolean }>`
  display: inline-flex;
  align-items: center;
  padding: 5px;
  height: 30px;
  background: ${p => p.theme.color[p.isActive ? 'turquoise' : 'background']};
  border-radius: 5px;
  width: fit-content;
  cursor: ${p => p.isActive ? 'default' : 'pointer'};
  svg {
    height: 20px;
    margin-right: 10px;
    fill: ${p => p.theme.color[p.isActive ? 'white' : 'gray']};
  }
`;

const Label = styled.div`
  max-width: 100%;
`;


export const Styled = {
  Container,
  Measure,
  Label,
};
