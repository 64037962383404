const prefix = '@work_packages';

export const WorkPackagesActionTypes = {
  LOAD_DATA: `${prefix}/LOAD_DATA`,
  LOAD_SUCCESSED: `${prefix}/LOAD_SUCCESSED`,
  SET_REQUEST: `${prefix}/SET_REQUEST`,
  SET_SUCCESSED: `${prefix}/SET_SUCCESSED`,
  SET_ACTIVITIES: `${prefix}/SET_ACTIVITIES`,
  RENAME_PACKAGE: `${prefix}/RENAME_PACKAGE`,
  CREATE_NEW: `${prefix}/CREATE_NEW`,
  SET_GROUPED: `${prefix}/SET_GROUPED`,
  SET_ACTIVE: `${prefix}/SET_ACTIVE`,
  SELECT_GROUP: `${prefix}/SELECT_GROUP`,
  SELECT_ALL_GROUP: `${prefix}/SELECT_ALL_GROUP`,
  SET_ACTIVE_GROUP: `${prefix}/SET_ACTIVE_GROUP`,
  SET_ACTIVE_WORKPACKAGE_BY_ID: `${prefix}/SET_ACTIVE_WORKPACKAGE_BY_ID`,
  SELECT_GROUP_ACTIVITY: `${prefix}/SELECT_GROUP_ACTIVITY`,
  SELECT_ALL_GROUP_ACTIVITY: `${prefix}/SELECT_ALL_GROUP_ACTIVITY`,
  REPLACE_GROUP: `${prefix}/REPLACE_GROUP`,
  REPLACE_ACTIVITY: `${prefix}/REPLACE_ACTIVITY`,
  REMOVE_WORKPACKAGE: `${prefix}/REMOVE_WORKPACKAGE`,
  DROP_EDIT_WORK_PACKAGES_STATE: `${prefix}/DROP_EDIT_WORK_PACKAGES_STATE`,
  SELECT_ACTIVITY: `${prefix}/SELECT_ACTIVITY`,
};
