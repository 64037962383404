import { RequestStatus } from 'common/enums/request-status';
import { DashboardReduxState } from '../interfaces/dashboard/dashboard-redux-state';

export const DASHBOARD_INITIAL_STATE: DashboardReduxState = {
  costHistogram: null,
  costEstimateTreeMap: null,
  statuses: {
    costHistogram: RequestStatus.Loaded,
    costEstimateTreeMap: RequestStatus.Loaded,
  },
};
