import { merge } from 'lodash';

import { RequestStatus } from 'common/enums/request-status';
import { NumberDictionary, StringDictionary } from 'common/interfaces/dictionary';
import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import { StringUtils } from 'common/utils/string-utils';
import { ActivityCategory } from '../../databases/interfaces/data';
import {
  FinishLayer,
  LayerMaterial,
} from '../../project-dashbord/interfaces/layers';
import {
  getLayerMaterialCode,
  proccessFinishLayers,
} from '../../project-dashbord/utils/mappers';
import {
  ClassificationElementTypeInfo,
  ClassificationLoadBimInfoSuccessPayload,
} from '../actions/payloads/classification';
import { ClassificationMultiselectOperation } from '../enums/classification-multiselect-operation';
import { ClassificationObjectType } from '../enums/classification-object-type';
import { RevitTreeLevel } from '../enums/revit-tree-level';
import { ClassificationBimElementInfo } from '../interfaces/classification/bim-element-info';
import { ClassificationGroup } from '../interfaces/classification/classification-group';
import { ClassificationHeaderTabType } from '../interfaces/classification/classification-header-tab-type';
import { ClassificationReduxState } from '../interfaces/classification/classification-redux-state';
import { ClassificationShortInfo } from '../interfaces/classification/classification-short-info';
import { ClassificationSource } from '../interfaces/classification/classification-source';
import { ClassificationTarget } from '../interfaces/classification/classification-target';
import { ClassificationComparisonPatternGroup } from '../interfaces/classification/comparison-pattern-group';
import { CurrentElementClassification } from '../interfaces/classification/current-element-classification';
import { ElementClassificationResponse } from '../interfaces/classification/element-classification-response';
import {
  ClassificationElementTypeComparisonPattern,
} from '../interfaces/classification/element-type-comparison-pattern';
import {
  ClassificationModelBrowserFilters,
} from '../interfaces/classification/model-browser-filters';
import {
  ClassificationOntologyNode,
  ClassificationOntologyUniclassInfo,
  ClassificationOntologyVariantTree,
} from '../interfaces/classification/ontology-data';
import { SourceRevitTreeNode } from '../interfaces/classification/source-revit-tree-node';
import { UniclassEntityShortInfo } from '../interfaces/classification/uniclass-entity-short-info';
import { RevitTreeFullInfo } from '../interfaces/revit-tree-full-info';
import { RevitTreeListNode } from '../interfaces/revit-tree-list-node';
import { RevitTreePathInfo } from '../interfaces/revit-tree-path-info';


const tabNames = ['Architecture', 'Structure', 'MEP'];
const MAIN_LAYER = 'aux_main';

function createCategory(
  name: string,
  firstEntityOfLevel: boolean,
  lastEntityOfLevel: boolean,
): RevitTreeListNode {
  return {
    name,
    expanded: false,
    engineIds: [0, 0],
    level: RevitTreeLevel.Category,
    lastChildId: 0,
    firstEntityOfLevel,
    lastEntityOfLevel,
    isError: false,
    isAugmentation: false,
  };
}

function createFamily(
  name: string,
  parentId: number,
  firstEntityOfLevel: boolean,
  lastEntityOfLevel: boolean,
  isParentLastEntityOfLevel: boolean,
): RevitTreeListNode {
  return {
    name,
    parentId,
    level: RevitTreeLevel.Family,
    lastChildId: 0,
    expanded: false,
    engineIds: [0, 0],
    firstEntityOfLevel,
    lastEntityOfLevel,
    isParentLastEntityOfLevel,
    isError: false,
    isAugmentation: false,
  };
}

function createElementType(
  name: string,
  parentId: number,
  firstEntityOfLevel: boolean,
  lastEntityOfLevel: boolean,
  isParentLastEntityOfLevel: boolean,
  categoryType: number,
): RevitTreeListNode {
  return {
    name,
    parentId,
    level: RevitTreeLevel.ElementType,
    engineIds: [0, 0],
    layersId: null,
    firstEntityOfLevel,
    lastEntityOfLevel,
    isParentLastEntityOfLevel,
    categoryType,
    isError: false,
    isAugmentation: false,
  };
}

function createBimHandleElement(
  node: SourceRevitTreeNode,
  category: number,
  family: number,
  type: number,
): ClassificationBimElementInfo {
  return {
    id: node.id,
    type,
    category,
    family,
    sourceId: node.sId,
    categoryType: node.categoryType,
  };
}

interface GroupData {
  elements: SourceRevitTreeNode[];
  layers: LayerMaterial[][];
}
function processRevitTree(
  { allBimInfo }: RevitTreeFullInfo,
  elementGroups: ClassificationGroup[],
): ClassificationLoadBimInfoSuccessPayload {
  const badIds = new Array<number>();
  const groupLinks = elementGroups.map(x => new Set(x.bimHandleIds));
  const treeData = new Array<RevitTreeListNode>();
  const elements = new Array<ClassificationBimElementInfo>();
  const layers = new Array<FinishLayer[]>();
  const tabs = new Array<number>();
  for (let categoryIndex = 0; categoryIndex < allBimInfo.length; categoryIndex++) {
    const category = allBimInfo[categoryIndex];
    const categoryEntity = createCategory(
      category.n,
      categoryIndex === 0,
      categoryIndex === allBimInfo.length - 1,
    );
    const categoryId = treeData.push(categoryEntity) - 1;
    const categoryIdStart = elements.length;
    for (let familyIndex = 0; familyIndex < category.v.length; familyIndex++) {
      const family = category.v[familyIndex];
      const familyEntity = createFamily(
        family.n,
        categoryId,
        familyIndex === 0,
        familyIndex === category.v.length - 1,
        categoryEntity.lastEntityOfLevel,
      );
      const familyId = treeData.push(familyEntity) - 1;
      const idsStart = elements.length;
      for (let elementTypeIndex = 0; elementTypeIndex < family.v.length; elementTypeIndex++) {
        const elementType = family.v[elementTypeIndex];
        const existedTabs = [];
        const dataByTab: NumberDictionary<NumberDictionary<GroupData>> = {};
        for (const bimElement of elementType.v) {
          existedTabs.push(bimElement.categoryType);
          if (!(bimElement.categoryType in dataByTab)) {
            dataByTab[bimElement.categoryType] = {};
          }
          const groupId = groupLinks.findIndex(x => x.has(bimElement.id));
          if (groupId === -1) continue;
          let currentGroupData: GroupData;
          if (!(groupId in dataByTab[bimElement.categoryType])) {
            currentGroupData = {
              elements: [],
              layers: null,
            };
            dataByTab[bimElement.categoryType][groupId] = currentGroupData;
          } else {
            currentGroupData = dataByTab[bimElement.categoryType][groupId];
          }
          currentGroupData.elements.push(bimElement);
          if (bimElement.layers) {
            currentGroupData.layers = currentGroupData.layers || [];
            currentGroupData.layers.push(bimElement.layers);
          }
        }
        const tabSet = Array.from(new Set<number>(existedTabs));
        arrayUtils.extendArray(tabs, tabSet);
        for (let i = 0; i < tabSet.length; i++) {
          const tab = tabSet[i];
          const elementTypeTabName =
            tabSet.length > 1 ? `[${tabNames[tab]}] ${elementType.n}` : elementType.n;
          const currentGroups = dataByTab[tab];
          const entries = Object.entries(currentGroups);
          let elementTypesCount = 0;
          for (let j = 0; j < entries.length; j++) {
            const [key, data] = entries[j] as [string, GroupData];
            let groups: GroupData[] = [data];
            if (data.layers) {
              const elementsByLayersGroups: StringDictionary<GroupData> = {};
              for (let k = 0; k < data.layers.length; k++) {
                const elementLayers = data.layers[k];
                let connectedLayersCode = '|';
                if (elementLayers && elementLayers.length) {
                  for (const layer of elementLayers) {
                    connectedLayersCode += `${getLayerMaterialCode(layer)}|`;
                  }
                }
                if (connectedLayersCode in elementsByLayersGroups) {
                  elementsByLayersGroups[connectedLayersCode].layers.push(elementLayers);
                  elementsByLayersGroups[connectedLayersCode].elements.push(data.elements[k]);
                } else {
                  elementsByLayersGroups[connectedLayersCode] = {
                    elements: [data.elements[k]],
                    layers: [elementLayers],
                  };
                }
              }
              groups = Object.values(elementsByLayersGroups);
            }
            const doesNeedToAddGroupNumber = groups.length > 1 || entries.length > 1;
            for (let k = 0; k < groups.length; k++) {
              elementTypesCount++;
              const group = groups[k];
              const elementTypeName = doesNeedToAddGroupNumber
                ? `${elementTypeTabName} #${elementTypesCount}` : elementTypeTabName;
              const elementTypeIsFirst = elementTypeIndex === 0 && i === 0 && j === 0 && k === 0;
              const elementTypeIsLast = elementTypeIndex === family.v.length - 1 &&
              i === tabSet.length - 1 &&
              j === entries.length - 1 &&
              k === groups.length - 1;
              const elementTypeEntity = createElementType(
                elementTypeName,
                familyId,
                elementTypeIsFirst,
                elementTypeIsLast,
                familyEntity.lastEntityOfLevel,
                tab,
              );
              const elementTypeId = treeData.push(elementTypeEntity) - 1;
              elementTypeEntity.engineIds[0] = elements.length;
              if (elementGroups[key].isUndefined) {
                arrayUtils.extendArray(badIds, elementGroups[key].bimHandleIds);
                categoryEntity.isError = true;
                familyEntity.isError = true;
                elementTypeEntity.isError = true;
              }
              if (elementGroups[key].augmentation) {
                categoryEntity.isAugmentation = true;
                familyEntity.isAugmentation = true;
                elementTypeEntity.isAugmentation = true;
              }
              if (group.layers) {
                const agregatedLayers = proccessFinishLayers(group.layers);
                elementTypeEntity.layersId = layers.push(agregatedLayers) - 1;
              }
              for (const element of group.elements) {
                elements.push(createBimHandleElement(element, categoryId, familyId, elementTypeId));
              }
              elementTypeEntity.engineIds[1] = elements.length;
            }
          }
        }
      }
      if (familyId === treeData.length - 1) {
        if (familyEntity.lastEntityOfLevel) {
          const previousEntity = treeData[familyId - 1];
          if (previousEntity.level === RevitTreeLevel.ElementType) {
            treeData[previousEntity.parentId].lastEntityOfLevel = true;
            for (let i = previousEntity.parentId + 1; i < familyId; i++) {
              treeData[i].isParentLastEntityOfLevel = true;
            }
          }
        }
        treeData.pop();
      } else {
        familyEntity.engineIds = [idsStart, elements.length];
        familyEntity.lastChildId = treeData.length - 1;
      }
    }
    if (categoryId === treeData.length - 1) {
      if (categoryEntity.lastEntityOfLevel) {
        const previousEntity = treeData[categoryId - 1];
        if (previousEntity.level === RevitTreeLevel.ElementType) {
          const lastFamilyOfPrevCategory = treeData[previousEntity.parentId];
          treeData[lastFamilyOfPrevCategory.parentId].lastEntityOfLevel = true;
          for (let i = lastFamilyOfPrevCategory.parentId + 1; i < categoryId; i++) {
            const familyEntity = treeData[i];
            familyEntity.isParentLastEntityOfLevel = true;
            i = familyEntity.lastChildId;
          }
        }
      }
      treeData.pop();
    } else {
      categoryEntity.engineIds = [categoryIdStart, elements.length];
      categoryEntity.lastChildId = treeData.length - 1;
    }
  }

  const uniqueTabs = new Array<ClassificationHeaderTabType>();

  for (const tab of new Set(tabs)) {
    uniqueTabs.push({
      name: tabNames[tab],
      id: tab,
    });
  }

  uniqueTabs.sort((x, y) => (x.id < y.id ? -1 : 1));
  uniqueTabs.unshift({ name: 'All', id: -1 });
  return {
    treeData,
    layers,
    elements,
    tabs: uniqueTabs,
    badElementIds: badIds,
  };
}

function getModelBrowserNodes(modelBrowser: RevitTreeListNode[]): number[] {
  const result = new Array<number>();
  for (let i = 0; i < modelBrowser.length; i++) {
    result.push(i);
    const { expanded, lastChildId } = modelBrowser[i];
    if (!expanded && lastChildId) {
      i = lastChildId;
      continue;
    }
  }
  return result;
}

function filterTreeNodes(
  tree: RevitTreeListNode[],
  tab: number,
  enabledOnlyUnclassified: boolean = false,
  searchQuery: string = '',
  minimumLevel: RevitTreeLevel = RevitTreeLevel.Category,
  isParentCompare: boolean = false,
  startPosition: number = 0,
  endPosition: number = null,
  parentId: number = null,
): RevitTreeListNode[] {
  const end = endPosition || tree.length;
  const result = new Array<RevitTreeListNode>();
  const { searchRegex, searchWords } = StringUtils.createSearchByWordRegex(searchQuery);
  let lastCurrentEntity = null;
  for (let i = startPosition; i < end; i++) {
    const node = tree[i];
    if (!node.isError && enabledOnlyUnclassified) continue;
    const nameLower = node.name.toLowerCase();
    const isCompare =
      !isParentCompare && !!searchQuery && searchWords.every(word => nameLower.includes(word));
    if (node.level !== RevitTreeLevel.ElementType) {
      const children = filterTreeNodes(
        tree,
        tab,
        enabledOnlyUnclassified,
        searchQuery,
        minimumLevel,
        isCompare || isParentCompare,
        i + 1,
        node.lastChildId + 1,
        result.length + parentId + node.level - minimumLevel,
      );
      if (!children.length) continue;
      let isError = false;
      for (let j = 0; j < children.length; j++) {
        const child = children[j];
        if (child.isError === true) {
          isError = true;
          break;
        }
        if (child.level === RevitTreeLevel.Family) {
          j = child.lastChildId - parentId - node.level + minimumLevel - result.length - 1;
        }
      }

      const newNode: RevitTreeListNode = {
        ...node,
        isError,
        expanded: false,
        parentId,
      };
      if (isCompare) {
        newNode.name = newNode.name.replace(searchRegex, substring => `<b>${substring}</b>`);
      } else if (searchQuery) {
        newNode.expanded = true;
      }
      lastCurrentEntity = result.push(newNode) - 1;
      if (children.length === 1) result.push(children[0]);
      else arrayUtils.extendArray(result, children);
      newNode.lastChildId = result.length - 1 + parentId + node.level - minimumLevel;
      i = node.lastChildId;
    } else {
      if (node.categoryType !== tab && tab !== -1) continue;
      if (isCompare) {
        lastCurrentEntity =
          result.push({
            ...node,
            name: node.name.replace(searchRegex, substring => `<b>${substring}</b>`),
            parentId,
          }) - 1;
      } else {
        if (searchQuery && !isParentCompare) continue;
        lastCurrentEntity = result.push({ ...node, parentId }) - 1;
      }
    }
  }
  if (lastCurrentEntity !== null) {
    result[0].firstEntityOfLevel = true;
    const lastEntity = result[lastCurrentEntity];
    lastEntity.lastEntityOfLevel = true;
    if (
      lastEntity.level === RevitTreeLevel.Category ||
      lastEntity.level === RevitTreeLevel.Family
    ) {
      for (let i = lastCurrentEntity; i < lastEntity.lastChildId - parentId; i++) {
        if (result[i].level === lastEntity.level + 1) {
          result[i].isParentLastEntityOfLevel = true;
          i = result[i].lastChildId;
        }
      }
    } else if (minimumLevel === RevitTreeLevel.ElementType) {
      for (const node of result) {
        node.isParentLastEntityOfLevel = true;
      }
    }
  }
  return result;
}

// preparation for usage in multilevel drop down
function processVariantsTree(variants: ClassificationOntologyVariantTree[]): void {
  for (const variant of variants) {
    variant.value = `${variant.canonicalName}`;
    if (variant.children) {
      processVariantsTree(variant.children);
    }
  }
}

/**
 * mutates graph for ui
 * @return true if has augmentation
 */
function prepareGraph(
  node: ClassificationOntologyNode,
  isView: boolean,
): boolean {
  if (isView && node.variants) {
    node.variants = undefined;
  }
  processVariantsTree(node.variantsNew);
  if (!node.isSuggest && node.variants && node.variants.length > 0) {
    const { canonicalName, canonicalRel, humanReadableName, humanReadableRel } = node;
    node.variants.unshift({
      canonicalName,
      canonicalRel,
      humanReadableName,
      humanReadableRel,
      isSelected: true,
    });
  }
  if (!node.subnodes || !node.subnodes.length) {
    node.maxChildrenCount = 0;
    return;
  }
  let maxChildCount = 0;
  let isAugmentation = false;
  for (const subnode of node.subnodes) {
    if (subnode.canonicalName === MAIN_LAYER) {
      node.mainLayer = true;
    }
    if (isView && subnode.isSuggest) {
      subnode.show = false;
      continue;
    } else if (subnode.isSuggest && !subnode.show) {
      subnode.show = true;
    }
    if (subnode.show === false) continue;
    if (prepareGraph(subnode, isView) && subnode.augmentation) {
      isAugmentation = true;
    }
    maxChildCount += subnode.maxChildrenCount || 1;
  }
  node.maxChildrenCount = maxChildCount;
  return isAugmentation;
}

const undefinedClassificationInfo = { title: 'Undefined', code: 'Undefined' };

function createCurrentClassificationVariant<T extends { systemId: number, productId: number }>(
  { systemId, productId }: T,
  productsShortInfo: NumberDictionary<UniclassEntityShortInfo>,
  systemsShortInfo: NumberDictionary<UniclassEntityShortInfo>,
  source: ClassificationSource,
  confidence?: number | undefined,
): CurrentElementClassification {
  const system = systemsShortInfo[systemId] || undefinedClassificationInfo;
  const product = productsShortInfo[productId] || undefinedClassificationInfo;
  return {
    systemCode: system.code,
    systemDescription: system.title,
    productCode: product.code,
    productDescription: product.title,
    systemId,
    productId,
    confidence,
    source,
  };
}

function createCurrentElementClassification(
  currentElementClassificationResponse: ElementClassificationResponse,
  { productsShortInfo, systemsShortInfo }: ClassificationReduxState,
): CurrentElementClassification[] {
  currentElementClassificationResponse.predictedClassifications.sort((x, y) =>
    x.rank < y.rank ? -1 : 1,
  );
  const mappedElementClassificition = new Array<CurrentElementClassification>();
  if (currentElementClassificationResponse.userClassifications.length > 0) {
    const [
      selectedUserClassification,
    ] = currentElementClassificationResponse.userClassifications.splice(0, 1);
    const selectedClassification = createCurrentClassificationVariant(
      selectedUserClassification,
      productsShortInfo,
      systemsShortInfo,
      ClassificationSource.User,
    );
    mappedElementClassificition.push(selectedClassification);
    for (const customClassification of currentElementClassificationResponse.userClassifications) {
      const userClassification = createCurrentClassificationVariant(
        customClassification,
        productsShortInfo,
        systemsShortInfo,
        ClassificationSource.User,
      );
      mappedElementClassificition.push(userClassification);
    }
  }
  for (const prediction of currentElementClassificationResponse.predictedClassifications) {
    const index = currentElementClassificationResponse.userClassifications.findIndex(
      u => u.productId === prediction.productId && u.systemId === prediction.systemId,
    );
    if (index !== -1) continue;
    const predictedClassificationInfo = createCurrentClassificationVariant(
      prediction,
      productsShortInfo,
      systemsShortInfo,
      ClassificationSource.Predicted,
      prediction.confidence,
    );
    mappedElementClassificition.push(predictedClassificationInfo);
  }
  return mappedElementClassificition;
}

function extractNeededInfoForAssemblyOrLayer(
  classification: CurrentElementClassification,
  isRootElement: boolean,
): ClassificationShortInfo {
  if (isRootElement) {
    const { systemDescription, systemCode, systemId, confidence } = classification;
    return {
      code: systemCode,
      description: systemDescription,
      id: systemId,
      confidence,
    };
  } else {
    const { productCode, productDescription, productId, confidence } = classification;
    return {
      code: productCode,
      description: productDescription,
      id: productId,
      confidence,
    };
  }
}

function uniClassToItemClassification(
  system: UniclassEntityShortInfo,
  product: UniclassEntityShortInfo,
): CurrentElementClassification {
  const result: Partial<CurrentElementClassification> = {
    source: ClassificationSource.User,
  };
  if (system) {
    result.systemId = system.id;
    result.systemCode = system.code;
    result.systemDescription = system.title;
  }

  if (product) {
    result.productId = product.id;
    result.productCode = product.code;
    result.productDescription = product.title;
  }
  return result as CurrentElementClassification;
}

function createBreadCrumbs(
  modelTree: RevitTreeListNode[],
  element: ClassificationBimElementInfo,
): RevitTreePathInfo[] {
  const breadCrumbs: RevitTreePathInfo[] = [
    {
      name: modelTree[element.category].name,
      category: element.category,
      type: RevitTreeLevel.Category,
    },
    {
      name: modelTree[element.family].name,
      category: element.category,
      family: element.family,
      type: RevitTreeLevel.Family,
    },
    {
      name: modelTree[element.type].name,
      category: element.category,
      family: element.family,
      elementType: element.type,
      type: RevitTreeLevel.ElementType,
    },
  ];
  return breadCrumbs;
}

function splitUniclass(
  uniclass: ClassificationOntologyUniclassInfo,
): ClassificationOntologyUniclassInfo {
  const [systemCode, productCode] = uniclass.code.split(';');
  const [systemDescription, productDescription] = uniclass.description.split(';');
  return {
    systemCode,
    productCode,
    systemDescription,
    productDescription,
  };
}

function calculateErrorElementTypes(tree: RevitTreeListNode[]): { all: number, error: number } {
  let error = 0;
  let all = 0;
  for (const node of tree) {
    if (node.level === RevitTreeLevel.ElementType) {
      all++;
      if (node.isError) {
        error++;
      }
    }
  }
  return { all, error };
}

function getLayerFunctionType(layerFunction: string): ClassificationObjectType {
  if (layerFunction.toLowerCase().startsWith(ClassificationObjectType.Finish)) {
    return ClassificationObjectType.Finish;
  } else {
    return layerFunction.toLowerCase() as ClassificationObjectType;
  }
}

function isTreeFiltered(filters: ClassificationModelBrowserFilters): boolean {
  const { tab, unclassifiedFilterEnabled, searchQuery, isolationEnabled } = filters;
  return tab !== -1 || isolationEnabled || unclassifiedFilterEnabled || !!searchQuery;
}

function createCustomUniclass(state: ClassificationReduxState): ClassificationReduxState {
  if (!state.tempUniclass) {
    return state;
  }
  const { system, product } = state.tempUniclass;
  return new MonoliteHelper(state)
    .setPrepend(_ => _.elementClassification[0], {
      ...uniClassToItemClassification(system, product),
      source: ClassificationSource.User,
    })
    .set(_ => _.classificationSelectedIndex, 0)
    .set(_ => _.isSaveButtonEnabled, true)
    .get();
}

function enableSaveButton(state: ClassificationReduxState): ClassificationReduxState {
  return new MonoliteHelper(state)
    .set(_ => _.isSaveButtonEnabled, true)
    .set(_ => _.elementClassificationLoadingStatus, RequestStatus.Loading)
    .get();
}

function changeTreeSelectStatus(
  tree: RevitTreeListNode[],
  value: boolean = true,
  start: number = 0,
  end: number = null,
): { tree: RevitTreeListNode[], changedElementTypes: number } {
  const endRange = end === null ? tree.length - 1 : end;
  const treeHelper = new MonoliteHelper(tree);
  let changedElementTypes = 0;
  for (let i = start; i <= endRange; i++) {
    const node = tree[i];
    const hasChildren = node.level !== RevitTreeLevel.ElementType;
    if (node.selected !== value) {
      if (!hasChildren) {
        changedElementTypes++;
      }
      treeHelper.set(_ => _[i].selected, value);
    } else if (hasChildren && node.hasSelectedChildren === value) {
      i = node.lastChildId;
    }
    if (hasChildren && node.hasSelectedChildren !== value) {
      treeHelper.set(_ => _[i].hasSelectedChildren, value);
    }
  }
  return { tree: treeHelper.get(), changedElementTypes };
}

function searchFilterTree(
  state: ClassificationReduxState,
  query: string,
): MonoliteHelper<ClassificationReduxState> {
  const breadCrumbs = state.modelBrowserFilters.breadCrumbs || [];
  const lastBreadCrumb = breadCrumbs[breadCrumbs.length - 1];
  const helper = new MonoliteHelper(state);
  const modelTree = changeTreeSelectStatus(state.modelTree, false).tree;
  let filteredTree: RevitTreeListNode[];
  if (lastBreadCrumb) {
    const lastTreeId =
      lastBreadCrumb.type === RevitTreeLevel.Category
        ? lastBreadCrumb.category
        : lastBreadCrumb.family;
    const item = modelTree[lastTreeId];
    const newTree = modelTree.slice(lastTreeId + 1, item.lastChildId + 1);
    const newTreeHelper = new MonoliteHelper(newTree);
    for (let i = 0; i < newTree.length; i++) {
      const node = newTree[i];
      if (node.lastChildId) {
        const newLastChild = node.lastChildId - lastTreeId - 1;
        newTreeHelper
          .set(_ => _[i].isParentLastEntityOfLevel, true)
          .set(_ => _[i].lastChildId, newLastChild);
        if (!newTree[newLastChild + 1]) {
          if (!node.lastEntityOfLevel) {
            newTreeHelper.set(_ => _[i].lastEntityOfLevel, true);
          }
          for (let j = i + 1; j < newLastChild + 1; j++) {
            newTreeHelper.set(_ => _[j].isParentLastEntityOfLevel, true);
          }
          break;
        }
        i = newLastChild;
      } else {
        newTreeHelper.set(_ => _[i].isParentLastEntityOfLevel, true);
      }
    }
    filteredTree = filterTreeNodes(
      newTreeHelper.get(),
      state.modelBrowserFilters.tab,
      state.modelBrowserFilters.unclassifiedFilterEnabled,
      query.toLowerCase(),
      lastBreadCrumb.type + 1,
    );
  } else {
    filteredTree = filterTreeNodes(
      state.modelTree,
      state.modelBrowserFilters.tab,
      state.modelBrowserFilters.unclassifiedFilterEnabled,
      query.toLowerCase(),
    );
  }
  const { all } = calculateErrorElementTypes(filteredTree);
  return changeFilter(helper)
    .set(_ => _.modelTree, modelTree)
    .set(_ => _.modelBrowserFiltered, filteredTree)
    .set(_ => _.modelBrowserFilters.searchQuery, query)
    .set(_ => _.filteredElementTypesCount, all);
}

function checkAndChangeParentSelectStatus(
  tree: RevitTreeListNode[],
  start: number = 0,
  end: number = null,
): RevitTreeListNode[] {
  const endRange = end === null ? tree.length : end;
  let hasSelectedChild = false;
  let hasUnselectedChild = false;
  for (let i = start + 1; i <= endRange; i++) {
    if (hasUnselectedChild && hasSelectedChild) break;

    if (tree[i].selected) {
      hasSelectedChild = true;
    } else {
      hasUnselectedChild = true;
    }
  }
  return new MonoliteHelper(tree)
    .set(_ => _[start].selected, !hasUnselectedChild)
    .set(_ => _[start].hasSelectedChildren, hasSelectedChild)
    .get();
}

function changeSystemOrProductFilter(
  state: ClassificationReduxState,
  filter: string,
  identifier: 'product' | 'system',
): ClassificationReduxState {
  const helper = new MonoliteHelper(state);
  if (state.tempUniclass !== null) {
    helper.set(_ => _.tempUniclass[identifier], null);
  }
  return helper
    .set(_ => _.isSystemTree, identifier === 'system')
    .set(_ => _.filterValue, filter)
    .get();
}

function changeFilter(
  helper: MonoliteHelper<ClassificationReduxState>,
): MonoliteHelper<ClassificationReduxState> {
  return helper
    .set(_ => _.selectedElementTypesCount, 0)
    .set(_ => _.selectedIds, [])
    .set(_ => _.selectedPath, null);
}

function* getSelectedElementTypes(tree: RevitTreeListNode[]): IterableIterator<number> {
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.level !== RevitTreeLevel.ElementType) {
      if (!node.hasSelectedChildren) {
        i = node.lastChildId;
      }
    } else if (node.selected) {
      yield i;
    }
  }
}


function selectElementTypes<T extends number | ClassificationElementTypeInfo>(
  state: ClassificationReduxState,
  elementTypes: T[],
  getIndex: (value: T) => number,
): ClassificationReduxState {
  const isolationDisabled = !isTreeFiltered(state.modelBrowserFilters);
  let { tree } = changeTreeSelectStatus(isolationDisabled ? state.modelTree : state.modelBrowserFiltered, false);
  const parentIds = new Array<number>();
  const selectedIds = new Array<number>();
  const selectedPath = elementTypes[elementTypes.length - 1] ?  getIndex(elementTypes[elementTypes.length - 1]) : null;
  for (const value of elementTypes) {
    const id = getIndex(value);
    const elementType = tree[id];
    elementType.selected = true;
    parentIds.push(elementType.parentId);
    const [start, end] = elementType.engineIds;
    arrayUtils.extendArray(selectedIds, state.bimElements.slice(start, end).map(x => x.id));
  }
  const minimumLevel = getMinimumLevel(state.modelBrowserFilters);
  if (minimumLevel !== RevitTreeLevel.ElementType) {
    for (const familyId of new Set(parentIds)) {
      let parentItem = tree[familyId];
      let currentItemId = familyId;
      while (currentItemId !== null && parentItem.level >= minimumLevel) {
        tree = checkAndChangeParentSelectStatus(tree, currentItemId, parentItem.lastChildId);
        if (parentItem.level === RevitTreeLevel.Category) {
          currentItemId = null;
        } else {
          currentItemId = parentItem.parentId;
          parentItem = tree[currentItemId];
        }
      }
    }
  }
  const helper = new MonoliteHelper(state);
  if (isolationDisabled) {
    helper.set(_ => _.modelTree, tree);
  } else {
    helper.set(_ => _.modelBrowserFiltered, tree);
  }
  return helper
    .set(_ => _.selectedElementTypesCount, elementTypes.length)
    .set(_ => _.selectedIds, selectedIds)
    .set(_ => _.selectedPath, selectedPath)
    .get();
}

function updateParentNodesSelectStatus(
  tree: RevitTreeListNode[],
  breadCrumbs: RevitTreePathInfo[],
  parentIds: number[],
): RevitTreeListNode[] {
  let minimumLevel = RevitTreeLevel.Category;
  if (breadCrumbs && breadCrumbs.length) {
    minimumLevel = breadCrumbs[breadCrumbs.length - 1].type + 1;
  }
  if (minimumLevel !== RevitTreeLevel.ElementType) {
    for (const familyId of new Set(parentIds)) {
      let parentItem = tree[familyId];
      let currentItemId = familyId;
      while (currentItemId !== null && parentItem && parentItem.level >= minimumLevel) {
        tree = checkAndChangeParentSelectStatus(tree, currentItemId, parentItem.lastChildId);
        if (parentItem.level === minimumLevel) {
          currentItemId = null;
        } else {
          currentItemId = parentItem.parentId;
          parentItem = tree[currentItemId];
        }
      }
    }
  }
  return tree;
}

function getMinimumLevel(filters: ClassificationModelBrowserFilters): RevitTreeLevel {
  const lastBreadCrumb = getLastBreadCrumb(filters);
  return lastBreadCrumb ? lastBreadCrumb.type + 1 : RevitTreeLevel.Category;
}

function comparePatterns(
  mainPattern: ClassificationElementTypeComparisonPattern,
  patternForCheck: ClassificationElementTypeComparisonPattern,
): boolean {
  if (mainPattern.layersCount !== patternForCheck.layersCount) {
    return false;
  }
  for (const [key, value] of Object.entries(patternForCheck.layersInfo)) {
    const currentLayersGroup = mainPattern.layersInfo[key];
    if (!currentLayersGroup || currentLayersGroup.length !== value.length) {
      return false;
    }
  }
  return true;
}

function groupSelectedElementTypes(
  state: ClassificationReduxState,
): ClassificationComparisonPatternGroup[] {
  const isolationDisabled = !isTreeFiltered(state.modelBrowserFilters);
  const tree = isolationDisabled ? state.modelTree : state.modelBrowserFiltered;
  const iterator = getSelectedElementTypes(tree);
  const patternToClassification = new Array<ClassificationComparisonPatternGroup>();
  type PatternToClassificationSetsTemp = [
    ClassificationElementTypeComparisonPattern,
    number,
    StringDictionary<number>
  ];
  const patternToClassificationSetsTemp = new Array<PatternToClassificationSetsTemp>();
  for (const elementTypeId of iterator) {
    const item = tree[elementTypeId];
    const layers = state.layerInfo[item.layersId] || [];
    const bimElements = state.bimElements.slice(item.engineIds[0], item.engineIds[1]);
    const layersInfo: StringDictionary<number[][]> = {};
    const layersToId: StringDictionary<number[]> = {};
    for (let i = 0; i < layers.length; i++) {
      const layerGroup = layers[i];
      const currentLayerKey = layerGroup.materials[0].name;
      layersInfo[currentLayerKey] = (layersInfo[currentLayerKey] || []);
      layersInfo[currentLayerKey].push([]);
      layersToId[currentLayerKey] = (layersToId[currentLayerKey] || []);
      layersToId[currentLayerKey].push(i);
    }
    const currentPattern: ClassificationElementTypeComparisonPattern = {
      layersCount: layers.length,
      bimElementIds: [],
      layersInfo,
    };
    const foundedGroup = patternToClassificationSetsTemp.find(([pattern]) => comparePatterns(pattern, currentPattern));
    if (!foundedGroup) {
      const classificationGroupsDictionary: StringDictionary<number> = {};
      const target: ClassificationTarget = {
        ids: bimElements.map(x => x.id),
        layers,
        elementTypesCount: 1,
        engineIds: item.engineIds,
      };
      for (const [layerName, idBatchs] of Object.entries(layersToId)) {
        for (let i = 0; i < idBatchs.length; i++) {
          classificationGroupsDictionary[`${layerName} ${i}`] = idBatchs[i];
        }
      }
      const patternId = patternToClassification.push({
        pattern: currentPattern,
        classificationTarget: target,
        elementTypes: [elementTypeId],
      }) - 1;
      patternToClassificationSetsTemp.push([currentPattern, patternId, classificationGroupsDictionary]);
    } else {
      const classificationGroupsDictionary = foundedGroup[2];
      const group = patternToClassification[foundedGroup[1]];
      const target = group.classificationTarget;
      const needUnshiftCurrentElementType = !item.isError && tree[group.elementTypes[0]].isError;
      if (needUnshiftCurrentElementType) {
        const ids = bimElements.map(x => x.id);
        arrayUtils.extendArray(ids, target.ids);
        target.ids = ids;
        group.elementTypes.unshift(elementTypeId);
        target.elementTypesCount++;
      } else {
        arrayUtils.extendArray(target.ids, bimElements.map(x => x.id));
        target.elementTypesCount++;
        group.elementTypes.push(elementTypeId);
      }
      for (const [layerName, idBatchs] of Object.entries(currentPattern.layersInfo)) {
        for (let i = 0; i < idBatchs.length; i++) {
          const ids = layers[layersToId[layerName][i]].ids;
          const layerGroupIndex = classificationGroupsDictionary[`${layerName} ${i}`];
          if (needUnshiftCurrentElementType) {
            arrayUtils.extendArray(ids, target.layers[layerGroupIndex].ids);
            target.layers[layerGroupIndex].ids = ids;
          } else {
            arrayUtils.extendArray(target.layers[layerGroupIndex].ids, ids);
          }
        }
      }
    }
  }
  return patternToClassification;
}


function getLastBreadCrumb(filters: ClassificationModelBrowserFilters): RevitTreePathInfo | null {
  const count = filters.breadCrumbs && filters.breadCrumbs.length;
  return count ? filters.breadCrumbs[count - 1] : null;
}

function createLayerIdsSets({ layers }: ClassificationTarget): Array<Set<number>> {
  return layers && layers.length ? layers.map(x => new Set(x.ids)) : [];
}


function updateErrorStatuses(
  state: ClassificationReduxState,
  elementTypesIds: ClassificationElementTypeInfo[],
  isUndefined: boolean,
  operation: ClassificationMultiselectOperation,
  isAugmentation?: boolean,
): MonoliteHelper<ClassificationReduxState> {
  const helper = new MonoliteHelper(state);

  let filtered: boolean;
  let minimumLevel: number;
  if (operation === ClassificationMultiselectOperation.Edit) {
    filtered = isTreeFiltered(state.cachedIsolationStatus);
    minimumLevel = getMinimumLevel(state.cachedIsolationStatus);
  } else {
    minimumLevel = getMinimumLevel(state.modelBrowserFilters);
    filtered = isTreeFiltered(state.modelBrowserFilters);
  }

  let errorIds = state.errorIds.slice();
  let errorCategoriesCount = state.errorCategoriesCount;
  let needUpdateError = true;
  let needUpdateAugmentation = true;

  for (const { sourceId, filteredId } of elementTypesIds) {
    if (isAugmentation === state.modelTree[sourceId].isAugmentation) {
      needUpdateAugmentation = false;
    }
    if (isUndefined === state.modelTree[sourceId].isError) {
      needUpdateError = false;
    }
    if (!needUpdateError && !needUpdateAugmentation) {
      continue;
    }

    let filteredFamilyId: number = null;
    helper
      .set(_ => _.modelTree[sourceId].isAugmentation, isAugmentation);
    if (filtered) {
      filteredFamilyId = state.modelBrowserFiltered[filteredId].parentId;
      helper.set(_ => _.modelBrowserFiltered[filteredId].isError, isUndefined);
    }
    const item = state.modelTree[sourceId];
    const [start, end] = item.engineIds;

    const bimElements = state.bimElements.slice(start, end);
    if (item.isError !== isUndefined) {
      errorCategoriesCount += isUndefined ? 1 : -1;
      const engineIds = bimElements.map(x => x.id);
      if (isUndefined) {
        arrayUtils.extendArray(errorIds, engineIds);
      } else {
        errorIds = errorIds.filter(x => !engineIds.includes(x));
      }

      helper.set(_ => _.modelTree[sourceId].isError, isUndefined);
    }

    helper.set(_ => _.errorIds, errorIds);
    let updatedState = helper.get();

    const needChangeFilteredFamily = minimumLevel <= RevitTreeLevel.Family;

    const { category, family } = bimElements[0];

    let familyIsUndefined = false;
    let familyIsAugmentation = false;
    let filteredCategoryId: number = null;
    const familyNode = updatedState.modelTree[family];
    const familyIsUndefinedSourceValue = familyNode.isError;
    const familyIsAugmentationSourceValue = familyNode.isAugmentation;
    for (let i = family + 1; i <= familyNode.lastChildId; i++) {
      if (updatedState.modelTree[i].isError) {
        familyIsUndefined = true;
      }
      if (updatedState.modelTree[i].isAugmentation) {
        familyIsAugmentation = true;
      }
      if (familyIsUndefined && familyIsAugmentation) {
        break;
      }
    }
    if (familyIsUndefined !== familyIsUndefinedSourceValue) {
      helper.set(_ => _.modelTree[family].isError, isUndefined);
      if (filtered && needChangeFilteredFamily) {
        helper.set(_ => _.modelBrowserFiltered[filteredFamilyId].isError, isUndefined);
      }
    }
    if (familyIsAugmentation !== familyIsAugmentationSourceValue) {
      helper.set(_ => _.modelTree[family].isAugmentation, isAugmentation);
      if (filtered && needChangeFilteredFamily) {
        helper.set(_ => _.modelBrowserFiltered[filteredFamilyId].isAugmentation, isAugmentation);
      }
    }

    let filteredFamilyIsUndefined = false;
    let filteredFamilyIsUndefinedSourceValue = false;
    let filteredFamilyIsAugmentation = false;
    let filteredFamilyIsAugmentationSourceValue = false;
    if (filtered &&  needChangeFilteredFamily) {
      const filteredFamily = updatedState.modelBrowserFiltered[filteredFamilyId];
      filteredFamilyIsUndefinedSourceValue = filteredFamily.isError;
      filteredFamilyIsAugmentationSourceValue = filteredFamily.isAugmentation;
      filteredCategoryId = filteredFamily.parentId;
      for (let i = filteredFamilyId + 1; i <= filteredFamily.lastChildId; i++) {
        if (updatedState.modelBrowserFiltered[i].isError) {
          filteredFamilyIsUndefined = true;
        }
        if (updatedState.modelBrowserFiltered[i].isAugmentation) {
          filteredFamilyIsAugmentation = true;
        }
        if (filteredFamilyIsUndefined && filteredFamilyIsAugmentation) {
          break;
        }
      }
      if (filteredFamilyIsUndefined !== filteredFamilyIsUndefinedSourceValue) {
        helper.set(_ => _.modelBrowserFiltered[filteredFamilyId].isError, filteredFamilyIsUndefined);
      }
      if (filteredFamilyIsAugmentation !== filteredFamilyIsAugmentationSourceValue) {
        helper.set(_ => _.modelBrowserFiltered[filteredFamilyId].isAugmentation, filteredFamilyIsAugmentation);
      }
    }

    if (familyIsUndefinedSourceValue === isUndefined && familyIsAugmentationSourceValue === isAugmentation) {
      continue;
    }

    const needChangeFilteredCategory = minimumLevel === RevitTreeLevel.Category;

    updatedState = helper.get();

    const categoryNode = updatedState.modelTree[category];
    let categoryIsUndefined = false;
    let categoryIsAugmentation = false;
    const categoryIsUndefinedSourceValue = categoryNode.isError;
    const categoryIsAugmentationSourceValue = categoryNode.isAugmentation;
    for (let i = category + 1; i <= categoryNode.lastChildId; i++) {
      if (updatedState.modelTree[i].isError) {
        categoryIsUndefined = true;
      }
      if (updatedState.modelTree[i].isAugmentation) {
        categoryIsAugmentation = true;
      }
      if (categoryIsUndefined && categoryIsAugmentation) {
        break;
      }
      i = updatedState.modelTree[i].lastChildId;
    }
    if (categoryIsUndefined !== categoryIsUndefinedSourceValue) {
      helper.set(_ => _.modelTree[category].isError, isUndefined);
      if (filtered && needChangeFilteredCategory) {
        filteredCategoryId =
          filteredCategoryId || updatedState.modelBrowserFiltered[filteredFamilyId].parentId;
        helper.set(_ => _.modelBrowserFiltered[filteredCategoryId].isError, isUndefined);
      }
    }
    if (categoryIsAugmentation !== categoryIsAugmentationSourceValue) {
      helper.set(_ => _.modelTree[category].isAugmentation, isAugmentation);
      if (filtered && needChangeFilteredCategory) {
        filteredCategoryId =
          filteredCategoryId || updatedState.modelBrowserFiltered[filteredFamilyId].parentId;
        helper.set(_ => _.modelBrowserFiltered[filteredCategoryId].isAugmentation, isAugmentation);
      }
    }
    if (filtered && needChangeFilteredCategory) {
      let filteredCategoryIsUndefined = false;
      let filteredCategoryIsAugmentation = false;
      const filteredCategory = updatedState.modelBrowserFiltered[filteredCategoryId];
      for (let i = filteredCategoryId + 1; i <= filteredCategory.lastChildId; i++) {
        const currentItem = updatedState.modelBrowserFiltered[i];
        if (currentItem.isError) {
          filteredCategoryIsUndefined = true;
        }
        if (currentItem.isAugmentation) {
          filteredCategoryIsAugmentation = true;
        }
        if (filteredCategoryIsUndefined && filteredCategoryIsAugmentation) {
          break;
        }
        i = currentItem.lastChildId;
      }
      if (filteredCategoryIsUndefined !== filteredCategory.isError) {
        helper.set(_ => _.modelBrowserFiltered[filteredCategoryId].isError, isUndefined);
      }
      if (filteredCategoryIsAugmentation !== filteredCategory.isAugmentation) {
        helper.set(_ => _.modelBrowserFiltered[filteredCategoryId].isAugmentation, isAugmentation);
      }
    }
  }
  if (!errorCategoriesCount && state.modelBrowserFilters.unclassifiedFilterEnabled) {
    helper.set(_ => _.modelBrowserFilters.unclassifiedFilterEnabled, false);
  }
  return helper.set(_ => _.errorCategoriesCount, errorCategoriesCount);
}


function isolateModelBrowserNode(tree: RevitTreeListNode[], start: number, end: number): RevitTreeListNode[] {
  const isolatedTree = tree.slice(start, end);
  if (!isolatedTree.length) {
    return [];
  }
  const updatedTreeHelper = new MonoliteHelper(isolatedTree).set(_ => _[0].firstEntityOfLevel, true);
  for (let i = 0; i < isolatedTree.length; i++) {
    const node = isolatedTree[i];
    if (node.lastChildId) {
      const newLastChild = node.lastChildId - start - node.level + 1;
      updatedTreeHelper.set(_ => _[i].lastChildId, newLastChild);
      const isLastEntityOfLevel = !isolatedTree[newLastChild + 1];
      if (isLastEntityOfLevel !== node.lastEntityOfLevel) {
        updatedTreeHelper.set(_ => _[i].lastEntityOfLevel, isLastEntityOfLevel);
      }

      for (let j = i + 1; j < newLastChild + 1; j++) {
        updatedTreeHelper
          .set(_ => _[j].isParentLastEntityOfLevel, isLastEntityOfLevel)
          .set(_ => _[j].parentId, i);
      }
      i = newLastChild;
    } else {
      updatedTreeHelper.set(_ => _[i].isParentLastEntityOfLevel, true);
    }
  }
  return updatedTreeHelper.get();
}


function classificationTargetIsMultilayer({ layers }: ClassificationTarget): boolean {
  return !!layers && !!layers.length;
}

function createClassificationTarget(
  node: RevitTreeListNode,
  { layerInfo, bimElements }: ClassificationReduxState,
  elementTypesCount: number,
): ClassificationTarget {
  const [start, end] = node.engineIds;
  return {
    engineIds: node.engineIds,
    ids: bimElements.slice(start, end).map(x => x.id),
    layers: Number.isInteger(node.layersId) ? layerInfo[node.layersId] : null,
    elementTypesCount,
  };
}

function mapValueVariantTree(
  items: ClassificationOntologyVariantTree[],
): StringDictionary<ClassificationOntologyVariantTree> {
  let result = {};
  for (const item of items) {
    const doesItemHaveChildren = item.children && item.children.length;
    if (doesItemHaveChildren) {
      result = merge(result, mapValueVariantTree(item.children));
    }
    if (item.isSelectable || !doesItemHaveChildren) {
      result[item.value] = item;
    }
  }
  return result;
}

function prepareShortUniClassInfo(uniclass: ActivityCategory[]): NumberDictionary<UniclassEntityShortInfo> {
  let preparedShortUniClassInfo: NumberDictionary<UniclassEntityShortInfo> = {};
  for (const { code, title, id, children } of uniclass) {
    preparedShortUniClassInfo[id] = { code, title };
    preparedShortUniClassInfo = merge(preparedShortUniClassInfo, prepareShortUniClassInfo(children));
  }
  return preparedShortUniClassInfo;
}

export const ClassificationUtils = {
  getModelBrowserNodes,
  processRevitTree,
  filterTreeNodes,
  prepareGraph,
  createCurrentElementClassification,
  extractNeededInfoForAssemblyOrLayer,
  createBreadCrumbs,
  uniClassToItemClassification,
  splitUniclass,
  calculateErrorElementTypes,
  getLayerFunctionType,
  isTreeFiltered,
  createCustomUniclass,
  enableSaveButton,
  searchFilterTree,
  changeTreeSelectStatus,
  checkAndChangeParentSelectStatus,
  changeSystemOrProductFilter,
  changeFilter,
  getSelectedElementTypes,
  selectElementTypes,
  updateParentNodesSelectStatus,
  getMinimumLevel,
  comparePatterns,
  groupSelectedElementTypes,
  getLastBreadCrumb,
  createLayerIdsSets,
  updateErrorStatuses,
  isolateModelBrowserNode,
  classificationTargetIsMultilayer,
  createClassificationTarget,
  mapValueVariantTree,
  prepareShortUniClassInfo,
};
