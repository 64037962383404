import { ExcelFormulaHelper } from 'common/components/excel-table';
import { flatten } from './flatten';

export function MIN(...numbers: number[] | number[][]): number | string {
  if (numbers.length === 0) {
    return ExcelFormulaHelper.INVALID_VALUE;
  }

  const arr = flatten(numbers);

  let min = Number.POSITIVE_INFINITY;

  arr.forEach((arg) => {
    if (Array.isArray(arg)) {
      arg.forEach((innerArg) => {
        if (typeof innerArg !== 'number') {
          return;
        }

        if (innerArg < min) {
          min = innerArg;
        }
      });
    } else {
      if (typeof arg !== 'number') {
        return;
      }

      if (arg < min) {
        min = arg;
      }
    }
  });

  if (min === Number.POSITIVE_INFINITY) {
    return 0;
  }

  return min;
}
