import { MonoliteHelper } from 'common/monolite';

import { ReducerMethods } from '../../../common/interfaces/reducer-methods';
import * as actionTypes from './action-types';
import { DialogState } from './dialog-state';

export const kreoDialogReducerMethods: ReducerMethods<DialogState> = {
  [actionTypes.OPEN_DIALOG]: (state, payload: { name: string, data: any }) => {
    const stateUpdater = new MonoliteHelper(state);
    return stateUpdater
      .set((_) => _, { ...state, [payload.name]: payload.data })
      .get();
  },
  [actionTypes.CLOSE_DIALOG]: (state, payload: string) => {
    const stateUpdater = new MonoliteHelper(state);
    return stateUpdater
      .removeKey((_) => _, payload)
      .get();
  },
  [actionTypes.UPDATE_DIALOG_DATA]: (state, payload: { name: string, data: any }) => {
    const { name, data } = payload;
    const stateUpdater = new MonoliteHelper(state);
    const hasData = !!state[name];
    if (hasData) {
      const newData = { ...state[name], ...data };

      stateUpdater
        .set((_) => _[name], newData);
    }

    return stateUpdater
      .get();
  },
};
