import { AnyAction } from 'redux';

import { AdminState } from '../units/admin/interfaces/redux-state';
import { adminCompaniesReducerMethods } from '../units/admin/reducers/companies';
import { adminDemoProjectsReducerMethods } from '../units/admin/reducers/demo-projects';
import { adminPeopleReducerMethods } from '../units/admin/reducers/people';
import adminCompanies, { INITIAL_STATE as INITIAL_COMPANIES_STATE } from './admin-companies';
import adminDemoProjects, { INITIAL_STATE as INITIAL_DEMO_PROJECTS_STATE } from './admin-demo-projects';
import adminPeople, { INITIAL_STATE as INITIAL_PEOPLE_STATE } from './admin-people';

const INITIAL_STATE: AdminState = {
  people: INITIAL_PEOPLE_STATE,
  companies: INITIAL_COMPANIES_STATE,
  demoProjects: INITIAL_DEMO_PROJECTS_STATE,
};

export default function admin(
  state: AdminState = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): AdminState {
  if (action.type in adminPeopleReducerMethods) {
    return { ...state, people: adminPeople(state.people, action) };
  } else if (action.type in adminCompaniesReducerMethods) {
    return { ...state, companies: adminCompanies(state.companies, action) };
  } else if (action.type in adminDemoProjectsReducerMethods) {
    return { ...state, demoProjects: adminDemoProjects(state.demoProjects, action) };
  } else {
    return state;
  }
}
