import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AbilityRoute } from 'common/ability/ability-route';
import { Subject } from 'common/ability/subject';
import { SubscriptionType } from 'common/constants/subscription';
import { RoutingContextProvider } from 'common/routing/routing-context';
import { DatabasePage } from '../../units/databases/pages/databases';
import { NotFoundPage } from '../../units/notfound/page';
import { ProjectsPage } from '../../units/projects/pages/projects/page';
import * as Activity from '../common/activity';
import * as Database from '../common/database';
import * as People from '../common/people';
import * as Subscription from '../common/subscription-routes-wrap';
import { CostEstimateProjectRouteParams } from './route-params';
import { ProjectRoutes } from './routes/project';
import { Urls } from './urls';

interface RoutesProps extends RouteComponentProps<CostEstimateProjectRouteParams> {
}

const databaseRoutes = (props: RouteComponentProps<any>): JSX.Element => {
  return (<Database.Routes {...props} urls={Urls.database}/>);
};

export class Routes extends React.Component<RoutesProps> {
  public render(): React.ReactNode {
    return (
      <RoutingContextProvider urls={Urls}>
        <Subscription.SubscriptionWrap
          urls={Urls}
          subscriptionType={SubscriptionType.CostEstimate}
        >
          <Switch>
            <Redirect exact={true} from={Urls.index.path} to={Urls.listing.path} />
            <Route exact={true} path={Urls.listing.path} component={ProjectsPage} />
            <Route path={Urls.project.index.path} component={ProjectRoutes}/>
            <AbilityRoute
              subject={Subject.Database}
              path={Urls.database.listing.path}
              component={DatabasePage}
            />
            <AbilityRoute
              subject={Subject.Database}
              path={Urls.database.index.path}
              component={databaseRoutes}
            />
            <Route path={Urls.people.index.path}>
              <People.Routes
                {...this.props}
                urls={Urls.people}
              />
            </Route>
            <Route path={Urls.activity.index.path}>
              <Activity.Routes
                {...this.props}
                urls={Urls.activity}
              />
            </Route>
            <Route component={NotFoundPage} />
          </Switch>
        </Subscription.SubscriptionWrap>
      </RoutingContextProvider>
    );
  }
}
