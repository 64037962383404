import { IconButtonStyle } from '@kreo/kreo-ui-components/components/buttons/icon';
import styled from 'styled-components';


const ControlPanelContainer = styled.div<{ withDataBase: boolean }>`
  position: sticky;
  top: -1px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.color.background};
  background-color: ${(p) => p.theme.color.backgroundRush};
  padding: 10px;
  justify-content: ${p => p.withDataBase ? 'space-between' : 'flex-end'};
`;

const IconButtonsContainer = styled.div`
  width: 40px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  

  ${IconButtonStyle} {
    height: 10px;
    width: 10px;

    span {
      height: 10px;
      svg {
        height: 10px;
      }
    }
  }
`;

export const Styled = {
  ControlPanelContainer,
  IconButtonsContainer,
};
