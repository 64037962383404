import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { KreoDialogComponent } from 'common/UIKit';
import { SelectOverlay } from './select-overlay';

interface Props {
  open: boolean;
  contentContainerClassName?: string;
  onCollapseExpand: () => void;
}

export class SelectBody extends React.Component<Props> {
  private bodyRef: HTMLDivElement = null;

  constructor(props: Props) {
    super(props);
    this.bodyRef = document.createElement('div');
    this.bodyRef.className = 'kreo-dialog';
  }

  public componentDidMount(): void {
    const root = KreoDialogComponent.getRoot();
    if (!!root && this.props.open) {
      root.appendChild(this.bodyRef);
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.open !== prevProps.open) {
      const root = KreoDialogComponent.root;
      if (this.props.open) {
        root.appendChild(this.bodyRef);
      } else {
        root.removeChild(this.bodyRef);
      }
    }
  }

  public render(): React.ReactNode {
    return ReactDOM.createPortal(
      <SelectOverlay
        className={this.props.contentContainerClassName}
        onClick={this.onClose}
      >
        {this.props.children}
      </SelectOverlay>,
      this.bodyRef);
  }

  @autobind
  private onClose(): void {
    this.props.onCollapseExpand();
  }
}
