import { CompanyResourcesApi, RevisionResourcesApi } from '../../../api/server';
import { QtoRecord, RecordsUpdateType } from '../components/quantity-take-off-left-panel/interfaces';
import { SaveState } from '../components/quantity-take-off-left-table-tab/use-save-state';
import { GraphStorageRecordsConfig } from '../interfaces/graph-storage-records-config';
import { QtoGlobalConfig } from '../interfaces/quantity-take-off/quantity-take-off-global-config';
import { QuantityTakeOffModel, QuantityTakeOffModelC } from '../interfaces/quantity-take-off/quantity-take-off-model';
import { ModelType } from '../interfaces/quantity-take-off/quantity-take-off-model-type';
import { QtoRecords } from '../interfaces/quantity-take-off/quantity-take-off-records';

function getModel(projectId: number, modelType: ModelType, _revisionId?: number): Promise<QuantityTakeOffModel> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.getV(`/${modelType}`, QuantityTakeOffModelC);
  }
  return CompanyResourcesApi.getV(`/projects/${projectId}/${modelType}`, QuantityTakeOffModelC);
}

function downloadGeneralReportFile(projectId: number, modelType: ModelType): void {
  if (modelType === ModelType.CostEstimate) {
    RevisionResourcesApi.downloadFile(`/${modelType}/general-report`);
  }
  CompanyResourcesApi.downloadFile(`/projects/${projectId}/${modelType}/general-report`);
}

function buildApproximateCostReport(projectId: number, modelType: ModelType): Promise<void> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.post(`/${modelType}/approximate-cost-report`);
  }
  return CompanyResourcesApi.post(`/projects/${projectId}/${modelType}/approximate-cost-report`);
}

function downloadApproximateCostReportFile(projectId: number, modelType: ModelType): void {
  if (modelType === ModelType.CostEstimate) {
    RevisionResourcesApi.downloadFile(`/${modelType}/approximate-cost-report`);
  }
  CompanyResourcesApi.downloadFile(`/projects/${projectId}/${modelType}/approximate-cost-report`);
}

function downloadRawDataFile(projectId: number, modelType: ModelType): void {
  if (modelType === ModelType.CostEstimate) {
    RevisionResourcesApi.downloadFile(`/${modelType}/raw-data`);
  }
  CompanyResourcesApi.downloadFile(`/projects/${projectId}/${modelType}/raw-data`);
}

function getLeftPanelRecords(projectId: number, modelType: ModelType): Promise<QtoRecords> {
  if (modelType === ModelType.CostEstimate) {
    return RevisionResourcesApi.get(`/${modelType}/records`);
  }
  return CompanyResourcesApi.get(`/projects/${projectId}/${modelType}/records`);
}

function getRecordsConfig(projectId: number): Promise<GraphStorageRecordsConfig> {
  return CompanyResourcesApi.get(`/projects/${projectId}/graphstorage/records/config`);
}

function updateRecordConfig(projectId: number, modelType: ModelType, config: any, tableName: string): void {
  if (modelType === ModelType.QuantityTakeOff) {
    CompanyResourcesApi.put(`/projects/${projectId}/${modelType}/records/config?type=${tableName}`, config);
  }
}

function getRecordsState(projectId: number, modelType: ModelType, tableName: string): Promise<SaveState> {
  if (modelType === ModelType.QuantityTakeOff) {
    return CompanyResourcesApi.get<SaveState>(`/projects/${projectId}/${modelType}/records/config?type=${tableName}`);
  }
  return null;
}

function updateRecords(
  projectId: number, modelType: ModelType, recordsPatch: QtoRecord[], updateType: RecordsUpdateType,
): Promise<void> {
  if (modelType === ModelType.QuantityTakeOff) {
    return CompanyResourcesApi.put(`/projects/${projectId}/${modelType}/records`, { recordsPatch, updateType });
  }
}

function getQtoGlobalConfig(projectId: number): Promise<QtoGlobalConfig> {
  return CompanyResourcesApi.get(`/projects/${projectId}/classification/qto-global-config`);
}

export const QuantityTakeOffApi = {
  getModel,
  downloadGeneralReportFile,
  buildApproximateCostReport,
  downloadApproximateCostReportFile,
  downloadRawDataFile,
  getLeftPanelRecords,
  getRecordsConfig,
  updateRecordConfig,
  getRecordsState,
  updateRecords,
  getQtoGlobalConfig,
};
