import * as React from 'react';
import { match } from 'react-router';

import './index.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { isProjectStatusGreaterThan, ProjectStatus } from 'common/enums/project-status';
import {
  PlanProjectRouteParams,
  Qto2dProjectRouteParams,
  Qto3dProjectRouteParams,
} from '../../../../routes/app-routes-params';
import { ProjectLayout } from '../../../project-dashbord';
import { Project } from '../../../projects/interfaces/project';

interface Props extends AbilityAwareProps {
  match: match<PlanProjectRouteParams | Qto3dProjectRouteParams | Qto2dProjectRouteParams>;
  project: Project;
}

const PageContainerComponent: React.FC<Props> = props => {
  const projectId = props.match.params && props.match.params.projectId;
  const currentProjectId = props.project && props.project.id;
  const isWaiting =
    !projectId ||
    parseInt(projectId, 10) !== currentProjectId ||
    !isProjectStatusGreaterThan(props.project.status, ProjectStatus.HasEngineMeshes, true);

  if (
    !props.ability.can(Operation.Read, Subject.PlanFeatures) &&
    !props.ability.can(Operation.Read, Subject.QuantityTakeOff3d)
  ) {
    return (
      <div className='viewer-page__container'>
        {!isWaiting ? props.children : <SvgSpinner size='large' />}
      </div>
    );
  } else {
    return (
      <ProjectLayout projectId={projectId} waitingForAdditionalInfo={isWaiting}>
        {props.children}
      </ProjectLayout>
    );
  }
};

export const PageContainer = withAbilityContext(PageContainerComponent);
