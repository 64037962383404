import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


export interface Props {
  image: string;
  error: boolean;
}

export const PreviewProject: React.FC<Props> = React.memo(({ image, error }: Props) => {
  return (
    <Styled.Container
      image={image}
      error={error}
    >
      {error && <Icons.Error />}
    </Styled.Container>
  );
});
