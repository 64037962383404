import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './people-categories-menu.scss';

import { RoleGroup } from 'common/enums/role-group';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { StringDictionary } from 'common/interfaces/dictionary';
import { State } from 'common/interfaces/state';
import { PeopleActions } from '../../actions/actions';
import { Person } from '../../interfaces/person';

interface StateProps {
  companyRoles: CompanyRoles;
  people: Person[];
  displayedRoleGroup: RoleGroup | null;
}

interface DispatchProps {
  changeDisplayedRoleGroup: (roleGroup: RoleGroup | null) => void;
}

interface MenuProps extends StateProps, DispatchProps { }

interface MenuItem {
  roleGroup: RoleGroup | null;
  label: string;
}

const items: MenuItem[] = [
  { roleGroup: null, label: 'All' },
  { roleGroup: RoleGroup.Employee, label: 'Employees' },
  { roleGroup: RoleGroup.Guest, label: 'Guests' },
  { roleGroup: RoleGroup.Subcontractor, label: 'Subcontractors' },
];

class PeopleCategoriesMenuComponent extends React.Component<MenuProps> {
  private handlers: StringDictionary<() => void> = {};

  public render(): React.ReactNode {
    const { displayedRoleGroup, companyRoles, people } = this.props;
    if (!people || !companyRoles || !companyRoles.roles || !companyRoles.roles.length) {
      return null;
    }

    return (
      <React.Fragment>
        {
          items.map((i) => {
            const className = classNames(
              'people-categories-menu__item',
              {
                'people-categories-menu__item--active': i.roleGroup === displayedRoleGroup,
              },
            );

            return (
              <div
                className={className}
                key={i.label}
                onClick={this.getHandler(i)}
              >
                {i.label}
              </div>
            );
          })
        }
      </React.Fragment>
    );
  }

  @autobind
  private getHandler(item: MenuItem): () => void {
    if (!this.handlers[item.label]) {
      this.handlers[item.label] = () => this.props.changeDisplayedRoleGroup(item.roleGroup);
    }

    return this.handlers[item.label];
  }
}

const mapStateToProps = (state: State): StateProps => {
  const companyRoles = state.account.subscriptionRoles;

  return {
    companyRoles,
    displayedRoleGroup: state.people.displayedRoleGroup,
    people: state.people.companiesUsers || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    changeDisplayedRoleGroup: (roleGroup) => dispatch(PeopleActions.changeDisplayedRoleGroup(roleGroup)),
  };
};

export const PeopleCategoriesMenu = connect(mapStateToProps, mapDispatchToProps)(PeopleCategoriesMenuComponent);
