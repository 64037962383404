import styled from 'styled-components';

import { KreoColors } from 'common/enums/kreo-colors';
import { Mood } from 'common/enums/mood';

const Status = styled.div<{ isActive: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: ${p => p.isActive ? p.theme.color.green : p.theme.color.red};
  animation: ${p => p.isActive ? 'pulse-animation-active' : 'pulse-animation-lost'} 2s infinite;

  @keyframes pulse-animation-active {
    0% {
      box-shadow: 0 0 0 0px ${KreoColors.green}20;
    }
    100% {
      box-shadow: 0 0 0 4px ${KreoColors.green}50;
    }
  }

  @keyframes pulse-animation-lost {
    0% {
      box-shadow: 0 0 0 0px ${KreoColors.red}20;
    }
    100% {
      box-shadow: 0 0 0 4px ${KreoColors.red}50;
    }
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${p => p.theme.color.backgroundRush};
`;

const ButtonWrapper = styled.div<{ mood: Mood }>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: ${p => p.theme.color.backgroundRush};

  > div {
    > button {
      > span {
        svg {
          fill: ${p => p.mood === Mood.Default ? p.theme.color.gray
    : p.mood === Mood.Positive ? p.theme.color.green
      : p.mood === Mood.Negative ? p.theme.color.red
        : p.theme.color.disabled
          } !important;
        }
      }
    }
  }
  > button {
    > span {
      svg {
        fill: ${p => p.mood === Mood.Default ? p.theme.color.gray
    : p.mood === Mood.Positive ? p.theme.color.green
      : p.mood === Mood.Negative ? p.theme.color.red
        : p.theme.color.disabled
        } !important;
      }
    }
  }
`;

export const Styled = {
  Status,
  StatusContainer,
  ButtonWrapper,
};
