import {
  CustomElementFilterItemType,
  FilterNode,
  InnerCustomElementFilterNumber,
  InnerCustomElementFilterReference,
  InnerCustomElementFilterString,
} from '../../components/custom-element-filter-builder/inner-interfaces';


const isStringBaseFilter = (filter: CustomElementFilterItemType): filter is InnerCustomElementFilterString => {
  return (filter as InnerCustomElementFilterString).isCaseSensitive !== undefined;
};

const isFilterNode = (filter: CustomElementFilterItemType): filter is FilterNode => {
  return (filter as FilterNode).innerNodes !== undefined;
};

const isNumberBaseFilter = (filter: CustomElementFilterItemType): filter is InnerCustomElementFilterNumber => {
  return !isStringBaseFilter(filter)
    && !isFilterNode(filter)
    && (filter as InnerCustomElementFilterNumber).operation !== undefined;
};

const isReferenceBaseFilter = (filter: CustomElementFilterItemType): filter is InnerCustomElementFilterReference => {
  return (filter as InnerCustomElementFilterReference).elementFilterId !== undefined;
};

export const CustomElementFilterBuilderTypeGuards = {
  isStringBaseFilter,
  isNumberBaseFilter,
  isReferenceBaseFilter,
  isFilterNode,
};
