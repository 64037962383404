import * as Ag from 'ag-grid-community';
import { sortComparator } from '2d/components/2d-element-view/constants';
import { AssignedPiaProperty } from '2d/interfaces';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { FormatTypeGuards } from 'unit-2d-database/helpers/format-typeguards';
import { PropertyFormatEnum } from 'unit-2d-database/interfaces';
import { ColumnType, PropertyColumn } from '../interfaces';

const mapPropertyToColumn = (prop: AssignedPiaProperty, columnType: ColumnType): Ag.ColDef => {
  return {
    colId: `[${prop.name}]`,
    field: prop.name,
    headerName: prop.name,
    comparator: columnType === ColumnType.String ? sortComparator : undefined,
  };
};

const getColumnType = (prop: AssignedPiaProperty): ColumnType => {
  return prop.value && prop.value.format.type === PropertyFormatEnum.Text
    ? ColumnType.String
    : ColumnType.Number;
};

const getPropertyColumnInfo = (property: AssignedPiaProperty): PropertyColumn => {
  const colType = getColumnType(property);
  const colDef = mapPropertyToColumn(property, colType);
  if (colType === ColumnType.Number) {
    if (property.value && FormatTypeGuards.isNumeric(property.value.format)) {
      const unit = property.value.format.unit as UnitTypes;
      return {
        colDef,
        colType,
        unitCategory: UnitUtil.getUnitCategory(unit),
        unitType: unit,
      };
    }
  }
  return {
    colDef,
    colType,
    unitCategory: null,
    unitType: null,
  };
};

const mergePropertyColumn = (target: PropertyColumn, update: PropertyColumn): PropertyColumn => {
  if (target.colType !== update.colType || update.colType === ColumnType.String) {
    return {
      ...update,
      colType: ColumnType.String,
      unitCategory: null,
      unitType: null,
    };
  }

  if (target.unitCategory !== update.unitCategory || update.unitCategory === null) {
    return {
      ...update,
      unitCategory: null,
      unitType: null,
    };
  }

  if (target.unitType !== update.unitType) {
    return {
      ...update,
      unitType: null,
    };
  }
  return update;
};

export const getPropertyColumn = (extraCol: PropertyColumn, property: AssignedPiaProperty): PropertyColumn => {
  const propertyColInfo = getPropertyColumnInfo(property);
  if (extraCol) {
    return mergePropertyColumn(extraCol, propertyColInfo);
  } else {
    return propertyColInfo;
  }
};
