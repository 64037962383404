import styled from 'styled-components';

import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';

const CreateButton = styled.div`
  position: relative;
  z-index: 10;
  background-color: ${p => p.theme.color.backgroundRush};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  width: 40px;
  overflow: hidden;
`;

const CreateTabWrapper = styled.div`
  ${UpgradeWrapperStyled.StarsContainer} {
    right: 5px;
  }
`;

export const Styled = {
  CreateButton,
  CreateTabWrapper,
};
