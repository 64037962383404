import * as Ag from 'ag-grid-community';

const columnMainMenu = {
  autoSizeThis: 'autoSizeThis',
  pinSubMenu: 'pinSubMenu',
  separator: 'separator',
};

const columnMenuTab = {
  generalMenuTab: 'generalMenuTab',
};

const constants =  {
  ROOT_NODE_ID: 'ROOT_NODE_ID',
  columnMainMenu,
  columnMenuTab,
  menuListSmallClassName: 'ag-menu-list__small',
  menuListMiddleClassName: 'ag-menu-list__middle',
  autoColumnId: 'ag-Grid-AutoColumn',
};

const getLevelSelectedIds = (api: Ag.GridApi, node: Ag.RowNode): string[] => {
  const parentRow = node.parent;
  const selectedNodes = api.getSelectedNodes();
  const selectedNodeMap = new Map<string, Ag.RowNode>();
  selectedNodes.forEach(x => selectedNodeMap.set(x.id, x));
  return parentRow.data.children.filter(id => selectedNodeMap.has(id));
};

export const AgGridHelper = {
  getAllDisplayedColumnGroups: (api: Ag.GridApi) =>
    (api as any).columnController.getAllDisplayedColumnGroups() as Ag.ColumnGroupChild[],
  getColumnDefs: (api: Ag.GridApi) => (api as any).columnController.columnDefs as Array<Ag.ColDef | Ag.ColGroupDef>,
  getRootNode: (api: Ag.GridApi) => (api as any).getModel().getRootNode() as Ag.RowNode,
  getColumnController: (api: Ag.GridApi) => (api as any).columnController as Ag.ColumnController,
  isRootNode: (node: Ag.RowNode) => node.id === constants.ROOT_NODE_ID,
  getLevelSelectedIds,
  constants,
};

