import { H4, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ConstantFunctions } from 'common/constants/functions';
import { DemoProjectType } from 'common/constants/project-type';
import { ColorsName } from 'common/enums/kreo-colors';
import { ProjectCreateStatus } from 'common/enums/project-create-status';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AdminDemoProjectsActions } from '../../actions/creators/demo-projects';
import { DemoProject } from '../../pages/demo-projects/interfaces/demo-project';
import { UPDATE_DEMO_PROJECT_DIALOG_NAME, UpdateDemoProjectDialog } from '../demo-projects/update-demo-project-dialog';
import { DemoProjectsCard } from './project-card';
import { Styled } from './styled';


interface StateProps {
  demoProjectHeaders: DemoProject[];
  demoProjectHeadersRequestStatus: RequestStatus;
}

interface DispatchProps {
  fetchDemoProjectHeaders: (type: DemoProjectType) => void;
  updateDemoProject: () => void;
  openEditDialog: (id: number) => void;
  removeDemoProject: (projectId: number, type: DemoProjectType) => void;
  dropStore: () => void;
}

interface OwnProps {
  projectType: DemoProjectType;
  title: string;
}

interface Props extends OwnProps, StateProps, DispatchProps { }

interface PageState {
  projectCreateStatus: ProjectCreateStatus;
}

class DemoProjects2dComponent extends React.Component<Props, PageState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      projectCreateStatus: null,
    };
  }

  public componentWillUnmount(): void {
    this.props.dropStore();
  }

  public render(): React.ReactNode {
    const { demoProjectHeaders, title } = this.props;

    const loaded = this.props.demoProjectHeadersRequestStatus === RequestStatus.Loaded;

    return (
      <Styled.Container>
        <Styled.Projects>
          <Styled.ProjectsHeader>
            <Styled.Count>
              <Text
                fontSize={14}
                color={ColorsName.turquoise}
              >
                {demoProjectHeaders.length}
              </Text>
            </Styled.Count>
            <H4 lineHeight={34}>{title}</H4>
          </Styled.ProjectsHeader>
          <Styled.Main>
            <Styled.MainGrid loading={loaded}>
              {!!demoProjectHeaders.length && demoProjectHeaders.map(project => {
                return (
                  <DemoProjectsCard
                    key={project.id}
                    projectHeader={project}
                    onDelete={this.props.removeDemoProject}
                    onInviteToProject={ConstantFunctions.doNothing}
                    onProjectNameEdit={this.props.openEditDialog}
                    onDuplicateProjectClick={ConstantFunctions.doNothing}
                  />
                );
              })}
            </Styled.MainGrid>
          </Styled.Main>
          <UpdateDemoProjectDialog onSubmit={this.props.updateDemoProject} />
        </Styled.Projects>
      </Styled.Container>
    );
  }

  public componentDidMount(): void {
    this.fetchProjectsFirstPageIfNeeded(null);
  }

  public componentDidUpdate(prevProps: Props): void {
    this.fetchProjectsFirstPageIfNeeded(prevProps.projectType);
  }

  private fetchProjectsFirstPageIfNeeded(prevProjectType: DemoProjectType): void {
    const {
      demoProjectHeadersRequestStatus,
      fetchDemoProjectHeaders,
      projectType,
    } = this.props;

    if (demoProjectHeadersRequestStatus !== RequestStatus.Loading
      && demoProjectHeadersRequestStatus !== RequestStatus.Loaded
      || projectType !== prevProjectType
    ) {
      fetchDemoProjectHeaders(projectType);
    }
  }
}

const mapStateToProps = (state: State): StateProps => {
  const demoProjects = state.admin.demoProjects;
  return {
    demoProjectHeaders: demoProjects.demoProjects,
    demoProjectHeadersRequestStatus: demoProjects.demoProjectsRequestStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    updateDemoProject: () => dispatch(AdminDemoProjectsActions.updateDemoProject()),
    fetchDemoProjectHeaders: (type) => dispatch(AdminDemoProjectsActions.loadDemoProjects(type)),
    openEditDialog: (id) => dispatch(KreoDialogActions.openDialog(UPDATE_DEMO_PROJECT_DIALOG_NAME, { id })),
    removeDemoProject: (id, type) => dispatch(AdminDemoProjectsActions.deleteDemoProject(type, id)),
    dropStore: () => dispatch(AdminDemoProjectsActions.dropStore()),
  };
};

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const DemoProjects2d = reduxConnector(DemoProjects2dComponent);
