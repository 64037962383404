import styled from 'styled-components';

export const Blanket = styled.div<{ zIndex?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${p => p.zIndex || 1000};
`;
