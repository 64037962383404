import { CodecUtils } from 'common/utils/codec-utils';

export enum BillingPeriodUnit {
  Unknown = 'Unknown',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export const BillingPeriodUnitC = CodecUtils.createEnumCodec<BillingPeriodUnit>(BillingPeriodUnit, 'BillingPeriodUnit');
