import styled from 'styled-components';

import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';

const Container = styled.div`
  position: relative;

  ${UpgradeWrapperStyled.StarsContainer} {
    top: -10px;
    right: -10px;
  }
`;

const Badge = styled.div<{ isBadge?: boolean }>`
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  background: ${p => p.theme.color.turquoise} !important;
  animation: ${p => p.isBadge ? 'pulse' : 'none'} 0.75s ease both;
`;

export const Styled = {
  Container,
  Badge,
};
