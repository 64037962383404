import React from 'react';
import { connect } from 'react-redux';

import { DrawingsMeasureUtils } from 'common/components/drawings';
import { DrawingsInstanceMeasure } from 'common/components/drawings/interfaces';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { SimpleInformationLine } from '../information-line';

interface StateProps {
  isImperial: boolean;
}

interface OwnProps {
  instanceMeasures: DrawingsInstanceMeasure;
}

interface Props extends OwnProps, StateProps {
}

const InstanceMeasuresComponent: React.FC<Props> = ({ instanceMeasures, isImperial }) => {
  if (!instanceMeasures) {
    return null;
  }
  const { type, measures } = instanceMeasures;
  if (DrawingsMeasureUtils.isLengthMeasure(type, measures)) {
    return (
      <>
        <SimpleInformationLine
          title='Length'
          value={UnitUtil.lengthToString(measures.length, UnitTypes.M, isImperial)}
        />
        <RenderIf condition={!!measures.height}>
          <SimpleInformationLine
            title='Height'
            value={UnitUtil.lengthToString(measures.height, UnitTypes.M, isImperial)}
          />
        </RenderIf>
        <RenderIf condition={!!measures.thickness}>
          <SimpleInformationLine
            title='Thickness'
            value={UnitUtil.lengthToString(measures.thickness, UnitTypes.M, isImperial)}
          />
        </RenderIf>
        <RenderIf condition={!!measures.area}>
          <SimpleInformationLine
            title='Area'
            value={UnitUtil.lengthToString(measures.area, UnitTypes.M2, isImperial)}
          />
        </RenderIf>
        <RenderIf condition={!!measures.verticalArea}>
          <SimpleInformationLine
            title='Vertical Area'
            value={UnitUtil.lengthToString(measures.verticalArea, UnitTypes.M2, isImperial)}
          />
        </RenderIf>
        <RenderIf condition={!!measures.volume}>
          <SimpleInformationLine
            title='Volume'
            value={UnitUtil.lengthToString(measures.volume, UnitTypes.M3, isImperial)}
          />
        </RenderIf>
      </>
    );
  } else if (DrawingsMeasureUtils.isCountMeasure(type, measures)) {
    return (
      <SimpleInformationLine
        title='Count'
        value={`${measures.count} ${UnitTypes.Nr}`}
      />
    );
  } else if (
    DrawingsMeasureUtils.isPolygonMeasure(type, measures)
    || (DrawingsMeasureUtils.isRectangleMeasure(type, measures))
  ) {
    const areaText = UnitUtil.measureToString2d(measures.area, UnitTypes.M2, isImperial);
    const perimeterText = UnitUtil.lengthToString(measures.perimeter, UnitTypes.M, isImperial);
    return (
      <>
        <RenderIf condition={!!measures.height}>
          <SimpleInformationLine
            title='Height'
            value={UnitUtil.lengthToString(measures.height, UnitTypes.M, isImperial)}
          />
        </RenderIf>
        <SimpleInformationLine
          title='Perimeter'
          value={perimeterText}
        />
        <SimpleInformationLine
          title='Area'
          value={areaText}
        />
        <RenderIf condition={!!measures.verticalArea}>
          <SimpleInformationLine
            title='Vertical Area'
            value={UnitUtil.lengthToString(measures.verticalArea, UnitTypes.M2, isImperial)}
          />
        </RenderIf>
        <RenderIf condition={!!measures.volume}>
          <SimpleInformationLine
            title='Volume'
            value={UnitUtil.lengthToString(measures.volume, UnitTypes.M3, isImperial)}
          />
        </RenderIf>
      </>
    );
  }
  return null;
};

function mapStateToProps(state: State): StateProps {
  return {
    isImperial: state.account.settings.isImperial,
  };
}

export const InstanceMeasures = connect(mapStateToProps)(InstanceMeasuresComponent);
