const PROPERTIES_GROUP = 'Properties';
const BREAKDOWN_PARAMETERS_GROUP = 'Breakdown Parameters';
const LOCATIONS_GROUP = 'Locations';
const MEASUREMENTS_GROUP = 'Measurements ';
const BASE_EXTRACTORS_GROUP = 'Base Extractors';
const CLASSIFICATION_SYSTEM_GROUP = 'Classification Systems';
const CREATED_FILTERS_GROUP = 'Created Filters';

const groupOrderedArray = [
  PROPERTIES_GROUP,
  BREAKDOWN_PARAMETERS_GROUP,
  LOCATIONS_GROUP,
  MEASUREMENTS_GROUP,
  BASE_EXTRACTORS_GROUP,
  CLASSIFICATION_SYSTEM_GROUP,
  CREATED_FILTERS_GROUP,
];

/* eslint-disable key-spacing */
const keyToGroupMap = {
  f_obj:                                        PROPERTIES_GROUP,
  f_loc_level:                                  PROPERTIES_GROUP,
  f_phase:                                      PROPERTIES_GROUP,
  f_mep_type:                                   PROPERTIES_GROUP,
  f_load_bear:                                  PROPERTIES_GROUP,
  f_position:                                   PROPERTIES_GROUP,
  f_name:                                       PROPERTIES_GROUP,
  f_material:                                   PROPERTIES_GROUP,
  f_prod_method:                                PROPERTIES_GROUP,

  f_fam:                                        BREAKDOWN_PARAMETERS_GROUP,
  f_cat:                                        BREAKDOWN_PARAMETERS_GROUP,
  f_obj_type:                                   BREAKDOWN_PARAMETERS_GROUP,
  f_elem_type:                                  BREAKDOWN_PARAMETERS_GROUP,
  extra_info_imperial:                          BREAKDOWN_PARAMETERS_GROUP,
  extra_info:                                   BREAKDOWN_PARAMETERS_GROUP,

  f_loc_room:                                   LOCATIONS_GROUP,
  f_loc_mep_space:                              LOCATIONS_GROUP,
  f_loc_functional_zone:                        LOCATIONS_GROUP,
  f_loc_hvac_zone:                              LOCATIONS_GROUP,
  f_loc_sub_loc_types:                          LOCATIONS_GROUP,
  f_loc_block:                                  LOCATIONS_GROUP,
  f_loc_sub_loc:                                LOCATIONS_GROUP,
  f_loc_site:                                   LOCATIONS_GROUP,
  f_loc_main_loc_types:                         LOCATIONS_GROUP,
  f_loc_sub_area:                               LOCATIONS_GROUP,
  f_loc_building:                               LOCATIONS_GROUP,
  f_loc_area:                                   LOCATIONS_GROUP,

  f_smm7_clf:                                   CLASSIFICATION_SYSTEM_GROUP,
  f_smm7_clf_0_level:                           CLASSIFICATION_SYSTEM_GROUP,
  f_uni_prod:                                   CLASSIFICATION_SYSTEM_GROUP,
  f_uni_sys:                                    CLASSIFICATION_SYSTEM_GROUP,
  f_nrm1:                                       CLASSIFICATION_SYSTEM_GROUP,
  f_nrm1_0_level:                               CLASSIFICATION_SYSTEM_GROUP,
  f_nrm1_1_level:                               CLASSIFICATION_SYSTEM_GROUP,
  f_nrm1_2_level:                               CLASSIFICATION_SYSTEM_GROUP,
  f_nrm2:                                       CLASSIFICATION_SYSTEM_GROUP,
  f_nrm2_0_level:                               CLASSIFICATION_SYSTEM_GROUP,
  f_mas_form:                                   CLASSIFICATION_SYSTEM_GROUP,
  f_mas_form_0_level:                           CLASSIFICATION_SYSTEM_GROUP,
  f_uni_form:                                   CLASSIFICATION_SYSTEM_GROUP,
  f_uni_form_0_level:                           CLASSIFICATION_SYSTEM_GROUP,
  f_uni_form_1_level:                           CLASSIFICATION_SYSTEM_GROUP,

  ef_objectbb_bottomsurfacearea:                BASE_EXTRACTORS_GROUP,
  ef_objectbb_sidesurfacearea:                  BASE_EXTRACTORS_GROUP,
  ef_objectbb_sectionarea:                      BASE_EXTRACTORS_GROUP,
  ef_objectbb_topsurfacearea:                   BASE_EXTRACTORS_GROUP,
  ef_objectbb_sectionmaxdimension:              BASE_EXTRACTORS_GROUP,
  ef_objectbb_length:                           BASE_EXTRACTORS_GROUP,
  ef_objectbb_sectionmindimension:              BASE_EXTRACTORS_GROUP,
  ef_selfdimension_sectionhorizontaldimension:  BASE_EXTRACTORS_GROUP,
  ef_selfdimension_bottomsurfacearea:           BASE_EXTRACTORS_GROUP,
  ef_selfdimension_sectionmaxdimension:         BASE_EXTRACTORS_GROUP,
  ef_selfdimension_sectionverticaldimension:    BASE_EXTRACTORS_GROUP,
  ef_selfdimension_topsurfacearea:              BASE_EXTRACTORS_GROUP,
  ef_selfdimension_sectionarea:                 BASE_EXTRACTORS_GROUP,
  ef_selfdimension_horizontalradius:            BASE_EXTRACTORS_GROUP,
  ef_selfdimension_sectionmindimension:         BASE_EXTRACTORS_GROUP,
  ef_selfdimension_widearea:                    BASE_EXTRACTORS_GROUP,
  ef_selfdimension_thickness:                   BASE_EXTRACTORS_GROUP,
  ef_selfdimension_length:                      BASE_EXTRACTORS_GROUP,
  ef_selfdimension_sidesurfacearea:             BASE_EXTRACTORS_GROUP,
  ef_formwork_slantedmore15withopenings:        BASE_EXTRACTORS_GROUP,
  ef_formwork_slantedmore15:                    BASE_EXTRACTORS_GROUP,
  ef_formwork_verticalwithopenings:             BASE_EXTRACTORS_GROUP,
  ef_formwork_horizontal:                       BASE_EXTRACTORS_GROUP,
  ef_formwork_horizontalwithopenings:           BASE_EXTRACTORS_GROUP,
  ef_formwork_slantedless15:                    BASE_EXTRACTORS_GROUP,
  ef_formwork_vertical:                         BASE_EXTRACTORS_GROUP,
  ef_formwork_slopedperimeterwithopenings:      BASE_EXTRACTORS_GROUP,
  ef_formwork_slopedperimeter:                  BASE_EXTRACTORS_GROUP,
  ef_formwork_slantedless15withopenings:        BASE_EXTRACTORS_GROUP,
  ef_verticalbb_sectionmaxdimension:            BASE_EXTRACTORS_GROUP,
  ef_verticalbb_sidesurfacearea:                BASE_EXTRACTORS_GROUP,
  ef_verticalbb_topsurfacearea:                 BASE_EXTRACTORS_GROUP,
  ef_verticalbb_bottomsurfacearea:              BASE_EXTRACTORS_GROUP,
  ef_verticalbb_slope:                          BASE_EXTRACTORS_GROUP,
  ef_verticalbb_sectionarea:                    BASE_EXTRACTORS_GROUP,
  ef_verticalbb_sectionmindimension:            BASE_EXTRACTORS_GROUP,
  ef_verticalbb_height:                         BASE_EXTRACTORS_GROUP,
  ef_object_volume:                             BASE_EXTRACTORS_GROUP,
  ef_object_surfacearea:                        BASE_EXTRACTORS_GROUP,
  ef_object_verticalsurfacearea:                BASE_EXTRACTORS_GROUP,
  ef_object_horizontalsurfacearea:              BASE_EXTRACTORS_GROUP,

  ef_mass:                                      MEASUREMENTS_GROUP,
  ef_bottom_area:                               MEASUREMENTS_GROUP,
  ef_area:                                      MEASUREMENTS_GROUP,
  ef_net_area:                                  MEASUREMENTS_GROUP,
  ef_wide_area:                                 MEASUREMENTS_GROUP,
  ef_net_top_area:                              MEASUREMENTS_GROUP,
  ef_vertical_surface_area:                     MEASUREMENTS_GROUP,
  ef_outer_surface_area:                        MEASUREMENTS_GROUP,
  ef_double_net_area:                           MEASUREMENTS_GROUP,
  ef_net_bottom_area:                           MEASUREMENTS_GROUP,
  ef_soffits_area:                              MEASUREMENTS_GROUP,
  ef_section_area:                              MEASUREMENTS_GROUP,
  ef_net_side_surface_area:                     MEASUREMENTS_GROUP,
  ef_max_double_face_area:                      MEASUREMENTS_GROUP,
  ef_two_sides_area:                            MEASUREMENTS_GROUP,
  ef_side_area_without_top:                     MEASUREMENTS_GROUP,
  ef_gross_area:                                MEASUREMENTS_GROUP,
  ef_double_gross_area:                         MEASUREMENTS_GROUP,
  ef_side_area:                                 MEASUREMENTS_GROUP,
  ef_outer_surface_area_without_top:            MEASUREMENTS_GROUP,
  ef_gross_side_surface_area:                   MEASUREMENTS_GROUP,
  ef_min_double_face_area:                      MEASUREMENTS_GROUP,
  ef_mid_double_face_area:                      MEASUREMENTS_GROUP,
  ef_height:                                    MEASUREMENTS_GROUP,
  ef_deep:                                      MEASUREMENTS_GROUP,
  ef_gross_length:                              MEASUREMENTS_GROUP,
  ef_width:                                     MEASUREMENTS_GROUP,
  ef_gross_width:                               MEASUREMENTS_GROUP,
  ef_radius:                                    MEASUREMENTS_GROUP,
  ef_diameter:                                  MEASUREMENTS_GROUP,
  ef_section_height:                            MEASUREMENTS_GROUP,
  ef_steps_length:                              MEASUREMENTS_GROUP,
  ef_lap:                                       MEASUREMENTS_GROUP,
  ef_outer_perimeter:                           MEASUREMENTS_GROUP,
  ef_rise:                                      MEASUREMENTS_GROUP,
  ef_cable_length:                              MEASUREMENTS_GROUP,
  ef_tread_length:                              MEASUREMENTS_GROUP,
  ef_overhang:                                  MEASUREMENTS_GROUP,
  ef_span:                                      MEASUREMENTS_GROUP,
  ef_depth:                                     MEASUREMENTS_GROUP,
  ef_projection:                                MEASUREMENTS_GROUP,
  ef_length:                                    MEASUREMENTS_GROUP,
  ef_side:                                      MEASUREMENTS_GROUP,
  ef_going:                                     MEASUREMENTS_GROUP,
  ef_total_perimeter:                           MEASUREMENTS_GROUP,
  ef_sum_of_two_sides:                          MEASUREMENTS_GROUP,
  ef_thick:                                     MEASUREMENTS_GROUP,
  ef_perimeter_without_doors:                   MEASUREMENTS_GROUP,
  ef_girth:                                     MEASUREMENTS_GROUP,
  ef_tread_depth:                               MEASUREMENTS_GROUP,
  ef_projection_length:                         MEASUREMENTS_GROUP,
  ef_thickness:                                 MEASUREMENTS_GROUP,
  ef_gross_height:                              MEASUREMENTS_GROUP,
  ef_longest_side:                              MEASUREMENTS_GROUP,
  ef_item:                                      MEASUREMENTS_GROUP,
  ef_quantity:                                  MEASUREMENTS_GROUP,
  ef_pack_quantity:                             MEASUREMENTS_GROUP,
  ef_count:                                     MEASUREMENTS_GROUP,
  ef_number_of_risers:                          MEASUREMENTS_GROUP,
  ef_number_of_treads:                          MEASUREMENTS_GROUP,
  ef_pitch:                                     MEASUREMENTS_GROUP,
  ef_volume:                                    MEASUREMENTS_GROUP,
  ef_capacity:                                  MEASUREMENTS_GROUP,
  ef_net_volume:                                MEASUREMENTS_GROUP,
  ef_gross_volume:                              MEASUREMENTS_GROUP,
  ef_wire_type:                                 MEASUREMENTS_GROUP,
  ef_wire_size:                                 MEASUREMENTS_GROUP,
  ef_mep_type:                                  MEASUREMENTS_GROUP,
  ef_fitting_size:                              MEASUREMENTS_GROUP,
  ef_host_wall_material:                        MEASUREMENTS_GROUP,
  ef_section_type:                              MEASUREMENTS_GROUP,
};
/* eslint-enable */

export const CustomElementFilterBuilderKeyConfig = {
  constants: {
    PROPERTIES_GROUP,
    BREAKDOWN_PARAMETERS_GROUP,
    LOCATIONS_GROUP,
    BASE_EXTRACTORS_GROUP,
    CLASSIFICATION_SYSTEM_GROUP,
    CREATED_FILTERS_GROUP,
  },
  keyToGroupMap,
  groupOrderedArray,
};
