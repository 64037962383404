import styled from 'styled-components';
import { LoaderStyled } from 'common/components/progress/components/loader';

const Container = styled.div<{ hasBar: boolean }>`
  width: 220px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${(p) => p.theme.color.backgroundRush};
  border-radius: 20px;
  padding: 10px 15px;
  display: ${(p) => !p.hasBar ? 'none' : 'flex'};
  gap: 5px;
  flex-direction: column;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.05);
`;

const Background = styled.div<{ isInitialized: boolean, hasBar: boolean }>`
  position: ${(p) => p.isInitialized ? 'absolute' : 'relative' };
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: ${(p) => p.isInitialized && !p.hasBar ? '' : '100'};
  top: 0;
  left: 0;
  background-color: ${(p) => `${p.theme.color.background}85`};
  ${LoaderStyled.Container} {
    width: 220px;
  }
`;

export const Styled = {
  Background,
  Container,
};
