import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { ConstantFunctions } from 'common/constants/functions';
import { DrawingsCanvasColors, DrawingsCanvasConstants } from '../constants';
import { DrawingStrokeStyles } from '../constants/drawing-styles';
import { DrawingContextObserver } from '../drawings-contexts';
import { DrawingsRenderParams } from '../interfaces';
import { DrawingSnapping } from '../interfaces/drawing';
import { VisibleEntity, VisibleEntityConfig } from './common';
import { DrawingsGeometryEntityPoint } from './drawings-geometry-entities';
import { Line } from './drawings-geometry-entities/utility/segment/line';
import { DrawingsGeometrySnappingHelper, DrawingsSnappingParameters, DrawingsViewHelper } from './drawings-helpers';
import { GetCurrentPageSizeInfoCallback } from './interfaces';

interface Geometry {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

interface Config extends VisibleEntityConfig<Geometry> {
  canvas: HTMLCanvasElement;
  getCurrentDrawingInfo: GetCurrentPageSizeInfoCallback;
  snappingObserver: DrawingContextObserver<DrawingSnapping>;
  onLineChanged: (start: paper.Point, end: paper.Point) => void;
}

export class LineEngine extends VisibleEntity<Geometry, Config> {
  private _scope: paper.PaperScope;
  private _linePoints = [];
  private _snappingHelper: DrawingsGeometrySnappingHelper;
  private _line: Line;
  private _points: DrawingsGeometryEntityPoint[] = [];
  private _tempLine: Line;
  private _layer: paper.Layer;
  private _viewHelper: DrawingsViewHelper;
  private _tempPoint: paper.Point;

  constructor(config: Config) {
    super(config);
    this._config.renderParamsContextObserver.subscribe(this.updateZoom);
  }

  public destroy(): void {
    if (this._layer) {
      this._layer.removeChildren();
    }
    this._config.renderParamsContextObserver.unsubscribe(this.updateZoom);
  }

  public get viewHelper(): DrawingsViewHelper {
    return this._viewHelper;
  }

  public setLine(line: Geometry): void {
    if (this._config.geometry === line) {
      return;
    }
    this._config.geometry = line;
    this.removeLine();
    this.render(line);
  }

  @autobind
  protected override render(geometry: Geometry): void {
    this.initScope();
    if (!geometry) {
      return;
    }
    this._line = new Line({
      layer: this._layer,
      color: DrawingsCanvasColors.utility,
      geometry: [new paper.Point(geometry.x1, geometry.y1), new paper.Point(geometry.x2, geometry.y2)],
      strokeStyled: {
        strokeWidth: 4,
        strokeStyle: DrawingStrokeStyles.Dashed,
      },
      renderParamsContextObserver: this._config.renderParamsContextObserver,
    });
    this._points = [
      new DrawingsGeometryEntityPoint({
        id: '0',
        layer: this._layer,
        geometry: new paper.Point(geometry.x1, geometry.y1),
        renderParamsContextObserver: this._config.renderParamsContextObserver,
      }),
      new DrawingsGeometryEntityPoint({
        id: '1',
        layer: this._layer,
        geometry: new paper.Point(geometry.x2, geometry.y2),
        renderParamsContextObserver: this._config.renderParamsContextObserver,
      }),
    ];
  }

  private initScope(): void {
    if (this._scope) {
      return;
    }
    this._scope = new paper.PaperScope();
    this._viewHelper = new DrawingsViewHelper({
      eventDefaults: {
        onLeftMouseClick: this.onClick,
        onRightMouseClick: ConstantFunctions.stopEvent,
        onMouseDown: ConstantFunctions.doNothing,
        onMouseMove: this.onMouseMove,
        onMouseUp: ConstantFunctions.doNothing,
        onDoubleClick: ConstantFunctions.doNothing,
      },
      scope: this._scope,
    });
    this._viewHelper.init(this._config.canvas);
    this._layer = new this._scope.Layer();
    this._snappingHelper = new DrawingsGeometrySnappingHelper({
      scope: this._scope,
      observableRenderParameters: this._config.renderParamsContextObserver,
      snappingObserver: this._config.snappingObserver,
      getSnappingPoint: () => null,
      getCurrentPageInfo: this._config.getCurrentDrawingInfo,
    });
    this._snappingHelper.snappingModes = [Core.PDFNet.GeometryCollection.SnappingMode.e_PathEndpoint];
    this._snappingHelper.enable = true;
    this._snappingHelper.onSendPoint = this.onSendPointFromSnapping;
  }

  @autobind
  private onClick(e: PaperMouseEvent): void {
    this.updateCurrentPointByEvent(e.point);
    this._linePoints.push(this._tempPoint);
    if (this._linePoints.length === 2) {
      this._config.onLineChanged(this._linePoints[0], this._linePoints[1]);
      this._tempLine.destroy();
      this._linePoints = [];
    } else {
      this._tempLine = new Line({
        layer: this._layer,
        color: DrawingsCanvasColors.utility,
        geometry: [this._linePoints[0], this._tempPoint],
        strokeStyled: {
          strokeWidth: 4,
          strokeStyle: DrawingStrokeStyles.Dashed,
        },
        renderParamsContextObserver: this._config.renderParamsContextObserver,
      });
    }
  }

  private updateCurrentPointByEvent(point: paper.Point): void {
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    const shouldChangeCurrentPosition = !this._tempPoint
      || point.subtract(this._tempPoint).length > DrawingsCanvasConstants.snappingThreshold / zoom;
    if (shouldChangeCurrentPosition) {
      this._tempPoint = point;
    }
    this.updateTempPoint(this._tempPoint);
  }

  @autobind
  private onMouseMove(e: PaperMouseEvent): void {
    if (this._snappingHelper) {
      this._snappingHelper.requestSnapping(e.point);
    }
  }

  private updateTempPoint(point: paper.Point): void {
    if (this._tempLine) {
      this._tempLine.updateGeometry([this._linePoints[0], point]);
    }
  }

  @autobind
  private onSendPointFromSnapping(
    point: paper.Point,
    snappingInfo?: DrawingsSnappingParameters,
  ): paper.Point {
    this._tempPoint = point;
    if (snappingInfo) {
      const { snappingPoint } = snappingInfo;
      if (snappingPoint && snappingPoint.subtract(point).length < DrawingsCanvasConstants.snappingThreshold) {
        this._tempPoint = snappingInfo.snappingPoint;
        this.updateTempPoint(this._tempPoint);
        return snappingInfo.snappingPoint;
      }
    }
    this.updateTempPoint(this._tempPoint);
    return null;
  }

  @autobind
  private updateZoom({ zoom }: DrawingsRenderParams): void {
    this._viewHelper.setViewportZoom(zoom);
  }

  private removeLine(): void {
    this._points.forEach(x => x.destroy());
    this._points = [];
    if (this._line) {
      this._line.destroy();
    }
  }
}
