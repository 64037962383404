import { MovableContextMenu } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ChatLinkButton } from './chat-link-button';
import { GiftLinkButton } from './gift-link-button';
import { HelpCenterButton } from './help-center-button';
import { ShortcutsButton } from './shortcuts-button';
import { Styled } from './styled';
import { VideoTutorialsButton } from './video-tutorials-button';
import { WhatsNewButton } from './whats-new-button';


interface Props {
  onClose: () => void;
  menuPositionX: number;
  menuPositionY: number;
}

export const MenuQuestions: React.FC<Props> = ({ onClose, menuPositionX, menuPositionY }) => {
  return (
    <MovableContextMenu
      onClose={onClose}
      x={menuPositionX}
      y={menuPositionY}
    >
      <Styled.Container>
        <ChatLinkButton />
        <GiftLinkButton />
        <HelpCenterButton />
        <WhatsNewButton />
        <VideoTutorialsButton />
        <ShortcutsButton />
      </Styled.Container>
    </MovableContextMenu>
  );
};
