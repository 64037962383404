import { CodecUtils } from 'common/utils/codec-utils';

export enum SubscriptionType {
  Takeoff2d = 'takeoff2D',
  Takeoff3d = 'takeoff3D',
  CostEstimate = 'costEstimate',
  KreoPlan = 'kreoPlan',
}

export const SubscriptionTypeC = CodecUtils.createEnumCodec<SubscriptionType>(SubscriptionType, 'SubscriptionType');
