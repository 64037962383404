import { arrayUtils } from 'common/utils/array-utils';
import { ModelCheckPriority } from '../../enums/mode-check-priority';
import { ModelCheckDataResponse } from './interfaces/model-check-data-response';
import * as DataTypes from './interfaces/types';

function mergeHandles(handles: DataTypes.SourceErrorHandles[]): DataTypes.SourceErrorHandles[] {
  const ret = new Array<DataTypes.SourceErrorHandles>();
  const indexes = [];
  handles.forEach((x, index) => {
    if (indexes.indexOf(x.bimHandleId) === -1) {
      indexes.push(x.bimHandleId);
      const xi = handles.slice(index).filter((y) => {
        if (y.bimHandleId === x.bimHandleId) {
          return true;
        } else {
          return false;
        }
      });
      const bimHandles = xi.map((error): DataTypes.BimHandleData => {
        return {
          category: error.secondCategory,
          family: error.secondFamily,
          type: error.secondType,
          bimHandleIds: error.secondIds,
        };
      });
      delete x.secondCategory;
      delete x.secondType;
      delete x.secondFamily;
      delete x.secondIds;
      x.bimHandleIds = bimHandles;
      ret.push(x);
    }
  });
  return ret;
}


function takeIds(handles: DataTypes.SourceErrorHandles[]): number[] {
  const ret = [];
  handles.forEach((sourceErrorHandle) => {
    if (sourceErrorHandle.bimHandleId !== undefined) {
      ret.push(sourceErrorHandle.bimHandleId);
      (sourceErrorHandle.bimHandleIds as DataTypes.BimHandleData[]).forEach((bimHandleInfo) => {
        arrayUtils.extendArray(ret, bimHandleInfo.bimHandleIds.map(x => x.bimHandleId));
      });
    } else {
      arrayUtils.extendArray(
        ret,
        (sourceErrorHandle.bimHandleIds as DataTypes.BimHandeIdToRevitId[]).map((pair) => pair.bimHandleId),
      );
    }
  });
  return ret;
}

function getIssuesCount(handles: DataTypes.SourceErrorHandles[]): number {
  let count = 0;
  handles.forEach((sourceErrorHandle) => {
    count += sourceErrorHandle.bimHandleIds.length;
  });
  return count;
}

const checkCategory = process.env.NODE_ENV === 'development'
  ? (val: DataTypes.SourceIssue) => {
    if (val.errors.length > 0) {
      const errors = val.errors;
      console.warn(errors);
      let ids: number[] = [];
      errors.forEach((error) => {
        ids = ids.concat(error.ids.map((value) => value.bimHandleId));
      });
      if (ids.length !== new Set(ids).size) {
        console.error(val, ids.length);
        console.error('bim handle id duplication');
      }
    } else {
      console.warn('clear array');
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  : (_val: DataTypes.SourceIssue) => { };

export function preprocess(data: ModelCheckDataResponse): DataTypes.PostProcessed {
  const ret = {
    issues: [],
    errors: [],
    warning: [],
    trivial: [],
    totalBim: data.totalBimHandles,
  };
  const source: DataTypes.SourceIssue[] = [];
  data.singleGroup.forEach(x => source[x.order - 1] = x);
  data.pairGroup.forEach(x => source[x.order - 1] = x);
  source.forEach((x: DataTypes.SourceIssue): void => {
    if (x.errors.length > 0 && x.errors[0].firstCategory !== undefined) {
      x.errors = mergeHandles(x.errors);
    } else if (x.errors.length > 0) {
      checkCategory(x);
      x.errors.map((handle: any) => {
        handle.bimHandleIds = handle.ids.slice();
        delete handle.ids;
      });
    }
    const ids = takeIds(x.errors);
    x.count = getIssuesCount(x.errors);
    switch (x.priority) {
      case ModelCheckPriority.Middle:
      case ModelCheckPriority.High: {
        arrayUtils.extendArray(ret.warning, ids);
        break;
      }
      default: {
        arrayUtils.extendArray(ret.trivial, ids);
      }
    }
    ret.errors[x.order - 1] = x.errors;
    x.errors = [];
  });
  ret.issues = source;
  return ret;
}
