import styled from 'styled-components';

const Title = styled.div`
  max-width: 100%;
`;


export const Styled = {
  Title,
};
