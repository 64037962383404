import autobind from 'autobind-decorator';
import React from 'react';
import { Field, FieldArray } from 'redux-form';

import './database-activity.scss';

import { MaterialInputField, MaterialInputProps } from 'common/UIKit';
import { ActivityModel, ActivityResourceVariant, CodeGroupModel } from '../../interfaces/data';
import { LaborVariantModel, PlantVariantModel } from '../../interfaces/resources-data';
import {
  DatabaseActivityClassificationField,
  DatabaseActivityClassificationProps,
} from './database-activity-classification';
import { Labors, LaborsProps } from './database-activity-labours';
import { Plants, PlantsProps } from './database-activity-plants';


interface Props {
  readonly: boolean;
  activity: ActivityModel;
  onChange: (activity: ActivityModel) => void;
}

export class DatabaseActivity extends React.Component<Props> {
  public render(): React.ReactNode {
    const isReadOnly = this.props.readonly;

    return (
      <div className='database-activity'>
        <div className='database-activity__name'>
          <Field<MaterialInputProps>
            name='name'
            component={MaterialInputField}
            label='Name'
            placeholder='Name'
            multiLine={true}
            value={this.props.activity.name}
            onChange={this.onNameChange}
            onBlur={this.onNameBlur}
            displayBottomInformation={true}
            disabled={isReadOnly}
          />
        </div>
        <FieldArray<LaborsProps>
          name='labors'
          component={Labors}
          labors={this.props.activity.labors}
          onChange={this.onLaborsChange}
          readonly={isReadOnly}
        />
        <FieldArray<PlantsProps>
          name='plants'
          component={Plants}
          plants={this.props.activity.plants}
          onChange={this.onPlantsChange}
          readonly={isReadOnly}
        />
        <div className='database-activity__classification'>
          <Field<DatabaseActivityClassificationProps>
            name='codeGroups'
            component={DatabaseActivityClassificationField}
            codeGroups={this.props.activity.codeGroups}
            onClassificationChange={this.onClassificationChange}
            readonly={isReadOnly}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onNameChange(_: React.ChangeEvent, name: string): void {
    this.props.onChange({
      ...this.props.activity,
      name,
    });
  }

  @autobind
  private onNameBlur(_: React.ChangeEvent, value: string): void {
    const name = value.trim();
    this.props.onChange({
      ...this.props.activity,
      name,
    });
  }

  @autobind
  private onLaborsChange(labors: Array<ActivityResourceVariant<LaborVariantModel>>): void {
    this.props.onChange({
      ...this.props.activity,
      labors,
    });
  }


  @autobind
  private onPlantsChange(plants: Array<ActivityResourceVariant<PlantVariantModel>>): void {
    this.props.onChange({
      ...this.props.activity,
      plants,
    });
  }

  @autobind
  private onClassificationChange(codeGroups: CodeGroupModel[]): void {
    this.props.onChange({
      ...this.props.activity,
      codeGroups,
    });
  }
}
