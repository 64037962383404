import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';

import './custom-filter-item.scss';

interface Params extends Ag.ICellRendererParams {
  onApplyFilter: (id: string) => void;
  onRemoveFilter: (id: string) => void;
}

export class CustomFilterItem implements Ag.ICellRendererComp {
  private eGui: HTMLDivElement;
  private eValue: HTMLSpanElement;
  private eApplyButton: HTMLDivElement;
  private eDiscardButton: HTMLDivElement;
  private eRemoveButton: HTMLDivElement;
  private params: Params;

  public init(params: Params): void {
    this.params = params;
    this.eGui = document.createElement('div');
    this.eGui.className = 'qto-custom-filter-item';
    this.eValue = document.createElement('span');
    this.eValue.className = 'qto-custom-filter-item__value';
    this.eValue.textContent = this.params.value;

    this.eApplyButton = document.createElement('div');
    this.eApplyButton.className = 'qto-custom-filter-item__btn qto-custom-filter-item__btn--apply';
    this.eApplyButton.title = 'Apply filter';

    this.eDiscardButton = document.createElement('div');
    this.eDiscardButton.className = 'qto-custom-filter-item__btn qto-custom-filter-item__btn--discard';
    this.eDiscardButton.title = 'Discard filter applying';

    this.eRemoveButton = document.createElement('div');
    this.eRemoveButton.className = 'qto-custom-filter-item__btn qto-custom-filter-item__btn--remove';
    this.eRemoveButton.title = 'Remove filter';

    this.renderInnerHTML();
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: Params): boolean {
    this.params = params;
    this.renderInnerHTML();
    return true;
  }

  public destroy(): void {
    this.destroyEvents();
  }

  public getValue(): any {
    return;
  }

  private renderInnerHTML(): void {
    this.destroyEvents();

    this.eGui.appendChild(this.eValue);
    this.eGui.appendChild(this.eApplyButton);
    this.eGui.appendChild(this.eDiscardButton);
    this.eGui.appendChild(this.eRemoveButton);

    this.initEvents();
  }

  private destroyEvents(): void {
    if (this.eApplyButton) {
      this.eApplyButton.removeEventListener('click', this.onApplyFilter);
    }
    if (this.eDiscardButton) {
      this.eDiscardButton.removeEventListener('click', this.onDiscardFilter);
    }
    if (this.eRemoveButton) {
      this.eRemoveButton.removeEventListener('click', this.onRemoveFilter);
    }
  }

  private initEvents(): void {
    if (this.eApplyButton) {
      this.eApplyButton.addEventListener('click', this.onApplyFilter);
    }
    if (this.eApplyButton) {
      this.eDiscardButton.addEventListener('click', this.onDiscardFilter);
    }
    if (this.eRemoveButton) {
      this.eRemoveButton.addEventListener('click', this.onRemoveFilter);
    }
  }

  @autobind
  private onApplyFilter(event: MouseEvent): void {
    event.stopPropagation();
    this.params.onApplyFilter(this.params.node.id);
  }

  @autobind
  private onDiscardFilter(event: MouseEvent): void {
    event.stopPropagation();
    this.params.onApplyFilter(null);
  }

  @autobind
  private onRemoveFilter(event: MouseEvent): void {
    event.stopPropagation();
    this.params.onRemoveFilter(this.params.node.id);
  }
}
