import * as Ag from 'ag-grid-community';

import { TreeTableValueGetterParams } from 'common/components/tree-table/interfaces';
import { MetricUnitConversationMap, UnitUtil } from 'common/utils/unit-util';
import { ValueHelper } from 'common/utils/value-helper';
import { DefaultPivotColumnKey } from '../../enums/default-pivot-column-key';
import { PropertyHelper, QtoReportPivotTableIdHelper } from '../../utils/quantity-take-off-tree-table';
import { QtoTreeTableCommon } from '../quantity-take-off-report-table/quantity-take-off-tree-table-common';


const unitCellValueFormatter = ({ value, context }: Ag.ValueFormatterParams): string => {
  return value
    ? UnitUtil.getSupUnit(context.isImperial && MetricUnitConversationMap[value] || value)
    : value;
};

const isFormula = (params: Ag.ValueFormatterParams): boolean => {
  const { columnId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(params.node.id);
  return PropertyHelper.isFormula(params.node.parent.data.properties[columnId]);
};

const quantityCellValueFormatter = (
  params: Ag.ValueFormatterParams,
  valueGetter: (params: TreeTableValueGetterParams) => string,
): string => {
  const { value, context } = params;
  if (!ValueHelper.isNumberValue(value)) {
    return value;
  }

  let convertedValue = value;
  if (context.isImperial && !isFormula(params)) {
    const unit = valueGetter({
      ...params,
      columnId: DefaultPivotColumnKey.Unit,
    });
    convertedValue = UnitUtil.convertUnit(value, unit, MetricUnitConversationMap[unit]).value;
  }

  return QtoTreeTableCommon.cellValueFormatter({
    ...params,
    value: convertedValue,
    context: {
      ...params.context,
      isImperial: false,
    },
  });
};

export const qtoReportPivotTableCellValueFormatter = (
  params: Ag.ValueFormatterParams,
  valueGetter: (params: TreeTableValueGetterParams) => string,
): string => {
  const { column } = params;
  const columnId = column.getColId();

  switch (columnId) {
    case DefaultPivotColumnKey.Unit:
      return unitCellValueFormatter(params);
    case DefaultPivotColumnKey.Quantity:
    case DefaultPivotColumnKey.QuantityPrev:
      return quantityCellValueFormatter(params, valueGetter);
    default:
      return QtoTreeTableCommon.cellValueFormatter(params);
  }
};
