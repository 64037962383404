import * as React from 'react';
import { DrawingsDrawMode } from '../enums';
import { DrawingsSelectItemsCursorHint } from '../layout-components/drawings-cursor-hint';
import { Styled } from '../styled';
import { LayoutFocusedContext } from './layout-focused-context';
import { useLayoutFocus } from './use-layout-focus';

interface Props {
  bottomOffset: number;
  isOpenDrawingsAnnotationLegendPanel: boolean;
  children: React.ReactNode;
  drawMode: DrawingsDrawMode;
}

export const DrawingsLayoutContainer = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, bottomOffset, drawMode, isOpenDrawingsAnnotationLegendPanel } = props;
  const { onBlur, onFocus, focused } = useLayoutFocus();
  return (
    <Styled.Container
      tabIndex={-1}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={ref}
      bottomOffset={bottomOffset}
      isOpenPanel={isOpenDrawingsAnnotationLegendPanel}
    >
      <DrawingsSelectItemsCursorHint mode={drawMode}/>
      <LayoutFocusedContext.Provider value={focused}>{children}</LayoutFocusedContext.Provider>
    </Styled.Container>
  );
});
