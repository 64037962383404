import * as React from 'react';
import { connect } from 'react-redux';

import './resource-limitations-row-total.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { WorkPackagesData } from '../../../scenarios';
import { ResourceLimitationsFieldsArea } from '../resource-limitations-fields-area';

interface ReduxProps {
  totalLimitation: WorkPackagesData.TotalLimitation;
}

class ResourceLimitationsRowTotalComponent extends React.Component<ReduxProps> {
  public render(): JSX.Element {
    const { totalLimitation } = this.props;

    return (
      <div className='resource-limitations-row-total'>
        <div className='resource-limitations-row-total__title'>Entire Project</div>
          <ResourceLimitationsFieldsArea
            itemId={null}
            productivity={totalLimitation.crewProductivity}
            useAmdahl={totalLimitation.useAmdahl}
            mixedCheckbox={totalLimitation.useAmdahlIndeterminate}
            shift={totalLimitation.calendarIds}
          />
      </div>
    );
  }
}
const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    totalLimitation: state.scenarios.totalLimitation,
  };
};


const connector = connect(mapStateToProps);
export const ResourceLimitationsRowTotal = connector(ResourceLimitationsRowTotalComponent);
