import { useCallback, useState } from 'react';

export function useApplyWithState(apply: () => Promise<void>): [() => void, boolean] {
  const [isApplying, setIsApplying] = useState(false);
  const wrapper = useCallback(() => {
    setIsApplying(true);
    apply().then(() => {
      setIsApplying(false);
    });
  }, [apply]);

  return [wrapper, isApplying];
}
