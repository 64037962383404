
import autobind from 'autobind-decorator';
import { get } from 'lodash';
import React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';

import { ConstraintModel } from '../../interfaces/data';
import { ConstraintForm } from '../../interfaces/rest-data';
import { DatabaseEntityNamedHeader } from '../database-entity-named-header';
import {
  DatabaseActivityVariantConstraintItemField,
  DatabaseActivityVariantConstraintItemProps,
} from './database-activity-variant-constraint-item';

export interface ConstraintsProps {
  readonly: boolean;
  constraints: ConstraintModel[];
  onChange: (constraints: ConstraintModel[]) => void;
}

export class DatabaseActivityVariantConstraints
  extends React.Component<ConstraintsProps & WrappedFieldArrayProps<ConstraintForm>> {
  public render(): React.ReactNode {
    return (
      <div className='constraints-right-panel'>
        <DatabaseEntityNamedHeader
          name='Constraints'
          info='This parameter may affect the cost and duration of the activity.
          Variants of the same activity differ in constraints.'
          onAddButtonClick={this.props.readonly ? null : this.onAdd}
        />
        <div className='constraints-right-panel__content'>
        {
          this.props.fields.map((fieldKey, index) => {
            return this.props.constraints[index] ? (
              <Field<DatabaseActivityVariantConstraintItemProps>
                name={fieldKey}
                component={DatabaseActivityVariantConstraintItemField}
                constraint={this.props.constraints[index]}
                key={fieldKey}
                index={index}
                onConstraintChange={this.onChange}
                onDelete={this.onDelete}
                readonly={this.props.readonly}
                validate={this.NotContainDuplicate}
              />
            ) : null;
          })
        }
        </div>
      </div>
    );
  }

  @autobind
  private onAdd(): void {
    const emptyConstraint: ConstraintModel = {
      id: null,
      operation: null,
      unitId: null,
      extractorFunction: '',
      value: null,
      stringValue: null,
    };
    const constraints = this.props.constraints.concat(emptyConstraint);
    this.props.onChange(constraints);
  }

  @autobind
  private onChange(index: number, constraint: ConstraintModel): void {
    const constraints = this.props.constraints.slice();
    constraints[index] = constraint;
    this.props.onChange(constraints);
  }

  @autobind
  private onDelete(index: number): void {
    const constraints = this.props.constraints.slice();
    constraints.splice(index, 1);
    this.props.fields.remove(index);
    this.props.onChange(constraints);
  }

  @autobind
  private NotContainDuplicate(value: ConstraintForm, values: any, __: any, fieldName: string): string | undefined {
    if (!value) {
      return;
    }

    const hasDuplicate = this.props.fields.map(name => {
      const constraint: ConstraintForm = get(values, name, {});
      return  name !== fieldName &&
        value.extractorFunctionId === constraint.extractorFunctionId &&
        value.operation === constraint.operation &&
        value.unitId === constraint.unitId &&
        (value.value || null) === (constraint.value || null);
    }).includes(true);

    if (hasDuplicate) {
      return 'Such constraint already exists';
    }
  }
}
