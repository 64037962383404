import { DropDownStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  max-height: 100%;
  background-color: ${p => p.theme.color.backgroundRush};

  ${DropDownStyled.DropDownMenuContainer} {
    max-height: 180px;

    > div {
      max-height: 180px;
    }
  }
`;

const TabsWrapper = styled.div`
  position: relative;
  max-width: calc(100% - 60px);
  z-index: 101;

  :hover {
    > div {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
`;

const PdfBrowserContainer = styled.div`
  position: relative;
  width: 90px;
`;

const PdfBrowserInner = styled.div<{ isPdfManagerDialogOpened: boolean }>`
  position: absolute;
  top: 10px;
  padding-left: 20px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  #open-pdf-browser-button {
    border: 2px solid ${p => p.theme.color.turquoise};

    path {
      fill: ${p => p.isPdfManagerDialogOpened ? p.theme.color.white : p.theme.color.turquoise};
    }

    &:hover {
      background-color: ${p => p.theme.color.turquoise};

      path {
        fill: ${ p => p.theme.color.white};
      }
    }
  }
`;

export const Styled = {
  Container,
  TabsWrapper,
  PdfBrowserContainer,
  PdfBrowserInner,
};
