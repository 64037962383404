import * as React from 'react';

import { MenuFileManager } from './page-header-menu-file-manager';
import { Styled } from './styled';


export class ProjectMenu extends React.Component {
  public render(): React.ReactNode {
    return (
      <Styled.ProjectMenuContainer>
        <MenuFileManager />
      </Styled.ProjectMenuContainer>
    );
  }
}
