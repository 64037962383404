import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-assembly-entity-info.scss';

import { KreoIconEditPen } from 'common/UIKit';
import { ClassificationActions } from '../../actions/creators/classification';

interface OwnProps {
  isAssembly: boolean;
  code: string;
  title: string;
  index: number;
  isDisabled: boolean;
}

interface DispatchProps {
  onEdit: (e: React.MouseEvent<HTMLDivElement>) => void;
}


interface Props extends OwnProps, DispatchProps {}

const ClassificationAssemblyEntityInfoComponent: React.FC<Props> = props => {
  const className = classNames('classification-assembly-entity-info', { 'disabled': props.isDisabled });
  return (
    <div
      className={className}
      onClick={props.onEdit}
      data-control-name='edit-assembly'
    >
      {!props.isDisabled && <i className='classification-assembly-entity-info__icon'><KreoIconEditPen /></i>}
      <span className='classification-assembly-entity-info__code'>
        {props.isAssembly ? 'System' : 'Layer'} {props.code}
      </span>
      <span className='classification-assembly-entity-info__title'>{props.title}</span>
    </div>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  { index, isAssembly }: OwnProps,
): DispatchProps => {
  return {
    onEdit: () => {
      if (isAssembly) {
        dispatch(ClassificationActions.editAssebmlyClassification());
      } else {
        dispatch(ClassificationActions.editLayerClassification(index));
      }
    },
  };
};

const connector = connect(null, mapDispatchToProps);
export const ClassificationAssemblyEntityInfo = connector(
  ClassificationAssemblyEntityInfoComponent,
);
