import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  ${Text}{
    margin-left: 10px;
  }
`;

export const Styled = {
  Container,
};
