import styled from 'styled-components';
import { FileInfoStyled } from './file-info';

const Container = styled.div`
  padding: 10px 0;
  width: 360px;
  ${FileInfoStyled.Container}+${FileInfoStyled.Container} {
    border-bottom: 1px solid ${p => p.theme.color.background};
  }
`;

const Controls = styled.div`
  margin-top: 10px;
  margin-bottom: 12px;
`;

const ContextMenuGroupWrapper = styled.div`
  margin-top: 10px;

  > div {
    border-top: none;
  }
`;

const Reset = styled.div`
  display: flex;
  margin-left: 10px;

  > div {
    > p {
      color: ${p => p.theme.color.mainFont} !important;
    }
    > svg {
      margin-right: 10px;
    }

    :hover {
      > p {
        color: ${p => p.theme.color.turquoise} !important;
      }
    }
  }
`;

export const Styled = {
  Container,
  Controls,
  ContextMenuGroupWrapper,
  Reset,
};
