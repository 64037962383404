import React, { useCallback, useMemo } from 'react';

import { Operation } from 'common/ability/operation';
import { useAbilityContext } from 'common/ability/use-ability-context';
import { OptionsSubjectsMap } from '../helpers';
import { InstrumentInfo } from '../interfaces';
import { Instrument } from './instrument';
import { SelectModeSettings } from './interfaces';
import { Styled } from './styled';


interface Props {
  instrument: InstrumentInfo;
  onClick: (selectSettings: SelectModeSettings) => void;
  isActive: boolean;
}

const InstrumentMenuItemComponent: React.FC<Props> = ({
  instrument,
  onClick,
  isActive,
}) => {
  const { ability } = useAbilityContext();
  const needUpdatePlan = useMemo(
    () => OptionsSubjectsMap[instrument.id]?.some((s) => ability.cannot(Operation.Update, s)),
    [instrument.id, ability],
  );
  const onClickHandler = useCallback(() => {
    onClick({ mode: instrument.id });
  }, [onClick, instrument]);

  return (
    <Styled.InstrumentContainer
      disabled={instrument.disabled}
      addSeparationBefore={instrument.addSeparationBefore}
      onClick={needUpdatePlan ? undefined : onClickHandler}
    >
      <Instrument
        instrument={instrument}
        onClick={onClick}
        isActive={isActive}
        needUpdatePlan={needUpdatePlan}
      />
    </Styled.InstrumentContainer>
  );
};

export const InstrumentMenuItem = React.memo(InstrumentMenuItemComponent);
