import { AnyAction } from 'redux';


import { RequestStatus } from 'common/enums/request-status';
import { AccountActionTypes } from '../units/account/actions/types';
import { AdminCompaniesState } from '../units/admin/pages/companies/interfaces';
import { adminCompaniesReducerMethods } from '../units/admin/reducers/companies';

export const INITIAL_STATE: AdminCompaniesState = {
  count: 0,
  data: [],
  status: RequestStatus.Loaded,
  statuses: {
    deletedProjects: RequestStatus.Loaded,
    attachDatabases: RequestStatus.Loaded,
    detachDatabase: RequestStatus.Loaded,
    attachEmployee: RequestStatus.Loaded,
    detachEmployee: RequestStatus.Loaded,
    loadCompanyRoles: RequestStatus.Loaded,
    changeRole: RequestStatus.Loaded,
    loadProjectsEmployee: RequestStatus.Loaded,
    attachEmployeeToProject: RequestStatus.Loaded,
    detachEmployeeFromProject: RequestStatus.Loaded,
    getSubscriptionPlans: RequestStatus.NotRequested,
    setMockSubscription: {},
    resetMockSubscription: {},
    createEmptySubscription: {},
    currencies: RequestStatus.NotRequested,
    updateCurrency: {},
  },
  databaseFeed: {
    count: 0,
    data: [],
    status: RequestStatus.Loaded,
  },
  employeeFeed: {
    count: 0,
    data: [],
    status: RequestStatus.Loaded,
  },
  selectedCompanyRoles: [],
  selectedProjectEmployees: [],
  subscriptionPlanOptions: [],
  currencies: [],
};

export default function adminCompanies(
  state: AdminCompaniesState = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): AdminCompaniesState {
  if (action.type in adminCompaniesReducerMethods) {
    return adminCompaniesReducerMethods[action.type](state, action.payload);
  } else {
    if (
      action.type === AccountActionTypes.LOG_OUT
    ) {
      return INITIAL_STATE;
    }
    return state;
  }
}
