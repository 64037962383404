import * as t from 'io-ts';
import { BillingCardBrandC } from '../enums/billing-card-brand';


export const BillingCardModelC = t.type(
  {
    expiryMonth: t.number,
    expiryYear: t.number,
    last4: t.string,
    brand: BillingCardBrandC,
  },
  'BillingCardForm');

export type BillingCardModel = t.TypeOf<typeof BillingCardModelC>;
