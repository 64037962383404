import { StringDictionary } from 'common/interfaces/dictionary';
import { ViewModelStatus } from '../enums/view-model-status';
import { Project } from '../interfaces/project';
import { ProjectFullViewModel } from '../interfaces/project-full-view-model';
import { ViewModelStatusPair } from '../interfaces/view-model-status-pair';

function makeViewModelStatusesDictionary(statusPairs: ViewModelStatusPair[]): StringDictionary<ViewModelStatus> {
  return statusPairs.reduce(
    (dict: StringDictionary<ViewModelStatus>, pair) => {
      dict[pair.viewModelType] = pair.status;
      return dict;
    },
    {});
}

export const ProjectMapper = {
  mapFromProjectFullViewModel: (vm: ProjectFullViewModel): Project => {
    return {
      ...vm,
      viewModelsStatuses: vm.viewModelsStatuses ?  makeViewModelStatusesDictionary(vm.viewModelsStatuses) : {},
      validationFinishResult: null,
      projectFailureNotified: vm.projectFailureNotified,
      hasUnstartedProducts: vm.hasUnstartedProducts,
      revisions: null,
    };
  },
};
