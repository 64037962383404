import _ from 'lodash';

export const flatten = (nestedObject: object): Record<string, any> => {
  let flatObject = {};

  Object.entries(nestedObject).forEach(([key, value]) => {
    const valueType = typeof value;
    if (valueType === 'object' && value !== null) {
      flatObject = _.merge(flatObject, flatten(value));
      return;
    }
    if (valueType === 'function') {
      return;
    }
    flatObject[key] = value;
  });

  return flatObject;
};
