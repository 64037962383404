import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { WorkPackagesData, WorkPackagesPayload } from '../../interfaces';
import { WorkPackagesActionTypes } from '../types';

function loadData(id: number): ActionWith<number> {
  return {
    type: WorkPackagesActionTypes.LOAD_DATA,
    payload: id,
  };
}

function loadDataComplete(
  workpackages: WorkPackagesData.WorkPackage[],
): ActionWith<WorkPackagesData.WorkPackage[]> {
  return {
    type: WorkPackagesActionTypes.LOAD_SUCCESSED,
    payload: workpackages,
  };
}

function setActivities(
  activities: WorkPackagesData.Activity[],
): ActionWith<WorkPackagesData.Activity[]> {
  return {
    type: WorkPackagesActionTypes.SET_ACTIVITIES,
    payload: activities,
  };
}

function setSuccessed(): Action {
  return {
    type: WorkPackagesActionTypes.SET_SUCCESSED,
  };
}

function setRequest(projectId: number, scenarioId: number): ActionWith<WorkPackagesPayload.ProjectScnearioIds> {
  return {
    type: WorkPackagesActionTypes.SET_REQUEST,
    payload: { projectId, scenarioId },
  };
}

function renamePackage(
  id: number,
  value: string,
): ActionWith<any> {
  return {
    type: WorkPackagesActionTypes.RENAME_PACKAGE,
    payload: { id, name: value },
  };
}

function createNewPackage(): Action {
  return {
    type: WorkPackagesActionTypes.CREATE_NEW,
  };
}

function setGrouped(
  grouped: WorkPackagesData.GropedWorkPackage[],
): ActionWith<WorkPackagesData.GropedWorkPackage[]> {
  return {
    type: WorkPackagesActionTypes.SET_GROUPED,
    payload: grouped,
  };
}

function setActive(
  pack: WorkPackagesData.GropedWorkPackage,
): ActionWith<WorkPackagesData.GropedWorkPackage> {
  return {
    type: WorkPackagesActionTypes.SET_ACTIVE,
    payload: pack,
  };
}

function selectGroup(
  groupId: number,
): ActionWith<number> {
  return {
    type: WorkPackagesActionTypes.SELECT_GROUP,
    payload: groupId,
  };
}

function selectAllGroup(
  checked: boolean,
): ActionWith<boolean> {
  return {
    type: WorkPackagesActionTypes.SELECT_ALL_GROUP,
    payload: checked,
  };
}

function setActiveGroup(
  group: WorkPackagesData.GroupOfActivity,
): ActionWith<WorkPackagesData.GroupOfActivity> {
  return {
    type: WorkPackagesActionTypes.SET_ACTIVE_GROUP,
    payload: group,
  };
}

function setActiveWorkPackageById(
  activityId: number,
): ActionWith<number> {
  return {
    type: WorkPackagesActionTypes.SET_ACTIVE_WORKPACKAGE_BY_ID,
    payload: activityId,
  };
}

function selectGroupActivity(
  activityId: number,
): ActionWith<number> {
  return {
    type: WorkPackagesActionTypes.SELECT_GROUP_ACTIVITY,
    payload: activityId,
  };
}

function selectAllGroupActivity(
  checked: boolean,
): ActionWith<boolean> {
  return {
    type: WorkPackagesActionTypes.SELECT_ALL_GROUP_ACTIVITY,
    payload: checked,
  };
}

function replaceGroup(
  idPack: number,
  idGroup: number,
): ActionWith<any> {
  return {
    type: WorkPackagesActionTypes.REPLACE_GROUP,
    payload: {
      idPack,
      idGroup,
    },
  };
}

function replaceActivity(
  packageId: number,
  activityId: number,
): ActionWith<WorkPackagesPayload.ReplaceActivityPayload> {
  return {
    type: WorkPackagesActionTypes.REPLACE_ACTIVITY,
    payload: {
      packageId,
      activityId,
    },
  };
}

function removeWorkpackage(
  id: number,
): ActionWith<number> {
  return {
    type: WorkPackagesActionTypes.REMOVE_WORKPACKAGE,
    payload: id,
  };
}

function dropWorkPackagesState(): Action {
  return {
    type: WorkPackagesActionTypes.DROP_EDIT_WORK_PACKAGES_STATE,
  };
}

function selectActivity(activity: WorkPackagesData.Activity): ActionWith<WorkPackagesData.Activity> {
  return {
    type: WorkPackagesActionTypes.SELECT_ACTIVITY,
    payload: activity,
  };
}

export const WorkPackageAction = {
  selectActivity,
  dropWorkPackagesState,
  removeWorkpackage,
  setGrouped,
  setActive,
  selectGroup,
  selectAllGroup,
  setActiveGroup,
  setActiveWorkPackageById,
  replaceActivity,
  replaceGroup,
  selectAllGroupActivity,
  selectGroupActivity,
  loadDataComplete,
  setActivities,
  setSuccessed,
  setRequest,
  renamePackage,
  createNewPackage,
  loadData,
};
