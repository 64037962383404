import {
  Icons,
  SearchList,
  SmallMenuList,
  Switcher,
  Text,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { ContextMenuItem } from 'common/components/context-menu';
import { ConstantFunctions } from 'common/constants/functions';
import { StringUtils } from 'common/utils/string-utils';
import { Styled } from './styled';
import { TemplateData, TemplatesMenuItem } from './templates-menu-item';

const CONTEXT_MENU_SHIFT_X = 13;
const CONTEXT_MENU_SHIFT_Y = 60;

interface Props<T> {
  disableCreate?: boolean;
  openDialog: (data?: T) => void;
  templates: T[];
  applyTemplate: (id: number) => void;
  updateTemplate: (template: T) => void;
  duplicateTemplate: (template: T) => void;
  deleteTemplate: (id: number) => void;
}

interface ComponentState<T> {
  contextMenu: {
    isOpen: boolean,
    menuPositionX: number,
    menuPositionY: number,
    selectedItem: T,
  };
}

export class TemplatesMenu<T extends TemplateData> extends React.PureComponent<Props<T>, ComponentState<T>> {
  private divRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      contextMenu: {
        isOpen: false,
        menuPositionX: 0,
        menuPositionY: 0,
        selectedItem: null,
      },
    };
  }

  public render(): JSX.Element {
    return (
      <SearchList
        items={this.props.templates}
        itemComponent={this.getItemComponent}
        maxItemsCount={4}
        width={370}
        showNoResult={false}
        getKeyValue={this.getTemplateKey}
        getStringToCompare={this.getTemplateName}
        onClick={ConstantFunctions.doNothing}
      >
        <Styled.MenuItemButton onClick={this.create} ref={this.divRef} disabled={this.props.disableCreate}>
          <Icons.PlusBig />
          <Text fontSize={14} color={'turquoise'}>Create template</Text>
        </Styled.MenuItemButton>
        {this.state.contextMenu.isOpen &&
          <Styled.TemplateMenuContainer
            x={this.state.contextMenu.menuPositionX}
            y={this.state.contextMenu.menuPositionY}
          >
            <SmallMenuList width={220} onExit={this.closeContextMenu}>
              <Styled.TemplateMenuContentWrapper>
                {this.state.contextMenu.selectedItem.isPublic &&
                  <Styled.SwitchWithLabel onClick={this.setDefault}>
                    <Switcher
                      checked={this.state.contextMenu.selectedItem.isDefault}
                      size='m'
                    />
                    <Text fontSize={14}>Use by default</Text>
                  </Styled.SwitchWithLabel>
                }
                <ContextMenuItem icon={Icons.Edit2D} onClick={this.edit}>Edit</ContextMenuItem>
                <ContextMenuItem icon={Icons.Duplicate2D} onClick={this.duplicate}>Duplicate</ContextMenuItem>
                <ContextMenuItem icon={Icons.Delete} onClick={this.delete}>Delete</ContextMenuItem>
              </Styled.TemplateMenuContentWrapper>
            </SmallMenuList>
          </Styled.TemplateMenuContainer>
        }
      </SearchList>
    );
  }

  private getTemplateKey(item: T): number {
    return item.id;
  }

  private getTemplateName(item: T): string {
    return item.name;
  }

  @autobind
  private getItemComponent(item: T): JSX.Element {
    return (
      <TemplatesMenuItem
        itemData={item}
        onApply={this.props.applyTemplate}
        onMenuClick={this.openContextMenu}
      />);
  }

  @autobind
  private openContextMenu(e: React.MouseEvent<HTMLDivElement>, data: T): void {
    const buttonElem = e.currentTarget;
    const scrollContainer = this.divRef.current.previousElementSibling.firstElementChild.firstElementChild;
    this.setState({
      contextMenu: {
        isOpen: true,
        menuPositionX: buttonElem.offsetLeft + CONTEXT_MENU_SHIFT_X,
        menuPositionY: buttonElem.offsetTop + CONTEXT_MENU_SHIFT_Y
          - scrollContainer.scrollTop,
        selectedItem: data,
      },
    });
  }

  @autobind
  private closeContextMenu(): void {
    this.setState({
      contextMenu: {
        isOpen: false,
        menuPositionX: 0,
        menuPositionY: 0,
        selectedItem: null,
      },
    });
  }

  @autobind
  private create(): void {
    this.props.openDialog();
  }

  @autobind
  private edit(): void {
    this.props.openDialog(this.state.contextMenu.selectedItem);
  }

  @autobind
  private setDefault(): void {
    const { isDefault } = this.state.contextMenu.selectedItem;
    this.props.updateTemplate({
      ...this.state.contextMenu.selectedItem,
      isDefault: !isDefault,
    });
    this.closeContextMenu();
  }

  @autobind
  private duplicate(): void {
    const { name } = this.state.contextMenu.selectedItem;
    const allTemplateNames = this.props.templates.map(template => template.name);
    this.props.duplicateTemplate({
      ...this.state.contextMenu.selectedItem,
      name: StringUtils.iterateName(allTemplateNames, `${name} copy`),
      isDefault: false,
    });
    this.closeContextMenu();
  }

  @autobind
  private delete(): void {
    this.props.deleteTemplate(this.state.contextMenu.selectedItem.id);
    this.closeContextMenu();
  }
}
