import autobind from 'autobind-decorator';
import * as React from 'react';

import { KreoIconKeyH, KreoIconKeyI } from 'common/UIKit';
import { MenuItem } from './menu-item';

interface HasSelcedContextType {
  color: any;
  isTint: boolean;
  onIsolate: () => void;
  onHide: () => void;
  onGhost: () => void;
  onShowAll: () => void;
  onFocus: () => void;
  onAssignColor: (color: any) => void;
}

export class HasSelectedContext extends React.Component<HasSelcedContextType> {

  public render(): any {
    return [
      <MenuItem key={6} title={'Isolate'} icons={[<KreoIconKeyI key='i' />]} onClick={this.props.onIsolate} />,
      <MenuItem key={1} title={'Hide'} icons={[<KreoIconKeyH key='h' />]} onClick={this.props.onHide} />,
      <MenuItem key={2} title={'Ghost'} icons={[]} onClick={this.props.onGhost} />,
      (
        <MenuItem key={3} title={'Assign colour'} icons={[]} onClick={this.onAssignColor} >
          <div className='color-circle'>
            <div
              className='color-circle-indicator'
              style={
                {
                  backgroundColor: this.props.color,
                }
              }
            />
          </div>
        </MenuItem >),
      this.props.isTint && (
        <MenuItem key={3} title={'Reset colour'} onClick={this.onResetColor} icons={[]}/>),
    ];
  }

  @autobind
  private onAssignColor(): void {
    this.props.onAssignColor(this.props.color);
  }

  @autobind
  private onResetColor(): void {
    this.props.onAssignColor(null);
  }
}
