import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { SubscriptionType } from 'common/constants/subscription';
import { State as ReduxState } from 'common/interfaces/state';
import { NotificationActions } from './actions';
import { Alerts } from './components/alert-notification/alerts';
import { BellNotificationsPanel } from './components/bell-notifications';

interface ReduxProps {
  userId: string;
  selectedCompanyId: number;
  subscriptionType: SubscriptionType;
}

interface ReduxActions {
  connect: (userId: string) => void;
}

interface Props extends ReduxProps, ReduxActions {}

class NotificationControllerComponent extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Props): boolean {
    // should be in shouldComponentUpdate because componentDidUpdate is not called when props are changed
    if (nextProps.userId && nextProps.subscriptionType && (
      this.props.subscriptionType !== nextProps.subscriptionType
      || nextProps.userId !== this.props.userId
    )) {
      this.props.connect(nextProps.userId);
    }
    return false;
  }


  public componentDidMount(): void {
    if (this.props.userId && this.props.subscriptionType) {
      this.props.connect(this.props.userId);
    }
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        <Alerts />
        <BellNotificationsPanel />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const selectedCompany = state.account.selectedCompany;

  return {
    userId: state.account.id,
    selectedCompanyId: selectedCompany && selectedCompany.id,
    subscriptionType: state.account.selectedSubscriptionType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    connect: userId => {
      dispatch(
        NotificationActions.connectToAbly({
          id: userId,
          namespace: 'user',
        }),
      );
    },
  };
};

export const NotificationController = connect(mapStateToProps, mapDispatchToProps)(
  NotificationControllerComponent,
);
