import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './sequence-edge-creator.scss';

import { IconButton, KreoDialogActions, KreoIconAddedDone, KreoIconCancel } from 'common/UIKit';
import { Direction, GraphItemDisplayType } from '../../../../components/graph-viewer/enums';
import { SEQUENCE_LOOP_WARNING_DIALOG, SequenceLoopWarningDialog } from './sequence-loop-warning-dialog';


interface State {
  loopVertexes: string[];
}

interface ReduxActions {
  openSequenceWarningDialog: () => void;
}

interface Props extends ReduxActions {
  destinationVertexName: string | null;
  rootVertexName: string;
  edgeDirection: Direction;

  onCancel: () => void;
  onApprove: () => void;
  getLoopVertexNames: () => string[];
}

class SequenceEdgeCreatorComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loopVertexes: [],
    };
  }

  public render(): JSX.Element {
    return  (
      <div className='sequence-edge-creator'>
        <div className='sequence-edge-creator__wrap'>
          <div className='sequence-edge-creator__title'>New “Finish-to-Start” Link</div>
          <div
            className={classNames(
              'sequence-edge-creator__content',
              { 'no-selected': !this.props.destinationVertexName },
            )}
          >
            <IconButton
              size='large'
              onClick={this.props.onCancel}
              className='sequence-edge-creator__button sequence-edge-creator__button-cancel'
              data-control-name={'cancel-sequence-edge-creator-button'}
            >
              <KreoIconCancel/>
            </IconButton>
            <div className='sequence-edge-creator__vertex-container sequence-edge-creator__vertex-container--left'>
              {this.renderLeftVertex()}
            </div>
            {this.renderEdge()}
            <div className='sequence-edge-creator__vertex-container sequence-edge-creator__vertex-container--right'>
              {this.renderRightVertex()}
            </div>
            <IconButton
              size='large'
              onClick={this.onApprove}
              className='sequence-edge-creator__button sequence-edge-creator__button-approve'
              data-control-name={'cancel-sequence-edge-creator-approve'}
            >
              <KreoIconAddedDone/>
            </IconButton>
          </div>
        </div>
        <SequenceLoopWarningDialog
          edgeDirection={this.props.edgeDirection}
          loopVertexes={this.state.loopVertexes}
          renderVertex={this.renderVertex}
        />
      </div>
    );
  }

  private renderEdge(): JSX.Element {
    return (
      <div
        className={classNames(
          'sequence-edge-creator__edge',
          { 'sequence-edge-creator__edge--start-finish': this.props.edgeDirection === Direction.Back },
        )}
      >
        <div className='sequence-edge-creator__edge-dot sequence-edge-creator__edge-dot--left'/>
        <div className='sequence-edge-creator__edge-line'/>
        <div className='sequence-edge-creator__edge-dot sequence-edge-creator__edge-dot--right'/>
      </div>
    );
  }

  private renderLeftVertex(): JSX.Element {
    if (this.props.edgeDirection === Direction.Forward) {
      return this.renderVertex(this.props.rootVertexName, GraphItemDisplayType.Active);
    } else {
      return this.renderVertex(this.props.destinationVertexName, GraphItemDisplayType.Previous);
    }
  }

  private renderRightVertex(): JSX.Element {
    if (this.props.edgeDirection === Direction.Back) {
      return this.renderVertex(this.props.rootVertexName, GraphItemDisplayType.Active);
    } else {
      return this.renderVertex(this.props.destinationVertexName, GraphItemDisplayType.Next);
    }
  }

  private renderVertex(name: string, displayType: GraphItemDisplayType): JSX.Element {
    if (name === null) {
      return (
        <div className={`sequence-edge-creator__empty-vertex sequence-edge-creator__empty-vertex--${displayType}`}/>
      );
    } else {
      return (
        <div
          className={`sequence-edge-creator__vertex sequence-edge-creator__vertex--${displayType}`}
          title={name}
        >
          {name}
        </div>
      );
    }
  }

  @autobind
  private onApprove(): void {
    const loopVertexes = this.props.getLoopVertexNames();
    if (loopVertexes.length) {
      this.setState({ loopVertexes });
      this.props.openSequenceWarningDialog();
    } else {
      this.props.onApprove();
    }
  }
}


const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    openSequenceWarningDialog: () => dispatch(KreoDialogActions.openDialog(SEQUENCE_LOOP_WARNING_DIALOG)),
  };
};


const connector = connect(null, mapDispatchToProps);
export const SequenceEdgeCreator = connector(SequenceEdgeCreatorComponent);
