import { Icons, RectangleButton, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


interface Props {
  addComment: () => void;
}

export const EmptyDiscussion: React.FC<Props> = ({ addComment }) => {
  return (
    <Styled.Container>
      <Styled.ImgContainer>
        <img src='/static/img/comments_none.png' />
      </Styled.ImgContainer>
      <Styled.TextContainer>
        <Text>Start a discussion</Text>
      </Styled.TextContainer>
      <Styled.AddComment>
        <RectangleButton
          size='xl'
          ghost={true}
          onClick={addComment}
          Icon={Icons.Comments2D}
          text='Add comment'
          width={150}
          height={30}
        />
      </Styled.AddComment>
    </Styled.Container>
  );
};
