import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './subheader-billing-notice.scss';

import { KreoButton, KreoIconAttention } from 'common/UIKit';
import { AccountActions } from '../../../units/account/actions/creators';

interface NoticeOwnProps {
  text: React.ReactNode;
}

interface NoticeDispatchProps {
  openSelfServePortal: () => void;
}

interface NoticeProps extends NoticeOwnProps, NoticeDispatchProps {}

class SubheaderBillingNoticeComponent extends React.Component<NoticeProps> {
  public render(): React.ReactNode {
    const { text, openSelfServePortal } = this.props;

    return (
      <div className='subheader-billing-notice'>
        <div className='subheader-billing-notice__icon'>
          <KreoIconAttention />
        </div>
        <div className='subheader-billing-notice__text'>
          { text }
        </div>
        <KreoButton
          mode='action'
          caption='Manage Subscription'
          rounded={true}
          onClick={openSelfServePortal}
          controlName='manage-subscription-button'
          size='small'
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): NoticeDispatchProps {
  return {
    openSelfServePortal: () => dispatch(AccountActions.openSelfServePortal()),
  };
}

export const SubheaderBillingNotice = connect(null, mapDispatchToProps)(SubheaderBillingNoticeComponent);
