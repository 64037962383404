import { AssignedPia } from '../../../../../units/2d';
import { DrawingsGeometryGroup } from '../../interfaces/drawings-geometry-group';

const hasAssignedPia = (id: string, assignPiaMap: Record<string, AssignedPia>): boolean => {
  const assignPia = assignPiaMap[id];
  return assignPia && !!(assignPia.assemblies.length || assignPia.items.length);
};


const hasInheritedPia = (
  groupId: string,
  hasAssignedPiaFunc: (id: string) => boolean,
  getParentGroupId: (id: string) => string,
): boolean => {
  let hasInheritedPiaStatus = false;
  while (groupId && !hasInheritedPiaStatus) {
    hasInheritedPiaStatus = hasAssignedPiaFunc(groupId);
    groupId = getParentGroupId(groupId);
  }
  return hasInheritedPiaStatus;
};

const hasInheritedPiaSimple = (
  groupId: string,
  assignPiaMap: Record<string, AssignedPia>,
  groupsMap: Record<string, DrawingsGeometryGroup>,
): boolean => {
  let hasInheritedPiaStatus = false;
  while (groupId && !hasInheritedPiaStatus) {
    hasInheritedPiaStatus = hasAssignedPia(groupId, assignPiaMap);
    const group = groupsMap[groupId];
    groupId = group && group.parentId;
  }
  return hasInheritedPiaStatus;
};

export const DrawingsAssign = {
  hasAssignedPia,
  hasInheritedPia,
  hasInheritedPiaSimple,
};
