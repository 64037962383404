import { AnyAction } from 'redux';

import { PERSISTED_STORAGE_INITIAL_STATE } from '../units/persisted-storage/constants/initial-state';
import { PersistedStorageState } from '../units/persisted-storage/interfaces/state';
import { PersistedStorageReducerMethods } from '../units/persisted-storage/reducer';

export function persistedStorage(
  state: PersistedStorageState = PERSISTED_STORAGE_INITIAL_STATE,
  action: AnyAction,
): PersistedStorageState {
  if (action && action.type in PersistedStorageReducerMethods) {
    return PersistedStorageReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
