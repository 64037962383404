import autobind from 'autobind-decorator';
import * as React from 'react';

import { MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';


interface Props<> {
  value: string;
  onChange: (item: string) => void;
  values: Record<string, string>;
  className?: string;
}


export class CustomElementFilterBuilderOperationSelect extends React.Component<Props> {
  public render(): JSX.Element {
    const { value, values, className } = this.props;

    return (
      <MaterialSelect
        autoWidth={true}
        value={value}
        onChange={this.onChange}
        className={className}
        displayedType={MaterialComponentType.Native}
      >
      {
        Object.entries(values).map(([key, oValue]) => (
          <MaterialMenuItem
            key={oValue}
            value={oValue}
          >
            {key}
          </MaterialMenuItem>
        ))
      }
      </MaterialSelect>
    );
  }

  @autobind
  private onChange(_: React.SyntheticEvent, value: string): void {
    this.props.onChange(value);
  }
}
