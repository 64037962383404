import styled from 'styled-components';

import { TRANSITION_POINT } from '../../../../../constants';


const Wrapper = styled.div`
  animation: replies-slide-top .35s cubic-bezier(0, 0, 1, 1) both;

  @keyframes replies-slide-top {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

const RepliesBlockContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 50%;
  gap: 20px;
  margin: 20px 0;
  cursor: pointer;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    grid-template-columns: 1fr;
    gap: 5px;
  }
`;

export const Styled = {
  Wrapper,
  RepliesBlockContainer,
};
