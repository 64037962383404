import React from 'react';

import { DrawingMarkShapes, DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsColorControl } from 'common/components/drawings/drawings-controls';
import { DrawingsStyleMenuType } from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { ColorList } from 'common/constants/color-list';
import { DrawingsContextMenuBodyHeaderType } from './drawings-context-menu-body';
import { DrawingsContextMenuGeometryStyles } from './drawings-context-menu-geometry-styles';

interface Props {
  headerType?: DrawingsContextMenuBodyHeaderType;
  selectedColor?: string;
  shape?: DrawingMarkShapes;
  colorTitle?: string;
  strokeStyle?: DrawingStrokeStyles;
  styleMenuType?: DrawingsStyleMenuType;
  strokeWidth?: number;
  setColor?: (color: string) => void;
  setStrokeStyle?: (value: DrawingStrokeStyles) => void;
  setStrokeWidth?: (value: number) => void;
  setShape?: (value: DrawingMarkShapes) => void;
}

const StylesHeaderComponent: React.FC<Props> = ({
  headerType,
  selectedColor,
  shape,
  colorTitle,
  strokeStyle,
  styleMenuType,
  strokeWidth,
  setColor,
  setStrokeStyle,
  setStrokeWidth,
  setShape,
}) => {

  if (!headerType || headerType === DrawingsContextMenuBodyHeaderType.Hidden) {
    return null;
  }

  if (headerType === DrawingsContextMenuBodyHeaderType.ColorPicker) {
    return (
      <DrawingsColorControl
        colorList={ColorList}
        selectedColor={selectedColor}
        onColor={setColor}
      />
    );
  } else {
    return (
      <DrawingsContextMenuGeometryStyles
        setShape={setShape}
        shape={shape}
        colorTitle={colorTitle}
        selectedColor={selectedColor}
        setColor={setColor}
        strokeStyle={strokeStyle}
        setStrokeStyle={setStrokeStyle}
        strokeWidth={strokeWidth}
        setStrokeWidth={setStrokeWidth}
        styleMenuType={styleMenuType}
      />
    );
  }
};

export const StylesHeader = React.memo(StylesHeaderComponent);
