import { Item, Property } from 'unit-2d-database/interfaces';
import { getPropertyIterator } from '../../helpers/get-property-iterator';
import { mapPropertyGroupForm } from '../item-panel/helpers/map-property-group-form';
import { ItemForm } from '../item-panel/helpers/property-group-form-updater';
import { IItemUpdater, ItemGroupFormData } from './item-form-updater';

export type GroupForm = Record<string, ItemGroupFormData>;

const getProperties = (properties: Property[], baseProperties: Property[]): Property[] => {
  const propertyMap: Map<string, Property> = new Map<string, Property>();

  baseProperties.forEach(p => {
    propertyMap.set(p.name, p);
  });

  properties.forEach(p => {
    propertyMap.set(p.name, p);
  });

  return Array.from(propertyMap.values());
};

const getItemForm = (
  item: Item,
  itemGroupUpdater: IItemUpdater,
  units: string[],
  onFormulaFieldClick: (itemId: string, id: string, groupName: string) => void,
  onBreakdownFieldClick: (itemId: string, id: string, groupName: string) => void,
  propertiesDatabase: Property[],
  getKey?: (item: Item) => string,
  getPropertyKey?: (property: Property) => string,
  itemDatabase?: Item,
): ItemForm  => {
  const key = getKey ? getKey(item) : item.id;
  const propertyGroupUpdater = itemGroupUpdater.getPropertyGroupUpdater(key);
  const onPropertyFormulaChange = (id: string, groupName: string): void => onFormulaFieldClick(key, id, groupName);
  const onPropertyBreakdownchange = (id: string, groupName: string): void => onBreakdownFieldClick(key, id, groupName);
  const propertiesList = itemDatabase
    ? getProperties(item.properties, itemDatabase.properties)
    : item.properties;
  const propertyGroupForm = mapPropertyGroupForm(
    getPropertyIterator(propertiesList, propertiesDatabase),
    units,
    propertyGroupUpdater,
    onPropertyFormulaChange,
    onPropertyBreakdownchange,
    getPropertyKey,
  );
  return {
    addedProperty: {},
    removedProperty: {},
    groupForm: propertyGroupForm,
  };
};

export const mapItemGroupForm = (
  items: Item[],
  itemGroupUpdater: IItemUpdater,
  units: string[],
  onFormulaFieldClick: (itemId: string, id: string, groupName: string) => void,
  onBreakdownFieldClick: (itemId: string, id: string, groupName: string) => void,
  propertiesDatabase: Property[],
  itemsDatabase: Item[],
  getKey?: (item: Item) => string,
  getPropertyKey?: (property: Property) => string,
  isSkipBaseItem?: boolean,
): GroupForm => {
  const groups: GroupForm = {};
  for (const item of items) {
    const key = getKey ? getKey(item) : item.id;
    const baseItem = isSkipBaseItem
      ? undefined
      : itemsDatabase?.find(i => i.id === item.baseItemId);
    const itemForm = getItemForm(
      item,
      itemGroupUpdater,
      units,
      onFormulaFieldClick,
      onBreakdownFieldClick,
      propertiesDatabase,
      getKey,
      getPropertyKey,
      baseItem,
    );
    const [ onDelete ] = itemGroupUpdater.getItemHandlers(key);
    const group: ItemGroupFormData = {
      id: key,
      originId: item.baseItemId,
      name: item.name,
      iconType: item.iconType,
      itemForm,
      onDelete,
    };
    groups[key] = group;
  }
  return groups;
};
