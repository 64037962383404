import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

interface TimeframeHandleProps {
  className: string;
  offset: number;
  onDrag: (newOffset: number) => void;
  onDragStart: () => void;
  onDragEnd: () => void;
  setCursorOnHandleStatus: (isOnHandle: boolean) => void;
}

interface TimeframeHandleState {
  isDragged: boolean;
  delta: number;
}

export class TimeframeHandle extends React.Component<TimeframeHandleProps, TimeframeHandleState> {
  constructor(props: TimeframeHandleProps) {
    super(props);

    this.state = {
      isDragged: false,
      delta: null,
    };
  }

  public render(): React.ReactNode {
    const className = classNames('handle', this.props.className);

    return (
      <div
        className={className}
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      />
    );
  }

  @autobind
  private onMouseDown(event: React.MouseEvent<HTMLDivElement>): void {
    if (event.button !== 0) {
      return;
    }

    this.setState({
      isDragged: true,
      delta: event.pageX - this.props.offset,
    });
    this.addListeners();
    this.props.onDragStart();
  }

  private addListeners(): void {
    document.addEventListener('mousemove', this.onMouseMove as any);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  private removeListeners(): void {
    document.removeEventListener('mousemove', this.onMouseMove as any);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  @autobind
  private onMouseUp(): void {
    this.setState({
      isDragged: false,
      delta: null,
    });
    this.removeListeners();
    this.props.onDragEnd();
  }

  @autobind
  private onMouseMove(event: React.MouseEvent<HTMLDocument>): void {
    if (this.state.isDragged) {
      const newOffset = event.pageX - this.state.delta;
      this.props.onDrag(newOffset);
    }
  }

  @autobind
  private onMouseEnter(): void {
    this.props.setCursorOnHandleStatus(true);
  }

  @autobind
  private onMouseLeave(): void {
    this.props.setCursorOnHandleStatus(false);
  }
}
