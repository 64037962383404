import { useCallback } from 'react';
import { useActionDispatch } from 'common/hooks';
import { MagicSearchActions } from '../../actions/creators/magic-search';
import { DrawingsCanvasConstants } from '../../constants';
import { DrawingStrokeStyles } from '../../constants/drawing-styles';
import { CollectionsUnifier } from '../../drawings-geometry/drawings-helpers/collections-unifier';
import { NewDrawingSettings } from '../../drawings-geometry/interfaces';
import { DrawingsDrawMode } from '../../enums';
import {
  useDrawModeApi,
  useInstances,
  usePoints,
  useShowCalibrationDialogIfNeed,
  useUpdateNewInstanceSettings,
} from '../../hooks';
import { DrawingsPolygonGeometry } from '../../interfaces';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';

export function useStartMagicSearch(instancesIds: string[]): () => void {
  const showCalibration = useShowCalibrationDialogIfNeed();
  const instances = useInstances();
  const points = usePoints();
  const setContoursToMagicSearch = useActionDispatch(MagicSearchActions.setContoursToSearch);
  const { setDrawMode } = useDrawModeApi();
  const updateNewInstanceSettings = useUpdateNewInstanceSettings();

  return useCallback(() => {
    showCalibration(() => {
      const contours = [];
      const unifier = new CollectionsUnifier<Partial<NewDrawingSettings>>();
      for (const id of instancesIds) {
        const instance = instances[id];
        const geometry = instance.geometry as DrawingsPolygonGeometry;
        unifier.add({
          strokeStyle: geometry.strokeStyle,
          strokeWidth: geometry.strokeWidth,
          polygonHeight: geometry.height,
          color: geometry.color,
          offset: geometry.offset,
          name: instance.name,
        });
        contours.push(instance.geometry.points.map((pointId) => points[pointId]));
      }
      setDrawMode(DrawingsDrawMode.MagicSearch, {
        prevSave: () => {
          setContoursToMagicSearch(contours);
        },
        afterSave: () => {
          updateNewInstanceSettings(unifier.getUnifiedOrDefault({
            strokeStyle: DrawingStrokeStyles.Normal,
            strokeWidth: DrawingsCanvasConstants.lineStroke,
            color: DrawingsCanvasUtils.getColorFromList(),
            polygonHeight: undefined,
            offset: undefined,
            name: 'Polygon',
          }));
        },
      });
    });
  }, [
    showCalibration,
    instances,
    setDrawMode,
    instancesIds,
    points,
    setContoursToMagicSearch,
    updateNewInstanceSettings,
  ]);
}
