import { Icons, SmallMenuList } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ContextMenuItem } from 'common/components/context-menu';
import { PropertyTypeEnum, MenuName, SidePanelExtraProps } from 'unit-2d-database/interfaces';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { Styled } from './styled';

export interface IMenuProperties {
  icon: React.ComponentType;
  text: string;
  onClick: () => void;
}

interface DispatchToProps {
  onClickAdd: (type: PropertyTypeEnum, extraProps?: SidePanelExtraProps) => void;
}

interface OwnProps {
  closeContextMenu: () => void;
  onClick?: () => void;
  extraProperties?: IMenuProperties[];
  propertyGroupName?: string;
}

interface Props extends DispatchToProps, OwnProps {
}

class MenuPropertiesComponent extends React.PureComponent<Props> {
  private menuProperties: IMenuProperties[] = [
    {
      icon: Icons.TextDataBase,
      text: 'Text',
      onClick: this.onClick(PropertyTypeEnum.Text),
    },
    {
      icon: Icons.Number,
      text: 'Number',
      onClick: this.onClick(PropertyTypeEnum.Number),
    },
    {
      icon: Icons.Formula,
      text: 'Formula',
      onClick: this.onClick(PropertyTypeEnum.Formula),
    },
    {
      icon: Icons.SingleSelect,
      text: 'Single select',
      onClick: this.onClick(PropertyTypeEnum.SingleSelect),
    },
    {
      icon: Icons.Breakdown,
      text: 'Breakdown',
      onClick: this.onClick(PropertyTypeEnum.Breakdown),
    },
  ];

  public render(): JSX.Element {
    const { closeContextMenu } = this.props;
    const menuProperties = this.getMenuProperties();

    return (
      <Styled.Container>
        <SmallMenuList width={220} onExit={closeContextMenu}>
          {menuProperties.map(item => (
            <ContextMenuItem
              key={item.text}
              icon={item.icon}
              onClick={item.onClick}
            >
              {item.text}
            </ContextMenuItem>
          ))}
        </SmallMenuList>
      </Styled.Container>
    );
  }

  @autobind
  private onClick(type: PropertyTypeEnum): () => void {
    return () => {
      const extraProperty = {
        defaultPropertyGroup: this.props.propertyGroupName,
      };
      this.props.onClickAdd(type, extraProperty);
      this.props.closeContextMenu();
    };
  }

  private getMenuProperties(): IMenuProperties[] {
    const { closeContextMenu, extraProperties } = this.props;
    if (extraProperties) {
      const extraMenu = extraProperties.map(p => {
        return ({
          ...p,
          onClick: () => {
            p.onClick();
            closeContextMenu();
          },
        });
      });
      return [...extraMenu, ...this.menuProperties];
    }

    return this.menuProperties;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchToProps => {
  return {
    onClickAdd: (type, extraProps) => {
      if (props.onClick) {
        props.onClick();
      }
      dispatch(TwoDDatabaseActions.setPropertyPanel({ type, data: null }));
      dispatch(TwoDDatabaseActions.openSidePanel({ type: MenuName.Properties, withSideBlock: false, extraProps }));
    },
  };
};

export const MenuProperties = connect(null, mapDispatchToProps)(MenuPropertiesComponent);
