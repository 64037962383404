
import styled from 'styled-components';
import { InputSmallStyled } from 'common/components/inputs';

const Container = styled.div`
  display: flex;
  > ${InputSmallStyled.InputStyle} {
    border-radius: 5px 0 0 5px;
  }

  > ${InputSmallStyled.InputStyle} + ${InputSmallStyled.InputStyle} {
    border-radius: 0 5px 5px 0;
    width: 5ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Styled = {
  Container,
};
