import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './resource-limitations-tile.scss';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { KreoIconInfoBigColor, MaterialInput } from 'common/UIKit';
import { HelpTooltip } from '../../../../components/controls/tooltips';
import {
  ResourceLimitationsMenuType,
  ScenariosActions,
  WorkPackagesData,
} from '../../../scenarios';

interface ReduxActions {
  setNewValue: (item: WorkPackagesData.Limitation, value: number, field: string) => void;
}

interface Props extends ReduxActions, I18nAwareProps {
  item: WorkPackagesData.Limitation;
  onDelete?: (id: number) => void;
  activeMenu: ResourceLimitationsMenuType;
}

interface State {
  rateValue: string;
  quantityValue: string;
}

class ResourceLimitationsTileComponent extends React.Component<Props, State> {

  private isError: boolean = false;
  private errorText: string = '';

  constructor(props: Props) {
    super(props);

    this.state = {
      rateValue: props.item.cost !== null ? props.item.cost.toString() : '',
      quantityValue: props.item.maximum ? props.item.maximum.toString() : '',
    };
  }

  public render(): JSX.Element {
    const { item, activeMenu } = this.props;
    return (
      <div className='resource-limitations-tile'>
        <span className='resource-limitations-tile__title' title={item.name}>{item.name}</span>
        <div className='resource-limitations-tile__fields-line'>
          <MaterialInput
            name={`${item.id}.quantity`}
            value={this.state.quantityValue}
            className='resource-limitations-tile__input'
            label='Max quantity'
            valueType='float'
            onChange={this.changeQuantity}
            onBlur={this.blurQuantity}
            isFloatingLabel={false}
            error={this.isError ? this.errorText : undefined}
            displayBottomInformation={true}
          />
          <div className='resource-limitations-tile__input'>
            <HelpTooltip
              icon={<KreoIconInfoBigColor />}
              text={`${ResourceLimitationsMenuType[activeMenu]} rate per hour`}
              id={`${item.id}_limitations_rate`}
              className='resource-limitations-tile__input-tooltip'
            />
            <MaterialInput
              name={`${item.id}.rate`}
              value={this.state.rateValue}
              label='Rate'
              valueType='float'
              displayPattern={this.pattern}
              onChange={this.changeRate}
              onBlur={this.blurRate}
              isFloatingLabel={false}
              className='resource-limitations-tile__text-field'
              required={true}
            />
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private pattern(val: string): string {
    return `${this.props.i18n.currency.symbol} ${val}`;
  }

  @autobind
  private blurQuantity(_event: React.ChangeEvent, value: string): void {
    let parsedValue = parseFloat(value);
    const isNanValue = isNaN(parsedValue);
    if (parsedValue < this.props.item.minimumLimit) {
      parsedValue = this.props.item.minimumLimit;
      if (this.isError) {
        this.isError = false;
        this.errorText = '';
      }
    } else if (isNanValue) {
      parsedValue = null;
      value = '';
      this.setState({ quantityValue: value });
    }
    if (!isNanValue) {
      this.setState({ quantityValue: parsedValue.toString() });
    }
    if (this.props.item.maximum !== parsedValue) {
      this.props.setNewValue(this.props.item, parsedValue, 'maximum');
    }
  }

  @autobind
  private changeQuantity(_event: React.ChangeEvent, value: string): void {
    const parsedValue = parseFloat(value);
    const isNanValue = isNaN(parsedValue);
    if (!parsedValue || parsedValue < this.props.item.minimumLimit) {
      this.isError = true;
      this.errorText = `${this.props.item.minimumLimit} is minimum`;
    } else if (this.isError) {
      this.isError = false;
    }
    if (isNanValue) {
      this.isError = false;
      this.errorText = '';
      value = '';
      this.setState({ quantityValue: value });
    }
    if (!isNanValue) {
      this.setState({ quantityValue: value });
    }
  }

  @autobind
  private blurRate(_event: React.ChangeEvent, value: string): void {
    const parsedValue = parseFloat(value);

    if (this.props.item.cost !== parsedValue) {
      this.props.setNewValue(this.props.item, isNaN(parsedValue) ? 0 : parsedValue, 'cost');  // send correct name
    }
  }

  @autobind
  private changeRate(_event: React.ChangeEvent, value: string): void {
    this.setState({ rateValue: value });
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    setNewValue: (item: WorkPackagesData.Limitation, value: number, field: string): void => {
      dispatch(ScenariosActions.changeLimitationValue({
        id: item.id,
        resourceManagementId: item.resourceManagementId,
        fieldKey: field,
        value,
      }));
    },
  };
};

const connector = connect(null, mapDispatchToProps);
export const ResourceLimitationsTile = connector(withI18n(ResourceLimitationsTileComponent));
