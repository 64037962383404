import { RequestStatus } from 'common/enums/request-status';
import { Feed } from 'common/interfaces/feed';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { ChangeUserEmail, EnableUserParams, UpdateAttribute } from '../actions/payloads/people';
import { AdminPeopleActionTypes } from '../actions/types/people';
import { FeedParams } from '../interfaces/feed';
import { User } from '../interfaces/user';
import { AdminPeopleState } from '../pages/people/interfaces';

export const adminPeopleReducerMethods: ReducerMethods<AdminPeopleState> = {
  [AdminPeopleActionTypes.LOAD_REQUEST]: (state, payloads: FeedParams) => {
    const helper = new MonoliteHelper(state);

    if (state.data && payloads.skip < state.data.length) {
      helper.set((_) => _.data, []);
    }

    return helper
      .set((_) => _.status, RequestStatus.Loading)
      .get();
  },
  [AdminPeopleActionTypes.LOAD_SUCCEEDED]: (state, payload: Feed<User>) => {
    const helper = new MonoliteHelper(state);

    return helper
      .set((_) => _.data, [...state.data, ...payload.data])
      .set((_) => _.count, payload.count)
      .set((_) => _.status, RequestStatus.Loaded)
      .get();
  },
  [AdminPeopleActionTypes.DELETE_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.deleteUser, RequestStatus.Loading)
      .get();
  },
  [AdminPeopleActionTypes.DELETE_SUCCEEDED]: (state, userId: string) => {
    const newData = state.data.filter((u) => u.guid !== userId);

    return new MonoliteHelper(state)
      .set((_) => _.data, newData)
      .set((_) => _.count, newData.length)
      .set((_) => _.statuses.deleteUser, RequestStatus.Loaded)
      .get();
  },
  [AdminPeopleActionTypes.ENABLE_USER_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.statuses.enableUser, RequestStatus.Loading)
      .get();
  },
  [AdminPeopleActionTypes.ENABLE_USER_SUCCEEDED]: (state, payload: EnableUserParams) => {
    const helper = new MonoliteHelper(state);
    const userIndex = state.data.findIndex((item) => item.guid === payload.guid);

    if (userIndex >= 0) {
      helper.set((_) => _.data[userIndex].enabled, payload.enable);
    }

    return helper
      .set((_) => _.statuses.enableUser, RequestStatus.Loaded)
      .get();
  },
  [AdminPeopleActionTypes.UPDATE_USER_ATTRIBUTE_SUCCEED]: (state, payload: UpdateAttribute) => {
    const index = state.data.findIndex((u) => u.guid === payload.userId);
    const helper = new MonoliteHelper(state);
    if (index >= 0) {
      const attrIndex = state.data[index].attributes.findIndex((a) => a.key === payload.key);
      if (attrIndex >= 0) {
        helper.set((_) => _.data[index].attributes[attrIndex].value, payload.value);
      }
    }

    return helper.get();
  },
  [AdminPeopleActionTypes.CHANGE_USER_EMAIL]: (state, payload: ChangeUserEmail) => {
    const index = state.data.findIndex(user => user.guid === payload.userId);
    return new MonoliteHelper(state)
      .set((_) => _.data[index].email, payload.email)
      .get();
  },
};
