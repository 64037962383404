import styled from 'styled-components';

import { PdfPagePreviewStyled, PdfPagePreviewWithBubbleStyled } from 'common/components/drawings/components';
import { ToggleButtonStyled } from 'common/components/toggle-button';

const Container = styled.div`
  display: grid;
  grid-template-columns: 60px minmax(65px,1fr) auto;
  padding: 0 10px;
  margin-top: 10px;
  height: 40px;
  box-sizing: border-box;

  ${PdfPagePreviewStyled.Container} {
    width: 60px;
    height: 40px;
    box-shadow: 0px 1px 4px #0000001F;
    border-radius: 10px;
  }

  ${PdfPagePreviewWithBubbleStyled.Container} {
    height: auto;
  }

  ${PdfPagePreviewWithBubbleStyled.PageBubble} {
    right: 3px;
    bottom: 3px;
    height: 16px;

    > p {
      font-size: 10px;
    }
  }

  ${p => p.onClick && 'cursor: pointer;'}
`;


const TitlesContainer = styled.div<{ canResize: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;

  p {
    max-width: 100%;
  }
`;

const Buttons = styled.div`
  margin-left: auto;
  align-items: center;
  height: 100%;
  display: flex;
  gap: 5px;
  margin-left: 2px;

  ${ToggleButtonStyled.VisibleToggle} {
    padding-right: 0;
  }
`;

const ResizeButton = styled.div`
  display: flex;
  align-items: center;

  > button {
    padding: 3px 8px;
  }
`;

export const Styled = {
  Container,
  TitlesContainer,
  Buttons,
  ResizeButton,
};
