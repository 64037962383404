import autobind from 'autobind-decorator';
import * as React from 'react';
import { KreoIconAddScreen, KreoIconDelBasket } from '../icons';

import { ImageMessageApi } from '../interfaces';
import './image-message.scss';
import { newLineFormatter } from './utils/new-line-formatter';

interface Props {
  name?: string;
  maxViewRows?: number;
  placeholder?: string;
  startViewLines?: number;
  getAPI?: (api: ImageMessageApi) => void;
  onChange?: (value: string) => void;
  onImage?: (e: React.MouseEvent<SVGSVGElement>) => void;
  onChangeImage?: (image: string) => void;
  onRemoveImage?: (e: React.MouseEvent<SVGElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
}

interface State {
  height: number;
}

class ImageMessage extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    maxViewRows: 4,
    placeholder: 'Message',
    startViewLines: 1,
    getAPI: (_api: ImageMessageApi) => null,
    onChange: (_value: string) => null,
    onChangeImage: (_image: string) => null,
    onRemoveImage: () => null,
    onKeyUp: () => null,
    onImage: () => null,
    autoFocus: false,
  };

  private image: string;
  private ref: HTMLTextAreaElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      height: 24 * this.props.startViewLines,
    };
  }


  public componentDidMount(): void {
    const api = { clear: this.clear, setImage: this.setImage };
    this.props.getAPI(api);
  }

  public render(): any {
    return (
      <div className='kreo-image-message' tabIndex={0}>
        {this.image ?
          <div style={{ position: 'relative', display: 'block' }}>
            <img src={`data:image/png;base64, ${this.image}`} />
            <div
              className='kreo-image-message__delete-btn'
              onClick={this.onRemoveImage}
              data-control-name='delete-image-button'
            >
              <KreoIconDelBasket />
            </div>
          </div>
          :
          <KreoIconAddScreen
            className='kreo-image-message__add-screen-btn'
            onClick={this.props.onImage}
            controlName='add-screen-button'
          />
        }
        <textarea
          autoFocus={this.props.autoFocus}
          onKeyUp={this.props.onKeyUp}
          placeholder={this.props.placeholder}
          name={this.props.name}
          style={{ height: this.state.height }}
          onChange={this.onChange}
          ref={this.saveRef}
        />
      </div>
    );
  }

  @autobind
  private saveRef(ref: HTMLTextAreaElement): void {
    this.ref = ref;
  }

  @autobind
  private onRemoveImage(): any {
    this.setImage(undefined);
  }

  @autobind
  private onChange(e: any): void {
    const maxViewRows = this.props.maxViewRows;
    const startViewLines = this.props.startViewLines;
    const value = e.target.value;
    const lines = value.split(/\r|\r\n|\n/);
    const count = lines.length;

    if (count <= maxViewRows) {
      if (count <= startViewLines) {
        this.setState({ height: startViewLines * 24 });
      } else {
        this.setState({ height: count * 24 });
      }
    }
    this.props.onChange(newLineFormatter(value));
  }

  @autobind
  private clear(): void {
    this.ref.value = '';
    this.image = undefined;
    this.props.onChangeImage(undefined);
  }

  @autobind
  private setImage(image: string): void {
    this.image = image;
    this.props.onChangeImage(image);
    this.forceUpdate();
  }
}


export default ImageMessage;
