import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
`;

const ScaleNoAppliedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  > button {
        padding: 3px 10px;
    width: fit-content;
    height: fit-content;
    p {
      line-height: 1;
      font-weight: 500;
    }
  }
`;

const ScaleMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
`;

export const Styled = {
  Container,
  ScaleNoAppliedContainer,
  ScaleMenuContainer,
};
