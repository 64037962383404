import classNames from 'classnames';
import * as React from 'react';

import './material-tile-menu-item.scss';


interface ItemProps {
  label: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  isActive?: boolean;
  isCritical?: boolean;
  isDisabled?: boolean;
  icon?: React.ReactNode;
}

export class MaterialTileMenuItem extends React.Component<ItemProps> {
  public render(): React.ReactNode {
    const { label, onClick, icon, isDisabled, isActive, isCritical } = this.props;
    return (
      <div
        className={classNames(
          'material-tile-menu-item',
          {
            'material-tile-menu-item--active': isActive,
            'material-tile-menu-item--critical': isCritical,
            'material-tile-menu-item--disabled': isDisabled,
          })}
        onClick={isDisabled ? null : onClick}
      >
        <div className='material-tile-menu-item__label'>
          {label}
        </div>
        { icon }
      </div>
    );
  }
}
