import * as t from 'io-ts';

import { IsoDateStringC } from 'common/codecs/iso-date-string';
import { CommentPointTypeC } from './comment-point-type';
import { CommentStatusC } from './comment-status';
import { CommentTypeC } from './comment-type';


interface SubCommentModelBase {
  id: number;
  text?: string;
  authorName?: string;
  authorGuid?: string;
  authorAvatar?: string;
  screen?: string;
  engineState?: string;
}

interface SubCommentModelInput extends SubCommentModelBase {
  created: string;
  subComments: SubCommentModelInput[];
}

interface SubCommentModelOutput extends SubCommentModelBase {
  created: Date;
  subComments: SubCommentModelOutput[];
}

export const SubCommentModelC = t.recursion<SubCommentModelOutput, SubCommentModelInput>(
  'SubCommentModel',
  self => t.intersection([
    t.type({
      id: t.number,
      created: IsoDateStringC,
      subComments: t.array(self),
    }),
    t.partial({
      authorName: t.string,
      authorGuid: t.string,
      authorAvatar: t.string,
      screen: t.string,
      text: t.string,
      engineState: t.string,
    }),
  ]),
);
export type SubCommentModel = t.TypeOf<typeof SubCommentModelC>;

export const CommentModelC = t.intersection(
  [
    t.type({
      id: t.number,
      bimHandleIds: t.array(t.number),
      type: CommentTypeC,
      status: CommentStatusC,
      data: SubCommentModelC,
      owner: t.string,
      created: IsoDateStringC,
      pointType: CommentPointTypeC,
      engineState: t.string,
      pointPosition: t.string,
      pointSerialNumber: t.number,
      lastRead: IsoDateStringC,
    }),
    t.partial({
      selected: t.boolean,
    }),
  ],
  'CommentModel');

export const CommentsC = t.array(CommentModelC);
export type CommentModel = t.TypeOf<typeof CommentModelC>;

