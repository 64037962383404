import React, { useCallback } from 'react';

import { MetricNames, useAnalytics } from 'utils/posthog';
import { ConfirmationDialog } from '../../../../../components/dialog/confirmation-dialog';
import { ConsequencesCancelSubscription } from './cancel-subscription-consequences';
import { Styled } from './styled';

export const CONSEQUENCES_CANCEL_SUBSCRIPTION_DIALOG = 'CONSEQUENCES_CANCEL_SUBSCRIPTION_DIALOG';

interface Props {
  onCancelSubscriptionClick?: () => void;
  onCancel: () => void;
}


export const ConsequencesCancelSubscriptionDialog: React.FC<Props> = ({
  onCancelSubscriptionClick,
  onCancel,
}) => {
  const { sendEvent } = useAnalytics();

  const handleClose = useCallback(() => {
    sendEvent(MetricNames.trial.cancelledConsequencesWindowOpen);
    onCancel();
  }, [onCancel, sendEvent]);

  return (
    <ConfirmationDialog
      name={CONSEQUENCES_CANCEL_SUBSCRIPTION_DIALOG}
      title={''}
      text={''}
      confirmButtonText="Cancel anyway"
      cancelButtonText="Do not cancel subscription"
      onConfirm={onCancelSubscriptionClick}
      onCancel={handleClose}
      ExtraStyled={Styled.Container}
      className={'cancel-subscription-consequences-dialog'}
    >
      <ConsequencesCancelSubscription />
    </ConfirmationDialog>
  );
};
