import React from 'react';
import { DatabaseEntityModifyPanel } from '../database-entity-modify-panel';
import { CreateDatabaseResourceLabor } from './create-database-resource-labor';
import { CreateDatabaseResourceLaborVariant } from './create-database-resource-labor-variant';
import { EditDatabaseResourceLabor } from './edit-database-resource-labor';
import { EditDatabaseResourceLaborVariant } from './edit-database-resource-labor-variant';


export class DatabaseResourceLaborModifyPanel extends React.Component {
  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyPanel
        createRootComponent={CreateDatabaseResourceLabor}
        createVariantComponent={CreateDatabaseResourceLaborVariant}
        editRootComponent={EditDatabaseResourceLabor}
        editVariantComponent={EditDatabaseResourceLaborVariant}
      />
    );
  }
}
