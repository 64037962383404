import { AnyAction } from 'redux';
import { SagaIterator, Task } from 'redux-saga';
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { DatadogLogger } from 'common/environment/datadog-logger';
import { arrayUtils } from 'common/utils/array-utils';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { DrawingsUserAnnotationActions } from '../actions/creators/user-annotation';
import {
  DrawingsAddImages,
  DrawingsAddRulers,
  DrawingsAddStickers,
  DrawingsAnnotationsChangeColors,
  DrawingsAnnotationsPosition,
  DrawingsDeleteAnnotations,
  DrawingsDeleteAnnotationsPayload,
} from '../actions/payloads/user-annotation';
import { DrawingsUserAnnotationActionTypes } from '../actions/types/user-annotation';
import { DrawingsApi } from '../api/drawings-api';
import { DrawingsState } from '../interfaces/drawings-state';
import {
  DrawingUserAnnotationImage,
  DrawingUserAnnotationRuler,
  DrawingUserAnnotationSticker,
  DrawingUserAnnotationsType,
} from '../interfaces/drawings-user-annotation';

interface DrawingUpdatesState {
  addedStickers: Record<string, DrawingUserAnnotationSticker>;
  addedRulers: Record<string, DrawingUserAnnotationRuler>;
  addedImages: Record<string, DrawingUserAnnotationImage>;

  removed: DrawingsDeleteAnnotations;

  updatedStickers: Record<string, DrawingUserAnnotationSticker>;
  updatedRulers: Record<string, DrawingUserAnnotationRuler>;
  updatedImages: Record<string, DrawingUserAnnotationImage>;
}


function createClearDrawingUpdateState(): DrawingUpdatesState {
  return {
    addedStickers: {},
    addedRulers: {},
    addedImages: {},

    removed: {
      deletedRulers: [],
      deletedStickers: [],
      deletedSvgs: [],
    },
    updatedStickers: {},
    updatedRulers: {},
    updatedImages: {},
  };
}

type UpdatesState = Record<string, DrawingUpdatesState>;
type UpdateMethod = (updates: UpdatesState, payload: any, state: DrawingsState) => UpdatesState;
type KeysOfType<T, TProp> = { [P in keyof T]: T[P] extends TProp? P : never }[keyof T];

function* colorChangesIdIterator(changes: DrawingsAnnotationsChangeColors[]): IterableIterator<string> {
  for (const { ids } of changes) {
    for (const id of ids) {
      yield id;
    }
  }
}

function* addStickers(pdfId: string, drawingId: string, stickers: DrawingUserAnnotationSticker[]): SagaIterator {
  try {
    if (stickers.length) {
      const creatorInfo = yield call(DrawingsApi.addStickers, pdfId, drawingId, stickers);
      yield put(DrawingsUserAnnotationActions.addStickerSuccuss(creatorInfo, stickers));
    }
  } catch (error) {
    console.error(console.error('error on save stickers', error));
  }
}

function* addRulers(pdfId: string, drawingId: string, rulers: DrawingUserAnnotationRuler[]): SagaIterator {
  try {
    if (rulers.length) {
      yield call(DrawingsApi.addRulers, pdfId, drawingId, rulers);
    }
  } catch (error) {
    console.error('error on save rulers', error);
  }
}

function* addImages(pdfId: string, drawingId: string, images: DrawingUserAnnotationImage[]): SagaIterator {
  try {
    if (images.length) {
      yield call(DrawingsApi.addImages, pdfId, drawingId, images);
    }
  } catch (error) {
    console.error('error on save images', error);
  }
}

function* updateStickers(pdfId: string, drawingId: string, stickers: DrawingUserAnnotationSticker[]): SagaIterator {
  try {
    if (stickers.length) {
      yield call(DrawingsApi.updateStickers, pdfId, drawingId, stickers);
    }
  } catch (error) {
    console.error(console.error('error on save stickers', error));
  }
}

function* updateRulers(pdfId: string, drawingId: string, rulers: DrawingUserAnnotationRuler[]): SagaIterator {
  try {
    if (rulers.length) {
      yield call(DrawingsApi.updateRulers, pdfId, drawingId, rulers);
    }
  } catch (error) {
    console.error('error on save rulers', error);
  }
}

function* updateImages(pdfId: string, drawingId: string, images: DrawingUserAnnotationImage[]): SagaIterator {
  try {
    if (images.length) {
      yield call(DrawingsApi.updateImages, pdfId, drawingId, images);
    }
  } catch (error) {
    console.error('error on save images', error);
  }
}

function addToCollection<T extends DrawingUserAnnotationsType>(
  updates:  UpdatesState,
  drawingId: string,
  collection: T[],
  addCollectionKey: KeysOfType<DrawingUpdatesState, Record<string, T>>,
): UpdatesState {
  updates[drawingId] = updates[drawingId] || createClearDrawingUpdateState();
  for (const sticker of collection) {
    updates[drawingId][addCollectionKey][sticker.id] = sticker;
  }
  return updates;
}

function clearUpdateForCollection<T>(
  updates:  UpdatesState,
  drawingId: string,
  idsForRemove: string[],
  addCollectionKey: KeysOfType<DrawingUpdatesState, Record<string, T>>,
  updateCollectionKey: KeysOfType<DrawingUpdatesState, Record<string, T>>,
  removedKey: keyof DrawingsDeleteAnnotations,
): UpdatesState {
  for (const id of idsForRemove) {
    if (id in updates[drawingId][addCollectionKey]) {
      delete updates[drawingId][addCollectionKey][id];
    } else {
      if (id in updates[drawingId][updateCollectionKey]) {
        delete updates[drawingId][updateCollectionKey][id];
      }
      updates[drawingId].removed[removedKey].push(id);
    }
  }
  return updates;
}

function updateAnnotations(updates: UpdatesState, ids: Iterable<string>, state: DrawingsState): UpdatesState {
  const { userAnnotations: { currentDrawingId, stickers, rulers, images } } = state;
  updates[currentDrawingId] = updates[currentDrawingId] || createClearDrawingUpdateState();
  for (const id of ids) {
    if (id in stickers) {
      if (id in updates[currentDrawingId].addedStickers) {
        updates[currentDrawingId].addedStickers[id] = stickers[id];
      } else {
        updates[currentDrawingId].updatedStickers[id] = stickers[id];
      }
    } else if (id in rulers) {
      if (id in updates[currentDrawingId].addedRulers) {
        updates[currentDrawingId].addedRulers[id] = rulers[id];
      } else {
        updates[currentDrawingId].updatedRulers[id] = rulers[id];
      }
    } else if (id in images) {
      if (id in updates[currentDrawingId].addedImages) {
        updates[currentDrawingId].addedImages[id] = images[id];
      } else {
        updates[currentDrawingId].updatedImages[id] = images[id];
      }
    }
  }
  return updates;
}

function updatePositions(
  updates: UpdatesState,
  changes: DrawingsAnnotationsPosition[],
  state: DrawingsState,
): UpdatesState {
  return updateAnnotations(updates, arrayUtils.mapIterator(changes, x => x.id), state);
}

function updateAnnotation(
  updates: UpdatesState,
  changes: { id: string },
  state: DrawingsState,
): UpdatesState {
  return updateAnnotations(updates, [changes.id], state);
}

export const UpdateChangesMethods: Record<string, UpdateMethod> = {
  [DrawingsUserAnnotationActionTypes.ADD_STICKER]: (updates, { drawingId, stickers }: DrawingsAddStickers) => {
    return addToCollection(updates, drawingId, stickers, 'addedStickers');
  },
  [DrawingsUserAnnotationActionTypes.ADD_RULERS]: (updates, { drawingId, rulers }: DrawingsAddRulers) => {
    return addToCollection(updates, drawingId, rulers, 'addedRulers');
  },
  [DrawingsUserAnnotationActionTypes.ADD_IMAGES]: (updates, { drawingId, images }: DrawingsAddImages) => {
    return addToCollection(updates, drawingId, images, 'addedImages');
  },
  [DrawingsUserAnnotationActionTypes.REMOVE_INSTANCES_FROM_STATE]: (
    updates,
    { drawingId, deletedAnnotations }: DrawingsDeleteAnnotationsPayload,
  ) => {
    if (drawingId in updates) {
      updates = clearUpdateForCollection(
        updates,
        drawingId,
        deletedAnnotations.deletedStickers,
        'addedStickers',
        'updatedStickers',
        'deletedStickers',
      );
      updates = clearUpdateForCollection(
        updates,
        drawingId,
        deletedAnnotations.deletedRulers,
        'addedRulers',
        'updatedRulers',
        'deletedRulers',
      );
      return clearUpdateForCollection(
        updates,
        drawingId,
        deletedAnnotations.deletedSvgs,
        'addedImages',
        'updatedImages',
        'deletedSvgs',
      );
    } else {
      updates[drawingId] = { ...createClearDrawingUpdateState(), removed: deletedAnnotations };
    }
    return updates;
  },
  [DrawingsUserAnnotationActionTypes.CHANGE_COLORS]: (updates, changes: DrawingsAnnotationsChangeColors[], state) => {
    return updateAnnotations(updates, colorChangesIdIterator(changes), state);
  },
  [DrawingsUserAnnotationActionTypes.UPDATE_STICKERS_POSITIONS]: updatePositions,
  [DrawingsUserAnnotationActionTypes.UPDATE_IMAGES_POSITIONS]: updatePositions,
  [DrawingsUserAnnotationActionTypes.UPDATE_IMAGE_PARAMETER]: updateAnnotation,
  [DrawingsUserAnnotationActionTypes.UPDATE_RULER_POINTS]: updateAnnotation,
  [DrawingsUserAnnotationActionTypes.CHANGE_STICKER_TEXT]: updateAnnotation,
};

function* saveChangesWithDelay(updates: UpdatesState): SagaIterator {
  try {
    for (const [drawingId, drawingUpdates] of Object.entries(updates)) {
      const pdfId = yield selectWrapper(state => state.drawings.drawingsInfo[drawingId].pdfId);
      const {
        removed,
        addedStickers,
        addedRulers,
        addedImages,
        updatedImages,
        updatedRulers,
        updatedStickers,
      } = drawingUpdates;
      if (removed.deletedRulers.length || removed.deletedSvgs.length || removed.deletedStickers.length) {
        yield call(DrawingsApi.deleteAnnotations, pdfId, drawingId, removed);
      }
      yield call(addStickers, pdfId, drawingId, Object.values(addedStickers));
      yield call(addRulers, pdfId, drawingId, Object.values(addedRulers));
      yield call(addImages, pdfId, drawingId, Object.values(addedImages));
      yield call(updateStickers, pdfId, drawingId, Object.values(updatedStickers));
      yield call(updateRulers, pdfId, drawingId, Object.values(updatedRulers));
      yield call(updateImages, pdfId, drawingId, Object.values(updatedImages));
    }
    yield put({ type: DrawingsUserAnnotationActionTypes.NEXT_UPDATE });
  } catch (error) {
    console.error(error);
  }
}

export function* userAnnotationUpdateSaga(): SagaIterator {
  try {
    DatadogLogger.log('DRAWINGS UPDATE SAGA INITIALIZED');
    let task: Task = null;
    let updates: Record<string, DrawingUpdatesState> = {};
    let hasUpdates = false;

    function* changesProcessor(action: AnyAction): SagaIterator {
      try {
        const drawingsState = yield selectWrapper(x => x.drawings);
        updates = UpdateChangesMethods[action.type](updates, action.payload, drawingsState);
        yield put({ type: DrawingsUserAnnotationActionTypes.TOGGLE_HAS_UPDATE });
      } catch (error) {
        console.error('annotations update failed');
      }
    }
    function* runNext(): SagaIterator {
      if (hasUpdates) {
        task = yield fork(saveChangesWithDelay, updates);
        hasUpdates = false;
        updates = {};
      }
    }

    yield takeEvery(DrawingsUserAnnotationActionTypes.ADD_STICKER, changesProcessor);
    yield takeEvery(DrawingsUserAnnotationActionTypes.ADD_RULERS, changesProcessor);
    yield takeEvery(DrawingsUserAnnotationActionTypes.ADD_IMAGES, changesProcessor);
    yield takeEvery(DrawingsUserAnnotationActionTypes.REMOVE_INSTANCES_FROM_STATE, changesProcessor);
    yield takeEvery(DrawingsUserAnnotationActionTypes.CHANGE_COLORS, changesProcessor);
    yield takeEvery(DrawingsUserAnnotationActionTypes.UPDATE_STICKERS_POSITIONS, changesProcessor);
    yield takeEvery(DrawingsUserAnnotationActionTypes.UPDATE_IMAGES_POSITIONS, changesProcessor);
    yield takeEvery(DrawingsUserAnnotationActionTypes.UPDATE_IMAGE_PARAMETER, changesProcessor);
    yield takeEvery(DrawingsUserAnnotationActionTypes.UPDATE_RULER_POINTS, changesProcessor);
    yield takeEvery(DrawingsUserAnnotationActionTypes.CHANGE_STICKER_TEXT, changesProcessor);

    yield takeEvery(DrawingsUserAnnotationActionTypes.TOGGLE_HAS_UPDATE, function*(): SagaIterator {
      hasUpdates = true;
      if (!task || !task.isRunning()) {
        yield put({ type: DrawingsUserAnnotationActionTypes.NEXT_UPDATE });
      }
    });
    yield takeEvery(DrawingsUserAnnotationActionTypes.NEXT_UPDATE, runNext);
  } catch (error) {
    console.error('drawing elements: update elements failed', error);
  }
}
