import { AnyAction } from 'redux';

import { RequestStatus } from 'common/enums/request-status';
import { WorkPackagesActionTypes } from '../units/scenarios/actions';
import { WorkpackagesReduxState } from '../units/scenarios/interfaces';
import { WorkpackagesReducerMethods } from '../units/scenarios/reducers';

const INITIAL_STATE: WorkpackagesReduxState = {
  packages: [],
  activities: [],
  iter: 0,

  groupedPackages: [],

  activePackage: null,
  selectedGroup: [],
  isAllGroupSelected: false,

  activeGroup: null,
  selectedActivities: [],
  isAllActivitySelected: false,
  subgroupScrollPosition: null,
  statuses: {
    packages: RequestStatus.NotRequested,
  },
};

export default function(
  state: WorkpackagesReduxState = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): WorkpackagesReduxState {
  if (action.type in WorkpackagesReducerMethods) {
    return WorkpackagesReducerMethods[action.type](state, action.payload);
  } else {
    if (
      action.type === WorkPackagesActionTypes.DROP_EDIT_WORK_PACKAGES_STATE
    ) {
      return INITIAL_STATE;
    }
    return state;
  }
}
