import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MagicSearchActions } from 'common/components/drawings/actions/creators/magic-search';
import { State } from 'common/interfaces/state';

export function useDPI(): [number, (newDpi: number) => void] {
  const dispatch = useDispatch();
  const setDpi = useCallback((newDpi: number) => {
    dispatch(MagicSearchActions.setDPI(newDpi));
  }, [dispatch]);

  const dpi = useSelector<State, number>(s => s.drawings.magicSearch.dpi);
  return [dpi, setDpi];
}
