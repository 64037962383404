import { useMemo } from 'react';
import { CommentaryThread, FiltersData } from 'unit-2d-comments/interfaces';
import { FilterUtils } from 'unit-2d-comments/utils';

export function useCommentsFilter(
  comments: CommentaryThread[],
  filterValue: FiltersData,
  currentUserId: string,
): CommentaryThread[] {
  const filter = useMemo(() => FilterUtils.createFilter(filterValue, currentUserId), [filterValue, currentUserId]);
  return useMemo(() => comments.filter(filter), [comments, filter]);
}
