import { KreoColors } from 'common/enums/kreo-colors';
import { ObjectStatisticType } from '../../../components/charts/interfaces/object-statistic-type';
import { ValidationStepStatisticDataPayload, ValidationStepStatisticNodePayload } from '../actions/payloads/validation';

function mapStatisticNodeToObjectStatisticType(
  { name, bimHandleIds, amount, children }: ValidationStepStatisticNodePayload,
  color: string,
): ObjectStatisticType {
  const ret: ObjectStatisticType = {
    name,
    bimHandleIds,
    amount,
    children: children ? children.map((node) => mapStatisticNodeToObjectStatisticType(node, color)) : undefined,
    color,
  };

  return ret;
}

export function mapStatisticRequest(
  statistic: ValidationStepStatisticDataPayload,
): {
  statistic: ObjectStatisticType[],
  errorIds: number[],
} {
  const errorIds = [];
  for (const node of statistic.bad.children) {
    errorIds.push(...node.bimHandleIds);
  }

  const retStatistic = [
    mapStatisticNodeToObjectStatisticType(statistic.good, KreoColors.f3),
    mapStatisticNodeToObjectStatisticType(statistic.bad, '#FFC642'),
  ];


  return { statistic: retStatistic, errorIds };
}
