import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { FormFieldStyled } from 'common/components/form-fields/form-field';
import { ValuesWithEditStyled } from 'common/components/values-with-edit';


const Container = styled.div`
  position: relative;
  padding: 10px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 10px;
  pointer-events: all;
  gap: 2px;

  ${FormFieldStyled.Container} {
    > ${Text} {
      text-transform: capitalize;
    }
  }

  ${ValuesWithEditStyled.StartEditingButton} {
    display: block;

    > button {
      > span svg {
        fill: ${p => p.theme.color.gray};
      }
    }
  }
`;

export const Styled = {
  Container,
};
