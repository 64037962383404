import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './kreo-toolbar.scss';

import { KreoIconDoubleRows } from 'common/UIKit/icons';

interface Props {
  className: string;
  onHideToggle: () => void;
  isToolbarHide: boolean;
}

interface State {
  hide: boolean;
}

export class KreoToolbar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hide: false,
    };
  }

  public render(): JSX.Element {
    const { className, children, isToolbarHide } = this.props;

    return (
      <div
        className={classNames(
          'kreo-toolbar',
          { 'kreo-toolbar--hide': isToolbarHide },
          className,
        )}
      >
        <div
          className='kreo-toolbar__hide-toggle'
          title={isToolbarHide ? 'Show toolbar' : 'Hide toolbar'}
          onClick={this.onHideToggle}
        >
          <KreoIconDoubleRows />
        </div>
        <div className='kreo-toolbar__inner'>
          {children}
        </div>
      </div>
    );
  }

  @autobind
  private onHideToggle(): void {
    this.props.onHideToggle();
  }
}
