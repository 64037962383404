import * as React from 'react';

export const IconResources: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M11.9146471,7 L12.5,7 C13.3284271,7 14,7.67157288 14,8.5 L14,11.5 C14,12.3284271 13.3284271,
          13 12.5,13 L7.5,13 C7.11582278,13 6.76537825,12.8555732 6.5,12.6180533 C6.23462175,12.8555732 5.88417722,
          13 5.5,13 L3.5,13 C2.67157288,13 2,12.3284271 2,11.5 L2,8.5 C2,7.67157288 2.67157288,7 3.5,7 L4.08535285,
          7 C4.03007711,6.84361054 4,6.67531771 4,6.5 L4,4.5 C4,3.67157288 4.67157288,3 5.5,3 L10.5,3 C11.3284271,
          3 12,3.67157288 12,4.5 L12,6.5 C12,6.67531771 11.9699229,6.84361054 11.9146471,7 Z M5.5,4 C5.22385763,
          4 5,4.22385763 5,4.5 L5,6.5 C5,6.77614237 5.22385763,7 5.5,7 L10.5,7 C10.7761424,7 11,6.77614237 11,
          6.5 L11,4.5 C11,4.22385763 10.7761424,4 10.5,4 L5.5,4 Z M7.5,8 C7.22385763,8 7,8.22385763 7,8.5 L7,
          11.5 C7,11.7761424 7.22385763,12 7.5,12 L12.5,12 C12.7761424,12 13,11.7761424 13,11.5 L13,8.5 C13,
          8.22385763 12.7761424,8 12.5,8 L7.5,8 Z M3.5,8 C3.22385763,8 3,8.22385763 3,8.5 L3,11.5 C3,
          11.7761424 3.22385763,12 3.5,12 L5.5,12 C5.77614237,12 6,11.7761424 6,11.5 L6,
          8.5 C6,8.22385763 5.77614237,8 5.5,8 L3.5,8 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
