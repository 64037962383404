import { Icons, MenuItem } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { CommentaryTargetType } from 'unit-2d-comments/enums';
import { CommentaryTarget, CommentaryThread, FiltersData, TemporaryComment } from 'unit-2d-comments/interfaces';
import { TwoDCommentsActions } from 'unit-2d-comments/store-slice';
import { EmptyDiscussion } from './empty-discussion';
import { Filters } from './filters';
import { Styled } from './styled';


interface StateProps {
  comments: CommentaryThread[];
  temporaryComments: TemporaryComment[];
  currentCommentaryTarget: CommentaryTarget;
  filterValues: FiltersData;
}

interface DispatchProps {
  startCreateComment: () => void;
}

interface Props extends StateProps, DispatchProps {

}


const HeaderComponent: React.FC<Props> = ({
  startCreateComment,
  filterValues,
  currentCommentaryTarget,
  comments,
  temporaryComments,
}) => {
  const onCreateCommentaryClick = useCallback(() => startCreateComment(), []);
  const showEmptyDiscussion = !currentCommentaryTarget && !comments?.length && !temporaryComments?.length;

  return (
    <Styled.Container isComment={showEmptyDiscussion}>
      <Styled.HeaderContainer>
        <Styled.CreateWrapper>
          <MenuItem
            fontSize={14}
            text='Create Discussion'
            Icon={Icons.PlusBig}
            onClick={onCreateCommentaryClick}
          />
        </Styled.CreateWrapper>
        <Filters
          filterValues={filterValues}
        />
      </Styled.HeaderContainer>
      <RenderIf condition={showEmptyDiscussion}>
        <Styled.EmptyDiscussionWrapper>
          <EmptyDiscussion
            addComment={onCreateCommentaryClick}
          />
        </Styled.EmptyDiscussionWrapper>
      </RenderIf>
    </Styled.Container>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    comments: state.twoDComments.comments,
    currentCommentaryTarget: state.twoDComments.newCommentData,
    temporaryComments: state.twoDComments.temporaryComments,
    filterValues: state.twoDComments.filters,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    startCreateComment: () => dispatch(TwoDCommentsActions.startAddComment({ type: CommentaryTargetType.Project })),
  };
}

export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
