import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { State } from 'common/interfaces/state';
import { useCurrentDrawingInfo } from '../../hooks';
import { DrawingsGeometryInstance } from '../../interfaces';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';

export function useCanMagicSearch(instancesIds: string[]): boolean {
  const canManage = useAbility(Operation.Manage, Subject.MagicSearch);
  const currentDrawing = useCurrentDrawingInfo();
  const currentDrawingId = currentDrawing?.drawingId;
  const instances = useSelector<State, Record<string, DrawingsGeometryInstance>>(
    state => state.drawings.aiAnnotation.geometry,
  );

  return useMemo(() => {
    if (!canManage) {
      return false;
    }
    let polygonsCount = 0;
    for (const id of instancesIds) {
      const instance = instances[id];
      if (
        !instance
        || instance.drawingId !== currentDrawingId
        || !DrawingsGeometryUtils.isClosedContour(instance.type, instance.geometry)
      ) {
        return false;
      }

      polygonsCount++;
      if (polygonsCount > 4) {
        return false;
      }
    }
    return !!polygonsCount;
  }, [canManage, currentDrawingId, instancesIds, instances]);
}
