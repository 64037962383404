import { Icons, MenuItem, SmallMenuList } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  left: number;
  top: number;
  onClose: () => void;
  deletePage: () => void;
  duplicatePage: () => void;
  openPage: () => void;
}

export class DrawingsPdfBrowserPageMenu extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    return (
      <Styled.PageMenuWrapper left={this.props.left} top={this.props.top}>
        <SmallMenuList
          emergencePlace={'right top'}
          onExit={this.props.onClose}
        >
          <MenuItem onClick={this.props.openPage} text='Open page' Icon={Icons.OpenNewTab} size={'mm'}/>
          <MenuItem onClick={this.props.duplicatePage} text='Duplicate' Icon={Icons.Duplicate2D} size={'mm'}/>
          <MenuItem onClick={this.props.deletePage} text='Delete' Icon={Icons.Delete} size={'mm'}/>
        </SmallMenuList>
      </Styled.PageMenuWrapper>
    );
  }
}
