import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const StartEditingButton = styled.div`
  display: none;
  position: absolute;
  left: 0px;
  top: 2px;
  transform: translateX(-100%);
  ${Text} {
    line-height: unset;
  }

  > button {
    margin-right: 5px;
    border-radius: 50%;

    > span svg {
      fill: ${p => p.theme.color.white};
    }

    :hover {
      > span svg {
        fill: ${p => p.theme.color.white};
      }
    }
  }
`;

export const Styled = {
  StartEditingButton,
};
