import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 250px;
  border: 1px solid ${props => props.theme.color.background};
  border-top: none;
  border-bottom: none;
`;


export const Styled = {
  Container,
};
