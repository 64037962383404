import { useSelector } from 'react-redux';

import { ProgressUtils } from 'common/components/drawings/utils';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { TwoDViewTableConfig } from 'unit-2d-database/interfaces';

interface StateProps{
  hasLoading: boolean;
  config: TwoDViewTableConfig;
  isPiaLoading: boolean;
}

export const useStateVariables = (reportId: string): StateProps => {
  const hasLoading = useSelector<State, boolean>(s =>
    s.progress[ProgressUtils.DRAWING_PROGRESS_KEY]?.progressBars.length > 0);
  const config = useSelector<State, TwoDViewTableConfig>(s => s.twoD.viewsConfigs[reportId]);
  const isPiaLoading = useSelector<State, boolean>(
    s => s.twoD.fetchAssignPiaStatus === RequestStatus.Loading
    || s.twoDDatabase.requestStatus === RequestStatus.Loading,
  );

  return {
    hasLoading,
    config,
    isPiaLoading,
  };
};
