// import autobind from 'autobind-decorator';
// import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './attach-employee-to-project-dialog.scss';

// import { SvgSpinner } from 'common/components/svg-spinner';
// import { SubscriptionType } from 'common/constants/subscription';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
// import { KreoDialog} from 'common/UIKit';
import { AdminCompaniesActions } from '../actions/creators/companies';
// import { AdminProjectVm } from '../interfaces/admin-project-vm';
import { ShortUser } from '../interfaces/short-user';
// import { DialogUserToProjectItem } from './items/dialog-user-to-project-item';

export const ATTACH_EMPLOYEE_TO_PROJECT_DIALOG_NAME = 'attachEmployeeToProject';

interface ReduxProps {
  // selectedProject: AdminProjectVm;
  loadProjectsEmployeesStatus: RequestStatus;
  employeesInProject: ShortUser[];
  /**
   * @description employees in current company without attached to project
   */
  // employeesInCompany: ShortUser[];
  isOpen: boolean;
  companyId: number;
}

interface ReduxActions {
  loadEmployees: (projectId: number) => void;
  // attach: (employeeGuid: string, projectId: number, subscriptionType: SubscriptionType) => void;
  // detach: (employeeGuid: string, projectId: number, subscriptionType: SubscriptionType) => void;
}

interface Props extends ReduxProps, ReduxActions {
}

class AttachEmployeeToProjectDialogComponent extends React.Component<Props> {

  // public componentDidUpdate(prevProps: Props): void {
  //   if (
  //     this.props.selectedProject &&
  //     (!prevProps.selectedProject || prevProps.selectedProject.id !== this.props.selectedProject.id)
  //   ) {
  //     this.props.loadEmployees(this.props.selectedProject.id);
  //   }
  // }

  public render(): React.ReactNode {
    return null;
    // const { selectedProject } = this.props;
    // const hasImage = selectedProject && !!selectedProject.id;

    // return (
    //   <KreoDialog
    //     name={ATTACH_EMPLOYEE_TO_PROJECT_DIALOG_NAME}
    //     title={`Attach Employees to ${selectedProject ? selectedProject.name : 'Project'}`}
    //     isModal={true}
    //   >
    //     <div className='attach-employee-to-project-dialog'>
    //       <div
    //         className={classNames(
    //           'attach-employee-to-project-dialog__column',
    //           { 'has-image': hasImage },
    //         )}
    //       >
    //         {
    //           hasImage ?
    //           <div
    //             className={classNames(
    //               'attach-employee-to-project-dialog__row',
    //               'attach-employee-to-project-dialog__project_screenshot',
    //             )}
    //           >
    //             <img src={`/api/admin/projects/${selectedProject.id}/screen`} />
    //           </div>
    //           : null
    //         }
    //         <div className='attach-employee-to-project-dialog__row'>
    //           <span className='attach-employee-to-project-dialog__title'>
    //             Employees in project
    //           </span>
    //           <div className='attach-employee-to-project-dialog__people-in-project'>
    //             {
    //               this.props.loadProjectsEmployeesStatus === RequestStatus.Loading  ?
    //               <div className='attach-employee-to-project-dialog__spinner'>
    //                 <SvgSpinner size='middle' />
    //               </div>
    //               :
    //               this.props.employeesInProject && this.props.employeesInProject.length > 0 ?
    //               this.props.employeesInProject.map(e => (
    //                 <DialogUserToProjectItem
    //                   key={e.guid}
    //                   item={e}
    //                   isAttached={true}
    //                   onActionClick={this.onDetachEmployeeToProject}
    //                 />
    //               )) :
    //               <span className='attach-employee-to-project-dialog__message'>
    //                 There are no employees in the project.
    //               </span>
    //             }
    //           </div>
    //         </div>
    //       </div>
    //       <div className='attach-employee-to-project-dialog__column'>
    //         <span className='attach-employee-to-project-dialog__title'>
    //           Other employees in company
    //         </span>
    //         <div className='attach-employee-to-project-dialog__people-in-company'>
    //           {
    //             this.props.employeesInCompany && this.props.employeesInCompany.length > 0 ?
    //             this.props.employeesInCompany.map(e => (
    //               <DialogUserToProjectItem
    //                 key={e.guid}
    //                 item={e}
    //                 disableAction={this.props.loadProjectsEmployeesStatus === RequestStatus.Loading}
    //                 onActionClick={this.onAttachEmployeeToProject}
    //               />
    //             )) :
    //             <span className='attach-employee-to-project-dialog__message'>
    //               All employees of the company are already in the project.
    //             </span>
    //           }
    //         </div>
    //       </div>
    //     </div>
    //   </KreoDialog>
    // );
  }

  // @autobind
  // private onAttachEmployeeToProject(guid: string): void {
  //   this.props.attach(guid, this.props.selectedProject.id);
  // }

  // @autobind
  // private onDetachEmployeeToProject(guid: string): void {
  //   this.props.detach(guid, this.props.selectedProject.id);
  // }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const companiesState = state.admin.companies;
  const employeesInProject = companiesState.selectedProjectEmployees;
  const dialogData = state.dialog[ATTACH_EMPLOYEE_TO_PROJECT_DIALOG_NAME];
  const companyId = dialogData && dialogData.companyId;
  // const projectId = dialogData && dialogData.ProjectId;
  // const company = state.admin.companies.data.find((c) => c.id === companyId);
  // let selectedProject: AdminProjectVm = null;
  // let employeesInSubscription: ShortUser[] = [];

  // if (company != null) {
  //   selectedProject = company.documents.find(d => d.id === projectId);
  //   const employeeInProjectQuids = employeesInProject.map(e => e.guid);
  //   // employeesInSubscription = company.employees.filter(e => !employeeInProjectQuids.includes(e.guid));
  // }

  return {
    employeesInProject,
    loadProjectsEmployeesStatus: companiesState.statuses.loadProjectsEmployee,
    isOpen: ATTACH_EMPLOYEE_TO_PROJECT_DIALOG_NAME in state.dialog,
    // selectedProject,
    // employeesInCompany: employeesInSubscription,
    companyId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadEmployees: (projectId: number) =>
      dispatch(AdminCompaniesActions.loadProjectsEmployees(projectId)),
    // attach: (employeeGuid: string, projectId: number, subscriptionType: SubscriptionType) =>
    //   dispatch(AdminCompaniesActions.attachEmployeeToProject({
    //     companyId,
    //     employeeGuid,
    //     projectId,
    //     subscriptionType,
    //   })),
    // detach: (employeeGuid: string, projectId: number, subscriptionType: SubscriptionType) =>
    //   dispatch(AdminCompaniesActions.detachEmployeeFromProject({
    //     companyId,
    //     employeeGuid,
    //     projectId,
    //     subscriptionType,
    //   })),
  };
};

export const AttachEmployeeToProjectDialog =
  connect(mapStateToProps, mapDispatchToProps)(AttachEmployeeToProjectDialogComponent);
