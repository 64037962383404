import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import {
  ConfirmationDialog,
} from '../../../../../components/dialog/confirmation-dialog';
import { DrawingDialogs } from '../../constants/drawing-dialogs';

interface Props {
  accept: () => void;
  cancel: () => void;
}

const UnsavedChangesDialogComponent: React.FC<Props> = ({ accept, cancel }) => {
  return (
    <ConfirmationDialog
      zIndex={1003}
      name={DrawingDialogs.UNSAVED_CHANGES_DIALOG}
      title='All unfinished measurements will be lost'
      text='Are you sure you want to exit?'
      confirmButtonText='Yes'
      cancelButtonText='Cancel'
      onConfirm={accept}
      onCancel={cancel}
    />
  );
};


function mapStateToProps({ dialog }: State): Props {
  return {
    accept: dialog[DrawingDialogs.UNSAVED_CHANGES_DIALOG]?.accept,
    cancel: dialog[DrawingDialogs.UNSAVED_CHANGES_DIALOG]?.cancel,
  };
}

export const UnsavedChangesDialog =
  connect(mapStateToProps)
  (UnsavedChangesDialogComponent);
