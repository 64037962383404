import { ICellRendererFunc } from 'ag-grid-community';

import './group-row-inner-cell.scss';

import { EMPTY_VALUE, FilterKeys } from 'common/components/tree-filter-panel/constants';
import {
  ExtractorConfig,
  GraphStorageRecordsConfig,
  RecordConfig,
} from '../../../../../units/projects/interfaces/graph-storage-records-config';
import { SharedParameterKeyHelper } from '../../../../../units/projects/utils/shared-parameter-key-helper';
import { QtoLeftPanelConstants } from '../../quantity-take-off-left-panel/constants';
import { isAdditionalProperties } from '../../quantity-take-off-left-panel/is-additional-properties';
import { AGG_HASH, aggregationCell } from './aggregation-cell/aggregation-cell';
import { standardClassification } from './standard-classification-cell';

const isStandardClassification = (field: string): boolean => {
  return field
    && (
      field === FilterKeys.F_NRM1
      || field === FilterKeys.F_NRM2
      || field.includes(FilterKeys.F_NRM1)
      || field.includes(FilterKeys.F_NRM2)
      || field === FilterKeys.F_UNI_SYS
      || field === FilterKeys.F_UNI_PROD
      || field === FilterKeys.F_MAS_FORM
      || field === FilterKeys.F_MAS_FORM_0_LEVEL
      || field === FilterKeys.F_UNI_FORM
      || field === FilterKeys.F_UNI_FORM_0_LEVEL
      || field === FilterKeys.F_UNI_FORM_1_LEVEL
    );
};

export const groupRowInnerCell = (displayNames: GraphStorageRecordsConfig): ICellRendererFunc => (params) => {
  if ((isStandardClassification(params.node.field))) {
    const column = params.columnApi.getColumn(params.node.field);
    return standardClassification(params.value, column.colDef.headerName);
  }

  if (params.node.field === AGG_HASH) {
    return aggregationCell(displayNames.extractors as ExtractorConfig)(params);
  }

  if (params.node.field === QtoLeftPanelConstants.EXTRA_INFO) {
    const isImperialUnit = params.context.isImperialUnit;
    const firstChild = params.node.allLeafChildren[0];
    return isImperialUnit
      ? firstChild.data[QtoLeftPanelConstants.EXTRA_INFO_IMPERIAL]
      : firstChild.data[QtoLeftPanelConstants.EXTRA_INFO];
  }

  if (params.value.toLowerCase() === EMPTY_VALUE) {
    if (isAdditionalProperties(params.node.field)) {
      const prefix = QtoLeftPanelConstants.ADDITIONAL_PROPERTIES_PREFIX;
      const filedName = SharedParameterKeyHelper.getNameFormKey(params.node.field, prefix);
      return `${params.value} ${filedName}`;
    }
    return `${params.value} ${(displayNames[params.node.field] as RecordConfig).name}`;
  }

  return params.value;
};
