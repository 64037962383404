import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './index.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { isProjectStatusLessThan, ProjectStatus } from 'common/enums/project-status';
import { Common } from '../../../actions';
import { State } from '../../../common/interfaces/state';
import { CostsActions } from '../../../units/projects/actions/creators/costs';
import { CostEstimateGroupingType } from '../../../units/projects/enums/cost-estimate-grouping-type';
import { ExportExcelReportType } from '../interfaces/export-excel-report-type';
import { FirstPageMenu } from './first-page-menu';
import { NoBidPricingFirstPage } from './no-bid-pricing-first-page-menu';

interface DispatchProps {
  loadExcelReportTypes: () => void;
  exportExcelReport: (selectedReports: string[], dialogText: string) => void;
}

interface OwnProps {
  calculation: number;
  projectStatus: ProjectStatus;
  groupingType: CostEstimateGroupingType;
  loadData: (group: CostEstimateGroupingType) => void;
  changeGroupingType: (group: CostEstimateGroupingType) => void;
}

interface StateProps {
  excelReportTypes: ExportExcelReportType[];
}

interface Props extends DispatchProps, OwnProps, StateProps, AbilityAwareProps {

}

interface ComponentState {
  selectedReportTypes: string[];
}

class CostEstimateHeaderComponent extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedReportTypes: [],
    };
    props.loadExcelReportTypes();
  }

  public render(): React.ReactNode {
    const { projectStatus } = this.props;
    return (
      <div className='cost-estimate-page__header'>
        <div className='cost-estimate-page__header-container'>
        <div className='cost-estimate-page__title'>Cost Estimate</div>
        </div>
        <div className='cost-estimate-page__header-action'>
          {isProjectStatusLessThan(projectStatus, ProjectStatus.OnBidPricing) &&
            (
              <NoBidPricingFirstPage
                groupingType={this.props.groupingType}
                onChangeType={this.onChangeType}
              />
            )}
          {this.props.ability.can(Operation.Export, Subject.Cost) && (
            <FirstPageMenu
              selectedReports={this.state.selectedReportTypes}
              onExportClick={this.loadReport}
              excelReportTypes={this.props.excelReportTypes}
              onChangeExcelReportType={this.onChangeReportType}
            />
          )}
        </div>
      </div>
    );
  }

  @autobind
  private loadReport(): void {
    const selectedReportTypes = new Set(this.state.selectedReportTypes);
    const typeNames = this.props.excelReportTypes.filter((x) => selectedReportTypes.has(x.type)).map((x) => x.name);
    const dialogText = typeNames.length > 0 ? typeNames.join(', ') : 'Cost Estimate';
    this.props.exportExcelReport(this.state.selectedReportTypes, dialogText);
  }

  @autobind
  private onChangeType(group: CostEstimateGroupingType): void {
    this.props.loadData(group);
    this.props.changeGroupingType(group);
  }

  @autobind
  private onChangeReportType(type: string): void {
    const selectedReportTypes = this.state.selectedReportTypes;
    if (selectedReportTypes.includes(type)) {
      const index = selectedReportTypes.indexOf(type);
      selectedReportTypes.splice(index, 1);
    } else {
      selectedReportTypes.push(type);
    }
    this.setState({ selectedReportTypes });
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    excelReportTypes: state.cost.get('excelReportTypes').toJS(),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    loadExcelReportTypes: () => {
      dispatch(CostsActions.loadExcelReportTypes());
    },
    exportExcelReport: (selectedReports: string[], dialogText: string) => {
      dispatch(Common.setReportsDialogText(dialogText));
      dispatch(CostsActions.exportExcelReport(selectedReports));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const CostEstimateHeader = withAbilityContext(connector(CostEstimateHeaderComponent));
