import { AnyAction } from 'redux';
import { PeopleState } from 'common/interfaces/people';
import {
  INITIAL_STATE,
  peopleReducerMethods,
} from '../units/people/reducer-methods';

export default function people(
  state: PeopleState = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): PeopleState {
  if (action.type in peopleReducerMethods) {
    return peopleReducerMethods[action.type](state, action.payload);
  }
  return state;
}
