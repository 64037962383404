import autobind from 'autobind-decorator';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DrawingsShortInfo } from '../../interfaces';

export interface DrawOptions extends Core.LoadCanvasAsyncOptions {
  drawingId: string;
}

interface RenderResult {
  canvas: HTMLCanvasElement;
  options: DrawOptions;
}

interface PdfRendererConfig {
  syncPageViewportRect: (drawOption: DrawOptions) => void;
  getCurrentDrawingInfo: () => DrawingsShortInfo;
  getCurrentZoom: () => number;
}

export class PdfRenderer {
  protected _currentRenderId: number | string;
  protected _renderDeferredExecutor = new DeferredExecutor(10);
  protected _config: PdfRendererConfig;

  constructor(config: PdfRendererConfig) {
    this._config = config;
  }

  public render(
    pdfDocument: Core.Document,
    options: DrawOptions,
  ): Promise<RenderResult> {
    return new Promise((resolve, reject) => {
      const drawingInfo = this._config.getCurrentDrawingInfo();
      this._renderDeferredExecutor.execute(
        this.drawPdfCoreControls,
        pdfDocument,
        {
          ...options,
          onerror: reject,
        },
        (canvas: HTMLCanvasElement, o: DrawOptions): void => {
          if (this._config.getCurrentZoom() !== options.zoom) {
            return;
          }
          if (!canvas) {
            throw new Error('No canvas rendered');
          }
          if (this.shouldRender(drawingInfo)) {
            resolve({ canvas, options: o });
          }
        },
      );
    });
  }

  public cancelAndUnload(pdfDocument: Core.Document): void {
    if (Number.isInteger(this._currentRenderId)) {
      pdfDocument.unloadCanvasResources(this._currentRenderId as number);
      pdfDocument.cancelLoadCanvas(this._currentRenderId);
    }
  }

  @autobind
  protected drawPdfCoreControls(
    pdfDocument: Core.Document,
    options: DrawOptions,
    completeCallBack: (canvas: HTMLCanvasElement, options: DrawOptions) => void,
  ): void {
    this.cancelAndUnload(pdfDocument);
    if (!this._config.getCurrentDrawingInfo()) {
      return;
    }
    this._config.syncPageViewportRect(options);
    this._currentRenderId = pdfDocument.loadCanvas({
      ...options,
      drawComplete: (canvas: HTMLCanvasElement) => {
        this._currentRenderId = null;
        pdfDocument.unloadCanvasResources(this._currentRenderId as number);
        completeCallBack(canvas, options);
      },
    });
  }

  protected shouldRender(requestedDrawingInfo: DrawingsShortInfo): boolean {
    return requestedDrawingInfo.drawingId === this._config.getCurrentDrawingInfo()?.drawingId;
  }
}
