import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Text } from 'common/components/text';
import { ElementTooltip } from 'common/components/tooltip';
import { Styled } from './styled';

interface Props {
  name: string;
  tooltipText: string;
}

export const PropertyWithInfo: React.FC<Props> = ({ children, name, tooltipText }) => {
  return (
    <Styled.Container>
      <ElementTooltip text={tooltipText} position="top" speed="l" wordBreakAll={true}>
        <Styled.Icon>
          <Icons.Question2D />
        </Styled.Icon>
      </ElementTooltip>
      <Text
        fontSize={14}
      >
        {name}
      </Text>
      <Styled.Input>
        {children}
      </Styled.Input>
    </Styled.Container>
  );
};
