import { GridApi, RowNode, RowSelectedEvent } from 'ag-grid-community';
import autobind from 'autobind-decorator';

import { AgGridSyncSelectHelper } from 'common/ag-grid/ag-grid-sync-select-helper';
import { arrayUtils } from 'common/utils/array-utils';
import { AgGridHelper } from '../../ag-grid';

enum SelectionStatus {
  selected,
  unSelected,
  partiallySelected,
}

export class SelectionHelper {
  private callBack: (ids: Array<number | string>) => void;
  private skipEventCount: number = 0;
  private gridApi: GridApi = null;
  private balkSetSelectedEventCount: number = 0;
  private isInnerTableSelect: boolean = true;
  private prevSelected: Array<number | string> = [];
  private getElementId: (node: RowNode) => string | undefined;
  private prevSelectedNodeIds: string[] = [];
  private prevSelectedNodeIdsWithStatus: Record<string, SelectionStatus> = {};
  private nodeIdWithIndex: Record<string, number> = {};

  public constructor(
    callBack: (ids: Array<number | string>) => void,
    getElementId: (node: RowNode) => string | undefined,
  ) {
    this.callBack = callBack;
    this.getElementId = getElementId;
  }

  @autobind
  public setApi(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  @autobind
  public setSelected(ids: Array<number | string>, isExpand?: boolean): void {
    if (!this.gridApi) {
      return;
    }

    if (arrayUtils.areSetsEqual(ids, this.prevSelected)) {
      this.callBack(ids);
      return;
    }

    const selectedBimIdsRecord = {};
    const selectedNodeIds = [];

    ids.forEach(id => selectedBimIdsRecord[id] = true);
    let nodeToShow = null;

    this.gridApi.forEachNodeAfterFilter(node => {
      if (node.group) {
        return;
      }
      const elementId = this.getElementId(node);
      if (this.isElementIdIsEmpty(elementId)) {
        const isSelected = !!selectedBimIdsRecord[elementId];
        if (isSelected !== node.isSelected()) {
          this.isInnerTableSelect = false;
          node.selectThisNode(isSelected);
          this.balkSetSelectedEventCount += 1;
        }
      }

      if (node.isSelected()) {
        if (isExpand) {
          nodeToShow = node;
          this.expandAllParent(node);
        }
        selectedNodeIds.push(node.id);
      }
    });

    this.prevSelected = ids;
    this.prevSelectedNodeIds = selectedNodeIds;

    if (this.isInnerTableSelect) {
      this.callBack(ids);
    }
    this.gridApi.onGroupExpandedOrCollapsed();
    if (nodeToShow) {
      this.gridApi.ensureNodeVisible(nodeToShow, 'middle');
    }
  }

  @autobind
  public setSelectedAfterUpdateRecords(ids: Array<number | string>): void {
    const selectedNodeIds = [];
    const selectedBimIdsRecord = {};
    ids.forEach(id => selectedBimIdsRecord[id] = true);
    this.gridApi.forEachNodeAfterFilter(node => {
      if (node.group) {
        return;
      }
      const elementId = this.getElementId(node);
      if (this.isElementIdIsEmpty(elementId)) {
        const isSelected = !!selectedBimIdsRecord[elementId];
        if (isSelected !== node.isSelected()) {
          this.isInnerTableSelect = false;
          node.selectThisNode(isSelected);
          this.balkSetSelectedEventCount += 1;
        }
      }

      if (node.isSelected()) {
        selectedNodeIds.push(node.id);
      }
    });

    this.prevSelected = ids;
    this.prevSelectedNodeIds = selectedNodeIds;
  }

  @autobind
  public setPrevSelected(): void {
    const selectedBimIdsRecord = {};
    this.prevSelected.forEach(id => selectedBimIdsRecord[id] = true);
    this.gridApi.forEachNodeAfterFilter(node => {
      if (node.group) {
        return;
      }
      const elementId = this.getElementId(node);
      const parent = node.parent;

      if (this.isElementIdIsEmpty(elementId) && node.isSelected() && !AgGridHelper.isRootNode(parent)) {
        this.updateParentSelection({ [parent.id]: parent });
      }
    });

    const prevSelectedNodeIds = [];
    const prevSelectedNodeIdsWithStatus = {};
    this.gridApi.getSelectedNodes().forEach((node) => {
      const selectedStatus = this.getSelectedStatus(node.isSelected());
      if (selectedStatus !== SelectionStatus.unSelected) {
        prevSelectedNodeIds.push(node.id);
      }
      prevSelectedNodeIdsWithStatus[node.id] = selectedStatus;
    });

    this.prevSelectedNodeIds = prevSelectedNodeIds;
    this.prevSelectedNodeIdsWithStatus = prevSelectedNodeIdsWithStatus;
    this.gridApi.onGroupExpandedOrCollapsed();
  }

  @autobind
  public rowSelectionHandler(e: RowSelectedEvent): void {
    if (this.isInnerTableSelect) {
      this.handelInnerSelectEvent(e);
    } else {
      this.handelOuterSelectionEvent(e);
    }
  }

  @autobind
  private expandAllParent(node: RowNode): void {
    if (node.level === -1) {
      return;
    }
    // setExpanded будет занимать больше времени из-за того, что на каждый возов пораждаеется все события
    node.expanded = true;
    this.expandAllParent(node.parent);
  }

  private handelInnerSelectEvent(e: RowSelectedEvent): void {
    if (this.skipEventCount === 0) {
      this.updateRowSelection(e.node, e.node.isSelected());
      this.syncPage();
      const selectedNodeIds = [];
      const nodesToSplice = [];
      this.gridApi.forEachNodeAfterFilter((node) => {
        const isSelected = node.isSelected();
        const selectedStatus = this.getSelectedStatus(isSelected);
        if (isSelected !== false) {
          const index = selectedNodeIds.push(node.id) - 1;
          this.nodeIdWithIndex[node.id] = index;
        }
        if (this.isPartialUpdate(node.id, selectedStatus)) {
          const index = this.nodeIdWithIndex[node.id];
          nodesToSplice.push(index);
        }
        if (this.prevSelectedNodeIdsWithStatus[node.id] !== selectedStatus) {
          this.prevSelectedNodeIdsWithStatus[node.id] = selectedStatus;
        }
      });
      nodesToSplice.sort((a, b) => b - a);
      nodesToSplice.forEach((index) => {
        this.prevSelectedNodeIds.splice(index, 1);
      });
      this.skipEventCount = Math.max(
        AgGridSyncSelectHelper.getSkipEventCount(selectedNodeIds, this.prevSelectedNodeIds) - 1,
        0,
      );
      this.prevSelectedNodeIds = selectedNodeIds;
      this.gridApi.onGroupExpandedOrCollapsed();
    } else {
      this.skipEventCount -= 1;
    }
  }

  private isPartialUpdate(nodeId: string, status: SelectionStatus): boolean {
    return (this.prevSelectedNodeIdsWithStatus[nodeId] === SelectionStatus.partiallySelected
      && status === SelectionStatus.selected)
      || (this.prevSelectedNodeIdsWithStatus[nodeId] === SelectionStatus.selected
        && status === SelectionStatus.partiallySelected);
  }

  private getSelectedStatus(value: boolean): SelectionStatus {
    if (value === undefined) return SelectionStatus.partiallySelected;
    if (value === false) return SelectionStatus.unSelected;
    return SelectionStatus.selected;
  }

  private handelOuterSelectionEvent(e: RowSelectedEvent): void {
    if (this.balkSetSelectedEventCount !== 0) {
      this.updateRowSelection(e.node, e.node.isSelected());
      this.balkSetSelectedEventCount -= 1;
      if (this.balkSetSelectedEventCount === 0 && this.skipEventCount === 0) {
        this.compileSyncAfterPageEvent();
      }
    } else {
      this.skipEventCount -= 1;
      if (this.skipEventCount === 0) {
        this.gridApi.onGroupExpandedOrCollapsed();
        this.compileSyncAfterPageEvent();
      }
    }
  }

  private compileSyncAfterPageEvent(): void {
    this.syncPage();
    this.isInnerTableSelect = true;
    this.prevSelectedNodeIds = [];
    this.gridApi.forEachNode((node) => {
      const isSelected = node.isSelected();
      if (isSelected !== false) {
        this.prevSelectedNodeIds.push(node.id);
      }
      this.prevSelectedNodeIdsWithStatus[node.id] = this.getSelectedStatus(isSelected);
    });
  }

  private isElementIdIsEmpty(elementId: string): boolean {
    return elementId !== undefined && elementId !== null;
  }

  private saveParent(
    parentSelect: Record<string, RowNode>,
    node: RowNode,
  ): void {
    if (AgGridHelper.isRootNode(node.parent)) {
      return;
    }

    if (!parentSelect[node.parent.id]) {
      parentSelect[node.parent.id] = node.parent;
    }
  }

  private updateRowSelection(node: RowNode, newValue: boolean): void {
    this.changeSelectionAllChildren(node, newValue);
    const parent = node.parent;
    if (!AgGridHelper.isRootNode(parent)) {
      this.updateParentSelection({ [parent.id]: parent });
    }
  }

  private updateParentSelection(selectNode: Record<string, RowNode>): void {
    const parentSelected = {};
    for (const [, node] of Object.entries(selectNode)) {
      const selectStatus = this.getSelectionState(node);
      if (selectStatus !== node.isSelected()) {
        node.selectThisNode(selectStatus);
        this.saveParent(parentSelected, node);
        this.skipEventCount += 1;
      }
    }

    if (Object.keys(selectNode).length !== 0) {
      this.updateParentSelection(parentSelected);
    }
  }

  private getSelectionState(node: RowNode): boolean {
    let isAllSelect: boolean = true;
    let isAllDeselect: boolean = true;
    node.childrenAfterFilter.forEach(n => {
      const isNodeSelected = n.isSelected();

      if (isNodeSelected === undefined) {
        isAllSelect = false;
        isAllDeselect = false;
      }

      if (!isNodeSelected && isAllSelect) {
        isAllSelect = false;
      }

      if (isNodeSelected && isAllDeselect) {
        isAllDeselect = false;
      }
    });

    if (isAllSelect) {
      return true;
    }

    if (isAllDeselect) {
      return false;
    }

    return undefined;
  }

  private syncPage(): void {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedIds = [];
    const addedIds = {};
    selectedNodes.forEach(node => {
      const elementId = this.getElementId(node);
      if (node.isSelected()) {
        if (this.isElementIdIsEmpty(elementId) && !addedIds[elementId]) {
          addedIds[elementId] = true;
          selectedIds.push(elementId);
        }
      }
    });

    this.callBack(selectedIds);
    this.prevSelected = selectedIds;
  }

  private changeSelectionAllChildren(node: RowNode, newValue: boolean): void {
    const children = node.childrenAfterFilter;
    if (children) {
      children.forEach(n => {
        if (n.isSelected() !== newValue) {
          this.skipEventCount += 1;
          n.selectThisNode(newValue);
          this.changeSelectionAllChildren(n, newValue);
        }
      });
    }
  }
}
