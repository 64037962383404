import autobind from 'autobind-decorator';
import * as React from 'react';

import { ProjectsApi } from '../../api/common';
import { ModelBrowserPropertyGroupView, PropertiesDataProviderApi } from '../../interfaces/properties-provider-types';
import {
  PropertiesDataProvider,
} from '../../utils/properties-data-provider/properties-data-provider';

interface ProviderState {
  provider: PropertiesDataProvider;
}

export const PropertiesDataContext = React.createContext<PropertiesDataProviderApi>(null);

export class PropertiesDataContextProvider extends React.Component<{}, ProviderState> {
  private api: PropertiesDataProviderApi;

  constructor(props: {}) {
    super(props);
    this.api = {
      getCommonElementProperties: this.getCommonElementProperties,
      getElementPropertiesName: this.getElementPropertiesName,
      getElementProperties: this.getElementProperties,
      getElementProrertyValue: this.getElementPropertyValue,
      getElementPropertyNameToValuesMap: this.getElementPropertyNameToValuesMap,
    };
    this.state = { provider: null };
  }

  public componentDidMount(): void {
    ProjectsApi.getModelBrowserProperties()
      .then(value => this.setState({ provider: new PropertiesDataProvider(new Uint8Array(value)) }));
  }

  public render(): React.ReactNode {
    return (
      <PropertiesDataContext.Provider value={this.api}>
        {this.props.children}
      </PropertiesDataContext.Provider>
    );
  }

  @autobind
  private getCommonElementProperties(ids: number[], showLayer?: boolean): ModelBrowserPropertyGroupView[] {
    if (this.state.provider) {
      return this.state.provider.getCommonElementProperties(ids, showLayer);
    }
  }

  @autobind
  private getElementPropertiesName(ids: number[]): ModelBrowserPropertyGroupView[] {
    if (this.state.provider) {
      return this.state.provider.getElementPropertiesName(ids);
    }
    return [];
  }

  @autobind
  private getElementProperties(id: number): ModelBrowserPropertyGroupView[] {
    if (this.state.provider) {
      return this.state.provider.getElementProperties(id);
    }
  }

  @autobind
  private getElementPropertyValue(id: number, group: string, propertyKey: string): string {
    if (this.state.provider) {
      return this.state.provider.getElementProrertyValue(id, group, propertyKey);
    }
  }

  @autobind
  private getElementPropertyNameToValuesMap(ids: number[]): Record<string, string[]> {
    if (this.state.provider) {
      return this.state.provider.getElementPropertyNameToValuesMap(ids);
    }
  }
}

