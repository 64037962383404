import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawingsAnnotationLegendActions } from 'common/components/drawings';
import { DrawingsGroupTemplate } from 'common/components/drawings/interfaces/drawings-group-template';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { CREATE_TEMPLATE_DIALOG_NAME } from 'components/dialog/create-template-dialog';

interface TemplatesState {
  templates: DrawingsGroupTemplate[];
  disabledCreate: boolean;
  openCreateTemplateDialog: (data?: DrawingsGroupTemplate) => void;
  applyTemplate: (templateId: number) => void;
  updateTemplate: (template: DrawingsGroupTemplate) => void;
  duplicateTemplate: (template: DrawingsGroupTemplate) => void;
  deleteTemplate: (templateId: number) => void;
}

export function useTemplatesRedux(afterClick: () => void): TemplatesState {
  const dispatch = useDispatch();

  const openCreateTemplateDialog = useCallback((data?: DrawingsGroupTemplate) => {
    dispatch(KreoDialogActions.openDialog(CREATE_TEMPLATE_DIALOG_NAME, data));
    afterClick();
  }, [dispatch, afterClick]);

  const applyTemplate = useCallback((templateId: number) => {
    dispatch(DrawingsAnnotationLegendActions.applyGroupTemplate(templateId));
    afterClick();
  }, [dispatch, afterClick]);

  const updateTemplate = useCallback((template: DrawingsGroupTemplate) => {
    const { id, name, isDefault, isPublic } = template;
    dispatch(DrawingsAnnotationLegendActions.updateGroupTemplate(id, { name, isDefault, isPublic }));
    afterClick();
  }, [dispatch, afterClick]);

  const duplicateTemplate = useCallback((template: DrawingsGroupTemplate) => {
    const { id, name, isDefault, isPublic } = template;
    dispatch(DrawingsAnnotationLegendActions.duplicateGroupTemplate(id, { name, isDefault, isPublic }));
    afterClick();
  }, [dispatch, afterClick]);

  const deleteTemplate = useCallback((templateId: number) => {
    dispatch(DrawingsAnnotationLegendActions.deleteGroupTemplate(templateId));
  }, [dispatch]);

  const templates = useSelector<State, DrawingsGroupTemplate[]>(state => state.drawings.drawingGroupTemplates);
  const disabledCreate = useSelector<State, boolean>(state => state.drawings.drawingGeometryGroups.length === 0);

  return {
    templates,
    openCreateTemplateDialog,
    applyTemplate,
    updateTemplate,
    duplicateTemplate,
    deleteTemplate,
    disabledCreate,
  };
}
