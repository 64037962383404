import * as paper from 'paper';
import { useCallback, useState } from 'react';

import { DrawingsGeometryDragEventHelper } from 'common/components/drawings/drawings-geometry';

export interface DraggingState {
  dragging: boolean;
  startDrag: (e: React.MouseEvent<HTMLDivElement>) => void;
  stopDrag: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export function useStickerDrag(
  id: string,
  dragEventsHelper: DrawingsGeometryDragEventHelper,
  onStartDrag: (id: string, startPoint: paper.Point) => void,
  onStopDrag: () => void,
): DraggingState {
  const [dragging, setDragging] = useState(false);
  const stopDrag = useCallback(() => {
    dragEventsHelper.stopExecutor();
    if (onStopDrag) {
      onStopDrag();
    }
    setDragging(false);
  }, [onStopDrag, dragEventsHelper]);

  const startDrag = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button !== 0 || !onStartDrag) {
      return;
    }
    const { left, top } = e.currentTarget.getBoundingClientRect();
    const startX = e.clientX - left;
    const startY = e.clientY - top;
    dragEventsHelper.onStartElementDrag(e, () => {
      onStartDrag(id, new paper.Point(startX, startY));
      setDragging(true);
    });
  }, [onStartDrag, dragEventsHelper]);
  return { dragging, startDrag, stopDrag };
}
