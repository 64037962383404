import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { CostEstimateProjectRevisionRouteParams } from 'routes/app-routes-params';
import { Measurements } from 'unit-cost-estimate/components/measurements';
import { ProjectLayout } from '../../../units/project-dashbord';

interface PageProps extends RouteComponentProps<CostEstimateProjectRevisionRouteParams> {
}

export const MeasurementsPage = (props: PageProps): JSX.Element => {
  const { projectId, revisionId } = props.match.params;

  return (
    <ProjectLayout
      projectId={projectId}
    >
      <Measurements
        projectId={parseInt(projectId)}
        revisionId={parseInt(revisionId)}
      />
    </ProjectLayout>
  );
};
