import * as t from 'io-ts';

export const BillingCountryModelC = t.intersection(
  [
    t.type({
      id: t.string,
      name: t.string,
    }),
    t.partial({
      allowVatNumber: t.boolean,
    }),
  ],
  'BillingModel');

export type BillingCountryModel = t.TypeOf<typeof BillingCountryModelC>;
export const BillingCountryModelsC = t.array(BillingCountryModelC);
