import autobind from 'autobind-decorator';
import * as paper from 'paper';
import { DrawingsCanvasConstants } from '../../constants';

import { ContextObserverCallbackGroupWithPrev, DrawingContextObserverWithPrev } from '../../drawings-contexts';
import { DrawingsRenderParams, ShortPointDescription, TextSearchResult } from '../../interfaces';
import { EngineObjectConfig } from '../common';
import { DestroyableObject } from '../common/destroyable-object';
import { TextSearchHighlightController } from './text-search-highlight-controller';
import { TextSearchResultRect } from './text-search-result-rect';

export interface TextSearchResultsConfig extends EngineObjectConfig{
  results: Record<string, TextSearchResult[]>;
  currentDrawingId: string;
  layer: paper.Layer | paper.Group;
  paramsObserver: DrawingContextObserverWithPrev<DrawingsRenderParams>;
}

export class TextSearchResults extends DestroyableObject<TextSearchResultsConfig> {
  private _visibleInstances: Map<number, TextSearchResultRect> = new Map<number, TextSearchResultRect>();
  private _layer: paper.Group;
  private _highlightController: TextSearchHighlightController = new TextSearchHighlightController();
  private _observer: ContextObserverCallbackGroupWithPrev<DrawingsRenderParams>;

  constructor(config: TextSearchResultsConfig) {
    super(config);
    this._observer = new ContextObserverCallbackGroupWithPrev(config.paramsObserver);
    this._layer = new paper.Group();
    this._layer.addTo(this._config.layer);
    this._config.paramsObserver.subscribe(this.updateZoom);
    this.render();
  }

  public clear(): void {
    this._observer.clean();
    this._layer.removeChildren();
    this._visibleInstances.clear();
  }

  public destroy(): void {
    this.clear();
    this._config.paramsObserver.unsubscribe(this.updateZoom);
  }

  public changeResults(results: Record<string, TextSearchResult[]>): void {
    if (this._config.results === results) {
      return;
    }
    this._config.results = results;
    this.render();
  }
  public changeDrawingId(drawingId: string): void {
    if (this._config.currentDrawingId === drawingId) {
      return;
    }
    this._config.currentDrawingId = drawingId;
    this.render();
  }

  public setHighlightItem(drawingId: string, id: number): void {
    if (this._config.currentDrawingId === drawingId) {
      this._highlightController.applyHighlight(this._visibleInstances.get(id));
    }
  }

  private render(): void {
    this.clear();
    if (!this._config.results[this._config.currentDrawingId]) {
      return;
    }
    for (const { rectangleBounds, id } of this._config.results[this._config.currentDrawingId]) {
      const result = this.renderResult(rectangleBounds);
      this._visibleInstances.set(id, result);
    }
  }

  @autobind
  private updateZoom({ zoom }: DrawingsRenderParams): void {
    const strokeWith = DrawingsCanvasConstants.infoLinesStroke / zoom;
    this._visibleInstances.forEach(x => x.strokeWith = strokeWith);
  }

  private renderResult(rectangleBounds: ShortPointDescription[]): TextSearchResultRect {
    return new TextSearchResultRect(
      {
        layer: this._layer,
        geometry: rectangleBounds,
        renderParamsContextObserver: this._observer,
      },
    );
  }
}
