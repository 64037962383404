import { UpdateCellData } from 'common/components/excel-table';
import { UndoRedoActionMethods } from 'common/undo-redo/undo-redo-context-provider';
import { SheetUpdateCells } from '../actions/payloads';
import { FocusChangeData, UpdateColumnWidth } from '../components/2d-report/2d-sheet';
import { Arrangement } from '../constants';
import { ReportPage } from '../interfaces';

const getUndoCellsData =
  (payload: Array<SheetUpdateCells<UpdateCellData[]>>): Array<SheetUpdateCells<UpdateCellData[]>> => {
    return payload.map(sheet => {
      const form = sheet.form.map(cell => {
        const prevValue = cell.value;
        const value = cell.prevValue === undefined
          ? ''
          : cell.prevValue;
        return { ...cell, prevValue, value };
      });

      return {
        sheetId: sheet.sheetId,
        form,
      };
    });
  };

const getUpdateCellsUndoRedo = (
  payload: Array<SheetUpdateCells<UpdateCellData[]>>,
  updateCells: (payload: Array<SheetUpdateCells<UpdateCellData[]>>) => void,
): UndoRedoActionMethods => {
  return {
    undo: () => updateCells(getUndoCellsData(payload)),
    redo: () => updateCells(payload),
  };
};

const getFocusCellUndoRedo = (
  data: FocusChangeData,
  setFocusCell: (sheetId: string, cellData: string) => void,
): UndoRedoActionMethods => {
  const undoData = data.prevData;
  const redoData = data.newData;
  return {
    undo: () => setFocusCell(undoData.sheetId, undoData.cellData.cellId),
    redo: () => setFocusCell(redoData.sheetId, redoData.cellData.cellId),
  };
};

const getSelectedSheetIdUndoRedo = (
  sheetId: string,
  prevSheetId: string,
  setSelectedSheetId: (sheetId: string) => void,
): UndoRedoActionMethods => {
  return {
    undo: () => setSelectedSheetId(prevSheetId),
    redo: () => setSelectedSheetId(sheetId),
  };
};

const getUpdateArrangementUndoRedo = (
  type: Arrangement,
  prevType: Arrangement,
  setArrangement: (type: Arrangement) => void,
): UndoRedoActionMethods => {
  return {
    undo: () => setArrangement(prevType),
    redo: () => setArrangement(type),
  };
};

const getUpdateColumnWidthUndoRedo = (
  prevColumnWidth: UpdateColumnWidth[],
  columnWidth: UpdateColumnWidth[],
  setColumnData: (data: UpdateColumnWidth[]) => void,
): UndoRedoActionMethods => {
  return {
    undo: () => setColumnData(prevColumnWidth),
    redo: () => setColumnData(columnWidth),
  };
};

const getChangePageInfoUndoRedo = (
  prevPageInfo: ReportPage,
  pageInfo: ReportPage,
  updatePageInfo: (pageInfo: ReportPage) => void,
): UndoRedoActionMethods => {
  return {
    undo: () => updatePageInfo(prevPageInfo),
    redo: () => updatePageInfo(pageInfo),
  };
};

function deletePageUndoRedo(
  pageId: string,
  sessionIdMap: () => Record<string, string>,
  deletePage: (pageId: string) => void,
  restorePage: (pageId: string, sessionId: string) => void,
): UndoRedoActionMethods {
  let sessionId: string;
  return {
    redo: () => {
      sessionId = sessionIdMap()[pageId];
      deletePage(pageId);
    },
    undo: () => restorePage(pageId, sessionId),
  };
}

function createPageUndoRedo(
  oldPages: ReportPage[],
  reportSessionIdMap: () => Record<string, string>,
  restorePage: (pageId: string, sessionId: string) => void,
  deletePage: (pageId: string) => void,
  getPages: () => ReportPage[],
): UndoRedoActionMethods {
  let pageId: string;
  let sessionId: string;
  return {
    redo: () => restorePage(pageId, sessionId),
    undo: () => {
      if (!pageId) {
        const pages = new Set(oldPages.map(page => page.id));
        pageId = getPages().find(page => !pages.has(page.id))?.id;
      }
      if (pageId) {
        sessionId = reportSessionIdMap()[pageId];
        deletePage(pageId);
      }
    },
  };
}

function movePageUndoRedo(
  pageId: string,
  offset: number,
  moveToPage: (pageId: string, offset: number) => void,
): UndoRedoActionMethods {
  return {
    redo: () => moveToPage(pageId, offset),
    undo: () => moveToPage(pageId, -offset),
  };
}

export const TwoDReportUndoRedoHelper = {
  getUpdateCellsUndoRedo,
  getFocusCellUndoRedo,
  getSelectedSheetIdUndoRedo,
  getUpdateArrangementUndoRedo,
  getUpdateColumnWidthUndoRedo,
  getChangePageInfoUndoRedo,
  createPageUndoRedo,
  deletePageUndoRedo,
  movePageUndoRedo,
};
