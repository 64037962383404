import styled from 'styled-components';

const Container = styled.div<{ color: string }>`
  button {
    svg {
      fill: ${p => p.color};
    }
    :hover {
      svg {
        fill: ${p => p.color};
      }
    }
  }
`;

const DrawingsColorControl = styled.div`
  max-width: 100%;
  padding: 10px 0;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: ${p => p.theme.color.backgroundRush};
  border: 1px solid ${p => p.theme.color.background};
`;

export const Styled = {
  Container,
  DrawingsColorControl,
};
