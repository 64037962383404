import { DrawingsInstanceMeasure } from 'common/components/drawings';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import {
  FormulaDefaultFunctions,
  VALUE_TO_MEASURED_FUNCTION_MAP,
} from 'common/components/formula-editor/input/constants';
import { arrayUtils } from 'common/utils/array-utils';
import { MeasureUtil } from 'common/utils/measure-util';
import { TWODUnitConversionMap, UnitUtil } from 'common/utils/unit-util';
import {
  Property,
} from 'unit-2d-database/interfaces';
import { AssignedPia, PiaCalculated } from '../../interfaces';
import { AssignmentCalculator } from './assignment-calculator';
import { MeasuredValueGetter, PrecompiledFormulaApi } from './interfaces';

export function calculate(
  { items, assemblies }: AssignedPia,
  measuredValues: DrawingsInstanceMeasure,
  isImperial: boolean,
  originProperties: Property[],
  pageName: string,
  precompiledFormulas: PrecompiledFormulaApi,
): PiaCalculated {
  const originPropertiesDictionary = arrayUtils.toDictionaryByKey(originProperties, x => x.name);
  let hasMeasuredValueUsage = false;
  const measuredValuesCache: Record<string, number | string> = {
    [FormulaDefaultFunctions.MeasuredPageName]: pageName,
  };

  if (measuredValues?.type !== DrawingsInstanceType.Count) {
    measuredValuesCache[FormulaDefaultFunctions.MeasuredCount] = 1;
  }

  const getMeasuredValue: MeasuredValueGetter = (measuredValueName) => {
    if (!hasMeasuredValueUsage) {
      for (const measure of Object.keys(measuredValues.measures)) {
        const valueName = VALUE_TO_MEASURED_FUNCTION_MAP[measure];
        const sourceUnit = MeasureUtil.measureUnit[measure];
        if (sourceUnit && isImperial) {
          const conversionUnit = TWODUnitConversionMap[sourceUnit];
          const sourceValue = measuredValues.measures[measure];
          measuredValuesCache[valueName] = UnitUtil.convertUnit(sourceValue, sourceUnit, conversionUnit).value;
        } else {
          measuredValuesCache[valueName] = measuredValues.measures[measure];
        }
      }
    }
    hasMeasuredValueUsage = true;
    return measuredValuesCache[measuredValueName];
  };

  const assignmentsCalculator = new AssignmentCalculator({
    assemblies,
    items,
    getMeasuredValue,
    originProperties: originPropertiesDictionary,
    precompiledFormulas,
  });

  return {
    ...assignmentsCalculator.calculate(),
    hasMeasuredValueUsage,
  };
}
