import {
  Input,
  RectangleButton,
  Switcher,
  Text,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { change, Field, FormSubmitHandler, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { ImageUploader } from 'common/components/image-uploader';
import { ConstantFunctions } from 'common/constants/functions';
import { InputPropsInterface } from 'common/interfaces/library/input';
import { State } from 'common/interfaces/state';
import { CommonsApi } from '../../../../../api/common';
import Form from '../../../../../components/form';
import { UPDATE_DEMO_PROJECT } from '../../../../../constants/forms';
import { AdminDemoProjectsApi } from '../../../api/demo-projects';
import { Styled } from './styled';

export const UPDATE_DEMO_PROJECT_DIALOG_NAME = 'UPDATE_DEMO_PROJECT_DIALOG_NAME';

const selector = formValueSelector(UPDATE_DEMO_PROJECT);


interface StateProps {
  id: number;
  disableSubmit: boolean;
  isImperial: boolean;
}

interface DispatchProps {
  setImage: (value: string) => void;
  setProjectName: (value: string) => void;
  setOrderIndex: (value: number | null) => void;
  setIsImperial: (value: boolean) => void;
}

interface Props extends StateProps, DispatchProps, InjectedFormProps<{}, {}> { }

class UpdateDemoProjectFormComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { disableSubmit, id } = this.props;
    return (
      <Styled.Container>
        <Form
          handleSubmit={this.handleSubmit}
        >
          <Styled.InputWrapper>
            <ImageUploader
              previewUrl={CommonsApi.tempFileLink}
              defaultImagePreviewUrl={AdminDemoProjectsApi.getScreenUrl(id)}
              deleteImage={ConstantFunctions.doNothing}
              onLoadEnd={this.props.setImage}
              size={'big'}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Field<InputPropsInterface>
              width={'340px'}
              name='name'
              component={Input}
              type='text'
              text={'Project name'}
            />
          </Styled.InputWrapper>
          <Styled.Separator />
          <Styled.InputWrapper>
            <Text>Is Imperial</Text>
            <Switcher
              size='l'
              checked={this.props.isImperial}
              onChange={this.setIsImperial}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Field<InputPropsInterface>
              width={'340px'}
              isMathInput={true}
              name='orderIndex'
              component={Input}
              type='text'
              text={'Order Index'}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Field<InputPropsInterface>
              width={'340px'}
              isMathInput={true}
              name='description'
              component={Input}
              type='text'
              text={'Description'}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Field<InputPropsInterface>
              width={'340px'}
              isMathInput={true}
              name='tags'
              component={Input}
              type='text'
              text={'Tags'}
            />
          </Styled.InputWrapper>
          <RectangleButton
            width={340}
            height={60}
            mood={disableSubmit ? 'disabled' : 'positive'}
            text='Update project'
          />
        </Form>
      </Styled.Container>
    );
  }

  @autobind
  private setIsImperial(): void {
    this.props.setIsImperial(!this.props.isImperial);
  }

  @autobind
  private handleSubmit(handler: FormSubmitHandler<{}, {}>): void {
    if (!this.props.disableSubmit) {
      this.props.handleSubmit(handler);
      this.props.setProjectName('');
      this.props.setOrderIndex(null);
    }
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    id: selector(state, 'id'),
    disableSubmit: !selector(state, 'name'),
    isImperial: selector(state, 'isImperial'),
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setImage: (value) => dispatch(change(UPDATE_DEMO_PROJECT, 'image', value)),
    setProjectName: (value) => dispatch(change(UPDATE_DEMO_PROJECT, 'name', value)),
    setOrderIndex: (value) => dispatch(change(UPDATE_DEMO_PROJECT, 'orderIndex', value)),
    setIsImperial: (value) => dispatch(change(UPDATE_DEMO_PROJECT, 'isImperial', value)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
const UpdateDemoProjectFormConnected = connector(UpdateDemoProjectFormComponent);

export const UpdateDemoProjectForm = reduxForm<{}, {}>({
  form: UPDATE_DEMO_PROJECT,
  destroyOnUnmount: true,
})(UpdateDemoProjectFormConnected);
