import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  background-color: ${props => props.theme.color.background};

  & ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  & ::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }
`;

export const Styled = {
  Container,
};
