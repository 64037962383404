import { useCallback, useState } from 'react';

export interface NumberState {
  value: number;
  onBlur: (newValue: number) => void;
  onChange: (newValue: number) => void;
  isChanged: boolean;
}

export const useNumberState = (): NumberState => {
  const [value, changeValue] = useState<number>();
  const [isChanged, setIsChange] = useState<boolean>(false);

  const handleChange = useCallback((newValue: number) => {
    setIsChange(true);
    changeValue(newValue);
  }, []);

  return {
    value,
    onBlur: handleChange,
    onChange: handleChange,
    isChanged,
  };
};
