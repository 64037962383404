import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { useSelector } from 'react-redux';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { RadioMenu } from 'common/components/selection-menus';
import { OptionInfo } from 'common/components/selection-menus/interfaces';
import { State } from 'common/interfaces/state';
import { BillingPeriodUnit } from '../../../enums/billing-period-unit';
import { Styled } from './styled';


const PeriodOptions: Array<OptionInfo<BillingPeriodUnit>> = [
  {
    name: 'Monthly billing',
    value: BillingPeriodUnit.Month,
  },
  {
    name: 'Annually billing',
    value: BillingPeriodUnit.Year,
  },
];

interface Props {
  onSelectionChanged: (billingPeriod: BillingPeriodUnit) => void;
  billingPeriod: BillingPeriodUnit;
  options?: Array<OptionInfo<BillingPeriodUnit>>;
  discount?: string;
}

export const ChangeBillingPeriodRadioMenu: React.FC<Props> = ({
  billingPeriod,
  options,
  discount,
  onSelectionChanged,
}) => {
  const isTwoBillingPeriod = useSelector<State, boolean>(s => s.account.isTwoBillingPeriod);
  const hasMonthVariants = useSelector<State, boolean>(s => s.account.hasMonthBillingPeriod);
  const hasYearVariants = useSelector<State, boolean>(s => s.account.hasYearBillingPeriod);
  React.useEffect(() => {
    if (isTwoBillingPeriod) {
      return;
    }
    if (billingPeriod === BillingPeriodUnit.Month && !hasMonthVariants) {
      onSelectionChanged(BillingPeriodUnit.Year);
    }
    if (billingPeriod === BillingPeriodUnit.Year && !hasYearVariants) {
      onSelectionChanged(BillingPeriodUnit.Month);
    }
  }, [billingPeriod]);
  const needHideBillingPeriod = useAbility(Operation.Manage, Subject.BillingPeriod)
    || !isTwoBillingPeriod;
  const periodOptions = options ? options : PeriodOptions;
  const handleSelectionChange = React.useCallback((value: BillingPeriodUnit) => {
    onSelectionChanged(value);
  }, [onSelectionChanged]);

  if (needHideBillingPeriod) {
    return null;
  }

  return (
    <Styled.RadioMenuContainer>
      <RadioMenu
        options={periodOptions}
        onSelectionChanged={handleSelectionChange}
        value={billingPeriod}
      />
      <Styled.Discount>
        <Text fontSize={12} color={'turquoise'}>{discount ? discount : 'Save 11%'}</Text>
      </Styled.Discount>
    </Styled.RadioMenuContainer>
  );
};
