import * as monolite from 'monolite';

import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { MODEL_CHECK_INITIAL_STATE } from '../../interfaces/model-check/initial-state';
import { ModelCheckAvailableCheck } from '../../interfaces/model-check/model-check-available-checks';
import * as actionTypes from './action-types';
import { ModelCheckState } from './interfaces/model-check-state';
import { ScrollToPayload } from './interfaces/payloads';
import { BimHandeIdToRevitId, ErrorHandle, SelectedHandle } from './interfaces/types';

export const modelCheckerReducerMethods: ReducerMethods<ModelCheckState> = {
  [actionTypes.MODEL_CHECK_DROP_STATE]: () => {
    return MODEL_CHECK_INITIAL_STATE;
  },
  [actionTypes.MODEL_CHECK_GET_DATA]: (state) => {
    return monolite.set(state, (sourceState) => sourceState.loaded)(false);
  },
  [actionTypes.MODEL_CHECK_GET_DATA_SUCCESSED]: (state, payload) => {
    const selectedChecks = {};
    for (const issue of payload.issues) {
      if (issue) {
        selectedChecks[issue.modelCheckType] = true;
      }
    }
    return new MonoliteHelper(state)
      .set(sourceState => sourceState.errors, payload.errors)
      .set(sourceState => sourceState.trivial, payload.trivial)
      .set(sourceState => sourceState.warning, payload.warning)
      .set(sourceState => sourceState.loaded, true)
      .set(sourceState => sourceState.totalBim, payload.totalBim)
      .set(sourcestate => sourcestate.issues, payload.issues)
      .set(sourceState => sourceState.selectedChecks, selectedChecks)
      .get();
  },
  [actionTypes.MODEL_CHECK_RUN]: (state) => {
    return new MonoliteHelper(state)
      .set(sourceState => sourceState.errors, [])
      .set(sourceState => sourceState.trivial, [])
      .set(sourceState => sourceState.warning, [])
      .set(sourceState => sourceState.loaded, false)
      .set(sourceState => sourceState.totalBim, null)
      .set(sourcestate => sourcestate.issues, [])
      .get();
  },
  [actionTypes.MODEL_CHECK_ORDER_SELECT]: (state, payload) => {
    let newState = monolite.set(state, (sourceState) => sourceState.selectedErrorCategory)(payload);
    newState = monolite.set(newState, (sourceState) => sourceState.secondHandle)(null);
    newState = monolite.set(newState, (sourceState) => sourceState.selectedHandle)(null);
    return newState;
  },
  [actionTypes.MODEL_CHECK_SELECT_HANDLE]: (state, payload) => {
    const categoryHandles = state.errors[state.selectedErrorCategory - 1];
    let handle = null;
    if (categoryHandles[0].bimHandleId !== undefined) {
      const selectedHandle = categoryHandles.find((x: any): any => x.bimHandleId === payload);
      handle = {
        bimHandleId: payload,
        category: selectedHandle.firstCategory,
        family: selectedHandle.firstFamily,
        type: selectedHandle.firstType,
        bimHandleIds: selectedHandle.bimHandleIds,
        revitId: selectedHandle.revitId,
      };
    } else {
      let revitId = '';
      const selectedHandle = categoryHandles.find((x: any): any => x.bimHandleIds.find(
        (pair: BimHandeIdToRevitId) => {
          revitId = pair.revitId;
          return pair.bimHandleId === payload;
        }) !== undefined);
      handle = {
        bimHandleId: payload,
        category: selectedHandle.category,
        family: selectedHandle.family,
        type: selectedHandle.type,
        revitId,
      };
    }

    const newState = monolite.set(state, (sourceState) => sourceState.secondHandle)(null);
    return monolite.set(newState, (sourceState) => sourceState.selectedHandle)(handle);
  },
  [actionTypes.MODEL_CHECK_DESELECT]: (state) => {
    let newState = monolite.set(state, (sourceState) => sourceState.secondHandle)(null);
    newState = monolite.set(newState, (sourceState) => sourceState.selectedHandle)(null);
    return monolite.set(newState, (sourceState) => sourceState.selectedErrorCategory)(null);
  },
  [actionTypes.SHOW_HIDE_PROPS]: (state) => {
    return monolite.set(state, (sourceState) => sourceState.showProps)(!state.showProps);
  },
  [actionTypes.DESELECT_HANDLE]: (state) => {
    const newState = monolite.set(state, (sourceState) => sourceState.secondHandle)(null);
    return monolite.set(newState, (sourceState) => sourceState.selectedHandle)(null);
  },
  [actionTypes.SELECT_SECOND_HANDLE]: (state, payload) => {
    let revitId: string = '';
    const selectedHandle = (state.selectedHandle.bimHandleIds as ErrorHandle[])
      .find((x: any) =>
        (x.bimHandleIds as BimHandeIdToRevitId[]).find((pair) => {
          revitId = pair.revitId;
          return pair.bimHandleId === payload;
        }) !== undefined);
    const handle: SelectedHandle = {
      bimHandleId: payload,
      revitId,
      category: selectedHandle.category,
      family: selectedHandle.family,
      type: selectedHandle.type,
      bimHandleIds: [],
    };
    return monolite.set(state, (sourceState) => sourceState.secondHandle)(handle);
  },
  [actionTypes.SELECT_BY_ID]: (state, payload: ScrollToPayload) => {
    const handles = state.errors[state.selectedErrorCategory - 1];
    if (handles.length > 0) {
      if (handles[0].bimHandleId !== undefined) {
        let newState = state;
        const selectedHandle = handles.find((element, index): boolean => {
          if (element.bimHandleId === payload.id) {
            payload.api(index);
            return true;
          } else {
            let revitId = '';
            let secondHandle: ErrorHandle;
            for (const errorHandle of (element.bimHandleIds as ErrorHandle[])) {
              const result = (errorHandle.bimHandleIds as BimHandeIdToRevitId[]).find((pair) => {
                revitId = pair.revitId;
                return pair.bimHandleId === payload.id;
              });
              if (result) {
                secondHandle = errorHandle;
                break;
              }
            }
            if (secondHandle !== undefined) {
              const secondSelectedHandle = {
                revitId,
                bimHandleId: payload.id,
                category: secondHandle.category,
                family: secondHandle.family,
                type: secondHandle.type,
                bimHandleIds: [],
              };
              newState = monolite.set(newState, (sourceState) => sourceState.secondHandle)(secondSelectedHandle);
              payload.api(index);
              return true;
            } else {
              return false;
            }
          }
        });
        const handle: SelectedHandle = {
          revitId: selectedHandle.revitId,
          bimHandleId: selectedHandle.bimHandleId,
          category: selectedHandle.firstCategory,
          family: selectedHandle.firstFamily,
          type: selectedHandle.firstType,
          bimHandleIds: selectedHandle.bimHandleIds,
        };
        return monolite.set(newState, (sourceState) => sourceState.selectedHandle)(handle);
      } else {
        let revitId = '';
        const selectedHandleIndex = handles.findIndex((element: ErrorHandle): boolean =>
          (element.bimHandleIds as BimHandeIdToRevitId[])
            .find((pair: BimHandeIdToRevitId) => {
              revitId = pair.revitId;
              return pair.bimHandleId === payload.id;
            }) !== undefined);
        payload.api(selectedHandleIndex);
        const selectedHandle = handles[selectedHandleIndex];
        const handle: SelectedHandle = {
          bimHandleId: payload.id,
          revitId,
          category: selectedHandle.category,
          family: selectedHandle.family,
          type: selectedHandle.type,
          bimHandleIds: [],
        };
        return monolite.set(state, (sourceState) => sourceState.selectedHandle)(handle);
      }
    } else {
      return state;
    }
  },
  [actionTypes.MODEL_CHECK_GET_AVAILABLE_CHECKS_SUCCESS]: (state, availableChecks: ModelCheckAvailableCheck[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.availableChecks, availableChecks.sort((a, b) => a.order - b.order))
      .get();
  },
  [actionTypes.MODEL_CHECK_TOGGLE_CHECK_SELECTED]: (state, type: string) => {
    return new MonoliteHelper(state).set(_ => _.selectedChecks[type], _ => !_).get();
  },
};
