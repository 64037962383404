import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { isEqual, xor } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';


import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { ConstantFunctions } from 'common/constants/functions';
import { StringDictionary } from 'common/interfaces/dictionary';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { CEActivityAssignmentTreeNodeActivitySort } from 'unit-cost-estimate/interfaces';
import { CEActivityAssignmentUtils } from 'unit-cost-estimate/utils/ce-activity-assignment-utils';
import { DatabaseAssignActivityRouteParams } from '../../../../routes/app-routes-params';
import { ActivityCategoryType } from '../../../../units/databases/enums';
import {
  ActivityCategory,
  ActivityVariantActivityAssignmentInfoModel,
  DatabaseModel,
} from '../../../../units/databases/interfaces/data';
import { DatabaseActivityListingActions } from '../../../databases/actions/creators/database-activity-listing';
import { DatabaseActivityListing } from '../../../databases/components/database-activity-listing';
import { CEActivityAssignmentActions } from '../../actions/creators/activity-assignment';
import {
  ActivityAssignmentDatabaseActivityListingDialog,
} from './activity-assignment-database-activity-listing-dialog';


interface ReduxProps {
  selectedWork: CEActivityAssignmentTreeNodeActivitySort;
  currentDatabase: DatabaseModel;
  selectedActivityVariantIds: number[];
  variantInfoModels: ActivityVariantActivityAssignmentInfoModel[];
  activityCategories: StringDictionary<ActivityCategory[]>;
  selectedCategoryType: ActivityCategoryType;
}

interface ReduxActions {
  getWork: (workId: number) => void;
  setDisabledActivityVariants: (ids: number[]) => void;
  setSelectedActivityVariants: (ids: number[]) => void;
  createActivitiesCodeGroup: (variants: ActivityVariantActivityAssignmentInfoModel[]) => void;
  assignModalities: (work: CEActivityAssignmentTreeNodeActivitySort) => void;
  goToEditActivityAssignmentPage: (projectId: string) => void;
  closeAssignDialog: () => void;
  setDefaultCategories: (categories: StringDictionary<ActivityCategory>) => void;
  setSelectedCategory: (category: ActivityCategory) => void;
}

interface OwnProps {
  projectId: number;
  revisionId: number;
  databaseId: number;
  workId: number;
}

interface Props extends
  ReduxProps,
  ReduxActions,
  AbilityAwareProps,
  RouteComponentProps<DatabaseAssignActivityRouteParams>,
  OwnProps {
}

interface State {
  variantInfoModels: ActivityVariantActivityAssignmentInfoModel[];
}

class ActivityAssignmentDatabaseActivityListingComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      variantInfoModels: [],
    };
  }

  public componentDidMount(): void {
    if (!this.props.selectedWork) {
      this.props.getWork(this.props.workId);
    } else {
      this.setSelectedAndDisabledActivityVariants();
      this.setDefaultActivityCategories();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (!isEqual(prevProps.selectedWork, this.props.selectedWork) && this.props.selectedWork) {
      this.setSelectedAndDisabledActivityVariants();
    }

    if (this.props.selectedWork && (
      !isEqual(prevProps.selectedWork, this.props.selectedWork) ||
      !isEqual(prevProps.databaseId, this.props.databaseId) ||
      !isEqual(prevProps.activityCategories, this.props.activityCategories)
    )) {
      this.setDefaultActivityCategories();
    }

    this.updateSelectedActivityVariantInfoModels(prevProps);
  }

  public render(): JSX.Element {
    const isReadonly = !this.props.currentDatabase ||
      this.props.currentDatabase.isReadOnly ||
      !this.props.ability.can(Operation.Create, Subject.Database);
    return (
      <React.Fragment>
      {
        this.props.selectedWork ? (
          <React.Fragment>
            <DatabaseActivityListing
              databaseId={this.props.databaseId}
              isCheckboxesVisible={true}
            />
            <ActivityAssignmentDatabaseActivityListingDialog
              onAlwaysClick={this.onAlwaysClick}
              onJustOnceClick={this.onJustOnceClick}
              isReadOnly={isReadonly}
              elementName={this.props.selectedWork.name}
            />
          </React.Fragment>
        ) : <SvgSpinner size='large'/>
      }
      </React.Fragment>
    );
  }

  @autobind
  private onJustOnceClick(): void {
    this.assignModalities();
    this.props.closeAssignDialog();
    this.props.goToEditActivityAssignmentPage(this.props.match.params.projectId);
  }

  @autobind
  private onAlwaysClick(): void {
    this.assignModalities();
    this.props.createActivitiesCodeGroup(this.state.variantInfoModels);
    this.props.closeAssignDialog();
    this.props.goToEditActivityAssignmentPage(this.props.match.params.projectId);
  }

  private assignModalities(): void {
    const work = CEActivityAssignmentUtils.putModalityToActivity(this.props.selectedWork, this.state.variantInfoModels);
    this.props.assignModalities(work);
  }

  private setSelectedAndDisabledActivityVariants(): void {
    const disabledVariantIds = Object.keys(this.props.selectedWork.data.variants).map(x => +x);
    this.props.setDisabledActivityVariants(disabledVariantIds);
    this.props.setSelectedActivityVariants(disabledVariantIds);
  }

  private updateSelectedActivityVariantInfoModels(prevProps: Props): void {
    const diffIds = xor(prevProps.selectedActivityVariantIds, this.props.selectedActivityVariantIds);
    if (!diffIds.length) {
      return;
    }
    const removedIds = diffIds.filter(x => prevProps.selectedActivityVariantIds.includes(x));
    const addedIds = diffIds.filter(x => this.props.selectedActivityVariantIds.includes(x));
    const updatedVariants = this.state.variantInfoModels
      .filter(x => !removedIds.includes(x.id))
      .concat(this.props.variantInfoModels.filter(x => addedIds.includes(x.id)));
    this.setState({ variantInfoModels: updatedVariants });
  }

  private setDefaultActivityCategories(): void {
    const defaultCategories = {};
    const appendDefaultCategory = (type: ActivityCategoryType, code: string): void => {
      const category = this.getCategoryCategoryByTypeAndCode(type, code);
      if (category) {
        defaultCategories[type] = category;
      }
    };
    appendDefaultCategory(ActivityCategoryType.Nrm1, this.props.selectedWork.predictedNrm1);
    appendDefaultCategory(ActivityCategoryType.Nrm2, this.props.selectedWork.predictedNrm2);
    appendDefaultCategory(ActivityCategoryType.UniProduct, this.props.selectedWork.uniProduct);
    appendDefaultCategory(ActivityCategoryType.UniSystem, this.props.selectedWork.uniSystem);

    this.props.setDefaultCategories(defaultCategories);
    const selectedCategory = defaultCategories[this.props.selectedCategoryType];
    if (selectedCategory) {
      this.props.setSelectedCategory(selectedCategory);
    }
  }

  private getCategoryCategoryByTypeAndCode(type: ActivityCategoryType, code: string): ActivityCategory | null {
    const separator = type === ActivityCategoryType.Nrm1 || type === ActivityCategoryType.Nrm2 ? '.' : '_';
    return this.getCategoryByCode(this.props.activityCategories[type], code, separator);
  }

  private getCategoryByCode(categories: ActivityCategory[], code: string, separator: string): ActivityCategory | null {
    if (!code) {
      return null;
    }
    const subCodes = code.split(separator).map((_, index, array) => array.slice(0, index + 1).join(separator));
    let category: ActivityCategory = null;
    let children: ActivityCategory[] = categories;
    for (const subCode of subCodes) {
      category = children.find(x => x.code.toLowerCase() === subCode);
      if (category) {
        if (category.code.toLowerCase() === code) {
          return category;
        }
        children = category.children;
      }
    }

    return null;
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    selectedWork: state.ceActivityAssignment.selectedWork,
    currentDatabase: state.database.currentDatabase.database,
    selectedActivityVariantIds: state.database.currentDatabase.activityListing.selectedActivityVariantIds,
    variantInfoModels: state.database.currentDatabase.activityListing.activityVariantActivityAssignmentInfo,
    activityCategories: state.database.categories,
    selectedCategoryType: state.database.currentDatabase.activityListing.categories.selectedCategoryType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): ReduxActions => {
  return {
    getWork: (workId) => dispatch(CEActivityAssignmentActions.loadWork(workId)),
    setDisabledActivityVariants: (ids) => dispatch(DatabaseActivityListingActions.setDisabledActivityVariantIds(ids)),
    setSelectedActivityVariants: (ids) => dispatch(DatabaseActivityListingActions.setSelectedActivityVariantIds(ids)),
    assignModalities: work => dispatch(CEActivityAssignmentActions.assignModalities(work)),
    createActivitiesCodeGroup: _variants => ConstantFunctions.doNothing,
    goToEditActivityAssignmentPage: () => dispatch(
      push(AppUrls.costEstimate.project.revision.activityAssignment.edit.url({
        projectId: ownProps.projectId.toString(),
        revisionId: ownProps.revisionId.toString(),
      }))),
    closeAssignDialog: () =>
      dispatch(KreoDialogActions.closeDialog(ActivityAssignmentDatabaseActivityListingDialog.dialogName)),
    setDefaultCategories: (categories) => dispatch(DatabaseActivityListingActions.setDefaultCategories(categories)),
    setSelectedCategory: (category) => dispatch(DatabaseActivityListingActions.setSelectedCategory(category)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityAssignmentDatabaseActivityListing = withRouter(withAbilityContext(
  connector(ActivityAssignmentDatabaseActivityListingComponent)));
