

export const DPI_MODE_NAMES = {
  150: 'Quick',
  300: 'Standard',
  450: 'Thorough',
  600: 'Detailed',
};


export const SEARCH_AND_MEASURE_DPIs = Object.keys(DPI_MODE_NAMES);
