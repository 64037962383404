import { css } from 'styled-components';

export const CommonScrollStyle = css`
  & ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
    :hover,
    :active {
      background: ${p => p.theme.color.gray};
    }
  }

  & ::-webkit-scrollbar-track {
    background: ${p => p.theme.color.background};
  }

  & ::-webkit-scrollbar {
    width: 5px;
  }

  & ::-webkit-scrollbar-corner {
      background-color: ${p => p.theme.color.background};
    }
`;
