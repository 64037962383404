import autobind from 'autobind-decorator';
import { ConstantFunctions } from 'common/constants/functions';
import { EngineObject, EngineObjectConfig } from '../../../common';
import { PaperMouseEventUtils } from '../../../drawings-helpers/paper-mouse-event-utils';
import { PaperObjectEventsApplier } from '../../../interfaces';
import { PaperMouseEventHandler } from '../../../interfaces/helpers/mouse-event-handler';

interface Config extends EngineObjectConfig {
  onClick?: PaperMouseEventHandler<string>;
}

export class MeasureLengthEventsApplier extends EngineObject<Config> implements PaperObjectEventsApplier<string>  {
  private _extraInfo: string;

  public setExtraInfo(extraInfo: string): void {
    this._extraInfo = extraInfo;
  }

  public setHandlers(path: paper.Item): void {
    path.onClick = this.onClick;
  }

  @autobind
  private onClick(e: PaperMouseEvent): void {
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      ConstantFunctions.stopEvent(e);
      this._config.onClick(e, this._extraInfo);
    }
  }
}
