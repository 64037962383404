import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { CompaniesSelectWithTheme } from 'common/components/companies-select-with-theme';
import { PageHeader } from 'common/components/page-header';
import { AppUrls } from 'routes/app-urls';
import { UpgradePlan } from '../../components/upgrade-plan-component';
import { SubscribePageStyled } from '../subscribe-page';

export const UpgradeSubscriptionPage = (): JSX.Element => {
  return (
    <SubscribePageStyled.Container>
      <SubscribePageStyled.Header>
        <PageHeader
          redirectUrl={AppUrls.products.path}
          logoSrc='/static/icons/kreo-logo-description.svg'
          Icon={Icons.KreoLogo2D}
          hideButton={true}
          size={50}
        >
          <CompaniesSelectWithTheme />
        </PageHeader>
      </SubscribePageStyled.Header>
      <SubscribePageStyled.Main>
        <UpgradePlan />
      </SubscribePageStyled.Main>
    </SubscribePageStyled.Container>
  );
};
