import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WizzardToolsActions } from 'common/components/drawings/actions/creators';
import { FinderSelectedVisiblity } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';

export function useToggleGeometriesVisibility(key: keyof FinderSelectedVisiblity): [boolean, () => void] {
  const visible = useSelector<State, boolean>(x => x.drawings.wizzardTools.finderSelectedVisibility[key]);

  const dispatch = useDispatch();

  const toggle = useCallback(() => {
    dispatch(WizzardToolsActions.setFinderSelectionVisibility(key, !visible));
  }, [visible, dispatch]);
  return [visible, toggle];
}


export function useRemoveGeometries(geometriesIds: number[]): () => void {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(WizzardToolsActions.addGeometriesToRemove(geometriesIds));
  }, [dispatch, geometriesIds]);
}
