import { Constants, Text } from '@kreo/kreo-ui-components';

import React, { useMemo } from 'react';

import { TagsInput } from 'common/components/tag-input';
import { EstimateSubscriptionError } from '../../../interfaces';
import { Styled } from './styled';

interface Props {
  coupons: string[];
  addCoupons: (coupons: string[]) => void;
  error: EstimateSubscriptionError;
  placeHolder?: string;
}

const CouponsComponent: React.FC<Props> = ({ coupons, addCoupons, error, placeHolder }) => {
  const invalidCoupons = useMemo(
    () => {
      if (Number.isInteger(error?.couponIndex)) {
        const coupon = coupons[error.couponIndex];
        return coupon ? [coupon] : [];
      }
      return [];
    },
    [coupons, error],
  );

  const adviceText = error ? error.message : placeHolder ? '' : 'press "Enter" to add the promo code';

  return (
    <Styled.Container>
      <TagsInput
        tags={coupons}
        onChange={addCoupons}
        invalidTags={invalidCoupons}
        placeHolder={placeHolder || 'Insert the promo code'}
      />
      <Text
        color='disabled'
        customColor={error ? Constants.Colors.GENERAL_COLORS.red : undefined}
        fontSize={10}
      >
        {adviceText}
      </Text>
    </Styled.Container>
  );
};

export const Coupons = React.memo(CouponsComponent);
