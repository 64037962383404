import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './activity-assignment-revit-tree-item.scss';

import { State } from 'common/interfaces/state';
import { ActivityAssignmentActions } from '../../actions/creators/activity-assignment';
import { RevitTreeLevel } from '../../enums/revit-tree-level';
import { ActivityAssignmentUtils } from '../../utils/activity-assignment-utils';
import { RevitTreeBaseItem } from '../revit-tree-base-item';
import { RevitTreeErrorAmount } from '../revit-tree-error-amount';

interface OwnProps {
  expandable: boolean;
  expanded: boolean;
  title: string;
  level: RevitTreeLevel;
  path: string;
  start: number;
  end: number;
  error: boolean;
  all: number;
  errors: number;
}


interface StateProps {
  selected: boolean;
  searched: boolean;
}

interface DisaptchProps {
  onExpand: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface Props extends StateProps, DisaptchProps, OwnProps {

}

class ActivityAssignmentRevitTreeItemComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      selected,
      error,
      expandable,
      expanded,
      onExpand,
      onClick,
      title,
      errors,
      all,
      level,
      searched,
    } = this.props;
    const className = classNames(
      'activity-assignment-revit-tree-item',
      {
        'activity-assignment-revit-tree-item--selected': selected,
        'activity-assignment-revit-tree-item--searched': searched,
        'activity-assignment-revit-tree-item--error': error && !expandable,
      },
    );
    return (
      <RevitTreeBaseItem
        className={className}
        expandable={expandable}
        expanded={expanded}
        onExpand={onExpand}
        title={title}
        level={level + 1}
        onClick={onClick}
        onDoubleClick={onExpand}
      >
        { expandable && <RevitTreeErrorAmount error={errors} all={all} />}
      </RevitTreeBaseItem>
    );
  }
}

function mapStateToProps({ activityAssignment }: State, { path, level }: OwnProps): StateProps {
  return {
    searched:
      activityAssignment.searchMode &&
      activityAssignment.searchedPathes.includes(path.replace('tree.', '')),
    selected: ActivityAssignmentUtils.itemIsSelected(activityAssignment, path, level + 1),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<AnyAction>,
  { path, level, title, expandable }: OwnProps,
): DisaptchProps {
  return {
    onExpand: (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      dispatch(ActivityAssignmentActions.expandItem(path));
    },
    onClick: (e: React.MouseEvent<HTMLDivElement>) => {
      if (expandable) {
        dispatch(ActivityAssignmentActions.selectExpandableTreeNode(path));
      } else {
        e.stopPropagation();
        if (level === RevitTreeLevel.Element) {
          dispatch(ActivityAssignmentActions.showWorksFromLayer({
            path,
            endElementTitle: title,
          }));
        } else {
          dispatch(ActivityAssignmentActions.showWorksFromType(path));
        }
      }
    },
  };
}

const connector = connect<StateProps, DisaptchProps, OwnProps>(mapStateToProps, mapDispatchToProps);
export const ActivityAssignmentRevitTreeItem = connector(ActivityAssignmentRevitTreeItemComponent);
