import autobind from 'autobind-decorator';
import * as React from 'react';

import { Checkbox } from 'common/UIKit';

interface Props {
  title: string;
  checked: boolean;
  onCheckbox: (title: string) => void;
}

export class ItemWithCheckbox extends React.Component<Props> {
  public render(): JSX.Element {
    const { title, checked } = this.props;

    return (
      <div
        className='drop-menu__item'
        onClick={this.onClick}
      >
        <Checkbox checked={checked} />
        <span className='drop-menu__item-title'>{title}</span>
      </div>
    );
  }

  @autobind
  private onClick(): void {
    this.props.onCheckbox(this.props.title);
  }
}
