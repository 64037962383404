import React from 'react';
import { RoundSwitcher } from 'common/components/round-switcher';
import { Styled } from './styled';
import { useReduxState } from './use-redux-state';

const UNIT_SWITCHER_CONFIG = [
  { mode: 'Metric', text: 'Metric', description: 'm², m' },
  { mode: 'Imperial', text: 'Imperial', description: 'ft², ft'  },
];

const UnitsSwitcherComponent: React.FC = () => {
  const { isImperial, toggle } = useReduxState();

  return (
    <Styled.RoundSwitcher>
      <RoundSwitcher
        modes={UNIT_SWITCHER_CONFIG}
        currentMode={isImperial ? 'Imperial' : 'Metric'}
        toggleMode={toggle}
        elementHeight={30}
      />
    </Styled.RoundSwitcher>
  );
};

export const UnitsSwitcher = React.memo(UnitsSwitcherComponent);
