import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { DrawingsFile, DrawingsFiles } from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingsFSHighlightState } from 'common/components/drawings/interfaces/drawings-state';
import { DrawingsPdfBrowserUtils } from 'common/components/drawings/utils/drawings-pdf-browser-utils';
import { TreeTableRow } from 'common/components/tree-table/interfaces';
import { Body } from './body';
import { DrawingsPdfBrowserFileTreeFooter } from './footer';
import { DrawingsPdfBrowserFileTreeHead } from './head';


import { Styled } from './styled';

interface Props {
  canEdit: boolean;
  files: DrawingsFiles;
  highlightState: DrawingsFSHighlightState;
  renderHeaderAdditionalContent?: () => React.ReactNode;
  renderFooterContent?: () => React.ReactNode;
  onExpandEntity: (id: string) => void;
  onSelectFile: (id: string) => void;
  onDragRow?: (parentId: string | null, position: number, rowIdsToMove: string[]) => void;
  addFolder: (parentId?: string) => void;
  onChangeName?: (id: string, name: string) => void;
  startAddFile?: (id: string) => void;
  onDeleteClick?: (id: string[]) => void;
  getContextMenuItems: () => Array<Ag.MenuItemDef | string>;
  getSelectedPagesCount: (entity: DrawingsFile) => number;
}


export class DrawingsPdfBrowserFileTree extends React.Component<Props> {
  private isDragging: boolean = false;
  private rows: Array<TreeTableRow<string>> = [];
  private allFilesCount: number = 0;

  public render(): React.ReactNode {
    const {
      renderHeaderAdditionalContent,
      renderFooterContent,
      canEdit,
      files,
      onSelectFile,
      onDragRow,
      addFolder,
      onChangeName,
      startAddFile,
      onDeleteClick,
    } = this.props;
    const { allFilesCount } = this.getRows();
    const hasFooter = !!renderFooterContent;
    const hasHeader = !!renderHeaderAdditionalContent;
    return (
      <Styled.Container
        hasFooter={hasFooter}
      >
        <DrawingsPdfBrowserFileTreeHead
          filesCount={allFilesCount}
        >
          { hasHeader ? renderHeaderAdditionalContent() : null}
        </DrawingsPdfBrowserFileTreeHead>
        <Body
          files={files}
          handleDragRow={onDragRow}
          changeName={onChangeName}
          handleRowClicked={onSelectFile}
          canEdit={canEdit}
          handleDelete={onDeleteClick}
          addFolder={addFolder}
          addFile={startAddFile}
        />
        {
          hasFooter ? (
            <DrawingsPdfBrowserFileTreeFooter>
              {renderFooterContent()}
            </DrawingsPdfBrowserFileTreeFooter>
          ) : null
        }
      </Styled.Container>
    );
  }

  @autobind
  private getRows(): { rows: Array<TreeTableRow<string>>, allFilesCount: number } {
    if (!this.isDragging) {
      const { rows, allFilesCount } = this.getNewRowsInfo();
      this.rows = rows;
      this.allFilesCount = allFilesCount;
      return { rows, allFilesCount };
    } else {
      return { rows: this.rows, allFilesCount: this.allFilesCount };
    }
  }

  private getNewRowsInfo(): { rows: Array<TreeTableRow<string>>, allFilesCount: number } {
    const rows = [];
    const rootRows = [];
    let allFilesCount = 0;
    for (const value of Object.values(this.props.files.entities)) {
      if (DrawingsPdfBrowserUtils.isFile(value)) {
        allFilesCount++;
      }

      const row = {
        ...value,
        selectedPagesCount: DrawingsPdfBrowserUtils.isFile(value) ? this.props.getSelectedPagesCount(value) : null,
      };
      if (value.parentId) {
        rows.push(row);
      } else {
        rootRows.push(row);
      }
    }

    rows.unshift(...rootRows);

    return { rows, allFilesCount };
  }
}
