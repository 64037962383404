import styled from 'styled-components';

const MetricInputStyled = styled.div`
  label {
    margin-bottom: 0px;
  }
`;

export const Styled = {
  MetricInputStyled,
};
