import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { DrawingsCanvasConstants } from '../../../../constants/drawing-canvas-constants';
import { ContextObserver } from '../../../../drawings-contexts';
import { DrawingsRenderParams } from '../../../../interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from '../../../../interfaces/drawing-text-render-parameters';
import { DrawingsAllowedPathType } from '../../../../interfaces/drawings-geometry';
import { DrawingsCanvasUtils } from '../../../../utils/drawings-canvas-utils';
import { DrawingsPaperUtils } from '../../../../utils/drawings-paper-utils';
import {
  DrawingsGeometrySymbolsStore,
  DrawingsSymbolParameters,
} from '../../../drawings-helpers/drawings-geometry-symbols-store';
import { PaperObjectEventsApplier } from '../../../interfaces';
import { DrawingsGeometryEntity, DrawingsMouseEventHandler } from '../../base';
import { DrawingsGeometryEntityConfig } from '../../drawings-geometry-entity-config';
import { DrawingsGeometryEntityText } from '../../drawings-geometry-entity-text';
import { MeasureLengthEventsApplier } from './events-applier';

interface DrawingsGeometryEntityMeasureLengthConfig extends DrawingsGeometryEntityConfig {
  layer: paper.Layer | paper.Group;
  textLayer: paper.Layer | paper.Group;
  color: paper.Color;
  points: [paper.Point, paper.Point];
  toOtherSide?: boolean;
  mustBeOutOfPath?: boolean;
  clampPath?: DrawingsAllowedPathType;
  opacity?: number;
  onMouseEnter?: DrawingsMouseEventHandler;
  onMouseLeave?: DrawingsMouseEventHandler;
  onMouseDown?: DrawingsMouseEventHandler;
  onMouseUp?: DrawingsMouseEventHandler;
  offset?: number;
  pinnedBorderLength?: boolean;
  textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
}

export class DrawingsGeometryEntityMeasureLength implements DrawingsGeometryEntity {
  /* paper.js data */
  private _group: paper.Group;
  private _leftLine: paper.Path;
  private _rightLine: paper.Path;
  private _sizeLine: paper.Path;
  private _leftArrow: paper.Path;
  private _rightArrow: paper.Path;

  /* entities */
  private _text: DrawingsGeometryEntityText;
  private _offset: number = DrawingsCanvasConstants.estimationBordersLength;
  private _vector: paper.Point;
  private _config: DrawingsGeometryEntityMeasureLengthConfig;

  private _eventsApplier: PaperObjectEventsApplier<string>;

  constructor(config: DrawingsGeometryEntityMeasureLengthConfig) {
    this._config = config;

    this._group = new paper.Group();

    if (config.offset) {
      this._offset = config.offset;
    }

    const [start, end] = config.points;
    this._vector = start.subtract(end);
    const length = this._vector.length;
    const borderY = this.getBorderY();

    this._leftLine = this.initLine(borderY, start);
    this._rightLine = this.initLine(borderY, new paper.Point(start.x + length, start.y));
    this._leftLine.addTo(this._group);
    this._rightLine.addTo(this._group);
    const [sizeLineStart, sizeLineEnd] = this.getSizeLineStartEnd(start, length);
    this._leftArrow = this.getArrow(sizeLineStart);
    this._rightArrow = this.getArrow(sizeLineEnd);
    this._rightArrow.rotate(180, sizeLineEnd);
    this._leftArrow.addTo(this._group);
    this._rightArrow.addTo(this._group);

    this._sizeLine = new paper.Path.Line(sizeLineStart, sizeLineEnd);
    this._sizeLine.strokeColor = this._config.color;
    this._sizeLine.strokeWidth = this.applyZoomToParameterValue(1);
    this._sizeLine.addTo(this._group);

    let checkDirectionPoint = new paper.Point(start.x + length / 2, start.y + this.applyZoomToParameterValue(1));
    checkDirectionPoint = checkDirectionPoint.rotate(180 + this._vector.angle, start);

    this._group.onClick = this.onClick;
    this._group.addTo(this._config.layer);
    this._group.rotate(180 + this._vector.angle, start);


    let textPosition = this._sizeLine.bounds.center.rotate(180 + this._vector.angle, start);
    textPosition = this.updateTextPosition(checkDirectionPoint, textPosition, start, end);

    if (this._config.onSelect) {
      this._eventsApplier = new MeasureLengthEventsApplier({
        onClick: this.select,
      });
    }

    this._text = new DrawingsGeometryEntityText(
      {
        geometry: textPosition,
        text: this.getText(),
        layer: this._config.textLayer,
        color: this._config.color,
        angle: this.getTextAngle(this._vector.angle),
        onMouseEnter: this.mouseEnter,
        onMouseLeave: this.mouseLeave,
        onMouseDown: this.mouseDown,
        onMouseUp: this.mouseUp,
        renderParamsContextObserver: this._config.renderParamsContextObserver,
        eventApplier: this._eventsApplier,
      },
    );

    this._group.onMouseEnter = this.mouseEnter;
    this._group.onMouseLeave = this.mouseLeave;
    this._config.renderParamsContextObserver.subscribe(this.changeVisualData);
  }

  public get bounds(): paper.Rectangle {
    return this._group.bounds;
  }

  public get opacity(): number {
    return this._group.opacity;
  }

  public set opacity(value: number) {
    this._group.opacity = value;
  }

  public destroy(): void {
    this._text.destroy();
    this._group.remove();
    this._config.renderParamsContextObserver.unsubscribe(this.changeVisualData);
  }

  public renderOnOtherSide(value: boolean): void {
    if (this._config.mustBeOutOfPath || this._config.toOtherSide === value) {
      return;
    }
    this._config.toOtherSide = value;
    const [start, end] = this._config.points;
    this._group.rotate(180, start.add(end).divide(2));
  }

  public changeOffset(offset: number): void {
    this._offset = offset;
    this.updateLine();
  }

  @autobind
  public changeLabel(): void {
    this._text.updateText(this.getText());
    this._text.rotation = this.getTextAngle(this._vector.angle);
  }

  public changeLine(points: [paper.Point, paper.Point]): void {
    this.removeRotation();
    this._config.points = points;
    this.updateLine();
  }

  @autobind
  public changeColor(color: paper.Color): void {
    this._config.color = color;
    this._leftLine.strokeColor = color;
    this._rightLine.strokeColor = color;
    this._sizeLine.strokeColor = color;
    this._leftArrow.strokeColor = color;
    this._rightArrow.strokeColor = color;
    this._leftArrow.fillColor = color;
    this._rightArrow.fillColor = color;
    this._text.changeColor(color);
  }

  @autobind
  private changeVisualData({ zoom }: DrawingsRenderParams): void {
    const strokeWidth = DrawingsCanvasConstants.infoLinesStroke / zoom;
    this._leftLine.strokeWidth = strokeWidth;
    this._rightLine.strokeWidth = strokeWidth;
    this._sizeLine.strokeWidth = strokeWidth;
    this.removeRotation();
    this.updateLine();
  }

  @autobind
  private mouseEnter(e: PaperMouseEvent): void {
    if (this._config.cursorHelper) {
      this._config.cursorHelper.hovered = true;
    }
    if (this._config.onMouseEnter) {
      this._config.onMouseEnter(this._config.id, e);
    }
  }


  @autobind
  private select(e: PaperMouseEvent): void {
    if (this._config.onSelect) {
      this._config.onSelect(this._config.id, e);
    }
  }

  @autobind
  private mouseUp(e: PaperMouseEvent): void {
    if (this._config.onMouseUp) {
      this._config.onMouseUp(this._config.id, e);
    }
  }

  @autobind
  private mouseDown(e: PaperMouseEvent): void {
    if (this._config.onMouseDown) {
      this._config.onMouseDown(this._config.id, e);
    }
  }

  @autobind
  private mouseLeave(e: PaperMouseEvent): void {
    if (this._config.cursorHelper) {
      this._config.cursorHelper.hovered = false;
    }
    if (this._config.onMouseLeave) {
      this._config.onMouseLeave(this._config.id, e);
    }
  }

  private rotateTemp180(
    start: paper.Point,
    end: paper.Point,
    textPosition: paper.Point,
  ): {
    group: paper.Group,
    textPosition: paper.Point,
  } {
    const rotatePoint = start.add(end).divide(2);
    this._group.rotate(180, rotatePoint);
    textPosition = textPosition.rotate(180, rotatePoint);
    return {
      group: this._group,
      textPosition,
    };
  }

  private getText(): string {
    const [start, end] = this._config.points;
    const { scale, isImperial, metersPerPixel } = this._config.textRenderParamsObserver.getContext();
    return UnitUtil.lengthToString(
      DrawingsCanvasUtils.pxToMetres(start.subtract(end).length, scale, metersPerPixel),
      UnitTypes.M,
      isImperial,
    );
  }

  private getTextAngle(angle: number): number {
    let rotation = angle;
    const drawingRotation = this._config.textRenderParamsObserver.getContext().rotation;
    rotation = angle - drawingRotation;
    if (rotation > 180) {
      rotation = rotation - 360;
    } else if (rotation < -180) {
      rotation = rotation + 360;
    }
    return (DrawingsCanvasUtils.isVerticallyRotated(drawingRotation) ? 0 : 180)
      + rotation + (Math.abs(rotation) < 90 ? 180 : 0) + drawingRotation;
  }

  private removeRotation(): void {
    const [start, end] = this._config.points;
    this._group.rotate(-180 - start.subtract(end).angle, start);
    this._group.rotate(-180, start.add(end).divide(2));
  }

  private updateLine(): void {
    const [start, end] = this._config.points;
    const vector = start.subtract(end);
    const length = vector.length;
    const borderY = this.getBorderY();

    this._leftLine.lastSegment.point = new paper.Point(start.x, start.y + borderY);
    this._leftLine.firstSegment.point = start;
    this._rightLine.lastSegment.point = new paper.Point(start.x + length, start.y + borderY);
    this._rightLine.firstSegment.point = new paper.Point(start.x + length, start.y);

    const [sizeLineStart, sizeLineEnd] = this.getSizeLineStartEnd(start, length);
    this._sizeLine.firstSegment.point = sizeLineStart;
    this._sizeLine.lastSegment.point = sizeLineEnd;
    let textPosition = this._sizeLine.bounds.center;

    this._text.changePosition(textPosition);
    this._text.updateText(this.getText());


    this._leftArrow.remove();
    this._leftArrow = this.getArrow(sizeLineStart);
    this._rightArrow.remove();
    this._rightArrow = this.getArrow(sizeLineEnd);
    this._rightArrow.addTo(this._group);
    this._leftArrow.addTo(this._group);
    this._rightArrow.rotate(180, sizeLineEnd);
    this._vector = start.subtract(end);
    const angle = vector.angle;

    textPosition = textPosition.rotate(180 + angle, start);
    let checkDirectionPoint = new paper.Point(start.x + length / 2, start.y + this.applyZoomToParameterValue(1));
    checkDirectionPoint = checkDirectionPoint.rotate(180 + vector.angle, start);

    this._group.rotate(180 + start.subtract(end).angle, start);
    if (this._config.toOtherSide) {
      const rotatePoint = start.add(end).divide(2);
      textPosition = textPosition.rotate(180, rotatePoint);
      this._group.rotate(180, rotatePoint);
    }

    textPosition = this.updateTextPosition(checkDirectionPoint, textPosition, start, end);

    this._text.changePosition(textPosition);
    this._text.rotation = this.getTextAngle(angle);
    this._group.onClick = this.onClick;
  }

  private updateTextPosition(
    checkDirectionPoint: paper.Point,
    textPosition: paper.Point,
    start: paper.Point,
    end: paper.Point,
  ): paper.Point {
    const { clampPath, mustBeOutOfPath, toOtherSide } = this._config;
    if (clampPath && clampPath.segments && mustBeOutOfPath) {
      const pointsIterator = DrawingsPaperUtils.getPolygonSegmentsPointIterator(clampPath.segments);
      if (DrawingsPaperUtils.isPointInside(checkDirectionPoint, pointsIterator)) {
        textPosition = this.rotateTemp180(start, end, textPosition).textPosition;
      }
    } else if (toOtherSide) {
      textPosition = this.rotateTemp180(start, end, textPosition).textPosition;
    }
    return textPosition;
  }

  private getArrow(point: paper.Point): paper.Path {
    const arrow = DrawingsGeometrySymbolsStore.arrow.clone();
    arrow.fillColor = this._config.color;
    arrow.position = point;
    arrow.scale(this.applyZoomToParameterValue(1));
    arrow.strokeWidth = this.applyZoomToParameterValue(1);
    return arrow;
  }

  private initLine(borderY: number, point: paper.Point): paper.Path.Line {
    const line = new paper.Path.Line(point, new paper.Point(point.x, point.y + borderY));
    line.strokeColor = this._config.color;
    line.strokeWidth = this.applyZoomToParameterValue(1);
    return line;
  }

  private getSizeLineStartEnd(start: paper.Point, length: number): [paper.Point, paper.Point] {
    const sizeLineStart = new paper.Point(
      start.x + this.applyZoomToParameterValue(DrawingsSymbolParameters.arrowParams.WIDTH) / 2,
      start.y + this.getOffset(),
    );
    const sizeLineEnd = new paper.Point(
      start.x + length - this.applyZoomToParameterValue(DrawingsSymbolParameters.arrowParams.WIDTH) / 2,
      start.y + this.getOffset(),
    );
    return [sizeLineStart, sizeLineEnd];
  }

  @autobind
  private onClick(e: PaperMouseEvent): void {
    if (this._config.onSelect) {
      this._config.onSelect(this._config.id, e);
    }
  }

  private getOffset(): number {
    return this._config.pinnedBorderLength
      ? this._offset
      : this.applyZoomToParameterValue(this._offset);
  }

  private getBorderY(): number {
    const offsetAbs = Math.abs(this.getOffset());
    const borderY = offsetAbs + this.applyZoomToParameterValue(DrawingsCanvasConstants.textLineMargin);
    return this._offset < 0 ? -borderY : borderY;
  }

  private applyZoomToParameterValue(value: number): number {
    return value / this._config.renderParamsContextObserver.getContext().zoom;
  }
}
