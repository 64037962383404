import classNames from 'classnames';
import * as React from 'react';

import './classification-assignment-layout.scss';

import { connect } from 'react-redux';
import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { ClassificationAssignmentFooter } from '../classification-assignment-footer';
import { ClassificationAssignmentHeader } from '../classification-assignment-header';

interface StateProps {
  isLoading: boolean;
}

interface OwnProps {
  showFooter: boolean;
  showHeader: boolean;
  footerSaveOnly?: boolean;
}

interface Props extends OwnProps, StateProps {
}

const ClassificationAssignmentLayoutComponent: React.FC<Props> = props => {
  const className = classNames('classification-assignment-layout__body', {
    'with-footer': props.showFooter,
    'with-header': props.showHeader,
  });
  return (
    <div className='classification-assignment-layout'>
      {props.showHeader && <ClassificationAssignmentHeader /> }
      <div className={className}>
        <KreoScrollbars>
          {props.children}
          {
            props.isLoading && (
              <div className='classification-assignment-layout__loading'>
                <SvgSpinner size='middle'/>
              </div>
            )
          }
        </KreoScrollbars>
      </div>
      {props.showFooter && (
        <ClassificationAssignmentFooter footerSaveOnly={props.footerSaveOnly} />
      )}
    </div>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    isLoading: state.classification.elementClassificationLoadingStatus === RequestStatus.Loading,
  };
}

const connector = connect(mapStateToProps);
export const ClassificationAssignmentLayout = connector(ClassificationAssignmentLayoutComponent);
