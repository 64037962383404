import * as monolite from 'monolite';


import { ReducerMethods } from '../../../common/interfaces/reducer-methods';
import * as actionTypes from '../actions/types';
import { ViewerState } from '../interfaces';


export const viewerReduxMethods: ReducerMethods<ViewerState> = {
  [actionTypes.VIEWER_GET_COMMENTS_SUCCESS]: (state, payload) => {
    return monolite.set(state, (sourceState)  => sourceState.comments)(payload);
  },
};
