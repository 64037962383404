import autobind from 'autobind-decorator';
import * as paper from 'paper';
import polylabel from 'polylabel';

import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import { DrawingsCanvasColors } from '../../constants/drawing-canvas-constants';
import { DrawingsGeometryEntityState } from '../../enums/drawings-geometry-entity-state';
import { DrawingsInstanceType } from '../../enums/drawings-instance-type';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { DrawingsRenderParams } from '../../interfaces/drawing-render-parameters';
import { DrawingsPaperColorInfo } from '../../interfaces/drawings-canvas-context-props';
import {
  DrawingsAllowedPathType,
  DrawingsPaperPolygonPath,
  DrawingsPolygonGeometry,
} from '../../interfaces/drawings-geometry';
import { DrawingsMeasurePolygon } from '../../interfaces/drawings-measures';
import { DrawingAnnotationUtils } from '../../utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';
import { DrawingsPaperUtils } from '../../utils/drawings-paper-utils';
import { DrawingsGeometryDragEventHelper } from '../drawings-helpers/drawings-geometry-drag-event-helper';
import { DrawingsGeometryEntityHelper } from '../drawings-helpers/drawings-geometry-entity-helper';
import { HoverSource } from '../enums';
import {
  DrawingsAddRemovePointResults,
  DrawingsGeometryHighOrderEntity,
  DrawingsMouseEventHandler,
} from './base';
import {
  RenderEntitiesParams,
} from './base/drawings-geometry-entity-stroked';
import { EntityClosedConfig, GeometryEntityClosed } from './base/geometry-entity-closed';
import { DrawingsGeometryEntityAngleArc } from './drawings-geometry-entity-angle-arc';
import { DrawingsGeometryEntityPoint } from './drawings-geometry-entity-point';
import { DrawingsGeometrySelectionArea, DrawingsGeometryEntityLineWithMeasure } from './utility';

export interface DrawingsGeometryEntityPolygonConfig extends EntityClosedConfig {
  layer: paper.Layer;
  parentEntityHelper: DrawingsGeometryEntityHelper;
  onStartDrag: DrawingsMouseEventHandler;
  getPointInfo: (id: string) => paper.Point;
  startDragPoint: DrawingsMouseEventHandler;
  addPoint: (lineId: string, coordinates: ShortPointDescription) => void;
  onSelectSegmentMeasure: DrawingsMouseEventHandler;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
}

const PARENT_CONTOUR_KEY = 'PARENT';
const BOUNDS_THRESHOLD = 0.01;

export class DrawingsGeometryEntityPolygon
  extends GeometryEntityClosed<DrawingsGeometryEntityPolygonConfig, DrawingsMeasurePolygon>
  implements DrawingsGeometryHighOrderEntity<DrawingsPolygonGeometry, DrawingsMeasurePolygon> {
  /* geometry entities */
  private _angleArc: DrawingsGeometryEntityAngleArc;

  /* state data */
  private _pointContour: Record<string, number>;

  /* executors */

  constructor(config: DrawingsGeometryEntityPolygonConfig) {
    super(config);
    this._config.renderParamsContextObserver.subscribe(this.changeVisualData);
  }

  public override destroy(): void {
    super.destroy();
    this._config.renderParamsContextObserver.unsubscribe(this.changeVisualData);
  }

  public getGeometry(): DrawingsPolygonGeometry {
    return this._config.geometry;
  }


  public canRemovePoints(pointIds: string[]): boolean {
    const geometryWithoutPointHelper = new MonoliteHelper(this._config.geometry);
    for (const pointId of pointIds) {
      const childContourId = this._pointContour && this._pointContour[pointId];

      const isChild = Number.isInteger(childContourId);
      const contourPoints = isChild
        ? this._config.geometry.children[childContourId]
        : this._config.geometry.points;

      if (!isChild && contourPoints.length === 3) {
        return false;
      }


      if (isChild) {
        geometryWithoutPointHelper.setFilter(_ => _.children[childContourId], x => x !== pointId);
      } else {
        geometryWithoutPointHelper.setFilter(_ => _.points, x => x !== pointId);
      }
    }

    const geometryWithoutPoint = geometryWithoutPointHelper.get();
    let path: DrawingsAllowedPathType
      = DrawingsPaperUtils.simplePolygonToPath(geometryWithoutPoint.points, this._config.getPointInfo);
    if (DrawingsPaperUtils.isPolygonSelfIntersected(path)) {
      path.remove();
      return false;
    }
    if (geometryWithoutPoint.points.length < 3) {
      return false;
    }
    if (geometryWithoutPoint.children) {
      for (const childContour of geometryWithoutPoint.children) {
        if (childContour.length === 0) {
          continue;
        } else if (childContour.length < 3) {
          return false;
        }
        const childPolygon = new paper.Path(childContour.map(this._config.getPointInfo));
        if (path.intersects(childPolygon) || !DrawingsPaperUtils.hasPointInside(childPolygon, path)) {
          childPolygon.remove();
          path.remove();
          return false;
        }
        childPolygon.add(childPolygon.firstSegment);
        const newPolygon = path.subtract(childPolygon) as DrawingsPaperPolygonPath | paper.Path;
        childPolygon.remove();
        path.remove();
        path = newPolygon;
      }
    }
    if (DrawingsPaperUtils.isPolygonSelfIntersected(path)) {
      path.remove();
      return false;
    }
    path.remove();
    return true;
  }

  public updatePointsInEntity(pointIds: string[]): DrawingsMeasurePolygon {
    for (const pointId of pointIds) {
      const { index, points, childIndex } = this.getPointInGeometryPosition(pointId);
      const currentPoint = this._config.getPointInfo(pointId);
      const prevPointId = index === 0 ? points[points.length - 1] : points[index - 1];
      const nextPointId = index === points.length - 1 ? points[0] : points[index + 1];
      const prevPoint = this._config.getPointInfo(prevPointId);
      const nextPoint = this._config.getPointInfo(nextPointId);
      let path: paper.Path;
      const isChild = Number.isInteger(childIndex);

      if (this._path.children && this._path.children.length) {
        path = this._path.children[isChild ? childIndex + 1 : 0] as paper.Path;
      } else {
        path = this._path as paper.Path;
      }

      if (path.closed && path.segments.length !== points.length) {
        this.openPolygonPath(path, points);
      }

      path.segments[index].point = currentPoint;
      if (index === 0 && !path.closed) {
        path.lastSegment.point = currentPoint;
      }

      if (this._points && this._points.has(pointId)) {
        this._points.get(pointId).changePosition(currentPoint.x, currentPoint.y);
      }

      if (this._angleArc) {
        this._angleArc.updatePoints([prevPoint, currentPoint, nextPoint]);
      }
      if (this._lines) {
        const prevLine = DrawingAnnotationUtils.getLineKey(pointId, prevPointId);
        const nextLine = DrawingAnnotationUtils.getLineKey(pointId, nextPointId);
        this.updatePointInLine(prevLine, pointId);
        this.updatePointInLine(nextLine, pointId);
      }
      this.fixPathOrientation(isChild, path, childIndex);
    }
    if (this.checkIntersectionsAndUpdateStyles()) {
      return null;
    }
    this.addEvents();
    this.updateLabelPosition();
    return this.updateLabelTextAndGetMeasures();
  }

  public isExistsInRect(rect: paper.Rectangle, selectionArea: DrawingsGeometrySelectionArea): boolean {
    return this._path.isInside(rect)
    || selectionArea.intersects(this._path)
    || DrawingsPaperUtils.isRectInsidePath(rect, this._path);
  }

  public getPath(): DrawingsAllowedPathType {
    return this._path;
  }

  @autobind
  public removePoints(
    pointsIds: string[],
  ): DrawingsAddRemovePointResults<DrawingsPolygonGeometry, DrawingsMeasurePolygon> {
    const newLines: Record<string, [string, string]> = {};
    const removedLines = new Array<string>();
    const addLineToRemoved = (lineId): void => {
      if (lineId in newLines) {
        delete newLines[lineId];
      } else {
        removedLines.push(lineId);
      }
      this.removeLine(lineId);
    };

    const removePoint = (pointId): void => {
      if (this._points && this._points.has(pointId)) {
        this._points.get(pointId).destroy();
        this._points.delete(pointId);
      }
    };
    const childPointIds = arrayUtils.groupBy(
      pointsIds,
      key => this._pointContour
        && Number.isInteger(this._pointContour[key])
        ? this._pointContour[key]
        : PARENT_CONTOUR_KEY,
    );
    for (const [contourKey, contourPoints] of Object.entries(childPointIds)) {
      const isChild = contourKey !== PARENT_CONTOUR_KEY;
      const childIndex = isChild ? Number(contourKey) : undefined;
      let path: paper.Path;
      const points: string[] = isChild ?
        this._config.geometry.children[childIndex].slice()
        : this._config.geometry.points.slice();
      if (this._path.children && this._path.children.length) {
        path = this._path.children[isChild ? childIndex + 1 : 0] as paper.Path;
      } else {
        path = this._path as paper.Path;
      }
      if (contourPoints.length === points.length) {
        for (const [start, end] of DrawingAnnotationUtils.iteratePoints(points, DrawingsInstanceType.Polygon)) {
          removePoint(start);
          addLineToRemoved(DrawingAnnotationUtils.getLineKey(start, end));
        }
        path.removeSegments();
        this._config.geometry = new MonoliteHelper(this._config.geometry)
          .set(_ => _.children[childIndex], [])
          .get();
      } else {
        for (const pointId of contourPoints) {
          if (this._points && this._points.has(pointId)) {
            this._points.get(pointId).destroy();
            this._points.delete(pointId);
          }
          const index = points.findIndex(x => x === pointId);

          const {
            startPoint,
            endPoint,
          } = DrawingsGeometryUtils.removeAndAddLinesByPointInClosedGeometry(index, points, addLineToRemoved);
          const newLineKey = DrawingAnnotationUtils.getLineKey(startPoint, endPoint);
          points.splice(index, 1);
          const helper = new MonoliteHelper(this._config.geometry);
          if (isChild) {
            helper.set(_ => _.children[childIndex], points);
          } else {
            helper.set(_ => _.points, points);
          }
          path.removeSegments();
          path.addSegments(points.map(x => new paper.Segment(this._config.getPointInfo(x))));
          path.closePath();
          this._config.geometry = helper.get();
          this.fixPathOrientation(isChild, path, isChild ? Number(childIndex) : undefined);
          newLines[newLineKey] = [startPoint, endPoint];
        }
      }
    }

    this.updatePointsSelection([], false);


    if (this._config.geometry.children) {
      for (const child of this._path.children) {
        if (!(child as paper.Path).segments.length) {
          child.remove();
        }
      }
      const children = this._config.geometry.children.filter(x => x.length);
      if (children.length !== this._config.geometry.children.length) {
        for (let i = 0; i < children.length; i++) {
          for (const pointId of children[i]) {
            this._pointContour[pointId] = i;
          }
        }
      }
      if (children.length) {
        this._config.geometry.children = children;
      } else {
        delete this._config.geometry.children;
        this._path.remove();
        this.render();
      }
    }
    if (this.state === DrawingsGeometryEntityState.Modify) {
      for (const [startPoint, endPoint] of Object.values(newLines)) {
        this.renderLine(startPoint, endPoint, true, false);
      }
    }
    if (this._text) {
      this._text.parentPath = this._path;
    }
    const measures = this.updateLabelTextAndGetMeasures();
    this.updateLabelPosition();
    this.changeColorOfEntities(this._config.color);
    return {
      geometry: this._config.geometry,
      removedLines,
      newLines,
      measures,
    };
  }

  public getOrderedSegmentPoints(segmentId: string): [string, string] {
    const segment = DrawingAnnotationUtils.getPointsIdsFromLineKey(segmentId);
    const pointInfo = this.getPointInGeometryPosition(segment[0]);
    const firstIndex = pointInfo.index;
    const lastIndex = pointInfo.points.indexOf(segment[1]);
    const needReverse = lastIndex < firstIndex ?
      firstIndex !== pointInfo.points.length - 1 || lastIndex !== 0
      : lastIndex === pointInfo.points.length - 1 && firstIndex === 0;
    return needReverse ? segment.reverse() as [string, string] : segment;
  }

  public addPoints(
    [first, last]: [string, string],
    newPointIds: string[],
  ): DrawingsAddRemovePointResults<DrawingsPolygonGeometry, DrawingsMeasurePolygon> {
    const { index: firstIndex, points: contour, childIndex } = this.getPointInGeometryPosition(first);
    const lastIndex = contour.lastIndexOf(last);
    const points = DrawingsGeometryUtils.addPointToPoly(
      contour,
      firstIndex,
      lastIndex,
      newPointIds,
      DrawingsInstanceType.Polygon,
    );
    const helper = new MonoliteHelper(this._config.geometry);
    if (Number.isInteger(childIndex)) {
      helper.set(_ => _.children[childIndex], points);
    } else {
      helper.set(_ => _.points, points);
    }
    this._config.geometry = helper.get();
    this.render();
    this.updateLabelPosition();
    const lineId = DrawingAnnotationUtils.getLineKey(first, last);
    const removedLines = [ lineId ];
    const newLines = {};
    this.removeLine(lineId);
    if (this.state === DrawingsGeometryEntityState.Modify) {
      this.removeStroke();
      this.renderPoint(newPointIds[0], this._config.getPointInfo(newPointIds[0]), true);
      this.addLineToRecordsAndRender(first, newPointIds[0], newLines);
      for (let i = 1; i < newPointIds.length; i++) {
        const pointId = newPointIds[i];
        this.renderPoint(pointId, this._config.getPointInfo(pointId), true);
        this.addLineToRecordsAndRender(newPointIds[i - 1], pointId, newLines);
      }
      this.addLineToRecordsAndRender(last, newPointIds[newPointIds.length - 1], newLines);
    } else {
      if (this._lines) {
        this.addLineToRecordsAndRender(first, newPointIds[0], newLines);
        this.addLineToRecordsAndRender(newPointIds[newPointIds.length - 1], last, newLines);
        for (let i = 1; i < newPointIds.length; i++) {
          this.addLineToRecordsAndRender(newPointIds[i - 1], newPointIds[i], newLines);
        }
      }
    }
    this.updateLabelPosition();
    const measures = this.updateLabelTextAndGetMeasures();
    return {
      geometry: this._config.geometry,
      measures,
      newLines,
      removedLines,
    };
  }

  public updateGeometry(polygon: DrawingsPolygonGeometry): DrawingsMeasurePolygon {
    this._config.geometry = polygon;
    this.render();
    if (this.state === DrawingsGeometryEntityState.Modify) {
      this.removeEntities();
      this.renderEntities({ modifyEnabled: true });
    } else if (
      this.state === DrawingsGeometryEntityState.Selected
      || this.state === DrawingsGeometryEntityState.Hover
    ) {
      this.removeStroke();
      this.removeEntities();
      this.renderEntities({
        modifyEnabled: false,
        skipPointsRender: true,
        showHoverLineStyle: this.state === DrawingsGeometryEntityState.Hover,
      });
      if (this._lines) {
        const processLines = this.state === DrawingsGeometryEntityState.Selected
          ? (x) => x.enableSelectedStyle()
          : (x) => x.enableHoverStyle();
        this._lines.forEach(processLines);
      }
    }
    const measures = this.getMeasures();
    if (this._text) {
      this._text.destroy();
      this._text = null;
      this.updateLabel(this._config.textRenderParamsObserver.getContext());
    }
    this.updateLabelPosition();

    return this.checkIntersectionsAndUpdateStyles() ? null : measures;
  }

  protected override changeColor(value: DrawingsPaperColorInfo, colorCode: string): void {
    super.changeColor(value, colorCode);
    this.changeColorOfEntities(value);
  }

  @autobind
  protected renderEntities(params: RenderEntitiesParams): void {
    this.initEntityGroups();
    const geometry = this._config.geometry;
    this.renderContourEntities(geometry.points, params);
    if (geometry.children) {
      geometry.children.forEach(child => this.renderContourEntities(child, params));
    }
    if (this.selectedPointsIds) {
      for (const pointId of this.selectedPointsIds) {
        if (this._points.has(pointId)) {
          this._points.get(pointId).selected = true;
        }
      }
    }
  }

  protected enableSelectMode(): void {
    this.removeStroke();
    if (this._lines) {
      this._lines.forEach(x => {
        x.enableSelectedStyle();
      });
    }
  }

  protected override render(): void {
    if (this._path) {
      this._pointContour = {};
      this._path.removeChildren();
      this._path.remove();
    }
    let path: DrawingsAllowedPathType
      = DrawingsPaperUtils.simplePolygonToPath(this._config.geometry.points, this._config.getPointInfo);
    this.fixPathOrientation(false, path, undefined);
    const geometry = { ...this._config.geometry };
    if (this._config.geometry.children && this._config.geometry.children.length) {
      path = new paper.CompoundPath({ children: [path] }) as DrawingsPaperPolygonPath;
      this._pointContour = {};
      geometry.children = [];
      for (let i = 0; i < this._config.geometry.children.length; i++) {
        const childContour = this._config.geometry.children[i];
        const points = [];
        for (const pointId of childContour) {
          this._pointContour[pointId] = i;
          points.push(this._config.getPointInfo(pointId));
        }
        const childPolygon = new paper.Path(points);
        childPolygon.add(childPolygon.firstSegment);
        if (childPolygon.clockwise) {
          childPolygon.reverse();
          geometry.children.push(childContour.reverse());
        } else {
          geometry.children.push(childContour);
        }
        path.addChild(childPolygon);
      }
    }
    this._path = path;
    this._path.strokeColor = this._config.color.stroke;
    this._path.fillColor = this._config.color.fill;
    this._path.addTo(this._config.layer);
    this.addEvents();
    DrawingsPaperUtils.updatePathStyles(this._path);
    this.changeVisualData(this._config.renderParamsContextObserver.getContext());
  }

  @autobind
  protected override getLabelPosition(): paper.Point {
    const path = (this._path.children ? this._path.firstChild : this._path) as paper.Path;
    if (path.bounds.width < BOUNDS_THRESHOLD || path.bounds.height < BOUNDS_THRESHOLD) {
      return path.bounds.center;
    }
    const paths = this._path.children ? this._path.children as paper.Path[] : [this._path];
    const points = paths.map(x => x.segments.map(({ point }) => [point.x, point.y]));
    const result = polylabel(points);
    return new paper.Point(result);
  }

  protected override addEvents(): void {
    super.addEvents();
    this._path.onMouseLeave = this.onPathMouseLeave;
  }

  private addLineToRecordsAndRender(start: string, end: string, newLines: Record<string, [string, string]>): void {
    DrawingsGeometryUtils.addLineToRecordsByPoints(start, end, newLines);
    const isModify = this.state === DrawingsGeometryEntityState.Modify;
    const isHover = this.state === DrawingsGeometryEntityState.Hover;
    this.renderLine(start, end, isModify, isHover);
  }

  @autobind
  private onLineClick(_id: string, e: PaperMouseEvent): void {
    this._config.onSelect(this.id, e);
  }

  private removeLine(lineId: string): void {
    if (this._lines && this._lines.has(lineId)) {
      this._lines.get(lineId).destroy();
      this._lines.delete(lineId);
    }
  }

  private renderContourEntities(
    contour: string[],
    { modifyEnabled, skipPointsRender, showHoverLineStyle }: RenderEntitiesParams,
  ): void {
    for (let i = 0; i < contour.length; i++) {
      const currentPointId = contour[i];
      if (!skipPointsRender && !this._points.has(currentPointId)) {
        this.renderPoint(currentPointId, this._config.getPointInfo(currentPointId), modifyEnabled);
      }

      const nextPointId = contour[i === contour.length - 1 ? 0 : i + 1];
      const lineId = DrawingAnnotationUtils.getLineKey(currentPointId, nextPointId);
      if (!this._lines.has(lineId)) {
        this.renderLine(currentPointId, nextPointId, modifyEnabled, showHoverLineStyle);
      } else {
        const line = this._lines.get(lineId);
        line.modifyEnabled = modifyEnabled;
        if (showHoverLineStyle) {
          line.enableHoverStyle();
        } else {
          line.disableHoverStyle();
        }
      }
    }
  }

  private renderPoint(id: string, point: paper.Point, modifyEnabled: boolean): void {
    this._points.set(
      id,
      new DrawingsGeometryEntityPoint(
        {
          id,
          renderParamsContextObserver: this._config.renderParamsContextObserver,
          geometry: point,
          layer: this._pointsGroup,
          canModify: modifyEnabled,
          onSelect: this.onPointSelect,
          onMouseEnter: this.pointMouseEnter,
          onMouseLeave: this.pointMouseLeave,
          onStartDrag: this._config.startDragPoint,
          cursorHelper: this._config.cursorHelper,
        },
      ),
    );
  }

  private renderLine(
    firstPointId: string,
    lastPointId: string,
    modifyEnabled: boolean,
    showHoverStyle: boolean,
  ): void {
    const lineId = DrawingAnnotationUtils.getLineKey(firstPointId, lastPointId);
    const line = new DrawingsGeometryEntityLineWithMeasure(
      {
        textRenderParamsObserver: this._textObserverGroup,
        renderParamsContextObserver: this._renderParamsObserverGroup,
        id: lineId,
        points: [firstPointId, lastPointId],
        layer: this._linesGroup,
        color: this._path.strokeColor,
        modifyEnabled,
        dashed: this.state === DrawingsGeometryEntityState.Selected,
        textLayer: this._segmentTextGroup,
        clampPath: this._path,
        mustBeOutOfPath: true,
        onSelect: this.onLineClick,
        onMouseEnter: this.onSegmentMouseEnter,
        onMouseLeave: this.onSegmentMouseLeave,
        onSelectSegmentMeasure: this._config.onSelectSegmentMeasure,
        dragEventsHelper: this._config.dragEventsHelper,
        canMove: this.canEditSegment(),
        strokeStyle: this._config.geometry,
        cursorHelper: this._config.cursorHelper,
        pointsManager: this._config,
        segmentDragHelper: this._config.segmentDragHelper,
      },
    );
    if (showHoverStyle) {
      line.enableHoverStyle();
    }
    this._lines.set(lineId, line);
  }

  @autobind
  private pointMouseEnter(id: string, e: PaperMouseEvent): void {
    this.mouseEnter(id, e);
    const { index, points } = this.getPointInGeometryPosition(id);
    const currentPoint = this._config.getPointInfo(id);
    const prevPoint = this._config.getPointInfo(index === 0 ? points[points.length - 1] : points[index - 1]);
    const nextPoint = this._config.getPointInfo(index === points.length - 1 ? points[0] : points[index + 1]);
    this._angleArc = new DrawingsGeometryEntityAngleArc(
      {
        textRenderParamsObserver: this._config.textRenderParamsObserver,
        renderParamsContextObserver: this._renderParamsObserverGroup,
        id: 'polygon-arc',
        points: [prevPoint, currentPoint, nextPoint],
        color: this._path.strokeColor,
        layer: this._config.pointLayer,
        mustExistInPath: true,
        clampPath: this._path,
        cursorHelper: this._config.cursorHelper,
      },
    );
  }

  private getPointInGeometryPosition(id: string): { index: number, points: string[], childIndex?: number } {
    let index: number;
    let points: string[];
    let childIndex: number;
    if (this._pointContour && Number.isInteger(this._pointContour[id])) {
      childIndex = this._pointContour[id];
      index = this._config.geometry.children[childIndex].findIndex(x => x === id);
      points = this._config.geometry.children[childIndex].slice();
    } else {
      index = this._config.geometry.points.findIndex(x => x === id);
      points = this._config.geometry.points.slice();
    }
    return { index, points, childIndex };
  }

  @autobind
  private pointMouseLeave(id: string, e: PaperMouseEvent): void {
    if (this._angleArc) {
      this._angleArc.destroy();
      this._angleArc = undefined;
    }
    if (this._hoverSource !== HoverSource.Body) {
      return;
    }
    this.mouseLeave(id, e);
  }

  @autobind
  private onPathMouseLeave(e: PaperMouseEvent): void {
    if (this._hoverSource === HoverSource.Body) {
      this.mouseLeave(this.id, e);
    }
  }

  private openPolygonPath(path: paper.Path, points: string[]): void {
    const clockwise = path.clockwise;
    path.closed = false;
    path.removeSegments();
    path.addSegments(points.map(x => new paper.Segment(this._config.getPointInfo(x))));
    path.addSegments([path.firstSegment]);
    if (path.clockwise !== clockwise) {
      path.reverse();
    }
  }

  @autobind
  private updateLabelPosition(): void {
    if (this._text) {
      const point = this.getLabelPosition();
      this._text.changePosition(point);
    }
  }

  private checkIntersectionsAndUpdateStyles(): boolean {
    if (DrawingsPaperUtils.isPolygonSelfIntersected(this._path)) {
      this.changeColorOfEntities(DrawingsCanvasColors.warningColorInfo);
      return true;
    } else {
      this.changeColorOfEntities(this._config.color);
      return false;
    }
  }

  private changeColorOfEntities(value: DrawingsPaperColorInfo): void {
    this._path.strokeColor = value.stroke;
    this._path.fillColor = value.fill;
    if (this._text) {
      this._text.changeColor(value.stroke);
    }
    if (this._angleArc) {
      this._angleArc.changeColor(value.stroke);
    }
    if (this._lines) {
      this._lines.forEach(x => x.changeColor(value.stroke));
    }
  }

  private fixPathOrientation(isChild: boolean, path: paper.Path, childIndex: number): void {
    if (isChild) {
      if (path.clockwise) {
        this.reversePath(path, this._config.geometry.children[childIndex]);
        this._config.geometry.children[childIndex] = this._config.geometry.children[childIndex].reverse();
      }
    } else if (!path.clockwise) {
      this.reversePath(path, this._config.geometry.points);
      this._config.geometry.points = this._config.geometry.points.reverse();
    }
  }

  private reversePath(path: paper.Path, points: string[]): void {
    if (path.segments.length === points.length) {
      path.reverse();
    } else {
      path.removeSegment(path.segments.length - 1);
      path.reverse();
      path.add(path.firstSegment);
    }
  }

  @autobind
  private changeVisualData(renderParameters: DrawingsRenderParams): void {
    if (!this._path.visible) {
      return;
    }
    if (this.state === DrawingsGeometryEntityState.Default) {
      const { strokeWidth } = this._config.geometry;
      this._path.strokeWidth = strokeWidth / renderParameters.zoom;
      this._path.dashArray = DrawingsCanvasUtils.scaleStroke(this._config.geometry, renderParameters.zoom);
    } else {
      this._path.strokeWidth = 0;
    }
  }

  private removeStroke(): void {
    this._path.strokeWidth = 0;
  }
}
