import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './classification-header-breadcrumb.scss';

import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { ClassificationActions } from '../../actions/creators/classification';
import { RevitTreePathInfo } from '../../interfaces/revit-tree-path-info';


interface OwnProps extends RouteComponentProps<PlanProjectRouteParams> {
  index: number;
  onBreadcrumbSelect: (callback: () => void) => void;
}

interface StateProps {
  itemInfo: RevitTreePathInfo;
  unsavedChangesExists: boolean;
}


interface DispatchProps {
  onClick: (item: RevitTreePathInfo) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {

}

class ClassificationHeaderBreadcrumbComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
    <span
      style={{ zIndex: 200 - 10 * this.props.index }}
      className='classification-header-breadcrumb'
      title={this.props.itemInfo.name}
      onClick={this.onClick}
    >
      <span>
        {this.props.itemInfo.name}
      </span>
    </span>
    );
  }

  @autobind
  private onClick(): void {
    if (this.props.unsavedChangesExists) {
      this.props.onBreadcrumbSelect(this.onBreadcrumbSelect);
    } else {
      this.onBreadcrumbSelect();
    }
  }

  @autobind
  private onBreadcrumbSelect(): void {
    this.props.onClick(this.props.itemInfo);
  }
}


function mapStateToProps(state: State, { index }: OwnProps): StateProps {
  return {
    itemInfo: state.classification.modelBrowserFilters.breadCrumbs[index],
    unsavedChangesExists: state.classification.isSaveButtonEnabled,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { match, location }: OwnProps): DispatchProps {
  return {
    onClick: breadCrumb => {
      const editUrl = AppUrls.plan.project.validation.editClassification.url(match.params);
      dispatch(ClassificationActions.onBreadCrumbClick(breadCrumb, editUrl === location.pathname));
    },
  };
}

export const ClassificationHeaderBreadcrumb =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ClassificationHeaderBreadcrumbComponent));
