import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { AppUrls } from '../../../routes/app-urls';
import { ScenariosActions } from '../../scenarios/actions';
import { ValidationActions } from '../actions/creators/validation';
import { CalculateActivityAssignmentPayload } from '../actions/payloads/activity-assignment';
import {
  ApproveValidationStepPayload,
  GetValidationResultsRequestPayload,
  RevertToValidationStepPayload,
} from '../actions/payloads/validation';
import { ValidationActionTypes } from '../actions/types/validation';
import { ActivityAssignmentApi } from '../api/activity-assignment';
import { ValidationApi } from '../api/validation';
import { ValidationStep } from '../enums/validation-step';
import { getValidationStepName } from '../enums/validation-step-name';
import { ValidationStepState } from '../enums/validation-step-state';
import {
  ProjectValidationResultsResponse,
  ProjectValidationResultsStatistic,
} from '../interfaces/project-validation-results';
import { ProjectValidationState } from '../interfaces/project-validation-state';

function* approveValidationStep(action: ActionWith<ApproveValidationStepPayload>): SagaIterator {
  try {
    yield call(ValidationApi.approveValidationStep, action.payload);
    if (action.payload.step === ValidationStep.Classification) {
      yield put(ProjectsActions.resetProjectHasUnstartedProducts(action.payload.projectId));
    }
  } catch (error) {
    console.error('validation: approve validation step failed', error, action.payload);
  }
}

function* revertToValidationStep(action: ActionWith<RevertToValidationStepPayload>): SagaIterator {
  try {
    const newValidationState: ProjectValidationState = yield call(
      ValidationApi.revertToValidationStep, action.payload,
    );
    yield put(ValidationActions.setProjectValidationState(newValidationState));
    const { projectId: documentId, targetStep, withRedirect } = action.payload;
    if (withRedirect) {
      yield put(
        push(
          AppUrls.plan.project.validation.step.url({
            projectId: documentId.toString(),
            step: getValidationStepName(targetStep),
          }),
        ),
      );
    }
  } catch (error) {
    console.error('validation: revert to validation step failed', error, action.payload);
  }
}

function* calculateActivityAssignment(
  { payload }: ActionWith<CalculateActivityAssignmentPayload>,
): SagaIterator {
  try {
    yield call(ActivityAssignmentApi.calculate, payload);
    yield put(ValidationActions.setProjectValidationState({
      documentId: payload.projectId,
      validationStep: ValidationStep.ActivityAssignment,
      stepState: ValidationStepState.Calculating,
    }));
    if (!payload.scenarioId) {
      yield put(ScenariosActions.getActiveScenarioByProject(payload.projectId));
    }
  } catch (error) {
    console.error('validation: calculate activity assignment failed', error, payload);
  }

}

function* getValidationResultsStatistic(
  action: ActionWith<GetValidationResultsRequestPayload>,
): SagaIterator {
  try {
    const [validationResult, classificationStatistic] = (yield all([
      call(ValidationApi.getResultsStatistic, action.payload.scenarioId),
      call(ValidationApi.getClassificationAggregatedStatistic),
    ])) as [ProjectValidationResultsResponse, ProjectValidationResultsStatistic];
    yield put(ValidationActions.getValidationResultsSuccess(validationResult, classificationStatistic));
  } catch (error) {
    console.error('validation: get validation results statistic failed', error, action.payload);
  }
}

export function* validationSagas(): SagaIterator {
  yield takeEvery(ValidationActionTypes.APPROVE_VALIDATION_STEP, approveValidationStep);
  yield takeEvery(ValidationActionTypes.REVERT_TO_VALIDATION_STEP, revertToValidationStep);
  yield takeEvery(ValidationActionTypes.CALCULATE_ACTIVITY_ASSIGNMENT, calculateActivityAssignment);
  yield takeLatest(
    ValidationActionTypes.GET_VALIDATION_RESULTS_REQUEST,
    getValidationResultsStatistic,
  );
}
