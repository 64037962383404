import classNames from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import './index.scss';

import { ElementTooltip, TooltipPosition } from 'common/components/element-tooltip';
import { SvgSpinner } from 'common/components/svg-spinner';
import { ParametrizedAppRoute } from 'common/routing/parametrized-app-route';
import { CostEstimateProjectRevisionRouteParams, PlanProjectRouteParams } from '../../../../routes/app-routes-params';

interface MenuItemProps {
  route: ParametrizedAppRoute<PlanProjectRouteParams | CostEstimateProjectRevisionRouteParams>;
  text: string;
  icon: string;
  blocked: boolean;
  inProgress: boolean;
  projectId: string;
  revisionId: string;
  isExpanded: boolean;
}

export class MenuItem extends React.PureComponent<MenuItemProps> {
  public render(): React.ReactNode {
    const { blocked, route, projectId, revisionId, inProgress } = this.props;

    const linkClassName = classNames('project-left-menu-item', {
      'project-left-menu-item--blocked': blocked,
      'project-left-menu-item--progress': inProgress,
    });

    return !blocked && projectId !== null ? (
      <NavLink to={route.url({ projectId, revisionId })} className={linkClassName}>
        {this.getContent()}
      </NavLink>
    ) : (
        <div className={linkClassName}>{this.getContent()}</div>
    );
  }

  private getContent(): React.ReactNode {
    const { icon, text, inProgress, isExpanded } = this.props;
    return (
      <ElementTooltip
        className='project-left-menu-item__inner'
        tooltipPosition={TooltipPosition.RightCenter}
        marginLeft={-5}
        tooltipClassName='project-left-menu-item__tooltip'
        size='medium'
        disabled={isExpanded}
        targetElement={
          <>
            <div className='project-left-menu-item__icon'>
              {inProgress ? <SvgSpinner size={'small'} /> : <img src={icon} />}
            </div>
            <span className='project-left-menu-item__title'>{text}</span>
          </>
        }
      >
        {text}
      </ElementTooltip>
    );
  }
}
