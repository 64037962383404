import styled from 'styled-components';

import { DrawingsLayoutConstants } from '../constants/drawings-layout-constants';
import { InstrumentsMenuStyled } from './instruments-menu';

export const MENU_ITEM_SIZE = 40;
export const MENU_ITEM_GAP = 10;

const Container = styled.div`
  height: ${DrawingsLayoutConstants.DRAWINGS_CONTROLS_HEIGHT}px;
  width: 100%;
  min-width: 100%;
  background: ${p => p.theme.color.backgroundRush};
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${p => p.theme.color.background};
  padding-right: 90px;
  box-sizing: border-box;
  position: relative;
  > div {
    box-shadow: none;
  }
  ${InstrumentsMenuStyled.InstrumentContainer} {
    border: none;
  }
  ${InstrumentsMenuStyled.Instrument} {
    width: 30px;
    height: 30px;
  }
  ${InstrumentsMenuStyled.Container} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Blanket = styled.div`
  background-color: ${p => p.theme.color.background}85;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

const LegendPanelToggle = styled.div`
  position: absolute;
  right: 0;
  z-index: 2;
  background: ${p => p.theme.color.backgroundRush};
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: 100%;
`;

export const Styled = {
  Container,
  LegendPanelToggle,
  Blanket,
};
