import { ExtractorConfig, UserExtractors } from '../../interfaces/graph-storage-records-config';

const extractorKey = 'extractorKey';
const description = 'description';

export const extendUserExtractorsConfig = (userExtractor: UserExtractors, extractorsConfig: ExtractorConfig): void => {
  if (extractorsConfig) {
    for (const [key, value] of Object.entries(extractorsConfig)) {
      userExtractor.forEach(x => {
        if (x.name === value.name) {
          x[extractorKey] = key;
          x[description] = value.description;
        }
      });
    }
  } else {
    console.error('Invalid config');
  }
};
