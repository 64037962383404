import { EnumUtils } from 'common/utils/enum-utils';
import { CostEstimateValidationStep } from 'unit-cost-estimate/interfaces';


const stepsSequence = [
  CostEstimateValidationStep.Classification,
  CostEstimateValidationStep.ActivityAssignment,
  CostEstimateValidationStep.Measurements,
  CostEstimateValidationStep.Reports,
];

export function getNextStep(step: CostEstimateValidationStep): CostEstimateValidationStep | null {
  const stepIndex = stepsSequence.findIndex(x => x === step);
  return stepIndex >= 0 && stepIndex < stepsSequence.length ? stepsSequence[stepIndex + 1] : null;
}

export function getPreviousStep(step: CostEstimateValidationStep): CostEstimateValidationStep | null {
  const stepIndex = stepsSequence.findIndex(x => x === step);
  return stepIndex >= 1 ? stepsSequence[stepIndex - 1] : null;
}

export function isCostEstimateValidationStepGreaterThan(
  current: CostEstimateValidationStep,
  target: CostEstimateValidationStep,
  equal: boolean = false,
): boolean {
  return EnumUtils.isGreaterThan(stepsSequence, current, target, equal);
}

export function isCostEstimateValidationStepLessThan(
  current: CostEstimateValidationStep,
  target: CostEstimateValidationStep,
  equal: boolean = false,
): boolean {
  return EnumUtils.isLessThan(stepsSequence, current, target, equal);
}
