import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCurrentDrawingInfo } from 'common/components/drawings/hooks';
import { DrawingsFilesData, DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import { DrawingInstancesAggregationUtils } from 'common/components/drawings/utils';
import { State } from 'common/interfaces/state';

interface AggreagatedProperties {
  height: Record<string, string[]>;
  thickness: Record<string, string[]>;
  offsets: Record<string, string[]>;
  color: Record<string, string[]>;
}

export function useAggregate(): AggreagatedProperties {
  const currentDrawing = useCurrentDrawingInfo();
  const fileData = useSelector<State, DrawingsFilesData>(s => s.drawings.aiAnnotation.fileData);
  const currentInstances = useMemo(() => {
    if (!currentDrawing) {
      return [];
    }
    if (!fileData[currentDrawing.pdfId] || !fileData[currentDrawing.pdfId][currentDrawing.drawingId]) {
      return [];
    }
    return fileData[currentDrawing.pdfId][currentDrawing.drawingId].instances;
  }, [currentDrawing, fileData]);

  const instances = useSelector<State, Record<string, DrawingsGeometryInstance>>(s => s.drawings.aiAnnotation.geometry);

  return useMemo(() => {
    return DrawingInstancesAggregationUtils.aggregateInstances(
      currentInstances,
      instances,
    );
  }, [instances, currentInstances]);
}
