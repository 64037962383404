import autobind from 'autobind-decorator';
import * as paper from 'paper';

import {
  DrawingsCustomSnappingModes,
  DrawingsSnappingModes,
} from 'common/components/drawings/enums/drawing-snapping-modes';
import { PdfHelper } from 'common/pdf/pdf-helper';
import { PdfSnappingReader } from 'common/pdf/pdf-snapping-reader';
import { DrawingsCanvasConstants } from '../../../constants/drawing-canvas-constants';
import { ContextObserverWithPrevious, DrawingContextObserver } from '../../../drawings-contexts';
import { DrawingPdfSnappingResult, DrawingSnapping } from '../../../interfaces/drawing';
import { DrawingsRenderParams } from '../../../interfaces/drawing-render-parameters';
import { DrawingsSnappingPoint } from '../../drawings-geometry-entities/utility';
import { DrawingsGeometryGuideline } from '../../drawings-geometry-entities/utility/drawings-geometry-guideline';
import { GetCurrentPageSizeInfoCallback } from '../../interfaces';
import { DrawingsEngineOrientation } from '../../interfaces/orientation';
import { EntityWithSelfSnapping, SnappingGuideInfo } from './with-self-snapping';

export interface DrawingsSnappingParameters {
  distance: number;
  snappingPoint: paper.Point;
  threshold: number;
}

export interface DrawingsGeometrySnappingHelperConfig {
  observableRenderParameters: ContextObserverWithPrevious<DrawingsRenderParams>;
  snappingObserver: DrawingContextObserver<DrawingSnapping>;
  getCurrentPageInfo: GetCurrentPageSizeInfoCallback;
  scope?: paper.PaperScope;
  getSnappingPoint: (point: paper.Point, threshold: number) => paper.Point;
}

export class DrawingsGeometrySnappingHelper {
  private _onSendPoint: (point: paper.Point, snappingInfo?: DrawingsSnappingParameters) => paper.Point;

  private _config: DrawingsGeometrySnappingHelperConfig;

  private _enabled: boolean;
  private _snapping: DrawingSnapping;
  private _snappingModes: DrawingsSnappingModes[];
  private _lastMouseMoveEventPoint: paper.Point;
  private _threshold: number;
  private _currentSnappingPoint: DrawingsSnappingPoint;
  private _layer: paper.Layer;
  private _guideline: DrawingsGeometryGuideline;
  private _cancelPrev: () => void;

  private _snapEntity: EntityWithSelfSnapping;

  private _cancelSnapping: () => void;

  constructor(config: DrawingsGeometrySnappingHelperConfig) {
    this._config = config;
    if (!this._config.scope) {
      this._config.scope = paper;
    }
    this._layer = new this._config.scope.Layer();
    this._config.observableRenderParameters.subscribe(this.updateZoom);
    this._config.snappingObserver.subscribe(this.updateSnapping);
  }

  public get canSnap(): boolean {
    return this._snapping && this._enabled;
  }

  public set onSendPoint(value: (point: paper.Point, snappingInfo?: DrawingsSnappingParameters) => paper.Point) {
    this._onSendPoint = value;
    this._snapEntity = null;
  }

  public set snapEntity(value: EntityWithSelfSnapping) {
    this._snapEntity = value;
  }

  public set enable(enabled: boolean) {
    this._enabled = enabled;
    if (!enabled) {
      this.removeSnapping();
    }
  }

  public set snapping(snapping: DrawingSnapping) {
    this._snapping = snapping;
  }

  public set snappingModes(modes: DrawingsSnappingModes[]) {
    this._snappingModes = modes;
  }

  @autobind
  public cancelSnapping(): void {
    if (this._cancelSnapping) {
      this._cancelSnapping();
      this._cancelSnapping = null;
    }
  }

  @autobind
  public requestSnapping(point: paper.Point, snappingPointSize?: number): void {
    this._lastMouseMoveEventPoint = point;
    if (this._cancelPrev) {
      this._cancelPrev();
      this._cancelPrev = null;
    }
    const { promise, cancel } = PdfHelper.callOrSaveOperation(
      PdfSnappingReader.getPDFTronSnapping,
      DrawingsCanvasConstants.snappingOperationName,
      this._snapping,
      point,
      this._threshold,
      this._snappingModes,
    );
    this._cancelPrev = cancel;
    promise.then((result) => this.snapPositionToSnappingPoint(snappingPointSize, result));
  }

  public destroy(): void {
    if (this._currentSnappingPoint) {
      this._currentSnappingPoint.destroy();
    }
    this._config.observableRenderParameters.unsubscribe(this.updateZoom);
    this._config.snappingObserver.unsubscribe(this.updateSnapping);
    this._enabled = false;
  }


  public removeSnapping(): void {
    if (this._guideline) {
      this._guideline.destroy();
      this._guideline = null;
    }
    if (this._currentSnappingPoint) {
      this._currentSnappingPoint.destroy();
      this._currentSnappingPoint = null;
    }
  }

  @autobind
  private updateSnapping(snapping: DrawingSnapping): void {
    this._snapping = snapping;
  }

  @autobind
  private updateZoom({ zoom }: DrawingsRenderParams): void {
    this._threshold = DrawingsCanvasConstants.snappingThreshold / zoom;
  }

  @autobind
  private snapPositionToSnappingPoint(snappingPointSize: number, snappingInfo?: DrawingPdfSnappingResult): void {
    if (!this._enabled) {
      return;
    }
    const point = this._lastMouseMoveEventPoint;

    let toDrawingSnapping = Infinity;
    let snapping: DrawingPdfSnappingResult;
    let distance: number = Infinity;
    if (snappingInfo) {
      toDrawingSnapping = point.subtract(snappingInfo.point).length;
      if (toDrawingSnapping < this._threshold) {
        snapping = snappingInfo;
        distance = toDrawingSnapping;
      }
    }
    if (this._snappingModes.includes(Core.PDFNet.GeometryCollection.SnappingMode.e_PathEndpoint)) {
      const userSnappingPoint = this._config.getSnappingPoint(point, this._threshold);
      if (userSnappingPoint) {
        const toUserSnapping = userSnappingPoint.subtract(userSnappingPoint).length;
        if (toUserSnapping < toDrawingSnapping) {
          snapping = { point: userSnappingPoint, mode: Core.PDFNet.GeometryCollection.SnappingMode.e_PathEndpoint };
          distance = toUserSnapping;
        }
      }
    }

    if (this._snappingModes.includes(DrawingsCustomSnappingModes.DynamicGuideline)) {
      const guideInfo = this.snapToGuidLine(point);
      if (guideInfo && guideInfo.distance < this._threshold && guideInfo.distance < distance) {
        distance = guideInfo.distance;
        snapping = {
          point: guideInfo.orientation === DrawingsEngineOrientation.Horizontal
            ? new paper.Point(point.x, guideInfo.value)
            : new paper.Point(guideInfo.value, point.y),
          mode: DrawingsCustomSnappingModes.DynamicGuideline,
        };
        if (this._guideline) {
          this._guideline.orientation = guideInfo.orientation;
          this._guideline.value = guideInfo.value;
        } else {
          this._guideline = new DrawingsGeometryGuideline({
            orientation: guideInfo.orientation,
            value: guideInfo.value,
            layer: this._layer,
            observableRenderParameters: this._config.observableRenderParameters,
            getCurrentDrawingInfo: this._config.getCurrentPageInfo,
          });
        }
      } else if (this._guideline) {
        this._guideline.destroy();
        this._guideline = null;
      }
    }

    if (this._onSendPoint) {
      this._onSendPoint(point, {
        snappingPoint: snapping ? snapping.point : undefined,
        distance,
        threshold: this._threshold,
      });
    }


    if (snapping) {
      if (!this._currentSnappingPoint) {
        this._currentSnappingPoint = new DrawingsSnappingPoint({
          mode: snapping.mode,
          position: snapping.point,
          observableRenderParameters: this._config.observableRenderParameters,
          layer: this._layer,
          snappingSize: snappingPointSize,
        });
      } else {
        this._currentSnappingPoint.mode = snapping.mode;
        this._currentSnappingPoint.position  = snapping.point;
      }
    } else {
      this.removeSnapping();
    }
  }

  private snapToGuidLine(point: paper.Point): SnappingGuideInfo {
    if (this._snapEntity) {
      return this._snapEntity.getSnappingGuideInfo(point);
    }
    return null;
  }
}
