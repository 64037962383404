import { Avatar, Icons, LinkComponent, Text } from '@kreo/kreo-ui-components';
import moment from 'moment';
import React, { useMemo } from 'react';

import { RenderIf } from 'common/components/render-if';
import { DateFormatConstants } from 'common/constants/date-formats';
import { ProjectAccessReason } from 'unit-projects/enums';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { Styled } from './styled';

interface Props {
  isHightLight: boolean;
  name: string;
  link: string;
  date: Date;
  text: string;
  projectName: string;
  accessReason: ProjectAccessReason;
  isDeleted: boolean;
  goToLink: () => void;
}

export const CommentNotification: React.FC<Props> = ({
  isHightLight,
  name,
  link,
  date,
  text,
  projectName,
  isDeleted,
  accessReason,
  goToLink,
}) => {
  const { sendEvent } = useAnalytics();
  const handleLinkClick = React.useCallback(() => {
    goToLink();
    sendEvent(MetricNames.notification.goToLink);
  }, []);

  const projectStatusText = useMemo<string>(() => {
    if (isDeleted) {
      return '(But the project has been deleted)';
    }
    if (accessReason === ProjectAccessReason.No) {
      return '(But you don\'t have access to this project. Contact your teammate to request access)';
    }
    return '';
  }, [isDeleted, accessReason]);

  return (
    <Styled.Container
      isHightLight={isHightLight}
    >
      <Styled.AvatarContainer>
        <Avatar name={name} size={30} avatar={link} />
        <RenderIf condition={isHightLight}>
          <Styled.Badge />
        </RenderIf>
      </Styled.AvatarContainer>
      <Styled.Content>
        <Styled.DateContainer>
          <Text color={'gray'} fontSize={10}>
            {moment(date).format(DateFormatConstants.FULL_DATE_TIME_FORMAT)}
          </Text>
        </Styled.DateContainer>
        <Text fontSize={14}>
          {name}
          <Text
            color={'gray'}
            fontSize={14}
          >
            {text}
            <Text fontSize={14}>
              {`"${projectName}" ${projectStatusText}`}
            </Text>
          </Text>
        </Text>
        <RenderIf condition={!isDeleted && accessReason !== ProjectAccessReason.No}>
          <Styled.ButtonContainer>
            <LinkComponent
              text='Go to project'
              Icon={Icons.LinkArrow}
              onClick={handleLinkClick}
            />
          </Styled.ButtonContainer>
        </RenderIf>
      </Styled.Content>
    </Styled.Container>
  );
};
