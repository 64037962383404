import * as React from 'react';

import { BaseChartHeader } from '../../../../../../components/gantt-chart/chart-header-components/base-chart-header';
import { TimeframeAnimationTarget } from '../../../../../../interfaces/gantt-chart';
import { PlaybackControlPanel } from './components/playback-control-panel';

interface ChartHeaderProps {
  leftPanelWidth: number;
  width: number;
  projectStartDate: Date;
  timeframeStartDay: number;
  timeframeDuration: number;
  isPlaying: boolean;
  speed: number;
  skipOffTime: boolean;
  timelineDuration: number;
  play: () => void;
  pause: () => void;
  moveBackward: () => void;
  moveForward: () => void;
  changeSpeed: (speed: number) => void;
  setSkipOffTime: (skipOffTime: boolean) => void;
  triggerTimeframeChangeAnimation: (target: TimeframeAnimationTarget) => void;
}

export class ChartHeader extends React.PureComponent<ChartHeaderProps> {
  public render(): JSX.Element {
    return (
      <BaseChartHeader
        width={this.props.width - this.props.leftPanelWidth}
        projectStartDate={this.props.projectStartDate}
        timeframeStartDay={this.props.timeframeStartDay}
        timeframeDuration={this.props.timeframeDuration}
        triggerTimeframeChangeAnimation={this.props.triggerTimeframeChangeAnimation}
        timelineDuration={this.props.timelineDuration}
      >
        <PlaybackControlPanel
          isPlaying={this.props.isPlaying}
          speed={this.props.speed}
          skipOffTime={this.props.skipOffTime}
          play={this.props.play}
          pause={this.props.pause}
          moveBackward={this.props.moveBackward}
          moveForward={this.props.moveForward}
          changeSpeed={this.props.changeSpeed}
          setSkipOffTime={this.props.setSkipOffTime}
        />
      </BaseChartHeader>
    );
  }
}
