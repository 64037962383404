import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { Spinner } from 'common/components/spinner';
import { SimilaritySliderWithCount } from '../../similarity-slider';
import { ToolMenuButton } from '../../tool-menu-button';
import { ApplyButton } from '../apply-button';
import { useResetResult } from '../finder-menu/hooks';
import { useApplyWithState } from '../hooks';
import { WizzardMainContainer } from '../wizzard-main-container';
import { Styled } from './styled';

interface Props {
  apply: () => Promise<void>;
  count: number;
  withSimilaritySlider?: boolean;
  minSimilarity?: number;
  maxSimilarity?: number;
  similarity?: number;
  onSimilarityChange?: (value: number) => void;
  visibleCount?: number;
}

const ResultsMenuComponent: React.FC<Props> = ({
  apply,
  count,
  withSimilaritySlider,
  minSimilarity,
  maxSimilarity,
  similarity,
  onSimilarityChange,
  visibleCount,
}) => {

  const [ applyCallback, isProcessing ] = useApplyWithState(apply);
  const reset = useResetResult();

  return (
    <WizzardMainContainer>
      <Spinner show={isProcessing} withBackground={true}/>
      <Styled.Header>
        <SimilaritySliderWithCount
          count={count}
          hideSlider={!withSimilaritySlider}
          minSimilarity={minSimilarity}
          maxSimilarity={maxSimilarity}
          similarity={similarity}
          onSimilarityChange={onSimilarityChange}
        />
      </Styled.Header>
      <ToolMenuButton
        text='Reset'
        icon={Icons.UndoClassic}
        onClick={reset}
      />
      <ApplyButton
        count={visibleCount ?? count}
        apply={applyCallback}
      />
    </WizzardMainContainer>
  );
};

export const ResultsMenu = React.memo(ResultsMenuComponent);
