import styled from 'styled-components';

const Container = styled.div`
  width: 300px;
  height: 25px;
  padding: 2px;
  display: flex;
  flex-direction: row;
`;

export const Styled = {
  Container,
};
