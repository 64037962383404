import React, { useCallback } from 'react';

import { Styled } from './styled';


interface Props {
  id: string;
  onClick: (id: string) => void;
  isActive: boolean;
  isFocus: boolean;
  renderer: (id: string) => React.ReactNode;
}

export const DropDownCustomItem: React.FC<Props> = (props) => {
  const {
    onClick,
    id,
    isActive,
    isFocus,
    renderer,
  } = props;
  const onItemClick = useCallback(
    (e) => {
      e.stopPropagation();
      onClick(id);
    },
    [id],
  );

  return (
    <Styled.ItemText isFocus={isFocus} isActive={isActive} onClick={onItemClick}>
      {renderer(id)}
    </Styled.ItemText>
  );
};
