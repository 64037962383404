/* eslint-disable indent */
import * as uuidv4 from 'uuid/v4';

import {
  DrawingsScaleInfo,
  DrawingsShortInfo,
} from 'common/components/drawings/interfaces/drawings-short-drawing-info';
import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { ProjectConvertibleFileTypes } from 'common/constants/project-type-to-file-extensions';
import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { ViewModelStatus } from '../../../../units/projects/enums/view-model-status';
import {
  AddPagePayload,
  DrawingsDeleteFromLocal,
  DrawingsEditFileTreeItem,
  DrawingsToggleFileOptimize,
  SetComparisonPagePayload,
  SetStatusDrawingConversionPayload,
  SetStatusReadyDrawingRecognitionPayload,
  ToggleDrawingOptimizePayload,
  UpdateComparisonSettingPayload,
  UpdateSplittingStatusPayload,
} from '../actions/payloads/common';
import { DrawingsActionTypes } from '../actions/types/common';
import { DrawingsInitialState, MagicSearchInitialState } from '../constants';
import { DrawingsDrawMode, DrawingsModesWithFullSelectionBlock, MagicSearchDrawModes } from '../enums';
import { HoverState } from '../interfaces';
import { DrawingSnapping } from '../interfaces/drawing';
import { DrawingsCreateFile, DrawingsFileSystem } from '../interfaces/drawings-api-payload';
import { DrawingsFile, DrawingsFolderViewInfo, DrawingsPage } from '../interfaces/drawings-file-info';
import { DrawingsInstanceMeasure } from '../interfaces/drawings-instance-measure';
import { DrawingsState } from '../interfaces/drawings-state';
import { DrawingsUploadPdfResult } from '../interfaces/drawings-upload-pdf-result';
import { CursorHintType } from '../layout-components/drawings-cursor-hint';
import { PdfScaleFinishPatch } from '../notifications/interfaces';
import { DrawingAnnotationUtils } from '../utils/drawing-annotation-utils';
import { DrawingsPdfBrowserUtils } from '../utils/drawings-pdf-browser-utils';
import { DrawingsSelectionUtils } from '../utils/drawings-selection-utils';
import { DrawingsUtils } from '../utils/drawings-utils';


function dropComparisonSettings(
  drawingsInfo: Record<string, DrawingsShortInfo>,
  currentDrawingId: string,
  helper: MonoliteHelper<DrawingsState>,
  removedPages: Set<string>,
): MonoliteHelper<DrawingsState> {
  for (const [key, info] of Object.entries(drawingsInfo)) {
    if (removedPages.has(info.pageToCompareId) && !removedPages.has(key)) {
      helper.set(_ => _.drawingsInfo[key].pageToCompareId, null);
      if (currentDrawingId === key) {
        helper.set(_ => _.currentDrawingInfo.pageToCompareId, null);
      }
    }
  }
  return helper;
}

function updateOptimizeInDrawing(
  drawingInfo: DrawingsShortInfo,
  isOptimized: boolean,
): DrawingsShortInfo {
  const newStatus = isOptimized && drawingInfo.rasterizationStatus !== ViewModelStatus.Ready ?
    ViewModelStatus.Calculating
    : drawingInfo.rasterizationStatus;
  return { ...drawingInfo, isOptimized, rasterizationStatus: newStatus };
}


export const DrawingsReducerMethods: ReducerMethods<DrawingsState> = {
  [DrawingsActionTypes.UPLOAD_PDF]: (s, filesUploadResult: DrawingsUploadPdfResult[]) => {
    const helper = new MonoliteHelper(s);
    for (const file of filesUploadResult) {
      const pages = new Array<DrawingsPage>();
      helper
        .set(_ => _.filesCount, _ => _ + 1);
      for (const { id, pdfId, pageNumber, width, height, snapping } of file.drawings) {
        const recognitionStatus = ViewModelStatus.Empty;
        pages.push({ pageNumber, drawingId: id, pdfId, recognitionStatus, isOptimized: false });
        helper
          .set(_ => _.drawingInstances[id], [])
          .set(_ => _.drawingsInfo[id], {
            drawingId: id,
            pdfId,
            pageNumber,
            name: (pageNumber + 1).toString(),
            recognitionStatus,
            width,
            height,
            scaleRecognitionStatus: ViewModelStatus.Calculating,
            isSnappingBlocked: snapping === null,
            rotationAngle: 0,
            isOptimized: false,
          },
          );
      }
      const fileData: DrawingsFile = {
        pages,
        id: file.pdf.id,
        properties: { name: file.pdf.name },
        parentId: file.pdf.parentId,
        type: TreeTableRowType.Element,
        splittingStatus: ViewModelStatus.Calculating,
      };
      if (ProjectConvertibleFileTypes.some(x => file.pdf.name.endsWith(x))) {
        fileData.cadFileConvertingStatus = ViewModelStatus.Calculating;
      }
      helper.set(_ => _.files.entities[file.pdf.id], fileData);
      if (file.pdf.parentId) {
        helper.setAppend(_ => _.files.entities[file.pdf.parentId].children, file.pdf.id);
      } else {
        helper.setAppend(_ => _.files.rootIds, file.pdf.id);
      }
    }
    return helper.get();
  },
  [DrawingsActionTypes.SET_DRAWING_CONVERSION_STATUS]: (s, payload: SetStatusDrawingConversionPayload) => {
    if (!s.files.entities[payload.pdfId]) {
      return s;
    }
    return new MonoliteHelper(s)
      .set(_ => (_.files.entities[payload.pdfId] as DrawingsFile).cadFileConvertingStatus, payload.status)
      .get();
  },
  [DrawingsActionTypes.SELECT_DRAWING]: (s, drawingId: string) => {
    const entity = s.files.entities[drawingId];
    if (DrawingsPdfBrowserUtils.isFolder(entity)) {
      return s;
    }
    const helper = new MonoliteHelper(s);
    const newDrawingId = entity && DrawingsPdfBrowserUtils.isFile(entity)
      ? entity.pages[0].drawingId
      : drawingId;
    if (!s.drawingInstances[newDrawingId]) {
      helper.set(_ => _.drawingInstances[newDrawingId], []);
    }
    return helper
      .set(_ => _.currentDrawingInfo, s.drawingsInfo[newDrawingId])
      .set(_ => _.snappingGeometry, s.drawingSnappingGeometry[newDrawingId])
      .set(_ => _.selectedInstances, [])
      .set(_ => _.selectGeometryGroup, [])
      .set(_ => _.hoverState, null)
      .get();
  },
  [DrawingsActionTypes.DELETE_DRAWING_FROM_LOCAL]: (s, payload: DrawingsDeleteFromLocal) => {
    const drawingsHelper = new MonoliteHelper(s).set(_ => _.files, payload.fileSystem);
    if (s.selectedFile && payload.files.includes(s.selectedFile)) {
      drawingsHelper.set(_ => _.selectedFile, null);
    }
    for (const drawingId of payload.drawings) {
      const drawingInfo = s.drawingsInfo[drawingId];
      if (s.drawingInstances[drawingId]) {
        drawingsHelper
        .setFilter(_ => _.hiddenInstances, _ => !s.drawingInstances[drawingId].includes(_))
        .setFilter(_ => _.selectedInstances, _ => !s.drawingInstances[drawingId].includes(_));
      }
      if (payload.fileSystem.entities[drawingInfo.pdfId]) {
        drawingsHelper
          .setFilter(
              _ => (_.files.entities[drawingInfo.pdfId] as DrawingsFile).pages,
              _ => _.drawingId !== drawingId,
            );
      }
      drawingsHelper
        .set(_ => _.drawingInstances[drawingId], undefined)
        .removeKey(_ => _.textSearch.results.results, drawingId)
        .set(_ => _.selectedPages, payload.selectedPages)
        .removeKey(_ => _.drawingsInfo, drawingId);
    }
    const drawingsSet = new Set(payload.drawings);
    dropComparisonSettings(s.drawingsInfo, s.currentDrawingInfo?.drawingId, drawingsHelper, drawingsSet);
    return drawingsHelper
      .set(_ => _.filesCount, s.filesCount - payload.files.length)
      .get();
  },
  [DrawingsActionTypes.PAGES_RESCALE]: (s, payload: DrawingsScaleInfo[]) => {
    const helper = new MonoliteHelper(s);
    payload.forEach(drawingScaleInfo => {
      helper
        .set(
          _ => _.drawingsInfo[drawingScaleInfo.drawingId],
          _ => {
            return { ..._, ...drawingScaleInfo };
          },
        )
        .set(
          _ => _.currentDrawingInfo,
          _ => _.drawingId === drawingScaleInfo.drawingId ? { ..._, ...drawingScaleInfo } : _,
        );
    });
    return helper.get();
  },
  [DrawingsActionTypes.PAGE_META_PARAMETER_UPDATE]: (s, payload) => {
    const helper = new MonoliteHelper(s);
    if (s.currentDrawingInfo && s.currentDrawingInfo.drawingId === payload.drawingId) {
      helper.set(_ => _.currentDrawingInfo[payload.parameter], payload.value);
    }
    return helper
      .set(_ => _.drawingsInfo[payload.drawingId][payload.parameter], payload.value)
      .get();
  },
  [DrawingsActionTypes.EXPAND_FOLDER]: (s, id: string) => {
    return new MonoliteHelper(s)
      .set(
        _ => (_.files.entities[id] as DrawingsFolderViewInfo).expanded,
        !(s.files.entities[id] as DrawingsFolderViewInfo).expanded,
      )
      .get();
  },
  [DrawingsActionTypes.CLOSE_ALL_FOLDERS]: (s) => {
    return new MonoliteHelper(s)
      .set(
        _ => _.files.entities,
        entities => {
          const newEntities = {};
          for (const [key, value] of Object.entries(entities)) {
            newEntities[key] = { ...value, expanded: false };
          }

          return newEntities;
        },
      )
      .get();
  },
  [DrawingsActionTypes.ADD_FOLDER]: (s, parentId?: string) => {
    const drawingsVisualFolder: DrawingsFolderViewInfo = {
      parentId,
      id: uuidv4(),
      properties: { name: 'New Folder' },
      children: [],
      expanded: true,
      type: TreeTableRowType.Group,
    };
    const drawingsHelper = new MonoliteHelper(s)
      .set(_ => _.files.entities[drawingsVisualFolder.id], drawingsVisualFolder);
    if (parentId) {
      drawingsHelper.setAppend(_ => _.files.entities[parentId].children, drawingsVisualFolder.id);
    } else {
      drawingsHelper.setAppend(_ => _.files.rootIds, drawingsVisualFolder.id);
    }
    return drawingsHelper.get();
  },
  [DrawingsActionTypes.LOAD_FILE_DATA_SUCCESS]: (s, file: DrawingsCreateFile) => {
    const isFileExist = file.id in s.files.entities;
    const { file: parsedFile, drawings } = isFileExist
      ? DrawingsUtils.parseFile(file, s.files.entities[file.id].parentId)
      : DrawingsUtils.parseFile(file);
    const helper = new MonoliteHelper(s)
      .set(_ => _.files.entities[file.id], parsedFile)
      .set(_ => _.drawingsInfo, _ => ({ ..._, ...drawings }));
    if (!isFileExist) {
      helper
        .setAppend(_ => _.files.rootIds, file.id)
        .set(_ => _.filesCount, _ => _ + 1);
    }
    if (s.currentDrawingInfo && s.currentDrawingInfo.drawingId in drawings) {
      helper.set(_ => _.currentDrawingInfo, drawings[s.currentDrawingInfo.drawingId]);
    }
    return helper.get();
  },
  [DrawingsActionTypes.SET_FILES_DATA]: (s, files: DrawingsCreateFile[]) => {
    const drawings = {};
    const fileResult = {};
    for (const file of files) {
      if (s.files.entities[file.id]) {
        const { file: parsedFile } = DrawingsUtils.parseFile(file, file.parentId, drawings);
        fileResult[file.id] = parsedFile;
      }
    }
    return new MonoliteHelper(s)
      .set(_ => _.files.entities, _ => ({ ..._, ...fileResult }))
      .set(_ => _.drawingsInfo, _ => ({ ..._, ...drawings }))
      .get();
  },
  [DrawingsActionTypes.LOAD_DRAWINGS_DATA_SUCCESS]: (s, fs: DrawingsFileSystem) => {
    const {
      drawings,
      files,
      filesCount,
      selectedPages,
    } = DrawingsUtils.prepareDrawingsData(fs);
    return new MonoliteHelper(s)
      .set(_ => _.filesCount, filesCount)
      .set(_ => _.drawingsInfo, drawings)
      .set(_ => _.files, files)
      .set(_ => _.selectedPages, selectedPages)
      .get();
  },
  [DrawingsActionTypes.DUPLICATE_PAGE_SUCCESS]: (s, payload: AddPagePayload) => {
    const { page, pdfId } = payload;
    const pdfFile = s.files.entities[pdfId] as DrawingsFile;
    const drawingsPage = DrawingsUtils.parsePage(page, pdfId, pdfFile.screenshotsStatus);
    return new MonoliteHelper(s)
      .setAppend(_ => (_.files.entities[pdfId] as DrawingsFile).pages, drawingsPage.page)
      .setSort(
        _ => (_.files.entities[pdfId] as DrawingsFile).pages,
        (item1, item2) => item1.pageNumber - item2.pageNumber,
      )
      .set(_ => _.drawingsInfo[page.id], drawingsPage.drawingInfo)
      .get();
  },
  [DrawingsActionTypes.EDIT_FILE_TREE_ITEM]: (s, payload: DrawingsEditFileTreeItem) => {
    const helper = new MonoliteHelper(s)
      .set(_ => _.files.entities[payload.id].properties.name, payload.name);
    const entity = s.files.entities[payload.id];
    if (
      entity.parentId !== payload.parentFolderId
      && !DrawingsPdfBrowserUtils.isFile(s.files.entities[payload.parentFolderId])
    ) {
      helper.set(_ => _.files.entities[entity.id].parentId, payload.parentFolderId);
      if (payload.parentFolderId) {
        helper.setAppend(_ => _.files.entities[payload.parentFolderId].children, entity.id);
      } else {
        if (entity.parentId) {
          helper.setFilter(_ => _.files.entities[entity.parentId].children, (id) => id !== entity.id);
        }
      }
      if (entity.parentId) {
        helper.setFilter(_ => _.files.entities[entity.parentId].children, x => x !== payload.id);
      } else {
        helper.setFilter(_ => _.files.rootIds, x => x !== payload.id);
      }

      if (s.fsHighlightStatus.filesWithSelectedElement[payload.id]) {
        helper.set(_ => _.fsHighlightStatus, DrawingsSelectionUtils.editFileTreeItem(s, payload));
      }
    }

    const result = helper.get();

    return result;
  },
  [DrawingsActionTypes.DROP_STATE]: () => DrawingsInitialState,
  [DrawingsActionTypes.SET_STATUS_DRAWING_RECOGNITION]: (s, payload: SetStatusReadyDrawingRecognitionPayload) => {
    const helper = new MonoliteHelper(s);
    const { pageId, status } = payload;
    if (s.currentDrawingInfo && s.currentDrawingInfo.drawingId === pageId) {
      helper.set(_ => _.currentDrawingInfo.recognitionStatus, status);
    }
    const pdfId = s.drawingsInfo && s.drawingsInfo[pageId] && s.drawingsInfo[pageId].pdfId;
    if (pdfId) {
      helper
        .set(_ => _.drawingsInfo[pageId].recognitionStatus, status)
        .setMap(_ => (_.files.entities[pdfId] as DrawingsFile).pages, page => {
          if (page.drawingId !== pageId) {
            return page;
          }
          return { ...page, recognitionStatus: status };
        });
    }
    return helper.get();
  },
  [DrawingsActionTypes.LOAD_DRAWINGS_DATA]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.loadStatus, RequestStatus.Loading)
      .set(_ => _.currentDrawingInfo, null)
      .get();
  },
  [DrawingsActionTypes.PDF_SCREENSHOTS_GENERATED]: (state, pdfId: string) => {
    if (!state.files.entities[pdfId]) {
      return state;
    }
    const helper = new MonoliteHelper(state)
      .set(_ => (_.files.entities[pdfId] as DrawingsFile).screenshotsStatus, ViewModelStatus.Ready);
    const drawingInfoHelper = new MonoliteHelper(state.drawingsInfo);
    for (const page of (state.files.entities[pdfId] as DrawingsFile).pages) {
      drawingInfoHelper.set(_ => _[page.drawingId].screenshotCreated, true);
      if (state.currentDrawingInfo && page.drawingId === state.currentDrawingInfo.drawingId) {
        helper.set(_ => _.currentDrawingInfo.screenshotCreated, true);
      }
    }
    return helper
      .setMap(_ => (_.files.entities[pdfId] as DrawingsFile).pages, _ => ({ ..._, screenshotCreated: true }))
      .set(_ => _.drawingsInfo, drawingInfoHelper.get())
      .get();
  },
  [DrawingsActionTypes.SAVE_MEASUREMENTS]: (state, measurements: DrawingsInstanceMeasure[]) => {
    const helper = new MonoliteHelper(state);

    const updateMeasure = {};
    for (const measurement of measurements) {
      const measurementId = Array.isArray(measurement.id)
        ? DrawingAnnotationUtils.getLineKey(...measurement.id as [string, string])
        : measurement.id;
        updateMeasure[measurementId] = measurement;
    }

    return helper
      .set(_ => _.elementMeasurement, _ => ({ ..._, ...updateMeasure }))
      .get();
  },
  [DrawingsActionTypes.FULL_DATA_LOADED]: (state) => {
    return new MonoliteHelper(state).set(_ => _.loadStatus, RequestStatus.Loaded).get();
  },
  [DrawingsActionTypes.SELECT_FILE]: (s, entityId: string) => {
    return new MonoliteHelper(s)
      .set(_ => _.selectedFile, entityId)
      .get();
  },
  [DrawingsActionTypes.SET_TABS]: (s, payload: string[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.selectedPages, _ => payload)
      .get();
  },
  [DrawingsActionTypes.LOAD_DATA_FAILED]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.loadStatus, RequestStatus.Failed)
      .get();
  },
  [DrawingsActionTypes.SET_PAGE_TO_COMPARE]: (s, payload: SetComparisonPagePayload) => {
    const drawingHelper = new MonoliteHelper(s.drawingsInfo[payload.currentId]);
    if (!s.drawingsInfo[payload.currentId].pagesCompareSettings) {
      drawingHelper.set(_ => _.pagesCompareSettings, { isActive: true });
    }
    drawingHelper.set(_ => _.pageToCompareId, payload.pageToCompareId);
    return new MonoliteHelper(s)
      .set(_ => _.drawingsInfo[payload.currentId], drawingHelper.get())
      .set(_ => _.currentDrawingInfo, drawingHelper.get())
      .get();
  },
  [DrawingsActionTypes.UPDATE_COMPARE_SETTING]: (s, payload: UpdateComparisonSettingPayload) => {
    const drawingHelper = new MonoliteHelper(s.drawingsInfo[payload.currentId]);
    drawingHelper.set(_ => _.pagesCompareSettings[payload.paramName], payload.value);

    return new MonoliteHelper(s)
      .set(_ => _.drawingsInfo[payload.currentId], drawingHelper.get())
      .set(_ => _.currentDrawingInfo, drawingHelper.get())
      .get();
  },
  [DrawingsActionTypes.REMOVE_COMPARISON]: (s, payload: string) => {
    return new MonoliteHelper(s)
      .set(_ => _.drawingsInfo[payload].pageToCompareId, null)
      .set(_ => _.currentDrawingInfo.pageToCompareId, null)
      .set(_ => _.drawingsInfo[payload].pagesCompareSettings, null)
      .get();
  },
  [DrawingsActionTypes.MARK_DRAWING_WITHOUT_SNAPPING]: (s, drawingId: string) => {
    if (!s.drawingsInfo[drawingId]) {
      return s;
    }
    return new MonoliteHelper(s)
      .set(_ => _.drawingsInfo[drawingId].isSnappingBlocked, true)
      .set(_ => _.currentDrawingInfo, _ => _?.drawingId !== drawingId ? _ : { ..._, isSnappingBlocked: true })
      .get();
  },
  [DrawingsActionTypes.PATCH_PAGE_SCALE]:
    (s, payload: { pageId: string, patch: PdfScaleFinishPatch }) => {
      const { pageId, patch } = payload;
      if (!s.drawingsInfo[pageId]) {
        return s;
      }
      const helper = new MonoliteHelper(s)
        .set(
          _ => _.drawingsInfo[pageId],
          _ => ({ ..._, ...patch, scaleRecognitionStatus: ViewModelStatus.Ready }),
        );
      if (pageId === s.currentDrawingInfo?.drawingId) {
        helper.set(_ => _.currentDrawingInfo, helper.get().drawingsInfo[pageId]);
      }
      return helper.get();
    },
  [DrawingsActionTypes.TOGGLE_DRAWING_OPTIMIZE]: (s, { drawingId }: ToggleDrawingOptimizePayload) => {
    const helper = new MonoliteHelper(s)
      .set(_ => _.drawingsInfo[drawingId], _ => updateOptimizeInDrawing(_, !_.isOptimized));
    if (s.currentDrawingInfo?.drawingId === drawingId) {
      helper
        .set(_ => _.currentDrawingInfo, helper.get().drawingsInfo[drawingId]);
    }
    return helper.get();
  },
  [DrawingsActionTypes.TOGGLE_FILE_OPTIMIZE]: (s, payload: DrawingsToggleFileOptimize) => {
    const drawingsHelper = new MonoliteHelper(s.drawingsInfo);
    for (const { pdfId, drawingId } of Object.values(s.drawingsInfo)) {
      if (pdfId === payload.fileId) {
        drawingsHelper.set(_ => _[drawingId], _ => updateOptimizeInDrawing(_, payload.status));
      }
    }
    const helper = new MonoliteHelper(s)
      .set(_ => _.drawingsInfo, drawingsHelper.get());
    if (s.currentDrawingInfo?.pdfId === payload.fileId) {
      helper.set(_ => _.currentDrawingInfo, helper.get().drawingsInfo[s.currentDrawingInfo.drawingId]);
    }
    return helper.get();
  },
  [DrawingsActionTypes.MARK_DRAWING_WITH_RENDER_PROBLEMS]: (s, drawingId: string) => {
    const helper = new MonoliteHelper(s);
    if (s.drawingsInfo[drawingId]) {
      helper.set(_ => _.drawingsInfo[drawingId].hasRenderProblems, true);
    }
    if (s.currentDrawingInfo.drawingId === drawingId) {
      helper.set(_ => _.currentDrawingInfo.hasRenderProblems, true);
    }
    return helper.get();
  },
  [DrawingsActionTypes.UPDATE_DRAWINGS_RASTERIZATION]: (s, payload: Record<string, ViewModelStatus>) => {
    const helper = new MonoliteHelper(s);
    for (const [drawingId, status] of Object.entries(payload)) {
      helper.set(_ => _.drawingsInfo[drawingId].rasterizationStatus, status);
      if (s.currentDrawingInfo?.drawingId === drawingId) {
        helper.set(_ => _.currentDrawingInfo.rasterizationStatus, status);
      }
    }
    return helper.get();
  },
  [DrawingsActionTypes.UPDATE_PDF_SPLITTING]: (s, { fileId, splittingStatus }: UpdateSplittingStatusPayload) => {
    return new MonoliteHelper(s)
      .set(_ => (_.files.entities[fileId] as DrawingsFile).splittingStatus, splittingStatus)
      .get();
  },

  [DrawingsActionTypes.CHANGE_GROUP_PIN_STATUS]: (s, groupId: string) => {
    const helper = new MonoliteHelper(s);
    if (s.pinnedGroupIds.includes(groupId)) {
      helper.setFilter(_ => _.pinnedGroupIds, _ => _ !== groupId);
    } else {
      helper.setAppend(_ => _.pinnedGroupIds, groupId);
    }

    return helper.get();
  },
  [DrawingsActionTypes.UNPIN_GROUPS]: (s, groupsToUnpin) => {
    return new MonoliteHelper(s)
      .setFilter(_ => _.pinnedGroupIds, _ => !groupsToUnpin.includes(_))
      .get();
  },
  [DrawingsActionTypes.PIN_GROUPS]: (s, groupsToPin) => {
    return new MonoliteHelper(s)
      .setConcat(_ => _.pinnedGroupIds, groupsToPin)
      .get();
  },
  [DrawingsActionTypes.SET_CONTEXT_MENU_POSITION]: (s, contextMenuPosition) => {
    const helper = new MonoliteHelper(s);
    if (contextMenuPosition) {
      helper.set(_ => _.contextMenuPosition, contextMenuPosition);
    } else if (s.contextMenuPosition) {
      helper.set(_ => _.contextMenuPosition, null);
    }

    return helper.get();
  },
  [DrawingsActionTypes.SET_DRAWING_HOVER_STATE]: (s, hoverState: HoverState) => {
    return new MonoliteHelper(s).set(_ => _.hoverState, hoverState).get();
  },
  [DrawingsActionTypes.REMOVE_DRAWING_HOVER_STATE]: (s, instanceId: string) => {
    if (s.hoverState?.hoverInstanceId === instanceId) {
      return new MonoliteHelper(s).set(_ => _.hoverState, null).get();
    }
    return s;
  },
  [DrawingsActionTypes.SET_CURRENT_DRAWING_SNAPPING]: (s, snapping: DrawingSnapping) => {
    return new MonoliteHelper(s)
      .set(_ => _.currentDrawingSnapping, snapping)
      .get();
  },
  [DrawingsActionTypes.SHOW_OPTIMIZE_MESSAGE]: (s, payload: boolean) => {
    return new MonoliteHelper(s).set(_ => _.showOptimizeMessage, payload).get();
  },
  [DrawingsActionTypes.SET_DRAW_MODE]: (s, payload: DrawingsDrawMode) => {
    const helper = new MonoliteHelper(s);
    if (!MagicSearchDrawModes.includes(payload) && MagicSearchDrawModes.includes(s.drawMode)) {
      helper.set(
        _ => _.magicSearch,
        {
          ...MagicSearchInitialState,
          dpi: s.magicSearch.dpi,
          similarity: s.magicSearch.similarity,
        });
    }
    if (DrawingsModesWithFullSelectionBlock.includes(payload)) {
      helper
        .set(_ => _.selectedInstances, [])
        .set(_ => _.selectGeometryGroup, []);
    }
    return helper
      .set(_ => _.finishApi, null)
      .set(_ => _.drawMode, payload)
      .set(_ => _.newInstanceSettings.shouldSearchSimilar, false)
      .get();
  },
  [DrawingsActionTypes.SET_DRAWING_RENDERED]: (s, payload: boolean) => {
    return new MonoliteHelper(s).set(_ => _.drawingRenderedStatus, payload).get();
  },
  [DrawingsActionTypes.SET_CURSOR_HINT]: (s, payload: CursorHintType) => {
    return new MonoliteHelper(s).set(_ => _.cursorHintType, payload).get();
  },
  [DrawingsActionTypes.TOGGLE_IS_3D]: (s) => {
    return new MonoliteHelper(s).set(_ => _.is3d, !s.is3d).get();
  },
  [DrawingsActionTypes.SET_FINISH_GEOMETRY_API]: (s, finishApi) => {
    return new MonoliteHelper(s).set(_ => _.finishApi, finishApi).get();
  },
  [DrawingsActionTypes.SET_OPERATION_IDS]: (s, operationIds: string[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.instanceToOperateWith, operationIds)
      .get();
  },
};
