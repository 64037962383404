import autobind from 'autobind-decorator';
import * as THREE from 'three';

import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsOffsetUtils } from 'common/components/drawings/drawings-geometry/drawings-helpers';
import { DrawingsPolylineGeometry, ShortPointDescription } from 'common/components/drawings/interfaces';
import { ThreeDMeasuresCreators } from 'common/components/drawings/utils/3d-geometries/3d-measures-creators';
import { ConvertGeometryResult, Geometry, GeometryConfig } from './geometry';

interface PolylineConfig extends GeometryConfig<DrawingsPolylineGeometry> {
  showThicknessContextObserver: ContextObserver<boolean>;
}

export class Polyline extends Geometry<DrawingsPolylineGeometry, PolylineConfig> {
  constructor(config: PolylineConfig) {
    super(config);
    config.showThicknessContextObserver.subscribe(this.onShowThicknessChange, true);
  }

  public override destroy(): void {
    super.destroy();
    this._config.showThicknessContextObserver.unsubscribe(this.onShowThicknessChange);
  }

  protected override async getConvertedGeometry(): Promise<ConvertGeometryResult> {
    if (this._config.geometry.thickness && this._config.showThicknessContextObserver.getContext()) {
      const offset = this._config.geometry.thickness * this._config.scaleObserver.getContext();
      const points = await DrawingsOffsetUtils.polylineAsyncOffset(
        this._config.geometry.points,
        (pointId) => {
          const p = this._config.getPointInfo(pointId);
          return { x: p[0], y: p[1] };
        },
        offset / 2,
        (point) => [point.x, -point.y] as ShortPointDescription,
      );
      return ThreeDMeasuresCreators.createPitched3DPolygonWithHeightAndHoles({
        polygon2D: points[0],
        polygonHoles2D: points.slice(1),
        height: this._height,
      });
    } else {
      const points = this._config.geometry.points.map((pointId) => {
        const p = this._config.getPointInfo(pointId);
        return [p[0], -p[1]] as ShortPointDescription;
      });
      return ThreeDMeasuresCreators.convert2DPolygine(
        {
          polyline2D: points,
          height: this._height,
        },
      );
    }
  }

  protected override getMaterialSettings(geometry: DrawingsPolylineGeometry): THREE.MeshStandardMaterialParameters {
    const base = super.getMaterialSettings(geometry);
    if (this._config.geometry.thickness && this._config.showThicknessContextObserver.getContext()) {
      return base;
    }
    return {
      ...base,
      side: THREE.DoubleSide,
    };
  }

  @autobind
  private onShowThicknessChange(): void {
    this.removeGeometry();
    this.render(this._config.geometry);
  }
}
