import { Icons, LinkComponent } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ChatLink } from '../../../../../../units/analytics/components/chat';
import { Styled } from './styled';


export const ChatLinkButton: React.FC = () => {
  return (
    <Styled.ChatLink>
      <ChatLink>
        <LinkComponent
          text='Chat with us'
          Icon={Icons.Comments2D}
        />
      </ChatLink>
    </Styled.ChatLink>
  );
};
