import { ExcelFormulaHelper } from 'common/components/excel-table';
import { TwoDRegex } from '../units/2d-regex';

export const FONT_SIZES = [8, 9, 10, 11, 12, 13, 14, 15, 16];

export const DEFAULT_FONT_SIZE = 11;

const normilazeString = (value: string): string => {
  return value.replace(TwoDRegex.fullCellIdGlobal, '');
};

export const isValidFormulaQuote = (value: string): boolean => {
  let hasOpenDoubleQuote = false;
  if (!ExcelFormulaHelper.isFormula(value)) {
    return true;
  }
  const normilizedString = normilazeString(value);
  for (const char of normilizedString) {
    if (char === '"') {
      hasOpenDoubleQuote = !hasOpenDoubleQuote;
      continue;
    }

    if (char === `'` && !hasOpenDoubleQuote) {
      return false;
    }
  }

  return true;
};
