import { DrawingsStyleMenuType } from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { DrawingsContextMenuBodyHeaderType } from '../drawings-context-menu-body';

export interface UseHeaderTypeParams {
  canEditMeasurement: boolean;
  hasStrokedInstancesInSelected: boolean;
  isRendererInitialized: boolean;
  instancesIds: string[];
}

interface HeaderType {
  headerType: DrawingsContextMenuBodyHeaderType;
  styleMenuType: DrawingsStyleMenuType;
}


export function useHeaderType(props: UseHeaderTypeParams): HeaderType {
  const { canEditMeasurement, hasStrokedInstancesInSelected, isRendererInitialized, instancesIds } = props;
  const hasSelectedInstances = instancesIds.length > 0;
  if (!isRendererInitialized) {
    return {
      headerType: DrawingsContextMenuBodyHeaderType.Hidden,
      styleMenuType: DrawingsStyleMenuType.OnlyColors,
    };
  }
  if (hasSelectedInstances && canEditMeasurement) {
    return {
      headerType: DrawingsContextMenuBodyHeaderType.EditMeasurementStyle,
      styleMenuType: hasStrokedInstancesInSelected ? DrawingsStyleMenuType.Stroked : DrawingsStyleMenuType.Count,
    };
  }
  return {
    headerType: DrawingsContextMenuBodyHeaderType.Default,
    styleMenuType: DrawingsStyleMenuType.OnlyColors,
  };
}
