import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { LinkComponent } from 'common/components/link-component';
import {
  DPI_MODE_NAMES,
  SEARCH_AND_MEASURE_DPIs,
} from '../../constants/dpi-constants';
import { WizzardStatus } from '../../enums/dropper-state';
import { AiButtonWithStatus } from '../ai-button-with-status';
import { DpiSelector } from '../dpi-selector';
import { SearchZoneButton } from '../search-zone-button';
import { useDPI } from './hooks/use-dpi';
import { useRerun } from './hooks/use-rerun';
import { useRun } from './hooks/use-run';
import { useSearchingZone } from './hooks/use-searching-zone';
import { Styled } from './styled';

interface Props {
  status: WizzardStatus;
  reset: () => void;
}


const MagicSearchMenuComponent: React.FC<Props> = ({ reset, status }) => {
  const [ dpi, setDpi ] = useDPI();

  const { zone, clearZone, specifyZone } = useSearchingZone();

  useRerun();

  const run = useRun();

  const runAgain = useCallback(() => {
    run(dpi);
  }, [run, dpi]);

  return (
    <Styled.Container>
      <SearchZoneButton
        hasZone={!!zone}
        clearZone={clearZone}
        specifyZone={specifyZone}
      />
      <LinkComponent
        text='Reset'
        icon={Icons.UndoClassic}
        onClick={reset}
        fontColor='mainFont'
        size='m'
      />
      <DpiSelector
        dpi={dpi}
        onChange={setDpi}
        options={SEARCH_AND_MEASURE_DPIs}
        names={DPI_MODE_NAMES}
      />
      <AiButtonWithStatus
        text={'Restart'}
        onClick={runAgain}
        status={status}
        icon={Icons.MagicSearch2D}
      />
    </Styled.Container>
  );
};

export const MagicSearchMenu = React.memo(MagicSearchMenuComponent);
