import { Popover } from '@material-ui/core';
import autobind from 'autobind-decorator';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { Calendar } from 'react-date-range';
import { WrappedFieldProps } from 'redux-form';

import './material-date-picker.scss';

import { DATE_FORMAT, MaterialInput, MaterialInputProps } from './material-input';

interface MaterialDatePickerState {
  anchorElement: HTMLDivElement;
  isOpen: boolean;
  date: Moment;
}

const popoverClasses = {
  paper: 'date-picker__paper',
};

export class MaterialDatePicker extends React.Component<MaterialInputProps, MaterialDatePickerState> {
  public state: MaterialDatePickerState = {
    isOpen: false,
    anchorElement: null,
    date: moment(),
  };

  public static getDerivedStateFromProps(
    props: MaterialInputProps,
  ): Partial<MaterialDatePickerState> {
    return props.value ? { date: moment(props.value) } : null;
  }

  public render(): React.ReactNode {
    return (
      <React.Fragment>
        <MaterialInput
          {...this.props}
          onClick={this.onOpen}
          isBlocked={true}
        />
        <Popover
          className='date-picker__wrap'
          open={this.state.isOpen}
          anchorEl={this.state.anchorElement}
          onClose={this.onClose}
          classes={popoverClasses}
        >
          <div className='date-picker'>
            <Calendar
              date={this.state.date}
              showMonthAndYearPickers={false}
              showDateDisplay={false}
              onChange={this.onChange}
            />
          </div>
        </Popover>
      </React.Fragment>
    );
  }

  @autobind
  private onOpen(e: React.MouseEvent<HTMLDivElement>): void {
    this.setState({
      anchorElement: e.currentTarget,
      isOpen: true,
    });
  }

  @autobind
  private onClose(): void {
    this.setState({
      isOpen: false,
    });
  }

  @autobind
  private onChange(value: Moment): void {
    const dateFormated = moment(value).format(DATE_FORMAT);
    this.props.onChange(null, dateFormated);
    this.onClose();
  }
}

export const MaterialDatePickerField: React.FC<MaterialInputProps> = (
  props: WrappedFieldProps & MaterialInputProps,
): JSX.Element => {
  const {
    input,
    meta: { submitFailed, error },
    ...rest } = props;

  const onChange = (_event: React.ChangeEvent<{}>, value: string): void => {
    input.onChange(value);
  };

  return (
    <MaterialDatePicker
      {...input}
      error={submitFailed && error}
      onChange={onChange} // eslint-disable-line react/jsx-no-bind
      {...rest}
    />
  );
};
