import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px 10px;
  > div {
    box-shadow: unset;
  }
`;

const Button = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

export const CreateColorDialogStyled = {
  Container,
  Button,
};
