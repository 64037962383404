import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { DrawingsShortInfo } from '../../interfaces';

export function useDrawingInfo(drawingId: string): DrawingsShortInfo {
  return useSelector<State, DrawingsShortInfo>(s => s.drawings.drawingsInfo[drawingId]);
}

export function useCurrentDrawingInfo(): DrawingsShortInfo {
  return useSelector<State, DrawingsShortInfo>(s => s.drawings.currentDrawingInfo);
}
