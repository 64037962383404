import { arrayUtils } from 'common/utils/array-utils';
import { AssignedPia } from '../../../../units/2d';
import { TwoDRegex } from '../../../../units/2d/units/2d-regex';
import { DrawingsAssign } from '../drawings-annotation-legend/utils/assing-utils';
import { DrawingsInstanceType } from '../enums';
import {
  DrawingsGeometryGroup,
  DrawingsGeometryInstance,
  DrawingsGeometryInstanceWithIdAndGroupId,
} from '../interfaces';
import { AnnotationLegendItem, AnnotationLegendTree } from '../interfaces/annotation-legend-tree';
import {
  AnnotationFilters,
  AssignFilterValue,
  FilterData,
  ScheduleFilterValue,
  ShapesFilterValue,
  OriginFilterValue,
} from '../interfaces/drawing-filters';
import { AnnotationLegendItemTypeguards } from './annotation-legend-item-typeguards';
import { DrawingsGroupUtils } from './drawings-group-utils';


type SortInstancesFn = (
  sortItems: DrawingsGeometryInstanceWithIdAndGroupId[],
) => DrawingsGeometryInstanceWithIdAndGroupId[];


function addRootMeasures(
  items: AnnotationLegendItem[],
  filteredGeometryIdsByMeasurementFilter: Set<string>,
  filteredGeometryIdsByGlobalFilterMap: Record<string, string>,
  geometry: Record<string, DrawingsGeometryInstance>,
  usedGeometryIdsMap: Record<string, boolean>,
  sortInstances: SortInstancesFn,
): AnnotationLegendItem[] {
  const rootItems = [];
  for (const instanceId of filteredGeometryIdsByMeasurementFilter) {
    if (usedGeometryIdsMap[instanceId] || !filteredGeometryIdsByGlobalFilterMap[instanceId]) {
      continue;
    }
    rootItems.push({
      ...geometry[instanceId],
      id: instanceId,
      groupId: null,
      nestingCount: 0,
    });
  }
  const sortedRootItems = sortInstances(rootItems);
  arrayUtils.extendArray(items, sortedRootItems);

  return items;
}

function addGroups(
  groups: DrawingsGeometryGroup[],
  openGroups: Record<string, boolean>,
  geometry: Record<string, DrawingsGeometryInstance>,
  usedGeometryIdsMap: Record<string, boolean>,
  filteredGeometryIdsByMeasurementFilter: Set<string>,
  filteredGeometryIdsByGlobalFilterMap: Record<string, string>,
  filterFn: (item: DrawingsGeometryGroup) => boolean,
  sortInstances: SortInstancesFn,
  hideUnselectedPageGroups: boolean,
): AnnotationLegendItem[] {
  const result: AnnotationLegendItem[] = [];
  const sortedGroups = arrayUtils.sortByField(groups, 0, groups.length - 1, 'orderIndex');
  const groupsMap = DrawingsGroupUtils.getParentToChildMap(sortedGroups);
  const groupRoots = sortedGroups.filter(group => !group.parentId);

  for (const group of groupRoots) {
    const { items, isGroupVisible } = handleGroup(
      filteredGeometryIdsByMeasurementFilter,
      filteredGeometryIdsByGlobalFilterMap,
      group,
      openGroups,
      usedGeometryIdsMap,
      geometry,
      groupsMap,
      0,
      filterFn,
      sortInstances,
      hideUnselectedPageGroups,
    );

    if (isGroupVisible) {
      arrayUtils.extendArray(result, items);
    }
  }
  return result;
}

function getFirstDrawingId(
  group: DrawingsGeometryGroup,
  filteredInstanceIdToDrawingIdMap: Record<string, string>,
  groupMap: Record<string, {
    value: DrawingsGeometryGroup,
    children: string[],
  }>,
  checkIdSet: Set<string>,
): string {
  for (const id of group.measurements) {
    if (checkIdSet.has(id)) {
      continue;
    }
    checkIdSet.add(id);
    if (filteredInstanceIdToDrawingIdMap[id]) {
      return filteredInstanceIdToDrawingIdMap[id];
    }
  }
  for (const id of (groupMap[group.id].children || [])) {
    if (groupMap[id]) {
      return getFirstDrawingId(
        groupMap[id].value,
        filteredInstanceIdToDrawingIdMap,
        groupMap,
        checkIdSet,
      );
    }
  }
  return null;
}

function getDrawingId(
  isGroupItemsOnOnePage: boolean,
  drawingId: string,
  filteredInstanceIdToDrawingIdMap: Record<string, string>,
): string {
  if (!drawingId) {
    return Object.values(filteredInstanceIdToDrawingIdMap)[0];
  }
  if (isGroupItemsOnOnePage) {
    return drawingId;
  }

  return null;
}

interface FilteredGroup {
  items: AnnotationLegendItem[];
  drawingId: string;
  drawingsInstanceCount: number;
  allInstances: AnnotationLegendItem[];
  isGroupVisible: boolean;
}

function handleGroup(
  filteredGeometryIdsByMeasurementFilter: Set<string>,
  filteredGeometryIdsByGlobalFilterMap: Record<string, string>,
  group: DrawingsGeometryGroup,
  openGroups: Record<string, boolean>,
  usedGeometryIdsMap: Record<string, boolean>,
  geometry: Record<string, DrawingsGeometryInstance>,
  groupsMap: Record<string, {
    value: DrawingsGeometryGroup,
    children: string[],
  }>,
  nestingCount: number,
  filterFn: (item: DrawingsGeometryGroup) => boolean,
  sortInstances: SortInstancesFn,
  hideUnselectedPageGroups: boolean,
): FilteredGroup {
  const checkIdSet = new Set([group.id]);
  const drawingId = getFirstDrawingId(group, filteredGeometryIdsByGlobalFilterMap, groupsMap, checkIdSet);
  let isGroupItemsOnOnePage = !!drawingId;
  let filteredMeasurements: DrawingsGeometryInstanceWithIdAndGroupId[] = [];
  const partiallyFilteredMeasurementIds = [];
  const showItems = [];
  let innerGroupShowItems: AnnotationLegendItem[] = [];
  let allDrawingsInstanceCount = 0;
  let allGroupInstancesCount = 0;
  let filteredInnerGroups = [];
  const groupChildren = groupsMap[group.id].children || [];

  groupChildren.forEach(groupId => {
    const {
      items: showInnerGroupItems,
      drawingId: innerGroupDrawingId,
      drawingsInstanceCount,
      allInstances: allInnerGroupInstances,
      isGroupVisible: isInnerGroupVisible,
    } = handleGroup(
      filteredGeometryIdsByMeasurementFilter,
      filteredGeometryIdsByGlobalFilterMap,
      groupsMap[groupId].value,
      openGroups,
      usedGeometryIdsMap,
      geometry,
      groupsMap,
      nestingCount + 1,
      filterFn,
      sortInstances,
      hideUnselectedPageGroups,
    );
    allDrawingsInstanceCount += drawingsInstanceCount;
    if (showInnerGroupItems.length) {
      allGroupInstancesCount += (allInnerGroupInstances.length + 1);

      if (isInnerGroupVisible) {
        innerGroupShowItems = innerGroupShowItems.concat(showInnerGroupItems);
        filteredInnerGroups.push(showInnerGroupItems[0]);
        filteredInnerGroups = filteredInnerGroups.concat(allInnerGroupInstances);
      }
      if (drawingId !== innerGroupDrawingId) {
        isGroupItemsOnOnePage = false;
      }
    }
  });

  group.measurements.forEach(instanceId => {
    usedGeometryIdsMap[instanceId] = true;
    const instanceDrawingId = filteredGeometryIdsByGlobalFilterMap[instanceId];
    if (instanceDrawingId) {
      filteredMeasurements.push({
        ...geometry[instanceId],
        id: instanceId,
        groupId: group.id,
        nestingCount: nestingCount + 1,
      });
      if (instanceDrawingId !== drawingId) {
        isGroupItemsOnOnePage = false;
      }
    }

    if (filteredGeometryIdsByMeasurementFilter.has(instanceId)) {
      partiallyFilteredMeasurementIds.push(instanceId);
    }
  });

  if (hideUnselectedPageGroups) {
    const hasFilteredInnerInstances = !!partiallyFilteredMeasurementIds.length || !!allGroupInstancesCount;
    const hasOriginalInnerInstances = !!group.measurements.length || !!groupChildren.length;
    if (!hasFilteredInnerInstances && hasOriginalInnerInstances) {
      return {
        items: [],
        drawingId: undefined,
        drawingsInstanceCount: 0,
        allInstances: [],
        isGroupVisible: false,
      };
    }
  }

  const groupDrawingsForCount: number = filteredMeasurements.length + allDrawingsInstanceCount;
  filteredMeasurements = sortInstances(filteredMeasurements);
  const filteredItems = filteredInnerGroups.concat(filteredMeasurements);

  showItems.push({
    ...group,
    nestingCount,
    drawingsCount: groupDrawingsForCount,
    allInnerInstances: filteredItems,
    groupId: group.parentId,
  });


  if (openGroups[group.id]) {
    arrayUtils.extendArray(showItems, innerGroupShowItems);
    arrayUtils.extendArray(showItems, filteredMeasurements);
  }

  return {
    items: showItems,
    drawingId: getDrawingId(isGroupItemsOnOnePage, drawingId, filteredGeometryIdsByGlobalFilterMap),
    drawingsInstanceCount: groupDrawingsForCount,
    allInstances: filteredItems,
    isGroupVisible: !!filteredItems.length || filterFn(group),
  };
}

const shapeValuesToInstanceTypes: Record<ShapesFilterValue, DrawingsInstanceType[]> = {
  [ShapesFilterValue.Lines]: [DrawingsInstanceType.Line, DrawingsInstanceType.Polyline],
  [ShapesFilterValue.Points]: [DrawingsInstanceType.Count],
  [ShapesFilterValue.Polygons]: [DrawingsInstanceType.Polygon, DrawingsInstanceType.Rectangle],
};

function getAllowedInstancesTypes(
  shapesFilters: Record<ShapesFilterValue, boolean>,
): Set<DrawingsInstanceType> {
  const result = [];
  for (const [filter, value] of Object.entries(shapesFilters)) {
    if (value) {
      result.push(...shapeValuesToInstanceTypes[filter]);
    }
  }
  return new Set(result);
}

function filterGeometryInstances(
  geometry: Record<string, DrawingsGeometryInstance>,
  filterData: FilterData,
  filterFunction: (item: DrawingsGeometryInstance, allowedInstanceTypes: Set<DrawingsInstanceType>) => boolean,
  globalFilterFn: (item: DrawingsGeometryInstance) => boolean,
): {
  filteredGeometryIdsByGlobalFilterMap: Record<string, string>,
  filteredGeometryIdsByMeasurementFilter: Set<string>,
} {
  const filteredGeometryIdsByGlobalFilterMap: Record<string, string> = {};
  const filteredGeometryIdsByMeasurementFilter = new Set<string>();
  const allowedInstanceTypes = getAllowedInstancesTypes(filterData[AnnotationFilters.Shapes]);
  for (const [id, item] of Object.entries(geometry)) {
    if (filterFunction({ ...item, id }, allowedInstanceTypes)) {
      filteredGeometryIdsByMeasurementFilter.add(id);
      if (globalFilterFn(item)) {
        filteredGeometryIdsByGlobalFilterMap[id] = item.drawingId;
      }
    }
  }

  return {
    filteredGeometryIdsByGlobalFilterMap,
    filteredGeometryIdsByMeasurementFilter,
  };
}


function getFilterResult(
  groups: DrawingsGeometryGroup[],
  openGroups: Record<string, boolean>,
  geometry: Record<string, DrawingsGeometryInstance>,
  filterFunction: (item: DrawingsGeometryInstance, allowedInstanceTypes: Set<DrawingsInstanceType>) => boolean,
  globalFilterFn: (item: DrawingsGeometryInstance | DrawingsGeometryGroup) => boolean,
  sortInstances: SortInstancesFn,
  hideUnselectedPageGroups: boolean,
  filterData: FilterData,
): AnnotationLegendTree {
  const {
    filteredGeometryIdsByGlobalFilterMap,
    filteredGeometryIdsByMeasurementFilter,
  } = filterGeometryInstances(
    geometry,
    filterData,
    filterFunction,
    globalFilterFn,
  );

  const usedGeometryIdsMap: Record<string, boolean> = {};
  let filteredItems = addGroups(
    groups,
    openGroups,
    geometry,
    usedGeometryIdsMap,
    filteredGeometryIdsByMeasurementFilter,
    filteredGeometryIdsByGlobalFilterMap,
    globalFilterFn,
    sortInstances,
    hideUnselectedPageGroups,
  );

  filteredItems = addRootMeasures(
    filteredItems,
    filteredGeometryIdsByMeasurementFilter,
    filteredGeometryIdsByGlobalFilterMap,
    geometry,
    usedGeometryIdsMap,
    sortInstances,
  );

  return  {
    geometryCount: 0,
    items: filteredItems,
  };
}

function getDrawingsInstanceIds(items: AnnotationLegendItem[]): string[] {
  const drawingIds = [];

  items.forEach(instance => {
    if (AnnotationLegendItemTypeguards.isGroup(instance)) {
      instance.allInnerInstances.forEach(groupInstance => {
        if (AnnotationLegendItemTypeguards.isGeometry(groupInstance)) {
          drawingIds.push(groupInstance.id);
        } else if (AnnotationLegendItemTypeguards.isPivoted(groupInstance)) {
          arrayUtils.extendArray(drawingIds, groupInstance.groupedGeometries);
        }
      });
    } else {
      if (AnnotationLegendItemTypeguards.isGeometry(instance)) {
        drawingIds.push(instance.id);
      } else if (AnnotationLegendItemTypeguards.isPivoted(instance)) {
        arrayUtils.extendArray(drawingIds, instance.groupedGeometries);
      }
    }
  });
  return arrayUtils.uniq(drawingIds);
}

function getMaxOrderIndex(items: AnnotationLegendItem[], parentId: string): number {
  const childrenGroups = arrayUtils.filterMap(
    items,
    item => AnnotationLegendItemTypeguards.isGroup(item) && item.parentId === parentId,
    group => (group as DrawingsGeometryGroup).orderIndex,
  );
  return Math.max(...childrenGroups, 0);
}

function isUserValid(instance: DrawingsGeometryInstance, userFilter: string): boolean {
  return !userFilter || instance.creator === userFilter;
}

function isAssignValid(
  instanceId: string,
  instance: DrawingsGeometryInstance,
  assignPia: Record<string, AssignedPia>,
  groupsMap: Record<string, DrawingsGeometryGroup>,
  assignFilter: AssignFilterValue,
): boolean {
  if (!assignFilter || assignFilter === AssignFilterValue.All) {
    return true;
  }

  const hasAssignedPia = DrawingsAssign.hasAssignedPia(instanceId, assignPia);
  const hasInheritedPia = !hasAssignedPia
    && DrawingsAssign.hasInheritedPiaSimple(instance.groupId, assignPia, groupsMap);
  if (assignFilter === AssignFilterValue.Assign) {
    return hasAssignedPia || hasInheritedPia;
  } else {
    return !hasAssignedPia && !hasInheritedPia;
  }
}

function isColorValid(instance: DrawingsGeometryInstance, color: string): boolean {
  return !color || instance.geometry.color === color;
}

function isValidByOrigin(instance: DrawingsGeometryInstance, originFilter: OriginFilterValue): boolean {
  switch (originFilter) {
    case OriginFilterValue.All:
      return true;
    case OriginFilterValue.AutoMeasure:
      return instance.isAuto;
    case OriginFilterValue.Manual:
      return !instance.isAuto;
    default:
      return false;
  }
}

function isInstanceUsed(
  instanceId: string,
  parentId: string,
  dynamicGroupsToCell: Record<string, string[]>,
  groups: DrawingsGeometryGroup[],
  instanceToCells: Record<string, string[]>,
  selectedSheetId: string,
  filteredNodeIds: Record<string, boolean>,
): boolean {
  const cells = instanceToCells[instanceId];
  if (!cells) {
    if (Object.keys(dynamicGroupsToCell).length) {
      const groupsMap = DrawingsGroupUtils.getParentToChildMap(groups);
      while (parentId && !(parentId in dynamicGroupsToCell && dynamicGroupsToCell[parentId].length)) {
        parentId = groupsMap[parentId].value.parentId;
      }
      return !!parentId;
    }
    return false;
  }

  return cells.some(cell => {
    const { sheetId, rowId } = TwoDRegex.fullCellId.exec(cell).groups;
    const isSelectedSheet = sheetId === selectedSheetId;

    return isSelectedSheet && (filteredNodeIds === null || filteredNodeIds[rowId]);
  });
}

function isInstanceParentGroupUsing(
  parentId: string,
  groups: DrawingsGeometryGroup[],
  instanceToCells: Record<string, string[]>,
): boolean {
  if (parentId && instanceToCells[parentId]?.length) {
    return true;
  }
  const groupsMap = DrawingsGroupUtils.getParentToChildMap(groups);
  while (groupsMap[parentId] && groupsMap[parentId].value.parentId) {
    parentId = groupsMap[parentId].value.parentId;
    if (instanceToCells[parentId]?.length) {
      return true;
    }
  }

  return false;
}

function isValidReportUsing(
  instanceId: string,
  parentId: string,
  filterData: FilterData,
  isShowTwoDReport: boolean,
  viewMeasureId: Record<string, boolean>,
  dynamicGroupsToCell: Record<string, string[]>,
  groups: DrawingsGeometryGroup[],
  drawingInstanceInCellRange: Record<string, boolean>,
  selectedMeasureIdFromView: Record<string, boolean>,
  instanceToCells: Record<string, string[]>,
  selectedSheetId: string,
  filteredNodeIds: Record<string, boolean>,
): boolean {
  const reportFilter = filterData[AnnotationFilters.Report];
  if (!reportFilter) {
    return true;
  }

  if (reportFilter === ScheduleFilterValue.CellRange) {
    return isShowTwoDReport
      ? drawingInstanceInCellRange[instanceId]
      : selectedMeasureIdFromView[instanceId];
  }

  const isInstanceUsedReport = isInstanceUsed(
    instanceId,
    parentId,
    dynamicGroupsToCell,
    groups,
    instanceToCells,
    selectedSheetId,
    filteredNodeIds,
  );
  const isUsedReport = isInstanceUsedReport || isInstanceParentGroupUsing(parentId, groups, instanceToCells);
  const isInstanceUsedView = viewMeasureId[instanceId];

  if (reportFilter === ScheduleFilterValue.Unscheduled) {
    return isShowTwoDReport
      ? !isUsedReport
      : !isInstanceUsedView;
  }

  if (reportFilter === ScheduleFilterValue.Scheduled) {
    return isShowTwoDReport
      ? isUsedReport
      : isInstanceUsedView;
  }

  return false;
}

function isValidName(instanceName: string, target: string): boolean {
  return instanceName.toLocaleLowerCase().includes(target.toLocaleLowerCase());
}

export const DrawingAnnotationFiltrationUtils = {
  isUserValid,
  isAssignValid,
  isColorValid,
  isValidReportUsing,
  isValidName,
  isValidByOrigin,
  getFilterResult,
  getDrawingsInstanceIds,
  getMaxOrderIndex,
  getAllowedInstancesTypes,
};
