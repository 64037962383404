import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';

import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialog } from 'common/UIKit';
import { ResourceType } from '../../enums';
import { ActivityResourceVariant, UnitModel } from '../../interfaces/data';
import { LaborVariantModel } from '../../interfaces/resources-data';
import { ResourceSelector } from '../resource-selector';

export const RESOURCE_SELECTOR_DIALOG_LABOUR = 'resourceSelectorLabour';

interface ReduxProps {
  databaseId: number;
  isDialogOpen: boolean;
  unitMap: Record<number, UnitModel>;
}

interface Props extends ReduxProps {
  selectedLabors: Array<ActivityResourceVariant<LaborVariantModel>>;
  onCancel: () => void;
  onSave: (materials: Array<ActivityResourceVariant<LaborVariantModel>>) => void;
}

class LaborSelectorComponent extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <KreoDialog
        name={RESOURCE_SELECTOR_DIALOG_LABOUR}
        title='Select Labours'
        onClose={this.props.onCancel}
      >
          <ResourceSelector
            databaseId={this.props.databaseId}
            name='Labour'
            type={ResourceType.Labour}
            selectedResources={this.props.selectedLabors.map(x => ({ ...x, id: x.resourceVariant.id }))}
            onClose={this.props.onCancel}
            onSave={this.onSave}
            isOpen={this.props.isDialogOpen}
            isMultiselect={true}
            unitMap={this.props.unitMap}
          />
      </KreoDialog>
    );
  }

  @autobind
  private onSave(resources: any[]): void {
    const result = resources.map(resource => {
      const previouslySavedLabor = this.props.selectedLabors.find(x => x.resourceVariant.id === resource.id);
      if (previouslySavedLabor) {
        return previouslySavedLabor;
      } else {
        return this.getLaborFromResource(resource);
      }
    });
    this.props.onSave(result);
    this.props.onCancel();
  }

  private getLaborFromResource(resource: any): ActivityResourceVariant<LaborVariantModel> {
    const resourceVariant = {
      id: resource.id,
      cost: resource.cost,
      name: resource.name,
      resourceName: resource.resourceName,
    };

    return {
      id: 0,
      amount: 1,
      resourceVariant: resourceVariant as LaborVariantModel,
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    databaseId: state.database.currentDatabase.database.id,
    isDialogOpen: RESOURCE_SELECTOR_DIALOG_LABOUR in state.dialog,
    unitMap: state.database.currentDatabase.unitMap,
  };
};

export const LaborSelector = connect(mapStateToProps)(LaborSelectorComponent);
