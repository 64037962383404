import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { DrawingsPolygonConverterResult } from 'common/components/drawings/interfaces/drawings-converter-result';
import { DrawingsAllowedPathType } from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import {
  DrawingsGeometryConverters,
  GeometryConfig,
} from 'common/components/drawings/utils/drawings-geometry-converters';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { BaseOffsetEntity } from './base-offset-entity';
import { DrawingsOffsetUtils } from './offset-utils';

export class PolylineOffsetEntity extends BaseOffsetEntity {
  protected _offsetPath: DrawingsAllowedPathType;

  public updateOffset(offset: number, stroke: boolean): boolean {
    const basePathClone = this._config.basePath.clone() as paper.Path;
    const path = DrawingsOffsetUtils.offsetSimpleStroke(
      basePathClone,
      offset,
      Math.abs(offset),
      stroke,
    );
    basePathClone.remove();
    if (DrawingsPaperUtils.isPolygonSelfIntersected(path) || !this.validateOffsetPath(path)) {
      return false;
    }
    this.saveOffsetPath(path);
    this._offset = offset;
    return true;
  }

  public convertToGeometry(): DrawingsPolygonConverterResult {
    const { color: { stroke: color }, strokeWidth, strokeStyle } = this._config;
    const config: GeometryConfig = {
      color: color.toCSS(true),
      strokeWidth,
      strokeStyle,
    };

    if (this._config.height) {
      config.height = this._config.height;
    }
    if (this._config.thickness) {
      config.thickness = this._config.thickness;
    }
    return DrawingsGeometryConverters.convertPathToPolygons(
      this._offsetPath,
      {},
      config,
    );
  }

  public getOffsetPath(): DrawingsAllowedPathType {
    return this._offsetPath;
  }

  public findEdgeOfPoint(pointInPath: paper.Point): [paper.Point, paper.Point] {
    return DrawingsOffsetUtils.findEdgeOfPoint(this._config.basePath, pointInPath);
  }

  public updateOffsetRenderParameters({ zoom }: DrawingsRenderParams): void {
    if (this._offsetPath) {
      this._offsetPath.strokeWidth = this._config.strokeWidth / zoom;
      this._offsetPath.dashArray = DrawingsCanvasUtils.scaleStroke(this._config, zoom);
    }
  }

  public removeOffsetPath(): void {
    if (this._offsetPath) {
      this._offsetPath.remove();
    }
  }

  private saveOffsetPath(path: DrawingsAllowedPathType): void {
    if (this._offsetPath) {
      this._offsetPath.remove();
      this._offsetPath = null;
    }
    this._offsetPath = path;
    this.renderPath(path);
  }
}
