import * as Ag from 'ag-grid-community';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';

import { TableSettings } from '../../../../units/2d/interfaces';
import { getConfigId } from '../../../../units/2d/units/excel-table-cell-formatter/common';
import { INDEX_COLUMN_KEY } from '../excel-table';
import { ExcelTableRowIdentification } from '../excel-table-row-identificator';
import { UpdateCellData } from '../interfaces';

export interface CSVFormatData {
  fromCell: string;
  value: string | number;
  data: string | number;
  settings: TableSettings;
}

const getRangeConfig = (cellRange: Ag.CellRange): [number, number] => {
  const isStartRowInLeft = cellRange.startRow.rowIndex < cellRange.endRow.rowIndex;
  return isStartRowInLeft
    ? [cellRange.startRow.rowIndex, cellRange.endRow.rowIndex]
    : [cellRange.endRow.rowIndex, cellRange.startRow.rowIndex];
};

const clearRange = (api: Ag.GridApi): UpdateCellData[] => {
  const cellRanges = api.getCellRanges();
  const updatedCells = [];
  cellRanges.forEach((cellRange) => {
    const [fromIndex, toIndex] = getRangeConfig(cellRange);
    for (let i = fromIndex; i <= toIndex; i++) {
      const node = api.getRowNode((i + 1).toString());
      cellRange.columns.forEach(c => {
        const columnId = c.getColId();
        if (columnId === INDEX_COLUMN_KEY) {
          return;
        }
        const prevValue = node.data[columnId];
        updatedCells.push({ rowId: node.id, columnId, value: '', prevValue });
        const configColumnId = getConfigId(columnId);
        if (configColumnId in node.data) {
          updatedCells.push({
            rowId: node.id,
            columnId: configColumnId,
            value: null,
            prevValue: node.data[configColumnId],
          });
        }
      });
    }
  });
  return updatedCells;
};

const getCSVFromSelectRange = (
  api: Ag.GridApi,
  format: (columnId: string, rowId: string, value: string) => string,
): string => {
  const cellRanges = api.getCellRanges();

  let result = '';

  cellRanges.forEach((cellRange) => {
    const [fromIndex, toIndex] = getRangeConfig(cellRange);
    for (let i = fromIndex; i <= toIndex; i++) {
      if (i !== fromIndex) {
        result += '\n';
      }
      const rowId = ExcelTableRowIdentification.getRowIdFromIndex(i);
      const node = api.getRowNode(rowId);
      cellRange.columns.forEach((c, x) => {
        const columnId = c.getColId();
        const value = node.data[columnId] !== undefined
          ? node.data[columnId]
          : '';
        if (x !== 0) {
          result += '\t';
        }
        result += format(columnId, rowId, value);
      });
    }
  });

  return result;
};

const getCellsValueFromCSV = (text: string): string[][] => {
  const rows = text.split('\n');

  return rows.map(row => {
    return row.split('\t');
  });
};

const getCSVFromArray = (rows: string[][]): string => {
  return rows.map(row => row.join('\t')).join('\n');
};

const getSelectRowsCount = (cellRange: Ag.CellRange): number => {
  const maxRowIndex = Math.max(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);
  const minRowIndex = Math.min(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);
  return maxRowIndex - minRowIndex;
};

const getMinRowIndex = (cellRange: Ag.CellRange): number => {
  const minRowIndex = Math.min(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);
  return minRowIndex;
};

const getSelectColumnsCount = (cellRange: Ag.CellRange): number => {
  return cellRange.columns.length;
};

const getMinColumnId = (cellRange: Ag.CellRange): number => {
  return cellRange.columns.reduce(
    (acc, item) => acc > ExcelColumnHelper.excelColNameToIndex(item.getColId())
      ? ExcelColumnHelper.excelColNameToIndex(item.getColId())
      : acc,
    ExcelColumnHelper.excelColNameToIndex(cellRange.startColumn.getColId()));
};

export const RangeHelper = {
  clearRange,
  getCSVFromSelectRange,
  getCellsValueFromCSV,
  getCSVFromArray,
  getSelectRowsCount,
  getSelectColumnsCount,
  getMinRowIndex,
  getMinColumnId,
};
