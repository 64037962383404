import { RequestStatus } from 'common/enums/request-status';
import { FinishLayer } from '../../project-dashbord';
import { ClassificationAssignmentStage } from '../enums/classification-assignment-stage';
import { ClassificationReduxState } from '../interfaces/classification/classification-redux-state';
import { CurrentElementClassification } from '../interfaces/classification/current-element-classification';
import { ClassificationModelBrowserFilters } from '../interfaces/classification/model-browser-filters';


export const CLASSIFICATION_MODEL_BROWSER_ISOLATION_STATUS_DEFAULT: ClassificationModelBrowserFilters = {
  searchQuery: '',
  tab: -1,
  breadCrumbs: null,
  unclassifiedFilterEnabled: false,
  isolationEnabled: false,
};

export const CLASSIFICATION_INITIAL_STATE: ClassificationReduxState = {
  editedClassificationGroup: 0,
  productsShortInfo: {},
  systemsShortInfo: {},
  modelBrowserFilters: CLASSIFICATION_MODEL_BROWSER_ISOLATION_STATUS_DEFAULT,
  cachedIsolationStatus: CLASSIFICATION_MODEL_BROWSER_ISOLATION_STATUS_DEFAULT,
  isSearchModeEnabled: false,
  tabs: [],
  bimElements: [],
  classificationSelectedIndex: 0,
  isSystemTree: true,
  classificationTarget: null,
  openProps: true,
  statistic: [],
  loadedStatistic: false,
  errorIds: [],
  selectedPath: null,
  treeCenterElement: null,
  hasChanges: false,
  statisticLoaded: false,
  elementOntology: {},
  uniquePropertyValues: [],
  selectedIds: [],
  parameterGroupDictionary: [],
  modelTree: [],
  layerInfo: new Array<FinishLayer[]>(),
  modelBrowserFiltered: [],
  elementOntologyGraph: [],
  itemClassification: [],
  tempUniclass: null,
  filterValue: '',
  systems: [],
  products: [],
  changeAssemblyRoot: false,
  assignmentStage: ClassificationAssignmentStage.PredictedInfo,
  elementClassification: new Array<CurrentElementClassification[]>(),
  isUniclass: false,
  editableLayer: 0,
  isSaveButtonEnabled: false,
  errorCategoriesCount: 0,
  elementClassificationLoadingStatus: RequestStatus.NotRequested,
  elementTypeComparisonPattern: null,
  graphsByKeys: null,
  copyPastRequestState: RequestStatus.NotRequested,
  selectedElementTypesCount: 0,
  allElementTypesCount: 0,
  filteredElementTypesCount: 0,
  classificationNotPasted: [],
  classificationPasted: [],
  currentSelectedElementTypesGroups: [],
  selectedClassificationGroup: -1,
};
