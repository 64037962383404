import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { Feed } from 'common/interfaces/feed';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { CurrencyModel } from '../../account/interfaces/currency-model';
import { AdminCompaniesActions } from '../actions/creators/companies';
import {
  AttachDatabasesParams,
  AttachEmployeeParams,
  AttachEmployeeToProjectParams,
  CompanyCurrencyPayload,
  CompanyIdAndSubscriptionTypePayload,
  CompanyMockSubscriptionPayload,
  CompanyPreferImperialPayload,
  DatabaseFeedParams,
  DetachDatabasesParams,
  LoadRolesParams,
} from '../actions/payloads/companies';
import { AdminCompaniesActionTypes } from '../actions/types/companies';
import { AdminCompaniesApi } from '../api/companies';
import { AdminCompanyVm } from '../interfaces/admin-company-vm';
import { AdminDatabaseVm } from '../interfaces/admin-database-vm';
import { CompanyMockSubscriptionSaveModel } from '../interfaces/company-mock-subscription-save-model';
import { FeedParams } from '../interfaces/feed';
import { ShortRole } from '../interfaces/short-role';
import { ShortUser, ShortUserWithRole } from '../interfaces/short-user';
import { SubscriptionPlanOption } from '../interfaces/subscription-plan-option';

function* loadFeed(
  action: ActionWith<FeedParams>,
): SagaIterator {
  try {
    const data: Feed<AdminCompanyVm> = yield call(AdminCompaniesApi.getCompaniesFeed, action.payload);
    yield put(AdminCompaniesActions.loadCompaniesSucceed(data));
  } catch (error) {
    console.error('admin companies: load feed failed', error, action.payload);
  }
}

function* deleteProjects(
  action: ActionWith<number>,
): SagaIterator {
  try {
    yield call(AdminCompaniesApi.deleteProjects, action.payload);
    yield put(AdminCompaniesActions.deleteProjectsSucceed(action.payload));
  } catch (error) {
    console.error('admin companies: delete projects failed', error, action.payload);
  }
}

function* loadDatabasesFeed(
  action: ActionWith<DatabaseFeedParams>,
): SagaIterator {
  try {
    const data: Feed<AdminDatabaseVm> = yield call(AdminCompaniesApi.getDatabasesFeed, action.payload);
    yield put(AdminCompaniesActions.loadDatabasesSucceed(data));
  } catch (error) {
    console.error('admin companies: load databases feed failed', error, action.payload);
  }
}

function* attachDatabases(
  action: ActionWith<AttachDatabasesParams>,
): SagaIterator {
  try {
    yield call(AdminCompaniesApi.attachDatabasesToCompany, action.payload);
    yield put(AdminCompaniesActions.attachDatabasesSucceed(action.payload));
  } catch (error) {
    console.error('admin companies: attach databases failed', error, action.payload);
  }
}

function* detachDatabase(
  action: ActionWith<DetachDatabasesParams>,
): SagaIterator {
  try {
    yield call(AdminCompaniesApi.detachDatabaseFromCompany, action.payload);
    yield put(AdminCompaniesActions.detachDatabaseSucceed(action.payload));
  } catch (error) {
    console.error('admin companies: detach database failed', error, action.payload);
  }
}

function* loadEmployeesFeed(
  action: ActionWith<FeedParams>,
): SagaIterator {
  try {
    const data: Feed<ShortUser> = yield call(AdminCompaniesApi.getEmployeesFeed, action.payload);
    yield put(AdminCompaniesActions.loadEmployeesSucceed(data));
  } catch (error) {
    console.error('admin companies: load employees feed failed', error, action.payload);
  }
}

function* attachEmployee(
  { payload }: ActionWith<AttachEmployeeParams>,
): SagaIterator {
  try {
    const { employeeGuid: userId, roleId, companyId, subscriptionType } = payload;
    yield call(AdminCompaniesApi.attachEmployeeToCompany, companyId, subscriptionType, userId, roleId);

    const employee: ShortUserWithRole = yield selectWrapper(
      (state) => {
        const empl = state.admin.companies.employeeFeed.data.find((e) => e.guid === userId);
        if (empl) {
          const role = state.admin.companies.selectedCompanyRoles.find((r) => r.id === roleId);
          return {
            ...empl,
            roleName: role ? role.name : '',
            roleCode: role ? role.code : null,
          };
        }
        return empl;
      },
    );

    yield put(AdminCompaniesActions.attachEmployeeSucceed({
      companyId,
      employee,
      subscriptionType,
    }));
  } catch (error) {
    console.error('admin companies: attach employee failed', error, payload);
  }
}

function* detachEmployee(
  { payload }: ActionWith<AttachEmployeeParams>,
): SagaIterator {
  try {
    const { companyId, employeeGuid: userId, subscriptionType } = payload;
    yield call(AdminCompaniesApi.detachEmployeeFromCompany, companyId, subscriptionType, userId);
    yield put(AdminCompaniesActions.detachEmployeeSucceed(payload));
  } catch (error) {
    console.error('admin companies: detach employee failed', error, payload);
  }
}

function* loadRoles(
  action: ActionWith<LoadRolesParams>,
): SagaIterator {
  try {
    const { companyId, subscriptionType } = action.payload;
    const data: ShortRole[] = yield call(AdminCompaniesApi.getRoles, companyId, subscriptionType);
    yield put(AdminCompaniesActions.loadRolesSucceed(data));
  } catch (error) {
    console.error('admin companies: load roles failed', error, action.payload);
  }
}

function* changeRole(
  action: ActionWith<AttachEmployeeParams>,
): SagaIterator {
  try {
    const { employeeGuid, companyId, roleId, subscriptionType } = action.payload;
    const data: boolean = yield call(
      AdminCompaniesApi.updateUserRole, companyId, subscriptionType, employeeGuid, roleId,
    );
    if (data) {
      const employee: ShortUserWithRole = yield selectWrapper(
        (state) => {
          const empl = state.admin.companies.employeeFeed.data.find((e) => e.guid === employeeGuid);
          if (empl) {
            const role = state.admin.companies.selectedCompanyRoles.find((r) => r.id === roleId);
            return {
              ...empl,
              roleName: role ? role.name : '',
              roleCode: role ? role.code : null,
            };
          }
          return empl;
        },
      );

      yield put(AdminCompaniesActions.changeRoleSucceed({
        companyId: action.payload.companyId,
        employee,
        subscriptionType,
      }));
    }
  } catch (error) {
    console.error('admin companies: change role failed', error, action.payload);
  }
}

function* loadEmployees(
  action: ActionWith<number>,
): SagaIterator {
  try {
    const data: ShortUserWithRole[] = yield call(AdminCompaniesApi.getProjectUsers, action.payload);
    yield put(AdminCompaniesActions.loadProjectsEmployeesSucceed(data));
  } catch (error) {
    console.error('admin companies: load employees failed', error, action.payload);
  }
}

function* attachEmployeeToProject(
  { payload }: ActionWith<AttachEmployeeToProjectParams>,
): SagaIterator {
  try {
    const { employeeGuid: userId, projectId } = payload;
    yield call(AdminCompaniesApi.attachEmployeeToProject, projectId, userId);
    yield put(AdminCompaniesActions.attachEmployeeToProjectSucceed(payload));
  } catch (error) {
    console.error('admin companies: attach employee to project failed', error, payload);
  }
}

function* detachEmployeeFromProject(
  { payload }: ActionWith<AttachEmployeeToProjectParams>,
): SagaIterator {
  try {
    const { employeeGuid: userId, projectId } = payload;
    yield call(AdminCompaniesApi.detachEmployeeFromProject, projectId, userId);
    yield put(AdminCompaniesActions.detachEmployeeFromProjectSucceed(payload));
  } catch (error) {
    console.error('admin companies: detach employee from project failed', error, payload);
  }
}

function* getSubscriptionPlans(): SagaIterator {
  try {
    const planOptions: SubscriptionPlanOption[] = yield call(AdminCompaniesApi.getSubscriptionPlans);
    yield put(AdminCompaniesActions.getSubscriptionPlansSucceeded(planOptions));
  } catch (error) {
    console.error('admin companies: get subscription plans failed', error);
    yield put(AdminCompaniesActions.getSubscriptionPlansFailed());
  }
}

function* setMockSubscription(
  { payload }: ActionWith<CompanyMockSubscriptionPayload>,
): SagaIterator {
  try {
    const { companyId, planId, planQuantity, subscriptionType } = payload;
    const mockSubscriptionToSave: CompanyMockSubscriptionSaveModel = { planId, planQuantity };
    yield call(AdminCompaniesApi.setMockSubscription, companyId, subscriptionType, mockSubscriptionToSave);
    yield put(AdminCompaniesActions.setMockSubscriptionSucceeded(companyId, planId, subscriptionType, planQuantity));
  } catch (error) {
    console.error('admin companies: set mock subscription failed', error, payload);
    yield put(AdminCompaniesActions.setMockSubscriptionFailed(payload && payload.companyId));
  }
}

function* resetMockSubscription({ payload }: ActionWith<CompanyIdAndSubscriptionTypePayload>): SagaIterator {
  const { companyId, subscriptionType } = payload;
  try {
    yield call(AdminCompaniesApi.deleteMockSubscription, companyId, subscriptionType);
    yield put(AdminCompaniesActions.resetMockSubscriptionSucceeded(companyId, subscriptionType));
  } catch (error) {
    console.error('admin companies: reset mock subscription failed', error, companyId);
    yield put(AdminCompaniesActions.resetMockSubscriptionFailed(companyId));
  }
}

function* createEmptySubscription({ payload }: ActionWith<CompanyIdAndSubscriptionTypePayload>): SagaIterator {
  const { companyId, subscriptionType } = payload;
  try {
    yield call(AdminCompaniesApi.createEmptySubscription, companyId, subscriptionType);
    yield put(AdminCompaniesActions.createEmptySubscriptionSucceeded(companyId, subscriptionType));
  } catch (error) {
    console.error('admin companies: reset mock subscription failed', error, companyId);
    yield put(AdminCompaniesActions.createEmptySubscriptionFailed(companyId));
  }
}

function* loadCurrencies(): SagaIterator {
  try {
    const currencies: CurrencyModel[] = yield call(AdminCompaniesApi.getCurrencies);
    yield put(AdminCompaniesActions.loadCurrenciesSucceeded(currencies));
  } catch (error) {
    console.error('admin companies: load currencies failed', error);
    yield put(AdminCompaniesActions.loadCurrenciesFailed());
  }
}

function* updateCurrency({ payload }: ActionWith<CompanyCurrencyPayload>): SagaIterator {
  try {
    const { companyId, currencyCode } = payload;
    yield call(AdminCompaniesApi.updateCompanyCurrency, companyId, currencyCode);
    yield put(AdminCompaniesActions.setCurrencySucceeded(companyId, currencyCode));
  } catch (error) {
    console.error('admin companies: update currency failed', error, payload);
    yield put(AdminCompaniesActions.setCurrencyFailed(payload && payload.companyId));
  }
}

function* updatePreferImperial({ payload }: ActionWith<CompanyPreferImperialPayload>): SagaIterator {
  try {
    const { companyId, preferImperial } = payload;
    yield call(AdminCompaniesApi.updatePreferImperial, companyId, preferImperial);
    yield put(AdminCompaniesActions.setPreferImperialSucceeded(companyId, preferImperial));
  } catch (error) {
    console.error('admin companies: update prefer imperial', error, payload);
    yield put(AdminCompaniesActions.setPreferImperialFailed(payload && payload.companyId));
  }
}

function* deleteCompany({ payload }: ActionWith<number>): SagaIterator {
  try {
    yield call(AdminCompaniesApi.deleteCompany, payload);
    yield put(AdminCompaniesActions.deleteCompanySucceeded(payload));
  } catch (error) {
    console.error('admin companies: delete company failed', error, payload);
  }
}

export function* adminCompaniesSagas(): SagaIterator {
  yield takeLatest(AdminCompaniesActionTypes.LOAD_REQUEST, loadFeed);
  yield takeLatest(AdminCompaniesActionTypes.DELETE_PROJECTS_REQUEST, deleteProjects);
  yield takeLatest(AdminCompaniesActionTypes.LOAD_DATABASES_REQUEST, loadDatabasesFeed);
  yield takeLatest(AdminCompaniesActionTypes.ATTACH_DATABASES_REQUEST, attachDatabases);
  yield takeLatest(AdminCompaniesActionTypes.DETACH_DATABASE_REQUEST, detachDatabase);
  yield takeLatest(AdminCompaniesActionTypes.LOAD_EMPLOYEES_REQUEST, loadEmployeesFeed);
  yield takeLatest(AdminCompaniesActionTypes.ATTACH_EMPLOYEE_REQUEST, attachEmployee);
  yield takeLatest(AdminCompaniesActionTypes.DETACH_EMPLOYEE_REQUEST, detachEmployee);
  yield takeLatest(AdminCompaniesActionTypes.LOAD_ROLES_REQUEST, loadRoles);
  yield takeLatest(AdminCompaniesActionTypes.CHANGE_ROLE_REQUEST, changeRole);
  yield takeLatest(AdminCompaniesActionTypes.LOAD_PROJECTS_EMPLOYEES_REQUEST, loadEmployees);
  yield takeLatest(AdminCompaniesActionTypes.ATTACH_EMPLOYEE_TO_PROJECT_REQUEST, attachEmployeeToProject);
  yield takeLatest(AdminCompaniesActionTypes.DETACH_EMPLOYEE_FROM_PROJECT_REQUEST, detachEmployeeFromProject);
  yield takeLatest(AdminCompaniesActionTypes.GET_SUBSCRIPTION_PLANS_REQUEST, getSubscriptionPlans);
  yield takeLatest(AdminCompaniesActionTypes.SET_MOCK_SUBSCRIPTION_REQUEST, setMockSubscription);
  yield takeLatest(AdminCompaniesActionTypes.RESET_MOCK_SUBSCRIPTION_REQUEST, resetMockSubscription);
  yield takeLatest(AdminCompaniesActionTypes.CREATE_EMPTY_SUBSCRIPTION, createEmptySubscription);
  yield takeLatest(AdminCompaniesActionTypes.LOAD_CURRENCIES_REQUEST, loadCurrencies);
  yield takeLatest(AdminCompaniesActionTypes.SET_CURRENCY_REQUEST, updateCurrency);
  yield takeLatest(AdminCompaniesActionTypes.SET_PREFER_IMPERIAL_REQUEST, updatePreferImperial);
  yield takeLatest(AdminCompaniesActionTypes.DELETE_COMPANY, deleteCompany);
}
