import styled from 'styled-components';

const DragAndDropField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  svg {
    height: 20px;
    fill: ${p => p.theme.color.turquoise};
  }
`;

export const Styled = {
  DragAndDropField,
};
