import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Mood } from 'common/enums/mood';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { AppUrls } from 'routes/app-urls';
import { Styled } from './styled';

export const DECREMENT_INVITED_EMAILS_DIALOG = 'DECREMENT_INVITED_EMAILS_DIALOG';
const description = `You’ve invited more users than the team size you are trying to set is.
  Remove users or press “Cancel” to continue with the current team size.`;


export const DecrementInvitedEmailsDialog: React.FC = () => {
  const dispatch = useDispatch();

  const onConfirm = useCallback(() => {
    dispatch(push(AppUrls.qto2d.invitingPeople.path));
  }, [dispatch]);

  return (
    <ConfirmationDialog
      name={DECREMENT_INVITED_EMAILS_DIALOG}
      title={'Not enough members'}
      text={description}
      cancelButtonText={'Cancel'}
      confirmButtonText={'Remove users'}
      cancelButtonMood={Mood.Positive}
      submitButtonMood={Mood.Default}
      onConfirm={onConfirm}
      ExtraStyled={Styled.Container}
      className={'decrement-invited-emails-dialog'}
    />
  );
};

