import posthog from 'posthog-js';

let isInit = false;

export const init = (key: string): void => {
  posthog.init(key, {
    api_host: 'https://app.posthog.com',
    autocapture: false,
    capture_pageview: false,
  });
  isInit = true;
};

export const analyticEvent = (eventName: string, props: any): void => {
  if (isInit) {
    posthog.capture(eventName, props) as void;
  }
};

export const setCompanyGroup = (
  id: number,
  companyName: string,
  companyUsers: Array<{ email?: string, id: string }>,
): void => {
  posthog.group('company', id?.toString(), {
    id,
    name: companyName,
    companyUsers,
  });
};

export const resetAnalytics = (): void => {
  if (isInit) {
    posthog.reset();
  }
};

export const setPosthogIdentify = (
  email?: string,
  companyId?: number,
  companyName?: string,
  companyUsers?: Array<{ email?: string, id: string }>,
): void => {
  if (email && isInit) {
    resetAnalytics();
    posthog.identify(email, { email, companyName });
    if (companyId && companyName) {
      setCompanyGroup(companyId, companyName, companyUsers);
    }
    posthog.reloadFeatureFlags();
  }
};

export const analyticToggleEvent = (eventName: string, currentStatus: boolean, props?: any): void => {
  analyticEvent(eventName, { status: !currentStatus ? 'Enable' : 'Disable', ...props });
};

export const getInitStatus = (): boolean => {
  return isInit;
};
