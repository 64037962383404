const ADD_BID_PRICING = 'ADD_BID_PRICING';
const CREATE_PROJECT = 'CREATE_PROJECT';
const CREATE_DEMO_PROJECT = 'CREATE_DEMO_PROJECT';
const UPDATE_DEMO_PROJECT = 'UPDATE_DEMO_PROJECT';
const ADD_FILE_TO_PROJECT = 'ADD_FILE_TO_PROJECT';
const INVITE_TO_COMPANY = 'invite_to_company';
const INVITE_TO_PROJECT = 'invite_to_project';
const LABOUR_LIST = 'labour_list';
const MATERIAL_RESOURCE_LIST = 'material_resource_list';
const EDIT_ACTIVITY = 'EDIT_ACTIVITY';
const EDIT_MODALITY = 'EDIT_MODALITY';
const PROFILE = 'profile';
const RESTRICT_AVATAR = 'RESTRICT_AVATAR';
const CREATE_DATABASE = 'CREATE_DATABASE';
const ADD_RESOURCE_ROLE = 'ADD_RESOURCE_ROLE';
const RESET_PASSWORD = 'reset';
const EDIT_SCENARIO = 'EDIT_SCENARIO';
const MANAGE_ROLES = 'MANAGE_ROLES';
const CREATE_REPORT_TEMPLATE = 'CREATE_REPORT_TEMPLATE';


export {
  ADD_BID_PRICING,
  ADD_FILE_TO_PROJECT,
  CREATE_PROJECT,
  CREATE_DEMO_PROJECT,
  UPDATE_DEMO_PROJECT,
  INVITE_TO_COMPANY,
  INVITE_TO_PROJECT,
  LABOUR_LIST,
  MATERIAL_RESOURCE_LIST,
  EDIT_ACTIVITY,
  EDIT_MODALITY,
  PROFILE,
  RESTRICT_AVATAR,
  CREATE_DATABASE,
  ADD_RESOURCE_ROLE,
  RESET_PASSWORD,
  EDIT_SCENARIO,
  MANAGE_ROLES,
  CREATE_REPORT_TEMPLATE,
};
