import { useCallback, useState } from 'react';

const isWidthValid = (): boolean => window.innerWidth >= 1640;

export const useCheckValidState = (): [boolean, (v: boolean) => void, boolean, () => void] => {
  const [valid, setValid] = useState(isWidthValid());
  const [isOpen, setOpen] = useState(!valid);
  const updateValidStatus = useCallback(() => {
    if (isOpen) {
      setValid(isWidthValid());
    }
  }, []);
  const close = useCallback(() => { setOpen(false); }, []);

  return [valid, updateValidStatus, isOpen, close];
};
