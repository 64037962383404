import { StringDictionary } from 'common/interfaces/dictionary';
import { RevitPropertiesResponseInfo } from '../../projects/interfaces/revit-properties-response-info';
import { PropertiesGroupAggregatedValues, PropertiesGroupToPropertiesMap } from '../interfaces';
import { FinishLayer, LayerMaterial } from '../interfaces/layers';

export const getLayerMaterialCode = (layer: FinishLayer | LayerMaterial): string => {
  let materialCode = '';
  for (const material of layer.materials) {
    materialCode += material.name;
    for (const materialPropertyValue of material.rawDatas) {
      materialCode += `-X-${materialPropertyValue.v}`;
    }
  }
  return materialCode;
};

const sourceLayerToFinish = (layer: LayerMaterial): FinishLayer => {
  return {
    materials: layer.materials,
    ids: [layer.id],
  };
};

export const mapRawData = (items: PropertiesGroupToPropertiesMap[][]): PropertiesGroupToPropertiesMap[] => {
  if (items.length === 1) {
    return items[0];
  }
  let properties: PropertiesGroupToPropertiesMap[] = [];

  const mapper = (item: PropertiesGroupToPropertiesMap[]): any => {
    const propertySet = new Set<number>(item.map(value => value.n));
    properties = properties.filter(propertyGroup => propertySet.has(propertyGroup.n));
    properties.forEach(properyGroup => {
      const propertyName = item.find((t: any): any => t.n === properyGroup.n);
      const propertyKeys = new Set(propertyName.v);
      properyGroup.v = properyGroup.v.filter(i => propertyKeys.has(i));
    });
  };
  if (items.length && items[0]) {
    properties = items[0].map((value): PropertiesGroupToPropertiesMap => {
      return { n: value.n, v: value.v.slice() };
    });
    if (!properties) {
      return [];
    }
    for (const i of items) {
      if (properties.length > 0) {
        mapper(i);
      } else {
        break;
      }
    }
  }
  return properties;
};


export const mergeRevitProperties = (
  baseProperties: PropertiesGroupToPropertiesMap[],
  additionalProperties: Map<number, number[]>,
): PropertiesGroupToPropertiesMap[] => {
  const merged = new Array<PropertiesGroupToPropertiesMap>();
  baseProperties.forEach((propertyGroup) => {
    const additionalPropertiesForGroup = additionalProperties.get(propertyGroup.n);
    if (additionalPropertiesForGroup) {
      merged.push({ n: propertyGroup.n, v: propertyGroup.v.concat(additionalPropertiesForGroup) });
      additionalProperties.delete(propertyGroup.n);
    } else {
      merged.push(propertyGroup);
    }
  });

  additionalProperties.forEach((propertiesIds, propertiesGroupId) => {
    merged.push({ n: propertiesGroupId, v: propertiesIds });
  });

  return merged;
};

const invalidCateogories = new Set(['invalid', 'pg_data']);

export const aggregateProperties = (
  properiesGroupToPropertiesMap: PropertiesGroupToPropertiesMap[],
  uniquePropertyRows: RevitPropertiesResponseInfo[],
  propertyGroupNames: string[],
): PropertiesGroupAggregatedValues[] => {
  const result = new Array<PropertiesGroupAggregatedValues>();
  for (const value of properiesGroupToPropertiesMap) {
    const categoryName = propertyGroupNames[value.n];
    if (invalidCateogories.has(categoryName.toLowerCase())) {
      continue;
    }
    result.push({
      n: categoryName.replace(/(PG_|_|pg_)/gi, ' '),
      v: value.v.map(x => uniquePropertyRows[x]),
    });
  }
  return result;
};

export const mapLayersToProperties = (layers: FinishLayer[]): PropertiesGroupAggregatedValues[] => {
  const result = layers.map(layerInfo => {
    const value = [];
    const hasFullRowData = layerInfo.materials[0].rawDatas.length > 1;
    if (hasFullRowData) {
      value.push({
        k: 'Function',
        v: layerInfo.materials[0].rawDatas[0].v,
      });
    }
    value.push({
      k: 'Thinkness',
      v: layerInfo.materials[0].rawDatas[hasFullRowData ? 1 : 0].v,
    });

    const rawData: PropertiesGroupAggregatedValues = {
      n: layerInfo.materials[0].name,
      v: value,
    };
    return rawData;
  });
  return result;
};


const layersToList = (layers: LayerMaterial[][]): LayerMaterial[] => {
  const ret: LayerMaterial[] = [];
  for (const layerGroup of layers) {
    const layerKeyCounter: StringDictionary<number> = {};
    for (const layer of layerGroup) {
      let layerKey = getLayerMaterialCode(layer);
      const currentLayerCount = (layerKeyCounter[layerKey] || 0) + 1;
      layerKeyCounter[layerKey] = currentLayerCount;
      if (currentLayerCount > 1) {
        layerKey = `${layerKey} ${currentLayerCount}`;
      }
      layer.key = layerKey;
      ret.push(layer);
    }
  }

  return ret;
};

const mapLayers = (
  layers: LayerMaterial[],
  keys: string[],
  proccessedLayers: FinishLayer[],
): void => {
  for (const layer of layers) {
    const index = keys.indexOf(layer.key);
    if (index !== -1) {
      proccessedLayers[index].ids.push(layer.id);
    }
  }
};

export const proccessFinishLayers = (layers: LayerMaterial[][]): FinishLayer[] => {
  if (layers.length > 0) {
    const layersFinish = layers.shift().map(sourceLayerToFinish);
    const layerKeys = new Array<string>();
    const layerKeyCounter: StringDictionary<number> = {};
    for (const layer of layersFinish) {
      let layerKey = getLayerMaterialCode(layer);
      const currentLayerCount = (layerKeyCounter[layerKey] || 0) + 1;
      layerKeyCounter[layerKey] = currentLayerCount;
      if (currentLayerCount > 1) {
        layerKey = `${layerKey} ${currentLayerCount}`;
      }
      layerKeys.push(layerKey);
    }
    const layerList = layersToList(layers);
    mapLayers(layerList, layerKeys, layersFinish);
    return layersFinish;
  }
  return [];
};

export const ungroupRawData = (
  groupedProperties: PropertiesGroupToPropertiesMap[],
  rawDataInfo: RevitPropertiesResponseInfo[],
): RevitPropertiesResponseInfo[] => {
  const properties: RevitPropertiesResponseInfo[] = [];
  groupedProperties.forEach(value => {
    properties.push(...value.v.map(property => rawDataInfo[property]));
  });
  return properties;
};

