import { PaymentManagerNew, PaymentMethodInput } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { BillingPaymentModel } from '../../../interfaces/billing-payment-model';
import { ExistedCards } from './existed-cards';
import { Styled } from './styled';
import { usePaymentPortal } from './use-payment-portal';


interface Props extends AbilityAwareProps {
  cards: BillingPaymentModel[];
  primaryCardId: string;
  backupCardId: string;
  subscriptionCardId: string;
  loadBillings: (force?: boolean) => void;
  setPrimaryCard: (cardId: string) => void;
  setBackupCard: (cardId: string) => void;
  setSubscriptionCard: (cardId: string) => void;
  removeCard: (cardId: string) => void;
  createCardLegacy: (form: { cardNumber: string, expiryDate: string, cvc: string }) => void;
}

const PaymentManagerComponent: React.FC<Props> = (props) => {
  const {
    cards,
    primaryCardId,
    backupCardId,
    subscriptionCardId,
    ability,
    setPrimaryCard,
    setBackupCard,
    setSubscriptionCard,
    removeCard,
    createCardLegacy,
    loadBillings,
  } = props;

  const loadBillingsCallback = useCallback(() => loadBillings(true), []);
  const openPaymentManager = usePaymentPortal(loadBillingsCallback);
  const selectPaymentCallback = useCallback(
    (payment: string) => setSubscriptionCard(payment),
    [setSubscriptionCard],
  );

  if (ability.can(Operation.Read, Subject.OldPaymentUI)) {
    return (
      <Styled.OldPaymentUI>
        <ExistedCards
          cards={cards}
          primaryCardId={primaryCardId}
          backupCardId={backupCardId}
          subscriptionCardId={subscriptionCardId}
          setPrimaryCard={setPrimaryCard}
          setBackupCard={setBackupCard}
          setSubscriptionCard={setSubscriptionCard}
          removeCard={removeCard}
        />
        <PaymentMethodInput onSubmit={createCardLegacy}/>
      </Styled.OldPaymentUI>
    );
  } else {
    return (
      <PaymentManagerNew
        paymentMethods={cards}
        selectedPaymentId={subscriptionCardId}
        onSelectPayment={selectPaymentCallback}
        openPaymentManagementDialog={openPaymentManager}
      />
    );
  }
};


export const PaymentManager = withAbilityContext(PaymentManagerComponent);
