import { ModalWrapper } from '@kreo/kreo-ui-components';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { UpgradePlan } from '../upgrade-plan-component';

export const UPGRADE_SUBSCRIPTION_DIAGLOG = 'UPGRADE_SUBSCRIPTION_DIAGLOG';

interface DispatchProps {
  closeDialog: () => void;
}

interface Props extends DispatchProps {}

const UpgradeSubscriptionDialogComponent = ({
  closeDialog,
}: Props): JSX.Element => {
  useEffect(
    () => {
      return () => closeDialog();
    },
    [],
  );

  return (
    <DialogWrapper name={UPGRADE_SUBSCRIPTION_DIAGLOG}>
      <ModalWrapper onExit={closeDialog}>
        <UpgradePlan />
      </ModalWrapper>
    </DialogWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(UPGRADE_SUBSCRIPTION_DIAGLOG)),
  };
};

export const UpgradeSubscriptionDialog = connect(
  null,
  mapDispatchToProps,
)(UpgradeSubscriptionDialogComponent);
