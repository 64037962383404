import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { PERSISTED_STORAGE_INITIAL_STATE } from 'persisted-storage/constants/initial-state';
import { PersistedStorageState, PersistedStorageStateBoolKeys } from 'persisted-storage/interfaces/state';

export function usePersistedStorageValue<K extends keyof PersistedStorageState>(key: K): PersistedStorageState[K] {
  return useSelector<State, PersistedStorageState[K]>(
    s => s.persistedStorage[key],
  ) ?? PERSISTED_STORAGE_INITIAL_STATE[key];
}

export function usePersistedStorageToggle(key: PersistedStorageStateBoolKeys): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(PersistedStorageActions.toggleValue(key));
  }, [dispatch, key]);
}

export function usePersistedStorageSetter<K extends keyof PersistedStorageState>(
  key: K,
): (value: PersistedStorageState[K]) => void {
  const dispatch = useDispatch();
  return useCallback((value: PersistedStorageState[K]) => {
    dispatch(PersistedStorageActions.updateValue(key, value));
  }, [dispatch, key]);
}

export function usePersistedStorageValueWithChange<K extends keyof PersistedStorageState>(
  key: K,
): [PersistedStorageState[K], (value: PersistedStorageState[K]) => void] {
  const value = usePersistedStorageValue(key);
  const setValue = usePersistedStorageSetter(key);
  return [value, setValue];
}

export function usePersistedStorageValueToggle(
  key: PersistedStorageStateBoolKeys,
): [boolean, () => void] {
  const value = usePersistedStorageValue(key);
  const toggle =  usePersistedStorageToggle(key);
  return [value as boolean, toggle];
}

export function usePersistedStoragePaneSize(
  paneName: string,
  defaultValue: number,
): [number, (newSize: number) => void] {
  const dispatch = useDispatch();
  const paneSize = usePersistedStorageValue('splitterPaneSize')[paneName] || defaultValue;

  const saveSize = useCallback((size: number) => {
    dispatch(PersistedStorageActions.saveSplitterSize(paneName, size));
  }, [dispatch, paneName]);

  return [paneSize, saveSize];
}
