import { useCallback } from 'react';
import { useUndoRedoFullApi } from 'common/undo-redo';

export function useUndoRedoForToggle(func: () => void): () => void {
  const { addUndoRedo } = useUndoRedoFullApi();

  return useCallback(() => {
    addUndoRedo(func, func);
    func();
  }, [addUndoRedo, func]);
}

export function useUndoRedoForSameFunction<T extends (...args) => void>(
  func: T,
  ...prevArgs: Parameters<T>
): (...args: Parameters<T>) => void {
  const { addUndoRedo } = useUndoRedoFullApi();

  return useCallback((...args: Parameters<T>) => {
    addUndoRedo(() => func(...prevArgs), () => func(...args));
    func(...args);
  }, [addUndoRedo, func, ...prevArgs]);
}
