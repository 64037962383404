import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './database-activity-category-navigation-tree-item.scss';

import {
  KreoIconRowCollapsed,
  KreoIconRowExpanded,
} from 'common/UIKit/icons';
import { UNDEFINED_CATEGORY_CODE } from '../../constants/undefined-categoty-code';
import { ActivityCategory, ActivityCategoryCounts } from '../../interfaces/data';


interface Props {
  category: ActivityCategory;
  categoryCounts: ActivityCategoryCounts;
  level: number;
  subcategoriesStyles?: React.CSSProperties;
  titleStyles?: React.CSSProperties;
  selectedCategory: ActivityCategory;
  selectCategory: (category: ActivityCategory) => void;
  parent?: any;
}

interface State {
  expanded: boolean;
}

export class Item extends React.Component<Props, State> {
  private rootDiv: HTMLDivElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      expanded: this.checkIfChildSelected(),
    };
  }

  public componentDidMount(): void {
    if (this.isSelected(this.props.category) && this.rootDiv) {
      this.rootDiv.scrollIntoView();
    }
  }

  public render(): JSX.Element {
    const count = this.getCount();
    const code = this.props.category.code === UNDEFINED_CATEGORY_CODE
      ? ''
      : this.props.category.code;
    let icon = null;
    let subcategories = null;

    if (this.props.category.children && this.props.category.children.length > 0) {
      icon = <KreoIconRowCollapsed />;

      if (this.state.expanded) {
        icon = <KreoIconRowExpanded />;

        const level = this.props.level + 1;
        const subcategoriesStyles = {
          paddingLeft: `${10 * level}px`,
        };

        subcategories = this.props.category.children.map(subcategory => (
          <Item
            category={subcategory}
            categoryCounts={this.props.categoryCounts}
            key={subcategory.id}
            level={level}
            parent={this.props.category}
            subcategoriesStyles={subcategoriesStyles}
            selectCategory={this.props.selectCategory}
            selectedCategory={this.props.selectedCategory}
          />
        ));
      }
    }

    return (
      <div ref={this.makeRef} className='activity-categories-list'>
        <div
          className={classNames('activity-categories-list__item', {
            'selected-category': this.isSelected(this.props.category),
          })}
          onClick={this.handleLoadClick}
          title={this.props.category.title}
        >
          <div className='activity-category-info' style={this.props.subcategoriesStyles}>
            {icon && <span className='activity-category-info__icon' onClick={this.handleExpandClick}>
              {icon}
            </span>}
            <div className={`activity-category-info__title lvl-${this.props.level}`} style={this.props.titleStyles}>
              {code && <div className='activity-category-info__code'>
                {code}
              </div>}
              {this.props.category.title}
            </div>
            {count ? (
              <span className='activity-category-info__count'>{count}</span>
            ) : null}
          </div>
        </div>
        {subcategories}
      </div>
    );
  }

  @autobind
  private makeRef(div: HTMLDivElement): void {
    this.rootDiv = div;
  }

  private checkIfChildSelected(): boolean {
    const { selectedCategory, category } = this.props;

    if (!selectedCategory) return false;

    return this.hasSelectedChild(category, selectedCategory.id);
  }

  private hasSelectedChild(category: ActivityCategory, selectedCode: number): boolean {
    return category.id === selectedCode || (
      (category.children && category.children.length > 0) &&
      category.children.some(subCat => this.hasSelectedChild(subCat, selectedCode))
    );
  }

  @autobind
  private handleExpandClick(event: React.MouseEvent<HTMLSpanElement>): void {
    event.stopPropagation();
    if (this.props.category.children && this.props.category.children.length > 0) {
      this.setState({ expanded: !this.state.expanded });
    }
  }

  @autobind
  private handleLoadClick(): void {
    if (
      this.props.selectedCategory == null ||
      this.props.selectedCategory.id !== this.props.category.id
    ) {
      this.props.selectCategory(this.props.category);
    } else if (this.props.selectedCategory.id === this.props.category.id) {
      this.setState({ expanded: !this.state.expanded });
    }
  }

  private getCount(): number | null {
    if (this.props.category.id === null) {
      return this.props.categoryCounts.total;
    } else if (this.props.category.id === 0) {
      return this.props.categoryCounts.undefined;
    } else {
      return this.props.categoryCounts.byCategoryId[this.props.category.id];
    }
  }

  private isSelected(category: ActivityCategory): boolean {
    return this.props.selectedCategory && category.id === this.props.selectedCategory.id;
  }
}
