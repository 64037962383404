import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { RenderIf } from 'common/components/render-if';
import { Text } from 'common/components/text';
import { ElementTutorialTooltip } from 'common/components/tooltip';
import { ToolMenuButton } from '../../../tool-menu-button';
import { TUTORIALS } from '../../constants';
import { EraserAgregationItem } from './eraser-agregation-item';
import { Styled } from './styled';
import { useEraserRedux } from './use-eraser-redux';

const EraserMenuComponent: React.FC = () => {
  const { hasGeometriesToDelete, applyEraser, cancelEraser, exit, aggregatedSelectedGeometries } = useEraserRedux();
  return (
    <>
      <Styled.Title>
        <Icons.Eraser />
        <Text
          fontSize={14}
          lineHeight={16}
          letterSpacing={0}
          fontWeight={500}
        >
          Eraser
        </Text>
        <ElementTutorialTooltip
          position='left'
          tutorialLink={TUTORIALS.Eraser.link}
          animation={TUTORIALS.Eraser.animation}
          description={TUTORIALS.Eraser.description}
        >
          <Icons.Questions2D />
        </ElementTutorialTooltip>
      </Styled.Title>
      <RenderIf condition={Object.keys(aggregatedSelectedGeometries).length === 2}>
        <EraserAgregationItem title='Area' visibilityKey='area' geometriesIds={aggregatedSelectedGeometries.areas} />
        <EraserAgregationItem title='Lines' visibilityKey='line' geometriesIds={aggregatedSelectedGeometries.lines} />
      </RenderIf>
      <Styled.ActionButtonsContainer>
        <ToolMenuButton text='Exit' icon={Icons.Left} onClick={exit} />
        <RenderIf condition={hasGeometriesToDelete}>
          <ToolMenuButton text='Cancel' icon={Icons.UndoClassic} onClick={cancelEraser} />
          <ToolMenuButton text='Apply' icon={Icons.Search} onClick={applyEraser} />
        </RenderIf>
      </Styled.ActionButtonsContainer>
    </>
  );
};

export const EraserMenu = React.memo(EraserMenuComponent);
