import * as React from 'react';

import { ClassificationAssignmentStage } from '../../enums/classification-assignment-stage';
import { ClassificationSimpleObjectAssignmentList } from '../classification-simple-object-assignment-list';
import { ClassificationSimpleObjectCustomUniclass } from '../classification-simple-object-custom-uniclass';


interface Props {
  stage: ClassificationAssignmentStage;
}

export const ClassificationSimpleObjectAssignment: React.FC<Props> = (props) => {
  switch (props.stage) {
    case ClassificationAssignmentStage.PredictedInfo:
      return (<ClassificationSimpleObjectAssignmentList/>);
    case ClassificationAssignmentStage.Assignment:
      return (<ClassificationSimpleObjectCustomUniclass />);
    default:
      return null;
  }
};
