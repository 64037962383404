import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { Notification, NotificationProcessor } from 'common/interfaces/realtime-messages';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { CommonNotificationProcessor } from '../../notifications/common-notification-processor';
import { Project } from '../../projects/interfaces/project';
import { TwoDActions } from '../actions/creators';
import { ReportPage } from '../interfaces';
import { TwoDNotificationKeys } from './keys';

const prefix = 'two d:';

function* syncReportPages(notification: Notification): SagaIterator {
  try {
    const { projectId, pages: updatedPageList }: { projectId: number, pages: ReportPage[] } = notification.data;
    const currentProject: Project = yield selectWrapper(x => x.projects.currentProject);
    if (currentProject && currentProject.id === projectId) {
      yield put(TwoDActions.setPages(updatedPageList));
    }
  } catch (error) {
    console.error(`${prefix} Failed to sync pages`, error, notification);
  }
}

function* notifyImportFailed(notification: Notification): SagaIterator {
  try {
    const currentProject: Project = yield selectWrapper(x => x.projects.currentProject);
    if (currentProject && currentProject.id === notification.data.projectId) {
      yield call(CommonNotificationProcessor.addErrorAlert, notification);
    }
  } catch (error) {
    console.error(`${prefix} Import failed`, error, notification);
  }
}

export const TwoDNotificationsProcessor: NotificationProcessor = {
  [TwoDNotificationKeys.TableReportsPagesCountChanged]: syncReportPages,
  [TwoDNotificationKeys.TableReportsImportFromExcelFailed]: notifyImportFailed,
};
