import { SvgComponent } from '@kreo/kreo-ui-components';
import React from 'react';
import { InteractiveMenu } from 'common/components/interactive-menu';
import { DrawingsFlyingMenuIconButton } from '../drawings-flying-menu-icon-button';


interface Props {
  disabled: boolean;
  iconType: SvgComponent;
  tooltip: string;
  menuRenderer: (close: () => void) => React.ReactNode;
}

const SubmenuComponent: React.FC<Props> = ({
  disabled,
  iconType,
  tooltip,
  menuRenderer,
}) => {
  return (
    <InteractiveMenu
      renderMenuContent={menuRenderer}
      disabled={disabled}
    >
      <DrawingsFlyingMenuIconButton
        tooltip={tooltip}
        icon={iconType}
        mood={disabled && 'disabled'}
      />
    </InteractiveMenu>
  );
};

export const Submenu = SubmenuComponent;

