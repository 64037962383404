import { ActionWith } from 'common/interfaces/action-with';
import {
  CreateQtoTreeRowsForm,
  ModelType,
  QtoTemplate,
  QtoTemplateColumnsForm,
  QtoTemplateForm,
  QtoTemplateInfo,
  QtoTemplateInfoForm,
  QtoTemplateRowForm,
  ReorderQtoTreeRowsForm,
} from '../../interfaces/quantity-take-off';
import { BaseTemplatePayload, TemplateFromPayload } from '../payloads/quantity-take-off-template';
import { QuantityTakeOffTemplateActionTypes } from '../types/quantity-take-off-template';

function createTemplate(
  model: QtoTemplateForm, modelType: ModelType,
): ActionWith<TemplateFromPayload<QtoTemplateForm>> {
  return {
    type: QuantityTakeOffTemplateActionTypes.CREATE_TEMPLATE,
    payload: { modelType, form: model, id: null },
  };
}

function setSelectedTemplateId(templateId: number): ActionWith<number> {
  return {
    type: QuantityTakeOffTemplateActionTypes.SET_SELECTED_TEMPLATE_ID,
    payload: templateId,
  };
}

function loadTemplatesInfo(modelType: ModelType): ActionWith<ModelType> {
  return {
    type: QuantityTakeOffTemplateActionTypes.GET_TEMPLATES_INFO,
    payload: modelType,
  };
}

function setTemplatesInfo(templates: QtoTemplateInfo[]): ActionWith<QtoTemplateInfo[]> {
  return {
    type: QuantityTakeOffTemplateActionTypes.SET_TEMPLATES_INFO,
    payload: templates,
  };
}

function updateTemplateInfo(
  id: number,
  modelType: ModelType,
  form: QtoTemplateInfoForm,
): ActionWith<TemplateFromPayload<QtoTemplateInfoForm>> {
  return {
    type: QuantityTakeOffTemplateActionTypes.UPDATE_TEMPLATE_INFO,
    payload: { id, modelType, form },
  };
}

function getInitialState(modelType: ModelType): ActionWith<ModelType> {
  return {
    type: QuantityTakeOffTemplateActionTypes.GET_INITIAL_STATE,
    payload: modelType,
  };
}

function deleteTemplate(id: number, modelType: ModelType): ActionWith<BaseTemplatePayload> {
  return {
    type: QuantityTakeOffTemplateActionTypes.DELETE_TEMPLATE,
    payload: { id, modelType },
  };
}

function loadTemplate(id: number, modelType: ModelType): ActionWith<BaseTemplatePayload> {
  return {
    type: QuantityTakeOffTemplateActionTypes.LOAD_TEMPLATE,
    payload: { id, modelType },
  };
}

function setTemplateModel(model: QtoTemplate): ActionWith<QtoTemplate> {
  return {
    type: QuantityTakeOffTemplateActionTypes.SET_TEMPLATE_MODEL,
    payload: model,
  };
}

function updateBasicColumns(
  id: number,
  modelType: ModelType,
  form: QtoTemplateColumnsForm,
): ActionWith<TemplateFromPayload<QtoTemplateColumnsForm>> {
  return {
    type: QuantityTakeOffTemplateActionTypes.UPDATE_BASIC_COLUMNS,
    payload: { id, form, modelType },
  };
}

function updateRows(
  id: number, modelType: ModelType, form: QtoTemplateRowForm[],
): ActionWith<TemplateFromPayload<QtoTemplateRowForm[]>> {
  return {
    type: QuantityTakeOffTemplateActionTypes.UPDATE_TEMPLATE_ROWS,
    payload: { id, form, modelType },
  };
}

function addRows(
  id: number, modelType: ModelType, form: CreateQtoTreeRowsForm,
): ActionWith<TemplateFromPayload<CreateQtoTreeRowsForm>> {
  return {
    type: QuantityTakeOffTemplateActionTypes.ADD_ROWS,
    payload: { id, form, modelType },
  };
}

function removeRows(id: number, modelType: ModelType, removeIds: string[]): ActionWith<TemplateFromPayload<string[]>> {
  return {
    type: QuantityTakeOffTemplateActionTypes.REMOVE_ROWS,
    payload: { id, modelType, form: removeIds },
  };
}

function reorderRows(
  id: number, modelType: ModelType, form: ReorderQtoTreeRowsForm,
): ActionWith<TemplateFromPayload<ReorderQtoTreeRowsForm>> {
  return {
    type: QuantityTakeOffTemplateActionTypes.REORDER_ROWS,
    payload: { id, form, modelType },
  };
}

function updateTemplateInfoAfterCreationViaReport(model: QtoTemplateInfo): ActionWith<QtoTemplateInfo> {
  return {
    type: QuantityTakeOffTemplateActionTypes.UPDATE_TEMPLATES_INFO_AFTER_CREATE_VIA_REPORT,
    payload: model,
  };
}

function createReportFromTemplate(
  projectId: number, modelType: ModelType, templateId: number,
): ActionWith<TemplateFromPayload<number>> {
  return {
    type: QuantityTakeOffTemplateActionTypes.CREATE_REPORT_FROM_TEMPLATE,
    payload: {
      id: projectId,
      modelType,
      form: templateId,
    },
  };
}

export const QuantityTakeOffTemplateActions = {
  reorderRows,
  removeRows,
  addRows,
  updateBasicColumns,
  createTemplate,
  setSelectedTemplateId,
  loadTemplatesInfo,
  setTemplatesInfo,
  updateTemplateInfo,
  getInitialState,
  loadTemplate,
  deleteTemplate,
  setTemplateModel,
  updateRows,
  updateTemplateInfoAfterCreationViaReport,
  createReportFromTemplate,
};
