import * as t from 'io-ts';
import { CustomElementFilterTreeNodeC } from '.';


export const CustomElementFilterBuilderModelC = t.type({
  id: t.string,
  name: t.string,
  rootNode: CustomElementFilterTreeNodeC,
});

export type CustomElementFilterBuilderModel = t.TypeOf<typeof CustomElementFilterBuilderModelC>;
