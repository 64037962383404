
import * as dotProps from 'dot-prop-immutable';
import { AnyAction } from 'redux';

const INITIAL_STATE = {
  bimInfo: [],
  comments: [],
  selectedPathes: [],
  seletedItem: {},
  comment: {},
  rawData: [],
  pgs: [],
  selectedComments: [],
  ids: []
}

import { StringUtils } from 'common/utils/string-utils';
import { MonoliteHelper } from '../common/monolite';
import { ViewerUtilsNew } from '../units/projects/utils/viewer-utils-new';
import *  as ViewerActionType from '../units/viewer/actions/types';
import { viewerReduxMethods } from '../units/viewer/reducers';
import * as ViewerUtils from '../units/viewer/utils';
import * as Storage from '../utils/storage';

export default function(
  state: any = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null }
): any {

  if (action.type in viewerReduxMethods) {
    state = viewerReduxMethods[action.type](state, action.payload);
  }

  switch (action.type) {

      case ViewerActionType.VIEWER_GET_DOCUMENT_INFO_SUCCESS:
        return new MonoliteHelper(state)
          .set(_ => _.bimInfo, action.payload.allBimInfo)
          .set(_ => _.ids, action.payload.ids)
          .get();
      case ViewerActionType.VIEWER_TREE_ELEMENT_EXPAND:
        return { ...Storage.updateInByPath(
          state,
          ['bimInfo', ...action.payload.split('.'), 'expanded'],
          (value: any): any => !value)
        };
      case ViewerActionType.VIEWER_SELECT_ITEM:
        let s = state;
        for (const path of state.selectedPathes) {
          if (path.length === 7) {
            path.pop()
            path.pop()
          }
          s = Storage.setInByPath(s, ['bimInfo', ...path, 'selected'], false);
        }
        s = Storage.setInByPath(s, ['bimInfo', ...action.payload.identifier.split('.'), 'selected'], true);
        s.selectedPathes = [action.payload.identifier.split('.')]
        return { ...s }
      case ViewerActionType.VIEWER_ADD_SELECT_ITEM:
        s = state;
        s = Storage.setInByPath(s, ['bimInfo', ...action.payload.identificator, 'selected'], true);
        s.selectedPathes.push([...action.payload.identificator])
        return { ...s }
      case ViewerActionType.VIEWER_DESELECT_ITEM:
        s = state;
        s = Storage.setInByPath(s, ['bimInfo', ...action.payload.identificator, 'selected'], false);
        let idx;
        s.selectedPathes.find((x: any, i: any): any => {
          if (x.length === action.payload.identificator.length) {
            if (x.join('.') === action.payload.identificator.join('.')) {
              idx = i;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        })
        s.selectedPathes.splice(idx, 1);
        return { ...s }
      case ViewerActionType.VIEWER_SELECT_COMMENT:
        state.comments.forEach((x: any) => {
          if (state.selectedComments.indexOf(x.pointSerialNumber) !== -1) {
            x.selected = false;
          }
        })


        state.comments.forEach((x: any) => {

          if (action.payload.indexOf(x.pointSerialNumber) !== -1) {
            x.selected = true;
          }
        })
        state.selectedComments = action.payload;
        return Storage.assignState(state);
      case ViewerActionType.VIEWER_APPROVE_NODE:
        s = Storage.setInByPath(state, ['bimInfo', ...action.payload.path], action.payload.element);
        return { ...s };
      case ViewerActionType.VIEWER_APPROVE_STATE:
        return { ...state }
      case ViewerActionType.VIEWER_SELECT_BY_ID_COMMIT:
        s = state;
        for (const path of state.selectedPathes) {
          if (path !== undefined) {
            if (path.length === 7) {
              path.pop()
              path.pop()
            }
            s = Storage.setInByPath(s, ['bimInfo', ...path, 'selected'], false);
          }
        }
        s = Storage.setInByPath(s, ['selectedPathes'], action.payload.map((x: any): any => x.path));
        ViewerUtils.selectedOnTree(action.payload, s.bimInfo, action.provider);

        return { ...s };
      case ViewerActionType.VIEWER_SET_IDS_FOR_ITEM:
        return {
          ...Storage.setInByPath(state, ['bimInfo', ...action.payload.identificator.split('.'), 'ids'],
          action.payload.ids)
        }
      case ViewerActionType.VIEWER_GHOST_COMMIT:
        s = state;
        action.payload.forEach((x: any) => {
          s = Storage.setInByPath(s, ['bimInfo', ...x, 'disvisible'], 'ghost');
        })
        return { ...s };
      case ViewerActionType.VIEWER_HIDE_ELEMENTS_COMMIT:
        s = state;
        action.payload.forEach((x: any) => {
          s = Storage.setInByPath(s, ['bimInfo', ...x, 'disvisible'], 'none');
        })
        return { ...s };
      case ViewerActionType.VIEWER_SHOW_ELEMENTS_COMMIT:
        s = state;
        action.payload.forEach((x: any) => {
          s = Storage.setInByPath(s, ['bimInfo', ...x, 'disvisible'], 'standard');
        })
        return { ...s };
      case ViewerActionType.VIEWER_RUN_SEARCH:
        const { searchWords } = StringUtils.createSearchByWordRegex(action.payload);
        const matchedProperties = [];
        for (let i = 0; i < state.rawData.length; i++) {
          const propertyValue = state.rawData[i].v.toString().toLowerCase();
          if (searchWords.every(x => propertyValue.includes(x))) {
            matchedProperties.push(i);
          }
        }
        for (const x of ViewerUtilsNew.search(state.bimInfo, searchWords, '', new Set(matchedProperties))) {
          const itemPath = `bimInfo.${x.path}`;
          if (x.isMatched !== dotProps.get(state, `${itemPath}.search`)) {
            state = dotProps.set(state, `${itemPath}.search`, x.isMatched);
          }
          if (x.expanded !== dotProps.get(state, `${itemPath}.expanded`)) {
            state = dotProps.set(state, `${itemPath}.expaned`, x.expanded);
          }
          state = dotProps.set(state, `${itemPath}.hasMatchedChild`, x.hasMatchedChild);
        }
        return state;
      case ViewerActionType.VIEWER_CREATE_COMMENT_SUCCESSED:
        return { ...Storage.setInByPath(state, ['comments', state.comments.length], action.payload) }
      case ViewerActionType.VIEWER_REMOVE_COMMENT_SUCCESS:
        s = state;
        idx = 0;
        s.comments.find((x: any, index: any): any => {
          if (x.id === action.payload) {
            idx = index;
            return true;
          } else {
            return false;
          }
        })
        return { ...Storage.deleteInByPath(state, ['comments', idx]) }
      case ViewerActionType.VIEWER_UPDATE_STATUS_COMMENT:
        s = state;
        s.comments.find((x: any, index: any): boolean => {
          if (x.id === action.payload.id) {
            idx = index;
            return true;
          } else {
            return false;
          }
        })
        return { ...Storage.setInByPath(s, ['comments', idx, 'status'], action.payload.status) };
      case ViewerActionType.VIEWER_GO_TO_COMMENT:
        return Storage.assignState(state, { comment: action.payload })
      case ViewerActionType.VIEWER_READ_COMMENT: {
        const s = state.comments.find((x: any): boolean => x.id === action.payload);
        s.lastRead = new Date();
        return Storage.assignState(state);
      }
      case ViewerActionType.VIEWER_ADD_SUBCOMMENT_SUCCESSED:
        s = state.comments.find((x: any, index: any): boolean => {
          if (x.id === action.payload.parent) {
            idx = index;
            return true;
          } else {
            return false;
          }
        })
        s.data.subComments.push(action.payload.data);
        const p = Storage.setInByPath(state, ['comments', idx], s);
        if (state.comment.id === action.payload.parent) {
          p.comment = s;
        }
        return { ...p }
      case ViewerActionType.VIEWER_UPDATE_CURRENT_COMMENT_STATUS:
        return { ...Storage.setInByPath(state, ['comment', 'status'], action.payload.status) }
      case ViewerActionType.VIEWER_BACK_TO_COMMENTS:
        return Storage.assignState(state, { comment: {} })
      case ViewerActionType.VIEWER_ADD_TIME_MARKER:
        return Storage.assignState(state, { timemarker: action.payload })
      case ViewerActionType.VIEWER_DROP_ALL_INFO:
        return INITIAL_STATE;
      default:
        return state;
    }
}