import { Location } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { SubscriptionType } from 'common/constants/subscription';
import { HeaderContext } from 'common/enums/header-context';
import { RequestStatus } from 'common/enums/request-status';
import { PageHeader } from 'common/interfaces/page-header';
import { State } from 'common/interfaces/state';
import { PageLayout } from '../../../layouts/page-layout';
import { SpinnerPage } from '../../../routes/spinner-page';
import { CompanySubscriptionModel } from '../../../units/account/interfaces/company-subscription-model';
import { BillingNoticePage } from '../../../units/account/pages/billing-notice';
import { AccountSelectors } from '../../../units/account/selectors';
import { NotFoundPage } from '../../../units/notfound/page';
import { QuestionnairePage } from '../../../units/questionnaire-page';
import { CancellationSurveyPage } from '../../../units/subscription/cancellation-survey-page';
import { SubscriptionStatus } from '../../../units/subscription/enums/subscription-status';
import { SubscriptionPlanListingModel } from '../../../units/subscription/interfaces/subscription-plan-listing-model';
import {
  RenewSubscriptionPage,
  UpgradeSubscriptionPage,
  NoneSubscriptionPage,
} from '../../../units/subscription/pages';
import { AppUrls } from '../../app-urls';

interface RoutesProps {
  currentLocation: Location;
  isOwnCompanySelected: boolean;
  subscription: CompanySubscriptionModel;
  isSelectedCompanyLicensesLimitExceeded: boolean;
  plansModel: SubscriptionPlanListingModel | null;
  isCompanyInfoLoading: boolean;
  showQuestionnaire: boolean;
  isBusinessCompany: boolean;
}

interface OwnProps {
  urls: any;
  subscriptionType: SubscriptionType;
  canShowQuestionnaire?: boolean;
}

interface Props extends RoutesProps, OwnProps {
}

class RoutesComponent extends React.Component<Props> {
  private billingHeader: PageHeader = {
    context: HeaderContext.BillingPages,
    backUrl: AppUrls.products.url(),
  };

  public render(): React.ReactNode {
    const {
      isOwnCompanySelected,
      subscription,
      isSelectedCompanyLicensesLimitExceeded,
      plansModel,
      isCompanyInfoLoading,
      showQuestionnaire,
      currentLocation,
      urls,
    } = this.props;

    const isNone = !subscription && isOwnCompanySelected
      || subscription && subscription.status === SubscriptionStatus.None;

    const subscriptionIsBlocked = !subscription
      || subscription.status !== SubscriptionStatus.Valid
      || isSelectedCompanyLicensesLimitExceeded;

    if (isCompanyInfoLoading) {
      return (<SpinnerPage />);
    }

    if (showQuestionnaire) {
      return this.renderQuestionnairePage();
    }

    if (!isOwnCompanySelected && subscriptionIsBlocked) {
      return this.renderBillingNoticePage();
    }

    if (isNone) {
      return this.renderSubscribePage();
    }

    const isFree = plansModel && plansModel.plans.find(x => x.variants.find(v => v.id === subscription.planId))?.isFree;

    if (isFree) {
      return this.renderUpgradePage();
    }

    const isCanceled = subscription.status === SubscriptionStatus.Canceled;
    const isPaused = AccountSelectors.isPausedSubscription(subscription);
    const isCancellationSurvey = urls?.cancellationSurvey && currentLocation.pathname === urls.cancellationSurvey.path;

    if (isCancellationSurvey) {
      return this.renderCancellationSurveyPage();
    }

    if (isCanceled) {
      return this.renderRenewSubscriptionPage();
    }

    if (isPaused) {
      return this.renderResumeSubscriptionPage();
    }

    return <SpinnerPage />;
  }

  private renderRenewSubscriptionPage(): JSX.Element {
    const urls = this.props.urls;

    return (
      <Switch>
        <Route
          path={urls.billing.renewSubscription.path}
          exact={true}
          metaTitle='Renew Subscription'
          component={RenewSubscriptionPage}
          header={this.billingHeader}
        />
        <Redirect to={urls.billing.renewSubscription.path} />
      </Switch>
    );
  }

  private renderResumeSubscriptionPage(): JSX.Element {
    const urls = this.props.urls;

    return (
      <Switch>
        <Route
          path={urls.billing.resumeSubscription.path}
          exact={true}
          metaTitle='Resume Subscription'
          component={RenewSubscriptionPage}
          header={this.billingHeader}
        />
        <Redirect to={urls.billing.resumeSubscription.path} />
      </Switch>
    );
  }

  private renderSubscribePage(): JSX.Element {
    const urls = this.props.urls;

    return (
      <Switch>
        <Route
          path={urls.billing.subscribe.path}
          exact={true}
          metaTitle='Subscribe'
          component={NoneSubscriptionPage}
          header={this.billingHeader}
        />
        <Redirect to={urls.billing.subscribe.path} />
      </Switch>
    );
  }

  private renderCancellationSurveyPage(): JSX.Element {
    const urls = this.props.urls;

    return (
      <Switch>
        <Route
          path={urls.cancellationSurvey.path}
          exact={true}
          metaTitle='Cancellation Survey'
          component={CancellationSurveyPage}
        />
      </Switch>
    );
  }

  private renderQuestionnairePage(): JSX.Element {
    const urls = this.props.urls;

    return (
      <Switch>
        <Route
          path={urls.questionnaire.path}
          exact={true}
          metaTitle='Questionnaire'
          component={QuestionnairePage}
        />
      </Switch>
    );
  }

  private renderUpgradePage(): JSX.Element {
    const urls = this.props.urls;
    return (
      <Switch>
        <Route
          path={urls.billing.upgradeSubscription.path}
          exact={true}
          metaTitle="Upgrade"
          component={UpgradeSubscriptionPage}
          header={this.billingHeader}
        />
        <Redirect to={urls.billing.upgradeSubscription.path} />
      </Switch>
    );
  }

  private renderBillingNoticePage(): JSX.Element {
    const urls = this.props.urls;

    return (
      <Switch>
        <PageLayout
          path={urls.billing.billingNotice.path}
          exact={true}
          metaTitle='Billing Notice'
          component={BillingNoticePage}
          header={this.billingHeader}
        />
        <Redirect to={urls.billing.billingNotice.path} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

function mapStateToProps(s: State, { canShowQuestionnaire }: OwnProps): RoutesProps {
  const ownCompany = s.account.ownedCompany;
  const selectedCompany = s.account.selectedCompany;

  return {
    currentLocation: s.router.location,
    isOwnCompanySelected: ownCompany && selectedCompany && selectedCompany.id === ownCompany.id,
    subscription: AccountSelectors.currentSubscription(s),
    isSelectedCompanyLicensesLimitExceeded: AccountSelectors.isSelectedCompanyLicensesLimitExceeded(s),
    plansModel: s.account.subscriptionPlans,
    isCompanyInfoLoading: s.people.requests.companiesUsers === RequestStatus.Loading,
    showQuestionnaire: canShowQuestionnaire && s.account.showQuestionnaire,
    isBusinessCompany: s.account.ownedCompany?.isBusiness,
  };
}

export const Routes = connect(mapStateToProps)(RoutesComponent);
