import * as React from 'react';
import { RouteComponentProps } from 'react-router';


import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { ClassificationEngineLayout } from '../../components/classification-engine-layout';
import { ClassificationLeftSideEdit } from '../../components/classification-left-side-edit';
import { ClassificationOntologyTreeLeftSide } from '../../components/classification-ontology-tree-left-side';
import { ValidationStepMode } from '../../enums/validation-step-mode';

export class ClassificationEditPage extends React.Component<RouteComponentProps<PlanProjectRouteParams>> {
  public render(): React.ReactNode {
    return (
      <ClassificationEngineLayout
        mode={ValidationStepMode.Edit}
        {...this.props}
      >
        <ClassificationOntologyTreeLeftSide
          mode={ValidationStepMode.Edit}
        >
          <ClassificationLeftSideEdit/>
        </ClassificationOntologyTreeLeftSide>
      </ClassificationEngineLayout>
    );
  }
}

