import styled from 'styled-components';

const AvatarAndInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px;

  div:first-child {
    margin-right: 15px;
  }
`;

const ButtonsPanelWrapper = styled.div`
  margin-left: 55px;
`;

export const Styled = {
  AvatarAndInput,
  ButtonsPanelWrapper,
};
