import { CellEditor } from './cell-editor';

function getInnerHtml(className: string): string {
  const wrapper = CellEditor.getEditorHtmlWrapper();
  const input = document.createElement('input');
  input.className = className;
  wrapper.appendChild(input);
  return wrapper.outerHTML;
}

function getInputElement(value: string, className: string): HTMLInputElement {
  const input = document.createElement('input');
  input.className = className;
  input.value = value;
  return input;
}

export const DefaultCellEditorHelper = {
  getInnerHtml,
  getInputElement,
};
