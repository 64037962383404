import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { BillingEntityStatus } from '../../../enums/billing-entity-status';
import { BillingPeriodUnit } from '../../../enums/billing-period-unit';
import { SubscriptionAddon } from '../../../interfaces/subscription-addon';
import { SubscriptionPlanListingModel } from '../../../interfaces/subscription-plan-listing-model';
import { SubscriptionPlanVariantWithName } from '../../../interfaces/subscription-plan-variant-with-name';
import { SubscriptionListingUtils } from '../../../utils/subscription-listing-utils';

interface OwnState {
  billingPeriod: {
    value: BillingPeriodUnit,
    set: (v: BillingPeriodUnit) => void,
  };
  selectedPlanGroup: {
    value: string,
    set: (v: string) => void,
  };
  selectedAddonGroups: {
    value: string[],
    set: (v: string[]) => void,
  };
  filteredPlanGroups: SubscriptionPlanVariantWithName[];
  filteredAddons: SubscriptionAddon[];
  selectedAddons: SubscriptionAddon[];
  currentPlanVariant: SubscriptionPlanVariantWithName;
}

const initialPlan: SubscriptionPlanListingModel = {
  plans: [],
  addons: [],
};

export const useUpgradePlanState = (): OwnState => {
  const plansModel = useSelector<State, SubscriptionPlanListingModel | null>(s => s.account.subscriptionPlans);
  const activePlansModels = plansModel ? plansModel : initialPlan;
  const [billingPeriod, setBillingPeriod] = useState(BillingPeriodUnit.Year);
  const [selectedPlanGroup, setSelectedPlanGroup] = useState<string>(null);
  const [selectedAddonGroups, setSelectedAddonsGroups] = useState([]);

  const variantsByPeriod = useMemo(
    () => SubscriptionListingUtils.getFilteredPlanGroups(activePlansModels, billingPeriod),
    [activePlansModels, billingPeriod],
  );
  const currentPlanVariant = useMemo(
    () => variantsByPeriod.find(x => x.name === selectedPlanGroup),
    [variantsByPeriod, selectedPlanGroup],
  );
  const filteredPlanGroups = useMemo(
    () => variantsByPeriod.filter(v => v.status !== BillingEntityStatus.Archived),
    [variantsByPeriod],
  );
  const filteredAddons = useMemo(
    () => SubscriptionListingUtils.getFilteredAddons(activePlansModels, billingPeriod),
    [activePlansModels, billingPeriod],
  );
  const selectedAddons = useMemo(
    () =>
      SubscriptionListingUtils.getSelectedAddons(
        activePlansModels,
        billingPeriod,
        selectedAddonGroups,
      ),
    [activePlansModels, billingPeriod, selectedAddonGroups],
  );

  return {
    billingPeriod: {
      value: billingPeriod,
      set: setBillingPeriod,
    },
    selectedPlanGroup: {
      value: selectedPlanGroup,
      set: setSelectedPlanGroup,
    },
    selectedAddonGroups: {
      value: selectedAddonGroups,
      set: setSelectedAddonsGroups,
    },
    filteredPlanGroups,
    filteredAddons,
    selectedAddons,
    currentPlanVariant,
  };
};
