import { IconButton } from '@material-ui/core';
import { default as Done } from '@material-ui/icons/Done';
import autobind from 'autobind-decorator';
import moment, { Moment } from 'moment';
import * as React from 'react';

import './date-time-input.scss';

import { DATE_FORMAT, MaterialDatePicker, MaterialInput } from 'common/UIKit';

interface DateTimeInputProps {
  value: string;
  className?: string;
  onChange?: (newValue: Moment) => void;
}

interface DateTimeInputState {
  value: string;
  valueDate: string;
  valueTime: string;
}

const TIME_FORMAT = 'HH:mm';
const INPUT_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export class DateTimeInput extends React.Component<DateTimeInputProps, DateTimeInputState> {
  constructor(props: DateTimeInputProps) {
    super(props);
    const dateTime = this.props.value ? new Date(this.props.value) : new Date();
    const mDT = moment(dateTime);

    this.state = {
      value: mDT.format(INPUT_FORMAT),
      valueDate: mDT.format(DATE_FORMAT),
      valueTime: mDT.format(TIME_FORMAT),
    };
  }

  public render(): JSX.Element {
    return (
      <div className='date-time-input'>
        <MaterialDatePicker
          className='date-time-input__date'
          id='date'
          type='text'
          defaultValue='default'
          value={this.state.valueDate ? this.state.valueDate : null}
          onChange={this.onChange}
        />
        <MaterialInput
          className='date-time-input__time'
          id='time'
          type='time'
          defaultValue='default'
          value={this.state.valueTime ? this.state.valueTime : null}
          onChange={this.onChangeTime}
        />
        <IconButton className='date-time-input__button' onClick={this.onDone}>
          <Done />
        </IconButton>
      </div>
    );
  }

  @autobind
  private onChange(_event: React.ChangeEvent<{}>, date: string): void {
    const valueDate = moment(date).format(DATE_FORMAT);
    const value = moment(`${valueDate} ${this.state.valueTime}`, INPUT_FORMAT).format(INPUT_FORMAT);
    this.setState({ value, valueDate });
  }

  @autobind
  private onChangeTime(_event: React.ChangeEvent, valueTime: string): void {
    const value = moment(`${this.state.valueDate} ${valueTime}`, INPUT_FORMAT).format(INPUT_FORMAT);
    this.setState({ value, valueTime });
  }

  @autobind
  private onDone(): void {
    if (this.props.onChange) {
      this.props.onChange(moment(this.state.value, INPUT_FORMAT));
    }
  }
}
