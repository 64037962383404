import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { arrayUtils } from 'common/utils/array-utils';
import { DrawingsAnnotationActions } from '../../actions/creators/annotation';
import { DrawingsAnnotationLegendActions } from '../../actions/creators/drawings-annotation-legend';
import { DrawingsUpdateActions } from '../../actions/creators/update';
import { DrawingsChanges } from '../../actions/payloads/update';
import { useRendererApiContext } from '../../drawings-contexts';
import { useDrawingsLayoutApi } from '../../drawings-layout-api-context';
import { DrawingsDrawMode, DrawingsInstancesUpdateType } from '../../enums';
import { DrawingChangeOperation } from '../../enums/drawing-change-operation';
import { DrawingChangeSource } from '../../enums/drawing-change-source';
import { DrawingsCommonUtils } from '../../utils/drawings-common-utils';
import { useDeleteGroups } from '../groups';
import { useCurrentDrawingInfo } from '../redux-selectors';
import { useDrawModeApi } from '../use-draw-mode-api';

function useApplyRemoveToState(): (instancesByDrawings: Record<string, string[]>) => void {
  const drawingLayoutApi = useDrawingsLayoutApi();
  const dispatch = useDispatch();

  return useCallback((instancesByDrawings: Record<string, string[]>) => {
    const changes = new Array<DrawingsChanges>();
    const instancesIds = [];

    for (const [pageId, ids] of Object.entries(instancesByDrawings)) {
      arrayUtils.extendArray(instancesIds, ids);
      changes.push(
        {
          pageId,
          operation: DrawingChangeOperation.Delete,
          elementIds: ids,
          updateType: DrawingsInstancesUpdateType.Full,
        },
      );
    }
    dispatch(TwoDElementViewActions.updateColumnBeforeDelete(instancesIds));
    dispatch(DrawingsAnnotationActions.removeInstances(instancesIds));
    const { onInstancesRemove } = drawingLayoutApi.getCallbacks();
    if (onInstancesRemove) {
      onInstancesRemove(instancesIds);
    }
    dispatch(DrawingsUpdateActions.commitUpdates(changes, DrawingChangeSource.Elements));
  }, [drawingLayoutApi, dispatch]);
}

export function useRemoveInstances(): (instancesByDrawings: Record<string, string[]>, groupIds: string[]) => void {
  const dispatch = useDispatch();
  const { rendererApi } = useRendererApiContext();
  const currentDrawingId = useCurrentDrawingInfo()?.drawingId;
  const applyRemove = useApplyRemoveToState();
  const deleteGroups = useDeleteGroups();
  const { drawMode, setDrawMode } = useDrawModeApi();
  return useCallback((instancesByDrawings: Record<string, string[]>, groupIds: string[]) => {
    let hasExitDrawMode = false;
    if (rendererApi) {
      hasExitDrawMode = DrawingsCommonUtils.isIsolativeDrawMode(drawMode)
        && instancesByDrawings[currentDrawingId]?.some(id => rendererApi.engine.isExistInOperation(id));
      rendererApi.engine.removeInstancesByIds(instancesByDrawings[currentDrawingId] || []);
    }
    const instancesIds = arrayUtils.flatArray(Object.values(instancesByDrawings));
    applyRemove(instancesByDrawings);
    dispatch(DrawingsAnnotationLegendActions.removeDrawingsFromGroups(instancesIds));
    if (groupIds && groupIds.length) {
      deleteGroups(groupIds);
    }
    if (hasExitDrawMode) {
      setDrawMode(DrawingsDrawMode.Disabled, { ignoreCancelMessage: true });
    }
  }, [rendererApi, dispatch, currentDrawingId, applyRemove, deleteGroups, drawMode, setDrawMode]);
}
