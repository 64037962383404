import styled from 'styled-components';
import { DrawingsSVGIcons, getSVGImageUrl } from '../../constants/drawings-svg-icons';


const Container = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  border: 1px solid ${p => p.theme.color.background} !important;
  border-radius: 10px;
  box-sizing: border-box;
`;

const ImageMonochrome = styled.div<{ code: DrawingsSVGIcons }>`
  display: inline-block;
  -webkit-mask: url(${p => getSVGImageUrl(p.code)}) no-repeat 50% 50%;
  mask: url(${p => getSVGImageUrl(p.code)}) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  width: 60px;
  height: 60px;
  background-color: ${p => p.theme.color.turquoise};
  margin: auto;
`;

const ImageMulticolor = styled.div<{ code: DrawingsSVGIcons }>`
  display: inline-block;
  mask-size: cover;
  width: 60px;
  height: 60px;
  background: url(${p => getSVGImageUrl(p.code)}) no-repeat 50% 50%;
  margin: auto;
`;

export const Styled = {
  Container,
  ImageMonochrome,
  ImageMulticolor,
};
