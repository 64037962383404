import { ElementTooltip, IconButton } from '@kreo/kreo-ui-components';
import React, { memo } from 'react';
import { DrawingsCanvasConstants } from 'common/components/drawings/constants';

interface Props {
  text: string;
  disabled?: boolean;
  disabledText?: string;
  onClick: () => void;
  Icon: React.ComponentType;
  defaultColor?: string;
}

const IconButtonComponent: React.FC<Props> = (props) => {
  const { text, onClick, Icon, defaultColor, disabled, disabledText } = props;

  return (
    <ElementTooltip
      position={'top'}
      speed={'xl'}
      text={disabled ? disabledText : text}
    >
      <IconButton
        defaultColor={defaultColor}
        onClick={onClick}
        Icon={Icon}
        height={DrawingsCanvasConstants.menuButtonSize}
        width={DrawingsCanvasConstants.menuButtonSize}
        mood={disabled ? 'disabled' : 'default'}
      />
    </ElementTooltip>
  );
};

export const MenuButton = memo(IconButtonComponent);
