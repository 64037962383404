import React, { useContext } from 'react';

import {
  DrawingsGeometryOperationsApiContext,
  DrawingsGeometryOperationsContext,
  DrawingsGeometryOperationsFullContext,
} from './geometry-operations-context';

export function withDrawingsGeometryOperationsContext<P>(
  Component: React.ComponentType<P & DrawingsGeometryOperationsFullContext>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawingsGeometryOperationsFullContext>>> {
  return (props: P) => {
    const setter = useContext(DrawingsGeometryOperationsContext);
    const api = useContext(DrawingsGeometryOperationsApiContext);
    return <Component {...props} {...setter}  {...api} />;
  };
}
