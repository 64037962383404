import * as React from 'react';

import './ghosting-toggle.scss';

import { Toggle } from '../../../../../../components/toggle';

interface SwitchToggle {
  ghostFinished: boolean;
  setGhostFinished: (ghostFinished: boolean) => void;
}

export class GhostingToggle extends React.Component<SwitchToggle> {
  public render(): React.ReactNode {
    return (
      <div className='ghosting-toggle'>
        <div className='ghosting-toggle__input-wrapper'>
          <Toggle
            checked={this.props.ghostFinished}
            onCheck={this.props.setGhostFinished}
            name='ghost-finished'
            checkedColor='#5C8AE6'
          />
        </div>
        <div className='ghosting-toggle__label'>
          GHOST FINISHED
        </div>
      </div>
    );
  }
}
