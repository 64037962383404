import autobind from 'autobind-decorator';

import {
  DrawingsGeometryGroup,
  DrawingsInstanceMeasure,
  DrawingsLayoutApi,
} from 'common/components/drawings';
import { DrawingsFile, DrawingsFolderViewInfo } from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces/drawings-short-drawing-info';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import { ExcelFormulaHelper } from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { FormulaInfoController } from 'common/components/excel-table/utils/formula-info-controller';
import { ValueHelper } from 'common/utils/value-helper';
import { TwoDRegex } from '../2d-regex';
import { replaceDrawingRef } from '../replace-drawing-ref';
import { SegmentLength } from '../segment-length-helper';
import { SheetDataStore } from './report-cell-data-store';

export interface DrawingPayload {
  drawingsLayoutApi: DrawingsLayoutApi;
  drawingsInfo: Record<string, DrawingsShortInfo>;
  aiAnnotation: DrawingsGeometryState;
  drawingGroups: DrawingsGeometryGroup[];
  entities: Record<string, DrawingsFile | DrawingsFolderViewInfo>;
  elementMeasurement: Record<string, DrawingsInstanceMeasure>;
}

export type GetReportPageData = (sheetId: string) => SheetDataStore;
export type GetDrawingValue = () => DrawingPayload;
export type GetDynamicCellValue = (id: string) => string;
export type IsImperial = () => boolean;
export type GetCellValue = (sheetId: string, colId: string, rowId: string, cellKey: string) => string | number;

export interface ReportCellValueGetterProps {
  getReportPageData: GetReportPageData;
  getDrawingValue: GetDrawingValue;
  getDynamicCellValue: GetDynamicCellValue;
  isImperial: IsImperial;
  getCellValue: GetCellValue;
}


export class ReportCellValueGetter {
  private getReportPageData: GetReportPageData = null;
  private getDrawingValue: GetDrawingValue = null;
  private getDynamicCellValue: GetDynamicCellValue = null;
  private isImperial: IsImperial;

  public constructor(props: ReportCellValueGetterProps) {
    this.getReportPageData = props.getReportPageData;
    this.getDrawingValue = props.getDrawingValue;
    this.getDynamicCellValue = props.getDynamicCellValue;
    this.isImperial = props.isImperial;
  }

  public getValue(cellId: string, fic: FormulaInfoController): string {
    const data = this.getFullCellData(cellId);
    return this.getValueByData(cellId, data, fic).toString();
  }

  public getValueByData(cellId: string, data: string, fic: FormulaInfoController): string | number {
    fic.setGetters(this.getFullCellData, this.getCellValueForFormula);
    if (this.isFormula(data)) {
      const cellsMap = new Set([cellId.toUpperCase()]);
      const formulaResult = ExcelFormulaHelper.calculateFormula(
        data,
        cellsMap,
        this.replaceDrawingRef,
        fic,
      );

      if (Number.isNaN(formulaResult)) {
        return ExcelFormulaHelper.INVALID_VALUE;
      }

      return formulaResult as string;
    }

    return data;
  }

  @autobind
  public getCellData(
    sheetId: string,
    columnId: string,
    rowId: string,
    fullCellId: string,
  ): string {
    const pageData = this.getReportPageData(sheetId);
    if (!pageData) {
      return ExcelFormulaHelper.INVALID_VALUE;
    }
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const row = pageData.rows[rowIndex];

    const reportValue = !row
      ? ''
      : row[columnId]?.toString() || '';

    const dynamicValue = this.getDynamicCellValue(fullCellId);
    if (dynamicValue) {
      return reportValue ? ExcelFormulaHelper.INVALID_VALUE : dynamicValue.toString();
    }
    return reportValue;
  }

  @autobind
  private getFullCellData(fullCellId: string): string {
    const [sheetId, columnId, rowId] = this.getCellIdInfo(fullCellId);
    return this.getCellData(sheetId, columnId, rowId, fullCellId);
  }

  private getCellIdInfo(fullId: string): [string, string, string] {
    let index = 38;
    for (let i = 37; i < fullId.length; i++) {
      if (!isNaN(fullId[i] as any)) {
        index = i;
        break;
      }
    }
    return [fullId.slice(0, 36), fullId.slice(37, index), fullId.slice(index)];
  }

  private isFormula(value: string | number): boolean {
    return value && typeof value === 'string' && value.startsWith('=');
  }

  @autobind
  private getCellValueForFormula(
    cellKey: string,
    fic: FormulaInfoController,
  ): string | number {
    const match = TwoDRegex.fullCellId.exec(cellKey);
    const { sheetId, columnId, rowId } = match.groups;
    if (sheetId === undefined) {
      return cellKey;
    }
    const colId = columnId.toUpperCase();
    const cellData: any = this.getCellData(sheetId, colId, rowId, cellKey);
    const cellDataString = Number.isNaN(Number(cellData))
      ? `"${cellData}"`
      : cellData.toString();
    const isFormula = this.isFormula(cellData);
    const result = isFormula
      ? this.getFormulaValue(fic, cellData, cellKey)
      : this.formatCellData(cellDataString);
    return ValueHelper.castToNumberIfNeeded(result);
  }

  private formatCellData(cellData: string): string {
    return cellData === ''
      ? '""'
      : cellData;
  }

  private getFormulaValue(
    fic: FormulaInfoController,
    cellData: string,
    cellKey: string,
  ): string {
    const hasLoop = fic.hasLoop(cellData, new Set([cellKey]));
    if (hasLoop) {
      return ExcelFormulaHelper.INVALID_VALUE;
    }
    return ExcelFormulaHelper.getFormulaValue(
      fic.optimize(cellData),
      this.replaceDrawingRef,
    ).toString();
  }

  @autobind
  private replaceDrawingRef(value: string): string | number {
    const { drawingsLayoutApi, drawingsInfo, drawingGroups, entities, aiAnnotation } = this.getDrawingValue();
    const isImperial = this.isImperial();
    const result = replaceDrawingRef(
      value,
      drawingsLayoutApi,
      isImperial,
      aiAnnotation.geometry,
      drawingsInfo,
      drawingGroups,
      entities,
      this.getInstancesMeasures,
      this.getSegmentLength,
    );

    if (result === ExcelFormulaHelper.INVALID_REF) {
      throw new Error();
    }

    return ValueHelper.castToNumberIfNeeded(result);
  }

  @autobind
  private getInstancesMeasures(instancesIds: string[]): DrawingsInstanceMeasure[] {
    const { drawingsLayoutApi, aiAnnotation, elementMeasurement, drawingsInfo } = this.getDrawingValue();
    return drawingsLayoutApi.getInstancesMeasures(
      instancesIds,
      drawingsInfo,
      aiAnnotation,
      elementMeasurement,
    );
  }

  @autobind
  private getSegmentLength(points: string): DrawingsInstanceMeasure | null {
    const { aiAnnotation, elementMeasurement, drawingsLayoutApi, drawingsInfo } = this.getDrawingValue();
    return drawingsLayoutApi.getSegmentMeasures(
      SegmentLength.splitId(points),
      drawingsInfo,
      aiAnnotation,
      elementMeasurement,
    );
  }
}
