import * as t from 'io-ts';
import { CEActivityAssignmentDataSelectedVariantC } from './ce-activity-assignment-data-selected-variant';


export const CEActivityAssignmentDataMaterialVariantC = t.type(
  {
    id: t.number,
    name: t.string,
  },
  'CEActivityAssignmentDataMaterialVariant',
);

export const CEActivityAssignmentDataMaterialC = t.type(
  {
    selectedVariant: CEActivityAssignmentDataSelectedVariantC,
    variants: t.array(CEActivityAssignmentDataMaterialVariantC),
  },
  'CEActivityAssignmentDataMaterial',
);

export type CEActivityAssignmentDataMaterial = t.TypeOf<typeof CEActivityAssignmentDataMaterialC>;
export type CEActivityAssignmentDataMaterialVariant = t.TypeOf<typeof CEActivityAssignmentDataMaterialVariantC>;
