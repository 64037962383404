import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawingsActions } from 'common/components/drawings/actions/creators/common';
import { getActualSnapping } from 'common/components/drawings/helpers/pdf-current-doc-getter';
import { DrawingSnapping } from 'common/components/drawings/interfaces/drawing';
import { State } from 'common/interfaces/state';

interface Snapping {
  snapping: DrawingSnapping;
  loaded: boolean;
}

export function useSnapping(
  document: Core.Document,
  drawingId: string,
  defaultSnapping: DrawingSnapping = null,
): Snapping {
  const sourceSnappingBlocked = useSelector<State, boolean>(
    (x) => x.drawings.drawingsInfo[drawingId].isSnappingBlocked,
  );
  const [snappingState, setSnapping] = useState<Snapping>({
    snapping: defaultSnapping,
    loaded: !!defaultSnapping || sourceSnappingBlocked,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (snappingState.loaded || !document) {
      return;
    }

    getActualSnapping(
      { isOptimized: false, isSnappingBlocked: false },
      { pdfDocument: document, drawingId, pageNumber: 0 },
    ).then((x) => {
      if (!x) {
        dispatch(DrawingsActions.markDrawingWithoutSnapping(drawingId));
        setSnapping((prev) => {
          return { ...prev, loaded: true };
        });
      } else {
        setSnapping({ snapping: x, loaded: true });
      }
    });
  }, [document, drawingId]);

  return snappingState;
}
