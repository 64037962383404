import {
  CustomElementFilterForm,
  CustomElementFilterSimpleViewModel,
  CustomElementFilterViewModel,
} from 'common/interfaces/custom-element-filter-builder';
import { objectUtils } from 'common/utils/object-utils';
import { CompanyResourcesApi } from '../../../api/server';


function getFilters(): Promise<CustomElementFilterSimpleViewModel[]> {
  return CompanyResourcesApi.get(`/filters/listing`);
}

function getFiltersByIds(ids: string[]): Promise<CustomElementFilterViewModel> {
  const query = objectUtils.toQueryString({ Id: ids });
  return CompanyResourcesApi.get(`/filters?${query}`);
}

function addFilters(forms: CustomElementFilterForm[]): Promise<void> {
  return CompanyResourcesApi.post(`/filters/`, forms);
}

function updateFilters(forms: CustomElementFilterForm[]): Promise<CustomElementFilterViewModel> {
  return CompanyResourcesApi.put(`/filters/`, forms);
}

function deleteFilters(ids: string[]): Promise<void> {
  const query = objectUtils.toQueryString({ Id: ids });
  return CompanyResourcesApi.delete(`/filters?${query}`, null);
}


export const CustomFiltersApi = {
  getFilters,
  getFiltersByIds,
  addFilters,
  updateFilters,
  deleteFilters,
};
