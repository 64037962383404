const TOPPANEL_EDITED_TYPE = 'TOPPANEL_EDITED_TYPE';
const TOPPANEL_LEFT_MENU_EXPAND = 'TOPPANEL_LEFT_MENU_EXPAND';
const TOPPANEL_LEFT_MENU_ACTIVATE_ACTION = 'TOPPANEL_LEFT_MENU_ACTIVATE_ACTION';
const TOPPANEL_SET_MOBILE = 'TOPPANEL_SET_MOBILE';

export {
  TOPPANEL_EDITED_TYPE,
  TOPPANEL_LEFT_MENU_EXPAND,
  TOPPANEL_LEFT_MENU_ACTIVATE_ACTION,
  TOPPANEL_SET_MOBILE
}