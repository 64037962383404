import { ActionWith } from 'common/interfaces/action-with';
import {
  ActivityVariantModel,
} from '../../interfaces/data';
import { ActivityVariantForm } from '../../interfaces/rest-data';
import {
  CreateActivityVariantPayload,
  DeleteActivityVariantPayload,
  DuplicateActivityVariantPayload,
  EditActivityVariantPayload,
  LoadActivityVariantPayload,
} from '../payloads/database-activity-variant';
import { DatabaseActivityVariantActionTypes } from '../types/database-activity-variant';

function createActivityVariant(
  databaseId: number,
  activityId: number,
  model: ActivityVariantForm,
  formId: string,
): ActionWith<CreateActivityVariantPayload> {
  return {
    type: DatabaseActivityVariantActionTypes.CREATE_ACTIVITY_VARIANT_REQUEST,
    payload: { databaseId, activityId, model, formId },
  };
}

function createActivityVariantResponse(
  model: ActivityVariantModel,
): ActionWith<ActivityVariantModel> {
  return {
    type: DatabaseActivityVariantActionTypes.CREATE_ACTIVITY_VARIANT_SUCCEEDED,
    payload: model,
  };
}

function loadActivityVariant(
  databaseId: number,
  activityId: number,
  activityVariantId: number,
): ActionWith<LoadActivityVariantPayload> {
  return {
    type: DatabaseActivityVariantActionTypes.GET_ACTIVITY_VARIANT_REQUEST,
    payload: {
      databaseId,
      activityId,
      activityVariantId,
    },
  };
}

function loadActivityVariantResponse(
  model: ActivityVariantModel,
): ActionWith<ActivityVariantModel> {
  return {
    type: DatabaseActivityVariantActionTypes.GET_ACTIVITY_VARIANT_SUCCEEDED,
    payload: model,
  };
}

function updateActivityVariant(
  databaseId: number,
  activityId: number,
  activityVariantId: number,
  model: ActivityVariantForm,
  formId: string,
): ActionWith<EditActivityVariantPayload> {
  return {
    type: DatabaseActivityVariantActionTypes.UPDATE_ACTIVITY_VARIANT_REQUEST,
    payload: { databaseId, activityId, activityVariantId, model, formId },
  };
}


function updateActivityVariantResponse(
  model: ActivityVariantModel,
): ActionWith<ActivityVariantModel> {
  return {
    type: DatabaseActivityVariantActionTypes.UPDATE_ACTIVITY_VARIANT_SUCCEEDED,
    payload: model,
  };
}

function deleteActivityVariant(
  databaseId: number,
  activityId: number,
  activityVariantId: number,
): ActionWith<DeleteActivityVariantPayload> {
  return {
    type: DatabaseActivityVariantActionTypes.DELETE_ACTIVITY_VARIANT_REQUEST,
    payload: { databaseId, activityId, activityVariantId },
  };
}

function deleteActivityVariantResponse(
  id: number,
): ActionWith<number> {
  return {
    type: DatabaseActivityVariantActionTypes.DELETE_ACTIVITY_VARIANT_SUCCEEDED,
    payload: id,
  };
}

function duplicateActivityVariant(
  databaseId: number,
  activityId: number,
  activityVariantId: number,
): ActionWith<DuplicateActivityVariantPayload> {
  return {
    type: DatabaseActivityVariantActionTypes.DUPLICATE_ACTIVITY_VARIANT,
    payload: { databaseId, activityId, activityVariantId },
  };
}


export const DatabaseActivityVariantActions = {
  createActivityVariant,
  createActivityVariantResponse,
  loadActivityVariant,
  loadActivityVariantResponse,
  updateActivityVariant,
  updateActivityVariantResponse,
  deleteActivityVariant,
  deleteActivityVariantResponse,
  duplicateActivityVariant,
};
