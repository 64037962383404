import { AnnotationLegendItem } from '../interfaces/annotation-legend-tree';
import { AnnotationLegendItemTypeguards } from './annotation-legend-item-typeguards';


function getNewVisibleItems(
  tree: AnnotationLegendItem[],
  openGroups: Record<string, boolean>,
  oldOpenGroups: Record<string, boolean>,
): AnnotationLegendItem[] {
  const result = [];
  const closedGroups = {};

  const openGroup = (innerInstances: AnnotationLegendItem[], groupId: string): void => {
    for (const item of innerInstances) {
      if (item.groupId === groupId || (openGroups[item.groupId] && !closedGroups[item.groupId])) {
        result.push(item);
      } else {
        closedGroups[item.id] = true;
      }
    }
  };

  for (const item of tree) {
    if (!item.groupId || (openGroups[item.groupId] && !closedGroups[item.groupId])) {
      result.push(item);
      if (AnnotationLegendItemTypeguards.isGroup(item) && !oldOpenGroups[item.id] && openGroups[item.id]) {
        openGroup(item.allInnerInstances, item.id);
      }
    } else {
      closedGroups[item.id] = true;
    }
  }
  return result;
}

export const DrawingAnnotationLegendUtils = {
  getNewVisibleItems,
};
