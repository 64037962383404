import React from 'react';

import { arrayUtils } from 'common/utils/array-utils';
import { IMenuProperties } from 'unit-2d-database/components/menu-properties/menu-properties';
import { VisibilityFilterType } from '../../../../interfaces';
import { Styled } from '../styled';
import { GroupPropertiesFrom } from './group-properties';
import { PropertyGroup } from './interfaces';


interface Props {
  parentId?: string;
  groups: Record<string, PropertyGroup>;
  onAddProperty?: () => void;
  visibilityMode: VisibilityFilterType;
  collapsedGroups: Set<string>;
  toggleGroup: (id: string, isOpen: boolean) => void;
  getId?: () => string;
  extraProperties?: IMenuProperties[];
  hideDeleteButton?: boolean;
  disabled?: boolean;
}

export class GroupPropertiesForm extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const {
      groups,
      visibilityMode,
      collapsedGroups,
      toggleGroup,
      onAddProperty,
      extraProperties,
      parentId,
      hideDeleteButton,
      disabled,
    } = this.props;
    const groupList = Object.values(groups);
    const sortGroupList = groupList.sort((ga, gb) => {
      if (ga.name === undefined) {
        return -1;
      }
      if (gb.name === undefined) {
        return 1;
      }
      return arrayUtils.localCompareWithNumber(ga.name, gb.name);
    });

    const hasItems = !!sortGroupList.length;

    return (
      <Styled.PropertyForm hasContent={hasItems}>
        {sortGroupList.map(group => {
          return (
            <GroupPropertiesFrom
              key={group.name}
              group={group}
              visibilityMode={visibilityMode}
              onAddProperty={onAddProperty}
              isOpen={!collapsedGroups.has(this.getKey(group.id)) || group.id === undefined}
              toggleGroup={toggleGroup}
              extraProperties={extraProperties}
              parentId={parentId}
              hideDeleteButton={hideDeleteButton}
              disabled={disabled}
              getId={this.props.getId}
            />
          );
        })}
      </Styled.PropertyForm>
    );
  }

  private getKey(id: string): string {
    return `${this.props.parentId}_${id}`;
  }
}
