import classNames from 'classnames';
import * as React from 'react';

import './measurements-extractor-item-inner-container.scss';

interface Props {
  className?: string;
  doubleInputs?: boolean;
}


export const MeasurementsExtractorItemInnerContainer: React.FC<Props> = ({ children, className, doubleInputs }) => {
  const containerClassName = classNames(
    'measurements-extractor-item-inner-container',
    className,
    {
      'measurements-extractor-input__double-input-container': doubleInputs,
    },
  );
  return (
    <div className={containerClassName}>
      {children}
    </div>
  );
};
