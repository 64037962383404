import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import './material-tile-menu.scss';

import { KreoColors } from 'common/enums/kreo-colors';
import { IconButton } from 'common/UIKit';
import { KreoIconActionBig } from 'common/UIKit/icons/action-big';

interface Props  {
  openBtnWhite?: boolean;
}
interface MenuState {
  isOpen: boolean;
  menuLeft: boolean;
}

export class MaterialTileMenu extends React.Component<Props, MenuState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      menuLeft: false,
    };
  }

  public componentWillUnmount(): void {
    if (this.state.isOpen) {
      document.removeEventListener('click', this.close);
    }
  }

  public render(): React.ReactNode {
    const classContent = classNames(
      'material-tile-menu__content',
      { 'material-tile-menu__content--left': this.state.menuLeft },
    );
    return (
      <div
        className='material-tile-menu'
      >
        <IconButton size='medium' onClick={this.open} >
          <KreoIconActionBig color={this.props.openBtnWhite ? KreoColors.white : KreoColors.f1} />
        </IconButton>
        <CSSTransition
          in={this.state.isOpen}
          mountOnEnter={this.state.isOpen}
          unmountOnExit={true}
          classNames='material-tile-menu__content'
          timeout={300}
        >
          <div className={classContent}>{this.props.children}</div>
        </CSSTransition>
      </div>
    );
  }

  public componentDidUpdate(): void {
    if (this.state.isOpen) {
      document.addEventListener('click', this.close);
    } else {
      document.removeEventListener('click', this.close);
    }
  }

  @autobind
  private open(event: React.MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
    if (180 >= document.documentElement.clientWidth - event.pageX) {
      this.setState({ isOpen: true, menuLeft: true });
    } else {
      this.setState({ isOpen: true });
    }
  }

  @autobind
  private close(): void {
    this.setState({ isOpen: false });
  }
}
