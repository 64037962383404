import { RowData } from 'common/components/data-base-table';
import { mapDate, mapUser } from 'unit-2d-database/helpers';
import { User } from 'unit-2d-database/interfaces';
import { ReportTemplateInfo } from 'unit-2d-report-template/interfaces';

export const getRows = (files: ReportTemplateInfo[], users: User[]): Record<string, RowData> => {
  const map: Record<string, RowData> = {};
  files.forEach(f => {
    map[f.id] = {
      h_isDragSource: false,
      h_isDragTarget: false,
      h_path: f.id.toString(),
      id: f.id,
      name: f.name,
      createdBy: mapUser(users.find(u => u.id === f.creatorId)),
      createdTime: mapDate(f.createdAt),
    };
  });
  return map;
};
