import { ActionWith } from 'common/interfaces/action-with';
import { ActivityGroupingActionTypes } from '../types';


function createActivityGroup(categoryId: number): ActionWith<number> {
  return {
    type: ActivityGroupingActionTypes.CREATE_ACTIVITY_GROUP,
    payload: categoryId,
  };
}

function createCategory(workPackageId: number): ActionWith<number> {
  return {
    type: ActivityGroupingActionTypes.CREATE_CATEGORY,
    payload: workPackageId,
  };
}

function renameActivityGroup(id: number, name: string): ActionWith<{ id: number, name: string }> {
  return {
    type: ActivityGroupingActionTypes.RENAME_ACTIVITY_GROUP,
    payload: { id, name },
  };
}

function renameCategory(id: number, name: string): ActionWith<{ id: number, name: string }> {
  return {
    type: ActivityGroupingActionTypes.RENAME_CATEGORY,
    payload: { id, name },
  };
}

function removeCategory(id: number): ActionWith<number> {
  return {
    type: ActivityGroupingActionTypes.REMOVE_CATEGORY,
    payload: id,
  };
}

function removeActivityGroup(id: number): ActionWith<number> {
  return {
    type: ActivityGroupingActionTypes.REMOVE_ACTIVITY_GROUP,
    payload: id,
  };
}

function replaceActivityGroup(
  activityGroupId: number,
  categoryId: number,
): ActionWith<{ activityGroupId: number, categoryId: number }> {
  return {
    type: ActivityGroupingActionTypes.REPLACE_ACTIVITY_GROUP,
    payload: { activityGroupId, categoryId },
  };
}

function replaceSelectedActivities(destinationActivityGroupId: number): ActionWith<number> {
  return {
    type: ActivityGroupingActionTypes.REPLACE_SELECTED_ACTIVITIES,
    payload: destinationActivityGroupId,
  };
}


function replaceActivity(
  activityId: number,
  activityGroupId: number,
): ActionWith<{ activityId: number, activityGroupId: number }> {
  return {
    type: ActivityGroupingActionTypes.REPLACE_ACTIVITY,
    payload: { activityGroupId, activityId },
  };
}

function setDraggingActivityGroup(id: number): ActionWith<number> {
  return {
    type: ActivityGroupingActionTypes.SET_DRAGGING_ACTIVITY_GROUP,
    payload: id,
  };
}

function setDraggingActivity(id: number): ActionWith<number> {
  return {
    type: ActivityGroupingActionTypes.SET_DRAGGING_ACTIVITY,
    payload: id,
  };
}

function setSelectedActivityIds(ids: Record<number, boolean>): ActionWith<Record<number, boolean>> {
  return {
    type: ActivityGroupingActionTypes.SET_SELECTED_ACTIVITY_IDS,
    payload: ids,
  };
}

function mergeActivityGroups(
  destinationGroupId: number, mergedGroupId: number,
): ActionWith<{ destinationGroupId: number, mergedGroupId: number }> {
  return {
    type: ActivityGroupingActionTypes.MERGE_ACTIVITY_GROUPS,
    payload: { destinationGroupId, mergedGroupId },
  };
}

export const ActivityGroupingActions = {
  createActivityGroup,
  createCategory,
  renameActivityGroup,
  renameCategory,
  removeCategory,
  removeActivityGroup,
  replaceActivityGroup,
  replaceActivity,
  replaceSelectedActivities,
  setDraggingActivityGroup,
  setDraggingActivity,
  setSelectedActivityIds,
  mergeActivityGroups,
};
