import { Constants } from '@kreo/kreo-ui-components';
import { arrayUtils } from 'common/utils/array-utils';
import { DEFAULT_FUNCTIONS, FormulaDefaultFunctions } from '../../constants';
import { FormulaPartTypes } from '../../enums';
import { FormulaPart } from '../../interfaces';


const OPERATION_SYMBOLS = /[-+*\/<>=]/g;
const COMMA_SPLITTER = /,/g;
const SPACE_REGEX = /\s/g;
const FIRST_WORD_REGEX = /^([^\w])*([\w\-]+)/;
const FORMULA_PROPERTIES_EXTRACTORS = /\[(.*?)\]/gm;
const MEASURED_FUNCTIONS_REGEX = new RegExp(`${DEFAULT_FUNCTIONS.join('()|')}()`, 'g');

const closersTo: Record<string, string> = {
  '[': ']',
  '(': ')',
  '\"': '\"',
};

const SplitterType: Record<string, FormulaPartTypes> = {
  '[': FormulaPartTypes.Property,
  '(': FormulaPartTypes.Function,
  '\"': FormulaPartTypes.Constant,
};

type ClosePartCallback = (
  index: number,
  partType: FormulaPartTypes,
  closedScope?: boolean,
  children?: FormulaPart[]
) => void;

function getPropertyName(text: string): string {
  return text.replace(/(\[|\])/g, '');
}

const TypeBackgroundColor: Record<string, string> = {
  [FormulaPartTypes.MeasureFunction]: '#55E184',
  [FormulaPartTypes.FunctionSplitted]: '#E1E155',
  [FormulaPartTypes.Property]: '#5555e1',
};


function addColorsToSpan(span: HTMLSpanElement, type: FormulaPartTypes): void {
  const typeColor = TypeBackgroundColor[type];
  if (typeColor) {
    span.style.color = Constants.Colors.GENERAL_COLORS.darkBlue;
    span.style.backgroundColor = typeColor;
  }
}

function makeSpan(content: string | FormulaPart[], type: FormulaPartTypes): HTMLSpanElement {
  const span = document.createElement('span');
  if (Array.isArray(content)) {
    for (const child of content) {
      span.appendChild(child.span);
    }
  } else {
    const symbolSpanValue = document.createTextNode(content);
    span.appendChild(symbolSpanValue);
  }
  addColorsToSpan(span, type);
  return span;
}

function getFunctionInfo({ children, text }: FormulaPart): { name: string, argsCount: number } {
  if (children) {
    return {
      name: children[0].text.substring(0, children[0].text.length - 1).replace(SPACE_REGEX, ''),
      argsCount: children.filter(x => x.type === FormulaPartTypes.Splitter).length + 1,
    };
  } else {
    return {
      name: text.replace(/[\(\)\s]/g, ''),
      argsCount: 0,
    };
  }
}

function getClosePart(text: string, startScopeIndex: number = 0): [FormulaPart[], ClosePartCallback] {
  const partsIndices = new Array<FormulaPart>();
  const closePart: ClosePartCallback = (index, partType, closedScope?, children?) => {
    const lastPart = partsIndices[partsIndices.length - 1];
    const startIndex = lastPart ? lastPart.bounds[1] : startScopeIndex;
    if (index + 1 === startIndex) {
      return;
    }
    if (partType === FormulaPartTypes.Function) {
      splitRoundBraceredScope(text, startIndex, index, closePart, closedScope);
    } else {
      const partText = text.substring(startIndex, index + 1);
      const part: FormulaPart =  {
        type: partType,
        bounds: [startIndex, index + 1],
        text: partText,
      };
      if (children) {
        part.children = children;
        part.span = makeSpan(children, partType);
      } else {
        part.span = makeSpan(partText, partType);
      }
      partsIndices.push(part);
    }
  };
  return [ partsIndices, closePart];
}

function processSymbols(
  text: string,
  startIndex: number,
  endIndex: number,
  closePart: ClosePartCallback,
  additionalSplitters?: RegExp,
): FormulaPartTypes {
  let openedBracer = false;
  let closer: string = null;
  let partType: FormulaPartTypes = FormulaPartTypes.Constant;
  let openedFunctionCount = 0;
  let isStringIntoFunctionOpened = false;
  for (let i = startIndex; i <= endIndex; i++) {
    const symbol = text[i];
    if (openedBracer) {
      if (!isStringIntoFunctionOpened) {
        if (symbol === closer) {
          if (openedFunctionCount) {
            openedFunctionCount--;
          } else {
            openedBracer = false;
            closePart(i, partType, true);
            partType = FormulaPartTypes.Constant;
          }
        } else if (symbol === '(' && partType !== FormulaPartTypes.Property) {
          openedFunctionCount++;
        }
      }
      if (partType === FormulaPartTypes.Function) {
        if (symbol === '"') {
          isStringIntoFunctionOpened = !isStringIntoFunctionOpened;
        }
      }
    } else if (symbol in closersTo) {
      closer = closersTo[text[i]];
      openedBracer = true;
      if (symbol !== '(') {
        closePart(i - 1, partType);
        partType = SplitterType[symbol];
      } else {
        if (partType === FormulaPartTypes.Spaces) {
          closePart(i - 1, partType);
        }
        partType = FormulaPartTypes.Function;
        openedFunctionCount = 0;
      }
    } else if (symbol.match(OPERATION_SYMBOLS)) {
      closePart(i - 1, partType);
      closePart(i, FormulaPartTypes.Operation);
    } else if (additionalSplitters && symbol.match(additionalSplitters)) {
      closePart(i - 1, partType);
      closePart(i, FormulaPartTypes.Splitter);
    } else if (symbol.match(SPACE_REGEX)) {
      if (partType !== FormulaPartTypes.Spaces) {
        closePart(i - 1, partType);
        partType = FormulaPartTypes.Spaces;
      }
    } else if (partType === FormulaPartTypes.Spaces) {
      closePart(i - 1, partType);
      partType = FormulaPartTypes.Constant;
    }
  }
  return partType;
}


function processChildScope(
  text: string,
  startIndex: number,
  endIndex: number,
  startScopeIndex: number,
  openType: FormulaPartTypes,
  closeType: FormulaPartTypes,
  isScopeClosed: boolean,
  additionalSplitters?: RegExp,
): FormulaPart[] {
  const [children, childClosePart] = getClosePart(text, startIndex);
  childClosePart(startIndex + startScopeIndex, openType, true, children.length ? children : null);
  const lastType = processSymbols(
    text,
    startIndex + startScopeIndex + 1,
    endIndex + Number(!isScopeClosed) - 1,
    childClosePart,
    additionalSplitters,
  );
  childClosePart(endIndex + Number(!isScopeClosed) - 1, lastType);
  if (isScopeClosed) {
    childClosePart(endIndex, closeType);
  }
  return children;
}

function processScope(
  text: string,
  startIndex: number,
  endIndex: number,
  substring: string,
  closedScope: boolean,
  closePart: ClosePartCallback,
  partType: FormulaPartTypes,
  additionalSplitters?: RegExp,
): void {
  const isFunction = partType === FormulaPartTypes.FunctionSplitted;
  const startScopeIndex = substring.indexOf('(');
  const endScopeIndex = closedScope ? substring.length - 2 : substring.length - 1;
  const scopeSubstring = substring.substring(startScopeIndex + 1, endScopeIndex + 1);
  if (startScopeIndex !== endScopeIndex && scopeSubstring.length) {
    const children = processChildScope(
      text,
      startIndex,
      endIndex,
      startScopeIndex,
      isFunction ? FormulaPartTypes.FunctionOpen : FormulaPartTypes.ScopeOpen,
      isFunction ? FormulaPartTypes.FunctionClose : FormulaPartTypes.ScopeClose,
      closedScope,
      additionalSplitters,
    );
    closePart(endIndex, partType, closedScope, children.length ? children : null);
  } else {
    closePart(endIndex, partType);
  }
}

function splitRoundBraceredScope(
  text: string,
  startIndex: number,
  endIndex: number,
  closePart: ClosePartCallback,
  closedScope: boolean,
): void {
  const substring = text.substring(startIndex, endIndex + 1);
  const name = substring.match(FIRST_WORD_REGEX);
  const withoutSpaces = substring.replace(SPACE_REGEX, '');
  if (withoutSpaces[0] === '(') {
    processScope(
      text,
      startIndex,
      endIndex,
      substring,
      closedScope,
      closePart,
      FormulaPartTypes.RoundBracerScope,
    );
  } else if (name && DEFAULT_FUNCTIONS.includes(name[2] as FormulaDefaultFunctions)) {
    closePart(endIndex, FormulaPartTypes.MeasureFunction);
  } else {
    processScope(
      text,
      startIndex,
      endIndex,
      substring,
      closedScope,
      closePart,
      FormulaPartTypes.FunctionSplitted,
      COMMA_SPLITTER,
    );
  }
}

function splitToVariables(text: string): FormulaPart[] | null {
  const [partsIndices, closePart] = getClosePart(text);

  const lastPartType = processSymbols(text, 0, text.length - 1, closePart);
  closePart(text.length - 1, lastPartType, false);
  return partsIndices;
}

function extractUsedPropertiesNames(formula: string): string[] {
  return arrayUtils.uniq(formula.match(FORMULA_PROPERTIES_EXTRACTORS)).map(x => x.slice(1, x.length - 1));
}

function extractUsedMeasuredFunction(formula: string): string[] {
  return arrayUtils.uniq(formula.match(MEASURED_FUNCTIONS_REGEX));
}

function *iterateFormulaParts(parts: FormulaPart[]): IterableIterator<FormulaPart> {
  for (const part of parts) {
    const passByType = part.type === FormulaPartTypes.FunctionSplitted
      || part.type === FormulaPartTypes.RoundBracerScope;
    if (passByType && part.children) {
      for (const child of iterateFormulaParts(part.children)) {
        yield child;
      }
    } else {
      yield part;
    }
  }
}

function *formulaIterator(text: string): IterableIterator<FormulaPart> {
  const variables = splitToVariables(text);
  for (const part of iterateFormulaParts(variables)) {
    yield part;
  }
}

export const FormulaInputParsers = {
  getPropertyName,
  getFunctionInfo,
  splitToVariables,
  extractUsedPropertiesNames,
  extractUsedMeasuredFunction,
  formulaIterator,
};
