import * as React from 'react';


interface MenuItemProps {
  title: string;
  icons: any[];
  onClick: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  return (
    <div className='viewer-context-item' key={1} onClick={props.onClick}>
      <span className='viewer-context-title'>{props.title}</span>
      {
        props.icons.map((x: any): JSX.Element => x)
      }
      {props.children}
    </div>
  );
};
