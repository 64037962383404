import styled from 'styled-components';


const FolderContainerWrapper = styled.div`
  > div {
    align-items: center;
  }
`;

export const Styled = {
  FolderContainerWrapper,
};
