import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { TwoDActions } from '../../../../units/2d/actions/creators';
import { TwoDApi } from '../../../../units/2d/api';
import { DrawingsAnnotationActions } from '../actions/creators/annotation';
import { DrawingsAnnotationLegendActions } from '../actions/creators/drawings-annotation-legend';

export function* reloadAfterRecognition(projectId: number, fileId: string, pageId: string): SagaIterator {
  yield put(DrawingsAnnotationActions.getAnnotationData(projectId, fileId, pageId));
  yield put(DrawingsAnnotationLegendActions.loadGroups(projectId));
  const data = yield call(TwoDApi.getPages, projectId);
  yield put(TwoDActions.setPages(data));
}
