import * as Ag from 'ag-grid-community';

import { DrawingsGeometryGroup } from 'common/components/drawings';
import { StringColumnDef } from '../constants';
import { getGroupPath } from './get-group-path';

export const GROUP_HIERARCHY_PREFIX = 'groupHierarchy';
export const GROUP_ID_POSTFIX = 'GROUP_ID_POSTFIX';

const extendsByGroupHierarchy = (
  data: Record<string, string>,
  columns: Record<string, Ag.ColDef>,
  groupId: string,
  groupsMap: Record<string, DrawingsGeometryGroup>,
  groupPathMap: Record<string, string[]>,
): void => {
  getGroupPath(groupId, groupsMap, [], groupPathMap).forEach((value, index) => {
    const [field, groupName, column, innerGroupId] = getPathInfo(index, value, groupsMap);
    data[field] = groupName;
    data[`${field}${GROUP_ID_POSTFIX}`] = innerGroupId;
    if (!columns[column.colId]) {
      columns[column.colId] = column;
    }
  });
};

export const getGroupExtender = (
  groupsMap: Record<string, DrawingsGeometryGroup>,
): [
  (data: Record<string, string>, groupId: string) => void,
  Record<string, Ag.ColDef>,
] => {
  const groupHierarchyColumns: Record<string, Ag.ColDef> = {};
  const groupPathMap: Record<string, string[]> = {};

  const extender = (data: Record<string, string>, groupId: string): void =>
    extendsByGroupHierarchy(data, groupHierarchyColumns, groupId, groupsMap, groupPathMap);

  return [extender, groupHierarchyColumns];
};

const undefinedNameValue = (name: string): string => `Undefined ${name}`;

export const extendDataByEmptyGroup = (
  data: Record<string, any>,
  hierarchy: Record<string, Ag.ColDef>,
): Record<string, any> => {
  const update = {};
  Object.values(data).forEach(d => {
    Object.values(hierarchy).forEach(h => {
      if (!d[h.field]) {
        d[h.field] = undefinedNameValue(h.headerName);
        update[d.id] = d;
      }
    });
  });
  return update;
};

export const removeEmptyData = (
  data: Record<string, any>,
  hierarchy: Record<string, Ag.ColDef>,
): Record<string, any> => {
  const update = {};
  Object.values(data).forEach(d => {
    Object.values(hierarchy).forEach(h => {
      if (d[h.field] && `${d[h.field]}` === undefinedNameValue(h.headerName)) {
        delete d[h.field];
        update[d.id] = d;
      }
    });
  });
  return update;
};

const getFieldId = (index: number): string => `${GROUP_HIERARCHY_PREFIX}${index}`;
const getName = (index: number): string => `Level ${index + 1} Grouping`;

export const getColumn = (index: number): Ag.ColDef => {
  const field = getFieldId(index);

  return {
    colId: field,
    field,
    headerName: getName(index),

    ...StringColumnDef,
  };
};

function getPathInfo(
  index: number,
  groupId: string,
  groupsMap: Record<string, DrawingsGeometryGroup>,
): [string, string, Ag.ColDef, string] {
  const field = getFieldId(index);
  const groupName = groupsMap[groupId].name;
  const column = getColumn(index);
  return [field, groupName, column, groupId];
}
