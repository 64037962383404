import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const Container = styled.div`
  ${Text} {
    font-family: Light;
    text-align: center;
    word-break: normal;
    line-height: 18px;
    font-size: 12px;
  }
`;

export const Styled = {
  Container,
};
