import classNames from 'classnames';
import * as React from 'react';

import { KreoIconExpand, KreoIconExpandSmall } from 'common/UIKit';

interface Props {
  collapsed: boolean;
  level: number;
}

export const CollapseIcon: React.FC<Props> = props => {
  const className = classNames('cost-estimate-table__collapse-icon', { expanded: !props.collapsed });
  return (
    <div className={className}>
      {props.level === 1 ? <KreoIconExpand /> : <KreoIconExpandSmall />}
    </div>
  );
};
