import * as Ag from 'ag-grid-community';

import { AgGridHelper } from 'common/ag-grid';
import { DrawingsElementTemplatesConstants } from 'common/components/drawings/constants/drawing-element-templates';
import { TIME_LAG_BEFORE_GROUP_EXPAND } from 'common/components/tree-table/constants';
import {
  TreeTableRowAddModel,
  TreeTableRowType,
} from 'common/components/tree-table/interfaces';
import {
  TreeTableColumnController,
  TreeTableColumnWithId,
} from 'common/components/tree-table/tree-table-column-controller';
import { TreeTableRowController } from 'common/components/tree-table/tree-table-row-controller';
import {
  AgGridDataTransferExporter, AgGridDataTransferImporter, MapperType,
} from 'common/utils/ag-grid-data-transporter';
import { DropEffectValues, GetDropEffect } from 'common/utils/ag-grid-data-transporter/ag-grid-data-importer';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { GraphStorageRecordsConfig } from '../../interfaces/graph-storage-records-config';
import { QtoTreeRowProperty } from '../../interfaces/quantity-take-off/quantity-take-off-tree-row-property';
import { PropertyHelper, TreeTableAgg } from '../../utils/quantity-take-off-tree-table';
import { QtoReportTableGroupRules } from './quantity-take-off-report-table-group-rules';

const transferKey = 'REPORT_TABLE_DATA_TRANSFER';

export interface TreeTableTransferData {
  rows: Array<TreeTableRowAddModel<QtoTreeRowProperty>>;
  columns: Array<TreeTableColumnWithId<QtoTreeRowProperty>>;
}

type OnImportFuncType = (data: TreeTableTransferData, api: Ag.GridApi, targetId: string) => void;


const getOnImportFunc = (
  rowController: TreeTableRowController<QtoTreeRowProperty>,
  columnController: TreeTableColumnController<QtoTreeRowProperty>,
  configs: GraphStorageRecordsConfig,
): OnImportFuncType => {
  return (data: TreeTableTransferData, api: Ag.GridApi, targetId: string) => {
    rowController.addRows(data.rows, targetId);
    if (targetId) {
      api.getRowNode(targetId).setExpanded(true);
    }

    const columnModel = columnController.getColumnModel();
    const newColumns = [];
    for (const column of data.columns) {
      if (columnModel.columns[column.id]) {
        continue;
      }
      const isVisible = (configs.extractors && (column.id in configs.extractors))
        || (column.id in DrawingsElementTemplatesConstants.quantitiesKeys);
      column.properties = {
        ...column.properties,
        [PropertyHelper.columnProperties.isVisible]: { default: isVisible },
        [PropertyHelper.columnProperties.aggregationStrategy]: { default: TreeTableAgg.Sum },
      };

      newColumns.push(column);
    }
    columnController.addColumns(newColumns);
  };
};

const getRowsType = (rows: Array<TreeTableRowAddModel<QtoTreeRowProperty>>): TreeTableRowType | null => {
  let rowType = null;
  for (const row of rows) {
    if (!rowType) {
      rowType = row.type;
    } else {
      if (row.type !== rowType) {
        return null;
      }
    }
  }

  return rowType;
};

const getDropEffect = (data: TreeTableTransferData, targetRow: Ag.RowNode): DropEffectValues => {
  if (!data) {
    return 'none';
  }

  const rowType = getRowsType(data.rows);
  if (!rowType) {
    return 'none';
  }

  const groupRules = new QtoReportTableGroupRules();
  if (rowType === TreeTableRowType.Group && groupRules.availableInsertInto(groupRules.EmptyGroupNode, targetRow)) {
    return 'move';
  }

  if (rowType === TreeTableRowType.Element && groupRules.availableInsertInto(groupRules.EmptyElementNode, targetRow)) {
    return 'move';
  }

  return 'none';
};


const getGetDropEffectFunc = (): GetDropEffect<TreeTableTransferData> => {
  const deferredExecutor: DeferredExecutor = new DeferredExecutor(TIME_LAG_BEFORE_GROUP_EXPAND);
  let lastTarget: Ag.RowNode;

  const getDropEffectFunc = (data: TreeTableTransferData, api: Ag.GridApi, targetId: string): DropEffectValues => {
    const targetRow = targetId ? api.getRowNode(targetId) : AgGridHelper.getRootNode(api);
    const dropEffect = getDropEffect(data, targetRow);
    if (targetRow !== lastTarget) {
      deferredExecutor.execute(() => targetRow.setExpanded(true));
      lastTarget = targetRow;
    }

    return dropEffect;
  };
  return getDropEffectFunc;
};


export class ReportTableImporter extends AgGridDataTransferImporter<TreeTableTransferData> {
  constructor(
    rowController: TreeTableRowController<QtoTreeRowProperty>,
    columnController: TreeTableColumnController<QtoTreeRowProperty>,
    configs: GraphStorageRecordsConfig,
  ) {
    const onImport = getOnImportFunc(rowController, columnController, configs);
    const getGetDropEffect = getGetDropEffectFunc();
    super(transferKey, onImport, getGetDropEffect);
  }
}

export class ReportTableExporter extends AgGridDataTransferExporter<TreeTableTransferData> {
  constructor(mapper: MapperType<TreeTableTransferData>) {
    super(transferKey, mapper);
  }
}
