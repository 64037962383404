import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconAddCommentMark: React.FC = () => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          // eslint-disable-next-line max-len
          d='M10.6005638,10.7250158 C11.9388377,9.39699773 13,7.90743152 13,5.95833333 C13,2.6676367 10.3137085,0 7,0 C3.6862915,0 1,2.6676367 1,5.95833333 C1,7.90770001 2.06162378,9.39705919 3.40003835,10.725465 C4.2,11.5194444 6,13.5714286 6,16 C6,16.5 6,17 6,17.5 L6,17.5 C6,17.7761424 6.22385763,18 6.5,18 L7.5,18 C7.77614237,18 8,17.7761424 8,17.5 C8,17 8,16.5 8,16 C8,13.5714286 9.8,11.5194444 10.6005638,10.7250158 Z'
          id='path-1-add-comment-mark'
        />
      </defs>
      <g
        id='Buttons/Float/icons/add-comment-mark'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Comment-1' transform='translate(3.000000, 1.000000)'>
          <path
            // eslint-disable-next-line max-len
            d='M7,19 C8.65685425,19 10,18.5522847 10,18 C10,17.4477153 8.65685425,17 7,17 C5.34314575,17 4,17.4477153 4,18 C4,18.5522847 5.34314575,19 7,19 Z'
            id='Oval'
            fillOpacity='0.140000001'
            fill={KreoColors.f2}
          />
          <mask id='mask-2' fill='white'>
            <use xlinkHref='#path-1-add-comment-mark' />
          </mask>
          <use id='Combined-Shape' fill='#5C8AE6' xlinkHref='#path-1-add-comment-mark' />
        </g>
      </g>
    </svg>
  );
};
