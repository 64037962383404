import styled from 'styled-components';


const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  border: 1px solid ${p => p.theme.color.background};
  border-radius: 20px;
  margin-bottom: 10px;
`;

const Img = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;

  > img {
    width: 60px;
    height: 60px;
    border-radius: 15px;
  }
`;

const InfoText = styled.div`
  width: 185px;
`;

export const Styled = {
  InfoBlock,
  Img,
  InfoText,
};
