import * as Ag from 'ag-grid-community';
import { AgGridHelper } from 'common/ag-grid';

import { MetricUnitConversationMap } from 'common/utils/unit-util';
import { ValueHelper } from 'common/utils/value-helper';
import {
  QtoTreeTableCommon,
} from '../../components/quantity-take-off-report-table/quantity-take-off-tree-table-common';
import { QtoTreeRowProperty } from '../../interfaces/quantity-take-off';
import { PropertyHelper, QtoColumnPropertyHelper } from '../quantity-take-off-tree-table';
import { QuantityTakeOffFormulaHelper } from '../quantity-take-off-tree-table/formula-helper';

function processCellForClipboard(params: Ag.ProcessCellForExportParams): string | number {
  const columnId = params.column.getColId();
  if (columnId === AgGridHelper.constants.autoColumnId) {
    return params.value;
  }
  const property = params.node.data.properties[columnId];
  if (PropertyHelper.isAggregation(property)) {
    return 'aggregation_key';
  }
  const value = PropertyHelper.getActualValue<string>(property);
  return ValueHelper.isNumberValue(value)
    ? getConvertedValue(Number(value), params)
    : value;
}

function getConvertedValue(value: number, params: Ag.ProcessCellForExportParams): number {
  const unit = QtoColumnPropertyHelper.getColumnUnit(params.column);
  const isImperial = params.context.isImperial;
  return QtoTreeTableCommon.metricValueConverter(value, unit, isImperial);
}

function replaceFormulaLinks(formula: string, columnApi: Ag.ColumnApi): string {
  const result = formula
    .replace(
      QuantityTakeOffFormulaHelper.formulaVariableRegex,
      (_match, header) => {
        const colDef = columnApi.getAllColumns().find((column) => {
          return column.getColDef().headerName.toUpperCase() === header.toUpperCase();
        });
        return colDef
          ? `[${colDef.getColId()}]`
          : `[${header}]`;
      });

  return result;
}

function processCellFromClipboard(params: Ag.ProcessCellForExportParams): QtoTreeRowProperty {
  if (params.value === 'aggregation_key') {
    return {};
  }
  const data = params.node.data.properties[params.column.getColId()];

  if (params.value === '') {
    delete params.node.data.properties[params.column.getColId()];

    return undefined;
  }

  const result = { ...data, override: params.value };

  if (params.value.startsWith('=')) {
    result.override = replaceFormulaLinks(params.value, params.columnApi);
  }

  if (ValueHelper.isNumberValue(params.value)) {
    const numberValue = Number(params.value);
    if (params.context.isImperial) {
      result.override = getValueFormConverted(numberValue, params);
    } else {
      result.override = numberValue;
    }
  }

  return result;
}

function getValueFormConverted(value: number, params: Ag.ProcessCellForExportParams): number {
  const unit = QtoColumnPropertyHelper.getColumnUnit(params.column);
  const isImperial = params.context.isImperial;
  return QtoTreeTableCommon.imperialValueConverter(value, MetricUnitConversationMap[unit], isImperial);
}

export const QtoTreeTableCopyPastHelper = {
  processCellForClipboard,
  processCellFromClipboard,
};
