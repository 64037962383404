import { Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';


interface Props {
  img: string;
  message: string;
}


export const MessageContent: React.FC<Props>  = ({ img, message }: Props) => {
  return (
    <Styled.Container>
      <Styled.Avatar>
        <img src={img} />
      </Styled.Avatar>
      <Styled.Message>
        <Text>{message}</Text>
      </Styled.Message>
    </Styled.Container>
  );
};
