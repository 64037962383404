import React, { useCallback } from 'react';
import {
  AnnotationLegendGroupColorIndicator,
  AnnotationLegendGroupCount,
  AnnotationLegendGroupStyled,
  AnnotationLegendGroupTitleWrapper,
} from 'common/components/drawings/drawings-annotation-legend/drawings-annotation-legend-group';
import {
  DrawingsAnnotationLegendUserInfo,
} from 'common/components/drawings/drawings-annotation-legend/drawings-annotation-legend-user-info';
import { EntityLabel } from 'common/components/drawings/drawings-annotation-legend/entity-label';
import { DrawingsGeometryGroupWithNesting } from 'common/components/drawings/interfaces';
import { ConstantFunctions } from 'common/constants/functions';

interface Props {
  group: DrawingsGeometryGroupWithNesting;
  collapsed: boolean;
  selected: boolean;
  hasAssignedPia: boolean;
  hasInheritedPia: boolean;
  toggleCollapse: (groupId: string) => void;
  onSelect: (groupId: string) => void;
}

const DialogContentGroupComponent: React.FC<Props> = (
  {
    group,
    collapsed,
    selected,
    toggleCollapse,
    onSelect,
    hasAssignedPia,
    hasInheritedPia,
  },
) => {
  const handleCollapse = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ConstantFunctions.stopEvent(e);
      toggleCollapse(group.id);
    },
    [group.id, toggleCollapse],
  );

  const handleSelect = useCallback(() => {
    onSelect(group.id);
  }, [group.id, onSelect]);

  return (
    <AnnotationLegendGroupStyled.Container
      nestingCount={group.nestingCount}
      isSelected={selected}
      onClick={handleSelect}
    >
      <AnnotationLegendGroupTitleWrapper
        isOpen={!collapsed}
        onOpenChange={handleCollapse}
        disableEdit={true}
        name={group.name}
        hideTitle={false}
        onNameChange={ConstantFunctions.doNothing}
        getContainerTitleRef={ConstantFunctions.doNothing}
        hideOpenToggleIcon={false}
        setRenameMode={ConstantFunctions.doNothing}
      />
      <AnnotationLegendGroupStyled.ItemsContainer>
        <AnnotationLegendGroupCount count={group.drawingsCount} />
        <AnnotationLegendGroupColorIndicator
          color={group.color}
          hide={false}
          openColorPicker={ConstantFunctions.doNothing}
          canEditMeasurement={false}
        />
        <DrawingsAnnotationLegendUserInfo
          creatorId={group.creatorId}
          createdAt={group.createdAt}
        />
        <EntityLabel
          selected={false}
          inherited={hasInheritedPia}
          assigned={hasAssignedPia}
        />
      </AnnotationLegendGroupStyled.ItemsContainer>
    </AnnotationLegendGroupStyled.Container>
  );
};

export const DialogContentGroup = React.memo(DialogContentGroupComponent);
