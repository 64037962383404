import autobind from 'autobind-decorator';
import { DatadogLogger } from 'common/environment/datadog-logger';
import { KeyCloakService } from '../../../units/account/keycloak';
import { ConnectionClient } from '../interfaces/connection-client';
import { SignalRLogger } from './signalr-logger';

@autobind
export class SignalRConnectionClient implements ConnectionClient {
  private _connection: SignalR.HubConnection;
  private _connected: boolean;
  private _stoped: boolean;
  private _onCloseCallback: () => void;

  private _invokesAfterInit: Array<() => void> = [];

  public set onCloseCallback(value: () => void) {
    this._onCloseCallback = value;
  }

  public init(hubUrl: string): Promise<SignalRConnectionClient> {
    return new Promise((resolve, reject) => {
      this.createConnection(hubUrl)
        .then(resolve)
        .catch((error) => {
          console.warn('SignalR connection error', error);
          DatadogLogger.warn('SignalR connection error', error);
          if (this._stoped) {
            reject(error);
            return;
          }
          this.createConnection(hubUrl)
            .then(resolve)
            .catch(reject);
        });
    }).then(() => {
      this._connected = true;
      for (const invoke of this._invokesAfterInit) {
        invoke();
      }
      this._invokesAfterInit = [];
      return this;
    });
  }

  public invoke(methodName: string, ...args: any[]): Promise<void> {
    if (this._connected) {
      return this._connection.invoke(methodName, ...args);
    } else {
      return new Promise((resolve) => {
        this._invokesAfterInit.push(() => this._connection.invoke(methodName, ...args).then(resolve));
      });
    }
  }

  public stop(): Promise<void> {
    this._stoped = true;
    if (!this._connected) {
      return Promise.resolve();
    }
    this._onCloseCallback = null;
    this._connected = false;
    this._invokesAfterInit = [];
    return this._connection.stop();
  }

  public subscribe(eventKey: string, listener: (...args: any) => void): void {
    if (!this._connected) {
      this._invokesAfterInit.push(() => this._connection.on(eventKey, listener));
    } else {
      this._connection.on(eventKey, listener);
    }
  }

  public unsubscribe(eventKey: string): void {
    this._connection.off(eventKey);
  }

  private onClose(): void {
    if  (this._onCloseCallback) {
      this._onCloseCallback();
    }
  }

  private createConnection(hubUrl: string): Promise<void> {
    this._connection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        transport: process.env.NODE_ENV === 'development'
          ? signalR.HttpTransportType.LongPolling
          : signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => KeyCloakService.getTokenAsync(),
        logger: SignalRLogger.getInstance(),
      }).build();
    this._connection.onclose(this.onClose);
    return this._connection.start();
  }
}
