import { arrayUtils } from 'common/utils/array-utils';
import {
  AssignAssemblyPatch,
  AssignedPia,
  AssignedPiaAssembly,
  AssignedPiaItem,
  AssignItemPatch,
  AssignPiaPatch,
} from '../../interfaces';


function updateEntities<T extends { name: string }, K extends { name: string }>(
  entities: T[],
  added: T[],
  updated: K[],
  deleted: string[],
  updater: (source: T, patch: K) => T,
): T[] {
  if (!added && !updated && !deleted) {
    return entities;
  }

  let result = new Array<T>();

  if (entities) {
    if (updated || deleted) {
      const updatePatchByName: Record<string, K> = {};
      const getPatchByName = (name: string): K => {
        if (!(name in updatePatchByName)) {
          updatePatchByName[name] = updated?.find((value) => value.name === name);
        }
        return updatePatchByName[name];
      };

      for (const entity of entities) {
        if (deleted && deleted.includes(entity.name)) {
          continue;
        }
        const updatePatch = getPatchByName(entity.name);
        if (updatePatch) {
          const updatedEntity = updater(entity, updatePatch);
          if (!updatedEntity) {
            continue;
          }
          result.push(updatedEntity);
        } else {
          result.push(entity);
        }
      }
    } else {
      result = entities.slice();
    }
  }
  if (added) {
    arrayUtils.extendArray(result, added);
  }
  return result;
}


function updateItem(item: AssignedPiaItem, patch: AssignItemPatch): AssignedPiaItem {
  const properties = updateEntities(
    item.properties,
    patch.addedProperties,
    patch.updatedProperties,
    patch.deletedProperties,
    (_source, propertyUpdate) => propertyUpdate,
  );
  if (!properties.length) {
    return;
  }
  return {
    ...item,
    name: patch.name,
    iconType: patch.iconType,
    properties,
  };
}

function updateAssembly(assembly: AssignedPiaAssembly, patch: AssignAssemblyPatch): AssignedPiaAssembly {
  const items = updateEntities(assembly.items, patch.addedItems, patch.updatedItems, patch.deletedItems, updateItem);
  if (!items.length) {
    return;
  }
  return {
    ...assembly,
    name: patch.name,
    items,
  };
}

export function* patchPiaData(
  assignedPia: Record<string, AssignedPia>,
  patch: AssignPiaPatch,
): IterableIterator<{ id: string, assignedPia: AssignedPia }> {
  for (const id of patch.ids) {
    const currentPia = assignedPia[id];
    const items = updateEntities(
      currentPia?.items,
      patch.addedItems,
      patch.updatedItems,
      patch.deletedItems,
      updateItem,
    ) || [];
    const assemblies = updateEntities(
      currentPia?.assemblies,
      patch.addedAssemblies,
      patch.updatedAssemblies,
      patch.deletedAssemblies,
      updateAssembly,
    ) || [];
    yield { id, assignedPia: { items, assemblies } };
  }
}
