import styled from 'styled-components';

const Container = styled.div`
  width: fit-content;
  margin-top: 40px;

  button {
    > p {
      color: ${p => p.theme.color.gray};
    }

    :hover {
      > p {
        color: ${p => p.theme.color.turquoise} !important;
      }
    }
  }
`;

export const Styled = {
  Container,
};
