import { reset, startSubmit, stopSubmit } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { getGetErrorModelFromValidationResponse } from 'common/utils/redux-form-utils';
import { NotificationActions } from '../../../units/notifications/actions';
import { AlertType } from '../../../units/notifications/alert-type';
import { DatabaseActivityListingActions } from '../actions/creators/database-activity-listing';
import { DatabaseResourceVariantActions } from '../actions/creators/database-resource-variant';
import {
  CreateResourceVariantPayload,
  DeleteResourceVariantPayload,
  EditResourceVariantPayload,
  LoadResourceVariantPayload,
} from '../actions/payloads/database-resource-variant';
import { DatabaseResourceVariantActionTypes } from '../actions/types/database-resource-variant';
import { ResourceVariantsApi } from '../api/resource-variants';


function* loadResourceVariant(action: ActionWith<LoadResourceVariantPayload>): SagaIterator {
  try {
    const { databaseId, resourceId, resourceVariantId, type } = action.payload;
    const result = yield call(ResourceVariantsApi.getResourceVariant, databaseId, resourceId, resourceVariantId, type);
    yield put(DatabaseResourceVariantActions.loadResourceVariantResponse(result));
  } catch (error) {
    console.error('database resource variant: load resource variant failed', error, action.payload);
  }
}

function* createResourceVariant(action: ActionWith<CreateResourceVariantPayload>): SagaIterator {
  try {
    const { databaseId, resourceId, type, model, formId } = action.payload;
    yield put(startSubmit(formId));
    try {
      const result = yield call(
        ResourceVariantsApi.createResourceVariant, databaseId, resourceId, type, model);
      yield put(reset(formId));
      yield put(stopSubmit(formId));
      yield put(DatabaseResourceVariantActions.createResourceVariantResponse(result));
      yield put(DatabaseActivityListingActions.closeEditPanel());
    } catch (error) {
      yield put(stopSubmit(formId, getGetErrorModelFromValidationResponse(error)));
    }
  } catch (error) {
    console.error('database resource variant: create resource variant failed', error, action.payload);
  }
}

function* updateResourceVariant(action: ActionWith<EditResourceVariantPayload>): SagaIterator {
  try {
    const { databaseId, resourceId, resourceVariantId, type, model, formId } = action.payload;
    yield put(startSubmit(formId));
    try {
      const result = yield call(
        ResourceVariantsApi.updateResourceVariant, databaseId, resourceId, resourceVariantId, type, model);
      yield put(reset(formId));
      yield put(stopSubmit(formId));
      yield put(DatabaseResourceVariantActions.updateResourceVariantResponse(result));
      yield put(DatabaseActivityListingActions.closeEditPanel());
    } catch (error) {
      yield put(stopSubmit(formId, getGetErrorModelFromValidationResponse(error)));
    }
  } catch (error) {
    console.error('database resource variant: update resource variant failed', error, action.payload);
  }
}

function* deleteResourceVariant(action: ActionWith<DeleteResourceVariantPayload>): SagaIterator {
  try {
    const { databaseId, resourceId, resourceVariantId, type } = action.payload;
    const result = yield call(
      ResourceVariantsApi.deleteResourceVariant,
      databaseId, resourceId, resourceVariantId, type,
    );
    yield put(DatabaseResourceVariantActions.deleteResourceVariantResponse(result));
  } catch (error) {
    yield put(NotificationActions.addAlert({ message: error.message, alertType: AlertType.Error }));
    console.error('database resource variant: delete resource variant failed', error, action.payload);
  }
}


export function* databaseResourceVariantSagas(): SagaIterator {
  yield takeLatest(DatabaseResourceVariantActionTypes.GET_RESOURCE_VARIANT_REQUEST, loadResourceVariant);
  yield takeLatest(DatabaseResourceVariantActionTypes.UPDATE_RESOURCE_VARIANT_REQUEST, updateResourceVariant);
  yield takeLatest(DatabaseResourceVariantActionTypes.CREATE_RESOURCE_VARIANT_REQUEST, createResourceVariant);
  yield takeLatest(DatabaseResourceVariantActionTypes.DELETE_RESOURCE_VARIANT_REQUEST, deleteResourceVariant);
}

