import { ElementTooltip, LabelWithRename } from '@kreo/kreo-ui-components';
import React from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { Styled } from './styled';

interface Props {
  name: string;
  disabled: boolean;
  onChange: (value: string) => void;
  setRenameMode?: (renameMode: boolean) => void;
}

const TitleComponent: React.FC<Props> = ({ name, disabled, onChange, setRenameMode }) => {
  return (
    <Styled.Title onDoubleClick={ConstantFunctions.stopEvent}>
      <ElementTooltip
        text={name}
        position='top'
        disabled={name.length < 12}
        wordBreakAll={true}
        tooltipOverflow='hidden'
        speed='l'
      >
        <LabelWithRename
          changeValue={onChange}
          value={name}
          fontSize={14}
          isHoverBackground={true}
          isHoverEditIcon={true}
          backgroundColor={'background'}
          disableRenaming={disabled}
          getRenameMode={setRenameMode}
        />
      </ElementTooltip>
    </Styled.Title>
  );
};

export const Title = React.memo(TitleComponent);
