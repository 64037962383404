import { createSlice } from '@reduxjs/toolkit';

import { ActionWith } from 'common/interfaces/action-with';
import { ViewType } from 'unit-2d-info-panel/constants';
import { Menu } from 'unit-2d-info-panel/constants/menus';
import { TwoDInfoPanelState } from 'unit-2d-info-panel/interfaces';

export const TWOD_INFO_PANEL_INITIAL_STATE: TwoDInfoPanelState = {
  view: ViewType.Page,
  menu: Menu.Default,
};


const TwoDInfoPanel = createSlice({
  name: '@twoDInfoPanel',
  initialState: TWOD_INFO_PANEL_INITIAL_STATE,
  reducers: {
    setView: (state, action: ActionWith<ViewType>) => {
      state.view = action.payload;
    },
    setMenu: (state, action: ActionWith<Menu>) => {
      state.menu = action.payload;
    },
    dropState: () => TWOD_INFO_PANEL_INITIAL_STATE,
  },
});

export const TwoDInfoPanelActions = TwoDInfoPanel.actions;
export const twoDInfoPanelReducers = TwoDInfoPanel.reducer;
