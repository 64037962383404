import { PaneConfig } from './interfaces';

const isIncreaseConditionExist = (pane: PaneConfig): boolean => !!pane.maxSize;

const isDecreaseConditionExist = (pane: PaneConfig): boolean => !!pane.minSize;

const isIncreaseSizeCorrect = (pane: PaneConfig, newSize: number): boolean => newSize <= pane.maxSize;

const isDecreaseSizeCorrect = (pane: PaneConfig, newSize: number): boolean => newSize >= pane.minSize;

const getNewIncreaseOffset = (pane: PaneConfig, newSize: number): number => newSize - pane.maxSize;

const getNewDecreaseOffset = (pane: PaneConfig, newSize: number): number =>  newSize - pane.minSize;

const getIncreaseMaxDiff = (pane: PaneConfig): number => pane.maxSize - pane.size;

const getDecreaseMaxDiff = (pane: PaneConfig): number => pane.minSize - pane.size;

export const Utils = {
  isIncreaseConditionExist,
  isDecreaseConditionExist,
  isIncreaseSizeCorrect,
  isDecreaseSizeCorrect,
  getNewIncreaseOffset,
  getNewDecreaseOffset,
  getIncreaseMaxDiff,
  getDecreaseMaxDiff,
};
