import { ElementTooltip, IconButton, Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';

interface Props {
  startContinue: () => void;
}

const ContinueButtonComponent: React.FC<Props> = ({ startContinue }) => {
  return (
    <Styled.Container>
      <ElementTooltip
        position={'top'}
        speed={'xl'}
        text={'Continue'}
      >
        <IconButton
          defaultColor='white'
          hoverColor='white'
          Icon={Icons.SmallArrow}
          width={20}
          height={20}
          iconHeight={16}
          onClick={startContinue}
        />
      </ElementTooltip>
    </Styled.Container>
  );
};

export const ContinueButton = React.memo(ContinueButtonComponent);
