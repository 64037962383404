import * as paper from 'paper';

import { CanvasHelper } from './canvas-helper';
import { Transform } from './interfaces';

interface BoundingBox {
  translate: Core.Math.Matrix;
  points: number[][];
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  width: number;
  height: number;
}

function findBoundingBox(points: number[][]): BoundingBox {
  const boundingBox: Partial<BoundingBox> = [...points].filter(Boolean).reduce(
    (bbox, point) => {
      return {
        minX: Math.min(bbox.minX, point[0]),
        minY: Math.min(bbox.minY, point[1]),
        maxX: Math.max(bbox.maxX, point[0]),
        maxY: Math.max(bbox.maxY, point[1]),
      };
    },
    { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity },
  );
  boundingBox.width = boundingBox.maxX - boundingBox.minX;
  boundingBox.height = boundingBox.maxY - boundingBox.minY;

  const translateToOriginalCoordinates = {
    deltaX: boundingBox.minX < 0 ? -boundingBox.minX : boundingBox.minX,
    deltaY: boundingBox.minY < 0 ? -boundingBox.minY : boundingBox.minY,
  };

  const transformationBuilder = new Core.Math.TransformationBuilder();
  transformationBuilder.translate(translateToOriginalCoordinates.deltaX, translateToOriginalCoordinates.deltaY);
  boundingBox.translate = transformationBuilder.getFinalTransform();

  boundingBox.points = [
    [boundingBox.minX, boundingBox.minY], // top left
    [boundingBox.maxX, boundingBox.minY], // top right
    [boundingBox.maxX, boundingBox.maxY], // bottom right
    [boundingBox.minX, boundingBox.maxY], // bottom left
  ];

  return boundingBox as BoundingBox;
}


function getTransformedPointsForPageCorners(
  width: number,
  height: number,
  transformationMatrix: Core.Math.Matrix,
): number[][] {
  const pointsInViewerCoordinates = [
    new Core.Math.Point(0, 0),
    new Core.Math.Point(width, 0),
    new Core.Math.Point(width, height),
    new Core.Math.Point(0, height),
  ];
  const transformedPoints = new Array(pointsInViewerCoordinates.length);
  for (let i = 0; i < pointsInViewerCoordinates.length; i++) {
    const pointInViewerCoordinate = pointsInViewerCoordinates[i];
    const transformedPoint = transformationMatrix.multiply(pointInViewerCoordinate.toMatrix());
    transformedPoints[i] = [transformedPoint.get(0, 0), transformedPoint.get(1, 0)];
  }
  return transformedPoints;
}

function getTransformedPageCornersFromDocumentsToCompare(
  documentsToTransform: Core.Document[],
  pageNumbersToRetrieve: number[],
  transformationMatriciesToApply: Core.Math.Matrix[],
): number[][][] {
  return documentsToTransform.map((document, index) => {
    let pageInfo = document.getPageInfo(pageNumbersToRetrieve[index]);
    if (!pageInfo) {
      // the page does not exist, create dummy information
      pageInfo = {
        width: 0,
        height: 0,
      };
    }
    let transformedPageCorners: number[][];
    if (transformationMatriciesToApply[index]) {
      const transformationMatrixToApply = transformationMatriciesToApply[index];
      const { width, height } = pageInfo;
      transformedPageCorners = getTransformedPointsForPageCorners(width, height, transformationMatrixToApply);
    }
    return transformedPageCorners;
  });
}

export function calculateImageDataForViewport(
  canvases: HTMLCanvasElement[],
  transformationMatriciesToApply: Core.Math.Matrix[],
  canvasSize: { width: number, height: number },
): ImageData[] {
  const result = new Array<ImageData>(canvases.length);

  for (let i = 0; i < canvases.length; i++) {
    const transformationMatrixToApply = transformationMatriciesToApply[i];
    result[i] = CanvasHelper.transformImageData(
      canvasSize.width,
      canvasSize.height,
      canvases[i],
      transformationMatrixToApply,
    );
  }
  return result;
}


export function getDocumentTransform(
  compareTransform: Transform,
): Core.Math.Matrix {
  const transformationBuilder = new Core.Math.TransformationBuilder();
  // order of operation matters
  transformationBuilder
    .rotate(compareTransform.rotation, true)
    .translate(
      compareTransform.translation.x,
      compareTransform.translation.y,
    );

  const matrix = transformationBuilder.getFinalTransform();

  return matrix;
}

export function getCombinedViewerCoordinateBoundingBoxFromTransformationMatrix(
  documentsToCompare: Core.Document[],
  pageNumbersToRetrieve: number[],
  transformationMatriciesToApply: Core.Math.Matrix[],
): BoundingBox {
  // rotates/transforms the corner points according to the tracnsformation matrix applied to the page
  // and find bounding box to fit the newly rotated/transformed rectangle
  const [baseDocumentTransformedPageCorners, diffDocumentTransformedPageCorners] =
    getTransformedPageCornersFromDocumentsToCompare(
      documentsToCompare,
      pageNumbersToRetrieve,
      transformationMatriciesToApply,
    );

  let combinedViewerCoordPoints = [...baseDocumentTransformedPageCorners, ...diffDocumentTransformedPageCorners];

  combinedViewerCoordPoints = combinedViewerCoordPoints.map((points) => points.map((point) => Math.floor(point)));
  const combinedViewerCoordBoundingBox = findBoundingBox(combinedViewerCoordPoints);
  return combinedViewerCoordBoundingBox;
}

export function findViewportForDocument(
  combinedBoundingBox: BoundingBox,
  viewport: BoundingBox,
  transformedPageCorners: number[][],
  transformationMatrix: Core.Math.Matrix,
): BoundingBox {
  if (!viewport) {
    return;
  }

  const coreControlsPoints = viewport.points.map((point) => {
    return new Core.Math.Point(point[0], point[1]);
  });

  const inverseTranslateMatrix = combinedBoundingBox.translate.inverse();

  const viewportPoints = coreControlsPoints.map((point) => {
    const transformedPoint = inverseTranslateMatrix.multiply(point.toMatrix());
    return [transformedPoint.get(0, 0), transformedPoint.get(1, 0)];
  });
  // Viewport is in combined bounding box coordinates, so this inverse reverse it to same coordinates as the document
  const viewportPolygon = new paper.Path(viewportPoints.map(([x, y]) => new paper.Point(x, y)));
  viewportPolygon.closePath();
  const diffPagePolygon = new paper.Path(transformedPageCorners.map(([x, y]) => new paper.Point(x, y)));
  diffPagePolygon.closePath();
  const intersections = viewportPolygon.getIntersections(diffPagePolygon);
  // if 2 documents doesn't have intersection, we don't need to show the diff document as it is not in viewport
  if (intersections.length) {
    // Convert intersection to diff page coordinates that are not rotated
    const transformedPoints2 = intersections.map((point) => {
      const tempPoint = new Core.Math.Point(point.point.x, point.point.y);
      const transformedPoint = transformationMatrix.inverse().multiply(tempPoint.toMatrix());
      return [transformedPoint.get(0, 0), transformedPoint.get(1, 0)];
    });

    // Find bounding box for this intersection. This is the rectangle we need from diff page in order to fill
    // viewport that was given to the base document
    const intersectBoundingBox = findBoundingBox(transformedPoints2);
    return intersectBoundingBox;
  }
}

interface DocumentsSetting extends Core.LoadCanvasAsyncOptions {
  transformationMatrixToApply: Core.Math.Matrix;
}

export function constructRenderSettingsForDocuments(
  sourceDocument: Core.Document,
  zoom: number,
  pageRotation: number,
  _renderRect: Core.ViewportRect,
  compareTransform: Transform,
  documentToCompare: Core.Document,
): DocumentsSetting[] {
  const matrix = getDocumentTransform(compareTransform);
  const { width, height } = sourceDocument.getPageInfo(1);
  const { width: documentToCompareWidth, height: documentToCompareHeight } = documentToCompare.getPageInfo(1);

  const documentRenderSettings = [
    {
      pageNumber: 1,
      pageRotation,
      zoom,
      transformationMatrixToApply: Core.Math.Matrix.Identity,
      renderRect: {
        x1: 0,
        y1: 0,
        x2: width,
        y2: height,
        rect: [0, 0, width, height],
      },
    },
    {
      pageNumber: 1,
      pageRotation,
      zoom: zoom * compareTransform.scale,
      transformationMatrixToApply: matrix,
      renderRect: {
        x1: 0,
        y1: 0,
        x2: documentToCompareWidth,
        y2: documentToCompareHeight,
        rect: [0, 0, documentToCompareWidth, documentToCompareHeight],
      },
    },
  ];
  return documentRenderSettings;
}
