import * as React from 'react';
import { Link } from 'react-router-dom';

import './link-container.scss';


interface Props {
  disabled: boolean;
  to: string;
  className?: string;
  controlName?: string;
}


export const LinkContainer: React.FC<Props> = (props) => {
  return props.disabled
    ? props.children as JSX.Element
    : <Link to={props.to} data-control-name={props.controlName} className='link-container'>{props.children}</Link>;
};
