import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ShortPointDescription } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';
import { useRun } from './use-run';


export function useRerun(): void {
  const runSearch = useRun();
  const searchZone = useSelector<State, ShortPointDescription[]>(state => state.drawings.magicSearch.searchArea);
  const dpi = useSelector<State, number>(state => state.drawings.magicSearch.dpi);

  useEffect(() => {
    runSearch(dpi);
  }, [dpi, searchZone, runSearch]);
}
