import * as React from 'react';

import './measurements-extractors-header-item-layout.scss';

interface Props {
  width?: string;
}

const defaultStyle = {};

export const MeasurementsExtractorsHeaderItemLayout: React.FC<Props> = ({ children, width }) => {
  const style = width ? { width } : defaultStyle;
  return (
    <div className='measurements-extractors-header-item-layout' style={style}>
      {children}
    </div>
  );
};
