import * as React from 'react';

export const ModularIconFinish: React.FC = () => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill='currentColor'
        // eslint-disable-next-line max-len
        d='M16.75,6s0-.08,0-.13a.94.94,0,0,0,0-.16s0,0,0,0a.24.24,0,0,0-.06-.08.67.67,0,0,0-.09-.14l-.13-.09s0,0-.09-.06l-11-5h0a.42.42,0,0,0-.15,0A.7.7,0,0,0,5,.25H5l-.11,0-.17,0A.75.75,0,0,0,4.6.38L4.48.46a.56.56,0,0,0-.1.15.24.24,0,0,0-.06.08h0a.37.37,0,0,0,0,.15.7.7,0,0,0,0,.14h0V19a.75.75,0,0,0,1.5,0V11.48l10.56-4.8,0,0a.8.8,0,0,0,.19-.13l0,0a.62.62,0,0,0,.12-.19s0,0,0,0a.94.94,0,0,0,0-.16S16.75,6,16.75,6Zm-11-3.84L14.19,6,5.75,9.84Z'
      />
    </svg>
  );
};
