import { Point } from 'paper';

import { PaperPointUtils } from '../paper-point-utils';


function getSimpleIntersection(
  l1Start: paper.Point,
  l1End: paper.Point,
  l2Start: paper.Point,
  l2End: paper.Point,
): paper.Point {
  if (PaperPointUtils.arePointsEqual(l1Start, l2Start) || PaperPointUtils.arePointsEqual(l1End, l2Start)) {
    return l2Start;
  } else if (PaperPointUtils.arePointsEqual(l1Start, l2End) || PaperPointUtils.arePointsEqual(l1End, l2End)) {
    return l2End;
  }

  if (PaperPointUtils.pointOnLine(l1Start, l1End, l2Start)) {
    return l2Start;
  } else if (PaperPointUtils.pointOnLine(l1Start, l1End, l2End)) {
    return l2End;
  } else if (PaperPointUtils.pointOnLine(l2Start, l2End, l1Start)) {
    return l1Start;
  } else if (PaperPointUtils.pointOnLine(l2Start, l2End, l1End)) {
    return l1End;
  }
  return null;
}

function getLinesIntersection(
  l1Start: paper.Point,
  l1End: paper.Point,
  l2Start: paper.Point,
  l2End: paper.Point,
): paper.Point | null {
  const simpleIntersectionPoint = getSimpleIntersection(l1Start, l1End, l2Start, l2End);
  if (simpleIntersectionPoint) {
    return simpleIntersectionPoint;
  }

  const denom = ((l2End.y - l2Start.y) * (l1End.x - l1Start.x)) - ((l2End.x - l2Start.x) * (l1End.y - l1Start.y));
  const numeA = ((l2End.x - l2Start.x) * (l1Start.y - l2Start.y)) - ((l2End.y - l2Start.y) * (l1Start.x - l2Start.x));
  const numeB = ((l1End.x - l1Start.x) * (l1Start.y - l2Start.y)) - ((l1End.y - l1Start.y) * (l1Start.x - l2Start.x));

  if (denom === 0) {
    return null;
  }

  const uA = numeA / denom;
  const uB = numeB / denom;

  if (uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1) {
    return new Point(l1Start.x + (uA * (l1End.x - l1Start.x)), l1Start.y + (uA * (l1End.y - l1Start.y)));
  }

  return null;
}

function areLinesEqual(
  l1Start: paper.Point,
  l1End: paper.Point,
  l2Start: paper.Point,
  l2End: paper.Point,
): boolean {
  return (PaperPointUtils.arePointsEqual(l1Start, l2Start) || PaperPointUtils.arePointsEqual(l1End, l2Start))
    && (PaperPointUtils.arePointsEqual(l1Start, l2End) || PaperPointUtils.arePointsEqual(l1End, l2End));
}

function hasLineIntersectionsWithPath(
  segments: paper.Segment[],
  line: [paper.Point, paper.Point],
  startIndex: number,
  lastIndex: number,
): boolean {
  for (let i = startIndex; i < lastIndex; i++) {
    const current = segments[i].point;
    const next = segments[i + 1].point;
    const intersection = getLinesIntersection(current, next, line[0], line[1]);
    if (intersection) {
      const linesEqual = areLinesEqual(current, next, line[0], line[1]);
      const connectedInStart = i === startIndex
        && (PaperPointUtils.arePointsEqual(line[0], current) || PaperPointUtils.arePointsEqual(line[1], current));
      const connectedInEnd = i === lastIndex - 1
        && (PaperPointUtils.arePointsEqual(line[0], next) || PaperPointUtils.arePointsEqual(line[1], next));
      if (linesEqual || !connectedInStart && !connectedInEnd) {
        return true;
      }
    }
  }
  return false;
}


export const PaperIntersectionUtils = {
  getLinesIntersection,
  hasLineIntersectionsWithPath,
};
