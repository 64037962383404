import { RequestStatus } from 'common/enums/request-status';
import { Feed } from 'common/interfaces/feed';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { LoadActivitiesPayload } from '../actions/payloads/database-activity';
import { DatabaseActivityActionTypes } from '../actions/types/database-activity';
import { ActivityModel } from '../interfaces/data';
import { DatabaseReduxState } from '../interfaces/redux-state';
import { ListModelMapper } from '../utils/list-model-mapper';


export const DatabaseActivityReducerMethods: ReducerMethods<DatabaseReduxState> = {
  [DatabaseActivityActionTypes.GET_ACTIVITIES_REQUEST]: (state, payload: LoadActivitiesPayload) => {
    const helper = new MonoliteHelper(state);
    if (payload.filter.skip === 0) {
      helper.set((_) => _.currentDatabase.activityListing.activities, []);
      helper.set((_) => _.currentDatabase.activityListing.activityVariantActivityAssignmentInfo, []);
    }

    return helper
      .set((_) => _.statuses.activityListing, RequestStatus.Loading)
      .get();
  },
  [DatabaseActivityActionTypes.GET_ACTIVITIES_SUCCEEDED]: (state, payload: Feed<ActivityModel>) => {
    const unitMap = state.currentDatabase.unitMap;
    const listingActivities = ListModelMapper.getActivityListModels(payload.data, unitMap);
    const databaseId = state.currentDatabase.database.id;
    const infoModels = ListModelMapper.getActivityVariantActivityAssignmentInfoModels(payload.data, databaseId);

    return new MonoliteHelper(state)
      .setConcat((_) => _.currentDatabase.activityListing.activities, listingActivities)
      .setConcat((_) => _.currentDatabase.activityListing.activityVariantActivityAssignmentInfo, infoModels)
      .set((_) => _.currentDatabase.activityListing.totalCount, payload.count)
      .set((_) => _.statuses.activityListing, RequestStatus.Loaded)
      .get();
  },
  [DatabaseActivityActionTypes.GET_ACTIVITY_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.currentDatabase.editModel.root, null)
      .get();
  },
  [DatabaseActivityActionTypes.GET_ACTIVITY_SUCCEEDED]: (state, payload: ActivityModel) => {
    return new MonoliteHelper(state)
      .set((_) => _.currentDatabase.editModel.root, payload)
      .get();
  },
};
