
import autobind from 'autobind-decorator';

import { DrawingsCanvasColors } from 'common/components/drawings/constants/drawing-canvas-constants';
import { ConstantFunctions } from 'common/constants/functions';
import { VisibleEntity, VisibleEntityConfig } from '../../../common';
import { DrawingsCursorTypeHelper } from '../../../drawings-helpers';
import { DrawingsGeometrySymbolsStore } from '../../../drawings-helpers/drawings-geometry-symbols-store';
import { PaperMouseEventUtils } from '../../../drawings-helpers/paper-mouse-event-utils';
import { StarDragSegmentHandler } from '../../../interfaces';
import { DrawingsMouseEventHandler } from '../../base';

type Geometry = [paper.Point, paper.Point];

interface DrawingsGeometrySegmentSliderConfig extends VisibleEntityConfig<Geometry> {
  layer: paper.Layer | paper.Group;
  mouseEnter: (e: PaperMouseEvent) => void;
  mouseLeave: DrawingsMouseEventHandler;
  startDragSegment: StarDragSegmentHandler;
  cursorHelper: DrawingsCursorTypeHelper;
}

export class DrawingsGeometrySegmentSlider extends VisibleEntity<Geometry, DrawingsGeometrySegmentSliderConfig> {
  private _vector: paper.Point;
  private _path: paper.Path.Rectangle;

  public destroy(): void {
    this._path.remove();
  }

  public updatePoints([start, end]: [paper.Point, paper.Point]): void {
    const position = start.add(end).divide(2);
    this._path.position = position;
    const newVector = start.subtract(end);
    this._path.rotate(-this._vector.angle + newVector.angle);
    this._vector = newVector;
  }

  @autobind
  public scale(scale: number): void {
    this._path.scale(scale);
    this._path.strokeWidth *= scale;
  }

  protected override render(geometry: Geometry): void {
    this._path = DrawingsGeometrySymbolsStore.segmentSlider;
    const [start, end] = geometry;
    this._vector = start.subtract(end);
    const position = start.add(end).divide(2);
    this._path.addTo(this._config.layer);
    this._path.position = position;
    this._path.rotate(this._vector.angle);
    this._path.onMouseEnter = this.onMouseEnter;
    this._path.onMouseLeave = this.onMouseLeave;
    this._path.onMouseDown = this.onMouseDown;
  }

  @autobind
  private onMouseEnter(e: PaperMouseEvent): void {
    ConstantFunctions.stopEvent(e);
    this._path.strokeColor = DrawingsCanvasColors.pointColor;
    this._config.mouseEnter(e);
    this._config.cursorHelper.hoveredSelected = true;
  }

  @autobind
  private onMouseDown(e: PaperMouseEvent): void {
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      this._config.startDragSegment(this._config.id, e, this._path.position);
    }
  }

  @autobind
  private onMouseLeave(e: PaperMouseEvent): void {
    ConstantFunctions.stopEvent(e);
    this._path.strokeColor = DrawingsCanvasColors.white;

    this._config.mouseLeave(this._config.id, e);
    this._config.cursorHelper.hovered = false;
  }
}
