import React from 'react';

import { CommentaryUserInfo, UserForMention } from 'unit-2d-comments/interfaces';

export interface CommentUserContextProps {
  users: Record<string, CommentaryUserInfo>;
  usersForMention: UserForMention[];
  getUserDisplayName: (id: string) => string;
}

export const CommentUserContext = React.createContext<CommentUserContextProps>(null);
