import { EMPTY_VALUE } from 'common/components/tree-filter-panel/constants';

export const standardClassification = (value: string, fieldName: string): string => {
  const [code, ...name] = value.split(' ');
  if (code.toLowerCase() === EMPTY_VALUE) {
    return (`<span>Undefined ${fieldName}</span>`);
  }

  return `<span class='group-row-inner-cell__code'>${code} </span>
  <span>${name.join(' ')}</span>`;
};
