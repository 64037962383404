import autobind from 'autobind-decorator';
import React from 'react';
import { Field } from 'redux-form';

import './database-activity-variant-resource-based-crew-hours-material-item.scss';

import { KreoIconAddPlus, MaterialNumberInput, MaterialNumberInputField, MaterialNumberInputProps } from 'common/UIKit';
import { FieldRequired, GreaterThan } from '../../../../components/dialog/validators';
import { MaterialBasedCrewHoursModel, UnitModel } from '../../interfaces/data';
import {
  DatabaseActivityVariantResourceBasedCrewHoursMaterilaField,
  DatabaseActivityVariantResourceBasedCrewHoursMaterilaFieldProps,
} from './database-activity-variant-resource-based-crew-hours-material-field';


const quantityGreaterThan0 = GreaterThan(0, 'Quantity');
const materialIsRequired = FieldRequired('Material');

interface Props {
  fieldKey: string;
  title: string;
  readonly: boolean;
  model: MaterialBasedCrewHoursModel;
  onChange: (model: MaterialBasedCrewHoursModel) => void;
  onPercentageChange: (percentage: number) => void;
  percentage: number;
  onSelectMaterialClick: () => void;
  unitMap: Record<number, UnitModel>;
}

export class DatabaseActivityVariantResourceBasedCrewHoursMaterilaItem extends React.Component<Props> {
  public render(): JSX.Element {
    const { model, unitMap } = this.props;
    const unit = unitMap[model && model.unitId];

    return (
      <div
        className='database-activity-variant-resource-based-crew-hours-material
        database-entity-modify-wrap-tile material'
      >
        <div className='database-entity-modify-wrap-tile__name'>
          <div className='database-activity-variant-resource-based-crew-hours-material__header'>
            <b>{this.props.title}</b>
            <div
              onClick={this.props.onSelectMaterialClick}
              className='database-activity-variant-resource-based-crew-hours-material__header__add-button'
            >
              <KreoIconAddPlus />
            </div>
          </div>
          <Field<DatabaseActivityVariantResourceBasedCrewHoursMaterilaFieldProps>
            name={`${this.props.fieldKey}.materialId`}
            component={DatabaseActivityVariantResourceBasedCrewHoursMaterilaField}
            material={this.props.model.resource}
            validate={materialIsRequired}
          />
          <b>{model.resource && model.resource.name}</b>
        </div>
        <div className='database-entity-modify-wrap-tile__content'>
          <Field<MaterialNumberInputProps>
            name={`${this.props.fieldKey}.amount`}
            component={MaterialNumberInputField}
            className='database-entity-modify-wrap-tile__quantity'
            label='Quantity'
            isFloatingLabel={false}
            value={model && model.amount}
            onChange={this.onAmountChange}
            placeholder='0.00'
            precision={2}
            valueType='float'
            disabled={this.props.readonly}
            displayBottomInformation={true}
            validate={quantityGreaterThan0}
          />
          <div className='database-entity-modify-wrap-tile__unit'>
            <div className='database-entity-modify-wrap-tile__label'>Unit</div>
            <div className='database-entity-modify-wrap-tile__value'>
              {unit && unit.acronym}
            </div>
          </div>
          <MaterialNumberInput
            className='database-entity-modify-wrap-tile__quantity'
            label='Percentage'
            isFloatingLabel={false}
            value={this.props.percentage}
            onChange={this.onPercentageChange}
            valueType='integer'
            disabled={this.props.readonly}
            displayBottomInformation={true}
            max={100}
            min={0}
          />
          <Field<MaterialNumberInputProps>
            name={this.nestedFieldName('crewHours')}
            component={MaterialNumberInputField}
            className='activity-variant-right-panel__gang-hours'
            value={this.props.model.crewHours}
            isFloatingLabel={false}
            onChange={this.onCrewHoursChange}
            label='Gang Hours'
            precision={2}
            valueType='float'
            disabled={this.props.readonly}
            displayBottomInformation={true}
            validate={quantityGreaterThan0}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onAmountChange(_: React.ChangeEvent, amount?: number): void {
    this.props.onChange({
      ...this.props.model,
      amount,
    });
  }

  @autobind
  private onPercentageChange(_: React.ChangeEvent, value?: number): void {
    this.props.onPercentageChange(value);
  }

  @autobind
  private nestedFieldName(fieldName: string): string {
    return this.props.fieldKey
      ? `${this.props.fieldKey}.${fieldName}`
      : fieldName;
  }

  @autobind
  private onCrewHoursChange(_: React.ChangeEvent, crewHours?: number): void {
    this.props.onChange({
      ...this.props.model,
      crewHours,
    });
  }
}
