import autobind from 'autobind-decorator';
import moment from 'moment';
import * as React from 'react';

import './project-tile-progress-bar.scss';

import { ProgressBar } from 'common/components/progress-bar';
import { RenewableExecutor } from 'common/utils/renewable-executor';
import { KnownViewModelNames } from '../../enums/known-view-model';
import { ViewModelStatusPair } from '../../interfaces/view-model-status-pair';
import { PercentageData, ProjectTileUtils } from './utils';

interface Props {
  viewModelStatus: ViewModelStatusPair;
}

export class ProjectTileProgressBar extends React.PureComponent<Props, PercentageData> {
  private renewableExecutor: RenewableExecutor;

  constructor(props: Props) {
    super(props);
    const viewModelStatus = this.props.viewModelStatus;
    this.state = ProjectTileUtils.getPercentage(viewModelStatus.estimatedDuration, viewModelStatus.startTime);
    this.renewableExecutor = new RenewableExecutor(this.update, this.state.stepTime);
  }

  public componentDidUpdate(prevProps: Props, prevState: PercentageData): void {
    if (this.props.viewModelStatus.estimatedDuration !== prevProps.viewModelStatus.estimatedDuration) {
      this.update();
    }

    if (prevState.stepTime !== this.state.stepTime) {
      this.renewableExecutor.updateTime(this.state.stepTime);
    }
  }

  public componentDidMount(): void {
    this.renewableExecutor.start();
  }

  public componentWillUnmount(): void {
    this.renewableExecutor.cancel();
  }

  public render(): React.ReactNode {
    return (
      <div className='project-tile-progress-bar'>
        <div className='project-tile-progress-bar__name'>
          {KnownViewModelNames[this.props.viewModelStatus.viewModelType]}
        </div>
        <div className='project-tile-progress-bar__progress'>
          <div className='project-tile-progress-bar__time'>
            ~{moment(this.state.targetTime + this.props.viewModelStatus.startTime).fromNow()}
          </div>
          <div className='project-tile-progress-bar__progress-bar'>
            <ProgressBar
              value={this.state.percent * 100}
            />
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private update(): void {
    this.setState(ProjectTileUtils.getPercentage(this.state.targetTime, this.props.viewModelStatus.startTime));
  }
}
