import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './activity-assignment-activities-header.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoButton } from 'common/UIKit';
import { ActivityAssignmentActions } from '../../actions/creators/activity-assignment';
import { Project } from '../../interfaces/project';

interface Props {
  edit?: boolean;
}

interface ReduxProps {
  project: Project;
  usedOldCmtdbVersion: boolean;
}

interface ReduxActions {
  navigateToDataBase: (projectId: number) => void;
}

interface Props extends ReduxProps, ReduxActions, AbilityAwareProps {}

class ActivityAssignmentActivitiesHeaderComponent extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='activity-assignment-activities-header'>
        <div className='activity-assignment-activities-header__title'>
          Activities
        </div>
        {this.props.edit ?
          this.props.usedOldCmtdbVersion
            ? (
              <div className='activity-assignment-activities-header__warning'>
                Activity Assignment is not editable, because it was calculated with an outdated database.
                Recalculate, please.
              </div>
            ) : this.props.ability.can(Operation.Read, Subject.Database) && (
              <div className='activity-assignment-activities-header__button-container'>
                <KreoButton size='medium' onClick={this.onClick} controlName='select-from-database-button'>
                  Select From Database
                </KreoButton>
              </div>
            ) : null}
      </div>
    );
  }

  @autobind
  private onClick(): void {
    this.props.navigateToDataBase(this.props.project ? this.props.project.id : null);
  }
}

function mapStateToProps({ projects, activityAssignment }: ReduxState): ReduxProps {
  return {
    project: projects.currentProject,
    usedOldCmtdbVersion: activityAssignment.usedOldCmtdbVersion,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): ReduxActions {
  return {
    navigateToDataBase: (projectId: number) => {
      dispatch(ActivityAssignmentActions.setSaveData(true));
      dispatch(ActivityAssignmentActions.openDataBasePage(projectId));
    },
  };
}


export const ActivityAssignmentActivitiesHeader =
  withAbilityContext(connect(mapStateToProps, mapDispatchToProps)(ActivityAssignmentActivitiesHeaderComponent));
