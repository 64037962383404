import { DrawingsGeometryUtils, DrawingsInstanceMeasure } from 'common/components/drawings';
import { MoveToCellOptionType } from 'common/components/drawings/drawings-canvas-menus';
import { getMeasure, getMeasureKey } from '../get-measure';

const getMeasureType = (instancesMeasure: DrawingsInstanceMeasure, type: string): string => {
  if (type === 'length') {
    return DrawingsGeometryUtils.isClosedContour(instancesMeasure.type, instancesMeasure.measures as any)
      ? 'perimeter'
      : 'length';
  }

  return type;
};

export function getValueToInsertInRange(instancesMeasures: DrawingsInstanceMeasure[], type: string): string[] {
  const result = [];

  if (type === MoveToCellOptionType.name || type === MoveToCellOptionType.groupName) {
    return instancesMeasures.map(instance => getMeasureKey(instance.id as string, type));
  }

  const sortedInstances = instancesMeasures.sort((f, s) => {
    return f.measures[getMeasureType(f, type)] - s.measures[getMeasureType(s, type)];
  });

  for (const instance of sortedInstances) {
    const measure = getMeasure(instance, getMeasureType(instance, type));
    result.push(measure);
  }

  return result;
}
