import { QtoRecord } from '../../../units/projects/components/quantity-take-off-left-panel/interfaces';

export enum FilterByCustomFilter {
  UseActiveCustomFilter = 'UseActiveCustomFilter',
}


const useActiveCustomFilter = (record: QtoRecord, filtrationFunction: (record: QtoRecord) => boolean): boolean => {
  if (!filtrationFunction) {
    return true;
  }
  return filtrationFunction(record);
};

export const filterByCustomFilter: Record<
  FilterByCustomFilter,
  (record: QtoRecord, filtrationFunction: (record: QtoRecord) => boolean) => boolean
> = {
  [FilterByCustomFilter.UseActiveCustomFilter]: useActiveCustomFilter,
};

