import { AnyAction } from 'redux';

import { TWO_D_INITIAL_STATE } from '2d/initial-state';
import { TwoDReduxState } from '2d/interfaces';
import { twoDReducerMethods } from '2d/reducers/two-d';

export function twoD(
  state: TwoDReduxState = TWO_D_INITIAL_STATE,
  action: AnyAction = { type: '', paylaod: null }): TwoDReduxState {
  if (action.type in twoDReducerMethods) {
    return twoDReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
