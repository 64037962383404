import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { OptionContainerStyled } from 'common/components/selection-menus';
import { TagsInputStyled } from 'common/components/tag-input';
import { AddonListBlockStyled } from '../subscription-details-new-user/addon-list-block';
import { BillingAddressBlockStyled } from '../subscription-details-new-user/billing-address-block';
import { FillStepScaleSubscriptionStyled } from '../subscription-details-new-user/fill-step-scale-subscription';
import { PaymentManagerBlockStyled } from '../subscription-details-new-user/payment-manager-block';
import { TeamSizeBlockStyled } from '../subscription-details-new-user/team-size-block';
import { CouponsStyled } from './coupons';
import { TeamSizeStyled } from './team-size';

const Container = styled.div`
  display: flex;
`;

const ReviewCard = styled.div`
  width: 590px;
  box-sizing: border-box;
  padding: 40px 25px 37px 25px;
  border-radius: 40px;
  align-self: flex-start;
  background-color: ${props => props.theme.color.backgroundRush};

  h5 {
    margin-bottom: 30px;
  }

  ${FillStepScaleSubscriptionStyled.Container} {
    display: none;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const PlanCard = styled.div`
  width: 270px;
  margin-left: 30px;
  padding: 25px 20px;
  border-radius: 30px;
  align-self: flex-start;
  box-sizing: border-box;
  background-color: ${props => props.theme.color.backgroundRush};

  button {
    width: 100%;
  }

  button + p {
    margin-top: 20px;
  }

  ${CouponsStyled.Container} {
    margin-top: 15px;
  }

  ${TagsInputStyled.Container} {
    border-radius: 10px;
  }
`;

const AttentionText = styled.div`
  display: flex;
  margin-top: 10px;

  svg {
    height: 20px;
    margin-right: 15px;
    fill: ${props => props.theme.color.green};
  }
`;

const LinkButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  color: ${p => p.theme.color[p.color]};
  cursor: pointer;

  svg {
    height: 20px;
    margin-right: 15px;
    fill: currentColor;
  }
`;

const Period = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  > div {
    margin: 0 10px;
  }
`;

const BorderBlock = styled.div`
  width: 540px;
  height: 0px;
  border: 1px solid ${props => props.theme.color.background};
`;

const BillingAddressBlock = styled.div`
  ${BillingAddressBlockStyled.ContentContainer} {
    margin: 0;
    > ${Text} {
      margin-bottom: 10px;
    }
  }
`;

const PaymentManagerBlock = styled.div`
  ${BillingAddressBlockStyled.ContentContainer} {
    margin: 0;
  }

  ${PaymentManagerBlockStyled.ContentContainer} {
    margin-left: 0;

    > ${Text} {
      display: none;
    }
  }

  ${PaymentManagerBlockStyled.PaymentManagerWrapper} {
    margin-top: 20px;
  }
`;

const TeamSizeBlock = styled.div`
  ${TeamSizeBlockStyled.FillStepScaleContainer} {
    display: none;
  }

  ${TeamSizeBlockStyled.ContentContainer} {
    margin-left: 0;
  }

  ${TeamSizeStyled.Counter} {
    margin-top: 15px;
    margin-bottom: 20px;
  }
`;

const AddonListBlock = styled.div`
  ${AddonListBlockStyled.ExtraText} {
    display: none;
  }

  ${AddonListBlockStyled.ContentContainer} {
    margin-left: 0;
  }

  ${AddonListBlockStyled.TextContainer} {
    margin: 20px 0;
  }
`;

const RadioMenu = styled.div`
  padding-bottom: 10px;

  ${OptionContainerStyled.Container} {
    display: flex;
    margin-bottom: 10px;
  }
`;

const CloseButtonContainer = styled.div`
  height: 30px;
  position: relative;
  top: -30px;
  border-radius: 50%;
  background-color: ${p => p.theme.color.backgroundRush};
`;


export const Styled = {
  Container,
  ReviewCard,
  HeaderWrapper,
  PlanCard,
  Period,
  LinkButton,
  AttentionText,
  BorderBlock,
  BillingAddressBlock,
  PaymentManagerBlock,
  TeamSizeBlock,
  AddonListBlock,
  RadioMenu,
  CloseButtonContainer,
};
