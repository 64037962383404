import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { KreoToolbarButton } from 'common/components/kreo-toolbar/kreo-toolbar-button';
import { StringDictionary } from 'common/interfaces/dictionary';
import { KreoIconPropertiesInfo } from 'common/UIKit';
import { ModelBrowserPropertyGroupView, PropertiesDataProviderApi } from '../../interfaces/properties-provider-types';
import { PropertiesPopupContainer } from '../properties-popup-container';
import { RevitPropertiesPopupItem } from '../revit-properties-popup-item';
import { withPropertiesDataProviderApi } from '../with-properties-data-provider-api/with-properties-data-provider-api';


export interface PropertiesPopupApi {
  showPropertiesForElements: (elementIds: number[], showLayers?: boolean, popupTitle?: string) => void;
  close: () => void;
}

interface OwnProps {
  containerRef: HTMLDivElement;
  sendApi: (api: PropertiesPopupApi) => void;
}

interface Props extends OwnProps, PropertiesDataProviderApi { }


interface State {
  visualData: {
    width: number,
    height: number,
    top: number,
    left: number,
    isInitial: boolean,
  };
  isOpen: boolean;
  properties: ModelBrowserPropertyGroupView[];
  elementIds: number[];
  propertiesOpenState: StringDictionary<boolean>;
  popupTitle: string | null;
}


class PropertiesPopupButtonComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visualData: {
        width: 0,
        left: 0,
        height: 0,
        top: 0,
        isInitial: true,
      },
      isOpen: false,
      properties: [],
      elementIds: [],
      propertiesOpenState: {},
      popupTitle: null,
    };
  }

  public componentDidMount(): void {
    this.props.sendApi({
      showPropertiesForElements: this.showPropertiesForElements,
      close: this.close,
    });
  }

  public render(): React.ReactNode {
    if (!this.props.containerRef) {
      return null;
    }
    const { elementIds, isOpen } = this.state;
    return (
      <>
        <KreoToolbarButton
          controlName='properties-popup-button'
          onClick={this.toggleOpenStatus}
          active={isOpen}
          defaultTooltipText='Properties'
          disabled={elementIds && !elementIds.length}
          disabledTooltipText='Select Element to unlock'
        >
          <KreoIconPropertiesInfo />
        </KreoToolbarButton>
        {this.renderPopup()}
      </>
    );
  }

  private renderPopup(): React.ReactNode {
    const { properties, propertiesOpenState, isOpen, visualData, elementIds, popupTitle } = this.state;
    if (!properties) {
      return null;
    }
    return ReactDOM.createPortal(
      (
        <CSSTransition
          in={isOpen && !!properties && !!properties.length}
          mountOnEnter={true}
          unmountOnExit={true}
          timeout={200}
          className='properties-popup-container'
        >
          <PropertiesPopupContainer
            parentLayoutRef={this.props.containerRef}
            onClose={this.toggleOpenStatus}
            name={popupTitle}
            count={elementIds.length}
            {...visualData}
            resizeVertically={true}
            resizeHorizontally={true}
            updatePopupLayoutInfo={this.updatePopupInfo}
            verticalMargin={10}
            horizontalMargin={10}
          >
            {
              properties.map(x => (
                <RevitPropertiesPopupItem
                  onOpen={this.toggleOpenClosePropertyCategory}
                  key={x.name}
                  title={x.name}
                  items={x.properties}
                  open={propertiesOpenState[x.name]}
                />
              ))
            }
          </PropertiesPopupContainer>
        </CSSTransition>
      ),
      this.props.containerRef,
    );
  }

  @autobind
  private showPropertiesForElements(
    elementIds: number[],
    showLayers: boolean = false,
    popupTitle: string = 'Properties',
  ): void {
    const properties = this.props.getCommonElementProperties(elementIds, showLayers);
    this.setState({ properties, elementIds, popupTitle: popupTitle || null });
  }

  @autobind
  private toggleOpenStatus(): void {
    this.setState({ isOpen: !this.state.isOpen });
  }

  @autobind
  private updatePopupInfo(top: number, left: number, width: number, height: number): void {
    const visualData = { width, height, left, top, isInitial: false };
    this.setState({ visualData });
  }

  @autobind
  private toggleOpenClosePropertyCategory(name: string, value: boolean): void {
    const propertiesOpenState = { ...this.state.propertiesOpenState, [name]: value };
    return this.setState({ propertiesOpenState });
  }

  @autobind
  private close(): void {
    this.setState({ isOpen: false });
  }
}

export const PropertiesPopupButton = withPropertiesDataProviderApi(PropertiesPopupButtonComponent);
