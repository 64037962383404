import * as React from 'react';

import { DrawingsSubtract, DrawingsUniteDialog, EncloseDialog } from '../drawings-boolean-dialogs';
import { DrawingsJoinCountDialog } from '../drawings-boolean-dialogs/drawings-join-count';
import { OpenPolygonDialog } from '../drawings-boolean-dialogs/open-polygon-dialog';
import { SplitPolylineDialog } from '../drawings-boolean-dialogs/split-polyline-dialog';
import { DrawingsOffsetMenu } from '../drawings-canvas-menus';
import { DrawingsDrawMode } from '../enums/drawings-draw-mode';
import { useDrawModeApi } from '../hooks';

const Dialogs = {
  [DrawingsDrawMode.Union]: { dialog: DrawingsUniteDialog, isBooleanMenu: true },
  [DrawingsDrawMode.Subtract]: { dialog: DrawingsSubtract, isBooleanMenu: true },
  [DrawingsDrawMode.Join]: { dialog: DrawingsJoinCountDialog, isBooleanMenu: true },
  [DrawingsDrawMode.Enclose]: { dialog: EncloseDialog, isBooleanMenu: true },
  [DrawingsDrawMode.OpenPolygon]: { dialog: OpenPolygonDialog, isBooleanMenu: true },
  [DrawingsDrawMode.Offset]: { dialog: DrawingsOffsetMenu, isBooleanMenu: false },
  [DrawingsDrawMode.SplitPolyline]: { dialog: SplitPolylineDialog, isBooleanMenu: true },
};

interface DialogRendererProps {
  dialogComponent: React.ComponentType<{ sendRef: (ref: HTMLDivElement) => void }>;
  sendRef: (ref: HTMLDivElement, isBoolean: boolean) => void;
  isBooleanMenu: boolean;
}

const DialogRenderer: React.FC<DialogRendererProps> = ({ dialogComponent, sendRef, isBooleanMenu }) => {
  const refCallback = React.useCallback((ref: HTMLDivElement) => {
    sendRef(ref, isBooleanMenu);
  }, [sendRef, isBooleanMenu]);

  const Dialog = dialogComponent;
  return <Dialog sendRef={refCallback}/>;
};


interface Props {
  sendMenuRef: (ref: HTMLDivElement, isBooleanMenu?: boolean) => void;
}

const DrawingsOperationMenuWrapperComponent: React.FC<Props> = ({
  sendMenuRef,
}) => {
  const { drawMode } = useDrawModeApi();

  const Dialog = Dialogs[drawMode];

  if (Dialog) {
    return (
      <DialogRenderer
        dialogComponent={Dialog.dialog}
        sendRef={sendMenuRef}
        isBooleanMenu={Dialog.isBooleanMenu}
      />
    );
  } else {
    return null;
  }
};

export const DrawingsOperationMenuWrapper = React.memo(DrawingsOperationMenuWrapperComponent);
