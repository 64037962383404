import autobind from 'autobind-decorator';
import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';
import { Color } from './color';

interface Props {
  colors?: string[];
  color: string;
  onChange: (color: string) => void;
}


export class SvgColorPicker extends React.PureComponent<Props> {

  public static defaultProps: Partial<Props> = {
    colors: [
      '#16a98f',
      '#9ccc3d',
      '#f7ee22',
      '#ffc115',
      '#FF9922',
      '#FF7722',
      '#FF2233',
      '#EE44AA',
      '#AA44AA',
      '#6b459c',
      '#0a57a5',
      KreoColors.blue4,
    ],
  };

  private cachedColors: Record<string, { r: number, g: number, b: number }> = {};

  private readonly strokeWidth: number = 2;
  private readonly size: number = 122;
  private readonly layerWidth: number = 12;
  private readonly tintLevels: number = 3;


  public render(): React.ReactNode {
    const center = this.size / 2;
    return (
      <svg width={`${this.size}px`} height={`${this.size}`} viewBox={`0 0 ${this.size} ${this.size}`}>
        { this.renderArcs(center) }
        <circle
          cx={center}
          cy={center}
          r={center - this.tintLevels * this.layerWidth}
          fill='#fff'
        />
      </svg>
    );
  }

  private renderArcs(center: number): React.ReactNode {
    const result = new Array<React.ReactNode>();
    let startAngle = 0;
    const tintFactorStep = 1 / this.tintLevels;
    const sectionAngle = 360 / this.props.colors.length;
    for (let i = 0; i < this.tintLevels; i++) {
      const tintFactor = tintFactorStep * i;
      const paddingAngle = this.strokeWidth / 1.9 * 180 / (center - i * this.layerWidth) / Math.PI;
      for (const color of this.props.colors) {
        const sumcolor = this.getColor(color, tintFactor);
        const endAngle = startAngle + sectionAngle;
        result.push(
          <Color
            key={sumcolor}
            selected={sumcolor === this.props.color}
            color={sumcolor}
            width={this.layerWidth}
            strokeWidth={this.strokeWidth}
            finishAngle={endAngle - paddingAngle}
            angle={startAngle + paddingAngle}
            center={center}
            layer={i}
            onSelect={this.onSelectColor}
          />,
        );
        startAngle = endAngle;
      }
    }
    return result;
  }

  private getColor(color: string, tintFactor: number): string {
    if (tintFactor) {
      const { r, g, b } = this.getParsedColor(color);
      const rHex = this.convertColorValue(r, tintFactor);
      const gHex = this.convertColorValue(g, tintFactor);
      const bHex = this.convertColorValue(b, tintFactor);
      return `#${rHex}${gHex}${bHex}`;
    } else {
      return color;
    }
  }

  private getParsedColor(color: string): { r: number, g: number, b: number } {
    if (!(color in this.cachedColors)) {
      this.cachedColors[color] = this.hexToRgb(color);
    }
    return this.cachedColors[color];
  }

  private convertColorValue(value: number, tintFactor: number): string {
    const val = Math.round((value + (255 - value) * tintFactor)).toString(16);
    return val.length < 2 ? `0${val}` : val;
  }

  private hexToRgb(hex: string): { r: number, g: number, b: number } {
    const color = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
    return {
      r: parseInt(color[1], 16),
      g: parseInt(color[2], 16),
      b: parseInt(color[3], 16),
    };
  }

  @autobind
  private onSelectColor(color: string): void {
    this.props.onChange(color);
  }
}
