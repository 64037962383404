import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
`;

const QueryItem = styled.div`
  padding: 2px 4px;
  border-radius: 5px;
  background: ${({ theme }) => theme.color.turquoise};
  cursor: pointer;
`;

const LineForMenu = styled.div`
  padding: 10px;
`;

export const Styled = {
  Container,
  QueryItem,
  LineForMenu,
};
