import React from 'react';

import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { CLOSE_CREATE_DIALOG_NAME, RENAME_DIALOG_NAME } from '../../constants';

interface Props {
  closeDialogTitle: string;
  closeDialogOnClose: () => void;
  renameTitle: string;
  renameDialogText: string;
}

export const ConfirmDialogs = (props: Props): JSX.Element => {
  const { closeDialogTitle, closeDialogOnClose, renameTitle, renameDialogText  } = props;

  return (
    <>
      <ConfirmationDialog
        name={CLOSE_CREATE_DIALOG_NAME}
        title={closeDialogTitle}
        text='This action cannot be undone'
        confirmButtonText='Exit'
        onConfirm={closeDialogOnClose}
        cancelButtonText='Cancel'
        zIndex={99999}
      />
      <ConfirmationDialog
        name={RENAME_DIALOG_NAME}
        title={renameTitle}
        text={renameDialogText}
        cancelButtonText='Okay'
        zIndex={99999}
      />
    </>

  );
};
