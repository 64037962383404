import autobind from 'autobind-decorator';

import { ConstantFunctions } from 'common/constants/functions';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { DrawingContextObserver } from '../../drawings-contexts';
import { DrawingsDrawMode } from '../../enums/drawings-draw-mode';
import { DrawingsCommonUtils } from '../../utils/drawings-common-utils';


export class DrawingsGeometryDragEventHelper  {
  private _drawMode: DrawingsDrawMode;
  private _dragExecutor: DeferredExecutor = new DeferredExecutor(DrawingsCanvasConstants.doubleClickDelay / 2);
  private _sharedSpaceBarHeldContext: DrawingContextObserver<boolean>;

  constructor(sharedSpaceBarHeldContext: DrawingContextObserver<boolean>) {
    this._sharedSpaceBarHeldContext = sharedSpaceBarHeldContext;
  }

  public set drawMode(mode: DrawingsDrawMode) {
    this._drawMode = mode;
  }

  public get canDrag(): boolean {
    return DrawingsCommonUtils.isDraggableByDrawMode(this._drawMode) && !this._sharedSpaceBarHeldContext.getContext();
  }

  public onStartElementDrag(e: PaperMouseEvent | React.MouseEvent<HTMLDivElement>, methodToExecute: () => void): void {
    if (this.canDrag) {
      ConstantFunctions.stopEvent(e);
      this._dragExecutor.execute(methodToExecute);
    }
  }

  @autobind
  public stopExecutor(): void {
    this._dragExecutor.reset();
  }
}
