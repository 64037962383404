import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ProjectType } from 'common/constants/project-type';
import { ActionWith } from 'common/interfaces/action-with';
import { State } from 'common/interfaces/state';

import { CREATE_DEMO_PROJECT, UPDATE_DEMO_PROJECT } from '../../../constants/forms';
import { AdminDemoProjectsActions } from '../actions/creators/demo-projects';
import { DeleteDemoProjectPayload } from '../actions/payloads/demo-projects';
import { AdminDemoProjectsActionTypes } from '../actions/types/demo-projects';
import { AdminDemoProjectsApi } from '../api/demo-projects';


function* createDemoProject(): SagaIterator {
  try {
    const { formValues, dumpBlobKey } = yield select((st: State) => {
      return {
        formValues: st.form[CREATE_DEMO_PROJECT].values,
        dumpBlobKey: st.common.files[0].uploadedFileName,
      };
    });
    const tags =  formValues.tags ? Array.from(new Set(formValues.tags.split(',').map(t => t.trim()))) : [];
    const model = {
      name: formValues.name,
      orderIndex: formValues.orderIndex,
      screenshotBlobKey: formValues.image,
      dumpBlobKey,
      isImperial: !!formValues.isImperial,
      tags,
      description: formValues.description,
    };

    yield call(AdminDemoProjectsApi.createDemoProject, formValues.type, model);
    yield put(AdminDemoProjectsActions.loadDemoProjects(formValues.type));
  } catch (error) {
    console.error('admin demo projects: create demo project failed', error);
  }
}

function* updateDemoProject(): SagaIterator {
  try {
    const { values: formValues, initial } = yield select((st: State) => st.form[UPDATE_DEMO_PROJECT]);
    const tags =  formValues.tags ? Array.from(new Set(formValues.tags.split(',').map(t => t.trim()))) : [];

    const model = {
      name: formValues.name,
      screenshotBlobKey: formValues.image,
      isImperial: !!formValues.isImperial,
      tags,
      description: formValues.description,
    };

    if (formValues.orderIndex && initial.orderIndex !== formValues.orderIndex) {
      AdminDemoProjectsApi.reorderDemoProject(formValues.id, formValues.orderIndex);
    }

    yield call(AdminDemoProjectsApi.updateDemoProject, formValues.id, model);
    yield put(AdminDemoProjectsActions.loadDemoProjects(formValues.type));
  } catch (error) {
    console.error('admin demo projects: update demo project failed', error);
  }
}

function* deleteDemoProject({ payload }: ActionWith<DeleteDemoProjectPayload>): SagaIterator {
  try {
    yield call(AdminDemoProjectsApi.deleteDemoProject, payload.id);
    yield put(AdminDemoProjectsActions.loadDemoProjects(payload.type));
  } catch (error) {
    console.error('admin demo projects: delete demo project failed', error);
  }
}

function* loadDemoProjects({ payload }: ActionWith<ProjectType>): SagaIterator {
  try {
    const demoProjects = yield call(AdminDemoProjectsApi.getDemoProjects, payload);
    yield put(AdminDemoProjectsActions.loadDemoProjectsSucceeded(demoProjects));
  } catch (error) {
    console.error('admin demo projects: create demo project failed', error);
  }
}

export function* adminDemoProjectsSagas(): SagaIterator {
  yield takeLatest(AdminDemoProjectsActionTypes.CREATE_DEMO_PROJECT, createDemoProject);
  yield takeLatest(AdminDemoProjectsActionTypes.UPDATE_DEMO_PROJECT, updateDemoProject);
  yield takeLatest(AdminDemoProjectsActionTypes.DELETE_DEMO_PROJECT, deleteDemoProject);
  yield takeLatest(AdminDemoProjectsActionTypes.LOAD_DEMO_PROJECTS, loadDemoProjects);
}
