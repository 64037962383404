import { FooterValue, NumberColumnDef, StringColumnDef } from '../../../constants';
import { GROUP_HIERARCHY_PREFIX, getColumn as getGroupHierarchyColumn } from '../../extends-by-group';
import { Column, ColumnType, PropertyColumn } from '../interfaces';

const groupOrder = {
  defaultColumn: 1,
  propertyColumn: 2,
  groupHierarchy: 3,
  breakDownColumn: 4,
};

const defaultColumnDef: Record<string, Column> = {
  itemName: {
    ...StringColumnDef,
    field: 'itemName',
    colId: 'itemName',
    headerName: 'Item name',
    checkboxSelection: (params) => !params.columnApi.getRowGroupColumns().length,
    headerCheckboxSelection: (params) => !params.columnApi.getRowGroupColumns().length,
    valueGetter: (params) => {
      const value = params.data?.itemName;
      if (params.columnApi.getRowGroupColumns().length || value) {
        return value;
      } else {
        return FooterValue;
      }
    },
  },
  assemblyName: {
    field: 'assemblyName',
    colId: 'assemblyName',
    headerName: 'Assembly name',
    ...StringColumnDef,
  },
  fileName: {
    field: 'fileName',
    colId: 'fileName',
    headerName: `File name`,
    ...StringColumnDef,
  },
  pageName: {
    field: 'pageName',
    colId: 'pageName',
    headerName: `Page name`,
    ...StringColumnDef,
  },
  parentGroup: {
    field: 'parentGroup',
    colId: 'parentGroup',
    headerName: 'Parent group',
    ...StringColumnDef,
  },
  measureName: {
    field: 'measureName',
    colId: 'measureName',
    headerName: 'Measurement name',
    ...StringColumnDef,
  },
};

export const defaultColumSet: Set<string> = new Set([
  'itemName',
  'assemblyName',
  'fileName',
  'pageName',
  'parentGroup',
  'measureName',
]);

export const defaultColumnOrder = {
  'itemName': 1,
  'assemblyName': 2,
  'fileName': 3,
  'pageName': 4,
  'parentGroup': 5,
  'measureName': 6,
};

const innerField: Set<string> = new Set([
  'id',
  'measureId',
  'fileId',
  'pageId',
  'parentGroupId',
]);

const getDefaultColDef = (field: string): Column => {
  return defaultColumnDef[field];
};

const getCommonColDef = (field: string): Column => {
  return ({
    field,
    colId: field,
    headerName: field,
    ...StringColumnDef,
  });
};

const getPropertyColumn = (propertyColumn: PropertyColumn): Column => {
  const { colDef: def, colType: type } = propertyColumn;

  if (type === ColumnType.String) {
    return def;
  }
  return {
    ...def,
    ...NumberColumnDef,
    enableValue: true,
  };
};

const getGroupColumn = (field: string): Column => {
  const index = Number(field.replace(GROUP_HIERARCHY_PREFIX, ''));
  return getGroupHierarchyColumn(index);
};

export const getColDef = (
  field: string,
  propertyColumns: Record<string, PropertyColumn>,
  breakDownPropertyColumns: Record<string, Column>,
): Column => {
  if (innerField.has(field)) {
    return null;
  }
  if (defaultColumSet.has(field)) {
    const colDef = getDefaultColDef(field);
    colDef.orderGroup = groupOrder.defaultColumn;
    return colDef;
  }
  if (propertyColumns[field]) {
    const colDef = getPropertyColumn(propertyColumns[field]);
    colDef.orderGroup = groupOrder.propertyColumn;
    return colDef;
  }
  if (field.startsWith(GROUP_HIERARCHY_PREFIX)) {
    const colDef = getGroupColumn(field);
    colDef.orderGroup = groupOrder.groupHierarchy;
    return colDef;
  }
  if (breakDownPropertyColumns[field]) {
    const colDef = breakDownPropertyColumns[field];
    colDef.orderGroup = groupOrder.breakDownColumn;
    return colDef;
  }
  return getCommonColDef(field);
};
