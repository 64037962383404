
import React, { useContext } from 'react';
import { MeasureValue } from '../../drawings-annotation-legend-item';
import { GroupMeasuresContext } from './group-measure-context';

export interface WithGroupMeasureContextProps {
  groupId: string;
  measureValues: MeasureValue[];
}


export function withGroupMeasureContext<P extends WithGroupMeasureContextProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, 'measureValues'>>> {

  return (props: P) => {
    const groupMeasures = useContext(GroupMeasuresContext);
    return <Component {...props as P} measureValues={groupMeasures[props.groupId]} />;
  };
}
