import { TinyText } from '@kreo/kreo-ui-components';
import * as React from 'react';


import { Styled } from './styled';

interface Props {
  filesCount: number;
}


export class DrawingsPdfBrowserFileTreeHead extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { filesCount, children } = this.props;
    return (
      <Styled.Container>
        <Styled.FilesCount>
          <TinyText color={'white'}>{filesCount}</TinyText>
        </Styled.FilesCount>
        <TinyText color={'gray'}>FILES</TinyText>
        { children }
      </Styled.Container>
    );
  }
}
