import { RectangleButton } from '@kreo/kreo-ui-components';
import React from 'react';
import { Text } from 'common/components/text';
import { Mood } from 'common/enums/mood';
import { Styled } from './styled';

interface Props {
  startCalibrate: () => void;
}

const ScaleNoAppliedComponent: React.FC<Props> = ({ startCalibrate }) => {
  return (
    <Styled.ScaleNoAppliedContainer>
      <Text
        fontSize={14}
        lineHeight={21}
      >
        This page is not scaled. Enter the page's scale to get the correct values.
      </Text>
      <RectangleButton
        size="s"
        text='Set Scale'
        onClick={startCalibrate}
        mood={Mood.Negative}
        fontSize={14}
      />
    </Styled.ScaleNoAppliedContainer>
  );
};


export const ScaleNoApplied = React.memo(ScaleNoAppliedComponent);
