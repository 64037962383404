import { ICellEditorParams } from 'ag-grid-community';
import autobind from 'autobind-decorator';

import { MetricUnitConversationMap } from 'common/utils/unit-util';
import { ValueHelper } from 'common/utils/value-helper';
import { DefaultPivotColumnKey } from '../../../enums/default-pivot-column-key';
import { QtoTreeRowProperty } from '../../../interfaces/quantity-take-off';
import { PropertyHelper } from '../../../utils/quantity-take-off-tree-table';
import { QtoTreeTableCommon } from '../../quantity-take-off-report-table/quantity-take-off-tree-table-common';
import { CellEditorType } from '../../quantity-take-off-tree-table-cell-edit/cell-editor-type';
import { DefaultCellEditorHelper } from '../../quantity-take-off-tree-table-cell-edit/default-cell-editor-helper';
import { PivotCellEditor } from './pivot-cell-editor';

export class PivotTableDefaultCellEditor extends PivotCellEditor<HTMLInputElement> {
  public constructor(params: ICellEditorParams, eGui: HTMLDivElement) {
    super(params, eGui);
    this.cellEditorType = CellEditorType.DefaultCellEditor;
  }

  public static getInnerHtml(): string {
    return DefaultCellEditorHelper.getInnerHtml(this.className);
  }

  public getNode(value: string): Node {
    const input = DefaultCellEditorHelper.getInputElement(value, PivotCellEditor.className);
    this.eValue = input;
    return input;
  }

  public initEvents(): void {
    // do nothing
  }

  public destroyEvents(): void {
    // do nothing
  }

  @autobind
  public getCurrentValue(): string {
    return this.eValue.value;
  }

  public setFocus(): void {
    this.eValue.focus();
  }

  protected setEValue(value: string): void {
    this.eValue.value = value;
  }

  protected currentInitEValue(): void {
    this.eValue.value = ValueHelper.isNumberValue(this.params.value)
      ? this.getConvertedValue(this.params.value)
      : this.params.value || null;
  }

  protected isEmpty(): boolean {
    return !this.eValue.value;
  }

  protected getNewValue(): QtoTreeRowProperty {
    const colId = this.params.column.getId();
    const property = this.propertyGetter(colId) || {};
    const hasDefaultValue = property && property.default;
    if (!this.eValue.value && !hasDefaultValue && colId !== DefaultPivotColumnKey.Quantity) {
      return undefined;
    }

    const valueWithDots = this.eValue.value.replace(',', '.');
    const value = ValueHelper.isNumberValue(valueWithDots)
      ? Number(valueWithDots)
      : this.eValue.value || null;
    property.override = value !== this.getConvertedValue(property.default)
      ? ValueHelper.isNumberValue(value)
        ? this.getValueFormConverted(Number(value))
        : value
      : null;

    return property;
  }

  private getValueFormConverted(value: number): number {
    const unit = MetricUnitConversationMap[this.getUnit()];
    const isImperial = this.params.context.isImperial && this.params.column.getId() === DefaultPivotColumnKey.Quantity;
    return QtoTreeTableCommon.imperialValueConverter(value, unit, isImperial);
  }

  private getUnit(): string {
    const unitProperty = this.propertyGetter(DefaultPivotColumnKey.Unit);
    return PropertyHelper.getActualValue(unitProperty);
  }
}
