import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';


import { IcicleButton } from 'common/components/icicle-button';
import { Styled } from './styled';

interface Props {
  left?: boolean;
  enabled: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const ScrollArrowComponent: React.FC<Props> = ({ left, enabled, onClick }) => {
  return (
    <Styled.Container left={left} >
      <IcicleButton
        horizontal={true}
        enabled={enabled}
        onClick={onClick}
        Icon={Icons.RightSmall}
      />
    </Styled.Container>
  );
};

export const ScrollArrow = React.memo(ScrollArrowComponent);
