import { CompanyResourcesApi } from '../../../api/server';
import {
  CreateCompanyReportTemplate,
  CreateProjectReportTemplate,
  ReportTemplateInfoViewModel,
  ReportTemplates,
  UpdateReportVariables,
} from '../interfaces';

function createReportTemplate(payload: CreateCompanyReportTemplate): Promise<void> {
  return CompanyResourcesApi.post('/report-template', payload);
}

function getTemplates(): Promise<ReportTemplateInfoViewModel[]> {
  return CompanyResourcesApi.get('/report-template');
}

function deleteTemplate(id: number): Promise<void> {
  return CompanyResourcesApi.delete(`/report-template/${id}`, undefined);
}

function updateTemplate(id: number, name: string): Promise<void> {
  return CompanyResourcesApi.put(`/report-template/${id}`, { name });
}

function getApplyTemplate(projectId: number): Promise<ReportTemplates> {
  return CompanyResourcesApi.get(`/projects/${projectId}/report-template`);
}

function applyTemplate(payload: CreateProjectReportTemplate): Promise<void> {
  return CompanyResourcesApi.post(`/projects/${payload.projectId}/report-template`, payload.form);
}

function deleteProjectTemplate(payload: CreateProjectReportTemplate): Promise<void> {
  return CompanyResourcesApi.delete(
    `/projects/${payload.projectId}/report-template/${payload.form.companyTemplateId}`,
    undefined,
  );
}

function exportTemplate(payload: CreateProjectReportTemplate): Promise<void> {
  const { projectId, form } = payload;
  return CompanyResourcesApi.downloadFile(
    `/projects/${projectId}/report-template/${form.companyTemplateId}/excel`,
  );
}

function updateVariableValues(payload: UpdateReportVariables): Promise<void> {
  const { projectId, form } = payload;
  return CompanyResourcesApi.put(
    `/projects/${projectId}/report-template/variables`,
    form,
  );
}

export const ReportTemplateApi = {
  createReportTemplate,
  getTemplates,
  deleteTemplate,
  updateTemplate,
  getApplyTemplate,
  applyTemplate,
  deleteProjectTemplate,
  exportTemplate,
  updateVariableValues,
};
