import styled from 'styled-components';

const Container = styled.div<{ selected?: boolean }>`
  background-color: ${p => p.selected ? p.theme.color.turquoise : 'inherit'};
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  svg {
    margin: auto;
    fill: ${p => p.selected ? p.theme.color.white : p.theme.color.gray};
  }
  &:hover {
    svg {
      color: ${p => (p.selected ? p.theme.color.white : p.theme.color.turquoise)};
      fill: ${p => (p.selected ? p.theme.color.white : p.theme.color.turquoise)};
    }
  }
`;

export const Styled = {
  Container,
};
