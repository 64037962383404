import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import { State as ReduxState } from 'common/interfaces/state';

import './activity-groups.scss';

import {
  KreoIconCreateNew,
  KreoIconNavbarBack,
  KreoScrollbars,
} from 'common/UIKit';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { ActivityGroupingActions, EngineActions, StepActions } from '../../actions';
import {
  RemoveUpdateHighlighDelay,
  ScrollDelay,
  SetEngineHighlighOnHoverDelay,
  UngroupedCategoryId,
} from '../../constants';
import { ActivityGroupData } from '../../interfaces';
import { BimElementIdsHelper, EntityCompareHelper } from '../../utils';
import { ActivityGroupCategory } from './category';

interface ReduxProps {
  activeWorkPackage: ActivityGroupData.WorkPackage | null;
  categories: Record<number, ActivityGroupData.Category>;
  activityGroups: Record<number, ActivityGroupData.ActivityGroup>;
  draggingActivity: ActivityGroupData.Activity | null;
  draggingActivityGroup: ActivityGroupData.ActivityGroup | null;
  activeActivityGroupId: number;
  activeCategoryId: number;
  newCategoryId: number;
  newActivityGroupId: number;
  selectedActivityGroupIds: Record<number, boolean>;
}

interface ReduxActions {
  setActiveActivityGroupOrUngroupedActivity(groupId: number | null): void;
  setActiveCategory(categoryId: number | null): void;
  backToWorkPackages(): void;
  createCategory(workPackageId: number): void;
  renameActivityGroup(activityGroupId: number, name: string): void;
  renameCategory(categoryId: number, name: string): void;
  removeCategory(categoryId: number): void;
  removeActivityGroup(activityGroupId: number): void;
  replaceActivityGroup(activityGroupId: number, categoryId: number): void;
  replaceActivity(activityId: number, activityGroupId: number): void;
  setDraggingActivityGroup(activityGroupId: number): void;
  createActivityGroup(categoryId: number): void;
  mergeActivityGroups(destinationId: number, mergedId: number): void;
  replaceSelectedActivities(destinationActivityGroupId: number): void;
  resetSelectedActivityIds(): void;
}

interface State {
  changedCategoryId: number | null;
}

interface Props extends ReduxProps, ReduxActions {
  scenarioId: number;
  onHover: (engineIds: number[]) => void;
}

class ActivityGroupsComponent extends React.Component<Props, State> {
  private deferredExecutor: DeferredExecutor;

  constructor(props: Props) {
    super(props);
    this.state = {
      changedCategoryId: null,
    };

    this.deferredExecutor = new DeferredExecutor(SetEngineHighlighOnHoverDelay);
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.props.activeCategoryId !== prevProps.activeCategoryId &&
      !Number.isInteger(this.props.activeActivityGroupId) ||
      this.state.changedCategoryId !== prevState.changedCategoryId
    ) {
      ActivityGroupsComponent.scrollToActiveCategory();
    }
  }

  public render(): JSX.Element {
    return (
      <div className='activity-groups-area'>
        <div className='activity-groups-area__container'>
          <div className='activity-groups-area__header'>
            <div
              className='activity-groups-area__header-back-button'
              onClick={this.props.backToWorkPackages}
            >
              <KreoIconNavbarBack/>
            </div>
            <div className={'work-package-name'}>
              {this.props.activeWorkPackage.name}
            </div>
          </div>
          <div className='activity-groups-area__content'>
            <KreoScrollbars>
              {this.renderUngroupedCategoryComponent()}
              {this.renderActivityGroupCategoryComponents()}
            </KreoScrollbars>
          </div>
          <div className='activity-groups-area__footer'>
            <div className='activity-groups-area__footer-button-container'>
            <div className='activity-groups-area__footer-button'>
              <KreoIconCreateNew/>
              <div className='activity-groups-area__footer-dropdown'>
                <div className='activity-groups-area__footer-dropdown-item' onClick={this.createCategory}>
                  Category
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount(): void {
    this.props.resetSelectedActivityIds();
  }

  private static scrollToActiveCategory(): void {
    setTimeout(
      () => {
        const activeCategories = document.getElementsByClassName('activity-group-category active');
        const changedCategories = document.getElementsByClassName('activity-group-category was-changed');
        const category = activeCategories && activeCategories.length > 0
          ? activeCategories[0] as HTMLElement
          : changedCategories && changedCategories.length > 0
            ? changedCategories[0] as HTMLElement
            : null;

        if (category) {
          const container = category.closest('.scroll-box__content');
          const categoryPosition = category.offsetTop;

          const isVisible = container.scrollTop < categoryPosition &&
          categoryPosition < container.scrollTop + container.clientHeight;
          if (!isVisible) {
            category.scrollIntoView();
          }
        }
      },
      ScrollDelay,
    );
  }

  @autobind
  private renderActivityGroupCategoryComponents(): JSX.Element[] {
    const props = this.props;
    return props.activeWorkPackage.categoryIds
      .map(categoryId => props.categories[categoryId])
      .sort(EntityCompareHelper.compareNamedEntity)
      .map((category) => (
        <ActivityGroupCategory
          key={category.id}
          id={category.id}
          name={category.name}
          selectedActivityGroupIds={props.selectedActivityGroupIds}
          activityGroups={category.activityGroupIds.map(id => props.activityGroups[id])}
          setActiveActivityGroup={props.setActiveActivityGroupOrUngroupedActivity}
          wasChanged={this.state.changedCategoryId === category.id}
          newActivityGroupId={this.props.newActivityGroupId}
          renameActivityGroup={props.renameActivityGroup}
          renameCategory={this.renameCategory}
          draggingActivity={props.draggingActivity}
          draggingActivityGroup={props.draggingActivityGroup}
          activeActivityGroupId={props.activeActivityGroupId}
          activeCategoryId={props.activeCategoryId}
          removeCategory={props.removeCategory}
          replaceActivity={props.replaceActivity}
          replaceActivityGroup={props.replaceActivityGroup}
          setDraggingActivityGroup={props.setDraggingActivityGroup}
          createActivityGroup={props.createActivityGroup}
          removeActivityGroup={props.removeActivityGroup}
          mergeActivityGroups={props.mergeActivityGroups}
          onActivityGroupHover={this.onActivityGroupHover}
          replaceSelectedActivities={props.replaceSelectedActivities}
        />));
  }

  @autobind
  private renderUngroupedCategoryComponent(): React.ReactNode {
    const ungroupedActivityGroupsCount = this.props.activeWorkPackage.ungroupedIds.length;
    if (ungroupedActivityGroupsCount === 0) {
      return null;
    }

    const active = this.props.activeCategoryId === UngroupedCategoryId ? 'active' : '';
    return (
      <div className='activity-groups-area__ungrouped-category-wrap'>
        <div
          className={`activity-groups-area__ungrouped-category ${active}`}
          onClick={this.onUngroupedCategoryClick}
          onMouseOver={this.onUngroupedCategoryMouseOver}
          onMouseLeave={this.onUngroupedCategoryMouseLeave}
        >
          <div className='activity-groups-area__ungrouped-category-name'>
            Ungrouped Activities
          </div>
          <div className='activity-groups-area__ungrouped-category-count'>
            {ungroupedActivityGroupsCount}
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private onUngroupedCategoryClick(): void {
    if (this.props.activeCategoryId === UngroupedCategoryId) {
      this.props.setActiveCategory(null);
    } else {
      this.props.setActiveCategory(UngroupedCategoryId);
    }
  }

  @autobind
  private onUngroupedCategoryMouseOver(): void {
    if (this.props.onHover) {
      this.deferredExecutor.execute(() => {
        const bimIds = BimElementIdsHelper.getUngroupedActivitiesBimIds(this.props.activeWorkPackage.id);
        this.props.onHover(bimIds);
      });
    }
  }

  @autobind
  private onUngroupedCategoryMouseLeave(): void {
    if (this.props.onHover) {
      if (this.deferredExecutor.isWaitingForExecution()) {
        this.deferredExecutor.reset();
      }
      this.props.onHover([]);
    }
  }

  @autobind
  private onActivityGroupHover(activityGroupId: number | null): void {
    if (Number.isInteger(activityGroupId)) {
      this.deferredExecutor.execute(() => {
        this.props.onHover(BimElementIdsHelper.getActivityGroupBimIds(activityGroupId));
      });
    } else {
      if (this.deferredExecutor.isWaitingForExecution()) {
        this.deferredExecutor.reset();
      }
      this.props.onHover([]);
    }
  }

  @autobind
  private setChangedCategory(categoryId: number): void {
    this.setState({ changedCategoryId: categoryId });
    const that = this;
    setTimeout(
      () => {
        if (that.state.changedCategoryId === categoryId)
          that.setState({ changedCategoryId: null });
      },
      RemoveUpdateHighlighDelay,
    );
  }

  @autobind
  private createCategory(): void {
    this.setChangedCategory(this.props.newCategoryId);
    this.props.createCategory(this.props.activeWorkPackage.id);
  }

  @autobind
  private renameCategory(categoryId: number, name: string): void {
    this.setChangedCategory(categoryId);
    this.props.renameCategory(categoryId, name);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    activeWorkPackage: state.activityGrouping.workPackages[state.activityGrouping.activeWorkPackageId],
    activityGroups: state.activityGrouping.activityGroups,
    categories: state.activityGrouping.categories,
    draggingActivity: state.activityGrouping.activityGrouping.dndItems.activity,
    draggingActivityGroup: state.activityGrouping.activityGrouping.dndItems.activityGroup,
    activeActivityGroupId: state.activityGrouping.activeActivityGroupId,
    activeCategoryId: state.activityGrouping.activeCategoryId,
    newActivityGroupId: state.activityGrouping.activityGrouping.availableIds.activityGroup,
    newCategoryId: state.activityGrouping.activityGrouping.availableIds.category,
    selectedActivityGroupIds: state.activityGrouping.activityGrouping.selectedActivityGroupIds,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setActiveActivityGroupOrUngroupedActivity: groupId =>
      dispatch(StepActions.setActiveActivityGroupOrUngroupedActivity(groupId)),
    setActiveCategory: categoryId => dispatch(StepActions.setActiveCategory(categoryId)),
    backToWorkPackages: (): void => {
      dispatch(StepActions.setActiveWorkPackage(null));
      dispatch(ActivityGroupingActions.setSelectedActivityIds({}));
      dispatch(EngineActions.resetColoredBimElements());
      dispatch(EngineActions.setHighlightedBimElements([]));
    },
    createActivityGroup: (categoryId: number): void => {
      dispatch(ActivityGroupingActions.createActivityGroup(categoryId));
    },
    createCategory: (workPackageId: number): void => {
      dispatch(ActivityGroupingActions.createCategory(workPackageId));
    },
    renameActivityGroup: (activityGroupId: number, name: string): void => {
      dispatch(ActivityGroupingActions.renameActivityGroup(activityGroupId, name));
    },
    renameCategory: (categoryId: number, name: string): void => {
      dispatch(ActivityGroupingActions.renameCategory(categoryId, name));
    },
    removeCategory: (categoryId: number): void => {
      dispatch(ActivityGroupingActions.removeCategory(categoryId));
    },
    replaceActivityGroup: (activityGroupId: number, categoryId: number): void => {
      dispatch(ActivityGroupingActions.replaceActivityGroup(activityGroupId, categoryId));
    },
    replaceActivity: (activityId: number, activityGroupId: number): void => {
      dispatch(ActivityGroupingActions.replaceActivity(activityId, activityGroupId));
    },
    setDraggingActivityGroup: (activityGroupId: number): void => {
      dispatch(ActivityGroupingActions.setDraggingActivityGroup(activityGroupId));
    },
    removeActivityGroup: (activityGroupId: number): void => {
      dispatch(ActivityGroupingActions.removeActivityGroup(activityGroupId));
    },
    mergeActivityGroups: (destinationId, mergedId: number) =>
      dispatch(ActivityGroupingActions.mergeActivityGroups(destinationId, mergedId)),
    replaceSelectedActivities: destinationActivityGroupId =>
      dispatch(ActivityGroupingActions.replaceSelectedActivities(destinationActivityGroupId)),
    resetSelectedActivityIds: () =>
      dispatch(ActivityGroupingActions.setSelectedActivityIds({})),
  };
};

const ActivityGroupsComponentContext = (ActivityGroupsComponent);
const connector = connect(mapStateToProps, mapDispatchToProps);

export const ActivityGroups = connector(ActivityGroupsComponentContext);
