import { UpdateCellData, UpdateColumnData } from 'common/components/excel-table';
import { TwoDViewTableConfig } from 'unit-2d-database/interfaces';
import { CommonApi, CompanyResourcesApi, ProjectResourcesApi } from '../../../api/server';
import { SetNewPagePayload } from '../actions/payloads';
import {
  AssignedPia,
  ReportPage,
  ReportPageData,
  TablePreset,
  TablePresetsC,
} from '../interfaces';
import { AssignPiaPatch } from '../interfaces/pia-patch';

function getPages(projectId: string | number): Promise<ReportPage[]> {
  return CompanyResourcesApi.get(`/projects/${projectId}/table-report/pages`);
}

function createPage(): Promise<SetNewPagePayload> {
  return ProjectResourcesApi.post(`/table-report/pages/table`);
}

function createView(
  configuration: TwoDViewTableConfig,
): Promise<SetNewPagePayload> {
  return ProjectResourcesApi.post(
    '/table-report/pages/view',
    configuration,
  );
}

function updateView(
  projectId: string,
  pageId: string,
  configuration: TwoDViewTableConfig,
): Promise<SetNewPagePayload> {
  return CompanyResourcesApi.put(
    `/projects/${projectId}/table-report/pages/${pageId}/view`,
    configuration,
  );
}

function duplicatePage(pageId: string): Promise<void> {
  return ProjectResourcesApi.post(`/table-report/pages/${pageId}/duplicate`);
}

function updatePageInfo(projectId: string, data: ReportPage): Promise<void> {
  return CompanyResourcesApi.put(
    `/projects/${projectId}/table-report/pages/`,
    [data],
  );
}

function moveToPage(pageId: string, offset: number): Promise<void> {
  return ProjectResourcesApi.put(`/table-report/pages/${pageId}/move/${offset}`, undefined);
}

function downloadExcelFile(projectId: string): Promise<void> {
  return CompanyResourcesApi.downloadFile(`/projects/${projectId}/table-report/excel`);
}

function postFiles(projectId: number, files: string[]): Promise<void> {
  return CompanyResourcesApi.post(
    `/projects/${projectId}/table-report/excel`,
    files,
  );
}

function deletePage(pageId: string): Promise<ReportPage[]> {
  return ProjectResourcesApi.delete(`/table-report/pages/`, [pageId]);
}

function restorePage(pageId: string): Promise<ReportPage[]> {
  return ProjectResourcesApi.put(`/table-report/pages/undo-delete`, [pageId]);
}

function addRows(
  projectId: string,
  pageId: string,
  count: number,
  etag: number,
  sessionId: string,
): Promise<void> {
  return CompanyResourcesApi.post(
    `/projects/${projectId}/table-report/pages/${pageId}/rows?count=${count}&etag=${etag}&sessionId=${sessionId}`);
}

function addColumns(
  projectId: string,
  pageId: string,
  count: number,
  etag: number,
  sessionId: string,
): Promise<void> {
  return CompanyResourcesApi.post(
    `/projects/${projectId}/table-report/pages/${pageId}/columns?count=${count}&etag=${etag}&sessionId=${sessionId}`);
}

function updateRows(
  projectId: string,
  pageId: string,
  rows: UpdateCellData[],
  etag: string,
  sessionId: string,
): Promise<{ etag: number }> {
  return CompanyResourcesApi.put(
    `/projects/${projectId}/table-report/pages/${pageId}/table`, { updatedCells: rows, etag, sessionId });
}

function updateColumns(
  pageId: string,
  columns: UpdateColumnData[],
  etag: string,
  sessionId: string,
): Promise<void> {
  return ProjectResourcesApi.put(
    `/table-report/pages/${pageId}/table`, { updatedColumns: columns, etag, sessionId });
}

function getPageData(pageId: string, projectId: string): Promise<ReportPageData> {
  return CompanyResourcesApi.get(`/projects/${projectId}/table-report/pages/${pageId}/table`);
}

function getViewConfig(pageId: string): Promise<{ configuration: TwoDViewTableConfig }> {
  return ProjectResourcesApi.get(`/table-report/pages/${pageId}/view`);
}

function getTablePresets(): Promise<TablePreset[]> {
  return CompanyResourcesApi.getV('/group-table-presets', TablePresetsC);
}

function createTablePresets(presets: TablePreset[]): Promise<void> {
  return CompanyResourcesApi.post('/group-table-presets', presets);
}

function updateTablePresets(presets: TablePreset[]): Promise<void> {
  return CompanyResourcesApi.put('/group-table-presets', presets);
}

function deleteTablePresets(ids: string[]): Promise<void> {
  return CompanyResourcesApi.delete('/group-table-presets', ids);
}

function sendAssignPatchList(assign: AssignPiaPatch[], projectId: string | number): Promise<void> {
  return CompanyResourcesApi.patch(`/projects/${projectId}/drawings/pia`, assign);
}

function fetchAssignPia(): Promise<AssignedPia> {
  return ProjectResourcesApi.get('/drawings/pia');
}

function downloadInvoicesPdf(path: string): void {
  return CommonApi.downloadFileFromAnotherSite(path);
}

export const TwoDApi = {
  getPages,
  createPage,
  createView,
  updateView,
  getViewConfig,
  updatePageInfo,
  addRows,
  addColumns,
  updateRows,
  getPageData,
  downloadExcelFile,
  deletePage,
  restorePage,
  postFiles,
  moveToPage,
  duplicatePage,
  updateColumns,

  getTablePresets,
  createTablePresets,
  updateTablePresets,
  deleteTablePresets,

  sendAssignPatchList,
  fetchAssignPia,

  downloadInvoicesPdf,
};
