import * as React from 'react';

import './radio-button.scss';

interface Props {
  checked: boolean;
  value?: React.ReactText;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export class KreoRadioButton extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <label className='kreo-radio-button'>
        <input
          onChange={this.props.onChange}
          type='radio'
          value={this.props.value}
          checked={this.props.checked}
          name={this.props.name}
        />
        <span className='kreo-radio-button__checkmark'/>
      </label>
    );
  }
}
