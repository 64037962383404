import { EMAIL } from '../../constants/regex-templates';

function Required<TValue = React.ReactText>(value: TValue): string | undefined {
  if (Array.isArray(value)) {
    return value.length > 0 ? undefined : 'Required';
  }
  return value ? undefined : 'Required';
}

const FieldRequired = (fieldName: string) => (value: React.ReactText): string => {
  return Required(value) ? `${fieldName} is required` : undefined;
};

const maxLength = (max: number): ((value: string) => string) => (
  value: string,
): string => {
  return value && value.length > max
    ? `Must be ${max} characters or less`
    : undefined;
};

const MaxLength15 = maxLength(15);

const minLength = (min: number): ((value: string) => string) => (
  value: string,
): string => {
  return value && value.length < min
    ? `Must be ${min} characters or more`
    : undefined;
};

const MinLength2 = minLength(2);

const OnlyNumber = (value: string): string => {
  return value && isNaN(Number(value)) ? 'Must be a number' : undefined;
};

const Email = (value: string): string => {
  return value && !EMAIL
    .test(value)
    ? 'Invalid email address'
    : undefined;
};

const length = (len: number): ((value: string) => string) => (
  value: string,
): string => {
  return value && value.length !== len
    ? `Must be ${len} characters`
    : undefined;
};

const GreaterThan = (limit: number, fieldName?: string): ((value: number) => string) => (
  value: number,
): string => {
  if (!(value > limit)) {
    return fieldName
      ? `${fieldName} should be more then ${limit}`
      : `Should be more then ${limit}`;
  }
};

const LessThan = (limit: number, fieldName?: string): ((value: number) => string) => (
  value: number,
): string => {
  if (!(value < limit)) {
    return fieldName
      ? `${fieldName} should be less then ${limit}`
      : `Should be less then ${limit}`;
  }
};


const Length3 = length(3);
const Length16 = length(16);

export {
  Required,
  MaxLength15,
  MinLength2,
  OnlyNumber,
  Email,
  Length3,
  Length16,
  GreaterThan,
  LessThan,
  FieldRequired,
};
