import styled, { css } from 'styled-components';


const Left = css`
  transform: rotate(180deg) translateX(100%);
  left: 0px;
`;

const Right = css`
  transform: rotate(0deg) translateX(100%);
  right: 0px;
`;

const Container = styled.div<{ left?: boolean }>`
  height: 100%;
  width: 40px;
  ${ p => p.left ? Left : Right }
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Styled = {
  Container,
};
