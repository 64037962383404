import { RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { Mood } from 'common/enums/mood';
import { TextInput } from '../inputs';
import { Text } from '../text';
import { Styled } from './styled';

interface Props {
  onMessageSend: (text: string) => void;
}

const MessageContentComponent: React.FC<Props> = ({ onMessageSend }) => {
  const [message, setMessage] = React.useState<string>('');

  const onMessageSendCallback = useCallback(() => {
    onMessageSend(message);
  }, [message, onMessageSend]);

  return (
    <Styled.ContentContainer>
      <Styled.MessageContentTitle>
        <Text
          fontSize={14}
          lineHeight={18}
          letterSpacing={0}
        >
          What could be improved? <span>(optional)</span>
        </Text>
      </Styled.MessageContentTitle>
      <TextInput
        value={message}
        onChange={setMessage}
        onBlur={setMessage}
        isArea={true}
        placeholder='Enter your feedback'
        areaRows={3}
      />
      <RectangleButton
        size='s'
        mood={Mood.Secondary}
        onClick={onMessageSendCallback}
        text='Send'
        width={52}
        height={22}
        fontSize={14}
      />
    </Styled.ContentContainer>
  );
};


export const MessageContent = React.memo(MessageContentComponent);
