import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

interface Props {
  progress: number;
  isError: boolean;
}

function getColors({ progress, isError, theme }: Props & { theme: any }): FlattenSimpleInterpolation {
  if (isError) {
    return css`
      svg {
        fill: ${theme.color.red};  
      }
      p {
        color: ${theme.color.red};
      }
    `;
  } if (progress === 100) {
    return css`
      svg {
        fill: ${theme.color.mainFont};
      }
    `;
  }
}

const Container = styled.div<Props>`
  height: 40px;
  width: 100%;
  background: ${props => props.theme.color.background};
  display: flex;
  align-items: center;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  padding: 10px 15px;
  box-sizing: border-box;
  &:after {
    content: '';
    width: ${props => props.progress}%;
    height: 100%;
    background: ${props => props.theme.color.turquoise};
    position: absolute;
    border-radius: 20px;
    left: 0;
  }
  svg {
    width: 20px;
    height: 20px;
    fill: ${props => props.theme.color.mainFont};
  }

  ${getColors}
`;


const Content = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr 33px;
  grid-column-gap: 10px;
  z-index: 2;
  align-items: center;
  width: 100%;
`;

export const Styled = {
  Container,
  Content,
};
