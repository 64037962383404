import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import * as uuidv4 from 'uuid/v4';
import { AgGridTooltipUtils } from 'common/UIKit/ag-grid-tooltip-utils';

import './tree-table-edit-title.scss';

interface Params {
  value: string;
  tooltip?: string;
  onChange: (name: string) => void | null;
  getDisplayName?: () => string;
}

export class TreeTableEditTitle implements Ag.IComponent<Params>, Ag.ICellRenderer  {
  private params: Params;
  private isViewMode: boolean;
  private eGui: HTMLDivElement;
  private eInput: HTMLInputElement;
  private eEditButton: HTMLSpanElement;


  public init(params: Params): void {
    this.params = params;
    this.eGui = document.createElement('div');
    this.renderViewMode();
  }

  public getGui(): HTMLDivElement {
    return this.eGui;
  }

  public refresh(params: any): boolean {
    this.params = params as Params;
    if (this.isViewMode && this.eGui) {
      this.renderViewMode();
    } else {
      this.renderEditMode();
    }
    return true;
  }

  public destroy(): void {
    if (this.eEditButton) {
      AgGridTooltipUtils.removeTooltip(this.eEditButton.id);
    }
  }

  @autobind
  private saveChanges(): void {
    if (this.eInput.value) {
      this.params.onChange(this.eInput.value);
    }
    this.renderViewMode();
  }

  @autobind
  private mouseDown(event: MouseEvent): void {
    event.stopPropagation();
  }

  @autobind
  private renderEditMode(event?: MouseEvent): void {
    event.stopPropagation();
    if (!this.eGui) {
      return;
    }

    this.eGui.className = 'tree-table-edit-title tree-table-edit-title--edit';
    this.eGui.innerHTML = '<input class="tree-table-edit-title__input"/>';
    this.eInput = this.eGui.querySelector('.tree-table-edit-title__input');
    this.eInput.focus();
    this.eInput.addEventListener('focusout', this.saveChanges);
    this.eInput.addEventListener('mousedown', this.mouseDown);
    this.eInput.value = this.params.value;
    this.eInput.addEventListener('keydown', this.onKeydown);
    this.isViewMode = false;
  }

  private renderViewMode(): void {
    if (!this.eGui) {
      return;
    }

    const { value, onChange, getDisplayName, tooltip } = this.params;
    const displayValue = getDisplayName ? getDisplayName() : value;
    const className = 'tree-table-edit-title__value';
    this.eGui.className = 'tree-table-edit-title';
    const editButton = onChange ? '<span class="tree-table-edit-title__edit-icon"></span>' : '';
    this.eGui.innerHTML =
      `<span class="${className}" title="${tooltip ? displayValue : ''}">${displayValue}</span>${editButton}`;
    this.eEditButton = this.eGui.querySelector('.tree-table-edit-title__edit-icon');
    if (this.eEditButton) {
      this.eEditButton.addEventListener('click', this.renderEditMode);
      if (tooltip) {
        const id = uuidv4();
        this.eEditButton.id = id;
        this.eEditButton.setAttribute(
          'onmouseover',
          `buttonCallbacks.appendTooltip('${id}', '${tooltip}')`,
        );
        this.eEditButton.setAttribute('onmouseleave', `buttonCallbacks.removeTooltip('${id}')`);
        this.eEditButton.setAttribute('onclick', `buttonCallbacks.removeTooltip('${id}')`);
      }
    }
    this.isViewMode = true;
  }

  @autobind
  private onKeydown(event: KeyboardEvent): void {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.eInput.blur();
    } else if (event.key === 'Escape') {
      this.eInput.removeEventListener('focusout', this.saveChanges);
      this.renderViewMode();
    }
  }
}
