import autobind from 'autobind-decorator';
import * as React from 'react';

import { BillingPeriodUnit } from '../../enums/billing-period-unit';
import { SubscriptionAddon } from '../../interfaces/subscription-addon';
import { SubscriptionPlanVariantWithName } from '../../interfaces/subscription-plan-variant-with-name';
import { Styled } from './styled';
import { SubscriptionCustomOfferCard } from './subscription-custom-offer-card';
import { SubscriptionFeatures } from './subscription-features';
import { SubscriptionListingHead } from './subscription-listing-head';
import { SubscriptionOfferCard } from './subscription-offer-card';

interface Props {
  filteredPlanGroups: SubscriptionPlanVariantWithName[];
  selectedAddons: SubscriptionAddon[];
  billingPeriod: BillingPeriodUnit;
  onSubscriptionPlanGroupClick: (groupName: string) => void;
  onBillingPeriodChanged: (billingPeriod: BillingPeriodUnit) => void;
  planButtonText?: string;
  hidePlanDescription?: boolean;
  isPinBlock?: boolean;
  hideOffersStyle?: boolean;
}

export class SubscriptionListing extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <>
        <Styled.Offers
          isPinBlock={this.props.isPinBlock}
          planLength={this.props.filteredPlanGroups.length}
          hideOffersStyle={this.props.hideOffersStyle}
        >
          <SubscriptionListingHead
            billingPeriod={this.props.billingPeriod}
            setBillingPeriod={this.setBillingPeriod}
            isFreePlan={this.props.filteredPlanGroups.length > 3}
          />
          {this.getOffers()}
        </Styled.Offers>
        <Styled.SubscriptionFeaturesWrapper>
          <SubscriptionFeatures planVariants={this.props.filteredPlanGroups} />
        </Styled.SubscriptionFeaturesWrapper>
      </>
    );
  }

  @autobind
  private getOffers(): React.ReactNode {
    const { filteredPlanGroups, selectedAddons, planButtonText, hidePlanDescription } = this.props;

    return filteredPlanGroups.map((variant, index) => {
      return variant.isSelfService
        ? (
          <SubscriptionOfferCard
            key={variant.id}
            ghostButton={index === 0}
            onSelectPlanGroup={this.onSelectPlanGroup}
            variant={variant}
            selectedAddons={selectedAddons}
            buttonText={planButtonText}
            hideDescription={hidePlanDescription}
            isPinBlock={this.props.isPinBlock}
          />
        )
        : (
          <SubscriptionCustomOfferCard
            key={variant.id}
            variant={variant}
          />
        );
    });
  }

  @autobind
  private setBillingPeriod(billingPeriod: BillingPeriodUnit): void {
    this.props.onBillingPeriodChanged(billingPeriod);
  }

  @autobind
  private onSelectPlanGroup(selectedPlanGroup: string): void {
    this.props.onSubscriptionPlanGroupClick(selectedPlanGroup);
  }
}
