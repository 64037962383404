import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

const Content = styled.div`
  display: flex;
`;

export const Styled = {
  Container,
  Content,
};
