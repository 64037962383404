import { Icons, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';

interface Props {
  onClick: () => void;
}

export const ExpandButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Styled.Container
      onClick={onClick}
    >
      <Styled.Icon>
        <Icons.DownSmall />
      </Styled.Icon>
      <Text
        fontSize={14}
        color={'gray'}
      >
        Expand
      </Text>
    </Styled.Container>
  );
};
