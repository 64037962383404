import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TwoDReportTemplateActions } from 'unit-2d-report-template/store-slice';

interface Callbacks {
  exportReport: (projectId: number, templateId: number) => void;
  loadProjectReportTempalte: (projectId: number) => void;
  deleteProjectReportTemplate: (projectId: number, templateId: number) => void;
}

export const useReportTemplateCallbacks = (): Callbacks => {
  const dispatch = useDispatch();
  const exportReport = useCallback((projectId, templateId) =>
    dispatch(TwoDReportTemplateActions.exportTemplate({
      projectId,
      form: {
        companyTemplateId: templateId,
      },
    })), [dispatch]);
  const loadProjectReportTempalte = useCallback(
    (projectId) => dispatch(TwoDReportTemplateActions.loadProjectReportTemplate(projectId)),
    [dispatch],
  );
  const deleteProjectReportTemplate = useCallback((
    projectId,
    templateId,
  ) => dispatch(TwoDReportTemplateActions.deleteProjectReport({
    projectId,
    form: {
      companyTemplateId: templateId,
    },
  })),
  [dispatch],
  );

  return { exportReport, loadProjectReportTempalte, deleteProjectReportTemplate };
};
