import { State } from 'common/interfaces/state';
import { store } from '../../store';

export class StoreSubscriptionHelper {
  private static subscriptions: Array<(state: State) => void> = [];

  public static init(): void {
    store.subscribe(StoreSubscriptionHelper.process);
  }

  public static subscribe(callback: (state: State) => void): void {
    StoreSubscriptionHelper.subscriptions.push(callback);
  }

  public static unsubscribe(callback: (state: State) => void): void {
    StoreSubscriptionHelper.subscriptions = StoreSubscriptionHelper.subscriptions.filter(x => x !== callback);
  }

  private static process(): void {
    const state = store.getState();
    for (const callback of StoreSubscriptionHelper.subscriptions) {
      callback(state);
    }
  }
}
