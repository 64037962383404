import { useEffect, useState } from 'react';
import { CommonApi } from '../../../api/server';
import { QueueLoader } from './queue-loader';


export function useDownloadImage(src: string): string {
  const [image, setImage] = useState<string>(null);
  useEffect(() => {
    if (src) {
      let promise = (): Promise<any> => CommonApi.getBlob(src);
      QueueLoader.request(promise)
        .then((value) => {
          promise = null;
          const reader = new FileReader();
          reader.readAsDataURL(value);
          reader.onloadend = () => {
            setImage(reader.result as string);
          };
        });
      return () => {
        if (promise) {
          QueueLoader.removeFromQueue(promise);
        }
      };
    }
  }, [src]);
  return image;
}
