import React, { useCallback } from 'react';
import { Styled } from './styled';

interface Props {
  disabled: boolean;
  onImageSelected: (file: File) => void;
}

export const ImageUploadInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { disabled, onImageSelected } = props;

  const handleImageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const filesList = e.target.files;
    if (filesList.length && filesList[0]) {
      onImageSelected(filesList[0]);
    }
  }, [onImageSelected]);

  return (
    <Styled.Input
      name={'filesInput'}
      id={'filesInput'}
      className='image-uploader__file-input'
      ref={ref}
      type='file'
      accept='image/*'
      disabled={disabled}
      onChange={handleImageChange}
    />
  );
});
