import * as Ag from 'ag-grid-community';

import { AgGridHelper } from '../../ag-grid';
import { RowDragHelper } from './row-drag-helper';


export enum DragType {
  InsertAfter = 'insertAfter',
  InsertInto = 'insertInto',
}

export class DefaultRowDragHelper extends RowDragHelper {
  public setOverNode(api: Ag.GridApi, overNode: Ag.RowNode): void {
    this.innerSetOverNode(api, overNode, true);
  }

  public reset(api: Ag.GridApi): void {
    this.innerReset(api, true);
  }

  public moveNode(
    api: Ag.GridApi,
    node: Ag.RowNode,
    onMove: (parentId: string | null, position: number, rowIdsToMove: string[]) => void,
  ): void {
    this.deferredExecutor.reset();
    this.redrawRows(api, this.draggableNode);
    onMove(node.data.parentId, node.parent.data.children.indexOf(node.id), [node.id]);
    this.reset(api);
    node.data.parentId = this.overNode.data.id;
    api.applyTransaction({
      update: [node.data],
    });
    onMove(node.data.parentId, node.parent.data.children.indexOf(node.id), [node.id]);
  }

  private isRootNodeId(id: string): boolean {
    return id === AgGridHelper.constants.ROOT_NODE_ID;
  }

  private getRowById(api: Ag.GridApi, id: string): Ag.RowNode {
    return !id || this.isRootNodeId(id)
      ? AgGridHelper.getRootNode(api)
      : api.getRowNode(id);
  }

  private getNewParentRow(overNode: Ag.RowNode, dragType: DragType): Ag.RowNode {
    if (dragType === DragType.InsertAfter) {
      return overNode.parent;
    } else {
      return overNode;
    }
  }

  private getInsetPosition(newParentRow: Ag.RowNode, overNode: Ag.RowNode, dragType: DragType): number {
    if (dragType === DragType.InsertAfter) {
      return newParentRow.data.children.findIndex(x => x === overNode.id) + 1;
    } else {
      return newParentRow.data.children.length;
    }
  }

  private redrawRows(api: Ag.GridApi, node: Ag.RowNode): void {
    if (!this.overNode) {
      return;
    }

    const parentRow = node.parent;
    parentRow.data.children = parentRow.data.children.filter(x => x !== node.id);
    const replacedNodesData = node.allLeafChildren.map(x => x.data);
    parentRow.updateData(parentRow.data);
    api.updateRowData({ remove: replacedNodesData });


    const newParentRow = this.getNewParentRow(this.overNode, this.dragType);
    const insertPosition = this.getInsetPosition(newParentRow, this.overNode, this.dragType);

    newParentRow.data.children = [
      ...newParentRow.data.children.slice(0, insertPosition),
      node.id,
      ...newParentRow.data.children.slice(insertPosition),
    ];

    const newParentRowId = newParentRow.id === AgGridHelper.constants.ROOT_NODE_ID
      ? null
      : newParentRow.id;
    replacedNodesData[0].parentId = newParentRowId;

    newParentRow.updateData(newParentRow.data);
    api.updateRowData({
      add: [replacedNodesData[0]],
      addIndex: insertPosition,
    });

    for (const replacedNode of replacedNodesData.slice(1)) {
      api.updateRowData({ add: [replacedNode] });
    }

    const reorderedParent = this.getRowById(api, newParentRowId);
    reorderedParent.childrenAfterGroup = reorderedParent.data.children.map(id => reorderedParent.childrenMapped[id]);
    api.updateRowData({ update: reorderedParent.allLeafChildren.map(x => x.data) });
  }
}
