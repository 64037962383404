import { useMemo } from 'react';

import { arrayUtils } from 'common/utils/array-utils';
import { DrawingsGeometryGroupWithNesting } from '../../interfaces';
import { AnnotationLegendItem } from '../../interfaces/annotation-legend-tree';
import { AnnotationLegendItemTypeguards } from '../../utils/annotation-legend-item-typeguards';


function* groupsIterator(groups: AnnotationLegendItem[]): IterableIterator<DrawingsGeometryGroupWithNesting> {
  const onlyGroupsIterator = arrayUtils.filterIterator(groups, AnnotationLegendItemTypeguards.isGroup);
  for (const group of onlyGroupsIterator as Iterable<DrawingsGeometryGroupWithNesting>) {
    yield group;
    if (group.allInnerInstances) {
      yield *groupsIterator(group.allInnerInstances);
    }
  }
}

export function useGroups(
  groups: AnnotationLegendItem[],
  pinnedGroups: string[],
): DrawingsGeometryGroupWithNesting[] {
  return  useMemo(() => {
    if (!pinnedGroups) {
      return [];
    }
    const groupsDictionary = arrayUtils.toDictionaryByKey(groupsIterator(groups), g => g.id);
    return arrayUtils.filterMap(pinnedGroups, x => !!groupsDictionary[x], x => groupsDictionary[x]);
  }, [groups, pinnedGroups]);
}
