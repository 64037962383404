import styled from 'styled-components';

const MultiValuesPopupContainer = styled.div`
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 15px;
  height: 20px;
  box-sizing: border-box;
  display: flex;
  padding: 0px 5px;
  box-shadow: ${p => p.theme.shadow.s};
  min-width: max-content;
`;

const MultiValuesPopupItem = styled.div`
  padding: 3px 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  
  svg {
    width: 10px;
    height: 10px;
    fill: ${p => p.theme.color.gray};
  }
  border-left-style: solid;
  border-left-width: 0px;
  border-left-color: ${p => p.theme.color.background};
  & + & {
    border-left-width: 1px;
  }
`;


export const Styled = {
  MultiValuesPopupContainer,
  MultiValuesPopupItem,
};
