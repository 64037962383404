import { PiaBreakdownNode } from '../interfaces';

export const deleteOption = (options: PiaBreakdownNode[], id: string): boolean => {
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (option.id === id) {
      options.splice(i, 1);
      return true;
    } else if (option.children.length) {
      const isDelete = deleteOption(option.children, id);
      if (isDelete) {
        return true;
      }
    }
  }

  return false;
};
