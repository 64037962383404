
import { SubscriptionType } from 'common/constants/subscription';
import { RequestStatus } from 'common/enums/request-status';
import { UserPermission } from 'common/enums/user-permission';
import { CurrentCountry } from 'common/environment/current-country-service';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { MonoliteHelper } from 'common/monolite';
import { Account } from '../../../common/interfaces/account';
import { ReducerMethods } from '../../../common/interfaces/reducer-methods';
import {
  CurrencyExchangeActionTypes,
  CurrentCountryActionTypes,
  PaymentMethodActionTypes,
  SubscriptionActionTypes,
} from '../actions/types';
import { EstimateSubscriptionError } from '../interfaces';
import { BillingCollection } from '../interfaces/billing-collection';
import { BillingCountryModel } from '../interfaces/billing-country-model';
import { CompanyBillingInfoModel } from '../interfaces/company-billing-info-model';
import { EstimateSubscriptionModel } from '../interfaces/estimation-subscription-model';
import { SubscriptionPlanListingModel } from '../interfaces/subscription-plan-listing-model';


const paymentRequest = (s: Account): Account => new MonoliteHelper(s)
  .set(_ => _.paymentStatus, RequestStatus.Loading)
  .get();
const paymentRequestFailed = (s: Account): Account => new MonoliteHelper(s)
  .set(_ => _.paymentStatus, RequestStatus.Failed)
  .get();
const paymentRequestSucceeded = (s: Account, payload): Account => new MonoliteHelper(s)
  .set(_ => _.paymentStatus, RequestStatus.Loaded)
  .set(_ => _.payment, payload)
  .get();


export const subscriptionReducerMethods: ReducerMethods<Account> = {
  [SubscriptionActionTypes.SET_SUBSCRIPTION_TYPE]: (s, payload: SubscriptionType) => {
    return new MonoliteHelper(s)
      .set(_ => _.selectedSubscriptionType, payload)
      .get();
  },
  [SubscriptionActionTypes.LOAD_SUBSCRIPTION_ROLES_SUCCEEDED]: (s, payload: CompanyRoles) => {
    return new MonoliteHelper(s)
      .set(_ => _.subscriptionRoles, payload)
      .get();
  },
  [SubscriptionActionTypes.UPDATE_SUBSCRIPTION_PERMISSION_ROLES_REQUEST]: (s) => {
    return new MonoliteHelper(s)
      .set((_) => _.updateRolesPermissionStatus, RequestStatus.Loading)
      .get();
  },
  [SubscriptionActionTypes.UPDATE_SUBSCRIPTION_PERMISSION_ROLES_SUCCEEDED]: (
    s, payload: Record<number, UserPermission[]>,
  ) => {
    return new MonoliteHelper(s)
      .set((_) => _.updateRolesPermissionStatus, RequestStatus.Loaded)
      .setMap((_) => _.subscriptionRoles.roles, (r) => {
        return payload[r.id]
          ? new MonoliteHelper(r).set(_ => _.permissions, payload[r.id]).get()
          : r;
      }).get();
  },
  [SubscriptionActionTypes.UPDATE_SUBSCRIPTION_ROLES_REQUEST]: (s) => {
    return new MonoliteHelper(s)
      .set((_) => _.updateRolesStatus, RequestStatus.Loading)
      .get();
  },
  [SubscriptionActionTypes.UPDATE_SUBSCRIPTION_ROLES_SUCCEEDED]: (s) => {
    return new MonoliteHelper(s)
      .set((_) => _.updateRolesStatus, RequestStatus.Loading)
      .get();
  },
  [SubscriptionActionTypes.SET_SELECTED_COMPANY]: (s, payload: number) => {
    return new MonoliteHelper(s)
      .set((_) => _.selectedCompany, s.companies.find(x => x.id === payload))
      .get();
  },
  [SubscriptionActionTypes.GET_SUBSCRIPTION_PLANS_REQUEST]: (s) => {
    const stateUpdater = new MonoliteHelper(s);
    stateUpdater.set(_ => _.requests.subscriptionPlans, RequestStatus.Loading);

    return stateUpdater.get();
  },
  [SubscriptionActionTypes.GET_SUBSCRIPTION_PLANS_SUCCEEDED]: (s, model: {
    plans: SubscriptionPlanListingModel,
    hasMonthBillingPeriod: boolean,
    hasYearBillingPeriod: boolean,
  }) => {
    const stateUpdater = new MonoliteHelper(s);
    stateUpdater.set(_ => _.subscriptionPlans, model.plans)
      .set(_ => _.requests.subscriptionPlans, RequestStatus.Loaded)
      .set(_ => _.isTwoBillingPeriod, model.hasMonthBillingPeriod && model.hasYearBillingPeriod)
      .set(_ => _.hasYearBillingPeriod, model.hasYearBillingPeriod)
      .set(_ => _.hasMonthBillingPeriod, model.hasMonthBillingPeriod);

    return stateUpdater.get();
  },
  [SubscriptionActionTypes.GET_SUBSCRIPTION_PLANS_FAILED]: (s) => {
    const stateUpdater = new MonoliteHelper(s);
    stateUpdater.set(_ => _.requests.subscriptionPlans, RequestStatus.Failed);

    return stateUpdater.get();
  },
  [SubscriptionActionTypes.FETCH_OWN_COMPANY_BILLING_INFO_REQUEST]: (s) => {
    const stateUpdater = new MonoliteHelper(s);
    stateUpdater
      .set(_ => _.ownCompanyBillingInfo, null)
      .set(_ => _.requests.ownCompanyBillingInfo, RequestStatus.Loading);

    return stateUpdater.get();
  },
  [SubscriptionActionTypes.FETCH_OWN_COMPANY_BILLING_INFO_FAILED]: (s) => {
    const stateUpdater = new MonoliteHelper(s);
    stateUpdater.set(_ => _.requests.ownCompanyBillingInfo, RequestStatus.Failed);

    return stateUpdater.get();
  },
  [SubscriptionActionTypes.FETCH_OWN_COMPANY_BILLING_INFO_SUCCEEDED]: (s, payload: CompanyBillingInfoModel) => {
    const stateUpdater = new MonoliteHelper(s);
    stateUpdater.set(_ => _.ownCompanyBillingInfo, { ...s.ownCompanyBillingInfo, ...payload })
      .set(_ => _.requests.ownCompanyBillingInfo, RequestStatus.Loaded);

    return stateUpdater.get();
  },
  [SubscriptionActionTypes.GET_CREATE_SUBSCRIPTION_ESTIMATION]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.billingEstimationError, null)
      .set(_ => _.billingEstimation, null)
      .get();
  },
  [SubscriptionActionTypes.GET_UPDATE_SUBSCRIPTION_ESTIMATION]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.billingEstimationError, null)
      .set(_ => _.billingEstimation, null)
      .get();
  },
  [SubscriptionActionTypes.SET_SUBSCRIPTION_ESTIMATION_ERROR]: (s, payload: EstimateSubscriptionError) => {
    return new MonoliteHelper(s)
      .set(_ => _.billingEstimationError, payload)
      .get();
  },
  [SubscriptionActionTypes.SET_SUBSCRIPTION_ESTIMATION]: (s, payload: EstimateSubscriptionModel) => {
    return new MonoliteHelper(s)
      .set(_ => _.billingEstimation, payload)
      .get();
  },
  [SubscriptionActionTypes.DROP_OWN_COMPANY_BILLING_INFO_STATE]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.requests.ownCompanyBillingInfo, RequestStatus.NotRequested)
      .set(_ => _.ownCompanyBillingInfo, null)
      .set(_ => _.billingEstimation, null)
      .set(_ => _.billingEstimationError, null)
      .get();
  },
  [SubscriptionActionTypes.START_UPDATE_SUBSCRIPTION]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isHandleSubscriptionUpdate, true)
      .get();
  },
  [SubscriptionActionTypes.END_UPDATE_SUBSCRIPTION]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.isHandleSubscriptionUpdate, false)
      .get();
  },
  [SubscriptionActionTypes.DROP_SUBSCRIPTION_PLANS_STATE]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.requests.subscriptionPlans, RequestStatus.NotRequested)
      .set(_ => _.subscriptionPlans, null)
      .get();
  },

  [PaymentMethodActionTypes.SET_AVAILABLE_BILLING_COUNTRIES]: (s, payload: BillingCountryModel[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.billingCountries, payload)
      .get();
  },

  [PaymentMethodActionTypes.SET_PRIMARY_CARD]: paymentRequest,
  [PaymentMethodActionTypes.SET_PRIMARY_CARD_FAILED]: paymentRequestFailed,
  [PaymentMethodActionTypes.SET_PRIMARY_CARD_SUCCEEDED]: paymentRequestSucceeded,

  [PaymentMethodActionTypes.SET_BACKUP_CARD]: paymentRequest,
  [PaymentMethodActionTypes.SET_BACKUP_CARD_FAILED]: paymentRequestFailed,
  [PaymentMethodActionTypes.SET_BACKUP_CARD_SUCCEEDED]: paymentRequestSucceeded,

  [PaymentMethodActionTypes.GET_PAYMENT_DATA]: paymentRequest,
  [PaymentMethodActionTypes.GET_PAYMENT_METHODS]: paymentRequest,
  [PaymentMethodActionTypes.GET_PAYMENT_DATA_FAILED]: paymentRequestFailed,
  [PaymentMethodActionTypes.GET_PAYMENT_METHODS_SUCCEEDED]: paymentRequestSucceeded,

  [PaymentMethodActionTypes.REMOVE_CARD]: paymentRequest,
  [PaymentMethodActionTypes.REMOVE_CARD_FAILED]: paymentRequestFailed,
  [PaymentMethodActionTypes.REMOVE_CARD_SUCCEEDED]: paymentRequestSucceeded,

  [PaymentMethodActionTypes.CREATE_CARD_LEGACY]: paymentRequest,
  [PaymentMethodActionTypes.CREATE_CARD_FAILED]: paymentRequestFailed,
  [PaymentMethodActionTypes.CREATE_CARD_SUCCEEDED]: paymentRequestSucceeded,

  [PaymentMethodActionTypes.DROP_PAYMENT_STATE]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.payment, null)
      .set(_ => _.paymentStatus, RequestStatus.NotRequested)
      .get();
  },
  [CurrencyExchangeActionTypes.SET_CURRENCY_RATE]: (s, payload: Record<string, number>) => {
    return new MonoliteHelper(s)
      .set(_ => _.currencyRates, payload)
      .get();
  },
  [CurrentCountryActionTypes.GET_CURRENT_COUNTRY_SUCCEED]: (s, payload: CurrentCountry) => {
    return new MonoliteHelper(s)
      .set(_ => _.currentCountry, payload)
      .get();
  },
  [PaymentMethodActionTypes.FETCH_BILLING_HISTORY]: (
    s, payload: { billingCollection: BillingCollection, append: boolean },
  ) => {
    if (payload.append) {
      return new MonoliteHelper(s)
        .set(_ => _.billingCollection.items, s.billingCollection.items.concat(payload.billingCollection.items))
        .set(_ => _.billingCollection.nextOffset, payload.billingCollection.nextOffset)
        .set(_ => _.billingHistoryStatus, RequestStatus.Loaded)
        .get();
    } else {
      return new MonoliteHelper(s)
        .set(_ => _.billingCollection, payload.billingCollection)
        .set(_ => _.billingHistoryStatus, RequestStatus.Loaded)
        .get();
    }
  },
  [PaymentMethodActionTypes.SET_BILLING_HISTORY_STATUS]: (state, status: RequestStatus) => {
    return new MonoliteHelper(state)
      .set(_ => _.billingHistoryStatus, status)
      .get();
  },
};
