import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { WizzardStatus } from 'common/components/drawings/enums/dropper-state';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { NewInstanceSettings } from 'common/components/drawings/layout-components';
import { MagicSearchMenu } from 'common/components/drawings/layout-components/magic-search-menu';
import { MagicSearchResults } from 'common/components/drawings/layout-components/magic-search-results';
import { RenderIf } from 'common/components/render-if';
import { SectionMenuItems } from 'common/components/section-menu-items';
import { State } from 'common/interfaces/state';
import { AiBadge } from '../ai-badge';


const MagicSearchViewComponent: React.FC = () => {
  const badge = useMemo(() => <AiBadge/>, []);

  const { setDrawMode } = useDrawModeApi();

  const onCloseMenu = useCallback(() => {
    setDrawMode(DrawingsDrawMode.Disabled);
  }, [ setDrawMode ]);

  const status = useSelector<State, WizzardStatus>(state => state.drawings.magicSearch.status);
  const specifySearchZone = useSelector<State, boolean>(state => state.drawings.magicSearch.searchAreaSpecifyMode);
  return (
    <>
      <SectionMenuItems
        onSelectionMenuClick={onCloseMenu}
        infoIcon={Icons.MagicSearch2D}
        badge={badge}
        title='Search for areas'
        manageIcon={Icons.CloseSmall}
      />
      <NewInstanceSettings/>
      <RenderIf condition={!specifySearchZone}>
        <MagicSearchMenu
          reset={onCloseMenu}
          status={status}
        />
        <RenderIf condition={status === WizzardStatus.Preview}>
          <MagicSearchResults/>
        </RenderIf>
      </RenderIf>
    </>
  );
};

export const MagicSearchView = React.memo(MagicSearchViewComponent);
