import * as React from 'react';

export const KreoIconFilterNormal: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      className='filter-icon'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M1,3 L13,3 C13.5522847,3 14,3.44771525 14,4 C14,4.55228475 13.5522847,5 13,
          5 L1,5 C0.44771525,5 6.76353751e-17,4.55228475 0,4 C-6.76353751e-17,3.44771525 0.44771525,
          3 1,3 Z M2.5,7 L11.5,7 C11.7761424,7 12,7.22385763 12,7.5 C12,7.77614237 11.7761424,
          8 11.5,8 L2.5,8 C2.22385763,8 2,7.77614237 2,7.5 C2,7.22385763 2.22385763,7 2.5,
          7 Z M4.5,10 L9.5,10 C9.77614237,10 10,10.2238576 10,10.5 C10,10.7761424 9.77614237,
          11 9.5,11 L4.5,11 C4.22385763,11 4,10.7761424 4,10.5 C4,10.2238576 4.22385763,10 4.5,10 Z'
          id='Combined-Shape'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
