import { useMemo } from 'react';

import { useInstances } from '../../hooks';
import { DrawingsMenuUtils } from '../../utils/drawings-menu-utils';

interface UseCanTraceLinkPayload {
  instancesIds: string[];
  canViewReport: boolean;
  canTraceLink: (instanceId: string) => boolean;
}

export function useCanTraceLink({ instancesIds, canViewReport, canTraceLink }: UseCanTraceLinkPayload): boolean {
  const instances = useInstances();
  return useMemo(() => {
    if (!canViewReport || instancesIds.length > 1) {
      return false;
    }
    const instanceId = DrawingsMenuUtils.getInstanceId(instancesIds, instances);
    return canTraceLink(instanceId);
  }, [instancesIds, instances, canViewReport, canTraceLink]);
}
