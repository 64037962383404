import { DropDownStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const AddProjectUserPopupWrapper = styled.div`
  ${DropDownStyled.DropDownMenuContainer} {
    max-height: 135px;
  }
  ${DropDownStyled.ItemsContainer} {
    max-height: 100%;
    height: 100%;
  }
`;

export const Styled = {
  AddProjectUserPopupWrapper,
};
