import { useMemo } from 'react';

import { getParentAssign } from '2d/helpers';
import { AssignedPia } from '2d/interfaces';
import { DrawingsGeometryGroup } from 'common/components/drawings';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import { Assembly, Item } from 'unit-2d-database/interfaces';
import { calcAssign } from '../helpers';

type GetAssignByGroup = (
  id: string,
  groups: DrawingsGeometryGroup[],
  assignPia: Record<string, AssignedPia>,
) => [Assembly[], Item[]];
export const getAssignByGroup: GetAssignByGroup = (id, groups, assignPia) => {
  const assign = calcAssign(getParentAssign(id, assignPia, groups));
  return [assign.assemblies, assign.items];
};

type GetAssignByMeasure = (
  groups: DrawingsGeometryGroup[],
  assignPia: Record<string, AssignedPia>,
  selectedId: string,
  aiAnnotation: DrawingsGeometryState,
) => [Assembly[], Item[]];
const getAssignByMeasure: GetAssignByMeasure = (groups, assignPia, selectedId, aiAnnotation) => {
  const measure = aiAnnotation.geometry[selectedId];
  const measureAssign = assignPia[selectedId]
    ? [assignPia[selectedId]]
    : [];
  const groupsAssign = measure.groupId
    ? [...getParentAssign(measure.groupId, assignPia, groups), ...measureAssign]
    : [...measureAssign];

  const assign = calcAssign(groupsAssign);

  return [assign.assemblies, assign.items];
};

type UseAssign = (
  selectedGroupId: string,
  groups: DrawingsGeometryGroup[],
  assignPia: Record<string, AssignedPia>,
  selectedMeasureId: string,
  aiAnnotation: DrawingsGeometryState,
) => [Assembly[], Item[]];
export const useAssign: UseAssign = (groupId, groups, assignPia, measureId, aiAnnotation) => {
  return useMemo(() => {
    if (groupId) {
      return getAssignByGroup(groupId, groups, assignPia);
    } else if (measureId) {
      return getAssignByMeasure(groups, assignPia, measureId, aiAnnotation);
    } else {
      return [[], []];
    }
  }, [groupId, groups, assignPia, measureId, aiAnnotation]);
};
