import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { arrayUtils } from 'common/utils/array-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { DrawingsBatchUpdateGeometries, DrawingsUpdateAnnotationGeometry } from '../../actions/payloads/annotation';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { ContextObserver } from '../../drawings-contexts';
import { DrawingsInstanceType } from '../../enums/drawings-instance-type';
import { DrawingsColorCacheHelper } from '../../helpers/drawings-color-cache-helper';
import { DrawingsPointInfo, ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { MeasuresViewSettings } from '../../interfaces/drawing-text-render-parameters';
import { DrawingsPolygonGeometry } from '../../interfaces/drawings-geometry';
import { DrawingsGeometryInstance } from '../../interfaces/drawings-geometry-instance';
import { DrawingsInstanceMeasure } from '../../interfaces/drawings-instance-measure';
import { DrawingsShortInfo } from '../../interfaces/drawings-short-drawing-info';
import { DrawingAnnotationNamingUtils } from '../../utils/drawing-annotation-naming-utils';
import { DrawingAnnotationUtils } from '../../utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';
import { DrawingsUpdateUtils } from '../../utils/drawings-update-utils';
import { DrawingsGeometryEntityStroked, StartPoint } from '../drawings-geometry-entities';
import { BatchedUpdateCallback, DrawingsEngineFlipType } from '../interfaces';
import { StrokedGeometryUpdate } from '../interfaces/stroke-styled-geometry-update';
import { DrawingsGeometryEntityHelper } from './drawings-geometry-entity-helper';
import { DrawingsRenderedPointsCache } from './drawings-rendered-points-cache';

interface DrawingsElementChangesProcessorConfig {
  entityRenderHelper: DrawingsGeometryEntityHelper;
  textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
  colorCacheHelper: DrawingsColorCacheHelper;
  getCurrentDrawing: () => DrawingsShortInfo;
  cachedPoints: DrawingsRenderedPointsCache;
  getPointInfo: (pointId: string) => DrawingsPointInfo;
  getDrawingInstance: (instanceId: string) => DrawingsGeometryInstance;
  getPointCoordinates: (pointId: string) => ShortPointDescription;
  getColorOfInstance: (instanceId: string) => string;
  getInstanceMeasures: (instanceId: string) => DrawingsInstanceMeasure;
  changePointsPositionAndMeasures: (
    updatedPoints: Record<string, ShortPointDescription>,
    instancesMeasures: DrawingsInstanceMeasure[],
  ) => void;
  onBatchUpdateGeometries: BatchedUpdateCallback;
}

export class DrawingsInstancesChangesProcessor {
  private _config: DrawingsElementChangesProcessorConfig;

  constructor(config: DrawingsElementChangesProcessorConfig) {
    this._config = config;
  }


  public addPointToEnd(instanceId: string, point: ShortPointDescription, sideToAdd: StartPoint): void {
    const pointId = UuidUtil.generateUuid();
    const points = this._config.getDrawingInstance(instanceId).geometry.points;
    const line: [ string, string ] = sideToAdd === 'start' ? [null, points[0]] : [points[points.length - 1], null];
    this._config.cachedPoints.setPoint(pointId, new paper.Point(point));
    const {
      geometry,
      measures,
      newLines,
    } = this._config.entityRenderHelper.addPointsToInstances(instanceId, line, [pointId]);
    const updated = DrawingsUpdateUtils.createUpdatePayload(
      { id: instanceId, geometry },
      null,
      null,
      { [pointId]: point },
      DrawingsUpdateUtils.createLinesUpdate([], newLines),
    );
    const instanceMeasures = this._config.getInstanceMeasures(instanceId);
    this._config.entityRenderHelper.recalculateSelectionState();
    this._config.onBatchUpdateGeometries(
      {
        pageId: this._config.getCurrentDrawing().drawingId,
        updated,
      },
      instanceMeasures ? [{ ...instanceMeasures, measures }] : null,
    );
  }


  public addSegmentToInstance(lineId: string, points: [ShortPointDescription, ShortPointDescription]): void {
    const { updated } = this.addPointToInstance(lineId, points);
    const currentDrawing = this._config.getCurrentDrawing();
    this._config.onBatchUpdateGeometries({ updated, pageId: currentDrawing.drawingId });
  }

  @autobind
  public addPoint(lineId: string, point: ShortPointDescription): void {
    const { updated } = this.addPointToInstance(lineId, [point]);
    const currentDrawing = this._config.getCurrentDrawing();
    const measures = this._config.getInstanceMeasures(updated.instance.id);
    this._config.onBatchUpdateGeometries({ updated, pageId: currentDrawing.drawingId }, measures ? [measures] : null);
  }

  public tryDuplicateSegment(
    lineId: string,
    instanceId: string,
  ): {
    lineId: string,
    points: [string, string],
    geometryUpdate: StrokedGeometryUpdate,
  } | null {
    const startId = UuidUtil.generateUuid();
    const endId = UuidUtil.generateUuid();
    const instance = this._config.entityRenderHelper.getInstance(instanceId);
    if (instance instanceof DrawingsGeometryEntityStroked) {
      const line = instance.getOrderedSegmentPoints(lineId);
      this._config.cachedPoints.setPoint(startId, this._config.cachedPoints.getPoint(line[0]));
      this._config.cachedPoints.setPoint(endId, this._config.cachedPoints.getPoint(line[1]));
      const segment: [string, string] = [startId, endId];
      const geometryUpdate =
        this._config.entityRenderHelper.addPointsToInstances(instanceId, line, segment) as StrokedGeometryUpdate;
      return {
        lineId: DrawingAnnotationUtils.getLineKey(startId, endId),
        points: segment,
        geometryUpdate,
      };
    }
  }

  public updateFullInstance(updated: DrawingsUpdateAnnotationGeometry, measures: DrawingsInstanceMeasure): void {
    const oldMeasures = this._config.getInstanceMeasures(updated.instance.id);
    this._config.onBatchUpdateGeometries(
      { updated, pageId: this._config.getCurrentDrawing().drawingId },
      oldMeasures ? [measures] : null,
      oldMeasures ? [oldMeasures] : null,
    );
  }

  public tryDuplicateSegmentWithSave(lineId: string, instanceId: string): string | null {
    const newSegment = this.tryDuplicateSegment(lineId, instanceId);
    if (!newSegment) {
      return null;
    }
    const { points: [startId, endId], geometryUpdate } = newSegment;
    const { geometry, newLines, removedLines, measures } = geometryUpdate;
    const updated = DrawingsUpdateUtils.createUpdatePayload(
      { id: instanceId, geometry },
      null,
      null,
      {
        [startId]: this._config.cachedPoints.getConvertedPoint(startId),
        [endId]: this._config.cachedPoints.getConvertedPoint(endId),
      },
      DrawingsUpdateUtils.createLinesUpdate(removedLines, newLines),
    );
    const instanceMeasures = this._config.getInstanceMeasures(instanceId);
    this._config.onBatchUpdateGeometries(
      { updated, pageId: this._config.getCurrentDrawing().drawingId },
      instanceMeasures ? [{ ...instanceMeasures, measures }] : null,
    );
    return DrawingAnnotationUtils.getLineKey(startId, endId);
  }

  public addPointToInstance(
    lineId: string,
    coordinates: ShortPointDescription[],
  ): { updated: DrawingsUpdateAnnotationGeometry, pointIds: string[] } {
    const line = DrawingAnnotationUtils.getPointsIdsFromLineKey(lineId);
    const { instanceId } = this._config.getPointInfo(line[0]);
    const newPoints = this.processNewPoints(coordinates);
    const pointIds = Object.keys(newPoints);
    const { geometry, newLines } = this._config.entityRenderHelper.addPointsToInstances(instanceId, line, pointIds);
    const entityUpdate = { id: instanceId, geometry };
    const linesUpdate = DrawingsUpdateUtils.createLinesUpdate([lineId], newLines);
    const updated = DrawingsUpdateUtils.createUpdatePayload(
      entityUpdate,
      null,
      null,
      newPoints,
      linesUpdate,
    );
    return { updated, pointIds };
  }

  public addPointToVisualSearch(
    lineId: string,
    point: ShortPointDescription,
  ): { geometry: DrawingsPolygonGeometry, points: Record<string, ShortPointDescription> } {
    const pointId = `${DrawingsCanvasConstants.visualSearch}${UuidUtil.generateUuid()}`;
    this._config.cachedPoints.setPointShortDescription(pointId, point);
    const line = DrawingAnnotationUtils.getPointsIdsFromLineKey(lineId);
    const {
      geometry,
    } = this._config.entityRenderHelper.addPointsToInstances(DrawingsCanvasConstants.visualSearch, line, [pointId]);
    const points = arrayUtils.toDictionary(geometry.points, x => x, this._config.cachedPoints.getConvertedPoint);
    return { geometry: geometry as DrawingsPolygonGeometry, points };
  }

  public removePointFromInstance(
    pointIds: string[],
  ): { updated: DrawingsUpdateAnnotationGeometry, measures: DrawingsInstanceMeasure[] } {
    const entityMeasures = new Array<DrawingsInstanceMeasure>();
    const { instanceId } = this._config.getPointInfo(pointIds[0]);
    const instance = this._config.getDrawingInstance(instanceId);
    const {
      newLines,
      geometry,
      measures,
      removedLines,
    } = this._config.entityRenderHelper.removePoint(pointIds, instanceId);
    if (this._config.getInstanceMeasures(instanceId)) {
      entityMeasures.push({
        measures,
        id: instanceId,
        type: instance.type,
        color: this._config.getColorOfInstance(instanceId),
      });
    }
    const linesUpdate = DrawingsUpdateUtils.createLinesUpdate(removedLines, newLines);
    const updated = DrawingsUpdateUtils.createUpdatePayload(
      { id: instanceId, geometry },
      null,
      pointIds,
      null,
      linesUpdate,
    );
    return { updated, measures: entityMeasures };
  }

  @autobind
  public flipElements(ids: string[], flipType: DrawingsEngineFlipType): void {
    const values = [];
    const pointParamIndex = flipType === 'horizontal' ? 0 : 1;
    const updatedPoints: Record<string, ShortPointDescription> = {};
    const instancesPoints: Record<string, string[]> = {};
    const currentDrawingId = this._config.getCurrentDrawing().drawingId;
    for (const id of ids) {
      const instance = this._config.getDrawingInstance(id);
      if (instance.drawingId !== currentDrawingId) {
        continue;
      }
      instancesPoints[id] = [];
      for (const pointId of DrawingAnnotationUtils.geometryPointsIterator(instance.geometry, instance.type)) {
        const point = this._config.getPointCoordinates(pointId);
        values.push(point[pointParamIndex]);
        updatedPoints[pointId] = [...point] as ShortPointDescription;
        instancesPoints[id].push(pointId);
      }
    }
    const center = (Math.max(...values) + Math.min(...values)) / 2;

    for (const [instanceId, value] of Object.entries(instancesPoints)) {
      for (const pointId of value) {
        updatedPoints[pointId][pointParamIndex] = 2 * center - updatedPoints[pointId][pointParamIndex];
        this._config.cachedPoints.setPoint(pointId, new paper.Point(updatedPoints[pointId]));
      }
      if (this._config.entityRenderHelper.isInstanceRendered(instanceId)) {
        this._config.entityRenderHelper.updatePointsPositionInInstance(value, instanceId);
      }
    }
    this._config.changePointsPositionAndMeasures(updatedPoints, []);
  }

  @autobind
  public finishEditPoints(pointsIds: string[], instanceMeasures?: DrawingsInstanceMeasure): void {
    const { scale, metersPerPixel } = this._config.textRenderParamsObserver.getContext();
    const measureUpdates = this.finishEditPointPosition(pointsIds, scale, metersPerPixel);
    if (instanceMeasures) {
      measureUpdates.push(instanceMeasures);
    }
    const updatedPoints = {};
    for (const pointId of pointsIds) {
      updatedPoints[pointId] = this._config.cachedPoints.getConvertedPoint(pointId);
    }
    this._config.changePointsPositionAndMeasures(updatedPoints, measureUpdates);
  }

  @autobind
  public removePointsProcessor(ids: string[], instanceId: string): void {
    if (this._config.entityRenderHelper.canRemovePoints(ids, instanceId)) {
      this.removePoints(ids);
    }
  }

  @autobind
  public onRemoveCountPoint(id: string, e: PaperMouseEvent): void {
    e.stopPropagation();
    this.removePointsProcessor([id], this._config.getPointInfo(id).instanceId);
  }

  public finishEditPointPosition(pointIds: string[], scale: number, metersPerPixel: number): DrawingsInstanceMeasure[] {
    const measuresUpdates = new Array<DrawingsInstanceMeasure>();
    for (const pointId of pointIds) {
      const pointInfo = this._config.getPointInfo(pointId);
      if (pointInfo.lines.length) {
        const updatedLines = {};
        const getPoint = (linePointId): ShortPointDescription => linePointId === pointId
          ? this._config.cachedPoints.getConvertedPoint(linePointId)
          : this._config.getPointCoordinates(linePointId);
        for (const lineId of pointInfo.lines) {
          const elementMeasurement = this._config.getInstanceMeasures(lineId);
          if (elementMeasurement) {
            if (lineId in updatedLines) {
              continue;
            }
            updatedLines[lineId] = true;
            const [start, end] = DrawingAnnotationUtils.getPointsIdsFromLineKey(lineId).map(getPoint);
            const pxLength = DrawingsCanvasUtils.calculateLineLength(start, end);
            const color = this._config.getColorOfInstance(pointInfo.instanceId);
            measuresUpdates.push({
              type: DrawingsInstanceType.Segment,
              id: DrawingAnnotationUtils.getPointsIdsFromLineKey(lineId),
              color,
              measures: {
                pxLength,
                length: DrawingsCanvasUtils.pxToMetres(pxLength, scale, metersPerPixel),
              },
            });
          }
        }
      }
    }
    return measuresUpdates;
  }


  public splitPolyline(instanceId: string, selectedPointsIds: string[]): void {
    const sourceInstance = this._config.getDrawingInstance(instanceId);

    const pointsIndices = selectedPointsIds.map(x => sourceInstance.geometry.points.indexOf(x)).sort((a, b) => a - b);

    if (pointsIndices[0] === 0) {
      pointsIndices.shift();
    }
    const firstBreakPoint = pointsIndices.shift();
    const pointsToRemove = sourceInstance.geometry.points.slice(firstBreakPoint + 1);
    const {
      geometry,
      measures,
      removedLines,
    } = this._config.entityRenderHelper.removePoint(pointsToRemove, instanceId);
    const newGeometries = [];
    const newPoints = {};
    for (
      const instance
      of this.splitedPolylineGenerator(pointsIndices, sourceInstance.geometry.points, firstBreakPoint)
    ) {
      const newFirstPointId = UuidUtil.generateUuid();
      newPoints[newFirstPointId] = this._config.cachedPoints.getConvertedPoint(instance[0]);
      for (let i = 1; i < instance.length; i++) {
        newPoints[instance[i]] = this._config.cachedPoints.getConvertedPoint(instance[i]);
      }
      instance[0] = newFirstPointId;
      const newDrawingInstance = {
        ...sourceInstance,
        id: UuidUtil.generateUuid(),
        name: DrawingAnnotationNamingUtils.getDefaultGeometryName(sourceInstance.type),
        geometry: { ...geometry, points: instance },
      };
      newGeometries.push(newDrawingInstance);
    }

    const payload = {
      addedInstances: newGeometries,
      newPoints,
      updated: { instance: { id: instanceId, geometry }, removePoints: pointsToRemove, removedLines },
      pageId: sourceInstance.drawingId,
    };
    const stateMeasure = this._config.getInstanceMeasures(instanceId);
    this._config.cachedPoints.addPoints(newPoints);
    if (stateMeasure) {
      this._config.onBatchUpdateGeometries(payload, [{ ...stateMeasure, measures }], [stateMeasure]);
    } else {
      this._config.onBatchUpdateGeometries(payload);
    }
  }

  public splitCount(instanceId: string, selectedPointsIds: string[]): void {
    const sourceInstance = this._config.getDrawingInstance(instanceId);
    const { geometry, measures } = this._config.entityRenderHelper.removePoint(selectedPointsIds, instanceId);
    const newPoints = selectedPointsIds.reduce((acc, x) => {
      const pointId = UuidUtil.generateUuid();
      acc.points[pointId] = this._config.cachedPoints.getConvertedPoint(x);
      acc.ids.push(pointId);
      return acc;
    }, { points: {}, ids: [] });
    const newDrawingInstance = {
      ...sourceInstance,
      id: UuidUtil.generateUuid(),
      name: DrawingAnnotationNamingUtils.getDefaultGeometryName(sourceInstance.type),
      geometry: { ...geometry, points: newPoints.ids },
    };
    const payload: DrawingsBatchUpdateGeometries = {
      addedInstances: [newDrawingInstance],
      newPoints: newPoints.points,
      updated: { instance: { id: instanceId, geometry }, removePoints: selectedPointsIds },
      pageId: sourceInstance.drawingId,
    };
    const stateMeasure = this._config.getInstanceMeasures(instanceId);
    if (stateMeasure) {
      this._config.onBatchUpdateGeometries(payload, [{ ...stateMeasure, measures }], [stateMeasure]);
    } else {
      this._config.onBatchUpdateGeometries(payload);
    }
  }

  @autobind
  public removePoints(ids: string[]): void {
    const currentDrawing = this._config.getCurrentDrawing();
    const { updated, measures } = this.removePointFromInstance(ids);
    this._config.onBatchUpdateGeometries({ updated, pageId: currentDrawing.drawingId }, measures);
  }

  public processNewPoints(coordinates: ShortPointDescription[]): Record<string, ShortPointDescription> {
    const newPoints = {};
    for (const coordinate of coordinates) {
      const pointId = UuidUtil.generateUuid();
      this._config.cachedPoints.setPoint(pointId, new paper.Point(coordinate));
      newPoints[pointId] = coordinate;
    }
    return newPoints;
  }

  private *splitedPolylineGenerator(
    indicedPoints: number[],
    points: string[],
    startIndex: number,
  ): IterableIterator<string[]> {
    let newInstance = [];
    const indices = indicedPoints.slice();
    let nextBreakPoint = indices.shift();
    for (let i = startIndex; i < points.length; i++) {
      newInstance.push(points[i]);
      if (i === nextBreakPoint) {
        yield newInstance;
        nextBreakPoint = indices.shift();
        newInstance = [points[i]];
      }
    }

    if (newInstance.length > 1) {
      yield newInstance;
    }
  }
}
