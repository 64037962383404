import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsDragCallbackParams, DrawingsDragProcessingHelper } from '../drag-instances';
import { DrawingsCursorTypeHelper } from '../visual-helpers';


type DragAnnotationsCallback = (diff: paper.Point, ids: string[]) => boolean;

interface DrawingsUserAnnotationDragHelperConfig {
  dragHelper: DrawingsDragProcessingHelper;
  cursorHelper: DrawingsCursorTypeHelper;
  saveDragResult: (ids: string[], diff: paper.Point) => void;
  enabled: boolean;
}

export class DrawingsUserAnnotationDragHelper {
  private _dragStickers: DragAnnotationsCallback;
  private _dragImages: DragAnnotationsCallback;
  private _startDragPoint: paper.Point;
  private _dragAnnotations: string[];
  private _config: DrawingsUserAnnotationDragHelperConfig;

  constructor(config: DrawingsUserAnnotationDragHelperConfig) {
    this._config = config;
  }

  public set dragStickers(value: DragAnnotationsCallback) {
    this._dragStickers = value;
  }

  public set dragImages(value: DragAnnotationsCallback) {
    this._dragImages = value;
  }

  public set enabled(value: boolean) {
    this._config.enabled = value;
  }

  public startDrag(point: paper.Point, ids: string[]): void {
    if (!this._config.enabled) {
      return;
    }
    this._startDragPoint = point;
    this._dragAnnotations = ids;
    this._config.dragHelper.setCallback(this.drag, { defaultValidPoint: point });
    this._config.cursorHelper.grabbing = true;
  }

  @autobind
  private drag({ point, finish }: DrawingsDragCallbackParams): boolean {
    const diff = point.subtract(this._startDragPoint);
    this.moveAnnotations(diff, this._dragAnnotations);
    if (finish) {
      this._config.cursorHelper.grabbing = false;
      this._config.saveDragResult(this._dragAnnotations, diff);
    }
    return true;
  }

  @autobind
  private moveAnnotations(diff: paper.Point, ids: string[]): void {
    if (this._dragStickers) {
      this._dragStickers(diff, ids);
    }
    if (this._dragImages) {
      this._dragImages(diff, ids);
    }
  }
}
