import * as Ag from 'ag-grid-community';

import { DefaultPivotColumnKey } from '../../enums/default-pivot-column-key';
import { QtoReportColumnsModel, QtoTreeColumn } from '../../interfaces/quantity-take-off';
import { PropertyHelper, QtoReportPivotTableIdHelper } from '../../utils/quantity-take-off-tree-table';

const getName = (column: QtoTreeColumn): string => {
  const headerKey = PropertyHelper.columnProperties.header;
  const headerProperty = column.properties[headerKey];
  return PropertyHelper.getActualValue(headerProperty);
};

const getMenuItem = (
  id: string,
  columns: Record<string, QtoTreeColumn>,
  action: (id: string) => void,
  displayingPivotRows: Record<string, boolean>,
): Ag.MenuItemDef => {
  const column = columns[id];
  const disabled = id in displayingPivotRows;
  const subMenu = column.children ? [] : null;
  if (column.children) {
    for (const childId of column.children) {
      if (!QtoReportPivotTableIdHelper.isIdContainDiffPostfix(childId)) {
        const subMenuItem = getMenuItem(childId, columns, action, displayingPivotRows);
        subMenu.push(subMenuItem);
      }
    }
  }

  return {
    name: getName(column),
    disabled,
    subMenu,
    action: !column.children
      ? () => action(id)
      : null,
  };
};

const getAddPivotRowSubMenu = (
  basicColumns: QtoReportColumnsModel,
  action: (id: string) => void,
  node: Ag.RowNode,
): Ag.MenuItemDef[] => {
  const displayingPivotRows = node.childrenAfterGroup.reduce(
    (displayRows, row, index) => {
      const ids = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(row.id);
      if (ids && !QtoReportPivotTableIdHelper.isIdContainDiffPostfix(ids.columnId)) {
        displayRows[ids.columnId] = index;
      }

      return displayRows;
    },
    {});

  const result = [];
  for (const columnId of basicColumns.firstLevelColumns) {
    if (!QtoReportPivotTableIdHelper.isIdContainDiffPostfix(columnId)) {
      const menuItem = getMenuItem(columnId, basicColumns.columns, action, displayingPivotRows);
      result.push(menuItem);
    }
  }

  return result;
};

const getGroupContextMenuItems = (
  basicColumns: QtoReportColumnsModel,
  addPivotRowAction: (id: string) => void,
  node: Ag.RowNode,
): Ag.MenuItemDef[] => {
  const subMenu = getAddPivotRowSubMenu(basicColumns, addPivotRowAction, node);

  return [
    {
      name: 'Add Pivot Row',
      disabled: !subMenu || !subMenu.length,
      subMenu,
    },
  ];
};

const getPivotRowContextMenuItems = (
  node: Ag.RowNode,
  hidePivotRow: () => void,
  setDefaultVisibility: () => void,
): Ag.MenuItemDef[] => {
  const IsVisibleKey = QtoReportPivotTableIdHelper
    .getPivotPropertyIdFromPivotRowId(node.id, DefaultPivotColumnKey.IsVisible);
  return [
    {
      name: 'Force Hide Row',
      action: hidePivotRow,
    },
    {
      name: 'Set Default Row Visibility',
      action: setDefaultVisibility,
      disabled: !node.parent.data.properties[IsVisibleKey],
    },
  ];
};

export const QtoReportPivotTableContextMenuService = {
  getPivotRowContextMenuItems,
  getGroupContextMenuItems,
};
