import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { CostEstimateDatabaseAssignActivityRouteParams } from 'routes/app-routes-params';
import {
  ActivityAssignmentDatabaseActivityListing,
} from '../components/activity-assignment-database-activity-listing';


interface Props extends RouteComponentProps<CostEstimateDatabaseAssignActivityRouteParams> {}

export class ActivityAssignmentDatabaseActivityListingPage extends React.Component<Props> {
  public render(): JSX.Element {
    const databaseId = +this.props.match.params.dbId;
    const elementId = +this.props.match.params.elementId;
    const revisionId = +this.props.match.params.revisionId;
    const projectId = +this.props.match.params.projectId;

    return (
      <ActivityAssignmentDatabaseActivityListing
        databaseId={databaseId}
        workId={elementId}
        projectId={projectId}
        revisionId={revisionId}
      />
    );
  }
}
