import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasConstants } from 'common/components/drawings/constants/drawing-canvas-constants';
import { DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsInstanceType } from 'common/components/drawings/enums/drawings-instance-type';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { ContextObserver, ContextObserverWithPrevious } from '../../../drawings-contexts';
import { DrawingsRenderParams } from '../../../interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from '../../../interfaces/drawing-text-render-parameters';
import { DrawingsPaperColorInfo } from '../../../interfaces/drawings-canvas-context-props';
import { SnappingGuideInfo } from '../../drawings-helpers/snapping/with-self-snapping';
import { DrawingsGeometryBaseAutocomplete, GetCurrentDrawingCallback } from '../../interfaces';
import { DrawingsGeometryEntityAngleArc } from '../drawings-geometry-entity-angle-arc';
import { DrawingsGeometryEntityPoint } from '../drawings-geometry-entity-point';
import { DrawingsGeometryEntityMeasureLength } from '../utility/measure-length';
import {
  DrawingsGeometryTempEntity,
  DrawingsGeometryTempEntityConfig,
  FinishedDrawingElement,
  TryAddPointConfig,
} from './drawings-geometry-temp-entity';


export interface DrawingsGeometryTempEntityWithStrokeConfig extends DrawingsGeometryTempEntityConfig {
  getDrawingInfo: GetCurrentDrawingCallback;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  textRenderParametersObserver: ContextObserver<MeasuresViewSettings>;
  autocomplete?: DrawingsGeometryBaseAutocomplete;

}

export class DrawingsGeometryTempEntityWithStroke<
  T extends DrawingsGeometryTempEntityWithStrokeConfig = DrawingsGeometryTempEntityWithStrokeConfig
>
  extends DrawingsGeometryTempEntity<T> {
  protected _path: paper.Path;
  protected _points: DrawingsGeometryEntityPoint[];
  protected _measures: DrawingsGeometryEntityMeasureLength[];
  protected _arc: DrawingsGeometryEntityAngleArc;

  constructor(config: T) {
    super(config);
    this._path = new paper.Path();
    DrawingsPaperUtils.updatePathStyles(this._path);
    this._path.strokeColor = this._config.color.stroke;
    this._points = [];
    this._path.addTo(this._config.layer);

    this._config.textRenderParametersObserver.subscribe(this.updateLabels);
    this._config.renderParametersContextObserver.subscribe(this.updateStrokeParams);
  }

  public destroy(): void {
    this._path.remove();

    this.removeVisualData();

    this._points.forEach(x => x.destroy());

    this._config.renderParametersContextObserver.unsubscribe(this.updateStrokeParams);
    this._config.textRenderParametersObserver.unsubscribe(this.updateLabels);
  }

  public set strokeStyle(value: DrawingStrokeStyles) {
    super.strokeStyle = value;
    const { zoom } = this._config.renderParametersContextObserver.getContext();
    const { strokeWidth } = this._config;
    this._path.dashArray = DrawingsCanvasUtils.scaleStroke({ strokeStyle: value, strokeWidth }, zoom);
  }

  public set strokeWidth(value: number) {
    super.strokeWidth = value;
    const { zoom } = this._config.renderParametersContextObserver.getContext();
    const { strokeStyle } = this._config;
    this._path.strokeWidth = value / zoom;
    this._path.dashArray = DrawingsCanvasUtils.scaleStroke({ strokeStyle, strokeWidth: value }, zoom);
  }

  public get pointsCount(): number {
    return this._path.segments.length;
  }

  public get lastPoint(): paper.Point {
    return this._path.lastSegment.point;
  }

  public get lastStablePoint(): paper.Point {
    return this._path.lastSegment?.previous?.point;
  }

  public get hasPoint(): boolean {
    return !!this._path.segments.length;
  }


  @autobind
  public getSnappingGuideInfo(point: paper.Point): SnappingGuideInfo {
    if (!this._path) {
      return null;
    }
    return DrawingsPaperUtils.getGuidelineValue(this.statePointsIterator(), point);
  }

  public canComplete(addLastPoint: boolean): boolean {
    return this._path.segments.length >= (3 - Number(addLastPoint));
  }

  public removeLastPoint(): paper.Point | null {
    if (this.hasPoint) {
      if (this._path.segments.length === 1) {
        this._path.removeSegments();
        this._points.forEach(x => x.destroy());
        this._points = [];
        if (this._measures) {
          this._measures.forEach(x => x.destroy());
          this._measures = [];
        }
        if (this._arc) {
          this._arc.destroy();
          this._arc = null;
        }
        this.updateAutocomplete();
        return null;
      } else {
        const lastPointPosition = this._path.lastSegment.point;
        this._path.removeSegment(this._path.segments.length - 1);
        const lastPoint = this._points.pop();
        if (lastPoint) {
          lastPoint.destroy();
        }
        this.updateAutocomplete();
        return lastPointPosition;
      }
    }
    return null;
  }

  public updateColor(color: DrawingsPaperColorInfo): void {
    this._config.color = color;
    this._path.strokeColor = color.stroke;

    if (this._measures) {
      this._measures.forEach(measure => measure.changeColor(color.stroke));
    }
    if (this._arc) {
      this._arc.changeColor(this._config.color.stroke);
    }
  }

  public getPoint(index: number): paper.Point {
    return this._path.segments[index].point;
  }

  public tryAddPoint(point: paper.Point, config: TryAddPointConfig): boolean {
    const pathOnly = config?.pathOnly;
    this._path.addSegments([new paper.Segment(point)]);
    if (!pathOnly) {
      this._points.push(
        new DrawingsGeometryEntityPoint({
          renderParamsContextObserver: this._config.renderParametersContextObserver,
          id: `temp ${this._points.length}`,
          onStartDrag: null,
          onSelect: null,
          layer: this._config.layer,
          geometry: [point.x, point.y],
        }),
      );
    }
    this.updateAutocomplete();
    return true;
  }

  public convert(
    addLastPoint: boolean,
    instanceType: DrawingsInstanceType,
  ): FinishedDrawingElement[] {
    const { strokeStyle, strokeWidth, color, offset } = this._config.newDrawingStylesObserver.getContext();
    const { points, polyline } = DrawingsPaperUtils.convertPathToPolyline(
      this._path,
      color,
      strokeWidth,
      strokeStyle,
      addLastPoint,
    );
    if (offset !== null && offset !== undefined) {
      polyline.offset = offset;
    }
    return [{
      type: instanceType,
      geometry: polyline,
      points,
    }];
  }

  public updateTempPointPosition(point: paper.Point, strictAngle?: boolean): paper.Point {
    let currentPoint = point;
    if (this._path && this._path.segments.length) {
      this._path.lastSegment.point = point;
      if (this._path.segments.length > 1) {
        const lastSegment = this._path.lastSegment;
        const points = [
          lastSegment.point,
          lastSegment.previous.point,
          lastSegment.previous.previous ? lastSegment.previous.previous.point : null,
        ] as [paper.Point, paper.Point, paper.Point];
        currentPoint = this.processPositionChange(points, strictAngle) || point;
        this.renderMeasures(points);
        this.renderOrUpdateArc(points);

      } else {
        this.removeVisualData();
      }
    }
    return currentPoint;
  }

  protected processPositionChange(
    points: [paper.Point, paper.Point, paper.Point],
    strictAngle: boolean,
  ): paper.Point {
    if (strictAngle) {
      const point = DrawingsCanvasUtils.bindToStrictAngle(points, DrawingsCanvasConstants.snappingAngleStep);
      const drawingInfo = this._config.getDrawingInfo();
      this._path.lastSegment.point =
        DrawingsCanvasUtils.getLineToCanvasIntersection(point, this._path.lastSegment.previous.point, drawingInfo)
        || point;
      return this._path.lastSegment.point;
    }
  }

  @autobind
  protected updateStrokeParams(): void {
    const { zoom } = this._config.renderParametersContextObserver.getContext();
    if (this._path) {
      this._path.strokeWidth = this._config.strokeWidth / zoom;
      this._path.dashArray = DrawingsCanvasUtils.scaleStroke(this._config, zoom);
    }
  }

  protected renderOrUpdateArc(points: [paper.Point, paper.Point, paper.Point]): void {
    if (!this._arc) {
      this._arc = new DrawingsGeometryEntityAngleArc({
        id: 'temp',
        points,
        color: this._config.color.stroke,
        layer: this._config.layer,
        mustExistInPath: true,
        clampPath: this._path,
        specificViewForRightAngle: false,
        radius: DrawingsCanvasConstants.angleCircleRadius / 2,
        renderParamsContextObserver: this._config.renderParametersContextObserver,
        textRenderParamsObserver: this._config.textRenderParametersObserver,
      });
    } else {
      this._arc.updatePoints(points, this._path, false);
    }
  }

  protected renderMeasures(points: [paper.Point, paper.Point, paper.Point], lastMeasureIndex: number = 0): void {
    const [p1, p2, p3] = points;
    const startAngle = p1.subtract(p2).angle;
    const endAngle = p3 ? p3.subtract(p2).angle : 0;
    this._measures = this._measures || [];
    const measure = this._measures[lastMeasureIndex];
    if (!measure) {
      this._measures.push(
        new DrawingsGeometryEntityMeasureLength(
          {
            textLayer: this._config.layer,
            id: 'temp',
            points: points.slice(0, 2) as [paper.Point, paper.Point],
            color: this._config.color.stroke,
            layer: this._config.layer,
            toOtherSide: DrawingsCanvasUtils.needRenderMeasureOnOtherSide(startAngle - endAngle),
            renderParamsContextObserver: this._config.renderParametersContextObserver,
            textRenderParamsObserver: this._config.textRenderParametersObserver,
          },
        ),
      );
    } else {
      measure.changeLine(points.slice(0, 2) as [paper.Point, paper.Point]);
      measure.renderOnOtherSide(DrawingsCanvasUtils.needRenderMeasureOnOtherSide(startAngle - endAngle));
    }
  }

  protected updateAutocomplete(): void {
    if (this._config.autocomplete) {
      this._config.autocomplete.updateGeometry(this._points.map(x => x.position));
    }
  }

  @autobind
  private updateLabels({ rotation }: MeasuresViewSettings): void {
    if (this._measures) {
      this._measures.forEach(measure => measure.changeLabel());
    }

    if (this._arc) {
      this._arc.textRotation = rotation;
    }
  }

  private removeVisualData(): void {
    if (this._arc) {
      this._arc.destroy();
      this._arc = null;
    }
    if (this._measures) {
      this._measures.forEach(x => x.destroy());
      this._measures = null;
    }
  }

  private* statePointsIterator(): IterableIterator<paper.Point> {
    for (let i = 0; i < this._path.segments.length - 1; i++) {
      yield this._path.segments[i].point;
    }
  }
}
