import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Optimize = styled.div<{ isNeedUpdate: boolean }>`
  display: flex;
  align-items: center;

  ${Text} {
    margin-left: 15px;
    margin-right: 30px;
  }

  > svg {
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }

  > div {
    > svg {
      display: ${p => p.isNeedUpdate ? 'none' : 'block'};
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  ${UpgradeWrapperStyled.StarsContainer} {
    right: -8px;
    top: -8px;
  }
`;

const OpenCloseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  > div {
    height: 20px;
    padding-right: 20px;
  }
`;

export const Styled = {
  Container,
  Optimize,
  ButtonsContainer,
  OpenCloseButtonContainer,
};
