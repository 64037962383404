import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsGeometryEntityState } from 'common/components/drawings/enums/drawings-geometry-entity-state';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import { DrawingsGeometryParams } from 'common/components/drawings/interfaces';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { DrawingsGeometryStyle } from 'common/components/drawings/interfaces/drawings-geometry-style';
import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { arrayUtils } from 'common/utils/array-utils';
import { DrawingsGeometryEntityConfig } from '../drawings-geometry-entity-config';
import { DrawingsGeometryEntityBase } from './drawings-geometry-entity-base';
import { DrawingsGeometryEntityPointBase } from '.';

export interface ModifiableEntityConfig extends DrawingsGeometryEntityConfig {
  colorHelper: DrawingsColorCacheHelper;
  geometry: { color: string };
  color: DrawingsPaperColorInfo;
}

export class DrawingsGeometryEntityModifiable
<
  T extends ModifiableEntityConfig = ModifiableEntityConfig,
  K extends DrawingsGeometryEntityPointBase = DrawingsGeometryEntityPointBase,
> extends DrawingsGeometryEntityBase<T>  {
  protected _points: Map<string, K> = new Map<string, K>();

  private _state: DrawingsGeometryEntityState = DrawingsGeometryEntityState.Default;
  private _selectedPointsIds: string[] = [];
  private _onChangePointsSelection: (ids: string[]) => void;

  public get selected(): boolean {
    return this._state === DrawingsGeometryEntityState.Selected;
  }

  public set state(value: DrawingsGeometryEntityState) {
    if (value === this._state) {
      return;
    }
    if (this.applyState) {
      this.applyState(value);
    }
    this._state = value;
  }

  public get state(): DrawingsGeometryEntityState {
    return this._state;
  }

  public get selectedPointsIds(): string[] {
    return this._selectedPointsIds;
  }

  public set onChangePointsSelection(value: (ids: string[]) => void) {
    this._onChangePointsSelection = value;
  }

  public updateStyle<P extends keyof DrawingsGeometryStyle>(field: P, value: DrawingsGeometryStyle[P]): void {
    if (field === 'color') {
      const color = this._config.colorHelper.getPaperColor(value as string);
      this.changeColor(color, value as string);
    } else if (this.applyStyle) {
      this.applyStyle(field, value);
    }
  }

  public updateGeometryParam<P extends keyof DrawingsGeometryParams>(field: P, value: DrawingsGeometryParams[P]): void {
    if (this.applyGeometryParam) {
      this.applyGeometryParam(field, value);
    }
  }

  public changeSelectedPointsByRectangle(
    rect: paper.Rectangle,
    ctrlKey: boolean,
  ): void {
    const pointIds = new Array<string>();
    if (this._points) {
      for (const [pointId, point] of this._points.entries()) {
        if (rect.contains(new paper.Point(point.position))) {
          pointIds.push(pointId);
        }
      }
    }
    this.updatePointsSelection(pointIds, ctrlKey);
  }

  public updatePointsSelection(pointIds: string[], ctrlKey: boolean, fromDisable?: boolean): void {
    if (ctrlKey) {
      this._selectedPointsIds = arrayUtils.symDiff(pointIds, this._selectedPointsIds);
    } else {
      this._selectedPointsIds = pointIds;
    }
    const selectedPointsSet = new Set(this._selectedPointsIds);
    if (this._points && !fromDisable) {
      this._points.forEach((point, key) => point.selected = selectedPointsSet.has(key));
    }
    if (this._onChangePointsSelection) {
      this._onChangePointsSelection(this._selectedPointsIds);
    }
  }

  public canSplit(): boolean {
    return false;
  }

  protected applyState?(state: DrawingsGeometryEntityState): void;
  protected applyStyle?<P extends keyof DrawingsGeometryStyle>(field: P, value: DrawingsGeometryStyle[P]): void;
  protected applyGeometryParam?<P extends keyof DrawingsGeometryParams>(
    field: P,
    value: DrawingsGeometryParams[P],
  ): void;

  protected clearModifyState(): void {
    this._selectedPointsIds = [];
  }


  @autobind
  protected onPointSelect(id: string, e: PaperMouseEvent): void {
    this.updatePointsSelection([id], HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e.event));
  }

  protected changeColor(value: DrawingsPaperColorInfo, colorCode: string): void {
    this._config.geometry.color = colorCode;
    this._config.color = value;
  }
}
