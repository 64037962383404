import { arrayUtils } from 'common/utils/array-utils';

export enum FormulaDefaultFunctions {
  MeasuredCount = 'MeasuredCount',
  MeasuredSegmentsCount = 'MeasuredSegmentsCount',
  MeasuredPointsCount = 'MeasuredPointsCount',
  MeasuredLength = 'MeasuredLength',
  MeasuredHeight = 'MeasuredHeight',
  MeasuredThickness = 'MeasuredThickness',
  MeasuredPerimeter = 'MeasuredPerimeter',
  MeasuredArea = 'MeasuredArea',
  MeasuredVerticalArea = 'MeasuredVerticalArea',
  MeasuredVolume = 'MeasuredVolume',
  MeasuredRectangleMin = 'MeasuredRectangleMin',
  MeasuredRectangleMax = 'MeasuredRectangleMax',
  MeasuredPageName = 'MeasuredPageName',
}

export const DEFAULT_FUNCTIONS = Object.values(FormulaDefaultFunctions);
const HIDDEN_FUNCTIONS = [FormulaDefaultFunctions.MeasuredRectangleMin, FormulaDefaultFunctions.MeasuredRectangleMax];
export const VISIBLE_FUNCTIONS = DEFAULT_FUNCTIONS.filter(x => !HIDDEN_FUNCTIONS.includes(x));
export const HIDDEN_MENU_FUNCTIONS = [FormulaDefaultFunctions.MeasuredPageName];

export const MEASURED_FUNCTION_TO_VALUE_MAP: Record<FormulaDefaultFunctions, string> = {
  [FormulaDefaultFunctions.MeasuredArea]: 'area',
  [FormulaDefaultFunctions.MeasuredPerimeter]: 'perimeter',
  [FormulaDefaultFunctions.MeasuredLength]: 'length',
  [FormulaDefaultFunctions.MeasuredRectangleMin]: 'rectangleMin',
  [FormulaDefaultFunctions.MeasuredRectangleMax]: 'rectangleMax',
  [FormulaDefaultFunctions.MeasuredCount]: 'count',
  [FormulaDefaultFunctions.MeasuredSegmentsCount]: 'segmentsCount',
  [FormulaDefaultFunctions.MeasuredPointsCount]: 'pointsCount',
  [FormulaDefaultFunctions.MeasuredVolume]: 'volume',
  [FormulaDefaultFunctions.MeasuredVerticalArea]: 'verticalArea',
  [FormulaDefaultFunctions.MeasuredHeight]: 'height',
  [FormulaDefaultFunctions.MeasuredThickness]: 'thickness',
  [FormulaDefaultFunctions.MeasuredPageName]: 'pageName',
};


export const FUNCTION_NAMES: Record<FormulaDefaultFunctions, string> = {
  [FormulaDefaultFunctions.MeasuredArea]: 'Area',
  [FormulaDefaultFunctions.MeasuredPerimeter]: 'Perimeter',
  [FormulaDefaultFunctions.MeasuredLength]: 'Length',
  [FormulaDefaultFunctions.MeasuredRectangleMin]: 'Rectangle Min',
  [FormulaDefaultFunctions.MeasuredRectangleMax]: 'Rectangle Max',
  [FormulaDefaultFunctions.MeasuredCount]: 'Count',
  [FormulaDefaultFunctions.MeasuredSegmentsCount]: 'Segments Count',
  [FormulaDefaultFunctions.MeasuredPointsCount]: 'Points Count',
  [FormulaDefaultFunctions.MeasuredVolume]: 'Volume',
  [FormulaDefaultFunctions.MeasuredVerticalArea]: 'Vertical Area',
  [FormulaDefaultFunctions.MeasuredHeight]: 'Height',
  [FormulaDefaultFunctions.MeasuredThickness]: 'Thickness',
  [FormulaDefaultFunctions.MeasuredPageName]: 'Page Name',
};

export const VALUE_TO_MEASURED_FUNCTION_MAP: Record<string, string> = arrayUtils.toDictionary(
  Object.entries(MEASURED_FUNCTION_TO_VALUE_MAP),
  ([_, value]) => value,
  ([key, _]) => key,
);
