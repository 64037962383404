import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  DrawingsAggregatedSelection,
  DrawingsGeometryInstance,
  DrawingsSelectAggregationGroup,
} from 'common/components/drawings/interfaces';

import { DrawingsGeometryUtils } from 'common/components/drawings/utils/drawings-geometry-utils';
import { State } from 'common/interfaces/state';

export function useAggregatedSelection(
  selectedInstances: string[],
): {
  aggregatedSelection: Record<DrawingsSelectAggregationGroup, DrawingsAggregatedSelection>,
  currentType: DrawingsSelectAggregationGroup,
} {
  const drawingId = useSelector<State, string>(x => x.drawings.currentDrawingInfo?.drawingId);
  const geometries = useSelector<State, Record<string, DrawingsGeometryInstance>>(
    x => x.drawings.aiAnnotation.geometry,
  );
  return useMemo(() => {
    const aggregatedSelection = DrawingsGeometryUtils.aggregateSelection(geometries, selectedInstances, drawingId);
    const aggregationKeys = Object.keys(aggregatedSelection) as DrawingsSelectAggregationGroup[];
    const currentType = aggregationKeys.length === 1 ? aggregationKeys[0] : undefined;
    return {
      aggregatedSelection,
      currentType,
    };
  }, [selectedInstances, drawingId, geometries]);
}
