import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { DrawingsInstanceType } from '../../enums';
import { useInstances } from '../../hooks';

interface UseInstancesToOperateWithResult {
  instancesToOperateWith: string[];
  hasStrokedInstancesInSelected: boolean;
}

export function useInstancesToOperateWith(): UseInstancesToOperateWithResult {
  const filteredIds = useSelector<State, string[]>((x) => x.drawings.filteredElementIds);
  const selectedInstances = useSelector<State, string[]>((x) => x.drawings.selectedInstances);
  const instances = useInstances();
  return useMemo(() => {
    const filteredIdsSet = new Set(filteredIds);
    const result = {
      instancesToOperateWith: [],
      hasStrokedInstancesInSelected: false,
    };
    if (filteredIdsSet.size === 0) {
      return result;
    }
    for (const instanceId of selectedInstances) {
      const instance = instances[instanceId];
      if (instance) {
        result.instancesToOperateWith.push(instanceId);
        if (instance.type !== DrawingsInstanceType.Count) {
          result.hasStrokedInstancesInSelected = true;
        }
      }
    }
    return result;
  }, [filteredIds, instances, selectedInstances]);
}
