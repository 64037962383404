import classNames from 'classnames';
import React from 'react';

import './kreo-dialog-actions-panel.scss';

import { KreoButton } from 'common/UIKit';

interface Props {
  submitDisabled?: boolean;
  centered?: boolean;
  className?: string;
  submitBtnName?: string;
  submitBtnControlName?: string;
  cancelBtnName?: string;
  cancelBtnControlName?: string;
  size?: 'medium';
  onCancel?: () => void;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export class KreoDialogActionsPanel extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    submitBtnName: 'Confirm',
    cancelBtnName: 'Cancel',
  };

  public render(): React.ReactNode {
    const {
      onCancel,
      onSubmit,
      submitDisabled,
      className,
      centered,
      cancelBtnName,
      cancelBtnControlName,
      submitBtnName,
      submitBtnControlName,
      children,
      size,
    } = this.props;
    return (
      <div
        className={classNames('kreo-dialog-actions-panel', className, {
          [`kreo-dialog-actions-panel--${size}`]: !!size,
          centered,
        })}
      >
        {children ? (
          children
        ) : (
          <React.Fragment>
            {onCancel && (
              <KreoButton
                size={size ? size : 'large'}
                caption={cancelBtnName}
                onClick={onCancel}
                mode='ghost'
                htmlButtonType='button'
                controlName={cancelBtnControlName ? cancelBtnControlName : 'dialog-cancel-btn'}
              />
            )}
            {onSubmit && (
              <KreoButton
                size={size ? size : 'large'}
                mode={'submit'}
                caption={submitBtnName}
                htmlButtonType='submit'
                onClick={onSubmit}
                disabled={submitDisabled}
                controlName={submitBtnControlName ? submitBtnControlName : 'dialog-submit-btn'}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
