import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { selectWrapper } from 'common/utils/saga-wrappers';

import { NotificationApi } from '../notifications/api';
import { Project } from '../projects/interfaces/project';
import { getCurrentProject } from '../projects/selectors';
import { ActivityActions, ActivityActionTypes } from './actions';
import { UserActivity } from './interfaces/user-activity';

function* getCompanyActivities(): SagaIterator {
  try {
    const activities: UserActivity[] = yield call(NotificationApi.getCompanySubscriptionActivities);
    yield put(ActivityActions.getUserActivitiesSucceeded(activities));
  } catch (error) {
    yield put(ActivityActions.getUserActivitiesFail());
    console.error('activity: get company activities failed', error);
  }
}
// todo: https://kreosoftware.atlassian.net/browse/KREOP-13390 проверить вообще ли нужно
function* getDocumentActivities(): SagaIterator {
  try {
    const project: Project = yield selectWrapper(getCurrentProject);
    const activities: UserActivity[] = yield call(
      NotificationApi.getDocumentActivities,
      project.id,
    );
    yield put(ActivityActions.getUserActivitiesSucceeded(activities));
  } catch (error) {
    yield put(ActivityActions.getUserActivitiesFail());
    console.error('activity: get document activities failed', error);
  }
}

export function* activitySagas(): SagaIterator {
  yield takeLatest(ActivityActionTypes.LOAD_REQUEST_BY_COMPANY_SUBSCRIPTION, getCompanyActivities);
  yield takeLatest(ActivityActionTypes.LOAD_REQUEST_BY_DOCUMENT, getDocumentActivities);
}
