export const Currency = {
  'USD': {
    shortcat: 'USD',
    symbol: '$',
    name: 'US Dollar',
  },
  'GBP': {
    shortcat: 'GBP',
    symbol: '£',
    name: 'Pound Sterling',
  },
  'AUD': {
    shortcat: 'AUD',
    symbol: '$',
    name: 'Australian dollar',
  },
  'NZD': {
    shortcat: 'NZD',
    symbol: '$',
    name: 'New Zealand dollar',
  },
  'CAD': {
    shortcat: 'CAD',
    symbol: '$',
    name: 'Canadian dollar',
  },
  'EUR': {
    shortcat: 'EUR',
    symbol: '€',
    name: 'Euro',
  },
};
