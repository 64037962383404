import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as monolite from 'monolite';
import * as React from 'react';

import { mathUtils } from 'common/utils/math-utils';
import { AnimationSpeed } from '../../../../../enums/animation-speed';
import { TimeframeHandle } from './timeframe-handle';

interface TimeframeProps {
  timeframeStartDay: number;
  timeframeDuration: number;
  timelineStartDay: number;
  horizontalMargin: number;
  minimalFrameDuration: number;
  isPlaying: boolean;
  currentMoment: number;
  nextCurrentMoment: number;
  handleWidth: number;
  pixelsPerDay: number;
  timelineDuration: number;
  updateTimeframe: (daysTillStart: number, duration: number) => void;
  play: () => void;
  pause: () => void;
  updateCurrentMoment: (currentMoment: number) => void;
  updateNextCurrentMoment: (nextCurrentMoment: number) => void;
  setAnimationSpeed: (animationSpeed: AnimationSpeed) => void;
  setCursorOnHandleStatus: (isOnHandle: boolean) => void;
}

interface TimeframeState {
  left: number;
  width: number;
  isDragged: boolean;
  dragDelta: number;
  wasPlaying: boolean;
  pointerOffsetPercentage: number;
  pointerStyle: React.CSSProperties;
}

export class Timeframe extends React.PureComponent<TimeframeProps, TimeframeState> {
  private container: HTMLDivElement = null;

  constructor(props: TimeframeProps) {
    super(props);

    const derivedState = Timeframe.getDerivedStateFromProps(props, null);

    this.state = {
      isDragged: false,
      dragDelta: null,
      pointerOffsetPercentage: null,
      wasPlaying: false,
      left: derivedState.left,
      width: derivedState.width,
      pointerStyle: derivedState.pointerStyle,
    };
  }

  public static getDerivedStateFromProps(
    nextProps: TimeframeProps,
    prevState: TimeframeState | null,
  ): Partial<TimeframeState> {
    let newState: Partial<TimeframeState> | null = null;

    const left = (nextProps.timeframeStartDay - nextProps.timelineStartDay) * nextProps.pixelsPerDay
      + nextProps.horizontalMargin - nextProps.handleWidth;
    if (!prevState || left !== prevState.left) {
      newState = monolite.set(newState || {}, (s) => s.left)(left);
    }

    const width = nextProps.timeframeDuration * nextProps.pixelsPerDay + 2 * nextProps.handleWidth;
    if (!prevState || width !== prevState.width) {
      newState = monolite.set(newState || {}, (s) => s.width)(width);
    }

    const currentMomentToRender = nextProps.nextCurrentMoment !== null
      ? nextProps.nextCurrentMoment
      : nextProps.currentMoment;
    const currentMomentOffset =
      (currentMomentToRender - nextProps.timeframeStartDay) * nextProps.pixelsPerDay + nextProps.handleWidth;
    if (!prevState || !prevState.pointerStyle || currentMomentOffset !== prevState.pointerStyle.left) {
      newState = {
        ...newState,
        pointerStyle: {
          left: currentMomentOffset,
        },
      };
    }

    return newState;
  }

  public render(): React.ReactNode {
    const style: React.CSSProperties = {
      transform: `translateX(${this.state.left}px)`,
      width: this.state.width,
    };

    const containerClassName = classNames('timeframe', {
      dragged: this.state.isDragged,
    });

    return (
      <div
        ref={this.setContainerRef}
        className={containerClassName}
        style={style}
        onMouseDown={this.onMouseDown}
        onContextMenu={this.preventDefault}
      >
        <TimeframeHandle
          offset={this.state.left}
          className='left'
          key='left-handle'
          onDragStart={this.onHandleDragStart}
          onDrag={this.onLeftHandleDrag}
          onDragEnd={this.onHandleDragEnd}
          setCursorOnHandleStatus={this.props.setCursorOnHandleStatus}
        />
        <div className='border left' key='left-border' />
        <div
          className='timeframe__time-pointer'
          style={this.state.pointerStyle}
        />
        <div className='border right' key='right-border' />
        <TimeframeHandle
          offset={this.state.left + this.state.width}
          className='right'
          key='right-handle'
          onDragStart={this.onHandleDragStart}
          onDrag={this.onRightHandleDrag}
          onDragEnd={this.onHandleDragEnd}
          setCursorOnHandleStatus={this.props.setCursorOnHandleStatus}
        />
      </div>
    );
  }

  @autobind
  private onHandleDragStart(): void {
    if (this.props.isPlaying) {
      this.setState({ wasPlaying: true });
      this.props.pause();
    }
    this.props.updateNextCurrentMoment(this.props.timeframeStartDay + this.props.timeframeDuration / 2);
    this.props.setAnimationSpeed(AnimationSpeed.None);
  }

  @autobind
  private onHandleDragEnd(): void {
    if (this.state.wasPlaying) {
      this.setState({ wasPlaying: false });
      this.props.play();
    }
    this.props.updateCurrentMoment(this.props.nextCurrentMoment);
    this.props.updateNextCurrentMoment(null);
    this.props.setAnimationSpeed(AnimationSpeed.Regular);
  }

  @autobind
  private onLeftHandleDrag(newLeftBorderOffset: number): void {
    const realOffset = newLeftBorderOffset - this.props.horizontalMargin;

    const daysTillFrameEnd = this.props.timeframeStartDay + this.props.timeframeDuration;

    const newDaysTillFrameStart = mathUtils.clamp(
      this.props.timelineStartDay + realOffset / this.props.pixelsPerDay,
      this.props.timelineStartDay,
      daysTillFrameEnd - this.props.minimalFrameDuration);

    const newDuration = daysTillFrameEnd - newDaysTillFrameStart;

    this.props.updateTimeframe(newDaysTillFrameStart, newDuration);
    this.props.updateNextCurrentMoment(newDaysTillFrameStart + newDuration / 2);
  }

  @autobind
  private onRightHandleDrag(newRightBorderOffset: number): void {
    const realOffset = newRightBorderOffset - this.props.horizontalMargin;

    const newDaysTillFrameEnd = mathUtils.clamp(
      this.props.timelineStartDay + realOffset / this.props.pixelsPerDay,
      this.props.timeframeStartDay + this.props.minimalFrameDuration,
      this.props.timelineStartDay + this.props.timelineDuration);

    const newDuration = newDaysTillFrameEnd - this.props.timeframeStartDay;

    this.props.updateTimeframe(this.props.timeframeStartDay, newDuration);
    this.props.updateNextCurrentMoment(this.props.timeframeStartDay + newDuration / 2);
  }

  @autobind
  private onMouseDown(event: React.MouseEvent<HTMLDivElement>): void {
    if (event.button !== 2 || event.currentTarget !== this.container) {
      return;
    }

    this.props.setAnimationSpeed(AnimationSpeed.None);
    event.preventDefault();

    let wasPlaying = false;
    if (this.props.isPlaying) {
      wasPlaying = true;
      this.props.pause();
    }

    const pointerOffsetPercentage =
      (this.props.currentMoment - this.props.timeframeStartDay) / this.props.timeframeDuration;

    this.setState({
      isDragged: true,
      dragDelta: event.pageX - this.state.left,
      wasPlaying,
      pointerOffsetPercentage,
    });

    this.addListeners();
    this.props.updateNextCurrentMoment(this.props.currentMoment);
  }

  private addListeners(): void {
    document.addEventListener('mousemove', this.onMouseMove as any);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  private removeListeners(): void {
    document.removeEventListener('mousemove', this.onMouseMove as any);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  @autobind
  private onMouseUp(): void {
    if (this.state.wasPlaying) {
      this.props.play();
    }

    this.setState({
      isDragged: false,
      dragDelta: null,
      wasPlaying: false,
      pointerOffsetPercentage: null,
    });

    this.removeListeners();
    this.props.updateCurrentMoment(this.props.nextCurrentMoment);
    this.props.updateNextCurrentMoment(null);
    this.props.setAnimationSpeed(AnimationSpeed.Regular);
  }

  @autobind
  private onMouseMove(event: React.MouseEvent<HTMLDocument>): void {
    if (this.state.isDragged) {
      const newOffset = event.pageX - this.state.dragDelta;
      const realOffset = newOffset - this.props.horizontalMargin;

      const newDaysTillFrameStart = mathUtils.clamp(
        realOffset / this.props.pixelsPerDay + this.props.timelineStartDay,
        this.props.timelineStartDay,
        this.props.timelineStartDay + this.props.timelineDuration - this.props.timeframeDuration);

      const nextCurrentMoment =
        newDaysTillFrameStart + this.props.timeframeDuration * this.state.pointerOffsetPercentage;
      this.props.updateTimeframe(newDaysTillFrameStart, this.props.timeframeDuration);
      this.props.updateNextCurrentMoment(nextCurrentMoment);
    }
  }

  private preventDefault(event: React.MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
  }

  @autobind
  private setContainerRef(ref: HTMLDivElement): void {
    this.container = ref;
  }
}
