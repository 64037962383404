import styled from 'styled-components';
import { KreoColors } from 'common/enums/kreo-colors';

const Container = styled.div`
  display: flex;
  padding-top: 10px;
`;

const Column = styled.div`
  width: 240px;
  display: flex;

  &:first-child {
    width: 270px;
    margin-right: 10px;
  }

  > div {
    margin-bottom: 20px;
  }
`;

const VatCountryLabel = styled.div<{ isInvalid: boolean }>`
  font-size: 12px;
  line-height: 60px;
  margin-right: ${p => p.isInvalid ? 13 : 15}px;
  margin-left: auto;
  color: ${props => props.theme.color.mainFont};
  min-width: fit-content;
`;

const ValidationError = styled.div`
  font-size: 12px;
  color: ${KreoColors.red};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Styled = {
  Container,
  Column,
  VatCountryLabel,
  ValidationError,
  InputContainer,
};
