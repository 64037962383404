import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import './databases.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { MainLayout } from 'common/components/main-layout';
import { SubHeader } from 'common/components/sub-header/sub-header';
import { SvgSpinner } from 'common/components/svg-spinner';
import { FaqCaption } from 'common/enums/faq-caption';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialogActions, KreoIconCreateNew, KreoIconDbCreate } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { getRootMenuItems } from '../../../../units/menu-items';
import { DatabaseActions } from '../../actions/creators/database';
import { DatabaseModel } from '../../interfaces/data';
import { CreateDatabaseForm } from '../../interfaces/rest-data';
import { CREATE_DATABASE_DIALOG_NAME, CreateDatabaseData, DatabaseDialog } from './database-dialog';
import { DatabaseItem } from './database-item';
import {
  REMOVE_DATABASE_CONFIRMATION_DIALOG,
  RemoveDatabaseConfirmationDialog,
} from './remove-database-confirmation-dialog';

interface ReduxProps {
  databases: DatabaseModel[];
  loadStatus: RequestStatus;
  loadCompaniesStatus: RequestStatus;
  companyId: number;
}

interface ReduxActions {
  loadDatabases: (companyId: number) => void;
  selectDatabase: (database: DatabaseModel) => void;
  createDatabase: (data: CreateDatabaseForm) => void;
  delete: (id: number) => void;
  setDefault: (id: number) => void;
  showDialog: (dialogName: string, data?: any) => void;
  closeDialog: (dialogName: string) => void;
}

interface Props extends ReduxProps, ReduxActions, RouteComponentProps<{}>, AbilityAwareProps {}
class DatabasePageComponent extends React.Component<Props> {
  private firstLoadFlag: boolean;

  public componentDidMount(): void {
    this.loadDatabases();
  }

  public componentDidUpdate(prevProps: Props): void {
    this.loadDatabases(prevProps.companyId);
  }

  public render(): JSX.Element {
    const { loadStatus, loadCompaniesStatus, databases, history, ability } = this.props;

    const displayedDatabases = ability.can(Operation.Buy, Subject.KreoProvidedDb)
      ? databases
      : databases.filter(d => !d.version || d.vendor);
    const isLoadingProcess =
      loadStatus === RequestStatus.Loading || loadCompaniesStatus === RequestStatus.Loading;

    const subHeaderButtonRelatedProps = ability.can(Operation.Create, Subject.Database)
      ? {
        buttonIcon: <KreoIconCreateNew />,
        buttonText: 'Create Database',
        onButtonClick: this.openCreateDatabaseDialog,
      }
      : {};
    return (
      <MainLayout
        backUrl={AppUrls.qto2d.index.url()}
        faqCaption={FaqCaption.Databases}
        getMenuItems={getRootMenuItems}
        subHeader={<SubHeader title='Databases' {...subHeaderButtonRelatedProps} />}
        metaTitle='Databases'
      >
        <DatabaseDialog onSubmit={this.onSubmit} />
        <RemoveDatabaseConfirmationDialog
          onYes={this.deleteDatabase}
        />
        <div className='content-wrapper'>
          <div className='databases'>
            {displayedDatabases && displayedDatabases.length > 0 ? (
              <div>
                <div className='databases__header'>
                  <div className='databases__name'>Name</div>
                  <div className='databases__date'>Created</div>
                  <div className='databases__date'>Last Modified</div>
                </div>
                <div className='databases__body'>
                  {displayedDatabases.map(database => (
                    <DatabaseItem
                      key={database.id}
                      database={database}
                      history={history}
                      selectDatabase={this.props.selectDatabase}
                      duplicate={this.duplicate}
                      delete={this.openDeleteDatabaseDialog}
                      setDefault={this.props.setDefault}
                    />
                  ))}
                </div>
              </div>
            ) : !isLoadingProcess ? (
              <div className='databases__empty' onClick={this.openCreateDatabaseDialog}>
                <KreoIconDbCreate />
                <div className='databases__empty-text'>Create your first database!</div>
              </div>
            ) : (
              <SvgSpinner size='large' />
            )}
          </div>
        </div>
      </MainLayout>
    );
  }

  private loadDatabases(prevCompanyId?: number): void {
    if (this.props.companyId && (this.props.companyId !== prevCompanyId || !this.firstLoadFlag)) {
      this.firstLoadFlag = true;
      this.props.loadDatabases(this.props.companyId);
    }
  }

  @autobind
  private duplicate(id: number): void {
    const databaseToDuplicate = this.props.databases.find(x => x.id === id);
    if (!databaseToDuplicate) {
      return;
    }

    this.props.createDatabase({
      isReadOnly: false,
      name: `Copy of ${databaseToDuplicate.name}`,
      version: databaseToDuplicate.version,
      importDatabaseIds: [id],
    });
  }

  @autobind
  private onSubmit(data: CreateDatabaseData): void {
    this.props.createDatabase({
      isReadOnly: false,
      name: data.name,
      version: '',
      importDatabaseIds: data.ids,
    });
    if (data.ids) {
      console.warn('try to create new database by import all data from databases');
    }
    this.props.closeDialog(CREATE_DATABASE_DIALOG_NAME);
  }

  @autobind
  private openCreateDatabaseDialog(): void {
    this.props.showDialog(CREATE_DATABASE_DIALOG_NAME);
  }

  @autobind
  private openDeleteDatabaseDialog(database: DatabaseModel): void {
    this.props.showDialog(REMOVE_DATABASE_CONFIRMATION_DIALOG, database);
  }

  @autobind
  private deleteDatabase(dialogData: DatabaseModel): void {
    if (dialogData && dialogData.id) {
      this.props.delete(dialogData.id);
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    databases: state.database.databases,
    loadStatus: state.database.statuses.databases,
    loadCompaniesStatus: state.account.statuses.fetchCompanies,
    companyId: state.account.selectedCompany ? state.account.selectedCompany.id : null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadDatabases: companyId => dispatch(DatabaseActions.loadDatabases(companyId)),
    selectDatabase: database => dispatch(DatabaseActions.setCurrentDatabase(database)),
    createDatabase: model => dispatch(DatabaseActions.createDatabase(model)),
    delete: id => dispatch(DatabaseActions.deleteDatabase(id)),
    setDefault: id => dispatch(DatabaseActions.setDefaultDatabase(id)),
    showDialog: (dialogName, data) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    closeDialog: dialogName => dispatch(KreoDialogActions.closeDialog(dialogName)),
  };
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export const DatabasePage = withAbilityContext(connector(DatabasePageComponent));
