import autobind from 'autobind-decorator';

import { arrayUtils } from 'common/utils/array-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import {
  ContextObserver,
  ContextObserverWithPrevious,
} from '../../../drawings-contexts';
import { DrawingsPointInfo, ShortPointDescription } from '../../../interfaces/drawing-ai-annotation';
import { DrawingsRenderParams } from '../../../interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from '../../../interfaces/drawing-text-render-parameters';
import { DrawingsPolygonGeometry } from '../../../interfaces/drawings-geometry';
import {
  DrawingsGeometryInstance,
  DrawingsGeometryInstanceWithId,
} from '../../../interfaces/drawings-geometry-instance';
import { DrawingsInstanceMeasure } from '../../../interfaces/drawings-instance-measure';
import { DrawingsShortInfo } from '../../../interfaces/drawings-short-drawing-info';
import { DrawingAnnotationNamingUtils } from '../../../utils/drawing-annotation-naming-utils';
import { DrawingsGeometryUtils } from '../../../utils/drawings-geometry-utils';
import { DrawingsGeometryOrthoLine } from '../../drawings-geometry-entities/utility';
import {
  AddInstancesWithUndoRedoCallback,
  EditPermissions,
  GetInstanceMeasureCallback,
  SendMeasuresUpdateCallback,
} from '../../interfaces';
import { DrawingsEngineOrientation } from '../../interfaces/orientation';
import { DrawingsGeometryEntityHelper } from '../drawings-geometry-entity-helper';
import { DrawingsRenderedPointsCache } from '../drawings-rendered-points-cache';
import { DrawingsGeometrySnappingHelper } from '../snapping/drawings-geometry-snapping-helper';
import { DrawingsCursorTypeHelper } from '../visual-helpers';
import {
  DrawingsDragCallbackParams,
  DrawingsDragProcessingHelper,
} from './drawings-drag-processing-helper';

interface DrawingsDragInstancesHelperConfig {
  keepOriginName: boolean;
  editPermissionsObserver: ContextObserver<EditPermissions>;
  entityRenderHelper: DrawingsGeometryEntityHelper;
  dragHelper: DrawingsDragProcessingHelper;
  cachedPoints: DrawingsRenderedPointsCache;
  cursorHelper: DrawingsCursorTypeHelper;
  textRenderParamsObserver:  ContextObserver<MeasuresViewSettings>;
  geometryRenderParamsObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  snappingHelper: DrawingsGeometrySnappingHelper;
  getCurrentPageInfo: () => Core.Document.PageInfo;
  sendMeasuresUpdate: SendMeasuresUpdateCallback;
  changeSelection: (ids: string[]) => void;
  getInstanceMeasures: GetInstanceMeasureCallback;
  getPointInfo: (pointId: string) => DrawingsPointInfo;
  getPoint: (point: ShortPointDescription) => paper.Point;
  getPointCoordinates: (pointId: string) => ShortPointDescription;
  getCurrentDrawing: () => DrawingsShortInfo;
  getDrawingInstance: (instanceId: string) => DrawingsGeometryInstance;
  getSegmentMeasureUpdate: (
    lineId: string,
    pointUpdated: Record<string, ShortPointDescription>,
    pointId: string,
    scale: number,
    metersPerPx: number,
  ) => DrawingsInstanceMeasure;
  addInstances: AddInstancesWithUndoRedoCallback;
  updatePointsPosition: (points: Record<string, ShortPointDescription>) => void;
  changePointsPosition: (
    updatedPoints: Record<string, ShortPointDescription>,
    instancesMeasures: DrawingsInstanceMeasure[],
  ) => void;
  updateInstanceMeasuresIterator: (instanceIds: string[]) => IterableIterator<DrawingsInstanceMeasure>;
}


export class DrawingsDragInstancesHelper {
  private _draggedInstances: string[];
  private _startDragPosition: paper.Point;
  private _lastDragPosition: paper.Point;
  private _isDuplicate: boolean;
  private _isOrtho: boolean;
  private _orthoLine: DrawingsGeometryOrthoLine;
  private _diff: paper.Point;
  private _orientation: DrawingsEngineOrientation;
  private _selectedRect: paper.Rectangle;
  private _moveByPoint: boolean;

  private _config: DrawingsDragInstancesHelperConfig;

  constructor(config: DrawingsDragInstancesHelperConfig) {
    this._config = config;
  }

  public set keepOriginName(value: boolean) {
    this._config.keepOriginName = value;
  }

  @autobind
  public startDragEntities(selectedInstances: string[], e: PaperMouseEvent, byPoint?: boolean): void {
    if (!this._config.editPermissionsObserver.getContext().canEditMeasure || !selectedInstances.length) {
      return;
    }
    this._selectedRect = this._config.entityRenderHelper.getEntityBounds(selectedInstances);
    if (!this._selectedRect) {
      return;
    }
    this._moveByPoint = byPoint;
    this._config.cursorHelper.grabbing = true;
    this._draggedInstances = selectedInstances;
    this._startDragPosition = e.point;
    this._diff = this._startDragPosition.subtract(this._selectedRect.center);
    if (e.event.altKey) {
      this._isDuplicate = true;
      for (const instanceId of selectedInstances) {
        this._config.entityRenderHelper.renderInstanceById(instanceId, `temp-${instanceId}`);
      }
    } else {
      this._isDuplicate = false;
    }

    if (e.event.shiftKey) {
      this._isOrtho = true;
      this._orthoLine = new DrawingsGeometryOrthoLine(
        {
          startPoint: this._selectedRect.center,
          geometryRenderParamsObserver: this._config.geometryRenderParamsObserver,
        },
      );
    } else {
      this._isOrtho = false;
    }
    this._config.dragHelper.setCallback(
      this.dragEntitiesCallback,
      {
        defaultValidPoint: e.point,
        withSnapping: byPoint,
      },
    );
  }

  @autobind
  private dragEntitiesCallback(params: DrawingsDragCallbackParams): boolean {
    const { point: currentPoint, finish } = params;
    let diff = currentPoint.subtract(this._startDragPosition);
    if (this._isOrtho) {
      const angle = Math.abs(diff.angle);
      if (!this._orientation) {
        this._orientation = 45 < angle && angle < 135
          ? DrawingsEngineOrientation.Vertical
          : DrawingsEngineOrientation.Horizontal;
      }
      if (this._orientation === DrawingsEngineOrientation.Horizontal) {
        currentPoint.y = this._startDragPosition.y;
      } else {
        currentPoint.x = this._startDragPosition.x;
      }
      this._orthoLine.changePointPosition(currentPoint.subtract(this._diff));
    }
    diff = currentPoint.subtract(this._lastDragPosition || this._startDragPosition);
    this._lastDragPosition = currentPoint;
    const pointUpdated: Record<string, ShortPointDescription> = {};
    const lineUpdatesMeasures: Record<string, DrawingsInstanceMeasure> = {};
    const measures = [];
    const selectedInstancesSet = new Set(this._draggedInstances);
    const instancesForUpdate = {};
    const getInstanceId = (id: string): string => this._isDuplicate ? `temp-${id}` : id;
    const { scale, metersPerPixel } = this._config.textRenderParamsObserver.getContext();
    let noIntersected = true;

    const updatePointById = (pointId: string): void => {
      if (!(pointId in pointUpdated)) {
        const point = this._config.cachedPoints.addToPoint(pointId, diff);
        pointUpdated[pointId] = [point.x, point.y];
        const pointInfo = this._config.getPointInfo(pointId);
        const trueInstanceId = getInstanceId(pointInfo.instanceId);
        if (!selectedInstancesSet.has(trueInstanceId)) {
          instancesForUpdate[trueInstanceId] = true;
        }
        for (const lineId of pointInfo.lines) {
          if (this._config.getInstanceMeasures(lineId)) {
            lineUpdatesMeasures[lineId] =
              this._config.getSegmentMeasureUpdate(lineId, pointUpdated, pointId, scale, metersPerPixel);
          }
        }
      }
    };


    for (const instanceId of selectedInstancesSet) {
      const { type, geometry } = this._config.getDrawingInstance(instanceId);
      const trueInstanceId = getInstanceId(instanceId);
      if (DrawingsGeometryUtils.isPolyGeometry(type, geometry)
        || (this._moveByPoint && DrawingsGeometryUtils.isCount(type, geometry))) {
        geometry.points.forEach(updatePointById);
        if (DrawingsGeometryUtils.isPolygon(type, geometry) && geometry.children) {
          for (const child of geometry.children) {
            child.forEach(updatePointById);
          }
        }
        if (this._config.entityRenderHelper.isInstanceRendered(trueInstanceId)) {
          this._config.entityRenderHelper.updateGeometry(trueInstanceId, geometry);
        }
      }
    }

    const isNewPositionValid = this.validatePositionUpdate(diff);

    if (!this._isDuplicate) {
      this._config.entityRenderHelper.updateSelectionBoundingRectPosition(diff);
      if (!isNewPositionValid) {
        return false;
      }
      for (const measure of this._config.updateInstanceMeasuresIterator(Object.keys(instancesForUpdate))) {
        if (!measure.measures) {
          noIntersected = false;
        } else {
          measures.push(measure);
        }
      }
    } else if (!isNewPositionValid) {
      return false;
    }

    if (!noIntersected) {
      return noIntersected;
    }
    if (finish) {
      this._config.entityRenderHelper.renderSelectionBoundingRectByIds(this._draggedInstances);
      this._config.cursorHelper.grabbing = false;
      this._lastDragPosition = null;
      this._orientation = null;
      if (this._isOrtho) {
        this._orthoLine.destroy();
      }
      if (this._isDuplicate) {
        this.duplicateAndMove(selectedInstancesSet, getInstanceId);
      } else {
        arrayUtils.extendArray(measures, Object.values(lineUpdatesMeasures));
        this._config.changePointsPosition(pointUpdated, measures);
      }
      this._draggedInstances = null;
    }
    return true;
  }

  private validatePositionUpdate(positionDiff: paper.Point): boolean {
    this._selectedRect.center = this._selectedRect.center.add(positionDiff);
    const pageInfo = this._config.getCurrentPageInfo();
    const { width, height }  = pageInfo;
    const { left, right, top, bottom } = this._selectedRect;
    return left > 0 && right < width && top > 0 && bottom < height;
  }

  private duplicateAndMove(selectedInstancesSet: Set<string>, getInstanceId: (id: string) => string): void {
    const geometriesForAdd = new Array<DrawingsGeometryInstanceWithId>();
    const newPoints = {};
    const oldPointsToNew = {};
    const idsMap = {};
    const processPoints = (instancePoints: string[]): string[] => {
      const points = new Array<string>(instancePoints.length);
      for (let i = 0; i < points.length; i++) {
        const point = instancePoints[i];
        if (oldPointsToNew[point]) {
          points[i] = oldPointsToNew[point];
        } else {
          const pointId = UuidUtil.generateUuid();
          newPoints[pointId] = this._config.cachedPoints.getConvertedPoint(point);
          this._config.cachedPoints.setPoint(pointId, this._config.cachedPoints.getPoint(point).clone());
          this._config.cachedPoints.setPoint(point, this._config.getPoint(this._config.getPointCoordinates(point)));
          points[i] = pointId;
          oldPointsToNew[point] = pointId;
        }
      }
      return points;
    };
    for (const instanceId of selectedInstancesSet) {
      const tempInstanceId = getInstanceId(instanceId);
      const instance = this._config.getDrawingInstance(instanceId);
      if (
        DrawingsGeometryUtils.isPolyGeometry(instance.type, instance.geometry)
        || (this._moveByPoint && DrawingsGeometryUtils.isCount(instance.type, instance.geometry))
      ) {
        const points = processPoints(instance.geometry.points);
        const geometry = { ...instance.geometry, points };
        if (DrawingsGeometryUtils.isPolygon(instance.type, instance.geometry) && instance.geometry.children) {
          (geometry as DrawingsPolygonGeometry).children = instance.geometry.children.map(processPoints);
        }
        const newId = UuidUtil.generateUuid();
        geometriesForAdd.push(
          {
            ...instance,
            geometry,
            id: newId,
            name: this._config.keepOriginName
              ? instance.name
              : DrawingAnnotationNamingUtils.getDuplicateGeometryName(instance.name),
          },
        );
        idsMap[newId] = instanceId;
      }
      this._config.entityRenderHelper.removeEntityById(tempInstanceId);
      this._config.changeSelection(this._draggedInstances);
    }
    this._config.addInstances({ instances: geometriesForAdd, points: newPoints, newIdsToSource: idsMap });
    this._config.dragHelper.setCallback(null);
  }
}
