import { push } from 'connected-react-router';
import dotProp from 'dot-prop-immutable';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionWith } from 'common/interfaces/action-with';
import { State } from 'common/interfaces/state';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { AppUrls } from 'routes/app-urls';
import {
  CalculateActivityAssignmentPayload,
  PatchActivityAssignmentPayload,
} from 'unit-cost-estimate/actions/payloads';
import { CEActivityAssignmentApi } from 'unit-cost-estimate/api/activity-assignment';
import {
  CEActivityAssignmentActivity,
  CEActivityAssignmentAggregatedData,
  CEActivityAssignmentDataTreeNode,
  CEActivityAssignmentResponse,
  CEActivityAssignmentState,
  CEActivityAssignmentTreeNodeActivitySort,
  RevisionState,
  UpdateActivityAssignmentActivity,
} from 'unit-cost-estimate/interfaces';
import { CEActivityAssignmentUtils } from 'unit-cost-estimate/utils/ce-activity-assignment-utils';
import { ViewerApi } from 'unit-projects/api/viewer';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { RevitTreeFullInfo } from 'unit-projects/interfaces/revit-tree-full-info';
import { CEActivityAssignmentActions, RevisionsActions } from '../actions/creators';
import { CEActivityAssignmentActionTypes } from '../actions/types';


function* calculate({ payload }: ActionWith<CalculateActivityAssignmentPayload>): SagaIterator {
  try {
    const { revisionId, databaseIds, usePreviousLaunch } = payload;
    const revisionStates = yield selectWrapper(s => s.projects.revisionStates);
    const revision = {
      ...revisionStates[revisionId],
      activityAssignmentStatus: ViewModelStatus.Calculating,
    };
    yield put(RevisionsActions.setRevisionState(revisionId, revision));
    const responseRevisionState = yield call(
      CEActivityAssignmentApi.calculateActivityAssignment,
      databaseIds,
      usePreviousLaunch);
    yield put(RevisionsActions.setRevisionState(revisionId, responseRevisionState));
  } catch (error) {
    console.error('CE Activity Assignment: Failed to calculate Activity Assignment', error);
  }
}

function* patch({ payload }: ActionWith<PatchActivityAssignmentPayload>): SagaIterator {
  try {
    const { databaseIds } = payload;
    const {
      selectedWork,
      revisionStates,
      projectId,
      revisionId,
    }: {
      selectedWork: CEActivityAssignmentActivity,
      revisionStates: Record<string, RevisionState>,
      projectId: number,
      revisionId: number,
    } = yield selectWrapper((_: State) => {
      const currentProjectId = _.projects.currentProject ? _.projects.currentProject.id : null;

      return {
        projectId: currentProjectId,
        selectedWork: _.ceActivityAssignment.selectedWork,
        revisionStates: _.projects.revisionStates,
        revisionId: _.persistedStorage.projectIdToRevisionId[currentProjectId],
      };
    });

    const revision = {
      ...revisionStates[revisionId],
      activityAssignmentStatus: ViewModelStatus.Calculating,
    };
    yield put(RevisionsActions.setRevisionState(revisionId, revision));

    const urlParams = {
      projectId: projectId.toString(),
      revisionId: revisionId.toString(),
    };
    yield put(push(AppUrls.costEstimate.project.revision.activityAssignment.index.url(urlParams)));

    const responseRevisionState = yield call(
      CEActivityAssignmentApi.patchActivityAssignment,
      selectedWork.id,
      databaseIds);
    yield put(RevisionsActions.setRevisionState(revisionId, responseRevisionState));
  } catch (error) {
    console.error('CE Activity Assignment: Failed to patch Activity Assignment', error);
  }
}


function* loadData(): SagaIterator {
  try {
    const data: CEActivityAssignmentResponse = yield call(CEActivityAssignmentApi.getData);
    const preparedData: CEActivityAssignmentAggregatedData =
      data.tree && data.tree.length > 0
        ? CEActivityAssignmentUtils.serverDataMapper(data)
        : {
          statistic: null,
          works: [],
          tree: [],
          workLinks: [],
          badIds: [],
          engineIds: [],
          databases: data.databaseIds,
        };
    yield put(CEActivityAssignmentActions.loadDataSucceeded(preparedData));
  } catch (error) {
    console.error('CE Activity Assignment: Failed to load AA data', error);
    yield put(CEActivityAssignmentActions.loadDataFailed());
  }
}

function* getWork(action: ActionWith<number>): SagaIterator {
  try {
    const work: CEActivityAssignmentActivity = yield call(CEActivityAssignmentApi.getWork, action.payload);
    yield put(CEActivityAssignmentActions.loadWorkSucceeded(work));
  } catch (error) {
    console.error('CE Activity Assignment: get work failed', error, action.payload);
  }
}

function* getRevitData(): SagaIterator {
  try {
    const bimFull: RevitTreeFullInfo = yield call(ViewerApi.loadFullBimInfo);
    const viewerData = CEActivityAssignmentUtils.getPropertyIdMapAndLayerIdMap(bimFull);
    yield put(CEActivityAssignmentActions.saveRevitData(viewerData));
  } catch (error) {
    console.error('CE Activity Assignment: get revit data failed', error);
  }
}

function* assignActivity(): SagaIterator {
  try {
    const activityAssignment: CEActivityAssignmentState = yield selectWrapper(
      state => state.ceActivityAssignment,
    );
    const variant = activityAssignment.selectedWorks[0] as CEActivityAssignmentDataTreeNode;
    let selectedWork: CEActivityAssignmentTreeNodeActivitySort = null;
    if (activityAssignment.selectedPath.split('.').length > 1) {
      const node = dotProp.get(activityAssignment, activityAssignment.selectedPath);
      selectedWork = activityAssignment.works.slice(node.start, node.end)[0];
    } else {
      selectedWork = activityAssignment.works[activityAssignment.selectedPath];
    }
    selectedWork.data = variant;
    yield put(CEActivityAssignmentActions.assignActivitySucceeded(selectedWork));
    yield call(CEActivityAssignmentApi.saveWork, { ...selectedWork.data, id: selectedWork.id });
  } catch (error) {
    console.error('activity assignment: assign activity failed', error);
  }
}

function* assignModalities(action: ActionWith<CEActivityAssignmentTreeNodeActivitySort>): SagaIterator {
  try {
    const activity = action.payload;
    const updateActivityModel: UpdateActivityAssignmentActivity  = {
      ...activity.data,
      id: activity.id,
    };
    yield call(CEActivityAssignmentApi.saveWork, updateActivityModel);
    yield put(CEActivityAssignmentActions.updateWork(activity));
    if (yield selectWrapper(_ => _.ceActivityAssignment.selectedPath)) {
      yield put(CEActivityAssignmentActions.assignActivity());
    }
  } catch (error) {
    console.error('CE Activity Assignment: assign modalities failed', error, action.payload);
  }
}

function* openDataBasePage(action: ActionWith<number>): SagaIterator {
  try {
    const {
      selectedWork,
      projectId,
      databaseId,
      revisionId,
    }: {
      selectedWork: CEActivityAssignmentActivity,
      projectId: number,
      databaseId: number,
      revisionId: number,
    } = yield selectWrapper((_: State) => {
      const currentProjectId = _.projects.currentProject ? _.projects.currentProject.id : null;

      return {
        selectedWork: _.ceActivityAssignment.selectedWork,
        projectId: currentProjectId,
        databaseId: _.ceActivityAssignment.databases[0],
        revisionId: _.persistedStorage.projectIdToRevisionId[currentProjectId],
      };
    });

    const activitiesPageUrl = AppUrls.costEstimate.project.revision.activityAssignment.database.activities.url({
      projectId: projectId.toString(),
      elementId: selectedWork.id.toString(),
      revisionId: revisionId.toString(),
      dbId: databaseId.toString(),
    });
    yield put(push(activitiesPageUrl));
  } catch (error) {
    console.error('activity assignment: open data base page failed', error, action.payload);
  }
}

function* approve(): SagaIterator {
  try {
    const revisionState = yield call(CEActivityAssignmentApi.approveActivityAssignment);
    yield put(RevisionsActions.setCurrentRevisionState(revisionState));
  } catch (error) {
    console.error('CE Activity Assignment: approve', error);
  }
}

function* disapprove(): SagaIterator {
  try {
    const revisionState = yield call(CEActivityAssignmentApi.disapproveActivityAssignment);
    yield put(RevisionsActions.setCurrentRevisionState(revisionState));
  } catch (error) {
    console.error('CE Activity Assignment: disapprove', error);
  }
}


export function* ceActivityAssignmentSagas(): SagaIterator {
  yield takeLatest(CEActivityAssignmentActionTypes.LOAD_DATA, loadData);
  yield takeLatest(CEActivityAssignmentActionTypes.LOAD_WORK, getWork);
  yield takeLatest(CEActivityAssignmentActionTypes.CALCULATE, calculate);
  yield takeLatest(CEActivityAssignmentActionTypes.PATCH, patch);
  yield takeLatest(CEActivityAssignmentActionTypes.GET_REVIT_DATA, getRevitData);
  yield takeLatest(CEActivityAssignmentActionTypes.ASSIGN_MODALITIES, assignModalities);
  yield takeLatest(CEActivityAssignmentActionTypes.ASSIGN_WORKS, assignActivity);
  yield takeLatest(CEActivityAssignmentActionTypes.OPEN_DATABASE_PAGE, openDataBasePage);
  yield takeLatest(CEActivityAssignmentActionTypes.APPROVE, approve);
  yield takeLatest(CEActivityAssignmentActionTypes.DISAPPROVE, disapprove);
}
