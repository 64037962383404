import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { DrawingsActions } from '../../../actions/creators/common';
import { DrawingsFailedMessageContainer } from '../failed-message-container';

interface StateProps {
  currentDrawingId: string;
}

interface DispatchProps {
  toggleOptimize: (fileId: string) => void;
}

interface Props  extends StateProps, DispatchProps{

}

export const FailedOptimizeMessageComponent: React.FC<Props> = ({ currentDrawingId, toggleOptimize }) => {
  const onTryRestartClick = useCallback(() => {
    toggleOptimize(currentDrawingId);
  }, [currentDrawingId]);
  return (
    <DrawingsFailedMessageContainer
      buttonText='Use source PDF'
      buttonWidth={140}
      onButtonClick={onTryRestartClick}
    >
      <span>
        It looks like we haven't been able to optimize your file
      </span>
    </DrawingsFailedMessageContainer>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    currentDrawingId: state.drawings.currentDrawingInfo?.drawingId,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    toggleOptimize: (fileId) => dispatch(DrawingsActions.toggleDrawingOptimize(fileId)),
  };
}

export const FailedOptimizeMessage = connect(mapStateToProps, mapDispatchToProps)(FailedOptimizeMessageComponent);
