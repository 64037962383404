import * as Ag from 'ag-grid-community';
import React from 'react';

type UseExtendsContextMenuCallBack = (deleteTemplate?: (id: string) => void) => (
  items: Array<Ag.MenuItemDef | string>,
  params: Ag.GetContextMenuItemsParams
) => Array<Ag.MenuItemDef | string>;

export const useExtendsContextMenuCallback: UseExtendsContextMenuCallBack = (deleteTemplate)  => {
  return React.useCallback((
    _,
    params,
  ) => {
    const result = [];
    if (deleteTemplate && params.node) {
      const itemMenuDelete: Ag.MenuItemDef = {
        name: 'Delete',
        action: () => deleteTemplate(params.node.data.id),
      };
      result.push(itemMenuDelete);
    }
    return result;
  }, [deleteTemplate]);
};
