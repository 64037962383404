import React, { useContext, useMemo } from 'react';
import { InheritType } from '../inherit-label';
import { OverrideContext } from './override-label';
import { Styled } from './styled';
import { Tooltip } from './tooltip';

interface Props {
  id: string;
  handleClick: () => void;
}

export const OverrideConsumer: React.FC<React.PropsWithChildren<Props>> = ({ children, id, handleClick }) => {
  const context = useContext(OverrideContext);
  const [hasOverride, type, parentId, entityName] = useMemo(() => {
    if (context && context.length) {
      const [property, item, assembly] = id.split('_');
      const override = context.find(c => c.assemblyName === assembly
        && c.itemName === item
        && c.propertyName === property);
      const inheritType: InheritType = assembly === ''
        ? InheritType.Item
        : InheritType.Assembly;
      const inheritId = override && override.inheritId;
      const name = inheritType === InheritType.Assembly
        ? assembly
        : item;
      return [!!override, inheritType, inheritId, name];
    }
    return [false];
  }, [context, id]);

  if (hasOverride) {
    return (
      <Styled.Container colored={hasOverride}>
        <Tooltip handleClick={handleClick} type={type} parentId={parentId} entityName={entityName} />
        {children}
      </Styled.Container>
    );
  }

  return <>{children}</>;
};
