import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './database-activity-category-navigation-tree.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { StringDictionary } from 'common/interfaces/dictionary';
import { State as ReduxState } from 'common/interfaces/state';
import {
  KreoScrollbars,
} from 'common/UIKit';
import { DatabaseActivityListingActions } from '../../actions/creators/database-activity-listing';
import { DatabaseCommonActions } from '../../actions/creators/database-common';
import { UNDEFINED_CATEGORY_CODE } from '../../constants/undefined-categoty-code';
import { ActivityCategoryType } from '../../enums';
import { ActivityCategory, ActivityCategoryCounts } from '../../interfaces/data';
import { Item } from './database-activity-category-navigation-tree-item';
import { NrmCategoryToggle } from './nrm-category-toggle';

interface ReduxProps {
  categories: StringDictionary<ActivityCategory[]>;
  categoriesCounts: StringDictionary<ActivityCategoryCounts>;
  selectedCategory: ActivityCategory;
  selectedCategoryType: ActivityCategoryType;
  categoriesCountsLoadStatuses: StringDictionary<RequestStatus>;
  categoriesLoadStatus: RequestStatus;
  defaultCategories: StringDictionary<ActivityCategory>;
}

interface ReduxActions {
  loadCategoryCounts: (databaseId: number, categoryType: ActivityCategoryType) => void;
  setSelectedCategoryType: (type: ActivityCategoryType) => void;
  setSelectedCategory: (category: ActivityCategory) => void;
}

interface Props extends ReduxProps, ReduxActions {
  databaseId: number;
}


class DatabaseActivityCategoryNavigationTreeComponent extends React.Component<Props> {
  private connectedItemSubcategoriesStyles: React.CSSProperties = {
    paddingLeft: '10px',
  };

  private readonly allActivitiesCategory: ActivityCategory = {
    title: 'All Activities',
    id: null,
    code: null,
  };

  public componentDidMount(): void {
    if (this.props.categoriesCountsLoadStatuses[this.props.selectedCategoryType] !== RequestStatus.Loaded) {
      this.props.loadCategoryCounts(this.props.databaseId, this.props.selectedCategoryType);
    }
    this.setDefaultSelectedCategory();
  }

  public componentDidUpdate(prevProps: Props): void {
    this.loadCategoriesCountIfNeeded(prevProps);
    this.updateDefaultSelectedCategoryIfNeeded(prevProps);
  }

  public render(): JSX.Element {
    const {
      categories,
      selectedCategoryType,
      selectedCategory,
      categoriesCounts,
    } = this.props;
    const categoriesToRender = categories[selectedCategoryType];
    const isLoaded = this.props.categoriesCountsLoadStatuses[selectedCategoryType] === RequestStatus.Loaded &&
      this.props.categoriesLoadStatus === RequestStatus.Loaded;
    const selectedCategoryCounts = categoriesCounts[selectedCategoryType];

    const undefinedCategory: ActivityCategory = {
      title: `Other ${selectedCategoryType === ActivityCategoryType.Nrm1 ? 'Elements' : 'Works'}`,
      id: 0,
      code: UNDEFINED_CATEGORY_CODE,
    };

    return (
      <div className='activities-left-menu-block'>
        <div className='activities-left-menu-block__header-select'>
          <NrmCategoryToggle />
        </div>
        <div className='categories-list'>
          <KreoScrollbars themeWhite={true}>
            {isLoaded ? (
              <div className='categories-list__inner'>
                <Item
                  category={this.allActivitiesCategory}
                  categoryCounts={selectedCategoryCounts}
                  level={0}
                  selectedCategory={selectedCategory}
                  selectCategory={this.selectCategory}
                />
                <Item
                  category={undefinedCategory}
                  categoryCounts={selectedCategoryCounts}
                  level={0}
                  selectedCategory={selectedCategory}
                  selectCategory={this.selectCategory}
                />
                {categoriesToRender.filter(x => x.id !== 0).map(category => (
                  <Item
                    key={category.id}
                    category={category}
                    categoryCounts={selectedCategoryCounts}
                    level={1}
                    parent={{}}
                    subcategoriesStyles={this.connectedItemSubcategoriesStyles}
                    selectedCategory={selectedCategory}
                    selectCategory={this.selectCategory}
                  />
                ))}
              </div>
            ) : (
              <SvgSpinner size='middle' />
            )}
          </KreoScrollbars>
        </div>
      </div>
    );
  }

  @autobind
  private selectCategory(category: ActivityCategory): void {
    this.props.setSelectedCategory(category);
  }

  private updateDefaultSelectedCategoryIfNeeded(prevProps: Props): void {
    const defaultCategoryChanged = !isEqual(
      prevProps.defaultCategories[prevProps.selectedCategoryType],
      this.props.defaultCategories[this.props.selectedCategoryType],
    );

    if (defaultCategoryChanged) {
      this.setDefaultSelectedCategory();
    }
  }

  private setDefaultSelectedCategory(): void {
    const defaultCategory = this.props.defaultCategories[this.props.selectedCategoryType];
    this.props.setSelectedCategory(defaultCategory ? defaultCategory : this.allActivitiesCategory);
  }

  private loadCategoriesCountIfNeeded(prevProps: Props): void {
    if (
      prevProps.databaseId !== this.props.databaseId ||
      prevProps.selectedCategoryType !== this.props.selectedCategoryType ||
      this.props.categoriesCountsLoadStatuses[this.props.selectedCategoryType] === RequestStatus.NotRequested
    ) {
      this.props.loadCategoryCounts(this.props.databaseId, this.props.selectedCategoryType);
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    categories: state.database.categories,
    categoriesCounts: state.database.currentDatabase.activityListing.categories.counts,
    categoriesCountsLoadStatuses: state.database.statuses.categoriesCounts,
    categoriesLoadStatus: state.database.statuses.categories,
    selectedCategory: state.database.currentDatabase.activityListing.categories.selectedCategory,
    selectedCategoryType: state.database.currentDatabase.activityListing.categories.selectedCategoryType,
    defaultCategories: state.database.currentDatabase.activityListing.defaultValues.categories,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadCategoryCounts: (databaseId, type) =>
      dispatch(DatabaseCommonActions.loadActivityCategoryCounts(databaseId, type)),
    setSelectedCategoryType: type => dispatch(DatabaseActivityListingActions.setSelectedCategoryType(type)),
    setSelectedCategory: category => dispatch(DatabaseActivityListingActions.setSelectedCategory(category)),
  };
};


const connector = connect(mapStateToProps, mapDispatchToProps);
export const DatabaseActivityCategoryNavigationTree = connector(DatabaseActivityCategoryNavigationTreeComponent);
