import { TwoDFullCellId } from '2d/units/2d-regex';
import { FormulaOperation } from 'common/enums/formula-operation';
import { ExcelExpressionEvaluator, functionNameRegex } from 'common/excel-expression-evaluator';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { RegexpUtil } from 'common/utils/regexp-util';
import { ReportPage } from '../../../../units/2d';
import {
  TwoDRegex,
  TwoDRegexGetter,
  TwoDRegexTypings,
} from '../../../../units/2d/units/2d-regex/2d-regex';
import { ExcelTableRowIdentification } from '../excel-table-row-identificator';
import { CellLinkRefType } from './absolute-link-utils';
import { FormulaInfoController } from './formula-info-controller';

enum ValueType {
  Formula,
  Cell,
}

enum VariableType {
  CellRef = 'CellRefVar',
  RangeRef = 'RangeRefVar',
  DrawingRef = 'DrawingRefVar',
  Const = 'Const',
}

type ExpressionParams = Record<VariableType, Record<string, string | number | any[]>>;

interface Value {
  type: ValueType;
  value: QuantityTakeOffParsedFormula | string;
}

interface QuantityTakeOffParsedFormula {
  firstValue: Value;
  secondValue: Value;
  operation: FormulaOperation;
}

interface FunctionParams {
  formulaWithParams: string;
  params: ExpressionParams;
}

const doubleQuote = `''`;
export const nonBreakingSpace = String.fromCharCode(160);
const workingSymbolsFormula = new RegExp(`(^=)|(${nonBreakingSpace})`, 'g');

const INVALID_REF = 'Invalid Ref';
const INVALID_VALUE = 'INVALID';
const BROKEN_LINK = '!REF';

export const MAX_ROWS_COUNT = 524288;
export const MAX_COLUMNS_COUNT = 8192;

const correctValue = (value: string): string => value.replace(workingSymbolsFormula, '');

function getCellWithSheetName(sheetName: string, cellId: string): string {
  return `'${sheetName}'!${cellId}`;
}

function getCellWithSheetId(sheetId: string, cellId: string): string {
  return `${sheetId}!${cellId}`;
}

function replaceBinaryOpts(value: string): string {
  // temporary while https://github.com/silentmatt/expr-eval/issues/253
  return value
    .replace(/(\|\|)|(==)(!=)/g, '#') // invalid symbol
    .replace(/=/g, '==')
    .replace(/&/g, '||')
    .replace(/<>/g, '!=')
    .replace(/<==/g, '<=')
    .replace(/>==/g, '>=');
}

function replaceFunctionNameToLowerCase(value: string): string {
  return value.replace(functionNameRegex, (f) => f.toLowerCase());
}

function getCellRangeInfo(cellRange: string): {
  sheetId: string,
  firstColIndex: number,
  lastColIndex: number,
  firstRowIndex: number,
  lastRowIndex: number,
} {
  const { startId, endId } = TwoDRegexGetter.getCellRangeField(cellRange);
  const startCell = TwoDRegex.fullCellId.exec(startId).groups;
  const sheetId = startCell.sheetId;

  const endCell = TwoDRegex.fullCellId.exec(endId).groups;

  const startColIndex = ExcelColumnHelper.excelColNameToIndex(startCell.columnId);
  const endColIndex = ExcelColumnHelper.excelColNameToIndex(endCell.columnId);

  const startRowIndex = ExcelTableRowIdentification.getRowIndexFromId(startCell.rowId);
  const endRowIndex = ExcelTableRowIdentification.getRowIndexFromId(endCell.rowId);

  const firstColIndex = Math.min(startColIndex, endColIndex);
  const lastColIndex = Math.max(startColIndex, endColIndex);

  const firstRowIndex = Math.min(startRowIndex, endRowIndex);
  const lastRowIndex = Math.max(startRowIndex, endRowIndex);

  return { sheetId, firstColIndex, lastColIndex, firstRowIndex, lastRowIndex };
}

function calculateVariableValues(
  params: ExpressionParams,
  getDrawingValue: (drawingFunction: string) => string | number,
): void {
  Object.entries(params[VariableType.DrawingRef]).forEach(([key, drawingRef]) => {
    params[VariableType.DrawingRef][key] = getDrawingValue(drawingRef as string);
  });
}

// temp while https://github.com/silentmatt/expr-eval/issues/257
function addConstantsToVariableValues(params: ExpressionParams, variables: string[]): void {
  variables.forEach(variable => {
    const key = variable.toLowerCase();
    if (key in ExcelExpressionEvaluator.consts) {
      params[variable] = ExcelExpressionEvaluator.consts[key];
    }
  });
}

function calculateFormula(
  formula: string,
  startCellKeys: Set<string>,
  getDrawingValue: (drawingFunction: string) => string | number,
  fic: FormulaInfoController,
): string | number {
  const hasLoopTest = fic.hasLoop(formula, startCellKeys);
  if (hasLoopTest) {
    return INVALID_VALUE;
  }

  const optimizeFormula = fic.optimize(formula);

  return getFormulaValue(optimizeFormula, getDrawingValue);
}

function getCellListFromCellRange(range: string): string[] {
  const cellList = [];

  const rangeField = TwoDRegexGetter.getCellRangeField(range);

  const startCellField = TwoDRegexGetter.getFullCellField(rangeField.startId);
  const endCellField = TwoDRegexGetter.getFullCellField(rangeField.endId);
  const [[minColumn, minRow], [maxColumn, maxRow]] = getMinMaxColumnRowIndex(startCellField, endCellField);
  for (let c = minColumn; c <= maxColumn; c++) {
    for (let r = minRow; r <= maxRow; r++) {
      cellList.push(getCellLink(
        startCellField.sheetId,
        ExcelColumnHelper.indexToExcelColName(c),
        ExcelTableRowIdentification.getRowIdFromIndex(r),
      ));
    }
  }

  return cellList;
}

function getMinMaxColumnRowIndex(
  startCellField: TwoDFullCellId,
  endCellField: TwoDFullCellId,
): [[number, number], [number, number]] {
  const startCellColumnIndex = ExcelColumnHelper.excelColNameToIndex(startCellField.columnId);
  const startCellRowIndex = ExcelTableRowIdentification.getRowIndexFromId(startCellField.rowId);
  const endCellColumnIndex = ExcelColumnHelper.excelColNameToIndex(endCellField.columnId);
  const endCellRowIndex = ExcelTableRowIdentification.getRowIndexFromId(endCellField.rowId);

  const minColumn = Math.min(startCellColumnIndex, endCellColumnIndex);
  const minRow = Math.min(startCellRowIndex, endCellRowIndex);
  const maxColumn = Math.max(startCellColumnIndex, endCellColumnIndex);
  const maxRow = Math.max(startCellRowIndex, endCellRowIndex);

  return [[minColumn, minRow], [maxColumn, maxRow]];
}

function getFormulaValue(
  formula: string,
  getDrawingValue: (drawingFunction: string) => string | number,
): string | number {
  try {
    const { formulaWithParams, params } = replaceParams(formula);
    const expression = ExcelExpressionEvaluator.parse(formulaWithParams);
    calculateVariableValues(params, getDrawingValue);
    addConstantsToVariableValues(params, expression.variables());
    const result = expression.evaluate(params as any);

    if (!isResultEvaluateValid(result)) {
      return formula;
    }

    return result;
  } catch (error) {
    return INVALID_VALUE;
  }
}

function isResultEvaluateValid(result: boolean | string | number | object): boolean {
  return typeof result !== 'function';
}

function replaceDrawingParams(formula: string, params: ExpressionParams): string {
  let count = 1;

  return formula.replace(TwoDRegex.drawingInstanceGlobal, (functionString) => {
    const index = ExcelColumnHelper.indexToExcelColName(count++);
    params[VariableType.DrawingRef][index] = functionString;
    return `${VariableType.DrawingRef}.${index}`;
  });
}

function replaceStringParams(formula: string, stringParams: ExpressionParams): string {
  let count = 1;

  return formula.replace(
    /('(?<first>[^']*)')|("(?<second>[^"]*)")/g,
    (...params) => {
      const groups = params[params.length - 1];
      const replaceValue = groups.first || groups.second || '';
      const index = ExcelColumnHelper.indexToExcelColName(count++);

      stringParams[VariableType.Const][index] = replaceValue;
      return `${VariableType.Const}.${index}`;
    });
}

function getDefaultParams(): ExpressionParams {
  return {
    [VariableType.CellRef]: {},
    [VariableType.Const]: {},
    [VariableType.RangeRef]: {},
    [VariableType.DrawingRef]: {},
  };
}

function replaceParams(formula: string): FunctionParams {
  const params: ExpressionParams = getDefaultParams();
  let formulaWithParams = correctValue(fixEmptyArgs(formula));
  formulaWithParams = replaceDrawingParams(formulaWithParams, params);
  formulaWithParams = replaceStringParams(formulaWithParams, params);
  formulaWithParams = replaceBinaryOpts(formulaWithParams);
  formulaWithParams = replaceFunctionNameToLowerCase(formulaWithParams);

  return { formulaWithParams, params };
}

function isFormula(formula: string): boolean {
  return formula && typeof formula === 'string' && formula.startsWith('=');
}

function isOpenFunction(value: string): boolean {
  return TwoDRegex.openFormulaFunction.test(value);
}

function fixEmptyArgs(str: string): string {
  const args = str.split(',');
  const fixedArgs = args.map((arg) => /^\)/.test(arg) && arg.replace(')', '"")') || arg.trim() || '""');
  return fixedArgs.join(',');
}

function handelInsertRange(oldValue: string, insertValue: string): string {
  if (TwoDRegex.cellRangeInEnd.test(oldValue)) {
    return oldValue.replace(
      TwoDRegex.cellRangeInEnd,
      (match) => {
        const firstPart = match.split(':')[0];
        return `${firstPart}${insertValue}`;
      });
  }

  return `${oldValue}${insertValue}`;
}

function getValueToInsert(oldValue: string, insertValue: string): string {
  const normalizedOldValue = oldValue.trim();
  if (!normalizedOldValue || !normalizedOldValue.length) {
    return `=${insertValue}`;
  }

  if (insertValue.startsWith(':')) {
    return handelInsertRange(oldValue, insertValue);
  }

  if (TwoDRegex.formulaSeparators.test(normalizedOldValue)) {
    return `${oldValue}${insertValue}`;
  }

  if (isOpenFunction(normalizedOldValue)) {
    return `${oldValue},${insertValue}`;
  }

  return `${oldValue}+${insertValue}`;
}

function getCellIdToInsert(value: string, selectedSheetId: string): string {
  const match = TwoDRegex.fullCellId.exec(value);
  const { sheetId, cellId } = match.groups;
  return sheetId === selectedSheetId ? cellId : value;
}

function replaceSheetIdToSheetName(value: string, reportPages: ReportPage[]): string {
  const sheetIds = reportPages.map(s => s.id);
  const sheetIdsRegex = new RegExp(sheetIds.join('|'), 'g');
  return value.replace(sheetIdsRegex, (id) => {
    const sheet = reportPages.find(s => s.id === id);
    return sheet ? `'${sheet.name}'` : id;
  });
}

function replaceSheetNameToSheetId(value: string, reportPages: ReportPage[]): string {
  const sheetNames = reportPages.map(s => `'${RegexpUtil.escapeRegExp(s.name)}'`);
  const sheetNameRegex = new RegExp(sheetNames.join('|'), 'g');
  return value.replace(sheetNameRegex, (sheetName) => {
    const sheet = reportPages.find(s => `'${s.name}'` === sheetName);
    return sheet ? sheet.id : sheetName;
  });
}

function extendCellIdBySheetId(formula: string, currentSheetId: string): string {
  return formula.replace(TwoDRegex.formulaPartGlobal, (...args) => {
    const groups = args[args.length - 1];
    if (groups.drawingInstance) {
      return groups.drawingInstance;
    }

    if (groups.stringValue) {
      return groups.stringValue;
    }

    if (groups.cellRange) {
      const startId = extendCellIdBySheetId(groups.startId, currentSheetId);
      return `${startId}:${groups.endId}`;
    }

    if (groups.sheetId || groups.sheetName) {
      return groups.fullCellId;
    }

    return groups.sheetId
      ? groups.fullCellId
      : getCellWithSheetId(currentSheetId, groups.cellId);
  });
}

function extendCellIdBySheetName(formula: string, currentSheetId: string, reportPages: ReportPage[]): string {
  return formula.replace(TwoDRegex.formulaPartGlobal, (...args) => {
    const groups = args[args.length - 1];

    if (groups.drawingInstance) {
      return groups.drawingInstance;
    }

    if (groups.stringValue) {
      return groups.stringValue;
    }

    if (groups.cellRange) {
      const startId = extendCellIdBySheetName(groups.startId, currentSheetId, reportPages);
      return `${startId}:${groups.endId}`;
    }

    if (groups.sheetName || groups.sheetId) {
      return groups.fullCellId;
    }

    const sheet = reportPages.find(x => x.id === currentSheetId);

    return getCellWithSheetName(sheet.name, groups.cellId);
  });
}

function narrowCellIdBySheetId(formula: string, currentSheetId: string): string {
  return formula.replace(TwoDRegex.formulaPartGlobal, (...args) => {
    const groups = args[args.length - 1];
    if (groups.drawingInstance) {
      return groups.drawingInstance;
    }

    if (groups.stringValue) {
      return groups.stringValue;
    }

    if (groups.cellRange) {
      const startId = narrowCellIdBySheetId(groups.startId, currentSheetId);
      return `${startId}:${groups.endId}`;
    }

    if (!groups.sheetId) {
      return groups.fullCellId;
    }

    return groups.sheetId === currentSheetId
      ? groups.cellId
      : groups.fullCellId;
  });
}

function getFormattingFormulaValue(value: string, sheetId: string, reportPages: ReportPage[]): string {
  return isFormula(value)
    ? replaceSheetIdToSheetName(
      narrowCellIdBySheetId(value, sheetId),
      reportPages,
    )
    : value;
}

const cellWithRefLink: Record<
  number,
  (
    columnId: string,
    rowId: number | string,
  ) => string> = {
    [CellLinkRefType.AbsoluteCell]: (columnId, rowId) => `$${columnId}$${rowId}`,
    [CellLinkRefType.AbsoluteColumn]: (columnId, rowId) => `$${columnId}${rowId}`,
    [CellLinkRefType.AbsoluteRow]: (columnId, rowId) => `${columnId}$${rowId}`,
    [CellLinkRefType.Relative]: (columnId, rowId) => `${columnId}${rowId}`,
  };

function getCellLink(
  sheetId: string,
  columnId: string,
  rowId: number | string,
  linkType: CellLinkRefType = CellLinkRefType.Relative,
): string {
  const cellId = cellWithRefLink[linkType](columnId, rowId);
  return sheetId
    ? getCellWithSheetId(sheetId, cellId)
    : cellId;
}


const isCellRefCorrect = (columnIndex: number, rowIndex: number): boolean => rowIndex > 0 && columnIndex > 0;

const getNewColumnInfo = (columnId: string, diff: number, absolute: string): {
  columnIndex: number,
  columnId: string,
} => {
  const newColumnIndex = ExcelColumnHelper.excelColNameToIndex(columnId) + diff;
  const newRowId = `${absolute}${ExcelColumnHelper.indexToExcelColName(newColumnIndex)}`;

  return { columnIndex: newColumnIndex, columnId: newRowId };
};

const getNewRowInfo = (rowId: string, diff: number, absolute: string): {
  rowIndex: number,
  rowId: string,
} => {
  const newRowIndex = Number(rowId) + diff;
  const newRowId = `${absolute}${newRowIndex}`;

  return { rowIndex: newRowIndex, rowId: newRowId };
};

const getIdInfo = (id: string, diff: number, absolute: string): { id: string, diff: number, absolute: string } => ({
  id,
  diff: absolute ? 0 : diff,
  absolute,
});

const getNewCellLink = (
  cellId: string,
  defaultSheetId: string,
  diffColumn: number,
  diffRow: number,
): string => {
  const fullCellId = TwoDRegexGetter.getFullCellField(cellId);
  const sheetId = fullCellId.sheetId ? fullCellId.sheetId : defaultSheetId;
  const column = getIdInfo(fullCellId.columnId, diffColumn, fullCellId.isColumnAbsolute);
  const row = getIdInfo(fullCellId.rowId, diffRow, fullCellId.isRowAbsolute);
  const { columnIndex, columnId } = getNewColumnInfo(column.id, column.diff, column.absolute);
  const { rowIndex, rowId } = getNewRowInfo(row.id, row.diff, row.absolute);
  return isCellRefCorrect(columnIndex, rowIndex)
    ? getCellLink(sheetId, columnId, rowId)
    : INVALID_REF;
};

function updateCellLinkByOffset(args: string[], diffColumn: number, diffRow: number, defaultSheetId: string): string {
  const groups = TwoDRegexTypings.typingFormulaPart(args);
  if (groups.stringValue) {
    return groups.stringValue;
  }

  if (groups.drawingInstance) {
    return groups.drawingInstance;
  }

  if (groups.fullCellId) {
    return getNewCellLink(groups.fullCellId, defaultSheetId, diffColumn, diffRow);
  }
  if (groups.cellRange) {
    const cellRange = TwoDRegexGetter.getCellRangeField(groups.cellRange);

    const newStartId = getNewCellLink(cellRange.startId, defaultSheetId, diffColumn, diffRow);
    const newEndId = getNewCellLink(cellRange.endId, undefined, diffColumn, diffRow);

    return `${newStartId}:${newEndId}`;
  }
}

function extendQuotInSheetName(value: string): string {
  return value.replace(/'/g, doubleQuote);
}

function getSubstringCount(value: string, substr: string): number {
  const substrings = value.match(new RegExp(RegexpUtil.escapeRegExp(substr), 'g'));
  return substrings ? substrings.length : 0;
}

function autoCloseParentheses(value: string): string {
  const openParenthesesCount = getSubstringCount(value, '(');
  const closeParenthesesCount = getSubstringCount(value, ')');
  const parenthesesDiff = openParenthesesCount - closeParenthesesCount;
  if (parenthesesDiff > 0) {
    return value + ')'.repeat(parenthesesDiff);
  }
  return value;
}

function splitByRow(cellIds: string[]): string[][] {
  const rows: Record<string, string[]> = {};
  cellIds.forEach(id => {
    const match = TwoDRegexGetter.getFullCellField(id);
    if (rows[match.rowId]) {
      rows[match.rowId].push(match.fullCellId);
    } else {
      rows[match.rowId] = [match.fullCellId];
    }
  });
  return Object.values(rows);
}

export const ExcelFormulaHelper = {
  replaceParams,
  calculateFormula,
  isFormula,
  getValueToInsert,
  getCellIdToInsert,
  replaceSheetIdToSheetName,
  replaceSheetNameToSheetId,
  extendCellIdBySheetId,
  extendCellIdBySheetName,
  narrowCellIdBySheetId,
  getFormattingFormulaValue,
  getCellLink,
  BROKEN_LINK,
  INVALID_REF,
  INVALID_VALUE,
  getCellWithSheetId,
  getCellWithSheetName,
  updateCellLinkByOffset,
  doubleQuote,
  extendQuotInSheetName,
  getFormulaValue,
  getCellRangeInfo,
  autoCloseParentheses,
  replaceBinaryOpts,
  getCellListFromCellRange,
  splitByRow,
};
