import { ProjectType } from 'common/constants/project-type';
import { RequestStatus } from 'common/enums/request-status';
import { BidPricingData } from 'common/interfaces/bid-pricing-data';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { Person } from '../people/interfaces/person';
import { ScenariosActionTypes } from '../scenarios/actions';
import { ScenariosActionsType } from '../scenarios/interfaces';
import {
  ChangeCompanyProjectsSearchQueryPayload,
  CompanyProjectHeadersFeedRequestPayload,
  CompanyProjectHeadersFeedResponsePayload,
  ClearProjectHeadersPayload,
  DemoProjectResponsePayload,
  ProjectSaveEngineFiltersState,
  RemoveProjectPayload,
  UpdateProjectNamePayload,
  UpdateProjectStatusPayload,
  UpdateViewModelSpecificInfo,
  UpdateViewModelStatusesPayload,
  UpdateViewModelStatusPayload,
  ChangeProjectAccessReasonPayload,
} from './actions/payloads/common';
import { BidPricingActionTypes } from './actions/types/bid-pricing';
import { ProjectsActionTypes } from './actions/types/common';
import { ProjectsFolderActionTypes } from './actions/types/projects-folder';
import { ValidationActionTypes } from './actions/types/validation';
import { KnownViewModel } from './enums/known-view-model';
import { ValidationStep } from './enums/validation-step';
import { ValidationStepState } from './enums/validation-step-state';
import { ViewModelStatus } from './enums/view-model-status';
import { PROJECT_INITIAL_STATE } from './initial-state';
import { CompanyProjectHeader } from './interfaces/company-project-header';
import { Project } from './interfaces/project';
import { ProjectReduxState } from './interfaces/project-redux-state';
import { ProjectValidationResultsPayload } from './interfaces/project-validation-results';
import { ProjectValidationState } from './interfaces/project-validation-state';
import { ProjectsFolder } from './interfaces/projects-folder';
import { ScenarioState } from './interfaces/scenario-state';
import { ShortProjectHeader } from './interfaces/short-project-header';
import { ViewModelStatusPair } from './interfaces/view-model-status-pair';
import { ProjectSelectors } from './selectors';

const saveProjectEngineState = (
  state: ProjectReduxState, payload: ProjectSaveEngineFiltersState,
): ProjectReduxState => {
  return new MonoliteHelper(state)
    .set(
      _ => _.projectsEngineState[payload.projectId],
      _ => _ ? { ..._, [payload.engineBasedPage]: payload.state } : { [payload.engineBasedPage]: payload.state },
    )
    .get();
};

export const projectReducerMethods: ReducerMethods<ProjectReduxState> = {
  [ProjectsActionTypes.REMOVE_PROJECT]: (state, projectId: number) => {
    return new MonoliteHelper(state)
      .set(_ => _.projectsToDelete[projectId], true)
      .get();
  },
  [ProjectsActionTypes.REMOVE_PROJECT_SUCCEEDED]: (
    state,
    { companyId, projectToRemoveId }: RemoveProjectPayload,
  ) => {
    const projectHeader = ProjectSelectors.findProjectHeader(state, projectToRemoveId);
    if (!projectHeader) {
      return state;
    }
    return new MonoliteHelper(state)
      .removeKey(_ => _.projectHeadersByCompanyId[companyId][projectHeader.type].data, projectToRemoveId)
      .removeKey(_ => _.projectsToDelete, projectToRemoveId)
      .get();
  },
  [ProjectsActionTypes.UPDATE_PROJECT_STATUS_SUCCEEDED]: (
    state,
    { projectId, status }: UpdateProjectStatusPayload) => {
    const projectHeader = ProjectSelectors.findProjectHeader(state, projectId);
    if (status && projectHeader) {
      const stateUpdater = new MonoliteHelper(state);
      stateUpdater.set(
        _ => _.projectHeadersByCompanyId[projectHeader.companyId][projectHeader.type].data[projectHeader.id].status,
        status,
      );
      if (state.currentProject && projectHeader.id === state.currentProject.id) {
        stateUpdater.set(_ => _.currentProject.status, status);
      }
      return stateUpdater.get();
    } else {
      return state;
    }
  },
  [ProjectsActionTypes.ADD_PROJECT]: (state, projectHeader: CompanyProjectHeader) => {
    const existingProjectHeader = ProjectSelectors.findProjectHeader(state, projectHeader.id);
    const companyInfo = state.projectHeadersByCompanyId[projectHeader.companyId];
    if (existingProjectHeader || !companyInfo || !companyInfo[projectHeader.type]) {
      return state;
    }
    const { companyId, type, id } = projectHeader;
    return new MonoliteHelper(state)
      .set(_ => _.projectHeadersByCompanyId[companyId][type].data[id], projectHeader)
      .get();
  },
  [ProjectsActionTypes.CREATE_PROJECT]: state => {
    return new MonoliteHelper(state).set(_ => _.status, RequestStatus.Loading).get();
  },
  [ProjectsActionTypes.CREATE_PROJECT_SUCCEEDED]: state => {
    return new MonoliteHelper(state).set(_ => _.status, RequestStatus.Loaded).get();
  },
  [ProjectsActionTypes.DUPLICATE_2D_PROJECT]: state => {
    return new MonoliteHelper(state).set(_ => _.status, RequestStatus.Loading).get();
  },
  [ProjectsActionTypes.DUPLICATE_2D_PROJECT_SUCCEEDED]: state => {
    return new MonoliteHelper(state).set(_ => _.status, RequestStatus.Loaded).get();
  },
  [ProjectsActionTypes.CREATE_PROJECT_FROM_DEMO_PROJECT]: state => {
    return new MonoliteHelper(state).set(_ => _.status, RequestStatus.Loading).get();
  },
  [ProjectsActionTypes.LOAD_CURRENT_PROJECT]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.status, RequestStatus.Loading)
      .get();
  },
  [ProjectsActionTypes.STORE_PROJECT_INFO]: (state, project: Project) => {
    const stateUpdater = new MonoliteHelper(state)
      .set(_ => _.currentProject, project);
    const existingProjectHeader = ProjectSelectors.findProjectHeader(state, project.id);
    if (!existingProjectHeader) {
      const companyProjectHeadersStore = state.projectHeadersByCompanyId[project.companyId];
      if (!companyProjectHeadersStore) {
        const data = { [project.type]: { data: {}, searchQuery: '', allFetched: false } };
        stateUpdater.set(
          _ => _.projectHeadersByCompanyId[project.companyId],
          data);
      } else if (!(project.type in companyProjectHeadersStore)) {
        stateUpdater.set(
          _ => _.projectHeadersByCompanyId[project.companyId][project.type],
          { data: {}, searchQuery: '', allFetched: false },
        );
      }

      const header: CompanyProjectHeader = {
        id: project.id,
        companyId: project.companyId,
        type: project.type,
        status: project.status,
        projectFailureNotified: project.projectFailureNotified,
        hasNoSourceFiles: project.hasNoSourceFiles,
        hasUnstartedProducts: project.hasUnstartedProducts,
        name: project.name,
        viewModelStatuses: [],
        validationState: project.validationState,
        users: project.people,
        isCompanyShared: project.isCompanyShared,
        ownerId: project.ownerId,
      };

      stateUpdater.set(_ => _.projectHeadersByCompanyId[project.companyId][project.type].data[project.id], header);
    }

    stateUpdater.set(_ => _.status, RequestStatus.Loaded);

    return stateUpdater.get();
  },
  [ProjectsActionTypes.GET_BID_PRICING_INFO_SUCCEEDED]: (state, payload: BidPricingData) => {
    return new MonoliteHelper(state).set(_ => _.bidPricing, payload).get();
  },
  [ProjectsActionTypes.UPDATE_PROJECT_NAME_SUCCEEDED]: (
    state,
    { id: projectId, name: newName }: UpdateProjectNamePayload,
  ) => {
    const helper = new MonoliteHelper(state);
    if (state.currentProject && state.currentProject.id === projectId) {
      helper.set(_ => _.currentProject.name, newName);
    }

    const projectHeader = ProjectSelectors.findProjectHeader(state, projectId);
    if (!projectHeader) {
      return helper.get();
    }

    const { companyId, type } = projectHeader;
    return helper
      .set(_ => _.projectHeadersByCompanyId[companyId][type].data[projectId].name, newName)
      .get();
  },
  [ProjectsActionTypes.RESET_PROJECT_HAS_UNSTARTED_PRODUCTS]: (state, projectId: number) => {
    if (!state.currentProject || state.currentProject.id !== projectId) {
      return state;
    }

    return new MonoliteHelper(state)
      .set(_ => _.currentProject.hasUnstartedProducts, false)
      .get();
  },
  [ProjectsActionTypes.UPDATE_VIEW_MODEL_STATUS]: (
    state,
    {
      projectId,
      viewModelType,
      viewModelStatus,
      startTime,
      estimatedDuration,
      specificInfo,
    }: UpdateViewModelStatusPayload,
  ) => {
    const stateUpdater = new MonoliteHelper(state);

    const projectHeader = ProjectSelectors.findProjectHeader(state, projectId);
    updateViewModel(
      projectHeader,
      viewModelType,
      stateUpdater,
      viewModelStatus,
      new Date(startTime).getTime(),
      estimatedDuration,
      specificInfo,
    );
    if (state.currentProject && state.currentProject.id === projectId) {
      stateUpdater.set(_ => _.currentProject.viewModelsStatuses[viewModelType], viewModelStatus);
      if (specificInfo) {
        stateUpdater.set(_ => _.currentProject, _ => ({ ..._, ...specificInfo }));
      }
    }
    return stateUpdater.get();
  },
  [ProjectsActionTypes.UPDATE_VIEW_MODEL_STATUSES]: (
    state,
    { projectId, statuses }: UpdateViewModelStatusesPayload,
  ) => {
    const stateUpdater = new MonoliteHelper(state);
    const projectHeader = ProjectSelectors.findProjectHeader(state, projectId);
    const projectIsCurrent = state.currentProject && state.currentProject.id === projectId;
    for (const { viewModelType, status, startTime, estimatedDuration } of statuses) {
      updateViewModel(projectHeader, viewModelType, stateUpdater, status, startTime, estimatedDuration, null);
      if (projectIsCurrent) {
        stateUpdater
          .set(_ => _.currentProject.viewModelsStatuses[viewModelType], status);
      }
    }
    return stateUpdater.get();
  },
  [ProjectsActionTypes.UPDATE_PROJECT_INVITED_USERS]: (state, payload: Person[]) => {
    return new MonoliteHelper(state).set(_ => _.projectInvitedUsers, payload).get();
  },
  [BidPricingActionTypes.SET_DIALOG_TYPE]: (state, payload: number) => {
    return new MonoliteHelper(state).set(_ => _.bidPricingDialogType, payload).get();
  },
  [ProjectsActionTypes.SET_NOT_FINISHED_SCENARIO_ID]: (state, payload: number) => {
    if (!state.currentProject) {
      return state;
    }

    return new MonoliteHelper(state)
      .set(_ => _.currentProject.notFinishedScenarioId, payload)
      .get();
  },
  [ValidationActionTypes.SET_PROJECT_VALIDATION_STATE]: (
    state,
    payload: ProjectValidationState,
  ) => {
    const stateUpdater = new MonoliteHelper(state);

    const projectHeader = ProjectSelectors.findProjectHeader(state, payload.documentId);
    if (projectHeader) {
      const { companyId, type, id: projectId } = projectHeader;
      stateUpdater
        .set(_ => _.projectHeadersByCompanyId[companyId][type].data[projectId].validationState, payload);
    }

    if (state.currentProject && state.currentProject.id === payload.documentId) {
      stateUpdater
        .set(_ => _.currentProject.validationState, payload)
        .set(
          _ => _.currentProject.isValidationCompleted,
          (
            payload.stepState === ValidationStepState.Approvable ||
            payload.validationStep === ValidationStep.Results && payload.stepState === ValidationStepState.Blocked
          ),
        );
    }

    return stateUpdater.get();
  },
  [ValidationActionTypes.GET_VALIDATION_RESULTS_SUCCESS]: (
    state,
    payload: ProjectValidationResultsPayload,
  ) => {
    if (!state.currentProject) {
      return state;
    }
    return new MonoliteHelper(state)
      .set(_ => _.currentProject.validationFinishResult, payload)
      .get();
  },
  [ScenariosActionTypes.REMOVE_SCENARIO_SUCCEEDED]: (state, scenarioId: number) => {
    return new MonoliteHelper(state)
      .setFilter(_ => _.currentProject.scenarios, x => x.id !== scenarioId)
      .get();
  },
  [ScenariosActionTypes.COPY_SCENARIO_SUCCEEDED]: (state, payload: ScenariosActionsType.CopyScenarioSucceeded) => {
    return new MonoliteHelper(state).setAppend(_ => _.currentProject.scenarios, payload.scenario).get();
  },
  [ProjectsActionTypes.DROP_CURRENT_PROJECT_INFO]: state => {
    const helper = new MonoliteHelper(state);
    const { projectHeadersByCompanyId, currentProject } = state;
    if (currentProject) {
      const { companyId, type, id } = currentProject;
      if (projectHeadersByCompanyId[companyId]
        && projectHeadersByCompanyId[companyId][type].data[id]) {
        const statuses = Object.entries(state.currentProject.viewModelsStatuses)
          .map(([viewModelType, status]) => ({ viewModelType, status }));
        helper.set(
          _ => _.projectHeadersByCompanyId[companyId][type].data[id].viewModelStatuses,
          statuses,
        );
      }
    }
    return helper
      .set(_ => _.currentProject, null)
      .get();
  },
  [ProjectsActionTypes.FETCH_SCENARIO_STATES]: state => {
    return new MonoliteHelper(state)
      .set(_ => _.scenarioStatesRequestStatus, RequestStatus.Loading)
      .get();
  },
  [ProjectsActionTypes.FETCH_SCENARIO_STATES_SUCCEEDED]: (state, payload: ScenarioState[]) => {
    const scenarioIdToState = {};
    payload.forEach(x => scenarioIdToState[x.scenarioGuid] = x);
    return new MonoliteHelper(state)
      .set(_ => _.scenarioStates, { ...state.scenarioStates, ...scenarioIdToState })
      .set(_ => _.scenarioStatesRequestStatus, RequestStatus.Loaded)
      .get();
  },
  [ProjectsActionTypes.APPEND_SCENARIO_STATES]: (state, payload: ScenarioState) => {
    return new MonoliteHelper(state)
      .set(_ => _.scenarioStates, { ...state.scenarioStates, [payload.scenarioGuid]: payload })
      .get();
  },
  [ProjectsActionTypes.FETCH_COMPANY_PROJECT_HEADERS_REQUEST]: (
    state,
    { companyId, type }: CompanyProjectHeadersFeedRequestPayload,
  ) => {
    const stateUpdater = new MonoliteHelper(state);
    const defaultData = { data: {}, searchQuery: '', allFetched: false };
    if (!(companyId in state.projectHeadersByCompanyId)) {
      const data = { [type]: defaultData };
      stateUpdater.set(
        _ => _.projectHeadersByCompanyId[companyId],
        data,
      );
    } else if (!(type in state.projectHeadersByCompanyId[companyId])) {
      stateUpdater.set(
        _ => _.projectHeadersByCompanyId[companyId][type],
        defaultData,
      );
    }

    stateUpdater.set(_ => _.requests.projectHeadersByCompanyId[companyId], RequestStatus.Loading);

    return stateUpdater.get();
  },
  [ProjectsActionTypes.FETCH_COMPANY_PROJECT_HEADERS_FAILED]: (
    state,
    companyId: number,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.requests.projectHeadersByCompanyId[companyId], RequestStatus.Failed)
      .get();
  },
  [ProjectsActionTypes.LOCALLY_UPDATE_PROJECT_HEADER]: (
    state, header: CompanyProjectHeader,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.projectHeadersByCompanyId[header.companyId][header.type].data[header.id], header)
      .get();
  },
  [ProjectsActionTypes.FETCH_COMPANY_PROJECT_HEADERS_SUCCEEDED]: (
    state,
    { companyId, feed, requestedCount, type }: CompanyProjectHeadersFeedResponsePayload,
  ) => {
    if (!state.projectHeadersByCompanyId[companyId] || !state.projectHeadersByCompanyId[companyId][type]) {
      return state;
    }
    return new MonoliteHelper(state)
      .set(_ => _.projectHeadersByCompanyId[companyId][type].data, {
        ...state.projectHeadersByCompanyId[companyId][type].data,
        ...feed.data.reduce(
          (map, projectHeader) => {
            map[projectHeader.id] = projectHeader;
            return map;
          },
          {}),
      })
      .set(_ => _.projectHeadersByCompanyId[companyId][type].allFetched, feed.data.length < requestedCount)
      .set(_ => _.requests.projectHeadersByCompanyId[companyId], RequestStatus.Loaded)
      .get();
  },
  [ProjectsActionTypes.FETCH_DEMO_PROJECTS_REQUEST]: (
    state,
    payload: [ProjectType, boolean],
  ) => {
    const [type] = payload;
    return new MonoliteHelper(state)
      .set(_ => _.demoProjects[type], [])
      .set(_ => _.requests.demoProjects[type], RequestStatus.Loading)
      .get();
  },
  [ProjectsActionTypes.FETCH_DEMO_PROJECTS_SUCCEEDED]: (
    state,
    { type, demoProjects }: DemoProjectResponsePayload,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.demoProjects[type], demoProjects)
      .set(_ => _.requests.demoProjects[type], RequestStatus.Loaded)
      .get();
  },
  [ProjectsActionTypes.SET_APPLY_DEMO_PROJECT_STATUS]: (state, status: RequestStatus) => {
    return new MonoliteHelper(state)
      .set(_ => _.requests.applyDemoProject, status)
      .get();
  },
  [ProjectsActionTypes.CLEAR_COMPANY_PROJECT_HEADERS]: (state, payload: ClearProjectHeadersPayload) => {
    const { companyId, type } = payload;
    if (!(companyId in state.projectHeadersByCompanyId)) {
      return state;
    }

    return new MonoliteHelper(state)
      .set(_ => _.projectHeadersByCompanyId[companyId][type].data, {})
      .set(_ => _.projectHeadersByCompanyId[companyId][type].allFetched, false)
      .get();
  },
  [ProjectsActionTypes.CHANGE_COMPANY_PROJECTS_SEARCH_QUERY]: (
    state,
    { companyId, searchQuery, type }: ChangeCompanyProjectsSearchQueryPayload,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.projectHeadersByCompanyId[companyId][type].searchQuery, searchQuery)
      .get();
  },
  [ProjectsActionTypes.FAILURE_PROJECT_SEND_SUCCEEDED]: (state, project: Project) => {
    return new MonoliteHelper(state)
      .set(
        _ => _.projectHeadersByCompanyId[project.companyId][project.type].data[project.id].projectFailureNotified,
        project.projectFailureNotified,
      )
      .get();
  },
  [ProjectsFolderActionTypes.GET_FOLDERS]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.foldersLoadingStatus, RequestStatus.Loading)
      .get();
  },
  [ProjectsFolderActionTypes.SET_FOLDERS]: (state, folders: ProjectsFolder[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.folders, folders)
      .setSort(
        _ => _.folders,
        (item1, item2) => item1.name > item2.name ? 1 : -1,
      )
      .set(_ => _.foldersLoadingStatus, RequestStatus.Loaded)
      .get();
  },
  [ProjectsFolderActionTypes.CREATE_FOLDER_SUCCESS]: (state, folder: ProjectsFolder) => {
    return new MonoliteHelper(state)
      .setAppend(_ => _.folders, folder)
      .setSort(
        _ => _.folders,
        (item1, item2) => item1.name > item2.name ? 1 : -1,
      )
      .get();
  },
  [ProjectsFolderActionTypes.REMOVE_FOLDER_SUCCESS]: (state, id: number) => {
    return new MonoliteHelper(state)
      .setFilter(_ => _.folders, _ => _.id !== id)
      .get();
  },
  [ProjectsFolderActionTypes.SET_REMOVING_FOLDER_INFO]: (state, users: string[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.removingFolderInfo, users)
      .get();
  },
  [ProjectsFolderActionTypes.UPDATE_FOLDER_SUCCESS]: (state, folder: ProjectsFolder) => {
    return new MonoliteHelper(state)
      .setMap(_ => _.folders, _ => _.id === folder.id ? folder : _)
      .setSort(
        _ => _.folders,
        (item1, item2) => item1.name > item2.name ? 1 : -1,
      )
      .get();
  },
  [ProjectsFolderActionTypes.SET_CURRENT_FOLDER]: (state, folder) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentFolder, folder)
      .get();
  },
  [ProjectsActionTypes.FETCH_ALL_TEMPLATES_SUCCEEDED]: (state, projectHeaders: ShortProjectHeader[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.templates, projectHeaders)
      .get();
  },
  [ProjectsActionTypes.GET_ENGINE_FILTER_STATE_SUCCEEDED]: saveProjectEngineState,
  [ProjectsActionTypes.SET_ENGINE_FILTER_STATE]: saveProjectEngineState,
  [ProjectsActionTypes.DROP_STORE]: (state) => {
    const demoProjects = state.demoProjects;
    return new MonoliteHelper(state)
      .set(_ => _, PROJECT_INITIAL_STATE)
      .set(_ => _.demoProjects, demoProjects)
      .get();
  },
  [ProjectsActionTypes.SET_PROJECT_NOT_FOUND]: (state, payload: boolean) => {
    return new MonoliteHelper(state)
      .set(_ => _.isProjectNotFound, payload)
      .get();
  },
  [ProjectsActionTypes.CHANGE_LOADING_STATUS]: state => {
    return new MonoliteHelper(state).set(_ => _.status, RequestStatus.Loaded).get();
  },
  [ProjectsActionTypes.CHANGE_PROJECT_ACCESS_REASON]: (
    state,
    { projectHeader, accessReason }: ChangeProjectAccessReasonPayload,
  ) => {
    const { companyId, type, id } = projectHeader;
    return new MonoliteHelper(state)
      .set(_ => _.projectHeadersByCompanyId[companyId][type].data[id].accessReason, accessReason)
      .get();
  },
};

function updateViewModel(
  projectHeader: CompanyProjectHeader,
  viewModelType: KnownViewModel,
  stateUpdater: MonoliteHelper<ProjectReduxState>,
  viewModelStatus: ViewModelStatus,
  startTime: number,
  estimatedDuration: number,
  specificInfo: UpdateViewModelSpecificInfo,
): void {
  if (projectHeader) {
    const { companyId, type, id, viewModelStatuses } = projectHeader;
    const predicate = (pair: ViewModelStatusPair): boolean => pair.viewModelType === viewModelType;
    if (specificInfo) {
      stateUpdater
        .set(_ => _.projectHeadersByCompanyId[companyId][type].data[id], _ => ({ ..._, ...specificInfo }));
    }
    if (viewModelStatuses.find(predicate)) {
      stateUpdater.setFind(
        _ => _.projectHeadersByCompanyId[companyId][type].data[id].viewModelStatuses,
        predicate,
        (_pair) => ({ viewModelType, status: viewModelStatus, startTime, estimatedDuration }));
    } else {
      stateUpdater.setAppend(
        _ => _.projectHeadersByCompanyId[companyId][type].data[id].viewModelStatuses,
        { viewModelType, status: viewModelStatus, startTime, estimatedDuration });
    }
  }
}
