import * as React from 'react';

import {
  KreoIconElementObject,
  KreoIconLayerFinish,
  KreoIconLayerInsulationMembrane,
  KreoIconLayerStructure,
} from 'common/UIKit';
import { ClassificationObjectType } from '../../enums/classification-object-type';


interface Props {
  type: ClassificationObjectType;
}

export class ClassificationOntologyTreeElementType extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    switch (this.props.type) {
      case ClassificationObjectType.Substrate:
      case ClassificationObjectType.Structure:
        return <KreoIconLayerStructure/>;
      case ClassificationObjectType.Object:
        return <KreoIconElementObject/>;
      case ClassificationObjectType.Finish:
        return <KreoIconLayerFinish/>;
      default:
        return <KreoIconLayerInsulationMembrane/>;
    }
  }
}
