import autobind from 'autobind-decorator';
import { ContextObserver } from './interfaces';

export class ContextObserverCallbackGroup<T> implements ContextObserver<T> {
  protected _observer: ContextObserver<T>;
  private _callbacks: Array<(context: T) => void> = [];

  constructor(observer: ContextObserver<T>) {
    this._observer = observer;
    this._observer.subscribe(this.listen);
  }

  public destroy(): void {
    this._observer.unsubscribe(this.listen);
    this._callbacks = [];
  }

  public clean(): void {
    this._callbacks = [];
  }

  public getContext(): T {
    return this._observer.getContext();
  }

  public updateContext(context: T): void {
    this._observer.updateContext(context);
  }

  public subscribe(observer: (context: T) => void, ignoreFirst?: boolean): void {
    this._callbacks.push(observer);
    if (!ignoreFirst) {
      observer(this._observer.getContext());
    }
  }

  public unsubscribe(observer: (context: T) => void): void {
    this._callbacks = this._callbacks.filter((callback) => callback !== observer);
  }

  @autobind
  private listen(context: T): void {
    this._callbacks.forEach((callback) => callback(context));
  }
}
