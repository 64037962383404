import autobind from 'autobind-decorator';
import { goBack } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Action, Dispatch } from 'redux';

import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit/dialogs/';
import { AppUrls } from 'routes/app-urls';
import { CostEstimateTable } from '../../../../components/cost-estimate-table';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { BidPricingActions } from '../../actions/creators/bid-pricing';
import { CostsActions } from '../../actions/creators/costs';
import { bidDescriptionDialogName } from '../../components/bid-pricing//bid-description-dialog';
import { CostPageLayout } from '../../components/bid-pricing//cost-page-layout';
import { CostEstimateGroupingType } from '../../enums/cost-estimate-grouping-type';
import { UserBidPricingStatus } from '../../enums/user-bid-pricing-status';
import { BidPricingUserCost } from '../../interfaces/bid-pricing/bid-pricing-user-cost';

interface ReduxProps {
  costs: any;
  constructionPackage: BidPricingUserCost;
}

interface ReduxActions {
  onAccept: () => void;
  dropCosts: () => void;
  openDescriptionDialog: () => void;
  updateBidDescription: (
    workPackageId: number,
    constructionCostId: number,
    description: string,
  ) => void;
}

interface Props extends ReduxProps, ReduxActions, RouteComponentProps<PlanProjectRouteParams> {}

class EditedCostPageComponent extends React.Component<Props> {
  public componentWillUnmount(): void {
    this.props.dropCosts();
  }

  public render(): React.ReactNode {
    const { constructionPackage } = this.props;
    return constructionPackage ? (
      <CostPageLayout
        projectId={this.props.match.params && this.props.match.params.projectId}
        caption={constructionPackage.name}
        onButtonClick={this.props.onAccept}
        navigate={this.navigate}
        isSubcontractor={true}
        status={constructionPackage.status}
        description={constructionPackage.description}
        onDescriptionClick={this.props.openDescriptionDialog}
        updateBidDescription={this.updateBidDescription}
      >
        <CostEstimateTable
          includeDuration={true}
          data={this.props.costs}
          edited={this.props.constructionPackage.status !== UserBidPricingStatus.Winner}
          groupingType={CostEstimateGroupingType.Workpackages}
        />
      </CostPageLayout>
    ) : null;
  }

  @autobind
  private navigate(): void {
    const { history, match } = this.props;
    history.push(AppUrls.plan.project.bidPricing.index.url({ projectId: match.params.projectId }));
  }

  @autobind
  private updateBidDescription(description: string): void {
    const { constructionPackage } = this.props;
    this.props.updateBidDescription(
      constructionPackage.packageId,
      constructionPackage.id,
      description,
    );
  }
}

function mapStateToProps(state: ReduxState): ReduxProps {
  return {
    costs: state.cost.get('costs').toJS(),
    constructionPackage: state.bidPricing.package,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): ReduxActions {
  return {
    onAccept: () => {
      dispatch(BidPricingActions.acceptPackage());
      dispatch(goBack());
    },
    dropCosts: () => {
      dispatch(CostsActions.dropCostData());
    },
    openDescriptionDialog: () => {
      dispatch(KreoDialogActions.openDialog(bidDescriptionDialogName));
    },
    updateBidDescription: (
      workPackageId: number,
      constructionCostId: number,
      description: string,
    ) => {
      dispatch(
        BidPricingActions.updateBidDescription(workPackageId, constructionCostId, description),
      );
    },
  };
}

export const EditedCostPage = connect(mapStateToProps, mapDispatchToProps)(EditedCostPageComponent);
