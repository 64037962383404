import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 20px;
  ${Text} {
    margin-top: 5px;
  }
`;

export const Styled = {
  Container,
};
