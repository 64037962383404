import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';
import { RevisionsActions } from 'unit-cost-estimate/actions/creators';
import { RevisionInfoModel } from 'unit-cost-estimate/interfaces';
import { RevisionItem } from './revision-item';
import { Styled } from './styled';

interface DispatchProps {
  removeRevision: (id: number) => void;
  duplicateRevision: (id: number, name: string) => void;
  updateRevision: (id: number, name: string) => void;
}

interface StateProps {
  revisionInfo: RevisionInfoModel[];
  selectedRevisionId: number;
  projectId: number;
}

interface Props extends StateProps, DispatchProps {}

export class RevisionSettingContentComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { selectedRevisionId, revisionInfo } = this.props;
    const canRemoveRevision = revisionInfo && revisionInfo.length > 1;
    if (!selectedRevisionId || !revisionInfo) {
      return null;
    }

    return (
      <Styled.Container>
        <Styled.Content>
        {
          revisionInfo.map(item => (
            <RevisionItem
              key={item.id}
              id={item.id}
              name={item.name}
              onDuplicate={this.duplicate}
              onNameChange={this.revisionNameChange}
              onRemove={canRemoveRevision ? this.removeRevision : null}
            />
          ))
        }
        </Styled.Content>
      </Styled.Container>
    );
  }

  @autobind
  private duplicate(id: number, name: string): void {
    this.props.duplicateRevision(id, name);
  }

  @autobind
  private removeRevision(id: number): void {
    this.props.removeRevision(id);
  }


  @autobind
  private revisionNameChange(id: number, name: string): void {
    this.props.updateRevision(id, name);
  }
}

const mapStateToProps = (state: State): StateProps => {
  const currentProject = state.projects.currentProject;
  const currentProjectId = currentProject && currentProject.id;
  const projectIdToRevisionId = state.persistedStorage.projectIdToRevisionId;
  const selectedRevisionId = projectIdToRevisionId && projectIdToRevisionId[currentProjectId];

  return {
    selectedRevisionId,
    projectId: currentProjectId,
    revisionInfo: state.projects.currentProject.revisions,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    removeRevision: id => dispatch(RevisionsActions.deleteRevision(id)),
    updateRevision: (id, form) => dispatch(RevisionsActions.updateRevision(id, form)),
    duplicateRevision: (id, name) => dispatch(RevisionsActions.duplicateRevision(id, name)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const RevisionSettingContent = connector(RevisionSettingContentComponent);
