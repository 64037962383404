import { DrawingMarkShapes, DrawingStrokeStyles } from '../constants/drawing-styles';

export interface DrawingsGeometryPointCoordinates {
  x: number;
  y: number;
}

export interface DrawingsLineCoordinates {
  start: DrawingsGeometryPointCoordinates;
  end: DrawingsGeometryPointCoordinates;
}

interface DrawingsGeometry {
  color: string;
}

export interface DrawingsCalibrationLineGeometry extends DrawingsGeometry {
  points: [string, string];
  pxLength: number;
  length: number;
  strokeWidth: number;
  strokeStyle: DrawingStrokeStyles;
}

export interface DrawingsGeometryParams {
  height?: number;
  offset?: number;
  thickness?: number;
}

export interface DrawingsBasePolyGeometryNew<T = string> extends DrawingsGeometry, DrawingsGeometryParams {
  points: T[];
  strokeWidth: number;
  strokeStyle: DrawingStrokeStyles;
}

export interface DrawingsPolylineGeometry extends DrawingsBasePolyGeometryNew<string> {
}

export interface DrawingsPolygonGeometry extends DrawingsBasePolyGeometryNew {
  children?: string[][];
}

export interface DrawingsCountGeometry extends DrawingsGeometry {
  points: string[];
  shape: DrawingMarkShapes;
}

export interface DrawingsPaperPolygonPath extends paper.CompoundPath {
  children: paper.Path[];
  firstChild: paper.Path;
  lastChild: paper.Path;
  segments?: paper.Segment[];
}

export type DrawingsAllowedPathType = DrawingsPaperPolygonPath | paper.Path;

export type DrawingsGeometryType =
  DrawingsCalibrationLineGeometry
  | DrawingsPolylineGeometry
  | DrawingsPolygonGeometry
  | DrawingsCountGeometry;

export type DrawingsGeometryStrokedType =
  DrawingsPolylineGeometry
  | DrawingsPolygonGeometry;
export interface DrawingsBounds {
  x: number;
  y: number;
  width: number;
  height: number;
  center?: DrawingsGeometryPointCoordinates;
}

export interface DrawingsCanvasRenderParams extends DrawingsBounds {
  left: number;
  top: number;
  drawingLayoutWidth: number;
  drawingLayoutHeight: number;
}

export interface DrawingsSizeParameters {
  width: number;
  height: number;
}

export enum DrawingsSelectAggregationGroup {
  Count = 'Count',
  Area = 'Area',
  Line = 'Line',
  Unknown = 'UNKNOWN',
}

export interface DrawingsAggregatedSelection {
  count: number;
  name: DrawingsSelectAggregationGroup;
}

export interface DrawingsSimplifiedBoundingRect {
  left: number;
  top: number;
  right: number;
  bottom: number;
}
