import styled from 'styled-components';

import { OptionContainerStyled } from 'common/components/selection-menus';


const HEADER_HEIGHT = 40;
const PADDING_TOP = 10;
const TAGS_PADDING_BOTTOM = 5;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.color.backgroundRush};
  height: 90vh;
  max-height: 780px;
  width: 90vw;
  max-width: 1240px;
  border: 1px solid ${p => p.theme.color.background};
  box-sizing: border-box;
  border-radius: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
  border-bottom: 1px solid ${p => p.theme.color.background};
  box-sizing: border-box;
  padding: 0 15px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 215px auto;
  grid-column-gap: 10px;
  padding-left: 15px;
  height: calc(100% - 15px);
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${p => p.theme.color.background};
  overflow-y: auto;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 5px;
  max-height: calc(100% - ${HEADER_HEIGHT}px - ${PADDING_TOP}px - ${TAGS_PADDING_BOTTOM}px);

  ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: ${p => p.theme.color.gray} !important;
  }
  ::-webkit-scrollbar-track {
    background: ${p => p.theme.color.background};
  }
  ::-webkit-scrollbar-corner {
    background-color: ${p => p.theme.color.background};
  }
`;

const DemosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  max-height: calc(100% - ${HEADER_HEIGHT}px - ${PADDING_TOP}px);
  overflow-y: auto;

  ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: ${p => p.theme.color.gray} !important;
  }
  ::-webkit-scrollbar-track {
    background: ${p => p.theme.color.background};
  }
  ::-webkit-scrollbar-corner {
    background-color: ${p => p.theme.color.background};
  }
`;

const DemoProjectsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-right: 5px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  ${OptionContainerStyled.Container} {
    border: 1px solid ${p => p.theme.color.background};
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 0;
    gap: 10px;

    > div {
      min-width: 20px;
    }
  }
`;

export const Styled = {
  Container,
  Header,
  ContentWrapper,
  TagsContainer,
  DemosContainer,
  DemoProjectsList,
  TagContainer,
};
