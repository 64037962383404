import { useCallback } from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { MeasureUtil } from 'common/utils/measure-util';

export function useFilterMeasure3d(): (type: string) => boolean {
  const canShowMeasure3d = useAbility(Operation.Read, Subject.Takeoff2dMeasurement3d);
  return useCallback((type: string) => {
    if (!canShowMeasure3d) {
      return !MeasureUtil.measure3dKey[type];
    }
    return true;
  }, [canShowMeasure3d]);
}
