import { Icons, Input } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { CustomDialog } from 'common/components/custom-dialog';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { Styled } from './styled';

export const CHANGE_EMAIL_DIALOG = 'CHANGE_EMAIL_DIALOG';

interface OwnProps {
  onConfirm: (email: string) => void;
  email: string;
}

interface DispatchProps {
  onCloseDialog: () => void;
  openChangeEmailDialog: () => void;
}

interface ComponentState {
  name: string;
}

interface Props extends OwnProps, DispatchProps {
}

class ChangeEmailDialogComponent extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: this.props.email,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    const { email } = this.props;
    if (email !== prevProps.email) {
      this.setState({ name: email });
    }
  }

  public render(): React.ReactNode {
    return (
      <DialogWrapper name={CHANGE_EMAIL_DIALOG}>
        <CustomDialog
          mainText={'Change user email'}
          submitText={'Change'}
          onSubmit={this.onSubmit}
          onReject={this.onClose}
        >
          <Styled.InputWrapper>
            <Input
              width={'280px'}
              type="text"
              text={'Email'}
              input={{
                value: this.state.name,
                onChange: this.onChangeName,
              }}
              icon={Icons.Email}
            />
          </Styled.InputWrapper>
        </CustomDialog>
      </DialogWrapper>
    );
  }

  @autobind
  private onSubmit(): void {
    this.props.onConfirm(this.state.name);
    this.props.onCloseDialog();
  }

  @autobind
  private onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ name: event.target.value });
  }

  @autobind
  private onClose(): void {
    this.props.onCloseDialog();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    onCloseDialog: () => dispatch(KreoDialogActions.closeDialog(CHANGE_EMAIL_DIALOG)),
    openChangeEmailDialog: () => dispatch(KreoDialogActions.openDialog(CHANGE_EMAIL_DIALOG)),
  };
};

export const ChangeEmailDialog = connect(null, mapDispatchToProps)(ChangeEmailDialogComponent);
