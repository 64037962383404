import { CodecUtils } from 'common/utils/codec-utils';

export enum NotificationRelatedArea {
  Management = 'Management',
  Scenarios = 'Scenarios',
  Database = 'Database',
  Comment = 'Comment',
  Validation = 'Validation',
  BidPricing = 'BidPricing',
}

export const NotificationRelatedAreaC = CodecUtils
  .createEnumCodec<NotificationRelatedArea>(NotificationRelatedArea, 'NotificationRelatedArea');
