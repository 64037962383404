import styled from 'styled-components';

export const QuickSearchHeight = 40;

const Container = styled.div`
  width: 100%;
  height: ${QuickSearchHeight}px;
  position: relative;
`;

const SearchIcon = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 15px;
  display: inline-block;
  position: absolute;
  top: 10px;
  svg {
    fill: ${p => p.theme.color.gray};
  };
`;

const Input = styled.input`
  padding-left: 50px;
  width: 100%;
  height: ${QuickSearchHeight}px;
  border: 1px solid transparent;
  color: ${props => props.theme.color.mainFont};
  background-color: ${props => props.theme.color.backgroundRush};
  box-sizing: border-box;
  text-overflow: ellipsis;
  :focus{
    outline: none;
  }
  ::placeholder {
    color: ${props => props.theme.color.disabled};
  }
`;

const Cross = styled.span`
  position: absolute;
  top: 0;
  right: 20px;
  width: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    svg {
      fill: ${p => p.theme.color.red};
    }
  }
`;

export const Styled = {
  Input,
  Container,
  SearchIcon,
  Cross,
};
