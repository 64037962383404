import { CancelTokenSource } from 'axios';
import * as t from 'io-ts';

import { ProgressData } from './common-api';
import { CommonApi } from '.';

export class BaseResourcesApi {
  public getUrl: (path: string) => string;

  constructor(getUrl: (path: string) => string) {
    this.getUrl = getUrl;
  }

  public get<T>(path: string, error: string = null): Promise<T> {
    return CommonApi.get<T>(this.getUrl(path), error);
  }

  /**
   * Runs the response through codec to ensure that response matches declared type
   */
  public getV<TResponseCodec extends t.Any>(
    path: string,
    responseCodec: TResponseCodec,
    error: string = null,
  ): Promise<t.TypeOf<TResponseCodec>> {
    return CommonApi.getV(this.getUrl(path), responseCodec, error);
  }

  public post<TResponse, TPayload>(
    path: string,
    data: TPayload = null,
    error: string = null,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<TResponse> {
    return CommonApi.post<TResponse, TPayload>(this.getUrl(path), data, error, cancelTokenSource);
  }

  public postV<TCodec extends t.Any, K>(
    path: string,
    data: K = null,
    codec: TCodec,
    error: string = null,
  ): Promise<t.TypeOf<TCodec>> {
    return CommonApi.postV(this.getUrl(path), data, codec, error);
  }

  public put<T, K>(path: string, data: K, error: string = null): Promise<T> {
    return CommonApi.put<T, K>(this.getUrl(path), data, error);
  }

  public putV<TCodec extends t.Any, K>(
    path: string,
    data: K = null,
    codec: TCodec,
    error: string = null,
  ): Promise<t.TypeOf<TCodec>> {
    return CommonApi.putV(this.getUrl(path), data, codec, error);
  }

  public delete<T, K>(path: string, body: K, error: string = null): Promise<T> {
    return CommonApi.delete<T, K>(this.getUrl(path), body, error);
  }

  public deleteV<TCodec extends t.Any>(
    path: string,
    codec: TCodec,
    error: string = null,
  ): Promise<t.TypeOf<TCodec>> {
    return CommonApi.deleteV(this.getUrl(path), null, codec, error);
  }

  public patch<T, K>(path: string, data: K, error: string = null): Promise<T> {
    return CommonApi.patch<T, K>(this.getUrl(path), data, error);
  }

  public patchV<TCodec extends t.Any, K>(
    path: string,
    data: K = null,
    codec: TCodec,
    error: string = null,
  ): Promise<t.TypeOf<TCodec>> {
    return CommonApi.patchV(this.getUrl(path), data, codec, error);
  }

  public downloadFile(path: string, error: string = null): Promise<void> {
    return CommonApi.downloadFile(this.getUrl(path), error);
  }

  public downloadFileWithData<T>(
    path: string,
    data: T,
    error: string = null,
    onProgress?: (progressEvent: ProgressData) => void,
  ): Promise<void> {
    return CommonApi.downloadFileWithData(this.getUrl(path), data, error, onProgress);
  }

  public getBinary(
    path: string,
    error: string = null,
    cancelTokenSource: CancelTokenSource = null,
    onProgress?: (progressEvent: any) => void,
  ): Promise<ArrayBuffer> {
    return CommonApi.getBinary(this.getUrl(path), error, cancelTokenSource, onProgress);
  }

  public getLink(path: string): string {
    return CommonApi.getLink(this.getUrl(path));
  }

  public getBaseUrl(): string {
    return this.getUrl('');
  }
}
