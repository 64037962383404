import * as t from 'io-ts';

export const QtoTemplateInfoC = t.type(
  {
    id: t.number,
    companyId: t.number,
    name: t.string,
  },
  'QtoTemplateInfo');

export const QtoTemplateInfoArrayC = t.array(QtoTemplateInfoC);

export type QtoTemplateInfo = t.TypeOf<typeof QtoTemplateInfoC>;
