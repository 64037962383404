import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { DrawingsLayoutApi } from 'common/components/drawings';
import { ExcelTableApi, UpdateColumnData } from 'common/components/excel-table';
import { ReferenceHelper } from 'common/components/excel-table/utils';
import { FormulaToolbarApi } from 'common/components/formula-toolbar';
import { State } from 'common/interfaces/state';
import { Arrangement } from '../../../../../2d/constants';
import { TwoDActions } from '../../../../actions/creators';
import { SheetUpdateCells } from '../../../../actions/payloads';
import { UpdateCellForm } from '../../../../interfaces';
import { SheetDataStore, CellDataStore } from '../../../../units/cell-data-controllers/report-cell-data-store';
import { FocusChangeData, TwoDSheet } from '../../2d-sheet';


interface OwnProps {
  sheetId: string;
  sheetName: string;
  drawingsLayoutApi: DrawingsLayoutApi;
  saveApi: (
    ref: ExcelTableApi,
    sheetId: string,
    updateColumns: (payload: Array<Record<string, string>>) => void,
  ) => void;
  referenceReader: ReferenceHelper;
  projectId: string;
  formulaBarApi: FormulaToolbarApi;
  onCellRangeChanged: (ranges: Ag.CellRange[]) => void;
  setFormulaBarValue: (value: string) => void;
  updateCells: (payload: Array<SheetUpdateCells<UpdateCellForm[]>>) => void;
  handlePaste: (cvs?: string) => Promise<void>;
  onFocusChange: (data: FocusChangeData) => void;
  reportEditPermission: boolean;
  selectCellValue: (sheetId: string, columnId: string, rowId: string) => string | number;
  selectCellData: (sheetId: string, columnId: string, rowId: string) => string;
  selectSheetData: (id: string) => SheetDataStore;
  addNewRows: (sheetId: string, count: number) => void;
  setColumnWidth: (sheetId: string, payload: Array<{ columnId: string, width: number }>) => void;
  addNewColumns: (sheetId: string, count: number) => void;
  onSheetReady: (sheetId: string) => void;
  getSheetDataStore: () => CellDataStore;
  updateCellDataOnFill: (sheetId: string, cells: UpdateCellForm[]) => void;
  isValidCell: (cellId: string) => boolean;
}

interface StateToProps {
  showSheetIds: string[];
  type: Arrangement;
}

interface DispatchProps {
  saveNewRows: (pageId: string, count: number) => void;
  addColumns: (pageId: string, count: number) => void;
  updateColumnData: (sheetId: string, form: UpdateColumnData[]) => void;
}

type Props = OwnProps & StateToProps & DispatchProps;

export class SpreadSheetComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { sheetId, drawingsLayoutApi, sheetName } = this.props;
    return (
      <TwoDSheet
        sheetId={sheetId}
        sheetName={sheetName}
        drawingsLayoutApi={drawingsLayoutApi}
        saveApi={this.props.saveApi}
        referenceReader={this.props.referenceReader}
        onCellRangeChanged={this.props.onCellRangeChanged}
        setFormulaBarValue={this.props.setFormulaBarValue}
        updateCells={this.props.updateCells}
        setColumnWidth={this.setColumnWidth}
        addColumns={this.addNewColumns}
        addRows={this.addNewRows}
        projectId={this.props.projectId}
        handlePaste={this.props.handlePaste}
        onFocusChange={this.props.onFocusChange}
        formulaBarApi={this.props.formulaBarApi}
        reportEditPermission={this.props.reportEditPermission}
        selectCellValue={this.props.selectCellValue}
        selectCellData={this.props.selectCellData}
        selectSheetData={this.props.selectSheetData}
        onSheetReady={this.props.onSheetReady}
        getSheetDataStore={this.props.getSheetDataStore}
        updateCellDataOnFill={this.props.updateCellDataOnFill}
        isValidCell={this.props.isValidCell}
      />
    );
  }

  @autobind
  private addNewRows(sheetId: string, count: number): void {
    this.props.addNewRows(sheetId, count);
    this.props.saveNewRows(sheetId, count);
  }

  @autobind
  private setColumnWidth(sheetId: string, payload: Array<{ columnId: string, width: number }>): void {
    const form = payload.map(
      ({ columnId, width }) => ({ columnId, propertyKey: 'width', value: width }),
    );
    this.props.updateColumnData(sheetId, form);
    this.props.setColumnWidth(sheetId, payload);
  }

  @autobind
  private addNewColumns(sheetId: string, count: number): void {
    this.props.addColumns(sheetId, count);
    this.props.addNewColumns(sheetId, count);
  }
}

function mapStateToProps(state: State): StateToProps {
  return {
    showSheetIds: state.twoD.showSheetIds,
    type: state.twoD.tableViewType,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps {
  return {
    saveNewRows: (pageId, count) => dispatch(TwoDActions.addRows(props.projectId, pageId, count)),
    addColumns: (pageId, count) => dispatch(TwoDActions.addColumns(props.projectId, pageId, count)),
    updateColumnData: (sheetId, form) =>
      dispatch(TwoDActions.updateColumnData({ projectId: props.projectId, sheetId, form })),
  };
}

export const SpreadSheet = connect(mapStateToProps, mapDispatchToProps)(SpreadSheetComponent);
