import * as t from 'io-ts';

import { SubscriptionTypeC } from 'common/constants/subscription';
import { AdminCompanySubscriptionVMC } from './admin-company-subscription-vm';
import { AdminDatabaseVmC } from './admin-database-vm';
import { AdminProjectVmC } from './admin-project-vm';


export const AdminCompanyVmC = t.type(
  {
    id: t.number,
    name: t.string,
    email: t.string,
    documents: t.array(AdminProjectVmC),
    databases: t.array(AdminDatabaseVmC),
    subscriptions: t.record(SubscriptionTypeC, AdminCompanySubscriptionVMC),
    currencyCode: t.string,
    preferImperial: t.boolean,
  },
  'AdminCompanyVm');

export type AdminCompanyVm = t.TypeOf<typeof AdminCompanyVmC>;
