import * as React from 'react';

interface Props {
  condition: boolean;
}

export class RenderIf extends React.Component<Props> {
  public render(): React.ReactNode {
    const { condition, children } = this.props;
    return condition ? children : null;
  }
}
