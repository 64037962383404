import { Action } from 'redux';

import { RequestStatus } from 'common/enums/request-status';
import { SubscriptionEditorState } from 'common/interfaces/account/subscription-editor-form-state';
import { ActionWith } from 'common/interfaces/action-with';
import { CreateOrganizationFormData } from '../../empty-organizations/components/create-organization-dialog';
import { Company, UpdateCompany } from '../interfaces/company';
import { MotivationPopupSettings } from '../interfaces/motivation-popup-settings';
import { UserInfoResponse } from '../interfaces/user-info-response';
import {
  ChangeCompanySubscriptionPayload,
  CreateCompanyPayload,
  SubscribeErrorPayload,
  UpdateCompanyPayload,
  ValidationField,
} from './payloads';
import { AccountActionTypes } from './types';


function getCurrentUserInfo(): Action {
  return {
    type: AccountActionTypes.GET_INFO,
  };
}

function userInfoSucceeded(data: UserInfoResponse): ActionWith<UserInfoResponse> {
  return {
    type: AccountActionTypes.GET_INFO_SUCCEEDED,
    payload: data,
  };
}

function logOut(withReturnUrl?: boolean): ActionWith<boolean> {
  return {
    type: AccountActionTypes.LOG_OUT,
    payload: withReturnUrl === false ? false : true,
  };
}

function signInFailed(message: string): ActionWith<string> {
  return {
    type: AccountActionTypes.SIGN_IN_FAILED,
    payload: message,
  };
}

function updateCompany(id: number, data: UpdateCompany): ActionWith<UpdateCompanyPayload> {
  return {
    type: AccountActionTypes.UPDATE_COMPANY,
    payload: { id, data },
  };
}

function setUserInfo(): Action {
  return {
    type: AccountActionTypes.SET_INFO,
  };
}

function setAvatarFile(file: File): ActionWith<File> {
  return {
    type: AccountActionTypes.SET_AVATAR_FILE,
    payload: file,
  };
}

function setCompanyLogoTemporaryKey(key: string): ActionWith<string> {
  return {
    type: AccountActionTypes.SET_COMPANY_LOGO_TEMPORARY_KEY,
    payload: key,
  };
}


function setResetPasswordStatus(status: RequestStatus): ActionWith<RequestStatus> {
  return {
    type: AccountActionTypes.SET_RESET_PASSWORD_STATUS,
    payload: status,
  };
}

function fetchCompanies(): Action {
  return {
    type: AccountActionTypes.FETCH_COMPANIES,
  };
}

function fetchCompaniesSuccess(
  companies: Company[],
): ActionWith<Company[]> {
  return {
    type: AccountActionTypes.FETCH_COMPANIES_SUCCEEDED,
    payload: companies,
  };
}

function selectCompany(company: Company): ActionWith<Company> {
  return {
    type: AccountActionTypes.SELECT_COMPANY,
    payload: company,
  };
}

function tryRefreshSession(): Action {
  return {
    type: AccountActionTypes.TRY_REFRESH_SESSION,
  };
}

function setQuestionnaireCompleted(isComplete: boolean): ActionWith<boolean> {
  return {
    type: AccountActionTypes.SET_QUESTIONNAIRE_COMPLETED,
    payload: isComplete,
  };
}

function setShowQuestionnaire(isComplete: boolean): ActionWith<boolean> {
  return {
    type: AccountActionTypes.SET_SHOW_QUESTIONNAIRE,
    payload: isComplete,
  };
}

function createCompany(
  data: CreateOrganizationFormData,
  logoTemporaryKey?: string,
): ActionWith<CreateCompanyPayload> {
  return {
    type: AccountActionTypes.CREATE_COMPANY_REQUEST,
    payload: { data, logoTemporaryKey },
  };
}

function createCompanySucceeded(): Action {
  return {
    type: AccountActionTypes.CREATE_COMPANY_SUCCEEDED,
  };
}

function getInitialAppData(): Action {
  return {
    type: AccountActionTypes.GET_INITIAL_APP_DATA,
  };
}

function setInitialAppDataStatus(status: RequestStatus): ActionWith<RequestStatus> {
  return {
    type: AccountActionTypes.SET_INITIAL_APP_DATA_STATUS,
    payload: status,
  };
}

function checkoutNewSubscription(planId: string): ActionWith<string> {
  return {
    type: AccountActionTypes.CHECKOUT_NEW_SUBSCRIPTION,
    payload: planId,
  };
}

function changeCompanySubscription(
  payload: ChangeCompanySubscriptionPayload,
): ActionWith<ChangeCompanySubscriptionPayload> {
  return {
    type: AccountActionTypes.CHANGE_COMPANY_SUBSCRIPTION,
    payload,
  };
}

function openSelfServePortal(): Action {
  return {
    type: AccountActionTypes.OPEN_SELF_SERVE_PORTAL,
  };
}

function acknowledgeSubscriptionChanges(): Action {
  return {
    type: AccountActionTypes.ACKNOWLEDGE_SUBSCRIRPTION_CHANGES,
  };
}

function loadMotivationPopupSettings(): Action {
  return {
    type: AccountActionTypes.LOAD_MOTIVATION_SETTINGS_REQUEST,
  };
}

function loadMotivationPopupSettingsSucceed(
  data: MotivationPopupSettings,
): ActionWith<MotivationPopupSettings> {
  return {
    type: AccountActionTypes.LOAD_MOTIVATION_SETTINGS_SUCCEED,
    payload: data,
  };
}

function setMotivationPopupSettings(
  timeBeforeOpening: number,
  timeBeforeClosing: number,
): ActionWith<MotivationPopupSettings> {
  return {
    type: AccountActionTypes.SET_MOTIVATION_SETTINGS,
    payload: {
      timeInSecondsBeforeOpen: timeBeforeOpening,
      timeInSecondsBeforeClose: timeBeforeClosing,
    },
  };
}

function deleteMotivationPopupSettings(): Action {
  return {
    type: AccountActionTypes.DELETE_MOTIVATION_SETTINGS,
  };
}


// todo: split account settings and account actions
function toggleImperialMetric(): Action {
  return {
    type: AccountActionTypes.TOGGLE_IMPERIAL_METRIC,
  };
}


function setUserColors(value: string[]): ActionWith<string[]> {
  return {
    type: AccountActionTypes.SET_CUSTOM_COLORS,
    payload: value,
  };
}

function removeUserColor(color: string): ActionWith<string> {
  return {
    type: AccountActionTypes.REMOVE_USER_COLOR,
    payload: color,
  };
}

function setSubscribeError(field: ValidationField, errorText: string): ActionWith<SubscribeErrorPayload> {
  return {
    type: AccountActionTypes.SET_SUBSCRIBE_ERROR,
    payload: {
      field,
      errorText,
    },
  };
}

function toggleShowLoading(showLoading: boolean): ActionWith<boolean> {
  return {
    type: AccountActionTypes.TOGGLE_SHOW_LOADING,
    payload: showLoading,
  };
}

function setAvatar(tempAvatarKey: string): ActionWith<string> {
  return {
    type: AccountActionTypes.SET_AVATAR,
    payload: tempAvatarKey,
  };
}

function setAvatarSucceed(): Action {
  return {
    type: AccountActionTypes.SET_AVATAR_SUCCEED,
  };
}

function removeAvatar(): Action {
  return {
    type: AccountActionTypes.REMOVE_AVATAR,
  };
}

function setSubscriptionEditFormState(payload: Partial<SubscriptionEditorState>):
  ActionWith<Partial<SubscriptionEditorState>> {
  return {
    type: AccountActionTypes.SET_SUBSCRIPTION_EDIT_FORM_STATE,
    payload,
  };
}

export const AccountActions = {
  getCurrentUserInfo,
  userInfoSucceeded,
  logOut,
  signInFailed,
  setUserInfo,
  setAvatarFile,
  setCompanyLogoTemporaryKey,
  setResetPasswordStatus,
  fetchCompanies,
  fetchCompaniesSuccess,
  selectCompany,
  tryRefreshSession,
  setQuestionnaireCompleted,
  setShowQuestionnaire,

  updateCompany,
  createCompany,
  createCompanySucceeded,

  getInitialAppData,
  setInitialAppDataStatus,
  checkoutNewSubscription,
  changeCompanySubscription,
  openSelfServePortal,
  acknowledgeSubscriptionChanges,
  loadMotivationPopupSettings,
  loadMotivationPopupSettingsSucceed,
  setMotivationPopupSettings,
  deleteMotivationPopupSettings,
  setSubscribeError,

  toggleImperialMetric,
  setUserColors,
  removeUserColor,
  toggleShowLoading,

  setAvatar,
  setAvatarSucceed,
  removeAvatar,

  setSubscriptionEditFormState,
};
