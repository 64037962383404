import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import {
  CustomElementFilterForm,
  CustomElementFilterSimpleViewModel,
  CustomElementFilterViewModel,
} from 'common/interfaces/custom-element-filter-builder';
import { CustomFiltersTypes } from '../types/custom-filters';

const getCustomFiltersInfo = (): Action => {
  return {
    type: CustomFiltersTypes.GET_CUSTOM_FILTERS_INFO,
  };
};

const setCustomFiltersInfo = (
  customFiltersInfo: CustomElementFilterSimpleViewModel[],
): ActionWith<CustomElementFilterSimpleViewModel[]> => {
  return {
    type: CustomFiltersTypes.SET_CUSTOM_FILTERS_INFO,
    payload: customFiltersInfo,
  };
};

const getCustomFiltersByIds = (ids: string[]): ActionWith<string[]> => {
  return {
    type: CustomFiltersTypes.GET_CUSTOM_FILTERS_BY_IDS,
    payload: ids,
  };
};

const setCustomFilterEditModel = (
  filterModel: CustomElementFilterViewModel,
): ActionWith<CustomElementFilterViewModel> => {
  return {
    type: CustomFiltersTypes.SET_CUSTOM_FILTER_EDIT_MODEL,
    payload: filterModel,
  };
};

const createCustomFilters = (forms: CustomElementFilterForm[]): ActionWith<CustomElementFilterForm[]> => {
  return {
    type: CustomFiltersTypes.CREATE_CUSTOM_FILTERS,
    payload: forms,
  };
};

const updateCustomFilters = (forms: CustomElementFilterForm[]): ActionWith<CustomElementFilterForm[]> => {
  return {
    type: CustomFiltersTypes.UPDATE_CUSTOM_FILTERS,
    payload: forms,
  };
};

const deleteCustomFilters = (ids: string[]): ActionWith<string[]> => {
  return {
    type: CustomFiltersTypes.DELETE_CUSTOM_FILTERS,
    payload: ids,
  };
};

const copyCustomFilter = (id: string, name: string): ActionWith<{ id: string, name: string }> => {
  return {
    type: CustomFiltersTypes.COPY_CUSTOM_FILTER,
    payload: { id, name },
  };
};

const applyCustomFilter = (id: string[] | null): ActionWith<string[] | null> => {
  return {
    type: CustomFiltersTypes.APPLY_CUSTOM_FILTER,
    payload: id,
  };
};

const setAppliedCustomFilter = (model: CustomElementFilterViewModel): ActionWith<CustomElementFilterViewModel> => {
  return {
    type: CustomFiltersTypes.SET_APPLIED_CUSTOM_FILTER,
    payload: model,
  };
};

export const CustomFiltersActions = {
  getCustomFiltersInfo,
  getCustomFiltersByIds,
  setCustomFiltersInfo,
  setCustomFilterEditModel,
  createCustomFilters,
  updateCustomFilters,
  deleteCustomFilters,
  copyCustomFilter,
  applyCustomFilter,
  setAppliedCustomFilter,
};
