import { RowNode } from 'ag-grid-community';

const hasChildren = (targetId: string, nodeMap: Record<string, RowNode>): boolean => {
  const nodeExist = nodeMap[targetId];
  if (nodeExist) {
    return true;
  }

  for (const node of Object.values(nodeMap)) {
    if (!node) {
      continue;
    }
    const result = hasChildren(targetId, node.childrenMapped);
    if (result) {
      return true;
    }
  }

  return false;
};

export const canMove = (targetId: string, nodes: RowNode[]): boolean => {
  if (!targetId) {
    return true;
  }
  if (!nodes) {
    throw new Error('Empty row nodes');
  }
  return !nodes.some(n => n.id === targetId || hasChildren(targetId, n.childrenMapped));
};
