import { useCallback } from 'react';

import { PiaBreakdownNode } from '../interfaces';
import { deleteOption, findOption, findParent } from '../utils';
import { UpdateFunction } from './use-options-state';

type DragEndCallback = (
  targetId: string,
  sourceId: string,
  _: boolean,
  isBefore: boolean,
  selectedNodeIds: string[],
) => void;

const isSourceChildOfTarget = (options: PiaBreakdownNode[], id: string): boolean => {
  console.warn(`optionsLenght: ${options.length}`);
  return !!findOption(options, id);
};

const handleUpdateOption = (
  sourceId: string,
  targetId: string,
  ops: PiaBreakdownNode[],
  opsMap: Record<string, PiaBreakdownNode>,
  isBefore: boolean,
): void => {
  if (sourceId === targetId) {
    return;
  }
  const sourceOption = opsMap[sourceId];
  const targetOption = opsMap[targetId];
  const shouldPreventMove = isSourceChildOfTarget(sourceOption.children, targetId);
  if (shouldPreventMove) {
    return;
  }
  deleteOption(ops, sourceId);
  if (targetId === '') {
    ops.push(sourceOption);
  } else {
    console.warn(targetOption.children.length);

    if (isBefore) {
      for (const option of ops) {
        if (option.id === targetId) {
          const indexOf = ops.indexOf(targetOption);
          ops.splice(indexOf, 0, sourceOption);
          break;
        } else {
          const targetParent = findParent(option.children, targetId, option);
          if (targetParent) {
            const targetIndex = targetParent.children.indexOf(targetOption);
            targetParent.children.splice(targetIndex, 0, sourceOption);
            break;
          }
        }
      }
    } else {
      targetOption.children.push(sourceOption);
    }
  }
};

export const useDragEndCallback = (updateOptions: UpdateFunction): DragEndCallback => {
  return useCallback(
    (targetId: string, sourceId: string, _: boolean, isBefore: boolean, selectedNodeIds: string[]) => {
      updateOptions((ops, opsMap) => {
        if (!selectedNodeIds.length) {
          selectedNodeIds.push(sourceId);
        }
        if (selectedNodeIds.includes(sourceId)) {
          selectedNodeIds.forEach(selectedNodeId => {
            handleUpdateOption(selectedNodeId, targetId, ops, opsMap, isBefore);
          });
        } else {
          handleUpdateOption(sourceId, targetId, ops, opsMap, isBefore);
        }
      });
    },
    [updateOptions],
  );
};
