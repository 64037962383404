import React from 'react';

import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { MagicSearchToggle } from 'common/components/drawings/layout-components/magic-search-toggle';
import {
  DropperMenu,
  FinderMenu,
  WandToolSettings,
  BucketSettings,
  WizzardMainContainer,
} from 'common/components/drawings/layout-components/wizzard-tools';

export const AdditionalSettings: React.FC = () => {
  const { drawMode } = useDrawModeApi();

  switch (drawMode) {
    case DrawingsDrawMode.Wand:
      return <WandToolSettings/>;
    case DrawingsDrawMode.Dropper:
      return <DropperMenu/>;
    case DrawingsDrawMode.Finder:
      return <FinderMenu/>;
    case DrawingsDrawMode.BucketFill:
      return <BucketSettings/>;
    case DrawingsDrawMode.Polygon:
    case DrawingsDrawMode.Rectangle2Point:
    case DrawingsDrawMode.Rectangle3Point:
      return (
        <WizzardMainContainer>
          <MagicSearchToggle/>
        </WizzardMainContainer>
      );
    default:
      return null;
  }
};
