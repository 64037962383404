import { ElementTooltip } from '@kreo/kreo-ui-components';
import React from 'react';

import { RenderIf } from 'common/components/render-if';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { Styled } from '../styled';

export interface MeasureValue {
  icon: React.ComponentType;
  value: number;
  unit?: UnitTypes;
  itemsCount?: number;
  title?: string;
}

interface Props {
  showTooltip: boolean;
  measureValue: MeasureValue;
  hideIconsInfo: boolean;
  hideInfo: boolean;
  isImperial: boolean;
}

const ItemMeasureComponent: React.FC<Props> = ({
  showTooltip,
  hideIconsInfo,
  hideInfo,
  measureValue,
  isImperial,
}) => {
  const valueString = UnitUtil.measureToString2d(measureValue.value, measureValue.unit, isImperial);
  const Icon = measureValue.icon;
  return (
    <Styled.InfoItem
      hideInfo={hideInfo}
      hideIconsInfo={hideIconsInfo}
    >
      <ElementTooltip
        text={valueString}
        position="top"
        disabled={!showTooltip || !hideInfo}
        wordBreakAll={true}
        tooltipOverflow="hidden"
        speed="l"
      >
        <Styled.Icon>
          {
            Icon ? <Icon/> : null
          }
        </Styled.Icon>
      </ElementTooltip>
      <RenderIf condition={!hideInfo}>
        <Styled.Text>{valueString}</Styled.Text>
      </RenderIf>
    </Styled.InfoItem>
  );
};

export const ItemMeasure = React.memo(ItemMeasureComponent);
