import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './expand-collapse-controls.scss';

import { IconButton, KreoIconCollapseExpand } from 'common/UIKit';
import { ElementTooltip, TooltipPosition } from '../element-tooltip';


interface Props {
  className?: string;
  onExpand?: () => void;
  onCollapse?: () => void;
}

export class ExpandCollapseControls extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    const { className } = this.props;
    const containerClassName = classNames('expand-collapse-controls', className);
    return (
      <div className={containerClassName} >
        <ElementTooltip
          className='expand-collapse-controls__button expand-collapse-controls__button--expand'
          size='small'
          tooltipPosition={TooltipPosition.CenterTop}
          targetElement={
            <IconButton
              size='small'
              rounded={false}
              controlName='expand-all-button'
              onClick={this.onExpand}
            >
              <KreoIconCollapseExpand />
            </IconButton>
          }
        >
          Expand All
        </ElementTooltip>
        <ElementTooltip
          className='expand-collapse-controls__button expand-collapse-controls__button--collapse'
          size='small'
          tooltipPosition={TooltipPosition.CenterTop}
          controlName='collapse-all-button'
          onClick={this.onCollapse}
          targetElement={
            <IconButton
              size='small'
              rounded={false}
            >
              <KreoIconCollapseExpand />
            </IconButton>
          }
        >
          Collapse All
        </ElementTooltip>
      </div>
    );
  }

  @autobind
  private onExpand(): void {
    this.props.onExpand();
  }

  @autobind
  private onCollapse(): void {
    this.props.onCollapse();
  }
}

