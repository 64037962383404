import * as React from 'react';

import './index.scss';

import { UserMap } from 'common/interfaces/people';
import { KreoButton, TopCardPanel } from 'common/UIKit';
import { CommentModel } from '../../interfaces';
import { TopPanelContent } from '../top-panel-content';
import { Body } from './body';


interface Props {
  commentaries: CommentModel[];
  withResolved: boolean;
  peopleMap: UserMap;
  companyId: number;
  projectId: number;
  markerMode: boolean;
  onCommentClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onExitClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onToStateClick: (value: any) => void;
  onUpdate: () => void;
  onResolve: (value: boolean) => void;
  selectComment: (index: any) => void;
  onShowNewCommentField: () => void;
  onMarkMode: () => void;
}

export class Comments extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: any): any {
    if (nextProps.enginemove) {
      this.props.onUpdate();
      return false;
    }
    return true;
  }

  public render(): React.ReactNode {
    return (
      <div className='viewer-comments'>
        <TopCardPanel
          onExitClick={this.props.onExitClick}
          exit={true}
          controlNameButtonClose='close-comments-sidebar-button'
        >
          <TopPanelContent
            canChange={true}
            resolved={this.props.withResolved}
            onResolve={this.props.onResolve}
          />
        </TopCardPanel>
        <div className='viewer-comments__create-buttons'>
          <KreoButton
            mode='action'
            size='medium'
            rounded={true}
            caption='Comment'
            controlName='button-comment'
            onClick={this.props.onShowNewCommentField}
          />
          <KreoButton
            mode={this.props.markerMode ? 'success' : 'action'}
            size='medium'
            rounded={true}
            caption='With Location Mark'
            controlName='button-with-location-mark'
            onClick={this.props.onMarkMode}
            tooltip='Select any element and the comment will be attached to it.'
          />
        </div>
        <Body
          className='viewer-comments__body'
          peopleMap={this.props.peopleMap}
          onToStateClick={this.props.onToStateClick}
          companyId={this.props.companyId}
          projectId={this.props.projectId}
          commentaries={this.props.commentaries}
          selectComment={this.props.selectComment}
          onCommentClick={this.props.onCommentClick}
        />
      </div>
    );
  }
}
