/**
 * @description Describes units on the timeline.
 */
export enum TimelineScale {
  Year = 0,
  Quarter = 1,
  Month = 2,
  Week = 3,
  Day = 4,
  Hour = 5,
}
