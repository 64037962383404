import * as React from 'react';

interface Props {
  color?: string;
}

export const KreoIconLayers: React.FC<Props> = (_props) => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icon_layers' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          // eslint-disable-next-line max-len
          d='M12.5077822,8 L15.2480695,9.56587843 C15.5839768,9.7578255 15.5839768,10.2421745 15.2480695,10.4341216 L8.24806947,14.4341216 C8.09435313,14.5219595 7.90564687,14.5219595 7.75193053,14.4341216 L0.751930531,10.4341216 C0.416023156,10.2421745 0.416023156,9.7578255 0.751930531,9.56587843 L3.49221778,8 L0.751930531,6.43412157 C0.416023156,6.2421745 0.416023156,5.7578255 0.751930531,5.56587843 L7.75193053,1.56587843 C7.90564687,1.47804052 8.09435313,1.47804052 8.24806947,1.56587843 L15.2480695,5.56587843 C15.5839768,5.7578255 15.5839768,6.2421745 15.2480695,6.43412157 L12.5077822,8 Z M11.5,8.57587555 L8.24806947,10.4341216 C8.09435313,10.5219595 7.90564687,10.5219595 7.75193053,10.4341216 L4.5,8.57587555 L2.00778222,10 L8,13.4241244 L13.9922178,10 L11.5,8.57587555 Z'
          id='Combined-Shape'
          fill={_props.color}
        />
      </g>
    </svg>
  );
};


KreoIconLayers.defaultProps = {
  color: '#5C8AE6',
};
