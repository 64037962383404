import * as React from 'react';

import { CommentCountMark } from 'unit-2d-comments/comment-count-mark';
import { Styled } from './styled';


interface Props {
  count: number;
}

const PageCommentCountMark: React.FC<Props> = ({ count }) => {
  return (
    <Styled.CountMark>
      <CommentCountMark
        count={count}
        showTooltip={true}
      />
    </Styled.CountMark>
  );
};


export const getPageCommentCountMark: (
  pageId: string,
  tabComments: Record<string, number>,
) => JSX.Element = (pageId, tabComments) => {
  const count = tabComments[pageId];

  return (count ?
    <PageCommentCountMark
      count={count}
    />
    : null
  );
};
