import classNames from 'classnames';
import * as React from 'react';

import './activity-assignment-activity-tile-containers.scss';

export const ActivityAssignmentActivityTileBodyTitle: React.FC = props => {
  return (
    <div className='activity-assignment-activity-tile-body-title'>
      {props.children}
    </div>
  );
};

export const ActivityAssignmentActivityTileBody: React.FC = props => {
  return (
    <div className='activity-assignment-activity-tile-body'>
      {props.children}
    </div>);
};

interface TileContainerProps {
  enabled: boolean;
}

export class ActivityAssignmentActivityTileContainer extends React.PureComponent<TileContainerProps> {
  public render(): React.ReactNode {
    const { children, enabled } = this.props;
    const className = classNames(
      'activity-assignment-activity-tile-container',
      {
        'activity-assignment-activity-tile-container--enabled': enabled,
      },
    );
    return <div className={className}>{children}</div>;
  }
}
