import { H5, Icons, LinkComponent, ModalWrapper, RectangleButton, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ControlNames } from 'common/constants/control-names';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { FeaturesList } from './adv-dialog-features-list';
import { TutorialVideo } from './adv-dialog-tutorial-video';
import { Styled } from './styled';

interface ItemProps {
  videoUrl?: string;
  header: string;
  description?: string;
  features: string[];
  onConfirm: () => void;
  text: string;
  controlName: string;
  marker?: JSX.Element;
  dialogName: string;
  onCloseDialog: () => void;
  onChatOpen: () => void;
}

export class AdvDialog extends React.Component<ItemProps> {
  public render(): JSX.Element {
    const {
      videoUrl,
      header,
      description,
      features,
      onConfirm,
      text,
      marker,
      controlName,
      dialogName,
      onChatOpen,
    } = this.props;
    return (
      <DialogWrapper name={dialogName}>
        <ModalWrapper onExit={this.props.onCloseDialog}>
          <Styled.Wrapper controlName={controlName}>
            <Styled.TutorialVideo controlName={ControlNames.advDialogVideo}>
              <TutorialVideo videoUrl={videoUrl} />
            </Styled.TutorialVideo>
            <Styled.Information controlName={ControlNames.advDialogInformation}>
              <Styled.AdvDialogHeader controlName={ControlNames.advDialogHeader}>
                <H5 controlName={ControlNames.advDialogHeaderH5}>{header}</H5>
              </Styled.AdvDialogHeader>
              <Styled.AdvDialogDescription controlName={ControlNames.advDialogDescription}>
                <Text>{description}</Text>
              </Styled.AdvDialogDescription>
              <Styled.FeatureList controlName={ControlNames.advDialogFeaturesList}>
                <FeaturesList features={features} marker={marker} />
              </Styled.FeatureList>
              <Styled.ButtonAndChatButton>
                <Styled.ConfirmButton controlName={ControlNames.advDialogButton}>
                  <RectangleButton
                    height={50}
                    width={225}
                    text={text}
                    mood={'secondary'}
                    onClick={onConfirm}
                  />
                </Styled.ConfirmButton>
                <Styled.ChatButton controlName={ControlNames.advDialogChatButton}>
                  <LinkComponent
                    controlName={ControlNames.advDialogChatLink}
                    text='Chat with us!'
                    Icon={Icons.Chat2D}
                    onClick={onChatOpen}
                  />
                </Styled.ChatButton>
              </Styled.ButtonAndChatButton>
            </Styled.Information>
          </Styled.Wrapper>
        </ModalWrapper>
      </DialogWrapper>
    );
  }
}
