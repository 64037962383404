import styled from 'styled-components';

import { SETTING_HEIGHT } from 'unit-2d-info-panel/content/settings/styled';
import { Text } from '../../../text';


const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: ${SETTING_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  > ${Text} {
    font-weight: 500;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.turquoise};
  }
`;

export const Styled = {
  Container,
  Content,
};
