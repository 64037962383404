import autobind from 'autobind-decorator';
import { DrawingsGeometryInstance, ShortPointDescription } from 'common/components/drawings/interfaces';
import { DrawingAnnotationUtils } from 'common/components/drawings/utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsGeometryUtils } from 'common/components/drawings/utils/drawings-geometry-utils';
import { EngineObject, EngineObjectConfig } from '../../common';
import { BoundingRect } from '../../drawings-geometry-entities';
import {
  InstanceGeometryManager,
  PointPositionsEditor,
  RotationProcessor,
  StrictAngleController,
} from '../../interfaces';
import { DragEventWrapper } from '../drag-instances';
import { DrawingsRenderedPointsCache } from '../drawings-rendered-points-cache';
import { StrictAngleRotationProcessor } from './strict-angle-rotation-processor';

interface Config extends EngineObjectConfig {
  dragProcessingHelper: DragEventWrapper;
  pointsPositionEditor: PointPositionsEditor;
  pointsManager: DrawingsRenderedPointsCache;
  instanceManager: InstanceGeometryManager;
  strictController: StrictAngleController;
  getSelectedInstancesIterator: () => Iterable<[string, DrawingsGeometryInstance]>;
  getSelectionRect: () => BoundingRect;
  getCurrentPageInfo: () => Core.Document.PageInfo;
}

export class InstancesRotationHelper extends EngineObject<Config> {
  private _rotationProcessor: RotationProcessor;

  constructor(config: Config) {
    super(config);

    this._rotationProcessor = new StrictAngleRotationProcessor({
      dragProcessingHelper: this._config.dragProcessingHelper,
      changeRotation: this.onChangeRotation,
      getCenterPoint: () => this._config.getSelectionRect().position,
      strictController: this._config.strictController,
    });
  }

  @autobind
  private onChangeRotation(diffAngle: number, finish: boolean): boolean {
    const rect = this._config.getSelectionRect();
    const centerPoint = [rect.position.x, rect.position.y] as ShortPointDescription;
    const updatedPoints = [];
    const { width, height } = this._config.getCurrentPageInfo();
    let isValid = true;
    for (const [instanceId, instance] of this._config.getSelectedInstancesIterator()) {
      const { type, geometry } = instance;
      if (DrawingsGeometryUtils.isPolyGeometry(type, geometry) || DrawingsGeometryUtils.isCount(type, geometry)) {
        const geometryPoints = [];
        for (const pointId of DrawingAnnotationUtils.geometryPointsIterator(geometry, type)) {
          geometryPoints.push(pointId);
          updatedPoints.push(pointId);
          const point = this._config.pointsManager.getConvertedPoint(pointId);
          const rotatedPoints = DrawingsCanvasUtils.rotatePoint(point, -diffAngle, centerPoint);
          const [x, y] = rotatedPoints;
          if (width < x || x < 0 || height < y || y < 0) {
            isValid = false;
          }
          this._config.pointsManager.setPointShortDescription(pointId, rotatedPoints);
        }
        this._config.instanceManager.updatePointsPositionInInstance(geometryPoints, instanceId);
      }
    }
    rect.rotate(diffAngle);
    if (finish) {
      if (!isValid) {
        return false;
      }
      this._config.pointsPositionEditor.finishEditPoints(updatedPoints);
    }
    return isValid;
  }


  public get rotationProcessor(): RotationProcessor {
    return this._rotationProcessor;
  }
}
