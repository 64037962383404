import {
  DrawingsGeometryGroupWithNesting,
} from '../interfaces/drawings-geometry-instance';

export enum DrawingsDragAndDropType {
  DrawingInstance = 'DrawingInstance',
  DrawingGroup = 'DrawingGroup',
}

export interface DrawingsDragAndDropData {
  groups: DrawingsGeometryGroupWithNesting[];
  measurements: string[];
}

function isLoop(selectedGroups: DrawingsGeometryGroupWithNesting[], targetGroupId: string): boolean {
  return selectedGroups.some(group => group.id === targetGroupId)
    || selectedGroups.some(group => group.allInnerInstances.some(inst => inst.id === targetGroupId));
}

export const DrawingsDragAndDropUtils = {
  isLoop,
};
