import styled from 'styled-components';

const Container = styled.div`
  width: 190px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  > button {
    height: 25px;
    width: 90px;
    color: ${p => p.theme.color.gray};
    border-radius: 30px;
  }
`;

export const Styled = {
  Container,
};
