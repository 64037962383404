import { useMemo } from 'react';

import { AssemblyForm } from '../../assembly-panel/item-form-updater';
import { MeasureForm } from '../interfaces';

const isEmptyForm = (assemblyForm: MeasureForm, itemForm: AssemblyForm): boolean => {
  return Object.keys(assemblyForm.assemblyGroupForm).length === 0
  && Object.keys(itemForm.itemGroupForm).length === 0;
};

export const useIsShowNotification = (assemblyForm: MeasureForm, itemForm: AssemblyForm): boolean => {
  return useMemo(() => isEmptyForm(assemblyForm, itemForm), [assemblyForm, itemForm]);
};
