import { UserPermission } from 'common/enums/user-permission';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { DefaultCompanyRole } from 'common/interfaces/account/default-company-role';
import { CompanyResourcesApi, CompaniesResourcesApi } from '../../../api/server';
import { UpdateRole } from '../../account/interfaces/update-role';
import { UpdateRolePermissions } from '../../account/interfaces/update-role-permissions';

function loadSubscriptionRoles(): Promise<CompanyRoles> {
  return CompanyResourcesApi.get(
    '/roles',
    `Error while fetching subscription roles`,
  );
}

function getDefaultSubscriptionRoles(): Promise<{ roles: DefaultCompanyRole[] }> {
  return CompaniesResourcesApi.get(
    '/roles',
    `Error while fetching subscription roles`,
  );
}

function updateSubscriptionPermissionRoles(
  rolesPermissions: UpdateRolePermissions[],
): Promise<Record<number, UserPermission[]>> {
  return CompanyResourcesApi.put(
    '/permissions',
    rolesPermissions,
    `Error while updating permission subscription roles}`,
  );
}

function updateSubscriptionRoles(roles: UpdateRole[]): Promise<CompanyRoles> {
  return CompanyResourcesApi.put(
    '/roles',
    roles,
    `Error while updating subscription roles`,
  );
}

export const SubscriptionRolesApi = {
  loadSubscriptionRoles,
  getDefaultSubscriptionRoles,
  updateSubscriptionPermissionRoles,
  updateSubscriptionRoles,
};

