import * as t from 'io-ts';
import { CEActivityAssignmentDataTreeNodeC } from './ce-activity-assignment-data-tree-node';
import { CEActivityAssignmentTreeNodeActivitySortC } from './ce-activity-assignment-tree-node-activity-short';

export enum CEActivityAssignmentDiffType {
  ActivityNames =   0b00000001,
  AmountOfWork =    0b00000010,
  Resources =       0b00000100,
  ResourceNames =   0b00001000,
  Rates =           0b00010000,
  CrewHours =       0b00100000,
}

export const CEActivityAssignmentTreeNodeActivityResponseC = t.intersection(
  [
    CEActivityAssignmentTreeNodeActivitySortC,
    t.type({
      activityIds: t.array(t.number),
      engineIds: t.array(t.number),
      geometryLessIds: t.array(t.number),
      diffType: t.number,
      data: CEActivityAssignmentDataTreeNodeC,
    }),
  ],
  'CEActivityAssignmentTreeNode',
);

export type CEActivityAssignmentTreeNodeActivityResponse =
  t.TypeOf<typeof CEActivityAssignmentTreeNodeActivityResponseC>;
