import { SliderHorizontal, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


interface Props {
  setStrokeWidth: (value: number) => void;
  strokeWidth: number;
}

export const DrawingsStrokeWithSlider = ({ setStrokeWidth, strokeWidth }: Props): JSX.Element => {
  return (
    <Styled.Container>
      <Text fontSize={14}>{strokeWidth} px</Text>
      <Styled.SliderContainer>
        <SliderHorizontal
          min={1}
          max={10}
          onChange={setStrokeWidth}
          value={strokeWidth}
        />
      </Styled.SliderContainer>
    </Styled.Container>
  );
};
