import { ModalWrapper } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { DrawingsPdf } from './drawings-pdf/drawings-pdf';
import { DrawingsPdfBrowserDialogProps } from './interfaces';

interface Props extends DrawingsPdfBrowserDialogProps {
  onClose: () => void;
}

export class DrawingsPdfBrowserContent extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      isLoading,
      canEdit,
      selectedFile,
      selectedFiles,
      files,
      selectedPages,
      highlightState,
      multiSelect,
      selectAllText,
      deselectAllText,
      onClose,
      renderFilesFooterContent,
      renderFilesHeaderAdditionalContent,
      onChangeDrawingSelection,
      onExpandEntity,
      onSelectFile,
      addFolder,
      getContextMenuItems,
      onDeleteFileClick,
      onDragRow,
      onChangeName,
      startAddFile,
      onPageParameterUpdate,
      onDeletePage,
    } = this.props;
    return (
      <ModalWrapper
        onExit={onClose}
        zIndex={1001}
      >
        <DrawingsPdf
          files={files}
          onDeletePage={onDeletePage}
          multiSelect={multiSelect}
          selectedPages={selectedPages}
          highlightState={highlightState}
          isLoading={isLoading}
          canEdit={canEdit}
          selectAllText={selectAllText}
          deselectAllText={deselectAllText}
          selectedFile={selectedFile}
          renderFilesFooterContent={renderFilesFooterContent}
          renderFilesHeaderAdditionalContent={renderFilesHeaderAdditionalContent}
          selectedFiles={selectedFiles}
          onChangeDrawingSelection={onChangeDrawingSelection}
          onExpandEntity={onExpandEntity}
          onSelectFile={onSelectFile}
          addFolder={addFolder}
          getContextMenuItems={getContextMenuItems}
          onDeleteFileClick={onDeleteFileClick}
          onDragRow={onDragRow}
          onChangeName={onChangeName}
          startAddFile={startAddFile}
          onPageParameterUpdate={onPageParameterUpdate}
        />
      </ModalWrapper>
    );
  }
}
