import { slideTop } from '@kreo/kreo-ui-components/constants/animations';
import styled, { css } from 'styled-components';

import { ProductConfig } from './products-page';

const CONTENT_WIDTH = 1200;

const Container = styled.div<{ url: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: fixed ${(p) => p.theme.color.background} url(${(p) => p.url}) center no-repeat;
  background-size: cover;
  user-select: none;
  transition: all 0.35s;

  &, * {
    box-sizing: border-box;
  }
`;

const Header = styled.header`
  width: ${CONTENT_WIDTH}px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
`;

const KreoLogoContainer = styled.div`
  display: inline-flex;
  align-items: center;
  height: 50px;

  svg {
    height: 100%;
  }
`;

const KreoLogo = styled.img`
  height: 50px;
`;

const SwitcherContainer = styled.div`
  width: 190px;
  color: ${(p) => p.theme.color.gray};
`;

const Content = styled.main`
  width: ${CONTENT_WIDTH}px;
  margin: auto;
  color: ${p => p.theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  animation: ${slideTop} 0.35s linear both;

  p:first-child {
    margin-bottom: 30px;
  }
`;

const Frames = styled.div<{ products: ProductConfig[] }>`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  ${p => p.products.reduce((acc, item, index) => {
    return css` 
      ${acc}
      #${item.id} {
        animation: ${slideTop} 0.35s ${0.35 + 0.15 * index}s linear both;
      }
    `;
  }, [''])
  }
`;

export const Styled = {
  Container,
  Header,
  KreoLogoContainer,
  KreoLogo,
  SwitcherContainer,
  Content,
  TextContainer,
  Frames,
};
