import { Text } from '@kreo/kreo-ui-components';
import styled, { keyframes } from 'styled-components';

const Animation = keyframes`
  0% {
    left: -70%;
  }
  50% {
    left: 120%;
  }
  0% {
    left: -70%;
  }
`;


const Container = styled.div`
  height: 20px;
  width: 100%;
  background: ${p => p.theme.color.background};
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    width: 70%;
    left: -70%;
    height: 100%;
    background: ${p => p.theme.color.turquoise};
    position: absolute;
    border-radius: 10px;
    animation: ${Animation} 2s linear infinite both;
  }
  
  ${Text} {
    position: absolute;
    z-index: 2;
    left: 10px;
  }
`;

export const Styled = {
  Container,
};
