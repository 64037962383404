import { DrawingsGeometryGroup } from 'common/components/drawings/interfaces';
import { DrawingsGroupUtils } from 'common/components/drawings/utils/drawings-group-utils';
import { arrayUtils } from 'common/utils/array-utils';

export function getClosestGroupWithColor(
  selectedGroup: DrawingsGeometryGroup,
  groups: DrawingsGeometryGroup[],
): DrawingsGeometryGroup {
  const groupMap = arrayUtils.toDictionaryByKey(groups, x => x.id);
  return DrawingsGroupUtils.getClosestGroup(selectedGroup, groupMap, group => !!group.color);
}
