import * as React from 'react';

export const IconGrouping: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M3.5,2 C3.22385763,2 3,1.77614237 3,1.5 C3,1.22385763 3.22385763,1 3.5,1 L4.5,1 C4.77614237,1 5,
          1.22385763 5,1.5 C5,1.77614237 4.77614237,2 4.5,2 L3.5,2 Z M6.5,2 C6.22385763,2 6,1.77614237 6,1.5 C6,
          1.22385763 6.22385763,1 6.5,1 L7.5,1 C7.77614237,1 8,1.22385763 8,1.5 C8,1.77614237 7.77614237,2 7.5,
          2 L6.5,2 Z M9.5,2 C9.22385763,2 9,1.77614237 9,1.5 C9,1.22385763 9.22385763,1 9.5,1 L10.5,
          1 C10.7761424,1 11,1.22385763 11,1.5 C11,1.77614237 10.7761424,2 10.5,2 L9.5,2 Z M12.5,2 C12.2238576,
          2 12,1.77614237 12,1.5 C12,1.22385763 12.2238576,1 12.5,1 C12.9288717,1 13.3431527,1.10853776 13.7107353,
          1.3123178 C13.9522477,1.44620718 14.0394934,1.75053046 13.905604,1.99204287 C13.7717146,2.23355528 13.4673913,
          2.32080099 13.2258789,2.1869116 C13.0057308,2.06486614 12.7581414,2 12.5,2 Z M13.9991848
          ,3.44991429 C13.9901841,3.17391865 14.2066262,2.94288345 14.4826219,2.93388274 C14.7586175,
          2.92488204 14.9896527,3.14132421 14.9986534,
          3.41731985 C14.9997069,3.45551633 14.9997069,3.45551633 14.9999941,3.49507044 L15,4.43852939 C15.0000017,
          4.71467177 14.7761455,4.9385308 14.5000031,4.93853254 C14.2238608,4.93853428 14.0000017,4.71467806 14,
          4.43853569 L13.9999955,3.49627911 C13.9998222,3.47302411 13.9998222,3.47302411 13.9991848,3.44991429 Z M14,
          6.43853254 C14,6.16239017 14.2238576,5.93853254 14.5,5.93853254 C14.7761424,5.93853254 15,6.16239017 15,
          6.43853254 L15,7.43853254 C15,7.71467492 14.7761424,7.93853254 14.5,7.93853254 C14.2238576,7.93853254 14,
          7.71467492 14,7.43853254 L14,6.43853254 Z M14,9.43853254 C14,9.16239017 14.2238576,8.93853254 14.5,
          8.93853254 C14.7761424,8.93853254 15,9.16239017 15,9.43853254 L15,10.4385325 C15,10.7146749 14.7761424,
          10.9385325 14.5,10.9385325 C14.2238576,10.9385325 14,10.7146749 14,10.4385325 L14,9.43853254 Z M14,
          12.4385325 C14,12.1623902 14.2238576,11.9385325 14.5,11.9385325 C14.7761424,11.9385325 15,12.1623902 15,
          12.4385325 L15,12.5 C15,12.9016283 14.904828,13.2906781 14.7249798,13.6408647 C14.5988241,
          13.8865054 14.2974235,13.9833671 14.0517829,13.8572114 C13.8061422,13.7310557 13.7092804,
          13.4296552 13.8354362,13.1840145 C13.9431091,12.9743621 14,12.7417999 14,12.5 L14,12.4385325 Z M12.5998848,
          13.9967456 C12.8754383,13.9787199 13.1134312,14.1874874 13.1314569,14.4630408 C13.1494826,
          14.7385942 12.9407151,14.9765871 12.6651617,14.9946128 C12.6143078,14.9979395 12.5632456,
          14.9997293 12.5096736,14.9999772 L11.6229478,15 C11.3468054,15.0000071 11.122942,14.7761553 11.1229349,
          14.5000129 C11.1229278,14.2238705 11.3467797,14.0000071 11.622922,14 L12.507296,13.9999828 C12.5383131,
          13.9998361 12.5691836,13.998754 12.5998848,13.9967456 Z M9.62293492,14 C9.89907729,14 10.1229349,
          14.2238576 10.1229349,14.5 C10.1229349,14.7761424 9.89907729,15 9.62293492,15 L8.62293492,
          15 C8.34679254,15 8.12293492,14.7761424 8.12293492,14.5 C8.12293492,14.2238576 8.34679254,
          14 8.62293492,14 L9.62293492,14 Z M6.62293492,14 C6.89907729,14 7.12293492,14.2238576 7.12293492,
          14.5 C7.12293492,14.7761424 6.89907729,15 6.62293492,15 L5.62293492,15 C5.34679254,15 5.12293492,
          14.7761424 5.12293492,14.5 C5.12293492,14.2238576 5.34679254,14 5.62293492,14 L6.62293492,14 Z M3.62293492,
          14 C3.89907729,14 4.12293492,14.2238576 4.12293492,14.5 C4.12293492,14.7761424 3.89907729,15 3.62293492,
          15 L3.5,15 C3.12547174,15 2.76169058,14.917249 2.42989362,14.7599154 C2.18038181,14.6416003 2.07402587,
          14.3434177 2.19234099,14.0939059 C2.3106561,13.844394 2.60883874,13.7380381 2.85835055,13.8563532 C3.05693442,
          13.950519 3.27445767,14 3.5,14 L3.62293492,14 Z M2.00730107,12.6493088 C2.0343476,12.9241234 1.83349186,
          13.1688303 1.5586772,13.1958768 C1.28386254,13.2229234 1.03915569,13.0220676 1.01210916,12.747253 C1.00462298,
          12.6711874 1.00059364,12.5945767 1.00004889,12.5141628 L1,11.6844318 C0.999983729,11.4082895 1.22382816,
          11.1844186 1.49997054,11.1844024 C1.77611291,11.1843861 1.99998373,11.4082305 2,11.6843729 L2.00003692,
          12.5106731 C2.00035924,12.5572461 2.00279113,12.6034842 2.00730107,12.6493088 Z M2,9.68440238 C2,
          9.96054475 1.77614237,10.1844024 1.5,10.1844024 C1.22385763,10.1844024 1,9.96054475 1,9.68440238 L1,
          8.68440238 C1,8.40826 1.22385763,8.18440238 1.5,8.18440238 C1.77614237,8.18440238 2,8.40826 2,
          8.68440238 L2,9.68440238 Z M2,6.68440238 C2,6.96054475 1.77614237,7.18440238 1.5,7.18440238 C1.22385763,
          7.18440238 1,6.96054475 1,6.68440238 L1,5.68440238 C1,5.40826 1.22385763,5.18440238 1.5,
          5.18440238 C1.77614237,5.18440238 2,5.40826 2,5.68440238 L2,6.68440238 Z M2,3.68440238 C2,
          3.96054475 1.77614237,4.18440238 1.5,4.18440238 C1.22385763,4.18440238 1,3.96054475 1,3.68440238 L1,3.5 C1,
          3.15251582 1.07122382,2.81411568 1.20742208,2.50169071 C1.3177734,2.24855597 1.61243708,
          2.13280715 1.86557182,2.24315847 C2.11870656,2.3535098 2.23445538,2.64817347 2.12410406,
          2.90130821 C2.0426049,3.088259 2,3.29068432 2,3.5 L2,3.68440238 Z M10,6.00000011 L11.5,
          6.00000011 C12.3284271,6.00000011 13,6.67157299 13,7.50000011 L13,11.5000001 C13,
          12.3284272 12.3284271,13.0000001 11.5,13.0000001 L7.5,13.0000001 C6.67157288,13.0000001 6,12.3284272 6,
          11.5000001 L6,10.0000001 L4.5,10.0000001 C3.67157288,10.0000001 3,9.32842724 3,8.50000011 L3,
          4.50000011 C3,3.67157299 3.67157288,3.00000011 4.5,3.00000011 L8.5,3.00000011 C9.32842712,
          3.00000011 10,3.67157299 10,4.50000011 L10,6.00000011 Z M10,7.00000011 L10,8.50000011 C10,
          9.32842724 9.32842712,10.0000001 8.5,10.0000001 L7,10.0000001 L7,11.5000001 C7,
          11.7761425 7.22385763,12.0000001 7.5,12.0000001 L11.5,12.0000001 C11.7761424,
          12.0000001 12,11.7761425 12,11.5000001 L12,7.50000011 C12,7.22385774 11.7761424,7.00000011 11.5,
          7.00000011 L10,7.00000011 Z M3.3015096,2.01295932 C3.02773387,2.04903503 2.77654963,
          1.8563411 2.74047391,1.58256536 C2.70439819,1.30878962 2.89709213,1.05760539 3.17086787,
          1.02152967 C3.27191536,1.00821454 3.37407089,1.00103878 3.4791892,1.00008931 L3.49773047,
          1.00000515 C3.77387001,0.998751727 3.99874143,1.22159094 3.99999485,1.49773047 C4.00124827,
          1.77387001 3.77840906,1.99874143 3.50226953,1.99999485 L3.48599926,2.00006354 C3.42383449,
          2.00062809 3.36227424,2.00495229 3.3015096,2.01295932 Z M4.5,4.00000011 C4.22385763,4.00000011 4,
          4.22385774 4,4.50000011 L4,8.50000011 C4,8.77614249 4.22385763,9.00000011 4.5,9.00000011 L8.5,
          9.00000011 C8.77614237,9.00000011 9,8.77614249 9,8.50000011 L9,4.50000011 C9,4.22385774 8.77614237,
          4.00000011 8.5,4.00000011 L4.5,4.00000011 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
