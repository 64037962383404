import * as t from 'io-ts';

import { PreparedSearchQuery } from 'common/utils/string-utils';
import { FilterOption, FilterValue } from '../filter-select/interfaces';

interface FilterRequest {
  props: Record<string, string[]>;
  suggestions?: string[];
}

interface SelectedFilterValue {
  key: string;
  value: FilterValue[];
}

interface FilterConfig {
  key: string;
  name: string;
  options: FilterValue[];
  optionsRender?: (option: FilterOption, query: PreparedSearchQuery, isSelected: boolean) => React.ReactNode;
}

interface TreeFilterPanelState {
  filterConfigs: FilterConfig[];
  selectedValue: Record<string, FilterValue[]>;
  lastSelectedFilterKey: string;
  selectedDisplayName: Record<string, FilterValue[]>;
  defaultFilterOptions: FilterConfig[];
  customFilterFunction: (object: any) => boolean;
}

interface StandardClassification {
  code: string;
  name: string;
}

export const FilterResponseC = t.strict(
  {
    recordIds: t.array(t.number),
    suggestions: t.record(t.string, t.array(t.union([t.string, t.null]))),
  },
  'FilterResponse',
);

export type FilterResponse = t.TypeOf<typeof FilterResponseC>;

export {
  TreeFilterPanelState,
  FilterConfig,
  SelectedFilterValue,
  FilterRequest,
  StandardClassification,
};
