import styled from 'styled-components';

const Container = styled.div<{ isCollapsed: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 20px;
  padding-left: 10px;
  background: ${p => p.theme.color.background};
  box-shadow: 0 0 0 0 ${p => p.theme.color.pale};
  border-radius: 10px;
  margin-top: auto;
  cursor: pointer;

  svg {
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }

  > p {
    position: absolute;
    left: 40px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition: ${p => p.theme.duration.s};
    opacity: ${p => p.isCollapsed ? 0 : 1};
    min-width: max-content;
  }

  :hover {
    box-shadow: 0 0 0 2px ${p => p.theme.color.pale};
  }
`;

export const Styled = {
  Container,
};
