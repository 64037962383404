import { RowData } from 'common/components/data-base-table';
import { DrawingsFolderViewInfo } from 'common/components/drawings/interfaces/drawings-file-info';

export const getGroupRow = (entity: DrawingsFolderViewInfo): RowData => {

  return {
    h_isDragSource: true,
    h_isDragTarget: true,
    h_path: entity.id,

    id: entity.id,
    name: entity.properties.name,
  };
};
