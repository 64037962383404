import autobind from 'autobind-decorator';
import React from 'react';

import { KreoButton } from 'common/UIKit';
import { numberUtils } from 'common/utils/number-utils';
import { UserBidPricingStatus } from '../../../enums/user-bid-pricing-status';
import { BidPricingWorkPackage } from '../../../interfaces/bid-pricing/bid-pricing-work-package';

interface Props {
  workpackage?: BidPricingWorkPackage;
  isSubcontractor: boolean;
  isBidPricingActive: boolean;
  onWin?(userId: string, workPackageId: number, constructionCostId: number): void;
}

export class DefaultItem extends React.Component<Props> {
  public render(): React.ReactNode {
    const { isSubcontractor, workpackage } = this.props;
    return (
      <div className='bid-pricing-invitations__inner-row'>
        {!isSubcontractor && <div className='bid-pricing-invitations__subcontractor' />}
        {workpackage && (
          <React.Fragment>
            <div className='bid-pricing-invitations__data cell'>
              {this.makeShort(workpackage.laborCost)}
            </div>
            <div className='bid-pricing-invitations__data cell'>
              {this.makeShort(workpackage.plantCost)}
            </div>
            <div className='bid-pricing-invitations__data cell'>
              {this.makeShort(workpackage.materialCost)}
            </div>
            <div className='bid-pricing-invitations__data cell'>
              {this.makeShort(workpackage.indirectCost)}
            </div>
            <div className='bid-pricing-invitations__data cell total'>
              {this.makeShort(workpackage.totalCost)}
            </div>
            <div className='bid-pricing-invitations__data cell'>
              {this.makeShort(workpackage.duration)}
            </div>
          </React.Fragment>
        )}
        <div className='bid-pricing-invitations__action cell'>
          {!isSubcontractor && <div className='action-buttons'>{this.getButton()}</div>}
        </div>
      </div>
    );
  }

  private makeShort(value: number): string {
    if (!this.props.isSubcontractor && value) {
      return `${numberUtils
        .getNumeralFormatter(value)
        .format('0,0')
        .replace(/,/g, ' ')}`;
    } else {
      return '...';
    }
  }

  @autobind
  private winBidPricing(e: React.MouseEvent<HTMLButtonElement>): void {
    e.stopPropagation();
    if (this.props.onWin) {
      this.props.onWin(null, this.props.workpackage.id, this.props.workpackage.constructionCostId);
    }
  }

  @autobind
  private winnerClick(e: React.MouseEvent<HTMLButtonElement>): void {
    e.stopPropagation();
  }

  private getButton(): React.ReactNode {
    switch (this.props.workpackage.status) {
      case UserBidPricingStatus.Estimated:
        return (
          <KreoButton
            className='table-btn'
            disabled={!this.props.isBidPricingActive}
            mode={'dark'}
            size='medium'
            onClick={this.winBidPricing}
          >
            Select
          </KreoButton>
        );
      case UserBidPricingStatus.Winner:
        return (
          <KreoButton
            className='table-btn'
            disabled={!this.props.isBidPricingActive}
            mode={'success'}
            size='medium'
            onClick={this.winnerClick}
          >
            Selected
          </KreoButton>
        );
      default:
        return;
    }
  }
}
