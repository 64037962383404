import autobind from 'autobind-decorator';
import { Canceler } from 'axios';
import { DrawingsApi } from 'common/components/drawings/api/drawings-api';
import { DrawingsScaleConstant } from 'common/components/drawings/constants/drawings-scale-constants';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsRenderParams, ShortPointDescription } from 'common/components/drawings/interfaces';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DrawingsGeometryEntity } from '../../drawings-geometry-entities';
import {
  DrawingsGeometryAutocompleteEntity, FinishedDrawingElement,
} from '../../drawings-geometry-entities/temporary';
import {
  AiSuggestSettings,
  DrawingsGeometryAutocompleteEntityTypes,
  DrawingsGeometryBaseAutocomplete,
  GetCurrentDrawingCallback,
  NewDrawingSettings,
} from '../../interfaces';


interface DrawingsGeometryAutocompleteConfig {
  layer: paper.Layer;
  getCurrentDrawingInfo: GetCurrentDrawingCallback;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  textRenderParametersObserver: ContextObserver<MeasuresViewSettings>;
  measurementsAutocompleteContext: ContextObserver<AiSuggestSettings>;
  instanceType: DrawingsGeometryAutocompleteEntityTypes;
  onLoaded: (value: boolean) => void;
}

export class DrawingsGeometryAutocomplete implements DrawingsGeometryEntity, DrawingsGeometryBaseAutocomplete {
  private _config: DrawingsGeometryAutocompleteConfig;
  private _currentRequestPoints: ShortPointDescription[];

  private _entity: DrawingsGeometryAutocompleteEntity;
  private _executor: DeferredExecutor = new DeferredExecutor(300);
  private _requestCanceler: Canceler;

  constructor(config: DrawingsGeometryAutocompleteConfig) {
    this._config = config;
    this._config.measurementsAutocompleteContext.subscribe(this.updateAutocompleteStatus);
  }

  public destroy(): void {
    this._currentRequestPoints = null;
    this._config.measurementsAutocompleteContext.unsubscribe(this.updateAutocompleteStatus);
    this.destroyEntity();
  }

  public canUseAutocompleteGeometry(): boolean {
    const settings = this._config.measurementsAutocompleteContext.getContext();
    const isSettingEnabled = this.canUseAutocomplete(settings);
    return isSettingEnabled && this._entity ? this._entity.isValid : false;
  }

  public updateGeometry(geometry: ShortPointDescription[]): void {
    this._currentRequestPoints = geometry;
    this.destroyEntity();
    if (!this.canUseAutocomplete(this._config.measurementsAutocompleteContext.getContext())) {
      this._executor.reset();
      return;
    }
    this._executor.execute(this.startSearch, geometry);
  }

  public convert(params: NewDrawingSettings): FinishedDrawingElement {
    return this._entity.convert(params);
  }

  @autobind
  private startSearch(points: ShortPointDescription[]): void {
    if (!points.length) {
      return;
    }
    const {
      pageNumber,
      pdfId,
      paperSize,
      width,
      height,
    } = this._config.getCurrentDrawingInfo();
    if (this._requestCanceler) {
      this._requestCanceler();
    }
    const [promise, canceler] = DrawingsApi.measurementAutocomplete({
      fileId: pdfId,
      pageIdx: pageNumber,
      scale: this._config.textRenderParametersObserver.getContext().scale,
      paperSize: paperSize || DrawingsScaleConstant.DEFAULT_FORMAT,
      type: this._config.instanceType,
      points,
      userScreen: [0, 0, width, height],
    });
    this._config.onLoaded(false);
    this._requestCanceler = canceler;
    promise.then(x => {
      const context = this._config.measurementsAutocompleteContext.getContext();
      this._requestCanceler = null;
      if (points !== this._currentRequestPoints || !this.canUseAutocomplete(context)) {
        return;
      }
      this.renderAutocompleteResult(x.points);
      this._config.onLoaded(true);
    });
  }

  private renderAutocompleteResult(points: ShortPointDescription[]): void {
    if (!points.length) {
      return;
    }
    this._entity = new DrawingsGeometryAutocompleteEntity({
      points,
      layer: this._config.layer,
      renderParametersContextObserver: this._config.renderParametersContextObserver,
      type: this._config.instanceType,
    });
    this._config.onLoaded(false);
  }

  private destroyEntity(): void {
    if (this._entity) {
      this._entity.destroy();
      this._entity = null;
    }
  }

  @autobind
  private updateAutocompleteStatus(settings: AiSuggestSettings): void {
    if (!this.canUseAutocomplete(settings)) {
      this.destroyEntity();
    } else if (this._currentRequestPoints) {
      this.startSearch(this._currentRequestPoints);
    }
  }

  private canUseAutocomplete({ enabled: isEnabled, canUseAiSuggestForLines }: AiSuggestSettings): boolean {
    if (!isEnabled) {
      return false;
    }
    return canUseAiSuggestForLines || this._config.instanceType !== DrawingsInstanceType.Polyline;
  }
}
