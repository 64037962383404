import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';


import './index.scss';

import { UserMap } from 'common/interfaces/people';
import { State } from 'common/interfaces/state';
import { Fab as Fb, KreoDialogActions, KreoIconMenuCollapse } from 'common/UIKit';
import { TIconComment } from '../../../../icons';
import { ViewerActions } from '../../actions';
import { CommentModel, SidePanelApi } from '../../interfaces';
import { Comment } from '../comment';
import { Comments as Comms } from '../comments';
import { PropertiesTree as PTree } from '../properties-tree';
import { SidePanel } from './side-panel';


const PropertiesTree: any = PTree;
const Fab: any = Fb;
const Comments: any = Comms;


interface StateProps {
  comment: any;
}

interface DispatchProps {
  showTreeMenu: () => void;
  onResolve: (comment: any, value: any) => void;
  onRemoveComment: (id: number) => void;
  onShowResolve: () => void;
  openDialog: (name: string) => void;
}

interface OwnProps {
  selectedId: any;
  commentaries: CommentModel[];
  commentsLevel: number;
  userId: string;
  showLeft: boolean;
  showRight: boolean;
  isEngineEvent: boolean;
  engineMove: boolean;
  fullscreen: boolean;
  companyId: number;
  projectId: number;
  markerMode: boolean;
  showNewComment: boolean;
  withresolved: boolean;
  peoplemap: UserMap;
  onAddSelect: (item: any, identificator: any) => void;
  onDeselectItem: (item: any, identificator: any) => void;
  onItemSelect: (item: any, identificator: any) => void;
  onCreateNewComment: (value: any) => void;
  onItemsShow: (item: any) => void;
  onItemHide: (item: any) => void;
  onItemGhost: (item: any) => void;
  getTreeList: (ref: any) => void;
  toState: (state: any) => void;
  goToComment: (comment: any) => void;
  selectComment: (index: any) => void;
  onUpdate: () => void;
  onMarkerMode: () => void;
  showNewCommentField: () => void;
  showComment: () => void;
  backToComments: () => void;
  onCreateSubcommentScreen: (api: any) => void;
  onAddSubcomment: (id: any, value: any, parent: any) => void;
}

interface Props extends React.Props<MainLayoutComponent>, OwnProps, StateProps, DispatchProps {

}


class MainLayoutComponent extends React.Component<Props> {
  private main: any = null;
  private leftSize: number = 320;
  private rightSize: number = 340;
  private resizeLeft: boolean = false;
  private resizeRight: boolean = false;
  private leftApi: SidePanelApi = null;
  private rightApi: SidePanelApi = null;

  public componentDidMount(): void {
    this.main.style.width = `calc(100% - ${this.leftSize + this.rightSize}px)`;
  }

  public shouldComponentUpdate(nextProps: Props): boolean {
    let minus = 0;
    if (nextProps.showRight) {
      minus += this.rightSize;
    }
    if (nextProps.showLeft) {
      minus += this.leftSize;
    }

    if (this.props.showLeft !== nextProps.showLeft ||
      this.props.showRight !== nextProps.showRight) {
      this.main.style.width = `calc(100% - ${minus}px)`;
    }
    return true;
  }

  public render(): JSX.Element {
    const classes = ['viewer-resizeble-layout'];
    if (this.props.fullscreen) {
      classes.push('fullscreen');
    }
    return (
      <div
        className={classes.join(' ')}
        onMouseMove={this.mouseMove}
        onMouseUp={this.mouseUp}
      >
        <SidePanel
          defaultSize={320}
          show={this.props.showLeft}
          onResize={this.onResizeLeft}
          onApiProccessed={this.saveLeftApi}
        >
          <PropertiesTree
            onAddSelect={this.props.onAddSelect}
            onDeselectItem={this.props.onDeselectItem}
            isEngineEvent={this.props.isEngineEvent}
            enginemove={this.props.engineMove}
            onShow={this.props.onItemsShow}
            onHide={this.props.onItemHide}
            selectedId={this.props.selectedId}
            showTree={this.props.showTreeMenu}
            onSelect={this.props.onItemSelect}
            onGhost={this.props.onItemGhost}
            onUpdate={this.props.onUpdate}
            getTreeList={this.props.getTreeList}
          />
        </SidePanel>
        <div className='main-panel' ref={this.setMain}>
          {this.getRightTopKeys()}
          {this.props.children}
          {this.getLeftTopButtons()}
        </div>
        <SidePanel
          onApiProccessed={this.saveRightApi}
          onResize={this.onResizeRight}
          defaultSize={340}
          show={this.props.showRight}
        >
          {this.props.commentsLevel === 0 ? (
            <Comments
              withresolved={this.props.withresolved}
              onToStateClick={this.props.toState}
              commentaries={this.props.commentaries}
              companyId={this.props.companyId}
              projectId={this.props.projectId}
              onExitClick={this.props.showComment}
              showNewComment={this.props.showNewComment}
              onCreateNewClick={this.props.onCreateNewComment}
              onCommentClick={this.props.goToComment}
              enginemove={this.props.engineMove}
              onUpdate={this.props.onUpdate}
              selectComment={this.props.selectComment}
              onResolve={this.props.onShowResolve}
              peopleMap={this.props.peoplemap}
              onShowNewCommentField={this.showNewCommentField}
              onMarkMode={this.markMode}
              markerMode={this.props.markerMode}
            />
          ) : (
              <Comment
                user={this.props.userId}
                engineMove={this.props.engineMove}
                onBackClick={this.props.backToComments}
                comment={this.props.comment}
                companyId={this.props.companyId}
                projectId={this.props.projectId}
                onCreateScreen={this.props.onCreateSubcommentScreen}
                onAddComment={this.props.onAddSubcomment}
                onRemoveComment={this.props.onRemoveComment}
                onUpdate={this.props.onUpdate}
                onResolve={this.props.onResolve}
                peopleMap={this.props.peoplemap}
                toEngineState={this.props.toState}
              />
          )}
        </SidePanel>
      </div>
    );
  }

  @autobind
  private saveLeftApi(api: SidePanelApi): void {
    this.leftApi = api;
  }

  @autobind
  private saveRightApi(api: SidePanelApi): void {
    this.rightApi = api;
  }

  @autobind
  private setMain(ref: any): void {
    this.main = ref;
  }

  @autobind
  private showNewCommentField(): void {
    if (this.props.markerMode) {
      this.props.onMarkerMode();
      if (this.props.showNewComment) {
        this.props.showNewCommentField();
      }
    }
    this.props.showNewCommentField();
  }

  @autobind
  private markMode(): void {
    if (this.props.showNewComment) {
      this.props.showNewCommentField();
    }
    if (this.props.markerMode && this.props.showNewComment) {
      this.props.onMarkerMode();
    }
    this.props.onMarkerMode();
  }


  @autobind
  private onShowComment(e: Event): void {
    e.stopPropagation();
    this.props.showComment();
  }


  @autobind
  private onResizeLeft(resize: boolean): void {
    this.resizeRight = false;
    this.resizeLeft = resize;
  }

  @autobind
  private onResizeRight(resize: boolean): void {
    this.resizeLeft = false;
    this.resizeRight = resize;

  }


  @autobind
  private mouseMove(e: React.MouseEvent<HTMLDivElement>): void {
    if (this.resizeLeft) {
      const xPosition = e.pageX - e.currentTarget.getBoundingClientRect().left;
      if (this.leftApi.setSize(xPosition)) {
        this.leftSize = xPosition;
        let minus = this.leftSize;
        if (this.props.showRight) {
          minus += this.rightSize;
        }
        this.main.style.width = `calc(100% - ${minus}px)`;
      }
    } else if (this.resizeRight) {
      const xPosition =
        e.currentTarget.offsetWidth - (e.pageX - e.currentTarget.getBoundingClientRect().left);
      if (this.rightApi.setSize(xPosition)) {
        this.rightSize = xPosition;
        let minus = this.rightSize;
        if (this.props.showLeft) {
          minus += this.leftSize;
        }
        this.main.style.width = `calc(100% - ${minus}px)`;
      }
    }
  }

  @autobind
  private mouseUp(): void {
    if (this.resizeLeft) {
      this.leftApi.deactivate();
    }
    this.resizeLeft = false;
    if (this.resizeRight) {
      this.rightApi.deactivate();
    }
    this.resizeRight = false;
  }

  private getRightTopKeys(): JSX.Element {
    if (!this.props.showRight) {
      return (
        <Fab
          top={true}
          color='white'
          floatHorizontal={30}
          size={50}
          onClick={this.onShowComment}
          controlName='open-comments-sidebar-button'
        >
          <img src={TIconComment} />
        </Fab>
      );
    }
  }


  private getLeftTopButtons(): JSX.Element {
    if (!this.props.showLeft) {
      return (
        <Fab
          key={0}
          left={true}
          top={true}
          color='white'
          floatHorizontal={30}
          size={50}
          onClick={this.props.showTreeMenu}
          controlName='open-model-browser-sidebar-button'
        >
          <KreoIconMenuCollapse />
        </Fab>
      );
    }
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    comment: state.viewersets.comment,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    showTreeMenu: () => {
      dispatch(ViewerActions.showHideTree());
    },
    onResolve: (comment: any, value: any) => {
      dispatch(ViewerActions.resolveComment(comment, value));
    },
    onRemoveComment: (id: number) => {
      dispatch(ViewerActions.removeComment(id));
    },
    onShowResolve: () => {
      dispatch(ViewerActions.withResolved());
    },
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
  };
}

const connecter = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps);
export const MainLayout = connecter(MainLayoutComponent);
