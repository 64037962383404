/* eslint-disable indent */
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  TextField,
} from '@material-ui/core';
import { FormHelperTextClassKey } from '@material-ui/core/FormHelperText';
import { InputClassKey } from '@material-ui/core/Input';
import { InputAdornmentClassKey } from '@material-ui/core/InputAdornment';
import { InputBaseClassKey, InputBaseComponentProps } from '@material-ui/core/InputBase';
import { InputLabelClassKey } from '@material-ui/core/InputLabel';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import './material-input.scss';

import { numberUtils } from 'common/utils/number-utils';
import { KreoIconDelCross, KreoIconSearch } from '../icons';
import { AdornmentProps, MaterialComponentProps, MaterialComponentType } from './interfaces';

export type MaterialInputType = 'text' | 'password' | 'email' | 'date' | 'time';
export type MaterialInputValueType = 'float' | 'integer' | 'string';

export const DATE_FORMAT = 'D MMM YYYY';
export const SHORT_DATE_FORMAT = 'D MMM';
export const TIME_FORMAT = 'h:mm A';

export interface MaterialInputProps extends MaterialComponentProps<React.ReactText> {
  type?: MaterialInputType;
  defaultValue?: React.ReactText;
  valueType?: MaterialInputValueType;
  precision?: number;
  required?: boolean;
  positive?: boolean;
  min?: React.ReactText;
  max?: React.ReactText;
  maxLength?: number;
  isCleanZero?: boolean;
  multiLine?: boolean;
  rows?: number;
  autoFocus?: boolean;
  inputComponent?: React.ComponentType<InputBaseComponentProps> | string;
  displayCharactersLeft?: boolean;
  isBlocked?: boolean;
  showClearButton?: boolean;
  searchType?: boolean;
  textAlignRight?: boolean;
  sizeSmall?: boolean;
  autocomplete?: string;
  title?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  displayPattern?: (value: string) => string;
  onFocus?: (event: React.FocusEvent, newValue?: string) => void;
  onChange?: (event: React.ChangeEvent, newValue?: string) => void;
  onBlur?: (event: React.FocusEvent, newValue?: string) => void;
  onKeyPress?: (event: React.KeyboardEvent<{}>) => void;
}

interface MaterialInputState {
  /**
   * @description is component in focused
   */
  isFocused: boolean;
  /**
   * @description value that shown while component is focused
   */
  value: string;
  clearBtnMouseHover: boolean;
}

// -------
// REGEX

const FLOAT_NUMBER = /^(|(-)?[0-9]*(\.|\,)?[0-9]*)$/;
const INTEGER_NUMBER = /^((-)?[0-9]*)$/;
const POSITIVE_FLOAT_NUMBER = /^(|[0-9]*(\.|\,)?[0-9]*)$/;
const POSITIVE_INTEGER_NUMBER = /^([0-9]*)$/;
/**
 * @description Detect all superfluous zeros
 */
const FIRST_ZEROS = /^[0]*(?=[0-9)])(?=[0\(.|,)1-9)])/;

// END-REGEX

const inputBaseClasses: Partial<ClassNameMap<InputBaseClassKey>> = {
  root: 'custom-material-input',
  input: 'custom-material-input__native-input',
};
const helperTextClasses: Partial<ClassNameMap<FormHelperTextClassKey>> = {
  root: 'custom-material-input__bottom-information',
};
const textFieldClasses: Partial<ClassNameMap<InputClassKey>> = {
  root: 'custom-material-input',
  input: 'custom-material-input__kreo-input',
  underline: 'custom-material-input__kreo-underline',
};
const labelClasses: Partial<ClassNameMap<InputLabelClassKey>> = {
  root: 'custom-material-input__kreo-label',
  shrink: 'custom-material-input__kreo-label--floated',
};
const inputAdornmentClasses: Partial<ClassNameMap<InputAdornmentClassKey>> = {
  root: 'custom-material-input__adornment',
};

export class MaterialInput extends React.Component<MaterialInputProps, MaterialInputState> {
  public static defaultProps: Partial<MaterialInputProps> = {
    rows: 2,
    type: 'text',
    valueType: 'string',
    value: '',
    positive: true,
    isFloatingLabel: true,
    displayUnderline: true,
    isCleanZero: true,
    displayedType: MaterialComponentType.Standard,
    displayBottomInformation: false,
    inputComponent: 'input',
    adornmentPosition: 'start',
  };

  private inputRef: HTMLInputElement | HTMLTextAreaElement;
  private regex: RegExp;
  private firstClean: boolean;
  private firstValue: string;

  constructor(props: MaterialInputProps) {
    super(props);

    if (props.valueType === 'float') {
      this.regex = props.positive ? POSITIVE_FLOAT_NUMBER : FLOAT_NUMBER;
    } else if (props.valueType === 'integer') {
      this.regex = props.positive ? POSITIVE_INTEGER_NUMBER : INTEGER_NUMBER;
    }

    const value = MaterialInput.getStringFromValue(props.value);
    this.state = {
      isFocused: false,
      value,
      clearBtnMouseHover: false,
    };
    this.firstClean = false;
  }

  public static getDerivedStateFromProps(props: MaterialInputProps, state: MaterialInputState): MaterialInputState {
    const value = MaterialInput.getStringFromValue(props.value);

    if (state.value !== value) {
      return { ...state, value };
    }

    return null;
  }

  public focus(): void {
    this.inputRef.focus();
  }

  public setSelectionRange(start: number, end: number): void {
    this.inputRef.setSelectionRange(start, end);
  }

  public getValue(): string {
    return this.inputRef.value;
  }

  public render(): React.ReactNode {
    const displayedValue = this.getDisplayedValue();
    const helperText = this.props.displayBottomInformation ? (
      this.props.error ? this.props.error : this.props.hint
    ) : null;
    const hasError = !!this.props.error;
    const title = typeof this.props.title === 'string'
      ? this.props.title
      : this.props.type !== 'password'
        ? displayedValue
        : null;
    const commonProps = {
      id: this.props.id,
      name: this.props.name,
      placeholder: this.props.placeholder,
      value: displayedValue,
      autoComplete: this.props.autocomplete,
      title,
      type: this.props.type,
      disabled: this.props.disabled,
      required: this.props.required,
      multiline: this.props.multiLine,
      rows: this.props.rows,
      error: hasError,
      defaultValue: this.props.defaultValue,
      onChange: !this.props.isBlocked ? this.onChange : null,
      autoFocus: this.props.autoFocus,
      onKeyPress: this.props.onKeyPress,
      inputRef: this.makeInputRef,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      fullWidth: true,
      inputProps: {
        maxLength: this.props.maxLength,
        min: this.props.min,
        max: this.props.max,
      },
    };
    let adornmentProps: AdornmentProps;

    if (this.props.adornment) {
      const adornment = (
        <InputAdornment
          position={this.props.adornmentPosition}
          classes={inputAdornmentClasses}
        >
          {this.props.adornment}
        </InputAdornment>
      );
      adornmentProps = { };
      if (this.props.adornmentPosition === 'start') {
        adornmentProps.startAdornment = adornment;
      } else {
        adornmentProps.endAdornment = adornment;
      }
    }

    return (
      <React.Fragment>
        {
          this.props.displayedType === MaterialComponentType.Standard ?
            (
          <TextField
            {...commonProps}
            className={
              classNames('custom-material-input__wrap',
                         { 'custom-material-input__wrap--has-bottom': this.props.displayBottomInformation },
                         { 'custom-material-input__wrap--focus': this.state.isFocused },
                         { 'custom-material-input__wrap--error': hasError },
                         { 'disabled': this.props.disabled },
                         this.props.className)
            }
            label={this.props.label}
            margin='none'
            helperText={helperText}
            InputProps={{
              disableUnderline: !this.props.displayUnderline,
              classes: textFieldClasses,
              inputComponent: this.props.inputComponent as any,
              ...adornmentProps,
            }}
            InputLabelProps={{ shrink: !this.props.isFloatingLabel ? true : undefined, classes: labelClasses }}
            FormHelperTextProps={{ classes: helperTextClasses }}
            onClick={this.props.onClick}
          />
            ) :
            (
        <FormControl
          fullWidth={true}
          className={
            classNames('custom-material-input__wrap',
                       { 'custom-material-input__wrap--has-bottom': this.props.displayBottomInformation },
                       { 'custom-material-input__wrap--small-size': this.props.sizeSmall },
                       { 'custom-material-input__wrap--error': hasError },
                       { 'custom-material-input__wrap--align-right': this.props.textAlignRight },
                       { 'disabled': this.props.disabled },
                       { 'custom-material-input__wrap--search': this.props.searchType },
                       this.props.className)
          }
          onClick={this.props.onClick}
        >
          {this.props.searchType && <KreoIconSearch />}
          {this.props.showClearButton && this.state.isFocused && displayedValue.length ? (
            <button
              className='custom-material-input__clear-btn'
              onMouseEnter={this.onClearBtnMouseHover}
              onMouseLeave={this.onClearBtnMouseLeave}
            >
              <KreoIconDelCross />
            </button>
          ) : null}
          <InputBase {...commonProps} classes={inputBaseClasses} {...adornmentProps} />
          {helperText && <FormHelperText error={hasError} classes={helperTextClasses}>{helperText}</FormHelperText>}
        </FormControl>
            )
        }
        {
          this.props.displayCharactersLeft && this.props.maxLength &&
          <span className='custom-material-input__characters-left'>
            {`${displayedValue.length}/${this.props.maxLength}`}
          </span>
        }
      </React.Fragment>
    );
  }

  private static getNumberFromValue(value: string, valueType: MaterialInputValueType): number {
    if (valueType === 'float') {
      return parseFloat(value);
    } else if (valueType === 'integer') {
      return parseInt(value, 10);
    }

    return NaN;
  }

  private static getStringFromValue(value?: React.ReactText): string {
    return value ? value.toString() : '';
  }

  private getDisplayedValue(): string {
    const { isFocused, value } = this.state;

    const isNumberType = this.props.valueType === 'float' || this.props.valueType === 'integer';
    const dispValue = isFocused ? (
      this.firstClean &&
      isNumberType &&
      this.props.isCleanZero &&
      MaterialInput.getNumberFromValue(value, this.props.valueType) === 0 ? '' : value
    ) : (
      this.props.displayPattern ? this.props.displayPattern(value) : value
    );
    this.firstClean = false;

    return dispValue;
  }

  @autobind
  private makeInputRef(ref: HTMLInputElement | HTMLTextAreaElement): void {
    if (!this.props.onKeyPress) {
      if (ref) {
        ref.addEventListener('keydown', this.keyDownListener);
      } else if (this.inputRef) {
        this.inputRef.removeEventListener('keydown', this.keyDownListener);
      }
    }
    this.inputRef = ref;
  }

  @autobind
  private keyDownListener(e: KeyboardEvent): void {
    if (e.keyCode === 13) {
      if (this.props.multiLine) {
        e.stopPropagation();
      } else {
        this.inputRef.blur();
      }
    }
  }

  @autobind
  private onFocus(event: React.FocusEvent): void {
    this.firstValue = this.state.value;
    this.firstClean = true;
    this.setState({ isFocused: true });

    if (this.props.onFocus) {
      this.props.onFocus(event, this.state.value);
    }
  }

  @autobind
  private onClearBtnMouseHover(): void {
    this.setState({ clearBtnMouseHover: true });
  }

  @autobind
  private onClearBtnMouseLeave(): void {
    this.setState({ clearBtnMouseHover: false });
  }

  @autobind
  private onBlur(event: React.FocusEvent): void {
    this.inputRef.removeEventListener('keydown', (e: KeyboardEvent) => e.preventDefault());
    if (this.state.clearBtnMouseHover) {
      this.setState({ clearBtnMouseHover: false });
      this.props.onChange(event, '');
      this.focus();
      return;
    }
    let newValue = MaterialInput.getStringFromValue(this.props.value);
    if (newValue !== this.firstValue) {
      if (this.props.valueType === 'float' || this.props.valueType === 'integer') {
        let parsedValue = MaterialInput.getNumberFromValue(newValue, this.props.valueType);

        if (isNaN(parsedValue) || parsedValue === 0) {
          newValue = this.props.valueType === 'float' ? (this.props.precision ? numberUtils
            .getNumeralFormatter(0)
            .format(`0.${'0'.repeat(this.props.precision)}`) : '0.0') : '0';
        } else {
          if (this.props.valueType === 'float' && this.props.precision) {
            parsedValue = parseFloat(parsedValue.toFixed(this.props.precision));
          }

          const { min, max } = this.props;

          if (min && parsedValue < (min as number)) parsedValue = min as number;
          else if (max && parsedValue > (max as number)) parsedValue = max as number;

          if (this.props.valueType === 'float' && this.props.precision) {
            newValue = numberUtils
              .getNumeralFormatter(parsedValue)
              .format(`0.${'0'.repeat(this.props.precision)}`);
          } else {
            newValue = parsedValue.toString();
          }
        }
      } else if (this.props.type === 'date') {
        let valueAsDate = moment(newValue, DATE_FORMAT).toDate();
        const max = this.props.max && moment(this.props.max, DATE_FORMAT).toDate();
        const min = this.props.min && moment(this.props.min, DATE_FORMAT).toDate();

        if (max && valueAsDate.getTime() > max.getTime()) {
          valueAsDate = max;
        } else if (min && valueAsDate.getTime() < min.getTime()) {
          valueAsDate = min;
        }
        newValue = moment(valueAsDate).format(DATE_FORMAT);
      }

      if (this.props.value !== newValue && this.props.onChange) {
        this.props.onChange(event, newValue);
      }
    }

    if (this.props.onBlur) {
      this.props.onBlur(event, newValue);
    }
    this.setState({ isFocused: false });
  }

  @autobind
  private onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    let newValue = event.target.value;
    let valid = true;

    if (this.props.valueType === 'float' || this.props.valueType === 'integer') {
      newValue = newValue.replace(',', '.').replace(FIRST_ZEROS, '');

      if (!(this.props.multiLine || this.regex.test(newValue))) {
        valid = false;
      }
    }

    if (valid && this.state.value !== newValue && this.props.onChange) {
      this.props.onChange(event, newValue);
    }
  }
}

export const MaterialInputField: React.FC<MaterialInputProps> = (
  props: WrappedFieldProps & MaterialInputProps,
): JSX.Element => {
  const {
    input,
    meta: { submitFailed, error },
    ...rest } = props;

  const onChange = (_event: React.ChangeEvent<{}>, value: string): void => {
    input.onChange(value);
  };

  return (
    <MaterialInput
      {...input}
      error={submitFailed && error}
      onChange={onChange} // eslint-disable-line react/jsx-no-bind
      {...rest}
    />
  );
};
