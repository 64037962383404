import autobind from 'autobind-decorator';
import * as React from 'react';


import { MultiLevelDropDownMenu } from 'common/components/multi-level-drop-down-menu';
import {
  MultilevelSelectOptionData,
} from 'common/components/multi-level-drop-down-menu/interfaces/multi-level-select-option-data';
import { KreoIconCreateNew, WaitButton } from 'common/UIKit';
import { QtoGenerateReportStatus } from '../../enums/qto-generate-report-status';
import { QtoTemplateInfo } from '../../interfaces/quantity-take-off';
import { QtoProjectReport } from '../../interfaces/quantity-take-off/quantity-take-off-project-reports';
import { QtoReportSetting } from '../../interfaces/quantity-take-off/quantity-take-off-report-generator';


const CREATE_NEW_REPORT_KEY = 'CREATE_NEW_REPORT_KEY';
const GENERATE_DEFAULT_REPORT = 'GENERATE_DEFAULT_REPORT';
const COPY_REPORT = 'COPY_REPORT';
const CREATE_REPORT_FROM_TEMPLATE = 'CREATE_REPORT_FROM_TEMPLATE';

interface Props {
  isOnlyGenerateReport?: boolean;
  defaultReportNames: QtoReportSetting[];
  onNewReportCreate: () => void;
  onGenerateDefaultReport: (reportKey: string) => void;
  onReportCopy: (projectId: number, reportId: number) => void;
  className: string;
  reportsForCopy: QtoProjectReport[];
  onCreateViaTemplate: (templateId: number) => void;
  templatesForCreate: QtoTemplateInfo[];
  reportCreationStatus: QtoGenerateReportStatus;
  onClickButton: () => void;
}

interface ProjectWithPayloadId { projectId?: number; id: number }

type SelectionEventHandler = (value: undefined | string | ProjectWithPayloadId) => void;

interface Option extends MultilevelSelectOptionData<Option> { }

export class QtoAddReportMenu extends React.Component<Props> {
  private selectionHandlers: Record<string, SelectionEventHandler> = {
    [CREATE_NEW_REPORT_KEY]: this.props.onNewReportCreate,
    [GENERATE_DEFAULT_REPORT]: this.props.onGenerateDefaultReport,
    [COPY_REPORT]: this.onReportCopy,
    [CREATE_REPORT_FROM_TEMPLATE]: this.onCreateViaTemplate,
  };

  public render(): JSX.Element {
    const isWaiting = this.props.reportCreationStatus !== QtoGenerateReportStatus.NoActions;
    return (
      <MultiLevelDropDownMenu
        disabled={isWaiting}
        onSelect={this.onSelect}
        options={this.getOptions()}
        value={null}
        className={this.props.className}
      >
        <WaitButton
          isWaiting={isWaiting}
          caption='Create'
          tooltip='Create report'
          waitTooltip={`${this.props.reportCreationStatus} report`}
          waitCaption={this.props.reportCreationStatus}
          icon={<KreoIconCreateNew />}
          size='medium'
          rounded={true}
          mode='action'
          onClick={this.props.onClickButton}
        />
      </MultiLevelDropDownMenu>
    );
  }

  private getOptions(): Array<MultilevelSelectOptionData<Option>> {
    const { isOnlyGenerateReport, reportsForCopy, defaultReportNames, templatesForCreate } = this.props;
    // костыль
    if (isOnlyGenerateReport) {
      return this.getDefaultReportsGroups(defaultReportNames);
    }


    return [
      {
        name: 'Create New Report',
        value: JSON.stringify({ type: CREATE_NEW_REPORT_KEY }),
        children: null,
        isSelectable: true,
      },
      {
        name: 'Generate Default Report',
        children: this.getDefaultReportsGroups(defaultReportNames),
      },
      {
        name: 'Copy Report From',
        children: reportsForCopy.map(x => ({
          name: x.name,
          children: x.reports.map(c => ({
            name: c.name,
            value: JSON.stringify({
              type: COPY_REPORT,
              value: {
                id: c.id,
                projectId: x.id,
              },
            }),
            isSelectable: true,
            children: null,
          })),
          isSelectable: false,
        })),
      },
      {
        name: 'Create Report Based On The Template',
        isSelectable: false,
        children: templatesForCreate.map(x => ({
          name: x.name,
          isSelectable: true,
          value: JSON.stringify({
            type: CREATE_REPORT_FROM_TEMPLATE,
            value: {
              id: x.id,
            },
          }),
        })),
      },
    ];
  }

  @autobind
  private getDefaultReportsGroups(defaultReportNames: QtoReportSetting[]): Array<MultilevelSelectOptionData<Option>> {
    if (!defaultReportNames) {
      return;
    }

    const reportGroupMap: Record<string, QtoReportSetting[]> = {};
    const reportsWithoutGroup = [];
    defaultReportNames.forEach(x => {
      if (x.group) {
        reportGroupMap[x.group] = reportGroupMap[x.group] || [];
        reportGroupMap[x.group].push(x);
      } else {
        reportsWithoutGroup.push(x);
      }
    });

    return Object.entries(reportGroupMap)
      .map(([group, reports]) => this.getDefaultReportGroup(group, reports))
      .concat(reportsWithoutGroup.map(this.getDefaultReportElement));
  }

  @autobind
  private getDefaultReportGroup(
    groupName: string, defaultReportNames: QtoReportSetting[],
  ): MultilevelSelectOptionData<Option> {
    return {
      name: groupName,
      children: defaultReportNames.map(this.getDefaultReportElement),
      isSelectable: false,
    };
  }

  private getDefaultReportElement(defaultReportName: QtoReportSetting): MultilevelSelectOptionData<Option> {
    return {
      name: defaultReportName.name,
      value: JSON.stringify({ type: GENERATE_DEFAULT_REPORT, value: defaultReportName.type }),
      children: null,
      isSelectable: true,
    };
  }

  @autobind
  private onCreateViaTemplate(value: { id: number }): void {
    this.props.onCreateViaTemplate(value.id);
  }

  @autobind
  private onReportCopy(value: { projectId: number, id: number }): void {
    this.props.onReportCopy(value.projectId, value.id);
  }

  @autobind
  private onSelect(value: string): void {
    const selectionValue = JSON.parse(value);
    this.selectionHandlers[selectionValue.type](selectionValue.value);
  }
}
