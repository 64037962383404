import { Constants, HotKeys, IconButton, Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import { TextInput } from 'common/components/inputs';
import { ConstantFunctions } from 'common/constants/functions';
import { StringUtils } from 'common/utils/string-utils';
import { DNDSourceProps, withDragSourceWrapper } from 'common/utils/with-drag/with-drag-source-wrapper';
import { SINGLE_SELECT_PROPERTY_DND_TYPE } from '../../dnd-type';
import { Styled } from './styled';

interface OwnProps {
  index: number;
  value: string;
  type: string;
  isAutoFocus: boolean;
  onChange: (index: number, value: string) => void;
  onRemove: (index: number) => void;
  onEnter: () => void;
}

interface Props extends OwnProps, DNDSourceProps {
}

const specDrag = {
  beginDrag: (props: Props) => {
    return { value: props.index };
  },
  canDrag: () => true,
};

class SingleSelectValueComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return (
      <Styled.SingleSelectValueContainer>
        <Styled.DnDIcon />
        <HotKeys
          keyMap={Constants.KeyMaps.ENTER_ESCAPE}
          handlers={{ escape: ConstantFunctions.doNothing, enter: this.props.onEnter }}
        >
          <TextInput
            value={this.props.value}
            onBlur={this.changeValue}
            isAutoFocus={this.props.isAutoFocus}
          />
        </HotKeys>
        <IconButton
          Icon={Icons.CloseSmall}
          width={10}
          height={10}
          iconHeight={10}
          onClick={this.remove}
        />
      </Styled.SingleSelectValueContainer>
    );
  }

  @autobind
  private changeValue(value: string): void {
    if (this.props.type === 'number') {
      const clearedValue = StringUtils.removeNonNumericSymbols(value);
      if (clearedValue === '-' || clearedValue === '+') {
        this.props.onChange(this.props.index, clearedValue);
      } else {
        const convertedValue = Number(clearedValue);
        if (!Number.isNaN(convertedValue)) {
          this.props.onChange(this.props.index, clearedValue);
        } else if (clearedValue.length < this.props.value.length) {
          this.props.onChange(this.props.index, clearedValue);
        }
        this.props.onChange(this.props.index, clearedValue);
      }
    } else {
      this.props.onChange(this.props.index, value);
    }
  }

  @autobind
  private remove(): void {
    this.props.onRemove(this.props.index);
  }
}

export const SingleSelectValue = withDragSourceWrapper(
  SingleSelectValueComponent,
  specDrag,
  SINGLE_SELECT_PROPERTY_DND_TYPE,
);
