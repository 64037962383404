import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SubscriptionType } from 'common/constants/subscription';
import { SubscriptionActions } from '../../../actions/creators';

export const useLoadSubscriptionEffect = (): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SubscriptionActions.getSubscriptionPlansRequest(SubscriptionType.Takeoff2d));
    dispatch(SubscriptionActions.fetchOwnCompanyBillingInfo(SubscriptionType.Takeoff2d));
  }, []);
};
