import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { InputSmallStyled } from 'common/components/inputs';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const InputGroup = styled.div<InputHTMLAttributes<HTMLDivElement>>`
  ${InputSmallStyled.SharedStyle}
  word-break: break-all;
  background-color: ${p => p.theme.color.background};
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  outline: 0;
  font-size: 14px;
  line-height: 30px;
  margin-right: 5px;
  cursor: text;
  row-gap: 10px;
  z-index: 1;
  transition: background-color ${p => p.theme.duration.s}, box-shadow ${p => p.theme.duration.s};
  padding: 10px 10px 10px 8px;
  > span {
    min-height: 30px;
    min-width: 3.6px;
  }

  &:empty:before {
    content: attr(placeholder);
    color: ${p => p.theme.color.disabled};
  }
`;


export const Styled = {
  Container,
  InputGroup,
};
