export abstract class RefHandler {
  protected state: Record<string, string[]>;
  constructor(state: Record<string, string[]>) {
    this.state = { ...state };
  }

  public abstract removeRefs(groups: Record<string, string>, cellId: string): void;
  public abstract addRefs(groups: Record<string, string>, cellId: string): void;
  public getResultState(): Record<string, string[]> {
    return this.state;
  }
}
