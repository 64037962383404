import styled from 'styled-components';

const Value = styled.div`
  width: auto;
  min-width: 20px;
  background-color: ${p => p.theme.color.turquoise};
  color: ${p => p.theme.color.white};
  text-align: center;
  border-radius: 6px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  display: flex;
`;

export const Styled = {
  Value,
};
