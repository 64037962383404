import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './work-package-selection-control.scss';

import { State } from 'common/interfaces/state';
import { GanttChartActions } from '../../../actions/creators/gantt-chart';
import { GanttChartChangePackageSelectionPayload } from '../../../actions/payloads/gantt-chart';
import { LineData } from '../../../interfaces/gantt-chart';
import { SelectName } from './select-name';
import { WorkPackageSelectionControlBody } from './work-package-selection-control-body';

interface StateProps {
  selectedPackagesCount: number;
  workPackagesCount: number;
}

interface PropsFromState {
  workPackages: LineData[];
  selectedPackagesCount: number;
}

interface DispatchProps {
  onSelectionChange: (changes: GanttChartChangePackageSelectionPayload[]) => void;
}

interface Props extends StateProps, DispatchProps {
  selectAll: () => void;
}

interface ControlState {
  isExpanded: boolean;
}

class WorkPackageSelectionControlComponent extends React.Component<Props, ControlState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  public componentDidMount(): void {
    this.props.selectAll();
  }

  public render(): React.ReactNode {
    const isAllSelected = this.props.workPackagesCount === this.props.selectedPackagesCount;
    return (
      <div className='gantt-work-package-selection-control-panel'>
        <SelectName
          className='gantt-work-package-selection-control-panel__selected-variant'
          isExpanded={this.state.isExpanded}
          onClick={this.onCollapseExpand}
        >
          {
            isAllSelected ?
              'Entire Project'
              :
              `Selected ${this.props.selectedPackagesCount} Package${this.props.selectedPackagesCount === 1 ? '' : 's'}`
          }
        </SelectName>
        <WorkPackageSelectionControlBody
          selectAll={this.props.selectAll}
          isAllSelected={isAllSelected}
          isExpanded={this.state.isExpanded}
          onCollapseExpand={this.onCollapseExpand}
        />
        <div className='gantt-work-package-selection-control-panel__time-title'>
          h
          <span className='gantt-work-package-selection-control-panel__time-title-divider'>:</span>
          min
        </div>
      </div>
    );
  }

  @autobind
  private onCollapseExpand(): void {
    this.setState({ isExpanded: !this.state.isExpanded });
  }
}


const mapStateToProps = (state: State): PropsFromState  => {
  const selectedPackages = state.fourDVisualisation.selectedPackages;
  return {
    selectedPackagesCount: selectedPackages ? Object.entries(selectedPackages)
      .filter(([_id, selected]) => selected).length : 0,
    workPackages: state.fourDVisualisation.ganttData ? state.fourDVisualisation.ganttData.workPackages : [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    onSelectionChange: (changes: GanttChartChangePackageSelectionPayload[]) => {
      dispatch(GanttChartActions.multipleChangePackageSelection(changes));
    },
  };
};

const mergeProps = (stateProps: PropsFromState, dispatchProps: DispatchProps): Props => {
  return {
    ...dispatchProps,
    selectedPackagesCount: stateProps.selectedPackagesCount,
    workPackagesCount: stateProps.workPackages.length,
    selectAll: (selected: boolean = true) => {
      const baseSelections = stateProps.workPackages.map((x): GanttChartChangePackageSelectionPayload => {
        return {
          lineId: x.lineId,
          isSelect: selected,
        };
      });
      dispatchProps.onSelectionChange(baseSelections);
    },
  };
};

export const WorkPackageSelectionControl =
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(WorkPackageSelectionControlComponent);
