import autobind from 'autobind-decorator';
import moment from 'moment';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import './user-activity-item.scss';

import { Avatar, DATE_FORMAT, TIME_FORMAT } from 'common/UIKit';
import { NotificationStatusIcon } from '../../../notifications/components/notification-status-icon';
import { GroupedUserActivity } from '../../interfaces';
import { UserActivityInfoCell } from '../user-activity-info-cell';

interface Props {
  activity: GroupedUserActivity;
  showProjectInfo: boolean;
  expanded: boolean;
  expand: (userActivityId: number) => void;
  collapse: (userActivityId: number) => void;
}

export class UserActivityItem extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      activity: {
        id,
        message,
        document,
        user,
        role,
        relatedArea,
        created,
        icon,
        groupedActivities,
      },
      showProjectInfo,
      expanded,
    } = this.props;
    const isExpandable = groupedActivities && groupedActivities.length > 0;
    return (
      <div className='user-activity-item'>
        <div className='user-activity-item__content'>
          <div className='user-activity-item__info'>
            <div
              className='user-activity-item__date'
              title={moment(`${created}+00:00`).format(`${DATE_FORMAT}  ${TIME_FORMAT}`)}
            >
              {moment(`${created}+00:00`).fromNow()}
            </div>
            <NotificationStatusIcon
              relatedArea={relatedArea}
              icon={icon}
              isExpandable={isExpandable}
              onClick={isExpandable ? this.toggleExpand : null}
              controlName={isExpandable ? `expand-toggle-group-${id}` : null}
              expanded={expanded}
              collapseText={isExpandable ? groupedActivities.length + 1 : null}
            />
            {user ? (
              <Avatar
                className='user-activity-item__avatar'
                userId={user.id}
                firstName={user.firstName}
                lastName={user.lastName}
                title={true}
              />
            ) : (
              null
            )}
            {this.getInfoMessage(message, id)}
          </div>
        </div>
        {showProjectInfo && <UserActivityInfoCell text={document && document.name} />}
        <UserActivityInfoCell text={user && `${user.firstName} ${user.lastName}`} />
        <UserActivityInfoCell text={role && role.name} />
        <UserActivityInfoCell relatedArea={relatedArea} />
      </div>
    );
  }

  private getInfoMessage(message: string, id: number): JSX.Element {
    return (
      <React.Fragment>
        <div
          data-for={id}
          className='user-activity-item__info-message'
          dangerouslySetInnerHTML={{ __html: message }}
          data-tip=''
        />
        {message && message.length > 140 ? (
          <ReactTooltip
            id={id.toString()}
            className='user-activity-item__info-tooltip'
            type='light'
            effect='solid'
            place='top'
          >
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </ReactTooltip>
        ) : null}
      </React.Fragment>
    );
  }

  @autobind
  private toggleExpand(): void {
    const { expanded, collapse, expand, activity } = this.props;
    if (expanded) {
      collapse(activity.id);
    } else {
      expand(activity.id);
    }
  }
}
