import { Action } from 'redux';

import { SortingDirection } from 'common/interfaces/sorting-direction';
import { ActionWith } from '../../../common/interfaces/action-with';
import { FilterType } from '../filter-type';
import { SimpleOption, Sorting, TimeInterval, UserActivity } from '../interfaces';
import { ActivityActionTypes, ActivityPayloads } from '.';

function getCompanySubscriptionActivities(): Action {
  return {
    type: ActivityActionTypes.LOAD_REQUEST_BY_COMPANY_SUBSCRIPTION,
  };
}

function getDocumentActivities(projectId: number): ActionWith<number> {
  return {
    type: ActivityActionTypes.LOAD_REQUEST_BY_DOCUMENT,
    payload: projectId,
  };
}

function getUserActivitiesSucceeded(data: UserActivity[]): ActionWith<UserActivity[]> {
  return {
    type: ActivityActionTypes.LOAD_SUCCEEDED,
    payload: data,
  };
}

function getUserActivitiesFail(): Action {
  return {
    type: ActivityActionTypes.LOAD_FAIL,
  };
}

function deleteFilterItem(
  type: FilterType,
  optionId: React.ReactText,
): ActionWith<ActivityPayloads.DeleteFilterItem> {
  return {
    type: ActivityActionTypes.DELETE_FILTER_ITEM,
    payload: { type, optionId },
  };
}

function addFilterItem(
  type: FilterType,
  option: SimpleOption,
): ActionWith<ActivityPayloads.AddFilterItem> {
  return {
    type: ActivityActionTypes.ADD_FILTER_ITEM,
    payload: { type, option },
  };
}

function setTimeInterval(start: Date, end: Date): ActionWith<TimeInterval> {
  return {
    type: ActivityActionTypes.SET_TIME_INTERVAL,
    payload: { start, end },
  };
}

function clearTimeInterval(): Action {
  return {
    type: ActivityActionTypes.CLEAR_TIME_INTERVAL,
  };
}

function setSorting(type: FilterType, direction: SortingDirection): ActionWith<Sorting> {
  return {
    type: ActivityActionTypes.SET_SORTING,
    payload: { type, direction },
  };
}

export const ActivityActions = {
  getCompanySubscriptionActivities,
  getDocumentActivities,
  getUserActivitiesSucceeded,
  getUserActivitiesFail,
  deleteFilterItem,
  addFilterItem,
  setTimeInterval,
  clearTimeInterval,
  setSorting,
};
