import QueryString from 'query-string';
import { Action, AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { store } from '../../../store';
import { extendWithApi } from './extend-with-api';
import { extendWithReduxAction, MultiViewWithReduxActions } from './extend-with-redux-action';
import { MultiViewHelper } from './multi-view-helper';


type CreateReduxMiddlewareResult<S> =
  (api: MiddlewareAPI<Dispatch<AnyAction>, S>) => (next: Dispatch<Action<S>>) => Dispatch<Action<S>>;

export class MultiViewersStates {
  public static views: MultiViewHelper[] = [];
  private static reduxActionSenders: MultiViewWithReduxActions = null;

  public static createMultiViewState(
    chanelName: string,
    parentId?: string,
    currentId?: string,
    dispatch?: boolean,
    actionBlackList: string[] = [],
    actionWhiteList: string[] = [],
    onSetParentType?: (state: MultiViewHelper) => void,
    onSetChildType?: (state: MultiViewHelper) => void,
    resetInitState?: () => void,
    onOpenChild?: () => void,
  ): MultiViewHelper {
    const multiViewState = new MultiViewHelper(
      chanelName,
      parentId,
      currentId,
      onSetParentType,
      onSetChildType,
      resetInitState,
      onOpenChild,
    );
    this.add(multiViewState);
    if (dispatch) {
      const reduxActionSender = extendWithReduxAction
        .bind(multiViewState)(store.dispatch, actionBlackList, actionWhiteList);
      this.reduxActionSenders = reduxActionSender;
    }

    return multiViewState;
  }

  public static destroyMultiViewState(view: MultiViewHelper): void {
    this.remove(view);
    view.destroy();
  }

  public static createReduxMiddleware<S>(): CreateReduxMiddlewareResult<S> {
    return () =>
      (next) =>
        (action: any) => {
          if (this.reduxActionSenders) {
            return this.reduxActionSenders.handleReduxAction(action, next);
          }

          return next(action);
        };
  }

  public static extendApiWithMultiViewState<T>(
    api: T,
    apiKey: string,
    state: MultiViewHelper,
    methodWhiteList?: string[],
  ): T {
    if (!state.isDisable()) {
      extendWithApi.bind(state)(api, apiKey, methodWhiteList);
    }

    return api;
  }

  public static getIdsFromRoute(): { parentId: string, currentId: string } {
    const { parentId, currentId } = QueryString.parse(window.location.search);
    return {
      parentId,
      currentId,
    };
  }

  public static isDisable(): boolean {
    return this.views[0].data.disable;
  }

  private static remove(view: MultiViewHelper): void {
    this.views = this.views.filter(_ => _.data.id !== view.data.id);
    delete this.reduxActionSenders[view.data.id];
  }

  private static add(view: MultiViewHelper): void {
    this.views.push(view);
  }
}
