import classNames from 'classnames';
import * as React from 'react';

import './validation-hidable-layout.scss';

import { KreoIconCollapseExpand } from 'common/UIKit';


interface Props {
  toggleShowStatus: () => void;
  isShow: boolean;
}

export class ValidationHidableLayout extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      isShow,
      children,
    } = this.props;
    const className = classNames(
      'validation-hidable-layout',
      {
        'validation-hidable-layout--hidden': !isShow,
      },
    );
    return (
      <div className={className}>
        <div
          onClick={this.props.toggleShowStatus}
          className='validation-hidable-layout__button'
          data-control-name='validation-hidable-layout-button'
        >
          <KreoIconCollapseExpand />
        </div>
        {isShow && children}
      </div>
    );
  }
}
