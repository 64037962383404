import * as React from 'react';
import { connect } from 'react-redux';
import { State } from 'common/interfaces/state';

import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { DatabaseModel } from '../../interfaces/data';

export const REMOVE_DATABASE_CONFIRMATION_DIALOG = 'REMOVE_DATABASE_CONFIRMATION_DIALOG';

interface OwnProps {
  onYes: (data: DatabaseModel) => void;
}

interface StateProps {
  data: DatabaseModel;
}

type Props = OwnProps & StateProps;

class RemoveDatabaseConfirmationDialogComponent extends React.Component<Props> {

  public render(): JSX.Element {
    return (
      <KreoConfirmationDialog
        name={REMOVE_DATABASE_CONFIRMATION_DIALOG}
        onYes={this.props.onYes}
        yesText='Delete'
        noText='Cancel'
        title='Delete Database'
      >
        The "{this.props.data && this.props.data.name}" database will be deleted
      </KreoConfirmationDialog>
    );
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    data: state.dialog[REMOVE_DATABASE_CONFIRMATION_DIALOG],
  };
}


export const RemoveDatabaseConfirmationDialog = connect(mapStateToProps)(RemoveDatabaseConfirmationDialogComponent);
