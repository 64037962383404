import { Icons, IconButton } from '@kreo/kreo-ui-components';
import React from 'react';

import { FinderSelectedVisiblity } from 'common/components/drawings/interfaces';
import { Text } from 'common/components/text';
import { ToggleButton } from 'common/components/toggle-button';
import { useRemoveGeometries, useToggleGeometriesVisibility } from './eraser-aggregation-item-hooks';
import { Styled } from './styled';

interface Props {
  title: string;
  visibilityKey: keyof FinderSelectedVisiblity;
  geometriesIds: number[];
}

const EraserAgregationItemComponent: React.FC<Props> = (
  {
    title,
    visibilityKey,
    geometriesIds,
  },
) => {
  const [visible, toggle] = useToggleGeometriesVisibility(visibilityKey);
  const remove = useRemoveGeometries(geometriesIds);
  if (!geometriesIds.length) {
    return null;
  }
  return (
    <Styled.AggregationItemContainer>
      <Styled.AggreationItemTitle>
        <Text fontSize={14} lineHeight={16}>{title}</Text>
        <Text fontSize={14} lineHeight={16} color='gray'>
          {` (${geometriesIds.length})`}
        </Text>
      </Styled.AggreationItemTitle>
      <Styled.ButtonsContainer>
        <ToggleButton
          active={visible}
          activeIcon={Icons.Show2D}
          icon={Icons.Hide2D}
          tooltip={visible ? 'Hide' : 'Show'}
          activeTooltip={visible ? 'Hide' : 'Show'}
          onToggle={toggle}
        />
        <IconButton
          Icon={Icons.Delete}
          onClick={remove}
          width={20}
          height={20}
          iconHeight={20}
        />
      </Styled.ButtonsContainer>
    </Styled.AggregationItemContainer>
  );
};

export const EraserAgregationItem = React.memo(EraserAgregationItemComponent);
