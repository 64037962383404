import React from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { GroupsView } from './groups-view';
import { InstancesView } from './instances-view';


export const SelectionView: React.FC = () => {
  const selectedInstances = useSelector<State, string[]>(state => state.drawings.selectedInstances);
  const selectedGroups = useSelector<State, string[]>(state => state.drawings.selectGeometryGroup);

  if (selectedInstances.length === 0) {
    return (<GroupsView groupsIds={selectedGroups}/>);
  }

  return (
    <InstancesView selectedInstancesIds={selectedInstances}/>
  );
};
