import React from 'react';

import { InputSmallStyled } from 'common/components/inputs';
import { InputSelectWithCustomOptions } from 'common/components/inputs/input-select/input-select-with-custom-options';
import { useValues } from './use-values';
import { ValueWithEdit } from './values-with-edit';


interface Props {
  elements: Record<string, string[]>;
  optionRenderer?: (value: string) => React.ReactNode;
  selectedOptionRenderer: (value: string) => React.ReactNode;
}

const CustomSelectValueComponent: React.FC<Props> = ({
  elements,
  optionRenderer,
}) => {
  const { values, activeElement, onChange } = useValues(elements);

  return (
    <ValueWithEdit>
      {
        activeElement ? (
          <InputSmallStyled.FakeInputStyle>
            {optionRenderer ? optionRenderer(activeElement) : activeElement}
          </InputSmallStyled.FakeInputStyle>
        ) : (
          <InputSelectWithCustomOptions
            elements={values}
            activeElement={activeElement}
            renderElement={optionRenderer}
            onSelect={onChange}
            isShowArrow={true}
            placeholder='Mixed'
            placeholderAsValue={true}
          />
        )
      }
    </ValueWithEdit>
  );
};

export const CustomSelectValue = React.memo(CustomSelectValueComponent);
