import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { TablePreset } from '2d/interfaces';
import { MultilevelSelectSimpleOptionData } from 'common/components/multi-level-drop-down-menu';
import { State } from 'common/interfaces/state';
import { MoveToCellOption } from '../../drawings-context-menu-renderers';
import { PresetMenu } from '../../preset-menu';

export function useMoveToCellOptionsRenderer(
  color: string,
): (option: MultilevelSelectSimpleOptionData) => React.ReactNode {
  const isImperialUnit = useSelector<State, boolean>((state) => state.account.settings.isImperial);
  return useCallback(
    (option: MultilevelSelectSimpleOptionData) => {
      return <MoveToCellOption option={option} color={color} isImperial={isImperialUnit} />;
    },
    [isImperialUnit, color],
  );
}

export function useDynamicMoveToCellOptionRenderer(
  onDynamicMoveToCell: (preset: TablePreset) => void,
  doActionAndClose: (action: (...args: any) => void, ...args: any) => void,
): () => React.ReactNode {
  const dynamicMoveToCell = React.useCallback(
    (preset: TablePreset) => {
      doActionAndClose(onDynamicMoveToCell, preset);
    },
    [doActionAndClose, onDynamicMoveToCell],
  );

  return useCallback(() => {
    return <PresetMenu onSelect={dynamicMoveToCell} />;
  }, [dynamicMoveToCell]);
}
