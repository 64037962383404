import {
  Constants,
  ElementTooltip,
  HotKeys,
  Icons,
  Input,
  LinkComponent,
  ModalWrapper,
  Text,
  TinyText,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { measure3dColumnKeys } from '2d/constants/table-settings';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { TablePreset } from '../../../units/2d/interfaces';
import { ConfirmationDialog } from '../confirmation-dialog';
import { PROPERTIES, SETTINGS } from './constants';
import { Setting } from './interfaces';
import { PresetTableDnDSetting } from './preset-table-dnd-setting';
import { PresetTableSetting } from './preset-table-setting';
import { Styled } from './styled';

interface StateProps {
  data?: TablePreset;
  isOpen: boolean;
  editMode: boolean;
}

interface DispatchProps {
  closeDialog: (dialogName: string) => void;
  openDialog: (dialogName: string) => void;
}

interface Props extends StateProps, DispatchProps, AnalyticsProps, AbilityAwareProps {
  onFormSubmit: (data: TablePreset) => void;
  onCloseDialog: () => void;
}

interface ComponentState {
  name: string;
  showTotal: boolean;
  showColumnNames: boolean;
  searchString: string;
  availableProperties: Setting[];
  selectedProperties: Setting[];
}

type ListName = 'availableProperties' | 'selectedProperties';

export const PRESET_TABLE_DIALOG_NAME = 'presetTableDialog';
const CLOSE_PRESET_TABLE_DIALOG_NAME = 'closePresetTableDialog';

class PresetTableDialogComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.isOpen && !prevProps.isOpen) {
      const initialState = this.getInitialState();
      this.setState(initialState);
    }
  }

  public render(): JSX.Element {
    const searchString = this.state.searchString.toLowerCase();
    const availableProperties = this.state.availableProperties.filter(({ name }) =>
      name.toLowerCase().includes(searchString),
    );
    const isSubmitDisabled = !this.state.selectedProperties.length || !this.state.name.trim();

    return (
      <DialogWrapper name={PRESET_TABLE_DIALOG_NAME}>
        <ModalWrapper onExit={this.onClose}>
          <HotKeys
            keyMap={Constants.KeyMaps.ENTER_ESCAPE}
            handlers={{ escape: this.onClose, enter: this.onSubmit }}
            autofocus={true}
          >
            <Styled.DialogContainer>
              <Styled.GeneralSettings>
                <Styled.GeneralSettingsHeader>
                  <Icons.Table2D height={20} width={20} />
                  <Text fontSize={14}>Preset table</Text>
                  <ElementTooltip
                    text={!this.state.name ? 'Preset name cannot be empty' : 'Add a property to the breakdown'}
                    position="bottom"
                    speed="m"
                    disabled={!isSubmitDisabled}
                  >
                    <LinkComponent
                      text={this.props.editMode ? 'Save' : 'Create'}
                      onClick={this.onSubmit}
                      Icon={this.props.editMode ? Icons.Save : Icons.PlusBig}
                      mood={isSubmitDisabled ? 'disabled' : 'secondary'}
                    />
                  </ElementTooltip>
                </Styled.GeneralSettingsHeader>
                <Input
                  width="100%"
                  type="text"
                  text={'Preset table'}
                  input={{
                    value: this.state.name,
                    onChange: this.onChangeName,
                  }}
                  autoFocus={true}
                />
                <Styled.Divider />
                <Styled.SettingsHeader color={'turquoiseFont'} textTransform={'uppercase'}>
                  Settings
                </Styled.SettingsHeader>
                {SETTINGS.map((setting) => (
                  <PresetTableSetting
                    key={setting.key}
                    setting={setting}
                    checked={this.state[setting.key]}
                    onClick={this.onToggleSetting}
                  />
                ))}
              </Styled.GeneralSettings>
              <Styled.PropertySettings>
                <Styled.PropertySettingsHeader>
                  <Styled.SearchContainer>
                    <Icons.Search />
                    <Styled.SearchInput
                      placeholder={'Type something...'}
                      value={this.state.searchString}
                      onChange={this.onSearch}
                    />
                  </Styled.SearchContainer>
                </Styled.PropertySettingsHeader>
                <Styled.PropertySettingsContainer>
                  <Styled.SettingsHeader color={'turquoiseFont'} textTransform={'uppercase'}>
                    Properties
                  </Styled.SettingsHeader>
                  <Styled.PropertyList>
                    {availableProperties.map((prop) => (
                      <PresetTableSetting key={prop.key} setting={prop} checked={false} onClick={this.selectProperty} />
                    ))}
                  </Styled.PropertyList>
                </Styled.PropertySettingsContainer>
              </Styled.PropertySettings>
              <Styled.PropertySettings>
                <Styled.PropertySettingsHeader>
                  <TinyText color={'turquoiseFont'} textTransform={'uppercase'}>
                    Breakdown
                  </TinyText>
                </Styled.PropertySettingsHeader>
                <Styled.PropertySettingsContainer>
                  {this.state.selectedProperties.length ? (
                    <Styled.PropertyList>
                      {this.state.selectedProperties.map((prop, index) => (
                        <PresetTableDnDSetting
                          key={prop.key}
                          index={index}
                          setting={prop}
                          changePosition={this.sortSelectedItems}
                          onClick={this.unselectProperty}
                        />
                      ))}
                    </Styled.PropertyList>
                  ) : (
                    <Styled.EmptyList>
                      <TinyText color={'turquoiseFont'}>Select the required properties</TinyText>
                    </Styled.EmptyList>
                  )}
                </Styled.PropertySettingsContainer>
              </Styled.PropertySettings>
            </Styled.DialogContainer>
          </HotKeys>
        </ModalWrapper>
        <ConfirmationDialog
          name={CLOSE_PRESET_TABLE_DIALOG_NAME}
          title='Are you sure you want to exit the "Create preset" Mode?'
          text="This action cannot be undone"
          confirmButtonText="Exit"
          onConfirm={this.onCloseConfirmationDialog}
          cancelButtonText="Cancel"
        />
      </DialogWrapper>
    );
  }

  private getInitialState(): ComponentState {
    const { editMode, ability } = this.props;
    const canShowMeasure3dColumns = ability.can(Operation.Read, Subject.Takeoff2dMeasurement3d);
    const properties = PROPERTIES.filter((p) => {
      if (!canShowMeasure3dColumns && this.isMeasure3dColumn(p.key)) {
        return false;
      }

      return true;
    });
    if (editMode) {
      const selectedProperties = [];

      this.props.data.settings.columnKeys.forEach((key) => {
        const property = properties.find((prop) => prop.key === key);
        if (property) {
          selectedProperties.push(property);
        }
      });

      const selectedColumns = new Set(this.props.data.settings.columnKeys);
      const availableProperties = properties.filter((prop) => !selectedColumns.has(prop.key));

      return {
        name: this.props.data.name,
        showTotal: this.props.data.settings.showTotal,
        showColumnNames: this.props.data.settings.showColumnHeaders,
        searchString: '',
        availableProperties,
        selectedProperties,
      };
    }
    return {
      name: 'Untitled',
      showTotal: false,
      showColumnNames: false,
      searchString: '',
      availableProperties: properties,
      selectedProperties: [],
    };
  }

  private isMeasure3dColumn(key: string): boolean {
    return measure3dColumnKeys.has(key);
  }

  @autobind
  private onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ name: event.target.value });
  }

  @autobind
  private onToggleSetting(setting: Setting): void {
    const settingKey = setting.key;
    const partial: Partial<ComponentState> = {
      [settingKey]: !this.state[settingKey],
    };
    this.setState(partial as ComponentState);
  }

  @autobind
  private onSearch(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchString = event.target.value.toLowerCase();
    this.setState({ searchString });
  }

  @autobind
  private selectProperty(property: Setting): void {
    this.moveProperty(property, 'availableProperties', 'selectedProperties');
  }

  @autobind
  private unselectProperty(property: Setting): void {
    this.moveProperty(property, 'selectedProperties', 'availableProperties');
  }

  private moveProperty(property: Setting, from: ListName, to: ListName): void {
    const partial: Partial<ComponentState> = {
      [from]: this.state[from].filter((name) => name !== property),
      [to]: [...this.state[to], property],
    };
    this.setState(partial as ComponentState);
  }

  @autobind
  private sortSelectedItems(dragIndex: number, hoverIndex: number): void {
    const draggableProperty = this.state.selectedProperties[dragIndex];
    const selectedPropertiesCopy = this.state.selectedProperties.slice();
    selectedPropertiesCopy.splice(dragIndex, 1);
    selectedPropertiesCopy.splice(hoverIndex, 0, draggableProperty);
    this.setState({ selectedProperties: selectedPropertiesCopy });
  }

  @autobind
  private onSubmit(): void {
    const { name, showColumnNames, showTotal, selectedProperties } = this.state;
    this.props.onFormSubmit({
      id: this.props.editMode ? this.props.data.id : null,
      name,
      settings: {
        showTotal,
        showColumnHeaders: showColumnNames,
        columnKeys: selectedProperties.map((prop) => prop.key),
      },
    });
    this.props.onCloseDialog();
    if (!this.props.editMode) {
      this.props.sendEvent(MetricNames.measureManager.createPreset, { name });
    }
  }

  @autobind
  private onClose(): void {
    this.props.openDialog(CLOSE_PRESET_TABLE_DIALOG_NAME);
  }

  @autobind
  private onCloseConfirmationDialog(): void {
    this.props.closeDialog(CLOSE_PRESET_TABLE_DIALOG_NAME);
    this.props.onCloseDialog();
  }
}

function mapStateToProps(state: State): StateProps {
  const dialogData = state.dialog[PRESET_TABLE_DIALOG_NAME];
  return {
    data: dialogData,
    isOpen: PRESET_TABLE_DIALOG_NAME in state.dialog,
    editMode: !!dialogData,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    closeDialog: (dialogName) => dispatch(KreoDialogActions.closeDialog(dialogName)),
    openDialog: (dialogName) => dispatch(KreoDialogActions.openDialog(dialogName)),
  };
}

export const PresetTableDialog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAnalyticsContext(withAbilityContext(PresetTableDialogComponent)));
