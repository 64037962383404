import {
  ColDef,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
  RowNode,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import './custom-filter-list.scss';

import { AgGridHelper } from 'common/ag-grid';
import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { SelectionHelper } from 'common/components/tree-table/selection-helper';
import {
  CustomElementFilterSimpleViewModel,
  CustomElementFilterViewModel,
} from 'common/interfaces/custom-element-filter-builder';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { CustomElementFilterBuilderCopyHelper } from 'common/utils/custom-element-filter-builder';
import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { CustomFiltersActions } from '../../actions/creators/custom-filters-actions';
import { QtoCommonTableConstants } from '../quantity-take-off-common-table/constants';
import { TableApi } from '../quantity-take-off-left-panel/interfaces';
import { TreeTableTransferData } from '../quantity-take-off-report-table/report-table-data-transfer';
import { TemplateTableExporter } from '../quantity-take-off-template-table/template-table-data-transfer';
import { CustomFilterItem } from './custom-filter-item';

interface StateProps {
  rowData: CustomElementFilterSimpleViewModel[];
  appliedCustomFilter: CustomElementFilterViewModel;
}

interface DispatchProps {
  getRowData: () => void;
  removeFilter: (id: string) => void;
  copyFilter: (id: string, name: string) => void;
  applyFilter: (ids: string[]) => void;
  openDialog: () => void;
}

interface OwnProps {
  onSelectionChange: (ids: string[]) => void;
  sendTableSelectionApi?: (ref: TableApi) => void;
}

interface StatePage {
  removeFilterName: string;
}

type Props = StateProps & DispatchProps & OwnProps;

const nameField = 'name';
const REMOVE_FILTER_CONFIRMATION_DIALOG =  'REMOVE_FILTER_CONFIRMATION_DIALOG';

class CustomFilterListComponent extends React.PureComponent<Props, StatePage> {
  private gridApi: GridApi;
  private removeFilterId: string = null;
  private dataExporter: TemplateTableExporter;
  private cellClassRules: Record<string, (a: any) => boolean> = {
    'applied-filter': (params) => {
      return params.context.appliedCustomFilter
        && params.context.appliedCustomFilter.includes(params.data.id);
    },
  };
  private selectionHelper: SelectionHelper;

  private columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      field: nameField,
      flex: 1,
      cellClassRules: this.cellClassRules,
      cellRenderer: CustomFilterItem,
      cellRendererParams: {
        onApplyFilter: this.applyFilter,
        onRemoveFilter: this.onRemoveFilter,
      },
      ...this.getDataExporterProps(),
    },
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      removeFilterName: null,
    };

    this.selectionHelper = new SelectionHelper(
      this.props.onSelectionChange,
      node => node.id,
    );

    if (this.props.sendTableSelectionApi) {
      this.props.sendTableSelectionApi({
        setSelected: this.selectionHelper.setSelected,
      });
    }
  }

  public componentDidMount(): void {
    this.dataExporter = new TemplateTableExporter(this.mapExportData);
  }

  public componentDidUpdate(prevProps: Props): void {

    if (this.props.appliedCustomFilter !== prevProps.appliedCustomFilter && this.gridApi) {
      const rows = AgGridHelper.getRootNode(this.gridApi).allLeafChildren;
      this.gridApi.refreshRows(rows);
    }
  }

  public render(): React.ReactNode {
    const appliedCustomFilter = this.props.appliedCustomFilter && this.props.appliedCustomFilter.rootFilterIds;
    const selectionAvailable = this.props.onSelectionChange;
    return (
      <div
        className={classNames('ag-theme-balham', 'ag-theme-balham--kreo', 'qto-common-table', 'custom-filter-list')}
      >
        <AgGridReact
          rowData={this.props.rowData}
          columnDefs={this.columnDefs}
          rowHeight={QtoCommonTableConstants.rowHeight}
          onGridReady={this.onAgGridReady}
          rowSelection={selectionAvailable ? 'multiple' : undefined}
          onRowSelected={selectionAvailable ? this.selectionHelper.rowSelectionHandler : undefined}
          getRowNodeId={this.getRowNodeId}
          getContextMenuItems={this.getContextMenuItems}
          context={{ appliedCustomFilter }}
        />
        <KreoConfirmationDialog
          name={REMOVE_FILTER_CONFIRMATION_DIALOG}
          onYes={this.removeFilter}
          title='Remove Filter'
          yesText='Remove'
          noText='Cancel'
        >
          Are you sure you want to remove the <b>{this.state.removeFilterName}</b> filter?
        </KreoConfirmationDialog>
      </div>
    );
  }

  private mapExportData(row: RowNode): TreeTableTransferData {
    return {
      rows: [{
        type: TreeTableRowType.Element,
        properties: {
          name: { default: row.data[nameField] },
          filterId: { default: row.data.id },
        },
      }],
      columns: [],
    };
  }

  private getDataExporterProps(): Partial<ColDef> {
    return {
      dndSource: true,
      dndSourceOnRowDrag: params => this.dataExporter.setExportData(params.rowNode, this.gridApi, params.dragEvent),
    };
  }

  private getRowNodeId(data: CustomElementFilterSimpleViewModel): string {
    return data && data.id;
  }

  @autobind
  private getContextMenuItems(params: GetContextMenuItemsParams): Array<string | MenuItemDef> {
    if (params.node) {
      return [{
        name: 'Create Copy',
        action: () => this.copyFilter(params.node, params.api),
      }];
    }

    return [];
  }

  @autobind
  private onAgGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.selectionHelper.setApi(event.api);
    if (!this.props.rowData.length) {
      this.props.getRowData();
    }
  }

  @autobind
  private applyFilter(id: string): void {
    const ids = id ? [id] : null;
    this.props.applyFilter(ids);
  }

  @autobind
  private onRemoveFilter(id: string): void {
    this.removeFilterId = id;
    const removeFilterName = this.props.rowData &&
      this.removeFilterId &&
      this.props.rowData.find(item => item.id === id).name;
    this.setState({ removeFilterName });
    this.props.openDialog();
  }

  @autobind
  private removeFilter(): void {
    const { removeFilter, appliedCustomFilter } = this.props;
    const selectedNodes = this.gridApi.getSelectedNodes();
    const filtered = arrayUtils.filterMap(selectedNodes, x => x.id === this.removeFilterId, x => x.id);
    if (filtered.length !== selectedNodes.length) {
      this.props.onSelectionChange(filtered);
    }

    if (appliedCustomFilter && appliedCustomFilter.rootFilterIds.includes(this.removeFilterId)) {
      this.props.applyFilter(null);
    }

    removeFilter(this.removeFilterId);
  }

  @autobind
  private copyFilter(node: RowNode, api: GridApi): void {
    const copyName = CustomElementFilterBuilderCopyHelper.getCopyName(node, api, nameField);
    this.props.copyFilter(node.id, copyName);
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    rowData: state.quantityTakeOff.customFilters,
    appliedCustomFilter: state.quantityTakeOff.appliedCustomFilter,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    getRowData: () => dispatch(CustomFiltersActions.getCustomFiltersInfo()),
    removeFilter: (id: string) => dispatch(CustomFiltersActions.deleteCustomFilters([id])),
    copyFilter: (id: string, name: string) => dispatch(CustomFiltersActions.copyCustomFilter(id, name)),
    applyFilter: (ids: string[]) => dispatch(CustomFiltersActions.applyCustomFilter(ids)),
    openDialog: () => dispatch(KreoDialogActions.openDialog(REMOVE_FILTER_CONFIRMATION_DIALOG)),
  };
};

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

export const CustomFilterList = reduxConnector(CustomFilterListComponent);
