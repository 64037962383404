import { ElementTooltip, Icons, MenuItem } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ColorsName } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { TwoDActions } from '../../../actions/creators';

interface StateProps {
  isDownloadReady: boolean;
  reportTabsCount: number;
}

interface DispatchProps {
  downloadExcelFile: (projectId: string) => void;
}

interface Props extends DispatchProps, StateProps, AnalyticsProps {
  projectId: string;
  onClick: () => void;
}

class ExportExcelButtonComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const Icon = this.props.isDownloadReady
      ? Icons.Sheet
      : Icons.Loader;
    const isDisabled = !this.props.isDownloadReady || !this.props.reportTabsCount;
    return  (
      <ElementTooltip
        text='Export .xlsx file'
        position='right'
        speed='l'
      >
        <MenuItem
          Icon={Icon}
          text='Export as .xlsx file'
          onClick={this.downloadExcelFile}
          textColor={ColorsName.mainFont}
          fontSize={14}
          size={'m'}
          withBorder={true}
          disabled={isDisabled}
        />
      </ElementTooltip>
    );
  }

  @autobind
  private downloadExcelFile(): void {
    this.props.downloadExcelFile(this.props.projectId);
    this.props.onClick();
    this.props.sendEvent(MetricNames.reports.exportExcl);
  }
}

const stateToProps = (state: State): StateProps => {
  return {
    isDownloadReady: state.twoD.isExportReportReady === '',
    reportTabsCount: state.twoD.reportPages.length,
  };
};

const dispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    downloadExcelFile: projectId => dispatch(TwoDActions.downloadExcelFile(projectId)),
  };
};

export const ExportExcelButton = connect(stateToProps, dispatchToProps)(
  withAnalyticsContext(ExportExcelButtonComponent));
