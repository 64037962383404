import * as t from 'io-ts';

import { ActivityAssignmentSubVariantC } from './activity-assignment-sub-variant';

export const ActivityAssignmentVariantDataC = t.exact(t.intersection(
  [
    t.type({
      resourceIds: t.array(t.number),
      selectedDbModalityId: t.number,
      isUsed: t.boolean,
      subVariants: t.array(ActivityAssignmentSubVariantC),
    }),
    t.partial({
      dataBaseId: t.number,
      rootActivityId: t.number,
    }),
  ],
  'ActivityAssignmentVariantData'));

export type ActivityAssignmentVariantData = t.TypeOf<typeof ActivityAssignmentVariantDataC>;
