import * as React from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import './kreo-input.scss';

interface Props {
  name?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

interface State {
  value: string;
}

export class KreoInput extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    name: 'name',
    type: 'text',
    onChange: ConstantFunctions.doNothing,
    onBlur: ConstantFunctions.doNothing,
    onFocus: ConstantFunctions.doNothing,
    value: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value ? props.value : '',
    };
  }

  public static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
    if (nextProps.value) {
      return {
        value: nextProps.value,
      };
    }
    return prevState;
  }

  public render(): any {
    return (
      <input
        placeholder={this.props.placeholder}
        className='kreo-input'
        type={this.props.type}
        name={this.props.name}
        onChange={this.props.onChange}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        value={this.props.value}
        autoComplete={'off'}
      />
    );
  }
}
