import autobind from 'autobind-decorator';
import * as React from 'react';
import { ModelType } from '../../interfaces/quantity-take-off';

import { QtoTemplateTable, QtoTemplateTableComponent } from '../quantity-take-off-template-table/template-table';

export interface QtoReportTemplateTableApi {
  expandAll: () => void;
  collapseAll: () => void;
  changeSelection: (ids: string[]) => void;
}

interface Props {
  projectId: number;
  modelType: ModelType;
  sendTableApi: (api: QtoReportTemplateTableApi) =>  void;
  onSelectFiltersFromTemplate: (ids: string[]) => void;
}

export class QuantityTakeOffTemplateTablePanel extends React.PureComponent<Props> {
  private templateTableRef: QtoTemplateTableComponent;

  public render(): JSX.Element {
    return (
      <QtoTemplateTable
        modelType={this.props.modelType}
        onSelectFiltersFromTemplate={this.props.onSelectFiltersFromTemplate}
        saveRef={this.saveTableRef}
      />
    );
  }

  public componentDidMount(): void {
    this.props.sendTableApi({
      expandAll: this.expandAll,
      collapseAll: this.collapseAll,
      changeSelection: this.changeSelection,
    });
  }

  @autobind
  private changeSelection(ids: string[]): void {
    this.templateTableRef.setSelected(ids);
  }

  @autobind
  private saveTableRef(ref: QtoTemplateTableComponent): void {
    this.templateTableRef = ref;
  }


  @autobind
  private expandAll(): void {
    if (this.templateTableRef && this.templateTableRef.tableRef && this.templateTableRef.tableRef.gridApi) {
      this.templateTableRef.tableRef.gridApi.expandAll();
    }
  }

  @autobind
  private collapseAll(): void {
    if (this.templateTableRef && this.templateTableRef.tableRef && this.templateTableRef.tableRef.gridApi) {
      this.templateTableRef.tableRef.gridApi.collapseAll();
    }
  }
}
