export enum SequenceColor {
  NormalVertex = '#EBF0F5',
  ActiveVertex = '#61728F',
  NextVertex = '#60B9E6',
  PreviousVertex = '#9990F0',
  ActiveEdge = '#5C8AE6',
  NormalEdge = '#EBF0F5',
  WhiteHighlight = '#FFFFFF',
  SelectedActivity = '#779CE6',
}
