import { CodecUtils } from 'common/utils/codec-utils';

export enum CostEstimateGroupingType {
  Workpackages = 'Workpackages',
  WorkpackagesExtended = 'WorkpackagesExtended',
  Nrm1 = 'Nrm1',
  Nrm2 = 'Nrm2',
  Nrm1Extended = 'Nrm1Extended',
  Objects = 'Objects',
}

export const CostEstimateGroupingTypeC =
  CodecUtils.createEnumCodec<CostEstimateGroupingType>(CostEstimateGroupingType, 'CostEstimateGroupingType');

interface GroupingTypeDescription {
  value: CostEstimateGroupingType;
  name: string;
}

/**
 * @description Order matters
 */
export const CostEstimateGroupingTypes: GroupingTypeDescription[] = [
  { value: CostEstimateGroupingType.Workpackages, name: 'Work Packages' },
  { value: CostEstimateGroupingType.Nrm1, name: 'NRM1' },
  { value: CostEstimateGroupingType.Nrm2, name: 'NRM2' },
  { value: CostEstimateGroupingType.WorkpackagesExtended, name: 'Detailed Work Packages' },
  { value: CostEstimateGroupingType.Nrm1Extended, name: 'Detailed NRM1' },
  { value: CostEstimateGroupingType.Objects, name: 'Objects' },
];
