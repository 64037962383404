import { fromJS } from 'immutable';

import * as ViewerActionType from '../units/viewer/actions/types';

const INITIAL_STATE = fromJS({
  showTree: true,
  showComments: true,
  commentsLevel: 0,
  showNewComment: false,
  markerMode: false,
  markerPos: {},
  searchMode: false,
  properties: {},
  showProps: false,
  selectedItem: {},
  isClipBox: false,
  selectedpathes: [],
  withresolved: false,
  clipmode: 'none',
  contextmode: false,
  contextpos: {},
  layers: [],
  mobile: false,
  dimansion: {
    leftWidth: 300,
    rightWidth: 300,
  },
});

export default function(
  state: any = INITIAL_STATE,
  action: any = { type: '', payload: null }): any {
  const mobile = state.get('mobile');
  const showTree = state.get('showTree');
  const showComments = state.get('showComments');
  switch (action.type) {
    case ViewerActionType.VIEWER_SHOW_HIDE_TREE:
      if (mobile && !showTree && showComments) {
        state = state.setIn(['showComments'], false);
      }
      if (state.get('showTree')) {
        state = state.setIn(['searchMode'], false);
      }
      return state.updateIn(['showTree'], (value: any): any => !value);
    case ViewerActionType.VIEWER_SHOW_HIDE_COMMENTS:
      if (mobile && showTree && !showComments) {
        state = state.setIn(['showTree'], false);
      }
      return state.updateIn(['showComments'], (value: any): any => !value);
    case ViewerActionType.VIEWER_SHOW_NEW_COMMENT:
      if (state.get('showNewComment')) {
        state = state.setIn(['markerMode'], false);
      }
      if (mobile) {
        state = state.setIn(['showTree'], false).setIn(['showComments'], false);
      }
      return state.updateIn(['showNewComment'], (value: any): any => !value);
    case ViewerActionType.VIEWER_CREATE_COMMENT_REQUEST:
      return state.setIn(['markerMode'], false);
    case ViewerActionType.VIEWER_GO_TO_COMMENT:
      return state.setIn(['commentsLevel'], 1).setIn(['markerMode'], false);
    case ViewerActionType.VIEWER_BACK_TO_COMMENTS:
      return state.setIn(['commentsLevel'], 0).setIn(['markerMode'], false);
    case ViewerActionType.VIEWER_ON_MARKER_MODE:
      if (mobile) {
        state = state.setIn(['showTree'], false).setIn(['showComments'], false);
      }
      return state.updateIn(['markerMode'], (value: any): any => !value);
    case ViewerActionType.VIEWER_MARKER_POSITION:
      return state.setIn(['markerPos'], fromJS(action.payload));
    case ViewerActionType.VIEWER_SET_CONTEXT_POSITION:
      return state
        .setIn(['contextmode'], true)
        .setIn(['contextpos'], fromJS(action.payload));
    case ViewerActionType.VIEWER_CLOSE_CONTEXT:
      return state.setIn(['contextmode'], false);
    case ViewerActionType.VIEWER_RUN_SEARCH:
      return state.setIn(['searchMode'], true);
    case ViewerActionType.VIEWER_END_SEARCH:
      return state.setIn(['searchMode'], false);
    case ViewerActionType.VIEWER_ON_OF_CLIPBOX:
      return state.updateIn(['isClipBox'], (value: any): any => !value);
    case ViewerActionType.VIEWER_FILTER_WITH_RESOLVED:
      return state.updateIn(['withresolved'], (value: any): any => !value);
    case ViewerActionType.VIEWER_SET_CLIPBOX_MODE:
      return state.setIn(['clipmode'], action.payload);
    case ViewerActionType.VIEWER_SET_MOBILE:
      if (action.payload) {
        state = state.setIn(['showTree'], false).setIn(['showComments'], false);
      }
      return state.setIn(['mobile'], action.payload);
    case ViewerActionType.VIEWER_SET_PANELS_DIMANSION:
      return state.setIn(['dimansion'], fromJS(action.payload));
    case ViewerActionType.VIEWER_DROP_ALL_INFO:
      return INITIAL_STATE;
    default:
      return state;
  }
}
