import { routerActions } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { CRASH_DIALOG } from 'common/components/crash-dialog';
import { SubscriptionType } from 'common/constants/subscription';
import { UserPermission } from 'common/enums/user-permission';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { ActionWith } from 'common/interfaces/action-with';
import { KreoDialogActions } from 'common/UIKit/dialogs/actions';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { AppUrls } from 'routes/app-urls';
import { MANAGE_ROLES } from '../../../constants/forms';
import { AccountActions } from '../../../units/account/actions/creators';
import { ValidationField } from '../../../units/account/actions/payloads';
import { Company } from '../../account/interfaces/company';
import { UpdateRole } from '../../account/interfaces/update-role';
import { UpdateRolePermissions } from '../../account/interfaces/update-role-permissions';
import { FormRoleGroup } from '../../people/components/manage-roles-dialog';
import { SubscriptionActions } from '../actions/creators';
import { CreateSubscriptionEstimationPayload, FormWithSubscriptionId } from '../actions/payloads';
import { SubscriptionActionTypes } from '../actions/types';
import { SubscriptionApi } from '../api/subscription';
import { SubscriptionRolesApi } from '../api/subscription-roles';
import { SUBSCRIBE_PAGE_APPROVE_DIALOG } from '../components/subscribe-page-approve-dialog';
import { SUBSCRIPTION__DIALOG } from '../components/subscription-dialog/subscription-dialog';
import { BillingEntityStatus } from '../enums/billing-entity-status';
import { BillingPeriodUnit } from '../enums/billing-period-unit';
import { CompanyBillingInfoModel } from '../interfaces/company-billing-info-model';
import { CreateSubscriptionForm } from '../interfaces/create-subscription-form';
import { EstimateSubscriptionForm } from '../interfaces/estimate-subscription-form';
import { SubscriptionPlanListingModel } from '../interfaces/subscription-plan-listing-model';
import { UpdateSubscriptionForm } from '../interfaces/update-subscription-form';

function* loadSubscriptionRoles(action: ActionWith<number>): SagaIterator {
  try {
    const roles: CompanyRoles = yield call(SubscriptionRolesApi.loadSubscriptionRoles);
    yield put(SubscriptionActions.loadSubscriptionRolesSucceeded(roles));
  } catch (error) {
    console.error('subscription: load subscription roles failed', error, action.payload);
  }
}

function* updateSubscriptionPermissionRoles(
  action: ActionWith<UpdateRolePermissions[]>,
): SagaIterator {
  try {
    const updatedRolesPermissions: Record<number, UserPermission[]> = yield call(
      SubscriptionRolesApi.updateSubscriptionPermissionRoles,
      action.payload,
    );
    yield put(
      SubscriptionActions.updateSubscriptionPermissionRolesSucceeded(updatedRolesPermissions),
    );
  } catch (error) {
    console.error('subscription: update subscription permission roles failed', error, action.payload);
  }
}

function* updateSubscriptionRoles(): SagaIterator {
  try {
    const formValues = yield selectWrapper(state => state.form[MANAGE_ROLES].values);
    const groups: FormRoleGroup[] = formValues.groups;
    let roles: UpdateRole[] = [];
    groups.map(g => {
      roles = [...roles, ...g.roles];
    });
    const result = yield call(SubscriptionRolesApi.updateSubscriptionRoles, roles);
    yield put(SubscriptionActions.loadSubscriptionRolesSucceeded(result));
    yield put(SubscriptionActions.updateSubscriptionRolesSucceeded());
  } catch (error) {
    console.error('subscription: update subscription roles failed', error);
  }
}

function* getSubscriptionPlans(action: ActionWith<SubscriptionType>): SagaIterator {
  try {
    const model: SubscriptionPlanListingModel = yield call(SubscriptionApi.getSubscriptionPlans, action.payload);
    model.plans = model.plans.sort((a, b) => a.order - b.order);
    let isMonthUnit = false;
    let  isYearUnit = false;
    model.plans.forEach(p => p.variants.forEach(v => {
      if (v.status === BillingEntityStatus.Archived) {
        return;
      }
      if (v.billingPeriodUnit === BillingPeriodUnit.Month) {
        isMonthUnit = true;
      }
      if (v.billingPeriodUnit === BillingPeriodUnit.Year) {
        isYearUnit = true;
      }
    }));
    yield put(SubscriptionActions.getSubscriptionPlansSucceeded(model, isMonthUnit, isYearUnit));
  } catch (error) {
    console.error('subscription: get subscription plans failed', error);
    yield put(SubscriptionActions.getSubscriptionPlansFailed());
  }
}

function* createSubscription(action: ActionWith<CreateSubscriptionForm>): SagaIterator {
  yield put(AccountActions.toggleShowLoading(true));
  try {
    const companyId = yield selectWrapper(state => state.account.selectedCompany && state.account.selectedCompany.id);
    const companies = yield call(SubscriptionApi.createSubscription, companyId, action.payload);
    yield call(handleValidCreateSubscription, companies);
    if (action.payload.subscriptionType === SubscriptionType.Takeoff2d) {
      yield put(routerActions.push(AppUrls.qto2d.thankYou.path));
    }
  } catch (error) {
    yield call(handleInvalidCreateSubscription, error);
    console.error('subscription: create subscription failed', error);
  } finally {
    yield put(AccountActions.toggleShowLoading(false));
  }
}

function* createFreeSubscription(action: ActionWith<CreateSubscriptionForm>): SagaIterator {
  yield put(AccountActions.toggleShowLoading(true));
  try {
    const companyId = yield selectWrapper(state => state.account.selectedCompany && state.account.selectedCompany.id);
    const companies = yield call(SubscriptionApi.createFreeSubscription, companyId, action.payload);
    yield call(handleValidCreateSubscription, companies);
    if (action.payload.subscriptionType === SubscriptionType.Takeoff2d) {
      yield put(routerActions.push(AppUrls.qto2d.thankYou.path));
    }
  } catch (error) {
    yield call(handleInvalidCreateSubscription, error);
    console.error('subscription: create subscription failed', error);
  } finally {
    yield put(AccountActions.toggleShowLoading(false));
  }
}

function* handleValidCreateSubscription(companies: Company[]): SagaIterator {
  const roles: CompanyRoles = yield call(SubscriptionRolesApi.loadSubscriptionRoles);
  yield put(SubscriptionActions.loadSubscriptionRolesSucceeded(roles));
  yield put(AccountActions.fetchCompaniesSuccess(companies));
  yield put(AccountActions.getCurrentUserInfo());
  yield put(KreoDialogActions.closeDialog(SUBSCRIBE_PAGE_APPROVE_DIALOG));
}


function* updateSubscription({ payload }: ActionWith<FormWithSubscriptionId<UpdateSubscriptionForm>>): SagaIterator {
  yield put(AccountActions.toggleShowLoading(true));
  try {
    yield call(SubscriptionApi.updateSubscription, payload.subscriptionId, payload.form);
    yield put(AccountActions.fetchCompanies());
    yield put(AccountActions.getCurrentUserInfo());
    yield put(KreoDialogActions.closeDialog(SUBSCRIPTION__DIALOG));
    if (payload.afterApply) {
      payload.afterApply(payload.subscriptionId);
    }
  } catch (error) {
    yield call(handleUpdateSubscriptionException, error);
  } finally {
    yield put(AccountActions.toggleShowLoading(false));
    yield put(SubscriptionActions.endUpdateSubscription());
  }
}

function* cancelSubscription({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(SubscriptionApi.cancelSubscription, payload);
    yield put(AccountActions.fetchCompanies());

  } catch (error) {
    console.error('subscription: cancel subscription failed', error);
  }
}

function* pauseSubscription({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(SubscriptionApi.pauseSubscription, payload);
  } catch (error) {
    console.error('subscription: pause subscription failed', error);
  }
}

function* renewSubscription({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(SubscriptionApi.renewSubscription, payload);
    yield put(AccountActions.fetchCompanies());
  } catch (error) {
    yield put(AccountActions.setSubscribeError(ValidationField.RenewSubscription, error.message));
    console.error('subscription: renew subscription failed', error);
  } finally {
    yield put(SubscriptionActions.endUpdateSubscription());
  }
}

function* resumeSubscription({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(SubscriptionApi.resumeSubscription, payload);
  } catch (error) {
    console.error('subscription: resume subscription failed', error);
  } finally {
    yield put(SubscriptionActions.endUpdateSubscription());
  }
}

function* getUpdateSubscriptionEstimation(
  { payload }: ActionWith<FormWithSubscriptionId<EstimateSubscriptionForm>>,
): SagaIterator {
  try {
    const { subscriptionId, form } = payload;
    const estimation = form
      ? yield call(SubscriptionApi.getUpdateSubscriptionEstimation, subscriptionId, form)
      : yield call(SubscriptionApi.getCurrentSubscriptionEstimation, subscriptionId);
    yield put(SubscriptionActions.setSubscriptionEstimation(estimation));

  } catch (error) {
    yield call(handleUpdateSubscriptionException, error);
    console.error('subscription: get update subscription estimation failed', error);
  }
}

function* getCreateSubscriptionEstimation(
  { payload }: ActionWith<CreateSubscriptionEstimationPayload>,
): SagaIterator {
  try {
    const { subscriptionType, form } = payload;
    const estimation = yield call(SubscriptionApi.getCreateSubscriptionEstimation, subscriptionType, form);
    yield put(SubscriptionActions.setSubscriptionEstimation(estimation));

  } catch (error) {
    yield call(handleInvalidCreateSubscription, error);
    console.error('subscription: get create subscription estimation failed', error);
  }
}


function* fetchOwnCompanyBillingInfo(action: ActionWith<SubscriptionType>): SagaIterator {
  try {
    const model: CompanyBillingInfoModel = yield call(SubscriptionApi.getBillingInfo, action.payload);
    yield put(SubscriptionActions.fetchOwnCompanyBillingInfoSucceeded(model));
  } catch (error) {
    yield put(SubscriptionActions.fetchOwnCompanyBillingInfoFailed());
    console.error('subscription: fetch own company billing info failed', error);
  }
}

function* setSubscriptionPaymentMethod(action: ActionWith<FormWithSubscriptionId<string>>): SagaIterator {
  try {
    const { subscriptionId, form: cardId } = action.payload;
    yield call(SubscriptionApi.setSubscriptionPaymentMethod, subscriptionId, cardId);
  } catch (error) {
    yield put(SubscriptionActions.fetchOwnCompanyBillingInfoFailed());
    console.error('subscription: fetch own company billing info failed', error);
  }
}

function* upgradeSubscription(
  action: ActionWith<FormWithSubscriptionId<UpdateSubscriptionForm & { cardId: string }>>,
): SagaIterator {
  yield put(AccountActions.toggleShowLoading(true));
  try {
    const { subscriptionId, form } = action.payload;
    yield call(SubscriptionApi.setSubscriptionPaymentMethod, subscriptionId, form.cardId);
    yield delay(3000);
    yield call(SubscriptionApi.updateSubscription, subscriptionId, form);
    yield put(AccountActions.fetchCompanies());
    yield put(AccountActions.getCurrentUserInfo());
    yield put(KreoDialogActions.closeDialog(SUBSCRIPTION__DIALOG));
  } catch (error) {
    yield call(handleUpdateSubscriptionException, error);
    console.error('subscription: update subscription failed', error);
  } finally {
    yield put(AccountActions.toggleShowLoading(false));
  }
}

enum ErrorMessageType {
  Internal,
  NonParsable,
  Parsed,
}

function getMessage(error: any): [string | Record<string, string[]>, ErrorMessageType] {
  try {
    const responseError = JSON.parse(error.message);
    if (responseError?.response?.data?.message) {
      return [responseError.response.data.message, ErrorMessageType.Internal];
    } else {
      return [responseError, ErrorMessageType.Parsed];
    }
  } catch {
    return [error.message, ErrorMessageType.NonParsable];
  }
}

const SORRY_TEXT = 'Sorry, something went wrong.';

function* handleInvalidCreateSubscription(error: Error): SagaIterator {
  const [message, type] = getMessage(error);
  if (type === ErrorMessageType.Parsed) {
    for (const [key, [text]] of Object.entries(message as Record<string, string[]>)) {
      if (key === 'coupons') {
        yield put(SubscriptionActions.setSubscriptionEstimationError({ message: text }));
      } else if (key.includes('coupons')) {
        const couponIndex = Number(key.match(/\d+/gm)[0]);
        yield put(SubscriptionActions.setSubscriptionEstimationError({ message: text, couponIndex }));
      } else {
        const errorText = text.replace(`${key}:`, '');
        yield put(AccountActions.setSubscribeError(key.trim() as ValidationField, errorText.trim()));
      }
    }
  }
}

function* handleUpdateSubscriptionException(error: any): SagaIterator {
  const [message, type] = getMessage(error);
  if (type === ErrorMessageType.Internal) {
    yield put(KreoDialogActions.openDialog(CRASH_DIALOG, SORRY_TEXT));
  } else if (type === ErrorMessageType.NonParsable) {
    const crashError = typeof message === 'string' ? message : SORRY_TEXT;
    yield put(KreoDialogActions.openDialog(CRASH_DIALOG, crashError));
  } else {
    for (const [key, [text]] of Object.entries(message as Record<string, string[]>)) {
      if (key.includes('customer')) {
        const [, , field] = key.match(/(customer\[(.+)\]+)/);
        const errorText = text.replace(`${key}:`, '');
        yield put(AccountActions.setSubscribeError(field.trim() as ValidationField, errorText.trim()));
      } else if (key === 'coupons') {
        yield put(SubscriptionActions.setSubscriptionEstimationError({ message: text }));
      } else if (key.includes('coupons')) {
        const couponIndex = Number(key.match(/\d+/gm)[0]);
        yield put(SubscriptionActions.setSubscriptionEstimationError({ message: text, couponIndex }));
      }
    }
  }
}

function* prolongateTrial({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(SubscriptionApi.prolongateTrial, payload);
    yield put(AccountActions.fetchCompanies());
  } catch (e) {
    console.error(e);
  }
}


export function* subscriptionSagas(): SagaIterator {
  yield takeLatest(SubscriptionActionTypes.LOAD_SUBSCRIPTION_ROLES, loadSubscriptionRoles);
  yield takeLatest(
    SubscriptionActionTypes.UPDATE_SUBSCRIPTION_PERMISSION_ROLES_REQUEST,
    updateSubscriptionPermissionRoles,
  );
  yield takeLatest(SubscriptionActionTypes.UPDATE_SUBSCRIPTION_ROLES_REQUEST, updateSubscriptionRoles);
  yield takeLatest(SubscriptionActionTypes.UPDATE_SUBSCRIPTION, updateSubscription);
  yield takeLatest(SubscriptionActionTypes.CANCEL_SUBSCRIPTION, cancelSubscription);
  yield takeLatest(SubscriptionActionTypes.PAUSE_SUBSCRIPTION, pauseSubscription);
  yield takeLatest(SubscriptionActionTypes.RENEW_SUBSCRIPTION, renewSubscription);
  yield takeLatest(SubscriptionActionTypes.RESUME_SUBSCRIPTION, resumeSubscription);
  yield takeLatest(SubscriptionActionTypes.GET_UPDATE_SUBSCRIPTION_ESTIMATION, getUpdateSubscriptionEstimation);
  yield takeLatest(SubscriptionActionTypes.GET_CREATE_SUBSCRIPTION_ESTIMATION, getCreateSubscriptionEstimation);
  yield takeLatest(SubscriptionActionTypes.GET_SUBSCRIPTION_PLANS_REQUEST, getSubscriptionPlans);
  yield takeLatest(SubscriptionActionTypes.CREATE_SUBSCRIPTION, createSubscription);
  yield takeLatest(SubscriptionActionTypes.FETCH_OWN_COMPANY_BILLING_INFO_REQUEST, fetchOwnCompanyBillingInfo);
  yield takeLatest(SubscriptionActionTypes.SET_SUBSCRIPTION_PAYMENT_METHOD_REQUEST, setSubscriptionPaymentMethod);
  yield takeLatest(SubscriptionActionTypes.CREATE_FREE_SUBSCRIPTION, createFreeSubscription);
  yield takeLatest(SubscriptionActionTypes.UPGRADE_SUBSCRIPTION, upgradeSubscription);
  yield takeLatest(SubscriptionActionTypes.PROPLONGARE_TRIAL, prolongateTrial);
}
