import autobind from 'autobind-decorator';
import * as paper from 'paper';

import {
  DrawingsAllowedPathType,
  DrawingsPaperPolygonPath,
  ShortPointDescription,
} from 'common/components/drawings/interfaces';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { VisibleEntity, VisibleEntityConfig } from '../../../common';
import { PaperMouseEventUtils } from '../../../drawings-helpers/paper-mouse-event-utils';
import { PolylineThickness } from '../../utility/polyline-thickness';

interface Config extends VisibleEntityConfig<ShortPointDescription[][]> {
  color: DrawingsPaperColorInfo;
  layer: paper.Layer | paper.Group;
  thickness: number;
  showThickness: boolean;
  onClick?: (e: PaperMouseEvent, id: number) => void;
  fill: boolean;
}

export class WizzardGeometryPreview extends VisibleEntity<ShortPointDescription[][], Config> {
  private _thicknessShape: PolylineThickness;
  private _path: DrawingsAllowedPathType;

  constructor(config: Config) {
    super(config);
    DrawingsPaperUtils.updatePathStyles(this._path);
  }

  public set fill(value: boolean) {
    this._config.fill = value;
    if (value) {
      this._path.fillColor = this._config.color.fill;
      (this._path as paper.Path).addSegments([this._path.firstSegment]);
    } else {
      this._path.fillColor = null;
    }
    this.renderThickness();
  }

  public set showThickness(value: boolean) {
    if (this._config.showThickness === value) {
      return;
    }
    this._config.showThickness = value;
    this.renderThickness();
  }

  public set thickness(value: number) {
    if (this._config.thickness === value) {
      return;
    }
    this._config.thickness = value;
    this.renderThickness();
  }

  public set strokeWidth(value: number) {
    this._path.strokeWidth = value;
  }

  public set dashArray(value: number[]) {
    this._path.dashArray = value;
  }

  public set color(value: DrawingsPaperColorInfo) {
    this._config.color = value;
    this._path.strokeColor = value.stroke;
    if (this._config.fill) {
      this._path.fillColor = value.fill;
      this._path.closePath();
    }
  }

  public destroy(): void {
    this._path.remove();
    if (this._thicknessShape) {
      this._thicknessShape.destroy();
    }
  }

  protected render(geometry: ShortPointDescription[][]): void {
    if (geometry.length === 1) {
      this._path =  new paper.Path(geometry[0].map(x => new paper.Point(x)));
      if (this._config.fill) {
        this._path.closePath();
      }
    } else {
      const mainContour = new paper.Path(geometry[0].map(x => new paper.Point(x)));
      mainContour.closePath();
      if (!mainContour.clockwise) {
        mainContour.reverse();
      }

      const children = [mainContour];

      for (let i = 1; i < geometry.length; i++) {
        const hole = new paper.Path(geometry[i].map(x => new paper.Point(x)));
        hole.closePath();
        if (hole.clockwise) {
          hole.reverse();
        }
        children.push(hole);
      }
      this._path = new paper.CompoundPath({
        children,
      }) as DrawingsPaperPolygonPath;
    }
    this._path.fillColor = this._config.color.fill;
    this._path.strokeColor = this._config.color.stroke;
    this._path.onClick = this._config.onClick ? this.onClick : undefined;
    this._path.addTo(this._config.layer);
    this.renderThickness();
  }

  @autobind
  private onClick(e: PaperMouseEvent): void {
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      this._config.onClick(e, Number(this.id));
    }
  }

  private renderThickness(): void {
    if (this._thicknessShape) {
      this._thicknessShape.destroy();
      this._thicknessShape = null;
    }
    if (this._config.fill || !this._config.showThickness || !this._config.thickness) {
      return;
    }

    this._thicknessShape = new PolylineThickness({
      geometry: this._path.segments,
      layer: this._config.layer,
      color: this._config.color.thickness,
      offset: this._config.thickness / 2,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
    });
  }
}
