import * as React from 'react';

import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';

export const REMOVE_PIA_CONFIRMATION_DIALOG = 'REMOVE_PIA_CONFIRMATION_DIALOG';

interface Props {
  onSubmit: () => void;
  showTextItem: boolean;
}

export class DrawingsDeletePIAConfirmationDialog extends React.Component<Props> {

  public render(): React.ReactNode {
    const { onSubmit, showTextItem } = this.props;
    const textItems = 'Item will be removed from assemblies which contain it. This action can’t be undone.';
    const textPropertyAssembly = 'This action can’t be undone.';
    const dialogText = showTextItem ? textItems : textPropertyAssembly;

    return (
      <ConfirmationDialog
        name={REMOVE_PIA_CONFIRMATION_DIALOG}
        title='Are you sure you want to delete it?'
        text={dialogText}
        confirmButtonText='Delete'
        cancelButtonText='Cancel'
        onConfirm={onSubmit}
      />
    );
  }
}
