import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces';
import { VisibleEntity, VisibleEntityConfig } from '../../common';
import { RotationProcessor } from '../../interfaces';
import { DrawingsGeometryEntityPoint } from '../drawings-geometry-entity-point';

export interface RotationControlConfig extends VisibleEntityConfig<[paper.Point, paper.Point]> {
  layer: paper.Layer;
  rotationProcessor: RotationProcessor;
}

export class RotationControl
  <T extends RotationControlConfig = RotationControlConfig>
  extends VisibleEntity<[paper.Point, paper.Point], T> {
  protected _group: paper.Group;
  private _point: DrawingsGeometryEntityPoint;
  private _line: paper.Path.Line;

  constructor(config: T) {
    super(config);
    this._config.renderParamsContextObserver.subscribe(this.updateRenderParams);
  }

  public destroy(): void {
    this._config.renderParamsContextObserver.unsubscribe(this.updateRenderParams);
    this._point.destroy();
    this._line.remove();
  }

  public move(diffPosition: paper.Point): void {
    this._group.position = this._group.position.add(diffPosition);
  }

  public updateGeometry(geometry: [paper.Point, paper.Point]): void {
    this.recalculatePositions(this._config.renderParamsContextObserver.getContext().zoom, geometry);
    this._config.geometry = geometry;
  }

  protected render(geometry: [paper.Point, paper.Point]): void {
    const [ point1, point2 ] = geometry;
    const center = point2.add(point1).divide(2);
    if (!this._group) {
      this._group = new paper.Group();
    }
    this._line = new paper.Path.Line(center, center);
    this._line.strokeColor = DrawingsCanvasColors.pointColor;
    this._line.addTo(this._group);
    this._point = new DrawingsGeometryEntityPoint({
      id: '',
      geometry: center,
      layer: this._group,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      onStartDrag: () => this._config.rotationProcessor.startRotate(this._point.paperPosition),
      canModify: true,
      forceCanMove: true,
      cursorHelper: null,
    });
    this._group.addTo(this._config.layer);
  }

  @autobind
  protected recalculatePositions(zoom: number, geometry: [paper.Point, paper.Point]): void {
    const [ point1, point2 ] = geometry;
    const vector = point2.subtract(point1);
    const center = point2.add(point1).divide(2);
    const point = center.add(new paper.Point(0, 12 / zoom)).rotate(vector.angle + 180, center);
    this.setVisualData(center, point, zoom);
  }

  protected setVisualData(centerPoint: paper.Point, pointPos: paper.Point, zoom: number): void {
    this._point.paperPosition = pointPos;
    this._line.firstSegment.point = centerPoint;
    this._line.lastSegment.point = pointPos;
    this._line.strokeWidth = 1 / zoom;
  }

  @autobind
  private updateRenderParams({ zoom }: DrawingsRenderParams): void {
    this.recalculatePositions(zoom, this._config.geometry);
  }
}
