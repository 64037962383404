import React, { useCallback, useState } from 'react';
import { ConstantFunctions } from 'common/constants/functions';

interface UseAccordion {
  icon: React.ComponentType;
  isOpen: boolean;
  toggleOpen: (e: React.MouseEvent) => void;
}

export function useAccordion(
  openIcon: React.ComponentType,
  closeIcon: React.ComponentType,
  defaultOpen: boolean = false,
): UseAccordion {
  const [isOpen, setIsOpen ] = useState(defaultOpen);
  const icon = isOpen ? openIcon : closeIcon;

  const toggle = useCallback((e: React.MouseEvent) => {
    ConstantFunctions.stopEvent(e);
    setIsOpen(s => !s);
  }, []);

  return {
    icon,
    isOpen,
    toggleOpen: toggle,
  };
}
