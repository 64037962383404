import { Avatar, HoverMenu } from '@kreo/kreo-ui-components';
import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { PersonUtils } from 'common/utils/person-utils';
import { Person } from '../../../../../units/people/interfaces/person';
import { UserInfoHint, UserTooltipContainer } from '../../components';
import { Styled } from './styled';

interface StateProps {
  users: Person[];
}

interface OwnProps {
  creatorId: string;
  createdAt: string;
  editorId?: string;
  editedAt?: string;
}

interface Props extends StateProps, OwnProps {
}


const DrawingsAnnotationLegendUserInfoComponent: React.FC<Props> = ({
  users,
  editorId,
  creatorId,
  editedAt,
  createdAt,
}) => {
  const creator = useMemo(() => users.find(user => user.id === creatorId), [users, creatorId]);
  const editor = useMemo(() => editorId ? users.find(user => user.id === editorId) : null, [users, editorId]);

  const getTooltipComponent = useCallback(() => {
    return (
      <UserTooltipContainer>
        {creator && <UserInfoHint title={'create by'} user={creator} timestamp={createdAt}/>}
        {editor && <UserInfoHint title={'modify by'} user={editor} timestamp={editedAt}/>}
      </UserTooltipContainer>
    );
  }, [creator, editor, createdAt, editedAt]);

  const lastUser = editor || creator;

  const tooltipPosition = 'right';

  const { userName, avatarLink } = PersonUtils.getPersonVisualData(lastUser);

  return (
    <Styled.AvatarContainer lastUser={!!lastUser}>
      <HoverMenu
        position={tooltipPosition}
        shift={10}
        speed={'l'}
        MenuComponent={getTooltipComponent}
      >
        <Avatar
          name={userName}
          size={20}
          avatar={avatarLink}
        />
      </HoverMenu>
    </Styled.AvatarContainer>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    users: state.people.companiesUsers,
  };
}

export const DrawingsAnnotationLegendUserInfo = connect(mapStateToProps)(DrawingsAnnotationLegendUserInfoComponent);
