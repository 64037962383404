import React, { Dispatch, useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { Blanket } from 'common/UIKit';
import { ModalRootWrapper } from 'common/UIKit/dialogs/modal-root-wrapper';
import { TwoDCopilotActions } from 'unit-2d-copilot/store-slice';
import { Styled } from './styled';


interface DispatchProps {
  close: () => void;
}

interface Props extends DispatchProps {

}

const RootWrapperComponent: React.FC<Props> = ({
  close,
  children,
}) => {

  const onClose = useCallback(() => {
    close();
  }, [close]);


  return (
    <ModalRootWrapper>
      <Blanket
        onClick={onClose}
      />
      <Styled.RootWrapperContainer>
        {children}
      </Styled.RootWrapperContainer>
    </ModalRootWrapper>
  );
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    close: () => {
      dispatch(TwoDCopilotActions.setPopupOpened(false));
    },
  };
}

export const RootWrapper = connect(null, mapDispatchToProps)(RootWrapperComponent);
