import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 150px;
  width: 100%;
  padding-bottom: 7px;
  overflow-x: overlay;
`;

const OldPaymentUI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div {
    font-family: ProximaSoft;
  }
`;

export const Styled = {
  Container,
  OldPaymentUI,
};
