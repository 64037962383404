import { RowData } from 'common/components/data-base-table';
import { DrawingsFile } from 'common/components/drawings/interfaces/drawings-file-info';

export const getFileRow = (entity: DrawingsFile): RowData => {
  return {
    h_path: entity.id,
    h_isDragSource: true,
    h_isDragTarget: false,

    id: entity.id,
    name: entity.properties.name,
  };
};
