import { InputSelectStyled } from '@kreo/kreo-ui-components';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

const PANEL_WIDTH = 360;
const BREAKDOWN_PANEL_WIDTH = 480;
const zIndex = 9999;

function getStylesForBreakdownPanel(isBreakdownPanel: boolean): FlattenSimpleInterpolation {
  if (isBreakdownPanel) {
    return css`
      padding: 20px 20px 20px 25px;
      overflow-y: hidden;
    `;
  } else {
    return null;
  }
}

const Container = styled.div<{ isOpen: boolean, withSidePanel?: boolean, isBreakdownPanel?: boolean }>`
  width: ${p => p.withSidePanel
    ? (PANEL_WIDTH + 5) * 2
    : p.isBreakdownPanel
      ? BREAKDOWN_PANEL_WIDTH : PANEL_WIDTH
    }px;
  background-color: ${p => p.theme.color.backgroundRush};
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  border-left: 1px solid ${p => p.theme.color.background};
  z-index: ${zIndex + 1};
  transform: translateX(100%);
  transition: all ${p => p.theme.duration.m} ease-in-out;
  ${p => p.isOpen && `
    transform: translateX(0%); 
  `}

  ${InputSelectStyled.DropDownMenuContainer} {
    z-index: ${zIndex};
  }

  & ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  & ::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }

  & ::-webkit-scrollbar-track {
    background: ${p => p.theme.color.background};
  }

  & ::-webkit-scrollbar {
    width: 6px;
  }

  > div {
    ${p => getStylesForBreakdownPanel(p.isBreakdownPanel)};
  }
`;

const blurStyles = css`
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
`;

const Backdrop = styled.div<{ show: boolean, withBlur?: boolean }>`
  width: 100%;
  height: 100%;
  display: ${p => p.show ? 'block' : 'none'};
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${zIndex};
  ${p => p.withBlur ? blurStyles : undefined}
`;

export const Styled = {
  Container,
  Backdrop,
};
