import { AnyAction } from 'redux';

import { DRAWINGS_UPDATE_ACTION_PREFIX } from 'common/components/drawings/actions/types/update';
import { DrawingsInitialState } from 'common/components/drawings/constants/drawings-initial-state';
import { DrawingsState } from 'common/components/drawings/interfaces/drawings-state';
import {
  DrawingsAnnotationLegendReducerMethods,
  DrawingsAnnotationReducerMethods,
  DrawingsReducerMethods,
  DrawingsTextSearchReducerMethods,
  DrawingsUserAnnotationReducerMethods,
  DrawingsAutoMeasureReducerMethods,
  DrawingsExportSettingsReducerMethods,
  DrawingsInstancesVisibilityReducerMethods,
  WizzardToolsReducerMethods,
  DrawingsCalibrateReducerMethods,
  NewInstanceSettingsReducerMethods,
  MagicSearchReducerMethods,
  PdfFilterReducerMethods,
} from 'common/components/drawings/reducer-methods';
import { DatadogLogger } from 'common/environment/datadog-logger';

export function drawings(
  state: DrawingsState = DrawingsInitialState,
  action: AnyAction,
): DrawingsState {
  // todo: Логгер в этом редюсере нужен до выяснения причин
  if (action && action.type in DrawingsReducerMethods) {
    DatadogLogger.log(action.type);
    return DrawingsReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in DrawingsAnnotationReducerMethods) {
    DatadogLogger.log(action.type);
    return DrawingsAnnotationReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in DrawingsAnnotationLegendReducerMethods) {
    DatadogLogger.log(action.type);
    return DrawingsAnnotationLegendReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in DrawingsUserAnnotationReducerMethods) {
    DatadogLogger.log(action.type);
    return DrawingsUserAnnotationReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in DrawingsTextSearchReducerMethods) {
    return DrawingsTextSearchReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in DrawingsAutoMeasureReducerMethods) {
    return DrawingsAutoMeasureReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in DrawingsExportSettingsReducerMethods) {
    return DrawingsExportSettingsReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in DrawingsInstancesVisibilityReducerMethods) {
    return DrawingsInstancesVisibilityReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in WizzardToolsReducerMethods) {
    return WizzardToolsReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in DrawingsCalibrateReducerMethods) {
    return DrawingsCalibrateReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in NewInstanceSettingsReducerMethods) {
    return NewInstanceSettingsReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in MagicSearchReducerMethods) {
    return MagicSearchReducerMethods[action.type](state, action.payload);
  } else if (action && action.type in PdfFilterReducerMethods) {
    return PdfFilterReducerMethods[action.type](state, action.payload);
  } else {
    if (action && action.type.startsWith(DRAWINGS_UPDATE_ACTION_PREFIX)) {
      DatadogLogger.log(action.type, action.payload);
    }
    return state;
  }
}

