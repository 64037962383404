import React from 'react';
import {
  LinkComponent,
  Props as LinkComponentProps,
} from 'common/components/link-component';
import { ColorsName } from 'common/enums/kreo-colors';


interface Props extends LinkComponentProps {
}

const ToolMenuButtonComponent: React.FC<Props> = ({ ...props }) => {
  return (
    <LinkComponent
      fontColor={props.mood ? undefined : ColorsName.mainFont}
      size='m'
      {...props}
    />
  );
};


export const ToolMenuButton = React.memo(ToolMenuButtonComponent);
