import { all, fork } from 'redux-saga/effects';

import { drawingsAnnotationSagas } from 'common/components/drawings/saga/annotation-saga';
import { autoMeasureSaga } from 'common/components/drawings/saga/auto-measure-saga';
import { drawingsSaga } from 'common/components/drawings/saga/common';
import { drawingsAnnotationLegendSagas } from 'common/components/drawings/saga/drawing-annotation-legend';
import { drawingsUpdateSaga } from 'common/components/drawings/saga/update';
import { drawingsUserAnnotationSaga } from 'common/components/drawings/saga/user-annotation';
import { twoDCommentsSagas } from 'unit-2d-comments/sagas';
import { twoDCopilotSaga } from 'unit-2d-copilot/sagas/2d-copilot-sagas';
import { twoDNotificationsSagas } from 'unit-2d-notification/store-slice/2d-notifications-saga';
import { reportTemplateSagas } from 'unit-2d-report-template/saga';
import { ceActivityAssignmentSagas } from 'unit-cost-estimate/sagas/activity-assignment';
import { ceMeasurementsSagas } from 'unit-cost-estimate/sagas/measurements';
import { revisionSagas } from 'unit-cost-estimate/sagas/revisions';
import { twoDDataBaseSagas } from '../units/2d-database/sagas';
import { towDSagas } from '../units/2d/sagas';
import { accountSagas } from '../units/account/sagas';
import { activitySagas } from '../units/activity/sagas';
import { adminCompaniesSagas } from '../units/admin/sagas/companies';
import { adminDemoProjectsSagas } from '../units/admin/sagas/demo-projects';
import { adminPeopleSagas } from '../units/admin/sagas/people';
import { adminProjectsSaga } from '../units/admin/sagas/projects';
import { commonDatabaseSagas } from '../units/databases/sagas';
import { notificationSagas } from '../units/notifications/sagas';
import { peopleSagas } from '../units/people/saga';
import { fourDVisualisationSagas } from '../units/projects/pages/four-d-visualisation/sagas';
import { modelCheckSagas } from '../units/projects/pages/model-check-view';
import { activityAssignmentSagas } from '../units/projects/saga/activity-assignment';
import { bidPricingSaga } from '../units/projects/saga/bid-pricing-saga';
import { classificationSagas } from '../units/projects/saga/classification';
import { projectsSagas } from '../units/projects/saga/common';
import { costsSagas } from '../units/projects/saga/costs';
import { customFilters } from '../units/projects/saga/custom-filters';
import { dashboardSagas } from '../units/projects/saga/dashboard';
import { measurementsSaga } from '../units/projects/saga/measurements';
import { modelManagementSagas } from '../units/projects/saga/model-management';
import { projectsFolderSagas } from '../units/projects/saga/projects-folder';
import { quantityTakeOffSagas } from '../units/projects/saga/quantity-take-off';
import { quantityTakeOffReportsSagas } from '../units/projects/saga/quantity-take-off-reports';
import { quantityTakeOffTemplateSagas } from '../units/projects/saga/quantity-take-off-template';
import { validationSagas } from '../units/projects/saga/validation';
import { viewerSagas } from '../units/projects/saga/viewer';
import { activityGroups, scenarioSagas, workpackageSagas } from '../units/scenarios/sagas';
import { currencyExchangeSagas } from '../units/subscription/sagas/currency-exchange';
import { paymentSagas } from '../units/subscription/sagas/payment';
import { subscriptionSagas } from '../units/subscription/sagas/subscription';
import * as Common from './common';

function* saga(): any {
  yield all([
    fork(Common.commonSagas),
    fork(peopleSagas),
    fork(costsSagas),
    fork(bidPricingSaga),
    fork(scenarioSagas),
    fork(accountSagas),
    fork(subscriptionSagas),
    fork(paymentSagas),
    fork(currencyExchangeSagas),
    fork(commonDatabaseSagas),
    fork(adminPeopleSagas),
    fork(adminProjectsSaga),
    fork(adminCompaniesSagas),
    fork(adminDemoProjectsSagas),
    fork(notificationSagas),
    fork(projectsSagas),
    fork(revisionSagas),
    fork(activitySagas),
    fork(modelCheckSagas),
    fork(workpackageSagas),
    fork(activityGroups),
    fork(viewerSagas),
    fork(classificationSagas),
    fork(activityAssignmentSagas),
    fork(ceActivityAssignmentSagas),
    fork(ceMeasurementsSagas),
    fork(measurementsSaga),
    fork(fourDVisualisationSagas),
    fork(validationSagas),
    fork(dashboardSagas),
    fork(quantityTakeOffSagas),
    fork(drawingsUpdateSaga),
    fork(drawingsSaga),
    fork(quantityTakeOffReportsSagas),
    fork(quantityTakeOffTemplateSagas),
    fork(customFilters),
    fork(modelManagementSagas),
    fork(drawingsAnnotationSagas),
    fork(drawingsAnnotationLegendSagas),
    fork(autoMeasureSaga),
    fork(towDSagas),
    fork(drawingsUserAnnotationSaga),
    fork(projectsFolderSagas),
    fork(twoDDataBaseSagas),
    fork(twoDCommentsSagas),
    fork(twoDNotificationsSagas),
    fork(twoDCopilotSaga),
    fork(reportTemplateSagas),
  ]);
}

export default saga;
