import { AnyAction } from 'redux';

import { AccountActionTypes } from '../units/account/actions/types';
import { BidPricingReduxState } from '../units/projects/interfaces/bid-pricing/bid-pricing-redux-state';
import { intialState } from '../units/projects/pages/bid-pricing/initial-state';
import { bidPricingReducerMethods } from '../units/projects/pages/bid-pricing/reducer';

export const bidPricingReducer = (
  state: BidPricingReduxState = intialState,
  action: AnyAction = { type: '', payload: null },
): BidPricingReduxState => {
  if (action.type in bidPricingReducerMethods) {
    return bidPricingReducerMethods[action.type](state, action.payload);
  } else if (action.type === AccountActionTypes.LOG_OUT) {
    return intialState;
  } else {
    return state;
  }
};
