import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyS: React.FC = () => {
  return (
    <svg
      width='20px'
      height='22px'
      viewBox='0 0 20 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='hotkey_s' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='S'>
          <g id='background'>
            <g id='Group' transform='translate(0.000000, 2.000000)'>
              <g id='path-1-link' fill={KreoColors.f1}>
                <rect id='path-1' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
            <g id='Group'>
              <g id='path-2-link' fill={KreoColors.f1}>
                <rect id='path-2' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
          </g>
          <rect id='padding-left' x='0' y='6' width='6' height='10' />
          <rect id='padding-right' x='14' y='6' width='6' height='10' />
          <path
            // eslint-disable-next-line max-len
            d='M9.89000051,13.0099998 C10.9240007,13.0099998 11.5070008,12.5699998 11.5070008,11.7999996 C11.5070008,11.4479996 11.4190008,11.1729995 11.1990007,10.9859995 C11.0010007,10.8099995 10.7370007,10.7109995 10.3465006,10.6559995 L9.41700044,10.5239994 C8.78450033,10.4304994 8.30600026,10.2324994 7.93750019,9.89149933 C7.54700013,9.53399927 7.3600001,9.02799919 7.3600001,8.38999908 C7.3600001,7.02599886 8.35000026,6.10199871 9.97800053,6.10199871 C10.7205007,6.10199871 11.1715007,6.22299873 11.8205008,6.56399878 C12.0955009,6.7069988 12.2990009,6.91049884 12.2990009,7.16349888 C12.2990009,7.2899989 12.2550009,7.41649892 12.1615009,7.52649894 C12.0735009,7.63099896 11.9580009,7.69699897 11.7985008,7.69699897 C11.6720008,7.69699897 11.4795008,7.62549896 11.3200007,7.52649894 C10.8525007,7.24049889 10.4840006,7.14149888 9.93400052,7.14149888 C9.01000037,7.14149888 8.51500029,7.64199896 8.51500029,8.35699908 C8.51500029,8.64299912 8.5920003,8.90699917 8.80100034,9.08299919 C8.99900037,9.25899922 9.23000041,9.36899924 9.65900048,9.43499925 L10.5940006,9.57799928 C11.3090007,9.68799929 11.7050008,9.85299932 12.0460009,10.1609994 C12.4640009,10.5459994 12.684001,11.0849995 12.684001,11.7669996 C12.684001,13.2079999 11.5180008,14.066 9.86800051,14.066 C8.92750036,14.066 8.33900026,13.8845 7.76150017,13.5379999 C7.3545001,13.2959999 7.20600007,13.1309999 7.20600007,12.8449998 C7.20600007,12.7129998 7.26650008,12.5589998 7.3710001,12.4544997 C7.47000012,12.3554997 7.59100014,12.3114997 7.71750016,12.3114997 C7.87150018,12.3114997 8.00900021,12.3609997 8.22350024,12.5094998 C8.75150033,12.8724998 9.2135004,13.0099998 9.89000051,13.0099998 Z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
};
