import { CodecUtils } from 'common/utils/codec-utils';

export enum BillingEntityStatus {
  Active='Active',
  Archived='Archived',
}

export const BillingEntityStatusC = CodecUtils
  .createEnumCodec<BillingEntityStatus>(BillingEntityStatus, 'BillingEntityStatus');

