import styled, { css } from 'styled-components';

const Button = styled.i`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${p => p.theme.color.backgroundRush};
  opacity: 1;
  transition: ${p => p.theme.duration.s};
  pointer-events: all;
  svg {
    transition: ${p => p.theme.duration.s};
    width: 10px;
    fill: ${p => p.theme.color.turquoiseInversed};
  }

  &:hover {
    svg {
      fill: ${p => p.theme.color.turquoise};
    }
  }
`;


const ButtonBorders = css`
  content: '';
  z-index: 1;
  display: block;
  position: absolute;
  height: 30px;
  width: 30px;

`;


const ButtonContainer = styled.div`
  background-color: ${p => p.theme.color.backgroundRush};
  justify-content: end;
  margin-right: auto;
  position: relative;

  ::before {
    ${ButtonBorders}
  }

  ::after {
    ${ButtonBorders}
  }
`;

const HorizontalButtonContainer = css`
  width: 40px;
  ${ButtonContainer} {
    width: 25px;
    ::before {
      left: -1px;
      bottom: -30px;
      background: radial-gradient(circle at bottom right,transparent 70%, ${p => p.theme.color.backgroundRush} 70%);
    }
    ::after {
      left: -1px;
      top: -30px;
      background: radial-gradient(circle at top right,transparent 70%, ${p => p.theme.color.backgroundRush} 70%);
    }
    ${Button} {
      margin-left: 15px;
    }
  }
`;

const VerticalButtonContainer = css`
  height: 25px; 
  ${ButtonContainer} {
    height: 25px;
    ::before {
      left: 25px;
      bottom: -1px;
      background: radial-gradient(circle at top right,transparent 70%, ${p => p.theme.color.backgroundRush} 70%);
    }
    ::after {
      right: 25px;
      bottom: -1px;
      background: radial-gradient(circle at top left,transparent 70%, ${p => p.theme.color.backgroundRush} 70%);
    }
  }

  ${Button} {
    transform: translateY(-50%);
  }
`;

const Container = styled.div<{ enabled: boolean, horizontal: boolean }>`
  display: ${p => p.enabled ? 'flex' : 'none'};

  color: ${p => p.theme.color.mainFont};
  cursor: pointer;
  z-index: 2;
  pointer-events: none;
  position: absolute;
  margin-left: auto;
  ${p => p.horizontal ? HorizontalButtonContainer : VerticalButtonContainer}
`;


export const Styled = {
  Container,
  Button,
  ButtonContainer,
};
