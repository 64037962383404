import * as React from 'react';

export const KreoIconTableYes: React.FC = () => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Table_Yes' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M10.0000012,3.55271368e-15 L10.0000012,3.55271368e-15 C15.5228494,2.53818293e-15 20.0000024,4.47715304
          20.0000024,10.0000012 L20.0000024,10.0000858 C20.0000024,15.5229339 15.5228494,20.000087
          10.0000012,20.000087 L10.0000012,20.000087 C4.47715304,20.000087 6.76353833e-16,15.5229339
          0,10.0000858 L0,10.0000012 C-6.76353833e-16,4.47715304 4.47715304,4.56724443e-15 10.0000012,3.55271368e-15 Z'
          id='Rectangle'
          fill='#54C4AE'
        />
        <path
          d='M13.6464466,7.64644661 C13.8417088,7.45118446 14.1582912,7.45118446 14.3535534,7.64644661
          C14.5488155,7.84170876 14.5488155,8.15829124 14.3535534,8.35355339 L9,13.7071068
          L5.64644661,10.3535534 C5.45118446,10.1582912 5.45118446,9.84170876 5.64644661,9.64644661
          C5.84170876,9.45118446 6.15829124,9.45118446 6.35355339,9.64644661 L9,12.2928932
          L13.6464466,7.64644661 Z'
          id='Rectangle-5'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
