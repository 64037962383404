import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import { createSlice } from '@reduxjs/toolkit';

import { ActionWith } from 'common/interfaces/action-with';
import { RealtimeConnectionInfo } from 'unit-2d-comments/interfaces';
import { CompanyNotification, LoadNotificationPayload, TwoDNotificationState } from './interfaces';

export const TWOD_NOTIFICATION_INITIAL_STATE: TwoDNotificationState = {
  lastAccess: null,
  notifications: [],
  bellCount: 0,
  isLoading: false,
  isPanelOpen: false,
  realtimeConnection: null,
};

const setLoading = (state: TwoDNotificationState): void => {
  state.isLoading = true;
};

const setFinishLoading = (state: TwoDNotificationState): void => {
  state.isLoading = false;
};

const TwoDNotifications = createSlice({
  name: '@twoDNotifications',
  initialState: TWOD_NOTIFICATION_INITIAL_STATE,
  reducers: {
    addNotification: (state, { payload }: ActionWith<CompanyNotification[]>) => {
      state.notifications.unshift(...payload);
      state.bellCount = state.notifications.filter(n => n.createAt > state.lastAccess).length;
    },
    updateNotifications: (state, { payload }: ActionWith<CompanyNotification[]>) => {
      state.notifications = state.notifications.concat(payload);
    },
    setNotifications: (state, { payload }: ActionWith<CompanyNotification[]>) => {
      state.notifications = payload;
    },
    setLastAccess: (state, { payload }: ActionWith<Date>) => {
      if (payload === null) {
        state.lastAccess = null;
        state.bellCount = state.notifications.length;
      } else {
        state.lastAccess = payload;
        state.bellCount = state.notifications.filter(n => n.createAt > payload).length;
      }
    },
    setBellCount: (state, { payload }: ActionWith<number>) => {
      state.bellCount = payload;
    },
    loadNotifications: (state, _: ActionWith<LoadNotificationPayload>) => setLoading(state),
    finishLoadNotifications: setFinishLoading,
    openPanel: (state) => {
      state.isPanelOpen = true;
    },
    closePanel: (state) => {
      state.isPanelOpen = false;
    },
    updateLastAccess: setLoading,
    realtimeConnected: (state, { payload: connection }: ActionWith<RealtimeConnectionInfo>) => {
      state.realtimeConnection = connection;
    },
    realtimeDisconnected: (state) => {
      state.realtimeConnection = null;
    },
    connectRealtime: ConstantFunctions.doNothing,
    disconnectRealtime: ConstantFunctions.doNothing,
  },
});

export const TwoDNotificationsActions = TwoDNotifications.actions;
export const twoDNotificationsReducers = TwoDNotifications.reducer;
