import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './validation-stepper.scss';

import { KreoColors } from 'common/enums/kreo-colors';
import { KreoProduct } from 'common/enums/kreo-product';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoButton, KreoDialogActions, KreoIconAddedDone } from 'common/UIKit';
import { KreoConfirmationDialog } from '../../../../../../components/dialog/kreo-confirmation-dialog';
import { Engine } from '../../../../../../components/engine';
import { AccountSelectors } from '../../../../../../units/account/selectors';
import { ScenarioState } from '../../../../../../units/projects/interfaces/scenario-state';
import { ValidationStepperProgressItem } from '../../../../components/validation-stepper-progress-item';
import {
  getNextStep,
  getPreviousStep,
  isValidationStepGreaterThan,
  ValidationStep,
} from '../../../../enums/validation-step';
import { getValidationStepName, ValidationStepName } from '../../../../enums/validation-step-name';
import { ValidationStepState } from '../../../../enums/validation-step-state';
import { ProjectValidationState } from '../../../../interfaces/project-validation-state';
import { ValidationStepContent } from './validation-step-content';
import { StepProps } from './validation-step-wrapper';

interface StateProps {
  projectId: number;
  validationState: ProjectValidationState;
  companyProducts: KreoProduct[];
  scenarioState: ScenarioState | null;
}

interface DispatchProps {
  navigateTo: (link: string) => void;
  openConfirmationDialog: () => void;
  showDialog: (dialogName: string) => void;
  closeDialog: (dialogName: string) => void;
}

interface OwnProps {
  mountPath: string;
  selectedStep: ValidationStepName;
}

interface Props extends StateProps, DispatchProps, React.Props<ValidationStepperComponent>, OwnProps {
  children: Array<React.ReactElement<StepProps>>;
}

const revertDialogName = 'revert-validation-step-dialog';
const removeApprovalDialogName = 'remove-validation-step-approval-dialog';
const approveDialogName = 'approve-validation-step-dialog';

export class ValidationStepperComponent extends React.PureComponent<Props> {
  private readonly warningColor: string = '#ffae3d';
  private readonly trivialColor: string = KreoColors.gray6;
  private isEngineUpdate: boolean = false;
  private engine: any;
  private engineLoadAction: () => void = null;

  public render(): React.ReactNode {
    const selectedStep = this.getSelectedStep();

    return (
      <div className='validation-stepper'>
        <div className='validation-stepper__top'>
          <div className='validation-stepper__progress'>
            {this.props.children.map((step, index) => {
              return step && (
                <ValidationStepperProgressItem
                  key={step.props.step}
                  title={step.props.title}
                  index={index}
                  step={step.props.step}
                  stepperMountPath={this.props.mountPath}
                />
              );
            })}
          </div>
          <div className='validation-stepper__btn-wrap'>
            {this.renderRevertButton()}
            {this.renderRemoveApprovalButton()}
            {this.renderApproveButton()}
          </div>
        </div>
        <div className='validation-stepper__content'>
          <div className='validation-step-wrapper'>
            {selectedStep}
            {selectedStep && selectedStep.props.with3dView ? (
              <ValidationStepContent width={'64%'} className='model-check-step-engine'>
                <Engine
                  projectId={this.props.projectId}
                  textures='/static/textures/'
                  sendEngineApi={this.setEngineApi}
                  affterLoadAction={this.afterLoadAction}
                  onHandleClick={this.dropSelect}
                  backgroundColor='white'
                />
              </ValidationStepContent>
            ) : null}
          </div>
        </div>
        {
          selectedStep &&
          selectedStep.props.onRemoveApproval &&
          (
            <KreoConfirmationDialog
              name={removeApprovalDialogName}
              onYes={selectedStep.props.onRemoveApproval}
              yesText='Remove'
              noText='Cancel'
              title={'Remove approval'}
            >
              Are you sure you want to remove approval of the current validation step?
            </KreoConfirmationDialog>
          )
        }
        {selectedStep &&
          selectedStep.props.onRevertToPreviousStep && (
            <KreoConfirmationDialog
              name={revertDialogName}
              onYes={selectedStep.props.onRevertToPreviousStep}
              yesText='Revert'
              noText='Cancel'
              title={`Revert to ${this.getPreviousStepTitle(selectedStep)}`}
            >
              {selectedStep.props.step === ValidationStep.Results
                ? 'You will lose all your calculated scenarios'
                : 'You will lose all the changes made at the current step'}
            </KreoConfirmationDialog>
        )}
        {selectedStep &&
          selectedStep.props.onApprove && (
            <KreoConfirmationDialog
              name={approveDialogName}
              onYes={selectedStep.props.onApprove}
              yesText='Approve'
              noText='Cancel'
              title={`${selectedStep.props.title} Approval`}
            >
              {selectedStep.props.step === ValidationStep.Measurements
                ? 'If you approve current step, the Validation will be completed and locked for editing'
                : 'If you approve current step, the data will be locked for editing'}
            </KreoConfirmationDialog>
        )}
      </div>
    );
  }

  @autobind
  public paintEngine(trivial: number[], warning: number[]): void {
    if (this.isEngineUpdate) {
      this.highlightElements(trivial, warning);
    } else {
      this.engineLoadAction = () => this.highlightElements(trivial, warning);
    }
  }

  @autobind
  public clearEngine(): void {
    if (!this.engine) {
      return;
    }

    this.engine.setColorTint(null);
  }

  @autobind
  public dropSelect(): void {
    this.engine.setSelected([]);
  }

  @autobind
  public showAll(): void {
    this.engine.setRenderMode('standard');
  }

  @autobind
  public isolate(ids: number[]): void {
    this.engine.setRenderMode('ghost');
    this.engine.setRenderMode('standard', ids);
  }

  private renderRemoveApprovalButton(): React.ReactNode {
    const selectedStep = this.getSelectedStep();

    const { companyProducts, validationState, scenarioState } = this.props;

    const currentStep = validationState.validationStep;
    const isSelectedStepApproved = isValidationStepGreaterThan(
      currentStep, selectedStep.props.step);

    if (
      !selectedStep ||
      !selectedStep.props.onRemoveApproval ||
      !isSelectedStepApproved ||
      !companyProducts.includes(KreoProduct.BillOfQuantities) ||
      companyProducts.includes(KreoProduct.Plan)
    ) {
      return null;
    }

    const scenarioInCalculation = scenarioState && scenarioState.inCalculation;
    const buttonIsDisabled = scenarioInCalculation ||
      isValidationStepGreaterThan(currentStep, getNextStep(currentStep));
    return (
      <KreoButton
        disabled={buttonIsDisabled}
        onClick={this.openRemoveApprovalDialog}
        mode='error'
        size='medium'
        controlName='remove-approval-button'
      >
        Remove approval
      </KreoButton>
    );
  }

  private renderApproveButton(): React.ReactNode {
    const selectedStep = this.getSelectedStep();
    if (!selectedStep || !selectedStep.props.onApprove) {
      return null;
    }

    const { validationState, scenarioState } = this.props;

    const isSelectedStepApproved = isValidationStepGreaterThan(validationState.validationStep, selectedStep.props.step);
    if (isSelectedStepApproved) {
      return (
        <KreoButton
          disabled={true}
          mode='success'
          size='medium'
          className='validation-stepper__approve-step-button--approved'
          controlName='approved-button'
          icon={<KreoIconAddedDone />}
        >
          Approved
        </KreoButton>
      );
    }

    const scenarioInCalculation = !scenarioState || scenarioState.inCalculation;
    const approvableStep = (validationState.validationStep === selectedStep.props.step &&
      validationState.stepState === ValidationStepState.Approvable);
    const isSelectedStepApprovable =
      selectedStep.props.step === ValidationStep.ModelCheck ||
      (!scenarioInCalculation && approvableStep) ||
      (selectedStep.props.step === ValidationStep.Classification && approvableStep);

    return (
      <KreoButton
        disabled={!isSelectedStepApprovable}
        mode='submit'
        size='medium'
        onClick={this.openApproveDialog}
        className='validation-stepper__approve-step-button'
        controlName='approve-button'
      >
        Approve
      </KreoButton>
    );
  }

  private renderRevertButton(): React.ReactNode {
    const selectedStep = this.getSelectedStep();
    const { validationState, scenarioState } = this.props;
    if (
      !selectedStep ||
      !selectedStep.props.onRevertToPreviousStep ||
      isValidationStepGreaterThan(validationState.validationStep, selectedStep.props.step)
    ) {
      return null;
    }

    const scenarioInCalculation = scenarioState && scenarioState.inCalculation;
    const isRevertStepDisabled =
      scenarioInCalculation ||
      (validationState.validationStep === selectedStep.props.step &&
        (validationState.stepState === ValidationStepState.Calculating ||
          validationState.stepState === ValidationStepState.Blocked));
    return (
      <KreoButton
        disabled={isRevertStepDisabled}
        size='medium'
        onClick={this.openRevertDialog}
        mode='error'
        controlName='revert-to-previous-step-button'
      >
        Revert to previous step
      </KreoButton>
    );
  }

  private getSelectedStep(): React.ReactElement<StepProps> {
    const children: Array<React.ReactElement<StepProps>> = this.props.children;
    return children.find(
      s => getValidationStepName(s.props.step) === this.props.selectedStep,
    );
  }

  private highlightElements(trivial: number[], warning: number[]): void {
    if (trivial.length > 0 || warning.length > 0) {
      this.engine.setColorTint(null);
      this.engine.setColorTint(this.trivialColor, trivial);
      this.engine.setColorTint(this.warningColor, warning);
    }
  }

  @autobind
  private afterLoadAction(): void {
    if (this.engineLoadAction !== null) this.engineLoadAction();
    this.isEngineUpdate = true;
  }

  @autobind
  private setEngineApi(engine: any): void {
    this.engine = engine;
    this.forceUpdate();
  }

  @autobind
  private openRemoveApprovalDialog(): void {
    this.props.showDialog(removeApprovalDialogName);
  }

  @autobind
  private openRevertDialog(): void {
    this.props.showDialog(revertDialogName);
  }

  @autobind
  private openApproveDialog(): void {
    this.props.showDialog(approveDialogName);
  }

  private getPreviousStepTitle(step: React.ReactElement<StepProps>): string {
    if (step) {
      if (step.props.step === ValidationStep.Results) {
        return 'Validation';
      }
      const previousStep = getPreviousStep(step.props.step);
      const children: Array<React.ReactElement<StepProps>> = this.props.children;
      const previousStepComponent = children.find(
        s => s.props.step === previousStep,
      );
      if (previousStepComponent) {
        return previousStepComponent.props.title;
      }
    }

    return null;
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  const currentProject = state.projects.currentProject;
  const validationState = currentProject ? currentProject.validationState : null;
  const scenario = state.scenarios.active_scenario;
  const scenarioState = state.scenarios.active_scenario &&
    state.projects.scenarioStates[scenario.scenarioGuid];

  const subscription = AccountSelectors.currentSubscription(state);
  const companyProducts = subscription ? subscription.products : [];

  return {
    projectId: currentProject ? currentProject.id : null,
    validationState,
    companyProducts,
    scenarioState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    navigateTo: link => {
      dispatch(push(link));
    },
    openConfirmationDialog: () => {
      dispatch(KreoDialogActions.openDialog('nextStepComplete'));
    },
    showDialog: dialogName => dispatch(KreoDialogActions.openDialog(dialogName)),
    closeDialog: dialogName => dispatch(KreoDialogActions.closeDialog(dialogName)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export const ValidationStepper = connector(ValidationStepperComponent);
