import * as React from 'react';

import { GlobalKeyboardEventsController } from 'common/components/global-keyboard-events-controller';
import { UndoRedoContextProvider } from './undo-redo-context-provider';


export const UndoRedoController: React.FC = props => {
  return (
    <GlobalKeyboardEventsController>
      <UndoRedoContextProvider>
        {props.children}
      </UndoRedoContextProvider>
    </GlobalKeyboardEventsController>
  );
};
