import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import './activity-assignment-revit-tree-body.scss';
import { State } from 'common/interfaces/state';
import { KreoScrollbars, VirtualList } from 'common/UIKit';
import { KreoScrollbarsApi } from 'common/UIKit/scrollbars/kreo-scrollbars';
import { CEActivityAssignmentUtils } from 'unit-cost-estimate/utils/ce-activity-assignment-utils';
import { RevitTreeLinearData } from 'unit-projects/interfaces/revit-tree-linear-data';
import { ActivityAssignmentRevitTreeItem } from './activity-assignment-revit-tree-item';

interface Props {
  tree: RevitTreeLinearData[];
  centralTreeElement: number;
}

class ActivityAssignmentRevitTreeBodyComponent extends React.PureComponent<Props> {
  private readonly itemHeight: number = 40;

  private bodyContainerRef: HTMLDivElement;
  private scrollbarsApi: KreoScrollbarsApi = null;

  public render(): React.ReactNode {
    return (
      <div className='activity-assignmnet-revit-tree-body'>
        <KreoScrollbars
          onSendRef={this.setViewport}
          sendApi={this.saveScrollbarsApi}
        >
          <VirtualList
            bufferSize={21}
            viewport={this.bodyContainerRef}
            items={this.props.tree}
            itemHeight={this.itemHeight}
            itemFactory={this.renderer}
          />
        </KreoScrollbars>
      </div>
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.centralTreeElement !== this.props.centralTreeElement
      && Number.isInteger(this.props.centralTreeElement)
    ) {
      this.scrollTo();
    }
  }


  @autobind
  private saveScrollbarsApi(api: KreoScrollbarsApi): void {
    this.scrollbarsApi = api;
  }


  @autobind
  private renderer(item: RevitTreeLinearData): React.ReactNode {
    return (
      <ActivityAssignmentRevitTreeItem
        key={item.path}
        path={item.path}
        level={item.level}
        title={item.name}
        start={item.startIds}
        end={item.endIds}
        expandable={item.expandable}
        expanded={item.expanded}
        error={item.error}
        all={item.all}
        errors={item.errors}
        isManuallyChanged={item.changedManually}
        hasDiff={item.hasDiff}
      />);
  }


  private scrollTo(): void {
    if (this.scrollbarsApi && this.bodyContainerRef) {
      const containerHeight = this.bodyContainerRef.getBoundingClientRect().height;
      const topElement = this.props.centralTreeElement;
      const topPosition = this.itemHeight * topElement - (this.itemHeight + containerHeight) / 2;
      this.scrollbarsApi.setScrollTop(topPosition);
    }
  }

  @autobind
  private setViewport(viewport: HTMLDivElement): void {
    this.bodyContainerRef = viewport;
  }

}


function mapStateToProps({ ceActivityAssignment }: State): Props {
  const { tree, works, searchMode, searchedPathes, workLinks, centralTreeElement } = ceActivityAssignment;
  return {
    tree: CEActivityAssignmentUtils.treeToList(
      tree, works, searchMode, searchedPathes, workLinks, workLinks, workLinks),
    centralTreeElement,
  };
}

const connector = connect(mapStateToProps);
export const ActivityAssignmentRevitTreeBody = connector(ActivityAssignmentRevitTreeBodyComponent);
