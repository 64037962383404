import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './sorting-icon.scss';

import { SortingDirection } from 'common/interfaces/sorting-direction';
import { KreoIconSortAZ, KreoIconSortZA } from 'common/UIKit/icons';
import { FilterType } from '../../filter-type';
import { Sorting } from '../../interfaces';

interface Props {
  type: FilterType;
  sorting: Sorting;
  setSorting: (type: FilterType, direction: SortingDirection) => void;
}

export class SortingIcon extends React.Component<Props> {
  public render(): JSX.Element {
    const { type, sorting } = this.props;
    const active = sorting.type === type;
    return (
      <div
        className={classNames('activity-sorting-icon', {
          active,
        })}
        onClick={this.setSorting}
        data-control-name={`sorting-toggle-AZ-${FilterType[type]}`}
      >
        {active && sorting.direction === SortingDirection.Ascending ? (
          <KreoIconSortAZ />
        ) : (
          <KreoIconSortZA />
        )}
      </div>
    );
  }

  @autobind
  private setSorting(): void {
    const { type, sorting } = this.props;
    this.props.setSorting(
      type,
      sorting.type !== type ? SortingDirection.Descending : sorting.direction * -1,
    );
  }
}
