import { Scrollbars } from '@kreo/kreo-ui-components';
import React from 'react';

import { DemoProject } from 'unit-projects/interfaces/demo-project';
import { DatabaseExampleItem } from './database-example-item';
import { Styled } from './styled';

interface Props {
  projects: DemoProject[];
  onApply: (id: number) => void;
}

export const DatabaseExampleList = (props: Props): JSX.Element => {
  return (
    <Styled.ExampleList>
      <Scrollbars autoHide={false}>
        {props.projects.map(project => (
          <DatabaseExampleItem key={project.id} project={project} onApply={props.onApply} />
        ))}
      </Scrollbars>
    </Styled.ExampleList>
  );
};
