import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import {
  KreoButton,
} from 'common/UIKit';
import { ClassificationActions } from '../../actions/creators/classification';
import { RevitTreeLevel } from '../../enums/revit-tree-level';
import { ClassificationRevitTreeItemButtonsContainer } from '../classification-revit-tree-item-buttons-container';
import {
  ClassificationRevitTreeItemContainer,
} from '../classification-revit-tree-item-container/classification-revit-tree-item-container';
import { ClassificationEditUniclassButton } from '../classificiation-edit-uniclass-button';
import { RevitTreeBaseItemBodyContent } from '../revit-tree-base-item-body-content';

interface DispatchProps {
  onExpand: () => void;
  onAssign: () => void;
  onIsolate: () => void;
  onOldAssignment: () => void;
  onItemSelect: () => void;
}

interface StateProps {
  selected: boolean;
  name: string;
  level: RevitTreeLevel;
  start: number;
  end: number;
}

interface OwnProps {
  buttonName: string;
  index: number;
  minimumLevel: RevitTreeLevel;
  isIsolatedTree: boolean;
  onHover: (start: number, end: number) => void;
  onFocus: (start: number, end: number) => void;
}

interface Props extends OwnProps, DispatchProps, StateProps { }

class ClassificationRevitTreeItemComponent extends React.PureComponent<Props> {
  private hovered: boolean = false;

  public componentWillUnmount(): void {
    if (this.hovered) {
      this.props.onHover(0, 0);
    }
  }

  public render(): React.ReactNode {
    const {
      index,
      selected,
      start,
      end,
      minimumLevel,
      isIsolatedTree,
      level,
      name,
    } = this.props;
    const isElementType = level === RevitTreeLevel.ElementType;

    return (
      <ClassificationRevitTreeItemContainer
        index={index}
        isTreeIsolated={isIsolatedTree}
        engineIdsEnd={end}
        engineIdsStart={start}
        selected={selected}
        minimumLevelInTree={minimumLevel}
        onHover={this.props.onHover}
        onFocus={this.props.onFocus}
        onClick={this.props.onItemSelect}
        isElementType={level === RevitTreeLevel.ElementType}
      >
        <RevitTreeBaseItemBodyContent
          title={name}
          count={isElementType ? end - start : null}
        />
        <ClassificationRevitTreeItemButtonsContainer
          onIsolateClick={this.onIsolate}
          showIsolationButton={!isElementType}
        >
          <ClassificationEditUniclassButton
            onClick={this.onUniclassAssignmentButtonClick}
          >
            View
          </ClassificationEditUniclassButton>
          <KreoButton
            size='medium'
            mode='action'
            rounded={true}
            onClick={this.onClickEdit}
          >
            View
          </KreoButton>
        </ClassificationRevitTreeItemButtonsContainer>
      </ClassificationRevitTreeItemContainer>
    );
  }

  @autobind
  private onIsolate(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.props.onFocus(this.props.start, this.props.end);
    this.props.onIsolate();
  }

  @autobind
  private onClickEdit(e: React.MouseEvent<HTMLButtonElement>): void {
    e.stopPropagation();
    this.props.onFocus(this.props.start, this.props.end);
    if (this.props.level === RevitTreeLevel.ElementType) {
      this.props.onAssign();
    }
  }


  @autobind
  private onUniclassAssignmentButtonClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    if (this.props.level === RevitTreeLevel.ElementType) {
      this.props.onOldAssignment();
    }
  }
}

const mapStateToProps = (state: State, { index, isIsolatedTree }: OwnProps): StateProps => {
  const item = isIsolatedTree ?
    state.classification.modelBrowserFiltered[index]
    : state.classification.modelTree[index];
  const [start, end] = item.engineIds;
  return {
    name: item.name,
    level: item.level,
    start,
    end,
    selected: index === state.classification.selectedPath,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  return {
    onExpand: () => dispatch(ClassificationActions.toggleCollapseExpandStatus(props.index)),
    onAssign: () => dispatch(ClassificationActions.toAssignment(props.index)),
    onIsolate: () => dispatch(ClassificationActions.isolateNode(props.index)),
    onOldAssignment: () => dispatch(ClassificationActions.toOldAssignment(props.index)),
    onItemSelect: () => dispatch(ClassificationActions.selectTreeNode(props.index)),
  };
};

const connector = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps);
export const ClassificationRevitTreeItemView = connector(ClassificationRevitTreeItemComponent);
