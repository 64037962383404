import { Constants } from 'common/components/data-base-table/constants';

const extendByParent = (
  list: string[],
  key: string,
  pathMap: Record<string, string>,
  usedKey: Record<string, boolean>,
): void => {
  const parentKey = pathMap[key];
  if (parentKey) {
    if (usedKey[parentKey]) {
      throw new Error('Circle dependency');
    }
    usedKey[parentKey] = true;
    list.unshift(parentKey);
    extendByParent(list, parentKey, pathMap, usedKey);
  }
};

export const getPath = (pathMap: Record<string, string>): Record<string, string> => {
  const resultMap = {};

  for (const key of Object.keys(pathMap)) {
    const parentKey = pathMap[key];
    const pathList = [parentKey, key];
    const usedKey = {
      [key]: true,
      [parentKey]: true,
    };
    extendByParent(pathList, parentKey, pathMap, usedKey);
    resultMap[key] = pathList.join(Constants.DATA_PATH_SPLITER);
  }

  return resultMap;
};
