import styled from 'styled-components';

const Container = styled.div<{ themeStyle?: boolean, select?: boolean, displayModeSelect?: boolean, zIndex?: number }>`
  position: absolute;
  overflow: overlay;
  z-index: ${p => p.zIndex ? p.zIndex : 1002};
  display:  ${props => props.themeStyle ? 'block' : 'flex'};
  flex-direction: column;
  min-width: ${props => props.displayModeSelect ? '260px' : 'auto'};
  background-color: ${props => props.themeStyle ? 'transparent' : 'white'};
  padding: ${props => props.themeStyle ? 0 : '10px 0px'};
  border-radius: ${props => props.themeStyle
    ? '15px'
    : props.select
      ? '6px'
      : '10px'
  };
  font-weight: 400;
  box-shadow: ${p => p.themeStyle ? p.theme.shadow.xl : '0 4px 14px 0 rgba(45,73,105,0.2)'};

  &::-webkit-scrollbar-track {
    margin: 15px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }
`;

export const Styled = {
  Container,
};
