import React, { useEffect, useState } from 'react';
import { NoInternetDialog } from './no-internet-dialog';


export const NoInternet: React.FC = () => {
  const [show, setShow] = useState<boolean>();
  useEffect(() => {
    window.onoffline = () => setShow(true);
  }, []);
  return show ? <NoInternetDialog/> : null;
};
