import * as t from 'io-ts';

import { SubscriptionTypeC } from 'common/constants/subscription';
import { BillingPeriodUnitC } from '../enums/billing-period-unit';
import { SubscriptionStatusC } from '../enums/subscription-status';

export const CompanyBillingInfoModelC = t.intersection(
  [
    t.type({
      status: SubscriptionStatusC,
      licensesCount: t.number,
      unitPrice: t.number,
      amount: t.number,
      billingPeriodUnit: BillingPeriodUnitC,
      billEvery: t.number,
      isMocked: t.boolean,
      planId: t.string,
      isTrial: t.boolean,
      subscriptionType: SubscriptionTypeC,
      addons: t.array(t.string),
      planName: t.string,
    }),
    t.partial({
      subscriptionId: t.string,
      paymentSourceId: t.string,
    }),
  ],
  'CompanyBillingInfoModel');

export type CompanyBillingInfoModel = t.TypeOf<typeof CompanyBillingInfoModelC>;
