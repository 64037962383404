import { SvgComponent, ProductFrame } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';

import { ProductConfigId } from '../products-page';


export interface Props {
  name: string;
  id: ProductConfigId;
  url: string;
  color: string;
  image: string;
  Logo: SvgComponent;
  RouterLink: any;
  setVideoId: (id: string) => void;
  setBackGroundImage: (image: string) => void;
  setProductId: (id: ProductConfigId) => void;
  videoId?: string;
  additionalInfo?: string;
  descriptionText?: string;
}

export const Product: React.FC<Props> = ({
  name,
  id,
  url,
  color,
  image,
  Logo,
  videoId,
  additionalInfo,
  descriptionText,
  RouterLink,
  setVideoId,
  setBackGroundImage,
  setProductId,
}) => {
  const addBackGroundImage = useCallback(() => {
    setProductId(id);
    setBackGroundImage(image);
  }, [id, image, setBackGroundImage, setProductId]);

  const clearBackGroundImage = useCallback(() => {
    setBackGroundImage(null);
    setProductId(null);
  }, [setBackGroundImage, setProductId]);

  const onClickWatchDemo = useCallback(() => {
    setVideoId(videoId);
  }, [setVideoId, videoId]);

  const watchDemoClick = useMemo(() => videoId ? onClickWatchDemo : null, [onClickWatchDemo, videoId]);

  return (
    <ProductFrame
      name={name}
      id={id}
      Logo={Logo}
      url={url}
      color={color}
      watchDemoClick={watchDemoClick}
      onMouseEnter={addBackGroundImage}
      onMouseLeave={clearBackGroundImage}
      additionalInfo={additionalInfo}
      descriptionText={descriptionText}
      RouterLink={RouterLink}
    />
  );
};
