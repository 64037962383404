import * as React from 'react';
import { connect } from 'react-redux';

import './classification-assembly-info-layout.scss';

import { State } from 'common/interfaces/state';
import { CurrentElementClassification } from '../../interfaces/classification/current-element-classification';
import { ClassificationAssemblyEntityInfo } from '../classification-assembly-entity-info';

interface OwnProps {
  isDisabled?: boolean;
}

interface StateProps {
  currentElementClassification: CurrentElementClassification[][];
}

interface Props extends StateProps, OwnProps {
}

const ClassificationAssemblyInfoLayoutComponent: React.FC<Props> = (props) => {
  return (
    <div className='classification-assembly-info-layout'>
      {
        props.currentElementClassification.map((_value, index) => {
          const currentClassification = props.currentElementClassification[index][0];
          const title = index === 0 ?
            currentClassification.systemDescription : currentClassification.productDescription;
          const code = index === 0 ? currentClassification.systemCode : currentClassification.productCode;
          return (
            <ClassificationAssemblyEntityInfo
              isDisabled={props.isDisabled}
              key={index}
              title={title}
              code={code}
              isAssembly={index === 0}
              index={index}
            />
          );
        })
      }
    </div>);
};


function mapStateToProps(state: State): StateProps {
  return {
    currentElementClassification: state.classification.elementClassification,
  };
}

const connector = connect(mapStateToProps);
export const ClassificationAssemblyInfoLayout = connector(ClassificationAssemblyInfoLayoutComponent);
