export enum Subject {
  Billing = 'Billing',
  BillingPeriod = 'BillingPeriod',
  AdminPanel = 'AdminPanel',
  Role = 'Role',
  Viewer = 'Viewer',
  ViewerComments = 'ViewerComments',
  Payment = 'Payment',
  Project = 'Project',
  ProjectAdministration = 'ProjectAdministration',
  DemoProject = 'DemoProject',
  ValidationResults = 'ValidationResults',
  ValidationMeasurements = 'ValidationMeasurements',
  ValidationActivityAssignment = 'ValidationActivityAssignment',
  ValidationClassification = 'ValidationClassification',
  ValidationModelCheck = 'ValidationModelCheck',
  InactiveScenarios = 'InactiveScenarios',
  Application = 'Application',
  KreoProvidedDb = 'KreoProvidedDb',
  SequenceStep = 'SequenceStep',
  PlanFeatures = 'PlanFeatures',
  Company = 'Company',
  Guests = 'Guests',
  Employees = 'Employees',
  Subcontractors = 'Subcontractors',
  Database = 'Database',
  Cost = 'Cost',
  CostEstimate = 'CostEstimate',
  Gantt = 'Gantt',
  GanttResources = 'GanttResources',
  Dashboard = 'Dashboard',
  Scenarios = 'Scenarios',
  BidPricing = 'BidPricing',
  BidPricingInvitation = 'BidPricingInvitation',
  BidPricingWorkPackages = 'BidPricingWorkPackages',
  FourDVisualisation = 'FourDVisualisation',
  QuantityTakeOff3d = 'QuantityTakeOff3d',
  QuantityTakeOffReport = 'QuantityTakeOffReport',
  QuantityTakeOff2d = 'QuantityTakeOff2d',
  QuantityTakeOffCustomFilters = 'QuantityTakeOffCustomFilters',
  ElementUniclassClassification = 'ElementUniclassClassification',
  ModelManagement = 'ModelManagement',
  SmartLook = 'SmartLook',
  OpenReplay = 'OpenReplay',
  ImportSchedules2D = 'ImportSchedules2D',
  Takeoff2DReport = 'Takeoff2DReport',
  Takeoff2DMeasurement = 'Takeoff2DMeasurement',
  Takeoff2DAutoMeasure = 'Takeoff2DAutoMeasure',
  Takeoff2dAnnotations = 'Takeoff2dAnnotations',
  Takeoff2dMeasurement3d = 'Takeoff2dMeasurement3d',
  Takeoff2dMeasurementAutocomplete = 'Takeoff2dMeasurementAutocomplete',
  OldPaymentUI = 'OldPaymentUI',
  Project2DTemplates = 'Project2DTemplates',
  PiaDatabase = 'PiaDatabase',
  Takeoff2dPiaAssignment = 'TakeoffPiaAssignment',
  Takeoff2dPageColor = 'Takeoff2dPageColor',
  PauseSubscription = 'PauseSubscription',
  Subscriptions = 'Subscriptions',
  Comment2d = 'Comment2d',
  Notification2d = 'Notification2d',
  RemindLaterTakeSurvey = 'RemindLaterTakeSurvey',
  ShowCommentaries = 'ShowCommentaries',
  TwoDCopilot = 'TwoDCopilot',
  Takeoff2dAiSuggest = 'Takeoff2dAiSuggest',
  Takeoff2dAutoScale = 'Takeoff2dAutoScale',
  Takeoff2dAutoCount = 'Takeoff2dAutoCount',
  Project2DPagesCompare = 'Project2DPagesCompare',
  Takeoff2dCaddie = 'Takeoff2dCaddie',
  Takeoff2dGroupTemplates = 'Takeoff2dGroupTemplates',
  Takeoff2dPdfExport = 'Takeoff2dPdfExport',
  TakeOff2dFileConverter = 'TakeOff2dFileConverter',
  Takeoff2dFileOptimizer = 'Takeoff2dFileOptimizer',
  Takeoff2dExcelImport = 'Takeoff2dExcelImport',
  Takeoff2dReportTemplate = 'TwoDReportTemplate',
  Takeoff2dReportTemplateShow = 'Takeoff2dReportTemplateShow',
  Takeoff2dWizzardShow = 'Takeoff2dWizzardShow',
  Takeoff2dWizzardFinderShow = 'Takeoff2dWizzardFinderShow',
  Takeoff2dWizzardFinderAccess = 'Takeoff2dWizzardFinderAccess',
  Takeoff2dWizzardAccess = 'Takeoff2dWizardAccess',
  Takeoff2dPdfFilter = 'Takeoff2dPdfFilter',
  Takeoff2dOneClickAreaHover = 'Takeoff2dOneClickAreaHover',
  ProposeExtendSubscription = 'ProposeExtendSubscription',
  ShareProjects = 'ShareProjects',

  SignalRLogging = 'SignalRLogging',
  MagicSearch = 'MagicSearch',
  AutoMeasure2 = 'AutoMeasure2',
}
