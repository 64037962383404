import * as t from 'io-ts';

export interface MessageType<DataT> {
  clientId: string;
  connectionId: string;
  connectionKey: string;
  data: DataT;
  encoding: string;
  extras: string;
  id: string;
  name: string;
  timestamp: number;
}

const nullableAndUndefinedString = t.union([t.string, t.null, t.undefined]);
export const MessageTypeC = <C extends t.Mixed>(dataT: C): t.Any =>
  t.strict(
    {
      data: dataT,
      id: t.string,
      timestamp: t.number,
      clientId: nullableAndUndefinedString,
      connectionId: nullableAndUndefinedString,
      connectionKey: nullableAndUndefinedString,
      encoding: nullableAndUndefinedString,
      extras: nullableAndUndefinedString,
      name: nullableAndUndefinedString,
    },
    'MessageType',
  );
