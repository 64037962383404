import React from 'react';

import { Category } from '../category';
import { ColorGroup } from './color-group';


interface Props {
  colorInstances: Record<string, string[]>;
}

const ColorCategoryComponent: React.FC<Props> = ({
  colorInstances,
}) => {
  return (
    <Category title='Color'>
      {
        Object.entries(colorInstances).map(([color, instances]) => {
          return (
            <ColorGroup
              key={color}
              instancesIds={instances}
              color={color}
            />
          );
        })
      }
    </Category>
  );
};

export const ColorCategory = React.memo(ColorCategoryComponent);
