import autobind from 'autobind-decorator';

import {
  Updater,
  Params,
} from 'unit-2d-database/components/side-panel/helpers/update-form-state';
import {
  PropertyField,
} from 'unit-2d-database/components/side-panel/helpers/update-property-form';
import { Item, UpdateItemRequest } from 'unit-2d-database/interfaces';
import { GroupForm } from '../item-panel/helpers/map-property-group-form';
import {
  IPropertyGroupUpdater,
  ItemForm,
  PropertyGroupUpdater,
  PropertyGroupUpdaterParams,
} from '../item-panel/helpers/property-group-form-updater';

export interface ItemGroupFormData {
  id: string;
  originId: string;
  name: string;
  iconType: string;
  itemForm: ItemForm;
  onDelete: () => void;
}

export interface AssemblyForm {
  addedItems: Record<string, ItemGroupFormData>;
  removedItems: Record<string, ItemGroupFormData>;
  itemGroupForm: Record<string, ItemGroupFormData>;
}

export interface AssemblyData {
  addedItems: Item[];
  updatedItems: UpdateItemRequest[];
  deletedItems: string[];
}

export type ItemGroupHandlers = [
  () => void,
];

export interface IItemUpdater {
  getPropertyGroupUpdater: (itemId: string) => IPropertyGroupUpdater;
  getItemHandlers: (itemId: string) => ItemGroupHandlers;
  updateAssemblyForm(item: Record<string, ItemGroupFormData>): void;
  applyNewAfterEffects(baseParams: Params<AssemblyForm>, params: ItemGroupUpdaterParams<AssemblyForm>): void;
  addPropertyGroup(itemId: string, propertyGroup: GroupForm): void;
  changeFormula(itemId: string, groupsForm: GroupForm, newField: PropertyField, groupName: string): void;
}

export interface ItemGroupUpdaterParams<T> extends PropertyGroupUpdaterParams<T> {
  afterDeleteItem: (form: T, itemId: string, ...args: any) => void;
  afterAddItem: (form: T, itemId: string, ...args: any) => void;
}

export class ItemUpdater
  extends Updater<AssemblyForm, ItemForm>
  implements IItemUpdater {
  private updaterParams: ItemGroupUpdaterParams<AssemblyForm>;
  public constructor(
    baseParams: Params<AssemblyForm>,
    params: ItemGroupUpdaterParams<AssemblyForm>,
  ) {
    super(baseParams);
    this.updaterParams = params;
  }

  public static getData(form: AssemblyForm): AssemblyData {
    const { addedItems, removedItems, itemGroupForm  } = form;

    const addItems: Item[] = [];
    const updatedItems: UpdateItemRequest[] = [];
    const deletedItems: string[] = Object.values(removedItems).map(i => i.id);


    Object.values(itemGroupForm).forEach((groupFrom) => {
      const propertyGroupData = PropertyGroupUpdater.getData(groupFrom.itemForm);
      if (addedItems[groupFrom.id]) {
        addItems.push({
          id: groupFrom.id,
          name: groupFrom.name,
          properties: propertyGroupData.updatedProperties.concat(propertyGroupData.addedProperties),
          iconType: groupFrom.iconType,
          baseItemId: groupFrom.originId,
        });
        return;
      }

      updatedItems.push({
        id: groupFrom.id,
        name: groupFrom.name,
        iconType: groupFrom.iconType,
        baseItemId: groupFrom.originId,
        ...propertyGroupData,
      });
    });

    return {
      addedItems: addItems,
      updatedItems,
      deletedItems,
    };
  }

  public getData(): AssemblyData {
    return ItemUpdater.getData(this.getForm());
  }

  public getPropertyGroupUpdater(itemId: string): IPropertyGroupUpdater {
    const { afterDeletePropertyGroup, afterAddProperty, afterChangeFormula } = this.updaterParams;
    return new PropertyGroupUpdater(
      {
        getForm: () => this.getElement(this.getForm(), itemId),
        afterEffects: {
          afterChange: this.bindHandler(itemId, this.applyChanges, () => this.afterChange),
          afterDelete: this.bindHandler(itemId, this.applyDeleteProperty, () => this.afterDelete),
          afterUnitChange: this.bindHandler(itemId, this.applyUnitChange, () => this.afterUnitChange),
          afterVisibilityChange:
            this.bindHandler(itemId, this.applyVisibilityChange, () => this.afterVisibilityChange),
        },
      },
      {
        afterAddProperty: this.bindHandler(itemId, this.applyAddedProperty, () => afterAddProperty),
        afterDeletePropertyGroup:
          this.bindHandler(itemId, this.applyDeletePropertyGroup, () => afterDeletePropertyGroup),
        afterChangeFormula:
          this.bindHandler(itemId, this.applyChanges, () => afterChangeFormula),
      },
    );
  }

  @autobind
  public getItemHandlers(itemId: string): ItemGroupHandlers {
    return [
      this.bindHandler(itemId, this.applyDeleteItemGroup, () => this.updaterParams.afterDeleteItem),
    ];
  }

  @autobind
  public updateAssemblyForm(item: Record<string, ItemGroupFormData>): void {
    this.bindHandler(undefined, this.applyAddedItemGroupFrom, () => this.updaterParams.afterAddItem)(item);
  }

  @autobind
  public applyNewAfterEffects(
    baseParams: Params<AssemblyForm>,
    params: ItemGroupUpdaterParams<AssemblyForm>): void {
    super.updateParams(baseParams);
    this.updaterParams = params;
  }

  @autobind
  public addPropertyGroup(itemId: string, propertyGroup: GroupForm): void {
    const propertyGroupUpdater = this.getPropertyGroupUpdater(itemId);
    propertyGroupUpdater.updateItemForm(propertyGroup);
  }

  public changeFormula(itemId: string, groupsForm: GroupForm, newField: PropertyField, groupName: string): void {
    const propertyUpdater = this.getPropertyGroupUpdater(itemId);
    propertyUpdater.changeFormula(groupsForm, newField, groupName);
  }

  @autobind
  protected override getElement(form: AssemblyForm, itemId: string): ItemForm {
    return form.itemGroupForm[itemId].itemForm;
  }

  @autobind
  private applyChanges(assemblyForm: AssemblyForm, itemId: string, itemForm: ItemForm): void {
    assemblyForm.itemGroupForm[itemId].itemForm = itemForm;
  }

  @autobind
  private applyUnitChange(assemblyForm: AssemblyForm, itemId: string, itemForm: ItemForm): void {
    assemblyForm.itemGroupForm[itemId].itemForm = itemForm;
  }

  @autobind
  private applyVisibilityChange(assemblyForm: AssemblyForm, itemId: string, itemForm: ItemForm): void {
    assemblyForm.itemGroupForm[itemId].itemForm = itemForm;
  }

  @autobind
  private applyAddedItemGroupFrom(
    assemblyForm: AssemblyForm,
    _: string,
    updateForm: Record<string, ItemGroupFormData>,
  ): void {
    for (const form of Object.values(updateForm)) {
      if (form.name in assemblyForm.removedItems && assemblyForm.removedItems[form.name].originId === form.originId) {
        delete assemblyForm.removedItems[form.name];
      }
      assemblyForm.addedItems[form.id] = form;
      assemblyForm.itemGroupForm[form.id] = form;
    }
  }

  @autobind
  private applyAddedProperty(assemblyForm: AssemblyForm, itemId: string, itemForm: ItemForm): void {
    assemblyForm.itemGroupForm[itemId].itemForm = itemForm;
  }

  @autobind
  private applyDeleteItemGroup(assemblyForm: AssemblyForm, itemId: string): void {
    if (assemblyForm.addedItems[itemId]) {
      delete assemblyForm.addedItems[itemId];
    } else {
      assemblyForm.removedItems[itemId] = assemblyForm.itemGroupForm[itemId];
    }
    delete assemblyForm.itemGroupForm[itemId];
  }

  @autobind
  private applyDeletePropertyGroup(
    assemblyForm: AssemblyForm,
    itemId: string,
    form: ItemForm,
  ): void {
    assemblyForm.itemGroupForm[itemId].itemForm = form;
  }

  @autobind
  private applyDeleteProperty(assemblyForm: AssemblyForm, itemId: string, itemForm: ItemForm): void {
    assemblyForm.itemGroupForm[itemId].itemForm = itemForm;
  }
}
