import * as React from 'react';

export const AlertError: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/14x14/Attention_Small'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <circle id='Oval-3' fill='#FF6652' cx='7' cy='7' r='6' />
        <path
          d='M6.49544305,4 L7.49544305,4 L7.49544305,8 L6.49544305,8 L6.49544305,4 Z M6.49544305,9 L7.49544305,
          9 L7.49544305,10 L6.49544305,10 L6.49544305,9 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
