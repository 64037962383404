import styled from 'styled-components';

import {
  DrawingsPdfStyled,
} from 'common/components/drawings/drawings-pdf-browser/drawings-pdf-browser-content/drawings-pdf';
import {
  DrawingsPdfBrowserPagesStyled,
} from 'common/components/drawings/drawings-pdf-browser/drawings-pdf-browser-content/drawings-pdf-browser-pages';
import { CommonScrollStyle } from 'components/scroll-style';
import { BackdropStyled } from 'unit-2d-database/components/side-panel/components';

const TwoDPageContainer = styled.div<{ isHide: boolean, isShowLink: boolean }>`
  position: absolute;
  top:${p => p.isShowLink ? 25 : 0}px;
  width: 100%;
  visibility: ${p => p.isHide ? 'hidden' : ''};
  opacity: ${p => p.isHide ? '0' : '1'};
  height: ${p => p.isShowLink ? 'calc(100% - 25px)' : '100%'};

  > div:focus-visible {
    outline: none;
  }
`;

const DrawingsPdfBrowserContainer = styled.div<{ isHide: boolean }>`
  position: absolute;
  top: 25px;
  width: 100%;
  visibility: ${p => p.isHide ? 'hidden' : ''};
  opacity: ${p => p.isHide ? '0' : '1'};
  height: calc(100% - 25px);

  ${DrawingsPdfStyled.Container} {
    height: 100%;
    width: 100%;
    right: 0;
    border-radius: 0;
    animation: none;
  }

  ${DrawingsPdfBrowserPagesStyled.ContainerFullScreen} {
    display: none;
  }

  ${DrawingsPdfBrowserPagesStyled.PagesContainer} {
    grid-template-columns: repeat(auto-fill,minmax(480px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(270px, 1fr));

    > div {
      height: 100%;
      width: 100%;
      border-radius: 15px;
      max-height: 390px;
      min-height: 390px;

      > div:first-child {
        width: fit-content;
      }
    }
  }
`;

const TwoDDatabaseContainer = styled.div<{ isHide: boolean }>`
  position: absolute;
  top: 25px;
  width: 100%;
  visibility: ${p => p.isHide ? 'hidden' : ''};
  opacity: ${p => p.isHide ? '0' : '1'};
  height: calc(100% - 25px);
  background-color: ${p => p.theme.color.backgroundRush};
  overflow: hidden;

  ${BackdropStyled.Backdrop} {
    backdrop-filter: blur(0px);
  }
`;

const UndoRedoControllerWrapper = styled.div`
  height: 100%;
  ${CommonScrollStyle}
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.color.backgroundRush};
`;

export const Styled = {
  TwoDPageContainer,
  TwoDDatabaseContainer,
  DrawingsPdfBrowserContainer,
  UndoRedoControllerWrapper,
  SpinnerContainer,
};
