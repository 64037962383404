import React, { useEffect } from 'react';

import { HELP_TOOLTIP_MENU } from '../../drawings-context-menu-renderers';
import { DrawingsContextMenuItem } from '../drawings-context-menu-config';
import { DrawingsContentMenuItemWrapper } from '../drawings-context-menu-item-wrapper';
import { ActionEventHandlers, OptionsConfig } from '../interfaces';
import { shouldShowOption } from './utils';

interface Props {
  filters: Record<string, boolean>;
  items: DrawingsContextMenuItem[];
  actionEventHandlers: Record<string, ActionEventHandlers>;
  optionsConfig: Record<string, OptionsConfig>;
  isShowTwoDReport: boolean;
  onClose: () => void;
  doActionAndClose: (action: (...args: any) => void, ...args: any) => void;
}

const DrawingsContextMenuContentComponent: React.FC<Props> = (
  {
    filters,
    items,
    actionEventHandlers,
    optionsConfig,
    isShowTwoDReport,
    doActionAndClose,
    onClose,
  },
) => {
  const result = new Array<React.ReactNode>();
  for (const item of items) {
    if (!shouldShowOption(item, filters)) {
      continue;
    }

    const eventHandlers = actionEventHandlers[item.action];
    if (!eventHandlers) {
      continue;
    }
    const icon = item.icon;
    if (item.multilevel) {
      const config = optionsConfig[item.optionsGetter];
      if (!config) {
        continue;
      }
      const options = config.getOptions();
      const canShow = isShowTwoDReport;

      result.push(
        <DrawingsContentMenuItemWrapper
          key={item.action}
          badge={item.badge}
          {...eventHandlers}
          options={canShow && options}
          icon={icon}
          optionContentRenderer={config.optionsRenderer}
          childRenderer={canShow && config.childRenderer}
          helpToolTip={!canShow && HELP_TOOLTIP_MENU}
          disabled={!canShow}
          doActionAndClose={doActionAndClose}
        >
          {item.action}
        </DrawingsContentMenuItemWrapper>,
      );
    } else {
      result.push(
        <DrawingsContentMenuItemWrapper
          key={item.action}
          badge={item.badge}
          hotkey={item.hotKey}
          icon={icon}
          {...eventHandlers}
          doActionAndClose={doActionAndClose}
        >
          {item.action}
        </DrawingsContentMenuItemWrapper>,
      );
    }
  }

  useEffect(() => {
    if (!result.length) {
      onClose();
    }
  }, [result.length]);

  if (!result) {
    return null;
  }
  return <>{result}</>;
};

export const DrawingsContextMenuContent = React.memo(DrawingsContextMenuContentComponent);

