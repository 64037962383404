import { useSelector } from 'react-redux';
import { DrawingsMeasureUtils } from 'common/components/drawings';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { DrawingsSelectAggregationGroup } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';


export function useResultType(): DrawingsInstanceType {
  const { drawMode } = useDrawModeApi();
  const wizzardWandIsPolygon = useSelector<State, boolean>(s => {
    return s.drawings.wizzardTools.connect && s.drawings.wizzardTools.enclose;
  });
  const dropperType = useSelector<State, DrawingsSelectAggregationGroup>(
    s => s.drawings.wizzardTools.dropperInstanceType,
  );
  return DrawingsMeasureUtils.getInstanceTypeFromDrawMode({
    drawMode,
    wizzardIsPolygon: wizzardWandIsPolygon,
    dropperType,
    finderIsCount: true,
  }) as DrawingsInstanceType;
}
