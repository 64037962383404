import {
  CompanyNotificationsCollectionViewModel,
  CompanyNotificationViewMode,
} from 'unit-2d-notification/api/interfaces';
import { CompanyNotification } from '../interfaces';
import { CompanyNotificationData } from '../interfaces/notification-data';

export const mapCompanyNotification = (
  notifications: CompanyNotificationViewMode[],
): CompanyNotification[] => {
  return notifications.map(n => {
    return {
      id: n.id,
      createAt: new Date(n.createdAt),
      data: n.data as CompanyNotificationData,
      initiatorId: n.initiatorId,
      project: n.project,
    };
  });
};

export const mapNotificationModelToState = (
  model: CompanyNotificationsCollectionViewModel,
): [CompanyNotification[], Date] => {
  const lastAccess = model.lastAccess ? new Date(model.lastAccess) : null;
  const notifications: CompanyNotification[] = mapCompanyNotification(model.notifications);

  return [notifications, lastAccess];
};
