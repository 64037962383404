import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';

interface MeasurementsAbilities {
  canEditMeasurements: boolean;
  canViewMeasurements: boolean;
}

export function useCanEditMeasurements(): boolean {
  return useAbility(Operation.Update, Subject.Takeoff2DMeasurement);
}

export function useMeasurementsAbilities(): MeasurementsAbilities {
  const canEditMeasurements = useCanEditMeasurements();
  const canViewMeasurements = useAbility(Operation.Read, Subject.Takeoff2DMeasurement);

  return {
    canEditMeasurements,
    canViewMeasurements,
  };
}
