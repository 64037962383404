import { ExcelFormulaHelper } from 'common/components/excel-table';

export function REPLACE(old_text: string, start_num: number, num_chars: number, new_text: string): string {
  // Convert start_num and num_chars to integers
  start_num = Math.floor(start_num);
  num_chars = Math.floor(num_chars);

  // Check if start_num and num_chars are valid
  if (start_num <= 0 || num_chars < 0) {
    return ExcelFormulaHelper.INVALID_VALUE;
  }

  const text = old_text.toString();
  // Split the old_text string into two parts
  const part1 = text.substring(0, start_num - 1);
  const part2 = text.substring(start_num - 1 + num_chars);

  // Combine the parts with the new_text string
  const new_string = part1 + new_text + part2;

  return new_string;
}
