import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { CompaniesSelectWithTheme } from 'common/components/companies-select-with-theme';
import { PageHeader } from 'common/components/page-header';
import { SubscriptionType } from 'common/constants/subscription';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { AppUrls } from '../../../../routes/app-urls';
import { SubscriptionActions } from '../../actions/creators';
import { SubscriptionEditor } from '../../components/subscription-dialog/subscription-editor';
import { CompanyBillingInfoModel } from '../../interfaces/company-billing-info-model';
import { Styled } from './styled';


interface StateProps {
  subscriptionType: SubscriptionType;
  ownCompanyBillingInfoRequestStatus: RequestStatus;
  ownCompanyBillingInfo: CompanyBillingInfoModel;
}

interface DispatchProps {
  getOwnCompanyBillingInfo: (subscriptionType: SubscriptionType) => void;
  dropOwnCompanyBillingInfoState: () => void;
  renewSubscription: (subscriptionId: string) => void;
}

interface PageProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

class UpdateSubscriptionPageComponent extends React.Component<PageProps> {
  public componentDidMount(): void {
    this.props.getOwnCompanyBillingInfo(this.props.subscriptionType);
  }

  public render(): React.ReactNode {
    return (
      <Styled.Container>
        <Styled.Header>
          <PageHeader
            redirectUrl={AppUrls.products.path}
            logoSrc='/static/icons/kreo-logo-description.svg'
            hideButton={true}
          >
            <CompaniesSelectWithTheme />
          </PageHeader>
        </Styled.Header>
        <Styled.Main>
          <Styled.Message>You need to update your subscription or contact with support.</Styled.Message>
          <SubscriptionEditor />
        </Styled.Main>
      </Styled.Container>
    );
  }

  public componentWillUnmount(): void {
    this.props.dropOwnCompanyBillingInfoState();
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    subscriptionType: state.account.selectedSubscriptionType,
    ownCompanyBillingInfoRequestStatus: state.account.requests.ownCompanyBillingInfo,
    ownCompanyBillingInfo: state.account.ownCompanyBillingInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    renewSubscription: (subscriptionId) => dispatch(SubscriptionActions.renewSubscription(subscriptionId)),
    getOwnCompanyBillingInfo: type => dispatch(SubscriptionActions.fetchOwnCompanyBillingInfo(type)),
    dropOwnCompanyBillingInfoState: () => dispatch(SubscriptionActions.dropOwnCompanyBillingInfoState()),
  };
}

export const UpdateSubscriptionPage = connect(mapStateToProps, mapDispatchToProps)(UpdateSubscriptionPageComponent);
