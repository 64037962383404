import React from 'react';

import { Styled } from './styled';


export const ValueWithEdit: React.FC = ({ children }) => {
  return (
    <Styled.ValueContainer>
        {children}
    </Styled.ValueContainer>
  );
};
