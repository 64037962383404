import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';
import { ShareWithCompanyStyled } from 'common/components/share-with-company';


const CONTAINER_WIDTH = 800;


const Container = styled.div`
  background: ${p => p.theme.color.backgroundRush};
  width: ${CONTAINER_WIDTH}px;
  max-height: 560px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  animation: ${Constants.Animations.modalAppear}  ${p => p.theme.duration.m} ease-in-out;
  form {
    max-height: 100%;
    display: flex;
    .child-context {
      width: 100%;
      max-height: 100%;
      display: flex;
    }
  }

  ${ShareWithCompanyStyled.Container} {
    margin-bottom: 10px;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SeparatorTop = styled.div`
  background: ${p => p.theme.color.pale};
  height: 10px;
  margin: 20px -40px 10px;
`;

const SeparatorBottom = styled.div`
  background: ${p => p.theme.color.pale};
  height: 10px;
  margin: 10px -40px 20px;
`;

const FormContentGroup = styled.div`
  width: 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 10px 0px;
  border-right: 1px solid ${p => p.theme.color.background};
  display: flex;
  flex-direction: column;
`;

const Files = styled(FormContentGroup)`
  padding: 30px;
  box-sizing: border-box;
  border-right: unset;
  height: 560px;
`;


const Separator = styled.div`
  background: ${p => p.theme.color.pale};
  height: 10px;
  width: 100%;
`;

const CreateSeparator = styled(Separator)`
  margin-top: auto;
`;

const FieldGroup = styled.div`
  padding: 20px 30px;
  > label {
    margin-bottom: unset;
  }
`;


const InvitePeopleGroup = styled(FieldGroup)<{ isInvitedPeople: boolean }>`
  flex-grow: 1;
  height: 210px;
  box-sizing: border-box;
  padding: ${p => p.isInvitedPeople ? 10 : 20}px 30px;


  ${UpgradeWrapperStyled.StarsContainer} {
    top: -10px;
    right: -10px;
  }
`;

const InvitePeople = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  height: 60px;
  border-radius: 15px;
  border: 1px solid ${p => p.theme.color.background};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  svg {
    height: 20px;
    margin-right: 35px;
    fill: ${p => p.theme.color.turquoise};
  }
`;

const UsersListContainer = styled.div`
  margin: 10px 0px;
`;


export const Styled = {
  Container,
  SeparatorTop,
  SeparatorBottom,
  FormContent,
  FieldGroup,
  FormContentGroup,
  Separator,
  CreateSeparator,
  InvitePeople,
  UsersListContainer,
  InvitePeopleGroup,
  Files,
};
