import * as React from 'react';

export const KreoIconRuler: React.FC = () => {
  return (
    <svg
      width='18px'
      height='10px'
      viewBox='0 0 18 10'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Elements' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='DrawingTools'
          transform='translate(-373.000000, -221.000000)'
          fill='currentColor'
          fillRule='nonzero'
        >
          <g id='Group-22'>
            <g id='Group-14' transform='translate(357.000000, 120.000000)'>
              <g id='Group-3'>
                <g id='Tools-Copy-11' transform='translate(0.000000, 81.000000)'>
                  <g id='Group-2' transform='translate(5.000000, 5.000000)'>
                    <g id='Icon-Icons/24x24/Ruler' transform='translate(8.000000, 8.000000)'>
                      <path
                        // eslint-disable-next-line max-len
                        d='M7,8 L7,11 L6,11 L6,8 L4.5,8 C4.22385763,8 4,8.22385763 4,8.5 L4,15.5 C4,15.7761424 4.22385763,16 4.5,16 L19.5,16 C19.7761424,16 20,15.7761424 20,15.5 L20,8.5 C20,8.22385763 19.7761424,8 19.5,8 L19,8 L19,11 L18,11 L18,8 L16,8 L16,13 L15,13 L15,8 L13,8 L13,11 L12,11 L12,8 L10,8 L10,13 L9,13 L9,8 L7,8 Z M4.5,7 L19.5,7 C20.3284271,7 21,7.67157288 21,8.5 L21,15.5 C21,16.3284271 20.3284271,17 19.5,17 L4.5,17 C3.67157288,17 3,16.3284271 3,15.5 L3,8.5 C3,7.67157288 3.67157288,7 4.5,7 Z'
                        id='icon'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
