import * as React from 'react';
import { connect } from 'react-redux';

import './classification-ontology-tree-body.scss';

import { State } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { ClassificationUtils } from '../../utils/classification-utils';
import { ClassificationAssignmentLayout } from '../classification-assignment-layout';
import { ClassificationOntologyTreeElement } from '../classification-ontology-tree-element';


interface OwnProps {
  isEdit: boolean;
}

interface StateProps {
  graphsCount: number;
  showFooter: boolean;
}

interface Props extends StateProps, OwnProps {

}

class ClassificationOntologyTreeBodyComponent extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    return (
      <ClassificationAssignmentLayout
        showFooter={this.props.showFooter}
        showHeader={false}
        footerSaveOnly={true}
      >
        <div className='classification-ontology-tree-body__content'>
          <KreoScrollbars showShadowTop={true}>
            <div className='classification-ontology-tree-body__wrapper'>
              {this.renderElements()}
            </div>
          </KreoScrollbars>
        </div>
      </ClassificationAssignmentLayout>
    );
  }

  private renderElements(): React.ReactNode[] {
    const elements = new Array<React.ReactNode>(this.props.graphsCount);
    for (let i = 0; i < this.props.graphsCount; i++) {
      elements[i] = (
        <ClassificationOntologyTreeElement
          isEdit={this.props.isEdit}
          key={i}
          count={this.props.graphsCount}
          index={i}
        />);
    }
    return elements;
  }
}

function mapStateToProps(state: State): StateProps {
  const lastBreadCrumb = ClassificationUtils.getLastBreadCrumb(state.classification.modelBrowserFilters);
  return {
    graphsCount: state.classification.elementOntologyGraph.length,
    showFooter: state.classification.isSaveButtonEnabled || lastBreadCrumb.multiple,
  };
}

const connector = connect(mapStateToProps);
export const ClassificationOntologyTreeBody = connector(ClassificationOntologyTreeBodyComponent);
