import Ag from 'ag-grid-community';
import { detailedDiff } from 'deep-object-diff';
import { isDiffEmpty } from 'utils/object-diff';
import { patch } from '../patch';

interface Diff {
  added: Record<string, any>;
  updated: Record<string, any>;
  deleted: Record<string, any>;
}

export class TransactionGenerator {
  private prevDataMap: Record<string, any> = {};

  public getTransaction(newDataMap: Record<string, any>): Ag.RowDataTransaction | null {
    if (this.prevDataMap === newDataMap) {
      return null;
    }

    const diff = detailedDiff(this.prevDataMap, newDataMap) as Diff;

    if (isDiffEmpty(diff)) {
      this.updatePrevDataMap(newDataMap);
      return null;
    }
    const transaction = patch(diff, (id) => this.prevDataMap[id]);
    this.updatePrevDataMap(newDataMap);
    return transaction;
  }

  private updatePrevDataMap(newDataMap: Record<string, any>): void {
    this.prevDataMap = newDataMap;
  }
}
