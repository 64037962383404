import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsRenderParams } from 'common/components/drawings/interfaces';
import { StrokeParams } from 'common/components/drawings/interfaces/stroke-params';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { VisibleEntity, VisibleEntityConfig } from '../../../common';
import { DrawingsMouseEventHandler } from '../../base';


export type LineGeometry = [paper.Point, paper.Point];

export interface Config extends VisibleEntityConfig<LineGeometry>{
  layer: paper.Layer | paper.Group;
  color: paper.Color;
  geometry: LineGeometry;
  strokeStyled: StrokeParams;
  onMouseEnter?: DrawingsMouseEventHandler;
  onMouseLeave?: DrawingsMouseEventHandler;
}

export class Line<C extends Config = Config> extends VisibleEntity<LineGeometry, C>  {
  protected _line: paper.Path.Line;

  constructor(config: C) {
    super(config);
    this._config.renderParamsContextObserver.subscribe(this.updateZoom);
  }

  public override destroy(): void {
    this._config.renderParamsContextObserver.unsubscribe(this.updateZoom);
    this._line.remove();
  }

  public updateGeometry(geometry: LineGeometry): void {
    for (let i = 0; i < 2; i++) {
      this._line.segments[i].point = geometry[i];
    }
  }

  public changeColor(color: paper.Color): void {
    this._config.color = color;
    this.applyColor(color);
  }

  public changeStrokeStyle(strokeParams: StrokeParams): void {
    this._config.strokeStyled = strokeParams;
    this.applyStrokeStyles(strokeParams, this._config.renderParamsContextObserver.getContext().zoom);
  }

  protected override render(geometry: LineGeometry): void {
    this._line = new paper.Path.Line(geometry[0], geometry[1]);
    this._line.strokeWidth = 1;
    this.applyColor(this._config.color);
    this._line.addTo(this._config.layer);
    this._line.onMouseEnter = this.onMouseEnter;
    this._line.onMouseLeave = this.onMouseLeave;
  }

  protected applyStrokeStyles(strokeStyle: StrokeParams, zoom: number): void {
    this._line.strokeWidth = strokeStyle.strokeWidth / zoom;
    this._line.dashArray = DrawingsCanvasUtils.scaleStroke(strokeStyle, zoom);
  }

  protected applyColor(color: paper.Color): void {
    this._line.strokeColor = color;
  }

  @autobind
  private updateZoom({ zoom }: DrawingsRenderParams): void {
    this.applyStrokeStyles(this._config.strokeStyled, zoom);
  }

  @autobind
  private onMouseEnter(e: PaperMouseEvent): void {
    this._config.onMouseEnter?.(this._config.id, e);
  }

  @autobind
  private onMouseLeave(e: PaperMouseEvent): void {
    this._config.onMouseLeave?.(this._config.id, e);
  }
}
