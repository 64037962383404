import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { BillingAddressStyled } from '../../payment-method-manager/billing-address';
import { TeamSizeStyled } from '../../subscription-details/team-size';


const FillStepScaleContainer = styled.div`
`;

const ContentContainer = styled.div<{ headerMargin: number }>`
  margin-left: 10px;
  
  > ${Text} {
    margin-bottom: ${p => p.headerMargin}px;
  }

  ${BillingAddressStyled.VatCountryLabel} {
    margin-left: 20px;
  }

  ${BillingAddressStyled.Column} {
    &:first-child {
      width: 280px;
    }
  }

  ${TeamSizeStyled.Counter} {
    margin-top: 19px;

    > button {
      height: 40px;
      width: 40px;
    }
  }

  ${TeamSizeStyled.CounterText} {
    margin: 0 10px 0 15px;
    width: 130px;
    height: 40px;
    padding: 0 15px;
    justify-content: center;
  }

  ${TeamSizeStyled.Container} {
    > p {
      margin-top: 18px;
    }
  }
`;

export const Styled = {
  FillStepScaleContainer,
  ContentContainer,
};
