import classNames from 'classnames';
import * as React from 'react';

import './select-name.scss';

import {
  KreoIconCollapse,
  KreoIconExpand,
} from 'common/UIKit/icons';


interface Props {
  isExpanded: boolean;
  className?: string;
  onClick: () => void;
}

export const SelectName: React.FC<Props> = (props) => {
  return  (
    <div className={classNames('gantt-chart-header-select-name', props.className)} onClick={props.onClick}>
      <div className='gantt-chart-header-select-name__title'>{props.children}</div>
      {props.isExpanded ? <KreoIconCollapse/> : <KreoIconExpand/>}
    </div>
  );
};
