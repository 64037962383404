import { Icons } from '@kreo/kreo-ui-components';

import { UnitTypes } from 'common/utils/unit-util';
import { DrawingsMeasureType } from '../../interfaces';
import { MeasureValue } from '../drawings-annotation-legend-item';

export enum MeasureType {
  Area = 'area',
  Length = 'length',
  Perimeter = 'perimeter',
  Count = 'count',
}

export const MeasureTypeTemplate = {
  [MeasureType.Area]: {
    icon: Icons.AreaSmall,
    unit: UnitTypes.M2,
  },
  [MeasureType.Length]: {
    icon: Icons.LineSmall,
    unit: UnitTypes.M,
  },
  [MeasureType.Perimeter]: {
    icon: Icons.PerimeterSmall,
    unit: UnitTypes.M,
  },
  [MeasureType.Count]: {
    icon: Icons.DotSmall,
    unit: UnitTypes.Nr,
  },
};

const MeasureTypeKeys = Object.values(MeasureType);

function getMeasureValues(measure: DrawingsMeasureType): Record<string, MeasureValue> {
  const result: Record<string, MeasureValue> = {};
  for (const key of MeasureTypeKeys) {
    if (measure[key] !== undefined) {
      result[key] = {
        ...MeasureTypeTemplate[key],
        title: key,
        value: measure[key],
      };
    }
  }
  return result;
}

export const MeasuresUtils = {
  getMeasureValues,
};
