import * as React from 'react';

export const KreoIconRowCollapsed: React.FC = (_props) => {
  return (
    <svg width='8px' height='3px' viewBox='0 0 8 3' xmlns='http://www.w3.org/2000/svg'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Client_GenC_Schedule' transform='translate(-163.000000, -499.000000)'>
          <g id='Content' transform='translate(100.000000, 60.000000)'>
            <g id='WP_1' transform='translate(50.000000, 410.000000)'>
              <g id='icon_Collapsed'>
                <g transform='translate(10.000000, 23.000000)'>
                  <g>
                    <rect id='Rectangle-7' x='0' y='0' width='14' height='14' />
                    <polyline id='Rectangle-5' stroke='#ffffff' strokeLinecap='round' points='10 6 7 9 4 6' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
