import { NumberDictionary } from 'common/interfaces/dictionary';
import { Direction, LimitationType } from './enums';
import {
  EdgeMap,
  GraphData,
  GraphForNode,
  GraphInfo,
  GraphNode,
} from './interfaces';

interface RelativeGroup {
    groupId: number;
    relativeNodeIds: number[];
}

export class GraphProcessor {
  private graphEdges: EdgeMap;

  constructor(graph: GraphData) {
    this.graphEdges = graph.edges;
  }

  public tryFindLoopForNode(loopNodeId: number): number[] {
    const sourceTarget = this.graphEdges.sourceTarget;
    const checkedVertexes: NumberDictionary<boolean> = {};
    const getPathToNode = (startNodeId: number, destinationId: number): number[] => {
      checkedVertexes[startNodeId] = true;
      if (!sourceTarget[startNodeId] || !sourceTarget[startNodeId]) {
        return null;
      }
      if (sourceTarget[startNodeId][destinationId]) {
        return [destinationId];
      }
      for (const id in sourceTarget[startNodeId]) {
        if (checkedVertexes[id]) {
          continue;
        }
        const result = getPathToNode(+id, destinationId);
        if (result) {
          result.push(+id);
          return result;
        }
      }

      return null;
    };

    const path = getPathToNode(loopNodeId, loopNodeId);
    return path ? path.reverse() : null;
  }


  public getGraphForRootNode(id: number, levelCounts: number): GraphForNode {
    let graphForRootNode = this.getNLevels(id, levelCounts);
    graphForRootNode = this.removeLevel3Nodes(graphForRootNode);
    const additionalInfo = this.getGraphInfo(graphForRootNode);

    return {
      rootNode: graphForRootNode,
      additionalInfo,
    };
  }

  public getDirectionNodes(id: number, direction: Direction, levels?: number): Record<number, undefined> {
    const getDirectionNodes = (edgeMap: Record<number, Record<number, any>>): Record<number, undefined> => {
      const result: Record<number, undefined> = {};
      const pushNextNodes = (nodeId: number, level?: number): void => {
        if (level === 0) {
          return;
        }
        if (nodeId in edgeMap) {
          for (const nextId in edgeMap[nodeId]) {
            if (!(nextId in result)) {
              result[nextId] = undefined;
              pushNextNodes(+nextId, level ? level - 1 : level);
            }
          }
        }
      };
      pushNextNodes(id, levels);

      return result;
    };

    switch (direction) {
      case Direction.Forward:
        return getDirectionNodes(this.graphEdges.sourceTarget);
      case Direction.Back:
        // TODO: BUG
        return getDirectionNodes(this.graphEdges.targetSource);
      default:
        return null;
    }
  }

  // public getGraphByLevels(): GraphMapData {
  //   const vertexToCoordinate: NumberDictionary<Coordinate> = {};
  //   const nodesCountPerLevel: NumberDictionary<number> = {};
  //   let graphEdges = this.graphEdges;
  //   let graphVertexes = this.graphVertexes;
  //   let level = 0;
  //   let maxNodesCountOnLevel = 0;
  //   let previousLevelRemovedTargets = [];
  //   const getSourcesToTargetsArrayAndTargetsToSourcesCount = (result, edge: GraphEdge) => {
  //     if (!result.sourcesToTargets[edge.source]) {
  //       result.sourcesToTargets[edge.source] = [];
  //     }
  //     result.sourcesToTargets[edge.source].push(edge.target);
  //     if (!result.targetsToSourcesCount[edge.target]) {
  //       result.targetsToSourcesCount[edge.target] = 0;
  //     }
  //     result.targetsToSourcesCount[edge.target] += 1;
  //     return result;
  //   };

  //   let isGraphEmpty = false;
  //   let isExistRemovedFromGraphNodesAndNotAddedToLevel = false;
  //   let tempCondition = true;

  //   do {
  //     const vertexes = graphEdges.reduce(
  //       getSourcesToTargetsArrayAndTargetsToSourcesCount,
  //       { sourcesToTargets: {}, targetsToSourcesCount: {} },
  //     );
  //     const targets = Object.keys(vertexes.targetsToSourcesCount)
  //       .map(x => +x);
  //     const levelSources = Object.keys(vertexes.sourcesToTargets)
  //       .filter(x => !targets.includes(+x))
  //       .map(x => +x);
  //     const levelTargetsToCount = levelSources
  //       .reduce(
  //         (result, sourceId) => {
  //           vertexes.sourcesToTargets[sourceId].forEach(x => {
  //             if (!result[x]) {
  //               result[x] = 0;
  //             }
  //             result[x] += 1;
  //           });
  //           return result;
  //         },
  //         {},
  //       );

  //     const levelTargets = Object.keys(levelTargetsToCount)
  //       .filter(x => levelTargetsToCount[x] === vertexes.targetsToSourcesCount[x])
  //       .map(x => +x);

  //     const levelVertexes = levelSources.concat(previousLevelRemovedTargets)
  //       .filter((x, index, array) => array.indexOf(x) === index);
  //     previousLevelRemovedTargets = levelTargets;
  //     levelVertexes.forEach((vertex, index) => {
  //       vertexToCoordinate[vertex] = {x: level, y: index};
  //       if (index > maxNodesCountOnLevel) {
  //         maxNodesCountOnLevel = index;
  //       }
  //     });
  //     nodesCountPerLevel[level] = levelVertexes.length;
  //     graphEdges = graphEdges.filter(x => !levelVertexes.includes(x.source));
  //     graphVertexes = graphVertexes.filter(x => !levelVertexes.includes(x));
  //     level += 1;

  //     isGraphEmpty = !graphEdges.length;
  //     isExistRemovedFromGraphNodesAndNotAddedToLevel = !!previousLevelRemovedTargets.length;
  //     tempCondition = level < 100;
  //   } while ((!isGraphEmpty || isExistRemovedFromGraphNodesAndNotAddedToLevel) && tempCondition);

  //   graphVertexes.forEach(x => {
  //     vertexToCoordinate[x] = {x: 0, y: nodesCountPerLevel[0]++ };
  //   });

  //   if (nodesCountPerLevel[0] > maxNodesCountOnLevel) {
  //     maxNodesCountOnLevel = nodesCountPerLevel[0];
  //   }

  //   return {
  //       levelsCount: level + 1,
  //       vertexes: vertexToCoordinate,
  //       maxCountInLevel: maxNodesCountOnLevel + 1,
  //       nodesCountPerLevel,
  //   };
  // }

  private getLevel(id: number, direction: Direction): number[] {
    const { sourceTarget, targetSource: targetSources } = this.graphEdges;
    const getSources = (targetId, edgeType): number[] => {
      return targetSources[targetId]
        ? Object.keys(targetSources[targetId])
          .filter(sourceId => targetSources[targetId][sourceId] === edgeType)
          .map(x => +x)
        : [];
    };

    const getTargets = (sourceId, edgeType): number[] => {
      return sourceTarget[sourceId]
        ? Object.keys(sourceTarget[sourceId])
          .filter(targetId => sourceTarget[sourceId][targetId] === edgeType)
          .map(x => +x)
        : [];
    };

    switch (direction) {
      case Direction.Back:
        return getSources(id, LimitationType.FinishStart);
      case Direction.Forward:
        return getTargets(id, LimitationType.FinishStart);
      default:
    }
  }

  private removeLevel3Nodes(rootNode: GraphNode): GraphNode {
    const removeLevel3NodesForDirection = (node: GraphNode, direction: Direction): GraphNode => {
      const level1Nodes = node[direction].map(x => x.id);
      const level2Nodes = node[direction]
        .map(x => x[direction])
        .reduce((a, b) => a.concat(b), [])
        .map(x => x.id);
      const duplicates = level1Nodes.filter(x => level2Nodes.indexOf(x) !== -1);
      duplicates.forEach(x => {
        const duplicatedNode = node[direction].find(level1Node => level1Node.id === x);
        duplicatedNode[direction] = [];
      });

      return node;
    };

    rootNode = removeLevel3NodesForDirection(rootNode, Direction.Forward);
    rootNode = removeLevel3NodesForDirection(rootNode, Direction.Back);
    return rootNode;
  }

  private getGraphInfo(rootNode: GraphNode): GraphInfo {
    const relativeGroups: NumberDictionary<RelativeGroup[]> = {};
    const levels = {
      level1: [],
      level2: [],
    };
    const getGroupsWithCommonNodesForDirection = (direction: Direction): void => {
      for (let i = 0; i < rootNode[direction].length; i++) {
        for (let j = i + 1; j < rootNode[direction].length; j++) {
          const group1 = rootNode[direction][i];
          const group2 = rootNode[direction][j];
          const groupsCommonNodes = group1[direction]
            .filter((x) => group2[direction].find(y => y.id === x.id))
            .map(x => x.id);
          if (groupsCommonNodes.length) {
            if (!relativeGroups[group1.id]) {
              relativeGroups[group1.id] = [];
            }
            relativeGroups[group1.id].push({ groupId: group2.id, relativeNodeIds: groupsCommonNodes });
            if (!relativeGroups[group2.id]) {
              relativeGroups[group2.id] = [];
            }
            relativeGroups[group2.id].push({ groupId: group1.id, relativeNodeIds: groupsCommonNodes });
          }
        }
        levels.level1.push(rootNode[direction][i].id);
        levels.level2 = levels.level2.concat(rootNode[direction][i][direction].map(x => x.id));
      }
    };

    getGroupsWithCommonNodesForDirection(Direction.Forward);
    getGroupsWithCommonNodesForDirection(Direction.Back);

    const allCommonChild: number[] = Object.keys(relativeGroups)
      .map(key => relativeGroups[key])
      .reduce((a, b) => a.concat(b), [])
      .map(x => x.nodes)
      .reduce((a, b) => a.concat(b), [])
      .filter((x, index, array) => index === array.indexOf(x));
    levels.level2 = levels.level2.filter((x, index, array) => index === array.indexOf(x));
    const nodesOnEachLevels: number[] = levels.level1.filter(x => levels.level2.indexOf(x) !== -1);
    return {
      relativeGroups,
      allCommonChild,
      nodesOnEachLevels,
    };
  }

  private getNLevels(rootNodeId: number, levels: number): GraphNode {
    const getNLevelsForDirection = (nodeId: number, direction: Direction, levelCounts: number): GraphNode => {
      const level = this.getLevel(nodeId, direction);
      // const startStartLevel = this.getLevel(nodeId, LimitationType.StartStart)
      //   .map(x => {
      //     return {id: x};
      //   });
      const result: GraphNode = {
        id: nodeId,
        [direction]: [],
        // [LimitationType.StartStart]: startStartLevel,
      };
      if (levelCounts < 1 || !level.length)  {
        return result;
      }

      const nextLevels = level
        .map(x => getNLevelsForDirection(x, direction, levelCounts - 1));

      result[direction] = nextLevels;
      return result;
    };

    return {
      ...getNLevelsForDirection(rootNodeId, Direction.Forward, levels),
      ...getNLevelsForDirection(rootNodeId, Direction.Back, levels),
    };
  }
}
