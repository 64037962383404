import { MenuOptionInfo } from 'common/components/selection-menus';
import { Filters, MentionFilterValues, StatusFiltersValue } from 'unit-2d-comments/enums';


export enum FilterType {
  Checkbox,
  RadioButton,
}

export interface FilterSettings {
  options: Array<MenuOptionInfo<string>>;
  filter: Filters;
  filterType: FilterType;
}

export const CommentaryFilters: FilterSettings[] = [
  {
    options: [
      {
        name: 'All',
        value: MentionFilterValues.All,
      },
      {
        name: 'For You',
        value: MentionFilterValues.ForYou,
      },
    ],
    filter: Filters.Mention,
    filterType: FilterType.RadioButton,
  },
  {
    options: [
      {
        name: 'Open',
        value: StatusFiltersValue.Open,
      },
      {
        name: 'Resolved',
        value: StatusFiltersValue.Resolved,
      },
    ],
    filter: Filters.Status,
    filterType: FilterType.Checkbox,
  },
];
