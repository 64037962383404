import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 440px;
  max-height: 515px;
  background-color: ${props => props.theme.color.backgroundRush};
  border-radius: 30px;
`;

const Header = styled.div`
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${p => p.theme.color.background};

  > p {
    font-size: 12px;
  }
`;

const BillingHistoryWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
`;

const LoadMoreWrapper = styled.div`
  margin: 15px 0;

  .load-more {
    width: 400px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid ${p => p.theme.color.turquoise};
  }

  .load-more__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.color.backgroundRush};
  }

  .load-more__content {
    color: ${p => p.theme.color.turquoise};
    font-size: 14px;
  }
`;

export const Styled = {
  Container,
  Header,
  BillingHistoryWrapper,
  LoadMoreWrapper,
};
