import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { ConstantFunctions } from 'common/constants/functions';
import { RequestStatus } from 'common/enums/request-status';
import { arrayUtils } from 'common/utils/array-utils';
import { AsyncIterationExecuter } from 'common/utils/async-iteration-executor';
import { objectUtils } from 'common/utils/object-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { DrawingsUpdateAnnotationGeometry } from '../actions/payloads/annotation';
import { DrawingsAnnotationsChangeColors, DrawingsAnnotationsPosition } from '../actions/payloads/user-annotation';
import { DrawingsCanvasConstants } from '../constants/drawing-canvas-constants';
import { DrawingsSVGIcons } from '../constants/drawings-svg-icons';
import {
  ContextObserver,
  ContextObserverCallbackGroup,
  ContextObserverCallbackGroupWithPrev,
  DrawingContextObserver,
  DrawingContextObserverWithPrev,
  DrawingsRemoveInstanceFunctionType,
} from '../drawings-contexts';
import { DrawingGeometryOperationType } from '../enums/drawing-geometry-operation-type';
import { DrawingsSnappingModes } from '../enums/drawing-snapping-modes';
import { DrawingsDrawMode } from '../enums/drawings-draw-mode';
import { DrawingsGeometryEntityState } from '../enums/drawings-geometry-entity-state';
import { DrawingsInstanceType } from '../enums/drawings-instance-type';
import { WizzardStatus } from '../enums/dropper-state';
import { DrawingsColorCacheHelper } from '../helpers/drawings-color-cache-helper';
import { FixContour, MagicSearchState, WizzardToolsState } from '../interfaces';
import { DrawingSnapping } from '../interfaces/drawing';
import { DrawingsPointInfo, ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import { DrawingsRenderParams } from '../interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from '../interfaces/drawing-text-render-parameters';
import { DrawingGeometryUpdateEventHandler } from '../interfaces/drawing-update-events-handlers';
import {
  DrawingsAllowedPathType,
  DrawingsBounds,
  DrawingsCalibrationLineGeometry,
  DrawingsGeometryParams,
  DrawingsGeometryPointCoordinates,
  DrawingsGeometryStrokedType,
  DrawingsGeometryType,
} from '../interfaces/drawings-geometry';
import {
  DrawingsGeometryInstance,
  DrawingsGeometryInstanceShort,
  DrawingsGeometryInstanceWithId,
} from '../interfaces/drawings-geometry-instance';
import { DrawingsGeometryStyle } from '../interfaces/drawings-geometry-style';
import { DrawingsInstanceMeasure } from '../interfaces/drawings-instance-measure';
import { DrawingsShortInfo } from '../interfaces/drawings-short-drawing-info';
import {
  DrawingRulerPoints,
  DrawingUserAnnotationImage,
  DrawingUserAnnotationRuler,
} from '../interfaces/drawings-user-annotation';
import { CursorHintType } from '../layout-components/drawings-cursor-hint';
import { DrawingAnnotationNamingUtils } from '../utils/drawing-annotation-naming-utils';
import { DrawingAnnotationUtils } from '../utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from '../utils/drawings-canvas-utils';
import { DrawingsCommonUtils } from '../utils/drawings-common-utils';
import { DrawingsGeometryUtils } from '../utils/drawings-geometry-utils';
import { FinishedDrawingElement } from './drawings-geometry-entities/temporary';
import { DrawingsGeometrySelectionArea } from './drawings-geometry-entities/utility';
import {
  AlignmentType,
  DrawingsCopyPasteBuffer,
  DrawingsCursorTypeHelper,
  DrawingsDragCallbackParams,
  DrawingsDragInstancesHelper,
  DrawingsDragProcessingHelper,
  DrawingsGeometryBooleanOperationHelper,
  DrawingsGeometryConversionProcessor,
  DrawingsGeometryDragEventHelper,
  DrawingsGeometryEntityHelper,
  DrawingsGeometryRendererDrawHelper,
  DrawingsGeometrySnappingHelper,
  DrawingsInstanceSetFilter,
  DrawingsKnifeHelper,
  DrawingsMarqueeZoomAreaHelper,
  DrawingsOffsetHelper,
  DrawingsOrthogonalModeStatusController,
  DrawingsRenderedPointsCache,
  DrawingsSourceOfBuffer,
  DrawingsUserAnnotationDragHelper,
  DrawingsUserAnnotationEvents,
  DrawingsUserAnnotationsHelper,
  DrawingsViewHelper,
  DrawingsDragSelectionHelper,
  DrawingsBooleanOperation,
  OpenPolygonSettings,
} from './drawings-helpers';
import { AiSearchHelperBase } from './drawings-helpers/ai-search/ai-search-helper-base';
import { MagicSearch } from './drawings-helpers/ai-search/magic-search';
import { DrawingsDrawKeyListenerHelper } from './drawings-helpers/key-listener';
import { DrawingsContinueKeyListenerHelper } from './drawings-helpers/key-listener/continue-key-listener-helper';
import { DrawingsModifyKeyListenerHelper } from './drawings-helpers/key-listener/modify-key-listener-helper';
import { StrictAngleKeyListenerHelper } from './drawings-helpers/key-listener/strict-angle-key-listener-helper';
import { PaperMouseEventUtils } from './drawings-helpers/paper-mouse-event-utils';
import { ShapeCreator } from './drawings-helpers/shape-creator';
import {
  AiSuggestSettings,
  BatchedUpdateCallback,
  DrawingsEngineFlipType,
  DrawingsEventsController,
  GetColorOfInstanceCallback,
  GetCurrentPageSizeInfoCallback,
  GetInstanceMeasureCallback,
  HoverChangedEventHandler,
  NewDrawingSettings,
  OpenDrawFinishMenuCallback,
  SendMeasuresUpdateCallback,
  SetDrawModeCallback,
  AddInstancesEnginePayload,
  CreateElementConfig,
} from './interfaces';
import { EditPermissions } from './interfaces/edit-permissions';
import { FinishDrawApi, FinishDrawConfig } from './interfaces/finish-draw-api';


interface DrawingsRendererEngineConfig {
  oneClickAreaHoverContextObserver: ContextObserver<boolean>;
  magicSearchDataObserver: ContextObserver<MagicSearchState>;
  wizzardSettingsObserver: ContextObserver<WizzardToolsState>;
  geometryRenderParamsObserver: DrawingContextObserverWithPrev<DrawingsRenderParams>;
  newDrawingStylesObserver: DrawingContextObserver<NewDrawingSettings>;
  textRenderParamsObserver: DrawingContextObserver<MeasuresViewSettings>;
  measurementsAutocompleteContext: DrawingContextObserver<AiSuggestSettings>;
  editPermissionsObserver: DrawingContextObserver<EditPermissions>;
  snappingObserver: DrawingContextObserver<DrawingSnapping>;

  onToggleMagicSearchPreviewStatus: (ids: number[]) => void;
  onMagicSearchPreviewStartFix: (api: FixContour) => void;
  applyMagicSearchFix: (contoursIndices: number[], polygons: ShortPointDescription[][]) => void;
  updateMagicSearchFixStatus: (status: RequestStatus) => void;

  keyboardListenerApi: GlobalKeyboardEventsControllerContextProps;
  removeInstancesWithUndo: DrawingsRemoveInstanceFunctionType;
  updateGeometry: DrawingGeometryUpdateEventHandler;
  viewHelper: DrawingsViewHelper;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
  cursorTypeHelper: DrawingsCursorTypeHelper;
  annotationsEvents: DrawingsUserAnnotationEvents;
  keepOriginName: boolean;
  onBatchUpdateGeometries: BatchedUpdateCallback;
  geometryConversionProcessor: DrawingsGeometryConversionProcessor;
  orthogonalModeController: DrawingsOrthogonalModeStatusController;
  onZoomAreaDrawn: (bounds: DrawingsBounds) => void;
  onFinishSelectionByArea: (instancesIds: string[], ctrlKey: boolean) => void;
  getNewInstanceName: () => string;
  getCurrentPageInfo: GetCurrentPageSizeInfoCallback;
  changePointsPosition: (
    updatedPoints: Record<string, ShortPointDescription>,
    newMeasures: DrawingsInstanceMeasure[],
  ) => void;
  onCalibrateLineDrawn: (line: DrawingsCalibrationLineGeometry) => void;
  changeOffsetPosition: (e: paper.Point, length: number) => void;
  sendMeasuresUpdate: SendMeasuresUpdateCallback;
  openContextMenu: (e: PaperMouseEvent) => void;
  openFinishDrawPopup: OpenDrawFinishMenuCallback;
  getSnappingPoint: (point: DrawingsGeometryPointCoordinates, threshold: number) => paper.Point;
  getColorOfInstance: GetColorOfInstanceCallback;
  getCurrentDrawing: () => DrawingsShortInfo;
  getPoint: (point: ShortPointDescription) => paper.Point;
  getPointInfo: (pointId: string) => DrawingsPointInfo;
  getPointCoordinates: (pointId: string) => ShortPointDescription;
  onSelectInstance: (id: string, e?: PaperMouseEvent) => void;
  getDrawingInstance: (instanceId: string) => DrawingsGeometryInstance;
  getInstanceMeasures: GetInstanceMeasureCallback;
  onSelectSegment: (segmentId: string) => void;
  changeDrawMode: SetDrawModeCallback;
  checkInstanceVisibility: (instanceId: string) => boolean;
  getImageInfo: (id: string) => DrawingUserAnnotationImage;
  getRulerInfo: (id: string) => DrawingUserAnnotationRuler;
  getColorOfLabel: (label: string) => string;
  copyPasteHelper: DrawingsCopyPasteBuffer;
  sharedSpaceBarHeldContext: DrawingContextObserver<boolean>;
  onAnnotationsSelectionChanged: (annotationsIds: string[], ctrlKey: boolean) => void;
  stickersInRectIdsIterator: (
    rect: paper.Rectangle,
    selectionArea: DrawingsGeometrySelectionArea,
  ) => IterableIterator<string>;
  undoRedoChangeActiveStatus: (value: boolean) => void;
  onHoverInstanceChanged: HoverChangedEventHandler;
  setCursorHint: (hintType: CursorHintType) => void;
  onChangeDropperState: (state: WizzardStatus, itemsCount?: number) => void;
  onWizzardWorkingArea: (area: ShortPointDescription[]) => void;
  onWizzardSelectionArea: (area: ShortPointDescription[]) => void;
  onPdfFilterArea: (area: ShortPointDescription[]) => void;
  addFinderSelectedGeometryToRemove: (geometryId: number) => void;
  clearWizzardResult: () => void;
  onRunDropper: (point: ShortPointDescription) => void;
  sendFinishApi: (api: FinishDrawApi) => void;
  setMagicSearchZone: (zone: ShortPointDescription[]) => void;
}

export class DrawingRendererEngine {
  private _config: DrawingsRendererEngineConfig;

  private _magicSearch: MagicSearch;
  private _autoMeasureAi: AiSearchHelperBase;
  private _shapeCreator: ShapeCreator = new ShapeCreator();
  private _dragSelectionHelper: DrawingsDragSelectionHelper;
  private _marqueeZoomArea: DrawingsMarqueeZoomAreaHelper;
  private _dragHelper: DrawingsDragProcessingHelper;
  private _entityRenderHelper: DrawingsGeometryEntityHelper;
  private _snappingHelper: DrawingsGeometrySnappingHelper;
  private _drawHelper: DrawingsGeometryRendererDrawHelper;
  private _cachedPoints: DrawingsRenderedPointsCache;
  private _operationHelper: DrawingsGeometryBooleanOperationHelper;
  private _knifeHelper: DrawingsKnifeHelper;
  private _drawingOffsetHelper: DrawingsOffsetHelper;
  private _dragInstancesHelper: DrawingsDragInstancesHelper;
  private _userAnnotationHelper: DrawingsUserAnnotationsHelper;
  private _selectedEntities: string[] = [];
  private _instancesFilter: DrawingsInstanceSetFilter = new DrawingsInstanceSetFilter(null);
  private _hiddenInstancesFilter: DrawingsInstanceSetFilter = new DrawingsInstanceSetFilter(null);
  private _editPoint: string;

  private _tempLayer: paper.Layer = new paper.Layer();
  private _colorCacheHelper: DrawingsColorCacheHelper = new DrawingsColorCacheHelper();
  private _renderOperationProcessor: AsyncIterationExecuter = new AsyncIterationExecuter();

  private _drawKeyboardListener: DrawingsEventsController;
  private _continueKeyboardListener: DrawingsEventsController;
  private _modifyKeyboardListener: DrawingsEventsController;

  private _renderParamsObserver: ContextObserverCallbackGroupWithPrev<DrawingsRenderParams>;
  private _textParamsObserver: ContextObserverCallbackGroup<MeasuresViewSettings>;

  constructor(config: DrawingsRendererEngineConfig) {
    this._config = config;
    this._cachedPoints = new DrawingsRenderedPointsCache(config.getPoint, config.getPointCoordinates);
    this._renderParamsObserver = new ContextObserverCallbackGroupWithPrev<DrawingsRenderParams>(
      config.geometryRenderParamsObserver,
    );
    this._textParamsObserver = new ContextObserverCallbackGroup<MeasuresViewSettings>(
      config.textRenderParamsObserver,
    );
    this._snappingHelper = new DrawingsGeometrySnappingHelper({
      observableRenderParameters: this._renderParamsObserver,
      snappingObserver: this._config.snappingObserver,
      getSnappingPoint: this._config.getSnappingPoint,
      getCurrentPageInfo: this._config.getCurrentPageInfo,
    });
    const strictAngleController = new StrictAngleKeyListenerHelper({
      api: this._config.keyboardListenerApi,
    });

    this._dragHelper = new DrawingsDragProcessingHelper(
      config.sharedSpaceBarHeldContext,
      this._snappingHelper,
      strictAngleController,
    );
    this._userAnnotationHelper = new DrawingsUserAnnotationsHelper({
      renderParametersContext: this._renderParamsObserver,
      textRenderParametersObserver: this._textParamsObserver,
      dragProcessingHelper: this._dragHelper,
      events: config.annotationsEvents,
      getImageInfo: config.getImageInfo,
      colorsCacheHelper: this._colorCacheHelper,
      getRulerInfo: config.getRulerInfo,
      tempLayer: this._tempLayer,
      cursorHelper: config.cursorTypeHelper,
      editPermissionsObserver: this._config.editPermissionsObserver,
    });

    this._knifeHelper = new DrawingsKnifeHelper({
      layer: this._tempLayer,
      renderParamsObserver: this._renderParamsObserver,
      getInstance: this._config.getDrawingInstance,
      getEntity: this.getEntityPath,
      textRenderParamsObserver: this._textParamsObserver,
      getPointCoordinates: this._config.getPointCoordinates,
      onUpdate: this._config.onBatchUpdateGeometries,
      getInstanceMeasures: this._config.getInstanceMeasures,
      getCurrentDrawing: this._config.getCurrentDrawing,
      addPointsToCached: this._cachedPoints.addPoints,
      changeDrawMode: this._config.changeDrawMode,
      getColorOfInstances: this._config.getColorOfInstance,
    });

    this._drawHelper = new DrawingsGeometryRendererDrawHelper(
      {
        newDrawingStylesObserver: this._config.newDrawingStylesObserver,
        shapeCreator: this._shapeCreator,
        viewSettingsObserver: this._textParamsObserver,
        renderParametersContextObserver: this._renderParamsObserver,
        snappingHelper: this._snappingHelper,
        dragHelper: this._dragHelper,
        viewHelper: this._config.viewHelper,
        openFinishPopup: this.openFinishPopup,
        onCreateElement: this.onCreateElement,
        layer: this._tempLayer,
        openContextMenu: this._config.openContextMenu,
        userAnnotationsHelper: this._userAnnotationHelper,
        pasteWithPoint: this.pasteWithPoint,
        getInstancesForPaste: () => this._config.copyPasteHelper.bufferedInstances,
        colorsCacheHelper: this._colorCacheHelper,
        getCurrentPageInfo: this._config.getCurrentPageInfo,
        getColorOfLabel: this._config.getColorOfLabel,
        knifeHelper: this._knifeHelper,
        orthogonalModeController: this._config.orthogonalModeController,
        getDrawingInfo: this._config.getCurrentDrawing,
        measurementsAutocompleteContext: this._config.measurementsAutocompleteContext,
        setCursorMessage: this._config.setCursorHint,
        cursorTypeHelper: this._config.cursorTypeHelper,
        wizzardSettingsObserver: this._config.wizzardSettingsObserver,
        onBatchUpdateGeometries: this._config.onBatchUpdateGeometries,
        onChangeDropperState: this._config.onChangeDropperState,
        setSelectionArea: this._config.onWizzardSelectionArea,
        addFinderSelectedGeometryToRemove: this._config.addFinderSelectedGeometryToRemove,
        clearWizzardResult: this._config.clearWizzardResult,
        onRunDropper: this._config.onRunDropper,
        magicSearchDataObserver: this._config.magicSearchDataObserver,
        sendFinishApi: this._config.sendFinishApi,
        oneClickAreaHoverContextObserver: this._config.oneClickAreaHoverContextObserver,
      },
    );

    this._entityRenderHelper = new DrawingsGeometryEntityHelper({
      shapeCreator: this._shapeCreator,
      cursorHelper: this._config.cursorTypeHelper,
      changeDrawMode: this._config.changeDrawMode,
      viewHelper: this._config.viewHelper,
      textRenderParamsObserver: this._textParamsObserver,
      renderParamsContextObserver: this._renderParamsObserver,
      onEntityClick: this.onEntityClick,
      getPoint: this._cachedPoints.getPoint,
      onDoubleMainGeometryClick: this.onDoubleMainGeometryClick,
      getDrawingInstance: this._config.getDrawingInstance,
      onDragEntity: this.startDragEntities,
      onBatchUpdateGeometries: this._config.onBatchUpdateGeometries,
      getPaperColor: this._colorCacheHelper.getPaperColor,
      onSelectSegmentMeasure: this._config.onSelectSegment,
      getColorOfInstance: this._config.getColorOfInstance,
      onUpdateInstances: this._config.updateGeometry,
      dragHelper: this._dragHelper,
      cachedPoints: this._cachedPoints,
      sendMeasuresUpdate: this.sendMeasuresUpdate,
      getPointInfo: this._config.getPointInfo,
      getPointCoordinates: this._config.getPointCoordinates,
      getCurrentDrawing: this._config.getCurrentDrawing,
      getInstanceMeasures: this._config.getInstanceMeasures,
      colorCacheHelper: this._colorCacheHelper,
      changePointsPositionAndMeasures: this.changePointsPosition,
      startDragUtilityPoint: this.startDragPoint,
      editPermissionsObserver: this._config.editPermissionsObserver,
      dragEventsHelper: this._config.dragEventsHelper,
      snappingHelper: this._snappingHelper,
      geometryConversionProcessor: this._config.geometryConversionProcessor,
      cancelModify: () => this._config.changeDrawMode(DrawingsDrawMode.Disabled),
      strictAngleController,
      onHoverInstanceChanged: this._config.onHoverInstanceChanged,
      setCursorHint: this._config.setCursorHint,
      isDrawingEnabled: () => this._drawHelper.isDrawingMode,
    });

    this._dragInstancesHelper = new DrawingsDragInstancesHelper({
      editPermissionsObserver: this._config.editPermissionsObserver,
      entityRenderHelper: this._entityRenderHelper,
      dragHelper: this._dragHelper,
      cachedPoints: this._cachedPoints,
      textRenderParamsObserver: this._textParamsObserver,
      getCurrentPageInfo: this._config.getCurrentPageInfo,
      sendMeasuresUpdate: this.sendMeasuresUpdate,
      changeSelection: this.changeSelection,
      getInstanceMeasures: this._config.getInstanceMeasures,
      getPointInfo: this._config.getPointInfo,
      getPoint: this._config.getPoint,
      getPointCoordinates: this._config.getPointCoordinates,
      getCurrentDrawing: this._config.getCurrentDrawing,
      getDrawingInstance: this._config.getDrawingInstance,
      getSegmentMeasureUpdate: this.getSegmentMeasureUpdate,
      addInstances: this.addInstances,
      updatePointsPosition: this.updatePointsPosition,
      changePointsPosition: this.changePointsPosition,
      updateInstanceMeasuresIterator: this.updateInstanceMeasuresIterator,
      geometryRenderParamsObserver: this._renderParamsObserver,
      keepOriginName: this._config.keepOriginName,
      cursorHelper: this._config.cursorTypeHelper,
      snappingHelper: this._snappingHelper,
    });
    this._operationHelper = new DrawingsGeometryBooleanOperationHelper({
      entityRenderHelper: this._entityRenderHelper,
      addPointsToCached: this._cachedPoints.addPoints,
      getInstanceColor: this._config.getColorOfInstance,
      getCurrentDrawingInfo: this._config.getCurrentDrawing,
      getInstance: this._config.getDrawingInstance,
      getPointCoordinates: this._config.getPointCoordinates,
      getInstanceMeasures: this._config.getInstanceMeasures,
      textRenderParamsObserver: this._textParamsObserver,
      getColorOfInstances: this._config.getColorOfInstance,
      onUpdate: this._config.onBatchUpdateGeometries,
    });
    this._drawingOffsetHelper = new DrawingsOffsetHelper({
      layer: this._tempLayer,
      viewHelper: this._config.viewHelper,
      changeOffsetPosition: this._config.changeOffsetPosition,
      addInstances: this.addInstances,
      observableRenderContext: this._renderParamsObserver,
      getCurrentPageInfo: this._config.getCurrentPageInfo,
    });

    this._dragSelectionHelper = new DrawingsDragSelectionHelper({
      dragEventsHelper: this._dragHelper,
      entitiesHelper: this._entityRenderHelper,
      userAnnotationHelper: this._userAnnotationHelper,
      geometryRenderParamsObserver: this._renderParamsObserver,
      onSelectionChange: config.onFinishSelectionByArea,
      stickersInRectIdsIterator: config.stickersInRectIdsIterator,
      onAnnotationsSelectionChanged: config.onAnnotationsSelectionChanged,
    });
    this._marqueeZoomArea = new DrawingsMarqueeZoomAreaHelper({
      dragEventsHelper: this._dragHelper,
      geometryRenderParamsObserver: this._renderParamsObserver,
      onAreaDrawn: this._config.onZoomAreaDrawn,
    });
    this._tempLayer.bringToFront();

    this._drawKeyboardListener = new DrawingsDrawKeyListenerHelper({
      api: this._config.keyboardListenerApi,
      drawProcessor: this._drawHelper,
      knifeHelper: this._knifeHelper,
      changeDrawMode: this._config.changeDrawMode,
    });
    this._continueKeyboardListener = new DrawingsContinueKeyListenerHelper({
      api: this._config.keyboardListenerApi,
      changeDrawMode: this._config.changeDrawMode,
    });
    this._modifyKeyboardListener = new DrawingsModifyKeyListenerHelper({
      api: this._config.keyboardListenerApi,
      changeDrawMode: this._config.changeDrawMode,
      modifyController: this._entityRenderHelper,
    });
  }

  public destroy(): void {
    this._renderParamsObserver.destroy();
    this._textParamsObserver.destroy();
    this._entityRenderHelper.destroy();
    this._cachedPoints.clear();
    this._snappingHelper.destroy();
    this._drawHelper.destroy();
    this._tempLayer.remove();
    this._userAnnotationHelper.destroy();
    this._renderOperationProcessor.cancel();
    this.removeDrawKeyListeners();
  }

  public set keepOriginNames(value: boolean) {
    this._dragInstancesHelper.keepOriginName = value;
  }

  public set offsetIsStroke(value: boolean) {
    this._drawingOffsetHelper.toggleOffsetType(value);
  }

  public get isDrawingMode(): boolean {
    return this._drawHelper.isDrawingMode;
  }

  public get hasUnsavedInstances(): boolean {
    return (this._drawHelper.isDrawing
      && this._drawHelper.drawMode !== DrawingsDrawMode.Rectangle2Point)
      || (this._knifeHelper.isActive && this._knifeHelper.hasPoints)
      || (this._drawingOffsetHelper.isActive && this._drawingOffsetHelper.hasChanges);
  }

  public set snappingModes(value: DrawingsSnappingModes[]) {
    this._snappingHelper.snappingModes = value;
  }

  public set tempImageId(value: DrawingsSVGIcons) {
    this._drawHelper.tempImageId = value;
  }

  public set isSnappingEnabled(value: boolean) {
    this._snappingHelper.enable = value;
  }

  public set mouseMoveDisabled(value: boolean) {
    this._drawHelper.mouseMoveDisabled = value;
  }

  public set filteredInstancesIds(value: string[]) {
    this._instancesFilter = new DrawingsInstanceSetFilter(value);
    for (const i  of this._entityRenderHelper.renderedInstancesIds) {
      if (!this._instancesFilter.applyFilter(i)) {
        this._entityRenderHelper.removeEntityById(i);
      }
    }
  }

  public set hiddenInstancesIds(value: string[]) {
    this._hiddenInstancesFilter = new DrawingsInstanceSetFilter(value, true);
  }

  public set onChangePointsSelection(value: (ids: string[]) => void) {
    this._entityRenderHelper.changePointsSelection = value;
  }

  public get selectedPoints(): string[] {
    return this._entityRenderHelper.selectedPoints;
  }

  public get modifiableInstanceType(): DrawingsInstanceType {
    return this._config.getDrawingInstance(this._entityRenderHelper.modifyInstanceId).type;
  }

  public get dragHelper(): DrawingsDragProcessingHelper {
    return this._dragHelper;
  }

  public get userAnnotationsDragHelper(): DrawingsUserAnnotationDragHelper {
    return this._userAnnotationHelper.dragHelper;
  }

  public get joinType(): DrawingsInstanceType {
    return this._operationHelper.instacesType;
  }

  public canSplitModifiable(): boolean {
    return this._entityRenderHelper.getInstance(this._entityRenderHelper.modifyInstanceId).canSplit();
  }

  public changeAnnotationsColor(colorChanges: DrawingsAnnotationsChangeColors[]): void {
    this._userAnnotationHelper.changeColors(colorChanges);
  }

  public rerenderInstanceWithNewType(
    id: string,
    instance: DrawingsGeometryInstance,
    stateCallback?: (state: DrawingsGeometryEntityState) => DrawingsGeometryEntityState,
  ): void {
    const renderedInstance = this._entityRenderHelper.getInstance(id);
    if (!renderedInstance) {
      return;
    }
    const newState = stateCallback ? stateCallback(renderedInstance.state) : renderedInstance.state;
    this._entityRenderHelper.removeEntityById(id);
    this._entityRenderHelper.renderInstance(id, instance);
    this._entityRenderHelper.getInstance(id).state = newState;
  }

  public changeEntitiesGeometryParams<T extends keyof DrawingsGeometryParams>(
    ids: string[],
    field: T,
    value: DrawingsGeometryParams[T],
  ): void {
    this._entityRenderHelper.changeEntitiesGeometryParams(ids, field, value);
  }

  public changeEntitiesStyles<T extends keyof DrawingsGeometryStyle>(
    ids: string[],
    field: T,
    value: DrawingsGeometryStyle[T],
  ): void {
    this._entityRenderHelper.changeEntitiesStyles(ids, field, value);
  }

  @autobind
  public applyFinder(): Promise<void> {
    return this._drawHelper.finder.apply();
  }

  @autobind
  public applyDropperResult(): Promise<void> {
    return this._drawHelper.dropper.apply();
  }

  @autobind
  public clearDropperResult(): void {
    this._drawHelper.dropper.clearResult();
  }

  @autobind
  public changeAnnotationImagesPositions(images: DrawingsAnnotationsPosition[]): void {
    this._userAnnotationHelper.moveImages(images);
  }

  @autobind
  public removeAnnotations(annotationsIds: string[]): void {
    this._userAnnotationHelper.removeAnnotations(annotationsIds);
  }

  @autobind
  public flipElements(ids: string[], flipType: DrawingsEngineFlipType): void {
    const rotation = this._textParamsObserver.getContext().rotation;
    flipType = DrawingsCanvasUtils.isVerticallyRotated(rotation)
      ? flipType === 'vertical' ? 'horizontal' : 'vertical'
      : flipType;
    this._entityRenderHelper.flipElements(ids, flipType);
  }

  @autobind
  public startSelectionByArea(e: PaperMouseEvent): void {
    this._dragSelectionHelper.startSelection(e);
  }

  @autobind
  public startZoomByArea(e: PaperMouseEvent): void {
    this._marqueeZoomArea.startSelection(e);
  }

  @autobind
  public renderAnnotations(
    images: Record<string, DrawingUserAnnotationImage>,
    rulers: Record<string, DrawingUserAnnotationRuler>,
  ): void {
    this._userAnnotationHelper.renderAnnotations(images, rulers);
  }

  public clearAnnotations(): void {
    this._userAnnotationHelper.clear();
  }

  @autobind
  public getVisibleInstancesIds(fileInstancesIds: string[]): string[] {
    return fileInstancesIds.filter(
      x => this._instancesFilter.applyFilter(x)
        && this._hiddenInstancesFilter.applyFilter(x),
    );
  }

  public updateInstanceGeometry(update: DrawingsUpdateAnnotationGeometry): void {
    const instance = this._entityRenderHelper.getInstance(update.instance.id);
    if (!instance) {
      return;
    }

    if (update.newPoints) {
      this._cachedPoints.addPoints(update.newPoints);
    }

    if (update.instance.type && instance.instanceType !== update.instance.type) {
      this._entityRenderHelper.removeEntityById(instance.id);
      this._entityRenderHelper.renderInstance(update.instance.id, update.instance as DrawingsGeometryInstanceShort);
    } else {
      this._entityRenderHelper.updateGeometry(update.instance.id, update.instance.geometry);
    }

  }

  public updatePointPositions(points: Record<string, ShortPointDescription>, updateSelection: boolean): void {
    const instancesForUpdate: Record<string, boolean> = {};
    for (const [pointId, point] of Object.entries(points)) {
      this._cachedPoints.setPoint(pointId, this._config.getPoint(point));
      const pointInfo = this._config.getPointInfo(pointId);
      if (pointInfo) {
        instancesForUpdate[pointInfo.instanceId] = true;
      }
    }

    for (const instanceId of Object.keys(instancesForUpdate)) {
      const instance = this._config.getDrawingInstance(instanceId);
      if (!instance) {
        continue;
      }
      this._entityRenderHelper.updateGeometry(instanceId, instance.geometry);
    }
    if (updateSelection) {
      this._entityRenderHelper.recalculateSelectionState();
    }
  }

  public getAnnotationsBounds(ids: string[]): DrawingsBounds {
    return this._userAnnotationHelper.getAnnotationsBounds(ids);
  }

  public setAnnotationImageParameter(id: string, value: number, parameter: keyof DrawingUserAnnotationImage): void {
    this._userAnnotationHelper.setImageParameter(id, value, parameter);
  }

  public setUserAnnotationRulerPoints(id: string, points: DrawingRulerPoints): void {
    this._userAnnotationHelper.setRulerPoints(id, points);
  }

  @autobind
  public isInstanceVisible(instanceId: string): boolean {
    return this._entityRenderHelper.isInstanceRendered(instanceId) && !!this._config.getDrawingInstance(instanceId);
  }

  @autobind
  public changeAnnotationSelection(ids: string[]): void {
    this._userAnnotationHelper.changeAnnotationSelected(ids);
  }

  @autobind
  public changeSelection(ids: string[]): void {
    if (arrayUtils.areSetsEqual(ids, this._selectedEntities)) {
      return;
    }
    this._entityRenderHelper.setEntitiesState(this._selectedEntities, DrawingsGeometryEntityState.Default);
    const deleteEnabled = ids.length === 1 && ids[0] === this._entityRenderHelper.modifyInstanceId;
    if (!deleteEnabled) {
      if (this._entityRenderHelper.isModifyEnabled) {
        this._config.changeDrawMode(DrawingsDrawMode.Disabled);
        this._entityRenderHelper.disableModify();
      } else if (
        this._entityRenderHelper.isContinueEnabled
        && this._entityRenderHelper.entityForContinueId !== ids[0]
      ) {
        this._config.changeDrawMode(DrawingsDrawMode.Disabled);
      }
    }
    this._selectedEntities = this._entityRenderHelper.setEntitiesState(
      ids,
      deleteEnabled ? DrawingsGeometryEntityState.Modify : DrawingsGeometryEntityState.Selected,
    );
  }

  public removeOld(): void {
    this._entityRenderHelper.clear();
    this._cachedPoints.clear();
    this._selectedEntities = [];
  }

  @autobind
  public changeDrawMode(mode: DrawingsDrawMode): void {
    this.removeCalibrateIfNeeded(mode);
    this.applyDrawModeToModify(mode);
    this.applyModeToDrawHelper(mode);
    this._entityRenderHelper.updateSelectionRectPointVisibilityIfNeed();
  }

  public setSelectedPointsAlignment(align: AlignmentType): void {
    const { rotation } = this._textParamsObserver.getContext();
    this._entityRenderHelper.setSelectedPointsAlignment((rotation + align) % 360);
  }

  @autobind
  public getInstanceMeasures(
    id: string,
    geometry: Record<string, DrawingsGeometryInstance<DrawingsGeometryType>>,
  ): DrawingsInstanceMeasure {
    if (this._entityRenderHelper.isInstanceRendered(id) && geometry[id]) {
      return {
        id,
        measures: this._entityRenderHelper.getInstance(id).getMeasures(),
        type: geometry[id].type,
        color: this._config.getColorOfInstance(id, geometry),
      };
    }
    return null;
  }

  public isExistInOperation(id: string): boolean {
    return this._operationHelper?.isExistInProcessInstances(id) ||
            this._drawingOffsetHelper?.isExistInProcessInstances(id) ||
            this._knifeHelper?.isExistInProcessInstances(id);
  }

  @autobind
  public setIdsForOperation(
    ids: string[],
    operation: DrawingGeometryOperationType,
  ): void {
    const idsSet = new Set(ids);
    switch (operation) {
      case DrawingGeometryOperationType.Boolean:
        this._operationHelper.instancesForProcess = ids;
        break;
      case DrawingGeometryOperationType.Offset:
        this._drawingOffsetHelper.instancesForProcess = ids;
        break;
      case DrawingGeometryOperationType.Knife:
        this._knifeHelper.instancesForProcess = ids;
        break;
      default:
    }
    if (ids) {
      for (const key of this._entityRenderHelper.renderedInstancesIds) {
        if (!idsSet.has(key)) {
          this._entityRenderHelper.removeEntityById(key);
        }
      }
    }
  }

  @autobind
  public modify(id: string): void {
    this.enableEntityModify(id);
    this._config.onSelectInstance(id);
  }


  @autobind
  public setVisibility(instancesIds: string[], visibility: boolean): void {
    const currentDrawing = this._config.getCurrentDrawing();
    let hasSelected: boolean = false;
    const selectedSet = new Set(this._selectedEntities);
    if (!visibility) {
      this.hiddenInstancesIds = instancesIds;
    }
    for (const instanceId of instancesIds) {
      const instance = this._config.getDrawingInstance(instanceId);
      if (
        !instance
        || (currentDrawing && currentDrawing.drawingId !== instance.drawingId)
        || (visibility && this._entityRenderHelper.isInstanceRendered(instanceId))
      ) {
        continue;
      }
      if (visibility) {
        this._entityRenderHelper.renderInstanceById(instanceId);
        if (this._selectedEntities.includes(instanceId)) {
          this._entityRenderHelper.setEntityState(instanceId, DrawingsGeometryEntityState.Selected);
        }
      } else {
        this._entityRenderHelper.removeEntityById(instanceId);
      }
      hasSelected = hasSelected || selectedSet.has(instanceId);
    }
    if (hasSelected) {
      this._entityRenderHelper.renderSelectionBoundingRectByIds(this._selectedEntities);
    }
  }

  @autobind
  public removeInstancesByIds(instancesIds: string[]): void {
    if (!instancesIds?.length) {
      return;
    }
    this._renderOperationProcessor.cancel();
    this.removeInstances(instancesIds);
    this._entityRenderHelper.removeSelectionBoundingRect();
  }

  public processBoleanOperation(...args: Parameters<DrawingsGeometryBooleanOperationHelper['processOperation']>): void {
    this._operationHelper.processOperation(...args);
  }

  @autobind
  public subtract(baseId: string, keepOld: boolean): void {
    this._operationHelper.processOperation(
      DrawingsBooleanOperation.Subtract,
      { keepOld, baseInstanceId: baseId },
    );
  }

  public convertPolygonsToPolylines(settings: OpenPolygonSettings): void {
    this._operationHelper.processOperation(
      DrawingsBooleanOperation.OpenPolygon,
      settings,
    );
  }

  @autobind
  public applyOffset(onApplied: () => void): void {
    this._drawingOffsetHelper.apply(onApplied);
  }

  public createInstances(analitycsParams: Record<string, string | number>): boolean {
    if (this._magicSearch) {
      this._magicSearch.apply(analitycsParams);
    }
    if (this._autoMeasureAi) {
      this._autoMeasureAi.apply(analitycsParams);
    }

    return true;
  }

  public async forceRenderInstances(
    {
      instances,
      points,
    }: {
      instances: DrawingsGeometryInstanceWithId[],
      points: Record<string, ShortPointDescription>,
    },
  ): Promise<void> {
    this._cachedPoints.addPoints(points);
    const currentDrawingId = this._config.getCurrentDrawing()?.drawingId;
    for await (const instance of this._renderOperationProcessor.startIteration(instances)) {
      if (instance.drawingId !== currentDrawingId) {
        continue;
      }
      this._entityRenderHelper.renderInstance(instance.id, instance);
    }
  }

  public renderInstances(instancesIds: string[]): void {
    this.renderInstancesAsync(instancesIds);
  }


  @autobind
  public splitBySelectedPoints(): void {
    if (this._entityRenderHelper.isModifyEnabled) {
      this._entityRenderHelper.splitBySelectedModifyPoints();
    }
    this._config.changeDrawMode(DrawingsDrawMode.Disabled);
  }

  @autobind
  public removeModifyPoints(): void {
    if (this._entityRenderHelper.isModifyEnabled) {
      this._entityRenderHelper.removeModifySelectedPoints();
    }
  }

  public initDrawKeyListeners(): void {
    this.removeContinueKeys();
    this.removeModifyKeyListeners();
    this._config.undoRedoChangeActiveStatus(false);
    this._drawKeyboardListener.activate();
  }

  public removeDrawKeyListeners(): void {
    this._config.undoRedoChangeActiveStatus(true);
    this._drawKeyboardListener.deactivate();
  }

  private initContinueKeys(): void {
    this.removeDrawKeyListeners();
    this.removeModifyKeyListeners();
    this._continueKeyboardListener.activate();
  }

  private removeContinueKeys(): void {
    this._continueKeyboardListener.deactivate();
  }

  private initModifyKeyListeners(): void {
    this.removeDrawKeyListeners();
    this.removeContinueKeys();
    this._modifyKeyboardListener.activate();
  }

  private removeModifyKeyListeners(): void {
    this._modifyKeyboardListener.deactivate();
  }

  @autobind
  private getEntityPath(instanceId: string): DrawingsAllowedPathType {
    let path: DrawingsAllowedPathType;
    if (this._entityRenderHelper.isInstanceRendered(instanceId)) {
      path = this._entityRenderHelper.getInstancePath(instanceId).clone();
    } else {
      this._entityRenderHelper.renderInstanceById(instanceId);
      path = this._entityRenderHelper.getInstancePath(instanceId).clone();
      this._entityRenderHelper.removeEntityById(instanceId);
    }
    return path;
  }

  @autobind
  private activateOffset(): void {
    const selectedInstanceId = this._drawingOffsetHelper.instancesForProcess[0];
    const path: DrawingsAllowedPathType = this.getEntityPath(selectedInstanceId);
    this._drawingOffsetHelper.startOffset(
      path,
      this._colorCacheHelper.getPaperColor(this._config.getColorOfInstance(selectedInstanceId)),
      this._config.getDrawingInstance(selectedInstanceId) as DrawingsGeometryInstance<DrawingsGeometryStrokedType>,
    );
  }

  @autobind
  private openFinishPopup(e: PaperMouseEvent, api: FinishDrawApi, config: FinishDrawConfig): void {
    this._config.openFinishDrawPopup(e, api, config);
  }

  private disableMagicSearch(): void {
    this._magicSearch?.destroy();
    this._magicSearch = null;
  }

  private disableContinue(): void {
    const entityForContinueId = this._entityRenderHelper.entityForContinueId;
    if (entityForContinueId && (
      !this._instancesFilter.applyFilter(entityForContinueId)
      || !this._config.checkInstanceVisibility(entityForContinueId)
    )) {
      this._entityRenderHelper.removeEntityById(entityForContinueId);
    }
    this._entityRenderHelper.entityForContinueId = undefined;
  }


  @autobind
  private pasteWithPoint(point: paper.Point): void {
    if (this._config.copyPasteHelper.bufferedInstancesSource === DrawingsSourceOfBuffer.Cut) {
      this._config.changeDrawMode(DrawingsDrawMode.Disabled);
    }
    this._config.copyPasteHelper.pasteWithPoint(point, this._config.getCurrentDrawing().drawingId, true);
  }

  @autobind
  private onEntityClick(id: string, e: PaperMouseEvent): void {
    if (
      !this._drawHelper.isDrawing
      && !this._entityRenderHelper.isContinueEnabled
      && !this._entityRenderHelper.isVisualSearchRendered
      && id !== DrawingsCanvasConstants.visualSearch
      && id
    ) {
      if (PaperMouseEventUtils.isRightMouseButton(e)) {
        this._config.changeDrawMode(DrawingsDrawMode.Disabled);
        if (this._selectedEntities.length) {
          return;
        }
      } else {
        if (this._drawHelper.isDrawingMode) {
          return;
        }
        ConstantFunctions.stopEvent(e);
      }
      this._config.onSelectInstance(id, e);
    }
  }

  @autobind
  private onDoubleMainGeometryClick(id: string, e: PaperMouseEvent): void {
    if (id === DrawingsCanvasConstants.calibrateLineId
      || id === DrawingsCanvasConstants.visualSearch
      || !this._config.editPermissionsObserver.getContext().canEditMeasure
      || this._entityRenderHelper.isContinueEnabled
      || this._drawHelper.isDrawingMode
    ) {
      return;
    }
    e.stopPropagation();
    this._config.onSelectInstance(id, e);
    this.enableEntityModify(id);
  }

  private enableEntityModify(id: string): void {
    const instance = this._config.getDrawingInstance(id);
    if (!instance) {
      return;
    }
    if (DrawingsGeometryUtils.isRectangle(instance.type, instance.geometry)) {
      this._config.geometryConversionProcessor.convertRectangleToPolygon(id);
    }
    this._entityRenderHelper.startModify(id);
    this._config.changeDrawMode(DrawingsDrawMode.Modify);
    this._entityRenderHelper.setEntityState(id, DrawingsGeometryEntityState.Modify);
  }


  @autobind
  private startDragPoint(id: string, e: PaperMouseEvent): void {
    this._editPoint = id;
    this._config.cursorTypeHelper.grabbing = true;
    if (DrawingsCanvasUtils.isCalibrationPoint(id)) {
      this._drawHelper.disableDrawMode();
      this._dragHelper.setCallback(this.editCalibrationPointPosition, { defaultValidPoint: e.point });
    }
  }

  @autobind
  private editCalibrationPointPosition({ point, finish }: DrawingsDragCallbackParams): boolean {
    this._cachedPoints.setPoint(this._editPoint, point);
    const entity = this._entityRenderHelper.getInstance(DrawingsCanvasConstants.calibrateLineId);
    const measure = this._entityRenderHelper.updatePointPositionInCalibration(this._editPoint);
    if (finish) {
      this._config.cursorTypeHelper.grabbing = false;
      this._drawHelper.enableDrawMode(DrawingsDrawMode.Calibrate);

      this._config.onCalibrateLineDrawn({
        ...entity.getGeometry() as DrawingsCalibrationLineGeometry,
        ...measure,
      });
      this._dragHelper.setCallback(null);
    }
    return true;
  }

  @autobind
  private startDragEntities(_id: string, e: PaperMouseEvent, byPoint?: boolean): void {
    this._dragInstancesHelper.startDragEntities(this._selectedEntities, e, byPoint);
  }

  @autobind
  private updatePointsPosition(points: Record<string, ShortPointDescription>): void {
    const instancesForUpdate: Record<string, boolean> = {};
    const lineUpdatesMeasures: Record<string, DrawingsInstanceMeasure> = {};

    for (const [pointId, point] of Object.entries(points)) {
      this._cachedPoints.setPoint(pointId, this._config.getPoint(point));
      const pointInfo = this._config.getPointInfo(pointId);
      if (pointInfo) {
        const instanceId = pointInfo.instanceId;
        const { scale, metersPerPixel } = this._textParamsObserver.getContext();
        instancesForUpdate[instanceId] = true;
        if (pointInfo.lines) {
          for (const lineId of pointInfo.lines) {
            if (this._config.getInstanceMeasures(lineId)) {
              lineUpdatesMeasures[lineId] =
                this.getSegmentMeasureUpdate(lineId, points, pointId, scale, metersPerPixel);
            }
          }
        }
      }
    }
    const measures = Object.values(lineUpdatesMeasures);
    for (const measure of this.updateInstanceMeasuresIterator(Object.keys(instancesForUpdate))) {
      measures.push(measure);
    }

    this.changePointsPosition(points, measures);
  }

  @autobind
  private changePointsPosition(
    updatedPoints: Record<string, ShortPointDescription>,
    newInstanceMeasures: DrawingsInstanceMeasure[],
  ): void {
    this._dragHelper.setCallback(null);
    this._config.changePointsPosition(updatedPoints, newInstanceMeasures);
  }

  @autobind
  private getSegmentMeasureUpdate(
    lineId: string,
    pointUpdated: Record<string, ShortPointDescription>,
    pointId: string,
    scale: number,
    metersPerPx: number,
  ): DrawingsInstanceMeasure {
    const [start, end] = DrawingAnnotationUtils.getPointsIdsFromLineKey(lineId)
      .map(linePointId => pointUpdated[linePointId] || this._config.getPointCoordinates(linePointId));
    const pxLength = DrawingsCanvasUtils.calculateLineLength(start, end);
    const color = this._config.getColorOfInstance(this._config.getPointInfo(pointId).instanceId);
    const measureUpdate = {
      type: DrawingsInstanceType.Segment,
      id: DrawingAnnotationUtils.getPointsIdsFromLineKey(lineId),
      color,
      measures: {
        pxLength,
        length: DrawingsCanvasUtils.pxToMetres(pxLength, scale, metersPerPx),
      },
    };
    return measureUpdate;
  }

  @autobind
  private * updateInstanceMeasuresIterator(instancesIds: string[]): IterableIterator<DrawingsInstanceMeasure> {
    for (const instanceId of instancesIds) {
      const measure = this.updateInstanceMeasures(instanceId);
      if (measure) {
        yield measure;
      }
    }
  }

  private updateInstanceMeasures(instanceId: string): DrawingsInstanceMeasure {
    const instance = this._config.getDrawingInstance(instanceId);
    const measures = this._entityRenderHelper.updateGeometry(instanceId, instance.geometry);
    const instanceMeasures = this._config.getInstanceMeasures(instanceId);
    if (this._config.getInstanceMeasures(instanceId) && measures) {
      return { ...instanceMeasures, measures };
    }
    return null;
  }

  @autobind
  private onCreateElement(data: FinishedDrawingElement[], config?: CreateElementConfig): void {
    if (data) {
      const { drawingId: elementDrawingId, isDrawn, analyticsParams: analyticsParams } = config || {};
      const geometries = [];
      const newPoints = {};
      const drawingId = elementDrawingId || this._config.getCurrentDrawing().drawingId;
      for (const element of data) {
        const { type, geometry, points, name: instanceName } = element;
        this._cachedPoints.addPoints(points);
        const id = UuidUtil.generateUuid();
        if (DrawingsGeometryUtils.isCalibrate(type, geometry)) {
          const color = this._colorCacheHelper.getPaperColor(geometry.color);
          this._entityRenderHelper.drawCalibrationLine(geometry, color);
          this._config.onCalibrateLineDrawn(geometry);
        } else if (DrawingsGeometryUtils.isDropperAreaPolygon(type, geometry)) {
          const contour = geometry.points.map(x => points[x]);
          if (this._drawHelper.drawMode === DrawingsDrawMode.MagicSearchArea) {
            this._config.setMagicSearchZone(contour);
          } else {
            this._config.onWizzardWorkingArea(contour);
          }
        } else if (DrawingsGeometryUtils.isWizzardSelectionAreaPolygon(type, geometry)) {
          this._config.onWizzardSelectionArea(geometry.points.map(x => points[x]));
        } else if (type === DrawingsInstanceType.PdfFilterArea) {
          this._config.changeDrawMode(DrawingsDrawMode.Disabled);
          this._config.onPdfFilterArea(geometry.points.map(x => points[x]));
        }

        if (!DrawingsGeometryUtils.isUtilityGeometry(type, geometry)) {
          const name = DrawingAnnotationNamingUtils
            .getDefaultGeometryName(instanceName || this._config.getNewInstanceName() || type);
          geometries.push({ id, drawingId, type, geometry, name, isAuto: false });
          objectUtils.extend(newPoints, points);
        }
      }

      if (geometries.length) {
        this.addInstances({
          instances: geometries,
          points: newPoints,
          moveToSelectedGroup: true,
          isDrawn,
          analyticsParams,
        });
      }
    }
  }

  @autobind
  private addInstances(payload: AddInstancesEnginePayload): void {
    const {
      instances,
      points,
      measures,
      ignoreSaveMeasureOnCreate,
      moveToSelectedGroup,
      newIdsToSource,
      isDrawn,
      analyticsParams,
    } = payload;
    this._config.onBatchUpdateGeometries({
      addedInstances: instances,
      newPoints: points,
      pageId: instances[0].drawingId,
      ignoreSaveMeasureOnCreate,
      moveToSelectedGroup,
      newIdsToSource,
      isDrawn,
      analyticsParams,
    }, measures);
  }

  @autobind
  private removeInstances(instancesIds: string[]): void {
    instancesIds.forEach(x => this._entityRenderHelper.removeEntityById(x));
    if (
      this._entityRenderHelper.isContinueEnabled && instancesIds.includes(this._entityRenderHelper.entityForContinueId)
    ) {
      this._config.changeDrawMode(DrawingsDrawMode.Disabled);
    }
  }

  @autobind
  private sendMeasuresUpdate(measures: DrawingsInstanceMeasure[]): void {
    if (measures.length && this._config.sendMeasuresUpdate) {
      this._config.sendMeasuresUpdate(measures);
    }
  }

  private async renderInstancesAsync(instancesIds: string[]): Promise<void> {
    for await (const instanceId of this._renderOperationProcessor.startIteration(instancesIds)) {
      const filterValue = this._instancesFilter.applyFilter(instanceId);
      const hiddenInstancesFilterValue = this._hiddenInstancesFilter.applyFilter(instanceId);
      if (filterValue && hiddenInstancesFilterValue) {
        if (this._entityRenderHelper.isInstanceRendered(instanceId)) {
          const geometry = this._config.getDrawingInstance(instanceId).geometry;
          if (this._entityRenderHelper.getInstanceGeometry(instanceId) !== geometry) {
            this._entityRenderHelper.removeEntityById(instanceId);
            this._entityRenderHelper.renderInstanceById(instanceId);
          }
        } else {
          this._entityRenderHelper.renderInstanceById(instanceId);
        }
      } else if (this._entityRenderHelper.isInstanceRendered(instanceId)) {
        this._entityRenderHelper.removeEntityById(instanceId);
      }
    }
    this._entityRenderHelper.setEntitiesState(this._selectedEntities, DrawingsGeometryEntityState.Selected);
  }

  private removeCalibrateIfNeeded(mode: DrawingsDrawMode): void {
    if (this.shouldRemoveCalibrationLine(mode)) {
      this._entityRenderHelper.removeCalibration();
    }
  }

  private shouldRemoveCalibrationLine(mode: DrawingsDrawMode): boolean {
    return mode !== DrawingsDrawMode.Calibrate
      && mode !== DrawingsDrawMode.CalibrateScale
      && this._entityRenderHelper.isCalibrationRendered;
  }

  private applyDrawModeToModify(mode: DrawingsDrawMode): void {
    if (mode === DrawingsDrawMode.Modify) {
      this.initModifyKeyListeners();
    } else if (this._entityRenderHelper.isModifyEnabled) {
      this._entityRenderHelper.setEntitiesState(
        [this._entityRenderHelper.modifyInstanceId],
        DrawingsGeometryEntityState.Selected,
      );
      this._entityRenderHelper.disableModify();
      this._entityRenderHelper.setEntitiesState(this._selectedEntities, DrawingsGeometryEntityState.Selected);
      this.removeModifyKeyListeners();
    }
  }

  private applyModeToDrawHelper(mode: DrawingsDrawMode): void {
    if (DrawingsCommonUtils.isDrawEnabled(mode)) {
      this.disableContinue();
      this._drawingOffsetHelper.finishOffset();
      const currentDrawing = this._config.getCurrentDrawing();
      this._drawHelper.enableDrawMode(
        mode,
        currentDrawing.drawingId,
      );
      this.initDrawKeyListeners();
      this.applyModeToMagicSearch(mode);
      this.applyModeToAutoMeasure(mode);
    } else {
      this._drawHelper.disableDrawMode();
      if (mode !== DrawingsDrawMode.Dropper) {
        this._config.viewHelper.restoreDefaultEvent('onMouseMove');
      }

      this._snappingHelper.removeSnapping();
      this.applyModeToOffset(mode);
      this.applyModeToContinue(mode);
      this.applyModeToMagicSearch(mode);
      this.applyModeToAutoMeasure(mode);
    }
  }

  private applyModeToContinue(mode: DrawingsDrawMode): void {
    if (mode === DrawingsDrawMode.Continue) {
      const selectedEntityId = this._selectedEntities[0];
      if (!this._entityRenderHelper.isInstanceRendered(selectedEntityId)) {
        this._entityRenderHelper.renderInstanceById(selectedEntityId);
        this._entityRenderHelper.setEntityState(selectedEntityId, DrawingsGeometryEntityState.Selected);
      }
      this._entityRenderHelper.entityForContinueId = this._selectedEntities[0];
      this.initContinueKeys();
    } else if (this._entityRenderHelper.isContinueEnabled) {
      this.disableContinue();
      this.removeContinueKeys();
      this.removeDrawKeyListeners();
    }
  }

  private applyModeToOffset(mode: DrawingsDrawMode): void {
    if (mode === DrawingsDrawMode.Offset) {
      if (!this._drawingOffsetHelper.isActive) {
        this.activateOffset();
      }
      this.removeDrawKeyListeners();
    } else if (this._drawingOffsetHelper.isActive) {
      this._drawingOffsetHelper.finishOffset();
    }
  }

  private applyModeToMagicSearch(mode: DrawingsDrawMode): void {
    if (mode === DrawingsDrawMode.MagicSearch) {
      this._magicSearch = new MagicSearch({
        magicSearchDataObserver: this._config.magicSearchDataObserver,
        renderParametersContextObserver: this._config.geometryRenderParamsObserver,
        newDrawingStylesObserver: this._config.newDrawingStylesObserver,
        colorCache: this._colorCacheHelper,
        layer: this._tempLayer,
        onCreateElement: this.onCreateElement,
        onTogglePreviewStatus: this._config.onToggleMagicSearchPreviewStatus,
        setPreviewFixApi: this._config.onMagicSearchPreviewStartFix,
        getDrawingInfo: this._config.getCurrentDrawing,
        applyFix: this._config.applyMagicSearchFix,
        updateFixStatus: this._config.updateMagicSearchFixStatus,
      });
    } else if (this._magicSearch) {
      this.disableMagicSearch();
    }
    if (mode === DrawingsDrawMode.MagicSearch || mode === DrawingsDrawMode.MagicSearchArea) {
      this._entityRenderHelper.clear();
    }
  }

  private applyModeToAutoMeasure(mode: DrawingsDrawMode): void {
    if (mode === DrawingsDrawMode.AutoMeasure2) {
      this._autoMeasureAi = new AiSearchHelperBase({
        magicSearchDataObserver: this._config.magicSearchDataObserver,
        renderParametersContextObserver: this._config.geometryRenderParamsObserver,
        newDrawingStylesObserver: this._config.newDrawingStylesObserver,
        colorCache: this._colorCacheHelper,
        layer: this._tempLayer,
        onCreateElement: this.onCreateElement,
        onTogglePreviewStatus: this._config.onToggleMagicSearchPreviewStatus,
        setPreviewFixApi: this._config.onMagicSearchPreviewStartFix,
        getDrawingInfo: this._config.getCurrentDrawing,
        applyFix: this._config.applyMagicSearchFix,
        updateFixStatus: this._config.updateMagicSearchFixStatus,
      });
    } else {
      this._autoMeasureAi?.destroy();
      this._autoMeasureAi = null;
    }

    if (mode === DrawingsDrawMode.AutoMeasure2 || mode === DrawingsDrawMode.MagicSearchArea) {
      this._entityRenderHelper.clear();
    }
  }
}
