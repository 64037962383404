import moment from 'moment';
import { Currency } from '../constants';
import {
  EstimateSubscriptionModel,
  InvoiceDiscountsModel,
  InvoiceItemModel,
  InvoiceModel,
  InvoiceTaxModel,
} from '../interfaces/estimation-subscription-model';

const DATE_FORMAT = 'D MMM YYYY';

const getExtraCurrency = (billingCountry: string): string | null => {
  return billingCountry === 'US' ? Currency.USD.shortcat : null;
};

const getExtraCurrencyValue = (value: number, extraCurrency: string, rates: Record<string, number>): number => {
  if (!(extraCurrency in rates)) {
    return value;
  }

  return Math.trunc(value * rates[extraCurrency]);
};

const numberToString = (price: number): string => {
  const cents = (price % 100).toString().padEnd(2, '0');
  return `${Math.trunc(price / 100)},${cents}`;
};

const getPriceString = (price: number, extraCurrency?: string, rates?: Record<string, number>): string => {
  const pounds = numberToString(price);
  if (extraCurrency && rates) {
    const extraValue = getExtraCurrencyValue(price, extraCurrency, rates);
    const extraValueString = numberToString(extraValue);
    return `${Currency.GBP.symbol}${pounds} (≈${Currency[extraCurrency].symblo}${extraValueString})`;
  }

  return `${Currency.GBP.symbol}${pounds}`;
};

const getDateString = (date: string): string => {
  return  moment(date).format(DATE_FORMAT);

};

const getTaxString = (tax: InvoiceTaxModel, currency: string): string => {
  const amount = getPriceString(tax.amount, currency);
  return `${amount} ${tax.description}`;
};

const getItemString = (item: InvoiceItemModel): string => {
  const pricePerUnit = getPriceString(item.amount / item.quantity);
  return `${item.description} ${pricePerUnit} x ${item.quantity}`;
};

const getTotalInvoicePriceString = (invoice: InvoiceModel, currency: string): string => {
  const total = getPriceString(invoice.total, currency);
  if (!invoice.taxes.length) {
    return `Total: ${total}`;
  }

  const totalTaxes = invoice.taxes.reduce((acc, tax) => acc + tax.amount, 0);
  const withoutTaxes = getPriceString(invoice.total - totalTaxes, currency);
  const taxes = invoice.taxes.map(x => getTaxString(x, currency)).join(' + ');
  return `Total: ${total} (${withoutTaxes} + ${taxes})`;
};

const getDiscountString = (discount: InvoiceDiscountsModel): string => {
  const price = getPriceString(discount.amount);
  return `Discount: ${discount.description}(${price})`;
};

const getInvoiceDetails = (invoice: InvoiceModel, currency: string): string[] => {
  return [
    ...invoice.items.map(getItemString),
    ...invoice.discounts.map(getDiscountString),
    getTotalInvoicePriceString(invoice, currency),
  ];
};

const getNextBillingDetails = (invoice: InvoiceModel, currency: string, nextBillingDate): string[] => {
  const billingDate = getDateString(nextBillingDate);
  return [`Next billing at ${billingDate}`]
    .concat(getInvoiceDetails(invoice, currency));
};

const getImmediateInvoiceBillingDetails = (invoice: InvoiceModel, currency: string): string[] => {
  return [`Current billing`]
    .concat(getInvoiceDetails(invoice, currency));
};

const getPaymentDetails = (estimation: EstimateSubscriptionModel, currency: string): string[] => {
  if (!estimation) {
    return null;
  }

  const current = estimation.immediateInvoice
    && getImmediateInvoiceBillingDetails(estimation.immediateInvoice, currency);
  const next = estimation.nextInvoice
    && getNextBillingDetails(estimation.nextInvoice, currency, estimation.nextBillingAt);
  const result = [];

  if (current) {
    result.push(...current);
  }

  if (current && next) {
    result.push('');
  }

  if (next) {
    result.push(...next);
  }

  return result;
};

export const BillingEstimationUtils = {
  getPaymentDetails,
  getExtraCurrencyValue,
  getExtraCurrency,
};
