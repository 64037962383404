import styled from 'styled-components';

import { BadgeStyled } from 'common/components/badge';
import { Text } from 'common/components/text';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-left: 5px;
`;

const BadgeContainer = styled.div`
  height: 18px;
  cursor: pointer;

  ${BadgeStyled.BadgeContainer} {
    height: 100%;
    border-radius: 4px;
    margin-left: 2px;
    padding: 0 4px;

    ${Text} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const Styled = {
  Container,
  BadgeContainer,
};
