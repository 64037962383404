import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './index.scss';

import { Controls3D } from 'common/components/controls-3d';
import { KreoToolbar } from 'common/components/kreo-toolbar';
import { KreoColors } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { Engine } from '../../../../components/engine';
import { KreoEngine } from '../../../../components/engine/KreoEngine';
import { Unit } from '../../../../components/engine/KreoEngineConsts';
import { PlanProjectRouteParams, Qto3dProjectRouteParams } from '../../../../routes/app-routes-params';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';
import { ModelCheckActions } from './actions';
import { Errors } from './errors';
import { ScrollToType } from './errors-content';
import * as Types from './interfaces/types';

interface DispatchProps {
  loadData: () => void;
  onIssueSelect: (order: number) => void;
  onDeselect: () => void;
  onSelectBimHandle: (id: number) => void;
  onSecondHandleSelect: (id: number) => void;
  onSelectBimHandleById: (id: number, api: ScrollToType) => void;
  onDeselectHandle: () => void;
  toggleAutoFocusEngine: () => void;
}

interface StateProps {
  selectedIssues: number;
  trivial: number[];
  warning: number[];
  errors: any[];
  selectedHandle: Types.SelectedHandle;
  secondHandle: Types.SelectedHandle;
  loaded: boolean;
  isAutoFocus: boolean;
}

interface ComponentState {
  isClipBoxEnabled: boolean;
  isIsometryEnabled: boolean;
  isGhostEnabled: boolean;
  engineContainerRef: HTMLDivElement;
  isRulerEnabled: boolean;
  isFullScreen: boolean;
  isToolbarHide: boolean;
  isImperialUnit: boolean;
  selectedIds: number[];
  isShowAllOn: boolean;
  color: string;
}

interface Props extends DispatchProps,
  StateProps,
  RouteComponentProps<PlanProjectRouteParams | Qto3dProjectRouteParams> { }

export class ModelCheck extends React.Component<Props, ComponentState> {
  private engineApi: KreoEngine = null;
  private readonly warningColor: string = '#ffae3d';
  private readonly trivialColor: string = KreoColors.gray6;
  private selectedid: number = null;
  private selectFromMenu: boolean = false;
  private scrollToApi: ScrollToType;
  private visibleIds: number[] = [];
  private intersectedIds: number[] = [];
  constructor(props: Props) {
    super(props);

    this.state = {
      isClipBoxEnabled: false,
      isIsometryEnabled: false,
      isRulerEnabled: false,
      isGhostEnabled: true,
      engineContainerRef: null,
      isFullScreen: false,
      isToolbarHide: false,
      isImperialUnit: false,
      selectedIds: [],
      isShowAllOn: false,
      color: KreoColors.blue4,
    };
  }


  public componentDidMount(): void {
    this.props.loadData();
  }

  public shouldComponentUpdate(nextProps: Props): boolean {
    if (this.props.selectedIssues !== nextProps.selectedIssues) {
      if (nextProps.selectedIssues === null) {
        this.setColorTint(nextProps.trivial, nextProps.warning);
      } else {
        this.dropColorTint();
      }
    }

    if (nextProps.selectedHandle && nextProps.selectedHandle !== this.props.selectedHandle) {
      if (nextProps.selectedHandle.bimHandleIds) {
        this.dropColorTint();
        const intersectedIds = new Array<number>();
        for (const handlesInfo of nextProps.selectedHandle.bimHandleIds) {
          arrayUtils.extendArray(
            intersectedIds,
            (handlesInfo.bimHandleIds as Types.BimHandeIdToRevitId[]).map(x => x.bimHandleId),
          );
        }
        const visibleIds = [nextProps.selectedHandle.bimHandleId];
        arrayUtils.extendArray(visibleIds, intersectedIds);
        this.engineApi.toggleVisibility(true, visibleIds, false);
        this.engineApi.setColorTint(this.warningColor, intersectedIds);
        this.visibleIds = visibleIds;
        this.intersectedIds = intersectedIds;
      }
      const selected: number[] = this.engineApi.getSelected();
      if ((selected.includes(nextProps.selectedHandle.bimHandleId) && selected.length > 1)
      || !selected.includes(nextProps.selectedHandle.bimHandleId)) {
        this.selectFromMenu = true;
        this.engineApi.setSelected([nextProps.selectedHandle.bimHandleId]);
      }
    }

    if (nextProps.secondHandle && this.props.secondHandle !== nextProps.secondHandle) {
      const selected: number[] = this.engineApi.getSelected();
      if (
        !selected.includes(nextProps.secondHandle.bimHandleId)
        || !selected.includes(nextProps.selectedHandle.bimHandleId)
      ) {
        this.selectFromMenu = true;
        this.engineApi.setSelected([nextProps.selectedHandle.bimHandleId, nextProps.secondHandle.bimHandleId]);
      }
    }

    return true;
  }

  public render(): any {
    return (
      <div className='model-check-page'>
        <Errors
          onSecondHandleSelect={this.onSelectSecondBimHandle}
          onSelectBimHandle={this.onSelectBimHandle}
          selectedIssues={this.props.selectedIssues}
          onIssueDeselect={this.onDeselect}
          onIssueSelect={this.onSelect}
          saveScrollApi={this.saveScrollTo}
        />
        <div
          className={classNames(
            'model-check-page__engine-layout',
            { 'model-check-page__engine-layout--has-selected': this.props.selectedIssues !== null },
            { 'model-check-page__engine-layout--full-screen': this.state.isFullScreen },
          )}
        >
          <div
            className={classNames(
              'model-check-page__engine-layout-inner',
              { 'model-check-page__engine-layout-inner--toolbar-hide': this.state.isToolbarHide },
            )}
          >
            <Engine
              onHandleClick={this.handleEngineSelect}
              onToggleRuler={this.onEngineToggleRuler}
              onToggleClipBox={this.onEngineToggleClipBox}
              toggleCameraParallel={this.onEngineToggleCameraParallel}
              projectId={parseInt(this.props.match.params.projectId, 10)}
              textures='/static/textures/'
              sendEngineApi={this.takeEngineApi}
              affterLoadAction={this.afterLoadAction}
              saveContainerRef={this.saveEngineContainerRef}
            />
            <KreoToolbar
              isToolbarHide={this.state.isToolbarHide}
              onHideToggle={this.onHideToolbarToggle}
              className='model-check-page__engine-toolbar'
            >
              <Controls3D
                isSelectedElement={!!this.state.selectedIds.length}
                ghostEnabled={this.state.isGhostEnabled}
                isometryEnabled={this.state.isIsometryEnabled}
                clipBoxEnabled={this.state.isClipBoxEnabled}
                rulerEnabled={this.state.isRulerEnabled}
                isFullScreen={this.state.isFullScreen}
                isImperialUnit={this.state.isImperialUnit}
                isAutoFocus={this.props.isAutoFocus}
                isShowAllOn={this.state.isShowAllOn}
                isShowAllEnabled={!!this.state.selectedIds.length}
                engineLayoutContainer={this.state.engineContainerRef}
                color={this.state.color}
                onChangeColor={this.onChangeColor}
                onAssignColor={this.onAssignColor}
                onDefaultColor={this.onDefaultColor}
                onHome={this.onHome}
                onFocus={this.onFocus}
                toggleGhost={this.toggleGhost}
                toggleClipBox={this.toggleClipBox}
                toggleIsometry={this.toggleIsometry}
                toggleRuler={this.toggleRuler}
                toggleFullScreen={this.toggleFullScreen}
                toggleAutoFocus={this.props.toggleAutoFocusEngine}
                toggleMetricImperial={this.toggleUnit}
                modelCheckProperties={true}
                toggleShowAll={this.toggleShowAll}
              />
            </KreoToolbar>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private onDefaultColor(): void {
    const ids = this.engineApi.getSelected();
    this.engineApi.setColorTint(null, ids);
  }

  @autobind
  private toggleShowAll(): void {
    if (!this.state.isShowAllOn) {
      this.engineApi.toggleVisibility(true);
    } else {
      this.engineApi.toggleVisibility(true, this.visibleIds, false);
      this.engineApi.setColorTint(this.warningColor, this.intersectedIds);
    }
    this.setState((s) => ({ isShowAllOn: !s.isShowAllOn }));
  }

  @autobind
  private onAssignColor(): void {
    const ids = this.engineApi.getSelected();
    this.engineApi.setColorTint(this.state.color, ids);
  }

  @autobind
  private onChangeColor(color: string): void {
    this.setState({ color });
  }

  @autobind
  private onHome(): void {
    this.engineApi.cameraToHome();
  }

  @autobind
  private onFocus(): void {
    const ids = this.engineApi.getSelected();
    this.engineApi.focusCamera(ids);
  }

  @autobind
  private onEngineToggleClipBox(isClipBoxEnabled: boolean): void {
    this.setState({ isClipBoxEnabled });
  }

  @autobind
  private toggleClipBox(): void {
    if (this.engineApi) {
      this.engineApi.toggleClipbox(!this.state.isClipBoxEnabled, this.state.isGhostEnabled ? 'ghost' : 'none');
    }
  }

  @autobind
  private toggleGhost(): void {
    if (this.engineApi) {
      const { isGhostEnabled, isClipBoxEnabled } = this.state;
      if (isClipBoxEnabled) {
        this.engineApi.toggleClipbox(true, !isGhostEnabled ? 'ghost' : 'none');
      }
      this.engineApi.showInvisibleAsGhost(!isGhostEnabled);
      this.setState({ isGhostEnabled: !isGhostEnabled });
    }
  }

  @autobind
  private onEngineToggleCameraParallel(isIsometryEnabled: boolean): void {
    this.setState({ isIsometryEnabled });
  }

  @autobind
  private toggleIsometry(): void {
    this.engineApi.toggleParallelProjection(!this.state.isIsometryEnabled);
  }

  @autobind
  private onEngineToggleRuler(enabledRuler: boolean): void {
    this.setState({ isRulerEnabled: enabledRuler });
  }

  @autobind
  private toggleRuler(): void {
    if (this.engineApi) {
      const { isRulerEnabled } = this.state;
      this.engineApi.toggleRuler(!isRulerEnabled);
    }
  }

  @autobind
  private saveEngineContainerRef(ref: HTMLDivElement): void {
    this.setState({ engineContainerRef: ref });
  }

  @autobind
  private onHideToolbarToggle(): void {
    this.setState((s) => ({ isToolbarHide: !s.isToolbarHide }));
  }

  @autobind
  private toggleFullScreen(): void {
    this.setState((s) => ({ isFullScreen: !s.isFullScreen }));
  }

  @autobind
  private toggleUnit(): void {
    if (this.engineApi) {
      const { isImperialUnit } = this.state;
      this.engineApi.setUiUnits(
        isImperialUnit
          ? { length: Unit.Meter, area: Unit.MeterPow2 }
          : { length: Unit.FootInch, area: Unit.FootPow2 },
      );
      this.setState({ isImperialUnit: !isImperialUnit });
    }
  }

  @autobind
  private saveScrollTo(scrollTo: ScrollToType): void {
    this.scrollToApi = scrollTo;
  }

  @autobind
  private dropColorTint(): void {
    this.engineApi.setColorTint(null);
  }

  @autobind
  private afterLoadAction(): void {
    this.setColorTint(this.props.trivial, this.props.warning);
  }

  @autobind
  private takeEngineApi(engine: any): void {
    this.engineApi = engine;
    this.engineApi.showInvisibleAsGhost(true);
    this.setColorTint(this.props.trivial, this.props.warning);
  }

  @autobind
  private onSelect(order: number): void {
    const errors = this.props.errors[order - 1];
    const ids = new Array<number>();
    for (const element of errors) {
      if (element.bimHandleId !== undefined) {
        ids.push(element.bimHandleId);
        for (const intersectedElements of element.bimHandleIds) {
          arrayUtils.extendArray(ids, intersectedElements.bimHandleIds.map(x => x.bimHandleId));
        }
      } else {
        arrayUtils.extendArray(ids, element.bimHandleIds.map(x => x.bimHandleId));
      }
    }
    this.engineApi.setSelected([]);
    this.engineApi.toggleVisibility(true, ids, false);
    this.props.onIssueSelect(order);
    if (this.props.isAutoFocus) {
      this.engineApi.focusCamera(ids);
    }
  }

  @autobind
  private onSelectBimHandle(id: number): void {
    this.props.onSelectBimHandle(id);
    this.selectedid = id;
    if (this.props.isAutoFocus) {
      this.engineApi.focusCamera([id]);
    }
    if (this.state.isShowAllOn) {
      this.engineApi.toggleVisibility(true, this.visibleIds, false);
      this.setState({ isShowAllOn: false });
    }
  }

  @autobind
  private setColorTint(trivial: number[], warning: number[]): void {
    if (trivial.length > 0 || warning.length > 0) {
      this.engineApi.setColorTint(this.trivialColor, trivial);
      this.engineApi.setColorTint(this.warningColor, warning);
    }
  }

  @autobind
  private onSelectSecondBimHandle(id: number): void {
    if (this.state.isShowAllOn) {
      this.engineApi.toggleVisibility(true, this.visibleIds, false);
      this.setState({ isShowAllOn: false });
    }
    this.props.onSecondHandleSelect(id);
    if (this.props.isAutoFocus) {
      this.engineApi.focusCamera([this.selectedid, id]);
    }
  }

  @autobind
  private handleEngineSelect(ids: number[]): void {
    if (this.props.selectedIssues === null || this.state.isShowAllOn) {
      this.engineApi.setSelected([]);
      return;
    }
    this.setState({ selectedIds: ids });
    if (this.selectFromMenu) {
      this.selectFromMenu = false;
      return;
    }

    if (ids.length === 1) {
      this.props.onSelectBimHandleById(ids[0], this.scrollToApi);
    } else if (ids.length > 1) {
      const lastId = ids[ids.length - 1];
      this.engineApi.setSelected([lastId]);
      return;
    } else {
      this.dropColorTint();
      this.props.onDeselectHandle();
      this.onSelect(this.props.selectedIssues);
    }
  }
  @autobind
  private onDeselect(): void {
    this.selectFromMenu = true;
    this.engineApi.setSelected([]);
    this.engineApi.toggleVisibility(true);
    this.props.onDeselect();
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    selectedIssues: state.modelcheck.selectedErrorCategory,
    warning: state.modelcheck.warning,
    trivial: state.modelcheck.trivial,
    errors: state.modelcheck.errors,
    selectedHandle: state.modelcheck.selectedHandle,
    loaded: state.modelcheck.loaded,
    secondHandle: state.modelcheck.secondHandle,
    isAutoFocus: state.persistedStorage.isAutoFocusEngine,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    loadData: () => {
      dispatch(ModelCheckActions.getData());
    },
    onIssueSelect: (order: number): void => {
      dispatch(ModelCheckActions.onIssueSelect(order));
    },
    onDeselect: () => {
      dispatch(ModelCheckActions.onDeselect());
    },
    onSelectBimHandle: (id: number) => {
      dispatch(ModelCheckActions.onSelectHandle(id));
    },
    onSecondHandleSelect: (id: number) => {
      dispatch(ModelCheckActions.secondHandleSelect(id));
    },
    onSelectBimHandleById: (id: number, api: ScrollToType) => {
      dispatch(ModelCheckActions.selectHandleById(id, api));
    },
    onDeselectHandle: () => {
      dispatch(ModelCheckActions.deselectHandle());
    },
    toggleAutoFocusEngine: () => dispatch(PersistedStorageActions.toggleEngineAutoFocus()),
  };
}

export const ModelCheckViewPage =
  connect(mapStateToProps, mapDispatchToProps)(ModelCheck);
