import { useCallback } from 'react';
import { DropDownProps } from 'unit-2d-database/components/side-panel/components/item-panel/interfaces';
import { InputProps, InputType } from './use-input-override-memo';
import { UnitState } from './use-unit-state';

export const useCloseCallback = (
  dropDownProps: DropDownProps,
  unitState: UnitState,
  close: () => void,
  inputProps: InputProps,
  input: any,
): () => void => {
  return useCallback(() => {
    if (dropDownProps && unitState.isUnitChanged) {
      if (unitState.value) {
        dropDownProps.onBlur(unitState.value);
      } else {
        dropDownProps.onClick(unitState.activeElementIndex);
      }
    }
    if (inputProps.type === InputType.text) {
      if (inputProps.isChanged) {
        input.onChange(inputProps.value);
      }
    }
    if (inputProps.type === InputType.number) {
      if (inputProps.isChanged) {
        input.onChange(inputProps.value);
      }
    }
    if (inputProps.type === InputType.select) {
      if (inputProps.isChanged) {
        if (inputProps.activeElementIndex !== undefined) {
          input.onClick(inputProps.activeElementIndex);
        } else {
          input.onBlur(inputProps.value);
        }
      }
    }
    close();
  }, [unitState, inputProps]);
};
