import autobind from 'autobind-decorator';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AbilityRoute } from 'common/ability/ability-route';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { MetaTagUtils } from 'common/utils/meta-tag-utils';
import { PageHeader } from '../common/interfaces/page-header';
import { PageLayoutContent } from './page-layout-content';

interface Props<TRouteParams> {
  component: React.ComponentType<RouteComponentProps<TRouteParams>>;
  header: PageHeader;
  backgroundColor?: string;
  path: string;
  exact?: boolean;
  subject?: Subject;
  operation?: Operation;
  redirectUrl?: string;
  metaTitle: string;
}

export class PageLayout<TRouteParams> extends React.Component<Props<TRouteParams>> {

  public componentDidMount(): void {
    MetaTagUtils.setTitle(this.props.metaTitle);
  }

  public componentDidUpdate(): void {
    MetaTagUtils.setTitle(this.props.metaTitle);
  }

  public render(): React.ReactNode {
    const { exact, path, subject, operation, redirectUrl } = this.props;
    return (
      <AbilityRoute
        exact={exact}
        path={path}
        component={this.renderRouteComponent}
        subject={subject}
        operation={operation}
        redirectUrl={redirectUrl}
      />
    );
  }

  @autobind
  public renderRouteComponent(
    props: RouteComponentProps<TRouteParams>,
  ): React.ReactElement<RouteComponentProps<TRouteParams>> {
    const { component, header, backgroundColor } = this.props;
    const InnerComponent = component;

    return (
      <PageLayoutContent header={header} backgroundColor={backgroundColor}>
        <InnerComponent {...props} />
      </PageLayoutContent>
    ) as React.ReactElement<RouteComponentProps<TRouteParams>>;
  }
}
