import { useContext } from 'react';
import { CommentaryThread } from '../interfaces';
import { CommentsApiContext, CommentsContext } from './comments-context';
import { CommentsProviderApi } from './interfaces';

export function useCommentsContext(): CommentaryThread[] {
  return useContext(CommentsContext);
}

export function useCommentsApiContext(): CommentsProviderApi {
  return useContext(CommentsApiContext);
}
