import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { Cancelable, debounce } from 'lodash';
import * as React from 'react';

import { KreoIconAddedDone } from 'common/UIKit';
import { MultilevelSelectOptionData } from '../interfaces/multi-level-select-option-data';
import { MultiLevelOptionDropDown } from '../multi-level-option-drop-down';
import { Styled } from './styled';


type OnSelectCallback<T> =(
  value:
  React.ReactText | React.ReactText[],
  option: T,
  event?: React.MouseEvent<HTMLDivElement>
) => void;

interface Props<T extends MultilevelSelectOptionData<T>> {
  subvariants: T[];
  value: React.ReactText;
  isSelected: boolean;
  isSelectable?: boolean;
  selectedValue: React.ReactText | React.ReactText[];
  optionContentRenderer?: (option: T, isSelected: boolean, isHighlighted?: boolean) => React.ReactNode;
  childRenderer?: () => React.ReactNode;
  onSelectClick: OnSelectCallback<T>;
  onOptionMouseOver?: (value: React.ReactText) => void;
  onOptionMouseOut?: (value: React.ReactText) => void;
  isHighlighted?: boolean;
  tooltip?: React.ReactText;
  optionData: T;
  themeStyle?: boolean;
}

interface State {
  areSubvariantsShown: boolean;
  style: React.CSSProperties;
}

export class MultiLevelSelectOption<T extends MultilevelSelectOptionData<T>>
  extends React.PureComponent<Props<T>, State> {
  private readonly closeNextLevelDebounce: (() => void) & Cancelable;

  private optionRef: HTMLDivElement;

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      areSubvariantsShown: false,
      style: {},
    };
    this.closeNextLevelDebounce = debounce(this.closeNextLevel, 10);
  }

  public render(): React.ReactNode {
    const {
      children,
      subvariants,
      isSelected,
      selectedValue,
      onSelectClick,
      onOptionMouseOver,
      onOptionMouseOut,
      isHighlighted,
      themeStyle,
      childRenderer,
    } = this.props;
    const hasSubvariants = (subvariants && !!subvariants.length) || !!childRenderer;
    const tooltip = typeof this.props.tooltip === 'string' && this.props.tooltip.toString();
    const visibleTooltip = tooltip && tooltip.length > 43 ? tooltip : null;
    const className = classNames(
      'multi-level-select-option',
      {
        'multi-level-select-option--has-subvariants': hasSubvariants,
        'multi-level-select-option--selected': isSelected,
        'multi-level-select-option--show-subvariants': this.state.areSubvariantsShown,
        'multi-level-select-option--highlighted': isHighlighted,
        'multi-level-select-option--theme': themeStyle,
      },
    );
    return (
      <Styled.Container
        themeStyle={themeStyle}
        showSubvariants={this.state.areSubvariantsShown}
        hasSubvariants={hasSubvariants}
        isSelected={isSelected}
        isHighlighted={isHighlighted}
        className={className}
        ref={this.saveOptionRef}
        onClick={this.selectCurrentValue}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <span
          className='multi-level-select-option__content'
          title={visibleTooltip}
        >
          {children}
        </span>
        {isSelected && <i className='multi-level-select-option__icon-selected'><KreoIconAddedDone /></i>}
        {hasSubvariants && this.state.areSubvariantsShown && (
          <MultiLevelOptionDropDown
            value={selectedValue}
            optionContentRenderer={this.props.optionContentRenderer}
            onMouseOver={this.onChildMouseOver}
            onMouseLeave={this.onMouseLeave}
            onOptionMouseOut={onOptionMouseOut}
            onOptionMouseOver={onOptionMouseOver}
            parentOptionRect={this.optionRef.getBoundingClientRect()}
            variants={subvariants}
            onSelectClick={onSelectClick}
            themeStyle={themeStyle}
            subContentRenderer={childRenderer}
          />
        )}
      </Styled.Container>);
  }

  public componentDidUpdate(_prevProps: Props<T>, prevState: State): void {
    if (prevState.areSubvariantsShown !== this.state.areSubvariantsShown) {
      if (this.state.areSubvariantsShown && this.props.onOptionMouseOver) {
        this.props.onOptionMouseOver(this.props.value);
      } else if (this.props.onOptionMouseOut) {
        this.props.onOptionMouseOut(this.props.value);
      }
    }
  }

  @autobind
  private onMouseLeave(): void {
    this.closeNextLevel();
    this.closeNextLevelDebounce();
  }

  @autobind
  private closeNextLevel(): void {
    this.setState({ areSubvariantsShown: false });
  }

  @autobind
  private onChildMouseOver(): void {
    this.closeNextLevelDebounce.cancel();
  }

  @autobind
  private onMouseEnter(): void {
    const { subvariants } = this.props;
    if (this.state.areSubvariantsShown) {
      this.closeNextLevel();
      if (subvariants && subvariants.length) {
        this.closeNextLevelDebounce.cancel();
      }
      return;
    } else {
      this.setState({
        areSubvariantsShown: true,
      });
    }
  }

  @autobind
  private selectCurrentValue(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    const { value, onSelectClick, isSelectable, optionData } = this.props;
    if (isSelectable) {
      onSelectClick(value, optionData, e);
    }
  }

  @autobind
  private saveOptionRef(ref: HTMLDivElement): void {
    this.optionRef = ref;
  }
}
