import { ElementTooltip, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { MoveToCellOptionType } from 'common/components/drawings/drawings-canvas-menus';
import {
  DrawingsContextMenuBody, DrawingsContextMenuBodyHeaderType,
} from 'common/components/drawings/drawings-canvas-menus/drawings-context-menu/drawings-context-menu-body';
import { RenderIf } from 'common/components/render-if';
import { ConstantFunctions } from 'common/constants/functions';
import { MeasureUtil } from 'common/utils/measure-util';
import { PuponContextData } from '../formula-toolbar';
import { MeasureTypePupon } from './measure-type-pupon';
import { Styled } from './styled';

interface Props {
  onColorChange: (color: string, isGroup: boolean) => void;
  puponContextData: PuponContextData;
  closePuponContext: (e: React.MouseEvent<HTMLDivElement>) => void;
  replaceType: (type: MoveToCellOptionType) => void;
}

const MeasureList: React.FC<{
  measures: Record<MoveToCellOptionType, string>,
  replaceType: (type: MoveToCellOptionType) => void,
  currentType: MoveToCellOptionType,
}> = ({ measures, replaceType, currentType }) => {
  const canShowMeasure3d = useAbility(Operation.Read, Subject.Takeoff2dMeasurement3d);
  const measureList = React.useMemo(() => Object.entries(measures)
    .filter(([type]) => canShowMeasure3d || !MeasureUtil.measure3dKey[type]), [measures, canShowMeasure3d]);
  return (<>
    {measureList.map(([type, value]) => {
      return (
        <MeasureTypePupon
          type={type as MoveToCellOptionType}
          key={type}
          value={value}
          isActive={currentType === type}
          onSelect={replaceType}
        />
      );
    })}
  </>);
};

export const DrawingsContextMenu: React.FC<Props> = ({
  puponContextData,
  onColorChange,
  closePuponContext,
  replaceType,
}) => {
  const drawingData = puponContextData.drawingData;
  const isGroup = drawingData.isGroup;
  const handleSetColor = React.useCallback(
    (color: string) => {
      onColorChange(color, !!isGroup);
    },
    [isGroup, onColorChange],
  );
  const showMeasureList = React.useMemo(
    () => drawingData.measures && drawingData.currentType !== MoveToCellOptionType.dynamicTable,
    [drawingData],
  );
  return (
    <DrawingsContextMenuBody
      x={puponContextData.x}
      y={puponContextData.y}
      onClose={closePuponContext}
      selectedColor={drawingData.color}
      setColor={handleSetColor}
      headerType={DrawingsContextMenuBodyHeaderType.ColorPicker}
      isColorPickerBottom={true}
      colorTitle={''}
    >
      <Styled.Container onMouseDown={ConstantFunctions.stopEvent}>
        <Styled.Label>
          <ElementTooltip
            text={drawingData.name}
            position='top'
            disabled={drawingData.name.length < 16}
          >
            <Text fontSize={14} withEllipsis={true}>{drawingData.name}</Text>
          </ElementTooltip>
        </Styled.Label>
        <RenderIf condition={showMeasureList}>
          <MeasureList
            currentType={drawingData.currentType}
            replaceType={replaceType}
            measures={drawingData.measures}
          />
        </RenderIf>
      </Styled.Container>
    </DrawingsContextMenuBody>
  );
};
