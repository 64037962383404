import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { ContextObserverCallbackGroupWithPrev } from '../../drawings-contexts';
import { DrawingsGeometryEntityState } from '../../enums/drawings-geometry-entity-state';
import { DrawingsRenderParams } from '../../interfaces/drawing-render-parameters';
import { DrawingsPaperColorInfo } from '../../interfaces/drawings-canvas-context-props';
import { DrawingsPolygonGeometry } from '../../interfaces/drawings-geometry';
import { DrawingsMeasureRectangle } from '../../interfaces/drawings-measures';
import { DrawingAnnotationUtils } from '../../utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';
import { DrawingsPaperUtils } from '../../utils/drawings-paper-utils';
import {
  DrawingsAddRemovePointResults,
  DrawingsGeometryHighOrderEntity,
} from './base';
import { GeometryEntityClosed } from './base/geometry-entity-closed';
import { DrawingsGeometryEntityAngleArc } from './drawings-geometry-entity-angle-arc';
import { DrawingsGeometryEntityPolygonConfig } from './drawings-geometry-entity-polygon';
import { DrawingsGeometrySelectionArea, DrawingsGeometryEntityLineWithMeasure  } from './utility';

const LAST_POINT_INDEX = 3;

export class DrawingsGeometryEntityRectangle
  extends GeometryEntityClosed<DrawingsGeometryEntityPolygonConfig, DrawingsMeasureRectangle>
  implements DrawingsGeometryHighOrderEntity<DrawingsPolygonGeometry, DrawingsMeasureRectangle> {
  protected _path: paper.Path;

  /* geometry entities */
  private _angleArcs: DrawingsGeometryEntityAngleArc[];
  private _arcsObserverGroup: ContextObserverCallbackGroupWithPrev<DrawingsRenderParams>;

  constructor(config: DrawingsGeometryEntityPolygonConfig) {
    super(config);
    this._config.renderParamsContextObserver.subscribe(this.changeVisualData);
  }

  public getGeometry(): DrawingsPolygonGeometry {
    return this._config.geometry;
  }

  public override changeColor(value: DrawingsPaperColorInfo, colorCode: string): void {
    super.changeColor(value, colorCode);
    if (this._text) {
      this._text.changeColor(value.stroke);
    }
    if (this._lines) {
      this._lines.forEach(x => x.changeColor(value.stroke));
    }
    if (this._angleArcs) {
      this._angleArcs.forEach(x => x.changeColor(value.stroke));
    }
  }

  public canRemovePoints(): boolean {
    return false;
  }

  public updateGeometry(geometry: DrawingsPolygonGeometry): DrawingsMeasureRectangle {
    this._config.geometry = geometry;
    this.render();
    if (this.state === DrawingsGeometryEntityState.Hover || this.state === DrawingsGeometryEntityState.Selected) {
      this._path.strokeWidth = 0;
      this.removeEntities();
      this.renderEntities();
      if (this._lines) {
        const lineProcessing =
          this.state === DrawingsGeometryEntityState.Hover
            ? (x: DrawingsGeometryEntityLineWithMeasure) => x.enableHoverStyle()
            : (x: DrawingsGeometryEntityLineWithMeasure) => x.enableSelectedStyle();
        this._lines.forEach(lineProcessing);
      }
    }
    if (this._text) {
      this._text.changePosition(this._path.position);
    }
    return this.updateLabel(this._config.textRenderParamsObserver.getContext());
  }

  public removePoints(
    _pointIds: string[],
  ): DrawingsAddRemovePointResults<DrawingsPolygonGeometry, DrawingsMeasureRectangle> {
    // todo 10704
    return null;
  }

  public isExistsInRect(rect: paper.Rectangle, selectionArea: DrawingsGeometrySelectionArea): boolean {
    return this._path.isInside(rect)
      || selectionArea.intersects(this._path)
      || DrawingsPaperUtils.isRectInsidePath(rect, this._path);
  }

  public updatePointsInEntity(pointIds: string[]): DrawingsMeasureRectangle {
    const points = this._config.geometry.points.slice();
    for (const pointId of pointIds) {
      const index = this._config.geometry.points.findIndex(x => x === pointId);
      const prevPointId = index === 0 ? points[points.length - 1] : points[index - 1];
      const nextPointId = index === points.length - 1 ? points[0] : points[index + 1];
      this._path.segments[index].point = this._config.getPointInfo(pointId);
      if (index === 0) {
        this._path.lastSegment.point = this._path.segments[index].point;
      }
      if (this._lines) {
        this.updatePointInLine(DrawingAnnotationUtils.getLineKey(prevPointId, pointId), pointId);
        this.updatePointInLine(DrawingAnnotationUtils.getLineKey(nextPointId, pointId), pointId);
      }
    }
    if (this._angleArcs) {
      this._angleArcs.forEach(x => x.destroy());
      this.renderEntities();
    }
    const measures = this.getMeasures();
    this.fixPathOrientation(this._path);
    if (this._text) {
      this._text.changePosition(this._path.position);
      this.updateText(measures, this._config.textRenderParamsObserver.getContext().isImperial);
    }
    return measures;
  }

  public getPath(): paper.Path {
    return this._path;
  }

  public override getMeasures(): DrawingsMeasureRectangle {
    const { scale, metersPerPixel } = this._config.textRenderParamsObserver.getContext();
    return DrawingsCanvasUtils.getRectangleMeasures(this._path, scale, metersPerPixel, this._config.geometry);
  }

  public override destroy(): void {
    super.destroy();
    this._config.renderParamsContextObserver.unsubscribe(this.changeVisualData);
  }

  protected override render(): void {
    this._path.removeSegments();
    this._path.addSegments(this._config.geometry.points.map((x) => new paper.Segment(this._config.getPointInfo(x))));
    this._path.add(this._path.firstSegment);
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    this._path.strokeWidth = this._config.geometry.strokeWidth / zoom;
    this._path.dashArray = DrawingsCanvasUtils.scaleStroke(
      this._config.geometry,
      zoom,
    );
    this._path.addTo(this._config.layer);
    DrawingsPaperUtils.updatePathStyles(this._path);
  }

  @autobind
  protected override removeEntities(): void {
    super.removeEntities();
    if (this._angleArcs) {
      this._arcsObserverGroup.destroy();
      this._arcsObserverGroup = undefined;
      this._pointsGroup.removeChildren();
      this._angleArcs = undefined;
    }
  }

  protected applyState(state: DrawingsGeometryEntityState): void {
    if (state === DrawingsGeometryEntityState.Hover || state === DrawingsGeometryEntityState.Selected) {
      this._path.strokeWidth = 0;
      if (!this._angleArcs) {
        this.renderEntities();
      }
      if (state === DrawingsGeometryEntityState.Selected) {
        this.selectToVisible();
        this._eventsApplier.enableDrag(true);
        this._lines.forEach(x => x.disableHoverStyle());
      } else {
        this._eventsApplier.enableDrag(false);
        this._lines.forEach(x => x.enableHoverStyle());
      }
    } else {
      this.removeSelection();
      this._eventsApplier.enableDrag(false);
      this.removeEntities();
    }
  }

  @autobind
  protected renderEntities(): void {
    this._angleArcs = [];
    this._arcsObserverGroup = new ContextObserverCallbackGroupWithPrev<DrawingsRenderParams>(
      this._renderParamsObserverGroup,
    );
    this.initEntityGroups();
    for (let i = 0; i < this._config.geometry.points.length; i++) {
      const segment = this._path.segments[i];
      const nextPoint = segment.next.point;
      const prevPoint = i === 0 ? this._path.segments[3].point : segment.previous.point;
      this._angleArcs.push(
        new DrawingsGeometryEntityAngleArc({
          id: 'rectangle-arc',
          layer: this._pointsGroup,
          points: [prevPoint, segment.point, nextPoint],
          color: this._path.strokeColor,
          mustExistInPath: true,
          clampPath: this._path,
          specificViewForRightAngle: true,
          radius: DrawingsCanvasConstants.angleCircleRadius / 2,
          renderParamsContextObserver: this._arcsObserverGroup,
          textRenderParamsObserver: this._textObserverGroup,
          cursorHelper: this._config.cursorHelper,
        }),
      );
      const startPoint = this._config.geometry.points[i];
      const endPoint = i === LAST_POINT_INDEX
        ? this._config.geometry.points[0]
        : this._config.geometry.points[i + 1];
      const lineKey = DrawingAnnotationUtils.getLineKey(startPoint, endPoint);
      if (!this._lines.has(lineKey)) {
        const lineMeasure = new DrawingsGeometryEntityLineWithMeasure(
          {
            id: lineKey,
            layer: this._linesGroup,
            textLayer: this._segmentTextGroup,
            color: this._path.strokeColor,
            points: [startPoint, endPoint],
            mustBeOutOfPath: true,
            clampPath: this._path,
            onSelect: this.onLineClick,
            onMouseEnter: this.onSegmentMouseEnter,
            onMouseLeave: this.onSegmentMouseLeave,
            onSelectSegmentMeasure: this._config.onSelectSegmentMeasure,
            dragEventsHelper: this._config.dragEventsHelper,
            renderParamsContextObserver: this._renderParamsObserverGroup,
            textRenderParamsObserver: this._textObserverGroup,
            canMove: this.canEditSegment(),
            strokeStyle: this._config.geometry,
            cursorHelper: this._config.cursorHelper,
            pointsManager: this._config,
            segmentDragHelper: this._config.segmentDragHelper,
          });
        this._lines.set(
          lineKey,
          lineMeasure,
        );
      }
    }
  }

  private updateText(measures: DrawingsMeasureRectangle, isImperial: boolean): void {
    const { area, perimeter } = measures;
    const areaText = UnitUtil.measureToString2d(Math.abs(area), UnitTypes.M2, isImperial);
    const perimeterText = UnitUtil.lengthToString(Math.abs(perimeter), UnitTypes.M, isImperial);
    const text = `${areaText}\n${perimeterText}`;
    this._text.updateText(text);
  }

  @autobind
  private changeVisualData({ zoom }: DrawingsRenderParams): void {
    if (this.state === DrawingsGeometryEntityState.Hover) {
      return;
    }
    this._path.strokeWidth = this._config.geometry.strokeWidth / zoom;
    this._path.dashArray = DrawingsCanvasUtils.scaleStroke(
      this._config.geometry,
      zoom,
    );
    if (this.state === DrawingsGeometryEntityState.Selected) {
      this.selectToVisible();
    }
  }

  @autobind
  private onLineClick(_id: string, e: PaperMouseEvent): void {
    this._config.onSelect(this.id, e);
  }

  private selectToVisible(): void {
    if (this._lines) {
      this._lines.forEach(x => {
        x.enableSelectedStyle();
      });
    }
    this._path.strokeWidth = 0;
  }

  private removeSelection(): void {
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    this._path.strokeWidth = this._config.geometry.strokeWidth / zoom;
    this._path.dashArray = DrawingsCanvasUtils.scaleStroke(
      this._config.geometry,
      zoom,
    );
    if (this._lines) {
      this._lines.forEach(x => x.disableSelectedStyle());
    }
  }

  private fixPathOrientation(path: paper.Path): void {
    if (!path.clockwise) {
      path.removeSegment(path.segments.length - 1);
      path.reverse();
      this._config.geometry.points = this._config.geometry.points.reverse();
      path.add(path.firstSegment);
    }
  }
}
