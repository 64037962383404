import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { ConstantFunctions } from 'common/constants/functions';
import { KreoDialogActions } from 'common/UIKit';
import { SelectPagesButton } from './select-pages-button';
import { SelectPagesDialog } from './select-pages-dialog';


interface OwnProps {
  selectedPages: string[];
  currentPageId: string;
  onSelectionChanged: (id: string[]) => void;
}

interface DispatchProps {
  onClose: () => void;
  onOpen: () => void;
}

interface Props extends OwnProps, DispatchProps {

}

export class SelectPagesButtonComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <>
        <SelectPagesButton
          onOpenClick={this.props.onOpen}
          selectedPagesCount={this.props.selectedPages.length}
          onRemoveSelectionClick={this.removeSelection}
        />
        <SelectPagesDialog
          currentPage={this.props.currentPageId}
          selectedPages={this.props.selectedPages}
          onSelectionChanged={this.props.onSelectionChanged}
          onClose={this.props.onClose}
        />
      </>
    );
  }

  @autobind
  private removeSelection(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onSelectionChanged([this.props.currentPageId]);
  }
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onClose: () => dispatch(KreoDialogActions.closeDialog(DrawingDialogs.SELECT_SCALE_PAGES)),
    onOpen: () => dispatch(KreoDialogActions.openDialog(DrawingDialogs.SELECT_SCALE_PAGES)),
  };
}


export const SelectPages = connect(null, mapDispatchToProps)(SelectPagesButtonComponent);
