import React from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'components/theme-provider';
import { store } from '../../../../../store';
import { IconItems } from '../styles-icon-items';

export const ItemIcon = (): JSX.Element => (
  <Provider store={store}>
    <ThemeProvider>
      <IconItems />
    </ThemeProvider>
  </Provider>
);
