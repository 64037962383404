import styled from 'styled-components';

import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';


const Container = styled.div`
  width: 240px;

  :not(:first-child) {
    border-top: 1px solid ${p => p.theme.color.background};
  }

  ${UpgradeWrapperStyled.StarsContainer} {
    right: 5px;
  }
`;


export const Styled = {
  Container,
};
