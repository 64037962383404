import { UuidUtil } from 'common/utils/uuid-utils';
import { Property } from 'unit-2d-database/interfaces';

export function* convertPropertiesIterator(
  ids: Iterable<string>, properties: Property[]): IterableIterator<[Property, Property]> {
  const idsSet = new Set(ids);
  for (const property of properties) {
    if (idsSet.has(property.id)) {
      yield [{ ...property, id: UuidUtil.generateUuid() }, property];
    }
  }
}
