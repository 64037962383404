import 'ag-grid-enterprise';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import uuid from 'uuid';

import './quantity-take-off-custom-filter-tab-header.scss';

import { CustomElementFilterCollectionOperation } from 'common/enums/custom-element-filter-collection-operation';
import {
  CustomElementFilterForm,
} from 'common/interfaces/custom-element-filter-builder';
import { KreoButton, KreoIconCreateNew } from 'common/UIKit';
import { CustomFiltersActions } from '../../actions/creators/custom-filters-actions';


interface DispatchProps {
  createCustomElementFilter: (forms: CustomElementFilterForm[]) => void;
}

type Props = DispatchProps;

class QtoCustomFilterTabHeaderComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <div className='quantity-take-off-custom-filter-tab-header'>
        <KreoButton
          className='quantity-take-off-custom-filter-tab-header__create-button'
          size='small'
          mode='action'
          rounded={true}
          icon={<KreoIconCreateNew />}
          caption='Create New Filter'
          onClick={this.createNewCustomElementFilter}
        />
      </div>
    );
  }

  @autobind
  private createNewCustomElementFilter(): void {
    this.props.createCustomElementFilter([{
      id: uuid.v4(),
      name: 'New Filter',
      rootNode: {
        doubleBasedFilters: [],
        innerNodes: [],
        referencedFilters: [],
        stringBasedFilters: [],
        operation: CustomElementFilterCollectionOperation.All,
        inverse: false,
        orderIndex: 0,
      },
    }]);
  }
}


const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    createCustomElementFilter: (forms: CustomElementFilterForm[]) =>
      dispatch(CustomFiltersActions.createCustomFilters(forms)),
  };
};

const reduxConnector = connect(null, mapDispatchToProps);

export const QtoCustomFilterTabHeader = reduxConnector(QtoCustomFilterTabHeaderComponent);
