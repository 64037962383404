import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { CancelSubscriptionButton } from '../cancel-subscription-button';
import { PauseSubscriptionButton } from '../pause-subscription-button';
import { Styled } from '../styled';

interface Props {
  showCancelSubscriptionButton?: boolean;
  onChangePlanClick?: () => void;
  onPauseSubscriptionClick?: () => void;
  handleExtendTrial?: () => void;
}

export const SubscriptionControllButtons: React.FC<Props> = ({
  showCancelSubscriptionButton,
  onChangePlanClick,
  onPauseSubscriptionClick,
  handleExtendTrial,
}) => {
  return (
    <>
      {onPauseSubscriptionClick && (
        <PauseSubscriptionButton onClick={onPauseSubscriptionClick} />
      )}
      {showCancelSubscriptionButton && (
        <CancelSubscriptionButton
          handleExtendTrial={handleExtendTrial}
        />
      )}
      {onChangePlanClick && (
        <Styled.LinkButton color="additionalFont" onClick={onChangePlanClick}>
          <Icons.LinkArrow />
          Choose another plan
        </Styled.LinkButton>
      )}
    </>
  );
};
