import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { DrawingsAnnotationLegendActions } from 'common/components/drawings';
import { FormSingleSelectField } from 'common/components/form-fields';
import { RenderIf } from 'common/components/render-if';
import { MeasurePanel } from 'unit-2d-database/components/side-panel/components/measure-panel';
import { Styled } from '../styled';
import { SelectEvents, selectAssembliesAction, selectItemsAction } from './constants';
import { Action, WithAfterEffectsProps, withAfterEffects } from './helpers';
import { useTotalAssign } from './hooks';
import { SelectPanelWrapper } from './select-panel-wrapper';
import { useOpenClose } from './use-open-close';

interface Props extends WithAfterEffectsProps {
  isGroup: boolean;
}


const AssignFormComponent: React.FC<Props> = ({
  assemblyAfterEffects,
  itemAfterEffects,
  isAssignReady,
  isGroup,
}) => {
  const [isOpenSelectTable] = useState(false);
  const action = useMemo(() => new Action<SelectEvents>(), []);
  const handleSelectAssemblies = useCallback((ids: string[]) => {
    action.dispatch(selectAssembliesAction, ids);
  }, []);
  const handleSelectItems = useCallback((ids: string[]) => {
    action.dispatch(selectItemsAction, ids);
  }, []);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const [top, isOpen, onOpenSelectPanel] = useOpenClose(buttonContainerRef);
  const dispatch = useDispatch();

  const canManage = useAbility(Operation.Manage, Subject.Takeoff2dPiaAssignment);
  const canRead = useAbility(Operation.Read, Subject.Takeoff2dPiaAssignment);
  const { assignNameList, getSelectedIds } = useTotalAssign();
  const handleChangAppliedEntity = useCallback((index: number) => {
    const name = assignNameList[index];
    const { groupIds, elementIds } = getSelectedIds(name);
    dispatch(DrawingsAnnotationLegendActions.updateSelection({ instanceIds: elementIds, groupIds }));
  }, [getSelectedIds, dispatch, assignNameList]);
  const selectInput = useMemo(() => ({
    elements: assignNameList,
    activeElementIndex: 0,
    onClick: handleChangAppliedEntity,
    createNewElement: false,
    createEmptyElement: false,
    value: 'Mixed',
  }), [assignNameList, handleChangAppliedEntity]);

  if (canRead && !canManage) {
    return null;
  }

  const hasSomeAssign = assignNameList.length > 1;

  return (
    <>
      <Styled.MeasurePanelContainer>
        <RenderIf condition={hasSomeAssign}>
          <Styled.SelectAssign isNeedOffset={isGroup}>
            <FormSingleSelectField
              label='Applied items'
              input={selectInput}
            />
          </Styled.SelectAssign>
        </RenderIf>
        <RenderIf condition={!hasSomeAssign}>
          <Styled.ButtonContainer ref={buttonContainerRef}>
            <SelectPanelWrapper
              top={top}
              isOpen={isOpen}
              handleSelectAssemblies={handleSelectAssemblies}
              handleSelectItems={handleSelectItems}
            />
          </Styled.ButtonContainer>
          <MeasurePanel
            action={action}
            assembliesAfterEffects={assemblyAfterEffects}
            itemsAfterEffects={itemAfterEffects}
            openSelectPanel={onOpenSelectPanel}
            isAssignReady={isAssignReady}
            isSelectPanelOpen={isOpenSelectTable}
          />
        </RenderIf>
      </Styled.MeasurePanelContainer>
    </>);
};

export const AssignForm = withAfterEffects(AssignFormComponent);
