import { AppRoute as R } from 'common/routing/app-route';
import { ParametrizedAppRoute as PR } from 'common/routing/parametrized-app-route';
import { ValidationStepName } from '../../units/projects/enums/validation-step-name';
import * as Activity from '../common/activity/urls-fabric';
import * as Database from '../common/database/urls-fabric';
import * as People from '../common/people/urls-fabric';
import * as Subscription from '../common/subscription-routes-wrap/urls-fabric';
import {
  AssignActivityRouteParams as AssignActivityRP,
  BidPricingRouteParams as BidPricingRP,
  DatabaseAssignActivityRouteParams as DbAssignActivityRP,
  PlanProjectRouteParams as ProjectRP,
  ScenarioRouteParams as ScenarioRP,
  ValidationRouteParams as ValidationRP,
} from './route-params';


/* eslint-disable max-len, space-in-parens */
const index =                          new R(                     '/plan');
const listing =                        new R(                     `${index.path}/projects`);

const databaseUrls = Database.UrlsFabric(index.path);

const projectIndex =                   new PR<ProjectRP>(         `${index.path}/project/:projectId`);
const viewer =                         new PR<ProjectRP>(         `${projectIndex.path}/collaboration`);
const validation =                     new PR<ProjectRP>(         `${projectIndex.path}/validation`);
const validationStep =                 new PR<ValidationRP>(      `${validation.path}/:step(${ValidationStepName.ModelCheck}|${ValidationStepName.Classification}|${ValidationStepName.ActivityAssignment}|${ValidationStepName.Measurements}|${ValidationStepName.Results})?`);
const classificationEdit =             new PR<ProjectRP>(         `${validation.path}/${ValidationStepName.Classification}/edit`);
const classificationView =             new PR<ProjectRP>(         `${validation.path}/${ValidationStepName.Classification}/view`);
const activityAssignment =             new PR<ProjectRP>(         `${validation.path}/${ValidationStepName.ActivityAssignment}`);
const activityAssignmentEdit =         new PR<ProjectRP>(         `${activityAssignment.path}/edit`);
const activityAssignmentView =         new PR<ProjectRP>(         `${activityAssignment.path}/view`);
const activityAssignmentDb =           new PR<AssignActivityRP>(  `${activityAssignmentEdit.path}/:endElementId`);
const activityAssignmentDbActivities = new PR<DbAssignActivityRP>(`${activityAssignmentDb.path}${databaseUrls.activities.path}`);
const activityAssignmentDbResources =  new PR<DbAssignActivityRP>(`${activityAssignmentDb.path}${databaseUrls.resources.path}`);
const measurementsEdit =               new PR<ProjectRP>(         `${validation.path}/${ValidationStepName.Measurements}/edit`);
const measurementsView =               new PR<ProjectRP>(         `${validation.path}/${ValidationStepName.Measurements}/view`);
const dashboard =                      new PR<ProjectRP>(         `${projectIndex.path}/dashboard`);
const costs =                          new PR<ProjectRP>(         `${projectIndex.path}/costs`);
const schedule =                       new PR<ProjectRP>(         `${projectIndex.path}/schedule`);
const fourD =                          new PR<ProjectRP>(         `${projectIndex.path}/4d`);
const quantityTakeOff =                new PR<ProjectRP>(         `${projectIndex.path}/quantity-take-off`);
const scenarios =                      new PR<ProjectRP>(         `${projectIndex.path}/scenarios`);
const scenario =                       new PR<ScenarioRP>(        `${projectIndex.path}/scenario/:scenarioId`);
const scenarioResourceLimitations =    new PR<ScenarioRP>(        `${scenario.path}/resource-limitations`);
const scenarioWorkpackages =           new PR<ScenarioRP>(        `${scenario.path}/workpackages`);
const scenarioSequence =               new PR<ScenarioRP>(        `${scenario.path}/sequence`);
const scenarioIndirectCosts =          new PR<ScenarioRP>(        `${scenario.path}/indirect`);
const bidPricing =                     new PR<ProjectRP>(         `${projectIndex.path}/bid-pricing`);
const bidPricingEditCost =             new PR<BidPricingRP>(      `${bidPricing.path}/:workPackageId/editcost`);
const bidPricingCostInfo =             new PR<BidPricingRP>(      `${bidPricing.path}/:workPackageId/costinfo`);
const modelCheck =                     new PR<ProjectRP>(         `${projectIndex.path}/model-check`);
const modelCheckView =                 new PR<ProjectRP>(         `${modelCheck.path}/view`);
const modelManagement =                new PR<ProjectRP>(         `${projectIndex.path}/model-management`);
/* eslint-enable */

export const Urls = {
  people: People.UrlsFabric(index.path),
  activity: Activity.UrlsFabric(index.path),
  billing: Subscription.UrlsFabric(index.path),
  database: databaseUrls,
  index,
  listing,
  project: {
    index: projectIndex,
    viewer,
    validation: {
      index: validation,
      step: validationStep,
      editClassification: classificationEdit,
      viewClassification: classificationView,
      activityAssignment,
      editActivityAssignment: activityAssignmentEdit,
      viewActivityAssignment: activityAssignmentView,
      activityAssignmentDb,
      activityAssignmentDbActivities,
      activityAssignmentDbResources,
      editMeasurements: measurementsEdit,
      viewMeasurements: measurementsView,
    },
    dashboard,
    costs,
    schedule,
    fourD,
    quantityTakeOff,
    modelManagement,
    modelCheck: {
      index: modelCheck,
      view: modelCheckView,
    },
    scenario: {
      index: scenario,
      listing: scenarios,
      resourceLimitations: scenarioResourceLimitations,
      workpackages: scenarioWorkpackages,
      sequence: scenarioSequence,
      indirectCosts: scenarioIndirectCosts,
    },
    bidPricing: {
      index: bidPricing,
      editCost: bidPricingEditCost,
      costInfo: bidPricingCostInfo,
    },
  },
};
