import React from 'react';

import { RenderIf } from 'common/components/render-if';
import { arrayUtils } from 'common/utils/array-utils';
import { VisibilityFilterType } from 'unit-2d-database/interfaces';
import { AssemblyForm } from '../assembly-panel/item-form-updater';
import { InheritType } from '../inherit-label';
import { InheritLabel } from '../inherit-label/inherit-label';
import { AssemblyGroup } from './assembly-form';

export interface AssemblyGroupData {
  id: string;
  originId: string;
  name: string;
  assemblyGroups: AssemblyForm;
  onDelete: () => void;
}

interface Props {
  groups: Record<string, AssemblyGroupData>;
  collapsedGroups: Set<string>;
  visibilityMode: VisibilityFilterType;
  toggleGroup: (id: string, isOpen: boolean) => void;
  switchPanel?: (itemId: string) => void;
  onClickSelect?: (itemId: string, baseId: string) => void;
  parentId?: string;
  hideDeleteButton?: boolean;
  editAssembly?: (assemblyName: string) => void;
  disabled?: boolean;
  withInhered: boolean;
}

export class AssemblyGroupForm extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const {
      groups,
      collapsedGroups,
      visibilityMode,
      toggleGroup,
      parentId,
      hideDeleteButton,
      editAssembly,
      disabled,
      withInhered,
    } = this.props;
    const sortGroups = Object.entries(groups).sort((ga, gb) => {
      return arrayUtils.localCompareWithNumber(ga[1].name, gb[1].name);
    });
    return (
      <>
        {sortGroups.map(([id, group]) => {
          return (
            <>
              <RenderIf condition={withInhered}>
                <InheritLabel
                  type={InheritType.Assembly}
                  entityName={group.name}
                />
              </RenderIf >
              <AssemblyGroup
                key={id}
                id={id}
                name={group.name}
                groups={group.assemblyGroups}
                isOpen={!collapsedGroups.has(this.getCollapsedKey(id))}
                visibilityMode={visibilityMode}
                collapsedGroups={collapsedGroups}
                toggleGroup={toggleGroup}
                onDeleteAssembly={group.onDelete}
                parentId={parentId}
                hideDeleteButton={hideDeleteButton}
                editAssembly={editAssembly}
                disabled={disabled}
              />
            </>
          );
        })
        }
      </>
    );
  }
  private getCollapsedKey(id: string): string {
    return `${this.props.parentId}_${id}`;
  }
}
