import * as React from 'react';
import { SegmentContext, SegmentContextProps } from './segment-context-provider';

export function withSegmentContext<P extends SegmentContextProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof SegmentContextProps>>> {
  return class AbilityAwareComponent
    extends React.Component<Pick<P, Exclude<keyof P, keyof SegmentContextProps>>> {
    public render(): React.ReactNode {
      return (
        <SegmentContext.Consumer>
          {
            segmentProps => <Component {...this.props as P} {...segmentProps} />
          }
        </SegmentContext.Consumer>
      );
    }
  };
}
