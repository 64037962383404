import autobind from 'autobind-decorator';
import * as React from 'react';

import { PreparedSearchQuery } from 'common/utils/string-utils';
import { DefaultOptions } from '../filter-select/default-options';
import { FilterSelect } from '../filter-select/filter-select';
import { FilterOption, FilterValue } from '../filter-select/interfaces';
import { DropDownPositioning } from '../multi-level-drop-down-menu/multi-level-drop-down';
import { FilterConfig, SelectedFilterValue } from './interfaces';
import { TreeFilterSelectTitle } from './tree-filter-select-title';

import './tree-filter.scss';

interface Props {
  name: string;
  filterConfig: FilterConfig;
  value: FilterValue[];
  onChangeSelectedValue: (value: SelectedFilterValue) => void;
  optionContentRenderer?: (option: FilterOption, query: PreparedSearchQuery, isSelected: boolean) => React.ReactNode;
}

interface State {
  searchQuery: string;
}

export class TreeFilter extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      searchQuery: null,
    };
  }

  public render(): JSX.Element {
    const { value, name, filterConfig } = this.props;
    const options = [DefaultOptions.getSelectAllOption(name)];
    options.push(...filterConfig.options
      .filter(
        option => {
          if (option.name && option.value && this.state.searchQuery) {
            const searchQuery = this.state.searchQuery.toLowerCase();
            return option.name.toLowerCase().includes(searchQuery)
              || option.value.toLocaleLowerCase().includes(searchQuery);
          }

          return true;
        },
      )
      .map(this.mapToFilterOption));

    const selectedAll = value.length === 0;
    const selectedValue = !selectedAll
      ? value
      : [
        {
          value: null,
          name: DefaultOptions.getSelectAllOption(name).name.toString(),
        },
      ];


    return (
      <FilterSelect
        value={selectedValue}
        entityName={name}
        options={options}
        onChangeSelectedValue={this.onChangeSelectedValue}
        selectTitle={this.selectTitle()}
        className='tree-filter-select-title'
        containerClassName='tree-filter-select-container'
        optionContentRenderer={this.props.optionContentRenderer}
        onSearchQueryChange={this.onSearchQueryChange}
        dropDownPositioning={DropDownPositioning.horizontal}
        onOpen={this.onOpen}
      />
    );
  }

  @autobind
  private onOpen(): void {
    this.setState({ searchQuery: null });
  }

  @autobind
  private onSearchQueryChange(searchQuery: string): void {
    this.setState({ searchQuery });
  }

  @autobind
  private selectTitle(): JSX.Element {
    return (<TreeFilterSelectTitle name={this.props.name} value={this.props.value} onDelete={this.onDelete} />);
  }

  @autobind
  private onDelete(value: string): void {
    this.onChangeSelectedValue(this.props.value.filter(x => x.value !== value));
  }

  @autobind
  private mapToFilterOption(option: FilterValue): FilterOption {
    return { isSelectable: true, ...option, children: [] };
  }

  @autobind
  private onChangeSelectedValue(value: FilterValue[]): void {
    let newValue = value;
    if (value.includes(null)) {
      if (this.props.value.length) {
        newValue = [];
      } else {
        newValue = value.filter(x => x !== null);
      }
    }
    this.props.onChangeSelectedValue({ key: this.props.filterConfig.key, value: newValue });
  }
}
