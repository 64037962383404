import autobind from 'autobind-decorator';
import * as React from 'react';

import './company-subscriptions-control.scss';

import { SubscriptionType } from 'common/constants/subscription';
import { AdminCompanyVm } from '../../interfaces/admin-company-vm';
import { CompanySubscriptionControl } from './company-subscription-control';


interface Props {
  company: AdminCompanyVm;
  createEmptySubscription: (companyId: number, subscriptionType: SubscriptionType) => void;
  onEmployeesAttach?: (companyId: number, subscriptionType: SubscriptionType) => void;
  onEmployeeDetachClick?: (companyId: number, employeeGuid: string, subscriptionType: SubscriptionType) => void;
  onEmployeeClick?: (email: string, subscriptionType: SubscriptionType) => void;
}


export class CompanySubscriptionsControl extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <div>
        {this.renderCompanySubscriptionControl(SubscriptionType.KreoPlan)}
        {this.renderCompanySubscriptionControl(SubscriptionType.Takeoff3d)}
        {this.renderCompanySubscriptionControl(SubscriptionType.Takeoff2d)}
        {this.renderCompanySubscriptionControl(SubscriptionType.CostEstimate)}
      </div>
    );
  }

  @autobind
  private renderCompanySubscriptionControl(subscriptionType: SubscriptionType): JSX.Element {
    const { company, createEmptySubscription, onEmployeesAttach, onEmployeeDetachClick, onEmployeeClick } = this.props;

    return (
      <CompanySubscriptionControl
        key={subscriptionType}
        subscriptionType={subscriptionType}
        company={company}
        createEmptySubscription={createEmptySubscription}
        onEmployeeClick={onEmployeeClick}
        onEmployeeDetachClick={onEmployeeDetachClick}
        onEmployeesAttach={onEmployeesAttach}
      />
    );
  }
}
