/* eslint-disable indent */
import * as monolite from 'monolite';

import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import { ActivityCategory } from '../../../units/databases/interfaces/data';
import {
  ClassificationBreadcrumbClickPayload,
  ClassificationCopySuccessPayload,
  ClassificationElementTypeInfo,
  ClassificationLoadBimInfoSuccessPayload,
  ClassificationPasteCompletePayload,
  ClassificationSelectItemPayload,
} from '../actions/payloads/classification';
import { ValidationStepStatisticDataPayload } from '../actions/payloads/validation';
import { ClassificationActionTypes } from '../actions/types/classification';
import {
  CLASSIFICATION_INITIAL_STATE,
  CLASSIFICATION_MODEL_BROWSER_ISOLATION_STATUS_DEFAULT,
} from '../constants/classification-intitial-state';
import { ClassificationAssignmentStage } from '../enums/classification-assignment-stage';
import { ClassificationMultiselectOperation } from '../enums/classification-multiselect-operation';
import { RevitTreeLevel } from '../enums/revit-tree-level';
import { ClassificationReduxState } from '../interfaces/classification/classification-redux-state';
import { ClassificationSource } from '../interfaces/classification/classification-source';
import {
  ClassificationComparisonPatternGroup,
} from '../interfaces/classification/comparison-pattern-group';
import { CurrentElementClassification } from '../interfaces/classification/current-element-classification';
import { ClassificationOntologyGraphResponse } from '../interfaces/classification/ontology-data';
import { UniClassCategories } from '../interfaces/classification/uniclass-categories';
import { RevitTreeListNode } from '../interfaces/revit-tree-list-node';
import { RevitTreePathInfo } from '../interfaces/revit-tree-path-info';
import { ClassificationUtils } from '../utils/classification-utils';
import { mapStatisticRequest } from '../utils/step-statistic-request-mapper';

function startCopyPaste(state: ClassificationReduxState): ClassificationReduxState {
  return new MonoliteHelper(state).set(_ => _.copyPastRequestState, RequestStatus.Loading).get();
}

function setCustomSystem(
  state: ClassificationReduxState,
  system: ActivityCategory,
  ): MonoliteHelper<ClassificationReduxState> {
  const helper = new MonoliteHelper(state);
  if (state.tempUniclass) {
    helper.set(_ => _.tempUniclass.system, system);
  } else {
    helper.set(_ => _.tempUniclass, { system, product: null });
  }
  return helper
      .set(_ => _.filterValue, '')
      .set(_ => _.classificationSelectedIndex, -1);
}

function setCustomProduct(
  state: ClassificationReduxState,
  product: ActivityCategory,
  ): MonoliteHelper<ClassificationReduxState> {
  const helper = new MonoliteHelper(state);
  if (state.tempUniclass) {
    helper.set(_ => _.tempUniclass.product, product);
  } else {
    helper.set(_ => _.tempUniclass, { product, system: null });
  }
  return helper
    .set(_ => _.filterValue, '')
    .set(_ => _.classificationSelectedIndex, -1);
}

export const classificationReducerMethods: ReducerMethods<ClassificationReduxState> = {
  [ClassificationActionTypes.LOAD_BIM_MODEL_INFO_SUCCESS]: (
    state,
    payload: ClassificationLoadBimInfoSuccessPayload,
  ) => {
    const { all, error } = ClassificationUtils.calculateErrorElementTypes(payload.treeData);
    return new MonoliteHelper(state)
      .set(_ => _.bimElements, payload.elements)
      .set(_ => _.modelTree, payload.treeData)
      .set(_ => _.layerInfo, payload.layers)
      .set(_ => _.loadedStatistic, true)
      .set(_ => _.tabs, payload.tabs)
      .set(_ => _.errorIds, payload.badElementIds)
      .set(_ => _.errorCategoriesCount, error)
      .set(_ => _.allElementTypesCount, all)
      .get();
  },
  [ClassificationActionTypes.LOAD_DATA]: state =>
    new MonoliteHelper(state).set(_ => _.loadedStatistic, false).get(),
  [ClassificationActionTypes.SET_CURRENT_TAB]: (state, payload) => {
    if (state.modelBrowserFilters.tab === payload) {
      return state;
    }
    const helper = new MonoliteHelper(state);
    let modelTree: RevitTreeListNode[];
    if (state.modelBrowserFilters.tab === -1) {
      modelTree = ClassificationUtils.changeTreeSelectStatus(state.modelTree, false).tree;
      helper.set(_ => _.modelTree, modelTree);
    } else {
      modelTree = state.modelTree;
    }
    if (
      payload !== -1
      || state.modelBrowserFilters.unclassifiedFilterEnabled
      || state.modelBrowserFilters.searchQuery
    ) {
      const filteredTree = ClassificationUtils.filterTreeNodes(
        modelTree,
        payload,
        state.modelBrowserFilters.unclassifiedFilterEnabled,
        state.modelBrowserFilters.searchQuery.toLowerCase(),
      );
      const { all } = ClassificationUtils.calculateErrorElementTypes(filteredTree);
      helper
        .set(_ => _.modelBrowserFiltered, filteredTree)
        .set(_ => _.filteredElementTypesCount, all);
    } else {
      helper.set(_ => _.modelBrowserFiltered, []);
    }
    return ClassificationUtils.changeFilter(helper)
      .set(_ => _.selectedPath, null)
      .set(_ => _.modelBrowserFilters.tab, payload)
      .get();
  },
  [ClassificationActionTypes.ON_HEADER_BACK_CLICK]: state => {
    const helper = new MonoliteHelper(state)
      .set(_ => _.isUniclass, false)
      .set(_ => _.elementClassification, [])
      .set(_ => _.classificationTarget, null)
      .set(_ => _.elementOntologyGraph, []);
    const lastBreadCrumb = ClassificationUtils.getLastBreadCrumb(state.modelBrowserFilters);
    if (lastBreadCrumb.multiple) {
      const groups = state.currentSelectedElementTypesGroups;
      let selectedEngineIds;
      if (state.selectedClassificationGroup === -1) {
        selectedEngineIds = new Array<number>();
        for (const group of groups) {
          arrayUtils.extendArray(selectedEngineIds, group.classificationTarget.ids);
        }
      } else {
        selectedEngineIds = groups[state.selectedClassificationGroup].classificationTarget.ids;
      }
      if (groups.length === 1) {
        helper.set(_ => _.currentSelectedElementTypesGroups, []);
      }
      return helper
        .set(_ => _.selectedIds, selectedEngineIds)
        .set(_ => _.modelBrowserFilters, state.cachedIsolationStatus)
        .get();
    }
    let firstElementIndex: number;
    switch (lastBreadCrumb.type) {
      case RevitTreeLevel.ElementType:
        firstElementIndex = state.classificationTarget.engineIds[0];
        break;
      case RevitTreeLevel.Family:
        firstElementIndex = state.modelTree[lastBreadCrumb.family].engineIds[0];
        break;
      case RevitTreeLevel.Category:
        firstElementIndex = state.modelTree[lastBreadCrumb.category].engineIds[0];
        break;
      default:
        firstElementIndex = null;
    }
    const isolatedTree =
      state.modelBrowserFilters.tab !== -1 ||
      state.modelBrowserFilters.unclassifiedFilterEnabled ||
      state.modelBrowserFilters.searchQuery;
    const currentTree = isolatedTree
      ? ClassificationUtils.filterTreeNodes(
          state.modelTree,
          state.modelBrowserFilters.tab,
          state.modelBrowserFilters.unclassifiedFilterEnabled,
          state.modelBrowserFilters.searchQuery.toLowerCase(),
        )
      : state.modelTree;
    const treeHelper = new MonoliteHelper(currentTree);
    let currentPath: number = null;
    let topPosition = 0;
    if (firstElementIndex !== null) {
      for (let i = 0; i < currentTree.length; i++) {
        if (currentPath === null) {
          topPosition++;
        }
        const {
          engineIds: [start, end],
          expanded,
          level,
          lastChildId,
        } = currentTree[i];
        if (start <= firstElementIndex && end > firstElementIndex) {
          if (level === RevitTreeLevel.ElementType) {
            currentPath = i;
            if (lastBreadCrumb.type === RevitTreeLevel.ElementType) {
              helper
                .set(_ => _.selectedPath, i)
                .set(_ => _.selectedIds, state.bimElements.slice(start, end).map(x => x.id));
            } else {
              helper.set(_ => _.selectedPath, null);
            }
          } else if (!expanded) {
            treeHelper.set(_ => _[i].expanded, true);
          }
        } else if (level !== RevitTreeLevel.ElementType) {
          if (expanded) {
            treeHelper.set(_ => _[i].expanded, false);
          }
          i = lastChildId;
        }
      }
    } else {
      helper.set(_ => _.selectedPath, null);
    }
    const { tree } = ClassificationUtils.changeTreeSelectStatus(treeHelper.get(), false);
    if (isolatedTree) {
      const { all } = ClassificationUtils.calculateErrorElementTypes(tree);
      helper.set(_ => _.modelBrowserFiltered, tree).set(_ => _.filteredElementTypesCount, all);
    } else {
      helper.set(_ => _.modelTree, tree).set(_ => _.modelBrowserFiltered, []);
    }
    return helper
      .set(_ => _.modelBrowserFilters.breadCrumbs, null)
      .set(_ => _.modelBrowserFilters.isolationEnabled, false)
      .set(_ => _.cachedIsolationStatus, CLASSIFICATION_MODEL_BROWSER_ISOLATION_STATUS_DEFAULT)
      .set(_ => _.isSaveButtonEnabled, false)
      .set(_ => _.treeCenterElement, topPosition)
      .set(_ => _.selectedElementTypesCount, 0)
      .set(_ => _.selectedIds, [])
      .get();
  },
  [ClassificationActionTypes.TO_ASSIGNMENT]: (state, payload: number) => {
    const filtered = ClassificationUtils.isTreeFiltered(state.modelBrowserFilters);
    const item = (filtered ? state.modelBrowserFiltered : state.modelTree)[payload];
    const target = ClassificationUtils.createClassificationTarget(item, state, 1);
    const helper = new MonoliteHelper(state)
      .set(_ => _.selectedIds, [])
      .set(_ => _.classificationTarget, target)
      .set(_ => _.selectedPath, null)
      .set(
        _ => _.modelBrowserFilters.breadCrumbs,
        ClassificationUtils.createBreadCrumbs(state.modelTree, state.bimElements[target.engineIds[0]]),
      )
      .set(_ => _.cachedIsolationStatus, CLASSIFICATION_MODEL_BROWSER_ISOLATION_STATUS_DEFAULT)
      .set(_ => _.elementOntologyGraph, [])
      .set(_ => _.elementClassificationLoadingStatus, RequestStatus.Loading)
      .set(_ => _.currentSelectedElementTypesGroups, []);
    return helper.get();
  },
  [ClassificationActionTypes.DROP_STATE]: () => CLASSIFICATION_INITIAL_STATE,
  [ClassificationActionTypes.GET_STATISTIC_REQUEST]: state =>
    monolite.set(state, _ => _.statisticLoaded)(false),
  [ClassificationActionTypes.GET_STATISTIC_SUCCESS]: (
    state,
    payload: ValidationStepStatisticDataPayload,
  ) => {
    state = monolite.set(state, _ => _.statisticLoaded)(true);
    const { statistic, errorIds } = mapStatisticRequest(payload);
    state = monolite.set(state, _ => _.statistic)(statistic);
    return monolite.set(state, _ => _.errorIds)(errorIds);
  },
  [ClassificationActionTypes.COLLAPSE_ALL_TREE]: state => {
    if (state.modelBrowserFilters.breadCrumbs) return state;
    function updateFunc(node: RevitTreeListNode): RevitTreeListNode {
      return node.expanded
        ? new MonoliteHelper(node).set(item => item.expanded, false).get()
        : node;
    }
    const filtered = ClassificationUtils.isTreeFiltered(state.modelBrowserFilters);
    return new MonoliteHelper(state)
      .setMap(_ => filtered ?  _.modelBrowserFiltered : _.modelTree, updateFunc)
      .get();
  },
  [ClassificationActionTypes.ON_BREAD_CRUMB_CLICK]: (state, payload: ClassificationBreadcrumbClickPayload) => {
    const helper = new MonoliteHelper(state);
    const { breadcrumb, isMultiselectAvaible } = payload;
    const { tab, unclassifiedFilterEnabled, searchQuery, breadCrumbs } = state.modelBrowserFilters;
    if (breadcrumb.type !== RevitTreeLevel.ElementType) {
      helper
        .set(_ => _.modelBrowserFilters.isolationEnabled, true)
        .set(_ => _.selectedPath, null)
        .set(_ => _.isUniclass, false)
        .set(_ => _.elementClassification, [])
        .set(_ => _.isSaveButtonEnabled, false)
        .set(_ => _.classificationTarget, null)
        .set(_ => _.elementOntologyGraph, [])
        .set(_ => _.currentSelectedElementTypesGroups, [])
        .set(_ => _.selectedIds, []);
      if (isMultiselectAvaible) {
        helper.set(_ => _.selectedElementTypesCount, state.classificationTarget ? 1 : 0);
      }
    }

    let isolatedTree: RevitTreeListNode[];
    let firstBimElementIndex: number = null;
    const { type: lastBreadCrumbType } = ClassificationUtils.getLastBreadCrumb(state.modelBrowserFilters);
    let newBreadCrumbs: RevitTreePathInfo[];
    switch (breadcrumb.type) {
      case RevitTreeLevel.Category: {
        const item = state.modelTree[breadcrumb.category];
        if (lastBreadCrumbType !== RevitTreeLevel.Category) {
          if (state.classificationTarget) {
            firstBimElementIndex = state.classificationTarget.engineIds[0];
          } else {
            firstBimElementIndex = state.modelBrowserFiltered
              ? state.modelBrowserFiltered[0].engineIds[0]
              : null;
          }
        }
        isolatedTree = ClassificationUtils.isolateModelBrowserNode(
          state.modelTree,
          breadcrumb.category + 1,
          item.lastChildId + 1,
        );
        isolatedTree = tab !== -1 || unclassifiedFilterEnabled || searchQuery
          ? ClassificationUtils.filterTreeNodes(
              isolatedTree,
              tab,
              unclassifiedFilterEnabled,
              searchQuery.toLowerCase(),
              RevitTreeLevel.Family,
            )
          : isolatedTree;
        newBreadCrumbs = [breadcrumb];
        helper
          .set(_ => _.elementOntologyGraph, []);
        break;
      }
      case RevitTreeLevel.Family: {
        const item = state.modelTree[breadcrumb.family];
        if (lastBreadCrumbType !== RevitTreeLevel.Family) {
          firstBimElementIndex = state.classificationTarget.engineIds[0];
        }
        isolatedTree = ClassificationUtils.isolateModelBrowserNode(
          state.modelTree,
          breadcrumb.family + 1,
          item.lastChildId + 1,
        );
        isolatedTree = ClassificationUtils.filterTreeNodes(
          isolatedTree,
          tab,
          unclassifiedFilterEnabled,
          searchQuery.toLowerCase(),
          RevitTreeLevel.ElementType,
        );
        newBreadCrumbs = breadCrumbs.slice(0, 2);
        helper
          .set(_ => _.modelBrowserFiltered, isolatedTree);
        break;
      }
      default:
        return state;
    }
    let parentId = null;
    if (firstBimElementIndex !== null) {
      let selectedPath: number = null;
      let elementPosition: number = 0;
      const treeHelper = new MonoliteHelper(isolatedTree);
      for (let i = 0; i < isolatedTree.length; i++) {
        const {
          engineIds: [start, end],
          lastChildId,
          expanded,
          level,
        } = isolatedTree[i];
        if (selectedPath === null) {
          elementPosition++;
        }

        if (start <= firstBimElementIndex && end > firstBimElementIndex) {
          if (level === lastBreadCrumbType) {
            selectedPath = i;
            if (lastBreadCrumbType === RevitTreeLevel.ElementType) {
              helper
                .set(_ => _.selectedPath, i)
                .set(_ => _.selectedIds, state.classificationTarget.ids);
              if (isMultiselectAvaible) {
                treeHelper.set(_ => _[i].selected, true);
              }
              parentId = isolatedTree[i].parentId;
            } else {
              helper
                .set(_ => _.selectedIds, []);
            }
          } else if (!expanded) {
            treeHelper.set(_ => _[i].expanded, true);
          }
        } else {
          if (level !== lastBreadCrumbType && level !== RevitTreeLevel.ElementType) {
            if (expanded) {
              treeHelper.set(_ => _[i].expanded, false);
            }
            i = lastChildId;
          }
        }
      }
      isolatedTree = parentId !== null
        ? ClassificationUtils.updateParentNodesSelectStatus(treeHelper.get(), newBreadCrumbs, [parentId])
        : treeHelper.get();
      helper.set(_ => _.treeCenterElement, elementPosition);
    }
    return helper
      .set(_ => _.modelBrowserFiltered, isolatedTree)
      .set(_ => _.modelBrowserFilters.breadCrumbs, newBreadCrumbs)
      .set(_ => _.filteredElementTypesCount, ClassificationUtils.calculateErrorElementTypes(isolatedTree).all)
      .get();
  },
  [ClassificationActionTypes.ISOLATE_NODE]: (state, payload: number) => {
    const filtered = ClassificationUtils.isTreeFiltered(state.modelBrowserFilters);
    let tree = filtered ? state.modelBrowserFiltered : state.modelTree;
    tree = ClassificationUtils.changeTreeSelectStatus(tree, false).tree;
    const helper = new MonoliteHelper(state);
    if (!filtered) {
      helper.set(_ => _.modelTree, tree);
    }
    const item = tree[payload];
    const level = item.level + 1;
    const element = state.bimElements[item.engineIds[0]];

    const breadCrumbs =
      state.modelBrowserFilters.breadCrumbs || new Array<RevitTreePathInfo>();
    for (let i = breadCrumbs.length; i < level; i++) {
      let name = '';
      switch (breadCrumbs.length) {
        case RevitTreeLevel.Category:
          name = state.modelTree[element.category].name;
          break;
        case RevitTreeLevel.Family:
          name = state.modelTree[element.family].name;
          break;
        default:
      }
      breadCrumbs.push({
        category: element.category,
        type: breadCrumbs.length,
        name,
        family: breadCrumbs.length > RevitTreeLevel.Category ? element.family : undefined,
      });
    }
    tree = ClassificationUtils.isolateModelBrowserNode(tree, payload + 1, item.lastChildId + 1);
    const { all } = ClassificationUtils.calculateErrorElementTypes(tree);
    return ClassificationUtils.changeFilter(helper)
      .set(_ => _.modelBrowserFilters.breadCrumbs, breadCrumbs)
      .set(_ => _.modelBrowserFiltered, tree)
      .set(_ => _.filteredElementTypesCount, all)
      .set(_ => _.modelBrowserFilters.isolationEnabled, true)
      .get();
  },
  [ClassificationActionTypes.TOGGLE_COLLAPSE_EXPAND_STATUS]: (state, payload: number) => {
    if (ClassificationUtils.isTreeFiltered(state.modelBrowserFilters)) {
      return new MonoliteHelper(state)
        .set(
          _ => _.modelBrowserFiltered[payload].expanded,
          !state.modelBrowserFiltered[payload].expanded,
        )
        .get();
    } else {
      return new MonoliteHelper(state)
        .set(_ => _.modelTree[payload].expanded, !state.modelTree[payload].expanded)
        .get();
    }
  },
  [ClassificationActionTypes.LOAD_ELEMENT_ONTOLOGY_SUCCESS]: (
    state,
    payload: ClassificationOntologyGraphResponse[],
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.elementClassificationLoadingStatus, RequestStatus.Loaded)
      .set(_ => _.elementOntologyGraph, payload)
      .get();
  },
  [ClassificationActionTypes.SAVE_UNICLASS_DATA]: (state, { uniSystem, uniProduct }: UniClassCategories) => {
    return new MonoliteHelper(state)
      .set(_ => _.products, uniProduct)
      .set(_ => _.systems, uniSystem)
      .set(_ => _.productsShortInfo, ClassificationUtils.prepareShortUniClassInfo(uniProduct))
      .set(_ => _.systemsShortInfo, ClassificationUtils.prepareShortUniClassInfo(uniSystem))
      .get();
  },
  [ClassificationActionTypes.TO_OLD_ASSIGNMENT]: (state, payload: number) => {
    const item = (ClassificationUtils.isTreeFiltered(state.modelBrowserFilters)
      ? state.modelBrowserFiltered
      : state.modelTree) [payload];
    const target = ClassificationUtils.createClassificationTarget(item, state, 1);
    return new MonoliteHelper(state)
      .set(_ => _.selectedIds, [])
      .set(_ => _.classificationTarget, target)
      .set(_ => _.selectedPath, null)
      .set(
        _ => _.modelBrowserFilters.breadCrumbs,
        ClassificationUtils.createBreadCrumbs(state.modelTree, state.bimElements[target.engineIds[0]]),
      )
      .set(_ => _.elementClassification, [])
      .set(_ => _.isUniclass, true)
      .get();
  },
  [ClassificationActionTypes.SAVE_CURRENT_CLASSIFICATION]: (
    state,
    payload: CurrentElementClassification[][],
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.elementClassification, payload)
      .set(_ => _.assignmentStage, ClassificationAssignmentStage.PredictedInfo)
      .set(_ => _.classificationSelectedIndex, 0)
      .get();
  },
  [ClassificationActionTypes.CHANGE_ASSIGNMENT_LEVEL]: (
    state,
    payload: ClassificationAssignmentStage,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.assignmentStage, payload)
      .set(_ => _.tempUniclass, null)
      .get();
  },
  [ClassificationActionTypes.CHANGE_SYSTEM_FILTER]: (state, payload) =>
    ClassificationUtils.changeSystemOrProductFilter(state, payload, 'system'),
  [ClassificationActionTypes.CHANGE_PRODUCT_FILTER]: (state, payload) =>
    ClassificationUtils.changeSystemOrProductFilter(state, payload, 'product'),
  [ClassificationActionTypes.SET_CUSTOM_PRODUCT]: (state, payload) => {
    return setCustomProduct(state, payload).set(_ => _.isSystemTree, true).get();
  },
  [ClassificationActionTypes.SET_CUSTOM_SYSTEM]: (state, payload) => {
    return setCustomSystem(state, payload).set(_ => _.isSystemTree, false).get();
  },
  [ClassificationActionTypes.CREATE_CUSTOM_UNICLASS]: ClassificationUtils.createCustomUniclass,
  [ClassificationActionTypes.EDIT_ASSEMBLY_CLASSIFICATION]: state => {
    return new MonoliteHelper(state)
      .set(_ => _.itemClassification, state.elementClassification[0])
      .set(_ => _.classificationSelectedIndex, 0)
      .set(_ => _.changeAssemblyRoot, true)
      .set(_ => _.tempUniclass, null)
      .set(_ => _.assignmentStage, ClassificationAssignmentStage.Assignment)
      .get();
  },
  [ClassificationActionTypes.SET_SELECTED_UNICLASS]: (state, payload: number) => {
    const helper = new MonoliteHelper(state);
    if (!state.classificationTarget.layers || !state.classificationTarget.layers.length) {
      helper.set(_ => _.isSaveButtonEnabled, true);
    }
    return helper.set(_ => _.classificationSelectedIndex, payload).get();
  },
  [ClassificationActionTypes.SET_ASSEMBLY_ROOT_CLASSIFICATION]: state => {
    const selectedIndex = state.classificationSelectedIndex;
    const helper = new MonoliteHelper(state);
    if (selectedIndex >= 0) {
      const [selectedUniItem] = state.itemClassification.splice(selectedIndex, 1);
      helper.setPrepend(_ => _.elementClassification[0], selectedUniItem);
    } else {
      const custom = state.tempUniclass;
      const selectedUniItem = {
        productId: null,
        systemId: custom.system.id,
        productCode: 'Pr',
        productDescription: 'Undefined',
        systemCode: custom.system.code,
        systemDescription: custom.system.title,
        source: ClassificationSource.User,
      };
      helper.setPrepend(_ => _.elementClassification[0], selectedUniItem);
    }
    return helper
      .set(_ => _.isSaveButtonEnabled, true)
      .set(_ => _.classificationTarget.rootChanged, true)
      .get();
  },
  [ClassificationActionTypes.SET_ASSEMBLY_LAYER_CLASSIFICATION]: state => {
    const editableLayer = state.editableLayer;
    const selectedIndex = state.classificationSelectedIndex;
    const helper = new MonoliteHelper(state);
    if (selectedIndex >= 0) {
      const [selectedUniItem] = state.itemClassification.splice(selectedIndex, 1);
      helper.setPrepend(x => x.elementClassification[editableLayer], selectedUniItem);
    } else {
      const custom = state.tempUniclass;
      const selectedUniItem = {
        productId: custom.product.id,
        systemId: null,
        productCode: custom.product.code,
        productDescription: custom.product.title,
        systemCode: 'Ss',
        systemDescription: 'Undefined',
        source: ClassificationSource.User,
      };
      helper.setPrepend(_ => _.elementClassification[editableLayer], selectedUniItem);
    }
    if (!state.classificationTarget.changedLayers) {
      helper.set(_ => _.classificationTarget.changedLayers, [editableLayer - 1]);
    } else if (!state.classificationTarget.changedLayers.includes(editableLayer - 1)) {
      helper.setAppend(_ => _.classificationTarget.changedLayers, editableLayer - 1);
    }
    return helper.set(_ => _.isSaveButtonEnabled, true).get();
  },
  [ClassificationActionTypes.SET_CUSTOM_ASSEMBLY_SYSTEM]: (state, payload) => setCustomSystem(state, payload).get(),
  [ClassificationActionTypes.SET_CUSTOM_LAYER_PRODUCT]: (state, payload) => setCustomProduct(state, payload).get(),
  [ClassificationActionTypes.EDIT_LAYER_CLASSIFICATION]: (state, payload: number) => {
    return new MonoliteHelper(state)
      .set(_ => _.classificationSelectedIndex, 0)
      .set(_ => _.itemClassification, state.elementClassification[payload])
      .set(_ => _.changeAssemblyRoot, false)
      .set(_ => _.editableLayer, payload)
      .set(_ => _.tempUniclass, null)
      .set(_ => _.filterValue, '')
      .set(_ => _.assignmentStage, ClassificationAssignmentStage.Assignment)
      .set(_ => _.isSaveButtonEnabled, true)
      .get();
  },
  [ClassificationActionTypes.ASSIGN_CLASSIFICATION]: state => {
    let isUndefined = false;
    let isAugmentation = false;
    if (state.isUniclass) {
        if (state.elementClassification.length > 1) {
          const { systemId } = state.elementClassification[0][0] || {
            systemId: null,
          };
          isUndefined = !Number.isInteger(systemId);
          if (!isUndefined) {
            for (let i = 1; i < state.elementClassification.length; i++) {
              const { productId } = state.elementClassification[i][0];
              if (!Number.isInteger(productId)) {
                isUndefined = true;
                break;
              }
            }
          }
        } else {
          const { productId, systemId } = state.elementClassification[0][
            state.classificationSelectedIndex
          ];
          isUndefined = !Number.isInteger(productId) || !Number.isInteger(systemId);
        }
    } else {
        for (const graph of state.elementOntologyGraph) {
          if (graph.isAugmentation) {
            isAugmentation = true;
          }
          if (graph.isUndefined) {
            isUndefined = true;
          }
          if (isUndefined && isAugmentation) {
            break;
          }
        }
      }

    const lastBreadCrumb = ClassificationUtils.getLastBreadCrumb(state.modelBrowserFilters);
    const elementTypes = new Array<ClassificationElementTypeInfo>();
    if (lastBreadCrumb.multiple) {
      const elementTypesIds = state.currentSelectedElementTypesGroups[state.editedClassificationGroup].elementTypes;
      const filtered = ClassificationUtils.isTreeFiltered(state.modelBrowserFilters);
      for (let i = 0; i < elementTypesIds.length; i++) {
        const filteredId = elementTypesIds[i];
        let sourceId = filteredId;
        if (filtered) {
          const [startEngineIds] = state.modelBrowserFiltered[filteredId].engineIds;
          sourceId = state.bimElements[startEngineIds].type;
        }
        elementTypes[i] = { sourceId, filteredId };
      }
    } else {
      elementTypes.push({ sourceId: lastBreadCrumb.elementType, filteredId: null });
    }
    return ClassificationUtils.updateErrorStatuses(
        state,
        elementTypes,
        isUndefined,
        ClassificationMultiselectOperation.Edit,
        isAugmentation,
      )
      .set(_ => _.isSaveButtonEnabled, false).get();
  },
  [ClassificationActionTypes.TOGGLE_UNCLASSIFIED_FILTER]: state => {
    const helper = new MonoliteHelper(state);
    let tree: RevitTreeListNode[];
    if (!state.modelBrowserFilters.unclassifiedFilterEnabled) {
      const filtered = ClassificationUtils.isTreeFiltered(state.modelBrowserFilters);
      tree = filtered ? state.modelBrowserFiltered : state.modelTree;
      tree = ClassificationUtils.changeTreeSelectStatus(tree, false).tree;
      const minimumLevel = ClassificationUtils.getMinimumLevel(state.modelBrowserFilters);
      tree = ClassificationUtils.filterTreeNodes(
        tree,
        state.modelBrowserFilters.tab,
        !state.modelBrowserFilters.unclassifiedFilterEnabled,
        state.modelBrowserFilters.searchQuery.toLowerCase(),
        minimumLevel,
      );
      helper
        .set(
          _ => _.filteredElementTypesCount,
          ClassificationUtils.calculateErrorElementTypes(tree).all,
        )
        .set(_ => _.modelBrowserFiltered, tree);
    } else {
      if (state.modelBrowserFilters.isolationEnabled) {
        const lastBreadCrumb = ClassificationUtils.getLastBreadCrumb(
          state.modelBrowserFilters,
        );
        const itemId =
          lastBreadCrumb.type === RevitTreeLevel.Family
            ? lastBreadCrumb.family
            : lastBreadCrumb.category;
        const firstItemId = itemId + 1;
        tree = state.modelTree
          .slice(firstItemId, state.modelTree[itemId].lastChildId + 1)
          .map(x => {
            return {
              ...x,
              lastChildId: x.lastChildId - firstItemId,
            };
          });
        tree = ClassificationUtils.filterTreeNodes(
          tree,
          state.modelBrowserFilters.tab,
          false,
          state.modelBrowserFilters.searchQuery.toLowerCase(),
          lastBreadCrumb.type + 1,
        );
        const { all } = ClassificationUtils.calculateErrorElementTypes(tree);
        helper.set(_ => _.modelBrowserFiltered, tree).set(_ => _.filteredElementTypesCount, all);
      } else {
        if (state.modelBrowserFilters.tab !== -1 || state.modelBrowserFilters.searchQuery) {
          tree = ClassificationUtils.filterTreeNodes(
            state.modelTree,
            state.modelBrowserFilters.tab,
            false,
            state.modelBrowserFilters.searchQuery.toLowerCase(),
          );
          const { all } = ClassificationUtils.calculateErrorElementTypes(tree);
          helper.set(_ => _.modelBrowserFiltered, tree).set(_ => _.filteredElementTypesCount, all);
        } else {
          helper
            .set(
              _ => _.modelTree,
              ClassificationUtils.changeTreeSelectStatus(state.modelTree, false).tree,
            )
            .set(_ => _.modelBrowserFiltered, [])
            .set(_ => _.filteredElementTypesCount, 0);
        }
      }
    }
    return ClassificationUtils.changeFilter(helper)
      .set(
        _ => _.modelBrowserFilters.unclassifiedFilterEnabled,
        !state.modelBrowserFilters.unclassifiedFilterEnabled)
      .get();
  },
  [ClassificationActionTypes.SELECT_NODE_VARIANT]: ClassificationUtils.enableSaveButton,
  [ClassificationActionTypes.REMOVE_NODE]: ClassificationUtils.enableSaveButton,
  [ClassificationActionTypes.TOGGLE_SEARCH_MODE]: state => {
    let helper: MonoliteHelper<ClassificationReduxState>;
    if (state.isSearchModeEnabled) {
      const isolated =
        state.modelBrowserFilters.tab !== -1 ||
        state.modelBrowserFilters.isolationEnabled ||
        state.modelBrowserFilters.unclassifiedFilterEnabled;
      helper = (isolated
        ? ClassificationUtils.searchFilterTree(state, '')
        : new MonoliteHelper(state)
      ).set(_ => _.modelBrowserFilters.searchQuery, '');
    } else {
      helper = new MonoliteHelper(state);
    }
    return helper.set(_ => _.isSearchModeEnabled, !state.isSearchModeEnabled).get();
  },
  [ClassificationActionTypes.CHANGE_SEARCH_QUERY]: (state, query) =>
    ClassificationUtils.searchFilterTree(state, query).get(),
  [ClassificationActionTypes.COPY_CLASSIFICATION_SUCCESS]: (
    state,
    payload: ClassificationCopySuccessPayload,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.elementTypeComparisonPattern, payload.pattern)
      .set(_ => _.graphsByKeys, payload.graphs)
      .set(_ => _.copyPastRequestState, RequestStatus.NotRequested)
      .get();
  },
  [ClassificationActionTypes.COPY_CLASSIFICATION]: startCopyPaste,
  [ClassificationActionTypes.PASTE_CLASSIFICATION]: startCopyPaste,
  [ClassificationActionTypes.PASTE_CLASSIFICATION_SUCCESS]: (
    state,
    payload: ClassificationPasteCompletePayload,
  ) => {
    return ClassificationUtils.updateErrorStatuses(
      state,
      payload.pastedElementTypes,
      payload.isUndefined,
      ClassificationMultiselectOperation.Paste,
    )
      .set(_ => _.copyPastRequestState, RequestStatus.Loaded)
      .set(_ => _.classificationPasted, payload.pastedElementTypes)
      .set(_ => _.classificationNotPasted, payload.notPastedElementTypes)
      .get();
  },
  [ClassificationActionTypes.ON_SELECT]: (state, payload: ClassificationSelectItemPayload) => {
    const helper = new MonoliteHelper(state);
    const filtered = ClassificationUtils.isTreeFiltered(state.modelBrowserFilters);
    let currentTree = filtered ? state.modelBrowserFiltered : state.modelTree;
    const currentItem = currentTree[payload.index];
    let selectedIds = state.selectedIds.slice();
    const [start, end] = currentItem.engineIds;
    const currentElementIds = state.bimElements.slice(start, end).map(x => x.id);
    if (payload.value) {
      arrayUtils.extendArray(selectedIds, currentElementIds);
      helper.set(_ => _.selectedPath, payload.index);
    } else {
      selectedIds = selectedIds.filter(x => !currentElementIds.includes(x));
      const selectedPathId = currentTree.findIndex(x => x.selected);
      helper.set(_ => _.selectedPath, selectedPathId !== -1 ? selectedPathId : null);
    }

    helper.set(_ => _.selectedIds, selectedIds);
    {
      const { tree, changedElementTypes } = ClassificationUtils.changeTreeSelectStatus(
        currentTree,
        payload.value,
        payload.index,
        currentItem.lastChildId || payload.index,
      );
      currentTree = tree;
      const selectedIdCount =
        state.selectedElementTypesCount + (payload.value ? 1 : -1) * changedElementTypes;
      helper.set(_ => _.selectedElementTypesCount, selectedIdCount);
    }
    if (currentItem.level !== currentTree[0].level) {
      currentTree = ClassificationUtils.updateParentNodesSelectStatus(
        currentTree,
        state.modelBrowserFilters.breadCrumbs,
        [currentItem.parentId],
      );
    }
    return helper
      .set(_ => (filtered) ? _.modelBrowserFiltered : _.modelTree, currentTree)
      .set(_ => _.currentSelectedElementTypesGroups, [])
      .get();
  },
  [ClassificationActionTypes.SELECT_ALL]: (state, payload) => {
    const helper = new MonoliteHelper(state);
    const filtered = ClassificationUtils.isTreeFiltered(state.modelBrowserFilters);
    let selectedCount = 0;
    let currentTree = filtered ? state.modelBrowserFiltered : state.modelTree;
    if (payload) {
      selectedCount = filtered ? state.filteredElementTypesCount : state.allElementTypesCount;
      const selectedIds = Array<number>();
      for (const item of currentTree) {
        if (item.level === RevitTreeLevel.ElementType) {
          const [start, end] = item.engineIds;
          arrayUtils.extendArray(selectedIds, state.bimElements.slice(start, end).map(x => x.id));
        }
      }
      helper.set(_ => _.selectedPath, 0);
      helper.set(_ => _.selectedIds, selectedIds);
    } else {
      helper.set(_ => _.selectedIds, []).set(_ => _.selectedPath, null);
    }

    currentTree = ClassificationUtils.changeTreeSelectStatus(currentTree, payload).tree;
    return helper
      .set(_ => (filtered ?  _.modelBrowserFiltered : _.modelTree), currentTree)
      .set(_ => _.selectedElementTypesCount, selectedCount)
      .set(_ => _.currentSelectedElementTypesGroups, [])
      .get();
  },
  [ClassificationActionTypes.SELECT_PASTED]: state =>
    ClassificationUtils.selectElementTypes(state, state.classificationPasted, value => value.filteredId),
  [ClassificationActionTypes.SELECT_NOT_PASTED]: state =>
    ClassificationUtils.selectElementTypes(state, state.classificationNotPasted, value => value.filteredId),
  [ClassificationActionTypes.SELECT_BY_ENGINE_IDS]: (state, payload: number[]) => {
    const filtered = ClassificationUtils.isTreeFiltered(state.modelBrowserFilters);
    let tree = filtered ? state.modelBrowserFiltered : state.modelTree;
    tree = ClassificationUtils.changeTreeSelectStatus(tree, false).tree;
    if (tree.length === 0) {
      return state;
    }
    const indices = new Array<boolean>(tree[tree.length - 1].engineIds[1]);
    const engineIds = new Set(payload);
    for (const element of state.bimElements) {
      if (engineIds.has(element.id)) {
        indices[state.modelTree[element.type].engineIds[0]] = true;
      }
    }
    const parentIds = [];
    const selectedEngineIds = [];
    let selectedPath = null;
    let selectedElementTypesCount = 0;
    let treeCenterPosition = 0;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      const [start, end] = node.engineIds;
      const firstNotFound = selectedPath === null;
      if (firstNotFound) treeCenterPosition++;
      if (node.level === RevitTreeLevel.ElementType) {
        if (indices[start]) {
          parentIds.push(node.parentId);
          node.selected = true;
          selectedElementTypesCount++;
          arrayUtils.extendArray(
            selectedEngineIds,
            state.bimElements.slice(start, end).map(x => x.id),
          );
          if (firstNotFound) selectedPath = i;
        }
      } else {
        const validIndices = indices.slice(start, end).filter(x => !!x);
        if (!validIndices.length) {
          i = node.lastChildId;
        }
        node.expanded = validIndices.length && firstNotFound;
      }
    }
    tree = ClassificationUtils.updateParentNodesSelectStatus(
      tree,
      state.modelBrowserFilters.breadCrumbs,
      parentIds,
    );
    return new MonoliteHelper(state)
      .set(_ => (filtered ? _.modelBrowserFiltered : _.modelTree), tree)
      .set(_ => _.selectedElementTypesCount, selectedElementTypesCount)
      .set(_ => _.selectedPath, selectedPath)
      .set(_ => _.treeCenterElement, treeCenterPosition)
      .set(_ => _.selectedIds, selectedEngineIds)
      .set(_ => _.currentSelectedElementTypesGroups, [])
      .get();
  },
  [ClassificationActionTypes.SAVE_GROUPING]: (state, payload: ClassificationComparisonPatternGroup[]) => {
    return new MonoliteHelper(state).set(_ => _.currentSelectedElementTypesGroups, payload).get();
  },
  [ClassificationActionTypes.SELECT_GROUP]: (state, payload: number) => {
    const ids = new Array<number>();
    const helper = new MonoliteHelper(state).set(_ => _.selectedClassificationGroup, payload);
    if (payload === -1) {
      for (const group of state.currentSelectedElementTypesGroups) {
        arrayUtils.extendArray(ids, group.elementTypes);
      }
    } else {
      arrayUtils.extendArray(ids, state.currentSelectedElementTypesGroups[payload].elementTypes);
    }
    state = ClassificationUtils.selectElementTypes(helper.get(), ids, id => id);
    return state;
  },
  [ClassificationActionTypes.EDIT_GROUP]: (state, payload: number) => {
    const group = state.currentSelectedElementTypesGroups[payload];
    const editMultipleBreadCrumb: RevitTreePathInfo = {
      name: `${group.elementTypes.length} Element Types`,
      multiple: true,
      type: RevitTreeLevel.ElementType,
    };
    return new MonoliteHelper(state)
      .set(_ => _.modelBrowserFilters.breadCrumbs, [editMultipleBreadCrumb])
      .set(_ => _.classificationTarget, group.classificationTarget)
      .set(_ => _.editedClassificationGroup, payload)
      .set(_ => _.cachedIsolationStatus, state.modelBrowserFilters)
      .set(_ => _.selectedIds, [])
      .get();
  },
  [ClassificationActionTypes.SELECT_TREE_NODE]: (state, payload: number) => {
    const tree = ClassificationUtils.isTreeFiltered(state.modelBrowserFilters)
      ?  state.modelBrowserFiltered : state.modelTree;
    const [start, end] = tree[payload].engineIds;
    return new MonoliteHelper(state)
      .set(_ => _.selectedIds, state.bimElements.slice(start, end).map(x => x.id))
      .set(_ => _.selectedPath, payload)
      .get();
  },
  [ClassificationActionTypes.SELECT_TREE_NODE_BY_ENGINE_ID]: (state, payload: number) => {
    const isFiltered = ClassificationUtils.isTreeFiltered(state.modelBrowserFilters);
    const bimElementInfoIndex = state.bimElements.findIndex(x => x.id === payload);
    const bimElementInfo = state.bimElements[bimElementInfoIndex];
    let selectedIds = [];
    let selectedPath: number;
    let treeHelper: MonoliteHelper<RevitTreeListNode[]>;
    let treeCenterPosition = 0;
    if (isFiltered) {
      treeHelper = new MonoliteHelper(state.modelBrowserFiltered);
      let node: RevitTreeListNode;
      for (let i = 0; i < state.modelBrowserFiltered.length; i++) {
        const treeNode = state.modelBrowserFiltered[i];
        if (treeNode.engineIds[0] <= bimElementInfoIndex && treeNode.engineIds[1] > bimElementInfoIndex) {
          if (treeNode.level === RevitTreeLevel.ElementType) {
            node = treeNode;
            selectedPath = i;
            break;
          } else {
            treeHelper.set(_ => _[i].expanded, true);
          }
        } else {
          if (treeNode.expanded) {
            treeHelper.set(_ => _[i].expanded, false);
          }
          if (treeNode.level !== RevitTreeLevel.ElementType) {
            i = treeNode.lastChildId;
          }
        }
        treeCenterPosition++;
      }
      const [start, end] = node.engineIds;
      selectedIds = state.bimElements.slice(start, end).map(x => x.id);
    } else {
      treeHelper = new MonoliteHelper(state.modelTree);
      const [start, end] = state.modelTree[bimElementInfo.type].engineIds;
      selectedIds = state.bimElements.slice(start, end).map(x => x.id);
      selectedPath = bimElementInfo.type;
      for (let i = 0; i < state.modelTree.length; i++) {
        const node = state.modelTree[i];
        const indexInPath = i === bimElementInfo.category || i === bimElementInfo.family || i === bimElementInfo.type;
        if (indexInPath) {
          if (!node.expanded) {
            treeHelper.set(_ => _[i].expanded, true);
          }
          if (node.level === RevitTreeLevel.ElementType) {
            break;
          }
        } else {
          if (node.expanded) {
            treeHelper.set(_ => _[i].expanded, false);
          }
          if (node.level !== RevitTreeLevel.ElementType) {
            i = node.lastChildId;
          }
        }
        treeCenterPosition++;
      }
    }

    return new MonoliteHelper(state)
      .set(_ => isFiltered ? _.modelBrowserFiltered : _.modelTree, treeHelper.get())
      .set(_ => _.selectedIds, selectedIds)
      .set(_ => _.selectedPath, selectedPath)
      .set(_ => _.treeCenterElement, treeCenterPosition)
      .get();
  },
};
