import React, { useEffect } from 'react';
import { CantSelectSinglePlanDialog, UserMoreThanSeatsDialog } from 'common/components/change-plan-dialogs';
import { SubscriptionType } from 'common/constants/subscription';
import { SubscribePageApproveDialog } from '../subscribe-page-approve-dialog';
import { SubscriptionFaq } from '../subscription-faq';
import { SubscriptionListing } from '../subscription-listing';
import {
  useHandleSelectPlanGroup,
  useHandleSubscriptionClickCallback,
  useLoadSubscriptionEffect,
  useUpgradePlanState,
} from './hooks';
import { Styled } from './styled';

export const UpgradePlan = (): JSX.Element => {
  useLoadSubscriptionEffect();
  const {
    billingPeriod,
    selectedAddonGroups,
    selectedPlanGroup,
    filteredAddons,
    selectedAddons,
    filteredPlanGroups,
    currentPlanVariant,
  } = useUpgradePlanState();

  const handleSubscriptionClick = useHandleSubscriptionClickCallback(
    billingPeriod.value,
    selectedPlanGroup.value,
    selectedAddons,
  );
  const handleSelectPlanGroup = useHandleSelectPlanGroup(selectedPlanGroup.set);

  useEffect(() => {
    const planGroups = filteredPlanGroups?.filter(plan => plan.isSelfService);
    if (planGroups && planGroups.length === 1) {
      handleSelectPlanGroup(planGroups[0].name);
    }
  });

  return (
    <>
      <Styled.Main>
        <SubscriptionListing
          billingPeriod={billingPeriod.value}
          filteredPlanGroups={filteredPlanGroups}
          selectedAddons={selectedAddons}
          onSubscriptionPlanGroupClick={handleSelectPlanGroup}
          onBillingPeriodChanged={billingPeriod.set}
          planButtonText={'Buy now'}
          hidePlanDescription={true}
        />
        <SubscriptionFaq subscriptionType={SubscriptionType.Takeoff2d} />
      </Styled.Main>
      <SubscribePageApproveDialog
        subscriptionType={SubscriptionType.Takeoff2d}
        onCreateSubscriptionClick={handleSubscriptionClick}
        addons={filteredAddons}
        onBillingPeriodChanged={billingPeriod.set}
        onSelectedAddonGroupsChanged={selectedAddonGroups.set}
        planVariant={currentPlanVariant}
        selectedAddonIds={selectedAddons.map(x => x.id)}
        billingPeriod={billingPeriod.value}
      />
      <CantSelectSinglePlanDialog />
      <UserMoreThanSeatsDialog isArhived={false} />
    </>
  );
};
