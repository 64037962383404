import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { BillingAddressStyled } from '../../payment-method-manager/billing-address';


const ContentContainer = styled.div<{ headerMargin: number, isVatNumber?: boolean }>`
  margin-left: 10px;
  margin-top: 14px;

  > ${Text} {
    margin-bottom: ${p => p.headerMargin}px;
  }

  ${BillingAddressStyled.VatCountryLabel} {
    margin-left: 20px;
  }

  ${BillingAddressStyled.Column} {
    &:first-child {
      width: 280px;
    }

    > div {
      margin-bottom: ${p => p.isVatNumber ? 0 : 20}px;
    }
  }
`;

const FillStepScaleContainer = styled.div`
`;

export const Styled = {
  ContentContainer,
  FillStepScaleContainer,
};
