import * as Ag from 'ag-grid-community';


import { TreeTableRowType, TreeTableValueGetterParams } from 'common/components/tree-table/interfaces';
import { PropertyHelper, QtoColumnPropertyHelper } from '..';
import { DefaultPivotColumnKey } from '../../../enums/default-pivot-column-key';
import { QtoTreeColumn, QtoTreeRowProperty } from '../../../interfaces/quantity-take-off';
import { QuantityTakeOffFormulaHelper } from '../formula-helper';
import { pivotAggregationFunctions } from './aggregation-function';
import { QtoReportPivotTableIdHelper } from './id-helper';


const columnPropertyGetter = (
  baseColumns: Record<string, QtoTreeColumn>,
  nodeId: string,
  propertyKey: string,
): QtoTreeRowProperty => {
  const ids = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(nodeId);
  if (!ids) {
    return;
  }

  const { columnId } = ids;
  return baseColumns[columnId]
    && baseColumns[columnId].properties
    && baseColumns[columnId].properties[propertyKey];
};

const columnValueGetter = (
  baseColumns: Record<string, QtoTreeColumn>,
  nodeId: string,
  propertyKey: string,
): string => {
  const property = columnPropertyGetter(baseColumns, nodeId, propertyKey);
  return PropertyHelper.getActualValue(property);
};

const unitPropertyGetter = (node: Ag.RowNode, baseColumns: Record<string, QtoTreeColumn>): QtoTreeRowProperty => {
  const unitPropertyKey = QtoColumnPropertyHelper.columnProperties.unit;
  return columnPropertyGetter(baseColumns, node.id, unitPropertyKey);
};

const pivotRowNamePropertyGetter = (
  node: Ag.RowNode, baseColumns: Record<string, QtoTreeColumn>,
): QtoTreeRowProperty => {
  const headerPropertyKey = QtoColumnPropertyHelper.columnProperties.header;
  return columnPropertyGetter(baseColumns, node.id, headerPropertyKey);
};

const getPropertyFromNode = (node: Ag.RowNode, propertyKey: string): QtoTreeRowProperty => {
  if (!node || !node.data || !node.data.properties) {
    return;
  }
  return node.data.properties[propertyKey];
};

const getDiffPropertyGetter = (node: Ag.RowNode, pivotColumnId: string): QtoTreeRowProperty => {
  const ids = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(node.id);
  let propertyKey;
  switch (pivotColumnId) {
    case DefaultPivotColumnKey.QuantityPrev:
      propertyKey = QtoReportPivotTableIdHelper.getColumnIdWithPrevPostfix(ids.columnId);
      break;
    default: return;
  }

  return getPropertyFromNode(node.parent, propertyKey);
};

const commonPropertyGetter = (
  node: Ag.RowNode,
  propertyId: string,
  params: TreeTableValueGetterParams,
): QtoTreeRowProperty => {
  const parent = node.parent;
  const ids = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(node.id);
  if (!ids) {
    return;
  }
  const pivotColumnId = QtoReportPivotTableIdHelper.getPivotPropertyId(ids.columnId, propertyId);

  const value = getPropertyFromNode(parent, pivotColumnId);

  if (PropertyHelper.isFormula(value)) {
    try {
      const actualValue = PropertyHelper.getActualValue<string>(value);
      return {
        override: QuantityTakeOffFormulaHelper.calculateFormulaPivotMode(
          actualValue.replace(QuantityTakeOffFormulaHelper.nonBreakingSpaceRegex, ''),
          parent,
          params,
        ),
      };
    } catch {
      return { override: 'Invalid' };
    }
  }

  return value;
};

const quantityPropertyGetter = (
  node: Ag.RowNode, defaultValues: Record<string, QtoTreeRowProperty>,
): QtoTreeRowProperty => {
  const { columnId } = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(node.id);

  return node.id in defaultValues
    ? defaultValues[node.id]
    : node.parent.data.properties[columnId];
};

const quantityValueGetter = (
  params: TreeTableValueGetterParams,
  baseColumns: Record<string, QtoTreeColumn>,
  defaultValues: Record<string, QtoTreeRowProperty>,
): string => {
  const property = quantityPropertyGetter(params.node, defaultValues);
  if (PropertyHelper.isAggregation(property)) {
    const aggPropertyKey = QtoColumnPropertyHelper.columnProperties.aggregationStrategy;
    const aggFuncKey = columnValueGetter(baseColumns, params.node.id, aggPropertyKey);
    return aggFuncKey && pivotAggregationFunctions[aggFuncKey](params, defaultValues);
  }

  if (PropertyHelper.isFormula(property)) {
    try {
      const node = params.node.parent;
      const formula = PropertyHelper.getActualValue<string>(property);
      return QuantityTakeOffFormulaHelper.calculateFormulaPivotMode(
        formula.replace(QuantityTakeOffFormulaHelper.nonBreakingSpaceRegex, ''),
        node,
        params);
    } catch {
      return 'Invalid';
    }
  }

  return PropertyHelper.getActualValue(property);
};

const totalValueGetter = (
  params: TreeTableValueGetterParams,
): string => {
  const { node, columnId } = params;
  const parent = node.parent;
  const ids = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(node.id);
  if (!ids) {
    return;
  }
  const pivotColumnId = QtoReportPivotTableIdHelper.getPivotPropertyId(ids.columnId, columnId);

  const property = getPropertyFromNode(parent, pivotColumnId);

  if (property && property.override) {
    return PropertyHelper.getActualValue(commonPropertyGetter(node, columnId, params));
  }

  const rateId = QtoReportPivotTableIdHelper.getPivotPropertyId(ids.columnId, DefaultPivotColumnKey.Rate);
  const rateProperty = getPropertyFromNode(parent, rateId);
  const rate = PropertyHelper.getActualValue(rateProperty);
  const totalProperty = parent.data.properties[pivotColumnId];
  const isTotalOverrideValue = PropertyHelper.isOverrideValue(totalProperty);

  if (!rate && !isTotalOverrideValue) {
    // TODO нельзя выставлять значение в функции getЗначения
    delete parent.data.properties[pivotColumnId];
    return undefined;
  }

  const formula = `=[${rateId}]*[${ids.columnId}]`;
  if (!property || !property.default) {
    // TODO нельзя выставлять значение в функции getЗначения
    parent.data.properties[pivotColumnId] = {
      default: rate ? formula : null,
      override: totalProperty && totalProperty.override,
    };
  }

  try {
    return QuantityTakeOffFormulaHelper.calculateFormulaPivotMode(
      formula,
      node.parent,
      params,
    );
  } catch {
    return 'Invalid';
  }
};


export const pivotPropertyGetter = (
  params: TreeTableValueGetterParams,
  baseColumns: Record<string, QtoTreeColumn>,
  defaultValues: Record<string, QtoTreeRowProperty>,
): QtoTreeRowProperty => {
  const { node, columnId } = params;
  const data = node && node.data;
  if (!data || data.rowType === TreeTableRowType.Group) {
    return;
  }

  switch (columnId) {
    case DefaultPivotColumnKey.Unit:
      return unitPropertyGetter(node, baseColumns);
    case 'name':
      return pivotRowNamePropertyGetter(node, baseColumns);
    case DefaultPivotColumnKey.QuantityPrev:
      return getDiffPropertyGetter(node, columnId);
    case DefaultPivotColumnKey.Quantity:
      return quantityPropertyGetter(node, defaultValues);
    default:
      return commonPropertyGetter(node, columnId, params);
  }
};

export const pivotValueGetter = (
  params: TreeTableValueGetterParams,
  baseColumns: Record<string, QtoTreeColumn>,
  defaultValues: Record<string, QtoTreeRowProperty>,
): string => {
  if (params.columnId === DefaultPivotColumnKey.Quantity) {
    return quantityValueGetter(params, baseColumns, defaultValues);
  }

  if (params.columnId === DefaultPivotColumnKey.Total) {
    return totalValueGetter(params);
  }

  const property = pivotPropertyGetter(params, baseColumns, defaultValues);
  return PropertyHelper.getActualValue(property);
};
