import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { ReportPanelPage } from '2d/report-panel-page';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { GlobalKeyboardEventsController } from 'common/components/global-keyboard-events-controller';
import { PageLayoutWithoutHeader } from 'common/components/page-layout/empty-page-layout';
import { ProjectType } from 'common/constants/project-type';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { ProjectsActions } from '../../../units/projects/actions/creators/common';
import { Project } from '../../../units/projects/interfaces/project';
import { SpinnerPage } from '../../spinner-page';
import { Qto2dProjectRouteParams } from '../route-params';
import { Urls } from '../urls';

interface RoutesStateProps {
  currentProject: Project | null;
  projectLoaded: boolean;
  companyId: number;
  projectLoadingStatus: RequestStatus;
}

interface RoutesDispatchProps {
  changeProjectName: (name: string) => void;
  setCurrentProject: (projectId: number) => void;
  dropCurrentProjectInfo: () => void;
  clearProjectHeaders: (projectId: number, type: ProjectType) => void;
  fetchDataBase: () => void;
}

interface RoutesProps
  extends RoutesStateProps,
  RoutesDispatchProps,
  RouteComponentProps<Qto2dProjectRouteParams>,
  AbilityAwareProps { }


interface RoutesProps extends RouteComponentProps<Qto2dProjectRouteParams> {
}

interface ComponentState {
  pageRef: HTMLDivElement;
}

class ProjectRoutesComponent extends React.Component<RoutesProps, ComponentState> {
  constructor(props: RoutesProps) {
    super(props);
    this.state = {
      pageRef: null,
    };
  }

  public componentDidMount(): void {
    this.fetchProjectIfNeeded();
    this.fetchDataBaseIfNeeded();
  }

  public componentDidUpdate(): void {
    this.fetchProjectIfNeeded();
  }

  public render(): React.ReactNode {
    if (!this.props.projectLoaded) {
      return <Route component={SpinnerPage} />;
    }

    return (
      <Switch>
        <GlobalKeyboardEventsController>
          <PageLayoutWithoutHeader
            component={ReportPanelPage}
            path={Urls.project.report.path}
            saveContainerRef={this.saveRef}
          />
        </GlobalKeyboardEventsController>
      </Switch>
    );
  }

  public componentWillUnmount(): void {
    const { companyId, clearProjectHeaders, dropCurrentProjectInfo, currentProject } = this.props;
    clearProjectHeaders(companyId, currentProject?.type);
    dropCurrentProjectInfo();
  }

  private fetchProjectIfNeeded(): void {
    const parsedProjectId = parseInt(this.props.match.params.projectId, 10);
    if (this.shouldFetchProject(parsedProjectId)) {
      this.props.setCurrentProject(parsedProjectId);
    }
  }

  private shouldFetchProject(parsedProjectId: number): boolean {
    return !isNaN(parsedProjectId)
      && (this.props.currentProject?.id !== parsedProjectId)
      && this.props.projectLoadingStatus !== RequestStatus.Loading;
  }

  private fetchDataBaseIfNeeded(): void {
    if (this.props.ability.can(Operation.Read, Subject.PiaDatabase)) {
      this.props.fetchDataBase();
    }
  }

  @autobind
  private saveRef(pageRef: HTMLDivElement): void {
    this.setState({ pageRef });
  }
}

function mapStateToProps(state: State, ownProps: RoutesProps): RoutesStateProps {
  const currentProject = state.projects.currentProject;
  const projectLoaded =
    !!currentProject &&
    currentProject.id === parseInt(ownProps.match.params.projectId, 10) &&
    !!state.account.selectedCompany &&
    currentProject.companyId === state.account.selectedCompany.id;
  const selectedCompany = state.account.selectedCompany;
  const companyId = selectedCompany ? selectedCompany.id : null;

  return {
    currentProject,
    projectLoaded,
    companyId,
    projectLoadingStatus: state.projects.status,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): RoutesDispatchProps {
  return {
    changeProjectName: (name: string) => dispatch(ProjectsActions.updateProjectName(name)),
    setCurrentProject: id => dispatch(ProjectsActions.setCurrentProject(id)),
    dropCurrentProjectInfo: () => dispatch(ProjectsActions.dropCurrentProjectInfo()),
    clearProjectHeaders: (companyId, type) => dispatch(ProjectsActions.clearCompanyProjectHeaders(companyId, type)),
    fetchDataBase: () => dispatch(TwoDDatabaseActions.fetchDatabaseRequest()),
  };
}

export const ReportRoutes = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProjectRoutesComponent),
);
