import { GridApi, RowNode } from 'ag-grid-community';
import { useCallback } from 'react';
import { canMove } from '../../helpers/can-move';
import { getSelectedRootIds } from '../../helpers/get-selected-root-ids';

type UseDragEndCallback = (
  handleDragRow: (parentId: string | null, _: number, rowIdsToMove: string[]) => void,
  gridApi: GridApi,
  canEdit: boolean
) => [(targetId: string, sourceId: string, isGroup: boolean) => void];

function moveNodes(
  nodes: RowNode[],
  nodesIds: string[],
  targetId: string,
  handleDragRow: (parentId: string | null, _: number, rowIdsToMove: string[]) => void,
): void {
  const isValidMove = canMove(targetId, nodes);
  if (!isValidMove) {
    return;
  }
  handleDragRow(targetId, 0, nodesIds);
}

function handleDragSelected(
  targetId: string,
  sourceId: string,
  selectedNodes: RowNode[],
  handleDragRow: (parentId: string | null, _: number, rowIdsToMove: string[]) => void,
  gridApi: GridApi,
): void {
  const selectedRootsNodes = getSelectedRootIds(selectedNodes);
  if (selectedRootsNodes.length) {
    moveNodes(selectedNodes, selectedRootsNodes, targetId, handleDragRow);
    return;
  }
  const node = gridApi.getRowNode(sourceId);
  moveNodes([node], [sourceId], targetId, handleDragRow);
}

function handleDragNotSelected(
  targetId: string,
  sourceId: string,
  handleDragRow: (parentId: string | null, _: number, rowIdsToMove: string[]) => void,
  gridApi: GridApi,
): void {
  const node = gridApi.getRowNode(sourceId);
  moveNodes([node], [sourceId], targetId, handleDragRow);
}

export const useDragEndCallback: UseDragEndCallback = (handleDragRow, gridApi, canEdit) => {
  const dragEndCallback = useCallback((targetId, sourceId) => {
    if (!gridApi) {
      return;
    }
    if (!sourceId) {
      return;
    }
    const selectedNodes = gridApi.getSelectedNodes();
    if (selectedNodes.some(x => x.id === sourceId)) {
      handleDragSelected(targetId, sourceId, selectedNodes, handleDragRow, gridApi);
    } else {
      handleDragNotSelected(targetId, sourceId, handleDragRow, gridApi);
    }
  }, [gridApi, handleDragRow]);

  if (!canEdit) {
    return [undefined];
  }

  return [dragEndCallback];
};
