import {
  DrawingsFilesData,
  DrawingsGeometryGroup,
  DrawingsGeometryInstance,
  DrawingsProcessedAiAnnotation,
} from 'common/components/drawings/interfaces';
import {
  AnnotationFilters,
  AssignFilterValue,
  FilterData,
} from 'common/components/drawings/interfaces/drawing-filters';
import { DrawingAnnotationFiltrationUtils } from 'common/components/drawings/utils/drawing-annotation-filtration-utils';
import { DrawingAnnotationUtils } from 'common/components/drawings/utils/drawing-annotation-utils';
import { arrayUtils } from 'common/utils/array-utils';
import { AssignedPia } from '../../../../../../../units/2d';


export function* filterFileInstances(
  hiddenIds: Set<string>,
  fileData: Record<string, DrawingsProcessedAiAnnotation>,
): IterableIterator<string> {
  yield *arrayUtils.filterIterator(
    DrawingAnnotationUtils.iterateFileInstances(fileData),
    instanceId => !hiddenIds.has(instanceId),
  );
}

export function filterAllFilesInstances(
  hiddenIds: Set<string>,
  fileData: DrawingsFilesData,
): string[] {
  const result = [];
  for (const file of Object.values(fileData)) {
    for (const instanceId of filterFileInstances(hiddenIds, file)) {
      result.push(instanceId);
    }
  }
  return result;
}


export function filterOpenedDrawingInstances(
  hiddenIds: Set<string>,
  instances: Record<string, DrawingsGeometryInstance>,
  selectedDrawings: Set<string>,
): string[] {
  const entries = Object.entries(instances);
  return arrayUtils.filterMap(
    entries,
    ([id, value]) => !hiddenIds.has(id) && selectedDrawings.has(value.drawingId),
    ([id]) => id,
  );
}


export function applyLegendFilters(
  instancesIds: Iterable<string>,
  filterData: FilterData,
  groups: DrawingsGeometryGroup[],
  instances: Record<string, DrawingsGeometryInstance>,
  assignPia: Record<string, AssignedPia>,
  isShowTwoDReport: boolean,
  viewMeasureId: Record<string, boolean>,
  dynamicGroupsToCell: Record<string, string[]>,
  drawingInstanceInCellRange: Record<string, boolean>,
  selectedMeasureIdFromView: Record<string, boolean>,
  instanceToCells: Record<string, string[]>,
  selectedSheetId: string,
  filteredNodeIds: Record<string, boolean>,
): string[] {
  const result = [];
  const groupsMap: Record<string, DrawingsGeometryGroup> = arrayUtils.toDictionary(
    groups,
    g => g.id,
    g => g,
  );
  const allowedInstanceTypes =
    DrawingAnnotationFiltrationUtils.getAllowedInstancesTypes(filterData[AnnotationFilters.Shapes]);

  const isPiaAssigned = (instanceId: string, instance: DrawingsGeometryInstance): boolean => {
    return DrawingAnnotationFiltrationUtils.isAssignValid(
      instanceId,
      instance,
      assignPia,
      groupsMap,
      filterData[AnnotationFilters.Assign] as AssignFilterValue,
    );
  };

  const isReportUsageValid = (instanceId: string, groupId: string): boolean => {
    return DrawingAnnotationFiltrationUtils.isValidReportUsing(
      instanceId,
      groupId,
      filterData,
      isShowTwoDReport,
      viewMeasureId,
      dynamicGroupsToCell,
      groups,
      drawingInstanceInCellRange,
      selectedMeasureIdFromView,
      instanceToCells,
      selectedSheetId,
      filteredNodeIds,
    );
  };

  for (const instanceId of instancesIds) {
    const instance = instances[instanceId];
    if (!instance) {
      continue;
    }
    if (
      allowedInstanceTypes.has(instance.type)
      && DrawingAnnotationFiltrationUtils.isValidByOrigin(instance, filterData[AnnotationFilters.Origin])
      && DrawingAnnotationFiltrationUtils.isColorValid(instance, filterData[AnnotationFilters.Color])
      && DrawingAnnotationFiltrationUtils.isUserValid(instance, filterData[AnnotationFilters.Users])
      && isPiaAssigned(instanceId, instance)
      && isReportUsageValid(instanceId, instance.groupId)
    ) {
      result.push(instanceId);
    }
  }
  return result;
}
