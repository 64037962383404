import * as React from 'react';
import { connect } from 'react-redux';

import './classification-simple-object-assignment-list.scss';

import { State } from 'common/interfaces/state';
import { ClassificationPairTile } from '../classification-pair-tile';

interface StateProps {
  classificationsAmount: number;
}

interface OwnProps {
  isDisabled?: boolean;
}

interface Props extends StateProps, OwnProps {

}


export class ClassificationSimpleObjectAssignmentListComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <div
        className='classification-simple-object-assignment-list'
      >
        {this.renderVariants()}
      </div>
    );
  }


  private renderVariants(): React.ReactNode {
    const { classificationsAmount, isDisabled } = this.props;
    const tiles = new Array<React.ReactNode>();
    for (let i = 0; i < classificationsAmount; i++) {
      tiles[i] = (<ClassificationPairTile isDisabled={isDisabled} index={i} key={i}/>);
    }
    return tiles;
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    classificationsAmount: state.classification.elementClassification.length > 0 ?
      state.classification.elementClassification[0].length : 0,
  };
}


const connector = connect(mapStateToProps);
export const ClassificationSimpleObjectAssignmentList = connector(ClassificationSimpleObjectAssignmentListComponent);
