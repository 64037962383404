import { useMemo } from 'react';
import { PiaBreakdownNode } from '../interfaces';

const extendMap = (option: PiaBreakdownNode, map: Record<string, PiaBreakdownNode>): void => {
  map[option.id] = option;
  option.children.forEach(c => extendMap(c, map));
};

export const useOptionMap = (options: PiaBreakdownNode[]): Record<string, PiaBreakdownNode> => {
  return useMemo(() => {
    const map = {};
    options.forEach(o => extendMap(o, map));
    return map;
  }, [options]);
};
