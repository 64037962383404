import * as Lodash from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { ScenariosApi } from '../../../api/scenario';
import { ProjectsActions } from '../../projects/actions/creators/common';
import { ScenariosActions, WorkPackageAction, WorkPackagesActionTypes } from '../actions';
import { WorkPackagesData, WorkPackagesPayload, WorkpackagesReduxState } from '../interfaces';

const createGroup = (
  packages: WorkPackagesData.WorkPackage[],
  activities: WorkPackagesData.Activity[],
): WorkPackagesData.GropedWorkPackage[] => {
  let activityGroupIndex = 0;
  return packages.map((pack): WorkPackagesData.GropedWorkPackage => {
    const activitiesToPack = activities.filter(activity => activity.workpackageid === pack.id);
    const activityGrouped = Lodash.groupBy(activitiesToPack, 'name');
    const activitiesGrouped = Lodash.keys(activityGrouped).map(
      (name: string): WorkPackagesData.GroupOfActivity => {
        return {
          idGroup: activityGroupIndex++,
          nameGroup: name,
          activities: activityGrouped[name],
          count: activityGrouped[name].length,
        };
      },
    );

    const count = activitiesGrouped.reduce(
      (total: number, current): number => total + current.count,
      0,
    );

    return {
      ...pack,
      activitiesGroups: activitiesGrouped,
      count,
    };
  });
};

function* loadData(action: ActionWith<number>): SagaIterator {
  try {
    const data: WorkPackagesData.ApiWorkpackageData = yield call(
      ScenariosApi.getPackages,
      action.payload,
    );

    const grouped = createGroup(data.workpackages, data.activities);

    yield put(WorkPackageAction.loadDataComplete(data.workpackages));
    yield put(WorkPackageAction.setActivities(data.activities));
    yield put(WorkPackageAction.setGrouped(grouped));
  } catch (error) {
    console.error('work package: load data failed', error, action.payload);
  }
}

function* savePackages(action: ActionWith<WorkPackagesPayload.ProjectScnearioIds>): SagaIterator {
  try {
    const { scenarioId } = action.payload;
    yield put(ProjectsActions.setProjectNotFinishedScenarioId(scenarioId));
    const workpackagesState: WorkpackagesReduxState = yield selectWrapper(state => state.workpackages);
    const groupedPackages = workpackagesState.groupedPackages;
    let packages: WorkPackagesData.WorkPackage[] = [];
    let activities: WorkPackagesData.Activity[] = [];

    packages = groupedPackages.map(pack => {
      if (pack.activitiesGroups) {
        pack.activitiesGroups.forEach(group => {
          activities = [...activities, ...group.activities];
        });
      }
      return { id: pack.id, name: pack.name };
    });
    const data: WorkPackagesData.ApiWorkpackageData = { workpackages: packages, activities };
    yield call(ScenariosApi.setPackages, scenarioId, data);
    yield put(WorkPackageAction.setSuccessed());
    yield put(ScenariosActions.setChangeWPSucceeded());
  } catch (error) {
    console.error('work package: save packages failed', error, action.payload);
    yield put(ScenariosActions.setChangeWPSucceeded());
  }
}

export function* workpackageSagas(): SagaIterator {
  yield takeLatest(WorkPackagesActionTypes.LOAD_DATA, loadData);
  yield takeLatest(WorkPackagesActionTypes.SET_REQUEST, savePackages);
}
