import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './chart-line.scss';

import { GanttChartActions } from '../../../actions/creators/gantt-chart';
import { BaseGanttLineBody, BaseWorkPackageName } from '../../../components/gantt-chart';
import { LineData } from '../../../interfaces/gantt-chart';
import { SlackHorizontalCoordinates } from '../../../utils/gantt-chart';
import { colorGenerator } from '../utils/color-generator';
import { isChartLineExpandable } from '../utils/item-expandable';

interface ChartLineOwnProps {
  className?: string;
  data: LineData;
  left: number;
  width: number;
  nestingLevel: number;
  showCriticalPath: boolean;
  slackCoordinates: SlackHorizontalCoordinates;
  saveOuterRangeRef: (lineId: string, ref: HTMLDivElement) => void;
}

interface ChartLineDispatchProps {
  toggleExpandStatus: () => void;
}

interface Props extends ChartLineOwnProps, ChartLineDispatchProps {}

class ChartLineComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const lineClassName = classNames(
      'chart-line',
      'gantt-chart-line',
      this.props.className,
      `nesting-level-${this.props.nestingLevel}`,
    );

    return (
      <div className={lineClassName} onClick={this.onClick}>
        <BaseWorkPackageName
          hideTime={this.props.data.isUndefined}
          data={this.props.data}
          isShowExpandButton={isChartLineExpandable(this.props.data)}
        />
        {!this.props.data.isUndefined && (
          <BaseGanttLineBody
            slackCoordinates={this.props.slackCoordinates}
            className={'gantt-chart-line__body'}
            lineId={this.props.data.lineId}
            left={this.props.left}
            width={this.props.width}
            offColor={colorGenerator.generateOffColor(
              this.props.data.type,
              this.props.data.isCritical && this.props.showCriticalPath,
            )}
            saveOuterRangeRef={this.props.saveOuterRangeRef}
          />
        )}
      </div>
    );
  }

  @autobind
  private onClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    if (isChartLineExpandable(this.props.data)) {
      this.props.toggleExpandStatus();
    }
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  ownProps: ChartLineOwnProps,
): ChartLineDispatchProps => {
  return {
    toggleExpandStatus: () => {
      dispatch(
        GanttChartActions.expandItem(
          ownProps.data.type,
          ownProps.data.entityId,
          ownProps.data.parentLinesIds,
        ),
      );
    },
  };
};

export const ChartLine = connect(null, mapDispatchToProps)(ChartLineComponent);
