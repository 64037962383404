// @flow
/* eslint-disable react/jsx-curly-spacing */
import React from 'react';
import { connect } from 'react-redux';

import Permission from './permission';
import './styles.scss';

class PermissionsList extends React.Component {
  constructor(props: any) {
    super(props);
    this.permissions = [];
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.loadPermissions();
  }

  onChange(id: string, value: number): any {
    const result = this.permissions.findIndex((x: any): any => { return x.permissionType == id });

    if (result == -1) {
      this.permissions.push({ permissionType: id, accessLevel: value });
    } else {
      this.permissions[result].accessLevel = value;
    }
    this.props.onChange(this.permissions);
  }

  render(): any {
    return (
      <div className="permissions-list">
        { this.props.permissions.map((permission: any): any => (
          <Permission key={ permission.id } permission={ permission } onChange={ this.onChange }
                      value={ this.props.value[permission.id] } />
        )) }
      </div>
    );
  }
}

function mapStateToProps(state: any): any {
  return {
    permissions: state.permissions.get('permissions').toJS()
  };
}

const mapDispatchToProps = (dispatch: Function): any => {
  return {
    loadPermissions: (): any => dispatch(Permissions.loadPermissionsList())
  };
};

const ConnectedPermissionsList = connect(mapStateToProps, mapDispatchToProps)(PermissionsList);
const InputPermissionsList = (props: any): any => {
  return (<ConnectedPermissionsList value={ props.input.value } onChange={ props.input.onChange } />);
};

export default InputPermissionsList;
