import { TwoDViewTableType } from '2d/constants/table-type';
import { ReportPageType } from '2d/interfaces';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { TwoDFullScreenMode } from 'persisted-storage/interfaces/state';
import { PersistedStorageSelectors } from 'persisted-storage/selectors/projectTwoDFullScreenSelector';

export const isItemViewVisible = (state: State): boolean => {
  const isMultiViewConnected = state.multiView.connectionStatus !== null;
  if (!state.projects.currentProject) {
    return false;
  }
  const projectId = state.projects.currentProject.id.toString();
  const fullScreenModes = PersistedStorageSelectors.selectTwoDFullScreenMode(state.persistedStorage, projectId);
  const isDrawingFullScreen = fullScreenModes === TwoDFullScreenMode.Drawings;
  const openTabs = new Set(state.twoD.showSheetIds);
  const tabsTypes = arrayUtils.filterMap(
    state.twoD.reportPages,
    (p) => openTabs.has(p.id) && p.type === ReportPageType.MeasureView && !!state.twoD.viewsConfigs[p.id],
    (p) => state.twoD.viewsConfigs[p.id],
  );
  const hasItem = tabsTypes.some(t => t.sourceType === TwoDViewTableType.Items);

  return (isMultiViewConnected || !isDrawingFullScreen) && hasItem;
};
