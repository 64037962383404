import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { getOrCreateRoot } from 'common/UIKit/dialogs/';
import { mathUtils } from 'common/utils/math-utils';
import { MultiLevelSelectConstants } from '../constants';
import { MultilevelSelectOptionData } from '../interfaces/multi-level-select-option-data';
import { MultilevelDropDownContainer } from '../multi-level-select-drop-down-container';
import { MultiLevelSelectOption } from '../multi-level-select-option/multi-level-select-option';

interface Props<T extends MultilevelSelectOptionData<T>> {
  parentOptionRect: ClientRect | DOMRect;
  value: React.ReactText | React.ReactText[];
  variants: T[];
  themeStyle?: boolean;
  onMouseOver: () => void;
  onMouseLeave: () => void;
  onOptionMouseOver?: (value: React.ReactText) => void;
  onOptionMouseOut?: (value: React.ReactText) => void;
  onSelectClick: (value: React.ReactText | React.ReactText[], option: T) => void;
  subContentRenderer: () => React.ReactNode;
  optionContentRenderer?: (option: T, isSelected: boolean, isHighlighted?: boolean) => React.ReactNode;
}

export class MultiLevelOptionDropDown<T extends MultilevelSelectOptionData<T>> extends React.PureComponent<Props<T>> {
  private dropDownRef: HTMLDivElement;

  public render(): React.ReactNode {
    return ReactDOM.createPortal(this.renderContent(), getOrCreateRoot());
  }

  public componentDidMount(): void {
    const { width, height: listHeight } = this.dropDownRef.getBoundingClientRect();
    const { parentOptionRect } = this.props;
    const bottom = window.innerHeight - parentOptionRect.bottom;
    const topSpace = parentOptionRect.top - MultiLevelSelectConstants.margin;
    const bottomSpace = bottom - MultiLevelSelectConstants.margin;
    let top = MultiLevelSelectConstants.margin;
    const maxHeight = topSpace + bottomSpace;
    if (listHeight < maxHeight) {
      top = parentOptionRect.top + (MultiLevelSelectConstants.subvariantHeight - listHeight) / 2;
      top = mathUtils.clamp(
        top,
        MultiLevelSelectConstants.margin,
        window.innerHeight - MultiLevelSelectConstants.margin - listHeight);
    }

    let left = parentOptionRect.right - 5;
    if (window.innerWidth - left - MultiLevelSelectConstants.margin < width) {
      if (window.innerWidth - width < parentOptionRect.right - parentOptionRect.left) {
        left = window.innerWidth - width;
      } else {
        left = parentOptionRect.left + 5 - width;
        if (left < MultiLevelSelectConstants.margin) {
          left = MultiLevelSelectConstants.margin + width;
        }
      }
    }
    this.dropDownRef.style.maxHeight = `${maxHeight}px`;
    this.dropDownRef.style.top = `${top}px`;
    this.dropDownRef.style.left = `${left}px`;
  }


  private renderContent(): React.ReactNode {
    const { variants, onMouseOver, themeStyle, subContentRenderer } = this.props;
    return (
      <MultilevelDropDownContainer
        getDropDownContainerRef={this.saveDropDownContainerRef}
        onMouseOver={onMouseOver}
        themeStyle={themeStyle}
        zIndex={1001}
      >
        {subContentRenderer ? subContentRenderer() : variants.map(this.renderSubVaraint)}
      </MultilevelDropDownContainer>
    );
  }

  @autobind
  private renderSubVaraint(subvariant: T, index: number): React.ReactNode {
    const {
      value,
      optionContentRenderer,
      onSelectClick,
      onOptionMouseOver,
      onOptionMouseOut,
      themeStyle,
    } = this.props;
    const isSelected = value === subvariant.value;
    return (
      <MultiLevelSelectOption
        isSelected={isSelected}
        selectedValue={value}
        key={subvariant.name || index}
        subvariants={subvariant.children}
        value={subvariant.value}
        isSelectable={subvariant.isSelectable}
        onSelectClick={onSelectClick}
        optionContentRenderer={optionContentRenderer}
        onOptionMouseOver={onOptionMouseOver}
        onOptionMouseOut={onOptionMouseOut}
        tooltip={subvariant.name}
        optionData={subvariant}
        themeStyle={themeStyle}
      >
        {optionContentRenderer ? optionContentRenderer(subvariant, isSelected) : subvariant.name}
      </MultiLevelSelectOption>
    );
  }


  @autobind
  private saveDropDownContainerRef(ref: HTMLDivElement): void {
    this.dropDownRef = ref;
  }
}
