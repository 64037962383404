import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Badge } from 'common/components/badge';


export const AiBadge = (): JSX.Element => {
  return (
    <Badge
      badgeText={'AI'}
      BadgeIcon={Icons.AiBadge}
    />
  );
};
