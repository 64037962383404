import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { SliderHorizontal } from 'common/components/slider-horizontal';
import { Text } from 'common/components/text';
import { ElementTooltip } from 'common/components/tooltip';
import { Styled } from './styled';

interface Props {
  minValue: number;
  maxValue: number;
  similarityText?: string;
  similarity: number;
  onChange: (value: number) => void;
}

const SimilaritySliderComponent: React.FC<Props> = ({
  minValue,
  maxValue,
  similarity,
  onChange,
  similarityText = 'Similarity',
}) => {
  const tooltipText = `AI Confidence indicates how accurately the AI has understood your request`;

  const changeOpacity = useCallback(
    (value: number) => {
      onChange(value / 100);
    },
    [onChange],
  );

  return (
    <Styled.Container>
      <Styled.Title>
        <Icons.Similarity2D />
        <Text
          fontSize={14}
          lineHeight={10}
        >
          {similarityText}
        </Text>
        <ElementTooltip
          position='left'
          speed='l'
          text={tooltipText}
        >
          <Icons.Questions2D />
        </ElementTooltip>
      </Styled.Title>
      <Styled.SliderContainer>
        <Text
          fontSize={12}
          lineHeight={10}
        >
          {Math.round(similarity * 100)}%
        </Text>
        <SliderHorizontal
          min={minValue}
          max={maxValue}
          onChange={changeOpacity}
          value={Math.round(similarity * 100)}
        />
      </Styled.SliderContainer>
    </Styled.Container>
  );
};


export const SimilaritySlider = React.memo(SimilaritySliderComponent);
