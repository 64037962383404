import { IconButton, Menu } from '@material-ui/core';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import autobind from 'autobind-decorator';
import * as React from 'react';

import './material-icon-menu.scss';

import { KreoIconAction } from '../icons';

interface Props {
  title?: string;
  iconButtonElement?: React.ReactNode;
  children: React.ReactElement<MenuItemProps> | Array<React.ReactElement<MenuItemProps>>;
  className?: string;
  controlName?: string;
  keepOpen?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

interface State {
  isOpen: boolean;
}

export class MaterialIconMenu extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    title: 'More',
  };
  private anchorEl: HTMLElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  public render(): React.ReactNode {
    const { isOpen } = this.state;
    let button = this.props.iconButtonElement;

    if (!button) {
      button = (
        <IconButton
          aria-owns={this.anchorEl ? 'menu' : null}
          aria-label={this.props.title}
          aria-haspopup={true}
          className='material-icon-menu__icon-button'
        >
          <KreoIconAction />
        </IconButton>
      );
    }

    return (
      <React.Fragment>
        <div
          ref={this.makeRef}
          onClick={this.onIconButtonClick}
          className={this.props.className}
          data-control-name={this.props.controlName}
        >
          {button}
        </div>
        <Menu
          anchorEl={this.anchorEl}
          open={isOpen}
          onClose={this.handleClose}
          onClick={this.onClick}
          onBackdropClick={this.onBackdropClick}
          classes={{
            paper: 'material-icon-menu__paper',
          }}
        >
          {this.props.children}
        </Menu>
      </React.Fragment>
    );
  }

  @autobind
  private makeRef(el: HTMLElement): void {
    this.anchorEl = el;
  }

  @autobind
  private onBackdropClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
  }

  @autobind
  private onIconButtonClick(event: React.MouseEvent<HTMLDivElement>): void {
    this.setState({
      isOpen: true,
    });

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  }

  @autobind
  private handleClose(): void {
    this.setState({
      isOpen: false,
    });
  }

  @autobind
  private onClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    if (!this.props.keepOpen) {
      this.handleClose();
    }
  }
}
