import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { CostEstimateGroupingType } from '../../enums/cost-estimate-grouping-type';
import { CostsTreeNode } from '../../interfaces/bid-pricing/costs-tree-node';
import { AddChildrenPayload } from '../payloads/costs';
import { CostsActionTypes } from '../types/costs';

function dropCostData(): Action {
  return {
    type: CostsActionTypes.COST_DROP_DATA,
  };
}

function changeField(path: any, value: any): ActionWith<any> {
  return {
    type: CostsActionTypes.COST_CHANGE_FIELD,
    payload: {
      path,
      value,
    },
  };
}

function getIndirectCost(calculationId: number): ActionWith<number> {
  return {
    type: CostsActionTypes.COST_GET_INDIRECT_DATA,
    payload: calculationId,
  };
}

function saveIndirectData(data: any): ActionWith<any> {
  return {
    type: CostsActionTypes.COST_GET_INDIRECT_DATA_SUCCESSED,
    payload: data,
  };
}

function getCostEstimateRoots(id: number, type: CostEstimateGroupingType): ActionWith<any> {
  return {
    type: CostsActionTypes.COST_GET_COST_ESTIMATE_ROOTS_REQUEST,
    payload: { id, type },
  };
}

function getCostEstimateRootsSucceeded(data: any): ActionWith<any> {
  return {
    type: CostsActionTypes.COST_GET_COST_ESTIMATE_ROOTS_SUCCEEDED,
    payload: data,
  };
}

function getLoadCalcsSuccessed(data: any): ActionWith<any> {
  return {
    type: CostsActionTypes.COST_LOAD_CALCULATIONS_SUCCESSED,
    payload: data,
  };
}

function getCostEstimateSucceeded(data: CostsTreeNode[]): ActionWith<CostsTreeNode[]> {
  return {
    type: CostsActionTypes.COST_GET_COST_ESTIMATE_SUCCEEDED,
    payload: data,
  };
}

function collapseExpand(item: any): ActionWith<any> {
  return {
    type: CostsActionTypes.COST_COLLAPSE_EXPAND,
    payload: item,
  };
}

function collapse(path: any): ActionWith<any> {
  return {
    type: CostsActionTypes.COST_COLLAPSE,
    payload: path,
  };
}

function expand(path: any): ActionWith<any> {
  return {
    type: CostsActionTypes.COST_EXPAND,
    payload: path,
  };
}

function addChilds(path: string[], childs: CostsTreeNode[]): ActionWith<AddChildrenPayload> {
  return {
    type: CostsActionTypes.COST_ADD_CHILD,
    payload: {
      path,
      childs,
    },
  };
}

function changeFieldCommit(pathes: any): ActionWith<any> {
  return {
    type: CostsActionTypes.COST_CHANGE_FIELD_COMMIT,
    payload: pathes,
  };
}

function loadExcelReportTypes(): Action {
  return {
    type: CostsActionTypes.COST_GET_EXCEL_REPORT_TYPES_REQUEST,
  };
}

function loadExcelReportTypesSuccessed(types: any): ActionWith<any> {
  return {
    type: CostsActionTypes.COST_GET_EXCEL_REPORT_TYPES_SUCCESSED,
    payload: types,
  };
}

function exportExcelReport(selectedReports: string[]): ActionWith<string[]> {
  return {
    type: CostsActionTypes.COST_EXPORT_EXCEL_REPORT,
    payload: selectedReports,
  };
}

export const CostsActions = {
  dropCostData,
  changeField,
  getIndirectCost,
  saveIndirectData,
  getCostEstimateRoots,
  getCostEstimateRootsSucceeded,
  getLoadCalcsSuccessed,
  getCostEstimateSucceeded,
  collapseExpand,
  collapse,
  expand,
  addChilds,
  changeFieldCommit,
  loadExcelReportTypes,
  loadExcelReportTypesSuccessed,
  exportExcelReport,
};
