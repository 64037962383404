import { SubscriptionType } from 'common/constants/subscription';
import { AppUrls } from 'routes/app-urls';

export function getUrlByLastUsedProductType(lastUsedProductType: SubscriptionType): string {
  switch (lastUsedProductType) {
    case SubscriptionType.Takeoff2d:
      return AppUrls.qto2d.index.path;
    case SubscriptionType.Takeoff3d:
      return AppUrls.qto3d.index.path;
    case SubscriptionType.CostEstimate:
      return AppUrls.costEstimate.index.path;
    case SubscriptionType.KreoPlan:
      return AppUrls.plan.index.path;
    default:
      console.error(`Unexpected product type. Last used product type: ${lastUsedProductType}`);
      return AppUrls.qto2d.index.path;
  }
}
