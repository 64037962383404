import * as t from 'io-ts';

import { KreoProductC } from 'common/enums/kreo-product';
import { BillingModelC } from './billing-model';

export const SubscriptionAddonC = t.intersection(
  [
    BillingModelC,
    t.type(
      {
        groupName: t.string,
        id: t.string,
        name: t.string,
        products: t.array(KreoProductC),
        isSelfService: t.boolean,
        // isEnableInTrial: t.boolean,
      }),
    t.partial({
      description: t.string,
    }),
  ],
  'SubscriptionPlan');

export type SubscriptionAddon = t.TypeOf<typeof SubscriptionAddonC>;
export const SubscriptionAddonsC = t.array(SubscriptionAddonC);

