import * as React from 'react';


export const KreoIconArrowUp: React.FC = () => {
  return (
    <svg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='icon_down' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M6.5,4.20710678 L6.5,9 C6.5,9.27614237 6.27614237,9.5 6,9.5 C5.72385763,9.5 5.5,9.27614237 5.5,9
          L5.5,4.20710678 L4.35355339,5.35355339 C4.15829124,5.54881554 3.84170876,5.54881554 3.64644661,5.35355339
          C3.45118446,5.15829124 3.45118446,4.84170876 3.64644661,4.64644661 L5.64644661,2.64644661
          C5.84170876,2.45118446 6.15829124,2.45118446 6.35355339,2.64644661 L8.35355339,4.64644661
          C8.54881554,4.84170876 8.54881554,5.15829124 8.35355339,5.35355339 C8.15829124,5.54881554
          7.84170876,5.54881554 7.64644661,5.35355339 L6.5,4.20710678 Z'
          id='Combined-Shape'
          fill='#FE5B45'
          fillRule='nonzero'
        />
      </g>
    </svg >
  );
};
