import styled from 'styled-components';

const AvatarContainer = styled.div`
  background-color: ${p => p.theme.color.background};
  border-radius: 10px;

  > div {
    border-radius: 10px;
  }
`;


export const Styled = {
  AvatarContainer,
};
