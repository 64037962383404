export enum ComponentDisplayType {
  Normal = '',
  Active = 'active',
  ActiveDropTarget = 'active-drop-target',
  Hover = 'hover',
  Grab = 'grab',
  Dragging = 'dragging',
  DragHover = 'drag-hover',
  Disabled = 'disabled',
  Selected = 'selected',
  WasChanged = 'was-changed',
}
