import classNames from 'classnames';
import * as React from 'react';

import { ControlNames } from 'common/constants/control-names';
import { IconButton } from '../../buttons';
import { KreoIconCancel } from '../../icons';

interface Props {
  onClose: () => void;
  className: string | undefined;
}

export const TitleContainer: React.FC<Props> = (props) => {
  return (
    <div className={classNames('kreo-dialog__title', props.className)}>
      <h2 className='kreo-dialog__title__text'>
        {props.children}
      </h2>
      <IconButton
        size='medium'
        onClick={props.onClose}
        controlName={ControlNames.dialogCloseButton}
      >
        <KreoIconCancel />
      </IconButton>
    </div>
  );
};
