import { useCallback, useEffect, useRef, useState } from 'react';
import { DrawingsViewportHelper } from 'common/components/drawings/helpers/viewport';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { DrawingsUtils } from 'common/components/drawings/utils/drawings-utils';
import { mathUtils } from 'common/utils/math-utils';
import { PreviewItemStyled } from '../preview-item';
import { PreviewCanvasState } from './interfaces';

const MAX_ZOOM_VALUE = 5;
const MIN_ZOOM_VALUE = 0.01;

interface UsePreviewStatePayload {
  resultSize: { width: number, height: number };
  viewportHelper: DrawingsViewportHelper;
  items: unknown[];
  drawingInfo: DrawingsShortInfo;
}

export function usePreviewState(
  {
    resultSize,
    viewportHelper,
    items,
    drawingInfo,
  }: UsePreviewStatePayload,
): PreviewCanvasState {
  const renderId = useRef<string | number>();
  const itemsCount = useRef<number>(0);
  const [canvasState, setCanvasState] = useState<PreviewCanvasState>(null);
  const loadCanvas = useCallback(() => {
    const { width, height } = resultSize;
    const max = Math.max(width, height);
    const zoom = mathUtils.clamp(
      PreviewItemStyled.PREVIEW_SIZE / max,
      MIN_ZOOM_VALUE,
      MAX_ZOOM_VALUE,
    );
    const pdfDocument = viewportHelper.drawingInfo.pdfDocument;
    if (Number.isInteger(renderId.current)) {
      pdfDocument.unloadCanvasResources(renderId.current as number);
      pdfDocument.cancelLoadCanvas(renderId.current as number);
    }
    renderId.current = pdfDocument.loadCanvas({
      zoom,
      pageNumber: DrawingsUtils.getPDFPageNumber(viewportHelper.drawingInfo.pageNumber),
      drawComplete: (canvas: HTMLCanvasElement) => {
        const { width: drawingWidth } = drawingInfo;
        renderId.current = null;
        setCanvasState({
          canvas,
          zoom,
          context: canvas.getContext('2d'),
          browserScale: canvas.width / (drawingWidth * zoom),
        });
      },
    });
  }, [resultSize, viewportHelper, renderId]);


  useEffect(() => {
    if (items.length && !itemsCount.current) {
      loadCanvas();
    }
    itemsCount.current = items.length;
  }, [items.length, itemsCount, loadCanvas]);
  return canvasState;
}
