import { useCallback } from 'react';

import { useActionDispatch } from 'common/hooks';
import { CommentaryTargetType } from 'unit-2d-comments/enums';
import { CommentaryDrawingTarget, CommentaryDrawingWithPointTarget } from 'unit-2d-comments/interfaces';
import { TwoDCommentsActions } from 'unit-2d-comments/store-slice';
import { useCurrentDrawingInfo } from '../../hooks';
import { DrawingContextMenuPosition } from '../../interfaces';

export function useAddComment(position: DrawingContextMenuPosition): () => void {
  const startAddComment = useActionDispatch(TwoDCommentsActions.startAddComment);
  const currentDrawing = useCurrentDrawingInfo();
  return useCallback(() => {
    const point = position?.drawingPoint;
    if (point) {
      startAddComment({
        type: CommentaryTargetType.DrawingWithPoint,
        pageId: currentDrawing?.drawingId,
        x: point.x,
        y: point.y,
      } as CommentaryDrawingWithPointTarget);
    } else {
      startAddComment({
        type: CommentaryTargetType.Drawing,
        pageId: currentDrawing?.drawingId,
      } as CommentaryDrawingTarget);
    }
  }, [position, currentDrawing?.drawingId, startAddComment]);
}
