import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DatabaseRouteParams } from 'routes/common/database';
import { DatabaseActivityListing } from '../../components/database-activity-listing';

interface Props extends RouteComponentProps<DatabaseRouteParams> {}

export class DatabaseActivityListingPage extends React.Component<Props> {
  public render(): JSX.Element {
    const databaseId = +this.props.match.params.dbId;

    return (
      <DatabaseActivityListing
        databaseId={databaseId}
        isCheckboxesVisible={false}
      />
    );
  }
}
