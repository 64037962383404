import { DrawingMarkShapes, DrawingStrokeStyles } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import {
  DrawingsCountGeometry,
  DrawingsGeometryInstance,
  DrawingsGeometryInstanceWithId,
  DrawingsPolylineGeometry,
  ShortPointDescription,
} from 'common/components/drawings/interfaces';
import { DrawingAnnotationNamingUtils } from 'common/components/drawings/utils/drawing-annotation-naming-utils';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { CollectionsUnifier } from '../collections-unifier';

interface JoinPayload {
  drawingId: string;
  instancesIds: string[];
  getInstance: (id: string) => DrawingsGeometryInstance;
  getPoint: (id: string) => ShortPointDescription;
}

interface JoinResult {
  instance: DrawingsGeometryInstance;
  newPoints: Record<string, ShortPointDescription>;
}

function joinCount(
  {
    instancesIds,
    getInstance,
    getPoint,
    drawingId,
  }: JoinPayload,
): JoinResult {
  const points = {};
  const name = DrawingAnnotationNamingUtils.getDefaultGeometryName(DrawingsInstanceType.Count);
  const unifier = new CollectionsUnifier<{ groupId: string, shape: DrawingMarkShapes, color: string }>();

  for (const id of instancesIds) {
    const instance = getInstance(id) as DrawingsGeometryInstance<DrawingsCountGeometry>;
    unifier.add({ groupId: instance.groupId, shape: instance.geometry.shape, color: instance.geometry.color });
    for (const pointId of instance.geometry.points) {
      points[UuidUtil.generateUuid()] = getPoint(pointId);
    }
  }
  const { groupId, shape, color } = unifier.getUnifiedOrDefault({
    groupId: undefined,
    shape: DrawingMarkShapes.Circle,
    color: DrawingsCanvasUtils.getColorFromList(),
  });
  const newInstance: DrawingsGeometryInstanceWithId = {
    id: UuidUtil.generateUuid(),
    drawingId,
    name,
    type: DrawingsInstanceType.Count,
    geometry: {
      points: Object.keys(points),
      shape,
      color,
    },
    groupId,
    isAuto: false,
  };
  return {
    instance: newInstance,
    newPoints: points,
  };
}

interface LineUnifierProps {
  groupId: string;
  strokeWidth: number;
  strokeStyle: DrawingStrokeStyles;
  color: string;
  height?: number;
  thickness?: number;
}

enum DistancesSource {
  Start,
  End,
}

const Sources = [
  {
    from: DistancesSource.Start,
    to: DistancesSource.Start,
  },
  {
    from: DistancesSource.Start,
    to: DistancesSource.End,
  },
  {
    from: DistancesSource.End,
    to: DistancesSource.Start,
  },
  {
    from: DistancesSource.End,
    to: DistancesSource.End,
  },
];

interface LineEndsInfo {
  startPoint: ShortPointDescription;
  endPoint: ShortPointDescription;
  instance: DrawingsGeometryInstance<DrawingsPolylineGeometry>;
  instanceId: string;
}

interface DistanceInfo {
  distance: number;
  from: DistancesSource;
  to: DistancesSource;
}

function getDistances(
  startPoint: ShortPointDescription,
  instanceStart: ShortPointDescription,
  instanceEnd: ShortPointDescription,
  endPoint: ShortPointDescription,
): number[] {
  const startToStart = DrawingsCanvasUtils.calculateLineLength(startPoint, instanceStart);
  const endToEnd = DrawingsCanvasUtils.calculateLineLength(instanceEnd, endPoint);
  const startToEnd = DrawingsCanvasUtils.calculateLineLength(startPoint, instanceEnd);
  const endToStart = DrawingsCanvasUtils.calculateLineLength(instanceStart, endPoint);
  const currentDistances = [startToStart, startToEnd, endToStart, endToEnd];
  return currentDistances;
}

interface InstanceDistance extends DistanceInfo {
  fromId: string;
  toId: string;
}

interface GetDistancePayload {
  instancesIds: string[];
  getInstance: (id: string) => DrawingsGeometryInstance;
  getPoint: (id: string) => ShortPointDescription;
  unifier?: CollectionsUnifier<LineUnifierProps>;
}

function getInstancesDistanceInfo(
  {
    instancesIds,
    getInstance,
    getPoint,
    unifier,
  }: GetDistancePayload,
): { distances: InstanceDistance[], instancesEnds: LineEndsInfo[] } {
  const instancesEnds = new Array<LineEndsInfo>();
  const distances = new Array<InstanceDistance>();
  for (const instanceId of instancesIds) {
    const instance = getInstance(instanceId) as DrawingsGeometryInstance<DrawingsPolylineGeometry>;
    if (unifier) {
      unifier.add({
        groupId: instance.groupId,
        strokeWidth: instance.geometry.strokeWidth,
        strokeStyle: instance.geometry.strokeStyle,
        color: instance.geometry.color,
        height: instance.geometry.height,
        thickness: instance.geometry.thickness,
      });
    }
    const points = instance.geometry.points;
    const currentInstanceEnds = {
      instance,
      startPoint: getPoint(points[0]),
      endPoint: getPoint(points[points.length - 1]),
      instanceId,
    };
    for (const instanceEnd of instancesEnds) {
      const currentDistances = getDistances(
        currentInstanceEnds.startPoint,
        instanceEnd.startPoint,
        instanceEnd.endPoint,
        currentInstanceEnds.endPoint,
      );
      for (let i = 0; i < 4; i++) {
        const source = Sources[i];
        distances.push({
          distance: currentDistances[i],
          fromId: instanceId,
          toId: instanceEnd.instanceId,
          ...source,
        });
      }
    }
    instancesEnds.push(currentInstanceEnds);
  }

  distances.sort((a, b) => a.distance - b.distance);
  return {
    distances,
    instancesEnds,
  };
}

function getSequencies(
  {
    distances,
    instancesEnds,
  }: { distances: InstanceDistance[], instancesEnds: LineEndsInfo[] },
): LineEndsInfo[][] {
  const instancesQueues = {};
  const queues = new Array<LineEndsInfo[]>();

  while (distances.length) {
    const distance = distances.shift();
    const { fromId, toId, from, to } = distance;
    const fromQueue = instancesQueues[fromId];
    const toQueue = instancesQueues[toId];
    if (!fromQueue && !toQueue) {
      const queueIndex = queues.push([instancesEnds.find(x => x.instanceId === fromId)]);
      instancesQueues[fromId] = queueIndex;
      instancesQueues[toId] = queueIndex;
      const toInstanceEnds = instancesEnds.find(x => x.instanceId === toId);
      queues[queueIndex - 1].push(toInstanceEnds);
    } else if (fromQueue && !toQueue) {

      instancesQueues[toId] = fromQueue;
      const queueIndex = fromQueue - 1;
      const toInstanceEnds = instancesEnds.find(x => x.instanceId === toId);
      const fromIndexInQueue = queues[queueIndex].findIndex(x => x.instanceId === fromId);
      if (fromIndexInQueue === 0) {
        queues[queueIndex].unshift(toInstanceEnds);
      } else {
        queues[queueIndex].push(toInstanceEnds);
      }
    } else if (!fromQueue && toQueue) {

      instancesQueues[fromId] = toQueue;
      const queueIndex = toQueue - 1;
      const fromInstanceEnds = instancesEnds.find(x => x.instanceId === fromId);
      const toIndexInQueue = queues[queueIndex].findIndex(x => x.instanceId === toId);
      if (toIndexInQueue === 0) {
        queues[queueIndex].unshift(fromInstanceEnds);
      } else {
        queues[queueIndex].push(fromInstanceEnds);
      }
    }
    distances = distances.filter(x => {
      if (
        x.fromId === fromId && x.toId === toId
        || x.fromId === toId && x.toId === fromId
        || x.fromId === fromId && x.from === from
        || x.toId === fromId && x.to === from
        || x.toId === toId && x.to === to
        || x.fromId === toId && x.from === to
      ) {
        return false;
      }
      return true;
    });
  }
  return queues;
}

interface JoinSequenciesPayload {
  instancesQueue: LineEndsInfo[];
  getPoint: (id: string) => ShortPointDescription;
  unifier?: CollectionsUnifier<LineUnifierProps>;
  drawingId: string;
}

function sequenceToInstance(
  {
    instancesQueue,
    getPoint,
    unifier,
    drawingId,
  }: JoinSequenciesPayload,
): JoinResult {
  const points: Record<string, ShortPointDescription> = {};
  const instancePoints = [];
  for (let i = 0; i < instancesQueue.length; i++) {
    const instanceEnds = instancesQueue[i];
    const { instance } = instanceEnds;
    const pointsIds = instance.geometry.points.slice();
    if (i === 0) {
      const startPoint = getPoint(pointsIds[0]);
      const endPoint = getPoint(pointsIds[pointsIds.length - 1]);
      const nextInstance = instancesQueue[i + 1];
      const nextInstanceStartPoint = getPoint(nextInstance.instance.geometry.points[0]);
      const nextInstanceEndPoint = getPoint(
        nextInstance.instance.geometry.points[nextInstance.instance.geometry.points.length - 1],
      );
      const distancesToNext = getDistances(startPoint, nextInstanceStartPoint, nextInstanceEndPoint, endPoint);
      const minDistanceIndex = distancesToNext.indexOf(Math.min(...distancesToNext));
      const sources = Sources[minDistanceIndex];
      if (sources.from === DistancesSource.Start) {
        pointsIds.reverse();
      }
    } else if (i !== 0) {
      const lastPoint = points[instancePoints[instancePoints.length - 1]];
      const instanceFirstPoint = getPoint(pointsIds[0]);
      const instanceLastPoint = getPoint(pointsIds[pointsIds.length - 1]);
      const isReversed = DrawingsCanvasUtils.calculateLineLength(lastPoint, instanceFirstPoint)
        > DrawingsCanvasUtils.calculateLineLength(lastPoint, instanceLastPoint);
      if (isReversed) {
        pointsIds.reverse();
      }
      const [firstPointX, firstPointY ] = isReversed ? instanceLastPoint : instanceFirstPoint;
      if (firstPointX === lastPoint[0] && firstPointY === lastPoint[1]) {
        pointsIds.shift();
      }
    }

    for (const pointId of pointsIds) {
      const newPointId = UuidUtil.generateUuid();
      points[newPointId] = getPoint(pointId);
      instancePoints.push(newPointId);
    }
  }
  const { groupId, strokeWidth, strokeStyle, color, height, thickness } = unifier.getUnifiedOrDefault({
    groupId: undefined,
    strokeWidth: 1,
    strokeStyle: DrawingStrokeStyles.Normal,
    color: DrawingsCanvasUtils.getColorFromList(),
  });
  const newInstance: DrawingsGeometryInstanceWithId = {
    id: UuidUtil.generateUuid(),
    drawingId,
    name: DrawingAnnotationNamingUtils.getDefaultGeometryName(DrawingsInstanceType.Polyline),
    type: DrawingsInstanceType.Polyline,
    geometry: {
      points: Object.keys(points),
      strokeStyle,
      strokeWidth,
      color,
    },
    groupId,
    isAuto: false,
  };
  if (height) {
    (newInstance.geometry as DrawingsPolylineGeometry).height = height;
  }
  if (thickness) {
    (newInstance.geometry as DrawingsPolylineGeometry).thickness = thickness;
  }
  return {
    instance: newInstance,
    newPoints: points,
  };
}

function joinPolyline(
  {
    instancesIds,
    getInstance,
    getPoint,
    drawingId,
  }: JoinPayload,
): JoinResult {
  const unifier = new CollectionsUnifier<LineUnifierProps>();
  const distancesInfo = getInstancesDistanceInfo({
    instancesIds,
    getInstance,
    getPoint,
    unifier,
  });
  let sequencies = getSequencies(distancesInfo);
  let points = {};
  while (sequencies.length > 1) {
    let newPoints = {};
    const instances: Record<string, DrawingsGeometryInstanceWithId> = {};
    for (const sequence of sequencies) {
      const { instance, newPoints: sequencePoints } = sequenceToInstance({
        instancesQueue: sequence,
        getPoint: (id: string) => points[id] || getPoint(id),
        unifier,
        drawingId,
      });
      instances[instance.id] = instance;
      newPoints = { ...newPoints, ...sequencePoints };
    }
    const newInstancesIds = Object.keys(instances);
    const newDistancesInfo = getInstancesDistanceInfo({
      instancesIds: newInstancesIds,
      getInstance: (id: string) => instances[id],
      getPoint: (id: string) => newPoints[id] || getPoint(id),
    });
    const newSequencies = getSequencies(newDistancesInfo);
    points = { ...points, ...newPoints };
    sequencies = newSequencies;
  }
  return sequenceToInstance({
    instancesQueue: sequencies[0],
    getPoint: id => points[id] || getPoint(id),
    unifier,
    drawingId,
  });
}

export const JoinUtils = {
  joinCount,
  joinPolyline,
};


