import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconElementObject: React.FC = () => {
  return (
    <svg width='44px' height='44px' viewBox='0 0 44 44' version='1.1'>
      <g id='Icons/44x44/Object' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          // eslint-disable-next-line max-len
          d='M21.5,36.8333333 L21.5,22.0767627 C21.3712297,22.0312256 21.244826,21.9766223 21.1217312,21.9129526 L8.5,15.3844709 L8.5,29.3 C8.5,29.669312 8.70354828,30.0085591 9.02941176,30.1823529 L21.5,36.8333333 Z M23.5,36.8333333 L35.9705882,30.1823529 C36.2964517,30.0085591 36.5,29.669312 36.5,29.3 L36.5,15.3844709 L23.8782688,21.9129526 C23.755174,21.9766223 23.6287703,22.0312256 23.5,22.0767627 L23.5,36.8333333 Z M9.42066703,13.6089776 L22.0405771,20.1365173 C22.3287272,20.2855604 22.6712728,20.2855604 22.9594229,20.1365173 L35.579333,13.6089776 L22.9705882,6.88431373 C22.6764706,6.72745098 22.3235294,6.72745098 22.0294118,6.88431373 L9.42066703,13.6089776 Z M23.9117647,5.11960784 L36.9117647,12.0529412 C37.8893552,12.5743228 38.5,13.5920641 38.5,14.7 L38.5,29.3 C38.5,30.4079359 37.8893552,31.4256772 36.9117647,31.9470588 L23.9117647,38.8803922 C23.0294118,39.3509804 21.9705882,39.3509804 21.0882353,38.8803922 L8.08823529,31.9470588 C7.11064483,31.4256772 6.5,30.4079359 6.5,29.3 L6.5,14.7 C6.5,13.5920641 7.11064483,12.5743228 8.08823529,12.0529412 L21.0882353,5.11960784 C21.9705882,4.64901961 23.0294118,4.64901961 23.9117647,5.11960784 Z'
          id='Shape'
          fill={KreoColors.blue}
          fillRule='nonzero'
        />
      </g>
    </svg>);
};
