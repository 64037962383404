import { Ability, AbilityBuilder } from '@casl/ability';

import { BoolFeatureFlag } from 'common/ability/bool-feature-flag';
import { FeatureFlagService } from 'common/ability/feature-flag-service';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { KreoProduct } from 'common/enums/kreo-product';
import { PermissionActivity } from 'common/enums/permission-activity';
import { PermissionState } from 'common/enums/permission-state';
import { RoleGroup } from 'common/enums/role-group';
import { CompanySubscriptionModel } from '../../units/account/interfaces/company-subscription-model';
import { SubscriptionStatus } from '../../units/subscription/enums/subscription-status';
import { StringUtil } from '../../utils/string';

Ability.addAlias(Operation.Manage, [
  Operation.Create,
  Operation.Read,
  Operation.Update,
  Operation.Delete,
  Operation.Duplicate,
]);

export class UserAbility {
  private readonly ability: Ability;

  constructor(
    isAdmin: boolean,
    isOwner: boolean,
    featureFlagService: FeatureFlagService,
    subscription: CompanySubscriptionModel | null,
  ) {
    const { rules, can, cannot } = AbilityBuilder.extract();
    const rolePermissions = subscription && subscription.userRolePermissions;
    const roleGroup = subscription && subscription.userRoleGroup;
    const subscriptionStatus = subscription && subscription.status;
    this.everyUserFeatureFlagPermissions(can, featureFlagService);
    if (isOwner) {
      can(Operation.Manage, Subject.Subscriptions);
      can(Operation.Delete, Subject.Project);
      if (subscription && subscription.isSelfService) {
        can(Operation.Manage, Subject.Billing);
      }
    }
    if (!rolePermissions && !isAdmin) {
      this.ability = new Ability(rules);
      return;
    }

    if (roleGroup !== RoleGroup.Guest) {
      can(Operation.Read, Subject.ProposeExtendSubscription);
      can(Operation.Manage, Subject.Takeoff2dPdfFilter);
    }

    if (rolePermissions) {
      // methods for setting abilities
      const permissions = {};
      Object.keys(rolePermissions).map(key => permissions[StringUtil.capitalize(key)] = rolePermissions[key]);

      const products = subscription ? subscription.products : [];

      if (permissions[PermissionActivity.AccountManagement] === PermissionState.Enabled) {
        this.activateAccountManagmentPermissions(can, subscription, cannot);
      }

      if (permissions[PermissionActivity.ProjectManagement] === PermissionState.Enabled) {
        this.activateProjectManagmentPermissions(can, subscription, cannot);
      }

      if (permissions[PermissionActivity.ProjectAccessManagement] === PermissionState.Enabled) {
        can(Operation.Manage, Subject.ProjectAdministration);
      }

      if (!subscription) {
        cannot(Operation.Delete, Subject.Project);
      }

      if (products.includes(KreoProduct.Qto2D)) {
        can(Operation.Manage, Subject.QuantityTakeOff2d);
      }

      if (products.includes(KreoProduct.Plan) || products.includes(KreoProduct.BillOfQuantities)) {
        if (permissions[PermissionActivity.ProjectManagement] === PermissionState.Enabled) {
          can(Operation.Read, Subject.ValidationModelCheck);
        }

        if (permissions[PermissionActivity.ValidationModelCheck] === PermissionState.Enabled) {
          can(Operation.Read, Subject.ValidationModelCheck);
        }
      }

      if (products.includes(KreoProduct.Plan)) {
        this.planPermissions(permissions, can, roleGroup);
      }

      if (products.includes(KreoProduct.Database)) {
        this.databasePermissions(permissions, can, roleGroup);
      }

      if (products.includes(KreoProduct.CostEstimate)) {
        this.costEstimatePermissions(permissions, can, roleGroup);
      }

      if (products.includes(KreoProduct.BillOfQuantities)) {
        can(Operation.Read, Subject.QuantityTakeOff3d);
      }

      if (products.includes(KreoProduct.Qto2D)) {
        if (permissions[PermissionActivity.Takeoff2DMeasurement] === PermissionState.View) {
          can(Operation.Read, Subject.Takeoff2DReport);
        }

        if (permissions[PermissionActivity.Takeoff2DMeasurement] === PermissionState.Edit) {
          can(Operation.Manage, Subject.Takeoff2DReport);
        }

        if (permissions[PermissionActivity.Takeoff2DReport] === PermissionState.View) {
          can(Operation.Read, Subject.Takeoff2DMeasurement);
        }

        if (permissions[PermissionActivity.Takeoff2DReport] === PermissionState.Edit) {
          can(Operation.Manage, Subject.Takeoff2DMeasurement);
        }
      }

      if (products.includes(KreoProduct.Takeoff2dAutoMeasure)) {
        can(Operation.Manage, Subject.Takeoff2DAutoMeasure);
      }

      if (products.includes(KreoProduct.Takeoff2dMeasurement3d)) {
        can(Operation.Manage, Subject.Takeoff2dMeasurement3d);
      }

      if (products.includes(KreoProduct.Takeoff2dAnnotations)) {
        if (permissions[PermissionActivity.Takeoff2DMeasurement] === PermissionState.Edit) {
          can(Operation.Manage, Subject.Takeoff2dAnnotations);
        } else {
          can(Operation.Read, Subject.Takeoff2dAnnotations);
        }
      }

      if (products.includes(KreoProduct.Project2DTemplates)) {
        if (permissions[PermissionActivity.ProjectManagement] === PermissionState.Enabled) {
          can(Operation.Manage, Subject.Project2DTemplates);
        }
      }

      if (products.includes(KreoProduct.Takeoff2dReportTemplate)) {
        can(Operation.Manage, Subject.Takeoff2dReportTemplate);
      }

      if (products.includes(KreoProduct.Takeoff2dGroupTemplates)) {
        can(Operation.Manage, Subject.Takeoff2dGroupTemplates);
      }

      if (products.includes(KreoProduct.Takeoff2dPdfExport)) {
        can(Operation.Manage, Subject.Takeoff2dPdfExport);
      }

      if (products.includes(KreoProduct.TakeOff2dFileConverter)) {
        can(Operation.Manage, Subject.TakeOff2dFileConverter);
      }

      if (products.includes(KreoProduct.Takeoff2dFileOptimizer)) {
        if (roleGroup !== RoleGroup.Guest) {
          can(Operation.Manage, Subject.Takeoff2dFileOptimizer);
        }
      }

      if (products.includes(KreoProduct.Takeoff2dExcelImport)) {
        can(Operation.Manage, Subject.Takeoff2dExcelImport);
      }

      if (products.includes(KreoProduct.Takeoff2dAiSuggest)) {
        can(Operation.Manage, Subject.Takeoff2dAiSuggest);
      }

      if (products.includes(KreoProduct.Takeoff2dAutoCount)) {
        can(Operation.Manage, Subject.Takeoff2dAutoCount);
      }
      if (products.includes(KreoProduct.Project2DPagesCompare)) {
        can(Operation.Manage, Subject.Project2DPagesCompare);
      }
      if (products.includes(KreoProduct.Takeoff2dAutoScale)) {
        can(Operation.Manage, Subject.Takeoff2dAutoScale);
      }

      if (products.includes(KreoProduct.Takeoff2dCaddie)) {
        can(Operation.Manage, Subject.Takeoff2dCaddie);
      }

      if (products.includes(KreoProduct.Comments)) {
        if (roleGroup !== RoleGroup.Guest) {
          can(Operation.Manage, Subject.Comment2d);
        }
      }

      if (roleGroup !== RoleGroup.Guest) {
        can(Operation.Preview, Subject.Takeoff2dAnnotations);
        can(Operation.Manage, Subject.Takeoff2dPageColor);
      }

      if (featureFlagService) {
        this.featureFlagPermissions(
          can,
          cannot,
          featureFlagService,
          products,
          permissions,
          roleGroup,
          subscriptionStatus,
        );
      }

      this.piaDatabasePermissions(can, roleGroup, cannot, products);
    }

    if (isAdmin) {
      can(Operation.Admin, Subject.Application);
      can(Operation.Read, Subject.AdminPanel);
    }

    if (roleGroup !== RoleGroup.Guest) {
      can(Operation.Manage, Subject.ShowCommentaries);
    }


    this.ability = new Ability(rules);
  }

  /**
   * method for checking ability presence
   */
  public can(operation: Operation, subject: Subject, field?: string): boolean {
    return this.ability.can(operation, subject, field);
  }

  /**
   * method for checking ability absence
   */
  public cannot(
    operation: Operation,
    subject: Subject,
    field?: string,
  ): boolean {
    return this.ability.cannot(operation, subject, field);
  }

  private everyUserFeatureFlagPermissions(can: any, featureFlagService: FeatureFlagService): void {
    if (!featureFlagService) {
      return null;
    }
    if (featureFlagService.isEnabled(BoolFeatureFlag.UseOldPaymentUI)) {
      can(Operation.Read, Subject.OldPaymentUI);
    }
    if (featureFlagService.isEnabled(BoolFeatureFlag.FeatureFlagOnlyAnnualPlans)) {
      can(Operation.Manage, Subject.BillingPeriod);
    }
  }

  private featureFlagPermissions(
    can: any,
    cannot: any,
    featureFlagService: FeatureFlagService,
    products: KreoProduct[],
    permissions: Record<string, PermissionState>,
    roleGroup: RoleGroup,
    subscriptionStatus: SubscriptionStatus,
  ): void {
    if (subscriptionStatus === SubscriptionStatus.Valid)
      can(Operation.Read, Subject.Application);


    // todo: add condition for case when user already bought that database
    if (featureFlagService.isEnabled(BoolFeatureFlag.BuyKreoProvidedDb)) {
      can(Operation.Buy, Subject.KreoProvidedDb);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AccessQtoReport)) {
      can(Operation.Read, Subject.QuantityTakeOffReport);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AccessModelManagement)) {
      can(Operation.Read, Subject.ModelManagement);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AccessQtoCustomFilters)) {
      can(Operation.Read, Subject.QuantityTakeOffCustomFilters);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AccessSmartLook)) {
      can(Operation.Read, Subject.SmartLook);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.OpenReplay)) {
      can(Operation.Manage, Subject.OpenReplay);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.ImportSchedules2D)) {
      can(Operation.Read, Subject.ImportSchedules2D);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.DemoProjects2d) && roleGroup !== RoleGroup.Guest) {
      can(Operation.Manage, Subject.DemoProject);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.RemindLaterTakeSurvey) && roleGroup !== RoleGroup.Guest) {
      can(Operation.Manage, Subject.RemindLaterTakeSurvey);
    }

    if (!featureFlagService.isEnabled(BoolFeatureFlag.AccessCaddie)) {
      cannot(Operation.Manage, Subject.Takeoff2dCaddie);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AccessCaddie)
      && roleGroup !== RoleGroup.Guest) {
      can(Operation.Manage, Subject.TwoDCopilot);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AccesTwoDReportTemplate) && roleGroup !== RoleGroup.Guest) {
      can(Operation.Read, Subject.Takeoff2dReportTemplateShow);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AccessBidPricing) && products.includes(KreoProduct.Plan)) {
      if (permissions[PermissionActivity.BidPricingManagement] === PermissionState.Enabled &&
        roleGroup === RoleGroup.Employee) {
        can(Operation.Create, Subject.BidPricing);
        can(Operation.Read, Subject.BidPricing);
        can(Operation.Read, Subject.BidPricingInvitation);
      }

      if (permissions[PermissionActivity.BidPricingCostInput] === PermissionState.Enabled &&
        (roleGroup === RoleGroup.Employee || roleGroup === RoleGroup.Subcontractor)) {
        can(Operation.Read, Subject.BidPricing);
        can(Operation.Read, Subject.BidPricingWorkPackages);
      }
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AccessActivityGroupingStep)) {
      can(Operation.Read, Subject.SequenceStep);
    }


    if (featureFlagService.isEnabled(BoolFeatureFlag.Access2DMeasurementsAutocomplete)) {
      can(Operation.Manage, Subject.Takeoff2dMeasurementAutocomplete);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.Ui2dPauseSubscription)) {
      can(Operation.Read, Subject.PauseSubscription);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AccessWizard)) {
      can(Operation.Manage, Subject.Takeoff2dWizzardShow);
      if (products.includes(KreoProduct.Takeoff2dWizard)) {
        can(Operation.Manage, Subject.Takeoff2dWizzardAccess);
      }
      if (featureFlagService.isEnabled(BoolFeatureFlag.OneClickAreaHover)) {
        can(Operation.Manage, Subject.Takeoff2dOneClickAreaHover);
      }
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AccessWizzardFinder)) {
      can(Operation.Manage, Subject.Takeoff2dWizzardFinderShow);
      if (products.includes(KreoProduct.Takeoff2dWizard)) {
        can(Operation.Manage, Subject.Takeoff2dWizzardFinderAccess);
      }
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.SignalRLogging)) {
      can(Operation.Manage, Subject.SignalRLogging);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.FindSimilarWithAi)) {
      can(Operation.Manage, Subject.MagicSearch);
    }

    if (featureFlagService.isEnabled(BoolFeatureFlag.AutoMeasure2)) {
      can(Operation.Manage, Subject.AutoMeasure2);
    }
  }

  private planPermissions(permissions: Record<string, PermissionState>, can: any, roleGroup: RoleGroup): void {
    if (permissions[PermissionActivity.Viewer] === PermissionState.Enabled) {
      can(Operation.Read, Subject.Viewer);
      if (roleGroup === RoleGroup.Employee) {
        can(Operation.Manage, Subject.ViewerComments);
      }
    }

    can(Operation.Read, Subject.PlanFeatures);

    if (permissions[PermissionActivity.ProjectManagement] === PermissionState.Enabled) {
      can(Operation.Manage, Subject.ValidationResults);
      can(Operation.Revert, Subject.ValidationResults);
      can(Operation.Read, Subject.ValidationMeasurements);
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Delete, Subject.InactiveScenarios);
      can(Operation.Read, Subject.ValidationClassification);
    }

    if (permissions[PermissionActivity.ValidationActivityAssignmentApprove] ===
      PermissionState.Enabled) {
      can(Operation.Revert, Subject.ValidationActivityAssignment);
    }

    if (permissions[PermissionActivity.ValidationСlassification] === PermissionState.View) {
      can(Operation.Read, Subject.ValidationClassification);
    } else if (permissions[PermissionActivity.ValidationСlassification] === PermissionState.Edit) {
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Update, Subject.ValidationClassification);
    }

    if (permissions[PermissionActivity.ValidationСlassificationApprove] ===
      PermissionState.Enabled) {
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Approve, Subject.ValidationClassification);
      can(Operation.Revert, Subject.ValidationClassification);
    }

    if (permissions[PermissionActivity.ValidationMeasurements] === PermissionState.View) {
      can(Operation.Read, Subject.ValidationMeasurements);
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
    } else if (permissions[PermissionActivity.ValidationMeasurements] === PermissionState.Edit) {
      can(Operation.Read, Subject.ValidationMeasurements);
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Update, Subject.ValidationMeasurements);
    }

    if (permissions[PermissionActivity.ValidationMeasurementsApprove] ===
      PermissionState.Enabled) {
      can(Operation.Read, Subject.ValidationMeasurements);
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Approve, Subject.ValidationMeasurements);
      can(Operation.Revert, Subject.ValidationMeasurements);
    }

    if (permissions[PermissionActivity.ValidationActivityAssignment] === PermissionState.View) {
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
    } else if (permissions[PermissionActivity.ValidationActivityAssignment] === PermissionState.Edit) {
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Update, Subject.ValidationActivityAssignment);
    }

    if (permissions[PermissionActivity.ValidationActivityAssignmentApprove] ===
      PermissionState.Enabled) {
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Approve, Subject.ValidationActivityAssignment);
      can(Operation.Revert, Subject.ValidationActivityAssignment);
    }

    if (permissions[PermissionActivity.ValidationСlassification] === PermissionState.Edit) {
      can(Operation.Update, Subject.ElementUniclassClassification);
    }

    if (permissions[PermissionActivity.Dashboard] === PermissionState.View ||
      permissions[PermissionActivity.Dashboard] === PermissionState.Edit) {
      can(Operation.Read, Subject.Dashboard);
    }

    if (permissions[PermissionActivity.Scenarios] === PermissionState.View) {
      can(Operation.Read, Subject.Scenarios);
    } else if (permissions[PermissionActivity.Scenarios] === PermissionState.Edit) {
      can(Operation.Manage, Subject.Scenarios);
    }

    if (permissions[PermissionActivity.Gantt] === PermissionState.Enabled) {
      can(Operation.Read, Subject.Gantt);
      if (roleGroup === RoleGroup.Employee) {
        can(Operation.Export, Subject.Gantt);
      }
      if (roleGroup === RoleGroup.Employee || roleGroup === RoleGroup.Guest) {
        can(Operation.Read, Subject.GanttResources);
      }
    }

    if (permissions[PermissionActivity.CostEstimate] === PermissionState.Enabled) {
      can(Operation.Read, Subject.Cost);
      if (roleGroup === RoleGroup.Employee) {
        can(Operation.Export, Subject.Cost);
      }
    }

    if (permissions[PermissionActivity.Visualisation4D] === PermissionState.Enabled) {
      can(Operation.Read, Subject.FourDVisualisation);
    }
  }

  private databasePermissions(permissions: Record<string, PermissionState>, can: any, roleGroup: RoleGroup): void {
    if (permissions[PermissionActivity.Database] === PermissionState.View) {
      can(Operation.Read, Subject.Database);
    } else if (permissions[PermissionActivity.Database] === PermissionState.Edit) {
      if (roleGroup === RoleGroup.Employee) {
        can(Operation.Manage, Subject.Database);
      } else if (roleGroup === RoleGroup.Guest) {
        can(Operation.Create, Subject.Database);
        can(Operation.Read, Subject.Database);
      }
    }
  }

  private costEstimatePermissions(permissions: Record<string, PermissionState>, can: any, roleGroup: RoleGroup): void {
    if (permissions[PermissionActivity.CostEstimate] === PermissionState.Enabled) {
      can(Operation.Read, Subject.CostEstimate);
      if (roleGroup === RoleGroup.Employee) {
        can(Operation.Manage, Subject.CostEstimate);
      }
    }

    if (permissions[PermissionActivity.ProjectManagement] === PermissionState.Enabled) {
      can(Operation.Manage, Subject.ValidationResults);
      can(Operation.Revert, Subject.ValidationResults);
      can(Operation.Read, Subject.ValidationMeasurements);
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Delete, Subject.InactiveScenarios);
      can(Operation.Read, Subject.ValidationClassification);
    }

    if (permissions[PermissionActivity.ValidationActivityAssignmentApprove] ===
      PermissionState.Enabled) {
      can(Operation.Revert, Subject.ValidationActivityAssignment);
    }

    if (permissions[PermissionActivity.ValidationMeasurements] === PermissionState.View) {
      can(Operation.Read, Subject.ValidationMeasurements);
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
    } else if (permissions[PermissionActivity.ValidationMeasurements] === PermissionState.Edit) {
      can(Operation.Read, Subject.ValidationMeasurements);
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Update, Subject.ValidationMeasurements);
    }

    if (permissions[PermissionActivity.ValidationMeasurementsApprove] ===
      PermissionState.Enabled) {
      can(Operation.Read, Subject.ValidationMeasurements);
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Approve, Subject.ValidationMeasurements);
      can(Operation.Revert, Subject.ValidationMeasurements);
    }

    if (permissions[PermissionActivity.ValidationActivityAssignment] === PermissionState.View) {
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
    } else if (permissions[PermissionActivity.ValidationActivityAssignment] === PermissionState.Edit) {
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Update, Subject.ValidationActivityAssignment);
    }

    if (permissions[PermissionActivity.ValidationActivityAssignmentApprove] ===
      PermissionState.Enabled) {
      can(Operation.Read, Subject.ValidationActivityAssignment);
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Approve, Subject.ValidationActivityAssignment);
      can(Operation.Revert, Subject.ValidationActivityAssignment);
    }

    if (permissions[PermissionActivity.ValidationСlassification] === PermissionState.View) {
      can(Operation.Read, Subject.ValidationClassification);
    } else if (permissions[PermissionActivity.ValidationСlassification] === PermissionState.Edit) {
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Update, Subject.ValidationClassification);
      can(Operation.Update, Subject.ElementUniclassClassification);
    }

    if (permissions[PermissionActivity.ValidationСlassificationApprove] ===
      PermissionState.Enabled) {
      can(Operation.Read, Subject.ValidationClassification);
      can(Operation.Approve, Subject.ValidationClassification);
      can(Operation.Revert, Subject.ValidationClassification);
    }
  }

  private activateAccountManagmentPermissions(
    can: any,
    selectedCompanySubscription: CompanySubscriptionModel,
    cannot: any,
  ): void {
    can(Operation.Manage, Subject.Payment);
    can(Operation.Manage, Subject.Role);
    can(Operation.Manage, Subject.Guests);
    can(Operation.Manage, Subject.Employees);
    // TODO: move to correct permission
    can(Operation.Manage, Subject.Subcontractors);

    if (selectedCompanySubscription && selectedCompanySubscription.isCollaborationBlocked) {
      cannot(Operation.Create, Subject.Guests);
      cannot(Operation.Create, Subject.Employees);
      cannot(Operation.Create, Subject.Subcontractors);
    }
  }

  private activateProjectManagmentPermissions(
    can: any,
    selectedCompanySubscription: CompanySubscriptionModel,
    cannot: any,
  ): void {
    can(Operation.Manage, Subject.ShareProjects);
    can(Operation.Manage, Subject.Project);

    if (selectedCompanySubscription && selectedCompanySubscription.isCollaborationBlocked) {
      cannot(Operation.Manage, Subject.ShareProjects);
    }
  }

  private piaDatabasePermissions(
    can: any,
    roleGroup: RoleGroup,
    cannot: any,
    products: KreoProduct[],
  ): void {
    const guest = roleGroup === RoleGroup.Guest;
    const hasProduct = products.includes(KreoProduct.PropertiesItemsAssemblies);
    if (hasProduct) {
      if (guest) {
        can(Operation.Read, Subject.Takeoff2dPiaAssignment);
        can(Operation.Read, Subject.PiaDatabase);
      } else {
        can(Operation.Manage, Subject.Takeoff2dPiaAssignment);
        can(Operation.Manage, Subject.PiaDatabase);
      }
    } else {
      cannot(Operation.Manage, Subject.Takeoff2dPiaAssignment);
      cannot(Operation.Manage, Subject.PiaDatabase);
    }
  }
}
