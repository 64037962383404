import React from 'react';
import { usePropertyNames } from './use-property-names';

export function withFormulaProperties<P>(
  Component: React.ComponentType<P & { properties?: string[] }>,
): React.ComponentType<Pick<P, Exclude<keyof P, 'properties'>> & { additionalPropertyNames?: string[] }> {
  const WithFormulaPropertiesComponent: React.FC<P & { additionalPropertyNames?: string[] }> = (props) => {
    const propertyNames = usePropertyNames(props.additionalPropertyNames);
    return <Component {...props} properties={propertyNames} />;
  };
  return React.memo(WithFormulaPropertiesComponent);
}
