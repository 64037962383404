const modelCheck = 'Model Check';
const classification = 'Information Modeling';
const activityAssignment = 'Activity Assignment';
const measurements = 'Measurements';
const result = 'Result';


export const ValidationStepsNames = {
  modelCheck,
  classification,
  activityAssignment,
  measurements,
  result,
};
