import * as t from 'io-ts';

const ModelManagementFileC = t.type(
  {
    bimElementIds: t.array(t.number),
    id: t.number,
    name: t.string,
  },
);


export const ModelManagementDataC = t.type(
  {
    files: t.array(ModelManagementFileC),
  },
  'ModelManagementData',
);


export type ModelManagementFileData = t.TypeOf<typeof ModelManagementFileC>;
export type ModelManagementData = t.TypeOf<typeof ModelManagementDataC>;
