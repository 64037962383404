import { useMemo } from 'react';

import { MentionUtils } from 'unit-2d-comments/utils';
import { MentionedTextPart, MentionTextPartType } from './interfaces';

export function useMentionSplit(text: string): MentionedTextPart[] {
  return useMemo(() => {

    const parts: MentionedTextPart[] = [];
    const pushText = (firstIndex: number, lastIndex?: number): void => {
      const splitted = text.slice(firstIndex, lastIndex).split('\n');
      parts.push({
        type: MentionTextPartType.Text,
        value: splitted[0],
      });
      for (let i = 1; i < splitted.length; i++) {
        parts.push({
          type: MentionTextPartType.NextLineText,
          value: splitted[i],
        });
      }
    };

    let lastTextIndex = 0;
    for (const match of MentionUtils.mentionsIterator(text)) {
      if (match.index !== lastTextIndex) {
        pushText(lastTextIndex, match.index);
      }
      parts.push({
        type: MentionTextPartType.Mention,
        value: match[1],
      });
      lastTextIndex = match.index + match[0].length;
    }
    if (lastTextIndex === 0 || lastTextIndex < text.length - 1) {
      pushText(lastTextIndex);
    }
    return parts;
  }, [text]);
}
