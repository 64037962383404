import { Icons } from '@kreo/kreo-ui-components';
import * as Ag from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
// eslint-disable-next-line import/no-unresolved
import { TableInnerClipboard } from 'src/units/persisted-storage/interfaces/state';
import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { arrayUtils } from 'common/utils/array-utils';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { mathUtils } from 'common/utils/math-utils';
import { ValueHelper } from 'common/utils/value-helper';
import { getConfigId, getStylePropertyId } from '../../../units/2d/units/excel-table-cell-formatter/common';
import { CopyPasteHotkey } from '../drawings/utils/hotkey-utils';
import {
  GlobalKeyboardEventsControllerContextProps,
  withGlobalKeyboardEventsController,
} from '../global-keyboard-events-controller';

import { DefaultCellRenderer } from './default-cell-renderer';
import { ExcelTableCellEdit } from './excel-table-cell-edit';
import { ExcelTableRowIdentification } from './excel-table-row-identificator';
import {
  CellFocusData,
  ExcelTableApi,
  ExcelTableContext,
  UpdateCellData,
  UpdateCellDataTransaction,
} from './interfaces';
import { Styled } from './styled';
import { CellFillHandler, ClipboardHelper, ExcelFormulaHelper, RangeHelper } from './utils';
import { ClipboardDataType } from './utils/clipboard-helper';
import { CSVFormatData } from './utils/range-helper';


export const INDEX_COLUMN_KEY = 'index';

const getRowNodeId = (d): string => d.id;

interface Props extends GlobalKeyboardEventsControllerContextProps {
  sheetId: string;
  sheetName: string;
  callbacks?: Array<{ ctrKey: boolean, altKey: boolean, key: string, callback: () => void }>;
  tableClipboard: TableInnerClipboard;
  onCellFocused?: (params: CellFocusData) => void;
  onCellRangeChanged?: (params: Ag.CellRange[]) => void;
  onCellMouseDown?: (e: Ag.CellMouseDownEvent) => void;
  valueGetter?: (params: Ag.ValueGetterParams) => string | number;
  saveRef?: (ref: ExcelTableApi) => void;
  removeRef: (sheetId: string) => void;
  defaultColDef?: Ag.ColDef | Ag.ColGroupDef;
  onRowUpdate?: (rows: UpdateCellData[]) => void;
  columns: Ag.ColDef[];
  tableContext: ExcelTableContext;
  onAddRows?: (count: number) => void;
  onAddColumns?: (count: number) => void;
  focusedCell?: CellFocusData;
  isEditable: boolean;
  handlePaste?(cvs?: string): Promise<void>;
  onColumnResize(payload: Array<{ columnId: string, width: number, prevWidth: number }>): void;
  onFilterChange(): void;
  insertRow(offset: number, startIndex: number): void;
  insertColumn(offset: number, startIndex: number): void;
  deleteRow(offset: number, startIndex: number): void;
  deleteColumn(offset: number, startIndex: number): void;
  onSendToClipboard(rows: string[][], expectedClipboard: string): void;
  getExtraContextMenu?: () => Array<(string | Ag.MenuItemDef)>;
}

const AgGridComponents = {
  excelTableCellEdit: ExcelTableCellEdit,
  defaultCellRenderer: DefaultCellRenderer,
};

const DEFAULT_ROW_DATA = [];

export const DEFAULT_FIRST_COLUMN = {
  headerName: '',
  field: INDEX_COLUMN_KEY,
  colId: INDEX_COLUMN_KEY,
  width: 50,
  maxWidth: 50,
  pinned: 'left',
  cellClass: 'excel-table__first-column-cel',
  lockPosition: true,
  editable: false,
  resizable: false,
  valueGetter: undefined,
  suppressSizeToFit: true,
};

const DEFAUTL_COLUMN_WIDTH = 110;

class ExcelTableComponent extends React.PureComponent<Props> {
  private defaultColDef: Ag.ColDef | Ag.ColGroupDef;
  private agGridColumnApi: Ag.ColumnApi;
  private agGridApi: Ag.GridApi;
  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  private resetMenuItem: Ag.MenuItemDef = {
    name: 'Reset Columns',
    action: () => {
      const columns = this.agGridColumnApi.getAllColumns();
      const payload = arrayUtils.filterMap(
        columns,
        c => c.getColId() !== INDEX_COLUMN_KEY,
        c => ({ key: c.getColId(), newWidth: DEFAUTL_COLUMN_WIDTH }),
      );
      this.agGridColumnApi.setColumnWidths(payload, true);
    },
  };

  public constructor(props: Props) {
    super(props);
    this.defaultColDef = {
      lockPosition: true,
      width: DEFAUTL_COLUMN_WIDTH,
      resizable: true,
      valueGetter: this.valueGetter,
      valueFormatter: this.valueFormatter,
      editable: this.props.isEditable,
      cellRenderer: 'defaultCellRenderer',
      cellEditor: 'excelTableCellEdit',
      headerComponentParams: {
        enableMenu: true,
      },
      menuTabs: ['generalMenuTab', 'filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        refreshValuesOnOpen: true,
        cellHeight: 30,
        buttons: ['reset'],
        valueFormatter: this.valueFormatter,
        comparator: this.compareFilterValue,
      },
      comparator: this.sortComparator,
      ...this.props.defaultColDef,
    };
  }

  public componentDidMount(): void {
    this.props.addKeyDownEventListener(CopyPasteHotkey.Cut, this.handelCutEvent);
  }

  public componentWillUnmount(): void {
    this.props.onCellRangeChanged([]);
    this.agGridApi = null;
    // this.props.removeRef(this.props.sheetId);
  }

  public render(): JSX.Element {
    return (
      <Styled.Container
        className='excel-table'
        ref={this.containerRef}
      >
        <AgGridReact
          enableFillHandle={true}
          columnDefs={this.props.columns}
          defaultColDef={this.defaultColDef}
          rowData={DEFAULT_ROW_DATA}
          enableRangeSelection={true}
          getRowNodeId={getRowNodeId}
          getContextMenuItems={this.getContextMenuItems}
          components={AgGridComponents}
          enterMovesDownAfterEdit={true}
          onCellFocused={this.onCellFocused}
          onGridReady={this.onGridReady}
          context={this.props.tableContext}
          processCellForClipboard={this.processCellForClipboard}
          processCellFromClipboard={this.processCellFromClipboard}
          onRangeSelectionChanged={this.onRangeSelectionChanged}
          fillOperation={CellFillHandler.fillOperation}
          overlayNoRowsTemplate={'Rendering...'}
          onColumnResized={this.onColumnResize}
          onFilterChanged={this.props.onFilterChange}
          getMainMenuItems={this.getMainMenuItems}
          cacheQuickFilter={false}
          processDataFromClipboard={this.processDataFromClipboard}
          sendToClipboard={this.sendToClipboard}
        />
      </Styled.Container>
    );
  }

  @autobind
  private sortComparator(a: string | number | undefined | null, b: string | number | undefined | null): number {
    if (ValueHelper.isNumberValue(a) && ValueHelper.isNumberValue(b)) {
      return Number(a) - Number(b);
    }
    const aValue = this.getValueToCompare(a);
    const bValue = this.getValueToCompare(b);
    return aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' });
  }

  private getValueToCompare(value: string | number | undefined | null): string {
    return value === undefined || value === null
      ? ''
      : value.toString();
  }

  @autobind
  private processDataFromClipboard(params: Ag.ProcessDataFromClipboardParams): string[][] | null {
    const csv = RangeHelper.getCSVFromArray(params.data);
    this.props.handlePaste(csv);
    return [];
  }

  @autobind
  private sendToClipboard(params: { data: string }): void {
    const text = params.data;
    const innerClipboard = [];
    const clipboard = [];
    const rows = RangeHelper.getCellsValueFromCSV(text);
    for (const row of rows) {
      const rowToInnerClipboard = [];
      const rowToClipboard = [];
      for (const column of row) {
        const data: CSVFormatData = JSON.parse(column);
        rowToClipboard.push(data.value);
        rowToInnerClipboard.push(data);
      }
      innerClipboard.push(rowToInnerClipboard);
      clipboard.push(rowToClipboard);
    }
    const clipboardCSV = RangeHelper.getCSVFromArray(clipboard);
    navigator.clipboard.writeText(clipboardCSV);
    this.props.onSendToClipboard(innerClipboard, clipboardCSV);
  }

  @autobind
  private compareFilterValue(a: string, b: string): number {
    const valA = parseInt(a, 10);
    const valB = parseInt(b, 10);
    if (valA === valB) return 0;
    return valA > valB ? 1 : -1;
  }

  @autobind
  private getMainMenuItems(): Array<string | Ag.MenuItemDef> {
    return ['autoSizeThis', 'autoSizeAll', this.resetMenuItem];
  }

  @autobind
  private onColumnResize(event: Ag.ColumnResizedEvent): void {
    if (!event.finished) {
      return;
    }
    if (event.column) {
      const payload = this.getChangeResizePayload(event.column);
      if (payload.width === payload.prevWidth) {
        return;
      }
      this.props.onColumnResize([ payload ]);
      return;
    }
    if (event.columns) {
      const payload = arrayUtils.filterMap(
        event.columns,
        c => c.getColId() !== INDEX_COLUMN_KEY,
        this.getChangeResizePayload,
      ); event.columns.map(this.getChangeResizePayload);
      this.props.onColumnResize(payload);
      return;
    }
  }

  @autobind
  private getChangeResizePayload(column: Ag.Column): { columnId: string, width: number, prevWidth: number } {
    const width = column.getActualWidth();
    const columnId = column.getColId();
    const prevWidth = column.getColDef().width;
    return { columnId, width, prevWidth };
  }

  @autobind
  private async handelCutEvent(event: KeyboardEvent): Promise<void> {
    if (!this.props.isEditable) {
      return;
    }

    await this.handelCutting();

    if (!this.props.callbacks) {
      return;
    }

    for (const callback of this.props.callbacks) {
      if (HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(event) === callback.ctrKey
        && event.altKey === callback.altKey
        && event.key === callback.key
      ) {
        callback.callback();
      }
    }
  }

  @autobind
  private onRangeSelectionChanged(event: Ag.RangeSelectionChangedEvent): void {
    if (event.finished) {
      const cellRangeToUpdate = [];
      let isIndexColumnIncluded = false;
      const ranges = event.api.getCellRanges();
      ranges.forEach(range => {
        const columns = range.columns.filter(c => c.getColId() !== INDEX_COLUMN_KEY);
        const columnStart = range.startColumn.getColDef().colId !== INDEX_COLUMN_KEY
          ? range.startColumn
          : columns[0];
        const rowStartIndex = range.startRow.rowIndex;
        const rowEndIndex = range.endRow.rowIndex;
        cellRangeToUpdate.push({ rowStartIndex, rowEndIndex, columns, columnStart });

        if (columns.length !== range.columns.length) {
          isIndexColumnIncluded = true;
        }
      });
      if (!ranges.length) {
        this.agGridApi.refreshHeader();
      }
      if (isIndexColumnIncluded) {
        event.api.clearRangeSelection();
        cellRangeToUpdate.forEach(r => {
          event.api.addCellRange(r);
        });
      }

      if (this.props.onCellRangeChanged) {
        this.props.onCellRangeChanged([...ranges]);
      }
    }
  }

  @autobind
  private async handelCutting(): Promise<void> {
    this.handelCopy();
    this.handleDelete();
  }

  @autobind
  private handelCopy(): void {
    this.agGridApi.copySelectedRangeToClipboard(false);
  }

  @autobind
  private handleDelete(): void {
    const cellEditorParams = (this.props.defaultColDef as Ag.ColDef).cellEditorParams;
    const updatedCells = RangeHelper.clearRange(this.agGridApi);
    cellEditorParams.onDelete(updatedCells);
    this.agGridApi.refreshCells({ force: true });
  }

  @autobind
  private processCellForClipboard(params: Ag.ProcessCellForExportParams): string {
    const colId = params.column.getId();
    const rowId = params.node.id.toString();
    const data: string = params.node.data[colId] === undefined || params.node.data[colId] === null
      ? ''
      : params.node.data[colId].toString();
    if (data && data.startsWith('=')) {
      const formatData = ExcelFormulaHelper.narrowCellIdBySheetId(data, this.props.sheetId);
      const settings = params.node.data[getConfigId(colId)];
      return ClipboardHelper.formatForClipboard(colId, rowId, formatData, params.value, settings);
    }
    return ClipboardHelper.formatForClipboard(colId, rowId, data, params.value);
  }

  @autobind
  private processCellFromClipboard(params: Ag.ProcessCellForExportParams): ClipboardDataType {
    const columnId = params.column.getId();
    const rowId = params.node.id;
    const cellId = ExcelFormulaHelper.getCellLink(null, columnId, rowId);
    return ClipboardHelper.formatFromClipboard(params.value, cellId, this.props.sheetId);
  }

  @autobind
  private valueGetter(params: Ag.ValueGetterParams): React.ReactText {
    if (this.props.valueGetter) {
      return this.props.valueGetter(params);
    }
    const value = params.node.data[params.colDef.field];
    return value;
  }

  @autobind
  private valueFormatter(params: Ag.ValueFormatterParams): string {
    const isNumberValue = ValueHelper.isNumberValue(params.value);
    if (isNumberValue) {
      return mathUtils.round(Number(params.value), 2).toString();
    }

    return params.value;
  }

  @autobind
  private getContextMenuItems(): Array<(string | Ag.MenuItemDef)> {
    if (this.getSelectedRowsAndColumns() === null) {
      return null;
    }
    const {
      selectedRowsCount,
      isValidCellRange,
      selectedColumnsCount,
      firstRowIndex,
      firstColumnIndex,
    } = this.getSelectedRowsAndColumns();
    const showRowCount = selectedRowsCount === 0 ? '' : selectedRowsCount + 1;
    const rowPostfix = selectedRowsCount > 0 ? 's' : '';
    const showColumnCount = selectedColumnsCount === 1 ? '' : selectedColumnsCount;
    const columnPostfix = selectedColumnsCount > 1 ? 's' : '';
    const extraContextMenu = this.props.getExtraContextMenu
      ? this.props.getExtraContextMenu()
      : [];
    return [
      this.props.isEditable && isValidCellRange && {
        name: `Insert ${showRowCount} Row${rowPostfix}`,
        action: () => this.props.insertRow(selectedRowsCount + 1, firstRowIndex),
        icon: ReactDOMServer.renderToString(<Icons.InsertRow2D />),
      },
      this.props.isEditable && isValidCellRange && {
        name: `Insert ${showColumnCount} Column${columnPostfix}`,
        action: () => this.props.insertColumn(selectedColumnsCount, firstColumnIndex),
        icon: ReactDOMServer.renderToString(<Icons.InsertColumn2D />),
      },
      this.props.isEditable && isValidCellRange && {
        name: `Delete ${showRowCount} Row${rowPostfix}`,
        action: () => this.props.deleteRow(selectedRowsCount + 1, firstRowIndex),
        icon: ReactDOMServer.renderToString(<Icons.DeleteRow2D />),
      },
      this.props.isEditable && isValidCellRange && {
        name: `Delete ${showColumnCount} Column${columnPostfix}`,
        action: () => this.props.deleteColumn(selectedColumnsCount, firstColumnIndex),
        icon: ReactDOMServer.renderToString(<Icons.DeleteColumn2D />),
      },
      {
        name: 'Copy',
        action: this.handelCopy,
        icon: ReactDOMServer.renderToString(<Icons.CopyDuplicate />),
      },
      this.props.isEditable && {
        name: 'Cut',
        action: this.handelCutting,
        icon: ReactDOMServer.renderToString(<Icons.Cut />),
      },
      this.props.isEditable && {
        name: 'Paste',
        action: this.props.handlePaste,
        icon: ReactDOMServer.renderToString(<Icons.Paste />),
      },
      this.props.isEditable && {
        name: 'Delete',
        action: this.handleDelete,
        icon: ReactDOMServer.renderToString(<Icons.Delete />),
      },
      this.props.isEditable && {
        name: 'Add rows',
        action: this.addRows,
        icon: ReactDOMServer.renderToString(<Icons.AddRows />),
      },
      this.props.isEditable && {
        name: 'Add columns',
        action: this.addColumns,
        icon: ReactDOMServer.renderToString(<Icons.AddColumns />),
      },
      {
        name: 'Export CSV',
        action: this.exportCSV,
        icon: ReactDOMServer.renderToString(<Icons.Export />),
      },
      ...extraContextMenu,
    ];
  }

  @autobind
  private getSelectedRowsAndColumns(): {
    selectedRowsCount: number,
    selectedColumnsCount: number,
    isValidCellRange: boolean,
    firstRowIndex: number,
    firstColumnIndex: number,
    } {
    const cellRanges = this.agGridApi?.getCellRanges();

    if (!this.agGridApi || !cellRanges.length) {
      return null;
    }

    const isValidCellRange = cellRanges.length === 1;
    const selectedRowsCount = RangeHelper.getSelectRowsCount(cellRanges[0]);
    const selectedColumnsCount = RangeHelper.getSelectColumnsCount(cellRanges[0]);
    const firstRowIndex = RangeHelper.getMinRowIndex(cellRanges[0]);
    const firstColumnIndex = RangeHelper.getMinColumnId(cellRanges[0]);

    return {
      isValidCellRange,
      selectedRowsCount,
      selectedColumnsCount,
      firstRowIndex,
      firstColumnIndex,
    };
  }

  @autobind
  private exportCSV(): void {
    const fileName = this.props.sheetName;
    this.agGridApi.exportDataAsCsv({ fileName, columnGroups: true });
  }

  @autobind
  private addRows(): void {
    this.props.onAddRows(100);
  }

  @autobind
  private addColumns(): void {
    this.props.onAddColumns(ExcelColumnHelper.ALPHABET_LENGTH);
  }

  @autobind
  private onCellFocused(event: Ag.CellFocusedEvent): void {
    if (!this.props.onCellFocused || !event.column) {
      return;
    }
    const columnId = event.column.getId();

    if (columnId === INDEX_COLUMN_KEY) {
      this.resetFocus();
      return;
    }

    this.changeHeaderHighlight(columnId);
    const rowNode = event.api.getDisplayedRowAtIndex(event.rowIndex);

    if (!rowNode) {
      return;
    }

    const params = this.getCellFocusData(columnId, rowNode.id);
    this.props.onCellFocused(params);
  }

  @autobind
  private resetFocus(): void {
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(this.props.focusedCell.rowId);
    this.agGridApi.setFocusedCell(rowIndex, this.props.focusedCell.columnId);
  }

  @autobind
  private getCellFocusData(columnId: string, rowId: string): CellFocusData {
    if (this.agGridApi === null) {
      return null;
    }
    const cellId = ExcelFormulaHelper.getCellLink(null, columnId, rowId);
    return {
      columnId,
      rowId,
      cellId,
    };
  }

  @autobind
  private changeHeaderHighlight(columnId: string): void {
    if (this.agGridApi === null) {
      return;
    }
    this.agGridApi.refreshHeader();
    if (this.containerRef.current) {
      const headerElement: HTMLElement = this.containerRef.current.querySelector(`[col-id='${columnId}']`);
      if (headerElement) {
        headerElement.className = `${headerElement.className} ag-header-focus`;
      }
    }
  }

  @autobind
  private onGridReady(event: Ag.GridReadyEvent): void {
    this.agGridApi = event.api;
    this.agGridColumnApi = event.columnApi;
    this.props.saveRef({
      api: event.api,
      handelCutting: this.handelCutting,
      handelCopy: this.handelCopy,
      updateColumnWidth: this.updateColumnWidth,
      updateRowData: this.updateRowData,
    });
  }

  private isRefreshCells(payload: UpdateCellDataTransaction): boolean {
    const columnDefs = this.agGridApi.getColumnDefs();
    const propertyKeys = (columnDefs as Ag.ColDef[]).map(el => getStylePropertyId(el.colId));
    return payload.update.some((el) => propertyKeys.some((key) => el[key]));
  }

  @autobind
  private updateRowData(payload: UpdateCellDataTransaction): void {
    if (this.agGridApi) {
      this.agGridApi.applyTransaction(payload);
      if (payload?.update?.length > 0) {
        if (this.isRefreshCells(payload)) {
          this.agGridApi.refreshCells({ force: true });
        }
      }
    }
  }

  @autobind
  private updateColumnWidth(payload: Array< { columnId: string, width: string | number }>): void {
    payload.forEach(({ columnId, width }) => {
      if (ValueHelper.isNumberValue(width)) {
        this.agGridColumnApi.setColumnWidth(columnId, width as number);
      }
    });
  }
}

export const ExcelTable = withGlobalKeyboardEventsController(ExcelTableComponent);
