import styled, { FlattenInterpolation } from 'styled-components';

const EditorContainer = styled.div<{ styles: FlattenInterpolation<any> }>`
  border-radius: 10px;
  background-color: ${p => p.theme.color.backgroundRush};
  padding: 10px 20px;
  box-shadow: 0px 15px 40px #00000029;
  ${p => p.styles};
`;

export const Styled = {
  EditorContainer,
};
