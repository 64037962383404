import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  DrawingsAnnotationLegendActions,
} from 'common/components/drawings/actions/creators/drawings-annotation-legend';
import { State } from 'common/interfaces/state';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import {
  AnnotationFilters,
  FilterChangePayload,
  FilterChangePayloadElement,
  FilterData,
  PagesFilterValue,
} from '../../../interfaces/drawing-filters';
import { FilterSet } from '../../drawings-annotation-legend-filters-menu';
import { FILTER_SETS } from '../../drawings-annotation-legend-filters-menu/filter-sets';
import { SelectFilter } from '../../drawings-annotation-legend-filters-menu/select-filter';
import { Styled } from './styled';


interface StateProps {
  filterData: FilterData;
}

interface DispatchProps {
  changeFilterData: (changeFilterData: FilterChangePayload) => void;
  saveFilterValue: (value: PagesFilterValue) => void;
}

interface Props extends StateProps, DispatchProps {
  filterGeometry: () => void;
  getDropdownMenuPosition: (isOpen: boolean) => void;
  isOpen: boolean;
}

const SelectFilterPagesComponent: React.FC<Props> = ({
  filterData,
  isOpen,
  changeFilterData,
  filterGeometry,
  getDropdownMenuPosition,
  saveFilterValue,
}) => {
  const pagesFilterData = (FILTER_SETS[1] as FilterSet);

  const handleFilterChange = useCallback((filterType: AnnotationFilters, element: FilterChangePayloadElement) => {
    changeFilterData({ filterType, element });
    saveFilterValue(element as PagesFilterValue);
  }, [changeFilterData, saveFilterValue]);

  useEffect(() => {
    filterGeometry();
  }, [filterGeometry, filterData]);
  return (
    <Styled.SelectFilterContainer isOpen={isOpen}>
      <SelectFilter
        selected={filterData[pagesFilterData.filterType] as string}
        elements={pagesFilterData.elements}
        filterType={pagesFilterData.filterType}
        changeFilterData={handleFilterChange}
        getDropdownMenuPosition={getDropdownMenuPosition}
      />
    </Styled.SelectFilterContainer>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    filterData: state.drawings.filterData,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    changeFilterData: (changeFilterData) =>
      dispatch(DrawingsAnnotationLegendActions.changeFilterData(changeFilterData)),
    saveFilterValue: (value) =>
      dispatch(PersistedStorageActions.setFiltersPages(value)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const SelectFilterPages = connector(SelectFilterPagesComponent);
