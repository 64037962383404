import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconActivity: React.FC = (_props) => {
  return (
    <svg width='60px' height='60px' viewBox='0 0 60 60' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='icon_activity_empty' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group' transform='translate(11.000000, 11.000000)' fill={KreoColors.f4} fillRule='nonzero'>
            <path
              d='M2,0 L36,0 C37.1045695,-2.22044605e-16 38,0.8954305 38,2 L38,27 C38,28.1045695 37.1045695,29
              36,29 L2,29 C0.8954305,29 1.11022302e-16,28.1045695 0,27 L0,2 C-2.22044605e-16,0.8954305
              0.8954305,2.22044605e-16 2,0 Z M2,2 L2,27 L36,27 L36,2 L2,2 Z M34,36 C34.5522847,36 35,36.4477153
              35,37 C35,37.5522847 34.5522847,38 34,38 L4,38 C3.44771525,38 3,37.5522847 3,37 C3,36.4477153
              3.44771525,36 4,36 L34,36 Z M25.7928932,9.29289322 C26.1834175,8.90236893 26.8165825,8.90236893
              27.2071068,9.29289322 C27.5976311,9.68341751 27.5976311,10.3165825 27.2071068,10.7071068
              L18.2071068,19.7071068 C17.8165825,20.0976311 17.1834175,20.0976311 16.7928932,19.7071068
              L10.7928932,13.7071068 C10.4023689,13.3165825 10.4023689,12.6834175 10.7928932,12.2928932
              C11.1834175,11.9023689 11.8165825,11.9023689 12.2071068,12.2928932 L17.5,17.5857864
              L25.7928932,9.29289322 Z'
              id='Combined-Shape'
            />
        </g>
    </g>
</svg>
  );
};
