import { TinyText } from '@kreo/kreo-ui-components';
import React from 'react';

import { UnitUtil } from 'common/utils/unit-util';
import { MeasureValue as MeasureValueType } from '../../../drawings-annotation-legend-item';
import { Styled } from './styled';

interface Props{
  measureValue: MeasureValueType;
  isImperial: boolean;
}

const MeasureValueComponent: React.FC<Props> = ({ measureValue, isImperial }) => {
  const valueString = UnitUtil.measureToString2d(measureValue.value, measureValue.unit, isImperial);
  const Icon = measureValue.icon;
  return (
    <Styled.MultiValuesPopupItem>
      <Icon/>
      <TinyText>
        {valueString}
      </TinyText>
      <TinyText color='turquoiseInversed'>
        ({measureValue.itemsCount})
      </TinyText>
    </Styled.MultiValuesPopupItem>
  );
};

export const MeasureValue = React.memo(MeasureValueComponent);
