import { ProjectType } from 'common/constants/project-type';
import { AdminResourcesApi } from '../../../api/server';
import { DemoProject } from '../pages/demo-projects/interfaces/demo-project';

function getDemoProjects(type: ProjectType): Promise<DemoProject[]> {
  return AdminResourcesApi.get(`/demo-projects/${type}`, 'error in load demo projects');
}

function deleteDemoProject(projectId: number): Promise<void> {
  return AdminResourcesApi.delete(`/demo-projects/${projectId}`, 'Error while deleting demo project');
}

function createDemoProject(type: ProjectType, form: any): Promise<void> {
  return AdminResourcesApi.post(`/demo-projects/${type}`, form);
}

function reorderDemoProject(projectId: number, orderIndex: number): Promise<void> {
  return AdminResourcesApi.put(`/demo-projects/${projectId}/reorder/${orderIndex}`, null);
}

function updateDemoProject(projectId: number, form: any): Promise<void> {
  return AdminResourcesApi.put(`/demo-projects/${projectId}`, form);
}

function getScreenUrl(projectId: number): string {
  return AdminResourcesApi.getUrl(`/demo-projects/${projectId}/screen`);
}

export const AdminDemoProjectsApi = {
  getDemoProjects,
  deleteDemoProject,
  createDemoProject,
  reorderDemoProject,
  updateDemoProject,
  getScreenUrl,
};
