import * as React from 'react';


export const KreoIconArrowDown: React.FC = () => {
  return (
    <svg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='icon_down' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M6.5,7.79289322 L7.64644661,6.64644661 C7.84170876,6.45118446 8.15829124,6.45118446 8.35355339,6.64644661
          C8.54881554,6.84170876 8.54881554,7.15829124 8.35355339,7.35355339 L6.35355339,9.35355339
          C6.15829124,9.54881554 5.84170876,9.54881554 5.64644661,9.35355339 L3.64644661,7.35355339
          C3.45118446,7.15829124 3.45118446,6.84170876 3.64644661,6.64644661 C3.84170876,6.45118446
          4.15829124,6.45118446 4.35355339,6.64644661 L5.5,7.79289322 L5.5,3 C5.5,2.72385763 5.72385763,2.5
          6,2.5 C6.27614237,2.5 6.5,2.72385763 6.5,3 L6.5,7.79289322 Z'
          id='Combined-Shape'
          fill='#77CE64'
          fillRule='nonzero'
        />
    </g>
    </svg >
  );
};
