import {
  Icons,
  Input,
  Switcher,
  Text,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { CreationDialog } from '../creation-dialog';
import { Styled } from './styled';

interface ComponentState {
  name: string;
  isPublic: boolean;
}

const DEFAULT_STATE = {
  name: 'Untitled',
  isPublic: true,
};

interface Props extends AnalyticsProps {
  onFormSubmit: (id: number | null, form: Partial<TemplateData>) => void;
  onCloseDialog: () => void;
}

export interface TemplateData {
  id: number;
  name: string;
  isPublic: boolean;
  isDefault: boolean;
}

export const CREATE_TEMPLATE_DIALOG_NAME = 'createTemplateDialogName';

class CreateTemplateDialogComponent<T extends TemplateData> extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  public render(): JSX.Element {
    return (
      <DialogWrapper name={CREATE_TEMPLATE_DIALOG_NAME}>
        <CreationDialog
          name={CREATE_TEMPLATE_DIALOG_NAME}
          title={'Folders structure template'}
          icon={Icons.Templates}
          tooltipText={'Template name cannot be empty'}
          submitDisabled={!this.state.name?.trim()}
          setInitialState={this.setInitialState}
          onSubmit={this.onSubmit}
          onCloseDialog={this.props.onCloseDialog}
        >
          <Input
            width='100%'
            type='text'
            text={'Template name'}
            input={{
              value: this.state.name,
              onChange: this.onChangeName,
            }}
            autoFocus={true}
          />
          <Styled.DialogOption>
            <Switcher
              checked={this.state.isPublic}
              onChange={this.onToggleOption}
              size='m'
            />
            <Text fontSize={14}>Available to everyone in the company</Text>
          </Styled.DialogOption>
        </CreationDialog>
      </DialogWrapper>
    );
  }

  @autobind
  private setInitialState(data: T, editMode: boolean): void {
    const state = editMode
      ? {
        name: data.name,
        isPublic: data.isPublic,
      }
      : DEFAULT_STATE;
    this.setState(state);
  }

  @autobind
  private onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ name: event.target.value });
  }

  @autobind
  private onToggleOption(): void {
    this.setState({ isPublic: !this.state.isPublic });
  }

  @autobind
  private onSubmit(data: T, editMode: boolean): void {
    const { name, isPublic } = this.state;
    if (!editMode || data.name !== name || data.isPublic !== isPublic) {
      this.props.onFormSubmit(
        editMode ? data.id : null,
        {
          name,
          isPublic,
          isDefault: editMode ? isPublic && data.isDefault : false,
        },
      );
    }
    this.props.onCloseDialog();
    this.props.sendEvent(MetricNames.measureManager.createTemplate, { name });
  }
}

export const CreateTemplateDialog = withAnalyticsContext(CreateTemplateDialogComponent);
