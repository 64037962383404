import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { useRendererApiContext } from '../../drawings-contexts';
import { DrawingsGeometryInstance } from '../../interfaces';

export function useHasRendered(instancesIds: string[]): boolean {
  const { rendererApi } = useRendererApiContext();
  const filteredIds = useSelector<State, string[]>(s => s.drawings.filteredElementIds);
  const hiddenInstances = useSelector<State, string[]>(s => s.drawings.hiddenInstances);
  const currentDrawingId = useSelector<State, string>(s => s.drawings.currentDrawingInfo?.drawingId);
  const instances = useSelector<State, Record<string, DrawingsGeometryInstance>>(s => s.drawings.aiAnnotation.geometry);

  return useMemo(() => {
    const filteredIdsSet = new Set(filteredIds);
    const hiddenInstancesSet = new Set(hiddenInstances);
    return rendererApi && instancesIds.some(
      id => instances[id]?.drawingId === currentDrawingId
      && rendererApi.engine.isInstanceVisible(id)
      && filteredIdsSet.has(id)
      && !hiddenInstancesSet.has(id),
    );
  }, [rendererApi, instancesIds, hiddenInstances, filteredIds, currentDrawingId, instances]);
}
