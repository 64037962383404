import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import './classification-engine-layout.scss';

import { Controls3D } from 'common/components/controls-3d';
import { EngineHotkeysListItem } from 'common/components/engine-hotkeys-hint';
import { KreoToolbar } from 'common/components/kreo-toolbar';
import { KreoColors } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { Engine } from '../../../../components/engine';
import { KreoEngine } from '../../../../components/engine/KreoEngine';
import { Unit } from '../../../../components/engine/KreoEngineConsts';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';
import { ClassificationActions } from '../../actions/creators/classification';
import { ValidationStepMode } from '../../enums/validation-step-mode';
import { ClassificationBimElementInfo } from '../../interfaces/classification/bim-element-info';
import { ProjectValidationState } from '../../interfaces/project-validation-state';
import { ClassificationEngineContextProvider } from '../classification-engine-context-provider';
import { ClassificationPasteElementsDialog } from '../classification-paste-elements-dialog';
import { ClassificationUnclassifiedElementsFilter } from '../classification-unclassified-elements-filter';
import { PropertiesDataContextProvider } from '../properties-data-context-provider';
import { PropertiesPopupApi } from '../properties-popup-button';

interface StateProps {
  bimElements: ClassificationBimElementInfo[];
  tab: number;
  ids: number[];
  selectedIds: number[];
  selectedPath: number;
  isolationEnabled: boolean;
  isOnAssignment: boolean;
  validationState: ProjectValidationState;
  errorIds: number[];
  unclassifiedFilterEnabled: boolean;
  isAutoFocus: boolean;
}

interface DispatchProps {
  loadData: (id: number) => void;
  dropState: () => void;
  deselectAll: () => void;
  onHomeClick: () => void;
  selectByEngineIds: (ids: number[]) => void;
  toggleAutoFocusEngine: () => void;
}

interface OwnProps extends RouteComponentProps<PlanProjectRouteParams> {
  mode: ValidationStepMode;
  children?: React.ReactNode;
}

interface Props extends DispatchProps, StateProps, OwnProps {}

interface PageState {
  isClipBoxEnabled: boolean;
  isShowAllOn: boolean;
  isIsometryEnabled: boolean;
  isGhostEnabled: boolean;
  engineContainerRef: HTMLDivElement;
  isRulerEnabled: boolean;
  isFullScreen: boolean;
  isToolbarHide: boolean;
  isImperialUnit: boolean;
  isAnyInvisible: boolean;
  color: string;
}

class ClassificationEngineLayoutComponent extends React.PureComponent<Props, PageState> {
  private engine: KreoEngine;
  private propertyPopupApi: PropertiesPopupApi;
  private selectedFromEngine: boolean = true;
  private specialAbilitiesHotkeyList: EngineHotkeysListItem[] = [
    { name: 'Next selected object', key: ['Tab'] },
    { name: 'Previous selected object', key: ['Shift', 'Tab'] },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      isClipBoxEnabled: false,
      isShowAllOn: false,
      isIsometryEnabled: false,
      isRulerEnabled: false,
      isGhostEnabled: true,
      engineContainerRef: null,
      isFullScreen: false,
      isToolbarHide: false,
      isImperialUnit: false,
      isAnyInvisible: false,
      color: KreoColors.blue4,
    };
  }

  public componentDidMount(): void {
    this.props.loadData(parseInt(this.props.match.params.projectId, 10));
  }

  public componentWillUnmount(): void {
    this.props.dropState();
  }

  public componentDidUpdate(prevProps: Props): void {
    const isAssignmentStatusChanged = this.props.isOnAssignment !== prevProps.isOnAssignment;
    const isUnclassifiedFilterChanged = this.props.unclassifiedFilterEnabled !== prevProps.unclassifiedFilterEnabled;
    const isFilterSwitched = isUnclassifiedFilterChanged || isAssignmentStatusChanged;
    if (isFilterSwitched) {
      this.engine.setColorTint(null);
      if (!this.props.unclassifiedFilterEnabled && !this.props.isOnAssignment) {
        this.engine.setColorTint('#ffb856', this.props.errorIds);
      }
    } else {
      if (
        this.props.errorIds.length !== prevProps.errorIds.length &&
        !this.props.unclassifiedFilterEnabled
      ) {
        this.engine.setColorTint(null);
        this.engine.setColorTint('#ffb856', this.props.errorIds);
      }
    }
    const sourceEngineSelectedCount = this.engine.getSelected().length;
    if (
      this.props.selectedPath !== prevProps.selectedPath ||
      sourceEngineSelectedCount !== this.props.selectedIds.length
    ) {
      this.selectedFromEngine = false;
      this.engine.setSelected(this.props.selectedIds);
      this.propertyPopupApi.showPropertiesForElements(this.props.selectedIds);
      this.selectedFromEngine = !!sourceEngineSelectedCount || !!this.engine.getSelected().length;
    }
    if (this.props.ids.length !== prevProps.ids.length) {
      this.showAll(false);
    }
    if (this.props.isOnAssignment && isAssignmentStatusChanged) {
      this.propertyPopupApi.showPropertiesForElements(this.props.ids);
    }
  }

  public render(): React.ReactNode {
    const numberProjectId = parseInt(this.props.match.params.projectId, 10);
    return (
      <ClassificationEngineContextProvider
        showAll={this.showAll}
        onIsolateItem={this.onIsolateItem}
        onFocus={this.onSelectedFocus}
        setHighLighted={this.onHover}
      >
        <PropertiesDataContextProvider>
          <div className='classification-engine-layout'>
            {this.props.children}
            <div
              className={classNames(
                'classification-engine-layout__engine',
                { 'classification-engine-layout__engine--full-screen': this.state.isFullScreen },
                { 'classification-engine-layout__engine--toolbar-hide': this.state.isToolbarHide },
              )}
            >
              <Engine
                affterLoadAction={this.engineAfterLoad}
                textures='/static/textures/'
                projectId={numberProjectId}
                sendEngineApi={this.saveEngine}
                onHandleClick={this.engineSelect}
                onToggleRuler={this.onEngineToggleRuler}
                onToggleClipBox={this.onEngineToggleClipBox}
                onChangeVisibility={this.onChangeVisibility}
                toggleCameraParallel={this.toggleCameraParallel}
                saveContainerRef={this.saveEngineContainerRef}
              />
              <KreoToolbar
                isToolbarHide={this.state.isToolbarHide}
                onHideToggle={this.onHideToolbarToggle}
                className='quantity-take-off-page__engine-toolbar'
              >
                <Controls3D
                  isSelectedElement={!!this.props.selectedIds.length}
                  isAutoFocus={this.props.isAutoFocus}
                  ghostEnabled={this.state.isGhostEnabled}
                  isometryEnabled={this.state.isIsometryEnabled}
                  clipBoxEnabled={this.state.isClipBoxEnabled}
                  rulerEnabled={this.state.isRulerEnabled}
                  isFullScreen={this.state.isFullScreen}
                  isImperialUnit={this.state.isImperialUnit}
                  isAnyInvisible={this.state.isAnyInvisible}
                  isShowAllOn={this.state.isShowAllOn}
                  isShowAllEnabled={this.props.isOnAssignment || this.props.isolationEnabled}
                  savePropertiesPopupApi={this.savePropertiesPopupApi}
                  engineLayoutContainer={this.state.engineContainerRef}
                  color={this.state.color}
                  onChangeColor={this.onChangeColor}
                  onDefaultColor={this.onDefaultColor}
                  onAssignColor={this.onAssignColor}
                  onHome={this.onHome}
                  onFocus={this.onFocus}
                  onIsolate={this.onIsolate}
                  onHide={this.onHide}
                  onShowHideElements={this.onShowHideElements}
                  specialAbilitiesHotkeyList={this.specialAbilitiesHotkeyList}
                  toggleGhost={this.toggleGhost}
                  toggleClipBox={this.toggleClipBox}
                  toggleIsometry={this.toggleIsometry}
                  toggleRuler={this.toggleRuler}
                  toggleFullScreen={this.toggleFullScreen}
                  toggleShowAll={this.toggleShowAll}
                  toggleMetricImperial={this.toggleUnit}
                  toggleAutoFocus={this.props.toggleAutoFocusEngine}
                />
              </KreoToolbar>
              <ClassificationUnclassifiedElementsFilter />
            </div>
          </div>
          <ClassificationPasteElementsDialog />
        </PropertiesDataContextProvider>
      </ClassificationEngineContextProvider>
    );
  }

  @autobind
  private onDefaultColor(): void {
    const ids = this.engine.getSelected();
    this.engine.setColorTint(null, ids);
  }

  @autobind
  private onChangeVisibility(): void {
    if (this.state.isAnyInvisible !== this.engine.isAnyInvisible()) {
      const isAnyInvisible = this.engine.isAnyInvisible();
      this.setState({ isAnyInvisible });
    }
  }

  @autobind
  private onIsolate(): void {
    const ids = this.engine.getSelected();
    this.engine.toggleVisibility(true, ids, false);
  }

  @autobind
  private onHide(): void {
    const ids = this.engine.getSelected();
    this.engine.toggleVisibility(false, ids);
  }

  @autobind
  private onShowHideElements(): void {
    this.engine.toggleVisibility(true);
  }

  @autobind
  private onAssignColor(): void {
    const ids = this.engine.getSelected();
    this.engine.setColorTint(this.state.color, ids);
  }

  @autobind
  private onChangeColor(color: string): void {
    this.setState({ color });
  }

  @autobind
  private onFocus(): void {
    const ids = this.engine.getSelected();
    this.engine.focusCamera(ids);
  }

  @autobind
  private saveEngineContainerRef(ref: HTMLDivElement): void {
    this.setState({ engineContainerRef: ref });
  }

  @autobind
  private savePropertiesPopupApi(api: PropertiesPopupApi): void {
    this.propertyPopupApi = api;
  }

  @autobind
  private engineAfterLoad(): void {
    this.engine.setColorTint('#ffb856', this.props.errorIds);
  }

  @autobind
  private toggleCameraParallel(isIsometryEnabled: boolean): void {
    this.setState({ isIsometryEnabled });
  }

  @autobind
  private toggleFullScreen(): void {
    this.setState((s) => ({ isFullScreen: !s.isFullScreen }));
  }

  @autobind
  private onHideToolbarToggle(): void {
    this.setState((s) => ({ isToolbarHide: !s.isToolbarHide }));
  }

  @autobind
  private toggleIsometry(): void {
    this.engine.toggleParallelProjection(!this.state.isIsometryEnabled);
  }

  @autobind
  private onHover(start: number, end: number): void {
    if (this.engine) {
      const bimElementIds = new Array<number>();
      for (let i = start; i < end; i++) {
        bimElementIds[i - start] = this.props.bimElements[i].id;
      }
      this.engine.setHighlighted(bimElementIds);
    }
  }

  @autobind
  private onHome(): void {
    if (this.engine) {
      this.engine.cameraToHome();
    }
    this.props.onHomeClick();
  }

  @autobind
  private onEngineToggleClipBox(isClipBoxEnabled: boolean): void {
    this.setState({ isClipBoxEnabled });
  }

  @autobind
  private toggleClipBox(): void {
    if (this.engine) {
      this.engine.toggleClipbox(!this.state.isClipBoxEnabled, this.state.isGhostEnabled ? 'ghost' : 'none');
      this.setState({ isClipBoxEnabled: !this.state.isClipBoxEnabled });
    }
  }

  @autobind
  private toggleGhost(): void {
    if (this.engine) {
      const { isGhostEnabled, isClipBoxEnabled } = this.state;
      if (isClipBoxEnabled) {
        this.engine.toggleClipbox(
          true,
          !isGhostEnabled ? 'ghost' : 'none',
        );
      }
      this.engine.showInvisibleAsGhost(!isGhostEnabled);
      this.setState({ isGhostEnabled: !isGhostEnabled });
    }
  }

  @autobind
  private toggleShowAll(): void {
    const { isShowAllOn: isShowAllEnabled } = this.state;
    if (!isShowAllEnabled) {
      this.engine.toggleVisibility(true);
    } else {
      this.engine.toggleVisibility(true, this.props.ids, false);
    }
    this.setState({ isShowAllOn: !isShowAllEnabled });
  }

  @autobind
  private onSelectedFocus(start: number, end: number): void {
    const bimElementIds = new Array<number>(end - start);
    for (let i = start; i < end; i++) {
      bimElementIds[i - start] = this.props.bimElements[i].id;
    }
    if (this.props.isAutoFocus) {
      this.engine.focusCamera(bimElementIds);
    }
  }

  @autobind
  private onIsolateItem(start: number, end: number): void {
    const bimElementIds = new Array<number>(end - start);
    for (let i = start; i < end; i++) {
      bimElementIds[i - start] = this.props.bimElements[i].id;
    }
    if (this.props.isAutoFocus) {
      this.engine.focusCamera(bimElementIds);
    }
    this.engine.toggleVisibility(true, bimElementIds, false);
  }

  @autobind
  private onEngineToggleRuler(enabledRuler: boolean): void {
    this.setState({ isRulerEnabled: enabledRuler });
  }

  @autobind
  private toggleRuler(): void {
    if (this.engine) {
      const { isRulerEnabled } = this.state;
      this.engine.toggleRuler(!isRulerEnabled);
      this.setState({ isRulerEnabled: !isRulerEnabled });
    }
  }

  @autobind
  private toggleUnit(): void {
    if (this.engine) {
      const { isImperialUnit } = this.state;
      this.engine.setUiUnits(
        isImperialUnit
          ? { length: Unit.Meter, area: Unit.MeterPow2 }
          : { length: Unit.FootInch, area: Unit.FootPow2 },
      );
      this.setState({ isImperialUnit: !isImperialUnit });
    }
  }

  @autobind
  private engineSelect(idsWithGeometry: number[]): void {
    if (!this.props.isOnAssignment && this.selectedFromEngine) {
      if (idsWithGeometry.length > 0) {
        this.selectedFromEngine = false;
        if (!this.state.isShowAllOn) {
          this.engine.setSelected(this.props.selectedIds);
        }
        this.props.selectByEngineIds(idsWithGeometry);
      } else {
        this.props.deselectAll();
      }
    }
    if (this.props.isAutoFocus) {
      this.engine.focusCamera(idsWithGeometry);
    }
    this.selectedFromEngine = true;
  }

  @autobind
  private showAll(needFocus: boolean = true): void {
    if (this.state.isShowAllOn && (this.props.isOnAssignment || this.props.isolationEnabled)) {
      this.engine.toggleVisibility(true);
    } else {
      this.engine.toggleVisibility(true, this.props.ids, false);
    }
    if (needFocus && this.props.isAutoFocus) {
      this.engine.focusCamera(this.props.ids);
    }
  }

  @autobind
  private saveEngine(engine: KreoEngine): void {
    this.engine = engine;
    this.engine.showInvisibleAsGhost(true);
  }
}

const mapStateToProps = (state: State): StateProps => {
  let ids = new Array<number>();
  const {
    modelBrowserFiltered,
    modelTree,
    bimElements,
    modelBrowserFilters: { tab, isolationEnabled },
    classificationTarget,
  } = state.classification;
  let elements = bimElements;
  const tree = isolationEnabled ? modelBrowserFiltered : modelTree;
  if (classificationTarget) {
    ids = classificationTarget.ids;
  } else {
    if (tree && tree.length) {
      const start = tree[0].engineIds[0];
      const end = tree[tree.length - 1].engineIds[1];
      elements = elements.slice(start, end);
      if (state.classification.modelBrowserFilters.unclassifiedFilterEnabled) {
        const errorIds = new Set(state.classification.errorIds);
        elements = elements.filter(x => errorIds.has(x.id));
      }
    }
    if (tab !== -1) {
      for (const { categoryType, id } of elements) {
        if (tab !== -1 && categoryType !== tab) {
          continue;
        }
        ids.push(id);
      }
    } else {
      ids = elements.map(({ id }) => id);
    }
  }

  const currentProject = state.projects.currentProject;

  return {
    isolationEnabled,
    tab,
    ids,
    isOnAssignment: !!state.classification.classificationTarget,
    bimElements: state.classification.bimElements,
    selectedIds: state.classification.selectedIds,
    validationState: currentProject ? currentProject.validationState : null,
    selectedPath: state.classification.selectedPath,
    errorIds: state.classification.errorIds,
    unclassifiedFilterEnabled: state.classification.modelBrowserFilters.unclassifiedFilterEnabled,
    isAutoFocus: state.persistedStorage.isAutoFocusEngine,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  return {
    loadData: id => {
      dispatch(ClassificationActions.loadData(id));
    },
    dropState: () => {
      dispatch(ClassificationActions.dropState());
    },
    selectByEngineIds: ids => {
      if (props.mode === ValidationStepMode.View) {
        dispatch(ClassificationActions.selectTreeNodeByEngineId(ids[0]));
      } else {
        dispatch(ClassificationActions.selectByEngineIds(ids));
      }
    },
    onHomeClick: () => {
      dispatch(ClassificationActions.collapseAllTree());
    },
    deselectAll: () => {
      dispatch(ClassificationActions.onSelectAll(false));
    },
    toggleAutoFocusEngine: () => dispatch(PersistedStorageActions.toggleEngineAutoFocus()),
  };
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export const ClassificationEngineLayout = connector(ClassificationEngineLayoutComponent);
