import * as classNames from 'classnames';
import * as React from 'react';

import { numberUtils } from 'common/utils/number-utils';

interface ReadonlyCostRateCellProps {
  rate: number | null;
  cost: number | null;
  className?: string;
}

const costFormat = '0,0';
const rateFormat = '0,0.00';

const getDisplayValue = (value: number | undefined, formatString: string): string => {
  return numberUtils.getNumeralFormatter(value).format(formatString).replace(/,/g, ' ');
};

export const ReadonlyCostRateCell: React.FC<ReadonlyCostRateCellProps> = (
  { rate, cost, className },
) => {
  const cellClassName = classNames('cost-estimate-table__price', className);

  return (
    <div className={cellClassName}>
      <div className='rate'>{rate === null ? '' : getDisplayValue(rate, rateFormat)}</div>
      <div className='cost'>{cost === null ? '' : getDisplayValue(cost, costFormat)}</div>
    </div>
  );
};
