import * as React from 'react';

export const KreoIconMicroplanning: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/16x16/microplanning'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          // eslint-disable-next-line max-len
          d='M11.0630087,1.5 C11.2850301,0.637386394 12.0680803,1.71190874e-16 13,0 C14.1045695,-2.02906125e-16 15,0.8954305 15,2 C15,3.1045695 14.1045695,4 13,4 C12.0680803,4 11.2850301,3.36261361 11.0630087,2.5 L11,2.5 C8.78503732,2.5 8.31834826,3.05426738 7.96939532,5.08468951 C7.71645887,6.55654385 7.33321446,7.48780175 6.48006651,7.99962146 C7.33289833,8.51021147 7.71558191,9.43835319 7.96718604,10.9024548 C8.31834826,12.9457326 8.78503732,13.5 11,13.5 L11.0630087,13.5 C11.2850301,12.6373864 12.0680803,12 13,12 C14.1045695,12 15,12.8954305 15,14 C15,15.1045695 14.1045695,16 13,16 C12.0680803,16 11.2850301,15.3626136 11.0630087,14.5 L11,14.5 C8.21236488,14.5 7.40627287,13.5426377 6.98384434,11.0846895 C6.63320457,9.04429305 6.17971239,8.5 4,8.5 C3.72385763,8.5 3.5,8.27614237 3.5,8 C3.5,7.72385763 3.72385763,7.5 4,7.5 C6.17971239,7.5 6.63320457,6.95570695 6.98163284,4.92817906 C7.40627287,2.45736228 8.21236488,1.5 11,1.5 L11.0630087,1.5 Z M13,13 C12.4477153,13 12,13.4477153 12,14 C12,14.5522847 12.4477153,15 13,15 C13.5522847,15 14,14.5522847 14,14 C14,13.4477153 13.5522847,13 13,13 Z M13,1 C12.4477153,1 12,1.44771525 12,2 C12,2.55228475 12.4477153,3 13,3 C13.5522847,3 14,2.55228475 14,2 C14,1.44771525 13.5522847,1 13,1 Z'
          id='Combined-Shape'
          fill='#93ACC7'
          fillRule='nonzero'
        />
        <path
          // eslint-disable-next-line max-len
          d='M3.93699126,8.5 C3.71496986,9.36261361 2.93191971,10 2,10 C0.8954305,10 1.3527075e-16,9.1045695 0,8 C-1.3527075e-16,6.8954305 0.8954305,6 2,6 C2.93191971,6 3.71496986,6.63738639 3.93699126,7.5 L11.0630087,7.5 C11.2850301,6.63738639 12.0680803,6 13,6 C14.1045695,6 15,6.8954305 15,8 C15,9.1045695 14.1045695,10 13,10 C12.0680803,10 11.2850301,9.36261361 11.0630087,8.5 L3.93699126,8.5 Z M13,7 C12.4477153,7 12,7.44771525 12,8 C12,8.55228475 12.4477153,9 13,9 C13.5522847,9 14,8.55228475 14,8 C14,7.44771525 13.5522847,7 13,7 Z M2,7 C1.44771525,7 1,7.44771525 1,8 C1,8.55228475 1.44771525,9 2,9 C2.55228475,9 3,8.55228475 3,8 C3,7.44771525 2.55228475,7 2,7 Z'
          id='Combined-Shape'
          fill='#689BFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
