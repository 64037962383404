import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { useRendererApiContext } from '../../drawings-contexts';
import { DrawingsInstanceType } from '../../enums';
import { useCurrentDrawingInfo, useInstances } from '../../hooks';
import { DrawingsBooleanOperationsUtils } from '../../utils/drawings-boolean-operations-utils';

export function useGetCanJoin(instancesIds: string[]): (InstanceType: DrawingsInstanceType) => boolean {
  const { rendererApi } = useRendererApiContext();
  const instances = useInstances();
  const hiddenIds = useSelector<State, string[]>((s) => s.drawings.hiddenInstances);
  const currentDrawing = useCurrentDrawingInfo();
  const currentDrawingId = currentDrawing?.drawingId;
  const hasRendererApi = !!rendererApi;

  return useCallback(
    (instanceType: DrawingsInstanceType) => {
      return (
        hasRendererApi &&
        DrawingsBooleanOperationsUtils.canJoin({
          hiddenInstances: hiddenIds,
          currentDrawingId,
          selectedInstances: instancesIds,
          instanceType,
          getInstance: (instanceId: string) => {
            return instances[instanceId];
          },
        })
      );
    },
    [instancesIds, currentDrawingId, instances, hiddenIds, hasRendererApi],
  );
}

export function useCanJoin(instancesIds: string[], instanceType: DrawingsInstanceType): boolean {
  const getCanJoin = useGetCanJoin(instancesIds);
  return useMemo(() => getCanJoin(instanceType), [getCanJoin, instanceType]);
}
