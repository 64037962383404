import * as React from 'react';

export const IconUserSettings: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M10.2941176,1.7816893 L10.2941176,1.47058824 C10.2941176,1.21068953 10.5048072,
      1 10.7647059,1 L11.2352941,1 C11.4951928,1 11.7058824,1.21068953 11.7058824,1.47058824 L11.7058824,
      1.7816893 C12.0924464,1.86608569 12.4536394,2.01835677 12.7763452,2.22538635 L12.9965368,
      2.00519481 C13.1803129,1.82141867 13.4782729,1.82141867 13.6620491,2.00519481 L13.9948052,
      2.33795094 C14.1785813,2.52172708 14.1785813,2.81968707 13.9948052,3.00346321 L13.7746136,
      3.22365475 C13.9816432,3.5463606 14.1339143,3.90755363 14.2183107,4.29411765 L14.5294118,
      4.29411765 C14.7893105,4.29411765 15,4.50480718 15,4.76470588 L15,5.23529412 C15,
      5.49519282 14.7893105,5.70588235 14.5294118,5.70588235 L14.2183107,5.70588235 C14.1339143,
      6.09244637 13.9816432,6.4536394 13.7746136,6.77634525 L13.9948052,6.99653679 C14.1785813,
      7.18031293 14.1785813,7.47827292 13.9948052,7.66204906 L13.6620491,7.99480519 C13.4782729,
      8.17858133 13.1803129,8.17858133 12.9965368,7.99480519 L12.7763452,7.77461365 C12.4536394,
      7.98164323 12.0924464,8.13391431 11.7058824,8.2183107 L11.7058824,8.52941176 C11.7058824,
      8.78931047 11.4951928,9 11.2352941,9 L10.7647059,9 C10.5048072,9 10.2941176,8.78931047 10.2941176,
      8.52941176 L10.2941176,8.2183107 C9.90755363,8.13391431 9.5463606,7.98164323 9.22365475,
      7.77461365 L9.00346321,7.99480519 C8.81968707,8.17858133 8.52172708,8.17858133 8.33795094,
      7.99480519 L8.00519481,7.66204906 C7.82141867,7.47827292 7.82141867,7.18031293 8.00519481,
      6.99653679 L8.22538635,6.77634525 C8.01835677,6.4536394 7.86608569,6.09244637 7.7816893,
      5.70588235 L7.47058824,5.70588235 C7.21068953,5.70588235 7,5.49519282 7,5.23529412 L7,
      4.76470588 C7,4.50480718 7.21068953,4.29411765 7.47058824,4.29411765 L7.7816893,
      4.29411765 C7.86608569,3.90755363 8.01835677,3.5463606 8.22538635,3.22365475 L8.00519481,
      3.00346321 C7.82141867,2.81968707 7.82141867,2.52172708 8.00519481,2.33795094 L8.33795094,
      2.00519481 C8.52172708,1.82141867 8.81968707,1.82141867 9.00346321,2.00519481 L9.22365475,
      2.22538635 C9.5463606,2.01835677 9.90755363,1.86608569 10.2941176,1.7816893 Z M3.47937624,
      9.29917416 C3.18049533,8.94967955 3,8.49591825 3,8 C3,6.8954305 3.8954305,6 5,6 C6.1045695,6 7,
      6.8954305 7,8 C7,8.49591825 6.81950467,8.94967955 6.52062376,9.29917416 C7.97560476,
      9.89764946 9,11.3291946 9,13 C9,13.2761424 8.77614237,13.5 8.5,13.5 C8.22385763,
      13.5 8,13.2761424 8,13 C8,11.3431458 6.65685425,10 5,10 C3.34314575,10 2,11.3431458 2,
      13 C2,13.2761424 1.77614237,13.5 1.5,13.5 C1.22385763,13.5 1,13.2761424 1,13 C1,
      11.3291946 2.02439524,9.89764946 3.47937624,9.29917416 Z M11,6 C11.5522847,6 12,
      5.55228475 12,5 C12,4.44771525 11.5522847,4 11,4 C10.4477153,4 10,4.44771525 10,
      5 C10,5.55228475 10.4477153,6 11,6 Z M5,9 C5.55228475,9 6,8.55228475 6,8 C6,7.44771525 5.55228475,
      7 5,7 C4.44771525,7 4,7.44771525 4,8 C4,8.55228475 4.44771525,9 5,9 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
        />
      </g>
    </svg>
  );
};
