import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import './database-activity-code-group-item.scss';

import { StringDictionary } from 'common/interfaces/dictionary';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoInput } from 'common/UIKit';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { Item } from '../../../../components/treeview/tree-view';
import { ActivityCategoryType } from '../../enums';
import { CodeGroupModel } from '../../interfaces/data';
import { CodeGroupElement } from './database-activity-code-group-element';

interface ReduxProps {
  categories: StringDictionary<Item[]>;
  databaseId: number;
}

export interface CodeGroupItemProps {
  codeGroup: CodeGroupModel;
  index: number;
  error?: string;
  readonly: boolean;
  onDelete: (index: number) => void;
  onCodeGroupChange: (index: number, codeGroup: CodeGroupModel) => void;
  onDuplicate: (codeGroup: CodeGroupModel) => void;
}

interface Props extends CodeGroupItemProps, ReduxProps {}


export class CodeGroupItemComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const className = classNames(
      'database-activity-code-group', {
        'database-activity-code-group--invalid': !!this.props.error,
      },
    );
    return (
      <React.Fragment>
        {
          this.props.error ? (
            <span className='database-activity-code-group__error-message'>{this.props.error}</span>
          ) : null
        }
        <div className={className}>
          <div className='database-activity-code-group__row'>
            <CodeGroupElement
              className='database-activity-code-group__uni'
              items={this.props.categories[ActivityCategoryType.UniSystem]}
              onSelect={this.updateUniSystemCode}
              itemId={this.props.codeGroup.uniSystem && this.props.codeGroup.uniSystem.id}
              isOnlyLeavesSelect={false}
              name='UniSystem'
              readonly={this.props.readonly}
            />
            <CodeGroupElement
              className='database-activity-code-group__uni'
              items={this.props.categories[ActivityCategoryType.UniProduct]}
              onSelect={this.updateUniProductCode}
              itemId={this.props.codeGroup.uniProduct && this.props.codeGroup.uniProduct.id}
              isOnlyLeavesSelect={false}
              name='UniProduct'
              readonly={this.props.readonly}
            />
            <CodeGroupElement
              className='database-activity-code-group__nrm-1'
              items={this.props.categories[ActivityCategoryType.Nrm1]}
              onSelect={this.updateNrm1Code}
              itemId={this.props.codeGroup.nrm1 && this.props.codeGroup.nrm1.id}
              isOnlyLeavesSelect={true}
              name='NRM1'
              readonly={this.props.readonly}
            />
            <CodeGroupElement
              className='database-activity-code-group__nrm-2'
              items={this.props.categories[ActivityCategoryType.Nrm2]}
              onSelect={this.updateNrm2Code}
              itemId={this.props.codeGroup.nrm2 && this.props.codeGroup.nrm2.id}
              isOnlyLeavesSelect={true}
              name='NRM2'
              readonly={this.props.readonly}
            />
          </div>
          <div className='database-activity-code-group__row'>
            <KreoInput
              placeholder={'Category'}
              value={this.props.codeGroup.category ? this.props.codeGroup.category : ''}
              onChange={this.updateCategory}
            />
            <KreoInput
              placeholder={'Family'}
              value={this.props.codeGroup.family ? this.props.codeGroup.family : ''}
              onChange={this.updateFamily}
            />
            <KreoInput
              placeholder={'Element Type'}
              value={this.props.codeGroup.elementType ? this.props.codeGroup.elementType : ''}
              onChange={this.updateElementType}
            />
            <KreoInput
              placeholder={'Type Comments'}
              value={this.props.codeGroup.comment ? this.props.codeGroup.comment : ''}
              onChange={this.updateComment}
            />
          </div>
          {
            !this.props.readonly ? (
              <ItemMenu
                className='database-activity-code-group__menu'
                menu={this.getMenuItems()}
              />
            ) : null
          }
        </div>
      </React.Fragment>
    );
  }

  private getMenuItems(): ItemMenuType[] {
    return [
      {
        action: this.onDuplicate,
        name: 'Duplicate',
      },
      {
        action: this.onDelete,
        name: 'Delete',
      },
    ];
  }

  @autobind
  private onDelete(): void {
    this.props.onDelete(this.props.index);
  }

  @autobind
  private onDuplicate(): void {
    this.props.onDuplicate(this.props.codeGroup);
  }

  @autobind
  private updateUniSystemCode(item: Item): void {
    const uniSystem = item
      ? {
        id: item.id,
        code: item.code,
        title: item.title,
      }
      : null;

    this.props.onCodeGroupChange(
      this.props.index,
      {
        ...this.props.codeGroup,
        uniSystem,
      },
    );
  }

  @autobind
  private updateUniProductCode(item: Item): void {
    const uniProduct = item
      ? {
        id: item.id,
        code: item.code,
        title: item.title,
      }
      : null;

    this.props.onCodeGroupChange(
      this.props.index,
      {
        ...this.props.codeGroup,
        uniProduct,
      },
    );
  }

  @autobind
  private updateNrm1Code(item: Item): void {
    const nrm1 = item
      ? {
        id: item.id,
        code: item.code,
        title: item.title,
      }
      : null;

    this.props.onCodeGroupChange(
      this.props.index,
      {
        ...this.props.codeGroup,
        nrm1,
      },
    );
  }

  @autobind
  private updateNrm2Code(item: Item): void {
    const nrm2 = item
      ? {
        id: item.id,
        code: item.code,
        title: item.title,
      }
      : null;

    this.props.onCodeGroupChange(
      this.props.index,
      {
        ...this.props.codeGroup,
        nrm2,
      },
    );
  }

  @autobind
  private updateCategory(e: React.ChangeEvent<HTMLInputElement>): void {
    const category = e.target.value || null;
    this.props.onCodeGroupChange(
      this.props.index,
      {
        ...this.props.codeGroup,
        category,
      },
    );
  }

  @autobind
  private updateFamily(e: React.ChangeEvent<HTMLInputElement>): void {
    const family = e.target.value || null;
    this.props.onCodeGroupChange(
      this.props.index,
      {
        ...this.props.codeGroup,
        family,
      },
    );
  }

  @autobind
  private updateElementType(e: React.ChangeEvent<HTMLInputElement>): void {
    const elementType = e.target.value || null;
    this.props.onCodeGroupChange(
      this.props.index,
      {
        ...this.props.codeGroup,
        elementType,
      },
    );
  }

  @autobind
  private updateComment(e: React.ChangeEvent<HTMLInputElement>): void {
    const comment = e.target.value || null;
    this.props.onCodeGroupChange(
      this.props.index,
      {
        ...this.props.codeGroup,
        comment,
      },
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    categories: state.database.categories as StringDictionary<Item[]>,
    databaseId: state.database.currentDatabase.database.id,
  };
};


export const CodeGroupItem = connect(mapStateToProps)(CodeGroupItemComponent);


export const CodeGroupItemField: React.FC<CodeGroupItemProps> = (
  props: WrappedFieldProps & CodeGroupItemProps,
): JSX.Element => {
  const {
    meta: { submitFailed, error },
  } = props;

  return (
    <CodeGroupItem
      error={submitFailed && error}
      codeGroup={props.codeGroup}
      onDelete={props.onDelete}
      onDuplicate={props.onDuplicate}
      onCodeGroupChange={props.onCodeGroupChange}
      index={props.index}
      readonly={props.readonly}
    />
  );
};
