import { ActionWith } from 'common/interfaces/action-with';
import { AutoMeasurePageSetting } from '../../enums/auto-measure-page-setting';
import { AutoMeasureOptionResponse } from '../../interfaces';
import { AutoMeasureActionTypes } from '../types/auto-measure';

function saveAvailableOptions(options: AutoMeasureOptionResponse[]): ActionWith<AutoMeasureOptionResponse[]> {
  return {
    type: AutoMeasureActionTypes.SAVE_AVAILABLE_OPTIONS,
    payload: options,
  };
}

function setSelectedOptions(values: number[]): ActionWith<number[]> {
  return {
    type: AutoMeasureActionTypes.SET_SELECTED_OPTIONS,
    payload: values,
  };
}

function startCalculation(setting: AutoMeasurePageSetting): ActionWith<AutoMeasurePageSetting> {
  return {
    type: AutoMeasureActionTypes.START_CALCULATION,
    payload: setting,
  };
}


export const AutoMeasureActions = {
  saveAvailableOptions,
  setSelectedOptions,
  startCalculation,
};
