import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 25px;
  width: 180px;
  > div {
    padding-left: 5px;
  }
  > button {
    margin-top: 3px;
  }
`;

export const Styled = {
  Container,
};
