import { ProjectResourcesApi } from '../../../api/server';
import { CommentModel, CommentsC, CommentStatus } from '../../viewer/interfaces';
import { RevitTreeFullInfo } from '../interfaces/revit-tree-full-info';

function createComment(data: any): Promise<any> {
  return ProjectResourcesApi.post(
    `/comments`,
    data,
    'Error in creating new comment',
  );
}

function getAllComments(): Promise<CommentModel[]> {
  return ProjectResourcesApi.getV(
    `/comments`,
    CommentsC,
    'Error int getting comments',
  );
}

function getUserComments(): Promise<CommentModel[]> {
  return ProjectResourcesApi.getV(
    `/comments/my`,
    CommentsC,
    'Error int getting user comments',
  );
}

function addSubComment(parentCommentId: number, linkCommentId: number, data: any): Promise<any> {
  return ProjectResourcesApi.put(
    `/comments/${parentCommentId}/${linkCommentId}`,
    data,
    'Error in adding sub comment',
  );
}

function removeComment(commentId: number): Promise<void> {
  return ProjectResourcesApi.delete(
    `/comments/${commentId}`,
    'Error in deleting comment',
  );
}

function updateStatus(commentId: number, status: CommentStatus): Promise<void> {
  return ProjectResourcesApi.post(
    `/comments/${commentId}/${status}`,
    null,
    'Error in updating comment status',
  );
}

function readComment(commentId: number): Promise<boolean> {
  return ProjectResourcesApi.post(
    `/comments/${commentId}/read`,
    null,
    'Error in mark as read comment',
  );
}

function loadFullBimInfo(): Promise<RevitTreeFullInfo> {
  return ProjectResourcesApi.get(`/bim-full-info`, 'Error in loading full bim info');
}

function getBimGeometry(): Promise<any> {
  return ProjectResourcesApi.getBinary(
    `/geometry`,
    'Error in loading geometry');
}

export const ViewerApi = {
  createComment,
  getAllComments,
  getUserComments,
  addSubComment,
  loadFullBimInfo,
  removeComment,
  updateStatus,
  readComment,
  getBimGeometry,
};
