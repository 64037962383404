import styled from 'styled-components';

const Container = styled.div`
  gap: 12px;
  flex-flow: column;
  padding: 10px;
  display: flex;
  border-top: 1px solid ${p => p.theme.color.background};
`;

export const Styled = {
  Container,
};
