import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconDots: React.FC<{ color?: string }> = ({ color = KreoColors.f1 }) => {
  return (
    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='icon_action' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icon'>
          <rect id='Rectangle-7' x='0' y='0' width='14' height='14' />
          <path
            d='M2,6 L4,6 L4,8 L2,8 L2,6 Z M6,6 L8,6 L8,8 L6,8 L6,6 Z M10,6 L12,6 L12,8 L10,8 L10,6 Z'
            id='Combined-Shape'
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};
