import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './indirect-cost-tile.scss';

import { KreoIconSelectArrow } from 'common/UIKit/icons/select-arrow';
import { WorkPackagesData } from '../../../scenarios';
import { IndirectCostTileItemComponent } from '../indirect-cost-tile-item';

interface Props {
  item: WorkPackagesData.IndirectCost;
  children: Array<React.ReactElement<IndirectCostTileItemComponent>>;
}

interface State {
  expanded: boolean;
}

export class IndirectCostTile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  public render(): JSX.Element {
    const { item, children } = this.props;
    return (
      <div className={classNames('indirect-cost-tile', { 'expanded': this.state.expanded })}>
        <div className='indirect-cost-tile__title' onClick={this.onClickExpandAction}>
          <div className='indirect-cost-tile__expand-icon'><KreoIconSelectArrow /></div>
          {item.name}
        </div>
        <div className='indirect-cost-tile__items'>{children}</div>
      </div>
    );
  }

  @autobind
  private onClickExpandAction(): void {
    this.setState({ expanded: !this.state.expanded });
  }
}
