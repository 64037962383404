import autobind from 'autobind-decorator';
import React from 'react';
import { Field } from 'redux-form';
import { MaterialNumberInputField, MaterialNumberInputProps } from 'common/UIKit';
import { ExtractorParameterNameHelper } from '../../utils/extractor-parameter-name-helper';


interface Props {
  fieldName: string;
  value: number;
  error?: string;
  readonly: boolean;
  id: string;
  unitAcronym: string;
  index: number;
  onChange: (index: number, value: number) => void;
}

export class DatabaseActivityVariantMeasurementParameterItem extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div key={this.props.id} className='database-activity-variant-measurement-parameter-item'>
        <div className='database-activity-variant-measurement-parameter-item__name'>
          {ExtractorParameterNameHelper.getName(this.props.id)}
        </div>
        <Field<MaterialNumberInputProps>
          name={this.props.fieldName}
          component={MaterialNumberInputField}
          className='database-activity-variant-measurement-parameter-item__value'
          value={this.props.value}
          valueType='float'
          isFloatingLabel={false}
          onChange={this.onChange}
          precision={2}
          disabled={this.props.readonly}
          displayBottomInformation={true}
        />
        <div className='database-activity-variant-measurement-parameter-item__unit'>
          {this.props.unitAcronym}
        </div>
      </div>
    );
  }

  @autobind
  private onChange(_: React.ChangeEvent, newValue?: number | null): void {
    this.props.onChange(this.props.index, newValue);
  }
}
