import React, { useCallback } from 'react';

import { Fields } from './enums';
import { ModeButton } from './mode-button';
import { Styled } from './styled';

interface Props {
  activeField: Fields;
  onChange: (field: Fields) => void;
}

export const ModesButtons: React.FC<Props> = ({ activeField, onChange }) => {

  const setPrefixRenameMode = useCallback(() => {
    onChange(Fields.Prefix);
  }, []);

  const setReplaceRenameMode = useCallback(() => {
    onChange(Fields.Replace);
  }, []);

  const setSuffixRenameMode = useCallback(() => {
    onChange(Fields.Suffix);
  }, []);

  return (
    <Styled.ModeButtons>
      <ModeButton
        width={75}
        text={Fields.Prefix}
        isActive={activeField=== Fields.Prefix}
        onClick={setPrefixRenameMode}
      />
      <ModeButton
        width={130}
        text={Fields.Replace}
        isActive={activeField=== Fields.Replace}
        onClick={setReplaceRenameMode}
      />
      <ModeButton
        width={75}
        text={Fields.Suffix}
        isActive={activeField=== Fields.Suffix}
        onClick={setSuffixRenameMode}
      />
    </Styled.ModeButtons>
  );
};
