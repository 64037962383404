import autobind from 'autobind-decorator';
import * as React from 'react';

import './tree.scss';

import { QuickSearchInput } from 'common/components/quick-search';
import { VirtualList } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { RevitTreeLevel } from '../../../../units/projects/enums/revit-tree-level';
import { ModelBrowserTreeItem  } from '../../interfaces';
import { TreeDataInterface } from './tree-data';
import { TreeItem } from './tree-item';

interface Props {
  data: any;
  searchMode: boolean;
  showProps: boolean;
  onExpandElement: (identifier: string) => void;
  onHide: (item: ModelBrowserTreeItem) => void;
  onShow: (item: ModelBrowserTreeItem) => void;
  onGhost: (item: ModelBrowserTreeItem) => void;
  search: (value: string) => void;
  onUpdate: () => void;
  onSelect: (item: ModelBrowserTreeItem, identificator: string) => void;
  onAdd: (item: ModelBrowserTreeItem, identificator: string) => void;
  onDeselect: (item: ModelBrowserTreeItem, identificator: string) => void;
  getTreeList: (ref: VirtualList) => void;
  showLayers?: boolean;
}

export class Tree extends React.Component<Props> {
  public viewport: HTMLDivElement = undefined;
  private virtualListRef: VirtualList = null;
  private needUpdateTree: boolean = false;
  private value: string = '';

  public shouldComponentUpdate(nextProps: any): boolean {
    if (nextProps.isEngineEvent) {
      this.needUpdateTree = true;
    }
    return true;
  }

  public render(): JSX.Element {
    const rows = this.buildRows(this.props.data, 0, '', false);
    return (
      <div
        ref={this.setViewPort}
        id='treecontainer'
        className={`viewer-tree-container ${this.props.showProps ? 'props' : ''}`}
      >
        <div className='quick-search-input'>
          <QuickSearchInput
            placeholder='Search'
            isQuickSearchVisible={true}
            setQuickSearchValue={this.onSearchFielChanges}
            value={this.value ? this.value : ''}
          />
        </div>
        <VirtualList
          ref={this.setVirtualListRef}
          updated={this.treeUpdated}
          bufferSize={30}
          viewport={this.viewport}
          items={rows}
          itemHeight={40}
          updatelist={this.needUpdateTree}
          itemFactory={this.rowRenderer}
        />
      </div>
    );
  }


  @autobind
  private onSearchFielChanges(value: string): void {
    this.needUpdateTree = true;
    this.value = value;
    this.props.search(value);
  }

  @autobind
  private treeUpdated(): void {
    this.needUpdateTree = false;
    this.props.onUpdate();
  }


  private buildRows(
    data: ModelBrowserTreeItem[],
    level: RevitTreeLevel,
    parentIdentifier: string,
    parentHasSearchMatches: boolean,
  ): TreeDataInterface[] {
    const ret = [];
    const isSearchEnabled = this.props.searchMode;
    for (let i = 0; i < data.length; i++) {
      const treeItem = data[i];
      if (!isSearchEnabled || treeItem.search || treeItem.hasMatchedChild || parentHasSearchMatches) {
        const identifier = `${parentIdentifier}${i}`;
        if (treeItem.v && treeItem.v.length > 0) {
          treeItem.name = treeItem.n;
          const processedTreeItem = this.createTreeDataEntity(treeItem, identifier, level, true);
          ret.push(processedTreeItem);
          if (treeItem.expanded) {
            arrayUtils.extendArray(ret, this.buildRows(
              treeItem.v, level + 1, `${identifier}.v.`, treeItem.search || parentHasSearchMatches));
          }
        } else {
          treeItem.name = `${treeItem.n} (${treeItem.end - treeItem.start})`;
          const item =  this.createTreeDataEntity(treeItem, identifier, level, false);
          ret.push(item);
        }
      }
    }
    return ret;
  }

  @autobind
  private setViewPort(viewport: HTMLDivElement): void {
    if (viewport === null) return;
    this.viewport = viewport;
    const observer = new MutationObserver(this.scrollList);
    observer.observe(viewport, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
    });
  }

  private createTreeDataEntity(
    treeItem: ModelBrowserTreeItem,
    identifier: string,
    level: RevitTreeLevel,
    expandable: boolean,
  ): TreeDataInterface {
    return {
      data: treeItem,
      identifier,
      level,
      expandable,
    };
  }


  @autobind
  private scrollList(mutations: MutationRecord[]): void {
    if (!this.virtualListRef) return;
    if ((mutations[0].target as HTMLDivElement).classList.length > 1) {
      this.virtualListRef.scrollAffterResize();
    }
  }


  @autobind
  private setVirtualListRef(list: VirtualList): void {
    this.virtualListRef = list;
    this.props.getTreeList(list);
  }

  @autobind
  private onSelect(item: ModelBrowserTreeItem, identificator: string): void {
    this.needUpdateTree = true;
    this.props.onSelect(item, identificator);
  }

  @autobind
  private hideItemEngineIds(item: ModelBrowserTreeItem): void {
    this.needUpdateTree = true;
    this.props.onHide(item);
  }

  @autobind
  private showItemEngineIds(item: ModelBrowserTreeItem): void {
    this.needUpdateTree = true;
    this.props.onShow(item);
  }

  @autobind
  private ghostItemEngineIds(item: ModelBrowserTreeItem): void {
    this.needUpdateTree = true;
    this.props.onGhost(item);

  }

  @autobind
  private toggleSelectStatus(item: ModelBrowserTreeItem, identifier: string): void {
    this.needUpdateTree = true;
    if (item.selected) {
      this.props.onDeselect(item, identifier);
    } else {
      this.props.onAdd(item, identifier);
    }
  }

  @autobind
  private rowRenderer(item: TreeDataInterface): JSX.Element {
    const { data, expandable, identifier, level } = item;
    const matched = this.props.searchMode && data.search;
    return (
      <TreeItem
        key={identifier}
        item={data}
        expandable={expandable}
        identifier={identifier}
        level={level}
        matched={matched}
        toggleSelectStatus={this.toggleSelectStatus}
        toggleCollapseExpand={this.props.onExpandElement}
        hide={this.hideItemEngineIds}
        show={this.showItemEngineIds}
        ghost={this.ghostItemEngineIds}
        onSelect={this.onSelect}
      />);
  }
}
