import React, { Component } from 'react';

import { KreoColors } from 'common/enums/kreo-colors';


class KreoIconCancel extends Component {
  static defaultProps = {
    onClick: () => {},
    color: KreoColors.f1,
    size: 8
  }

  shouldComponentUpdate(): any {
    return false;
  }

  render(): any {
    const size = this.props.size;
    return (
      <svg
        width="16px"
        height="16px"
        onClick={this.props.onClick}
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs/>
        <g
          id="icon_cancel"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd">
          <g id="icon">
            <rect id="Rectangle-7" x="0" y="0" width="16" height="16"/>
            <g
              id="Group"
              transform={`translate(${(16 - size)/ 2}, ${(16 - size)/2})`}
              stroke={this.props.color}
              strokeLinecap="round"
              strokeLinejoin="round">
              <path d={`M${size},${size} L0,0`} id="Rectangle-5"/>
              <path d={`M0,${size} L${size},0`} id="Rectangle-5"/>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default KreoIconCancel;