import * as React from 'react';

import './table-header.scss';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { Header } from '../table/header';

interface TableHeaderProps extends I18nAwareProps {
  includeDuration: boolean;
  fixed: boolean;
}

class TableHeaderComponent extends React.Component<TableHeaderProps> {
  public render(): React.ReactNode {
    const { i18n, includeDuration } = this.props;
    return (
      <Header fixed={this.props.fixed} >
        <div className='cost-estimate-table__name'>
          <div className='cost-estimate-table__caption'>NAME</div>
          <div className='cost-estimate-table__unit'>
            <div className='cost-estimate-table__caption' title='Units'>U.</div>
          </div>
          <div className='cost-estimate-table__quantity'>
            <div className='cost-estimate-table__caption'>QUANTITY</div>
          </div>
        </div>
        <div className='cost-estimate-table__price'>
          <div className='cost-estimate-table__caption rate'>RATE</div>
          <div className='cost-estimate-table__caption name'>LABOUR</div>
          <div className='cost-estimate-table__caption cost'>COST</div>
          <div className='cost-estimate-table__sub-row'>{i18n.currency.symbol}</div>
        </div>
        <div className='cost-estimate-table__price'>
          <div className='cost-estimate-table__caption rate'>RATE</div>
          <div className='cost-estimate-table__caption name'>PLANT</div>
          <div className='cost-estimate-table__caption cost'>COST</div>
          <div className='cost-estimate-table__sub-row'>{i18n.currency.symbol}</div>
        </div>
        <div className='cost-estimate-table__price material'>
          <div className='cost-estimate-table__caption rate'>RATE</div>
          <div className='cost-estimate-table__caption name'>MATERIAL</div>
          <div className='cost-estimate-table__caption cost'>COST</div>
          <div className='cost-estimate-table__sub-row'>{i18n.currency.symbol}</div>
        </div>
        <div className='cost-estimate-table__price'>
          <div className='cost-estimate-table__caption rate'>RATE</div>
          <div className='cost-estimate-table__caption name'>DIRECT</div>
          <div className='cost-estimate-table__caption cost'>COST</div>
          <div className='cost-estimate-table__sub-row'>{i18n.currency.symbol}</div>
        </div>
        <div className='cost-estimate-table__price'>
          <div className='cost-estimate-table__caption overhead' title='OVERHEAD AND PROFIT'>
            O&amp;P
            <div className='cost-estimate-table__sub-row'>{i18n.currency.symbol}</div>
          </div>
        </div>
        <div className='cost-estimate-table__price'>
          <div className='cost-estimate-table__caption rate'>RATE</div>
          <div className='cost-estimate-table__caption name'>TOTAL</div>
          <div className='cost-estimate-table__caption cost'>COST</div>
          <div className='cost-estimate-table__sub-row'>{i18n.currency.symbol}</div>
        </div>
        {includeDuration ? (
          <div className='cost-estimate-table__days'>
            <div className='cost-estimate-table__caption days'>
              DURATION<div className='cost-estimate-table__sub-row'>days</div>
            </div>
          </div>
        ) : null}
      </Header>
    );
  }
}

export const TableHeader = withI18n(TableHeaderComponent);
