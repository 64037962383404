import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseResourceActions } from '../../actions/creators/database-resource';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { DatabaseResourceVariantActions } from '../../actions/creators/database-resource-variant';
import { ModifyDatabaseEntityMode, ResourceType } from '../../enums';
import { MaterialModel, MaterialVariantModel } from '../../interfaces/resources-data';
import { MaterialVariantForm } from '../../interfaces/resources-rest-data';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';
import { DatabaseResourceMaterialVariant } from './database-resource-material-variant';

interface ReduxProps {
  variant: MaterialVariantModel;
  resource: MaterialModel;
  resourceId: number;
  databaseId: number;
}

interface ReduxActions {
  setEditResourceVariantModel: (variant: MaterialVariantModel) => void;
  loadDatabaseResource: (databaseId: number, resourceId: number, type: ResourceType) => void;
  createDatabaseResourceVariant: (
    databaseId: number, resourceId: number, type: ResourceType, model: MaterialVariantForm, formId: string,
  ) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps {
}

class CreateDatabaseResourceMaterialVariantComponent extends React.Component<Props> {
  private readonly formId: string = 'create-material-variant';

  public componentDidMount(): void {
    this.props.loadDatabaseResource(this.props.databaseId, this.props.resourceId, ResourceType.Material);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.resource && !prevProps.resource) {
      this.props.setEditResourceVariantModel(this.getDefaultVariantModel());
    }
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Material Variant'
        isApplyButtonDisabled={this.isCreateButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Create}
        onSubmit={this.create}
        form={this.formId}
        readonly={this.props.readonly}
        initialValues={this.getDefaultVariantForm()}
      >
      {
        this.props.resource && this.props.variant ? (
          <KreoScrollbars>
            <DatabaseResourceMaterialVariant
              resource={this.props.resource}
              variant={this.props.variant}
              onChange={this.props.setEditResourceVariantModel}
              readonly={this.props.readonly}
            />
          </KreoScrollbars>
        ) : <SvgSpinner size='middle'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }


  @autobind
  private create(values: MaterialVariantForm): void {
    this.props.createDatabaseResourceVariant(
      this.props.databaseId, this.props.resourceId, ResourceType.Material, values, this.formId,
    );
  }

  private isCreateButtonDisabled(): boolean {
    const { variant } = this.props;
    return !variant ||
      !variant.name ||
      !variant.name.trim() ||
      !variant.unitId ||
      !(variant.cost >= 0) ||
      !(variant.amount >= 0);
  }

  private getDefaultVariantModel(): MaterialVariantModel {
    return {
      id: 0,
      name: '',
      cost: null,
      resourceId: this.props.resourceId,
      amount: null,
      unitId: this.props.resource && this.props.resource.defaultUnitId,
    };
  }

  private getDefaultVariantForm(): MaterialVariantForm {
    return {
      id: 0,
      name: '',
      cost: 0,
      amount: 0,
      unitId: this.props.resource && this.props.resource.defaultUnitId,
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    variant: editModel.variant,
    resource: editModel.root,
    resourceId: editModel.rootId,
    databaseId: state.database.currentDatabase.database.id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditResourceVariantModel: variant =>
      dispatch(DatabaseResourceListingActions.setEditResourceVariantModel(variant)),
    createDatabaseResourceVariant: (databaseId, resourceId, type, model, formId) =>
      dispatch(DatabaseResourceVariantActions.createResourceVariant(databaseId, resourceId, type, model, formId)),
    loadDatabaseResource: (databaseId, resourceId, type) =>
      dispatch(DatabaseResourceActions.loadResource(databaseId, resourceId, type)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const CreateDatabaseResourceMaterialVariant = connector(CreateDatabaseResourceMaterialVariantComponent);
