import { NumberDictionary } from '../../common/interfaces/dictionary';

interface NodeHandle {
  value: number;
  isAvailable: boolean;
  isAttached: boolean;
  element: HTMLDivElement;
}

export type ReadonlyNodeHandle = Readonly<NodeHandle>;

export class DivPool {
  private nodesPool: NumberDictionary<NodeHandle[]> = {};
  private container: HTMLDivElement;
  private elementsClassName: string;

  constructor(container: HTMLDivElement, elementsClassName: string) {
    this.container = container;
    this.elementsClassName = elementsClassName;
  }

  public markAllAsAvailable(): void {
    const contentGroups = Object.values(this.nodesPool);
    for (const contentGroup of contentGroups) {
      for (const node of contentGroup) {
        node.isAvailable = true;
      }
    }
  }

  public getNodes(valuesParam: number[]): ReadonlyNodeHandle[] {
    const resultHandles: NodeHandle[] = [];
    let values = valuesParam.slice(0, valuesParam.length);
    let valuesWithoutNodes: number[] = [];

    // collect attached available nodes with same value
    for (const value of values) {
      const bucket = this.nodesPool[value] || [];
      const node = bucket.find((n) => n.isAvailable && n.isAttached);
      if (node) {
        node.isAvailable = false;
        resultHandles.push(node);
      } else {
        valuesWithoutNodes.push(value);
      }
    }

    if (valuesWithoutNodes.length === 0) {
      return resultHandles;
    }

    // collect attached available nodes (might have other value)
    values = valuesWithoutNodes;
    valuesWithoutNodes = [];

    const attachedAvailableNodes = this.getNodesFlatArray().filter((n) => n.isAvailable && n.isAttached);

    for (const value of values) {
      const node = attachedAvailableNodes.pop();
      if (node) {
        node.isAvailable = false;
        this.updateNodeValue(node, value);

        resultHandles.push(node);
      } else {
        valuesWithoutNodes.push(value);
      }
    }

    if (valuesWithoutNodes.length === 0) {
      return resultHandles;
    }

    // collect available detached nodes (might have other value)
    values = valuesWithoutNodes;
    valuesWithoutNodes = [];

    const detachedAvailableNodes = this.getNodesFlatArray().filter((n) => n.isAvailable);

    for (const value of values) {
      const node = detachedAvailableNodes.pop();
      if (node) {
        node.isAvailable = false;

        if (node.value !== value) {
          this.updateNodeValue(node, value);
        }

        this.attachNode(node);
        resultHandles.push(node);
      } else {
        valuesWithoutNodes.push(value);
      }
    }

    if (valuesWithoutNodes.length === 0) {
      return resultHandles;
    }

    // create new nodes
    values = valuesWithoutNodes;
    valuesWithoutNodes = [];

    for (const value of values) {
      const element = document.createElement('div');
      element.className = this.elementsClassName;
      element.innerText = this.formatValue(value);

      const node = {
        value,
        element,
        isAttached: false,
        isAvailable: false,
      };

      const bucket = this.nodesPool[value] || (this.nodesPool[value] = []);
      bucket.push(node);

      this.attachNode(node);

      resultHandles.push(node);
    }

    return resultHandles;
  }

  public detachAvailableNodes(): void {
    const attachedAvailableNodes = this.getNodesFlatArray().filter((n) => n.isAttached && n.isAvailable);
    for (const node of attachedAvailableNodes) {
      node.element.style.display = 'none';
      this.detachNode(node);
    }
  }

  private getNodesFlatArray(): NodeHandle[] {
    const flatArray: NodeHandle[] = [];
    const buckets = Object.values(this.nodesPool);
    for (const bucket of buckets) {
      flatArray.push(...bucket);
    }

    return flatArray;
  }

  private updateNodeValue(node: NodeHandle, newValue: number): void {
    this.nodesPool[node.value].splice(this.nodesPool[node.value].indexOf(node), 1);
    if (!this.nodesPool[newValue]) {
      this.nodesPool[newValue] = [];
    }

    this.nodesPool[newValue].push(node);
    node.value = newValue;
    node.element.innerText = this.formatValue(newValue);
  }

  private attachNode(node: NodeHandle): void {
    this.container.appendChild(node.element);
    node.isAttached = true;
  }

  private detachNode(node: NodeHandle): void {
    this.container.removeChild(node.element);
    node.isAttached = false;
  }

  private formatValue(value: number): string {
    return value.toString();
  }
}
