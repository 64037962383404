import { TwoDRegexGetter } from '../../../../units/2d/units/2d-regex';
import { ExcelFormulaHelper } from './excel-formula-helper';

export enum CellLinkRefType {
  AbsoluteCell,
  AbsoluteColumn,
  AbsoluteRow,
  Relative,
}

export const cellLinksTypes = [
  CellLinkRefType.AbsoluteCell,
  CellLinkRefType.AbsoluteColumn,
  CellLinkRefType.AbsoluteRow,
  CellLinkRefType.Relative,
];

const getNextCellLinkType = (currentType: CellLinkRefType): CellLinkRefType => {
  const currentTypeIndex = cellLinksTypes.indexOf(currentType);
  if (currentTypeIndex === cellLinksTypes.length - 1) {
    return cellLinksTypes[0];
  }

  return cellLinksTypes[currentTypeIndex + 1];
};

const getNewCellRef = (cellId: string, linkType: CellLinkRefType): string => {
  const cellField = TwoDRegexGetter.getFullCellField(cellId);
  const sheetName = cellField.sheetName ? `'${cellField.sheetName}'` : cellField.sheetName;
  return ExcelFormulaHelper.getCellLink(
    sheetName,
    cellField.columnId,
    cellField.rowId,
    linkType,
  );
};

const getCellLinkRefType = (cellId: string): CellLinkRefType => {
  const cellField = TwoDRegexGetter.getFullCellField(cellId);
  if (cellField.isColumnAbsolute && cellField.isRowAbsolute) {
    return CellLinkRefType.AbsoluteCell;
  }

  if (cellField.isColumnAbsolute) {
    return CellLinkRefType.AbsoluteColumn;
  }

  if (cellField.isRowAbsolute) {
    return CellLinkRefType.AbsoluteRow;
  }

  return CellLinkRefType.Relative;
};

const getAllCellLinkTypes = (cellId: string): string[] => {
  const cellField = TwoDRegexGetter.getFullCellField(cellId);

  return cellLinksTypes.map(
    type => ExcelFormulaHelper.getCellLink(cellField.sheetName, cellField.columnId, cellField.rowId, type),
  );
};

export const AbsoluteLinkUtils = {
  getNextCellLinkType,
  getNewCellRef,
  getCellLinkRefType,
  getAllCellLinkTypes,
};
