import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { CurrencyExchangeActions } from '../actions/creators';

import { CurrencyExchangeActionTypes } from '../actions/types';
import { ExchangeApi } from '../api/exchange-api';
import { Currency } from '../constants';

function* fetchCurrencyExchange(): SagaIterator {
  try {
    // чтоб не комментить все, пока не перейдем на новый бэк
    return;
    const rates: Record<string, number> = yield call(
      ExchangeApi.getExchange,
      Currency.GBP.shortcat,
      Object.keys(Currency),
    );
    yield put(CurrencyExchangeActions.setCurrencyRate(rates));
  } catch (e) {
    console.error('currencyExchange: failed to fetch currency exchange');
  }
}

export function* currencyExchangeSagas(): SagaIterator {
  yield takeLatest(CurrencyExchangeActionTypes.FETCH_CURRENCY_EXCHANGE, fetchCurrencyExchange);
}
