import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImgContainer = styled.div`
  width: 180px;
  height: 180px;
  margin-bottom: 20px;

  img {
    width: 180px;
    height: 180px;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 20px;
`;

const AddComment = styled.div`
  > button {
    background-color: ${p => p.theme.color.turquoise};
    border-color: ${p => p.theme.color.turquoise};

    > svg {
      fill: ${p => p.theme.color.white};
    }
    > p {
      color: ${p => p.theme.color.white};
      font-size: 14px;
    }
  }
`;

export const Styled = {
  Container,
  ImgContainer,
  TextContainer,
  AddComment,
};
