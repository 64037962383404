import * as React from 'react';
import { connect } from 'react-redux';

import { TextSearchResultsInfo, TextSearchSettings } from 'common/components/drawings/interfaces';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { ResultsStatus } from './results-status';
import { Styled } from './styled';
import { TextSearchResultBody } from './text-search-result-body/text-search-result-body';


interface StateProps {
  results: TextSearchResultsInfo;
  settings: TextSearchSettings;
}

interface OwnProps {
  isLoading: boolean;
  isError: boolean;
  onFocus: (id: number, drawingId: string) => void;
}

interface Props extends StateProps, OwnProps {
}


class TextSearchResultsComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    if (!this.props.settings.query) {
      return null;
    }
    return (
      <Styled.Container>
        <ResultsStatus
          isError={this.props.isError}
          isLoading={this.props.isLoading}
          count={this.props.results.count}
        />
        <RenderIf condition={!this.props.isLoading}>
          <Styled.TextSearchResultContainer>
            <TextSearchResultBody
              results={this.props.results.results}
              query={this.props.settings.query}
              caseSensitive={this.props.settings.caseSensitive}
              onFocus={this.props.onFocus}
            />
          </Styled.TextSearchResultContainer>
        </RenderIf>
      </Styled.Container>
    );
  }


}

function mapStateToProps(state: State): StateProps {
  return {
    results: state.drawings.textSearch.results,
    settings: state.drawings.textSearch.settings,
  };
}

export const TextSearchResults = connect(mapStateToProps)(TextSearchResultsComponent);
