import { ElementTooltip, MovableContextMenu, Waves } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  DragSource,
  DropTarget,
  DragSourceCollector,
  DropTargetCollector,
} from 'react-dnd';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { getMovingElement } from './dnd-util';
import { ContextMenuProps, ItemData } from './interfaces';
import { Styled } from './styled';
import { TabItemChangeNameInput } from './tab-item-change-name-input';

const TAB_ITEM_NAME = 'DND_SHEET-TAB-ITEM';

interface DnDProps {
  connectDropTarget: (element: JSX.Element) => JSX.Element;
  connectDragSource: (element: JSX.Element) => JSX.Element;
  connectDragPreview: (element: JSX.Element) => JSX.Element;
  isDragging: boolean;
}

export type ContextMenuType<T extends ItemData> = React.FC<ContextMenuProps<T>>;

interface OwnProps<T extends ItemData> {
  item: T;
  itemList: T[];
  index: number;
  isActive: boolean;
  canEdit: boolean;
  defaultColor: string;
  onClickTab: (id: string) => void;
  onChangeTabInfo: (tabInfo: T) => void;
  onMoveTo: (id: string, offset: number) => void;
  changePosition: (dragIndex: number, hoverIndex: number) => void;
  getOriginalTabIndex: (id: string) => number;
  ContextMenu: React.FC<ContextMenuProps<T>>;
}

interface State {
  isEditMode: boolean;
  name: string;
  isContextMenuOpened: boolean;
  contextMenuPosition: { x: number, y: number };
}

interface Props<T extends ItemData> extends OwnProps<T>, DnDProps {}

const NAME_MAX_GUARANTEED_LENGTH = 16;

class TabItemComponent<T extends ItemData> extends React.Component<Props<T>, State> {
  private divRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      isEditMode: false,
      name: this.props.item.name,
      isContextMenuOpened: false,
      contextMenuPosition: { x: 0, y: 0 },
    };
  }

  public componentDidUpdate(): void {
    if (!this.state.isEditMode && this.props.item.name !== this.state.name) {
      this.setState({
        name: this.props.item.name,
      });
    }
  }

  public render(): JSX.Element {
    const {
      connectDragPreview,
      connectDragSource,
      connectDropTarget,
      isDragging,
      isActive,
      item,
      ContextMenu,
      canEdit,
    } = this.props;
    const Postfix = item.postfix;

    return connectDragPreview(
      connectDragSource(
        connectDropTarget(
          <div
            style={{
              display: 'inherit',
              opacity: isDragging ? 0 : 1,
              cursor: canEdit ? 'grab' : 'default',
            }}
          >
            <Styled.TabItemContainer>
              <UpgradeWrapper isNeedUpdate={item.shouldUpgradePlan}>
                <Styled.TabItem
                  ref={this.divRef}
                  onMouseDown={this.stopPropagation}
                  onClick={this.onClickTab}
                  onDoubleClick={!item.shouldUpgradePlan && this.onEdit}
                  onContextMenu={!item.shouldUpgradePlan && this.openContextMenu}
                  isActive={isActive}
                  isOpenMenu={this.state.isContextMenuOpened}
                  activeColor={item.color || this.props.defaultColor}
                >
                  {this.state.isEditMode ? (
                    <TabItemChangeNameInput
                      className="table-tabs-item__input"
                      value={this.state.name}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      autoFocus={true}
                      keysToBlur={['Enter']}
                    />
                  ) : (
                    <span>
                      <ElementTooltip
                        text={this.state.name}
                        position={'top'}
                        speed={'s'}
                        disabled={this.state.name.length < NAME_MAX_GUARANTEED_LENGTH}
                        wordBreakAll={true}
                      >
                        <Styled.Name>{this.state.name}</Styled.Name>
                      </ElementTooltip>
                      {Postfix && <Styled.Postfix>{Postfix}</Styled.Postfix>}
                    </span>
                  )}
                  {!isActive && <Waves color="turquoise" />}
                </Styled.TabItem>
              </UpgradeWrapper>
            </Styled.TabItemContainer>

            {this.state.isContextMenuOpened && (
              <div onClick={this.stopEvent} onDoubleClick={this.stopEvent}>
                <MovableContextMenu
                  x={this.state.contextMenuPosition.x}
                  y={this.state.contextMenuPosition.y}
                  onClose={this.onContextMenuClose}
                >
                  <ContextMenu
                    item={this.props.item}
                    itemList={this.props.itemList}
                    index={this.props.index}
                    closeContextMenu={this.onContextMenuClose}
                    editItemName={this.onEdit}
                  />
                </MovableContextMenu>
              </div>
            )}
          </div>,
        ),
      ),
    );
  }

  private stopEvent(e: React.SyntheticEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  @autobind
  private stopPropagation(e: MouseEvent): void {
    if (this.state.isEditMode) {
      e.stopPropagation();
    }
  }

  @autobind
  private onClickTab(): void {
    this.props.onClickTab(this.props.item.id);
  }

  @autobind
  private onEdit(): void {
    if (this.props.canEdit) {
      this.onContextMenuClose();
      this.setState({ isEditMode: true });
    }
  }

  @autobind
  private onChange(value: string): void {
    this.setState({ name: value });
  }

  @autobind
  private onChangeTabInfo(pageInfo: T): void {
    this.props.onChangeTabInfo(pageInfo);
  }

  @autobind
  private onBlur(name: string): void {
    const { item } = this.props;
    if (item.name !== name) {
      this.onChangeTabInfo({ ...item, name });
    }
    this.setState({ isEditMode: false });
  }

  @autobind
  private openContextMenu(e: MouseEvent): void {
    e.preventDefault();
    if (this.props.canEdit) {
      this.setState({
        isContextMenuOpened: true,
        contextMenuPosition: { x: e.pageX, y: e.pageY },
      });
    }
  }

  @autobind
  private onContextMenuClose(): void {
    this.setState({ isContextMenuOpened: false });
  }
}

const specDrop = {
  hover: (props: Props<ItemData>, monitor, component) => {
    if (props.canEdit) {
      if (!component) {
        return null;
      }

      const node = component.divRef.current;
      const hoverIndex = props.index;

      const element = getMovingElement(hoverIndex, node, monitor);
      if (element) {
        const { dragElement, dragIndex } = element;
        props.changePosition(dragIndex, hoverIndex);
        dragElement.index = hoverIndex;
      }
    }
  },
};

const collectDrop: DropTargetCollector<any, any> = (connect) => {
  return {
    connectDropTarget: connect.dropTarget(),
  };
};

const specDrag = {
  endDrag: (props: Props<ItemData>) => {
    if (props.canEdit) {
      const dragIndex = props.getOriginalTabIndex(props.item.id);
      const offset = props.index - dragIndex;
      props.onMoveTo(props.item.id, offset);
    }
  },
  beginDrag: (props: Props<ItemData>) => {
    if (props.canEdit) {
      return { index: props.index };
    }
  },
};

const collectDrag: DragSourceCollector<any, any> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const dragConnector = DragSource<OwnProps<ItemData>, DnDProps>(
  TAB_ITEM_NAME,
  specDrag,
  collectDrag,
);

const dropConnector = DropTarget<OwnProps<ItemData>, DnDProps>(
  TAB_ITEM_NAME,
  specDrop,
  collectDrop,
);

export const TabItem = dropConnector(dragConnector(TabItemComponent));
