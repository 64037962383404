import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './bell-notifications-panel.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { EnhancedVirtualList } from 'common/UIKit';
import { KreoIconNotificationEmpty } from 'common/UIKit/icons';
import { NotificationActions } from '../../actions';
import { BellNotification } from './bell-notification';
import { BellNotificationHeader } from './bell-notification-header';
import { BellNotificationsPanelHeader } from './bell-notifications-panel-header';

interface ReduxProps {
  notificationsIds: React.ReactText[];
  showNotifications: boolean;
  loadStatus: RequestStatus;
}

interface ReduxActions {
  hideNotificationPanel(): void;
}

interface Props extends ReduxProps, ReduxActions {}

enum SubHeader {
  Today = 'today',
  Older = 'older',
}

class BellNotificationsPanelComponent extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Props): boolean {
    if (
      this.props.loadStatus !== nextProps.loadStatus ||
      this.props.showNotifications !== nextProps.showNotifications ||
      this.props.notificationsIds.length !== nextProps.notificationsIds.length
    ) {
      return true;
    }

    return false;
  }

  public render(): JSX.Element {
    const { notificationsIds, showNotifications, loadStatus } = this.props;
    const hasNotifications = notificationsIds && notificationsIds.length > 0;

    return (
      <div
        className={classNames('bell-notification-panel', { opened: showNotifications })}
        onClick={this.props.hideNotificationPanel}
      >
        <div className='bell-notification-panel__content' onClick={this.stopPropagation}>
          <BellNotificationsPanelHeader />
          {showNotifications && <div className='bell-notification-panel__list'>
            {loadStatus === RequestStatus.Loading ? (
              <SvgSpinner size='middle' />
            ) : hasNotifications ? (
              <EnhancedVirtualList
                objects={notificationsIds}
                itemHeight={100}
                renderedItemsCount={16}
                renderItem={this.renderNotification}
                customItemHeight={this.getItemSize}
                transitionClassName='bell-notification-panel__list-item'
                itemIdProvider={this.getItemId}
              />
            ) : (
              <div className='bell-notification-panel__placeholder'>
                <KreoIconNotificationEmpty />
                <span>No notifications</span>
              </div>
            )}
          </div>}
        </div>
      </div>
    );
  }

  private getItemSize(id: React.ReactText): number {
    return id === SubHeader.Today || id === SubHeader.Older ? 40 : 100;
  }

  private renderNotification(id: React.ReactText): React.ReactNode {
    if (id === SubHeader.Today) {
      return <BellNotificationHeader isTodayHeader={true} showActions={true} />;
    }
    if (id === SubHeader.Older) {
      return <BellNotificationHeader isTodayHeader={false} showActions={true} />;
    }
    return <BellNotification id={id as number} />;
  }

  private getItemId(id: React.ReactText): React.ReactText {
    return id;
  }

  private stopPropagation(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  let hasTodayHeader = false;
  let hasOlderHeader = false;
  const today = new Date();
  const notificationsIds: React.ReactText[] = [];
  state.notifications.notifications.map(x => {
    const created = x.created;

    if (today.toDateString() === created.toDateString()) {
      if (!hasTodayHeader) {
        hasTodayHeader = true;
        notificationsIds.push(SubHeader.Today);
      }
    } else {
      if (!hasOlderHeader) {
        hasOlderHeader = true;
        notificationsIds.push(SubHeader.Older);
      }
    }
    notificationsIds.push(x.id);
  });

  return {
    notificationsIds,
    showNotifications: state.notifications.showBellNotifications,
    loadStatus: state.notifications.loadStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    hideNotificationPanel: () => {
      dispatch(NotificationActions.showNotificationPanel(false));
    },
  };
};

export const BellNotificationsPanel = connect(mapStateToProps, mapDispatchToProps)(
  BellNotificationsPanelComponent,
);
