import { ShortPointDescription } from 'common/components/drawings/interfaces/drawing-ai-annotation';
import { DrawingsGeometryEntityConfig } from '../drawings-geometry-entity-config';
import { DrawingsMouseEventHandler } from './drawings-geometry-entity';
import { DrawingsGeometryEntityBase } from '.';

export interface DrawingsGeometryEntityPointBaseConfig extends DrawingsGeometryEntityConfig {
  geometry: ShortPointDescription | paper.Point;
  layer: paper.Layer | paper.Group;
  onStartDrag?: DrawingsMouseEventHandler;
  onMouseEnter?: DrawingsMouseEventHandler;
  onMouseLeave?: DrawingsMouseEventHandler;
}

export abstract class DrawingsGeometryEntityPointBase
  <T extends DrawingsGeometryEntityPointBaseConfig = DrawingsGeometryEntityPointBaseConfig>
  extends DrawingsGeometryEntityBase<T> {
  protected _selected: boolean = false;
  protected _point: paper.Item;

  constructor(config: T) {
    super(config);
  }

  public get position(): ShortPointDescription {
    return [this._point.position.x, this._point.position.y];
  }

  public set position(value: ShortPointDescription) {
    this._point.position = new paper.Point(value);
  }

  public get paperPosition(): paper.Point {
    return this._point.position;
  }

  public set paperPosition(position: paper.Point) {
    this._point.position = position;
  }

  public get selected(): boolean {
    return this._selected;
  }

  public set selected(value: boolean) {
    if (this._selected === value) {
      return;
    }
    this._selected = value;
    if (this.updateStylesBySelection) {
      this.updateStylesBySelection(value);
    }
  }

  protected updateStylesBySelection?(value: boolean): void;
}
