import { ComparableDocumentType, ComparisonLine, updateCompareDocumentProperties } from 'common/pdf/compare-document';
import { objectUtils } from 'common/utils/object-utils';
import { DrawingComparisonSetting, DrawingsShortInfo } from '../interfaces';
import { CancelableAction, CancelableResult } from '../interfaces/util';

interface ReloadSettings {
  document: ComparableDocumentType;
  drawingId: string;
  drawingToCompareWithId: string;
  settings: DrawingComparisonSetting;
  renderMainLayout: () => Promise<boolean>;
}

function updateWithCanceller(
  { document, renderMainLayout, settings }: ReloadSettings,
): CancelableAction<boolean>  {
  let isCanceled = false;
  updateCompareDocumentProperties(document, settings);
  const promise = new Promise<CancelableResult<boolean>>((resolve, reject) => {
    setTimeout(() => {
      if (isCanceled) {
        resolve({ data: false, isFinished: false });
        return;
      }
      renderMainLayout().then((value) => {
        resolve({ data: value, isFinished: !isCanceled });
      }).catch(reject);
    }, 1000);
  });
  return {
    cancel() {
      isCanceled = true;
    },
    promise,
  };
}

export class DrawingCompareSettingsReloader {
  private _canceler: () => void;
  private _nextSettings: ReloadSettings;
  private _getDrawingInfo: () => DrawingsShortInfo;

  constructor(getDrawingInfo: () => DrawingsShortInfo) {
    this._getDrawingInfo = getDrawingInfo;
  }

  public reload(settings: ReloadSettings): Promise<boolean> {
    if (this._canceler) {
      this._canceler();
    }
    const { promise, cancel } = updateWithCanceller(settings);
    this._canceler = cancel;
    return new Promise((resolve, reject) => {
      promise.then(({ data, isFinished }) => {
        this._canceler = null;
        if (this._nextSettings) {
          this.reload(this._nextSettings);
          this._nextSettings = null;
          return;
        }
        if (isFinished && this.isActual(settings)) {
          resolve(data);
        }
      }).catch(reject);
    });
  }

  public cancel(): void {
    if (this._canceler) {
      this._canceler();
    }
    this._nextSettings = null;
  }

  private isActual(settings: ReloadSettings): boolean {
    const drawingInfo = this._getDrawingInfo();
    return drawingInfo?.drawingId === settings.drawingId
      && drawingInfo.pageToCompareId === settings.drawingToCompareWithId
      && this.areComparisonSettingsSame(settings.settings, drawingInfo.pagesCompareSettings);
  }

  private areComparisonSettingsSame(
    settings: DrawingComparisonSetting,
    currentDrawingSettings: DrawingComparisonSetting,
  ): boolean {
    return  currentDrawingSettings.isActive === settings.isActive
      && this.areLinesEqual(settings.sourceLine, currentDrawingSettings.sourceLine)
      && this.areLinesEqual(settings.targetLine, currentDrawingSettings.targetLine);
  }

  private areLinesEqual(line1: ComparisonLine, line2: ComparisonLine): boolean {
    return (!line1 && !line2)
      || (line1 && line2 && objectUtils.areEqualByFields(line1, line2, ['x1', 'y1', 'x2', 'y2']));
  }
}
