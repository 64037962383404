import { ValidationStep } from './validation-step';

export enum ValidationStepName {
  ModelCheck = 'model-check',
  Classification = 'classification',
  ActivityAssignment = 'activity-assignment',
  Measurements = 'measurements',
  Results = 'results',
}

export function getValidationStepName(step: ValidationStep): ValidationStepName {
  switch (step) {
    case ValidationStep.ModelCheck:
      return ValidationStepName.ModelCheck;
    case ValidationStep.Classification:
      return ValidationStepName.Classification;
    case ValidationStep.ActivityAssignment:
      return ValidationStepName.ActivityAssignment;
    case ValidationStep.Measurements:
      return ValidationStepName.Measurements;
    case ValidationStep.Results:
      return ValidationStepName.Results;
    default:
      console.error('Unexpected ValidationStep enum value');
      return null;
  }
}
