import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';

export interface CommentContext<T> {
  comments: T[];
  getComent: (columId: string, rowIndex: number, rowId: string) => T;
  openComent: (commentId: number) => void;
  isSelected: (columId: string, rowIndex: number, rowId: string) => boolean;
}

interface CellWitnCommentParams<T> extends Ag.ICellRendererParams {
  context: CommentContext<T>;
}

export const className = 'CommentHightlight';

export class AgGridCellWithComment<T extends { id?: number }> {
  private params: CellWitnCommentParams<T>;
  public constructor(params: CellWitnCommentParams<T>) {
    this.params = params;
  }

  public getCommentHightLight(parent: HTMLDivElement): HTMLDivElement {
    const { colDef, rowIndex, node } = this.params;
    const rowId = node.id;
    const comment = this.params.context.getComent(colDef.colId, rowIndex, rowId);
    if (!comment) {
      return null;
    }
    const isSelected = this.params.context.isSelected(colDef.colId, rowIndex, rowId);
    const container = document.createElement('div');
    container.style.width = '6px';
    container.style.height = '6px';
    container.style.position = 'absolute';
    container.style.top = '0px';
    container.style.right = '0px';
    container.style.zIndex = '999';
    if (isSelected) {
      parent.className = 'selected_comment_hight_light';
    }
    container.style.opacity = '1';
    container.style.borderBottomLeftRadius = '5px';
    container.className = className;
    container.onclick = this.handleClick(comment);
    return container;
  }

  @autobind
  private handleClick(comment: any): () => void {
    return () => this.params.context.openComent(comment.id);
  }
}
