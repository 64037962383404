import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './companies-select.scss';

import { State } from 'common/interfaces/state';
import { MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { AccountActions } from '../../../units/account/actions/creators';
import { Company } from '../../../units/account/interfaces/company';

interface SelectStateProps {
  companies: Company[];
  selectedCompany: Company;
}

interface SelectDispatchProps {
  selectCompany: (company: Company) => void;
}

interface SelectProps extends SelectStateProps, SelectDispatchProps {}

class CompaniesSelectComponent extends React.Component<SelectProps> {
  public render(): React.ReactNode {
    const { companies, selectedCompany } = this.props;
    if (!companies) {
      return <div className='companies-select--loading' />;
    }

    return (
      <div className='companies-select'>
        <MaterialSelect
          className='material-select__content'
          dropdownClassName='companies-select__paper'
          autoWidth={true}
          value={selectedCompany ? selectedCompany.id : null}
          onChange={this.selectCompany}
          displayedType={MaterialComponentType.Native}
        >
          {companies.map((company, index) => (
            <MaterialMenuItem className='companies-select__item' key={index} value={company.id}>
              {company.name}
            </MaterialMenuItem>
          ))}
        </MaterialSelect>
      </div>
    );
  }

  @autobind
  private selectCompany(_e: React.SyntheticEvent<{}>, value: number): void {
    this.props.selectCompany(this.props.companies.find(c => c.id === value));
  }
}

const mapStateToProps = (state: State): SelectStateProps => {
  return {
    companies: state.account.companies,
    selectedCompany: state.account.selectedCompany,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): SelectDispatchProps => {
  return {
    selectCompany: company => dispatch(AccountActions.selectCompany(company)),
  };
};

export const CompaniesSelect = connect(mapStateToProps, mapDispatchToProps)(
  CompaniesSelectComponent,
);
