import React from 'react';

import { UuidUtil } from 'common/utils/uuid-utils';
import { DropDownProps } from 'unit-2d-database/components/side-panel/components/item-panel/interfaces';
import { InputSelect } from '../../inputs';
import { FormField } from '../form-field';
import { useEditContext } from '../use-edit-context';


interface Props {
  label: string;
  input: any;
  onDeleteClick?: (e: React.MouseEvent) => void;
  dropDownProps?: DropDownProps;
}

export const FormSingleSelectField: React.FC<Props> = (props) => {
  const {
    label,
    input,
    dropDownProps,
    onDeleteClick,
  } = props;
  const inputId = React.useMemo(() => UuidUtil.generateUuid(), []);
  const { startEditing, editIcon } = useEditContext(onDeleteClick);
  return (
    <FormField
      label={label}
      dropDownProps={dropDownProps}
      inputId={inputId}
      startEditing={startEditing}
      editIcon={editIcon}
    >
      <InputSelect
        elements={input.elements}
        activeElementIndex={input.activeElementIndex}
        onClick={input.onClick}
        onBlur={input.onBlur}
        value={input.value}
        inputId={inputId}
        createNewElement={input.createNewElement}
        isShowArrow={true}
        searchType={input.format}
        createEmptyElement={input.createEmptyElement}
      />
    </FormField>
  );
};
