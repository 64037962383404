import * as React from 'react';
import { TimeframeAnimationTarget } from '../../../interfaces/gantt-chart';
import { ChartTimeline } from './chart-timeline';


interface Props {
  width: number;
  projectStartDate: Date;
  timeframeStartDay: number;
  timeframeDuration: number;
  timelineDuration: number;
  triggerTimeframeChangeAnimation: (target: TimeframeAnimationTarget) => void;
}

class BaseChartHeader extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <div className='chart-header'>
        {this.props.children}
        <ChartTimeline
          width={this.props.width}
          projectStartDate={this.props.projectStartDate}
          timeframeStartDay={this.props.timeframeStartDay}
          timeframeDuration={this.props.timeframeDuration}
          triggerTimeframeChangeAnimation={this.props.triggerTimeframeChangeAnimation}
          timelineDuration={this.props.timelineDuration}
        />
      </div>
    );
  }
}


export { BaseChartHeader };
