import classNames from 'classnames';
import * as React from 'react';

import './tooltip-container.scss';

interface Props {
  className?: string;
  hotKey?: string;
}

export const TooltipContainer: React.FC<Props> = ({ children, className, hotKey }) => {
  return (
    <div className={classNames('tooltip-container', className)}>
      {children}
      {hotKey && <span className='tooltip-container__hot-key'>{hotKey}</span>}
    </div>
  );
};
