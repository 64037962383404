import styled from 'styled-components';
import {
  AnnotationLegendGroupStyled,
} from 'common/components/drawings/drawings-annotation-legend/drawings-annotation-legend-group';
import { QuickSearchStyled } from 'common/components/quick-search';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  min-height: 280px;
  max-height: calc(100vh - 160px);
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 20px;
  box-shadow: 0px 20px 20px #00000029;
  ${QuickSearchStyled.Container} {
    padding-left: 5px;
    box-sizing: border-box;
  }
`;

const Groups = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  overflow-y: auto;
  border-top: 1px solid ${p => p.theme.color.background};

  ${AnnotationLegendGroupStyled.Container} {
    margin-right: unset;
  }
  ${AnnotationLegendGroupStyled.Container} + ${AnnotationLegendGroupStyled.Container} {
    margin-top: 5px;
  }
`;

export const Styled = {
  Container,
  Groups,
};

