import styled from 'styled-components';

const PromptContainer = styled.div`
  padding: 1px 5px;
  border-radius: 5px;
  background-color: ${p => p.theme.color.turquoise};
  box-sizing: border-box;
  cursor: pointer;
`;

export const Styled = {
  PromptContainer,
};
