import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import './labours-tab.scss';

import { NumberDictionary } from 'common/interfaces/dictionary';
import { State } from 'common/interfaces/state';
// eslint-disable-next-line import/named
import { ChartRangeHoverEvent } from '../../../../../../components/engine/KreoEngine';
import { KreoChart, KreoChartMode } from '../../../../../../components/kreo-chart';
import { AllLaboursId, ChartDataProvider, ResourceDescription, ResourceType } from '../../../../utils/gantt-chart';
import { datesFormatter } from '../../dates-formatter';

interface TabOwnProps {
  height: number;
  width: number;
  dataProvider: ChartDataProvider;
  currentMoment: number;
  nextCurrentMoment: number | null;
  timeframeStartDay: number;
  timeframeDuration: number;
}

interface TabStateProps {
  labours: Map<number, ResourceDescription>;
  projectStartDate: Date;
}

interface TabProps extends TabOwnProps, TabStateProps { }

class LaboursTabComponent extends React.Component<TabProps> {
  private readonly sideMargin: number = 20;
  private colors: NumberDictionary<string> = {};

  constructor(props: TabProps) {
    super(props);

    for (const [labourId, labour] of props.labours) {
      this.colors[labourId] = labour.colors.main;
    }
  }

  public render(): React.ReactNode {
    const chartWidth = this.props.width - 2 * this.sideMargin;
    const chartData = this.props.dataProvider.calculateTimeboundLaboursChart(chartWidth);

    const pixelsPerDay = chartWidth / this.props.timeframeDuration;
    const currentMomentToRender = this.props.nextCurrentMoment
      ? this.props.nextCurrentMoment
      : this.props.currentMoment;
    const currentMomentOffset =
      (currentMomentToRender - this.props.timeframeStartDay) * pixelsPerDay;

    const currentMergeRangeIndex =
      Math.floor((currentMomentToRender - chartData.mergeStart) / chartData.mergeDuration);

    const currentQuantities: NumberDictionary<number> = {};
    for (let i = 0; i < chartData.mergeIds.length; ++i) {
      const valForResource = chartData.mergedValues2dCumm[i][currentMergeRangeIndex];
      const valForPrevResource = i > 0 ? chartData.mergedValues2dCumm[i - 1][currentMergeRangeIndex] : 0;
      const resId = chartData.mergeIds[i];
      currentQuantities[resId] = Math.round(valForResource - valForPrevResource);
    }

    const currentTotal =
      Math.round(chartData.mergedValues2dCumm[chartData.mergeIds.length - 1][currentMergeRangeIndex]);

    return (
      <div
        className='labours-tab'
      >
        <div className='labours-tab__chart-container'>
          <KreoChart
            id='labours-side-panel'
            backgroundColor='#202533'
            height={160}
            width={chartWidth}
            className='labours-tab__chart'
            isAvailable={true}
            mode={KreoChartMode.Multi}
            data={chartData}
            colors={this.colors}
            topLeftLabel={'All Labours'}
            topRightLabel={currentTotal.toString()}
            renderLegend={true}
            renderTooltip={this.renderTooltip}
          />
          <div
            className='labours-tab__timepointer'
            style={{ left: currentMomentOffset }}
          />
        </div>
        <div className='labours-tab__labours-list'>
          <div className='labours-tab__labours-list-header'>
            <div className='labours-tab__labours-list-header-name'>
              Name
            </div>
            <div className='labours-tab__labours-list-header-quantity'>
              Day Max
            </div>
          </div>
          <div className='labours-tab__list-scroll-container'>
            {
              chartData.mergeIds.slice().reverse()
                .map((labourId: number) => {
                  const labour = this.props.labours.get(labourId);
                  const quantity = currentQuantities[labourId];

                  return (
                    <div
                      className={classNames('labours-tab__labour', { active: quantity > 0 })}
                      key={labourId}
                    >
                      <div
                        className='labours-tab__labour-badge'
                        style={{ backgroundColor: labour.colors.main }}
                        title={labour.displayName}
                      >
                        {labour.displayName}
                      </div>
                      <div className='labours-tab__labour-quantity'>
                        {quantity > 0 ? quantity : ''}
                      </div>
                    </div>
                  );
                })
            }
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private renderTooltip(event: ChartRangeHoverEvent): React.ReactNode {
    if (!event) {
      return;
    }

    const consumption = this.props.dataProvider.calculateResourceConsumptionForTimerange(
      ResourceType.Labour,
      AllLaboursId,
      event.startDay,
      event.endDay,
    );

    let datesString;
    const itemDuration = event.endDay - event.startDay;
    if (itemDuration <= 1) {
      datesString = datesFormatter.formatFullDate(event.startDay + itemDuration / 2, this.props.projectStartDate);
    } else {
      const dates = datesFormatter.formatDateSpan(
        event.startDay,
        event.endDay - 0.5,
        this.props.projectStartDate);
      datesString = `${dates.start} - ${dates.end}`;
    }

    return (
      <div className='labours-tab__tooltip'>
        <div>
          <b>{datesString}</b>
        </div>
        <div>
          Max: {Math.round(consumption.max)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State): TabStateProps => {
  return {
    labours: state.fourDVisualisation.resources[ResourceType.Labour],
    projectStartDate: state.fourDVisualisation.projectStartDate,
  };
};

export const LaboursTab = connect(mapStateToProps)(LaboursTabComponent);
