const prefix = `@projects`;

export const ProjectsActionTypes = {
  REMOVE_PROJECT: `${prefix}/REMOVE_PROJECT`,
  REMOVE_PROJECT_SUCCEEDED: `${prefix}/REMOVE_PROJECT_SUCCEEDED`,
  LEAVE_PROJECT: `${prefix}/LEAVE_PROJECT`,
  RUN_CLASSIFICATION: `${prefix}/RUN_CLASSIFICATION`,
  UPDATE_PROJECT_STATUS_REQUEST: `${prefix}/UPDATE_PROJECT_STATUS_REQUEST`,
  UPDATE_PROJECT_STATUS_SUCCEEDED: `${prefix}/UPDATE_PROJECT_STATUS_SUCCEEDED`,
  LOAD_CURRENT_PROJECT: `${prefix}/LOAD_CURRENT_PROJECT`,
  CREATE_PROJECT: `${prefix}/CREATE_PROJECT`,
  CREATE_PROJECT_FROM_DEMO_PROJECT: `${prefix}/CREATE_PROJECT_FROM_DEMO_PROJECT`,
  PATCH_COMPANY_FROM_DEMO_PROJECT: `${prefix}/PATCH_COMPANY_FROM_DEMO_PROJECT`,
  SET_APPLY_DEMO_PROJECT_STATUS: `${prefix}/SET_APPLY_DEMO_PROJECT_STATUS`,
  DUPLICATE_2D_PROJECT: `${prefix}/DUPLICATE_2D_PROJECT`,
  DUPLICATE_2D_PROJECT_SUCCEEDED: `${prefix}/DUPLICATE_2D_PROJECT_SUCCEEDED`,
  DUMP_2D_PROJECT: `${prefix}/DUMP_2D_PROJECT`,
  CREATE_PROJECT_SUCCEEDED: `${prefix}/CREATE_PROJECT_SUCCEEDED`,
  CREATE_FAILED: `${prefix}/CREATE_FAILED`,
  STORE_PROJECT_INFO: `${prefix}/STORE_PROJECT_INFO`,
  ADD_PROJECT: `${prefix}/ADD_PROJECT`,
  CONTRACTOR_INVITE_REQUEST: `${prefix}/CONTRACTOR_INVITE_REQUEST`,
  INVITE_REQUEST: `${prefix}/INVITE_REQUEST`,
  GET_BID_PRICING_INFO: `${prefix}/SAVE_BID_PRICING_INFO`,
  GET_BID_PRICING_INFO_SUCCEEDED: `${prefix}/GET_BID_PRICING_INFO_SUCCEEDED `,
  UPDATE_PROJECT_NAME_BY_ID_REQUEST: `${prefix}/UPDATE_PROJECT_NAME_BY_ID_REQUEST`,
  UPDATE_PROJECT_NAME_REQUEST: `${prefix}/UPDATE_PROJECT_NAME_REQUEST`,
  UPDATE_PROJECT_NAME_SUCCEEDED: `${prefix}/UPDATE_PROJECT_NAME_SUCCEEDED `,
  MOVE_PROJECT_TO_FOLDER: `${prefix}/MOVE_PROJECT_TO_FOLDER`,
  GET_VALIDATION_STATUS_REQUEST: `${prefix}/GET_VALIDATION_STATUS_REQUEST`,
  SET_NOT_FINISHED_SCENARIO_ID: `${prefix}/SET_NOT_FINISHED_SCENARIO_ID`,
  RESET_PROJECT_HAS_UNSTARTED_PRODUCTS: `${prefix}/RESET_PROJECT_HAS_UNSTARTED_PRODUCTS`,
  START_BID_PRICING_REQUEST: `${prefix}/START_BID_PRICING_REQUEST`,
  UPDATE_VIEW_MODEL_STATUS: `${prefix}/UPDATE_VIEW_MODEL_STATUS`,
  UPDATE_VIEW_MODEL_STATUSES: `${prefix}/UPDATE_VIEW_MODEL_STATUSES`,
  UPDATE_PROJECT_INVITED_USERS: `${prefix}/UPDATE_PROJECT_INVITED_USERS`,
  DROP_CURRENT_PROJECT_INFO: `${prefix}/DROP_CURRENT_PROJECT_INFO`,
  FETCH_SCENARIO_STATES: `${prefix}/FETCH_SCENARIO_STATES`,
  FETCH_SCENARIO_STATES_SUCCEEDED: `${prefix}/FETCH_SCENARIO_STATES_SUCCEEDED`,
  APPEND_SCENARIO_STATES: `${prefix}/APPEND_SCENARIO_STATES`,
  CHANGE_COMPANY_PROJECTS_SEARCH_QUERY: `${prefix}/CHANGE_COMPANY_PROJECTS_SEARCH_QUERY`,
  FETCH_COMPANY_PROJECT_HEADERS_REQUEST: `${prefix}/FETCH_COMPANY_PROJECT_HEADERS_REQUEST`,
  FETCH_COMPANY_PROJECT_HEADERS_SUCCEEDED: `${prefix}/FETCH_COMPANY_PROJECT_HEADERS_SUCCEEDED`,
  FETCH_DEMO_PROJECTS_REQUEST: `${prefix}/FETCH_DEMO_PROJECTS_REQUEST`,
  FETCH_DEMO_PROJECTS_SUCCEEDED: `${prefix}/FETCH_DEMO_PROJECTS_SUCCEEDED`,
  FETCH_COMPANY_PROJECT_HEADERS_FAILED: `${prefix}/FETCH_COMPANY_PROJECT_HEADERS_FAILED`,
  CLEAR_COMPANY_PROJECT_HEADERS: `${prefix}/CLEAR_COMPANY_PROJECT_HEADERS`,
  SET_ENGINE_FILTER_STATE: `${prefix}/SET_ENGINE_FILTER_STATE`,
  GET_ENGINE_FILTER_STATE: `${prefix}/GET_ENGINE_FILTER_STATE`,
  GET_ENGINE_FILTER_STATE_SUCCEEDED: `${prefix}/GET_ENGINE_FILTER_STATE_SUCCEEDED`,
  FAILURE_PROJECT_SEND: `${prefix}/FAILURE_PROJECT_SEND`,
  FAILURE_PROJECT_SEND_SUCCEEDED: `${prefix}/FAILURE_PROJECT_SEND_SUCCEEDED`,
  LOCALLY_UPDATE_PROJECT_HEADER: `${prefix}/LOCALLY_UPDATE_PROJECT_HEADER`,
  FETCH_ALL_TEMPLATES: `${prefix}/FETCH_ALL_TEMPLATES`,
  FETCH_ALL_TEMPLATES_SUCCEEDED: `${prefix}/FETCH_ALL_TEMPLATES_SUCCEEDED`,
  CREATE_TEMPLATE_FROM_PROJECT: `${prefix}/CREATE_TEMPLATE_FROM_PROJECT`,
  DROP_STORE: `${prefix}/DROP_STORE`,
  SET_PROJECT_NOT_FOUND: `${prefix}/SET_PROJECT_NOT_FOUND`,
  CHANGE_LOADING_STATUS: `${prefix}/CHANGE_LOADING_STATUS`,
  CHANGE_PROJECT_ACCESS_REASON: `${prefix}/CHANGE_PROJECT_ACCESS_REASON`,
};
