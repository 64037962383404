import React, { useCallback, useMemo, useState } from 'react';

import { FormSingleSelectField } from 'common/components/form-fields';
import { PropertyFormatEnum } from '../../../../../../interfaces';

interface Props {
  selectedUnit: string;
  extraUnits: string[];
  onChange: (updates: Record<string, string | PropertyFormatEnum>) => void;
}

export const UnitInput: React.FC<Props> = ({ selectedUnit, extraUnits, onChange }) => {
  const unitValue = !selectedUnit
    ? 'Text'
    : selectedUnit;
  const index = extraUnits.indexOf(unitValue);
  const isNewUnit = index === -1;
  const units = useMemo(() => {
    return isNewUnit && unitValue !== undefined
      ? [unitValue, ...extraUnits]
      : extraUnits;
  }, [isNewUnit, unitValue, extraUnits]);

  const [activeElementIndex, setActiveElementIndex] = useState(index);

  const handleClick = useCallback((indexUnit: number) => {
    const unit = units[indexUnit];
    const format = unit === 'Text'
      ? PropertyFormatEnum.Text
      : PropertyFormatEnum.Number;
    setActiveElementIndex(indexUnit);
    onChange({ unit, format });
  }, [units, setActiveElementIndex, onChange]);

  const handleCreate = useCallback((unit: string) => {
    setActiveElementIndex(0);
    onChange({ unit: unit.slice(0, 31), format: PropertyFormatEnum.Number });
  }, [setActiveElementIndex, onChange]);

  return (
    <FormSingleSelectField
      label='Unit'
      input={{
        activeElementIndex,
        elements: units,
        onClick: handleClick,
        value: unitValue,
        onBlur: handleCreate,
        createNewElement: true,
      }}
    />
  );
};
