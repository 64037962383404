import { merge } from 'lodash';
import * as React from 'react';


import './classification-ontology-graph.scss';

import { StringDictionary } from 'common/interfaces/dictionary';
import { arrayUtils } from 'common/utils/array-utils';
import { ClassificationOntologyEdgeType } from '../../enums/classification-ontology-edge-type';
import {
  ClassificationGraphPreparedPositions,
  ClassificationOntologyGraphEdge,
  ClassificationOntologyGraphPositions,
  ClassificatioOntologyEdgePositionWithType,
  CoordinateWithDescription,
} from '../../interfaces/classification/classification-graph-visual-data';
import {
  ClassificationOntologyNode,
} from '../../interfaces/classification/ontology-data';
import { ClassificationOntologyVertex } from '../classification-ontology-vertex';


interface Props {
  graph: ClassificationOntologyNode[];
  graphId: number;
  isEdit: boolean;
}

interface ComponentState {
  graphHeight: number;
  positions: ClassificationOntologyGraphPositions;
}

const vertexHeight = 44;
const yMargin = 10;
const vertexWidth: number = 160;
const xMargin: number = 20;

function calclulateGraphPositions(
  graph: ClassificationOntologyNode[],
  parentX: number = 0,
  parentY: number = 20,
  parentEdgeId: string = '',
): ClassificationGraphPreparedPositions {
  const fromTo = new Array<ClassificationOntologyGraphEdge>();
  let nodePositions: StringDictionary<CoordinateWithDescription> = {};
  const x = parentX ? (parentX + vertexWidth + xMargin) : 20;
  let y = parentY;
  if (!graph) return { edges: [], nodes: {} };
  for (let i = 0; i < graph.length; i++) {
    const graphNode = graph[i];
    if (graphNode.show === false) continue;
    const nodeId = parentEdgeId ? `${parentEdgeId}/${i}` : `${i}`;
    nodePositions[nodeId] = {
      x,
      y,
      description: {
        nodeValue: graphNode.humanReadableName,
        nodeRel: graphNode.isSuggest ? graphNode.name : graphNode.humanReadableRel,
        isError: graphNode.isSuggest,
        newVariants: graphNode.variantsNew,
        augmentation: graphNode.augmentation,
      },
    };
    let linkType = ClassificationOntologyEdgeType.Default;
    if (parentEdgeId) {
      linkType = graphNode.isSuggest ? ClassificationOntologyEdgeType.Alert : ClassificationOntologyEdgeType.Good;
    }
    fromTo.push({ from: parentEdgeId, to: nodeId, type: linkType });
    const { nodes, edges } = calclulateGraphPositions(graphNode.subnodes, x, y, nodeId);
    arrayUtils.extendArray(fromTo, edges);
    nodePositions = merge(nodePositions, nodes);
    const maxChildrenCount = graphNode.maxChildrenCount || 1;
    y += vertexHeight * maxChildrenCount + (yMargin * maxChildrenCount);
  }
  return { edges: fromTo, nodes: nodePositions };
}

function calculatePostions(graph: ClassificationOntologyNode[]): ClassificationOntologyGraphPositions {
  const { nodes, edges } = calclulateGraphPositions(graph);
  return {
    vertexes: nodes,
    edges: edges.map(({ from, to, type }) => {
      const fromNode = nodes[from] || { x: 0, y: 0 };
      const toNode = nodes[to];
      return {
        from: { id: from, x: fromNode.x, y: fromNode.y },
        to: { id: to, ...toNode },
        type,
      };
    }),
  };
}

function calculateHeight(graph: ClassificationOntologyNode[]): number {
  let graphHeight = 20;
  for (const node of graph) {
    if (node.show === false) continue;
    const maxChildrenCount = node.maxChildrenCount || 1;
    graphHeight += maxChildrenCount * vertexHeight + maxChildrenCount * yMargin;
  }

  return graphHeight;
}

export class ClassificationOntologyGraph extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = ClassificationOntologyGraph.getDerivedStateFromProps(props);
  }

  public static getDerivedStateFromProps(props: Props): ComponentState {
    return {
      graphHeight: calculateHeight(props.graph),
      positions: calculatePostions(props.graph),
    };
  }

  public render(): React.ReactNode {
    const { graphHeight } = this.state;
    return (
      <div className='classification-ontology-graph'>
        <svg height={graphHeight} className='classification-ontology-graph__edges'>
          {this.renderEdges()}
        </svg>
        {this.renderVertexes()}
      </div>
    );
  }

  private renderVertexes(): React.ReactNodeArray {
    const { vertexes } = this.state.positions;
    const p = [];
    for (const [k, v] of Object.entries(vertexes)) {
      p.push(
        <ClassificationOntologyVertex
          isEdit={this.props.isEdit}
          propertyKey={v.description.nodeRel}
          key={k}
          graphId={this.props.graphId}
          x={v.x}
          y={v.y}
          id={k}
          name={v.description.nodeValue}
          variantsTree={v.description.newVariants}
          isError={v.description.isError}
          augmentation={v.description.augmentation}
        />);
    }

    return p;
  }

  private getPath({ from, to }: ClassificatioOntologyEdgePositionWithType): string {
    let radiusPathValue: string;
    let path: string;
    let radius: number;
    if (from.id === '') {
      radiusPathValue = '10,10';
      radius = 10;
      path = `M ${from.x + 2} ${from.y}`;
    } else {
      radiusPathValue = '5,5';
      radius = 5;
      path = `M${from.x + vertexWidth} ${from.y + vertexHeight / 2}`;
    }
    if (from.id !== '' && from.y < to.y) {
      path += `h${xMargin / 4}
        a${radiusPathValue} 0 0 1 ${radiusPathValue} v ${to.y - from.y - yMargin}
        a${radiusPathValue} 1 0 0 ${radiusPathValue} h${xMargin}`;
    } else if (from.y < to.y) {
      path += `v ${to.y - from.y + vertexHeight / 2 - radius} a${radiusPathValue} 1 0 0 ${radiusPathValue} h${xMargin}`;
    } else {
      path += `h${xMargin}`;
    }
    return path;
  }

  private renderEdges(): JSX.Element[] {
    const { edges } = this.state.positions;
    return edges
      .map(position => {
        const key =
        `edge/
        ${position.from.id}/${position.to.id}/${position.from.x}/${position.from.y}/${position.to.x}/${position.to.y}`;
        return (
          <path
            className={`classification-ontology-graph__edge ${position.type}`}
            id={key}
            key={key}
            d={this.getPath(position)}
          />);
      });
  }
}

