import React, { useCallback } from 'react';
import { CommentaryUserInfo } from 'unit-2d-comments/interfaces';
import { Content } from '../content';
import { Styled } from './styled';

interface Props {
  id: number;
  text: string;
  user: CommentaryUserInfo;
  currentUserId: string;
  createdAt: string;
  updatedAt: string;
  resolved: boolean;
  isEdit: boolean;
  resolveDiff?: boolean;
  onRemove: (id: number) => void;
  setEditSubComment: (id: number) => void;
  onEdit: (id: number, text: string) => void;
}

const SubCommentComponent: React.FC<Props> = ({
  id,
  text,
  resolveDiff,
  onRemove,
  onEdit,
  setEditSubComment,
  user,
  currentUserId,
  createdAt,
  updatedAt,
  isEdit,
  resolved,
}) => {
  const onRemoveCallback = useCallback(() => onRemove(id), []);
  const onEditCallback = useCallback((newText) => onEdit(id, newText), []);
  const onStartEdit = useCallback(() => setEditSubComment(id), []);
  const onStopEdit = useCallback(() => setEditSubComment(null), []);

  return (
    <Styled.Container>
      <Content
        resolveDiff={resolveDiff}
        user={user}
        text={text}
        edited={!!updatedAt}
        isOwner={currentUserId === user?.id}
        lastActionDate={updatedAt || createdAt}
        resolved={resolved}
        onRemove={onRemoveCallback}
        onEdit={onEditCallback}
        isEdit={isEdit}
        onStartEdit={onStartEdit}
        onStopEdit={onStopEdit}
      />
    </Styled.Container>
  );
};

export const SubComment = React.memo(SubCommentComponent);
