export function LOOKUP(lookup_value: any, lookup_vector: any[], result_vector?: any[]): any {
  if (lookup_vector.length === 0) {
    throw new Error('Lookup vector must not be empty.');
  }

  if (result_vector && lookup_vector.length !== result_vector.length) {
    throw new Error('Lookup and result vectors must be of the same length.');
  }

  let i = 0;
  while (i < lookup_vector.length && lookup_vector[i] < lookup_value) {
    i++;
  }

  if (i === 0) {
    return result_vector ? result_vector[0] : lookup_vector[0];
  } else if (i === lookup_vector.length || lookup_vector[i] > lookup_value) {
    return result_vector ? result_vector[i - 1] : lookup_vector[i - 1];
  } else {
    return result_vector ? result_vector[i] : lookup_vector[i];
  }
}
