import { useMemo } from 'react';

import { useRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { useCurrentDrawingInfo, useInstances } from 'common/components/drawings/hooks';

export function useCanEnclose(instancesIds: string[]): boolean {
  const { rendererApi } = useRendererApiContext();
  const instances = useInstances();
  const currentDrawingInfo = useCurrentDrawingInfo();

  return useMemo(() => {
    return rendererApi && instancesIds.some(x => {
      if (!instances[x]) {
        return false;
      }

      const { type, drawingId } = instances[x];

      return type === DrawingsInstanceType.Polyline && drawingId === currentDrawingInfo?.drawingId;
    });
  }, [rendererApi, instancesIds, instances, currentDrawingInfo]);
}
