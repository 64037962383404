import styled from 'styled-components';


const Container = styled.div<{ error: boolean, image: string }>`
  height: 100%;
  max-width: 46px;
  max-height: 46px;
  min-width: 40px;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.error ? p.theme.color.red : p.theme.color.background}
    ${p => !p.error && `url(${p.image})`} center center / cover no-repeat padding-box padding-box;
  border-radius: 5px;
  background-position: center;
  background-size:  cover;

  > svg {
    width: 20px;
    height: 18px;
    fill: ${p => p.theme.color.white};
  }
`;

export const Styled = {
  Container,
};
