import React, { useCallback } from 'react';
import { useOpenCloseDialog } from 'common/UIKit';
import { DrawingDialogs } from '../../constants/drawing-dialogs';
import { NoScaleDescriptionType } from '../../dialogs';
import { DrawingsUtils } from '../../utils/drawings-utils';
import { useCurrentDrawingInfo } from '../redux-selectors';

export function useShowCalibrationDialogIfNeed(): (skip: () => void, description?: NoScaleDescriptionType) => void {
  const currentDrawingInfo = useCurrentDrawingInfo();
  const isCalibrated = currentDrawingInfo ? DrawingsUtils.isCalibrated(currentDrawingInfo) : false;

  const [ openDialog ] =  useOpenCloseDialog(DrawingDialogs.NOT_SCALED_DIALOG);

  return useCallback(
    (skip: () => void, description: NoScaleDescriptionType = NoScaleDescriptionType.Default) => {
      if (isCalibrated) {
        skip();
      } else {
        openDialog({ onSkip: skip, descriptionType: description });
      }
    },
    [isCalibrated, openDialog],
  );
}

export interface WithOpenShowCalibrationDialogIfNeed {
  openCalibrationDialogIfNeeded: (skip: () => void, description?: NoScaleDescriptionType) => void;
}

export function withShowCalibrationDialogIfNeed<P>(
  Component: React.ComponentType<P & WithOpenShowCalibrationDialogIfNeed>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof WithOpenShowCalibrationDialogIfNeed>>> {
  return (props: P) => {
    const openCalibrationDialogIfNeeded = useShowCalibrationDialogIfNeed();

    return <Component {...props} openCalibrationDialogIfNeeded={openCalibrationDialogIfNeeded} />;
  };
}
