import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div<{ isShowLabel: boolean }>`
  position: relative;
  padding: 20px 20px 0px;
  height: inherit;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0 0 0 ${p => p.isShowLabel ? 4 : 0}px ${p => p.theme.color.turquoise};
  border-right: 1px solid ${p => p.theme.color.background};

  button {
    width: 100%;
  }
`;

const PopularLabel = styled.div`
  position: absolute;
  top: -29px;
  width: 200px;
  height: 25px;
  display: flex;
  align-items: center;
  margin-left: -24px;
  padding-left: 10px;
  border-radius: 10px 10px 0px 0px;
  background-color: ${props => props.theme.color.turquoise};

  > p {
    text-transform: uppercase;
  }
`;

const Price = styled.div`
  height: 80px;
`;

const PriceCost = styled.div`
${Text} {
  margin-right: 20px;
  font-size: 36px;
  line-height: 41px;
  color: ${props => props.theme.color.mainFont};
  }
`;

const PriceDescription = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: flex-end;
  padding-bottom: 7px;

  p {
    line-height: 16px;
  }
`;

const Description = styled.div`
  width: 180px;
  margin-top: 20px;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.color.gray};
`;

export const Styled = {
  Container,
  PopularLabel,
  Price,
  PriceCost,
  PriceDescription,
  Description,
};
