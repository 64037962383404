import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './resource-limitations-row-menu.scss';

import { WorkPackagesData } from '../../../scenarios';
import { ResourceLimitationsRowMenuItem } from './resource-limitations-row-menu-item';

interface Props {
  activated: number;
  collapsed: boolean;
  items: WorkPackagesData.ResourceLimitation[];
  setActivated: (index: number) => void;
}

export class ResourceLimitationsRowMenu extends React.Component<Props> {

  public render(): JSX.Element {
    return (
      <div className={classNames('resource-limitations-row-menu', { 'collapsed': this.props.collapsed })}>
        {
          this.props.items.map((x) => {
            return (
              <ResourceLimitationsRowMenuItem
                key={x.index}
                item={x}
                activate={this.onActivate}
              />
            );
          })
        }
      </div>
    );
  }

  @autobind
  private onActivate(index: number): void {
    this.props.setActivated(index);
  }
}
