import { DeferredExecutor } from 'common/utils/deferred-executer';

const SELECTION_AREA_THRESHOLD = 5;

export class SelectionEventsHelper {
  private _mouseDownEvent: PaperMouseEvent;
  private _startSelectionExecutor: DeferredExecutor = new DeferredExecutor(100);
  private _callback: (e: PaperMouseEvent) => void;

  public onMouseDown(e: PaperMouseEvent, callback: (e: PaperMouseEvent) => void): void {
    this._mouseDownEvent = e;
    this._callback = callback;
    this._startSelectionExecutor.execute(() => {
      this._mouseDownEvent = null;
      this._callback(e);
    });
  }

  public onMouseMouseMove(e: PaperMouseEvent): void {
    if (this._mouseDownEvent && e.point.getDistance(this._mouseDownEvent.point) > SELECTION_AREA_THRESHOLD) {
      this._startSelectionExecutor.reset();
      this._callback(this._mouseDownEvent);
      this._mouseDownEvent = null;
    }
  }

  public onMouseUp(): void {
    this._startSelectionExecutor.reset();
    this._mouseDownEvent = null;
  }
}
