import * as paper from 'paper';

import { arrayUtils } from 'common/utils/array-utils';
import { DrawingsAddRemovePointResults } from '../drawings-geometry/drawings-geometry-entities';
import { DrawingsInstanceType, UtilityInstanceTypes } from '../enums/drawings-instance-type';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import {
  DrawingsAggregatedSelection,
  DrawingsBasePolyGeometryNew,
  DrawingsBounds,
  DrawingsCalibrationLineGeometry,
  DrawingsCountGeometry,
  DrawingsGeometryPointCoordinates,
  DrawingsGeometryType,
  DrawingsPolygonGeometry,
  DrawingsPolylineGeometry,
  DrawingsSelectAggregationGroup,
} from '../interfaces/drawings-geometry';
import { DrawingsGeometryInstance, DrawingsGeometryInstanceWithId } from '../interfaces/drawings-geometry-instance';
import { DrawingsMeasureCount, DrawingsMeasureLength, DrawingsMeasureType } from '../interfaces/drawings-measures';
import { DrawingAnnotationUtils } from './drawing-annotation-utils';
import { DrawingsCanvasUtils } from './drawings-canvas-utils';
import { DrawingsMenuUtils } from './drawings-menu-utils';
import { DrawingsPaperUtils } from './drawings-paper-utils';
import { PaperIntersectionUtils } from './paper-utils';

function isPolygon(
  type: DrawingsInstanceType,
  _geometry: DrawingsGeometryType,
): _geometry is DrawingsPolygonGeometry {
  return type === DrawingsInstanceType.Polygon;
}


function isPolyline(
  type: DrawingsInstanceType,
  _geometry: DrawingsGeometryType,
): _geometry is DrawingsPolylineGeometry {
  return type === DrawingsInstanceType.Polyline;
}

function isCount(
  type: DrawingsInstanceType,
  _geometry: DrawingsGeometryType,
): _geometry is DrawingsCountGeometry {
  return type === DrawingsInstanceType.Count;
}


function isCalibrate(
  type: DrawingsInstanceType,
  _geometry: DrawingsGeometryType,
): _geometry is DrawingsCalibrationLineGeometry {
  return type === DrawingsInstanceType.CalibrationLine;
}

function isVisualSearchSourcePolygon(
  type: DrawingsInstanceType,
  _geometry: DrawingsGeometryType,
): _geometry is DrawingsPolygonGeometry {
  return type === DrawingsInstanceType.VisualSearchSourcePolygon;
}

function isVisualSearchAreaPolygon(
  type: DrawingsInstanceType,
  _geometry: DrawingsGeometryType,
): _geometry is DrawingsPolygonGeometry {
  return type === DrawingsInstanceType.VisualSearchAreaPolygon;
}

function isDropperAreaPolygon(
  type: DrawingsInstanceType,
  _geometry: DrawingsGeometryType,
): _geometry is DrawingsPolygonGeometry {
  return type === DrawingsInstanceType.WizzardWorkingArea;
}

function isWizzardSelectionAreaPolygon(
  type: DrawingsInstanceType,
  _geometry: DrawingsGeometryType,
): _geometry is DrawingsPolygonGeometry {
  return type === DrawingsInstanceType.WizzardSelectionArea;
}

function isRectangle(
  type: DrawingsInstanceType,
  _geometry: DrawingsGeometryType,
): _geometry is DrawingsPolygonGeometry {
  return type === DrawingsInstanceType.Rectangle;
}

function isPolyGeometry(
  type: DrawingsInstanceType,
  geometry: DrawingsGeometryType | DrawingsBasePolyGeometryNew,
): geometry is DrawingsBasePolyGeometryNew {
  return isPolyline(type, geometry) ||
    isPolygon(type, geometry) ||
    isRectangle(type, geometry);
}

function isClosedContour(
  type: DrawingsInstanceType,
  geometry: DrawingsGeometryType,
): geometry is DrawingsPolygonGeometry {
  return isPolygon(type, geometry) || isRectangle(type, geometry);
}

function isUtilityGeometry(
  type: DrawingsInstanceType,
  _geometry: DrawingsGeometryType,
): _geometry is DrawingsPolygonGeometry | DrawingsPolylineGeometry {
  return UtilityInstanceTypes.includes(type);
}

function* selectedPolygonsIterator(
  selectedInstances: string[],
  getInstance: (instanceId: string) => DrawingsGeometryInstance,
): IterableIterator<DrawingsGeometryInstanceWithId<DrawingsPolygonGeometry>> {
  for (const id of selectedInstances) {
    const instance = getInstance(id);
    if (
      instance && (
        isPolygon(instance.type, instance.geometry)
        || isRectangle(instance.type, instance.geometry)
      )
    ) {
      const instanceWithId = { id, ...instance };
      yield instanceWithId as DrawingsGeometryInstanceWithId<DrawingsPolygonGeometry>;
    }
  }
}

function getPointsCenter(points: DrawingsGeometryPointCoordinates[]): DrawingsGeometryPointCoordinates {
  const summary = points.reduce((prev, current) => ({ x: prev.x + current.x, y: prev.y + current.y }), { x: 0, y: 0 });
  return {
    x: summary.x / points.length,
    y: summary.y / points.length,
  };
}

function getPointsBounds(points: Iterable<ShortPointDescription>): DrawingsBounds {
  const xs = [];
  const ys = [];
  for (const [x, y] of points) {
    xs.push(x);
    ys.push(y);
  }
  const maxX = Math.max(...xs);
  const maxY = Math.max(...ys);
  const minX = Math.min(...xs);
  const minY = Math.min(...ys);
  const width = maxX - minX;
  const height = maxY - minY;
  return {
    x: minX,
    y: minY,
    width,
    height,
    center: { x: minX + width / 2, y: minY + height / 2 },
  };
}


function getBounds(
  instances: Record<string, DrawingsGeometryInstance>,
  instancesIds: Iterable<string>,
  points: Record<string, ShortPointDescription>,
): DrawingsBounds {
  let maxX = -Infinity;
  let maxY = -Infinity;
  let minX = Infinity;
  let minY = Infinity;
  for (const instanceId of instancesIds) {
    const instance = instances[instanceId];
    if (instance && (isPolyGeometry(instance.type, instance.geometry) || isCount(instance.type, instance.geometry))) {
      for (const pointId of instance.geometry.points) {
        const [x, y] = points[pointId];
        maxX = Math.max(maxX, x);
        minX = Math.min(minX, x);
        maxY = Math.max(maxY, y);
        minY = Math.min(minY, y);
      }
    } else {
      return null;
    }
  }
  const width = maxX - minX;
  const height = maxY - minY;

  return {
    x: minX,
    y: minY,
    width,
    height,
    center: { x: minX + width / 2, y: minY + height / 2 },
  };
}

function getCurrentDrawingInstancesBounds(
  instances: Record<string, DrawingsGeometryInstance>,
  instancesIds: string[],
  points: Record<string, ShortPointDescription>,
  drawingId: string,
): DrawingsBounds {
  const currentDrawingInstancesIds =
    arrayUtils.filterIterator(instancesIds, id => instances[id]?.drawingId === drawingId);
  return getBounds(instances, currentDrawingInstancesIds, points);
}

function zoomAndRotateBounds(
  bounds: DrawingsBounds,
  zoom: number,
  rotation: number,
  pageWidth: number,
  pageHeight: number,
): DrawingsBounds {
  const center: ShortPointDescription = [pageWidth / 2, pageHeight / 2];
  let minX = bounds.x;
  let maxX = bounds.x + bounds.width;
  let minY = bounds.y;
  let maxY = bounds.y + bounds.height;
  [minX, minY] = DrawingsCanvasUtils.rotatePoint([minX, minY], -rotation, center);
  [maxX, maxY] = DrawingsCanvasUtils.rotatePoint([maxX, maxY], -rotation, center);
  if (minX > maxX) {
    [minX, maxX] = [maxX, minX];
  }
  if (minY > maxY) {
    [minY, maxY] = [maxY, minY];
  }
  const width = maxX - minX;
  const height = maxY - minY;
  let sizeDiff = 0;
  if (rotation === 90 || rotation === 270) {
    sizeDiff = Math.abs(pageWidth - pageHeight) / 2;
  }
  const centerPoint = bounds.center ? {
    x: (minX - sizeDiff + width / 2) * zoom,
    y: (minY + sizeDiff + height / 2) * zoom,
  } : undefined;
  return {
    x: (minX - sizeDiff) * zoom,
    y: (minY + sizeDiff) * zoom,
    width: width * zoom,
    height: height * zoom,
    center: bounds.center ? centerPoint : undefined,
  };
}

function aggregateSelection(
  instances: Record<string, DrawingsGeometryInstance>,
  instancesIds: string[],
  currentDrawingId: string,
): Record<DrawingsSelectAggregationGroup, DrawingsAggregatedSelection> {
  const aggregatedSelection: Record<DrawingsSelectAggregationGroup, DrawingsAggregatedSelection> = {} as any;
  const incrementCategoryCount = (type: DrawingsSelectAggregationGroup): void => {
    aggregatedSelection[type] = aggregatedSelection[type] || { name: type, count: 0 };
    aggregatedSelection[type].count++;
  };
  for (const instanceId of instancesIds) {
    const instance = instances[instanceId];
    if (!instance || instance.drawingId !== currentDrawingId) {
      continue;
    }
    if (isPolyGeometry(instance.type, instance.geometry) || isCount(instance.type, instance.geometry)) {
      incrementCategoryCount(DrawingsMenuUtils.getAggregationType(instance));
    } else {
      return null;
    }
  }
  return aggregatedSelection;
}

function calculateInstanceMeasurementsWithSavedPoints(
  instance: DrawingsGeometryInstance,
  points: Record<string, ShortPointDescription>,
  scale: number,
  metersPerPixel: number,
): DrawingsMeasureType {
  return calculateInstanceMeasurements(instance, scale, metersPerPixel, pointId => points[pointId]);
}


function calculateInstanceMeasurements(
  instance: DrawingsGeometryInstance,
  scale: number,
  metersPerPixel: number,
  getPointInfo: (pointId: string) => ShortPointDescription,
): DrawingsMeasureType {
  let measures: DrawingsMeasureType;
  if (isPolygon(instance.type, instance.geometry)) {
    const path = DrawingsPaperUtils.simplePolygonToPath(
      instance.geometry.points,
      x => new paper.Point(getPointInfo(x)),
    );
    measures = DrawingsCanvasUtils.getPolygonMeasures(path, scale, metersPerPixel, instance.geometry);
    if (instance.geometry.children) {
      for (const child of instance.geometry.children) {
        const childPath = DrawingsPaperUtils.simplePolygonToPath(child, x => new paper.Point(getPointInfo(x)));
        measures = DrawingsCanvasUtils.subtractPolygonMeasures(
          measures,
          DrawingsCanvasUtils.getPolygonMeasures(childPath, scale, metersPerPixel, instance.geometry),
        );
        childPath.remove();
      }
    }
    path.remove();
  } else if (isRectangle(instance.type, instance.geometry)) {
    const path = new paper.Path(instance.geometry.points.map(x => new paper.Point(getPointInfo(x))));
    path.closePath();
    measures = DrawingsCanvasUtils.getRectangleMeasures(path, scale, metersPerPixel, instance.geometry);
    path.remove();
  } else if (isCount(instance.type, instance.geometry)) {
    return { count: instance.geometry.points.length, pointsCount: instance.geometry.points.length };
  } else if (isPolyline(instance.type, instance.geometry)) {
    const path = new paper.Path(instance.geometry.points.map(x => new paper.Point(getPointInfo(x))));
    measures = DrawingsCanvasUtils.getPolylineMeasures(path, scale, metersPerPixel, instance.geometry);
    path.remove();
  }
  return measures;
}

function calculateSegmentMeasurements(
  [startPoint, endPoint]: [string, string],
  points: Record<string, ShortPointDescription>,
  scale: number,
  mInPx: number,
): DrawingsMeasureLength {
  const pxLength = DrawingsCanvasUtils.calculateLineLength(points[startPoint], points[endPoint]);
  return {
    length: DrawingsCanvasUtils.pxToMetres(pxLength, scale, mInPx),
    pxLength,
  };
}

function checkContourIntersections(
  contour: paper.Point[],
  contours: paper.Point[][],
  lines: Array<[paper.Point, paper.Point]>,
): boolean {
  const parentContour = contours.length ? contours[0] : null;
  for (const [start, end] of DrawingAnnotationUtils.iteratePoints(contour, DrawingsInstanceType.Polygon)) {
    for (const line of lines) {
      const [lineStart, lineEnd] = line;
      if (start.equals(lineEnd)) {
        continue;
      }
      if (PaperIntersectionUtils.getLinesIntersection(start, end, lineStart, lineEnd)) {
        return false;
      }
    }
    if (parentContour) {
      const pointsIterator = DrawingAnnotationUtils.iteratePoints(parentContour, DrawingsInstanceType.Polygon);
      const isPointInside = DrawingsPaperUtils.isPointInside(start, pointsIterator);
      if (!isPointInside) {
        return false;
      }
    }
    for (let i = 1; i < contours.length; i++) {
      const pointsIterator = DrawingAnnotationUtils.iteratePoints(contours[i], DrawingsInstanceType.Polygon);

      if (DrawingsPaperUtils.isPointInside(start, pointsIterator)) {
        return false;
      }
    }
    lines.push([start, end]);
  }

  for (let i = 1; i < contours.length; i++) {
    for (const [start] of DrawingAnnotationUtils.iteratePoints(contours[i], DrawingsInstanceType.Polygon)) {
      const pointsIterator = DrawingAnnotationUtils.iteratePoints(contours[i], DrawingsInstanceType.Polygon);
      if (DrawingsPaperUtils.isPointInside(start, pointsIterator)) {
        return false;
      }
    }
  }
  contours.push(contour);
  return true;
}

function hasIntersectionsInHiddenData(
  geometry: DrawingsPolygonGeometry,
  getPaperPointInfo: (pointId: string) => paper.Point,
): boolean {
  const contours = new Array<paper.Point[]>();
  const lines = [];
  if (!checkContourIntersections(geometry.points.map(getPaperPointInfo), contours, lines)) {
    return true;
  }
  if (geometry.children) {
    for (const child of geometry.children) {
      if (!checkContourIntersections(child.map(getPaperPointInfo), contours, lines)) {
        return true;
      }
    }
  }
  return false;
}

function canRemovePointFromNotVisibleData(
  geometry: DrawingsPolygonGeometry,
  pointIds: string[],
  getPaperPointInfo: (pointId: string) => paper.Point,
): boolean {

  const contours = new Array<paper.Point[]>();
  const lines = [];
  const pointIdsSet = new Set(pointIds);

  const checkContour = (contour: string[]): boolean => {
    const filteredContour = arrayUtils.filterMap(contour, x => !pointIdsSet.has(x), getPaperPointInfo);
    if (filteredContour.length < 3) {
      return false;
    }
    return checkContourIntersections(filteredContour, contours, lines);
  };
  if (!checkContour(geometry.points)) {
    return false;
  }
  if (geometry.children) {
    for (const child of geometry.children) {
      if (!checkContour(child)) {
        return false;
      }
    }
  }
  return true;
}

function removePointsFromNotVisibleCount(
  geometry: DrawingsCountGeometry,
  pointIds: string[],
): DrawingsAddRemovePointResults<DrawingsCountGeometry, DrawingsMeasureCount> {
  const pointsSet = new Set(pointIds);
  const count = geometry.points.length - pointIds.length;
  return {
    geometry: { ...geometry, points: geometry.points.filter(x => !pointsSet.has(x)) },
    measures: {
      count,
      pointsCount: count,
    },
    removedLines: [],
    newLines: {},
  };
}

function removeAndAddLinesByPointInClosedGeometry(
  index: number,
  points: string[],
  addLineToRemoved: (lineId: string) => void,
): { startPoint: string, endPoint: string } {
  let startPoint: string;
  let endPoint: string;
  if (index === 0 || index === points.length - 1) {
    if (index === points.length - 1) {
      startPoint = points[0];
      endPoint = points[index - 1];
      addLineToRemoved(DrawingAnnotationUtils.getLineKey(points[index], points[index - 1]));
      addLineToRemoved(DrawingAnnotationUtils.getLineKey(points[0], points[points.length - 1]));
    } else if (index === 0) {
      startPoint = points[1];
      endPoint = points.slice(-1)[0];
      addLineToRemoved(DrawingAnnotationUtils.getLineKey(points[0], points[1]));
      addLineToRemoved(DrawingAnnotationUtils.getLineKey(endPoint, points[0]));
    }
  } else {
    startPoint = points[index - 1];
    endPoint = points[index + 1];
    addLineToRemoved(DrawingAnnotationUtils.getLineKey(points[index + 1], points[index]));
    addLineToRemoved(DrawingAnnotationUtils.getLineKey(points[index], points[index - 1]));
  }
  return { startPoint, endPoint };
}

function addPointToPoly(
  source: string[],
  firstIndex: number,
  lastIndex: number,
  pointIds: string[],
  type: DrawingsInstanceType,
): string[] {
  if (firstIndex > lastIndex) {
    [lastIndex, firstIndex] = [firstIndex, lastIndex];
  }
  if (firstIndex === 0 && lastIndex === source.length -1 && type !== DrawingsInstanceType.Line) {
    return source.concat(pointIds);
  } else {
    return [...source.slice(0, firstIndex + 1), ...pointIds, ...source.slice(lastIndex)];
  }
}


function addLineToRecordsByPoints(start: string, end: string, lines: Record<string, [string, string]>): void {
  lines[DrawingAnnotationUtils.getLineKey(start, end)] = [start, end];
}

function addPointToHiddenInstance<T extends DrawingsGeometryType>(
  geometry: T,
  type: DrawingsInstanceType,
  pointIds: string[],
  [startPoint, endPoint]: [string, string],
): DrawingsAddRemovePointResults<T, DrawingsMeasureType> {
  const removedLines = [DrawingAnnotationUtils.getLineKey(startPoint, endPoint)];
  const newLines: Record<string, [string, string]> = {};
  addLineToRecordsByPoints(startPoint, pointIds[0], newLines);
  addLineToRecordsByPoints(pointIds[pointIds.length - 1], endPoint, newLines);
  for (let i = 1; i < pointIds.length; i++) {
    addLineToRecordsByPoints(pointIds[i - 1], pointIds[i], newLines);
  }

  if (isPolyGeometry(type, geometry)) {
    const newGeometry = { ...geometry };
    const startPointIndex = geometry.points.lastIndexOf(startPoint);
    if (startPointIndex === -1 && isPolygon(type, geometry) && geometry.children) {
      const children = [];
      for (const child of geometry.children) {
        const startIndex = child.findIndex(x => x === startPoint);
        if (startIndex === -1) {
          children.push(child);
        } else {
          const endPointIndex = geometry.points.lastIndexOf(endPoint);
          children.push(addPointToPoly(child, startIndex, endPointIndex, pointIds, type));
        }
      }
      (newGeometry as DrawingsPolygonGeometry).children = children;
    } else {
      const endPointIndex = geometry.points.lastIndexOf(endPoint);
      newGeometry.points = addPointToPoly(geometry.points, startPointIndex, endPointIndex, pointIds, type);
      return { geometry: newGeometry, removedLines, newLines, measures: null };
    }
  }
  return { geometry, removedLines, newLines, measures: null };
}

export const DrawingsGeometryUtils = {
  isPolygon,
  isPolyline,
  isCount,
  isCalibrate,
  isPolyGeometry,
  isClosedContour,
  isVisualSearchSourcePolygon,
  isVisualSearchAreaPolygon,
  isRectangle,
  isUtilityGeometry,
  isDropperAreaPolygon,
  isWizzardSelectionAreaPolygon,
  aggregateSelection,
  getCurrentDrawingInstancesBounds,
  selectedPolygonsIterator,
  getPointsCenter,
  getInstancesBounds: getBounds,
  zoomAndRotateBounds,
  calculateInstanceMeasurements,
  calculateSegmentMeasurements,
  calculateInstanceMeasurementsWithSavedPoints,
  canRemovePointFromNotVisibleData,
  hasIntersectionsInHiddenData,
  removeAndAddLinesByPointInClosedGeometry,
  addPointToHiddenInstance,
  addPointToPoly,
  addLineToRecordsByPoints,
  removePointsFromNotVisibleCount,
  getPointsBounds,
};
