import { Icons, CheckboxButton, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { AutoMeasureOption } from 'common/components/drawings/interfaces';
import { Styled } from './styled';


interface Props {
  data: AutoMeasureOption;
  selected: boolean;
}

export const AutoMeasureCheckboxOption: React.FC<Props> = ({ data, selected }) => {
  return (
    <>
      <CheckboxButton checked={selected} />
      <Styled.Icon>
        { data.isPolyline ? <Icons.Line /> : <Icons.Area2D />}
      </Styled.Icon>
      <Text fontSize={14}>{data.name}</Text>
    </>
  );
};
