import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconTreeCollapsed: React.FC = () => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
    >
      <g id='Icons/20x20/Tree_Collapsed' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          // eslint-disable-next-line max-len
          d='M10,1 C5.02943725,1 1,5.02943725 1,10 C1,14.9705627 5.02943725,19 10,19 C14.9705627,19 19,14.9705627 19,10 C19,5.02943725 14.9705627,1 10,1 Z'
          id='Rectangle-Copy-5'
          stroke={KreoColors.blue2}
          strokeWidth='2'
          fill='#FFFFFF'
        />
        <path
          // eslint-disable-next-line max-len
          d='M9,9 L9,6 C9,5.44771525 9.44771525,5 10,5 C10.5522847,5 11,5.44771525 11,6 L11,9 L14,9 C14.5522847,9 15,9.44771525 15,10 C15,10.5522847 14.5522847,11 14,11 L11,11 L11,14 C11,14.5522847 10.5522847,15 10,15 C9.44771525,15 9,14.5522847 9,14 L9,11 L6,11 C5.44771525,11 5,10.5522847 5,10 C5,9.44771525 5.44771525,9 6,9 L9,9 Z'
          fill={KreoColors.blue}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
