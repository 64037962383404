import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Button from 'react-progress-button';

import './image-upload-zone.scss';

import { ConstantFunctions } from 'common/constants/functions';
import { CommonsApi } from '../../../../api/common';
import { CommonApi } from '../../../../api/server';
import { ImageUploadInput } from '../image-upload-input';
import { Preview } from './preview';

enum ButtonLoadStates {
  none = '',
  success = 'success',
  error = 'error',
  loading = 'loading',
}

interface State {
  imagePreviewUrl: string;
  defaultPreview: string;
  loadState: ButtonLoadStates;
  displayNotification: boolean;
  errorMessage: string;
}

interface Props {
  previewUrl: string;
  defaultImagePreviewUrl?: string;
  deleteImage: () => void;
  // todo typedef
  onLoadEnd: (response: string) => void;
  imageInterceptor: (file: File) => void;
  size?: string;
}

export class ImageUploadZone extends Component<Props, State> {
  private input: HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      imagePreviewUrl: null,
      defaultPreview: props.defaultImagePreviewUrl,
      loadState: ButtonLoadStates.none,
      displayNotification: false,
      errorMessage: '',
    };
  }

  public componentDidMount(): void {
    document.addEventListener('dragover', this.preventDefault, false);
    document.addEventListener('drop', this.preventDefault, false);
    if (this.state.defaultPreview) {
      CommonApi.getBinary(this.state.defaultPreview).catch(() => {
        this.setState({ defaultPreview: undefined });
      });
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (
      this.props.defaultImagePreviewUrl === undefined
      && this.props.defaultImagePreviewUrl !== prevProps.defaultImagePreviewUrl
    ) {
      this.setState({ imagePreviewUrl: undefined, defaultPreview: undefined });
      this.props.deleteImage();
    }
  }

  public render(): React.ReactNode {
    const { loadState, imagePreviewUrl, defaultPreview } = this.state;
    const previewUrl = imagePreviewUrl || defaultPreview;
    return (
      <div className='image-uploader'>
        <div className='image-uploader__files-input-container'>
          <div
            className={classNames(
              'image-uploader__load-container',
              { [`image-uploader__load-container--${this.props.size}`]: !!this.props.size },
            )}
          >
            { previewUrl && (
                <div
                  className={'image-uploader__delete-preview'}
                  onClick={this.onDeletePreviewClick}
                />
            )}
            <Dropzone
              onDrop={this.handleFileDrop}
              accept='image/*'
              disabled={false}
            >
              {
                (({ getRootProps, getInputProps }) => {
                  return (
                    <div {...getRootProps({ className: 'image-uploader__dropzone' })}>
                      <input {...getInputProps()}/>
                      <Button
                        controlled={true}
                        state={loadState}
                        onClick={this.openFileDialogClick}
                        type='button'
                        classNamespace='image-uploader__button-'
                        className='image-uploader__pseudo-button'
                        onMouseOver={this.showNotification}
                        onMouseLeave={this.hideNotification}
                        onDragOver={this.showNotification}
                        onDragLeave={this.hideNotification}
                      >
                        <Preview
                          displayNotification={this.state.displayNotification}
                          previewUrl={previewUrl}
                        />
                      </Button>
                    </div>
                  );
                })
              }
            </Dropzone>
          </div>
          <ImageUploadInput
            ref={this.saveInputRef}
            onImageSelected={this.props.imageInterceptor}
            disabled={loadState === 'loading'}
          />
        </div>
        {this.state.errorMessage ? <span className='image-uploader__error'>{this.state.errorMessage}</span> : null}
      </div>
    );
  }

  public componentWillUnmount(): void {
    document.removeEventListener('dragover', this.preventDefault);
    document.removeEventListener('drop', this.preventDefault);
  }

  @autobind
  public upload(blob: Blob): void {
    const { onLoadEnd } = this.props;
    this.setState({ loadState: ButtonLoadStates.loading });
    if (!blob.type.match('image.*')) {
      console.warn('Invalid file type');
      this.setState({ loadState: ButtonLoadStates.error });
    } else {
      this.uploadImages(blob, onLoadEnd);
    }
  }

  @autobind
  public openFileDialogClick(e: React.MouseEvent): void {
    ConstantFunctions.stopEvent(e);
    if (this.input) {
      this.input.value = '';
      this.input.click();
    }
  }
  private preventDefault(event: DragEvent): void {
    event.preventDefault();
  }

  @autobind
  private saveInputRef(input: HTMLInputElement): void {
    this.input = input;
  }

  @autobind
  private hideNotification(): void {
    this.setState({ displayNotification: false });
  }

  @autobind
  private onDeletePreviewClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.preventDefault();
    this.setState({ imagePreviewUrl: undefined, defaultPreview: undefined });
    this.props.deleteImage();
  }

  @autobind
  private handleFileDrop(files: File[]): void {
    if (files.length && files[0]) {
      this.props.imageInterceptor(files[0]);
    }
  }

  @autobind
  private showNotification(): void {
    const { imagePreviewUrl } = this.state;
    if (imagePreviewUrl) {
      this.setState({ displayNotification: true });
    }
  }

  private uploadImages(file: Blob, onLoadEnd: (response?: string) => void): void {
    CommonsApi.uploadFileWithoutProgressTracking(file as File).then(responseData => {
      const [imageUrl] = responseData;
      this.setState({
        imagePreviewUrl: `${this.props.previewUrl}/${imageUrl}`,
        loadState: ButtonLoadStates.success,
      });
      onLoadEnd(imageUrl);
    }).catch(error => {
      console.error(error);
      this.setState({ loadState: ButtonLoadStates.error });
    });
  }
}
