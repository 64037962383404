import dotProp from 'dot-prop-immutable';
import * as monolite from 'monolite';

import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import {
  ActivityAssignmentSelectMaterialPayload,
  ActivityAssignmentSetModalityPayload,
  PathAndEndElementTitlePayload,
} from 'unit-cost-estimate/actions/payloads';
import { CEActivityAssignmentActionTypes } from 'unit-cost-estimate/actions/types';
import {
  CE_ACTIVITY_ASSIGNMENT_INITIAL_STATE,
} from 'unit-cost-estimate/constants/ce-activity-assignment-initial-state';
import {
  CEActivityAssignmentActivity,
  CEActivityAssignmentAggregatedBimInfo,
  CEActivityAssignmentAggregatedData,
  CEActivityAssignmentState,
  CEActivityAssignmentTreeElementType,
  CEActivityAssignmentTreeNodeActivitySort,
} from 'unit-cost-estimate/interfaces';
import { CEActivityAssignmentUtils } from 'unit-cost-estimate/utils/ce-activity-assignment-utils';

const saveBimData = (
  state: CEActivityAssignmentState,
  payload: CEActivityAssignmentAggregatedBimInfo,
): CEActivityAssignmentState => {
  return new MonoliteHelper(state)
    .set(_ => _.layersData, payload.layersData)
    .set(_ => _.groupNames, payload.groupNames)
    .set(_ => _.dataDictionary, payload.dataDictionary)
    .set(_ => _.rawData, payload.rawData)
    .set(_ => _.elementDataMap, payload.elementDataMap)
    .set(_ => _.statuses.revitData, RequestStatus.Loaded)
    .get();
};

const expandItem = (
  state: CEActivityAssignmentState,
  payload: string,
): CEActivityAssignmentState => {
  return dotProp.set(state, `${payload}.expanded`, value => !value);
};

const saveCurrentProperties = (
  state: CEActivityAssignmentState,
  work: CEActivityAssignmentActivity,
): CEActivityAssignmentState => {
  if (CEActivityAssignmentUtils.stateIsFull(state)) {
    const props = CEActivityAssignmentUtils.buildProperties(
      work,
      state.layersData,
      state.groupNames,
      state.rawData,
      state.dataDictionary,
      state.elementDataMap,
    );
    state = monolite.set(state, _ => _.properties)(props);
  }
  return state;
};

const hideActivitiesAndProps = (state: CEActivityAssignmentState): CEActivityAssignmentState => {
  state = monolite.set(state, _ => _.selectedWork)(null);
  state = monolite.set(state, _ => _.properties)([]);
  state = monolite.set(state, _ => _.isShowActivities)(false);
  return state;
};


const selectTreeNode = (
  state: CEActivityAssignmentState,
  payload: string,
): CEActivityAssignmentState => {
  state = hideActivitiesAndProps(state);
  state = monolite.set(state, _ => _.selectedPath)(payload);
  state = monolite.set(state, _ => _.isShowSelection)(true);
  return state;
};

const showActivitiesByElementType = (
  state: CEActivityAssignmentState,
  payload: string,
): CEActivityAssignmentState => {
  const item: CEActivityAssignmentTreeElementType = dotProp.get(state, payload);
  const works = state.works.slice(item.start, item.end);
  state = monolite.set(state, _ => _.selectedWorks)(CEActivityAssignmentUtils.extractActivityData(works[0]));
  state = saveCurrentProperties(state, works[0]);
  state = monolite.set(state, _ => _.selectedPath)(payload);
  state = monolite.set(state, _ => _.selectedWork)(works[0]);
  state = monolite.set(state, _ => _.isShowActivities)(true);
  state = monolite.set(state, _ => _.isShowSelection)(true);
  return state;
};

const showActivitiesForLayer = (
  state: CEActivityAssignmentState,
  payload: PathAndEndElementTitlePayload,
): CEActivityAssignmentState => {

  const work: CEActivityAssignmentActivity = state.works[payload.path];
  state = monolite.set(state, _ => _.selectedWorks)([...CEActivityAssignmentUtils.extractActivityData(work)]);
  state = saveCurrentProperties(state, work);
  state = monolite.set(state, _ => _.selectedPath)(payload.path);
  state = monolite.set(state, _ => _.selectedWork)(work);
  state = monolite.set(state, _ => _.isShowActivities)(true);
  state = monolite.set(state, _ => _.isShowSelection)(true);
  return state;
};

const searchInRevitTree = (
  state: CEActivityAssignmentState,
  payload: string,
): CEActivityAssignmentState => {
  const helper = new MonoliteHelper(state);
  if (payload) {
    const searchResult = CEActivityAssignmentUtils.search(state.tree, payload);
    const tree = CEActivityAssignmentUtils.expandTreeByPathes(state.tree.slice(), searchResult);

    helper.set(_ => _.searchMode, true);
    helper.set(_ => _.searchedPathes, searchResult);
    helper.set(_ => _.tree, tree);
  } else {
    helper.set(_ => _.searchMode, false);
  }
  return helper.get();
};

const changeSelectedModalityForActivity = (
  state: CEActivityAssignmentState,
  payload: ActivityAssignmentSetModalityPayload,
): CEActivityAssignmentState => {
  const { index, variantId } = payload;
  return new MonoliteHelper(state)
    .set(
      _ => _.selectedWorks[index].selectedVariant,
      _ => ({
        ..._,
        manualId: variantId !== _.autoId ? variantId : null,
      }))
    .get();
};

function selectMaterial(
  state: CEActivityAssignmentState,
  payload: ActivityAssignmentSelectMaterialPayload,
): CEActivityAssignmentState {
  const { activityIndex, materialId, materialVariantId } = payload;
  return new MonoliteHelper(state)
    .set(
      _ => _.selectedWorks[activityIndex].materials[materialId].selectedVariant,
      _ => ({
        ..._,
        manualId: materialVariantId !== _.autoId ? materialVariantId : null,
      }))
    .get();
}

const approveAssignmentInState = (
  state: CEActivityAssignmentState, payload: CEActivityAssignmentTreeNodeActivitySort,
): CEActivityAssignmentState => {
  const linkIndex = state.workLinks.findIndex(link => link.work === payload.id);
  if (linkIndex === -1) {
    console.error(`work with id = ${payload.id} doesn't exist in state`);
    return state;
  }
  const path = CEActivityAssignmentUtils.getActivityPath(state.workLinks[linkIndex]);
  const node = dotProp.set(state, path);
  const workIndex = state.works.findIndex(value => value.id === payload.id);
  const work = state.works[workIndex];

  let updatedState: CEActivityAssignmentState = { ...state };
  updatedState = monolite.set(
    updatedState,
    _ => _.workLinks[linkIndex].manuallyChanged,
  )(CEActivityAssignmentUtils.isActivityChangedManually(payload.data));
  updatedState = monolite.set(updatedState, _ => _.workLinks[linkIndex].hasDiff)(false);
  updatedState = monolite.set(updatedState, _ => _.works[workIndex].diffType)(0);
  if (CEActivityAssignmentUtils.isActivityBad(payload.data)) {
    updatedState = monolite.set(updatedState, _ => _.workLinks[linkIndex].isGood)(false);

    if (
      work.engineIds.length > 0 &&
      !updatedState.badIds.find(value => value === work.engineIds[0])
    ) {
      updatedState = monolite.set(updatedState, _ => _.badIds)([...updatedState.badIds, ...work.engineIds]);
    }
    if (node.end - node.start > 1) {
      const workPath = `work.${workIndex}`;
      const isExistsInSetOfErrors = !!updatedState.errorNodes.find(value => value === workPath);
      if (!isExistsInSetOfErrors) {
        const newErrorPathes = [...updatedState.errorNodes, workPath];
        updatedState = monolite.set(updatedState, _ => _.errorNodes)(newErrorPathes);
      }
    } else {
      const isExistsInSetOfErrors = !!updatedState.errorNodes.find(value => value === path);
      if (
        work.engineIds.length > 0 &&
        !!updatedState.badIds.find(bimElementId => bimElementId === work.engineIds[0])
      ) {
        const set = new Set(work.engineIds);
        updatedState = monolite.set(
          updatedState,
          _ => _.badIds,
        )(updatedState.badIds.filter(bimElementId => set.has(bimElementId)));
      }
      if (!isExistsInSetOfErrors) {
        const pathes = [...updatedState.errorNodes, path];
        updatedState = monolite.set(updatedState, _ => _.errorNodes)(pathes);
      }
    }
  } else {
    updatedState = monolite.set(updatedState, _ => _.workLinks[linkIndex].isGood)(true);
    let pathes = new Array<string>();
    if (node.end - node.start > 1) {
      const wpath = `work.${workIndex}`;
      pathes = updatedState.errorNodes.filter(value => value !== wpath);
    } else {
      pathes = updatedState.errorNodes.filter(value => value !== path);
    }
    updatedState = monolite.set(updatedState, _ => _.errorNodes)(pathes);
    const engineIds = new Set<number>(work.engineIds);
    updatedState = monolite.set(
      updatedState,
      _ => _.badIds,
    )(updatedState.badIds.filter(value => !engineIds.has(value)));
  }
  updatedState = monolite.set(updatedState, (_) => _.hasChanges)(true);
  return updatedState;
};

const selectFromEngine = (
  state: CEActivityAssignmentState,
  payload: number,
): CEActivityAssignmentState => {
  const helper = new MonoliteHelper(state)
    .setMap(
      x => x.tree,
      x => !x.expanded ? x : new MonoliteHelper(x).set(_ => _.expanded, false).get(),
    );
  const elementPath = CEActivityAssignmentUtils.getElementPathById(state, payload).path;
  const path = elementPath.split('.');
  const category = path[0];
  const family = path[2];
  const type = path[4];
  helper
    .set(_ => _.tree[category].expanded, true)
    .set(_ => _.tree[category].children[family].expanded, true)
    .set(_ => _.tree[category].children[family].children[type].expanded, true)
    .set(_ => _.centralTreeElement, Number(category) + Number(family) + Number(type));
  if (path.length % 2 === 1) {
    return showActivitiesByElementType(helper.get(), `tree.${elementPath}`);
  } else {
    return showActivitiesForLayer(helper.get(), {
      path: path[5],
      endElementTitle: state.tree[category].children[family].children[type].name,
    });
  }
};

const updateActivityData = (
  state: CEActivityAssignmentState,
  payload: CEActivityAssignmentTreeNodeActivitySort,
): CEActivityAssignmentState => {
  const helper = new MonoliteHelper(state);
  const index = state.works.findIndex(({ id }) => id === payload.id);

  if (index >= 0) {
    helper.set(_ => _.works[index], payload);
    helper.set(_ => _.selectedWorks, CEActivityAssignmentUtils.extractActivityData(payload));
  }

  if (state.selectedWork && state.selectedWork.id === payload.id) {
    helper.set(_ => _.selectedWork, payload);
  }

  return helper.get();
};

const dropState = (state: CEActivityAssignmentState): CEActivityAssignmentState => {
  if (state.isSaveData) {
    return state;
  } else {
    return CE_ACTIVITY_ASSIGNMENT_INITIAL_STATE;
  }
};

const showHideRevitTree = (state: CEActivityAssignmentState): CEActivityAssignmentState => {
  return monolite.set(state, _ => _.viewTree)(!state.viewTree);
};

export const CEActivityAssignmentReducerMethods: ReducerMethods<CEActivityAssignmentState> = {
  [CEActivityAssignmentActionTypes.LOAD_DATA]: (state: CEActivityAssignmentState) => {
    return new MonoliteHelper(state)
      .set(_ => _.dataLoaded, false)
      .set(_ => _.statuses.loadData, RequestStatus.Loading)
      .get();
  },
  [CEActivityAssignmentActionTypes.LOAD_DATA_SUCCEEDED]: (
    state: CEActivityAssignmentState,
    payload: CEActivityAssignmentAggregatedData,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.tree, payload.tree)
      .set(_ => _.works, payload.works)
      .set(_ => _.badIds, payload.badIds)
      .set(_ => _.dataLoaded, true)
      .set(_ => _.workLinks, payload.workLinks)
      .set(_ => _.engineIds, payload.engineIds)
      .set(_ => _.statuses.loadData, RequestStatus.Loaded)
      .set(_ => _.databases, payload.databases)
      .set(_ => _.statistic, payload.statistic)
      .get();
  },
  [CEActivityAssignmentActionTypes.LOAD_WORK_SUCCEEDED]: (
    state: CEActivityAssignmentState,
    payload: CEActivityAssignmentActivity,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.selectedWork, payload)
      .set(_ => _.dataLoaded, true)
      .get();
  },
  [CEActivityAssignmentActionTypes.LOAD_DATA_FAILED]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.statuses.loadData, RequestStatus.Failed)
      .get();
  },
  [CEActivityAssignmentActionTypes.EXPAND_ITEM]: expandItem,
  [CEActivityAssignmentActionTypes.SHOW_WORK_FROM_TYPE]: showActivitiesByElementType,
  [CEActivityAssignmentActionTypes.SHOW_WORK_FROM_LAYER]: showActivitiesForLayer,
  [CEActivityAssignmentActionTypes.START_SEARCH]: searchInRevitTree,
  [CEActivityAssignmentActionTypes.GET_REVIT_DATA]:
    (state) => monolite.set(state, (_) => _.statuses.revitData)(RequestStatus.Loading),
  [CEActivityAssignmentActionTypes.DROP_STATE]: dropState,
  [CEActivityAssignmentActionTypes.SAVE_REVIT_DATA]: saveBimData,
  [CEActivityAssignmentActionTypes.SET_MODALITY]: changeSelectedModalityForActivity,
  [CEActivityAssignmentActionTypes.ASSIGN_WORKS_SUCCEEDED]: approveAssignmentInState,
  [CEActivityAssignmentActionTypes.SELECT_FROM_ENGINE]: selectFromEngine,
  [CEActivityAssignmentActionTypes.SET_SAVE_DATA]:
    (state, payload: boolean) => monolite.set(state, (_) => _.isSaveData)(payload),
  [CEActivityAssignmentActionTypes.UPDATE_WORK]: updateActivityData,
  [CEActivityAssignmentActionTypes.SHOW_HIDE_TREE]: showHideRevitTree,
  [CEActivityAssignmentActionTypes.SELECT_TREE_NODE]: selectTreeNode,
  [CEActivityAssignmentActionTypes.SELECT_MATERIAL]: selectMaterial,
};
