import { Icons } from '@kreo/kreo-ui-components';
import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';

import { AnyAction, Dispatch } from 'redux';

import { TwoDActions } from '2d/actions/creators';
import { UPGRADE_PRO_PLAN_CONSTANTS } from '2d/components/upgrade-plan';
import { ReportPage } from '2d/interfaces/report-pages';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { CommentContext } from 'common/components/ag-grid-cell-with-comment';
import { DrawingsLayoutApi } from 'common/components/drawings';
import { ProgressActions, ProgressBarType } from 'common/components/progress';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { ProgressUtils } from 'common/utils/progress-utils';
import { withCommentsContext, WithCommentsContext } from 'unit-2d-comments/comments-context';
import { CommentaryTargetType, TwoDCommentsActions } from 'unit-2d-comments/index';
import { CommentaryItemsPageTarget, CommentaryThread } from 'unit-2d-comments/interfaces';
import { CommentaryTargetTypeGuards } from 'unit-2d-comments/utils';
import { TwoDViewTableConfig } from 'unit-2d-database/interfaces';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { DrawingElementsTable } from '../../../2d-element-view';
import {
  ItemHelper,
  ItemsViewTransactionProps,
  withItemsViewTransactionContext,
} from '../../../2d-element-view/utils';

interface DispatchProps {
  startCreateComment: (target: CommentaryItemsPageTarget) => void;
  openComment: (comentId: number) => void;
  openUpgradProPlanDialog: () => void;
  calculatePia: () => void;
  startProgress: (total: number, title: string) => void;
  updateProgress: (finished: number, title: string) => void;
  closeProgressbar: (title: string) => void;
}

interface StateProps {
  withTableTotal: boolean;
  selectedCommentId: number;
  reportPages: ReportPage[];
}

interface Props extends StateProps,
  DispatchProps,
  WithCommentsContext,
  AnalyticsProps,
  AbilityAwareProps,
  ItemsViewTransactionProps {
  drawingsLayoutApi: DrawingsLayoutApi;
  tableId: string;
  config: TwoDViewTableConfig;
  tableName: string;
}

class ItemsViewComponent extends React.PureComponent<Props> {
  private itemsDataHelper = new ItemHelper();
  private tableContext: CommentContext<{}> = {
    comments: [],
    getComent: this.getComment,
    openComent: this.openComment,
    isSelected: this.isCommentSelected,
  };
  private api: Ag.GridApi;
  private columnApi: Ag.ColumnApi;
  private updateColumns: (columns: Ag.ColDef[], isFirst: boolean) => void;
  private setApplyState: (value: boolean) => void;
  private setFilterWithSet: () => void;

  public componentDidMount(): void {
    const { reportPages, ability } = this.props;
    if (reportPages.length === 1
      && ability.cannot(Operation.Read, Subject.PiaDatabase)
    ) {
      this.props.openUpgradProPlanDialog();
    }
    this.props.calculatePia();
  }

  public render(): JSX.Element {
    const {
      tableId,
      drawingsLayoutApi,
      config,
      tableName,
      withTableTotal,
      ability,
    } = this.props;
    const cannotManage = ability.cannot(Operation.Read, Subject.PiaDatabase);
    const overlayNoRowsTemplate = cannotManage
      ? 'Your subscription doesn’t support Items report.'
      : undefined;
    const showCode = this.props.config.createCodeColumn;

    return (
      <DrawingElementsTable
        tableId={tableId}
        dataHelper={this.itemsDataHelper}
        drawingApi={drawingsLayoutApi}
        config={config}
        tableName={tableName}
        key={`${tableId}${withTableTotal}${showCode}${overlayNoRowsTemplate}`}
        getExtraContextMenu={this.getExtraContextMenu}
        tableContext={this.tableContext}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        disableUpdateConfig={cannotManage}
        saveApi={this.saveApi}
        isSkipInnerUpdate={true}
        onPivotModeChange={this.onPivotModeChange}
      />
    );
  }

  @autobind
  private saveApi(
    api: Ag.GridApi,
    columnApi: Ag.ColumnApi,
    updateColumns: (columns?: Ag.ColDef[], isFirst?: boolean) => void,
    setFilterWithSet: () => void,
    setApplyState: (value: boolean) => void,
  ): void {
    this.api = api;
    this.columnApi = columnApi;
    this.updateColumns = updateColumns;
    this.setApplyState = setApplyState;
    this.setFilterWithSet = setFilterWithSet;
    this.props.transaction.saveUpdate(this.update, this.setApplyState, this.isPivot);
  }

  @autobind
  private isPivot(): boolean {
    return this.columnApi.isPivotMode();
  }

  @autobind
  private update(p: { add: any[], update: any[], remove: any[] }, columns?: Ag.ColDef[], isFirst?: boolean): void {
    this.setApplyState(true);
    setTimeout(() => {
      this.api.applyTransactionAsync(p, () => {
        if (columns) {
          this.updateColumns(columns, isFirst);
        }
        this.api.refreshCells({ force: true });
        this.setFilterWithSet();
        this.setApplyState(false);
      });
    });
  }

  @autobind
  private onPivotModeChange(): void {
    this.props.transaction.changePivotMode();
  }

  @autobind
  private getComment(colId: string, _: number, rowId: string): CommentaryThread {
    return this.props.comments.find(c => {
      if (CommentaryTargetTypeGuards.isItemsPage(c.target) && c.target.reportPageId === this.props.tableId) {
        const targetColumnId = c.target.columnId;
        const { measurementId, itemName, assemblyName } = c.target;
        const targetRowId = `${measurementId}_${itemName}_${assemblyName}`;
        return targetColumnId === colId && rowId === targetRowId;
      }
    });
  }

  @autobind
  private isCommentSelected(colId: string, _: number, rowId: string): boolean {
    const { tableId, comments, selectedCommentId } = this.props;
    const actualComments = comments.filter(c => {
      if (CommentaryTargetTypeGuards.isItemsPage(c.target) && c.target.reportPageId === tableId) {
        const targetColumnId = c.target.columnId;
        const { measurementId, itemName, assemblyName } = c.target;
        const targetRowId = `${measurementId}_${itemName}_${assemblyName}`;
        return targetColumnId === colId && rowId === targetRowId;
      }
    });
    return actualComments.some(c => c.id === selectedCommentId);
  }

  @autobind
  private openComment(commentId: number): void {
    this.props.openComment(commentId);
    this.props.sendEvent(MetricNames.comments.openComments, { target: 'items view' });
  }

  @autobind
  private getExtraContextMenu(param: Ag.GetContextMenuItemsParams): Array<(string | Ag.MenuItemDef)> {
    const options = [];

    if (!param || !param.column) {
      return options;
    }

    const handleAddComment = this.startCreateComment(param);
    if (this.props.ability.can(Operation.Manage, Subject.Comment2d) && handleAddComment) {
      options.push(
        {
          name: 'Add Comment',
          action: this.startCreateComment(param),
          icon: ReactDOMServer.renderToString(<Icons.Comments2D />),
        },
      );
    }
    return options;
  }

  @autobind
  private startCreateComment(param: Ag.GetContextMenuItemsParams): () => void {
    const reportPageId = this.props.tableId;
    const columnId = param.column.getColId();
    const nodeId: string = param.node.data?.id;
    if (!nodeId) {
      return null;
    }
    const [measurementId, itemName, assemblyName] = nodeId.split('_');
    return columnId
      ? () => this.props.startCreateComment({
        type: CommentaryTargetType.ItemsReportPage,
        reportPageId,
        measurementId,
        columnId,
        itemName,
        assemblyName,
      })
      : null;
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    withTableTotal: state.persistedStorage.showTableTotal,
    selectedCommentId: state.twoDComments.selectedCommentId,
    reportPages: state.twoD.reportPages,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    startCreateComment: (target) => dispatch(TwoDCommentsActions.startAddComment(target)),
    openComment: (commentId) => {
      dispatch(TwoDCommentsActions.selectComment(commentId));
      dispatch(TwoDCommentsActions.setSelectedCommentId(commentId));
    },
    openUpgradProPlanDialog: () => dispatch(
      KreoDialogActions.openDialog(UPGRADE_PRO_PLAN_CONSTANTS.UPGRADE_PLAN_DIALOG)),
    calculatePia: () => dispatch(TwoDActions.calculatePia()),
    startProgress: (total, title) => dispatch(ProgressActions.addOrUpdateProgress({
      progressKey: ProgressUtils.REPORT_DATA_PROGRESS_KEY,
      progressBar: {
        title,
        type: ProgressBarType.Count,
        finished: 0,
        total,
      },
    })),
    updateProgress: (finished, title) => dispatch(ProgressActions.updateProgress({
      progressKey: ProgressUtils.REPORT_DATA_PROGRESS_KEY,
      progressBar: {
        title,
        finished,
      },
    })),
    closeProgressbar: (progressBarTitle) => dispatch(ProgressActions.removeProgressBar({
      progressKey: ProgressUtils.REPORT_DATA_PROGRESS_KEY,
      progressBarTitle,
    })),
  };
};

const ConnectedToRedux = connect(mapStateToProps, mapDispatchToProps)(withCommentsContext(ItemsViewComponent));

export const ItemsView = withAbilityContext(
  withAnalyticsContext(
    withItemsViewTransactionContext(ConnectedToRedux),
  ),
);
