import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { ExportSettingsActionTypes } from '../actions/types/export-settings';
import { DrawingsState } from '../interfaces/drawings-state';
import {
  AnnotationOptions,
  MeasurementOptions,
  PageOptions,
} from '../layout-components/top-control-group/drawings-export';

export const DrawingsExportSettingsReducerMethods: ReducerMethods<DrawingsState> = {
  [ExportSettingsActionTypes.SET_EXPORT_PAGES]: (s, payload: PageOptions) => {
    return new MonoliteHelper(s)
      .set(_ => _.exportSettings.exportPages, payload)
      .get();
  },
  [ExportSettingsActionTypes.SET_EXPORT_MEASUREMENTS]: (s, payload: MeasurementOptions) => {
    return new MonoliteHelper(s)
      .set(_ => _.exportSettings.exportMeasurements, payload)
      .get();
  },
  [ExportSettingsActionTypes.SET_EXPORT_ANNOTATIONS]: (s, payload: AnnotationOptions[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.exportSettings.exportAnnotations, payload)
      .get();
  },
  [ExportSettingsActionTypes.TOGGLE_DRAWING_STYLES_ENABLED]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.exportSettings.drawingStylesEnabled, _ => !_)
      .get();
  },
  [ExportSettingsActionTypes.TOGGLE_SEGMENT_LENGTH]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.exportSettings.segmentLength, _ => !_)
      .get();
  },
  [ExportSettingsActionTypes.TOGGLE_SEGMENT_LENGTH_ON_LAYER]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.exportSettings.segmentLengthOnOtherLayer, _ => !_)
      .get();
  },
  [ExportSettingsActionTypes.SET_LOADING_EXPORT]: (s, value: boolean) => {
    return new MonoliteHelper(s)
      .set(_ => _.exportSettings.loadingExport, _ => value)
      .get();
  },
};
