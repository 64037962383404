import styled from 'styled-components';

import { TRANSITION_POINT } from '../../../../../constants';


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 370px;
  height: 490px;
  background-color: ${p => p.theme.color.backgroundRush};
  box-shadow: 0px 15px 40px #0000001F;
  border-radius: 40px;

  &, * {
    box-sizing: border-box;
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    max-width: calc(100% - 20px);
    height: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  height: 100%;

  &, * {
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  
`;

const InfoContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
`;

const AddButtonContainer = styled.div<any>`
  padding-bottom: 20px;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    button {
      width: 100%;
    }
  }
`;

const ExitButtonContainer = styled.div<any>`
  display: flex;
  justify-content: center;

  > div {
    > p {
      font-size: 14px;
    }
  }
`;

export const Styled = {
  Wrapper,
  Container,
  Header,
  InfoContainer,
  AddButtonContainer,
  ExitButtonContainer,
};
