import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

interface Props {
  className?: string;
}

export const KreoIconCollapseExpand4d: React.FC<Props> = (props: Props) => {
  return (
    <svg
      className={props.className}
      width='8px'
      height='3px'
      viewBox='0 0 8 3'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='UI-Elements' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='4D_UI-Elements'
          transform='translate(-666.000000, -1677.000000)'
          fill={KreoColors.f3}
          fillRule='nonzero'
        >
          <g id='Group-21' transform='translate(0.000000, 1461.000000)'>
            <g id='Group-6' transform='translate(80.000000, 106.000000)'>
              <g id='Group-24-Copy' transform='translate(410.000000, 44.000000)'>
                <g id='Group-4' transform='translate(0.000000, 63.000000)'>
                  <g id='4D_8x8' transform='translate(176.000000, 1.000000)'>
                    <path
                      // eslint-disable-next-line max-len
                      d='M1.2773501,4.91602515 C1.04758575,5.06920138 0.737151082,5.00711444 0.583974853,4.7773501 C0.430798623,4.54758575 0.492885558,4.23715108 0.722649902,4.08397485 L3.7226499,2.08397485 C3.89059961,1.97200838 4.10940039,1.97200838 4.2773501,2.08397485 L7.2773501,4.08397485 C7.50711444,4.23715108 7.56920138,4.54758575 7.41602515,4.7773501 C7.26284892,5.00711444 6.95241425,5.06920138 6.7226499,4.91602515 L4,3.10092521 L1.2773501,4.91602515 Z'
                      id='Path-2'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
