
import React, { useCallback } from 'react';
import { MagicSearchActions } from 'common/components/drawings/actions/creators/magic-search';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useDrawModeApi, useShowCalibrationDialogIfNeed } from 'common/components/drawings/hooks';
import { useActionDispatch } from 'common/hooks';
import { QUERIES } from '../queries';
import { QueryLine } from './query-line';
import { Styled } from './styled';

interface Props {
  onRun: () => void;
}


const QueryLineWithOpenSearchComponent: React.FC<Props> = ({ onRun }) => {
  const { setDrawMode } = useDrawModeApi();
  const openScaleIfNeed = useShowCalibrationDialogIfNeed();
  const setQuery = useActionDispatch(MagicSearchActions.setQuery);
  const runQuery = useCallback(
    (newQuery: string) => {
      openScaleIfNeed(() => setDrawMode(DrawingsDrawMode.AutoMeasure2, {
        afterSave: () => {
          setQuery(newQuery);
          onRun();
        },
      }));
    },
    [setQuery, setDrawMode, onRun, openScaleIfNeed],
  );

  return (
    <Styled.LineForMenu>
      <QueryLine
        queries={QUERIES}
        runQuery={runQuery}
      />
    </Styled.LineForMenu>
  );
};

export const QueryLineWithOpenSearch = React.memo(QueryLineWithOpenSearchComponent);
