import { createSlice } from '@reduxjs/toolkit';
import { ActionWith } from 'common/interfaces/action-with';

export enum ConnectionStatus {
  connect,
  disconnect,
}

export interface MultiViewState {
  connectionStatus: ConnectionStatus;
}

const initialSate: MultiViewState = {
  connectionStatus: null,
};

const multiViewSlice = createSlice({
  name: '@multiView',
  initialState: initialSate,
  reducers: {
    setConnection: (s, { payload }: ActionWith<ConnectionStatus>) => {
      if (payload === ConnectionStatus.disconnect && s.connectionStatus === null) {
        return;
      }
      s.connectionStatus = payload;
    },
  },
});

export const multiViewReducerMethod = multiViewSlice.reducer;
export const multiViewActions = multiViewSlice.actions;
export const multiViewPrefix = multiViewSlice.name;
