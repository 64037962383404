const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const fullMonthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];


const FullDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const QUARTS = [1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 4, 4];
const startmonths = [undefined, 0, 3, 6, 9];
const endmonths = [undefined, 3, 6, 9, 12];


export const getFullMonthName = (date: any): any => fullMonthNames[date.getMonth()];
export const getFullDayName = (date: any): any => FullDayNames[date.getDay()]
export const getStartMonth = (quart: number): any => startmonths[quart];
export const getEndMonth = (quart: number): any => endmonths[quart];
export const getQuart = (month: any): any => QUARTS[month];
export const getMonth = (date: any): any => `${monthNames[date.getMonth()]}`
export const getDay = (date: any): any => days[date.getDay()];
export const getDayMonth = (date: any): any => `${date.getDate()} ${getMonth(date)}`
