import { DrawingsSegment } from 'common/components/drawings/interfaces';
import { PaperIntersectionUtils, PaperPointUtils } from 'common/components/drawings/utils/paper-utils';
import { Intersection } from '../../interfaces';


export function findContourKnifeIntersection(
  contourSegments: Array<DrawingsSegment<paper.Point>>,
  knifePoints: DrawingsSegment<paper.Point>,
  knifeSegmentId: string,
): [Intersection[], paper.Point[]] {
  let intersections = new Array<Intersection>();

  const addIntersectionPoint = (index: number, point: paper.Point): void => {
    intersections.push({ index, point, knifePoints, knifeSegmentId });
  };

  for (let j = 0; j < contourSegments.length; j++) {
    const [s, e] = contourSegments[j];
    const intersectionPoint = PaperIntersectionUtils.getLinesIntersection(knifePoints[0], knifePoints[1], s, e);

    if (intersectionPoint) {
      if (intersections.length > 0 && PaperPointUtils.arePointsEqual(s, intersectionPoint)) {
        const prevIntersection = intersections[j === contourSegments.length - 1 ? 0 : intersections.length - 1];
        if (PaperPointUtils.arePointsEqual(prevIntersection.point, contourSegments[j - 1][0])) {
          intersections.pop();
        } else {
          addIntersectionPoint(j, intersectionPoint);
        }
      } else {
        addIntersectionPoint(j, intersectionPoint);
      }
    }
  }
  intersections = PaperPointUtils.sortEntityWithPointByDestination(knifePoints[0], intersections, i => i.point);
  return [intersections, intersections.map(i => i.point)];
}
