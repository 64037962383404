import { calculatePiaAssignment } from '2d/helpers';
import { PrecompiledFormulaApi } from '2d/helpers/calculate-pia-assignment/interfaces';
import {
  AssignedPia,
  AssignedPiaItem,
  AssignedPiaProperty,
  PiaCalculated,
  PiaCalculatedPropertyValue,
} from '2d/interfaces';
import { DrawingsInstanceMeasure } from 'common/components/drawings';
import { mathUtils } from 'common/utils/math-utils';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { PropertiesTypeGuards } from 'unit-2d-database/helpers';
import { formatData } from 'unit-2d-database/helpers/format-data-getter';
import { Property } from 'unit-2d-database/interfaces';
import { wrapZeroValue } from '../../../constants';
import { BreakdownPropertyExtender } from '../../items-helper/utils';
import { AssignInfo } from '../interfaces';
import { setBreakDownAssignData } from './set-break-down-assign-data';

type Data = {
  assignId: string,
  withUnit: boolean,
} | {
  [key: string]: string | number,
};

const unitPrefix = 'unit_';

const getPropertyInfo = (
  calculatedPia: PiaCalculated,
  assemblyName: string,
  itemName: string,
  propertyName: string,
): PiaCalculatedPropertyValue => {
  const pia = calculatedPia;
  const items = assemblyName ? pia?.assemblies[assemblyName] : pia?.items;
  return items?.[itemName]?.[propertyName];
};

const isBreakdownProperty = (p: AssignedPiaProperty): boolean => p.value?.type && PropertiesTypeGuards.isBreakdown(p);

const getData = (
  calculatedPia: PiaCalculated,
  item: AssignedPiaItem,
  originProperties: Property[],
  breakdownExtender: BreakdownPropertyExtender,
  assemblyName: string,
  withUnit: boolean,
): Data => {
  const propertyValue = {
    assignId: `${item.name}_${assemblyName}`,
    assemblyName,
    itemName: item.name,
    withUnit,
  };

  for (const p of item.properties) {
    if (isBreakdownProperty(p)) {
      setBreakDownAssignData(propertyValue, originProperties, p, breakdownExtender);
      continue;
    }

    const propertyInfo = getPropertyInfo(calculatedPia, assemblyName, item.name, p.name);

    if (!propertyInfo) {
      propertyValue[p.name] = '';
      propertyValue[`${unitPrefix}${p.name}`] = '';
      continue;
    }

    propertyValue[p.name] = wrapZeroValue(propertyInfo.value);
    const format = propertyInfo.format;
    const formatUnit = formatData.getUnit(format);

    if (UnitUtil.isWithoutUnit(formatUnit as UnitTypes)) {
      propertyValue[`${unitPrefix}${p.name}`] = '';
      continue;
    }

    const roundValue = propertyValue[p.name];
    propertyValue[p.name] = roundValue && typeof roundValue === 'number'
      ? mathUtils.round(roundValue, 2)
      : roundValue;
    propertyValue[`${unitPrefix}${p.name}`] = formatUnit;
  }

  return propertyValue;
};

export const getAssignRowData = (
  formulaCache: PrecompiledFormulaApi,
  assign: AssignedPia,
  elementMeasure: DrawingsInstanceMeasure,
  assignInfo: AssignInfo,
  breakdownPropertyExtender: BreakdownPropertyExtender,
  pageName: string,
): Data[] => {
  const { isImperial, originProperties, withUnit } = assignInfo;
  const calculatedValue = calculatePiaAssignment(
    assign,
    elementMeasure,
    isImperial,
    originProperties,
    pageName,
    formulaCache,
  );
  const dataList = [];
  for (const assembly of assign.assemblies) {
    for (const item of assembly.items) {
      const data = getData(
        calculatedValue,
        item,
        originProperties,
        breakdownPropertyExtender,
        assembly.name,
        withUnit,
      );
      dataList.push(data);
    }
  }

  for (const item of assign.items) {
    const data = getData(
      calculatedValue,
      item,
      originProperties,
      breakdownPropertyExtender,
      '',
      withUnit,
    );
    dataList.push(data);
  }

  return dataList;
};
