import * as React from 'react';

import { AbilityContext } from './ability-context';
import { FeatureFlagService } from './feature-flag-service';
import { UserAbility } from './user-ability';

export interface AbilityAwareProps {
  ability: UserAbility;
  featureFlagService: FeatureFlagService;
}

export function withAbilityContext<P extends AbilityAwareProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof AbilityAwareProps>>> {
  return class AbilityAwareComponent extends React.Component<Pick<P, Exclude<keyof P, keyof AbilityAwareProps>>> {
    public render(): React.ReactNode {
      return (
        <AbilityContext.Consumer>
          {
            ({ ability, featureFlagService }) => {
              return <Component {...this.props as P} ability={ability} featureFlagService={featureFlagService}/>;
            }
          }
        </AbilityContext.Consumer>
      );
    }
  };
}
