import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  title: string;
  Icon: React.ComponentType;
}

export const Header = React.memo<Props>(({ title, Icon }) => {
  return (
    <Styled.Header>
      <Icon />
      <Text fontSize={14}>{title}</Text>
    </Styled.Header>
  );
});
