import React from 'react';

import { Styled } from './styled';

interface Props {
  checked: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const RadionButtonComponent: React.FC<Props> = ({ checked, onClick, disabled }) => {
  return (
    <Styled.Container onClick={onClick} disabled={disabled}>
      <Styled.RadioButtonStyle checked={checked} />
    </Styled.Container>
  );
};


export const RadioButton = React.memo(RadionButtonComponent);
