import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { Location } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { Prompt, RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './page.scss';

import { Controls3D } from 'common/components/controls-3d';
import { KreoToolbar } from 'common/components/kreo-toolbar';
import { KreoColors } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { Engine } from '../../../../components/engine';
import { KreoEngine } from '../../../../components/engine/KreoEngine';
import { SplitterLayout } from '../../../../components/splitter-layout';
import { PlanProjectRouteParams, Qto3dProjectRouteParams } from '../../../../routes/app-routes-params';
import { PersistedStorageActions } from '../../../persisted-storage/actions/creators';
import { ProjectLayout } from '../../../project-dashbord';
import { ModelManagementActions } from '../../actions/creators/model-management';
import { ModelManagementFilesBrowser } from '../../components/model-management-files-browser';
import { PropertiesDataContextProvider } from '../../components/properties-data-context-provider';
import { PropertiesPopupApi } from '../../components/properties-popup-button';
import { ModelManagementDialogs } from '../../constants/model-management-dialogs';
import { KnownViewModel } from '../../enums/known-view-model';
import { ViewModelStatus } from '../../enums/view-model-status';

interface StateProps {
  isReady: boolean;
  isAutoFocusEngine: boolean;
  hasUnappliedChanges: boolean;
}

interface DispatchProps {
  selectFromEngine: (id: number[]) => void;
  loadData: (id: number) => void;
  dropState: () => void;
  toggleAutoFocusEngine: () => void;
  openLeaveConfirmationDialog: () => void;
}

interface Props extends StateProps,
  DispatchProps,
  RouteComponentProps<PlanProjectRouteParams | Qto3dProjectRouteParams> {}

interface PageState {
  pagePanesConfigs: Array<{ size: number, minSize: number }>;
  isToolbarHide: boolean;
  isFullScreen: boolean;
  isGhostEnabled: boolean;
  isClipBoxEnabled: boolean;
  isRulerEnabled: boolean;
  isIsometryEnabled: boolean;
  color: string;
  engineContainerRef: HTMLDivElement;
  isAnyInvisible: boolean;
  isAnySelected: boolean;
  targetLocation: Location;
}

const minPagePanesSize = [555, 530];

class ModelManagementPageComponent extends React.PureComponent<Props, PageState> {
  private engine: KreoEngine;
  private propertiesPopupApi: PropertiesPopupApi;
  private skipEngineSelect: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      pagePanesConfigs: minPagePanesSize.map(minSize => ({ size: 0, minSize })),
      isToolbarHide: false,
      isFullScreen: false,
      isGhostEnabled: true,
      isClipBoxEnabled: false,
      isRulerEnabled: false,
      isIsometryEnabled: false,
      color: KreoColors.blue4,
      engineContainerRef: null,
      isAnyInvisible: false,
      isAnySelected: false,
      targetLocation: null,
    };
  }


  public componentDidMount(): void {
    if (this.props.isReady) {
      this.props.loadData(Number(this.props.match.params.projectId));
    }
  }

  public render(): React.ReactNode {
    return (
      <ProjectLayout waitingForAdditionalInfo={!this.props.isReady} projectId={this.props.match.params.projectId}>
        <Prompt
          when={this.props.hasUnappliedChanges}
          message={this.handleLeave}
        />
        <KreoConfirmationDialog
          name={ModelManagementDialogs.CLOSE_PAGE}
          onYes={this.onLeave}
          onNo={this.onCancelLeaving}
          yesText='Leave'
          noText='Cancel'
          title='Leave This Page'
        >
          You have unsaved changes. Are you sure you want to leave this page?
        </KreoConfirmationDialog>
        <PropertiesDataContextProvider >
          <div className='model-management-page' ref={this.savePageRef}>
            <SplitterLayout
              paneConfigs={this.state.pagePanesConfigs}
              primaryPaneCollapseToggleEnable={true}
            >
              <div className='model-management-page__left-panel'>
                <ModelManagementFilesBrowser
                  selectEngineIds={this.selectEngineIds}
                />
              </div>
              <div
                className={
                  classNames(
                    'model-management-page__engine-panel',
                    {
                      'model-management-page__engine-panel--full-screen': this.state.isFullScreen,
                    },
                  )
                }
              >
                <div
                  className={
                    classNames(
                      'model-management-page__engine-panel-inner',
                      {
                        'model-management-page__engine-panel-inner--toolbar-hide': this.state.isToolbarHide,
                      },
                    )}
                >
                  <Engine
                    projectId={Number(this.props.match.params.projectId)}
                    textures='/static/textures/'
                    onHandleClick={this.handleEngineClick}
                    onChangeVisibility={this.onChangeVisibility}
                    onToggleClipBox={this.onEngineToggleClipBox}
                    onToggleRuler={this.onEngineToggleRuler}
                    toggleCameraParallel={this.onEngineToggleCameraParallel}
                    sendEngineApi={this.saveEngineRef}
                    saveContainerRef={this.saveEngineContainerRef}
                  />
                  <KreoToolbar
                    isToolbarHide={this.state.isToolbarHide}
                    onHideToggle={this.onHideToolbarToggle}
                    className='model-management-page__engine-toolbar'

                  >
                    <Controls3D
                      ghostEnabled={this.state.isGhostEnabled}
                      isometryEnabled={this.state.isIsometryEnabled}
                      clipBoxEnabled={this.state.isClipBoxEnabled}
                      rulerEnabled={this.state.isRulerEnabled}
                      isAutoFocus={this.props.isAutoFocusEngine}
                      isSelectedElement={this.state.isAnySelected}
                      isFullScreen={this.state.isFullScreen}
                      savePropertiesPopupApi={this.savePropertiesPopupApi}
                      engineLayoutContainer={this.state.engineContainerRef}
                      color={this.state.color}
                      onChangeColor={this.onChangeColor}
                      onDefaultColor={this.onDefaultColor}
                      onAssignColor={this.onAssignColor}
                      onHome={this.onHome}
                      onFocus={this.onFocus}
                      onIsolate={this.onIsolate}
                      onHide={this.onHide}
                      isAnyInvisible={this.state.isAnyInvisible}
                      onShowHideElements={this.onShowHideElements}
                      toggleGhost={this.toggleGhost}
                      toggleClipBox={this.toggleClipBox}
                      toggleIsometry={this.toggleIsometry}
                      toggleAutoFocus={this.props.toggleAutoFocusEngine}
                      toggleRuler={this.toggleRuler}
                      toggleFullScreen={this.toggleFullScreen}
                    />
                  </KreoToolbar>
                </div>
              </div>
            </SplitterLayout>
          </div>
        </PropertiesDataContextProvider>
      </ProjectLayout>
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.isReady && !prevProps.isReady) {
      this.props.loadData(Number(this.props.match.params.projectId));
    }
  }

  public componentWillUnmount(): void {
    this.props.dropState();
  }


  @autobind
  private onCancelLeaving(): void {
    this.setState({ targetLocation: null });
  }

  @autobind
  private onLeave(): void {
    this.props.history.push(this.state.targetLocation.pathname);
  }

  @autobind
  private handleLeave(targetLocation: Location): boolean | string {
    if (this.state.targetLocation) {
      return true;
    }
    this.setState({ targetLocation }, () => {
      this.props.openLeaveConfirmationDialog();
    });
    return false;
  }

  @autobind
  private selectEngineIds(ids: number[]): void {
    this.skipEngineSelect = true;
    this.engine.setSelected(ids);
  }

  @autobind
  private handleEngineClick(ids: number[]): void {
    if (this.propertiesPopupApi) {
      this.propertiesPopupApi.showPropertiesForElements(ids, true);
    }
    this.setState({ isAnySelected: !!ids.length });
    if (this.skipEngineSelect) {
      this.skipEngineSelect = false;
      return;
    }
    this.props.selectFromEngine(ids);
  }

  @autobind
  private onChangeVisibility(): void {
    this.setState({ isAnyInvisible: this.engine.isAnyInvisible() });
  }

  @autobind
  private onChangeColor(color: string): void {
    this.setState({ color });
  }

  @autobind
  private onDefaultColor(): void {
    const ids = this.engine.getSelected();
    this.engine.setColorTint(null, ids);
  }

  @autobind
  private onAssignColor(): void {
    const ids = this.engine.getSelected();
    this.engine.setColorTint(this.state.color, ids);
  }

  @autobind
  private onShowHideElements(): void {
    this.engine.setRenderMode('standard');
    this.engine.toggleVisibility(true);
  }

  @autobind
  private toggleIsometry(): void {
    this.engine.toggleParallelProjection(!this.state.isIsometryEnabled);
  }

  @autobind
  private onFocus(): void {
    const ids = this.engine.getSelected();
    this.engine.focusCamera(ids);
  }

  @autobind
  private onHome(): void {
    this.engine.cameraToHome();
  }

  @autobind
  private toggleGhost(): void {
    if (this.engine) {
      const { isGhostEnabled, isClipBoxEnabled } = this.state;
      if (isClipBoxEnabled) {
        this.engine.toggleClipbox(true, !isGhostEnabled ? 'ghost' : 'none');
      }
      this.engine.showInvisibleAsGhost(!isGhostEnabled);
      this.setState({ isGhostEnabled: !isGhostEnabled });
    }
  }

  @autobind
  private toggleFullScreen(): void {
    this.setState((s) => ({ isFullScreen: !s.isFullScreen }));
  }

  @autobind
  private onHideToolbarToggle(): void {
    this.setState((s) => ({ isToolbarHide: !s.isToolbarHide }));
  }

  @autobind
  private onEngineToggleClipBox(isClipBoxEnabled: boolean): void {
    this.setState({ isClipBoxEnabled });
  }

  @autobind
  private toggleClipBox(): void {
    if (this.engine) {
      const { isGhostEnabled, isClipBoxEnabled } = this.state;
      this.engine.toggleClipbox(!isClipBoxEnabled, isGhostEnabled ? 'ghost' : 'none');
    }
  }

  @autobind
  private saveEngineRef(engine: KreoEngine): void {
    this.engine = engine;
    this.engine.showInvisibleAsGhost(this.state.isGhostEnabled);
  }

  @autobind
  private onHide(): void {
    const ids = this.engine.getSelected();
    this.engine.toggleVisibility(false, ids);
  }

  @autobind
  private onIsolate(): void {
    const ids = this.engine.getSelected();
    this.engine.toggleVisibility(true, ids, false);
  }

  @autobind
  private toggleRuler(): void {
    if (this.engine) {
      const { isRulerEnabled } = this.state;
      this.engine.toggleRuler(!isRulerEnabled);
    }
  }

  @autobind
  private onEngineToggleRuler(enabledRuler: boolean): void {
    this.setState({ isRulerEnabled: enabledRuler });
  }

  @autobind
  private onEngineToggleCameraParallel(isIsometryEnabled: boolean): void {
    this.setState({ isIsometryEnabled });
  }

  @autobind
  private savePropertiesPopupApi(api: PropertiesPopupApi): void {
    this.propertiesPopupApi = api;
  }

  @autobind
  private saveEngineContainerRef(ref: HTMLDivElement): void {
    this.setState({ engineContainerRef: ref });
  }


  @autobind
  private savePageRef(ref: HTMLDivElement): void {
    if (ref === null) {
      return;
    }
    const pageRect = ref.getBoundingClientRect();
    const pagePanesConfigs = [
      { size: pageRect.width * 0.4, minSize: minPagePanesSize[0] },
      { size: pageRect.width * 0.6, minSize: minPagePanesSize[1] },
    ];
    this.setState({ pagePanesConfigs });
  }
}


function mapStateToProps(state: State): StateProps {
  const vmStatuses = state.projects.currentProject.viewModelsStatuses;
  return {
    isReady: vmStatuses && vmStatuses[KnownViewModel.BimProcessing] === ViewModelStatus.Ready,
    isAutoFocusEngine: state.persistedStorage.isAutoFocusEngine,
    hasUnappliedChanges: !!state.modelManagement.modelsForRemove.length
      || !!state.modelManagement.temporaryModels.length,
  };
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    loadData: id => dispatch(ModelManagementActions.loadData(id)),
    dropState: () => dispatch(ModelManagementActions.dropPageState()),
    toggleAutoFocusEngine: () => dispatch(PersistedStorageActions.toggleEngineAutoFocus()),
    selectFromEngine: ids => dispatch(ModelManagementActions.selectFromEngine(ids)),
    openLeaveConfirmationDialog: () => dispatch(KreoDialogActions.openDialog(ModelManagementDialogs.CLOSE_PAGE)),
  };
}

export const ModelManagementPage = connect(mapStateToProps, mapDispatchToProps)(ModelManagementPageComponent);
