import * as t from 'io-ts';
import { RevisionStateC } from './revision-state';

export const RevisionStateModelC = t.strict(
  {
    id: t.number,
    name: t.string,
    state: RevisionStateC,
  },
  'RevisionStateModel');

export type RevisionStateModel = t.TypeOf<typeof RevisionStateModelC>;
