import React from 'react';
import { DndComponentClass, DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';

export interface DNDSourceProps {
  connectDragSource: (element: JSX.Element) => JSX.Element;
  canDrag: boolean;
}


const collectDrag: DragSourceCollector<any, any> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    canDrag: monitor.canDrag(),
  };
};


export function withDragSourceWrapper<P extends DNDSourceProps>(
  Component: React.ComponentType<P>,
  specDrag: DragSourceSpec<P>,
  type: string,
): DndComponentClass<React.ComponentType<any>, Pick<P, Exclude<keyof P, keyof DNDSourceProps>>> {
  class DNDSourceComponent extends React.Component<P> {
    public render(): React.ReactNode {
      return this.props.connectDragSource(
        <div
          style={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
        >
          <Component {...this.props}/>
        </div>,
      );
    }
  }

  const dragConnector = DragSource<P>(type, specDrag, collectDrag);
  return dragConnector(DNDSourceComponent as any) as any;
}
