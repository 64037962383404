import { IconButton, Icons } from '@kreo/kreo-ui-components';
import { useOpenMenuWithPosition } from '@kreo/kreo-ui-components/utils';
import React from 'react';

import { FiltersData } from 'unit-2d-comments/interfaces';
import { FiltersMenu } from './filters-menu';

interface Props {
  filterValues: FiltersData;
}

const FilterComponent: React.FC<Props> = ({ filterValues }) => {
  const { isOpen, position, onClose, onOpen } = useOpenMenuWithPosition();

  return (
    <>
      <IconButton
        Icon={Icons.Filters2D}
        onClick={onOpen}
        width={20}
        height={20}
      />
      {
        isOpen ? (
          <FiltersMenu
            x={position.x}
            y={position.y}
            onClose={onClose}
            filterValues={filterValues}
          />
        ) : null
      }
    </>
  );
};

export const Filters = React.memo(FilterComponent);
