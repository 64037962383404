import React from 'react';

import { UndoRedoContext } from './undo-redo-context';
import { UndoRedoContextApiProps } from './undo-redo-context-props';

export function withUndoRedoApiProps<P extends UndoRedoContextApiProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof UndoRedoContextApiProps>>> {
  return class UndoRedoAwareComponents
    extends React.Component<P> {
    public render(): React.ReactNode {
      return (
        <UndoRedoContext.Consumer>
          { context => (
            <Component
              {...this.props}
              addUndoRedo={context.addUndoRedo}
              undoRedoChangeActiveStatus={context.undoRedoChangeActiveStatus}
              cleanUndoRedo={context.cleanUndoRedo}
            />)}
        </UndoRedoContext.Consumer>
      );
    }
  };
}
