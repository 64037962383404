import * as React from 'react';
import { DrawingMarkShapes, DrawingStrokeStyles } from '../../constants/drawing-styles';
import { NewInstanceSettings } from '../../interfaces';

export interface DrawingsNewInstanceSettingsContextProps extends NewInstanceSettings {
  drawingNewInstanceStrokeWidth: number;
}

export const DrawingsNewInstanceSettingsContext = React.createContext<DrawingsNewInstanceSettingsContextProps>(null);

export interface NewInstanceSettingsStateSetters {
  setNewDrawingColor: (color: string) => void;
  setStrokeStyle: (style: DrawingStrokeStyles) => void;
  setShape: (shape: DrawingMarkShapes) => void;
  setStrokeWidth: (strokeWidth: number) => void;
  setNewDrawingName: (name: string) => void;
}

export const NewInstanceSettingsSetterContext = React.createContext<NewInstanceSettingsStateSetters>(null);
