import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { StringDictionary } from 'common/interfaces/dictionary';
import { State } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { mathUtils } from 'common/utils/math-utils';
import { LineData } from '../../../interfaces/gantt-chart';
import {
  ChartDataProvider,
  GanttLineCoordinatesWithSLack,
  GroupType,
  SlackType,
} from '../../../utils/gantt-chart';
import {
  InternalRangesPool,
} from '../../four-d-visualisation/components/new-gantt-chart/components/chart-body/internal-ranges-pool';
import { colorGenerator } from '../utils/color-generator';
import { ganttChartContants } from '../utils/constants';
import { ChartLine } from './chart-line';
import { EmptyChartLine } from './empty-chart-line';

interface OwnProps {
  dataProvider: ChartDataProvider;
  timeframeStartDay: number;
  timeframeDuration: number;
  width: number;
  height: number;
  slacksType: SlackType;
  showCriticalPath: boolean;
}

interface StateProps {
  workPackages: LineData[];
  selectionData: StringDictionary<boolean>;
}

interface Props extends OwnProps, StateProps {}

interface ChartBodyState {
  offset: number;
}

class ChartBodyComponent extends React.Component<Props, ChartBodyState> {
  private readonly lineHeight: number = 40;
  private visibleLinesIds: string[] = [];
  private outerRangesRefs: Map<string, HTMLDivElement> = new Map<string, HTMLDivElement>();
  private coordsLookup: StringDictionary<GanttLineCoordinatesWithSLack> = {};
  private linesToRender: LineData[] = [];
  private rangesPool: InternalRangesPool = new InternalRangesPool(
    this.getOuterRangeRef,
    'internal-range',
  );

  constructor(props: Props) {
    super(props);
    this.state = { offset: 0 };
  }

  public render(): React.ReactNode {
    this.props.dataProvider.setGanttPixelsRange(
      mathUtils.clamp(this.props.width - ganttChartContants.leftPanelWidth, 1, Number.MAX_SAFE_INTEGER),
    );

    const visibleLinesCount = Math.ceil(this.props.height / this.lineHeight);
    const topUnRenderedLinesCount = Math.floor(this.state.offset / this.lineHeight);
    const topSpacerHeight = topUnRenderedLinesCount * this.lineHeight;

    this.coordsLookup = {};

    const linesData: LineData[] = [];
    for (const workPackage of this.props.workPackages) {
      if (!this.props.selectionData[workPackage.lineId]) {
        continue;
      }
      this.coordsLookup[workPackage.lineId] = this.props.dataProvider.calculateGanttLineCoordinatesWithSlacks(
        GroupType.WorkPackage,
        this.props.slacksType,
        workPackage.entityId,
      );

      linesData.push(workPackage);
      if (workPackage.isExpanded) {
        for (const activityGroup of workPackage.children) {
          this.coordsLookup[
            activityGroup.lineId
          ] = this.props.dataProvider.calculateGanttLineCoordinatesWithSlacks(
            GroupType.ActivityGroup,
            this.props.slacksType,
            activityGroup.entityId,
          );
          activityGroup.parentLinesIds = [workPackage.entityId];
          linesData.push(activityGroup);
          if (activityGroup.isExpanded && activityGroup.children) {
            for (const activity of activityGroup.children) {
              this.coordsLookup[
                activity.lineId
              ] = this.props.dataProvider.calculateGanttLineCoordinatesWithSlacks(
                GroupType.Activity,
                this.props.slacksType,
                activity.entityId,
              );
              activity.parentLinesIds = [workPackage.entityId, activityGroup.entityId];
              linesData.push(activity);
            }
          }
        }
      }
    }

    this.linesToRender = linesData.slice(
      topUnRenderedLinesCount,
      topUnRenderedLinesCount + visibleLinesCount,
    );
    this.visibleLinesIds = this.linesToRender.map(l => l.lineId);

    return (
      <div className='chart-body'>
        <KreoScrollbars onScroll={this.onScroll} relative={true}>
          <div
            className='chart-body__content'
            style={{ minHeight: linesData.length * this.lineHeight }}
          >
            <div className='chart-body__spacer' style={{ height: topSpacerHeight }} />
            {this.linesToRender && this.linesToRender.length > 0 ? (
              this.linesToRender.map(lineData => {
                return this.renderLine(
                  lineData,
                  this.coordsLookup[lineData.lineId],
                );
              })
            ) : (
              <div className='chart-body__message'>
                No activities have been scheduled for chosen timeframe
              </div>
            )}
          </div>
        </KreoScrollbars>
      </div>
    );
  }

  public componentDidMount(): void {
    this.renderInternalRanges(false);
  }

  public componentDidUpdate(prevProps: Props): void {
    const scaleChanged =
      prevProps.width !== this.props.width ||
      prevProps.timeframeDuration !== this.props.timeframeDuration
      || prevProps.showCriticalPath !== this.props.showCriticalPath;
    this.renderInternalRanges(scaleChanged);
  }

  private renderLine(
    lineData: LineData,
    horizontalCoords: GanttLineCoordinatesWithSLack,
  ): React.ReactNode {
    const nestingLevel = this.getNestingLevel(lineData);
    if (lineData.isUndefined || !horizontalCoords) {
      return (
        <EmptyChartLine
          key={lineData.lineId}
          nestingLevel={nestingLevel}
          data={lineData}
        />
      );
    }
    return (
      <ChartLine
        showCriticalPath={this.props.showCriticalPath}
        slackCoordinates={horizontalCoords.slackCoordinates}
        key={lineData.lineId}
        left={horizontalCoords.ganttCoordinates.start}
        nestingLevel={nestingLevel}
        data={lineData}
        width={horizontalCoords.ganttCoordinates.width}
        saveOuterRangeRef={this.saveOuterRangeRef}
      />
    );
  }


  private renderInternalRanges(scaleChanged: boolean): void {
    // recycle all elements
    this.rangesPool.markAllAsAvailable();

    // prepare request to the pool
    const request = new Map<string, number[]>();
    for (const line of this.linesToRender) {
      if (line.isUndefined || !this.coordsLookup[line.lineId]) {
        continue;
      }
      const coords = this.coordsLookup[line.lineId].ganttCoordinates;
      const neededRangesCount = coords.internalLinesSizes.length / 2;
      const neededRanges: number[] = new Array<number>(neededRangesCount);

      for (let i = 0; i < neededRangesCount; ++i) {
        neededRanges[i] = i + coords.internalLinesStartIndex / 2;
      }

      request.set(line.lineId, neededRanges);
    }

    // request elements
    const linesElements = this.rangesPool.getNodes(request, scaleChanged);

    // position and redraw elements
    for (const line of this.linesToRender) {
      if (line.isUndefined || !this.coordsLookup[line.lineId]) {
        continue;
      }
      const lineId = line.lineId;
      const ranges = linesElements.get(lineId);

      const coords = this.coordsLookup[lineId].ganttCoordinates;

      for (const range of ranges) {
        const indexInSizesArray = (range.indexInLine - coords.internalLinesStartIndex / 2) * 2;
        const rangeOffset = coords.internalLinesSizes[indexInSizesArray];
        const rangeWidth = coords.internalLinesSizes[indexInSizesArray + 1];

        if (range.needsRedraw) {
          range.element.style.transform = `translateX(${rangeOffset}px)`;
          range.element.style.width = `${rangeWidth}px`;
          range.element.style.background = colorGenerator.generateMainColor(
            line.type,
            line.isCritical && this.props.showCriticalPath,
          );
          range.element.id = `${lineId}--${range.indexInLine}`;
        }
      }
    }

    // remove unused elements
    this.rangesPool.detachAvailableElements();
  }

  private getNestingLevel(
    lineData: LineData,
  ): number {
    switch (lineData.type) {
      case GroupType.WorkPackage:
        return 0;
      case GroupType.ActivityGroup:
        return 1;
      case GroupType.Activity:
        return 2;
      default:
        throw new Error('Unexpected GanttLineType');
    }
  }

  @autobind
  private onScroll(e: React.UIEvent<HTMLDivElement>): void {
    this.setState(prevState => {
      const newOffset = e.currentTarget.scrollTop || 0;
      const oldOffset = prevState.offset;

      if (newOffset < 0 || oldOffset === newOffset) {
        return;
      }

      return {
        offset: newOffset,
      };
    });
  }

  @autobind
  private saveOuterRangeRef(lineId: string, ref: HTMLDivElement): void {
    this.outerRangesRefs.set(lineId, ref);
    this.rangesPool.markLineElementsAsDetached(lineId);
  }

  @autobind
  private getOuterRangeRef(lineId: string): HTMLDivElement {
    if (!this.visibleLinesIds.includes(lineId)) {
      return null;
    }

    return this.outerRangesRefs.get(lineId);
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    workPackages: state.fourDVisualisation.ganttData ? state.fourDVisualisation.ganttData.workPackages : [],
    selectionData: state.fourDVisualisation.selectedPackages,
  };
};

export const ChartBody = connect(mapStateToProps)(ChartBodyComponent);
