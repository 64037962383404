import * as React from 'react';

export const KreoIconKeyCombinePlus: React.FC = () => {
  return (
    <svg
      width='10px'
      height='10px'
      viewBox='0 0 10 10'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Hotkeys/plus'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g id='+' transform='translate(2.000000, 2.000000)' stroke='#FFFFFF'>
          <path d='M3,0.5 L3,5.5 L3,0.5 Z' id='Rectangle-2' />
          <path d='M5.5,3 L0.5,3 L5.5,3 Z' id='Rectangle-2' />
        </g>
      </g>
    </svg>
  );
};
