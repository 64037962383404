import * as t from 'io-ts';
import { ProjectTypeC } from 'common/constants/project-type';

export const AdminProjectVmC = t.strict(
  {
    id: t.number,
    name: t.string,
    isDeleted: t.boolean,
    type: ProjectTypeC,
  },
  'AdminProjectVm');

export type AdminProjectVm = t.TypeOf<typeof AdminProjectVmC>;
