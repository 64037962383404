import { Constants } from '@kreo/kreo-ui-components';
import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  transition: all 0.2s ease;
  align-items: center;
  background: ${p => p.theme.color.background};
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  line-height: 1.4;
  padding: 8px 45px 7px 10px;
`;


const Input = styled.input`
  border: 0;
  outline: 0;
  font-size: inherit;
  line-height: inherit;
  width: 100%;

  font-size: 12px;
  line-height: 16px;
  color: ${p => p.theme.color.mainFont};
  background-color: transparent;
  &::placeholder{
    color: ${p => p.theme.color.disabled};
  }
`;

const DefaultTag = css`
  background: ${p => p.theme.color.backgroundRush};
  color: ${p => p.theme.color.mainFont};
  svg {
    fill: ${Constants.Colors.GENERAL_COLORS.red};
  }
`;

const ErrorTag = css`
  background-color: ${Constants.Colors.GENERAL_COLORS.red};
  color: ${Constants.Colors.GENERAL_COLORS.white};

  svg {
    fill: ${Constants.Colors.GENERAL_COLORS.white};
  }
`;

const Tag = styled.span<{ hasProblem?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  background: ${p => p.theme.color.backgroundRush};
  color: ${p => p.theme.color.mainFont};
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  padding: 3px 5px;
  align-items: center;
  max-width: 100%;

  > span {
    height: 14px;
    max-width: calc(100% - 15px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }

  ${p => p.hasProblem ? ErrorTag : DefaultTag};
`;

const ApplyButton = styled.span`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: ${p => p.theme.color.backgroundRush};
  cursor: pointer;

  > svg {
    width: 10px;
    height: 10px;
    fill: ${p => p.theme.color.green};
  }
`;

export const Styled = {
  Container,
  Input,
  Tag,
  DefaultTag,
  ErrorTag,
  ApplyButton,
};
