import { call, put } from 'redux-saga/effects';

import { DrawingsNotificationProcessor } from 'common/components/drawings/notifications/processor';
import { Notification } from 'common/interfaces/realtime-messages';
import { CodecUtils } from 'common/utils/codec-utils';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { RevisionsNotificationsProcessor } from 'unit-cost-estimate/notifications/processors/revisions';
import { AppUrls } from '../../routes/app-urls';
import { TwoDNotificationsProcessor } from '../2d/notifications/processor';
import { AccountActions } from '../account/actions/creators';
import { ChangeCompanySubscriptionPayload } from '../account/actions/payloads';
import { CompanySubscriptionUsersChangedModelC } from '../account/interfaces/company-subscription-users-changed-model';
import { AccountNotificationsProcessor } from '../account/notifications/processor';
import { PeopleActions } from '../people/actions/actions';
import { ProjectsActions } from '../projects/actions/creators/common';
import { UpdateViewModelStatusPayload } from '../projects/actions/payloads/common';
import { ViewerNotificationDataProcessor } from '../projects/notifications/processors//viewer';
import { ProjectsNotificationsProcessor } from '../projects/notifications/processors/common';
import { ModelCheckNotificationsProcessor } from '../projects/notifications/processors/model-check';
import { ModelManagementNotificationsProcessor } from '../projects/notifications/processors/model-management';
import { QuantityTakeOffNotificationsProcessor } from '../projects/notifications/processors/quantity-take-off';
import { ScenariosNotificationsProcessor } from '../projects/notifications/processors/scenarios';
import { ValidationNotificationsProcessor } from '../projects/notifications/processors/validation';
import { SubscriptionActions } from '../subscription/actions/creators';
import { CompanyBillingInfoModel } from '../subscription/interfaces/company-billing-info-model';
import { NotificationActions } from './actions';
import { CommonNotificationProcessor } from './common-notification-processor';
import { MessageType } from './interfaces';
import { NotificationType } from './notification-type';

// used to debug notifications
const debug = false;


export function* processMessage(message: MessageType<Notification>): any {
  try {
    const notification = message.data;
    if (debug) {
      // eslint-disable-next-line no-console
      console.log('%cNOTIFICATON:', 'color:red;', notification);
    }
    if (notification) {
      if (notification.type in ProjectsNotificationsProcessor) {
        yield call(ProjectsNotificationsProcessor[notification.type], notification);
      }

      if (notification.type in ValidationNotificationsProcessor) {
        yield call(ValidationNotificationsProcessor[notification.type], notification);
      }

      if (notification.type in AccountNotificationsProcessor) {
        yield call(ValidationNotificationsProcessor[notification.type], notification);
      }

      if (notification.type in ViewerNotificationDataProcessor) {
        yield call(ViewerNotificationDataProcessor[notification.type], notification);
      }

      if (notification.type in ModelCheckNotificationsProcessor) {
        yield call(ModelCheckNotificationsProcessor[notification.type], notification);
      }

      if (notification.type in ScenariosNotificationsProcessor) {
        yield call(ScenariosNotificationsProcessor[notification.type], notification);
      }

      if (notification.type in QuantityTakeOffNotificationsProcessor) {
        yield call(QuantityTakeOffNotificationsProcessor[notification.type], notification);
      }

      if (notification.type in DrawingsNotificationProcessor) {
        yield call(DrawingsNotificationProcessor[notification.type], notification);
      }

      if (notification.type in ModelManagementNotificationsProcessor) {
        yield call(ModelManagementNotificationsProcessor[notification.type], notification);
      }

      if (notification.type in TwoDNotificationsProcessor) {
        yield call(TwoDNotificationsProcessor[notification.type], notification);
      }

      if (notification.type in RevisionsNotificationsProcessor) {
        yield call(RevisionsNotificationsProcessor[notification.type], notification);
      }

      switch (notification.type) {
        // database
        case NotificationType.DatabaseDuplicatePassed:
        case NotificationType.DatabaseImportPassed: {
          if (debug || !window.location.pathname.startsWith(AppUrls.plan.database.listing.url())) {
            yield CommonNotificationProcessor.addInfoAlert(notification);
          }
          break;
        }
        case NotificationType.DatabaseDuplicateFailed:
        case NotificationType.DatabaseImportFailed: {
          yield CommonNotificationProcessor.addErrorAlert(notification);
          break;
        }
        case NotificationType.DatabaseCreated:
        case NotificationType.DatabaseDeleted:
          yield put(NotificationActions.addNotification(notification));
          break;

        case NotificationType.CompanySubscriptionChanged: {
          const payload = notification.data as ChangeCompanySubscriptionPayload;
          yield put(AccountActions.changeCompanySubscription(payload));
          break;
        }
        case NotificationType.CompanyBillingInfoChanged: {
          const payload = notification.data as CompanyBillingInfoModel;
          yield put(SubscriptionActions.fetchOwnCompanyBillingInfoSucceeded(payload));
          break;
        }
        case NotificationType.CompanySubscriptionUsersChanged: {
          const payload = CodecUtils.decode(notification.data, CompanySubscriptionUsersChangedModelC);
          const { selectedCompanyId, selectedSubscriptionType } = yield selectWrapper(s => s.account);
          const { companyId, subscriptionType }  = payload;
          if (companyId === selectedCompanyId && subscriptionType === selectedSubscriptionType) {
            yield put(PeopleActions.updateCompanySubscriptionUsers(payload));
          }
          break;
        }
        case NotificationType.ViewModelChanged: {
          const payload: UpdateViewModelStatusPayload = notification.data;
          yield put(ProjectsActions.updateViewModelStatus(payload));
          break;
        }
        default:
      }
    }
  } catch (e) {
    console.error('Error while processing message:', message, e);
  }
}

