import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { DrawingsBounds, DrawingsShortInfo } from '../../interfaces';
import { PdfGeometry } from '../../interfaces/api-responses/pdf-geometry-response';
import { PreviewCanvasState, PreviewPanel } from '../search-preview';
import { WizzardPreviewItem } from './preview-item';
import { useResults } from './use-results';

const WizzardPreviewComponent: React.FC = () => {
  const currentDrawingInfo = useSelector<State, DrawingsShortInfo>(s => s.drawings.currentDrawingInfo);

  const renderItem = useCallback((
    item: PdfGeometry,
    canvasState: PreviewCanvasState,
    onFocus: (bounds: DrawingsBounds) => void,
  ) => {
    return (
      <WizzardPreviewItem
        item={item}
        zoom={canvasState.zoom}
        context={canvasState.context}
        focus={onFocus}
        browserScale={canvasState.browserScale}
      />
    );
  }, []);


  const [results, size] = useResults();
  if (!results?.length) {
    return null;
  }

  return (
    <PreviewPanel
      items={results}
      resultSize={size}
      drawingInfo={currentDrawingInfo}
      renderItem={renderItem}
    />
  );
};


export const WizzardPreview = React.memo(WizzardPreviewComponent);
