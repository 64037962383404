import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { ProjectHotkey } from 'common/components/drawings/utils/hotkey-utils';
import {
  GlobalKeyboardEventsControllerContextProps,
  withGlobalKeyboardEventsController,
} from 'common/components/global-keyboard-events-controller';
import { ProjectType } from 'common/constants/project-type';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { Create2dProjectDialog, CREATE_2D_PROJECT_DIALOG_NAME } from '../../components/dialog';
import { AnalyticsProps, withAnalyticsContext } from '../../utils/posthog/analytics-wraper';
import { MetricNames } from '../../utils/posthog/metric-names';
import { ProjectMenuTab, TwoDNavigation } from '../2d-navigation';
import { AccountActions } from '../account/actions/creators';
import { Company } from '../account/interfaces/company';
import { OwnCompany } from '../account/interfaces/own-company';
import { CreateOrganizationFormData } from '../empty-organizations/components/create-organization-dialog';
import { PersistedStorageActions } from '../persisted-storage/actions/creators';
import { ProjectsActions } from '../projects/actions/creators/common';
import { ProjectsFolderActions } from '../projects/actions/creators/projects-folder';
import { TwoDProjectsContent } from './2d-projects-content';


interface PageStateProps {
  companyId: number;
  companies: Company[];
  selectedCompany: Company;
  isCollapseMenu: boolean;
  ownCompany: OwnCompany;
}

interface PageDispatchProps {
  showDialog: (name: string, data?) => void;
  closeDialog: (dialogName: string) => void;
  createProject: () => void;
  selectCompany: (company: Company) => void;
  goToProducts: () => void;
  toggleProjectMenu: () => void;
  createCompany: (company: CreateOrganizationFormData) => void;
}

interface PageProps extends
  GlobalKeyboardEventsControllerContextProps,
  PageStateProps,
  PageDispatchProps,
  AbilityAwareProps,
  AnalyticsProps { }

class TwoDProjectsPageComponent extends React.Component<PageProps> {
  public componentDidMount(): void {
    this.props.addKeyDownEventListener(ProjectHotkey.OpenCreateProjectDialog, this.openCreate2dProjectDialog);
  }

  public componentWillUnmount(): void {
    this.props.removeKeyDownEventListener(ProjectHotkey.OpenCreateProjectDialog, this.openCreate2dProjectDialog);
  }

  public render(): React.ReactNode {
    const {
      ability,
    } = this.props;

    const canCreateProject = ability.can(Operation.Create, Subject.Project);
    const canCreate2dProject = canCreateProject && ability.can(Operation.Read, Subject.QuantityTakeOff2d);

    return (
      <TwoDNavigation
        pageTitle='Projects'
        isCreateButtonHide={false}
        createButtonDisable={!canCreate2dProject}
        createButtonText={'Create Project'}
        onClickCreateButton={this.openCreate2dProjectDialog}
        activeTabIndex={ProjectMenuTab.Projects}
      >
        <Create2dProjectDialog onSubmit={this.onSubmit2d} sendEvent={this.sendEvent}/>
        <TwoDProjectsContent
          projectType={ProjectType.Project2d}
        />
      </TwoDNavigation>
    );
  }


  @autobind
  private onSubmit2d(): void {
    this.props.createProject();
    this.props.closeDialog(CREATE_2D_PROJECT_DIALOG_NAME);
  }

  @autobind
  private sendEvent(params: { name: string, files: string }): void {
    this.props.sendEvent(MetricNames.projects.createProject, params);
  }

  @autobind
  private openCreate2dProjectDialog(): void {
    const canUseTemplates = this.props.ability.can(Operation.Manage, Subject.Project2DTemplates);
    this.props.showDialog(CREATE_2D_PROJECT_DIALOG_NAME, {
      projectType: ProjectType.Project2d,
      canUseTemplates,
    });
  }
}

const mapStateToProps = (state: State): PageStateProps => {
  const ownCompany = state.account.ownedCompany;
  const selectedCompany = state.account.selectedCompany;
  const companyId = selectedCompany ? selectedCompany.id : null;

  return {
    companyId,
    companies: state.account.companies,
    selectedCompany: state.account.selectedCompany,
    isCollapseMenu: state.persistedStorage.isCollapseMenu,
    ownCompany,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): PageDispatchProps => {
  return {
    showDialog: (dialogName, data) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    closeDialog: dialogName => dispatch(KreoDialogActions.closeDialog(dialogName)),
    createProject: () => dispatch(ProjectsActions.createProject()),
    selectCompany: company => {
      dispatch(AccountActions.selectCompany(company));
      dispatch(ProjectsFolderActions.setCurrentFolder(null));
    },
    goToProducts: () => dispatch(push(AppUrls.products.path)),
    toggleProjectMenu: () => dispatch(PersistedStorageActions.toggleProjectMenu()),
    createCompany: company => dispatch(AccountActions.createCompany(company)),
  };
};

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const TwoDProjectsPage = reduxConnector(
  withAbilityContext(withGlobalKeyboardEventsController(withAnalyticsContext(TwoDProjectsPageComponent))),
);
