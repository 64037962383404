import React, { useCallback, useEffect, useState } from 'react';
import { MentionsInput, Mention, MentionProps } from 'react-mentions';

import { ConstantFunctions } from 'common/constants/functions';
import { CommentTextAreaUtils } from 'unit-2d-comments/comments-panel/comment/comment-text-area/utils';
import { searchEmoji } from './search-emoji';
import { Styled } from './styled';

const neverMatchingRegex = /($a)/;

interface Props {
  value: string;
  autofocus?: boolean;
  children?: React.ReactElement<MentionProps> | Array<React.ReactElement<MentionProps>>;
  placeholder?: string;
  maxLength?: number;
  minHeight?: number;
  inputRef?: React.MutableRefObject<HTMLInputElement>;
  useDefaultEmojiPosition?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFinish: () => void;
  onBlur?: () => void;
}


const TextAreaComponent: React.FC<Props> = ({
  value,
  autofocus,
  children,
  placeholder,
  maxLength,
  inputRef,
  minHeight,
  useDefaultEmojiPosition,
  onChange,
  onFinish,
  onBlur,
}) => {
  const [isEmojiMenu, setIsEmojiMenu] = useState<boolean>(false);
  const [isEmailMenu, setIsEmailMenu] = useState<boolean>(false);
  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && onFinish) {
      ConstantFunctions.stopEvent(e);
      onFinish();
    }
  }, [onFinish]);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      if (!value) {
        inputRef.current.style.height = `${minHeight}px`;
      } else {
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
      }
    }
  }, [inputRef, minHeight, value]);
  const onChangeCallback = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const values = e?.target?.value;
    const trigger = values[values?.length - 1];

    setIsEmojiMenu(trigger === ':');
    if (trigger === '@') {
      setIsEmailMenu(true);
    }

    onChange(e);
  }, [isEmailMenu, isEmailMenu, onChange]);

  const top = CommentTextAreaUtils.getStyleDropList(inputRef?.current, isEmojiMenu);

  const setRef = useCallback((ref: HTMLInputElement) => {
    if (ref) {
      inputRef.current = ref;
      if (autofocus) {
        ref.focus();
        ref.selectionStart = ref.value.length || 0;
      }
    }
  }, [inputRef, autofocus]);

  return (
    <Styled.Container
      top={top}
      isEmojiMenu={isEmojiMenu}
      isChangeMenuPosition={!useDefaultEmojiPosition && (isEmojiMenu || isEmailMenu)}
      minHeight={minHeight}
    >
      <MentionsInput
        value={value}
        onChange={onChangeCallback}
        className={'mention-input'}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autofocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        inputRef={setRef}
        rows={1}
      >
        <Mention
          trigger=":"
          markup='__id__'
          data={searchEmoji}
          regex={neverMatchingRegex}
        />
        {children as any}
      </MentionsInput>
    </Styled.Container>
  );
};

export const TextArea = React.memo(TextAreaComponent);
