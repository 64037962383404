import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import {
  ResourceType,
} from '../../../utils/gantt-chart';


interface Props {
  isSelected: boolean;
  type: ResourceType;
  onSelect: (type: ResourceType) => void;
}

export class ResourceSelectorContentTab extends React.Component<Props> {
  public render(): React.ReactNode {
    const className = classNames('gantt-resources-chart-container-resource-selector__body__tab', {
      selected: this.props.isSelected,
    });
    return (
      <div className={className} onClick={this.onClick}>
        {this.props.children}
      </div>
    );
  }

  @autobind
  private onClick(): void {
    this.props.onSelect(this.props.type);
  }
}
