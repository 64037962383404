import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import './kreo-chart.scss';

import { NumberDictionary } from '../../common/interfaces/dictionary';
import { MultiChartData, SingleChartData } from '../../units/projects/utils/gantt-chart';
// eslint-disable-next-line import/named
import { ChartEngine, ChartRangeHoverEvent } from '../engine/KreoEngine';
import { DivPool } from './div-pool';
import { KreoChartMode } from './kreo-chart-mode';

export interface SingleChartColors {
  main: string | number;
  off?: string | number;
}

interface LegendLabel {
  x: number;
  y: number;
  value: number;
}

interface ChartProps {
  /**
   * @description Should be unique within page
   */
  id?: number | string;
  height: number;
  width: number;
  backgroundColor: string | number;
  isAvailable: boolean;
  mode: KreoChartMode;
  data: SingleChartData | MultiChartData;
  colors: SingleChartColors | NumberDictionary<number | string>;
  className?: string;
  renderLegend?: boolean;
  topLeftLabel?: string;
  topRightLabel?: string;
  renderTooltip?: (hoverEvent: ChartRangeHoverEvent) => React.ReactNode;
  getChartContainerRef?: (ref: HTMLDivElement) => void;
}

export interface ChartState {
  lastHoverEvent: ChartRangeHoverEvent;
}

const MINIMAL_LEGEND_LABEL_WIDTH = 14;

export class KreoChart extends React.Component<ChartProps, ChartState> {

  private container: HTMLDivElement = null;
  private engine: ChartEngine = null;
  private legendPool: DivPool = null;

  constructor(props: ChartProps) {
    super(props);

    this.state = {
      lastHoverEvent: null,
    };
  }

  public render(): React.ReactNode {
    const tooltipKey = `chart-${this.props.id}-tooltip`;
    const containerClassName = classNames('kreo-chart', this.props.className);

    return (
      <React.Fragment>
        <div
          className={containerClassName}
          ref={this.setContainerRef}
          data-tip={this.props.isAvailable}
          data-for={tooltipKey}
          style={{
            height: this.props.height,
            width: this.props.width,
          }}
        />
        {
          this.props.renderTooltip && this.props.isAvailable
            ? (
              <ReactTooltip
                id={tooltipKey}
                place='bottom'
                isCapture={false}
                offset={{ right: 60, top: 0 }}
                className='kreo-chart__tooltip'
              >
                {this.props.renderTooltip(this.state.lastHoverEvent)}
              </ReactTooltip>
            ) : null
        }
        {
          !this.props.isAvailable
            ? (
              <React.Fragment>
                <div className='kreo-chart__message-overlay' />
                <div className='kreo-chart__message-text'>
                  Resource is not available
                </div>
              </React.Fragment>
            ) : null
        }
        {
          this.props.renderLegend
            ? (
              <div
                className='kreo-chart__legend-container'
                ref={this.initLegendPool}
                style={{
                  height: this.props.height,
                  width: this.props.width,
                }}
              />
            ) : null
        }
        {
          this.props.topLeftLabel
            ? (
              <div
                className='kreo-chart__top-left-label'
              >
                {this.props.topLeftLabel}
              </div>
            ) : null
        }
        {
          this.props.topRightLabel
            ? (
              <div
                className='kreo-chart__top-right-label'
              >
                {this.props.topRightLabel}
              </div>
            ) : null
        }

      </React.Fragment>
    );
  }

  public componentDidMount(): void {
    if (!this.engine) {
      this.engine = new ChartEngine(this.container);
      this.engine.setBackgroundColor(this.props.backgroundColor);
      if (this.props.renderTooltip) {
        this.engine.addListener('hover', this.onChartRangeHover);
      } else {
        this.engine.toggleMouseInteraction(false);
      }

      this.setData();
      this.setColors();
      if (this.props.renderLegend) {
        this.renderLegend();
      }
    }
  }

  public componentDidUpdate(prevProps: ChartProps): void {
    if (
      this.props.height !== prevProps.height ||
      this.props.width !== prevProps.width
    ) {
      this.engine.resize();
    }

    this.setData();
    this.setColors();
    if (this.props.renderLegend) {
      this.renderLegend();
    }
  }

  public componentWillUnmount(): void {
    this.engine.dispose();
  }

  private setData(): void {
    if (!this.engine) {
      return;
    }

    if (this.props.mode === KreoChartMode.Single) {
      this.engine.showSingleChartData(this.props.data as SingleChartData);
    } else if (this.props.mode === KreoChartMode.Multi) {
      this.engine.showMultiChartData(this.props.data as MultiChartData);
    }
  }

  private setColors(): void {
    if (!this.engine) {
      return;
    }

    if (this.props.mode === KreoChartMode.Single) {
      const colors = this.props.colors as SingleChartColors;
      this.engine.setSingleChartColors(colors.main, colors.off);
    } else if (this.props.mode === KreoChartMode.Multi) {
      this.engine.setMultiChartColors(this.props.colors as NumberDictionary<number | string>);
    }
  }

  private renderLegend(): void {
    this.legendPool.markAllAsAvailable();

    const { mode, data, width, height } = this.props;

    if (data && this.engine) {
      const labels: LegendLabel[] = [];
      let values: number[] = null;
      if (mode === KreoChartMode.Single) {
        const singleData = data as SingleChartData;
        values = singleData.mergedValues;
      } else if (mode === KreoChartMode.Multi) {
        const multiData = data as MultiChartData;
        values = multiData.mergedValues2dCumm[multiData.mergedValues2dCumm.length - 1];
      }

      values = values.map(Math.round);

      const pixelsPerDay = width / (data.timeTo - data.timeFrom);
      const pixelsPerUnit = height / data.globalMax;
      const rangeWidth = pixelsPerDay * data.mergeDuration;
      if (rangeWidth >= MINIMAL_LEGEND_LABEL_WIDTH) {
        values.forEach((value, index) => {
          const rangeMiddleDay = data.mergeDuration * (index + 0.5) + data.mergeStart - data.timeFrom;
          if (value === 0) {
            return;
          }

          const x = rangeMiddleDay * pixelsPerDay;
          const y = this.props.height - value * pixelsPerUnit;

          labels.push({ x, y, value });
        });

        const nodes = this.legendPool.getNodes(labels.map((l) => l.value));
        const positionedLabels = [];
        nodes.forEach((node) => {
          const label = labels.find(l => node.value === l.value && !positionedLabels.includes(l));

          if (label) {
            node.element.style.bottom = `calc(100% - ${label.y}px)`;
            node.element.style.left = `${label.x}px`;
            node.element.style.display = '';

            positionedLabels.push(label);
          }
        });
      }
    }

    this.legendPool.detachAvailableNodes();
  }

  @autobind
  private setContainerRef(ref: HTMLDivElement): void {
    this.container = ref;
    if (this.props.getChartContainerRef) {
      this.props.getChartContainerRef(ref);
    }
  }

  @autobind
  private initLegendPool(legendContainerRef: HTMLDivElement): void {
    this.legendPool = new DivPool(legendContainerRef, 'kreo-chart__legend-item');
  }

  @autobind
  private onChartRangeHover(event: ChartRangeHoverEvent): void {
    this.setState({ lastHoverEvent: event });
  }
}
