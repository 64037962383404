import classNames from 'classnames';
import * as React from 'react';

import './copy-to-clipboard.scss';

import { KreoIconCopyClipboard } from 'common/UIKit';

interface Props {
  tipPosition?: string;
  text: string;
  onClick?: () => void;
}

export const CopyToClipBoard: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames('copy-to-clipboard-icon', props.tipPosition)}
      onClick={props.onClick}
      data-control-name='copy-to-clipboard-icon'
    >
      <KreoIconCopyClipboard />
      <span className='copy-to-clipboard-icon__tooltip'>
        {props.text}
      </span>
    </div>
  );
};

CopyToClipBoard.defaultProps = {
  tipPosition: 'bottom',
};
