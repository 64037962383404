import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { State as ReduxState } from 'common/interfaces/state';
import { IconButton, KreoIconCancel } from 'common/UIKit';
import { NotificationActions } from '../../actions';

interface ReduxProps {
  unreadNotificationsCount: number;
  allNotificationsCount: number;
}

interface ReduxActions {
  hideNotificationPanel(): void;
}

interface Props extends ReduxProps, ReduxActions {}

const BellNotificationsPanelHeaderComponent: React.FC<Props> = props => {
  const hasUnread = props.unreadNotificationsCount > 0;

  return (
    <div className='bell-notification-panel__header'>
      <div className='bell-notification-panel__header-title'>
        <div
          className={classNames('bell-notification-panel__header-title-badge', {
            unread: hasUnread,
          })}
        >
          {hasUnread ? props.unreadNotificationsCount : props.allNotificationsCount}
        </div>
        Notifications
      </div>
      <IconButton size='medium' onClick={props.hideNotificationPanel} icon={<KreoIconCancel />} />
    </div>
  );
};

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const notifications = state.notifications.notifications;
  return {
    unreadNotificationsCount: notifications.filter(x => !x.isRead).length,
    allNotificationsCount: notifications.length,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    hideNotificationPanel: () => {
      dispatch(NotificationActions.showNotificationPanel(false));
    },
  };
};

export const BellNotificationsPanelHeader = connect(mapStateToProps, mapDispatchToProps)(
  BellNotificationsPanelHeaderComponent,
);
