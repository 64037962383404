import { AnyAction } from 'redux';

import {
  MEASUREMENT_INITIAL_STATE,
} from '../units/projects/constants/measurements-initial-state';
import { MeasurementsState } from '../units/projects/interfaces/measurements/measurements-state';
import { measurementReducerMethods } from '../units/projects/reducers/measurements-reducer-methods';

export default function(
  state: MeasurementsState = MEASUREMENT_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null }): MeasurementsState {
  if (action.type in measurementReducerMethods) {
    return measurementReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
