import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { ResourceType } from '../../enums';
import { ResourceModel, ResourceVariantModel } from '../../interfaces/resources-data';
import { OpenEditResourceVariantPayload } from '../payloads/database-resource-listing';
import { DatabaseResourceListingActionTypes } from '../types/database-resource-listing';


function setSelectedResourceType(
  type: ResourceType,
): ActionWith<ResourceType> {
  return {
    type: DatabaseResourceListingActionTypes.SET_SELECTED_RESOURCE_TYPE,
    payload: type,
  };
}

function openEditResource(
  resourceId: number,
): ActionWith<number> {
  return {
    type: DatabaseResourceListingActionTypes.OPEN_EDIT_RESOURCE,
    payload: resourceId,
  };
}

function openEditResourceVariant(
  resourceId: number,
  resourceVariantId: number,
): ActionWith<OpenEditResourceVariantPayload> {
  return {
    type: DatabaseResourceListingActionTypes.OPEN_EDIT_RESOURCE_VARIANT,
    payload: { resourceId, resourceVariantId },
  };
}

function openCreateResource(): Action {
  return {
    type: DatabaseResourceListingActionTypes.OPEN_CREATE_RESOURCE,
  };
}

function openCreateResourceVariant(resourceId: number): ActionWith<number> {
  return {
    type: DatabaseResourceListingActionTypes.OPEN_CREATE_RESOURCE_VARIANT,
    payload: resourceId,
  };
}

function closeEditPanel(): Action {
  return {
    type: DatabaseResourceListingActionTypes.CLOSE_EDIT_PANEL,
  };
}

function resetRequestStatuses(): Action {
  return {
    type: DatabaseResourceListingActionTypes.RESET_REQUEST_STATUSES,
  };
}

function setEditResourceModel(resource: ResourceModel): ActionWith<ResourceModel> {
  return {
    type: DatabaseResourceListingActionTypes.SET_EDIT_RESOURCE_MODEL,
    payload: resource,
  };
}

function setEditResourceVariantModel(resource: ResourceVariantModel): ActionWith<ResourceVariantModel> {
  return {
    type: DatabaseResourceListingActionTypes.SET_EDIT_RESOURCE_VARIANT_MODEL,
    payload: resource,
  };
}

export const DatabaseResourceListingActions = {
  setSelectedResourceType,
  openEditResource,
  openEditResourceVariant,
  openCreateResource,
  openCreateResourceVariant,
  closeEditPanel,

  resetRequestStatuses,
  setEditResourceModel,
  setEditResourceVariantModel,
};
