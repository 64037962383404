import { TinyText } from '@kreo/kreo-ui-components';
import React from 'react';
import { BigTooltip } from './big-tooltip';
import { SmallTooltip } from './small-tooltip';

interface ContentProps {
  hotKey?: string;
  description?: string;
  picture?: string;
  text?: string;
  size?: 'small' | 'big';
  wordBreakAll?: boolean;
  tooltipOverflow?: string;
  childComponent?: React.ComponentType;
}


const TooltipContentComponent: React.FC<ContentProps> = ({
  hotKey,
  description,
  picture,
  text,
  size,
  wordBreakAll,
  tooltipOverflow,
  childComponent: ChildComponent,
}) => {

  if (ChildComponent) {
    return <ChildComponent />;
  } else if (hotKey || description || picture) {
    return size === 'small' ? (
      <SmallTooltip text={text} hotKey={hotKey} description={description} />
    ) : (
      <BigTooltip picture={picture} text={text} hotKey={hotKey} description={description} />
    );
  } else {
    return (
      <TinyText
        textAlign="center"
        wordBreak={wordBreakAll && 'keep-all'}
        nowrap={!wordBreakAll}
        overflow={tooltipOverflow}
      >
        {text}
      </TinyText>
    );
  }
};

export const TooltipContent = React.memo(TooltipContentComponent);
