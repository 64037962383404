import * as React from 'react';

import { ValidationResizableEditorApi } from '../../interfaces/validation-resizable-editor-api';

export interface ValidationEngineResizableContextInterface {
  getApi: (api: ValidationResizableEditorApi) => void;
  onResizeStart: () => void;
  onResize: (width: number) => void;
}

export const ValidationEngineResizableContext =
  React.createContext<ValidationEngineResizableContextInterface>(null);


export class ValidationEngineResizableLayoutContextProvider
  extends React.PureComponent<ValidationEngineResizableContextInterface> {

  public render(): React.ReactNode {
    return (
      <ValidationEngineResizableContext.Provider value={this.props}>
        {this.props.children}
      </ValidationEngineResizableContext.Provider>
    );
  }
}
