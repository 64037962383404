import { SvgComponent } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';


import { DrawingMarkShapes } from 'common/components/drawings/constants/drawing-styles';
import { Styled } from './styled';

interface Props {
  Icon: SvgComponent;
  shape: DrawingMarkShapes;
  selected: boolean;
  onSelectClick: (shape: DrawingMarkShapes) => void;
}

const ShapeComponent: React.FC<Props> = ({ Icon, shape, onSelectClick, selected }) => {
  const onSelect = useCallback(() => onSelectClick(shape), [shape]);

  return (
    <Styled.Container onClick={onSelect} selected={selected}>
      <Icon />
    </Styled.Container>
  );
};


export const Shape = React.memo(ShapeComponent);
