import dotProp from 'dot-prop-immutable';
import * as monolite from 'monolite';

import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import {
  ActivityAssignmentSelectMaterialPayload,
  ActivityAssignmentSetModalityPayload,
  PathAndEndElementTitlePayload,
} from '../actions/payloads/activity-assignment';
import { ActivityAssignmentActionTypes } from '../actions/types/activity-assignment';
import { ACTIVITY_ASSIGNMENT_INITIAL_STATE } from '../constants/activity-assignment-initial-state';
import {
  ActivityAssignmentAgregatedBimInfo,
} from '../interfaces/activity-assignment/activity-assignment-agregated-bim-info';
import { ActivityAssignmentAgregatedData } from '../interfaces/activity-assignment/activity-assignment-agregated-data';
import { ActivityAssignmentState } from '../interfaces/activity-assignment/activity-assignment-state';
import { ActivityAssignmentTreeNode } from '../interfaces/activity-assignment/activity-assignment-tree-node';
import { ActivityAssignmentActivity } from '../interfaces/activity-assignment/activity-assignmnet-activity';
import { ActivityAssignmentUtils } from '../utils/activity-assignment-utils';

const saveBimData = (
  state: ActivityAssignmentState,
  payload: ActivityAssignmentAgregatedBimInfo,
): ActivityAssignmentState => {
  return new MonoliteHelper(state)
    .set(_ => _.layersData, payload.layersData)
    .set(_ => _.groupNames, payload.groupNames)
    .set(_ => _.dataDictionary, payload.dataDictionary)
    .set(_ => _.rawData, payload.rawData)
    .set(_ => _.elementDataMap, payload.elementDataMap)
    .set(_ => _.statuses.revitData, RequestStatus.Loaded)
    .get();
};

const expandItem = (
  state: ActivityAssignmentState,
  payload: string,
): ActivityAssignmentState => {
  return dotProp.set(state, `${payload}.expanded`, value => !value);
};

const saveCurrentProperties = (
  state: ActivityAssignmentState,
  work: ActivityAssignmentActivity,
): ActivityAssignmentState => {
  if (ActivityAssignmentUtils.stateIsFull(state)) {
    const props = ActivityAssignmentUtils.buildProperties(
      work,
      state.layersData,
      state.groupNames,
      state.rawData,
      state.dataDictionary,
      state.elementDataMap,
    );
    state = monolite.set(state, _ => _.properties)(props);
  }
  return state;
};

const hideActivitiesAndProps = (state: ActivityAssignmentState): ActivityAssignmentState => {
  state = monolite.set(state, _ => _.selectedWork)(null);
  state = monolite.set(state, _ => _.properties)([]);
  state = monolite.set(state, _ => _.isShowActivities)(false);
  return state;
};


const selectTreeNode = (
  state: ActivityAssignmentState,
  payload: string,
): ActivityAssignmentState => {
  state = hideActivitiesAndProps(state);
  state = monolite.set(state, _ => _.selectedPath)(payload);
  state = monolite.set(state, _ => _.isShowSelection)(true);
  return state;
};

const showActivitiesByElementType = (
  state: ActivityAssignmentState,
  payload: string,
): ActivityAssignmentState => {
  const item: ActivityAssignmentTreeNode<number> = dotProp.get(state, payload);
  const works = state.works.slice(item.start, item.end);
  state = monolite.set(state, _ => _.selectedWorks)(ActivityAssignmentUtils.extractActivityData(works[0]));
  state = saveCurrentProperties(state, works[0]);
  state = monolite.set(state, _ => _.selectedPath)(payload);
  state = monolite.set(state, _ => _.selectedWork)(works[0]);
  state = monolite.set(state, _ => _.isShowActivities)(true);
  state = monolite.set(state, _ => _.isShowSelection)(true);
  return state;
};

const showActivitiesForLayer = (
  state: ActivityAssignmentState,
  payload: PathAndEndElementTitlePayload,
): ActivityAssignmentState => {

  const work: ActivityAssignmentActivity = state.works[payload.path];
  state = monolite.set(state, _ => _.selectedWorks)([...ActivityAssignmentUtils.extractActivityData(work)]);
  state = saveCurrentProperties(state, work);
  state = monolite.set(state, _ => _.selectedPath)(payload.path);
  state = monolite.set(state, _ => _.selectedWork)(work);
  state = monolite.set(state, _ => _.isShowActivities)(true);
  state = monolite.set(state, _ => _.isShowSelection)(true);
  return state;
};

const searchInRevitTree = (
  state: ActivityAssignmentState,
  payload: string,
): ActivityAssignmentState => {
  const helper = new MonoliteHelper(state);
  if (payload) {
    const searchResult = ActivityAssignmentUtils.search(state.tree, payload);
    const tree = ActivityAssignmentUtils.expandTreeByPathes(state.tree.slice(), searchResult);

    helper.set(_ => _.searchMode, true);
    helper.set(_ => _.searchedPathes, searchResult);
    helper.set(_ => _.tree, tree);
  } else {
    helper.set(_ => _.searchMode, false);
  }
  return helper.get();
};

const changeActivitySelected = (
  state: ActivityAssignmentState,
  payload: number,
): ActivityAssignmentState => {
  state = dotProp.set(state, `selectedWorks.${payload}.isUsed`, value => !value);
  return state;
};

const removeActivity = (
  state: ActivityAssignmentState,
  payload: number,
): ActivityAssignmentState => {
  const works = state.selectedWorks.slice();
  works.splice(payload, 1);

  state = monolite.set(state, _ => _.selectedWorks)(works);
  return state;
};

const changeSelectedModalityForActivity = (
  state: ActivityAssignmentState,
  payload: ActivityAssignmentSetModalityPayload,
): ActivityAssignmentState => {
  const { subVariantData } = payload;

  return new MonoliteHelper(state)
    .set(_ => _.selectedWorks[payload.index].selectedDbModalityId, subVariantData.dbModalityId)
    .set(_ => _.selectedWorks[payload.index].dataBaseId, subVariantData.dataBaseId)
    .set(_ => _.selectedWorks[payload.index].rootActivityId, subVariantData.rootActivityId)
    .get();
};

function selectMaterial(
  state: ActivityAssignmentState,
  payload: ActivityAssignmentSelectMaterialPayload,
): ActivityAssignmentState {
  const subVariantIndex = state.selectedWorks[payload.activityIndex]
    .subVariants.findIndex(x => x.dbModalityId === payload.activitySubVariantId);
  return new MonoliteHelper(state)
    .set(
      _ =>
        _.selectedWorks[payload.activityIndex].subVariants[subVariantIndex].materials[
          payload.materialSelectIndex
        ].selectedMaterialId,
      payload.materialId,
    )
    .get();
}

const approveAssignmentInState = (
  state: ActivityAssignmentState, payload: ActivityAssignmentActivity,
): ActivityAssignmentState => {
  const linkIndex = state.workLinks.findIndex(link => link.work === payload.id);
  if (linkIndex === -1) {
    console.error(`work with id = ${payload.id} doesn't exist in state`);
    return state;
  }
  const path = ActivityAssignmentUtils.getActivityPath(state.workLinks[linkIndex]);
  const node = dotProp.set(state, path);
  const workIndex = state.works.findIndex(value => value.id === payload.id);
  const work = state.works[workIndex];
  if (ActivityAssignmentUtils.isActivityBad(payload.data.variants)) {
    state = monolite.set(state, _ => _.workLinks[linkIndex].isGood)(false);

    if (
      work.data.engineIds.length > 0 &&
      !state.badIds.find(value => value === work.data.engineIds[0])
    ) {
      state = monolite.set(state, _ => _.badIds)([...state.badIds, ...work.data.engineIds]);
    }
    if (node.end - node.start > 1) {
      const workPath = `work.${workIndex}`;
      const isExistsInSetOfErrors = !!state.errorNodes.find(value => value === workPath);
      if (!isExistsInSetOfErrors) {
        const newErrorPathes = [...state.errorNodes, workPath];
        state = monolite.set(state, _ => _.errorNodes)(newErrorPathes);
      }
    } else {
      const isExistsInSetOfErrors = !!state.errorNodes.find(value => value === path);
      if (
        work.data.engineIds.length > 0 &&
        !!state.badIds.find(bimElementId => bimElementId === work.data.engineIds[0])
      ) {
        const set = new Set(work.data.engineIds);
        state = monolite.set(state, _ => _.badIds)(state.badIds.filter(bimElementId => set.has(bimElementId)));
      }
      if (!isExistsInSetOfErrors) {
        const pathes = [...state.errorNodes, path];
        state = monolite.set(state, _ => _.errorNodes)(pathes);
      }
    }
  } else {
    state = monolite.set(state, _ => _.workLinks[linkIndex].isGood)(true);
    let pathes = new Array<string>();
    if (node.end - node.start > 1) {
      const wpath = `work.${workIndex}`;
      pathes = state.errorNodes.filter(value => value !== wpath);
    } else {
      pathes = state.errorNodes.filter(value => value !== path);
    }
    state = monolite.set(state, _ => _.errorNodes)(pathes);
    const engineIds = new Set<number>(work.data.engineIds);
    state = monolite.set(state, _ => _.badIds)(state.badIds.filter(value => !engineIds.has(value)));
  }
  state = monolite.set(state, (_) => _.hasChanges)(true);
  return state;
};

const selectFromEngine = (
  state: ActivityAssignmentState,
  payload: number,
): ActivityAssignmentState => {
  const helper = new MonoliteHelper(state)
    .setMap(
      x => x.tree,
      x => !x.expanded ? x : new MonoliteHelper(x).set(_ => _.expanded, false).get(),
    );
  const elementPath = ActivityAssignmentUtils.getElementPathById(state, payload).path;
  const path = elementPath.split('.');
  const category = path[0];
  const family = path[2];
  const type = path[4];
  helper
    .set(_ => _.tree[category].expanded, true)
    .set(_ => _.tree[category].children[family].expanded, true)
    .set(_ => _.tree[category].children[family].children[type].expanded, true)
    .set(_ => _.centralTreeElement, Number(category) + Number(family) + Number(type));
  if (path.length % 2 === 1) {
    return showActivitiesByElementType(helper.get(), `tree.${elementPath}`);
  } else {
    return showActivitiesForLayer(helper.get(), {
      path: path[5],
      endElementTitle: state.tree[category].children[family].children[type].name,
    });
  }
};

const updateActvityData = (
  state: ActivityAssignmentState,
  payload: ActivityAssignmentActivity,
): ActivityAssignmentState => {
  const index = state.works.findIndex(({ id }) => id === payload.id);

  if (index >= 0) {
    state = monolite.set(state, _ => _.works[index])(payload);
    return monolite.set(state, _ => _.selectedWorks)(ActivityAssignmentUtils.extractActivityData(payload));
  }

  return state;
};

const dropState = (state: ActivityAssignmentState): ActivityAssignmentState => {
  if (state.isSaveData) {
    return state;
  } else {
    return ACTIVITY_ASSIGNMENT_INITIAL_STATE;
  }
};

const showHideRevitTree = (state: ActivityAssignmentState): ActivityAssignmentState => {
  return monolite.set(state, _ => _.viewTree)(!state.viewTree);
};

const selectWorkById = (
  state: ActivityAssignmentState,
  payload: number,
): ActivityAssignmentState => {
  const workIndex = state.works.findIndex(work => work.id === payload);

  if (workIndex >= 0) {
    const work = state.works[workIndex];
    state = monolite.set(state, _ => _.selectedWorks)([...ActivityAssignmentUtils.extractActivityData(work)]);
    state = saveCurrentProperties(state, work);
    state = monolite.set(state, _ => _.selectedWork)(work);
    state = monolite.set(state, _ => _.selectedPath)(workIndex.toString());

    const modalitiesIds = [];
    state.selectedWorks.forEach(selectedWork => {
      const subVariantIds = selectedWork.subVariants.map(subVariant => subVariant.dbModalityId);
      modalitiesIds.push(...subVariantIds);
    });

    state = monolite.set(state, _ => _.assignmentExistedModalities)(modalitiesIds);
  }

  return state;
};


function dropStatistic(state: ActivityAssignmentState): ActivityAssignmentState {
  return new MonoliteHelper(state)
    .set(_ => _.statistic, null)
    .set(_ => _.statuses.loadData, RequestStatus.NotRequested)
    .get();
}

export const ActivityAssignmentReducerMethods: ReducerMethods<ActivityAssignmentState> = {
  [ActivityAssignmentActionTypes.LOAD_DATA]: (state: ActivityAssignmentState) => {
    return new MonoliteHelper(state)
      .set(_ => _.dataLoaded, false)
      .set(_ => _.statuses.loadData, RequestStatus.Loading)
      .get();
  },
  [ActivityAssignmentActionTypes.LOAD_DATA_SUCCEEDED]: (
    state: ActivityAssignmentState,
    payload: ActivityAssignmentAgregatedData,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.tree, payload.tree)
      .set(_ => _.works, payload.works)
      .set(_ => _.badIds, payload.badIds)
      .set(_ => _.dataLoaded, true)
      .set(_ => _.workLinks, payload.workLinks)
      .set(_ => _.engineIds, payload.engineIds)
      .set(_ => _.statuses.loadData, RequestStatus.Loaded)
      .set(_ => _.databases, payload.databases)
      .set(_ => _.statistic, payload.statistic)
      .set(_ => _.usedOldCmtdbVersion, payload.usedOldCmtdbVersion)
      .get();
  },
  [ActivityAssignmentActionTypes.LOAD_WORK_SUCCEEDED]: (
    state: ActivityAssignmentState,
    payload: ActivityAssignmentActivity,
  ) => {
    return new MonoliteHelper(state)
      .set(_ => _.selectedWork, payload)
      .set(_ => _.dataLoaded, true)
      .get();
  },
  [ActivityAssignmentActionTypes.LOAD_DATA_FAILED]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.statuses.loadData, RequestStatus.Failed)
      .get();
  },
  [ActivityAssignmentActionTypes.EXPAND_ITEM]: expandItem,
  [ActivityAssignmentActionTypes.SHOW_WORK_FROM_TYPE]: showActivitiesByElementType,
  [ActivityAssignmentActionTypes.SHOW_WORK_FROM_LAYER]: showActivitiesForLayer,
  [ActivityAssignmentActionTypes.START_SEARCH]: searchInRevitTree,
  [ActivityAssignmentActionTypes.CHECK_WORK]: changeActivitySelected,
  [ActivityAssignmentActionTypes.REMOVE_WORK]: removeActivity,
  [ActivityAssignmentActionTypes.GET_REVIT_DATA]:
    (state) => monolite.set(state, (_) => _.statuses.revitData)(RequestStatus.Loading),
  [ActivityAssignmentActionTypes.GET_REVIT_DATA_BY_PROJECT_ID]:
    (state) => monolite.set(state, (_) => _.statuses.revitData)(RequestStatus.Loading),
  [ActivityAssignmentActionTypes.DROP_STATE]: dropState,
  [ActivityAssignmentActionTypes.SAVE_REVIT_DATA]: saveBimData,
  [ActivityAssignmentActionTypes.SET_MODALITY]: changeSelectedModalityForActivity,
  [ActivityAssignmentActionTypes.ASSIGN_WORKS_SUCCEEDED]: approveAssignmentInState,
  [ActivityAssignmentActionTypes.SELECT_FROM_ENGINE]: selectFromEngine,
  [ActivityAssignmentActionTypes.SET_SAVE_DATA]:
    (state, payload: boolean) => monolite.set(state, (_) => _.isSaveData)(payload),
  [ActivityAssignmentActionTypes.UPDATE_WORK]: updateActvityData,
  [ActivityAssignmentActionTypes.SHOW_HIDE_TREE]: showHideRevitTree,
  [ActivityAssignmentActionTypes.SELECT_WORK_BY_ID]: selectWorkById,
  [ActivityAssignmentActionTypes.DROP_STATISTIC]: dropStatistic,
  [ActivityAssignmentActionTypes.SELECT_TREE_NODE]: selectTreeNode,
  [ActivityAssignmentActionTypes.SELECT_MATERIAL]: selectMaterial,
};
