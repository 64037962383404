import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';

import './index.scss';

import { RequestStatus } from 'common/enums/request-status';
import { Feed } from 'common/interfaces/feed';
import {
  LazyLoadingVirtualList,
  MaterialInput,
} from 'common/UIKit';
import { KreoDialogActionsPanel } from 'common/UIKit/dialogs/kreo-dialog-actions-panel';
import { ResourceVariantsApi } from '../../api/resource-variants';
import { ResourcesApi } from '../../api/resources';
import { ResourceType } from '../../enums';
import { RequestFilterModel, UnitModel } from '../../interfaces/data';
import { Resource, ResourceModelType } from './resource';

interface Props {
  isMultiselect: boolean;
  databaseId: number;
  filterFields?: Record<string, any>;
  type: ResourceType;
  selectedResources: any[];
  name: string;
  isOpen: boolean;
  unitMap: Record<number, UnitModel>;
  onClose: () => void;
  onSave: (selectedResources: any[]) => void;
}

interface State {
  search: string;
  selectedResources: ResourceModelType[];
  resources: ResourceModelType[];
  loadStatus: RequestStatus;
  total: number;
}

export class ResourceSelector extends React.Component<Props, State> {
  private timeout: number | any = null;
  private batch: number = 25;

  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
      selectedResources: this.props.selectedResources,
      resources: [],
      loadStatus: RequestStatus.NotRequested,
      total: 0,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.selectedResources !== this.props.selectedResources) {
      this.setState({ selectedResources: this.props.selectedResources });
    }

    if (!isEqual(prevProps.filterFields, this.props.filterFields)) {
      this.loadResourcesWithDelay();
    }
  }

  public render(): JSX.Element {
    const { name } = this.props;
    const { resources, loadStatus, selectedResources } = this.state;
    const disabledBtn = selectedResources.length === 0;

    return (
      <React.Fragment>
        <div className='modality-resource-selector'>
          <div className='search'>
            <MaterialInput
              name='search'
              label={name}
              placeholder='Search'
              isFloatingLabel={false}
              value={this.state.search}
              onChange={this.onChangeSearch}
            />
          </div>
          {loadStatus === RequestStatus.Loaded && resources.length === 0 ? (
            <React.Fragment>
              <div className={`message ${name}`}>
                {`${name} database is empty`}
              </div>
            </React.Fragment>
          ) : (
            <div className='resources'>
              {this.props.isOpen ? (
                <LazyLoadingVirtualList
                  objects={resources}
                  itemHeight={66}
                  renderedItemsCount={14}
                  renderItem={this.renderResource}
                  batch={this.batch}
                  bufferCount={5}
                  load={this.lazyLoadResources}
                  loadStatus={this.state.loadStatus}
                  total={this.state.total}
                />
              ) : null}
            </div>
          )}
        </div>
        <KreoDialogActionsPanel
          onSubmit={this.onSave}
          submitBtnName='Apply'
          submitDisabled={disabledBtn}
          onCancel={this.props.onClose}
        />
      </React.Fragment>
    );
  }

  @autobind
  private isSelected(item: any): boolean {
    return this.state.selectedResources
      .map((x) => x.id)
      .includes(item.id);
  }

  @autobind
  private select(item: any): void {
    let selectedResources = this.state.selectedResources.slice();

    if (this.props.isMultiselect) {
      if (this.isSelected(item)) {
        selectedResources = selectedResources.filter(
          (x) => x.id !== item.id,
        );
      } else {
        selectedResources = selectedResources.concat([item]);
      }
    } else {
      if (this.isSelected(item)) {
        selectedResources = [];
      } else {
        selectedResources = [item];
      }
    }

    this.setState({ selectedResources });
  }

  @autobind
  private onChangeSearch(_event: React.ChangeEvent, newValue: string): void {
    this.setState({ search: newValue });
    this.loadResourcesWithDelay();
  }

  private loadResourcesWithDelay(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(
      () => {
        this.loadResources(
          0,
          25,
          this.state.search,
        );
      },
      300,
    );
  }

  @autobind
  private onSave(): void {
    this.props.onSave(this.state.selectedResources);
  }

  @autobind
  private renderResource(item: any): JSX.Element {
    return (
      <Resource
        resource={item}
        isSelected={this.isSelected}
        select={this.select}
        type={this.props.type}
        unitMap={this.props.unitMap}
      />
    );
  }

  @autobind
  private lazyLoadResources(skip: number, take: number): void {
    this.loadResources(skip, take, this.state.search);
  }

  @autobind
  private loadResources(skip: number, take: number, search: string): void {
    const loadResources = this.getLoadResourcesMethod(this.props.type);
    const component = this;
    this.setState({
      resources: skip > 0 ? this.state.resources : [],
      loadStatus: RequestStatus.Loading,
    });

    const filter: RequestFilterModel = { search, skip, take, ...this.props.filterFields };
    loadResources(this.props.databaseId, filter).then(result => {
      result.data.forEach(x => {
        if (this.props.type === ResourceType.Labour) {
          x.laborVariantId = x.id;
        } else if (this.props.type  === ResourceType.Material) {
          x.resourceId = x.id;
        } else if (this.props.type  === ResourceType.Plant) {
          x.plantVariantId = x.id;
        }
      });

      component.setState({
        resources: this.state.resources.concat(result.data),
        loadStatus: RequestStatus.Loaded,
        total: result.count,
      });
    });
  }

  private getLaborsVariants(databaseId: number, filter: RequestFilterModel): Promise<Feed<any>> {
    return ResourceVariantsApi.getResourcesVariants(databaseId, ResourceType.Labour, filter);
  }

  private getPlantsVariants(databaseId: number, filter: RequestFilterModel): Promise<Feed<any>> {
    return ResourceVariantsApi.getResourcesVariants(databaseId, ResourceType.Plant, filter);
  }

  private getMaterials(databaseId: number, filter: RequestFilterModel): Promise<Feed<any>> {
    return ResourcesApi.getResources(databaseId, ResourceType.Material, filter);
  }

  private getLoadResourcesMethod(
    type: ResourceType,
  ): (databaseId: number, filter: RequestFilterModel) => Promise<Feed<any>> {
    if (type === ResourceType.Labour) {
      return this.getLaborsVariants;
    } else if (type === ResourceType.Plant) {
      return this.getPlantsVariants;
    } else if (type === ResourceType.Material) {
      return this.getMaterials;
    }
  }
}
