import autobind from 'autobind-decorator';
import { DeferredExecutor } from 'common/utils/deferred-executer';

export interface Highlightable {
  setHighlightStatus(status: boolean): void;
}

export class TextSearchHighlightController {
  private _finishHighlightExecutor: DeferredExecutor = new DeferredExecutor(4000);

  private _lastInstances: Highlightable;

  public applyHighlight(highlightable: Highlightable): void {
    this.removeHighlight();
    if (highlightable) {
      this._lastInstances = highlightable;
      this.highlight();
      this._finishHighlightExecutor.execute(this.removeHighlight);
    }
  }

  @autobind
  private highlight(): void {
    this._lastInstances.setHighlightStatus(true);
  }

  @autobind
  private removeHighlight(): void {
    if (this._lastInstances) {
      this._lastInstances.setHighlightStatus(false);
      this._lastInstances = null;
    }
  }
}
