import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import {
  OpenEditResourceVariantPayload,
} from '../actions/payloads/database-resource-listing';
import { DatabaseResourceListingActionTypes } from '../actions/types/database-resource-listing';
import { ModifyDatabaseEntityMode, ResourceType } from '../enums';
import { DatabaseReduxState } from '../interfaces/redux-state';
import { ResourceModel, ResourceVariantModel } from '../interfaces/resources-data';


export const DatabaseResourceListingReducerMethods: ReducerMethods<DatabaseReduxState> = {
  [DatabaseResourceListingActionTypes.OPEN_CREATE_RESOURCE]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.root, null)
      .set(_ => _.currentDatabase.editModel.variant, null)
      .set(_ => _.currentDatabase.editModel.rootId, null)
      .set(_ => _.currentDatabase.editModel.variantId, null)
      .set(_ => _.currentDatabase.editModel.mode, ModifyDatabaseEntityMode.Create)
      .set(_ => _.currentDatabase.editModel.isOpen, true)
      .get();
  },
  [DatabaseResourceListingActionTypes.OPEN_CREATE_RESOURCE_VARIANT]: (state, payload: number) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.root, null)
      .set(_ => _.currentDatabase.editModel.variant, null)
      .set(_ => _.currentDatabase.editModel.rootId, payload)
      .set(_ => _.currentDatabase.editModel.variantId, null)
      .set(_ => _.currentDatabase.editModel.mode, ModifyDatabaseEntityMode.Create)
      .set(_ => _.currentDatabase.editModel.isOpen, true)
      .get();
  },
  [DatabaseResourceListingActionTypes.OPEN_EDIT_RESOURCE]: (state, payload: number) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.root, null)
      .set(_ => _.currentDatabase.editModel.variant, null)
      .set(_ => _.currentDatabase.editModel.rootId, payload)
      .set(_ => _.currentDatabase.editModel.variantId, null)
      .set(_ => _.currentDatabase.editModel.mode, ModifyDatabaseEntityMode.Edit)
      .set(_ => _.currentDatabase.editModel.isOpen, true)
      .get();
  },
  [DatabaseResourceListingActionTypes.OPEN_EDIT_RESOURCE_VARIANT]: (state, payload: OpenEditResourceVariantPayload) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.root, null)
      .set(_ => _.currentDatabase.editModel.variant, null)
      .set(_ => _.currentDatabase.editModel.rootId, payload.resourceId)
      .set(_ => _.currentDatabase.editModel.variantId, payload.resourceVariantId)
      .set(_ => _.currentDatabase.editModel.mode, ModifyDatabaseEntityMode.Edit)
      .set(_ => _.currentDatabase.editModel.isOpen, true)
      .get();
  },
  [DatabaseResourceListingActionTypes.CLOSE_EDIT_PANEL]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.isOpen, false)
      .get();
  },
  [DatabaseResourceListingActionTypes.SET_SELECTED_RESOURCE_TYPE]: (state, payload: ResourceType) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.resourceListing.resourceType, payload)
      .get();
  },
  [DatabaseResourceListingActionTypes.RESET_REQUEST_STATUSES]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.statuses.resourceListing, RequestStatus.NotRequested)
      .get();
  },
  [DatabaseResourceListingActionTypes.SET_EDIT_RESOURCE_MODEL]: (state, payload: ResourceModel) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.root, payload)
      .get();
  },
  [DatabaseResourceListingActionTypes.SET_EDIT_RESOURCE_VARIANT_MODEL]: (state, payload: ResourceVariantModel) => {
    return new MonoliteHelper(state)
      .set(_ => _.currentDatabase.editModel.variant, payload)
      .get();
  },
};
