import { TOOLTIP_CLASSNAME } from '../../../styled';

const ID_PREFIX = 'tooltip_';

const appendTooltip = (id: string, innerText: string): void => {
  const tooltipId = `${ID_PREFIX}${id}`;
  if (document.getElementById(tooltipId)) {
    return;
  }
  const descriptionTooltip = document.createElement('div');
  const element = document.getElementById(id);
  const modalRoot = document.getElementById('modal-root');
  const elementRect = element.getBoundingClientRect();

  descriptionTooltip.style.top = `${elementRect.top - elementRect.height}px`;
  descriptionTooltip.style.left = `${elementRect.left + elementRect.width / 2}px`;

  descriptionTooltip.className = TOOLTIP_CLASSNAME;
  descriptionTooltip.id = tooltipId;
  descriptionTooltip.innerText = innerText;

  modalRoot.appendChild(descriptionTooltip);
};

const removeTooltip = (id: string): void => {
  const tooltip = document.getElementById(`${ID_PREFIX}${id}`);
  if (tooltip) {
    tooltip.remove();
  }
};

export const AgGridTooltipUtils = {
  appendTooltip,
  removeTooltip,
};
