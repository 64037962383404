import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { DrawingsTextSearchActions } from '../../actions/creators';
import { PdfLoader } from '../../helpers';
import { loadAndExtractPageTextRects } from '../../helpers/load-page-text';
import { useCurrentDrawingInfo } from '../../hooks';
import { DrawingsFile } from '../../interfaces/drawings-file-info';

export function useLoadingText(): void {
  const currentDrawingInfo = useCurrentDrawingInfo();
  const files = useSelector<State, Record<string, DrawingsFile>>(
    s => s.drawings.files.entities as Record<string, DrawingsFile>,
  );

  const saveRects = useActionDispatch(DrawingsTextSearchActions.setPageTextRects);
  const setIsTextLoading = useActionDispatch(DrawingsTextSearchActions.setIsTextLoading);
  const currentTextDrawingId=  useSelector<State, string | null>(
    s => s.drawings.textSearch.textRectangles.drawingId,
  );

  const loader = useMemo(() => new PdfLoader(), []);

  const cancel = useRef<() => void>(null);

  useEffect(() => {
    if (!currentDrawingInfo) {
      saveRects({ drawingId: null, rects: [] });
      return;
    }
    if (currentDrawingInfo.drawingId === currentTextDrawingId) {
      return;
    }
    saveRects({ drawingId: null, rects: [] });
    setIsTextLoading(true);
    const [ promise, canceller ] = loadAndExtractPageTextRects({ drawing: currentDrawingInfo, files, loader });
    cancel.current = canceller;
    promise.then((rects) => {
      setIsTextLoading(false);
      saveRects({ drawingId: currentDrawingInfo.drawingId, rects });
      cancel.current = null;
    }).catch((error) => {
      console.error('Error loading text', error);
      setIsTextLoading(false);
    });
  }, [currentDrawingInfo?.drawingId]);

  useEffect(() => {
    return () => {
      if (cancel.current) {
        cancel.current();
        cancel.current = null;
      }
      setIsTextLoading(false);
    };
  }, []);
}

