import autobind from 'autobind-decorator';
import * as React from 'react';
import { FormulaFormIssues, FormulaInputValidationStatus, FormulaInput, FormulaAPI } from '../input';
import { FormulaFieldStatus } from '../status';
import { Styled } from './styled';

interface Props {
  properties?: string[];
  value: string;
  onChangeValue?: (value: string) => void;
  onChangeValidation: (isValid: boolean) => void;
  sendApi?: (api: FormulaAPI) => void;
  onClick?: () => void;
}


interface ComponentState {
  validationStatus: FormulaInputValidationStatus;
  issues?: FormulaFormIssues;
}

export class FormulaField extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      validationStatus: FormulaInputValidationStatus.Ok,
    };
  }

  public render(): React.ReactNode {
    return (
      <Styled.Container>
        <Styled.InputContainer onClick={this.props.onClick}>
          <FormulaInput
            readonly={!this.props.onChangeValue}
            sendApi={this.props.sendApi}
            onChangeValue={this.props.onChangeValue}
            value={this.props.value}
            properties={this.props.properties}
            onChangeValidationStatus={this.onChangeValidationStatus}
          />
        </Styled.InputContainer>
        <FormulaFieldStatus
          validationStatus={this.state.validationStatus}
          issues={this.state.issues}
        />
      </Styled.Container>
    );
  }

  @autobind
  private onChangeValidationStatus(validationStatus: FormulaInputValidationStatus, issues?: FormulaFormIssues): void {
    const isValid = validationStatus !== FormulaInputValidationStatus.Error
      && validationStatus !== FormulaInputValidationStatus.InProgress;
    this.setState({ validationStatus, issues }, () => this.props.onChangeValidation(isValid));
  }
}
