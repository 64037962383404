import {
  OpenArrowButton,
  RoundButton,
  EditButton,
  DeleteButton,
  Text,
  IconButton,
} from '@kreo/kreo-ui-components';
import { PlusSmall } from '@kreo/kreo-ui-components/icons';
import React, { useCallback } from 'react';
import { ElementTooltip } from '../tooltip';
import { Styled } from './styled';

interface Props {
  text: string;
  isExpanded: boolean;
  Icon?: React.ComponentType;
  addItemToGroup?: () => void;
  removeGroup: () => void;
  onGroupClick: (e: React.MouseEvent) => void;
  editItem?: () => void;
  addButtonContextMenu?: JSX.Element;
  tooltipTextAddButton?: string;
  tooltipTextDeleteButton?: string;
  tooltipTextEditButton?: string;
}

const LabelGroupComponent: React.FC<Props> = ({
  Icon,
  text,
  isExpanded,
  addItemToGroup,
  removeGroup,
  onGroupClick,
  addButtonContextMenu,
  tooltipTextAddButton,
  tooltipTextDeleteButton,
  editItem,
  tooltipTextEditButton,
}) => {
  const addLabelGroup = useCallback(
    (e) => {
      e.stopPropagation();
      addItemToGroup();
    },
    [addItemToGroup],
  );
  const onClickIconClose = useCallback(
    (e) => {
      e.stopPropagation();
      removeGroup();
    },
    [removeGroup],
  );
  const onClickIconEdit = useCallback(
    (e) => {
      e.stopPropagation();
      editItem();
    },
    [editItem],
  );
  return (
    <>
      <Styled.LabelGroupWithIcon
        onClick={onGroupClick}
      >
        <Styled.InfoContainer
          isExpanded={isExpanded}
        >
          <OpenArrowButton isOpen={isExpanded} />
          {Icon && (
            <IconButton height={20} Icon={Icon} />
          )}
          <Styled.TextContainer>
            <Text withEllipsis={true}>{text}</Text>
          </Styled.TextContainer>
        </Styled.InfoContainer>
        <Styled.ButtonContainer>
          <ElementTooltip
            text={tooltipTextDeleteButton}
            position={'top'}
            speed={'s'}
            wordBreakAll={true}
          >
            <DeleteButton onClick={onClickIconClose} />
          </ElementTooltip>
          {addItemToGroup && (
            <ElementTooltip
              text={tooltipTextAddButton}
              position={'top'}
              speed={'s'}
              wordBreakAll={true}
            >
              <Styled.RoundButtonContainer>
                <RoundButton Icon={PlusSmall} onClick={addLabelGroup} />
              </Styled.RoundButtonContainer>
            </ElementTooltip>
          )}
          {editItem && (
            <ElementTooltip
              text={tooltipTextEditButton}
              position={'top'}
              speed={'s'}
              wordBreakAll={true}
            >
              <EditButton onClick={onClickIconEdit} />
            </ElementTooltip>
          )}
        </Styled.ButtonContainer>
      </Styled.LabelGroupWithIcon>
      {addButtonContextMenu}
    </>
  );
};

export const LabelGroup = React.memo(LabelGroupComponent);
