import * as Ag from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import autobind from 'autobind-decorator';
import { merge } from 'lodash';
import * as React from 'react';

import './tree-table.scss';

import { ConstantFunctions } from 'common/constants/functions';
import { AgGridDataTransferExporter, AgGridDataTransferImporter } from 'common/utils/ag-grid-data-transporter';

// move to QTO
import { TableApi } from '../../../units/projects/components/quantity-take-off-left-panel/interfaces';
import { PropertyHelper } from '../../../units/projects/utils/quantity-take-off-tree-table';
import { AgGridHelper } from '../../ag-grid';
import { TIME_LAG_BEFORE_GROUP_EXPAND } from './constants';
import { DefaultRowDragHelper, DragType } from './default-row-drag-helper';
import {
  TreeTableColumn,
  TreeTableColumnsData,
  TreeTableData,
  TreeTableGroupRules,
  TreeTableRow,
  TreeTableRowType,
  TreeTableValueGetterParams,
  TreeTableValueSetterParams,
} from './interfaces';
import { RowDragHelper } from './row-drag-helper';
import { SelectionHelper } from './selection-helper';
import { TreeTableCell } from './tree-table-cell';
import { TreeTableCellName } from './tree-table-cell-name';
import { TreeTableColumnController } from './tree-table-column-controller';
import { TreeTableColumnHeader } from './tree-table-column-header';
import { RowChangesCallbacks, TreeTableRowController } from './tree-table-row-controller';
import { TreeToFlatArrayHelper } from './tree-to-flat-array-helper';


const MIN_COLUMN_WIDTH = 60;

type OpenApproveDialogType = (onApprove: () => void, onCancel?: () => void) => void;

export interface ApproveDialogs {
  openImportDialog?: OpenApproveDialogType;
  openDropDialog?: OpenApproveDialogType;
  openImportDisableDialog?: OpenApproveDialogType;
}

interface Props<TRow, TColumn> extends RowChangesCallbacks<TRow> {
  dataImporter?: AgGridDataTransferImporter<any>;
  dataExporters?: Array<AgGridDataTransferExporter<any>>;
  withSideBar?: boolean;
  noStyling?: boolean;
  rowDrag: boolean;
  tableId: string;
  treeTableData: TreeTableData<TRow, TColumn>;
  groupRules: TreeTableGroupRules;
  groupedColumnKey: string;
  overrideGridOptions?: Partial<Ag.GridOptions>;
  defaultColumnsDef?: Partial<Ag.ColDef | Ag.ColGroupDef>;
  context?: any;
  approveDialogs?: ApproveDialogs;
  rowDragHelper?: RowDragHelper;
  onColumnNameChanged?: (colId: string, name: string) => void;
  onRowNameChanged?: (row: Ag.RowNode, name: string) => void;
  getColumnHeaderDisplayName?: (displayName: string, column: Ag.Column) => string;
  cellValueGetter: (params: TreeTableValueGetterParams) => any;
  cellValueSetter?: (params: TreeTableValueSetterParams<TRow>) => boolean;
  getColumnDef?: (column: TreeTableColumn<TColumn>) => Partial<Ag.ColDef | Ag.ColGroupDef>;
  getColumnProperties?: (column: Ag.Column) => Record<string, TColumn>;
  onColumnUpdate?: (columnsState: TreeTableColumnsData<TColumn>) => void;
  onGridReady?: (tableRef: TreeTable<TRow, TColumn>) => void;
  getRowNodeId?: (node: Ag.RowNode) => string;
  onSelectionChange?: (ids: Array<string | number>) => void;
  sendTableSelectionApi?: (ref: TableApi) => void;
}

interface OwnState {
  childToParentMap: Record<string, string>;
}

export class TreeTable<TRow, TColumn> extends React.Component<Props<TRow, TColumn>, OwnState> {
  public columnController: TreeTableColumnController<TColumn>;
  public rowController: TreeTableRowController<TRow>;

  public gridApi: Ag.GridApi;
  public gridColumnApi: Ag.ColumnApi;

  private isExporting: boolean = false;
  private rowDragHelper: RowDragHelper;
  private agGridProps: Partial<AgGridReactProps>;
  private selectionHelper: SelectionHelper;

  constructor(props: Props<TRow, TColumn>) {
    super(props);
    this.rowDragHelper = props.rowDragHelper
      ? props.rowDragHelper
      : new DefaultRowDragHelper(TIME_LAG_BEFORE_GROUP_EXPAND, this.props.groupRules);
    this.agGridProps = merge(this.getDefaultGridProps(), props.overrideGridOptions);
    this.state = {
      childToParentMap: TreeTable.getChildToParentMap(this.props.treeTableData.rows),
    };

    this.selectionHelper = new SelectionHelper(
      this.props.onSelectionChange,
      this.props.getRowNodeId ? this.props.getRowNodeId : node => node.id,
    );
    if (this.props.sendTableSelectionApi) {
      this.props.sendTableSelectionApi({
        setSelected: this.selectionHelper.setSelected,
        getElementIds: this.getElementIds,
      });
    }
  }

  public static getDerivedStateFromProps(props: Props<any, any>): OwnState {
    const rows = props.treeTableData.rows;
    return { childToParentMap: TreeTable.getChildToParentMap(rows) };
  }

  public componentDidUpdate(): void {
    if (!this.gridApi) {
      return;
    }

    const rootNode = AgGridHelper.getRootNode(this.gridApi);
    rootNode.data.children = this.props.treeTableData.firstLevelRows;
    rootNode.setData(rootNode.data);
  }

  public render(): JSX.Element {
    const hasDataImporter = this.props.dataImporter;
    const onImportDragOver = hasDataImporter ? this.onImportDragOver : null;
    const onImportDragLeave = hasDataImporter ? this.onImportDragLeave : null;
    const onImportDrop = hasDataImporter ? this.onImportDrop : null;
    const onMouseEnter = hasDataImporter ? this.onMouseEnter : null;
    const classNames = this.props.noStyling ? null : 'ag-theme-balham ag-theme-balham--kreo tree-table';

    return (
      <div
        id={this.props.tableId}
        className={classNames}
        onDragOver={onImportDragOver}
        onDragLeave={onImportDragLeave}
        onDrop={onImportDrop}
        onMouseEnter={onMouseEnter}
      >
        <AgGridReact
          columnDefs={this.getColumnDefs(this.props.treeTableData)}
          rowData={this.props.treeTableData.rows}
          treeData={true}
          animateRows={true}
          suppressClipboardPaste={true}
          getDataPath={this.getDataPath}
          getRowNodeId={this.getRowNodeId}
          onGridReady={this.onGridReady}
          getMainMenuItems={this.defaultGetMainMenuItems}
          enableRangeSelection={true}
          onRowDragEnter={this.onRowDragEnter}
          onRowDragMove={this.onRowDragMove}
          onRowDragLeave={this.onRowDragLeave}
          onRowDragEnd={this.onRowDragEnd}
          onDragStarted={this.onDragStarted}
          onCellEditingStarted={this.onRowEditingStarted}
          onCellEditingStopped={this.onCellEditingEnded}
          suppressCopyRowsToClipboard={true}

          // Я бы добавил типизацию для context
          context={this.props.context}
          {...this.getSelectionOptions()}
          {...this.agGridProps}
        />
      </div>
    );
  }

  private static getChildToParentMap(rows: Array<TreeTableRow<any>>): Record<string, string> {
    const result = {};
    if (rows) {
      rows.forEach(row => {
        if (row.children) {
          row.children.forEach(childId => result[childId] = row.id);
        }
      });
    }

    return result;
  }

  private defaultGetMainMenuItems(): string[] {
    return [];
  }

  @autobind
  private onRowEditingStarted(event: Ag.CellEditingStartedEvent): void {
    event.context.highlight.set(event);
  }

  @autobind
  private onCellEditingEnded(event: Ag.CellEditingStoppedEvent): void {
    event.context.highlight.reset(event);
  }

  @autobind
  private onImportDragOver(event: React.DragEvent<HTMLDivElement>): void {
    this.isExporting = true;
    this.props.dataImporter.setDropEffect(event, this.gridApi);
  }

  @autobind
  private onImportDragLeave(): void {
    this.props.dataImporter.resetDropEffect();
  }

  @autobind
  private onImportDrop(event: React.DragEvent<HTMLDivElement>): void {
    this.isExporting = false;
    const approveDialogs = this.props.approveDialogs;
    if (approveDialogs && approveDialogs.openImportDialog) {
      event.persist();
      approveDialogs.openImportDialog(
        () => {
          this.props.dataImporter.unBlock();
          this.props.dataImporter.onImport(event, this.gridApi);
        },
      );
    } else {
      this.props.dataImporter.onImport(event, this.gridApi);
    }
  }

  @autobind
  private onMouseEnter(): void {
    if (!this.isExporting) {
      return;
    }

    const approveDialogs = this.props.approveDialogs;
    if (approveDialogs && approveDialogs.openImportDisableDialog) {
      approveDialogs.openImportDisableDialog(ConstantFunctions.doNothing);
    }

    this.isExporting = false;
  }

  @autobind
  private getDataPath(data: TreeTableRow<TRow>): string[] {
    if (this.gridApi) {
      return this.getDataPathForReadyTable(data);
    } else {
      const path = this.getDataPathForNotReadyTable(data);
      return path;
    }
  }

  @autobind
  private getDataPathForReadyTable(data: TreeTableRow<TRow>): string[] {
    if (data.parentId) {

      const parentData = this.gridApi.getRowNode(data.parentId).data;
      return this.getDataPathForReadyTable(parentData).concat(data.id);
    } else {
      return [data.id];
    }
  }

  @autobind
  private getDataPathForNotReadyTable(data: { id: string, parentId?: string }): string[] {
    if (!data.parentId) {
      return [data.id];
    }

    return this.getDataPathForNotReadyTable({
      id: data.parentId,
      parentId: this.state.childToParentMap[data.parentId],
    }).concat(data.id);
  }

  private getRowNodeId(data: TreeTableRow<TRow>): string {
    return data && data.id;
  }

  @autobind
  private onDragStarted(): void {
    this.rowDragHelper.onDragStart();
  }

  @autobind
  private onRowDragEnter(event: Ag.RowDragEvent): void {
    this.rowDragHelper.setDraggableNode(event.node);
  }

  @autobind
  private onRowDragMove(event: Ag.RowDragEvent): void {
    this.rowDragHelper.setOverNode(event.api, event.overNode);
  }

  @autobind
  private onRowDragLeave(event: Ag.RowDragEvent): void {
    this.rowDragHelper.reset(event.api);
  }

  @autobind
  private isSkipEventHandler(event: Ag.RowDragEvent): boolean {
    const dragNode = event.node;
    const overNode = this.rowDragHelper.getOverNode();
    return !!overNode && !!dragNode && overNode.id !== dragNode.id;
  }

  @autobind
  private onRowDragEnd(event: Ag.RowDragEvent): void {
    const dragNode = event.node;
    const isExecuteEvent = this.isSkipEventHandler(event);

    if (isExecuteEvent) {
      this.rowDragHelper.freezeDragType();
      const approveDialogs = this.props.approveDialogs;
      if (approveDialogs && approveDialogs.openDropDialog) {
        approveDialogs.openDropDialog(
          () => this.rowDragHelper.moveNode(this.gridApi, dragNode, this.props.onReorderRows),
          () => this.rowDragHelper.reset(event.api),
        );
      } else {
        this.rowDragHelper.moveNode(this.gridApi, dragNode, this.props.onReorderRows);
      }
    }
    this.rowDragHelper.onDragEnd();
  }

  @autobind
  private onGridReady(params: Ag.GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.selectionHelper.setApi(this.gridApi);
    AgGridHelper.getRootNode(this.gridApi).setData({
      id: AgGridHelper.constants.ROOT_NODE_ID,
      children: this.props.treeTableData.firstLevelRows,
      type: TreeTableRowType.Group,
    });

    this.columnController = new TreeTableColumnController(
      this.gridApi,
      this.gridColumnApi,
      this.getColumnDefs,
      this.props.getColumnProperties,
    );
    this.rowController = new TreeTableRowController(this.gridApi, this.props);

    this.addEventListeners();

    if (this.props.onGridReady) {
      this.props.onGridReady(this);
    }
  }

  // move to props
  @autobind
  private getElementIds(): { nodeIds: Record<string, boolean>, duplicatedNodeIds: Record<string, boolean> } {
    const nodeIds = {};
    const duplicatedNodeIds = {};
    const addedNodeId = {};
    if (this.gridApi) {
      this.gridApi.forEachNode(node => {
        const id = node.data.properties.bimElementId && node.data.properties.bimElementId.default;
        if (id !== null && id !== undefined) {
          if (!addedNodeId[id]) {
            nodeIds[id] = true;
            addedNodeId[id] = true;
          } else {
            duplicatedNodeIds[id] = true;
          }
        }
      });
    }

    return { nodeIds, duplicatedNodeIds };
  }

  private addEventListeners(): void {
    this.gridApi.addEventListener(Ag.Events.EVENT_COLUMN_EVERYTHING_CHANGED, this.onColumnsUpdate);
    this.gridApi.addEventListener(Ag.Events.EVENT_NEW_COLUMNS_LOADED, () => this.onColumnsUpdate);
    this.gridApi.addEventListener(Ag.Events.EVENT_COLUMN_ROW_GROUP_CHANGED, this.onColumnsUpdate);
    this.gridApi.addEventListener(Ag.Events.EVENT_COLUMN_PIVOT_CHANGED, this.onColumnsUpdate);
    this.gridApi.addEventListener(Ag.Events.EVENT_GRID_COLUMNS_CHANGED, this.onColumnsUpdate);
    this.gridApi.addEventListener(Ag.Events.EVENT_COLUMN_VALUE_CHANGED, this.onColumnsUpdate);
    this.gridApi.addEventListener(Ag.Events.EVENT_COLUMN_MOVED, this.onColumnsUpdate);
    this.gridApi.addEventListener(Ag.Events.EVENT_COLUMN_VISIBLE, this.onColumnsUpdate);
    this.gridApi.addEventListener(Ag.Events.EVENT_COLUMN_PINNED, this.onColumnsUpdate);
    this.gridApi.addEventListener(Ag.Events.EVENT_COLUMN_RESIZED, this.onColumnsUpdate);
  }

  @autobind
  private onColumnsUpdate(): void {
    const updateModel = this.columnController.getColumnModel();
    this.props.onColumnUpdate(updateModel);
  }

  private getDefaultGridProps(): Partial<AgGridReactProps> {
    return {
      defaultColGroupDef: {
        children: [],
        marryChildren: true,
      },
      defaultColDef: {
        width: 235,
        resizable: true,
      },
      sideBar: this.getSideBarGridProps(),
      autoGroupColumnDef: this.getAutoGroupColumnDef(),
      onSortChanged: this.onSortChanged,
    };
  }

  private getAutoGroupColumnDef(): Ag.ColDef {
    const hasDataExporter = !!this.props.dataExporters;
    const cellClassRules = {
      'ag-cell--group': (params) => {
        return this.props.groupRules.isGroup(params.node);
      },
      'ag-cell--insert-into': (params) => {
        return this.rowDragHelper.getDragType(params.node) === DragType.InsertInto;
      },
      'ag-cell--insert-after': (params) => {
        return this.rowDragHelper.getDragType(params.node) === DragType.InsertAfter;
      },
    };


    return {
      rowDrag: this.props.rowDrag,
      headerName: 'Name',
      width: 250,
      dndSource: hasDataExporter,
      dndSourceOnRowDrag: hasDataExporter ? this.dndSourceOnRowDrag : null,
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: TreeTableCellName,
        onRename: this.props.onRowNameChanged,
        checkbox: !!this.props.onSelectionChange,
      },
      valueGetter: this.getRowName,
      cellClassRules,
      pinned: 'left',
      minWidth: MIN_COLUMN_WIDTH,
    };
  }

  @autobind
  private onSortChanged(event: Ag.SortChangedEvent): void {
    const { api } = event;

    const model = api.getSortModel()[0];
    const table = document.getElementById(this.props.tableId);
    table.querySelectorAll('#sort-icon').forEach(x => x.className = 'icon-hidden');
    const iconSort = model && table.querySelector(`div[col-id='${model.colId}'] #sort-icon`);
    if (iconSort) {
      iconSort.className = model.sort;
    }
  }

  @autobind
  private dndSourceOnRowDrag(params: { rowNode: Ag.RowNode, dragEvent: DragEvent }): void {
    this.props.dataExporters.forEach(exporter => {
      exporter.setExportData(params.rowNode, this.gridApi, params.dragEvent);
    });
  }

  private getSideBarGridProps(): Ag.SideBarDef | null {
    if (!this.props.withSideBar) {
      return null;
    }

    return {
      toolPanels: [{
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressRowGroups: true,
        },
      }],
      defaultToolPanel: 'columns',
    };
  }

  private getSelectionOptions(): Partial<AgGridReactProps> {
    if (this.props.onSelectionChange) {
      return {
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        onRowSelected: this.selectionHelper.rowSelectionHandler,
      };
    }
    return {};
  }


  // Получается, что тут дефолтная Qto колонка описанна.
  @autobind
  private getColumnDefs(columnsData: TreeTableColumnsData<TColumn>): Array<Ag.ColDef | Ag.ColGroupDef> {
    const getColumnDef = this.props.getColumnDef
      ? this.props.getColumnDef
      : () => ({});

    // move to QTO
    const cellClassRules = {
      'ag-cell--insert-into': (params) => {
        return this.rowDragHelper.getDragType(params.node) === DragType.InsertInto;
      },
      'ag-cell--insert-after': (params) => {
        return this.rowDragHelper.getDragType(params.node) === DragType.InsertAfter;
      },
      'ag-cell--group': (params) => {
        return this.props.groupRules.isGroup(params.node);
      },
      'ag-cell--error': (params) => {
        const values = params.data.properties && params.data.properties[params.colDef.colId];
        return values && !values.override && !values.default && !params.value && params.value !== 0;
      },
      'ag-cell--edited': (params: Ag.ICellRendererParams) => {
        const property = params.data.properties && params.data.properties[params.colDef.colId];
        return PropertyHelper.isOverrideValue(property);
      },
    };

    const cellStyle = (params): any => {
      if (params.context.highlight) {
        return params.context.highlight.getStyle(params);
      }
    };

    const isEditable = !!this.props.cellValueSetter;
    const defaultColumnsDef = {
      cellClassRules,
      cellStyle,
      cellRenderer: TreeTableCell,
      headerComponent: TreeTableColumnHeader,
      headerComponentParams: {
        onRename: this.props.onColumnNameChanged,
        getColumnDisplayName: this.props.getColumnHeaderDisplayName,
      },
      minWidth: MIN_COLUMN_WIDTH,
      valueGetter: this.valueGetter,
      valueSetter: isEditable ? this.valueSetter : undefined,
      ...(this.props.defaultColumnsDef || {}),
      editable: isEditable,
    };

    return TreeToFlatArrayHelper.treeColumnsToFlatArray(
      columnsData,
      defaultColumnsDef,
      getColumnDef,
    );
  }

  // Под any прячется QtoRowP
  @autobind
  private valueGetter(params: Ag.ValueGetterParams): any {
    return this.props.cellValueGetter({
      columnApi: params.columnApi,
      columnId: params.column.getColId(),
      node: params.node,
      api: params.api,
      context: params.context,
    });
  }

  @autobind
  private valueSetter(params: Ag.ValueSetterParams): any {
    return this.props.cellValueSetter({
      columnId: params.column.getColId(),
      data: params.data,
      newValue: params.newValue,
    });
  }

  @autobind
  private getRowName(params: Ag.ValueGetterParams): any {
    return this.props.cellValueGetter({
      columnId: this.props.groupedColumnKey,
      node: params.node,
      columnApi: params.columnApi,
      api: params.api,
      context: params.context,
    });
  }
}
