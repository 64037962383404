import Slider from '@material-ui/lab/Slider';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './app-slider.scss';

interface SliderProps {
  /**
   * @description Normalized ([0, 1]) value.
   */
  value: number;
  /**
   * @param value Normalized ([0, 1]) value.
   */
  onChange: (value: number) => void;
  className?: string;
  label?: string;
}

const maxValue = 100;

export class AppSlider extends React.Component<SliderProps> {
  public render(): React.ReactNode {
    const containerClassName = classNames('app-slider__container', this.props.className);

    return (
      <Slider
        classes={{
          container: containerClassName,
          trackBefore: 'app-slider__track-before',
          trackAfter: 'app-slider__track-after',
          thumb: 'app-slider__thumb',
          activated: 'activated',
          track: 'app-slider__track',
        }}
        min={0}
        value={this.props.value * maxValue}
        max={maxValue}
        onChange={this.onChange}
      />
    );
  }

  @autobind
  private onChange(_event: any, value: number): void {
    const normalizedValue = value / maxValue;
    this.props.onChange(normalizedValue);
  }
}
