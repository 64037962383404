import autobind from 'autobind-decorator';
import * as React from 'react';

import { MultilevelSelectOptionDataWithObjectValue } from 'common/components/multi-level-drop-down-menu';
import { MoveToCellOption } from '../drawings-context-menu-renderers';
import { MoveToCellOptionData } from '../interfaces/options';
import { DrawingsSelectType } from './drawings-select-type';

interface Props {
  getMeasures: () => Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>;
  moveToCell: (type: string) => void;
  currentColor: string;
  isImperial: boolean;
}

export class DrawingsFlyingMeasureSelect extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <DrawingsSelectType<MoveToCellOptionData>
        optionRenderer={this.moveToGroupOptionRenderer}
        onSelect={this.moveToCell}
        options={this.props.getMeasures()}
      />
    );
  }

  @autobind
  private moveToGroupOptionRenderer(
    option: MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>,
  ): React.ReactNode {
    return (<MoveToCellOption option={option} color={this.props.currentColor} isImperial={this.props.isImperial}/>);
  }

  @autobind
  private moveToCell(value: MoveToCellOptionData): void {
    this.props.moveToCell(value.type);
  }
}
