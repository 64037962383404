
// todo эта хрень необходима только для тестирования

import * as React from 'react';
import { FormulaFormIssues, FormulaInputValidationStatus } from '../input';
import { Styled } from './styled';

interface Props {
  validationStatus: FormulaInputValidationStatus;
  issues?: FormulaFormIssues;
}

function makeProblemsText(problems: FormulaFormIssues): string {
  if (problems) {
    const { errors, warnings } = problems;
    return `Errors ${errors.length} , Warnings ${warnings.length}`;
  }  else {
    return 'In Progress';
  }
}

export const FormulaFieldStatus: React.FC<Props> = (props) => {
  const { validationStatus, issues } = props;
  const text = validationStatus === FormulaInputValidationStatus.Ok ? 'The formula is valid' : makeProblemsText(issues);
  return (
    <Styled.Container status={validationStatus}>
      <span>
        {text}
      </span>
    </Styled.Container>
  );
};
