import { Icons, Input } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { CreationDialog } from '../../../../components/dialog/creation-dialog';
import { AnalyticsProps, withAnalyticsContext } from '../../../../utils/posthog/analytics-wraper';
import { MetricNames } from '../../../../utils/posthog/metric-names';
import { ProjectsFolder, ProjectsFolderFormData } from '../../../projects/interfaces/projects-folder';

interface ComponentState {
  name: string;
}

interface Props extends AnalyticsProps {
  onFormSubmit: (id: number, data: ProjectsFolderFormData) => void;
  onCloseDialog: () => void;
}

export interface FolderData {
  id: number;
  name: string;
  parentFolderId: number;
  parentFolder: ProjectsFolder;
}

export const CREATE_FOLDER_DIALOG_NAME = 'createFolderDialogName';

class CreateFolderDialogComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = { name: '' };
  }

  public render(): React.ReactNode {
    return (
      <DialogWrapper name={CREATE_FOLDER_DIALOG_NAME}>
        <CreationDialog
          name={CREATE_FOLDER_DIALOG_NAME}
          title={'Folder'}
          icon={Icons.Group_2}
          tooltipText={'Folder name cannot be empty'}
          submitDisabled={!this.state.name.trim()}
          setInitialState={this.setInitialState}
          onSubmit={this.onSubmit}
          onCloseDialog={this.props.onCloseDialog}
        >
          <Input
            width='100%'
            type='text'
            text={'Folder name'}
            input={{
              value: this.state.name,
              onChange: this.onChangeName,
            }}
            autoFocus={true}
          />
        </CreationDialog>
      </DialogWrapper>
    );
  }

  @autobind
  private setInitialState(data: FolderData, editMode: boolean): void {
    const state = {
      name: editMode ? data.name : '',
    };
    this.setState(state);
  }

  @autobind
  private onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ name: event.target.value });
  }

  @autobind
  private onSubmit(data: FolderData, editMode: boolean): void {
    const { name } = this.state;
    if (!editMode || data.name !== name) {
      this.props.onFormSubmit(
        editMode ? data.id : null,
        {
          name,
          parentFolderId: editMode ? data.parentFolderId : (data.parentFolder ? data.parentFolder.id : null),
        });
    }
    this.props.onCloseDialog();
    this.props.sendEvent(MetricNames.projects.createFolder, { folderName: name });
  }
}

export const CreateFolderDialog = withAnalyticsContext(CreateFolderDialogComponent);
