import { RoundSwitcher } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { DrawingStrokeStyles, DrawingStylesSwitcherConfig } from 'common/components/drawings/constants/drawing-styles';
import { Styled } from './styled';

interface Props {
  setStrokeStyle: (value: DrawingStrokeStyles) => void;
  strokeStyle: DrawingStrokeStyles;
  elementWidth?: number;
}

export const DrawingsStrokeStyleSwitcher = ({
  strokeStyle,
  elementWidth = 55,
  setStrokeStyle,
}: Props): JSX.Element => {
  return (
    <Styled.Container>
      <RoundSwitcher
        modes={DrawingStylesSwitcherConfig}
        currentMode={strokeStyle}
        toggleMode={setStrokeStyle}
        elementWidth={elementWidth}
      />
    </Styled.Container>
  );
};
