import * as React from 'react';


export default class KreoArrowIcon extends React.Component {
  public render(): JSX.Element {
    return (
      <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='icon_download-file' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <rect id='Rectangle-7' x='0' y='0' width='14' height='14' />
          <g
            id='Group-8'
            transform='translate(4.000000, 3.000000)'
            stroke='#7D786D'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <polyline id='Rectangle-5' points='6 5 3 8 0 5' />
            <path d='M3,-9.09494702e-13 L3,7.5' id='Path-2' />
          </g>
        </g>
      </svg>);
  }
}
