import * as React from 'react';

import { Styled } from './styled';

interface Props {
  horizontal: boolean;
  enabled: boolean;
  Icon: React.ComponentType;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export function IcicleButton(props: Props): JSX.Element {
  const { enabled, onClick, Icon, horizontal } = props;
  return (
    <Styled.Container enabled={enabled} horizontal={horizontal}>
      <Styled.ButtonContainer>
        <Styled.Button onClick={onClick}>
          <Icon/>
        </Styled.Button>
      </Styled.ButtonContainer>
    </Styled.Container>
  );
}
