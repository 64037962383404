import { GENERAL_COLORS } from '@kreo/kreo-ui-components/constants/colors';

export interface ItemData {
  id: string;
  name: string;
  shouldUpgradePlan: boolean;
  color: string;
  postfix: JSX.Element;
}

export interface ContextMenuProps<T extends ItemData> {
  item: T;
  itemList: T[];
  index: number;
  closeContextMenu: () => void;
  editItemName: () => void;
}

export const DEFAULT_COLOR = GENERAL_COLORS.gray;
