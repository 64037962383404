import { IconButton, Icons, Switcher, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { DrawingsRendererApiContextProps, withRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { KreoDialogActions } from 'common/UIKit';
import { BillingPeriodUnit } from '../../../../../../units/subscription/enums/billing-period-unit';
import { SubscriptionAddon } from '../../../../interfaces/subscription-addon';
import { PriceField } from '../../../price-field';
import { AddonAdvDialogName } from '../addon-list';
import { AddonAdvDialogs } from './addon-adv-dialogs';
import { Styled } from './styled';

interface DispatchProps {
  openAdvDialog: () => void;
}

interface OwnProps {
  addon: SubscriptionAddon;
  isSelected: boolean;
  isTeamPlan: boolean;
  advDialogName: AddonAdvDialogName;
  toggleSelection: (id: string, isSelected: boolean) => void;
  billingPeriod: BillingPeriodUnit;
  fontWeight?: string;
  switcherSize?: string;
  disable?: boolean;
}

interface Props extends DispatchProps, OwnProps, DrawingsRendererApiContextProps {}

class AddonComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      addon,
      isSelected,
      openAdvDialog,
      advDialogName,
      fontWeight,
      switcherSize,
      disable,
    } = this.props;
    return (
      <Styled.AddonItem key={addon.id}>
        <Styled.AddonGroupName isSelected={isSelected}>
          <Switcher
            checked={isSelected}
            onChange={this.toggleSelection}
            size={switcherSize ? switcherSize : 'm'}
            disable={disable}
          />
          <Text fontSize={16} color='mainFont'>{addon.groupName}</Text>
        </Styled.AddonGroupName>
        <Styled.CostAddonAndIconButtonWrapper>
          <Styled.CostAddon>
            <Styled.PriceField fontWeight={fontWeight}>
              <Text fontSize={12} color='mainFont'>{'+'}&nbsp;</Text>
              <PriceField GBPPrice={this.getPrice()}/>
            </Styled.PriceField>
            <Text fontSize={12} color='mainFont'>&nbsp;{this.getPriceText()}</Text>
          </Styled.CostAddon>
          {advDialogName &&
            <Styled.AdvDialogButton>
              <IconButton
                Icon={Icons.Question}
                onClick={openAdvDialog}
                height={40}
              />
              <AddonAdvDialogs
                dialogName={advDialogName}
                onConfirm={this.onConfirm}
              />
            </Styled.AdvDialogButton>
          }
        </Styled.CostAddonAndIconButtonWrapper>
      </Styled.AddonItem>
    );
  }

  @autobind
  private toggleSelection(): void {
    const { addon, isSelected } = this.props;
    this.props.toggleSelection(addon.id, !isSelected);
  }

  @autobind
  private onConfirm(): void {
    this.props.toggleSelection(this.props.addon.id, true);
  }

  @autobind
  private getPrice(): number {
    const { addon, billingPeriod } = this.props;
    const priceMonth = addon.price / 100;
    const priceYear = Math.ceil(addon.price / 100 / 12);
    return billingPeriod === BillingPeriodUnit.Year ? priceYear : priceMonth;
  }

  @autobind
  private getPriceText(): string {
    const { isTeamPlan } = this.props;
    const perUser = isTeamPlan ? 'per user, per month' : 'per month';
    return perUser;
  }
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
  return {
    openAdvDialog: () => dispatch(KreoDialogActions.openDialog(ownProps.advDialogName)),
  };
}

export const Addon = connect(null, mapDispatchToProps)(withRendererApiContext(AddonComponent));
