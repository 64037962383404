import { EventChannel, SagaIterator } from 'redux-saga';
import { call, fork, put, take, takeLatest } from 'redux-saga/effects';

import { ProgressActions, ProgressBarType } from 'common/components/progress';
import { ActionWith } from 'common/interfaces/action-with';
import { createSagaEventsChannel, selectWrapper } from 'common/utils/saga-wrappers';
import { ExportSettingsActions } from '../actions/creators/export-settings';
import { ExportPdfPayload } from '../actions/payloads/common';
import { ExportSettingsActionTypes } from '../actions/types/export-settings';
import { DrawingsApi } from '../api/drawings-api';
import { DrawingsExportConstants } from '../constants/export-constants';
import { ProgressUtils } from '../utils';


interface WatchProps {
  channel: EventChannel<any>;
}

function* watchOnProgress({ channel }: WatchProps): SagaIterator {
  while (true) {
    try {
      const data = yield take(channel);
      const progressKey = ProgressUtils.createCurrentProjectExportKey(data.projectId);
      if (yield selectWrapper(s => s.projects.currentProject?.id !== data.projectId)) {
        yield put(ProgressActions.removeProgress(progressKey));
        break;
      }
      if (data.event.lengthComputable) {
        yield put(ProgressActions.addOrUpdateProgress({
          progressKey,
          progressBar: {
            progressTitle: 'Downloading...',
            finished: data.event.loaded,
            total: data.event.total,
            type: ProgressBarType.Count,
          },
        }));
      }
    } catch (e) {
      console.error('drawings: watchOnProgress failed', e);
    }
  }
}


function* exportPdf({ payload }: ActionWith<ExportPdfPayload>): SagaIterator {
  yield put(ExportSettingsActions.setLoadingExport(true));
  try {
    const {
      showLabel,
      exportAnnotations,
      fontSize,
      lineThicknessFactor,
      segmentLengthToLabel,
      segmentLengthLabelToLayer,
    } = yield selectWrapper(s => {
      return {
        showLabel: s.persistedStorage.drawingsShowLabel,
        exportAnnotations: s.drawings.exportSettings.exportAnnotations,
        fontSize: s.persistedStorage.drawingExportFontSize || DrawingsExportConstants.DEFAULT_FONT_SIZE,
        lineThicknessFactor: s.persistedStorage.drawingExportLineThickness
          || DrawingsExportConstants.DEFAULT_LINE_THICKNESS,
        segmentLengthToLabel: s.drawings.exportSettings.segmentLength,
        segmentLengthLabelToLayer: s.drawings.exportSettings.segmentLengthOnOtherLayer,
      };
    });
    const { projectId, pdfId, currentFileOnly } = payload;
    const requestPayload = {
      ...payload.requestPayload,
      nameToLabel: showLabel,
      perimeterToLabel: showLabel,
      areaToLabel: showLabel,
      useRulers: false,
      useStickers: false,
      fontSize,
      lineThicknessFactor,
      segmentLengthToLabel,
      segmentLengthLabelToLayer,
    };

    for (const annotationKey of exportAnnotations) {
      requestPayload[annotationKey] = true;
    }
    let listener: (input: any) => void;
    const channel = createSagaEventsChannel((emitter) => (listener = emitter));
    yield fork<(props: WatchProps) => SagaIterator>(watchOnProgress, { channel });
    if (currentFileOnly) {
      yield call(
        DrawingsApi.exportPdfFile,
        projectId,
        pdfId,
        requestPayload,
        (progress) => listener({ event: progress, projectId }),
      );
    } else {
      yield call(
        DrawingsApi.exportAndMergePdfPages,
        projectId,
        requestPayload,
        (progress) => listener({ event: progress, projectId }),
      );
    }
    const progressKey = ProgressUtils.createCurrentProjectExportKey(projectId);
    yield put(ProgressActions.removeProgress(progressKey));
  } catch (error) {
    console.error('drawings: export PDF failed', error, payload);
  } finally {
    yield put(ExportSettingsActions.setLoadingExport(false));
  }
}

export function* drawingsExportSaga(): SagaIterator {
  yield takeLatest(ExportSettingsActionTypes.EXPORT_PDF, exportPdf);
}
