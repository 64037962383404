import React from 'react';

export const useProjectIdRef = (projectId: number): React.MutableRefObject<number> => {
  const ref = React.useRef(projectId);
  React.useEffect(() => {
    ref.current = projectId;
  }, [projectId]);

  return ref;
};
