import React from 'react';

import { Fields } from 'unit-2d-database/components/side-panel/components/group-properties-list';
import { OverrideConsumer } from 'unit-2d-database/components/side-panel/components/override-label/override-consumer';
import {
  FormTextInputField,
  FormButtonField,
  FormNumberInputField,
  FormSingleSelectField,
} from '../form-fields';
import { ButtonInput, InputSelect, NumberInput, TextInput } from '../inputs';
import { wrapMixedSelect } from './mixed-select-with';
import { Styled } from './styled';

const fieldMap = {
  TextInput: wrapMixedSelect(FormTextInputField, TextInput),
  NumberInput: wrapMixedSelect(FormNumberInputField, NumberInput),
  Button: wrapMixedSelect(FormButtonField, ButtonInput),
  SingleSelect: wrapMixedSelect(FormSingleSelectField, InputSelect),
};

interface Props {
  fields: Fields;
  disabled?: boolean;
  getId?: () => string;
}

export const Form: React.FC<Props> = (props) => {
  const { fields, disabled, getId } = props;
  return (
    <Styled.Container>
      {fields.map((field) => {
        const Element = fieldMap[field.type];
        const id = getId ? `${field.name}_${getId()}` : field.name;
        return (
          <Styled.ElementWrapper
            key={field.name}
            hasUnit={!!field.dropDownProps}
          >
            <OverrideConsumer id={id} handleClick={field.onDeleteClick}>
              <Element
                key={field.name}
                input={field.input as any}
                label={field.name}
                isHide={field.isHide}
                onVisibilityClick={field.onVisibilityClick}
                onDeleteClick={field.onDeleteClick}
                dropDownProps={field.dropDownProps}
                disabled={disabled}
                id={id}
              />
            </OverrideConsumer>
          </Styled.ElementWrapper>
        );
      })}
    </Styled.Container>
  );
};
