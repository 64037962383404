import autobind from 'autobind-decorator';

interface Config {
  setSpaceBarHeld: (isSpaceBarHeld: boolean) => void;
}

const SPACE_CODE = 'Space';

export class DrawingsSpacebarHeldHelper {
  private _config: Config;

  private _isActive: boolean = false;

  constructor(config: Config) {
    this._config = config;
  }

  public initEvents(): void {
    if (this._isActive) {
      return;
    }
    document.addEventListener('keydown', this.keyDownListener);
  }


  public disableEvents(): void {
    if (!this._isActive) {
      return;
    }
    document.removeEventListener('keydown', this.keyDownListener);
  }

  @autobind
  private keyDownListener(e: KeyboardEvent): void {
    if (e.code !== SPACE_CODE) {
      return;
    }
    document.addEventListener('keyup', this.keyUpListener);
    this._config.setSpaceBarHeld(true);
  }


  @autobind
  private keyUpListener(e: KeyboardEvent): void {
    if (e.code !== SPACE_CODE) {
      return;
    }
    document.removeEventListener('keyup', this.keyUpListener);
    this._config.setSpaceBarHeld(false);
  }
}
