import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { QuantityTakeOffFilter } from '../actions/types/quantity-take-off-filter';
import { QuantityTakeOffState } from '../interfaces/quantity-take-off/quantity-take-off-state';

export const quantityTakeOffFilterReducerMethods: ReducerMethods<QuantityTakeOffState> = {
  [QuantityTakeOffFilter.CALL_FILTER]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.needToUpdateFilter, !s.needToUpdateFilter)
      .get();
  },
};
