import { MenuItemStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const Container = styled.div`
  position: absolute;
  left: 85px;
  top: 50px;
  z-index: 2;

  ${MenuItemStyled.Item} {
    height: 45px;
  }

  ${MenuItemStyled.Dropdown} {
    width: 220px;
  }
`;

export const Styled = {
  Container,
};
