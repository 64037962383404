export enum DataType {
  Comment = 'Comment',
}

export enum CommentDataAction {
  CreateMessage = 'CreateMessage',
  CreateThread = 'CreateThread',
  ResolveThread = 'ResolveThread',
  OpenThread = 'OpenThread',
  AddToThread = 'AddToThread',
}


export interface CompanyNotificationData {
  type: DataType;
}

export interface CommentData extends CompanyNotificationData {
  type: DataType.Comment;
  action: CommentDataAction;
  messageId: number;
  projectId: number;
  threadId: number;
}

