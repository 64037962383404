import * as React from 'react';

import { Text } from 'common/components/text';
import { Styled } from './styled';


interface Props {
  icon?: React.ComponentType;
  title: string;
  badge?: JSX.Element;
  disabled?: boolean;
}

export const EntityHeader: React.FC<Props> = ({
  badge,
  icon: Icon,
  title,
  disabled,
}) => {
  return (
    <Styled.Container
      hasIcon={!!Icon}
    >
      {Icon ? (
        <Styled.IconContainer disabled={disabled}>
          <Icon />
        </Styled.IconContainer>
      ) : null}
      <Styled.TitleContainer disabled={disabled}>
        <Text
          lineHeight={1}
          fontSize={12}
          fontWeight={500}
          letterSpacing={.5}
          textTransform="uppercase"
        >
          {title}
        </Text>
      </Styled.TitleContainer>
      {badge && badge}
    </Styled.Container>
  );
};
