import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { MenuItemWrapper } from 'common/components/drawings/components';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { DrawModeMenuItem } from '../../draw-mode-menu-item';
import { DrawModes } from './draw-modes';

interface Props {
  createNewGroup: () => void;
  drawGeometry: (drawMode: DrawingsDrawMode) => void;
  onClose: () => void;
}

const CreateNewMenuContentComponent: React.FC<Props> = ({ createNewGroup, drawGeometry, onClose }) => {
  const createGroup = useCallback(() => {
    createNewGroup();
    onClose();
  }, [createNewGroup, onClose]);

  const drawGeometryCallback = useCallback(
    (drawMode: DrawingsDrawMode) => {
      drawGeometry(drawMode);
      onClose();
    },
    [drawGeometry, onClose],
  );

  return (
    <>
      <MenuItemWrapper
        name='New Group in the Group'
        onClick={createGroup}
        icon={Icons.NewGroup2d}
      />
      {
        DrawModes.map(drawMode => {
          return (
            <DrawModeMenuItem
              key={drawMode}
              drawMode={drawMode}
              onClick={drawGeometryCallback}
            />
          );
        })
      }
    </>
  );
};

export const CreateNewMenuContent = React.memo(CreateNewMenuContentComponent);
