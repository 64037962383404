import styled from 'styled-components';

interface ContainerProps {
  disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: ${p => p.disabled ? 'none' : 'auto'};
  & > label {
    margin-bottom: 0;
  }
  &,* {
    box-sizing: border-box;
  }
`;

const Slider = styled.div`
  margin-top: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;
  position: relative;
`;

const SliderProgress = styled.div`
  display: flex;
  align-items: center;
  height: 2px;
  background-color: ${p => p.theme.color.background};
  border-radius: 2px;
`;


interface SliderInputProps {
  min: number;
  max: number;
  value: number;
}

const SliderInput = styled.input<SliderInputProps>`
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;

  &::-webkit-slider-thumb {
    position: absolute;
    left: ${p => ((p.value - p.min) / (p.max - p.min)) * 100}%;
    -webkit-appearance: none;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid ${p => p.theme.color.turquoise};
    background: ${p => p.theme.color.background};
    cursor: col-resize;
    transition: all ${p => p.theme.duration.s} ${p => p.theme.function.l};

    ${Slider}:hover & {
      width: 12px;
      height: 12px;
      background: ${p => p.theme.color.turquoise};
    }
  }
`;

interface ProgressBarProps {
  min: number;
  max: number;
  value: number;
}

const ProgressBar = styled.div<ProgressBarProps>`
  height: 4px;
  width: ${p => ((p.value - p.min) / (p.max - p.min)) * 100}%;
  position: relative;
  background: ${p => p.theme.color.pale};
  border-radius: 2px;
  transition: all ${p => p.theme.duration.s} ${p => p.theme.function.l};

  ${Slider}:hover & {
    background: ${p => p.theme.color.turquoise};
  }
`;

export const Styled = {
  Container,
  Slider,
  SliderProgress,
  SliderInput,
  ProgressBar,
};
