import { useCallback, useMemo, useState } from 'react';

export interface UseInputTextResult {
  onEdit: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  cancelEdit: () => void;
  value: string;
  isValueValid: boolean;
}

const LIMIT = 200;

export function useEdit(defaultValue: string): UseInputTextResult {
  const [ value, setValue ] = useState(defaultValue);
  const onEdit = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value), []);
  const isValid = useMemo(() => value?.trim() && value.length <= LIMIT, [value]);
  const cancelEdit = useCallback(() => setValue(defaultValue), [defaultValue]);

  return {
    onEdit,
    cancelEdit,
    value,
    isValueValid: isValid,
  };
}

