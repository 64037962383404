export enum PropertyFormatEnum {
  Number = 'Number',
  Text = 'Text',
}

export interface BaseFormat {
  readonly type: PropertyFormatEnum;
}

export interface NumericFormat extends BaseFormat {
  unit: string;
}
