import { CursorHint } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { DrawingsDrawMode } from '../../enums/drawings-draw-mode';
import { CursorHintByType } from './cursor-hint-by-type';
import { CursorHintType } from './cursor-hint-type';

interface Props {
  mode: DrawingsDrawMode;
}

const getHintText = (mode: DrawingsDrawMode, cursorHint: CursorHintType): string => {
  if (cursorHint) {
    return CursorHintByType[cursorHint];
  }

  switch (mode) {
    case DrawingsDrawMode.SelectItems:
      return 'Select the area, line or count';
    case DrawingsDrawMode.Legend:
      return 'Specify the insertion point';
    case DrawingsDrawMode.Knife:
      return `Draw a line according to which you'd like to separate the measurements`;
    case DrawingsDrawMode.PdfFilterArea:
      return 'Use rectangle selection to hide styled elements';
    default:
      return '';
  }
};

export const DrawingsSelectItemsCursorHint: React.FC<Props> = ({ mode }) => {
  const cursorHintType = useSelector<State, CursorHintType>(x => x.drawings.cursorHintType);
  const hintText = getHintText(mode, cursorHintType);
  if (!hintText) {
    return null;
  }
  return (
    <CursorHint
      position={'right'}
      hintText={hintText}
      yShift={30}
    />
  );
};
