import autobind from 'autobind-decorator';

import { FilterOption } from 'common/components/filter-select/interfaces';
import { Feed } from 'common/interfaces/feed';
import { ResourceVariantsApi } from '../../api/resource-variants';
import { ResourcesApi } from '../../api/resources';
import { ResourceType } from '../../enums';
import { LaborVariantModel, MaterialModel, PlantVariantModel } from '../../interfaces/resources-data';
import { DatabaseTableColumnFilterProps } from '../database-table/interfaces';


export class DatabaseActivityTableColumnFilterPropsProvider {
  public plantFilterProps: DatabaseTableColumnFilterProps<PlantVariantModel>;
  public materialFilterProps: DatabaseTableColumnFilterProps<MaterialModel>;
  public laborFilterProps: DatabaseTableColumnFilterProps<LaborVariantModel>;
  private databaseId: number;


  constructor(databaseId: number) {
    this.databaseId = databaseId;
    this.plantFilterProps = this.getPlantFilterProps();
    this.materialFilterProps = this.getMaterialFilterProps();
    this.laborFilterProps = this.getLaborFilterProps();
  }

  private getPlantFilterProps(): DatabaseTableColumnFilterProps<PlantVariantModel> {
    return {
      load: this.loadPlants,
      mapToFilterOption: this.mapPlant,
    };
  }

  private getMaterialFilterProps(): DatabaseTableColumnFilterProps<MaterialModel> {
    return {
      load: this.loadMaterials,
      mapToFilterOption: this.mapMaterial,
    };
  }

  private getLaborFilterProps(): DatabaseTableColumnFilterProps<LaborVariantModel> {
    return {
      load: this.loadLabors,
      mapToFilterOption: this.mapLabor,
    };
  }

  @autobind
  private loadPlants(skip: number, take: number, search?: string): Promise<Feed<PlantVariantModel>> {
    return ResourceVariantsApi.getResourcesVariants(
      this.databaseId,
      ResourceType.Plant,
      { skip, take, search },
    ) as Promise<Feed<PlantVariantModel>>;
  }

  @autobind
  private loadMaterials(skip: number, take: number, search?: string): Promise<Feed<MaterialModel>> {
    return ResourcesApi.getResources(
      this.databaseId,
      ResourceType.Material,
      { skip, take, search },
    ) as Promise<Feed<MaterialModel>>;
  }

  @autobind
  private loadLabors(skip: number, take: number, search?: string): Promise<Feed<LaborVariantModel>> {
    return ResourceVariantsApi.getResourcesVariants(
      this.databaseId,
      ResourceType.Labour,
      { skip, take, search },
    ) as Promise<Feed<LaborVariantModel>>;
  }


  private mapPlant(plant: PlantVariantModel): FilterOption {
    return {
      children: undefined,
      isSelectable: true,
      name: `${plant.resourceName} ${plant.name}`,
      value: plant.id,
    };
  }

  private mapLabor(labor: LaborVariantModel): FilterOption {
    return {
      children: undefined,
      isSelectable: true,
      name: `${labor.resourceName} ${labor.name}`,
      value: labor.id,
    };
  }

  private mapMaterial(material: MaterialModel): FilterOption {
    return {
      children: undefined,
      isSelectable: true,
      name: material.name,
      value: material.id,
    };
  }
}
