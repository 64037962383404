import autobind from 'autobind-decorator';
import * as React from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { InteractiveMenuContainer } from './container';
import { Styled } from './container-styled';


interface Props {
  disabled?: boolean;
  renderMenuContent: (close: () => void) => React.ReactNode;
  onOpenStatusChange?: (value: boolean) => void;
  closeOnContainerClick?: boolean;
  marginTop?: number;
}

interface State {
  opened: boolean;
}

export class InteractiveMenu extends React.PureComponent<Props, State> {
  private ref: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  public render(): React.ReactNode {
    const { closeOnContainerClick, children, marginTop, renderMenuContent } = this.props;
    const isClose = closeOnContainerClick === undefined ? this.state.opened : closeOnContainerClick;
    return (
      <Styled.ButtonContainer ref={this.saveRef}>
        <div onClick={this.toggleOpen}>
          {children}
        </div>
        {
          this.state.opened && (
            <InteractiveMenuContainer
              orientation='vertical'
              closeOnContainerClick={isClose}
              parentRect={this.ref.getBoundingClientRect() as DOMRect}
              withBlanket={true}
              onCloseClick={this.toggleOpen}
              marginTop={marginTop}
            >
              {renderMenuContent(this.close)}
            </InteractiveMenuContainer>
          )
        }
      </Styled.ButtonContainer>
    );
  }

  @autobind
  private saveRef(ref: HTMLDivElement): void {
    this.ref = ref;
  }

  @autobind
  private close(): void {
    this.setState(
      { opened: !this.state.opened },
      () => this.props.onOpenStatusChange && this.props.onOpenStatusChange(this.state.opened),
    );
  }

  @autobind
  private toggleOpen(e: React.MouseEvent<HTMLDivElement>): void {
    if (this.props.disabled) {
      return;
    }
    ConstantFunctions.stopEvent(e);
    this.close();
  }
}
