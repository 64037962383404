import * as t from 'io-ts';

import { CompanyResourcesApi } from '../server';

const LayerDecompositionC = t.array(
  t.strict({
    elementId: t.number,
    layerIds: t.array(t.number),
  }),
  'LayerDecomposition',
);

type LayerDecomposition = t.TypeOf<typeof LayerDecompositionC>;

const RevitIdC = t.record(
  t.string,
  t.string,
);

type RevitId = t.TypeOf<typeof RevitIdC>;

export class BimRecordDataApi {
  public static getLayerDecomposition(projectId: number): Promise<LayerDecomposition> {
    return CompanyResourcesApi.getV(
      `/projects/${projectId}/layer-decomposition`,
      LayerDecompositionC,
    );
  }

  public static getRevitId(projectId: number): Promise<RevitId> {
    return CompanyResourcesApi.getV(
      `/projects/${projectId}/revit-id`,
      RevitIdC,
    );
  }
}
