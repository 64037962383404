import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { DatabaseActivityVariantActionTypes } from '../actions/types/database-activity-variant';
import { ActivityVariantModel } from '../interfaces/data';
import { DatabaseReduxState } from '../interfaces/redux-state';


export const DatabaseActivityVariantReducerMethods: ReducerMethods<DatabaseReduxState> = {
  [DatabaseActivityVariantActionTypes.GET_ACTIVITY_VARIANT_SUCCEEDED]: (state, payload: ActivityVariantModel) => {
    return new MonoliteHelper(state)
      .set((_) => _.currentDatabase.editModel.variant, payload)
      .get();
  },
};
