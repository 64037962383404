import { ElementTooltip, Switcher, Text } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { METRIC_IDS } from 'common/constants/id-maps';
import { useAiSuggestSetting } from './hooks';
import { Styled } from './styled';


interface Props {
  enabled: boolean;
}

export const AiSuggest: React.FC<Props> = ({ enabled }) => {
  const { toggleAutocomplete, isAutoCompleteEnabled } = useAiSuggestSetting();

  const handleClick = useCallback(() => {
    if (enabled) {
      toggleAutocomplete();
    }
  }, [isAutoCompleteEnabled, enabled, toggleAutocomplete]);

  return (
    <UpgradeWrapper isNeedUpdate={!enabled}>
      <ElementTooltip
        id={METRIC_IDS.legendAnnotation}
        disabled={true}
        text={'AI Suggest'}
        position={'top'}
        wordBreakAll={true}
      >
        <Styled.Container onClick={!enabled ? undefined : handleClick}>
          <Text>
            {'AI Suggest'}
          </Text>
          <Switcher
            checked={isAutoCompleteEnabled}
            size='m'
            disabled={!enabled}
          />
        </Styled.Container>
      </ElementTooltip>
    </UpgradeWrapper>
  );
};
