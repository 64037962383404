import autobind from 'autobind-decorator';
import { hierarchy, HierarchyRectangularNode, treemap } from 'd3-hierarchy';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { isEqual } from 'lodash';
import React from 'react';

import './treemap.scss';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { numberUtils } from 'common/utils/number-utils';
import { CostEstimateTreeMapData } from '../../../interfaces/dashboard/cost-estimate-tree-map';
import { createTooltip, toggleTooltip } from './schedule-tooltip';

interface Props extends I18nAwareProps {
  data: CostEstimateTreeMapData;
  name: string;
}

interface State {
  depth: number;
}

class TreemapChartComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      depth: 0,
    };
  }

  public componentDidMount(): void {
    this.draw(this.props.data);
  }

  public UNSAFE_componentWillUpdate(nextProps: Props): void {
    if (nextProps.data && !isEqual(this.props.data, nextProps.data)) {
      this.draw(nextProps.data);
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.displayText);
  }

  public render(): JSX.Element {
    return (
      <div className='treemap-component' id={this.props.name}>
        <div className='treemap' />
      </div>
    );
  }

  private draw(data: CostEstimateTreeMapData): void {
    const width = 100;
    const height = 100;
    const x = scaleLinear()
      .domain([0, width])
      .range([0, width]);
    const y = scaleLinear()
      .domain([0, width])
      .range([0, height]);
    const innerPadding = '1px';
    const outerPadding = '1px';

    const treemapLayout = treemap()
      .size([width, height])
      .round(true);

    const nodes = hierarchy(data).sum(d => (d.value < 0 ? 0 : d.value)) as HierarchyRectangularNode<
      CostEstimateTreeMapData
    >;
    treemapLayout(nodes);

    // clear
    select(`#${this.props.name} > .treemap`)
      .selectAll('.node')
      .remove();

    const cell = select(`#${this.props.name} > .treemap`)
      .selectAll('.node')
      .data(nodes.descendants())
      .enter()
      .append('div')
      .attr('class', d => `node level-${d.depth} ${!d.children ? 'view' : ''}`)
      .style('left', d => `${x(d.x0)}%`)
      .style('top', d => `${y(d.y0)}%`)
      .style('width', d => `${x(d.x1 - d.x0)}%`)
      .style('height', d => `${y(d.y1 - d.y0)}%`)
      .append('div')
      .style('top', d => {
        if (!d.children && d.parent) {
          return d.y0 > d.parent.y0 ? innerPadding : outerPadding;
        }
        return 'none';
      })
      .style('bottom', d => {
        if (!d.children && d.parent) {
          return d.y1 < d.parent.y1 ? innerPadding : outerPadding;
        }
        return 'none';
      })
      .style('left', d => {
        if (!d.children && d.parent) {
          return d.x0 > d.parent.x0 ? innerPadding : outerPadding;
        }
        return 'none';
      })
      .style('right', d => {
        if (!d.children && d.parent) {
          return d.x1 < d.parent.x1 ? innerPadding : outerPadding;
        }
        return 'none';
      })
      .style(
        'background-color',
        d => (d.data.color ? d.data.color : d.parent ? d.parent.data.color : 'transparent'),
      )
      .on('mouseenter', (d) => {
        const event = require('d3-selection').event;
        const modal = select('#modal-root');
        const cost = this.props.i18n.c(d.value, '0.[00]a');
        createTooltip(modal, d.data.name, cost);
        toggleTooltip(event, true);
      })
      .on('mousemove', () => {
        const event = require('d3-selection').event;
        toggleTooltip(event, true);
      })
      .on('mouseleave', () => {
        toggleTooltip(null, false);
      });

    cell
      .append('p')
      .html(d => `
        <b>${d.data.name}</b>
        <span>${this.props.i18n.c(d.value, '0.[00]a')}</span>
        `);

    cell.append('b').text((d: any) => {
      let value = '';
      if (d.parent && !d.parent.percentShown && d.parent.data.percentage) {
        d.parent.percentShown = true;
        value = numberUtils.getNumeralFormatter(d.parent.data.percentage).format('0%');
      }
      return value;
    });

    window.addEventListener('resize', this.displayText);
    this.displayText();
  }

  @autobind
  private displayText(): void {
    const changeOpacity = function(): string {
      const title = this.querySelector('b');
      const cost = this.querySelector('span');
      if (
        this.offsetWidth > this.parentElement.offsetWidth - 5 ||
        title.offsetHeight + cost.offsetHeight > this.parentElement.offsetHeight - 5
      ) {
        title.classList.add('hidden');
        if (
          cost.offsetWidth > this.parentElement.offsetWidth - 5 ||
          cost.offsetHeight > this.parentElement.offsetHeight - 5
        ) {
          return '0';
        }
      }
      return '1';
    };

    setTimeout(
      () => {
        select(`#${this.props.name} > .treemap`)
          .selectAll('p')
          .style('opacity', changeOpacity);
      },
      300);
  }
}

export const TreemapChart = withI18n(TreemapChartComponent);
