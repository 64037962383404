import Ag from 'ag-grid-community';
import { UserInfo } from 'common/components/data-base-table/interfaces';

export class UserCell implements Ag.ICellRendererComp {
  private eGui: HTMLElement;
  private params: Ag.ICellRendererParams;

  public refresh(): boolean {
    return false;
  }

  public getGui(): HTMLElement {
    this.eGui = document.createElement('div');
    this.addInfoToGui(this.params, this.eGui);
    return this.eGui;
  }

  public addInfoToGui(params: Ag.ICellRendererParams, gui: HTMLElement): void {
    const value = (params.value as UserInfo);
    if (value) {
      gui.innerHTML = value.content;
    }
  }

  public init(params: Ag.ICellRendererParams): void | Ag.AgPromise<void> {
    this.params = params;
  }
}
