import { State } from 'common/interfaces/state';
import { store } from '../../store';
import { BaseResourcesApi } from './base-resource-api';

const getBaseUrl = (url: string = ''): string => {
  const state: State = store.getState();
  const companyId = state.account.selectedCompany && state.account.selectedCompany.id;
  const subscriptionType = state.account.selectedSubscriptionType;

  if (!companyId) {
    throw new Error(`CompanyResourcesApi: companyId is empty requested path ${url || '/'}`);
  }

  if (!subscriptionType) {
    throw new Error(`CompanyResourcesApi: subscriptionType is empty requested path ${url || '/'}`);
  }

  return `/company/${companyId}/${subscriptionType}${url}`;
};

const getDefaultRolesUrl = (url: string = ''): string => {
  const state: State = store.getState();
  const subscriptionType = state.account.selectedSubscriptionType;

  if (!subscriptionType) {
    throw new Error(`CompanyResourcesApi: subscriptionType is empty requested path ${url || '/'}`);
  }

  return `/companies/${subscriptionType}${url}`;
};

export const CompanyResourcesApi = new BaseResourcesApi(getBaseUrl);
export const CompaniesResourcesApi = new BaseResourcesApi(getDefaultRolesUrl);
