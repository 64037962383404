import * as React from 'react';

interface Props {
  color?: string;
}

export const KreoIconInfo: React.FC<Props> = props => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='info' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          // eslint-disable-next-line max-len
          d='M10.6630004,13.6484999 C10.6630004,13.9669999 10.6435004,14.0839999 10.5655004,14.24 C10.4745004,14.4155 10.2860003,14.552 10.0000003,14.552 C9.71400023,14.552 9.5255002,14.4155 9.43450019,14.24 C9.35650018,14.0839999 9.33700017,13.9669999 9.33700017,13.6484999 L9.33700017,8.76049906 C9.33700017,8.44199901 9.35650018,8.32499899 9.43450019,8.16899896 C9.5255002,7.99349893 9.71400023,7.85699891 10.0000003,7.85699891 C10.2860003,7.85699891 10.4745004,7.99349893 10.5655004,8.16899896 C10.6435004,8.32499899 10.6630004,8.44199901 10.6630004,8.76049906 L10.6630004,13.6484999 Z M9.20050015,5.75749857 C9.20050015,5.31549849 9.55800021,4.95799844 10.0000003,4.95799844 C10.4420004,4.95799844 10.7995004,5.31549849 10.7995004,5.75749857 C10.7995004,6.19949864 10.4420004,6.5569987 10.0000003,6.5569987 C9.55800021,6.5569987 9.20050015,6.19949864 9.20050015,5.75749857 Z'
          id='i'
          fill={props.color ? props.color : '#001B52'}
        />
      </g>
    </svg>
  );
};
