import { Action } from 'redux';
import { RequestStatus } from 'common/enums/request-status';
import { ActionWith } from 'common/interfaces/action-with';
import { DrawingsDrawMode } from '../../enums';
import { WizzardStatus } from '../../enums/dropper-state';

import { FixContour, ShortPointDescription } from '../../interfaces';
import { MagicSearchSnappedGeometry } from '../../interfaces/api-responses/magic-search-responses';
import {
  ApplyContourFixPayload,
  MagicSearchSaveResultPayload,
  MagicSearchSetContourPayload,
  SetPreviewsPayload,
} from '../payloads/magic-search';
import { MagicSearchActionTypes } from '../types/magic-search';

function setContoursToSearch(
  contours: ShortPointDescription[][],
  drawModeOnClose?: DrawingsDrawMode,
): ActionWith<MagicSearchSetContourPayload> {
  return {
    type: MagicSearchActionTypes.SET_CONTOURS_TO_SEARCH,
    payload: {
      contours,
      drawModeOnClose,
    },
  };
}

function toggleDeletePreview(ids: number[]): ActionWith<number[]> {
  return {
    type: MagicSearchActionTypes.TOGGLE_DELETE_PREVIEW,
    payload: ids,
  };
}

function resetDeletePreviews(): Action {
  return {
    type: MagicSearchActionTypes.RESET_DELETE_PREVIEWS,
  };
}

function runSearch(): Action {
  return {
    type: MagicSearchActionTypes.RUN_SEARCH,
  };
}

function saveResult(payload: MagicSearchSaveResultPayload): ActionWith<MagicSearchSaveResultPayload> {
  return {
    type: MagicSearchActionTypes.SAVE_RESULT,
    payload,
  };
}

function setDPI(dpi: number): ActionWith<number> {
  return {
    type: MagicSearchActionTypes.SET_DPI,
    payload: dpi,
  };
}

function setSimilarity(similarity: number): ActionWith<number> {
  return {
    type: MagicSearchActionTypes.SET_SIMILARITY,
    payload: similarity,
  };
}

function setPreviews(previews: SetPreviewsPayload): ActionWith<SetPreviewsPayload> {
  return {
    type: MagicSearchActionTypes.SAVE_PREVIEW_RESULT,
    payload: previews,
  };
}

function setSnappedGeometry(geometries: MagicSearchSnappedGeometry[]): ActionWith<MagicSearchSnappedGeometry[]> {
  return {
    type: MagicSearchActionTypes.SAVE_SNAPPED_GEOMETRIES,
    payload: geometries,
  };
}

function setStatus(status: WizzardStatus): ActionWith<WizzardStatus> {
  return {
    type: MagicSearchActionTypes.SET_STATUS,
    payload: status,
  };
}

function setSearchingZone(area: ShortPointDescription[]): ActionWith<ShortPointDescription[]> {
  return {
    type: MagicSearchActionTypes.SET_SEARCH_ZONE,
    payload: area,
  };
}

function setSpecifyingZoneMode(specify: boolean): ActionWith<boolean> {
  return {
    type: MagicSearchActionTypes.SET_SPECIFY_SEARCH_ZONE,
    payload: specify,
  };
}

function textSearch(query: string): ActionWith<string> {
  return {
    type: MagicSearchActionTypes.RUN_TEXT_SEARCH,
    payload: query,
  };
}

function cancelSearch(): Action {
  return {
    type: MagicSearchActionTypes.CANCEL_SEARCH,
  };
}

function setQuery(query: string): ActionWith<string> {
  return {
    type: MagicSearchActionTypes.SET_QUERY,
    payload: query,
  };
}

function setSnappingStatus(status: RequestStatus): ActionWith<RequestStatus> {
  return {
    type: MagicSearchActionTypes.SET_SNAPPING_STATUS,
    payload: status,
  };
}

function setFixContourApi(api: FixContour): ActionWith<FixContour> {
  return {
    type: MagicSearchActionTypes.SET_FIX_API,
    payload: api,
  };
}

function setFixContourStatus(status: RequestStatus): ActionWith<RequestStatus> {
  return {
    type: MagicSearchActionTypes.SET_FIX_STATUS,
    payload: status,
  };
}

function applyFixContour(
  sourceIndices: number[],
  contour: ShortPointDescription[][],
): ActionWith<ApplyContourFixPayload> {
  return {
    type: MagicSearchActionTypes.APPLY_FIX_CONTOUR,
    payload: { sourceIndices, contour },
  };
}

function deleteFixContour(fixContourIndex: number): ActionWith<number> {
  return {
    type: MagicSearchActionTypes.DELETE_FIX_CONTOUR,
    payload: fixContourIndex,
  };
}

function resnap(): Action {
  return {
    type: MagicSearchActionTypes.RESNAP,
  };
}

export const MagicSearchActions = {
  setContoursToSearch,
  setSimilarity,
  runSearch,
  saveResult,
  setDPI,
  setStatus,
  setPreviews,
  setSearchingZone,
  setSpecifyingZoneMode,
  textSearch,
  toggleDeletePreview,
  cancelSearch,
  resetDeletePreviews,
  setQuery,
  setSnappingStatus,
  setSnappedGeometry,
  setFixContourApi,
  applyFixContour,
  setFixContourStatus,
  deleteFixContour,
  resnap,
};
