
import * as React from 'react';

export const KreoIconClipBoxFilter: React.FC = () => {
  return (
    <svg
      width='24px'
      height='24px'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <g id='Layer_9' data-name='Layer 9'>
        <line
          x1='12'
          y1='15.67'
          x2='12'
          y2='11.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='12'
          y1='15.67'
          x2='12'
          y2='11.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='12'
          y1='15.67'
          x2='12'
          y2='11.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='8.68'
          y1='9.91'
          x2='12'
          y2='11.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='15.33'
          y1='9.91'
          x2='12'
          y2='11.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='8.68'
          y1='13.75'
          x2='12'
          y2='15.67'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='8.68'
          y1='13.75'
          x2='8.68'
          y2='9.91'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='8.68'
          y1='9.91'
          x2='12'
          y2='7.99'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='15.33'
          y1='9.91'
          x2='12'
          y2='7.99'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='15.33'
          y1='9.91'
          x2='15.33'
          y2='13.75'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='12'
          y1='15.67'
          x2='15.33'
          y2='13.75'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='12'
          y1='21.17'
          x2='12'
          y2='11.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='12'
          y1='21.17'
          x2='12'
          y2='11.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='12'
          y1='21.17'
          x2='12'
          y2='11.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='3.92'
          y1='7.16'
          x2='12'
          y2='11.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='20.08'
          y1='7.16'
          x2='12'
          y2='11.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='3.92'
          y1='16.5'
          x2='12'
          y2='21.17'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='3.92'
          y1='16.5'
          x2='3.92'
          y2='7.16'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='3.92'
          y1='7.16'
          x2='12'
          y2='2.5'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='20.08'
          y1='7.16'
          x2='12'
          y2='2.5'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='20.08'
          y1='7.16'
          x2='20.08'
          y2='16.5'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='12'
          y1='21.17'
          x2='20.08'
          y2='16.5'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
