import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconDate: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icon_14x14_start-date' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect id='14x14' x='0' y='0' width='14' height='14' />
        <path
          // eslint-disable-next-line max-len
          d='M10,1 L12,1 C12.5522847,1 13,1.44771525 13,2 L13,12 C13,12.5522847 12.5522847,13 12,13 L2,13 C1.44771525,13 1,12.5522847 1,12 L1,2 C1,1.44771525 1.44771525,1 2,1 L4,1 L4,0 L5,0 L5,1 L9,1 L9,0 L10,0 L10,1 Z M2,12 L12,12 L12,2 L2,2 L2,12 Z M4,6 L4,4 L6,4 L6,6 L4,6 Z M8,6 L8,4 L10,4 L10,6 L8,6 Z M4,10 L4,8 L6,8 L6,10 L4,10 Z M8,10 L8,8 L10,8 L10,10 L8,10 Z'
          id='Combined-Shape'
          fill={KreoColors.f5}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
