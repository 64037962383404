import * as React from 'react';

import { MenuGroupContainerWithTitle } from 'common/components/menu-group-container';
import { Styled } from './styled';


export const SetContainer: React.FC= ({ children }) => {
  return (
    <Styled.Set>
      <MenuGroupContainerWithTitle>
        {children}
      </MenuGroupContainerWithTitle>
    </Styled.Set>
  );
};
