import * as React from 'react';

export const KreoIconGhost = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <g
      id='Icons/24x24/Ghost_Normal'
      className='icon'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path
        // eslint-disable-next-line max-len
        d='M5.5,3.5 L13.5,3.5 C14.6045695,3.5 15.5,4.3954305 15.5,5.5 L15.5,13.5 C15.5,14.6045695 14.6045695,15.5 13.5,15.5 L5.5,15.5 C4.3954305,15.5 3.5,14.6045695 3.5,13.5 L3.5,5.5 C3.5,4.3954305 4.3954305,3.5 5.5,3.5 Z'
        className='icon__color-light'
        stroke='currentColor'
      />
      <path
        // eslint-disable-next-line max-len
        d='M10.5,8.5 L18.5,8.5 C19.6045695,8.5 20.5,9.3954305 20.5,10.5 L20.5,18.5 C20.5,19.6045695 19.6045695,20.5 18.5,20.5 L10.5,20.5 C9.3954305,20.5 8.5,19.6045695 8.5,18.5 L8.5,10.5 C8.5,9.3954305 9.3954305,8.5 10.5,8.5 Z'
        stroke='currentColor'
        strokeDasharray='2'
      />
    </g>
  </svg>
);
