import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { TwoDDatabaseActions } from '../../../../store-slice';
import { AssemblyGroupData } from '../assembly-group-form/assembly-group-form';
import { ItemGroupFormData } from '../assembly-panel/item-form-updater';
import { GroupForm } from '../item-panel/helpers/map-property-group-form';
import { VisibillityControlPanel } from './visibillity-control-panel';

interface DispatchProps {
  setCollapsedGroups: (ids: Set<string>) => void;
}

interface Props extends DispatchProps {
  groups: GroupForm | Record<string, ItemGroupFormData> | Record<string, AssemblyGroupData>;
  getGroupKeys?: () => Set<string>;
  isSelectPanelOpen?: boolean;
  openSelectPanel?: () => void;
}

class VisibilityPanelComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isSelectPanelOpen, openSelectPanel } = this.props;
    return (
      <VisibillityControlPanel
        onCollapse={this.collapseGroups}
        onExpand={this.expandGroups}
        isSelectPanelOpen={isSelectPanelOpen}
        openSelectPanel={openSelectPanel}
      />
    );
  }

  @autobind
  private collapseGroups(): void {
    const { getGroupKeys, groups, setCollapsedGroups } = this.props;
    const keys = getGroupKeys ? getGroupKeys() : new Set(Object.keys(groups));
    setCollapsedGroups(keys);
  }

  @autobind
  private expandGroups(): void {
    this.props.setCollapsedGroups(new Set());
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    setCollapsedGroups: (ids) => dispatch(TwoDDatabaseActions.setCollapsedGroups(ids)),
  };
};

export const VisibilityPanel = connect(null, mapDispatchToProps)(VisibilityPanelComponent);
