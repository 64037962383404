import * as React from 'react';

import './database-activity-listing.scss';

import { DatabaseActivityCategoryNavigationTree } from '../../components/database-activity-category-navigation-tree';
import { DatabaseActivityModifyPanel } from '../../components/database-activity-modify-panel';
import { DatabaseActivityTable } from '../../components/database-activity-table';
import { DatabaseBaseLayout } from '../../components/database-base-layout';

interface Props {
  databaseId: number;
  isCheckboxesVisible: boolean;
}

export class DatabaseActivityListing extends React.Component<Props> {
  public render(): JSX.Element {
    const { databaseId, isCheckboxesVisible } = this.props;

    return (
      <DatabaseBaseLayout databaseId={databaseId}>
        <div className='activities-page'>
          <div className='activities-page__left-menu'>
            <DatabaseActivityCategoryNavigationTree databaseId={databaseId} />
          </div>
          <div className='activities-page__content'>
            <DatabaseActivityTable
              databaseId={databaseId}
              isCheckboxesVisible={isCheckboxesVisible}
            />
          </div>
          <DatabaseActivityModifyPanel/>
        </div>
      </DatabaseBaseLayout>
    );
  }
}
