import styled from 'styled-components';

const VERTICAL_MARGIN = 60;
const RESIZE_LINE_HEIGHT = 3;
const MIN_WINDOW_HEIGHT = 400;

const RootWrapperContainer = styled.div`
  position: absolute;
  bottom: ${60}px;
  z-index: 1001;
  left: 50%;
  transform: translateX(-50%);
`;

const DialogContainer = styled.div`
  width: 920px;
  box-shadow: ${props => props.theme.shadow.xl};
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 25px;
  min-height: ${MIN_WINDOW_HEIGHT}px;
  max-height: calc(100vh - ${2 * VERTICAL_MARGIN}px);
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(2px);
  background: rgba(0,0,0,0.5);
  border: 2px solid ${p => p.theme.color.turquoise};
`;


const ResizeLine = styled.div`
  height: ${RESIZE_LINE_HEIGHT}px;
  width: 100%;
  position: absolute;
  top: 0;
  cursor: row-resize;
`;

export const Styled = {
  RootWrapperContainer,
  DialogContainer,
  ResizeLine,
  VERTICAL_MARGIN,
  MIN_WINDOW_HEIGHT,
};
