import { RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { Spinner } from 'common/components/spinner';
import { PAYMENT_METHOD_NOT_ADDED_DIALOG } from '../../subscription-details-new-user/payment-method-not-added-dialog';
import { Styled } from './styled';

interface Props {
  applyButtonCaption: string;
  onApplyClick: () => void;
  openPaymentDialog?: (name: string) => void;
  inProgress?: boolean;
  countryId?: string | null;
}

export const ApplyButton: React.FC<Props> = (props: Props) => {
  const { onApplyClick, openPaymentDialog, applyButtonCaption, inProgress, countryId } = props;
  const text = inProgress ? '' : applyButtonCaption;
  const mood = countryId || onApplyClick && !inProgress ? 'secondary' : 'disabled';

  const handleOnClick = useCallback(() => {
    if (onApplyClick) {
      onApplyClick();
    } else if (openPaymentDialog) {
      openPaymentDialog(PAYMENT_METHOD_NOT_ADDED_DIALOG);
    }
  }, [onApplyClick, openPaymentDialog]);

  return (
    <Styled.Container mood={mood}>
      <RectangleButton
        size="l"
        mood={mood}
        onClick={handleOnClick}
        text={text}
      />
      <Spinner show={inProgress} />
    </Styled.Container>
  );
};
