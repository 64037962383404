import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { SectionMenuItems } from 'common/components/section-menu-items';
import { Menu } from 'unit-2d-info-panel/constants';
import { useOpenMenu } from 'unit-2d-info-panel/hooks/use-open-menu';

interface Props {
  title: string;
  onClose?: () => void;
  infoIcon?: React.ComponentType;
  menuComponent: React.ComponentType;
}


const MenuViewComponent: React.FC<Props> = ({
  title,
  onClose,
  infoIcon,
  menuComponent: MenuComponent,
}) => {
  const changeView = useOpenMenu(Menu.Default);
  const onCloseMenu = useCallback(() => {
    changeView();
    if (onClose) {
      onClose();
    }
  }, []);

  return (
    <>
      <SectionMenuItems
        onSelectionMenuClick={onCloseMenu}
        infoIcon={infoIcon}
        title={title}
        manageIcon={Icons.CloseSmall}
      />
      <MenuComponent />
    </>
  );
};


export const MenuView = React.memo(MenuViewComponent);
