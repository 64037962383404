import { Input } from '@kreo/kreo-ui-components';
import { Length } from '@kreo/kreo-ui-components/icons';
import * as React from 'react';
import { UnitTypes } from 'common/utils/unit-util';
import { Styled } from './styled';

interface Props {
  isDistanceValid: boolean;
  disableDistance: boolean;
  distance: string | number;
  changeDistance: (value: string) => void;
}

export const CalibrateMetricInputComponent: React.FC<Props> = (
  {
    distance,
    disableDistance,
    changeDistance,
    isDistanceValid,
  }) => {
  return (
    <Styled.MetricInputStyled>
      <Input
        width='100%'
        type='text'
        text={`Length, ${UnitTypes.M}`}
        input={{
          value: distance.toString(),
          onChange: e => changeDistance(e.target.value),
          disabled: disableDistance,
        }}
        icon={<Length />}
        placeholder='0'
        isInvalid={!isDistanceValid}
      />
    </Styled.MetricInputStyled>
  );
};


export const CalibrateMetricInput = React.memo(CalibrateMetricInputComponent);
