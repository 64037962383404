import { ModelCheckState } from '../../pages/model-check-view/interfaces/model-check-state';

export const MODEL_CHECK_INITIAL_STATE: ModelCheckState = {
  selectedErrorCategory: null,
  errors: [],
  issues: [],
  warning: [],
  trivial: [],
  selectedHandle: null,
  showProps: false,
  secondHandle: null,
  loaded: false,
  totalBim: null,
  availableChecks: null,
  selectedChecks: {},
};
