import { Avatar } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';

import './styles.scss';

import { InformationItem, InformationRow } from '..';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { Toggle } from '../../../../components/toggle';
import { AccountApi } from '../../../../units/account/api';
import { AttributeOption, ShortCompany, User } from '../../interfaces/user';
import { PeopleItemAttribute, PeopleItemAttributeType } from './people-item-attribute';
import { ShortCompanyItem } from './short-company-item';


interface PeopleItemProps {
  user: User;
  onDelete?: (email: string) => void;
  onEnable: (guid: string, enable: boolean) => void;
  updateUserAttribute: (key: string, value: string, userId: string) => void;
  onCompanyClick: (companySearch: React.ReactText) => void;
  onDeleteOwnedCompany: (userId: string) => void;
  openChangeEmailDialog: (email: string, id: string) => void;
}

export class PeopleItem extends React.Component<PeopleItemProps> {
  private menu: ItemMenuType[] = [
    {
      name: 'Change Email',
      action: this.openChangeEmailDialog,
    },
    {
      name: 'Delete',
      action: this.delete,
    },
    {
      name: 'Delete Owned Company',
      action: this.deleteOwnedCompany,
    },
  ];

  public render(): React.ReactNode {
    const { user } = this.props;
    const userName = `${user.firstName} ${user.lastName}`;

    return (
      <div className={classNames('admin-item')} >
        <div className='admin-item__information'>
          <div className='admin-item__row'>
            <div className='admin-item__col direction-col'>
              <div className='admin-item__head'>
                <Avatar
                  name={`${user.firstName} ${user.lastName}`}
                  size={30}
                  avatar={AccountApi.getAvatarLink({ id: user.guid, hasAvatar: user.hasAvatar })}
                />
                {`${userName} (${user.email})`}
              </div>
            </div>
            <div className='admin-item__col'>
              <div className='admin-item__secondary'>
                Enabled:
              </div>
              <Toggle
                checked={this.props.user.enabled}
                checkedColor='#5C8AE6'
                onCheck={this.onEnableChange}
              />
            </div>
          </div>
          <ItemMenu menu={this.menu} className='admin-item__item-actions-menu' />
        </div>
        <InformationRow>
          {
            this.props.user.created ?
              <InformationItem
                title='Created'
                data={moment(`${this.props.user.created}+00:00`).format('D MMM YYYY')}
              /> : null
          }
          <InformationItem
            title='Companies'
            data={this.props.user.companies}
            renderItem={this.renderCompany}
          />
        </InformationRow>
        <InformationRow>
          <InformationItem
            title='Attributes'
            data={user.attributes}
            renderItem={this.renderAttribute}
          />
        </InformationRow>
      </div>
    );
  }

  @autobind
  private openChangeEmailDialog(): void {
    const { guid, email } = this.props.user;
    this.props.openChangeEmailDialog(guid, email);
  }

  @autobind
  private renderCompany(item: ShortCompany): React.ReactNode {
    const user = this.props.user;

    return (
      <ShortCompanyItem
        key={`${user.guid}_${item.id}`}
        item={item}
        className='admin-item__chip clickable'
        onClick={this.onCompanyClick}
        ownCompanyId={user.ownCompanyId}
      />
    );
  }

  @autobind
  private onCompanyClick(item: ShortCompany): void {
    if (this.props.onCompanyClick) {
      this.props.onCompanyClick(item.name);
    }
  }

  @autobind
  private renderAttribute(item: AttributeOption): React.ReactNode {
    let valueType: PeopleItemAttributeType = 'text';
    const disabled = item.name === 'verificationToken' || item.name === 'completionVerificationTokenTime';

    if (item.name === 'planTrialPeriodTill') {
      valueType = 'date-time';
    } else if (item.name === 'isAdmin') {
      valueType = 'bool';
    }

    return (
      <PeopleItemAttribute
        key={`${this.props.user.guid}_${item.key}`}
        item={item}
        valueType={valueType}
        userId={this.props.user.guid}
        updateUserAttribute={this.props.updateUserAttribute}
        disabled={disabled}
      />
    );
  }

  @autobind
  private delete(): void {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.user.guid);
    }
  }

  @autobind
  private deleteOwnedCompany(): void {
    this.props.onDeleteOwnedCompany(this.props.user.guid);
  }

  @autobind
  private onEnableChange(newValue: boolean): void {
    if (this.props.onEnable) {
      this.props.onEnable(this.props.user.guid, newValue);
    }
  }
}
