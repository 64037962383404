import * as t from 'io-ts';
import { ViewModelStatusC } from 'unit-projects/enums/view-model-status';
import { CostEstimateValidationStepC } from './cost-estimate-validation-step';

export const RevisionStateC = t.strict(
  {
    validationStep: CostEstimateValidationStepC,
    activityAssignmentStatus: ViewModelStatusC,
    measurementsStatus: ViewModelStatusC,
  },
  'RevisionState');

export type RevisionState = t.TypeOf<typeof RevisionStateC>;
