import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const CONTAINER_WIDTH = 420;

const Container = styled.div`
  background: ${p => p.theme.color.backgroundRush};
  width: ${CONTAINER_WIDTH}px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 40px;
  animation: ${Constants.Animations.modalAppear}  ${p => p.theme.duration.m} ease-in-out;
  *, & {
    box-sizing: border-box;
  }

  button {
    margin-left: auto;
  }
`;


export const Styled = {
  Container,
};
