import React from 'react';

import { StringUtils } from 'common/utils/string-utils';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';

import { CompanyProjectHeader } from 'unit-projects/interfaces/company-project-header';


export const REMOVE_PROJECT_DIALOG_NAME = 'project-remove-dialog';

interface Props {
  projectToBeRemoved: CompanyProjectHeader | null;
  type: string;
  onConfirmed: () => void;
  onCancel: () => void;
}

const ProjectRemoveDialogComponent: React.FC<Props> = ({
  projectToBeRemoved,
  type,
  onConfirmed,
  onCancel,
}) => {
  if (!projectToBeRemoved) {
    return null;
  }

  const projectName = projectToBeRemoved.name;
  const dialogText = `${StringUtils.firstUpperLetter(type)} "${projectName}"
  will be removed immediately. This action can’t be undone.`;

  return (
    <ConfirmationDialog
      name={REMOVE_PROJECT_DIALOG_NAME}
      title={`Are you sure you want to delete the ${type}?`}
      text={dialogText}
      confirmButtonText='Delete'
      cancelButtonText='Cancel'
      onConfirm={onConfirmed}
      onCancel={onCancel}
    />
  );
};


export const ProjectRemoveDialog = React.memo(ProjectRemoveDialogComponent);

