import * as React from 'react';

export const KreoIconCopy: React.FC = () => {
  return (
    <svg
      width='12px'
      height='14px'
      viewBox='0 0 12 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='copy_12x14' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Revit-Tree' transform='translate(-109.000000, -1777.000000)' fill='#BACEDE' fillRule='nonzero'>
          <g id='Group-31' transform='translate(56.000000, 1274.000000)'>
            <g id='Group-28' transform='translate(0.000000, 494.000000)'>
              <g id='Icon' transform='translate(43.000000, 0.000000)'>
                <g id='Icons/16x16/Copy/Copy-Icons/16x16/Copy' transform='translate(8.000000, 8.000000)'>
                  <path
                    // eslint-disable-next-line max-len
                    d='M9,4 C10.1045695,4 11,4.8954305 11,6 L11,13 C11,14.1045695 10.1045695,15 9,15 L4,15 C2.8954305,15 2,14.1045695 2,13 L2,6 C2,4.8954305 2.8954305,4 4,4 L9,4 Z M9,5 L4,5 C3.44771525,5 3,5.44771525 3,6 L3,13 C3,13.5522847 3.44771525,14 4,14 L9,14 C9.55228475,14 10,13.5522847 10,13 L10,6 C10,5.44771525 9.55228475,5 9,5 Z M8.5,10 C8.77614237,10 9,10.2238576 9,10.5 C9,10.7761424 8.77614237,11 8.5,11 L4.5,11 L4.41012437,10.9919443 C4.17687516,10.9496084 4,10.7454599 4,10.5 C4,10.2238576 4.22385763,10 4.5,10 L8.5,10 Z M12,1 C13.1045695,1 14,1.8954305 14,3 L14,10 C14,11.1045695 13.1045695,12 12,12 L12,11 C12.5128358,11 12.9355072,10.6139598 12.9932723,10.1166211 L13,10 L13,3 C13,2.44771525 12.5522847,2 12,2 L7,2 C6.48716416,2 6.06449284,2.38604019 6.00672773,2.88337887 L6,3 L5,3 C5,1.8954305 5.8954305,1 7,1 L12,1 Z M8.5,7 C8.77614237,7 9,7.22385763 9,7.5 C9,7.77614237 8.77614237,8 8.5,8 L4.5,8 C4.22385763,8 4,7.77614237 4,7.5 C4,7.22385763 4.22385763,7 4.5,7 L8.5,7 Z'
                    id='Shape'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
