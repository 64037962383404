import * as React from 'react';

interface Props {
  className?: string;
}

export const KreoIconCheckMark: React.FC<Props> = (props: Props) => {
  return (
    <svg
      width='24'
      height='24'
      className={props.className}
      viewBox='0 0 24 24'
    >
      <path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/>
    </svg>
  );
};
