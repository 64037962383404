import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { HeaderContext } from 'common/enums/header-context';
import { LogoType } from 'common/enums/logo-type';
import { PageHeader } from 'common/interfaces/page-header';
import { State } from 'common/interfaces/state';
import { ActivityAssignmentPage } from 'unit-cost-estimate/pages/activity-assignment';
import { ActivityAssignmentEditPage } from 'unit-cost-estimate/pages/activity-assignment-edit';
import { ActivityAssignmentViewPage } from 'unit-cost-estimate/pages/activity-assignment-view';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { ValidationStepsNames } from 'unit-projects/constants/validation-steps-names';
import { Project } from 'unit-projects/interfaces/project';
import { Urls } from '..';
import { PageLayout } from '../../../layouts/page-layout';
import { CostEstimateProjectRevisionRouteParams } from '../../../routes/app-routes-params';
import { NotFoundPage } from '../../../units/notfound/page';
import { ActivityAssignmentDatabaseRoutes } from './activity-assignment-database';


interface RoutesStateProps {
  currentProject: Project | null;
  locationPathname: string;
}

interface RoutesDispatchProps {
  changeProjectName: (name: string) => void;
}

interface RoutesProps
  extends RoutesStateProps,
    RoutesDispatchProps,
    RouteComponentProps<CostEstimateProjectRevisionRouteParams>,
    AbilityAwareProps {}

export const projectInnerHeader: PageHeader = {
  context: HeaderContext.Project,
  logoType: LogoType.LogoBlackLight,
  disableToggle: true,
};

export class ActivityAssignmentRoutesComponent extends React.Component<RoutesProps> {
  public render(): React.ReactNode {
    const { match } = this.props;
    const indexValidationPageUrl = Urls.project.index.url({
      projectId: match.params.projectId,
    });

    return (
      <Switch>
        <Route
          path={Urls.project.revision.activityAssignment.database.index.path}
          component={ActivityAssignmentDatabaseRoutes}
        />
        <PageLayout
          exact={true}
          metaTitle={`Edit ${ValidationStepsNames.activityAssignment}`}
          path={Urls.project.revision.activityAssignment.edit.path}
          component={ActivityAssignmentEditPage}
          header={{
            ...projectInnerHeader,
            backUrl: this.getBackUrl(),
            title: ValidationStepsNames.activityAssignment,
          }}
          subject={Subject.ValidationActivityAssignment}
          redirectUrl={indexValidationPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle={`View ${ValidationStepsNames.activityAssignment}`}
          path={Urls.project.revision.activityAssignment.view.path}
          component={ActivityAssignmentViewPage}
          header={{
            ...projectInnerHeader,
            backUrl: this.getBackUrl(),
            title: ValidationStepsNames.activityAssignment,
          }}
          subject={Subject.ValidationActivityAssignment}
          redirectUrl={indexValidationPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle={ValidationStepsNames.activityAssignment}
          path={Urls.project.revision.activityAssignment.index.path}
          backgroundColor='white'
          component={ActivityAssignmentPage}
          header={this.getProjectHeader()}
          subject={Subject.ValidationActivityAssignment}
          redirectUrl={Urls.listing.url()}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  private getProjectHeader(): PageHeader {
    return {
      context: HeaderContext.ProjectRevision,
      backUrl: Urls.listing.url(),
      title: this.props.currentProject ? this.props.currentProject.name : '',
      onEditTitle: this.props.ability.can(Operation.Update, Subject.Project)
        ? this.props.changeProjectName
        : null,
      showLeftMenu: true,
    };
  }

  private getBackUrl(): string {
    return Urls.project.revision.activityAssignment.index.url(this.props.match.params);
  }
}

function mapStateToProps(state: State): RoutesStateProps {
  return {
    currentProject: state.projects.currentProject,
    locationPathname: state.router.location.pathname,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): RoutesDispatchProps {
  return {
    changeProjectName: (name: string) => dispatch(ProjectsActions.updateProjectName(name)),
  };
}

export const ActivityAssignmentRoutes = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ActivityAssignmentRoutesComponent),
);
