import { SagaIterator } from 'redux-saga';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { TwoDApi } from '2d/api';
import { RequestStatus } from 'common/enums/request-status';
import { CurrentCountry, CurrentCountryService } from 'common/environment/current-country-service';
import { ActionWith } from 'common/interfaces/action-with';
import { AccountActions } from '../../../units/account/actions/creators';
import { ValidationField } from '../../../units/account/actions/payloads';
import { CurrentCountryActions, PaymentMethodActions } from '../actions/creators';
import { PaymentMethodActionTypes } from '../actions/types';
import { PaymentMethodsApi } from '../api/payment-methods';
import { BillingCollection } from '../interfaces/billing-collection';
import { BillingCountryModel } from '../interfaces/billing-country-model';
import { CreateBillingCardForm } from '../interfaces/create-billing-card-form';
import { PaymentSourcesModel } from '../interfaces/payment-sources-model';


function* createCardLegacy(action: ActionWith<CreateBillingCardForm>): SagaIterator {
  try {
    const payment: PaymentSourcesModel = yield call(PaymentMethodsApi.createPaymentMethodLegacy, action.payload);
    yield put(PaymentMethodActions.createCardSucceeded(payment));
    yield put(AccountActions.setSubscribeError(ValidationField.RenewSubscription, ''));
  } catch (error) {
    yield put(PaymentMethodActions.createCardFailed());
    console.error('paymentMethod: create card failed', error, action.payload);
  }
}

function* getCardsAndBillingCountries(): SagaIterator {
  try {

    const billingCountries: BillingCountryModel[] = yield call(PaymentMethodsApi.getAvailableBillingCountries);
    const country: CurrentCountry = yield call(CurrentCountryService.getCurrentCountry);
    if (country && billingCountries.some((bc) => bc.id === country.country)) {
      yield put(CurrentCountryActions.getCurrentCountrySucceed(country));
    }
    yield put(PaymentMethodActions.setAvailableBillingCountries(billingCountries));
    yield put(PaymentMethodActions.getPaymentMethods());
  } catch (error) {
    yield put(PaymentMethodActions.getPaymentDataFailed());
    console.error('paymentMethod: get cards failed', error);
  }
}

function* getPaymentSources({ payload }: ActionWith<boolean>): SagaIterator {
  try {
    if (payload) {
      yield delay(1000);
    }
    const payment: PaymentSourcesModel = yield call(PaymentMethodsApi.getPaymentMethods, payload);
    if (!payment.primaryCardId) {
      payment.primaryCardId = payment.paymentSources[0]?.id;
    }
    yield put(PaymentMethodActions.getPaymentMethodsSucceeded(payment));
  } catch (error) {
    yield put(PaymentMethodActions.getPaymentDataFailed());
    console.error('paymentMethod: get cards failed', error);
  }
}

function* removeCard(action: ActionWith<string>): SagaIterator {
  try {
    const payment: PaymentSourcesModel = yield call(PaymentMethodsApi.deletePaymentMethod, action.payload);
    yield put(PaymentMethodActions.removeCardSucceeded(payment));
  } catch (error) {
    yield put(PaymentMethodActions.removeCardFailed());
    console.error('paymentMethod: remove card failed', error);
  }
}

function* setPrimaryCard(action: ActionWith<string>): SagaIterator {
  try {
    const payment: PaymentSourcesModel = yield call(PaymentMethodsApi.setPrimaryPaymentMethod, action.payload);
    yield put(PaymentMethodActions.setPrimaryCardSucceeded(payment));
  } catch (error) {
    yield put(PaymentMethodActions.setPrimaryCardFailed());
    console.error('paymentMethod: remove card failed', error);
  }
}

function* setBackupCard(action: ActionWith<string>): SagaIterator {
  try {
    const payment: PaymentSourcesModel = yield call(PaymentMethodsApi.setBackupPaymentMethod, action.payload);
    yield put(PaymentMethodActions.setBackupCardSucceeded(payment));
  } catch (error) {
    yield put(PaymentMethodActions.setBackupCardFailed());
    console.error('paymentMethod: remove card failed', error);
  }
}

function* getBillingHistory(action: ActionWith<{ limit: number, offset: string }>): SagaIterator {
  try {
    const { limit, offset } = action.payload;
    const billingCollection: BillingCollection = yield call(PaymentMethodsApi.getBillingHistory, limit, offset);
    yield put(PaymentMethodActions.fetchBillingHistory({ billingCollection, append: !!offset }));
  } catch (error) {
    PaymentMethodActions.setBillingHistoryStatus(RequestStatus.Failed);
    console.error('history: fetch billing history failed', error);
  }
}

function* getInvoicesPdf(action: ActionWith<string>): SagaIterator {
  try {
    const pdfDownloadLink: string = yield call(PaymentMethodsApi.getInvoicesPdf, action.payload);
    yield call(TwoDApi.downloadInvoicesPdf, pdfDownloadLink);
  } catch (error) {
    console.error('history: get invoice pdf link failed', error);
  }
}


export function* paymentSagas(): SagaIterator {
  yield takeLatest(PaymentMethodActionTypes.CREATE_CARD_LEGACY, createCardLegacy);
  yield takeLatest(PaymentMethodActionTypes.REMOVE_CARD, removeCard);
  yield takeLatest(PaymentMethodActionTypes.GET_PAYMENT_DATA, getCardsAndBillingCountries);
  yield takeLatest(PaymentMethodActionTypes.GET_PAYMENT_METHODS, getPaymentSources);
  yield takeLatest(PaymentMethodActionTypes.SET_PRIMARY_CARD, setPrimaryCard);
  yield takeLatest(PaymentMethodActionTypes.SET_BACKUP_CARD, setBackupCard);
  yield takeLatest(PaymentMethodActionTypes.GET_BILLING_HISTORY, getBillingHistory);
  yield takeLatest(PaymentMethodActionTypes.GET_INVOICES_PDF, getInvoicesPdf);
}
