import styled from 'styled-components';

export const UserTooltipContainer = styled.div`
  background-color: ${p => p.theme.color.backgroundRush};
  box-shadow: ${p => p.theme.shadow.xl};
  border-radius: 15px;
  width: 190px;
  padding: 5px 0;
`;

