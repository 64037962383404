import { MultilevelSelectOptionDataWithObjectValue } from 'common/components/multi-level-drop-down-menu';
import { MeasureUtil } from 'common/utils/measure-util';
import { MoveToCellOptionData, MoveToCellOptionType } from '../drawings-canvas-menus';
import { DrawingsInstanceType } from '../enums';
import { DrawingsSelectAggregationGroup } from '../interfaces/drawings-geometry';
import { DrawingsGeometryInstance } from '../interfaces/drawings-geometry-instance';
import { DrawingsGeometryUtils } from './drawings-geometry-utils';

function makeMenuItem<T>(name: string, value: T): MultilevelSelectOptionDataWithObjectValue<T> {
  return {
    value,
    name,
    isSelectable: true,
  };
}

function makeMenuOption(
  type: MoveToCellOptionType,
  value: number = null,
): MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData> {
  return makeMenuItem(MeasureUtil.measureVisibleName[type] || type, { type, value });
}

function getAggregationType(instance: DrawingsGeometryInstance): DrawingsSelectAggregationGroup {
  if (!instance) {
    console.error('cannot get aggregation instance doesn`t exist');
    return DrawingsSelectAggregationGroup.Unknown;
  }
  if (DrawingsGeometryUtils.isClosedContour(instance.type, instance.geometry)) {
    return DrawingsSelectAggregationGroup.Area;
  } else if (DrawingsGeometryUtils.isPolyline(instance.type, instance.geometry)) {
    return DrawingsSelectAggregationGroup.Line;
  } else {
    return DrawingsSelectAggregationGroup.Count;
  }
}

function getInstanceId(
  selectedInstances: string[],
  instances: Record<string, DrawingsGeometryInstance>,
  selectedGroupType?: DrawingsSelectAggregationGroup,
): string {
  return selectedGroupType
    ? selectedInstances.find(x => getAggregationType(instances[x]) === selectedGroupType)
    : selectedInstances[0];
}

const AGGREGATION_TO_INSTANCE_TYPES: Record<DrawingsSelectAggregationGroup, DrawingsInstanceType[]> = {
  [DrawingsSelectAggregationGroup.Area]: [DrawingsInstanceType.Polygon, DrawingsInstanceType.Rectangle],
  [DrawingsSelectAggregationGroup.Line]: [DrawingsInstanceType.Polyline],
  [DrawingsSelectAggregationGroup.Count]: [DrawingsInstanceType.Count],
  [DrawingsSelectAggregationGroup.Unknown]: [],
};

export const DrawingsMenuUtils = {
  makeMenuItem,
  makeMenuOption,
  getAggregationType,
  getInstanceId,
  AGGREGATION_TO_INSTANCE_TYPES,
};
