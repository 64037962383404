import { RowNode } from 'ag-grid-community';

import { EMPTY_VALUE } from 'common/components/tree-filter-panel/constants';
import { UnitUtil } from 'common/utils/unit-util';
import { ExtractorConfig } from '../../../../../../units/projects/interfaces/graph-storage-records-config';
import { QtoLeftPanelConstants } from '../../../quantity-take-off-left-panel/constants';
import { isEFPrefix } from '../../../quantity-take-off-left-panel/is-ef-prefix';


export const getAggregationCellValue = (node: RowNode): string => {
  const { f_name, f_elem_type } = node.allLeafChildren[0].data;
  const title = `${f_elem_type}${f_name && f_name.toLowerCase() !== EMPTY_VALUE ? `: ${f_name}` : ''}`;
  return title !== EMPTY_VALUE ? title : 'Measurements';
};

export interface AggregationExtractor {
  name: string;
  value: number;
  unit: string;
  orderIndex: number;
}

export const getAggregationCellExtractorValues = (
  node: RowNode,
  extractorsDisplayNames: ExtractorConfig,
  isImperialUnit: boolean,
): Record<string, AggregationExtractor> => {
  const agg = {};
  node.allLeafChildren.forEach(child => {
    Object.keys(extractorsDisplayNames).forEach(key => {
      const value = Number(child.data[key]);
      if (!Number.isNaN(value)) {
        const efName = extractorsDisplayNames[key].name;
        const unit = isImperialUnit
          ? extractorsDisplayNames[key].imerialUnit
          : extractorsDisplayNames[key].unit;
        if (!agg[key]) {
          agg[key] = {
            name: efName,
            value: 0,
            unit: UnitUtil.getSupUnit(unit as string),
            orderIndex: extractorsDisplayNames[key][QtoLeftPanelConstants.QTO_COLUMNS_ELEMENT],
          };
        }
        agg[key].value += isImperialUnit
          ? UnitUtil.convertUnit(
            value,
            extractorsDisplayNames[key].unit as string,
            extractorsDisplayNames[key].imerialUnit as string,
          ).value
          : value;
      }
    });
  });

  return agg;
};

export const getAggregationCellExtractorsCount = (node: RowNode): number => {
  const usedKeys = {};
  node.allLeafChildren.forEach(child => {
    Object.entries(child.data).forEach(([key, value]) => {
      if (isEFPrefix(key) && !Number.isNaN(Number(value))) {
        if (!usedKeys[key]) {
          usedKeys[key] = true;
        }
      }
    });
  });

  return Object.keys(usedKeys).length;
};
