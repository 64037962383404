import styled from 'styled-components';

import { CustomDialogStyled } from 'common/components/custom-dialog';
import { TRANSITION_POINT } from '../../../../constants';


const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: ${props => props.theme.color.backgroundRush};

  @media all and (max-width: ${TRANSITION_POINT}px) {
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: 100%;
  max-width: 640px;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    max-width: 100%;
  }
`;

const HeaderTextContainer = styled.div`
  display: flex;
  margin-bottom: 35px;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    margin-bottom: 44px;
    h2 {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
`;

const MessageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 640px;

  @media all and (max-width: ${TRANSITION_POINT}px) {
    max-width: 100%;
  }
`;

const DialogContainer = styled.div`
  .invite-teammates-dialog {
    ${CustomDialogStyled.Buttons} {
      button:nth-child(1) {
        > p {
          color: ${p => p.theme.color.mainFont};
        }
      }
    }
  }
`;

export const Styled = {
  Container,
  Wrapper,
  HeaderTextContainer,
  BodyContainer,
  MessageContentContainer,
  DialogContainer,
};
