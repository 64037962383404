import { RectangleButton, Text, TinyText } from '@kreo/kreo-ui-components';
import React from 'react';
import { RenderIf } from 'common/components/render-if';

import { Mood } from 'common/enums/mood';
import { Styled } from './styled';

interface Props {
  name: string;
  description: string;
  icon: string;
  buttonText: string;
  targetRef: string;
  handleClick: () => void;
}

export const Option: React.FC<Props> = ({ name, description, buttonText, icon, targetRef, handleClick }) => {
  return (
    <Styled.OptionContainer>
      <Styled.OptionHeader>
        <img src={`/static/icons/${icon}.svg`} />
        <Text fontSize={14}>
          {name}
        </Text>
      </Styled.OptionHeader>
      <TinyText color={'gray'}>
        {description}
      </TinyText>
      <RenderIf condition={!!handleClick && !targetRef}>
        <RectangleButton
          mood={Mood.Secondary}
          onClick={handleClick}
          text={buttonText}
          height={20}
          fontSize={12}
        />
      </RenderIf>
      <RenderIf condition={!!handleClick && !!targetRef}>
        <a
          href={targetRef}
          target='_blank'
        >
          <RectangleButton
            mood={Mood.Secondary}
            onClick={handleClick}
            text={buttonText}
            height={20}
            fontSize={12}
          />
        </a>
      </RenderIf>
    </Styled.OptionContainer>
  );
};
