import styled from 'styled-components';

import { LinkComponentStyled } from '../link-component';
import { Text } from '../text';

const Container = styled.div<{ canAddUser: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.color.backgroundRush};
  width: 100%;
  border-radius: 40px;
  &, * {
    box-sizing: border-box;
  }
  button {
    margin-left: auto;
  }
  > ${Text} {
    margin-bottom: 40px;
  }

  ${LinkComponentStyled.DivContainer} {
    margin-top: ${p => p.canAddUser ? 7 : 0}px;
  }
`;

const ContinueButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  margin-top: 30px;

  > button {
    width: fit-content;
    padding: 0 28px;
    margin-left: 0;
  }
`;

const FieldNames = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Styled = {
  Container,
  ContinueButtonContainer,
  FieldNames,
};
