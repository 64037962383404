import { AuthClientEvent, AuthClientTokens } from '@react-keycloak/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, { useCallback, useMemo } from 'react';

import { EnvironmentConfigurationProps } from 'common/environment/environment-configuration-props';
import { withEnvironmentConfiguration } from 'common/environment/with-environment-configuration';
import { ServiceWorkerConnector } from 'common/utils/service-worker';
import { ServiceWorkerActions } from 'common/utils/service-worker/service-worker-messaging';
import { KeyCloakService } from '../../keycloak';
import { getRedirectUrl } from './get-redirect-url';
import { KeycloakAuthRender } from './keycloak-auth-render';


interface Props extends EnvironmentConfigurationProps {
}

const KeycloakProviderComponent: React.FC<Props> = ({ children, environmentConfiguration }) => {
  const KeycloakInitSettings = useMemo(() => {
    return {
      redirectUri: getRedirectUrl(true),
      onLoad: 'login-required',
    };
  }, []);

  const keycloakInstance = useMemo(() => {
    const authHost = environmentConfiguration.serverHost || window.location.origin;
    return KeyCloakService.initInstance(`${authHost}/auth`);
  }, [environmentConfiguration.serverHost]);

  const onKeyCloakEvent = useCallback((eventType: AuthClientEvent) => {
    switch (eventType) {
      case 'onAuthError':
        KeyCloakService.logIn();
        break;
      case 'onAuthLogout':
      case 'onAuthRefreshError':
        KeyCloakService.logIn(getRedirectUrl());
        break;
      default:
    }
  }, []);

  const onTokensUpdated = useCallback(({ token }: AuthClientTokens) => {
    ServiceWorkerConnector.sendMessage(ServiceWorkerActions.setToken(token));
  }, []);

  return (
    <ReactKeycloakProvider
      initOptions={KeycloakInitSettings}
      authClient={keycloakInstance}
      onEvent={onKeyCloakEvent}
      onTokens={onTokensUpdated}
    >
      <KeycloakAuthRender>
        {children}
      </KeycloakAuthRender>
    </ReactKeycloakProvider>
  );
};

export const KeycloakProvider = withEnvironmentConfiguration(KeycloakProviderComponent);
