import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';

import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { State } from 'common/interfaces/state';
import { useUndoRedoFullApi } from 'common/undo-redo';
import { AccountActions } from '../../../../account/actions/creators';


interface UnitsSwitcherState {
  toggle: () => void;
  isImperial: boolean;
}

export function useReduxState(): UnitsSwitcherState {
  const { addUndoRedo } = useUndoRedoFullApi();

  const dispatch = useDispatch();

  const toggle = useCallback(() => {
    const toggleInState = (): Action => dispatch(AccountActions.toggleImperialMetric());
    addUndoRedo(toggleInState, toggleInState);
    toggleInState();
    dispatch(TwoDElementViewActions.handleChangeIsImperial());
  }, [addUndoRedo, dispatch]);

  const isImperial = useSelector<State, boolean>(s => s.account.settings.isImperial);

  return {
    toggle,
    isImperial,
  };
}
