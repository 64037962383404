import React, { useCallback } from 'react';

import { useWizzardSetting } from 'common/components/drawings/hooks';
import { useRendererApiContext } from '../../../drawings-contexts';
import { ResultsMenu } from '../results-menu';
import { useResultCount } from './hooks';
import { useFinderSettingsRedux } from './use-finder-settings-state';

const FinderResultOverviewComponent: React.FC = () => {
  const { count, visibleCount } = useResultCount();
  const { rendererApi } = useRendererApiContext();
  const [ similarity, onChangeSimilarity] = useWizzardSetting('finderSimilarity');
  const { dropFinderArea } = useFinderSettingsRedux();

  const apply = useCallback(() => {
    if (rendererApi?.engine?.applyFinder) {
      return rendererApi.engine.applyFinder().then(() => {
        dropFinderArea();
        return;
      });
    } else {
      return Promise.resolve();
    }
  }, [ rendererApi, dropFinderArea]);

  return (
    <ResultsMenu
      apply={apply}
      count={count}
      withSimilaritySlider={true}
      minSimilarity={80}
      maxSimilarity={100}
      similarity={similarity}
      onSimilarityChange={onChangeSimilarity}
      visibleCount={visibleCount}
    />
  );
};


export const FinderResultOverview = React.memo(FinderResultOverviewComponent);
