import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import './index.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { ScenarioModule, ScenarioState } from '../../../projects/interfaces/scenario-state';
import { ScenarioStateHelper } from '../../../projects/utils/scenario-state-helper';
import { StepActions } from '../../actions';
import { ActivityGroupingStepPage } from '../../interfaces';
import { ActivityGroupingPage } from '../activity-grouping-page';
import { SequenceEngineContainer } from '../engine-activity-grouping';
import { MacroSequencePage } from '../macro-sequence-page';


interface ReduxActions {
  loadActivityGroups: (scenarioId: number) => void;
  loadMacroSequence: (scenarioId: number) => void;
  updateActivityGrouping: (scenarioId: number, recalculate: boolean) => void;
  updateMacroSequence: (scenarioId: number) => void;
  dropState: () => void;
}

interface ReduxProps {
  activeStepPage: ActivityGroupingStepPage;
  activityGroupingStatus: RequestStatus;
  updateActivityGroupingStatus: RequestStatus;
  macroSequenceStatus: RequestStatus;
  activityGroupingWasChanged: boolean;
  macroSequenceWasChanged: boolean;
  scenarioState: ScenarioState;
}

interface Props extends ReduxProps, ReduxActions {
  projectId: number;
  scenarioId: number;
}

export class ActivityGroupingStepComponent extends React.Component<Props> {
  public componentDidMount(): void {
    this.loadDataIfNeeded(null);
  }

  public componentWillUnmount(): void {
    if (this.props.activityGroupingWasChanged) {
      this.props.updateActivityGrouping(this.props.scenarioId, true);
    }
    if (this.props.macroSequenceWasChanged) {
      this.props.updateMacroSequence(this.props.scenarioId);
    }

    this.props.dropState();
  }

  public componentDidUpdate(previousProps: Props): void {
    if (previousProps.activeStepPage === ActivityGroupingStepPage.ActivityGrouping &&
      this.props.activeStepPage === ActivityGroupingStepPage.MacroSequence &&
      this.props.activityGroupingWasChanged) {
      this.props.updateActivityGrouping(this.props.scenarioId, true);
    }

    this.loadDataIfNeeded(previousProps);
  }

  public render(): JSX.Element {
    return (
      <div className='activity-grouping-step'>
        {
          this.props.activeStepPage === ActivityGroupingStepPage.ActivityGrouping
            ? this.renderActivityGroupingPage()
            : null
        }
        {
          this.props.activeStepPage === ActivityGroupingStepPage.MacroSequence
            ? this.renderMacroSequencePage()
            : null
        }
        <SequenceEngineContainer
          projectId={this.props.projectId}
          page={this.props.activeStepPage}
        />
      </div>
    );
  }

  private renderActivityGroupingPage(): JSX.Element {
    return this.props.activityGroupingStatus === RequestStatus.Loaded ? (
      <ActivityGroupingPage
        scenarioId={this.props.scenarioId}
        projectId={this.props.projectId}
      />
    ) : <SvgSpinner size='large'/>;
  }

  private renderMacroSequencePage(): JSX.Element {
    return this.props.activityGroupingStatus === RequestStatus.Loaded &&
      this.props.macroSequenceStatus === RequestStatus.Loaded &&
      this.props.updateActivityGroupingStatus === RequestStatus.Loaded ? (
      <MacroSequencePage
        scenarioId={this.props.scenarioId}
      />
      ) : <SvgSpinner size='large'/>;
  }

  private loadDataIfNeeded(prevProps: Props): void {
    const scenarioState = this.props.scenarioState;
    const prevScenarioState = prevProps && prevProps.scenarioState;
    const activityGroupingReady = ScenarioStateHelper
      .shouldLoadModel(scenarioState, prevScenarioState, ScenarioModule.ActivitiesGrouping);
    const macroSequenceReady = ScenarioStateHelper
      .shouldLoadModel(scenarioState, prevScenarioState, ScenarioModule.MacroLimitations);

    if (activityGroupingReady) {
      this.props.loadActivityGroups(this.props.scenarioId);
    }
    if (macroSequenceReady) {
      this.props.loadMacroSequence(this.props.scenarioId);
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const scenarioGuid = state.scenarios.editScenario && state.scenarios.editScenario.scenarioGuid;
  const scenarioState = state.projects.scenarioStates[scenarioGuid];

  return {
    activeStepPage: state.activityGrouping.activeStepPage,
    activityGroupingStatus: state.activityGrouping.statuses.activityGrouping,
    updateActivityGroupingStatus: state.activityGrouping.statuses.updateActivityGrouping,
    macroSequenceStatus: state.activityGrouping.statuses.macroSequence,
    activityGroupingWasChanged: !!state.activityGrouping.activityGrouping.changes.activities.length
      || !!state.activityGrouping.activityGrouping.changes.activityGroups.length
      || !!state.activityGrouping.activityGrouping.changes.categories.length
      || !!state.activityGrouping.activityGrouping.changes.removedCategoryIds.length
      || !!state.activityGrouping.activityGrouping.changes.removedActivityGroupIds.length,
    macroSequenceWasChanged: !!state.activityGrouping.macroSequence.changes.length,
    scenarioState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadActivityGroups: (scenarioId) => dispatch(StepActions.loadActivityGroups(scenarioId)),
    loadMacroSequence: (scenarioId) => dispatch(StepActions.loadMacroSequence(scenarioId)),
    updateActivityGrouping: (scenarioId, recalculate) =>
      dispatch(StepActions.updateActivityGrouping(scenarioId, recalculate)),
    updateMacroSequence: (scenarioId) => dispatch(StepActions.updateMacroSequence(scenarioId)),
    dropState: () => dispatch(StepActions.dropState()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityGroupingStep = connector(ActivityGroupingStepComponent);
