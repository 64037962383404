import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './quantity-take-off-report-table.scss';

import { AgGridHelper } from 'common/ag-grid';
import { DrawingsElementTemplatesConstants } from 'common/components/drawings/constants/drawing-element-templates';
import { SvgSpinner } from 'common/components/svg-spinner';
import { TreeTable } from 'common/components/tree-table';
import { HighlightFormulaHelper } from 'common/components/tree-table/highlight-formula-helper';
import {
  TreeTableGroupRules,
  TreeTableRow,
  TreeTableRowAddModel,
  TreeTableRowType,
  TreeTableRowUpdateModel,
  TreeTableValueGetterParams,
  TreeTableValueSetterParams,
} from 'common/components/tree-table/interfaces';
import { ApproveDialogs } from 'common/components/tree-table/tree-table';
import { ConstantFunctions } from 'common/constants/functions';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { MetricUnitConversationMap, UnitUtil } from 'common/utils/unit-util';
import { UuidUtil } from 'common/utils/uuid-utils';
import { QuantityTakeOffReportActions } from '../../actions/creators/quantity-take-off-report';
import { QtoReportRowUpdateType } from '../../enums/qto-report-row-update-type';
import { GraphStorageRecordsConfig } from '../../interfaces/graph-storage-records-config';
import {
  CreateQtoTreeRowsForm,
  ModelType,
  QtoReport,
  QtoReportRow,
  QtoTreeColumnsForm,
  QtoTreeRowForm,
  QtoTreeRowProperty as PropertyType,
  ReorderQtoTreeRowsForm,
} from '../../interfaces/quantity-take-off';
import { QtoSyncBreakdownData } from '../../interfaces/quantity-take-off/sync-breakdown-data';
import {
  aggregationFunctions,
  MergeRowPropertiesHelper,
  PropertyHelper,
  QtoColumnPropertyHelper,
  QtoTreeTableHelper,
  TreeTableAgg,
} from '../../utils/quantity-take-off-tree-table';
import { getFunctionValue } from '../../utils/quantity-take-off-tree-table/get-function-value';
import {
  QuantityTakeOffReportRowDiffStatusUtils,
} from '../../utils/quantity-take-off-tree-table/row-diff-status-utils';
import { QtoTreeTableCopyPastHelper } from '../../utils/quantity-take-tree-table-off-copy-past-helper';
import { TableApi } from '../quantity-take-off-left-panel/interfaces';
import { TreeTableCellEdit } from '../quantity-take-off-tree-table-cell-edit/tree-table-cell-edit';
import {
  CONFIRM_IMPORT_DIALOG_NAME,
  CONFIRM_IMPORT_DISABLE_DIALOG_NAME,
  ConfirmationDialogNames,
  DROP_ELEMENT_CONFIRM_DIALOG,
  ELEMENT_REMOVE_CONFIRM_DIALOG,
  QtoTreeTableConfirmationDialogs,
} from '../quantity-take-off-tree-table-confirmation-dialog';
import { QtoReportTableGroupRules } from './quantity-take-off-report-table-group-rules';
import { QtoTreeTableUpdater } from './quantity-take-off-report-table-updater';
import { QtoTreeTableCellNonEdit } from './quantity-take-off-tree-table-cell-non-edit';
import { QtoTreeTableCommon } from './quantity-take-off-tree-table-common';
import { QuantityTakeOffTreeTableNameCell } from './quantity-take-off-tree-table-name-cell';
import { ReportTableImporter } from './report-table-data-transfer';
import { QtoReportRowDragHelper } from './utils';


const GROUPED_COLUMN_KEY = 'name';
const TABLE_UPDATE_DELAY = 1000;

interface OwnState {
  reportTableDataImporter: ReportTableImporter;
  showDiff: boolean;
  rows: QtoReportRow[];
}

interface OwnProps {
  readonly: boolean;
  saveRef: (ref: QtoReportTableComponent) => void;
  findInElements: (ids: Array<string | number>) => void;
  projectId: number;
  modelType: ModelType;
  onSelectionChange: (ids: number[]) => void;
  sendTableSelectionApi: (ref: TableApi) => void;
  getSyncBreakdownData: (nodes: Ag.RowNode[]) => QtoSyncBreakdownData;
  onReportReady: () => void;
}

interface ReduxProps {
  isSync: boolean;
  isImperial: boolean;
  selectedReportId: number | null;
  loadReportStatus: RequestStatus;
  reportModel: QtoReport;
  disableShowDialogList: string[];
  recordsConfig: GraphStorageRecordsConfig;
}

interface DispatchProps {
  loadReport: (reportId: number) => void;
  updateColumns: (reportId: number, columnsForm: QtoTreeColumnsForm) => void;
  updateRows: (reportId: number, rows: QtoTreeRowForm[]) => void;
  addRows: (reportId: number, rows: CreateQtoTreeRowsForm) => void;
  removeRows: (reportId: number, rowIds: string[]) => void;
  reorderRows: (reportId: number, form: ReorderQtoTreeRowsForm) => void;
  openDialog: (name: string) => void;
}

interface Props extends OwnProps, DispatchProps, ReduxProps { }

const confirmationDialogNames: ConfirmationDialogNames = {
  removeElementsName: `REPORT_${ELEMENT_REMOVE_CONFIRM_DIALOG}`,
  importsElementsName: `REPORT_${CONFIRM_IMPORT_DIALOG_NAME}`,
  importsElementsDisableName: `REPORT_${CONFIRM_IMPORT_DISABLE_DIALOG_NAME}`,
  moveElementsName: `REPORT_${DROP_ELEMENT_CONFIRM_DIALOG}`,
};

export class QtoReportTableComponent extends React.Component<Props, OwnState> {
  public tableRef: TreeTable<PropertyType, PropertyType>;

  private groupRules: TreeTableGroupRules = new QtoReportTableGroupRules();
  private rowDragHelper = new QtoReportRowDragHelper(300, this.groupRules);
  private tableUpdater: QtoTreeTableUpdater;
  private confirmationDialogActions: Record<string, (cancel?: boolean) => void> = {};
  private highlighter: HighlightFormulaHelper = new HighlightFormulaHelper((event: Ag.CellEditingStartedEvent) => {
    const columnId = event.column.getColId();
    return event.data.properties[columnId];
  });

  private rowClassRules: Record<string, string | ((data: any) => boolean)> = {
    'quantity-take-off-report-table__row--added': (row) => {
      return QuantityTakeOffReportRowDiffStatusUtils.getDiffStatus(row.data) === QtoReportRowUpdateType.Added;
    },
    'quantity-take-off-report-table__row--deleted': (row) => {
      return QuantityTakeOffReportRowDiffStatusUtils.getDiffStatus(row.data) === QtoReportRowUpdateType.Deleted;
    },
  };

  private approveDialogs: ApproveDialogs = {
    openImportDialog: this.openImportDialog,
    openDropDialog: this.openDropElementDialog,
    openImportDisableDialog: this.openImportDisableDialog,
  };

  constructor(props: Props) {
    super(props);
    props.saveRef(this);
    this.state = {
      reportTableDataImporter: null,
      showDiff: false,
      rows: null,
    };
  }

  public static getDerivedStateFromProps(props: Props, prevState: OwnState): Partial<OwnState> {
    const showDiff = props.selectedReportId && props.reportModel && props.reportModel.showDiff;
    const isDataLoading = props.selectedReportId && props.loadReportStatus === RequestStatus.Loaded;
    if (prevState.showDiff !== showDiff) {
      return {
        showDiff,
        rows: isDataLoading
          ? showDiff
            ? props.reportModel.rows
            : props.reportModel.rows.filter(QuantityTakeOffReportRowDiffStatusUtils.isRowNotDeleted)
          : null,
      };
    } else if (!prevState.rows && isDataLoading) {
      return {
        rows: showDiff
          ? props.reportModel.rows
          : props.reportModel.rows.filter(QuantityTakeOffReportRowDiffStatusUtils.isRowNotDeleted),
      };
    } else if (prevState.rows && !isDataLoading) {
      return {
        rows: null,
      };
    }
    return { showDiff };
  }

  public executeAllChanges(): void {
    if (this.tableUpdater) {
      this.tableUpdater.executeImmediatelyAll();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.selectedReportId) {
      const currentShowDiff = this.props.reportModel && this.props.reportModel.showDiff;
      const prevShowDiff = prevProps.reportModel && prevProps.reportModel.showDiff;
      if (this.props.selectedReportId !== prevProps.selectedReportId) {
        this.createTableUpdater();
        this.props.loadReport(this.props.selectedReportId);
      } else if (currentShowDiff !== prevShowDiff) {
        if (this.props.loadReportStatus === RequestStatus.Loaded && this.tableRef.gridColumnApi) {
          const columnsForChange = [];
          for (const column of this.tableRef.gridColumnApi.getAllColumns()) {
            const id = column.getColId();
            if (QtoTreeTableHelper.isColumnIdHasDiffPostfix(id) && currentShowDiff !== column.isVisible()) {
              columnsForChange.push(column);
            }
          }
          this.tableRef.gridColumnApi.setColumnsVisible(columnsForChange, currentShowDiff);
        }
      }
    }
    if (this.props.isSync !== prevProps.isSync && !this.props.isSync) {
      this.tableUpdater.cancelAll();
    }
    if (this.props.isImperial !== prevProps.isImperial) {
      QtoTreeTableCommon.refreshTable(this.tableRef);
    }
  }

  public render(): JSX.Element {
    const canEdit = !this.props.readonly;
    const report = this.props.reportModel;
    const hideDiff = !this.props.reportModel || !this.props.reportModel.showDiff;
    const className = classNames(
      'quantity-take-off-report-table',
      {
        'quantity-take-off-report-table__hide-diff': hideDiff,
      },
    );

    return (
      <div className={className}>
        {this.props.loadReportStatus === RequestStatus.Loaded ? (
          <>
            <TreeTable<PropertyType, PropertyType>
              tableId='qtoReportTable'
              rowDrag={canEdit}
              withSideBar={true}
              groupRules={this.groupRules}
              groupedColumnKey={GROUPED_COLUMN_KEY}

              treeTableData={{
                firstLevelColumns: report.basicColumns.firstLevelColumns,
                columns: report.basicColumns.columns,
                firstLevelRows: report.firstLevelRows,
                rows: this.state.rows,
              }}
              rowDragHelper={this.rowDragHelper}
              onColumnUpdate={this.onColumnsUpdate}
              onReorderRows={this.onReorderRows}
              onRowsUpdate={this.onRowsUpdate}
              onAddNewRows={this.onRowsCreate}
              onRemoveRows={this.onRowsRemove}
              cellValueGetter={this.cellValueGetter}
              cellValueSetter={canEdit ? this.cellValueSetter : null}
              onSelectionChange={this.props.onSelectionChange}
              getRowNodeId={this.getElementId}
              sendTableSelectionApi={this.props.sendTableSelectionApi}
              dataImporter={this.state.reportTableDataImporter}
              ref={this.saveTableRef}
              onGridReady={this.onGridReady}
              defaultColumnsDef={this.getDefaultColumnsDef()}
              getColumnDef={QtoTreeTableCommon.getColumnDef}
              getColumnProperties={QtoTreeTableCommon.getColumnProperties}
              onRowNameChanged={canEdit ? this.onRowNameChanged : null}
              onColumnNameChanged={canEdit ? this.onColumnHeaderRename : null}
              getColumnHeaderDisplayName={this.getColumnHeaderDisplayName}
              context={{ isImperial: this.props.isImperial, hideDiff, highlight: this.highlighter }}
              overrideGridOptions={{
                getMainMenuItems: this.getMainMenuItems,
                getContextMenuItems: this.getContextMenuItems,
                rowClassRules: this.rowClassRules,
                components: {
                  qtoReportTableCellEdit: TreeTableCellEdit,
                  qtoTreeTableCellNonEdit: QtoTreeTableCellNonEdit,
                },
                autoGroupColumnDef: {
                  headerCheckboxSelection: true,
                  cellRendererParams: {
                    innerRenderer: QuantityTakeOffTreeTableNameCell,
                    onAggregate: canEdit ? this.onAggregateRow : null,
                  },
                },
                suppressClipboardPaste: false,
                processCellForClipboard: this.processCellForClipboard,
                processCellFromClipboard: this.processCellFromClipboard,
              }}
              approveDialogs={this.approveDialogs}
            />
            <QtoTreeTableConfirmationDialogs
              confirmationDialogActions={this.confirmationDialogActions}
              confirmationDialogNames={confirmationDialogNames}
              name='Element'
            />
          </>
        ) : this.props.loadReportStatus === RequestStatus.Loading ? (
          <SvgSpinner size='middle' />
        ) : (
              <span className='quantity-take-off-report-table__placeholder'>You will see the Selected Report here</span>
        )
        }
      </div>
    );
  }

  public syncWithElementNodes({ elementNodes, getColumn }: QtoSyncBreakdownData): void {
    const reportColumns = this.tableRef.gridColumnApi.getAllColumns();
    const existedColumns = arrayUtils.toDictionary(reportColumns, item => item.getColId(), () => true);
    const elementDataCache = arrayUtils.toDictionary(elementNodes, item => item.data.id, item => item);
    const rows = [];
    const newColumns = [];
    for (const node of this.tableRef.gridApi.getSelectedNodes()) {
      if (node.data.type !== TreeTableRowType.Element) {
        continue;
      }
      const id = node.data.properties.bimElementId || node.data.properties.drawingElementId;
      const reportData = node.data;
      const elementNode = elementDataCache[id.default];
      const merged = MergeRowPropertiesHelper.merge(
        reportData.properties,
        elementNode.data,
        existedColumns,
        getColumn,
      );
      rows.push({ id: node.id, properties: merged.properties });
      arrayUtils.extendArray(newColumns, merged.newColumns);
    }
    this.tableRef.columnController.addColumns(newColumns, 0);
    this.tableRef.rowController.updateRows(rows);
  }

  public componentWillUnmount(): void {
    if (this.props.saveRef) {
      this.props.saveRef(null);
    }
    this.executeAllChanges();
  }

  @autobind
  private getDefaultColumnsDef(): Partial<Ag.ColDef | Ag.ColGroupDef> {
    const canEdit = !this.props.readonly;

    return {
      editable: canEdit,
      menuTabs: canEdit ? [AgGridHelper.constants.columnMenuTab.generalMenuTab] : null,
      valueFormatter: QtoTreeTableCommon.cellValueFormatter,
      cellEditorSelector: (params: Ag.ICellEditorParams) => {
        const component = !QtoTreeTableHelper.isColumnIdHasDiffPostfix(params.column.getColId())
          && QuantityTakeOffReportRowDiffStatusUtils.isRowNotDeleted(params.data)
          && canEdit
          ? 'qtoReportTableCellEdit'
          : 'qtoTreeTableCellNonEdit';
        return { component };
      },
      toolPanelClass: (params) => {
        const colId = params.column && params.column.getColId();
        return QtoTreeTableHelper.isColumnIdHasDiffPostfix(colId) ? 'quantity-take-off-report-table__column-diff' : '';
      },
    };
  }
  private  getElementId(node: Ag.RowNode): string {
    return PropertyHelper.getActualValue(node.data.properties.bimElementId || node.data.properties.drawingElementId);
  }

  private createTableUpdater(): void {
    const reportId = this.props.selectedReportId;
    const callBacks = {
      onColumnsUpdate: this.props.updateColumns,
      onReorderRows: this.props.reorderRows,
      onRowsCreate: this.props.addRows,
      onRowsRemove: this.props.removeRows,
      onRowsUpdate: this.props.updateRows,
    };
    this.executeAllChanges();

    this.tableUpdater = new QtoTreeTableUpdater(reportId, TABLE_UPDATE_DELAY, callBacks);
  }

  private processCellForClipboard(params: Ag.ProcessCellForExportParams): string | number {
    return QtoTreeTableCopyPastHelper.processCellForClipboard(params);
  }

  private processCellFromClipboard(params: Ag.ProcessCellForExportParams): PropertyType {
    return QtoTreeTableCopyPastHelper.processCellFromClipboard(params);
  }

  @autobind
  private getMainMenuItems(params: Ag.GetMainMenuItemsParams): Array<string | Ag.MenuItemDef> {
    if (this.props.readonly) {
      return null;
    }

    const columnId = params.column.getId();
    const constants = AgGridHelper.constants.columnMainMenu;
    if (columnId === Ag.Constants.GROUP_AUTO_COLUMN_ID) {
      return [
        {
          name: 'Add new column',
          action: () => this.addNewColumn(0),
        },
        constants.separator,
      ].concat(params.defaultItems);
    } else {
      const disabled = this.isColumnHasValues(params.api, columnId);
      return [
        {
          name: 'Add new column',
          action: () => {
            this.addNewColumn(params.columnApi.getAllColumns().findIndex(x => x.getColId() === columnId) + 2);
          },
        },
        constants.separator,
        constants.pinSubMenu,
        constants.separator,
        constants.autoSizeThis,
        constants.separator,
        {
          name: 'Remove',
          disabled,
          tooltip: disabled ? 'You can\'t remove a non-empty column' : null,
          action: () => this.tableRef.columnController.removeColumns([columnId]),
        },
      ];
    }
  }

  @autobind
  private isColumnHasValues(api: Ag.GridApi, columnId: string): boolean {
    let hasValues = false;

    api.forEachNode(rowNode => {
      if (!hasValues && (columnId in rowNode.data.properties)) {
        hasValues = true;
      }
    });

    return hasValues;
  }

  @autobind
  private addNewColumn(index: number): void {
    this.tableRef.columnController.addColumns(
      [{
        properties: {
          [PropertyHelper.columnProperties.header]: { default: 'New Column' },
          [PropertyHelper.columnProperties.isVisible]: { default: true },
          [PropertyHelper.columnProperties.aggregationStrategy]: { default: TreeTableAgg.Sum },
        },
      }],
      index,
    );
  }

  @autobind
  private getContextMenuItems(params: Ag.GetContextMenuItemsParams): Array<string | Ag.MenuItemDef> {
    if (this.props.readonly) {
      return null;
    }

    const groupRules = this.groupRules;
    const hasCellRange = (): boolean => !!params.api.getCellRanges().length;

    if (!params.column) {
      return [
        {
          name: 'Add...',
          subMenu: [
            {
              name: `Add Group into <b>Root</b>`,
              action: () => this.addNewRow(TreeTableRowType.Group, AgGridHelper.getRootNode(params.api)),
            },
          ],
        },
      ];
    }

    if (params.column.getColId() !== Ag.Constants.GROUP_AUTO_COLUMN_ID) {
      return hasCellRange() ? ['chartRange'] : null;
    }

    const position = params.node.parent.childrenAfterGroup.findIndex(x => x.id === params.node.id);
    const result: Array<string | Ag.MenuItemDef> = [
      {
        name: 'Add...',
        subMenu: [
          {
            name: `Add Group after <b>${params.value}</b>`,
            disabled: !groupRules.availableInsertAfter(groupRules.EmptyGroupNode, params.node),
            action: () => this.addNewRow(TreeTableRowType.Group, params.node.parent, position + 1),
          },
          {
            name: `Add Group into <b>${params.value}</b>`,
            disabled: !groupRules.availableInsertInto(groupRules.EmptyGroupNode, params.node),
            action: () => {
              this.addNewRow(TreeTableRowType.Group, params.node);
              params.node.setExpanded(true);
            },
          },
          {
            name: `Add Element after <b>${params.value}</b>`,
            disabled: !groupRules.availableInsertAfter(groupRules.EmptyElementNode, params.node),
            action: () => this.addNewRow(TreeTableRowType.Element, params.node.parent, position + 1),
          },
          {
            name: `Add Element into <b>${params.value}</b>`,
            disabled: !groupRules.availableInsertInto(groupRules.EmptyElementNode, params.node),
            action: () => {
              this.addNewRow(TreeTableRowType.Element, params.node);
              params.node.setExpanded(true);
            },
          },
        ],
      },
      {
        name: 'Show in the Breakdown Table',
        action: () => this.showInElements(params.node),
      },
      {
        name: 'Remove',
        action: () => this.removeRow(params.api, params.columnApi, params.node),
      },
    ];

    return hasCellRange()
      ? result.concat(['separator', 'chartRange'])
      : result;
  }

  @autobind
  private showInElements(row: Ag.RowNode): void {
    if (row.data.type === TreeTableRowType.Group) {
      const elementsIds = [];
      for (const node of row.allLeafChildren) {
        if (node.data.type !== TreeTableRowType.Group) {
          const id = node.data.properties.bimElementId || node.data.properties.drawingElementId;
          if (id) {
            elementsIds.push(id.default);
          }
        }
      }
      this.props.findInElements(elementsIds);
    } else {
      const id = row.data.properties.bimElementId || row.data.properties.drawingElementId;
      if (id) {
        this.props.findInElements([id.default]);
      }
    }
  }

  @autobind
  private addNewRow(type: TreeTableRowType, parent: Ag.RowNode, position?: number): void {
    const parentId = !AgGridHelper.isRootNode(parent) ? parent.id : null;
    const insertPosition = position || parent.data.children.length;
    const newRows: Array<TreeTableRowAddModel<PropertyType>> = [{
      children: type === TreeTableRowType.Group ? [] : undefined,
      type,
      properties: { [GROUPED_COLUMN_KEY]: { default: `New ${type}` } },
    }];

    this.tableRef.rowController.addRows(newRows, parentId, insertPosition);
  }

  @autobind
  private removeRow(gridApi: Ag.GridApi, columnApi: Ag.ColumnApi, row: Ag.RowNode): void {
    if (
      this.props.disableShowDialogList
      && this.props.disableShowDialogList.includes(confirmationDialogNames.removeElementsName)
    ) {
      this.removeRowAction(gridApi, columnApi, row);
    } else {
      this.props.openDialog(confirmationDialogNames.removeElementsName);
      this.confirmationDialogActions[confirmationDialogNames.removeElementsName] =
        () => this.removeRowAction(gridApi, columnApi, row);
    }
  }

  @autobind
  private removeRowAction(gridApi: Ag.GridApi, columnApi: Ag.ColumnApi, row: Ag.RowNode): void {
    const rowIdsToRemove = row.isSelected()
      ? AgGridHelper.getLevelSelectedIds(gridApi, row)
      : [row.id];

    this.tableRef.rowController.removeRows(rowIdsToRemove);
    const columns = this.getEmptyAutoGenerateColumnIds(gridApi, columnApi);
    if (columns.length) {
      this.tableRef.columnController.removeColumns(columns);
    }
  }

  @autobind
  private openImportDialog(onApprove: () => void): void {
    if (
      this.props.disableShowDialogList
      && this.props.disableShowDialogList.includes(confirmationDialogNames.importsElementsName)
    ) {
      onApprove();
    } else {
      this.openDialog(confirmationDialogNames.importsElementsName, onApprove);
    }
  }

  @autobind
  private openImportDisableDialog(): void {
    if (
      this.props.disableShowDialogList
      && !this.props.disableShowDialogList.includes(confirmationDialogNames.importsElementsDisableName)
    ) {
      this.openDialog(confirmationDialogNames.importsElementsDisableName, ConstantFunctions.doNothing);
    }
  }


  @autobind
  private openDropElementDialog(onApprove: () => void, onCancel?: () => void): void {
    if (
      this.props.disableShowDialogList
      && this.props.disableShowDialogList.includes(confirmationDialogNames.moveElementsName)
    ) {
      onApprove();
    } else {
      this.openDialog(confirmationDialogNames.moveElementsName, onApprove, onCancel);
    }
  }

  @autobind
  private openDialog(dialogName: string, onApprove: () => void, onCancel?: () => void): void {
    this.confirmationDialogActions[dialogName] = (cancel: boolean) => {
      cancel ? onCancel() : onApprove();
    };
    this.props.openDialog(dialogName);
  }

  @autobind
  private getEmptyAutoGenerateColumnIds(gridApi: Ag.GridApi, columnApi: Ag.ColumnApi): string[] {
    let columnIds = columnApi.getColumnState()
      .map(x => x.colId)
      .filter(x => x !== Ag.Constants.GROUP_AUTO_COLUMN_ID && !UuidUtil.isUuid(x));

    gridApi.forEachNode(rowNode => {
      columnIds = columnIds.filter(id => !(id in rowNode.data.properties));
    });

    return columnIds;
  }


  @autobind
  private cellValueGetter(params: TreeTableValueGetterParams): string | null {
    const { node: { data }, columnId } = params;
    if (aggregationFunctions) {
      const aggregation = this.getAggregation(params);
      const aggregationFunction = aggregationFunctions[aggregation];

      if (aggregationFunction) {
        return aggregationFunction(params);
      }
    }

    if (!data.properties) {
      return null;
    }

    if (PropertyHelper.isFormula(data.properties[columnId])) {
      return getFunctionValue(columnId, params);
    } else {
      return PropertyHelper.getActualValue(data.properties[columnId]);
    }
  }

  private getAggregation(params: TreeTableValueGetterParams): string | null {
    const { node, columnId, columnApi } = params;
    if (!columnApi && node && node.data) {
      return null;
    }

    const column = columnApi.getColumn(columnId);
    if (
      node.data.type === TreeTableRowType.Group
      && PropertyHelper.isAggregation(node.data.properties[columnId])
      && column
    ) {
      const columnData = column.getColDef().cellRendererParams;
      const aggProperty = columnData[PropertyHelper.columnProperties.aggregationStrategy];
      return columnData && PropertyHelper.getActualValue(aggProperty);
    }

    return null;
  }

  private aggregateChildRow(
    data: QtoReportRow,
    columnIds: string[],
    rowsForUpdate: Array<TreeTableRowUpdateModel<PropertyType>>,
  ): void {
    if (!data.children) {
      return;
    }
    for (const child of data.children) {
      const childData = this.tableRef.gridApi.getRowNode(child).data as QtoReportRow;
      if (childData.type !== TreeTableRowType.Group) {
        continue;
      }
      const properties = { ...childData.properties };
      const currentAggregationColumns = [];
      for (const columnId of columnIds) {
        if (!properties[columnId] || PropertyHelper.isDefaultValue(properties[columnId])) {
          properties[columnId] = PropertyHelper.getAggregationProperty();
          currentAggregationColumns.push(columnId);
        }
      }

      this.aggregateChildRow(childData, currentAggregationColumns, rowsForUpdate);
      if (currentAggregationColumns.length) {
        rowsForUpdate.push({ id: childData.id, properties });
      }
    }
  }

  @autobind
  private onAggregateRow(node: Ag.RowNode): void {
    if (!this.tableRef) {
      return;
    }
    const rowsForUpdate = [];
    const columnIds = [];
    const data = node.data;
    for (const column of this.tableRef.gridColumnApi.getAllColumns()) {
      const id = column.getColId();
      if (
        this.props.recordsConfig.extractors[id]
        || DrawingsElementTemplatesConstants.templatesKeyNames[id]
        || UuidUtil.isUuid(id)
      ) {
        columnIds.push(id);
        data.properties[id] = PropertyHelper.getAggregationProperty();
      }
    }
    this.aggregateChildRow(data, columnIds, rowsForUpdate);
    rowsForUpdate.push({ id: data.id, properties: data.properties });
    this.tableRef.rowController.updateRows(rowsForUpdate);
  }


  @autobind
  private cellValueSetter(params: TreeTableValueSetterParams<PropertyType>): boolean {
    if (!this.tableRef) {
      return false;
    }
    const { columnId, newValue, data } = params;
    const rowsForUpdate = [];
    if (newValue) {
      data.properties[columnId] = newValue;
      if (PropertyHelper.isAggregation(newValue)) {
        this.aggregateChildRow(data, [columnId], rowsForUpdate);
      }
    } else {
      delete data.properties[columnId];
    }
    rowsForUpdate.push({
      id: data.id,
      properties: data.properties,
    });
    this.tableRef.rowController.updateRows(rowsForUpdate);
    return true;
  }

  @autobind
  private onRowNameChanged(row: Ag.RowNode, name: string): void {
    this.cellValueSetter({
      columnId: GROUPED_COLUMN_KEY,
      data: row.data,
      newValue: { default: name },
    });
  }

  @autobind
  private onColumnHeaderRename(id: string, name: string): void {
    QtoTreeTableCommon.onColumnHeaderRename(this.tableRef.columnController, id, name);
  }

  @autobind
  private getColumnHeaderDisplayName(displayName: string, column: Ag.Column): string {
    const isImperial = this.props.isImperial;
    const unit = QtoColumnPropertyHelper.getColumnUnit(column);
    const supUnit = UnitUtil.getSupUnit(isImperial && MetricUnitConversationMap[unit] || unit);

    return unit
      ? `${displayName}, <span class="qto-column-header-unit">${supUnit}</span>`
      : displayName;
  }

  @autobind
  private onGridReady(ref: TreeTable<PropertyType, PropertyType>): void {
    const { rowController, columnController } = ref;
    const reportTableDataImporter = new ReportTableImporter(rowController, columnController, this.props.recordsConfig);
    this.setState({
      reportTableDataImporter,
    });
    this.props.onReportReady();
  }

  @autobind
  private saveTableRef(ref: TreeTable<PropertyType, PropertyType>): void {
    this.tableRef = ref;
    if (!this.tableRef) {
      this.setState({ reportTableDataImporter: null });
    }
  }

  @autobind
  private onRowsCreate(
    targetParentId: string | null, position: number,
    rootRowIds: string[], rows: Array<TreeTableRow<PropertyType>>,
  ): void {
    const newRows = rows.reduce(
      (result, row) => {
        result[row.id] = row;
        return result;
      },
      {},
    );

    const form = {
      targetParent: targetParentId,
      targetIndex: position,
      rootRowIds,
      rows: newRows,
    };

    this.tableUpdater.onRowsCreate(form);
  }

  @autobind
  private onRowsRemove(rowIds: string[]): void {
    this.tableUpdater.onRowsRemove(rowIds);
  }

  @autobind
  private onReorderRows(targetParentId: string | null, position: number, rowIdsToMove: string[]): void {
    const form = {
      targetParent: targetParentId,
      targetIndex: position,
      rowIdsToMove,
    };
    this.tableUpdater.onReorderRows(form);
  }

  @autobind
  private onRowsUpdate(rows: QtoTreeRowForm[]): void {
    this.tableUpdater.onRowsUpdate(rows);
  }

  @autobind
  private onColumnsUpdate(columnsUpdateModel: QtoTreeColumnsForm): void {
    this.tableUpdater.onColumnsUpdate(columnsUpdateModel);
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const reportState = state.quantityTakeOff.report;
  return {
    loadReportStatus: reportState.statuses.loadReport,
    reportModel: reportState.reportModel,
    selectedReportId: reportState.selectedReportId,
    isSync: reportState.isSync,
    disableShowDialogList: state.persistedStorage.disableShowDialogList,
    isImperial: state.account.settings.isImperial,
    recordsConfig: state.quantityTakeOff.recordsConfig,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  const { projectId, modelType } = props;

  return {
    loadReport: (reportId) => dispatch(QuantityTakeOffReportActions.loadReport(projectId, modelType, reportId)),
    updateColumns: (reportId, columnsForm) =>
      dispatch(QuantityTakeOffReportActions.updateBasicColumns(projectId, modelType, reportId, columnsForm)),
    updateRows: (reportId, rows) =>
      dispatch(QuantityTakeOffReportActions.updateRows(projectId, modelType, reportId, rows)),
    addRows: (reportId, form) => dispatch(QuantityTakeOffReportActions.addRows(projectId, modelType, reportId, form)),
    removeRows: (reportId, ids) =>
      dispatch(QuantityTakeOffReportActions.removeRows(projectId, modelType, reportId, ids)),
    reorderRows: (reportId, form) =>
      dispatch(QuantityTakeOffReportActions.reorderRows(projectId, modelType, reportId, form)),
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const QtoReportTable = connector(QtoReportTableComponent);
