import React, { useCallback } from 'react';

import { ModeType, SwitcherMode } from './interfaces';
import { Styled } from './styled';
import { SwitcherItem } from './switcher-item';

interface Props<T> {
  modes: SwitcherMode[];
  currentMode: ModeType;
  toggleMode: (mode: ModeType | T & { newValue: ModeType }) => void;
  additionalParams?: T;
  elementHeight?: number;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  tooltipSize?: 'small' | 'big';
  tooltipDelay?: 's' | 'm' | 'l' | 'xl';
  backgroundColor?: string;
}

function RoundSwitcherComponent<T>({
  modes,
  currentMode,
  toggleMode,
  additionalParams,
  elementHeight,
  tooltipPosition = 'top',
  tooltipSize = 'big',
  tooltipDelay = 'l',
  backgroundColor,
}: Props<T>):  React.ReactElement {
  const leftCoefficient = modes.findIndex(m => m.mode === currentMode);
  const toggleModeCallback = useCallback((mode: ModeType) => {
    toggleMode(additionalParams ? { newValue: mode, ...additionalParams } : mode);
  }, [toggleMode, additionalParams]);

  const elementWidthInPercent = 100 / modes.length;

  return (
    <Styled.RoundPicker
      elementWidth={elementWidthInPercent}
      backgroundColor={backgroundColor}
    >
      {(currentMode !== undefined && currentMode !== null) && <Styled.RoundSwitcher
        leftCoefficient={leftCoefficient}
        elementWidthPercentage={elementWidthInPercent}
      />}
      {modes.map(element => {
        return (
          <SwitcherItem
            key={`${element.mode}`}
            element={element}
            tooltipDelay={tooltipDelay}
            tooltipPosition={tooltipPosition}
            tooltipSize={tooltipSize}
            selected={currentMode === element.mode}
            toggleMode={toggleModeCallback}
            elementHeight={elementHeight}
            controlName={element.controlName}
          />
        );
      })}
    </Styled.RoundPicker>
  );
}

export const RoundSwitcher = React.memo(RoundSwitcherComponent);
