import * as React from 'react';

export const IconUserDelete: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M3.47937624,9.29917416 C3.18049533,8.94967955 3,8.49591825 3,8 C3,6.8954305
        3.8954305,6 5,6 C6.1045695,6 7,6.8954305 7,8 C7,8.49591825 6.81950467,8.94967955 6.52062376,9.29917416
        C7.97560476,9.89764946 9,11.3291946 9,13 C9,13.2761424 8.77614237,13.5 8.5,13.5 C8.22385763,13.5 8,
        13.2761424 8,13 C8,11.3431458 6.65685425,10 5,10 C3.34314575,10 2,11.3431458 2,13 C2,13.2761424 1.77614237,
        13.5 1.5,13.5 C1.22385763,13.5 1,13.2761424 1,13 C1,11.3291946 2.02439524,9.89764946 3.47937624,
        9.29917416 Z M12.2071068,
        5.5 L14.3535534,7.64644661 C14.5488155,7.84170876 14.5488155,8.15829124 14.3535534,8.35355339 C14.1582912,
        8.54881554 13.8417088,8.54881554 13.6464466,8.35355339 L11.5,6.20710678 L9.35355339,8.35355339 C9.15829124,
        8.54881554 8.84170876,8.54881554 8.64644661,8.35355339 C8.45118446,8.15829124 8.45118446,7.84170876 8.64644661,
        7.64644661 L10.7928932,5.5 L8.64644661,3.35355339 C8.45118446,3.15829124 8.45118446,2.84170876 8.64644661,
        2.64644661 C8.84170876,2.45118446 9.15829124,2.45118446 9.35355339,2.64644661 L11.5,4.79289322 L13.6464466,
        2.64644661 C13.8417088,2.45118446 14.1582912,2.45118446 14.3535534,2.64644661 C14.5488155,2.84170876 14.5488155,
        3.15829124 14.3535534,3.35355339 L12.2071068,5.5 Z M5,9 C5.55228475,9 6,8.55228475 6,8 C6,7.44771525 5.55228475,
        7 5,7 C4.44771525,7 4,7.44771525 4,8 C4,8.55228475 4.44771525,9 5,9 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
