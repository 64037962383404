
import autobind from 'autobind-decorator';
import React from 'react';

import { Form } from 'common/components/form';
import { LabelGroup } from 'common/components/label-group';
import { RenderIf } from 'common/components/render-if';
import { arrayUtils } from 'common/utils/array-utils';
import { MenuProperties } from 'unit-2d-database/components/menu-properties';
import { IMenuProperties } from 'unit-2d-database/components/menu-properties/menu-properties';
import { VisibilityFilterType } from 'unit-2d-database/interfaces';
import { Styled } from '../styled';
import { PropertyGroup, Fields } from './interfaces';


interface Props {
  group: PropertyGroup;
  isOpen: boolean;
  visibilityMode: VisibilityFilterType;
  toggleGroup: (id: string, isOpen: boolean) => void;
  getId?: () => string;
  onAddProperty?: () => void;
  extraProperties?: IMenuProperties[];
  parentId?: string;
  hideDeleteButton?: boolean;
  disabled?: boolean;
}

interface OwnState {
  isOpenContextMenu: boolean;
}

const Filters = {
  [VisibilityFilterType.ShowAll]: (fields: Fields) => fields,
  [VisibilityFilterType.OnlyHidden]: (fields: Fields) => fields.filter(f => f.isHide),
  [VisibilityFilterType.OnlyVisible]: (fields: Fields) => fields.filter(f => !f.isHide),
};

export class GroupPropertiesFrom extends React.PureComponent<Props, OwnState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isOpenContextMenu: false,
    };
  }
  public render(): JSX.Element {
    const {
      visibilityMode,
      isOpen,
      group: { name, fields, onDelete },
      onAddProperty,
      extraProperties,
      hideDeleteButton,
      disabled,
    } = this.props;
    const { isOpenContextMenu } = this.state;

    const filterFields = Filters[visibilityMode]([...fields]);
    const sortedField = filterFields.sort((fa, fb) => arrayUtils.localCompareWithNumber(fa.name, fb.name));
    const isShowLabel = name !== undefined;

    return (
      <Styled.PropertyGroup>
        <RenderIf condition={isShowLabel}>
          <Styled.LabelGroupWrapper hideDeleteButton={hideDeleteButton}>
            <LabelGroup
              text={name}
              tooltipTextAddButton={'Add a new property to item'}
              tooltipTextDeleteButton={'Remove from the list'}
              isExpanded={isOpen}
              onGroupClick={this.onClickGroup}
              addItemToGroup={onAddProperty && this.openContextMenu}
              removeGroup={onDelete}
              addButtonContextMenu={
                isOpenContextMenu
                  ? <MenuProperties
                    closeContextMenu={this.closeContextMenu}
                    onClick={onAddProperty}
                    extraProperties={extraProperties}
                    propertyGroupName={name}
                  />
                  : undefined
              }
            />
          </Styled.LabelGroupWrapper>
        </RenderIf>
        <Styled.FormContainer hideDeleteButton={hideDeleteButton} withLabel={isShowLabel}>
          {isOpen && <Form
            fields={sortedField}
            disabled={disabled}
            getId={this.props.getId}
          />}
        </Styled.FormContainer>
      </Styled.PropertyGroup>
    );
  }

  @autobind
  private onClickGroup(): void {
    const { isOpen, toggleGroup, group, parentId } = this.props;
    toggleGroup(`${parentId}_${group.id}`, !isOpen);
  }

  @autobind
  private openContextMenu(): void {
    this.setState({ isOpenContextMenu: true });
  }

  @autobind
  private closeContextMenu(): void {
    this.setState({ isOpenContextMenu: false });
  }
}
