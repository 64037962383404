import React from 'react';
import { CustomDialog } from 'common/components/custom-dialog';
import { RenderIf } from 'common/components/render-if';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import {
  ChangeBillingRadioMenu,
} from '../../../subscription/components/subscription-details-new-user/change-billing-radio-menu';
import { UPGRADE_PRO_PLAN_CONSTANTS, UpgradePeriodOptions } from './constants';
import { useBillingPeriod, useFetchCompanyEffect, useHandleCloseCallback, useHandleSubmitCallback } from './hooks';
import { Styled } from './styled';
import { UpgradePlanDialogHeader, UpgradePlanDialogInfo } from './upgrade-plan-dialog-elements';


export const UpgradeProPlanDialog: React.FC = () => {
  useFetchCompanyEffect();
  const [billingPeriod, handleChangeBillingPeriod] = useBillingPeriod();
  const handleSubmit = useHandleSubmitCallback(UPGRADE_PRO_PLAN_CONSTANTS.PLAN_GROUP_NAME);
  const handleClose = useHandleCloseCallback();
  return (
    <>
      <DialogWrapper name={UPGRADE_PRO_PLAN_CONSTANTS.UPGRADE_PLAN_DIALOG}>
        <Styled.CustomDialogWrapper>
          <CustomDialog
            submitText={UPGRADE_PRO_PLAN_CONSTANTS.SUBMIT_TEXT}
            onSubmit={handleSubmit}
            onExit={handleClose}
            submitButtonMood={'secondary'}
            submitButtonWidth={450}
            width={490}
          >
            <UpgradePlanDialogHeader />
            <RenderIf condition={billingPeriod !== null}>
              <Styled.BillingRadioMenuWrapper>
                <ChangeBillingRadioMenu
                  billingPeriod={billingPeriod}
                  onSelectionChanged={handleChangeBillingPeriod}
                  discount={'-11%'}
                  options={UpgradePeriodOptions}
                />
              </Styled.BillingRadioMenuWrapper>
            </RenderIf>
            <UpgradePlanDialogInfo isBillingPeriod={billingPeriod !== null}/>
          </CustomDialog>
        </Styled.CustomDialogWrapper>
      </DialogWrapper>
    </>
  );
};
