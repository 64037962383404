import { Icons } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

export const IconItems = styled(Icons.Items)`
  && {
    g {
      path {
        stroke: ${p => p.theme.color.gray};
      }
    }
  }
`;
