import styled from 'styled-components';
import { ICON_DRAG } from 'common/UIKit/backgrounds-svg';

const SingleSelectValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 2px 0;
  cursor: grab;

  div {
    width: 100%;
  }
`;

const DnDIcon = styled.div`
  width: 20px !important;
  height: 20px;
  cursor: grab;
  background-image: ${p => ICON_DRAG(p.theme.color.gray)};
  &:hover {
    background-image: ${p => ICON_DRAG(p.theme.color.turquoise)};
  }
`;

export const Styled = {
  SingleSelectValueContainer,
  DnDIcon,
};
