import { Avatar, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';

interface Props {
  content: string;
  userAvatar: string;
  userName: string;
}


const UserMessageComponent: React.FC<Props> = ({
  content,
  userAvatar,
  userName,
}) => {
  return (
    <Styled.Container>
      <Avatar
        name={userName}
        avatar={userAvatar}
        size={30}
      />
      <Styled.TextContainer>
        <Text>
          {content}
        </Text>
      </Styled.TextContainer>
    </Styled.Container>
  );
};


export const UserMessage = React.memo(UserMessageComponent);
