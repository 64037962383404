import autobind from 'autobind-decorator';
import * as React from 'react';

import { Shield } from './shield';

import './point.scss';


interface Props {
  number: number;
  position: any;
  selected: boolean;
  disabled?: boolean;
  index?: number;
  onClick?: (index: number) => void;
}

export class Point extends React.Component<Props> {

  private link: HTMLDivElement;

  public shouldComponentUpdate(nextProps: any): boolean {
    const s = this.getStyle(nextProps.position);
    this.link.style.left = s.left.toString();
    this.link.style.top = s.top.toString();

    this.link.className = `viewer-comment-point ${nextProps.selected ? 'x' : ''}`;


    return false;
  }

  public render(): any {
    return (
      <div
        className='viewer-comment-point'
        ref={this.setLink}
        style={this.getStyle(this.props.position)}
        onClick={this.onClick}
      >
        <div className='viewer-comment-point-number'>
          <span className='viewer-comment-point-text'>
            {this.props.number}
          </span>
        </div>
        <Shield />
      </div>);
  }

  @autobind
  private setLink(ref: HTMLDivElement): void {
    this.link = ref;
  }

  @autobind
  private onClick(): void {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick(this.props.index);
    }
  }

  private getStyle(pos: number[]): React.CSSProperties {
    if (!pos) {
      return {};
    }
    return {
      left: `${pos[0] - 15}px`,
      top: `${pos[1] - 40}px`,
    };
  }
}


export default Point;
