import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './template-table.scss';

import { AgGridHelper } from 'common/ag-grid';
import { DrawingsElementTemplatesConstants } from 'common/components/drawings/constants/drawing-element-templates';
import { SvgSpinner } from 'common/components/svg-spinner';
import { TreeTable } from 'common/components/tree-table';
import { HighlightFormulaHelper } from 'common/components/tree-table/highlight-formula-helper';
import {
  TreeTableGroupRules,
  TreeTableRow,
  TreeTableRowAddModel,
  TreeTableRowType,
  TreeTableRowUpdateModel,
  TreeTableValueGetterParams,
  TreeTableValueSetterParams,
} from 'common/components/tree-table/interfaces';
import { ApproveDialogs } from 'common/components/tree-table/tree-table';
import { TreeTableDefaultGroupRules } from 'common/components/tree-table/tree-table-default-group-rules';
import { ConstantFunctions } from 'common/constants/functions';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { MetricUnitConversationMap, UnitUtil } from 'common/utils/unit-util';
import { UuidUtil } from 'common/utils/uuid-utils';
import { QuantityTakeOffTemplateActions } from '../../actions/creators/quantity-take-off-template';
import { GraphStorageRecordsConfig } from '../../interfaces/graph-storage-records-config';
import {
  CreateQtoTreeRowsForm,
  ModelType,
  QtoTemplate,
  QtoTemplateColumnsForm,
  QtoTemplateRowForm,
  QtoTreeColumnsForm,
  QtoTreeRowForm,
  QtoTreeRowProperty,
  ReorderQtoTreeRowsForm,
} from '../../interfaces/quantity-take-off';
import { QtoTemplateRow } from '../../interfaces/quantity-take-off/quantity-take-off-template-row';
import { QtoTemplateRowProperty } from '../../interfaces/quantity-take-off/quantity-take-off-template-row-property';
import {
  aggregationFunctions,
  PropertyHelper,
  QtoColumnPropertyHelper,
  QtoTreeTableHelper,
  TreeTableAgg,
} from '../../utils/quantity-take-off-tree-table';
import { QuantityTakeOffFormulaHelper } from '../../utils/quantity-take-off-tree-table/formula-helper';
import { QtoTreeTableCopyPastHelper } from '../../utils/quantity-take-tree-table-off-copy-past-helper';
import { TableApi } from '../quantity-take-off-left-panel/interfaces';
import { QtoTreeTableUpdater } from '../quantity-take-off-report-table/quantity-take-off-report-table-updater';
import {
  QtoTreeTableCellNonEdit,
} from '../quantity-take-off-report-table/quantity-take-off-tree-table-cell-non-edit';
import { QtoTreeTableCommon } from '../quantity-take-off-report-table/quantity-take-off-tree-table-common';
import {
  QuantityTakeOffTreeTableNameCell,
} from '../quantity-take-off-report-table/quantity-take-off-tree-table-name-cell';
import { TreeTableCellEdit } from '../quantity-take-off-tree-table-cell-edit/tree-table-cell-edit';
import {
  CONFIRM_IMPORT_DIALOG_NAME,
  CONFIRM_IMPORT_DISABLE_DIALOG_NAME,
  ConfirmationDialogNames,
  DROP_ELEMENT_CONFIRM_DIALOG,
  ELEMENT_REMOVE_CONFIRM_DIALOG,
  QtoTreeTableConfirmationDialogs,
} from '../quantity-take-off-tree-table-confirmation-dialog';
import { TemplateTableImporter } from './template-table-data-transfer';

const GROUPED_COLUMN_KEY = 'name';
const TABLE_UPDATE_DELAY = 1000;

interface OwnState {
  templateTableDataImporter: TemplateTableImporter;
}

interface OwnProps {
  modelType: ModelType;
  saveRef: (ref: QtoTemplateTableComponent) => void;
  onSelectFiltersFromTemplate: (filtersIds: string[]) => void;
}

interface ReduxProps {
  isSync: boolean;
  isImperial: boolean;
  selectedTemplateId: number | null;
  loadTemplateStatus: RequestStatus;
  templateModel: QtoTemplate;
  disableShowDialogList: string[];
  recordsConfig: GraphStorageRecordsConfig;
}

interface DispatchProps {
  loadTemplate: (id: number) => void;
  updateColumns: (templateId: number, columnsForm: QtoTreeColumnsForm) => void;
  updateRows: (templateId: number, rows: QtoTreeRowForm[]) => void;
  addRows: (templateId: number, rows: CreateQtoTreeRowsForm) => void;
  removeRows: (templateId: number, rowIds: string[]) => void;
  reorderRows: (templateId: number, form: ReorderQtoTreeRowsForm) => void;
  openDialog: (name: string) => void;
}

interface Props extends OwnProps, DispatchProps, ReduxProps { }

const confirmationDialogNames: ConfirmationDialogNames = {
  removeElementsName: `TEMPLATE_${ELEMENT_REMOVE_CONFIRM_DIALOG}`,
  importsElementsName: `TEMPLATE_${CONFIRM_IMPORT_DIALOG_NAME}`,
  moveElementsName: `TEMPLATE_${DROP_ELEMENT_CONFIRM_DIALOG}`,
  importsElementsDisableName: `TEMPLATE_${CONFIRM_IMPORT_DISABLE_DIALOG_NAME}`,
};

export class QtoTemplateTableComponent extends React.Component<Props, OwnState> {
  public tableRef: TreeTable<QtoTemplateRowProperty, QtoTemplateRowProperty>;

  private tableApi: TableApi;
  private groupRules: TreeTableGroupRules = new TreeTableDefaultGroupRules();
  private tableUpdater: QtoTreeTableUpdater;
  private confirmationDialogActions: Record<string, (cancel?: boolean) => void> = {};
  private highlighter: HighlightFormulaHelper = new HighlightFormulaHelper((event: Ag.CellEditingStartedEvent) => {
    const columnId = event.column.getColId();
    return event.data.properties[columnId];
  });

  private defaultColumnsDef: Partial<Ag.ColDef | Ag.ColGroupDef> = {
    editable: true,
    menuTabs: [AgGridHelper.constants.columnMenuTab.generalMenuTab],
    valueFormatter: QtoTreeTableCommon.cellValueFormatter,
    cellEditorSelector: (params: Ag.ICellEditorParams) => {
      return {
        component: !QtoTreeTableHelper.isColumnIdHasDiffPostfix(params.column.getColId())
          ? 'qtoTreeTableCellEdit'
          : 'qtoTreeTableCellNonEdit',
      };
    },
  };

  private approveDialogs: ApproveDialogs = {
    openImportDialog: this.openImportDialog,
    openDropDialog: this.openDropElementDialog,
    openImportDisableDialog: this.openImportDisableDialog,
  };

  constructor(props: Props) {
    super(props);
    props.saveRef(this);
    this.state = {
      templateTableDataImporter: null,
    };
  }

  public executeAllChanges(): void {
    if (this.tableUpdater) {
      this.tableUpdater.executeImmediatelyAll();
    }
  }

  public setSelected(ids: string[]): void {
    this.tableApi.setSelected(ids);
  }

  public componentDidMount(): void {
    if (this.props.selectedTemplateId) {
      this.createTableUpdater();
      this.props.loadTemplate(this.props.selectedTemplateId);
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.selectedTemplateId && this.props.selectedTemplateId !== prevProps.selectedTemplateId) {
      this.createTableUpdater();
      this.props.loadTemplate(this.props.selectedTemplateId);
    }
    if (this.props.isSync !== prevProps.isSync && !this.props.isSync) {
      this.tableUpdater.cancelAll();
    }
    if (this.props.isImperial !== prevProps.isImperial) {
      QtoTreeTableCommon.refreshTable(this.tableRef);
    }
  }

  public render(): JSX.Element {
    const template = this.props.templateModel;
    return (
      <div className='quantity-take-off-template-table'>
        {this.props.loadTemplateStatus === RequestStatus.Loaded ? (
          <>
            <TreeTable<QtoTemplateRowProperty, QtoTemplateRowProperty>
              tableId='qtoTemplateTable'
              rowDrag={true}
              withSideBar={true}
              groupRules={this.groupRules}
              groupedColumnKey={GROUPED_COLUMN_KEY}
              treeTableData={{
                firstLevelColumns: template.basicColumns.firstLevelColumns,
                columns: template.basicColumns.columns,
                firstLevelRows: template.firstLevelRows,
                rows: template.rows,
              }}
              onColumnUpdate={this.onColumnsUpdate}
              onReorderRows={this.onReorderRows}
              onRowsUpdate={this.onRowsUpdate}
              onAddNewRows={this.onRowsCreate}
              onRemoveRows={this.onRowsRemove}
              cellValueGetter={this.cellValueGetter}
              cellValueSetter={this.cellValueSetter}
              dataImporter={this.state.templateTableDataImporter}
              ref={this.saveTableRef}
              onGridReady={this.onGridReady}
              onSelectionChange={this.props.onSelectFiltersFromTemplate}
              getRowNodeId={this.getElementId}
              defaultColumnsDef={this.defaultColumnsDef}
              getColumnDef={QtoTreeTableCommon.getColumnDef}
              getColumnProperties={QtoTreeTableCommon.getColumnProperties}
              sendTableSelectionApi={this.sendTableSelectionApi}
              onRowNameChanged={this.onRowNameChanged}
              onColumnNameChanged={this.onColumnHeaderRename}
              getColumnHeaderDisplayName={this.getColumnHeaderDisplayName}
              context={{ isImperial: this.props.isImperial, highlight: this.highlighter }}
              overrideGridOptions={{
                getMainMenuItems: this.getMainMenuItems,
                getContextMenuItems: this.getContextMenuItems,
                components: {
                  qtoTreeTableCellEdit: TreeTableCellEdit,
                  qtoTreeTableCellNonEdit: QtoTreeTableCellNonEdit,
                },
                autoGroupColumnDef: {
                  cellRendererParams: {
                    innerRenderer: QuantityTakeOffTreeTableNameCell,
                    onAggregate: this.onAggregateRow,
                  },
                },
                suppressClipboardPaste: false,
                processCellForClipboard: this.processCellForClipboard,
                processCellFromClipboard: this.processCellFromClipboard,
              }}
              approveDialogs={this.approveDialogs}
            />
            <QtoTreeTableConfirmationDialogs
              confirmationDialogActions={this.confirmationDialogActions}
              confirmationDialogNames={confirmationDialogNames}
              name='Filter'
            />
          </>
        ) : this.props.loadTemplateStatus === RequestStatus.Loading ? (
          <SvgSpinner size='middle' />
        ) : (
              <span className='quantity-take-off-template-table__placeholder'>
                You will see the Selected Template here
              </span>
        )
        }
      </div>
    );
  }

  public componentWillUnmount(): void {
    this.executeAllChanges();
  }

  @autobind
  private sendTableSelectionApi(api: TableApi): void {
    this.tableApi = api;
  }

  private getElementId(node: Ag.RowNode): string {
    return PropertyHelper.getActualValue(node.data.properties.filterId);
  }

  private createTableUpdater(): void {
    const templateId = this.props.selectedTemplateId;
    const callBacks = {
      onColumnsUpdate: this.props.updateColumns,
      onReorderRows: this.props.reorderRows,
      onRowsCreate: this.props.addRows,
      onRowsRemove: this.props.removeRows,
      onRowsUpdate: this.props.updateRows,
    };
    if (this.tableUpdater) {
      this.tableUpdater.executeImmediatelyAll();
    }

    this.tableUpdater = new QtoTreeTableUpdater(templateId, TABLE_UPDATE_DELAY, callBacks);
  }

  @autobind
  private processCellForClipboard(params: Ag.ProcessCellForExportParams): string | number {
    return QtoTreeTableCopyPastHelper.processCellForClipboard(params);
  }

  @autobind
  private processCellFromClipboard(params: Ag.ProcessCellForExportParams): QtoTreeRowProperty {
    return QtoTreeTableCopyPastHelper.processCellFromClipboard(params);
  }

  @autobind
  private getMainMenuItems(params: Ag.GetMainMenuItemsParams): Array<string | Ag.MenuItemDef> {
    const columnId = params.column.getId();
    const constants = AgGridHelper.constants.columnMainMenu;
    if (columnId === Ag.Constants.GROUP_AUTO_COLUMN_ID) {
      return [
        {
          name: 'Add new column',
          action: () => this.addNewColumn(0),
        },
        constants.separator,
      ].concat(params.defaultItems);
    } else {
      const disabled = this.isColumnHasValues(params.api, columnId);
      return [
        {
          name: 'Add new column',
          action: () => {
            this.addNewColumn(params.columnApi.getAllColumns().findIndex(x => x.getColId() === columnId) + 2);
          },
        },
        constants.separator,
        constants.pinSubMenu,
        constants.separator,
        constants.autoSizeThis,
        constants.separator,
        {
          name: 'Remove',
          disabled,
          tooltip: disabled ? 'You can\'t remove a non-empty column' : null,
          action: () => this.tableRef.columnController.removeColumns([columnId]),
        },
      ];
    }
  }

  @autobind
  private isColumnHasValues(api: Ag.GridApi, columnId: string): boolean {
    let hasValues = false;

    api.forEachNode(rowNode => {
      if (!hasValues && (columnId in rowNode.data.properties)) {
        hasValues = true;
      }
    });

    return hasValues;
  }

  @autobind
  private addNewColumn(index: number): void {
    this.tableRef.columnController.addColumns(
      [{
        properties: {
          [PropertyHelper.columnProperties.header]: { default: 'New Column' },
          [PropertyHelper.columnProperties.isVisible]: { default: true },
          [PropertyHelper.columnProperties.aggregationStrategy]: { default: TreeTableAgg.Sum },
        },
      }],
      index,
    );
  }

  @autobind
  private getContextMenuItems(params: Ag.GetContextMenuItemsParams): Array<string | Ag.MenuItemDef> {
    const groupRules = this.groupRules;
    const hasCellRange = (): boolean => !!params.api.getCellRanges().length;

    if (!params.column) {
      return [
        {
          name: 'Add...',
          subMenu: [
            {
              name: `Add Group into <b>Root</b>`,
              action: () => this.addNewRow(TreeTableRowType.Group, AgGridHelper.getRootNode(params.api)),
            },
          ],
        },
      ];
    }

    if (params.column.getColId() !== Ag.Constants.GROUP_AUTO_COLUMN_ID) {
      return hasCellRange() ? ['chartRange'] : null;
    }

    const position = params.node.parent.childrenAfterGroup.findIndex(x => x.id === params.node.id);
    const result: Array<string | Ag.MenuItemDef> = [
      {
        name: 'Add...',
        subMenu: [
          {
            name: `Add Group after <b>${params.value}</b>`,
            disabled: !groupRules.availableInsertAfter(groupRules.EmptyGroupNode, params.node),
            action: () => this.addNewRow(TreeTableRowType.Group, params.node.parent, position + 1),
          },
          {
            name: `Add Group into <b>${params.value}</b>`,
            disabled: !groupRules.availableInsertInto(groupRules.EmptyGroupNode, params.node),
            action: () => {
              this.addNewRow(TreeTableRowType.Group, params.node);
              params.node.setExpanded(true);
            },
          },
          {
            name: `Add Element after <b>${params.value}</b>`,
            disabled: !groupRules.availableInsertAfter(groupRules.EmptyElementNode, params.node),
            action: () => this.addNewRow(TreeTableRowType.Element, params.node.parent, position + 1),
          },
          {
            name: `Add Element into <b>${params.value}</b>`,
            disabled: !groupRules.availableInsertInto(groupRules.EmptyElementNode, params.node),
            action: () => {
              this.addNewRow(TreeTableRowType.Element, params.node);
              params.node.setExpanded(true);
            },
          },
        ],
      },
      {
        name: 'Remove',
        action: () => this.removeRow(params.api, params.columnApi, params.node),
      },
    ];

    return hasCellRange()
      ? result.concat(['separator', 'chartRange'])
      : result;
  }

  @autobind
  private addNewRow(type: TreeTableRowType, parent: Ag.RowNode, position?: number): void {
    const parentId = !AgGridHelper.isRootNode(parent) ? parent.id : null;
    const insertPosition = position || parent.data.children.length;
    const newRows: Array<TreeTableRowAddModel<QtoTemplateRowProperty>> = [{
      children: type === TreeTableRowType.Group ? [] : undefined,
      type,
      properties: { [GROUPED_COLUMN_KEY]: { default: `New ${type}` } },
    }];

    this.tableRef.rowController.addRows(newRows, parentId, insertPosition);
  }

  @autobind
  private removeRow(gridApi: Ag.GridApi, columnApi: Ag.ColumnApi, row: Ag.RowNode): void {
    if (
      this.props.disableShowDialogList
      && this.props.disableShowDialogList.includes(confirmationDialogNames.removeElementsName)) {
      this.removeRowAction(gridApi, columnApi, row);
    } else {
      this.props.openDialog(confirmationDialogNames.removeElementsName);
      this.confirmationDialogActions[confirmationDialogNames.removeElementsName] =
        () => this.removeRowAction(gridApi, columnApi, row);
    }
  }

  @autobind
  private removeRowAction(gridApi: Ag.GridApi, columnApi: Ag.ColumnApi, row: Ag.RowNode): void {
    this.tableRef.rowController.removeRows([row.id]);
    const columns = this.getEmptyAutoGenerateColumnIds(gridApi, columnApi);
    if (columns.length) {
      this.tableRef.columnController.removeColumns(columns);
    }
  }

  @autobind
  private openImportDialog(onApprove: () => void): void {
    if (
      this.props.disableShowDialogList
      && this.props.disableShowDialogList.includes(confirmationDialogNames.importsElementsName)
    ) {
      onApprove();
    } else {
      this.openDialog(confirmationDialogNames.importsElementsName, onApprove);
    }
  }

  @autobind
  private openDropElementDialog(onApprove: () => void, onCancel?: () => void): void {
    if (
      this.props.disableShowDialogList
      && this.props.disableShowDialogList.includes(confirmationDialogNames.moveElementsName)
    ) {
      onApprove();
    } else {
      this.openDialog(confirmationDialogNames.moveElementsName, onApprove, onCancel);
    }
  }

  @autobind
  private openImportDisableDialog(): void {
    if (
      this.props.disableShowDialogList
      && !this.props.disableShowDialogList.includes(confirmationDialogNames.importsElementsDisableName)
    ) {
      this.openDialog(confirmationDialogNames.importsElementsDisableName, ConstantFunctions.doNothing);
    }
  }

  @autobind
  private openDialog(dialogName: string, onApprove: () => void, onCancel?: () => void): void {
    this.confirmationDialogActions[dialogName] = (cancel: boolean) => {
      cancel ? onCancel() : onApprove();
    };
    this.props.openDialog(dialogName);
  }

  @autobind
  private getEmptyAutoGenerateColumnIds(gridApi: Ag.GridApi, columnApi: Ag.ColumnApi): string[] {
    let columnIds = columnApi.getColumnState()
      .map(x => x.colId)
      .filter(x => x !== Ag.Constants.GROUP_AUTO_COLUMN_ID && !UuidUtil.isUuid(x));

    gridApi.forEachNode(rowNode => {
      columnIds = columnIds.filter(id => !(id in rowNode.data.properties));
    });

    return columnIds;
  }


  @autobind
  private cellValueGetter(params: TreeTableValueGetterParams): string | null {
    const { node: { data }, columnId } = params;
    if (aggregationFunctions) {
      const aggregation = this.getAggregation(params);
      const aggregationFunction = aggregationFunctions[aggregation];

      if (aggregationFunction) {
        return aggregationFunction(params);
      }
    }

    if (!data.properties) {
      return null;
    }

    if (PropertyHelper.isFormula(data.properties[columnId])) {
      const formula = PropertyHelper.getActualValue<string>(data.properties[columnId]);
      return QuantityTakeOffFormulaHelper.replaceKeyToHeader(formula, params.columnApi);
    }

    return PropertyHelper.getActualValue(data.properties[columnId]);
  }

  private getAggregation(params: TreeTableValueGetterParams): string | null {
    const { node, columnId, columnApi } = params;
    if (!columnApi && node && node.data) {
      return null;
    }

    const column = columnApi.getColumn(columnId);
    if (
      node.data.type === TreeTableRowType.Group
      && PropertyHelper.isAggregation(node.data.properties[columnId])
      && column
    ) {
      const columnData = column.getColDef().cellRendererParams;
      const aggProperty = columnData[PropertyHelper.columnProperties.aggregationStrategy];
      return columnData && PropertyHelper.getActualValue(aggProperty);
    }

    return null;
  }

  private aggregateChildRow(
    data: QtoTemplateRow,
    columnIds: string[],
    rowsForUpdate: Array<TreeTableRowUpdateModel<QtoTemplateRowProperty>>,
  ): void {
    if (!data.children) {
      return;
    }
    for (const child of data.children) {
      const childData = this.tableRef.gridApi.getRowNode(child).data as QtoTemplateRow;
      if (childData.type !== TreeTableRowType.Group) {
        continue;
      }
      const properties = { ...childData.properties };
      const currentAggregationColumns = [];
      for (const columnId of columnIds) {
        if (!properties[columnId] || PropertyHelper.isDefaultValue(properties[columnId])) {
          properties[columnId] = PropertyHelper.getAggregationProperty();
          currentAggregationColumns.push(columnId);
        }
      }
      if (currentAggregationColumns.length) {
        rowsForUpdate.push({ id: childData.id, properties });
      }
      this.aggregateChildRow(childData, currentAggregationColumns, rowsForUpdate);
    }
  }

  @autobind
  private onAggregateRow(node: Ag.RowNode): void {
    if (!this.tableRef) {
      return;
    }
    const rowsForUpdate = [];
    const columnIds = this.tableRef.gridColumnApi
      .getAllColumns()
      .map(x => x.getColId())
      .filter(x => this.props.recordsConfig.extractors[x] || DrawingsElementTemplatesConstants.templatesKeyNames[x]);
    const data = node.data;
    for (const id of columnIds) {
      data.properties[id] = PropertyHelper.getAggregationProperty();
    }
    this.aggregateChildRow(data, columnIds, rowsForUpdate);
    rowsForUpdate.push({ id: data.id, properties: data.properties });
    this.tableRef.rowController.updateRows(rowsForUpdate);
  }


  @autobind
  private cellValueSetter(params: TreeTableValueSetterParams<QtoTemplateRowProperty>): boolean {
    if (!this.tableRef) {
      return false;
    }
    const { columnId, newValue, data } = params;

    const rowsForUpdate = [];
    if (newValue) {
      data.properties[columnId] = newValue;
      if (PropertyHelper.isAggregation(newValue)) {
        this.aggregateChildRow(data, [columnId], rowsForUpdate);
      }
    } else {
      delete data.properties[columnId];
    }
    rowsForUpdate.push({
      id: data.id,
      properties: data.properties,
    });
    this.tableRef.rowController.updateRows(rowsForUpdate);
    return true;
  }

  @autobind
  private onRowNameChanged(row: Ag.RowNode, name: string): void {
    this.cellValueSetter({
      columnId: GROUPED_COLUMN_KEY,
      data: row.data,
      newValue: { default: name },
    });
  }

  @autobind
  private onColumnHeaderRename(id: string, name: string): void {
    QtoTreeTableCommon.onColumnHeaderRename(this.tableRef.columnController, id, name);
  }

  @autobind
  private getColumnHeaderDisplayName(displayName: string, column: Ag.Column): string {
    const isImperial = this.props.isImperial;
    const unit = QtoColumnPropertyHelper.getColumnUnit(column);
    const supUnit = UnitUtil.getSupUnit(isImperial && MetricUnitConversationMap[unit] || unit);

    return unit
      ? `${displayName}, <span class="qto-column-header-unit">${supUnit}</span>`
      : displayName;
  }

  @autobind
  private onGridReady(ref: TreeTable<QtoTemplateRowProperty, QtoTemplateRowProperty>): void {
    const { rowController, columnController } = ref;
    this.setState({
      templateTableDataImporter: new TemplateTableImporter(rowController, columnController, this.props.recordsConfig),
    });
  }

  @autobind
  private saveTableRef(ref: TreeTable<QtoTemplateRowProperty, QtoTemplateRowProperty>): void {
    this.tableRef = ref;
    if (!this.tableRef) {
      this.setState({ templateTableDataImporter: null });
    }
  }

  @autobind
  private onRowsCreate(
    targetParentId: string | null, position: number,
    rootRowIds: string[], rows: Array<TreeTableRow<QtoTemplateRowProperty>>,
  ): void {
    const newRows = rows.reduce(
      (result, row) => {
        result[row.id] = row;
        return result;
      },
      {},
    );

    const form = {
      targetParent: targetParentId,
      targetIndex: position,
      rootRowIds,
      rows: newRows,
    };

    this.tableUpdater.onRowsCreate(form);
  }

  @autobind
  private onRowsRemove(rowIds: string[]): void {
    this.tableUpdater.onRowsRemove(rowIds);
  }

  @autobind
  private onReorderRows(targetParentId: string | null, position: number, rowIdsToMove: string[]): void {
    const form = {
      targetParent: targetParentId,
      targetIndex: position,
      rowIdsToMove,
    };
    this.tableUpdater.onReorderRows(form);
  }

  @autobind
  private onRowsUpdate(rows: QtoTemplateRowForm[]): void {
    this.tableUpdater.onRowsUpdate(rows);
  }

  @autobind
  private onColumnsUpdate(columnsUpdateModel: QtoTemplateColumnsForm): void {
    this.tableUpdater.onColumnsUpdate(columnsUpdateModel);
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  const templateState = state.quantityTakeOff.template;
  return {
    loadTemplateStatus: templateState.statuses.loadTemplate,
    templateModel: templateState.templateModel,
    selectedTemplateId: templateState.selectedTemplateId,
    isSync: true,
    disableShowDialogList: state.persistedStorage.disableShowDialogList,
    isImperial: state.account.settings.isImperial,
    recordsConfig: state.quantityTakeOff.recordsConfig,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  const modelType = props.modelType;

  return {
    loadTemplate: (templateId) => dispatch(QuantityTakeOffTemplateActions.loadTemplate(templateId, modelType)),
    updateColumns: (templateId, columnsForm) =>
      dispatch(QuantityTakeOffTemplateActions.updateBasicColumns(templateId, modelType, columnsForm)),
    updateRows: (templateId, rows) => dispatch(QuantityTakeOffTemplateActions.updateRows(templateId, modelType, rows)),
    addRows: (templateId, form) => dispatch(QuantityTakeOffTemplateActions.addRows(templateId, modelType, form)),
    removeRows: (templateId, ids) => dispatch(QuantityTakeOffTemplateActions.removeRows(templateId, modelType, ids)),
    reorderRows: (templateId, form) =>
      dispatch(QuantityTakeOffTemplateActions.reorderRows(templateId, modelType, form)),
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const QtoTemplateTable = connector(QtoTemplateTableComponent);
