import autobind from 'autobind-decorator';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { ConstantFunctions } from 'common/constants/functions';
import { KreoColors } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { RoutingContextProps, withRoutingContext } from 'common/routing/with-routing-context';
import { DATE_FORMAT, KreoButton, TIME_FORMAT } from 'common/UIKit';
import { KreoIconDataBase } from 'common/UIKit/icons';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { Toggle } from '../../../../components/toggle';
import { DatabaseModel } from '../../interfaces/data';

interface ReduxProps {
  canUpdate: boolean;
  canDelete: boolean;
  canDuplicate: boolean;
}

interface OwnProps extends AbilityAwareProps, RoutingContextProps {
  database: DatabaseModel;
  history: any;
  selectDatabase: (database: DatabaseModel) => void;
  duplicate: (id: number) => void;
  delete: (database: DatabaseModel) => void;
  setDefault: (id: number) => void;
}

interface Props extends OwnProps, ReduxProps {}

class DatabaseItemComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { database, canUpdate, canDelete, canDuplicate } = this.props;

    const menu: ItemMenuType[] = [];
    if (canDuplicate) {
      menu.push({
        name: 'Duplicate',
        action: this.duplicate,
        disabled: !!(this.props.database.version && this.props.database.vendor),
      });
    }

    if (canDelete) {
      menu.push({
        name: 'Delete',
        action: this.delete,
        disabled: !!(this.props.database.version && this.props.database.vendor),
      });
    }

    return (
      <div className='databases__item'>
        <div className='databases__item-inner' onClick={this.selectDatabase}>
          <div className='databases__name'>
            <KreoIconDataBase />
            <span>{`${database.name} ${database.version || ''}`}</span>
          </div>
          <div className='databases__date'>
            <label>Created</label>
            <span>{database.createdAt ? moment(database.createdAt).format(DATE_FORMAT) : ''}</span>
          </div>
          {!database.version && (
            <div className='databases__date'>
              <label>Last Modified</label>
              <span>
                {database.updatedAt
                  ? moment(database.updatedAt).format(`${DATE_FORMAT}  ${TIME_FORMAT}`)
                  : ''}
              </span>
            </div>
          )}
        </div>
        <div className='databases__actions'>
          {database.version && !database.vendor ? (
            <KreoButton
              mode='action'
              size='medium'
              className='databases__actions-buy'
              caption='Buy Now'
              onClick={ConstantFunctions.doNothing}
            />
          ) : (
            <React.Fragment>
              {(canUpdate || database.isDefault) && (
                <div
                  className={classNames('databases__actions-toggle', {
                    visible: database.isDefault,
                  })}
                >
                  <Toggle
                    checked={database.isDefault}
                    checkedColor={KreoColors.blue}
                    onCheck={this.setDefault}
                  />
                  Default
                </div>
              )}
              {menu.length > 0 && <ItemMenu menu={menu} className='databases__actions-menu' />}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  @autobind
  private duplicate(): void {
    this.props.duplicate(this.props.database.id);
  }

  @autobind
  private delete(): void {
    this.props.delete(this.props.database);
  }

  @autobind
  private selectDatabase(): void {
    const { database, urls } = this.props;
    if (!database.version || database.vendor) {
      this.props.selectDatabase(database);
      this.props.history.push(urls.database.activities.url({ dbId: database.id.toString() }));
    }
  }

  @autobind
  private setDefault(): void {
    if (this.props.canUpdate && !this.props.database.isDefault) {
      this.props.setDefault(this.props.database.id);
    }
  }
}

const mapStateToProps = (_state: State, { ability }: OwnProps): ReduxProps => {
  return {
    canUpdate: ability.can(Operation.Update, Subject.Database),
    canDelete: ability.can(Operation.Delete, Subject.Database),
    canDuplicate: ability.can(Operation.Duplicate, Subject.Database),
  };
};

export const DatabaseItem = withRoutingContext(withAbilityContext(
  connect(mapStateToProps)(DatabaseItemComponent),
));
