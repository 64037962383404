import {
  ElementTooltip,
  IconButton,
  Icons,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ColorControl } from 'common/components/color-control';
import { InteractiveMenu } from 'common/components/interactive-menu';
import { ColorList } from 'common/constants/color-list';
import { State } from 'common/interfaces/state';
import { UndoRedoContextApiProps, withUndoRedoApiProps } from 'common/undo-redo';
import { DrawingsUserAnnotationActions } from '../../actions/creators/user-annotation';
import { DrawingsAnnotationsChangeColors } from '../../actions/payloads/user-annotation';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { DrawingsSolidColorSVGUrls } from '../../constants/drawings-svg-icons';
import { DrawingsRendererApiContextProps, withRendererApiContext } from '../../drawings-contexts';
import { DrawingUserAnnotationImage, DrawingUserAnnotationRuler } from '../../interfaces/drawings-user-annotation';
import { DrawingsFlyingMenuContainer } from '../drawings-flying-menu';
import { DrawingsSubmenuContainer } from '../drawings-flying-menu/submenu-containers';
import { Styled } from './styled';

interface StateProps {
  images: Record<string, DrawingUserAnnotationImage>;
  rulers: Record<string, DrawingUserAnnotationRuler>;
}

interface DispatchProps {
  onColorChange: (colorChanges: DrawingsAnnotationsChangeColors[]) => void;
}

interface OwnProps {
  currentDrawingId: string;
  sendRef: (ref: HTMLDivElement) => void;
  removeAnnotations: (ids: string[]) => void;
  selectedAnnotations: string[];
}

interface Props extends DrawingsRendererApiContextProps, StateProps, OwnProps, DispatchProps, UndoRedoContextApiProps {
}

interface ComponentState {
  color: string;
}

export class DrawingsAnnotationsFlyingMenuComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      color: this.getColor(),
    };
  }

  public render(): React.ReactNode {
    return (
      <DrawingsFlyingMenuContainer ref={this.props.sendRef}>
        { this.state.color ? (
            <InteractiveMenu renderMenuContent={this.renderColorPicker}>
              <Styled.ColorIndicatorContainer color={this.state.color}>
                <IconButton
                  Icon={Icons.Background}
                  height={DrawingsCanvasConstants.menuButtonSize}
                  width={DrawingsCanvasConstants.menuButtonSize}
                />
              </Styled.ColorIndicatorContainer>
            </InteractiveMenu>
        ) : null
        }
        <ElementTooltip
          position={'top'}
          speed={'xl'}
          text={'Delete'}
        >
          <IconButton
            defaultColor='red'
            onClick={this.onRemove}
            Icon={Icons.Delete}
            height={DrawingsCanvasConstants.menuButtonSize}
            width={DrawingsCanvasConstants.menuButtonSize}
          />
        </ElementTooltip>
      </DrawingsFlyingMenuContainer>
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.selectedAnnotations !== prevProps.selectedAnnotations) {
      this.setState({ color: this.getColor() });
    }
  }

  @autobind
  private renderColorPicker(): React.ReactNode {
    return (
      <DrawingsSubmenuContainer>
        <ColorControl
          selectedColor={this.state.color}
          onColor={this.onColorChange}
          colorList={ColorList}
        />
      </DrawingsSubmenuContainer>
    );
  }

  @autobind
  private onColorChange(color: string): void {
    const { images, rulers, addUndoRedo, selectedAnnotations } = this.props;

    this.setState({ color });
    const changedIds = [];
    const sourceColorAnnotation: Record<string, string[]> = {};
    for (const id of selectedAnnotations) {
      let oldColor;
      if (images[id] && DrawingsSolidColorSVGUrls.includes(images[id].type)) {
        oldColor = images[id].color;
        changedIds.push(id);
      } else if (rulers[id]) {
        oldColor = rulers[id].color;
        changedIds.push(id);
      }
      sourceColorAnnotation[oldColor] = sourceColorAnnotation[oldColor] || [];
      sourceColorAnnotation[oldColor].push(id);
    }
    const undo = this.getColorChangeOperation(
      Object.entries(sourceColorAnnotation).map(([key, value]) => ({ color: key, ids: value })));
    const redo = this.getColorChangeOperation([{ color, ids: changedIds }]);
    addUndoRedo(undo, redo);
    redo();
  }

  private getColorChangeOperation(colorChanges: DrawingsAnnotationsChangeColors[]): () => void {
    return () => {
      if (this.props.rendererApi) {
        this.props.rendererApi.engine.changeAnnotationsColor(colorChanges);
      }
      this.props.onColorChange(colorChanges);
    };
  }

  @autobind
  private onRemove(): void {
    this.props.rendererApi.engine.removeAnnotations(this.props.selectedAnnotations);
    this.props.removeAnnotations(this.props.selectedAnnotations);
  }

  private getAnnotationColor(id: string): string {
    const annotation = (this.props.images[id] || this.props.rulers[id]);
    return annotation ? annotation.color : null;
  }

  private getColor(): string {
    const { selectedAnnotations } = this.props;
    if (!selectedAnnotations.length) {
      return ColorList[0];
    }
    const selectedAnnotation = selectedAnnotations[0];
    const color: string = this.getAnnotationColor(selectedAnnotation);
    for (const id of selectedAnnotations) {
      const annotationColor = this.getAnnotationColor(id);
      if (!annotationColor) {
        return null;
      }
      if (color !== annotationColor) {
        return ColorList[0];
      }
    }
    return color;
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    images: state.drawings.userAnnotations.images,
    rulers: state.drawings.userAnnotations.rulers,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onColorChange: colorChanges => {
      dispatch(DrawingsUserAnnotationActions.changeColorOfAnnotation(colorChanges));
    },
  };
}

const withApi = withRendererApiContext(withUndoRedoApiProps(DrawingsAnnotationsFlyingMenuComponent));
export const DrawingsAnnotationsFlyingMenu = connect(mapStateToProps, mapDispatchToProps)(withApi);

