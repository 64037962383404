import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { Text } from 'common/components/text';
import { useOpenCloseDialog } from 'common/UIKit';
import { INVITE_PEOPLE_DIALOG } from '../../invite-users-to-project-dialog';
import { Styled } from './styled';


interface Props {
  text: string;
  canShareProject: boolean;
}

const InvitePeopleButtonComponent: React.FC<Props> = ({ text, canShareProject }) => {
  const [openDialog] = useOpenCloseDialog(INVITE_PEOPLE_DIALOG);

  const openDialogClick = useCallback(() => {
    if (canShareProject) {
      openDialog();
    }
  }, [canShareProject, openDialog]);

  return (
    <Styled.InvitePeople onClick={openDialogClick}>
      <Icons.AddUser />
      <Text>{text}</Text>
    </Styled.InvitePeople>
  );
};

export const InvitePeopleButton = React.memo(InvitePeopleButtonComponent);
