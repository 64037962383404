import autobind from 'autobind-decorator';
import * as React from 'react';

import './database-table-root-item.scss';

import { EnhancedVirtualList } from 'common/UIKit';
import { ItemMenu, ItemMenuType } from '../../../../components/controls/item-menu';
import { DatabaseTableRootModel, DatabaseTableVariantModel } from '../../interfaces/data';
import { DatabaseTableAddVariantButton } from './database-table-add-variant-button';
import { DatabaseTableVariant } from './database-table-variant-item';
import { DatabaseTableColumn, DatabaseTableRootMenuItem, DatabaseTableVariantMenuItem } from './interfaces';


interface Props {
  rootItem: DatabaseTableRootModel;
  tableName: string;
  columns: Array<DatabaseTableColumn<any>>;
  rootMenuItems?: DatabaseTableRootMenuItem[];
  variantMenuItems: DatabaseTableVariantMenuItem[];
  isSelected: boolean;
  isSelectDisabled: boolean;
  selectedVariantIds: number[];
  disabledVariantIds: number[];

  editRoot: (rootId: number) => void;
  editVariant: (rootId: number, variantId: number) => void;
  selectRoot?: (rootId: number, value: boolean) => void;
  selectVariant?: (rootId: number, variantId: number, value: boolean) => void;
  createVariant?: (rootId: number) => void;
}

const variantsMaxCount: number = 20;
const variantDefaultHeight: number = 38;

export const DatabaseTableElementSize = {
  rootMarginBottom: 16,
  rootMarginLastBottom: 20,
  rootDefaultHeight: 90,
  readonlyRootDefaultHeight: 60,
  variantDefaultHeight,
  variantsMaxHeight: variantsMaxCount * variantDefaultHeight,
};


export class DatabaseTableRoot extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='database-table-root-item'>
        <div className='database-table-root-item__header' onClick={this.editActivity}>
          <div className='action-column'>
          {
            this.props.rootMenuItems ? (
              <ItemMenu className='action-menu' menu={this.getMenuItems()} handleClick={this.stopPropagation} />
            ) : null
          }
          </div>
          <span className='database-table-root-item__header-name'>
            <b>{this.props.rootItem.name}</b>
          </span>
        </div>
        {this.props.rootItem.variants && (
          <div
            className='database-table-root-item__variant-container'
            style={{ height: this.calcHeight() }}
          >
            <EnhancedVirtualList
              objects={this.props.rootItem.variants}
              itemHeight={variantDefaultHeight}
              renderedItemsCount={variantsMaxCount}
              renderItem={this.renderVariant}
            />
          </div>
        )}
        {
          this.props.createVariant ? (
            <div className='database-table-root-item__add-button' onClick={this.newVariant}>
              <DatabaseTableAddVariantButton variantName={this.props.tableName}/>
            </div>
          ) : null
        }
      </div>
    );
  }

  private calcHeight(): string {
    const activityVariants = this.props.rootItem.variants;
    let height = 0;
    if (activityVariants) {
      height = activityVariants.length * variantDefaultHeight;
      if (height > DatabaseTableElementSize.variantsMaxHeight) {
        height = DatabaseTableElementSize.variantsMaxHeight;
      }
    }
    return `${height}px`;
  }

  private getMenuItems(): ItemMenuType[] {
    return this.props.rootMenuItems.map(x => ({
      name: x.name,
      disabled: !x.action,
      action: x.action
        ? () => x.action(this.props.rootItem.id)
        : null,
    }));
  }


  @autobind
  private newVariant(): void {
    this.props.createVariant(this.props.rootItem.id);
  }

  private stopPropagation(e: any): void {
    e.stopPropagation();
  }


  @autobind
  private editActivity(): void {
    this.props.editRoot(this.props.rootItem.id);
  }

  @autobind
  private renderVariant(variant: DatabaseTableVariantModel): JSX.Element {
    const isSelectDisabled = this.props.disabledVariantIds.includes(variant.id);
    const isSelected = this.props.selectedVariantIds.includes(variant.id);

    return (
      <DatabaseTableVariant
        columns={this.props.columns}
        isSelectDisabled={isSelectDisabled}
        menuItems={this.props.variantMenuItems}
        isSelected={isSelected}
        rootItemId={this.props.rootItem.id}
        variant={variant}
        editVariant={this.props.editVariant}
        selectVariant={this.props.selectVariant}
      />
    );
  }
}

