import * as React from 'react';


export const KreoIconTooltipHelp: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
    <g id='icon_help' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='1'>
        <path
          d='M7,13 C3.6862915,13 1,10.3137085 1,7 C1,3.6862915 3.6862915,1 7,1 C10.3137085,1 13,3.6862915 13,7
          C13,10.3137085 10.3137085,13 7,13 Z M7,12 C9.76142375,12 12,9.76142375 12,7 C12,4.23857625 9.76142375,2
          7,2 C4.23857625,2 2,4.23857625 2,7 C2,9.76142375 4.23857625,12 7,12 Z M6.60175031,9.94199991
          C6.60175031,9.61799986 6.86275035,9.35699981 7.18675041,9.35699981 C7.51075046,9.35699981
          7.7717505,9.61799986 7.7717505,9.94199991 C7.7717505,10.266 7.51075046,10.527 7.18675041,10.527
          C6.86275035,10.527 6.60175031,10.266 6.60175031,9.94199991 Z M8.93275069,5.72999922 C8.93275069,6.2024993
          8.76625067,6.49499934 8.50975062,6.8459994 L7.89325052,7.69649954 C7.7402505,7.90799958 7.69975049,8.0564996
          7.66825049,8.27699964 C7.65025048,8.39849966 7.60525048,8.50199967 7.53775046,8.57849969
          C7.45675045,8.6684997 7.33975043,8.71799971 7.19125041,8.71799971 C7.04275038,8.71799971
          6.92575036,8.6594997 6.84475035,8.56949968 C6.76825034,8.48399967 6.72775033,8.37149965
          6.72775033,8.22299963 C6.72775033,7.89899957 6.78625034,7.70549954 7.03375038,7.37249949
          L7.7357505,6.42749933 C7.89325052,6.2159993 8.01025054,6.01349926 8.01025054,5.72999922 C8.01025054,5.23949914
          7.65475048,4.86149908 7.1417504,4.86149908 C6.89425036,4.86149908 6.67375032,4.93349909 6.49825029,5.10899912
          C6.35875027,5.24849914 6.29575026,5.41499917 6.23725025,5.6399992 C6.21025025,5.74349922 6.16075024,5.86049924
          6.11125023,5.91449925 C6.03925022,5.99549926 5.9267502,6.04949927 5.77825018,6.04949927 C5.65675016,6.04949927
          5.54425014,5.99999926 5.46775013,5.91449925 C5.38225011,5.81999923 5.35975011,5.71649922 5.35975011,5.57699919
          C5.35975011,5.25749914 5.52625013,4.82999907 5.84575019,4.52399902 C6.16975024,4.21799897 6.61075031,4.0379989
          7.1372504,4.03799894 C8.13625056,4.03799894 8.93275069,4.69949905 8.93275069,5.72999922 Z'
          id='Combined-Shape'
          fill='currentColor'
          fillRule='nonzero'
        />
    </g>
    </svg>
  );
};
