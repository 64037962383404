import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { useRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { useHasVisibleInstances } from 'common/components/drawings/hooks';
import { State } from 'common/interfaces/state';
import { useCurrentProjectFullScreen } from 'persisted-storage/hooks';
import { TwoDFullScreenMode } from 'persisted-storage/interfaces/state';
import { ActionEventHandlers } from '../../drawings-context-menu/interfaces';
import { Submenu } from '../submenu';
import { InteractiveContextMenuContent } from './interactive-context-menu-content';

interface Props {
  selectedInstancesIds: string[];
  contextMenuActions: Record<string, ActionEventHandlers>;
}

const InteractiveContextMenuComponent: React.FC<Props> = ({
  selectedInstancesIds,
  contextMenuActions,
}) => {
  const { rendererApi } = useRendererApiContext();
  const canEditMeasurements = useAbility(Operation.Update, Subject.Takeoff2DMeasurement);
  const hiddenInstancesIds = useSelector<State, string[]>(x => x.drawings.hiddenInstances);
  const hasHiddenInstances = hiddenInstancesIds?.length > 0;
  const areSelectedInstancesHidden = useMemo(() => {
    if (!hasHiddenInstances) {
      return false;
    }
    const hiddenIdsMap = new Set(hiddenInstancesIds);
    return selectedInstancesIds.every(x => hiddenIdsMap.has(x));
  }, [selectedInstancesIds, hiddenInstancesIds]);

  const isShowTwoDReport = useSelector<State, boolean>(x => x.twoD.isShowTwoDReport);

  const [fullScreenMode] = useCurrentProjectFullScreen();
  const isFullscreen = fullScreenMode=== TwoDFullScreenMode.Drawings;
  const hasVisibleInstances = useHasVisibleInstances();


  const renderContextMenu = useCallback((close: () => void) => {
    return (
      <InteractiveContextMenuContent
        canPaste={rendererApi.canPaste()}
        hasHiddenInstances={hasHiddenInstances}
        hasVisibleInstances={hasVisibleInstances}
        canEditMeasurement={canEditMeasurements}
        isSelectedInstancesHidden={areSelectedInstancesHidden}
        actionEventsHandlers={contextMenuActions}
        isTwoDReport={isShowTwoDReport}
        isFullScreen={isFullscreen}
        onClose={close}
        canPasteInSamePlace={rendererApi.canPasteInSamePlace()}
      />
    );
  }, [
    selectedInstancesIds,
    rendererApi,
    hasHiddenInstances,
    canEditMeasurements,
    areSelectedInstancesHidden,
    isShowTwoDReport,
    isFullscreen,
    hasVisibleInstances,
  ]);

  return (
    <Submenu
      menuRenderer={renderContextMenu}
      iconType={Icons.Menu}
      disabled={false}
      tooltip={'Show more'}
    />
  );
};

export const InteractiveContextMenu = React.memo(InteractiveContextMenuComponent);
