import { FormControl, FormHelperText, InputAdornment, InputLabel, Select } from '@material-ui/core';
import { FormHelperTextClassKey } from '@material-ui/core/FormHelperText';
import { InputAdornmentClassKey } from '@material-ui/core/InputAdornment';
import { InputLabelClassKey } from '@material-ui/core/InputLabel';
import { MenuProps } from '@material-ui/core/Menu';
import { SelectClassKey } from '@material-ui/core/Select';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import './material-select.scss';

import { KreoIconExpand } from '../icons';
import { AdornmentProps, MaterialComponentProps, MaterialComponentType } from './interfaces';

type ValueType = React.ReactText | boolean;
type DropDownHeightType = 'small' | 'middle';

export interface MaterialMenuItemProps {
  value?:  ValueType | ValueType[];
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export interface MaterialSelectProps extends MaterialComponentProps<ValueType[] | ValueType> {
  children: Array<React.ReactElement<MaterialMenuItemProps>>;
  itemClassName?: string;
  dropdownClassName?: string;
  wrapClassName?: string;
  iconComponent?: JSX.Element;
  multiple?: boolean;
  autoWidth?: boolean;
  bottomElement?: React.ReactNode;
  dropDownHeight?: DropDownHeightType;
  noDataText?: string;
  selectionRenderer?: (value: ValueType[] | ValueType) => React.ReactNode;
  onChange?: (e: React.SyntheticEvent, menuItemValue: ValueType[] | ValueType) => void;
  displayEmpty?: boolean;
  controlName?: string;
}

interface State {
  focused: boolean;
}

const labelClasses: Partial<ClassNameMap<InputLabelClassKey>> = {
  root: 'custom-material-select__kreo-label',
  shrink: 'custom-material-select__kreo-label--floated',
};
const selectClasses: Partial<ClassNameMap<SelectClassKey>>  = {
  select: 'custom-material-select__select',
  selectMenu: 'custom-material-select__menu',
};
const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};
const helpTextClasses: Partial<ClassNameMap<FormHelperTextClassKey>> = {
  root: 'custom-material-input__bottom-information',
};
const modalClasses = {
  root: 'custom-material-select__modal',
};
const inputAdornmentClasses: Partial<ClassNameMap<InputAdornmentClassKey>> = {
  root: 'custom-material-select__adornment',
};

export class MaterialSelect extends React.Component<MaterialSelectProps, State> {
  public static defaultProps: Partial<MaterialSelectProps> = {
    autoWidth: false,
    displayBottomInformation: false,
    displayUnderline: true,
    noDataText: 'No data available',
    dropDownHeight: 'middle',
    displayedType: MaterialComponentType.Standard,
    adornmentPosition: 'start',
  };

  constructor(props: MaterialSelectProps) {
    super(props);

    this.state = {
      focused: false,
    };
  }

  public componentWillUnmount(): void {
    document.removeEventListener('click', this.removeFocus);
  }

  public render(): React.ReactNode {
    const noData = !this.props.children || this.props.children.length === 0;
    const displayLabel = this.props.label && this.props.displayedType !== MaterialComponentType.Native;
    const disableUnderline = this.props.displayedType === MaterialComponentType.Native || !this.props.displayUnderline;
    const helperText = this.props.displayBottomInformation ? (
      this.props.error ? this.props.error : this.props.hint
    ) : null;
    const hasError = !!this.props.error;

    let adornmentProps: AdornmentProps;

    if (this.props.adornment) {
      const adornment = (
      <InputAdornment
        position={this.props.adornmentPosition}
        classes={inputAdornmentClasses}
      >
        {this.props.adornment}
      </InputAdornment>
      );
      adornmentProps = { };
      if (this.props.adornmentPosition === 'start') {
        adornmentProps.startAdornment = adornment;
      } else {
        adornmentProps.endAdornment = adornment;
      }
    }

    return (
      <FormControl
        fullWidth={true}
        className={
          classNames(
            'custom-material-select',
            {
              'custom-material-select--has-bottom': this.props.displayBottomInformation,
              'custom-material-select--error': hasError,
              'custom-material-select--disabled': !!this.props.disabled,
              'custom-material-select--focused': this.state.focused,
              'custom-material-select--has-label': !!this.props.label,
              'custom-material-select--has-native': this.props.displayedType === MaterialComponentType.Native,
            },
            this.props.className)
        }
        onClick={this.onClick}
      >
        {
          displayLabel &&
          <InputLabel
            shrink={this.props.isFloatingLabel ? undefined : true}
            classes={labelClasses}
            error={!!this.props.error}
          >
            {this.props.label}
          </InputLabel>
        }
        <Select
          value={this.props.value || this.props.multiple && [] || ''}
          onChange={this.onChange}
          inputProps={{ id: this.props.id, name: this.props.name }}
          multiple={this.props.multiple}
          className={classNames('custom-material-select__wrap', this.props.wrapClassName)}
          data-control-name={this.props.controlName}
          data-placeholder={!this.props.value ? this.props.placeholder : null}
          classes={{
            root: classNames('custom-material-select', {
              'custom-material-select--native': this.props.displayedType === MaterialComponentType.Native,
            }),
            ...selectClasses,
          }}
          autoWidth={this.props.autoWidth}
          placeholder={this.props.placeholder}
          error={hasError}
          disableUnderline={disableUnderline}
          renderValue={this.props.selectionRenderer}
          disabled={this.props.disabled}
          IconComponent={this.getIcon}
          MenuProps={{
            ...menuProps,
            ModalClasses: modalClasses,
            classes: {
              paper: classNames(
                'custom-material-select__paper',
                this.props.dropdownClassName,
                {
                  'has-bottom': !!this.props.bottomElement,
                },
                this.props.dropDownHeight,
              ),
            },
          }}
          displayEmpty={this.props.displayEmpty}
          {...adornmentProps}
        >
          {noData ? (
            <div className='custom-material-select__no-data-wrap'>
              <div className='custom-material-select__no-data' onClick={this.onIgnoreClick}>
                {this.props.noDataText}
              </div>
            </div>
          ) : (
            this.props.children
          )}
          {
            this.props.bottomElement &&
            <div
              className='custom-material-select__bottom-element'
              onClick={this.onIgnoreClick}
            >
              {this.props.bottomElement}
            </div>
          }
        </Select>
        {
          helperText &&
          <FormHelperText error={hasError} classes={helpTextClasses}>{helperText}</FormHelperText>
        }
      </FormControl>
    );
  }

  @autobind
  private onClick(): void {
    document.addEventListener('click', this.removeFocus);
    this.setState({ focused: true });
  }

  @autobind
  private removeFocus(): void {
    document.removeEventListener('click', this.removeFocus);
    this.setState({ focused: false });
  }

  @autobind
  private getIcon(): JSX.Element {
    return this.props.iconComponent ? this.props.iconComponent : <KreoIconExpand />;
  }

  @autobind
  private onChange(event: React.SyntheticEvent, child: React.ReactElement<MaterialMenuItemProps>): void {
    let newValue: ValueType[] | ValueType;

    if (this.props.multiple) {
      if (this.props.value instanceof Array) {
        if (this.props.value.includes(child.props.value as React.ReactText)) {
          newValue = this.props.value.filter(v => v !== child.props.value);
        } else {
          newValue = [child.props.value as React.ReactText, ...this.props.value];
        }
      }
    } else {
      newValue = child.props.value;
    }

    if (this.props.onChange) {
      this.props.onChange(event, newValue);
    }
  }

  @autobind
  private onIgnoreClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
  }
}

export const MaterialSelectField: React.FC<MaterialSelectProps> = (
  props: WrappedFieldProps & MaterialSelectProps,
): JSX.Element => {
  const { input, meta: { error, submitFailed }, onChange, ...rest } = props;

  const onChangeHandler = (
    event: React.SyntheticEvent,
    menuItemValue: any,
  ): void => {
    if (onChange) {
      onChange(event, menuItemValue);
    }

    input.onChange(menuItemValue);
    event.preventDefault();
  };

  return (
    <MaterialSelect
      error={submitFailed && error}
      {...rest}
      value={input.value}
      onChange={onChangeHandler} // eslint-disable-line react/jsx-no-bind
    >
      {props.children}
    </MaterialSelect>
  );
};
