import { DatadogLogger } from 'common/environment/datadog-logger';
import { PdfHelper, PdfReaders } from 'common/pdf';
import { extendDocumentToComparable } from 'common/pdf/compare-document/extend-document-to-comparable';
import { arrayUtils } from 'common/utils/array-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { DrawingProcessingState } from '../enums';
import { DrawingComparisonSetting, DrawingsShortInfo } from '../interfaces';
import { DrawingSnapping } from '../interfaces/drawing';
import { DrawingsFile } from '../interfaces/drawings-file-info';
import { CancelableAction, CancelableResult, DocsLoader, DocsLoadSettings } from '../interfaces/util';
import { DrawingsUtils } from '../utils/drawings-utils';
import { SnappingGetters } from '../utils/snapping-getters';

interface LoadingState {
  isCanceled: boolean;
}

export type Document = {
  pdfDocument: Core.Document,
  pageNumber: number,
  drawingId: string,
  canGetText?: boolean,
} | null;


async function comparePages(
  currentPage: Core.PDFNet.Page,
  pageToCompareWith: Core.PDFNet.Page,
  settings: DrawingComparisonSetting,
): Promise<Core.Document> {
  const newDoc = await Core.PDFNet.PDFDoc.create();
  await newDoc.lock();
  const docToCompareWith = await Core.PDFNet.PDFDoc.create();
  docToCompareWith.lock();
  docToCompareWith.pagePushBack(pageToCompareWith);
  const documentToCompareWith = await PdfReaders.convertDocToCore(docToCompareWith, UuidUtil.generateUuid());

  await newDoc.pagePushBack(currentPage);
  await newDoc.unlock();
  const document = await PdfReaders.convertDocToCore(newDoc, UuidUtil.generateUuid());

  extendDocumentToComparable(document, documentToCompareWith, settings);
  return document;
}

async function getPage(file: Core.PDFNet.PDFDoc, pageNumber: number): Promise<Core.PDFNet.Page> {
  return file.getPage(DrawingsUtils.getPDFPageNumber(pageNumber));
}

export function shouldLoadOnlyPage(file: DrawingsFile): boolean {
  return file.splittingStatus === ViewModelStatus.Ready;
}

export function shoulLoadFilteredPage(drawing: DrawingsShortInfo): boolean {
  return drawing.filter && drawing.filter.isEnabled && !!drawing.filter.state.uselessElementStylesCount;
}

async function loadPage(loader: DocsLoader, settings: DocsLoadSettings): Promise<Core.PDFNet.Page> {
  const pageFile = await loader.loadFileAsDoc(settings);
  return pageFile.getPage(1);
}

async function loadPdfPagesFromSameFile(
  loader: DocsLoader,
  file: DrawingsFile,
  pages: DocsLoadSettings[],
): Promise<Core.PDFNet.Page[]> {
  if (shouldLoadOnlyPage(file)) {
    return Promise.all(pages.map(x => loadPage(loader, x)));
  } else {
    const pdfFile = await loader.loadFileAsDoc({ fileId: file.id, drawingId: pages[0].drawingId, isFiltered: false });
    return Promise.all(pages.map(x => getPage(pdfFile, x.pageNumber || 0)));
  }
}

async function loadPages(
  loader: DocsLoader,
  pages: Array<{ file: DrawingsFile, pagesToLoad: DocsLoadSettings[] }>,
): Promise<Core.PDFNet.Page[]> {
  const promises = pages.map(({ file, pagesToLoad }) => loadPdfPagesFromSameFile(loader, file, pagesToLoad));
  const pdfPages = await Promise.all(promises);
  return arrayUtils.flatArray(pdfPages);
}

export function prepareLoadSettings(
  drawingInfo: DrawingsShortInfo,
  files: Record<string, DrawingsFile>,
): DocsLoadSettings {
  const loadConfig: DocsLoadSettings = {
    fileId: drawingInfo.pdfId,
    isOptimized: drawingInfo.rasterizationStatus === ViewModelStatus.Ready
      ? drawingInfo.isOptimized
      : false,
    drawingId: drawingInfo.drawingId,
    isFiltered: shoulLoadFilteredPage(drawingInfo),
  };
  if (shouldLoadOnlyPage(files[drawingInfo.pdfId]) || loadConfig.isOptimized || loadConfig.isFiltered) {
    loadConfig.pageNumber = drawingInfo.pageNumber;
  }
  return loadConfig;
}

function prepareLoadForCompareSettings(
  drawingInfo: DrawingsShortInfo,
  files: Record<string, DrawingsFile>,
): DocsLoadSettings {
  const loadSettings = prepareLoadSettings(drawingInfo, files);
  loadSettings.pageNumber = drawingInfo.pageNumber;
  return loadSettings;
}

export async function getPageWithoutCompare(
  loader: DocsLoader,
  currentDrawingInfo: DrawingsShortInfo,
  files: Record<string, DrawingsFile>,
): Promise<Document> {
  const loadConfig = prepareLoadSettings(currentDrawingInfo, files);
  return {
    pdfDocument: await loader.loadFileAsCore(loadConfig),
    pageNumber: Number.isInteger(loadConfig.pageNumber) ? 0 : currentDrawingInfo.pageNumber,
    drawingId: currentDrawingInfo.drawingId,
    canGetText: !loadConfig.isOptimized,
  };
}

export async function loadPageAsDocument(
  loader: DocsLoader,
  currentDrawingInfo: DrawingsShortInfo,
  files: Record<string, DrawingsFile>,
): Promise<Core.Document> {
  const loadConfig = prepareLoadSettings(currentDrawingInfo, files);
  const file = await loader.loadFileAsDoc(loadConfig);
  if (Number.isInteger(loadConfig.pageNumber)) {
    return PdfReaders.convertDocToCore(file, UuidUtil.generateUuid());
  }
  const page = await getPage(file, currentDrawingInfo.pageNumber);
  const newDoc = await Core.PDFNet.PDFDoc.create();
  await newDoc.lock();
  await newDoc.pagePushBack(page);
  await newDoc.unlock();
  return PdfReaders.convertDocToCore(file, UuidUtil.generateUuid());
}

async function getComparisonFromSameFile(
  loader: DocsLoader,
  currentDrawingInfo: DrawingsShortInfo,
  drawingToCompareInfo: DrawingsShortInfo,
  files: Record<string, DrawingsFile>,
  state: LoadingState,
): Promise<Document> {
  const [currentPage, pageToCompareWith] = await loadPdfPagesFromSameFile(
    loader,
    files[currentDrawingInfo.pdfId],
    [
      prepareLoadForCompareSettings(currentDrawingInfo, files),
      prepareLoadForCompareSettings(drawingToCompareInfo, files),
    ],
  );
  if (state.isCanceled) {
    throw state;
  }
  return {
    pdfDocument: await comparePages(currentPage, pageToCompareWith, currentDrawingInfo.pagesCompareSettings),
    pageNumber: 0,
    drawingId: currentDrawingInfo.drawingId,
    canGetText: false,
  };
}

function prepareFileLoadSettings(
  drawingInfo: DrawingsShortInfo,
  files: Record<string, DrawingsFile>,
): { file: DrawingsFile, pagesToLoad: DocsLoadSettings[] } {
  return {
    file: files[drawingInfo.pdfId],
    pagesToLoad: [prepareLoadSettings(drawingInfo, files)],
  };
}

async function getComparisonFromDifferentFiles(
  loader: DocsLoader,
  currentDrawingInfo: DrawingsShortInfo,
  drawingToCompareInfo: DrawingsShortInfo,
  files: Record<string, DrawingsFile>,
  state: LoadingState,
): Promise<Document> {
  const settings = [
    prepareFileLoadSettings(currentDrawingInfo, files),
    prepareFileLoadSettings(drawingToCompareInfo, files),
  ];
  const [currentPage, pageToCompareWith] = await loadPages(loader, settings);
  if (state.isCanceled) {
    throw state;
  }
  return {
    pdfDocument: await comparePages(currentPage, pageToCompareWith, currentDrawingInfo.pagesCompareSettings),
    pageNumber: 0,
    drawingId: currentDrawingInfo.drawingId,
    canGetText: false,
  };
}

async function load(
  loader: DocsLoader,
  currentDrawingInfo: DrawingsShortInfo,
  state: LoadingState,
  drawings: Record<string, DrawingsShortInfo>,
  files: Record<string, DrawingsFile>,
): Promise<Document> {
  if (!currentDrawingInfo.pageToCompareId || !currentDrawingInfo.pagesCompareSettings?.isActive) {
    return getPageWithoutCompare(loader, currentDrawingInfo, files);
  }

  const drawingToCompareInfo = drawings[currentDrawingInfo.pageToCompareId];

  if (
    currentDrawingInfo.pdfId === drawingToCompareInfo.pdfId
    && !shoulLoadFilteredPage(currentDrawingInfo)
    && !shoulLoadFilteredPage(drawingToCompareInfo)
  ) {
    return getComparisonFromSameFile(loader, currentDrawingInfo, drawingToCompareInfo, files, state);
  }

  return getComparisonFromDifferentFiles(loader, currentDrawingInfo, drawingToCompareInfo, files, state);
}


interface GetCurrentDocToRenderConfig {
  loader: DocsLoader;
  currentDrawingInfo: DrawingsShortInfo;
  drawings: Record<string, DrawingsShortInfo>;
  files: Record<string, DrawingsFile>;
  isSnappingEnabled: () => boolean;
}

export function getCurrentDocToRender(
  { loader, currentDrawingInfo, drawings, files }: GetCurrentDocToRenderConfig,
): CancelableAction<Document> {
  DatadogLogger.log('getCurrentDocToRender start', currentDrawingInfo);
  const loadingState: LoadingState = { isCanceled: false };
  const promise = load(loader, currentDrawingInfo, loadingState, drawings, files)
    .then<CancelableResult<Document>>((info) => {
      return {
        data: info,
        isFinished: true,
      };
    }).catch<CancelableResult<Document>>(err => {
      if (!err.isCanceled) {
        throw err;
      }
      return { isFinished: false, data: null };
    });
  return {
    promise,
    cancel: () => {
      DatadogLogger.log('getCurrentDocToRender cancel', currentDrawingInfo);
      loader.cancel();
      loadingState.isCanceled = true;
    },
  };
}

const READ_SNAPPING_OPERATION = 'read_snapping';

async function getSnapping(
  { pdfDocument, pageNumber }: { pdfDocument: Core.Document, pageNumber: number, drawingId: string },
): Promise<DrawingSnapping> {
  return PdfHelper.callOrSaveOperation(
    SnappingGetters.getSnappingCore,
    READ_SNAPPING_OPERATION,
    DrawingsUtils.getPDFPageNumber(pageNumber),
    pdfDocument,
  ).promise;
}

export function getActualSnapping(
  { isSnappingBlocked, isOptimized }: { isSnappingBlocked?: boolean, isOptimized?: boolean },
  drawingLoadResponse: { pdfDocument: Core.Document, pageNumber: number, drawingId: string },
): Promise<DrawingSnapping> {
  const shouldIgnoreSnappingLoading = isSnappingBlocked || isOptimized;
  return shouldIgnoreSnappingLoading
    ? Promise.resolve(null)
    : getSnapping(drawingLoadResponse);
}

export interface DocumentWithSnapping {
  docInfo: Document;
  snapping: DrawingSnapping;
}

const LONG_TIME_TO_PROCESSING = 25000;

export function getSnappingWithProgress(
  currentDrawing: DrawingsShortInfo,
  drawingLoadResponse: { pdfDocument: Core.Document, pageNumber: number, drawingId: string },
  onProcessingChanged: (state: DrawingProcessingState) => void,
): Promise<DocumentWithSnapping> {
  return new Promise<DocumentWithSnapping>((resolve, reject) => {
    onProcessingChanged(DrawingProcessingState.Loading);
    const timeout = setTimeout(
      () => {
        onProcessingChanged(DrawingProcessingState.ToLongProcessing);
      },
      LONG_TIME_TO_PROCESSING,
    );
    getActualSnapping(currentDrawing, drawingLoadResponse)
      .then((snapping) => {
        clearTimeout(timeout);
        onProcessingChanged(DrawingProcessingState.Loaded);
        resolve({ docInfo: drawingLoadResponse, snapping });
      })
      .catch((error: any) => {
        clearTimeout(timeout);
        onProcessingChanged(DrawingProcessingState.Error);
        reject(error);
      });
  });
}

export function getCurrentDocWithSnapping(
  renderInfo: GetCurrentDocToRenderConfig,
  onProcessingChanged: (state: DrawingProcessingState) => void,
): { promise: Promise<DocumentWithSnapping>, cancel: () => void } {
  const { promise: docPromise, cancel } = getCurrentDocToRender(renderInfo);
  const promise = new Promise<DocumentWithSnapping>((resolve, reject) => {
    docPromise.then((info) => {
      DatadogLogger.log('load drawing pdf', {
        isFinished: info.isFinished,
        drawingId: renderInfo.currentDrawingInfo.drawingId,
      });
      if (!info.isFinished) {
        return;
      }
      onProcessingChanged(DrawingProcessingState.Loading);

      if (!renderInfo.isSnappingEnabled()) {
        onProcessingChanged(DrawingProcessingState.Loaded);
        resolve({ docInfo: info.data, snapping: null });
      } else {
        getSnappingWithProgress(renderInfo.currentDrawingInfo, info.data, onProcessingChanged)
          .then(resolve)
          .catch(reject);
      }
    }).catch(reject);
  });
  return { promise, cancel };
}
