import { useEffect, useRef } from 'react';

import { useGlobalKeyboardEvents } from 'common/components/global-keyboard-events-controller';
import { BooleanOperationsKeyListeners } from '../../drawings-geometry/drawings-helpers/key-listener';

export function useBooleanKeyboardListener(
  apply: () => void,
  cancel: () => void,
): void {
  const api = useGlobalKeyboardEvents();

  const listener = useRef<BooleanOperationsKeyListeners>(null);

  useEffect(() => {
    listener.current = new BooleanOperationsKeyListeners({
      api,
      apply,
      cancel,
    });

    listener.current.activate();
    return () => {
      if (listener.current) {
        listener.current.deactivate();
        listener.current = null;
      }
    };
  }, [api, cancel, apply]);
}
