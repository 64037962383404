import autobind from 'autobind-decorator';
import { AssignInfoGetter, DrawingInfoGetter } from './interfaces';

export class CommonTransaction {
  protected getDrawingInfo: DrawingInfoGetter = null;
  protected getCommonAssignInfo: AssignInfoGetter = null;
  protected isPivot: () => boolean = null;
  protected drawingElementRows: Record<string, Set<string>> = {};
  protected sourceData: Record<string, any> = {};
  protected rowIds: Set<string> = new Set();

  protected add: any[] = [];
  protected remove: any[] = [];
  protected update: any[] = [];


  public constructor(getDrawingInfo: DrawingInfoGetter, getAssignInfo: AssignInfoGetter) {
    this.getDrawingInfo = getDrawingInfo;
    this.getCommonAssignInfo = getAssignInfo;
  }

  @autobind
  protected getRowsIdsByDrawingElementId(drawingElementId: string): string[] | null {
    const rows = this.drawingElementRows[drawingElementId];
    if (!rows || !rows.size) {
      return null;
    }
    return Array.from(rows);
  }
}
