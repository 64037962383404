import autobind from 'autobind-decorator';
import * as React from 'react';

import './material-input-search.scss';

import { MaterialInput } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';

interface Props {
  placeholder?: string;
  initialText?: string;
  onChange?: (value: string) => void;
}

interface State {
  search: string;
}

// TODO: remove it to components/UIKit
export class MaterialInputSearch extends React.Component<Props, State> {
  public static defaultProps: Props = {
    placeholder: 'Search...',
  };

  private timeout: any; // NodeJS.Timer ERROR: TS2322: Type 'number' is not assignable to type 'Timer'.

  constructor(props: Props) {
    super(props);
    this.state = {
      search: props.initialText ? props.initialText : '',
    };
  }

  public render(): JSX.Element {
    return (
      <MaterialInput
        displayedType={MaterialComponentType.Native}
        value={this.state.search}
        onChange={this.onSearchChange}
        placeholder={this.props.placeholder}
        searchType={true}
        className='material-input-search'
      />
    );
  }

  @autobind
  private onSearchChange(_event: React.ChangeEvent, newValue: string): void {
    this.setState({
      search: newValue,
    },            () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.search);
          }
        },
        300);
    });
  }
}
