import React, { useCallback } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { KreoScrollbarsApi } from 'common/UIKit/scrollbars/kreo-scrollbars';
import { AssignedPia } from '../../../../../units/2d';
import { Person } from '../../../../../units/people/interfaces/person';
import { DrawingsGeometryGroup, DrawingsGeometryGroupWithNesting } from '../../interfaces';
import { AnnotationLegendItem } from '../../interfaces/annotation-legend-tree';
import { DrawingsDragAndDropData } from '../../utils/drawings-annotation-drag-utils';
import { DrawingsAnnotationList } from '../drawings-annotation-list';
import { GroupsMenuType } from '../groups-menu';
import { LegendItemAPI } from '../interfaces';
import { PinnedGroups } from '../pinned-groups';
import { useGroups } from '../pinned-groups/use-groups';
import { Styled } from './styled';

interface Props {
  selectedGroups: string[];
  canEditMeasurements: boolean;
  groupsIsOpenMap: Record<string, boolean>;
  users: Person[];
  onGroupClick: (event: React.MouseEvent<HTMLDivElement>, group: DrawingsGeometryGroupWithNesting) => void;
  changeGroupPinStatus: (groupId: string) => void;
  onGroupNameChanged: (groupId: string, name: string) => void;
  onHiddenChanged: (ids: string[], isHidden: boolean) => void;
  onGroupOpenChange: (id: string) => void;
  onGroupDoubleClick: (instancesIds: string[]) => void;
  onGroupContextMenu: (e: React.MouseEvent<HTMLDivElement>, group: DrawingsGeometryGroupWithNesting) => boolean;
  moveToGroup(groupId: string, groupIds: string[], measurementIds: string[]): void;
  assignPia: Record<string, AssignedPia>;
  groupsMap: Record<string, DrawingsGeometryGroup>;
  openGroupMenu: (
    e: React.MouseEvent<HTMLDivElement>,
    group: DrawingsGeometryGroupWithNesting,
    menuType: GroupsMenuType,
  ) => void;
  getItemsToMove: () => DrawingsDragAndDropData;
  filterResultItems: AnnotationLegendItem[];
  itemHeight: number;
  pinnedGroupIds: string[];
  saveListApi(api: KreoScrollbarsApi): void;
  cancelSelectedInstancesAndGroup(): void;
  renderItemFunction(item: AnnotationLegendItem): React.ReactNode;
  onUpdateBodyWidth: (width: number) => void;
  sendUpdateApi: (api: LegendItemAPI, id: string) => void;
  isOpenFilterPages: boolean;
}

export const Body: React.FC<Props> = ({
  selectedGroups,
  groupsIsOpenMap,
  users,
  changeGroupPinStatus,
  onGroupNameChanged,
  itemHeight,
  filterResultItems,
  saveListApi,
  renderItemFunction,
  cancelSelectedInstancesAndGroup,
  onHiddenChanged,
  onGroupClick,
  onGroupOpenChange,
  onGroupContextMenu,
  onGroupDoubleClick,
  moveToGroup,
  assignPia,
  groupsMap,
  openGroupMenu,
  getItemsToMove,
  canEditMeasurements,
  pinnedGroupIds,
  onUpdateBodyWidth,
  sendUpdateApi,
  isOpenFilterPages,
}) => {
  const groupsToShow = useGroups(filterResultItems, pinnedGroupIds);
  const disableTextSelect = useCallback((e: React.MouseEvent) => {
    if (e.shiftKey) {
      e.preventDefault();
    }
  }, []);

  return (
    <Styled.Container
      onClick={cancelSelectedInstancesAndGroup}
      onMouseDown={disableTextSelect}
      isPinned={!groupsToShow.length}
    >
      <PinnedGroups
        canEditMeasurement={canEditMeasurements}
        changeGroupPinStatus={changeGroupPinStatus}
        groups={filterResultItems}
        users={users}
        selectedGroups={selectedGroups}
        groupsOpenMap={groupsIsOpenMap}
        onGroupNameChanged={onGroupNameChanged}
        onHiddenChanged={onHiddenChanged}
        onGroupClick={onGroupClick}
        onGroupOpenChange={onGroupOpenChange}
        onGroupContextMenu={onGroupContextMenu}
        onGroupDoubleClick={onGroupDoubleClick}
        moveToGroup={moveToGroup}
        assignPia={assignPia}
        groupsMap={groupsMap}
        openGroupMenu={openGroupMenu}
        getItemsToMove={getItemsToMove}
        sendUpdateApi={sendUpdateApi}
        isOpenFilterPages={isOpenFilterPages}
      />
      <DrawingsAnnotationList
        filterResultItems={filterResultItems}
        itemHeight={itemHeight}
        renderItemFunction={renderItemFunction}
        saveListApi={saveListApi}
      />
      <ReactResizeDetector handleHeight={false} handleWidth={true} onResize={onUpdateBodyWidth} />
    </Styled.Container>
  );
};
