import * as t from 'io-ts';

export const DrawingsGeometryGroupC = t.intersection(
  [
    t.type(
      {
        id: t.string,
        name: t.string,
        measurements: t.array(t.string),
        orderIndex: t.number,
        innerGroups: t.any,
      }),
    t.partial({
      color: t.string,
      parentId: t.string,
      creatorId: t.string,
      createdAt: t.string,
    }),
  ],
  'DrawingsGeometryGroup');


export const DrawingsGeometryGroupsC = t.array(DrawingsGeometryGroupC);

export type DrawingsGeometryGroup = t.TypeOf<typeof DrawingsGeometryGroupC>;
