import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { LinkComponent } from 'common/components/link-component';
import { RenderIf } from 'common/components/render-if';
import { RequestStatus } from 'common/enums/request-status';
import { useActionWithArgsDispatch } from 'common/hooks';
import { PdfFilterActions } from '../../actions/creators/pdf-filter';
import { DrawingsSettingsSwitcherWithLabel } from '../../components/setting-parameter';
import { useCurrentDrawingInfo } from '../../hooks';
import { AiButtonWithStatus } from '../ai-button-with-status';
import { WizzardMainContainer } from '../wizzard-tools';
import { StatusesMap, TextOverrides } from './statuses-map';

interface Props {
  startAddRect: () => void;
  createEmptyAction: () => void;
  hideBG: () => void;
  status: RequestStatus;
}

const PdfFilterEditorComponent: React.FC<Props> = ({
  startAddRect,
  createEmptyAction,
  hideBG,
  status,
}) => {
  const currentDrawing = useCurrentDrawingInfo();

  const switchfilter = useActionWithArgsDispatch(
    PdfFilterActions.switchFilter,
    currentDrawing.pdfId,
    currentDrawing.drawingId,
    !currentDrawing.filter.isEnabled,
  );

  const hasHidden = currentDrawing.filter.state.uselessElementStylesCount > 0;

  return (
    <WizzardMainContainer>
      <RenderIf condition={hasHidden}>
        <DrawingsSettingsSwitcherWithLabel
          label="Show filter"
          checked={currentDrawing.filter.isEnabled}
          toggle={switchfilter}
          disabled={!hasHidden}
        />
      </RenderIf>
      <AiButtonWithStatus
        text={'Add more'}
        onClick={startAddRect}
        icon={Icons.PlusBig}
        status={StatusesMap[status]}
        textOverrides={TextOverrides}
      />
      <RenderIf condition={!hasHidden}>
        <LinkComponent
          text="Hide Background"
          fontSize={12}
          icon={Icons.Hide2D}
          onClick={hideBG}
          fontColor="mainFont"
          size="m"
        />
      </RenderIf>
      <LinkComponent
        text="Reset"
        mood={hasHidden ? 'default' : 'disabled'}
        icon={Icons.UndoClassic}
        onClick={createEmptyAction}
        fontColor={hasHidden ? 'mainFont' : undefined}
        size="m"
      />
    </WizzardMainContainer>
  );
};

export const PdfFilterEditor = React.memo(PdfFilterEditorComponent);
