import { useCallback, useState } from 'react';
import { Styled } from '../styled';

const HEADER_HEIGHT = 125;
const MARGIN= 10;

export function useOpenClose(
  ref: React.MutableRefObject<HTMLDivElement>,
): [top: number, isOpen: boolean, toggleOpen: () => void] {
  const [isOpen, setOpen] = useState(false);
  const [top, setTop] = useState(0);
  const toggleOpen = useCallback(() => {
    setOpen(!isOpen);
    if (!isOpen) {
      const rect = ref.current.getBoundingClientRect();
      const menuHeight = window.innerHeight * Styled.SELECT_PANEL_HEIGHT_SIZE;
      let newTop = rect.top - menuHeight / 2;
      if (newTop + menuHeight > window.innerHeight) {
        newTop = window.innerHeight - menuHeight - MARGIN;
      }

      if (HEADER_HEIGHT > newTop) {
        newTop = HEADER_HEIGHT + MARGIN;
      }
      setTop(newTop - rect.top);
    }
  }, [isOpen, ref]);
  return [top, isOpen, toggleOpen];
}
