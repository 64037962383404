import * as t from 'io-ts';

import { CommentModelC, CommentStatusC, SubCommentModelC } from '../../viewer/interfaces';

export const ViewerNotificationDataC = <C extends t.Mixed>(dataC: C): t.Any =>
  t.strict({
    projectId: t.number,
    data: dataC,
  });

export interface ViewerNotificationData<T> {
  projectId: number;
  data: T;
}

export const ViewerCommentNotificationC = ViewerNotificationDataC(CommentModelC);
export type ViewerCommentNotification = t.TypeOf<typeof ViewerCommentNotificationC>;

export const ViewerSubCommentNotificationC = ViewerNotificationDataC(t.strict({
  commentId: t.number,
  linkCommentId: t.number,
  comment: SubCommentModelC,
}));
export type ViewerSubCommentNotification = t.TypeOf<typeof ViewerSubCommentNotificationC>;

export const ViewerCommentStatusNotificationC = ViewerNotificationDataC(t.strict({
  commentId: t.number,
  status: CommentStatusC,
}));

export type ViewerCommentStatusNotification = t.TypeOf<typeof ViewerCommentStatusNotificationC>;

export const ViewerRemoveCommentNotificationC = ViewerNotificationDataC(t.number);
export type ViewerRemoveCommentNotification = t.TypeOf<typeof ViewerRemoveCommentNotificationC>;

