import { ItemMenu } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { TinyText } from '../text';
import { ElementTooltip } from '../tooltip';
import { Styled } from './styled';

const PROJECT_NAME_MAX_GUARANTEED_LENGTH = 30;

interface MenuItem  {
  text: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  icon?: React.ReactNode;
}

interface Props {
  image: string;
  projectName: string;
  onClick: () => void;
  menuItems?: MenuItem[];
}

export const DemoProjectCard = React.memo<Props>(({
  image,
  projectName,
  menuItems,
  onClick,
}) => {
  return (
    <Styled.Wrapper onClick={onClick}>
      <Styled.Container image={image}>
        <ItemMenu
          menuItems={menuItems}
        />
        <Styled.ProjectName>
          <ElementTooltip
            text={projectName}
            position={'bottom'}
            speed={'s'}
            wordBreakAll={true}
            disabled={projectName.length < PROJECT_NAME_MAX_GUARANTEED_LENGTH}
          >
            <TinyText withEllipsis={true} textAlign='center'>{projectName}</TinyText>
          </ElementTooltip>
        </Styled.ProjectName>
      </Styled.Container>
    </Styled.Wrapper>
  );
},
);
