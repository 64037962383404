import styled from 'styled-components';

const Container = styled.div<{ isComment: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${p => p.isComment ? 'inherit' : 'auto'};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid ${p => p.theme.color.background};
  padding-right: 15px;
`;

const CreateWrapper = styled.div`
  > div {
    width: auto;
    height: 40px;

    > div {
      padding-left: 15px;
    }
  }
`;

const EmptyDiscussionWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Styled = {
  Container,
  HeaderContainer,
  CreateWrapper,
  EmptyDiscussionWrapper,
};
