import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';


import './sequence-loop-warning-dialog.scss';

import { KreoDialog, KreoDialogActions, KreoScrollbars } from 'common/UIKit';
import { KreoDialogActionsPanel } from 'common/UIKit/dialogs/kreo-dialog-actions-panel';
import { Direction, GraphItemDisplayType } from '../../../../components/graph-viewer/enums';

interface ReduxActions {
  closeDialog: () => void;
}

export interface OwnProps {
  loopVertexes: string[];
  edgeDirection: Direction;
  renderVertex: (name: string, displayType: GraphItemDisplayType) => JSX.Element;
}

interface Props extends ReduxActions,  OwnProps {}

export const SEQUENCE_LOOP_WARNING_DIALOG = 'SEQUENCE_LOOP_WARNING_DIALOG';

class SequenceLoopWarningDialogComponent extends React.Component<Props> {

  public render(): JSX.Element {
    const { loopVertexes, renderVertex, edgeDirection } = this.props;
    const vertexes = loopVertexes.map((name, index, array) => ({
      name,
      displayType: this.getVertexDisplayType(index, array.length),
    }));

    return (
      <KreoDialog
        title='Looping Group'
        name={SEQUENCE_LOOP_WARNING_DIALOG}
        bodyClassName='sequence-loop-warning-dialog'
      >
        <div className='sequence-loop-warning-dialog__description'>
          Cannot add this limitation as it will create a loop,
          <br/>
          please select another {edgeDirection === Direction.Forward ? 'predecessor' : 'successor'}.
        </div>
        <div className='sequence-loop-warning-dialog__content'>
          <KreoScrollbars showShadowTop={true}>
            <div className='sequence-loop-warning-dialog__container'>
              {renderVertex(loopVertexes[loopVertexes.length - 1], GraphItemDisplayType.Active)}
              {vertexes.map((x, i) => this.renderVertexWithEdge(x.name, x.displayType, i))}
            </div>
          </KreoScrollbars>
        </div>
        <KreoDialogActionsPanel onSubmit={this.onSubmit} submitBtnName='OK' />
      </KreoDialog>
    );
  }

  @autobind
  private onSubmit(): void {
    this.props.closeDialog();
  }

  private renderVertexWithEdge(name: string, displayType: GraphItemDisplayType, index: number): JSX.Element {
    return (
      <React.Fragment key={index}>
        <div className='sequence-loop-warning-dialog__edge'/>
        {this.props.renderVertex(name, displayType)}
      </React.Fragment>
    );
  }

  private getVertexDisplayType(index: number, totalCount: number): GraphItemDisplayType {
    const edgeDirection = this.props.edgeDirection;
    if (index === totalCount - 1) {
      return GraphItemDisplayType.Active;
    }

    if (index === 0 && edgeDirection === Direction.Forward) {
      return GraphItemDisplayType.Next;
    }

    if (index === totalCount - 2 && edgeDirection === Direction.Back) {
      return GraphItemDisplayType.Previous;
    }

    return GraphItemDisplayType.Normal;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(SEQUENCE_LOOP_WARNING_DIALOG)),
  };
};

const connector = connect(null, mapDispatchToProps);
export const SequenceLoopWarningDialog = connector(SequenceLoopWarningDialogComponent);
