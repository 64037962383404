import * as React from 'react';

import { ItemType } from '../../../../../../../units/subscription/interfaces/billing-collection';
import { Billed } from './billed';
import { Styled } from './styled';

interface Props {
  collectionItems: ItemType[];
  getInvoicesPdf: (invoiceId: string) => void;
}

export const BillingHistory: React.FC<Props> = ({ collectionItems, getInvoicesPdf }) => {
  return (
    <>
      {collectionItems.map(item => {
        return (
          <Styled.Container key={item.id}>
            <Billed
              id={item.id}
              date={item.date}
              total={item.total}
              description={item.description}
              onDownload={getInvoicesPdf}
            />
          </Styled.Container>
        );
      })}
    </>
  );
};
