import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { ControlNames } from 'common/constants/control-names';
import { ConstantFunctions } from 'common/constants/functions';
import { ContextMenu } from './components';
import { Styled } from './styled';

interface Props {
  addButtonDisabled: boolean;
  isAddNewSheetLoading: boolean;
  isFullScreenDrawings: boolean;
  isFullScreenTableReport: boolean;
  isReports: boolean;
  createSpreadSheet: () => void;
  createMeasurementsView: () => void;
  createItemView: () => void;
}

interface ComponentState {
  contextMenu: {
    isOpen: boolean,
    menuPositionX: number,
    menuPositionY: number,
  };
}

export class TableTabsCreateButton extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contextMenu: {
        isOpen: false,
        menuPositionX: 0,
        menuPositionY: 0,
      },
    };
  }

  public render(): JSX.Element {
    const {
      addButtonDisabled,
      isAddNewSheetLoading,
      isReports,
      createSpreadSheet,
      createMeasurementsView,
      createItemView,
    } = this.props;
    const { contextMenu } = this.state;
    const Icon = isAddNewSheetLoading ? Icons.Loader : Icons.PlusBig;
    const buttonText = !isReports && !isAddNewSheetLoading ? 'Create report' : '';

    return (
      <>
        <Styled.CreateButton
          isReports={isReports}
          isAddNewSheetLoading={isAddNewSheetLoading}
          data-control-name={ControlNames.createReport}
        >
          <RectangleButton
            onClick={this.openContextMenu}
            height={36}
            text={buttonText}
            Icon={Icon}
            mood={addButtonDisabled && 'disabled'}
          />
        </Styled.CreateButton>
        <ContextMenu
          isOpen={contextMenu.isOpen}
          positionX={contextMenu.menuPositionX}
          positionY={contextMenu.menuPositionY}
          onClose={this.closeContextMenu}
          createSpreadSheet={createSpreadSheet}
          createMeasurementsView={createMeasurementsView}
          createItemView={createItemView}
        />
      </>
    );
  }

  @autobind
  private openContextMenu(e: React.MouseEvent): void {
    ConstantFunctions.stopEvent(e);
    const heightTable = 550;
    const { left, top } = e.currentTarget.getBoundingClientRect();
    const { isFullScreenDrawings, isFullScreenTableReport } = this.props;
    const contextMenu_X = 20;
    const contextMenu_Y = isFullScreenDrawings || top > heightTable || isFullScreenTableReport ? -25 : 120;
    this.setState({
      contextMenu: {
        isOpen: true,
        menuPositionX: left + contextMenu_X,
        menuPositionY: top - contextMenu_Y,
      },
    });
  }

  @autobind
  private closeContextMenu(): void {
    this.setState({
      contextMenu: {
        isOpen: false,
        menuPositionX: 0,
        menuPositionY: 0,
      },
    });
  }
}
