import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyH: React.FC = () => {
  return (
    <svg
      width='20px'
      height='22px'
      viewBox='0 0 20 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='hotkey_h' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='H'>
          <g id='background'>
            <g id='Group' transform='translate(0.000000, 2.000000)'>
              <g id='path-1-link' fill={KreoColors.f1}>
                <rect id='path-1' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
            <g id='Group'>
              <g id='path-2-link' fill={KreoColors.f1}>
                <rect id='path-2' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
          </g>
          <rect id='padding-left' x='0' y='6' width='6' height='10' />
          <rect id='padding-right' x='14' y='6' width='6' height='10' />
          <path
            // eslint-disable-next-line max-len
            d='M8.41050036,9.51749927 L11.5895009,9.51749927 L11.5895009,6.93249884 C11.5895009,6.64099879 11.5950009,6.54749878 11.6775009,6.39349875 C11.7655009,6.23399873 11.9470009,6.12399871 12.183501,6.12399871 C12.420001,6.12399871 12.601501,6.23399873 12.6895011,6.39349875 C12.7720011,6.54749878 12.7775011,6.64099879 12.7775011,6.93249884 L12.7775011,13.2354999 C12.7775011,13.5269999 12.7720011,13.6204999 12.6895011,13.7745 C12.601501,13.934 12.420001,14.044 12.183501,14.044 C11.9470009,14.044 11.7655009,13.934 11.6775009,13.7745 C11.5950009,13.6204999 11.5895009,13.5269999 11.5895009,13.2354999 L11.5895009,10.5734994 L8.41050036,10.5734994 L8.41050036,13.2354999 C8.41050036,13.5269999 8.40500036,13.6204999 8.32250035,13.7745 C8.23450033,13.934 8.0530003,14.044 7.81650026,14.044 C7.58000023,14.044 7.3985002,13.934 7.31050018,13.7745 C7.22800017,13.6204999 7.22250017,13.5269999 7.22250017,13.2354999 L7.22250017,6.93249884 C7.22250017,6.64099879 7.22800017,6.54749878 7.31050018,6.39349875 C7.3985002,6.23399873 7.58000023,6.12399871 7.81650026,6.12399871 C8.0530003,6.12399871 8.23450033,6.23399873 8.32250035,6.39349875 C8.40500036,6.54749878 8.41050036,6.64099879 8.41050036,6.93249884 L8.41050036,9.51749927 Z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
};
