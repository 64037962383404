import autobind from 'autobind-decorator';

import { TreeFilterPanelState } from 'common/components/tree-filter-panel';
import { QuantityTakeOffApi } from '../../api/quantity-take-off';
import { ModelType } from '../../interfaces/quantity-take-off';
import { AgGridState } from '../quantity-take-off-common-table/save-grid-state-helper';

export interface FilterPanelState extends TreeFilterPanelState {
  selectedIds: number[];
}

export interface SaveState {
  tableState: AgGridState;
  filterPanelState: FilterPanelState;
}

export class UseSaveState {
  private projectId: number;
  private modelType: ModelType;
  private name: string;
  private tableState: AgGridState;
  private filterPanelState: FilterPanelState;

  constructor(projectId: number, modelType: ModelType, name: string) {
    this.projectId = projectId;
    this.name = name;
    this.modelType = modelType;
  }

  @autobind
  public async loadState(): Promise<void> {
    try {
      const state: SaveState = await QuantityTakeOffApi.getRecordsState(this.projectId, this.modelType, this.name);
      this.tableState = state.tableState;
      this.filterPanelState = state.filterPanelState;
    } catch (e) {
      console.warn('Empty state', this.projectId, this.name);
    }
  }

  @autobind
  public getFilterPanelState(): FilterPanelState {
    return this.filterPanelState;
  }

  @autobind
  public getTableState(): AgGridState {
    return this.tableState;
  }

  @autobind
  public saveFilterPanelState(filterPanelState: TreeFilterPanelState, selectedIds: number[]): void {
    this.filterPanelState = {
      ...filterPanelState,
      selectedIds,
    };

    this.saveOnBackEnd();
  }

  @autobind
  public saveTableState(tableState: AgGridState): void {
    this.tableState = tableState;

    this.saveOnBackEnd();
  }

  private saveOnBackEnd(): void {
    const state = {
      tableState: this.tableState,
      filterPanelState: this.filterPanelState,
    };
    QuantityTakeOffApi.updateRecordConfig(this.projectId, this.modelType, state, this.name);
  }
}
