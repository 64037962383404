import { Icons, TinyText, HoverMenu, IconButton, Constants, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import { UserInfoHint, UserTooltipContainer } from 'common/components/drawings/components';
import { RenderIf } from 'common/components/render-if';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { Person } from '../../../../../../../../units/people/interfaces/person';
import { Styled } from './styled';

interface Props {
  fileName: string;
  creator: Person;
  createdAt: string;
  conversionStatus: ViewModelStatus;
  delete: () => void;
  canEdit: boolean;
}

export class DrawingsPdfBrowserFileName extends React.Component<Props> {
  public render(): React.ReactNode {
    const isError = this.props.conversionStatus === ViewModelStatus.Failed;
    const isProgress = this.props.conversionStatus === ViewModelStatus.Waiting ||
      this.props.conversionStatus === ViewModelStatus.Calculating;
    return (
      <HoverMenu position={
        isError || isProgress ? 'bottom' : 'right'
      } shift={10} MenuComponent={this.getTooltipComponent}>
        <Styled.Container>
          <Styled.IconContainer
            status={this.props.conversionStatus}
          >
            {this.getIcon()}
          </Styled.IconContainer>
          <Styled.TextContainer>
            <TinyText withEllipsis={true}>{this.props.fileName}</TinyText>
          </Styled.TextContainer>
          <RenderIf condition={this.props.canEdit && isError}>
            <Styled.DeleteButtonContainer>
              <IconButton
                Icon={Icons.Delete}
                width={20}
                height={20}
                onClick={this.props.delete}
                defaultColor={Constants.Colors.GENERAL_COLORS.red}
                hoverColor={Constants.Colors.GENERAL_COLORS.red}
              />
            </Styled.DeleteButtonContainer>
          </RenderIf>
        </Styled.Container>

      </HoverMenu>
    );
  }

  private getIcon(): React.ReactNode {
    const { conversionStatus } = this.props;
    switch (conversionStatus) {
      case ViewModelStatus.Failed:
        return <Icons.Error />;
      case ViewModelStatus.Waiting:
      case ViewModelStatus.Calculating:
        return <Icons.Loader />;
      default:
        return <Icons.PageName2D />;
    }
  }

  @autobind
  private getTooltipComponent(): React.ReactNode {
    const { creator, createdAt, conversionStatus } = this.props;
    switch (conversionStatus) {
      case ViewModelStatus.Failed:
        return (
          <Styled.ErrorTextContainer>
            <Text fontSize={14}>
              Oops, something happened...
            </Text>
          </Styled.ErrorTextContainer>
        );
      case ViewModelStatus.Waiting:
      case ViewModelStatus.Calculating:
        return (
          <Styled.ErrorTextContainer>
            <Text fontSize={14}>
              Processing in progress
            </Text>
          </Styled.ErrorTextContainer>
        );
      default:
        return (
          <UserTooltipContainer>
            {creator && <UserInfoHint title={'upload by'} user={creator} timestamp={createdAt}/>}
          </UserTooltipContainer>
        );
    }
  }
}
