import { arrayUtils } from 'common/utils/array-utils';
import {
  DrawingsCountGeometry,
  DrawingsGeometryGroupWithNesting,
  DrawingsGeometryInstanceWithIdAndGroupId,
  DrawingsPolylineGeometry,
  DrawingsSelectAggregationGroup,
  PivotedInstance,
} from '../../interfaces';
import { AnnotationLegendItem } from '../../interfaces/annotation-legend-tree';
import { AnnotationLegendItemTypeguards } from '../../utils/annotation-legend-item-typeguards';
import { DrawingsMenuUtils } from '../../utils/drawings-menu-utils';


const StrokedKeys: Array<keyof DrawingsPolylineGeometry> = ['color', 'strokeWidth', 'strokeStyle', 'offset', 'height'];
const PolylineKeys: Array<keyof DrawingsPolylineGeometry> = [...StrokedKeys, 'thickness'];
const CountKeys: Array<keyof DrawingsCountGeometry> = ['color', 'shape'];

const PivotCompareKeysByType: Record<string, string[]> = {
  [DrawingsSelectAggregationGroup.Area]: StrokedKeys,
  [DrawingsSelectAggregationGroup.Line]: PolylineKeys,
  [DrawingsSelectAggregationGroup.Count]: CountKeys,
};


function createInstanceKey(item: DrawingsGeometryInstanceWithIdAndGroupId, groupId: string): string {
  const type = DrawingsMenuUtils.getAggregationType(item);
  const keys = PivotCompareKeysByType[type];
  const keysString = keys.map((key) => `${key}:${item.geometry[key]}`).join('|');
  return `${keysString}|name:${item.name}|type:${type}|parent:${groupId}`;
}

export function getVisibleItems(
  items: AnnotationLegendItem[],
  openGroups: Record<string, boolean>,
  onlyGroups: boolean,
  isPivot: boolean,
  nestingCount: number = 0,
  parentId: string = null,
): [AnnotationLegendItem[], AnnotationLegendItem[]] {
  const pivots: Record<string, PivotedInstance> = {};
  const resultItems = new Array<AnnotationLegendItem>();
  const allInnerItems = new Array<AnnotationLegendItem>();

  const addItem = (item: DrawingsGeometryInstanceWithIdAndGroupId): void => {
    const key = createInstanceKey(item, parentId);
    if (!pivots[key]) {
      const type = DrawingsMenuUtils.getAggregationType(item);
      const pivotItem: PivotedInstance = {
        id: key,
        name: item.name,
        groupId: item.groupId,
        color: item.geometry.color,
        type,
        groupedGeometries: [],
        nestingCount,
      };
      const fields = PivotCompareKeysByType[type];
      for (const field of fields) {
        pivotItem[field] = item.geometry[field];
      }
      pivots[key] = pivotItem;
      resultItems.push(pivotItem);
      if (parentId) {
        allInnerItems.push(pivotItem);
      }
    }
    pivots[key].groupedGeometries.push(item.id);
  };

  for (const item of items) {
    if (AnnotationLegendItemTypeguards.isGroup(item)) {
      if (!nestingCount && item.parentId || (nestingCount && item.parentId !== parentId)) {
        continue;
      }
      const [ result, innerItems ] = getVisibleItems(
        item.allInnerInstances,
        openGroups,
        onlyGroups,
        isPivot,
        nestingCount + 1,
        item.id,
      );
      const updatedItem = { ...item, allInnerInstances: innerItems } as DrawingsGeometryGroupWithNesting;
      resultItems.push(updatedItem);
      if (parentId) {
        allInnerItems.push(updatedItem);
        arrayUtils.extendArray(allInnerItems, innerItems);
      }
      if (openGroups[item.id]) {
        arrayUtils.extendArray(resultItems, result);
      }
    } else if (!onlyGroups) {
      if (item.groupId !== parentId) {
        continue;
      }
      if (isPivot) {
        addItem(item as DrawingsGeometryInstanceWithIdAndGroupId);
      } else {
        resultItems.push(item);
        if (parentId) {
          allInnerItems.push(item);
        }
      }
    }
  }

  return [resultItems, allInnerItems];
}
