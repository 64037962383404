import { ExcelFormulaHelper, UpdateCellData } from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { TwoDRegexGetter } from '../2d-regex';
import { MoveDataPayload, TableDataMoveType } from './interfaces';

const isRemovePartLess = (removePoints: number[], rangePoints: number[]): boolean => {
  return removePoints[0] <= rangePoints[0] && removePoints[1] <= rangePoints[0];
};

const isRemoveIsLarge = (removePoints: number[], rangePoints: number[]): boolean => {
  return removePoints[0] > rangePoints[1] && removePoints[1] > rangePoints[1];
};

const hasCommonPart = (removePoints: number[], rangePoints: number[]): boolean => {
  return !(isRemovePartLess(removePoints, rangePoints) || isRemoveIsLarge(removePoints, rangePoints));
};

const hadnleDelete = (
  startIndex: number,
  offset: number,
  rangeFirstIndex: number,
  rangeSecondIndex: number,
): number[] => {
  const removePoints = [startIndex, startIndex + offset - 1];
  const isValidOrder = rangeSecondIndex >= rangeFirstIndex;
  const rangePoints = isValidOrder
    ? [rangeFirstIndex, rangeSecondIndex]
    : [rangeSecondIndex, rangeFirstIndex];
  const commonPoints = hasCommonPart(removePoints, rangePoints)
    ? [Math.max(removePoints[0], rangePoints[0]), Math.min(removePoints[1], rangePoints[1])]
    : null;

  const commonLenght = commonPoints
    ? commonPoints[1] - commonPoints[0] + 1
    : 0;
  const outerLenght = removePoints[0] >= rangePoints[1] && removePoints[1] >= rangePoints[1]
    ? 0
    : offset - commonLenght;

  const deletedPonints = [rangePoints[0], rangePoints[1] - commonLenght];
  const movedPoints = [deletedPonints[0] - outerLenght, deletedPonints[1] - outerLenght];

  if (movedPoints[0] > movedPoints[1] || movedPoints[0] < 0 || movedPoints[1] < 0) {
    return null;
  }

  return isValidOrder
    ? movedPoints
    : movedPoints.reverse();
};

const applyRowOffset = (cellId: string, rowIndex: number): string => {
  const { columnId } = TwoDRegexGetter.getFullCellField(cellId);
  const newRowId = ExcelTableRowIdentification.getRowIdFromIndex(rowIndex);
  return ExcelFormulaHelper.getCellLink(undefined, columnId, newRowId);
};

const hadleDeleteRows = (
  startIndex: number,
  offset: number,
  startId: string,
  endId: string,
): [string, string]  => {
  const { rowId: startRowId } = TwoDRegexGetter.getFullCellField(startId);
  const firstIndex = ExcelTableRowIdentification.getRowIndexFromId(startRowId);
  const { rowId: endRowId } = TwoDRegexGetter.getFullCellField(endId);
  const secondIndex = ExcelTableRowIdentification.getRowIndexFromId(endRowId);

  const points = hadnleDelete(startIndex, offset, firstIndex, secondIndex);

  if (points === null) {
    return [null, null];
  }
  const updateStartId = applyRowOffset(startId, points[0]);
  const updateEndtId = applyRowOffset(endId, points[1]);

  return [updateStartId, updateEndtId];
};

const applyColumnOffset = (cellId: string, columnIndex: number): string => {
  const { rowId } = TwoDRegexGetter.getFullCellField(cellId);
  const newColumnId = ExcelColumnHelper.indexToExcelColName(columnIndex);
  return ExcelFormulaHelper.getCellLink(undefined, newColumnId, rowId);
};

const hadnleDeleteColumns = (
  startIndex: number,
  offset: number,
  rangeStartId: string,
  rangeEndId: string,
): [string, string] => {
  const { columnId: startColumnId } = TwoDRegexGetter.getFullCellField(rangeStartId);
  const firstIndex = ExcelColumnHelper.excelColNameToIndex(startColumnId);
  const { columnId: endColumnId } = TwoDRegexGetter.getFullCellField(rangeEndId);
  const secondIndex = ExcelColumnHelper.excelColNameToIndex(endColumnId);

  const points = hadnleDelete(startIndex, offset, firstIndex, secondIndex);

  if (points === null || points[0] < 1 || points[1] < 1) {
    return [null, null];
  }

  const updateStartId = applyColumnOffset(rangeStartId, points[0]);
  const updateEndtId = applyColumnOffset(rangeEndId, points[1]);

  return [updateStartId, updateEndtId];
};

export const removeRefAfterDeleteRows = (
  updatedCell: UpdateCellData,
  payload: MoveDataPayload,
  startId: string,
  endId: string,
): [string, string] => {
  if (updatedCell.prevValue === updatedCell.value) {
    return [startId, endId];
  }
  const { type, offset, startIndex } = payload;
  if (type === TableDataMoveType.DeleteRows) {
    return hadleDeleteRows(startIndex, offset, startId, endId);
  }

  if (type === TableDataMoveType.DeleteColumns) {
    return hadnleDeleteColumns(startIndex, offset, startId, endId);
  }

  return [startId, endId];
};
