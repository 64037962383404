import { CollaborationElementInfo } from '../../projects/interfaces/collaboration/element-info';

function getByPath(path: any, state: any): any {
  if (path.length === 0) {
    return state;
  }
  const p = path.splice(0, 1);
  if (path.length > 0) {
    return getByPath(path, state[p]);
  } else {
    return state[p];
  }
}

function* childIdentificators(identificator: any, item: any): any {
  const splited = identificator;
  yield splited;
  const level = (splited.length - 1) / 2;
  if (level < 3) {
    let iter = 0;
    for (const i of item.v) {
      yield* childIdentificators([...identificator, 'v', iter], i);
      iter++;
    }
  } else {
    // do nothing
  }
}

function approveVisibilityToElement(element: any, mode: any): any {
  if (element.disvisible === mode) {
    return element;
  }
  element.disvisible = mode;
  if (element.v) {
    element.v.map((item: any): any => {
      return approveVisibilityToElement(item, mode);
    });
  }
  return element;
}

function* parentIdentificators(indifier: any, items: any, show: any): any {
  const splited = indifier;
  splited.pop();
  const pch = getByPath(Array(...splited), items);
  const pv = pch.filter((x: any): boolean => {
    return x.disvisible === show;
  });
  if (pv.length + 1 === pch.length) {
    if (splited.length > 0) {
      splited.pop();
      yield splited;
      yield* parentIdentificators(splited, items, show);
    }
  } else if (splited.length > 0) {
    const children = getByPath(splited, items);
    const dv = children.filter((x: any): boolean => x.disvisible === show);
    if (dv.length + 1 === children.length) {
      splited.pop();
      yield splited;
      if (splited.length > 2) {
        yield* parentIdentificators(splited, items, show);
      }
    }
  }
}


function* getSimplePathes(path: any): any {
  const spl = path.split('.');
  spl.pop();
  if (spl.length >= 1) {
    spl.pop();
    yield spl;
    if (spl.length > 1) {
      yield* getSimplePathes(spl.join('.'));
    }
  }
}

function getHandlesProps(item: any): any[] {
  const ret = [];
  if (item.rd) {
    ret.push(item.rd);
  } else if (item.v) {
    for (const i of item.v) {
      ret.push(...getHandlesProps(i));
    }
  }

  return ret;
}

function getPropertiesData(props: any, items: any, pgs: any): any {
  const ret = [];
  items.forEach((x: any) => {
    const p = {
      n: pgs[x.n], v: [],
    };
    x.v.forEach((i: any) => {
      p.v.push(props[i]);
    });
    ret.push(p);
  });
  return ret;
}

function* getHandlesByIdsps(pathes: CollaborationElementInfo[], state: any): any {
  for (const p of pathes) {
    yield state[p.category].v[p.family].v[p.type];
  }
}
function getIdps(ids: number[], idps: CollaborationElementInfo[]): any {
  const s = new Set(ids);
  return idps.filter((x: any): any => s.has(x.id));
}

function updateTreeRender(idps: CollaborationElementInfo[], tree: any[], mode: string): any {
  tree.forEach((category: any, index: any) => {
    const ids = idps.filter((x: any, i: any): any => {
      if (x.category === index) {
        idps.splice(i, 0);
        return true;
      } else {
        return false;
      }
    });
    if (ids.length > 0) {
      let change_category = ids.length === (category.end - category.start);
      let ccount = 0;
      category.v.forEach((family: any, j: any) => {
        let change_family = change_category;
        let fids = [];
        if (!change_family) {
          fids = ids.filter((x: any, i: any): any => {
            if (x.family === j) {
              ids.splice(i, 0);
              return true;
            } else {
              return false;
            }
          });
          if (fids.length > 0) {
            change_family = fids.length === (family.end - family.start);
          }
        }

        if (change_family || fids.length > 0) {
          let fcount = 0;

          family.v.forEach((type: any, k: any) => {
            let change_type = change_family;

            let tids = [];
            if (!change_type) {
              tids = ids.filter((x: any, i: any): any => {
                if (x.type === k) {
                  fids.splice(i, 0);
                  return true;
                } else {
                  return false;
                }
              });

              if (tids.length > 0) {
                change_type = tids.length === (type.end - type.start);
              }
            }
            if (change_type) {
              type.disvisible = mode;
            }

            if (tids.length > 0) {
              type.disvisible = mode;
            }
            if (type.disvisible === mode) {
              fcount++;
            }
            change_type = false;
          });
          if (!change_family && fcount === family.v.length) {
            change_family = true;
          }
        }

        if (change_family) {
          family.disvisible = mode;
        } else if (fids.length > 0) {
          family.disvisible = 'standard';
        }
        if (family.disvisible === mode) {
          ccount++;
        }
        change_family = false;
      });
      if (ccount === category.v.length) {
        change_category = true;
      }

      if (change_category) {
        category.disvisible = mode;
      } else if (ids.length > 0) {
        category.disvisible = 'standard';
      }
      change_category = false;
    }

  });
}


function selectedOnTree(ids: CollaborationElementInfo[], tree: any, provider: any): any {
  const idps = ids;
  let hs = provider === null || provider === undefined;
  let last = false;
  let ibefore = 0;
  tree.forEach((category: any, i: any) => {
    ibefore++;
    const cids = idps.filter((x: any, index: any): boolean => {
      if (x.category === i) {
        idps.splice(index, 1);
        return true;
      } else {
        return false;
      }
    });
    last = idps.length === 0;

    category.selected = false;
    if (cids.length === (category.end - category.start)) {
      category.selected = true;
      if (!hs && last) {
        hs = true;
        provider.scrollTo(ibefore, true);
      }
    } else if (cids.length > 0) {
      category.v.forEach((family: any, j: any): any => {
        ibefore++;
        family.selected = false;
        const fids = cids.filter((x: any, index: any): any => {
          if (x.family === j) {
            cids.splice(index, 1);
            return true;
          } else {
            return false;
          }
        });

        if (fids.length === (family.end - family.start)) {
          family.selected = true;
          category.expanded = true;
          if (!hs && last) {
            hs = true;
            provider.scrollTo(ibefore, true);
          }
        } else if (fids.length > 0) {
          family.v.forEach((type: any, index: any) => {
            ibefore++;
            type.selected = false;
            const tids = fids.filter((x: any, t: any): any => {
              if (x.type === index) {
                cids.splice(t, 1);
                return true;
              } else {
                return false;
              }
            });

            type.selected = false;
            if (tids.length > 0) {
              type.selected = true;
              if (!hs && last) {
                hs = true;
                provider.scrollTo(ibefore, true);
              }
              family.expanded = true;
              category.expanded = true;
            }
          });
        } else if (family.expanded) {
          family.expanded = false;
        }
      });
    } else if (category.expanded) {
      category.expanded = false;
    }
  });
}


export {
  updateTreeRender,
  childIdentificators,
  getHandlesProps,
  getSimplePathes,
  getByPath,
  getPropertiesData,
  approveVisibilityToElement,
  getIdps,
  getHandlesByIdsps,
  selectedOnTree,
  parentIdentificators,
};

export * from './filters';
