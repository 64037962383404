import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { MagicSearchState } from 'common/components/drawings/interfaces';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { AiSearchHelperBase } from './ai-search-helper-base';

export class MagicSearch extends AiSearchHelperBase {
  private _source: paper.Path[];
  private _sourceGroup: paper.Group;

  public override destroy(): void {
    super.destroy();
    this._sourceGroup.remove();
  }

  @autobind
  protected override updatePreviews(magicSearch: MagicSearchState): void {
    if (!this._source?.length) {
      this._sourceGroup = this._sourceGroup || new paper.Group();
      this._sourceGroup.addTo(this._config.layer);
      const { strokeStyle, strokeWidth, stroke, fill } = this.getStyles();
      this._source = this._config.magicSearchDataObserver.getContext().contoursToSearch.map((contour) => {
        const path = new paper.Path(contour.map(x => new paper.Point(x)));
        path.closePath();
        DrawingsPaperUtils.updatePathStyles(path);
        path.strokeWidth = strokeWidth;
        path.strokeColor = stroke;
        path.dashArray = strokeStyle;
        path.fillColor = fill;
        path.addTo(this._sourceGroup);
        return path;
      });
    }
    super.updatePreviews(magicSearch);
  }
}
