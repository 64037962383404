import React, { useCallback, useMemo } from 'react';

import { usePersistedStorageValueToggle } from 'persisted-storage/hooks';
import {
  useGeometryOperationContext,
} from '../drawings-contexts/geometry-operations-context';
import { DrawingsBooleanOperation } from '../drawings-geometry/drawings-helpers';
import { useApplyOperation, useBooleanKeyboardListener, useCancelBoolean } from './hooks';
import { Styled } from './styled';
import { SubtractDialog } from './subtract-dialog';

interface Props {
  sendRef: (ref: HTMLDivElement, isBooleanMenu?: boolean) => void;
}

const DrawingsSubtractDialogWrapperComponent: React.FC<Props> = ({ sendRef }) => {
  const [ shouldKeepOld, toggleKeepOld ] = usePersistedStorageValueToggle('drawingIsKeepPolygon');
  const { selectedIdForSubtract, setSelectedIdForSubtract } = useGeometryOperationContext();
  const applySettings = useMemo(() => ({
    keepOld: shouldKeepOld,
    baseInstanceId: selectedIdForSubtract,
  }), [shouldKeepOld, selectedIdForSubtract]);
  const apply = useApplyOperation(DrawingsBooleanOperation.Subtract, applySettings);
  const applyWrapper = useCallback(() => {
    apply();
    setSelectedIdForSubtract(null);
  }, [setSelectedIdForSubtract, apply]);
  const cancel = useCancelBoolean();
  useBooleanKeyboardListener(applyWrapper, cancel);
  const removeBaseId = useCallback(() => setSelectedIdForSubtract(null), [setSelectedIdForSubtract]);

  return (
    <Styled.Container ref={sendRef}>
      <SubtractDialog
        toggleKeepPolygons={toggleKeepOld}
        isKeepPolygons={shouldKeepOld}
        isBasePolygonSelected={!!selectedIdForSubtract}
        switchToPolygonSelectMode={removeBaseId}
        apply={applyWrapper}
        cancel={cancel}
      />
    </Styled.Container>
  );
};

export const DrawingsSubtractDialogWrapper = React.memo(DrawingsSubtractDialogWrapperComponent);
