import React from 'react';
import { connect } from 'react-redux';

import { State as ReduxState } from 'common/interfaces/state';
import { ResourceType } from '../../enums';
import { DatabaseResourceLaborModifyPanel } from '../modify-database-resource-labor-panel';
import { DatabaseResourceMaterialModifyPanel } from '../modify-database-resource-material-panel';
import { DatabaseResourcePlantModifyPanel } from '../modify-database-resource-plant-panel';

interface Props {
  resourceType: ResourceType;
}

class ModifyDatabaseResourcePanelComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    switch (this.props.resourceType) {
      case ResourceType.Labour: return <DatabaseResourceLaborModifyPanel/>;
      case ResourceType.Plant: return <DatabaseResourcePlantModifyPanel/>;
      case ResourceType.Material: return <DatabaseResourceMaterialModifyPanel/>;
      default:
        throw new Error(`ModifyDatabaseResourcePanel.render(): unknown resource type ${this.props.resourceType}`);
    }
  }
}

const mapStateToProps = (state: ReduxState): Props => ({
  resourceType: state.database.currentDatabase.resourceListing.resourceType,
});

export const ModifyDatabaseResourcePanel = connect(mapStateToProps)(ModifyDatabaseResourcePanelComponent);
