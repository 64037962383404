import { Text } from '@kreo/kreo-ui-components';
import styled, { keyframes } from 'styled-components';

const AppearanceAnimations = keyframes`
  0% {
    transform: rotateZ(225deg) scale(0);
  }
  100% {
    transform: rotateZ(0deg) scale(1);  
  }
`;


const Icon = styled.div`
  width: 20px;
  height: 20px;
  fill: ${p => p.theme.color.gray};
`;

const DeleteButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: none;
  background: #E15556;
  animation: ${AppearanceAnimations} 0.375s ease both;
  svg {
    width: 10px;
    height: 10px;
    fill: white;
  }
`;

const Container = styled.div<{ canRemoveSelection?: boolean }>`
  display: flex;
  padding: 5px 15px 5px 10px;
  height: 30px;
  border-radius: 15px;
  align-items: center;
  background-color: ${p => p.theme.color.background};
  cursor: pointer;
  max-width: 140px;
  box-sizing: border-box;
  ${Text} {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 85px;
    text-overflow: ellipsis;
  }

  &:hover{
    background-color: ${p => p.theme.color.pale};
    ${DeleteButton} {
      display: flex;
      justify-content: center;
      align-items: center;

    }
    ${Icon} {
      display: ${p => p.canRemoveSelection ? 'none' : 'block'};
    }
  }
`;


export const Styled = {
  Container,
  Icon,
  DeleteButton,
};
