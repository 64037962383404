import { Action } from 'redux';

import { SubscriptionType } from 'common/constants/subscription';
import { ActionWith } from 'common/interfaces/action-with';
import { Feed } from 'common/interfaces/feed';
import { CurrencyModel } from '../../../account/interfaces/currency-model';
import { AdminCompanyVm } from '../../interfaces/admin-company-vm';
import { AdminDatabaseVm } from '../../interfaces/admin-database-vm';
import { FeedParams } from '../../interfaces/feed';
import { ShortRole } from '../../interfaces/short-role';
import { ShortUser } from '../../interfaces/short-user';
import { SubscriptionPlanOption } from '../../interfaces/subscription-plan-option';
import {
  AttachDatabasesParams,
  AttachEmployeeParams,
  AttachEmployeeSucceedParams,
  AttachEmployeeToProjectParams,
  CompanyCurrencyPayload,
  CompanyIdAndSubscriptionTypePayload,
  CompanyMockSubscriptionPayload,
  CompanyPreferImperialPayload,
  DatabaseFeedParams,
  DetachDatabasesParams,
  LoadRolesParams,
} from '../payloads/companies';
import { AdminCompaniesActionTypes } from '../types/companies';

function loadCompanies(
  data: FeedParams,
): ActionWith<FeedParams> {
  return {
    type: AdminCompaniesActionTypes.LOAD_REQUEST,
    payload: data,
  };
}

function loadCompaniesSucceed(
  data: Feed<AdminCompanyVm>,
): ActionWith<Feed<AdminCompanyVm>> {
  return {
    type: AdminCompaniesActionTypes.LOAD_SUCCEED,
    payload: data,
  };
}

function deleteProjects(
  companyId: number,
): ActionWith<number> {
  return {
    type: AdminCompaniesActionTypes.DELETE_PROJECTS_REQUEST,
    payload: companyId,
  };
}

function deleteProjectsSucceed(
  companyId: number,
): ActionWith<number> {
  return {
    type: AdminCompaniesActionTypes.DELETE_PROJECTS_SUCCEED,
    payload: companyId,
  };
}

function loadDatabases(
  data: DatabaseFeedParams,
): ActionWith<DatabaseFeedParams> {
  return {
    type: AdminCompaniesActionTypes.LOAD_DATABASES_REQUEST,
    payload: data,
  };
}

function loadDatabasesSucceed(
  data: Feed<AdminDatabaseVm>,
): ActionWith<Feed<AdminDatabaseVm>> {
  return {
    type: AdminCompaniesActionTypes.LOAD_DATABASES_SUCCEED,
    payload: data,
  };
}

function attachDatabases(
  data: AttachDatabasesParams,
): ActionWith<AttachDatabasesParams> {
  return {
    type: AdminCompaniesActionTypes.ATTACH_DATABASES_REQUEST,
    payload: data,
  };
}

function attachDatabasesSucceed(
  data: AttachDatabasesParams,
): ActionWith<AttachDatabasesParams> {
  return {
    type: AdminCompaniesActionTypes.ATTACH_DATABASES_SUCCEED,
    payload: data,
  };
}

function detachDatabase(
  data: DetachDatabasesParams,
): ActionWith<DetachDatabasesParams> {
  return {
    type: AdminCompaniesActionTypes.DETACH_DATABASE_REQUEST,
    payload: data,
  };
}

function detachDatabaseSucceed(
  data: DetachDatabasesParams,
): ActionWith<DetachDatabasesParams> {
  return {
    type: AdminCompaniesActionTypes.DETACH_DATABASE_SUCCEED,
    payload: data,
  };
}


function loadEmployees(
  data: FeedParams,
): ActionWith<FeedParams> {
  return {
    type: AdminCompaniesActionTypes.LOAD_EMPLOYEES_REQUEST,
    payload: data,
  };
}

function loadEmployeesSucceed(
  data: Feed<ShortUser>,
): ActionWith<Feed<ShortUser>> {
  return {
    type: AdminCompaniesActionTypes.LOAD_EMPLOYEES_SUCCEED,
    payload: data,
  };
}

function attachEmployee(
  data: AttachEmployeeParams,
): ActionWith<AttachEmployeeParams> {
  return {
    type: AdminCompaniesActionTypes.ATTACH_EMPLOYEE_REQUEST,
    payload: data,
  };
}

function attachEmployeeSucceed(
  data: AttachEmployeeSucceedParams,
): ActionWith<AttachEmployeeSucceedParams> {
  return {
    type: AdminCompaniesActionTypes.ATTACH_EMPLOYEE_SUCCEED,
    payload: data,
  };
}

function detachEmployee(
  data: AttachEmployeeParams,
): ActionWith<AttachEmployeeParams> {
  return {
    type: AdminCompaniesActionTypes.DETACH_EMPLOYEE_REQUEST,
    payload: data,
  };
}

function detachEmployeeSucceed(
  data: AttachEmployeeParams,
): ActionWith<AttachEmployeeParams> {
  return {
    type: AdminCompaniesActionTypes.DETACH_EMPLOYEE_SUCCEED,
    payload: data,
  };
}

function loadRoles(
  companyId: number,
  subscriptionType: SubscriptionType,
): ActionWith<LoadRolesParams> {
  return {
    type: AdminCompaniesActionTypes.LOAD_ROLES_REQUEST,
    payload: { companyId, subscriptionType },
  };
}

function loadRolesSucceed(
  data: ShortRole[],
): ActionWith<ShortRole[]> {
  return {
    type: AdminCompaniesActionTypes.LOAD_ROLES_SUCCEED,
    payload: data,
  };
}

function changeRole(
  companyId: AttachEmployeeParams,
): ActionWith<AttachEmployeeParams> {
  return {
    type: AdminCompaniesActionTypes.CHANGE_ROLE_REQUEST,
    payload: companyId,
  };
}

function changeRoleSucceed(
  data: AttachEmployeeSucceedParams,
): ActionWith<AttachEmployeeSucceedParams> {
  return {
    type: AdminCompaniesActionTypes.CHANGE_ROLE_SUCCEED,
    payload: data,
  };
}

function loadProjectsEmployees(
  projectId: number,
): ActionWith<number> {
  return {
    type: AdminCompaniesActionTypes.LOAD_PROJECTS_EMPLOYEES_REQUEST,
    payload: projectId,
  };
}

function loadProjectsEmployeesSucceed(
  data: ShortUser[],
): ActionWith<ShortUser[]> {
  return {
    type: AdminCompaniesActionTypes.LOAD_PROJECTS_EMPLOYEES_SUCCEED,
    payload: data,
  };
}

// --------------------------------------
// Attach/Detach employee to/from project
function attachEmployeeToProject(
  data: AttachEmployeeToProjectParams,
): ActionWith<AttachEmployeeToProjectParams> {
  return {
    type: AdminCompaniesActionTypes.ATTACH_EMPLOYEE_TO_PROJECT_REQUEST,
    payload: data,
  };
}

function attachEmployeeToProjectSucceed(
  data: AttachEmployeeToProjectParams,
): ActionWith<AttachEmployeeToProjectParams> {
  return {
    type: AdminCompaniesActionTypes.ATTACH_EMPLOYEE_TO_PROJECT_SUCCEED,
    payload: data,
  };
}

function detachEmployeeFromProject(
  data: AttachEmployeeToProjectParams,
): ActionWith<AttachEmployeeToProjectParams> {
  return {
    type: AdminCompaniesActionTypes.DETACH_EMPLOYEE_FROM_PROJECT_REQUEST,
    payload: data,
  };
}

function detachEmployeeFromProjectSucceed(
  data: AttachEmployeeToProjectParams,
): ActionWith<AttachEmployeeToProjectParams> {
  return {
    type: AdminCompaniesActionTypes.DETACH_EMPLOYEE_FROM_PROJECT_SUCCEED,
    payload: data,
  };
}

function getSubscriptionPlans(): Action {
  return { type: AdminCompaniesActionTypes.GET_SUBSCRIPTION_PLANS_REQUEST };
}

function getSubscriptionPlansSucceeded(planOptions: SubscriptionPlanOption[]): ActionWith<SubscriptionPlanOption[]> {
  return {
    type: AdminCompaniesActionTypes.GET_SUBSCRIPTION_PLANS_SUCCEEDED,
    payload: planOptions,
  };
}

function getSubscriptionPlansFailed(): Action {
  return {
    type: AdminCompaniesActionTypes.GET_SUBSCRIPTION_PLANS_FAILED,
  };
}

function setMockSubscription(
  companyId: number,
  planId: string,
  subscriptionType: SubscriptionType,
  planQuantity: number,
): ActionWith<CompanyMockSubscriptionPayload> {
  return {
    type: AdminCompaniesActionTypes.SET_MOCK_SUBSCRIPTION_REQUEST,
    payload: { companyId, planId, planQuantity, subscriptionType },
  };
}

function setMockSubscriptionSucceeded(
  companyId: number,
  planId: string,
  subscriptionType: SubscriptionType,
  planQuantity: number,
): ActionWith<CompanyMockSubscriptionPayload> {
  return {
    type: AdminCompaniesActionTypes.SET_MOCK_SUBSCRIPTION_SUCCEEDED,
    payload: { companyId, planId, planQuantity, subscriptionType },
  };
}

function setMockSubscriptionFailed(companyId: number): ActionWith<number> {
  return {
    type: AdminCompaniesActionTypes.SET_MOCK_SUBSCRIPTION_FAILED,
    payload: companyId,
  };
}

function resetMockSubscription(
  companyId: number, subscriptionType: SubscriptionType,
): ActionWith<CompanyIdAndSubscriptionTypePayload> {
  return {
    type: AdminCompaniesActionTypes.RESET_MOCK_SUBSCRIPTION_REQUEST,
    payload: { companyId, subscriptionType },
  };
}

function resetMockSubscriptionSucceeded(
  companyId: number, subscriptionType: SubscriptionType,
): ActionWith<CompanyIdAndSubscriptionTypePayload> {
  return {
    type: AdminCompaniesActionTypes.RESET_MOCK_SUBSCRIPTION_SUCCEEDED,
    payload: { companyId, subscriptionType },
  };
}

function resetMockSubscriptionFailed(companyId: number): ActionWith<number> {
  return {
    type: AdminCompaniesActionTypes.RESET_MOCK_SUBSCRIPTION_FAILED,
    payload: companyId,
  };
}

function createEmptySubscription(
  companyId: number, subscriptionType: SubscriptionType,
): ActionWith<CompanyIdAndSubscriptionTypePayload> {
  return {
    type: AdminCompaniesActionTypes.CREATE_EMPTY_SUBSCRIPTION,
    payload: { companyId, subscriptionType },
  };
}

function createEmptySubscriptionSucceeded(
  companyId: number, subscriptionType: SubscriptionType,
): ActionWith<CompanyIdAndSubscriptionTypePayload> {
  return {
    type: AdminCompaniesActionTypes.CREATE_EMPTY_SUBSCRIPTION_SUCCEEDED,
    payload: { companyId, subscriptionType },
  };
}

function createEmptySubscriptionFailed(companyId: number): ActionWith<number> {
  return {
    type: AdminCompaniesActionTypes.CREATE_EMPTY_SUBSCRIPTION_FAILED,
    payload: companyId,
  };
}


function loadCurrencies(): Action {
  return {
    type: AdminCompaniesActionTypes.LOAD_CURRENCIES_REQUEST,
  };
}

function loadCurrenciesSucceeded(currencies: CurrencyModel[]): ActionWith<CurrencyModel[]> {
  return {
    type: AdminCompaniesActionTypes.LOAD_CURRENCIES_SUCCEEDED,
    payload: currencies,
  };
}

function loadCurrenciesFailed(): Action {
  return {
    type: AdminCompaniesActionTypes.LOAD_CURRENCIES_FAILED,
  };
}

function setCurrency(companyId: number, currencyCode: string): ActionWith<CompanyCurrencyPayload> {
  return {
    type: AdminCompaniesActionTypes.SET_CURRENCY_REQUEST,
    payload: { companyId, currencyCode },
  };
}

function setCurrencySucceeded(companyId: number, currencyCode: string): ActionWith<CompanyCurrencyPayload> {
  return {
    type: AdminCompaniesActionTypes.SET_CURRENCY_SUCCEEDED,
    payload: { companyId, currencyCode },
  };
}

function setCurrencyFailed(companyId: number): ActionWith<number> {
  return {
    type: AdminCompaniesActionTypes.SET_CURRENCY_FAILED,
    payload: companyId,
  };
}

function setPreferImperial(companyId: number, preferImperial: boolean): ActionWith<CompanyPreferImperialPayload> {
  return {
    type: AdminCompaniesActionTypes.SET_PREFER_IMPERIAL_REQUEST,
    payload: { companyId, preferImperial },
  };
}

function setPreferImperialSucceeded(
  companyId: number,
  preferImperial: boolean,
): ActionWith<CompanyPreferImperialPayload> {
  return {
    type: AdminCompaniesActionTypes.SET_PREFER_IMPERIAL_SUCCEEDED,
    payload: { companyId, preferImperial },
  };
}

function setPreferImperialFailed(companyId: number): ActionWith<number> {
  return {
    type: AdminCompaniesActionTypes.SET_PREFER_IMPERIAL_FAILED,
    payload: companyId,
  };
}

function deleteCompany(companyId: number): ActionWith<number> {
  return {
    type: AdminCompaniesActionTypes.DELETE_COMPANY,
    payload: companyId,
  };
}

function deleteCompanySucceeded(companyId: number): ActionWith<number> {
  return {
    type: AdminCompaniesActionTypes.DELETE_COMPANY_SUCCEED,
    payload: companyId,
  };
}


export const AdminCompaniesActions = {
  loadCompanies,
  loadCompaniesSucceed,
  deleteProjects,
  deleteProjectsSucceed,
  loadDatabases,
  loadDatabasesSucceed,
  attachDatabases,
  attachDatabasesSucceed,
  detachDatabase,
  detachDatabaseSucceed,
  loadEmployees,
  loadEmployeesSucceed,
  attachEmployee,
  attachEmployeeSucceed,
  detachEmployee,
  detachEmployeeSucceed,
  loadRoles,
  loadRolesSucceed,
  changeRole,
  changeRoleSucceed,
  loadProjectsEmployees,
  loadProjectsEmployeesSucceed,
  attachEmployeeToProject,
  attachEmployeeToProjectSucceed,
  detachEmployeeFromProject,
  detachEmployeeFromProjectSucceed,
  getSubscriptionPlans,
  getSubscriptionPlansFailed,
  getSubscriptionPlansSucceeded,
  setMockSubscription,
  setMockSubscriptionSucceeded,
  setMockSubscriptionFailed,
  resetMockSubscription,
  resetMockSubscriptionSucceeded,
  resetMockSubscriptionFailed,
  createEmptySubscription,
  createEmptySubscriptionSucceeded,
  createEmptySubscriptionFailed,
  loadCurrencies,
  loadCurrenciesSucceeded,
  loadCurrenciesFailed,
  setCurrency,
  setCurrencySucceeded,
  setCurrencyFailed,
  deleteCompany,
  deleteCompanySucceeded,
  setPreferImperial,
  setPreferImperialSucceeded,
  setPreferImperialFailed,
};
