import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import './database-resource-listing.scss';

import { AbilityAwareProps } from 'common/ability/with-ability-context';
import { DatabaseRouteParams } from 'routes/common/database';
import { DatabaseBaseLayout } from '../../components/database-base-layout';
import { DatabaseResourceTable } from '../../components/database-resource-table';
import { DatabaseResourceTypeNavigationList } from '../../components/database-resource-type-navigation-list';
import { ModifyDatabaseResourcePanel } from '../../components/modify-database-resource-panel';
import { DatabaseModel } from '../../interfaces/data';

interface ReduxProps {
  currentDatabase: DatabaseModel;
}

interface Props extends
  ReduxProps,
  RouteComponentProps<DatabaseRouteParams>,
  AbilityAwareProps {}

export class DatabaseResourceListingPage extends React.Component<Props> {
  public render(): JSX.Element {
    const databaseId = +this.props.match.params.dbId;

    return (
      <DatabaseBaseLayout databaseId={databaseId}>
        <div className='resources-page'>
          <div className='resources-page__left-menu'>
            <DatabaseResourceTypeNavigationList/>
          </div>
          <div className='resources-page__content'>
            <DatabaseResourceTable
              databaseId={databaseId}
              isCheckboxesVisible={false}
            />
          </div>
          <ModifyDatabaseResourcePanel/>
        </div>
      </DatabaseBaseLayout>
    );
  }
}
