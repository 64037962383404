import { Feed } from 'common/interfaces/feed';
import { ValuePayload } from 'common/request-payloads/value-payload';
import { CommonApi } from '../../../api/server';
import { FeedParams } from '../interfaces/feed';
import { UpdateUserAttributeRequestPayload } from '../interfaces/update-user-attribute-request-payload';
import { UpdateUserEmailRequestPayload } from '../interfaces/update-user-email-request-payload';
import { User, UserFeedC } from '../interfaces/user';

function getUserFeed(data: FeedParams): Promise<Feed<User>> {
  return CommonApi.getV(
    `/admin/users?skip=${data.skip}${data.search ? `&search=${data.search}` : ``}`,
    UserFeedC,
    'error in load user feed',
  );
}

function deleteUser(userId: string): Promise<void> {
  return CommonApi.delete(`/admin/users/${userId}`, 'Error while deleting user');
}

function setUserEnabled(userId: string, isEnabled: boolean): Promise<void> {
  return CommonApi.put<void, ValuePayload<boolean>>(
    `/admin/users/${userId}/enabled`,
    { value: isEnabled });
}

function updateUserAttribute(userId: string, key: string, value: string): Promise<void> {
  return CommonApi.patch<void, UpdateUserAttributeRequestPayload>(
    `/admin/users/${userId}/attributes`,
    { key, value },
    'error in updating user attribute',
  );
}

function deleteOwnedCompany(userId: string): Promise<void> {
  return CommonApi.delete(`/admin/users/${userId}/owned-company`);
}

function setNewUserEmail(userId: string, email: string): Promise<void> {
  return CommonApi.put<void, UpdateUserEmailRequestPayload>(
    `/admin/users/${userId}/email`,
    { email },
  );
}

export const AdminPeopleApi = {
  getUserFeed,
  deleteUser,
  setUserEnabled,
  updateUserAttribute,
  deleteOwnedCompany,
  setNewUserEmail,
};
