import * as React from 'react';

export const KreoIconAttention: React.FC = () => {
  return (
    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='attention_min' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M1.03645142,9.42514833 C0.187892731,10.8471996 0.844350735,12 2.49942248,12 L11.5005775,12 C13.1571128,12
            13.8040713,10.8464355 12.9466294,9.42514833 L8.51230228,2.07485167 C7.6543994,0.652800358
            6.27058302,0.653564453 5.42248028,2.07485167 L1.03645142,9.42514833 Z'
          id='Path-3'
          fill='#F6AC46'
        />
        <path
          d='M6.49544305,4 L7.49544305,4 L7.49544305,8 L6.49544305,8 L6.49544305,4 Z M6.49544305,9 L7.49544305,9
            L7.49544305,10 L6.49544305,10 L6.49544305,9 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
