import { DemoProjectType, ProjectType } from 'common/constants/project-type';
import { ProjectStatus } from 'common/enums/project-status';
import { Feed } from 'common/interfaces/feed';
import { ValuePayload } from 'common/request-payloads/value-payload';
import { objectUtils } from 'common/utils/object-utils';
import { CommonApi, CompanyResourcesApi, ProjectResourcesApi } from '../../../api/server';
import { EngineBasedPages } from '../enums/engine-based-pages';
import {
  CompanyProjectHeader,
  CompanyProjectHeaderC,
  CompanyProjectHeadersFeedC,
} from '../interfaces/company-project-header';
import { CreateDocumentModel } from '../interfaces/create-document-model';
import { DemoProject, DemoProjectsC } from '../interfaces/demo-project';
import { EngineFilterState } from '../interfaces/engine-filter-state';
import { Project } from '../interfaces/project';
import { ProjectFullViewModel } from '../interfaces/project-full-view-model';
import {
  ShortProjectHeader,
  ShortProjectHeadersC,
} from '../interfaces/short-project-header';
import { ProjectMapper } from '../mappers/project-mapper';

interface ProjectCreationParams {
  type?: ProjectType;
  folderId: number;
}

interface ProjectHeaderSearchParams {
  skip: number;
  take: number;
  search?: string;
  type?: ProjectType;
  folderId?: number;
  accessReason: string[];
}

function getCompanyProjectHeaders(
  params: ProjectHeaderSearchParams,
): Promise<Feed<CompanyProjectHeader>> {
  return CompanyResourcesApi.getV(
    `/project-headers?${objectUtils.toQueryString(params)}`,
    CompanyProjectHeadersFeedC,
  );
}

function getDemoProjects(type: DemoProjectType, imperial: boolean): Promise<DemoProject[]> {
  const query = imperial === undefined
    ? ''
    : `?imperial=${imperial}`;
  return CompanyResourcesApi.getV(`/demo-projects/${type}${query}`, DemoProjectsC);
}

function getAllProjectHeaders(type: ProjectType): Promise<ShortProjectHeader[]> {
  return CompanyResourcesApi.getV(
    `/lightweight-project-headers?${objectUtils.toQueryString({ type })}`,
    ShortProjectHeadersC);
}

function deleteProject(projectId: number): Promise<void> {
  return CompanyResourcesApi.delete(
    `/projects/${projectId}`,
    `Delete project request failed for project with id = ${projectId}`,
  );
}

function leaveProject(projectId: number): Promise<void> {
  return CompanyResourcesApi.patch(`/projects/${projectId}/unshare`, null);
}

function duplicate2dProject(targetCompany: number, projectId: number): Promise<void> {
  return CompanyResourcesApi.post(
    `/projects/${projectId}/duplicate?targetCompanyId=${targetCompany}`,
  );
}

function dump2dProject(projectId: number, quality: number): Promise<void> {
  return CompanyResourcesApi.downloadFile(`/projects/${projectId}/dump?quality=${quality}`, null);
}

function createTemplateFromProject(projectId: number): Promise<CompanyProjectHeader> {
  return CompanyResourcesApi.postV(`/projects/${projectId}/template`, null, CompanyProjectHeaderC);
}

function runClassification(): Promise<void> {
  return ProjectResourcesApi.post<void, void>(
    `/classify`,
    null,
    'Classify project request failed for project',
  );
}

function updateProjectStatus(projectId: number, status: ProjectStatus): Promise<void> {
  return CompanyResourcesApi.post<void, ValuePayload<ProjectStatus>>(
    `/projects/${projectId}/status`,
    { value: status },
    'Error while updating project status');
}

function createProject(params: ProjectCreationParams, data: CreateDocumentModel): Promise<CompanyProjectHeader> {
  return CompanyResourcesApi.postV(`/projects?${objectUtils.toQueryString(params)}`, data, CompanyProjectHeaderC);
}

function createProjectFromDemoProject(
  demoProjectId: number,
  params: ProjectCreationParams,
): Promise<CompanyProjectHeader> {
  return CompanyResourcesApi.post(
    `/demo-projects/${demoProjectId}/projects?${objectUtils.toQueryString(params)}`,
    null,
  );
}

function patchCompanyFromDemoProject(demoProjectId: number): Promise<void> {
  return CompanyResourcesApi.patch(`/demo-projects/${demoProjectId}`, null);
}

function getProjectInfo(projectId: number): Promise<Project> {
  return CompanyResourcesApi.get<ProjectFullViewModel>(
    `/projects/${projectId}`,
    'get project info error',
  ).then(vm => ProjectMapper.mapFromProjectFullViewModel(vm));
}


function changeProjectName(projectId: number, newName: string): Promise<void> {
  return CompanyResourcesApi.put<void, ValuePayload<string>>(
    `/projects/${projectId}/name`,
    { value: newName },
    'error in updating project name');
}

function moveProjectToFolder(projectId: number, folderId: number): Promise<void> {
  return CompanyResourcesApi.put<void, ValuePayload<number>>(
    `/projects/${projectId}/folder`,
    { value: folderId },
    'error in project to folder movement');
}

function loadUpdateProjectInformation(projectId: number): Promise<Project> {
  return CommonApi.get(
    `/bim/document/${projectId}`,
    'error in getting page doc',
  );
}

function buildBaseProjectUrl(projectId: number | string): string {
  return `/api${CompanyResourcesApi.getBaseUrl()}/projects/${projectId}`;
}

function buildProjectScreenshotUrl(projectId: number | string): string {
  return `${buildBaseProjectUrl(projectId)}/screen`;
}

function buildDemoProjectScreenshotUrl(id: number): string {
  return `/api${CompanyResourcesApi.getBaseUrl()}/demo-projects/${id}/screen`;
}


function buildProjectWorkPackageScreenshotUrl(
  projectId: number | string,
  scenarioId: number,
  workPackageId: number,
): string {
  return `${buildBaseProjectUrl(projectId)}/scenarios/${scenarioId}/workpackages/${workPackageId}/screen`;
}

function getModelBrowserProperties(): Promise<any> {
  return ProjectResourcesApi.getBinary(`/model-browser-properties`);
}


function updateEngineFilterState(
  projectId: number,
  state: EngineFilterState,
  engineBasedPage: EngineBasedPages,
): void {
  CompanyResourcesApi.put(`/projects/${projectId}/engine-filter/${engineBasedPage}`, state);
}

function getEngineFilterState(
  projectId: number,
  engineBasedPage: EngineBasedPages,
): Promise<EngineFilterState> {
  return CompanyResourcesApi.get(`/projects/${projectId}/engine-filter/${engineBasedPage}`);
}

function failureProjectIdSend(projectId: number): Promise<void> {
  return CompanyResourcesApi.post(`/projects/${projectId}/notify-failure`, projectId);
}

const demoModelUrl = '/static/files/demo-kreo-plan.bim';

export const ProjectsApi = {
  deleteProject,
  leaveProject,
  runClassification,
  updateProjectStatus,
  createProject,
  createProjectFromDemoProject,
  patchCompanyFromDemoProject,
  getProjectInfo,
  changeProjectName,
  moveProjectToFolder,
  loadUpdateProjectInformation,
  buildProjectScreenshotUrl,
  buildProjectWorkPackageScreenshotUrl,
  buildDemoProjectScreenshotUrl,
  getModelBrowserProperties,
  demoModelUrl,
  getCompanyProjectHeaders,
  getDemoProjects,
  getAllProjectHeaders,
  updateEngineFilterState,
  getEngineFilterState,
  failureProjectIdSend,
  duplicate2dProject,
  dump2dProject,
  createTemplateFromProject,
};
