import autobind from 'autobind-decorator';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsPolygonGeometry } from 'common/components/drawings/interfaces';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { EngineObject } from '../../../common';
import {
  DrawingsGeometryEntityPolygon,
  DrawingsGeometryEntityPolygonConfig,
} from '../../../drawings-geometry-entities';
import { ModifiableInstanceType } from '../../drawings-geometry-modify';
import { GeometryFactory } from './geometry-factory';

export class PolygonFactory extends EngineObject<Partial<DrawingsGeometryEntityPolygonConfig>>
  implements GeometryFactory<DrawingsPolygonGeometry, DrawingsGeometryEntityPolygonConfig>  {

  @autobind
  public render(
    instanceId: string,
    geometry: DrawingsPolygonGeometry,
    color: DrawingsPaperColorInfo,
    overrodeProps: Partial<DrawingsGeometryEntityPolygonConfig>,
  ): ModifiableInstanceType {
    const config = {
      ...this._config,
      id: instanceId,
      geometry,
      color,
      instanceType: DrawingsInstanceType.Polygon,
      ...overrodeProps,
    };

    return new DrawingsGeometryEntityPolygon(config as DrawingsGeometryEntityPolygonConfig);
  }
}

