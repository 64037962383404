import { AccountApi } from '../../units/account/api';

interface Person {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  hasAvatar?: boolean;
}

function getPersonName(person: Person): string {
  if (!person) {
    return 'undefined undefined';
  }
  if (person.firstName || person.lastName) {
    return `${person.firstName} ${person.lastName}`;
  }
  return person.email;
}

function getPersonVisualData(person: Person): { userName: string, avatarLink: string } {
  return {
    userName: getPersonName(person),
    avatarLink: person ? AccountApi.getAvatarLink(person) : null,
  };
}

export const PersonUtils = {
  getPersonVisualData,
  getPersonName,
};
