import { AnyAction } from 'redux';

import { RequestStatus } from 'common/enums/request-status';
import { AccountActionTypes } from '../units/account/actions/types';
import { AdminDemoProjectsState } from '../units/admin/pages/demo-projects/interfaces';
import { adminDemoProjectsReducerMethods } from '../units/admin/reducers/demo-projects';

export const INITIAL_STATE: AdminDemoProjectsState = {
  demoProjects: [],
  demoProjectsRequestStatus: RequestStatus.NotRequested,
};

export default function adminDemoProjects(
  state: AdminDemoProjectsState = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): AdminDemoProjectsState {
  if (action.type in adminDemoProjectsReducerMethods) {
    return adminDemoProjectsReducerMethods[action.type](state, action.payload);
  } else {
    if (
      action.type === AccountActionTypes.LOG_OUT
    ) {
      return INITIAL_STATE;
    }
    return state;
  }
}
