import * as React from 'react';

import './material-tile-menu-group.scss';

export class MaterialTileMenuGroup extends React.Component {
  public render(): React.ReactNode {
    return (
      <div className='material-tile-menu-group'>
        {this.props.children}
      </div>
    );
  }
}
