

function createCanvas(width: number, height: number, canvasMultiplier: number): HTMLCanvasElement {
  const result = document.createElement('canvas');
  const resultCtx = result.getContext('2d');
  result.width = width;
  result.height = height;
  result.style.width = `${width / canvasMultiplier}px`;
  result.style.height = `${height / canvasMultiplier}px`;
  result.style.backgroundColor = 'white';
  resultCtx.fillStyle = 'white';
  return result;
}


export const Util = {
  createCanvas,
};
