import { objectUtils } from 'common/utils/object-utils';
import {
  CEActivityAssignmentResponse,
  CEActivityAssignmentTreeNodeActivitySort,
  CEActivityAssignmentTreeNodeActivitySortC,
  RevisionState,
  RevisionStateC,
  UpdateActivityAssignmentActivity,
} from 'unit-cost-estimate/interfaces';
import { RevisionResourcesApi } from '../../../api/server';


function calculateActivityAssignment(databaseIds: number[], usePreviousLaunch: boolean): Promise<RevisionState> {
  const queryParams = objectUtils.toQueryString({ dataBaseId: databaseIds, usePreviousLaunch });
  return RevisionResourcesApi.postV(`/activity-assignment?${queryParams}`, null, RevisionStateC);
}

function patchActivityAssignment(selectedNodeId: number, databaseIds: number[]): Promise<RevisionState> {
  const queryParams = objectUtils.toQueryString({ dataBaseId: databaseIds });
  const form = [ selectedNodeId ];
  return RevisionResourcesApi.patchV(`/activity-assignment?${queryParams}`, form, RevisionStateC);
}

function getWork(workId: number): Promise<CEActivityAssignmentTreeNodeActivitySort> {
  return RevisionResourcesApi.getV(`/activity-assignment/works/${workId}`, CEActivityAssignmentTreeNodeActivitySortC);
}

function saveWork(activity: UpdateActivityAssignmentActivity): Promise<void> {
  return RevisionResourcesApi.put(`/activity-assignment`, activity);
}

function getData(): Promise<CEActivityAssignmentResponse> {
  return RevisionResourcesApi.get(`/activity-assignment`);
}

function approveActivityAssignment(): Promise<RevisionState> {
  return RevisionResourcesApi.postV(`/activity-assignment/approve`, null, RevisionStateC);
}

function disapproveActivityAssignment(): Promise<RevisionState> {
  return RevisionResourcesApi.postV(`/activity-assignment/disapprove`, null, RevisionStateC);
}


export const CEActivityAssignmentApi = {
  calculateActivityAssignment,
  patchActivityAssignment,
  approveActivityAssignment,
  disapproveActivityAssignment,
  saveWork,
  getWork,
  getData,
};


