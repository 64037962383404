import styled from 'styled-components';

const ExampleList = styled.div`
  width: 300px;
  height: 385px;
  border-radius: 30px;
  padding: 10px;
  margin-right: 80px;
  background-color: ${p => p.theme.color.background};
  > div:first-child {
    inset: -1px;
  }
`;

const ExampleItem = styled.div`
  display: flex;
  border-radius: 20px;
  border: 1px solid ${p => p.theme.color.background};
  background-color: ${p => p.theme.color.backgroundRush};
  box-shadow: ${p => p.theme.shadow.s};
  padding: 20px;
  height: 115px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ExampleImage = styled.div<{ image: string }>`
  width: 75px;
  height: 75px;
  background-color: ${p => p.theme.color.background};
  background-image: ${p => `url(${p.image})`};
  background-position: center;
  background-size:  cover;
  border-radius: 10px;
  margin-right: 20px;
  flex-shrink: 0;
`;

const ExampleName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const Styled = {
  ExampleList,
  ExampleItem,
  ExampleImage,
  ExampleName,
};
