import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { DemoProjectType } from 'common/constants/project-type';
import { State } from 'common/interfaces/state';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { DemoProject } from 'unit-projects/interfaces/demo-project';

export const useDemoProject = (): DemoProject[] => {
  const type = DemoProjectType.Project2d;
  const canUseDemoProject = useAbility(Operation.Create, Subject.Project);
  const isShow = useAbility(Operation.Manage, Subject.DemoProject);
  const dispatch = useDispatch();
  const demoProjects = useSelector<State, DemoProject[]>(s => s.projects.demoProjects[type]);
  const isImperial = useSelector<State, boolean>(s => s.account.settings.isImperial);

  useEffect(() => {
    if (canUseDemoProject && isShow) {
      dispatch(ProjectsActions.fetchDemoProjectsRequest(DemoProjectType.Project2d, isImperial));
    }
  }, [isImperial]);


  return demoProjects;
};
