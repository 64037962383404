import styled, { css } from 'styled-components';
import {
  ICON_ADD_FOLDER,
  ICON_ADD_PLUS,
  ICON_DELETE,
  ICON_DRAG,
  ICON_EDIT,
  ICON_ROW_BOTTOM_SMALL,
  ICON_ROW_RIGHT_SMALL,
} from 'common/UIKit/backgrounds-svg/background-svg';

function createGroupIndent(size: number): any {
  let styles = '';

  for (let i = 0; i < 10; i += 1) {
    styles += `
     .ag-row-group-indent-${i} {
       padding-left: ${i * size}px;
     }`;
  }

  return css`${styles}`;
}

const GROUP_INDENT_SIZE = 35;

const Container = styled.div`
  width: inherit;
  color: ${p => p.theme.color.white};

  #pdfTable {
    height: 100%;
    border-top: 1px solid ${p => p.theme.color.background};

    & ::-webkit-scrollbar-thumb {
      background: ${p => p.theme.color.disabled};
    }

    & ::-webkit-scrollbar-thumb:hover {
      background: ${p => p.theme.color.gray};
    }

    .ag-header {
      display: none;
    }

    ${createGroupIndent(GROUP_INDENT_SIZE)};

    .ag-row {
      border-bottom: 1px solid ${p => p.theme.color.background};

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 5px;
        background-color: ${p => p.theme.color.turquoise};
        border-radius: 1px 4px 4px 1px;
        opacity: 0;
        transition: all 0.175s linear 0s;
      }

      &-hover {
        .ag-cell:not(.ag-cell--insert-into) .ag-group-value:hover {
          .drawings-pdf-browser-item-ag-grid .tree-table-edit-title:not(.tree-table-edit-title--edit) {
            width: calc(100% - 30px);

            &::after {
              right: 30px;
            }

            .tree-table-edit-title__edit-icon {
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        .ag-cell:not(.ag-cell--insert-into).ag-cell--group .ag-group-value:hover {
          .tree-table-edit-title:not(.tree-table-edit-title--edit) {
            max-width: calc(100% - 90px);

            &::after {
              right: 0;
            }

          }
        }
        .ag-cell.ag-cell--group .ag-group-value:hover {
          .drawings-pdf-browser-item-ag-grid__count-child {
            display: none;
          }
        }
      }

      &-selected {
        &::after {
            opacity: 1;
        }

        .ag-cell-wrapper .ag-cell-value {

          .drawings-pdf-browser-item-ag-grid {
            .tree-table-edit-title {
              color: ${p => p.theme.color.turquoise};
            }

            &__count-page {
                color: ${p => p.theme.color.gray};
            }
          }
        }
      }

      &-dragging {
        box-shadow: 0 0 0 2px inset ${p => p.theme.color.turquoise};
      }
    }

    .ag-drag-handle {
      padding-left: 20px;
      margin-right: 10px;

      &:hover .ag-icon.ag-icon-grip::before {
        background-image: ${p => ICON_DRAG(p.theme.color.turquoise)};
      }
    }

    .ag-icon {
      &::before {
        content: '';
        display: block;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.ag-icon-grip::before {
        width: 20px;
        height: 20px;
        background-image: ${p => ICON_DRAG(p.theme.color.gray)};
      }

      &.ag-icon-tree-closed::before {
        cursor: pointer;
        width: 20px;
        height: 20px;
        background-image: ${p => ICON_ROW_RIGHT_SMALL(p.theme.color.gray)};
      }

      &.ag-icon-tree-open::before {
        cursor: pointer;
        width: 20px;
        height: 20px;
        background-image: ${p => ICON_ROW_BOTTOM_SMALL(p.theme.color.gray)};
      }
    }

    .ag-cell {
      border: 0;
      outline: 0;

      &-value {
        position: relative;
        flex-grow: 1;
        height: 100%;
      }

      &--insert-into {
        background-color: ${props => props.theme.color.pale};

        &.ag-cell--group .ag-group-value .drawings-pdf-browser-item-ag-grid {
          &__eActions {
            background-color: ${props => props.theme.color.pale};
          }

          .tree-table-edit-title::after {
            background: linear-gradient(
              270deg,
              ${p => p.theme.color.pale} 0%,
              ${p => p.theme.color.pale} 30px,
              ${p => p.theme.color.pale}00 100%) padding-box;
          }
        }
      }

      .ag-group {
        &-expanded {
          margin: auto 10px auto 0;
          padding-left: 20px;

          &:hover .ag-icon.ag-icon-tree-open::before {
            background-image: ${p => ICON_ROW_BOTTOM_SMALL(p.theme.color.turquoise)};
          }
        }
        &-contracted {
          margin: auto 10px auto 0;
          padding-left: 20px;

          &:hover .ag-icon.ag-icon-tree-closed::before {
            background-image: ${p => ICON_ROW_RIGHT_SMALL(p.theme.color.turquoise)};
          }
        }
        &-value {
          width: 100%;
          height: 100%;
          cursor: pointer;

          .drawings-pdf-browser-item-ag-grid {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 40px);
            height: 100%;
            padding-left: 20px;

            &:hover {
              .tree-table-edit-title {
                &:not(.tree-table-edit-title--edit)::after {
                  display: block;
                }
                &__edit-icon {
                  opacity: 1;
                  transition: opacity 0.175s linear 0s;
                }
              }

              .drawings-pdf-browser-item-ag-grid {
                &__eActions {
                  opacity: 1;
                  width: auto;
                  transition: opacity 0.175s linear 0s;

                  &-icon {
                    display: block;
                  }
                }
              }
            }

            &__count-child {
              background-color: ${props => props.theme.color.turquoise};
              box-sizing: border-box;
              height: 20px;
              min-width: 20px;
              padding: 0 4px;
              font-size: 12px;
              line-height: 20px;
              text-align: center;
              border-radius: 5px;
            }

            .tree-table-edit-title {
              height: auto;
              flex-grow: unset;
              color: ${p => p.theme.color.mainFont};
              font-size: 14px;
              line-height: 15px;
              transition: all 0.175s linear 0s;

              &.tree-table-edit-title--edit {
                color: ${props => props.theme.color.mainFont};

                input {
                  padding: 5px;
                  border-radius: 5px;
                }
              }

              &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                width: 70px;
                height: 100%;
                display: none;
                background: linear-gradient(
                  270deg,
                  ${p => p.theme.color.backgroundRush} 0%,
                  ${p => p.theme.color.backgroundRush} 30px,
                  ${p => p.theme.color.backgroundRush}00 100%) padding-box;
              }

              &__value {
                box-sizing: border-box;
                padding: 5px 0;
                transition: all 0.175s linear 0s;
              }

              &__input {
                border: 0;
                outline: none;
                height: auto;
                padding: 5px 0;
                line-height: inherit;
                background-color: ${p => p.theme.color.background};
              }

              &__edit-icon {
                display: block;
                flex-shrink: 0;
                width: 20px;
                height: 20px;
                background-size: 20px;
                position: absolute;
                right: 30px;
                top: 0;
                z-index: 2;
                margin: 0;
                margin-right: 10px;
                background: ${p => ICON_EDIT(p.theme.color.gray)} center no-repeat;
                opacity: 0;
                transition: none;
                &:hover {
                  background: ${p => ICON_EDIT(p.theme.color.turquoise)} center no-repeat;
                }
              }

              &--edit::after,
              &--edit~.drawings-pdf-browser-item-ag-grid__eActions,
              &--edit~.drawings-pdf-browser-item-ag-grid__count-child {
                display: none;
              }
            }

            .kreo-icon-btn{
              margin-top: auto;
              margin-bottom: auto;
              background-repeat: no-repeat;
              background-position: center;
              width: 20px;
              height: 20px;
              min-width: 20px;
              min-height: 20px;
              border-radius: 0;

              &:hover {
                background-color: transparent;
              }
            }

            &__eActions {
              display: block;
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              opacity: 0;

              &-icon {
                display: none;
                width: 20px;
                height: 20px;
                background-size: 20px;
                background-repeat: no-repeat;
                transition: all 0.175s linear 0s;
              }
            }

            &__eDeleteIcon {
              background-image: ${p => ICON_DELETE(p.theme.color.gray)};
              &:hover {
                background-image: ${p => ICON_DELETE(p.theme.color.turquoise)};
              }
            }

            &__eAddFolderIcon {
              background-image: ${p => ICON_ADD_FOLDER(p.theme.color.gray)};
              &:hover {
                background-image: ${p => ICON_ADD_FOLDER(p.theme.color.turquoise)};
              }
            }

            &__eLoadFileIcon {
              background-image: ${p => ICON_ADD_PLUS(p.theme.color.gray)};
              &:hover {
                background-image: ${p => ICON_ADD_PLUS(p.theme.color.turquoise)};
              }
            }

            &__count-page {
              font-size: 12px;
              line-height: 15px;
              color: ${p => p.theme.color.gray};
              transition: all 0.175s linear 0s;
              &--failed {
                color: ${p => p.theme.color.red}
              }
            }
          }
        }
      }

      &--group {
        .ag-group-value .drawings-pdf-browser-item-ag-grid {
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .tree-table-edit-title {
            position: relative;
            width: 100%;
            max-width: calc(100% - 30px);
            transition: none;
            flex-grow: 1;

            &__edit-icon {
              right: 0;
              top: unset;
              transform: none;
            }
          }

          &__eActions {
            position: relative;
            display: flex;
            width: 0;
            gap: 10px;
            top: 0;
            bottom: 0;
            right: 0;
            transform: none;
            background-color: ${p => p.theme.color.backgroundRush};
          }
        }
      }
    }

    .ag-body-viewport {
      overflow: overlay;
    }
  }
`;


export const Styled = {
  Container,
};
