import styled from 'styled-components';

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  button {
    padding: 0px;
  }
`;

export const Styled = {
  Buttons,
};
