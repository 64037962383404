import { Icons, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  numberStep: number;
  isValid: boolean;
}

export const FillStepScaleSubscription: React.FC<Props> = ({ numberStep, isValid }: Props) => {
  return (
    <Styled.Container>
      <Styled.NumberContainer isValid={isValid}>
        <Text color={isValid ? 'green' : 'fontSize'}>
          {numberStep}
        </Text>
      </Styled.NumberContainer>
      <Styled.StatusIconContainer isValid={isValid}>
        {isValid ? <Icons.ApplyS/> : <Icons.CloseSmall/>}
      </Styled.StatusIconContainer>
    </Styled.Container>
  );
};
