import { GridApi } from 'ag-grid-community';
import { useCallback } from 'react';

export function useQuickSearch(gridApi: GridApi): [(value: string) => void] {
  const handleChangeSearchValue = useCallback((value) => {
    gridApi.setQuickFilter(value);
  }, [gridApi]);

  return [handleChangeSearchValue];
}
