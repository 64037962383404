import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants/drawing-canvas-constants';
import { ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { ConstantFunctions } from 'common/constants/functions';
import { DrawingsCursorTypeHelper } from '../../../drawings-helpers';
import { PaperMouseEventUtils } from '../../../drawings-helpers/paper-mouse-event-utils';
import { DrawingsMouseEventHandler } from '../../base';

export interface DrawingsGeometryBoundingPointConfig {
  observableRenderContext: ContextObserverWithPrevious<DrawingsRenderParams>;
  onStartDrag: DrawingsMouseEventHandler;
  layer: paper.Layer;
  position: paper.Point;
  cursorHelper: DrawingsCursorTypeHelper;
}

export class DrawingsGeometryBoundingPoint {
  private _config: DrawingsGeometryBoundingPointConfig;

  private _rect: paper.Path;
  private _group: paper.Group = new paper.Group();

  constructor(config: DrawingsGeometryBoundingPointConfig) {
    this._config = config;
    const prevZoom = this._config.observableRenderContext.getPrevContext().zoom;
    this.renderRect();
    this.applyZoom(1 / prevZoom);
    config.observableRenderContext.subscribe(this.updateZoom);
  }

  public destroy(): void {
    this._rect.remove();
    this._config.observableRenderContext.unsubscribe(this.updateZoom);
  }

  public set visibility(value: boolean) {
    this._group.visible = value;
  }

  public set position(value: paper.Point) {
    this._config.position = value;
    this._rect.position = value;
  }

  public get position(): paper.Point {
    return this._rect.position;
  }

  @autobind
  private updateZoom({ zoom }: DrawingsRenderParams): void {
    const prevZoom = this._config.observableRenderContext.getPrevContext().zoom;
    this.applyZoom(prevZoom / zoom);
  }

  private applyZoom(zoom: number): void {
    this._rect.scale(zoom, this._config.position);
    this._rect.strokeWidth = this._rect.strokeWidth * zoom;
    this.addEventHandlers();
  }

  private renderRect(): void {
    const size = new paper.Size(10, 10);
    const radius = new paper.Size(3, 3);
    const rectangle = new paper.Rectangle(this._config.position, size);
    this._rect = new paper.Path(new paper.Path.Rectangle(rectangle, radius).segments);
    this._rect.add(this._rect.firstSegment);
    this._rect.position = this._config.position;
    this._rect.strokeWidth = 2;
    this._rect.fillColor = DrawingsCanvasColors.pointColor;
    this._rect.strokeColor = DrawingsCanvasColors.white;
    this._rect.addTo(this._group);
  }

  private addEventHandlers(): void {
    this._rect.onMouseDown = this.onMouseDown;
    this._rect.onMouseEnter = this.onMouseEnter;
    this._rect.onMouseEnter = this.onMouseLeave;
  }

  @autobind
  private onMouseDown(e: PaperMouseEvent): void {
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      ConstantFunctions.stopEvent(e);
      e.point = this._rect.position;
      this._config.onStartDrag(undefined, e);
    }
  }

  @autobind
  private onMouseEnter(e: PaperMouseEvent): void {
    ConstantFunctions.stopEvent(e);
    this._config.cursorHelper.hoveredSelected = true;
  }

  @autobind
  private onMouseLeave(): void {
    this._config.cursorHelper.hovered = false;
  }
}
