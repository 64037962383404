import { AnyAction } from 'redux';
import { RevisionsReducerMethods } from 'unit-cost-estimate/reducers/revisions';

import { AccountActionTypes } from '../units/account/actions/types';
import { PROJECT_INITIAL_STATE } from '../units/projects/initial-state';
import { ProjectReduxState } from '../units/projects/interfaces/project-redux-state';
import { projectReducerMethods } from '../units/projects/reducer';

export default function(
  state: ProjectReduxState = PROJECT_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null }): ProjectReduxState {
  if (action.type in projectReducerMethods) {
    return projectReducerMethods[action.type](state, action.payload);
  } else if (action.type === AccountActionTypes.LOG_OUT) {
    return PROJECT_INITIAL_STATE;
  } else if (action.type in RevisionsReducerMethods) {
    return RevisionsReducerMethods[action.type](state, action.payload);
  }  else {
    return state;
  }
}
