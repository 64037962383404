import * as paper from 'paper';

import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import {
  ConvertedPolygon,
  DrawingsPolygonConverterResult,
} from 'common/components/drawings/interfaces/drawings-converter-result';
import {
  DrawingsAllowedPathType,
} from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import {
  DrawingsGeometryConverters,
  GeometryConfig,
} from 'common/components/drawings/utils/drawings-geometry-converters';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { BaseOffsetEntity } from './base-offset-entity';
import { DrawingsOffsetUtils } from './offset-utils';

export class PolygonOffsetEntity extends BaseOffsetEntity {
  protected _offsetPath: DrawingsAllowedPathType[];

  public override updateOffset(offset: number, stroke: boolean): boolean {
    super.updateOffset(offset, stroke);
    const basePathClone = this._config.basePath.clone() as paper.Path;
    basePathClone.closed = true;
    let pathes: DrawingsAllowedPathType[];
    if (stroke) {
      const offsetAbs = Math.abs(offset);
      pathes = DrawingsOffsetUtils.offsetClosedPathStroke(
        basePathClone,
        offsetAbs,
        offsetAbs,
      );
    } else {
      pathes = [DrawingsOffsetUtils.offsetClosedPath(basePathClone, offset, Math.abs(offset))];
    }
    basePathClone.remove();
    for (const path of pathes) {
      if (!this.validateOffsetPath(path)) {
        return false;
      }
    }
    this.saveOffsetPath(pathes);
    this._offset = offset;
    return true;
  }


  public convertToGeometry(): DrawingsPolygonConverterResult {
    let newPoints = {};
    const geometriesIterators = [];
    const flatIter = function*(
      iterArray: Array<IterableIterator<ConvertedPolygon>>,
    ): IterableIterator<ConvertedPolygon> {
      for (const iter of iterArray) {
        for (const polygonGeometry of iter) {
          yield polygonGeometry;
        }
      }
    };

    const params: GeometryConfig = {
      color: this._config.color.stroke.toCSS(true),
      strokeWidth: this._config.strokeWidth,
      strokeStyle: this._config.strokeStyle,
    };

    if (this._config.height) {
      params.height = this._config.height;
    }

    for (let path of this._offsetPath.slice()) {
      if (DrawingsPaperUtils.isCompoundPolygonPath(path)) {
        path.children.sort((a, b) => b.area - a.area);
        let externalContour = new paper.Path(path.firstChild.segments) as DrawingsAllowedPathType;
        for (let i = 1; i < path.children.length; i++) {
          const child = new paper.Path(path.children[i].segments);
          const contourUpdate = externalContour.subtract(child) as DrawingsAllowedPathType;
          if (externalContour.intersects(child)) {
            this._offsetPath.push(child.subtract(externalContour) as DrawingsAllowedPathType);
          }
          externalContour.remove();
          externalContour = contourUpdate;
          child.remove();
        }
        path = externalContour;
      }
      const result = DrawingsGeometryConverters.convertPathToPolygons(
        path,
        {},
        params,
      );
      if (result) {
        geometriesIterators.push(result.geometriesIterator);
        newPoints = { ...result.newPoints, ...newPoints };
      }
    }

    return { newPoints, geometriesIterator: flatIter(geometriesIterators) };
  }

  public getOffsetPath(): DrawingsAllowedPathType[] {
    return this._offsetPath;
  }

  public updateOffsetRenderParameters({ zoom }: DrawingsRenderParams): void {
    if (this._offsetPath) {
      for (const path of this._offsetPath) {
        const { strokeStyle, strokeWidth } = this._config.instance.geometry;
        path.strokeWidth = strokeWidth / zoom;
        path.dashArray = DrawingsCanvasUtils.scaleStroke({ strokeStyle, strokeWidth }, zoom);
      }
    }
  }

  public removeOffsetPath(): void {
    if (this._offsetPath) {
      this._offsetPath.forEach(x => x.remove());
    }
  }

  public findEdgeOfPoint(point: paper.Point): [paper.Point, paper.Point] {
    return DrawingsOffsetUtils.findEdgeOfPoint(this._config.basePath, point);
  }

  private saveOffsetPath(path: DrawingsAllowedPathType[]): void {
    if (this._offsetPath) {
      for (const oldPath of this._offsetPath) {
        oldPath.remove();
      }
      this._offsetPath = null;
    }
    this._offsetPath = path;
    for (const p of path) {
      this.renderPath(p);
    }
  }
}
