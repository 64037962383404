export interface Group {
  parentId?: string;
}

export const getGroupPath = (
  groupId: string,
  groupMap: Record<string, Group>,
  totalPath: string[],
  groupPathMap: Record<string, string[]>,
): string[] => {
  if (!totalPath) {
    return [];
  }

  const path = groupPathMap[groupId];
  if (path) {
    return path.concat(totalPath);
  }

  const group = groupMap ? groupMap[groupId] : undefined;
  if (!group || !totalPath) {
    return totalPath;
  }

  totalPath.unshift(groupId);

  if (!group.parentId) {
    return totalPath;
  }

  const resultPath = getGroupPath(group.parentId, groupMap, totalPath, groupPathMap);
  const indexOf = resultPath.indexOf(groupId);
  groupPathMap[groupId] = resultPath.slice(0, indexOf + 1);
  return resultPath;
};
