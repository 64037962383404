import { IconButton, ElementTooltip, Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RenderIf } from 'common/components/render-if';
import { ControlNames } from 'common/constants/control-names';
import { KreoDialogActions } from 'common/UIKit';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { DrawingsAnnotationActions } from '../../actions/creators/annotation';
import { DrawingsGeometryRenameInstance } from '../../actions/payloads/annotation';
import { DrawingDialogs } from '../../constants/drawing-dialogs';
import { FilterData } from '../../interfaces/drawing-filters';
import { SortData } from '../../interfaces/drawing-measurement-sort';
import { DrawingsAnnotationLegendFilter } from '../drawings-annotation-legend-filter';
import { DrawingsAnnotationLegendMeasurementsMenu } from '../drawings-annotation-legend-measurements-menu';
import { DrawingsAnnotationLegendRenameTool } from '../drawings-annotation-legend-rename-tool';
import { DrawingsAnnotationLegendSort } from '../drawings-annotation-legend-sort';
import { SelectFilterPages } from './select-filter-pages/select-filter-pages';
import { Styled } from './styled';

interface OwnProps {
  hasSelectedGroups: boolean;
  canEditMeasurement: boolean;
  selectedMeasurementSort: SortData;
  geometryCount: number;
  isOpenGroups: boolean;
  hasSelectedGroupsAndElements: boolean;
  isOpenFilterPages: boolean;
  toggleQuickSearch: () => void;
  setQuickSearchValue: (value: string) => void;
  deleteEmptyGroups: () => void;
  invertSelection: () => void;
  collapseAllGroups: () => void;
  expandAllGroups: () => void;
  createEmptyGroup: () => void;
  isFiltersChanged: (prevData: FilterData, currentData: FilterData) => boolean;
  filterGeometry: () => void;
  getFilterPagesPosition: (isOpen: boolean) => void;
  duplicateGroups: () => void;
}

interface DispatchProps {
  moveTo: () => void;
  renameElements: (instances: DrawingsGeometryRenameInstance[]) => void;
  setSortData: (sortData: SortData) => void;
}

interface Props extends OwnProps, DispatchProps {
}

const HeaderComponent: React.FC<Props> = ({
  canEditMeasurement,
  selectedMeasurementSort,
  geometryCount,
  isOpenGroups,
  hasSelectedGroupsAndElements,
  hasSelectedGroups,
  isOpenFilterPages,
  toggleQuickSearch,
  setQuickSearchValue,
  deleteEmptyGroups,
  invertSelection,
  collapseAllGroups,
  expandAllGroups,
  setSortData,
  createEmptyGroup,
  moveTo,
  isFiltersChanged,
  filterGeometry,
  getFilterPagesPosition,
  duplicateGroups,
}) => {
  const createGroupHandler = useCallback(() => createEmptyGroup(), [createEmptyGroup]);
  return (
    <>
      <Styled.Header>
        <Styled.MenuButton>
          <DrawingsAnnotationLegendMeasurementsMenu
            canEditMeasurement={canEditMeasurement}
            toggleQuickSearch={toggleQuickSearch}
            setQuickSearchValue={setQuickSearchValue}
            deleteGroups={deleteEmptyGroups}
            invertSelection={invertSelection}
          />
        </Styled.MenuButton>
        <Styled.HeaderButtonsLeft>
          <ElementTooltip text="Add new Group" position="top" controlName={ControlNames.addNewGroup}>
            <IconButton
              Icon={Icons.NewGroup2d}
              onClick={createGroupHandler}
              width={20}
              height={20}
              mood={canEditMeasurement ? 'default' : 'disabled'}
              controlName={ControlNames.addNewGroup}
            />
          </ElementTooltip>
          <ElementTooltip text="Duplicate Groups" position="top">
            <IconButton
              Icon={Icons.DuplicateGroups}
              onClick={duplicateGroups}
              width={20}
              height={20}
              mood={canEditMeasurement && hasSelectedGroups ? 'default' : 'disabled'}
            />
          </ElementTooltip>
          <ElementTooltip text="Move To" position="top">
            <IconButton
              Icon={Icons.MoveToGroup_1}
              onClick={moveTo}
              width={20}
              height={20}
              mood={hasSelectedGroupsAndElements && canEditMeasurement ? 'default' : 'disabled'}
            />
          </ElementTooltip>
          <RenderIf condition={isOpenGroups}>
            <ElementTooltip text="Collapse All" position="top">
              <IconButton
                Icon={Icons.CollapseFolders}
                onClick={collapseAllGroups}
                iconHeight={20}
                width={20}
                height={20}
              />
            </ElementTooltip>
          </RenderIf>
          <RenderIf condition={!isOpenGroups}>
            <ElementTooltip text="Expand All" position="top">
              <IconButton Icon={Icons.ExpandFolders} onClick={expandAllGroups} iconHeight={20} width={20} height={20} />
            </ElementTooltip>
          </RenderIf>
          <DrawingsAnnotationLegendSort
            sortDataState={selectedMeasurementSort}
            changeSortData={setSortData}
          />
          <DrawingsAnnotationLegendRenameTool
            disabled={!canEditMeasurement}
          />
          <DrawingsAnnotationLegendFilter
            geometryCount={geometryCount}
            isFiltersChanged={isFiltersChanged}
          />
        </Styled.HeaderButtonsLeft>
      </Styled.Header>
      <SelectFilterPages
        filterGeometry={filterGeometry}
        getDropdownMenuPosition={getFilterPagesPosition}
        isOpen={isOpenFilterPages}
      />
    </>
  );
};


function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    moveTo: () => dispatch(KreoDialogActions.openDialog(DrawingDialogs.MOVE_TO_DIALOG)),
    renameElements: (instances) => dispatch(DrawingsAnnotationActions.renameInstance(instances)),
    setSortData: (sortData) => dispatch(PersistedStorageActions.setSortData(sortData)),
  };
}

export const Header = connect(null, mapDispatchToProps)(HeaderComponent);
