import * as t from 'io-ts';

import { ActivityAssignmentActivityDataC } from './activity-assignmnet-activty-data';

export const ActivityAssignmentActivityC = t.exact(
  t.intersection([
    t.type({
      id: t.number,
      name: t.string,
      data: ActivityAssignmentActivityDataC,
      idsStart: t.number,
      idsEnd: t.number,
    }),
    t.partial({
      predictedNrm1: t.string,
      predictedNrm2: t.string,
      uniProduct: t.string,
      uniSystem: t.string,
    }),
  ]),
  'ActivityAssignmentActivity');

export type ActivityAssignmentActivity = t.TypeOf<typeof ActivityAssignmentActivityC>;
