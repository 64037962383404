import styled from 'styled-components';

const Container = styled.div<{ active: boolean }>`
  transition: ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  svg {
    fill: ${p => p.theme.color[p.active ? 'turquoise' : 'gray']};
  }
`;

export const Styled = {
  Container,
};
