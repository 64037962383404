import { PrecompiledFormula, PrecompiledFormulaApi } from './interfaces';

export function getDefaultPrecompiledFormulaApi(): PrecompiledFormulaApi {
  const formulas: Record<string, PrecompiledFormula> = {};

  return {
    getFormula: (formulaValue: string) => formulas[formulaValue],
    setFormula: (formulaValue: string, formula: PrecompiledFormula) => {
      formulas[formulaValue] = formula;
    },
  };
}
