import { TinyText } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import { KreoDialogActions } from 'common/UIKit';
import { FORMULA_DIALOG_NAME, FormulaDialogData } from '../../formula-dialog';
import { FormulaFieldWrapper } from '../../formula-field-wrapper';
import { PropertyFormData } from '../interfaces';
import { GroupsDropdown, UnitInput, PropertyNameInput } from './fields';
import { validatePropertyNames, validatePropertyUnit } from './helper';
import { Styled } from './styled';


interface DispatchProps {
  openFormulaDialog: (formulaDialogData: FormulaDialogData) => void;
}

interface Props extends DispatchProps {
  property: PropertyFormData;
  groups: string[];
  units: string[];
  onChange: (data: PropertyFormData, isValid?: boolean) => void;
}


interface State {
  isNamesValid: boolean;
  isFormulaValid: boolean;
}

export class FormulaFormComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isNamesValid: validatePropertyNames(this.props.property),
      isFormulaValid: true,
    };
  }

  public render(): JSX.Element {
    return (
      <Styled.FormContainer>
        <PropertyNameInput
          value={this.props.property.name}
          onChange={this.triggerChange}
        />
        <UnitInput
          selectedUnit={this.props.property.unit}
          onChange={this.triggerChange}
          extraUnits={this.props.units}
        />
        <GroupsDropdown
          groups={this.props.groups}
          value={this.props.property.groupName}
          onChange={this.triggerChange}
        />
        <Styled.FieldLabel>
          <TinyText color={'gray'}>Formula:</TinyText>
        </Styled.FieldLabel>
        <FormulaFieldWrapper
          onChangeValidation={this.onChangeValidation}
          value={this.props.property.value.toString()}
          onClick={this.onFormulaClick}
        />
      </Styled.FormContainer>
    );
  }

  @autobind
  private onFormulaClick(): void {
    this.props.openFormulaDialog({
      formulaValue: this.props.property.value.toString(),
      onChangeValue: this.onFormulaChange,
      canAddProperties: false,
      propertyName: this.props.property.name,
      itemProperties: [],
    });
  }

  @autobind
  private triggerChange(updates: Record<string, string | number>): void {
    const updatedProperty = { ...this.props.property, ...updates };
    this.setState(
      { isNamesValid: validatePropertyNames(updatedProperty) && validatePropertyUnit(updatedProperty) },
      () => this.onChange(updatedProperty),
    );
  }

  @autobind
  private onFormulaChange(value: string): void {
    if (this.props.property.value.toString() === value) {
      return;
    }
    this.onChange({ ...this.props.property, value });
  }

  @autobind
  private onChangeValidation(isValid: boolean): void {
    this.setState(
      { isFormulaValid: isValid },
      () => this.onChange(this.props.property),
    );
  }

  private onChange(data: PropertyFormData): void {
    this.props.onChange(data, this.state.isNamesValid && this.state.isFormulaValid);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openFormulaDialog: (data) => dispatch(KreoDialogActions.openDialog(FORMULA_DIALOG_NAME, data)),
  };
};

export const FormulaForm = connect(null, mapDispatchToProps)(FormulaFormComponent);
