import styled from 'styled-components';
import { FormulaFieldStatusStyled } from '../status';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${FormulaFieldStatusStyled.Container} {
    margin-top: 10px;
  }
`;

const InputContainer = styled.div`
  height: 180px;
  margin-top: 10px;
`;

export const Styled = {
  Container,
  InputContainer,
};
