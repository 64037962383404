import styled from 'styled-components';

const UserInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

export const Styled = {
  UserInfo,
};
