import autobind from 'autobind-decorator';
import * as React from 'react';
import { mathUtils } from 'common/utils/math-utils';
import { ScrollArrow } from '../scroll-arrow';
import { Styled } from './styled';


interface Props<T> {
  pagePreviewsCount: number;
  items: T[];
  canvas: HTMLCanvasElement;
  renderItem: (instanceId: T) => React.ReactNode;
}


interface ComponentState {
  page: number;
  maxPages: number;
}

export class PaginatedPreview<T> extends React.Component<Props<T>, ComponentState> {

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      page: 0,
      maxPages: 0,
    };
  }

  public render(): React.ReactNode {
    if (!this.props.canvas) {
      return null;
    }
    return this.renderItems(this.state.page);
  }

  public componentDidMount(): void {
    if (this.props.items.length) {
      this.calculateAndSaveNewPagesCount();
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props<T>>): void {
    if (prevProps.items !== this.props.items) {
      this.calculateAndSaveNewPagesCount();
    }
  }

  private renderItems(step: number): React.ReactNode {
    const startIndex = step * this.props.pagePreviewsCount;
    let pageSize = this.props.items.length - startIndex;
    if (pageSize > this.props.pagePreviewsCount) {
      pageSize = this.props.pagePreviewsCount;
    }
    const items = [];
    for (let i = step * this.props.pagePreviewsCount; i < startIndex + pageSize; i++) {
      items.push(this.props.renderItem(this.props.items[i]));
    }
    return (
      <Styled.Container>
        <ScrollArrow
          left={true}
          enabled={this.state.page !== 0}
          onClick={this.decrementPage}
        />
        {items}
        <ScrollArrow
          onClick={this.incrementPage}
          enabled={this.state.page !== this.state.maxPages}
        />
      </Styled.Container>
    );
  }

  @autobind
  private decrementPage(): void {
    this.setPage(this.state.page - 1);
  }

  @autobind
  private incrementPage(): void {
    this.setPage(this.state.page + 1);
  }

  private setPage(target: number): void {
    this.setState({ page: mathUtils.clamp(target, 0, this.state.maxPages) });
  }


  private getPagesCount(): number {
    const { items, pagePreviewsCount } = this.props;
    const pagesCount = Math.floor(items.length / pagePreviewsCount)
      - Number(items.length % pagePreviewsCount === 0);
    return Math.max(0, pagesCount);
  }

  private calculateAndSaveNewPagesCount(): void {
    const { page, maxPages } = this.state;
    const newMaxPages = this.getPagesCount();
    if (newMaxPages === maxPages) {
      return;
    }
    this.setState(page < newMaxPages ? { maxPages: newMaxPages } : { page: newMaxPages, maxPages: newMaxPages });
  }
}
