import { TwoDNotificationKeys } from '../2d/notifications/keys';
import { ModelManagementNotificationKeys } from '../projects/notifications/keys/model-management';
import { QuantityTakeOffNotificationKeys } from '../projects/notifications/keys/quantity-take-off';

export const NotificationsMessages: Record<string, (data: any) => string> = {
  [QuantityTakeOffNotificationKeys.QuantityTakeOffCalculationSuccess]:
    (data) => (`<span>Quantity Take Off for <b>${data.projectName}</b></span> is ready for verification`),
  [QuantityTakeOffNotificationKeys.QuantityTakeOffCalculationFailed]:
    (data) => (`Quantity Take Off for <b>${data.projectName}</b> failed`),
  [QuantityTakeOffNotificationKeys.QuantityTakeOffReportReady]:
    (data) => (`<span>${data.reportName} for <b>${data.projectName}</b></span> is ready for download`),
  [QuantityTakeOffNotificationKeys.QuantityTakeOffReportFailed]:
    (data) => (`Generation of <span>${data.reportName} for <b>${data.projectName}</b></span> failed`),
  [ModelManagementNotificationKeys.ModelManagementSuccess]:
    (data) => (`Updating of <span> model for <b>${data.projectName}</b></span> is completed`),
  [ModelManagementNotificationKeys.ModelManagementFailed]:
    (data) => (`Updating of <span> model for <b>${data.projectName}</b></span> failed`),
  [TwoDNotificationKeys.TableReportsImportFromExcelFailed]: () => ('Import from Excel failed'),
};

