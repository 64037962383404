import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconLocation: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='icon_14x14_location' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect id='14x14' x='0' y='0' width='14' height='14' />
        <path
          // eslint-disable-next-line max-len
          d='M7.36169361,13.8452213 C7.1647206,14.0515929 6.8352794,14.0515929 6.63830639,13.8452213 C3.22057592,10.2644129 1.5,7.51149147 1.5,5.5 C1.5,2.46243388 3.96243388,0 7,0 C10.0375661,0 12.5,2.46243388 12.5,5.5 C12.5,7.51149147 10.7794241,10.2644129 7.36169361,13.8452213 Z M11.5,5.5 C11.5,3.01471863 9.48528137,1 7,1 C4.51471863,1 2.5,3.01471863 2.5,5.5 C2.5,7.10721622 3.9930458,9.55688967 7,12.7720088 C10.0069542,9.55688967 11.5,7.10721622 11.5,5.5 Z M7,7 C5.8954305,7 5,6.1045695 5,5 C5,3.8954305 5.8954305,3 7,3 C8.1045695,3 9,3.8954305 9,5 C9,6.1045695 8.1045695,7 7,7 Z M7,6 C7.55228475,6 8,5.55228475 8,5 C8,4.44771525 7.55228475,4 7,4 C6.44771525,4 6,4.44771525 6,5 C6,5.55228475 6.44771525,6 7,6 Z'
          id='Combined-Shape'
          fill={KreoColors.f5}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
