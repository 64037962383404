import { NumberDictionary } from 'common/interfaces/dictionary';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import {
  CEMeasurementsLoadDataSuccessPayload,
  MeasurementsOnChangeExtractorValuePayload,
  MeasurementsOnChangeGeneraralValuePayload,
  MeasurementsToggleItemSelectStatusPayload,
} from 'unit-cost-estimate/actions/payloads/measurements';
import { CEMeasurementsActionTypes } from 'unit-cost-estimate/actions/types';
import { CEMeasurementsValueId, CE_MEASUREMENT_INITIAL_STATE } from 'unit-cost-estimate/constants';
import {
  CEMeasurementsActivityExtractor,
  CEMeasurementsState,
  CEMeasurementValue,
  CEMeasurementValueGroup,
} from 'unit-cost-estimate/interfaces';
import { CEMeasurementsUtils, CEMeasurementValueChangeUtils, ChangeResult } from 'unit-cost-estimate/utils';
import { CEPercentageExtractorUtils } from 'unit-cost-estimate/utils/ce-percentage-extractor-utils';
import { ValidationStepStatisticDataPayload } from 'unit-projects/actions/payloads/validation';
import { MeasurementsConstants } from 'unit-projects/constants/measurements';
import { MeasurementsExtractorEditorRowType } from 'unit-projects/enums/measurements-extractor-editor-row-type';
import { RevitTreeLevel } from 'unit-projects/enums/revit-tree-level';
import { MeasurementRevitTreeNodeData } from 'unit-projects/interfaces/measurements/measurement-revit-tree-node-data';
import { RevitGroupNode } from 'unit-projects/interfaces/revit-group-node';
import { mapStatisticRequest } from 'unit-projects/utils/step-statistic-request-mapper';

function saveData(
  state: CEMeasurementsState,
  payload: CEMeasurementsLoadDataSuccessPayload,
): CEMeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  return stateModifier
    .set(s => s.extractors, payload.extractors)
    .set(s => s.badIds, payload.badIds)
    .set(s => s.dataLoaded, true)
    .set(s => s.engineIds, payload.engineIds)
    .set(s => s.rootIds, payload.rootIds)
    .set(s => s.modelBrowser, payload.list)
    .set(s => s.layerIds, payload.layerIds)
    .set(s => s.errorLinks, payload.errorNodes)
    .set(s => s.manualChangedLinks, payload.manualChangedNodes)
    .get();
}

function toggleGeneralSelectStatus(state: CEMeasurementsState, payload: boolean): CEMeasurementsState {
  const elementSelectStatuses: NumberDictionary<boolean> = {};
  if (payload) {
    for (let i = 0; i < state.extractorEditorRows.length; i++) {
      elementSelectStatuses[i] = true;
    }
  }
  return new MonoliteHelper(state)
    .set(_ => _.elementSelectStatuses, elementSelectStatuses)
    .set(_ => _.isGeneralSelected, payload)
    .get();
}


function disabledItemSelectStatus(state: CEMeasurementsState, itemIndex: number): CEMeasurementsState {
  return new MonoliteHelper(state)
    .set(_ => _.elementSelectStatuses, {})
    .set(_ => _.elementSelectStatuses[itemIndex], true)
    .get();
}

function toggleItemSelectStatus(
  state: CEMeasurementsState,
  { itemIndex, value }: MeasurementsToggleItemSelectStatusPayload,
): CEMeasurementsState {
  const helper = new MonoliteHelper(state);
  if (state.isGeneralSelected && !value) {
    helper.set(_ => _.isGeneralSelected, false);
  }
  if (state.extractorEditorRows[itemIndex].type === MeasurementsExtractorEditorRowType.GroupHeader) {
    for (let i = itemIndex + 1; i < state.extractorEditorRows.length; i++) {
      if (state.extractorEditorRows[i].type === MeasurementsExtractorEditorRowType.GroupHeader) {
        break;
      }
      if (state.elementSelectStatuses[i] !== value) {
        helper.set(_ => _.elementSelectStatuses[i], value);
      }
    }
  }
  return helper.set(_ => _.elementSelectStatuses[itemIndex], value).get();
}

function changeErrorCount(
  state: CEMeasurementsState,
): CEMeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  const newErrorCountMap: Record<number, number> = {};
  for (const [i, row] of state.extractorEditorRows.entries()) {
    if (row.type !== MeasurementsExtractorEditorRowType.Activity) {
      continue;
    }

    let hasError = false;
    const { values, isError } = row.data;
    Object.values(values).forEach(
      extractorValue => Object.entries(extractorValue.valuesMap)
        .forEach(([leafNodeId, value]) => {
          newErrorCountMap[leafNodeId] = newErrorCountMap[leafNodeId] || 0;
          if (CEMeasurementsUtils.isMeasurementBad(value && value.value)) {
            newErrorCountMap[leafNodeId]++;
            hasError = hasError || newErrorCountMap[leafNodeId];
          }
        }));

    if (isError !== hasError) {
      stateModifier.set(_ => _.extractorEditorRows[i].data.isError, hasError);
    }
  }

  for (const [leafNodeId, count] of Object.entries(newErrorCountMap)) {
    modifyErrorCount(stateModifier, state, +leafNodeId, count);
  }

  return stateModifier.get();
}

function updateManualChangeFlag(
  state: CEMeasurementsState,
): CEMeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  const newManualChangesMap: Record<number, number> = {};
  for (const [i, row] of state.extractorEditorRows.entries()) {
    if (row.type !== MeasurementsExtractorEditorRowType.Activity) {
      continue;
    }

    let hasManualChange = false;
    const { values } = row.data;
    Object.values(values).forEach(
      extractorValue => Object.entries(extractorValue.valuesMap)
        .forEach(([leafNodeId, value]) => {
          newManualChangesMap[leafNodeId] = newManualChangesMap[leafNodeId] || 0;
          if (CEMeasurementsUtils.isManualChanged(value && value.value)) {
            newManualChangesMap[leafNodeId]++;
            hasManualChange = hasManualChange || newManualChangesMap[leafNodeId];
          }
        }));

    stateModifier.set(_ => _.extractorEditorRows[i].data.isManualChanged, hasManualChange);
  }

  for (const [leafNodeId, count] of Object.entries(newManualChangesMap)) {
    modifyManualChangedCounts(stateModifier, state, +leafNodeId, count);
  }

  return stateModifier.get();
}


/**
 * modify error counts in state modifier
 * @param stateModifier
 * @param state
 * @param leafNodeId
 * @param newErrorCount
 */
function modifyErrorCount(
  stateModifier: MonoliteHelper<CEMeasurementsState>,
  state: CEMeasurementsState,
  leafNodeId: number,
  newErrorCount: number,
): void {
  const countDifference = (state.errorLinks[leafNodeId] || 0) - newErrorCount;
  stateModifier.set(s => s.errorLinks[leafNodeId], newErrorCount);
  let itemIndex = state.modelBrowser[leafNodeId].parentId;
  let item = state.modelBrowser[itemIndex];
  while (item.parentId !== undefined && item.parentId !== null) {
    item = state.modelBrowser[itemIndex];
    stateModifier.set(s => s.errorLinks[itemIndex], value => (value || 0) - countDifference);
    itemIndex = item.parentId;
  }
}

function modifyManualChangedCounts(
  stateModifier: MonoliteHelper<CEMeasurementsState>,
  state: CEMeasurementsState,
  leafNodeId: number,
  newManualChangesCount: number,
): void {
  const countDifference = (state.manualChangedLinks[leafNodeId] || 0) - newManualChangesCount;
  stateModifier.set(s => s.manualChangedLinks[leafNodeId], newManualChangesCount);
  let itemIndex = state.modelBrowser[leafNodeId].parentId;
  let item = state.modelBrowser[itemIndex];
  while (item.parentId !== undefined && item.parentId !== null) {
    item = state.modelBrowser[itemIndex];
    stateModifier.set(s => s.manualChangedLinks[itemIndex], value => (value || 0) - countDifference);
    itemIndex = item.parentId;
  }
}

function pushChanges(
  changes: Record<number, Record<number, CEMeasurementValue[]>>,
  valueGroup: Record<string, CEMeasurementValueGroup>,
  values: Array<{ id: string, value: number }>,
): void {
  for (const { id, value } of values) {
    Object.entries(valueGroup[id].valuesMap).forEach(
      ([leafNodeId, measurementValue]) => {
        changes[+leafNodeId] = changes[leafNodeId] || {};
        changes[+leafNodeId][measurementValue.activityId] = changes[+leafNodeId][measurementValue.activityId] || [];
        const changedValue = { ...measurementValue.value };
        changedValue.manualValue = value;
        changes[+leafNodeId][measurementValue.activityId].push(changedValue);
      });
  }
}

function fillLeafNodeIndexMap(leafNodeIndexMap: Record<number, number>, group: CEMeasurementValueGroup): void {
  if (group) {
    Object.keys(group.valuesMap)
      .forEach(x => leafNodeIndexMap[x] = x in leafNodeIndexMap ? leafNodeIndexMap[x] + 1 : 0);
  }
}

function setMeasurementValue(
  state: CEMeasurementsState,
  helper: MonoliteHelper<CEMeasurementsState>,
  leafNodeIndexMap: Record<number, number>,
  rowIndex: number,
  extractorId: string,
  value: number | string,
): MonoliteHelper<CEMeasurementsState> {
  const row = state.extractorEditorRows[rowIndex];

  helper.set(_ => _.extractorEditorRows[rowIndex].data.values[extractorId].commonValue, value);
  for (const leafNodeId of Object.keys(row.data.values[extractorId].valuesMap)) {
    const nodeIndex = leafNodeIndexMap[leafNodeId];
    const measurements = state.extractors[+leafNodeId].data.activities[nodeIndex].measurements;
    if (!(extractorId in measurements)) {
      return helper;
    }

    const autoValue = measurements[extractorId].autoValue;
    if (autoValue === value) {
      helper
        .set(_ => _.extractors[+leafNodeId].data.activities[nodeIndex].measurements[extractorId].manualValue, null)
        .set(
          _ => _.extractorEditorRows[rowIndex].data.values[extractorId].valuesMap[+leafNodeId].value.manualValue,
          null,
        );
    } else {
      helper
        .set(_ => _.extractors[+leafNodeId].data.activities[nodeIndex].measurements[extractorId].manualValue, value)
        .set(
          _ => _.extractorEditorRows[rowIndex].data.values[extractorId].valuesMap[+leafNodeId].value.manualValue,
          value,
        );
    }
  }

  return helper;
}

function onChangeGeneralInputValue(
  state: CEMeasurementsState,
  { value, extractorId, generalExtractorId }: MeasurementsOnChangeGeneraralValuePayload,
): CEMeasurementsState {
  const leafNodeIndexMap = {};
  let updatedState = state;

  for (let i = 0; i < state.extractorEditorRows.length; i++) {
    const row = state.extractorEditorRows[i];
    if (row.type === MeasurementsExtractorEditorRowType.GroupHeader) {
      continue;
    }


    const isSelected = state.elementSelectStatuses[i];
    const { values } = row.data;
    const measurement = values[extractorId];
    if (!measurement) {
      continue;
    }

    fillLeafNodeIndexMap(leafNodeIndexMap, measurement);

    const { elementExtractorValue, numberValue } = CEMeasurementsUtils.convertMeasurementValues(value, measurement);
    if (!isSelected || elementExtractorValue === numberValue) {
      continue;
    }

    updatedState = onChangeInputValue(updatedState, { elementIndex: i, extractorId, generalExtractorId, value });
  }

  const helper = new MonoliteHelper(updatedState);
  const totalValues = { ...state.generalExtractorEditors };
  Object.keys(totalValues).forEach(x => helper.set(_ => _.generalExtractorEditors[x].value, ''));
  helper.set(_ => _.generalExtractorEditors[extractorId].value, value);

  return helper.get();
}

function setChangesToState(
  state: CEMeasurementsState,
  { elementIndex, extractorId }: MeasurementsOnChangeExtractorValuePayload,
  changes: ChangeResult,
): CEMeasurementsState {
  const row = state.extractorEditorRows[elementIndex];
  const values = row.data.values;
  const helper = new MonoliteHelper(state);
  const uncommittedChanges: Record<number, Record<number, CEMeasurementValue[]>> = {};

  const leafNodeIndexMap = {};
  const baseExtractorId = CEPercentageExtractorUtils.getBaseExtractorId(extractorId);
  for (let i = 0; i <= elementIndex; i++) {
    if (state.extractorEditorRows[i].type === MeasurementsExtractorEditorRowType.Activity) {
      fillLeafNodeIndexMap(leafNodeIndexMap, state.extractorEditorRows[i].data.values[baseExtractorId]);
    }
  }

  for (const [extractor, extractorValue] of Object.entries(changes.changedValues)) {
    setMeasurementValue(state, helper, leafNodeIndexMap, elementIndex, extractor, extractorValue.value);
    const oldTotalValue = state.generalExtractorEditors[extractor].totalValue;
    const totalValue = oldTotalValue + extractorValue.delta;
    helper
      .set(_ => _.generalExtractorEditors[extractor].totalValue, totalValue)
      .set(_ => _.hasChanges, true);
  }

  for (const extractorValues of Object.values(changes.primaryExtractorValues)) {
    pushChanges(uncommittedChanges, values, extractorValues);
  }
  helper.set(_ => _.uncommittedChanges, x => CEMeasurementsUtils.mergeUncommittedChanges(x, uncommittedChanges));

  const updatedState = changeErrorCount(helper.get());
  return updateManualChangeFlag(updatedState);
}

function onChangeInputValue(
  state: CEMeasurementsState,
  payload: MeasurementsOnChangeExtractorValuePayload,
): CEMeasurementsState {
  const { elementIndex, value, extractorId, generalExtractorId } = payload;
  const template = state.extractorFunctions[generalExtractorId];
  const templates = state.extractorFunctions;
  const row = state.extractorEditorRows[elementIndex];
  const values = row.data.values;

  const engineId = row.data.engineId;
  const volume = template.relationParameters
    && template.relationParameters[engineId]
    && template.relationParameters[engineId][MeasurementsConstants.reinforcementVolumeKey];

  const baseExtractorId = CEPercentageExtractorUtils.getBaseExtractorId(extractorId);
  if (volume && (
    baseExtractorId === CEMeasurementsValueId.ReinforcementMass
    || baseExtractorId === CEMeasurementsValueId.ReinforcementConcentration)
  ) {
    const changes: ChangeResult = {
      changedValues: {},
      primaryExtractorValues: {},
    };
    if (baseExtractorId === extractorId) {
      const reinforcementChanges = CEMeasurementValueChangeUtils.getReinforcementChanges(
        values, templates, volume, generalExtractorId, extractorId, value,
      );
      CEMeasurementValueChangeUtils.appendChanges(changes, reinforcementChanges);
      for (const [id, extractorValue] of Object.entries(changes.primaryExtractorValues)) {
        const newChanges = CEMeasurementValueChangeUtils
          .getExtractorsChanges(values, templates, id, extractorValue[0].value.toString());
        CEMeasurementValueChangeUtils.appendChanges(changes, newChanges);
      }
    } else {
      const newChanges = CEMeasurementValueChangeUtils
        .getExtractorsChanges(values, templates, extractorId, value);
      CEMeasurementValueChangeUtils.appendChanges(changes, newChanges);
      const baseExtractorValue = changes.primaryExtractorValues[baseExtractorId][0].value.toString();
      const reinforcementChanges = CEMeasurementValueChangeUtils.getReinforcementChanges(
        values, templates, volume, baseExtractorId, baseExtractorId, baseExtractorValue,
      );
      CEMeasurementValueChangeUtils.appendChanges(changes, reinforcementChanges);
    }

    return setChangesToState(state, payload, changes);
  }

  const changes = CEMeasurementValueChangeUtils.getExtractorsChanges(values, templates, extractorId, value);

  return setChangesToState(state, payload, changes);
}

function cancelEdit(
  state: CEMeasurementsState,
): CEMeasurementsState {
  if (state.hasChanges) {
    const helper = new MonoliteHelper(state);
    for (const cachedValue of state.cachedMeasurementsValues) {
      for (const [extractorId, measurements] of Object.entries(cachedValue)) {
        const leafNodeIdMap = {};
        for (const [leafNodeId, value] of Object.entries(measurements.valuesMap)) {
          const index = leafNodeId in leafNodeIdMap ? leafNodeIdMap[leafNodeId] + 1 : 0;
          leafNodeIdMap[leafNodeId] = index;
          helper.set(_ => _.extractors[leafNodeId].data.activities[index].measurements[extractorId], value);
        }
      }
    }
    helper
      .set(_ => _.errorLinks, state.cachedErrorLinks)
      .set(_ => _.cachedMeasurementsValues, [])
      .set(_ => _.cachedErrorLinks, [])
      .set(_ => _.hasChanges, false);
    return dropSelection(helper.get());
  } else {
    return dropSelection(state);
  }
}

function commitChangesSuccess(state: CEMeasurementsState): CEMeasurementsState {
  return new MonoliteHelper(state).set(_ => _.uncommittedChanges, {}).get();
}

function getStatisticSuccess(
  state: CEMeasurementsState,
  payload: ValidationStepStatisticDataPayload,
): CEMeasurementsState {
  const { errorIds, statistic } = mapStatisticRequest(payload);
  return new MonoliteHelper(state)
    .set(_ => _.statisticLoaded, true)
    .set(_ => _.statistic, statistic)
    .set(_ => _.badIds, errorIds)
    .get();
}

function getStatisticRequest(state: CEMeasurementsState): CEMeasurementsState {
  return new MonoliteHelper(state).set(_ => _.statisticLoaded, false).get();
}

function dropSelection(state: CEMeasurementsState): CEMeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  return stateModifier
    .set(s => s.selectedNodeId, null)
    .set(s => s.extractorFunctions, {})
    .set(s => s.extractorEditorRows, [])
    .set(s => s.elementSelectStatuses, {})
    .set(s => s.showMeasurementEditor, false)
    .get();
}

function selectNode(state: CEMeasurementsState, payload: number): CEMeasurementsState {
  state = dropSelection(state);
  return new MonoliteHelper(state).set(_ => _.selectedNodeId, payload).get();
}


function selectExtractorById(state: CEMeasurementsState, payload: number): CEMeasurementsState {
  const selectedNodes = getSelectedExtractors(state, payload);
  const { templates, measurementData } = CEMeasurementsUtils.getExtractorFunctions(selectedNodes);
  const extractorEditorRows = CEMeasurementsUtils.getExtractorElements(measurementData);
  const currentMeasurementsValues = measurementData.map(x => x.measurements);
  return new MonoliteHelper(state)
    .set(_ => _.selectedNodeId, payload)
    .set(_ => _.extractorFunctions, templates)
    .set(_ => _.hasChanges, false)
    .set(_ => _.extractorEditorRows, extractorEditorRows)
    .set(_ => _.cachedMeasurementsValues, currentMeasurementsValues)
    .set(_ => _.cachedErrorLinks, state.errorLinks)
    .set(_ => _.showMeasurementEditor, true)
    .set(_ => _.elementSelectStatuses, {})
    .set(_ => _.centralEditorRowPosition, null)
    .set(
      _ => _.generalExtractorEditors,
      CEMeasurementsUtils.createGeneralExtractorEditors(templates, measurementData),
    )
    .get();
}

function expandById(state: CEMeasurementsState, payload: number): CEMeasurementsState {
  return new MonoliteHelper(state)
    .set(_ => _.modelBrowser[payload].isExpanded, !state.modelBrowser[payload].isExpanded)
    .get();
}

function collapseAllNodesMapFunction(
  node: RevitGroupNode<MeasurementRevitTreeNodeData>,
): RevitGroupNode<MeasurementRevitTreeNodeData> {
  if (!node.isExpanded) {
    return node;
  }
  return new MonoliteHelper(node).set(_ => _.isExpanded, false).get();
}

function collapseAllNodes(state: CEMeasurementsState): CEMeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  return stateModifier
    .setMap(_ => _.modelBrowser, collapseAllNodesMapFunction)
    .get();
}

function getSelectedExtractors(state: CEMeasurementsState, selectedNodeId: number): CEMeasurementsActivityExtractor[] {
  if (selectedNodeId in state.extractors) {
    return [state.extractors[selectedNodeId]];
  }

  const lastIndex = state.modelBrowser[selectedNodeId].lastChildrenIndex;
  const result = [];
  for (let index = selectedNodeId; index <= lastIndex; index ++) {
    if (index in state.extractors) {
      result.push(state.extractors[index]);
    }
  }

  return result;
}

function selectExtractor(state: CEMeasurementsState): CEMeasurementsState {
  const selectedExtractor = getSelectedExtractors(state, state.selectedNodeId);
  const { templates, measurementData } = CEMeasurementsUtils.getExtractorFunctions(selectedExtractor);
  const extractorEditorRows = CEMeasurementsUtils.getExtractorElements(measurementData);
  const currentMeasurementsValues = measurementData.map(x => x.measurements);

  return new MonoliteHelper(state)
    .set(_ => _.extractorFunctions, templates)
    .set(_ => _.showMeasurementEditor, true)
    .set(_ => _.elementSelectStatuses, {})
    .set(_ => _.extractorEditorRows, extractorEditorRows)
    .set(_ => _.cachedMeasurementsValues, currentMeasurementsValues)
    .set(_ => _.cachedErrorLinks, state.errorLinks)
    .set(_ => _.centralEditorRowPosition, null)
    .set(
      _ => _.generalExtractorEditors,
      CEMeasurementsUtils.createGeneralExtractorEditors(templates, measurementData),
    )
    .get();
}

function extractorSelectionByBimHandle(
  state: CEMeasurementsState,
  payload: number,
): CEMeasurementsState {
  const element = state.engineIds.find(x => x.id === payload);
  state = collapseAllNodes(state);
  const { measurement, id, type, family, category } = element;
  state = new MonoliteHelper(state).set(_ => _.selectedNodeId, measurement).get();
  state = selectExtractor(state);
  const indexOfRow = state.extractorEditorRows.findIndex(x =>
    x.type === MeasurementsExtractorEditorRowType.Activity && x.data.engineId === id);
  const stateModifier = new MonoliteHelper(state);
  if (indexOfRow !== -1) {
    stateModifier
      .set(_ => _.centralEditorRowPosition, indexOfRow)
      .set(_ => _.elementSelectStatuses[indexOfRow], true);
  }
  let itemPosition = 0;
  for (let i = 0; i < type; i++) {
    itemPosition++;
    switch (state.modelBrowser[i].level) {
      case RevitTreeLevel.Category: {
        if (i !== category) {
          i = state.modelBrowser[i].lastChildrenIndex;
          continue;
        }
        break;
      }
      case RevitTreeLevel.Family: {
        if (i !== family) {
          i = state.modelBrowser[i].lastChildrenIndex;
          continue;
        }
        break;
      }
      default:
    }
  }
  return stateModifier
    .set(_ => _.modelBrowser[type].isExpanded, true)
    .set(_ => _.modelBrowser[family].isExpanded, true)
    .set(_ => _.modelBrowser[category].isExpanded, true)
    .set(_ => _.centralTreeElement, itemPosition)
    .get();
}

function search(state: CEMeasurementsState, payload: string): CEMeasurementsState {
  state = collapseAllNodes(state);
  const stateModifier = new MonoliteHelper(state);
  if (payload) {
    const searchResult = CEMeasurementsUtils.search(state.modelBrowser, state.activityMeasurement, payload);
    searchResult.forEach(itemId => {
      let item = state.modelBrowser[itemId];
      while (item.parentId !== undefined && item.parentId !== null) {
        stateModifier.set(s => s.modelBrowser[item.parentId].isExpanded, true);
        item = state.modelBrowser[item.parentId];
      }
    });
    stateModifier.set(s => s.searchMode, true).set(s => s.searchedNodes, searchResult);
  } else {
    stateModifier.set(s => s.searchMode, false);
  }
  return stateModifier.get();
}

function showHideRevitTree(state: CEMeasurementsState): CEMeasurementsState {
  return new MonoliteHelper(state).set(_ => _.viewTree, !state.viewTree).get();
}

function toggleElementCheckStatusFromEngine(
  state: CEMeasurementsState,
  payload: number[],
): CEMeasurementsState {
  const idSet = new Set(payload);
  const helper = new MonoliteHelper(state);
  const elementSelectStatuses = {};
  let selectedRowPosition = null;
  for (let i = 0; i < state.extractorEditorRows.length; i++) {
    const row = state.extractorEditorRows[i];
    if (row.type === MeasurementsExtractorEditorRowType.Activity && idSet.has(row.data.engineId)) {
      elementSelectStatuses[i] = true;
      selectedRowPosition = i;
    }
  }
  return helper
    .set(_ => _.elementSelectStatuses, elementSelectStatuses)
    .set(_ => _.centralEditorRowPosition, selectedRowPosition)
    .get();
}

function dropStatistic(state: CEMeasurementsState): CEMeasurementsState {
  const stateModifier = new MonoliteHelper(state);
  return stateModifier.set(s => s.statistic, []).get();
}

export const ceMeasurementReducerMethods: ReducerMethods<CEMeasurementsState> = {
  [CEMeasurementsActionTypes.LOAD_DATA_REQUEST]: state =>
    new MonoliteHelper(state).set(_ => _.dataLoaded, false).get(),
  [CEMeasurementsActionTypes.LOAD_DATA_SUCCESSED]: saveData,
  [CEMeasurementsActionTypes.DROP_STATE]: () => CE_MEASUREMENT_INITIAL_STATE,
  [CEMeasurementsActionTypes.SHOW_HIDE_TREE]: showHideRevitTree,
  [CEMeasurementsActionTypes.START_SEARCH]: search,
  [CEMeasurementsActionTypes.SELECT_NODE_BY_ENGINE_ID]: extractorSelectionByBimHandle,
  [CEMeasurementsActionTypes.DROP_STATISTIC]: dropStatistic,
  [CEMeasurementsActionTypes.EXPAND_ITEM_BY_ID]: expandById,
  [CEMeasurementsActionTypes.SHOW_EXTRACTORS_BY_ID]: selectExtractorById,
  [CEMeasurementsActionTypes.SELECT_NODE]: selectNode,
  [CEMeasurementsActionTypes.GET_STATISTIC_REQUEST]: getStatisticRequest,
  [CEMeasurementsActionTypes.GET_STATISTIC_SUCCESS]: getStatisticSuccess,
  [CEMeasurementsActionTypes.ON_CHANGE_INPUT_VALUE]: onChangeInputValue,
  [CEMeasurementsActionTypes.ON_CHANGE_GENERAL_INPUT_VALUE]: onChangeGeneralInputValue,
  [CEMeasurementsActionTypes.TOGGLE_ITEM_SELECT_STATUS]: toggleItemSelectStatus,
  [CEMeasurementsActionTypes.TOGGLE_GENERAL_SELECT_STATUS]: toggleGeneralSelectStatus,
  [CEMeasurementsActionTypes.SELECT_ELEMENT_FROM_ENGINE]: toggleElementCheckStatusFromEngine,
  [CEMeasurementsActionTypes.COMMIT_CHANGES_SUCCESS]: commitChangesSuccess,
  [CEMeasurementsActionTypes.DISABLED_ITEM_SELECT_STATUS]: disabledItemSelectStatus,
  [CEMeasurementsActionTypes.CANCEL_EDIT_SUCCESS]: cancelEdit,
  [CEMeasurementsActionTypes.TOGGLE_LEVELS_SHOW_STATUS]: (state, payload) =>
    new MonoliteHelper(state).set(_ => _.areLevelsShown, payload).get(),
};
