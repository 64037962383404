import styled from 'styled-components';

import { CustomDialogStyled } from 'common/components/custom-dialog';

const Container = styled.div`
  .decrement-invited-emails-dialog {
    ${CustomDialogStyled.Buttons} {
      button:first-child {
        > p {
          color: ${p => p.theme.color.gray};
        }

        :hover {
          > p {
            color: ${p => p.theme.color.turquoise};
          }
        }
      }
    }
  }
`;


export const Styled = {
  Container,
};
