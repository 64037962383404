import { PiaBreakdownNode } from 'unit-2d-database/interfaces';

const checkNode = (nodes: PiaBreakdownNode[], id: string, nesting: number, maxNesting?: number): PiaBreakdownNode => {
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    }
    const childNode = checkNode(node.children, id, nesting + 1,  maxNesting);

    if (childNode) {
      if (maxNesting !== nesting) {
        return childNode;
      } else {
        return node;
      }
    }
  }

  return null;
};

export const getNodeById = (nodes: PiaBreakdownNode[], id: string, maxNesting?: number): PiaBreakdownNode => {
  if (!nodes) {
    return null;
  }
  return checkNode(nodes, id, 0, maxNesting);
};
