import React from 'react';

import { AdvDialog } from 'common/components/adv-dialog';
import { ControlNames } from 'common/constants/control-names';
import { ConstantFunctions } from 'common/constants/functions';
import { VideoUrl } from 'common/constants/video-url';

interface Props {
  onCloseDialog: () => void;
  onConfirm: () => void;
}

export const AUTO_MEASURE_ADV_DIALOG_NAME = 'AUTO_MEASURE_ADV_DIALOG_NAME';
export const AUTO_MEASURE_HEADER_ADV_DIALOG = 'Auto Measure';
const DESCRIPTION_ADV_DIALOG = `Auto Measure is the AI-powered add-on, which defines all the areas and
elements on your drawings and groups them by type in several minutes after the tool is launched.`;
const FEATURES_ADV_DIALOG = [
  'Works for residential floorplans only',
  'Applicable for vector graphics only',
  'Recognizes following areas: rooms; flats; GIA; NIA/NSF; GEA/GSF',
  'Results are automatically properly structured in the Measurements Manager & in the spreadsheet',
  'Recognizes following elements: doors; windows; walls; openings',
];
export class AutoMeasureAdvDialog extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <AdvDialog
        videoUrl={VideoUrl.AutoMeasureMechanism2D}
        header={AUTO_MEASURE_HEADER_ADV_DIALOG}
        description={DESCRIPTION_ADV_DIALOG}
        features={FEATURES_ADV_DIALOG}
        onConfirm={this.props.onConfirm}
        text={'Add to my plan'}
        controlName={ControlNames.advDialogAutoMeasure}
        dialogName={AUTO_MEASURE_ADV_DIALOG_NAME}
        onCloseDialog={this.props.onCloseDialog}
        onChatOpen={ConstantFunctions.doNothing}
      />
    );
  }
}
