import * as React from 'react';

import './classification-edit-uniclass-button.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { KreoIconEditPen } from 'common/UIKit';
import { ClassificationRevitTreeItemRoundedButton } from '../classification-revit-tree-item-rounded-button';


interface Props extends AbilityAwareProps {
  children: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const ClassificationEditUniclassButtonComponent: React.FC<Props> = props => {
  const { children, onClick, ability } = props;
  if (!ability.can(Operation.Update, Subject.ElementUniclassClassification)) {
    return null;
  }
  return (
    <ClassificationRevitTreeItemRoundedButton
      controlName='edit-uniclass'
      className='classification-edit-uniclass-button'
      onClick={onClick}
      tootlipText={`${children} Uniclass`}
    >
      <KreoIconEditPen/>
    </ClassificationRevitTreeItemRoundedButton>
  );
};


export const ClassificationEditUniclassButton = withAbilityContext(ClassificationEditUniclassButtonComponent);
