import React, { useMemo } from 'react';

import { CheckboxGroupMenu } from 'common/components/selection-menus';
import { arrayUtils } from 'common/utils/array-utils';
import { Styled } from './styled';

interface Props {
  tags: string[];
  selectedTags: string[];
  onSelectChange: (selectedTags: string[]) => void;
}

export const TagList: React.FC<Props> = ({
  tags,
  selectedTags,
  onSelectChange,
}) => {
  const options = useMemo(() => {
    const sortedTag = tags.sort((a, b) => arrayUtils.localCompareWithNumber(a.toLowerCase(), b.toLowerCase()));
    return sortedTag.map((tag) => ({ name: tag, value: tag }));
  }, [tags]);
  return (
    <Styled.TagContainer>
      <CheckboxGroupMenu
        withCommon={true}
        value={selectedTags}
        options={options}
        onSelectionChanged={onSelectChange}
      />
    </Styled.TagContainer>
  );
};
