import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './model-management-files-browser.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { UploadingFile } from 'common/interfaces/common-state';
import { State } from 'common/interfaces/state';
import { KreoButton, KreoDialogActions, KreoIconCreateNew, KreoIconProjectCreate, KreoScrollbars } from 'common/UIKit';
import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { PersistedStorageActions } from '../../../persisted-storage/actions/creators';
import { ModelManagementActions } from '../../actions/creators/model-management';
import { ModelManagementDialogs } from '../../constants/model-management-dialogs';
import { ViewModelStatus } from '../../enums/view-model-status';
import { ModelManagementFileData } from '../../interfaces/model-management/model-management-data';
import { ModelManagementFile } from './model-management-file';
import { ModelManagementLoadFileDialog } from './model-management-load-file-dialog';


interface OwnProps {
  selectEngineIds: (bimIds: number[]) => void;
}

interface StateProps {
  models: ModelManagementFileData[];
  temporaryModels: UploadingFile[];
  isApplyDisabled: boolean;
  isApplyFailed: boolean;
  selectedFiles: number[];
  isLoading: boolean;
}

interface DispatchProps {
  onDeleteFile: (id: number | string) => void;
  openDialog: (dialogName: string) => void;
  closeDialog: (dialogName: string) => void;
  apply: () => void;
  selectFile: (id: number) => void;
  onChangeDisableShowDialog: (name: string) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {

}

interface ComponentState {
  fileForRemove: ModelManagementFileData;
}

class ModelManagementFilesBrowserComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fileForRemove: null,
    };
  }

  public render(): React.ReactNode {
    const {
      onChangeDisableShowDialog,
      models,
      temporaryModels,
      isLoading,
    } = this.props;
    return (
      <div className='model-management-files-browser'>
        <KreoConfirmationDialog
          name={ModelManagementDialogs.DELETE_FILE}
          onYes={this.acceptDeleteFile}
          yesText='Delete'
          noText='Cancel'
          title='Delete File'
          onDisableShowDialogChange={onChangeDisableShowDialog}
        >
          Are you sure you want to delete the <b>{this.state.fileForRemove && this.state.fileForRemove.name}</b>?
        </KreoConfirmationDialog>
        <ModelManagementLoadFileDialog onSubmit={this.onDialogSubmitted} />
        <div className='model-management-files-browser__header'>
          <KreoButton
            size='large'
            mode='action'
            onClick={this.onOpenFileUploadDialog}
            icon={<KreoIconCreateNew />}
            caption='Add *.ifc, *.rvt or *.bim file'
          />
        </div>
        <div className='model-management-files-browser__content'>
          <KreoScrollbars>
            {models.length || temporaryModels.length ?
              <>
                {
                  models.map(x => {
                    return (
                      <ModelManagementFile
                        key={x.id}
                        id={x.id}
                        name={x.name}
                        bimElementIds={x.bimElementIds}
                        onSelectFile={this.onSelectFile}
                        onDeleteFile={this.onDeleteFile}
                        selected={this.props.selectedFiles.includes(x.id)}
                      />
                    );
                  })
                }
                {
                  temporaryModels.map(x => {
                    return (
                      <ModelManagementFile
                        key={x.uploadedFileName}
                        name={x.file.name}
                        id={x.uploadedFileName}
                        onDeleteFile={this.props.onDeleteFile}
                        isNew={true}
                      />
                    );
                  })
                }
              </>
              : isLoading ? <SvgSpinner size='middle' /> : this.renderPlaceholder()}
          </KreoScrollbars>
        </div>
        <div className='model-management-files-browser__footer'>
          <KreoButton
            size='large'
            onClick={this.openConfirmDialog}
            disabled={this.props.isApplyDisabled}
            mode={this.props.isApplyFailed ? 'error' : 'submit'}
            caption={this.props.isApplyFailed ? 'Try Again' : 'Apply Changes'}
          />
          <KreoConfirmationDialog
            name={ModelManagementDialogs.APPLY_CHANGES}
            onYes={this.applyChanges}
            yesText='Apply'
            noText='Cancel'
            title='Apply Changes'
          >
            Are you sure you want to apply changes? Your QTO Reports and Templates will remain the same.
          </KreoConfirmationDialog>
        </div>
      </div>
    );
  }

  @autobind
  private applyChanges(): void {
    this.props.apply();
  }

  @autobind
  private openConfirmDialog(): void {
    this.props.openDialog(ModelManagementDialogs.APPLY_CHANGES);
  }

  @autobind
  private acceptDeleteFile(): void {
    this.props.onDeleteFile(this.state.fileForRemove.id);
  }

  @autobind
  private renderPlaceholder(): React.ReactNode {
    return (
      <div
        className='model-management-files-browser__content-placeholder'
        onClick={this.onOpenFileUploadDialog}
      >
        <KreoIconProjectCreate />
        <div className='model-management-files-browser__content-placeholder-text'>Add your first file!</div>
      </div>
    );
  }

  @autobind
  private onDeleteFile(id: number | string): void {
    const file = this.props.models.find(x => x.id === id);
    this.setState(
      { fileForRemove: file },
      () => this.props.openDialog(ModelManagementDialogs.DELETE_FILE),
    );
  }

  @autobind
  private onSelectFile(id: number, bimIds: number[]): void {
    this.props.selectFile(id);
    this.props.selectEngineIds(bimIds);
  }

  @autobind
  private onDialogSubmitted(): void {
    this.props.closeDialog(ModelManagementDialogs.LOAD_FILE);
  }

  @autobind
  private onOpenFileUploadDialog(): void {
    this.props.openDialog(ModelManagementDialogs.LOAD_FILE);
  }
}

function mapStateToProps(state: State): StateProps {
  const {
    modelManagement: { temporaryModels, modelsForRemove, models, isLoading, selectedFileIds, isApplyFailed },
    projects: { currentProject },
  } = state;
  const hasTemporaryModels = !temporaryModels.length && !modelsForRemove.length;
  return {
    isLoading,
    models,
    temporaryModels,
    isApplyDisabled: hasTemporaryModels || Object.values(currentProject.viewModelsStatuses)
      .some(x =>  x === ViewModelStatus.Calculating || x === ViewModelStatus.Waiting),
    selectedFiles: selectedFileIds,
    isApplyFailed,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onDeleteFile: id => dispatch(ModelManagementActions.deleteFile(id)),
    openDialog: (dialogName) => dispatch(KreoDialogActions.openDialog(dialogName)),
    closeDialog: (dialogName) => dispatch(KreoDialogActions.closeDialog(dialogName)),
    apply: () => dispatch(ModelManagementActions.apply()),
    onChangeDisableShowDialog: (name) => dispatch(PersistedStorageActions.toggleDisableShowDialog(name)),
    selectFile: id => dispatch(ModelManagementActions.selectFile(id)),
  };
}

export const ModelManagementFilesBrowser =
  connect(mapStateToProps, mapDispatchToProps)(ModelManagementFilesBrowserComponent);
