import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseResourceActions } from '../../actions/creators/database-resource';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { DatabaseResourceVariantActions } from '../../actions/creators/database-resource-variant';
import { ModifyDatabaseEntityMode, ResourceType } from '../../enums';
import { MaterialModel, MaterialVariantModel } from '../../interfaces/resources-data';
import { MaterialVariantForm } from '../../interfaces/resources-rest-data';
import { ResourcesRestFormMapper } from '../../utils/resources-rest-form-mapper';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';
import { DatabaseResourceMaterialVariant } from './database-resource-material-variant';

interface ReduxProps {
  variant: MaterialVariantModel;
  resource: MaterialModel;
  resourceId: number;
  variantId: number;
  databaseId: number;
}

interface ReduxActions {
  setEditResourceVariantModel: (variant: MaterialVariantModel) => void;
  loadDatabaseResource: (databaseId: number, resourceId: number, type: ResourceType) => void;
  loadDatabaseResourceVariant: (databaseId: number, resourceId: number, variantId: number, type: ResourceType) => void;
  updateDatabaseResourceVariant: (
    databaseId: number, resourceId: number, variantId: number,
    type: ResourceType, model: MaterialVariantForm, formId: string,
  ) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps {
}

interface State {
  initialValues: MaterialVariantForm;
}


class EditDatabaseResourceMaterialVariantComponent extends React.Component<Props, State> {
  private readonly formId: string = 'edit-material-variant';

  constructor(props: Props) {
    super(props);
    this.state = { initialValues: null };
  }

  public componentDidMount(): void {
    const { databaseId, resourceId, variantId } = this.props;
    this.props.loadDatabaseResource(databaseId, resourceId, ResourceType.Material);
    this.props.loadDatabaseResourceVariant(databaseId, resourceId, variantId, ResourceType.Material);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.variant === null && this.props.variant !== null) {
      this.setState({ initialValues: ResourcesRestFormMapper.GetMaterialVariantForm(this.props.variant) });
    }
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Material Variant'
        isApplyButtonDisabled={this.isEditButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Edit}
        onSubmit={this.save}
        readonly={this.props.readonly}
        form={this.formId}
        initialValues={this.state.initialValues}
      >
      {
        this.props.resource && this.props.variant ? (
          <KreoScrollbars>
            <DatabaseResourceMaterialVariant
              resource={this.props.resource}
              variant={this.props.variant}
              onChange={this.props.setEditResourceVariantModel}
              readonly={this.props.readonly}
            />
          </KreoScrollbars>
        ) : <SvgSpinner size='middle'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }


  @autobind
  private save(values: MaterialVariantForm): void {
    this.props.updateDatabaseResourceVariant(
      this.props.databaseId, this.props.resourceId, this.props.variantId, ResourceType.Material, values, this.formId,
    );
  }

  private isEditButtonDisabled(): boolean {
    const { variant } = this.props;
    return !variant ||
      !variant.name ||
      !variant.name.trim() ||
      !variant.unitId ||
      !(variant.cost >= 0) ||
      !(variant.amount >= 0);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    variant: editModel.variant,
    resource: editModel.root,
    resourceId: editModel.rootId,
    variantId: editModel.variantId,
    databaseId: state.database.currentDatabase.database.id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditResourceVariantModel: variant =>
      dispatch(DatabaseResourceListingActions.setEditResourceVariantModel(variant)),
    updateDatabaseResourceVariant: (databaseId, resourceId, variantId, type, model, formId) =>
      dispatch(DatabaseResourceVariantActions.updateResourceVariant(
        databaseId, resourceId, variantId, type, model, formId,
      )),
    loadDatabaseResourceVariant: (databaseId, resourceId, variantId, type) =>
      dispatch(DatabaseResourceVariantActions.loadResourceVariant(databaseId, resourceId, variantId, type)),
    loadDatabaseResource: (databaseId, resourceId, type) =>
      dispatch(DatabaseResourceActions.loadResource(databaseId, resourceId, type)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const EditDatabaseResourceMaterialVariant = connector(EditDatabaseResourceMaterialVariantComponent);
