import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { arrayUtils } from 'common/utils/array-utils';
import { DrawingContextObserver } from '../../drawings-contexts';
import { DrawingsGeometryGroup } from '../../interfaces/drawings-geometry-group';
import { DrawingsGroupMeasure } from '../../interfaces/drawings-measures';
import { DrawingsUserAnnotationLegendHeader, DrawingsUserLegendHeaderConfig } from './header';
import { DrawingsUserAnnotationLegendItem } from './item';
import { Styled } from './styled';

interface Props {
  isImperial: boolean;
  groups: DrawingsGeometryGroup[];
  headerConfig: DrawingsUserLegendHeaderConfig;
  zoomHandler: DrawingContextObserver<{ zoom: number }>;
  saveTableRef: (ref: HTMLTableElement) => void;
  getGroupMeasures: (groupId: string, instances: string[]) => DrawingsGroupMeasure;
}


export const DrawingsUserAnnotationLegendContent2: React.FC<Props> = ({
  isImperial,
  groups,
  headerConfig,
  zoomHandler,
  saveTableRef,
  getGroupMeasures,
}: Props) => {
  const bodyRef = useRef<HTMLTableSectionElement>(null);
  const headerRef = useRef<HTMLTableSectionElement>(null);

  const onUpdateCallback = useCallback(({ zoom }: { zoom: number }) => {
    if (!bodyRef.current || !headerRef.current) {
      return;
    }
    const fontSize = zoom * Styled.FONT_SIZE;
    const height = fontSize * Styled.LINE_HEIGHT_MULTIPLIER;
    bodyRef.current.style.transform = `max-height: calc(100% - ${height}px);`;

    const values = headerRef.current.getElementsByClassName(String(Styled.HeaderValue));

    for (let i = 0; i < values.length; i++) {
      const value = values.item(i) as HTMLDivElement;
      value.style.width = `${Styled.VALUE_CELL_WIDTH * zoom}px`;
      value.style.minWidth = `${Styled.VALUE_CELL_WIDTH * zoom}px`;
      value.style.maxWidth = `${Styled.VALUE_CELL_WIDTH * zoom}px`;
    }
  }, []);


  useEffect(() => {
    zoomHandler.subscribe(onUpdateCallback);

    return () => {
      zoomHandler.unsubscribe(onUpdateCallback);
    };
  }, []);

  const saveHeaderRef = useCallback((ref: HTMLTableSectionElement) => {
    headerRef.current = ref;
    onUpdateCallback(zoomHandler.getContext());
  }, [headerRef, onUpdateCallback]);

  const saveBodyRef = useCallback((ref: HTMLTableSectionElement) => {
    bodyRef.current = ref;
    onUpdateCallback(zoomHandler.getContext());
  }, [bodyRef, onUpdateCallback]);

  const groupsToRender = useMemo(() => {
    const sortedGroups = groups.sort((a, b) => a.orderIndex - b.orderIndex);
    const result = [];
    const groupsTree = new Array<DrawingsGeometryGroup>();
    const groupChildren = new Map<string, DrawingsGeometryGroup[]>();
    for (const group of sortedGroups) {
      let currentGroupChildren = groupChildren.get(group.id);
      if (!currentGroupChildren) {
        currentGroupChildren = [];
        groupChildren.set(group.id, currentGroupChildren);
      }
      if (group.parentId) {
        if (!groupChildren.has(group.parentId)) {
          groupChildren.set(group.parentId, []);
        }
        groupChildren.get(group.parentId).push(group);
      } else {
        groupsTree.push({ ...group });
      }
    }

    const reversedTree = groupsTree.reverse();

    while (reversedTree.length) {
      const group = reversedTree.pop();
      if (group.measurements && group.measurements.length) {
        result.push(group);
      }
      const reversedChildren = groupChildren.get(group.id).reverse();
      if (reversedChildren) {
        arrayUtils.extendArray(reversedTree, reversedChildren);
      }
    }

    return result;
  }, [groups]);


  return (
    <Styled.Content>
      <table ref={saveTableRef}>
        <DrawingsUserAnnotationLegendHeader
          isImperial={isImperial}
          config={headerConfig}
          ref={saveHeaderRef}
        />
        <Styled.Body ref={saveBodyRef}>
          {
            groupsToRender.map(group => (
              <DrawingsUserAnnotationLegendItem
                headerConfig={headerConfig}
                key={group.id}
                groupInfo={group}
                calculateMeasuresAndCache={getGroupMeasures}
                zoomHandler={zoomHandler}
              />
            ))
          }
        </Styled.Body>
      </table>
    </Styled.Content>
  );
};
