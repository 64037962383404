import autobind from 'autobind-decorator';
import * as paper from 'paper';
import { ConstantFunctions } from 'common/constants/functions';

import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { DrawingMarkShapes } from '../../constants/drawing-styles';
import { DrawingContextObserver } from '../../drawings-contexts';
import { DrawingsGeometryEntityState } from '../../enums/drawings-geometry-entity-state';
import { DrawingsRenderParams } from '../../interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from '../../interfaces/drawing-text-render-parameters';
import { DrawingsPaperColorInfo } from '../../interfaces/drawings-canvas-context-props';
import {
  DrawingsAllowedPathType,
  DrawingsCountGeometry,
  DrawingsSimplifiedBoundingRect,
} from '../../interfaces/drawings-geometry';
import { DrawingsGeometryStyle } from '../../interfaces/drawings-geometry-style';
import { DrawingsMeasureCount } from '../../interfaces/drawings-measures';
import { DrawingsPaperUtils } from '../../utils/drawings-paper-utils';
import { DrawingsGeometryDragEventHelper } from '../drawings-helpers/drawings-geometry-drag-event-helper';
import { ShapeFactory } from '../interfaces';
import {
  DrawingsAddRemovePointResults,
  DrawingsGeometryEntityContinuable,
  DrawingsGeometryEntityModifiable,
  ModifiableEntityConfig,
} from './base';
import { DrawingsGeometryEntityCountMark } from './count-mark/drawings-geometry-entity-count-mark';

export interface DrawingsGeometryEntityCountConfig extends ModifiableEntityConfig {
  textRenderParamsObserver: DrawingContextObserver<MeasuresViewSettings>;
  geometry: DrawingsCountGeometry;
  layer: paper.Layer | paper.Group;
  shapeFactory: ShapeFactory;
  point: paper.Point;
  getPointInfo: (pointId: string) => paper.Point;
  tryRemovePoint: (pointId: string, e: PaperMouseEvent) => void;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
}

export class DrawingsGeometryEntityCount
  extends DrawingsGeometryEntityModifiable<DrawingsGeometryEntityCountConfig, DrawingsGeometryEntityCountMark>
  implements DrawingsGeometryEntityContinuable<DrawingsCountGeometry, DrawingsMeasureCount> {

  private _continueEnabled: boolean;

  /* paper data */
  private _group: paper.Group;
  private _path: paper.Path;

  constructor(config: DrawingsGeometryEntityCountConfig) {
    super(config);
    this._group = new paper.Group();
    this.renderMarks();
    this._group.addTo(this._config.layer);

    this._config.textRenderParamsObserver.subscribe(this.updateMarksTextRenderParams);
    this._config.renderParamsContextObserver.subscribe(this.updateMarksRenderParams);
  }


  public destroy(): void {
    this._config.renderParamsContextObserver.unsubscribe(this.updateMarksRenderParams);
    this._config.textRenderParamsObserver.unsubscribe(this.updateMarksTextRenderParams);
    this._points.forEach(x => x.destroy());
    this._points.clear();
    if (this._path) {
      this._path.remove();
      this._path = null;
    }
  }

  public get bounds(): DrawingsSimplifiedBoundingRect {
    const allXs = [];
    const allYs = [];
    for (const value of this._points.values()) {
      const [x, y] = value.position;
      allXs.push(x);
      allYs.push(y);
    }
    return {
      left: Math.min(...allXs),
      top: Math.min(...allYs),
      right: Math.max(...allXs),
      bottom: Math.max(...allYs),
    };
  }

  public set continueEnabled(value: boolean) {
    this._continueEnabled = value;
    if (value) {
      if (!this._path) {
        this.applyState(DrawingsGeometryEntityState.Selected);
      }
      this._path.add(this._path.lastSegment.point);
    } else if (this._path) {
      this._path.removeSegment(this._path.segments.length - 1);
      this.applyState(this.state);
    }
  }

  public override canSplit(): boolean {
    return this._config.geometry.points.length > 1 && this.selectedPointsIds.length > 0;
  }

  public updateContinueTempPoint(point: paper.Point): void {
    this._path.lastSegment.point = point;
  }

  public addPoints(
    _line: [string, string],
    [pointId]: string[],
  ): DrawingsAddRemovePointResults<DrawingsCountGeometry, DrawingsMeasureCount> {
    this._config.geometry = {
      ...this._config.geometry,
      points: this._config.geometry.points.concat(pointId),
    };
    this.renderMark(pointId, this._config.geometry.points.length, true);
    return {
      geometry: this._config.geometry,
      removedLines: [],
      newLines: {},
      measures: this.getMeasures(),
    };
  }

  public updatePointShapes(value: DrawingMarkShapes): void {
    this._config.geometry.shape = value;
    this._points.forEach(x => x.shape = value);
  }

  public getPath(): DrawingsAllowedPathType {
    return null;
  }

  public getGeometry(): DrawingsCountGeometry {
    return this._config.geometry;
  }

  public updateLabel(): DrawingsMeasureCount {
    return this.getMeasures();
  }

  public updateGeometry(geometry: DrawingsCountGeometry): DrawingsMeasureCount {
    this._config.geometry = geometry;
    this._points.forEach(x => x.destroy());
    this._points.clear();
    this.renderMarks(true);
    return this.getMeasures();
  }

  public removePoints(pointsIds: string[]): DrawingsAddRemovePointResults<DrawingsCountGeometry, DrawingsMeasureCount> {

    const pointsSet = new Set(pointsIds);
    const points = [];
    const indicesForRemove = [];
    if (this._path) {
      this._path.removeSegments();
    }
    for (let i = 0; i < this._config.geometry.points.length; i++)  {
      const pointId = this._config.geometry.points[i];
      if (pointsSet.has(pointId)) {
        this._points.get(pointId).destroy();
        this._points.delete(pointId);
        indicesForRemove.push(i);
      } else {
        points.push(pointId);
        if (this._path) {
          this._path.add(this._points.get(pointId).position);
        }
      }
    }
    this._config.geometry = {
      ...this._config.geometry,
      points,
    };
    points.forEach((x, i) => this._points.get(x).order = i + 1);
    if (this._path && this._continueEnabled) {
      this._path.add(this._path.lastSegment.point);
    }
    return {
      geometry: this._config.geometry,
      measures: this.getMeasures(),
      removedLines: [],
      newLines: {},
    };
  }

  public isExistsInRect(rect: paper.Rectangle): boolean {
    for (const mark of this._points.values()) {
      if (mark.isExistsInRect(rect)) {
        return true;
      }
    }
    return false;
  }

  public updatePointsInEntity(pointIds: string[]): DrawingsMeasureCount {
    for (const pointId of pointIds) {
      const point = this._points.get(pointId);
      point.paperPosition = this._config.getPointInfo(pointId);
      if (this._path) {
        this._path.segments[point.order - 1].point = point.paperPosition;
      }
    }
    return this.getMeasures();
  }

  public getMeasures(): DrawingsMeasureCount {
    return {
      count: this._config.geometry.points.length,
      pointsCount: this._config.geometry.points.length,
    };
  }

  public canRemovePoints(ids: string[]): boolean {
    return this._config.geometry.points.length - ids.length > 0;
  }

  @autobind
  protected onPointSelect(id: string, e: PaperMouseEvent): void {
    ConstantFunctions.stopEvent(e);
    if (this.state === DrawingsGeometryEntityState.Modify) {
      super.onPointSelect(id, e);
    } else {
      this._config.onSelect(this.id, e);
    }
  }

  protected override applyStyle<P extends keyof DrawingsGeometryStyle>(
    field: P,
    value: DrawingsGeometryStyle[P],
  ): void {
    if (field === 'shape') {
      this._config.geometry.shape = value as DrawingMarkShapes;
      this._points.forEach(x => x.shape = value as DrawingMarkShapes);
    }
  }

  protected override changeColor(value: DrawingsPaperColorInfo, colorCode: string): void {
    super.changeColor(value, colorCode);
    this._points.forEach(x => x.changeColor(value.stroke));
    if (this._path) {
      this._path.strokeColor = value.stroke;
    }
  }

  protected applyState(state: DrawingsGeometryEntityState): void {
    let modifyEnabled: boolean = false;
    if (state === DrawingsGeometryEntityState.Default) {
      if (this._path) {
        this._path.remove();
        this._path = null;
      }
    } else {
      this.renderPath();
      modifyEnabled = state === DrawingsGeometryEntityState.Modify;
      if (modifyEnabled) {
        this._eventsApplier.enableDrag(true);
      }
    }
    this._points.forEach(x => {
      x.selected = state === DrawingsGeometryEntityState.Selected;
      x.onDoubleClick = modifyEnabled ? undefined : this.pointDoubleClick;
    });
  }


  @autobind
  private updateMarksRenderParams(renderParameters: DrawingsRenderParams): void {
    this._points.forEach(x => x.changeVisualData(renderParameters.zoom));
    if (this._path) {
      this._path.strokeWidth = DrawingsCanvasConstants.countTraceStrokeWidth / renderParameters.zoom;
      this._path.dashArray = [DrawingsCanvasConstants.dashArray * this._path.strokeWidth];
    }
  }

  @autobind
  private updateMarksTextRenderParams(renderParameters: MeasuresViewSettings): void {
    this._points.forEach(x => x.updateRenderParams(renderParameters));
  }

  @autobind
  private renderMarks(withRescale?: boolean): void {
    if (this._path) {
      this._path.removeSegments();
    }
    for (let i = 0; i < this._config.geometry.points.length; i++) {
      const pointId = this._config.geometry.points[i];
      this.renderMark(pointId, i + 1, withRescale);
    }
    if (this._continueEnabled) {
      this._path.add(this._path.lastSegment.point);
    }
  }


  private renderMark(pointId: string, order: number, withRescale?: boolean): void {
    const point = this._config.getPointInfo(pointId);
    if (this._path) {
      if (this._continueEnabled && this._path.segments.length) {
        this._path.lastSegment.point = point;
      }
      this._path.add(point);
    }
    const mark =  new DrawingsGeometryEntityCountMark({
      id: pointId,
      geometry: point,
      layer: this._group,
      color: this._config.color.stroke,
      order,
      onStartDrag: this._config.onStartDrag,
      onDoubleClick: this.pointDoubleClick,
      onSelect: this.onPointSelect,
      getPointInfo: this._config.getPointInfo,
      dragEventsHelper: this._config.dragEventsHelper,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      onMouseEnter: this.onMarkEnter,
      onMouseLeave: this.onMarkLeave,
      cursorHelper: this._config.cursorHelper,
      shapeCreator: this._config.shapeFactory,
      shape: this._config.geometry.shape || DrawingMarkShapes.Circle,
    });
    mark.selected = this.state === DrawingsGeometryEntityState.Selected
    || (this.state === DrawingsGeometryEntityState.Modify && this.selectedPointsIds.includes(pointId));
    if (withRescale) {
      mark.changeVisualData(this._config.renderParamsContextObserver.getContext().zoom);
      mark.updateRenderParams(this._config.textRenderParamsObserver.getContext());
    }
    this._points.set(pointId, mark);
  }

  @autobind
  private onMarkEnter(id: string, e: PaperMouseEvent): void {
    this._config.cursorHelper.hovered = true;
    if (this.state === DrawingsGeometryEntityState.Default) {
      this.state = DrawingsGeometryEntityState.Hover;
    } else if (this.state === DrawingsGeometryEntityState.Modify) {
      if (this._points.get(id).selected) {
        this._config.cursorHelper.hoveredSelected = true;
      }
    }
    if (this._config.onMouseEnter) {
      this._config.onMouseEnter(this.id, e);
    }
  }

  @autobind
  private onMarkLeave(_id: string, e: PaperMouseEvent): void {
    this._config.cursorHelper.hovered = false;
    if (this.state === DrawingsGeometryEntityState.Hover) {
      this.state = DrawingsGeometryEntityState.Default;
    }
    if (this._config.onMouseLeave) {
      this._config.onMouseLeave(this.id, e);
    }
  }

  private renderPath(): void {
    if (this._path) {
      return;
    }
    const zoom = this._config.renderParamsContextObserver.getContext().zoom;
    this._path = new paper.Path(this._config.geometry.points.map(this._config.getPointInfo));
    DrawingsPaperUtils.updatePathStyles(this._path);
    this._path.strokeWidth = DrawingsCanvasConstants.countTraceStrokeWidth / zoom;
    this._path.dashArray = [DrawingsCanvasConstants.dashArray * this._path.strokeWidth];
    this._path.strokeColor = this._config.color.stroke;
    this._path.addTo(this._group);
    this._path.sendToBack();
  }

  @autobind
  private pointDoubleClick(_id: string, e: PaperMouseEvent): void {
    this._config.onDoubleClick(this.id, e);
  }
}
