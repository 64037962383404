import { StringDictionary } from 'common/interfaces/dictionary';
import { UnitModel } from '../../interfaces/data';

export class MaterialUnitHelper {
  private static readonly availableMaterialUnitMeasurementsMap: StringDictionary<boolean | undefined> = {
    ['number']: true,
    ['mass']: true,
    ['distance']: true,
    ['area']: true,
    ['volume']: true,
  };

  public static isMaterialUnitAvailable(unit: UnitModel): boolean {
    return MaterialUnitHelper.isMeasurementAvailable(unit.measurement);
  }

  public static getMaterialUnitInAvailableMeasurementValidator(
    unitMap: Record<number, UnitModel>,
  ): (unitId: number) => string | undefined {
    return (unitId) => {
      const unit = unitMap[unitId];
      if (!MaterialUnitHelper.isMeasurementAvailable(unit && unit.measurement)) {
        const measurementString = Object.keys(MaterialUnitHelper.availableMaterialUnitMeasurementsMap)
          .join(' or ');
        return `Unit should be in ${measurementString} measurement`;
      }
    };
  }

  private static isMeasurementAvailable(measurement: string): boolean {
    return !!MaterialUnitHelper.availableMaterialUnitMeasurementsMap[measurement];
  }
}
