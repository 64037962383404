import { QuantityTakeOffApi } from '../../api/quantity-take-off';
import { ModelType } from '../../interfaces/quantity-take-off';
import { QtoRecord, RecordsUpdateType, SelectUserExtractorResult } from './interfaces';

export const calculatingStatus = 'Calculating...';

const getUpdatedRecord = (
  records: QtoRecord[],
  selectedBimIds: number[],
  key: string,
): QtoRecord[] => {
  const updatedRecords = [];
  records.forEach((record => {
    if (selectedBimIds.includes(record.id as number)) {
      const updatedRecord = {
        id: record.id,
        props: {
          [key]: [calculatingStatus],
        },
      };
      updatedRecords.push(updatedRecord);
    }
  }));
  return updatedRecords;
};

export const handleSelectUserExtractor =
  async (records: QtoRecord[],
    projectId: number,
    modelType: ModelType,
    selectedBimIds: number[],
    extractorValue: SelectUserExtractorResult,
  ): Promise<void> => {
    const updatedRecords = getUpdatedRecord(records, selectedBimIds, extractorValue.extractorKey);
    await QuantityTakeOffApi.updateRecords(
      projectId, modelType, updatedRecords, RecordsUpdateType.StartUpdateUserExtractor);
  };
