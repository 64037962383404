import { QtoTreeTableHelper } from '../column-helper';

export class QtoReportPivotTableIdHelper {
  private static KEY_SEPARATOR: string = '#';

  public static getColumnIdWithPrevPostfix(columnId: string): string {
    return `${columnId}${QtoTreeTableHelper.constants.ID_PREV_POSTFIX}`;
  }

  public static isIdContainDiffPostfix(id: string): boolean {
    return QtoTreeTableHelper.isColumnIdHasDiffPostfix(id);
  }

  public static isIdContainSeparator(id: string): boolean {
    const ids = QtoReportPivotTableIdHelper.splitIds(id);
    return !!ids && ids.length >= 2;
  }


  public static getPivotRowId(groupId: string, columnId: string): string {
    return QtoReportPivotTableIdHelper.concatKeys(groupId, columnId);
  }

  public static getPivotPropertyId(columnId: string, propertyId: string): string {
    return QtoReportPivotTableIdHelper.concatKeys(columnId, propertyId);
  }

  public static getPivotPropertyIdFromPivotRowId(pivotRowId: string, propertyId: string): string {
    const ids = QtoReportPivotTableIdHelper.getIdsFromPivotRowId(pivotRowId);
    if (!ids) {
      return null;
    }

    return QtoReportPivotTableIdHelper.concatKeys(ids.columnId, propertyId);
  }

  public static getIdsFromPivotRowId(id: string): { groupId: string, columnId: string } {
    const ids = QtoReportPivotTableIdHelper.splitIds(id);
    return ids && ids.length === 2
      ? {
        groupId: ids[0],
        columnId: ids[1],
      }
      : null;
  }

  public static getIdsFromPivotPropertyId(id: string): { columnId: string, propertyId: string } {
    const ids = QtoReportPivotTableIdHelper.splitIds(id);
    return ids && ids.length === 2
      ? {
        columnId: ids[0],
        propertyId: ids[1],
      }
      : null;
  }

  private static splitIds(key: string): string[] {
    return key && key.split(QtoReportPivotTableIdHelper.KEY_SEPARATOR);
  }

  private static concatKeys(key1: string, key2: string): string {
    return `${key1}${QtoReportPivotTableIdHelper.KEY_SEPARATOR}${key2}`;
  }
}
