import styled from 'styled-components';

import { NotificationBellStyled } from 'unit-2d-notification/components';

export const PAGE_HEADER_HEIGHT = 60;

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: min-content min-content minmax(10%, 1fr) min-content;
  height: ${PAGE_HEADER_HEIGHT}px;
  width: 100%;
  background-color: ${p => p.theme.color.backgroundRush};

  .page-header__logo {
    display: flex;
    padding: 25px 20px;

    img {
      width: 144px;
      height: 40px;
    }
  }
`;

const IconContainer = styled.div`
  svg {
    height: 40px;

    > path {
      fill: ${p => p.theme.color.mainFont};
    }
  }
`;

const Content = styled.div`
  height: calc(100% - 5px);
  max-height: 100%;
  width: 100%;
`;

const UserMenu = styled.div`
  display: flex;
  align-items: center;
  height: 90px;
  max-height: 100%;
  margin-left: auto;

  ${NotificationBellStyled.IconContainer} {
    margin-right: 20px;
  }
`;

const ProjectMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  position: relative;

  *, & {
    box-sizing: border-box;
  }
`;

export const Styled = {
  Container,
  IconContainer,
  UserMenu,
  Content,
  ProjectMenuContainer,
};
