const prefix = '@viewer/';


const VIEWER_SHOW_HIDE_COMMENTS = `${prefix}SHOW_HIDE_COMMENTS`;
const VIEWER_SHOW_HIDE_TREE = `${prefix}SHOW_HIDE_TREE`;
const VIEWER_SHOW_NEW_COMMENT = `${prefix}SHOW_NEW_COMMENT`;
const VIEWER_GET_COMMENTS = `${prefix}GET_COMMENTS`;
const VIEWER_GET_COMMENTS_SUCCESS = `${prefix}GET_COMMENTS_SUCCESS`;
const VIEWER_CREATE_COMMENT_REQUEST = `${prefix}CREATE_COMMENT_REQUEST`;
const VIEWER_CREATE_COMMENT_SUCCESSED = `${prefix}CREATE_COMMENT_SUCCESSED`;
const VIEWER_GET_DOCUMENT_INFO = `${prefix}GET_DOCUMENT_INFO`;
const VIEWER_GO_TO_COMMENT = `VIWER_GO_TO_COMMENT`;
const VIEWER_BACK_TO_COMMENTS = `VIWER_BACK_TO_COMMENTS`;
const VIEWER_ON_MARKER_MODE = `${prefix}ON_MARKER_MODE`;
const VIEWER_MARKER_POSITION = `${prefix}MARKER_POSITION`;
const VIEWER_ON_ADD_SUBCOMMENT  = `${prefix}ON_ADD_SUBCOMMENT`;
const VIEWER_ADD_SUBCOMMENT_SUCCESSED = `${prefix}ADD_SUBCOMMENT_SUCCESSED`;
const VIEWER_GET_DOCUMENT_INFO_SUCCESS = `${prefix}GET_DOCUMENT_INFO_SUCCESSED`;
const VIEWER_TREE_ELEMENT_EXPAND = `${prefix}TREE_ELEMENT_EXPAND`;
const VIEWER_HIDE_ELEMENTS = `${prefix}HIDE_ELEMENTS`;
const VIEWER_SHOW_ELEMENTS = `${prefix}SHOW_ELEMENTS`;
const VIEWER_HIDE_ELEMENTS_COMMIT = `${prefix}HIDE_ELEMENTS_COMMIT`;
const VIEWER_SHOW_ELEMENTS_COMMIT = `${prefix}SHOW_ELEMENTS_COMMIT`;
const VIEWER_RUN_SEARCH = `${prefix}RUN_SEARCH`;
const VIEWER_COMMIT_SEARCH = `${prefix}COMMIT_SEARCH`;
const VIEWER_END_SEARCH = `${prefix}END_SEARCH`;
const VIEWER_SELECT_ITEM = `${prefix}SELECT_ITEM`;
const VIEWER_SELECT_ITEM_COMMIT = `${prefix}SELECT_ITEM_COMMIT`;
const VIEWER_ON_OF_CLIPBOX = `${prefix}ON_OF_CLIPBOX`;
const VIEWER_GHOST_ELEMENTS = `${prefix}GHOST_ELEMENTS`;
const VIEWER_GHOST_COMMIT = `${prefix}GHOST_COMMIT`;
const VIEWER_CHANGE_RENDER_MODE = `${prefix}CHANGE_RENDER_MODE`;
const VIEWER_SELECT_BY_ID = `${prefix}SELECT_BY_ID`;
const VIEWER_SELECT_BY_ID_COMMIT = `${prefix}SELECT_BY_ID_COMMIT`;
const VIEWER_REMOVE_COMMENT = `${prefix}REMOVE_COMMENT`;
const VIEWER_REMOVE_COMMENT_SUCCESS = `${prefix}REMOVE_COMMENT_SUCCESS`;
const VIEWER_MAP_PARENTS = `${prefix}MAP_PARENTS`;
const VIEWER_DROP_ALL_INFO = `${prefix}DROP_ALL_INFO`;
const VIEWER_RESOLVE_COMMENT = `${prefix}RESOLVE_COMMENT`;
const VIEWER_UPDATE_STATUS_COMMENT = `${prefix}UPDATE_STATUS_COMMENT`;
const VIEWER_UPDATE_CURRENT_COMMENT_STATUS = `${prefix}UPDATE_CURRENT_COMMENT_STATUS`;
const VIEWER_FILTER_WITH_RESOLVED = `${prefix}FILTER_WITH_RESOLVED`;
const VIEWER_SET_CLIPBOX_MODE = `${prefix}SET_CLIPBOX_MODE`;
const VIEWER_SET_IDS_FOR_ITEM = `${prefix}SET_IDS_FOR_ITEM`;
const VIEWER_APPROVE_NODE = `${prefix}APPROVE_NODE`;
const VIEWER_APPROVE_STATE = `${prefix}APPROVE_STATE`;
const VIEWER_ADD_TIME_MARKER = `${prefix}ADD_TIME_MARKER`;
const VIEWER_SET_CONTEXT_POSITION = `${prefix}SET_CONTEXT_POSITION`;
const VIEWER_CLOSE_CONTEXT = `${prefix}CLOSE_CONTEXT`;
const VIEWER_SELECT_COMMENT = `${prefix}SELECT_COMMENT`;
const VIEWER_ADD_SELECT_ITEM = `${prefix}ADD_SELECT_ITEM`;
const VIEWER_DESELECT_ITEM = `${prefix}DESELECT_ITEM`;
const VIEWER_SET_MOBILE = `${prefix}SET_MOBILE`;
const VIEWER_READ_COMMENT = `${prefix}READ_COMMENT`;
const VIEWER_GO_TO_COMMENT_BY_ID = `${prefix}GO_TO_COMMENT_BY_ID`;
const VIEWER_SET_PANELS_DIMANSION = `${prefix}SET_PANELS_DIMANSION`;

export {
  VIEWER_SHOW_HIDE_COMMENTS,
  VIEWER_SHOW_HIDE_TREE,
  VIEWER_SHOW_NEW_COMMENT,
  VIEWER_GET_COMMENTS,
  VIEWER_GET_COMMENTS_SUCCESS,
  VIEWER_CREATE_COMMENT_REQUEST,
  VIEWER_CREATE_COMMENT_SUCCESSED,
  VIEWER_GET_DOCUMENT_INFO,
  VIEWER_GO_TO_COMMENT,
  VIEWER_BACK_TO_COMMENTS,
  VIEWER_ON_MARKER_MODE,
  VIEWER_MARKER_POSITION,
  VIEWER_ON_ADD_SUBCOMMENT,
  VIEWER_ADD_SUBCOMMENT_SUCCESSED,
  VIEWER_GET_DOCUMENT_INFO_SUCCESS,
  VIEWER_TREE_ELEMENT_EXPAND,
  VIEWER_HIDE_ELEMENTS,
  VIEWER_SHOW_ELEMENTS,
  VIEWER_HIDE_ELEMENTS_COMMIT,
  VIEWER_SHOW_ELEMENTS_COMMIT,
  VIEWER_RUN_SEARCH,
  VIEWER_COMMIT_SEARCH,
  VIEWER_END_SEARCH,
  VIEWER_SELECT_ITEM,
  VIEWER_SELECT_ITEM_COMMIT,
  VIEWER_ON_OF_CLIPBOX,
  VIEWER_GHOST_ELEMENTS,
  VIEWER_GHOST_COMMIT,
  VIEWER_CHANGE_RENDER_MODE,
  VIEWER_SELECT_BY_ID,
  VIEWER_SELECT_BY_ID_COMMIT,
  VIEWER_REMOVE_COMMENT,
  VIEWER_REMOVE_COMMENT_SUCCESS,
  VIEWER_MAP_PARENTS,
  VIEWER_DROP_ALL_INFO,
  VIEWER_RESOLVE_COMMENT,
  VIEWER_UPDATE_STATUS_COMMENT,
  VIEWER_UPDATE_CURRENT_COMMENT_STATUS,
  VIEWER_FILTER_WITH_RESOLVED,
  VIEWER_SET_CLIPBOX_MODE,
  VIEWER_SET_IDS_FOR_ITEM,
  VIEWER_APPROVE_NODE,
  VIEWER_APPROVE_STATE,
  VIEWER_ADD_TIME_MARKER,
  VIEWER_SET_CONTEXT_POSITION,
  VIEWER_CLOSE_CONTEXT,
  VIEWER_SELECT_COMMENT,
  VIEWER_ADD_SELECT_ITEM,
  VIEWER_DESELECT_ITEM,
  VIEWER_SET_MOBILE,
  VIEWER_READ_COMMENT,
  VIEWER_GO_TO_COMMENT_BY_ID,
  VIEWER_SET_PANELS_DIMANSION,
};
