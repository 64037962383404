import { push } from 'connected-react-router';
import dotProp from 'dot-prop-immutable';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { NumberDictionary } from 'common/interfaces/dictionary';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { AppUrls } from '../../../routes/app-urls';
import { ActivityCategoryType } from '../../../units/databases/enums';
import { ActivityVariantActivityAssignmentInfoModel } from '../../../units/databases/interfaces/data';
import { CodeGroupForm } from '../../../units/databases/interfaces/rest-data';
import { RestFormMapper } from '../../../units/databases/utils/rest-form-mapper';
import { ActivitiesApi } from '../../databases/api/activities';
import { ActivityAssignmentActions } from '../actions/creators/activity-assignment';
import { ActivityAssignmentActionTypes } from '../actions/types/activity-assignment';
import { ActivityAssignmentApi } from '../api/activity-assignment';
import { ViewerApi } from '../api/viewer';
import { ActivityAssignmentAgregatedData } from '../interfaces/activity-assignment/activity-assignment-agregated-data';
import {
  ActivityAssignmentMaterialVariant,
} from '../interfaces/activity-assignment/activity-assignment-material-variant';
import { ActivityAssignmentResponse } from '../interfaces/activity-assignment/activity-assignment-response-data';
import { ActivityAssignmentState } from '../interfaces/activity-assignment/activity-assignment-state';
import { ActivityAssignmentSubVariant } from '../interfaces/activity-assignment/activity-assignment-sub-variant';
import { ActivityAssignmentActivity } from '../interfaces/activity-assignment/activity-assignmnet-activity';
import { ActivityAssignmentVariantData } from '../interfaces/activity-assignment/activity-assignmnet-variant-data';
import { Project } from '../interfaces/project';
import { RevitTreeFullInfo } from '../interfaces/revit-tree-full-info';
import { getCurrentProject } from '../selectors';
import { ActivityAssignmentUtils } from '../utils/activity-assignment-utils';

function* getData(): SagaIterator {
  try {
    const project: Project = yield selectWrapper(getCurrentProject);
    const data: ActivityAssignmentResponse = yield call(
      ActivityAssignmentApi.getData,
      project.id,
    );
    const preparedData: ActivityAssignmentAgregatedData =
      data.tree && data.tree.length > 0 && data.works && data.works.length > 0
        ? ActivityAssignmentUtils.serverDataMapper(data)
        : {
          statistic: null,
          works: [],
          tree: [],
          workLinks: [],
          badIds: [],
          engineIds: [],
          databases: data.databases,
          usedOldCmtdbVersion: true,
        };
    yield put(ActivityAssignmentActions.loadDataSucceeded(preparedData));
  } catch (error) {
    console.error('activity assignment: get data failed', error);
    yield put(ActivityAssignmentActions.loadDataFailed());
  }
}

function* getWork(action: ActionWith<number>): SagaIterator {
  try {
    const project: Project = yield selectWrapper(getCurrentProject);
    const work: ActivityAssignmentActivity = yield call(
      ActivityAssignmentApi.getWork,
      project.id,
      action.payload,
    );
    yield put(ActivityAssignmentActions.loadWorkSucceeded(work));
  } catch (error) {
    console.error('activity assignment: get work failed', error, action.payload);
  }
}

function* assignActivity(): SagaIterator {
  try {
    const [activityAssignment, project]: [ActivityAssignmentState, Project] = yield selectWrapper(
      state => [state.activityAssignment, getCurrentProject(state)],
    );
    const variants = activityAssignment.selectedWorks as ActivityAssignmentVariantData[];
    let selectedWork: ActivityAssignmentActivity = null;
    if (activityAssignment.selectedPath.split('.').length > 1) {
      const node = dotProp.get(activityAssignment, activityAssignment.selectedPath);
      selectedWork = activityAssignment.works.slice(node.start, node.end)[0];
    } else {
      selectedWork = activityAssignment.works[activityAssignment.selectedPath];
    }
    selectedWork.data.variants = variants;
    yield call(ActivityAssignmentApi.saveWork, project.id, selectedWork);
    yield put(ActivityAssignmentActions.assignActivitySucceeded(selectedWork));
  } catch (error) {
    console.error('activity assignment: assign activity failed', error);
  }
}

function* getRevitData(): SagaIterator {
  try {
    const bimFull: RevitTreeFullInfo = yield call(ViewerApi.loadFullBimInfo);
    const viewerData = ActivityAssignmentUtils.getPropertyIdMapAndLayerIdMap(bimFull);
    yield put(ActivityAssignmentActions.saveRevitData(viewerData));
  } catch (error) {
    console.error('activity assignment: get revit data failed', error);
  }
}

function* getRevitDataByProjectId({ payload: projectId }: ActionWith<number>): SagaIterator {
  try {
    const bimFull: RevitTreeFullInfo = yield call(ViewerApi.loadFullBimInfo);
    const viewerData = ActivityAssignmentUtils.getPropertyIdMapAndLayerIdMap(bimFull);
    yield put(ActivityAssignmentActions.saveRevitData(viewerData));
  } catch (error) {
    console.error('activity assignment: get revit data by project id failed', error, { projectId });
  }
}

function* openDataBasePage(action: ActionWith<number>): SagaIterator {
  try {
    const {
      selectedWork,
      projectId,
      databaseId,
    }: {
      selectedWork: ActivityAssignmentActivity,
      projectId: number,
      databaseId: number,
    } = yield selectWrapper(_ => {
      return {
        selectedWork: _.activityAssignment.selectedWork,
        projectId: _.projects.currentProject ? _.projects.currentProject.id : null,
        databaseId: _.activityAssignment.databases[0],
      };
    });

    const activitiesPageUrl = AppUrls.plan.project.validation.activityAssignmentDbActivities.url({
      projectId: projectId.toString(),
      endElementId: selectedWork.id.toString(),
      dbId: databaseId.toString(),
    });
    yield put(push(activitiesPageUrl));
  } catch (error) {
    console.error('activity assignment: open data base page failed', error, action.payload);
  }
}

function* assignModalities(action: ActionWith<ActivityAssignmentActivity>): SagaIterator {
  try {
    const project: Project = yield selectWrapper(getCurrentProject);
    const variantsIdsValue: NumberDictionary<ActivityAssignmentSubVariant> = {};
    const activity = action.payload;
    for (const act of activity.data.variants) {
      for (const modality of act.subVariants) {
        if (modality.dataBaseId && !modality.materials) {
          variantsIdsValue[modality.dbModalityId] = modality;
        }
      }
    }
    const materials: NumberDictionary<ActivityAssignmentMaterialVariant> =
      yield call(ActivityAssignmentApi.getActivitiesMaterials, project.id, Object.keys(variantsIdsValue));
    for (const [key, value] of Object.entries(materials) as Array<[string, ActivityAssignmentMaterialVariant]>) {
      variantsIdsValue[key].materials = value;
    }
    yield call(ActivityAssignmentApi.saveWork, project.id, action.payload);
    yield put(ActivityAssignmentActions.updateWork(activity));
    if (yield selectWrapper(_ => _.activityAssignment.selectedPath)) {
      yield put(ActivityAssignmentActions.assignActivity());
    }
  } catch (error) {
    console.error('activity assignment: assign modalities failed', error, action.payload);
  }
}

function* createActivitiesCodeGroup(action: ActionWith<ActivityVariantActivityAssignmentInfoModel[]>): SagaIterator {
  function * updateActivity(databaseId: number, activityId: number, codeGroup: CodeGroupForm): SagaIterator {
    try {
      const isSameCodeGroupe = (first: CodeGroupForm, second: CodeGroupForm): boolean => {
        return first.nrm1Id === second.nrm1Id &&
          first.nrm2Id === second.nrm2Id &&
          first.uniProductId === second.uniProductId &&
          first.uniSystemId === second.uniSystemId &&
          first.category === second.category &&
          first.family === second.family &&
          first.elementType === second.elementType &&
          first.comment === second.comment;
      };

      const activity = yield call(ActivitiesApi.getActivity, databaseId, activityId);
      const functions = yield selectWrapper(x => x.database.currentDatabase.functions);
      const form = RestFormMapper.GetActivityForm(activity, functions);
      if (!form.codeGroups.find(x => isSameCodeGroupe(x, codeGroup))) {
        form.codeGroups.push(codeGroup);
        yield call(ActivitiesApi.updateActivity, databaseId, activityId, form);
      }
    } catch (error) {
      console.error('activity assignment: update activity failed', error, { databaseId, activityId, codeGroup });
    }
  }

  try {
    const variants = action.payload;
    if (!variants || !variants.length) {
      return;
    }

    const categories = yield selectWrapper(_ => {
      return  _.database.currentDatabase.activityListing.defaultValues.categories;
    });
    const codeGroupForm: CodeGroupForm = {
      id: 0,
      nrm1Id: categories[ActivityCategoryType.Nrm1] && categories[ActivityCategoryType.Nrm1].id,
      nrm2Id: categories[ActivityCategoryType.Nrm2] && categories[ActivityCategoryType.Nrm2].id,
      uniProductId: categories[ActivityCategoryType.UniProduct] && categories[ActivityCategoryType.UniProduct].id,
      uniSystemId: categories[ActivityCategoryType.UniSystem] && categories[ActivityCategoryType.UniSystem].id,
    };

    if (!codeGroupForm.nrm1Id && !codeGroupForm.nrm2Id && !codeGroupForm.uniProductId && !codeGroupForm.uniSystemId) {
      return;
    }

    const activityMap = {};
    variants.forEach(x => activityMap[`${x.databaseId}/${x.activityId}`] = {
      databaseId: x.databaseId,
      activityId: x.activityId,
    });

    yield all(Object.keys(activityMap)
      .map(key => call(updateActivity, activityMap[key].databaseId, activityMap[key].activityId, codeGroupForm)),
    );
  } catch (error) {
    console.error('activity assignment: create activities code group failed', error, action.payload);
  }
}

export function* activityAssignmentSagas(): SagaIterator {
  yield takeLatest(ActivityAssignmentActionTypes.LOAD_DATA, getData);
  yield takeLatest(ActivityAssignmentActionTypes.LOAD_WORK, getWork);
  yield takeLatest(ActivityAssignmentActionTypes.ASSIGN_WORKS, assignActivity);
  yield takeLatest(ActivityAssignmentActionTypes.GET_REVIT_DATA, getRevitData);
  yield takeLatest(
    ActivityAssignmentActionTypes.GET_REVIT_DATA_BY_PROJECT_ID,
    getRevitDataByProjectId,
  );
  yield takeLatest(ActivityAssignmentActionTypes.OPEN_DATABASE_PAGE, openDataBasePage);
  yield takeLatest(ActivityAssignmentActionTypes.ASSIGN_MODALITIES, assignModalities);
  yield takeLatest(ActivityAssignmentActionTypes.CREATE_ACTIVITIES_CODE_GROUP, createActivitiesCodeGroup);
}
