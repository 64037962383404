import { useCallback } from 'react';
import { MetricNames, useAnalytics } from 'utils/posthog';

export function useFolderToCell(onFolderToCell: (type: string) => void): (type: string) => void {
  const { sendEvent } = useAnalytics();
  return useCallback(
    (type: string) => {
      onFolderToCell(type);
      sendEvent(MetricNames.transferMeasurements.folderToCell, { name: type });
    },
    [sendEvent, onFolderToCell],
  );
}


export function useMoveToCell(
  onMoveToCell: (instancesIds: string[], type: string) => void,
  instancesIds: string[],
): (type: string) => void {
  const { sendEvent } = useAnalytics();
  return useCallback(
    (type: string) => {
      onMoveToCell(instancesIds, type);
      sendEvent(MetricNames.transferMeasurements.moveToCell, { type });
    },
    [sendEvent, onMoveToCell, instancesIds],
  );
}
