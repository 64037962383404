import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import { FieldArray, WrappedFieldProps } from 'redux-form';

import './database-activity-classification.scss';

import { KreoButton, KreoDialogActions } from 'common/UIKit';
import { CodeGroupModel } from '../../interfaces/data';
import { DatabaseEntityNamedHeader } from '../database-entity-named-header';
import {
  CODE_GROUP_SELECTOR_DIALOG_NAME,
  CodeGroupSelector,
  CodeGroupSelectorProps,
} from './database-activity-code-group-selector';


interface ReduxActions {
  showDialog: (dialogName: string) => void;
}

export interface DatabaseActivityClassificationProps {
  name: string;
  error?: string;
  readonly: boolean;
  codeGroups: CodeGroupModel[];
  onClassificationChange: (codeGroups: CodeGroupModel[]) => void;
}

interface Props extends ReduxActions, DatabaseActivityClassificationProps {}


export class ClassificationComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const codeGroupsCount = this.props.codeGroups
      ? this.props.codeGroups.length
      : 0;
    const description  = codeGroupsCount
      ? `${codeGroupsCount} Code Group${codeGroupsCount !== 1 ? 's' : ''}`
      : 'No Code Groups';
    return (
      <div
        className={classNames('database-activity-classification', {
          'database-activity-classification--invalid': !!this.props.error,
        })}
      >
        <DatabaseEntityNamedHeader name='Classification'/>
        <div className='database-activity-classification__content'>
          <div
            className={classNames('database-activity-classification__code-groups', {
              'database-activity-classification__code-groups--empty': !codeGroupsCount,
            })}
          >
            {description}
          </div>
          <KreoButton
            size='medium'
            htmlButtonType='button'
            onClick={this.openDialog}
            caption={this.props.readonly ? 'View' : 'Edit'}
          />
        </div>
        <FieldArray<CodeGroupSelectorProps>
          name={this.props.name}
          component={CodeGroupSelector}
          codeGroups={this.props.codeGroups}
          onChangeCodeGroups={this.onChange}
        />
      </div>
    );
  }

  @autobind
  private openDialog(): void {
    this.props.showDialog(CODE_GROUP_SELECTOR_DIALOG_NAME);
  }

  @autobind
  private onChange(codeGroups: CodeGroupModel[]): void {
    this.props.onClassificationChange(codeGroups);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    showDialog: (dialogName: string) => dispatch(KreoDialogActions.openDialog(dialogName)),
  };
};

export const DatabaseActivityClassification = connect(null, mapDispatchToProps)(ClassificationComponent);


export const DatabaseActivityClassificationField: React.FC<DatabaseActivityClassificationProps> = (
  props: WrappedFieldProps & DatabaseActivityClassificationProps,
): JSX.Element => {
  const {
    input: { name },
    meta: { submitFailed, error },
  } = props;

  return (
    <DatabaseActivityClassification
      name={name}
      error={submitFailed && error}
      codeGroups={props.codeGroups}
      onClassificationChange={props.onClassificationChange}
      readonly={props.readonly}
    />
  );
};
