const prefix = '@drawings-wizzard';

export const WizzardToolsActionTypes = {
  SET_SETTINGS: `${prefix}/SET_SETTINGS`,
  SET_WIZZARD_STATE: `${prefix}/SET_WIZZARD_STATE`,
  BATCH_UPDATE_SETTINGS: `${prefix}/BATCH_UPDATE_SETTINGS`,
  DROP_WIZZARD_STATE: `${prefix}/DROP_DROPPER_STATE`,
  DROP_FINDER_SELECTION_AREA: `${prefix}/DROP_FINDER_SELECTION_AREA`,
  SET_SELECTION_AREA: `${prefix}/SET_SELECTION_AREA`,
  SET_SELECTED_GEOMETRY: `${prefix}/SET_SELECTED_GEOMETRY`,
  ADD_GEOMETRY_TO_REMOVE: `${prefix}/ADD_GEOMETRY_TO_REMOVE`,
  APPLY_REMOVE_GEOMETRIES: `${prefix}/APPLY_REMOVE_GEOMETRIES`,
  DROP_REMOVE_GEOMETRIES: `${prefix}/DROP_REMOVE_GEOMETRIES`,
  RUN_FINDER: `${prefix}/RUN_FINDER`,
  SET_RESULT: `${prefix}/SET_RESULT`,
  SET_ERROR: `${prefix}/SET_ERROR`,
  SET_FINDER_SELECTION_VISIBILITY: `${prefix}/SET_FINDER_SELECTION_VISIBILITY`,
  GET_PDF_STATISTIC: `${prefix}/GET_PDF_STATISTIC`,
  SET_PDF_STATISTIC: `${prefix}/SET_PDF_STATISTIC`,
  RUN_DROPPER: `${prefix}/RUN_DROPPER`,
  CHANGE_RESULT_HIDE_STATUS: `${prefix}/CHANGE_RESULT_HIDE_STATUS`,
};
