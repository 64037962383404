import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { ActivityGroupingStepPage, ActivityGroupRestData } from '../../interfaces';
import { StepActionTypes } from '../types';

function setActivityGroupingStepPage(page: ActivityGroupingStepPage): ActionWith<ActivityGroupingStepPage> {
  return {
    type: StepActionTypes.SET_ACTIVITY_GROUPING_STEP_PAGE,
    payload: page,
  };
}


function updateActivityGrouping(
  scenarioId: number,
  recalculate: boolean,
): ActionWith<{ scenarioId: number, recalculate: boolean }> {
  return {
    type: StepActionTypes.UPDATE_ACTIVITY_GROUPING,
    payload: {
      scenarioId,
      recalculate,
    },
  };
}

function updateMacroSequence(scenarioId: number): ActionWith<number> {
  return {
    type: StepActionTypes.UPDATE_MACRO_SEQUENCE,
    payload: scenarioId,
  };
}

function loadActivityGroups(scenarioId: number): ActionWith<number> {
  return {
    type: StepActionTypes.LOAD_ACTIVITY_GROUPING,
    payload: scenarioId,
  };
}

function loadActivityGroupingComplete(
  model: ActivityGroupRestData.ActivityGroupingResponse,
): ActionWith<ActivityGroupRestData.ActivityGroupingResponse> {
  return {
    type: StepActionTypes.LOAD_ACTIVITY_GROUPING_SUCCEEDED,
    payload: model,
  };
}

function loadMacroSequence(scenarioId: number): ActionWith<number> {
  return {
    type: StepActionTypes.LOAD_MACRO_SEQUENCE,
    payload: scenarioId,
  };
}

function loadMacroSequenceComplete(
  model: ActivityGroupRestData.MacroSequenceResponse,
): ActionWith<ActivityGroupRestData.MacroSequenceResponse> {
  return {
    type: StepActionTypes.LOAD_MACRO_SEQUENCE_SUCCEEDED,
    payload: model,
  };
}

function updateActivityGroupingComplete(scenarioId: number): ActionWith<number> {
  return {
    type: StepActionTypes.UPDATE_ACTIVITY_GROUPING_SUCCEEDED,
    payload: scenarioId,
  };
}


function updateMacroSequenceComplete(scenarioId: number): ActionWith<number> {
  return {
    type: StepActionTypes.UPDATE_MACRO_SEQUENCE_SUCCEEDED,
    payload: scenarioId,
  };
}

function setActiveWorkPackage(
  workPackageId: number | null,
): ActionWith<number | null> {
  return {
    type: StepActionTypes.SET_ACTIVE_WORK_PACKAGE,
    payload: workPackageId,
  };
}

function setActiveActivityGroupOrUngroupedActivity(
  activityGroupId: number | null,
): ActionWith<number | null> {
  return {
    type: StepActionTypes.SET_ACTIVE_ACTIVITY_GROUP_OR_UNGROUPED_ACTIVITY,
    payload: activityGroupId,
  };
}

function setActiveCategory(
  categoryId: number | null,
): ActionWith<number | null> {
  return {
    type: StepActionTypes.SET_ACTIVE_CATEGORY,
    payload: categoryId,
  };
}

function setActiveActivityOrUngroupedActivity(
  activityId: number | null,
): ActionWith<number | null> {
  return {
    type: StepActionTypes.SET_ACTIVE_ACTIVITY_OR_UNGROUPED_ACTIVITY,
    payload: activityId,
  };
}

function dropState(): Action {
  return {
    type: StepActionTypes.DROP_ACTIVITY_GROUPING_STATE,
  };
}

export const StepActions = {
  setActivityGroupingStepPage,
  updateActivityGrouping,
  updateActivityGroupingComplete,
  updateMacroSequence,
  updateMacroSequenceComplete,
  loadActivityGroups,
  loadActivityGroupingComplete,
  loadMacroSequence,
  loadMacroSequenceComplete,
  setActiveWorkPackage,
  setActiveActivityGroupOrUngroupedActivity,
  setActiveCategory,
  setActiveActivityOrUngroupedActivity,
  dropState,
};
