import React, { useCallback } from 'react';
import { Text } from 'common/components/text';
import { Styled } from './styled';


interface Props {
  value: string;
  onClick: (value: string) => void;
}


const QueryItemComponent: React.FC<Props> = ({
  value,
  onClick,
}) => {
  const onClickCallback = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <Styled.QueryItem
      onClick={onClickCallback}
    >
      <Text
        fontSize={12}
        lineHeight={14}
        color='white'
        letterSpacing={0}
        textTransform='capitalize'
      >
        {value}
      </Text>
    </Styled.QueryItem>
  );
};

export const QueryItem = React.memo(QueryItemComponent);
