import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  min-width: 190px;
`;

export const Styled = {
  Container,
};
