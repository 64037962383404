import * as React from 'react';

export const KreoIconCollapseExpand: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/14x14/Expand-Collapse'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <polyline id='Path-22' stroke='currentColor' points='4 11 7 7 4 3' />
        <polyline id='Path-22' stroke='currentColor' points='7 11 10 7 7 3' />
      </g>
    </svg>
  );
};
