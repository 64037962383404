import { formatData } from '../../../../../../helpers/format-data-getter';
import { FormulaProperty, NumericFormat } from '../../../../../../interfaces';
import { Field, FieldType } from '../../interfaces';

export interface FormulaFieldInput {
  onClick: () => void;
  value: string;
}

export const mapFormulaPropertyToField = (
  property: FormulaProperty,
  originId: string,
  units: string[],
  onChange: () => void,
  onDeleteClick: () => void,
  onClickUnit: (index: number) => void,
  onVisibilityClick: () => void,
  onBlurUnit: (unit: string) => void,
): Field<FormulaFieldInput> => {
  const unit = (property.value.format as NumericFormat).unit;
  const activeElementIndex = units.indexOf(unit);
  return {
    id: property.id,
    originId,
    name: property.name,
    type: FieldType.Button,
    isHide: false,
    input: {
      value: property.value.value.toString(),
      onClick: onChange,
    },
    dropDownProps: {
      elements: units,
      activeElementIndex,
      onClick: onClickUnit,
      value: unit,
      onBlur: onBlurUnit,
    },
    onDeleteClick,
    onVisibilityClick,
    getDataUpdate: (field: Field<FormulaFieldInput>) => {
      const format = property.value.format;
      const { activeElementIndex: currentIndex, elements, value } = field.dropDownProps;
      return {
        ...property,
        id: field.id,
        value: {
          format: formatData.updateFormatUnit(format, value || elements[currentIndex]),
          value: field.input.value,
          type: property.value.type,
        },
      };
    },
  };
};
