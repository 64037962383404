import * as clipperLib from 'js-angusj-clipper';

export class ClipperLoaderWrapper {
  private static cliper: clipperLib.ClipperLibWrapper;

  public static async getInstanceAsync(): Promise<clipperLib.ClipperLibWrapper> {
    if (!this.cliper) {
      this.cliper = await clipperLib.loadNativeClipperLibInstanceAsync(
        clipperLib.NativeClipperLibRequestedFormat.WasmWithAsmJsFallback,
      );
    }
    return this.cliper;
  }
}
