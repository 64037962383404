import { Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import { LabelGroup } from 'common/components/label-group';
import { VisibilityFilterType } from 'unit-2d-database/interfaces';
import { AssemblyForm } from '../assembly-panel/item-form-updater';
import { ItemGroupForm } from '../group-item-form';
import { Styled } from '../styled';

interface Props {
  id: string;
  name: string;
  groups: AssemblyForm;
  isOpen: boolean;
  visibilityMode: VisibilityFilterType;
  collapsedGroups: Set<string>;
  toggleGroup: (id: string, isOpen: boolean) => void;
  switchPanel?: (itemId: string) => void;
  onClickSelect?: (itemId: string, baseId: string) => void;
  onDeleteAssembly: () => void;
  parentId?: string;
  hideDeleteButton?: boolean;
  editAssembly?: (assemblyName: string) => void;
  disabled?: boolean;
}

export class AssemblyGroup extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const {
      id,
      name,
      isOpen,
      onDeleteAssembly,
      visibilityMode,
      collapsedGroups,
      groups,
      parentId,
      hideDeleteButton,
      editAssembly,
      disabled,
    } = this.props;
    return (
      <Styled.AssemblyGroup className={id}>
        <Styled.LabelGroupWrapper>
          <LabelGroup
            text={name}
            Icon={Icons.AssembliesDataBase}
            isExpanded={isOpen}
            onGroupClick={this.onAssemblyClickGroup}
            removeGroup={onDeleteAssembly}
            tooltipTextDeleteButton={'Remove from the list'}
            editItem={editAssembly && this.editAssembly}
            tooltipTextEditButton={'Edit Assembly'}
          />
        </Styled.LabelGroupWrapper>
        {isOpen && (
          <Styled.ItemGroupForm>
            <ItemGroupForm
              groups={groups.itemGroupForm}
              toggleGroup={this.onItemClickGroup}
              visibilityMode={visibilityMode}
              collapsedGroups={collapsedGroups}
              parentId={`${parentId}_${id}`}
              hideDeleteButton={hideDeleteButton}
              disabled={disabled}
              withInhered={false}
              getId={this.getId}
            />
          </Styled.ItemGroupForm>
        )}
      </Styled.AssemblyGroup>
    );
  }

  @autobind
  private getId(): string {
    return this.props.id;
  }

  @autobind
  private editAssembly(): void {
    this.props.editAssembly(this.props.id);
  }

  @autobind
  private onAssemblyClickGroup(): void {
    const { isOpen, id, toggleGroup, parentId } = this.props;
    toggleGroup(`${parentId}_${id}`, !isOpen);
  }

  @autobind
  private onItemClickGroup(id: string, isOpen: boolean): void {
    this.props.toggleGroup(id, isOpen);
  }
}
