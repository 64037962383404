import styled, { css } from 'styled-components';


const disabledCSS = css`
  cursor: not-allowed;
  pointer-events: none;
  p {
    color: ${p => p.theme.color.disabled};
  }
  svg {
    fill: ${p => p.theme.color.disabled};
  }
`;


const MenuItemButton = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 15px;
  height: 50px;
  padding: 0 20px;
  svg {
    height: 20px;
    fill: ${p => p.theme.color.turquoise};
  }

  ${p => p.disabled ? disabledCSS : ''}
`;

const MenuItemText = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  overflow: hidden;
  p {
    margin-right: 10px;
    white-space: nowrap;
  }
  > div:first-child {
    overflow: hidden;
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 15px;
  width: 100%;
`;

const MenuItemActions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    color: ${p => p.theme.color.white} !important;
  }
`;

const ShareIcon = styled.div`
  svg {
    fill: ${p => p.theme.color.turquoise}
  }
  margin-right: 5px;
  display: flex;
`;

const DefaultIcon = styled.div`
  svg {
    vertical-align: middle;
  }
  circle {
    fill: ${p => p.theme.color.turquoise};
  }
`;

const TemplateMenuContainer = styled.div<{ x: number, y: number }>`
  position: absolute;
  left: ${p => p.x}px;
  top: ${p => p.y}px;
  z-index: 100000;
`;

const TemplateMenuContentWrapper = styled.div`
  > div {
    height: 50px;
  }
  svg {
    margin-right: 20px;
  }
`;

const SwitchWithLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  p {
    margin-left: 20px;
    transition: ${p => p.theme.duration.s} linear;
  }
  &:hover {
    p {
      color: ${p => p.theme.color.turquoise};
    }
  }
`;

export const Styled = {
  MenuItemButton,
  MenuItem,
  MenuItemText,
  MenuItemActions,
  ShareIcon,
  DefaultIcon,
  TemplateMenuContainer,
  TemplateMenuContentWrapper,
  SwitchWithLabel,
};
