import * as React from 'react';

import './engine-hotkeys-hint-block.scss';

import { EngineHotkeysBlock } from './engine-hotkeys-list';

interface Props {
  block: EngineHotkeysBlock;
}

export const EngineHotkeysHintBlock: React.FC<Props> = (props) => {
  return (
    <div className='engine-hotkeys-hint-block'>
      <div className='engine-hotkeys-hint-block__title'>{props.block.blockName}</div>
      {props.block.hotkeyList.map(item => {
        return (
          <div key={item.name} className='engine-hotkeys-hint-block__item'>
            <div className='engine-hotkeys-hint-block__item-name'>{item.name}</div>
            <div className='engine-hotkeys-hint-block__item-key'>
              {item.key.map(key => <b key={key}>{key}</b>)}
            </div>
            {item.additionalKey && (
              <>
                <span>or</span>
                <div className='engine-hotkeys-hint-block__item-key'>
                  {item.additionalKey.map(key => <b key={key}>{key}</b>)}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
