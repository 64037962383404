import autobind from 'autobind-decorator';
import { TwoDRegex } from '2d/units/2d-regex';
import { ExcelFormulaHelper } from './excel-formula-helper';

type CellDataGetter = (fullCellId: string) => string;
type GetCellValueType = (
  cellKey: string,
  fic: FormulaInfoController,
  nonCalculatingValue: Set<string>,
) => string | number;

enum MatchType {
  Range,
  CellId,
  String,
}

export class FormulaInfoController {
  private getCellData: CellDataGetter;
  private getCellValue: GetCellValueType;
  private formulaPartMap: Record<string, RegExpMatchArray>;
  private formulaCellIdsMap: Record<string, Set<string>>;
  private formulaOptimizeMap: Record<string, string>;
  private cellIdOptimezeMap: Record<string, string | number>;
  private matcheTypes: Record<string, MatchType>;
  private notCalculatedCells: Set<string>;

  public constructor() {
    this.formulaPartMap = {};
    this.formulaCellIdsMap = {};
    this.formulaOptimizeMap = {};
    this.cellIdOptimezeMap = {};
    this.matcheTypes = {};
  }

  public setGetters(getCellData: CellDataGetter, getCellValue: GetCellValueType): void {
    this.getCellData = getCellData;
    this.getCellValue = getCellValue;
  }

  public setNotCalculatedCells(cells: Set<string>): void {
    this.notCalculatedCells = cells;
  }

  public hasLoop(formula: string, startCellKeys: Set<string>): boolean {
    if (!formula) {
      return false;
    }
    const cellIds = this.getFormulaCellIdsMap(formula);
    for (const key of startCellKeys) {
      if (cellIds.has(key.toUpperCase())) {
        this.setCellIdOptimizeMap(key, ExcelFormulaHelper.INVALID_VALUE);
        return true;
      }
    }
    return false;
  }

  public optimize(formula: string): string {
    if (!formula) {
      return formula;
    }
    this.prepareState(formula);
    if (this.formulaOptimizeMap[formula]) {
      return this.formulaOptimizeMap[formula];
    }

    let optimizeFormula = '';
    const matches = this.getFormulaMatch(formula);
    for (const match of matches) {
      if (TwoDRegex.cellRange.test(match) || TwoDRegex.fullCellId.test(match)) {
        optimizeFormula += this.getCellIdOptimizeValue(match);
      } else {
        optimizeFormula += match;
      }
    }
    this.formulaOptimizeMap[formula] = optimizeFormula;
    return optimizeFormula;
  }

  private prepareState(formula: string): void {
    this.getFormulaCellIdsMap(formula);
    this.setCellIdOptimize(formula);
  }

  private setCellIdOptimize(formula: string): void {
    const matches = this.getFormulaMatch(formula);

    for (const match of matches) {
      const type = this.matcheTypes[match];
      if (type === MatchType.CellId) {
        this.tryGetCellValue(match);
        continue;
      }

      if (type === MatchType.Range) {
        const cellList = ExcelFormulaHelper.getCellListFromCellRange(match);
        const rowList = ExcelFormulaHelper.splitByRow(cellList);
        const cellValues = this.getCellValues(rowList);
        const value = this.getRangeValue(cellValues);
        this.setCellIdOptimizeMap(match, value);
        continue;
      }
    }
  }

  private getCellValues(rowList: string[][]): number[][] {
    const result = [];

    rowList.forEach(columns => {
      result.push(columns.map(this.tryGetCellValue));
    });

    return result;
  }

  private getRangeValue(rowList: number[][]): string {
    const result = [];
    rowList.forEach(r => {
      result.push(`[${r.join(',')}]`);
    });
    return `[${result.join(',')}]`;
  }

  @autobind
  private tryGetCellValue(fullCellId: string): string | number {
    const value = this.getCellIdOptimizeValue(fullCellId);
    if (value !== undefined) {
      return value;
    }
    this.setCellIdOptimizeMap(fullCellId, this.getCellValue(fullCellId, this, this.notCalculatedCells));

    return this.getCellIdOptimizeValue(fullCellId);
  }

  private setCellIdOptimizeMap(cellId: string, value: string | number): void {
    const key = cellId.toLowerCase();
    this.cellIdOptimezeMap[key] = value;
  }

  private getCellIdOptimizeValue(cellId: string): string | number {
    const key = cellId.toLowerCase();
    return this.cellIdOptimezeMap[key];
  }

  private getFormulaCellIdsMap(formula: string): Set<string> {
    const ids = this.formulaCellIdsMap[formula];
    if (ids) {
      return ids;
    }
    const cellIds = new Set<string>();
    this.formulaCellIdsMap[formula] = cellIds;
    const matches = this.getFormulaMatch(formula);
    for (const match of matches) {
      if (TwoDRegex.cellRange.test(match)) {
        const cellLlist = ExcelFormulaHelper.getCellListFromCellRange(match);
        for (const cell of cellLlist) {
          cellIds.add(cell.toUpperCase());
          const data = this.getCellData(cell);
          if (ExcelFormulaHelper.isFormula(data)) {
            this.getFormulaCellIdsMap(data);
          }
        }
        continue;
      }
      if (!TwoDRegex.fullCellId.test(match)) {
        continue;
      }
      const cellId = match.toUpperCase();
      cellIds.add(cellId);
      const cellData = this.getCellData(match);
      if (ExcelFormulaHelper.isFormula(cellData)) {
        const innerIds = this.getFormulaCellIdsMap(cellData);
        innerIds.forEach(id => {
          cellIds.add(id);
        });
      }
    }
    return cellIds;
  }

  private getFormulaMatch(formula: string): RegExpMatchArray {
    if (this.formulaPartMap[formula]) {
      return this.formulaPartMap[formula];
    }
    const mathces = formula.match(TwoDRegex.formulaToolBarGlobal);
    for (const match of mathces) {
      this.matcheTypes[match] = this.getMatchType(match);
    }
    this.formulaPartMap[formula] = mathces;
    return mathces;
  }

  private getMatchType(match: string): MatchType {
    if (TwoDRegex.cellRange.test(match)) {
      return MatchType.Range;
    }

    if (TwoDRegex.fullCellId.test(match)) {
      return MatchType.CellId;
    }

    return MatchType.String;
  }
}
