export interface DrawingsStickerAPI {
  changePosition: (x: number, y: number) => void;
}

export function createApi(container: HTMLDivElement, onPositionChanged: () => void): DrawingsStickerAPI {
  if (!container) {
    return null;
  }
  return {
    changePosition: (x: number, y: number): void => {
      if (!container) {
        return;
      }
      container.style.left = `${x}px`;
      container.style.top = `${y}px`;
      onPositionChanged();
    },
  };
}
