import * as React from 'react';

export const KreoIconTopSettings: React.FC = () => {
  return (
    <svg width='20px' height='20px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='icon_databases' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icon_settings'>
          <circle id='Oval' cx='11' cy='11' r='9' />
          <g id='Group_settings' transform='translate(2.000000, 2.000000)'>
            <path
              // eslint-disable-next-line max-len
              d='M11,4.17157288 L11,0 L7,0 L7,4.17157288 L4.05025253,1.22182541 L1.22182541,4.05025253 L4.17157288,7 L0,7 L0,11 L4.17157288,11 L1.22182541,13.9497475 L4.05025253,16.7781746 L7,13.8284271 L7,18 L11,18 L11,13.8284271 L13.9497475,16.7781746 L16.7781746,13.9497475 L13.8284271,11 L18,11 L18,7 L13.8284271,7 L16.7781746,4.05025253 L13.9497475,1.22182541 L11,4.17157288 Z'
              id='Combined-Shape'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <circle id='Oval-2' fill='#A7B0BD' cx='9' cy='9' r='2' />
          </g>
        </g>
      </g>
    </svg>
  );
};
