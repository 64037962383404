import * as t from 'io-ts';
import { ActivityAssignmentCategoryResponseC } from './activity-assignment-tree-node-response';
import { ActivityAssignmentActivityC } from './activity-assignmnet-activity';

export const ActivityAssignmentResponseC = t.exact(
  t.intersection([
    t.type({
      tree: t.array(ActivityAssignmentCategoryResponseC),
      works: t.array(ActivityAssignmentActivityC),
    }),
    t.partial({
      databases: t.array(t.number),
      usedOldCmtdbVersion: t.boolean,
    }),
  ]),
  'ActivityAssignmentResponse');

export type ActivityAssignmentResponse = t.TypeOf<typeof ActivityAssignmentResponseC>;
