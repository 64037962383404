import styled from 'styled-components';

const PauseIcon = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  color: ${p => p.theme.color[p.color]};
  cursor: pointer;
  
  svg {
    height: 20px;
    margin-right: 15px;
    fill: currentColor;
  }
`;

export const Styled = {
  PauseIcon,
};
