import { ElementTooltip } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { useAbilityContext } from 'common/ability/use-ability-context';
import { ContextMenuItem } from 'common/components/context-menu';
import { DrawingsSVGIcons } from 'common/components/drawings/constants/drawings-svg-icons';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { InteractiveMenuContainer } from 'common/components/interactive-menu';
import { useOpenMenuWithRect } from 'common/hooks';
import { DrawingsSvgMenu } from '../../drawings-svg-lib-control';
import { OptionsSubjectsMap } from '../../helpers';
import { InstrumentInfo } from '../../interfaces';
import { SelectModeSettings } from '../interfaces';
import { Styled } from './styled';


interface Props {
  instrument: InstrumentInfo;
  active: boolean;
  onClick: (payload: SelectModeSettings) => void;
}

const SubvariantComponent: React.FC<Props> = ({
  instrument,
  active,
  onClick,
}) => {
  const { onOpen, isOpen, position, onClose } = useOpenMenuWithRect(true);
  const { ability } = useAbilityContext();
  const needUpdatePlan = useMemo(
    () => OptionsSubjectsMap[instrument.id]?.some((s) => ability.cannot(Operation.Update, s)),
    [instrument.id, ability],
  );
  const onClickCallback = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (instrument.id === DrawingsDrawMode.Image) {
      onOpen(e);
    } else {
      onClick({ mode: instrument.id });
    }
  }, [instrument.id, onClick, onOpen]);

  const onSelectImage = useCallback((image: DrawingsSVGIcons) => {
    onClick({ mode: instrument.id, image });
  }, [instrument, onClick]);

  return (
    <>
      <ElementTooltip
        position={'right'}
        tooltipWidth={210}
        speed={'l'}
        text={instrument.name}
        size='small'
        hotKey={instrument.hotKey}
        description={instrument.description}
        disabled={needUpdatePlan || !instrument.description}
      >
        <UpgradeWrapper isNeedUpdate={needUpdatePlan}>
          <Styled.Subvariant active={active}>
            <ContextMenuItem
              icon={instrument.icon}
              hotkey={instrument.hotKey}
              disabled={needUpdatePlan || instrument.disabled}
              onClick={onClickCallback}
            >
              {instrument.name}
            </ContextMenuItem>
          </Styled.Subvariant>
        </UpgradeWrapper>
      </ElementTooltip>
      {isOpen ? (
        <InteractiveMenuContainer
          parentRect={position}
          withBlanket={true}
          onCloseClick={onClose}
          bindVertical='top'
          bindHorizontal='right'
          orientation='horizontal'
          marginTop={10}
          style={Styled.MenuContainerStyle}
        >
          <DrawingsSvgMenu onSelect={onSelectImage} />
        </InteractiveMenuContainer>
      ) : null}
    </>
  );
};

export const Subvariant = React.memo(SubvariantComponent);

