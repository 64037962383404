import * as React from 'react';

import './double-content.scss';

interface Props {
  top: JSX.Element;
  bottom: JSX.Element;
}

export class DoubleContent extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='checker-double-content'>
        <div className='checker-double-top'>
          {this.props.top}
        </div>
        <div className='checker-double-bottom'>
          {this.props.bottom}
        </div>
      </div>
    );
  }
}
