import * as t from 'io-ts';

import { ElementClassificationC } from './element-classification';
import { ExtractorFunctionC } from './extractor-function';
import { QuantityTakeOffElementC } from './quantity-take-off-element';

export const QuantityTakeOffModelC = t.type(
  {
    elements: t.array(QuantityTakeOffElementC),
    uniSystemClassifications: t.array(ElementClassificationC),
    uniProductClassifications: t.array(ElementClassificationC),
    nrm1Classifications: t.array(ElementClassificationC),
    nrm2Classifications: t.array(ElementClassificationC),
    extractors: t.array(ExtractorFunctionC),
  },
  'QuantityTakeOffModel');

export type QuantityTakeOffModel = t.TypeOf<typeof QuantityTakeOffModelC>;
