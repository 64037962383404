import { HotkeyMultiOsHelper } from './hotkey-multi-os-helper';
import { OS, OsDetector } from './os-detector';


interface HotkeyParams {
  os?: OS;
  splitSymbol?: string;
  shortcutToShow?: string;
  splitterToShow?: string;
}

export class Hotkey {
  private _shortcut: string;
  private _os: OS;
  private _splitSymbol: string;
  private _shortcutToShow: string;
  private _splitterToShow: string;

  constructor(shortcut: string, params?: HotkeyParams) {
    this._os = params && params.os;
    this._shortcut = this.getOsShortcut(shortcut, this._os);
    this._shortcutToShow = HotkeyMultiOsHelper.getShortcutToShow(params && params.shortcutToShow || this._shortcut);
    this._splitSymbol = params && params.splitSymbol || '+';
    this._splitterToShow = params && params.splitterToShow || '+';
  }

  public get shortcutToShow(): string {
    return this._shortcutToShow;
  }

  public get splitterToShow(): string {
    return this._splitterToShow;
  }

  public get shortcut(): string {
    return this._shortcut;
  }

  public get os(): string {
    return this._os;
  }

  public get splitSymbol(): string {
    return this._splitSymbol;
  }

  private getOsShortcut(defaultShortcut: string, os: OS): string {
    const currentOs = OsDetector.getOs();
    if (os) {
      return os === currentOs ? defaultShortcut : null;
    }

    return this.replaceOsSymbols(defaultShortcut, currentOs);
  }

  private replaceOsSymbols(defaultShortcut: string, currentOs: OS): string {
    return currentOs === OS.Mac
      ? HotkeyMultiOsHelper.toMacHotkey(defaultShortcut)
      : HotkeyMultiOsHelper.toWindowsHotkey(defaultShortcut);
  }
}
