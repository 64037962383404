import { RectangleButton } from '@kreo/kreo-ui-components';
import emojiRegex from 'emoji-regex';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextInput } from 'common/components/inputs';
import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { Text, TinyText } from 'common/components/text';
import { Mood } from 'common/enums/mood';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AccountActions } from '../../../../units/account/actions/creators';
import { CompanyLogo } from '../company-logo';
import { REJECT_COMPANY_NAME_DIALOG, RejectCompanyNameDialog } from '../reject-company-name-dialog';
import { Styled } from './styled';


export const CREATE_COMPANY_DIALOG_NAME = 'CREATE_COMPANY_DIALOG_NAME';

export const CreateCompany: React.FC = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [companyName, setCompanyName] = useState<string>('');
  const [isValidName, setValidName] = useState<boolean>(true);
  const logoTemporaryKey = useSelector<State, string>(state => state.account.companyLogoTemporaryKey);
  const isEmptyName = useMemo(() => !companyName.trim(), [companyName]);
  const createCompanyStatus = useSelector<State, RequestStatus>((s) => s.account.statuses.createCompany);
  useEffect(() => {
    if (ref.current && createCompanyStatus === RequestStatus.Loaded) {
      dispatch(KreoDialogActions.closeDialog(CREATE_COMPANY_DIALOG_NAME));
    }
  }, [createCompanyStatus, dispatch]);

  const createCompany = useCallback(() => {
    if (!isEmptyName) {
      ref.current = true;
      dispatch(AccountActions.createCompany({ name: companyName }, logoTemporaryKey));
    }
  }, [companyName, dispatch, isEmptyName, logoTemporaryKey]);

  const checkCompanyName = useCallback((value: string) => {
    const regex = emojiRegex();

    if (value.length > 149) {
      return false;
    }
    if (regex.test(value)) {
      return false;
    }
    return true;
  }, []);

  const onChangeName = useCallback((value: string) => {
    const isValidValue = checkCompanyName(value);
    setValidName(isValidValue);
    if (isValidValue) {
      setCompanyName(value);
    }
  }, [checkCompanyName]);

  const onHandelBlur = useCallback((value: string) => {
    const isValidValue = checkCompanyName(value);
    if (!isValidValue) {
      dispatch(KreoDialogActions.openDialog(REJECT_COMPANY_NAME_DIALOG));
    }
  }, [checkCompanyName, dispatch]);

  return (
    <>
      <RenderIf condition={createCompanyStatus === RequestStatus.Loading}>
        <Spinner show={true} withBackground={true} /> :
      </RenderIf>
      <Styled.Container>
        <Text>
          Create your workspace
        </Text>
        <CompanyLogo />
        <TinyText>
          Company name
        </TinyText>
        <TextInput
          value={companyName}
          placeholder={'Enter the name of the team'}
          onChange={onChangeName}
          onKeyEnterDown={createCompany}
          onBlur={onHandelBlur}
        />
        <RectangleButton
          height={36}
          borderRadius={10}
          mood={isEmptyName || !isValidName ? Mood.Disabled : Mood.Secondary}
          text={'Create company'}
          onClick={createCompany}
        />
      </Styled.Container>
      <RejectCompanyNameDialog />
    </>
  );
};
