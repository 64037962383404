import { createSlice } from '@reduxjs/toolkit';
import { ActionWith } from 'common/interfaces/action-with';
import { TwoDFastNavigationState } from 'unit-2d-database/interfaces/2d-fast-navigation-state';
import { FastNavigationLinks } from '../../../units/2d/constants/fast-navigation-links';

export const TWO_D_FAST_NAVIGATION_INITIAL_STATE: TwoDFastNavigationState = {
  activeNavigationType: FastNavigationLinks.Project,
  assembliesLocationUrl: '',
};

const TwoDFastNavigation = createSlice({
  name: '@twoDFastNavigation',
  initialState: TWO_D_FAST_NAVIGATION_INITIAL_STATE,
  reducers: {
    changeActiveNavigationType: (s, action: ActionWith<string>) => {
      const activeNavigationType = action.payload;
      s.activeNavigationType = activeNavigationType;
    },
    setAssembliesLocationUrl: (s, action: ActionWith<string>) => {
      s.assembliesLocationUrl = action.payload;
    },
  },
});

export const TwoDFastNavigationActions = TwoDFastNavigation.actions;
export const twoDFastNavigationReducer = TwoDFastNavigation.reducer;
