import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { DatabaseActions } from '../actions/creators/database';
import { DatabaseCommonActions } from '../actions/creators/database-common';
import { DatabaseActionTypes } from '../actions/types/database';
import { DatabasesApi } from '../api/databases';
import { DatabaseModel } from '../interfaces/data';
import { CreateDatabaseForm, UpdateDatabaseForm } from '../interfaces/rest-data';


function* createDatabase(action: ActionWith<CreateDatabaseForm>): SagaIterator {
  try {
    const data = action.payload;
    const result = yield call(DatabasesApi.createDatabase, data);
    yield put(DatabaseActions.createDatabaseResponse(result));
  } catch (error) {
    console.error('database: create database failed', error, action.payload);
  }
}

// todo: проверить action по которому дергается эта сага https://kreosoftware.atlassian.net/browse/KREOP-13390
function* loadDatabases(action: ActionWith<number>): SagaIterator {
  try {
    const result = yield call(DatabasesApi.getDatabases);
    yield put(DatabaseActions.loadDatabasesResponse(result));
  } catch (error) {
    console.error('database: load databases failed', error, action.payload);
  }
}

function* getDatabase(action: ActionWith<number>): SagaIterator {
  try {
    const result = yield call(
      DatabasesApi.getDatabase,
      action.payload,
    );
    yield put(DatabaseActions.setCurrentDatabase(result.model));
  } catch (error) {
    console.error('database: get database failed', error, action.payload);
  }
}

function* setCurrentDatabase({ payload }: ActionWith<DatabaseModel>): SagaIterator {
  try {
    yield put(DatabaseCommonActions.loadFunctionsWithUnits(payload.id));
  } catch (error) {
    console.error('database: set current database failed', error, payload.id);
  }
}

function* updateDatabase(action: ActionWith<UpdateDatabaseForm>): SagaIterator {
  try {
    const result = yield call(DatabasesApi.updateDatabase, action.payload.databaseId, action.payload);
    yield put(DatabaseActions.updateDatabaseResponse(result));
  } catch (error) {
    console.error('database: update database failed', error, action.payload);
  }
}

function* deleteDatabase(action: ActionWith<number>): SagaIterator {
  try {
    yield call(DatabasesApi.deleteDatabase, action.payload);
    yield put(DatabaseActions.deleteDatabaseResponse(action.payload));
  } catch (error) {
    console.error('database: delete database failed', error, action.payload);
  }
}

function* setDefaultDatabase(action: ActionWith<number>): SagaIterator {
  try {
    yield call(DatabasesApi.setDefault, action.payload);
    yield put(DatabaseActions.setDefaultDatabaseResponse(action.payload));
  } catch (error) {
    console.error('database: set default database failed', error, action.payload);
  }
}

export function* databaseSagas(): SagaIterator {
  yield takeLatest(DatabaseActionTypes.CREATE_DATABASE_REQUEST, createDatabase);
  yield takeLatest(DatabaseActionTypes.GET_DATABASES_REQUEST, loadDatabases);
  yield takeLatest(DatabaseActionTypes.GET_DATABASE_REQUEST, getDatabase);
  yield takeLatest(DatabaseActionTypes.UPDATE_DATABASE_REQUEST, updateDatabase);
  yield takeEvery(DatabaseActionTypes.DELETE_DATABASE_REQUEST, deleteDatabase);
  yield takeEvery(DatabaseActionTypes.SET_DEFAULT_DATABASE, setDefaultDatabase);
  yield takeLatest(DatabaseActionTypes.SET_CURRENT_DATABASE, setCurrentDatabase);
}
