import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { DrawingsSubmenuContainer } from '../../drawings-canvas-menus/drawings-flying-menu/submenu-containers';

const FONT_SIZE = 12;
const LINE_HEIGHT_MULTIPLIER = 1.5;
const VALUE_CELL_WIDTH = 90;
const COLOR_INDICATOR_SIZE = 20;
const ITEM_NAME_TEXT_MARGIN_LEFT = 10;
const TABLE_BORDER_SPACING = 10;
const CONTAINER_BORDER_RADIUS = 15;
const CONTAINER_PADDING = 15;
const NAME_WIDTH = 120;

const Content = styled.div`
  height: 100%;
  overflow: auto;
`;

const HeaderName = styled.th`
  display: flex;
`;

const Name = styled.td`
  display: flex;
  > div {
    position: initial;
  }
`;

const Field = styled.td`
  display: flex;
  > div {
    position: initial;
  }
`;

const HeaderValue = styled.th`
  overflow: hidden;
  white-space: nowrap;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  overflow: auto;
`;

const ItemLine = styled.tr`
`;

const ItemValue = styled.td`
  p {
    text-align: right;
  }
`;

const HeaderContainer = styled.thead`
  top: 0px;
  position: sticky;
  background: ${p => p.theme.color.backgroundRush};
`;

function setColor(color: string, themeColor: string): FlattenSimpleInterpolation {
  if (color) {
    return css`
      background-color: ${color};
      border-color: transparent;
    `;
  } else {
    return css`
      border-color: ${themeColor};
      border-style: solid;
    `;
  }
}

const ColorIndicator = styled.div`
  box-sizing: border-box;
  border-radius: 50%;
  ${p => setColor(p.color, p.theme.color.gray)}
`;


const Container = styled(DrawingsSubmenuContainer)`
  position: absolute;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  min-width: unset;
  flex-direction: column;
  width: fit-content;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  > div {
    overflow: auto;
  }
  ${HeaderContainer} {
    th {
      padding: 0px;
    }
  }
`;


export const Styled = {
  Container,
  Name,
  HeaderValue,
  ItemContainer,
  Body,
  Field,
  Content,
  HeaderName,
  ItemLine,
  HeaderContainer,
  ColorIndicator,
  ItemValue,
  CONTAINER_PADDING,
  CONTAINER_BORDER_RADIUS,
  TABLE_BORDER_SPACING,
  FONT_SIZE,
  LINE_HEIGHT_MULTIPLIER,
  COLOR_INDICATOR_SIZE,
  NAME_WIDTH,
  ITEM_NAME_TEXT_MARGIN_LEFT,
  VALUE_CELL_WIDTH,
};
