import { AnyAction } from 'redux';

import { RequestStatus } from 'common/enums/request-status';
import { AccountActionTypes } from '../units/account/actions/types';
import { NotificationReduxState } from '../units/notifications/interfaces';
import { notificationReducerMethods } from '../units/notifications/reducer';

const INITIAL_STATE: NotificationReduxState = {
  notifications: [],
  showBellNotifications: false,
  alerts: [],
  loadStatus: RequestStatus.Loaded,
};

export default function notifications(
  state: NotificationReduxState = INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): NotificationReduxState {
  if (action.type in notificationReducerMethods) {
    return notificationReducerMethods[action.type](state, action.payload);
  } else if (action.type === AccountActionTypes.LOG_OUT) {
    return INITIAL_STATE;
  } else {
    return state;
  }
}
