import { CommonApi } from '../../../api/server';
import { BillingCollection } from '../interfaces/billing-collection';
import { BillingCountryModel, BillingCountryModelsC } from '../interfaces/billing-country-model';
import { CreateBillingCardForm } from '../interfaces/create-billing-card-form';
import { PaymentSourcesModel, PaymentSourcesModelC } from '../interfaces/payment-sources-model';


function getPaymentMethods(force: boolean): Promise<PaymentSourcesModel> {
  return CommonApi.getV(
    `user/billing?force=${force || false}`,
    PaymentSourcesModelC,
    'Error while fetch payment methods',
  );
}

function createPaymentMethod(token: string): Promise<PaymentSourcesModel> {
  return CommonApi.postV(
    `user/billing/token`,
    { token, type: 'Card' },
    PaymentSourcesModelC,
    'Error while create new payment method',
  );
}

function createPaymentMethodLegacy(paymentMethod: CreateBillingCardForm): Promise<PaymentSourcesModel> {
  return CommonApi.postV(
    `user/billing`,
    paymentMethod,
    PaymentSourcesModelC,
    'Error while create new payment method',
  );
}


function setPrimaryPaymentMethod(methodId: string): Promise<PaymentSourcesModel> {
  return CommonApi.putV(
    `user/billing/${methodId}/primary`,
    null,
    PaymentSourcesModelC,
    `Error while set payment method ${methodId} as primary`,
  );
}

function setBackupPaymentMethod(methodId: string): Promise<PaymentSourcesModel> {
  return CommonApi.putV(
    `user/billing/${methodId}/backup`,
    null,
    PaymentSourcesModelC,
    `Error while set payment method ${methodId} as backup`,
  );
}

function deletePaymentMethod(methodId: string): Promise<PaymentSourcesModel> {
  return CommonApi.deleteV(
    `user/billing/${methodId}`,
    null,
    PaymentSourcesModelC,
    `Error while set payment method ${methodId} as backup`,
  );
}

function getAvailableBillingCountries(): Promise<BillingCountryModel[]> {
  return CommonApi.getV(
    `/billing/available-countries`,
    BillingCountryModelsC,
    'Error while get available countries',
  );
}

function getPaymentPortal(): Promise<any> {
  return CommonApi.get('/user/billing/portal');
}

function getBillingHistory(limit: number, offset: string): Promise<BillingCollection> {
  if (offset) {
    return CommonApi.get(`/user/billing/invoices?limit=${limit}&offset=${offset}`);
  } else {
    return CommonApi.get(`/user/billing/invoices?limit=${limit}`);
  }
}

function getInvoicesPdf(invoiceId: string): Promise<string> {
  return CommonApi.get(`/user/billing/invoice/${invoiceId}/pdf`);
}

export const PaymentMethodsApi = {
  getPaymentMethods,
  createPaymentMethod,
  createPaymentMethodLegacy,
  setPrimaryPaymentMethod,
  setBackupPaymentMethod,
  deletePaymentMethod,
  getAvailableBillingCountries,
  getPaymentPortal,
  getBillingHistory,
  getInvoicesPdf,
};
