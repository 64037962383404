import { ElementTooltip } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  selected: boolean;
  inherited: boolean;
  assigned: boolean;
}

const EntityLabelComponent: React.FC<Props> = ({ selected, inherited, assigned }) => {
  const tooltipApplied = 'The entity from the folder is applied';
  const tooltipNotApplied = 'No entities are applicable';
  const textTooltip = inherited && !assigned ? tooltipApplied : tooltipNotApplied;

  return (
    <Styled.Container>
      <ElementTooltip
        text={textTooltip}
        position='top'
        disabled={assigned}
        speed='l'
      >
        <Styled.IconContainer
          selected={selected}
          inherited={inherited}
          assigned={assigned}
        >
          <Styled.Icon />
        </Styled.IconContainer>
      </ElementTooltip>
    </Styled.Container>
  );
};

export const EntityLabel = React.memo(EntityLabelComponent);
