import { MoveToCellOptionType } from 'common/components/drawings/drawings-canvas-menus/interfaces/options';

export interface TwoDFullCellId {
  fullCellId: string;
  sheetId: string;
  sheetName: string;
  cellId: string;
  isColumnAbsolute: string;
  columnId: string;
  isRowAbsolute: string;
  rowId: string;
}

const TWO_D_FULL_CELL_ID: TwoDFullCellId = {
  fullCellId: 'fullCellId',
  sheetId: 'sheetId',
  sheetName: 'sheetName',
  cellId: 'cellId',
  isColumnAbsolute: 'isColumnAbsolute',
  columnId: 'columnId',
  isRowAbsolute: 'isRowAbsolute',
  rowId: 'rowId',
};

export interface TwoDCellRange {
  cellRange: string;
  startId: string;
  endId: string;
}

const TWO_D_CELL_RANGE: TwoDCellRange = {
  cellRange: 'cellRange',
  startId: 'startId',
  endId: 'endId',
};

export interface TwoDDrawingInstance {
  drawingInstance: string;
  drawingInstanceType: string;
  drawingInstanceId: string;
  otherArgs: string;
}

const TWO_D_DRAWING_INSTANCE: TwoDDrawingInstance = {
  drawingInstance: 'drawingInstance',
  drawingInstanceType: 'drawingInstanceType',
  drawingInstanceId: 'drawingInstanceId',
  otherArgs: 'otherArgs',
};

interface FormulaPart extends TwoDCellRange, TwoDFullCellId, TwoDDrawingInstance {
  stringValue: string;
}

interface TwoDFormulaToolBar extends FormulaPart {
  symbol: string;
}

interface TwoDGroupsNames extends TwoDFormulaToolBar  {
  separator: string;
  formulaFunction: string;
}


const TWO_D_GROUPS_NAMES: TwoDGroupsNames = {
  symbol: 'symbol',
  separator: 'separator',
  formulaFunction: 'formulaFunction',
  stringValue: 'stringValue',
  ...TWO_D_FULL_CELL_ID,
  ...TWO_D_CELL_RANGE,
  ...TWO_D_DRAWING_INSTANCE,
};

const absoluteRefPrefix = '\\$?';
const guid = '[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}';
const sheetIdGroup = `(?<${TWO_D_GROUPS_NAMES.sheetId}>${guid})`;
const sheetName = `'(?<${TWO_D_GROUPS_NAMES.sheetName}>[^']+)'`;
const columnIdRegex = '[a-zA-Z]+';
const columnIdGroup =
  `(?<${TWO_D_GROUPS_NAMES.isColumnAbsolute}>${absoluteRefPrefix})(?<${TWO_D_GROUPS_NAMES.columnId}>${columnIdRegex})`;
const rowIdRegex = '\\d+';
const rowIdGroup =
  `(?<${TWO_D_GROUPS_NAMES.isRowAbsolute}>${absoluteRefPrefix})(?<${TWO_D_GROUPS_NAMES.rowId}>${rowIdRegex})`;
const cellIdRegex = `${columnIdGroup}${rowIdGroup}`;
const cellIdGroup = `(?<${TWO_D_GROUPS_NAMES.cellId}>${cellIdRegex})`;
const fullCellId = `(?<${TWO_D_GROUPS_NAMES.fullCellId}>((${sheetIdGroup}|${sheetName})!)?${cellIdGroup})`;
// eslint-disable-next-line max-len
const cellRange = `(?<${TWO_D_GROUPS_NAMES.cellRange}>(?<${TWO_D_GROUPS_NAMES.startId}>((${guid}|'([^']+)')!)?${absoluteRefPrefix}${columnIdRegex}${absoluteRefPrefix}${rowIdRegex}):(?<${TWO_D_GROUPS_NAMES.endId}>${absoluteRefPrefix}${columnIdRegex}${absoluteRefPrefix}${rowIdRegex}))`;
const cellRangeInEnd = `${cellRange}$`;
const symbol = `(?<${TWO_D_GROUPS_NAMES.symbol}>.)`;
const options = Object.values(MoveToCellOptionType).join('|');
const drawingInstanceTypes = `(?<${TWO_D_GROUPS_NAMES.drawingInstanceType}>${options})`;
const drawingInstanceId = `(?<${TWO_D_GROUPS_NAMES.drawingInstanceId}>${guid})`;
const otherArgs = `(?<${TWO_D_GROUPS_NAMES.otherArgs}>,[^)]+)`;
const drawingInstance =
  `(?<${TWO_D_GROUPS_NAMES.drawingInstance}>${drawingInstanceTypes}\\(${drawingInstanceId}${otherArgs}?\\))`;
const stringValue = `(?<${TWO_D_GROUPS_NAMES.stringValue}>\\"[^"]*\\")`;
const formulaPart = `${stringValue}|${drawingInstance}|${cellRange}|${fullCellId}`;
const formulaToolbar = `${formulaPart}|${symbol}`;
const formulaSeparators = `(?<${TWO_D_GROUPS_NAMES.separator}>^=(.*[-+*/\>\<\=(,;])?$)`;
const openFormulaFunction = `(?<${TWO_D_GROUPS_NAMES.formulaFunction}>)([a-zA-Z]+\\((${fullCellId}[,; ]*)+$)`;
const formulaReplaceValue = `${cellRange}|${fullCellId}`;

export const TwoDRegex = {
  formulaToolBarGlobal: new RegExp(formulaToolbar, 'g'),
  formulaPartGlobal: new RegExp(formulaPart, 'g'),
  drawingInstance: new RegExp(drawingInstance),
  drawingInstanceGlobal: new RegExp(drawingInstance, 'g'),
  fullCellId: new RegExp(fullCellId),
  fullCellIdGlobal: new RegExp(fullCellId, 'g'),
  formulaSeparators: new RegExp(formulaSeparators),
  openFormulaFunction: new RegExp(openFormulaFunction),
  symbolGlobal: new RegExp(symbol, 'g'),
  cellRangeInEnd: new RegExp(cellRangeInEnd),
  formulaReplaceValueGlobal: new RegExp(formulaReplaceValue, 'g'),
  cellRange: new RegExp(cellRange),
};

const mapRegexGroupToFieldGetter = <T>(regexp: RegExp, declaredFields: Record<keyof T, string>) =>
  (value: string): T => {
    const groups = value.match(regexp).groups;
    const resultObject = {};
    Object.values<string>(declaredFields).forEach(groupName => {
      resultObject[groupName] = groups[groupName];
    });

    return resultObject as T;
  };

export const TwoDRegexGetter = {
  getFullCellField: mapRegexGroupToFieldGetter<TwoDFullCellId>(TwoDRegex.fullCellId, TWO_D_FULL_CELL_ID),
  getCellRangeField: mapRegexGroupToFieldGetter<TwoDCellRange>(TwoDRegex.cellRange, TWO_D_CELL_RANGE),
  getDrawingInstanceField:
    mapRegexGroupToFieldGetter<TwoDDrawingInstance>(TwoDRegex.drawingInstance, TWO_D_DRAWING_INSTANCE),
};

const typingReplaceArgs = <T>() =>
  (args: any[]): T => {
    return args[args.length - 1];
  };

export const TwoDRegexTypings = {
  typingFullCellIdArgs: typingReplaceArgs<TwoDFullCellId>(),
  typingFormulaReplaceValueGlobal: typingReplaceArgs<TwoDFullCellId & TwoDCellRange>(),
  typingFormulaPart: typingReplaceArgs<FormulaPart>(),
  typingFormulaToolBar: typingReplaceArgs<TwoDFormulaToolBar>(),
};
