import { DrawingsUtils } from 'common/components/drawings/utils/drawings-utils';
import { ApiConstants } from 'common/constants/api';
import { MonoliteHelper } from 'common/monolite';
import { CommonState, DownloadFileInfo, Location } from '../../common/interfaces/common-state';
import { ReducerMethods } from '../../common/interfaces/reducer-methods';
import { CommonActionTypes } from './action-types';
import {
  UpdateFileLoadingProgressPayload,
  UploadFileAddPayload,
  UploadFileFailedPayload,
  UploadSuccessPayload,
} from './payloads';

/**
 * When uploading files, progress is measured by amount of bytes sent from client,
 * not by amount processed on server.
 * That leads to progress beign shown as 100%, although upload request is not finished yet.
 * That constant limits upload progress to 99%, and 100% are shown when upload request is finished.
 */
const fileUploadProgressSendingThreshold = 0.99;
const fileUploadingCompletedProgressValue = 1;

const addFile = (state: CommonState, payload: UploadFileAddPayload[]): CommonState => {
  const helper = new MonoliteHelper(state);
  for (const { file, canceler } of payload) {
    helper.setAppend(_ => _.files, {
      file,
      progress: 0,
      error: '',
      uploadedFileName: '',
      key: DrawingsUtils.getFileKey(file),
      canceler,
    });
  }
  return helper.get();
};

const removeFile = (state: CommonState, payload: string): CommonState => {
  return new MonoliteHelper(state)
    .setFilter(_ => _.files, file => {
      if (file.key !== payload) {
        return true;
      } else if (file.progress !== fileUploadingCompletedProgressValue) {
        file.canceler(ApiConstants.RequestCancelMessage);
        return false;
      }
    })
    .get();
};

const downloadFile = (state: CommonState, payload: DownloadFileInfo): CommonState => {
  return new MonoliteHelper(state)
    .set(_ => _.downloadFileInfo, payload)
    .get();
};

const removeDownloadFileName = (state: CommonState): CommonState => {
  return new MonoliteHelper(state)
    .set(_ => _.downloadFileInfo, null)
    .get();
};

const uploadSuccessed = (state: CommonState, payload: UploadSuccessPayload): CommonState => {
  const fileIndex = state.files.findIndex(x => x.key === payload.key);
  const file = new MonoliteHelper(state.files[fileIndex])
    .set(_ => _.uploadedFileName, payload.result[0])
    .set(_ => _.progress, fileUploadingCompletedProgressValue)
    .get();
  return new MonoliteHelper(state)
    .set(_ => _.files[fileIndex], file)
    .get();
};

const uploadFailed = (state: CommonState, payload: UploadFileFailedPayload): CommonState => {
  const fileIndex = state.files.findIndex(x => x.key === payload.key);
  const helper = new MonoliteHelper(state);
  if (fileIndex > -1) {
    helper.set(_ => _.files[fileIndex].error, payload.error);
  }
  return helper.get();
};

const fileProgress = (state: CommonState, payload: UpdateFileLoadingProgressPayload): CommonState => {
  const fileIndex = state.files.findIndex(x => x.key === payload.key);
  return new MonoliteHelper(state)
    .set(_ => _.files[fileIndex].progress, payload.progress * fileUploadProgressSendingThreshold)
    .get();
};

const removeAllFiles = (state: CommonState): CommonState => {
  for (const file of state.files) {
    file.canceler(ApiConstants.RequestCancelMessage);
  }
  return new MonoliteHelper(state).set(_ => _.files, []).get();
};

const locationsSuccessed = (state: CommonState, payload: Location[]): CommonState => {
  return new MonoliteHelper(state).set(_ => _.locations, payload).get();
};

const sendReporstsText = (state: CommonState, payload: string): CommonState => {
  return new MonoliteHelper(state).set(_ => _.reportsSentDialogText, payload).get();
};

export const commonReducerMethods: ReducerMethods<CommonState> = {
  [CommonActionTypes.UPLOAD_FILES_ADD]: addFile,
  [CommonActionTypes.UPLOAD_FILE_REMOVE]: removeFile,
  [CommonActionTypes.UPLOAD_FILE_SUCCESSED]: uploadSuccessed,
  [CommonActionTypes.FILE_PROGRESS]: fileProgress,
  [CommonActionTypes.REMOVE_ALL_FILES]: removeAllFiles,
  [CommonActionTypes.UPLOAD_FILE_FAILED]: uploadFailed,
  [CommonActionTypes.GET_LOCATIONS_SUCCESSED]: locationsSuccessed,
  [CommonActionTypes.SET_REPORTS_TEXT]: sendReporstsText,
  [CommonActionTypes.DOWNLOAD_FILE]: downloadFile,
  [CommonActionTypes.REMOVE_DOWNLOAD_FILE_NAME]: removeDownloadFileName,
};
