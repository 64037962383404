import { IconInput } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { produce } from 'immer';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { UuidUtil } from 'common/utils/uuid-utils';
import { getNameById } from 'unit-2d-database/components/breakdown-property/utils';
import { Constants } from 'unit-2d-database/constants';
import { formatData } from 'unit-2d-database/helpers/format-data-getter';
import { getDynamicData } from 'unit-2d-database/helpers/get-dynamic-data';
import {
  IconsTypeEnum,
  Item,
  Property,
  UpdateItemRequest,
  VisibilityFilterType,
} from 'unit-2d-database/interfaces';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { TwoDDatabaseActions } from '../../../../store-slice';
import { IconItems } from '../../../icons';
import { RENAME_DIALOG_NAME } from '../../constants';
import { convertPropertiesIterator } from '../../helpers/convert-properties-iterator';
import { getPropertyIterator } from '../../helpers/get-property-iterator';
import { PropertyGroupForm } from '../../helpers/update-property-form';
import { validateName } from '../../helpers/validate-name';
import { SidePanelContentProps } from '../../interfaces';
import { BREAKDOWN_DIALOG_NAME } from '../breakdown-dialog';
import { ConfirmDialogs } from '../confirm-dialogs';
import { DatabaseEmptyList } from '../empty-list-notification';
import { EmptyListName } from '../empty-list-notification/constants';
import { FormulaDialogData, FORMULA_DIALOG_NAME } from '../formula-dialog';
import { GroupPropertiesForm } from '../group-properties-list';
import { Header } from '../header';
import { SelectPropertyPanel } from '../select-property-panel';
import { VisibilityPanel } from '../visibility-panel';
import { FormulaFieldInput } from './helpers/map-formula-property-to-field';
import { GroupForm, mapPropertyGroupForm } from './helpers/map-property-group-form';
import { ItemForm, PropertyGroupUpdater } from './helpers/property-group-form-updater';

import { Styled } from './styled';

interface StateToProps {
  isEdit: boolean;
  data: Item;
  items: Item[];
  properties: Property[];
  visibilityMode: VisibilityFilterType;
  collapsedGroups: Set<string>;
}

interface DispatchToProps {
  close: () => void;
  open: (type: string) => void;
  openFormulaDialog: (formulaDialogData: FormulaDialogData) => void;
  openBreakdownDialog: (data: any) => void;
  openRenameDialog: () => void;
  setOpenCloseDialog: () => void;
  setCollapsedGroups: (ids: Set<string>) => void;
}

export interface OwnState {
  name: string;
  iconType: IconsTypeEnum;
  form: ItemForm;
  selectedIdsMap: Record<string, boolean>;
  propertyGroupUpdater: PropertyGroupUpdater;
  isValid: boolean;
  units: string[];
  showSelectionPanel: boolean;
}

interface OwnProps {
  isShowRenameDialog: (items: Item[], data: Item, formName: string) => boolean;
  update: (payload: UpdateItemRequest, data: Item) => void;
  create: (name: string, properties: Property[], iconType: string) => void;
  skipCloseAfterSubmit?: boolean;
  disableEditName?: boolean;
  afterClose?: () => void;
}

interface Props extends StateToProps,
  DispatchToProps,
  SidePanelContentProps<Property, OwnState>,
  OwnProps,
  AnalyticsProps {
}

const propertyGroupFormKey = 'propertyGroup';

class ItemPanelComponent extends React.PureComponent<Props, OwnState> {
  private updaterParams = {
    baseParams: {
      getForm: this.getForm,
      afterEffects: {
        afterChange: this.changeState,
        afterDelete: this.afterDeleteProperty,
        afterUnitChange: this.changeState,
        afterVisibilityChange: this.changeState,
      },
    },
    propertyGroupParams: {
      afterDeletePropertyGroup: this.afterDeletePropertyGroup,
      afterAddProperty: this.afterAddProperty,
      afterChangeFormula: this.onFormulaChange,
    },
  };

  public constructor(props: Props) {
    super(props);

    this.state = props.stateToApply
      ? this.getApplyState()
      : this.getInitialState();
  }

  public componentDidMount(): void {
    const patchData = this.props.patchState();
    if (patchData) {
      this.patchState(patchData);
    }
    setTimeout(() => {
      this.setState({ showSelectionPanel: true });
    }, 450);
  }

  public render(): JSX.Element {
    const { isEdit, visibilityMode, collapsedGroups, disableEditName } = this.props;
    const { form, selectedIdsMap, iconType, isValid, name } = this.state;
    const showNotification = Object.keys(form.groupForm).length === 0;

    return (
      <Styled.Container>
        <Styled.PanelBlock>
          <Styled.PanelContainer>
            <Styled.ItemForm>
              <Header
                headerIcon={IconItems}
                headerText={'Item'}
                isValid={isValid}
                isEdit={isEdit}
                onSubmit={this.onSubmit}
              />
              <IconInput
                name={'Item name'}
                value={name}
                onChangeValue={this.changeName}
                onChangeIcon={this.onChangeIcon}
                iconListMap={Constants.ICON_LIST_MAP}
                selectedId={iconType}
                disabled={disableEditName}
              />
            </Styled.ItemForm>
            <VisibilityPanel groups={form.groupForm} getGroupKeys={this.getCommonGroupKeys} />
            <Styled.ScrollBarContainer>
              <GroupPropertiesForm
                groups={form.groupForm}
                onAddProperty={this.switchPanel}
                visibilityMode={visibilityMode}
                collapsedGroups={collapsedGroups}
                toggleGroup={this.toggleGroup}
                parentId={propertyGroupFormKey}
              />
              <RenderIf condition={showNotification}>
                <DatabaseEmptyList type={EmptyListName.Items} />
              </RenderIf>
            </Styled.ScrollBarContainer>
          </Styled.PanelContainer>
        </Styled.PanelBlock>
        <Styled.PanelBlock>
          <SelectPropertyPanel
            onSelectProperties={this.handleSelectProperties}
            selectedIdsMap={selectedIdsMap}
            hideTable={!this.state.showSelectionPanel}
          />
        </Styled.PanelBlock>
        <ConfirmDialogs
          closeDialogTitle='Are you sure you want to exit the "Create item" Mode?'
          closeDialogOnClose={this.close}
          renameTitle="Item with the same name already exists"
          renameDialogText="Please rename your item"
        />
      </Styled.Container>
    );
  }

  @autobind
  private getCommonGroupKeys(): Set<string> {
    const { form } = this.state;
    const totalSet = new Set<string>();
    Object.keys(form.groupForm).forEach(propertyGroupKey => {
      totalSet.add(`${propertyGroupFormKey}_${propertyGroupKey}`);
    });
    return totalSet;
  }

  private patchState(property: Property): void {
    const units = this.getUnits(property);
    this.updateUnits(units);
    const groupForm = mapPropertyGroupForm(
      [[{ ...property, id: UuidUtil.generateUuid() }, property]],
      units,
      this.state.propertyGroupUpdater,
      this.onFormulaFieldClick,
      this.onBreakdownFieldClick,
    );
    this.state.propertyGroupUpdater.updateItemForm(groupForm);
  }

  private updateUnits(units: string[]): void {
    const newState = produce(this.state, (s) => {
      s.units = units;
    });
    this.setState(newState);
  }

  @autobind
  private getForm(): ItemForm {
    return this.state.form;
  }

  @autobind
  private changeState(newForm: ItemForm): void {
    this.setState((state) => {
      const newState = produce(state, (s) => {
        s.form.groupForm = newForm.groupForm;
      });
      return newState;
    });
    this.props.setOpenCloseDialog();
  }

  @autobind
  private onFormulaChange(newForm: ItemForm): void {
    const newState = produce(this.state, (s) => {
      s.form.groupForm = newForm.groupForm;
      for (const property of Object.values(newForm.addedProperty)) {
        s.selectedIdsMap[property.originId] = true;
      }
      s.form.addedProperty = newForm.addedProperty;
    });
    this.setState(newState);
  }

  @autobind
  private afterAddProperty(newForm: ItemForm, _: string, propertyGroup: GroupForm): void {
    this.setState((state) => {
      const newFormWithExtendsUnits = produce(newForm, (s) => {
        Object.values(s.groupForm).forEach(p => {
          p.fields.forEach(f => {
            if (f.dropDownProps) {
              const activeElement = f.dropDownProps.elements[f.dropDownProps.activeElementIndex];
              f.dropDownProps.elements = state.units;
              f.dropDownProps.activeElementIndex = f.dropDownProps.elements.indexOf(activeElement);
            }
          });
        });
      });
      const newState = produce(state, (s) => {
        Object.values(propertyGroup).forEach(g => {
          g.fields.forEach(f => {
            s.selectedIdsMap[f.originId] = true;
          });
        });
        s.form = newFormWithExtendsUnits;
      });
      return newState;
    });
    this.props.setOpenCloseDialog();
  }

  @autobind
  private afterDeletePropertyGroup(newForm: ItemForm, propertyGroupName: string): void {
    const newState = produce(this.state, (s) => {
      s.form.groupForm[propertyGroupName].fields.forEach(f => {
        s.selectedIdsMap[f.originId] = false;
      });
      s.form = newForm;
    });
    this.setState(newState);
    this.props.setOpenCloseDialog();
  }

  @autobind
  private afterDeleteProperty(
    newForm: ItemForm,
    propertyGroupName: string,
    _: PropertyGroupForm,
    propertyId: string,
  ): void {
    const newState = produce(this.state, (s) => {
      const field = s.form.groupForm[propertyGroupName].fields.find(f => f.id === propertyId);
      s.selectedIdsMap[field.originId] = false;
      const groupForm = { ...newForm.groupForm };
      if (newForm.groupForm[propertyGroupName].fields.length === 0) {
        delete groupForm[propertyGroupName];
      }
      s.form = {
        ...newForm,
        groupForm,
      };
    });
    this.setState(newState);
    this.props.setOpenCloseDialog();
  }

  private getApplyState(): OwnState {
    const units = this.getUnits();
    const applyState = this.props.stateToApply;
    applyState.units = units;
    applyState.propertyGroupUpdater.applyNewAfterEffects(
      this.updaterParams.baseParams,
      this.updaterParams.propertyGroupParams,
    );
    return applyState;
  }

  private getInitialState(): OwnState {
    const propertyGroupUpdater: PropertyGroupUpdater = new PropertyGroupUpdater(
      this.updaterParams.baseParams,
      this.updaterParams.propertyGroupParams,
    );
    const units = this.getUnits();
    const properties = this.props.data ? this.props.data.properties : [];
    const groups = mapPropertyGroupForm(
      getPropertyIterator(properties, this.props.properties),
      units,
      propertyGroupUpdater,
      this.onFormulaFieldClick,
      this.onBreakdownFieldClick,
    );
    const form: ItemForm = {
      groupForm: groups,
      addedProperty: {},
      removedProperty: {},
    };
    const selectedIdsMap = {};
    properties.forEach(p => {
      const sourceProperty = this.props.properties.find(x => x.name === p.name);
      if (sourceProperty) {
        selectedIdsMap[sourceProperty.id] = true;
      }
    });

    return {
      name: this.props.data ? this.props.data.name : '',
      iconType: this.props.data ? this.props.data.iconType as IconsTypeEnum : IconsTypeEnum.Geomtery,
      form,
      selectedIdsMap,
      propertyGroupUpdater,
      isValid: this.props.isEdit,
      units,
      showSelectionPanel: false,
    };
  }

  private getUnits(property?: Property): string[] {
    const list = property
      ? [...this.props.properties, property]
      : this.props.properties;
    const [, units] = getDynamicData(list);
    return units;
  }

  @autobind
  private switchPanel(): void {
    this.props.switchContent(this.state, this.afterCreateProperty);
  }

  @autobind
  private afterCreateProperty(): void {
    this.props.open(this.props.panelType);
  }

  @autobind
  private changeName(event: React.ChangeEvent<HTMLInputElement>): void {
    const isValid = validateName(event.currentTarget.value);
    this.setState({ name: event.currentTarget.value, isValid });
    this.props.setOpenCloseDialog();
  }

  @autobind
  private onChangeIcon(iconId: IconsTypeEnum): void {
    const newState = produce(this.state, (s) => {
      s.iconType = iconId;
    });
    this.setState(newState);
  }

  @autobind
  private onFormulaFieldClick(id: string, groupName: string): void {
    const field = this.state.form.groupForm[groupName].fields.find(x => x.id === id);
    const propertiesNames = [];
    for (const g of Object.values(this.state.form.groupForm)) {
      for (const { name } of g.fields) {
        propertiesNames.push(name);
      }
    }
    this.props.openFormulaDialog({
      canAddProperties: true,
      propertyName: field.name,
      itemProperties: propertiesNames,
      formulaValue: (field.input as FormulaFieldInput).value,
      onChangeValue: (value: string, propertiesToAdd: string[]) => {
        const groupsForm = mapPropertyGroupForm(
          convertPropertiesIterator(
            this.getNotAddedPropertiesIdByNamesIterator(propertiesToAdd),
            this.props.properties,
          ),
          this.state.units,
          this.state.propertyGroupUpdater,
          this.onFormulaFieldClick,
          this.onBreakdownFieldClick,
        );
        const newField = {
          ...field,
          input: {
            ...(field.input as FormulaFieldInput),
            value,
          },
        };
        this.state.propertyGroupUpdater.changeFormula(groupsForm, newField, groupName);
      },
    });
  }

  @autobind
  private onBreakdownFieldClick(id: string, groupName: string): void {
    const field: any = this.state.form.groupForm[groupName].fields.find(x => x.id === id);
    this.props.openBreakdownDialog({
      propertyName: field.name,
      breakdownValue: field.input.value,
      selectedId: field.input.selectedId,
      root: field.input.root,
      onSelect: (breakdownId: string) => {
        const newField: any = produce(field, (s) => {
          const name = getNameById(field.input.root, breakdownId);
          s.input.value = name;
          s.input.selectedId = breakdownId;
        });
        const [change] = this.state.propertyGroupUpdater.getFieldHandlers(id, groupName);
        change(newField.input);
      },
    });
  }

  private *getNotAddedPropertiesIdByNamesIterator(names: string[]): IterableIterator<string> {
    for (const name of names) {
      const property = this.props.properties.find(p => p.name === name);
      if (property && !this.state.selectedIdsMap[property.id]) {
        yield property.id;
      }
    }
  }

  @autobind
  private handleSelectProperties(ids: string[]): void {
    const groupsFrom = mapPropertyGroupForm(
      convertPropertiesIterator(ids, this.props.properties),
      this.state.units,
      this.state.propertyGroupUpdater,
      this.onFormulaFieldClick,
      this.onBreakdownFieldClick,
    );
    this.state.propertyGroupUpdater.updateItemForm(groupsFrom);
  }

  @autobind
  private onSubmit(): void {
    const { items, openRenameDialog, isEdit, data, isShowRenameDialog } = this.props;
    const isItemExist = isShowRenameDialog(items, data, this.state.name);
    if (isItemExist) {
      openRenameDialog();
    } else if (isEdit) {
      this.update();
    } else {
      this.create();
    }
  }

  private update(): void {
    const { update, data, skipCloseAfterSubmit } = this.props;
    const { name, iconType, form } = this.state;
    const { addedProperties, updatedProperties, deletedProperties } = PropertyGroupUpdater.getData(form);

    update({
      id: data.id,
      name,
      iconType,
      updatedProperties: this.filterEmptyUpdate(updatedProperties),
      addedProperties,
      deletedProperties,
    },
    data);
    if (!skipCloseAfterSubmit) {
      this.close();
    }
  }

  @autobind
  private filterEmptyUpdate(updatedProperties: Property[]): Property[] {
    return updatedProperties.filter(p => {
      const originProperty = this.props.data.properties.find(_ => _.name === p.name);
      if (!originProperty) {
        return true;
      }
      const originUnit = formatData.getUnit(originProperty.value.format);
      const newUnit = formatData.getUnit(p.value.format);
      return p.value.value !== originProperty.value.value || originUnit !== newUnit;
    });
  }

  private create(): void {
    const { create } = this.props;
    const { name, iconType, form } = this.state;
    const { addedProperties } = PropertyGroupUpdater.getData(form);

    create(name, addedProperties, iconType);
    this.close();
    this.props.sendEvent(MetricNames.pia.createItem, { name });
  }

  @autobind
  private close(): void {
    const { close, afterClose } = this.props;
    close();
    if (afterClose) {
      afterClose();
    }
  }

  @autobind
  private toggleGroup(id: string, isOpen: boolean): void {
    const groupsCopy = new Set(this.props.collapsedGroups);
    if (isOpen) {
      groupsCopy.delete(id);
    } else {
      groupsCopy.add(id);
    }
    this.props.setCollapsedGroups(groupsCopy);
  }
}

const mapStateToProps = (state: State): StateToProps => {
  const { data } = state.twoDDatabase.itemPanel;
  const { visibilityMode, collapsedGroups } = state.twoDDatabase.sidePanel;

  return {
    isEdit: !!data,
    data,
    items: state.twoDDatabase.items,
    properties: state.twoDDatabase.properties,
    visibilityMode,
    collapsedGroups,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchToProps => {
  return {
    close: () => {
      dispatch(TwoDDatabaseActions.closeSidePanel());
      dispatch(TwoDDatabaseActions.closeItemPanel());
    },
    open: (type) => {
      dispatch(TwoDDatabaseActions.openSidePanel({ type: type as any, withSideBlock: true }));
    },
    openFormulaDialog: (data) => dispatch(KreoDialogActions.openDialog(FORMULA_DIALOG_NAME, data)),
    openBreakdownDialog: (data) => dispatch(KreoDialogActions.openDialog(BREAKDOWN_DIALOG_NAME, data)),
    openRenameDialog: () => dispatch(KreoDialogActions.openDialog(RENAME_DIALOG_NAME)),
    setOpenCloseDialog: () => dispatch(TwoDDatabaseActions.setOpenCloseDialog()),
    setCollapsedGroups: (ids) => dispatch(TwoDDatabaseActions.setCollapsedGroups(ids)),
  };
};

export const ItemPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAnalyticsContext(ItemPanelComponent));
