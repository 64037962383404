import React, { useCallback, useEffect, useState } from 'react';
import { ConstantFunctions } from 'common/constants/functions';

interface InputHandler {
  isDropdownOpen: boolean;
  openDropdown: () => void;
  closeDropdown: () => void;
  onClickDropDownTextItem: (index: string) => void;
}

export const useInputHandler = (
  onClick: (index: string) => void,
  isNumber?: boolean,
): InputHandler => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const openDropdown = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const onClickDropDownTextItem = useCallback(
    (index) => {
      const element = isNumber ? Number(index) : index;
      onClick(element);
      closeDropdown();
    },
    [closeDropdown, isNumber, onClick],
  );

  return {
    isDropdownOpen,
    openDropdown,
    onClickDropDownTextItem,
    closeDropdown,
  };
};

interface ArrowMoveHandlers {
  focusElement: number;
  handleArrowKeys: (e: React.KeyboardEvent) => void;
  handleOpen: () => void;
}

export const useArrowMove = (
  elements: string[],
  isOpen: boolean,
  open: () => void,
  showValue: string,
  setSearchValue: (value: string) => void,
  handleSelect: (id: string | number) => void,
  isNumber: boolean,
): ArrowMoveHandlers => {
  const [focusElement, setFocusElement] = useState(0);
  const handleOpen = useCallback(() => {
    open();
    const selectIndex = elements.indexOf(showValue);
    const focusIndex = selectIndex === -1 ? 0 : selectIndex;
    setFocusElement(focusIndex);
    setSearchValue('');
  }, [open, showValue, setFocusElement, setSearchValue, elements]);

  const handleBlur = useCallback(() => {
    if (isOpen) {
      handleSelect(showValue);
    }
  }, [isOpen, showValue, handleSelect]);

  useEffect(() => {
    if (elements.length === 1) {
      setFocusElement(0);
    }
    if (focusElement > elements.length - 1) {
      setFocusElement(elements.length - 1);
    }
  }, [elements, focusElement]);

  const handleArrowKeys = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'ArrowUp') {
      ConstantFunctions.preventDefault(e);
      const nextFocus = focusElement === 0 ? elements.length - 1 : focusElement - 1;
      setFocusElement(nextFocus);
    }

    if (e.key === 'ArrowDown') {
      ConstantFunctions.preventDefault(e);
      const nextFocus = focusElement === elements.length - 1 ? 0 : focusElement + 1;
      setFocusElement(nextFocus);
    }

    if (e.key === 'Enter') {
      if (isOpen) {
        const element = isNumber ? Number(elements[focusElement]) : elements[focusElement];
        handleSelect(element);
      } else {
        handleOpen();
      }
    }

    if (e.key === 'Escape') {
      handleBlur();
    }
  }, [focusElement, elements, isOpen, isNumber, handleSelect, handleOpen, handleBlur]);

  return {
    handleArrowKeys,
    handleOpen,
    focusElement,
  };
};
