export class UrlBuilder {
  public static build<TParams>(path: string, params: TParams): string {
    let url = path;
    const paramsArray = Object.entries(params) as Array<[keyof TParams, any]>;
    for (const [paramName, value] of paramsArray) {
      // For example, /:projectId\??/. JS eats one backslash while parsing literal.
      const paramRegex = new RegExp(`:${paramName}(\\(.*?\\))?\\??`);
      if (!paramRegex.test(path)) {
        continue;
        // throw new Error(`Parameter ${paramName} is missing in path`);
      }

      url = url.replace(paramRegex, value);
    }

    return url;
  }
}
