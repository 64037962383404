import styled from 'styled-components';
import { className } from 'common/components/ag-grid-cell-with-comment/ag-grid-cell-with-comment';

const Container = styled.div<{ canEdit: boolean }>`
  width: 100%;
  height: 100%;

  div[row-id="rowGroupFooter_ROOT_NODE_ID"] {
    font-weight: 600;
  }

  .ag-header-cell-label .ag-header-icon.ag-sort-order {
    display: none
  }

  .ag-right-aligned-cell {
    div {
      display: inherit !important; 
    }
  }

  .ag-cell:has(.selected_comment_hight_light) {
    background: ${p => p.theme.color.green} !important;
    opacity: 0.5;
  }

  .ag-header {
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
    &-row {
      color: ${p => p.theme.color.mainFont};
      font-size: 14px;
      line-height: 23px;
      font-weight: 600;
    }
    &-cell {
      padding-left: 20px;
    }
    .ag-header-group-cell.ag-focus-managed.ag-header-group-cell-with-group {
      border-color: ${p => p.theme.color.background};
    }
  }
  .ag-header-cell::after {
    background-color: ${p => p.theme.color.background};
  }
  .ag-menu-separator-cell:after {
    border-color: ${p => p.theme.color.background};
  }
  .ag-header-cell.ag-header-cell-moving {
    background-color: ${p => p.theme.color.background};
  }
  .ag-header-cell.ag-focus-managed.ag-header-cell-sortable {
    border-color: ${p => p.theme.color.background};
  }
  .ag-header-viewport {
    .ag-header-cell.ag-focus-managed {
      border-color: ${p => p.theme.color.background};
    }
  }
  .ag-header-row:not(:first-child).ag-header-cell {
    border-color: ${p => p.theme.color.background};
  }
  .ag-row {
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
    color: ${p => p.theme.color.mainFont};
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    cursor: pointer;
    &.ag-row-hover {
      background-color: ${p => p.theme.color.pale};
    }
    &.ag-row-focus {
      display: flex;
      align-items: center;
      box-shadow: inset 0 0 0px 2px ${p => p.theme.color.turquoise};
    }
  }
  .ag-cell-value {
    > div {
      display: flex;
      align-items: center;
      height: 100%;
      span:first-child {
        height: 20px;
        svg {
          fill: ${p => p.theme.color.gray};
        }
      }

      > div {
        div {
          background-color: ${p => p.theme.color.turquoise};
          p {
            color: ${p => p.theme.color.white};
          }
        }
        p {
          color: ${p => p.theme.color.mainFont};
        }
      }
    }
  }
  .ag-row-group {
    font-weight: 600;
  }
  .ag-group-value {
    display: flex;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      height: 100%;
      span:first-child {
        height: 20px;
        svg {
          fill: ${p => p.theme.color.gray};
          g {
            path {
              stroke: ${p => p.theme.color.gray};
            }
          }
        }
      }
    }
  }
  .ag-ltr .ag-row-group-indent-1 {
    align-items: center;
  }
  .ag-root-wrapper {
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
  }
  .ag-icon {
    &.ag-icon-tree-closed {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-tree-open {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-desc {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-asc {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-menu {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-filter {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-columns {
      color: ${p => p.theme.color.turquoise};
    }
    &.ag-icon-grip:before {
      color: ${p => p.theme.color.gray};
    }
  }
  .ag-icon.ag-icon-small-right.ag-column-drop-cell-separator.ag-column-drop-horizontal-cell-separator {
    color: ${p => p.theme.color.mainFont};
  }
  .ag-menu {
    color: ${p => p.theme.color.mainFont};
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
  }
  .ag-menu-header {
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
  }
  .ag-tab-selected {
    background-color: ${p => p.theme.color.background};
    border-color: ${p => p.theme.color.background};
  }
  input.ag-input-field-input {
    background-color: ${p => p.theme.color.background};
  }
  .ag-filter-header-container {
    border-bottom-color: ${p => p.theme.color.background};
  }
  .ag-filter-separator {
    border-color: ${p => p.theme.color.background};
  }
  .ag-column-select-header {
    border-bottom-color: ${p => p.theme.color.background};
  }
  .ag-cell.ag-cell-auto-height.ag-cell-range-left.ag-cell-inline-editing {
    left: 5px !important;
  }
  .ag-cell-inline-editing {
    border-radius: 5px;
    border-color: ${p => p.theme.color.background};
    input[class^=ag-][type=text] {
      border-radius: 5px;
      border-color: ${p => p.theme.color.background};
      :focus {
        box-shadow: none;
        border-radius: 5px;
        border-color: ${p => p.theme.color.background};
      }
    }
  }
  input[class^=ag-][type=text] {
    border-color: ${p => p.theme.color.background};
    :focus {
      border-color: ${p => p.theme.color.background};
      box-shadow: none;
    }
  }
  .ag-menu-option-active {
    background-color: ${p => p.theme.color.pale};
  }
  .ag-checkbox-input-wrapper {
    cursor: pointer;
    background-color: ${p => p.theme.color.background};
    :focus-within {
      box-shadow: none;
    }
    &.ag-checked::after {
      color: ${p => p.theme.color.turquoise};
    }
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  border-right-color: ${p => p.theme.color.background};
  }
  .ag-pinned-left-header {
    border-right-color: ${p => p.theme.color.background};
  }
  .ag-pinned-right-header {
    border-left-color: ${p => p.theme.color.background};
    .ag-header-cell.ag-focus-managed {
      border-color: ${p => p.theme.color.background};
    }
  }
  .ag-pinned-left-header {
    border-left-color: ${p => p.theme.color.background};
    .ag-header-cell.ag-focus-managed {
      border-color: ${p => p.theme.color.background};
    }
  }
  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    border-left-color: ${p => p.theme.color.background};
  }

  .ag-tool-panel-wrapper {
    background-color: ${p => p.theme.color.backgroundRush};
    .ag-column-panel-column-select {
      border-color: ${p => p.theme.color.background};
      .ag-virtual-list-item.ag-column-select-virtual-list-item {
        color: ${p => p.theme.color.mainFont};
      }
    }
    .ag-pivot-mode-panel {
      color: ${p => p.theme.color.mainFont};
    }
    .ag-column-drop-cell.ag-column-drop-vertical-cell {
      background-color: ${p => p.theme.color.pale};
      color: ${p => p.theme.color.mainFont};
      .ag-column-drop-cell-button.ag-column-drop-vertical-cell-button {
        color: ${p => p.theme.color.red};
      }
    }
    .ag-unselectable.ag-column-drop.ag-column-drop-vertical {
      border-color: ${p => p.theme.color.background};
    }
    .ag-column-drop-empty {
      border-color: ${p => p.theme.color.background};
      .ag-column-drop-vertical-empty-message {
        color: ${p => p.theme.color.gray};
      }
      .ag-column-drop-vertical-title {
        color: ${p => p.theme.color.mainFont};
      }
      .ag-column-drop-vertical-icon {
        color: ${p => p.theme.color.gray};
      }
    }
    .ag-column-panel {
      .ag-icon.ag-icon-aggregation.ag-column-drop-icon.ag-column-drop-vertical-icon {
        color: ${p => p.theme.color.gray};
      }
      .ag-icon.ag-icon-pivot.ag-column-drop-icon.ag-column-drop-vertical-icon {
        color: ${p => p.theme.color.gray};
      }
      .ag-icon.ag-icon-group.ag-column-drop-icon.ag-column-drop-vertical-icon {
        color: ${p => p.theme.color.gray};
      }
      .ag-column-drop-title.ag-column-drop-vertical-title {
        color: ${p => p.theme.color.mainFont};
      }
    }
  }
  .ag-virtual-list-item {
    cursor: pointer;
  }
  .ag-unselectable.ag-column-drop.ag-column-drop-horizontal {
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
    color: ${p => p.theme.color.gray};
  }
  .ag-column-drop-empty-message.ag-column-drop-horizontal-empty-message {
    color: ${p => p.theme.color.disabled};
  }
  .ag-select-agg-func-popup.ag-ltr.ag-popup-child {
    background-color: ${p => p.theme.color.backgroundRush};
    border-color: ${p => p.theme.color.background};
    color: ${p => p.theme.color.mainFont};
  }
  .ag-column-drop-cell.ag-column-drop-horizontal-cell {
    background-color: ${p => p.theme.color.pale};
    color: ${p => p.theme.color.mainFont};
    pointer-events: ${p => p.canEdit
    ? 'auto'
    : 'none'};
  }
  .ag-column-drop-cell-button.ag-column-drop-horizontal-cell-button {
    color: ${p => p.theme.color.red};
  }
  .ag-select-agg-func-virtual-list-item:hover {
    background-color: ${p => p.theme.color.turquoise};
  }
  .ag-side-bar.ag-unselectable.ag-side-bar-right {
    border-color: ${p => p.theme.color.background};
    .ag-tool-panel-wrapper {
      border-color: ${p => p.theme.color.background};
      .ag-filter-toolpanel-header {
        background-color: ${p => p.theme.color.background};
        color: ${p => p.theme.color.mainFont};
      }
      .ag-filter-toolpanel-group-level-0 {
        border-color: ${p => p.theme.color.backgroundRush};
        .ag-filter-toolpanel-instance-filter {
          border-color: ${p => p.theme.color.background};
        }
      }
    }
    .ag-side-button {
      color: ${p => p.theme.color.mainFont};
      > button {
        border-color: ${p => p.theme.color.background};
      }
    }
    .ag-side-button.ag-selected {
      border-color: ${p => p.theme.color.background};
      > button {
        background-color: ${p => p.theme.color.background};
      }
    }
  }
  .ag-checkbox-input-wrapper {
    background-color: ${p => p.theme.color.background};
  }
  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    color: ${p => p.theme.color.turquoise};
  }
  .ag-checkbox-input-wrapper::after {
    color: ${p => p.theme.color.disabled};
  }
  .ag-toggle-button-input-wrapper {
    background-color: ${p => p.theme.color.disabled};
    border-color: ${p => p.theme.color.backgroundRush};
    &:focus-within {
      box-shadow: none;
    }
  }
  .ag-toggle-button-input-wrapper.ag-checked {
    background-color: ${p => p.theme.color.turquoise};
    border-color: ${p => p.theme.color.backgroundRush};
  }
  .ag-toggle-button-input-wrapper::before{
    background-color: ${p => p.theme.color.background};
    border: none;
  }
  .ag-input-field {
    color: ${p => p.theme.color.mainFont};
  }
  .ag-text-field-input-wrapper {
    color: ${p => p.theme.color.mainFont};
  }
  .ag-filter-no-matches {
    color: ${p => p.theme.color.mainFont};
  }
  .ag-input-field-input.ag-text-field-input {
    border-radius: 5px;
    &::placeholder {
      color: ${p => p.theme.color.disabled};
    }
  }
  .ag-input-field-input.ag-number-field-input {
    border-radius: 5px;
    background-color: ${p => p.theme.color.background};
    &::placeholder {
      color: ${p => p.theme.color.disabled};
    }
  }
  input[class^='ag-'] {
    cursor: pointer;
  }
  .ag-ltr input[class^='ag-'][type='number'] {
    border: none;
    box-shadow: none;
  }
  .ag-wrapper.ag-picker-field-wrapper {
    background-color: ${p => p.theme.color.background};
    border: none;
    &:focus {
      box-shadow: none;
    }
    .ag-picker-field-icon {
      color: ${p => p.theme.color.gray};
    }
    .ag-picker-field-display {
      color: ${p => p.theme.color.mainFont};
    }
  }
  .ag-select-list-item {
    background-color: ${p => p.theme.color.background};
    color: ${p => p.theme.color.mainFont};
  }
  .ag-list-item.ag-active-item {
    background-color: ${p => p.theme.color.pale};
  }
  .ag-radio-button-input-wrapper.ag-checked {
    box-shadow: none;
    &::after {
      color: ${p => p.theme.color.turquoise};
      box-shadow: none;
    }
  }
  .ag-wrapper.ag-input-wrapper.ag-radio-button-input-wrapper {
    background-color: ${p => p.theme.color.background};
    box-shadow: none;
  }
  .ag-radio-button-input-wrapper::after {
    color: ${p => p.theme.color.disabled};
  }
  .ag-menu-separator-part:after {
    border-color: ${p => p.theme.color.background};
  }
  .ag-header-group-cell::after {
    background-color: ${p => p.theme.color.background};
  }
  .ag-header-icon.ag-header-expand-icon.ag-header-expand-icon-collapsed {
    color: ${p => p.theme.color.gray};
  }
  .ag-menu-option-part.ag-menu-option-icon {
    color: ${p => p.theme.color.gray};
    fill: ${p => p.theme.color.gray};
  }
  .ag-header-icon.ag-header-expand-icon.ag-header-expand-icon-expanded {
    color: ${p => p.theme.color.gray};
  }
  .ag-panel.ag-default-panel.ag-dialog.ag-ltr.ag-popup-child {
    border-radius: 10px;
    border-color: ${p => p.theme.color.background};
    background-color: ${p => p.theme.color.backgroundRush};
    color: ${p => p.theme.color.mainFont};
    .ag-panel-title-bar.ag-default-panel-title-bar.ag-unselectable {
      border-color: ${p => p.theme.color.background};
      background-color: ${p => p.theme.color.backgroundRush};
      color: ${p => p.theme.color.mainFont};
      .ag-panel-title-bar-buttons.ag-default-panel-title-bar-buttons {
        color: ${p => p.theme.color.gray};
      }
    }
    .ag-panel.ag-chart-menu-panel{
      border-color: ${p => p.theme.color.background};
      .ag-tabs.ag-chart-tabbed-menu.ag-focus-managed {
        background-color: ${p => p.theme.color.backgroundRush};
      }
      .ag-group-title-bar.ag-charts-settings-group-title-bar.ag-unselectable {
        background-color: ${p => p.theme.color.background};
        border-color: ${p => p.theme.color.background};
      }
      .ag-tabs-header.ag-chart-tabbed-menu-header {
        border-color: ${p => p.theme.color.background};
      }
      .ag-chart-mini-thumbnail.ag-group-item.ag-charts-settings-group-item {
        border-color: ${p => p.theme.color.background};
        &:hover {
          border-color: ${p => p.theme.color.turquoise};
        }
      }
      .ag-chart-settings-nav-bar {
        color: ${p => p.theme.color.gray};
        .ag-chart-settings-card-item.ag-selected {
          background-color: ${p => p.theme.color.turquoise};
        }
        .ag-chart-settings-card-item.ag-not-selected {
          background-color: ${p => p.theme.color.disabled};
        }
      }
      .ag-group-title-bar.ag-charts-data-group-title-bar.ag-unselectable {
        background-color: ${p => p.theme.color.background};
        border-color: ${p => p.theme.color.background};
      }
      .ag-group-title-bar.ag-charts-format-top-level-group-title-bar.ag-unselectable {
        background-color: ${p => p.theme.color.background};
        border-color: ${p => p.theme.color.background};
      }
      .ag-group-title-bar.ag-charts-format-sub-level-group-title-bar.ag-unselectable {
        background-color: ${p => p.theme.color.background};
      }
      .ag-group-toolbar {
        background-color: ${p => p.theme.color.pale};
        .ag-charts-format-sub-level-group-toolbar {
          background-color: ${p => p.theme.color.pale};
          border-color: ${p => p.theme.color.background};
        }
      }
      .ag-angle-select-parent-circle {
        background-color: ${p => p.theme.color.background};
        border-color: ${p => p.theme.color.turquoise};
        .ag-angle-select-child-circle {
          background-color: ${p => p.theme.color.turquoise};
        }
      }
    }
  }
  .ag-chart-menu {
    border-radius: 5px;
    background-color: ${p => p.theme.color.background};
    .ag-chart-menu-icon {
      color: ${p => p.theme.color.gray};
    }
  }

  input[type='range']::-webkit-slider-thumb {
    background-color: ${p => p.theme.color.pale} !important;
    border-color:  ${p => p.theme.color.disabled} !important;
  }
  input[type='range']:focus::-webkit-slider-thumb {
    box-shadow: none !important;
    background-color: ${p => p.theme.color.turquoise} !important;
    border-color: ${p => p.theme.color.background} !important;
  }
  input[type='range']::-webkit-slider-runnable-track {
    background-color: ${p => p.theme.color.disabled} !important;
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background-color: ${p => p.theme.color.turquoise} !important;
  }

  input[type='range']::-moz-range-thumb {
    background-color: ${p => p.theme.color.pale} !important;
    border-color:  ${p => p.theme.color.disabled} !important;
  }
  input[type='range']:focus::-moz-range-thumb {
    box-shadow: none !important;
    background-color: ${p => p.theme.color.turquoise} !important;
    border-color: ${p => p.theme.color.background} !important;
  }
  input[type='range']::-moz-range-track {
    background-color: ${p => p.theme.color.disabled} !important;
  }
  input[type='range']:focus::-moz-range-track {
    background-color: ${p => p.theme.color.turquoise} !important;
  }

  .ag-filter {
    .ag-filter-separator {
      border-color: ${p => p.theme.color.background}
    }
  }
  *::-webkit-scrollbar-thumb {
    background-color:  ${p => p.theme.color.disabled};
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: ${p => p.theme.color.gray};
  }
  *::-webkit-scrollbar-track {
    background-color: ${p => p.theme.color.background};
  }
  .ag-chart-title-edit.currently-editing {
    background-color: ${p => p.theme.color.white};
    color: ${p => p.theme.color.mainFont};
  }
  input[class^='ag-']:not([type]):focus {
    box-shadow: none;
    border-color: ${p => p.theme.color.background};
  }
  input[class^='ag-'][type='text']:disabled {
    border-color: ${p => p.theme.color.disabled};
    color: ${p => p.theme.color.disabled};
    background-color: ${p => p.theme.color.background};
  }
  .ag-overlay-wrapper.ag-layout-normal.ag-overlay-no-rows-wrapper {
    color: ${p => p.theme.color.mainFont};
  }
  .ag-header-group-cell.ag-header-cell-moving {
    background-color: ${p => p.theme.color.background};
  }
  .ag-column-drop-list.ag-column-drop-horizontal-list {
    overflow: auto;
  }
  .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height {
    .${className} {
      background: ${p => p.theme.color.turquoise};

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        right: inherit;
        background: transparent;
      }

      &:hover {
        width: 10px !important;
        height: 10px !important;
        transition: all 0.35s cubic-bezier(0.72, 0.04, 0.78, 1.62);
      }
    }
  }
`;

export const Styled = {
  Container,
};
