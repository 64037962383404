import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { ActivityCategory } from '../../../../units/databases/interfaces/data';
import { ClassificationAssignmentStage } from '../../enums/classification-assignment-stage';
import { ClassificationComparisonPatternGroup } from '../../interfaces/classification/comparison-pattern-group';
import { CurrentElementClassification } from '../../interfaces/classification/current-element-classification';
import {
  ClassificationOntologyGraphResponse,
  ClassificationOntologyVariantTree,
} from '../../interfaces/classification/ontology-data';
import { UniClassCategories } from '../../interfaces/classification/uniclass-categories';
import { RevitTreePathInfo } from '../../interfaces/revit-tree-path-info';
import {
  ClassificationBreadcrumbClickPayload,
  ClassificationCopySuccessPayload,
  ClassificationElementTypeInfo,
  ClassificationLoadBimInfoSuccessPayload,
  ClassificationNodeActionBasePayload,
  ClassificationPasteCompletePayload,
  ClassificationSelectItemPayload,
  ClassificationSelectVariantFromTreePayload,
} from '../payloads/classification';
import { ValidationStepStatisticDataPayload } from '../payloads/validation';
import { ClassificationActionTypes } from '../types/classification';

function getStatisticRequest(projectId: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.GET_STATISTIC_REQUEST,
    payload: projectId,
  };
}

function getStatisticSuccess(
  statistic: ValidationStepStatisticDataPayload,
): ActionWith<ValidationStepStatisticDataPayload> {
  return {
    type: ClassificationActionTypes.GET_STATISTIC_SUCCESS,
    payload: statistic,
  };
}

function assignClassification(): Action {
  return {
    type: ClassificationActionTypes.ASSIGN_CLASSIFICATION,
  };
}


function toAssignment(index: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.TO_ASSIGNMENT,
    payload: index,
  };
}

function setCurrentTab(id: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.SET_CURRENT_TAB,
    payload: id,
  };
}

function onHeaderBackClick(): Action {
  return {
    type: ClassificationActionTypes.ON_HEADER_BACK_CLICK,
  };
}


function loadData(projectId: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.LOAD_DATA,
    payload: projectId,
  };
}

function dropState(): Action {
  return {
    type: ClassificationActionTypes.DROP_STATE,
  };
}

function collapseAllTree(): Action {
  return {
    type: ClassificationActionTypes.COLLAPSE_ALL_TREE,
  };
}

function onBreadCrumbClick(
  breadcrumb: RevitTreePathInfo,
  isMultiselectAvaible: boolean,
): ActionWith<ClassificationBreadcrumbClickPayload> {
  return {
    type: ClassificationActionTypes.ON_BREAD_CRUMB_CLICK,
    payload: {
      breadcrumb,
      isMultiselectAvaible,
    },
  };
}

function isolateNode(id: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.ISOLATE_NODE,
    payload: id,
  };
}


function loadBimInfoSuccess(
  bimInfo: ClassificationLoadBimInfoSuccessPayload): ActionWith<ClassificationLoadBimInfoSuccessPayload> {
  return {
    type: ClassificationActionTypes.LOAD_BIM_MODEL_INFO_SUCCESS,
    payload: bimInfo,
  };
}

function toggleCollapseExpandStatus(index: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.TOGGLE_COLLAPSE_EXPAND_STATUS,
    payload: index,
  };
}

function loadElementGraphSuccess(
  graph: ClassificationOntologyGraphResponse[],
): ActionWith<ClassificationOntologyGraphResponse[]> {
  return {
    type: ClassificationActionTypes.LOAD_ELEMENT_ONTOLOGY_SUCCESS,
    payload: graph,
  };
}

function removeNode(
  graphId: number,
  nodeId: string,
): ActionWith<ClassificationNodeActionBasePayload> {
  return {
    type: ClassificationActionTypes.REMOVE_NODE,
    payload: {
      graphId,
      nodeId,
    },
  };
}

function productFilter(value: string): ActionWith<string> {
  return {
    type: ClassificationActionTypes.CHANGE_PRODUCT_FILTER,
    payload: value,
  };
}

function systemFilter(value: string): ActionWith<string> {
  return {
    type: ClassificationActionTypes.CHANGE_SYSTEM_FILTER,
    payload: value,
  };
}

function setCustomProduct(value: ActivityCategory): ActionWith<ActivityCategory> {
  return {
    type: ClassificationActionTypes.SET_CUSTOM_PRODUCT,
    payload: value,
  };
}

function setCustomSystem(value: ActivityCategory): ActionWith<ActivityCategory> {
  return {
    type: ClassificationActionTypes.SET_CUSTOM_SYSTEM,
    payload: value,
  };
}

function setSelectedUniclass(value: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.SET_SELECTED_UNICLASS,
    payload: value,
  };
}

function editAssebmlyClassification(): Action {
  return {
    type: ClassificationActionTypes.EDIT_ASSEMBLY_CLASSIFICATION,
  };
}

function editLayerClassification(index: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.EDIT_LAYER_CLASSIFICATION,
    payload: index,
  };
}

function toOldAssignment(index: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.TO_OLD_ASSIGNMENT,
    payload: index,
  };
}

function setCustomAssemblySystem(
  value: ActivityCategory,
): ActionWith<ActivityCategory> {
  return {
    type: ClassificationActionTypes.SET_CUSTOM_ASSEMBLY_SYSTEM,
    payload: value,
  };
}

function setCustomLayerProduct(value: ActivityCategory): ActionWith<ActivityCategory> {
  return {
    type: ClassificationActionTypes.SET_CUSTOM_LAYER_PRODUCT,
    payload: value,
  };
}

function saveUniclassData(uniClassCategories: UniClassCategories): ActionWith<UniClassCategories> {
  return {
    type: ClassificationActionTypes.SAVE_UNICLASS_DATA,
    payload: uniClassCategories,
  };
}

function saveCurrentClassifition(
  classification: CurrentElementClassification[][],
): ActionWith<CurrentElementClassification[][]> {
  return {
    type: ClassificationActionTypes.SAVE_CURRENT_CLASSIFICATION,
    payload: classification,
  };
}

function changeAssignmentLevel(level: ClassificationAssignmentStage): ActionWith<ClassificationAssignmentStage> {
  return {
    type: ClassificationActionTypes.CHANGE_ASSIGNMENT_LEVEL,
    payload: level,
  };
}

function createCustomUniclass(): Action {
  return {
    type: ClassificationActionTypes.CREATE_CUSTOM_UNICLASS,
  };
}

function setAssemblyRootClassification(): Action {
  return {
    type: ClassificationActionTypes.SET_ASSEMBLY_ROOT_CLASSIFICATION,
  };
}

function setAssemblyLayerClassification(): Action {
  return {
    type: ClassificationActionTypes.SET_ASSEMBLY_LAYER_CLASSIFICATION,
  };
}

function toggleUnclassifiedFilterStatus(): Action {
  return {
    type: ClassificationActionTypes.TOGGLE_UNCLASSIFIED_FILTER,
  };
}

function toggleSearchMode(): Action {
  return {
    type: ClassificationActionTypes.TOGGLE_SEARCH_MODE,
  };
}

function changeSearchQuery(query: string): ActionWith<string> {
  return {
    type: ClassificationActionTypes.CHANGE_SEARCH_QUERY,
    payload: query,
  };
}


function copyClassification(index: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.COPY_CLASSIFICATION,
    payload: index,
  };
}

function pasteClassification(): Action {
  return {
    type: ClassificationActionTypes.PASTE_CLASSIFICATION,
  };
}

function copyClassificationSuccess(
  payload: ClassificationCopySuccessPayload,
): ActionWith<ClassificationCopySuccessPayload> {
  return {
    type: ClassificationActionTypes.COPY_CLASSIFICATION_SUCCESS,
    payload,
  };
}

function pasteClassificationSuccess(
  notPasted: ClassificationElementTypeInfo[],
  pasted: ClassificationElementTypeInfo[],
  isUndefined: boolean,
): ActionWith<ClassificationPasteCompletePayload> {
  return {
    type: ClassificationActionTypes.PASTE_CLASSIFICATION_SUCCESS,
    payload: {
      pastedElementTypes: pasted,
      notPastedElementTypes: notPasted,
      isUndefined,
    },
  };
}

function onSelect(
  index: number,
  value: boolean,
): ActionWith<ClassificationSelectItemPayload> {
  return {
    type: ClassificationActionTypes.ON_SELECT,
    payload: {
      index,
      value,
    },
  };
}

function onSelectAll(value: boolean): ActionWith<boolean> {
  return {
    type: ClassificationActionTypes.SELECT_ALL,
    payload: value,
  };
}

function selectNotPasted(): Action {
  return {
    type: ClassificationActionTypes.SELECT_NOT_PASTED,
  };
}

function selectPasted(): Action {
  return {
    type: ClassificationActionTypes.SELECT_PASTED,
  };
}

function selectByEngineIds(ids: number[]): ActionWith<number[]> {
  return {
    type: ClassificationActionTypes.SELECT_BY_ENGINE_IDS,
    payload: ids,
  };
}

function tryStartMultipleEdit(): Action {
  return {
    type: ClassificationActionTypes.TRY_START_MULTIPLE_EDIT,
  };
}

function selectGroup(index: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.SELECT_GROUP,
    payload: index,
  };
}

function editGroup(index: number): ActionWith<number> {
  return {
    type: ClassificationActionTypes.EDIT_GROUP,
    payload: index,
  };
}

function saveGrouping(
  groups: ClassificationComparisonPatternGroup[],
): ActionWith<ClassificationComparisonPatternGroup[]> {
  return {
    type: ClassificationActionTypes.SAVE_GROUPING,
    payload: groups,
  };
}

function selectTreeNode(
  nodeId: number,
): ActionWith<number> {
  return {
    type: ClassificationActionTypes.SELECT_TREE_NODE,
    payload: nodeId,
  };
}

function selectTreeNodeByEngineId(
  engineId: number,
): ActionWith<number> {
  return {
    type: ClassificationActionTypes.SELECT_TREE_NODE_BY_ENGINE_ID,
    payload: engineId,
  };
}

function selectNodeVariant(
  graphId: number,
  nodeId: string,
  variant: ClassificationOntologyVariantTree,
): ActionWith<ClassificationSelectVariantFromTreePayload> {
  return {
    type: ClassificationActionTypes.SELECT_NODE_VARIANT,
    payload: { graphId, nodeId, variant },
  };
}

export const ClassificationActions = {
  getStatisticRequest,
  getStatisticSuccess,
  assignClassification,
  toAssignment,
  setCurrentTab,
  onHeaderBackClick,
  loadData,
  dropState,
  collapseAllTree,
  onBreadCrumbClick,
  isolateNode,
  loadBimInfoSuccess,
  toggleCollapseExpandStatus,
  loadElementGraphSuccess,
  productFilter,
  systemFilter,
  setCustomProduct,
  setCustomSystem,
  setSelectedUniclass,
  editAssebmlyClassification,
  editLayerClassification,
  toOldAssignment,
  setCustomAssemblySystem,
  setCustomLayerProduct,
  saveUniclassData,
  saveCurrentClassifition,
  changeAssignmentLevel,
  createCustomUniclass,
  setAssemblyRootClassification,
  setAssemblyLayerClassification,
  toggleUnclassifiedFilterStatus,
  removeNode,
  toggleSearchMode,
  changeSearchQuery,
  copyClassification,
  pasteClassification,
  copyClassificationSuccess,
  pasteClassificationSuccess,
  onSelect,
  onSelectAll,
  selectNotPasted,
  selectPasted,
  selectByEngineIds,
  tryStartMultipleEdit,
  selectGroup,
  editGroup,
  saveGrouping,
  selectTreeNode,
  selectTreeNodeByEngineId,
  selectNodeVariant,
};
