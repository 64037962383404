import autobind from 'autobind-decorator';
import * as React from 'react';

import { Clipboard } from '../../../../../../utils/clipboard';
import { ModelCheckPriority } from '../../../../enums/mode-check-priority';
import { ModelCheckType } from '../../../../enums/model-check-type';
import { BasePropertiesHeader } from '../../../validation/components/properties';
import { CopyToClipBoard } from '../../components/copy-to-clipboard';
import { TipPositions } from '../../components/tip-positions';
import { TooltipIcon } from '../../components/tooltip-icon';


interface Props {
  family: string;
  id: string;
  canCollapse?: boolean;
  priority?: ModelCheckPriority;
  type?: ModelCheckType;
  closeClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  hasBottom?: boolean;
  bottomId?: string;
}


export class Header extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    canCollapse: false,
  };

  public render(): JSX.Element {
    return (
      <BasePropertiesHeader
        closeClick={this.props.closeClick}
        hasCollapseButton={this.props.canCollapse}
      >
        {this.props.canCollapse &&
          <TooltipIcon
            modelCheckType={this.props.type}
            type={this.props.priority}
            tipPosition={TipPositions.TOP}
          />
        }
        <span className='checker-properties-top-panel-family'>
          {this.props.family}
        </span>
        <span className='checker-properties-top-panel-id'>
          [{this.props.id}]
          </span>
        {this.props.canCollapse && (<CopyToClipBoard
          tipPosition='top'
          text={`Copy ${this.props.hasBottom ? 'ids' : 'id'} to clipboard`}
          onClick={this.onClipboardCopy}
        />)}
      </BasePropertiesHeader>
    );
  }

  @autobind
  private onClipboardCopy(): void {
    if (this.props.hasBottom) {
      Clipboard.copy(`${this.props.id} ${this.props.bottomId}`);
    } else {
      Clipboard.copy(this.props.id);
    }
  }
}
