import * as React from 'react';

import './comment-text.scss';

import { TextFormatter } from '../text-formatter';
import { TimeStamp } from '../time-stamp';

interface Props {
  text: string;
  imageUri?: string;
  onScreenClick?: () => void;
  created: Date | undefined;
}

export const CommentText: React.FC<Props> = (props) => {
  return (
  <div
    className={`viewer-text-comment ${props.imageUri ? ' x' : ''}
    ${(!props.text || props.text !== '') ? 'y' : ''}`}
  >
    {props.created && <TimeStamp stamp={props.created}/>}
    {props.imageUri && <img src={props.imageUri} onClick={props.onScreenClick} />}
    {!!props.text && <TextFormatter>{props.text}</TextFormatter>}
  </div>);
};
