import * as React from 'react';

export const KreoIconFullScreenExit: React.FC = () => {
  return (
    <svg
      fill='currentColor'
      height='24px'
      viewBox='0 0 24 24'
      width='24px'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z' />
    </svg>
  );
};
