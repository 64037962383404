import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { KreoDialogActions } from '../actions';


export function useOpenCloseDialog(dialogName: string): [(data?: any) => void, () => void] {
  const dispatch = useDispatch();
  const openDialog = useCallback(
    (data?: any) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    [dispatch, dialogName],
  );
  const closeDialog = useCallback(
    () => dispatch(KreoDialogActions.closeDialog(dialogName)),
    [dispatch, dialogName],
  );
  return [openDialog, closeDialog];
}
