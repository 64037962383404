import { Hotkey, OS } from 'common/hotkeys';

export const DrawingToolHotkey = {

  SelectTool: [
    new Hotkey('1', { shortcutToShow: '1', splitSymbol: ',', splitterToShow: ',' }),
    new Hotkey('num_1', { shortcutToShow: '1', splitSymbol: ',', splitterToShow: ',' }),
    new Hotkey('V'),
  ],
  PolylineTool: [
    new Hotkey('2'),
    new Hotkey('num_2', { shortcutToShow: '2' }),
  ],
  PolygonTool: [
    new Hotkey('3'),
    new Hotkey('num_3', { shortcutToShow: '3' }),
  ],
  Rectangle2PointTool: [
    new Hotkey('4'),
    new Hotkey('num_4', { shortcutToShow: '4' }),
  ],
  Rectangle3PointTool: [
    new Hotkey('5'),
    new Hotkey('num_5', { shortcutToShow: '5' }),
  ],
  AutoCountTool: [
    new Hotkey('6'),
    new Hotkey('num_6', { shortcutToShow: '6' }),
  ],
  CountTool: [
    new Hotkey('7'),
    new Hotkey('num_7', { shortcutToShow: '7' }),
  ],
  StickersTool: [
    new Hotkey('N'),
  ],
  RulerTool: [
    new Hotkey('R'),
  ],
};

export const ZoomHotkey = {
  ZoomIn: [
    new Hotkey('Shift + =', { shortcutToShow: 'Shift & +', splitSymbol: '&', splitterToShow: '+' }),
    new Hotkey('num_add'),
  ],
  ZoomOut: [
    new Hotkey('-'),
    new Hotkey('num_subtract', { shortcutToShow: '-' }),
  ],
  Home: [
    new Hotkey('0'),
    new Hotkey('num_0', { shortcutToShow: '0' }),
  ],
};

export const MoveDrawingHotkey = {
  MoveDrawing: [
    new Hotkey('Middle mouse button'),
    new Hotkey('Space + Left mouse button'),
  ],
};


export const ModifyHotkey = {
  Escape: [
    new Hotkey('Esc'),
  ],
  RemoveModifyPoints: [
    new Hotkey('Delete', { os: OS.Windows }),
    new Hotkey('Backspace'),
  ],
  FinishDraw: [
    new Hotkey('Enter'),
  ],
  RemoveLastDrawnPoint: [
    new Hotkey('Backspace'),
    new Hotkey('Ctrl + Z'),
  ],
  StrictAngleMode: [
    new Hotkey('Shift + Shift', { shortcutToShow: 'Shift' }),
  ],
};

export const DrawingHotkey = {
  ToggleAiSuggest: [
    new Hotkey('A'),
  ],
  ShowAll: [
    new Hotkey('Ctrl + H'),
  ],
  ShowOrHide: [
    new Hotkey('H'),
  ],
  Focus: [
    new Hotkey('F'),
  ],
  Isolate: [
    new Hotkey('I'),
  ],
  SelectAll: [
    new Hotkey('Ctrl + A'),
  ],
  Unselect: [
    new Hotkey('Esc'),
  ],
  ToggleElementsPanel: [
    new Hotkey('M'),
  ],
  Unite: [
    new Hotkey('Ctrl + Alt + U'),
  ],
  Subtract: [
    new Hotkey('Ctrl + Alt + S'),
  ],
  RemoveInstance: [
    new Hotkey('Backspace'),
    new Hotkey('Delete', { os: OS.Windows, shortcutToShow: 'Delete', splitSymbol: ',', splitterToShow: ',' }),
  ],
  GroupDrawings: [
    new Hotkey('Ctrl + G'),
  ],
  RemoveFromGroupGroup: [
    new Hotkey('Ctrl + Shift + G'),
  ],
  ToggleDragLayout: [
    new Hotkey('Space'),
  ],
  Duplicate: [
    new Hotkey('Ctrl + D'),
  ],
  ToggleSnapping: [
    new Hotkey('S'),
  ],
};

export const CopyPasteHotkey = {
  Copy: [
    new Hotkey('Ctrl + C'),
  ],
  Paste: [
    new Hotkey('Ctrl + Shift + V'),
  ],
  PasteWithPoint: [
    new Hotkey('Ctrl + V'),
  ],
  Cut: [
    new Hotkey('Ctrl + X'),
  ],
};

export const UndoHotkey = {
  Undo: [
    new Hotkey('Ctrl + Z'),
  ],
  Redo: [
    new Hotkey('Ctrl + Y'),
    new Hotkey('Ctrl + Shift + Z'),
  ],
};

export const FormatPanelHotkey = {
  Underline: [
    new Hotkey('Ctrl + U'),
  ],
  Italic: [
    new Hotkey('Ctrl + I'),
  ],
  Bold: [
    new Hotkey('Ctrl + B'),
  ],
  StrikeThrough: [
    new Hotkey('Ctrl + Shift + S'),
  ],
};

export const ProjectHotkey = {
  OpenCreateProjectDialog: [
    new Hotkey('N'),
  ],
};
