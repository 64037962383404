import { Avatar } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import { ShortPointDescription } from 'common/components/drawings/interfaces';
import { Spinner } from 'common/components/spinner';
import { PersonUtils } from 'common/utils/person-utils';
import { CommentaryDrawingWithPointTarget } from 'unit-2d-comments/interfaces';
import { Person } from '../../../../../../units/people/interfaces/person';
import { Styled } from './styled';

export interface DrawingsCommentApi {
  setPosition: (
    x: number,
    y: number,
  ) => void;
  getTargetPosition: () => ShortPointDescription;
  hide: () => void;
}

interface Props {
  id: number | string;
  user: Person;
  target: CommentaryDrawingWithPointTarget;
  temporary: boolean;
  isSelected: boolean;
  isSending?: boolean;
  sendCommentRef: (api: DrawingsCommentApi, id: number | string) => void;
  onClick?: (commentId: number) => void;
}


export class Comment extends React.PureComponent<Props>  {
  private container: HTMLDivElement;

  public render(): React.ReactNode {
    const { user, temporary, isSelected, isSending } = this.props;
    const { userName, avatarLink } = PersonUtils.getPersonVisualData(user);
    return (
      <Styled.Container
        temporary={temporary}
        ref={this.saveRef}
        onClick={this.onClick}
        isSelected={isSelected}
      >
        {
          isSending ? (
            <Spinner
              show={true}
              withBackground={false}
            />
          ) : (
            <Avatar
              name={userName}
              size={30}
              avatar={avatarLink}
            />
          )
        }
      </Styled.Container>
    );
  }

  @autobind
  private onClick(): void {
    if (this.props.onClick) {
      this.props.onClick(this.props.id as number);
    }
  }

  @autobind
  private changePositionAndOrientation(
    x: number,
    y: number,
  ): void {
    this.container.style.display = 'flex';
    this.container.style.left = `${x}px`;
    this.container.style.top = `${y}px`;
  }

  @autobind
  private getTargetPosition(): ShortPointDescription {
    return [this.props.target.x, this.props.target.y];
  }

  @autobind
  private saveRef(ref: HTMLDivElement): void {
    this.container = ref;
    this.props.sendCommentRef(
      ref
        ? {
          setPosition: this.changePositionAndOrientation,
          getTargetPosition: this.getTargetPosition,
          hide: this.hide,
        }
        : null,
      this.props.id,
    );
  }

  @autobind
  private hide(): void {
    this.container.style.display = 'none';
  }
}
