import autobind from 'autobind-decorator';

import {
  Updater,
  Params,
} from 'unit-2d-database/components/side-panel/helpers/update-form-state';
import { PropertyField } from 'unit-2d-database/components/side-panel/helpers/update-property-form';
import { Assembly, Item } from 'unit-2d-database/interfaces';
import {
  AssemblyForm,
  IItemUpdater,
  ItemGroupUpdaterParams,
  ItemUpdater,
} from '../../../assembly-panel/item-form-updater';
import { GroupForm as PropertyGroupForm } from '../../../item-panel/helpers/map-property-group-form';
import { MeasureForm } from '../../interfaces';
import { GroupForm } from '../map-assembly-to-form';

export type AssemblyGroupHandlers = [
  () => void,
];

export interface IAssemblyUpdater {
  getItemGroupUpdater: (assemblyId: string) => IItemUpdater;
  getAssemblyHandlers: (assemblyId: string) => AssemblyGroupHandlers;
  updateAssemblyForm(form: GroupForm): void;
  changeFormula(
    assemblyId: string,
    itemId: string,
    groupsForm: PropertyGroupForm,
    newField: PropertyField,
    groupName: string,
  ): void;
}

export interface AssemblyGroupUpdaterParams<T> extends ItemGroupUpdaterParams<T> {
  afterAddAssemblyForm: (measureForm: MeasureForm, ...args: any) => void;
  afterDeleteAssembly: (measureForm: MeasureForm, assemblyId: string, ...args: any) => void;
}

export class AssemblyUpdater
  extends Updater<MeasureForm, AssemblyForm>
  implements IAssemblyUpdater {
  private updaterParams: AssemblyGroupUpdaterParams<MeasureForm>;
  public constructor(
    baseParams: Params<MeasureForm>,
    params: AssemblyGroupUpdaterParams<MeasureForm>,
  ) {
    super(baseParams);
    this.updaterParams = params;
  }

  public static getData(form: GroupForm): Assembly[] {
    return Object.values(form).map(group => {
      const data = ItemUpdater.getData(group.assemblyGroups);
      const items = data.updatedItems.map(u => {
        const item: Item = {
          name: u.name,
          iconType: u.iconType,
          properties: [...u.updatedProperties, ...u.addedProperties],
        };
        return item;
      });
      const assembly: Assembly = {
        name: group.name,
        items: [...items, ...data.addedItems],
      };
      return assembly;
    });
  }

  public updateAssemblyForm(form: GroupForm): void {
    this.bindHandler(undefined, this.applyUpdateAssemblyForm, () => this.updaterParams.afterAddAssemblyForm)(form);
  }

  public getItemGroupUpdater(assemblyId: string): IItemUpdater {
    const {
      afterDeletePropertyGroup,
      afterAddProperty,
      afterDeleteItem,
      afterAddItem,
      afterChangeFormula,
    } = this.updaterParams;
    return new ItemUpdater(
      {
        getForm: () => this.getElement(this.getForm(), assemblyId),
        afterEffects: {
          afterChange: this.bindHandler(assemblyId, this.applyChanges, () => this.afterChange),
          afterDelete: this.bindHandler(assemblyId, this.applyChanges, () => this.afterDelete),
          afterUnitChange: this.bindHandler(assemblyId, this.applyChanges, () => this.afterUnitChange),
          afterVisibilityChange:
            this.bindHandler(assemblyId, this.applyChanges, () => this.afterVisibilityChange),
        },
      },
      {
        afterAddProperty: this.bindHandler(assemblyId, this.applyChanges, () => afterAddProperty),
        afterDeletePropertyGroup:
          this.bindHandler(assemblyId, this.applyChanges, () => afterDeletePropertyGroup),
        afterAddItem: this.bindHandler(assemblyId, this.applyChanges, () => afterAddItem),
        afterDeleteItem: this.bindHandler(assemblyId, this.applyChanges, () => afterDeleteItem),
        afterChangeFormula: this.bindHandler(assemblyId, this.applyChanges, () => afterChangeFormula),
      },
    );
  }

  @autobind
  public getAssemblyHandlers(itemId: string): AssemblyGroupHandlers {
    return [
      this.bindHandler(itemId, this.applyDeleteAssembly, () => this.updaterParams.afterDeleteAssembly),
    ];
  }

  public changeFormula(
    assemblyName: string,
    itemId: string,
    propertyGroup: PropertyGroupForm,
    newField: PropertyField,
    groupName: string,
  ): void {
    const itemUpdater = this.getItemGroupUpdater(assemblyName);
    itemUpdater.changeFormula(itemId, propertyGroup, newField, groupName);
  }


  @autobind
  protected override getElement(form: MeasureForm, assemblyId: string): AssemblyForm {
    return form.assemblyGroupForm[assemblyId].assemblyGroups;
  }

  @autobind
  private applyChanges(measureForm: MeasureForm, assemblyId: string, assemblyForm: AssemblyForm): void {
    measureForm.assemblyGroupForm[assemblyId].assemblyGroups = assemblyForm;
  }

  @autobind
  private applyUpdateAssemblyForm(measureForm: MeasureForm, _: string, form: GroupForm): void {
    Object.entries(form).forEach(([assemblyId, group]) => {
      measureForm.assemblyGroupForm[assemblyId] = group;
    });
  }

  @autobind
  private applyDeleteAssembly(measureForm: MeasureForm, assemblyId: string): void {
    delete measureForm.assemblyGroupForm[assemblyId];
  }
}
