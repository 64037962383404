import * as React from 'react';

import { Spinner } from 'common/components/spinner';
import { Styled } from './styled';

export const SpinnerPage: React.FC = () => {
  return (
    <Styled.Container>
      <Spinner show={true} fullScreen={true} withBackground={true} />
    </Styled.Container>
  );
};
