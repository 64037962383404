import { numberUtils } from 'common/utils/number-utils';
import { ResourceType } from '../enums';
import {
  ActivityModel,
  ActivityVariantActivityAssignmentInfoModel,
  ActivityVariantModel,
  DatabaseTableRootModel,
  DatabaseTableVariantModel,
  UnitModel,
} from '../interfaces/data';
import {
  LaborModel,
  MaterialModel,
  PlantModel,
  ResourceModel,
} from '../interfaces/resources-data';
import { DatabaseCostCalculator } from './database-cost-calculator';

export class ListModelMapper {
  public static getActivityListModels(
    models: ActivityModel[], unitMap: Record<number, UnitModel>,
  ): DatabaseTableRootModel[] {
    return models.map(activity => {
      const laborCost = DatabaseCostCalculator.getLaborsCost(activity.labors);
      const plantCost = DatabaseCostCalculator.getPlantsCost(activity.plants);

      return {
        id: activity.id,
        name: activity.name,
        variants: activity.variants.map(x => this.getActivityVariantListModel(x, laborCost, plantCost, unitMap)),
      };
    });
  }

  public static getResourceListModels(
    models: ResourceModel[],
    type: ResourceType,
    unitMap: Record<number, UnitModel>,
  ): DatabaseTableRootModel[] {
    switch (type) {
      case ResourceType.Labour:
        return this.getLaborListModels(models as LaborModel[]);
      case ResourceType.Material:
        return this.getMaterialListModels(models as MaterialModel[], unitMap);
      case ResourceType.Plant:
        return this.getPlantListModels(models as PlantModel[]);
      default:
        throw new Error(`GetResourceListModels(): unknown type ${type}`);
    }
  }

  public static getActivityVariantActivityAssignmentInfoModels(
    models: ActivityModel[], databaseId: number,
  ): ActivityVariantActivityAssignmentInfoModel[] {
    return models.map(activity => {
      const plantIds = activity.plants.map(x => x.id);
      const laborIds = activity.labors.map(x => x.id);
      return activity.variants.map(variant => ({
        id: variant.id,
        activityId: activity.id,
        databaseId,
        name: `${activity.name}. ${variant.name}`,
        crewHours: variant.crewHours,
        plantIds,
        laborIds,
        materials: variant.materials,
        materialBasedCrewHours: variant.materialBasedCrewHours,
      }));
    }).reduce((a, b) => a.concat(b), []);
  }

  private static formatMoney(value: React.ReactText): string {
    return numberUtils.getNumeralFormatter(value).format('0,0.00').replace(/,/g, ' ');
  }

  private static getActivityVariantListModel(
    variant: ActivityVariantModel, laborCost: number, plantCost: number, unitMap: Record<number, UnitModel>,
  ): DatabaseTableVariantModel {
    const crewHours = DatabaseCostCalculator.getCrewHours(variant);
    const crewHoursMaterialsCost = DatabaseCostCalculator.getCrewHoursMaterialsCost(variant, unitMap);
    const materialCost = DatabaseCostCalculator.getMaterialsCost(variant.materials, unitMap);
    const totalMaterialCost = materialCost + crewHoursMaterialsCost;
    const totalCost = (laborCost + plantCost) * crewHours + totalMaterialCost;
    const unit = unitMap[variant.unitId];

    return {
      id: variant.id,
      values: {
        name: variant.name,
        crewHours: crewHours.toFixed(2),
        unit: unit && unit.acronym,
        totalCost: ListModelMapper.formatMoney(totalCost),
        laborCost: ListModelMapper.formatMoney(laborCost * crewHours),
        plantCost: ListModelMapper.formatMoney(plantCost * crewHours),
        materialCost: ListModelMapper.formatMoney(totalMaterialCost),
      },
    };
  }

  private static getMaterialListModels(
    models: MaterialModel[], unitMap: Record<number, UnitModel>,
  ): DatabaseTableRootModel[] {
    return models.map(root => {
      const unit = unitMap[root.defaultUnitId];
      return {
        id: root.id,
        name: root.name,
        variants: root.variants.map((variant) => ({
          id: variant.id,
          values: {
            name: variant.name,
            defaultUnit: unit.acronym,
            package: `${variant.amount.toFixed(2)} ${unitMap[variant.unitId].acronym}`,
            costPerUnit: ListModelMapper.formatMoney(
              DatabaseCostCalculator.getMaterialVariantCostPerUnit(root, variant, unitMap),
            ),
            costPerPackage: ListModelMapper.formatMoney(variant.cost),
          },
        })),
      };
    });
  }

  private static getLaborListModels(models: LaborModel[]): DatabaseTableRootModel[] {
    return models.map(root => ({
      id: root.id,
      name: root.name,
      variants: root.variants.map((variant) => ({
        id: variant.id,
        values: {
          skill: variant.name,
          costPerHour: ListModelMapper.formatMoney(variant.cost),
        },
      })),
    }));
  }

  private static getPlantListModels(models: PlantModel[]): DatabaseTableRootModel[] {
    return models.map(root => ({
      id: root.id,
      name: root.name,
      variants: root.variants.map((variant) => ({
        id: variant.id,
        values: {
          name: variant.name,
          costPerShift: ListModelMapper.formatMoney(variant.cost * variant.shiftHours),
          costPerHour: ListModelMapper.formatMoney(variant.cost),
          isMainContractor: variant.isMainContractor ? 'Yes' : 'No',
          rentTime: variant.rentTime.toFixed(),
          siftHours: `${variant.shiftHours.toFixed()} hr`,
        },
      })),
    }));
  }
}
