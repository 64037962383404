import * as paper from 'paper';

import { arrayUtils } from 'common/utils/array-utils';
import { objectUtils } from 'common/utils/object-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { DrawingStrokeStyles } from '../constants/drawing-styles';
import { DrawingsInstanceType } from '../enums';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import {
  ConvertedPolygon,
  DrawingsConverterPolygonInfo,
  DrawingsPolygonConverterResult,
} from '../interfaces/drawings-converter-result';
import { DrawingsAllowedPathType, DrawingsPaperPolygonPath } from '../interfaces/drawings-geometry';
import { DrawingAnnotationUtils } from './drawing-annotation-utils';
import { DrawingsPaperUtils } from './drawings-paper-utils';

function* cycleIterate(array: string[], index: number): Iterable<string> {
  for (let i = index; i < array.length; i++) {
    yield array[i];
  }

  for (let i = 0; i < index; i++) {
    yield array[i];
  }
}

interface ShortContourDescription {
  contour: string[];
  coordinates: Record<string, ShortPointDescription>;
}

function* splitPointSets(
  points: string[],
  pointCoordinates: Record<string, ShortPointDescription>,
  index: number,
): IterableIterator<ShortContourDescription> {
  const copyPoint = points[index];
  let copyCount = 0;
  const contoursForSplit = [{ points, index }];
  while (contoursForSplit.length) {
    const { points: currentContour, index: firstDuplicateIndex } = contoursForSplit.pop();

    let contour = new Array<string>();
    let coordinates: Record<string, ShortPointDescription> = {};
    let shouldSplitContour = false;

    for (const point of cycleIterate(currentContour, firstDuplicateIndex)) {
      if (copyPoint === point) {
        copyCount++;
        if (copyCount === 2) {
          if (!shouldSplitContour) {
            yield { contour, coordinates };
          }
          shouldSplitContour = false;
          contour = [];
          coordinates = {};
        }
        const pointId = UuidUtil.generateUuid();
        contour.push(pointId);
        coordinates[pointId] = pointCoordinates[point];
      } else {
        const pointIndex = contour.push(point) - 1;
        if (point in coordinates) {
          shouldSplitContour = true;
          contoursForSplit.push({ points: contour, index: pointIndex });
        }
        coordinates[point] = pointCoordinates[point];
      }
    }
    if (contour.length && !shouldSplitContour) {
      yield { contour, coordinates };
    }
  }
}

function convertToPolygon(
  path: DrawingsAllowedPathType,
  coordinatesIds: Record<number, Record<number, string>>,
  usedPointsInOtherContours: Record<string, ShortPointDescription>,
): {
  geometries: string[][],
  newPoints: Record<string, ShortPointDescription>,
} {
  let newPoints: Record<string, ShortPointDescription> = {};
  const usedPoints: Record<string, number> = {};
  let duplicateSegmentIndex = -1;
  const points = [];
  const oldPoints = {};
  for (const segment of path.segments) {
    let pointId = coordinatesIds[segment.point.x] ? coordinatesIds[segment.point.x][segment.point.y] : null;
    if (!pointId || usedPointsInOtherContours[pointId]) {
      pointId = UuidUtil.generateUuid();
      newPoints[pointId] = [segment.point.x, segment.point.y];
      coordinatesIds[segment.point.x] = coordinatesIds[segment.point.x] || {};
      coordinatesIds[segment.point.x][segment.point.y] = pointId;
    } else {
      oldPoints[pointId] = [segment.point.x, segment.point.y];
    }
    usedPoints[pointId] = (usedPoints[pointId] || 0) + 1;
    const index = points.push(pointId) - 1;
    if (usedPoints[pointId] > 1) {
      duplicateSegmentIndex = index;
    }
  }

  const geometries = [];
  if (duplicateSegmentIndex === -1) {
    geometries.push(points);
  } else {
    const iterator = splitPointSets(points, { ...oldPoints, ...newPoints }, duplicateSegmentIndex);
    for (const { coordinates, contour } of iterator) {
      newPoints = { ...newPoints, ...coordinates };
      geometries.push(contour);
    }
  }
  return {
    geometries,
    newPoints,
  };
}

export interface GeometryConfig {
  color: string;
  strokeWidth: number;
  strokeStyle: DrawingStrokeStyles;
  height?: number;
  thickness?: number;
}

function convertPathToPolygons(
  path: DrawingsAllowedPathType,
  coordinates?: Record<number, Record<number, string>>,
  styles?: GeometryConfig,
): DrawingsPolygonConverterResult {
  const rootPolygons = new Array<number>();
  const idsPolygons: Record<number, DrawingsConverterPolygonInfo> = {};
  let points: Record<string, ShortPointDescription> = {};
  const processedPolygons = new Array<paper.Path>();
  function copyOrCreateCompound(item: paper.Item): paper.CompoundPath {
    return (item.children ? item.clone() : new paper.CompoundPath([item.clone()])) as paper.CompoundPath;
  }

  const createPolygonInfo = (current: DrawingsAllowedPathType): DrawingsConverterPolygonInfo => {
    const { geometries, newPoints } = convertToPolygon(current, coordinates, points);
    points = { ...points, ...newPoints };
    return {
      paperGeometry: copyOrCreateCompound(current),
      geometryInfo: geometries,
      children: [],
      parent: null,
    };
  };

  const getCurrentPolygon = (current: DrawingsAllowedPathType): DrawingsConverterPolygonInfo => {
    if (!idsPolygons[current.id]) {
      idsPolygons[current.id] = createPolygonInfo(current);
    }
    return idsPolygons[current.id];
  };

  if (path.children) {
    (path.children as paper.Path[]).sort((a, b) => a.area - b.area);
    const children = [];
    for (const child of path.children as paper.Path[]) {
      if (child.area > 0) {
        rootPolygons.push(child.id);
        processedPolygons.push(child);
        idsPolygons[child.id] = createPolygonInfo(child);
      } else {
        children.push(child);
      }
    }
    for (const current of children) {
      const currentPolygon = getCurrentPolygon(current);
      for (const rootId of rootPolygons) {
        const polygonData = idsPolygons[rootId];
        if (DrawingsPaperUtils.arePathesEquals(current, polygonData.paperGeometry as paper.Path)) {
          continue;
        } else if (DrawingsPaperUtils.hasPointInside(current, polygonData.paperGeometry)) {
          if (currentPolygon.parent) {
            const parentPolygon = idsPolygons[currentPolygon.parent];
            if (Math.abs(polygonData.paperGeometry.area) < parentPolygon.paperGeometry.area) {
              parentPolygon.children = parentPolygon.children.filter((x) => x !== current.id);
              currentPolygon.parent = rootId;
              polygonData.children.push(current.id);
            }
          } else {
            polygonData.children.push(current.id);
            currentPolygon.parent = rootId;
          }
        }
      }
      processedPolygons.push(current);
    }
    path.remove();
  } else if (path.segments.length) {
    rootPolygons.push(path.id);
    getCurrentPolygon(path);
  } else {
    return null;
  }
  return { newPoints: points, geometriesIterator: convertToPolygons(rootPolygons, idsPolygons, points, styles) };
}

function* iterateChildContours(
  ids: number[],
  idsPolygons: Record<number, DrawingsConverterPolygonInfo>,
): IterableIterator<string[]> {
  for (const childId of ids) {
    const child = idsPolygons[childId];
    for (const childGeometry of child.geometryInfo) {
      yield childGeometry;
    }
  }
}

interface Intersection {
  point: paper.Point;
  segmentIndices: number[];
}


const vertexToString = (vertex: paper.Point): string => `${vertex.x}:${vertex.y}`;

function createGraph(
  polygon: paper.Point[],
  intersections: Intersection[],
): { graph: Map<string, string[]>, points: Map<string, paper.Point> } {
  const graph = new Map<string, string[]>();
  const pointKey = new WeakMap<paper.Point, string>();
  const keyToPoint = new Map<string, paper.Point>();

  const getPointKey = (point: paper.Point): string => {
    if (!pointKey.has(point)) {
      const key = vertexToString(point);
      keyToPoint.set(key, point);
      pointKey.set(point, vertexToString(point));
    }
    return pointKey.get(point);
  };

  polygon.forEach((vertex, index) => {
    const vertexKey = getPointKey(vertex);
    const nextVertex = polygon[(index + 1) % polygon.length];
    const nextVertexKey = getPointKey(nextVertex);
    graph.set(vertexKey, [nextVertexKey]);
  });

  const addPointToGraph = (start: paper.Point, end: paper.Point): void => {
    const startToString = getPointKey(start);
    const endToString = getPointKey(end);
    if (graph.has(startToString)) {
      graph.get(startToString).unshift(endToString);
    } else {
      graph.set(startToString, [endToString]);
    }
  };

  intersections.forEach((intersection) => {
    intersection.segmentIndices.forEach((segment) => {
      const startVertex = polygon[segment];
      const endVertex = polygon[(segment + 1) % polygon.length];
      addPointToGraph(startVertex, intersection.point);
      addPointToGraph(intersection.point, endVertex);
    });
  });

  return { graph, points: keyToPoint };
}

function findPolygonsFromGraph(graph: Map<string, string[]>, keyToPoint: Map<string, paper.Point>): paper.Point[][] {
  const visited = new Set<string>();
  const path: string[] = [];
  const cycleHashes: Record<string, string[]> = {};

  const dfs = (vertex: string, start: string): void =>  {
    if (visited.has(vertex)) {
      if (vertex === start && path.length > 1) {
        const key = arrayUtils.uniq(path).sort().join('-');
        if (!cycleHashes[key]) {
          cycleHashes[key] = [...path, start];
        }
      }
      return;
    }

    visited.add(vertex);
    path.push(vertex);
    const neighbors = graph.get(vertex);

    if (neighbors) {
      for (const neighbor of neighbors) {
        dfs(neighbor, start);
      }
    }

    visited.delete(vertex);
    path.pop();
  };

  const graphEnties = Array.from(graph.entries());
  graphEnties.sort((a, b) => b[1].length - a[1].length);

  for (const [graphKey, value] of graph.entries()) {
    if (value.length > 1) {
      dfs(graphKey, graphKey);
    }
  }
  const keys = Object.keys(cycleHashes).sort((a, b) => a.length - b.length);
  return keys.reduce<[paper.Point[][], string[]]>((acc, key) => {
    const [result, usedKeys] = acc;
    if (usedKeys.some(x => key.includes(x))) {
      return acc;
    }
    usedKeys.push(key);
    const uniqPoints = arrayUtils.uniq(cycleHashes[key]).map(k => keyToPoint.get(k));
    if (!result.some(x => x.every(p => uniqPoints.some(point => point.equals(p))))) {
      result.push(uniqPoints);
    }
    return acc;
  }, [[], []])[0];
}

function constructPolygons(polygon: paper.Point[], intersections: Intersection[]): paper.Point[][] {
  const { graph, points } = createGraph(polygon, intersections);
  const newPolygons = findPolygonsFromGraph(graph, points);
  return newPolygons;
}

function tryBreakExternalContours(
  contours: string[][],
  points: Record<string, ShortPointDescription>,
): { canBreak: boolean, result: string[][] } {
  const result = [];
  const pointToId = new WeakMap<paper.Point, string>();
  const visitedPoints = new Set<string>();
  const getPoint = (pointId: string): paper.Point => {
    const paperPoint = new paper.Point(points[pointId]);
    pointToId.set(paperPoint, pointId);
    return paperPoint;
  };

  const translatePath = (path: paper.Point[]): string[] => {
    const stringPath = [];
    for (const segment of path) {
      const pointId = pointToId.get(segment);
      if (pointId) {
        if (visitedPoints.has(pointId)) {
          const newPointId = UuidUtil.generateUuid();
          stringPath.push(newPointId);
          points[newPointId] = [segment.x, segment.y];
        } else {
          stringPath.push(pointId);
        }
        visitedPoints.add(pointId);
      } else {
        const newPointId = UuidUtil.generateUuid();
        stringPath.push(newPointId);
        points[newPointId] = [segment.x, segment.y];
        pointToId.set(segment, newPointId);
      }
    }
    return stringPath;
  };

  for (const contour of contours) {
    const paperPoints = contour.map((p) => getPoint(p));
    const intersections = DrawingsPaperUtils.allIntersectionInContour(paperPoints);
    if (intersections.length > 0) {
      const intersectionIds = new WeakMap<paper.Point, number>();
      const intersectionsConverted = intersections.map<Intersection>(({ point, segments }) => {
        const indices = segments.map((s) => s.index);
        intersectionIds.set(point, indices[0]);
        return { point, segmentIndices: indices };
      });
      const newPolygons = constructPolygons(paperPoints, intersectionsConverted);
      for (const newPolygon of newPolygons) {
        const translatedPath = translatePath(newPolygon);
        result.push(translatedPath);
      }
    } else {
      result.push(contour);
    }
  }
  return { canBreak: true, result };
}

function* convertToPolygons(
  rootPolygons: number[],
  idsPolygons: Record<number, DrawingsConverterPolygonInfo>,
  newPoints: Record<string, ShortPointDescription>,
  styles: GeometryConfig,
): IterableIterator<ConvertedPolygon> {
  for (const id of rootPolygons) {
    const children = idsPolygons[id].children;
    let geometryInfo = idsPolygons[id].geometryInfo;
    const { canBreak, result } = tryBreakExternalContours(geometryInfo, newPoints);
    geometryInfo = result;
    if (!canBreak) {
      yield [false, { points: geometryInfo[0], children: null, ...styles }];
    }
    if (geometryInfo.length > 1) {
      if (children?.length) {
        const paperSources = geometryInfo.map(
          (contour) => new paper.Path(contour.map((p) => new paper.Point(newPoints[p]))),
        );

        const paperCompounds = new Array<paper.CompoundPath>(paperSources.length);

        const childContours = new Array<string[][]>(paperSources.length);
        for (const childContour of iterateChildContours(children, idsPolygons)) {
          const paperPoint = new paper.Point(newPoints[childContour[0]]);
          const parentIndex = paperSources.findIndex((parent) => {
            const pointsIterator = DrawingAnnotationUtils.iteratePathPoints(parent, DrawingsInstanceType.Polygon);
            return DrawingsPaperUtils.isPointInside(paperPoint, pointsIterator);
          });
          if (parentIndex !== -1) {
            const paperChild = new paper.Path(childContour.map((x) => new paper.Point(newPoints[x])));

            childContours[parentIndex] = childContours[parentIndex] || [];
            childContours[parentIndex].push(childContour);
            if (!paperCompounds[parentIndex]) {
              const compoundPath = new paper.CompoundPath({ children: [paperSources[parentIndex], paperChild] });
              paperCompounds[parentIndex] = compoundPath;
            } else {
              paperCompounds[parentIndex].addChild(paperChild);
            }
          }
        }
        for (let i = 0; i < paperSources.length; i++) {
          const isSelfIntersected = paperCompounds[i]
            ? DrawingsPaperUtils.isPolygonSelfIntersected(paperCompounds[i] as DrawingsAllowedPathType)
            : false;
          paperSources[i].remove();
          paperCompounds[i]?.remove();
          yield [
            !isSelfIntersected,
            {
              points: geometryInfo[i],
              children: childContours[i] || undefined,
              ...styles,
            },
          ];
        }
        paperSources.forEach((x) => x.remove());
        paperCompounds.forEach((x) => x && x.remove());
      } else {
        for (const geometry of geometryInfo) {
          const contour = new paper.Path(geometry.map((p) => new paper.Point(newPoints[p])));
          const isSelfIntersected = DrawingsPaperUtils.isPolygonSelfIntersected(contour);
          yield [
            !isSelfIntersected,
            {
              points: geometry,
              ...styles,
            },
          ];
        }
      }
    } else {
      if (children.length) {
        const parsedChildren = [];
        const paperContours = [new paper.Path(geometryInfo[0].map((p) => new paper.Point(newPoints[p])))];
        for (const childContour of iterateChildContours(children, idsPolygons)) {
          parsedChildren.push(childContour);
          const childParsed = new paper.Path(childContour.map((x) => new paper.Point(newPoints[x])));
          paperContours.push(childParsed);
        }
        const paperPolygon = new paper.CompoundPath({ children: paperContours }) as DrawingsPaperPolygonPath;
        const isSelfIntersected = DrawingsPaperUtils.isPolygonSelfIntersected(paperPolygon);
        yield [
          !isSelfIntersected,
          {
            points: geometryInfo[0],
            children: parsedChildren,
            ...styles,
          },
        ];
        paperPolygon.remove();
        if (isSelfIntersected) {
          break;
        }
      } else {
        const contour = new paper.Path(geometryInfo[0].map((p) => new paper.Point(newPoints[p])));
        const isSelfIntersected = DrawingsPaperUtils.isPolygonSelfIntersected(contour);
        yield [
          !isSelfIntersected,
          {
            points: geometryInfo[0],
            children: null,
            ...styles,
          },
        ];
      }
    }
  }
  Object.values(idsPolygons).forEach((i) => i.paperGeometry.remove());
}

function simpleConvertToPolygon(path: DrawingsAllowedPathType): {
  contour: string[],
  children?: string[][],
  points: Record<string, ShortPointDescription>,
} {
  if (path.children) {
    const { contour, points } = simpleConvertToPolygon(path.children[0] as DrawingsAllowedPathType);
    const children = [];
    for (let i = 1; i < path.children.length; i++) {
      const child = simpleConvertToPolygon(path.children[i] as DrawingsAllowedPathType);
      children.push(child.contour);
      objectUtils.extend(points, child.points);
    }
    return { contour, children, points };
  } else {
    const contour = [];
    const points = {};
    for (const segment of path.segments) {
      const pointId = UuidUtil.generateUuid();
      points[pointId] = [segment.point.x, segment.point.y];
      contour.push(pointId);
    }
    return { contour, points };
  }
}

export const DrawingsGeometryConverters = {
  convertPathToPolygons,
  simpleConvertToPolygon,
};
