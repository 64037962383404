import { CompanyResourcesApi } from '../../../api/server';
import { DatabaseModel, DatabaseModelC, DatabaseModelsC } from '../interfaces/data';
import { CreateDatabaseForm, UpdateDatabaseForm } from '../interfaces/rest-data';


function createDatabase(data: CreateDatabaseForm): Promise<DatabaseModel> {
  return CompanyResourcesApi.post(`/databases`, data, 'api create database error');
}

function getDatabases(): Promise<DatabaseModel[]> {
  return CompanyResourcesApi.getV(
    `/databases`,
    DatabaseModelsC,
    'api get company databases error',
  );
}

function getDatabase(databaseId: number): Promise<DatabaseModel> {
  return CompanyResourcesApi.getV(
    `/databases/${databaseId}`,
    DatabaseModelC,
    `api get company database(${databaseId}) error`,
  );
}

function updateDatabase(databaseId: number, data: UpdateDatabaseForm): Promise<DatabaseModel> {
  return CompanyResourcesApi.put(
    `/databases/${databaseId}`, data, `api update company database(${databaseId}) error.`,
  );
}

function deleteDatabase(databaseId: number): Promise<{}> {
  return CompanyResourcesApi.delete(`/databases/${databaseId}`, `'api update company database(${databaseId}) error'`);
}

function setDefault(databaseId: number): Promise<{}> {
  return CompanyResourcesApi.post(
    `/databases/default/${databaseId}`, null, `'api set default database(${databaseId})`,
  );
}

export const DatabasesApi = {
  createDatabase,
  getDatabases,
  getDatabase,
  updateDatabase,
  deleteDatabase,
  setDefault,
};
