import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import './measurements-revit-tree-body.scss';

import { State } from 'common/interfaces/state';
import { KreoScrollbars, VirtualList } from 'common/UIKit';
import { KreoScrollbarsApi } from 'common/UIKit/scrollbars/kreo-scrollbars';
import { MeasurementsUtils } from '../../utils/measurements-utils';
import { MeasurementsRevitTreeItem } from '../measurements-revit-tree-item';

interface Props {
  items: number[];
  centralItem: number;
}

class MeasurementsRevitTreeBodyComponent extends React.Component<Props> {
  private readonly itemHeight: number = 40;

  private bodyContainerRef: HTMLDivElement;
  private scrollbarsApi: KreoScrollbarsApi;

  public componentDidMount(): void {
    if (Number.isInteger(this.props.centralItem)) {
      this.scrollTo();
    }
  }

  public render(): React.ReactNode {
    return (
      <div className='measurements-revit-tree-body'>
        <KreoScrollbars onSendRef={this.setViewport} sendApi={this.saveScrollbarsApi}>
          <VirtualList
            bufferSize={21}
            viewport={this.bodyContainerRef}
            items={this.props.items}
            itemHeight={this.itemHeight}
            itemFactory={this.renderer}
          />
        </KreoScrollbars>
      </div>
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.centralItem !== this.props.centralItem && Number.isInteger(this.props.centralItem)) {
      this.scrollTo();
    }
  }

  private scrollTo(): void {
    if (this.scrollbarsApi && this.bodyContainerRef) {
      const containerHeight = this.bodyContainerRef.getBoundingClientRect().height;
      const topElement = this.props.centralItem;
      const topPosition = this.itemHeight * topElement - (this.itemHeight + containerHeight) / 2;
      this.scrollbarsApi.setScrollTop(topPosition);
    }
  }

  @autobind
  private saveScrollbarsApi(api: KreoScrollbarsApi): void {
    this.scrollbarsApi = api;
  }

  @autobind
  private renderer(item: number): React.ReactNode {
    return <MeasurementsRevitTreeItem key={item} id={item}/>;
  }


  @autobind
  private setViewport(viewport: HTMLDivElement): void {
    if (viewport === null) return;
    this.bodyContainerRef = viewport;
  }
}

function mapStateToProps({ measurements }: State): Props {
  return {
    items: MeasurementsUtils.extractRevitTreeNodeIdsForRender(measurements.modelBrowser),
    centralItem: measurements.centralTreeElement,
  };
}

const connector = connect(mapStateToProps);
export const MeasurementsRevitTreeBody = connector(MeasurementsRevitTreeBodyComponent);
