import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  DrawingsAnnotationLegendActions,
} from 'common/components/drawings/actions/creators/drawings-annotation-legend';
import { DrawingsSettingsSwitcherWithLabel } from 'common/components/drawings/components/setting-parameter';
import { DrawingsGeometryGroup } from 'common/components/drawings/interfaces';
import { FolderContainer } from 'common/components/folder-container';
import { Property } from 'common/components/property';
import { State } from 'common/interfaces/state';
import { usePersistedStorageValueToggle } from 'persisted-storage/hooks';
import { Styled } from './styled';

interface Props {
  selectedGroup: DrawingsGeometryGroup;
}

const FolderPropertiesComponent: React.FC<Props> = ({ selectedGroup }) => {
  const groups = useSelector<State, DrawingsGeometryGroup[]>((state) => state.drawings.drawingGeometryGroups);
  const [nameAsGroup, toggleNameAsGroup] = usePersistedStorageValueToggle('drawingUseGroupNameForNewGeometry');

  const dispatch = useDispatch();
  const onRemoveSelection = useCallback(() => {
    dispatch(DrawingsAnnotationLegendActions.updateSelection({ groupIds: [] }));
  }, [useDispatch]);

  return (
    <>
      <Styled.FolderContainerWrapper>
        <Property title='Group'>
          <FolderContainer
            withoutArrowContainer={true}
            groups={groups}
            id={selectedGroup.id}
            onRemoveSelection={onRemoveSelection}
            showHoverMenuLeft={true}
            delayHoverMenu={0}
          />
        </Property>
      </Styled.FolderContainerWrapper>
      <DrawingsSettingsSwitcherWithLabel
        checked={nameAsGroup}
        toggle={toggleNameAsGroup}
        label="Name as group"
      />
    </>
  );
};

export const FolderProperties = React.memo(FolderPropertiesComponent);
