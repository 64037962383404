import React from 'react';

import { CompanySubscriptionModel } from '../../../../account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../account/selectors';

export const useIsCanceledMemo = (subscription: CompanySubscriptionModel): boolean => {
  return React.useMemo(() => {
    return !AccountSelectors.isPausedSubscription(subscription);
  }, [subscription]);
};
