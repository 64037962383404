import styled from 'styled-components';

import { Text } from 'common/components/text';
import { SETTING_HEIGHT } from 'unit-2d-info-panel/content/settings/styled';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

const Setting = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
`;

const PropertyWithSlider = styled.div`
  height: ${SETTING_HEIGHT}px;
  display: grid;
  grid-template-columns: 1fr 25px 100px;
  grid-column-gap: 7px;
  align-items: center;

  > ${Text} {
    font-weight: 500;
  }
`;

export const Styled = {
  Container,
  Setting,
  PropertyWithSlider,
};
