import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { AnimationDataResponse, GanttData, ResourcesData } from '../../interfaces/gantt-chart';
import { GroupType } from '../../utils/gantt-chart';
import {
  GanttChartChangePackageSelectionPayload,
  GanttChartRowExpandPayload,
} from '../payloads/gantt-chart';
import { GanttChartActionTypes } from '../types/gantt-chart';


const getAnimationData = (calculationId: number): ActionWith<number> => {
  return {
    type: GanttChartActionTypes.ANIMATION_DATA_REQUEST_STARTED,
    payload: calculationId,
  };
};

const getAnimationDataSucceded = (animationData: AnimationDataResponse): ActionWith<AnimationDataResponse> => {
  return {
    type: GanttChartActionTypes.ANIMATION_DATA_REQUEST_SUCCEEDED,
    payload: animationData,
  };
};

const saveGanttData = (ganttData: GanttData): ActionWith<GanttData> => {
  return {
    type: GanttChartActionTypes.SAVE_GANTT_DATA,
    payload: ganttData,
  };
};

const resetState = (): Action => {
  return {
    type: GanttChartActionTypes.RESET_STATE,
  };
};

const expandItem = (
  type: GroupType,
  rowId: number,
  parentIds: number[],
): ActionWith<GanttChartRowExpandPayload> => {
  return {
    type: GanttChartActionTypes.EXPAND_ITEM,
    payload: { type, rowId, parentIds },
  };
};

const expandAll = (): Action => {
  return {
    type: GanttChartActionTypes.EXPAND_ALL,
  };
};

const collapseAll = (): Action => {
  return {
    type: GanttChartActionTypes.COLLAPSE_ALL,
  };
};

const multipleChangePackageSelection = (
  changes: GanttChartChangePackageSelectionPayload[],
): ActionWith<GanttChartChangePackageSelectionPayload[]> => {
  return {
    type: GanttChartActionTypes.MULTIPLE_CHANGE_SELECTION,
    payload: changes,
  };
};

const changePackageSelection = (
  lineId: string,
  isSelect: boolean,
): ActionWith<GanttChartChangePackageSelectionPayload> => {
  return {
    type: GanttChartActionTypes.CHANGE_SELECTION,
    payload: {
      lineId,
      isSelect,
    },
  };
};


const saveResourcesData = (
  data: ResourcesData,
): ActionWith<ResourcesData> => {
  return {
    type: GanttChartActionTypes.SAVE_RESOURCES_DATA,
    payload: data,
  };
};

const resetActiveTab = (): Action => {
  return {
    type: GanttChartActionTypes.RESET_ACTIVE_TAB,
  };
};

export const GanttChartActions = {
  getAnimationData,
  getAnimationDataSucceded,
  saveGanttData,
  resetState,
  expandItem,
  multipleChangePackageSelection,
  changePackageSelection,
  collapseAll,
  expandAll,
  saveResourcesData,
  resetActiveTab,
};
