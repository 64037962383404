import { ContainerWithContextMenu, ContextMenuPositionMode } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { ContextMenuItem } from 'common/components/context-menu';
import { State } from 'common/interfaces/state';
import { mathUtils } from 'common/utils/math-utils';
import { DrawingsUserAnnotationActions } from '../../actions/creators/user-annotation';
import { DrawingContextObserver } from '../../drawings-contexts';
import { DrawingsViewportHelper } from '../../helpers/viewport';
import { DrawingsProcessedAiAnnotation, ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { DrawingsGeometryGroup } from '../../interfaces/drawings-geometry-group';
import { DrawingsGroupMeasure } from '../../interfaces/drawings-measures';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';
import { DrawingsUserAnnotationLegendUtils } from '../../utils/drawings-user-annotation-legend-utils';
import { DrawingsUserAnnotationLegendContent2 } from './content';
import { DrawingsUserLegendHeaderConfig } from './header';
import { Styled } from './styled';

export interface DrawingsUserAnnotationsLegendApi {
  updateViewportParams: () => void;
}

interface StateProps {
  isImperial: boolean;
  currentDrawingId: string;
  groups: DrawingsGeometryGroup[];
  position: ShortPointDescription;
  measures: Record<string, DrawingsGroupMeasure>;
}

interface DispatchProps {
  removeLegend: () => void;
}

interface OwnProps {
  zoomHandler: DrawingContextObserver<{ zoom: number }>;
  canEditAnnotations: boolean;
  fileData: DrawingsProcessedAiAnnotation;
  viewportHelper: DrawingsViewportHelper;
  getTruePosition: (position: ShortPointDescription) => ShortPointDescription | null;
  getGroupMeasures: (groupId: string, instanceIds: string[]) => DrawingsGroupMeasure;
  sendLegendApi: (api: DrawingsUserAnnotationsLegendApi) => void;
}

interface Props extends StateProps, OwnProps, DispatchProps {
}

interface ComponentState {
  header: DrawingsUserLegendHeaderConfig;
}

class UserAnnotationsLegendComponent extends React.PureComponent<Props, ComponentState> {
  private ref: HTMLDivElement = null;
  private tableRef: HTMLTableElement = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      header: DrawingsUserAnnotationLegendUtils.calculateHeaderConfig(props.measures),
    };
  }

  public render(): React.ReactNode {
    return (
      <Styled.Container ref={this.saveRef}>
        <ContainerWithContextMenu
          disabled={!this.props.canEditAnnotations}
          positionMode={ContextMenuPositionMode.MousePosition}
          renderContextMenuContent={this.renderContextMenuContent}
        >
          <DrawingsUserAnnotationLegendContent2
            saveTableRef={this.saveTableRef}
            getGroupMeasures={this.props.getGroupMeasures}
            isImperial={this.props.isImperial}
            headerConfig={this.state.header}
            groups={this.props.groups}
            zoomHandler={this.props.zoomHandler}
          />
        </ContainerWithContextMenu>
      </Styled.Container>
    );
  }

  public componentDidMount(): void {
    this.props.sendLegendApi({ updateViewportParams: this.updateViewportParams });
    this.props.zoomHandler.subscribe(this.applyZoom);
  }

  public componentWillUnmount(): void {
    this.props.sendLegendApi(null);
    this.props.zoomHandler.unsubscribe(this.applyZoom);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.position !== prevProps.position) {
      this.updateViewportParams();
    }
    if (this.props.measures !== prevProps.measures) {
      const header = DrawingsUserAnnotationLegendUtils.calculateHeaderConfig(this.props.measures);
      if (!_.isEqual(header, this.state.header)) {
        this.setState({ header });
      }
    }
    this.applyZoom(this.props.zoomHandler.getContext());
  }


  @autobind
  private renderContextMenuContent(): React.ReactNode {
    return (
      <ContextMenuItem onClick={this.props.removeLegend}>
        Remove
      </ContextMenuItem>
    );
  }

  @autobind
  private saveRef(ref: HTMLDivElement): void {
    this.ref = ref;
    if (ref) {
      this.updateViewportParams();
    }
  }

  @autobind
  private saveTableRef(ref: HTMLTableElement): void {
    this.tableRef = ref;
  }

  private calculateVerticalStyles(
    tableHeight: number,
    viewportHeight: number,
    pageY: number,
    tablePositionY: number,
  ): { top: number, maxHeight: number } {
    const zoomedPageHeight = viewportHeight * this.props.viewportHelper.zoom;
    const bottomSpace = zoomedPageHeight - pageY * this.props.viewportHelper.zoom;
    tableHeight = Styled.CONTAINER_PADDING * this.props.viewportHelper.zoom * 2 + tableHeight;
    if (tableHeight > bottomSpace) {
      const diffY = mathUtils.clamp(
        tableHeight - bottomSpace,
        0,
        pageY * this.props.viewportHelper.zoom,
      );
      const top = tablePositionY - diffY;
      const maxHeight = top < 0 ? zoomedPageHeight : zoomedPageHeight - top;
      return { top, maxHeight };
    } else {
      return { top: tablePositionY, maxHeight: bottomSpace };
    }
  }

  private calculateHorizontalStyles(
    tableWidth: number,
    viewportWidth: number,
    pageX: number,
    tablePositionX: number,
  ): { left: number, maxWidth: number } {
    const rightSpace = (viewportWidth - pageX) * this.props.viewportHelper.zoom;
    const zoomedPageWidth = viewportWidth * this.props.viewportHelper.zoom;
    tableWidth = Styled.CONTAINER_PADDING * this.props.viewportHelper.zoom * 2 + tableWidth;
    if (tableWidth > rightSpace) {
      const diffX = mathUtils.clamp(
        tableWidth - rightSpace,
        0,
        pageX * this.props.viewportHelper.zoom,
      );
      const left = tablePositionX - diffX;
      const maxWidth = left < 0 ? zoomedPageWidth : zoomedPageWidth - left;
      return { left, maxWidth };
    } else {
      return { left: tablePositionX, maxWidth: rightSpace };
    }
  }

  @autobind
  private updateViewportParams(): void {
    const position = this.props.getTruePosition(this.props.position);
    if (!position) {
      return;
    }
    const [x, y] = position;
    const { width, height } = this.props.viewportHelper.getCurrentPageParameters();
    const [pageX, pageY] = DrawingsCanvasUtils.getPointOnPage(
      this.props.position,
      this.props.viewportHelper.currentPageInfo,
      this.props.viewportHelper.rotation);

    const { height: tableHeight, width: tableWidth } = this.tableRef.getBoundingClientRect();
    const { top, maxHeight } = this.calculateVerticalStyles(tableHeight, height, pageY, y);
    const { left, maxWidth } = this.calculateHorizontalStyles(tableWidth, width, pageX, x);
    this.ref.style.maxWidth = `${maxWidth}px`;
    this.ref.style.maxHeight = `${maxHeight}px`;
    this.ref.style.top = `${top}px`;
    this.ref.style.left = `${left}px`;
  }

  @autobind
  private applyZoom({ zoom }: { zoom: number }): void {
    this.ref.style.padding = `${Styled.CONTAINER_PADDING * zoom}px`;
    this.ref.style.borderRadius = `${Styled.CONTAINER_BORDER_RADIUS * zoom}px`;
    this.tableRef.style.borderSpacing = `${Styled.TABLE_BORDER_SPACING * zoom}px`;
    const fontSize = zoom * Styled.FONT_SIZE;
    const height = fontSize * Styled.LINE_HEIGHT_MULTIPLIER;
    const texts = this.ref.getElementsByTagName('p');
    for (let i = 0; i < texts.length; i++) {
      const text = texts.item(i);
      text.style.fontSize = `${fontSize}px`;
      text.style.lineHeight = `${height}px`;
    }
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    groups: state.drawings.drawingGeometryGroups,
    currentDrawingId: state.drawings.currentDrawingInfo ? state.drawings.currentDrawingInfo.drawingId : null,
    position: state.drawings.userAnnotations.legend,
    isImperial: state.account.settings.isImperial,
    measures: state.drawings.userAnnotations.groupsMeasuresCache,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    removeLegend: () => dispatch(DrawingsUserAnnotationActions.removeLegend()),
  };
}

export const DrawingsUserAnnotationLegend = connect(mapStateToProps, mapDispatchToProps)(
  UserAnnotationsLegendComponent,
);
