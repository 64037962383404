import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants/drawing-canvas-constants';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { DrawingsGeometryEntityPoint } from '../../drawings-geometry-entities';
import { DrawingsGeometrySelectionArea, RotationControl } from '../../drawings-geometry-entities/utility';
import { EditPermissions, RotationProcessor } from '../../interfaces';
import { DrawingsDragCallbackParams, DrawingsDragProcessingHelper } from '../drag-instances';
import { DrawingsCursorTypeHelper } from '../visual-helpers';


export interface DrawingsUserAnnotationImageBoundsConfig {
  bounds: paper.Rectangle;
  rotation: number;
  layer: paper.Layer;
  renderParametersContext: ContextObserverWithPrevious<DrawingsRenderParams>;
  dragProcessingHelper: DrawingsDragProcessingHelper;
  cursorHelper: DrawingsCursorTypeHelper;
  editPermissionsObserver: ContextObserver<EditPermissions>;
  rotationProcessor: RotationProcessor;
  onScaleChange: (scale: number, finish: boolean) => void;
}

export class DrawingsUserAnnotationImageBounds {
  private _selected: boolean = false;

  private _rect: paper.Path.Rectangle;
  private _points: DrawingsGeometryEntityPoint[];
  private _dragPointId: string;
  private _rotationControl: RotationControl;

  private _config: DrawingsUserAnnotationImageBoundsConfig;

  constructor(config: DrawingsUserAnnotationImageBoundsConfig) {
    this._config = config;
    this._rect  = new paper.Path.Rectangle(this._config.bounds);
    this._rect.strokeColor = DrawingsCanvasColors.pointColor;
    this._rect.rotate(this._config.rotation);
    this._rect.addTo(this._config.layer);
    this._rect.visible = false;
  }

  public set rotation(value: number) {
    const rotationDiff = value - this._config.rotation;
    this._rect.rotate(value - this._config.rotation);
    if (this._selected) {
      this._rotationControl.updateGeometry([this._rect.segments[1].point, this._rect.segments[2].point]);
      this._points.forEach((point) => {
        point.paperPosition = point.paperPosition.rotate(rotationDiff, this._rect.position);
      });
    }
    this._config.rotation = value;
  }

  public get rotation(): number {
    return this._config.rotation;
  }

  public set position(position: paper.Point) {
    if (this._points) {
      const diff = position.subtract(this._rect.position);
      this._points.forEach((point) => {
        point.paperPosition = point.paperPosition.add(diff);
      });
    }
    this._rect.position = position;
    if (this._rotationControl) {
      this._rotationControl.updateGeometry([this._rect.segments[1].point, this._rect.segments[2].point]);
    }
  }

  public get selected(): boolean {
    return this._selected;
  }

  public set selected(value: boolean) {
    this._selected = value;
    if (this._selected && this._config.editPermissionsObserver.getContext().canEditUserAnnotation) {
      this._rect.visible = true;
      this._config.renderParametersContext.subscribe(this.changeRenderParams);
      this._points = this._rect.segments.map((x, i) => {
        return new DrawingsGeometryEntityPoint({
          id: `${i}`,
          geometry: x.point,
          layer: this._config.layer,
          renderParamsContextObserver: this._config.renderParametersContext,
          onStartDrag: this.dragPoint,
          canModify: true,
          forceCanMove: true,
          cursorHelper: this._config.cursorHelper,
        });
      });
      this._rotationControl = new RotationControl({
        geometry: [this._rect.segments[1].point, this._rect.segments[2].point],
        renderParamsContextObserver: this._config.renderParametersContext,
        layer: this._config.layer,
        rotationProcessor: this._config.rotationProcessor,
      });
    } else if (this._points) {
      this._points.forEach(x => x.destroy());
      this._points = [];
      this._config.renderParametersContext.unsubscribe(this.changeRenderParams);
      this._rotationControl.destroy();
      this._rotationControl = null;
      this._rect.visible = false;
    }
  }

  public intersectsWithSelectionRect(rect: paper.Rectangle, selectionArea: DrawingsGeometrySelectionArea): boolean {
    return this._rect.isInside(rect) || selectionArea.intersects(this._rect);
  }

  public destroy(): void {
    this._rect.remove();
    if (this._points) {
      this._points.forEach(x => x.destroy());
    }
    if (this._rotationControl) {
      this._rotationControl.destroy();
    }
  }

  @autobind
  private dragPoint(id: string): void {
    this._dragPointId = id;
    this._config.dragProcessingHelper.setCallback(this.drag, { defaultValidPoint: this._points[id].paperPosition });
  }

  @autobind
  private drag({ point, finish }: DrawingsDragCallbackParams): boolean {
    const opositePoint = this._rect.segments[(Number(this._dragPointId) + 2) % 4].point;
    const startDiagonal = opositePoint.subtract(this._rect.segments[this._dragPointId].point);
    const endDiagonal = opositePoint.subtract(point);
    const maxScale = Math.min(endDiagonal.x / startDiagonal.x, endDiagonal.y / startDiagonal.y);
    this._rect.scale(maxScale);
    this._points.forEach((currentPoint, i) => {
      const { x, y } = this._rect.segments[i].point;
      currentPoint.changePosition(x, y);
    });
    this._config.onScaleChange(maxScale, finish);
    this._rotationControl.updateGeometry([this._rect.segments[1].point, this._rect.segments[2].point]);
    return true;
  }

  @autobind
  private changeRenderParams({ zoom }: DrawingsRenderParams): void {
    this._rect.strokeWidth = 1 / zoom;
    this._rect.dashArray = [5 / zoom];
  }
}
