import styled from 'styled-components';

const Container = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: ${p => p.theme.color.turquoise};
  transition: ${p => p.theme.duration.s} ease-in-out;
  cursor: pointer;

  > svg {
    fill: ${p => p.theme.color.white};
    width: 10px;
    height: 10px;
  }
`;

export const Styled = {
  Container,
};
