import * as React from 'react';

import './charts-list.scss';

import { ChartDataProvider } from '../../../../utils/gantt-chart';
import { ChartCard } from '../chart-card';
import { ResourceSelectorPopup } from '../resource-selector-popup';

interface ListProps {
  height: number;
  width: number;
  currentMoment: number;
  nextCurrentMoment: number | null;
  dataProvider: ChartDataProvider;
  timeframeStartDay: number;
  timeframeDuration: number;
}

export class ChartsList extends React.Component<ListProps> {
  private readonly chartMinHeight: number = 140;
  private readonly chartsMargin: number = 20;
  private readonly chartMinVerticalSpace: number = this.chartMinHeight + this.chartsMargin;
  private readonly padding: number = 20;

  public render(): React.ReactNode {
    return (
      <div className='charts-list'>
        <ResourceSelectorPopup
          dataProvider={this.props.dataProvider}
        />
        {this.renderCharts()}
      </div>
    );
  }

  private renderCharts(): React.ReactNode {
    const chartsCount = Math.floor(this.props.height / this.chartMinVerticalSpace);
    const cardRealHeight = Math.floor(this.props.height / chartsCount) - this.chartsMargin;
    const charts: React.ReactNode[] = [];
    for (let i = 0; i < chartsCount; i++) {
      charts.push(
        <ChartCard
          key={i}
          height={cardRealHeight}
          width={this.props.width - this.padding * 2}
          chartId={i}
          currentMoment={this.props.currentMoment}
          nextCurrentMoment={this.props.nextCurrentMoment}
          dataProvider={this.props.dataProvider}
          timeframeStartDay={this.props.timeframeStartDay}
          timeframeDuration={this.props.timeframeDuration}
        />);
    }

    return charts;
  }
}
