import { Text, CheckboxButton } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { Styled } from './styled';

interface Props{
  checked: boolean;
  text: string;
  onToggle: () => void;
}

export const CheckboxWithText: React.FC<Props> = ({ checked, text, onToggle }) => {
  return (
    <Styled.Container onClick={onToggle}>
      <CheckboxButton checked={checked} />
      <Text fontSize={14}>{text}</Text>
    </Styled.Container>
  );
};
