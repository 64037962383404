import { IconButton, Icons } from '@kreo/kreo-ui-components';
import { Colors } from '@kreo/kreo-ui-components/constants';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ConstantFunctions } from 'common/constants/functions';
import { UploadingFile } from 'common/interfaces/common-state';
import { Common } from '../../../../actions';
import { FileProgress } from './file-progress/file-progress';
import { Styled } from './styled';

interface Props{
  file: UploadingFile;
  index: number;
  remove: (file: UploadingFile, index: number) => void;
}

const FileLineComponent: React.FC<Props> = ({
  file,
  index,
  remove,
}) => {
  const dispatch = useDispatch();

  const onRemoveCallback = useCallback((e: React.MouseEvent) => {
    ConstantFunctions.stopEvent(e);
    dispatch(Common.removeUploadFile(file.key));
    remove(file, index);
  }, [file, index, remove, dispatch]);

  return (
    <Styled.ProgressLine key={index}>
      <FileProgress title={file.file.name} progress={file.progress} isValid={!file.error} />
      <IconButton
        Icon={Icons.Delete}
        defaultColor={Colors.GENERAL_COLORS.red}
        hoverColor={Colors.GENERAL_COLORS.red}
        onClick={onRemoveCallback}
        height={30}
        width={20}
      />
    </Styled.ProgressLine>
  );
};

export const FileLine = React.memo(FileLineComponent);
