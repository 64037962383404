import React from 'react';

import { DrawingsInstanceMeasure } from 'common/components/drawings/interfaces';
import { ItemMeasure } from '../item-measure';
import { useMeasureItem } from './use-measure-item';


interface Props {
  measure: DrawingsInstanceMeasure;
  showTooltip: boolean;
  hideIconsInfo: boolean;
  hideInfo: boolean;
  isImperial: boolean;
}


const ItemMeasuresComponent: React.FC<Props> = (
  {
    measure,
    showTooltip,
    hideIconsInfo,
    hideInfo,
    isImperial,
  },
) => {
  const measureItem = useMeasureItem(measure, showTooltip);

  if (!measureItem) {
    return null;
  }

  return (
    <>
      <ItemMeasure
        showTooltip={showTooltip}
        hideIconsInfo={hideIconsInfo}
        hideInfo={hideInfo}
        isImperial={isImperial}
        measureValue={measureItem.firstValue}
      />
      {
        measureItem.secondValue ? (
          <ItemMeasure
            showTooltip={showTooltip}
            hideIconsInfo={hideIconsInfo}
            hideInfo={hideInfo}
            isImperial={isImperial}
            measureValue={measureItem.secondValue}
          />
        ) : null
      }
    </>
  );
};

export const ItemMeasures = React.memo(ItemMeasuresComponent);
