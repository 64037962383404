import { ViewModelStatus } from '../../enums/view-model-status';
import { ViewModelStatusPair } from '../../interfaces/view-model-status-pair';

export interface PercentageData {
  percent: number;
  stepTime: number;
  targetTime: number;
}

export interface FullPercentageData extends PercentageData {
  startTime: number;
  allReady: boolean;
}

const DEFAULT_WAITING_DURATION = 100000;

function getPercentage(
  estimatedDuration: number,
  startTime: number,
): PercentageData {
  const diffTime = new Date().getTime() - startTime;
  let targetTime = estimatedDuration;
  let percent =  diffTime / targetTime;
  let stepTime = targetTime / 100;
  if (percent > 1) {
    targetTime = diffTime * 1.1;
    percent = 1 / 1.1;
    stepTime = targetTime / 100;
  }
  return { percent, stepTime, targetTime };
}

function getCommonTime(viewModelStatuses: ViewModelStatusPair[]): FullPercentageData {
  const currentTime = new Date().getTime();
  let startTime = currentTime;
  let targetTime = 0;
  let allReady = true;
  for (const viewModelStatus of viewModelStatuses) {
    if (viewModelStatus.status === ViewModelStatus.Waiting || viewModelStatus.status === ViewModelStatus.Calculating) {
      allReady = false;
    } else {
      continue;
    }
    if (viewModelStatus.startTime && viewModelStatus.startTime < startTime) {
      startTime = viewModelStatus.startTime;
    }
    if (viewModelStatus.estimatedDuration && targetTime < viewModelStatus.estimatedDuration) {
      targetTime = viewModelStatus.estimatedDuration;
    }
  }
  targetTime = targetTime || DEFAULT_WAITING_DURATION;
  return {
    startTime,
    targetTime,
    stepTime: targetTime / 100,
    percent: (currentTime - startTime) / targetTime,
    allReady,
  };
}

export const ProjectTileUtils = {
  getPercentage,
  getCommonTime,
};
