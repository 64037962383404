import { ActionWith } from 'common/interfaces/action-with';
import { AnalyticsActionTypes } from './types';

function setChatOpen(payload: boolean): ActionWith<boolean> {
  return {
    type: AnalyticsActionTypes.SET_CHAT_OPEN,
    payload,
  };
}
export const AnalyticsActions = {
  setChatOpen,
};
