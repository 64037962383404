import { PropertyField } from 'unit-2d-database/components/side-panel/helpers/update-property-form';
import { Property } from 'unit-2d-database/interfaces';
import { PropertyGroup } from '../../group-properties-list';
import { getFieldWithGroup } from './map-property-to-field';
import { IPropertyGroupUpdater } from './property-group-form-updater';

export type GroupForm = Record<string, PropertyGroup>;

function* mapItemToFieldsIterator(
  properties: Iterable<[Property, Property]>,
  units: string[],
  propertyGroupUpdater: IPropertyGroupUpdater,
  onFormulaFieldClick: (id: string, groupName: string) => void,
  onBreakdownFieldClick: (id: string, groupName: string) => void,
  getPropertyKey?: (property: Property) => string,
): IterableIterator<[string, PropertyField]> {
  for (const [property, originProperty] of properties) {
    if (property.value === null || property.value === undefined) {
      continue;
    }
    yield getFieldWithGroup(
      property,
      originProperty,
      units,
      propertyGroupUpdater,
      onFormulaFieldClick,
      onBreakdownFieldClick,
      getPropertyKey,
    );
  }
}

export const mapPropertyGroupForm = (
  properties: Iterable<[Property, Property]>,
  units: string[],
  propertyGroupUpdater: IPropertyGroupUpdater,
  onFormulaFieldClick: (id: string, groupName: string) => void,
  onBreakdownFieldClick: (id: string, groupName: string) => void,
  getPropertyKey?: (property: Property) => string,
): GroupForm => {
  const groups: GroupForm = {};
  const groupField = mapItemToFieldsIterator(
    properties,
    units,
    propertyGroupUpdater,
    onFormulaFieldClick,
    onBreakdownFieldClick,
    getPropertyKey,
  );
  for (const [groupName, field] of groupField) {
    if (groups[groupName]) {
      groups[groupName].fields.push(field);
    } else {
      const [onDelete] = propertyGroupUpdater.getPropertyGroupHandlers(groupName);
      groups[groupName] = {
        id: groupName,
        name: groupName,
        fields: [field],
        onDelete,
      };
    }
  }
  return groups;
};
