import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { useRendererApiContext } from '../../drawings-contexts';
import { DrawingsProcessedAiAnnotation } from '../../interfaces';

export function useHasVisibleInstances(): boolean {
  const currentDrawingData = useSelector<State, DrawingsProcessedAiAnnotation>(x => {
    const fileData = x.drawings.aiAnnotation.fileData;
    const drawing = x.drawings.currentDrawingInfo;
    return drawing && fileData[drawing.pdfId]
      ? fileData[drawing.pdfId][drawing.drawingId]
      : undefined;
  });

  const { rendererApi } = useRendererApiContext();

  return useMemo(() => {
    if (!rendererApi || !currentDrawingData) {
      return false;
    }
    return !!rendererApi.engine.getVisibleInstancesIds(currentDrawingData.instances).length;
  }, [rendererApi, currentDrawingData]);
}
