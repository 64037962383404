import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { UserMap } from 'common/interfaces/people';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { ScenariosApi } from '../../../api/scenario/scenario';
import { AccountApi } from '../../account/api';
import { PeopleActions } from '../../people/actions/actions';
import { ProjectsActions } from '../../projects/actions/creators/common';
import { CalculationApi } from '../../projects/api/calculation';
import { Project } from '../../projects/interfaces/project';
import { ScenarioState } from '../../projects/interfaces/scenario-state';
import { getCurrentProject } from '../../projects/selectors';
import { ScenariosActions, ScenariosActionTypes } from '../actions';
import {
  ScenariosActionsType,
  ScenariosData,
  ScenariosReduxState,
  TextFormatting,
  WorkPackagesData,
} from '../interfaces';

function* getScenarios(action: ActionWith<number>): SagaIterator {
  try {
    const usersMap: UserMap = yield selectWrapper(state => state.people.usersmap);
    const scenarios: ScenariosData.Scenario[] = yield call(
      ScenariosApi.getScenarios,
    );
    const usersIds = Object.keys(usersMap);
    const missingUsersIds = scenarios
      .map(s => s.profileId)
      .filter(id => usersIds.indexOf(id) === -1);
    const missingUsersInfo: UserMap = yield call(AccountApi.getUsersInfo, missingUsersIds);

    yield put(PeopleActions.addToUserMap(missingUsersInfo));
    yield put(ScenariosActions.getScenariosSucceeded(scenarios));
  } catch (error) {
    console.error('scenarios: get scenarios failed', error, action.payload);
  }
}

function* getCalculations(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    const calculations: ScenariosData.Calculation[] = yield call(ScenariosApi.getCalculations, scenarioId);
    yield put(ScenariosActions.getCalculationsSucceeded({ calculations, scenarioId }));
  } catch (error) {
    console.error('scenarios: get calculations failed', error, action.payload);
  }
}

function* getScenarioByProject(action: ActionWith<number>): SagaIterator {
  try {
    const data: ScenariosData.Scenario = yield call(ScenariosApi.getActiveScenarioByDocumentId);
    yield put(ScenariosActions.setActiveScenario(data));
  } catch (error) {
    console.error('scenarios: get scenario by project failed', error, action.payload);
  }
}

function* getScenarioResourceLimitations(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    const data = yield call(ScenariosApi.getResourceLimitationRequest, scenarioId);
    yield put(ScenariosActions.getResourceLimitationsSucceeded(data.managements));
  } catch (error) {
    console.error('scenarios: get scenario resource limitations failed', error, action.payload);
  }
}

function* setScenarioResourceLimitations(): SagaIterator {
  try {
    const scenarios: ScenariosReduxState = yield selectWrapper(state => state.scenarios);
    yield put(ProjectsActions.setProjectNotFinishedScenarioId(scenarios.editScenario.id));
    const data: ScenariosActionsType.ResourceLimitationApiData = {
      managements: scenarios.resourceLimitations,
    };
    const scenarioId =
      data.managements && data.managements.length > 0 && data.managements[0].scenarioId;
    yield call(ScenariosApi.setResourceLimitationRequest, scenarioId, data);
  } catch (error) {
    console.error('scenarios: set scenario resource limitations failed', error);
  }
}

function* setActiveScenario(
  action: ActionWith<ScenariosActionsType.ScenarioCalculationIds>,
): SagaIterator {
  try {
    yield call(ScenariosApi.setActiveScenario, action.payload.scenarioId, action.payload.calculationId);
    yield put(ScenariosActions.setActiveScenarioSucceeded(action.payload.scenarioId));
    yield put(ScenariosActions.setActiveCalculationSucceeded(action.payload));
  } catch (error) {
    console.error('scenarios: set active scenario failed', error, action.payload);
  }
}

function* deleteScenario(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    yield call(ScenariosApi.deleteScenario, scenarioId);
    yield put(ScenariosActions.deleteScenarioSucceeded(action.payload));
  } catch (error) {
    console.error('scenarios: delete scenario failed', error, action.payload);
  }
}

function* setActiveCalculation(
  action: ActionWith<ScenariosActionsType.ScenarioCalculationIds>,
): SagaIterator {
  try {
    const { scenarioId, calculationId } = action.payload;
    yield call(ScenariosApi.setActiveCalculation, scenarioId, calculationId);
    yield put(ScenariosActions.setActiveCalculationSucceeded(action.payload));
  } catch (error) {
    console.error('scenarios: set active calculation failed', error, action.payload);
  }
}

function* getIndirectSettings(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    const data: WorkPackagesData.IndirectCost = yield call(ScenariosApi.getIndirectSettings, scenarioId);
    yield put(ScenariosActions.getIndirectSettingsSucceeded(data));
  } catch (error) {
    console.error('scenarios: get indirect settings failed', error, action.payload);
  }
}

function* setIndirectSettings({ payload: scenarioId }: ActionWith<number>): SagaIterator {
  try {
    const indirect: WorkPackagesData.IndirectCost[] = yield selectWrapper(state => state.scenarios.indirectCost);
    const data = indirect.map(x => {
      if (x.parameters) {
        x.parameters = x.parameters.map(y => {
          if (y.formating === TextFormatting.Percentage) {
            y.value = (y.value / 100).toFixed(4);
          }
          return y;
        });
      }
      return x;
    });
    yield call(ScenariosApi.setIndirectSettings, scenarioId, data);
    yield put(ProjectsActions.setProjectNotFinishedScenarioId(null));
  } catch (error) {
    console.error('scenarios: set indirect settings failed', error, { scenarioId });
  }
}

function* removeCalculation(
  action: ActionWith<ScenariosActionsType.ScenarioCalculationIds>,
): SagaIterator {
  try {
    yield call(CalculationApi.removeCalculation, action.payload.calculationId);
    yield put(ScenariosActions.removeCalculationSucceeded(action.payload));
  } catch (error) {
    console.error('scenarios: remove calculation failed', error, action.payload);
  }
}

function* updateScenarioName(action: ActionWith<ScenariosActionsType.ChangeName>): SagaIterator {
  try {
    const { id, name } = action.payload;
    yield call(ScenariosApi.changeScenarioName, id, name);
    yield put(ScenariosActions.updateScenarioNameSucceeded(action.payload));
  } catch (error) {
    console.error('scenarios: update scenario name failed', error, action.payload);
  }
}

function* updateCalculationName(
  action: ActionWith<ScenariosActionsType.CalculationChangeName>,
): SagaIterator {
  try {
    const { data, scenarioId } = action.payload;
    yield call(CalculationApi.changeCalculationName, data.id, data.name);
    yield put(ScenariosActions.updateCalculationNameSucceeded(data, scenarioId));
  } catch (error) {
    console.error('scenarios: update calculation name failed', error, action.payload);
  }
}

function* deleteAllScenariosExceptActive(action: ActionWith<number>): SagaIterator {
  try {
    const project: Project = yield selectWrapper(getCurrentProject);
    yield call(ScenariosApi.deleteAllScenariosExceptActive);
    yield put(
      ScenariosActions.deleteAllScenariosExceptActiveSucceeded(project && project.people.length > 1),
    );
  } catch (error) {
    console.error('scenarios: delete all scenarios except active failed', error, action.payload);
  }
}

function* copyScenario(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    const data: ScenariosData.Scenario = yield call(ScenariosApi.copyScenario, scenarioId);
    yield put(
      ScenariosActions.copyScenarioSucceeded({ sourceScenarioId: scenarioId, scenario: data }),
    );
    const sourceScenarioState: ScenarioState = yield selectWrapper(state => {
      const sourceScenarioGuid = state.scenarios.scenarios.find(x => x.id === scenarioId).scenarioGuid;
      return state.projects.scenarioStates[sourceScenarioGuid];
    },
    );
    yield put(ProjectsActions.appendScenarioStates({ ...sourceScenarioState, scenarioGuid: data.scenarioGuid }));
  } catch (error) {
    yield put(ScenariosActions.copyScenarioFailed(action.payload));
    console.error('scenarios: copy scenario failed', error, action.payload);
  }
}

function* publishScenario(action: ActionWith<ScenariosActionsType.Publish>): SagaIterator {
  try {
    yield call(ScenariosApi.publishScenario, action.payload.id, action.payload.value);
    yield put(ScenariosActions.publishScenarioSucceeded(action.payload));
  } catch (error) {
    console.error('scenarios: publish scenario failed', error, action.payload);
  }
}

function* updateScenario(action: ActionWith<ScenariosActionsType.UpdateScenario>): SagaIterator {
  try {
    yield call(ScenariosApi.updateScenario, action.payload);
    yield put(ScenariosActions.updateScenarioSucceeded(action.payload));
  } catch (error) {
    console.error('scenarios: update scenario failed', error, action.payload);
  }
}


export function* scenarioSagas(): SagaIterator {
  yield takeEvery(ScenariosActionTypes.UPDATE_SCENARIO_REQUEST, updateScenario);
  yield takeEvery(ScenariosActionTypes.COPY_SCENARIO_REQUEST, copyScenario);
  yield takeEvery(ScenariosActionTypes.REMOVE_CALCULATION_REQUEST, removeCalculation);
  yield takeEvery(ScenariosActionTypes.UPDATE_CALCULATION_NAME_REQUEST, updateCalculationName);
  yield takeEvery(ScenariosActionTypes.UPDATE_SCENARIO_NAME_REQUEST, updateScenarioName);
  yield takeLatest(ScenariosActionTypes.GET_INDIRECT_SETTINGS, getIndirectSettings);
  yield takeLatest(ScenariosActionTypes.SET_INDIRECT_SETTINGS, setIndirectSettings);
  yield takeLatest(ScenariosActionTypes.GET_REQUEST, getScenarios);
  yield takeEvery(ScenariosActionTypes.GET_CALCULATIONS_REQUEST, getCalculations);
  yield takeLatest(ScenariosActionTypes.SET_ACTIVE_SCENARIO_REQUEST, setActiveScenario);
  yield takeEvery(ScenariosActionTypes.REMOVE_SCENARIO_REQUEST, deleteScenario);
  yield takeLatest(
    ScenariosActionTypes.EDITING_GET_BASE_RESOURCE_LIMITATIONS_REQUEST,
    getScenarioResourceLimitations,
  );
  yield takeLatest(
    ScenariosActionTypes.EDITING_SET_BASE_RESOURCE_LIMITATIONS_REQUEST,
    setScenarioResourceLimitations,
  );
  yield takeLatest(ScenariosActionTypes.GET_ACTIVE_SCENARIO_REQUEST, getScenarioByProject);
  yield takeLatest(ScenariosActionTypes.RELOAD_ACTIVE_CALCULATION_REQUEST, getScenarioByProject);
  yield takeEvery(ScenariosActionTypes.SET_ACTIVE_CALCULATION_REQUEST, setActiveCalculation);
  yield takeEvery(ScenariosActionTypes.PUBLISH_REQUEST, publishScenario);
  yield takeEvery(
    ScenariosActionTypes.DELETE_ALL_SCENARIOS_EXCEPT_ACTIVE,
    deleteAllScenariosExceptActive,
  );
}
