import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { CostEstimate } from 'unit-cost-estimate/components/cost-estimate';
import { ModelType } from 'unit-projects/interfaces/quantity-take-off';
import { Qto3dProjectRouteParams } from '../../../routes/app-routes-params';
import { PlanProjectRouteParams } from '../../../routes/plan';

interface PageProps extends RouteComponentProps<PlanProjectRouteParams | Qto3dProjectRouteParams> {
}

export const CostEstimatePage = (props: PageProps): JSX.Element => {
  const projectId = parseInt(props.match.params.projectId, 10);

  return (
    <CostEstimate
      projectId={projectId}
      modelType={ModelType.CostEstimate}
    />
  );
};
