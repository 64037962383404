import * as React from 'react';

import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';

export const RENAME_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG = 'RENAME_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG';

interface Props {
  onSubmit: () => void;
}

export const RenameBreakDownPropertyConfirmationDialog: React.FC<Props> = ({ onSubmit }) => {
  const textPropertyAssembly = ` If you rename tree property in the database,
    you will loose all values for this property in existing projects. If you still
    want to have this property in your existing projects, you have to keep it
    in your database.`;
  const dialogText = textPropertyAssembly;

  return (
    <ConfirmationDialog
      name={RENAME_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG}
      title='Renaming will affect your projects.'
      text={dialogText}
      confirmButtonText='Rename'
      cancelButtonText='Cancel'
      onConfirm={onSubmit}
      zIndex={99999}
    />
  );
};
