import { ConstantFunctions } from 'common/constants/functions';

const TOUCHES_TO_TRACK = 2;

export function addTouchZoom(
  element: HTMLElement,
  onChangeZoom: (zoomCoefficient: number, targetCenter: [number, number]) => void,
): void {
  let targetCenter: [number, number] = null;
  let lastDistance: number = null;

  element.ontouchstart = (event: TouchEvent) => {
    if (event.touches.length === TOUCHES_TO_TRACK) {
      const { clientX: x1, clientY: y1 } = event.touches.item(0);
      const { clientX: x2, clientY: y2 } = event.touches.item(1);
      lastDistance = Math.hypot(x2 - x1, y2 - y1);
      targetCenter = [x2 + x1 / 2, y2 + y1 / 2];
    }
  };

  element.ontouchmove = (event: TouchEvent) => {
    if (event.touches.length !== TOUCHES_TO_TRACK) {
      return;
    }
    ConstantFunctions.stopEvent(event);
    const { clientX: x1, clientY: y1 } = event.touches.item(0);
    const { clientX: x2, clientY: y2 } = event.touches.item(1);
    const newDistance = Math.hypot(x2 - x1, y2 - y1);
    const zoomCoefficient = newDistance / lastDistance;
    onChangeZoom(zoomCoefficient, targetCenter);
    lastDistance = newDistance;
  };
}
