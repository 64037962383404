import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { CollectionsUnifier } from '../../drawings-geometry/drawings-helpers/collections-unifier';
import { DrawingsGeometryInstance } from '../../interfaces';
import { DrawingsGeometryStyle } from '../../interfaces/drawings-geometry-style';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';

export function useInstancesStyles(instancesIds: string[]): DrawingsGeometryStyle {
  const instances = useSelector<State, Record<string, DrawingsGeometryInstance>>(
    (state) => state.drawings.aiAnnotation.geometry,
  );

  return useMemo(() => {
    const unifier = new CollectionsUnifier<DrawingsGeometryStyle>();
    for (const id of instancesIds) {
      if (!instances[id]) {
        continue;
      }
      const { type, geometry } = instances[id];
      if (DrawingsGeometryUtils.isCount(type, geometry)) {
        unifier.add({
          color: geometry.color,
          strokeStyle: null,
          strokeWidth: null,
          shape: geometry.shape,
        });
      } else {
        unifier.add({
          color: geometry.color,
          strokeStyle: geometry.strokeStyle,
          strokeWidth: geometry.strokeWidth,
          shape: null,
        });
      }
    }
    return unifier.getUnifiedOrDefault({
      color: null,
      strokeStyle: null,
      strokeWidth: null,
      shape: null,
    });
  }, [instances, instancesIds]);
}
