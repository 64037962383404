import { Icons, RoundButton } from '@kreo/kreo-ui-components';
import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import React, { useMemo } from 'react';

import { ContextMenuUtils } from 'common/components/item-context-menu';
import { Item } from 'common/components/item-context-menu/menu-item-container';
import { RenderIf } from 'common/components/render-if';
import { Styled } from './styled';


const BUTTON_SIZE = 30;

interface Props {
  menuItems: Item[];
  isTranslucent: boolean;
  sizeItem: string;
  menuWidth: number;
}

export const MenuButton: React.FC<Props> = React.memo(({
  menuItems,
  isTranslucent,
  sizeItem,
  menuWidth,
}: Props) => {
  const isMenuButton = useMemo(() => menuItems && !!menuItems.length, [menuItems]);
  const extraProps = useMemo(() => ({ menuWidth, sizeItem }), [menuWidth, sizeItem]);
  const onOpenClick = ContextMenuUtils.useOpenContextMenuButton(() => menuItems, extraProps);

  return (
    <RenderIf condition={isMenuButton}>
      <Styled.Container
        onClick={ConstantFunctions.stopEvent}
        onMouseDown={ConstantFunctions.stopEvent}
        isTranslucent={isTranslucent}
      >
        <RoundButton
          Icon={Icons.Menu}
          diameter={BUTTON_SIZE}
          onClick={onOpenClick}
        />
      </Styled.Container>
    </RenderIf>
  );
});
