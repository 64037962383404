import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { ConstantFunctions } from 'common/constants/functions';
import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { arrayUtils } from 'common/utils/array-utils';
import { ContextObserver } from '../../drawings-contexts';
import { DrawingsInstanceType } from '../../enums';
import { DrawingsInstanceMeasure } from '../../interfaces';
import { DrawingAnnotationUtils } from '../../utils/drawing-annotation-utils';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';
import { DrawingsPaperUtils } from '../../utils/drawings-paper-utils';
import { DrawingsUpdateUtils } from '../../utils/drawings-update-utils';
import {
  BatchedUpdateCallback,
  EditPermissions,
  GetCurrentDrawingCallback,
  GetDrawingInstanceCallback,
  GetPointInfoCallback,
  InstanceGeometryManager,
  InstancesSelectionManager,
} from '../interfaces';
import { StrokedGeometryUpdate } from '../interfaces/stroke-styled-geometry-update';
import { DrawingsDragCallbackParams, DrawingsDragProcessingHelper } from './drag-instances';
import { DrawingsGeometryConversionProcessor } from './drawings-geometry-conversion-processor';
import { DrawingsInstancesChangesProcessor } from './drawings-instances-changes-processor';
import { DrawingsRenderedPointsCache } from './drawings-rendered-points-cache';
import { DrawingsCursorTypeHelper } from './visual-helpers';

interface DrawingsEditSegmentProcessorConfig {
  instancesGeometryManager: InstanceGeometryManager;
  instancesSelectionManager: InstancesSelectionManager;
  geometryConversionProcessor: DrawingsGeometryConversionProcessor;
  changesProcessor: DrawingsInstancesChangesProcessor;
  dragHelper: DrawingsDragProcessingHelper;
  cachedPoints: DrawingsRenderedPointsCache;
  editPermissionsObserver: ContextObserver<EditPermissions>;
  cursorHelper: DrawingsCursorTypeHelper;
  getPointInfo: GetPointInfoCallback;
  getInstance: GetDrawingInstanceCallback;
  getDrawingInfo: GetCurrentDrawingCallback;
  onBatchUpdateGeometries: BatchedUpdateCallback;
  isDrawingEnabled: () => boolean;
}


export class DrawingsEditSegmentHelper {
  private _lastDragPoint: paper.Point;
  private _withNewSegment: boolean;
  private _segmentId: string;
  private _instanceId: string;
  private _geometryUpdates: StrokedGeometryUpdate;

  private _config: DrawingsEditSegmentProcessorConfig;

  constructor(config: DrawingsEditSegmentProcessorConfig) {
    this._config = config;
  }

  public canDrag(): boolean {
    const { canEditMeasure } = this._config.editPermissionsObserver.getContext();
    return canEditMeasure && !this._config.isDrawingEnabled();
  }

  @autobind
  public startDragSegment(id: string, e: PaperMouseEvent, position: paper.Point): void {
    if (!this.canDrag()) {
      return;
    }
    ConstantFunctions.stopEvent(e);
    this._config.cursorHelper.grabbing = true;
    this._lastDragPoint = position;
    const [sourceLineStart] = DrawingAnnotationUtils.getPointsIdsFromLineKey(id);
    this._instanceId = this._config.getPointInfo(sourceLineStart).instanceId;
    this._withNewSegment = HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e.event);

    if (this._withNewSegment) {
      const instance = this._config.getInstance(this._instanceId);
      if (DrawingsGeometryUtils.isRectangle(instance.type, instance.geometry)) {
        this._config.instancesGeometryManager.removeEntityById(this._instanceId);
        this._config.instancesGeometryManager.renderInstance(
          this._instanceId,
          { ...instance, type: DrawingsInstanceType.Polygon },
        );
      }
      const update = this._config.changesProcessor.tryDuplicateSegment(id, this._instanceId);
      if (update) {
        this._segmentId = update.lineId;
        this._geometryUpdates = update.geometryUpdate;
      } else {
        this._withNewSegment = false;
        this._segmentId = id;
      }
    } else {
      this._segmentId = id;
    }

    const updatePositionCallback = this._withNewSegment ? this.updateNewSegmentPosition : this.updateSegmentPosition;
    this._config.dragHelper.setCallback(updatePositionCallback, { defaultValidPoint: this._lastDragPoint });
  }

  @autobind
  private updateNewSegmentPosition(
    { point, finish }: DrawingsDragCallbackParams,
  ): boolean {
    const pointIds = DrawingAnnotationUtils.getPointsIdsFromLineKey(this._segmentId);
    const { isValid, elementMeasures } = this.updatePointsPosition(point, pointIds);
    if (finish) {
      if (isValid) {
        this.saveNewSegment(pointIds, elementMeasures);
      } else {
        this._config.instancesGeometryManager.removeEntityById(this._instanceId);
        this._config.instancesGeometryManager.renderInstanceById(this._instanceId);
      }
      this.cleanDragData();
    }
    if (!isValid) {
      return false;
    }
    if (this._config.instancesSelectionManager.isInstanceSelected(this._instanceId)) {
      this._config.instancesSelectionManager.recalculateSelectionState();
    }
    return true;
  }

  @autobind
  private updateSegmentPosition({ point, finish }: DrawingsDragCallbackParams): boolean {
    const pointIds = DrawingAnnotationUtils.getPointsIdsFromLineKey(this._segmentId);
    const { isValid, elementMeasures } = this.updatePointsPosition(point, pointIds);
    if (!isValid) {
      return false;
    }
    if (this._config.instancesSelectionManager.isInstanceSelected(this._instanceId)) {
      this._config.instancesSelectionManager.recalculateSelectionState();
    }
    if (finish) {
      this.movePoints(pointIds, elementMeasures);
    }
    return true;
  }

  private isValid(measurements: DrawingsInstanceMeasure, startPoint: paper.Point, endPoint: paper.Point): boolean {
    const { width, height } = this._config.getDrawingInfo();
    return measurements
      && DrawingsPaperUtils.isPointOnDrawing(endPoint, width, height)
      && DrawingsPaperUtils.isPointOnDrawing(startPoint, width, height);
  }


  private movePoints(updatedPointIds: string[], elementMeasures: DrawingsInstanceMeasure): void {
    this._config.changesProcessor.finishEditPoints(updatedPointIds, elementMeasures);
    this.cleanDragData();
  }

  private saveNewSegment(pointIds: [string, string], elementMeasures: DrawingsInstanceMeasure): void {
    const instance = { ...this._config.getInstance(this._instanceId) };
    if (DrawingsGeometryUtils.isRectangle(instance.type, instance.geometry)) {
      instance.type = DrawingsInstanceType.Polygon;
    }
    instance.id = this._instanceId;
    instance.geometry = this._geometryUpdates.geometry;
    const update = DrawingsUpdateUtils.createUpdatePayload(
      instance,
      null,
      null,
      arrayUtils.toDictionary(pointIds, p => p, p => this._config.cachedPoints.getConvertedPoint(p)),
      {
        addLines: this._geometryUpdates.newLines,
        removedLines: this._geometryUpdates.removedLines,
      },
    );
    this._config.changesProcessor.updateFullInstance(update, elementMeasures);
  }

  private cleanDragData(): void {
    this._config.cursorHelper.grabbing = false;
    this._segmentId = null;
    this._lastDragPoint = null;
    this._geometryUpdates = null;
  }

  private updatePointsPosition(
    point: paper.Point,
    pointsToUpdate: string[],
  ): { isValid: boolean, elementMeasures: DrawingsInstanceMeasure } {
    const [start, end] = pointsToUpdate.map(x => this._config.cachedPoints.getPoint(x));
    const angle = end.subtract(start).angle;
    const pointRotated = point.rotate(-angle, start);
    const offset = new paper.Point(0, pointRotated.y - start.y);
    const newEndPoint = end.rotate(-angle, start).add(offset).rotate(angle, start);
    const newStartPoint = start.add(offset).rotate(angle, start);
    const [startId, endId] = pointsToUpdate;
    this._config.cachedPoints.setPoint(startId, newStartPoint);
    this._config.cachedPoints.setPoint(endId, newEndPoint);
    const elementMeasures =
      this._config.instancesGeometryManager.updatePointsPositionInInstance(pointsToUpdate, this._instanceId);
    const isValid = this.isValid(elementMeasures, newStartPoint, newEndPoint);
    return { isValid, elementMeasures };
  }
}
