import QueryString from 'query-string';

import { Notification, NotificationsC } from 'common/interfaces/realtime-messages';
import { State } from 'common/interfaces/state';
import { CompanyResourcesApi } from '../../api/server';
import { BaseResourcesApi } from '../../api/server/base-resource-api';
import { store } from '../../store';
import { UserActivity } from '../activity/interfaces';


const getBaseNotificationUrls = (url: string = ''): string => {
  const state: State = store.getState();
  const subscriptionType = state.account.selectedSubscriptionType;

  if (!subscriptionType) {
    throw new Error(`getBaseNotificationUrls: subscriptionType is empty requested path ${url || '/'}`);
  }

  return `/notifications/${subscriptionType}${url}`;
};

const NotificationResourcesApi = new BaseResourcesApi(getBaseNotificationUrls);


function getCompanySubscriptionActivities(): Promise<UserActivity[]> {
  return CompanyResourcesApi.get('/activities', 'Error in loading company activities');
}

function getDocumentActivities(projectId: number): Promise<UserActivity[]> {
  return NotificationResourcesApi.get(
    `/project/${projectId}/activities`,
    'Error in loading project activities',
  );
}

function getBells(): Promise<Notification[]> {
  return NotificationResourcesApi.getV('/bell', NotificationsC, 'Error in load bell notifications');
}

function readBells(notificationIds: number[], value: boolean): Promise<void> {
  return NotificationResourcesApi.put(
    `/bell/read?${QueryString.stringify({ id: notificationIds })}`,
    { value },
    'Error in mark as read bell notifications',
  );
}

function deleteBells(notificationIds: number[]): Promise<void> {
  return NotificationResourcesApi.delete(
    `/bell?${QueryString.stringify({ id: notificationIds })}`,
    null,
    'Error in deleting bell notifications',
  );
}

export const NotificationApi = {
  getCompanySubscriptionActivities,
  getDocumentActivities,
  getBells,
  readBells,
  deleteBells,
};
