import styled from 'styled-components';

import { CustomDialogStyled } from 'common/components/custom-dialog';
import {
  Styled as ChangeBillingRadioMenuStyled,
} from '../../../../units/subscription/components/subscription-details-new-user/change-billing-radio-menu/styled';

const BUTTON_CONTAINER_HEIGHT = 100;


const CustomDialogWrapper = styled.div`
  ${CustomDialogStyled.Container} {
    height: 90vh;
    padding: 0 0 10px 0;
    overflow: hidden;

    > h6 {
      display: none;
    }
  }

  ${CustomDialogStyled.Body} {
    height: calc(100% - ${BUTTON_CONTAINER_HEIGHT}px);
    min-height: calc(100% - ${BUTTON_CONTAINER_HEIGHT}px);
    margin-bottom: 0;
  }

  ${CustomDialogStyled.Buttons} {
    height: auto;
    background-color: ${p => p.theme.color.backgroundRush};
    border-top: 10px solid ${p => p.theme.color.pale};

    button {
      overflow: hidden;
      margin: 20px;

      &:before {
        content: '';
        position: absolute;
        width: 300px;
        height: 100%;
        background-image: linear-gradient(
          120deg,
          rgba(255,255,255, 0) 30%,
          rgba(255,255,255, .35),
          rgba(255,255,255, 0) 70%
        );
        top: 0;
        left: -100px;
        animation: shine 3s infinite linear;
      }

      @keyframes shine {
        0% {left: -100px}
        20% {left: 100%}
        100% {left: 100%}
      }
    }
  }

  & ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
`;

const StarsContainer = styled.div`
  display: none;
  position: absolute;
  right: 2px;
  top: 0px;

  > svg {
    fill: ${p => p.theme.color.turquoise};
  }
`;

const Container = styled.div`
  position: relative;
  cursor: default;

  button {
    > div {
      left: 20px;
      bottom: 15px;
    }
  }

  button, div:first-child {
    svg {
      fill: ${p => p.theme.color.disabled};
    }
    p {
      color: ${p => p.theme.color.disabled};
    }
  }

  &:hover {
    ${StarsContainer} {
      display: block;
      animation: scale-up-center 0.375s ease both;
    }
  }

  @keyframes scale-up-center {
    0% {
      transform: scale(0) translateX(-10px) translateY(10px);
    }
    75% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1) translateX(0px) translateY(0px);
    }
  }
`;

const BillingRadioMenuWrapper = styled.div`
  margin: 0 20px;

  ${ChangeBillingRadioMenuStyled.RadioMenuContainer} {
    > div:first-child {
      padding-right: 90px;
    }
  }

  ${ChangeBillingRadioMenuStyled.Discount} {
    width: 30px;
    height: 20px;
    left: 140px;
    bottom: 15px;
    background: ${p => p.theme.color.turquoise};

    p {
      color: ${p => p.theme.color.white};
    }
  }
`;


export const Styled = {
  CustomDialogWrapper,
  StarsContainer,
  Container,
  BillingRadioMenuWrapper,
};
