import { AccordionStyled, ColorPickerStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const WrapperWithScroll = styled.div`
  ${AccordionStyled.DescriptionTextContainer} {
    max-height: 90px;
    overflow-y: overlay;
  
    :hover {
      ::-webkit-scrollbar-thumb {
        background: ${p => p.theme.color.disabled};
        transition: background-color 0.5s;
      }
    }
  
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  ${ColorPickerStyled.List} {
    padding: 5px 10px 10px;
  }
`;

const CustomColorsList = styled(WrapperWithScroll)`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, 20px);
  padding: 10px 10px 10px 20px;
  max-height: 80px;
`;

const DefaultColorPickerWrapper = styled.div`
  ${ColorPickerStyled.List} {
    padding: 5px 10px 10px;
  }
`;

const ColorControlWrapper = styled.div`
  ${ColorPickerStyled.Container} {
    padding: 10px 0px;
  }
  ${ColorPickerStyled.List} {
    padding: 0px;
    justify-content: center;
  }

  >div + div {
    border-top: 1px solid ${p => p.theme.color.background};
  }
`;

export const Styled = {
  CustomColorsList,
  WrapperWithScroll,
  DefaultColorPickerWrapper,
  ColorControlWrapper,
};
