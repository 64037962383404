import React, { Component } from 'react';

interface Props {
  className?: string;
  onClick?: (e: any) => void;
}

export class KreoIconEyeNormal extends Component<Props> {
  public render(): any {
    return (
      <svg
        width='16px'
        height='10px'
        className={this.props.className}
        onClick={this.props.onClick}
        data-control-name='hide-button'
        viewBox='0 0 16 10'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs />
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='UI-Elements' transform='translate(-553.000000, -3082.000000)' fill='#D3D7DB'>
            <path
              // eslint-disable-next-line max-len
              d='M561,3092 C558.240973,3092 555.726606,3090.5377 553.456898,3087.61309 C553.176893,3087.2523 553.176893,3086.74764 553.456899,3086.38684 L553.456928,3086.38687 C555.726628,3083.46229 558.240985,3082 561,3082 C563.759027,3082 566.273394,3083.4623 568.543102,3086.38691 C568.823107,3086.7477 568.823107,3087.25236 568.543101,3087.61316 L568.543072,3087.61313 C566.273372,3090.53771 563.759015,3092 561,3092 Z M561,3090 C562.656854,3090 564,3088.65685 564,3087 C564,3085.34315 562.656854,3084 561,3084 C559.343146,3084 558,3085.34315 558,3087 C558,3088.65685 559.343146,3090 561,3090 Z'
              id='eye_normal'
            />
          </g>
        </g>
      </svg>
    );
  }
}
