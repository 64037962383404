import autobind from 'autobind-decorator';
import * as monolite from 'monolite';
import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { mathUtils } from 'common/utils/math-utils';
import { KreoChart, KreoChartMode } from '../../../../../../../../components/kreo-chart';
import { AllLaboursId, ChartDataProvider } from '../../../../../../utils/gantt-chart';
import { AnimationSpeed } from '../../../../enums/animation-speed';
import { ResourceIdentifier } from '../../../../interfaces/resource-identifier';
import { Timeframe } from './components/timeframe';


const hourMs = 60 * 60 * 1000;
const dayMs = 24 * hourMs;

interface TimelineOwnProps {
  projectStartDate: Date;
  timeframeStartDay: number;
  timeframeDuration: number;
  width: number;
  currentMoment: number;
  nextCurrentMoment: number;
  isPlaying: boolean;
  timelineStartDay: number;
  timelineDuration: number;
  dataProvider: ChartDataProvider;
  updateTimeframe: (daysTillStart: number, duration: number) => void;
  updateCurrentMoment: (currentMoment: number) => void;
  updateNextCurrentMoment: (nextCurrentMoment: number) => void;
  play: () => void;
  pause: () => void;
  setAnimationSpeed: (animationSpeed: AnimationSpeed) => void;
}

interface TimelineStateProps {
  resourceToRender: ResourceIdentifier;
  resourceToRenderName: string;
  isPreviewingResourceChart: boolean;
}

interface TimelineProps extends TimelineOwnProps, TimelineStateProps { }

interface TimelineState {
  selectionStart: number;
  selectionEnd: number;
  isCursorOnTimeline: boolean;
  isCursorOnTimeframeHandle: boolean;
  timeframeGestureInProgress: boolean;
  pixelsPerDay: number;
  mouseButtonPressed: boolean;
  wasPlaying: boolean;
  timelineStartDate: Date;
  timelineEndDate: Date;
}

class ProjectTimelineComponent extends React.PureComponent<TimelineProps, TimelineState> {
  private static readonly horizontalMargin: number = 30;

  private readonly minimalFrameDuration: number = 1;
  private readonly defaultFrameDuration: number = 7;
  private readonly timeframeHandleWidth: number = 5;
  private readonly clickToleranceDistance: number = 3;
  private readonly clickToleranceTime: number = 1000;

  private timelineContainer: HTMLDivElement = null;
  private mouseDownTime: number = 0;
  private lastMouseX: number = 0;
  private lastMouseY: number = 0;
  private mouseMovement: number = 0;

  constructor(props: TimelineProps) {
    super(props);

    const derivedState = ProjectTimelineComponent.getDerivedStateFromProps(props, null);

    this.state = {
      selectionStart: null,
      selectionEnd: null,
      isCursorOnTimeline: false,
      isCursorOnTimeframeHandle: false,
      timeframeGestureInProgress: false,
      mouseButtonPressed: false,
      wasPlaying: false,
      pixelsPerDay: derivedState.pixelsPerDay,
      timelineStartDate: derivedState.timelineStartDate,
      timelineEndDate: derivedState.timelineEndDate,
    };
  }

  public static getDerivedStateFromProps(
    nextProps: TimelineProps,
    prevState: TimelineState | null,
  ): Partial<TimelineState> {
    let newState: Partial<TimelineState> | null = null;

    const datesAreaWidth = nextProps.width - 2 * ProjectTimelineComponent.horizontalMargin;
    const pixelsPerDay = datesAreaWidth / nextProps.timelineDuration;
    if (!prevState || pixelsPerDay !== prevState.pixelsPerDay) {
      newState = monolite.set(newState || {}, (s) => s.pixelsPerDay)(pixelsPerDay);
    }

    const timelineStartMs = nextProps.projectStartDate.getTime() + nextProps.timelineStartDay * dayMs;
    if (!prevState || timelineStartMs !== prevState.timelineStartDate.getTime()) {
      newState = monolite.set(newState || {}, (s) => s.timelineStartDate)(new Date(timelineStartMs));
    }

    const timelineEndMs = timelineStartMs + nextProps.timelineDuration * dayMs;
    if (!prevState || timelineEndMs !== prevState.timelineEndDate.getTime()) {
      newState = monolite.set(newState || {}, (s) => s.timelineEndDate)(new Date(timelineEndMs));
    }

    return newState;
  }

  public render(): JSX.Element {
    const chartWidth = this.props.width - ProjectTimelineComponent.horizontalMargin * 2;
    const chartData = this.props.dataProvider.calculateFullChart(
      this.props.resourceToRender.type,
      this.props.resourceToRender.id,
      chartWidth);

    return (
      <div
        className='gantt-chart__project-timeline'
        ref={this.setTimelineContainerRef}
        onWheel={this.onWheel}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseDown={this.onMouseDown}
        onContextMenu={this.preventDefault}
      >
        {
          this.state.selectionStart !== null &&
          !this.state.isCursorOnTimeframeHandle &&
          !this.state.timeframeGestureInProgress &&
          <div
            className='gantt-chart__project-timeline__selection-pointer'
            style={{ left: this.state.selectionStart }}
          />
        }
        {
          this.state.selectionEnd !== null &&
          <div
            className='gantt-chart__project-timeline__selection'
            style={{
              left: Math.min(this.state.selectionStart, this.state.selectionEnd),
              width: Math.abs(this.state.selectionEnd - this.state.selectionStart),
            }}
          />
        }
        {
          this.state.selectionEnd !== null &&
          <div
            className='gantt-chart__project-timeline__selection-pointer'
            style={{ left: this.state.selectionEnd }}
          />
        }

        <div
          className='row dates'
        >
          <div
            className='label start'
            key='start'
          >
            Start {this.state.timelineStartDate.toLocaleDateString(
            'en-GB', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </div>
          <div
            className='label resource-name'
          >
            {this.props.isPreviewingResourceChart ? 'Preview: ' : ''}
            {this.props.resourceToRenderName}
          </div>
          <div
            className='label end'
            key='end'
          >
            {this.state.timelineEndDate.toLocaleDateString('en-GB', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })} End
          </div>
        </div>
        <Timeframe
          timeframeStartDay={this.props.timeframeStartDay}
          timeframeDuration={this.props.timeframeDuration}
          updateTimeframe={this.props.updateTimeframe}
          horizontalMargin={ProjectTimelineComponent.horizontalMargin}
          minimalFrameDuration={this.minimalFrameDuration}
          isPlaying={this.props.isPlaying}
          currentMoment={this.props.currentMoment}
          handleWidth={this.timeframeHandleWidth}
          pixelsPerDay={this.state.pixelsPerDay}
          nextCurrentMoment={this.props.nextCurrentMoment}
          timelineStartDay={this.props.timelineStartDay}
          timelineDuration={this.props.timelineDuration}
          play={this.props.play}
          pause={this.props.pause}
          updateCurrentMoment={this.props.updateCurrentMoment}
          updateNextCurrentMoment={this.props.updateNextCurrentMoment}
          setAnimationSpeed={this.props.setAnimationSpeed}
          setCursorOnHandleStatus={this.setCursorOnHandleStatus}
        />
        <KreoChart
          className='row chart'
          id='labours-timeline'
          backgroundColor='#232836'
          colors={{ main: '#3C455F' }}
          data={chartData}
          mode={KreoChartMode.Single}
          height={30}
          width={this.props.width - ProjectTimelineComponent.horizontalMargin * 2}
          isAvailable={true}
        />
      </div>
    );
  }

  private get timelineOffset(): number {
    return this.timelineContainer
      ? this.timelineContainer.getBoundingClientRect().left
      : 0;
  }

  @autobind
  private onWheel(event: React.WheelEvent<HTMLDivElement>): void {
    const directionSign = Math.sign(event.deltaY);
    const zoomSpeed = 0.05;
    const multiplier = 1 + directionSign * zoomSpeed;

    if (
      (this.props.timeframeDuration <= this.minimalFrameDuration && directionSign < 0) ||
      (this.props.timeframeDuration >= this.props.timelineDuration && directionSign > 0)
    ) {
      return;
    }

    const newDuration = mathUtils.clamp(
      this.props.timeframeDuration * multiplier,
      this.minimalFrameDuration,
      this.props.timelineDuration,
    );

    const offset = this.clampPointerOffset(event.pageX - this.timelineOffset);
    const target = (offset - ProjectTimelineComponent.horizontalMargin) / this.state.pixelsPerDay
      + this.props.timelineStartDay;

    const newDaysTillStart = mathUtils.clamp(
      mathUtils.lerp(this.props.timeframeStartDay, target - newDuration / 2, zoomSpeed * 2),
      this.props.timelineStartDay,
      this.props.timelineStartDay + this.props.timelineDuration - newDuration);

    this.props.updateCurrentMoment(mathUtils.clamp(
      mathUtils.lerp(this.props.currentMoment, target, zoomSpeed * 2),
      newDaysTillStart,
      newDaysTillStart + newDuration,
    ));

    this.props.updateTimeframe(newDaysTillStart, newDuration);
  }

  @autobind
  private onMouseEnter(): void {
    this.setState({
      isCursorOnTimeline: true,
    });
    document.addEventListener('mousemove', this.onMouseMove as any);
  }

  @autobind
  private onMouseMove(event: React.MouseEvent<HTMLDocument>): void {
    if (this.state.mouseButtonPressed) {
      this.mouseMovement += Math.sqrt(
        (event.pageX - this.lastMouseX) ** 2 +
        (event.pageY - this.lastMouseY) ** 2);

      this.lastMouseX = event.pageX;
      this.lastMouseY = event.pageY;

      if (
        performance.now() - this.mouseDownTime > this.clickToleranceTime ||
        this.mouseMovement > this.clickToleranceDistance
      ) {
        const selectionEnd = this.clampPointerOffset(event.pageX - this.timelineOffset);

        const selectionLeftBorder = Math.min(this.state.selectionStart, selectionEnd);
        const newTimeframeStartDay =
          (selectionLeftBorder - ProjectTimelineComponent.horizontalMargin) / this.state.pixelsPerDay +
          this.props.timelineStartDay;

        const selectionWidth = Math.abs(selectionEnd - this.state.selectionStart);
        const newDuration = mathUtils.clamp(
          selectionWidth / this.state.pixelsPerDay,
          this.minimalFrameDuration,
          this.props.timelineDuration);

        const nextCurrentMoment = newTimeframeStartDay + newDuration / 2;

        this.props.updateTimeframe(newTimeframeStartDay, newDuration);
        this.props.updateNextCurrentMoment(nextCurrentMoment);

        this.setState({ selectionEnd });
      }
    } else {
      const pointerOffset = this.clampPointerOffset(event.pageX - this.timelineOffset);
      this.setState({
        selectionStart: pointerOffset,
      });
    }
  }

  @autobind
  private onMouseLeave(): void {
    this.setState({ isCursorOnTimeline: false });
    if (this.state.selectionEnd === null) {
      this.setState({
        selectionStart: null,
      });
      document.removeEventListener('mousemove', this.onMouseMove as any);
    }
  }

  @autobind
  private onMouseDown(event: React.MouseEvent<HTMLDivElement>): void {
    if (
      event.button === 2
      || this.state.isCursorOnTimeframeHandle
    ) {
      this.setState({
        timeframeGestureInProgress: true,
      });

      document.addEventListener('mouseup', this.timeframeGestureMouseUpHandler as any);
      return;
    }

    if (event.button === 0) {
      this.mouseDownTime = performance.now();
      this.lastMouseX = event.pageX;
      this.lastMouseY = event.pageY;
      this.mouseMovement = 0;

      let wasPlaying = false;
      if (this.props.isPlaying) {
        wasPlaying = true;
        this.props.pause();
      }

      this.setState({
        selectionEnd: event.pageX - this.timelineOffset,
        mouseButtonPressed: true,
        wasPlaying,
      });
      document.addEventListener('mouseup', this.onMouseUp as any);
    }
  }

  @autobind
  private onMouseUp(event: React.MouseEvent<HTMLDivElement>): void {
    if (
      performance.now() - this.mouseDownTime < this.clickToleranceTime
      && this.mouseMovement <= this.clickToleranceDistance
    ) {
      this.handleClick(event.pageX);
    } else {
      if (this.props.nextCurrentMoment) {
        this.props.updateCurrentMoment(this.props.nextCurrentMoment);
        this.props.updateNextCurrentMoment(null);
      }
    }

    if (this.state.wasPlaying) {
      this.props.play();
    }

    this.setState({
      selectionEnd: null,
      mouseButtonPressed: false,
      wasPlaying: false,
    });

    document.removeEventListener('mouseup', this.onMouseUp as any);
    if (!this.state.isCursorOnTimeline) {
      this.setState({
        selectionStart: null,
      });
      document.removeEventListener('mousemove', this.onMouseMove as any);
    }
  }

  @autobind
  private setTimelineContainerRef(ref: HTMLDivElement): void {
    this.timelineContainer = ref;
  }

  private clampPointerOffset(offset: number): number {
    return mathUtils.clamp(
      offset,
      ProjectTimelineComponent.horizontalMargin,
      this.props.width - ProjectTimelineComponent.horizontalMargin,
    );
  }

  private preventDefault(event: React.MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
  }

  @autobind
  private timeframeGestureMouseUpHandler(): void {
    this.setState({
      timeframeGestureInProgress: false,
    });

    document.removeEventListener('mouseup', this.timeframeGestureMouseUpHandler as any);
  }

  private handleClick(pointerPageX: number): void {
    const pointerOffset = this.clampPointerOffset(
      pointerPageX - this.timelineOffset);
    const dayUnderThePointer = this.props.timelineStartDay +
      (pointerOffset - ProjectTimelineComponent.horizontalMargin) / this.state.pixelsPerDay;

    this.props.updateTimeframe(dayUnderThePointer - this.defaultFrameDuration / 2, this.defaultFrameDuration);
    this.props.updateCurrentMoment(dayUnderThePointer);
  }

  @autobind
  private setCursorOnHandleStatus(isOnHandle: boolean): void {
    this.setState({ isCursorOnTimeframeHandle: isOnHandle });
  }
}

const mapStateToProps = (state: State): TimelineStateProps => {
  const resourceToRender = state.fourDVisualisation.timelineChart.previewedResource
    || state.fourDVisualisation.timelineChart.selectedResource
    || state.fourDVisualisation.timelineChart.defaultResource;

  const isPreviewingResourceChart = state.fourDVisualisation.timelineChart.previewedResource != null;

  const resourceToRenderName = resourceToRender.id === AllLaboursId
    ? 'All Labours'
    : state.fourDVisualisation.resources[resourceToRender.type].get(resourceToRender.id).displayName;

  return {
    resourceToRender,
    resourceToRenderName,
    isPreviewingResourceChart,
  };
};

export const ProjectTimeline = connect(mapStateToProps)(ProjectTimelineComponent);
