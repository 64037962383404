import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  display: flex;
  bottom: 15px;
  right: 15px;
  z-index: 12;
  box-shadow: ${p => p.theme.shadow.xl};
  background: ${p => p.theme.color.backgroundRush};
  border-radius: 25px;
  flex-direction: row;

  > div:first-child {
    bottom: 50px;
  }

  * {
    outline: none;
  }
`;

const TogglableButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Styled = {
  Container,
  TogglableButtonContainer,
};
