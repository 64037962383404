import * as React from 'react';

import { IconButton, KreoButton, KreoIconAction, MaterialIconMenu } from 'common/UIKit';
import { ReportsSentPopup } from '../../dialog/reports-sent/reports-sent-popup';
import { ExportExcelReportType } from '../interfaces/export-excel-report-type';
import { SelectReportTypeItem } from './select-report-type-item';


interface Props {
  onExportClick: () => void;
  excelReportTypes: ExportExcelReportType[];
  onChangeExcelReportType: (group: string) => void;
  selectedReports: string[];
}

export const FirstPageMenu: React.FC<Props> = (props) => {

  return (
    <div className='export'>
      <ReportsSentPopup/>
      <KreoButton
        className='table-btn header-action'
        size='medium'
        caption='Export'
        onClick={props.onExportClick}
      />
      <MaterialIconMenu
        keepOpen={true}
        iconButtonElement={
          <IconButton size='medium' className='cost-estimate-page__header-icon-btn'>
            <KreoIconAction />
          </IconButton>
        }
      >
        {props.excelReportTypes.map((x, index) => (
          <SelectReportTypeItem
            selected={props.selectedReports.includes(x.type)}
            name={x.name}
            id={x.type}
            key={index}
            onChangeReportType={props.onChangeExcelReportType}
          />
        ))}
      </MaterialIconMenu>
    </div>
  );
};
