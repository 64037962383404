import { ProjectResourcesApi } from '../../../api/server';
import { CostEstimateTreeMap } from '../interfaces/dashboard/cost-estimate-tree-map';
import { CostHistogramViewModel } from '../interfaces/dashboard/cost-histogram';

function getBoqTreeMap(calculationId: number): Promise<CostEstimateTreeMap[]> {
  return ProjectResourcesApi.get(`/calculations/${calculationId}/boq-tree-map`);
}

function getCostHistogram(calculationId: number):
  Promise<CostHistogramViewModel> {
  return ProjectResourcesApi.get(`/calculations/${calculationId}/cost-histogram`);
}


export const DashboardApi = {
  getBoqTreeMap,
  getCostHistogram,
};
