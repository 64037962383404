import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { DashboardActions } from '../actions/creators/dashboard';
import { DashboardActionTypes } from '../actions/types/dashboard';
import { DashboardApi } from '../api/dashboard';
import { CostEstimateGroupingType } from '../enums/cost-estimate-grouping-type';
import { CostEstimateTreeMap } from '../interfaces/dashboard/cost-estimate-tree-map';
import { CostHistogramViewModel } from '../interfaces/dashboard/cost-histogram';
import { Project } from '../interfaces/project';
import { getCurrentProject } from '../selectors';
import { DashboardUtil } from '../utils/dashboard-util';

function* getCostEstimateTreeMap(calculationId: number): SagaIterator {
  try {
    const data: CostEstimateTreeMap[] = yield call(DashboardApi.getBoqTreeMap, calculationId);

    data.map(x => {
      const projectData = x.data.children[0];
      switch (x.groupingType) {
        case CostEstimateGroupingType.Workpackages: {
          projectData.color = DashboardUtil.Colors[0];
          const colors = DashboardUtil.getColors(projectData.children);
          projectData.children = projectData.children.map(v => {
            v.color = colors.find(c => c.name === v.name).color;
            return v;
          });
          break;
        }
        case CostEstimateGroupingType.Nrm1: {
          const color = '#B0D3F7';
          projectData.color = color;
          projectData.children.forEach(y => (y.color = color));
          break;
        }
        case CostEstimateGroupingType.Nrm2: {
          const color = '#96E6FA';
          projectData.color = color;
          projectData.children.forEach(y => (y.color = color));
          break;
        }
        default:
      }
      return x;
    });

    yield put(DashboardActions.getCostEstimateTreeMapDataSucceed(data));
  } catch (error) {
    console.error('dashboard: get cost estimate tree map failed', error);
  }
}

function* getCostHistogram(calculationId: number): SagaIterator {
  try {
    const data: CostHistogramViewModel = yield call(DashboardApi.getCostHistogram, calculationId);

    // improve headers
    data.costs.forEach(d => {
      switch (d.period) {
        case 0:
          d.columns.forEach((x, i) => {
            if (x.header) {
              x.header = {
                title: (i + 1).toString(),
                year: x.header
                  .toString()
                  .replace(/^[a-zA-z\s]*/g, '')
                  .replace(/^.*of./, ''),
              };
            }
          });
          break;
        case 1:
          d.columns.forEach(x => {
            if (x.header) {
              x.header = {
                title: x.header.toString().replace(/[0-9\s]*$/, ''),
                year: x.header.toString().replace(/^[a-zA-Z\s]*/, ''),
              };
            }
          });
          break;
        default:
        // nothing to improve
      }
    });

    yield put(DashboardActions.getCostHistogramDataSucceed(data.costs));
  } catch (error) {
    console.error('dashboard: get cost histogram failed', error);
  }
}


function* getAllData(action: ActionWith<number>): SagaIterator {
  try {
    const calculationId = action.payload;
    const project: Project = yield selectWrapper(getCurrentProject);
    if (project) {
      yield all([
        call(getCostEstimateTreeMap, calculationId),
        call(getCostHistogram, calculationId),
      ]);
    }
  } catch (error) {
    console.error('dashboard: get all data failed', error, action.payload);
  }
}

export function* dashboardSagas(): SagaIterator {
  yield takeLatest(DashboardActionTypes.GET_ALL_DATA_REQUEST, getAllData);
}
