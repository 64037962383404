import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DrawingsAnnotationLegendActions } from 'common/components/drawings';

export function useValues(elements: Record<string, string[]>): {
  values: string[],
  activeElement: string,
  onChange: (value: string) => void,
} {
  const values = useMemo(() => Object.keys(elements), [elements]);
  const dispatch = useDispatch();
  const onChange = useCallback((value) => {
    dispatch(DrawingsAnnotationLegendActions.updateSelection({ instanceIds: elements[value] }));
  }, [elements]);

  const activeElement = useMemo(() => {
    return values.length === 1 ? values[0] : undefined;
  }, [values]);

  return {
    values,
    activeElement,
    onChange,
  };
}
