import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { DrawingsContextMenuContent, DrawingsContextMenuFilter } from '../../drawings-context-menu';
import { ActionEventHandlers } from '../../drawings-context-menu/interfaces';
import { DrawingsSubmenuContainer } from '../submenu-containers';
import { FlyingMenuContextMenuItems } from './menu-items';

interface Props {
  canPaste: boolean;
  hasHiddenInstances: boolean;
  isSelectedInstancesHidden: boolean;
  hasVisibleInstances: boolean;
  isFullScreen: boolean;
  canEditMeasurement: boolean;
  actionEventsHandlers: Record<string, ActionEventHandlers>;
  isTwoDReport: boolean;
  canPasteInSamePlace: boolean;
  onClose: () => void;
}

export const InteractiveContextMenuContent: React.FC<Props> = ({
  canPaste,
  hasHiddenInstances,
  isSelectedInstancesHidden,
  hasVisibleInstances,
  isFullScreen,
  canEditMeasurement,
  actionEventsHandlers,
  isTwoDReport,
  onClose,
  canPasteInSamePlace,
}) => {
  const is2d = useSelector<State, boolean>(x => !x.drawings.is3d);
  const filters =  {
    [DrawingsContextMenuFilter.CanPaste]: canPaste,
    [DrawingsContextMenuFilter.HasHiddenInstances]: hasHiddenInstances,
    [DrawingsContextMenuFilter.CanInstancesHide]: !isSelectedInstancesHidden,
    [DrawingsContextMenuFilter.CanInstancesShow]: isSelectedInstancesHidden,
    [DrawingsContextMenuFilter.HasVisibleInstances]: hasVisibleInstances,
    [DrawingsContextMenuFilter.NotFullScreen]: !isFullScreen,
    [DrawingsContextMenuFilter.FullScreen]: isFullScreen,
    [DrawingsContextMenuFilter.CanEditMeasurement]: canEditMeasurement,
    [DrawingsContextMenuFilter.CanPasteInSamePlace]: canPasteInSamePlace,
    [DrawingsContextMenuFilter.Is2D]: is2d,
  };

  const doActionAndClose = useCallback((action: (...args) => void, ...args) => {
    action(...args);
    onClose();
  }, [onClose]);

  return (
    <DrawingsSubmenuContainer>
      <DrawingsContextMenuContent
        filters={filters}
        items={FlyingMenuContextMenuItems}
        actionEventHandlers={actionEventsHandlers}
        isShowTwoDReport={isTwoDReport}
        optionsConfig={{}}
        onClose={onClose}
        doActionAndClose={doActionAndClose}
      />
    </DrawingsSubmenuContainer>
  );
};
