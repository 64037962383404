import { ActionWith } from 'common/interfaces/action-with';
import { ValidationStep } from '../../enums/validation-step';
import {
  ProjectValidationResultsPayload,
  ProjectValidationResultsResponse,
  ProjectValidationResultsStatistic,
} from '../../interfaces/project-validation-results';
import { ProjectValidationState } from '../../interfaces/project-validation-state';
import { CalculateActivityAssignmentPayload } from '../payloads/activity-assignment';
import {
  ApproveValidationStepPayload,
  GetValidationResultsRequestPayload,
  RevertToValidationStepPayload,
} from '../payloads/validation';
import { ValidationActionTypes } from '../types/validation';

function approveValidationStep(
  step: ValidationStep,
  documentId: number,
  scenarioId?: number,
): ActionWith<ApproveValidationStepPayload> {
  return {
    type: ValidationActionTypes.APPROVE_VALIDATION_STEP,
    payload: {
      step,
      projectId: documentId,
      scenarioId: scenarioId || null,
    },
  };
}

function revertToValidationStep(
  step: ValidationStep,
  documentId: number,
  withRedirect?: boolean,
): ActionWith<RevertToValidationStepPayload> {
  return {
    type: ValidationActionTypes.REVERT_TO_VALIDATION_STEP,
    payload: {
      targetStep: step,
      projectId: documentId,
      withRedirect: withRedirect === false ? false : true,
    },
  };
}

function calculateActivityAssignment(
  documentId: number,
  scenarioId: number,
  databaseIds: number[],
): ActionWith<CalculateActivityAssignmentPayload> {
  return {
    type: ValidationActionTypes.CALCULATE_ACTIVITY_ASSIGNMENT,
    payload: {
      projectId: documentId,
      scenarioId,
      databaseIds,
    },
  };
}

function setProjectValidationState(validationState: ProjectValidationState): ActionWith<ProjectValidationState> {
  return {
    type: ValidationActionTypes.SET_PROJECT_VALIDATION_STATE,
    payload: {
      ...validationState,
    },
  };
}


function getValidationResultsRequest(
  projectId: number,
  scenarioId: number,
): ActionWith<GetValidationResultsRequestPayload> {
  return {
    type: ValidationActionTypes.GET_VALIDATION_RESULTS_REQUEST,
    payload: {
      projectId,
      scenarioId,
    },
  };
}

function getValidationResultsSuccess(
  statistic: ProjectValidationResultsResponse,
  classificationStatistic: ProjectValidationResultsStatistic,
): ActionWith<ProjectValidationResultsPayload> {
  return {
    type: ValidationActionTypes.GET_VALIDATION_RESULTS_SUCCESS,
    payload: {
      ...statistic,
      classificationStatistic,
    },
  };
}

export const ValidationActions = {
  approveValidationStep,
  revertToValidationStep,
  calculateActivityAssignment,
  setProjectValidationState,
  getValidationResultsRequest,
  getValidationResultsSuccess,
};
