import { AppRoute } from 'common/routing/app-route';

export const UrlsFabric = (indexPath: string): any => {
  const index = new AppRoute(`${indexPath}/people`);
  const permissions = new AppRoute(`${indexPath}/people/permissions`);

  return {
    index,
    permissions,
  };
};
