import { AddCompanyUserPopup, ModalWrapper } from '@kreo/kreo-ui-components';
import React from 'react';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { COMPANY_INFO_ADD_PEOPLE_DIALOG } from './constants';
import { RoleData } from './interfaces';

interface Props {
  closeAddPeopleDialog: () => void;
  onInvitePeople: (peopleInvites: Array<{ email: string, role: RoleData }>) => void;
  isAddGuest: boolean;
  emptySeatCount: number;
  userRoles: RoleData[];
  guestRoles: RoleData[];
}

const AddCompanyInfoPopupComponent: React.FC<Props> = ({
  closeAddPeopleDialog,
  onInvitePeople,
  isAddGuest,
  emptySeatCount,
  userRoles,
  guestRoles,
}) => {
  return (
    <DialogWrapper name={COMPANY_INFO_ADD_PEOPLE_DIALOG}>
      <ModalWrapper onExit={closeAddPeopleDialog}>
        <AddCompanyUserPopup
          userRoles={isAddGuest ? guestRoles : userRoles}
          title="Add people to company"
          buttonTitle="Share"
          onFormSubmit={onInvitePeople}
          userLimit={!isAddGuest ? emptySeatCount : null}
        />
      </ModalWrapper>
    </DialogWrapper>
  );
};

export const AddCompanyInfoPopup = React.memo(AddCompanyInfoPopupComponent);
