import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DrawingsGeometryGroup } from 'common/components/drawings';
import { State } from 'common/interfaces/state';

const getRootSelectedGroup = (
  groups: DrawingsGeometryGroup[],
  selectedIds: Set<string>,
  group: DrawingsGeometryGroup,
): string => {
  if (!group.parentId) {
    return group.id;
  }

  if (!selectedIds.has(group.parentId)) {
    return group.id;
  }
  const parent = groups.find(g => g.id === group.parentId);
  return getRootSelectedGroup(groups, selectedIds, parent);
};

export const useSelectedRootGroupIds = (): string[] => {
  const selectedIds = useSelector<State, string[]>(s => s.drawings.selectGeometryGroup);
  const groups = useSelector<State, DrawingsGeometryGroup[]>(s => s.drawings.drawingGeometryGroups);
  return useMemo(() => {
    const selectedSet = new Set(selectedIds);
    const ids = new Set<string>();

    for (const group of groups) {
      if (selectedSet.has(group.id)) {
        ids.add(getRootSelectedGroup(groups, selectedSet, group));
      }
    }

    return Array.from(ids);
  }, [selectedIds, groups]);
};
