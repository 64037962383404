import * as t from 'io-ts';

export const AdminDatabaseVmC = t.intersection(
  [
    t.type({
      id: t.number,
      name: t.string,
    }),
    t.partial({
      version: t.string,
      vendor: t.string,
    }),
  ],
  'AdminDatabaseVm');

export const AdminDatabaseVmsC = t.array(AdminDatabaseVmC);

export type AdminDatabaseVm = t.TypeOf<typeof AdminDatabaseVmC>;
