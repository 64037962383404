export const MetricNames = {
  trial: {
    startFreeTrialFree: 'Start Free Trial Free',
    startFreeTrialIndividual: 'Start Free Trial Individual',
    startFreeTrialTeam: 'Start Free Trial Team',
    extendedTrial: 'Extended the trial',
    cancelledSubscription: 'Cancelled the subscription',
    cancelledWarningWindowOpen: 'The warning window is open',
    cancelledConsequencesWindowOpen: 'The window with the consequences is open',
  },
  projects: {
    createProject: 'Create project',
    createFolder: 'Create project folder',
    createProjectTemplate: 'Create project template',
    createTemplateFromProject: 'Create template from project',
    applyDemoProject: 'Demo project has been applied',
  },
  pia: {
    createProperty: 'Create property',
    createItem: 'Create item',
    createAssembly: 'Create assembly',
    applyItemDatabase: 'Apply item database',
  },
  threeDMeasures: {
    switchingTo3D: 'Switching to 3D',
    switchingTo2D: 'Switching to 2D',
    screenshotCaptured: 'Screenshot Captured',
    propertiesModified: 'Properties Modified',
  },
  measurements: {
    createMeasurements: 'Create measurements',
    launchAutoMeasure: 'Launch auto measure',
    tryLaunchAutoMeasure: 'Try launch auto measure',
    createAutoCount: 'Create auto count',
    setUpAutoScale: 'Setup Auto Scale',
    setUpCalibrateScale: 'Setup calibrate scale',
    setUpStandartScale: 'Setup standart scale',
    aiSuggest: 'Ai suggest',
    requestAM2: 'AutoMeasure 2.0 send prompt',
    requestMagicSearch: 'Magic search requested',
  },
  engineNavigation: {
    textSearch: 'Search&Highlight Text',
  },
  annotaion: {
    createLegend: 'Create legend',
    createStiker: 'Create stiker',
    createDimensionLine: 'Create dimension line',
    createSymbol: 'Create symbol',
  },
  measureManager: {
    createFolder: 'Create measure folder',
    createTemplate: 'Create measure folder template',
    createPreset: 'Create measure preset',
    pinFolder: 'Pin folder',
    unpinAllFolders: 'Unpin all folders',
    moveToGroup: 'Move to group',
    duplicateGroup: 'Duplicate group',
    pivot: 'Pivot Measurements',
    showOnlyGroups: 'Show only groups',
  },
  transferMeasurements: {
    moveToCell: 'Move to Cell',
    folderToCell: 'Group to Cell',
    moveToCellTable: 'Move to cell table',
    dynamicTableToCell: 'Dynamic table to cell',
    selectMeasures: 'Select measures',
    autoMoveToCell: 'Auto move to cell',
  },
  reports: {
    createSpreadSheet: 'Create spread sheet',
    createTakeoffReport: 'Create takeoff report',
    createItemsReport: 'Create items report',
    exportExcl: 'Export excl',
    importExcl: 'Import excl',
    exportPdf: 'Export pdf',
    openReportInNewWindow: 'Report in new window',
  },
  fileManager: {
    addedFile: 'Added file',
    addedFolder: 'Added file folder',
    optimizePage: 'Toggle optimize page',
    optimizeFile: 'Toggle optimize file',
  },
  assignItemAssembly: {
    assignEntity: 'Assign entity',
  },
  posthogPageDropped: {
    crashPage: 'Crash page',
  },
  comments: {
    createThread: 'Create thread',
    createMessage: 'Create message',
    deleteThread: 'Delete thread',
    deleteMessage: 'Delete message',
    resolveThread: 'Resolve thread',
    reopenThread: 'Reopen thread',
    editThread: 'Edit thread',
    editMessage: 'Edit message',
    changeFilter: 'Change filter',
    openComments: 'Open comments',
    tryOpenCommentsWhithoutAccess: 'Try open comments whithout access',
  },
  notification: {
    openNotification: 'Open notification panel',
    goToLink: 'Notification go to link',
    scrollEnd: 'Notification scroll end',
  },
  twoDCopilot: {
    sendPrompt: 'Send prompt in Caddie',
    actionOnResult: 'Action on result',
  },
  invitePeople: {
    inviteAfterCreationSubscription: 'Invite users after creation subscription',
    actionOnResult: 'Action on result',
  },
  common: {
    feedback: 'Feedback about the feature',
  },
};

// Когда добаляется новое событие надо вызвать эту функцию и обновить связь с интерком
export const showNamesStringForIntercom = (): void => {
  const names = [];
  Object.values(MetricNames).forEach(v => Object.values(v).forEach(s => names.push(s)));
  console.warn(names.join(','));
};
