import autobind from 'autobind-decorator';

import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';

@autobind
export class DrawingsRenderedPointsCache  {
  private getPaperPoint: (point: ShortPointDescription) => paper.Point;
  private getPointCoordinates: (pointId: string) => ShortPointDescription;

  private cachedPoints: Record<string, paper.Point> = {};

  constructor(
    getPaperPoint: (point: ShortPointDescription) => paper.Point,
    getPointCoordinates: (pointId: string) => ShortPointDescription,
  ) {
    this.getPaperPoint = getPaperPoint;
    this.getPointCoordinates = getPointCoordinates;
  }

  public isPointInCache(pointId: string): boolean {
    return pointId in this.cachedPoints;
  }

  public setPoint(pointId: string, point: paper.Point): void {
    this.cachedPoints[pointId] = point;
  }

  public setPointFromState(pointId: string): void {
    this.setPointShortDescription(pointId, this.getPointCoordinates(pointId));
  }

  public setPointShortDescription(pointId: string, point: ShortPointDescription): void {
    this.cachedPoints[pointId] = this.getPaperPoint(point);
  }

  public addToPoint(pointId: string, pointToAdd: paper.Point): paper.Point {
    this.cachedPoints[pointId] = this.cachedPoints[pointId].add(pointToAdd);
    return this.cachedPoints[pointId];
  }

  public getPoint(id: string): paper.Point {
    if (!this.isPointInCache(id)) {
      this.setPoint(id, this.getPaperPoint(this.getPointCoordinates(id)));
    }
    return this.cachedPoints[id];
  }

  public getConvertedPoint(pointId: string): ShortPointDescription {
    return [this.cachedPoints[pointId].x, this.cachedPoints[pointId].y];
  }

  public removePoint(pointId: string): void {
    delete this.cachedPoints[pointId];
  }

  public addPoints(points: Record<string, ShortPointDescription>): void {
    for (const [pointId, point] of Object.entries(points)) {
      this.cachedPoints[pointId] = this.getPaperPoint(point);
    }
  }

  public removePoints(pointIds: string[]): void {
    for (const pointId of pointIds) {
      this.removePoint(pointId);
    }
  }

  public clear(): void {
    this.cachedPoints = {};
  }
}

