import { RectangleButton, Icons, H2 } from '@kreo/kreo-ui-components';
import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Urls } from 'routes/quantity-take-off-2d';
import { useInvitePeopleCallback } from './hooks';
import { HEADER_TEXT, LIST_MESSAGES } from './interfaces';
import { MessageContent } from './message-content';
import { Styled } from './styled';


export const ThankYouForTrialPage: React.FC = () => {
  const invitePeople = useInvitePeopleCallback();
  const dispatch = useDispatch();
  const handleClick = useCallback(
    () => {
      invitePeople();
      dispatch(push(Urls.listing.path));
    },
    [dispatch, invitePeople],
  );
  return (
    <Styled.Container>
      <Styled.HeaderTextContainer>
        <H2>{HEADER_TEXT}</H2>
      </Styled.HeaderTextContainer>
      <Styled.BodyContainer>
        <Styled.MessageContentContainer>
          {LIST_MESSAGES.map((el, index) => {
            return (
              <MessageContent
                key={index}
                img={el.img}
                message={el.message}
              />
            );
          })}
        </Styled.MessageContentContainer>
        <Styled.ContinueButtonContainer>
          <RectangleButton
            Icon={Icons.Right}
            mood={'secondary'}
            text={'Continue'}
            width={215}
            height={60}
            onClick={handleClick}
          />
        </Styled.ContinueButtonContainer>
      </Styled.BodyContainer>
    </Styled.Container>
  );
};
