import * as React from 'react';

export const KreoIconCollapse: React.FC = () => {
  return (
    <svg
      className='kreo-icon collapse'
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icon_expanded_l1'>
          <g id='icon_Collapse'>
            <rect id='Rectangle-7' x='0' y='0' width='14' height='14' />
            <polyline
              stroke='#1F222D'
              strokeLinecap='round'
              transform='translate(7.000000, 6.500000) rotate(-180.000000) translate(-7.000000, -6.500000) '
              points='10 5 7 8 4 5'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
