import { Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { RenderIf } from 'common/components/render-if';
import { DrawingsRendererApiContextProps, withRendererApiContext } from '../../drawings-contexts/renderer-api-context';
import { AlignmentType } from '../../drawings-geometry/drawings-helpers/drawings-geometry-modify';
import { DrawingsInstanceType } from '../../enums/drawings-instance-type';
import { DrawingsFlyingMenuContainer } from '../drawings-flying-menu';
import { MenuButton } from './menu-button';

interface Props extends DrawingsRendererApiContextProps {
  sendRef: (ref: HTMLDivElement) => void;
}

interface State {
  showMenu: boolean;
  canSplit: boolean;
}

class DrawingsPointFlyingMenuComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.props.rendererApi.engine.onChangePointsSelection = this.onChangePointsSelection;
    const selectedPoints = this.props.rendererApi.engine.selectedPoints;
    this.state = { showMenu: selectedPoints && !!selectedPoints.length, canSplit: false };
  }

  public componentWillUnmount(): void {
    this.props.rendererApi.engine.onChangePointsSelection = undefined;
  }

  public render(): React.ReactNode {
    if (!this.state.showMenu || !this.props.rendererApi.engine) {
      return null;
    }
    const instanceType = this.props.rendererApi.engine.modifiableInstanceType;
    return (
      <DrawingsFlyingMenuContainer ref={this.props.sendRef}>
        <MenuButton
          text={'Delete'}
          defaultColor="red"
          onClick={this.props.rendererApi.engine.removeModifyPoints}
          Icon={Icons.Delete}
        />
        {instanceType === DrawingsInstanceType.Count ? (
          <MenuButton
            text={'Split'}
            onClick={this.props.rendererApi.engine.splitBySelectedPoints}
            Icon={Icons.CountSplit}
          />
        ) : (
          <>
            <RenderIf condition={instanceType === DrawingsInstanceType.Polyline}>
              <MenuButton
                text='Cut Path'
                onClick={this.props.rendererApi.engine.splitBySelectedPoints}
                Icon={Icons.CutPath}
                disabled={!this.state.canSplit}
                disabledText='With a start and end point, this action is not available'
              />
            </RenderIf>
            <MenuButton
              text={'Align Left'}
              onClick={this.alignLeft}
              Icon={Icons.AlignLeft}
            />
            <MenuButton
              text={'Align Right'}
              onClick={this.alignRight}
              Icon={Icons.AlignRight}
            />
            <MenuButton
              text={'Align Top'}
              onClick={this.alignTop}
              Icon={Icons.AlignTop}
            />
            <MenuButton
              text={'Align Bottom'}
              onClick={this.alignBottom}
              Icon={Icons.AlignBottom}
            />
          </>
        )}
      </DrawingsFlyingMenuContainer>
    );
  }

  @autobind
  private onChangePointsSelection(ids: string[]): void {
    this.setState({ showMenu: !!ids.length, canSplit: this.props.rendererApi.engine.canSplitModifiable() });
  }

  @autobind
  private alignTop(): void {
    this.props.rendererApi.engine.setSelectedPointsAlignment(AlignmentType.TOP);
  }

  @autobind
  private alignBottom(): void {
    this.props.rendererApi.engine.setSelectedPointsAlignment(AlignmentType.BOTTOM);
  }

  @autobind
  private alignLeft(): void {
    this.props.rendererApi.engine.setSelectedPointsAlignment(AlignmentType.LEFT);
  }

  @autobind
  private alignRight(): void {
    this.props.rendererApi.engine.setSelectedPointsAlignment(AlignmentType.RIGHT);
  }
}

export const DrawingsPointFlyingMenu = withRendererApiContext(DrawingsPointFlyingMenuComponent);
