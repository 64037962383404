import * as Ag from 'ag-grid-community';

import './tree-table-cell-name.scss';

import { TreeTableEditTitle } from './tree-table-edit-title';


interface Params extends Ag.ICellRendererParams {
  onRename: (node: Ag.RowNode, name: string) => void | null;
}

export class TreeTableCellName implements Ag.ICellRendererComp  {
  private params: Params;
  private editTitle: TreeTableEditTitle;
  private eEditTitle: HTMLDivElement;
  private eGui: HTMLDivElement;

  public init(params: Params): void {
    this.params = params;
    this.eGui = document.createElement('div');
    this.eGui.className = 'tree-table-cell-name';

    this.editTitle = new TreeTableEditTitle();
    this.editTitle.init(this.getEditTitleParams());
    this.eEditTitle = this.editTitle.getGui();
    this.eGui.appendChild(this.eEditTitle);
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: Params): boolean {
    this.params = params;
    this.editTitle.refresh(this.getEditTitleParams());
    return true;
  }

  public destroy(): void {
    this.editTitle.destroy();
  }

  private getEditTitleParams(): any {
    return {
      value: this.params.value,
      onChange: this.params.onRename
        ? (name) => this.params.onRename(this.params.node, name)
        : null,
    };
  }
}
