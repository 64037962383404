import styled from 'styled-components';

const Container = styled.div`
  width: 20px;
  height: 20px;
`;

export const Styled = {
  Container,
};
