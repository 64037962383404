import styled from 'styled-components';

const HEIGHT = 65;

const Container = styled.div`
  box-sizing: border-box;
  height: ${HEIGHT}px;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  align-items: center;
`;

const FilesCount = styled.div`
  height: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background: ${p => p.theme.color.turquoise};
  border-radius: 5px;
  box-shadow: ${p => p.theme.shadow.m};
  padding: 0 4px;
  box-sizing: border-box;
`;


export const Styled = {
  Container,
  FilesCount,
  HEIGHT,
};
