import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-assembly-entity-custom-assignment.scss';

import { State } from 'common/interfaces/state';
import { KreoIconCheckMark, MaterialInput } from 'common/UIKit';
import { TreeView } from '../../../../components/treeview';
import { Item } from '../../../../components/treeview/tree-view';
import { ActivityCategory } from '../../../../units/databases/interfaces/data';
import { ClassificationActions } from '../../actions/creators/classification';

interface StateProps {
  currentTree: ActivityCategory[];
  title: string;
  filter: string;
  selected: boolean;
}

interface OwnProps {
  isAssembly: boolean;
}

interface DispatchProps {
  setFilter: (value: string) => void;
  setUniClass: (value: ActivityCategory) => void;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

interface ComponentState {
  searchInputTouched: boolean;
  filterQuery: string;
}

class ClassificationAssemblyEntityCustomAssignmentComponent extends React.Component<Props, ComponentState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      searchInputTouched: false,
      filterQuery: null,
    };
  }

  public render(): React.ReactNode {
    const { selected, currentTree } = this.props;
    const touched = this.state.searchInputTouched;
    return (
      <div className='classification-assembly-entity-custom-assignment'>
        <div
          className={classNames(
            'classification-assembly-entity-custom-assignment__field',
            { untouched: !touched },
            { tile: selected },
          )}
        >
          <MaterialInput
            name='search'
            label={touched ? 'Select From Database' : this.props.title}
            onChange={this.searchInputChangeHandler}
            onFocus={this.searchInputFocusHandler}
            value={this.state.filterQuery ? this.state.filterQuery : ''}
            autoFocus={true}
            displayUnderline={touched}
          />
          <label>
            <KreoIconCheckMark/>
          </label>
        </div>
        {touched && (
          <div className='classification-tree'>
            <TreeView
              filter={this.state.filterQuery}
              items={currentTree as Item[]}
              onClick={this.selectUniclass}
              isOnlyLeavesSelect={true}
              selectedCode={''}
            />
          </div>
        )}
      </div>
    );
  }

  @autobind
  private searchInputFocusHandler(): void {
    if (!this.state.searchInputTouched) {
      this.setState({ searchInputTouched: true });
    }
  }

  @autobind
  private selectUniclass(value: ActivityCategory): void {
    this.setState({
      searchInputTouched: false,
      filterQuery: '',
    });
    this.props.setUniClass(value);
  }

  @autobind
  private searchInputChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      filterQuery: event.target.value,
    });
  }
}

const mapStateToProps = (state: State): StateProps => {
  const tempUniclass = state.classification.tempUniclass;
  const filterType = state.classification.changeAssemblyRoot;
  let title = 'Select from Database';
  if (filterType) {
    if (tempUniclass && tempUniclass.system) {
      title = tempUniclass.system.title;
    }
  } else {
    if (tempUniclass && tempUniclass.product) {
      title = tempUniclass.product.title;
    }
  }
  return {
    currentTree: filterType ? state.classification.systems : state.classification.products,
    title,
    filter: state.classification.filterValue,
    selected: state.classification.classificationSelectedIndex < 0,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps => {
  return {
    setFilter: filter => {
      if (props.isAssembly) {
        dispatch(ClassificationActions.systemFilter(filter));
      } else {
        dispatch(ClassificationActions.productFilter(filter));
      }
    },
    setUniClass: value => {
      if (props.isAssembly) {
        dispatch(ClassificationActions.setCustomAssemblySystem(value));
      } else {
        dispatch(ClassificationActions.setCustomLayerProduct(value));
      }
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationAssemblyEntityCustomAssignment =
  connector(ClassificationAssemblyEntityCustomAssignmentComponent);
