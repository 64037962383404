import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CANT_SELECT_PLAN_DIALOG, USER_MORE_THAN_SEATS_DIALOG } from 'common/components/change-plan-dialogs';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { SubscriptionActions } from '../../../actions/creators';
import { BillingPeriodUnit } from '../../../enums/billing-period-unit';
import { CompanyBillingInfoModel } from '../../../interfaces/company-billing-info-model';
import { SubscriptionAddon } from '../../../interfaces/subscription-addon';
import { SubscriptionPlanListingModel } from '../../../interfaces/subscription-plan-listing-model';
import { SubscriptionListingUtils } from '../../../utils/subscription-listing-utils';

type UseHandleSubscriptionClickCallback = (
  billingPeriod: BillingPeriodUnit,
  selectedPlanGroup: string,
  selectedAddons: SubscriptionAddon[],
) => (
  quantity: number,
  paymentCardId: string,
  billingCountry: string,
  vatNumber: string,
) => void;

export const useHandleSubscriptionClickCallback: UseHandleSubscriptionClickCallback = (
  billingPeriod,
  selectedPlanGroup,
  selectedAddons,
) => {
  const dispatch = useDispatch();
  const plansModel = useSelector<State, SubscriptionPlanListingModel | null>(s => s.account.subscriptionPlans);
  const companyUserCount = useSelector<State, number>(s => s.people.companiesUsers.length);
  const ownCompanyBillingInfo = useSelector<State, CompanyBillingInfoModel>(s => s.account.ownCompanyBillingInfo);
  return useCallback(
    (
      quantity,
      paymentCardId,
      billingCountry,
      vatNumber,
    ) => {
      const variant = SubscriptionListingUtils.getSelectedPlanVariant(
        plansModel,
        billingPeriod,
        selectedPlanGroup);
      if (variant.isCollaborationBlocked && companyUserCount > 1) {
        dispatch(KreoDialogActions.openDialog(CANT_SELECT_PLAN_DIALOG));
        return;
      }
      if (companyUserCount > quantity) {
        dispatch(KreoDialogActions.openDialog(USER_MORE_THAN_SEATS_DIALOG));
        return;
      }
      const form = {
        addons: selectedAddons.map(x => x.id),
        planId: variant.id,
        quantity: variant.isCollaborationBlocked ? 1 : quantity,
        billingCountry,
        vatNumber,
        reactivate: false,
        coupons: [],
      };
      dispatch(SubscriptionActions.upgradeSubscription(ownCompanyBillingInfo.subscriptionId, form, paymentCardId));
    },
    [plansModel, billingPeriod, selectedPlanGroup, selectedAddons, ownCompanyBillingInfo, dispatch, companyUserCount],
  );
};
