import * as React from 'react';

import './custom-element-filter-builder-condition-select.scss';

import { CustomElementFilterCollectionOperation } from 'common/enums/custom-element-filter-collection-operation';
import { KreoIconCreateNew } from 'common/UIKit';
import { ItemMenu, ItemMenuType } from '../../../components/controls/item-menu';
import { CustomElementFilterOperatorItem } from './custom-element-filter-builder-operator-item';


interface Props {
  currentValue: CustomElementFilterCollectionOperation;
  addNewCondition: () => void;
  addNewGroup: () => void;
  onConditionChange: (condition: CustomElementFilterCollectionOperation) => void;
}

export class CustomElementFilterConditionSelector extends React.Component<Props> {

  private addConditionMenu: ItemMenuType[] = [
    {
      name: 'Add Condition',
      action: () => this.props.addNewCondition(),
    },
    {
      name: 'Add Group',
      action: () => this.props.addNewGroup(),
    },
  ];

  public render(): JSX.Element {
    const { currentValue } = this.props;

    return (
      <div className='custom-element-filter-builder-condition-select'>
        <div className='custom-element-filter-builder-condition-select__switch'>
          <CustomElementFilterOperatorItem
            onChange={this.props.onConditionChange}
            operation={CustomElementFilterCollectionOperation.All}
            name='AND'
            current={currentValue}
          />
          <CustomElementFilterOperatorItem
            onChange={this.props.onConditionChange}
            operation={CustomElementFilterCollectionOperation.Any}
            name='OR'
            current={currentValue}
          />
        </div>
        <ItemMenu
          className='custom-element-filter-builder-condition-select__create-button'
          buttonSize='small'
          icon={<KreoIconCreateNew />}
          menu={this.addConditionMenu}
        />
      </div>
    );
  }
}
