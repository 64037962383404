import {
  Avatar,
  ClickOutsideWrapper,
  Icons,
  MenuItem,
  Text,
  TinyText,
  UsersList,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RenderIf } from 'common/components/render-if';
import { ElementTooltip } from 'common/components/tooltip';
import { ColorsName } from 'common/enums/kreo-colors';
import { RoleCode } from 'common/enums/role-code';
import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { CompanyProjectHeader } from 'unit-projects/interfaces/company-project-header';
import { Project } from 'unit-projects/interfaces/project';
import { ProjectsFolder } from 'unit-projects/interfaces/projects-folder';
import { AccountApi } from '../../../../../units/account/api';
import { Company } from '../../../../../units/account/interfaces/company';
import { CompanySubscriptionModel } from '../../../../../units/account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../../units/account/selectors';
import { UserListInstance } from '../menu-project-fast-navigation';
import { FolderProjectsContainer } from './folder-projects-container';
import { Styled } from './styled';


interface DispatchProps {
  goToProjects: () => void;
}

interface StateProps {
  firstName: string;
  lastName: string;
  userId: string;
  hasAvatar: boolean;
  folders: ProjectsFolder[];
  company: Company;
  selectedCompany: CompanySubscriptionModel;
  currentProject: Project;
}

interface OwnProps {
  usersList: UserListInstance[];
  parentFolderId: string;
  projectHeader: CompanyProjectHeader;
  onClose: () => void;
  moveProject: () => void;
  onInviteToProject: (projectId: number) => void;
  onDuplicateProject: () => void;
  onProjectRename: () => void;
  deleteProject: () => void;
  onSaveAsTemplate: () => void;
  startCreateComment: () => void;
  onLeaveProject: () => void;
}

interface Props extends DispatchProps, StateProps, AbilityAwareProps, OwnProps {

}

class MenuProjectDropDownComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      firstName,
      lastName,
      userId,
      hasAvatar,
      currentProject,
      folders,
      usersList,
      parentFolderId,
      company,
      selectedCompany,
      ability,
      projectHeader,
      onClose,
      moveProject,
      onDuplicateProject,
      onProjectRename,
      deleteProject,
      onSaveAsTemplate,
      goToProjects,
      onInviteToProject,
      startCreateComment,
      onLeaveProject,
    } = this.props;
    const fullName = `${firstName} ${lastName}`;
    const avatarUrl = AccountApi.getAvatarLink({ id: userId, hasAvatar });
    const currentFolderId = currentProject.folderId ? currentProject?.folderId.toString() : undefined;
    const folderId = parentFolderId ? parentFolderId : currentFolderId;
    const group = folderId ? folders.find(g => g.id.toString() === folderId) : null;
    const showPath = group && group?.parentFolderId;
    const isInvitedUsers = usersList && usersList.length > 0;
    const showFolderContainer = folderId === 'null' ? null : folderId;
    const isRoleGuest = selectedCompany.userRoleCode === RoleCode.Guest;
    const canManageProjectTemplates = ability.can(Operation.Manage, Subject.Project2DTemplates);
    const canCreateComment = ability.can(Operation.Manage, Subject.Comment2d);
    const canShareProject = ability.can(Operation.Manage, Subject.ShareProjects);
    const canManageProject = this.canManageProject();
    const companyName = company.name;
    const companyLogo = AccountApi.getCompanyLogoLink(company);
    const isCompanyShared = projectHeader && projectHeader.isCompanyShared;

    return (
      <ClickOutsideWrapper onExit={onClose}>
        <Styled.Container>
          <Styled.Header>
            <Avatar
              name={fullName}
              size={40}
              avatar={avatarUrl}
              onClick={null}
            />
            <Styled.FullName>
              <Text
                color={ColorsName.additionalFont}
                textTransform='Capitalize'
                fontSize={12}
              >
                {'CREATOR'}
              </Text>
              <Text
                fontSize={16}
                withEllipsis={true}
              >
                {fullName}
              </Text>
            </Styled.FullName>
          </Styled.Header>
          <Styled.ProjectMenuDate>
            <Icons.Clock />
            <TinyText color={'gray'}>
              {moment(currentProject.created).format('MMMM Do YYYY, h:mm a')}
            </TinyText>
          </Styled.ProjectMenuDate>
          <RenderIf condition={!!showFolderContainer && !isRoleGuest}>
            <Styled.FolderContainer showPath={!!showPath}>
              <FolderProjectsContainer
                id={folderId}
                withoutArrowContainer={true}
              />
            </Styled.FolderContainer>
          </RenderIf>
          <Styled.MoveToItemContainer withFolderPath={!!parentFolderId}>
            <RenderIf condition={!isRoleGuest}>
              <MenuItem
                Icon={Icons.MoveToGroup_1}
                text='Move to folder'
                onClick={moveProject}
                fontSize={14}
                textColor={ColorsName.mainFont}
                withBorder={true}
              />
            </RenderIf>
          </Styled.MoveToItemContainer>
          <RenderIf condition={canShareProject || isCompanyShared || isInvitedUsers}>
            <Styled.ShareContainer
              isInvitedUsers={isInvitedUsers}
              isRoleGuest={isRoleGuest}
              isCompanyShared={isCompanyShared}
            >
              <RenderIf condition={isCompanyShared || isInvitedUsers}>
                <Styled.UsersListWrapper
                  isCompanyShared={isCompanyShared}
                >
                  <ElementTooltip
                    text={'Available to the whole company'}
                    position='bottom'
                    wordBreakAll={true}
                  >
                    <Avatar
                      name={companyName}
                      size={40}
                      avatar={companyLogo}
                    />
                  </ElementTooltip>
                  <UsersList
                    maxUsers={4}
                    users={usersList}
                    avatarHeight={40}
                    avatarShift={20}
                    borderWidth={3}
                    backgroundColor={'backgroundRush'}
                  />
                </Styled.UsersListWrapper>
              </RenderIf>
              <RenderIf condition={!isRoleGuest && canShareProject}>
                <MenuItem
                  Icon={Icons.AddUser}
                  text='Share'
                  onClick={onInviteToProject}
                  fontSize={14}
                  textColor={ColorsName.mainFont}
                  withBorder={true}
                />
              </RenderIf>
            </Styled.ShareContainer>
          </RenderIf>
          <Styled.Main>
            <RenderIf condition={!isRoleGuest}>
              <MenuItem
                Icon={Icons.Duplicate}
                text='Duplicate'
                onClick={onDuplicateProject}
                fontSize={14}
                textColor={ColorsName.mainFont}
                withBorder={true}
              />
              <MenuItem
                Icon={Icons.Edit2D}
                text='Rename'
                onClick={onProjectRename}
                fontSize={14}
                textColor={ColorsName.mainFont}
                withBorder={true}
              />
              <MenuItem
                Icon={Icons.Delete}
                text={canManageProject ? 'Delete' : 'Leave'}
                onClick={canManageProject ? deleteProject : onLeaveProject}
                fontSize={14}
                textColor={ColorsName.mainFont}
                withBorder={true}
                disabled={!canManageProject && isCompanyShared}
              />
              <UpgradeWrapper isNeedUpdate={!canManageProjectTemplates}>
                <MenuItem
                  Icon={Icons.Templates}
                  text='Save as template'
                  onClick={onSaveAsTemplate}
                  fontSize={14}
                  withBorder={true}
                  textColor={!canManageProjectTemplates && ColorsName.disabled}
                  disabled={!canManageProjectTemplates}
                />
              </UpgradeWrapper>
            </RenderIf>
            <RenderIf condition={canCreateComment}>
              <MenuItem
                Icon={Icons.Comments2D}
                text='Add Comment'
                onClick={startCreateComment}
                fontSize={14}
                textColor={ColorsName.mainFont}
                withBorder={true}
              />
            </RenderIf>
            <MenuItem
              Icon={Icons.Exit}
              text='Go to projects'
              onClick={goToProjects}
              fontSize={14}
              textColor={ColorsName.mainFont}
              withBorder={true}
            />
          </Styled.Main>
        </Styled.Container>
      </ClickOutsideWrapper>
    );
  }

  @autobind
  private canManageProject(): boolean {
    const { currentProject, userId, ability } = this.props;
    const canManageProject = ability.can(Operation.Delete, Subject.Project) && userId === currentProject.ownerId;

    return canManageProject;
  }
}

function mapStateToProps(state: State): StateProps {
  const { firstName, lastName, id, hasAvatar } = state.account;
  return {
    firstName,
    lastName,
    userId: id,
    hasAvatar,
    folders: state.projects.folders,
    selectedCompany: AccountSelectors.currentSubscription(state),
    company: state.account.selectedCompany,
    currentProject: state.projects.currentProject,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    goToProjects: () => dispatch(push(AppUrls.qto2d.listing.path)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const MenuProjectDropDown = connector(withAbilityContext(MenuProjectDropDownComponent));
