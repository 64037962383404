import styled from 'styled-components';

const RenameDialogContainer = styled.div`
  width: 480px;
  border-radius: 20px;
  background: ${p => p.theme.color.backgroundRush};
  padding: 30px 30px 40px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  label {
    margin: 0;
  }
  &, * {
    box-sizing: border-box;
  }
`;

const MeasurementsNumber = styled.div`
  height: 20px;
  min-width: 20px;
  border-radius: 5px;
  background: ${p => p.theme.color.turquoise};
  padding: 2px 6px 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  > div:last-child {
    margin-left: auto;
  }
`;

const ModeButtons = styled.div`
  display: flex;
  gap: 10px;
  margin: 30px 0 20px;
`;

export const Styled = {
  RenameDialogContainer,
  MeasurementsNumber,
  DialogHeader,
  ModeButtons,
};
