import { Icons, LinkComponent, RectangleButton } from '@kreo/kreo-ui-components';
import React from 'react';

import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { PageTabs } from '../page-tabs';
import { Styled } from '../styled';

interface Props {
  selectedDrawing: string;
  setSelectedDrawing: (drawingId: string) => void;
  apply: () => void;
  currentDrawingInfo: DrawingsShortInfo;
  reset: () => void;
  close: () => void;
  canSaveLines: boolean;
}

export const Header: React.FC<Props> = ({
  selectedDrawing,
  setSelectedDrawing,
  apply,
  currentDrawingInfo,
  close,
  reset,
  canSaveLines,
}) => {
  return (
    <Styled.Header>
      <LinkComponent
        Icon={Icons.Left}
        fontSize={14}
        text={'Close'}
        onClick={close}
      />
      <PageTabs
        selectedDrawing={selectedDrawing}
        drawings={[currentDrawingInfo.drawingId, currentDrawingInfo.pageToCompareId]}
        selectDrawing={setSelectedDrawing}
      />
      <Styled.HeaderActionButtons>
        <LinkComponent
          text='Reset'
          onClick={reset}
          fontSize={14}
          Icon={Icons.Reset}
          mood={canSaveLines ? 'default' : 'disabled'}
        />
        <RectangleButton
          mood={canSaveLines ? 'positive' : 'disabled'}
          onClick={apply}
          ghost={!canSaveLines}
          Icon={Icons.Apply}
          text={'Apply'}
        />
      </Styled.HeaderActionButtons>
    </Styled.Header>
  );
};
