import autobind from 'autobind-decorator';
import { StrictAngleController } from '../../interfaces';
import { DrawingsDragCallbackParams } from '../drag-instances';
import { BaseRotationProcessor, RotationProcessorConfig } from './base-rotation-processor';

interface Config extends RotationProcessorConfig {
  strictController: StrictAngleController;
}

export class StrictAngleRotationProcessor extends BaseRotationProcessor<Config> {
  public override startRotate(point: paper.Point): void {
    super.startRotate(point);
    this._config.strictController.activate();
  }

  @autobind
  protected override changeRotation(dragCallback: DrawingsDragCallbackParams): boolean {
    if (!this._config.strictController.isEnabled()) {
      return super.changeRotation(dragCallback);
    }
    const { point, finish } = dragCallback;
    let angle = point.subtract(this._centerPoint).angle;
    const gradeAngle = angle % 15;
    if (gradeAngle === 0) {
      return super.changeRotation(dragCallback);
    }
    if (15 - Math.abs(gradeAngle) < Math.abs(gradeAngle)) {
      if (gradeAngle > 0) {
        angle = angle + (15 - gradeAngle);
      } else {
        angle = angle - (15 + gradeAngle);
      }
    } else {
      angle -= gradeAngle;
    }
    const diffAngle = angle - this._angle;
    this._angle = angle;
    if (finish) {
      this._config.strictController.deactivate();
    }
    return this._config.changeRotation(diffAngle, finish);
  }
}
