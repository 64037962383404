import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, StaticContext } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './permissions-page.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { MainLayout } from 'common/components/main-layout';
import { SubHeader } from 'common/components/sub-header';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { SubscriptionActions } from '../../../../units/subscription/actions/creators';
import { Company } from '../../../account/interfaces/company';
import { UpdateRolePermissions } from '../../../account/interfaces/update-role-permissions';
import { getRootMenuItems } from '../../../menu-items';
import { ManageRolesDialog, ManageRolesDialogName } from '../../components/manage-roles-dialog';
import { PermissionTable } from '../../components/permission-table';
import { MappedRolesPermissions } from '../../interfaces/mapped-roles-permissions';
import { PermissionUtil } from '../../utils/permissions-util';

interface PageStateProps {
  company: Company;
  updateRolesPermissionStatus: RequestStatus;
  updateRolesStatus: RequestStatus;
  rolesPermissions: MappedRolesPermissions;
}

interface PageDispatchProps {
  loadSubscriptionRoles(): void;
  updateSubscriptionPermissionRoles(roles: UpdateRolePermissions[]): void;
  openDialog(): void;
}

interface PageProps
  extends PageStateProps,
    PageDispatchProps,
    RouteComponentProps<any, StaticContext>,
    AbilityAwareProps {}

class PermissionsPageComponent extends React.Component<PageProps> {
  public render(): React.ReactNode {
    const {
      company,
      updateRolesPermissionStatus,
      ability,
      rolesPermissions,
      updateRolesStatus,
    } = this.props;

    const subHeaderButtonRelatedProps = ability.can(Operation.Update, Subject.Role)
      ? {
        buttonText: 'Manage Roles',
        onButtonClick: this.props.openDialog,
      }
      : {};

    return (
      <MainLayout
        getMenuItems={getRootMenuItems}
        backUrl={AppUrls.products.url()}
        subHeader={
          <SubHeader
            title='Roles & Permissions'
            buttonClassname='permissions-page__manage-btn'
            {...subHeaderButtonRelatedProps}
          />
        }
        metaTitle='Roles & Permissions'
      >
        <div className='permissions-page'>
          <PermissionTable
            companyId={company && company.id}
            updateCompanyPermissionRoles={this.props.updateSubscriptionPermissionRoles}
            updateRolesPermissionStatus={updateRolesPermissionStatus}
            updateRolesStatus={updateRolesStatus}
            rolesPermissions={rolesPermissions}
          />
          <ManageRolesDialog />
        </div>
      </MainLayout>
    );
  }

  public componentDidMount(): void {
    this.props.loadSubscriptionRoles();
  }
}

const mapStateToProps = (state: State): PageStateProps => {
  const companyRoles = state.account.subscriptionRoles;
  return {
    company: state.account.selectedCompany,
    updateRolesPermissionStatus: state.account.updateRolesPermissionStatus,
    updateRolesStatus: state.account.updateRolesStatus,
    rolesPermissions: PermissionUtil.getRolesPermissions(companyRoles),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): PageDispatchProps => {
  return {
    loadSubscriptionRoles: () => dispatch(SubscriptionActions.loadSubscriptionRoles()),
    updateSubscriptionPermissionRoles: roles =>
      dispatch(SubscriptionActions.updateSubscriptionPermissionRoles(roles)),
    openDialog: () => dispatch(KreoDialogActions.openDialog(ManageRolesDialogName)),
  };
};

export const PermissionsPage = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PermissionsPageComponent),
);
