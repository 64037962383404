import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { DatabaseCommonActions } from '../actions/creators/database-common';
import { LoadActivityCategoryCountsPayload } from '../actions/payloads/database-common';
import { DatabaseCommonActionTypes } from '../actions/types/database-common';
import { ActivityCategoriesApi } from '../api/activity-categories';
import { UnitsApi } from '../api/units';


function* loadCategories(): SagaIterator {
  try {
    const categories = yield call(ActivityCategoriesApi.getActivityCategories);
    yield put(DatabaseCommonActions.loadActivityCategoriesResponse(categories));
  } catch (error) {
    console.error('database common: load categories failed', error);
  }
}

function* loadCategoryCounts(action: ActionWith<LoadActivityCategoryCountsPayload>): SagaIterator {
  try {
    const { databaseId, type } = action.payload;
    const counts = yield call(ActivityCategoriesApi.getActivityCategoryCounts, databaseId, type);
    yield put(DatabaseCommonActions.loadActivityCategoryCountsResponse(type, counts));
  } catch (error) {
    console.error('database common: load category counts failed', error, action.payload);
  }
}

function* loadFunctionsWithUnits(action: ActionWith<number>): SagaIterator {
  try {
    const databaseId = action.payload;
    const units = yield call(UnitsApi.getUnits, databaseId);
    const measurements = yield call(UnitsApi.getMeasurements);
    const measurementTypes = yield call(UnitsApi.getMeasurementTypes);
    const extractorFunctions = yield call(UnitsApi.getExtractorFunctions);
    yield put(DatabaseCommonActions.loadFunctionsWithUnitsResponse(
      units, measurements, measurementTypes, extractorFunctions,
    ));
  } catch (error) {
    console.error('database common: load functions with units failed', error, action.payload);
  }
}

export function* commonSagas(): SagaIterator {
  yield takeLatest(DatabaseCommonActionTypes.GET_ACTIVITY_CATEGORIES, loadCategories);
  yield takeLatest(DatabaseCommonActionTypes.GET_ACTIVITY_CATEGORY_COUNTS, loadCategoryCounts);
  yield takeLatest(DatabaseCommonActionTypes.LOAD_FUNCTIONS_WITH_UNITS, loadFunctionsWithUnits);
}
