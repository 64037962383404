import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconNoCalculation: React.FC = () => {
  return (
    <svg width='60px' height='60px' viewBox='0 0 60 60' version='1.1'>
      <g
        id='Icons/60x60/NoCalculation'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          // eslint-disable-next-line max-len
          d='M54.9811303,31.0196348 C54.4662343,44.3723473 43.4788137,55.0392693 30,55.0392693 C16.1928813,55.0392693 5,43.846388 5,30.0392693 C5,16.5670654 15.6564627,5.5838118 28.9999923,5.05890406 L29,15.0328046 C21.1819682,15.5473906 15,22.0517573 15,30 C15,38.2842712 21.7157288,45 30,45 C37.9415876,45 44.4417161,38.8283799 44.9658903,31.0196348 L54.9811303,31.0196348 L54.9811303,31.0196348 Z'
          id='Path'
          fill={KreoColors.gray2}
        />
        <path
          // eslint-disable-next-line max-len
          d='M31,5.01963449 C44.0118212,5.53149347 54.4685065,15.9881788 54.9803652,29 L44.9671954,29 C44.4743646,21.5124918 38.4875082,15.5256354 31,15.0328046 L31,5.01963449 L31,5.01963449 Z'
          id='Path'
          fill={KreoColors.gray4}
        />
      </g>
    </svg>
  );
};
