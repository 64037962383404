import autobind from 'autobind-decorator';
import * as React from 'react';

import './validation-resizable-editor.scss';

import {
  ValidationEngineResizableContext,
  ValidationEngineResizableContextInterface,
} from '../validation-engine-resizable-layout-context-provider';


class ValidationResizableEditorComponent extends React.Component<ValidationEngineResizableContextInterface> {
  private readonly minWidth: number = 330;
  private container: HTMLDivElement = null;
  private resizer: HTMLDivElement = null;
  private button: HTMLDivElement = null;
  private currentWidth: number = 420;
  private buttonOver: boolean = false;
  private buttonClicked: boolean = false;
  private lastFixedWidth: number = 420;

  public componentDidMount(): void {
    this.props.getApi({
      onResize: this.onResize,
      onResizeOff: this.onResizeOff,
    });
    this.props.onResize(this.currentWidth);
  }

  public render(): React.ReactNode {
    return (
      <div
        className='validation-resizable-editor'
        ref={this.saveContainerRef}
      >
        {this.props.children}
        <div
          className='validation-resizable-editor__resizer '
          onMouseDown={this.resizerOnMouseDown}
          ref={this.saveResizerRef}
        >
          <div className='validation-resizable-editor__line' />
          <div
            className='validation-resizable-editor__button'
            ref={this.saveButtonRef}
            onMouseOver={this.onResizeButtonOver}
            onMouseOut={this.onResizeButtonOut}
            onClick={this.onResizeButtonClick}
            onContextMenu={this.onResizeButtonClick}
          />
        </div>
      </div>
    );
  }

  @autobind
  private saveButtonRef(ref: HTMLDivElement): void {
    this.button = ref;
  }

  @autobind
  private onResizeButtonClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    if (this.currentWidth < this.minWidth) {
      this.props.onResize(this.minWidth);
      this.button.classList.toggle('validation-resizable-editor__button--active');
      this.currentWidth = this.minWidth;
    } else {
      this.props.onResize(-this.currentWidth);
      this.button.classList.toggle('validation-resizable-editor__button--active');
      this.currentWidth = 0;
    }

    this.buttonClicked = true;
    this.lastFixedWidth = this.currentWidth;
    this.container.style.width = `${this.currentWidth}px`;
  }

  @autobind
  private onResizeButtonOver(): void {
    this.buttonOver = true;
  }

  @autobind
  private onResizeButtonOut(): void {
    this.buttonOver = false;
  }

  @autobind
  private saveContainerRef(ref: HTMLDivElement): void {
    this.container = ref;
  }

  @autobind
  private saveResizerRef(ref: HTMLDivElement): void {
    this.resizer = ref;
  }

  @autobind
  private resizerOnMouseDown(): void {
    if (this.buttonOver) return;
    this.resizer.classList.toggle('validation-resizable-editor__resizer--active');
    this.props.onResizeStart();
  }


  @autobind
  private onResize(e: React.MouseEvent<HTMLDivElement>): number {
    if (this.buttonClicked) {
      this.buttonClicked = false;
      return null;
    }

    const { x } = e.currentTarget.getBoundingClientRect() as DOMRect;
    const newWidth = e.clientX - x;
    const width = `${newWidth}px`;
    this.container.style.width = width;
    this.container.style.maxWidth = width;
    this.currentWidth = newWidth;
    return newWidth;
  }


  @autobind
  private onResizeOff(): number {
    this.resizer.classList.toggle('validation-resizable-editor__resizer--active');
    if (this.buttonClicked) {
      this.buttonClicked = false;
      return null;
    }
    let deltaWidth = 0;
    if (this.lastFixedWidth === 0) {
      this.button.classList.toggle('validation-resizable-editor__button--active');
    }

    if (this.currentWidth < this.minWidth) {
      deltaWidth = this.minWidth - this.currentWidth;
      if (deltaWidth > this.minWidth / 2) {
        deltaWidth = -this.currentWidth;
        this.currentWidth = 0;
        this.button.classList.toggle('validation-resizable-editor__button--active');
      } else {
        this.currentWidth = this.minWidth;
      }
    }

    const width = `${this.currentWidth}px`;
    this.container.style.width = width;
    this.lastFixedWidth = this.currentWidth;
    return deltaWidth;
  }
}


export const ValidationResizableEditor: React.FC = ({ children }) => {
  return (
    <ValidationEngineResizableContext.Consumer>
      {
        (context) => (
          <ValidationResizableEditorComponent
            {...context}
          >
            {children}
          </ValidationResizableEditorComponent>
        )
      }
    </ValidationEngineResizableContext.Consumer>
  );
};
