import React from 'react';

import './sc-number.scss';

import { KreoIconArrowDown, KreoIconArrowUp, KreoIconBest } from 'common/UIKit';
import { formatCostValue } from './utils';

interface Props {
  source: number;
  scdata: number;
}

export class ScNumber extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <div className='subcontractor-number'>
        <span className='user-value'>
          {this.props.scdata ? formatCostValue(this.props.scdata) : '...'}
          <div className='sc-icon-container'>{this.getIcon()}</div>
        </span>
      </div>
    );
  }

  private getIcon(): React.ReactNode {
    const { source, scdata } = this.props;
    if (scdata === 0) {
      return null;
    } else if (source === scdata) {
      return <KreoIconBest />;
    } else if (source > scdata) {
      return <KreoIconArrowDown />;
    } else if (source < scdata) {
      return <KreoIconArrowUp />;
    }
  }
}
