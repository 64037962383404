import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconLayerInsulationMembrane: React.FC = () => (
  <svg
    width='44px'
    height='44px'
    viewBox='0 0 44 44'
    version='1.1'
  >
    <g id='Icons/44x44/Layer-Insulation-Membrane' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        // eslint-disable-next-line max-len
        d='M12.361232,9.54945118 L28.4690376,17.3732424 L31.5675857,15.6518268 L15.5437785,7.63992326 L12.361232,9.54945118 Z M11.5,11.3545758 L11.5,27.881966 L27.5,35.881966 L27.5,19.1260044 L11.5,11.3545758 Z M29.5,35.7338096 L32.5,33.9338096 L32.5,17.4217367 L29.5,19.0884033 L29.5,35.7338096 Z M9.5,27.881966 L9.5,10.0661904 C9.5,9.36366467 9.86859767,8.71265102 10.4710085,8.35120453 L14.514787,5.92493741 C15.1011413,5.57312483 15.8265958,5.54526389 16.4382057,5.85106888 L33.3944272,14.3291796 C34.0719952,14.6679636 34.5,15.3604899 34.5,16.118034 L34.5,33.9338096 C34.5,34.6363353 34.1314023,35.287349 33.5289915,35.6487955 L29.485213,38.0750626 C28.8988587,38.4268752 28.1734042,38.4547361 27.5617943,38.1489311 L10.6055728,29.6708204 C9.92800475,29.3320364 9.5,28.6395101 9.5,27.881966 Z M13.738876,18.4640759 C13.482574,18.5668566 13.1914799,18.442403 13.0886992,18.186101 C12.9859185,17.9297989 13.1103721,17.6387048 13.3666741,17.5359241 C15.8808713,16.5276957 17.968803,17.6440028 20.3926312,20.6850317 C21.5941134,22.1663345 23.212001,22.4575485 25.3561156,21.540299 C25.6100014,21.4316869 25.903864,21.5494546 26.0124761,21.8033405 C26.1210881,22.0572264 26.0033204,22.351089 25.7494346,22.459701 C23.2163212,23.5433637 21.1169325,23.1654813 19.6133115,21.3116437 C17.4305251,18.5730616 15.7318456,17.6648671 13.738876,18.4640759 Z M13.738876,24.4640759 C13.482574,24.5668566 13.1914799,24.442403 13.0886992,24.186101 C12.9859185,23.9297989 13.1103721,23.6387048 13.3666741,23.5359241 C15.8808713,22.5276957 17.968803,23.6440028 20.3926312,26.6850317 C21.5941134,28.1663345 23.212001,28.4575485 25.3561156,27.540299 C25.6100014,27.4316869 25.903864,27.5494546 26.0124761,27.8033405 C26.1210881,28.0572264 26.0033204,28.351089 25.7494346,28.459701 C23.2163212,29.5433637 21.1169325,29.1654813 19.6133115,27.3116437 C17.4305251,24.5730616 15.7318456,23.6648671 13.738876,24.4640759 Z'
        id='Shape'
        fill={KreoColors.blue}
        fillRule='nonzero'
      />
    </g>
  </svg>
);
