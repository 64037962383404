import { ActionWith } from 'common/interfaces/action-with';
import { DumpProjectParams } from '../payloads/projects';
import { AdminProjectsActionTypes } from '../types/projects';

function download(projectId: number, quality: number): ActionWith<DumpProjectParams> {
  return {
    type: AdminProjectsActionTypes.DOWNLOAD,
    payload: {
      projectId,
      quality,
    },
  };
}

export const AdminProjectsActions = {
  download,
};
