import * as Ag from 'ag-grid-community';


export class NameTextareaCellEditor implements Ag.ICellEditorComp {
  private eGui: HTMLTextAreaElement;

  public getValue(): string {
    return this.eGui.value;
  }

  public init(params: Ag.ICellEditorParams): void {
    this.eGui = document.createElement('textarea');
    this.eGui.className = 'name-textarea-cell-editor';
    this.eGui.innerText = params.value;

    setTimeout(() => this.eGui.select(), 1);
    this.eGui.setSelectionRange(params.value.length, params.value.length);
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }
}
