import * as React from 'react';

import { ClassificationAssignmentStage } from '../../enums/classification-assignment-stage';
import { ClassificationAssemblyEntityAssignment } from '../classification-assembly-entity-assignment';
import { ClassificationAssemblyInfoLayout } from '../classification-assembly-info-layout';

interface Props {
  stage: ClassificationAssignmentStage;
}


export const ClassificationAssemblyAssignment: React.FC<Props> = ({ stage }) => {
  switch (stage) {
    case 1:
      return (<ClassificationAssemblyEntityAssignment/>);
    case 0:
    default:
      return (<ClassificationAssemblyInfoLayout />);
  }
};
