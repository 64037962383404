import { Parser } from 'expr-eval';

import {
  ExcelExpressionEvaluatorAvaliableDefaultBinaryOps,
  ExcelExpressionEvaluatorOverrideBinaryOps,
} from './excel-expression-evaluator-binary-ops';
import {
  ExcelExpressionEvaluatorAvaliableDefaultFunctions,
  ExcelExpressionEvaluatorOverrideFunctions,
} from './excel-expression-evaluator-functions';
import {
  ExcelExpressionEvaluatorAvaliableDefaultUnaryOps,
  ExcelExpressionEvaluatorOverrideUnaryOps,
} from './excel-expression-evaluator-unary-ops';


export const ExcelExpressionEvaluator = new Parser();
delete ExcelExpressionEvaluator.consts.PI;

const overriderExcelExpressionEvaluatorOperators = (
  operatorType: 'functions' | 'unaryOps' | 'binaryOps',
  availableDefaults: string[],
  overrideDefault: Record<string, () => any>,
): void => {
  const defaultOperators = ExcelExpressionEvaluator[operatorType];
  ExcelExpressionEvaluator[operatorType] = {};

  availableDefaults
    .forEach(x => ExcelExpressionEvaluator[operatorType][x] = defaultOperators[x]);
  Object.entries(overrideDefault)
    .forEach(([key, operator]) => ExcelExpressionEvaluator[operatorType][key] = operator);
};

overriderExcelExpressionEvaluatorOperators(
  'functions',
  ExcelExpressionEvaluatorAvaliableDefaultFunctions,
  ExcelExpressionEvaluatorOverrideFunctions,
);

overriderExcelExpressionEvaluatorOperators(
  'unaryOps',
  ExcelExpressionEvaluatorAvaliableDefaultUnaryOps,
  ExcelExpressionEvaluatorOverrideUnaryOps,
);

overriderExcelExpressionEvaluatorOperators(
  'binaryOps',
  ExcelExpressionEvaluatorAvaliableDefaultBinaryOps,
  ExcelExpressionEvaluatorOverrideBinaryOps,
);

Object.entries(ExcelExpressionEvaluator.consts).forEach(([key, func]) => {
  delete ExcelExpressionEvaluator.consts[key];
  ExcelExpressionEvaluator.consts[key.toLowerCase()] = func;
});

