import * as React from 'react';
import { NavLink } from 'react-router-dom';

import './internal-header-menu-item.scss';

interface Props {
  link: string;
  name: string;
}

export class InternalHeaderMenuItem extends React.Component<Props> {
  public render(): JSX.Element {
    const { name, link } = this.props;

    return (
      <div className='internal-header-menu-item'>
        <NavLink
          to={link}
          className='internal-header-menu-item__link'
        >
          {name}
        </NavLink>
      </div>
    );
  }
}
