const prefix = '@drawings';

export const DrawingsActionTypes = {
  UPLOAD_PDF: `${prefix}/UPLOAD_PDF`,
  SELECT_DRAWING: `${prefix}/SELECT_DRAWING`,
  DELETE_DRAWING: `${prefix}/DELETE_DRAWING`,
  DELETE_DRAWING_FROM_LOCAL: `${prefix}/DELETE_DRAWING_FROM_LOCAL`,
  DELETE_PAGE: `${prefix}/DELETE_PAGE`,
  PAGE_META_PARAMETER_UPDATE: `${prefix}/PAGE_META_PARAMETER_UPDATE`,
  ADD_FOLDER: `${prefix}/ADD_FOLDER`,
  LOAD_FILE_DATA: `${prefix}/LOAD_FILE_DATA`,
  LOAD_FILE_DATA_SUCCESS: `${prefix}/LOAD_FILE_DATA_SUCCESS`,
  SET_FILES_DATA: `${prefix}/SET_FILES_DATA`,
  LOAD_DRAWINGS_DATA: `${prefix}/LOAD_DRAWINGS_DATA`,
  LOAD_DRAWINGS_DATA_SUCCESS: `${prefix}/LOAD_DRAWINGS_DATA_SUCCESS`,
  EDIT_FILE_TREE_ITEM: `${prefix}/EDIT_FILE_TREE_ITEM`,
  EXPAND_FOLDER: `${prefix}/EXPAND_FOLDER`,
  EXPORT_PDF: `${prefix}/EXPORT_PDF`,
  DROP_STATE: `${prefix}/DROP_STATE`,
  PDF_SCREENSHOTS_GENERATED: `${prefix}/PDF_SCREENSHOTS_GENERATED`,
  SAVE_MEASUREMENTS: `${prefix}/SAVE_MEASUREMENTS`,
  FULL_DATA_LOADED: `${prefix}/FULL_DATA_LOADED`,
  LOAD_DATA_FAILED: `${prefix}/LOAD_DATA_FAILED`,
  SELECT_FILE: `${prefix}/SELECT_FILE`,
  ADD_TABS: `${prefix}/ADD_TABS`,
  REMOVE_TABS: `${prefix}/REMOVE_TABS`,
  SET_TABS: `${prefix}/SET_TABS`,
  MOVE_TAB: `${prefix}/MOVE_TAB`,
  SET_STATUS_DRAWING_RECOGNITION: `${prefix}/SET_STATUS_DRAWING_RECOGNITION`,
  SET_DRAWING_CONVERSION_STATUS: `${prefix}/SET_DRAWING_CONVERSION_STATUS`,
  SAVE_SELECTED_DRAWINGS_STATE: `${prefix}/SAVE_SELECTED_DRAWINGS_STATE`,
  PAGES_RESCALE: `${prefix}/PAGES_RESCALE`,
  UPDATE_SELECTED_PAGES: `${prefix}/UPDATE_SELECTED_PAGES`,
  CLOSE_ALL_FOLDERS: `${prefix}/CLOSE_ALL_FOLDERS`,
  DUPLICATE_PAGE: `${prefix}/DUPLICATE_PAGE`,
  DUPLICATE_PAGE_SUCCESS: `${prefix}/DUPLICATE_PAGE_SUCCESS`,
  SET_OPERATION_IDS: `${prefix}/SET_OPERATION_IDS`,

  SET_PAGE_TO_COMPARE: `${prefix}/SET_PAGE_TO_COMPARE`,
  UPDATE_COMPARE_SETTING: `${prefix}/UPDATE_COMPARE_SETTING`,
  REMOVE_COMPARISON: `${prefix}/REMOVE_COMPARISON`,

  MARK_DRAWING_WITHOUT_SNAPPING: `${prefix}/MARK_DRAWING_WITHOUT_SNAPPING`,
  SET_CURRENT_DRAWING_SNAPPING: `${prefix}/SET_CURRENT_DRAWING_SNAPPING`,
  MARK_DRAWING_WITH_RENDER_PROBLEMS: `${prefix}/MARK_DRAWING_WITH_RENDER_PROBLEMS`,
  TOGGLE_DRAWING_OPTIMIZE: `${prefix}/TOGGLE_DRAWING_OPTIMIZE`,
  TOGGLE_FILE_OPTIMIZE: `${prefix}/TOGGLE_FILE_OPTIMIZE`,
  UPDATE_DRAWINGS_RASTERIZATION: `${prefix}/UPDATE_DRAWINGS_RASTERIZATION`,
  UPDATE_PDF_SPLITTING: `${prefix}/UPDATE_PDF_SPLITTING`,

  PATCH_PAGE_SCALE: `${prefix}/PATCH_PAGE_SCALE`,

  CHANGE_GROUP_PIN_STATUS: `${prefix}/CHANGE_GROUP_PIN_STATUS`,
  UNPIN_GROUPS: `${prefix}/UNPIN_GROUPS`,
  PIN_GROUPS: `${prefix}/PIN_GROUPS`,

  SET_CONTEXT_MENU_POSITION: `${prefix}/SET_CONTEXT_MENU_POSITION`,

  SET_DRAWING_HOVER_STATE: `${prefix}/SET_DRAWING_HOVER_STATE`,
  REMOVE_DRAWING_HOVER_STATE: `${prefix}/REMOVE_DRAWING_HOVER_STATE`,

  SHOW_OPTIMIZE_MESSAGE: `${prefix}/SHOW_OPTIMIZE_MESSAGE`,
  SET_DRAW_MODE: `${prefix}/SET_DRAW_MODE`,
  SET_DRAWING_RENDERED: `${prefix}/SET_DRAWING_RENDERED`,

  SET_CURSOR_HINT: `${prefix}/SET_CURSOR_HINT`,

  TOGGLE_IS_3D: `${prefix}/TOGGLE_IS_3D`,
  SET_FINISH_GEOMETRY_API: `${prefix}/SET_FINISH_GEOMETRY_API`,
};
