export enum Operation {
  /**
   * Is aliased to CRUD operations. I.e., if user can manage smth,
   * they can create, read, update or delete it.
   */
  Manage = 'manage',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  Preview = 'preview',
  Approve = 'approve',
  Revert = 'revert',
  Admin = 'admin',
  Buy = 'buy',
  Duplicate = 'duplicate',
  Export = 'export',
}
