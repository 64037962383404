import { QuantityTakeOffLinearTreeNode } from './quantity-take-off-linear-tree-node';

export interface QuantityTakeOffLinearTreeLeaf extends QuantityTakeOffLinearTreeNode {
  description: string;
  extraPropertiesIds: number[];
  extraPropertiesValues: number[];
  extractorsIds: number[];
  extractedValues: number[];
}

export function isLinearTreeLeaf(
  node: QuantityTakeOffLinearTreeNode | QuantityTakeOffLinearTreeLeaf,
): node is QuantityTakeOffLinearTreeLeaf {
  return (node as QuantityTakeOffLinearTreeLeaf).extractorsIds !== undefined;
}
