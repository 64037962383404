import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { TinyText } from 'common/components/text';

const CONTAINER_WIDTH = 340;

const Container = styled.div`
  width: ${CONTAINER_WIDTH}px;
  max-width: calc(100% - 20px);
  background: ${p => p.theme.color.backgroundRush};
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  animation: ${Constants.Animations.modalAppear}  ${p => p.theme.duration.m} ease-in-out;

  ${TinyText} {
    margin-bottom: 5px;
  }

  > button:last-child {
    width: 100%;
    margin-top: 25px;
  }
`;

export const Styled = {
  Container,
};
