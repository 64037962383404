export class CaretPositionManager {
  private ref: HTMLElement;

  public constructor(parentRef: HTMLElement) {
    this.ref = parentRef;
  }

  public getCurrentCaretState(): { caretIndexInChildren: number, children: HTMLElement[], offset: number } {
    const { childIndex, offset } = this.getTextCaretIndex();
    const children = this.getChildren();
    return { caretIndexInChildren: childIndex, children, offset };
  }

  public setCaretOnChildIndex(childIndex: number): void {
    if (childIndex >= 0) {
      document.getSelection().setPosition(this.ref, childIndex);
    }
  }

  public updateCaretPosition(prevChild: HTMLElement[], prevChildIndex: number, offset: number): void {
    if (offset !== undefined) {
      const index = this.getNewChildIndex(prevChild, prevChildIndex, offset);
      this.setCaretOnChildIndex(index);
    }
  }

  private getNewChildIndex(prevChildren: HTMLElement[], prevFocusChildIndex: number, offset: number): number {
    if (prevFocusChildIndex === 0 && offset === 0) {
      return 0;
    }
    const childrenText = prevChildren.slice(0, prevFocusChildIndex + 1).map(child => child.textContent);
    if (childrenText[prevFocusChildIndex]) {
      childrenText[prevFocusChildIndex] = childrenText[prevFocusChildIndex].slice(0, offset);
    }
    const text = childrenText.join('');
    const newIndex = this.getChildIndexByText(text);
    return newIndex;
  }

  private getTextCaretIndex(): { childIndex: number, offset: number } {
    const selection = document.getSelection();
    const range = selection.getRangeAt(0);

    if (!range.startContainer) {
      return { childIndex: 0, offset: 0 };
    }

    if (range.startContainer === this.ref) {
      return { childIndex: range.startOffset, offset: range.startOffset - selection.focusOffset };
    }

    const childInMainSpan = this.getChildNodeInMainSpan(range.startContainer as any);
    const childIndex = this.getChildIndexInParent(childInMainSpan);
    return { childIndex, offset: range.endOffset };
  }

  private getChildIndexInParent(child: HTMLElement): number {
    const children = this.getChildren();
    return children.indexOf(child);
  }

  private getChildIndexByText(text: string): number {
    let textLength = text.length;
    let childIndex;
    const children = this.getChildren();
    for (let i = 0; i < children.length; i++) {
      const childText = children[i].textContent;
      textLength = textLength - childText.length;
      if (textLength > 0) {
        continue;
      }
      childIndex = i;
      break;
    }

    return childIndex + 1;
  }

  private getChildNodeInMainSpan(element: HTMLElement): HTMLElement {
    return element.parentElement === this.ref
      ? element
      : this.getChildNodeInMainSpan(element.parentElement);
  }

  private getChildren(): HTMLElement[] {
    return Array.from(this.ref.childNodes) as HTMLElement[];
  }
}
