import React from 'react';

export const usePinBlock = (): [boolean, (event: React.UIEvent<HTMLDivElement>) => void] => {
  const [isPinBlock, setIsPinBlock] = React.useState(false);
  const handleScroll = React.useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const {
      currentTarget: { scrollTop },
    } = event;
    setIsPinBlock(scrollTop > 60);
  }, []);

  return [isPinBlock, handleScroll];
};
