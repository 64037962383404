import { ElementTooltip, H6, IconButton, Icons, RoundButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Breadcrumbs } from 'common/components/breadcrumbs';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { ConfirmationDialog } from '../../../../components/dialog/confirmation-dialog';
import { Person } from '../../../people/interfaces/person';
import { ProjectsFolderActions } from '../../../projects/actions/creators/projects-folder';
import { ProjectsFolder } from '../../../projects/interfaces/projects-folder';
import { MOVE_TO_FOLDER_DIALOG_NAME } from '../move-to-folder-dialog';
import { CREATE_FOLDER_DIALOG_NAME } from './create-folder-dialog';
import { FolderListItem } from './folder-list-item';
import { Styled } from './styled';

const HOME_BREADCRUMB = {
  id: 'home',
  name: 'Home',
};

const REMOVE_FOLDER_DIALOG_NAME = 'removeFolderDialogName';

interface StateProps {
  isFoldersSectionCollapsed: boolean;
  folders: ProjectsFolder[];
  removingFolderInfo: string[];
  companyUsers: Person[];
  companyId: number;
}

interface DispatchProps {
  toggleFoldersSection: () => void;
  openDialog: (dialogName: string, data?: any) => void;
  closeDialog: (dialogName: string) => void;
  fetchFolders: () => void;
  updateFolder: (folder: ProjectsFolder) => void;
  removeFolder: (id: number) => void;
  clearDialogInfo: () => void;
}

interface OwnProps {
  isAdmin: boolean;
  currentFolder: ProjectsFolder;
  onFolderClick: (folder: ProjectsFolder) => void;
  searchQuery: string;
}

interface Props extends StateProps, DispatchProps, OwnProps { }

class FolderListComponent extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.fetchFolders();
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.removingFolderInfo.length && !prevProps.removingFolderInfo.length) {
      this.showRemoveDialog();
    }
    if (this.props.companyId !== prevProps.companyId) {
      this.props.fetchFolders();
    }
  }

  public render(): React.ReactNode {
    const { folders, currentFolder, isFoldersSectionCollapsed, searchQuery } = this.props;
    const currentFolderId = currentFolder ? currentFolder.id : undefined;
    const visibleFolders = searchQuery
      ? folders.filter(folder => folder.name.toLowerCase().includes(searchQuery))
      : folders.filter(folder => folder.parentFolderId === currentFolderId);
    const breadcrumbs = this.getBreadcrumbs();

    return (
      <>
        <RenderIf condition={!searchQuery}>
          <Styled.BreadcrumbsWrapper>
            <Breadcrumbs items={breadcrumbs} onClick={this.onBreadcrumbClick} Icon={Icons.Group_2} />
          </Styled.BreadcrumbsWrapper>
        </RenderIf>
        <Styled.Header>
          <RoundButton
            Icon={Icons.PlusSmall}
            size='s'
            onClick={this.openCreateDialog}
            mood={'secondary'}
            iconHeight={10}
            diameter={20}
          />
          <H6>Folders</H6>
          <RenderIf condition={!!folders.length}>
            <ElementTooltip
              text={isFoldersSectionCollapsed ? 'Expand All' : 'Collapse All'}
              position='top'
            >
              <IconButton
                Icon={isFoldersSectionCollapsed ? Icons.ExpandFolders : Icons.CollapseFolders}
                onClick={this.props.toggleFoldersSection}
                iconHeight={20}
                width={20}
                height={20}
              />
            </ElementTooltip>
          </RenderIf>
        </Styled.Header>
        <RenderIf condition={!isFoldersSectionCollapsed}>
          <Styled.Grid>
            {visibleFolders.map(folder => (
              <FolderListItem
                key={folder.id}
                folder={folder}
                onClick={this.props.onFolderClick}
                editFolder={this.openEditDialog}
                deleteFolder={this.props.removeFolder}
                moveFolder={this.openMoveToDialog}
              />
            ))}
          </Styled.Grid>
        </RenderIf>
        {this.renderRemoveFolderDialog()}
      </>
    );
  }

  @autobind
  private openCreateDialog(): void {
    this.props.openDialog(CREATE_FOLDER_DIALOG_NAME, { parentFolder: this.props.currentFolder });
  }

  @autobind
  private openEditDialog(folder: ProjectsFolder): void {
    this.props.openDialog(CREATE_FOLDER_DIALOG_NAME, folder);
  }

  private getBreadcrumbs(): ProjectsFolder[] {
    let firstBreadcrumb = this.props.currentFolder;
    const breadcrumbs = [];
    while (firstBreadcrumb) {
      breadcrumbs.push(firstBreadcrumb);
      firstBreadcrumb = this.props.folders.find(folder => folder.id === firstBreadcrumb.parentFolderId);
    }
    breadcrumbs.push(HOME_BREADCRUMB);
    breadcrumbs.reverse();
    return breadcrumbs;
  }

  @autobind
  private onBreadcrumbClick(breadcrumb: ProjectsFolder): void {
    const folder = breadcrumb.name === HOME_BREADCRUMB.name ? null : breadcrumb;
    this.props.onFolderClick(folder);
  }

  @autobind
  private openMoveToDialog(folder: ProjectsFolder): void {
    this.props.openDialog(
      MOVE_TO_FOLDER_DIALOG_NAME,
      {
        currentFolder: this.props.currentFolder,
        selectedFolder: folder,
        projectId: null,
      },
    );
  }

  @autobind
  private renderRemoveFolderDialog(): React.ReactNode {
    const users = this.props.companyUsers
      .filter(user => this.props.removingFolderInfo.includes(user.id))
      .map(user => user.email);
    const dialogText = `This folder contains projects owned by the following users: ${users.join(',')}`;

    return (
      <ConfirmationDialog
        name={REMOVE_FOLDER_DIALOG_NAME}
        title='Folder cannot be removed'
        text={dialogText}
        cancelButtonText='Okay'
        onCancel={this.closeRemoveDialog}
      />
    );
  }

  @autobind
  private showRemoveDialog(): void {
    this.props.openDialog(REMOVE_FOLDER_DIALOG_NAME);
  }

  @autobind
  private closeRemoveDialog(): void {
    this.props.closeDialog(REMOVE_FOLDER_DIALOG_NAME);
    this.props.clearDialogInfo();
  }
}

const mapStateToProps = (state: State): StateProps => {
  const selectedCompany = state.account.selectedCompany;
  const companyId = selectedCompany ? selectedCompany.id : null;
  return {
    isFoldersSectionCollapsed: state.persistedStorage.isFoldersSectionCollapsed,
    folders: state.projects.folders,
    removingFolderInfo: state.projects.removingFolderInfo,
    companyUsers: state.people.companiesUsers,
    companyId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => {
  return {
    toggleFoldersSection: () => dispatch(PersistedStorageActions.toggleFoldersSection()),
    openDialog: (dialogName, data) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    closeDialog: (dialogName) => dispatch(KreoDialogActions.closeDialog(dialogName)),
    fetchFolders: () => dispatch(ProjectsFolderActions.getFolders()),
    updateFolder: (folder) => dispatch(ProjectsFolderActions.updateFolder(folder)),
    removeFolder: (id) => dispatch(ProjectsFolderActions.removeFolder(id, ownProps.isAdmin)),
    clearDialogInfo: () => dispatch(ProjectsFolderActions.setRemovingFolderInfo([])),
  };
};

export const FolderList = connect(mapStateToProps, mapDispatchToProps)(FolderListComponent);
