import * as monolite from 'monolite';

export class MonoliteHelper<T> {
  private state: T;
  constructor(state: T) {
    this.state = state;
  }

  public set = <U>(
    accessor: (sourceState: T) => U,
    value: U | ((_: U) => U),
  ): MonoliteHelper<T> => {
    this.state = monolite.set(this.state, accessor)(value);
    return this;
  };

  public removeKey = <U>(
    accessor: (sourceState: T) => U,
    key: string | number,
  ): MonoliteHelper<T> => {
    this.state = monolite.set(this.state, accessor)((obj) => {
      const newValue = { ...obj };
      delete newValue[key];
      return newValue;
    });
    return this;
  };

  public setRecordValue = <U>(
    accessor: (sourceState: T) => Record<string, U>,
    getNewValue: (key: string, value: U) => U,
  ): MonoliteHelper<T> => {
    this.state = monolite.set(this.state, accessor)((obj) => {
      const newValue = {};
      for (const [key, value] of Object.entries(obj)) {
        newValue[key] = getNewValue(key, value);
      }

      return newValue;
    });
    return this;
  };

  public setFromStringArr = <U>(
    path: string[],
    value: U,
  ): MonoliteHelper<T> => {
    this.state = monolite.set(this.state, path)(value);
    return this;
  };

  public setMap = <U>(
    accessor: (sourceState: T) => U[],
    updateFunc: (item: U) => U,
  ): MonoliteHelper<T> => {
    this.state = monolite.setMap(this.state, accessor)(updateFunc);
    return this;
  };

  public setFilter = <U>(
    accessor: (sourceState: T) => U[],
    filter: (item: U) => boolean,
  ): MonoliteHelper<T> => {
    this.state = monolite.setFilter(this.state, accessor)(filter);
    return this;
  };

  public setAppend = <U>(
    accessor: (sourceState: T) => U[],
    value: U,
  ): MonoliteHelper<T> => {
    this.state = monolite.setAppend(this.state, accessor)(value);
    return this;
  };

  public setConcat = <U>(
    accessor: (sourceState: T) => U[],
    value: U[],
  ): MonoliteHelper<T> => {
    return this.set(accessor, _ => _.concat(value));
  };

  public setPrepend = <U>(
    accessor: (sourceState: T) => U[],
    value: U,
  ): MonoliteHelper<T> => {
    this.state = monolite.setPrepend(this.state, accessor)(value);
    return this;
  };

  public setSort = <U>(
    accessor: (sourceState: T) => U[],
    comparer: (item1: U, item2: U) => number,
  ): MonoliteHelper<T> => {
    this.state = monolite.set(this.state, accessor)((arr) => {
      return arr.sort(comparer);
    });
    return this;
  };

  public setFind = <U>(
    accessor: (sourceState: T) => U[],
    predicate: (item: U, index: number, obj: U[]) => boolean,
    updateFunc: (item: U) => U,
    suppressError: boolean = false,
  ): MonoliteHelper<T> => {
    this.state = monolite.set(this.state, accessor)((arr) => {
      const index = arr.findIndex(predicate);
      if (index < 0) {
        if (suppressError) {
          return arr;
        }

        throw new Error('Cannot setFind: predicate did not match any item in the array');
      }

      const result = arr.slice();
      result[index] = updateFunc(arr[index]);

      return result;
    });

    return this;
  };

  public get = (): T => {
    return this.state;
  };
}
