import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { GanttChartActions } from '../../actions/creators/gantt-chart';
import { GanttChartActionTypes } from '../../actions/types/gantt-chart';
import { AnimationDataResponse } from '../../interfaces/gantt-chart';
import { Project } from '../../interfaces/project';
import { getCurrentProject } from '../../selectors';
import { FourDVisualisationApi } from './api';

function* getAnimationData(action: ActionWith<number>): SagaIterator {
  try {
    const project: Project = yield selectWrapper(getCurrentProject);
    const data: AnimationDataResponse = yield call(
      FourDVisualisationApi.getAnimationData,
      project.id,
      action.payload,
    );
    yield put(GanttChartActions.getAnimationDataSucceded(data));
  } catch (error) {
    console.error('4d: get animation data failed', error, action.payload);
  }
}

export function* fourDVisualisationSagas(): SagaIterator {
  yield takeLatest(GanttChartActionTypes.ANIMATION_DATA_REQUEST_STARTED, getAnimationData);
}
