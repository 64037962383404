import React from 'react';

import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { DrawingsPointFlyingMenu } from '../drawings-canvas-menus';
import { AiSearchFixMenu } from '../drawings-canvas-menus/ai-search-fix-menu';
import {
  DrawingsAnnotationsFlyingMenu,
} from '../drawings-canvas-menus/drawings-annotations-flying-menu/drawings-annotations-flying-menu';
import { DrawingsDrawMode, MagicSearchDrawModes } from '../enums';
import { useCurrentDrawingInfo, useDrawModeApi } from '../hooks';
import { DrawingContextMenuPosition, FixContour } from '../interfaces';
import { DrawingsContextInstancesMenus } from './drawings-context-instances-menus';
import { useInstancesToOperateWith } from './hooks';

interface Props {
  canViewReport: boolean;
  canToggleFullScreen: boolean;
  selectedAnnotations: string[];
  showAnnotations: boolean;
  showFlyingMenu: boolean;
  isFullScreen: boolean;
  isRendererInitialized: boolean;
  canEditReport: boolean;
  canEditMeasurement: boolean;
  canViewMeasurement: boolean;
  canEditAnnotations: boolean;
  canPasteToSamePlace: () => boolean;
  pasteInSamePlace: () => void;
  onRemoveInstancesHandler: () => void;
  sendFlyingMenuRef: (ref: HTMLDivElement) => void;
  cut: () => void;
  onShowAll: () => void;
  canPaste: () => boolean;
  copy: () => void;
  paste: (coordinates: paper.Point) => void;
  groupDrawings: () => void;
  toggleFullScreen: () => void;
  onContextMenuClose: () => void;
  selectAll: () => void;
  focus: () => void;
  removeAnnotations: (ids: string[]) => void;
  pasteWithPoint: () => void;
  startContinue: () => void;
}


const DrawingsContextMenusComponent: React.FC<Props> = (props) => {
  const {
    showAnnotations,
    canEditAnnotations,
    removeAnnotations,
    selectedAnnotations,
    sendFlyingMenuRef,
    showFlyingMenu,
    canToggleFullScreen,
    ...rest
  } = props;
  const { instancesToOperateWith, hasStrokedInstancesInSelected } = useInstancesToOperateWith();
  const currentDrawing = useCurrentDrawingInfo();
  const contextMenuPosition = useSelector<State, DrawingContextMenuPosition>(
    s => s.drawings.contextMenuPosition,
  );
  const { drawMode } = useDrawModeApi();

  const hasSelectedInstances = instancesToOperateWith.length > 0;
  const fixContourState = useSelector<State, FixContour>(s => s.drawings.magicSearch.fixContour);

  if (contextMenuPosition || (showFlyingMenu && hasSelectedInstances)) {
    return (
      <DrawingsContextInstancesMenus
        {...rest}
        canToggleFullScreen={canToggleFullScreen}
        showFlyingMenu={showFlyingMenu}
        sendFlyingMenuRef={sendFlyingMenuRef}
        hasStrokedInstancesInSelected={hasStrokedInstancesInSelected}
        instancesToOperateWith={instancesToOperateWith}
      />
    );
  } else if (drawMode === DrawingsDrawMode.Modify) {
    return <DrawingsPointFlyingMenu sendRef={sendFlyingMenuRef} />;
  } else if (showAnnotations && canEditAnnotations) {
    return (
      <DrawingsAnnotationsFlyingMenu
        removeAnnotations={removeAnnotations}
        currentDrawingId={currentDrawing?.drawingId}
        selectedAnnotations={selectedAnnotations}
        sendRef={sendFlyingMenuRef}
      />
    );
  } else if (MagicSearchDrawModes.includes(drawMode) && fixContourState) {
    return (
      <AiSearchFixMenu
        fixContourAPI={fixContourState}
        sendRef={sendFlyingMenuRef}
      />
    );
  } else {
    return null;
  }
};

export const DrawingsContextsMenus = DrawingsContextMenusComponent;
