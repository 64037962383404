import { ElementTooltip, Icons, Input, RectangleButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { debounce } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { PageHeaderAvatar } from 'common/components/page-header-avatar';
import { RenderIf } from 'common/components/render-if';
import { METRIC_IDS } from 'common/constants/id-maps';
import { ProjectType } from 'common/constants/project-type';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { NotificationBell } from 'unit-2d-notification/components';
import { COMPANY_INFO_DIALOG } from '../people/components/company-info-wrapper';
import { Styled } from './styled';


interface StateProps {
  searchQuery: string;
  companyId: number;
}

interface DispatchProps {
  openCompanyInfoDialog: () => void;
}

interface OwnProps {
  onSearchChange?: (companyId: number, value: string) => void;
  showSearch: boolean;
  canAddPeople?: boolean;
  searchPlaceholder?: string;
  projectType?: ProjectType;
  searchQueryValue?: string;
  isFolderEnable?: boolean;
}

interface OwnState {
  value: string;
}

interface Props extends StateProps, DispatchProps, OwnProps, AbilityAwareProps { }


export const PROJECTS_PAGE_SIZE = 10;

class ProjectsHeaderComponent extends React.Component<Props, OwnState> {
  private updateSearch: (id: number, value: string) => void;

  constructor(props: Props) {
    super(props);
    if (props.onSearchChange) {
      this.updateSearch = debounce(props.onSearchChange, 1000);
    }
    const isDatabase = props.projectType === ProjectType.Database;
    const value = isDatabase ? props.searchQueryValue : props.searchQuery;
    this.state = { value };
  }

  public render(): React.ReactNode {
    const {
      searchPlaceholder,
      projectType,
      openCompanyInfoDialog,
      showSearch,
      canAddPeople,
      ability,
      isFolderEnable,
    } = this.props;
    const isDatabase = projectType === ProjectType.Database;
    const placeholder = isDatabase
      ? searchPlaceholder
      :  isFolderEnable
        ? `Enter the name of your ${searchPlaceholder} or the name of the folder`
        : `Enter your ${searchPlaceholder}\'s name`;
    const showNotification = ability.can(Operation.Manage, Subject.ShowCommentaries);

    return (
      <Styled.Container showSearch={showSearch}>
        {showSearch &&
          <Styled.HeaderSearch searchQuery={this.state.value}>
            <Input
              type='text'
              input={{
                value: this.state.value,
                onChange: this.onSearchChanged,
              }}
              icon={<Icons.Search />}
              placeholder={placeholder}
            />
          </Styled.HeaderSearch>
        }
        <Styled.HeaderButtons showNotification={showNotification}>
          <RenderIf condition={canAddPeople}>
            <Styled.InviteButton>
              <ElementTooltip
                id={METRIC_IDS.addNewUsers}
                text='Add "Administrator" or "Editor" to the Company'
                position='bottom'
                speed='l'
              >
                <RectangleButton
                  size='m'
                  mood='secondary'
                  ghost={true}
                  onClick={openCompanyInfoDialog}
                  Icon={Icons.AddUser}
                  text='Invite members'
                  borderRadius={10}
                />
              </ElementTooltip>
            </Styled.InviteButton>
          </RenderIf>
          <NotificationBell target='main page' />
          <PageHeaderAvatar size={40} />
        </Styled.HeaderButtons>
      </Styled.Container>
    );
  }

  @autobind
  private onSearchChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const { companyId } = this.props;

    event.preventDefault();
    this.setState({ value: event.target.value });
    if (this.updateSearch) {
      this.updateSearch(companyId, event.target.value);
    }
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const selectedCompany = state.account.selectedCompany;
  const companyId = selectedCompany ? selectedCompany.id : null;

  const headersStore= state.projects.projectHeadersByCompanyId[companyId];
  const projectHeadersStore = headersStore && headersStore[ownProps.projectType];

  const searchQuery = projectHeadersStore ? projectHeadersStore.searchQuery : '';

  return {
    searchQuery,
    companyId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openCompanyInfoDialog: () => dispatch(KreoDialogActions.openDialog(COMPANY_INFO_DIALOG)),
  };
};

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const ProjectsHeader = reduxConnector(withAbilityContext(ProjectsHeaderComponent));
