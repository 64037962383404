import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import * as React from 'react';
import ReactDOM from 'react-dom';

import './multi-level-drop-down.scss';

import { MaterialInput } from 'common/UIKit';
import { getOrCreateRoot } from 'common/UIKit/dialogs';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { PreparedSearchQuery, StringUtils } from 'common/utils/string-utils';
import { MultiLevelSelectConstants } from '../constants';
import { MultilevelSelectOptionData } from '../interfaces/multi-level-select-option-data';
import { MultilevelDropDownContainer } from '../multi-level-select-drop-down-container';
import { MultiLevelSelectOption } from '../multi-level-select-option';
import { MultiLevelSelectUtils } from '../utils';

export enum DropDownPositioning {
  vertical,
  horizontal,
}

interface Props<T extends MultilevelSelectOptionData<T>> {
  options: T[];
  value: React.ReactText | React.ReactText[];
  parentRect: DOMRect | ClientRect;
  displayModeSelect?: boolean;
  containerClassName?: string;
  dropDownPositioning?: DropDownPositioning;
  optionContentRenderer?: (
    option: T,
    query: PreparedSearchQuery,
    isSelected: boolean,
    isHighlighted?: boolean,
    ) => React.ReactNode;
  compare?: (option: T, query: PreparedSearchQuery) => boolean;
  onSelectClick: (value: React.ReactText | React.ReactText[], option: T) => void;
  onClose: (e: React.MouseEvent<HTMLDivElement>) => void;
  onSearchQueryChange?: (searchQuery: string) => void;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  onOptionMouseOver?: (value: React.ReactText) => void;
  onOptionMouseOut?: (value: React.ReactText) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => void;
  highlightedIndex?: number;
  getKey?: (option: T) => string;
}

interface State<T extends MultilevelSelectOptionData<T>> {
  searchQuery: string;
  preparedQuery: PreparedSearchQuery;
  filteredOptions: T[];
}

/**
 * Default horizontal indent
 */
const INDENT_FROM_CONTAINER = 10;

export class MultiLevelDropDown<T extends MultilevelSelectOptionData<T>>
  extends React.PureComponent<Props<T>, State<T>> {

  private readonly scrolledClassNameModifier: string = 'scrolled';

  private dropDownRef: HTMLDivElement;

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      searchQuery: '',
      preparedQuery: null,
      filteredOptions: [],
    };
  }

  public static defaultOptionContentRender<T extends MultilevelSelectOptionData<T>>(
    option: T, _: PreparedSearchQuery, __: boolean,
  ): React.ReactNode {
    return option.name;
  }

  public componentDidUpdate(prevProps: Props<T>, prevState: State<T>): void {
    if (prevState.searchQuery !== this.state.searchQuery && this.props.onSearchQueryChange) {
      this.props.onSearchQueryChange(this.state.searchQuery);
    }

    if (!isEqual(prevProps.options, this.props.options)) {
      this.setFilteredOptionsAndSearchQuery(this.state.searchQuery);
    }
  }

  public render(): React.ReactNode {
    return ReactDOM.createPortal(
      this.renderContent(),
      getOrCreateRoot(),
    );
  }

  public componentDidMount(): void {
    if (this.props.dropDownPositioning === DropDownPositioning.horizontal) {
      this.horizontalPositioning();
    } else {
      this.verticalPositioning();
    }
  }

  private verticalPositioning(): void {
    const { parentRect } = this.props;
    const isTop = this.setMaxHeight();
    const left = parentRect.left + parentRect.width / 2;
    this.dropDownRef.style.left = `${left}px`;
    if (isTop) {
      this.dropDownRef.style.bottom = `${window.innerHeight - parentRect.top}px`;
    } else {
      this.dropDownRef.style.top = `${parentRect.bottom}px`;
    }
  }

  private horizontalPositioning(): void {
    const { parentRect } = this.props;
    const bottomOfParentElement = parentRect.top + 46;
    const bottom = window.innerHeight - bottomOfParentElement;
    const isTop = parentRect.top > bottom;
    const maxHeight = (isTop ? parentRect.top : bottom) - MultiLevelSelectConstants.margin;
    this.dropDownRef.style.maxHeight = `${maxHeight}px`;
    const left = parentRect.right - INDENT_FROM_CONTAINER;
    this.dropDownRef.style.left = `${left}px`;
    if (isTop) {
      this.dropDownRef.style.bottom = `${window.innerHeight - bottomOfParentElement}px`;
    } else {
      this.dropDownRef.style.top = `${parentRect.top}px`;
    }
  }

  private setMaxHeight(): boolean {
    const { parentRect } = this.props;
    const bottom = window.innerHeight - parentRect.bottom;
    const isTop = parentRect.top > bottom;
    const maxHeight = window.innerHeight - MultiLevelSelectConstants.margin;
    this.dropDownRef.style.maxHeight = `${maxHeight}px`;
    return isTop;
  }

  private renderContent(): React.ReactNode {
    return (
      <React.Fragment>
        <MultilevelDropDownContainer
          onClick={this.onDropDownClick}
          getDropDownContainerRef={this.saveDropDownRef}
          className={classNames(this.props.containerClassName, 'multi-level-drop-down')}
          displayModeSelect={this.props.displayModeSelect}
          onMouseDown={this.props.onMouseDown}
        >
          {
            this.renderSearchField()
          }
          <div
            className='multi-level-drop-down__variants-container'
            onScroll={this.onScrollVariants}
          >
            {this.renderOptions()}
          </div>
        </MultilevelDropDownContainer>
        <div className='multi-level-drop-down__blanket' onClick={this.props.onClose} />
      </React.Fragment>
    );
  }

  private renderSearchField(): React.ReactNode {
    if (this.props.compare || this.props.onSearchQueryChange) {
      return (
        <div className='multi-level-drop-down__search-field'>
          <MaterialInput
            searchType={true}
            placeholder='Search'
            sizeSmall={true}
            displayedType={MaterialComponentType.Native}
            onChange={this.onChangeSearchQuery}
            value={this.state.searchQuery}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  private renderOptions(): React.ReactNode {
    const { searchQuery, filteredOptions } = this.state;
    const options = searchQuery ? filteredOptions : this.props.options;
    return options.map(this.renderOption);
  }

  private onDropDownClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }

  @autobind
  private onScrollVariants(event: React.UIEvent<HTMLDivElement>): void {
    const { currentTarget: { classList, scrollTop } } = event;
    if (this.props.onScroll) {
      this.props.onScroll(event);
    }
    const scrolled = classList.contains(this.scrolledClassNameModifier);
    const needToggle = scrollTop > 0 ? !scrolled : scrolled;
    if (needToggle) {
      classList.toggle(this.scrolledClassNameModifier);
    }
  }

  @autobind
  private saveDropDownRef(ref: HTMLDivElement): void {
    this.dropDownRef = ref;
  }

  @autobind
  private renderOption(option: T, index: number): React.ReactNode {
    const { optionContentRenderer, value, onSelectClick, getKey } = this.props;
    const selected = this.isSelected(option.value, value);
    const key = (getKey && getKey(option)) || option.name || index;
    return (
      <MultiLevelSelectOption<T>
        onSelectClick={onSelectClick}
        selectedValue={value}
        optionContentRenderer={optionContentRenderer ? this.renderOptionFactory : undefined}
        key={key}
        subvariants={option.children}
        value={option.value}
        isSelected={selected}
        isSelectable={option.isSelectable}
        onOptionMouseOut={this.props.onOptionMouseOut}
        onOptionMouseOver={this.props.onOptionMouseOver}
        isHighlighted={index === this.props.highlightedIndex}
        tooltip={option.name}
        optionData={option}
      >
        {
          optionContentRenderer
            ? optionContentRenderer(option, this.state.preparedQuery, selected)
            : MultiLevelDropDown.defaultOptionContentRender(option, this.state.preparedQuery, selected)
        }
      </MultiLevelSelectOption>
    );
  }

  private isSelected(optionValue: React.ReactText, selectedValue: React.ReactText | React.ReactText[]): boolean {
    if (Array.isArray(selectedValue)) {
      return selectedValue.includes(optionValue);
    } else {
      return optionValue === selectedValue;
    }
  }

  @autobind
  private renderOptionFactory(option: T, isSelected: boolean, isHighlighted?: boolean): React.ReactNode {
    return this.props.optionContentRenderer(option, this.state.preparedQuery, isSelected, isHighlighted);
  }

  @autobind
  private onChangeSearchQuery(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    this.setFilteredOptionsAndSearchQuery(value);
  }

  @autobind
  private setFilteredOptionsAndSearchQuery(searchQuery: string): void {
    const { options, compare } = this.props;
    if (!searchQuery) {
      this.setState({ searchQuery: null, filteredOptions: options, preparedQuery: null });
      return;
    }

    if (compare) {
      const filteredOptions = new Array<T>();
      const query = StringUtils.createSearchByWordRegex(searchQuery);
      for (const matchedOption of MultiLevelSelectUtils.recursiveFilter(options, option => compare(option, query))) {
        filteredOptions.push(matchedOption);
      }

      this.setState({ searchQuery, filteredOptions, preparedQuery: query });
    } else {
      this.setState({ searchQuery, filteredOptions: options, preparedQuery: null });
    }
  }
}
