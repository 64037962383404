import React from 'react';

import { useSelector } from 'react-redux';
import { LoadersList, ProgressState } from 'common/components/progress';
import { State } from 'common/interfaces/state';
import { ProgressUtils } from 'common/utils/progress-utils';
import { Styled } from './styled';


interface Props {
  isInitialized: boolean;
}

const ReportDataProgressComponent: React.FC<Props> = ({ isInitialized }) => {
  const reportProgress = useSelector<State, ProgressState>(s => s.progress[ProgressUtils.REPORT_DATA_PROGRESS_KEY]);
  const drawingProgress = useSelector<State, ProgressState>(s => s.progress[ProgressUtils.DRAWING_PROGRESS_KEY]);
  const reportHasBar = !!reportProgress?.progressBars?.length;
  const drawingHasBar = !!drawingProgress?.progressBars?.length;
  return (
    <Styled.Background isInitialized={isInitialized} hasBar={reportHasBar || drawingHasBar}>
      <Styled.Container hasBar={reportHasBar || drawingHasBar}>
        <LoadersList progressKey={ProgressUtils.DRAWING_PROGRESS_KEY} />
        <LoadersList progressKey={ProgressUtils.REPORT_DATA_PROGRESS_KEY} />
      </Styled.Container>
    </Styled.Background>
  );
};

export const ReportDataProgress = React.memo(ReportDataProgressComponent);
