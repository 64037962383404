import autobind from 'autobind-decorator';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import './project-tile.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { AuthImage } from 'common/components/auth-image';
import { LinkContainer } from 'common/components/link-container';
import { SvgSpinner } from 'common/components/svg-spinner';
import { ControlNames } from 'common/constants/control-names';
import { ProjectType } from 'common/constants/project-type';
import { ProjectCreateStatus } from 'common/enums/project-create-status';
import { ProjectStatus } from 'common/enums/project-status';
import { State } from 'common/interfaces/state';
import { RoutingContextProps, withRoutingContext } from 'common/routing/with-routing-context';
import { AlertAttention, IconButton, KreoButton, KreoIconDelBasket } from 'common/UIKit';
import { BaseTile } from '../../../../components/tiles';
import { ProjectsApi } from '../../api/common';
import { KnownViewModel } from '../../enums/known-view-model';
import { ViewModelStatus } from '../../enums/view-model-status';
import { CompanyProjectHeader } from '../../interfaces/company-project-header';
import { ViewModelStatusPair } from '../../interfaces/view-model-status-pair';
import { ProjectTileProgress } from './project-tile-progress';

interface TileOwnProps {
  projectHeader: CompanyProjectHeader;
  onRemoveConfirm: (id: number, createStatus: ProjectCreateStatus) => void;
}

interface TileStateProps {
  currentUserId: string;
}

interface TileProps extends TileOwnProps, TileStateProps, AbilityAwareProps, RoutingContextProps { }

class ProjectTileComponent extends React.Component<TileProps> {
  private isLoadingStatus: boolean = false;

  public render(): React.ReactNode {
    const { projectHeader } = this.props;

    const {
      viewModelStatuses: vmStatuses,
    } = projectHeader;

    const projectStatus = vmStatuses
      && vmStatuses.find((vmStatus) => vmStatus.viewModelType === KnownViewModel.ProjectCreating);
    const isFailedStatus = projectStatus && projectStatus.status === ViewModelStatus.Failed;
    this.isLoadingStatus = !isFailedStatus && projectStatus && projectStatus.status !== ViewModelStatus.Ready;

    const url = this.getLink(projectStatus);

    const tileClassName = classNames('project-tile', {
      'project-tile--loading': this.isLoadingStatus,
      'project-tile--failed': isFailedStatus,
    });

    return (
      <LinkContainer
        controlName={ControlNames.projectTileLink}
        to={url}
        disabled={!url || this.isLoadingStatus || isFailedStatus}
      >
        <BaseTile
          component={this.getContent()}
          className={tileClassName}
          controlName={ControlNames.projectTile}
        >
          <div className='project-tile__body'>
            <div className='project-tile__content' data-control-name={ControlNames.projectTileContent}>
              {
                isFailedStatus
                  ? this.getFailedContent()
                  : this.getTileScreenshotContent(projectHeader)
              }
            </div>
            <div className='project-tile__title-wrap'>
              <div className='project-tile__title' title={projectHeader.name}>
                {projectHeader.name}
              </div>
            </div>
            {projectHeader.status === ProjectStatus.OnBidPricing ? (
              <div className='project-tile__bid-pricing-signal'>Bid Pricing</div>
            ) : null}
          </div>
        </BaseTile>
      </LinkContainer>
    );
  }

  private getTileScreenshotContent(projectHeader: CompanyProjectHeader): React.ReactNode {
    return (
      <>
        {
          projectHeader.id && <AuthImage src={ProjectsApi.buildProjectScreenshotUrl(projectHeader.id)} />
        }
        {
          projectHeader.viewModelStatuses &&
            <ProjectTileProgress viewModelStatuses={projectHeader.viewModelStatuses}/>
        }
      </>
    );
  }

  private getContent(): React.ReactNode {
    return (
      <>
        {this.props.ability.can(Operation.Delete, Subject.Project)
          ? (
              <IconButton
                className='project-tile__delete-btn'
                size='medium'
                onClick={this.delete}
                controlName={ControlNames.projectTileDeleteButton}
              >
                <KreoIconDelBasket />
              </IconButton>
          ) : null
        }
        {this.isLoadingStatus && <div className='project-tile__spinner'><SvgSpinner size='middle' /></div>}
      </>
    );
  }

  private getFailedContent(): React.ReactNode {
    const projectFailureNotified = this.props.projectHeader.projectFailureNotified;
    return (
      <>
        <IconButton
          className='project-tile__delete-btn'
          size='medium'
          onClick={this.delete}
        >
          <KreoIconDelBasket />
        </IconButton>
        <i className='project-tile__icon-attention'>
          <AlertAttention />
        </i>
        <div className='project-tile__failed-description'>
          Calculation error
        </div>
        {projectFailureNotified
          ? <div className='project-tile__notification-sended'>We will contact you soon</div>
          : (
            <KreoButton
              className='project-tile__failed-btn'
              mode='toaction-white'
              size='small'
              rounded={true}
              caption='Send the notification to us'
              onClick={this.sendFailedNotification}
            />
          )
        }
      </>
    );
  }

  @autobind
  private getLink(projectStatus: ViewModelStatusPair): string {
    const { projectHeader, ability, urls } = this.props;
    const { viewModelStatuses, id } = projectHeader;

    if (projectHeader.type === ProjectType.Project2d) {
      return urls.project.index.url({ projectId: id.toString() });
    }

    if (projectHeader.hasNoSourceFiles
        || viewModelStatuses && viewModelStatuses.find(
          x => x.status !== ViewModelStatus.Ready && projectStatus.viewModelType === KnownViewModel.BimProcessing,
        )
    ) {
      return urls.project.modelManagement.url({ projectId: id.toString() });
    }

    if (ability.can(Operation.Read, Subject.CostEstimate)) {
      // TODO select CE submodule based on:
      // 1. currentRevision.step.validationStep
      // 2. hasUnstartedProducts
      return urls.project.informationModeling.index.url({ projectId: id.toString() });
    }

    if (ability.can(Operation.Read, Subject.PlanFeatures)) {
      return urls.project.viewer.url({ projectId: id.toString() });
    }

    if (ability.can(Operation.Read, Subject.Cost)) {
      // is usefull for Guests in CostEstimate
      return urls.project.publishedReports.url({ projectId: id.toString() });
    }

    return urls.project.quantityTakeOff.url({ projectId: id.toString() });
  }

  @autobind
  private sendFailedNotification(): void {
    this.props.onRemoveConfirm(this.props.projectHeader.id, ProjectCreateStatus.Failed);
  }

  @autobind
  private delete(e: React.MouseEvent<HTMLDivElement>): void {
    e.preventDefault();
    const createStatus = this.isLoadingStatus ? ProjectCreateStatus.Loading : ProjectCreateStatus.Created;
    this.props.onRemoveConfirm(this.props.projectHeader.id, createStatus);
  }
}

function mapStateToProps(state: State): TileStateProps {
  return {
    currentUserId: state.account.id,
  };
}

const connector = connect(mapStateToProps);
export const ProjectTile = connector(withRoutingContext(withAbilityContext(ProjectTileComponent)));
