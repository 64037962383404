export enum DrawingNeededValueType {
  Count = 'count',
  Length = 'length',
  Height = 'height',
  Width = 'width',
  Thickness = 'thickness',
  Perimeter = 'perimeter',
  Area = 'area',
  VerticalArea = 'verticalArea',
  Volume = 'volume',
  Diameter = 'diameter',
  Radius = 'radius',
  Angle = 'angle',
  PointsCount = 'Points Count',
  PointsCountFr = 'pointsCount',
  SegmentsCount = 'Segments Count',
  SegmentsCountFr = 'segmentsCount',
  SegmentLength = 'segmentLength',
  Name = 'name',
  DrawingName = 'drawingName',
  GroupName = 'Group Name',
  GroupNameFr = 'groupName',
  DocumentName = 'documentName',
  Property = 'property',
  Table = 'table',
  DynamicTable = 'dynamicTable',
  UnKnown = 'undefined',
}
