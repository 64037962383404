import * as t from 'io-ts';

import { IsoDateStringC } from 'common/codecs/iso-date-string';

export const BidPricingDataC = t.strict(
  {  calculationId: t.number,
    description: t.string,
    endDate: IsoDateStringC,
    id: t.number,
    isPrivate: t.boolean,
    startDate: IsoDateStringC,
  },
  'BidPricingData');

export type BidPricingData = t.TypeOf<typeof BidPricingDataC>;
