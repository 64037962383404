import autobind from 'autobind-decorator';
import uuid from 'uuid';

import { ConnectionChecker } from './connection-checker';
import { MultiViewData } from './multi-view-data';
import { INNER_COMMANDS, MultiVieHelperModule } from '.';

export class MultiViewHelperParent extends ConnectionChecker implements MultiVieHelperModule {
  public readonly isParentMode: boolean = true;

  private readonly data: MultiViewData;
  private resetInitState: () => void;
  private onOpenChild: () => void;
  private sendInnerCommand: (value: any) => void;

  private childWindow: Window = null;

  public constructor(
    data: MultiViewData,
    sendInnerCommand: (value: any) => void,
    resetInitState?: () => void,
    onOpenChild?: () => void,
  ) {
    super();
    this.resetInitState = resetInitState;
    this.onOpenChild = onOpenChild;
    this.data = data;

    this.handleOnInit();
    this.sendInnerCommand = sendInnerCommand;
  }

  @autobind
  public handleOnDestroy(): void {
    if (this.childWindow) {
      this.childWindow.close();
    }
    if (this.resetInitState) {
      this.resetInitState();
    }
    this.restChildRef();
    this.data.channel.closeChannel();
  }

  public openChild(url: string): void {
    const secondViewId = uuid.v4();
    this.childWindow = window.open(
      `${url}?parentId=${this.data.id}&currentId=${secondViewId}`,
      this.getChildWindowTarget(),
      'channelmode=1',
    );
    this.data.secondViewId = secondViewId;
    this.handleOnOpenChild();
  }

  public closeSecondWindow(): void {
    if (this.childWindow) {
      this.childWindow.close();
    }
  }

  @autobind
  public handleOnDestroyPartner(): void {
    if (this.resetInitState) {
      this.resetInitState();
    }
    this.restChildRef();
  }

  @autobind
  public handleOnLoaded(): void {
    this.startLifeChildLifeCheck();
  }

  @autobind
  public handleIsAlive(): void {
    this.startLifeChildLifeCheck();
    this.handleLifeConnection();
  }

  protected restChildRef(): void {
    this.childWindow = null;
    this.data.secondViewId = null;
  }

  protected handleOnInit(): void {
    window.addEventListener('beforeunload', this.handleOnDestroy);
  }

  private startLifeChildLifeCheck(): void {
    this.sendInnerCommand({ type: INNER_COMMANDS.IS_ALIVE });
  }

  private handleOnOpenChild(): void {
    if (this.onOpenChild) {
      this.onOpenChild();
    }
  }

  private getChildWindowTarget(): string {
    return `child_of_${this.data.id}`;
  }
}
