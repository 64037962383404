import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 5px;
  height: 20px;
`;

const Star = styled.div<{ highlight: boolean }>`
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    stroke-width: 1.5;
    fill: ${p => p.highlight ? p.theme.color.turquoise : p.theme.color.background};
    stroke: ${p => p.theme.color.turquoise};
    transition: all 0.25s ease;
  }

  &:hover {
    svg {
      fill: ${p => p.theme.color.turquoise};
      transform: scale(1.25);
    }
  }

  &:active {
    svg {
      transform: scale(0.9);
    }
  }
`;

export const Styled = {
  Container,
  Star,
};

