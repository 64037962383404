import * as React from 'react';

import { IconButton, KreoIconAction, MaterialIconMenu, MaterialMenuItem } from 'common/UIKit';
import { ButtonSize } from 'common/UIKit/buttons/icon-button';

export interface ItemMenuType {
  name: string;
  action: (data?: any) => void;
  disabled?: boolean;
}

interface Props {
  menu: ItemMenuType[];
  className?: string;
  buttonSize?: ButtonSize;
  handleClick?: (e: any) => void;
  title?: string;
  icon?: React.ReactNode;
}

export const ItemMenu: React.FC<Props> = (props) => {
  const { menu, className, buttonSize, handleClick, title, icon } = props;
  return (
    <div className={className || ''} title={title}>
      <MaterialIconMenu
        onClick={handleClick}
        iconButtonElement={
          <IconButton size={buttonSize}>
            {icon ? icon : <KreoIconAction />}
          </IconButton>
        }
      >
        {menu &&
          menu.map((x, i) => (
            <MaterialMenuItem
              key={i}
              onClick={x.action}
              disabled={x.disabled}
            >
              {x.name}
            </MaterialMenuItem>
          ))}
      </MaterialIconMenu>
    </div>
  );
};
