import { H5, Icons, RectangleButton, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { SubscriptionPlanVariantWithName } from '../../../interfaces/subscription-plan-variant-with-name';

import { Styled } from './styled';

interface Props {
  variant: SubscriptionPlanVariantWithName;
}

export class SubscriptionCustomOfferCard extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <Styled.Container>
        <H5>{this.props.variant.name}</H5>
        <Styled.Contact>
          <Icons.Chat2D />
          <Text fontSize={12} color='green'>Contact sales</Text>
        </Styled.Contact>
        <a
          href={this.props.variant.buttonLink}
          target='_blank'
        >
          <RectangleButton
            size='m'
            mood={'positive'}
            text={this.props.variant.buttonCaption}
          />
        </a>
        {this.props.variant.description && <Styled.Description>{this.props.variant.description}</Styled.Description>}
      </Styled.Container>
    );
  }
}
