import { TimelineCellData } from './timeline-cell-data';
import { TimelineScale } from './timeline-scale';

const quarters = [1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 4, 4];
const quarterStartMonths = [undefined, 0, 3, 6, 9];
const quarterEndMonths = [undefined, 3, 6, 9, 12];
const dayMs = 24 * 60 * 60 * 1000;

const getQuarterStartMonth = (quarter: number): number => quarterStartMonths[quarter];
const getQuarterEndMonth = (quarter: number): number => quarterEndMonths[quarter];
export const getQuarter = (month: number): number => quarters[month];

const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(date.getDate() + days);
  return result;
};

export const getWeekStartDate = (date: Date): Date => {
  const weekStartDay = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  const clonedDate = new Date(date.getTime());
  clonedDate.setDate(weekStartDay);
  clonedDate.setHours(0, 0, 0, 0);

  return clonedDate;
};

const wrapEnds = (cells: TimelineCellData[], from: Date, to: Date): TimelineCellData[] => {
  cells[0].duration = cells[0].duration - ((from.getTime() - cells[0].start.getTime()) / dayMs);
  cells[0].start = from;

  cells[cells.length - 1].duration = (to.getTime() - cells[cells.length - 1].start.getTime()) / dayMs;
  return cells;
};

/*
 * todo:
 * consider caching of calculated cells by scale and timeframes
 * for example, weeks were calculated from 2017-01-01 to 2018-01-01
 * there is no reason to recalculate them once again
 */

const buildYears = (from: Date, to: Date): TimelineCellData[] => {
  const startYear = from.getFullYear();
  const endYear = to.getFullYear();
  const cells: TimelineCellData[] = [];
  for (let year = startYear; year <= endYear; year++) {
    const startDate = new Date(year, 0, 1);
    const startTime = startDate.getTime();
    cells.push({
      id: `year-${year}`,
      start: startDate,
      duration: (new Date(year + 1, 0, 1).getTime() - startTime) / dayMs,
    });
  }

  return wrapEnds(cells, from, to);
};

const buildQuarters = (from: Date, to: Date): TimelineCellData[] => {
  const startYear = from.getFullYear();
  const startMonth = from.getMonth();
  const startQuarter = getQuarter(startMonth);

  const cells: TimelineCellData[] = [];
  let quarter = startQuarter;
  let year = startYear;
  let quarterEndDate: Date = null;
  do {
    quarterEndDate = new Date(year, getQuarterEndMonth(quarter), 0);
    const startDate = new Date(year, getQuarterStartMonth(quarter), 1);
    const startTime = startDate.getTime();

    cells.push({
      id: `quarter-${quarter}-${year}`,
      start: startDate,
      duration: (quarterEndDate.getTime() - startTime) / dayMs,
    });

    year = quarter < 4 ? year : year + 1;
    quarter = quarter < 4 ? quarter + 1 : 1;
  } while (quarterEndDate < to);

  return wrapEnds(cells, from, to);
};

const buildMonths = (from: Date, to: Date): TimelineCellData[] => {
  const startYear = from.getFullYear();
  const startMonth = from.getMonth();

  const cells: TimelineCellData[] = [];
  let month = startMonth;
  let year = startYear;
  let monthEndDate: Date = null;
  do {
    monthEndDate = new Date(year, month + 1, 1);
    const startDate = new Date(year, month, 1);
    const startTime = startDate.getTime();

    cells.push({
      id: `month-${month}-${year}`,
      start: startDate,
      duration: (monthEndDate.getTime() - startTime) / dayMs,
    });

    year = month < 11 ? year : year + 1;
    month = month < 11 ? month + 1 : 0;
  } while (monthEndDate < to);

  return wrapEnds(cells, from, to);
};

// todo: remove objects creation in the loop, mutate existing objects instead, see buildHours
// todo: replace duration calculation with const
const buildWeeks = (from: Date, to: Date): TimelineCellData[] => {
  const cells: TimelineCellData[] = [];
  let weekStartDate = getWeekStartDate(from);
  let weekEndDate = null;
  do {
    weekEndDate = addDays(weekStartDate, 7);

    cells.push({
      id: `week-${weekStartDate.toDateString()}`,
      start: new Date(weekStartDate),
      duration: 7,
    });

    weekStartDate = weekEndDate;
  } while (weekEndDate < to);

  return wrapEnds(cells, from, to);
};

// todo: replace duration calculation with const
const buildDays = (from: Date, to: Date): TimelineCellData[] => {
  const cells: TimelineCellData[] = [];
  let dayStartDate = new Date(from.getFullYear(), from.getMonth(), from.getDate());
  let dayEndDate: Date = null;
  do {
    dayEndDate = addDays(dayStartDate, 1);

    cells.push({
      id: `day-${dayStartDate.toDateString()}`,
      start: new Date(dayStartDate),
      duration: 1,
    });

    dayStartDate = dayEndDate;
  } while (dayEndDate < to);

  return wrapEnds(cells, from, to);
};

// todo: replace duration calculation with const
const buildHours = (from: Date, to: Date): TimelineCellData[] => {
  const cells: TimelineCellData[] = [];
  const hourStartDate = new Date(from);
  hourStartDate.setMinutes(0, 0, 0);
  const hourEndDate = new Date(hourStartDate);
  do {
    hourEndDate.setHours(hourEndDate.getHours() + 1);

    cells.push({
      id: `hour-${hourStartDate.getTime()}`,
      start: new Date(hourStartDate),
      duration: (hourEndDate.getTime() - hourStartDate.getTime()) / dayMs,
    });

    hourStartDate.setHours(hourStartDate.getHours() + 1);
  } while (hourEndDate < to);

  return wrapEnds(cells, from, to);
};

interface TimelineDataProvider {
  [unit: number]: (from: Date, to: Date) => TimelineCellData[];
}

export const timelineDataProvider: TimelineDataProvider = {
  [TimelineScale.Year]: buildYears,
  [TimelineScale.Quarter]: buildQuarters,
  [TimelineScale.Month]: buildMonths,
  [TimelineScale.Week]: buildWeeks,
  [TimelineScale.Day]: buildDays,
  [TimelineScale.Hour]: buildHours,
};
