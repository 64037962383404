import { ProjectResourcesApi } from '../../../../api/server';
import { PdfRect } from '../interfaces/api-payloads';
import { PdfPageFilterResponse } from '../interfaces/api-responses/pdf-filter';

function hideBacground(
  fileId: string,
  pageId: string,
): Promise<PdfPageFilterResponse> {
  return ProjectResourcesApi.post(`/drawings/files/${fileId}/pages/${pageId}/filter/hide-background`);
}

function switchFilter(
  fileId: string,
  pageId: string,
  value: boolean,
): Promise<PdfPageFilterResponse> {
  return ProjectResourcesApi.post(`/drawings/files/${fileId}/pages/${pageId}/filter/switch`, { value });
}

function createEmptyFilter(
  fileId: string,
  pageId: string,
): Promise<PdfPageFilterResponse> {
  return ProjectResourcesApi.post(`/drawings/files/${fileId}/pages/${pageId}/filter/empty`);
}

function addToFilter(
  fileId: string,
  pageId: string,
  rect: PdfRect,
): Promise<PdfPageFilterResponse> {
  return ProjectResourcesApi.patch(`/drawings/files/${fileId}/pages/${pageId}/filter`, rect);
}

export const DrawingsFilterApi = {
  hideBacground,
  switchFilter,
  createEmptyFilter,
  addToFilter,
};

