import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import React from 'react';

import './permission-table.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { PermissionActivity } from 'common/enums/permission-activity';
import { RequestStatus } from 'common/enums/request-status';
import { UserPermission } from 'common/enums/user-permission';
import { KreoButton, KreoScrollbars } from 'common/UIKit';
import { UpdateRolePermissions } from '../../../../units/account/interfaces/update-role-permissions';
import { StringUtil } from '../../../../utils/string';
import { ExtendedPermission } from '../../interfaces/extended-permission';
import { MappedRolesPermissions } from '../../interfaces/mapped-roles-permissions';
import { PermissionActivityAccess } from '../../interfaces/permission-activity-access';
import { PermissionTableCell } from '../permission-table-cell';

interface Props {
  companyId: number;
  updateRolesPermissionStatus: RequestStatus;
  updateRolesStatus: RequestStatus;
  rolesPermissions: MappedRolesPermissions;
  updateCompanyPermissionRoles(roles: UpdateRolePermissions[]): void;
}

interface State {
  updatedPermissions: Record<number, Record<number, ExtendedPermission>>;
  companyId: number;
}

export class PermissionTable extends React.Component<Props, State> {
  public state: State = {
    updatedPermissions: null,
    companyId: this.props.companyId,
  };

  public static getDerivedStateFromProps(props: Props, state: State): Partial<State> {
    if (!state.companyId || props.companyId !== state.companyId) {
      return {
        companyId: props.companyId,
        updatedPermissions: null,
      };
    } else if (state.companyId && props.updateRolesStatus === RequestStatus.Loading) {
      return {
        updatedPermissions: null,
      };
    }

    return null;
  }

  public render(): React.ReactNode {
    const { updateRolesPermissionStatus, rolesPermissions } = this.props;
    const { updatedPermissions, companyId } = this.state;

    if (!companyId || !rolesPermissions) {
      return null;
    }
    const { roleGroups, activities, roles } = rolesPermissions;
    const permissions = updatedPermissions ? updatedPermissions : rolesPermissions.permissions;

    return (
      <div className='permission-page-table'>
        <div className='permission-page-table__header'>
          <table className='permission-page-table__header-table'>
            <thead className='permission-page-table__header-head'>
              <tr className='permission-page-table__header-tr'>
                {Object.keys(roleGroups).map(key => {
                  const colspan = roles.filter(role => role.group === StringUtil.capitalize(key)).length;
                  return (
                    <th
                      className='permission-page-table__header-th'
                      key={key}
                      colSpan={colspan}
                      title={roleGroups[key]}
                    >
                      {roleGroups[key]}
                    </th>
                  );
                })}
              </tr>
              <tr className='permission-page-table__header-tr'>
                {roles.map(x => (
                  <th key={x.id} title={x.name} className='permission-page-table__header-th'>
                    {x.name}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
        </div>
        <div
          className={classNames('permission-page-table__main', {
            'permission-page-table__main--save-menu': !!updatedPermissions,
          })}
        >
          <KreoScrollbars relative={true}>
            <table className='permission-page-table__main-table'>
              <tbody className='permission-page-table__main-body'>
                {activities.map(act => (
                  <tr key={act.activity} className='permission-page-table__main-tr'>
                    <td title={act.name} className='permission-page-table__main-td'>
                      {act.name}
                    </td>
                    {roles.map(r => (
                      <PermissionTableCell
                        key={r.id}
                        roleId={r.id}
                        activity={act.activity}
                        permissions={permissions}
                        activities={activities}
                        onChange={this.changePermission}
                      />
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </KreoScrollbars>
        </div>
        {updatedPermissions && (
          <div className='permission-page-table__save-wrap'>
            <div className='permission-page-table__save'>
            <KreoButton size='large' caption='Cancel' onClick={this.resetChanges} mode='ghost' />
              <KreoButton size='large' caption={'Save'} onClick={this.savePermissions} mode='submit' />
            </div>
          </div>
        )}
        {updateRolesPermissionStatus === RequestStatus.Loading && (
            <div className='permission-page-table__spinner'>
              <SvgSpinner size='large' />
            </div>
        )}
      </div>
    );
  }

  @autobind
  private changePermission(
    access: PermissionActivityAccess,
    activityCode: PermissionActivity,
    roleId: number,
  ): void {
    let updatedPermissions = this.state.updatedPermissions;
    const { rolesPermissions } = this.props;
    if (!updatedPermissions) {
      updatedPermissions = cloneDeep(rolesPermissions.permissions);
    }

    if (updatedPermissions) {
      const permission = updatedPermissions[roleId][activityCode];
      permission.activityAccess = access;
      if (access !== PermissionActivityAccess.NoAccess) {
        permission.relatedActivitiesAccess.forEach(x => {
          if (
            updatedPermissions[roleId][x.activity].activityAccess ===
            PermissionActivityAccess.NoAccess
          ) {
            updatedPermissions[roleId][x.activity].activityAccess = x.access;
          }
        });
      }
    }

    this.setState({ updatedPermissions });
  }

  @autobind
  private resetChanges(): void {
    this.setState({ updatedPermissions: null });
  }

  @autobind
  private savePermissions(): void {
    const { updatedPermissions, companyId } = this.state;
    const { rolesPermissions: { roles, activities } } = this.props;

    if (companyId && updatedPermissions) {
      const rolePermissions = roles.map(r => {
        const permissions = new Set<UserPermission>();
        activities.map(act => {
          const permission = updatedPermissions[r.id][act.activity];
          permission.availableAccess[permission.activityAccess].forEach(x => permissions.add(x));
        });

        return {
          roleId: r.id,
          permissions: [...permissions],
        };
      });

      this.props.updateCompanyPermissionRoles(rolePermissions);
      this.resetChanges();
    }
  }
}
