import styled from 'styled-components';

import { UpgradeWrapperStyled } from '2d/components/upgrade-plan';

export const Container = styled.div`
  height: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 20px;
  background-color: ${p => p.theme.color.background};
  box-sizing: border-box;
`;

export const TabsWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: ${p => p.theme.color.background};
  box-sizing: border-box;

  ${UpgradeWrapperStyled.StarsContainer} {
    right: 15px;
    top: -3px;
  }
  ${UpgradeWrapperStyled.Container} {
    height: 20px;
  }
`;

const MenuProjectFastContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 189px;
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

export const Styled = {
  Container,
  TabsWrapper,
  MenuProjectFastContainer,
  ButtonsContainer,
};
