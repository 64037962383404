import classNames from 'classnames';
import React from 'react';

import './people-on-project-select.scss';

import { Link } from 'react-router-dom';
import { RoutingContextProps, withRoutingContext } from 'common/routing/with-routing-context';
import { ProjectPerson } from '../../interfaces/project-person';
import { PeopleOnProjectSelectItem } from './people-on-project-select-item';

interface Props extends RoutingContextProps {
  people: ProjectPerson[];
  selectedEmails: string[];
  className?: string;
  onSelectChange: (selected: boolean, email: string, selectedDefault: boolean) => void;
  emptyText?: string;
  onInviteClick?: () => void;
}

export class PeopleOnProjectSelectComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { people, className, emptyText, urls } = this.props;
    return (
      <div className={classNames('people-on-project-select', className)}>
        {people && people.length > 0
          ? people.map((human, index) => {
            if (human.isAccepted) {
              return (
                  <PeopleOnProjectSelectItem
                    id={human.id}
                    key={index}
                    disabled={false}
                    firstName={human.firstName}
                    lastName={human.lastName}
                    email={human.email}
                    selectedDefault={human.selectedDefault}
                    selected={this.isEmailSelected(human.email, human.selectedDefault)}
                    onToggleChange={this.props.onSelectChange}
                  />
              );
            } else {
              return null;
            }
          })
          : emptyText && (
              <div className='people-on-project-select__empty'>
                <div className='people-on-project-select__empty-text'>{emptyText}</div>
                  <Link
                    to={urls.people.index.url()}
                    className='people-on-project-select__invite-btn'
                    onClick={this.props.onInviteClick}
                  >
                    Invite People
                  </Link>
              </div>
          )}
      </div>
    );
  }

  private isEmailSelected(email: string, selectedDefault: boolean): boolean {
    return this.props.selectedEmails.includes(email) ? !selectedDefault : selectedDefault;
  }
}

export const PeopleOnProjectSelect = withRoutingContext(PeopleOnProjectSelectComponent);
