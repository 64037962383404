import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';

import './graph.scss';

import { GraphItemDisplayType } from './enums';
import { GraphEdge } from './graph-edge';
import { EdgeIds } from './graph-position-helper';
import { Coordinate } from './interfaces';
import { PositionPxHelper } from './position-px-helper-provider';


interface EdgeWithDisplayType extends EdgeIds {
  displayType: GraphItemDisplayType;
}

interface Props {
  edges: EdgeWithDisplayType[];
  vertexPositions: Record<number, Coordinate>;
  positionPxHelper: PositionPxHelper;
  vertexWidth: number;
}


export class GraphEdges extends React.Component<Props> {
  public shouldComponentUpdate(prevProps: Props): boolean {
    const props = this.props;
    return !isEqual(props.edges, prevProps.edges) ||
      !isEqual(props.positionPxHelper, prevProps.positionPxHelper);
  }

  public render(): JSX.Element {
    const edges = this.props.edges;
    if (!edges) {
      return null;
    }


    const edgesElements = edges
      .sort(this.edgesComparer)
      .map(this.renderEdge);

    const result = (<>{edgesElements}</>);
    return result;
  }

  @autobind
  private renderEdge(edge: EdgeWithDisplayType): JSX.Element {
    const {
      vertexPositions,
      positionPxHelper,
      vertexWidth,
    } = this.props;

    const fromPosition = vertexPositions[edge.fromId];
    const fromPxPosition = positionPxHelper.getPxPositions(fromPosition.x, fromPosition.y);
    const toPosition = vertexPositions[edge.toId];
    const toPxPosition = positionPxHelper.getPxPositions(toPosition.x, toPosition.y);
    const key = `edge/${fromPxPosition.x}/${fromPxPosition.y}/${toPxPosition.x}/${toPxPosition.y}`;
    return (
      <GraphEdge
        key={key}
        from={fromPxPosition}
        to={toPxPosition}
        direction={edge.direction}
        displayType={edge.displayType}
        vertexWidth={vertexWidth}
      />
    );
  }

  private edgesComparer(edgeA: EdgeWithDisplayType, edgeB: EdgeWithDisplayType): number {
    if (edgeA.displayType === edgeB.displayType) {
      return 0;
    }

    if (edgeA.displayType === GraphItemDisplayType.Highlighted) {
      return 1;
    }
    if (edgeA.displayType === GraphItemDisplayType.Active) {
      return edgeB.displayType === GraphItemDisplayType.Highlighted
        ? -1
        : 1;
    }
  }
}
