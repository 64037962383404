import { Constants } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';

import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { DragScroll } from '../../../../components/drag-scroll';
import { DrawingsViewportHelper } from '../helpers/viewport';
import { useDrawingSettings } from '../hooks/settings/use-drawing-settings';
import { Styled } from './styled';


interface Props {
  viewportHelper: DrawingsViewportHelper;
  children: React.ReactNode;
  canViewMeasurement: boolean;
  isLeftMouseButtonDragEnabled: boolean;
  shouldIgnoreSettings: boolean;
  onDragFinish: () => void;
  onDragStart: () => void;
  onScroll: (x: number, y: number) => void;
  setDrawingsWrapperRef: (ref: HTMLDivElement) => void;
  setScrollLayoutRef: (ref: HTMLDivElement) => void;
  setMainLayoutRef: (ref: HTMLDivElement) => void;
  onCanvasResized: (width: number, height: number) => void;
}

const BLOCKED_DRAG_BUTTONS = [0, 2];
const BLOCKED_DRAG_BUTTONS_EXTRA = [2];

export function DrawingsDragLayoutCanvasWrapper(props: Props): JSX.Element {

  const [ hasHighQualityLayout, setHasHighQualityLayout ] = React.useState<boolean>(false);

  useEffect(() => {
    props.viewportHelper.secondLayoutObserver.subscribe(setHasHighQualityLayout);
    return () => {
      props.viewportHelper.secondLayoutObserver.unsubscribe(setHasHighQualityLayout);
    };
  }, []);

  const settings = useDrawingSettings();
  const isNightTheme = useSelector<State, boolean>(
    state => state.persistedStorage.theme === Constants.Theme.DARK_THEME.name,
  );
  const drawingRendered = useSelector<State, boolean>(s => s.drawings.drawingRenderedStatus);

  const onZoom = useCallback((zoomCoefficient: number, mousePosition: [number, number]) => {
    props.viewportHelper.applyZoom({ zoomCoefficient, mousePosition });
  }, [props.viewportHelper]);

  return (
    <DragScroll
      onDragFinish={props.onDragFinish}
      onDragStart={props.onDragStart}
      onZoom={onZoom}
      sendScrollRef={props.setScrollLayoutRef}
      onPositionChanged={props.onScroll}
      blockButtons={props.isLeftMouseButtonDragEnabled ? BLOCKED_DRAG_BUTTONS_EXTRA : BLOCKED_DRAG_BUTTONS}
    >
      <ReactResizeDetector handleWidth={true} handleHeight={true} onResize={props.onCanvasResized} />
      <Styled.MainLayout
        shouldIgnoreSettings={props.shouldIgnoreSettings}
        ref={props.setMainLayoutRef}
        onContextMenu={ConstantFunctions.stopEvent}
        className='drawings-canvas__main-layout'
        measurementViewPermission={props.canViewMeasurement}
        hasHighQualityLayout={hasHighQualityLayout}
        isNightTheme={isNightTheme}
        drawingRendered={drawingRendered}
        {...settings}
      >
        <div
          ref={props.setDrawingsWrapperRef}
          className='drawings-canvas__drawing-wrapper'
        >
          {props.children}
        </div>
      </Styled.MainLayout>
    </DragScroll>
  );
}
