import { CodecUtils } from 'common/utils/codec-utils';


export enum CustomElementFilterNumberOperation {
  Less = 'Less',
  Greater = 'Greater',
  LessOrEqual = 'LessOrEqual',
  GreaterOrEqual = 'GreaterOrEqual',
  Equal = 'Equal',
}

export const CustomElementFilterNumberOperationC = CodecUtils.createEnumCodec<CustomElementFilterNumberOperation>(
  CustomElementFilterNumberOperation,
  'CustomElementFilterNumberOperation',
);
