import autobind from 'autobind-decorator';
import { keys } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import './page.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { KreoColors } from '../../../../common/enums/kreo-colors';
import { Doughnut, DoughnutItem } from '../../../../components/charts';
import { ScenarioStatus } from '../../../../constants';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { ProjectLayout } from '../../../project-dashbord';
import { ScenariosData } from '../../../scenarios';
import { DashboardActions } from '../../actions/creators/dashboard';
import { ProjectsApi } from '../../api/common';
import {
  CostEstimateGroupingType,
  CostEstimateGroupingTypes,
} from '../../enums/cost-estimate-grouping-type';
import { CostEstimateTreeMap, CostEstimateTreeMapData } from '../../interfaces/dashboard/cost-estimate-tree-map';
import { CostHistogram } from '../../interfaces/dashboard/cost-histogram';
import { DashboardStatuses } from '../../interfaces/dashboard/dashboard-redux-state';
import { Project } from '../../interfaces/project';
import { getCurrentProject } from '../../selectors';
import { Card, CardContainer } from './components/cards';
import { RadioBtnPanel } from './components/radio-btn-panel';
import { ResourceAllocationChart } from './components/resource-allocation-chart';
import { ScheduleChart } from './components/schedule';
import { TreemapChart } from './components/treemap';

interface ReduxProps {
  calculation: ScenariosData.Calculation;
  project: Project;
  scenarioStatus: ScenarioStatus;
  statuses: DashboardStatuses;
  costHistogram: CostHistogram[];
  costEstimateTreeMap: CostEstimateTreeMap[];
}

interface ReduxActions {
  dropData: () => void;
  getAllData: (calculationId: number) => void;
}

interface Props
  extends ReduxProps,
    ReduxActions,
    RouteComponentProps<PlanProjectRouteParams>,
    AbilityAwareProps {}

interface State {
  treemapGroup: CostEstimateGroupingType;
}

const UNNECESSARY_GROUPING_TYPE = [
  CostEstimateGroupingType.WorkpackagesExtended,
  CostEstimateGroupingType.Nrm1Extended,
  CostEstimateGroupingType.Objects,
];

const treemapGroups = CostEstimateGroupingTypes.filter(x => !UNNECESSARY_GROUPING_TYPE.includes(x.value));

class DashboardPageComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      treemapGroup: treemapGroups[0].value,
    };
  }

  public componentDidMount(): void {
    if (this.props.calculation) {
      this.props.getAllData(this.props.calculation.id);
    }
  }

  public componentWillUnmount(): void {
    this.props.dropData();
  }

  public render(): JSX.Element {
    const { project, calculation, ability, match, statuses, costHistogram } = this.props;

    const costs = this.getCostData();
    const treemap = this.getTreemapData();
    const urlParams = { projectId: match.params.projectId };

    const readyForDisplay = calculation && project && project.id && project.isValidationCompleted;

    return (
      <ProjectLayout
        projectId={this.props.match.params && this.props.match.params.projectId}
        waitingForAdditionalInfo={!readyForDisplay}
      >
        <KreoScrollbars>
          <CardContainer>
            <Card
              title='Total cost'
              link={ability.can(Operation.Read, Subject.Cost) && AppUrls.plan.project.costs.url(urlParams)}
              linkName='Costs'
            >
              {costs.length > 0 && <Doughnut type='currency' data={costs} />}
            </Card>
            <Card title='Resource Allocation' width={2}>
              {statuses.costHistogram === RequestStatus.Loaded && costHistogram ? (
                <ResourceAllocationChart data={costHistogram} />
              ) : (
                <SvgSpinner size='middle' />
              )}
            </Card>
            <Card
              title='4D Visualisation'
              className='visualisation'
              link={
                ability.can(Operation.Read, Subject.FourDVisualisation) &&
                AppUrls.plan.project.fourD.url(urlParams)
              }
              linkName='View'
            >
              <img
                alt='4D Visualisation'
                title='4D Visualisation'
                src={ProjectsApi.buildProjectScreenshotUrl(project.id)}
              />
            </Card>
            <Card
              title='Schedule'
              width={2}
              height={1.5}
              link={
                ability.can(Operation.Read, Subject.Gantt) && AppUrls.plan.project.schedule.url(urlParams)
              }
              linkName='Gantt'
            >
              <div className='schedule-title'>Work Packages</div>
              <ScheduleChart projectId={project.id} companyId={project.companyId} calculationId={calculation.id} />
            </Card>
            <Card title='Costs' width={2} height={1.5}>
              <RadioBtnPanel items={treemapGroups} onChange={this.treemapChangeGroup} />
              {statuses.costEstimateTreeMap === RequestStatus.Loaded && treemap ? (
                <TreemapChart data={treemap} name='costs' />
              ) : (
                <SvgSpinner size='middle' />
              )}
            </Card>
            {/* TODO: uncomment when API is ready
            <Card width={4} height={2} className='card-component--activity'>
              <UserActivities projectId={project.id} />
            </Card> */}
          </CardContainer>
        </KreoScrollbars>
      </ProjectLayout>
    );
  }

  private getCostData(): DoughnutItem[] {
    let data: DoughnutItem[] = [];
    if (this.props.calculation) {
      const obj = this.props.calculation;
      if (keys(obj).length > 0) {
        data = [
          {
            name: 'Labour',
            value: obj.labor_cost,
            color: '#52BAD5',
          },
          {
            name: 'Plant',
            value: obj.plant_cost,
            color: '#6188CC',
          },
          {
            name: 'Material',
            value: obj.material_cost,
            color: '#FAC873',
          },
          {
            name: 'O&P',
            value: obj.totalPreliminariesOverhead,
            color: KreoColors.gray3,
          },
        ];
      }
    }
    return data;
  }

  private getTreemapData(): CostEstimateTreeMapData {
    const { treemapGroup } = this.state;
    if (this.props.costEstimateTreeMap) {
      const item = this.props.costEstimateTreeMap.find(x => x.groupingType === treemapGroup);
      return item ? item.data : null;
    }
    return null;
  }

  @autobind
  private treemapChangeGroup(group: CostEstimateGroupingType): void {
    this.setState({ treemapGroup: group });
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    calculation: state.scenarios.active_calculation,
    scenarioStatus: state.scenarios.active_scenario ? state.scenarios.active_scenario.status : null,
    project: getCurrentProject(state),
    statuses: state.dashboard.statuses,
    costHistogram: state.dashboard.costHistogram,
    costEstimateTreeMap: state.dashboard.costEstimateTreeMap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    dropData: () => {
      dispatch(DashboardActions.dropAllData());
    },
    getAllData: calculationId => {
      dispatch(DashboardActions.getAllData(calculationId));
    },
  };
};

export const DashboardPage = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DashboardPageComponent),
);
