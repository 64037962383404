import React from 'react';

import { useCommentUserContext } from 'unit-2d-comments/comments-panel/comment-user-context';
import { Styled } from './styled';

interface Props {
  userId: string;
}

const UserMentionComponent: React.FC<Props> = ({ userId }) => {
  const { getUserDisplayName } = useCommentUserContext();
  return (
    <Styled.UserMention color='grey'>
      @{getUserDisplayName(userId)}
    </Styled.UserMention>
  );
};

export const UserMention = React.memo(UserMentionComponent);
