import { DropdownInput } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { AccountActions } from '../../../units/account/actions/creators';
import { Company } from '../../../units/account/interfaces/company';
import { Styled } from './styled';

interface SelectStateProps {
  companies: Company[];
  selectedCompany: Company;
}

interface SelectDispatchProps {
  selectCompany: (company: Company) => void;
}

interface SelectProps extends SelectStateProps, SelectDispatchProps { }

class CompaniesSelectWithThemeComponent extends React.Component<SelectProps> {
  public render(): React.ReactNode {
    const { companies, selectedCompany } = this.props;
    if (!companies) {
      return null;
    }

    const companyNames = companies.map(company => company.name);
    const selectedCompanyIndex = companies.findIndex(company => company.id === selectedCompany.id);
    return (
      <Styled.Container>
        <DropdownInput
          elements={companyNames}
          activeElementIndex={selectedCompanyIndex}
          onClick={this.selectCompany}
          closeAfterClick={true}
          backgroundColor='backgroundRush'
        />
      </Styled.Container>
    );
  }

  @autobind
  private selectCompany(index: number): void {
    this.props.selectCompany(this.props.companies[index]);
  }
}

const mapStateToProps = (state: State): SelectStateProps => {
  return {
    companies: state.account.companies,
    selectedCompany: state.account.selectedCompany,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): SelectDispatchProps => {
  return {
    selectCompany: company => dispatch(AccountActions.selectCompany(company)),
  };
};

export const CompaniesSelectWithTheme = connect(mapStateToProps, mapDispatchToProps)(
  CompaniesSelectWithThemeComponent,
);
