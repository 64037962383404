import React from 'react';
import { ReportTemplateVariable } from '../../../interfaces';

export const useChangeVariableCallback = (
  projectId: number,
  changeVariable: (projectId: number, variables: ReportTemplateVariable[]) => void,
  variables: ReportTemplateVariable[],
): (variable: ReportTemplateVariable) => void => {
  return React.useCallback((variable: ReportTemplateVariable) => {
    const originVariable = variables.find(v => v.name === variable.name);
    if (originVariable && originVariable.value !== variable.value) {
      changeVariable(projectId, [variable]);
    }
  }, [projectId, changeVariable, variables]);
};

