import { ProjectAccessReason } from 'unit-projects/enums';
import { ProjectAccessFilterConstants } from './constants';

function isOnlyInvited(filterName: string): boolean {
  const currentFilter = ProjectAccessFilterConstants.filters.find(x => x.name === filterName);
  return currentFilter?.accessReasons.length === 1 && currentFilter.accessReasons[0] === ProjectAccessReason.Shared;
}

export const ProjectAccessFilterUtils = {
  isOnlyInvited,
};
