import classNames from 'classnames';
import * as React from 'react';

import './revit-tree-base-item-new.scss';

import { RevitTreeLevelLine } from '../revit-tree-level-line';


interface Props {
  className?: string;
  expandable: boolean;
  expanded: boolean;
  level: number;
  minimumLevel: number;
  firstInLvl: boolean;
  lastInLvl: boolean;
  parentIsLast: boolean;
  error?: boolean;
  onExpand: (e: React.MouseEvent<HTMLDivElement>) => void;
  mouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void;
  mouseOut?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onDoubleClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const RevitTreeBaseItemNew: React.FC<Props> = props => {
  return (
    <div
      className={classNames(
        'revit-tree-base-item-new',
        `revit-tree-base-item-new--lvl-${props.level}`,
        props.className,
        { expanded: props.expanded, 'last-in-lvl': props.lastInLvl },
      )}
      onMouseOver={props.mouseOver}
      onMouseOut={props.mouseOut}
      onClick={props.onClick}
      data-control-name='revit-tree-item'
      onDoubleClick={props.onDoubleClick}
    >
      <RevitTreeLevelLine
        minimumLevel={props.minimumLevel}
        expandable={props.expandable}
        expanded={props.expanded}
        level={props.level}
        firstInLvl={props.firstInLvl}
        lastInLvl={props.lastInLvl}
        parentIsLast={props.parentIsLast}
        onClick={props.onExpand}
      />
      {props.children}
    </div>
  );
};
