import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsGeometryEntityState } from '../../enums/drawings-geometry-entity-state';
import { DrawingsInstanceType } from '../../enums/drawings-instance-type';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { DrawingsRenderParams } from '../../interfaces/drawing-render-parameters';
import { DrawingsPaperColorInfo } from '../../interfaces/drawings-canvas-context-props';
import {
  DrawingsGeometryParams,
  DrawingsPolylineGeometry,
} from '../../interfaces/drawings-geometry';
import { DrawingsMeasurePolyline } from '../../interfaces/drawings-measures';
import { DrawingAnnotationUtils } from '../../utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';
import { DrawingsPaperUtils } from '../../utils/drawings-paper-utils';
import { DrawingsGeometryDragEventHelper } from '../drawings-helpers/drawings-geometry-drag-event-helper';
import {
  DrawingsAddRemovePointResults,
  DrawingsGeometryEntityContinuable,
  DrawingsGeometryEntityStroked,
  DrawingsGeometryEntityStrokedConfig,
  DrawingsMouseEventHandler,
  RenderEntitiesParams,
  StartPoint,
} from './base';
import { DrawingsGeometryEntityAngleArc } from './drawings-geometry-entity-angle-arc';
import { DrawingsGeometryEntityPoint } from './drawings-geometry-entity-point';
import { DrawingsGeometrySelectionArea, DrawingsGeometryEntityLineWithMeasure } from './utility';
import { PolylineThickness } from './utility/polyline-thickness';

export interface DrawingsGeometryEntityPolylineConfig extends DrawingsGeometryEntityStrokedConfig {
  geometry: DrawingsPolylineGeometry;
  layer: paper.Layer;
  startDragPoint: DrawingsMouseEventHandler;
  onStartDrag: DrawingsMouseEventHandler;
  getPointInfo: (id: string) => paper.Point;
  addPoint: (lineId: string, coordinates: ShortPointDescription) => void;
  onSelectSegmentMeasure: DrawingsMouseEventHandler;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
}

export class DrawingsGeometryEntityPolyline
  extends DrawingsGeometryEntityStroked<DrawingsGeometryEntityPolylineConfig>
  implements DrawingsGeometryEntityContinuable<DrawingsPolylineGeometry, DrawingsMeasurePolyline> {
  protected _path: paper.Path;
  private _angleArc: DrawingsGeometryEntityAngleArc;

  private _continueEnabled: boolean;
  private _tempLine: paper.Path;
  private _tempStart: StartPoint;

  private _thicknessShape: PolylineThickness;

  private _geometryGroup: paper.Group;

  constructor(config: DrawingsGeometryEntityPolylineConfig) {
    super(config);
    this._geometryGroup = new paper.Group();
    this._geometryGroup.addTo(this._config.layer);

    this._path = new paper.Path(this._config.geometry.points.map(this._config.getPointInfo));
    this.renderThickness();
    const { zoom } = config.renderParamsContextObserver.getContext();
    this._path.strokeWidth = this._config.geometry.strokeWidth / zoom;
    this._path.dashArray = DrawingsCanvasUtils.scaleStroke(this._config.geometry, zoom);
    this._eventsApplier.setExtraInfo(this.id);
    this._eventsApplier.setHandlers(this._path);
    this._path.onMouseEnter = (e) => this.mouseEnter(this._config.id, e);
    this._path.strokeColor = config.color.stroke;
    this._geometryGroup.addTo(this._config.layer);
    DrawingsPaperUtils.updatePathStyles(this._path);
    this._config.renderParamsContextObserver.subscribe(this.changeVisualData);
    this._config.textRenderParamsObserver.subscribe(this.renderThickness);
  }

  public set continueEnabled(value: boolean) {
    this._continueEnabled = value;
    if (!value) {
      this.removeTempLine();
    }
  }

  public override canSplit(): boolean {
    return (
      this.selectedPointsIds.length > 2 ||
      !this.selectedPointsIds.every((x) => {
        const pointIndex = this._config.geometry.points.indexOf(x);
        return pointIndex === 0 || pointIndex === this._config.geometry.points.length - 1;
      })
    );
  }

  public getGeometry(): DrawingsPolylineGeometry {
    return this._config.geometry;
  }


  public getPath(): paper.Path {
    return this._path;
  }

  public canRemovePoints(pointIds: string[]): boolean {
    return this._config.geometry.points.length - pointIds.length >= 2;
  }

  public getMeasures(): DrawingsMeasurePolyline {
    const { scale, metersPerPixel } = this._config.textRenderParamsObserver.getContext();
    return DrawingsCanvasUtils.getPolylineMeasures(this._path, scale, metersPerPixel, this._config.geometry);
  }

  public removePoints(
    pointsIds: string[],
  ): DrawingsAddRemovePointResults<DrawingsPolylineGeometry, DrawingsMeasurePolyline> {
    this.removeTempLine();
    const removedLines = [];
    const newLines: Record<string, [string, string]> = {};

    const addLineToRemoved = (lineId): void => {
      removedLines.push(lineId);
      this.removeLine(lineId);
    };
    const points = this._config.geometry.points.slice();

    const pointsForRemoveSet = new Set(pointsIds);
    const filteredPoints = [];
    this._path.removeSegments();
    let lastValidPointIndex;
    for (let i = 0; i < points.length; i++) {
      const pointId = points[i];
      if (!pointsForRemoveSet.has(pointId)) {
        if (Number.isInteger(lastValidPointIndex) && i - lastValidPointIndex > 1) {
          const prevValidPoint = points[lastValidPointIndex];
          newLines[DrawingAnnotationUtils.getLineKey(prevValidPoint, pointId)] = [prevValidPoint, pointId];
        }
        lastValidPointIndex = i;
        filteredPoints.push(pointId);
        this._path.addSegments([new paper.Segment(this._config.getPointInfo(pointId))]);
      } else if (this._points && this._points.has(pointId)) {
        this._points.get(pointId).destroy();
        if (i === 0) {
          addLineToRemoved(DrawingAnnotationUtils.getLineKey(pointId, points[i + 1]));
        } else if (i === points.length - 1) {
          addLineToRemoved(DrawingAnnotationUtils.getLineKey(pointId, points[i - 1]));
        } else {
          addLineToRemoved(DrawingAnnotationUtils.getLineKey(pointId, points[i - 1]));
          addLineToRemoved(DrawingAnnotationUtils.getLineKey(pointId, points[i + 1]));
        }
        this._points.delete(pointId);
      }
    }
    const measures = this.getMeasures();
    this._config.geometry = { ...this._config.geometry, points: filteredPoints, ...measures };

    if (this.state !== DrawingsGeometryEntityState.Default) {
      for (const [lineId, [start, end]] of Object.entries(newLines)) {
        if (this._lines.has(lineId)) {
          continue;
        }
        this.renderLine(
          start,
          end,
          this.state === DrawingsGeometryEntityState.Modify,
          this.state === DrawingsGeometryEntityState.Hover,
        );
      }
    }
    this.updatePointsSelection([], false);
    if (this._continueEnabled) {
      this._tempLine.remove();
      this._tempLine = null;
    }
    this.renderThickness();
    return {
      geometry: this._config.geometry,
      removedLines,
      newLines,
      measures,
    };
  }

  public isExistsInRect(rect: paper.Rectangle, selectionArea: DrawingsGeometrySelectionArea): boolean {
    return this._path.isInside(rect) || selectionArea.intersects(this._path);
  }

  public updatePointsInEntity(ids: string[]): DrawingsMeasurePolyline {
    const points = this._config.geometry.points;
    for (const id of ids) {
      const index = points.indexOf(id);
      const currentPoint = this._config.getPointInfo(id);
      this._path.segments[index].point = currentPoint;
      const isFirst = index === 0;
      const isLast = index === points.length - 1;
      if (this.state === DrawingsGeometryEntityState.Modify) {
        if (this._points && this._points.has(id)) {
          this._points.get(id).changePosition(currentPoint.x, currentPoint.y);
        }
      }

      if (this._lines) {
        if (!isFirst) {
          const prevLine = DrawingAnnotationUtils.getLineKey(id, points[index - 1]);
          this.updatePointInLine(prevLine, id);
        }
        if (!isLast) {
          const nextLine = DrawingAnnotationUtils.getLineKey(id, points[index + 1]);
          this.updatePointInLine(nextLine, id);
        }
      }

      if (this._angleArc && !isFirst && !isLast) {
        const prevPoint = this._config.getPointInfo(points[index - 1]);
        const nextPoint = this._config.getPointInfo(points[index + 1]);
        this._angleArc.updatePoints([prevPoint, currentPoint, nextPoint]);
      }
    }
    this.renderThickness();
    return this.getMeasures();
  }

  public updateGeometry(geometry: DrawingsPolylineGeometry): DrawingsMeasurePolyline {
    this._path.removeSegments();
    this.removeTempLine();
    if (this._lines) {
      this._lines.forEach((x) => x.destroy());
      this._lines.clear();
    }
    this._config.geometry = geometry;
    this.renderPolyline();

    if (this.state !== DrawingsGeometryEntityState.Default) {
      this._path.strokeWidth = 0;
      this.applyState(this.state);
    }
    this.renderThickness();
    return this.getMeasures();
  }

  public addPoints(
    segment: [string, string],
    newPointIds: string[],
  ): DrawingsAddRemovePointResults<DrawingsPolylineGeometry, DrawingsMeasurePolyline> {
    this.removeTempLine();
    if (segment.every((x) => x)) {
      return this.addPointsToSegment(segment, newPointIds);
    } else if (segment[1]) {
      return this.addPointsToStart(newPointIds);
    } else if (segment[0]) {
      return this.addPointsToEnd(newPointIds);
    }
    this.renderThickness();
  }

  public updateLabel(): void {
    if (this._lines) {
      this._lines.forEach((x) => x.changeLabel());
    }
  }

  public destroy(): void {
    super.destroy();
    this._config.renderParamsContextObserver.unsubscribe(this.changeVisualData);
    this._config.textRenderParamsObserver.unsubscribe(this.renderThickness);
    this.removeTempLine();
    if (this._thicknessShape) {
      this._thicknessShape.destroy();
    }
  }

  @autobind
  public updateContinueTempPoint(tempPoint: paper.Point, startPoint?: StartPoint): void {
    if (tempPoint) {
      if (!this._tempLine) {
        paper.view.update();
        const points = this._config.geometry.points;
        this._tempStart = startPoint;
        const pointInfo = this._config.getPointInfo(points[startPoint === StartPoint.Start ? 0 : points.length - 1]);
        this._tempLine = new paper.Path([pointInfo, tempPoint]);
        const { zoom } = this._config.renderParamsContextObserver.getContext();
        this._tempLine.strokeWidth = this._config.geometry.strokeWidth / zoom;
        this._tempLine.strokeColor = this._config.color.stroke;
        this._tempLine.dashArray = DrawingsCanvasUtils.scaleStroke(this._config.geometry, zoom);
        DrawingsPaperUtils.updatePathStyles(this._tempLine);
        this._tempLine.addTo(this._config.layer);
      }
      this._tempLine.lastSegment.point = tempPoint;
      this.renderThickness();
    } else {
      this.removeTempLine();
      this.renderThickness();
    }
  }


  protected override changeColor(value: DrawingsPaperColorInfo, colorCode: string): void {
    super.changeColor(value, colorCode);
    if (this._angleArc) {
      this._angleArc.changeColor(value.stroke);
    }
    if (this._lines) {
      this._lines.forEach((x) => x.changeColor(value.stroke));
    }
    if (this._tempLine) {
      this._tempLine.strokeColor = value.stroke;
    }

    if (this._thicknessShape) {
      this._thicknessShape.fillColor = value.fill;
    }
  }

  @autobind
  protected removeEntities(): void {
    super.removeEntities();
    this.removeAngleArc();
  }

  @autobind
  protected renderEntities({ skipPointsRender, modifyEnabled, showHoverLineStyle }: RenderEntitiesParams): void {
    this.initEntityGroups();
    this.removeAngleArc();
    for (let i = 0; i < this._config.geometry.points.length; i++) {
      const segment = this._path.segments[i];
      const currentPointId = this._config.geometry.points[i];
      if (!skipPointsRender) {
        this.renderPoint(currentPointId, segment.point);
      }
      const nextPointId = this._config.geometry.points[i + 1];
      if (!nextPointId) {
        return;
      }
      const lineId = DrawingAnnotationUtils.getLineKey(currentPointId, nextPointId);
      if (!this._lines.has(lineId)) {
        this.renderLine(currentPointId, nextPointId, modifyEnabled, showHoverLineStyle);
      } else {
        const line = this._lines.get(lineId);
        line.modifyEnabled = modifyEnabled;
        if (showHoverLineStyle) {
          line.enableHoverStyle();
        } else {
          line.disableHoverStyle();
        }
      }
    }
  }

  protected enableSelectMode(): void {
    this._path.strokeWidth = 0;
    if (this._lines) {
      this._lines.forEach((x) => {
        x.canDragMode = true;
        x.enableSelectedStyle();
      });
    }
  }

  protected override applyGeometryParam<P extends keyof DrawingsGeometryParams>(
    field: P,
    value: DrawingsGeometryParams[P],
  ): void {
    if (field === 'thickness') {
      this._config.geometry.thickness = value;
      this.renderThickness();
    }
  }

  @autobind
  private renderLineAndAddToNew(start: string, end: string, lines: Record<string, [string, string]>): void {
    DrawingsGeometryUtils.addLineToRecordsByPoints(start, end, lines);
    const isModify = this.state === DrawingsGeometryEntityState.Modify;
    const isHover = this.state === DrawingsGeometryEntityState.Hover;
    this.renderLine(start, end, isModify, isHover);
  }

  @autobind
  private changeVisualData({ zoom }: DrawingsRenderParams): void {
    const { strokeWidth } = this._config.geometry;
    if (this.state === DrawingsGeometryEntityState.Default) {
      this._path.strokeWidth = strokeWidth / zoom;
      this._path.dashArray = DrawingsCanvasUtils.scaleStroke(this._config.geometry, zoom);
    }
    if (this._tempLine) {
      this._tempLine.strokeWidth = strokeWidth / zoom;
      this._tempLine.dashArray = DrawingsCanvasUtils.scaleStroke(this._config.geometry, zoom);
    }
  }

  @autobind
  private removeLine(lineId: string): void {
    if (this._lines && this._lines.has(lineId)) {
      this._lines.get(lineId).destroy();
      this._lines.delete(lineId);
    }
  }

  private renderPoint(id: string, point: paper.Point): void {
    if (this._points.has(id)) {
      this._points.get(id).paperPosition = point;
    } else {
      this._points.set(
        id,
        new DrawingsGeometryEntityPoint({
          id,
          geometry: point,
          layer: this._pointsGroup,
          canModify: true,
          canDelete: true,
          onSelect: this.onPointSelect,
          onStartDrag: this._config.startDragPoint,
          onMouseEnter: this.pointMouseEnter,
          onMouseLeave: this.pointMouseLeave,
          renderParamsContextObserver: this._config.renderParamsContextObserver,
          cursorHelper: this._config.cursorHelper,
        }),
      );
    }
  }

  private removeAngleArc(): void {
    if (this._angleArc) {
      this._angleArc.destroy();
      this._angleArc = null;
    }
  }

  @autobind
  private pointMouseLeave(id: string, e: PaperMouseEvent): void {
    this.mouseLeave(id, e);
    this.removeAngleArc();
  }

  private renderLine(
    firstPointId: string,
    lastPointId: string,
    modifyEnabled: boolean,
    showHoverLineStyle: boolean,
  ): void {
    const lineId = DrawingAnnotationUtils.getLineKey(firstPointId, lastPointId);
    const line = new DrawingsGeometryEntityLineWithMeasure({
      id: lineId,
      points: [firstPointId, lastPointId],
      layer: this._linesGroup,
      color: this._path.strokeColor,
      modifyEnabled,
      dashed: this.state === DrawingsGeometryEntityState.Selected,
      textLayer: this._segmentTextGroup,
      onSelectSegmentMeasure: this._config.onSelectSegmentMeasure,
      onMouseEnter: this.mouseEnter,
      onMouseLeave: this.mouseLeave,
      onSelect: this.onLineClick,
      onDoubleClick: this.onLineDoubleClick,
      onStartDrag: this.onStartDragByChild,
      dragEventsHelper: this._config.dragEventsHelper,
      renderParamsContextObserver: this._renderParamsObserverGroup,
      textRenderParamsObserver: this._textObserverGroup,
      segmentDragHelper: this._config.segmentDragHelper,
      canMove: this.canEditSegment(),
      strokeStyle: this._config.geometry,
      cursorHelper: this._config.cursorHelper,
      pointsManager: this._config,
    });
    if (showHoverLineStyle) {
      line.enableHoverStyle();
    }
    this._lines.set(lineId, line);
  }

  @autobind
  private pointMouseEnter(id: string, e: PaperMouseEvent): void {
    this.mouseEnter(id, e);
    const points = this._config.geometry.points;
    const index = points.findIndex((x) => x === id);
    if (index === 0 || index === points.length - 1) {
      return;
    }
    const currentPoint = this._config.getPointInfo(id);
    const prevPoint = this._config.getPointInfo(points[index - 1]);
    const nextPoint = this._config.getPointInfo(points[index + 1]);
    this._angleArc = new DrawingsGeometryEntityAngleArc({
      textRenderParamsObserver: this._config.textRenderParamsObserver,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      id: 'polygon-arc',
      points: [prevPoint, currentPoint, nextPoint],
      color: this._path.strokeColor,
      layer: this._config.pointLayer,
      mustExistInPath: true,
      clampPath: this._path,
      cursorHelper: this._config.cursorHelper,
    });
  }

  @autobind
  private onStartDragByChild(_id: string, e: PaperMouseEvent): void {
    if (this._config.onStartDrag) {
      this._config.onStartDrag(this.id, e);
    }
  }

  @autobind
  private onLineClick(_lineId: string, e: PaperMouseEvent): void {
    this._config.onSelect(this.id, e);
  }

  @autobind
  private onLineDoubleClick(_lineId: string, e: PaperMouseEvent): void {
    this._config.onDoubleClick(this.id, e);
  }

  private renderPolyline(): void {
    this._path.addSegments(this._config.geometry.points.map((x) => new paper.Segment(this._config.getPointInfo(x))));
    this.renderThickness();
  }

  private addPointsToSegment(
    [first, last]: [string, string],
    newPointIds: string[],
  ): DrawingsAddRemovePointResults<DrawingsPolylineGeometry, DrawingsMeasurePolyline> {
    this._path.removeSegments();
    let firstIndex = this._config.geometry.points.lastIndexOf(first);
    let lastIndex = this._config.geometry.points.lastIndexOf(last);
    if (firstIndex < lastIndex) {
      [firstIndex, lastIndex] = [lastIndex, firstIndex];
    }
    const points = DrawingsGeometryUtils.addPointToPoly(
      [...this._config.geometry.points],
      firstIndex,
      lastIndex,
      newPointIds,
      DrawingsInstanceType.Line,
    );
    this._config.geometry = { ...this._config.geometry, points };
    this.renderPolyline();
    const removedLines = [];
    const lineId = DrawingAnnotationUtils.getLineKey(first, last);
    removedLines.push(lineId);
    const measures = this.getMeasures();
    this.removeLine(lineId);
    const newLines = this.processNewLines(newPointIds, [
      [first, newPointIds[0]],
      [newPointIds[newPointIds.length - 1], last],
    ]);
    this.reaplyState();
    return {
      geometry: this._config.geometry,
      measures,
      removedLines,
      newLines,
    };
  }

  private addPointsToStart(
    newPointIds: string[],
  ): DrawingsAddRemovePointResults<DrawingsPolylineGeometry, DrawingsMeasurePolyline> {
    this._path.removeSegments();
    const firstPointId = this._config.geometry.points[0];
    const points = [...newPointIds, ...this._config.geometry.points];
    this._config.geometry = { ...this._config.geometry, points };
    this.renderPolyline();
    const measures = this.getMeasures();

    const newLines = this.processNewLines(newPointIds, [[newPointIds[newPointIds.length - 1], firstPointId]]);

    this.reaplyState();
    return {
      geometry: this._config.geometry,
      measures,
      removedLines: [],
      newLines,
    };
  }

  private addPointsToEnd(
    newPointIds: string[],
  ): DrawingsAddRemovePointResults<DrawingsPolylineGeometry, DrawingsMeasurePolyline> {
    this._path.removeSegments();
    const lastPointId = this._config.geometry.points[this._config.geometry.points.length - 1];
    const points = [...this._config.geometry.points, ...newPointIds];
    this._config.geometry = { ...this._config.geometry, points };
    this.renderPolyline();
    const measures = this.getMeasures();

    const newLines = this.processNewLines(newPointIds, [[lastPointId, newPointIds[0]]]);
    this.reaplyState();
    return {
      geometry: this._config.geometry,
      measures,
      removedLines: [],
      newLines,
    };
  }

  private reaplyState(): void {
    if (this.state === DrawingsGeometryEntityState.Selected) {
      this.applyState(this.state);
    } else if (this.state === DrawingsGeometryEntityState.Modify) {
      this.applyState(this.state);
    }
  }

  private processNewLines(
    newPointIds: string[],
    additionalLines: Array<[string, string]>,
  ): Record<string, [string, string]> {
    const newLines = {};
    const addLines = this._lines ? this.renderLineAndAddToNew : DrawingsGeometryUtils.addLineToRecordsByPoints;
    for (const [start, end] of additionalLines) {
      addLines(start, end, newLines);
    }

    for (let i = 1; i < newPointIds.length; i++) {
      addLines(newPointIds[i - 1], newPointIds[i], newLines);
    }

    return newLines;
  }

  private removeTempLine(): void {
    if (this._tempLine) {
      this._tempLine.remove();
      this._tempLine = null;
      this.renderThickness();
    }
  }

  @autobind
  private renderThickness(): void {
    if (this._thicknessShape) {
      this._thicknessShape.destroy();
    }
    const { thickness } = this._config.geometry;
    const { showThickness } = this._config.textRenderParamsObserver.getContext();
    if (!thickness || !showThickness) {
      return;
    }
    const { scale, metersPerPixel } = this._config.textRenderParamsObserver.getContext();
    const thicknessInPx = DrawingsCanvasUtils.metersToPx(thickness, scale, metersPerPixel);
    let segments = this._path.segments;
    if (this._tempLine) {
      if (this._tempStart === StartPoint.Start) {
        segments = [this._tempLine.segments[1], this._tempLine.segments[0], ...segments];
      } else {
        segments = segments.concat(this._tempLine.segments);
      }
    }

    this._thicknessShape = new PolylineThickness({
      geometry: segments,
      layer: this._geometryGroup,
      color: this._config.color.thickness,
      offset: thicknessInPx / 2,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
    });
  }
}
