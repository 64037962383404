import { Property, PropertyTypeEnum } from 'unit-2d-database/interfaces';

export function* getPropertyIterator(
  properties: Property[],
  originProps: Property[],
): IterableIterator<[Property, Property]> {
  for (const property of properties) {
    const originProperty = property.value && originProps.find(p => p.name === property.name
      && (p.value.type === property.value.type
        || (property.value.type === PropertyTypeEnum.BreakdownLink && p.value.type === PropertyTypeEnum.Breakdown)
      ),
    );
    yield originProperty
      ? [property, originProperty]
      : [property, undefined];
  }
}

export function* getPropertyIteratorById(
  properties: Property[],
  originProps: Property[],
): IterableIterator<[Property, Property]> {
  for (const property of properties) {
    const originProperty = originProps.find(p => p.id === property.id);
    yield originProperty
      ? [property, originProperty]
      : [property, undefined];
  }
}
