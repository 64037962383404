import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { ProjectsActions } from '../actions/creators/common';
import { ModelManagementActions } from '../actions/creators/model-management';
import { ModelManagementActionTypes } from '../actions/types/model-management';
import { ModelManagementApi } from '../api/model-management';
import { CompanyProjectHeader } from '../interfaces/company-project-header';
import { ModelManagementData } from '../interfaces/model-management/model-management-data';

function* loadData({ payload: id }: ActionWith<number>): SagaIterator {
  try {
    const data: ModelManagementData = yield call(ModelManagementApi.loadData, id);
    yield put(ModelManagementActions.loadDataSuccess(data.files));
  } catch (error) {
    console.error(error);
  }
}


function* applyChanges(): SagaIterator {
  try {
    const { projectId, changes } = yield selectWrapper(state => {
      return {
        projectId: state.projects.currentProject.id,
        changes: {
          filesToAdd: state.modelManagement.temporaryModels.map(x => x.uploadedFileName),
          filesToDelete: state.modelManagement.modelsForRemove.map(x => x.id),
        },
      };
    });
    const projectData: CompanyProjectHeader = yield call(ModelManagementApi.updateFiles, projectId, changes);
    yield put(ModelManagementActions.dropPageState());
    yield put(ProjectsActions.updateViewModelStatuses(projectData.id, projectData.viewModelStatuses));
  } catch (error) {
    console.error(error);
    yield put(ModelManagementActions.applyFailed());
  }
}

export function* modelManagementSagas(): SagaIterator {
  yield takeLatest(ModelManagementActionTypes.LOAD_DATA, loadData);
  yield takeLatest(ModelManagementActionTypes.APPLY_CHANGES, applyChanges);
}
