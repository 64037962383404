import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { ActivityGroupingApi } from '../../../api/scenario';
import { ScenarioStatus } from '../../../constants';
import { ScenariosActions, StepActions, StepActionTypes } from '../actions';
import { ActivityGroupData, ActivityGroupRestData, GraphChangeType } from '../interfaces';

function* loadActivityGroups(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    const data: ActivityGroupRestData.ActivityGroupingResponse = yield call(
      ActivityGroupingApi.getActivityGroups,
      scenarioId,
    );
    yield put(StepActions.loadActivityGroupingComplete(data));
  } catch (error) {
    console.error('activity grouping: load activity groups failed', error, action.payload);
  }
}

function* saveActivityGroups(
  action: ActionWith<{ scenarioId: number, recalculate: boolean }>,
): SagaIterator {
  try {
    const changes: ActivityGroupRestData.ActivityGroupingChangesRequest = yield selectWrapper(
      state => state.activityGrouping.activityGrouping.changes,
    );
    const scenarioId = action.payload.scenarioId;
    changes.scenarioId = scenarioId;
    changes.shouldRecalculate = action.payload.recalculate;

    yield put(ScenariosActions.updateScenarioStatus({ scenarioId, status: ScenarioStatus.Calculating }));
    yield call(ActivityGroupingApi.updateActivityGroups, action.payload.scenarioId, changes);
    yield put(StepActions.updateActivityGroupingComplete(scenarioId));
  } catch (error) {
    console.error('activity grouping: save activity groups failed', error, action.payload);
  }
}

function* loadMacroSequence(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    const data: ActivityGroupRestData.MacroSequenceResponse = yield call(
      ActivityGroupingApi.getMacroSequence,
      scenarioId,
    );
    yield put(StepActions.loadMacroSequenceComplete(data));
  } catch (error) {
    console.error('activity grouping: load macro sequence failed', error, action.payload);
  }
}

function* saveMacroSequence(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    const changes: ActivityGroupData.GraphChange[] = yield selectWrapper(
      state => state.activityGrouping.macroSequence.changes,
    );
    const requestModel: ActivityGroupRestData.MacroSequenceChangesRequest = {
      addedLimitations: [],
      updatedLimitationUsings: {},
      updatedLimitationTimeLags: {},
    };

    for (const change of changes) {
      const limitation = change.limitation;
      switch (change.graphChangeType) {
        case GraphChangeType.Added:
          requestModel.addedLimitations.push(limitation);
          break;
        case GraphChangeType.NotUsed:
        case GraphChangeType.Used:
          requestModel.updatedLimitationUsings[limitation.source] =
            requestModel.updatedLimitationUsings[limitation.source] || {};
          requestModel.updatedLimitationUsings[limitation.source][limitation.target] =
            change.graphChangeType === GraphChangeType.Used;
          break;
        case GraphChangeType.TimeLagChanged:
          requestModel.updatedLimitationTimeLags[limitation.source] =
            requestModel.updatedLimitationTimeLags[limitation.source] || {};
          requestModel.updatedLimitationTimeLags[limitation.source][limitation.target] = limitation.timeLag;
          break;
        default:
      }
    }

    yield put(ScenariosActions.updateScenarioStatus({ scenarioId, status: ScenarioStatus.Calculating }));
    yield call(ActivityGroupingApi.updateMacroSequence, scenarioId, requestModel);
    yield put(StepActions.updateMacroSequenceComplete(scenarioId));
  } catch (error) {
    console.error('activity grouping: save macro sequence failed', error, action.payload);
  }
}

function* saveRequestSucceeded(action: ActionWith<number>): SagaIterator {
  try {
    const scenarioId = action.payload;
    yield put(ScenariosActions.updateScenarioStatus({ scenarioId, status: ScenarioStatus.Success }));
  } catch (error) {
    console.error('activity grouping: save request succeeded failed', error, action.payload);
  }
}

export function* activityGroups(): SagaIterator {
  yield takeLatest(StepActionTypes.LOAD_ACTIVITY_GROUPING, loadActivityGroups);
  yield takeLatest(StepActionTypes.UPDATE_ACTIVITY_GROUPING, saveActivityGroups);
  yield takeEvery(StepActionTypes.UPDATE_ACTIVITY_GROUPING_SUCCEEDED, saveRequestSucceeded);
  yield takeLatest(StepActionTypes.UPDATE_MACRO_SEQUENCE, saveMacroSequence);
  yield takeLatest(StepActionTypes.UPDATE_MACRO_SEQUENCE_SUCCEEDED, saveRequestSucceeded);
  yield takeLatest(StepActionTypes.LOAD_MACRO_SEQUENCE, loadMacroSequence);
}
