import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { analyticEvent } from './posthog';

export function usePageTracking(): void {
  const pathName = useSelector<State>((state) => state.router.location.pathname);
  const search = useSelector<State>((state) => state.router.location.search);
  useEffect(() => {
    analyticEvent(
      '$pageview',
      {
        '$current_url': window.location.href,
      },
    );
  }, [pathName, search]);
}
