import * as React from 'react';

import './resource-limitations-list-header.scss';

import { KreoIconInfoBigColor } from 'common/UIKit';
import { HelpTooltip } from '../../../../components/controls/tooltips';

export const ResourceLimitationsListHeader: React.FC = () => {
  const className = 'resource-limitations-list-header';
  return (
    <div className={className}>
      <div className={`${className}__title`}>Work packages</div>
      <div className={`${className}__fields`}>
        <div className={`${className}__fields-item ${className}__fields-item--shift`}>
          Shift
        </div>
        <div className={`${className}__fields-item ${className}__fields-item--resource`}>
          Resource Overloading Impact
          <HelpTooltip
            icon={<KreoIconInfoBigColor />}
            text='Consider the change in productivity growth with an increase in the number of gangs.
                  Amdahl’s law in action.'
            id='resource-overloading-tooltip'
            className={`${className}__tooltip`}
          />
        </div>
        <div className={`${className}__fields-item ${className}__fields-item--productivity`}>
          Productivity
        </div>
      </div>
    </div>
  );
};
