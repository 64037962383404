import styled from 'styled-components';

const Container = styled.div`
  padding-left: 20px;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: end;
`;

const TextContainer = styled.div`
  padding: 10px 15px;
  background-color: ${p => p.theme.color.backgroundRush};
  margin-left: 30px;
  margin-right: 15px;
  border-radius: 20px 20px 5px 20px;
  width: calc(100% - 45px);

  table {
    width: 100%;
    border-collapse: collapse;
    border-width: 2px;
    border-color: ${p => p.theme.color.background};
    border-style: solid;
  }

  table td, table th {
    border-width: 2px;
    border-color: ${p => p.theme.color.background};
    border-style: solid;
    padding: 5px;
  }

  table td {
    text-align: left;
    vertical-align: top;
  }

  table thead {
    background-color: ${p => p.theme.color.pale};
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 5px;
  margin-top: 12px;
  padding: 4px 5px;
  margin-left: auto;

  p {
    color: ${p => p.theme.color.mainFont};
  }

  button {
    height: 18px;
    width: fit-content;

    svg {
      fill: ${p => p.theme.color.gray};
      width: 10px;
      height: 10px;
    }
  }
`;

export const Styled = {
  MessageContainer,
  Container,
  TextContainer,
  Buttons,
};
