import autobind from 'autobind-decorator';
import React from 'react';
import { Field } from 'redux-form';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { KreoIconCloseSmall, MaterialNumberInputField, MaterialNumberInputProps } from 'common/UIKit';
import { GreaterThan } from '../../../../components/dialog/validators';
import { ActivityResourceVariant } from '../../interfaces/data';
import { LaborVariantModel } from '../../interfaces/resources-data';

const quantityGreaterThan0 = GreaterThan(0, 'Quantity');

interface Props extends I18nAwareProps {
  fieldKey: string;
  labor: ActivityResourceVariant<LaborVariantModel>;
  readonly: boolean;
  index: number;
  onQuantityChange: (laborVariantId: number, quantity: number) => void;
  onDelete: (laborVariantId: number) => void;
}

class LaborItemComponent extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='database-entity-modify-wrap-tile labor'>
        <div className='database-entity-modify-wrap-tile__name'>{this.props.labor.resourceVariant.resourceName}</div>
        <div className='database-entity-modify-wrap-tile__content'>
          <Field<MaterialNumberInputProps>
            name={`${this.props.fieldKey}.amount`}
            component={MaterialNumberInputField}
            className='database-entity-modify-wrap-tile__quantity'
            label='Quantity'
            isFloatingLabel={false}
            value={this.props.labor.amount}
            onChange={this.onQuantityChange}
            precision={2}
            valueType='float'
            disabled={this.props.readonly}
            displayBottomInformation={true}
            validate={quantityGreaterThan0}
          />
          <div className='database-entity-modify-wrap-tile__key'>
            <div className='database-entity-modify-wrap-tile__label'>Skill</div>
            <div className='database-entity-modify-wrap-tile__value'>{this.props.labor.resourceVariant.name}</div>
          </div>
          <div className='database-entity-modify-wrap-tile__rate'>
            <div className='database-entity-modify-wrap-tile__label'>Rate</div>
            <div className='database-entity-modify-wrap-tile__value'>
              {this.props.i18n.currency.symbol}{this.props.labor.resourceVariant.cost}
            </div>
          </div>
          {
            !this.props.readonly ? (
              <div onClick={this.onDelete} className='database-entity-modify-wrap-tile__del-btn'>
                <KreoIconCloseSmall/>
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }

  @autobind
  private onDelete(): void {
    this.props.onDelete(this.props.index);
  }

  @autobind
  private onQuantityChange(_: React.ChangeEvent, newValue: number | null): void {
    this.props.onQuantityChange(this.props.index, newValue);
  }
}

export const LaborItem = withI18n(LaborItemComponent);
