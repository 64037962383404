import classNames from 'classnames';
import * as React from 'react';

import './mode-switcher.scss';

interface SwitcherProps {
  modes: string[];
  activeMode: string;
  setActiveMode: (activeMode: string) => void;
  large?: boolean;
}

interface ClickHandlersStorage {
  [mode: string]: () => void;
}

export class ModeSwitcher extends React.Component<SwitcherProps> {
  private clickHandlers: ClickHandlersStorage = {};

  public render(): React.ReactNode {
    const containerClassName = classNames(
      'mode-switcher',
      {
        large: !!this.props.large,
      });

    return (
      <div className={containerClassName}>
        {
          this.props.modes.map((mode) => {
            const className = classNames(
              'mode-switcher__button',
              {
                active: mode === this.props.activeMode,
              });

            return (
              <div
                className={className}
                key={mode}
                onClick={this.getClickHandler(mode)}
              >
                {mode}
              </div>
            );
          })
        }
      </div>
    );
  }

  private getClickHandler(mode: string): () => void {
    if (!this.clickHandlers[mode]) {
      this.clickHandlers[mode] = () => { this.props.setActiveMode(mode); };
    }

    return this.clickHandlers[mode];
  }
}
