import { Feed } from 'common/interfaces/feed';
import { objectUtils } from 'common/utils/object-utils';
import { CompanyResourcesApi } from '../../../api/server';
import { ResourceType } from '../enums';
import { RequestFilterModel } from '../interfaces/data';
import { ResourceVariantModel } from '../interfaces/resources-data';
import { ResourceVariantForm } from '../interfaces/resources-rest-data';
import { ResourcesBaseUrlBuilder } from './resources-base-url-builder';


function getResourceVariant(
  databaseId: number,
  resourceId: number,
  resourceVariantId: number,
  type: ResourceType,
): Promise<ResourceVariantModel> {
  const baseUrl = ResourcesBaseUrlBuilder.getResourcesBaseUrl(databaseId, type);
  return CompanyResourcesApi.get(
    `${baseUrl}/${resourceId}/variants/${resourceVariantId}`,
    `api get database(${databaseId}) ${type} resource(${resourceId}) variant(${resourceVariantId}) error`,
  );
}

function getResourcesVariants(
  databaseId: number,
  type: ResourceType,
  filter: RequestFilterModel,
): Promise<Feed<ResourceVariantModel>> {
  const baseUrl = ResourcesBaseUrlBuilder.getResourcesBaseUrl(databaseId, type);
  return CompanyResourcesApi.get(
    `${baseUrl}/variants?${objectUtils.toQueryString(filter)}`,
    `api get database(${databaseId}) ${type} resources variants error`,
  );
}

function updateResourceVariant(
  databaseId: number,
  resourceId: number,
  resourceVariantId: number,
  type: ResourceType,
  data: ResourceVariantForm,
): Promise<ResourceVariantModel> {
  const baseUrl = ResourcesBaseUrlBuilder.getResourcesBaseUrl(databaseId, type);
  return CompanyResourcesApi.put(`${baseUrl}/${resourceId}/variants/${resourceVariantId}`, data);
}

function  createResourceVariant(
  databaseId: number,
  resourceId: number,
  type: ResourceType,
  data: ResourceVariantForm,
): Promise<ResourceVariantModel> {
  const baseUrl = ResourcesBaseUrlBuilder.getResourcesBaseUrl(databaseId, type);
  return CompanyResourcesApi.post(`${baseUrl}/${resourceId}/variants`, data);
}

function deleteResourceVariant(
  databaseId: number,
  resourceId: number,
  resourceVariantId: number,
  type: ResourceType,
): Promise<{}> {
  const baseUrl = ResourcesBaseUrlBuilder.getResourcesBaseUrl(databaseId, type);
  return CompanyResourcesApi.delete(`${baseUrl}/${resourceId}/variants/${resourceVariantId}`, null);
}


export const ResourceVariantsApi = {
  updateResourceVariant,
  createResourceVariant,
  deleteResourceVariant,
  getResourceVariant,
  getResourcesVariants,
};
