import * as React from 'react';

export const KreoIconNwResize: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          // eslint-disable-next-line max-len
          d='M4.70710678,4 L10,9.29289322 L10,8 C10,7.72385763 10.2238576,7.5 10.5,7.5 C10.7761424,7.5 11,7.72385763 11,8 L11,10.5 C11,10.7761424 10.7761424,11 10.5,11 L8,11 C7.72385763,11 7.5,10.7761424 7.5,10.5 C7.5,10.2238576 7.72385763,10 8,10 L9.29289322,10 L4,4.70710678 L4,6 C4,6.27614237 3.77614237,6.5 3.5,6.5 C3.22385763,6.5 3,6.27614237 3,6 L3,3.5 C3,3.22385763 3.22385763,3 3.5,3 L6,3 C6.27614237,3 6.5,3.22385763 6.5,3.5 C6.5,3.77614237 6.27614237,4 6,4 L4.70710678,4 Z'
          id='kreo-icon-nw-resize'
        />
      </defs>
      <g id='nw-resize' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <mask id='mask-2' fill='white'>
          <use xlinkHref='#kreo-icon-nw-resize' />
        </mask>
        <use id='Mask' fill='currentColor' fillRule='nonzero' xlinkHref='#kreo-icon-nw-resize' />
      </g>

      <g
        id='nw-resize-hover'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        opacity='0'
      >
        <path d='M11.5,11.5 L2.5,2.5' id='Path-17' stroke='currentColor' fillRule='nonzero' />
        <polyline
          id='Path-16'
          stroke='currentColor'
          fillRule='nonzero'
          points='11.5 8 11.5 11.5 8 11.5'
        />
        <polyline id='Path-16' stroke='currentColor' fillRule='nonzero' points='6 2.5 2.5 2.5 2.5 6' />
      </g>
    </svg>
  );
};
