import autobind from 'autobind-decorator';
import classNames from 'classnames';
import pluralize from 'pluralize';
import * as React from 'react';

import './lazy-loading-filter-select.scss';

import { RequestStatus } from 'common/enums/request-status';
import { Feed } from 'common/interfaces/feed';
import { KreoIconFilterNormal } from 'common/UIKit';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DefaultOptions } from '../filter-select/default-options';
import { FilterSelect } from '../filter-select/filter-select';
import { FilterOption, FilterValue } from '../filter-select/interfaces';

interface Props<T> {
  value: FilterValue[];
  entityName: string;
  onChangeSelectedValue: (value: FilterValue[]) => void;
  mapToFilterOption: (option: T) => FilterOption;
  load: (skip: number, take: number, searchQuery?: string) => Promise<Feed<T>>;
  batch: number;
}

interface State {
  options: FilterOption[];
  total: number;
  searchQuery: string;
  loadStatus: RequestStatus;
}

export class LazyLoadingFilterSelect<T> extends React.Component<Props<T>, State> {
  private deferredExecutor: DeferredExecutor = new DeferredExecutor(300);
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      loadStatus: RequestStatus.NotRequested,
      options: [],
      searchQuery: null,
      total: 0,
    };
  }

  public componentDidUpdate(_: Props<T>, prevState: State): void {
    if (prevState.searchQuery !== this.state.searchQuery) {
      this.deferredExecutor.execute(() => {
        this.loadOptions(0, this.props.batch, this.state.searchQuery);
      });
    }
  }

  public render(): JSX.Element {
    const { value, entityName } = this.props;
    const selectedAll = value.length === 0;
    const selectedValue = !selectedAll
      ? value
      : [
        {
          value: null,
          name: DefaultOptions.getSelectAllOption(this.props.entityName).name.toString(),
        },
      ];
    const className = classNames(
      { selected: !selectedAll },
      'lazy-loading-filter-select',
    );
    const options = [...this.state.options];

    if (!options.length || this.state.loadStatus === RequestStatus.Loading) {
      options.push(DefaultOptions.getSpinnerOption);
    }

    return (
      <FilterSelect
        value={selectedValue}
        entityName={entityName}
        options={options}
        onChangeSelectedValue={this.onChange}
        className={className}
        selectTitle={this.getSelectTitle()}
        compare={this.compare}
        onOpen={this.onOpen}
        onSearchQueryChange={this.onSearchQueryChange}
        onScroll={this.onScroll}
      />
    );
  }

  @autobind
  private onScroll(event: React.UIEvent<HTMLDivElement>): void {
    const state = this.state;
    const loadedCount = state.options.length;
    const {
      currentTarget: { scrollTop, scrollHeight, clientHeight },
    } = event;

    if (
      state.loadStatus !== RequestStatus.Loading &&
      clientHeight + scrollTop + 200 >= scrollHeight &&
      loadedCount < state.total
    ) {
      this.loadOptions(loadedCount, this.props.batch, state.searchQuery);
    }
  }

  @autobind
  private onSearchQueryChange(searchQuery: string): void {
    this.setState({ searchQuery });
  }

  @autobind
  private loadOptions(skip: number, take: number, search: string): void {
    this.setState(
      (state) =>
        ({
          options: skip > 0 ? state.options : [],
          loadStatus: RequestStatus.Loading,
        }),
      () => {
        this.load(skip, take, search).then(result => {
          this.setState((state) => ({
            options: state.options.concat(result.data),
            loadStatus: RequestStatus.Loaded,
            total: result.count,
          }));
        });
      },
    );
  }

  @autobind
  private onChange(value: FilterValue[]): void {
    let newValue = value;
    if (value.includes(null)) {
      if (this.props.value.length) {
        newValue = [];
      } else {
        newValue = value.filter(x => x !== null);
      }
    }
    this.props.onChangeSelectedValue(newValue);
  }

  @autobind
  private onOpen(): void {
    this.loadOptions(0, this.props.batch, this.state.searchQuery);
  }

  private compare(): boolean {
    return true;
  }

  @autobind
  private getSelectTitle(): JSX.Element {
    const selectedAll = !this.props.value.length;
    return (
      <div className='filter-select'>
        {selectedAll
          ? `All ${pluralize.plural(this.props.entityName)}`
          : pluralize(this.props.entityName, this.props.value.length, true)}
        <KreoIconFilterNormal />
      </div>
    );
  }

  @autobind
  private mapToFilterOption(option: T): FilterOption {
    const filterOption = this.props.mapToFilterOption(option);
    return filterOption;
  }

  @autobind
  private load(skip: number, take: number, searchQuery?: string): Promise<Feed<FilterOption>> {
    return this.props.load(skip, take, searchQuery).then(response => {
      const result: FilterOption[] = [];
      if (skip === 0) {
        result.push(DefaultOptions.getSelectAllOption(this.props.entityName));
      }

      return {
        count: response.count,
        skip: response.skip,
        data: result.concat(response.data.map(this.mapToFilterOption)),
      };
    });
  }
}
