import { NumericDictionary } from 'lodash';
import { Direction, GraphItemDisplayType } from './enums';
import { GraphNode } from './interfaces';

export const VertexDisplayTypeMapBuilder = {
  getVertexDisplayTypeMap: (rootNode: GraphNode): NumericDictionary<GraphItemDisplayType> =>  {
    const result = {
      [rootNode.id]: GraphItemDisplayType.Active,
    };
    rootNode[Direction.Back].forEach(x => {
      result[x.id] = GraphItemDisplayType.Previous;
    });
    rootNode[Direction.Forward].forEach(x => {
      result[x.id] = GraphItemDisplayType.Next;
    });
    // rootNode[LimitationType.StartStart].forEach(x => {
    //   result[x.id] = GraphItemDisplayType.Next;
    // });

    return result;
  },
};
