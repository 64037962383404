import { useCallback } from 'react';

import { Item, Property } from 'unit-2d-database/interfaces';
import { AssemblyForm, ItemUpdater } from '../../assembly-panel/item-form-updater';
import { mapItemGroupForm } from '../../assembly-panel/map-items-to-form';
import { FormulaDialogData } from '../../formula-dialog';
import { handleItemFormulaFieldClick } from '../helpers';
import { handleItemBreakdownFieldClick } from '../helpers/handle-item-breakdown-field-click';

type SelectItemsCallback = (
  updater: ItemUpdater,
  units: string[],
  getItemsDatabase: () => Item[],
  getPropertiesDatabase: () => Property[],
  form: AssemblyForm,
  openDialog: (data: FormulaDialogData) => void,
  openBreakdownDialog: (data: any) => void,
) => (ids: string[]) => void;

export const useSelectItemsCallback: SelectItemsCallback = (
  updater,
  units,
  getItemsDatabase,
  getPropertiesDatabase,
  form,
  openDialog,
  openBreakdownDialog,
) => {
  return useCallback((ids) => {
    const idsSet = new Set(ids);
    const itemsDatabase = getItemsDatabase();
    const propertiesDatabase = getPropertiesDatabase();
    const items = itemsDatabase.filter(i => idsSet.has(i.id));
    const handleClick = handleItemFormulaFieldClick(
      form,
      updater,
      openDialog,
      propertiesDatabase,
      units,
    );
    const handleBBClick = handleItemBreakdownFieldClick(
      form,
      updater,
      openBreakdownDialog,
    );
    const groupsForm = mapItemGroupForm(
      items,
      updater,
      units,
      handleClick,
      handleBBClick,
      propertiesDatabase,
      itemsDatabase,
      item => item.name,
      property => property.name,
    );
    updater.updateAssemblyForm(groupsForm);
  }, []);
};
