import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'redux-form';

import './database-activity-variant.scss';

import { State } from 'common/interfaces/state';
import {
  MaterialInputField,
  MaterialInputProps,
  MaterialNumberInput,
  MaterialNumberInputField,
  MaterialNumberInputProps,
} from 'common/UIKit';
import {
  ActivityVariantMaterialBasedCrewHoursModel,
  ActivityVariantModel,
  ActivityVariantResource,
  ActivityVariantType,
  ConstraintModel,
  ExtractorFunctionModel,
  MeasurementModel,
  MeasurementType,
  UnitModel,
} from '../../interfaces/data';
import { MaterialModel } from '../../interfaces/resources-data';
import { ExtractorParameterForm } from '../../interfaces/rest-data';
import { DatabaseCostCalculator } from '../../utils/database-cost-calculator';
import { ConstraintsProps, DatabaseActivityVariantConstraints } from './database-activity-variant-constraints';
import { DatabaseActivityVariantMaterials, MaterialsProps } from './database-activity-variant-materials';
import { DatabaseActivityVariantMeasurementItem } from './database-activity-variant-measurement-item';
import { DatabaseActivityVariantResourceBasedCrewHours } from './database-activity-variant-resource-based-crew-hours';
import { DatabaseActivityVariantType } from './database-activity-variant-type';


interface ReduxProps {
  measurementsMap: Record<string, MeasurementModel>;
  unitMap: Record<number, UnitModel>;
}

interface Props extends ReduxProps {
  fieldName?: string;
  readonly: boolean;
  activityVariant: ActivityVariantModel;
  onChange: (variant: ActivityVariantModel) => void;
}

interface OwnState {
  isNameChangedManually: boolean;
}

class DatabaseActivityVariantComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isNameChangedManually: !!this.props.activityVariant.name,
    };
  }

  public render(): React.ReactNode {
    const isReadonly = this.props.readonly;

    return (
      <div className='activity-variant-right-panel'>
        <Field<MaterialInputProps>
          name={this.nestedFieldName('name')}
          component={MaterialInputField}
          className='activity-variant-right-panel__name'
          value={this.props.activityVariant.name}
          label='Name'
          multiLine={true}
          onChange={this.onNameChange}
          onBlur={this.onNameUpdate}
          displayBottomInformation={true}
          disabled={isReadonly}
        />
        <DatabaseActivityVariantType
          fieldKey={this.nestedFieldName('type')}
          onChange={this.onTypeChange}
          readonly={isReadonly}
          type={this.props.activityVariant.type}
        />
        {this.props.activityVariant.type === ActivityVariantType.FixedCrewHours ? (
          <Field<MaterialNumberInputProps>
            name={this.nestedFieldName('crewHours')}
            component={MaterialNumberInputField}
            className='activity-variant-right-panel__gang-hours'
            value={this.props.activityVariant.crewHours}
            valueType='float'
            isFloatingLabel={false}
            onChange={this.onGangHoursChange}
            precision={2}
            label='Gang Hours'
            placeholder='0.00'
            disabled={isReadonly}
            displayBottomInformation={true}
          />
        ) : (
          <MaterialNumberInput
            className='activity-variant-right-panel__total-gang-hours'
            value={DatabaseCostCalculator.getCrewHours(this.props.activityVariant)}
            valueType='float'
            isFloatingLabel={false}
            precision={2}
            label='Total Gang Hours'
            placeholder='0.00'
            disabled={true}
          />
        )}
        <DatabaseActivityVariantMeasurementItem
          fieldName={this.props.fieldName}
          extractorFunction={this.props.activityVariant.extractorFunction}
          extractorFunctionParameters={this.props.activityVariant.extractorParameters}
          unitId={this.props.activityVariant.unitId}
          onFunctionChange={this.onFunctionChange}
          onUnitChange={this.onUnitChange}
          onFunctionParametersChange={this.onFunctionParametersChange}
          readonly={isReadonly}
        />
        {this.props.activityVariant.type === ActivityVariantType.ResourceBasedCrewHours &&
          <DatabaseActivityVariantResourceBasedCrewHours
            fieldName={this.nestedFieldName('materialBasedCrewHours')}
            readonly={isReadonly}
            materialBasedCrewHours={this.props.activityVariant.materialBasedCrewHours}
            onChange={this.onMaterialBasedCrewHoursChange}
          />
        }
        <FieldArray<MaterialsProps>
          name={this.nestedFieldName('materials')}
          component={DatabaseActivityVariantMaterials}
          materials={this.props.activityVariant.materials}
          onChange={this.onMaterialsChange}
          readonly={isReadonly}
          unitMap={this.props.unitMap}
        />
        <FieldArray<ConstraintsProps>
          name={this.nestedFieldName('constraints')}
          component={DatabaseActivityVariantConstraints}
          constraints={this.props.activityVariant.constraints}
          onChange={this.onConstraintsChange}
          readonly={isReadonly}
        />
      </div>
    );
  }

  @autobind
  private nestedFieldName(fieldName: string): string {
    return this.props.fieldName
      ? `${this.props.fieldName}.${fieldName}`
      : fieldName;
  }

  @autobind
  private onTypeChange(type: ActivityVariantType): void {
    const changedModel = { ...this.props.activityVariant, type };
    if (type === ActivityVariantType.FixedCrewHours) {
      changedModel.crewHours = 0;
      changedModel.materialBasedCrewHours = null;
    } else {
      changedModel.crewHours = null;
      changedModel.materialBasedCrewHours = {
        primaryMaterial: {
          id: null,
          amount: null,
          unitId: null,
          crewHours: null,
          resource: null,
        },
        secondaryMaterial: {
          id: null,
          amount: null,
          unitId: null,
          crewHours: null,
          resource: null,
        },
        primaryPercentage: 1,
      };
    }
    this.props.onChange(changedModel);
  }

  @autobind
  private onNameChange(_: React.ChangeEvent, name: string): void {
    if (!this.state.isNameChangedManually) {
      this.setState({ isNameChangedManually: true });
    }
    this.props.onChange({
      ...this.props.activityVariant,
      name,
    });
  }

  @autobind
  private onNameUpdate(_: React.ChangeEvent, value: string): void {
    const name = value.trim();
    this.props.onChange({
      ...this.props.activityVariant,
      name,
    });
  }

  @autobind
  private onGangHoursChange(_: React.ChangeEvent, crewHours: number | null): void {
    this.props.onChange({
      ...this.props.activityVariant,
      crewHours,
    });
  }

  @autobind
  private onMaterialsChange(materials: Array<ActivityVariantResource<MaterialModel>>): void {
    this.props.onChange({
      ...this.props.activityVariant,
      materials,
    });
  }

  @autobind
  private onUnitChange(unitId: number): void {
    this.props.onChange({
      ...this.props.activityVariant,
      unitId,
    });
  }

  @autobind
  private onMaterialBasedCrewHoursChange(materialBasedCrewHours: ActivityVariantMaterialBasedCrewHoursModel): void {
    this.props.onChange({
      ...this.props.activityVariant,
      materialBasedCrewHours,
    });
  }

  @autobind
  private onFunctionParametersChange(extractorParameters: ExtractorParameterForm[]): void {
    this.props.onChange({
      ...this.props.activityVariant,
      extractorParameters,
    });
  }

  @autobind
  private onFunctionChange(extractorFunction: ExtractorFunctionModel): void {
    this.props.onChange({
      ...this.props.activityVariant,
      extractorFunction: extractorFunction.id,
      unitId: null,
      extractorParameters: extractorFunction.extractorParameters,
    });
  }

  @autobind
  private onConstraintsChange(constraints: ConstraintModel[]): void {
    const name = this.state.isNameChangedManually
      ? this.props.activityVariant.name
      : this.getNameFromConstraints(constraints);

    this.props.onChange({
      ...this.props.activityVariant,
      name,
      constraints,
    });
  }

  private getNameFromConstraints(constraints: ConstraintModel[]): string {
    if (!constraints || !constraints.length) {
      return null;
    }

    return constraints
      .filter(x => x.operation && (x.value >= 0 && x.unitId || x.stringValue))
      .map(this.getConstraintNameForVariantName)
      .join(', ');
  }

  @autobind
  private getConstraintNameForVariantName(constraint: ConstraintModel): string {
    const { measurementsMap, unitMap } = this.props;
    const unit = unitMap[constraint.unitId];
    const measurement = measurementsMap[unit.measurement];
    if (measurement.name  === 'probability') {
      const extractorFunctionNameParts = constraint.extractorFunction.match(/(.+)\.(.+)/);
      return `${extractorFunctionNameParts[1]} is ${extractorFunctionNameParts[2]}`;
    } else if (measurement.type === MeasurementType.String) {
      return `${constraint.operation} ${constraint.stringValue}`;
    } else {
      return `${constraint.operation} ${constraint.value} ${unit.acronym}`;
    }
  }
}

const mapStateToProps = (state: State): ReduxProps => {
  return {
    measurementsMap: state.database.currentDatabase.measurementsMap,
    unitMap: state.database.currentDatabase.unitMap,
  };
};


export const DatabaseActivityVariant = connect(mapStateToProps)(DatabaseActivityVariantComponent);
