import * as React from 'react';
import { useSelector } from 'react-redux';

import { useSnappingFullApi } from 'common/components/drawings/hooks/settings/use-snappings-settings';
import { State } from 'common/interfaces/state';
import { SettingsSwitcherWithBlock } from '../setting-parameter';
import { DrawingsSnappingModesSelection } from './snapping-modes-selection';


export const SnappingSettingsComponents: React.FC = () => {
  const {
    toggleOrthogonalMode,
    toggleSnapping,
    isSnappingEnabled,
    snappingModes,
    setSnappingModes,
  } = useSnappingFullApi();
  const isSnappingBlocked = useSelector<State, boolean>(state => state.drawings.currentDrawingInfo?.isSnappingBlocked);
  const isOptimized = useSelector<State, boolean>(state => state.drawings.currentDrawingInfo?.isOptimized);

  return (
    <>
      <SettingsSwitcherWithBlock
        toggle={toggleSnapping}
        label='Enable snapping'
        checked={isSnappingEnabled}
        blocked={isSnappingBlocked || isOptimized}
        blockedText='Ohhh. Snapping can not be displayed on this drawing.'
      />
      <DrawingsSnappingModesSelection
        selectedModes={snappingModes}
        isOptimized={isOptimized}
        enabled={isSnappingEnabled}
        selectionChanged={setSnappingModes}
        toggleOrthogonalMode={toggleOrthogonalMode}
      />
    </>
  );
};


export const SnappingSettings = React.memo(SnappingSettingsComponents);
