import styled from 'styled-components';

const CreateButton = styled.div<{ isReports: boolean, isAddNewSheetLoading: boolean }>`
  position: relative;
  z-index: 10;
  background-color: ${p => p.theme.color.backgroundRush};
  border-left: ${p => p.isReports ? 0 : 1}px solid ${p => p.theme.color.background};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  overflow: hidden;

  > button {
    width: ${p => p.isReports ? '36px' : 'auto'};
    box-shadow: none;
    border-radius: 0;
    background-color: ${p => p.theme.color.backgroundRush};

    > p {
      font-size: 14px;
      line-height: 21px;
      margin-left: 15px;
    }
    > svg {
      margin-right: 0;
      padding-left: ${p => p.isReports ? 10 : 0}px;
      min-width: 20px;
      fill: ${p => p.isAddNewSheetLoading ? p.theme.color.turquoise : p.theme.color.gray}
    }

    :hover {
      transform: none;
      box-shadow: none;
    }

    :active {
      box-shadow: ${props => props.theme.shadow.m};
      transform: scale(0.95) translateY(2px);
    }
  }
`;

export const Styled = {
  CreateButton,
};
