import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './bell-badge.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { KreoIconNotification } from 'common/UIKit';
import { NotificationActions } from '../../actions';

interface ReduxProps {
  unreadNotificationsCount: number;
}

interface ReduxActions {
  showNotificationPanel: () => void;
}

interface Props extends ReduxProps, ReduxActions {}

class BellBadgeComponent extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { unreadNotificationsCount, showNotificationPanel } = this.props;

    return (
      <div className='bell-badge' onClick={showNotificationPanel}>
        <KreoIconNotification />
        {unreadNotificationsCount ? (
          <div className='bell-badge__counter'>{unreadNotificationsCount}</div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    unreadNotificationsCount: state.notifications.notifications.filter(x => !x.isRead).length,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    showNotificationPanel: () => dispatch(NotificationActions.showNotificationPanel(true)),
  };
};

export const BellBadge = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BellBadgeComponent);
