import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import { TMarker } from '../../../../../../../icons';
import { AnimationSpeed } from '../../../enums/animation-speed';

interface PointerProps {
  /**
   * @description Current moment in days since project start.
   */
  currentMoment: number;
  leftPanelWidth: number;
  width: number;
  timeframeStartDay: number;
  timeframeDuration: number;
  isPlaying: boolean;
  nextCurrentMoment: number;
  play: () => void;
  pause: () => void;
  updateCurrentMoment: (currentMoment: number, force?: boolean) => void;
  updateNextCurrentMoment: (nextCurrentMoment: number) => void;
  setAnimationSpeed: (animationSpeed: AnimationSpeed) => void;
}

interface PointerState {
  viewportWidth: number;
  pixelsPerDay: number;
  dragStartCursorOffset: number;
  wasPlaying: boolean;
}

export class TimePointer extends React.PureComponent<PointerProps, PointerState> {
  // dirty hack to make cursor behavior consistent. Styles are in main.scss
  private bodyDraggingCursorClassName: string = 'cursor-grabbing';

  constructor(props: PointerProps) {
    super(props);

    const derivedState = TimePointer.getDerivedStateFromProps(props);

    this.state = {
      dragStartCursorOffset: 0,
      wasPlaying: false,
      viewportWidth: derivedState.viewportWidth,
      pixelsPerDay: derivedState.pixelsPerDay,
    };
  }

  public static getDerivedStateFromProps(nextProps: PointerProps): Partial<PointerState> {
    const viewportWidth = nextProps.width - nextProps.leftPanelWidth;
    return {
      viewportWidth,
      pixelsPerDay: viewportWidth / nextProps.timeframeDuration,
    };
  }

  public render(): JSX.Element {
    const currentMoment = this.props.nextCurrentMoment !== null
      ? this.props.nextCurrentMoment
      : this.props.currentMoment;
    if (
      currentMoment < this.props.timeframeStartDay
      // || this.props.currentMoment > (this.props.daysTillFrameStart + this.props.timeframeDuration)
    ) {
      return null;
    }

    let left = 0;

    const className = classNames(
      'time-pointer',
      {
        dragged: !!this.props.nextCurrentMoment,
      });

    const daysOffset = currentMoment - this.props.timeframeStartDay;
    const offsetFromChartBodyEdge = daysOffset * this.state.pixelsPerDay;
    left = offsetFromChartBodyEdge + this.props.leftPanelWidth;

    return (
      <div
        className={className}
        style={{ transform: `translateX(${left}px)` }}
      >
        <img
          className='time-pointer__icon'
          src={TMarker}
          onMouseDown={this.onMouseDown}
        />
      </div>
    );
  }

  @autobind
  private onMouseDown(event: React.MouseEvent<HTMLImageElement>): void {
    if (event.button !== 0) {
      return;
    }

    event.preventDefault();

    this.props.setAnimationSpeed(AnimationSpeed.None);
    this.props.updateNextCurrentMoment(this.props.currentMoment);
    this.setState({
      dragStartCursorOffset: event.pageX,
      wasPlaying: this.props.isPlaying,
    });
    if (this.props.isPlaying) {
      this.props.pause();
    }
    this.addListeners();
    this.addCursorStyle();
  }

  @autobind
  private onMouseUp(): void {
    this.props.setAnimationSpeed(AnimationSpeed.Regular);
    this.props.updateCurrentMoment(this.props.nextCurrentMoment);
    this.props.updateNextCurrentMoment(null);
    if (this.state.wasPlaying) {
      this.props.play();
    }
    this.setState({
      dragStartCursorOffset: 0,
      wasPlaying: false,
    });
    this.removeListeners();
    this.removeCursorStyle();
  }

  @autobind
  private onMouseMove(event: React.MouseEvent<HTMLDocument>): void {
    if (this.props.nextCurrentMoment !== null) {
      const dragDelta = event.pageX - this.state.dragStartCursorOffset;
      const daysDelta = dragDelta / this.state.pixelsPerDay;
      const nextCurrentMoment = this.props.currentMoment + daysDelta;

      this.props.updateNextCurrentMoment(nextCurrentMoment);
    }
  }

  private addListeners(): void {
    document.addEventListener('mousemove', this.onMouseMove as any);
    document.addEventListener('mouseup', this.onMouseUp as any);
  }

  private removeListeners(): void {
    document.removeEventListener('mousemove', this.onMouseMove as any);
    document.removeEventListener('mouseup', this.onMouseUp as any);
  }

  private addCursorStyle(): void {
    document.body.classList.add(this.bodyDraggingCursorClassName);
  }

  private removeCursorStyle(): void {
    document.body.classList.remove(this.bodyDraggingCursorClassName);
  }
}
