import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Text } from 'common/components/text';
import { Styled } from './styled';


interface Props {
  name: string;
  value: string;
}

export const InfoBlock: React.FC<Props> = ({ name, value }) => {
  return (
    <Styled.Container>
      <Styled.Icon>
        <Icons.DoneIcon2d/>
      </Styled.Icon>
      <Styled.TextContainer>
        <Text fontSize={14}>{name}</Text>
        <Styled.ValueContainer>
          <Text fontSize={12}>{value}</Text>
        </Styled.ValueContainer>
      </Styled.TextContainer>
    </Styled.Container>
  );
};
