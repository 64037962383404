import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import { CostsActions } from '../../units/projects/actions/creators/costs';
import { CostEstimateGroupingType } from '../../units/projects/enums/cost-estimate-grouping-type';
import { Row } from './row';

interface TableBodyOwnProps {
  data: any;
  projectName: any;
  isEditable: boolean;
  groupingType: CostEstimateGroupingType;
  includeDuration: boolean;
}

interface TableBodyDispatchProps {
  collapseExpand: (row: any) => void;
  changeField: (path: any, value: number) => void;
}

interface TableBodyProps extends TableBodyOwnProps, TableBodyDispatchProps { }

class TableBodyComp extends React.Component<TableBodyProps> {
  public render(): React.ReactNode {
    return (
      <div className='cost-estimate-table__body'>
        {
          this.rowsView()
        }
      </div>
    );
  }

  @autobind
  private rowsView(): any {
    return this.mountRows(this.props.data, 0);
  }

  @autobind
  private getRowCollapseHandler(row: any): () => void {
    return () => {
      this.props.collapseExpand(row);
    };
  }

  @autobind
  private getRowInputChangeHandler(row: any): (value: string, fieldName: string) => void {
    return (value: string, fieldName: string) => {
      this.changeField(row, fieldName, value);
    };
  }

  @autobind
  private mountRows(rows: any, level: any): any {
    const rows_list = [];
    rows.forEach((row: any): any => {
      const rowJsxElement = this.createRow(
        row,
        this.getRowCollapseHandler(row),
        this.getRowInputChangeHandler(row),
      );
      rows_list.push(rowJsxElement);
      if (row.expanded === true && row.childrens) {
        rows_list.push(this.mountRows(row.childrens, level + 1));
      }
    });

    return (rows_list);
  }

  @autobind
  private changeField(node: any, field: any, value: any): void {
    const path = node.identificator.split('.');
    path.push(field);
    this.props.changeField(path, parseFloat(value));
  }

  @autobind
  private createRow(
    rowElement: any,
    collapseHandler: () => void,
    inputChangeHandler: (value: string, fieldName: string) => void,
  ): JSX.Element {
    if (rowElement === undefined) {
      return;
    }

    return (
      <Row
        key={rowElement.identificator}
        projectName={this.props.projectName}
        path={rowElement.identificator}
        isEditable={!!this.props.isEditable}
        groupingType={this.props.groupingType}
        changeField={inputChangeHandler}
        onCollapse={collapseHandler}
        includeDuration={this.props.includeDuration}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): TableBodyDispatchProps => {
  return {
    changeField: (path, value) => {
      dispatch(CostsActions.changeField(path, value));
    },
    collapseExpand: (item) => {
      dispatch(CostsActions.collapseExpand(item));
    },
  };
};

export const TableBody =
  connect<void, TableBodyDispatchProps, TableBodyOwnProps>(null, mapDispatchToProps)(TableBodyComp);
