import { Text, Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


export const NotPinnedFoldersHeader: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.PinIcon>
        <Icons.SmallAll />
      </Styled.PinIcon>
      <Text fontSize={10}>
        ALL
      </Text>
    </Styled.Container>
  );
};
