import { Icons, SvgComponent } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { RenderIf } from 'common/components/render-if';
import { Breadcrumb, BreadcrumbItem } from './breadcrumb';
import { Styled } from './styled';

interface Props<T> {
  items: T[];
  Icon: SvgComponent;
  onClick: (item: T) => void;
}

export const Breadcrumbs = <T extends BreadcrumbItem>({ items, onClick, Icon }: Props<T>): JSX.Element => {
  const lastItemIndex = items.length - 1;
  return (
    <Styled.BreadcrumbsContainer>
      {items.map((item, index) => {
        const isLastItem = index === lastItemIndex;
        return (
          <Styled.Breadcrumb key={item.id}>
            <Breadcrumb item={item} onClick={onClick} isClickable={!isLastItem} Icon={Icon}/>
            <RenderIf condition={!isLastItem}>
              <Styled.Separator>
                <Icons.SmallArrow height={20} width={20}/>
              </Styled.Separator>
            </RenderIf>
          </Styled.Breadcrumb>
        );
      })}
    </Styled.BreadcrumbsContainer>
  );
};
