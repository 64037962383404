import autobind from 'autobind-decorator';
import { scaleLinear, ScaleLinear } from 'd3-scale';
import * as d3Select from 'd3-selection';
import moment from 'moment';
import * as React from 'react';

import './schedule.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { DATE_FORMAT, SHORT_DATE_FORMAT } from 'common/UIKit';
import { getOrCreateRoot } from 'common/UIKit/dialogs/';
import { getMonth } from '../../../../../components/gantt-chart/utils/formatDate';
import { GanttConstants } from '../../../constants/gantt';
import { DashboardUtil } from '../../../utils/dashboard-util';
import { ChartDataProvider, GroupType } from '../../../utils/gantt-chart';
import { GanttUtils } from '../../../utils/gantt-chart/gantt-utils';
import { FourDVisualisationApi } from '../../four-d-visualisation/api';
import { DaysPerWeek } from '../../four-d-visualisation/utils';
import { createTooltip, toggleTooltip } from './schedule-tooltip';


interface TimeDivision {
  name: string;
  start: number;
  duration: number;
}

interface Package {
  name: string;
  startDay: number;
  duration: number;
  color: string;
}

interface State {
  startDate: Date;
  endDate: Date;
  duration: number;
  packages: Package[];
}

interface Props {
  calculationId: number;
  projectId: number;
  companyId: number;
}


const percent = (value: number): string => `${value}%`;
const CHART_ID = 'schedule-chart';

export class ScheduleChart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      duration: null,
      packages: null,
    };
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.displayText);
  }

  public render(): JSX.Element {
    if (this.state.packages === null) {
      return <SvgSpinner size={'small'}/>;
    }
    return (
      <div className='schedule-component'>
        <div className='schedule-component__days'>{`${this.totalDaysCount} days`}</div>
        <div className='schedule-component__chart' id={CHART_ID} />
        <div className='schedule-component__time-line' />
      </div>
    );
  }

  public componentDidMount(): void {
    const { projectId, calculationId } = this.props;
    FourDVisualisationApi.getAnimationData(projectId, calculationId)
      .then(response => {
        const provider = new ChartDataProvider(response);
        const colors = DashboardUtil.getColors(response.workPackages);
        const packages = response.workPackages.map<Package>(x => {
          const info = provider.getGanttLineInfo(GroupType.WorkPackage, x.id);
          return {
            name: x.name,
            startDay: info.startDay,
            duration: provider.calculateFullWorkTime(GroupType.WorkPackage, x.id),
            color: colors.find(color => color.name === x.name).color,
          };
        }).sort((first, second) => first.startDay - second.startDay);
        const startDate = new Date(parseInt(response.startDatetime.substr(6), 10));
        const duration = provider.getTotalDuration();
        this.setState({
          startDate,
          packages,
          endDate: new Date(startDate.getTime() + duration * GanttConstants.msInDay),
          duration,
        });
      });
  }

  public componentDidUpdate(): void {
    if (this.state.packages) {
      this.draw();
    }
  }


  private get totalDaysCount(): number {
    const { startDate, endDate } = this.state;
    return GanttUtils.calculateTotalDaysDuration(endDate, startDate);
  }


  private draw(): void {
    const { packages, duration } = this.state;
    const width = 100;
    const height = 100;
    const xTask = scaleLinear()
      .domain([0, duration])
      .range([0, width]);
    const yTask = scaleLinear()
      .domain([0, packages.length])
      .range([0, height]);
    const chart = d3Select.select('.schedule-component__chart');
    chart.selectAll('div').remove();
    const getLeft = (lineData: Package): string => {
      return percent(xTask(lineData.startDay));
    };
    const getWidth = <T extends { duration: number }>(data: T): string =>  {
      return percent(xTask(data.duration));
    };
    const packageHeight = percent(100 / (packages.length + 5));
    // render tasks
    chart
      .append('div')
      .attr('class', 'schedule-component__chart-task-list')
      .selectAll('.schedule-component__chart-task')
      .data(packages)
      .enter()
      .append('div')
      .attr('class', 'schedule-component__chart-task')
      .style('width', getWidth)
      .style('height', packageHeight)
      .style('top', (_, i) => percent(yTask(i)))
      .style('left', getLeft)
      .style('background-color', this.getLineColor)
      .append('div')
      .attr('class', 'schedule-component__chart-task-name')
      .text(this.getName)
      .on('mouseenter', this.onChartMouseEnter)
      .on('mousemove', this.onMouseMove)
      .on('mouseleave', this.onMouseLeave);

    this.drawTimeLine(xTask, chart, getWidth);
    window.addEventListener('resize', this.displayText);
    this.displayText();
  }

  private drawTimeLine(
    xTask: ScaleLinear<number, number>,
    chart: d3Select.Selection<d3Select.BaseType, {}, HTMLElement, any>,
    getWidth: <T extends { duration: number }>(data: T) => string,
  ): void {
    const { startDate, endDate, duration } = this.state;
    const [weeks, months, years] = this.calculateScaleDivisions(startDate, endDate, duration);
    const getLeft = (division: TimeDivision): string => percent(xTask(division.start));
    chart
      .append('div')
      .attr('class', 'schedule-component__chart-weeks')
      .selectAll('.schedule-component__chart-week')
      .data(weeks)
      .enter()
      .append('div')
      .attr('class', 'schedule-component__chart-week')
      .style('left', getLeft)
      .style('width', getWidth)
      .on('mouseenter', this.onWeekMouseEnter)
      .on('mousemove', this.onMouseMove)
      .on('mouseleave', this.onMouseLeave);


    const timeLine = d3Select.select('.schedule-component__time-line');
    timeLine.selectAll('div').remove();
    // weeks
    timeLine
      .append('div')
      .attr('class', 'schedule-component__time-line-weeks')
      .selectAll('.schedule-component__time-line-week')
      .data(weeks)
      .enter()
      .append('div')
      .attr('class', 'schedule-component__time-line-week')
      .style('left', getLeft);

    // months
    timeLine
      .append('div')
      .attr('class', 'schedule-component__time-line-months')
      .selectAll('.schedule-component__time-line-month')
      .data(months)
      .enter()
      .append('div')
      .attr('class', 'schedule-component__time-line-month')
      .style('left', getLeft)
      .style('width', getWidth)
      .text(this.getName);

    // start and end date
    timeLine
      .append('div')
      .attr('class', 'schedule-component__time-line-start')
      .text(moment(startDate).format(DATE_FORMAT));
    timeLine
      .append('div')
      .attr('class', 'schedule-component__time-line-end')
      .text(moment(endDate).format(DATE_FORMAT));

    // years
    timeLine
      .append('div')
      .attr('class', 'schedule-component__time-line-years')
      .selectAll('.schedule-component__time-line-year')
      .data(years)
      .enter()
      .append('div')
      .attr('class', 'schedule-component__time-line-year')
      .style('left', getLeft)
      .style('width', getWidth)
      .text(this.getName);
  }


  private onMouseMove(): void {
    toggleTooltip(d3Select.event, true);
  }

  private onMouseLeave(): void {
    toggleTooltip(null, false);
  }

  private getName<T extends { name: string }>(visibleEntity: T): string {
    return visibleEntity.name;
  }

  private getLineColor(lineData: Package): string {
    return lineData.color;
  }

  @autobind
  private onChartMouseEnter(
    lineData: Package,
  ): void {
    const { startDate } = this.state;
    const modal = d3Select.select(getOrCreateRoot());
    const datesStartEnd = `
      Start: ${moment(startDate).add(lineData.startDay, 'days').format(DATE_FORMAT)}<br/>
      End: ${moment(startDate).add(lineData.startDay + lineData.duration, 'days').format(DATE_FORMAT)}
    `;
    createTooltip(modal, lineData.name, datesStartEnd);
    toggleTooltip(d3Select.event, true);
  }

  @autobind
  private onWeekMouseEnter(
    week: TimeDivision,
  ): void {
    const modal = d3Select.select(getOrCreateRoot());
    const start = this.state.startDate;
    const endDate = moment(start).add(week.start + week.duration, 'days');
    const startDate = moment(start).add(week.start, 'days');
    let period = '';
    if (
      startDate.month() === endDate.month() &&
      startDate.year() === endDate.year()
    ) {
      period = `${startDate.format('D')} – ${endDate.format(DATE_FORMAT)}`;
    } else if (startDate.year() === endDate.year()) {
      period = `${startDate.format(SHORT_DATE_FORMAT)} – ${endDate.format(DATE_FORMAT)}`;
    } else {
      period = `${startDate.format(DATE_FORMAT)} – ${endDate.format(DATE_FORMAT)}`;
    }
    createTooltip(modal, week.name, period);
    toggleTooltip(d3Select.event, true);
  }

  private calculateScaleDivisions(
    startDate: Date,
    endDate: Date,
    duration: number,
  ): [TimeDivision[], TimeDivision[], TimeDivision[]]  {
    const totalDaysCount = this.totalDaysCount;
    const weeksCount = Math.ceil(totalDaysCount / 7);
    const weeks = new Array<TimeDivision>(weeksCount);
    let weekStart = DaysPerWeek - startDate.getDay();
    weeks[0] = {
      start: 0,
      duration: weekStart,
      name: 'Week 1',
    };
    for (let i = 1; i < weeksCount; i++) {
      let weekActiveTime = totalDaysCount - weekStart;
      if (weekActiveTime > DaysPerWeek) {
        weekActiveTime = DaysPerWeek;
      }
      weeks[i] = {
        start: weekStart,
        duration: weekActiveTime,
        name: `Week ${i + 1}`,
      };
      weekStart += weekActiveTime;
    }
    const startMonth = startDate.getMonth();
    const finishMonth = endDate.getMonth();
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const monthsCount = finishMonth - startMonth + 12 * (endYear - startYear) + 1;
    const years = new Array<TimeDivision>(endYear - startYear + 1);
    const months: TimeDivision[] = new Array<TimeDivision>(monthsCount);
    let monthLeftPosition = 0;
    let yearLeftPosition = 0;
    if (startYear === endYear) {
      yearLeftPosition = duration;
      if (startMonth === finishMonth) {
        monthLeftPosition = duration;
      } else {
        monthLeftPosition =
          (new Date(startYear, startMonth + 1, 1).getTime() - startDate.getTime()) / GanttConstants.msInDay;
      }
    } else {
      monthLeftPosition =
        (new Date(startYear, startMonth + 1, 1).getTime() - startDate.getTime()) / GanttConstants.msInDay;
      yearLeftPosition = (new Date(startYear + 1, 0, 1).getTime() - startDate.getTime()) / GanttConstants.msInDay;
    }
    months[0] = {
      start: 0,
      duration: monthLeftPosition,
      name: getMonth(startDate),
    };
    let filledMonths = 1;
    years[0] = {
      start: 0,
      duration: yearLeftPosition,
      name: startYear.toString(),
    };
    if (monthsCount > 1) {
      const lastMonthInCurrentYear = startYear === endYear ? finishMonth : 11;
      for (let i = startMonth + 1; i <= lastMonthInCurrentYear; i++) {
        const date = new Date(startYear, i, 1);
        const monthDuration =
          (new Date(startYear, i + 1, 1).getTime() - date.getTime()) / GanttConstants.msInDay;
        months[filledMonths] = {
          start: monthLeftPosition,
          name: getMonth(date),
          duration: monthDuration,
        };
        monthLeftPosition += monthDuration;
        filledMonths++;
      }
    }
    for (let i = 1; i < endYear - startYear; i++) {
      const currentYear = i + startYear;
      const yearActiveTime =
        (new Date(currentYear + 1, 0, 1).getTime() - new Date(currentYear, 0, 1).getTime()) / GanttConstants.msInDay;
      years[i] = {
        start: yearLeftPosition,
        duration: yearActiveTime,
        name: currentYear.toString(),
      };
      for (let j = 0; j < 12; j++) {
        const date = new Date(currentYear, j, 1);
        const monthDuration = (new Date(currentYear, j + 1, 1).getTime() - date.getTime()) / GanttConstants.msInDay;
        months[filledMonths] = {
          start: monthLeftPosition,
          name: getMonth(date),
          duration: monthDuration,
        };
        filledMonths++;
        monthLeftPosition += monthDuration;
      }
      yearLeftPosition = monthLeftPosition;
    }
    for (let i = 0; i < monthsCount - filledMonths; i++) {
      const date = new Date(endYear, i, 1);
      const monthDuration = (new Date(endYear, i + 1, 1).getTime() - date.getTime()) / GanttConstants.msInDay;
      months[i + filledMonths] = {
        start: monthLeftPosition,
        name: getMonth(date),
        duration: monthDuration,
      };
      monthLeftPosition += monthDuration;
    }
    years[years.length - 1] = {
      start: yearLeftPosition,
      duration: duration - yearLeftPosition,
      name: endYear.toString(),
    };
    return [weeks, months, years];
  }

  @autobind
  private displayText(): void {
    const changeOpacity = function(): string {
      if (this.offsetWidth >= this.parentElement.offsetWidth) {
        return '0';
      }
      return '1';
    };

    setTimeout(
      () => {
        d3Select.select(`#${CHART_ID}`)
          .selectAll('.schedule-component__chart-task-name')
          .style('opacity', changeOpacity);
      },
      300);
  }
}
