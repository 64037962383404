import React from 'react';
import { useSelector } from 'react-redux';
import { Property } from 'common/components/property';
import { State } from 'common/interfaces/state';
import { UnitTypes } from 'common/utils/unit-util';
import { MeasurementInput } from '../../../measurement-input';
import { AllowedKeys } from './interfaces';
import { MeasurePropertyWithFilter } from './measure-property-with-filter';
import { useChangeProperty } from './use-change-property';

interface Props {
  values: Record<string, string[]>;
  valueKey: AllowedKeys;
  canEdit: boolean;
  name: string;
  ignoreCalculation?: boolean;
}

const MeasurePropertyComponent: React.FC<Props> = ({ values, valueKey, canEdit, name, ignoreCalculation }) => {
  const onChange = useChangeProperty(valueKey, values, ignoreCalculation);
  const isImperial = useSelector<State, boolean>(s => s.account.settings.isImperial);
  if (Object.keys(values).length > 1) {
    return (
      <MeasurePropertyWithFilter
        values={values}
        canEdit={canEdit}
        onChange={onChange}
        name={name}
        isImperial={isImperial}
      />
    );
  } else {
    const value = Object.keys(values)[0];
    return (
      <Property title={name}>
        <MeasurementInput
          value={value !== 'undefined' ? Number(value) : undefined}
          onChange={onChange}
          unit={UnitTypes.M}
          readonly={!canEdit}
        />
      </Property>
    );
  }
};

export const MeasureProperty = React.memo(MeasurePropertyComponent);
