import * as React from 'react';
import { connect } from 'react-redux';

import { State } from '../../../../common/interfaces/state';
import { ModalRootWrapper } from '../modal-root-wrapper';


interface StateProps {
  open: boolean;
}

interface OwnProps {
  name: string;
  onClose?: () => void;
  dialogAnimationDelay?: number;
  children?: React.ReactNode;
}

interface Props extends StateProps, OwnProps {
}

export class DialogWrapperComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    if (!this.props.open) {
      return null;
    }
    return (
      <ModalRootWrapper>
        {this.props.children}
      </ModalRootWrapper>
    );
  }
}


const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  return {
    open: ownProps.name in state.dialog,
  };
};

const connecter = connect(mapStateToProps);
export const DialogWrapper = connecter(DialogWrapperComponent);
