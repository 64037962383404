import { Icons, Waves } from '@kreo/kreo-ui-components';
import React, { useCallback, useState } from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { Text } from '../text';
import { Tooltip } from '../tooltip';
import { Styled } from './styled';


interface TooltipElement {
  text: string;
  targetElement: DOMRect;
  position: 'top' | 'bottom' | 'left' | 'right';
  speed: 's' | 'm' | 'l' | 'xl';
  description: string;
}

interface Props {
  Icon: React.ComponentType;
  text: string;
  textColor: string;
  fontSize: number;
  withBorder: boolean;
  size: string;
  disabled: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  subText?: string;
  subTextColor?: string;
  img?: string;
  helpToolTip?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  withoutWaves?: boolean;
  fontWeight?: number;
  badge?: JSX.Element;
}

export const MenuItem: React.FC<Props> = ({
  Icon,
  text,
  textColor = 'gray',
  subText,
  subTextColor,
  fontSize,
  withBorder,
  size = 'l',
  onClick,
  img,
  helpToolTip,
  disabled,
  tooltipPosition,
  withoutWaves,
  fontWeight,
  badge,
}) => {

  const [tooltip, setTooltip] = useState<TooltipElement>(null);

  const onHandleClick = useCallback((e) => {
    ConstantFunctions.stopPropagation(e);
    onClick(e);
  }, [onClick]);

  const onMouseEnter = useCallback((e) => {
    setTooltip({
      targetElement: e.currentTarget.getBoundingClientRect(),
      position: tooltipPosition || 'right',
      text: helpToolTip,
      speed: 's',
      description: '',
    });
  }, [helpToolTip, tooltipPosition]);

  const onMouseLeave = useCallback(() => {
    setTooltip(null);
  }, []);

  return (
    <Styled.Item
      onClick={onHandleClick}
      withBorder={withBorder}
      size={size}
      disabled={disabled}
      isBadge={!!badge}
    >
      <Styled.IconWithText>
        {img ? <img src={img} alt='' /> : Icon && <Icon />}
        <Text
          color={disabled ? 'disabled' : textColor}
          fontSize={fontSize}
        >
          {text}
        </Text>
      </Styled.IconWithText>
      <Styled.SubText isBadge={!!badge}>
        <Text
          fontWeight={fontWeight}
          color={subTextColor}
          fontSize={fontSize}
        >
          {subText}
        </Text>
      </Styled.SubText>
      {helpToolTip && (
        <Styled.TooltipContainer
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Icons.QuestionS/>
        </Styled.TooltipContainer>
      )}
      {!withoutWaves ? <Waves /> : null}
      {tooltip && <Tooltip
        position={tooltip.position}
        targetTop={tooltip.targetElement.top}
        targetBottom={tooltip.targetElement.bottom}
        targetLeft={tooltip.targetElement.left}
        targetRight={tooltip.targetElement.right}
        targetWidth={tooltip.targetElement.width}
        targetHeight={tooltip.targetElement.height}
        text={tooltip.text}
        speed={tooltip.speed}
        description={tooltip.description}
      />
      }
      {badge ? badge : null}
    </Styled.Item>
  );
};
