import { orderBy, uniqBy } from 'lodash';
import moment from 'moment';
import React from 'react';

import { NumberDictionary, StringDictionary } from 'common/interfaces/dictionary';
import { SortingDirection } from 'common/interfaces/sorting-direction';
import { DATE_FORMAT } from 'common/UIKit';
import { activityTypes } from './constants';
import { FilterType } from './filter-type';
import { GroupedUserActivity, SimpleOption, Sorting, TimeInterval, UserActivity } from './interfaces';

function getFilterOptions(filterType: FilterType, activities: GroupedUserActivity[]): SimpleOption[] {
  let options = [];
  switch (filterType) {
    case FilterType.Project:
      options = activities.filter(a => a.document != null).map(a => a.document);
      break;
    case FilterType.People:
      options = activities.filter(a => a.user != null).map(a => {
        return {
          id: a.user.id,
          name: `${a.user.firstName} ${a.user.lastName}`,
        };
      });
      break;
    case FilterType.Role:
      options = activities.filter(a => a.role != null).map(a => a.role);
      break;
    case FilterType.Type:
      options = Object.keys(activityTypes).map(x => {
        return {
          id: x,
          name: activityTypes[x].text,
        };
      });
      break;
    default:
      return [];
  }

  return uniqBy(options, 'id');
}

function filterActivities(
  filter: NumberDictionary<SimpleOption[]>,
  timeInterval: TimeInterval,
  activities: GroupedUserActivity[],
): GroupedUserActivity[] {
  let filteredActivities = activities;

  if (timeInterval && timeInterval.start && timeInterval.end) {
    const start = moment(timeInterval.start);
    const end = moment(timeInterval.end);
    end.add(1, 'days');
    filteredActivities = filteredActivities.filter(
      a => moment(a.created).isSameOrAfter(start) && moment(a.created).isSameOrBefore(end),
    );
  }

  Object.keys(filter).map(t => {
    const type = parseInt(t, 10);
    const filters = filter[type];

    if (filters && filters.length > 0) {
      const filtersId = filters.map(x => x.id);
      switch (type) {
        case FilterType.Project:
          filteredActivities = filteredActivities.filter(
            a => a.document && filtersId.includes(a.document.id),
          );
          break;
        case FilterType.People:
          filteredActivities = filteredActivities.filter(
            a => a.user && filtersId.includes(a.user.id),
          );
          break;
        case FilterType.Role:
          filteredActivities = filteredActivities.filter(
            a => a.role && filtersId.includes(a.role.id),
          );
          break;
        case FilterType.Type:
          filteredActivities = filteredActivities.filter(a => filtersId.includes(a.relatedArea));
          break;
        default:
      }
    }
  });

  return filteredActivities;
}

function hasAnyFilter(
  filter: NumberDictionary<SimpleOption[]>,
  timeInterval: TimeInterval,
): boolean {
  return (
    (timeInterval && timeInterval.start && timeInterval.end !== null) ||
    Object.keys(filter)
      .map(type => (filter[type] ? filter[type].length > 0 : false))
      .includes(true)
  );
}

function displayInterval(timeInterval: TimeInterval): React.ReactNode {
  if (!timeInterval) {
    return null;
  }

  if (moment(timeInterval.start).isSame(timeInterval.end)) {
    return <b>{moment(timeInterval.start).format(DATE_FORMAT)}</b>;
  }
  return (
    <React.Fragment>
      <b>{moment(timeInterval.start).format(DATE_FORMAT)}</b>
      <span> – </span>
      <b>{moment(timeInterval.end).format(DATE_FORMAT)}</b>
    </React.Fragment>
  );
}

function sortActivities(activities: GroupedUserActivity[], sorting: Sorting): GroupedUserActivity[] {
  // sort by name, then by id, then by date
  return orderBy(
    activities,
    [
      x => {
        switch (sorting.type) {
          case FilterType.Date:
            return x.created;
          case FilterType.Project:
            return x.document && x.document.name;
          case FilterType.People:
            return x.user && `${x.user.firstName} ${x.user.lastName}`;
          case FilterType.Role:
            return x.role && x.role.name;
          case FilterType.Type:
            return activityTypes[x.relatedArea].text;
          default:
            return null;
        }
      },
      x => {
        switch (sorting.type) {
          case FilterType.Date:
            return x.created;
          case FilterType.Project:
            return x.document && x.document.id;
          case FilterType.People:
            return x.user && x.user.id;
          case FilterType.Role:
            return x.role && x.role.id;
          case FilterType.Type:
            return activityTypes[x.relatedArea].text;
          default:
            return null;
        }
      },
      x => x.created,
    ],
    [sorting.direction === SortingDirection.Ascending ? 'asc' : 'desc', 'asc', 'desc'],
  );
}

function groupActivities(activities: UserActivity[]): GroupedUserActivity[] {
  const groupedActivities: StringDictionary<GroupedUserActivity> = {};
  activities.forEach(activity => {
    const activityKey = getActivityGroupKey(activity);
    const existsGroup = groupedActivities[activityKey];
    if (existsGroup) {
      existsGroup.groupedActivities.push(activity);
    } else {
      groupedActivities[activityKey] = { ...activity, groupedActivities: [] };
    }
  });

  return Object.keys(groupedActivities).map(key => groupedActivities[key]);
}

function getActivityGroupKey(activity: GroupedUserActivity): string {
  return `${moment(activity.created).format('YYYY-MM-DD')}-${
    activity.user ? activity.user.id : 'empty'
  }-${activity.document ? activity.document.id : 'empty'}-${activity.message}`;
}

export const UserActivityUtil = {
  getFilterOptions,
  filterActivities,
  hasAnyFilter,
  displayInterval,
  sortActivities,
  groupActivities,
};
