import autobind from 'autobind-decorator';
import * as React from 'react';

import './custom-element-filter-builder-item-wrap.scss';

import { Checkbox, IconButton, KreoIconCancel } from 'common/UIKit';
import {
  CustomElementFilterConditionVlidatationResult,
} from '../../../units/projects/utils/quantity-take-off-tree-table/custom-element-filter-condition-validator';
import {
  CustomElementFilterBuilderMapper,
  CustomElementFilterBuilderTypeGuards,
} from '../../utils/custom-element-filter-builder';
import { Autocomplete, AutocompleteOption, AutocompleteOptionWithGroupName } from '../autocomplete';
import { CustomElementFilterBuilderNumberItem } from './custom-element-filter-builder-number-item';
import { CustomElementFilterBuilderStringItem } from './custom-element-filter-builder-string-item';
import { CustomElementFilterBuilderTreeNode } from './custom-element-filter-builder-tree-node';
import { CustomElementFilterBuilderWarningTooltip } from './custom-element-filter-builder-warning-tooltip';
import { FilterItemTypes } from './inner-enums';
import { CustomElementFilterItemType, CustomFilterBuilderAutocompleteOption } from './inner-interfaces';


interface Props {
  index: number;
  filterItem: CustomElementFilterItemType;
  filterKeyOptions: CustomFilterBuilderAutocompleteOption[];
  filterKeyToPredefineValues: Record<string, CustomFilterBuilderAutocompleteOption[]>;
  getCustomKeyFromName: (name: string) => string;
  getCustomNameFromKey: (key: string) => string;
  onChange: (index: number, item: CustomElementFilterItemType) => void;
  onRemove: (index: number) => void;
  isOnlyModelValues: boolean;
  conditionsValidationResult: CustomElementFilterConditionVlidatationResult;
}

interface State {
  filteredFilterKeyOptions: AutocompleteOption[];
}


export class CustomElementFilterBuilderItemWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { filterKeyOptions, isOnlyModelValues, filterItem } = props;
    const itemKey = this.getItemKey(filterItem);
    this.state = {
      filteredFilterKeyOptions: this.getFilteredFilterKeyOptions(filterKeyOptions, isOnlyModelValues, itemKey),
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    const { filterKeyOptions, isOnlyModelValues, filterItem } = this.props;
    if (prevProps.filterKeyOptions !== filterKeyOptions || prevProps.isOnlyModelValues !== isOnlyModelValues) {
      const itemKey = this.getItemKey(filterItem);
      const filteredFilterKeyOptions = this.getFilteredFilterKeyOptions(filterKeyOptions, isOnlyModelValues, itemKey);
      this.setState({ filteredFilterKeyOptions });
    }
  }

  public render(): JSX.Element {
    const {
      filterItem,
      conditionsValidationResult: { usedKeysMap, trueConditionIdsMap },
    } = this.props;
    const innerNodesCount = this.getInnerNodesCount();
    const itemKey = this.getItemKey(filterItem);
    const isFilterNode = this.isFilterNode();
    const isKeyUsed = itemKey in usedKeysMap && !usedKeysMap[itemKey];
    const isFalseCondition = filterItem.uniqueKey in trueConditionIdsMap && !trueConditionIdsMap[filterItem.uniqueKey];

    return (
      <div className={'custom-element-filter-builder-item-wrap'}>
        <IconButton
          className='custom-element-filter-builder-item-wrap__remove-button'
          size='small'
          onClick={this.onRemove}
        >
          <KreoIconCancel />
        </IconButton>
        <div className='custom-element-filter-builder-item-wrap__content'>
          {
            !isFilterNode ? (
              <React.Fragment>
                <Autocomplete
                  options={this.state.filteredFilterKeyOptions}
                  onChange={this.onKeyChange}
                  value={itemKey}
                  getCustomValuesFromName={this.getCustomOptionFromName}
                  getCustomValuesFromKey={this.getCustomOptionFromKey}
                />
                { isKeyUsed ? (
                  <CustomElementFilterBuilderWarningTooltip
                    text={'The key is not used in this model'}
                  />
                ) : null}
              </React.Fragment>
            ) : null
          }
          {
            itemKey || innerNodesCount ? (
              <div className='custom-element-filter-builder-item-wrap__inverse'>
                <Checkbox
                  checked={filterItem.inverse}
                  label='not'
                  onChange={this.onInverse}
                />
              </div>
            ) : null
          }
          {this.renderItem()}
          {
            isFalseCondition ? (
              <CustomElementFilterBuilderWarningTooltip
                text={'The result of the condition in this model is false'}
              />
            ) : null
          }
        </div>
      </div>
    );
  }

  @autobind
  private onRemove(): void {
    this.props.onRemove(this.props.index);
  }

  @autobind
  private onInverse(): void {
    const { filterItem } = this.props;
    this.onInnerItemChange({
      ...filterItem,
      inverse: !filterItem.inverse,
    });
  }

  @autobind
  private getCustomOptionFromName(name: string): AutocompleteOptionWithGroupName[] {
    return [{
      groupName: 'Custom',
      name,
      value: this.props.getCustomKeyFromName(name),
      isSelectable: true,
      type: FilterItemTypes.String,
    }];
  }

  @autobind
  private getCustomOptionFromKey(key: string): AutocompleteOptionWithGroupName {
    if (!key) {
      return null;
    }

    return {
      groupName: 'Custom',
      name: this.props.getCustomNameFromKey(key),
      value: key,
      isSelectable: true,
      type: FilterItemTypes.String,
    };
  }


  @autobind
  private onKeyChange(item: AutocompleteOption): void {
    const { filterItem } = this.props;

    if (this.getItemKey(filterItem) === item.value) {
      this.onInnerItemChange({
        ...filterItem,
        propertyKey: (filterItem as any).propertyKey && item.value,
        elementFilterId: (filterItem as any).elementFilterId && item.value,
      } as any);
    }

    const emptyItem = CustomElementFilterBuilderMapper.getEmptyFilterByKey(
      item.value.toString(),
      item.type as FilterItemTypes,
      filterItem.inverse,
      (filterItem as any).uniqueKey,
    );
    this.onInnerItemChange(emptyItem);
  }

  @autobind
  private onInnerItemChange(innerItem: CustomElementFilterItemType): void {
    const index = this.props.index;
    this.props.onChange(index, innerItem);
  }

  private isFilterNode(): boolean {
    const { filterItem } = this.props;
    if (CustomElementFilterBuilderTypeGuards.isFilterNode(filterItem)) {
      return true;
    }

    return false;
  }

  private getItemKey(filterItem: CustomElementFilterItemType): string {
    if (CustomElementFilterBuilderTypeGuards.isStringBaseFilter(filterItem)) {
      return filterItem.propertyKey;
    } else if (CustomElementFilterBuilderTypeGuards.isNumberBaseFilter(filterItem)) {
      return filterItem.propertyKey;

    } else if (CustomElementFilterBuilderTypeGuards.isReferenceBaseFilter(filterItem)) {
      return filterItem.elementFilterId;
    }

    return null;
  }

  private getInnerNodesCount(): number {
    const { filterItem } = this.props;
    if (CustomElementFilterBuilderTypeGuards.isFilterNode(filterItem)) {
      return filterItem.innerNodes.length;
    }

    return null;
  }

  private renderItem(): JSX.Element {
    const { filterItem, filterKeyToPredefineValues, filterKeyOptions, isOnlyModelValues } = this.props;
    const { getCustomKeyFromName, getCustomNameFromKey } = this.props;

    if (CustomElementFilterBuilderTypeGuards.isFilterNode(filterItem)) {
      return (
        <CustomElementFilterBuilderTreeNode
          isOnlyModelValues={isOnlyModelValues}
          node={filterItem}
          onChange={this.onInnerItemChange}
          filterKeyToPredefineValues={filterKeyToPredefineValues}
          filterKeyOptions={filterKeyOptions}
          getCustomKeyFromName={getCustomKeyFromName}
          getCustomNameFromKey={getCustomNameFromKey}
          conditionsValidationResult={this.props.conditionsValidationResult}
        />
      );
    } else if (CustomElementFilterBuilderTypeGuards.isStringBaseFilter(filterItem)) {
      return (
        <CustomElementFilterBuilderStringItem
          filterItem={filterItem}
          onChange={this.onInnerItemChange}
          predefinedValues={filterKeyToPredefineValues[this.getItemKey(filterItem)]}
          isOnlyModelValues={isOnlyModelValues}
        />
      );
    } else if (CustomElementFilterBuilderTypeGuards.isNumberBaseFilter(filterItem)) {
      return (
        <CustomElementFilterBuilderNumberItem
          filterItem={filterItem}
          onChange={this.onInnerItemChange}
        />
      );
    }

    return null;
  }

  private getFilteredFilterKeyOptions(
    options: CustomFilterBuilderAutocompleteOption[],
    isOnlyModelValues: boolean,
    value: string,
  ): AutocompleteOption[] {
    if (!options) {
      return null;
    }

    if (!isOnlyModelValues) {
      return options;
    }

    const result: AutocompleteOption[] = [];
    for (const option of options) {
      const children = this.getFilteredFilterKeyOptions(option.children, isOnlyModelValues, value);
      const isSelectedValue = option.value === value;
      const isVisible = (children && children.length) || option.isContainsInModel;
      if (isVisible || isSelectedValue) {
        result.push({
          ...option,
          children,
        });
      }
    }

    return result;
  }
}
