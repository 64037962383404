
import { Filters, MentionFilterValues, StatusFiltersValue } from 'unit-2d-comments/enums';
import { CommentaryThread, FiltersData } from '../interfaces';
import { MentionUtils } from './mention-utils';

function createMentionFilter(value: string, currentUserId: string): (thread: CommentaryThread) => boolean {
  return value === MentionFilterValues.All
    ? () => true
    : (thread: CommentaryThread) => {
      return MentionUtils.getUserIdsSet(thread.text).has(currentUserId)
        || thread.messages?.some((message) => MentionUtils.getUserIdsSet(message.text).has(currentUserId));
    };
}

function createStatusFilter(value: string): (thread: CommentaryThread) => boolean {
  if (!value) {
    return () => true;
  }
  return value === StatusFiltersValue.Resolved ? (thread) => thread.resolved : (thread) => !thread.resolved;
}

function createFilter(filters: FiltersData, currentUserId: string): (thread: CommentaryThread) => boolean {
  const mentionFilter = createMentionFilter(filters[Filters.Mention], currentUserId);
  const statusFilter = createStatusFilter(filters[Filters.Status]);
  return thread => statusFilter(thread) && mentionFilter(thread);
}

export const FilterUtils = {
  createFilter,
};
