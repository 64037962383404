import { GridApi } from 'ag-grid-community';
import { useCallback } from 'react';
import { getSelectedRootIds } from '../../helpers/get-selected-root-ids';

type UseDeleteCallback = (gridApi: GridApi, handleDelete: (ids: string[]) => void) => [(id: string) => void];

export const useDeleteCallback: UseDeleteCallback = (gridApi, handleDelete) => {
  const handleDeleteCallback = useCallback((id) => {
    if (!gridApi) {
      return;
    }
    const selectedNodes = gridApi.getSelectedNodes();
    if (selectedNodes.length && selectedNodes.some(node => node.id === id)) {
      const rootIds = getSelectedRootIds(selectedNodes);
      handleDelete(rootIds);
      return;
    }

    handleDelete([id]);
  }, [gridApi]);

  return [handleDeleteCallback];
};
