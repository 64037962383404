import { Icons } from '@kreo/kreo-ui-components';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import Ag, { ToolPanelVisibleChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import autobind from 'autobind-decorator';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import uuid from 'uuid';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { withAbilityContext } from 'common/ability/with-ability-context';
import { getSaveAgGridStateHelper, AgGridSaveStateHelper, AgGridTableApi } from 'common/ag-grid';
import { DrawingsAnnotationLegendActions } from 'common/components/drawings';
import { Spinner } from 'common/components/spinner';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { TwoDActions } from '../../actions/creators';
import { AGG_FUNCS, DEFAULT_COLUMN_DEF, columnTypes } from './constants';
import { DefaultCellRenderer } from './default-cell-renderer';
import { DispatchProps, Props, StateProps } from './interfaces';
import { Styled } from './styled';
import { CODE_COLUMN_ID } from './utils/extend-code-column';
import { ORDER_AUTO_GROUP_COLUMN_ID, ORDER_COLUMN_ID } from './utils/items-helper/utils';
import { TransactionGenerator } from './utils/transaction-generator';

const ROW_DATA = [];
const getRowNodeId = (data: Record<string, string | number>): string => data.id as string;

type GridEventHandlers = {
  onFilterChanged: (e: Ag.AgGridEvent) => void,
  onColumnVisible: (e: Ag.AgGridEvent) => void,
  onColumnPinned: (e: Ag.AgGridEvent) => void,
  onColumnResized: (e: Ag.AgGridEvent) => void,
  onColumnMoved: (e: Ag.AgGridEvent) => void,
  onColumnPivotChanged: (e: Ag.AgGridEvent) => void,
  onGridColumnsChanged: (e: Ag.AgGridEvent) => void,
  onColumnRowGroupChanged: (e: Ag.AgGridEvent) => void,
  onSortChanged: (e: Ag.AgGridEvent) => void,
  onColumnValueChanged: (e: Ag.AgGridEvent) => void,
};

interface ComponentState {
  isInitialFilterSet: boolean;
  isInitialized: boolean;
  isReady: boolean;
  applyColumns: boolean;
}

const AgGridComponents = {
  defaultCellRenderer: DefaultCellRenderer,
};

const promiseList = {
  lastPromiseId: '',
};

class TwoDElementView<TRowDataView> extends React.PureComponent<
  Props<TRowDataView>,
  ComponentState
> {
  private gridApi: Ag.GridApi;
  private columnApi: Ag.ColumnApi;
  private columns: Ag.ColDef[] = [];
  private rowTransactionGenerator: TransactionGenerator = new TransactionGenerator();
  private saveApi: AgGridSaveStateHelper<GridEventHandlers>;
  private toolPanelStatus: string | undefined;
  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private deferredSaveColumnsConfigExecuter: DeferredExecutor = new DeferredExecutor(500);
  private prevShowCode: boolean = null;

  constructor(props: Props<TRowDataView>) {
    super(props);
    this.saveApi = getSaveAgGridStateHelper<GridEventHandlers>(
      {
        name: 'Measured View',
        saveState: this.saveState,
      },
      [
        'onColumnVisible',
        'onColumnPinned',
        'onColumnResized',
        'onColumnPivotChanged',
        'onColumnMoved',
        'onColumnRowGroupChanged',
        'onSortChanged',
        'onColumnValueChanged',
      ],
    );

    this.state = { isInitialized: false, isReady: false, isInitialFilterSet: false, applyColumns: false };
    this.initializeToolPanelStatus();
  }

  public componentDidUpdate(prevProps: Props<TRowDataView>): void {
    const { tableId, viewToolPanelsStatus, config } = this.props;
    const toolPanelsStatus = viewToolPanelsStatus[tableId];

    if (this.props.showCode !== this.prevShowCode) {
      this.prevShowCode = this.props.showCode;
      this.props.handleChangeShowCode(this.props.showCode);
    }

    if (config) {
      this.updateRowData();
    }

    if (tableId !== prevProps.tableId) {
      if (toolPanelsStatus) {
        this.gridApi.openToolPanel(toolPanelsStatus);
      } else {
        this.gridApi.closeToolPanel();
      }
    }

    if (this.gridApi
      && (this.props.comments !== prevProps.comments
        || this.props.selectedCommentId !== prevProps.selectedCommentId)) {
      this.gridApi.refreshCells({ force: true });
    }
  }

  public render(): React.ReactNode {
    const { config, withTableTotal } = this.props;
    const sideBar = this.getDefaultToolPanel();

    return (
      <Styled.Container
        className="ag-theme-balham-dark"
        ref={this.containerRef}
        canEdit={config.allowColumnsConfiguration}
      >
        <Spinner show={this.state.applyColumns} />
        <AgGridReact
          autoGroupColumnDef={this.props.dataHelper.getAutoColumnGroup()}
          columnDefs={this.columns}
          rowData={ROW_DATA}
          sideBar={sideBar}
          onGridReady={this.onGridReady}
          getRowNodeId={getRowNodeId}
          defaultColDef={DEFAULT_COLUMN_DEF}
          enableRangeSelection={true}
          enableCharts={true}
          onSelectionChanged={this.onSelectionChange}
          {...this.saveApi.eventHandlers}
          onColumnPivotModeChanged={this.onColumnPivotModeChanged}
          rowSelection={'multiple'}
          groupSelectsChildren={true}
          suppressRowClickSelection={true}
          getContextMenuItems={this.getContextMenuItems}
          rowGroupPanelShow='always'
          pivotPanelShow='always'
          aggFuncs={AGG_FUNCS}
          suppressColumnVirtualisation={true}
          onCellClicked={this.onSelectedView}
          onToolPanelVisibleChanged={this.onToolPanelVisibleChanged}
          allowDragFromColumnsToolPanel={false}
          onFilterChanged={this.onFilterChanged}
          onGridColumnsChanged={this.onGridColumnsChanged}
          onColumnResized={this.onColumnResized}
          groupIncludeTotalFooter={withTableTotal && config.allowColumnsConfiguration}
          components={AgGridComponents}
          context={this.props.tableContext}
          suppressAggFuncInHeader={true}
          overlayNoRowsTemplate={this.props.overlayNoRowsTemplate}
          columnTypes={columnTypes}
          onSortChanged={this.handleSortChange}
          rowBuffer={100}
        />
      </Styled.Container>
    );
  }

  @autobind
  private onColumnPivotModeChanged(e: Ag.AgGridEvent): void {
    this.saveState(e);
    if (this.props.onPivotModeChange) {
      this.props.onPivotModeChange();
    }
  }

  @autobind
  private handleSortChange(event: Ag.SortChangedEvent): void {
    const sortModel = event.api.getSortModel();
    if (!sortModel || !sortModel.length) {
      event.api.setSortModel([
        {
          colId: ORDER_COLUMN_ID,
          sort: 'asc',
        },
        {
          colId: ORDER_AUTO_GROUP_COLUMN_ID,
          sort: 'asc',
        },
      ]);
    }
    this.saveState(event);
  }

  @autobind
  private onFilterChanged(event: Ag.FilterChangedEvent): void {
    this.saveState(event);
    this.setViewMeasureId(event);
  }

  @autobind
  private onGridColumnsChanged(event: Ag.GridColumnsChangedEvent): void {
    this.saveState(event);
    this.setViewMeasureId(event);
  }

  @autobind
  private onColumnResized(event: Ag.ColumnResizedEvent): void {
    if (event.finished) {
      this.saveState(event);
    }
  }

  private setViewMeasureId(event: Ag.AgGridEvent): void {
    const filteredElementIds: Record<string, boolean> = {};
    event.api.forEachNodeAfterFilter(n => {
      if (n.data) {
        filteredElementIds[n.data.measureId] = true;
      }
    });
    this.props.setViewMeasureId(filteredElementIds);
  }

  @autobind
  private initializeToolPanelStatus(): void {
    const { tableId, viewToolPanelsStatus } = this.props;
    if (
      viewToolPanelsStatus[tableId] === undefined ||
      viewToolPanelsStatus[tableId] === 'columns'
    ) {
      this.toolPanelStatus = 'columns';
    }
    if (viewToolPanelsStatus[tableId] === '') {
      this.toolPanelStatus = undefined;
    }
    if (viewToolPanelsStatus[tableId] === 'filters') {
      this.toolPanelStatus = 'filters';
    }
  }

  @autobind
  private onToolPanelVisibleChanged(event: ToolPanelVisibleChangedEvent): void {
    const { tableId, viewToolPanelsStatus, toggleViewToolPanels } = this.props;

    toggleViewToolPanels(tableId, event.source);
    if (viewToolPanelsStatus[tableId] === undefined) {
      toggleViewToolPanels(tableId, this.toolPanelStatus);
    }
    if (event.source === undefined) {
      toggleViewToolPanels(tableId, '');
    }
  }

  @autobind
  private getDefaultToolPanel(): any {
    if (!this.props.config.allowColumnsConfiguration) {
      return undefined;
    }
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 0,
          width: 0,
          maxWidth: 0,
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 0,
          maxWidth: 0,
          width: 0,
        },
      ],
      position: 'right',
      defaultToolPanel: this.toolPanelStatus,
    };
  }

  @autobind
  private saveState(tableApi: AgGridTableApi): void {
    const {
      config,
      ability,
      tableId,
      withTableTotal,
      projectId,
      disableUpdateConfig,
      updateViewConfig,
      showCode,
    } = this.props;

    if (!this.state.isInitialized) {
      return;
    }
    if (!config.allowColumnsConfiguration) {
      return;
    }

    if (ability.can(Operation.Update, Subject.Takeoff2DReport) && !disableUpdateConfig) {
      const columns: Ag.ColumnState[] = tableApi.columnApi.getColumnState().filter(c => {
        return c.colId !== CODE_COLUMN_ID && c.colId !== ORDER_COLUMN_ID && c.colId !== ORDER_AUTO_GROUP_COLUMN_ID;
      });

      const payload = {
        ...config,
        isPivot: tableApi.columnApi.isPivotMode(),
        columns,
        filters: tableApi.api.getFilterModel(),
        groupIncludeTotalFooter: withTableTotal,
        createCodeColumn: showCode,
      };

      this.deferredSaveColumnsConfigExecuter.execute(() => {
        updateViewConfig(projectId.toString(), tableId, payload);
      });
    }
  }

  @autobind
  private onSelectedView(): void {
    this.props.setFocusView(this.props.tableId);
  }

  @autobind
  private onSelectionChange(event: Ag.SelectionChangedEvent): void {
    const nodes = event.api.getSelectedNodes();
    const ids = arrayUtils.filterMap(nodes, node => !!node.data, node => node.data.measureId);
    this.props.drawingApi.scrollToDrawingInstances(ids);
    this.props.setSelectedInstances(ids);
    this.props.setSelectedMeasureIdView(ids);
  }

  @autobind
  private onGridReady(event: Ag.GridReadyEvent): void {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.updateRowData(true);
    this.setState({ isInitialized: true });
    if (this.props.saveApi) {
      this.props.saveApi(
        this.gridApi,
        this.columnApi,
        this.updateColumnsApi,
        this.setFilterWithSet,
        this.setApplyState,
      );
    }
  }

  @autobind
  private async updateRowData(isFirstUpdate?: boolean): Promise<void> {
    const {
      dataHelper,
      config,
      isSkipInnerUpdate,
    } = this.props;
    if (isSkipInnerUpdate) {
      return;
    }
    if (this.gridApi) {
      if (!isFirstUpdate && !dataHelper.isNeedUpdate(this.props, this.columnApi.isPivotMode())) {
        return;
      }
      this.setState({ isReady: false });
      const promise = dataHelper.getViewData(this.props, this.columnApi.isPivotMode(), config);
      const promiseId = uuid.v4();
      promiseList.lastPromiseId = promiseId;
      const data = await promise;
      if (promiseList.lastPromiseId !== promiseId) {
        return;
      }
      const { map, columns } = data
        ? data
        : { map: {}, columns: [] };
      const transaction = this.rowTransactionGenerator.getTransaction(map);
      const colDefs = this.gridApi.getColumnDefs();
      const isColumnChange = this.isChangeColumns(columns, colDefs);
      const isRowChange = !!transaction;
      if (isColumnChange) {
        this.updateColumns(columns, isFirstUpdate);
      }
      this.applyFilterWithoutSet();
      if (isRowChange) {
        this.gridApi.applyTransactionAsync(transaction, () => {
          if (isColumnChange || isRowChange) {
            this.gridApi.refreshCells({ force: true });
          }
          if (!this.state.isReady) {
            this.setState({ isReady: true });
          }
          if (!this.state.isInitialFilterSet && config) {
            this.setFilterWithSet();
            this.setState({ isInitialFilterSet: true });
          }
          this.gridApi.refreshCells({ force: true, columns: [CODE_COLUMN_ID] });
        });
      }
    }
  }

  @autobind
  private updateColumns(columns: Ag.ColDef[], isFirstUpdate?: boolean): void {
    this.gridApi.setColumnDefs(columns);
    this.applyConfig(isFirstUpdate, columns);
    this.updatePanelStyle(columns);
  }

  @autobind
  private updateColumnsApi(columns: Ag.ColDef[], isFirstUpdate?: boolean): void {
    this.updateColumns(columns, isFirstUpdate);
    this.applyFilterWithoutSet();
    this.setState({ applyColumns: false });
  }

  @autobind
  private setApplyState(value: boolean): void {
    this.setState({ applyColumns: value });
  }

  @autobind
  private setFilterWithSet(): void {
    const { config } = this.props;
    if (this.gridApi.getVirtualRowCount && !this.state.isInitialFilterSet) {
      this.gridApi.setFilterModel(config.filters);
      this.setState({ isInitialFilterSet: true });
    }
  }

  private applyFilterWithoutSet(): void {
    const {
      config,
    } = this.props;
    if (!this.state.isInitialFilterSet && config) {
      const filterModel = {};
      for (const filterKey of Object.keys(config.filters)) {
        const filter = config.filters[filterKey];
        const hasSet = filter.filterModels.some(f => f && f.filterType === 'set');
        if (!hasSet) {
          filterModel[filterKey] = filter;
        }
      }
      this.gridApi.setFilterModel(filterModel);
    }
  }

  private updatePanelStyle(columns: Ag.ColDef[]): void {
    const columnToolPanel: any = this.gridApi.getToolPanelInstance('columns');
    const columnToolFiltersPanel: any = this.gridApi.getToolPanelInstance('filters');
    const columnsToShow = columns.filter(c => c.colId !== ORDER_COLUMN_ID
      && c.colId !== CODE_COLUMN_ID
      && c.colId !== ORDER_AUTO_GROUP_COLUMN_ID,
    );

    if (columnToolPanel) {
      columnToolPanel.setColumnLayout(columnsToShow);
    }
    if (columnToolFiltersPanel) {
      columnToolFiltersPanel.setFilterLayout(columnsToShow);
    }
  }

  private isChangeColumns(newColumns: Ag.ColDef[], currentColumns: Ag.ColDef[]): boolean {
    if (newColumns.length !== currentColumns.length) {
      return true;
    }

    const newColumnsSet = new Set(newColumns.map(c => c.colId));
    for (const currentColumn of currentColumns) {
      if (!newColumnsSet.has(currentColumn.colId)) {
        return true;
      }
    }

    return false;
  }

  @autobind
  private getContextMenuItems(param: Ag.GetContextMenuItemsParams): Array<string | Ag.MenuItemDef> {
    const extraContextMenu = this.props.getExtraContextMenu(param);

    return [
      'autoSizeAll',
      'expandAll',
      'contractAll',
      'copy',
      'resetColumns',
      'chartRange',
      'pivotChart',
      {
        name: 'Export CSV',
        action: this.exportCSV,
        icon: ReactDOMServer.renderToString(<Icons.Export />),
      },
      ...extraContextMenu,
    ];
  }

  @autobind
  private exportCSV(): void {
    const fileName = this.props.tableName;
    this.gridApi.exportDataAsCsv({ fileName, columnGroups: true });
  }

  private applyConfig(applyOrder: boolean, colDefs?: Ag.ColDef[]): void {
    if (this.gridApi) {
      const { isPivot, columns } = this.props.config;
      const colDefIdSet = new Set(colDefs.map(c => c.colId));
      const [actualColumns, filteredColumns] = this.filterColumnsConfig(columns, colDefIdSet);
      const newColumns = this.getNewColumnsState(actualColumns, colDefs);
      const columnState = [...filteredColumns, ...newColumns];
      columnState.forEach((c, i) => {
        if (c.aggFunc as any === 0) {
          columnState[i] = { ...c, aggFunc: undefined };
        }
      });
      this.columnApi.applyColumnState({ state: columnState, applyOrder });
      this.columnApi.setPivotMode(isPivot);
    }
  }

  private filterColumnsConfig(oldColumns: Ag.ColumnState[], colDefIds?: Set<string>): [Set<string>, Ag.ColumnState[]] {
    const actualColumn = new Set<string>();
    const result = [];
    if (colDefIds) {
      for (const o of oldColumns) {
        if (colDefIds.has(o.colId) || o.colId === 'ag-Grid-AutoColumn') {
          actualColumn.add(o.colId);
          result.push({ ...o });
        }
      }
    }

    return [actualColumn, result];
  }

  private getNewColumnsState(oldColumns: Set<string>, colDefs?: Ag.ColDef[]): Ag.ColumnState[] {
    if (!colDefs) {
      return [];
    }
    const newColumns = colDefs.filter(c => !oldColumns.has(c.colId));
    return newColumns.map(c => {
      if (c.colId === CODE_COLUMN_ID || c.colId === ORDER_COLUMN_ID || c.colId === ORDER_AUTO_GROUP_COLUMN_ID) {
        return c;
      }
      return {
        colId: c.colId,
        hide: !!oldColumns.size,
      };
    });
  }
}


function mapStateToProps(state: State): StateProps {
  const {
    drawingGeometryGroups,
    aiAnnotation,
    files,
    drawingsInfo,
    elementMeasurement,
  } = state.drawings;

  const projectId = state.projects.currentProject.id;

  return {
    groups: drawingGeometryGroups,
    files,
    drawingsInfo,
    aiAnnotation,
    elementMeasurement,
    users: state.people.companiesUsers,
    assignPia: state.twoD.assignPia,
    calculatedPia: state.twoD.calculatedPia,
    isImperial: state.account.settings.isImperial,
    viewToolPanelsStatus: state.twoD.viewToolPanels,
    withUnit: state.persistedStorage.showTableUnits,
    withTableTotal: state.persistedStorage.showTableTotal,
    comments: state.twoDComments.comments,
    selectedCommentId: state.twoDComments.selectedCommentId,
    originProperties: state.twoDDatabase.properties,
    projectId,
    showCode: state.twoD.viewsConfigs && state.twoD.viewsConfigs[state.twoD.selectedSheetId]?.createCodeColumn,
    handleChangeShowCode: state.twoDElementView.handleToggleShowCode,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    setSelectedInstances: instanceIds => dispatch(DrawingsAnnotationLegendActions.updateSelection({ instanceIds })),
    setFocusView: id => dispatch(TwoDActions.setSelectedView(id)),
    updateViewConfig: (projectId, tabId, config) =>
      dispatch(TwoDActions.updateViewConfig(projectId, tabId, config)),
    toggleViewToolPanels: (tableId, toolPanelName) =>
      dispatch(TwoDActions.toggleViewToolPanels(tableId, toolPanelName)),
    setViewMeasureId: map => dispatch(TwoDActions.setViewMeasureId(map)),
    setSelectedMeasureIdView: map => dispatch(TwoDActions.setSelectedMeasureIdView(map)),
  };
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const DrawingElementsTable = connector(withAbilityContext(TwoDElementView));
