import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MagicSearchActions } from 'common/components/drawings/actions/creators/magic-search';
import { State } from 'common/interfaces/state';

export function useSimilarity(): { count: number, similarity: number, setSimilarity: (value: number) => void } {
  const dispatch = useDispatch();
  const similarity = useSelector<State, number>((state) => state.drawings.magicSearch.similarity);
  const setSimilarity = useCallback((value) => dispatch(MagicSearchActions.setSimilarity(value)), [dispatch]);
  const count = useSelector<State, number>(
    ({ drawings: { magicSearch } }) => {
      return magicSearch.previews.length - magicSearch.previewsToDelete.length + magicSearch.fixedContours.length;
    });
  return {
    similarity,
    count,
    setSimilarity,
  };
}
