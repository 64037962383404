import classNames from 'classnames';
import * as React from 'react';
import ReactTooltip, { Effect, Offset, Place } from 'react-tooltip';

import './react-tooltip-mod.scss';

interface Props {
  id: string;
  delayShow?: number;
  delayHide?: number;
  className?: string;
  place?: Place;
  effect?: Effect;
  offset?: Offset;
  type?: 'light' | 'dark';
}

export class ReactTooltipMod extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    delayShow: 200,
    place: 'bottom',
    type: 'light',
  };
  public render(): React.ReactNode {
    const { id, className, delayShow, delayHide, place, effect, offset, type } = this.props;
    return (
      <ReactTooltip
        id={id}
        delayShow={delayShow}
        delayHide={delayHide}
        place={place}
        offset={offset}
        effect={effect}
        type={type}
        className={classNames('react-tooltip-mod', className)}
      >
        {this.props.children}
      </ReactTooltip>
    );
  }
}
