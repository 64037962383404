import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State as ReduxState } from 'common/interfaces/state';

import { ActivityGroupingActions, MacroSequenceActions, StepActions } from '../../actions';
import { UngroupedCategoryId } from '../../constants';
import { ActivityGroupData, ActivityGroupingStepPage } from '../../interfaces';
import { EntityCompareHelper } from '../../utils';
import { EnginePopoverOperationType } from '../engine-popover';
import {
  ActivityGroupingReduxActions,
  ActivityGroupingReduxProps,
  EngineProps,
  MacroSequenceReduxActions,
  MacroSequenceReduxProps,
} from './interfaces';
import { SequenceEngineOverlay } from './sequence-engine-overlay';


interface ReduxProps extends ActivityGroupingReduxProps, MacroSequenceReduxProps { }

interface ReduxActions extends ActivityGroupingReduxActions, MacroSequenceReduxActions { }

interface ActivityGroupingProps extends ActivityGroupingReduxProps, ActivityGroupingReduxActions { }

interface MacroSequenceProps extends MacroSequenceReduxProps, MacroSequenceReduxActions { }

interface Props extends ReduxProps, ReduxActions {
  projectId: number;
  page: ActivityGroupingStepPage;
}

class SequenceEngineContainerComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const engineProps = this.getEngineProps();

    return (
      <div className='sequence-engine-container'>
        <SequenceEngineOverlay
          projectId={this.props.projectId}
          {...engineProps}
        />
      </div>
    );
  }

  private getEngineProps(): EngineProps {
    switch (this.props.page) {
      case ActivityGroupingStepPage.ActivityGrouping:
        return this.getPropsForActivityGroupingPageEngine();
      case ActivityGroupingStepPage.MacroSequence:
        return this.getPropsForMacroSequencePageEngine();
      case ActivityGroupingStepPage.MicroSequence:
      default:
        return null;
    }
  }

  private getPropsForActivityGroupingPageEngine(): EngineProps {
    const onItemSelect = (activityIds: number[], operation: EnginePopoverOperationType): void => {
      const props: ActivityGroupingProps = this.props;
      const selectedIds = { ...props.selectedActivityIds };
      const isSelectOption = operation === 'select';
      for (const id of activityIds) {
        if (isSelectOption) {
          selectedIds[id] = true;
        } else {
          delete selectedIds[id];
        }
      }

      props.setSelectedActivityIds(selectedIds);
      const someSelectedActivityId = Object.values(activityIds)[0];
      if (!Number.isInteger(props.activeActivityGroupId)) {
        props.setActiveActivityOrUngroupedActivity(someSelectedActivityId);
      }
    };

    const getItemsForPopover =
      (bimHandleIds: number[], operation: EnginePopoverOperationType): ActivityGroupData.GroupedIdsByName[] => {
        const props: ActivityGroupingProps = this.props;
        const groupedActivities = {};
        const isDeselectOption = operation === 'deselect';


        for (const bimId of bimHandleIds) {
          for (const activityId of props.bimIdToActivityIds[bimId]) {
            if (isDeselectOption && !props.selectedActivityIds[activityId]) {
              continue;
            }

            let workPackageId;
            let groupId;
            let activityName;
            const isGroupedActivity = activityId in props.activities;
            if (isGroupedActivity) {
              const activity = props.activities[activityId];
              activityName = activity.name;
              groupId = activity.activityGroupId;
              workPackageId = props.activityGroups[activity.activityGroupId].workPackageId;
            } else {
              const ungroupedActvivity = props.ungroupedActivities[activityId];
              activityName = ungroupedActvivity.name;
              groupId = ungroupedActvivity.activityGroupId;
              workPackageId = ungroupedActvivity.workPackageId;
            }

            if (Number.isInteger(props.activeWorkPackageId) && props.activeWorkPackageId !== workPackageId) {
              continue;
            }

            if (
              isGroupedActivity &&
              props.activeCategoryId === UngroupedCategoryId &&
              !isDeselectOption
            ) {
              continue;
            }

            if (
              Number.isInteger(props.activeActivityGroupId) &&
              props.activeActivityGroupId !== groupId &&
              props.activeCategoryId !== UngroupedCategoryId &&
              !isDeselectOption
            ) {
              continue;
            }

            if (!groupedActivities[workPackageId]) {
              groupedActivities[workPackageId] = {};
            }

            groupedActivities[workPackageId][activityId] = activityName;
          }
        }

        const result: ActivityGroupData.GroupedIdsByName[] = [];
        for (const wpId in groupedActivities) {
          const groupedByName = {};
          for (const activityId in groupedActivities[wpId]) {
            if (!groupedByName[groupedActivities[wpId][activityId]]) {
              groupedByName[groupedActivities[wpId][activityId]] = [];
            }

            groupedByName[groupedActivities[wpId][activityId]].push(+activityId);
          }

          const items: ActivityGroupData.IdsByName[] = [];
          for (const name in groupedByName) {
            items.push({
              name,
              ids: groupedByName[name],
            });
          }
          result.push({
            id: +wpId,
            name: props.workPackages[wpId].name,
            items: items.sort(EntityCompareHelper.compareNamedEntity),
          });
        }

        return result.sort(EntityCompareHelper.compareNamedEntity);
      };

    const getAvailableBimIds = (bimIds: number[], operation: EnginePopoverOperationType): number[] => {
      if (operation === 'deselect') {
        const availableBimIds = new Map();
        for (const activityId in this.props.selectedActivityIds) {
          (this.props.activities[activityId] || this.props.ungroupedActivities[activityId])
            .engineIds.forEach(id => availableBimIds.set(id, true));
        }

        return bimIds.filter(id => availableBimIds.has(id));
      }
      return bimIds;
    };

    return {
      getItemsForPopover,
      onItemSelect,
      popoverTitle: 'Element Activities',
      getPopoverAvailableBimIds: getAvailableBimIds,
    };
  }

  private getPropsForMacroSequencePageEngine(): EngineProps {
    const onItemSelect = (ids: number[], operation: EnginePopoverOperationType): void => {
      const props: MacroSequenceProps = this.props;
      if (ids.length > 0) {
        if (props.edgeCreator) {
          const filteredIds = props.edgeCreator.destinationIds.filter(id => ids.includes(id));
          if (operation === 'select') {
            props.setSelectedDestinationIds(filteredIds.concat(ids));
          } else {
            props.setSelectedDestinationIds(filteredIds);
          }
        } else if (operation === 'select') {
          props.setActiveActivityGroupOrUngroupedActivityById(ids[0]);
        }
      }
    };

    const getItemsForPopover = (bimHandleIds: number[]): ActivityGroupData.GroupedIdsByName[] => {
      const props: MacroSequenceProps = this.props;
      const isInFilteredWorkPackageAndGroup = (groupId: number, packageId: number): boolean => {
        if (props.filteredWorkPackageIds && !(packageId in props.filteredWorkPackageIds)) {
          return false;
        }
        if (props.filteredActivityGroupIds && !(groupId in props.filteredActivityGroupIds)) {
          return false;
        }

        return true;
      };

      const groupedActivityGroups = {};
      for (const bimId of bimHandleIds) {
        for (const activityId of props.bimIdToActivityIds[bimId]) {
          if (props.activities[activityId]) {
            const activityGroup = props.activityGroups[props.activities[activityId].activityGroupId];
            if (isInFilteredWorkPackageAndGroup(activityGroup.id, activityGroup.workPackageId)) {
              groupedActivityGroups[activityGroup.id] = activityGroup.name;
            }
          } else {
            const ungroupedActvivity = props.ungroupedActivities[activityId];
            if (isInFilteredWorkPackageAndGroup(ungroupedActvivity.activityGroupId, ungroupedActvivity.workPackageId)) {
              groupedActivityGroups[ungroupedActvivity.activityGroupId] = ungroupedActvivity.name;
            }
          }
        }
      }

      const groupedByName = {};
      for (const activityId in groupedActivityGroups) {
        if (!groupedByName[groupedActivityGroups[activityId]]) {
          groupedByName[groupedActivityGroups[activityId]] = [];
        }
        groupedByName[groupedActivityGroups[activityId]].push(+activityId);
      }

      const items: ActivityGroupData.IdsByName[] = [];
      for (const name in groupedByName) {
        items.push({
          name,
          ids: groupedByName[name],
        });
      }

      return [{
        id: 0,
        name: '',
        items: items.sort(EntityCompareHelper.compareNamedEntity),
      }];
    };

    return {
      getItemsForPopover,
      onItemSelect,
      popoverTitle: 'Element Activity Groups',
      getPopoverAvailableBimIds: (x) => x,
    };
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    activeWorkPackageId: state.activityGrouping.activeWorkPackageId,
    activeActivityGroupId: state.activityGrouping.activeActivityGroupId,
    activeCategoryId: state.activityGrouping.activeCategoryId,
    activities: state.activityGrouping.activities,
    activityGroups: state.activityGrouping.activityGroups,
    workPackages: state.activityGrouping.workPackages,
    ungroupedActivities: state.activityGrouping.ungroupedActivities,
    selectedActivityIds: state.activityGrouping.activityGrouping.selectedActivityIds,
    bimIdToActivityIds: state.activityGrouping.bimIdToActivityIds,
    edgeCreator: state.activityGrouping.macroSequence.edgeCreator,
    filteredActivityGroupIds: state.activityGrouping.macroSequence.filteredActivityGroupIds,
    filteredWorkPackageIds: state.activityGrouping.macroSequence.filteredWorkPackageIds,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    setActiveActivityOrUngroupedActivity: id =>
      dispatch(StepActions.setActiveActivityOrUngroupedActivity(id)),
    setActiveActivityGroupOrUngroupedActivityById: (id) =>
      dispatch(MacroSequenceActions.setActiveActivityGroupOrUngroupedActivityById(id)),
    setSelectedActivityIds: (ids) => dispatch(ActivityGroupingActions.setSelectedActivityIds(ids)),
    setSelectedDestinationIds: ids => dispatch(MacroSequenceActions.setSelectedDestinationId(ids)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const SequenceEngineContainer = connector(SequenceEngineContainerComponent);
