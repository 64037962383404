import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './page-header.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State } from 'common/interfaces/state';
import {
  IconButton,
  KreoButton,
  KreoIconCollapseExpand,
  KreoIconSubnavBack,
  KreoIconSubnavForward,
  MaterialMenuItem,
  MaterialSelect,
} from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { CommonApi } from '../../../../../api/server';
import { TZoomIn, TZoomOut } from '../../../../../icons';
import { GanttChartActions } from '../../../actions/creators/gantt-chart';
import { GanttApi } from '../../../api/gantt';
import { getCurrentProject } from '../../../selectors';
import { SlackType } from '../../../utils/gantt-chart';
import { CheckableEntity } from './checkable-entity';

interface OwnProps {
  showCriticalPath: boolean;
  visibleSlackType: SlackType;
  zoomIn: () => void;
  zoomOut: () => void;
  onBack: () => void;
  onForward: () => void;
  onChangeSlackType: (value: SlackType) => void;
  onChangeCriticalPathVisibility: (value: boolean) => void;
}

interface StateProps {
  calculationId: number;
  companyId: number;
  projectId: number;
}

interface DispatchProps {
  expandAll: () => void;
  collapseAll: () => void;
}

interface Props extends OwnProps, StateProps, DispatchProps, AbilityAwareProps {}

class PageHeaderComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <div className='gantt-chart-page-container-header'>
        <div className='gantt-chart-page-container-header__control-block collapse-expand'>
          <IconButton
            size='medium'
            onClick={this.props.expandAll}
            className='gantt-chart-page-container-header__icon-button'
          >
            <KreoIconCollapseExpand />
          </IconButton>
          <IconButton
            size='medium'
            onClick={this.props.collapseAll}
            className='gantt-chart-page-container-header__icon-button'
          >
            <KreoIconCollapseExpand />
          </IconButton>
        </div>
        <div className='gantt-chart-page-container-header__control-block back-forward'>
          <IconButton
            size='medium'
            onClick={this.props.onBack}
            className='gantt-chart-page-container-header__icon-button'
          >
            <KreoIconSubnavBack />
          </IconButton>
          <IconButton
            size='medium'
            onClick={this.props.onForward}
            className='gantt-chart-page-container-header__icon-button'
          >
            <KreoIconSubnavForward />
          </IconButton>
        </div>
        <div className='gantt-chart-page-container-header__control-block plus-minus'>
          <IconButton
            size='medium'
            onClick={this.props.zoomIn}
            className='gantt-chart-page-container-header__icon-button'
          >
            <img src={TZoomIn} />
          </IconButton>
          <IconButton
            size='medium'
            className='gantt-chart-page-container-header__icon-button'
            onClick={this.props.zoomOut}
          >
            <img src={TZoomOut} />
          </IconButton>
        </div>
        <div className='gantt-chart-page-container-header__control-block toggles'>
          <CheckableEntity
            onCheck={this.props.onChangeCriticalPathVisibility}
            checked={this.props.showCriticalPath}
          >
            Critical Activities
          </CheckableEntity>
          <CheckableEntity
            onCheck={this.onChangeSlaksVisibility}
            checked={this.props.visibleSlackType !== SlackType.Disabled}
          >
            Floats
          </CheckableEntity>
          {this.props.visibleSlackType !== SlackType.Disabled && (
            <MaterialSelect
              wrapClassName='gantt-chart-page-container-header__select'
              displayedType={MaterialComponentType.Native}
              autoWidth={false}
              multiple={false}
              value={this.props.visibleSlackType}
              onChange={this.onChangeSlackType}
            >
              <MaterialMenuItem value={SlackType.FreeSlack}>Free slack</MaterialMenuItem>
              <MaterialMenuItem value={SlackType.TotalSlack}>Total slack</MaterialMenuItem>
            </MaterialSelect>
          )}
        </div>
        {this.props.ability.can(Operation.Export, Subject.Gantt) && (
          <KreoButton
            className='gantt-chart-page-container-header__export-button'
            size='medium'
            onClick={this.onExportClick}
          >
            Export
          </KreoButton>
        )}
      </div>
    );
  }

  @autobind
  private onChangeSlackType(_e: React.SyntheticEvent<Element>, value: SlackType): void {
    this.props.onChangeSlackType(value);
  }

  @autobind
  private onChangeSlaksVisibility(value: boolean): void {
    this.props.onChangeSlackType(value ? SlackType.TotalSlack : SlackType.Disabled);
  }

  @autobind
  private onExportClick(): void {
    const { companyId, projectId, calculationId } = this.props;

    CommonApi.downloadFile(GanttApi.exportUrl(companyId, projectId, calculationId));
  }
}

const mapStateToProps = (state: State): StateProps => {
  const project = getCurrentProject(state);
  return {
    calculationId: state.scenarios.active_calculation
      ? state.scenarios.active_calculation.id
      : null,
    companyId: project ? project.companyId : null,
    projectId: project ? project.id : null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    expandAll: () => {
      dispatch(GanttChartActions.expandAll());
    },
    collapseAll: () => {
      dispatch(GanttChartActions.collapseAll());
    },
  };
};

export const PageHeader = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(PageHeaderComponent),
);
