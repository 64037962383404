import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { MagicSearchUtils } from 'common/components/drawings/helpers/geometry/magic-search';
import { DrawingsRenderParams, ShortPointDescription } from 'common/components/drawings/interfaces';
import { VisibleEntity, VisibleEntityConfig } from '../../common';


interface Config extends VisibleEntityConfig<ShortPointDescription[][]> {
  layer: paper.Group | paper.Path;
  withBorder?: boolean;
}


const PREVIEW_STYLES = {
  shadowBlur: 4,
  dashArray: [6, 3],
  strokeWidth: 2,
  shadownOffset: [2, 2],
};

export class BucketAiMasks extends VisibleEntity<ShortPointDescription[][], Config> {
  private _group: paper.Group;
  private _masks: paper.Path[] = [];

  constructor(config: Config) {
    super(config);
    if (config.withBorder) {
      this._config.renderParamsContextObserver.subscribe(this.updateRenderParams);
    }
  }

  public get masksCount(): number {
    return this._masks?.length;
  }

  public destroy(): void {
    this.removeMasks();
    this._config.renderParamsContextObserver.unsubscribe(this.updateRenderParams);
  }

  public isInsideMasks(point: paper.Point): boolean {
    return this._masks.some((mask) => mask.contains(point));
  }

  protected async render(geometry: ShortPointDescription[][]): Promise<void> {
    this._group = new paper.Group();
    this._group.addTo(this._config.layer);
    const paths = await MagicSearchUtils.contoursUnion(geometry);
    this._masks = paths.map((contour) => {
      const path = new paper.Path({
        segments: contour,
        closed: true,
        strokeColor: DrawingsCanvasColors.autocompleteColorInfo.stroke,
        fillColor: DrawingsCanvasColors.autocompleteColorInfo.fillHover,
        strokeWidth: this._config.withBorder
          ? PREVIEW_STYLES.strokeWidth / this._config.renderParamsContextObserver.getContext().zoom
          : 0,
        strokeCap: 'round',
      });
      path.addTo(this._group);
      return path;
    });
  }

  protected removeMasks(): void {
    this._masks.forEach((mask) => mask.remove());
    this._masks = [];
  }


  @autobind
  private updateRenderParams({ zoom }: DrawingsRenderParams): void {
    this._group.strokeWidth = PREVIEW_STYLES.strokeWidth / zoom;
  }
}
