import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './user-activities.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { NumberDictionary } from 'common/interfaces/dictionary';
import { DATE_FORMAT, KreoIconActivity } from 'common/UIKit';
import { State as ReduxState } from '../../../../common/interfaces/state';
import { ActivityActions } from '../../actions';
import { activityTypes } from '../../constants';
import { FilterType } from '../../filter-type';
import { GroupedUserActivity, SimpleOption, Sorting, TimeInterval } from '../../interfaces';
import { UserActivityUtil } from '../../user-activity-util';
import { FilterHeader } from '../filter-header';
import { FilterPanel } from '../filter-panel';
import { VirtualUserActivities } from '../virtual-user-activities';

interface ReduxProps {
  activities: GroupedUserActivity[];
  filteredActivities: GroupedUserActivity[];
  filter: NumberDictionary<SimpleOption[]>;
  activitiesStatus: RequestStatus;
  companyId: number;
  timeInterval: TimeInterval;
  sorting: Sorting;
}

interface ReduxActions {
  getCompanySubscriptionActivities: () => void;
  getDocumentActivities: (documentId: number) => void;
}

interface Props extends ReduxProps, ReduxActions {
  projectId?: number;
}

interface ActivityHeaderData {
  text?: string;
  id?: React.ReactText;
}

class UserActivitiesComponent extends React.Component<Props> {
  private lastSubHeaderData?: ActivityHeaderData = null;

  public componentDidMount(): void {
    this.loadActivities(this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    if (
      this.props.companyId !== nextProps.companyId &&
      nextProps.companyId &&
      this.props.activitiesStatus !== RequestStatus.Loading
    ) {
      this.loadActivities(nextProps);
    }
  }

  public render(): JSX.Element {
    const {
      activities,
      activitiesStatus,
      filter,
      filteredActivities,
      timeInterval,
      projectId,
    } = this.props;
    this.lastSubHeaderData = null;
    const hasFilters = UserActivityUtil.hasAnyFilter(filter, timeInterval);

    const itemsToRender = [];
    (hasFilters ? filteredActivities : activities).map(x => {
      const header = this.renderActivityHeader(x);
      if (header) {
        itemsToRender.push({ header });
      }
      itemsToRender.push({ userActivity: x });
    });

    return (
      <div className={classNames('user-activities-container', { 'has-filter': hasFilters })}>
        {activitiesStatus === RequestStatus.Loading ? (
          <SvgSpinner size='large' />
        ) : activities.length === 0 ? (
          <div className='user-activities-container__placeholder'>
            <KreoIconActivity />
            <div className='user-activities-container__placeholder-text'>
              There is no activity to display
            </div>
          </div>
        ) : (
          <React.Fragment>
            <FilterHeader showProjectInfo={!projectId} />
            <FilterPanel />
            <div className={'user-activities-container__activities'}>
              <VirtualUserActivities projectId={projectId} items={itemsToRender} />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  private renderActivityHeader(activity: GroupedUserActivity): React.ReactNode {
    const { sorting } = this.props;
    let headerData: ActivityHeaderData = {};
    switch (sorting.type) {
      case FilterType.Date: {
        const created = new Date(activity.created);
        const today = new Date();
        headerData.text =
          created.toDateString() === today.toDateString()
            ? 'Today'
            : moment(created).format(DATE_FORMAT);
        headerData.id = headerData.text;
        break;
      }
      case FilterType.Project:
        if (activity.document) {
          headerData = { text: activity.document.name, id: activity.document.id };
        }
        break;
      case FilterType.People:
        if (activity.user) {
          headerData = {
            text: `${activity.user.firstName} ${activity.user.lastName}`,
            id: activity.user.id,
          };
        }
        break;
      case FilterType.Role:
        if (activity.role) {
          headerData = { text: activity.role.name, id: activity.role.id };
        }
        break;
      case FilterType.Type:
        const Type = activityTypes[activity.relatedArea];
        headerData = { text: Type.text, id: activity.relatedArea };
        break;
      default:
        return null;
    }
    if (!headerData.text) {
      headerData.text = 'Common actions';
    }

    const result = (this.lastSubHeaderData == null ||
      headerData.id !== this.lastSubHeaderData.id) && (
      <div className='user-activities-container__activity-header'>{headerData.text}</div>
    );
    this.lastSubHeaderData = headerData;
    return result;
  }

  private loadActivities({ companyId, projectId }: Props): void {
    if (projectId) {
      this.props.getDocumentActivities(projectId);
    } else if (companyId) {
      this.props.getCompanySubscriptionActivities();
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    activities: state.userActivities.activities,
    filteredActivities: state.userActivities.filteredActivities,
    filter: state.userActivities.filter,
    timeInterval: state.userActivities.timeInterval,
    activitiesStatus: state.userActivities.statuses.activities,
    companyId: state.account.selectedCompany && state.account.selectedCompany.id,
    sorting: state.userActivities.sorting,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    getCompanySubscriptionActivities: () => dispatch(ActivityActions.getCompanySubscriptionActivities()),
    getDocumentActivities: (documentId) => {
      dispatch(ActivityActions.getDocumentActivities(documentId));
    },
  };
};

export const UserActivities = connect(mapStateToProps, mapDispatchToProps)(UserActivitiesComponent);
