import { useCallback } from 'react';
import { useActionWithArgsDispatch } from 'common/hooks';
import { PaymentMethodActions } from '../../../actions/creators';
import { PaymentMethodsApi } from '../../../api/payment-methods';


export async function createPortal(onClosePortal: () => void): Promise<any> {
  const cbInstance = Chargebee.getInstance();
  cbInstance.openCheckout({
    hostedPage: () => {
      return PaymentMethodsApi.getPaymentPortal();
    },
    close: onClosePortal,
  });
  return Promise.resolve();
}

export function usePaymentPortal(onClosePortal: () => void): () => void {

  return useCallback(() => {
    createPortal(onClosePortal);
  }, [onClosePortal]);
}

export function usePaymentPortalWithReload(): () => void {
  const loadPayments = useActionWithArgsDispatch(PaymentMethodActions.getPaymentMethods, true);
  return usePaymentPortal(loadPayments);
}
