import { useSelector } from 'react-redux';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import {
  useAreInstancesIdsHidden,
  useCurrentDrawingInfo,
  useHasVisibleInstances,
} from 'common/components/drawings/hooks';
import { DrawingContextMenuPosition } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';
import { useCanMagicSearch, useHasRendered, useIsOneGroupSelected } from '../../hooks';
import { DrawingsContextMenuFilter } from '../drawings-context-menu-config';
import { useCanUngroup } from './use-can-ungroup';

export interface UseFiltersCachePayload {
  isFullScreen: boolean;
  canPaste: boolean;
  canTraceLink: boolean;
  hasMeasures: boolean;
  canEditMeasurement: boolean;
  canEditReport: boolean;
  canViewReport: boolean;
  isRendererInitialized: boolean;
  canPasteToSamePlace: () => boolean;
  instancesIds: string[];
  position: DrawingContextMenuPosition;
  canToggleFullScreen: boolean;
}

export function useFiltersCache(props: UseFiltersCachePayload): Record<DrawingsContextMenuFilter, boolean> {
  const {
    isFullScreen,
    canPaste,
    canTraceLink,
    hasMeasures,
    canEditMeasurement,
    canEditReport,
    canViewReport,
    isRendererInitialized,
    canPasteToSamePlace,
    instancesIds,
    position,
    canToggleFullScreen,
  } = props;
  const isDrawingSource = !!position?.drawingPoint;
  const canMagicSearch = useCanMagicSearch(instancesIds);
  const canComment = useAbility(Operation.Manage, Subject.Comment2d);
  const hasSelectedInstances = instancesIds.length > 0;
  const currentDrawingInfo = useCurrentDrawingInfo();
  const canUngroup = useCanUngroup(instancesIds);
  const hasRendered = useHasRendered(instancesIds);
  const isDrawingRendered = useSelector<State, boolean>((s) => s.drawings.drawingRenderedStatus);
  const hasVisibleInstances = useHasVisibleInstances();
  const hiddenInstances = useSelector<State, string[]>((s) => s.drawings.hiddenInstances);
  const isOneGroupSelected = useIsOneGroupSelected();
  const hasSelectedAnnotations = useSelector<State, boolean>(
    (s) => s.drawings.userAnnotations.selectedAnnotations.length > 0,
  );
  const hasSelectedGroups = useSelector<State, boolean>((s) => s.drawings.selectGeometryGroup.length > 0);
  const areSelectedInstancesHidden = useAreInstancesIdsHidden(instancesIds);
  const is2dView = useSelector<State, boolean>(s => !s.drawings.is3d);
  const filtersCache: Record<DrawingsContextMenuFilter, boolean> = {
    [DrawingsContextMenuFilter.CanDelete]: hasSelectedInstances || hasSelectedAnnotations || hasSelectedGroups,
    [DrawingsContextMenuFilter.HasSelectedInstances]: hasSelectedInstances,
    [DrawingsContextMenuFilter.IsDrawingSource]: isDrawingSource,
    [DrawingsContextMenuFilter.CanPaste]: canPaste,
    [DrawingsContextMenuFilter.HasHiddenInstances]: hiddenInstances.length > 0,
    [DrawingsContextMenuFilter.CanInstancesHide]: !areSelectedInstancesHidden,
    [DrawingsContextMenuFilter.CanInstancesShow]: areSelectedInstancesHidden,
    [DrawingsContextMenuFilter.CanGroup]: instancesIds.length >= 2,
    [DrawingsContextMenuFilter.CanUngroup]: canUngroup,
    [DrawingsContextMenuFilter.CanTraceLink]: canTraceLink,
    [DrawingsContextMenuFilter.IsSelectOne]: instancesIds.length === 1,
    [DrawingsContextMenuFilter.HasMeasure]: hasMeasures,
    [DrawingsContextMenuFilter.HasVisibleInstances]: hasVisibleInstances,
    [DrawingsContextMenuFilter.NotFullScreen]: !isFullScreen && canToggleFullScreen,
    [DrawingsContextMenuFilter.FullScreen]: isFullScreen && canToggleFullScreen,
    [DrawingsContextMenuFilter.CanEditMeasurement]: canEditMeasurement,
    [DrawingsContextMenuFilter.CanEditReport]: canEditReport,
    [DrawingsContextMenuFilter.CanViewReport]: canViewReport,
    [DrawingsContextMenuFilter.IsGroupSelected]: isOneGroupSelected,
    [DrawingsContextMenuFilter.IsRendererInitialized]: isRendererInitialized,
    [DrawingsContextMenuFilter.CanComment]: canComment,
    [DrawingsContextMenuFilter.CanMoveToGroup]: hasSelectedInstances || hasSelectedGroups,
    [DrawingsContextMenuFilter.IsDrawingOpened]: !!currentDrawingInfo,
    [DrawingsContextMenuFilter.CanPasteInSamePlace]: canPasteToSamePlace(),
    [DrawingsContextMenuFilter.Is2D]: is2dView,
    [DrawingsContextMenuFilter.CanSearch]: canMagicSearch,
    [DrawingsContextMenuFilter.HasRendered]: hasRendered,
    [DrawingsContextMenuFilter.IsDrawingRendered]: isDrawingRendered,
  };
  return filtersCache;
}
