/* eslint-disable indent */
import styled from 'styled-components';


const CELL_COORDINATES_WIDTH_WITH_MARGIN = 85;
const FORMULA_CONTAINER_HEIGHT = 36;
const MOVE_TO_CELL_BUTTON_WIDTH = 36;
const SCROLL_CONTAINER_PADDING = 4;
const OUTSIDE_CONTAINER_WIDTH = CELL_COORDINATES_WIDTH_WITH_MARGIN + MOVE_TO_CELL_BUTTON_WIDTH;
const EXPAND_BUTTON_HEIGHT = 20;

const Container = styled.div<{ isFocused: boolean, reportViewPermission: boolean }>`
  display: flex;
  height: ${FORMULA_CONTAINER_HEIGHT}px;
  width: calc(100% - ${OUTSIDE_CONTAINER_WIDTH}px);
  border-left: 1px solid ${p => p.theme.color.background};
  border-right: 1px solid ${p => p.theme.color.background};
  color: ${props => props.theme.color.mainFont};
  box-sizing: border-box;
  background-color: ${props => props.theme.color.backgroundRush};
  transition: all 0.175s ease-in-out 0s;
  position: relative;

  &:hover {
    i svg {
      fill: ${p => p.reportViewPermission ? p.theme.color.turquoise : p.theme.color.disabled};
    }
  }

  i svg {
    fill: ${p => p.reportViewPermission
      ? p.isFocused
        ? p.theme.color.turquoise
        : p.theme.color.gray
      : p.theme.color.disabled
    };
  }

  & *::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  & *::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  & *::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }

  .wrapped-cell {
    background-color: ${p => p.theme.color.turquoise};
    text-align: center;
    box-shadow: ${p => p.theme.shadow.s};
    height: 24px;
    border-radius: 6px;
    padding: 0 6px;
    margin: 0 5px;
    display: inline-flex;
    align-content: center;
    align-items: center;
  }

  .formula-toolbar-pin {
    cursor: pointer;
    transition: ${props => props.theme.duration.s} ${props => props.theme.function.e};

    * {
      cursor: pointer;
    }

    &:hover {
      transform: scaleX(1.05) scaleY(1.05) scaleZ(1.05);
    }

    &:active {
      transform: scaleX(0.95) scaleY(0.95) scaleZ(0.95);
    }
  }
`;

const ToolbarIcon = styled.i`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: auto 10px auto 15px;
  transition: all 0.175s ease-in-out 0s;
`;

interface InputGroupProps {
  isExpandedBar: boolean;
  value: string;
  isFocused: boolean;
  reportViewPermission: boolean;
  reportEditPermission: boolean;
  scrollContainerRect: DOMRect;
  minWidth: number;
  withExpand: boolean;
}

const InputGroup = styled.span<InputGroupProps>`
  position: ${p => p.isExpandedBar ? 'fixed' : 'relative'};
  align-items: center;
  min-width: ${p => p.minWidth}px; 
  width: ${p => p.isExpandedBar ? `${p.scrollContainerRect.width - 2 * SCROLL_CONTAINER_PADDING}px` : 'unset'};
  height: ${p => p.isExpandedBar ? 'auto' : 'unset'};
  max-height: ${p => p.isExpandedBar
    ? `${window.innerHeight - p.scrollContainerRect.top - EXPAND_BUTTON_HEIGHT
      - SCROLL_CONTAINER_PADDING * 4}px`
    : 'unset'
  };
  outline: 0;
  font-size: 12px;
  line-height: 24px;
  vertical-align: ${p => !p.value.length ? 'middle' : 'top'};
  cursor: text;
  white-space: nowrap;
  display: ${p => p.isExpandedBar ? 'flex' : 'inline-block'};
  flex-wrap: ${p => p.isExpandedBar ? 'wrap' : 'nowrap'};
  row-gap: 10px;
  z-index: 1;
  top: ${p => p.isExpandedBar ? p.scrollContainerRect.top : 0}px;
  background-color: ${p => p.isExpandedBar ? '#00000038' : 'none'};
  padding-top: ${p => p.isExpandedBar && `${SCROLL_CONTAINER_PADDING}px`};
  box-shadow: ${p => p.isExpandedBar ?
    `0 ${SCROLL_CONTAINER_PADDING * 2}px 0 ${SCROLL_CONTAINER_PADDING * 2}px #00000038` : 'none'};
  border-radius: 5px;
  transition: background-color ${p => p.theme.duration.s}, box-shadow ${p => p.theme.duration.s};
  overflow: ${p => p.isExpandedBar ? 'auto' : 'hidden'};
  > span {
    height: 24px;
    min-width: 3.6px;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
  }
  &::before {
    display: ${p => (p.value || p.isFocused || !p.reportViewPermission || !p.reportEditPermission ? 'none' : 'block')};
    content: '${props => (props.placeholder)}';
    position: absolute;
    color: ${p => p.theme.color.disabled};
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

interface ExpandButtonProps {
  rectForPosition: DOMRect;
  isExpandedBar: boolean;
}

const ExpandButton = styled.button<ExpandButtonProps>`
  position: fixed;
  height: ${EXPAND_BUTTON_HEIGHT}px;
  width: 60px;
  background: ${p => p.theme.color.turquoise};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 2px 2px 5px 5px;
  transition: opacity ${p => p.theme.duration.s};
  left: ${p => p.rectForPosition.left + p.rectForPosition.width / 2}px;
  top: ${p => p.rectForPosition.bottom + (p.isExpandedBar ?  SCROLL_CONTAINER_PADDING * 4 : 0)}px;
  transform: translateX(-50%);
  opacity: ${p => p.isExpandedBar ? 1 : 0};
  visibility: ${p => p.isExpandedBar ? 'visible' : 'hidden'};
  svg {
    height: 10px;
    fill: ${p => p.theme.color.white};
    transform: rotate(${p => p.isExpandedBar && '180deg'});
    transition: ${p => p.theme.duration.s};
  }
`;

const ScrollContainer = styled.div`
  width: calc(100% - 248px);
  height: calc(100% - 8px);
  padding: ${SCROLL_CONTAINER_PADDING}px 0;
  flex-grow: 1;
  overflow-x: overlay;
  overflow-y: hidden;
  position: relative;
  :hover {
    ${ExpandButton} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

interface SelectButtonProps {
  isChecked: boolean;
  reportEditPermission: boolean;
}

const SelectButton = styled.div<SelectButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  margin: auto 5px auto 0;
  color: ${p => p.isChecked ? p.theme.color.mainFont : p.theme.color.icon};
  border-radius: 15px;
  transition: all 0.175s ease-in-out 0s;
  cursor: ${p => p.reportEditPermission ? 'pointer' : 'default'};
  svg {
    fill: ${p => p.reportEditPermission ?
      p.isChecked
        ? p.theme.color.turquoise
        : p.theme.color.gray
      : p.theme.color.disabled
    };
  }

  > div {
    display: flex;
    align-items: center;
    height: 30px;
  }

  &:hover {
    > div > p {
      color: ${p => p.reportEditPermission ?
        p.isChecked
          ? p.theme.color.white
          : p.theme.color.turquoise
        : p.theme.color.disabled
      };
    }
    svg {
      fill: ${p => p.reportEditPermission ? p.theme.color.turquoise : p.theme.color.disabled};
    }
  }
`;

export const Styled = {
  Container,
  ToolbarIcon,
  InputGroup,
  SelectButton,
  ScrollContainer,
  ExpandButton,
};
