import { CompanyResourcesApi } from '../../../../api/server';
import { AnimationDataResponse } from '../../interfaces/gantt-chart';

function getAnimationData(
  projectId: number | string,
  calculationId: number,
): Promise<AnimationDataResponse> {
  return CompanyResourcesApi.get(
    `/projects/${projectId}/calculations/${calculationId}/gantt-animation`);
}

export const FourDVisualisationApi = {
  getAnimationData,
};
