import { RectangleButton, Text } from '@kreo/kreo-ui-components';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { KreoDialogActions } from 'common/UIKit';
import { APPLY_TEMPLATE_DIALOG } from '../apply-template-dialog';
import { Styled } from './styled';

interface DispatchProps {
  openDialog: () => void;
}

interface Props extends DispatchProps {

}

const HeaderComponent: React.FC<Props> = ({ openDialog }) => {
  return (
    <Styled.Header>
      <Text fontSize={18}>Applied templates</Text>
      <Styled.ButtonTextWrapper>
        <RectangleButton
          height={20}
          width={80}
          borderRadius={10}
          text={'+ Apply'}
          mood={'secondary'}
          onClick={openDialog}
        />
      </Styled.ButtonTextWrapper>
    </Styled.Header>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return ({
    openDialog: () => dispatch(KreoDialogActions.openDialog(APPLY_TEMPLATE_DIALOG)),
  });
};

export const Header = connect(null, mapDispatchToProps)(HeaderComponent);
