import { delay } from 'redux-saga/effects';

class IteratorBuilderWithCancel<T> {
  private _array: T[];
  private _needClose: boolean;

  constructor(array: T[]) {
    this._array = array;
  }

  public async *getIterator(stepDelay: number = 1, steps: number = 100): AsyncIterableIterator<T> {
    for (let i = 0; i < this._array.length; i++) {
      if (this._needClose) {
        break;
      }
      yield this._array[i];
      if (i % steps === 0) {
        await delay(stepDelay);
      }
    }
  }

  public  cancel(): void {
    this._needClose = true;
  }
}

export class AsyncIterationExecuter {
  protected _iterator: IteratorBuilderWithCancel<any>;
  protected _stepDelay: number;
  protected _steps: number;

  constructor(stepDelay: number = 1, steps: number = 100) {
    this._stepDelay = stepDelay;
    this._steps = steps;
  }

  public async *startIteration<T>(array: T[]): AsyncIterableIterator<T> {
    this.cancel();
    this._iterator = new IteratorBuilderWithCancel<T>(array);
    for await (const value of this._iterator.getIterator(this._stepDelay, this._steps)) {
      yield value;
    }
    this._iterator = null;
  }

  public cancel(): void {
    if (this._iterator) {
      this._iterator.cancel();
    }
  }
}
