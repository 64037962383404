import { Text } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RequestStatus } from 'common/enums/request-status';
import { CurrentCountry } from 'common/environment/current-country-service';
import { State } from 'common/interfaces/state';
import { AccountActions } from '../../../../../units/account/actions/creators';
import { ValidationField } from '../../../../../units/account/actions/payloads';
import { BillingCountryModel } from '../../../../../units/subscription/interfaces/billing-country-model';
import { BillingAddress } from '../../payment-method-manager/billing-address';
import { FillStepScaleSubscription } from '../fill-step-scale-subscription';
import { Styled } from './styled';

interface StateProps {
  availableCountries: BillingCountryModel[];
  vatNumberValidation: string;
  paymentMethodRequestStatus: RequestStatus;
  currentCountry: CurrentCountry;
}

interface DispatchProps {
  resetVatNumberValidation: () => void;
}

interface Props extends StateProps, DispatchProps {
  onCountryIdChanged: (id: string) => void;
  onVatNumberIdChanged: (vatNumber: string) => void;
  countryId: string | null;
  vatNumber: string | null;
  headerText: string;
}

const BillingAddressBlockComponent: React.FC<Props> = (props) => {
  const {
    availableCountries,
    countryId,
    vatNumberValidation,
    vatNumber,
    paymentMethodRequestStatus,
    currentCountry,
    headerText,
    onCountryIdChanged,
    onVatNumberIdChanged,
    resetVatNumberValidation,
  } = props;

  const prevPaymentMethodRequestStatus = usePrevious(paymentMethodRequestStatus);
  const selectedCountryIndex = useMemo(() => availableCountries.findIndex(x => x.id === countryId), [countryId]);
  const selectedCountry = availableCountries[selectedCountryIndex];
  const isVatNumberAvailable = selectedCountry && selectedCountry.allowVatNumber;

  const onVatNumberChange = useCallback((value: string) => {
    onVatNumberIdChanged(value);
    resetVatNumberValidation();
  }, []);

  const setCurrentCountryAsSelected = useCallback(() => {
    const country = availableCountries.find(x => x.id === currentCountry.country);
    if (country) {
      onCountryIdChanged(country.id);
    }
  }, [availableCountries, currentCountry, onCountryIdChanged]);

  const setCountryIfNeeded = useCallback(() => {
    if (
      paymentMethodRequestStatus === RequestStatus.Loaded
      && paymentMethodRequestStatus !== prevPaymentMethodRequestStatus
      && !countryId
      && currentCountry
    ) {
      setCurrentCountryAsSelected();
    }
  }, [paymentMethodRequestStatus, countryId, currentCountry, setCurrentCountryAsSelected]);

  useEffect(() => {
    setCountryIfNeeded();
  }, [setCountryIfNeeded]);

  return (
    <>
      <Styled.FillStepScaleContainer>
        <FillStepScaleSubscription
          numberStep={1}
          isValid={!!countryId}
        />
      </Styled.FillStepScaleContainer>
      <Styled.ContentContainer
        headerMargin={13}
        isVatNumber={isVatNumberAvailable}
      >
        <Text>{headerText}</Text>
        <BillingAddress
          availableCountries={availableCountries}
          selectedCountryId={countryId}
          vatNumber={vatNumber}
          onCountryChange={onCountryIdChanged}
          onVatNumberChange={onVatNumberChange}
          vatNumberErrorText={vatNumberValidation}
          vatCountryLabelWidth={'135px'}
        />
      </Styled.ContentContainer>
    </>
  );
};

function usePrevious(value: any): RequestStatus {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function mapStateToProps(state: State): StateProps {
  return {
    availableCountries: state.account.billingCountries,
    vatNumberValidation: state.account.subscriptionValidation.vatNumberValidation,
    paymentMethodRequestStatus: state.account.paymentStatus,
    currentCountry: state.account.currentCountry,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    resetVatNumberValidation: () => dispatch(AccountActions.setSubscribeError(ValidationField.VatNumber, '')),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export const BillingAddressBlock = connector(BillingAddressBlockComponent);
