import { Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { RadioButton } from 'common/components/radion-button';
import { RadioMenu } from 'common/components/selection-menus';

import { AnnotationFilters } from '../../interfaces/drawing-filters';
import { FilterElement } from './interfaces';


interface Props {
  filterType: AnnotationFilters;
  value: string;
  options: FilterElement[];
  onChangeFilter: (filterType: AnnotationFilters, value: string) => void;
}

export class RadioFilter extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <RadioMenu
        value={this.props.value}
        options={this.props.options}
        customOptionRender={this.renderOption}
        onSelectionChanged={this.onChangeSelection}
      />
    );
  }

  @autobind
  private renderOption(data: FilterElement, selected: boolean): React.ReactNode {
    return (
      <>
        <Text>{data.name}</Text>
        <RadioButton checked={selected} />
      </>
    );
  }

  @autobind
  private onChangeSelection(value: string): void {
    this.props.onChangeFilter(this.props.filterType, value);
  }
}
