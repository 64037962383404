import { SettingsSorting } from '2d/constants/table-settings';

export function getSortingMeasureTypes(
  measurements: Record<string, number | string> | any[],
): any {

  if (Array.isArray(measurements)) {
    return measurements.sort((a, b) => {
      if (a.name) {
        return SettingsSorting[a.name] - SettingsSorting[b.name];
      } else {
        return SettingsSorting[a[0]] - SettingsSorting[b[0]];
      }
    });
  } else {
    const measurementsArr = Object.entries(measurements).sort((a, b) => SettingsSorting[a[0]] - SettingsSorting[b[0]]);
    const measurementsObject = {};

    for (const [key, value] of measurementsArr) {
      measurementsObject[key] = value;
    }

    return measurementsObject;
  }
}
