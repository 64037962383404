import React from 'react';
import { useDispatch } from 'react-redux';

import { KreoDialogActions } from 'common/UIKit';
import { CONTANC_OWNER_DIALOG_NAME } from 'components/dialog/contact-owner-dialog';
import { UPGRADE_PRO_PLAN_CONSTANTS } from '../constants';


type UseHandleClickCallback = (
  canChangeBilling: boolean,
) => () => void;

export const useHandleClickCallback: UseHandleClickCallback = (
  canChangeBilling,
) => {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    if (!canChangeBilling) {
      dispatch(KreoDialogActions.openDialog(CONTANC_OWNER_DIALOG_NAME));
      return;
    }

    dispatch(KreoDialogActions.openDialog(UPGRADE_PRO_PLAN_CONSTANTS.UPGRADE_PLAN_DIALOG));
  }, [canChangeBilling, dispatch]);
};
