import React, { useMemo } from 'react';

import { ProjectCreateStatus } from 'common/enums/project-create-status';
import { RequestStatus } from 'common/enums/request-status';
import { ProjectAccessReason } from 'unit-projects/enums';
import { CompanyProjectHeader } from 'unit-projects/interfaces/company-project-header';
import { TwoDProjectsCardWrapper } from '../../2d-projects-card-wrapper';
import { NoFound } from '../no-found';
import { StatusButton } from '../status-button';

interface Props {
  loaded: boolean;
  allFetched: boolean;
  projectTypeName: string;
  isAdminMode: boolean;
  projectHeaders: Record<number, CompanyProjectHeader>;
  projectHeadersRequestStatus: RequestStatus;
  fetchProjects: () => void;
  onLeaveProject: (projectId: number, projectCreateStatus: ProjectCreateStatus) => void;
  onRemoveProject: (projectId: number, projectCreateStatus: ProjectCreateStatus) => void;
  onInviteToProjectClick: (projectId: number) => void;
  onOpenEditProjectNameDialog: (projectId: number) => void;
  onOpenDuplicateProjectDialog: (projectId: number) => void;
  openMoveToFolderDialog: (projectId: number) => void;
  saveProjectAsTemplate: (projectId: number) => void;
}


export const ProjectsContainer: React.FC<Props> = React.memo((props) => {
  const {
    loaded,
    allFetched,
    projectTypeName,
    projectHeaders,
    fetchProjects,
    onRemoveProject,
    onLeaveProject,
    onInviteToProjectClick,
    onOpenEditProjectNameDialog,
    onOpenDuplicateProjectDialog,
    openMoveToFolderDialog,
    saveProjectAsTemplate,
    projectHeadersRequestStatus,
    isAdminMode,
  } = props;

  const projectIds = useMemo(() => Object.keys(projectHeaders).reverse(), [projectHeaders]);

  return (
    <>
      {projectIds.length
        ? projectIds.map(projectId => {
          const isInaccessibleProject = projectHeaders[projectId].accessReason === ProjectAccessReason.Admin;
          return (
            <TwoDProjectsCardWrapper
              key={projectId}
              projectHeader={projectHeaders[projectId]}
              onRemoveConfirm={onRemoveProject}
              onLeaveProject={onLeaveProject}
              onInviteToProject={onInviteToProjectClick}
              onProjectNameEdit={onOpenEditProjectNameDialog}
              onDuplicateProjectClick={onOpenDuplicateProjectDialog}
              onMoveProjectClick={openMoveToFolderDialog}
              isAdminMode={isAdminMode}
              onSaveAsTemplateClick={saveProjectAsTemplate}
              isInaccessibleProject={isInaccessibleProject}
            />
          );
        })
        : loaded && (<NoFound projectTypeName={projectTypeName} />)
      }
      {!allFetched && (
        <StatusButton
          status={projectHeadersRequestStatus}
          fetchProjects={fetchProjects}
        />
      )}
    </>
  );
});
