import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './filter-select.scss';

import { PreparedSearchQuery } from 'common/utils/string-utils';
import { MultiLevelDropDownMenu } from '../multi-level-drop-down-menu';
import { DropDownPositioning } from '../multi-level-drop-down-menu/multi-level-drop-down';
import { SvgSpinner } from '../svg-spinner';
import { FilterSelectOption } from './filter-select-option';
import { FilterOption, FilterValue } from './interfaces';

interface Props {
  value: FilterValue[];
  entityName: string;
  onChangeSelectedValue: (value: FilterValue[]) => void;
  options: FilterOption[];
  className?: string;
  containerClassName?: string;
  selectTitle?: JSX.Element;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  onSearchQueryChange?: (searchQuery: string) => void;
  onOpen?: () => void;
  compare?: () => boolean;
  dropDownPositioning?: DropDownPositioning;
  optionContentRenderer?: (option: FilterOption, query: PreparedSearchQuery, isSelected: boolean) => React.ReactNode;
}

export class FilterSelect extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { selectTitle, onScroll, onSearchQueryChange, onOpen, compare, className, dropDownPositioning } = this.props;
    const { dropDownValue, dropDownOption } = this.getDropDownProps();

    const containerClassName = classNames(
      'filter-select__container',
      this.props.containerClassName,
    );

    return (
      <MultiLevelDropDownMenu<FilterOption>
        value={dropDownValue}
        options={dropDownOption}
        onSelect={this.onChange}
        optionContentRenderer={this.optionContentRenderer}
        containerClassName={containerClassName}
        className={className}
        multiple={true}
        onScroll={onScroll}
        onSearchQueryChange={onSearchQueryChange}
        onOpen={onOpen}
        compare={compare}
        dropDownPositioning={dropDownPositioning}
        isHighlightOnSelect={true}
      >
        {selectTitle}
      </MultiLevelDropDownMenu>
    );
  }

  private getDropDownProps(): { dropDownValue: string[], dropDownOption: FilterOption[] } {
    const { options, value } = this.props;

    const dropDownValue = value.map(this.mapFilterValueToDropDownValue);
    const dropDownOption = options.map(option => ({
      ...option,
      value: this.mapFilterValueToDropDownValue(option),
    }));

    return { dropDownValue, dropDownOption };
  }

  private renderOption(
    option: FilterOption,
    _: PreparedSearchQuery,
    isSelected: boolean,
  ): JSX.Element {
    return <FilterSelectOption selected={isSelected} option={option} />;
  }

  @autobind
  private optionContentRenderer(
    option: FilterOption,
    query: PreparedSearchQuery,
    isSelected: boolean,
  ): React.ReactNode {
    if (option.optionType === 'spinner') {
      return <SvgSpinner size='small' />;
    }

    if (this.props.optionContentRenderer) {
      return this.props.optionContentRenderer(option, query, isSelected);
    }

    return this.renderOption(option, query, isSelected);
  }

  @autobind
  private onChange(value: string[]): void {
    this.props.onChangeSelectedValue(value.map(this.getFilterValue));
  }

  private getFilterValue(value: string): FilterValue {
    if (value === null) return null;
    const matches = value.match(/(.+)\|(.+)/);
    return {
      value: matches[1],
      name: matches[2],
    };
  }

  private mapFilterValueToDropDownValue(filterValue: FilterValue | FilterOption): string {
    return filterValue.value === null ? null : `${filterValue.value}|${filterValue.name}`;
  }
}
