const storage: Record<string, any> = {};

export class AgGridDataTransferStorage {
  public static contain(key: string): boolean {
    return storage[key] !== undefined;
  }

  public static set(key: string, data: any): void {
    storage[key] = { data, isBlock: false };
  }

  public static get(key: string): any {
    const keyValue = storage[key];
    return keyValue.isBlock
      ? undefined
      : keyValue.data;
  }

  public static block(key: string): void {
    if (storage[key]) {
      storage[key].isBlock = true;
    }
  }

  public static unBlock(key: string): void {
    if (storage[key]) {
      storage[key].isBlock = false;
    }
  }

  public static remove(key: string): void {
    delete storage[key];
  }
}
