import { IconButton } from '@kreo/kreo-ui-components';
import React from 'react';

import { RenderIf } from 'common/components/render-if';
import { Styled } from './styled';

interface Props {
  Icon?: React.ComponentType;
  startEditing?: (e: React.MouseEvent) => void;
}

export const ValueWithEditIcon: React.FC<Props> = ({ children, startEditing, Icon }) => {
  return (
    <>
      <RenderIf condition={!!startEditing && !!Icon}>
        <Styled.StartEditingButton>
          <IconButton
            Icon={Icon}
            onClick={startEditing}
            width={20}
            height={20}
            iconHeight={10}
          />
        </Styled.StartEditingButton>
      </RenderIf>
      {children}
    </>
  );
};
