import * as t from 'io-ts';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { UserMap } from 'common/interfaces/people';
import { NotificationProcessor } from 'common/interfaces/realtime-messages';
import { CodecUtils } from 'common/utils/codec-utils';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { AppUrls } from '../../../../routes/app-urls';
import { AccountApi } from '../../../account/api';
import { CommonNotificationProcessor } from '../../../notifications/common-notification-processor';
import { PeopleActions } from '../../../people/actions/actions';
import { ViewerState } from '../../../viewer';
import { ViewerActions } from '../../../viewer/actions';
import {
  ViewerCommentNotification,
  ViewerCommentNotificationC,
  ViewerCommentStatusNotification,
  ViewerCommentStatusNotificationC,
  ViewerRemoveCommentNotification,
  ViewerRemoveCommentNotificationC,
  ViewerSubCommentNotification,
  ViewerSubCommentNotificationC,
} from '../../interfaces/viewer-notification-data';
import { ViewerNotificationKeys } from '../keys/viewer';

function* callIfUserIsOnViewerPage<TResponseValidator extends t.Any>(
  processor: (data: t.TypeOf<TResponseValidator>) => SagaIterator,
  data: any,
  validator: TResponseValidator,
): SagaIterator {
  try {
    const decodedValue = CodecUtils.decode(data, validator);
    const isOnViewerPage = window.location.pathname.startsWith(
      AppUrls.plan.project.viewer.url({ projectId: decodedValue.projectId && decodedValue.projectId.toString() }),
    );

    if (isOnViewerPage) {
      yield call(processor, decodedValue);
    }
  } catch (error) {
    console.error('Error in ViewerNotificationDataProcessor.callIfUserIsOnViewerPage', error);
  }
}

function* addComment({ data }: ViewerCommentNotification): SagaIterator {
  try {
    let userMap: UserMap = yield selectWrapper(state => state.people.usersmap && state.people.usersmap[data.owner]);
    if (!userMap) {
      userMap = yield call(AccountApi.getUsersInfo, [data.owner]);
      yield put(PeopleActions.addToUserMap(userMap));
    }
    yield put(ViewerActions.createCommentSuccessed(data));
  } catch (error) {
    console.error('Error in ViewerNotificationDataProcessor.addComment', error);
  }
}

function* addSubComment({ data }: ViewerSubCommentNotification): SagaIterator {
  try {
    const [userMap, viewersets]: [UserMap, ViewerState] = yield selectWrapper(state => [
      state.people.usersmap[data.comment.authorGuid],
      state.viewersets,
    ]);
    if (!userMap) {
      const newUserMap = yield call(AccountApi.getUsersInfo, [data.comment.authorGuid]);
      yield put(PeopleActions.addToUserMap(newUserMap));
    }
    if (viewersets.comment.id === data.commentId) {
      yield put(ViewerActions.readComment(data.commentId));
    }
    yield put(ViewerActions.addSubcommentSuccessed(data.commentId, data.linkCommentId, data.comment));
  } catch (error) {
    console.error('Error in ViewerNotificationDataProcessor.addSubComment', error);
  }
}

function* removeComment({ data: commentId }: ViewerRemoveCommentNotification): SagaIterator {
  try {
    const viewersets: ViewerState = yield selectWrapper(state => state.viewersets);
    if (viewersets.comment.id === commentId) {
      yield put(ViewerActions.backToComments());
    }
    yield put(ViewerActions.removeCommentSuccess(commentId));
  } catch (error) {
    console.error('Error in ViewerNotificationDataProcessor.removeComment', error);
  }
}

function* updateCommentStatus({ data }: ViewerCommentStatusNotification): SagaIterator {
  try {
    const viewersets: ViewerState = yield selectWrapper(state => state.viewersets);
    if (viewersets.comment.id === data.commentId) {
      yield put(ViewerActions.updateCurrentCommentStatus(data.status));
    }

    yield put(ViewerActions.updateCommentStatus(data.commentId, data.status));
  } catch (error) {
    console.error('Error in ViewerNotificationDataProcessor.updateCommentStatus', error);
  }
}


export const ViewerNotificationDataProcessor: NotificationProcessor = {
  [ViewerNotificationKeys.ViewerAddComment]: notification =>
    callIfUserIsOnViewerPage(addComment, notification.data, ViewerCommentNotificationC),
  [ViewerNotificationKeys.ViewerAddSubComment]: notification =>
    callIfUserIsOnViewerPage(addSubComment, notification.data, ViewerSubCommentNotificationC),
  [ViewerNotificationKeys.ViewerRemoveComment]: notification =>
    callIfUserIsOnViewerPage(removeComment, notification.data, ViewerRemoveCommentNotificationC),
  [ViewerNotificationKeys.ViewerUpdateCommentStatus]: notification =>
    callIfUserIsOnViewerPage(updateCommentStatus, notification.data, ViewerCommentStatusNotificationC),
  [ViewerNotificationKeys.ViewerNotification]: CommonNotificationProcessor.addNotification,
};
