import React from 'react';

import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { INVALIED_CHANGE_UNIT } from '../../constants';

const TITLE = `Can't save the Property`;
const TEXT =
  `Some of your values are text, but selected unit is numerical. Edit or remove your values or set "text" unit.`;

export const InvalidChangeUnitDialog = (): JSX.Element => {
  return (
    <ConfirmationDialog
      name={INVALIED_CHANGE_UNIT}
      title={TITLE}
      text={TEXT}
      cancelButtonText='Okay'
      zIndex={99999}
    />
  );
};
