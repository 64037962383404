export class Clipboard {
  private static _data: string = null;

  // eslint-disable-next-line @typescript-eslint/dot-notation
  private static POS: boolean = !!window['clipboardData'];

  public static  init = (): void => {
    window.addEventListener('copy', (e: ClipboardEvent) => {
      if (Clipboard._data) {
        e.clipboardData.setData('text/plain', Clipboard._data);
        e.preventDefault();
        Clipboard._data = null;
      }
    });
  };

  public static  copy = (data: string): void => {
    Clipboard._data = data;
    if (!Clipboard.POS) {
      document.execCommand('copy');
    // eslint-disable-next-line @typescript-eslint/dot-notation
    } else if (window['clipboardData']) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['clipboardData'].setData('Text', Clipboard._data);
      Clipboard._data = null;
    }
  };
}

Clipboard.init();
