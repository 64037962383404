import { Text } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { mathUtils } from 'common/utils/math-utils';
import { Currency } from '../../constants';

interface StateProps {
  selectedCurrency: string;
  currencyRates: Record<string, number>;
}

interface Props extends StateProps {
  GBPPrice: number;
}

const PriceFieldComponent = ({ GBPPrice, selectedCurrency, currencyRates }: Props): JSX.Element => {
  const price = useMemo(
    () => mathUtils.round(GBPPrice * currencyRates[selectedCurrency], 2),
    [GBPPrice, selectedCurrency, currencyRates],
  );
  return (<Text>{Currency.GBP.symbol} {price}</Text>);
};

const mapStateToProps = (state: State): StateProps => {
  const isCurrencyRateExist = state.account.currencyRates &&
    state.persistedStorage.selectedCurrency &&
    state.account.currencyRates[state.persistedStorage.selectedCurrency];

  return {
    selectedCurrency: isCurrencyRateExist
      ? state.persistedStorage.selectedCurrency
      : Currency.GBP.shortcat,
    currencyRates: state.account.currencyRates,
  };
};

export const PriceField = connect(mapStateToProps)(PriceFieldComponent);
