import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { KreoIconCollapseExpand } from 'common/UIKit';
import { CollapseExpandButton } from './collapse-expand-button';

interface Props {
  className: string;
  saveSplitterRef: (ref: HTMLDivElement) => void;
  onMouseDown: (separatorIndex: number) => void;
  separatorIndex: number;
  isToggleVisible?: boolean;
  onHorizontalToggle?: (index: number) => void;
  onClickCollapseExpandButton?: () => void;
}

export class Separator extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <div
        role='separator'
        className={classNames('layout-splitter', this.props.className)}
        ref={this.props.saveSplitterRef}
        onMouseDown={this.onMouseDown}
      >
        {
          this.props.isToggleVisible &&
          <div
            className='layout-splitter__toggle'
            onClick={this.onHorizontalToggle}
          >
            <KreoIconCollapseExpand />
          </div>
        }
        <CollapseExpandButton
          togglePanel={this.props.onClickCollapseExpandButton}
        />
      </div>
    );
  }

  @autobind
  private onHorizontalToggle(): void {
    this.props.onHorizontalToggle(this.props.separatorIndex);
  }

  @autobind
  private onMouseDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    this.props.onMouseDown(this.props.separatorIndex);
    ConstantFunctions.stopEvent(event);
  }
}
