
import { ExcelExpressionEvaluator } from 'common/excel-expression-evaluator';
import { arrayUtils } from 'common/utils/array-utils';

export const FORMULA_FUNCTIONS = [
  'ABS',
  'AND',
  'AVERAGE',
  'CHOOSE',
  'CONCAT',
  'COS',
  'COT',
  'COUNT',
  'DEGREES',
  'FIND',
  'IF',
  'LEFT',
  'LEN',
  'MAX',
  'MEDIAN',
  'MID',
  'MIN',
  'NOT',
  'OR',
  'PI',
  'POWER',
  'RADIANS',
  'RIGHT',
  'ROUND',
  'ROUNDDOWN',
  'ROUNDUP',
  'SIN',
  'SQRT',
  'SWITCH',
  'TAN',
];


export const FORMULA_FUNCTION_ARGUMENTS_COUNT = arrayUtils.toDictionary(
  FORMULA_FUNCTIONS,
  x => x,
  x => ExcelExpressionEvaluator.functions[x.toLowerCase()].length,
);
