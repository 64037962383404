import * as React from 'react';
import { connect } from 'react-redux';

import './four-d-side-panel.scss';

import { State } from 'common/interfaces/state';
import { KreoEngine } from '../../../../../../components/engine/KreoEngine';
import { ChartDataProvider } from '../../../../utils/gantt-chart';
import { SidePanelTab } from '../../enums/side-panel-tab';
import { ActivitiesTab } from '../activities-tab';
import { FourDSidePanelHeader } from '../four-d-side-panel-header';
import { ResourcesTab } from '../resources-tab';


interface PanelOwnProps {
  height: number;
  width: number;
  currentMoment: number;
  nextCurrentMoment: number | null;
  dataProvider: ChartDataProvider;
  engine: KreoEngine;
  timeframeStartDay: number;
  timeframeDuration: number;
  updateCurrentMoment: (currentMoment: number, force?: boolean) => void;
  selectElementOnEngine: (bimId: number) => void;
}

interface PanelStateProps {
  activeTab: SidePanelTab;
}

interface PanelProps extends PanelOwnProps, PanelStateProps { }

class FourDSidePanelComponent extends React.Component<PanelProps> {
  private readonly headerHeight: number = 70;

  public render(): React.ReactNode {
    return (
      <div
        className='four-d-side-panel'
        style={{
          height: this.props.height,
          width: this.props.width,
        }}
      >
        <FourDSidePanelHeader />
        {
          this.props.activeTab === SidePanelTab.Resources &&
          <ResourcesTab
            height={this.props.height - this.headerHeight}
            width={this.props.width}
            currentMoment={this.props.currentMoment}
            nextCurrentMoment={this.props.nextCurrentMoment}
            dataProvider={this.props.dataProvider}
            timeframeStartDay={this.props.timeframeStartDay}
            timeframeDuration={this.props.timeframeDuration}
          />
        }
        {
          this.props.activeTab === SidePanelTab.Activities &&
          <ActivitiesTab
            currentMoment={this.props.currentMoment}
            engine={this.props.engine}
            dataProvider={this.props.dataProvider}
            updateCurrentMoment={this.props.updateCurrentMoment}
            selectElementOnEngine={this.props.selectElementOnEngine}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state: State): PanelStateProps => {
  return {
    activeTab: state.fourDVisualisation.sidePanel.activeTab,
  };
};

export const FourDSidePanel = connect(mapStateToProps)(FourDSidePanelComponent);
