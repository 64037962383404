import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

interface Props {
  color?: string;
}

export const KreoIconActionBig: React.FC<Props> = (props: Props) => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
    <g id='icon_action_big_dark' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          // eslint-disable-next-line max-len
          d='M1.5,6 C2.32842712,6 3,6.67157288 3,7.5 C3,8.32842712 2.32842712,9 1.5,9 C0.671572875,9 1.01453063e-16,8.32842712 0,7.5 C-1.01453063e-16,6.67157288 0.671572875,6 1.5,6 Z M7.5,6 C8.32842712,6 9,6.67157288 9,7.5 C9,8.32842712 8.32842712,9 7.5,9 C6.67157288,9 6,8.32842712 6,7.5 C6,6.67157288 6.67157288,6 7.5,6 Z M13.5,6 C14.3284271,6 15,6.67157288 15,7.5 C15,8.32842712 14.3284271,9 13.5,9 C12.6715729,9 12,8.32842712 12,7.5 C12,6.67157288 12.6715729,6 13.5,6 Z'
          id='Combined-Shape'
          fill={props.color ? props.color : KreoColors.f1}
        />
    </g>
    </svg>
  );
};
