import { TinyText, Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Apply, BaseSelectedStatus, DialogContainerStyled, Header, KeepOld } from './components';

interface Props {
  toggleKeepPolygons: () => void;
  switchToPolygonSelectMode: () => void;
  isKeepPolygons: boolean;
  isBasePolygonSelected: boolean;
  apply: () => void;
  cancel: () => void;
}

export const SubtractDialog = React.memo<Props>(
  ({ toggleKeepPolygons, switchToPolygonSelectMode, isKeepPolygons, isBasePolygonSelected, apply, cancel }) => {
    return (
      <DialogContainerStyled.Container>
        <Header title="Subtract" Icon={Icons.SubtractEmpty} />
        <BaseSelectedStatus
          caption="Base polygon selected"
          isBaseSelected={isBasePolygonSelected}
          switchToSelectMode={switchToPolygonSelectMode}
        />
        <KeepOld toggleKeepOld={toggleKeepPolygons} isKeepOld={isKeepPolygons}>
          <TinyText color={'gray'}>Keep polygons</TinyText>
        </KeepOld>
        <Apply apply={apply} cancel={cancel} />
      </DialogContainerStyled.Container>
    );
  },
);
