import { useCallback } from 'react';

const TableSymbolRegex = /\|/g;
const RepalceTalbeSignRegex = /\||\-/g;

function convertTableLine(line: string): string {
  return line.slice(1, -1).replace(TableSymbolRegex, '\t');
}

function convertBeforeAddToClipboard(content: string): string {
  const splitted = content.split('\n');
  let result = '';
  for (const line of splitted) {
    if (line[0] === '|') {
      if (line.replace(RepalceTalbeSignRegex, '').trim() === '') {
        continue;
      }
      const lineWithoutTable = convertTableLine(line);
      result = `${result}${lineWithoutTable}\n`;
    } else {
      result = `${result}${line}\n`;
    }
  }
  return result;
}


export function useCopy(content: string): () => void {
  return useCallback(() => {
    const contentToCopy = convertBeforeAddToClipboard(content);
    navigator.clipboard.writeText(contentToCopy);
  }, [content]);
}
