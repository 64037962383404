import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { CustomDialogStyled } from 'common/components/custom-dialog';
import { TwoDItemContextMenuStyled } from 'common/components/item-context-menu';

export const TOOLTIP_CLASSNAME = 'ag-tooltip';

type SlowTopAppearWithXPositionType = (xPosition: string, start: number, end: number) => any;
const slowTopAppearWithXPosition: SlowTopAppearWithXPositionType =
  Constants.Animations.slowTopAppearWithXPosition as any;

const ModalRoot = styled.div`
  ${`.${TOOLTIP_CLASSNAME}`} {
    position: fixed;
    transform: translate(-50%, -30px);
    animation: ${slowTopAppearWithXPosition('-50%', -10, -30)} ${p => p.theme.duration.m};
    background-color: ${props => props.theme.color.background};
    color: ${props => props.theme.color.mainFont};
    font-size: 12px;
    line-height: 14px;
    padding: 8px 30px;
    box-shadow: ${props => props.theme.shadow.xl};
    border-radius: 15px;
  }

  > div {
    > p {
      white-space: normal;
    }
  }

  :has(${TwoDItemContextMenuStyled.ItemMenuContentWrapper}) {
    .context-menu {
      box-shadow: ${p => p.theme.shadow.m};
      padding: 0;
    }
  }

  ${CustomDialogStyled.Container} {
    max-height: calc(100% - 80px);
    max-width: calc(100vw - 20px);
  }
`;

export const Styled = {
  ModalRoot,
};
