import { Assembly, Item, Property } from 'unit-2d-database/interfaces';
import { AssemblyGroupData } from '../../assembly-group-form/assembly-group-form';
import { AssemblyForm } from '../../assembly-panel/item-form-updater';
import { mapItemGroupForm } from '../../assembly-panel/map-items-to-form';
import { IAssemblyUpdater } from './assembly-form-updater/assembly-form-updater';

export type GroupForm = Record<string, AssemblyGroupData>;

const getAssemblyForm = (
  assembly: Assembly,
  assemblyGroupUpdater: IAssemblyUpdater,
  units: string[],
  onFormulaFieldClick: (assemblyId: string, itemId: string, id: string, groupName: string) => void,
  onBreakdownFieldClick: (assemblyId: string, itemId: string, id: string, groupName: string) => void,
  propertiesDatabase: Property[],
  itemDatabase: Item[],
): AssemblyForm  => {
  const itemGroupForm = mapItemGroupForm(
    assembly.items,
    assemblyGroupUpdater.getItemGroupUpdater(assembly.name),
    units,
    (itemId, id, groupName) => onFormulaFieldClick(assembly.name, itemId, id, groupName),
    (itemId, id, groupName) => onBreakdownFieldClick(assembly.name, itemId, id, groupName),
    propertiesDatabase,
    itemDatabase,
    item => item.name,
    property => property.name,
  );
  return {
    addedItems: {},
    removedItems: {},
    itemGroupForm,
  };
};

export const mapAssemblyGroupForm = (
  assemblies: Assembly[],
  assemblyGroupUpdater: IAssemblyUpdater,
  units: string[],
  onFormulaFieldClick: (assemblyId: string, itemId: string, id: string, groupName: string) => void,
  onBreakdownFieldClick: (assemblyId: string, itemId: string, id: string, groupName: string) => void,
  assembliesDataBase: Assembly[],
  propertiesDatabase: Property[],
  itemDatabase: Item[],
): GroupForm => {
  const groups: GroupForm = {};
  for (const assembly of assemblies) {
    const assemblyForm = getAssemblyForm(
      assembly,
      assemblyGroupUpdater,
      units,
      onFormulaFieldClick,
      onBreakdownFieldClick,
      propertiesDatabase,
      itemDatabase,
    );
    const [ onDelete ] = assemblyGroupUpdater.getAssemblyHandlers(assembly.name);
    const originAssembly = assembliesDataBase.find(a => a.name === assembly.name);
    const originId = originAssembly ? originAssembly.id : assembly.name;
    const group: AssemblyGroupData = {
      id: assembly.name,
      originId,
      name: assembly.name,
      assemblyGroups: assemblyForm,
      onDelete,
    };
    groups[assembly.name] = group;
  }
  return groups;
};
