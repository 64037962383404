const prefix = `@admin-people`;

const LOAD_REQUEST = `${prefix}/LOAD_REQUEST`;
const LOAD_SUCCEEDED = `${prefix}/LOAD_SUCCEEDED`;
const DELETE_REQUEST = `${prefix}/DELETE_USER_REQUEST`;
const DELETE_SUCCEEDED = `${prefix}/DELETE_USER_SUCCEEDED`;
const ENABLE_USER_REQUEST = `${prefix}/ENABLE_USER_REQUEST`;
const ENABLE_USER_SUCCEEDED = `${prefix}/ENABLE_USER_SUCCEEDED`;
const UPDATE_USER_ATTRIBUTE_REQUEST = `${prefix}/UPDATE_USER_ATTRIBUTE_REQUEST`;
const UPDATE_USER_ATTRIBUTE_SUCCEED = `${prefix}/UPDATE_USER_ATTRIBUTE_SUCCEED`;
const DELETE_OWNED_COMPANY = `${prefix}/DELETE_OWNED_COMPANY`;
const CHANGE_USER_EMAIL = `${prefix}/CHANGE_USER_EMAIL`;

export const AdminPeopleActionTypes = {
  LOAD_REQUEST,
  LOAD_SUCCEEDED,
  DELETE_REQUEST,
  DELETE_SUCCEEDED,
  ENABLE_USER_REQUEST,
  ENABLE_USER_SUCCEEDED,
  UPDATE_USER_ATTRIBUTE_REQUEST,
  UPDATE_USER_ATTRIBUTE_SUCCEED,
  DELETE_OWNED_COMPANY,
  CHANGE_USER_EMAIL,
};
