import { Text } from '@kreo/kreo-ui-components';
import React from 'react';
import { Styled } from '../styled';


interface Props {
  title: string;
}

export const InformationLine: React.FC<Props> = ({ title, children }) => {
  return (
    <Styled.InformationLine>
      <Text
        fontSize={10}
        lineHeight={21}
        textTransform={'uppercase'}
        color={'turquoise'}
      >
        {title}
      </Text>
      {children}
    </Styled.InformationLine>
  );
};

