import * as paper from 'paper';
import { ShortPointDescription } from '../../interfaces';
import { Vector2Utils } from '../../utils/math-utils/vector2-utils';
import { DrawingsCursorTypeHelper } from '../drawings-helpers';
import { TextSearchResultRect, TextSearchResultRectConfig } from './text-search-result-rect';

interface Config extends TextSearchResultRectConfig {
  cursorHelper: DrawingsCursorTypeHelper;
  text: string;
  onClick: (text: string) => void;
}

export class TextRect extends TextSearchResultRect<Config> {
  public get text(): string {
    return this._config.text;
  }

  protected override render([point1, point2]: ShortPointDescription[]): void {
    this._config.cursorHelper.hovered = true;
    const rectangle = new paper.Rectangle(
      new paper.Point(point1),
      new paper.Size(...Vector2Utils.subtract(point2, point1)));
    this._path = new paper.Path.Rectangle(rectangle);
    this._path.onClick = () => this._config.onClick(this._config.text);
    this._path.onMouseEnter = () => this._config.cursorHelper.hovered = true;
    this._path.onMouseLeave = () => this._config.cursorHelper.hovered = false;
    this.applyStyles();
    this._path.addTo(this._config.layer);
  }
}
