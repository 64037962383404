import { Constants } from 'common/components/data-base-table/constants';
import { Constants as TreeFormConstants } from '../constants';
import { PiaBreakdownNode, TreePropertyRowData } from '../interfaces';

export interface Option {
  id: string;
  description: string;
  code: string;
  order?: number;
  parentId?: string;
}

const getPath = (parentPath: string, id: string): string => {
  return parentPath ? `${parentPath}${Constants.DATA_PATH_SPLITER}${id}` : id;
};

const getCode = (parentCode: string, code: number): string => {
  return parentCode
    ? `${parentCode}${TreeFormConstants.codeSeparator}${code}`
    : code.toString();
};

export const getRow = (
  option: PiaBreakdownNode,
  codeNumber: number,
  index: number,
  parentPath: string = '',
  parentCode: string = '',
): TreePropertyRowData[] => {
  const path = getPath(parentPath, option.id);
  const code = getCode(parentCode, option.code || codeNumber);
  const codeParts = code.split(TreeFormConstants.codeSeparator);
  const codePrefix = codeParts.slice(0, codeParts.length - 2).join(TreeFormConstants.codeSeparator);
  const codePostfix = codeParts[codeParts.length - 1];
  const root = {
    id: option.id,
    name: ' ',
    desc: option.name,
    code,
    codePostfix,
    codePrefix,
    order: index,

    h_isDragSource: true,
    h_isDragTarget: true,
    h_path: path,
  };

  let children = [];
  let nextCode = 1;
  option.children.forEach((c, i) => {
    nextCode = c.code
      ? Number(c.code)
      : nextCode;
    const rows = getRow(c, nextCode, i, path, code);
    nextCode++;
    children = children.concat(rows);
  });

  return [root, ...children];
};
