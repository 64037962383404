export const ValidationNotificationKeys = {
  StateChanged: 'ValidationStateChanged',
  ClassificationStarted: 'ValidationClassificationStarted',
  ClassificationPassed: 'ValidationClassificationPassed',
  ClassificationFailed: 'ValidationClassificationFailed',
  ClassificationEdited: 'ValidationClassificationEdited',
  ValidationActivityAssignmentStarted: 'ValidationActivityAssignmentStarted',
  ActivityAssignmentPassed: 'ValidationActivityAssignmentPassed',
  ActivityAssignmentFailed: 'ValidationActivityAssignmentFailed',
  ActivityAssignmentEdited: 'ValidationActivityAssignmentEdited',
  ValidationMeasurementStarted: 'ValidationMeasurementStarted',
  MeasurementPassed: 'ValidationMeasurementPassed',
  MeasurementFailed: 'ValidationMeasurementFailed',
  MeasurementEdited: 'ValidationMeasurementEdited',
  Completed: 'ValidationCompleted',
  Failed: 'ValidationFailed',
  StepApproved: 'ValidationStepApproved',
  StepReverted: 'ValidationStepReverted',
  ClassificationStepDissapproved: 'ValidationClassificationStepDisapproved',
};
