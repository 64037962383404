const MAIN_VALUE = 'main';
const COUNT = 'count';

const quantitiesKeys = {
  netVolume: 'ef_net_volume',
  netSideSurfaceArea: 'ef_net_side_surface_area',
  netTopArea: 'ef_net_top_area',
  netBottomArea: 'ef_net_bottom_area',
  quantity: 'ef_quantity',
  length: 'ef_length',
  outerPerimeter: 'ef_outer_perimeter',
};

const geometryInstrumentsKeys = {
  height: 'height',
  count: 'count',
  guidelines: 'guidelines',
  width: 'width',
  horizontalSection: 'horizontal_section',
};

const templatesKeyNames: Record<string, string> = {
  [geometryInstrumentsKeys.height]: 'HEIGHT',
  [geometryInstrumentsKeys.count]: 'COUNT',
  [geometryInstrumentsKeys.guidelines]: 'LENGTH',
  [geometryInstrumentsKeys.width]: 'WIDTH',
  [geometryInstrumentsKeys.horizontalSection]: 'HORIZONTAL SECTION',
  [quantitiesKeys.length]: 'LENGTH',
  [quantitiesKeys.netVolume]: 'NET VOLUME',
  [quantitiesKeys.netSideSurfaceArea]: 'NET SIDE SURFACE AREA',
  [quantitiesKeys.netBottomArea]: 'NET BOTTOM AREA',
  [quantitiesKeys.quantity]: 'QUANTITY',
  [quantitiesKeys.outerPerimeter]: 'OUTER PERIMETER',
  [quantitiesKeys.netTopArea]: 'NET TOP AREA',
};

export const DrawingsElementTemplatesConstants = {
  MAIN_VALUE,
  COUNT,
  templatesKeyNames,
  quantitiesKeys,
};

