export enum Resources {
  Labour = 0,
  Plant,
  Material,
}

export const ResourcesData = [
  {
    id: Resources.Labour,
    name: 'Labour',
    color: '#52bad5',
  },
  {
    id: Resources.Plant,
    name: 'Plant',
    color: '#6188cc',
  },
  {
    id: Resources.Material,
    name: 'Material',
    color: '#FAC873',
  },
];
