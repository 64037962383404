import { arrayUtils } from 'common/utils/array-utils';
import { ProjectAccessReason } from 'unit-projects/enums';
import { ProjectFilter } from './interfaces';


const filters: ProjectFilter[] = [
  {
    name: 'All projects',
    accessReasons: [
      ProjectAccessReason.Admin,
      ProjectAccessReason.Owner,
      ProjectAccessReason.Shared,
      ProjectAccessReason.CompanyShared,
    ],
    adminPermissionRequired: true,
  },
  {
    name: 'My projects',
    accessReasons: [ProjectAccessReason.Owner, ProjectAccessReason.Shared, ProjectAccessReason.CompanyShared],
  },
  {
    name: 'Only my projects',
    accessReasons: [ProjectAccessReason.Owner],
  },
  {
    name: 'Only invited',
    accessReasons: [ProjectAccessReason.Shared, ProjectAccessReason.CompanyShared],
  },
];

export const ProjectAccessFilterConstants = {
  defaultProjectsFilterName: 'My projects',
  filters,
  adminFilters: filters.map(x => x.name),
  nonAdminFilters: arrayUtils.filterMap(filters, x => !x.adminPermissionRequired, x => x.name),
};
