import autobind from 'autobind-decorator';
import * as React from 'react';
import { TrackerProvider } from '../../utils/tracker-provider';

export class ChatLink extends React.Component {
  private get intercomTracker(): IntercomTracker {
    return TrackerProvider.intercomTracker();
  }

  public render(): React.ReactNode {
    return (
      <div onClick={this.openChat}>
        {this.props.children}
      </div>
    );
  }

  @autobind
  private openChat(): void {
    if (this.intercomTracker) {
      this.intercomTracker('show');
    }
  }
}
