import autobind from 'autobind-decorator';
import React from 'react';

import './database-activity-variant-type.scss';

import { ConstantFunctions } from 'common/constants/functions';
import { Input } from '../../../../components/controls/inputs';
import { Toggle, ToggleSize } from '../../../../components/toggle';
import { ActivityVariantType } from '../../interfaces/data';

interface Props {
  fieldKey: string;
  readonly: boolean;
  type: ActivityVariantType;
  onChange: (value: ActivityVariantType) => void;
}

export class DatabaseActivityVariantType extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='database-activity-variant-type'>
        <b className='database-activity-variant-type__header'>
          Materials' relation type
        </b>
        <div className='database-activity-variant-type__toggle'>
          <div>Independent</div>
          <Toggle
            checked={this.props.type === ActivityVariantType.ResourceBasedCrewHours}
            onCheck={!this.props.readonly ? this.onChange : null}
            toggleSize={ToggleSize.Medium}
          />
          <div>Materials mix</div>
          <Input
            className='visually-hidden'
            value={this.props.type}
            onChange={ConstantFunctions.doNothing}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onChange(value: boolean): void {
    this.props.onChange(value ? ActivityVariantType.ResourceBasedCrewHours : ActivityVariantType.FixedCrewHours);
  }
}
