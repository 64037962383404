import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { Styled } from './styled';

interface StarProps {
  rating: number;
  highlight: boolean;
  onMouseOver: (rating: number) => void;
  onMouseLeave: (rating: number) => void;
  onSelect: (rating: number) => void;
}

const Star: React.FC<StarProps> = ({ rating, onSelect, highlight, onMouseOver, onMouseLeave }) => {
  const onSelectCallback = useCallback(() => onSelect(rating), [onSelect, rating]);
  const onHoverCallback = useCallback(() => onMouseOver(rating), [onMouseOver, rating]);
  const onMouseLeaveCallback = useCallback(() => onMouseLeave(rating), [onMouseLeave, rating]);
  return (
    <Styled.Star
      highlight={highlight}
      onClick={onSelectCallback}
      onMouseOver={onHoverCallback}
      onMouseLeave={onMouseLeaveCallback}
    >
      <Icons.RatingStar/>
    </Styled.Star>
  );
};


interface Props {
  onSelectRating: (rating: number) => void;
}

const RatingStarsComponent: React.FC<Props> = ({ onSelectRating }) => {

  const stars = new Array(5).fill(0);

  const [highlightedRating, setHighlightedRating] = React.useState<number | null>(null);

  const onHover = useCallback((rating: number) => {
    setHighlightedRating(rating);
  }, []);

  const onMouseLeave = useCallback((leftRating: number) => {
    setHighlightedRating(rating => rating === leftRating ? null : rating);
  }, []);

  return (
    <Styled.Container>
      {
        stars.map((_, index) => {
          const rating = index + 1;
          return (
            <Star
              key={index}
              rating={rating}
              highlight={highlightedRating ? rating <= highlightedRating : false}
              onSelect={onSelectRating}
              onMouseOver={onHover}
              onMouseLeave={onMouseLeave}
            />
          );
        })
      }
    </Styled.Container>
  );
};


export const RatingStars = React.memo(RatingStarsComponent);
