import React, { useCallback } from 'react';

import { Mood } from 'common/enums/mood';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { ConfirmationDialog } from '../../../../../components/dialog/confirmation-dialog';
import { Styled } from './styled';

export const EXTENDED_TRIAL_DIALOG = 'EXTENDED_TRIAL_DIALOG';

interface Props {
  onExtendTrialClick: () => void;
}


export const ExtendedTrialDialog: React.FC<Props> = ({ onExtendTrialClick }) => {
  const { sendEvent } = useAnalytics();
  const onConfirm = useCallback(() => {
    onExtendTrialClick();
    sendEvent(MetricNames.trial.extendedTrial);
  }, [onExtendTrialClick, sendEvent]);

  return (
    <ConfirmationDialog
      name={EXTENDED_TRIAL_DIALOG}
      title={'Your trial has been extended for 7 days!'}
      text={'Should you encounter any issues, please don\'t hesitate to contact support'}
      confirmButtonText={'Ok'}
      onConfirm={onConfirm}
      submitButtonMood={Mood.Secondary}
      ExtraStyled={Styled.Container}
      className={'extended-trial-dialog'}
    />
  );
};
