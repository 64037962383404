import autobind from 'autobind-decorator';
import * as React from 'react';

import './index.scss';

import { MaterialInput } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';

interface Props {
  onSearch: (value: string) => void;
  value: string;
}

export class SearchInput extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className='search-input'>
        <MaterialInput
          displayedType={MaterialComponentType.Native}
          showClearButton={true}
          searchType={true}
          type='text'
          value={this.props.value}
          onChange={this.onChange}
          placeholder='Search'
        />
      </div>
    );
  }

  @autobind
  private onChange(event: React.ChangeEvent, value: string): void {
    event.preventDefault();
    this.props.onSearch(value);
  }
}
