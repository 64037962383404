import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';

import { SvgSpinner } from 'common/components/svg-spinner';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoScrollbars } from 'common/UIKit';
import { DatabaseResourceActions } from '../../actions/creators/database-resource';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { ModifyDatabaseEntityMode, ResourceType } from '../../enums';
import { MaterialModel } from '../../interfaces/resources-data';
import { MaterialForm } from '../../interfaces/resources-rest-data';
import { ResourcesRestFormMapper } from '../../utils/resources-rest-form-mapper';
import { DatabaseEntityModifyWrap } from '../database-entity-modify-wrap';
import { DatabaseEntityModifyWrapProps } from '../database-entity-modify-wrap/database-entity-modify-wrap';
import { DatabaseResourceMaterial } from './database-resource-material';

interface ReduxProps {
  resourceId: number;
  resource: MaterialModel;
  databaseId: number;
}

interface ReduxActions {
  setEditResourceModel: (variant: MaterialModel) => void;
  updateDatabaseResource: (
    databaseId: number, resourceId: number, type: ResourceType, model: MaterialForm, formId: string,
  ) => void;
  loadDatabaseResource: (databaseId: number, resourceId: number, type: ResourceType) => void;
}

interface Props extends ReduxProps, ReduxActions, DatabaseEntityModifyWrapProps {
}

interface State {
  initialValues: MaterialForm;
}


class EditDatabaseResourceMaterialComponent extends React.Component<Props, State> {
  private readonly formId: string = 'edit-material';

  constructor(props: Props) {
    super(props);
    this.state = { initialValues: null };
  }

  public componentDidMount(): void {
    this.props.loadDatabaseResource(this.props.databaseId, this.props.resourceId, ResourceType.Material);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.resource === null && this.props.resource !== null) {
      this.setState({ initialValues: ResourcesRestFormMapper.GetMaterialForm(this.props.resource) });
    }
  }

  public render(): React.ReactNode {
    return (
      <DatabaseEntityModifyWrap
        entityName='Material'
        isApplyButtonDisabled={this.isSaveButtonDisabled()}
        mode={ModifyDatabaseEntityMode.Edit}
        onSubmit={this.save}
        form={this.formId}
        readonly={this.props.readonly}
        initialValues={this.state.initialValues}
      >
      {
        this.props.resource ? (
          <KreoScrollbars>
            <DatabaseResourceMaterial
              resource={this.props.resource}
              onChange={this.props.setEditResourceModel}
              readonly={this.props.readonly}
            />
          </KreoScrollbars>
        ) : <SvgSpinner size='middle'/>
      }
      </DatabaseEntityModifyWrap>
    );
  }


  @autobind
  private save(values: MaterialForm): void {
    const { databaseId, resourceId } = this.props;
    this.props.updateDatabaseResource(databaseId, resourceId, ResourceType.Material, values, this.formId);
  }

  private isSaveButtonDisabled(): boolean {
    const { resource } = this.props;
    return !resource ||
      !resource.name ||
      !resource.name.trim() ||
      !resource.defaultUnitId;
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const editModel = state.database.currentDatabase.editModel;
  return {
    resource: editModel.root,
    resourceId: editModel.rootId,
    databaseId: state.database.currentDatabase.database.id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setEditResourceModel: variant =>
      dispatch(DatabaseResourceListingActions.setEditResourceModel(variant)),
    updateDatabaseResource: (databaseId, resourceId, type, model, formId) =>
      dispatch(DatabaseResourceActions.updateResource(databaseId, resourceId, type, model, formId)),
    loadDatabaseResource: (databaseId, resourceId, type) =>
      dispatch(DatabaseResourceActions.loadResource(databaseId, resourceId, type)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const EditDatabaseResourceMaterial = connector(EditDatabaseResourceMaterialComponent);
