import { ModalWrapper, Text } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { LoadMore } from 'unit-projects/components/load-more';
import { PaymentMethodActions } from '../../../../../../units/subscription/actions/creators';
import { BillingCollection, ItemType } from '../../../../../../units/subscription/interfaces/billing-collection';
import { BillingHistory } from './billing-history';
import { Styled } from './styled';

export const BILLING_HISTORY_DIALOG = 'BILLING_HISTORY_DIALOG';

interface DispatchProps {
  closeDialog: () => void;
  getBillingHistory: (limit: number, offset: string) => void;
  getInvoicesPdf: (invoiceId: string) => void;
  resetRequestStatus: (status: RequestStatus) => void;
}

interface StateProps {
  billingCollection: BillingCollection;
  billingHistoryStatus: RequestStatus;
}

interface Props extends DispatchProps, StateProps {

}

const BillingHistoryDialogComponent: React.FC<Props> = ({
  billingCollection,
  billingHistoryStatus,
  getInvoicesPdf,
  getBillingHistory,
  closeDialog,
  resetRequestStatus,
}) => {
  const [collectionItems, setCollectionItems] = useState<ItemType[]>([]);

  useEffect(() => {
    setCollectionItems(billingCollection.items);
  }, [billingCollection]);

  const fetchNextBilling = useCallback(() => {
    resetRequestStatus(RequestStatus.Loading);
    getBillingHistory(5, billingCollection.nextOffset);
  }, [collectionItems, billingCollection, getBillingHistory]);

  const onClose = useCallback(() => {
    resetRequestStatus(RequestStatus.Loaded);
    closeDialog();
  }, [closeDialog]);

  return (
    <DialogWrapper name={BILLING_HISTORY_DIALOG}>
      <ModalWrapper onExit={onClose}>
        <Styled.Container>
          <Styled.Header>
            <Text color={'gray'}>BILLING HISTORY</Text>
          </Styled.Header>
          <Styled.BillingHistoryWrapper>
            <BillingHistory
              collectionItems={collectionItems}
              getInvoicesPdf={getInvoicesPdf}
            />
          </Styled.BillingHistoryWrapper>
          <Styled.LoadMoreWrapper>
            {!billingCollection?.nextOffset ? null : (
              <LoadMore status={billingHistoryStatus} onLoadMore={fetchNextBilling} />
            )}
          </Styled.LoadMoreWrapper>
        </Styled.Container>
      </ModalWrapper>
    </DialogWrapper>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    billingCollection: state.account.billingCollection,
    billingHistoryStatus: state.account.billingHistoryStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(BILLING_HISTORY_DIALOG)),
    getBillingHistory: (limit, offset) => dispatch(PaymentMethodActions.getBillingHistory({ limit, offset })),
    getInvoicesPdf: (invoiceId) => dispatch(PaymentMethodActions.getInvoicesPdf(invoiceId)),
    resetRequestStatus: (status) => dispatch(PaymentMethodActions.setBillingHistoryStatus(status)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const BillingHistoryDialog = connector(BillingHistoryDialogComponent);
