/* eslint-disable react/jsx-no-bind */
import {
  FormattingMenu,
  Icons,
  Scrollbars,
  Text,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { StyleFields } from '../units/excel-table-cell-formatter';
import { DEFAULT_FONT_SIZE, FONT_SIZES } from './constants';
import { Styled } from './styled';

interface Props {
  value: number;
  disabled: boolean;
}

export const FontSizeComponent = (props: Props): JSX.Element => (
  <Styled.FontSizeContainer disabled={props.disabled}>
    <Text fontSize={14} color={'mainFont'}>{props.value || DEFAULT_FONT_SIZE} pt</Text>
    <Icons.DownSmall width={20} height={10} />
  </Styled.FontSizeContainer>
);

interface MenuProps {
  value: number;
  setValue: (field: StyleFields, size: number) => void;
}

export class FontSizeMenu extends React.Component<MenuProps> {
  public render(): React.ReactNode {
    return (
      <Styled.FontSizeMenuContainer>
        <Scrollbars autoHide={false}>
          <Styled.FontSizeListWrapper>
            <FormattingMenu
              borderRadius={10}
              menuItems={
                FONT_SIZES.map(size => {
                  const onClick = (): void => this.setFontSize(size);
                  const isSelected = size === (this.props.value || DEFAULT_FONT_SIZE);
                  return {
                    name: size,
                    component: () => (
                      <Styled.FontSizeContainer isSelected={isSelected} onClick={onClick} highlightOnHover={true}>
                        <Text fontSize={14}>{size}</Text>
                        {isSelected && <Icons.Apply width={20} height={20} />}
                      </Styled.FontSizeContainer>
                    ),
                  };
                })
              }
            />
          </Styled.FontSizeListWrapper>
        </Scrollbars>
      </Styled.FontSizeMenuContainer>
    );
  }

  @autobind
  private setFontSize(size: number): void {
    if (size !== this.props.value) {
      this.props.setValue(StyleFields.FontSize, size);
    }
  }
}
