import { useContext } from 'react';
import { AbilityContext } from './ability-context';
import { Operation } from './operation';
import { Subject } from './subject';


export function useAbility(operation: Operation, subject: Subject): boolean {
  const context = useContext(AbilityContext);
  return context.ability.can(operation, subject);
}
