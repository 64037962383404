import * as React from 'react';

export const KreoIconUniclass: React.FC = (_props) => {
  return (
    <svg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='UI-KIT' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Database_Activities_UI-Elements' transform='translate(-618.000000, -1726.000000)'>
                <g id='ActivityEditor' transform='translate(560.000000, 1281.000000)'>
                    <g id='ResType-1-Copy-2' transform='translate(40.000000, 364.000000)'>
                        <g id='Uniclass' transform='translate(0.000000, 60.000000)'>
                            <g id='DB/icon-nrm1' transform='translate(18.000000, 21.000000)'>
                                <rect id='Background' x='0' y='0' width='18' height='18'/>
                                <g id='icon_'>
                                    <circle id='Oval' fill='#5E82BB' cx='9' cy='9' r='9'/>
                                    <path
                                      d='M11,5 L13,5 L13,10 C13,12.209139 11.209139,14 9,14 C6.790861,14
                                      5,12.209139 5,10 L5,5 L7,5 L7,10 C7,11.1045695 7.8954305,12 9,12
                                      C10.1045695,12 11,11.1045695 11,10 L11,5 Z'
                                      id='Rectangle-4'
                                      fill='#FFFFFF'
                                      fillRule='nonzero'
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
};
