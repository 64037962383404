import React from 'react';

import { Styled } from './styled';

export const IconRenderer = ({ Icon }: { Icon: React.ComponentType }): JSX.Element => {
  return (
    <Styled.Container>
      <Icon/>
    </Styled.Container>
  );
};
