import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { WrappedFieldArrayProps } from 'redux-form';

import { KreoDialogActions } from 'common/UIKit/dialogs';
import { ActivityResourceVariant } from '../../interfaces/data';
import { LaborVariantModel } from '../../interfaces/resources-data';
import { LaborForm } from '../../interfaces/rest-data';
import { DatabaseEntityNamedHeader } from '../database-entity-named-header';
import { ResourceFieldHelper } from '../resource-selector/resource-field-helper';
import { LaborItem } from './database-activity-labour-item';
import { LaborSelector, RESOURCE_SELECTOR_DIALOG_LABOUR } from './database-activity-labour-selector';

export interface LaborsProps {
  readonly: boolean;
  labors: Array<ActivityResourceVariant<LaborVariantModel>>;
  onChange: (labors: Array<ActivityResourceVariant<LaborVariantModel>>) => void;
}
interface DispatchProps {
  close: (name: string) => void;
  open: (name: string) => void;
}

interface Props extends DispatchProps, LaborsProps {}

class LaborsComponent extends React.Component<Props & WrappedFieldArrayProps<LaborForm>> {

  public render(): React.ReactNode {
    return (
      <div className='database-activity-labours'>
        <DatabaseEntityNamedHeader
          name='Labour'
          onAddButtonClick={this.props.readonly ? null : this.openLaborSelector}
        />
        <div className='database-activity-labours__content'>
        {
          this.props.fields.map((fieldKey, index) => {
            return this.props.labors[index] ? (
              <LaborItem
                fieldKey={fieldKey}
                labor={this.props.labors[index]}
                key={fieldKey}
                index={index}
                onQuantityChange={this.onQuantityChange}
                onDelete={this.onDelete}
                readonly={this.props.readonly}
              />
            ) : null;
          })
        }
        </div>
        {
          !this.props.readonly ? (
            <LaborSelector
              selectedLabors={this.props.labors}
              onCancel={this.closeLaborSelector}
              onSave={this.onChange}
            />
          ) : null
        }
      </div>
    );
  }

  @autobind
  private onChange(labors: Array<ActivityResourceVariant<LaborVariantModel>>): void {
    ResourceFieldHelper.changeResourcesField(
      this.props.labors,
      labors,
      this.props.fields,
      this.props.onChange,
    );
  }

  @autobind
  private openLaborSelector(): void {
    this.props.open(RESOURCE_SELECTOR_DIALOG_LABOUR);
  }

  @autobind
  private closeLaborSelector(): void {
    this.props.close(RESOURCE_SELECTOR_DIALOG_LABOUR);
  }

  @autobind
  private onQuantityChange(index: number, amount: number): void {
    const result = this.props.labors.slice();
    result[index] = { ...result[index], amount };
    this.props.onChange(result);
  }

  @autobind
  private onDelete(index: number): void {
    const labors = this.props.labors.slice();
    labors.splice(index, 1);
    this.props.fields.remove(index);
    this.props.onChange(labors);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    close: (name: string) => dispatch(KreoDialogActions.closeDialog(name)),
    open: (name: string) => dispatch(KreoDialogActions.openDialog(name)),
  };
};

export const Labors =
  connect(null, mapDispatchToProps)(LaborsComponent);
