import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors, DrawingsCanvasConstants } from '../../constants';
import { DrawingContextObserverWithPrev } from '../../drawings-contexts';
import { DrawingsRenderParams } from '../../interfaces';
import { PdfTextRectangle, PdfTextRects } from '../../interfaces/pdf-text-rectangle';
import { DestroyableObject, EngineObjectConfig } from '../common';
import { DrawingsCursorTypeHelper } from '../drawings-helpers';
import { TextRect } from './text-rect';

interface TextSearchRectsConfig extends EngineObjectConfig {
  paramsObserver: DrawingContextObserverWithPrev<DrawingsRenderParams>;
  layer: paper.Layer | paper.Group;
  rects: PdfTextRects;
  cursorHelper: DrawingsCursorTypeHelper;
  onSelectTextForSearch: (text: string) => void;
}

const TEXT_RECTS_DISTANCE = 5;

export class TextSearchRects extends DestroyableObject<TextSearchRectsConfig> {
  private _layer: paper.Group;
  private _rectsByText: Record<string, TextRect[]> = { };

  constructor(config: TextSearchRectsConfig) {
    super(config);
    this._layer = new paper.Group();
    this._layer.addTo(this._config.layer);
    this._config.paramsObserver.subscribe(this.updateZoom);
  }

  public destroy(): void {
    this._layer.remove();
    this._config.paramsObserver.unsubscribe(this.updateZoom);
  }

  public setRects(rects: PdfTextRects): void {
    this._layer.removeChildren();
    this._config.rects = rects;
  }

  public onMouseMove(e: PaperMouseEvent): void {
    const newRectsByText: Record<string, TextRect[]> = {};
    const saveRect = (text: string, rect: PdfTextRectangle): void => {
      if (!newRectsByText[text]) {
        newRectsByText[text] = [];
      }
      const textRect = new TextRect({
        layer: this._layer,
        renderParamsContextObserver: this._config.paramsObserver,
        cursorHelper: this._config.cursorHelper,
        text,
        onClick: this._config.onSelectTextForSearch,
        geometry: rect.rect,
        strokeColor: DrawingsCanvasColors.secondaryFigure,
        fillColor: DrawingsCanvasColors.secondaryFigureTransparent,
      });
      newRectsByText[text].push(textRect);
    };

    const distance = TEXT_RECTS_DISTANCE / this._config.paramsObserver.getContext().zoom;

    for (const textRect of this._config.rects.rects) {
      if (
        textRect.rect[0][0] - distance <= e.point.x
        && textRect.rect[1][0] + distance >= e.point.x
        && textRect.rect[0][1] - distance <= e.point.y
        && textRect.rect[1][1] + distance >= e.point.y
      ) {
        if (!newRectsByText[textRect.text]) {
          newRectsByText[textRect.text] = [];
        }
        if (this._rectsByText[textRect.text]) {
          const rectangle = this._rectsByText[textRect.text].find((rect) => rect.geometry === textRect.rect);
          if (rectangle) {
            newRectsByText[textRect.text].push(rectangle);
          } else {
            saveRect(textRect.text, textRect);
          }
        } else {
          saveRect(textRect.text, textRect);
        }
      }
    }

    for (const [text, rects] of Object.entries(this._rectsByText)) {
      if (newRectsByText[text]) {
        for (const rect of rects) {
          if (!newRectsByText[text].includes(rect)) {
            rect.destroy();
          }
        }
      } else {
        rects.forEach((rect) => rect.destroy());
      }
    }

    this._rectsByText = newRectsByText;
  }

  @autobind
  private updateZoom(params: DrawingsRenderParams): void {
    for (const rects of Object.values(this._rectsByText)) {
      rects.forEach((rect) => rect.strokeWith = DrawingsCanvasConstants.infoLinesStroke / params.zoom);
    }
  }
}
