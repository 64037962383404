import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { DemoProject } from 'unit-projects/interfaces/demo-project';
import { MetricNames, useAnalytics } from 'utils/posthog';

export const useClickDemoProjectCallback = (demoProjects: DemoProject[]): (id: number) => void  => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  return useCallback((id) => {
    dispatch(ProjectsActions.createProjectFromDemoProject(id));
    const demo = demoProjects.find(d => d.id === id);
    analytics.sendEvent(MetricNames.projects.applyDemoProject, { name: demo.name });
  }, [demoProjects]);
};
