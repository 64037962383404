import * as React from 'react';

export const KreoIconIsometry = (): JSX.Element => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <g
      id='Icons/24x24/Izometry_Normal'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M5,9.5 L19,9.5' className='icon__color-light' stroke='currentColor' fillRule='nonzero' />
      <path d='M5,14.5 L19,14.5' className='icon__color-light' stroke='currentColor' fillRule='nonzero' />
      <path
        d='M7.5,12 L21.5,12'
        className='icon__color-light'
        stroke='currentColor'
        fillRule='nonzero'
        transform='translate(14.500000, 12.000000) rotate(-270.000000) translate(-14.500000, -12.000000) '
      />
      <path
        d='M2.5,12 L16.5,12'
        className='icon__color-light'
        stroke='currentColor'
        fillRule='nonzero'
        transform='translate(9.500000, 12.000000) rotate(-270.000000) translate(-9.500000, -12.000000) '
      />
      <path
        // eslint-disable-next-line max-len
        d='M6.5,4.5 L17.5,4.5 C18.6045695,4.5 19.5,5.3954305 19.5,6.5 L19.5,17.5 C19.5,18.6045695 18.6045695,19.5 17.5,19.5 L6.5,19.5 C5.3954305,19.5 4.5,18.6045695 4.5,17.5 L4.5,6.5 C4.5,5.3954305 5.3954305,4.5 6.5,4.5 Z'
        stroke='currentColor'
      />
    </g>
  </svg>
);
