import { IconButton, Icons, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { SettingParamStyled } from '../../../../components/setting-parameter';
import { Styled } from './styled';

interface Props {
  rotation: number;
  changeRotation: (value: number) => void;
}


export class RotationSettings extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <SettingParamStyled.Container>
        <Text fontSize={14}>Rotate</Text>
        <Styled.Buttons>
          <IconButton
            Icon={Icons.RotateSmallRight}
            onClick={this.rotateClockwise}
            height={20}
            width={20}
            iconHeight={20}
          />
          <IconButton
            Icon={Icons.RotateSmallLeft}
            height={20}
            width={20}
            iconHeight={20}
            onClick={this.rotateBackward}
          />
        </Styled.Buttons>
      </SettingParamStyled.Container>
    );
  }

  @autobind
  private rotateClockwise(): void {
    this.changeRotation(90);
  }

  @autobind
  private rotateBackward(): void {
    this.changeRotation(270);
  }

  @autobind
  private changeRotation(rotation: number): void {
    this.props.changeRotation((this.props.rotation + rotation) % 360);
  }
}
