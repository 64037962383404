import { ElementTooltip } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { ConstantFunctions } from 'common/constants/functions';
import { FormulaInputAutocompleteItemType } from '../../../enums';
import { FormulaInputAutocompleteItemInfo } from '../interfaces';
import { Styled } from './styled';


interface Props{
  searchText: string;
  selected: boolean;
  item: FormulaInputAutocompleteItemInfo;
  onClick: (item: FormulaInputAutocompleteItemInfo) => void;
}


const TYPES: Record<FormulaInputAutocompleteItemType, string> = {
  [FormulaInputAutocompleteItemType.Property]: 'Property',
  [FormulaInputAutocompleteItemType.Function]: 'Function',
  [FormulaInputAutocompleteItemType.MeasuredFunction]: 'Measures',
  [FormulaInputAutocompleteItemType.Operator]: 'Operator',
};


export class FormulaInputAutoCompleteItem extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    const { item: { type, text }, selected } = this.props;
    return (
      <ElementTooltip
        text={text}
        position='bottom'
      >
        <Styled.Container
          selected={selected}
          onClick={this.onClick}
          onMouseDown={ConstantFunctions.preventDefault}
        >
          <Styled.Title>
            {text}
          </Styled.Title>
          <Styled.Type type={type}>
            {TYPES[type]}
          </Styled.Type>
        </Styled.Container>
      </ElementTooltip>
    );
  }

  @autobind
  private onClick(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onClick(this.props.item);
  }
}
