import * as React from 'react';

export const KreoIconAdmin: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='icon_admin'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path
          d='M6.05263158,2.03603944 L6.05263158,1.94736842 C6.05263158,1.42415129
          6.47678287,1 7,1 C7.52321713,1 7.94736842,1.42415129 7.94736842,1.94736842
          L7.94736842,2.03603944 C8.6570228,2.17065608 9.31414246,2.45368365
          9.88522369,2.85161841 L10.1578947,2.57894737 C10.5067062,2.23013595
          11.0722412,2.23013595 11.4210526,2.57894737 C11.7698641,2.92775879 11.7698641,3.49329384
          11.4210526,3.84210526 L11.1483816,4.11477631 C11.5463164,4.68585754 11.8293439,5.3429772
          11.9639606,6.05263158 L12.0526316,6.05263158 C12.5758487,6.05263158 13,6.47678287 13,7
          C13,7.52321713 12.5758487,7.94736842 12.0526316,7.94736842 L11.9639606,7.94736842
          C11.8293439,8.6570228 11.5463164,9.31414246 11.1483816,9.88522369 L11.4210526,10.1578947
          C11.7698641,10.5067062 11.7698641,11.0722412 11.4210526,11.4210526 C11.0722412,11.7698641
          10.5067062,11.7698641 10.1578947,11.4210526 L9.88522369,11.1483816 C9.31414246,11.5463164
          8.6570228,11.8293439 7.94736842,11.9639606 L7.94736842,12.0526316 C7.94736842,12.5758487
          7.52321713,13 7,13 C6.47678287,13 6.05263158,12.5758487 6.05263158,12.0526316
          L6.05263158,11.9639606 C5.3429772,11.8293439 4.68585754,11.5463164 4.11477631,11.1483816
          L3.84210526,11.4210526 C3.49329384,11.7698641 2.92775879,11.7698641 2.57894737,11.4210526
          C2.23013595,11.0722412 2.23013595,10.5067062 2.57894737,10.1578947 L2.85161841,9.88522369
          C2.45368365,9.31414246 2.17065608,8.6570228 2.03603944,7.94736842 L1.94736842,7.94736842
          C1.42415129,7.94736842 1,7.52321713 1,7 C1,6.47678287 1.42415129,6.05263158
          1.94736842,6.05263158 L2.03603944,6.05263158 C2.17065608,5.3429772 2.45368365,4.68585754
          2.85161841,4.11477631 L2.57894737,3.84210526 C2.23013595,3.49329384 2.23013595,2.92775879
          2.57894737,2.57894737 C2.92775879,2.23013595 3.49329384,2.23013595 3.84210526,2.57894737
          L4.11477631,2.85161841 C4.68585754,2.45368365 5.3429772,2.17065608 6.05263158,2.03603944
          Z M7,8.89473684 C8.04643426,8.89473684 8.89473684,8.04643426 8.89473684,7
          C8.89473684,5.95356574 8.04643426,5.10526316 7,5.10526316 C5.95356574,5.10526316
          5.10526316,5.95356574 5.10526316,7 C5.10526316,8.04643426 5.95356574,8.89473684 7,8.89473684 Z'
          id='Combined-Shape'
          stroke='currentColor'
        />
      </g>
    </svg>
  );
};
