import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useDrawingsLayoutApi } from 'common/components/drawings/drawings-layout-api-context';
import { RenderIf } from 'common/components/render-if';
import { Text } from 'common/components/text';
import { State } from 'common/interfaces/state';
import { TextSearchQuery } from './text-search-query';
import { TextSearchResults } from './text-search-results/text-search-results';
import { useLoadingText } from './use-loading-text';
import { useUpdateActiveStatus } from './use-update-active-status';

const TextSearchComponent: React.FC = () => {
  const drawingApi = useDrawingsLayoutApi();
  const isLoading = useSelector<State, boolean>((s) => s.drawings.textSearch.isLoading);
  const isTextLoading = useSelector<State, boolean>((s) => s.drawings.textSearch.isTextLoading);
  const isError = useSelector<State, boolean>((s) => s.drawings.textSearch.isError);

  const onFocus = useCallback(
    (id: number, drawingId: string) => {
      drawingApi.focusTextSearchResult(id, drawingId);
    },
    [drawingApi],
  );

  useLoadingText();
  useUpdateActiveStatus();

  return (
    <>
      <TextSearchQuery />
      <RenderIf condition={isTextLoading}>
        <Text>Extacting text...</Text>
      </RenderIf>
      <TextSearchResults
        isError={isError}
        isLoading={isLoading}
        onFocus={onFocus}
      />
    </>
  );
};


export const TextSearch = React.memo(TextSearchComponent);
