
import * as React from 'react';


export const Shield: React.FC = () => {
  return (
    <svg
      width='30px'
      height='40px'
      viewBox='0 0 30 40'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d={`M22.8012216,25.4000344 C25.7008151,22.5025405 28,19.2525779 28,15
            C28,7.82029825 22.1797017,2 15,2 C7.82029825,2 2,7.82029825 2,15 C2,19.2531637
            4.30018486,22.5026746 7.20008309,25.4010145 C8.93333333,27.1333333 14,33 14,35.2
            C14,35.9666667 14,36.7333333 14,37.5 L14,37.5 C14,37.7761424 14.2238576,38 14.5,38
            L15.5,38 C15.7761424,38 16,37.7761424 16,37.5 C16,36.7333333 16,35.9666667 16,35.2
            C16,33 21.0666667,27.1333333 22.8012216,25.4000344 Z`}
          id='path-3'
        />
      </defs>
      <g id='Comment-type-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <mask id='mask-2' fill='white'>
          <use xlinkHref='#path-3' />
        </mask>
        <use id='Combined-Shape' fill='#5C8AE6' xlinkHref='#path-3' />
      </g>
    </svg>
  );
};

