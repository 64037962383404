import * as React from 'react';

import {
  ResourceDescription,
} from '../../../utils/gantt-chart';
import { ItemType, SelectItem } from './select-item';


interface Props {
  resources: ResourceDescription[];
  selectedResourceId: number;
  onSelectAllLabours: (select: boolean) => void;
  onSelectItem: (id: number, name: string) => void;
}


class ResourceSelectorContentLabours extends React.Component<Props> {
  private selectResourceMethods: Map<number, () => void> = new Map<number, () => void>();

  public render(): React.ReactNode {
    return (
      <div className='gantt-resources-chart-container-resource-selector__body__content'>
        <SelectItem
          selected={this.props.selectedResourceId === -7}
          level={0}
          value={0}
          type={ItemType.Radio}
          onCheck={this.props.onSelectAllLabours}
          title='All Labours'
        />
        {
          this.props.resources && this.props.resources.map((resourceDescription) => {
            return (
              <SelectItem
                value={resourceDescription.id}
                title={resourceDescription.displayName}
                type={ItemType.Radio}
                key={resourceDescription.id}
                onCheck={this.getSelectMethod(resourceDescription.id, resourceDescription.displayName)}
                selected={resourceDescription.id === this.props.selectedResourceId}
                level={1}
              />);
          })
        }
      </div>
    );
  }


  private getSelectMethod(id: number, name: string): () => void {
    if (!this.selectResourceMethods.has(id)) {
      this.selectResourceMethods.set(id, () => {
        this.props.onSelectItem(id, name);
      });
    }
    return this.selectResourceMethods.get(id);
  }
}


export { ResourceSelectorContentLabours };
