import { DrawingsContextMenuItem } from '../drawings-context-menu-config';

export function shouldShowOption(
  item: DrawingsContextMenuItem,
  filtersCache: Record<string, boolean>,
): boolean {
  if (!item.filters || !item.filters.length) {
    return true;
  }

  return item.filters.every(filter => {
    if (!(filter in filtersCache)) {
      console.warn('Please add value for filter');
    }
    return filtersCache[filter];
  });
}
