import React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { MeasureValue as MeasureValueType } from '../../../drawings-annotation-legend-item';
import { MeasureValue } from './measures-value';
import { Styled } from './styled';

interface StateProps{
  isImperial: boolean;
}

interface OwnProps {
  measureValues: MeasureValueType[];
}

interface Props extends StateProps, OwnProps {
}

const MeasuresPopupComponent: React.FC<Props> = ({
  measureValues,
  isImperial,
}) => {
  return (
    <Styled.MultiValuesPopupContainer>
      {
        measureValues?.map((measureValue, index) => {
          return (
            <MeasureValue
              key={index}
              measureValue={measureValue}
              isImperial={isImperial}
            />
          );
        })
      }
    </Styled.MultiValuesPopupContainer>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    isImperial: state.account.settings.isImperial,
  };
}

export const MeasuresPopup = connect(mapStateToProps)(MeasuresPopupComponent);
