import autobind from 'autobind-decorator';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch  } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import './database-entity-modify-wrap.scss';

import { IconButton, KreoButton, KreoIconCancel } from 'common/UIKit';
import Form from '../../../../components/form';
import { DatabaseActivityListingActions } from '../../actions/creators/database-activity-listing';
import { ModifyDatabaseEntityMode } from '../../enums';

export interface DatabaseEntityModifyWrapProps {
  readonly: boolean;
}

interface ReduxActions {
  closeModifyPanel: () => void;
}

interface Props extends DatabaseEntityModifyWrapProps {
  onClose?: () => void;
  onApplyChanges?: () => void;
  isApplyButtonDisabled: boolean;
  mode: ModifyDatabaseEntityMode;
  entityName: string;
  className?: string;
}

interface FormProps extends  Props, ReduxActions, InjectedFormProps<{}, Props> {}


class DatabaseEntityModifyWrapComponent extends React.Component<FormProps> {
  public render(): React.ReactNode {
    const applyButtonCaption = this.props.mode === ModifyDatabaseEntityMode.Create
      ? 'Create'
      : 'Save';

    return (
      <div
        className={classNames('database-entity-modify-wrap', this.props.className, {
          'database-entity-modify-wrap--read-only': this.props.readonly,
        })}
      >
        <Form handleSubmit={this.props.handleSubmit}>
          <div className='database-entity-modify-wrap__header'>
            <div className='database-entity-modify-wrap__header-title'>{this.props.mode} {this.props.entityName}</div>
            <IconButton size='medium' onClick={this.close}>
              <KreoIconCancel />
            </IconButton>
          </div>
          <div className='database-entity-modify-wrap__content'>
          {this.props.children}
          </div>
          <div className='database-entity-modify-wrap__footer'>
            <KreoButton onClick={this.close} caption='Cancel' size='large' mode='cancel'/>
            {
              !this.props.readonly ? (
                <KreoButton
                  disabled={this.props.isApplyButtonDisabled}
                  caption={applyButtonCaption}
                  size='large'
                  mode='submit'
                  htmlButtonType='submit'
                />
              ) : null
            }
          </div>
        </Form>
      </div>
    );
  }

  @autobind
  private close(e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>): void {
    e.preventDefault();
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.props.closeModifyPanel();
  }
}


const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    closeModifyPanel: () => dispatch(DatabaseActivityListingActions.closeEditPanel()),
  };
};

const connector = connect(null, mapDispatchToProps);
const component = connector(DatabaseEntityModifyWrapComponent);
export const DatabaseEntityModifyWrap = reduxForm<{}, Props>({
  destroyOnUnmount: true,
})(component);
