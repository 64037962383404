import { ConfirmResponseCode } from 'common/enums/confirm-response-code';
import { StringDictionary } from 'common/interfaces/dictionary';
import { UserInfo } from 'common/interfaces/people';
import { objectUtils } from 'common/utils/object-utils';
import { CommonApi } from '../../api/server';
import { CreateOrganizationFormData } from '../empty-organizations/components/create-organization-dialog';
import { SubscriptionPlan, SubscriptionPlansC } from '../subscription/interfaces/subscription-plan';
import { CompaniesC, Company, UpdateCompany } from './interfaces/company';
import { MotivationPopupSettings, MotivationPopupSettingsC } from './interfaces/motivation-popup-settings';
import { SignInFormData } from './interfaces/sign-in';
import { ConfirmByEmailParam, ConfirmParam, SignUpData } from './interfaces/sign-up';
import { UserInfoResponse } from './interfaces/user-info-response';
import { UserSettings } from './interfaces/user-settings';

function signUp(user: SignUpData): Promise<void> {
  return CommonApi.post('/user/signup', user, null);
}

function signIn(signInData: SignInFormData): Promise<void> {
  return CommonApi.post('/auth/login', signInData, null);
}

function getUserInfo(): Promise<UserInfoResponse> {
  return CommonApi.get('/user/info');
}

function setUserInfo(data: any): Promise<any> {
  return CommonApi.put('/user/info', data);
}

function setQuestionnaireCompleted(data: boolean): Promise<void> {
  return CommonApi.put('/user/questionnaire', { isComplete: data });
}

function resetPassword(data: any): Promise<void> {
  return CommonApi.post('/user/resetpassword/send', data, 'User not exist');
}

function newPassword(token: string, password: string): Promise<void> {
  return CommonApi.post(
    `/user/resetpassword`,
    { token, password },
    null);
}

function getUsersInfo(usersIds: string[]): Promise<StringDictionary<UserInfo>> {
  if (!usersIds.length) return Promise.resolve({});

  const query = objectUtils.toQueryString({ userId: usersIds });
  return CommonApi.get(`/user/infos?${query}`);
}

function fetchCompanies(): Promise<Company[]> {
  return CommonApi.getV('/companies', CompaniesC);
}

function updateCompany(companyId: number, data: UpdateCompany): Promise<Company[]> {
  return CommonApi.putV(`/companies/${companyId}`, data, CompaniesC);
}

function confirm(confirmProps: ConfirmParam): Promise<ConfirmResponseCode> {
  return CommonApi.post(`/user/confirm`, confirmProps, 'Something wrong while confirmation.');
}

function confirmAgain(confirmProps: ConfirmParam): Promise<ConfirmResponseCode> {
  return CommonApi.post(`/user/confirm-again`, confirmProps, 'Something wrong while send confirm mail again.');
}

function confirmAgainByEmail(confirmProps: ConfirmByEmailParam): Promise<ConfirmResponseCode> {
  return CommonApi.post(
    `/user/confirm-again-by-email`,
    confirmProps,
    'Something wrong while send confirm mail again.',
  );
}

function createCompany(data: CreateOrganizationFormData): Promise<Company[]> {
  return CommonApi.postV(
    '/companies',
    data,
    CompaniesC,
    'Something wrong while creating a company.');
}


const ablyGetTokenUrl = '/user/ably-token';

function getAblyToken(): Promise<string> {
  return CommonApi.get(ablyGetTokenUrl);
}

function buildLogoPostFix(companyId: number): string {
  return `/companies/${companyId}/logo`;
}

function getCompanyLogoLink(company: Company): string {
  return company.hasLogo ? `/api${buildLogoPostFix(company.id)}` : null;
}

function buildAvatarLinkPostFix(userId: string): string {
  return `/user/${userId}/avatar`;
}

function buildAvatarLink(userId: string): string {
  return `/api${buildAvatarLinkPostFix(userId)}`;
}

function getAvatarLink(user: { id?: string, hasAvatar?: boolean }): string {
  return user.hasAvatar && user.id ? `/api${buildAvatarLinkPostFix(user.id)}` : null;
}

function getSubscriptionPlans(): Promise<SubscriptionPlan[]> {
  return CommonApi.getV('/billing/plans', SubscriptionPlansC, 'Error while fetching subscription plans from backend');
}

function getCheckoutNewSubscriptionHostedPage(planId: string): Promise<ChargebeeHostedPage> {
  return CommonApi.get(
    `/billing/plans/${planId}/checkout-new-subscription-page`,
    'Error while fetching checkout-new-subscription hosted page object');
}

function getPortalSession(): Promise<ChargebeePortalSession> {
  return CommonApi.get('/billing/portal-session', 'Error while fetching portal session object');
}

function acknowledgeSubscriptionChanges(): Promise<void> {
  return CommonApi.post('/billing/acknowledge-changes', null, 'Error while acknowledging subscription changes');
}

function getMotivationPopupSettings(): Promise<MotivationPopupSettings> {
  return CommonApi.getV(
    `/common/subscription-popup-config`,
    MotivationPopupSettingsC,
    'error in load motivation popup settings',
  );
}

function setMotivationPopupSettings(settings: MotivationPopupSettings): Promise<void> {
  return CommonApi.post(
    `/admin/subscription-popup-config`,
    settings,
    'error set motivation popup settings',
  );
}

function deleteMotivationPopupSettings(): Promise<void> {
  return CommonApi.delete(
    `/admin/subscription-popup-config`,
    null,
    'error delete motivation popup settings',
  );
}

function changeSettings(settings: UserSettings): Promise<void> {
  return CommonApi.put('/user/settings', settings);
}

function setAvatar(key: string): Promise<void> {
  return CommonApi.put('/user/avatar', { temporaryBlobKey: key });
}

function removeAvatar(): Promise<void> {
  return CommonApi.delete('/user/avatar');
}

export const AccountApi = {
  createCompany,
  confirmAgainByEmail,
  confirmAgain,
  confirm,
  fetchCompanies,
  updateCompany,
  getUserInfo,
  getUsersInfo,
  newPassword,
  resetPassword,
  setUserInfo,
  setQuestionnaireCompleted,
  signIn,
  signUp,
  getAblyToken,
  ablyGetTokenUrl,
  buildLogoPostFix,
  getCompanyLogoLink,
  buildAvatarLink,
  buildAvatarLinkPostFix,
  getAvatarLink,
  getSubscriptionPlans,
  getCheckoutNewSubscriptionHostedPage,
  getPortalSession,
  acknowledgeSubscriptionChanges,
  getMotivationPopupSettings,
  setMotivationPopupSettings,
  deleteMotivationPopupSettings,
  changeSettings,
  setAvatar,
  removeAvatar,
};
