import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ConfirmationDialog } from '../../../../../components/dialog/confirmation-dialog';

export interface DrawingsDeletePage {
  name: string;
  pageId: string;
  pdfId: string;
}
export const DRAWING_DELETE_PAGE_CONFIRMATION_DIALOG = 'DRAWING_DELETE_PAGE_CONFIRMATION_DIALOG';

interface StateProps {
  data: DrawingsDeletePage;
}

interface OwnProps {
  onSubmit: (data: DrawingsDeletePage) => void;
}

type Props = OwnProps & StateProps;


class DrawingsDeletePageConfirmationDialogComponent extends React.Component<Props> {

  public render(): React.ReactNode {
    const { onSubmit, data } = this.props;
    const dialogText = `The "${data && data.name}" will be removed immediately. This action can’t be undone.`;

    return (
      <ConfirmationDialog
        name={DRAWING_DELETE_PAGE_CONFIRMATION_DIALOG}
        title='Are you sure you want to delete it?'
        text={dialogText}
        confirmButtonText='Delete'
        onConfirm={onSubmit}
        cancelButtonText='Cancel'
        zIndex={1003}
      />
    );
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    data: state.dialog[DRAWING_DELETE_PAGE_CONFIRMATION_DIALOG],
  };
}

export const DrawingsDeletePageConfirmationDialog =
  connect(mapStateToProps)(DrawingsDeletePageConfirmationDialogComponent);
