import { RequestStatus } from 'common/enums/request-status';
import { Arrangement } from './constants';
import { AddNewSheetStatuses, TwoDReduxState } from './interfaces';

export const TWO_D_INITIAL_STATE: TwoDReduxState = {
  focusedCell: {
    cell: {
      rowId: '1',
      columnId: 'A',
      cellId: 'A1',
    },
    isCanRead: false,
    isCanWrite: false,
  },
  drawingInstanceToCell: {},
  dynamicGroupsToCell: {},
  cellToCell: {},
  cellToUpdate: [],
  reportPages: [],
  selectedSheetId: undefined,
  showSheetIds: [],
  traceLink: '',
  cellWithBorder: '',
  formulaBar: {
    value: undefined,
    isFocused: false,
  },
  drawingInstanceInCellRange: {},
  tableViewType: Arrangement.SingleView,
  editFullCellId: '',
  quickSearchValue: '',
  filteredNodeIds: null,
  addNewSheetStatus: AddNewSheetStatuses.IN_ACTIVE,
  presets: [],
  piaMeasurePanel: {
    selectedAssembliesIds: {},
    selectedItemsIds: {},
    isOpenSelectTable: false,
  },
  assignPia: {},
  fetchAssignPiaStatus: RequestStatus.NotRequested,
  isShowTwoDReport: true,
  viewsConfigs: {},
  calculatedPia: {},
  viewToolPanels: {},
  isAssignPiaReady: true,
  isExportReportReady: '',
  usedViewMeasureId: {},
  selectedMeasureIdView: {},
  reportPageEtagMap: {},
  reportPageSessionIdMap: {},
  isGetPageData: true,
  isSkipCalcPia: false,
  assignPiaBuffer: {},
};
