import styled  from 'styled-components';

const MENU_WIDTH = 240;

const Container = styled.div`
  width: ${MENU_WIDTH}px;
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: ${p => p.theme.shadow.xl};
  &,* {
    box-sizing: border-box;
  }
`;


const ImageContainer = styled.div`
  width: 100%;
  height: 135px;
  position: relative;
  border-radius: 20px 20px 0 0;

  svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 40px;
  }
`;

const InfoGroup = styled.div<{ imageUrl?: string }>`
  width: 100%;
  padding: 20px;
  background: ${p => p.imageUrl && `url(${p.imageUrl}) center/cover`};
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  :not(:last-child) {
    border-bottom: 1px solid ${p => p.theme.color.background};
  }
`;

const InfoGroupHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const InfoGroupHeadBubble = styled.div`
  min-width: 20px;
  height: 20px;
  background-color: ${p => p.color || p.theme.color.red};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0 7px;
`;

export const Styled = {
  Container,
  InfoGroup,
  InfoGroupHead,
  InfoGroupHeadBubble,
  ImageContainer,
  MENU_WIDTH,
};
