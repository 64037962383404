import { InputSelectNewOption } from '@kreo/kreo-ui-components';
import React from 'react';
import { PropertyWithInfo } from '../../../property-with-info';
import { useChange } from './use-change';


interface DropDownListData {
  name: string;
  elements: number[];
  tooltipText: string;
}

interface Props {
  data: DropDownListData;
  value: number;
  onChange: (value: number) => void;
}

const FieldSettingDropDownListComponent: React.FC<Props> = ({
  value,
  data,
  onChange,
}) => {
  const { name, tooltipText, elements } = data;
  const { onClick, onBlur } = useChange(elements, onChange);
  return (
    <PropertyWithInfo name={name} tooltipText={tooltipText}>
      <InputSelectNewOption
        elements={elements}
        activeElementIndex={elements.indexOf(value)}
        closeAfterClick={true}
        maxItemsAmount={5}
        onClick={onClick}
        onBlur={onBlur}
        value={value}
        format='number'
      />
    </PropertyWithInfo>
  );
};

export const FieldSettingDropDownList = React.memo(FieldSettingDropDownListComponent);
