import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import { Avatar } from 'common/UIKit';
import { TAdd, TAdded } from '../../../../icons';

interface Props {
  id: string;
  disabled: boolean;
  firstName: string;
  lastName: string;
  email: string;
  selected: boolean;
  selectedDefault: boolean;
  onToggleChange: (selected: boolean, email: string, defaultValue: boolean) => void;
}

export class PeopleOnProjectSelectItem extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    selectedDefault: false,
  };

  public render(): React.ReactNode {
    const className = classNames('people-on-project-select__item', {
      disabled: this.props.disabled,
      selected: this.props.selected,
    });
    return (
      <div
        className={className}
        onClick={this.toggleSelect}
      >
        <div className='people-on-project-select__item__avatar'>
          <Avatar
            firstName={this.props.firstName}
            lastName={this.props.lastName}
            userId={this.props.id}
          />
        </div>
        <div className='people-on-project-select__item__details'>
          <div className='people-on-project-select__item__details__full-name'>
            {this.props.firstName} {this.props.lastName}
          </div>
          <div className='people-on-project-select__item__details__email'>
            {this.props.email}
          </div>
        </div>
        <div className='people-on-project-select__item__check'>
          <img src={this.props.selected ? TAdded : TAdd} />
        </div>
      </div>
    );
  }

  @autobind
  private toggleSelect(): void {
    if (!this.props.disabled) {
      this.props.onToggleChange(!this.props.selected, this.props.email, this.props.selectedDefault);
    }
  }
}
