import React from 'react';
import { Transaction } from '.';

export const ItemsViewTransactionContext = React.createContext<Transaction>(null);

export interface ItemsViewTransactionProps {
  transaction: Transaction;
}

export const withItemsViewTransactionContext = <P extends ItemsViewTransactionProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof ItemsViewTransactionProps>>> => {
  return class AbilityAwareComponent extends React.Component<
    Pick<P, Exclude<keyof P, keyof ItemsViewTransactionProps>>
  > {
    public render(): React.ReactNode {
      return (
        <ItemsViewTransactionContext.Consumer>
          {transaction => {
            return (<Component
              {...this.props as P}
              transaction={transaction}
            />);
          }}
        </ItemsViewTransactionContext.Consumer>
      );
    }
  };
};
