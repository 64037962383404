import { ClickOutsideWrapper, OpenArrowButton } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo, useState } from 'react';

import { RenderIf } from 'common/components/render-if';
import { ConstantFunctions } from 'common/constants/functions';
import { UuidUtil } from 'common/utils/uuid-utils';
import { EmailInput, NumberInput, TextInput } from '../small-input';
import { ButtonInput } from '../small-input/button-input';
import { DropdownList } from './dropdown-list';
import { useArrowMove } from './hooks';
import { Styled } from './styled';

const SearchPlaceholder = 'Search for an option...';

interface Props {
  elements: string[];
  activeElement: string;
  isDropdownOpen: boolean;
  openDropdown: () => void;
  closeDropdown: () => void;
  onClickDropDownTextItem: (id: string | number) => void;
  inputId?: string;
  createNewElement?: boolean;
  createEmptyElement?: boolean;
  withSearch?: boolean;
  isCollaps?: boolean;
  isShowArrow?: boolean;
  getElementLabel?: (value: string) => string;
  placeholder?: string;
  placeholderAsValue?: boolean;
  searchType?: 'Number' | 'Text' | 'Email';
}

export const SearchItemsWithDropdownList: React.FC<Props> = (props) => {
  const {
    isDropdownOpen,
    activeElement,
    elements,
    onClickDropDownTextItem,
    openDropdown,
    closeDropdown,
    createNewElement,
    withSearch,
    isCollaps,
    inputId,
    isShowArrow,
    getElementLabel,
    placeholder,
    placeholderAsValue,
    searchType = 'Text',
    createEmptyElement,
  } = props;
  const selectedElementInfo = React.useMemo(
    () => elements.find((element) => element === activeElement),
    [elements, activeElement],
  );
  const [searchValue, setSearchValue] = useState<string | number>();
  const [filteredElements, isValueAdded] = useMemo(() => {
    const lowerCasedSearch = searchValue?.toString().toLowerCase();
    const filterValues = lowerCasedSearch
      ? elements.filter(e => {
        const elementLabel = getElementLabel ? getElementLabel(e) : e;
        return elementLabel?.toString().toLowerCase().includes(lowerCasedSearch);
      })
      : elements.slice();
    let isAdded = false;
    const searchElement = elements.filter(v => {
      if (v === searchValue) {
        return true;
      }
      return (createEmptyElement && (searchValue === undefined || searchValue === '') && v === undefined);
    });
    if (createNewElement && !searchElement.length) {
      if (createEmptyElement && (searchValue === undefined || searchValue === '')) {
        filterValues.push(undefined);
        isAdded = true;
      } else if (searchValue !== undefined && searchValue !== '') {
        filterValues.push(searchValue.toString());
        isAdded = true;
      }
    }
    return [filterValues, isAdded];
  }, [searchValue, elements, createNewElement, getElementLabel, createEmptyElement]);
  const showValue = useMemo(() => selectedElementInfo || activeElement, [selectedElementInfo, activeElement]);
  const handleClickItem = useCallback((id: string) => {
    onClickDropDownTextItem(id);
  }, [onClickDropDownTextItem]);
  const {
    handleArrowKeys,
    handleOpen,
    focusElement,
  } = useArrowMove(
    filteredElements,
    isDropdownOpen,
    openDropdown,
    showValue,
    setSearchValue,
    handleClickItem,
    searchType === 'Number',
  );

  const uuid = useMemo(() => inputId || UuidUtil.generateUuid(), [inputId]);

  const handleExit = useCallback(() => {
    closeDropdown();
  }, [closeDropdown]);

  const handleClick = useCallback((e: React.MouseEvent) => {
    ConstantFunctions.stopEvent(e);
    handleOpen();
  }, [handleOpen]);

  const showCreateNew = useMemo(() => {
    return isValueAdded;
  }, [isValueAdded]);
  const showArrow = isShowArrow && !isCollaps;
  const inputVisibleValue = useMemo(() => {
    if (placeholderAsValue && !showValue) {
      return placeholder;
    }
    return getElementLabel && showValue ? getElementLabel(showValue) : showValue;
  }, [showValue, placeholderAsValue, placeholder, getElementLabel]);

  return (
    <ClickOutsideWrapper onExit={handleExit}>
      <Styled.DropDownContainer
        onClick={handleClick}
        onKeyDown={handleArrowKeys}
        isCollapse={isCollaps}
      >
        <ButtonInput value={inputVisibleValue} id={uuid} placeholder={placeholder} />
        <RenderIf condition={showArrow}>
          <OpenArrowButton isOpen={isDropdownOpen} />
        </RenderIf>
        {isDropdownOpen && (
          <Styled.DropDownMenuContainer>
            <RenderIf condition={withSearch}>
              <RenderIf condition={searchType === 'Text'}>
                <TextInput
                  value={searchValue as string}
                  onChange={setSearchValue}
                  onBlur={ConstantFunctions.doNothing}
                  placeholder={SearchPlaceholder}
                  isAutoFocus={true}
                />
              </RenderIf>
              <RenderIf condition={searchType === 'Number'}>
                <NumberInput
                  value={searchValue as number}
                  onChange={setSearchValue}
                  onBlur={ConstantFunctions.doNothing}
                  placeholder={SearchPlaceholder}
                  isAutoFocus={true}
                />
              </RenderIf>
              <RenderIf condition={searchType === 'Email'}>
                <EmailInput
                  value={searchValue as string}
                  placeholder={SearchPlaceholder}
                  onChange={setSearchValue}
                  onBlur={ConstantFunctions.doNothing}
                  isAutoFocus={true}
                />
              </RenderIf>
            </RenderIf>
            <DropdownList
              elements={filteredElements}
              activeElement={activeElement}
              onClick={handleClickItem}
              focusElement={focusElement}
              createNewElement={searchValue.toString()}
              showCreateNew={showCreateNew}
              getElementLabel={getElementLabel}
            />
          </Styled.DropDownMenuContainer >
        )}
      </Styled.DropDownContainer>
    </ClickOutsideWrapper>
  );
};
