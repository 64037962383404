import { MovableContextMenu } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Filters } from 'unit-2d-comments/enums';
import { TwoDCommentsActions } from 'unit-2d-comments/index';
import { FiltersData } from 'unit-2d-comments/interfaces';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { CommentaryFilters } from './commentary-filters';
import { Filter } from './filter';
import { Styled } from './styled';

interface OwnProps {
  x: number;
  y: number;
  onClose: () => void;
  filterValues: FiltersData;
}

interface DispatchProps {
  onFilterValueChanged: (filter: Filters, value: string) => void;
}

interface Props extends OwnProps, DispatchProps {}

const FilterMenuComponent: React.FC<Props> = ({
  x,
  y,
  onClose,
  filterValues: filters,
  onFilterValueChanged,
}) => {
  const { sendEvent } = useAnalytics();
  const filterChangeCallback = useCallback((filter: Filters, value: string) => {
    onFilterValueChanged(filter, value);
    const newValue = filters[filter] === value
      ? null
      : value;
    sendEvent(MetricNames.comments.changeFilter, { ...filters, [filter]: newValue });
  }, [filters]);
  return (
    <MovableContextMenu x={x} y={y} onClose={onClose} zIndex={10001}>
      <Styled.Container>
        {CommentaryFilters.map(settings => {
          return (
            <Filter
              key={settings.filter}
              filter={settings}
              onFilterValueChanged={filterChangeCallback}
              value={filters[settings.filter]}
            />
          );
        })}
      </Styled.Container>
    </MovableContextMenu>
  );
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onFilterValueChanged: (filter, value) => {
      dispatch(TwoDCommentsActions.updateFilters({ filter, value }));
    },
  };
}

export const FiltersMenu = connect(
  null,
  mapDispatchToProps,
)(FilterMenuComponent);
