import Ag from 'ag-grid-community';

import { Constants as DataBaseConstants } from 'common/components/data-base-table/constants';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { roundNumeric } from './helpers/round-numeric';
import { ICON_LIST_MAP } from './icon-list-map';
import { IconsTypeEnum, PropertyFormatEnum } from './interfaces';

interface IConstants {
  PROPERTIES_COLUMNS: Ag.ColDef[];
  ITEMS_COLUMNS: Ag.ColDef[];
  ASSEMBLIES_COLUMNS: Ag.ColDef[];
  UNITS: Record<PropertyFormatEnum, string[]>;
  TRANSFORMED_UNITS: string[];
  ICON_LIST_MAP: Record<IconsTypeEnum, React.ReactNode>;
  RESET_ASSEMBLY_OVERRIDE_DIALOG: string;
}

const UNITS = {
  [PropertyFormatEnum.Number]: [
    UnitTypes.WithoutUnit, UnitTypes.Nr,
    UnitTypes.Mm, UnitTypes.M, UnitTypes.M2, UnitTypes.M3,
    UnitTypes.In, UnitTypes.Ft, UnitTypes.Yd,
    UnitTypes.Ft2, UnitTypes.Yd2,
    UnitTypes.Yd3,
    UnitTypes.Kg, UnitTypes.Lb, UnitTypes.T,
    UnitTypes.GBP, UnitTypes.USD, UnitTypes.EUR,
    UnitTypes.Percentage,
  ],
  [PropertyFormatEnum.Text]: [],
};

const TRANSFORMED_UNITS = UNITS[PropertyFormatEnum.Number].map(unit => UnitUtil.getSupUnit(unit));

const RESET_ASSEMBLY_OVERRIDE_DIALOG = `RESET_ASSEMBLY_OVERRIDE_DIALOG`;

const isNumberValue = (value: string | number): boolean => typeof value === 'number' || !Number.isNaN(Number(value));

export const Constants: IConstants = {
  PROPERTIES_COLUMNS: [
    {
      colId: 'type',
      headerName: 'Type',
      field: 'type',
      cellRenderer: 'typeColumnCell',
    },
    {
      colId: 'value',
      headerName: 'Value',
      field: 'value',
      valueFormatter: (param) => isNumberValue(param.value) ? roundNumeric(param.value).toString() : param.value,
    },
    {
      colId: 'unit',
      headerName: 'Unit',
      field: 'unit',
    },
    ...DataBaseConstants.DEFAULT_COLUMNS,
  ],
  ITEMS_COLUMNS: DataBaseConstants.DEFAULT_COLUMNS,
  ASSEMBLIES_COLUMNS: DataBaseConstants.DEFAULT_COLUMNS,
  UNITS,
  TRANSFORMED_UNITS,
  ICON_LIST_MAP,
  RESET_ASSEMBLY_OVERRIDE_DIALOG,
};
