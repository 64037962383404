import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './styles.scss';

import { KreoIconCancel } from 'common/UIKit';
import { AdminDatabaseVm } from '../../interfaces/admin-database-vm';

interface ShortDatabaseItemProp {
  item: AdminDatabaseVm;
  className?: string;
  onDetachClick?: (item: AdminDatabaseVm) => void;
}

export class ShortDatabaseItem extends React.Component<ShortDatabaseItemProp> {
  public render(): React.ReactNode {
    const { item } = this.props;
    return (
      <div className={classNames('short-item', this.props.className)}>
        <div className='short-item__head-content'>
          <span>
            {item.name}
          </span>
          <span
            className='short-item__action'
            onClick={this.onDetachClick}
          >
            <KreoIconCancel />
          </span>
        </div>
      </div>
    );
  }

  @autobind
  private onDetachClick(): void {
    if (this.props.onDetachClick) {
      this.props.onDetachClick(this.props.item);
    }
  }
}
