import autobind from 'autobind-decorator';
import * as React from 'react';

import './index.scss';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { KreoIconAddedDone, KreoIconAddPlus } from 'common/UIKit';
import { ResourceType } from '../../enums';
import { UnitModel } from '../../interfaces/data';
import { LaborVariantModel, MaterialModel, PlantVariantModel } from '../../interfaces/resources-data';
import { InfoItem } from './info-item';

export type ResourceModelType = PlantVariantModel | LaborVariantModel | MaterialModel;

interface ResourceProps extends I18nAwareProps {
  resource: ResourceModelType;
  type: ResourceType;
  isSelected: (item: ResourceModelType) => boolean;
  select: (item: ResourceModelType) => void;
  unitMap: Record<number, UnitModel>;
}

class ResourceComponent extends React.Component<ResourceProps> {

  public render(): JSX.Element {
    const { type, resource } = this.props;
    const isSelected = this.props.isSelected(resource);
    const component = this.getResourceComponent();
    const name = this.getName();
    let titleClass = '';

    if (type === ResourceType.Labour) {
      titleClass = 'labor-item';
    } else if (type === ResourceType.Material) {
      titleClass = 'material-item';
    } else if (type === ResourceType.Plant) {
      titleClass = 'plant-item';
    }

    return (
      <div
        onClick={this.select}
        className={`modality-resource ${isSelected ? 'selected' : ''}`}
      >
        <div className='title-container'>
          <div
            title={name}
            className={`title ${titleClass}`}
          >
            {name}
          </div>
        </div>
        {component}
        <div className='action'>
          {isSelected ? <KreoIconAddedDone /> : <KreoIconAddPlus />}
        </div>
      </div>
    );
  }

  @autobind
  private getResourceComponent(): JSX.Element {
    const { type, i18n } = this.props;

    if (type === ResourceType.Labour) {
      const resource = this.props.resource as LaborVariantModel;
      return (
        <div className='info'>
          <InfoItem title='Skill' text={resource.name} />
          <InfoItem
            title='Rate'
            text={i18n.c(resource.cost)}
          />
        </div>
      );
    } else if (type === ResourceType.Material) {
      const resource = this.props.resource as MaterialModel;
      const unit = this.props.unitMap[resource.defaultUnitId];

      return (
        <div className='info'>
          <InfoItem
            title='Unit'
            text={unit && unit.acronym}
          />
        </div>
      );
    } else if (type === ResourceType.Plant) {
      const resource = this.props.resource as PlantVariantModel;
      const { rentTime, shiftHours } = resource;
      return (
        <div className='info'>
          <InfoItem
            title='Shift Hours'
            text={shiftHours.toString()}
          />
          <InfoItem
            title='Rent Time'
            text={rentTime.toString()}
          />
          <InfoItem
            title='Rate'
            text={i18n.c(resource.cost)}
          />
        </div>
      );
    }

    return null;
  }

  private getName(): string {
    const { type, resource } = this.props;
    if (type === ResourceType.Labour) {
      return (resource as LaborVariantModel).resourceName;
    } else if (type === ResourceType.Material) {
      return resource.name;
    } else if (type === ResourceType.Plant) {
      return `${(resource as PlantVariantModel).resourceName} ${resource.name}`;
    }
  }

  @autobind
  private select(): void {
    this.props.select(this.props.resource);
  }
}


export const Resource = withI18n(ResourceComponent);
