import { Constants, Icons, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { WizzardStatus } from 'common/components/drawings/enums/dropper-state';
import { useWizzardSetting } from 'common/components/drawings/hooks';
import { LinkComponent } from 'common/components/link-component';
import { RenderIf } from 'common/components/render-if';
import { WIZZARD_ERROR_MESSAGES } from '../../../enums';
import { AiButtonWithStatus } from '../../ai-button-with-status';
import { TUTORIALS } from '../constants';
import { SettingsItemWizzard } from '../setting-item-wizzard';
import { WizzardMainContainer } from '../wizzard-main-container';
import { WizzardWorkingAreaButton } from '../wizzard-working-area-button';
import { useFinderSettingsRedux } from './use-finder-settings-state';


const FinderSettingsComponent: React.FC = () => {
  const { error, dropFinderArea, search, eraser } = useFinderSettingsRedux();
  const [ allowRotation ] = useWizzardSetting('allowRotation');
  return (
    <WizzardMainContainer>
      <RenderIf condition={!!error}>
        <Text customColor={Constants.Colors.GENERAL_COLORS.red}>{WIZZARD_ERROR_MESSAGES[error]}</Text>
      </RenderIf>
      <SettingsItemWizzard
        label='Allow rotation'
        disabled={false}
        tutorial={TUTORIALS.AllowRotate}
        settingKey='allowRotation'
      />
      <SettingsItemWizzard
        label='Allow flipping'
        disabled={!allowRotation}
        tutorial={TUTORIALS.AllowFlip}
        settingKey='allowFlipping'
      />
      <LinkComponent
        text="Eraser"
        icon={Icons.Eraser}
        onClick={eraser}
        fontColor="mainFont"
        size="m"
      />
      <LinkComponent
        text="Reset"
        icon={Icons.UndoClassic}
        onClick={dropFinderArea}
        fontColor="mainFont"
        size="m"
      />
      <WizzardWorkingAreaButton />
      <AiButtonWithStatus
        status={WizzardStatus.Start}
        onClick={search}
        icon={Icons.Search}
        text='Search'
        disabled={false}
      />
    </WizzardMainContainer>
  );
};

export const FinderSettings = React.memo(FinderSettingsComponent);
