import * as classNames from 'classnames';
import * as React from 'react';

import './item.scss';

interface Props {
  label: string;
  className?: string;
  secondLabel?: string;
}

export class Item extends React.PureComponent<Props> {

  public render(): JSX.Element {
    const { label, className, secondLabel } = this.props;

    const classes = classNames('dashed-header-item', className);
    return (
      <div className={classes} title={label}>
        <div className='dashed-header-item__container'>
          <b>{label}</b>
          {secondLabel && <span>{secondLabel}</span>}
        </div>
      </div>
    );
  }
}
