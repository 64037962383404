import classNames from 'classnames';
import * as React from 'react';

import './toggle.scss';

import { ToggleSize } from '.';

interface Props {
  checked: boolean;
  checkedColor?: string;
  size: ToggleSize;
}

export class ToggleIcon extends React.Component<Props> {
  public render(): JSX.Element {
    const className = classNames('toggle-slider', this.props.size, {
      'checked': this.props.checked,
    });

    return (
      <span className={className} style={this.getSpanStyle()} />
    );
  }

  private getSpanStyle(): React.CSSProperties {
    const ret: React.CSSProperties = {};
    if (this.props.checked) {
      ret.backgroundColor = this.props.checkedColor;
    }
    return ret;
  }
}
