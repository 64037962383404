import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';

import { ValidationStepStatisticDataPayload } from 'unit-projects/actions/payloads/validation';
import {
  CEMeasurementsLoadDataSuccessPayload,
  MeasurementsOnChangeExtractorValuePayload,
  MeasurementsOnChangeGeneraralValuePayload,
  MeasurementsToggleItemSelectStatusPayload,
} from '../payloads/measurements';
import { CEMeasurementsActionTypes } from '../types/measurements';

function expandItemById(id: number): ActionWith<number> {
  return {
    type: CEMeasurementsActionTypes.EXPAND_ITEM_BY_ID,
    payload: id,
  };
}

function showExtractorByTreeId(id: number): ActionWith<number> {
  return {
    type: CEMeasurementsActionTypes.SHOW_EXTRACTORS_BY_ID,
    payload: id,
  };
}

function selectTreeNode(id: number): ActionWith<number> {
  return {
    type: CEMeasurementsActionTypes.SELECT_NODE,
    payload: id,
  };
}

function runSearch(query: string): ActionWith<string> {
  return {
    type: CEMeasurementsActionTypes.START_SEARCH,
    payload: query,
  };
}

function showHideTree(): Action {
  return {
    type: CEMeasurementsActionTypes.SHOW_HIDE_TREE,
  };
}


function onChangeInputValue(
  elementIndex: number,
  value: string,
  extractorId: string,
  generalExtractorId: string,
): ActionWith<MeasurementsOnChangeExtractorValuePayload> {
  return {
    type: CEMeasurementsActionTypes.ON_CHANGE_INPUT_VALUE,
    payload: {
      elementIndex,
      value,
      extractorId,
      generalExtractorId,
    },
  };
}

function onChangeGeneralInputValue(
  value: string,
  extractorId: string,
  generalExtractorId: string,
): ActionWith<MeasurementsOnChangeGeneraralValuePayload> {
  return {
    type: CEMeasurementsActionTypes.ON_CHANGE_GENERAL_INPUT_VALUE,
    payload: {
      value,
      extractorId,
      generalExtractorId,
    },
  };
}

function toggleItemSelectStatus(
  value: boolean,
  itemIndex: number,
): ActionWith<MeasurementsToggleItemSelectStatusPayload> {
  return {
    type: CEMeasurementsActionTypes.TOGGLE_ITEM_SELECT_STATUS,
    payload: {
      value,
      itemIndex,
    },
  };
}


function toggleGeneralSelectStatus(
  value: boolean,
): ActionWith<boolean> {
  return {
    type: CEMeasurementsActionTypes.TOGGLE_GENERAL_SELECT_STATUS,
    payload: value,
  };
}


function disabledItemSelectStatus(
  itemIndex: number,
): ActionWith<number> {
  return {
    type: CEMeasurementsActionTypes.DISABLED_ITEM_SELECT_STATUS,
    payload: itemIndex,
  };
}


function selectNodeByEngineId(
  id: number,
): ActionWith<number> {
  return {
    type: CEMeasurementsActionTypes.SELECT_NODE_BY_ENGINE_ID,
    payload: id,
  };
}

function loadDataRequest(): Action {
  return {
    type: CEMeasurementsActionTypes.LOAD_DATA_REQUEST,
  };
}

function loadDataSuccess(data: CEMeasurementsLoadDataSuccessPayload): ActionWith<CEMeasurementsLoadDataSuccessPayload> {
  return {
    type: CEMeasurementsActionTypes.LOAD_DATA_SUCCESSED,
    payload: data,
  };
}


function toggleCheckStatusFromEngine(engineIds: number[]): ActionWith<number[]> {
  return {
    type: CEMeasurementsActionTypes.SELECT_ELEMENT_FROM_ENGINE,
    payload: engineIds,
  };
}

function dropState(): Action {
  return {
    type: CEMeasurementsActionTypes.DROP_STATE,
  };
}

function getStatisticRequest(scenarioId: number): ActionWith<number> {
  return {
    type: CEMeasurementsActionTypes.GET_STATISTIC_REQUEST,
    payload: scenarioId,
  };
}

function getStatisticSuccess(
  statistic: ValidationStepStatisticDataPayload,
): ActionWith<ValidationStepStatisticDataPayload> {
  return {
    type: CEMeasurementsActionTypes.GET_STATISTIC_SUCCESS,
    payload: statistic,
  };
}

function dropStatistic(): Action {
  return {
    type: CEMeasurementsActionTypes.DROP_STATISTIC,
  };
}

function onBlurEdit(): Action {
  return {
    type: CEMeasurementsActionTypes.ON_BLUR_EDIT,
  };
}

function commitChangesSuccess(): Action {
  return {
    type: CEMeasurementsActionTypes.COMMIT_CHANGES_SUCCESS,
  };
}

function cancelEdit(): Action {
  return {
    type: CEMeasurementsActionTypes.CANCEL_EDIT,
  };
}

function cancelEditSuccess(): Action {
  return {
    type: CEMeasurementsActionTypes.CANCEL_EDIT_SUCCESS,
  };
}

function toggleLevelsShowStatus(value: boolean): ActionWith<boolean> {
  return {
    type: CEMeasurementsActionTypes.TOGGLE_LEVELS_SHOW_STATUS,
    payload: value,
  };
}

function approve(): Action {
  return {
    type: CEMeasurementsActionTypes.APPROVE,
  };
}

function disapprove(): Action {
  return {
    type: CEMeasurementsActionTypes.DISAPPROVE,
  };
}


export const CEMeasurementsActions = {
  expandItemById,
  showExtractorByTreeId,
  selectTreeNode,
  runSearch,
  showHideTree,
  onChangeInputValue,
  onChangeGeneralInputValue,
  toggleItemSelectStatus,
  toggleGeneralSelectStatus,
  selectNodeByEngineId,
  loadDataRequest,
  toggleCheckStatusFromEngine,
  loadDataSuccess,
  dropState,
  getStatisticRequest,
  getStatisticSuccess,
  dropStatistic,
  onBlurEdit,
  commitChangesSuccess,
  disabledItemSelectStatus,
  cancelEdit,
  cancelEditSuccess,
  toggleLevelsShowStatus,

  approve,
  disapprove,
};
