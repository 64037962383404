import styled from 'styled-components';

import { PaperStyled } from 'common/components/paper';
import { QuickSearchStyled } from 'common/components/quick-search';
import { OptionContainerStyled } from 'common/components/selection-menus';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${OptionContainerStyled.Container} {
    margin-bottom: 5px;
    align-items: center;
    gap: 0px;
    > div:first-child {
      margin-right: 15px;
    }

    > p {
      font-size: 14px;
    }
  }

  ${QuickSearchStyled.SearchIcon} {
    margin-left: 10px;
  }

  ${QuickSearchStyled.Input} {
    padding-left: 45px;
    font-size: 14px;
  }

  ${PaperStyled.Container} {
    margin: 0 10px;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid ${p => p.theme.color.background};
`;

const Launch = styled.div`
  display: flex;
  padding: 10px 10px 0px;
  > button {
    width: 100%;
    margin-bottom: 20px;

    > svg {
      margin-right: 15px;
    }

    > p {
      font-size: 14px;
    }
  }
`;

export const Styled = {
  Container,
  Categories,
  Launch,
};
