import styled from 'styled-components';

const PADDING = 20;

const InputWithSliderWrap = styled.div`
  width: 80%;
  box-sizing: border-box;
  margin: 20px auto 0;
  padding: 0 ${PADDING}px;

  > div {
    width: 100%;
  }
`;

const RoundSwitcher = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${PADDING}px;
  margin-bottom: 20px;
`;


export const Styled = {
  InputWithSliderWrap,
  RoundSwitcher,
};
