import * as React from 'react';

import { Arrangement } from '../../../constants';
import { FourSplit } from './four-view';
import { FourViewsBottomSplit } from './four-views-bottom-split';
import { FourViewsLeftSplit } from './four-views-left-split';
import { FourViewsRightSplit } from './four-views-right-split';
import { FourViewsSideBySideSplit } from './four-views-side-by-side';
import { FourViewsStakedSplit } from './four-views-stacked';
import { FourViewsTopSplit } from './four-views-top-split';
import { ThreeViewsBottomSplit } from './three-views-bottom-split';
import { ThreeViewsLeftSplit } from './three-views-left-split';
import { ThreeViewsRightSplit } from './three-views-right-split';
import { ThreeViewsTopSplit } from './three-views-top-split';
import { TwoReportViewsStacked } from './two-report-views-stacked';
import { TwoViewsSideBySide } from './two-views-side-by-side';

interface Props {
  type: Arrangement;
  tables: JSX.Element[];
  getTableBodySize(): { height: number, width: number };
}

export const ViewSplitter = (props: Props): JSX.Element => {
  const { getTableBodySize, type, tables } = props;
  const { height, width } = getTableBodySize();
  switch (type) {
    case Arrangement.TwoViewsStacked:
      return <TwoReportViewsStacked height={height} width={width} >{tables}</TwoReportViewsStacked>;
    case Arrangement.TwoViewsSideBySide:
      return <TwoViewsSideBySide height={height} width={width} >{tables}</TwoViewsSideBySide>;
    case Arrangement.ThreeViewsTopSplit:
      return <ThreeViewsTopSplit height={height} width={width} >{tables}</ThreeViewsTopSplit>;
    case Arrangement.ThreeViewsBottomSplit:
      return <ThreeViewsBottomSplit height={height} width={width}>{tables}</ThreeViewsBottomSplit>;
    case Arrangement.ThreeViewsLeftSplit:
      return <ThreeViewsLeftSplit height={height} width={width}>{tables}</ThreeViewsLeftSplit>;
    case Arrangement.ThreeViewsRightSplit:
      return <ThreeViewsRightSplit height={height} width={width}>{tables}</ThreeViewsRightSplit>;
    case Arrangement.FourViews:
      return <FourSplit height={height} width={width}>{tables}</FourSplit>;
    case Arrangement.FourViewsBottomSplit:
      return <FourViewsBottomSplit height={height} width={width}>{tables}</FourViewsBottomSplit>;
    case Arrangement.FourViewsTopSplit:
      return <FourViewsTopSplit height={height} width={width}>{tables}</FourViewsTopSplit>;
    case Arrangement.FourViewsLeftSplit:
      return <FourViewsLeftSplit height={height} width={width}>{tables}</FourViewsLeftSplit>;
    case Arrangement.FourViewsRightSplit:
      return <FourViewsRightSplit height={height} width={width}>{tables}</FourViewsRightSplit>;
    case Arrangement.FourViewsStacked:
      return <FourViewsStakedSplit height={height} width={width}>{tables}</FourViewsStakedSplit>;
    case Arrangement.FourViewsSideBySide:
      return <FourViewsSideBySideSplit height={height} width={width}>{tables}</FourViewsSideBySideSplit>;
    default: return tables[0] || null;
  }
};
