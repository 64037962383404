import { objectUtils } from 'common/utils/object-utils';

export class AppRoute<TQueryParams = {}> {
  public readonly path: string;

  constructor(path: string) {
    this.path = path;
  }

  public url(): string {
    return this.path;
  }

  public urlWithQuery(params: TQueryParams): string {
    const queryString = objectUtils.toQueryString(params);

    return `${this.url()}${queryString ? `?${queryString}` : ''}`;
  }
}
