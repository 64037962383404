import React, { useMemo } from 'react';

import { wrapComponentWithImageAuth } from '../auth-image/wrap-component-with-image-auth';
import { ContextMenuUtils } from '../item-context-menu';
import { Item } from '../item-context-menu/menu-item-container';
import { Company } from '../project-card';
import { ContentRow } from './content-row';
import { Styled } from './styled';


export interface UserInfo {
  name: string;
  email: string;
  avatar: string;
}

interface Props {
  image: string;
  projectName: string;
  created: string;
  menuItems: Item[];
  users: UserInfo[];
  url: string;
  RouterLink: any;
  sizeItem: string;
  menuWidth: number;
  isCalculating: boolean;
  CalculatingComponent: () => JSX.Element;
  isBlocked?: boolean;
  isInaccessibleProject: boolean;
  company: Company;
  isShareWithCompany: boolean;
}

const ProjectRowComponent = React.memo<Props>(
  ({
    image,
    projectName,
    created,
    menuItems,
    users,
    url,
    RouterLink,
    sizeItem,
    menuWidth,
    isCalculating,
    CalculatingComponent,
    isBlocked,
    isInaccessibleProject,
    company,
    isShareWithCompany,
  }) => {
    const Link = !isBlocked && url ? RouterLink : ({ children }) => <div>{children}</div>;
    const error = !url && !isCalculating;
    const extraProps = useMemo(() => ({ menuWidth, sizeItem }), [menuWidth, sizeItem]);
    const onOpenClick = ContextMenuUtils.useOpenContextMenu(() => menuItems, extraProps);

    return (
      <Styled.Wrapper>
        <Link to={url}>
          <Styled.Container
            error={error}
            isInaccessibleProject={isInaccessibleProject}
            onContextMenu={onOpenClick}
          >
            {isCalculating ? (
              <CalculatingComponent />
            ) : (
              <ContentRow
                projectName={projectName}
                menuItems={menuItems}
                users={users}
                url={url}
                sizeItem={sizeItem}
                menuWidth={menuWidth}
                isInaccessibleProject={isInaccessibleProject}
                image={image}
                created={created}
                company={company}
                isShareWithCompany={isShareWithCompany}
              />
            )}
          </Styled.Container>
        </Link>
      </Styled.Wrapper>
    );
  },
);

export const ProjectRow = wrapComponentWithImageAuth(ProjectRowComponent, 'image');
