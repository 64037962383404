import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants/drawing-canvas-constants';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { ContextObserverWithPrevious } from '../../../drawings-contexts';
import { DrawingsGeometryEntityBase, DrawingsGeometryEntityBaseConfig } from '../base';

interface DrawingsGeometryOffsetPointConfig extends DrawingsGeometryEntityBaseConfig {
  layer: paper.Layer;
  position: paper.Point;
  observableRenderContext: ContextObserverWithPrevious<DrawingsRenderParams>;
}

const POINT_RADIUS = 10;
const STROKE_WIDTH = 5;

export class DrawingsGeometryOffsetPoint extends DrawingsGeometryEntityBase<DrawingsGeometryOffsetPointConfig> {
  private _point: paper.Path.Circle;

  constructor(config: DrawingsGeometryOffsetPointConfig) {
    super(config);
    this._eventsApplier.enableDrag(true);
    const { zoom } = config.observableRenderContext.getPrevContext();
    this._point = new paper.Path.Circle(config.position, POINT_RADIUS / zoom);
    this._point.strokeWidth = STROKE_WIDTH / zoom;
    this._point.strokeColor = DrawingsCanvasColors.white;
    this._point.fillColor = DrawingsCanvasColors.pointColor;
    this._point.onMouseDown = this.onMouseDown;
    this._config.layer.addTo(this._point);
    this._config.observableRenderContext.subscribe(this.updateRenderParams);
  }

  public get position(): paper.Point {
    return this._point.position;
  }

  public set position(position: paper.Point) {
    this._point.position = position;
  }

  @autobind
  public updateRenderParams({ zoom }: DrawingsRenderParams): void {
    const oldZoom = this._config.observableRenderContext.getPrevContext().zoom;
    this._point.scale(oldZoom / zoom);
    this._point.strokeWidth = STROKE_WIDTH / zoom;
  }

  public destroy(): void {
    this._point.remove();
    this._config.observableRenderContext.unsubscribe(this.updateRenderParams);
  }

  @autobind
  private onMouseDown(e: PaperMouseEvent): void {
    this._config.onStartDrag(this.id, e);
  }
}
