import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-header-breadcrumbs.scss';

import { State } from 'common/interfaces/state';
import { IconButton, KreoDialogActions, KreoIconNavbarBack, KreoIconSearchLarge } from 'common/UIKit';
import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { ClassificationActions } from '../../actions/creators/classification';
import {
  ClassificationHeaderBreadcrumbsContainer,
} from '../classification-header-breadcrumbs-container';


interface DispatchProps {
  onAssign: () => void;
  onBack: () => void;
  openConfirmationDialog: () => void;
  toggleSearchMode: () => void;
}

interface OwnProps {
  breadCrumbsCount: number;
}

interface StateProps {
  isSaveEnabled: boolean;
  isSearchShown: boolean;
}

interface Props extends DispatchProps, OwnProps, StateProps {

}


const DIALOG_NAME = 'CLASSIFICATION_BACK_CONFIRMATION_DIALOG';

class ClassificationHeaderBreadcrumbsComponent extends React.PureComponent<Props> {
  private clickMethod: () => void = null;

  public render(): React.ReactNode {
    return (
      <div className='classification-header-breadcrumbs'>
        <IconButton
          size='large'
          className='classification-header-breadcrumbs__button'
          controlName='back-button'
          onClick={this.onBack}
        >
          <KreoIconNavbarBack />
        </IconButton>
        <ClassificationHeaderBreadcrumbsContainer
          onBreadcrumbSelect={this.saveMethod}
          breadCrumbsCount={this.props.breadCrumbsCount}
        />
        {
          this.props.isSearchShown && (
            <IconButton
              size='large'
              className='classification-header-breadcrumbs__search'
              onClick={this.props.toggleSearchMode}
            >
              <KreoIconSearchLarge/>
            </IconButton>
          )
        }
        <KreoConfirmationDialog
          onYes={this.onSaveExit}
          name={DIALOG_NAME}
          yesText='Save & Exit'
          noText='Leave'
          onNo={this.onLeave}
          title='You have unsaved changes'
        >
          If you leave now, your changes will be lost
        </KreoConfirmationDialog>
      </div>);
  }

  @autobind
  private onBack(): void {
    if (this.props.isSaveEnabled) {
      this.clickMethod = this.props.onBack;
      this.props.openConfirmationDialog();
    } else {
      this.props.onBack();
    }
  }

  @autobind
  private onSaveExit(): void {
    this.props.onAssign();
    this.clickMethod();
  }

  @autobind
  private onLeave(): void {
    this.clickMethod();
  }

  @autobind
  private saveMethod(callback: () => void): void {
    this.clickMethod = callback;
    this.props.openConfirmationDialog();
  }
}


function mapStateToProps(state: State, { breadCrumbsCount }: OwnProps): StateProps {
  const { modelBrowserFilters: modelBrowserIsolationStatus } = state.classification;
  return {
    isSaveEnabled: state.classification.isSaveButtonEnabled,
    isSearchShown: !(breadCrumbsCount === 3
      || (breadCrumbsCount && modelBrowserIsolationStatus.breadCrumbs[breadCrumbsCount - 1].multiple)),
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onAssign: () => dispatch(ClassificationActions.assignClassification()),
    onBack: () => dispatch(ClassificationActions.onHeaderBackClick()),
    openConfirmationDialog: () => dispatch(KreoDialogActions.openDialog(DIALOG_NAME)),
    toggleSearchMode: () => dispatch(ClassificationActions.toggleSearchMode()),
  };
}

const connecter = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationHeaderBreadcrumbs = connecter(ClassificationHeaderBreadcrumbsComponent);
