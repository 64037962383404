import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconNotification: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons/16x16/Notification' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M10.9499874,13 C10.9499874,13.0358686 10.949358,13.0715901 10.9481094,13.1071542 L10.9481094,13
          L0.921339789,13 C0.645197414,13 0.421339789,12.7761424 0.421339789,12.5 C0.421339789,12.4032675
          0.449399357,12.3086119 0.502117179,12.2275071 L1.94998744,10 L1.94998744,6.02500628 C1.94998744,2.69748719
          4.64747464,9.10105957e-13 7.97499372,9.09494702e-13 C11.3025128,9.08883446e-13 14,2.69748719 14,6.02500628
          L14,10 L15.4478703,12.2275071 C15.598364,12.4590373 15.5326709,12.7687289 15.3011406,12.9192226
          C15.2200357,12.9719404 15.1253801,13 15.0286477,13 L10.9499874,13 Z M5.12069315,14 L10.7792817,14
          C10.3674453,15.1651924 9.25620627,16 7.94998744,16 C6.64376862,16 5.53252956,15.1651924 5.12069315,14 Z'
          id='Combined-Shape'
          fill={KreoColors.f2}
        />
      </g>
    </svg>
  );
};
