import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { KreoDialogActions } from 'common/UIKit';
import { DrawingDialogs } from '../../constants/drawing-dialogs';

import { DrawingsLayoutApiContextProvider } from '../../drawings-layout-api-context';
import { DrawingsGeometryRendererAPI } from '../../interfaces/drawings-geometry-renderer-api';
import { DrawingsRendererApiContext, DrawingsRendererApiContextSetter } from './renderer-api-context';

interface Props {
  openUnsavedChangesDialog: (accept: () => void, cancel?: () => void) => void;
}

export const DrawingsRendererApiContextProviderComponent: React.FC<Props> = (
  {
    children,
    openUnsavedChangesDialog,
  },
) => {
  const [rendererApi, setRendererApi] = useState<DrawingsGeometryRendererAPI>(null);
  const tryCancelDrawMode = useCallback((accept, cancel) => {
    if (rendererApi?.hasNotFinishedGeometry()) {
      openUnsavedChangesDialog(accept, cancel);
    } else {
      accept();
    }
  }, [rendererApi]);

  const api = useMemo(() => {
    return { tryCancelDrawMode, rendererApi };
  }, [tryCancelDrawMode, rendererApi]);
  return (
    <DrawingsRendererApiContext.Provider value={api}>
      <DrawingsRendererApiContextSetter.Provider value={setRendererApi}>
        <DrawingsLayoutApiContextProvider>
          {children}
        </DrawingsLayoutApiContextProvider>
      </DrawingsRendererApiContextSetter.Provider>
    </DrawingsRendererApiContext.Provider>
  );
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): Props {
  return {
    openUnsavedChangesDialog: (accept, cancel) =>
      dispatch(KreoDialogActions.openDialog(DrawingDialogs.UNSAVED_CHANGES_DIALOG, { accept, cancel })),
  };
}

export const DrawingsRendererApiContextProvider =
  connect(null, mapDispatchToProps)(DrawingsRendererApiContextProviderComponent);
