const prefix = '@custom-filters';

const GET_CUSTOM_FILTERS_INFO = `${prefix}/GET_CUSTOM_FILTERS_INFO`;
const GET_CUSTOM_FILTERS_BY_IDS = `${prefix}/GET_CUSTOM_FILTERS_BY_IDS`;
const SET_CUSTOM_FILTERS_INFO = `${prefix}/SET_CUSTOM_FILTERS_INFO`;
const SET_CUSTOM_FILTER_EDIT_MODEL = `${prefix}/SET_CUSTOM_FILTER_EDIT_MODEL`;
const CREATE_CUSTOM_FILTERS = `${prefix}/CREATE_CUSTOM_FILTERS`;
const UPDATE_CUSTOM_FILTERS = `${prefix}/UPDATE_CUSTOM_FILTERS`;
const DELETE_CUSTOM_FILTERS = `${prefix}/DELETE_CUSTOM_FILTERS`;
const COPY_CUSTOM_FILTER = `${prefix}/COPY_CUSTOM_FILTER`;
const APPLY_CUSTOM_FILTER = `${prefix}/APPLY_CUSTOM_FILTER`;
const SET_APPLIED_CUSTOM_FILTER = `${prefix}/SET_APPLIED_CUSTOM_FILTER`;

export const CustomFiltersTypes = {
  GET_CUSTOM_FILTERS_INFO,
  GET_CUSTOM_FILTERS_BY_IDS,
  SET_CUSTOM_FILTERS_INFO,
  SET_CUSTOM_FILTER_EDIT_MODEL,
  CREATE_CUSTOM_FILTERS,
  UPDATE_CUSTOM_FILTERS,
  DELETE_CUSTOM_FILTERS,
  COPY_CUSTOM_FILTER,
  APPLY_CUSTOM_FILTER,
  SET_APPLIED_CUSTOM_FILTER,
};
