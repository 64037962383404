import React from 'react';

import { TemplatesMenu } from 'common/components/templates-menu';
import { useTemplatesRedux } from './hooks/use-templates-redux';
import { Styled } from './styled';

interface Props {
  onCloseMenu: () => void;
}


const TempalatesMenuWrapperComponent: React.FC<Props> = ({ onCloseMenu }) => {
  const {
    openCreateTemplateDialog,
    applyTemplate,
    updateTemplate,
    duplicateTemplate,
    deleteTemplate,
    templates,
    disabledCreate,
  } = useTemplatesRedux(onCloseMenu);
  return (
    <Styled.HoverMenuContainer
      allItemsCount={templates.length}
    >
      <TemplatesMenu
        openDialog={openCreateTemplateDialog}
        templates={templates}
        applyTemplate={applyTemplate}
        updateTemplate={updateTemplate}
        duplicateTemplate={duplicateTemplate}
        deleteTemplate={deleteTemplate}
        disableCreate={disabledCreate}
      />
    </Styled.HoverMenuContainer>
  );
};


export const TempalatesMenuWrapper = React.memo(TempalatesMenuWrapperComponent);
