import { RowNode } from 'ag-grid-community';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import './page.scss';

import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { Controls3D } from 'common/components/controls-3d';
import { KreoToolbar } from 'common/components/kreo-toolbar';
import { KreoColors } from 'common/enums/kreo-colors';
import { RequestStatus } from 'common/enums/request-status';
import { SyncStatus } from 'common/enums/sync-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { AccordionMenu } from '../../../../components/accordion-menu';
import { Engine } from '../../../../components/engine';
// eslint-disable-next-line import/named
import { Disposable, KreoEngine } from '../../../../components/engine/KreoEngine';
import { Unit } from '../../../../components/engine/KreoEngineConsts';
import { MapIdHelper } from '../../../../components/engine/map-id-helper';
import { SplitterLayout } from '../../../../components/splitter-layout';
import { PlanProjectRouteParams, Qto3dProjectRouteParams } from '../../../../routes/app-routes-params';
import { HELP_VIDEO_DIALOG_NAME } from '../../../../units/analytics/components/help-center';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';
import { ProjectLayout } from '../../../project-dashbord';
import { ProjectsActions } from '../../actions/creators/common';
import { QuantityTakeOffActions } from '../../actions/creators/quantity-take-off';
import { QuantityTakeOffFilterActions } from '../../actions/creators/quantity-take-off-filter';
import { PropertiesDataContextProvider } from '../../components/properties-data-context-provider';
import { PropertiesPopupApi } from '../../components/properties-popup-button';
import {
  QtoLeftPanelWithDataProvider as QtoLeftPanel,
} from '../../components/quantity-take-off-left-panel';
import { QtoLeftPanelConstants } from '../../components/quantity-take-off-left-panel/constants';
import { TableApi } from '../../components/quantity-take-off-left-panel/interfaces';
import { isLocation } from '../../components/quantity-take-off-left-panel/is-location';
import { QtoReportPanel } from '../../components/quantity-take-off-report-panel';
import { QtoReportTableApi } from '../../components/quantity-take-off-report-panel/quantity-take-off-report-panel';
import {
  QtoReportPanelControls,
} from '../../components/quantity-take-off-report-panel/quantity-take-off-report-panel-controls';
import {
  QtoReportTemplateTableApi,
  QuantityTakeOffTemplateTablePanel,
} from '../../components/quantity-take-off-template-table-panel/quantity-take-off-template-table-panel';
import {
  TemplateTableTemplatePanelControls,
} from '../../components/quantity-take-off-template-table-panel/template-table-panel-controls';
import { EngineBasedPages } from '../../enums/engine-based-pages';
import { QtoSelectElementsEventSource } from '../../enums/qto-select-elements-event-source';
import { EngineFilterState } from '../../interfaces/engine-filter-state';
import { GraphStorageRecordsConfig } from '../../interfaces/graph-storage-records-config';
import { ExtractorFunction } from '../../interfaces/quantity-take-off/extractor-function';
import { QuantityTakeOffLinearTree } from '../../interfaces/quantity-take-off/quantity-take-off-linear-tree';
import { QuantityTakeOffModel } from '../../interfaces/quantity-take-off/quantity-take-off-model';
import { ModelType } from '../../interfaces/quantity-take-off/quantity-take-off-model-type';
import { QtoRecords } from '../../interfaces/quantity-take-off/quantity-take-off-records';
import { QtoSyncBreakdownData } from '../../interfaces/quantity-take-off/sync-breakdown-data';
import { SyncHelper } from './sync-helper';

interface OwnProps {
  modelType: ModelType;
  projectId: number;
}


interface StateProps {
  tree: QuantityTakeOffLinearTree | null;
  extractorsLookup: ExtractorFunction[];
  modelRequestStatus: RequestStatus;
  loadedProjectId: number;
  currentProjectId: number;
  selectedBimElementsIds: number[];
  selectElementsEventSource: QtoSelectElementsEventSource | null;
  model: QuantityTakeOffModel | null;
  isAutoFocusEngine: boolean | null;
  elementRecords: QtoRecords;
  expandTable?: boolean;
  filteredIds: number[];
  recordsConfig: GraphStorageRecordsConfig;
  loadReportStatus: RequestStatus;
  isAddNewPropsInRecords: boolean;
  isImperialUnit: boolean;
  disableShowDialogList: string[];
  engineFilterState: EngineFilterState;
}

interface DispatchProps {
  getModel: (projectId: number) => void;
  highlightTreeNode: (nodeIndex: number) => void;
  selectBimElementsFromEngine: (bimIds: number[]) => void;
  dropState: () => void;
  collapseAll: () => void;
  userExpand: () => void;
  uploadQtoPageData: (projectId: number) => void;
  getLeftPanelRecords: (projectId: number) => void;
  filterTree: (bimIds: number[]) => void;
  toggleAutoFocusEngine: () => void;
  getRecordsConfig: (projectId: number) => void;
  updateStateAfterAddNewProps: () => void;
  openDialog: (name: string) => void;
  setEngineFilterState: (projectId: number, filterState: EngineFilterState) => void;
  getEngineFilterState: () => void;
  callFilter: () => void;
}

interface Props extends OwnProps, StateProps, DispatchProps, AbilityAwareProps { }

interface PageState {
  isClipBoxEnabled: boolean;
  isIsometryEnabled: boolean;
  isGhostEnabled: boolean;
  engineContainerRef: HTMLDivElement;
  isShowAllEnabled: boolean;
  isLocation: boolean;
  leftPanelHeight: number;
  isRulerEnabled: boolean;
  isImperialUnit: boolean;
  selectedIds: number[];
  pagePanesConfigs: Array<{ size: number, minSize: number }>;
  rightPanesConfigs: Array<{ size: number, minSize: number }>;
  isToolbarHide: boolean;
  isFullScreen: boolean;
  bimIdsSelectedFromReport: number[];
  isEnginReady: boolean;
  is3DLeftPanelReady: boolean;
  isAnyInvisible: boolean;
  color: string;
  visibleIds: Record<number, boolean>;
  clipboxVisible: number[];
  isPivotModeReportTable: boolean;
  isReportReady: boolean;
  recordValuesMap: Record<string, Record<string, void>>;
}

const minPagePanesSize = [555, 530];
const minRightPaneSize = [200, 50];

class QuantityTakeOffComponent extends React.Component<Props, PageState> {
  private engine: KreoEngine = null;
  private propertiesPopupApi: PropertiesPopupApi = null;
  private mapIdHelper: MapIdHelper = new MapIdHelper();
  private elementTableApi: TableApi = null;
  private reportTableSelectApi: TableApi = null;
  private filteredIds: number[] = [];
  private filteredIdsMap: Record<number, boolean> = {};
  private syncSelection: SyncHelper = new SyncHelper();
  private engineUnit: Unit = null;
  private needsUpdatedVisibleCollection: boolean = true;
  private qtoReportTableApi: QtoReportTableApi = null;
  private qtoReportTemplateTableApi: QtoReportTemplateTableApi = null;
  private customFilterSelectApi: TableApi = null;
  private skipFilterSelection: boolean;

  private leftPaneRef: React.RefObject<HTMLDivElement> = React.createRef();
  private deferredExecutor: DeferredExecutor = new DeferredExecutor(100);
  private clipBoxState: any = null;
  private engineEventSubscriptions: Disposable[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      isClipBoxEnabled: false,
      isIsometryEnabled: false,
      isGhostEnabled: true,
      engineContainerRef: null,
      isShowAllEnabled: false,
      isLocation: false,
      leftPanelHeight: 0,
      isRulerEnabled: false,
      isImperialUnit: this.props.isImperialUnit,
      selectedIds: [],
      pagePanesConfigs: minPagePanesSize.map(minSize => ({ size: 0, minSize })),
      rightPanesConfigs: minRightPaneSize.map(minSize => ({ size: 0, minSize })),
      isToolbarHide: false,
      isFullScreen: false,
      bimIdsSelectedFromReport: [],
      isEnginReady: false,
      is3DLeftPanelReady: false,
      isAnyInvisible: false,
      color: KreoColors.blue4,
      visibleIds: {},
      clipboxVisible: null,
      isPivotModeReportTable: false,
      isReportReady: false,
      recordValuesMap: {},
    };
  }

  private get isPageDataReady(): boolean {
    const { is3DLeftPanelReady, isEnginReady, isReportReady } = this.state;
    return is3DLeftPanelReady && isEnginReady && isReportReady;
  }

  public render(): React.ReactNode {
    const {
      modelRequestStatus,
    } = this.props;
    const projectId = this.props.projectId;
    const isLoaded = modelRequestStatus === RequestStatus.Loaded;
    return (
      <ProjectLayout waitingForAdditionalInfo={!isLoaded} projectId={projectId && projectId.toString()}>
        <PropertiesDataContextProvider >
          <div className='quantity-take-off-page' ref={this.savePageRef} >
            <SplitterLayout
              paneConfigs={this.state.pagePanesConfigs}
              primaryPaneCollapseToggleEnable={true}
            >
              <div
                ref={this.leftPaneRef}
                className='quantity-take-off-page__tree-panel'
              >
                {this.renderTreePanelContent()}
              </div>
              <div className='quantity-take-off-page__right-panel' ref={this.saveRightPanes}>
                {this.renderEnginPanelContent()}
              </div>
            </SplitterLayout>
          </div>
        </PropertiesDataContextProvider>
      </ProjectLayout >
    );
  }

  @autobind
  public async componentDidMount(): Promise<void> {
    this.fetchModelIfNeeded();
    await this.mapIdHelper.init(this.props.currentProjectId);
    if (this.props.disableShowDialogList
      && !this.props.disableShowDialogList.includes(HELP_VIDEO_DIALOG_NAME)) {
      this.props.openDialog(HELP_VIDEO_DIALOG_NAME);
    }
    this.props.getEngineFilterState();
  }

  public componentDidUpdate(prevProps: Props, prevState: PageState): void {
    const { filteredIds } = this.props;

    if (this.leftPaneRef.current && this.state.leftPanelHeight === 0) {
      const leftPanelHeight = this.leftPaneRef.current.getBoundingClientRect().height;
      this.setState({ leftPanelHeight });
    }

    if (!this.engine || !this.elementTableApi) {
      return;
    }
    const is3DReady = this.state.is3DLeftPanelReady && this.state.isEnginReady;
    const prevIs3DReadyStatus = prevState.is3DLeftPanelReady && prevState.isEnginReady;
    if (is3DReady && !prevIs3DReadyStatus && this.state.selectedIds.length) {
      this.syncSelection.startSync(
        QtoSelectElementsEventSource.ReportPanel,
        () => this.syncSelectedValue(false),
      );
    }

    const pageReadyStatus = is3DReady && this.state.isReportReady;
    const prevPageReadyStatus = prevIs3DReadyStatus && prevState.isReportReady;
    if (pageReadyStatus && !prevPageReadyStatus) {
      this.props.callFilter();
    }

    if (filteredIds !== prevProps.filteredIds) {
      this.filteredIds = filteredIds;
      this.filteredIdsMap = {};
      filteredIds.forEach(id => this.filteredIdsMap[id] = true);
      this.showFilteredElements(filteredIds);
    }

    if (this.props.engineFilterState && !prevProps.engineFilterState) {
      this.proccessEngineFilterState();
    }

    if (!this.engineUnit) {
      this.engineUnit = this.engine && this.engine.getUiUnits().length;
    }

    if (this.props.isImperialUnit !== prevProps.isImperialUnit) {
      this.toggleUnit();
    }
  }

  public componentWillUnmount(): void {
    for (const subscription of this.engineEventSubscriptions) {
      subscription.dispose();
    }

    this.engineEventSubscriptions = [];
    this.props.dropState();
  }

  @autobind
  private savePageRef(ref: HTMLDivElement): void {
    if (ref === null) {
      return;
    }
    const pageRect = ref.getBoundingClientRect();
    const pagePanesConfigs = [
      { size: pageRect.width * 0.4, minSize: minPagePanesSize[0] },
      { size: pageRect.width * 0.6, minSize: minPagePanesSize[1] },
    ];
    this.setState({ pagePanesConfigs });
  }

  @autobind
  private saveRightPanes(ref: HTMLDivElement): void {
    if (ref === null) {
      return;
    }
    const rightPaneRect = ref.getBoundingClientRect();
    const rightPanesConfigs = [
      { size: rightPaneRect.height * 0.6, minSize: minRightPaneSize[0] },
      { size: rightPaneRect.height * 0.4, minSize: minRightPaneSize[1] },
    ];
    this.setState({ rightPanesConfigs });
  }

  private renderEnginPanelContent(): React.ReactNode {
    const { currentProjectId } = this.props;

    const {
      isFullScreen,
    } = this.state;

    const accordionMenuConfigs = [
      { name: 'Engin', hideHeader: true },
      { name: 'Reports', isOpen: true, children: this.getQtoReportTableControl() },
      { name: 'Templates', children: this.getQtoReportTemplateTableControl() },
    ];

    return (
      <AccordionMenu
        tabConfig={accordionMenuConfigs}
      >
        <div
          className={classNames(
            'quantity-take-off-page__engine-panel',
            { 'quantity-take-off-page__engine-panel--full-screen': isFullScreen },
          )}
        >
          { this.render3DEngineContext() }
        </div>
        <div className='quantity-take-off-page__report-panel'>
          <QtoReportPanel
            findInElements={this.findInElements}
            projectId={currentProjectId}
            modelType={this.props.modelType}
            onSelectionChange={this.onReportTableSelectionChange}
            isPivotMode={this.state.isPivotModeReportTable}
            sendTableSelectionApi={this.saveReportTable}
            sendTableApi={this.saveReportTableApi}
            getSyncBreakdownData={this.getSyncBreakdownData}
            onReportReady={this.onReportReady}
            readonly={false}
          />
        </div>
        <div className='quantity-take-off-page__report-panel'>
          <QuantityTakeOffTemplateTablePanel
            modelType={this.props.modelType}
            projectId={currentProjectId}
            onSelectFiltersFromTemplate={this.onSelectFiltersFromTemplate}
            sendTableApi={this.saveReportTemplateTableApi}
          />
        </div>
      </AccordionMenu >
    );
  }

  @autobind
  private getQtoReportTableControl(): JSX.Element {
    if (this.qtoReportTableApi) {
      return (
        <QtoReportPanelControls
          onReportExport={this.qtoReportTableApi.onReportExport}
          projectId={this.props.currentProjectId}
          modelType={this.props.modelType}
          pivot={this.state.isPivotModeReportTable}
          pivotChanged={this.pivotModeReportTableChanged}
          expandAll={this.qtoReportTableApi.expandAll}
          collapseAll={this.qtoReportTableApi.collapseAll}
        />
      );
    }
  }

  @autobind
  private getQtoReportTemplateTableControl(): JSX.Element {
    if (this.qtoReportTableApi) {
      return (
        <TemplateTableTemplatePanelControls
          collapseAll={this.qtoReportTemplateTableApi.collapseAll}
          expandAll={this.qtoReportTemplateTableApi.expandAll}
          projectId={this.props.currentProjectId}
          modelType={this.props.modelType}
        />
      );
    }
  }

  private renderTreePanelContent(): React.ReactNode {
    return (
      <QtoLeftPanel
        isPageDataReady={this.isPageDataReady}
        syncWithElementsDataInReport={this.syncElementsDataWithReport}
        disableEngineFilter={this.disableEngineFilter}
        expandTable={this.props.expandTable}
        onElementTableSelected={this.onElementTableSelected}
        locationsViewChange={this.onLocationViewChange}
        elementViewChange={this.onLocationViewChange}
        sendElementTableApi={this.saveElementTable}
        userExpand={this.props.userExpand}
        selectElementsEventSource={this.props.selectElementsEventSource}
        recordsConfig={this.props.recordsConfig}
        elementRecords={this.props.elementRecords}
        isLocation={this.state.isLocation}
        onFilter={this.onFilter}
        currentProjectId={this.props.currentProjectId}
        height={this.state.leftPanelHeight}
        engine={this.engine}
        mapIdHelper={this.mapIdHelper}
        loadRecords={this.props.getLeftPanelRecords}
        getReportElementIds={this.getReportElementIds}
        isImperialUnit={this.props.isImperialUnit}
        onReady={this.on3DLeftPanelReady}
        visibleElementIds={this.state.visibleIds}
        visibleClipBoxElements={this.state.clipboxVisible}
        pivotEnabled={this.state.isPivotModeReportTable}
        changeSyncStatus={this.changeSyncStatus}
        recordValuesMap={this.state.recordValuesMap}
        onCustomFiltersSelect={this.onCustomFiltersSelect}
        sendCustomFiltersSelectionApi={this.saveCustomFilterApi}
        modelType={this.props.modelType}
      />
    );
  }

  @autobind
  private saveCustomFilterApi(api: TableApi): void {
    this.customFilterSelectApi = api;
  }

  @autobind
  private onCustomFiltersSelect(ids: string[]): void {
    if (this.skipFilterSelection) {
      this.skipFilterSelection = false;
      return;
    }
    this.skipFilterSelection = true;
    this.qtoReportTemplateTableApi.changeSelection(ids);
  }

  @autobind
  private saveReportTableApi(api: QtoReportTableApi): void {
    this.qtoReportTableApi = api;
  }

  @autobind
  private onSelectFiltersFromTemplate(ids: string[]): void {
    if (this.skipFilterSelection) {
      this.skipFilterSelection = false;
      return;
    }
    this.skipFilterSelection = true;
    this.customFilterSelectApi.setSelected(ids);
  }

  @autobind
  private syncElementsDataWithReport(nodes: RowNode[]): void {
    this.qtoReportTableApi.syncWithElements(
      {
        elementNodes: nodes.filter(x => !x.group),
        getColumn: this.elementTableApi.getColumn,
      });
  }

  @autobind
  private getSyncBreakdownData(nodes: RowNode[]): QtoSyncBreakdownData {
    const ids = [];
    for (const node of nodes) {
      const id = node.data.properties.bimElementId;
      if (id) {
        ids.push(id.default);
      }
    }

    return { elementNodes: this.elementTableApi.getRowNodes(ids), getColumn: this.elementTableApi.getColumn };
  }

  @autobind
  private pivotModeReportTableChanged(): void {
    this.setState((s) => ({ isPivotModeReportTable: !s.isPivotModeReportTable }));
  }

  @autobind
  private saveReportTemplateTableApi(api: QtoReportTemplateTableApi): void {
    this.qtoReportTemplateTableApi = api;
  }

  @autobind
  private on3DLeftPanelReady(): void {
    this.setState({ is3DLeftPanelReady: true });
  }

  @autobind
  private getReportElementIds(): { nodeIds: Record<string, boolean>, duplicatedNodeIds: Record<string, boolean> } {
    if (!this.reportTableSelectApi) {
      return {
        nodeIds: {},
        duplicatedNodeIds: {},
      };
    }

    return this.reportTableSelectApi.getElementIds();
  }

  private render3DEngineContext(): React.ReactNode {
    const {
      isAutoFocusEngine,
      currentProjectId,
    } = this.props;
    return (
      <div
        className={classNames(
          'quantity-take-off-page__engine-panel-inner',
          { 'quantity-take-off-page__engine-panel-inner--toolbar-hide': this.state.isToolbarHide },
        )}
      >
        <Engine
          projectId={currentProjectId}
          textures='/static/textures/'
          sendEngineApi={this.saveEngineRef}
          toggleCameraParallel={this.toggleCameraParallel}
          saveContainerRef={this.saveEngineContainerRef}
          onToggleRuler={this.onEngineToggleRuler}
          onToggleClipBox={this.onEngineToggleClipBox}
          onChangeVisibility={this.onChangeVisibility}
          affterLoadAction={this.afterEngineLoadAction}
        />
        <KreoToolbar
          isToolbarHide={this.state.isToolbarHide}
          onHideToggle={this.onHideToolbarToggle}
          className='quantity-take-off-page__engine-toolbar'
        >
          <Controls3D
            ghostEnabled={this.state.isGhostEnabled}
            isometryEnabled={this.state.isIsometryEnabled}
            clipBoxEnabled={this.state.isClipBoxEnabled}
            rulerEnabled={this.state.isRulerEnabled}
            isAutoFocus={isAutoFocusEngine}
            isSelectedElement={!!this.state.selectedIds.length}
            isFullScreen={this.state.isFullScreen}
            savePropertiesPopupApi={this.savePropertiesPopupApi}
            engineLayoutContainer={this.state.engineContainerRef}
            color={this.state.color}
            onChangeColor={this.onChangeColor}
            onDefaultColor={this.onDefaultColor}
            onAssignColor={this.onAssignColor}
            onHome={this.onHome}
            onFocus={this.onFocus}
            onIsolate={this.onIsolate}
            onHide={this.onHide}
            onApplyClipboxToFilter={this.applyClipboxToFilter}
            isAnyInvisible={this.state.isAnyInvisible}
            onShowHideElements={this.onShowHideElements}
            toggleGhost={this.toggleGhost}
            toggleClipBox={this.toggleClipBox}
            toggleIsometry={this.toggleIsometry}
            toggleAutoFocus={this.toggleAutoFocus}
            toggleRuler={this.toggleRuler}
            toggleFullScreen={this.toggleFullScreen}
          />
        </KreoToolbar>
      </div>
    );
  }


  @autobind
  private onReportReady(): void {
    this.setState({ isReportReady: true });
  }

  @autobind
  private onDefaultColor(): void {
    const ids = this.engine.getSelected();
    this.engine.setColorTint(null, ids);
  }

  @autobind
  private onChangeVisibility(isVisible: boolean, ids: number[]): void {
    if (!this.state.isEnginReady) {
      return;
    }
    const stateChange: Partial<PageState> = {};
    if (this.state.isAnyInvisible !== this.engine.isAnyInvisible()) {
      const isAnyInvisible = this.engine.isAnyInvisible();
      stateChange.isAnyInvisible = isAnyInvisible;
    }
    if (this.needsUpdatedVisibleCollection) {
      stateChange.visibleIds = { ...this.state.visibleIds };
      let notAllInFiltered = false;
      for (const id of this.mapIdHelper.mapEngineIdToBimIdIterator(ids)) {
        stateChange.visibleIds[id] = isVisible;
        if (!this.filteredIdsMap[id]) {
          notAllInFiltered = true;
        }
      }
      this.setState(stateChange as PageState, () => {
        if (isVisible && notAllInFiltered) {
          this.showFilteredElements(this.filteredIds);
        }
        this.saveEngineFilterState();
      });
    }
  }

  @autobind
  private saveEngineFilterState(): void {
    this.deferredExecutor.execute(() => {
      const visibleIds = arrayUtils.uniq(
        arrayUtils.filterMap(
          Object.entries(this.state.visibleIds),
          ([, value]) => !value,
          ([k]) => this.mapIdHelper.mapBimIdToEngineId(k),
        ),
      );
      this.props.setEngineFilterState(this.props.currentProjectId, {
        visibleIds,
        clipBox: {
          isActive: this.state.isClipBoxEnabled,
          state: JSON.stringify(this.clipBoxState),
        },
      });
    });
  }

  @autobind
  private onIsolate(): void {
    const ids = this.engine.getSelected();
    this.engine.toggleVisibility(true, ids, false);
  }

  @autobind
  private onHide(): void {
    const ids = this.engine.getSelected();
    this.engine.toggleVisibility(false, ids);
  }

  @autobind
  private applyClipboxToFilter(event?: React.MouseEvent<HTMLDivElement>): void {
    if (event) {
      event.stopPropagation();
    }
    this.engine.calculateElementsIntersectingClipbox().then((visibleIds) => {
      const visibleIdsSet = new Set(visibleIds);
      const clipboxIds = [];
      for (const id in this.state.visibleIds) {
        if (visibleIdsSet.has(this.mapIdHelper.mapBimIdToEngineId(Number(id)))) {
          clipboxIds.push(Number(id));
        }
      }
      if (!this.state.clipboxVisible || !arrayUtils.areSetsEqual(clipboxIds, this.state.clipboxVisible)) {
        this.setState({ clipboxVisible: clipboxIds });
      }
      this.clipBoxState = this.engine.getClipboxStateView();
      this.saveEngineFilterState();
    });
  }

  @autobind
  private disableEngineFilter(): void {
    this.setState({ clipboxVisible: null });
    this.onShowHideElements();
  }

  @autobind
  private onShowHideElements(): void {
    this.engine.toggleVisibility(true);
  }

  @autobind
  private onAssignColor(): void {
    const ids = this.engine.getSelected();
    this.engine.setColorTint(this.state.color, ids);
  }

  @autobind
  private onChangeColor(color: string): void {
    this.setState({ color });
  }

  @autobind
  private onHideToolbarToggle(): void {
    this.setState((s) => ({ isToolbarHide: !s.isToolbarHide }));
  }

  @autobind
  private changeSyncStatus(source: QtoSelectElementsEventSource, syncStatus: SyncStatus): void {
    if (this.syncSelection) {
      this.syncSelection.setSyncStatus(source, syncStatus);
    }
  }

  @autobind
  private syncSelectedValue(isExpandParentInLeftPanel: boolean = true): void {
    const selectedBimElementsIds = this.state.selectedIds;
    const selectedEngineIds = this.mapIdHelper.mapBimIdsToEngineIds(selectedBimElementsIds);
    const outSyncKeys = this.syncSelection.getSourceForSync();
    outSyncKeys.forEach(k => {
      switch (QtoSelectElementsEventSource[k]) {
        case QtoSelectElementsEventSource.Engine: {
          this.engineSelect(selectedEngineIds);
          break;
        }
        case QtoSelectElementsEventSource.LeftPanel: {
          const prevSelect = this.elementTableApi.getSelectedValue();
          if (selectedBimElementsIds.length === 0) {
            if (!arrayUtils.areSetsEqual(selectedBimElementsIds, prevSelect)) {
              this.elementTableApi.setSelected(selectedBimElementsIds, isExpandParentInLeftPanel);
              break;
            }
          } else {
            const filteredIds = selectedBimElementsIds.filter(id => this.filteredIdsMap[id]);
            if (!arrayUtils.areSetsEqual(filteredIds, prevSelect)) {
              this.elementTableApi.setSelected(selectedBimElementsIds, isExpandParentInLeftPanel);
              break;
            }
          }
          this.syncSelection.setInSync(QtoSelectElementsEventSource.LeftPanel);
          break;
        }
        case QtoSelectElementsEventSource.ReportPanel: {
          if (this.props.loadReportStatus === RequestStatus.Loaded
            && this.reportTableSelectApi
            && !this.state.isPivotModeReportTable
          ) {
            this.reportTableSelectApi.setSelected(selectedBimElementsIds);
            break;
          }
          this.syncSelection.setInSync(QtoSelectElementsEventSource.ReportPanel);
          break;
        }
        default: throw new Error(`Unknown source type ${k}`);
      }
    });

    if (this.propertiesPopupApi) {
      this.propertiesPopupApi.showPropertiesForElements(selectedEngineIds);
    }

    if (this.props.isAddNewPropsInRecords) {
      this.props.updateStateAfterAddNewProps();
    }
  }

  private engineSelect(selectedEngineIds: number[]): void {
    const selectedFromEngine = this.engine.getSelected();
    if (!arrayUtils.areSetsEqual(selectedFromEngine, selectedEngineIds)) {
      this.engine.setSelected(selectedEngineIds);
    }
    this.syncSelection.setInSync(QtoSelectElementsEventSource.Engine);
  }

  @autobind
  private saveEngineContainerRef(ref: HTMLDivElement): void {
    this.setState({ engineContainerRef: ref });
  }

  @autobind
  private onLocationViewChange(ids: number[], status: boolean): void {
    this.needsUpdatedVisibleCollection = false;
    const engineIds = [];
    const filteredEngineIds = [];
    for (const id of ids) {
      const engineId = this.mapIdHelper.mapBimIdToEngineId(id);
      if (this.state.visibleIds[id]) {
        filteredEngineIds.push(engineId);
      }
      engineIds.push(engineId);
    }
    this.engine.toggleVisibilityLock(false, engineIds);
    this.engine.toggleVisibility(status, filteredEngineIds);
    this.needsUpdatedVisibleCollection = true;
    if (!status) {
      this.engine.toggleVisibilityLock(true, engineIds);
    }
  }

  @autobind
  private saveElementTable(ref: TableApi): void {
    this.elementTableApi = ref;
  }

  @autobind
  private saveReportTable(ref: TableApi): void {
    this.reportTableSelectApi = ref;
  }

  @autobind
  private onElementTableSelected(selectedBimElementsIds: Array<string | number>): void {
    const selectedIds = selectedBimElementsIds.map(Number);
    if (selectedBimElementsIds.length !== this.state.selectedIds.length) {
      this.syncSelection.startSync(
        QtoSelectElementsEventSource.LeftPanel,
        () => {
          this.setState(
            {
              selectedIds,
              bimIdsSelectedFromReport: selectedIds,
            },
            this.syncSelectedValue,
          );

          const selectedEngineIds = this.mapIdHelper.mapBimIdsToEngineIds(selectedIds);
          if (this.props.isAutoFocusEngine) {
            this.engine.focusCamera(selectedEngineIds);
          }
        },
      );
    }

    this.syncSelection.setInSync(QtoSelectElementsEventSource.LeftPanel);
  }

  @autobind
  private findInElements(ids: Array<string | number>): void {
    const bimElementIds = new Array<number>();
    for (const id of ids) {
      bimElementIds.push(id as number);
    }
    this.elementTableApi.focusAndExpand(bimElementIds);
  }

  @autobind
  private onReportTableSelectionChange(selectedIds: Array<string | number>): void {
    const bimElementIds = new Array<number>();
    for (const id of selectedIds) {
      bimElementIds.push(id as number);
    }

    this.syncSelection.startSync(
      QtoSelectElementsEventSource.ReportPanel,
      () => {
        this.setState(
          {
            selectedIds: bimElementIds,
            bimIdsSelectedFromReport: bimElementIds,
          },
          () => this.syncSelectedValue(false),
        );
      },
    );
    this.syncSelection.setInSync(QtoSelectElementsEventSource.ReportPanel);
  }

  @autobind
  private afterEngineLoadAction(): void {
    const ids = [];
    const visibleIds = {};
    const recordValuesMap = {};
    const checkDefaultVisibility = this.getDefaultVisibilityCheckMethod();
    for (const record of this.props.elementRecords) {
      const id = Number(record.id);
      visibleIds[id] = checkDefaultVisibility(id);
      if (
        !isLocation(record.props[QtoLeftPanelConstants.OBJECT_COMPLEX_KEY][0] as string)
        && visibleIds[id]
        && this.filteredIdsMap[id]
      ) {
        ids.push(id);
      }

      for (const [recordPropKey, values] of Object.entries(record.props)) {
        recordValuesMap[recordPropKey] = recordValuesMap[recordPropKey] || {};
        values.forEach(value => recordValuesMap[recordPropKey][value] = undefined);
      }
    }

    const engineIds = this.mapIdHelper.mapBimIdsToEngineIds(ids);
    this.setState({ isEnginReady: true, visibleIds, recordValuesMap }, () => {
      this.needsUpdatedVisibleCollection = false;
      this.engine.toggleVisibilityLock(false, engineIds);
      this.engine.toggleVisibility(true, engineIds, false);
      this.needsUpdatedVisibleCollection = true;
      if (this.clipBoxState) {
        this.engine.setClipboxFromStateView(this.clipBoxState);
        this.applyClipboxToFilter();
      }
    });
  }


  private getDefaultVisibilityCheckMethod(): (id: number) => boolean {
    return this.state.visibleIds && Object.keys(this.state.visibleIds).length
      ? (id: number) => id in this.state.visibleIds ? this.state.visibleIds[id] : true
      : () => true;
  }

  private proccessEngineFilterState(): void {
    const engineFilterData = this.props.engineFilterState;
    if (!engineFilterData) {
      return;
    }
    if (engineFilterData.clipBox.isActive && engineFilterData.clipBox.state) {
      this.clipBoxState = JSON.parse(engineFilterData.clipBox.state);
    }
    if (this.state.isEnginReady) {
      const engineDataVisibleIdsSet = new Set(this.mapIdHelper.mapEngineIdsToBimIds(engineFilterData.visibleIds));
      this.needsUpdatedVisibleCollection = false;
      this.engine.toggleVisibility(false, engineFilterData.visibleIds, true);
      this.needsUpdatedVisibleCollection = true;
      const visibleIds = { ...this.state.visibleIds };
      for (const id in visibleIds) {
        visibleIds[id] = !engineDataVisibleIdsSet.has(Number(id));
      }
      this.setState({ visibleIds, isClipBoxEnabled: engineFilterData.clipBox.isActive });
      this.engine.toggleClipbox(engineFilterData.clipBox.isActive, this.state.isGhostEnabled ? 'ghost' : 'none');
      if (this.clipBoxState) {
        this.engine.setClipboxFromStateView(this.clipBoxState);
        this.applyClipboxToFilter();
      }
    } else {
      const visibleIds = { ...this.state.visibleIds };
      for (const id of this.mapIdHelper.mapEngineIdToBimIdIterator(engineFilterData.visibleIds)) {
        visibleIds[id] = false;
      }
      this.setState({ visibleIds, isClipBoxEnabled: engineFilterData.clipBox.isActive });
    }
  }

  @autobind
  private onFocus(): void {
    const selectedEngineIds = this.mapIdHelper.mapBimIdsToEngineIds(this.state.selectedIds);
    this.engine.focusCamera(selectedEngineIds);
  }

  @autobind
  private toggleAutoFocus(): void {
    this.props.toggleAutoFocusEngine();
  }

  @autobind
  private onHome(): void {
    this.engine.cameraToHome();
    this.props.collapseAll();
  }

  @autobind
  private toggleGhost(): void {
    if (this.engine) {
      const { isGhostEnabled, isClipBoxEnabled } = this.state;
      if (isClipBoxEnabled) {
        this.engine.toggleClipbox(true, !isGhostEnabled ? 'ghost' : 'none');
      }
      this.engine.showInvisibleAsGhost(!isGhostEnabled);
      this.setState({ isGhostEnabled: !isGhostEnabled });
    }
  }

  @autobind
  private onEngineToggleClipBox(isClipBoxEnabled: boolean): void {
    this.changeClipboxEnable(isClipBoxEnabled);
  }

  @autobind
  private toggleClipBox(): void {
    if (this.engine) {
      const { isGhostEnabled, isClipBoxEnabled } = this.state;
      this.engine.toggleClipbox(!isClipBoxEnabled, isGhostEnabled ? 'ghost' : 'none');
      this.changeClipboxEnable(!isClipBoxEnabled);
    }
  }

  private changeClipboxEnable(isClipBoxEnabled: boolean): void {
    const clipboxVisible = this.state.clipboxVisible;
    this.setState({ isClipBoxEnabled, clipboxVisible: isClipBoxEnabled ? clipboxVisible : null });
    if (!isClipBoxEnabled) {
      this.clipBoxState = this.engine.getClipboxStateView();
      this.saveEngineFilterState();
    }
  }

  @autobind
  private onEngineToggleRuler(isRulerEnabled: boolean): void {
    this.setState({ isRulerEnabled });
  }

  @autobind
  private toggleRuler(): void {
    if (this.engine) {
      const { isRulerEnabled } = this.state;
      this.engine.toggleRuler(!isRulerEnabled);
    }
  }

  private setEngineUnits(isImperialUnit: boolean): void {
    if (this.engine) {
      this.engine.setUiUnits(
        isImperialUnit
          ? { length: Unit.FootInch, area: Unit.FootPow2 }
          : { length: Unit.Meter, area: Unit.MeterPow2 },
      );
    }
  }

  @autobind
  private toggleUnit(): void {
    if (this.engine) {
      const { isImperialUnit } = this.state;
      this.setEngineUnits(!isImperialUnit);
      this.setState({ isImperialUnit: !isImperialUnit });
    }
  }

  @autobind
  private toggleIsometry(): void {
    if (this.engine) {
      this.engine.toggleParallelProjection(!this.state.isIsometryEnabled);
    }
  }

  @autobind
  private toggleCameraParallel(isIsometryEnabled: boolean): void {
    this.setState({ isIsometryEnabled });
  }

  @autobind
  private toggleFullScreen(): void {
    this.setState((s) => ({ isFullScreen: !s.isFullScreen }));
  }

  @autobind
  private savePropertiesPopupApi(api: PropertiesPopupApi): void {
    this.propertiesPopupApi = api;
  }

  @autobind
  private onFilter(ids: number[]): void {
    this.props.selectBimElementsFromEngine([]);
    const bimIds = this.mapIdHelper.mapEngineIdsToBimIds(ids);
    this.props.filterTree(bimIds);
  }

  @autobind
  private showFilteredElements(ids: number[]): void {
    if (!Object.keys(this.state.visibleIds).length) {
      return;
    }
    const selectedEngineIds = this.mapIdHelper.mapBimIdsToEngineIds(ids.filter(x => this.state.visibleIds[x]));
    this.needsUpdatedVisibleCollection = false;
    this.engine.toggleVisibilityLock(false, selectedEngineIds);
    this.engine.toggleVisibility(true, selectedEngineIds, false);
    this.needsUpdatedVisibleCollection = true;
  }

  private fetchModelIfNeeded(): void {
    const {
      modelRequestStatus,
      loadedProjectId,
      currentProjectId,
      uploadQtoPageData,
    } = this.props;

    if (
      (loadedProjectId !== currentProjectId) &&
      modelRequestStatus !== RequestStatus.Loading &&
      modelRequestStatus !== RequestStatus.Failed
    ) {
      uploadQtoPageData(currentProjectId);
    }
  }

  @autobind
  private saveEngineRef(engine: KreoEngine): void {
    this.engine = engine;
    this.engine.showInvisibleAsGhost(this.state.isGhostEnabled);
    this.engine.toggleMultiselect(false);
    this.engineEventSubscriptions.push(this.engine.addListener('selection', this.onEngineSelectionChange));
    this.setEngineUnits(this.state.isImperialUnit);
  }

  @autobind
  private onEngineSelectionChange(ids: number[]): void {
    this.syncSelection.startSync(
      QtoSelectElementsEventSource.Engine,
      () => {
        const bimIds = this.mapIdHelper.mapEngineIdsToBimIds(ids);
        this.setState(
          {
            selectedIds: bimIds,
            bimIdsSelectedFromReport: bimIds,
          },
          this.syncSelectedValue,
        );
      });

    this.syncSelection.setInSync(QtoSelectElementsEventSource.Engine);
  }
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const projectId = ownProps.projectId;
  return {
    tree: state.quantityTakeOff.tree,
    modelRequestStatus: state.quantityTakeOff.getModelRequestStatus,
    loadedProjectId: state.quantityTakeOff.loadedProjectId,
    currentProjectId: projectId,
    extractorsLookup: state.quantityTakeOff.model ? state.quantityTakeOff.model.extractors : null,
    selectedBimElementsIds: state.quantityTakeOff.selectedBimElementsIds,
    selectElementsEventSource: state.quantityTakeOff.selectElementsEventSource,
    model: state.quantityTakeOff.model,
    isAutoFocusEngine: state.persistedStorage.isAutoFocusEngine,
    elementRecords: state.quantityTakeOff.elementRecords,
    expandTable: state.quantityTakeOff.expandTable,
    filteredIds: state.quantityTakeOff.filteredIds,
    recordsConfig: state.quantityTakeOff.recordsConfig,
    loadReportStatus: state.quantityTakeOff.report.statuses.loadReport,
    isAddNewPropsInRecords: state.quantityTakeOff.isAddNewPropsInRecords,
    isImperialUnit: state.account.settings.isImperial,
    disableShowDialogList: state.persistedStorage.disableShowDialogList,
    engineFilterState: state.projects.projectsEngineState[projectId]
      ? state.projects.projectsEngineState[projectId][EngineBasedPages.Qto]
      : null,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, props: OwnProps): DispatchProps {
  const { modelType } = props;

  return {
    getModel: projectId => dispatch(QuantityTakeOffActions.getModel(projectId, modelType)),
    highlightTreeNode: bimId => dispatch(QuantityTakeOffActions.highlightTreeNode(bimId)),
    selectBimElementsFromEngine:
      bimIds => dispatch(QuantityTakeOffActions.selectBimElements(bimIds, QtoSelectElementsEventSource.Engine)),
    collapseAll: () => dispatch(QuantityTakeOffActions.changeExpandStatus(false)),
    userExpand: () => dispatch(QuantityTakeOffActions.changeExpandStatus()),
    dropState: () => dispatch(QuantityTakeOffActions.dropState()),
    uploadQtoPageData: projectId => dispatch(QuantityTakeOffActions.uploadQtoPageData(projectId, modelType)),
    getLeftPanelRecords: projectId => dispatch(QuantityTakeOffActions.getLeftPanelRecords(projectId, modelType, true)),
    filterTree: bimIds => dispatch(QuantityTakeOffActions.filterTree(bimIds)),
    toggleAutoFocusEngine: () => dispatch(PersistedStorageActions.toggleEngineAutoFocus()),
    getRecordsConfig: propjetId => dispatch(QuantityTakeOffActions.loadRecordsConfig(propjetId)),
    updateStateAfterAddNewProps: () => dispatch(QuantityTakeOffActions.updateStateAfterAddNewProps()),
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
    setEngineFilterState: (projectId, state) =>
      dispatch(ProjectsActions.setEngineFilterState(projectId, state, EngineBasedPages.Qto)),
    getEngineFilterState: () => dispatch(ProjectsActions.getEngineFilterState(EngineBasedPages.Qto)),
    callFilter: () => dispatch(QuantityTakeOffFilterActions.callFilter()),
  };
}

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export const QuantityTakeOff = withAbilityContext(reduxConnector(QuantityTakeOffComponent));


interface PageProps extends RouteComponentProps<PlanProjectRouteParams | Qto3dProjectRouteParams> {
}

export const QuantityTakeOffPage = (props: PageProps): JSX.Element => {
  const projectId = parseInt(props.match.params.projectId, 10);

  return (
    <QuantityTakeOff
      projectId={projectId}
      modelType={ModelType.QuantityTakeOff}
    />
  );
};
