import { CodecUtils } from 'common/utils/codec-utils';

export enum ProjectAccessReason {
  No = 'No',
  Owner = 'Owner',
  Shared = 'Shared',
  Admin = 'Admin',
  CompanyShared = 'CompanyShared',
}

export const ProjectAccessReasonC =
  CodecUtils.createEnumCodec<ProjectAccessReason>(ProjectAccessReason, 'ProjectAccessReason');
