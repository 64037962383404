import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './styles.scss';

import { KreoIconCancel } from 'common/UIKit';
import { ShortUserWithRole } from '../../interfaces/short-user';

interface Props {
  user: ShortUserWithRole;
  className?: string;
  onClick: (user: ShortUserWithRole) => void;
  onDetachClick?: (user: ShortUserWithRole) => void;
  companyEmail: string;
}

export class ShortUserItem extends React.Component<Props> {
  public render(): React.ReactNode {
    const { user } = this.props;
    return (
      <div
        className={classNames('short-item', this.props.className)}
        onClick={this.onClick}
      >
        <div className='admin-item__employee-role'>{user.roleName ? user.roleName : 'No role'}</div>
        <div className='short-item__head-content'>
          <span>{`${user.firstName} ${user.lastName}`}</span>
          {
            this.props.companyEmail !== user.email &&
            <span
              className='short-item__action'
              onClick={this.onDetachClick}
            >
              <KreoIconCancel />
            </span>
          }
        </div>
      </div>
    );
  }

  @autobind
  private onClick(): void {
    if (this.props.onClick) {
      this.props.onClick(this.props.user);
    }
  }

  @autobind
  private onDetachClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();

    if (this.props.onDetachClick) {
      this.props.onDetachClick(this.props.user);
    }
  }
}
