import { UsersListStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';


const Container = styled.div<{ isTranslucent: boolean, showList: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  opacity: ${p => p.isTranslucent ? 0.5 : 1};

  ${UsersListStyled.Container} {
    display: ${p => p.showList ? 'flex' : 'none'};
  }
`;

export const Styled = {
  Container,
};
