import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TwoDActions } from '2d/actions/creators';
import { useStateVariables } from './use-state-variables';

export const useLoadConfigEffect = (reportId: string): void => {
  const { config } = useStateVariables(reportId);
  const dispatch = useDispatch();
  const loadViewConfig = useCallback(() => {
    dispatch(TwoDActions.loadViewConfig(reportId));
  }, [dispatch, reportId]);
  useEffect(() => {
    if (!config) {
      loadViewConfig();
    }
  }, [reportId, loadViewConfig]);
};
