import { useEffect, useMemo } from 'react';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { ContextObserver, DrawingContextObserver } from '../../drawings-contexts';

export function useOneClickAreaHoverObserver(): ContextObserver<boolean> {
  const canUseHover = useAbility(Operation.Manage, Subject.Takeoff2dOneClickAreaHover);
  const observer = useMemo(() => new DrawingContextObserver(canUseHover), []);

  useEffect(() => {
    observer.updateContext(canUseHover);
  }, [observer, canUseHover]);

  return observer;
}
