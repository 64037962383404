import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { DrawingsActions } from '../../../actions/creators/common';
import { DrawingsFailedMessageContainer } from '../failed-message-container';

interface StateProps {
  currentDrawingId: string;
}

interface DispatchProps {
  optimize: (drawingId: string) => void;
}

interface Props  extends StateProps, DispatchProps{

}

export const FailedRenderMessageComponent: React.FC<Props> = ({ currentDrawingId, optimize }) => {
  const onTryRestartClick = useCallback(() => {
    optimize(currentDrawingId);
  }, [currentDrawingId]);
  return (
    <DrawingsFailedMessageContainer
      buttonText='Optimize'
      onButtonClick={onTryRestartClick}
      buttonMood='positive'
    >
      <span>
        Oops. For some reason we can't display the file
      </span>
    </DrawingsFailedMessageContainer>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    currentDrawingId: state.drawings.currentDrawingInfo?.drawingId,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    optimize: (drawingId) => dispatch(DrawingsActions.toggleDrawingOptimize(drawingId)),
  };
}

export const FailedRenderMessage = connect(mapStateToProps, mapDispatchToProps)(FailedRenderMessageComponent);
