export default function rafDebounce(fn: any): any {
  let rafScheduled = false;

  const fnWrapper = () => {
    fn();
    rafScheduled = false;
  };

  return {
    request() {
      if (!rafScheduled) {
        requestAnimationFrame(fnWrapper);
        rafScheduled = true;
      }
    }
  };
}