import { BaseFormat, NumericFormat, PropertyFormatEnum } from 'unit-2d-database/interfaces';

function numeric(unit: string): NumericFormat {
  return {
    type: PropertyFormatEnum.Number,
    unit,
  };
}

function text(): BaseFormat {
  return {
    type: PropertyFormatEnum.Text,
  };
}

export const FormatCreators = {
  numeric,
  text,
};
