import { Constants, Icons, Utils } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';

import { getSortingMeasureTypes } from '2d/units/get-sorting-measure-types';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import { PuponContextMenu } from 'common/components/formula-toolbar/pupon-context-menu';
import { ColorList } from 'common/constants/color-list';
import { ConstantFunctions } from 'common/constants/functions';
import { KreoColors } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { mathUtils } from 'common/utils/math-utils';
import { MeasureUtil } from 'common/utils/measure-util';
import { TWODUnitConversionMap, UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { FocusedCell, ReportPage } from '../../../units/2d';
import { TwoDRegex, TwoDRegexGetter, TwoDRegexTypings } from '../../../units/2d/units/2d-regex';
import { DrawingsGeometryGroup, DrawingsGeometryInstance, DrawingsGeometryUtils } from '../drawings';
import { MoveToCellOptionData, MoveToCellOptionType } from '../drawings/drawings-canvas-menus';
import { getFolderToCellOptions } from '../drawings/drawings-canvas/get-folder-to-cell-options';
import { DrawingsInstanceType } from '../drawings/enums/drawings-instance-type';
import { DrawingsGeometryType, DrawingsInstanceMeasure, DrawingsShortInfo } from '../drawings/interfaces';
import { ExcelFormulaHelper, MAX_COLUMNS_COUNT, MAX_ROWS_COUNT } from '../excel-table';
import { ExcelTableRowIdentification } from '../excel-table/excel-table-row-identificator';
import { AbsoluteLinkUtils } from '../excel-table/utils/absolute-link-utils';
import { MultilevelSelectOptionDataWithObjectValue } from '../multi-level-drop-down-menu';
import { CaretPositionManager } from './caret-position-manager';
import { SelectMeasurementsButton } from './select-measurements';
import { Styled } from './styled';

const ColorUtils = Utils.ColorUtils;

const nonBrakingSymbol = '\u200b';
const globalNonBrakingSymbol = new RegExp(nonBrakingSymbol, 'g');
const whiteSpace = new RegExp('&nbsp;', 'g');
export const INVALID_SHEET_NAME = `'INVALID Sheet REF'!`;

export enum FormulaBarElementType {
  DrawingElement,
  Cell,
}

export type GetMaxCellIdentificationSizes = (sheetId: string) => { maxColumn: number, maxRow: number };

interface DrawingData {
  drawingInstanceId: string;
  color: string;
  measures: Record<MoveToCellOptionType, string>;
  name: string;
  currentType: MoveToCellOptionType;
  elementIndex: number;
  isGroup?: boolean;
}

interface CellData {
  cellId: string;
}

export interface PuponContextData {
  x: number;
  y: number;
  puponIndex: number;
  drawingData?: DrawingData;
  cellData?: CellData;
}

interface ComponentState {
  isFocused: boolean;
  puponContextData: PuponContextData;
  tempCursorIndex: number;
}

interface StateProps {
  aiAnnotation: DrawingsGeometryState;
  drawingsGroups: DrawingsGeometryGroup[];
  elementMeasurement: Record<string, DrawingsInstanceMeasure>;
  drawings: Record<string, DrawingsShortInfo>;
}

interface Props extends StateProps, AbilityAwareProps {
  onInput?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  updateData?: (formulaValue: string, continueEditing: boolean) => void;
  onSelectClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onPuponClick?: (value: string, puponType: FormulaBarElementType) => void;
  onMouseEnterPupon?: (value: string, puponType: FormulaBarElementType) => void;
  onMouseLeavePupon?: (value: string, puponType: FormulaBarElementType) => void;
  getColor: (id: string, type: FormulaBarElementType) => string;
  getValue: (id: string, type: FormulaBarElementType) => string;
  isSelect?: boolean;
  value?: string;
  isFocused: boolean;
  isImperial: boolean;
  saveApi: (api: FormulaToolbarApi) => void;
  onEscapeDown: () => void;
  focusedCell: FocusedCell;
  canEditReport: boolean;
  canViewReport: boolean;
  changeEntitiesColor: (ids: string[], color: string, isGroup: boolean) => void;
  reportPages: ReportPage[];
  getMaxCellIdentificationSizes: GetMaxCellIdentificationSizes;
  getInstancesMeasures: (
    instancesIds: Array<string | string[]>,
    drawings: Record<string, DrawingsShortInfo>,
    aiAnnotation: DrawingsGeometryState,
    elementMeasurement: Record<string, DrawingsInstanceMeasure>,
  ) => DrawingsInstanceMeasure[];
}

interface ComponentState {
  isFocused: boolean;
  withExpand: boolean;
  isExpandedBar: boolean;
  spanRect: ClientRect;
  scrollContainerRect: ClientRect;
}

export interface FormulaToolbarApi {
  insertCellValue: (value: string) => void;
  insertFunctionValue: (value: string) => void;
  setFocus: () => void;
  refresh: (value?: string) => void;
}

const DrawingInstanceIdClass = 'drawing-instance-id';
const DrawingInstanceValueClass = 'drawing-instance-value';
const DrawingInstanceContainerClass = 'drawing-instance-container';
const CellIdWrapClass = 'cell-id-wrap';
const WrappedCellValueClass = 'wrapped-cell';

class FormulaToolbarComponent extends React.PureComponent<Props, ComponentState> {
  private spanRef: React.RefObject<HTMLSpanElement> = React.createRef();
  private scrollContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private caretPositionManager: CaretPositionManager;
  private measureShowValueGetter: Record<string, (drawingId: string) => string> = {
    [MoveToCellOptionType.pointsCount]: this.getPointsCountShowValue,
    [MoveToCellOptionType.segmentsCount]: this.getSegmentsCount,
    [MoveToCellOptionType.area]: this.getAreaShowValue,
    [MoveToCellOptionType.perimeter]: this.getPerimetrShowValue,
    [MoveToCellOptionType.height]: this.getHeightShowValue,
    [MoveToCellOptionType.width]: this.getWidthShowValue,
    [MoveToCellOptionType.length]: this.getLengthShowValue,
    [MoveToCellOptionType.count]: this.getCountShowValue,
    [MoveToCellOptionType.volume]: this.getVolumeShowValue,
    [MoveToCellOptionType.thickness]: this.getThicknessShowValue,
    [MoveToCellOptionType.verticalArea]: this.getVerticalAreaShowValue,
  };

  public constructor(props: Props) {
    super(props);

    this.state = {
      isFocused: false,
      withExpand: false,
      isExpandedBar: false,
      spanRect: null,
      scrollContainerRect: null,
      puponContextData: null,
      tempCursorIndex: null,
    };
  }

  public componentDidMount(): void {
    const value = this.props.value !== undefined ? this.props.value : '';
    this.updateBarElement(value);
    this.props.saveApi({
      insertCellValue: this.insertValue,
      insertFunctionValue: this.insertFunctionValue,
      setFocus: this.setFocus,
      refresh: this.refreshHandler,
    });
    this.caretPositionManager = new CaretPositionManager(this.spanRef.current);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (!this.state.isFocused) {
      this.updateBarElement(this.props.value);
    } else if (
      prevProps.drawingsGroups !== this.props.drawingsGroups
      || prevProps.aiAnnotation.geometry !== this.props.aiAnnotation.geometry
    ) {
      this.updateBarElement(this.props.value);
      this.setFocus();
    }
    if (prevProps.value !== this.props.value) {
      this.updateParametersForExpand();
    }
    if (prevProps.focusedCell.cell.cellId !== this.props.focusedCell.cell.cellId) {
      this.setState({ isExpandedBar: false });
    }
  }

  public render(): JSX.Element {
    return (
      <Styled.Container
        isFocused={this.state.isFocused}
        reportViewPermission={this.props.canViewReport}
      >
        <Styled.ToolbarIcon>
          <Icons.Formula />
        </Styled.ToolbarIcon>
        <Styled.ScrollContainer
          ref={this.scrollContainerRef}
          onMouseDown={this.onMouseDown}
        >
          <Styled.InputGroup
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onInput={this.onInput}
            contentEditable={this.props.canEditReport}
            ref={this.spanRef}
            onKeyDown={this.onKeyDown}
            isFocused={this.state.isFocused}
            placeholder={'Enter formula or select measurements'}
            value={this.props.value}
            isExpandedBar={this.state.isExpandedBar}
            withExpand={this.state.withExpand}
            scrollContainerRect={this.state.scrollContainerRect}
            reportViewPermission={this.props.canViewReport}
            reportEditPermission={this.props.canEditReport}
            minWidth={this.spanRef.current?.parentElement.getBoundingClientRect().width}
          />
          {this.state.withExpand && (
            <Styled.ExpandButton
              onClick={this.toggleExpandedBar}
              isExpandedBar={this.state.isExpandedBar}
              rectForPosition={this.state.isExpandedBar ? this.state.spanRect : this.state.scrollContainerRect}
            >
              <Icons.DownSmall />
            </Styled.ExpandButton>
          )}
        </Styled.ScrollContainer>
        <SelectMeasurementsButton
          onSelectClick={this.props.onSelectClick}
          isSelect={this.props.isSelect}
          canEditReport={this.props.canEditReport}
          canViewReport={this.props.canViewReport}
        />
        {this.state.puponContextData &&
          <PuponContextMenu
            replaceType={this.replaceDrawingParameterType}
            puponContextData={this.state.puponContextData}
            closePuponContext={this.closePuponContext}
            onColorChange={this.onColorChange}
            onClickLinkType={this.onClickLinkType}
          />
        }
        <ReactResizeDetector
          handleHeight={false}
          handleWidth={true}
          onResize={this.updateParametersForExpand}
        />
      </Styled.Container>
    );
  }

  @autobind
  private onClickLinkType(childIndex: number, newCellLink: string): void {
    const cellId = TwoDRegexGetter.getFullCellField(newCellLink);
    const cellLink = cellId.sheetId
      ? ExcelFormulaHelper.replaceSheetIdToSheetName(cellId.fullCellId, this.props.reportPages)
      : newCellLink;
    this.replaceOnIndex(childIndex, cellLink);
    this.props.updateData(this.getCurrentSpanValue(), true);
    this.props.onMouseLeavePupon(newCellLink, FormulaBarElementType.Cell);
  }

  @autobind
  private iterateLinkType(): void {
    const { element, childIndex: index } = this.getClosedCellChild();
    if (!element) {
      return;
    }

    const valueElement = element.getElementsByClassName(WrappedCellValueClass)[0];
    const cellId = valueElement.innerHTML;
    const cellLinkType = AbsoluteLinkUtils.getCellLinkRefType(cellId);
    const nextCellLinkType = AbsoluteLinkUtils.getNextCellLinkType(cellLinkType);
    const newValue = AbsoluteLinkUtils.getNewCellRef(cellId, nextCellLinkType);
    this.replaceOnIndex(index, newValue);
  }

  private getClosedCellChild(): { element: Element, childIndex: number } {
    const { caretIndexInChildren, children, offset } = this.caretPositionManager.getCurrentCaretState();
    const currentElement = children[caretIndexInChildren];
    if (this.isCell(currentElement)) {
      return { element: currentElement, childIndex: caretIndexInChildren };
    }
    const isPrevElementCheck = offset === 0;
    const elementIndex = isPrevElementCheck ? caretIndexInChildren - 1 : caretIndexInChildren + 1;
    const element = children[elementIndex];

    if (this.isCell(element)) {
      return { element, childIndex: elementIndex };
    }

    return { element: null, childIndex: caretIndexInChildren };
  }

  private isCell(element: HTMLElement): boolean {
    return element && element.classList.contains(CellIdWrapClass);
  }

  @autobind
  private updateParametersForExpand(): void {
    const withExpand = this.state.isExpandedBar ||
      this.spanRef.current.offsetWidth > this.spanRef.current.parentElement.offsetWidth;
    const spanRect = this.spanRef.current.getBoundingClientRect();
    const scrollContainerRect = this.scrollContainerRef.current.getBoundingClientRect();
    this.setState({ withExpand, spanRect, scrollContainerRect });
  }

  @autobind
  private toggleExpandedBar(): void {
    this.setState({ isExpandedBar: !this.state.isExpandedBar }, () => this.updateParametersForExpand());
    if (!this.state.isExpandedBar) {
      this.setFocus();
    }
  }

  @autobind
  private onMouseDown(e: React.MouseEvent): void {
    if (e.button === 2 || this.state.puponContextData) {
      ConstantFunctions.stopEvent(e);
      return;
    }
    if (!this.state.isFocused) {
      this.spanRef.current.focus();
    }
  }

  @autobind
  private insertFunctionValue(formulaValue: string): void {
    const span = this.spanRef.current;
    const valueToInsert = ExcelFormulaHelper.getValueToInsert(this.getCurrentSpanValue(), formulaValue);
    this.updateBarElement(valueToInsert);
    this.caretPositionManager.setCaretOnChildIndex(span.childNodes.length - 1);
  }

  @autobind
  private setFocus(): void {
    const span = this.spanRef.current;
    const children = this.spanRef.current.children;
    span.focus();
    const index = this.isPupon(children[children.length - 1] as HTMLElement)
      ? span.childNodes.length - 1
      : span.childNodes.length;
    this.caretPositionManager.setCaretOnChildIndex(index);
  }

  @autobind
  private insertValue(insertValue: string): void {
    const span = this.spanRef.current;
    const { caretIndexInChildren: oldIndex } = this.caretPositionManager.getCurrentCaretState();
    const oldLength = span.childNodes.length;
    const newValue = this.getValueToInsert(oldIndex, insertValue);
    this.updateBarElement(newValue);
    const newLength = span.childNodes.length;
    const indexOffset = newLength - oldLength;
    this.caretPositionManager.setCaretOnChildIndex(oldIndex + indexOffset);
    this.props.onInput(newValue);
  }

  private getValueToInsert(oldIndex: number, insertValue: string): string {
    const prevValue = this.getCurrentSpanValue();
    const sliceIndex = this.getSliceToIndex(oldIndex);
    const sliceValue = prevValue.slice(0, sliceIndex);
    const valueToInsert = ExcelFormulaHelper.getValueToInsert(sliceValue, insertValue);
    const newValue = this.getCurrentSpanValue().replace(sliceValue, valueToInsert);
    return newValue;
  }

  private replaceOnIndex(index: number, insertValue: string): void {
    const { caretIndexInChildren, offset } = this.state.isFocused && this.caretPositionManager.getCurrentCaretState();
    const prevValue = this.getCurrentSpanValue();
    const slicedValueBefore = prevValue.slice(0, this.getSliceToIndex(index));
    const slicedValueAfter = prevValue.slice(this.getSliceToIndex(index + 1));
    const newValue = slicedValueBefore + insertValue + slicedValueAfter;
    this.updateBarElement(newValue);
    this.props.onInput(newValue);
    if (this.state.isFocused) {
      this.caretPositionManager.setCaretOnChildIndex(caretIndexInChildren + offset);
    }
  }

  private getCurrentSpanValue(): string {
    const result = [];
    const span = this.spanRef.current;
    const children = span.childNodes as any;
    children.forEach((c: HTMLElement) => {
      if (c.className === DrawingInstanceContainerClass) {
        const instance = c.getElementsByClassName(DrawingInstanceIdClass)[0];
        result.push(instance.textContent);
      } else {
        result.push(c.textContent);
      }
    });
    return result.join('').replace(globalNonBrakingSymbol, '');
  }

  @autobind
  private updateBarElement(value: string): void {
    const elements = this.getElements(value);
    this.spanRef.current.innerHTML = '';
    elements.forEach(element => this.spanRef.current.appendChild(element));

    if (!elements.length || this.isPupon(elements[elements.length - 1])) {
      const brDelimiter = document.createElement('br');
      if (elements.length) {
        const spanRect = document.createElement('span');
        spanRect.appendChild(brDelimiter);
        this.spanRef.current.appendChild(spanRect);
      } else {
        this.spanRef.current.appendChild(brDelimiter);
      }
    }
  }

  private getElements(value: string): HTMLElement[] {
    const elements = this.getColoredValue(value.toString().replace(whiteSpace, ' '));
    let isPrevPupon = false;
    elements.forEach((e, i) => {
      if (isPrevPupon === true && this.isPupon(e)) {
        elements.splice(i, 0, this.getWhiteSpaceElement());
        isPrevPupon = false;
      } else {
        isPrevPupon = this.isPupon(e);
      }
    });
    return elements;
  }

  @autobind
  private refreshHandler(value: string): void {
    this.updateBarElement(value);
    if (this.props.isFocused && typeof this.state.tempCursorIndex === 'number') {
      this.caretPositionManager.setCaretOnChildIndex(this.state.tempCursorIndex);
      this.setState({ tempCursorIndex: null });
    }
  }

  @autobind
  private onInput(): void {
    const {
      caretIndexInChildren: prevIndex,
      children: prevChildren,
      offset,
    } = this.caretPositionManager.getCurrentCaretState();
    this.updateBarElement(this.getCurrentSpanValue());
    this.caretPositionManager.updateCaretPosition(prevChildren, prevIndex, offset);
    if (this.props.onInput) {
      this.props.onInput(this.getCurrentSpanValue());
    }
  }

  private getSliceToIndex(focusIndex: number): number {
    const span = this.spanRef.current;
    let result = '';
    for (let i = 0; i < focusIndex; i++) {
      const node = span.childNodes[i] as any;
      const innerText = node.innerText.replace(new RegExp('\\r?\\n', 'g'), '').replace(globalNonBrakingSymbol, '');
      const textContent = node.textContent.replace(globalNonBrakingSymbol, '');
      result += textContent !== innerText ? textContent.replace(innerText, '') : textContent;
    }
    return result.length;
  }

  @autobind
  private onFocus(): void {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
    this.setState({ isFocused: true });
  }

  @autobind
  private onBlur(): void {
    if (!this.state.puponContextData) {
      if (this.props.onBlur) {
        this.props.onBlur();
      }
      this.setState({ isFocused: false, isExpandedBar: false });
    }
  }

  @autobind
  private onKeyDown(e: React.KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.spanRef.current.blur();
      this.props.updateData(this.getCurrentSpanValue(), false);
    }

    if (e.key === 'Escape') {
      this.spanRef.current.blur();
      this.props.onEscapeDown();
      this.setState({ isExpandedBar: false });
    }

    if (e.key === 'F4') {
      this.iterateLinkType();
    }
  }

  private getSeparator(): HTMLSpanElement {
    const nonBrakingContainer = document.createElement('span');
    nonBrakingContainer.contentEditable = 'false';
    const nonBrakingTextNode = document.createTextNode(nonBrakingSymbol);
    nonBrakingContainer.appendChild(nonBrakingTextNode);
    return nonBrakingContainer;
  }

  @autobind
  private onClickPupon(e: MouseEvent): void {
    if (!this.state.isFocused) {
      return;
    }
    const span = this.spanRef.current;
    const element = e.currentTarget;
    const indexOf = Array.from(span.childNodes).findIndex(el => el === element) + 1;

    this.caretPositionManager.setCaretOnChildIndex(indexOf);
  }

  @autobind
  private onMouseDownPupon(e: MouseEvent): void {
    if (!this.state.isFocused) {
      ConstantFunctions.stopEvent(e);
    }
  }

  private isPupon(element: HTMLElement): boolean {
    return element.contentEditable === 'false';
  }

  private getWhiteSpaceElement(): HTMLElement {
    const whiteSpaceSpan = document.createElement('span');
    const whiteSpaceText = document.createTextNode(' ');
    whiteSpaceSpan.appendChild(whiteSpaceText);
    return whiteSpaceSpan;
  }

  private wrapCellId(sheetCellId: string): HTMLElement[] {
    const cellSpanContainer = document.createElement('span');
    cellSpanContainer.contentEditable = 'false';
    const { sheetId, columnId, rowId } = TwoDRegexGetter.getFullCellField(sheetCellId);
    const sheetCellIdWithName = ExcelFormulaHelper.replaceSheetIdToSheetName(sheetCellId, this.props.reportPages);
    const sheetCellIdWithNameGroups = sheetCellIdWithName.match(TwoDRegex.fullCellId).groups;
    let beautyName = !sheetCellIdWithNameGroups.sheetId
      ? sheetCellIdWithName
      : `${INVALID_SHEET_NAME}${columnId}${rowId}`;
    const columnIndex = ExcelColumnHelper.excelColNameToIndex(columnId);
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const { maxColumn, maxRow } = this.props.getMaxCellIdentificationSizes(sheetId);
    if (columnIndex > MAX_COLUMNS_COUNT || rowIndex > MAX_ROWS_COUNT || columnIndex > maxColumn || rowIndex > maxRow) {
      beautyName = `${INVALID_SHEET_NAME}${columnId}${rowId}`;
    }

    const cellSpan = document.createElement('span');
    const cellSpanValue = document.createTextNode(beautyName);
    cellSpan.appendChild(cellSpanValue);
    const color = this.props.getColor(sheetCellId, FormulaBarElementType.Cell);
    cellSpan.style.color = color || KreoColors.dayBackground;
    cellSpan.className = `${cellSpan.className} ${WrappedCellValueClass}`;
    cellSpan.contentEditable = 'false';
    cellSpanContainer.style.cursor = 'default';
    cellSpanContainer.style.verticalAlign = 'middle';

    cellSpanContainer.appendChild(this.getSeparator());
    cellSpanContainer.appendChild(cellSpan);
    cellSpanContainer.appendChild(this.getSeparator());

    if (this.props.onMouseEnterPupon) {
      cellSpanContainer.onmouseenter = () => this.props.onMouseEnterPupon(
        beautyName,
        FormulaBarElementType.Cell,
      );
    }

    if (this.props.onMouseLeavePupon) {
      cellSpanContainer.onmouseleave = () => this.props.onMouseLeavePupon(
        beautyName,
        FormulaBarElementType.Cell,
      );
    }

    cellSpanContainer.className = CellIdWrapClass;
    cellSpanContainer.onclick = this.onClickPupon;
    cellSpanContainer.onmousedown = this.onMouseDownPupon;
    cellSpanContainer.oncontextmenu = (e: MouseEvent) => {
      if (this.props.canEditReport) {
        this.onCellPuponContext(e, sheetCellId);
      }
    };

    return [cellSpanContainer];
  }

  private onCellPuponContext(e: MouseEvent, cellId: string): void {
    e.preventDefault();
    const {
      caretIndexInChildren: tempCursorIndex,
    } = this.state.isFocused && this.caretPositionManager.getCurrentCaretState();
    const name = ExcelFormulaHelper.replaceSheetIdToSheetName(cellId, this.props.reportPages);
    const sheetCellIdWithNameGroups = name.match(TwoDRegex.fullCellId).groups;
    if (sheetCellIdWithNameGroups.sheetId) {
      return;
    }
    this.setState({
      puponContextData: {
        x: e.pageX,
        y: e.pageY,
        cellData: { cellId },
        puponIndex: Array.from<Element>(this.spanRef.current.children).indexOf(e.currentTarget as Element),
      },
      tempCursorIndex,
    });
  }

  private wrapSymbol(symbol: string): HTMLElement {
    const symbolSpan = document.createElement('span');
    const symbolSpanValue = document.createTextNode(symbol);
    symbolSpan.appendChild(symbolSpanValue);

    return symbolSpan;
  }

  private getSvgByType(type: MoveToCellOptionType): { svg: string, metricUnit: UnitTypes } {
    const Icon = MeasureUtil.measureIcon[type];
    const svg = Icon
      ? ReactDOMServer.renderToString(<Icon />)
      : '';

    return {
      svg,
      metricUnit: MeasureUtil.measureUnit[type],
    };
  }

  private isNumberValue(drawingType: MoveToCellOptionType): boolean {
    return drawingType !== MoveToCellOptionType.name
      && drawingType !== MoveToCellOptionType.drawingName
      && drawingType !== MoveToCellOptionType.documentName
      && drawingType !== MoveToCellOptionType.groupName
      && drawingType !== MoveToCellOptionType.dynamicTable
      && drawingType !== MoveToCellOptionType.property;
  }

  private wrapDrawings(
    drawingType: MoveToCellOptionType,
    drawingId: string,
    otherArgs: string,
    group: DrawingsGeometryGroup,
  ): HTMLElement {
    const drawingSpanContainer = document.createElement('span');
    drawingSpanContainer.contentEditable = 'false';

    const { svg } = this.getSvgByType(drawingType);
    const unit = this.getUnit(drawingType);

    const hiddenSpan = document.createElement('span');
    hiddenSpan.style.display = 'none';
    const drawingRef = `${drawingType}(${drawingId}${otherArgs || ''})`;
    const hiddenSpanValue = document.createTextNode(drawingRef);
    hiddenSpan.appendChild(hiddenSpanValue);
    hiddenSpan.className = DrawingInstanceIdClass;

    const folderSpan = document.createElement('span');
    folderSpan.innerHTML = svg;

    const nameFolderSpan = document.createElement('span');
    nameFolderSpan.className = DrawingInstanceValueClass;
    const nameFolderTextNode = document.createTextNode(group ? group.name : null);
    nameFolderSpan.style.paddingRight = '10px';
    nameFolderSpan.appendChild(nameFolderTextNode);

    const drawingUnitSpan = document.createElement('span');
    drawingUnitSpan.innerHTML = UnitUtil.getSupUnit(unit);
    drawingUnitSpan.style.paddingLeft = '5px';
    drawingUnitSpan.style.height = '24px';
    if (drawingUnitSpan.childNodes[1]) {
      (drawingUnitSpan.childNodes[1] as HTMLElement).style.lineHeight = '0.5';
    }

    const drawingSpan = document.createElement('span');
    const drawingValue = this.props.getValue(drawingRef, FormulaBarElementType.DrawingElement);
    const drawingValueSpan = document.createElement('span');
    const drawingValueTextNode = this.isNumberValue(drawingType)
      ? document.createTextNode(mathUtils.round(Number(drawingValue), 2).toString())
      : document.createTextNode(drawingValue);
    drawingValueSpan.appendChild(drawingValueTextNode);
    drawingValueSpan.className = DrawingInstanceValueClass;
    drawingSpan.innerHTML = group ? ReactDOMServer.renderToString(<Icons.Group_2 />) : svg;

    const color = this.props.getColor(drawingId, FormulaBarElementType.DrawingElement) || ColorList[0];
    const textColor = ColorUtils.needReverseTextColorByBackgroundHex(color)
      ? Constants.Colors.GENERAL_COLORS.darkBlue
      : 'white';

    if (svg) {
      folderSpan.style.height = '20px';
      this.stylePupon(drawingSpan, textColor);
      this.stylePupon(folderSpan, textColor);
    }

    if (group) {
      drawingSpan.appendChild(nameFolderSpan);
      drawingSpan.appendChild(folderSpan);
    }

    drawingSpan.appendChild(drawingValueSpan);
    drawingSpan.appendChild(drawingUnitSpan);

    drawingSpan.style.backgroundColor = color;
    drawingSpan.style.boxShadow = '0px 1px 2px #0000001F';
    drawingSpan.style.padding = '0px 4px 0px 2px';
    drawingSpan.style.borderRadius = '6px';
    drawingSpan.contentEditable = 'false';
    drawingSpan.style.height = '24px';
    drawingSpan.style.display = 'inline-flex';
    drawingSpan.style.alignItems = 'center';
    drawingSpan.style.margin = '0 5px';
    drawingSpan.style.color = textColor;
    drawingSpan.className = 'formula-toolbar-pin';

    drawingSpanContainer.appendChild(this.getSeparator());
    drawingSpanContainer.appendChild(hiddenSpan);
    drawingSpanContainer.appendChild(drawingSpan);
    drawingSpanContainer.appendChild(this.getSeparator());

    if (this.props.onPuponClick) {
      drawingSpanContainer.style.cursor = 'pointer';
      drawingSpanContainer.style.verticalAlign = 'middle';
      drawingSpanContainer.onmousedown = this.onMouseDownPupon;
      drawingSpanContainer.onclick = (e: MouseEvent) => {
        this.props.onPuponClick(drawingId, FormulaBarElementType.DrawingElement);
        this.onClickPupon(e);
      };
    }

    drawingSpanContainer.oncontextmenu = (e: MouseEvent) => {
      if (this.props.canEditReport) {
        this.onPuponContext(e, color, drawingId, drawingType);
      }
    };

    drawingSpanContainer.className = DrawingInstanceContainerClass;
    return drawingSpanContainer;
  }

  @autobind
  private stylePupon(styleEl: HTMLElement, textColor: string): void {
    (styleEl.childNodes[0] as HTMLElement).style.height = '20px';
    (styleEl.childNodes[0] as HTMLElement).style.paddingRight = '6px';
    (styleEl.childNodes[0] as HTMLElement).style.fill = textColor;
    (styleEl.childNodes[0] as HTMLElement).style.transform = 'translateY(0px)';
  }

  private getDrawingElementValue(type: MoveToCellOptionType, drawingId: string): string {
    return this.props.getValue(`${type}(${drawingId})`, FormulaBarElementType.DrawingElement);
  }

  private isContextMenuWithMeasures(type: MoveToCellOptionType): boolean {
    return type === MoveToCellOptionType.area
      || type === MoveToCellOptionType.perimeter
      || type === MoveToCellOptionType.height
      || type === MoveToCellOptionType.length
      || type === MoveToCellOptionType.pointsCount
      || type === MoveToCellOptionType.segmentsCount
      || type === MoveToCellOptionType.volume
      || type === MoveToCellOptionType.verticalArea
      || type === MoveToCellOptionType.thickness;
  }

  private fillStrokeMeasures(
    measures: Record<string, string>,
    drawingId: string,
  ): void {
    const area = this.measureShowValueGetter[MoveToCellOptionType.area](drawingId);
    if (area !== null) {
      measures[MoveToCellOptionType.area] = area;
    }
    const perimeter = this.measureShowValueGetter[MoveToCellOptionType.perimeter](drawingId);
    if (perimeter !== null) {
      measures[MoveToCellOptionType.perimeter] = perimeter;
    }
    const height = this.measureShowValueGetter[MoveToCellOptionType.height](drawingId);
    if (height !== null) {
      measures[MoveToCellOptionType.height] = height;
    }
    if (this.props.ability.can(Operation.Read, Subject.Takeoff2dMeasurement3d)) {
      const volume = this.measureShowValueGetter[MoveToCellOptionType.volume](drawingId);
      if (volume !== null) {
        measures[MoveToCellOptionType.volume] = volume;
      }
      const verticalArea = this.measureShowValueGetter[MoveToCellOptionType.verticalArea](drawingId);
      if (verticalArea !== null) {
        measures[MoveToCellOptionType.verticalArea] = verticalArea;
      }
    }
  }

  @autobind
  private getAreaShowValue(drawingId: string): string {
    const areaValue = this.getDrawingElementValue(MoveToCellOptionType.area, drawingId);
    if (areaValue === undefined) {
      return null;
    }
    const areaUnit = UnitUtil.getSupUnit(this.getUnit(MoveToCellOptionType.area));
    return `${mathUtils.round(Number(areaValue), 2)} ${areaUnit}`;
  }

  @autobind
  private getPerimetrShowValue(drawingId: string): string {
    const perimeterValue = this.getDrawingElementValue(MoveToCellOptionType.perimeter, drawingId);
    if (perimeterValue === undefined) {
      return null;
    }
    const perimeterUnit = this.getUnit(MoveToCellOptionType.perimeter);
    return `${mathUtils.round(Number(perimeterValue), 2)} ${perimeterUnit}`;
  }

  @autobind
  private getVolumeShowValue(drawingId: string): string {
    const volumeValue = this.getDrawingElementValue(MoveToCellOptionType.volume, drawingId);
    if (volumeValue === undefined) {
      return null;
    }
    const volumeUnit = UnitUtil.getSupUnit(this.getUnit(MoveToCellOptionType.volume));
    return `${mathUtils.round(Number(volumeValue), 2)} ${volumeUnit}`;
  }

  @autobind
  private getVerticalAreaShowValue(drawingId: string): string {
    const value = this.getDrawingElementValue(MoveToCellOptionType.verticalArea, drawingId);
    if (value === undefined) {
      return null;
    }
    const unit = UnitUtil.getSupUnit(this.getUnit(MoveToCellOptionType.verticalArea));
    return `${mathUtils.round(Number(value), 2)} ${unit}`;
  }

  @autobind
  private getThicknessShowValue(drawingId: string): string {
    const value = this.getDrawingElementValue(MoveToCellOptionType.thickness, drawingId);
    if (value === undefined) {
      return null;
    }
    const unit = this.getUnit(MoveToCellOptionType.thickness);
    return `${mathUtils.round(Number(value), 2)} ${unit}`;
  }

  @autobind
  private getHeightShowValue(drawingId: string): string {
    const heightValue = this.getDrawingElementValue(MoveToCellOptionType.height, drawingId);
    if (heightValue === undefined) {
      return null;
    }
    const perimeterUnit = this.getUnit(MoveToCellOptionType.perimeter);
    return `${mathUtils.round(Number(heightValue), 2)} ${perimeterUnit}`;
  }

  @autobind
  private getWidthShowValue(drawingId: string): string {
    const widthValue = this.getDrawingElementValue(MoveToCellOptionType.width, drawingId);
    const perimeterUnit = this.getUnit(MoveToCellOptionType.perimeter);
    return `${mathUtils.round(Number(widthValue), 2)} ${perimeterUnit}`;
  }

  private fillPolylineMeasures(
    measures: Record<string, string>,
    drawingId: string,
  ): void {
    const length = this.measureShowValueGetter[MoveToCellOptionType.length](drawingId);
    if (length !== null) {
      measures[MoveToCellOptionType.length] = length;
    }
    const height = this.measureShowValueGetter[MoveToCellOptionType.height](drawingId);
    if (height !== null) {
      measures[MoveToCellOptionType.height] = height;
    }
    const canShowMeasure3d = this.props.ability.can(Operation.Read, Subject.Takeoff2dMeasurement3d);
    if (canShowMeasure3d) {
      const area = this.measureShowValueGetter[MoveToCellOptionType.area](drawingId);
      if (area !== null) {
        measures[MoveToCellOptionType.area] = area;
      }
      const volume = this.measureShowValueGetter[MoveToCellOptionType.volume](drawingId);
      if (volume !== null) {
        measures[MoveToCellOptionType.volume] = volume;
      }
      const thickness = this.measureShowValueGetter[MoveToCellOptionType.thickness](drawingId);
      if (thickness !== null) {
        measures[MoveToCellOptionType.thickness] = thickness;
      }
      const verticalArea = this.measureShowValueGetter[MoveToCellOptionType.verticalArea](drawingId);
      if (verticalArea) {
        measures[MoveToCellOptionType.verticalArea] = verticalArea;
      }
    }
  }

  @autobind
  private getLengthShowValue(drawingId: string): string {
    const lengthUnit = this.getUnit(MoveToCellOptionType.length);
    const lengthValue = this.getDrawingElementValue(MoveToCellOptionType.length, drawingId);
    return `${mathUtils.round(Number(lengthValue), 2)} ${lengthUnit}`;
  }

  private getPuponMeasures(
    drawingId: string,
    instance: DrawingsGeometryInstance<DrawingsGeometryType>,
  ): Record<string, string> {
    if (instance.type === DrawingsInstanceType.Count) {
      return {};
    }
    const measures = {
      [MoveToCellOptionType.pointsCount]: this.measureShowValueGetter[MoveToCellOptionType.pointsCount](drawingId),
      [MoveToCellOptionType.segmentsCount]: this.measureShowValueGetter[MoveToCellOptionType.segmentsCount](drawingId),
    };
    if (DrawingsGeometryUtils.isClosedContour(instance.type, instance.geometry)) {
      this.fillStrokeMeasures(measures, drawingId);
    } else if (DrawingsGeometryUtils.isPolyline(instance.type, instance.geometry)) {
      this.fillPolylineMeasures(measures, drawingId);
    }
    return getSortingMeasureTypes(measures);
  }

  @autobind
  private getPointsCountShowValue(drawingId: string): string {
    const pointsCount = this.getDrawingElementValue(MoveToCellOptionType.pointsCount, drawingId);
    return `${pointsCount} ${this.getUnit(MoveToCellOptionType.pointsCount)}`;
  }

  @autobind
  private getSegmentsCount(drawingId: string): string {
    const segmentsCount = this.getDrawingElementValue(MoveToCellOptionType.segmentsCount, drawingId);
    return `${segmentsCount} ${this.getUnit(MoveToCellOptionType.segmentsCount)}`;
  }

  @autobind
  private getCountShowValue(drawingId: string): string {
    const count = this.getDrawingElementValue(MoveToCellOptionType.count, drawingId);
    return `${count} Nr`;
  }

  @autobind
  private onPuponContext(
    e: MouseEvent,
    color: string,
    drawingId: string,
    drawingType: MoveToCellOptionType,
  ): void {
    e.preventDefault();
    const { aiAnnotation, drawingsGroups, elementMeasurement, drawings, getInstancesMeasures } = this.props;
    const geometry = aiAnnotation.geometry;
    const instance = geometry[drawingId];
    const elementIndex = Array.from(this.spanRef.current.childNodes).findIndex(el => el === e.currentTarget);
    const {
      caretIndexInChildren: tempCursorIndex,
    } = this.state.isFocused && this.caretPositionManager.getCurrentCaretState();
    if (instance) {
      const measures = this.isContextMenuWithMeasures(drawingType)
        ? this.getPuponMeasures(drawingId, instance)
        : null;
      this.setState({
        puponContextData: {
          x: e.pageX,
          y: e.pageY,
          drawingData: {
            drawingInstanceId: drawingId,
            color,
            measures,
            name: instance.name,
            currentType: drawingType,
            elementIndex,
          },
          puponIndex: elementIndex,
        },
        tempCursorIndex,
      });
    } else {
      const group = drawingsGroups.find(g => g.id === drawingId);
      if (!group) {
        return;
      }

      const options = getFolderToCellOptions(
        drawingsGroups,
        [drawingId],
        elementMeasurement,
        drawings,
        aiAnnotation,
        getInstancesMeasures,
      );
      this.setState({
        puponContextData: {
          x: e.pageX,
          y: e.pageY,
          drawingData: {
            drawingInstanceId: drawingId,
            color: group.color,
            measures: this.mapOptionsToMeasures(drawingId, options),
            name: group.name,
            currentType: drawingType,
            elementIndex,
            isGroup: true,
          },
          puponIndex: elementIndex,
        },
        tempCursorIndex,
      });
    }
  }

  private mapOptionsToMeasures(
    id: string,
    optiosn: Array<MultilevelSelectOptionDataWithObjectValue<MoveToCellOptionData>>,
  ): Record<MoveToCellOptionType, string> {
    const measures: any = {};
    optiosn.forEach(o => {
      const type = o.value.type;
      if (this.measureShowValueGetter[type]) {
        measures[type] = this.measureShowValueGetter[type](id);
      }
    });

    return measures;
  }

  @autobind
  private replaceDrawingParameterType(type: MoveToCellOptionType): void {
    if (this.state.puponContextData && this.state.puponContextData.drawingData.elementIndex > -1) {
      const { elementIndex, drawingInstanceId } = this.state.puponContextData.drawingData;
      this.replaceOnIndex(elementIndex, `${type}(${drawingInstanceId})`);
      this.props.updateData(this.getCurrentSpanValue(), true);
      this.setState((state) => ({
        puponContextData: {
          ...state.puponContextData,
          drawingData: {
            ...state.puponContextData.drawingData,
            currentType: type,
          },
        },
      }));
    }
  }

  @autobind
  private getColoredValue(value: string): HTMLElement[] {
    const elements = [];
    const isFormula = ExcelFormulaHelper.isFormula(value);
    const valueWithSheetId = ExcelFormulaHelper.replaceSheetNameToSheetId(value, this.props.reportPages);
    valueWithSheetId.replace(TwoDRegex.formulaToolBarGlobal, (...args) => {
      const groups = TwoDRegexTypings.typingFormulaToolBar(args);

      if (groups.fullCellId) {
        if (isFormula) {
          elements.push(...this.wrapCellId(groups.fullCellId));
        } else {
          groups.fullCellId.replace(TwoDRegex.symbolGlobal, (...a) => {
            const g = a[a.length - 1];
            elements.push(this.wrapSymbol(g.symbol));
            return '';
          });
        }
      }

      if (groups.drawingInstance) {
        const group = this.props.drawingsGroups.find(groupId => groupId.id === groups.drawingInstanceId);
        elements.push(
          this.wrapDrawings(
            MoveToCellOptionType[groups.drawingInstanceType],
            groups.drawingInstanceId,
            groups.otherArgs,
            group,
          ),
        );
      }

      if (groups.cellRange) {
        if (isFormula) {
          elements.push(...this.wrapCellId(groups.startId));
          elements.push(this.wrapSymbol(':'));
          elements.push(...this.wrapCellId(groups.endId));
        } else {
          groups.cellRange.replace(TwoDRegex.symbolGlobal, (...a) => {
            const g = a[a.length - 1];
            elements.push(this.wrapSymbol(g.symbol));
            return '';
          });
        }
      }

      if (groups.symbol) {
        elements.push(this.wrapSymbol(groups.symbol));
      }

      if (groups.stringValue) {
        groups.stringValue.replace(TwoDRegex.symbolGlobal, (...a) => {
          const g = a[a.length - 1];
          elements.push(this.wrapSymbol(g.symbol));
          return '';
        });
      }

      return '';
    });
    return elements;
  }

  @autobind
  private getUnit(type: MoveToCellOptionType): string {
    const metricValue = MeasureUtil.measureUnit[type];
    return metricValue !== null && this.props.isImperial
      ? TWODUnitConversionMap[metricValue]
      : metricValue;
  }

  @autobind
  private closePuponContext(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.setState({
      puponContextData: null,
    });
  }

  @autobind
  private onColorChange(color: string, isGroup: boolean): void {
    const { drawingInstanceId } = this.state.puponContextData.drawingData;
    this.props.changeEntitiesColor([drawingInstanceId], color, isGroup);
    this.setState((state) => ({
      puponContextData: {
        ...state.puponContextData,
        drawingData: {
          ...state.puponContextData.drawingData,
          color,
        },
      },
    }),
    );
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    aiAnnotation: state.drawings.aiAnnotation,
    drawingsGroups: state.drawings.drawingGeometryGroups,
    elementMeasurement: state.drawings.elementMeasurement,
    drawings: state.drawings.drawingsInfo,
  };
}


export const FormulaToolbar = connect(mapStateToProps)(withAbilityContext(FormulaToolbarComponent));
