const prefix = '@gantt-chart';

export const GanttChartActionTypes = {
  ANIMATION_DATA_REQUEST_STARTED: `${prefix}/ANIMATION_DATA_REQUEST_STARTED`,
  ANIMATION_DATA_REQUEST_SUCCEEDED: `${prefix}/ANIMATION_DATA_REQUEST_SUCCEEDED`,
  SAVE_GANTT_DATA: `${prefix}/SAVE_GANTT_DATA`,
  RESET_STATE: `${prefix}/RESET_STATE`,
  EXPAND_ITEM: `${prefix}/EXPAND_ITEM`,
  MULTIPLE_CHANGE_SELECTION: `${prefix}/MULTIPLE_CHANGE_SELECTION`,
  CHANGE_SELECTION: `${prefix}/CHANGE_SELECTION`,
  EXPAND_ALL: `${prefix}/EXPAND_ALL`,
  COLLAPSE_ALL: `${prefix}/COLLAPSE_ALL`,
  SAVE_RESOURCES_DATA: `${prefix}/SAVE_RESOURCES_DATA`,
  RESET_ACTIVE_TAB: `${prefix}/RESET_ACTIVE_TAB`,
};
