import { goBack } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import './index.scss';

import { KreoIconSubnavBack } from 'common/UIKit';

class PageBackBtn extends Component<{ navigate: () => void }> {
  shouldComponentUpdate(): boolean {
    return false;
  }

  render(): any {
    return (
      <div
        className='page-back-btn'
        onClick={(): any => { // eslint-disable-line react/jsx-no-bind
          if (typeof this.props.navigate === 'function') {
            this.props.navigate();
          } else {
            this.props.goBack();
          }
      }}>
        <KreoIconSubnavBack />
      </div>
    );
  }
}

function mapStateToProps(): any {
  return {
  }
}

function mapDispatchToProps(dispatch: Function): any {
  return {
    goBack: () => {
      dispatch(goBack())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageBackBtn);
