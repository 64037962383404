import { Text, Constants } from '@kreo/kreo-ui-components';
import React from 'react';
import { useSelector } from 'react-redux';

import { WIZZARD_ERROR_MESSAGES } from 'common/components/drawings/enums';
import { DrawingsSelectAggregationGroup } from 'common/components/drawings/interfaces';
import { RenderIf } from 'common/components/render-if';
import { RoundSwitcher } from 'common/components/round-switcher';
import { State } from 'common/interfaces/state';
import { useWizzardSetting } from '../../../hooks';
import { TUTORIALS } from '../constants';
import { SettingsItemWizzard } from '../setting-item-wizzard';
import { WizzardMainContainer } from '../wizzard-main-container';
import { WizzardWorkingAreaButton } from '../wizzard-working-area-button';

const GeometrySwitcherConfig = [
  {
    mode: DrawingsSelectAggregationGroup.Area,
    text: 'Polygon',
    description: 'Select "Polygon" to search for areas',
  },
  {
    mode: DrawingsSelectAggregationGroup.Line,
    text: 'Line',
    description: 'Select "Line" to search for lines',
  },
];

const DropperSettingsComponent: React.FC = () => {
  const [selectedGeometryType, onChangeGeometryType] = useWizzardSetting('dropperInstanceType');
  const error = useSelector<State, string>(x => x.drawings.wizzardTools.error);

  return (
    <WizzardMainContainer>
      <RenderIf condition={!!error}>
        <Text customColor={Constants.Colors.GENERAL_COLORS.red}>{WIZZARD_ERROR_MESSAGES[error]}</Text>
      </RenderIf>
      <RoundSwitcher
        modes={GeometrySwitcherConfig}
        currentMode={selectedGeometryType}
        toggleMode={onChangeGeometryType}
        elementHeight={30}
        backgroundColor='background'
      />
      <SettingsItemWizzard
        label='Same size'
        disabled={false}
        tutorial={TUTORIALS.SameSize}
        settingKey='dropperSameGeometry'
      />
      <SettingsItemWizzard
        label='Allow rotation'
        disabled={false}
        tutorial={TUTORIALS.AllowRotate}
        settingKey='allowRotation'
      />
      <SettingsItemWizzard
        label='Allow flipping'
        disabled={false}
        tutorial={TUTORIALS.AllowFlip}
        settingKey='allowFlipping'
      />
      <WizzardWorkingAreaButton />
    </WizzardMainContainer>
  );
};

export const DropperSettings = React.memo(DropperSettingsComponent);
