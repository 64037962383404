import { LocationStateWrapper } from 'common/interfaces/router-state-wrapper';

export const updateQuery = (
  location: LocationStateWrapper,
  updateFields: Record<string, string>,
): Record<string, string> => {
  const query = location.query;

  return {
    ...query,
    ...updateFields,
  };
};
