import * as t from 'io-ts';

import { RoleGroupC } from 'common/enums/role-group';

export const PersonC = t.exact(
  t.intersection([
    t.type({
      id: t.string,
      isAccepted: t.boolean,
      isOwner: t.boolean,
      isPayable: t.boolean,
      hasAvatar: t.boolean,
      isCompletedRegistration: t.boolean,
    }),
    t.partial({
      firstName: t.string,
      lastName: t.string,
      roleGroup: RoleGroupC,
      image: t.number,
      invitationToken: t.string,
      email: t.string,
      roleId: t.number,
      invitationResent: t.boolean,
    }),
  ]),
  'Person');

export const PeopleV = t.array(PersonC);

export type Person = t.TypeOf<typeof PersonC>;
