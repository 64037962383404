import { ContextObserverWithPrevious } from '../../drawings-contexts';
import { DrawingsRenderParams } from '../../interfaces';
import { DrawingsGeometryEntity } from '../drawings-geometry-entities';
import { DestroyableObject } from './destroyable-object';
import { EngineObjectConfig } from './engine-object';

export interface VisibleEntityConfig<TGeometryData> extends EngineObjectConfig {
  geometry: TGeometryData;
  renderParamsContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
}

export abstract class VisibleEntity<
  TGeometry,
  C extends VisibleEntityConfig<TGeometry> = VisibleEntityConfig<TGeometry>
> extends DestroyableObject<C>
  implements DrawingsGeometryEntity {
  constructor(config: C) {
    super(config);

    this.render(config.geometry);
  }

  public get geometry(): TGeometry {
    return this._config.geometry;
  }

  protected abstract render(geometry: TGeometry);
}
