import * as React from 'react';
import { EnvironmentConfigurationProps } from 'common/environment/environment-configuration-props';
import { ExceptionHandlingUtils } from 'common/environment/exception-handling-utils';
import { withEnvironmentConfiguration } from 'common/environment/with-environment-configuration';
import { TrackerProvider } from '../utils/tracker-provider';

export interface SegmentContextProps {
  isSegmentReady: boolean;
  isSegmentLoaded: boolean;
}

export const SegmentContext = React.createContext<SegmentContextProps>(null);


class Component extends React.Component<EnvironmentConfigurationProps, SegmentContextProps> {
  private get segmentTracker(): SegmentTracker {
    return TrackerProvider.segmentTracker();
  }

  constructor(props: EnvironmentConfigurationProps) {
    super(props);
    this.state = {
      isSegmentReady: false,
      isSegmentLoaded: false,
    };
  }

  public shouldComponentUpdate(
    _nextProps: EnvironmentConfigurationProps,
    nextState: Readonly<SegmentContextProps>,
  ): boolean {
    if (!this.state.isSegmentReady && !nextState.isSegmentReady && !this.state.isSegmentLoaded) {
      this.tryInitSegment();
    }
    return this.state.isSegmentReady !== nextState.isSegmentReady
      || this.state.isSegmentLoaded !== nextState.isSegmentLoaded;
  }


  public render(): React.ReactNode {
    return (
      <SegmentContext.Provider value={this.state}>
        {this.props.children}
      </SegmentContext.Provider>
    );
  }

  private tryInitSegment(): void {
    const { environmentConfiguration } = this.props;
    const { isSegmentReady } = this.state;

    if (!isSegmentReady && this.segmentTracker && environmentConfiguration) {
      const { segmentApiKey } = environmentConfiguration;
      if (typeof this.segmentTracker.ready === 'function') {
        this.segmentTracker.ready(() => {
          this.setState({ isSegmentReady: true }, () => this.segmentTracker.page(window.location.pathname));
        });
      }

      if (typeof this.segmentTracker.load === 'function' && segmentApiKey) {
        this.segmentTracker.load(segmentApiKey);
        this.setState({
          isSegmentLoaded: true,
        }, () => {
          setTimeout(
            () => {
              const isDevelopment = ['Development'].includes(environmentConfiguration.environmentName) ||
                process.env.NODE_ENV === 'development';
              if (!isDevelopment && environmentConfiguration.sentryDsn) {
                ExceptionHandlingUtils.initSentryAndOverrideConsoleError(
                  environmentConfiguration.sentryDsn,
                  environmentConfiguration.release,
                  environmentConfiguration.environmentName,
                );
              }
            },
            10000);
        });
      }
    }
  }
}


export const SegmentContextProvider = withEnvironmentConfiguration(Component);
