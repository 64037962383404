import * as React from 'react';

import { KreoColors } from 'common/enums/kreo-colors';

interface Props {
  color?: KreoColors;
}

export const KreoIconHotkeys: React.FC<Props> = (props) => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
    <g id='Icons/24x24/Hotkeys' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        // eslint-disable-next-line max-len
        d='M19,6 C20.6568542,6 22,7.34314575 22,9 L22,15 C22,16.6568542 20.6568542,18 19,18 L5,18 C3.34314575,18 2,16.6568542 2,15 L2,9 C2,7.34314575 3.34314575,6 5,6 L19,6 Z M19,7 L5,7 C3.8954305,7 3,7.8954305 3,9 L3,15 C3,16.1045695 3.8954305,17 5,17 L19,17 C20.1045695,17 21,16.1045695 21,15 L21,9 C21,7.8954305 20.1045695,7 19,7 Z M19,15 L19,16 L18,16 L18,15 L19,15 Z M17,15 L17,16 L7,16 L7,15 L17,15 Z M6,15 L6,16 L5,16 L5,15 L6,15 Z M6,12 L6,13 L5,13 L5,12 L6,12 Z M9,12 L9,13 L8,13 L8,12 L9,12 Z M12,12 L12,13 L11,13 L11,12 L12,12 Z M15,12 L15,13 L14,13 L14,12 L15,12 Z M19,9 L19,13 L17,13 L17,12 L18,12 L18,9 L19,9 Z M6,9 L6,10 L5,10 L5,9 L6,9 Z M12,9 L12,10 L11,10 L11,9 L12,9 Z M9,9 L9,10 L8,10 L8,9 L9,9 Z M15,9 L15,10 L14,10 L14,9 L15,9 Z'
        id='Shape'
        fill={props.color ? props.color : 'currentColor'}
        fillRule='nonzero'
      />
    </g>
  </svg>
);
