import { FilterPanelState } from '../../../units/projects/components/quantity-take-off-left-table-tab/use-save-state';
import { CustomOptionConfig } from './custom-filter-map';
import { TreeFilterPanelState } from './interfaces';

export const handleSaveState = (
  callBack: (filter: TreeFilterPanelState) => void,
  getSaveState: () => FilterPanelState,
): boolean => {
  const saveState = getSaveState();
  const isSaveStateUse = !!saveState;
  if (isSaveStateUse) {
    const filterState = saveState as TreeFilterPanelState;
    filterState.filterConfigs.forEach(f => {
      const customConfig = CustomOptionConfig[f.key];
      f.optionsRender = customConfig ? customConfig.optionsRender : undefined;
    });

    filterState.defaultFilterOptions.forEach(f => {
      const customConfig = CustomOptionConfig[f.key];
      f.optionsRender = customConfig ? customConfig.optionsRender : undefined;
    });
    callBack({ ...filterState });
  }

  return isSaveStateUse;
};
