import { Text, H3 } from '@kreo/kreo-ui-components';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Questionnaire } from 'common/components/questionnaire';
import { Spinner } from 'common/components/spinner';
import { Mood } from 'common/enums/mood';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { SUBSCRIPTION_EDIT_FORM_STATE } from '../../../reducers/account';
import { AccountActions } from '../../../units/account/actions/creators';
import { SegmentContextProps, withSegmentContext } from '../../analytics/components/segment-context';
import { SubscriptionActions } from '../actions/creators';
import { SUBSCRIPTION__DIALOG } from '../components/subscription-dialog';
import { EXTRA_TEXT, HEADER_TEXT, LIST_QUESTION } from './interfaces';
import { Styled } from './styled';


interface StateProps {
  subscriptionId: string;
}

interface DispatchProps {
  cancelSubscription: (subscriptionId: string) => void;
  resetSubscriptionEditForm: () => void;
  closeDialog: () => void;
  onChangeUrl: (url: string) => void;
}

interface PageProps extends StateProps, DispatchProps, SegmentContextProps { }

const CancellationSurveyPageComponent: React.FC<PageProps>  = ({
  subscriptionId,
  cancelSubscription,
  onChangeUrl,
  resetSubscriptionEditForm,
  closeDialog,
}: PageProps) => {
  const { sendEvent } = useAnalytics();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [id, setSubscriptionId] = useState<string>('');

  const sendQuestionnaireForm = useCallback((form: Record<string, string>) => {
    setIsLoading(true);
    cancelSubscription(id);
    sendEvent(MetricNames.trial.cancelledSubscription, form);
    resetSubscriptionEditForm();
    closeDialog();
    onChangeUrl(AppUrls.products.url());
  }, [cancelSubscription, id, sendEvent, resetSubscriptionEditForm, closeDialog, onChangeUrl]);

  const closePage = useCallback(() => {
    setIsLoading(true);
    closeDialog();
    resetSubscriptionEditForm();
    onChangeUrl(AppUrls.root.url());
  }, [closeDialog, onChangeUrl, resetSubscriptionEditForm]);

  useEffect(() => {
    if (subscriptionId) {
      setSubscriptionId(subscriptionId);
    }

    return () => {
      setIsLoading(false);
    };
  }, [isLoading, subscriptionId, setIsLoading]);

  return (
    <Styled.Container>
      <Spinner show={isLoading} withBackground={true} />
      <Styled.HeaderContainer>
        <H3>{HEADER_TEXT}</H3>
      </Styled.HeaderContainer>
      <Styled.ExtraText>
        <Text color={'gray'}>
          {EXTRA_TEXT}
        </Text>
      </Styled.ExtraText>
      <Styled.QuestionnaireContainer>
        <Questionnaire
          content={LIST_QUESTION}
          continueButtonTitle={'Cancel subscription'}
          continueButtonMood={Mood.Negative}
          showCloseButton={true}
          onClickContinue={sendQuestionnaireForm}
          closeButtonClick={closePage}
        />
      </Styled.QuestionnaireContainer>
    </Styled.Container>
  );
};


const mapStateToProps = (state: State): StateProps => {
  const account = state.account;
  const subscriptionId = account && account.ownCompanyBillingInfo
    && account.ownCompanyBillingInfo.subscriptionId || null;

  return {
    subscriptionId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    cancelSubscription: (subscriptionId) => dispatch(SubscriptionActions.cancelSubscription(subscriptionId)),
    resetSubscriptionEditForm: () =>
      dispatch(AccountActions.setSubscriptionEditFormState(SUBSCRIPTION_EDIT_FORM_STATE)),
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(SUBSCRIPTION__DIALOG)),
    onChangeUrl: (url) => dispatch(push(url)),
  };
};

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const CancellationSurveyPage = withSegmentContext(
  reduxConnector(CancellationSurveyPageComponent),
);
