import { Icons, Switcher, Waves } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { HintWithDescription } from 'common/components/hint-with-description';
import { ColorsName } from 'common/enums/kreo-colors';
import { Text } from '../../../text';
import { useNewInstancesSetting, useShowCalibrationDialogIfNeed } from '../../hooks';
import { Styled } from './styled';


interface Props {
  disabled?: boolean;
}

const MagicSearchToggleComponent: React.FC<Props> = ({
  disabled,
}) => {
  const description = `Enable this feature, and after creating measurement,
  AI will help find areas similar to your measurement`;

  const [ checked, onChange ] = useNewInstancesSetting('shouldSearchSimilar');
  const openCallibrationIfNeeded = useShowCalibrationDialogIfNeed();

  const onChangeCallback = useCallback((value: boolean) => {
    if (value) {
      openCallibrationIfNeeded(() => onChange(value));
    } else {
      onChange(value);
    }
  }, [onChange, openCallibrationIfNeeded]);

  const toggleSwitcher = useCallback(() => onChangeCallback(!checked), [onChangeCallback, checked]);
  const canUseMagicSearch = useAbility(Operation.Manage, Subject.MagicSearch);

  if (!canUseMagicSearch) {
    return null;
  }

  return (
    <>
      <Styled.Container
        onClick={disabled ? undefined : toggleSwitcher}
      >
        <Styled.Content>
          <Icons.MagicSearch2D/>
          <Text
            fontSize={14}
          >
            {'Find similar areas with AI'}
          </Text>
        </Styled.Content>
        <Switcher
          checked={checked}
          size='m'
          disabled={disabled}
        />
        <Waves color={ColorsName.turquoise} />
      </Styled.Container>
      <HintWithDescription text={description} />
    </>
  );
};


export const MagicSearchToggle = React.memo(MagicSearchToggleComponent);
