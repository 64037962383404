import styled, { css, FlattenSimpleInterpolation } from 'styled-components';


interface StylesSettingsForEmojiMenu {
  isEmojiMenu: boolean;
  isChangeMenuPosition: boolean;
  top: number;
}

function getStylesForDropList(
  { isEmojiMenu, isChangeMenuPosition, top }: StylesSettingsForEmojiMenu,
): FlattenSimpleInterpolation {
  if (isChangeMenuPosition) {
    return css`
      position: fixed !important;
      left: 80px !important;
      top: ${top}px !important;
      min-width: ${isEmojiMenu ? 100 : 120}px !important;
      max-width: min-content;
    `;
  } else {
    return null;
  }
}

interface ContainerProps extends StylesSettingsForEmojiMenu{
  minHeight: number;
}

const Container = styled.div<ContainerProps>`
  width: 100%;

  > div {
    > div {
      > textarea {
        background: ${p => p.theme.color.background};
      }
    }
  }
  .mention-input {
    width: 100%;
  }
  .mention-input_input {
    resize: none;
    outline: none;
    font-size: 18px;
    line-height: 27px;
    width: 100%;
    height: 100%;
    border-style: none;
    font-weight: 500;
    border: 1px solid ${p => p.theme.color.background};
  }

  .mention-input__control {
    min-height: ${p => p.minHeight ? `${p.minHeight}px` : 'unset'};
  }

  .mention-input__input {
    position: relative!important;
    min-height: 30px;
    background: ${p => p.theme.color.background} !important;
    border: 1px solid ${p => p.theme.color.background};
    color: ${p => p.theme.color.mainFont};
    border-radius: 10px;
    font-size: 14px !important;
    padding-right: 5px;
    padding-left: 10px;
    max-width: 195px;
    outline: none;
    line-height: 25px;
    -moz-appearance: none;

    :focus {
      border-color: ${p => p.theme.color.turquoise};
    }

    ::placeholder {
      color: ${p => p.theme.color.disabled};
    }
  }

  .mention-input__suggestions {
    background: ${p => p.theme.color.backgroundRush} !important;
    box-shadow: 0px 15px 40px #00000029;
    border-radius: 10px;
    color: ${p => p.theme.color.mainFont};
    font-size: 12px;
    ${p => getStylesForDropList(p)};
    > ul {
      > li {
        padding: 5px 10px;
      }
    }
  }

  .mention-input__suggestions__list{
    max-height: 180px;
    overflow-y: auto;
  }

  .mention-input__suggestions__item.mention-input__suggestions__item--focused {
    background: ${p => p.theme.color.pale} !important;
    border-radius: 10px;
  }

  .mention-input__highlighter {
    position: absolute!important;
    max-width: 200px;
    .mention-input__highlighter__substring {
      max-width: 202px;
    }

    .mention-input__highlighter__caret {
      display: inline-block;
      position: relative;
      top: 5px;
    }
  }
`;

export const Styled = {
  Container,
};
