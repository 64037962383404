import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './gantt-line-body.scss';

import { SlackHorizontalCoordinates } from '../../utils/gantt-chart';
import { BaseSlackRange } from './base-slack-range';

interface Props {
  left: number;
  width: number;
  offColor: string;
  lineId: string;
  className?: string;
  slackCoordinates?: SlackHorizontalCoordinates;
  saveOuterRangeRef: (lineId: string, ref: HTMLDivElement) => void;
}

export class BaseGanttLineBody extends React.Component<Props> {
  private slackRef: HTMLDivElement = null;
  private containerRef: HTMLDivElement = null;
  private rangeRef: HTMLDivElement = null;
  private slackIsMounted: boolean = false;

  public componentDidMount(): void {
    if (this.props.slackCoordinates) {
      this.slackIsMounted = true;
    }
  }

  public shouldComponentUpdate(nextProps: Props): boolean {
    // CAUTION: DIRTY HACK
    // internal-ranges are rendered into outer-range by vanilla JS in ChartBody.componentDidUpdate
    // when outer-range size or position is changed, React removes all children from outer-range
    // to prevent this behavior, we let React call render method only while mounting the component
    // all updates are handled via ref and vanilla JS

    if (this.props.left !== nextProps.left) {
      this.rangeRef.style.transform = `translateX(${nextProps.left}px)`;
    }

    if (this.props.width !== nextProps.width) {
      this.rangeRef.style.width = `${nextProps.width}px`;
    }

    if (this.props.offColor !== nextProps.offColor) {
      this.rangeRef.style.backgroundColor = nextProps.offColor;
    }

    if (nextProps.slackCoordinates) {
      if (!this.slackRef) {
        return true;
      }
      if (!this.slackIsMounted) {
        this.containerRef.insertBefore(this.slackRef, this.rangeRef);
      }
      this.slackRef.style.width = `${nextProps.slackCoordinates.width}px`;
      this.slackRef.style.transform = `translateX(${nextProps.slackCoordinates.left}px)`;
      this.slackRef.style.backgroundColor = nextProps.offColor;
      this.slackIsMounted = true;
    } else {
      if (this.slackRef && this.slackIsMounted) {
        this.containerRef.removeChild(this.slackRef);
      }
      this.slackIsMounted = false;
    }

    return false;
  }


  public render(): React.ReactNode {
    return (
      <div
        className={classNames('gantt-line-body', this.props.className)}
        ref={this.saveBodyRef}
      >
        <BaseSlackRange
          sendSlackRef={this.saveSlackRef}
          slackData={this.props.slackCoordinates}
          color={this.props.offColor}
        />
        <div
          className='gantt-line-body__outer-range'
          style={{
            transform: `translateX(${this.props.left}px)`,
            width: this.props.width,
            background: this.props.offColor,
          }}
          ref={this.saveRangeRef}
        />
      </div>
    );
  }

  @autobind
  private saveBodyRef(ref: HTMLDivElement): void {
    this.containerRef = ref;
  }

  @autobind
  private saveSlackRef(ref: HTMLDivElement): void {
    this.slackRef = ref;
  }

  @autobind
  private saveRangeRef(ref: HTMLDivElement): void {
    this.rangeRef = ref;
    this.props.saveOuterRangeRef(this.props.lineId, ref);
  }
}
