import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';
import { SpinnerStyled } from 'common/components/spinner';

const Container = styled.div`
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
  display: flex;
  position: relative;
  ${SpinnerStyled.SpinnerContainer} {
    margin: 25px auto;
    position: static;
  }
  > ${Text} {
    margin: 10px;
  }
`;

export const Styled = {
  Container,
};
