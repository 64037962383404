import React from 'react';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { useAbilityContext } from 'common/ability/use-ability-context';
import { MenuItemWrapper } from '../../components';
import { OPTIONS_BASE_DESCRIPTIONS, OptionsSubjectsMap, VisibleSubjectsMap } from '../../drawings-controls/helpers';
import { DrawingsDrawMode } from '../../enums';
import { Styled } from './styled';

interface Props {
  drawMode: DrawingsDrawMode;
  onClick: (drawMode: DrawingsDrawMode) => void;
}


const DrawModeMenuItemComponent: React.FC<Props> = ({
  drawMode,
  onClick,
}) => {
  const { ability } = useAbilityContext();
  const needUpdatePlan = OptionsSubjectsMap[drawMode]?.some((subject) => ability.cannot(Operation.Update, subject));
  const { name, icon } = OPTIONS_BASE_DESCRIPTIONS[drawMode];
  const hide = VisibleSubjectsMap[drawMode]?.some((subject) => ability.cannot(Operation.Update, subject));
  if (hide) {
    return null;
  }
  return (
    <Styled.Container>
      <UpgradeWrapper isNeedUpdate={needUpdatePlan}>
        <MenuItemWrapper
          onClick={onClick}
          name={name}
          icon={icon}
          id={drawMode}
          disabled={needUpdatePlan}
        />
      </UpgradeWrapper>
    </Styled.Container>
  );
};

export const DrawModeMenuItem = React.memo(DrawModeMenuItemComponent);
