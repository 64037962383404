import autobind from 'autobind-decorator';
import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { NotFoundPage } from '../../units/notfound/page';

interface Props<TRouteParams> extends AbilityAwareProps {
  component: React.ComponentType<RouteComponentProps<TRouteParams>>;
  path: string;
  exact?: boolean;
  subject?: Subject;
  operation?: Operation;
  redirectUrl?: string;
}

class AbilityRouteComponent<TRouteParams> extends React.Component<Props<TRouteParams>> {
  public render(): React.ReactNode {
    const { exact, path, ability, subject, operation, redirectUrl } = this.props;

    if (subject && ability.cannot(operation ? operation : Operation.Read, subject)) {
      return redirectUrl ? <Redirect to={redirectUrl} /> : <NotFoundPage />;
    }
    return <Route exact={exact} path={path} render={this.renderRouteComponent} />;
  }

  @autobind
  public renderRouteComponent(props: RouteComponentProps<TRouteParams>): React.ReactNode {
    const { component } = this.props;
    const InnerComponent = component;

    return <InnerComponent {...props} />;
  }
}


export const AbilityRoute = withAbilityContext(AbilityRouteComponent);
