import autobind from 'autobind-decorator';

import {
  ContextObserverWithPrevious,
} from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import {
  DrawingsAllowedPathType,
  DrawingsGeometryStrokedType,
} from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingsGeometryInstance } from 'common/components/drawings/interfaces/drawings-geometry-instance';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsGeometryUtils } from 'common/components/drawings/utils/drawings-geometry-utils';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { EngineObjectConfig } from '../../common';
import { DrawingsGeometryOffsetPoint } from '../../drawings-geometry-entities/utility';
import { AddInstancesWithUndoRedoCallback, GetCurrentPageSizeInfoCallback } from '../../interfaces';
import { DrawingsOperationHelper } from '../common/operation-helper';
import { DrawingsViewHelper } from '../drawings-view-helper';
import { BaseOffsetEntity } from './base-offset-entity';
import { PolygonOffsetEntity } from './polygon-offset-entity';
import { PolylineOffsetEntity } from './polyline-offset-entity';


interface OffsetHelperSettings extends EngineObjectConfig {
  layer: paper.Layer;
  viewHelper: DrawingsViewHelper;
  getCurrentPageInfo: GetCurrentPageSizeInfoCallback;
  changeOffsetPosition: (e: paper.Point, length: number) => void;
  addInstances: AddInstancesWithUndoRedoCallback;
  observableRenderContext: ContextObserverWithPrevious<DrawingsRenderParams>;
}

export class DrawingsOffsetHelper extends DrawingsOperationHelper<OffsetHelperSettings> {
  private _path: DrawingsAllowedPathType;
  private _point: DrawingsGeometryOffsetPoint;
  private _startPointPosition: paper.Point;
  private _offsetIsStroke: boolean;
  private _dragStartSegment: [paper.Point, paper.Point];
  private _currentOffsetEntity: BaseOffsetEntity;

  constructor(config: OffsetHelperSettings) {
    super(config);
    this._config.observableRenderContext.subscribe(this.updateRenderParams);
  }

  public destroy(): void {
    this._config.observableRenderContext.unsubscribe(this.updateRenderParams);
    if (this._path) {
      this._path.remove();
    }
  }

  public get isActive(): boolean {
    return !!this._path;
  }

  public get hasChanges(): boolean {
    return !!this._startPointPosition;
  }

  public startOffset(
    path: DrawingsAllowedPathType,
    color: DrawingsPaperColorInfo,
    instance: DrawingsGeometryInstance<DrawingsGeometryStrokedType>,
  ): boolean {
    this._path = path;
    this._config.viewHelper.onMouseMove = this.changePointPosition;
    if (DrawingsGeometryUtils.isClosedContour(instance.type, instance.geometry)) {
      this._currentOffsetEntity = new PolygonOffsetEntity(
        {
          basePath: path,
          layer: this._config.layer,
          color,
          instance,
          stroke: this._offsetIsStroke,
          observableRenderContext: this._config.observableRenderContext,
          strokeWidth: instance.geometry.strokeWidth,
          strokeStyle: instance.geometry.strokeStyle,
          getCurrentPageInfo: this._config.getCurrentPageInfo,
          height: instance.geometry.height,
        },
      );
      return true;
    } else if (DrawingsGeometryUtils.isPolyline(instance.type, instance.geometry)) {
      this._currentOffsetEntity = new PolylineOffsetEntity(
        {
          basePath: path,
          layer: this._config.layer,
          color,
          instance,
          stroke: this._offsetIsStroke,
          observableRenderContext: this._config.observableRenderContext,
          strokeWidth: instance.geometry.strokeWidth,
          strokeStyle: instance.geometry.strokeStyle,
          height: instance.geometry.height,
          thickness: instance.geometry.thickness,
          getCurrentPageInfo: this._config.getCurrentPageInfo,
        });
      return true;
    }
    this._config.viewHelper.restoreDefaultEvent('onMouseMove');
    this.finishOffset();
    return false;
  }

  @autobind
  public updateRenderParams(params: DrawingsRenderParams): void {
    if (this._path) {
      const { strokeWidth, strokeStyle } = this._currentOffsetEntity.instance.geometry;
      this._path.strokeWidth = strokeWidth / params.zoom;
      this._path.dashArray = DrawingsCanvasUtils.scaleStroke({ strokeStyle, strokeWidth }, params.zoom);
    }
    if (this._currentOffsetEntity) {
      this._currentOffsetEntity.updateOffsetRenderParameters(params);
    }

    if (this._point) {
      this._currentOffsetEntity.updateOffsetRenderParameters(params);
    }
  }

  @autobind
  public apply(onApplied: () => void): void {
    if (!this._currentOffsetEntity) {
      return;
    }
    const { instances, points } = this._currentOffsetEntity.getGeometry();
    if (instances.length) {
      this._config.addInstances({ instances, points });
      onApplied();
      this._currentOffsetEntity.destroy();
    }
  }

  public finishOffset(): void {
    if (this._path) {
      this._path.remove();
      this._path = null;
    }
    if (this._point) {
      this._point.destroy();
      this._point = null;
    }
    if (this._currentOffsetEntity) {
      this._currentOffsetEntity.destroy();
      this._currentOffsetEntity = null;
    }
    this._dragStartSegment = null;
    this.instancesForProcess = null;
  }

  @autobind
  public toggleOffsetType(stroke: boolean): void {
    if (stroke !== this._offsetIsStroke && this._currentOffsetEntity) {
      this._currentOffsetEntity.updateOffset(this.getOffset(this._point.position), stroke);
    }
    this._offsetIsStroke = stroke;
  }

  @autobind
  private changePointPosition(e: PaperMouseEvent): void {
    const pointProjectionToPath = this._path.getNearestPoint(e.point);
    if (this._point) {
      this._point.position = pointProjectionToPath;
    } else {
      this._point = new DrawingsGeometryOffsetPoint(
        {
          id: 'offset-point',
          layer: this._config.layer,
          position: pointProjectionToPath,
          onStartDrag: this.startDrag,
          observableRenderContext: this._config.observableRenderContext,
        },
      );
    }
  }

  private getOffset(position: paper.Point): number {
    const [startPoint, endPoint] = this._dragStartSegment;
    const segmentAngle = endPoint.subtract(startPoint).angle;
    const lineAngle = this._point.position.subtract(this._startPointPosition).angle;
    let offset = this._startPointPosition.subtract(position).length;
    const diffAngle = Math.round(segmentAngle - lineAngle);
    if (diffAngle === 270 || diffAngle === -90) {
      offset = -offset;
    }
    return offset;
  }

  @autobind
  private startDrag(): void {
    if (!this._dragStartSegment) {
      this._dragStartSegment = this._currentOffsetEntity.findEdgeOfPoint(this._point.position);
      this._startPointPosition = this._point.position;
    }
    this._config.viewHelper.onMouseMove = this.dragPoint;
    this._config.viewHelper.onMouseUp = this.dragMouseUp;
  }

  @autobind
  private dragPoint(e: PaperMouseEvent): void {
    const angle = DrawingsPaperUtils.getPaperAngle(e.point, this._startPointPosition, this._dragStartSegment[0]);
    const turn = DrawingsPaperUtils.getRightAngleSnappingTurn(angle);
    const position = turn === 90 ?
      e.point
      : DrawingsPaperUtils.getClosestPointOnLine(
        e.point.rotate(turn, this._startPointPosition),
        this._startPointPosition,
        e.point,
      );

    const offset = this.getOffset(position);
    if (this._currentOffsetEntity.updateOffset(offset, this._offsetIsStroke)) {
      this._point.position = position;
      this._config.changeOffsetPosition(position, offset);
    }
  }

  @autobind
  private dragMouseUp(): void {
    this._config.viewHelper.onMouseUp = undefined;
    this._config.viewHelper.onMouseMove = undefined;
  }
}
