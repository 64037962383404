import { FlowNavigation, Text, RectangleButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { SelectAssemblyPanel } from '../select-assembly-panel';
import { SelectItemPanel } from '../select-item-panel';
import { Styled } from './styled';

enum MenuName {
  Assemblies = 'Assemblies',
  Items = 'Items',
}

interface OwnState {
  currentTab: MenuName;
  showSelectionPanel: boolean;
}

interface StateProps {
  selectedItemsIds: Record<string, boolean>;
  selectedAssembliesIds: Record<string, boolean>;
}

interface DispatchProps {
  openSidePanel: (type: MenuName) => void;
}

interface Props extends StateProps, DispatchProps {
  handleSelectAssemblies: (ids: string[]) => void;
  handleSelectItems: (ids: string[]) => void;
}

class SelectPanelComponent extends React.PureComponent<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentTab: MenuName.Assemblies,
      showSelectionPanel: false,
    };
  }
  public render(): JSX.Element {
    const menuItems = Object.values(MenuName).map(name => ({
      name,
      current: name === this.state.currentTab,
      onClick: () => this.setTab(name),
    }));
    return (
      <>
        <Styled.Navigation>
          <FlowNavigation
            items={menuItems}
            TextComponent={Text}
            borderRadius={5}
            borderOverhang={0}
            height={5}
          />
          <Styled.AddNewButtonContainer>
            <RectangleButton
              height={20}
              width={80}
              borderRadius={10}
              text={'+ New'}
              mood={'secondary'}
              onClick={this.openSidePanel}
            />
          </Styled.AddNewButtonContainer>
        </Styled.Navigation>
        {this.renderSelectPanel()}
      </>
    );
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({ showSelectionPanel: true });
    }, 450);
  }

  private renderSelectPanel(): React.ReactNode {
    switch (this.state.currentTab) {
      case MenuName.Assemblies:
        return this.renderSelectAssembly();
      case MenuName.Items:
        return this.renderSelectItems();
      default:
        return null;
    }
  }

  private renderSelectAssembly(): JSX.Element {
    return (
      <SelectAssemblyPanel
        setUsedAssembly={this.props.handleSelectAssemblies}
        selectedIdsMap={this.props.selectedAssembliesIds}
        hideEmptyEntity={true}
        autoGroupColumnMinWidth={478}
        hideTable={!this.state.showSelectionPanel}
      />
    );
  }

  private renderSelectItems(): JSX.Element {
    return (
      <SelectItemPanel
        setUsedItems={this.props.handleSelectItems}
        selectedIdsMap={this.props.selectedItemsIds}
        hideEmptyEntity={true}
        autoGroupColumnMinWidth={478}
        hideTable={!this.state.showSelectionPanel}
      />
    );
  }

  @autobind
  private openSidePanel(): void {
    this.props.openSidePanel(this.state.currentTab);
  }

  private setTab(name: MenuName): void {
    this.setState({ currentTab: name });
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openSidePanel: type =>
      dispatch(TwoDDatabaseActions.openSidePanel({ type, withSideBlock: true })),
  };
};

const mapStateToProps = (state: State): StateProps => {
  return {
    selectedAssembliesIds: state.twoD.piaMeasurePanel.selectedAssembliesIds,
    selectedItemsIds: state.twoD.piaMeasurePanel.selectedItemsIds,
  };
};

export const SelectPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectPanelComponent);
