import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import './multi-level-drop-down-menu.scss';

import { PreparedSearchQuery } from 'common/utils/string-utils';
import { MultilevelSelectOptionData } from './interfaces/multi-level-select-option-data';
import { DropDownPositioning, MultiLevelDropDown } from './multi-level-drop-down';

interface State {
  isOpen: boolean;
}

interface Props<T extends MultilevelSelectOptionData<T>> {
  value: React.ReactText | React.ReactText[];
  options: T[];
  onSelect: (value: React.ReactText | React.ReactText[]) => void;
  className?: string;
  containerClassName?: string;
  multiple?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  displayModeSelect?: boolean;
  label?: string;
  isHighlightOnSelect?: boolean;
  compare?: (option: T, query: PreparedSearchQuery) => boolean;
  optionContentRenderer?: (option: T, query: PreparedSearchQuery, isSelected: boolean) => React.ReactNode;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  onSearchQueryChange?: (searchQuery: string) => void;
  onOpen?: () => void;
  onClick?: () => void;
  dropDownPositioning?: DropDownPositioning;
  sendApi?: (api: MultiLevelDropDownMenuApi) => void;
  onOptionMouseOver?: (value: React.ReactText) => void;
  onOptionMouseOut?: (value: React.ReactText) => void;
}

export interface MultiLevelDropDownMenuApi {
  toggleOpenStatus: () => void;
}

export class MultiLevelDropDownMenu<T extends MultilevelSelectOptionData<T>>
  extends React.PureComponent<Props<T>, State> {
  private menuRef: HTMLDivElement = null;

  constructor(props: Props<T>) {
    super(props);
    if (this.props.sendApi) {
      this.props.sendApi({ toggleOpenStatus: this.toggleOpenStatus });
    }
    this.state = { isOpen: false };
  }


  public static defaultSearchComparer = (option: any, query: PreparedSearchQuery): boolean => {
    if (!query) {
      return true;
    }
    const lowerCasedName = `${option.name || ''}`.toLowerCase();
    return query.searchWords.every(x => lowerCasedName.includes(x));
  };

  public static defaultSearchQueryHighlighter = (text: string | number, searchQuery: PreparedSearchQuery): string => {
    return searchQuery && searchQuery.searchRegex
      ? text.toString().replace(searchQuery.searchRegex, matchingValue => `<b>${matchingValue}</b>`)
      : text.toString();
  };

  public componentDidUpdate(_: Props<T>, prevState: State): void {
    if (this.state.isOpen && !prevState.isOpen && this.props.onOpen) {
      this.props.onOpen();
    }
  }

  public render(): React.ReactNode {
    const { children, displayModeSelect, disabled, label, className, style, isHighlightOnSelect } = this.props;
    const isOpen = this.state.isOpen;
    return (
      <div
        style={style}
        className={classNames(
          className,
          {
            'multi-level-drop-down-menu': displayModeSelect,
            'multi-level-drop-down-menu--select': displayModeSelect,
            'multi-level-drop-down-menu--disabled': disabled,
            'multi-level-drop-down-menu--open': isHighlightOnSelect && isOpen,
          },
        )}
        data-label={label}
        onClick={this.onContainerClick}
        ref={this.saveMenuRef}
      >
        {children}
        {isOpen && this.renderOptions()}
      </div>
    );
  }

  private renderOptions(): React.ReactNode {
    const { options, value, compare, optionContentRenderer } = this.props;
    return (
      <MultiLevelDropDown
        value={value}
        containerClassName={this.props.containerClassName}
        onClose={this.onContainerClick}
        compare={compare}
        options={options}
        optionContentRenderer={optionContentRenderer}
        parentRect={this.menuRef.getBoundingClientRect()}
        onSelectClick={this.onSelect}
        displayModeSelect={this.props.displayModeSelect}
        onSearchQueryChange={this.props.onSearchQueryChange}
        onScroll={this.props.onScroll}
        dropDownPositioning={this.props.dropDownPositioning}
        onOptionMouseOut={this.props.onOptionMouseOut}
        onOptionMouseOver={this.props.onOptionMouseOver}
      />
    );
  }

  @autobind
  private onSelect(value: React.ReactText): void {
    if (!this.props.multiple) {
      this.setState({ isOpen: false });
      this.props.onSelect(value);
    } else {
      const values = this.props.value as React.ReactText [];
      const newValues = values.includes(value)
        ? values.filter(x => x !== value)
        : values.concat(value);
      this.props.onSelect(newValues);
    }
  }

  @autobind
  private onContainerClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick();
    }
    if (this.props.disabled) return null;
    this.toggleOpenStatus();
  }

  @autobind
  private toggleOpenStatus(): void {
    if (this.props.options && this.props.options.length) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  }

  @autobind
  private saveMenuRef(ref: HTMLDivElement): void {
    this.menuRef = ref;
  }
}
