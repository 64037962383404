import classNames from 'classnames';
import * as React from 'react';

import './kreo-toolbar-button.scss';

import { ElementTooltip, TooltipPosition } from '../element-tooltip';

export interface KreoToolbarButtonProps {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  active?: boolean;
  className?: string;
  controlName: string;
  text?: string;
  defaultTooltipText?: string;
  hotKey?: string;
  disableTooltip?: boolean;
  disabled?: boolean;
  disabledTooltipText?: string;
}

export class KreoToolbarButton extends React.Component<KreoToolbarButtonProps> {
  public render(): JSX.Element {
    const {
      children,
      onClick,
      text,
      defaultTooltipText,
      disabledTooltipText,
      active,
      disabled,
      disableTooltip,
      controlName,
      className,
      hotKey,
    } = this.props;
    return (
      <ElementTooltip
        className={classNames(
          'kreo-toolbar-button',
          {
            'kreo-toolbar-button--active': active,
            'kreo-toolbar-button--disabled': disabled,
          },
          className,
        )}
        size='small'
        marginTop={10}
        tooltipPosition={TooltipPosition.CenterTop}
        onClick={onClick}
        disabledClick={disabled}
        disabled={disableTooltip}
        controlName={controlName}
        targetElement={
          <>
            {text
              ? <span className='kreo-toolbar-button__text'>{text}</span>
              : children
            }
          </>
        }
      >
        <div className='kreo-toolbar-button__tooltip'>
          {disabled ? disabledTooltipText : null}
          <b>
            {defaultTooltipText}
            {hotKey ? <span>{hotKey}</span> : null}
          </b>
        </div>
      </ElementTooltip>
    );
  }
}
