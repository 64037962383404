import React, { Component } from 'react';

import './positional-dialog.scss';

type PositionalDialogType = {
  width: any,
  height: any,
  parrentHeight: any,
  parrentWidth: any,
  x: any,
  y: any,
};

class PositionalDialog extends Component<void, PositionalDialogType, void> {
  static defaultProps = {
    width: 340,
    height: 260
  }


  getStyle(): any {
    const { width, height, parrentHeight, parrentWidth, x, y } = this.props;
    const style = {
      width,
      height
    };
    if (parrentHeight === undefined || parrentWidth === undefined) {
      style.left = `calc(50% - ${width/2}px)`;
      style.top = `calc(50% - ${height/2}px)`;
    } else {
      if (y > parrentHeight / 2) {
        style.top = y - height;
      } else {
        style.top = y;
      }
      if (x > parrentWidth / 2) {
        style.left = x - width;
      } else {
        style.left = x
      }
    }

    return style;
  }

  render(): any {
    return (
    <div className="kreo-positional-dialog" style={this.getStyle()}>
      {this.props.children}
    </div>);
  }
}


export default PositionalDialog;