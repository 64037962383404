import autobind from 'autobind-decorator';
import * as React from 'react';

import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { UserMap } from 'common/interfaces/people';
import {
  ImageMessage,
  ImageMessageApi,
  KreoButton,
} from 'common/UIKit';
import { SubCommentModel } from '../../interfaces';
import { CommentText } from './comment-text';
import { Sender } from './sender';
import { SubComment } from './sub-comment';

interface Props {
  comment: SubCommentModel;
  peopleMap: UserMap;
  companyId: number;
  projectId: number;
  state: string;
  blockClassName: string;
  toEngineState: (state: any) => void;
  onAddComment: (id: number, value: any) => void;
  onCreateScreen: (api: (image: any) => void) => void;
}

export class Body extends React.Component<Props> {
  private imageApi: ImageMessageApi;
  private value: string;
  private image: any;

  public render(): React.ReactNode {
    const { comment, blockClassName } = this.props;
    let last_author = comment.authorGuid;
    let owner = this.props.peopleMap[last_author];
    return (
      <div className={`${blockClassName}__body`}>
        <img
          src={this.buildImageUri(this.props.companyId, this.props.projectId, this.props.comment.screen)}
          onClick={this.goToDialogState}
        />
        <Sender
          firstName={owner.firstName}
          lastName={owner.lastName}
          avatar={owner.avatar}
          timeStamp={comment.created}
        />
        <CommentText
          text={comment.text}
          created={undefined}
        />
        {
          comment.subComments &&
          comment.subComments.map((subComment: SubCommentModel): JSX.Element[] => {
            const ret = [];
            const newAuthor = subComment.authorGuid !== last_author;
            if (newAuthor) {
              last_author = subComment.authorGuid;
              owner = this.props.peopleMap[last_author];
              if (!owner) {
                return undefined;
              }
              ret.push(<Sender
                firstName={owner.firstName}
                lastName={owner.lastName}
                avatar={owner.avatar}
                timeStamp={subComment.created}
              />);
            }
            ret.push(
              <SubComment
                toEngineState={this.props.toEngineState}
                subComment={subComment}
                imageUri={this.buildImageUri(this.props.companyId, this.props.projectId, subComment.screen)}
                state={subComment.engineState}
                timeStamp={!newAuthor ? subComment.created : undefined}
              />);
            return ret;
          })
        }
        <div className={`${blockClassName}__message-container`}>
          <ImageMessage
            onKeyUp={this.onKeyUp}
            onChangeImage={this.onImageChange}
            getAPI={this.saveApi}
            onChange={this.onChange}
            onImage={this.onImage}
          />
        </div>
        <div className={`${blockClassName}__button-container`}>
          <KreoButton
            mode='success'
            size='medium'
            caption='Reply'
            onClick={this.onAdd}
            controlName='add-reply-button'
          />
        </div>
      </div>);
  }


  private buildImageUri = (companyId: number, projectId: number, imageUuid: string): string => {
    return imageUuid ? `/api/company/${companyId}/projects/${projectId}/comments/images/${imageUuid}` : '';
  };

  @autobind
  private onAdd(): void {
    if ((this.value && this.value !== '') || this.image !== undefined) {
      this.props.onAddComment(this.props.comment.id, { text: this.value, screen: this.image });
      this.imageApi.clear();
    }
    this.value = '';
  }

  @autobind
  private onChange(value: string): void {
    this.value = value;
  }

  @autobind
  private onImageChange(value: any): void {
    this.image = value;
  }

  @autobind
  private onKeyUp(e: React.KeyboardEvent<HTMLTextAreaElement>): void {
    if (e.keyCode === 13 && HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e)) {
      if ((this.value && this.value !== '') || this.image !== undefined) {
        this.props.onAddComment(this.props.comment.id, { text: this.value, screen: this.image });
        this.imageApi.clear();
      }
      this.value = '';
    }
  }

  @autobind
  private goToDialogState(): void {
    if (this.props.state) {
      this.props.toEngineState(JSON.parse(this.props.state));
    }
  }

  @autobind
  private saveApi(api: ImageMessageApi): void {
    this.imageApi = api;
  }

  @autobind
  private onImage(): void {
    this.props.onCreateScreen(this.imageApi.setImage);
  }
}
