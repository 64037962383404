import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TwoDActions } from '2d/actions/creators';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { TwoDViewTableConfig } from 'unit-2d-database/interfaces';
import { TwoDSwitcher } from '../switcher/switcher';

interface Props {
  handleClick: () => void;
}

export const ShowCodeSwitcher: React.FC<Props> = React.memo(({ handleClick }) => {
  const viewId = useSelector<State, string>(s => s.twoD.selectedSheetId);
  const view = useSelector<State, TwoDViewTableConfig>((s) => s.twoD.viewsConfigs[s.twoD.selectedSheetId]);
  const isView = !!view;
  const showCode = isView && view.createCodeColumn;
  const dispatch = useDispatch();
  const handleToggle = useCallback(() => {
    dispatch(TwoDActions.toggleShowCode(viewId));
    handleClick();
  }, [handleClick, dispatch, viewId]);
  return (
    <RenderIf condition={isView}>
      <TwoDSwitcher
        onToggle={handleToggle}
        isChecked={showCode}
        name={'Show codes'}
      />
    </RenderIf>

  );
});
