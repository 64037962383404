import { DatadogLogger } from 'common/environment/datadog-logger';
import { UuidUtil } from 'common/utils/uuid-utils';

interface Operation {
  args: any[];
  type?: string;
  method: (...args: any[]) => Promise<any>;
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
  key: string;
}

export class PdfHelper {
  private static _key: string;
  private static initialized: boolean = false;
  private static operations: Operation[] = [];
  private static operationInProcess: boolean = false;
  private static bufferedAfterInitializeMethods: Array<() => Promise<void>> = [];

  public static get key(): string {
    return this._key;
  }

  public static initializeClient(licenseKey: string): Promise<void> {
    DatadogLogger.log('PDfTron initializeClient');
    Core.setWorkerPath('/static/pdf/lib4/core');
    Core.enableFullPDF();
    PdfHelper._key = licenseKey;
    return Core.PDFNet.initialize(PdfHelper._key).then(this.pdfInitialized).catch((e) => console.error(e));
  }

  public static restart(): void {
    this.initialized = false;
    Core.resetWorker();
    Core.setWorkerPath('/static/pdf/lib4/core');
    Core.enableFullPDF();
    Core.PDFNet.initialize(this._key).then(this.pdfInitialized);
  }

  public static async runAfterInit<T>(
    method: (...args: any[]) => Promise<T>,
    ...args: any[]
  ): Promise<T> {
    DatadogLogger.log('PDfTron run operation', { initialized: PdfHelper.initialized });
    if (PdfHelper.initialized) {
      const result = await method(...args);
      return result;
    }

    return new Promise((resolve, reject) => {
      const operation = (): Promise<void> => {
        return method(...args)
          .then(x => {
            PdfHelper.bufferedAfterInitializeMethods = null;
            resolve(x);
          })
          .catch(x => {
            PdfHelper.bufferedAfterInitializeMethods = null;
            reject(x);
          });
      };
      PdfHelper.bufferedAfterInitializeMethods.push(operation);
    });
  }

  public static callOrSaveOperation(
    method: (...args: any[]) => Promise<any>,
    type: string,
    ...args: any[]
  ): { cancel: () => void, promise: Promise<any> } {
    const key = UuidUtil.generateUuid();
    const cancel = (): void => {
      PdfHelper.operations = PdfHelper.operations.filter(x => x.key !== key);
    };
    const promise = new Promise((resolve, reject) => {
      PdfHelper.operations.push({
        method,
        args,
        reject,
        resolve,
        type,
        key,
      });
      if (!PdfHelper.operationInProcess) {
        PdfHelper.operationInProcess = true;
        PdfHelper.runNextOperation();
      }
    });

    return { cancel, promise };
  }


  public static removeMethodsOfType(type: string): void {
    PdfHelper.operations = PdfHelper.operations.filter(x => x.type !== type);
  }

  private static async pdfInitialized(): Promise<void> {
    PdfHelper.initialized = true;
    Core.disableEmbeddedJavaScript();
    if (PdfHelper.bufferedAfterInitializeMethods.length) {
      await Promise.all(PdfHelper.bufferedAfterInitializeMethods.map(x => x()));
      PdfHelper.bufferedAfterInitializeMethods = [];
    }
    DatadogLogger.log('PDfTron initialized');
  }


  private static async callMethod(
    method: (...args: any[]) => Promise<any>,
    args: any[]): Promise<void> {
    await Core.PDFNet.beginOperation({ allowMultipleInstances: true });
    const result = await method(...args);
    await Core.PDFNet.finishOperation();
    return result;
  }

  private static runNextOperation(): void {
    if (PdfHelper.operations.length) {
      const { method, args, resolve, reject } = PdfHelper.operations.shift();
      PdfHelper.callMethod(method, args)
        .then(x => {
          PdfHelper.runNextOperation();
          resolve(x);
        }).catch((e) => {
          PdfHelper.runNextOperation();
          reject(e);
        });
    } else {
      PdfHelper.operationInProcess = false;
    }
  }
}
