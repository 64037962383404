import React from 'react';
import { useDispatch } from 'react-redux';
import { KreoDialogActions } from 'common/UIKit';
import { UPGRADE_PRO_PLAN_CONSTANTS } from '../constants';

export const useHandleCloseCallback: () => () => void = () => {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch(KreoDialogActions.closeDialog(UPGRADE_PRO_PLAN_CONSTANTS.UPGRADE_PLAN_DIALOG));
  }, []);
};
