import * as React from 'react';

export const KreoIconFullscreenOn: React.FC = () => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/20x20/Fullscreen_normal'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          // eslint-disable-next-line max-len
          d='M13.4142136,8 L15,8 C15.5522847,8 16,8.44771525 16,9 C16,9.55228475 15.5522847,10 15,10 L11,10 C10.4477153,10 10,9.55228475 10,9 L10,5 C10,4.44771525 10.4477153,4 11,4 C11.5522847,4 12,4.44771525 12,5 L12,6.58578644 L14.7928932,3.79289322 C15.1834175,3.40236893 15.8165825,3.40236893 16.2071068,3.79289322 C16.5976311,4.18341751 16.5976311,4.81658249 16.2071068,5.20710678 L13.4142136,8 Z M6.58578644,12 L5,12 C4.44771525,12 4,11.5522847 4,11 C4,10.4477153 4.44771525,10 5,10 L9,10 C9.55228475,10 10,10.4477153 10,11 L10,15 C10,15.5522847 9.55228475,16 9,16 C8.44771525,16 8,15.5522847 8,15 L8,13.4142136 L5.20710678,16.2071068 C4.81658249,16.5976311 4.18341751,16.5976311 3.79289322,16.2071068 C3.40236893,15.8165825 3.40236893,15.1834175 3.79289322,14.7928932 L6.58578644,12 Z'
          id='Combined-Shape'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
