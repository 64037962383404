import autobind from 'autobind-decorator';
import * as paper from 'paper';
import { DrawingsCanvasConstants } from 'common/components/drawings/constants';
import { ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces';
import { DrawingsGeometryEntity } from '../../drawings-geometry-entities';
import { DrawingsGeometrySelectionArea } from '../../drawings-geometry-entities/utility';
import { EventWrapper } from '../../interfaces';
import { DragCallbackConfig, DrawingsDragCallback, DrawingsDragCallbackParams } from '../drag-instances';

export interface BaseSelectionAreaHelperConfig {
  geometryRenderParamsObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  dragEventsHelper: EventWrapper<DrawingsDragCallback, DragCallbackConfig>;
}

export interface BaseSelectionAreaHelper {
  finishDrag(ctrlKey: boolean): void;
}

export class BaseSelectionAreaHelper<T extends BaseSelectionAreaHelperConfig = BaseSelectionAreaHelperConfig>
implements DrawingsGeometryEntity {
  protected _config: T;
  protected _layer: paper.Layer = new paper.Layer();
  protected _selectionArea: DrawingsGeometrySelectionArea;


  constructor(config: T) {
    this._config = config;
    this._config.geometryRenderParamsObserver.subscribe(this.updateRenderParams);
  }

  public destroy(): void {
    this.removeSelectionArea();
    this._config.geometryRenderParamsObserver.unsubscribe(this.updateRenderParams);
    this._layer.remove();
  }

  @autobind
  public startSelection(e: PaperMouseEvent): void {
    this.renderArea(e.point);
    this._config.dragEventsHelper.setCallback(this.updateSelectionArea, { defaultValidPoint: e.point });
  }


  @autobind
  protected updateRenderParams({ zoom }: DrawingsRenderParams): void {
    if (this._selectionArea) {
      this._selectionArea.strokeWidth = DrawingsCanvasConstants.lineStroke / zoom;
    }
  }

  @autobind
  protected removeSelectionArea(): void {
    if (this._selectionArea) {
      this._selectionArea.destroy();
      this._selectionArea = null;
    }
  }

  @autobind
  protected renderArea(point: paper.Point): void {
    this._selectionArea = new DrawingsGeometrySelectionArea(point, this._layer);
    const zoom = this._config.geometryRenderParamsObserver.getContext().zoom;
    this._selectionArea.strokeWidth = DrawingsCanvasConstants.lineStroke / zoom;
  }

  @autobind
  private updateSelectionArea({ point, ctrlKey, finish }: DrawingsDragCallbackParams): boolean {
    this._selectionArea.updateDragPosition(point);
    if (finish) {
      if (this.finishDrag) {
        this.finishDrag(ctrlKey);
      }
      this.removeSelectionArea();
      this._config.dragEventsHelper.setCallback(null);
    }
    return true;
  }
}
