import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataBaseTable, useGridApiState } from 'common/components/data-base-table';
import { Constants as DataBaseConstants } from 'common/components/data-base-table/constants';
import { State } from 'common/interfaces/state';
import { TwoDReportTemplateActions } from 'unit-2d-report-template/store-slice';
import { ReportTemplateInfo } from '../../interfaces';
import {
  useLoadTemplateEffect,
  useTransactionEffect,
  useExtendsContextMenuCallback,
  useHandleRowSelectedCallback,
  useFilterTemplatesMemo,
} from './hooks';

const rowsData = [];
const columns = [];
const defaultColumns = DataBaseConstants.DEFAULT_COLUMNS.filter(dc =>
  dc.field === 'createdBy'
  || dc.field === 'createdTime',
);

interface Props {
  canEdit: boolean;
  applyTemplate?: (id: string) => void;
  hideTemplateIds?: Set<number>;
}

export const InfoReportTemplateTable: React.FC<Props> = ({
  canEdit,
  hideTemplateIds,
  applyTemplate,
}) => {
  const dispatch = useDispatch();
  const templates = useSelector<State, ReportTemplateInfo[]>(s => s.twoDReportTemplate.infoTemplates);
  const [saveGridRef, gridApiState] = useGridApiState();
  const deleteTemplate = useCallback(
    (id) => dispatch(TwoDReportTemplateActions.deleteReportTemplate(Number(id))),
    [dispatch],
  );
  const loadReportTemplates = useCallback(
    () => dispatch(TwoDReportTemplateActions.loadInfoReportTemplates()),
    [dispatch],
  );
  const changeName = useCallback(
    (id, name) => dispatch(TwoDReportTemplateActions.updateReportTemplate({ id: Number(id), name })),
    [dispatch],
  );

  const extendReportTemplate = useExtendsContextMenuCallback(canEdit && deleteTemplate);

  useLoadTemplateEffect(loadReportTemplates);
  const filteredTemplates = useFilterTemplatesMemo(templates, hideTemplateIds);
  useTransactionEffect(filteredTemplates, gridApiState);
  const getRowNodeId = React.useCallback((d) => d.id, []);
  const handleSelect = useHandleRowSelectedCallback(applyTemplate);
  const handleRowClicked = applyTemplate && handleSelect;

  return (
    <DataBaseTable
      rowData={rowsData}
      columns={columns}
      defaultColumns={defaultColumns}
      onRowSelected={ConstantFunctions.doNothing}
      onRowClicked={handleRowClicked}
      selectByCheckbox={false}
      suppressRowClickSelection={false}
      autoGroupName='Name'
      onGridReady={saveGridRef}
      extendContextMenuItem={extendReportTemplate}
      onChangeElementName={changeName}
      isEditElement={canEdit}
      getRowNodeId={getRowNodeId}
    />);
};
