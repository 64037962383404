import { CodecUtils } from 'common/utils/codec-utils';
import { EnumUtils } from 'common/utils/enum-utils';

/**
 * @see DocumentStatus enum on backend
 */

export enum ProjectStatus {
  Created = 'Created',
  BimHandlesLoadedToDb = 'BimHandlesLoadedToDb',
  IsTransformingEngineMeshes = 'IsTransformingEngineMeshes',
  HasEngineMeshes = 'HasEngineMeshes',
  HasBimFullInfo = 'HasBimFullInfo',
  OnBidPricing = 'OnBidPricing',
  BidPricingCanceled = 'BidPricingCanceled',
  BidPricingError = 'BidPricingError',
  BidPricingSuccess = 'BidPricingSuccess',
}

const projectStatusSequence = [
  ProjectStatus.Created,
  ProjectStatus.BimHandlesLoadedToDb,
  ProjectStatus.IsTransformingEngineMeshes,
  ProjectStatus.HasEngineMeshes,
  ProjectStatus.HasBimFullInfo,
  ProjectStatus.OnBidPricing,
  ProjectStatus.BidPricingCanceled,
  ProjectStatus.BidPricingError,
  ProjectStatus.BidPricingSuccess,
];

export function isProjectStatusGreaterThan(
  current: ProjectStatus,
  target: ProjectStatus,
  equal: boolean = false,
): boolean {
  return EnumUtils.isGreaterThan(projectStatusSequence, current, target, equal);
}

export function isProjectStatusLessThan(
  current: ProjectStatus,
  target: ProjectStatus,
  equal: boolean = false,
): boolean {
  return EnumUtils.isLessThan(projectStatusSequence, current, target, equal);
}

export const ProjectStatusC = CodecUtils.createEnumCodec<ProjectStatus>(ProjectStatus, 'ProjectStatus');
