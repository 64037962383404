import { objectUtils } from 'common/utils/object-utils';
import { DrawingsShortInfo } from '../../interfaces';
import { PdfRenderer } from './pdf-renderer';

export class MainCanvasRenderer extends PdfRenderer {
  protected override shouldRender(requestedDrawingInfo: DrawingsShortInfo): boolean {
    const current = this._config.getCurrentDrawingInfo();
    if (!current) {
      return false;
    }
    return objectUtils.areEqualByFields(
      requestedDrawingInfo,
      current,
      ['drawingId', 'pagesCompareSettings', 'pageToCompareId'],
    );
  }
}
