import * as t from 'io-ts';

import { IsoDateStringC } from 'common/codecs/iso-date-string';
import { NumberDictionary, StringDictionary } from 'common/interfaces/dictionary';
import { LaborVariantModel, MaterialModel, PlantVariantModel } from './resources-data';

export interface UnitModel {
  id: number;
  acronym: string;
  measurement: string;
  coefficient: number;
}

export enum MeasurementType {
  Numeric = 'numeric',
  String = 'string',
}

export interface MeasurementTypeModel {
  availableConstraintOperations: string[];
}
export interface MeasurementModel {
  name: string;
  metricUnit: string;
  type: MeasurementType;
}

export interface ExtractorFunctionParameter {
  parameterExtractorFunctionId: string;
  value: number;
}

export interface ExtractorFunctionModel {
  id: string;
  name: string;
  description: string;
  className: string;
  measurement: string;
  enableForMeasurement: boolean;
  enableForConstraint: boolean;
  extractorParameters: ExtractorFunctionParameter[];
}

export interface ExtractorFunctionModelDropDown {
  value: string;
  group: string;
  description: string;
  name: string;
  withImage: string;
  defaultUnit: string;
  isSelectable: boolean;
 }

export interface ExtractorFunctionOption {
  value: string;
  name: string;
  children: ExtractorFunctionModelDropDown[];
}

export interface ExtractorFunctionParameterOption {
  id: string;
  defaultValue: number;
  unitId: number;
}

export interface ConstraintModel {
  id: number;
  extractorFunction: string;
  unitId: number;
  operation: string;
  value: number;
  stringValue: string;
}

export interface ActivityResourceVariant<T> {
  id: number;
  amount: number;
  resourceVariant: T;
}

export interface ActivityVariantResource<T> {
  id: number;
  amount: number;
  unitId: number;
  resource: T;
}

interface CodeGroupItem {
  id: number;
  code: string;
  title: string;
}

export interface CodeGroupModel {
  id: number;
  nrm1?: CodeGroupItem;
  nrm2?: CodeGroupItem;
  uniSystem?: CodeGroupItem;
  uniProduct?: CodeGroupItem;
  category?: string;
  family?: string;
  elementType?: string;
  comment?: string;
}

export enum ActivityVariantType {
  FixedCrewHours= 'fixedCrewHours',
  ResourceBasedCrewHours = 'resourceBasedCrewHours',
}

export interface MaterialBasedCrewHoursModel extends ActivityVariantResource<MaterialModel> {
  crewHours: number;
}

export interface ActivityVariantMaterialBasedCrewHoursModel  {
  primaryMaterial: MaterialBasedCrewHoursModel;
  secondaryMaterial: MaterialBasedCrewHoursModel;
  primaryPercentage: number;
}

export interface ActivityVariantModel {
  id: number;
  code: string;
  name: string;
  extractorFunction: string;
  extractorParameters: ExtractorFunctionParameter[];
  unitId: number;

  materials: Array<ActivityVariantResource<MaterialModel>>;
  constraints: ConstraintModel[];

  type: ActivityVariantType;
  crewHours?: number;
  materialBasedCrewHours: ActivityVariantMaterialBasedCrewHoursModel;
}

export interface ActivityCategory {
  id: number;
  code: string;
  title: string;
  children?: ActivityCategory[];
}

export interface ActivityModel {
  id: number;
  name: string;
  description: string;
  codeGroups: CodeGroupModel[];
  labors: Array<ActivityResourceVariant<LaborVariantModel>>;
  plants: Array<ActivityResourceVariant<PlantVariantModel>>;
  variants: ActivityVariantModel[];
}

export interface RequestFilterModel {
  skip: number;
  take?: number;
  search: string;
}

export interface ActivitiesRequestFilterModel extends RequestFilterModel {
  nrm1?: string;
  nrm2?: string;
  labours?: string[];
  plants?: string[];
  materials?: string[];
}

export interface DatabaseTableVariantModel {
  id: number;
  values: StringDictionary<string>;
}

export interface DatabaseTableRootModel {
  id: number;
  name: string;
  variants: DatabaseTableVariantModel[];
}

export interface ActivityVariantActivityAssignmentInfoModel {
  name: string;
  id: number;
  activityId: number;
  databaseId: number;
  laborIds: number[];
  plantIds: number[];
  crewHours: number;
  // for cost-estimate product
  materials: Array<ActivityVariantResource<MaterialModel>>;
  materialBasedCrewHours: ActivityVariantMaterialBasedCrewHoursModel;
}

export interface ActivityCategoryCounts {
  total: number;
  undefined: number;
  byCategoryId: NumberDictionary<number>;
}

export const DatabaseModelC = t.intersection(
  [
    t.strict ({
      id: t.number,
      name: t.string,
      ownerId: t.string,
      createdAt: IsoDateStringC,
      updatedAt: IsoDateStringC,
      isReadOnly: t.boolean,
      isDefault: t.boolean,
    }),
    t.partial ({
      vendor: t.string,
      version: t.string,
    }),
  ],
  'DatabaseModel');

export const DatabaseModelsC = t.array(DatabaseModelC);
export type DatabaseModel = t.TypeOf<typeof DatabaseModelC>;
