import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';
import { FormulaInputValidationStatus } from '../input';


const StatusColor: Record<FormulaInputValidationStatus, string> = {
  [FormulaInputValidationStatus.InProgress]: Constants.Colors.GENERAL_COLORS.gray,
  [FormulaInputValidationStatus.Ok]: Constants.Colors.GENERAL_COLORS.green,
  [FormulaInputValidationStatus.Warning]: Constants.Colors.GENERAL_COLORS.yellow,
  [FormulaInputValidationStatus.Error]: Constants.Colors.GENERAL_COLORS.red,
};

const Container = styled.div<{ status: FormulaInputValidationStatus }>`
  display: flex;
  color: ${p => StatusColor[p.status]};
`;

export const Styled = {
  Container,
};
