import { Action } from 'redux';

import { DrawingsSnappingModes } from 'common/components/drawings/enums/drawing-snapping-modes';
import { SortData } from 'common/components/drawings/interfaces/drawing-measurement-sort';
import { ProjectType } from 'common/constants/project-type';
import { SubscriptionType } from 'common/constants/subscription';
import { ActionWith } from 'common/interfaces/action-with';
import { PickOnlyFieldsOfType } from 'common/interfaces/omiters';
import { PagesFilterValue } from '../../../common/components/drawings/interfaces/drawing-filters';
import { ThemeToneInterface, ThemeType } from '../../../components/theme-provider/interfaces';
import { ProjectsView } from '../../../units/2d-projects-page/2d-projects-content/constants';
import {
  PanelPositionInfo,
  TableInnerClipboard,
  TwoDFullScreenMode,
  SplitterPaneSizePayload,
  PersistedStorageState,
  PersistedStorageStateBoolKeys,
  ThreeDSettings,
} from '../interfaces/state';
import {
  SetProjectRevisionPayload,
  SetProjectsAccessFilterPayload,
  UpdateDrawing3dValue,
  UpdateValue,
} from './payloads';
import { PersistedStorageActionTypes } from './types';

function persistSelectedCompanyId(selectedCompanyId: number): ActionWith<number> {
  return {
    type: PersistedStorageActionTypes.PERSIST_SELECTED_COMPANY_ID,
    payload: selectedCompanyId,
  };
}

function persistSelectedProjectRevisionId(
  projectId: number, revisionId: number,
): ActionWith<SetProjectRevisionPayload> {
  return {
    type: PersistedStorageActionTypes.PERSIST_SELECTED_PROJECT_REVISION_ID,
    payload: { projectId, revisionId },
  };
}

function toggleDisableShowDialog(dialogName: string): ActionWith<string> {
  return {
    type: PersistedStorageActionTypes.TOGGLE_DISABLE_SHOW_DIALOG,
    payload: dialogName,
  };
}

function toggleDisableTour(tourName: string): ActionWith<string> {
  return {
    type: PersistedStorageActionTypes.TOGGLE_DISABLE_SHOW_TOUR,
    payload: tourName,
  };
}

function dropSelectedCompanyId(): Action {
  return {
    type: PersistedStorageActionTypes.DROP_SELECTED_COMPANY_ID,
  };
}

function dismissChatCloud(): Action {
  return {
    type: PersistedStorageActionTypes.PERSIST_CHAT_CLOUD_DISMISSED,
  };
}

function toggleEngineAutoFocus(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_ENGINE_AUTO_FOCUS,
  };
}

function saveSplitterSize(spliterName: string, size: number): ActionWith<SplitterPaneSizePayload> {
  return {
    type: PersistedStorageActionTypes.SAVE_SPLITTER_SIZE,
    payload: { nameType: spliterName, value: size },
  };
}

function changeDrawingOpacity(value: number): ActionWith<number> {
  return {
    type: PersistedStorageActionTypes.SET_TWOD_DRAWING_OPACITY,
    payload: value,
  };
}

function changeDrawingSnappingToggle(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_TWOD_DRAWING_SNAPPING,
  };
}

function changeDrawingBlackAndWhiteToggle(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_TWOD_DRAWING_BLACK_AND_WHITE,
  };
}

function changeDrawingNightModeToggle(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_TWOD_DRAWING_NIGHT_MODE,
  };
}

function toggleDrawingMinimapOpened(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_TWOD_DRAWING_MINIMAP_OPENED,
  };
}

function toggleDrawingOffsetIsStroke(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_DRAWINGS_OFFSET_IS_STROKE,
  };
}

function toggleDrawingKeepMeasureName(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_DRAWING_KEEP_MEASURE_NAME,
  };
}

function toggleTwoDPagesMinified(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_PAGES_TABS_MINIFIED,
  };
}

function updateViewedReleaseNotesVersion(currentRelease: string): ActionWith<string> {
  return {
    type: PersistedStorageActionTypes.UPDATE_VIEWED_RELEASE_NOTES_VERSION,
    payload: currentRelease,
  };
}

function setTheme(theme: ThemeType): ActionWith<ThemeType> {
  return {
    type: PersistedStorageActionTypes.SET_THEME,
    payload: theme,
  };
}

function setThemeTone(theme: ThemeToneInterface): ActionWith<ThemeToneInterface> {
  return {
    type: PersistedStorageActionTypes.SET_THEME_TONE,
    payload: theme,
  };
}

function toggleQuickSearch(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_QUICK_SEARCH,
  };
}

function toggleMeasurementsSearch(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_MEASUREMENTS_SEARCH,
  };
}

function toggleAutoMoveToCell(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_AUTO_MOVE_TO_CELL,
  };
}

function set2dFullScreenMode(
  payload: { type: TwoDFullScreenMode, projectId: string | number },
): ActionWith<{ type: TwoDFullScreenMode, projectId: string | number }> {
  return {
    type: PersistedStorageActionTypes.SET_2D_FULLSCREEN_MODE,
    payload,
  };
}

function setFiltersPages(payload: PagesFilterValue): ActionWith<PagesFilterValue> {
  return {
    type: PersistedStorageActionTypes.SET_FILTERS_PAGES,
    payload,
  };
}

function setSortData(payload: SortData): ActionWith<SortData> {
  return {
    type: PersistedStorageActionTypes.SET_SORT_DATA,
    payload,
  };
}

function drawingToggleKeepOldPolygons(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_DRAWING_KEEP_OLD_POLYGON,
  };
}

function drawingsToggleKeepGroup(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_DRAWING_KEEP_GROUP,
  };
}

function setTwoDTableInnerClipboard(payload: TableInnerClipboard): ActionWith<TableInnerClipboard> {
  return {
    type: PersistedStorageActionTypes.SET_TWO_D_TABLE_INNER_CLIPBOARD,
    payload,
  };
}

function toggleProjectMenu(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_PROJECT_MENU,
  };
}

function toggleOpenDrawingsAnnotationLegendPanel(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_OPEN_DRAWINGS_ANNOTATION_LEGEND_PANEL,
  };
}

function toggleIsDemoProjectsCollapsed(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_IS_DEMO_PROJECTS_COLLAPSED,
  };
}

function toggleFoldersSection(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_FOLDERS_SECTION,
  };
}

function setDrawingSnappingModes(
  value: DrawingsSnappingModes[],
): ActionWith<DrawingsSnappingModes[]> {
  return {
    type: PersistedStorageActionTypes.SET_DRAWING_SNAPPING_MODES,
    payload: value,
  };
}

function drawingToggleAutofocus(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_DRAWINGS_AUTO_FOCUS,
  };
}


function toggleDrawingKeepStructure(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_KEEP_STRUCTURE,
  };
}

function setTwoDDataBaseColumnState(type: string, state: any[]): ActionWith<{ type: string, state: any[] }> {
  return {
    type: PersistedStorageActionTypes.SET_TWOD_DATA_BASE_COLUMNS_STATE,
    payload: {
      type,
      state,
    },
  };
}

function toggleOrthogonalMode(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_TWOD_ORTHOGONAL_MODE,
  };
}

function toggleAutocomplete(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_DRAWINGS_AUTOCOMPLETE,
  };
}

function toggleDrawingShowLabel(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_TWOD_DRAWING_SHOW_LABEL,
  };
}

function toggleHideGroups(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_HIDE_GROUPS,
  };
}

function setEntitiesPanelPosition(payload: PanelPositionInfo): ActionWith<PanelPositionInfo> {
  return {
    type: PersistedStorageActionTypes.SET_ENTITIES_PANEL_POSITION,
    payload,
  };
}

function toggleEntitiesPanel(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_ENTITIES_PANEL,
  };
}

function openEntitiesPanel(): Action {
  return {
    type: PersistedStorageActionTypes.OPEN_ENTITIES_PANEL,
  };
}

function toggleShowUnits(): Action {
  return {
    type: PersistedStorageActionTypes.SHOW_UNITS_TOGGLE,
  };
}

function toggleShowTableTotal(): Action {
  return {
    type: PersistedStorageActionTypes.SHOW_TABLE_TOTAL_TOGGLE,
  };
}

function setSelectedCurrency(currency: string): ActionWith<string> {
  return {
    type: PersistedStorageActionTypes.SET_SELECTED_CURRENCY,
    payload: currency,
  };
}

function setNewDrawingInstanceStrokeWidth(value: number): ActionWith<number> {
  return {
    type: PersistedStorageActionTypes.SET_NEW_DRAWING_INSTANCE_STROKE_WIDTH,
    payload: value,
  };
}

function setDrawingExportFontSize(value: number): ActionWith<number> {
  return {
    type: PersistedStorageActionTypes.SET_DRAWING_EXPORT_FONT_SIZE,
    payload: value,
  };
}

function setDrawingExportScaleFactor(value: number): ActionWith<number> {
  return {
    type: PersistedStorageActionTypes.SET_DRAWING_EXPORT_SCALE_FACTOR,
    payload: value,
  };
}

function setProjectsAccessFilter(
  filterName: string,
  projectType: ProjectType,
): ActionWith<SetProjectsAccessFilterPayload> {
  return {
    type: PersistedStorageActionTypes.SET_PROJECTS_ACCESS_FILTER,
    payload: { filterName, projectType },
  };
}

function setLastUsedProductType(lastUsedProductType: SubscriptionType): ActionWith<SubscriptionType> {
  return {
    type: PersistedStorageActionTypes.SET_LAST_USED_PRODUCT_TYPE,
    payload: lastUsedProductType,
  };
}

function toggleDrawingUseGroupNameForNewGeometry(): Action {
  return {
    type: PersistedStorageActionTypes.TOGGLE_DRAWING_USE_GROUP_NAME_FOR_NEW_GEOMETRY,
  };
}

function setCurrentPathname(path: string): ActionWith<string> {
  return {
    type: PersistedStorageActionTypes.SET_CURRENT_PATH_NAME,
    payload: path,
  };
}

function hideSmallScreenDisplayDialog(): Action {
  return {
    type: PersistedStorageActionTypes.HIDE_SMALL_SCREEN_DISPLAY_WARNING,
  };
}

function closeTryAiPanel(): Action {
  return {
    type: PersistedStorageActionTypes.CLOSE_TRY_AI_PANEL,
  };
}

function changeViewProjects(viewType: ProjectsView): ActionWith<ProjectsView> {
  return {
    type: PersistedStorageActionTypes.CHANGE_VIEW_PROJECTS,
    payload: viewType,
  };
}

function setDrawingThreeDSetting<T extends keyof ThreeDSettings>(
  setting: T,
  value: ThreeDSettings[T],
): ActionWith<UpdateDrawing3dValue<T>> {
  return {
    type: PersistedStorageActionTypes.SET_DRAWING_3D_SETTING,
    payload: { key: setting, value },
  };
}

function toggleDrawingThreeDSetting<T extends keyof PickOnlyFieldsOfType<ThreeDSettings, boolean>>(
  setting: T,
): ActionWith<T> {
  return {
    type: PersistedStorageActionTypes.TOGGLE_DRAWING_3D_SETTING,
    payload: setting,
  };
}

function updateValue<T extends keyof PersistedStorageState>(
  key: T, value: PersistedStorageState[T],
): ActionWith<UpdateValue<T>> {
  return {
    type: PersistedStorageActionTypes.UPDATE_VALUE,
    payload: { key, value },
  };
}

function toggleValue(
  key: PersistedStorageStateBoolKeys,
): ActionWith<PersistedStorageStateBoolKeys> {
  return {
    type: PersistedStorageActionTypes.TOGGLE_VALUE,
    payload: key,
  };
}

export const PersistedStorageActions = {
  setDrawingSnappingModes,
  persistSelectedCompanyId,
  persistSelectedProjectRevisionId,
  dropSelectedCompanyId,
  dismissChatCloud,
  toggleEngineAutoFocus,
  toggleDisableShowDialog,
  toggleDisableTour,
  updateViewedReleaseNotesVersion,
  saveSplitterSize,
  changeDrawingOpacity,
  changeDrawingSnappingToggle,
  changeDrawingBlackAndWhiteToggle,
  changeDrawingNightModeToggle,
  toggleTwoDPagesMinified,
  toggleDrawingUseGroupNameForNewGeometry,
  setTheme,
  setThemeTone,
  toggleQuickSearch,
  toggleMeasurementsSearch,
  set2dFullScreenMode,
  toggleAutoMoveToCell,
  drawingToggleKeepOldPolygons,
  drawingsToggleKeepGroup,
  drawingToggleAutofocus,
  toggleDrawingOffsetIsStroke,
  setTwoDTableInnerClipboard,
  toggleProjectMenu,
  toggleOpenDrawingsAnnotationLegendPanel,
  toggleIsDemoProjectsCollapsed,
  toggleFoldersSection,
  toggleDrawingKeepMeasureName,
  toggleDrawingMinimapOpened,
  setFiltersPages,
  toggleDrawingKeepStructure,
  setTwoDDataBaseColumnState,
  setSortData,
  toggleOrthogonalMode,
  toggleHideGroups,
  setEntitiesPanelPosition,
  toggleEntitiesPanel,
  toggleShowUnits,
  toggleDrawingShowLabel,
  setNewDrawingInstanceStrokeWidth,
  setSelectedCurrency,
  toggleAutocomplete,
  setDrawingExportFontSize,
  setDrawingExportScaleFactor,
  openEntitiesPanel,
  setProjectsAccessFilter,
  setLastUsedProductType,
  toggleShowTableTotal,
  setCurrentPathname,
  hideSmallScreenDisplayDialog,
  closeTryAiPanel,
  changeViewProjects,
  setDrawingThreeDSetting,
  toggleDrawingThreeDSetting,

  updateValue,
  toggleValue,
};
