
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Form } from 'common/components/form';
import { State } from 'common/interfaces/state';
import { TwoDReportTemplateActions } from 'unit-2d-report-template/store-slice';
import { ReportTemplateVariable } from '../../interfaces';
import { useChangeVariableCallback, useMapVariablesToFieldMemo } from './hooks';
import { Styled } from './styled';

interface StateProps {
  variables: ReportTemplateVariable[];
  projectId: number;
}

interface DispatchProps {
  changeVariables: (projectId: number, variables: ReportTemplateVariable[]) => void;
}

interface Props extends StateProps, DispatchProps {

}

const ReportTemplateVariableComponent: React.FC<Props> = ({
  variables,
  projectId,
  changeVariables,
}) => {
  const handleChangeVariable = useChangeVariableCallback(projectId, changeVariables, variables);
  const fields = useMapVariablesToFieldMemo(variables, handleChangeVariable);
  return (
    <Styled.Container>
      <Form
        fields={fields}
      />
    </Styled.Container>

  );
};

const mapStateToProps = (state: State): StateProps => {
  const { projectReportTemplates } = state.twoDReportTemplate;
  const project = state.projects.currentProject;
  const projectId = project && project.id;
  return {
    variables: projectReportTemplates.variables,
    projectId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    changeVariables: (projectId, variables) => dispatch(
      TwoDReportTemplateActions.updateReportVariables({
        projectId,
        form: variables,
      }),
    ),
  };
};

export const ReportTemplateVariableForm = connect(mapStateToProps, mapDispatchToProps)(ReportTemplateVariableComponent);
