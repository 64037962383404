import { push } from 'connected-react-router';
import { identity } from 'lodash';
import QueryString from 'query-string';
import { change } from 'redux-form';
import { EventChannel, SagaIterator } from 'redux-saga';
import { all, call, CallEffect, fork, ForkEffect, put, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { DrawingsUtils } from 'common/components/drawings/utils/drawings-utils';
import { ActionWith } from 'common/interfaces/action-with';
import { updateQuery } from 'common/routing/push-search';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { Common } from '../actions';
import { CommonActionTypes } from '../actions/common/action-types';
import { UploadFileAddPayload, UploadFilePayload } from '../actions/common/payloads';
import { CommonsApi } from '../api/common';

function* watchOnProgress(key: string, channel: EventChannel<number>): SagaIterator {
  while (true) {
    const data = yield take(channel);
    yield put(Common.fileProgress(key, data));
  }
}

function* uploadFile(
  file: File,
  form: string,
  field: string,
  promise: Promise<string[]>,
  channel: EventChannel<number>,
): SagaIterator {
  try {
    const fileKey = DrawingsUtils.getFileKey(file);
    yield fork(watchOnProgress, fileKey, channel);
    const result: string[] = yield call<(value: Promise<string[]>) => Promise<string[]>>(identity, promise);
    if (form) {
      yield put(change(form, field, result[0]));
    }
    yield put(Common.uploadFileSuccessed(fileKey, result));
  } catch (error) {
    yield put(Common.uploadFileFailed(DrawingsUtils.getFileKey(file), 'Something went wrong, please try again'));
  }
}

function* uploadFiles(action: ActionWith<UploadFilePayload>): SagaIterator {
  try {
    const { files, field, form } = action.payload;
    const uploads = new Array<CallEffect>();
    const filesWithCancelers = new Array<UploadFileAddPayload>();
    for (const file of files) {
      const [promise, channel, canceler] = CommonsApi.uploadFile(file);
      uploads.push(call(uploadFile, file, form, field, promise, channel));
      filesWithCancelers.push({ file, canceler });
    }
    yield put(Common.addFilesForUpload(filesWithCancelers));
    yield all(uploads);
  } catch (error) {
    console.error(error);
  }
}


function* getLocations(): SagaIterator {
  try {
    const data = yield call(CommonsApi.getLocations);
    yield put(Common.getLocationsSuccessed(data));
  } catch (e) {
    console.error(e);
  }
}

function* pushSearch({ payload }: ActionWith<Record<string, string>>): SagaIterator {
  try {
    const location = yield selectWrapper<any>(s => s.router.location);
    const newQuery = updateQuery(location, payload);
    yield put(push({ search: QueryString.stringify(newQuery) }));
  } catch (e) {
    console.error('Failed to push new search', e);
  }
}

export function* commonSagas(): IterableIterator<ForkEffect> {
  yield takeEvery(CommonActionTypes.UPLOAD_FILES, uploadFiles);
  yield takeLatest(CommonActionTypes.GET_LOCATIONS, getLocations);
  yield takeLatest(CommonActionTypes.PUSH_SEARCH, pushSearch);
}
