import { FeedC } from 'common/interfaces/feed';
import { AdminCompanyVmC } from './admin-company-vm';
import { AdminDatabaseVmC } from './admin-database-vm';
import { ShortUserC } from './short-user';


export const ShortUserFeedC = FeedC(ShortUserC, 'ShortUserFeed');
export const AdminCompanyVmFeedC = FeedC(AdminCompanyVmC, 'AdminCompanyVmFeed');
export const AdminDatabaseVmFeedC = FeedC(AdminDatabaseVmC, 'AdminDatabaseVmFeed');

export interface FeedParams {
  skip: number;
  search: string;
}
