import * as React from 'react';

import './date-row.scss';

import { GanttUtils } from '../../../utils/gantt-chart/gantt-utils';

interface Props {
  startDate: Date;
  endDate: Date;
}

const dateFormatOptions: Intl.DateTimeFormatOptions =  {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const DateRow: React.FC<Props> = (props) => {
  return (
    <div className='gantt-chart-timeline-date-row'>
      <div className='gantt-chart-timeline-date-row__label'>
        Start {props.startDate.toLocaleDateString('en-GB', dateFormatOptions)}
      </div>
      <div className='gantt-chart-timeline-date-row__label'>
        {GanttUtils.calculateTotalDaysDuration(props.endDate, props.startDate)} days
      </div>
      <div className='gantt-chart-timeline-date-row__label'>
        {props.endDate.toLocaleDateString('en-GB', dateFormatOptions)} End
      </div>
    </div>
  );
};
