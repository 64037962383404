import { IconButton, Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { useGlobalKeyboardEvents } from 'common/components/global-keyboard-events-controller';
import { RenderIf } from 'common/components/render-if';
import { ElementTooltip } from 'common/components/tooltip';
import { ControlNames } from 'common/constants/control-names';
import { State } from 'common/interfaces/state';
import { MultiViewSelectors } from 'common/utils/multi-view/multi-view-selector';
import { useCurrentProjectFullScreen, usePersistedStorageValueToggle } from 'persisted-storage/hooks';
import { TwoDFullScreenMode } from 'persisted-storage/interfaces/state';
import { DrawingsDrawMode } from '../../enums';
import { DrawingsViewportHelper } from '../../helpers/viewport';
import { useCurrentDrawingInfo, useDrawModeApi } from '../../hooks';
import { ZoomHotkey } from '../../utils/hotkey-utils';
import { Minimap } from './minimap';
import { Styled } from './styled';
import { useToggle3d } from './use-toggle-3d';

interface Props {
  viewportHelper: DrawingsViewportHelper;
  toggleMarqueeZoom: () => void;
  isMarqueeZoomEnabled: boolean;
  onToggleFullScreen: () => void;
  canToggleFullScreen: boolean;
}

const TwoDZoomControlComponent: React.FC<Props> = ({
  viewportHelper,
  onToggleFullScreen,
  toggleMarqueeZoom,
  isMarqueeZoomEnabled,
  canToggleFullScreen,
}) => {
  const currentDrawingInfo = useCurrentDrawingInfo();
  const isDrawingRendered = useSelector<State, boolean>(state => state.drawings.drawingRenderedStatus);
  const [fullscreenMode] = useCurrentProjectFullScreen();
  const isFullScreen = fullscreenMode === TwoDFullScreenMode.Drawings;

  const screenShotCreated = currentDrawingInfo && currentDrawingInfo.screenshotCreated;
  const mood = !isDrawingRendered ? 'disabled' : 'default';
  const [isMinimapOpened, toggleOpenMinimap] = usePersistedStorageValueToggle('drawingMinimapOpened');

  const toggle3dView = useToggle3d()[1];
  const { drawMode, setDrawMode } = useDrawModeApi();
  const toggle3d = useCallback(() => {
    setDrawMode(DrawingsDrawMode.Disabled, { afterSave: toggle3dView });
  }, [toggle3dView, drawMode, setDrawMode]);

  const keyboardEvents = useGlobalKeyboardEvents();
  const areEventsAdded = useRef(false);
  useEffect(() => {
    if (isDrawingRendered) {
      if (areEventsAdded.current) {
        return;
      }
      keyboardEvents.addKeyDownEventListener(ZoomHotkey.ZoomIn, viewportHelper.zoomIn);
      keyboardEvents.addKeyDownEventListener(ZoomHotkey.ZoomOut, viewportHelper.zoomOut);
      keyboardEvents.addKeyDownEventListener(ZoomHotkey.Home, viewportHelper.home);
      areEventsAdded.current = true;
    } else {
      keyboardEvents.removeKeyDownEventListener(ZoomHotkey.ZoomIn, viewportHelper.zoomIn);
      keyboardEvents.removeKeyDownEventListener(ZoomHotkey.ZoomOut, viewportHelper.zoomOut);
      keyboardEvents.removeKeyDownEventListener(ZoomHotkey.Home, viewportHelper.home);
      areEventsAdded.current = false;
    }
    return () => {
      keyboardEvents.removeKeyDownEventListener(ZoomHotkey.ZoomIn, viewportHelper.zoomIn);
      keyboardEvents.removeKeyDownEventListener(ZoomHotkey.ZoomOut, viewportHelper.zoomOut);
      keyboardEvents.removeKeyDownEventListener(ZoomHotkey.Home, viewportHelper.home);
      areEventsAdded.current = false;
    };
  }, [isDrawingRendered]);

  const canEdit3dMeasurements = useAbility(Operation.Update, Subject.Takeoff2dMeasurement3d);
  const showFullScreen = MultiViewSelectors.useIsMultiViewConnect();

  return (
    <Styled.Container>
      {screenShotCreated && isMinimapOpened ? (
        <Minimap currentDrawingInfo={currentDrawingInfo} viewportHelper={viewportHelper} />
      ) : null}
      <RenderIf condition={showFullScreen && canToggleFullScreen}>
        <ElementTooltip text={isFullScreen ? 'Exit Fullscreen' : 'Fullscreen'} position="top">
          <IconButton
            onClick={onToggleFullScreen}
            Icon={isFullScreen ? Icons.ExitFullscreen : Icons.FullScreen}
            height={40}
            defaultColor={isFullScreen ? 'turquoise' : 'gray'}
          />
        </ElementTooltip>
      </RenderIf>
      <ElementTooltip text={'Marquee Zoom'} position="top">
        <IconButton
          onClick={toggleMarqueeZoom}
          Icon={Icons.MarqueeZoom}
          height={40}
          defaultColor={isMarqueeZoomEnabled ? 'turquoise' : 'gray'}
          mood={mood}
        />
      </ElementTooltip>
      <ElementTooltip text={'Minimap'} position="top">
        <IconButton
          onClick={toggleOpenMinimap}
          Icon={Icons.Minimap}
          height={40}
          defaultColor={isMinimapOpened ? 'turquoise' : 'gray'}
          mood={mood}
        />
      </ElementTooltip>
      <ElementTooltip text={'Zoom In'} position="top">
        <IconButton onClick={viewportHelper.zoomIn} Icon={Icons.ZoomIn} height={40} mood={mood} />
      </ElementTooltip>
      <ElementTooltip text={'Home'} position="top">
        <IconButton onClick={viewportHelper.home} Icon={Icons.Home2D} height={40} mood={mood} />
      </ElementTooltip>
      <ElementTooltip text={'Zoom Out'} position="top">
        <IconButton onClick={viewportHelper.zoomOut} Icon={Icons.ZoomOut} height={40} mood={mood} />
      </ElementTooltip>
      <UpgradeWrapper isNeedUpdate={!canEdit3dMeasurements}>
        <ElementTooltip text={'2d'} position="top">
          <IconButton
            onClick={canEdit3dMeasurements ? toggle3d : undefined}
            Icon={Icons.ThreeDView}
            height={40}
            defaultColor={'gray'}
            controlName={ControlNames.threeDView}
          />
        </ElementTooltip>
      </UpgradeWrapper>
    </Styled.Container>
  );
};

export const TwoDZoomControl = React.memo(TwoDZoomControlComponent);
