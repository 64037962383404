import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { FaqCaption } from 'common/enums/faq-caption';
import { HeaderContext } from 'common/enums/header-context';
import { LogoType } from 'common/enums/logo-type';
import { PageHeader } from 'common/interfaces/page-header';
import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { PageLayout } from '../../../layouts/page-layout';
import { PlanProjectRouteParams } from '../../../routes/app-routes-params';
import { NotFoundPage } from '../../notfound/page';
import { ProjectsActions } from '../actions/creators/common';
import { ValidationStepsNames } from '../constants/validation-steps-names';
import { ValidationStepName } from '../enums/validation-step-name';
import { Project } from '../interfaces/project';
import { ClassificationEditPage } from '../pages/classification-edit';
import { ClassificationViewPage } from '../pages/classification-view';
import { MeasurementsEditPage } from '../pages/measurements-edit';
import { MeasurementsViewPage } from '../pages/measurements-view';
import { ValidationPage } from '../pages/validation/page';
import { ActivityAssignmentRoutes } from './activity-assignment';

interface RoutesStateProps {
  currentProject: Project | null;
  locationPathname: string;
}

interface RoutesDispatchProps {
  changeProjectName: (name: string) => void;
}

interface RoutesProps
  extends RoutesStateProps,
    RoutesDispatchProps,
    RouteComponentProps<PlanProjectRouteParams>,
    AbilityAwareProps {}

const projectExtensionHeader: PageHeader = {
  context: HeaderContext.ProjectExtension,
  color: 'white',
  logoType: LogoType.LogoBlackLight,
  disableToggle: true,
};
export const projectInnerHeader: PageHeader = {
  context: HeaderContext.Project,
  logoType: LogoType.LogoBlackLight,
  disableToggle: true,
};

export class ValidationRoutesComponent extends React.Component<RoutesProps> {
  public render(): React.ReactNode {
    const { match } = this.props;
    const indexValidationPageUrl = AppUrls.plan.project.validation.index.url({
      projectId: match.params.projectId,
    });
    return (
      <Switch>
        <PageLayout
          exact={true}
          metaTitle={`Edit ${ValidationStepsNames.classification}`}
          path={AppUrls.plan.project.validation.editClassification.path}
          component={ClassificationEditPage}
          header={{
            ...projectInnerHeader,
            backUrl: this.getBackUrl(ValidationStepName.Classification),
            title: ValidationStepsNames.classification,
            faqCaption: FaqCaption.Classification,
          }}
          subject={Subject.ValidationModelCheck}
          redirectUrl={indexValidationPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle={`View ${ValidationStepsNames.classification}`}
          path={AppUrls.plan.project.validation.viewClassification.path}
          component={ClassificationViewPage}
          header={{
            ...projectInnerHeader,
            backUrl: this.getBackUrl(ValidationStepName.Classification),
            title: ValidationStepsNames.classification,
            faqCaption: FaqCaption.Classification,
          }}
          subject={Subject.ValidationModelCheck}
          redirectUrl={indexValidationPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle={`View ${ValidationStepsNames.measurements}`}
          path={AppUrls.plan.project.validation.viewMeasurements.path}
          component={MeasurementsViewPage}
          header={{
            ...projectExtensionHeader,
            backUrl: this.getBackUrl(ValidationStepName.Measurements),
            title: ValidationStepsNames.measurements,
            faqCaption: FaqCaption.Measurements,
          }}
          subject={Subject.ValidationMeasurements}
          redirectUrl={indexValidationPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle={`Edit ${ValidationStepsNames.measurements}`}
          path={AppUrls.plan.project.validation.editMeasurements.path}
          component={MeasurementsEditPage}
          header={{
            ...projectExtensionHeader,
            backUrl: this.getBackUrl(ValidationStepName.Measurements),
            title: ValidationStepsNames.measurements,
            faqCaption: FaqCaption.Measurements,
          }}
          subject={Subject.ValidationMeasurements}
          operation={Operation.Update}
          redirectUrl={indexValidationPageUrl}
        />
        <PageLayout
          exact={true}
          metaTitle='Validation'
          path={AppUrls.plan.project.validation.step.path}
          component={ValidationPage}
          header={{
            context: HeaderContext.Project,
            backUrl: AppUrls.plan.listing.url(),
            title: this.props.currentProject.name,
            faqCaption: this.getValidationfaqCaption(),
            onEditTitle: this.props.ability.can(Operation.Update, Subject.Project)
              ? this.props.changeProjectName
              : null,
            showLeftMenu: true,
          }}
        />
        <Route path={AppUrls.plan.project.validation.activityAssignment.path}  component={ActivityAssignmentRoutes} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  @autobind
  private  getValidationfaqCaption(): FaqCaption {
    const { locationPathname } = this.props;

    if (locationPathname.indexOf(ValidationStepName.Classification) !== -1) {
      return FaqCaption.Classification;
    } else if (
      locationPathname.indexOf(ValidationStepName.ActivityAssignment) !== -1) {
      return FaqCaption.ActivityAssignment;
    } else if (
      locationPathname.indexOf(ValidationStepName.Measurements) !== -1) {
      return FaqCaption.Measurements;
    }
  }

  private getBackUrl(stepName: ValidationStepName): string {
    return AppUrls.plan.project.validation.step.url({
      projectId: this.props.match.params.projectId,
      step: stepName,
    });
  }
}

function mapStateToProps(state: State): RoutesStateProps {
  return {
    currentProject: state.projects.currentProject,
    locationPathname: state.router.location.pathname,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): RoutesDispatchProps {
  return {
    changeProjectName: (name: string) => dispatch(ProjectsActions.updateProjectName(name)),
  };
}

export const ValidationRoutes = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ValidationRoutesComponent),
);
