import styled from 'styled-components';

const ValueContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Styled = {
  ValueContainer,
};
