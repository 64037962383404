import { Text, Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { SETTING_HEIGHT } from 'unit-2d-info-panel/content/settings/styled';


const MARGIN = 10;
const SWITCH_WIDTH = 50;

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: ${SETTING_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > ${Text} {
    width: calc(100% - ${MARGIN + SWITCH_WIDTH}px);
    margin-right: ${MARGIN}px;
    font-weight: 500;
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  svg {
    fill: ${Constants.Colors.GENERAL_COLORS.red};
  }
`;

export const Styled = {
  Container,
  Icon,
};
