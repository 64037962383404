import styled from 'styled-components';

import { BackdropStyled } from 'unit-2d-database/components/side-panel/components';


const SidePanelWrapper = styled.div`
  ${BackdropStyled.Container} {
    width: 320px;
  }
`;

const Body = styled.div`
  height: calc(100% - 40px);
  overflow-y: auto;
`;

export const Styled = {
  SidePanelWrapper,
  Body,
};
