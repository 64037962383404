import { useCallback, useRef } from 'react';

export const useGetDatabaseEntitiesCallback = <T>(assbmlies: T[]): () => T[] => {
  const refEntityDatabase = useRef<T[]>([]);
  refEntityDatabase.current = assbmlies;

  return useCallback(() => {
    return refEntityDatabase.current;
  }, []);
};
