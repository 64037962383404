import React from 'react';
import { Link } from 'react-router-dom';

import './kreo-link.scss';

interface Props {
  text: string;
  navigateTo: string;
}

export const KreoLink: React.FC<Props> = (props: Props) => {
  return (
    <Link to={props.navigateTo} className='kreo-link'>{props.text}</Link>
  );
};
