import autobind from 'autobind-decorator';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { ModifyHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { DrawingsEventsController, SetDrawModeCallback } from '../../interfaces';

interface DrawingsContinueKeyListenerHelperConfig {
  api: GlobalKeyboardEventsControllerContextProps;
  changeDrawMode: SetDrawModeCallback;
}

export class DrawingsContinueKeyListenerHelper implements DrawingsEventsController {
  private _config: DrawingsContinueKeyListenerHelperConfig;

  constructor(config: DrawingsContinueKeyListenerHelperConfig) {
    this._config = config;
  }

  public activate(): void {
    const { api } = this._config;
    api.addKeyDownEventListener(ModifyHotkey.Escape, this.disableDrawMode);
    api.addKeyDownEventListener(ModifyHotkey.FinishDraw, this.disableDrawMode);
  }

  public deactivate(): void {
    const { api } = this._config;
    api.removeKeyDownEventListener(ModifyHotkey.Escape, this.disableDrawMode);
    api.removeKeyDownEventListener(ModifyHotkey.FinishDraw, this.disableDrawMode);
  }

  @autobind
  private disableDrawMode(): void {
    this._config.changeDrawMode(DrawingsDrawMode.Disabled);
  }
}
