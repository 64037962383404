const prefix = `@user-activities`;

export const ActivityActionTypes = {
  LOAD_REQUEST_BY_COMPANY_SUBSCRIPTION: `${prefix}/LOAD_REQUEST_BY_COMPANY_SUBSCRIPTION`,
  LOAD_REQUEST_BY_DOCUMENT: `${prefix}/LOAD_REQUEST_BY_DOCUMENT`,
  LOAD_SUCCEEDED: `${prefix}/LOAD_SUCCEEDED`,
  LOAD_FAIL: `${prefix}/LOAD_FAIL`,
  DELETE_FILTER_ITEM: `${prefix}/DELETE_FILTER_ITEM`,
  ADD_FILTER_ITEM: `${prefix}/ADD_FILTER_ITEM`,
  SET_TIME_INTERVAL: `${prefix}/SET_TIME_INTERVAL`,
  CLEAR_TIME_INTERVAL: `${prefix}/CLEAR_TIME_INTERVAL`,
  SET_SORTING: `${prefix}/SET_SORTING`,
};
