import styled from 'styled-components';

const Container = styled.div<{ progress: number }>`
  height: 20px;
  width: 100%;
  background: ${props => props.theme.color.background};
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    width: ${props => props.progress}%;
    height: 100%;
    background: ${props => props.theme.color.turquoise};
    position: absolute;
    border-radius: 10px;
  }

`;

const TextContainer = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  width: 100%;
  box-sizing: border-box;
`;

export const Styled = {
  Container,
  TextContainer,
};
