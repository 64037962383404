import { GanttConstants } from '../../constants/gantt';

function makeUtcDateWithoutTime(date: Date): number {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
}

function calculateTotalDaysDuration(end: Date, start: Date): number {
  const endDate = makeUtcDateWithoutTime(new Date(end.getTime() + GanttConstants.msInDay));
  const startDate =  makeUtcDateWithoutTime(start);
  return (endDate - startDate) / GanttConstants.msInDay;
}

export const GanttUtils = {
  calculateTotalDaysDuration,
};
