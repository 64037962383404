import { UnitTypes } from 'common/utils/unit-util';

export const MeasureUnit = {
  count: UnitTypes.Nr,
  length: UnitTypes.M,
  area: UnitTypes.M2,
  perimeter: UnitTypes.M,
  volume: UnitTypes.M3,
  verticalArea: UnitTypes.M2,
};

export const MeasureNames = {
  verticalArea: 'Vertical Area',
};

