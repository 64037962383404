import { Icons, Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { UPGRADE_PRO_PLAN_CONSTANTS } from '../../constants';
import { Styled } from './styled';


export const UpgradePlanDialogHeader: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.IframeContainer>
        <Icons.UpgradePro />
      </Styled.IframeContainer>
      <Styled.TextContainer>
        <Text fontSize={18}>{UPGRADE_PRO_PLAN_CONSTANTS.TEXT_HEADER}</Text>
        <Text fontSize={14}>{UPGRADE_PRO_PLAN_CONSTANTS.HEADER_DESCRIPTION}</Text>
      </Styled.TextContainer>
    </Styled.Container>
  );
};
