import { Text, Icons, ElementTooltip } from '@kreo/kreo-ui-components';
import React from 'react';
import { DrawingsSettingsSwitcherWithLabel } from './drawings-settings-switcher-with-label';
import { Styled } from './styled';

interface Props {
  toggle: () => void;
  label: string;
  checked: boolean;
  blocked: boolean;
  blockedText: string;
}

export const SettingsSwitcherWithBlock: React.FC<Props> = ({
  toggle,
  label,
  checked,
  blocked,
  blockedText,
}: Props) => {
  if (blocked) {
    return (
      <Styled.Container>
        <Text fontSize={14}>{label}</Text>
        <ElementTooltip
          text={blockedText}
          wordBreakAll={true}
          position='left'
        >
          <Styled.Icon>
            <Icons.Question/>
          </Styled.Icon>
        </ElementTooltip>
      </Styled.Container>
    );
  }
  return (
    <DrawingsSettingsSwitcherWithLabel
      checked={checked}
      label={label}
      toggle={toggle}
    />
  );
};

