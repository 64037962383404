import * as paper from 'paper';

import { DrawingsSVGIcons, getSVGImageUrl } from '../../../constants/drawings-svg-icons';

const IMAGE_MAX_SIDE_SIZE = 80;

export class DrawingsUserAnnotationImageStore {
  private icons: Record<string, paper.Item>;
  private onImageLoadedHandlers: Record<string, Array<() => void>> = {};

  constructor() {
    this.init();
  }

  public async init(): Promise<void> {
    this.icons = {};
    const loadings = Object.values(DrawingsSVGIcons).map(async (i) => {
      try {
        this.icons[i] = await this.loadImage(i);
        this.icons[i].visible = false;
        if (i in this.onImageLoadedHandlers) {
          this.onImageLoadedHandlers[i].forEach(x => x());
          delete this.onImageLoadedHandlers[i];
        }
      } catch (e) {
        console.error('image not found');
      }
    });
    await Promise.all(loadings);
  }

  public loadImage(key: DrawingsSVGIcons): Promise<paper.Item> {
    return new Promise((resolve, reject) => {
      paper.project.importSVG(getSVGImageUrl(key), {
        onLoad: (item: paper.Item) => {
          const max = Math.max(item.bounds.height, item.bounds.width);
          const scale = IMAGE_MAX_SIDE_SIZE / max;
          item.scale(scale);
          resolve(item);
        },
        onError: reject,
      });
    });
  }

  public isImageExist(key: DrawingsSVGIcons): boolean {
    return key in this.icons;
  }

  public getIcon(key: DrawingsSVGIcons): paper.Item | null {
    const icon = this.icons[key].clone();
    icon.visible = true;
    return icon;
  }

  public waitForLoadImage(key: DrawingsSVGIcons, operation: () => void): void {
    this.onImageLoadedHandlers[key] = this.onImageLoadedHandlers[key] || [];
    this.onImageLoadedHandlers[key].push(operation);
  }
}
