import { CodecUtils } from 'common/utils/codec-utils';

export enum KnownViewModel {
  QuantityTakeOff = 'QuantityTakeOff',
  QtoApproximateCostReport = 'QtoApproximateCostReport',
  ModelCheck = 'ModelCheck',
  ProjectCreating = 'ProjectCreating',
  BimProcessing = 'BimProcessing',
  Classifier = 'Classifier',
  DrawingsCopy = 'DrawingsCopy',
  DrawingsCurrentPage = 'DrawingsCurrentPage',
  ProjectScreenshot = 'ProjectScreenshot',
}

export const KnownViewModelNames = {
  [KnownViewModel.QuantityTakeOff]: 'Takeoff',
  [KnownViewModel.QtoApproximateCostReport]: 'Qto Approximate Cost Report',
  [KnownViewModel.ModelCheck]: 'Model Check',
  [KnownViewModel.ProjectCreating]: 'Project Creating',
  [KnownViewModel.BimProcessing]: 'Bim Process',
  [KnownViewModel.Classifier]: 'Info Model',
  [KnownViewModel.DrawingsCopy]: 'Drawings Copy',
};

export const KnownViewModelC = CodecUtils.createEnumCodec<KnownViewModel>(KnownViewModel, 'KnownViewModel');
