import { reset, startSubmit, stopSubmit } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { Feed } from 'common/interfaces/feed';
import { getGetErrorModelFromValidationResponse } from 'common/utils/redux-form-utils';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { NotificationActions } from '../../../units/notifications/actions';
import { AlertType } from '../../../units/notifications/alert-type';
import { DatabaseActivityActions } from '../actions/creators/database-activity';
import { DatabaseActivityListingActions } from '../actions/creators/database-activity-listing';
import {
  CreateActivityPayload,
  DeleteActivityPayload,
  DuplicateActivityPayload,
  EditActivityPayload,
  LoadActivitiesPayload,
  LoadActivityPayload,
} from '../actions/payloads/database-activity';
import { DatabaseActivityActionTypes } from '../actions/types/database-activity';
import { ActivitiesApi } from '../api/activities';
import { ActivityModel } from '../interfaces/data';
import { RestFormMapper } from '../utils/rest-form-mapper';


function* loadActivities(action: ActionWith<LoadActivitiesPayload>): SagaIterator {
  try {
    const { databaseId, filter } = action.payload;
    const result: Feed<ActivityModel> = yield call(ActivitiesApi.getActivities, databaseId, filter);

    yield put(DatabaseActivityActions.loadActivitiesResponse(result));
  } catch (error) {
    console.error('database activity: load activities failed', error, action.payload);
  }
}

function* loadActivity(action: ActionWith<LoadActivityPayload>): SagaIterator {
  try {
    const databaseId = action.payload.databaseId;
    const activityId = action.payload.activityId;
    const result = yield call(ActivitiesApi.getActivity, databaseId, activityId);
    yield put(DatabaseActivityActions.loadActivityResponse(result));
  } catch (error) {
    console.error('database activity: load activity failed', error, action.payload);
  }
}

function* createActivity(action: ActionWith<CreateActivityPayload>): SagaIterator {
  try {
    const { databaseId, model, formId } = action.payload;
    yield put(startSubmit(formId));
    try {
      const result = yield call(ActivitiesApi.createActivity, databaseId, model);
      yield put(reset(formId));
      yield put(stopSubmit(formId));
      yield put(DatabaseActivityActions.createActivityResponse(result));
      yield put(DatabaseActivityListingActions.closeEditPanel());
    } catch (error) {
      yield put(stopSubmit(formId, getGetErrorModelFromValidationResponse(error)));
    }
  } catch (error) {
    console.error('database activity: create activity failed', error, action.payload);
  }
}

function* updateActivity(action: ActionWith<EditActivityPayload>): SagaIterator {
  try {
    const { databaseId, activityId, model, formId } = action.payload;
    yield put(startSubmit(formId));
    try {
      const result = yield call(ActivitiesApi.updateActivity, databaseId, activityId, model);
      yield put(reset(formId));
      yield put(stopSubmit(formId));
      yield put(DatabaseActivityActions.updateActivityResponse(result));
      yield put(DatabaseActivityListingActions.closeEditPanel());
    } catch (error) {
      yield put(stopSubmit(formId, getGetErrorModelFromValidationResponse(error)));
    }
  } catch (error) {
    console.error('database activity: update activity failed', error, action.payload);
  }
}

function* deleteActivity(action: ActionWith<DeleteActivityPayload>): SagaIterator {
  try {
    const { databaseId, activityId } = action.payload;
    const result = yield call(ActivitiesApi.deleteActivity, databaseId, activityId);
    yield put(DatabaseActivityActions.deleteActivityResponse(result));
  } catch (error) {
    console.error('database activity: delete activity failed', error, action.payload);
  }
}

function* duplicateActivity(action: ActionWith<DuplicateActivityPayload>): SagaIterator {
  try {
    const { databaseId, activityId } = action.payload;
    const activity = yield call(ActivitiesApi.getActivity, databaseId, activityId);
    const functions = yield selectWrapper(x => x.database.currentDatabase.functions);
    const form = RestFormMapper.GetActivityForm(activity, functions);
    form.codeGroups = form.codeGroups.map(x => ({ ...x, id: 0 }));
    form.name = `Copy of ${form.name}`;
    form.labors = form.labors.map(x => ({ ...x, id: 0 }));
    form.plants = form.plants.map(x => ({ ...x, id: 0 }));
    form.variants = form.variants.map(variant => ({
      ...variant,
      id: 0,
      constraints: variant.constraints.map(x => ({ ...x, id: 0 })),
      materials: variant.materials.map(x => ({ ...x, id: 0 })),
    }));

    try {
      const result = yield call(ActivitiesApi.createActivity, databaseId, form);
      yield put(DatabaseActivityActions.createActivityResponse(result));
    } catch (error) {
      yield put(NotificationActions.addAlert({ message: `Can't create ${form.name}`, alertType: AlertType.Error }));
    }

  } catch (error) {
    console.error('database activity: duplicate activity failed', error, action.payload);
  }
}

export function* databaseActivitySagas(): SagaIterator {
  yield takeLatest(DatabaseActivityActionTypes.GET_ACTIVITIES_REQUEST, loadActivities);
  yield takeLatest(DatabaseActivityActionTypes.GET_ACTIVITY_REQUEST, loadActivity);
  yield takeLatest(DatabaseActivityActionTypes.CREATE_ACTIVITY_REQUEST, createActivity);
  yield takeLatest(DatabaseActivityActionTypes.UPDATE_ACTIVITY_REQUEST, updateActivity);
  yield takeLatest(DatabaseActivityActionTypes.DELETE_ACTIVITY_REQUEST, deleteActivity);
  yield takeLatest(DatabaseActivityActionTypes.DUPLICATE_ACTIVITY, duplicateActivity);
}

