import autobind from 'autobind-decorator';
import * as React from 'react';

// eslint-disable-next-line import/named
import { ChartRangeHoverEvent } from '../../../../../components/engine/KreoEngine';
import { KreoChart, KreoChartMode } from '../../../../../components/kreo-chart';
import { SingleChartColors } from '../../../../../components/kreo-chart/kreo-chart';
import {
  ChartDataProvider,
  ResourceType,
} from '../../../utils/gantt-chart';
import { datesFormatter } from '../../four-d-visualisation/dates-formatter';
import { ganttChartContants } from '../utils/constants';

interface Props {
  resourceType: ResourceType;
  dataProvider: ChartDataProvider;
  selectedResourceId: number;
  chartWidth: number;
  projectStartDate: Date;
}


class ResourcesChartBody extends React.Component<Props> {
  private readonly colors: SingleChartColors = { main: '#44B8A1', off: '#BAE8DE' };


  public render(): React.ReactNode {
    const chartWidth = this.props.chartWidth - ganttChartContants.leftPanelWidth - 1;
    const chartData = this.props.dataProvider.calculateTimeboundChart(
      this.props.resourceType,
      this.props.selectedResourceId,
      chartWidth,
    );

    return (
      <div
        className='gantt-resources-chart-container__chart__body'
      >
        <KreoChart
          id='labours-timeline'
          backgroundColor='#fff'
          colors={this.colors}
          data={chartData}
          mode={KreoChartMode.Single}
          height={66}
          width={chartWidth}
          isAvailable={true}
          renderLegend={true}
          renderTooltip={this.renderTooltip}
        />
      </div>
    );
  }

  @autobind
  private renderTooltip(event: ChartRangeHoverEvent): React.ReactNode {
    if (!event) {
      return;
    }

    const data = this.props.dataProvider.calculateResourceConsumptionForTimerange(
      this.props.resourceType,
      this.props.selectedResourceId,
      event.startDay,
      event.endDay,
    );

    let datesString;
    const itemDuration = event.endDay - event.startDay;
    if (itemDuration <= 1) {
      datesString = datesFormatter.formatFullDate(event.startDay + itemDuration / 2, this.props.projectStartDate);
    } else {
      const dates = datesFormatter.formatDateSpan(
        event.startDay,
        event.endDay - 0.5,
        this.props.projectStartDate,
      );
      datesString = `${dates.start} - ${dates.end}`;
    }
    const hours = data.amount * 24;
    const fractionDigits = Math.abs(hours - Math.round(hours)) < 0.05 ? 0 : 1;
    return (
      <div>
        <b>{datesString}</b>
        <div>
          Max: {Math.round(data.max)}
          <br />
          {this.props.selectedResourceId !== -7  && `Hours: ${hours.toFixed(fractionDigits)}`}
        </div>
      </div>
    );
  }
}

export { ResourcesChartBody };
