import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './edit-activity-group-area.scss';

import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { EngineActions } from '../../actions';
import { UngroupedCategoryId } from '../../constants';
import { BimElementIdsHelper } from '../../utils';
import { Activities } from '../activities';
import { ActivityGroups } from '../activity-groups';
import { WorkPackages } from '../work-pages-list';

interface ReduxProps {
  activitiesLoadStatus: RequestStatus;
  activeWorkPackageId: number | null;
  activeActivityGroupId: number | null;
  activeCategoryId: number | null;
}

interface ReduxActions {
  setHighlightedBimElements: (bimIds: number[]) => void;
  setAvailableBimElements: (bimIds: number[]) => void;
  setDefaultAvailableBimElements: (bimIds: number[]) => void;
  resetEngine: () => void;
}

interface Props extends ReduxProps, ReduxActions {
  projectId: number;
  scenarioId: number;
}

class EditActivityGroupAreaComponent extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.resetEngine();
    const availableBimIds = this.getAvailableBimIds();
    this.props.setAvailableBimElements(availableBimIds);
    this.props.setDefaultAvailableBimElements(availableBimIds);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.activeWorkPackageId !== this.props.activeWorkPackageId ||
      prevProps.activeCategoryId !== this.props.activeCategoryId ||
      prevProps.activeActivityGroupId !== this.props.activeActivityGroupId
    ) {
      const availableBimIds = this.getAvailableBimIds();
      this.props.setAvailableBimElements(availableBimIds);
      this.props.setDefaultAvailableBimElements(availableBimIds);
    }
  }

  public render(): JSX.Element {
    return (
        <div className='edit-activity-group-area'>
        {
          !this.isVisibleActivityGroups() ? (
            <WorkPackages scenarioId={this.props.scenarioId}/>
          ) : null
        }
        {
          this.isVisibleActivityGroups() ? (
            <ActivityGroups onHover={this.onHover} scenarioId={this.props.scenarioId}/>
          ) : null
        }
        {
          this.isVisibleActivities() ? (
            <Activities
              isGrouped={true}
              onHover={this.onHover}
            />
          ) : null
        }
        {
          this.isVisibleUngroupedActivities() ? (
            <Activities
              isGrouped={false}
              onHover={this.onHover}
            />
          ) : null
        }
      </div>
    );
  }

  @autobind
  private onHover(ids: number[]): void {
    this.props.setHighlightedBimElements(ids);
  }

  @autobind
  private isVisibleActivities(): boolean {
    const { activeWorkPackageId, activeActivityGroupId, activeCategoryId  } = this.props;
    return  Number.isInteger(activeWorkPackageId)
      && Number.isInteger(activeActivityGroupId)
      && Number.isInteger(activeCategoryId)
      && activeCategoryId !== UngroupedCategoryId;
  }

  @autobind
  private isVisibleUngroupedActivities(): boolean {
    const { activeWorkPackageId, activeCategoryId  } = this.props;
    return  Number.isInteger(activeWorkPackageId) && activeCategoryId === UngroupedCategoryId;
  }

  @autobind
  private isVisibleActivityGroups(): boolean {
    return  Number.isInteger(this.props.activeWorkPackageId);
  }

  private getDefaultAvailableBimIds(): number[] | null {
    if (Number.isInteger(this.props.activeWorkPackageId)) {
      return BimElementIdsHelper.getWorkPackageBimIds(this.props.activeWorkPackageId);
    }

    return null;
  }

  private getAvailableBimIds(): number[] | null {
    const props = this.props;
    if (props.activeCategoryId === UngroupedCategoryId) {
      return BimElementIdsHelper.getUngroupedActivitiesBimIds(props.activeWorkPackageId);
    }

    if (Number.isInteger(props.activeActivityGroupId)) {
      return BimElementIdsHelper.getActivityGroupBimIds(props.activeActivityGroupId);
    }

    return this.getDefaultAvailableBimIds();
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    activitiesLoadStatus: state.activityGrouping.statuses.activityGrouping,
    activeWorkPackageId: state.activityGrouping.activeWorkPackageId,
    activeActivityGroupId: state.activityGrouping.activeActivityGroupId,
    activeCategoryId: state.activityGrouping.activeCategoryId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setHighlightedBimElements: ids => dispatch(EngineActions.setHighlightedBimElements(ids)),
    setAvailableBimElements: ids  => dispatch(EngineActions.setAvailableBimElements(ids)),
    setDefaultAvailableBimElements: bimIds => dispatch(EngineActions.setDefaultAvailableBimElements(bimIds)),
    resetEngine: () => dispatch(EngineActions.resetEngine()),
  };
};


const connector = connect(mapStateToProps, mapDispatchToProps);

export const EditActivityGroupsArea = connector(EditActivityGroupAreaComponent);
