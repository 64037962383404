import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { UndoRedoContextApiProps } from 'common/undo-redo';
import { withUndoRedoApiProps } from 'common/undo-redo/with-undo-redo-api-props';
import { DrawingsAnnotationLegendActions } from '../../actions/creators/drawings-annotation-legend';
import { DrawingAnnotationUtils } from '../../utils/drawing-annotation-utils';
import { DrawingsUndoRedoHelper } from '../../utils/drawings-undo-redo-helper';

export interface DrawingsSelectionContextProviderProps {
  onInstancesSelect: (instancesIds: string[]) => void;
  onSegmentSelect: (segmentPoints: [string, string]) => void;
}

interface StateProps {
  selectedInstances: string[];
}

interface DispatchProps {
  selectInstances: (instancesIds: string[]) => void;
}

interface Props extends DrawingsSelectionContextProviderProps, StateProps, DispatchProps, UndoRedoContextApiProps {

}

export interface DrawingsSelectionContextProps {
  selectInstances: (instancesIds: string[]) => void;
  selectSegment: (segmentId: string) => void;
}

export const DrawingsSelectionContext = React.createContext<DrawingsSelectionContextProps>(null);

class DrawingsSelectionContextProviderComponent extends React.PureComponent<Props> {
  private contextApi: DrawingsSelectionContextProps = {
    selectInstances: this.selectInstances,
    selectSegment: this.selectSegment,
  };

  public render(): React.ReactNode {
    return (
      <DrawingsSelectionContext.Provider value={this.contextApi}>
        {this.props.children}
      </DrawingsSelectionContext.Provider>
    );
  }

  @autobind
  private selectInstances(instancesIds: string[]): void {
    const { selectedInstances, selectInstances } = this.props;
    if (!selectedInstances.length && !instancesIds.length) {
      return;
    }
    const { undo, redo } =
      DrawingsUndoRedoHelper.createSelectUndoRedo(instancesIds, selectedInstances, selectInstances);
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private selectSegment(segmentId: string): void {
    this.props.onSegmentSelect(DrawingAnnotationUtils.getPointsIdsFromLineKey(segmentId));
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    selectedInstances: state.drawings.selectedInstances,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<AnyAction>,
  ownProps: DrawingsSelectionContextProviderProps,
): DispatchProps {
  return {
    selectInstances: instanceIds => {
      if (ownProps.onInstancesSelect) {
        ownProps.onInstancesSelect(instanceIds);
      }
      dispatch(DrawingsAnnotationLegendActions.updateSelection({ instanceIds }));
    },
  };
}

export const DrawingsSelectionContextProvider =
  connect(mapStateToProps, mapDispatchToProps)(withUndoRedoApiProps(DrawingsSelectionContextProviderComponent));
