import { Text } from '@kreo/kreo-ui-components';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

import { Mood } from 'common/enums/mood';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { ConfirmationDialog } from '../../../../../components/dialog/confirmation-dialog';
import { getRemainingDays } from './get-remaining-days';
import { Option } from './option';
import { Styled } from './styled';

export const CONFIRM_DELETE_SUBSCRIPTION_ACTIVE_DIALOG = 'CONFIRM_DELETE_SUBSCRIPTION_ACTIVE_DIALOG';

interface Props {
  nextBilling: string;
  onCancelSubscriptionClick?: () => void;
  handleBookDemo: () => void;
  handleWriteUs: () => void;
  onCancel: () => void;
}

interface OptionParam {
  name: string;
  description: string;
  icon: string;
  buttonText: string;
  handleClick: () => void;
  targetRef?: string;
}

const getOptionList = (
  handleBookDemo: () => void,
  handleWriteUs: () => void,
): OptionParam[]  => [
  {
    name: 'Contact Us',
    description: `If you have any question or concerns, please don't hesitate to write to us`,
    icon: 'contact-us',
    buttonText: 'Contact Support',
    handleClick: handleWriteUs,
  },
  {
    name: 'Book a Demo',
    description: `Our expert team will guide you through the features and answer any question`,
    icon: 'book-demo',
    buttonText: 'Book a Demo',
    targetRef: 'https://www.kreo.net/book-a-demo',
    handleClick: handleBookDemo,
  },
];

export const CancelSubscriptionActiveDialog: React.FC<Props> = ({
  nextBilling,
  onCancelSubscriptionClick,
  handleBookDemo,
  handleWriteUs,
  onCancel,
}) => {
  const { sendEvent } = useAnalytics();
  const countRemainingDays = getRemainingDays(nextBilling);
  const title = `${countRemainingDays} of access remaining: confirm cancellation`;
  const optionList = useMemo(() => getOptionList(handleBookDemo, handleWriteUs), [handleBookDemo, handleWriteUs]);

  const handleClose = useCallback(() => {
    sendEvent(MetricNames.trial.cancelledWarningWindowOpen);
    onCancel();
  }, [onCancel, sendEvent]);

  return (
    <ConfirmationDialog
      name={CONFIRM_DELETE_SUBSCRIPTION_ACTIVE_DIALOG}
      title={title}
      text={''}
      confirmButtonText="Yes, Cancel"
      submitButtonMood={Mood.Default}
      cancelButtonMood={Mood.Positive}
      cancelButtonText="No, Go back!"
      onConfirm={onCancelSubscriptionClick}
      onCancel={handleClose}
      ExtraStyled={Styled.Container}
      className={'cancel-subscription-active-dialog'}
    >
      <>
        <Text
          color={'gray'}
          fontSize={12}
        >
          By cancelling your subscription, you will forfeit access to the app,
          which would have continued until <b>{moment(nextBilling).format('MMMM Do YYYY')}. </b>
          Are you sure you want to terminate your product usage prematurely?
        </Text>
        {optionList.map(o => {
          return (
            <Option
              key={o.name}
              name={o.name}
              description={o.description}
              icon={o.icon}
              buttonText={o.buttonText}
              targetRef={o.targetRef}
              handleClick={o.handleClick}
            />
          );
        })}
      </>
    </ConfirmationDialog>
  );
};
