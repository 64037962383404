import autobind from 'autobind-decorator';
import * as React from 'react';

import './index.scss';

import { KreoScrollbars } from 'common//UIKit/scrollbars/kreo-scrollbars';
import { UserMap } from 'common/interfaces/people';
import { TopCardPanel } from 'common/UIKit';
import { CommentModel, CommentStatus } from '../../interfaces';
import { TopPanelContent } from '../top-panel-content';
import { Body } from './body';


interface Props {
  comment: CommentModel;
  user: string;
  engineMove: boolean;
  companyId: number;
  projectId: number;
  peopleMap: UserMap;
  onCreateScreen: (imageApi: (image: any) => void) => void;
  onBackClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onAddComment: (id: number, value: any, parent: number) => void;
  onRemoveComment: (id: any) => void;
  onResolve: (comment: CommentModel, value: boolean) => void;
  onUpdate: () => void;
  toEngineState: (state: any) => void;
}

export class Comment extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Props): boolean {
    if (nextProps.engineMove) {
      this.props.onUpdate();
      return false;
    }
    return true;
  }

  public render(): React.ReactNode {
    return (
      <div className='viewer-comment'>
        <TopCardPanel
          back={true}
          onBackClick={this.props.onBackClick}
          controlNameButtonBack='comments-back-button'
        >
          <TopPanelContent
            commentNumber={this.props.comment.pointSerialNumber}
            canChange={this.props.comment.owner === this.props.user}
            resolved={this.props.comment.status === CommentStatus.Resolved}
            onResolve={this.onResolve}
            remove={this.remove}
          />
        </TopCardPanel>
        <div className='viewer-comment__subcomments-container'>
          <KreoScrollbars>
            <Body
              blockClassName='viewer-comment'
              peopleMap={this.props.peopleMap}
              comment={this.props.comment.data}
              state={this.props.comment.engineState}
              companyId={this.props.companyId}
              projectId={this.props.projectId}
              onCreateScreen={this.props.onCreateScreen}
              toEngineState={this.props.toEngineState}
              onAddComment={this.onAddComment}
            />
          </KreoScrollbars>
        </div>
      </div>
    );
  }

  @autobind
  private onResolve(value: boolean): void {
    this.props.onResolve(this.props.comment, value);
  }

  @autobind
  private remove(_e: React.MouseEvent<HTMLDivElement>): void {
    this.props.onRemoveComment(this.props.comment.id);
  }

  @autobind
  private onAddComment(id: number, value: any): void {
    this.props.onAddComment(id, value, this.props.comment.id);
  }
}
