import autobind from 'autobind-decorator';
import * as paper from 'paper';
import { DrawingsApi } from 'common/components/drawings/api/drawings-api';
import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import {
  DrawingsPolygonGeometry,
  DrawingsPolylineGeometry,
  WizzardToolsState,
} from 'common/components/drawings/interfaces';
import {
  PdfGeometryResponse,
  PdfGeometryStatus,
} from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { CursorHintType } from 'common/components/drawings/layout-components/drawings-cursor-hint';
import { DrawingAnnotationUtils } from 'common/components/drawings/utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { PaperIntersectionUtils } from 'common/components/drawings/utils/paper-utils';
import { arrayUtils } from 'common/utils/array-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { UuidUtil } from 'common/utils/uuid-utils';
import { DrawingsCursorTypeHelper } from '../../drawings-helpers';
import {
  SetCursorHintCallback,
} from '../../interfaces';
import { PolylineThickness } from '../utility/polyline-thickness';
import { FinishedDrawingElement, TryAddPointConfig } from './drawings-geometry-temp-entity';
import {
  DrawingsGeometryTempEntityWithStroke,
  DrawingsGeometryTempEntityWithStrokeConfig,
} from './drawings-geometry-temp-entity-with-stroke';


enum ConnectionType {
  StartStart,
  StartEnd,
  EndStart,
  EndEnd,
  Undefined,
}


export class Segment extends paper.Segment {
  private _id: string;

  constructor(...args: any[]) {
    super(...args);
    this._id = UuidUtil.generateUuid();
  }

  public get id(): string {
    return this._id;
  }
}

interface NearestPoint {
  point: paper.Point;
  addToEnd: boolean;
  shouldReverse: boolean;
  newSegments: Segment[];
  distance: number;
  shouldRemoveLastPoint?: boolean;
}

/** настройки будут нужны какое-то время */
(window as any).INTERSECTION_THRESHOLD = 10;
(window as any).WITH_POLYLINE_CONTINUE = true;
(window as any).WITH_INTERSECTION = true;
(window as any).WITH_MAGNET = true;

interface Config extends DrawingsGeometryTempEntityWithStrokeConfig{
  setCursorMessage: SetCursorHintCallback;
  onEnableContextMenu: (canClear: boolean, canComplete) => void;
  onFinish: () => void;
  cursorTypeHelper: DrawingsCursorTypeHelper;
  wizzardSettingsObserver: ContextObserver<WizzardToolsState>;
}

export class WizzardPolyline extends DrawingsGeometryTempEntityWithStroke<Config> {
  private _addedSegments: Segment[][] = [];
  private _segmentsSnapshot: Segment[][] = [];
  private _segmentPoints: paper.Point[] = [];
  private _isProcessing = false;
  private _hideMessageExecutor: DeferredExecutor = new DeferredExecutor(3000);
  private _backColorToNormalExecutor: DeferredExecutor = new DeferredExecutor(3000);
  private _destroyed = false;

  private _thicknessShape: PolylineThickness;

  constructor(config: Config) {
    super(config);
    this._config.wizzardSettingsObserver.subscribe(this.onSettingsChanged);
    this._config.newDrawingStylesObserver.subscribe(this.renderThickness);
  }

  public override convert(): FinishedDrawingElement[] {
    const { enclose, connect } = this._config.wizzardSettingsObserver.getContext();
    const isPolygon = enclose && connect;
    const instanceType = isPolygon ? DrawingsInstanceType.Polygon : DrawingsInstanceType.Polyline;
    if (isPolygon) {
      const pathClone = this.clonePath();
      pathClone.addSegments([pathClone.firstSegment]);
      const isSelfIntersected = DrawingsPaperUtils.isPolygonSelfIntersected(pathClone);
      pathClone.remove();
      this._path.strokeColor = DrawingsCanvasColors.warningStrokeColor;
      this._path.fillColor = DrawingsCanvasColors.warningFillColor;
      this._backColorToNormalExecutor.execute(() => {
        this._path.strokeColor = this._config.color.stroke;
        this._path.fillColor = this._config.color.fill;
      });
      if (isSelfIntersected) {
        return null;
      }
    }
    const converted = super.convert(true, instanceType);
    if (isPolygon) {
      const { polygonHeight } = this._config.newDrawingStylesObserver.getContext();
      converted.forEach(x => {
        (x.geometry as DrawingsPolygonGeometry).height = polygonHeight;
      });
    } else {
      const { polylineHeight, polylineThickness } = this._config.newDrawingStylesObserver.getContext();
      converted.forEach(x => {
        (x.geometry as DrawingsPolylineGeometry).height = polylineHeight;
        (x.geometry as DrawingsPolylineGeometry).thickness = polylineThickness;
      });
    }
    this._addedSegments = [];
    this._segmentsSnapshot = [];
    this._segmentPoints = [];
    this.removeThickness();
    return converted;
  }

  public override tryAddPoint(point: paper.Point, { mousePoint }: TryAddPointConfig): boolean {
    if (this._isProcessing) {
      return false;
    }
    const { pdfId, drawingId } = this._config.getDrawingInfo();
    this._isProcessing = true;
    this.showMessage(CursorHintType.WizzardPolylineInProgress);
    this._config.cursorTypeHelper.loading = true;
    DrawingsApi.processPdfClick(pdfId, drawingId, {
      position: [point.x, point.y],
      filled: false,
      sameStyle: false,
      sameGeometry: false,
      allowRotation: false,
      allowFlipping: false,
      findText: false,
      smoothAngle: 150,
    }).then((result) => {
      this._isProcessing = false;
      this._config.cursorTypeHelper.loading = false;
      if (this._destroyed) {
        return;
      }
      if (result.status !== PdfGeometryStatus.Succcess) {

        this._config.setCursorMessage(CursorHintType.WizzardPolylineNotFound);
        this._hideMessageExecutor.execute(() => {
          this._config.setCursorMessage(null);
        });
      } else {
        this._backColorToNormalExecutor.reset();
        this._config.setCursorMessage(null);
        this.processAddSegment(result, mousePoint);
      }
    });
    return true;
  }

  public override get hasPoint(): boolean {
    return this._path.segments.length > 0;
  }

  public override destroy(): void {
    super.destroy();
    this._addedSegments = null;
    this._segmentPoints = null;
    this._config.setCursorMessage(null);
    this._destroyed = true;
    this._config.cursorTypeHelper.loading = false;
    this._config.wizzardSettingsObserver.unsubscribe(this.onSettingsChanged);
    this.removeThickness();
    this._config.newDrawingStylesObserver.unsubscribe(this.renderThickness);
  }

  public override removeLastPoint(): null {
    this._addedSegments.pop();
    this._segmentsSnapshot.pop();
    this._path.removeSegments();
    if (this._segmentsSnapshot.length !== 0) {
      this._path.addSegments(this._segmentsSnapshot[this._segmentsSnapshot.length - 1]);
      this.validateIfClosed();
    }
    this.renderThickness();
    return null;
  }

  public canComplete(): boolean {
    const { connect, enclose } = this._config.wizzardSettingsObserver.getContext();
    if (!enclose || !connect) {
      return this._path.segments.length > 1;
    }
    if (this._path.segments.length < 3) {
      return false;
    }
    const path = this.clonePath();
    path.addSegments([path.firstSegment]);
    const isSelfIntersected = DrawingsPaperUtils.isPolygonSelfIntersected(path);
    path.remove();
    if (isSelfIntersected) {
      this._path.strokeColor = DrawingsCanvasColors.warningStrokeColor;
      this._path.fillColor = DrawingsCanvasColors.warningFillColor;
      this._backColorToNormalExecutor.execute(() => {
        this._path.strokeColor = this._config.color.stroke;
        this._path.fillColor = this._config.color.fill;
      });
    }
    return !isSelfIntersected;
  }

  public override updateTempPointPosition(point: paper.Point, _strictAngle?: boolean): paper.Point {
    return point;
  }

  @autobind
  private processAddSegment({ geometries: [payload] }: PdfGeometryResponse, point: paper.Point): void {
    if (!payload) {
      return;
    }
    let newSegments = payload.points.map((x) => new Segment(new paper.Point(x)));
    this._segmentPoints.push(point);
    if (!this._path.segments.length) {
      this._addedSegments.push(newSegments);
      this._path.addSegments(newSegments);
      if (!this._config.wizzardSettingsObserver.getContext().connect) {
        this._config.onFinish();
      } else {
        this._config.onEnableContextMenu(true, this.canComplete());
      }
    } else {
      const intersection = (window as any).WITH_INTERSECTION
        ? this.getIntersectionWithLastAddedSegments(newSegments)
        : null;
      if (intersection) {
        newSegments = this.processIntersecion(point, intersection, newSegments, intersection.point);
      } else {
        const nearbyToIntersect = (window as any).WITH_MAGNET
          ? this.getNearbyToIntersect(newSegments, point)
          : null;
        if (nearbyToIntersect) {
          newSegments = this.processNearestIntersection(nearbyToIntersect);
        } else {
          const connectionType = this.getConnectionType(newSegments);
          this.addSegments(newSegments, connectionType);
        }
      }
      this._addedSegments.push(newSegments);
      this._config.onEnableContextMenu(this.hasPoint, this.canComplete());
    }
    this._segmentsSnapshot.push(this._path.segments.slice() as Segment[]);
    this.validateIfClosed();
    this.saveIfClosed();
    this.renderThickness();
  }

  private unifySegments(targetSegments: Segment[]): Segment[] {
    const segments = [];
    for (const segment of targetSegments) {
      const lastSegment = segments[segments.length - 1];
      if (lastSegment && lastSegment.point.equals(segment.point)) {
        continue;
      }
      segments.push(segment);
    }
    return segments;
  }

  private validateIfClosed(): void {
    const { enclose, connect } = this._config.wizzardSettingsObserver.getContext();
    if (!enclose || !connect || this._path.length < 3) {
      return;
    }
    const clone = this.clonePath();
    const isValid = !DrawingsPaperUtils.isPolygonSelfIntersected(clone);
    this._backColorToNormalExecutor.reset();
    if (!isValid) {
      this._path.strokeColor = DrawingsCanvasColors.warningStrokeColor;
      this._path.fillColor = DrawingsCanvasColors.warningFillColor;
      this._config.onEnableContextMenu(this.hasPoint, false);
    } else {
      this._path.strokeColor = this._config.color.stroke;
      this._path.fillColor = this._config.color.fill;
      this._config.onEnableContextMenu(this.hasPoint, true);
    }
  }

  private saveIfClosed(): void {
    const { enclose, connect } = this._config.wizzardSettingsObserver.getContext();
    if (!enclose || !connect) {
      return;
    }
    const unifiedSegments = this.unifySegments(this._path.segments as Segment[]);
    if (unifiedSegments.length < 4) {
      return;
    }
    const pathCopy = new paper.Path(unifiedSegments);

    const linesIntersetion = PaperIntersectionUtils.getLinesIntersection(
      this._path.firstSegment.point,
      this._path.firstSegment.next.point,
      this._path.lastSegment.point,
      this._path.lastSegment.previous.point,
    );
    if (linesIntersetion) {
      pathCopy.removeSegment(0);
      pathCopy.insertSegments(0, [new Segment(linesIntersetion)]);
      pathCopy.removeSegment(pathCopy.segments.length - 1);
      pathCopy.addSegments([new Segment(linesIntersetion)]);
    } else {
      const nearbyIntersection = this.getNearbyIntersectionBetweenEnds();
      if (nearbyIntersection) {
        const { point, fromEnd } = nearbyIntersection;
        if (fromEnd) {
          pathCopy.removeSegment(0);
          pathCopy.insertSegments(0, [new Segment(point)]);
        } else {
          pathCopy.removeSegment(pathCopy.segments.length - 1);
          pathCopy.addSegments([new Segment(point)]);
        }
      } else {
        pathCopy.remove();
        return;
      }
    }
    if (!DrawingsPaperUtils.isPolygonSelfIntersected(pathCopy)) {
      this._path.remove();
      this._path = pathCopy;
      this._config.onFinish();
      this._addedSegments = [];
      this._segmentsSnapshot = [];
    } else {
      pathCopy.remove();
    }
  }

  private getNearbyIntersectionBetweenEnds(): { point: paper.Point, fromEnd: boolean } {
    const fromEnd = DrawingsPaperUtils.getClosestPointOnLine(
      this._path.firstSegment.point,
      this._path.firstSegment.next.point,
      this._path.lastSegment.point,
    );
    const fromStart = DrawingsPaperUtils.getClosestPointOnLine(
      this._path.lastSegment.point,
      this._path.lastSegment.previous.point,
      this._path.firstSegment.point,
    );
    const distanceFromEnd = fromEnd.subtract(this._path.lastSegment.point).length;
    const distanceFromStart = fromStart.subtract(this._path.firstSegment.point).length;
    if (
      distanceFromEnd > (window as any).INTERSECTION_THRESHOLD
      && distanceFromStart > (window as any).INTERSECTION_THRESHOLD
    ) {
      return null;
    }

    if (distanceFromEnd < distanceFromStart) {
      return {
        point: fromEnd,
        fromEnd: true,
      };
    } else {
      return {
        point: fromStart,
        fromEnd: false,
      };
    }
  }

  private processNearestIntersection(
    intersection: NearestPoint,
  ): Segment[] {
    const { point: intersectionPoint, shouldReverse, addToEnd, newSegments, shouldRemoveLastPoint } = intersection;

    if (shouldReverse) {
      newSegments.reverse();
    }

    if (!addToEnd) {
      this._path.reverse();
    }

    if (shouldRemoveLastPoint) {
      this._path.removeSegment(this._path.segments.length - 1);
    }
    const segmentsToAdd = [new Segment(intersectionPoint), ...newSegments];
    this._path.addSegments(segmentsToAdd);
    return segmentsToAdd;
  }

  private processIntersecion(
    point: paper.Point,
    intersection: { point: paper.Point, segment: [string, string], newSegment: [Segment, Segment] },
    newSegments: Segment[],
    additionlPoint?: paper.Point,
  ): Segment[] {
    const { point: intersectionPoint, newSegment } = intersection;
    const newSegmentsList = [];
    if (this._segmentsSnapshot.length === 1) {
      const nearestMousePoint = DrawingsPaperUtils.getClosestPointOnLine(
        this._path.firstSegment.point,
        this._path.lastSegment.point,
        point,
      );
      const intersectionPointToStart = intersectionPoint.subtract(this._path.firstSegment.point).length;
      const toStart = nearestMousePoint.subtract(this._path.firstSegment.point).length;
      if (toStart > intersectionPointToStart) {
        this._path.reverse();
      }
    }
    for (let i = 0; i < this._path.segments.length - 1; i++) {
      const segmentToSave = this._path.segments[i] as Segment;
      newSegmentsList.push(segmentToSave);
    }
    newSegmentsList.push(new Segment(intersectionPoint));
    if (additionlPoint) {
      newSegmentsList.push(new Segment(additionlPoint));
    }
    const segmentsToAdd = this.getSegmentsToAdd(newSegments, intersectionPoint, newSegment, point);
    arrayUtils.extendArray(newSegmentsList, segmentsToAdd);
    this._path.removeSegments();
    this._path.addSegments(newSegmentsList);
    return segmentsToAdd;
  }

  private getSegmentsToAdd(
    newSegments: Segment[],
    intersectionPoint: paper.Point,
    intersectedSegment: [Segment, Segment],
    mousePoint: paper.Point,
  ): Segment[] {
    if (intersectedSegment[0].point.equals(intersectionPoint)) {
      return newSegments;
    } else if (intersectedSegment[1].point.equals(intersectionPoint)) {
      return newSegments.slice().reverse();
    }

    const [startIndex, endIndex] = intersectedSegment.map(x => newSegments.findIndex(s => s.id === x.id));
    const isPointInStart = DrawingsPaperUtils.checkIsPointInStartLine(
      [newSegments[startIndex].point, newSegments[endIndex].point],
      intersectionPoint,
      mousePoint,
    );
    if (isPointInStart) {
      return startIndex < endIndex
        ? [ new Segment(intersectionPoint), ...newSegments.slice(0, startIndex + 1).reverse()]
        : [ new Segment(intersectionPoint), ...newSegments.slice(startIndex)];
    } else {
      return startIndex < endIndex
        ? [ new Segment(intersectionPoint), ...newSegments.slice(startIndex + 1)]
        : [ new Segment(intersectionPoint), ...newSegments.slice(0, startIndex + 1).reverse()];
    }
  }


  private getNearestPoints(newSegments: Segment[]): paper.Point[][] {
    const [ start, end ] = newSegments;
    const startNearest = [
      DrawingsPaperUtils.getClosestPointOnLine(
        this._path.firstSegment.point,
        this._path.firstSegment.next.point,
        start.point,
      ),
      DrawingsPaperUtils.getClosestPointOnLine(
        this._path.lastSegment.point,
        this._path.lastSegment.previous.point,
        start.point,
      ),
    ];
    const endNearest = [
      DrawingsPaperUtils.getClosestPointOnLine(
        this._path.firstSegment.point,
        this._path.firstSegment.next.point,
        end.point,
      ),
      DrawingsPaperUtils.getClosestPointOnLine(
        this._path.lastSegment.point,
        this._path.lastSegment.previous.point,
        end.point,
      ),
    ];
    const newNearest = [
      DrawingsPaperUtils.getClosestPointOnLine(
        start.point,
        end.point,
        this._path.firstSegment.point,
      ),
      DrawingsPaperUtils.getClosestPointOnLine(
        start.point,
        end.point,
        this._path.lastSegment.point,
      ),
    ];
    return [startNearest, endNearest, newNearest];
  }

  private getDistanceInfo(points: paper.Point[], targetPoint: paper.Point): { index: number, distance: number } {
    const distances = points.map((p) => p.subtract(targetPoint).length);
    const minIndex = (window as any).WITH_POLYLINE_CONTINUE && this._addedSegments.length > 1
      ? 1
      : arrayUtils.getMinIndex(distances, (d) => d);
    return {
      index: minIndex,
      distance: distances[minIndex],
    };
  }

  private getNearbyToIntersect(
    newSegments: Segment[],
    point: paper.Point,
  ): NearestPoint {
    const [ startNearest, endNearest, newNearest ] = this.getNearestPoints(newSegments);
    const {
      index: startDistanceMinIndex,
      distance: startDistanceMin,
    } = this.getDistanceInfo(startNearest, newSegments[0].point);
    const {
      index: endDistanceMinIndex,
      distance: endDistanceMin,
    } = this.getDistanceInfo(endNearest, newSegments[1].point);
    const newDistances = [
      newNearest[0].subtract(this._path.firstSegment.point).length,
      newNearest[1].subtract(this._path.lastSegment.point).length,
    ];
    const newDistanceMinIndex = (window as any).WITH_POLYLINE_CONTINUE && this._addedSegments.length > 1
      ? 1
      : arrayUtils.getMinIndex(newDistances, d => d);
    const newDistanceMin = newDistances[newDistanceMinIndex];
    if (
      startDistanceMin > (window as any).INTERSECTION_THRESHOLD
      && endDistanceMin > (window as any).INTERSECTION_THRESHOLD
      && newDistanceMin > (window as any).INTERSECTION_THRESHOLD
      || startDistanceMin === 0
      || endDistanceMin === 0
      || newDistanceMin === 0
    ) {
      return null;
    }

    if (newDistanceMin <= startDistanceMin && newDistanceMin <= endDistanceMin) {
      return this.findNearestToNewSegment(newSegments, point, newNearest, newDistanceMinIndex, newDistanceMin);
    } else if (startDistanceMin < endDistanceMin) {
      return {
        point: startNearest[startDistanceMinIndex],
        shouldReverse: this.shouldReverseNewSegment(newSegments, startNearest[startDistanceMinIndex]),
        addToEnd: startDistanceMinIndex === 1,
        newSegments,
        distance: startDistanceMin,
        shouldRemoveLastPoint: true,
      };
    } else {
      return {
        point: endNearest[endDistanceMinIndex],
        shouldReverse: this.shouldReverseNewSegment(newSegments, endNearest[endDistanceMinIndex]),
        addToEnd: endDistanceMinIndex === 1,
        newSegments,
        distance: endDistanceMin,
        shouldRemoveLastPoint: true,
      };
    }
  }

  private findNearestToNewSegment(
    newSegments: Segment[],
    point: paper.Point,
    newNearest: paper.Point[],
    newDistanceMinIndex: number,
    newDistanceMin: number,
  ): NearestPoint {
    const [start, end] = newSegments;
    const nearestMousePoint = DrawingsPaperUtils.getClosestPointOnLine(
      start.point,
      end.point,
      point,
    );
    const nearestMouseToStart = nearestMousePoint.subtract(start.point).length;
    const intersectionPointToStart = newNearest[newDistanceMinIndex].subtract(start.point).length;
    const segment = nearestMouseToStart < intersectionPointToStart
      ? [start, new Segment(newNearest[newDistanceMinIndex])]
      : [new Segment(newNearest[newDistanceMinIndex]), end];
    const pointToConnect = newDistanceMinIndex === 0 ? this._path.firstSegment.point : this._path.lastSegment.point;
    return {
      point: newNearest[newDistanceMinIndex],
      shouldReverse: this.shouldReverseNewSegment(segment, pointToConnect),
      addToEnd: newDistanceMinIndex === 1,
      newSegments: segment,
      distance: newDistanceMin,
      shouldRemoveLastPoint: false,
    };
  }

  private shouldReverseNewSegment(
    segment: Segment[],
    point: paper.Point,
  ): boolean {
    const startToConnectionPoint = segment[0].point.subtract(point).length;
    const endToConnectionPoint = segment[segment.length - 1].point.subtract(point).length;
    return endToConnectionPoint < startToConnectionPoint;
  }

  private getIntersectionWithLastAddedSegments(
    newSegments: Segment[],
  ): { point: paper.Point, segment: [string, string], newSegment: [Segment, Segment] } {
    const lastAddedSegments = this._addedSegments[this._addedSegments.length - 1];
    for (
      const [ start, end ] of DrawingAnnotationUtils.iteratePoints(lastAddedSegments, DrawingsInstanceType.Polyline)
    ) {
      if (!end) {
        continue;
      }
      for (
        const [newStart, newEnd] of DrawingAnnotationUtils.iteratePoints(newSegments, DrawingsInstanceType.Polyline)
      ) {
        if (!newEnd) {
          continue;
        }
        const intersection = PaperIntersectionUtils
          .getLinesIntersection(start.point, end.point, newStart.point, newEnd.point);
        if (
          intersection && !(
            (start.point.equals(intersection) || end.point.equals(intersection))
            && (newStart.point.equals(intersection) || newEnd.point.equals(intersection))
          )
        ) {
          return {
            point: intersection,
            segment: [start.id, end.id],
            newSegment: [newStart, newEnd],
          };
        }
      }
    }
  }

  private getConnectionType(
    newSegments: paper.Segment[],
  ): ConnectionType {
    const { firstSegment: { point: start }, lastSegment: { point: end } } = this._path;
    const pointsFirst = newSegments[0].point;
    const pointsLast = newSegments[newSegments.length - 1].point;
    const distances = [
      start.subtract(pointsFirst).length,
      start.subtract(pointsLast).length,
      end.subtract(pointsFirst).length,
      end.subtract(pointsLast).length,
    ];
    if (this._segmentsSnapshot.length > 1 && (window as any).WITH_POLYLINE_CONTINUE) {
      if (distances[2] > distances[3]) {
        return ConnectionType.EndEnd;
      } else {
        return ConnectionType.EndStart;
      }
    }
    const minIndex = arrayUtils.getMinIndex(distances, (d) => d);
    return minIndex as ConnectionType;
  }


  private addSegments(segments: paper.Segment[], connectionType: ConnectionType): void {
    switch (connectionType) {
      case ConnectionType.StartStart:
        this._path.reverse();
        this._path.addSegments(segments);
        break;
      case ConnectionType.StartEnd:
        this._path.reverse();
        segments.reverse();
        this._path.addSegments(segments);
        break;
      case ConnectionType.EndStart:
        this._path.addSegments(segments);
        break;
      case ConnectionType.EndEnd:
        segments.reverse();
        this._path.addSegments(segments);
        break;
      default:
        break;
    }
  }

  private showMessage(hint: CursorHintType): void {
    this._hideMessageExecutor.reset();
    this._config.setCursorMessage(hint);
  }

  @autobind
  private onSettingsChanged({ connect, enclose }: WizzardToolsState): void {
    if (!connect && this._path.segments.length) {
      this._config.onFinish();
      this._config.onEnableContextMenu(false, false);
      this._segmentsSnapshot = [];
      this._addedSegments = [];
      return;
    }
    if (enclose) {
      this._path.fillColor = this._config.color.fill;
      this.validateIfClosed();
      this.removeThickness();
    } else {
      this._path.strokeColor = this._config.color.stroke;
      this._backColorToNormalExecutor.reset();
      this._path.fillColor = null;
      this.renderThickness();
    }
    this._config.onEnableContextMenu(this.hasPoint, this.canComplete());
  }

  private clonePath(): paper.Path {
    return new paper.Path(this.unifySegments(this._path.segments as Segment[]));
  }

  private removeThickness(): void {
    if (this._thicknessShape) {
      this._thicknessShape.destroy();
      this._thicknessShape = null;
    }
  }

  @autobind
  private renderThickness(): void {
    if (this._thicknessShape) {
      this._thicknessShape.destroy();
      this._thicknessShape = null;
    }
    const { showThickness, scale, metersPerPixel } = this._config.textRenderParametersObserver.getContext();
    const { polylineThickness } = this._config.newDrawingStylesObserver.getContext();
    const { enclose, connect } = this._config.wizzardSettingsObserver.getContext();
    if (!polylineThickness || !showThickness || !this._path.segments.length || enclose || !connect) {
      return;
    }

    const thickness = DrawingsCanvasUtils.metersToPx(polylineThickness, scale, metersPerPixel);

    this._thicknessShape = new PolylineThickness({
      geometry: this._path.segments,
      layer: this._config.layer,
      color: this._config.color.thickness,
      offset: thickness / 2,
      renderParamsContextObserver: this._config.renderParametersContextObserver,
    });
  }
}
