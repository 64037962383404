import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { BidPricingInviteUsers } from '../../interfaces/bid-pricing/bid-pricing-invite-users';
import { BidPricingUserCost } from '../../interfaces/bid-pricing/bid-pricing-user-cost';
import { BidPricingUserPackage } from '../../interfaces/bid-pricing/bid-pricing-user-package';
import { BidPricingWorkPackage } from '../../interfaces/bid-pricing/bid-pricing-work-package';
import {
  BidPricingUserPackageInfoPayload,
  GetPackageCost,
  NewBid,
  PackageEmails,
  SubcontractorBid,
  UpdateBidDescription,
  WinnerPackage,
} from '../payloads/bid-pricing';
import { BidPricingActionTypes } from '../types/bid-pricing';
import { ProjectsActionTypes } from '../types/common';

function bidPricingStartRequest(): Action {
  return {
    type: ProjectsActionTypes.START_BID_PRICING_REQUEST,
  };
}

function showBidPricingDialog(type: number): ActionWith<number> {
  return {
    type: BidPricingActionTypes.SET_DIALOG_TYPE,
    payload: type,
  };
}

function acceptPackage(): Action {
  return {
    type: BidPricingActionTypes.ACCEPT_REQUEST,
  };
}

function getBidPricingInfo(id: number): ActionWith<number> {
  return {
    type: BidPricingActionTypes.GET_INFO_REQUEST,
    payload: id,
  };
}

function getBidPricingInfoSucceeded(workpackages: BidPricingWorkPackage[]): ActionWith<BidPricingWorkPackage[]> {
  return {
    type: BidPricingActionTypes.GET_INFO_SUCCEEDED,
    payload: workpackages,
  };
}

function setWinner(
  userId: string,
  workPackageId: number,
  constructionCostId: number,
): ActionWith<WinnerPackage> {
  return {
    type: BidPricingActionTypes.SET_WINNER_REQUEST,
    payload: { userId, workPackageId, constructionCostId },
  };
}

function setWinnerSucceded(winner: WinnerPackage): ActionWith<WinnerPackage> {
  return {
    type: BidPricingActionTypes.SET_WINNER_SUCCEEDED,
    payload: winner,
  };
}

function removeSubcontractorBid(subcontractorBid: SubcontractorBid): ActionWith<SubcontractorBid> {
  return {
    type: BidPricingActionTypes.REMOVE_SUBCONTRACTOR_REQUEST,
    payload: subcontractorBid,
  };
}

function removeSubcontractorBidSuccess(
  subcontractorBid: SubcontractorBid,
): ActionWith<SubcontractorBid> {
  return {
    type: BidPricingActionTypes.REMOVE_SUBCONTRACTOR_SUCCESSED,
    payload: subcontractorBid,
  };
}

function cancelRequest(bidPricingUser: BidPricingUserPackageInfoPayload): ActionWith<BidPricingUserPackageInfoPayload> {
  return {
    type: BidPricingActionTypes.CANCEL_REQUEST,
    payload: bidPricingUser,
  };
}

function stopBidPricing(): Action {
  return {
    type: BidPricingActionTypes.STOP_REQUEST,
  };
}

function edit(): Action {
  return {
    type: BidPricingActionTypes.EDIT_REQUEST,
  };
}

function removePeopleFromWorkPackage(id: number, emails: string[]): ActionWith<PackageEmails> {
  return {
    type: BidPricingActionTypes.REMOVE_SUBCONTRACTORS_BY_PACKAGE,
    payload: {
      id,
      emails,
    },
  };
}

function createNewBid(workPackageId: number): ActionWith<number> {
  return {
    type: BidPricingActionTypes.CREATE_NEW_BID_REQUEST,
    payload: workPackageId,
  };
}

function createNewBidSucceeded(workPackageId: number, bid: BidPricingUserCost): ActionWith<NewBid> {
  return {
    type: BidPricingActionTypes.CREATE_NEW_BID_SUCCEEDED,
    payload: { workPackageId, bid },
  };
}

function updateBidDescription(
  workPackageId: number,
  constructionCostId: number,
  description: string,
): ActionWith<UpdateBidDescription> {
  return {
    type: BidPricingActionTypes.UPDATE_BID_DESCRIPTION_REQUEST,
    payload: { workPackageId, constructionCostId, description },
  };
}

function updateBidDescriptionSucceeded(
  data: UpdateBidDescription,
): ActionWith<UpdateBidDescription> {
  return {
    type: BidPricingActionTypes.UPDATE_BID_DESCRIPTION_SUCCEEDED,
    payload: data,
  };
}

function getSubcontractorPackages(calculationId: number): ActionWith<number> {
  return {
    type: BidPricingActionTypes.GET_SUBCONTRACTOR_PACKAGES_REQUEST,
    payload: calculationId,
  };
}

function getSubcontractorPackagesSucceeded(
  userPackages: BidPricingUserPackage[],
): ActionWith<BidPricingUserPackage[]> {
  return {
    type: BidPricingActionTypes.GET_SUBCONTRACTOR_PACKAGES_SUCCEEDED,
    payload: userPackages,
  };
}

function setSubcontractorPackage(data: GetPackageCost): ActionWith<GetPackageCost> {
  return {
    type: BidPricingActionTypes.SET_SUBCONTRACTOR_PACKAGE,
    payload: data,
  };
}

function inviteUsersToPackage(inviteData: BidPricingInviteUsers): ActionWith<BidPricingInviteUsers> {
  return {
    type: BidPricingActionTypes.INVITE_USERS_TO_PACKAGE,
    payload: inviteData,
  };
}

export const BidPricingActions = {
  bidPricingStartRequest,
  showBidPricingDialog,
  acceptPackage,
  getBidPricingInfo,
  getBidPricingInfoSucceeded,
  setWinner,
  setWinnerSucceded,
  removeSubcontractorBid,
  removeSubcontractorBidSuccess,
  cancelRequest,
  stopBidPricing,
  edit,
  removePeopleFromWorkPackage,
  createNewBid,
  createNewBidSucceeded,
  updateBidDescription,
  updateBidDescriptionSucceeded,
  getSubcontractorPackages,
  getSubcontractorPackagesSucceeded,
  setSubcontractorPackage,
  inviteUsersToPackage,
};
