import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconClipBoxActive: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='clip-box_light'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g id='Group-5' transform='translate(2.000000, 2.000000)' stroke={KreoColors.f4}>
          <path d='M14.5,4.5 L10.5,0.5' id='Rectangle-6' />
          <path d='M14.5,14.5 L10.5,10.5' id='Rectangle-6' />
          <path d='M4.5,4.5 L0.5,0.5' id='Rectangle-6' />
          <path d='M4.5,14.5 L0.5,10.5' id='Rectangle-6' />
          <rect id='Rectangle-6' x='0.5' y='0.5' width='10' height='10' />
        </g>
        <rect id='Rectangle-6' stroke='#FFFFFF' x='6.5' y='6.5' width='10' height='10' />
        <g
          id='Group-16'
          transform='translate(5.000000, 5.000000)'
          stroke='#FFFFFF'
          strokeWidth='3'
        >
          <path d='M11.5,11.5 L11.5100021,11.5' id='Rectangle-6' />
          <path d='M1.5,11.5 L1.51000214,11.5' id='Rectangle-6' />
          <path d='M1.5,1.5 L1.51000214,1.5' id='Rectangle-6' />
          <path d='M11.5,1.5 L11.5100021,1.5' id='Rectangle-6' />
        </g>
      </g>
    </svg>
  );
};
