import * as React from 'react';

import { mathUtils } from 'common/utils/math-utils';
import { Styled } from './styled';

interface OwnProps {
  parentLayoutRef: HTMLElement;
  isInitial: boolean;
  width: number;
  height: number;
  top: number;
  left: number;
  resizeVertically: boolean;
  resizeHorizontally: boolean;
  pointerEvents?: string;
  verticalMargin: number;
  horizontalMargin: number;
  updatePopupLayoutInfo: (top: number, left: number, width: number, height: number) => void;
}

interface AdditionalProps {
  savePopupContainerRef: (ref: HTMLDivElement) => void;
  getPopupContainerRef: () => HTMLDivElement;
  headerOnMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
  resizeIconOnMouseDown: () => void;
}

enum PopupTransformAction {
  NONE,
  DRAG,
  RESIZE,
}

interface ComponentState {
  popupTransformAction: PopupTransformAction;
  popupContainerLeft: number;
  popupContainerTop: number;
}

export interface MovablePanelProps extends OwnProps, AdditionalProps {}

export function MovableContainer<P extends MovablePanelProps>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof AdditionalProps>>> {
  return class MovablePanelComponent extends React.Component<
    Pick<P, Exclude<keyof P, keyof AdditionalProps>>,
    ComponentState
  > {
    private popupContainerRef: HTMLDivElement;
    private minWidth: number = 240;
    private maxWidth: number = 500;
    private minHeight: number = 160;

    constructor(props: P) {
      super(props);

      this.state = {
        popupTransformAction: PopupTransformAction.NONE,
        popupContainerLeft: 0,
        popupContainerTop: 0,
      };
      this.savePopupContainerRef = this.savePopupContainerRef.bind(this);
      this.getPopupContainerRef = this.getPopupContainerRef.bind(this);
      this.headerOnMouseDown = this.headerOnMouseDown.bind(this);
      this.resizeIconOnMouseDown = this.resizeIconOnMouseDown.bind(this);
      this.onResize = this.onResize.bind(this);
      this.onDrag = this.onDrag.bind(this);
      this.removeListenersFromPage = this.removeListenersFromPage.bind(this);
    }

    public render(): JSX.Element {
      return (
        <>
          {this.state.popupTransformAction !== PopupTransformAction.NONE && <Styled.Blank/>}
          <Component
            {...this.props as P}
            savePopupContainerRef={this.savePopupContainerRef}
            getPopupContainerRef={this.getPopupContainerRef}
            headerOnMouseDown={this.headerOnMouseDown}
            resizeIconOnMouseDown={this.resizeIconOnMouseDown}
          />
        </>
      );
    }

    public componentDidMount(): void {
      if (!this.props.isInitial) {
        const { width, height, top, left } = this.props;
        this.popupContainerRef.style.width = `${width}%`;
        this.popupContainerRef.style.left = `${left}%`;
        this.popupContainerRef.style.height = `${height}%`;
        this.popupContainerRef.style.top = `${top}%`;
      }
    }

    private onResize(e: MouseEvent): void {
      const layoutRect = this.props.parentLayoutRef.getBoundingClientRect() as DOMRect;
      const { x, y } =
        this.popupContainerRef.getBoundingClientRect() as DOMRect;
      if (this.props.resizeHorizontally) {
        let newWidth = e.clientX - x + 10;
        if (newWidth + x >= layoutRect.width + layoutRect.x - 10) {
          newWidth -= (newWidth + x) - (layoutRect.width + layoutRect.x) + 10;
        }
        newWidth = mathUtils.clamp(newWidth, this.minWidth, this.maxWidth);
        const width = newWidth / layoutRect.width * 100;
        this.popupContainerRef.style.width = `${width}%`;
      }
      if (this.props.resizeVertically) {
        const newHeight = mathUtils.clamp(
          e.clientY - y + 10,
          this.minHeight,
          layoutRect.height + layoutRect.y - y - this.props.verticalMargin,
        );
        const height = newHeight / layoutRect.height * 100;
        this.popupContainerRef.style.height = `${height}%`;
      }
    }

    private onDrag(e: MouseEvent): void {
      const { x, y, width, height } = this.props.parentLayoutRef.getBoundingClientRect() as DOMRect;
      const { offsetHeight, offsetWidth } = this.popupContainerRef;
      const { horizontalMargin, verticalMargin } = this.props;
      const maxTop = height - (offsetHeight + verticalMargin + 10);
      const minTop = verticalMargin;
      const top = maxTop > minTop
        ? mathUtils.clamp(e.clientY - this.state.popupContainerTop - y, minTop, maxTop)
        : minTop;
      const newTop = top / height * 100;
      this.popupContainerRef.style.top = `${newTop}%`;
      const maxLeft = width - offsetWidth - horizontalMargin;
      const minLeft = horizontalMargin;
      const left = maxLeft > minLeft
        ? mathUtils.clamp(e.clientX - this.state.popupContainerLeft - x, minLeft, maxLeft)
        : minLeft;
      const newLeft = left / width * 100;
      this.popupContainerRef.style.left = `${newLeft}%`;
    }

    private addListenersToPage(action: (e: MouseEvent) => void): void {
      document.addEventListener('mousemove', action);
      document.addEventListener('mouseleave', this.removeListenersFromPage);
      document.addEventListener('mouseup', this.removeListenersFromPage);
      document.querySelector('body').style.pointerEvents = this.props.pointerEvents || 'none';
      document.querySelector('body').style.userSelect = 'none';
    }

    private removeListenersFromPage(): void {
      if (this.state.popupTransformAction) {
        document.removeEventListener(
          'mousemove',
          this.state.popupTransformAction === PopupTransformAction.DRAG ? this.onDrag : this.onResize,
        );
        document.removeEventListener('mouseup', this.removeListenersFromPage);
        document.querySelector('body').removeAttribute('style');
        this.setState({ popupTransformAction: PopupTransformAction.NONE });
        const { top, left, width, height } = this.popupContainerRef.style;
        this.props.updatePopupLayoutInfo(
          this.transformValue(top),
          this.transformValue(left),
          this.transformValue(width),
          this.transformValue(height),
        );
      }
    }

    private transformValue(value: string): number {
      return value !== '' ? Number(value.replace('%', '')) : null;
    }

    private headerOnMouseDown(e: React.MouseEvent<HTMLDivElement>): void {
      const headerRect = this.popupContainerRef.getBoundingClientRect() as DOMRect;
      const left = e.clientX - headerRect.x;
      const top = e.clientY - headerRect.y;
      this.addListenersToPage(this.onDrag);
      this.setState({
        popupTransformAction: PopupTransformAction.DRAG,
        popupContainerLeft: left,
        popupContainerTop: top,
      });
    }

    private savePopupContainerRef(ref: HTMLDivElement): void {
      this.popupContainerRef = ref;
    }

    private getPopupContainerRef(): HTMLDivElement {
      return this.popupContainerRef;
    }

    private resizeIconOnMouseDown(): void {
      this.addListenersToPage(this.onResize);
      this.setState({ popupTransformAction: PopupTransformAction.RESIZE });
    }
  };
}
