import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { ConstantFunctions } from 'common/constants/functions';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { ContextObserverWithPrevious } from '../../drawings-contexts';
import { DrawingsInstanceType } from '../../enums';
import { DrawingsRenderParams } from '../../interfaces/drawing-render-parameters';
import { DrawingGeometryUpdateEventHandler } from '../../interfaces/drawing-update-events-handlers';
import { CursorHintType } from '../../layout-components/drawings-cursor-hint';
import { DrawingsPaperUtils } from '../../utils/drawings-paper-utils';
import { DestroyableObject, EngineObjectConfig } from '../common';
import {
  DrawingsGeometryEntityBase,
  DrawingsGeometryEntityContinuable,
  DrawingsGeometryEntityPoint,
  StartPoint,
} from '../drawings-geometry-entities';
import {
  GetCurrentDrawingCallback,
  GetDrawingInstanceCallback,
  GetInstanceMeasureCallback,
  SendMeasuresUpdateCallback,
  SetCursorHintCallback,
} from '../interfaces';
import { DrawingsInstancesChangesProcessor } from './drawings-instances-changes-processor';
import { DrawingsRenderedPointsCache } from './drawings-rendered-points-cache';
import { DrawingsViewHelper } from './drawings-view-helper';
import { DrawingsGeometrySnappingHelper, DrawingsSnappingParameters } from './snapping';

interface DrawingsGeometryContinueConfig extends EngineObjectConfig {
  cachedPoints: DrawingsRenderedPointsCache;
  snappingHelper: DrawingsGeometrySnappingHelper;
  viewHelper: DrawingsViewHelper;
  changesProcessor: DrawingsInstancesChangesProcessor;
  getCurrentDrawing: GetCurrentDrawingCallback;
  getDrawingInstance: GetDrawingInstanceCallback;
  sendMeasuresUpdate: SendMeasuresUpdateCallback;
  updateGeometry: DrawingGeometryUpdateEventHandler;
  getInstanceMeasures: GetInstanceMeasureCallback;
  setCursorHint: SetCursorHintCallback;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
}

export class DrawingsGeometryContinue extends DestroyableObject<DrawingsGeometryContinueConfig> {

  private _entityForProcessing: DrawingsGeometryEntityContinuable & DrawingsGeometryEntityBase;

  private _currentPosition: paper.Point;
  private _points: DrawingsGeometryEntityPoint[];
  private _pointsLayer: paper.Layer = new paper.Layer();
  private _selectedPoint: StartPoint;

  public destroy(): void {
    if (this._points) {
      this._points.forEach((point) => point.destroy());
      this._points = null;
    }
  }

  public get enabled(): boolean {
    return !!this._entityForProcessing;
  }

  public get entityForProcessing(): DrawingsGeometryEntityContinuable & DrawingsGeometryEntityBase {
    return this._entityForProcessing;
  }

  public set entityForProcessing(entity: DrawingsGeometryEntityContinuable & DrawingsGeometryEntityBase) {
    if (this._entityForProcessing) {
      this._entityForProcessing.continueEnabled = false;
      this._config.setCursorHint(null);
      if (this._points) {
        this._points.forEach((point) => point.destroy());
        this._points = null;
      }
    }
    if (entity) {
      const drawingInstance = this._config.getDrawingInstance(entity.id);
      if (drawingInstance.type === DrawingsInstanceType.Count) {
        entity.continueEnabled = true;
        this._config.viewHelper.onLeftMouseClick = this.onLeftMouseClick;
        this._config.snappingHelper.onSendPoint = this.updateMousePosition;
        this._config.viewHelper.onMouseMove = this.onMouseMove;
      } else {
        const points = drawingInstance.geometry.points;
        this._config.viewHelper.onLeftMouseClick = ConstantFunctions.stopEvent;
        this._config.setCursorHint(CursorHintType.Continue);
        this._points = [
          new DrawingsGeometryEntityPoint({
            geometry: this._config.cachedPoints.getPoint(points[0]),
            id: 'start',
            layer: this._pointsLayer,
            renderParamsContextObserver: this._config.renderParametersContextObserver,
            onSelect: this.selectPointToStart,
          }),
          new DrawingsGeometryEntityPoint({
            geometry: this._config.cachedPoints.getPoint(points[points.length - 1]),
            id: 'end',
            layer: this._pointsLayer,
            renderParamsContextObserver: this._config.renderParametersContextObserver,
            onSelect: this.selectPointToStart,
          }),
        ];
      }
    } else {
      this._config.viewHelper.restoreDefaultEvent('onLeftMouseClick');
      this._config.viewHelper.restoreDefaultEvent('onMouseMove');
    }
    this._entityForProcessing = entity;
  }

  @autobind
  private updateMousePosition(
    point: paper.Point,
    snappingInfo?: DrawingsSnappingParameters,
  ): paper.Point {
    if (!this.enabled) {
      return null;
    }
    this._currentPosition = point;
    if (snappingInfo) {
      const { threshold, snappingPoint } = snappingInfo;
      if (snappingPoint && snappingPoint.subtract(point).length < threshold) {
        this._currentPosition = snappingPoint;
        this._entityForProcessing.updateContinueTempPoint(this._currentPosition, this._selectedPoint);
        return this._currentPosition;
      }
    }
    this._entityForProcessing.updateContinueTempPoint(this._currentPosition, this._selectedPoint);
    return null;
  }

  @autobind
  private onMouseMove(e: PaperMouseEvent): void {
    if (!this._config.snappingHelper.canSnap) {
      this.updateMousePosition(e.point);
    } else {
      this._config.snappingHelper.requestSnapping(e.point);
    }
  }

  private getCurrentPoint(point: paper.Point): paper.Point {
    const { zoom } = this._config.renderParametersContextObserver.getContext();
    if (
      !this._currentPosition
      || point.subtract(this._currentPosition).length > DrawingsCanvasConstants.snappingThreshold / zoom
    ) {
      return point;
    }
    return this._currentPosition;
  }

  @autobind
  private selectPointToStart(id: string, e: PaperMouseEvent): void {
    ConstantFunctions.stopEvent(e);
    this._selectedPoint = id as StartPoint;
    this._entityForProcessing.continueEnabled = true;
    this._config.viewHelper.onLeftMouseClick = this.onLeftMouseClick;
    this._config.snappingHelper.onSendPoint = this.updateMousePosition;
    this._config.viewHelper.onMouseMove = this.onMouseMove;
    this._points.forEach((point) => point.destroy());
    this._points = null;
    this._config.setCursorHint(null);
  }


  @autobind
  private onLeftMouseClick(e: PaperMouseEvent): void {
    this._config.changesProcessor.addPointToEnd(
      this._entityForProcessing.id,
      DrawingsPaperUtils.convertPoint(this.getCurrentPoint(e.point)),
      this._selectedPoint || StartPoint.End,
    );
  }
}
