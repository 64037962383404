import styled from 'styled-components';
import { TextAreaStyled } from 'common/components/text-area';

const Container = styled.div`
  display: flex;
  margin: 10px;

  svg {
    fill: white;
  }
`;

const Content = styled.div`
  min-width: 200px;
  padding: 0 10px 5px 10px;

  > span {
    word-break: break-all;
  }

  > p {
    font-size: 14px;
    line-height: 21px;
  }

  ${TextAreaStyled.Container} {
    min-height: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const CreationInfo = styled.div<{ isMenu: boolean, isApply: boolean }>`
  max-width: ${p => p.isApply && p.isMenu ? 155 : p.isApply || p.isMenu ? 185 : 205}px;
`;

const Menus = styled.div`
  display: flex;
`;

const ApplySButton = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  border-radius: 50%;
  border: 2px solid ${p => p.theme.color.green};
  background: ${p => p.theme.color.background};
  box-sizing: border-box;

  > button {
    > span {
      align-items: center;
      svg {
        width: 10px;
        height: 10px;
        min-height: 10px;
        min-width: 10px;
        margin-right: 3px;
      }
    }
    :hover {
      > span {
          svg {
          fill: ${p => p.theme.color.green};
        }
      }
    }
  }

  :active {
    transform: scale(0.8);
  }
`;

const ReOpenButton = styled.div`
  margin: 0 5px;
`;

const EditContainer = styled.div`
  flex-direction: column;
  flex-wrap: wrap;
`;

export const Styled = {
  Container,
  Content,
  Header,
  Menus,
  ApplySButton,
  ReOpenButton,
  CreationInfo,
  EditContainer,
};
