import { H6 } from '@kreo/kreo-ui-components';
import styled from 'styled-components';
import { Size } from 'common/enums/size';


const ParamsBySize = {
  [Size.Large]: {
    padding: '20px',
    gridColumnGap: '20px',
    indentBetweenElement: '10px',
  },
  [Size.Medium]: {
    padding: '10px',
    gridColumnGap: '10px',
    indentBetweenElement: '10px',
  },
  [Size.Small]: {
    padding: '5px',
    gridColumnGap: '10px',
    indentBetweenElement: '5px',
  },
};


const Block = styled.div`
  width: 100%;
  background-color: ${p => p.theme.color.backgroundRush};
  &:not(:first-of-type) {
    border-top: 1px solid ${p => p.theme.color.background};
  }
`;

interface ContainerProps {
  indentBetweenElement: boolean;
  size: Size;
  hasSplitter: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  ${Block} + ${Block} {
    border-top: 1px solid ${p => p.theme.color.background};
    margin-top: ${p => (p.indentBetweenElement ? ParamsBySize[p.size] : '0px')};
  }

  h4 {
    margin-left: 30px;
    margin-bottom: 20px;
  }

  * {
    box-sizing: border-box;
  }
`;

const BlockDescription = styled.div<{ isOpen: boolean }>`
  max-height: ${p => (!p.isOpen ? '0px' : 'auto')};
  padding: ${p => p.isOpen ? '0 10px 10px 10px' : '0 10px'};
  transition-property: max-height, height, border-top, padding;
  transition-timing-function: linear, ease;
  transition-duration: ${p => p.theme.duration.s};
  overflow: hidden;
  position: relative;
`;

const DescriptionTextContainer = styled.div<{ isOpen: boolean }>`
  opacity: ${p => (p.isOpen ? 1 : 0)};
  transition-property: opacity;
  transition-timing-function: linear, ease;
  transition-duration: ${p => p.theme.duration.s};

  ${H6} {
    font-size: 16px;
  }
`;

interface ArrowContainerProps {
  isOpen: boolean;
  expandButtonBorders: boolean;
  expandIconSize: number;
}

const ArrowContainer = styled.div<ArrowContainerProps>`
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${p => p.expandButtonBorders ? p.theme.color.pale : 'transparent'};
  svg {
    transition: all ${p => p.theme.duration.s} linear;
    height: ${p => p.expandIconSize}px;
    width: ${p => p.expandIconSize}px;
    fill: ${p => p.theme.color.gray};
    transform: rotate(${p => p.isOpen && '90deg'});
  }
`;

const getColumnTemplate = ({ hasIcon, expandLeft }: { hasIcon: boolean, expandLeft: boolean }): string => {
  if (hasIcon) {
    return '20px 1fr 20px';
  } else {
    return expandLeft ? '20px 1fr' : '1fr 20px';
  }
};

const BlockHeader = styled.div`
  width: 100%;
  padding: 10px;
  min-height: 40px;
  display: grid;
  grid-template-columns: ${getColumnTemplate};
  grid-column-gap: 10px;
  cursor: pointer;
  position: relative;
  align-items: center;
  :hover {
    svg {
      fill: ${p => p.theme.color.turquoise};
    }
  }
`;

export const Styled = {
  Container,
  Block,
  BlockDescription,
  DescriptionTextContainer,
  ArrowContainer,
  BlockHeader,
};
