import { MoveCellTempData, MoveDataPayload } from '../interfaces';
import { setEmptyRows } from '../set-empty-lines';
import { shiftRows } from '../shift-rows';

export const insertRows = ({ startIndex, sheetsData, sheetId, offset }: MoveDataPayload): MoveCellTempData => {
  const sheetData = sheetsData[sheetId];

  const updatedRows = shiftRows(sheetData, startIndex + offset, -offset);
  setEmptyRows(startIndex, offset, sheetData.rows, updatedRows);

  return updatedRows;
};
