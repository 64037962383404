import { Property } from 'unit-2d-database/interfaces';
import { GroupForm } from '../../item-panel/helpers/map-property-group-form';

export function *getNotAddedPropertiesIdByNamesIterator(
  names: string[],
  propertyGroups: GroupForm,
  propertiesDatabase: Property[],
): IterableIterator<string> {
  for (const name of names) {
    const property = propertiesDatabase.find(p => p.name === name);
    if (property && !(propertyGroups[property.groupName]?.fields.find(f => f.name === name))) {
      yield property.id;
    }
  }
}
