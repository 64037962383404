import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { RadioButton } from 'common/components/radion-button';
import { OptionInfo, OptionKeyType, OptionCustomRender } from '../interfaces';
import { OptionContainer } from '../option-container';

export interface Props<K extends OptionKeyType, T extends OptionInfo<K> = OptionInfo<K>> {
  onSelectionChanged: (value: K) => void;
  customOptionRender?: OptionCustomRender<T>;
  options: T[];
  value: K;
  disabled?: boolean;
}

export class RadioMenu
  <K extends OptionKeyType, T extends OptionInfo<K> = OptionInfo<K>>
  extends React.PureComponent<Props<K, T>> {
  public render(): React.ReactNode {
    return this.props.options.map((option) => {
      return (
        <OptionContainer
          key={`${option.value}`}
          value={option.value}
          onSelect={this.props.onSelectionChanged}
        >
          {
            this.renderItem(option, this.props.value === option.value, this.props.disabled)
          }
        </OptionContainer>
      );
    });
  }

  private renderItem(data: T, selected: boolean, disabled: boolean): React.ReactNode {
    if (this.props.customOptionRender) {
      return this.props.customOptionRender(data, selected);
    }
    return (
      <>
        <RadioButton checked={selected} disabled={disabled}/>
        <Text>{data.name}</Text>
      </>
    );
  }
}
