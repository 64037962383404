import styled from 'styled-components';

const Container = styled.div`
  width: 240px;
  *, & {
    box-sizing: border-box;
  }
`;

export const Styled = {
  Container,
};
