import { NotificationProcessor } from 'common/interfaces/realtime-messages';
import { CommonNotificationProcessor } from '../../notifications/common-notification-processor';
import { AccountNotificationKeys } from './keys';

export const AccountNotificationsProcessor: NotificationProcessor = {
  [AccountNotificationKeys.AddedToCompany]: CommonNotificationProcessor.addNotification,
  [AccountNotificationKeys.AddedToProject]: CommonNotificationProcessor.addNotification,
  [AccountNotificationKeys.DeletedFromProject]: CommonNotificationProcessor.addNotification,
  [AccountNotificationKeys.RoleChanged]: CommonNotificationProcessor.addNotification,
  [AccountNotificationKeys.RolesAndPermissionsChanged]: CommonNotificationProcessor.addNotification,
};

