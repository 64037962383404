import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { KreoButton, KreoDialogActions, KreoIconAddedDone } from 'common/UIKit';
import { KreoConfirmationDialog } from '../../../../../components/dialog/kreo-confirmation-dialog';

interface DispatchProps {
  openApproveDialog: () => void;
}

interface Props extends DispatchProps {
  title: string;
  isApproved: boolean;
  onApprove?: () => void;
}


const APPROVE_DIALOG_NAME = 'APPROVE_DIALOG_NAME';


class ApproveButtonComponent extends React.Component<Props> {

  public render(): JSX.Element {
    if (this.props.isApproved) {
      return (
        <KreoButton
          disabled={true}
          mode='success'
          size='medium'
          className='validation-stepper__approve-step-button--approved'
          controlName='approved-button'
          icon={<KreoIconAddedDone />}
        >
          Approved
        </KreoButton>
      );
    }

    return (
      <>
        <KreoButton
          disabled={!this.props.onApprove}
          mode='submit'
          size='medium'
          onClick={this.props.openApproveDialog}
          className='validation-stepper__approve-step-button'
          controlName='approve-button'
        >
          Approve
        </KreoButton>
        <KreoConfirmationDialog
          name={APPROVE_DIALOG_NAME}
          onYes={this.props.onApprove}
          yesText='Approve'
          noText='Cancel'
          title={`${this.props.title} Approval`}
        >
          If you approve current step, the data will be locked for editing
        </KreoConfirmationDialog>
      </>
    );
  }
}


const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    openApproveDialog: () => dispatch(KreoDialogActions.openDialog(APPROVE_DIALOG_NAME)),
  };
};

const connector = connect(null, mapDispatchToProps, null, { forwardRef: true });
export const ApproveButton = connector(ApproveButtonComponent);
