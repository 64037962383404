import React from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { PolygonParams } from './polygon-params';
import { PolylineParams } from './polyline-params';
import { useResultType } from './use-result-type';

const ContentByInstanceType = {
  [DrawingsInstanceType.Polygon]: PolygonParams,
  [DrawingsInstanceType.Rectangle]: PolygonParams,
  [DrawingsInstanceType.Polyline]: PolylineParams,
};

const GeometryParamsComponent: React.FC = () => {
  const instanceType = useResultType();
  const canEdit3dMeasurements = useAbility(Operation.Update, Subject.Takeoff2dMeasurement3d);
  const Component = ContentByInstanceType[instanceType];

  if (!Component || !canEdit3dMeasurements) {
    return null;
  }
  return (<Component/>);
};

export const GeometryParams = React.memo(GeometryParamsComponent);
