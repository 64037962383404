import { ProjectType, DemoProjectType } from './project-type';

export const ProjectConvertibleFileTypes = ['dwg', 'dxf', 'dwf', 'dgn',
  'png', 'tiff', 'jpg', 'jpeg', 'bmp', 'emf', 'gif'];


const Project2dFileTypes = ['pdf'];
const Project2dAdminFileTypes = ['kt2d'];

export const ProjectTypeToFileExtension = {
  [ProjectType.Project2d]: {
    default: Project2dFileTypes,
    toShow: Project2dFileTypes,
    admin: Project2dAdminFileTypes,
    convertable: ProjectConvertibleFileTypes,
  },
  [ProjectType.Project3d]: {
    default: ['bim', 'ifc', 'ifcxml', 'ifczip', 'rvt'],
    toShow: ['bim', 'ifc', 'rvt'],
  },
};

const DemoProject2dFileTypes = ['kt2d'];
const DemoPiaDbFileTypes = ['pia'];

export const DemoProjectTypeToFileExtension = {
  [DemoProjectType.Project2d]: {
    default: DemoProject2dFileTypes,
    toShow: DemoProject2dFileTypes,
  },
  [DemoProjectType.PiaDb]: {
    default: DemoPiaDbFileTypes,
    toShow: DemoPiaDbFileTypes,
  },
};

