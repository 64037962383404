import { RequestStatus } from 'common/enums/request-status';
import { Feed } from 'common/interfaces/feed';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { LoadResourcesPayload } from '../actions/payloads/database-resource';
import { DatabaseResourceActionTypes } from '../actions/types/database-resource';
import { DatabaseTableRootModel } from '../interfaces/data';
import { DatabaseReduxState } from '../interfaces/redux-state';
import { ResourceModel } from '../interfaces/resources-data';


export const DatabaseResourceReducerMethods: ReducerMethods<DatabaseReduxState> = {
  [DatabaseResourceActionTypes.GET_RESOURCES_REQUEST]: (state, payload: LoadResourcesPayload) => {
    const helper = new MonoliteHelper(state);
    if (payload.filter.skip === 0) {
      helper.set((_) => _.currentDatabase.resourceListing.resources, []);
    }

    return helper
      .set((_) => _.statuses.resourceListing, RequestStatus.Loading)
      .get();
  },
  [DatabaseResourceActionTypes.GET_RESOURCES_SUCCEEDED]: (state, payload: Feed<DatabaseTableRootModel>) => {
    const resources = state.currentDatabase.resourceListing.resources.concat(payload.data);

    return new MonoliteHelper(state)
      .set((_) => _.currentDatabase.resourceListing.resources, resources)
      .set((_) => _.currentDatabase.resourceListing.totalCount, payload.count)
      .set((_) => _.statuses.resourceListing, RequestStatus.Loaded)
      .get();
  },
  [DatabaseResourceActionTypes.GET_RESOURCE_REQUEST]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.currentDatabase.editModel.root, null)
      .get();
  },
  [DatabaseResourceActionTypes.GET_RESOURCE_SUCCEEDED]: (state, payload: ResourceModel) => {
    return new MonoliteHelper(state)
      .set((_) => _.currentDatabase.editModel.root, payload)
      .get();
  },
};
