import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


export interface Props {
  onClick: () => void;
}

export const ApplyButton: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <Styled.ApplyButton
      onClick={onClick}
    >
      <Icons.ApplyS />
    </Styled.ApplyButton>
  );
};
