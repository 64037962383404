import { EngineObject, EngineObjectConfig } from '../../common';

export class DrawingsOperationHelper<T extends EngineObjectConfig = EngineObjectConfig> extends EngineObject<T> {
  public isExistInProcessInstances: (id: string) => boolean;

  protected _instancesIds: string[];

  constructor(config: T) {
    super(config);
    this.isExistInProcessInstances = () => true;
  }

  public set instancesForProcess(value: string[]) {
    this._instancesIds = value;
    const instancesSet = new Set(value);
    this.isExistInProcessInstances = value ? id => instancesSet.has(id) : () => true;
  }

  public get instancesForProcess(): string[] {
    return this._instancesIds;
  }
}
