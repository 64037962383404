import autobind from 'autobind-decorator';
import { debounce } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './classification-simple-object-custom-uniclass.scss';

import { State } from 'common/interfaces/state';
import { MaterialInput } from 'common/UIKit';
import { TreeView } from '../../../../components/treeview';
import { Item } from '../../../../components/treeview/tree-view';
import { ActivityCategory } from '../../../../units/databases/interfaces/data';
import { ClassificationActions } from '../../actions/creators/classification';
import { UniclassPairWithShortInfo } from '../../interfaces/classification/uniclass-pair-with-short-info';

interface StateProps {
  currentTree: ActivityCategory[];
  systemValue: string;
  productValue: string;
  filter: string;
  isSystemTree: boolean;
  tempUniclass: UniclassPairWithShortInfo;
}

interface DispatchProps {
  setProductFilter: (filter: string) => void;
  setSystemFilter: (filter: string) => void;
  setCustomProduct: (product: ActivityCategory) => void;
  setCustomSystem: (system: ActivityCategory) => void;
}

interface ComponentState {
  systemValue: string;
  productValue: string;
}

interface Props extends StateProps, DispatchProps {}


class ClassificationSimpleObjectCustomUniclassComponent extends React.Component<Props, ComponentState> {
  private productInputRef: MaterialInput;
  private changeSystemFilterDebounced: (value: string) => void;
  private changeProductFilterDebounced: (value: string) => void;

  constructor(props: Props) {
    super(props);

    this.changeSystemFilterDebounced = debounce(props.setSystemFilter, 300);
    this.changeProductFilterDebounced = debounce(props.setProductFilter, 300);

    this.state = {
      systemValue: props.systemValue,
      productValue: props.productValue,
    };
  }

  public render(): JSX.Element {
    const { currentTree, filter } = this.props;
    return (
      <div className='classification-simple-object-custom-uniclass '>
        <div className='classification-simple-object-custom-uniclass__fields'>
          <MaterialInput
            className='classification-simple-object-custom-uniclass__text-field'
            label='System'
            onChange={this.changeSystem}
            onFocus={this.focusSystem}
            value={this.state.systemValue}
            autoFocus={true}
          />
          <MaterialInput
            className='classification-simple-object-custom-uniclass__text-field'
            label='Product'
            onChange={this.changeProduct}
            onFocus={this.focusProduct}
            value={this.state.productValue}
            ref={this.saveProductInputRef}
          />
        </div>
        <div>
          <TreeView
            filter={filter}
            items={currentTree as Item[]}
            onClick={this.setUniclass}
            isOnlyLeavesSelect={true}
            selectedCode={''}
          />
        </div>
      </div>
    );
  }

  public componentDidUpdate(): void {
    if (this.props.tempUniclass && this.props.tempUniclass.system && !this.props.tempUniclass.product) {
      if (!this.productInputRef.getValue()) {
        this.productInputRef.focus();
      }
    }
  }

  @autobind
  private saveProductInputRef(ref: MaterialInput): void {
    this.productInputRef = ref;
  }

  @autobind
  private setUniclass(value: ActivityCategory): void {
    if (this.props.isSystemTree) {
      this.props.setCustomSystem(value);
      this.setState({ systemValue: value.title });
    } else {
      this.props.setCustomProduct(value);
      this.setState({ productValue: value.title });
    }
  }

  @autobind
  private changeProduct(_event: React.ChangeEvent, value: string): void {
    this.setState({ productValue: value });
    this.changeProductFilterDebounced(value);
  }

  @autobind
  private changeSystem(_event: React.ChangeEvent, value: string): void {
    this.setState({ systemValue: value });
    this.changeSystemFilterDebounced(value);
  }

  @autobind
  private focusProduct(_e: React.FocusEvent, value: string): void {
    this.props.setProductFilter(value);
  }

  @autobind
  private focusSystem(_e: React.FocusEvent, value: string): void {
    this.props.setSystemFilter(value);
  }
}

const mapStateToProps = (state: State): StateProps => {
  const isSystemTree = state.classification.isSystemTree;
  const tempUniclass = state.classification.tempUniclass;
  const filter = state.classification.filterValue;
  let systemValue = '';
  let productValue = '';
  if (isSystemTree && filter !== '') {
    systemValue = filter;
  } else if (tempUniclass && tempUniclass.system) {
    systemValue = tempUniclass.system.title;
  }

  if (!isSystemTree && filter !== '') {
    productValue = filter;
  } else if (tempUniclass && tempUniclass.product) {
    productValue = tempUniclass.product.title;
  }

  return {
    currentTree: isSystemTree ? state.classification.systems : state.classification.products,
    systemValue,
    productValue,
    filter,
    isSystemTree,
    tempUniclass,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    setProductFilter: filter => dispatch(ClassificationActions.productFilter(filter)),
    setSystemFilter: filter => dispatch(ClassificationActions.systemFilter(filter)),
    setCustomProduct: product => dispatch(ClassificationActions.setCustomProduct(product)),
    setCustomSystem: system => dispatch(ClassificationActions.setCustomSystem(system)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ClassificationSimpleObjectCustomUniclass = connector(ClassificationSimpleObjectCustomUniclassComponent);
