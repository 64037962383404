import React from 'react';
import { PROMPTS } from 'unit-2d-copilot/constants';
import { Prompt } from './prompt';

interface Props {
  onSelectPrompt: (prompt: string) => void;
}


const PromptsListComponent: React.FC<Props> = ({
  onSelectPrompt,
}) => {
  return (
    <>
      {
        PROMPTS.map((prompt) => (
          <Prompt
            key={prompt}
            content={prompt}
            onClick={onSelectPrompt}
          />
        ))
      }
    </>
  );
};

export const PromptsList = React.memo(PromptsListComponent);

