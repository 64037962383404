import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DrawingsApi } from '../../api/drawings-api';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { DrawingsViewportHelper } from '../../helpers/viewport';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { DrawingsBounds } from '../../interfaces/drawings-geometry';
import { DrawingsShortInfo } from '../../interfaces/drawings-short-drawing-info';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';
import { Vector2Utils } from '../../utils/math-utils/vector2-utils';
import { MinimapImage } from './minimap-image';
import { Styled } from './minimap-styled';


interface StateProps {
  projectId: number;
}


interface OwnProps {
  currentDrawingInfo: DrawingsShortInfo;
  viewportHelper: DrawingsViewportHelper;
}

interface Props extends OwnProps, StateProps {

}

interface StateSize {
  width: number;
  height: number;
  scale: number;
}

interface ComponentState {
  params: StateSize;
  drag: boolean;
}

const MAX_SIZE = 270;

class MinimapComponent extends React.PureComponent<Props, ComponentState> {
  private viewportAreaRef: HTMLDivElement = null;

  private dragExecutor: DeferredExecutor = new DeferredExecutor(DrawingsCanvasConstants.doubleClickDelay);
  private lastMousePosition: ShortPointDescription;

  constructor(props: Props) {
    super(props);
    this.state = {
      params: this.getSizes(),
      drag: false,
    };
  }

  public render(): React.ReactNode {
    const {
      currentDrawingInfo: { pdfId, drawingId, rotationAngle },
    } = this.props;
    const rotation = rotationAngle || 0;
    const { params, drag } = this.state;
    const [ width, height ] = DrawingsCanvasUtils.changeWidthHeightByRotation(params.width, params.height, rotation);
    return (
      <Styled.Container
        width={width}
        height={height}
        onClick={this.onClick}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseUp}
      >
        <MinimapImage
          draggable={false}
          width={params.width}
          height={params.height}
          rotation={rotation}
          src={DrawingsApi.getScreenshotLink(pdfId, drawingId)}
        />
        <Styled.ViewportArea
          drag={drag}
          onMouseUp={this.onMouseUp}
          ref={this.saveViewportAreaRef}
          onClick={ConstantFunctions.stopEvent}
          onMouseDown={this.onMouseDown}
        />
      </Styled.Container>
    );
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.currentDrawingInfo.drawingId !== this.props.currentDrawingInfo.drawingId) {
      this.setState({ params: this.getSizes() });
    }
  }

  public componentDidMount(): void {
    this.props.viewportHelper.renderRectObserver.subscribe(this.setBounds);
  }

  public componentWillUnmount(): void {
    this.props.viewportHelper.renderRectObserver.unsubscribe(this.setBounds);
  }

  private getSizes(): StateSize {
    const { width, height } = this.props.currentDrawingInfo;
    if (width > height) {
      const scale = MAX_SIZE / width;
      return {
        width: MAX_SIZE,
        height: height * scale,
        scale,
      };
    } else {
      const scale = MAX_SIZE / height;
      return {
        height: MAX_SIZE,
        width: width * scale,
        scale,
      };
    }
  }

  @autobind
  private onClick(e: React.MouseEvent<HTMLDivElement>): void {
    this.dragExecutor.reset();
    const scale = this.state.params.scale;
    const viewportHelper = this.props.viewportHelper;
    const clientRect = e.currentTarget.getBoundingClientRect() as DOMRect;
    const x = (e.clientX - clientRect.x) / scale;
    const y = (e.clientY - clientRect.y) / scale;
    viewportHelper.scrollToPagePosition(x, y);
  }

  @autobind
  private onMouseDown(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    const scale = this.state.params.scale;
    const clientRect = e.currentTarget.getBoundingClientRect() as DOMRect;

    this.lastMousePosition = [
      (e.clientX - clientRect.x - clientRect.width / 2) / scale,
      (e.clientY - clientRect.y  - clientRect.height / 2) / scale,
    ];
    this.setState({ drag: true });
  }

  @autobind
  private onMouseUp(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.lastMousePosition = null;
    this.setState({ drag: false });
  }

  @autobind
  private onMouseMove(e: React.MouseEvent<HTMLDivElement>): void {
    const { params, drag } = this.state;
    if (!this.lastMousePosition || !drag) {
      return;
    }
    const { viewportHelper } = this.props;
    const clientRect = e.currentTarget.getBoundingClientRect() as DOMRect;
    const [x, y]: ShortPointDescription = Vector2Utils.divide(
      [e.clientX - clientRect.x, e.clientY - clientRect.y],
      params.scale,
    );
    viewportHelper.scrollToPagePosition(x - this.lastMousePosition[0], y - this.lastMousePosition[1]);
  }

  @autobind
  private saveViewportAreaRef(ref: HTMLDivElement): void {
    this.viewportAreaRef = ref;
    const rect = this.props.viewportHelper.renderRectObserver.getContext();
    this.setBounds(rect);
  }

  @autobind
  private setBounds(rect: DrawingsBounds): void {
    if (!rect || !this.viewportAreaRef) {
      return;
    }

    const scale = this.state.params.scale;
    this.viewportAreaRef.style.left = `${rect.x * scale}px`;
    this.viewportAreaRef.style.top = `${rect.y * scale}px`;
    this.viewportAreaRef.style.width = `${rect.width * scale}px`;
    this.viewportAreaRef.style.height = `${rect.height * scale}px`;
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    projectId: state.projects.currentProject.id,
  };
}

export const Minimap = connect(mapStateToProps)(MinimapComponent);
