import { InputSelect } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { DrawingsAnnotationLegendActions } from '../../actions/creators/drawings-annotation-legend';
import {
  AnnotationFilters,
  AssignFilterValue,
  FilterChangePayload,
  FilterData,
} from '../../interfaces/drawing-filters';

import { Styled } from './styled';

interface StateToProps {
  filterData: FilterData;
}

interface DispatchToProps {
  changeFilterData: (changeFilterData: FilterChangePayload) => void;
}

type Props = StateToProps & DispatchToProps;

const ALL_LABEL = 'All';
const ASSIGN_LABEL = 'Assign';
const UNASSIGN_LABEL = 'Unassign';

class EntitiesSelectFilterComponent extends React.PureComponent<Props> {
  private elements = [ALL_LABEL, ASSIGN_LABEL, UNASSIGN_LABEL];
  private elementsMap = {
    [ALL_LABEL]: AssignFilterValue.All,
    [ASSIGN_LABEL]: AssignFilterValue.Assign,
    [UNASSIGN_LABEL]: AssignFilterValue.UnAssign,
  };
  private enumMap = {
    [AssignFilterValue.All]: ALL_LABEL,
    [AssignFilterValue.Assign]: ASSIGN_LABEL,
    [AssignFilterValue.UnAssign]: UNASSIGN_LABEL,
  };

  public render(): React.ReactNode {
    const selectedEnum = this.props.filterData[AnnotationFilters.Assign];
    const activeElementIndex = this.elements.indexOf(this.enumMap[selectedEnum]);
    return (
      <Styled.Set>
        <Styled.SelectContainer>
          <InputSelect
            elements={this.elements}
            closeAfterClick={true}
            activeElementIndex={activeElementIndex}
            onClick={this.onClick}
          />
        </Styled.SelectContainer>
      </Styled.Set>
    );
  }

  @autobind
  private onClick(index: number): void {
    const enumValue = this.elementsMap[this.elements[index]];
    const selectedEnum = this.props.filterData[AnnotationFilters.Assign];
    if (selectedEnum === AssignFilterValue.All && enumValue === AssignFilterValue.All) {
      return;
    }
    const payload: FilterChangePayload = {
      filterType: AnnotationFilters.Assign,
      element: enumValue === selectedEnum
        ? AssignFilterValue.All
        : enumValue,
    };
    this.props.changeFilterData(payload);
  }
}

const mapStateToProps = (state: State): StateToProps => {
  const { filterData } = state.drawings;
  return {
    filterData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchToProps => {
  return {
    changeFilterData: (changeFilterData) =>
      dispatch(DrawingsAnnotationLegendActions.changeFilterData(changeFilterData)),
  };
};

export const EntitiesSelectFilter = connect(mapStateToProps, mapDispatchToProps)(EntitiesSelectFilterComponent);
