import React from 'react';

import { NumberInput } from 'common/components/inputs';
import { UuidUtil } from 'common/utils/uuid-utils';
import { FormField } from '../form-field';
import { useEditContext } from '../use-edit-context';

export const FormNumberInputField = (props: any): any => {
  const {
    label,
    dropDownProps,
    input,
    onDeleteClick,
    disabled,
  } = props;
  const { value, onChange } = input;
  const inputId = React.useMemo(() => UuidUtil.generateUuid(), []);
  const { startEditing, editIcon } = useEditContext(onDeleteClick);

  return (
    <FormField
      label={label}
      dropDownProps={dropDownProps}
      inputId={inputId}
      startEditing={startEditing}
      editIcon={editIcon}
    >
      <NumberInput
        value={value}
        onBlur={onChange}
        disabled={disabled}
      />
    </FormField>
  );
};
