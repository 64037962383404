import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
  canvas {
    max-width: 500px;
    max-height: 500px;
  }
`;


const Canvas = styled.canvas`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: crosshair;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Styled = {
  Container,
  Canvas,
  SpinnerContainer,
};
