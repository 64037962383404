import { UrlBuilder } from 'common/utils/url-builder';

export class ParametrizedAppRoute<TParams> {
  public readonly path: string;

  constructor(path: string) {
    this.path = path;
  }

  public url(params: TParams): string {
    return UrlBuilder.build(this.path, params);
  }
}
