import styled from 'styled-components';

const Container = styled.div`
  overflow-y: auto;
  height: calc(100% - 50px);
`;

export const Styled = {
  Container,
};
