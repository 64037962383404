import autobind from 'autobind-decorator';
import * as React from 'react';

import { MaterialMenuItem, MaterialSelect } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { CEActivityAssignmentDataMaterial } from 'unit-cost-estimate/interfaces';
import { CEActivityAssignmentUtils } from 'unit-cost-estimate/utils/ce-activity-assignment-utils';


interface Props {
  materialId: number;
  material: CEActivityAssignmentDataMaterial;
  selectMaterial: (materialId: number, materialVariantId: number) => void;
}


export class ActivityAssignmentSelectMaterialVariant extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { material } = this.props;
    const selectedId = CEActivityAssignmentUtils.getSelectedVariantId(material.selectedVariant);
    const className = material.selectedVariant.manualId
      ? 'activity-assignment-activity-tile--manually-changed'
      : null;

    return (
      <MaterialSelect
        displayedType={MaterialComponentType.Native}
        value={selectedId}
        onChange={this.onChangeMaterial}
        dropdownClassName='activity-assignment-activity-tile__dropdown'
        className={className}
      >
        {
          material.variants.map(x => (
            <MaterialMenuItem
              key={x.id}
              value={x.id}
            >
              {x.name}
            </MaterialMenuItem>
          ))
        }
      </MaterialSelect>
    );
  }

  @autobind
  private onChangeMaterial(_e: React.SyntheticEvent<Element>, value: number): void {
    const { materialId } = this.props;
    this.props.selectMaterial(materialId, value);
  }
}
