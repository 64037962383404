import styled from 'styled-components';
import { ResultStatusStyled } from './results-status';
import { PageInfoStyled } from './text-search-result-body';

const Container = styled.div`
  ${ResultStatusStyled.Container} + ${PageInfoStyled.Container} {
    padding-top: 5px;
  }

  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const TextSearchResultContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;

  ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
    border-width: 2px;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: ${p => p.theme.color.gray} !important;
  }
  ::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 5px;
    background: ${p => p.theme.color.background};
  }
`;

export const Styled = {
  Container,
  TextSearchResultContainer,
};
