import * as React from 'react';

import { ToggleIcon, ToggleSize } from '../../../components/toggle';
import { FilterOption } from './interfaces';


interface Props {
  option: FilterOption;
  selected: boolean;
}

export class FilterSelectOption extends React.Component<Props> {
  public render(): React.ReactNode {
    const { option, selected } = this.props;
    const name = option.name as string;
    return (
      <div
        className='filter-select-option'
        data-control-name={`filter-option-${option.value}`}
        title={`${option.name}`}
      >
        <ToggleIcon
          checked={selected}
          checkedColor='rgba(255, 255, 255, .4)'
          size={ToggleSize.Small}
        />
        <div className='filter-select-option__label'>
          {name.split('_').join(' ')}
        </div>
      </div>
    );
  }
}
