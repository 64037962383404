import autobind from 'autobind-decorator';
import * as React from 'react';
import { Styled } from './styled';

interface Props {
  value: string | number;
  onChange: (value: string | number) => void;
  className?: string;
  type?: string;
  onBlur?: (value?: string | number) => void;
  keysToBlur?: string[];
  placeholder?: string;
  autoFocus?: boolean;
}

export class TabItemChangeNameInput extends React.Component<Props> {
  private inputRef: HTMLInputElement;

  public componentDidMount(): void {
    if (this.props.autoFocus) {
      this.inputRef.focus();
      this.inputRef.select();
    }
  }

  public render(): JSX.Element {
    const { className, type, value, placeholder } = this.props;

    return (
      <Styled.TabItemChangeNameInput
        className={className}
        type={type}
        value={value}
        onBlur={this.onBlur}
        onKeyPress={this.onKeyPress}
        onChange={this.onChangeValue}
        placeholder={placeholder}
        ref={this.saveInputRef}
      />
    );
  }

  @autobind
  private saveInputRef(ref: HTMLInputElement): void {
    this.inputRef = ref;
  }

  @autobind
  private onChangeValue(e: any): void {
    const value = e.target.value;
    this.props.onChange(value);
  }

  @autobind
  private onBlur(): void {
    if (this.props.onBlur) {
      this.props.onBlur(this.props.value);
    }
  }

  @autobind
  private onKeyPress(e: any): void {
    if (this.props.keysToBlur && this.props.keysToBlur.includes(e.key)) {
      this.onBlur();
    }
  }
}
