import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  padding-top: 30px;
  width: 270px;
  height: 60px;
  border-radius: 0px 0px 15px 15px;
  box-sizing: border-box;
  background: transparent linear-gradient(
    180deg,
    ${p => p.theme.color.backgroundRush}00 0%,
    ${p => p.theme.color.background} 100%
  ) 0% 0% no-repeat padding-box;
  cursor: pointer;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;

  > svg {
    width: 10px !important;
    height: 10px !important;
    min-height: 10px !important;
    min-width: 10px !important;
    fill: ${p => p.theme.color.gray};
  }
`;


export const Styled = {
  Container,
  Icon,
};
