import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyMouse1: React.FC = () => {
  return (
    <svg
      width='20px'
      height='32px'
      viewBox='0 0 20 32'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1-m1' x='0' y='2' width='18' height='28' rx='9' />
        <rect id='path-2-m1' x='0' y='0' width='18' height='28' rx='9' />
        <path
          d='M9,0 L9,12 L18,12 L18,9 C18,4.02943725 13.9705627,-9.13077564e-16 9,0 Z'
          id='path-3-m1'
        />
        <rect id='path-4-m1' x='7' y='5' width='4' height='8' rx='2' />
      </defs>
      <g id='Hotkeys/20x32/icon-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icon-1' transform='translate(1.000000, 1.000000)'>
          <g id='Rectangle-2'>
            <use fill={KreoColors.f1} fillRule='evenodd' xlinkHref='#path-1-m1' />
            <rect
              stroke={KreoColors.f2}
              strokeWidth='1'
              x='0.5'
              y='2.5'
              width='17'
              height='27'
              rx='8.5'
            />
          </g>
          <g id='Rectangle-2'>
            <use fill={KreoColors.f1} fillRule='evenodd' xlinkHref='#path-2-m1' />
            <rect
              stroke={KreoColors.f2}
              strokeWidth='1'
              x='0.5'
              y='0.5'
              width='17'
              height='27'
              rx='8.5'
            />
          </g>
          <path
            d='M9,0.5 L9,12 L0.5,12 L0.5,9 C0.5,4.30557963 4.30557963,0.5 9,0.5 Z'
            id='Rectangle-2'
            fill='#FFFFFF'
          />
          <path
            d='M8.5,0.514461789 C4.03831026,0.773270689 0.5,4.47342702 0.5,9 L0.5,11.5 L8.5,11.5 L8.5,0.514461789 Z'
            id='Rectangle-2'
            stroke={KreoColors.f2}
          />
          <g id='Rectangle-2'>
            <use fill={KreoColors.f1} fillRule='evenodd' xlinkHref='#path-3-m1' />
            <path
              stroke={KreoColors.f2}
              strokeWidth='1'
              // eslint-disable-next-line max-len
              d='M9.5,0.514461789 L9.5,11.5 L17.5,11.5 L17.5,9 C17.5,4.47342702 13.9616897,0.773270689 9.5,0.514461789 Z'
            />
          </g>
          <g id='Rectangle-2'>
            <use fill={KreoColors.f1} fillRule='evenodd' xlinkHref='#path-4-m1' />
            <rect
              stroke={KreoColors.f2}
              strokeWidth='1'
              x='7.5'
              y='5.5'
              width='3'
              height='7'
              rx='1.5'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
