import * as THREE from 'three';

import { VisibleObject, Config as VisibleObjectConfig } from './visible-object';

export interface PlaneGeometry {
  width: number;
  height: number;
  canvas: HTMLCanvasElement;
}

interface Config extends VisibleObjectConfig<HTMLCanvasElement> {
  opacity: number;
  z: number;
  visible: boolean;
}

export class PdfPlane extends VisibleObject<HTMLCanvasElement, Config> {
  constructor(config: Config) {
    super(config);
  }

  public set visible(visible: boolean) {
    this._mesh.visible = visible;
  }

  public set z(z: number) {
    this._mesh.position.z = z;
  }

  public set opacity(opacity: number) {
    if (this._mesh instanceof THREE.Mesh) {
      this._mesh.material.opacity = opacity;
    }
  }

  protected render(sourceCanvas: HTMLCanvasElement): void {
    const { width, height } = sourceCanvas;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(sourceCanvas, 0, 0);
    const newMat = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
      map: new THREE.CanvasTexture(canvas),
      opacity: this._config.opacity,
      transparent: true,
    });
    const plane = new THREE.PlaneGeometry(width, height);
    const planeMesh = new THREE.Mesh(plane, newMat);
    planeMesh.position.x = width / 2;
    planeMesh.position.y = -height / 2;
    planeMesh.position.z = this._config.z;
    this._config.scene.add(planeMesh);
    this._mesh = planeMesh;
    this._mesh.visible = this._config.visible;
  }
}
