import { change } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionWith } from 'common/interfaces/action-with';
import { State } from 'common/interfaces/state';
import { Common } from '../../../actions';
import { CREATE_REPORT_TEMPLATE } from '../../../constants/forms';
import { ReportTemplateApi } from '../api';
import {
  CreateCompanyReportTemplate,
  CreateProjectReportTemplate,
  UpdateReportTemplate,
  UpdateReportVariables,
} from '../interfaces';
import { TwoDReportTemplateActions } from '../store-slice';

function* createReportTemplate(): SagaIterator {
  try {
    const { formValues } = yield select((st: State) => {
      return {
        companyId: st.account.selectedCompany.id,
        formValues: st.form[CREATE_REPORT_TEMPLATE].values,
      };
    });

    const payload: CreateCompanyReportTemplate = {
      name: formValues.name,
      fileKey: formValues.fileName,
    };

    const reportTempalte = yield call(ReportTemplateApi.createReportTemplate, payload);
    yield put(TwoDReportTemplateActions.addeTempalte(reportTempalte));
    yield put(TwoDReportTemplateActions.createTemplateSucced());
    yield put(change(CREATE_REPORT_TEMPLATE, 'name', ''));
    yield put(change(CREATE_REPORT_TEMPLATE, 'fileName', ''));
    yield put(Common.commonClearFiles());
  } catch (error) {
    yield put(TwoDReportTemplateActions.createTemplateSucced());
    console.error('report template: create report template failed');
  }
}

function* loadReportTemplates(): SagaIterator {
  try {
    const reportTemplates = yield call(ReportTemplateApi.getTemplates);
    yield put(TwoDReportTemplateActions.setTemplates(reportTemplates));
    yield put(TwoDReportTemplateActions.createTemplateSucced());
  } catch (error) {
    yield put(TwoDReportTemplateActions.createTemplateSucced());
    console.error('report template: load report template failed');
  }
}

function* deleteReportTemplate({ payload }: ActionWith<number>): SagaIterator {
  try {
    yield call(ReportTemplateApi.deleteTemplate, payload);
    yield put(TwoDReportTemplateActions.createTemplateSucced());
  } catch (error) {
    yield put(TwoDReportTemplateActions.createTemplateSucced());
    console.error('report template: delete report template failed');
  }
}

function* updateReportTemplate({ payload }: ActionWith<UpdateReportTemplate>): SagaIterator {
  try {
    yield call(ReportTemplateApi.updateTemplate, payload.id, payload.name);
    yield put(TwoDReportTemplateActions.createTemplateSucced());
  } catch (error) {
    yield put(TwoDReportTemplateActions.createTemplateSucced());
    console.error('report template: update report template failed');
  }
}

function* loadProjectReportTemplate({ payload }: ActionWith<number>): SagaIterator {
  try {
    if (payload) {
      const result = yield call(ReportTemplateApi.getApplyTemplate, payload);
      yield put(TwoDReportTemplateActions.setProjectReportTemplates(result));
    }
    yield put(TwoDReportTemplateActions.createTemplateSucced());
  } catch (error) {
    yield put(TwoDReportTemplateActions.createTemplateSucced());
    console.error('report template: load project report template failed');
  }
}

function* applyProjectReportTemplate({ payload }: ActionWith<CreateProjectReportTemplate>): SagaIterator {
  try {
    yield call(ReportTemplateApi.applyTemplate, payload);
    yield put(TwoDReportTemplateActions.loadProjectReportTemplate(payload.projectId));
    yield put(TwoDReportTemplateActions.createTemplateSucced());
  } catch (error) {
    yield put(TwoDReportTemplateActions.createTemplateSucced());
    console.error('report template: apply project report template failed');
  }
}

function* deleteProjectTemplate({ payload }: ActionWith<CreateProjectReportTemplate>): SagaIterator {
  try {
    yield call(ReportTemplateApi.deleteProjectTemplate, payload);
    yield put(TwoDReportTemplateActions.createTemplateSucced());
  } catch (error) {
    yield put(TwoDReportTemplateActions.createTemplateSucced());
    console.error('report template: delete project report tempalte failed');
  }
}

function* exportTemplate({ payload }: ActionWith<CreateProjectReportTemplate>): SagaIterator {
  try {
    yield call(ReportTemplateApi.exportTemplate, payload);
    yield put(TwoDReportTemplateActions.createTemplateSucced());
  } catch (error) {
    yield put(TwoDReportTemplateActions.createTemplateSucced());
    console.error('report template: export template failed');
  }
}

function* updateVariable({ payload }: ActionWith<UpdateReportVariables>): SagaIterator {
  try {
    yield call(ReportTemplateApi.updateVariableValues, payload);
    yield put(TwoDReportTemplateActions.createTemplateSucced());
  } catch (error) {
    yield put(TwoDReportTemplateActions.createTemplateSucced());
    console.error('report tempalte: update variable failed');
  }
}

export function* reportTemplateSagas(): SagaIterator {
  yield takeLatest(TwoDReportTemplateActions.createTeplate, createReportTemplate);
  yield takeLatest(TwoDReportTemplateActions.loadInfoReportTemplates, loadReportTemplates);
  yield takeLatest(TwoDReportTemplateActions.deleteReportTemplate, deleteReportTemplate);
  yield takeLatest(TwoDReportTemplateActions.updateReportTemplate, updateReportTemplate);
  yield takeLatest(TwoDReportTemplateActions.loadProjectReportTemplate, loadProjectReportTemplate);
  yield takeLatest(TwoDReportTemplateActions.applyProjectReportTemplate, applyProjectReportTemplate);
  yield takeLatest(TwoDReportTemplateActions.deleteProjectReport, deleteProjectTemplate);
  yield takeLatest(TwoDReportTemplateActions.exportTemplate, exportTemplate);
  yield takeLatest(TwoDReportTemplateActions.updateReportVariables, updateVariable);
}
