import styled from 'styled-components';
import { CustomDialogStyled } from 'common/components/custom-dialog';

const Container = styled.div`
  > div {
    > div {
      display: flex;
    }
  }

  ${CustomDialogStyled.Container} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  ${CustomDialogStyled.Body} {
    width: 100%;
    height: calc(100% - 60px);
    margin-bottom: unset;
    flex: 1;
    display: flex;
    flex-direction: column;
    > {
      flex: 1;
    }
  }
  ${CustomDialogStyled.Buttons} {
    margin-top: 20px;
    height: 40px;
  }
`;


export const Styled = {
  Container,
};
