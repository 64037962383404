import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { ButtonInput } from 'common/components/inputs';
import { Property } from 'common/components/property';
import { KreoDialogActions } from 'common/UIKit';

interface Props {
  value: string;
  canEdit: boolean;
}

const GroupPropertyComponent: React.FC<Props> = ({
  value,
  canEdit,
}) => {

  const dispatch = useDispatch();

  const startEdit = useCallback(() => {
    dispatch(KreoDialogActions.openDialog(DrawingDialogs.RENAME_TOOL_DIALOG));
  }, []);


  return (
    <Property title='Name' startEditing={canEdit ? startEdit : undefined}>
      <ButtonInput value={value} />
    </Property>
  );
};

export const NameProperty = React.memo(GroupPropertyComponent);
