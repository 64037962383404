import * as React from 'react';

interface Props {
  className?: string;
}

export const KreoIconSelectArrow: React.FC<Props> = (props: Props) => {
  return (
    <svg
      className={props.className}
      width='14'
      height='14'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Mockups' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect id='Rectangle-7' x='3' y='5' width='8' height='4' />
        <polyline
          id='Rectangle-5'
          stroke='currentColor'
          strokeLinecap='round'
          points='4 6 7 9 10 6'
        />
      </g>
    </svg>
  );
};
