import * as Ag from 'ag-grid-community';
import { AgGridCellWithComment, CommentContext } from 'common/components/ag-grid-cell-with-comment';
import { SEPARATOR_PROPERTY_KEY, TREE_PROPERTY_POSTFIX } from './utils/items-helper/utils';

interface Params extends Ag.ICellRendererParams, CommentContext<{ id?: number }> {
}

const getValue = (params: Params): string => {
  if (params.value && typeof params.value === 'string' && params.value.startsWith(TREE_PROPERTY_POSTFIX)) {
    return params.value.split(SEPARATOR_PROPERTY_KEY)[2];
  }

  return params.valueFormatted !== null && params.valueFormatted !== undefined
    ? params.valueFormatted
    : params.value;
};

export class DefaultCellRenderer implements Ag.ICellRendererComp {
  private eDiv: HTMLDivElement;
  private cellWithComment: AgGridCellWithComment<{ id?: number }>;

  public init(params: Params): void | Ag.AgPromise<void> {
    this.cellWithComment = new AgGridCellWithComment(params);
    this.eDiv = document.createElement('div');
    this.eDiv.style.width = '100%';
    this.eDiv.style.height = '100%';

    const value = getValue(params);
    this.eDiv.innerText = value === null || value === undefined
      ? ''
      : value;
    const commentContainer = this.cellWithComment.getCommentHightLight(this.eDiv);
    if (commentContainer) {
      this.eDiv.appendChild(commentContainer);
    }
  }

  public refresh(): boolean {
    return false;
  }

  public getGui(): HTMLElement {
    return this.eDiv;
  }
}
