import {
  DeferredUpdater,
  DeferredUpdaterFactory,
  OnExecFuncType,
} from 'common/utils/deferred-updater';
import { QtoTreeColumnsForm } from '../../interfaces/quantity-take-off/quantity-take-off-report-column-form';
import { QtoTreeRowForm } from '../../interfaces/quantity-take-off/quantity-take-off-report-row-form';


const aggregateUpdateColumns = (_: QtoTreeColumnsForm | null, data: QtoTreeColumnsForm): QtoTreeColumnsForm => {
  return data;
};

const aggregateUpdateRows = (rows: QtoTreeRowForm[] | null, newRows: QtoTreeRowForm[]): QtoTreeRowForm[] => {
  if (!rows) {
    return newRows;
  }

  const idToIndex = {};
  rows.forEach((x, index) => idToIndex[x.id] = index);
  const result = rows.slice();
  newRows.forEach(x => {
    if (x.id in idToIndex) {
      result[idToIndex[x.id]] = x;
    } else {
      result.push(x);
    }
  });

  return result;
};


interface CallBacks {
  onColumnsUpdate: (reportId: number, form: QtoTreeColumnsForm) => void;
  onRowsUpdate: (reportId: number, form: QtoTreeRowForm[]) => void;
}

export class QtoReportPivotTableUpdater {
  private columnUpdateExecuter: DeferredUpdater<QtoTreeColumnsForm>;
  private rowUpdateExecuter: DeferredUpdater<QtoTreeRowForm[]>;

  constructor(reportId: number, delay: number, callBacks: CallBacks) {
    const factory = new DeferredUpdaterFactory(delay, delay * 5);

    this.columnUpdateExecuter = factory.create(
      this.getDeferredUpdaterCallBack(reportId, callBacks.onColumnsUpdate),
      aggregateUpdateColumns,
    );
    this.rowUpdateExecuter = factory.create(
      this.getDeferredUpdaterCallBack(reportId, callBacks.onRowsUpdate),
      aggregateUpdateRows,
    );
  }

  public executeImmediatelyAll(): void {
    this.columnUpdateExecuter.executeImmediatelyIfDeferred();
    this.rowUpdateExecuter.executeImmediatelyIfDeferred();
  }

  public cancelAll(): void {
    this.columnUpdateExecuter.cancel();
    this.rowUpdateExecuter.cancel();
  }

  public onColumnsUpdate(updateModel: QtoTreeColumnsForm): void {
    this.columnUpdateExecuter.execute(updateModel);
  }

  public onRowsUpdate(data: QtoTreeRowForm[]): void {
    this.rowUpdateExecuter.execute(data);
  }

  private getDeferredUpdaterCallBack<T>(
    reportId: number, func: (reportId: number, form: T) => void,
  ): OnExecFuncType<T> {
    return (form: T) => func(reportId, form);
  }
}
