import autobind from 'autobind-decorator';

import { DrawingsApi } from 'common/components/drawings/api/drawings-api';
import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import {
  DrawingsPolygonGeometry,
  ShortPointDescription,
  WizzardToolsState,
} from 'common/components/drawings/interfaces';
import {
  PdfGeometry,
  PdfGeometryResponse,
  PdfGeometryStatus,
} from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { arrayUtils } from 'common/utils/array-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { UuidUtil } from 'common/utils/uuid-utils';
import { CursorHintType } from '../../../layout-components/drawings-cursor-hint/cursor-hint-type';
import { DrawingsCursorTypeHelper } from '../../drawings-helpers';
import { ThreeDParamsHelper } from '../../drawings-helpers/threed-params-helper';
import { BucketAI } from '../../drawings-helpers/wizzard';
import { BatchedUpdateCallback, NewDrawingSettings, SetCursorHintCallback } from '../../interfaces';
import { FinishedDrawingElement } from './drawings-geometry-temp-entity';
import {
  DrawingsGeometryTempEntityWithStroke,
  DrawingsGeometryTempEntityWithStrokeConfig,
} from './drawings-geometry-temp-entity-with-stroke';


interface Config extends DrawingsGeometryTempEntityWithStrokeConfig {
  setCursorMessage: SetCursorHintCallback;
  wizzardSettingsObserver: ContextObserver<WizzardToolsState>;
  onEnableContextMenu: (canClear: boolean, canFinish) => void;
  onFinish: () => void;
  cursorTypeHelper: DrawingsCursorTypeHelper;
  colorsCacheHelper: DrawingsColorCacheHelper;
  newDrawingStylesObserver: ContextObserver<NewDrawingSettings>;
  onBatchUpdateGeometries: BatchedUpdateCallback;
  oneClickAreaHoverContextObserver: ContextObserver<boolean>;
}

export class BucketFill extends DrawingsGeometryTempEntityWithStroke<Config> {
  private _isProcessing: boolean = false;
  private _hideMessageExecutor: DeferredExecutor = new DeferredExecutor(3000);
  private _destroyed: boolean = false;
  private _geometry: PdfGeometry[] = [];
  private _bucketAI: BucketAI;

  constructor(config: Config) {
    super(config);
    this._bucketAI = new BucketAI({
      layer: this._config.layer,
      wizzardSettingsObserver: this._config.wizzardSettingsObserver,
      renderParamsContextObserver: this._config.renderParametersContextObserver,
      colorCache: this._config.colorsCacheHelper,
      newDrawingStylesObserver: this._config.newDrawingStylesObserver,
      onBatchUpdateGeometries: this._config.onBatchUpdateGeometries,
      setCursorMessage: this.setMessageWithHide,
      getCurrentDrawingInfo: this._config.getDrawingInfo,
      oneClickAreaHoverContextObserver: this._config.oneClickAreaHoverContextObserver,
    });
    this._config.wizzardSettingsObserver.subscribe(this.onWizzardSettingsChanged);
  }

  public override updateTempPointPosition(point: paper.Point, strictAngle?: boolean): paper.Point {
    const { boostWithAi } = this._config.wizzardSettingsObserver.getContext();
    if (boostWithAi) {
      this._bucketAI.onHover(point);
      return point;
    }
    return super.updateTempPointPosition(point, strictAngle);
  }


  public override get hasPoint(): boolean {
    const { boostWithAi } = this._config.wizzardSettingsObserver.getContext();
    return boostWithAi && this._bucketAI.hasGeometies();
  }

  public override removeLastPoint(): paper.Point {
    const { boostWithAi } = this._config.wizzardSettingsObserver.getContext();
    if (boostWithAi) {
      const removeResult = this._bucketAI.removeLastPoint();
      this.showMessage(null);
      this._config.cursorTypeHelper.loading = false;
      this._isProcessing = false;
      this._config.onEnableContextMenu(false, false);
      if (removeResult[1]) {
        this.processAIClick(removeResult[1]);
      }
      if (removeResult[0]) {
        return removeResult[0];
      }
    }
    return null;
  }

  public override tryAddPoint(point: paper.Point): boolean {
    if (this._isProcessing) {
      return true;
    }

    const drawingInfo = this._config.getDrawingInfo();
    this.showMessage(CursorHintType.BucketFillInProgress);

    const { boostWithAi } = this._config.wizzardSettingsObserver.getContext();

    if (boostWithAi) {
      this._config.onEnableContextMenu(false, false);
      this.processAIClick(this._bucketAI.addPoint(point));
    } else {
      this._isProcessing = true;
      this._config.cursorTypeHelper.loading = true;
      const { pdfId, drawingId } = drawingInfo;
      const promise = DrawingsApi.processPdfClick(pdfId, drawingId, {
        position: [point.x, point.y],
        filled: true,
        sameStyle: false,
        sameGeometry: false,
        allowRotation: false,
        allowFlipping: false,
        findText: false,
      });
      this.processClick(promise);
    }
    return true;
  }

  public override canComplete(): boolean {
    const { boostWithAi } = this._config.wizzardSettingsObserver.getContext();
    return boostWithAi ? (this._bucketAI?.hasGeometies() && this._bucketAI?.isValid()) : !!this._geometry.length;
  }

  public override convert(): FinishedDrawingElement[] {
    const { boostWithAi } = this._config.wizzardSettingsObserver.getContext();
    if (boostWithAi) {
      const geometry = this._bucketAI.getGeometry();
      return geometry;
    }
    const { color, strokeWidth, strokeStyle } = this._config;
    const points: Record<string, ShortPointDescription> = {};
    const childrenPoints: string[][] = [];
    const geometriesByParent = arrayUtils.groupBy(this._geometry, x => x.parentId);
    const rootPoints = [];
    const root = geometriesByParent[0][0];
    const children = root.id !== root.parentId && geometriesByParent[root.id];
    for (const point of root.points) {
      const pointId = UuidUtil.generateUuid();
      points[pointId] = point;
      rootPoints.push(pointId);
    }
    if (children) {
      for (const child of children) {
        const childPoints = [];
        for (const point of child.points) {
          const pointId = UuidUtil.generateUuid();
          points[pointId] = point;
          childPoints.push(pointId);
        }
        childrenPoints.push(childPoints);
      }
    }

    const geometry: DrawingsPolygonGeometry = {
      color: color.fill.toCSS(true),
      strokeWidth,
      strokeStyle,
      points: rootPoints,
      children: childrenPoints.length ? childrenPoints : undefined,
    };
    ThreeDParamsHelper.addToPolygon(geometry, this._config.newDrawingStylesObserver.getContext());
    return [
      {
        type: DrawingsInstanceType.Polygon,
        geometry,
        points,
      },
    ];
  }

  public override destroy(): void {
    super.destroy();
    this._config.setCursorMessage(null);
    this._destroyed = true;
    this._config.cursorTypeHelper.loading = false;
    this._config.wizzardSettingsObserver.unsubscribe(this.onWizzardSettingsChanged);
    this._bucketAI.destroy();
  }

  private processAIClick(promise: Promise<PdfGeometryResponse>): void {
    promise.then((result) => {
      this._isProcessing = false;
      this._config.cursorTypeHelper.loading = false;
      if (this._destroyed || !result) {
        this._config.setCursorMessage(null);
        return;
      }
      if (result.status !== PdfGeometryStatus.Succcess) {
        if (result.status !== PdfGeometryStatus.IGNORE) {
          this._config.setCursorMessage(CursorHintType.WizzardPolylineNotFound);
          this._hideMessageExecutor.execute(() => {
            this._config.setCursorMessage(null);
          });
        }
      } else {
        this._config.setCursorMessage(null);
        this._geometry = result.geometries;
        this._config.onEnableContextMenu(true, true);
      }
    });
  }

  private processClick(promise: Promise<PdfGeometryResponse>): void {
    promise.then((result) => {
      this._isProcessing = false;
      this._config.cursorTypeHelper.loading = false;
      if (this._destroyed) {
        return;
      }
      if (result.status !== PdfGeometryStatus.Succcess) {
        if (result.status !== PdfGeometryStatus.IGNORE) {
          this._config.setCursorMessage(CursorHintType.WizzardPolylineNotFound);
          this._hideMessageExecutor.execute(() => {
            this._config.setCursorMessage(null);
          });
        }
      } else {
        this._config.setCursorMessage(null);
        this.saveMeasurement(result.geometries);
        this._config.onEnableContextMenu(true, true);
      }
    });
  }

  private saveMeasurement(payload: PdfGeometry[]): void {
    this._geometry = payload;
    this._config.onFinish();
  }

  private showMessage(hint: CursorHintType): void {
    this._hideMessageExecutor.reset();
    this._config.setCursorMessage(hint);
  }

  @autobind
  private setMessageWithHide(message: CursorHintType): void {
    this.showMessage(message);
    this._hideMessageExecutor.execute(() => {
      this._config.setCursorMessage(null);
    });
  }

  @autobind
  private onWizzardSettingsChanged(state: WizzardToolsState): void {
    if (!state.boostWithAi) {
      this._bucketAI.clear();
    }
  }
}
