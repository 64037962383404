import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './measurements-extractor-input.scss';

import { State } from 'common/interfaces/state';
import { CEMeasurementsActions } from 'unit-cost-estimate/actions/creators';
import { CEMeasurementsUtils } from 'unit-cost-estimate/utils';


interface OwnProps {
  engineId?: number;
  onItemSelect: (ids: number[]) => void;
  onChange?: (functionId: string, itemIndex: number, value: number) => void;
  itemIndex?: number;
  disabled: boolean;
  functionId: string;
  generalFunctionId?: string;
  isTotal: boolean;
}


interface StateProps {
  value: React.ReactText;
  possibleValues: React.ReactText[];
  isManualChanged: boolean;
}

interface DispatchProps {
  onChangeValue: (value: string) => void;
  onChangeGeneralValue: (value: string) => void;
  onBlur: () => void;
}

interface Props extends StateProps, OwnProps, DispatchProps {

}

interface ComponentState {
  value: React.ReactText;
}

class ExtractorInput extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  public render(): JSX.Element {
    const { disabled, value, possibleValues, isManualChanged } = this.props;
    const placeholder = !value && possibleValues
      ? `(${possibleValues.join(', ')})`
      : null;

    const className = classNames(
      {
        'measurements-extractor-input-container--manual-changed': isManualChanged,
      },
      'measurements-extractor-input-container');

    return (
      <div className={className}>
        <input
          className={className}
          type='text'
          value={value === null ? '' : value}
          onBlur={this.onBlur}
          onChange={this.onChangeValue}
          onFocus={this.onFocus}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
    );
  }

  @autobind
  private onChangeValue(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    if (value !== '' && (!/^\d+[,\.]?\d*$/.test(value) || /^0[0-9]/gm.test(value))) {
      return;
    }
    const processedValue = value.replace(',', '.');
    if (this.props.isTotal) {
      this.props.onChangeGeneralValue(processedValue);
    } else {
      this.props.onChangeValue(processedValue);
    }
  }


  @autobind
  private onFocus(e: React.FocusEvent<HTMLInputElement>): void {
    e.isDefaultPrevented();
    if (this.props.engineId) {
      this.props.onItemSelect([this.props.engineId]);
    } else {
      this.props.onItemSelect([]);
    }
  }

  @autobind
  private onBlur(): void {
    this.props.onBlur();
  }
}


function mapStateToProps({ ceMeasurements }: State, ownProps: OwnProps): StateProps {
  let value: React.ReactText = 0;
  let possibleValues: React.ReactText[];
  let isManualChanged: boolean = false;
  const { functionId, itemIndex, isTotal } = ownProps;
  if (isTotal) {
    value = ceMeasurements.generalExtractorEditors[functionId].value;
  } else {
    const valueGroup = ceMeasurements.extractorEditorRows[itemIndex].data.values[functionId];
    isManualChanged = CEMeasurementsUtils.isManualChangedGroup(valueGroup);
    value = valueGroup.commonValue;
    if (value === null) {
      possibleValues = Array.from(new Set(Object.values(valueGroup.valuesMap)
        .map(x => CEMeasurementsUtils.getMeasurementValue(x.value))));
    }
  }
  return {
    value,
    possibleValues,
    isManualChanged,
  };
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
  const { functionId, itemIndex, generalFunctionId } = ownProps;
  return {
    onChangeValue: (value) => {
      dispatch(CEMeasurementsActions.onChangeInputValue(itemIndex, value, functionId, generalFunctionId));
    },
    onChangeGeneralValue: (value) => {
      dispatch(CEMeasurementsActions.onChangeGeneralInputValue(value, functionId, generalFunctionId));
    },
    onBlur: () => {
      dispatch(CEMeasurementsActions.onBlurEdit());
    },
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const MeasurementsExtractorInput = connector(ExtractorInput);
