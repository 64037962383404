import { END, EventChannel, eventChannel } from 'redux-saga';
import { select, SelectEffect, Tail } from 'redux-saga/effects';
import { State } from 'common/interfaces/state';

type SelectorFn<T> = (state: State, ...args) => T;

export function selectWrapper<T, Fn extends SelectorFn<T> = SelectorFn<T>>(
  selector: Fn,
  ...args: Tail<Parameters<Fn>>
): SelectEffect {
  return select<Fn>(selector, ...args);
}


export function createSagaEventsChannel(subscribe: (emitter: (input: any | END) => void) => void): EventChannel<any> {
  return eventChannel((emitter: (input: any) => void) => {
    subscribe(emitter);
    return () => null;
  });
}
