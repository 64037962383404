import * as React from 'react';

export const KreoIconFilter = (): JSX.Element => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
  >
    <g
      id='Icons/24x24/Filter'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <polygon
        fill='#B3D1FF'
        fillRule='nonzero'
        points='14.5 13 14.5 14 9.5 14 9.5 13'
      />
      <path
        // eslint-disable-next-line max-len
        d='M18.9050189,4 L5.09498111,4 C4.61379792,4 4.14874491,4.17348027 3.78512003,4.48862183 L3.66550567,4.60106098 C2.94342079,5.33727831 2.89635746,6.51688552 3.58360294,7.30986108 L9,13.559 L9,19.190983 C9,19.9485271 9.42800475,20.6410534 10.1055728,20.9798374 L12.8291796,22.3416408 L12.9496208,22.3956605 C13.9185312,22.7816734 15,22.0704701 15,21 L15,13.558 L20.4163971,7.30986108 C20.7315386,6.9462362 20.9050189,6.48118318 20.9050189,6 C20.9050189,4.8954305 20.0095884,4 18.9050189,4 Z M18.9050189,5.5 C19.1811613,5.5 19.4050189,5.72385763 19.4050189,6 C19.4050189,6.1202958 19.3616488,6.23655905 19.2828634,6.32746527 L13.7443109,12.7181028 C13.5867401,12.8999152 13.5,13.1324417 13.5,13.3730333 L13.5,21 L10.7763932,19.6381966 C10.6070012,19.5535006 10.5,19.380369 10.5,19.190983 L10.5,13.3730333 C10.5,13.1324417 10.4132599,12.8999152 10.2556891,12.7181028 L4.71713657,6.32746527 C4.53628249,6.11878749 4.55883806,5.80300953 4.76751584,5.62215546 C4.85842206,5.54337007 4.97468531,5.5 5.09497327,5.5 L18.9050189,5.5 Z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </g>
  </svg>
);
