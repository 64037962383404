import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TwoDViewTableType } from '2d/constants/table-type';
import { AddNewSheetStatuses, ReportPage, ReportPageType } from '2d/interfaces/report-pages';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { UserAbility } from 'common/ability/user-ability';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { TabsList } from 'common/components/tabs-list';
import { ColorList } from 'common/constants/color-list';
import { State } from 'common/interfaces/state';
import { MultiViewersStates } from 'common/utils/multi-view/multi-view-states';
import { CommentaryTargetTypeGuards } from 'unit-2d-comments/index';
import { CommentaryThread } from 'unit-2d-comments/interfaces';
import { TwoDViewTableConfig } from 'unit-2d-database/interfaces';
import { ReportTab } from './interfaces';
import { getPageCommentCountMark } from './page-comment-count-mark';
import { Styled } from './styled';
import { getTableTabContext } from './table-tab-context-menu';
import { TableTabsCreateButton } from './table-tabs-create-button';

interface Props extends AbilityAwareProps {
  tabList: ReportPage[];
  activeTabId: string;
  onClickTab: (id: string) => void;
  onDeleteTab: (id: string, name: string) => void;
  onDuplicateTab: (id: string) => void;
  onCreateSpreadSheet: () => void;
  onCreateMeasureView: () => void;
  onCreateItemView: () => void;
  onChangeTabInfo: (pageInfo: ReportTab) => void;
  onMoveTo: (id: string, offset: number) => void;
  addNewSheetStatus: AddNewSheetStatuses;
  canEditReport: boolean;
  isFullScreenDrawings: boolean;
  isFullScreenTableReport: boolean;
  comments: CommentaryThread[];
}

const useTabList = (pages: ReportPage[], comments: CommentaryThread[], ability: UserAbility): ReportTab[] => {
  const viewConfigs = useSelector<State, Record<string, TwoDViewTableConfig>>((s) => s.twoD.viewsConfigs);
  return useMemo(() => {
    const tabComments = {};
    for (const comment of comments) {
      if (!comment.resolved && CommentaryTargetTypeGuards.isReportPage(comment.target)) {
        tabComments[comment.target.reportPageId] = tabComments[comment.target.reportPageId] || 0;
        tabComments[comment.target.reportPageId]++;
      }
    }

    return pages.map((p) => {
      const shouldUpgradePlan =
        p.type === ReportPageType.MeasureView &&
        viewConfigs[p.id]?.sourceType === TwoDViewTableType.Items &&
        ability.cannot(Operation.Read, Subject.PiaDatabase);
      return {
        ...p,
        postfix: getPageCommentCountMark(p.id, tabComments),
        shouldUpgradePlan,
      };
    });
  }, [pages, comments, viewConfigs]);
};

const TableTabsComponent: React.FC<Props> = ({
  onClickTab,
  activeTabId,
  addNewSheetStatus,
  canEditReport,
  isFullScreenDrawings,
  isFullScreenTableReport,
  comments,
  onChangeTabInfo,
  onDeleteTab,
  onDuplicateTab,
  onMoveTo,
  onCreateSpreadSheet,
  onCreateMeasureView,
  onCreateItemView,
  tabList,
  ability,
}) => {
  const tabs = useTabList(tabList, comments, ability);

  const isAddNewSheetLoading = addNewSheetStatus === AddNewSheetStatuses.LOADING;
  const addButtonDisabled = isAddNewSheetLoading || !canEditReport;

  const direction = 'horizontal';
  const itemTooltipPosition = 'right';
  const ContextMenu = getTableTabContext({ canEditReport, onChangeTabInfo, onDeleteTab, onDuplicateTab, onMoveTo });
  const isHideNewWindowButton = MultiViewersStates.isDisable();

  return (
    <Styled.Container isNewWindowButton={!isHideNewWindowButton}>
      <Styled.TabsListContainer>
        <TabsList
          ContextMenu={ContextMenu}
          activeTabId={activeTabId}
          canEdit={canEditReport}
          onChangeTabInfo={onChangeTabInfo}
          onClickTab={onClickTab}
          onMoveTo={onMoveTo}
          tabList={tabs}
          itemTooltipPosition={itemTooltipPosition}
          direction={direction}
          defaultColor={ColorList[0]}
        />
      </Styled.TabsListContainer>
      <TableTabsCreateButton
        isAddNewSheetLoading={isAddNewSheetLoading}
        addButtonDisabled={addButtonDisabled}
        createSpreadSheet={onCreateSpreadSheet}
        createMeasurementsView={onCreateMeasureView}
        createItemView={onCreateItemView}
        isFullScreenDrawings={isFullScreenDrawings}
        isFullScreenTableReport={isFullScreenTableReport}
        isReports={!!tabs.length}
      />
    </Styled.Container>
  );
};

export const TableTabs = withAbilityContext(TableTabsComponent);
