import Axios, { Canceler } from 'axios';

import {
  CompanyResourcesApi,
  makeRequestWithCancelation,
  ProjectResourcesApi,
  ProgressData,
} from '../../../../api/server';
import {
  CreateDrawingGroupsResponse,
  CreateDrawingGroupsTree,
  MoveDrawingGroupTree,
  UpdateDrawingGroupTree,
} from '../actions/payloads/drawings-annotation-legend';
import { DrawingsBeUpdatesState, DrawingsSelectedPagesUpdates, Rescale } from '../actions/payloads/update';
import { DrawingsDeleteAnnotations } from '../actions/payloads/user-annotation';
import {
  AutoMeasureOption,
  DrawingsAutocompleteResponse,
  DrawingsShortInfo,
  GeometryGroupsResponse,
  GeometryGroupsResponseC,
  GeometryInstanceUpdateResponse,
  GeometryInstanceUpdateResponseC,
  DrawingsGeometryGroup,
  DrawingsGeometryGroupsC,
} from '../interfaces';
import {
  PdfAiPolygonClickPayloadExtended,
  PdfClickPayload,
  PdfFindPayload,
  PdfSelectionPayload,
} from '../interfaces/api-payloads';
import {
  PdfGeometryResponse,
  PdfStatistic,
} from '../interfaces/api-responses/pdf-geometry-response';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import { DrawingsPageResponse } from '../interfaces/drawings-api-payload';
import { DrawingsFile } from '../interfaces/drawings-file-info';
import {
  DrawingsGeometryInstance,
  DrawingsGeometryInstanceUpdatePayload,
} from '../interfaces/drawings-geometry-instance';
import {
  DrawingsGroupTemplate,
  DrawingsGroupTemplateC,
  DrawingsGroupTemplatesC,
  GroupTemplateShortInfo,
} from '../interfaces/drawings-group-template';
import { DrawingsDuplicatePage, DrawingsPdfExport } from '../interfaces/drawings-pdf';
import {
  DrawingUserAnnotationImage,
  DrawingUserAnnotationRuler,
  DrawingUserAnnotationSticker,
} from '../interfaces/drawings-user-annotation';

function loadFileSystem(projectId: number): Promise<any> {
  return CompanyResourcesApi.get(`/projects/${projectId}/drawings/filesystem`);
}

function loadAutoMeasureAvailableOptions(): Promise<any> {
  return CompanyResourcesApi.get('/recognition/available-categories');
}

function getFileData(projectId: number, fileId: string): Promise<DrawingsFile> {
  return CompanyResourcesApi.get(`/projects/${projectId}/drawings/filesystem/${fileId}`);
}

function getFile(
  id: string,
  onProgres: (progressEvent: any) => void,
  isOptimized: boolean = false,
): [Promise<ArrayBuffer>, Canceler] {
  const cancelTokenSource = Axios.CancelToken.source();
  const promise = ProjectResourcesApi.getBinary(
    `/drawings/files/${id}${isOptimized ? '/rasterized' : ''}`,
    null,
    cancelTokenSource,
    onProgres,
  );
  return [promise, cancelTokenSource.cancel];
}

interface GetPagePalyload {
  fileId: string;
  pageNumber: number;
  rasterized: boolean;
  drawingId: string;
  onProgress: (progressEvent: any) => void;
  isFiltered: boolean;
}

function getPage({
  fileId,
  pageNumber,
  rasterized,
  onProgress,
  isFiltered,
  drawingId,
}: GetPagePalyload): [Promise<ArrayBuffer>, Canceler] {
  const cancelTokenSource = Axios.CancelToken.source();

  let url = `/drawings/files/${fileId}`;

  if (isFiltered) {
    url += `/pages/${drawingId}/filter/pdf`;
  } else {
    url += `/${pageNumber}${rasterized ? '/rasterized' : ''}`;
  }

  const promise = ProjectResourcesApi.getBinary(
    url,
    null,
    cancelTokenSource,
    onProgress,
  );
  return [promise, cancelTokenSource.cancel];
}

function measurementAutocomplete(
  response: DrawingsAutocompleteResponse,
): [Promise<{ points: ShortPointDescription[] }>, Canceler] {
  return makeRequestWithCancelation(
    (cancelationSource) => ProjectResourcesApi.post('/ai/autocomplete', response, null, cancelationSource),
  );
}

function update(projectId: number, payload: DrawingsBeUpdatesState): Promise<void> {
  return CompanyResourcesApi.put(`/projects/${projectId}/drawings`, payload);
}

function updateSelectedPages(form: DrawingsSelectedPagesUpdates): Promise<void> {
  return ProjectResourcesApi.put('/drawings/selection', form);
}

function getScreenshotLink(fileId: string, pageId: string): string {
  const projectUrl = `/drawings/filesystem/${fileId}/pages/${pageId}/screenshot`;
  return ProjectResourcesApi.getLink(projectUrl);
}

function calculateAnnotationData(
  projectId: number,
  docIds: string[],
): Promise<any> {
  return CompanyResourcesApi.post(`/projects/${projectId}/ai/pdf-recognition`, { docIds });
}

export interface DrawingsGeometryPayload {
  geometry: Record<string, DrawingsGeometryInstance>;
  points: Record<string, ShortPointDescription>;
}

async function getMarkups(
  projectId: number,
  fileId: string,
  pageId: string,
): Promise<DrawingsGeometryPayload> {
  return CompanyResourcesApi.get(`/projects/${projectId}/drawings/files/${fileId}/pages/${pageId}/markup`);
}

function updateMarkups(
  projectId: number,
  fileId: string,
  pageId: string,
  data: DrawingsGeometryInstanceUpdatePayload,
): Promise<GeometryInstanceUpdateResponse> {
  return CompanyResourcesApi.putV(
    `/projects/${projectId}/drawings/files/${fileId}/pages/${pageId}/markup`,
    data,
    GeometryInstanceUpdateResponseC,
  );
}

function getDrawingsGroups(projectId: number): Promise<GeometryGroupsResponse> {
  return CompanyResourcesApi.getV(`/projects/${projectId}/drawings/groups`, GeometryGroupsResponseC);
}

function addDrawingsGroups(projectId: number, data: CreateDrawingGroupsTree): Promise<CreateDrawingGroupsResponse> {
  return CompanyResourcesApi.post(`/projects/${projectId}/drawings/groups`, data);
}

function deleteDrawingsGroups(projectId: number, groupIds: string[]): Promise<void> {
  return CompanyResourcesApi.delete(`/projects/${projectId}/drawings/groups`, groupIds);
}

function updateDrawingsGroups(projectId: number, data: UpdateDrawingGroupTree): Promise<void> {
  return CompanyResourcesApi.put(`/projects/${projectId}/drawings/groups`, data);
}

function moveDrawingsGroups(projectId: number, data: MoveDrawingGroupTree): Promise<void> {
  return CompanyResourcesApi.put(`/projects/${projectId}/drawings/groups/reorder`, data);
}


function startAutoMeasureCalculation(
  settings: AutoMeasureOption[],
  fileId?: string,
  pageId?: string,
): Promise<void> {
  const url = fileId && pageId
    ? `/drawings/files/${fileId}/pages/${pageId}/recognition/measurements`
    : `/drawings/recognition/measurements`;

  return ProjectResourcesApi.post(url, { filters: settings });
}

function reScale(
  projectId: number,
  data: Rescale[],
): Promise<void> {
  return CompanyResourcesApi.put(
    `/projects/${projectId}/drawings/pages/rescale`,
    data,
  );
}

function exportPdfFile(
  projectId: number,
  fileId: string,
  data: DrawingsPdfExport,
  onProgres: (progressEvent: ProgressData) => void,
): Promise<void> {
  return CompanyResourcesApi.downloadFileWithData(
    `/projects/${projectId}/drawings/files/${fileId}/export`,
    data,
    undefined,
    onProgres,
  );
}

function exportAndMergePdfPages(
  projectId: number,
  data: DrawingsPdfExport,
  onProgres: (progressEvent: ProgressData) => void,
): Promise<void> {
  return CompanyResourcesApi.downloadFileWithData(
    `/projects/${projectId}/drawings/export`,
    data,
    undefined,
    onProgres,
  );
}

function duplicatePage(
  projectId: number,
  fileId: string,
  pageId: string,
  data: DrawingsDuplicatePage,
): Promise<DrawingsPageResponse> {
  return CompanyResourcesApi
    .post(`/projects/${projectId}/drawings/filesystem/${fileId}/pages/${pageId}/duplicate`, data);
}

function getGroupTemplates(): Promise<DrawingsGroupTemplate[]> {
  return CompanyResourcesApi.getV(`/group-templates`, DrawingsGroupTemplatesC);
}

function updateGroupTemplate(templateId: number, template: GroupTemplateShortInfo): Promise<void> {
  return CompanyResourcesApi.put(`/group-templates/${templateId}`, template);
}

function duplicateGroupTemplate(
  templateId: number,
  template: GroupTemplateShortInfo,
): Promise<DrawingsGroupTemplate> {
  return CompanyResourcesApi.postV(`/group-templates/${templateId}/duplicate`, template, DrawingsGroupTemplateC);
}

function deleteGroupTemplate(templateId: number): Promise<void> {
  return CompanyResourcesApi.delete(`/group-templates/${templateId}`, null);
}

function createGroupTemplate(template: GroupTemplateShortInfo): Promise<GroupTemplateShortInfo> {
  return ProjectResourcesApi.postV(`/drawings/groups/template`, template, DrawingsGroupTemplateC);
}

function applyGroupTemplate(templateId: number): Promise<DrawingsGeometryGroup[]> {
  return ProjectResourcesApi.putV(`/drawings/groups/template/${templateId}`, null, DrawingsGeometryGroupsC);
}

function addStickers(
  fileId: string,
  pageId: string,
  data: DrawingUserAnnotationSticker[],
): Promise<void> {
  return ProjectResourcesApi.post(
    `/drawings/files/${fileId}/pages/${pageId}/annotations/sticker`,
    data,
  );
}

function addRulers(
  fileId: string,
  pageId: string,
  data: DrawingUserAnnotationRuler[],
): Promise<void> {
  return ProjectResourcesApi.post(
    `/drawings/files/${fileId}/pages/${pageId}/annotations/ruler`,
    data,
  );
}

function addImages(
  fileId: string,
  pageId: string,
  data: DrawingUserAnnotationImage[],
): Promise<void> {
  return ProjectResourcesApi.post(
    `/drawings/files/${fileId}/pages/${pageId}/annotations/svg`,
    data,
  );
}

function updateStickers(
  fileId: string,
  pageId: string,
  data: DrawingUserAnnotationSticker[],
): Promise<void> {
  return ProjectResourcesApi.put(
    `/drawings/files/${fileId}/pages/${pageId}/annotations/sticker`,
    data,
  );
}


function updateRulers(
  fileId: string,
  pageId: string,
  data: DrawingUserAnnotationRuler[],
): Promise<void> {
  return ProjectResourcesApi.put(
    `/drawings/files/${fileId}/pages/${pageId}/annotations/ruler`,
    data,
  );
}

function updateImages(
  fileId: string,
  pageId: string,
  data: DrawingUserAnnotationImage[],
): Promise<void> {
  return ProjectResourcesApi.put(
    `/drawings/files/${fileId}/pages/${pageId}/annotations/svg`,
    data,
  );
}

function updateLegend(
  fileId: string,
  pageId: string,
  data: ShortPointDescription | null,
): Promise<void> {
  return ProjectResourcesApi.put(
    `/drawings/files/${fileId}/pages/${pageId}/annotations/legend`,
    data ? { x: data[0], y: data[1] } : null,
  );
}

function removeLegend(
  fileId: string,
  pageId: string,
): Promise<void> {
  return ProjectResourcesApi.delete(`/drawings/files/${fileId}/pages/${pageId}/annotations/legend`, null);
}

function deleteAnnotations(
  fileId: string,
  pageId: string,
  data: DrawingsDeleteAnnotations,
): Promise<void> {
  return ProjectResourcesApi.delete(
    `/drawings/files/${fileId}/pages/${pageId}/annotations`,
    data,
  );
}

function getAnnotations(
  fileId: string,
  pageId: string,
): Promise<{}> {
  return ProjectResourcesApi.get(
    `/drawings/files/${fileId}/pages/${pageId}/annotations`,
  );
}

function updateCompare(updates: DrawingsShortInfo[]): Promise<void> {
  return ProjectResourcesApi.put(
    `/drawings/pages/compare`,
    updates.map(
      ({ drawingId, pageToCompareId, pagesCompareSettings }) => {
        return { id: drawingId, pageToCompareId, pagesCompareSettings };
      },
    ),
  );
}

function updatePinnedGroups(projectId: number, groupsIds: string[]): Promise<void> {
  return CompanyResourcesApi.patch(
    `/projects/${projectId}/drawings/groups/pin`,
    { pinnedGroupIds: groupsIds },
  );
}

function readPdf(fileId: string, pageId: string): Promise<PdfStatistic> {
  return ProjectResourcesApi.post(`/drawings/files/${fileId}/pages/${pageId}/geometries/read`);
}

function processPdfClick(fileId: string, pageId: string, payload: PdfClickPayload): Promise<PdfGeometryResponse> {
  return ProjectResourcesApi.post(`/drawings/files/${fileId}/pages/${pageId}/geometries/click`, payload);
}

function processAiClick(
  fileId: string,
  pageId: string,
  body: { input: PdfAiPolygonClickPayloadExtended },
  snap: boolean = true,
): Promise<{ id: string }> {
  return ProjectResourcesApi.post(
    `/drawings/files/${fileId}/pages/${pageId}/geometries/ai-click?snap=${snap}`,
    body,
  );
}

function cancelAiClick(
  fileId: string,
  pageId: string,
  jobId: string,
): Promise<void> {
  return ProjectResourcesApi.post(
    `/drawings/files/${fileId}/pages/${pageId}/geometries/ai-click/${jobId}/cancel`,
  );
}

function processPdfSelect(fileId: string, pageId: string, payload: PdfSelectionPayload): Promise<PdfGeometryResponse> {
  return ProjectResourcesApi.post(`/drawings/files/${fileId}/pages/${pageId}/geometries/select`, payload);
}

function findPdfGeometries(
  fileId: string,
  pageId: string,
  isCount: boolean,
  payload: PdfFindPayload,
): Promise<PdfGeometryResponse> {
  return ProjectResourcesApi.post(
    `/drawings/files/${fileId}/pages/${pageId}/geometries/${isCount ? 'count' : 'find'}`,
    payload,
  );
}

export const DrawingsApi = {
  loadFileSystem,
  loadAutoMeasureAvailableOptions,
  getFileData,
  getFile,
  getPage,
  update,
  getScreenshotLink,
  calculateAnnotationData,

  getMarkups,
  updateMarkups,

  getDrawingsGroups,
  addDrawingsGroups,
  deleteDrawingsGroups,
  updateDrawingsGroups,
  moveDrawingsGroups,
  reScale,
  updateSelectedPages,

  startAutoMeasureCalculation,
  exportPdfFile,
  exportAndMergePdfPages,
  duplicatePage,

  getGroupTemplates,
  createGroupTemplate,
  applyGroupTemplate,
  updateGroupTemplate,
  duplicateGroupTemplate,
  deleteGroupTemplate,

  addStickers,
  addRulers,
  addImages,
  updateStickers,
  updateRulers,
  updateImages,
  deleteAnnotations,
  getAnnotations,

  updateLegend,
  removeLegend,

  measurementAutocomplete,
  updateCompare,

  updatePinnedGroups,

  processPdfClick,
  processPdfSelect,
  findPdfGeometries,
  readPdf,
  processAiClick,
  cancelAiClick,
};
