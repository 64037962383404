import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { DrawingsGeometryEntityText, PinType } from '../drawings-geometry-entity-text';
import { RotationControl } from './rotation-control';

export class RotationControlWithText extends RotationControl {
  private _preferredTextPos: paper.Point;
  private _text: DrawingsGeometryEntityText;

  public override destroy(): void {
    super.destroy();
    this.hideAngle();
  }

  public showAngle(value: number): void {
    const text = this.getAngleText(value);
    if (this._text) {
      this._text.updateText(text);
      this._text.changePinType(this.getTextPin(value));
    } else {
      this._text = new DrawingsGeometryEntityText({
        pinType: this.getTextPin(value),
        geometry: this._preferredTextPos,
        text,
        layer: this._group,
        color: DrawingsCanvasColors.utility,
        renderParamsContextObserver: this._config.renderParamsContextObserver,
      });
    }
  }

  public hideAngle(): void {
    if (this._text) {
      this._text.destroy();
      this._text = null;
    }
  }

  @autobind
  protected override recalculatePositions(zoom: number, geometry: [paper.Point, paper.Point]): void {
    const [ point1, point2 ] = geometry;
    const vector = point2.subtract(point1);
    const center = point2.add(point1).divide(2);
    const point = center.add(new paper.Point(0, 12 / zoom)).rotate(vector.angle + 180, center);
    this.setVisualData(center, point, zoom);
    this._preferredTextPos = center.add(new paper.Point(0, 30 / zoom)).rotate(vector.angle + 180, center);
    if (this._text) {
      this._text.changePosition(this._preferredTextPos);
    }
  }

  private getTextPin(angle: number): PinType {
    const abs = Math.abs(angle);
    if (abs < 45) {
      return PinType.LEFT;
    } else if (abs > 135) {
      return PinType.RIGHT;
    }
    return angle > 0 ? PinType.TOP : PinType.BOTTOM;
  }

  private getAngleText(angle: number): string {
    return `${Math.round(-angle)}°`;
  }
}
