import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { CustomDialog } from 'common/components/custom-dialog';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';

interface OwnProps<T> {
  name: string;
  title: string;
  text: string;
  children?: JSX.Element;
  zIndex?: number;
  confirmButtonText?: string;
  cancelButtonText?: string;
  cancelButtonMood?: string;
  submitButtonMood?: string;
  cancelButtonWidth?: number;
  submitButtonWidth?: number;
  className?: string;
  autoHeight?: boolean;
  onConfirm?: (data: T) => void;
  onCancel?: (data: T) => void;
  ExtraStyled?: React.FC;
}

interface DispatchProps {
  onCloseDialog: (name: string) => void;
}

interface ReduxProps<T> {
  data: T;
}

interface Props<T> extends OwnProps<T>, ReduxProps<T>, DispatchProps {
}

class ConfirmationDialogComponent<T> extends React.Component<Props<T>> {
  public render(): React.ReactNode {
    const { name, ExtraStyled } = this.props;

    const content = ExtraStyled
      ? <ExtraStyled>{this.renderDialog()}</ExtraStyled>
      : this.renderDialog();

    return (
      <DialogWrapper name={name}>
        {content}
      </DialogWrapper>
    );
  }

  private renderDialog(): JSX.Element {
    const {
      onConfirm,
      title,
      text,
      children,
      confirmButtonText,
      cancelButtonText,
      zIndex,
      className,
      submitButtonMood,
      cancelButtonMood,
      autoHeight,
    } = this.props;

    return (
      <CustomDialog
        mainText={title}
        secondaryText={text}
        submitText={onConfirm && confirmButtonText}
        onSubmit={onConfirm && this.onSubmit}
        rejectText={cancelButtonText}
        onReject={this.onClose}
        zIndex={zIndex}
        className={className}
        submitButtonMood={submitButtonMood}
        cancelButtonMood={cancelButtonMood}
        autoHeight={autoHeight}
      >
        {children}
      </CustomDialog>
    );
  }

  @autobind
  private onSubmit(): void {
    this.props.onConfirm(this.props.data);
    this.props.onCloseDialog(this.props.name);
  }

  @autobind
  private onClose(): void {
    const { onCancel, onCloseDialog } = this.props;

    onCloseDialog(this.props.name);
    if (onCancel) {
      onCancel(this.props.data);
    }
  }
}

function mapStateToProps<T>(state: State, ownProps: OwnProps<T>): ReduxProps<T> {
  return {
    data: state.dialog[ownProps.name],
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    onCloseDialog: (name: string) => dispatch(KreoDialogActions.closeDialog(name)),
  };
};

export const ConfirmationDialog = connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialogComponent);
