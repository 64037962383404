import * as React from 'react';

import { Paper } from 'common/components/paper';
import { Text } from 'common/components/text';
import { Mood } from 'common/enums/mood';
import { Styled } from './styled';


const AutoMeasureDescriptionComponent: React.FC = () => {
  const description = 'Auto Measure is only compatible with architectural floor plans';
  return (
    <Paper mood={Mood.Negative}>
      <Styled.Container>
        <Styled.Content>
          <Text
            fontSize={14}
          >
            {description}
          </Text>
        </Styled.Content>
      </Styled.Container>
    </Paper>
  );
};


export const AutoMeasureDescription = React.memo(AutoMeasureDescriptionComponent);
