import { ExcelFormulaHelper } from 'common/components/excel-table';
import { RowDataStore } from '../../cell-data-controllers/report-cell-data-store';
import { collectCellsShiftMap } from '../collect-cell-shift-map/collect-cells-shift-map';
import { MoveCellRefMap, UpdatedCellsMap } from '../interfaces';
import { getCellValue, getShiftedColumnId } from '../utils';

export const shiftColumns = (offset: number, updatedCellsMap: UpdatedCellsMap, moveCellRefMap: MoveCellRefMap) =>
  (columnKeys: Set<string>, row: RowDataStore): void => {
    columnKeys.forEach(columnIdToSet => {
      const columnIdFromSet = getShiftedColumnId(columnIdToSet, offset);
      const rowId = row.id.toString();

      const newValue = getCellValue(row, columnIdFromSet);
      const prevValue = getCellValue(row, columnIdToSet);

      const cellId = ExcelFormulaHelper.getCellLink(null, columnIdToSet, rowId);

      updatedCellsMap[cellId] = {
        rowId,
        columnId: columnIdToSet,
        value: newValue,
        prevValue,
      };

      collectCellsShiftMap(
        moveCellRefMap,
        rowId,
        rowId,
        columnIdToSet,
        columnIdFromSet,
      );
    });
  };
