import dotProp from 'dot-prop-immutable';
import { cloneDeep, xor } from 'lodash';

import { KreoColors } from 'common/enums/kreo-colors';
import { arrayUtils } from 'common/utils/array-utils';
import { ObjectStatisticType } from '../../../components/charts/interfaces/object-statistic-type';
import { ActivityVariantActivityAssignmentInfoModel } from '../../databases/interfaces/data';
import {
  FinishLayer,
  Layer,
  LayerMaterial,
  PropertiesGroupAggregatedValues,
  PropertiesGroupToPropertiesMap,
} from '../../project-dashbord';
import {
  mapLayersToProperties,
  mapRawData,
  proccessFinishLayers,
  ungroupRawData,
} from '../../project-dashbord/utils/mappers';
import { RevitTreeLevel } from '../enums/revit-tree-level';
import {
  ActivityAssignmentAgregatedBimInfo,
} from '../interfaces/activity-assignment/activity-assignment-agregated-bim-info';
import { ActivityAssignmentAgregatedData } from '../interfaces/activity-assignment/activity-assignment-agregated-data';
import {
  ActivityAssignmentElementProperties,
} from '../interfaces/activity-assignment/activity-assignment-element-properties';
import { ActivityAssignmentMappedVariant } from '../interfaces/activity-assignment/activity-assignment-mapped-variant';
import { ActivityAssignmentResponse } from '../interfaces/activity-assignment/activity-assignment-response-data';
import { ActivityAssignmentState } from '../interfaces/activity-assignment/activity-assignment-state';
import {
  ActivityAssignmentTreeCategory,
  ActivityAssignmentTreeElementType,
  ActivityAssignmentTreeFamily,
  ActivityAssignmentTreeNode,
} from '../interfaces/activity-assignment/activity-assignment-tree-node';
import {
  ActivityAssignmentTreeNodeResponse,
} from '../interfaces/activity-assignment/activity-assignment-tree-node-response';
import { ActivityAssignmentActivity } from '../interfaces/activity-assignment/activity-assignmnet-activity';
import { ActivityAssignmentActivityLink } from '../interfaces/activity-assignment/activity-assignmnet-activity-link';
import { ActivityAssignmentVariantData } from '../interfaces/activity-assignment/activity-assignmnet-variant-data';
import { RevitPropertiesResponseInfo } from '../interfaces/revit-properties-response-info';
import { RevitPropertyNamedInfo } from '../interfaces/revit-property-named-info';
import { RevitTreeFullInfo } from '../interfaces/revit-tree-full-info';
import { RevitTreeLinearData } from '../interfaces/revit-tree-linear-data';


function itemIsSelected(
  { isShowSelection, selectedPath }: ActivityAssignmentState,
  path: string,
  level: number,
): boolean {
  return isShowSelection && level > 0 && path === selectedPath;
}


function convertActivitiesToTreeItems(
  activities: ActivityAssignmentActivity[],
  start: number,
  errorIds: Set<number>,
): RevitTreeLinearData[] {
  const revitLineData = new Array<RevitTreeLinearData>();
  for (let i = 0; i < activities.length; i++) {
    const { name, idsStart, idsEnd, id } = activities[i];
    revitLineData[i] = {
      name,
      expandable: false,
      expanded: false,
      path: `${start + i}`,
      level: RevitTreeLevel.Element,
      startIds: idsStart,
      endIds: idsEnd,
      error: errorIds.has(id),
      errors: null,
      all: null,
    };
  }
  return revitLineData;
}

function extractErorrActivityLinks(
  links: ActivityAssignmentActivityLink[],
  level: RevitTreeLevel,
  id: number = null,
): ActivityAssignmentActivityLink[] {
  switch (level) {
    case RevitTreeLevel.Category:
      return links.filter(({ isGood }) => !isGood);
    case RevitTreeLevel.Family:
      return links.filter(({ category }) => category === id);
    case RevitTreeLevel.ElementType:
      return links.filter(({ family }) => family === id);
    case RevitTreeLevel.Element:
      return links.filter(({ type }) => type === id);
    default:
      return links;
  }
}

function getNeededIndexes(links: ActivityAssignmentActivityLink[], level: RevitTreeLevel): number[] {
  switch (level) {
    case RevitTreeLevel.ElementType:
      return links.map(({ type }) => type);
    case RevitTreeLevel.Family:
      return links.map(({ family }) => family);
    case RevitTreeLevel.Category:
    default:
      return links.map(({ category }) => category);
  }
}

function getNeededIndex(
  { type, family, category }: ActivityAssignmentActivityLink,
  level: RevitTreeLevel = RevitTreeLevel.Category,
): number {
  switch (level) {
    case RevitTreeLevel.ElementType:
      return type;
    case RevitTreeLevel.Family:
      return family;
    case RevitTreeLevel.Category:
    default:
      return category;
  }
}

type TreeNodeType = ActivityAssignmentTreeCategory | ActivityAssignmentTreeFamily | ActivityAssignmentTreeElementType;

function expandTreeNode(
  treeNode: TreeNodeType,
  lineData: RevitTreeLinearData,
  searched: boolean,
  index: number,
  errorLinks: ActivityAssignmentActivityLink[],
  activities: ActivityAssignmentActivity[],
  pathes: string[],
  level: RevitTreeLevel,
  searchMode: boolean,
): RevitTreeLinearData[] {
  const currentErrors = extractErorrActivityLinks(errorLinks, level + 1, index);
  if (level === 2 && lineData.expandable && treeNode.expanded) {
    const currentErrorIds = currentErrors.map(({ work }) => work);
    return convertActivitiesToTreeItems(
      activities.slice(treeNode.start, treeNode.end),
      treeNode.start,
      new Set(currentErrorIds),
    );
  } else if (lineData.expandable && treeNode.expanded) {
    const isSearchModeOn = !searched && searchMode;
    return treeToList(
      treeNode.children as any,
      activities,
      isSearchModeOn,
      pathes,
      currentErrors,
      level + 1,
      `${lineData.path}.children.`,
    );
  } else {
    return [];
  }
}


function treeToList(
  tree: TreeNodeType[],
  works: ActivityAssignmentActivity[],
  searchMode: boolean,
  pathes: string[],
  links: ActivityAssignmentActivityLink[],
  level: RevitTreeLevel = RevitTreeLevel.Category,
  parrentPath: string = 'tree.',
): RevitTreeLinearData[] {
  const list = new Array<RevitTreeLinearData>();

  const errors = level === 0 ? extractErorrActivityLinks(links, level) : links;
  const errorIndexes = getNeededIndexes(errors, level);
  const errorSet = new Set<number>(errorIndexes);
  tree.forEach((value, index) => {
    const errorIds = errors.filter((val) => getNeededIndex(val, level) === index);
    const data: RevitTreeLinearData = {
      level,
      path: `${parrentPath}${index}`,
      name: value.name,
      expandable: !!value.children || value.end - value.start > 1,
      expanded: value.expanded,
      startIds: value.idsStart,
      endIds: value.idsEnd,
      error: errorSet.has(index),
      all: value.idCount,
      errors: errorIds.length > 0 ? errorIds.map(({ idCount }) => idCount).reduce((x, y) => x + y) : 0,
    };
    const treePath = data.path.replace('tree.', '');
    if (searchMode && pathes.find(path => path.startsWith(treePath))) {
      list.push(data);
      list.push(...expandTreeNode(value, data, true, index, errors, works, pathes, level, searchMode));
    } else if (!searchMode) {
      list.push(data);
      list.push(...expandTreeNode(value, data, true, index, errors, works, pathes, level, searchMode));
    }
  });
  return list;
}

function getSelectedIds(state: ActivityAssignmentState): number[] {
  const { selectedPath } = state;
  if (selectedPath && state.isShowSelection) {
    if (/\./.test(selectedPath)) {
      const data: ActivityAssignmentTreeElementType = dotProp.get(state, selectedPath);
      return state.engineIds.slice(data.idsStart, data.idsEnd);
    } else {
      const work = state.works[selectedPath];
      return work.data.engineIds;
    }
  } else {
    return [];
  }
}

function sortTree<U>(
  items: Array<ActivityAssignmentTreeNodeResponse<U>> | U[],
  level: RevitTreeLevel,
): Array<ActivityAssignmentTreeNodeResponse<U>> | U[] {
  if (level === RevitTreeLevel.Element) {
    return items;
  }
  for (const value of (items as Array<ActivityAssignmentTreeNodeResponse<U>>)) {
    if (value.children.length > 0) {
      if (typeof (value.children[0]) !== 'number') {
        value.children = sortTree(value.children, level + 1) as U[];
      }
    }
  }
  return arrayUtils.sortByField(items as Array<ActivityAssignmentTreeNodeResponse<U>>, 0, items.length - 1, 'name');
}

function extractEngineIdsFromWorks({ data }: ActivityAssignmentActivity, all: boolean = false): number[] {
  if (all) {
    if (data.geometryLessIds && data.geometryLessIds.length > 0) {
      return data.geometryLessIds;
    } else {
      return data.engineIds;
    }
  } else {
    return data.engineIds;
  }
}


function isActivityBad(variants: ActivityAssignmentVariantData[]): boolean {
  const filtered = variants
    .filter(({ selectedDbModalityId, isUsed }) => selectedDbModalityId !== -1 && isUsed === true);
  return filtered.length === 0;
}

function createElement(
  start: number,
  end: number,
  name: string,
  idsStart: number,
  idsEnd: number,
  idCount: number,
): ActivityAssignmentTreeElementType {
  return {
    start,
    end,
    expanded: false,
    selected: false,
    name,
    idsStart,
    idsEnd,
    idCount,
  };
}

function createFamily(
  children: ActivityAssignmentTreeElementType[],
  name: string,
): ActivityAssignmentTreeFamily {
  const firstChild = children[0];
  const lastChild = children[children.length - 1];
  return {
    start: firstChild.start,
    end: lastChild.end,
    children,
    selected: false,
    expanded: false,
    name,
    idsStart: firstChild.idsStart,
    idsEnd: lastChild.idsEnd,
    idCount: children.map((_) => _.idCount).reduce((x, y) => x + y),
  };
}

function createCategory(
  children: ActivityAssignmentTreeFamily[],
  name: string): ActivityAssignmentTreeCategory {
  const firstChild = children[0];
  const lastChild = children[children.length - 1];
  return {
    start: firstChild.start,
    end: lastChild.end,
    children,
    name,
    expanded: false,
    selected: false,
    idsStart: firstChild.idsStart,
    idsEnd: lastChild.idsEnd,
    idCount: children.map((_) => _.idCount).reduce((x, y) => x + y),
  };
}

const createBaseStatistic = (goodName: string, badName: string):
{ goodStatistic: ObjectStatisticType, badStatistic: ObjectStatisticType } => {
  const goodStatistic: ObjectStatisticType = {
    name: goodName,
    color: KreoColors.f3,
    amount: 0,
    children: [],
  };
  const badStatistic: ObjectStatisticType = {
    name: badName,
    children: [],
    amount: 0,
    color: '#FFC642',
  };
  return { goodStatistic, badStatistic };
};


function serverDataMapper(serverData: ActivityAssignmentResponse): ActivityAssignmentAgregatedData {
  const activitiesDictionary = {};
  for (const activity of serverData.works) {
    activitiesDictionary[activity.id] = activity;
  }
  const serverTree = sortTree(serverData.tree, RevitTreeLevel.Category);
  const engineIds = new Array<number>();
  const sortedActivities = new Array<ActivityAssignmentActivity>();
  const categories = new Array<ActivityAssignmentTreeCategory>();
  const { goodStatistic, badStatistic } = createBaseStatistic('Assigned', 'Undefined');

  let badIds = new Array<number>();
  const workLinks = new Array<ActivityAssignmentActivityLink>();
  serverTree.forEach((category, categoryId) => {
    const stat = createBaseStatistic(category.name, category.name);
    const goodCategoryStatistic = stat.goodStatistic;
    goodCategoryStatistic.children = undefined;
    goodCategoryStatistic.bimHandleIds = [];
    const badCategoryStatistic = stat.badStatistic;
    badCategoryStatistic.children = undefined;
    badCategoryStatistic.bimHandleIds = [];

    const families = new Array<ActivityAssignmentTreeFamily>();
    category.children.forEach((family, familyId) => {
      const types = new Array<ActivityAssignmentTreeElementType>();
      family.children.forEach((type, typeId) => {
        let elementIdCount = 0;
        const familyActivities = new Array<ActivityAssignmentActivity>();
        for (const activityId of type.children) {
          const activity = activitiesDictionary[activityId];
          activity.idsStart = engineIds.length;
          const workIdCount = extractEngineIdsFromWorks(activity, true).length;
          elementIdCount += workIdCount;
          const shortActivityInfo: ActivityAssignmentActivityLink = {
            work: activity.id,
            category: categoryId,
            family: familyId,
            type: typeId,
            isGood: true,
            idCount: workIdCount,
          };
          if (isActivityBad(activity.data.variants)) {
            badIds = badIds.concat(activity.data.engineIds);
            if (activity.data.geometryLessIds.length > 0) {
              badCategoryStatistic.amount += activity.data.geometryLessIds.length;
            } else {
              badCategoryStatistic.amount += activity.data.engineIds.length;
            }
            shortActivityInfo.isGood = false;
            badCategoryStatistic.bimHandleIds = badCategoryStatistic.bimHandleIds.concat(activity.data.engineIds);
          } else {
            if (activity.data.geometryLessIds.length > 0) {
              goodCategoryStatistic.amount += activity.data.geometryLessIds.length;
            } else {
              goodCategoryStatistic.amount += activity.data.engineIds.length;
            }
            shortActivityInfo.isGood = true;
            goodCategoryStatistic.bimHandleIds = goodCategoryStatistic.bimHandleIds.concat(activity.data.engineIds);
          }
          workLinks.push(shortActivityInfo);
          arrayUtils.extendArray(engineIds, extractEngineIdsFromWorks(activity));
          activity.idsEnd = engineIds.length;
          familyActivities.push(activity);
        }
        if (familyActivities.length > 0) {
          const start = sortedActivities.length;
          arrayUtils.extendArray(sortedActivities, familyActivities);
          const end = sortedActivities.length;
          const bimHandleIdsStart = familyActivities[0].idsStart;
          const bimHandleIdsEnd = familyActivities[familyActivities.length - 1].idsEnd;
          const newElement =
            createElement(start, end, type.name, bimHandleIdsStart, bimHandleIdsEnd, elementIdCount);
          types.push(newElement);
        }
      });
      const newFamily = createFamily(types, family.name);
      families.push(newFamily);
    });
    const newCategory = createCategory(families, category.name);
    categories.push(newCategory);
    goodStatistic.amount += goodCategoryStatistic.amount;
    badStatistic.amount += badCategoryStatistic.amount;
    goodStatistic.children.push(goodCategoryStatistic);
    badStatistic.children.push(badCategoryStatistic);
  });
  return {
    statistic: [goodStatistic, badStatistic],
    tree: categories,
    works: sortedActivities,
    workLinks,
    badIds,
    engineIds,
    databases: serverData.databases,
    usedOldCmtdbVersion: serverData.usedOldCmtdbVersion,
  };
}

function getPropertyIdMapAndLayerIdMap(info: RevitTreeFullInfo): ActivityAssignmentAgregatedBimInfo {
  let elements = [];
  const properties: PropertiesGroupToPropertiesMap[][] = [];

  const layerProps = new Array<FinishLayer[]>();
  info.allBimInfo.forEach((category) => {
    category.v.forEach((family) => {
      family.v.forEach((type) => {
        const rd = [];
        let ids = new Array<number>();
        const layers = new Array<LayerMaterial[]>();
        type.v.forEach((element) => {
          if (element.rd) {
            rd.push(element.rd);
          }
          ids.push(element.id);
          if (element.layers) {
            layers.push(element.layers);
          }
        });
        const rawData = mapRawData(rd);
        properties.push(rawData);
        const elementsMap = ids.map((value) => {
          const dataMap: ActivityAssignmentElementProperties = {
            isLayer: false,
            rawData: properties.length - 1,
            element: value,
          };
          return dataMap;
        });
        elements = elements.concat(elementsMap);
        ids = new Array<number>();
        const layerDataList = new Array<Layer[]>();
        layers.forEach((value) => {
          const data = [];
          value.forEach((layerMaterialData) => {
            ids.push(layerMaterialData.id);
            data.push(layerMaterialData.materials[0]);
          });
          layerDataList.push(data);
        });
        const mergedLayers = proccessFinishLayers(layers);
        if (mergedLayers.length > 0) {
          layerProps.push(mergedLayers);
        }
        const layerMap = ids.map((value) => {
          const dataMap: ActivityAssignmentElementProperties = {
            isLayer: true,
            rawData: layerProps.length - 1,
            element: value,
          };
          return dataMap;
        });
        if (layerMap !== undefined) {
          elements = elements.concat(layerMap);
        }
      });
    });
  });
  return {
    layersData: layerProps,
    elementDataMap: elements,
    rawData: properties,
    dataDictionary: info.rawDataDictionary,
    groupNames: info.parameterGroupDictionary,
  };
}

function getActivityPath(
  { category, family, type }: ActivityAssignmentActivityLink,
): string {
  return `${category}.children.${family}.children.${type}`;
}


function getElementPathById(state: ActivityAssignmentState, id: number): { path: string, to: number } {
  const tree = state.tree;
  const works = state.works;
  const indexOfId = state.engineIds.findIndex((value) => value === id);
  const workIndex = works.findIndex((value) => {
    return value.idsStart <= indexOfId && value.idsEnd > indexOfId;
  });
  const work = works[workIndex];
  const link = state.workLinks.find((value) => value.work === work.id);
  const node = tree[link.category].children[link.family].children[link.type];
  const scroll = link.category + link.family + link.type;
  if (node.end - node.start > 1) {
    return {
      path: `${link.category}.children.${link.family}.children.${link.type}.${workIndex}`,
      to: scroll,
    };
  } else {
    return {
      path: `${link.category}.children.${link.family}.children.${link.type}`,
      to: scroll,
    };
  }
}

function putModalityToActivity(
  activity: ActivityAssignmentActivity, variants: ActivityVariantActivityAssignmentInfoModel[],
): ActivityAssignmentActivity {
  const cloneOfTargetActivity = cloneDeep(activity);
  const data = cloneOfTargetActivity.data;
  for (const activityVariant of variants) {
    const plantsAndLaboursIds = [];
    arrayUtils.extendArray(plantsAndLaboursIds, activityVariant.plantIds);
    arrayUtils.extendArray(plantsAndLaboursIds, activityVariant.laborIds);
    let currentVariant = data.variants.find(variant => xor(variant.resourceIds, plantsAndLaboursIds).length === 0);

    if (!currentVariant) {
      currentVariant = {
        isUsed: true,
        resourceIds: plantsAndLaboursIds,
        subVariants: [],
        selectedDbModalityId: 0,
        dataBaseId: 0,
        rootActivityId: 0,
      };
      data.variants.push(currentVariant);
    }

    currentVariant.subVariants.push({
      name: activityVariant.name,
      laborHours: parseFloat(activityVariant.crewHours.toString()),
      dbModalityId: activityVariant.id,
      dataBaseId: activityVariant.databaseId,
      rootActivityId: activityVariant.activityId,
    });
    currentVariant.selectedDbModalityId = activityVariant.id;
    currentVariant.dataBaseId = activityVariant.databaseId;
    currentVariant.rootActivityId = activityVariant.activityId;
  }
  return cloneOfTargetActivity;
}

function getBimHandleIds({ data: { engineIds, geometryLessIds } }: ActivityAssignmentActivity): number[] {
  let bimHandleIds = new Array<number>();
  bimHandleIds = bimHandleIds.concat(engineIds);
  bimHandleIds = bimHandleIds.concat(geometryLessIds);
  return bimHandleIds;
}


const namesOfProperties = new Set([
  'Enable Analytical Model',
  'Function',
  'System Name',
  'System Classification',
  'Service Type',
  'System Type',
  'Category',
  'Family',
  'Type',
]);

const valuesMap = {
  Type: {
    name: 'Element Type',
  },
  ['Enable Analytical Model']: {
    name: 'Enable Analytical Model',
    values: ['no', 'yes'],
  },
};

function mapPropertyNames(properties: RevitPropertiesResponseInfo[]): RevitPropertyNamedInfo[] {
  const props = properties.filter((value) => namesOfProperties.has(value.k));
  const finished = [];
  props.forEach((value) => {
    if (value.k in valuesMap) {
      const fieldValue = valuesMap[value.k].values ?
        valuesMap[value.k].values[parseInt(value.v, 0)] : value.v;
      const mapped: RevitPropertyNamedInfo = {
        value: fieldValue,
        key: valuesMap[value.k].name,
      };
      finished.push(mapped);
    } else {
      const mapped: RevitPropertyNamedInfo = {
        value: value.v,
        key: value.k,
      };
      finished.push(mapped);
    }
  });
  return finished;
}

function buildProperties(
  work: ActivityAssignmentActivity,
  layers: FinishLayer[][],
  _groups: string[],
  rawData: PropertiesGroupToPropertiesMap[][],
  dataDictionary: RevitPropertiesResponseInfo[],
  elementDataMap: ActivityAssignmentElementProperties[],
): PropertiesGroupAggregatedValues[] {
  const ids = new Set(getBimHandleIds(work));
  const elementData = elementDataMap.filter(
    (value) => ids.has(value.element));
  const dataIds = new Set<number>();
  const layerIds = new Set<number>();
  elementData.forEach((value) => {
    if (value.isLayer) {
      layerIds.add(value.rawData);
    } else {
      dataIds.add(value.rawData);
    }
  });
  let data: PropertiesGroupToPropertiesMap[] = [];
  if (dataIds.size > 1) {
    const rds = [];
    dataIds.forEach((value) => {
      rds.push(rawData[value]);
    });
    data = mapRawData(rds);
  } else if (dataIds.size !== 0) {
    data = rawData[Array.from(dataIds)[0]];
  }
  const rawDataList = ungroupRawData(data, dataDictionary);
  const filteredPropertiesForElement = mapPropertyNames(rawDataList.slice());
  let layersData: FinishLayer[] = null;
  if (layerIds.size > 1) {
    layerIds.forEach((value) => {
      layersData = layersData.concat(layers[value]);
    });
  } else if (layerIds.size !== 0) {
    layersData = layers[Array.from(layerIds)[0]];
  }
  const objectData: PropertiesGroupAggregatedValues = {
    n: 'Object Properties',
    v: filteredPropertiesForElement.map((value) => {
      return { v: value.value, k: value.key };
    }),
  };
  let namedData = [objectData];
  if (!!layersData && layersData.length !== 0) {
    const workGeometryLessIds = work.data.geometryLessIds;
    layersData = layersData.filter((value) => {
      const layersDataIds = new Set(value.ids);
      const difference = workGeometryLessIds.filter((x) => !layersDataIds.has(x));
      return difference.length !== workGeometryLessIds.length;
    });
    namedData = namedData.concat(mapLayersToProperties(layersData));
  }
  return namedData;
}

function stateIsFull(state: ActivityAssignmentState): boolean {
  return (state.rawData.length > 0 && state.dataDictionary.length > 0 && state.elementDataMap.length > 0);
}

function extractActivityData(activity: ActivityAssignmentActivity): ActivityAssignmentMappedVariant[] {
  const resultArray = new Array<ActivityAssignmentMappedVariant>();
  activity.data.variants.forEach((value) => {
    const newName = value.subVariants.find((subVar) => subVar.dbModalityId === value.selectedDbModalityId).name;
    const newActvity = {
      ...value,
      name: newName,
    };
    newActvity.name = newName;
    resultArray.push(newActvity);
  });

  return resultArray;
}

function existsPathInSelected(pathes: string[], path: string): boolean {
  return !!pathes.find((value) => value.startsWith(path) && value !== path);
}


function expandTreeByPathes<T>(
  tree: Array<ActivityAssignmentTreeNode<T>> | T[],
  pathes: string[],
  path: string = '',
): Array<ActivityAssignmentTreeNode<T>> | T[] {
  (tree as Array<ActivityAssignmentTreeNode<T>>).forEach((value, index) => {
    const objectPath = `${path}${index}`;
    if (existsPathInSelected(pathes, objectPath)) {
      value.expanded = true;
      if (value.children) {
        value.children =
          expandTreeByPathes(value.children, pathes, `${objectPath}.children.`) as T[];
      }
    } else {
      value.expanded = false;
    }
  });
  return tree;
}

function containsTerm(value: string, term: string[]): boolean {
  const valueLowerCase = value.toLowerCase();
  return term.every((word) => valueLowerCase.includes(word));
}


function findPathesInTree<T>(
  tree: Array<ActivityAssignmentTreeNode<T>> | T[],
  filter: string[],
  parrentPath: string = '',
): string[] {
  let pathes = new Array<string>();
  (tree as Array<ActivityAssignmentTreeNode<T>>).forEach((value, index) => {
    const path = `${parrentPath}${index}`;
    if (containsTerm(value.name, filter)) {
      pathes.push(path);
    }
    if (value.children) {
      pathes = pathes.concat(
        findPathesInTree(
          value.children,
          filter,
          `${path}.children.`,
        ));
    }
  });
  return pathes;
}


function search(
  tree: ActivityAssignmentTreeCategory[],
  filter: string): string[] {
  const filterWords = filter.toLowerCase().split(' ');
  const pathes = findPathesInTree(tree, filterWords);
  return pathes;
}

export const ActivityAssignmentUtils = {
  itemIsSelected,
  treeToList,
  getSelectedIds,
  serverDataMapper,
  isActivityBad,
  getPropertyIdMapAndLayerIdMap,
  getActivityPath,
  getElementPathById,
  putModalityToActivity,
  buildProperties,
  stateIsFull,
  extractActivityData,
  expandTreeByPathes,
  search,
};
