import { RegexpUtil } from 'common/utils/regexp-util';

export interface Source {
  h_path: string;
}

export const changeParent = (targetPath: string, sourceId: string, source: Source[]): void => {
  const replaceRegex = new RegExp(`(?<prefix>.*(\/)?)(?<main>${RegexpUtil.escapeRegExp(sourceId)})(?<postfix>.*)`);
  source.forEach(data => {
    const match = data.h_path.match(replaceRegex);
    if (match !== null) {
      const groups = match.groups;
      const newPath = targetPath ? `${targetPath}/${sourceId}` : `${sourceId}`;
      data.h_path = `${newPath}${groups.postfix}`;
    }
  });
};
