import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div<{ isViewMode?: boolean }>`
  padding: ${props => props.isViewMode ? 0 : '30px 20px 20px 20px'};
  display: flex;
  flex-direction: column;
  border-left: ${props => props.isViewMode ? 0 : 1}px solid ${props => props.theme.color.background};
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 20px;
`;

const PriceCost = styled.div`
  margin-right: 20px;
  line-height: 50px;
  display: flex;
  flex-direction: column;
`;

const PrimaryPrice = styled.div`
${Text} {
  font-size: 30px;
  line-height: 41px;
  color: ${props => props.theme.color.mainFont};
  }
`;

const PriceDescription = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: flex-end;
  padding-bottom: 7px;

  p {
    line-height: 16px;
  }
`;

export const Styled = {
  Container,
  Price,
  PriceCost,
  PrimaryPrice,
  PriceDescription,
};
