import { Icons } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';

import { Text } from 'common/components/text';
import { ConstantFunctions } from 'common/constants/functions';
import { Styled } from './styled';

interface Props {
  title: string;
  progress: number;
  isValid: boolean;
}

const FileProgressComponent: React.FC<Props> = ({ title, progress, isValid }) => {
  const isInProgress = progress < 1;
  const statusIcon = useMemo(() => {
    if (!isValid) {
      return <Icons.Error/>;
    } else if (isInProgress) {
      return <Icons.Loader/>;
    } else {
      return <Icons.Apply/>;
    }
  }, [isValid, isInProgress]);

  const progressText = isValid ? `${Math.floor(progress * 100)}%` : 'Error';

  return (
    <Styled.Container progress={isValid ? progress * 100 : 0} isError={!isValid} onClick={ConstantFunctions.stopEvent}>
      <Styled.Content>
        {statusIcon}
        <Text
          withEllipsis={true}
          fontSize={14}
          lineHeight={18}
        >
          {title}
        </Text>
        <Text
          fontSize={14}
          lineHeight={18}
        >
          {progressText}
        </Text>
      </Styled.Content>
    </Styled.Container>
  );
};


export const FileProgress = React.memo(FileProgressComponent);
