import * as React from 'react';
import { OpenCloseButtons } from '../open-close-buttons';
import { Styled } from './styled';

interface Props {
  selectedPagesCount: number;
  pagesCount: number;
  selectAllText?: string;
  deselectAllText?: string;
  onSelectAll: () => void;
  onDeselectAll: () => void;
}

export class DrawingsPdfBrowserPagesHeader extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { selectedPagesCount, pagesCount, onSelectAll, onDeselectAll, selectAllText, deselectAllText } = this.props;
    return (
      <Styled.Container>
        <OpenCloseButtons
          selectedPagesCount={selectedPagesCount}
          pagesCount={pagesCount}
          onDeselectAll={onDeselectAll}
          onSelectAll={onSelectAll}
          showText={true}
          selectAllText={selectAllText}
          deselectAllText={deselectAllText}
        />
      </Styled.Container>
    );
  }
}
