import autobind from 'autobind-decorator';
import * as React from 'react';

import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { KreoButton, Message } from 'common/UIKit';

import './new-comment.scss';


interface Props {
  createNew: (text: string) => void;
}

export class NewComment extends React.Component<Props> {
  private text: string = '';

  public render(): any {
    return (
      <div className='viewer-new-comment'>
        <div className='viewer-new-comment___content'>
          <div className='viewer-new-comment__line'>
            <Message
              startViewLines={4}
              autoFocus={true}
              onKeyUp={this.keyUp}
              onChange={this.onChange}
              maxViewRows={4}
            />
          </div>
          <div className='viewer-new-comment__line'>
            <div className='button-container'>
              <KreoButton
                mode='success'
                size='medium'
                caption='Post'
                onClick={this.onClickButton}
                controlName='viewer-new-comment-post-button'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  private onClickButton(): void {
    if (this.text && this.text !== '') {
      this.props.createNew(this.text);
    }
  }

  @autobind
  private onChange(value: string): void {
    this.text = value;
  }

  @autobind
  private keyUp(e: React.KeyboardEvent<HTMLTextAreaElement>): void {
    if (e.keyCode === 13 && HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e)) {
      e.stopPropagation();
      if (this.text && this.text !== '') {
        this.props.createNew(this.text);
      }
    }
  }
}

