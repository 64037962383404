import autobind from 'autobind-decorator';
import React from 'react';
import { Field } from 'redux-form';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { KreoIconCloseSmall, MaterialNumberInputField, MaterialNumberInputProps } from 'common/UIKit';
import { GreaterThan } from '../../../../components/dialog/validators';
import { ActivityResourceVariant } from '../../interfaces/data';
import { PlantVariantModel } from '../../interfaces/resources-data';

const quantityGreaterThan0 = GreaterThan(0, 'Quantity');

interface Props extends I18nAwareProps {
  fieldKey: string;
  index: number;
  readonly: boolean;
  plant: ActivityResourceVariant<PlantVariantModel>;
  onQuantityChange: (plantVariantId: number, quantity: number) => void;
  onDelete: (plantVariantId: number) => void;
}

class PlantItemComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const plantVariant = this.props.plant.resourceVariant;

    return (
      <div className='database-entity-modify-wrap-tile plant'>
        <div className='database-entity-modify-wrap-tile__name'>
          {`${plantVariant.resourceName} ${plantVariant.name}`}
        </div>
        <div className='database-entity-modify-wrap-tile__content'>
          <Field<MaterialNumberInputProps>
            name={`${this.props.fieldKey}.amount`}
            component={MaterialNumberInputField}
            className='database-entity-modify-wrap-tile__quantity'
            label='Quantity'
            value={this.props.plant.amount}
            onChange={this.props.readonly ? null : this.onQuantityChange}
            precision={2}
            valueType='float'
            disabled={this.props.readonly}
            displayBottomInformation={true}
            validate={quantityGreaterThan0}
          />
          <div className='database-entity-modify-wrap-tile__key'>
            <div className='database-entity-modify-wrap-tile__label'>Shift</div>
            <div className='database-entity-modify-wrap-tile__value'>
              {`${this.props.plant.resourceVariant.shiftHours} hr`}
            </div>
          </div>
          <div className='database-entity-modify-wrap-tile__key'>
            <div className='database-entity-modify-wrap-tile__label'>Rent Time</div>
            <div className='database-entity-modify-wrap-tile__value'>
              {this.props.plant.resourceVariant.rentTime}
            </div>
          </div>
          <div className='database-entity-modify-wrap-tile__rate'>
            <div className='database-entity-modify-wrap-tile__label'>Rate</div>
            <div className='database-entity-modify-wrap-tile__value'>
              {this.props.i18n.currency.symbol}{this.props.plant.resourceVariant.cost}
            </div>
          </div>
          {!this.props.readonly ? (
            <div onClick={this.onDelete} className='database-entity-modify-wrap-tile__del-btn'>
              <KreoIconCloseSmall />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  @autobind
  private onDelete(): void {
    this.props.onDelete(this.props.index);
  }

  @autobind
  private onQuantityChange(_: React.ChangeEvent, newValue: number | null): void {
    this.props.onQuantityChange(this.props.index, newValue);
  }
}

export const PlantItem = withI18n(PlantItemComponent);
