import styled from 'styled-components';

const Value = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  border-radius: 6px;
  flex: none;
`;

export const Styled = {
  Value,
};
