import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyMouse3: React.FC = () => {
  return (
    <svg
      width='20px'
      height='32px'
      viewBox='0 0 20 32'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          // eslint-disable-next-line max-len
          d='M9,2 L9,2 C13.9705627,2 18,6.02943725 18,11 L18,21 C18,25.9705627 13.9705627,30 9,30 L9,30 C4.02943725,30 6.08718376e-16,25.9705627 0,21 L0,11 C-6.08718376e-16,6.02943725 4.02943725,2 9,2 Z'
          id='path-1-m3'
        />
        <path
          // eslint-disable-next-line max-len
          d='M9,0 L9,0 C13.9705627,-9.13077564e-16 18,4.02943725 18,9 L18,19 C18,23.9705627 13.9705627,28 9,28 L9,28 C4.02943725,28 6.08718376e-16,23.9705627 0,19 L0,9 C-6.08718376e-16,4.02943725 4.02943725,9.13077564e-16 9,0 Z'
          id='path-2-m3'
        />
        <rect id='path-3-m3' x='7' y='5' width='4' height='8' rx='2' />
      </defs>
      <g id='Hotkeys/20x32/icon-3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-5' transform='translate(1.000000, 1.000000)'>
          <g id='Rectangle-2'>
            <use fill={KreoColors.f1} fillRule='evenodd' xlinkHref='#path-1-m3' />
            <path
              stroke={KreoColors.f2}
              strokeWidth='1'
              // eslint-disable-next-line max-len
              d='M9,2.5 C4.30557963,2.5 0.5,6.30557963 0.5,11 L0.5,21 C0.5,25.6944204 4.30557963,29.5 9,29.5 C13.6944204,29.5 17.5,25.6944204 17.5,21 L17.5,11 C17.5,6.30557963 13.6944204,2.5 9,2.5 Z'
            />
          </g>
          <g id='Rectangle-2'>
            <use fill={KreoColors.f1} fillRule='evenodd' xlinkHref='#path-2-m3' />
            <path
              stroke={KreoColors.f2}
              strokeWidth='1'
              // eslint-disable-next-line max-len
              d='M9,0.5 C4.30557963,0.5 0.5,4.30557963 0.5,9 L0.5,19 C0.5,23.6944204 4.30557963,27.5 9,27.5 C13.6944204,27.5 17.5,23.6944204 17.5,19 L17.5,9 C17.5,4.30557963 13.6944204,0.5 9,0.5 Z'
            />
          </g>
          <g id='Rectangle-2'>
            <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-3-m3' />
            <rect stroke={KreoColors.f2} strokeWidth='1' x='7.5' y='5.5' width='3' height='7' rx='1.5' />
          </g>
        </g>
      </g>
    </svg>
  );
};
