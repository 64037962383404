import { Avatar } from '@kreo/kreo-ui-components';
import React, { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import { ConfirmationButtonsPanel } from 'common/components/button-sets';
import { DialogContainer } from 'common/components/dialog-container';
import { DrawingsUserAnnotationActions } from 'common/components/drawings/actions/creators/user-annotation';
import { TextArea } from 'common/components/text-area';
import { State } from 'common/interfaces/state';
import { AccountApi } from '../../../../../../../units/account/api';
import { DialogPosition } from '../../hooks';
import { ContainerShakeName, ContentContainer } from '../content-container';
import { useEdit } from '../hooks';
import { Styled } from './styled';


interface StateProps {
  currentUser?: { hasAvatar?: boolean, id?: string, firstName?: string, lastName?: string };
}

interface DispatchProps {
  setTextStatus: (value: boolean) => void;
}

interface OwnProps {
  position: DialogPosition;
  onClose: () => void;
  remove: () => void;
  createComment: (text: string) => void;
}

interface Props extends StateProps, OwnProps, DispatchProps {
}

const NewStickerContentComponent: React.FC<Props> = ({
  currentUser,
  createComment,
  setTextStatus,
  position,
  onClose,
  remove,
}) => {
  const { value, isValueValid, onEdit } = useEdit('');
  const [shake, setShake] = useState<boolean>(false);
  const textRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (textRef?.current) {
      textRef.current.focus();
      textRef.current.selectionStart = textRef.current.value.length;
    }
  }, [textRef?.current]);

  const sendResult = useCallback(() => {
    if (isValueValid) {
      createComment(value);
    }
  }, [isValueValid, value, createComment]);

  const hasText = !!value;

  useEffect(() => {
    setTextStatus(hasText);
  }, [hasText, setTextStatus]);

  const onCloseCallback = useCallback(() => {
    if (hasText) {
      setShake(true);
    } else {
      onClose();
    }
  }, [hasText]);

  const onAnimationEnd = useCallback((e: React.AnimationEvent<HTMLDivElement>) => {
    if (e.animationName === ContainerShakeName) {
      setShake(false);
    }
  }, []);

  return (
    <DialogContainer
      x={position.x}
      y={position.y}
      bottom={position.pinBottom}
      right={position.pinRight}
      blockEvents={hasText}
      onClose={onCloseCallback}
    >
      <ContentContainer onAnimationEnd={onAnimationEnd} shake={shake}>
        <Styled.Container>
          <Styled.Avatar>
            {
              currentUser ? (
                <Avatar
                  name={`${currentUser.firstName} ${currentUser.lastName}`}
                  size={30}
                  avatar={AccountApi.getAvatarLink(currentUser as { id: string, hasAvatar: boolean })}
                />
              ) : null
            }
          </Styled.Avatar>
          <Styled.TextArea>
            <TextArea
              autofocus={true}
              placeholder='Add Text'
              value={value}
              onChange={onEdit}
              onFinish={sendResult}
              useDefaultEmojiPosition={true}
              inputRef={textRef}
            />
          </Styled.TextArea>
        </Styled.Container>
        {
          hasText ? (
            <Styled.ButtonsContainer>
              <ConfirmationButtonsPanel
                applyText='Submit'
                apply={sendResult}
                disabled={!isValueValid}
                cancel={remove}
              />
            </Styled.ButtonsContainer>
          ) : null
        }
      </ContentContainer>
    </DialogContainer>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    currentUser: state.account.id ? state.account : null,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setTextStatus: value => dispatch(DrawingsUserAnnotationActions.setTempStickerHasText(value)),
  };
}

export const NewStickerContent = connect(mapStateToProps, mapDispatchToProps)(NewStickerContentComponent);
