import React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import {
  DrawingsCanvasContextProps,
  DrawingsCanvasContextStateProps,
} from '../interfaces/drawings-canvas-context-props';


export const DrawingsCanvasContext =
  React.createContext<DrawingsCanvasContextProps>(null);

interface Props extends DrawingsCanvasContextProps {
  children?: React.ReactNode;
}

class DrawingsCanvasContextProviderComponent
  extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const props = { ...this.props };
    delete props.children;
    return (
      <DrawingsCanvasContext.Provider value={props}>
        {this.props.children}
      </DrawingsCanvasContext.Provider>
    );
  }
}

function mapStateToProps(state: State): DrawingsCanvasContextStateProps {
  return {
    isImperialUnit: state.account.settings.isImperial,
  };
}

export const DrawingsCanvasContextProvider = connect(mapStateToProps)(DrawingsCanvasContextProviderComponent);
