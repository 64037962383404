import { useCallback } from 'react';

type UseChangeNameCallback = (
  changeName: (id: string, newName: string) => void,
  canEdit: boolean,
) => [(id: string, newName: string) => void];

export const useChangeNameCallback: UseChangeNameCallback = (changeName, canEdit) => {
  const handleChangeName = useCallback((id, newName) => {
    changeName(id, newName);
  }, [changeName]);
  if (!canEdit) {
    return [undefined];
  }
  return [handleChangeName];
};
