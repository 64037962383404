import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { DrawingsSnappingModes } from 'common/components/drawings/enums/drawing-snapping-modes';
import { SubscriptionType } from 'common/constants/subscription';
import { PickOnlyFieldsOfType } from 'common/interfaces/omiters';
import { PagesFilterValue } from '../../../common/components/drawings/interfaces/drawing-filters';
import { SortData } from '../../../common/components/drawings/interfaces/drawing-measurement-sort';
import { ThemeType } from '../../../components/theme-provider/interfaces';
import { ProjectsView } from '../../../units/2d-projects-page/2d-projects-content/constants';

export enum TwoDFullScreenMode {
  Drawings = 'drawings',
  Report = 'report',
}


export interface TableInnerClipboard {
  rows: string[][];
  expectedClipboard: string;
}

export interface InnerClipboard {
  twoDTable: TableInnerClipboard;
}

export interface SplitterPaneSizePayload {
  nameType: string;
  value: number;
}

export interface PanelPositionInfo {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface PanelInfo {
  position: PanelPositionInfo;
  show: boolean;
}

export interface ThreeDSettings {
  isCameraOrthographic: boolean;
  fillOpacity: number;
  showDrawing: boolean;
  drawingOpacity: number;
  drawingOffset: number;
}

export interface PersistedStorageState {
  selectedCompanyId: number | null;
  projectIdToRevisionId: Record<number, number>;
  isChatCloudDismissed: boolean | null;
  isAutoFocusEngine: boolean | null;
  disableShowDialogList: string[] | null;
  disableShowTourList: Record<string, boolean> | null;
  viewedReleaseNotesVersion: string | null;
  drawingBlackAndWhiteToggle: boolean;
  drawingNightModeToggle: boolean;
  drawingSnappingToggle: boolean;
  drawingOpacity: number;
  drawingIsKeepPolygon: boolean | null;
  drawingIsKeepGroup: boolean | null;
  drawingOffsetIsStroke: boolean | null;
  drawingSnappingModes: DrawingsSnappingModes[];
  drawingKeepMeasuresNames: boolean | null;
  drawingAutofocusEnabled: boolean | null;
  drawingKeepStructureEnabled: boolean | null;
  drawingMinimapOpened: boolean | null;
  drawingOrthogonalMode: boolean | null;
  drawingNewInstanceStrokeWidth: number;
  drawingNewPolylineThickness: number;
  drawingNewPolylineHeight: number;
  drawingNewPolygonHeight: number;
  drawingsShowLabel: boolean | null;
  drawingAutocomplete: boolean | null;
  drawingExportFontSize: number | null;
  drawingExportLineThickness: number | null;
  drawingUseGroupNameForNewGeometry: boolean | null;
  drawingThreeDSettings: ThreeDSettings;
  drawingShowThickness: boolean | null;
  drawingMeasurementsPivot: boolean | null;
  drawingMeasurementCategoryInstrument: Record<string, DrawingsDrawMode>;
  isPagesTabsMinified: boolean | null;
  theme: ThemeType;
  darkTone: string;
  lightTone: string;
  isQuickSearchVisible: boolean;
  projectTwoDFullScreenMode: Array<[string, TwoDFullScreenMode]>;
  isMeasurementsSearchVisible: boolean;
  isAutoMoveToCell: boolean;
  innerClipboard: InnerClipboard;
  isCollapseMenu: boolean;
  isOpenDrawingsAnnotationLegendPanel: boolean;
  isDemoProjectCollapsed: boolean;
  isFoldersSectionCollapsed: boolean;
  selectedFilterPages: PagesFilterValue;
  twoDDataBaseColumnState: Record<string, any>;
  selectedMeasurementSort: SortData;
  splitterPaneSize: Record<string, number>;
  hideUnselectedPageGroups: boolean | null;
  entityPanel: PanelInfo;
  showTableUnits: boolean;
  showTableTotal: boolean;
  selectedCurrency: string;
  projectAccessFilter: Record<string, string>;
  lastUsedProductType: SubscriptionType;
  currentPathname: string;
  hideSmallScreenDialog: boolean;
  isTryAiPanelShown: boolean;
  projectsViewType: ProjectsView;
  drawingsPinFlyingMenu: boolean;
}


export type PersistedStorageStateBoolKeys = keyof PickOnlyFieldsOfType<PersistedStorageState, boolean>;
