/* eslint-disable max-len, space-in-parens */
export const ignoreErrors = [
  'Request failed with status code 401',
  '****************************************************************************************************************',
  '***************************************** ag-Grid Enterprise License *******************************************',
  '****************************************** License Key Not Found ***********************************************',
  '* All ag-Grid Enterprise features are unlocked.                                                                *',
  '* This is an evaluation only version, it is not licensed for development projects intended for production.     *',
  '* If you want to hide the watermark, please email info@ag-grid.com for a trial license.                        *',
];

