import React from 'react';

import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { AnalyticsActions } from '../analytics/actions/actions';
import { SegmentContextProps, withSegmentContext } from '../analytics/components/segment-context';
import { TrackerProvider } from '../analytics/components/utils/tracker-provider';

interface DispatchProps {
  setChatOpen: (isOpen: boolean) => void;
}

interface Props extends DispatchProps, SegmentContextProps {

}

class Component extends React.Component<Props> {

  private get intercomTracker(): IntercomTracker {
    return TrackerProvider.intercomTracker();
  }

  private isIntercomLoaded: boolean = false;

  public componentDidMount(): void {
    if (this.props.isSegmentReady) {
      this.tryInitIntercom();
    }

  }

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (nextProps.isSegmentReady && !this.props.isSegmentReady) {
      this.tryInitIntercom();
    }

    return false;
  }

  public render(): React.ReactNode {
    return null;
  }

  private tryInitIntercom(): void {
    if (this.intercomTracker && !this.isIntercomLoaded) {
      this.intercomTracker('onShow', () => this.props.setChatOpen(true));
      this.intercomTracker('onHide', () => this.props.setChatOpen(false));
      this.isIntercomLoaded = true;
    }
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>): DispatchProps {
  return {
    setChatOpen: (isOpen) => dispatch(AnalyticsActions.setChatOpen(isOpen)),
  };
}


export const Intercom = withSegmentContext(connect(null, mapDispatchToProps)(Component));
