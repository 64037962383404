import autobind from 'autobind-decorator';

export class DeferredExecutor {
  private delay: number;
  private timer: any;
  private func: (...args: any) => void;

  constructor(delay: number) {
    this.delay = delay;
    this.timer = null;
  }

  public isWaitingForExecution(): boolean {
    return !!this.timer;
  }

  @autobind
  public execute(func: (...args: any) => any, ...args: any): void {
    if (this.isWaitingForExecution()) {
      this.reset();
    }

    this.func = func;
    this.timer = setTimeout(() => {
      func(...args);
      this.clearState();
    }, this.delay);
  }

  public reset(): void {
    clearTimeout(this.timer);
    this.clearState();
  }

  public executeImmediately(): void {
    if (!this.isWaitingForExecution()) {
      return;
    }
    const func = this.func;
    this.reset();
    func();
  }

  private clearState(): void {
    this.timer = null;
    this.func = null;
  }
}
