import styled from 'styled-components';

import { TRANSITION_POINT } from '../../../../../../constants';


const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 526px;
  background-color: ${p => p.theme.color.backgroundRush};

  &, * {
    box-sizing: border-box;
  }

  @media all and (max-width: ${TRANSITION_POINT}px) {
    margin: 0 10px;
    max-width: 100%;
  }
`;

export const Styled = {
  Container,
};
