import * as React from 'react';


import { IconButton, KreoIconCloseSmall, TopCardPanel as Tk } from 'common/UIKit';

const TopCardPanel: any = Tk;

import './base-header.scss';

interface Props {
  closeClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  hasCollapseButton?: boolean;
}


export const BasePropertiesHeader: React.FC<Props> = (props) => {
  return (
    <TopCardPanel className='validation-base-properties-top-panel'>
      {props.children}
      {
        props.hasCollapseButton && (
          <IconButton size='small' onClick={props.closeClick}>
            <KreoIconCloseSmall />
          </IconButton>
        )
      }
    </TopCardPanel>
  );
};

BasePropertiesHeader.defaultProps = {
  hasCollapseButton: false,
};
