import { Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';


export const InfoIconAutoMeasure: React.ComponentType = () => {
  const autoMeasureCalculating = useSelector<State, boolean>(s => s.drawings.currentDrawingInfo?.recognitionStatus &&
    s.drawings.currentDrawingInfo.recognitionStatus === ViewModelStatus.Calculating);

  return autoMeasureCalculating ? <Icons.Loader/> : <Icons.AutoMeasureSmall/>;
};
