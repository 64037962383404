import { Message } from './message';

export enum AIRequestStatus {
  Default = 'Default',
  TextExtracting = 'TextExtracting',
  Loading = 'Loading',
  Stoped = 'Stoped',
  Error = 'Error',
}

export interface TwoDCopilotState {
  isPopupOpened: boolean;
  history: Message[];
  tempAnswerText: string;
  currentRequestStatus: AIRequestStatus;
  windowHeight: number | null;
}

