import  React from 'react';
import { ContextMenuItem, ContextMenuItemProps } from 'common/components/context-menu';
import { useCloseAfterAction } from '../hooks';
import { Styled } from './styled';

interface Props extends ContextMenuItemProps {
  doActionAndClose: (action: (...args: any) => void, ...args: any) => void;
}

export const DrawingsContentMenuItemWrapper: React.FC<Props> = ({
  children,
  options,
  onSelect,
  onClick,
  onMouseOver,
  onMouseOut,
  icon,
  hotkey,
  optionContentRenderer,
  childRenderer,
  helpToolTip,
  disabled,
  badge,
  doActionAndClose,
}) => {
  const selectCallbackWrapper = useCloseAfterAction(doActionAndClose, onSelect, true);
  const onClickCallbackWrapper = useCloseAfterAction(doActionAndClose, onClick);

  return (
    <Styled.ContextMenuItem>
      <ContextMenuItem
        options={options}
        onSelect={selectCallbackWrapper}
        onClick={onClickCallbackWrapper}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        icon={icon}
        hotkey={hotkey}
        optionContentRenderer={optionContentRenderer}
        childRenderer={childRenderer}
        helpToolTip={helpToolTip}
        disabled={disabled}
        badge={badge}
      >
        {children}
      </ContextMenuItem>
    </Styled.ContextMenuItem>
  );
};

