import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import { DrawingsGeometryInstance, ShortPointDescription } from 'common/components/drawings/interfaces';
import { PdfGeometry } from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { AsyncArrayUtils } from 'common/utils/async-array-util';
import { UuidUtil } from 'common/utils/uuid-utils';
import { VisibleEntity, VisibleEntityConfig } from '../../common';
import { WizzardGeometryPreview } from '../../drawings-geometry-entities/temporary/preview';
import { NewDrawingSettings } from '../../interfaces';
import { ThreeDParamsHelper } from '../threed-params-helper';

interface Config extends VisibleEntityConfig<PdfGeometry[]> {
  fill?: boolean;
  newDrawingsStylesObserver: ContextObserver<NewDrawingSettings>;
  viewSettingsObserver: ContextObserver<MeasuresViewSettings>;
  colorCache: DrawingsColorCacheHelper;
  layer: paper.Layer;
  similarity: number;
  geometriesToHide: Record<number, boolean>;
}

export class WizzardPreview extends VisibleEntity<PdfGeometry[], Config> {
  private _geometies: Map<number, WizzardGeometryPreview>;
  private _group: paper.Group;

  constructor(config: Config) {
    super(config);
    config.newDrawingsStylesObserver.subscribe(this.onUpdateDrawingStyles);
    config.renderParamsContextObserver.subscribe(this.onUpdateZoom);
    config.viewSettingsObserver.subscribe(this.updateViewParams);
  }

  public get count(): number {
    return this._geometies.size;
  }

  public destroy(): void {
    this._config.newDrawingsStylesObserver.unsubscribe(this.onUpdateDrawingStyles);
    this._config.renderParamsContextObserver.unsubscribe(this.onUpdateZoom);
    this._config.viewSettingsObserver.unsubscribe(this.updateViewParams);
    this._group.remove();
  }

  public get geometry(): PdfGeometry[] {
    return this._config.geometry;
  }

  public async getInstances(drawingId: string): Promise<{
    instances: DrawingsGeometryInstance[],
    newPoints: Record<string, ShortPointDescription>,
  }> {
    const overiderInstanceType =
      typeof this._config.fill === 'boolean'
        ? this._config.fill
          ? DrawingsInstanceType.Polygon
          : DrawingsInstanceType.Polyline
        : DrawingsInstanceType.Polyline;
    const {
      strokeStyle,
      strokeWidth,
      color,
      name,
    } = this._config.newDrawingsStylesObserver.getContext();
    const newDrawingContext = this._config.newDrawingsStylesObserver.getContext();
    const instances = new Array<DrawingsGeometryInstance>();
    const newPoints: Record<string, ShortPointDescription> = {};
    const iterator = AsyncArrayUtils.iteratorWithDelayEveryNStep(this._config.geometry, 1, 500);
    const updatePolygon = ThreeDParamsHelper.shouldAddToPolygon(newDrawingContext)
      ? (geometry) => ThreeDParamsHelper.addToPolygon(geometry, newDrawingContext)
      : ConstantFunctions.doNothing;
    const updatePolyline = ThreeDParamsHelper.shouldAddToPolyline(newDrawingContext)
      ? (geometry) => ThreeDParamsHelper.addToPolyline(geometry, newDrawingContext)
      : ConstantFunctions.doNothing;

    for await (const { points, isClosed, similarity, id }  of iterator) {
      if (similarity < this._config.similarity || this._config.geometriesToHide[id]) {
        continue;
      }
      const geometryPoints = [];
      for (const point of points) {
        const newPointId = UuidUtil.generateUuid();
        geometryPoints.push(newPointId);
        newPoints[newPointId] = point;
      }
      const instanceId = UuidUtil.generateUuid();
      const type = isClosed ? DrawingsInstanceType.Polygon : DrawingsInstanceType.Polyline;
      const instanceType = overiderInstanceType || type;
      const geometry = {
        color,
        strokeWidth,
        strokeStyle,
        points: geometryPoints,
      };
      if (isClosed) {
        updatePolygon(geometry);
      } else {
        updatePolyline(geometry);
      }
      instances.push({
        drawingId,
        id: instanceId,
        name,
        isAuto: false,
        type: instanceType,
        geometry,
      });
    }
    return { instances, newPoints };
  }

  public updateFilters(similarity: number, geometriesToHide: Record<number, boolean>): void {
    this._config.similarity = similarity;
    this._config.geometriesToHide = geometriesToHide;
    const color = this._config.colorCache.getPaperColor(this._config.newDrawingsStylesObserver.getContext().color);
    const shouldOverriderFill = typeof this._config.fill === 'boolean';
    const thickness = this.getCurrentThickness();
    this._config.geometry.forEach((geometry, index) => {
      if (geometry.similarity < this._config.similarity || this._config.geometriesToHide[geometry.id]) {
        const preview = this._geometies.get(index);
        if (preview) {
          preview.destroy();
          this._geometies.delete(index);
        }
      } else if (!this._geometies.has(index)) {
        this.renderGeometry(index, geometry, color, shouldOverriderFill, thickness);
      }
    });
    this.applyStyles();
  }

  protected render(geometries: PdfGeometry[]): void {
    this._geometies = new Map();
    this._group = new paper.Group();
    this._group.addTo(this._config.layer);
    const color = this._config.colorCache.getPaperColor(this._config.newDrawingsStylesObserver.getContext().color);
    const shouldOverriderFill = typeof this._config.fill === 'boolean';
    const thicknessInPx = this.getCurrentThickness();
    geometries.forEach((geometry, index) => {
      if (geometry.similarity < this._config.similarity || this._config.geometriesToHide[geometry.id]) {
        return;
      }
      this.renderGeometry(index, geometry, color, shouldOverriderFill, thicknessInPx);
    });
  }

  private renderGeometry(
    index: number,
    geometry: PdfGeometry,
    color: DrawingsPaperColorInfo,
    shouldOverriderFill: boolean,
    thickness: number,
  ): void {
    const preview = new WizzardGeometryPreview({
      geometry: [geometry.points],
      color,
      layer: this._group,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      showThickness: this._config.viewSettingsObserver.getContext().showThickness,
      thickness,
      fill: shouldOverriderFill ? this._config.fill : geometry.isClosed,
    });
    this._geometies.set(index, preview);
  }

  @autobind
  private onUpdateZoom({ zoom }: { zoom: number }): void {
    const newStyle = this._config.newDrawingsStylesObserver.getContext();
    this._group.strokeWidth = newStyle.strokeWidth / zoom;
    this._group.dashArray = DrawingsCanvasUtils.scaleStroke(newStyle, zoom);
  }

  @autobind
  private onUpdateDrawingStyles(newStyle: NewDrawingSettings): void {
    const color = this._config.colorCache.getPaperColor(newStyle.color);
    const zoom = this._config.renderParamsContextObserver.getContext().zoom;
    this._group.strokeWidth = newStyle.strokeWidth / zoom;
    this._group.dashArray = DrawingsCanvasUtils.scaleStroke(newStyle, zoom);
    const thickness = this.getCurrentThickness();
    for (const geometry of this._geometies.values()) {
      geometry.color = color;
      geometry.thickness = thickness;
    }
  }

  @autobind
  private updateViewParams(viewParams: MeasuresViewSettings): void {
    const thickness = this.getCurrentThickness();
    this._geometies.forEach((geometry) => {
      geometry.showThickness = viewParams.showThickness;
      geometry.thickness = thickness;
    });
  }

  private applyStyles(): void {
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    const newStyle = this._config.newDrawingsStylesObserver.getContext();
    this._group.strokeWidth = newStyle.strokeWidth / zoom;
    this._group.dashArray = DrawingsCanvasUtils.scaleStroke(newStyle, zoom);
  }

  private getCurrentThickness(): number {
    const { scale, metersPerPixel } = this._config.viewSettingsObserver.getContext();
    const { polylineThickness } = this._config.newDrawingsStylesObserver.getContext();
    return DrawingsCanvasUtils.metersToPx(polylineThickness, scale, metersPerPixel);
  }
}
