import * as t from 'io-ts';

import { CustomElementFilterCollectionOperationC } from 'common/enums/custom-element-filter-collection-operation';
import { CustomElementFilterStringOperationC } from 'common/enums/custom-element-filter-string-operation';
import { CustomElementFilterBaseC } from '.';


export const CustomElementFilterStringC = t.intersection([
  t.type({
    propertyKey: t.string,
    collectionOperation: CustomElementFilterCollectionOperationC,
    operation: CustomElementFilterStringOperationC,
    isCaseSensitive: t.boolean,
    operationArg: t.string,
  }),
  CustomElementFilterBaseC,
]);

export type CustomElementFilterString = t.TypeOf<typeof CustomElementFilterStringC>;
