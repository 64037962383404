import { useCallback } from 'react';
import { MetricNames, useAnalytics } from 'utils/posthog';

type SendFunction = (query: string) => void;

export function useSendPromtsAnalitics(sendFunction: SendFunction): SendFunction {
  const { sendEvent } = useAnalytics();

  return useCallback((query: string) => {
    sendEvent(MetricNames.twoDCopilot.sendPrompt, { prompt: query });
    sendFunction(query);
  }, [sendFunction]);
}
