const prefix = '@validation';

const APPROVE_VALIDATION_STEP = `${prefix}/APPROVE_VALIDATION_STEP`;
const REVERT_TO_VALIDATION_STEP = `${prefix}/REVERT_TO_VALIDATION_STEP`;
const CALCULATE_ACTIVITY_ASSIGNMENT = `${prefix}/CALCULATE_ACTIVITY_ASSIGNMENT`;
const SET_PROJECT_VALIDATION_STATE = `${prefix}/SET_PROJECT_VALIDATION_STATE`;
const GET_VALIDATION_RESULTS_REQUEST = `${prefix}/GET_VALIDATION_RESULTS_REQUEST`;
const GET_VALIDATION_RESULTS_SUCCESS = `${prefix}/GET_VALIDATION_RESULTS_SUCCESS`;

export const ValidationActionTypes = {
  APPROVE_VALIDATION_STEP,
  REVERT_TO_VALIDATION_STEP,
  CALCULATE_ACTIVITY_ASSIGNMENT,
  SET_PROJECT_VALIDATION_STATE,
  GET_VALIDATION_RESULTS_REQUEST,
  GET_VALIDATION_RESULTS_SUCCESS,
};
