import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';


import { DrawingsUndoRedoHelper } from 'common/components/drawings/utils/drawings-undo-redo-helper';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { useUndoRedoFullApi } from 'common/undo-redo';
import { DNDSourceProps, withDragSourceWrapper } from 'common/utils/with-drag/with-drag-source-wrapper';
import {
  DrawingsGeometryInstanceWithIdAndGroupId,
  DrawingsInstanceMeasure,
} from '../../';
import { DrawingsAnnotationActions } from '../../actions/creators/annotation';
import { DrawingsDragAndDropData, DrawingsDragAndDropType } from '../../utils/drawings-annotation-drag-utils';
import { LegendItemAPI } from '../interfaces';
import { DrawingsAnnoationBaseItem } from './drawings-annotation-base-item';


interface OwnProps {
  isSelected: boolean;
  isImperial: boolean;
  isHidden: boolean;
  instance: DrawingsGeometryInstanceWithIdAndGroupId;
  nestingCount: number;
  measure: DrawingsInstanceMeasure;
  onClick: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
  onContextMenu: (event: React.MouseEvent<HTMLDivElement>, id: string) => boolean;
  onHiddenChange: (ids: string[], isHidden: boolean) => void;
  onDoubleClick: (ids: string[]) => void;
  canEditMeasurement: boolean;
  getItemsToMove(): DrawingsDragAndDropData;
  hasAssignedPia: boolean;
  hasInheritedPia: boolean;
  minWidth: number;
  sendUpdateApi: (api: LegendItemAPI, id: string) => void;
  onOpenInstanceMenu: (e: React.MouseEvent, instance: DrawingsGeometryInstanceWithIdAndGroupId) => void;
}

interface Props extends OwnProps, DNDSourceProps {
}

const DrawingsAnnotationLegendItemComponent: React.FC<Props> = ({
  instance,
  isSelected,
  isHidden,
  nestingCount,
  canEditMeasurement,
  isImperial,
  hasAssignedPia,
  hasInheritedPia,
  minWidth,
  sendUpdateApi,
  onClick: onClickEvent,
  onContextMenu: onContextMenuEvent,
  onDoubleClick: onDoubleClickEvent,
  onHiddenChange,
  measure,
  onOpenInstanceMenu,
}: Props) => {

  const name = useSelector<State, string>(
    state => state.drawings.aiAnnotation.geometry[instance.id]?.name || instance.name,
  );

  const dispatchOnNameChange = useActionDispatch(DrawingsAnnotationActions.renameInstance);

  const onVisibleToggle = useCallback(() => {
    onHiddenChange([instance.id], !isHidden);
  }, [onHiddenChange, isHidden, instance.id]);

  const onDoubleClick = useCallback(() => {
    onDoubleClickEvent([instance.id]);
  }, [onDoubleClickEvent, instance.id]);

  const onClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    onClickEvent(event, instance.id);
  }, [onClickEvent, instance.id]);

  const onContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    return onContextMenuEvent(event, instance.id);
  }, [onContextMenuEvent, instance.id]);

  const { addUndoRedo } = useUndoRedoFullApi();

  const onNameChange = useCallback((value: string) => {
    if (instance.name !== value) {
      const { undo, redo } = DrawingsUndoRedoHelper.renameMeasurementUndoRedo(
        [{ instanceId: instance.id, name: value }],
        [{ instanceId: instance.id, name: instance.name }],
        dispatchOnNameChange,
      );
      addUndoRedo(undo, redo);
      redo();
    }
  }, [instance.id, instance.name, dispatchOnNameChange, addUndoRedo]);

  const openInstanceMenuCallback = useCallback((e: React.MouseEvent) => {
    ConstantFunctions.stopEvent(e);
    onOpenInstanceMenu(e, instance);
  }, [instance, onOpenInstanceMenu]);

  return (
    <DrawingsAnnoationBaseItem
      onOpenInstanceMenu={openInstanceMenuCallback}
      color={instance.geometry.color}
      id={instance.id}
      name={name}
      isSelected={isSelected}
      isHidden={isHidden}
      nestingCount={nestingCount}
      canEditMeasurement={canEditMeasurement}
      isImperial={isImperial}
      hasAssignedPia={hasAssignedPia}
      hasInheritedPia={hasInheritedPia}
      minWidth={minWidth}
      sendUpdateApi={sendUpdateApi}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onNameChange={onNameChange}
      onDoubleClick={onDoubleClick}
      onVisibleToggle={onVisibleToggle}
      ignoreAvatar={false}
      measure={measure}
      editor={instance.editor}
      creator={instance.creator}
      createdAt={instance.createdAt}
      editedAt={instance.editedAt}
    />
  );
};

const specDrag = {
  beginDrag: (props: Props) => {
    const itemsToMove = props.isSelected
      ? props.getItemsToMove()
      : {
        groups: [],
        measurements: [props.instance.id],
      };
    return itemsToMove;
  },
  canDrag: (props: Props) => props.canEditMeasurement,
};

export const DrawingsAnnotationLegendItem = withDragSourceWrapper(
  DrawingsAnnotationLegendItemComponent,
  specDrag,
  DrawingsDragAndDropType.DrawingGroup,
);
