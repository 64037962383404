import { VisibleEntity, VisibleEntityConfig } from '../../../common';

export interface GeometryPreviewConfig<T> extends VisibleEntityConfig<T> {
  layer: paper.Layer | paper.Group;
}

export abstract class GeometryPreview<
  T,
  C extends GeometryPreviewConfig<T> = GeometryPreviewConfig<T>
> extends VisibleEntity<T, C> {
  protected _path: paper.Path;

  constructor(config: C) {
    super(config);
    this._path.addTo(this._config.layer);
  }

  public destroy(): void {
    this._path.remove();
  }

  public set strokeWidth(value: number) {
    this._path.strokeWidth = value;
  }

  public set dashArray(value: number[]) {
    this._path.dashArray = value;
  }

  public set strokeColor(value: paper.Color) {
    this._path.strokeColor = value;
  }
}
