import React, { useCallback } from 'react';

import { DrawingMarkShapes, ShapeToIconMap } from 'common/components/drawings/constants/drawing-styles';
import { ShapeMenu, ShapeStyled } from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { useEditStyle } from 'common/components/drawings/hooks';
import { DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import { arrayUtils } from 'common/utils/array-utils';
import { PropertyWithMenuEditor } from '../property-menu-editor';
import { Styled } from './styled';


interface Props {
  instances: Record<string, DrawingsGeometryInstance>;
  values: Record<string, string[]>;
  selectedInstancesIds: string[];
  canEdit: boolean;
}

const ShapePropertyComponent: React.FC<Props> = ({
  values,
  instances,
  selectedInstancesIds,
  canEdit,
}) => {
  const editShape = useEditStyle('shape', instances);

  const optionRenderer = useCallback((property: string) => {
    const Icon = ShapeToIconMap[property];
    return (
      <Styled.OptionContainer>
        <ShapeStyled.Container>
          <Icon/>
        </ShapeStyled.Container>
      </Styled.OptionContainer>
    );
  }, []);

  const changeShape = useCallback((value: DrawingMarkShapes): void => {
    const instancesIds = Object.values(values).reduce((acc, v) => {
      arrayUtils.extendArray(acc, v);
      return acc;
    }, []);
    editShape(instancesIds, value);
  }, [editShape, selectedInstancesIds]);

  const editorRenderer = useCallback(() => {
    const elements = Object.keys(values);
    return (
      <Styled.EditorContainer>
        <ShapeMenu
          setShape={changeShape}
          shape={elements[0] as DrawingMarkShapes}
        />
      </Styled.EditorContainer>
    );
  }, [values, changeShape]);

  return (
    <PropertyWithMenuEditor
      name='Shape'
      elements={values}
      editorRenderer={canEdit ? editorRenderer : undefined}
      optionRenderer={optionRenderer}
    />
  );
};

export const ShapeProperty = React.memo(ShapePropertyComponent);
