import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { ClassificationChart } from 'components/charts';
import { ObjectStatisticType } from 'components/charts/interfaces/object-statistic-type';
import { AppUrls } from 'routes/app-urls';
import { CEActivityAssignmentActions } from 'unit-cost-estimate/actions/creators';
import { CostEstimateValidationStep, RevisionState } from 'unit-cost-estimate/interfaces';
import { RevisionStateHelper } from 'unit-cost-estimate/utils/revision-state-helper';
import { isCostEstimateValidationStepGreaterThan } from 'unit-cost-estimate/utils/validation-step';
import { ValidationStep } from 'unit-projects/enums/validation-step';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { DatabaseSelector } from 'unit-projects/pages/validation/steps/components';
import { StepWrapper, StepWrapperEngineApi } from '../step-wrapper';

interface DispatchProps {
  runActivityAssignmentModule: (dbIds: number[], usePreviousLaunch: boolean) => void;
  loadData: () => void;
  approve: () => void;
  disapprove: () => void;
  dropState: () => void;
}

interface StateProps {
  statistic: ObjectStatisticType[];
  dataLoadingStatus: RequestStatus;
  errors: number[];
  isApprovable: boolean;
  canDisapprove: boolean;
  isEditable: boolean;
  canBeCalculated: boolean;
  isCalculating: boolean;
  revisionState: RevisionState;
  databaseIds: number[];
}

interface OwnProps extends AbilityAwareProps {
  projectId: number;
  revisionId: number;
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class ActivityAssignmentComponent extends React.PureComponent<Props> {
  private stepEngineApi: StepWrapperEngineApi = null;

  public render(): React.ReactNode {
    const { revisionState, isApprovable, canDisapprove, disapprove, approve } = this.props;
    const scenarioInCalculation = revisionState
      && revisionState.activityAssignmentStatus === ViewModelStatus.Calculating;
    const shouldDisplayLoader = scenarioInCalculation || this.props.dataLoadingStatus === RequestStatus.Loading;

    const urlParams = {
      projectId: this.props.projectId.toString(),
      revisionId: this.props.revisionId.toString(),
    };
    const viewLink = AppUrls.costEstimate.project.revision.activityAssignment.view.url(urlParams);
    const fixLink = AppUrls.costEstimate.project.revision.activityAssignment.edit.url(urlParams);
    const onDisapprove = canDisapprove ? disapprove : null;
    const onApprove = isApprovable ? approve : null;


    return (
      <StepWrapper
        projectId={this.props.projectId}
        onApprove={onApprove}
        onDisapprove={onDisapprove}
        onEngineApiReady={this.initStepEngine}
      >
         <div className='classification-step__header'>
          <h3>Activity Assignment</h3>
        </div>
        <DatabaseSelector
          onCalculate={this.runActivityAssignmentModule}
          canBeCalculated={this.props.canBeCalculated}
          databasesCalculated={this.props.databaseIds}
          allowSettings={true}
        />
        <ClassificationChart
          fixLink={fixLink}
          viewLink={viewLink}
          isFixable={this.props.isEditable}
          data={this.props.statistic || []}
          isLoading={shouldDisplayLoader}
          noDataMessage='No calculation yet'
          onSelectIds={this.isolate}
          onDeselect={this.showAll}
          chartName={ValidationStep.ActivityAssignment}
          attentionStatus={this.hasUndefinedStatistic()}
        />
      </StepWrapper>
    );
  }

  public componentDidMount(): void {
    this.loadDataIfNeeded(this.props, null);
  }

  public componentDidUpdate(prevProps: Props): void {
    this.updateEngineColors();
    this.loadDataIfNeeded(this.props, prevProps);
  }

  public componentWillUnmount(): void {
    this.props.dropState();
  }

  private updateEngineColors(): void {
    const { errors, statistic, dataLoadingStatus } = this.props;

    if (
      dataLoadingStatus === RequestStatus.Loading
      && Array.isArray(errors)
      && errors.length
    ) {
      this.stepEngineApi.paintEngine([], errors);
    }

    if (!statistic || !statistic.length) {
      this.stepEngineApi.clearEngine();
    }
  }

  @autobind
  private initStepEngine(api: StepWrapperEngineApi): void {
    this.stepEngineApi = api;
    this.stepEngineApi.clearEngine();
    this.stepEngineApi.paintEngine([], this.props.errors);
  }

  private loadDataIfNeeded(currentProps: Props, prevProps: Props): void {
    const currentState = currentProps.revisionState;
    const prevState = prevProps && prevProps.revisionState;
    const needToLoad = RevisionStateHelper.shouldLoadActivityAssignmentModel(currentState, prevState);


    if (needToLoad) {
      this.props.loadData();
    }
  }

  @autobind
  private hasUndefinedStatistic(): boolean {
    const undefinedStat = this.props.statistic ? this.props.statistic.find(s => s.name === 'Undefined') : null;
    return undefinedStat && undefinedStat.amount > 0;
  }


  @autobind
  private runActivityAssignmentModule(ids: number[], usePreviousLaunch: boolean): void {
    if (this.props.canBeCalculated) {
      this.props.runActivityAssignmentModule(ids, usePreviousLaunch);
      if (this.stepEngineApi) {
        this.stepEngineApi.clearEngine();
      }
    }
  }

  @autobind
  private isolate(ids: number[]): void {
    this.stepEngineApi.isolate(ids);
  }

  @autobind
  private showAll(): void {
    this.stepEngineApi.showAll();
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const revisionState = state.projects.revisionStates[ownProps.revisionId];

  const canUpdate = ownProps.ability.can(Operation.Update, Subject.ValidationActivityAssignment);
  const canApprove = ownProps.ability.can(Operation.Approve, Subject.ValidationActivityAssignment);

  const isReady = revisionState &&
    revisionState.validationStep === CostEstimateValidationStep.ActivityAssignment &&
    revisionState.activityAssignmentStatus === ViewModelStatus.Ready;
  const isEditable = isReady && canUpdate;
  const isApprovable = isReady && canApprove;

  const isStepPassed = revisionState && isCostEstimateValidationStepGreaterThan(
    revisionState.validationStep, CostEstimateValidationStep.ActivityAssignment,
  );

  const canDisapprove = isStepPassed && canApprove;
  const canBeCalculated =  revisionState
    && revisionState.validationStep === CostEstimateValidationStep.ActivityAssignment
    && (
      revisionState.activityAssignmentStatus === ViewModelStatus.Empty
      || revisionState.activityAssignmentStatus === ViewModelStatus.Ready
    )
    && canUpdate;

  const isCalculating = revisionState
    && (
      revisionState.activityAssignmentStatus === ViewModelStatus.Calculating
      || revisionState.activityAssignmentStatus === ViewModelStatus.Waiting
    );

  return {
    databaseIds: state.ceActivityAssignment.databases,
    statistic: state.ceActivityAssignment.statistic,
    dataLoadingStatus: state.ceActivityAssignment.statuses.loadData,
    errors: state.ceActivityAssignment.badIds,
    isApprovable,
    canDisapprove,
    isEditable,
    canBeCalculated,
    isCalculating,
    revisionState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => {
  return {
    runActivityAssignmentModule: (dbIds: number[], usePreviousLaunch: boolean) =>
      dispatch(CEActivityAssignmentActions.calculateActivityAssignment(ownProps.revisionId, dbIds, usePreviousLaunch)),
    loadData: () => dispatch(CEActivityAssignmentActions.loadData()),
    approve: () => dispatch(CEActivityAssignmentActions.approve()),
    disapprove: () => dispatch(CEActivityAssignmentActions.disapprove()),
    dropState: () => dispatch(CEActivityAssignmentActions.dropState()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ActivityAssignment = withAbilityContext(connector(ActivityAssignmentComponent));
