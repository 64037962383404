import {
  CustomElementFilterNumber,
  CustomElementFilterString,
} from 'common/interfaces/custom-element-filter-builder';
import { CustomElementFilterResolvers } from '../custom-element-filter-helper/custom-element-filter-resolvers';

type FilterConditionType = CustomElementFilterNumber | CustomElementFilterString;
type ValueType = Array<string | number> | number | string;
type ValueGetter<T> = (object: T, key: string) => ValueType;

export interface CustomElementFilterConditionVlidatationResult {
  usedKeysMap: Record<string, boolean>;
  trueConditionIdsMap: Record<string, boolean>;
}

export class CustomElementFilterConditionValidator {
  public static validate<T>(
    records: T[],
    filterConditionsMap: Record<string, FilterConditionType>,
    valueGetter: ValueGetter<T>,
  ): CustomElementFilterConditionVlidatationResult {
    const result = {
      usedKeysMap: {},
      trueConditionIdsMap: {},
    };
    const notCheckedConditionIds = Object.keys(filterConditionsMap);
    notCheckedConditionIds.forEach(x => {
      result.trueConditionIdsMap[x] = false;
      result.usedKeysMap[filterConditionsMap[x].propertyKey] = false;
    });

    for (const record of records) {
      if (!notCheckedConditionIds.length) {
        return result;
      }

      for (const conditionId of notCheckedConditionIds) {
        const condition = filterConditionsMap[conditionId];
        const value = valueGetter(record, condition.propertyKey);
        if (value) {
          result.usedKeysMap[condition.propertyKey] = true;
        }

        if (this.getConditionValue(condition, value)) {
          result.trueConditionIdsMap[conditionId] = true;
          notCheckedConditionIds.splice(notCheckedConditionIds.findIndex(x => x === conditionId), 1);
        }
      }
    }

    return result;
  }

  private static getConditionValue(condition: FilterConditionType, value: ValueType): boolean {
    if ((condition as CustomElementFilterString).isCaseSensitive !== undefined) {
      return CustomElementFilterResolvers.resolveStringFilter(
        value as string | string[],
        condition as CustomElementFilterString,
      );
    } else if ((condition as CustomElementFilterNumber).operation !== undefined) {
      return CustomElementFilterResolvers.resolveNumberFilter(
        value as number | number[],
        condition as CustomElementFilterNumber,
      );
    }

    return false;
  }
}
