import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { DrawingsActions } from '../../actions/creators/common';

export function useToggle3d(): [boolean, () => void] {
  const is3d = useSelector<State, boolean>(state => state.drawings.is3d);
  const dispatch = useDispatch();
  const { sendEvent } = useAnalytics();
  const toggle3dView = useCallback(() => {
    dispatch(DrawingsActions.toggle3d());
    if (is3d) {
      sendEvent(MetricNames.threeDMeasures.switchingTo2D);
    } else {
      sendEvent(MetricNames.threeDMeasures.switchingTo3D);
    }
  }, [dispatch, is3d]);
  return [is3d, toggle3dView];
}
