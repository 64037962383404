export enum PermissionActivity {
  AccountManagement = 'AccountManagement',
  ProjectManagement = 'ProjectManagement',
  ProjectAccessManagement = 'ProjectAccessManagement',
  Viewer = 'Viewer',
  Database = 'Database',
  ValidationModelCheck = 'ValidationModelCheck',
  ValidationСlassification = 'ValidationСlassification',
  ValidationСlassificationApprove = 'ValidationСlassificationApprove',
  ValidationActivityAssignment = 'ValidationActivityAssignment',
  ValidationActivityAssignmentApprove = 'ValidationActivityAssignmentApprove',
  ValidationMeasurements = 'ValidationMeasurements',
  ValidationMeasurementsApprove = 'ValidationMeasurementsApprove',
  CostEstimate = 'CostEstimate',
  Gantt = 'Gantt',
  Visualisation4D = 'Visualisation4D',
  Dashboard = 'Dashboard',
  Scenarios = 'Scenarios',
  BidPricingManagement = 'BidPricingManagement',
  BidPricingCostInput = 'BidPricingCostInput',
  Takeoff2DMeasurement = 'Takeoff2DMeasurement',
  Takeoff2DReport = 'Takeoff2DReport',
}
