export const splitMap = async <T>(
  offset: number,
  keys: T[],
  mapPart: (id: T, index: number) => void,
  afterPart?: () => void,
): Promise<void> => {
  const updates = [];
  for (let index = 0; index < keys.length; index = index + offset) {
    const part = keys.slice(index, index + offset);
    updates.push(
      new Promise<void>((resolve, reject) => {
        try {
          setTimeout(() => {
            try {
              part.forEach((p, i) => mapPart(p, i + index));
              resolve();
              if (afterPart) {
                afterPart();
              }
            } catch (e) {
              console.error(e);
              reject(e);
            }
          }, 0);
        } catch (e) {
          console.error(e);
          reject(e);
        }
      }),
    );
  }
  await Promise.all(updates);
};
