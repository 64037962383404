export interface Reply {
  reply: string;
}

export interface QuestionnaireContent {
  question: string;
  replies: Reply[];
  isChecked: boolean;
  isOther: boolean;
}

export const HEADER_NEW_USER = 'Welcome aboard to Kreo';
export const HEADER_OTHER_USER = 'Tell us about yourself';
export const EXTRA_TEXT = 'Let us learn a little more about you, so that we can help you get the most out of Kreo';

export const LIST_QUESTION: QuestionnaireContent[] = [
  {
    question: 'What describes you best?',
    replies: [
      { reply: 'Quantity Surveyor' },
      { reply: 'Cost Estimator' },
      { reply: 'Contractor' },
      { reply: 'Builder' },
      { reply: 'Supplier' },
      { reply: 'Architect' },
    ],
    isChecked: false,
    isOther: true,
  },
  {
    question: 'What is your trade?',
    replies: [
      { reply: 'All trades' },
      { reply: 'Concrete' },
      { reply: 'Framing' },
      { reply: 'Drywall' },
      { reply: 'Painting' },
      { reply: 'Steel' },
      { reply: 'Flooring' },
      { reply: 'Masonry' },
    ],
    isChecked: false,
    isOther: true,
  },
  {
    question: 'What are you planning to use Kreo for?',
    replies: [
      { reply: 'For takeoffs' },
      { reply: 'For cost estimates' },
      { reply: 'For bills of quantities' },
      { reply: 'For material lists' },
      { reply: 'For cost plans' },
    ],
    isChecked: false,
    isOther: true,
  },
  {
    question: 'Have you used any takeoff software before? Which one?',
    replies: [
      { reply: 'No, I did my job with pen and ruler' },
      { reply: 'Stack' },
      { reply: 'PlanSwift' },
      { reply: 'Autodesk Takeoff' },
      { reply: 'CostX' },
      { reply: 'Bluebeam' },
    ],
    isChecked: false,
    isOther: true,
  },
  {
    question: 'How did you learn about Kreo?',
    replies: [
      { reply: 'Search engine (Google, Bing, etc.)' },
      { reply: 'Social media' },
      { reply: 'Referral from a friend or colleague' },
      { reply: 'Online advertising' },
      { reply: 'Trade show or event' },
      { reply: 'Direct mail or email marketing' },
    ],
    isChecked: false,
    isOther: true,
  },
];
