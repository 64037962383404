import * as Ag from 'ag-grid-community';

import {
  TwoDViewTableConfig,
} from 'unit-2d-database/interfaces';
import {
  FooterValue,
  numberValueFormatter,
  NUMBER_COLUMN_DEF_TYPE,
  sortComparator,
} from '../../constants';
import { Props } from '../../interfaces';
import { hasUpdatedGeometry } from '../has-updated-geometry';
import { IDataHelper, ViewData } from '../measure-helper';

interface MeasureInfo {
  fileName: string;
  pageName: string;
  parentGroup: string;
  measureName: string;
  measureId: string;
}

interface ItemRowData extends MeasureInfo {
  id: string;
  itemName: string;
  assemblyName: string;
  withUnit: boolean;
}

const emptyResult = {
};

export class ItemHelper implements IDataHelper<ItemRowData> {
  private prevResult: ViewData<ItemRowData>;
  private prevProps: Props<ItemRowData>;
  private prevIsPivot: boolean;
  private autoGroupColumn: Ag.ColDef = {
    field: 'name',
    width: 180,
    minWidth: 125,
    cellRendererParams: {
      footerValueGetter: () => {
        return FooterValue;
      },
      suppressCount: true,
      innerRenderer: 'defaultCellRenderer',
    },
    checkboxSelection: true,
    headerCheckboxSelection: true,
    comparator: sortComparator,
    valueFormatter: (params: Ag.ValueFormatterParams) => {
      const colDefs = params.api.getColumnDefs();
      const colDef: Ag.ColDef = colDefs.find((c: Ag.ColDef) => c.field === params.node.field);
      const isNumberColumn = colDef && colDef.type === NUMBER_COLUMN_DEF_TYPE;
      return isNumberColumn ? numberValueFormatter(params) : params.value;
    },
  };

  public isNeedUpdate(sourceData: Props<ItemRowData>, isPivot: boolean): boolean {
    return sourceData.assignPia !== this.prevProps?.assignPia
      || sourceData.calculatedPia !== this.prevProps?.calculatedPia
      || sourceData.aiAnnotation.additionalColors !== this.prevProps?.aiAnnotation.additionalColors
      || sourceData.aiAnnotation.fileData !== this.prevProps?.aiAnnotation.fileData
      || sourceData.aiAnnotation.geometryCreators !== this.prevProps?.aiAnnotation.geometryCreators
      || sourceData.aiAnnotation.pointsInfo !== this.prevProps?.aiAnnotation.pointsInfo
      || sourceData.elementMeasurement !== this.prevProps?.elementMeasurement
      || sourceData.files !== this.prevProps?.files
      || sourceData.groups !== this.prevProps?.groups
      || sourceData.drawingsInfo !== this.prevProps?.drawingsInfo
      || sourceData.isImperial !== this.prevProps?.isImperial
      || sourceData.withUnit !== this.prevProps?.withUnit
      || this.prevIsPivot !== isPivot
      || sourceData.withTableTotal !== this.prevProps?.withTableTotal
      || sourceData.originProperties !== this.prevProps?.originProperties
      || sourceData.showCode !== this.prevProps?.showCode
      || hasUpdatedGeometry(sourceData.aiAnnotation.geometry, this.prevProps?.aiAnnotation.geometry);
  }

  public async getViewData(
    props: Props<ItemRowData>,
    isPivot: boolean,
  ): Promise<ViewData<ItemRowData>> {
    if (!this.isNeedUpdate(props, isPivot)) {
      return this.prevResult;
    }
    return emptyResult as any;
  }

  public getAutoColumnGroup(config?: TwoDViewTableConfig): Ag.ColDef {
    if (config) {
      this.autoGroupColumn.minWidth = config.allowColumnsConfiguration
        ? 125
        : 445;
      this.autoGroupColumn.width = config.allowColumnsConfiguration
        ? 180
        : 600;
    }
    return this.autoGroupColumn;
  }
}
