import * as t from 'io-ts';

import { BidPricingWorkPackageC } from './bid-pricing-work-package';

export const BidPricingCalculationDetailsC = t.strict(
  {
    calculationId: t.number,
    workPackages: t.array(BidPricingWorkPackageC),
  },
  'BidPricingCalculationDetails');

export type BidPricingCalculationDetails = t.TypeOf<typeof BidPricingCalculationDetailsC>;
