import autobind from 'autobind-decorator';
import { goBack } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Action, Dispatch } from 'redux';

import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit/dialogs/';
import { AppUrls } from 'routes/app-urls';
import { CostEstimateTable } from '../../../../components/cost-estimate-table';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { BidPricingActions } from '../../actions/creators/bid-pricing';
import { CostsActions } from '../../actions/creators/costs';
import { bidDescriptionDialogName } from '../../components/bid-pricing//bid-description-dialog';
import { CostPageLayout } from '../../components/bid-pricing/cost-page-layout';
import { CostEstimateGroupingType } from '../../enums/cost-estimate-grouping-type';
import { BidPricingUserCost } from '../../interfaces/bid-pricing/bid-pricing-user-cost';

interface ReduxProps {
  costs: any;
  constructionPackage: BidPricingUserCost;
  userid: string;
}

interface ReduxActions {
  dropData: () => void;
  openDescriptionDialog: () => void;
  setWinner(userId: string, workPackageId: number, constructionCostId: number): void;
}

interface Props extends ReduxProps, ReduxActions, RouteComponentProps<PlanProjectRouteParams> {}

class InfoCostPageComponent extends React.Component<Props> {
  public componentWillUnmount(): void {
    this.props.dropData();
  }

  public render(): React.ReactNode {
    const { constructionPackage } = this.props;

    if (!constructionPackage) {
      return null;
    }

    const caption = constructionPackage.user.firstName
      ? `${constructionPackage.user.firstName} ${constructionPackage.user.lastName}`
      : constructionPackage.user.email;
    return (
      <CostPageLayout
        projectId={this.props.match.params && this.props.match.params.projectId}
        caption={caption}
        status={constructionPackage.status}
        description={constructionPackage.description}
        onButtonClick={this.setWinner}
        navigate={this.navigate}
        isSubcontractor={false}
        onDescriptionClick={this.props.openDescriptionDialog}
      >
        <CostEstimateTable
          data={this.props.costs}
          groupingType={CostEstimateGroupingType.Workpackages}
          edited={false}
          includeDuration={false}
        />
      </CostPageLayout>
    );
  }

  @autobind
  private navigate(): void {
    const { history, match } = this.props;
    history.push(AppUrls.plan.project.bidPricing.index.url({ projectId: match.params.projectId }));
  }

  @autobind
  private setWinner(): void {
    this.props.setWinner(
      this.props.userid,
      this.props.constructionPackage.packageId,
      this.props.constructionPackage.id,
    );
  }
}

function mapStateToProps(state: ReduxState): ReduxProps {
  return {
    costs: state.cost.get('costs').toJS(),
    constructionPackage: state.bidPricing.package,
    userid: state.account.id,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): ReduxActions {
  return {
    dropData: () => {
      dispatch(CostsActions.dropCostData());
    },
    setWinner: (userId: string, workPackageId: number, constructionCostId: number) => {
      dispatch(BidPricingActions.setWinner(userId, workPackageId, constructionCostId));
      dispatch(goBack());
    },
    openDescriptionDialog: () => {
      dispatch(KreoDialogActions.openDialog(bidDescriptionDialogName));
    },
  };
}

export const InfoCostPage = connect(mapStateToProps, mapDispatchToProps)(InfoCostPageComponent);
