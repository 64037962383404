import * as React from 'react';
import { ResponsiveProps } from 'react-grid-layout';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  rowHeight: number;
}

interface State {
  width: number;
  rowHeight: number;
  render: boolean;
}

export function WidthProvider(
  ComposedComponent: React.ComponentClass<ResponsiveProps>,
): React.ComponentClass<ResponsiveProps & Props> {
  return class WidthProviderComponent extends React.Component<
    ResponsiveProps & Props,
    State
  > {
    public state: State = {
      width: 1280,
      rowHeight: this.props.rowHeight,
      render: false,
    };
    private mounted: boolean = false;
    private container: HTMLDivElement = null;
    private component: React.Component<ResponsiveProps, any> = null;
    private timeout: any = null;

    public componentDidMount(): void {
      this.mounted = true;
      window.addEventListener('resize', this.onWindowResize);
      this.onWindowResize();
    }

    public componentWillUnmount(): void {
      this.mounted = false;
      window.removeEventListener('resize', this.onWindowResize);
    }

    public componentDidUpdate(): void {
      this.onWindowResize();
    }

    public render(): JSX.Element {
      const style = {
        opacity: this.state.render ? 1 : 0,
        transition: 'all .3s ease',
      };

      if (!this.mounted) {
        return <div className={this.props.className} style={style} />;
      }

      return (
        <div ref={this.setContainer} style={style}>
          <ComposedComponent
            {...this.props}
            {...this.state}
            ref={this.setComponent}
          />
        </div>
      );
    }

    private onWindowResize = (): void => {
      if (!this.mounted) return;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const node = this.container;
        if (node instanceof HTMLElement) {
          const width = node.offsetWidth;
          const rowHeight =
            (width -
              this.component.props.margin[0] * this.component.state.cols) /
              this.component.state.cols || this.props.rowHeight;

          if (
            this.state.width === node.offsetWidth &&
            this.state.rowHeight === rowHeight &&
            !this.state.render
          ) {
            this.setState({ render: true });
          } else if (
            this.state.width !== node.offsetWidth ||
            this.state.rowHeight !== rowHeight
          ) {
            this.setState({ width, rowHeight });
          }
        }
      },                        300);
    };

    private setContainer = (x: HTMLDivElement): void => {
      this.container = x;
    };

    private setComponent = (x: React.Component<ResponsiveProps, any>): void => {
      this.component = x;
    };
  };
}
