import { Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import { LabelGroup } from 'common/components/label-group';
import { IMenuProperties, MenuProperties } from 'unit-2d-database/components/menu-properties/menu-properties';
import { VisibilityFilterType } from 'unit-2d-database/interfaces';
import { GroupPropertiesForm } from '../group-properties-list';
import { ItemForm } from '../item-panel/helpers/property-group-form-updater';
import { Styled } from '../styled';

interface Props {
  id: string;
  baseId: string;
  name: string;
  itemForm: ItemForm;
  onDeleteItem: () => void;
  isOpen: boolean;
  visibilityMode: VisibilityFilterType;
  collapsedGroups: Set<string>;
  toggleGroup: (id: string, isOpen: boolean) => void;
  Icon: React.ComponentType;
  switchPanel: (itemId: string) => void;
  onClickSelect: (itemId: string, baseId: string) => void;
  getId?: () => string;
  parentId?: string;
  hideDeleteButton?: boolean;
  editItem?: (name: string) => void;
  disabled?: boolean;
}

interface OwnState {
  isOpenContextMenu: boolean;
}

export class ItemGroup extends React.PureComponent<Props, OwnState> {
  private extraProperties: IMenuProperties[] = [
    {
      icon: Icons.PropertiesDataBase,
      text: 'Select from the base',
      onClick: this.onClickSelect,
    },
  ];

  public constructor(props: Props) {
    super(props);

    this.state = {
      isOpenContextMenu: false,
    };
  }


  public render(): JSX.Element {
    const {
      id,
      name,
      itemForm,
      isOpen,
      visibilityMode,
      collapsedGroups,
      Icon,
      onDeleteItem,
      parentId,
      toggleGroup,
      hideDeleteButton,
      editItem,
      disabled,
    } = this.props;
    return (
      <Styled.ItemGroup className={id}>
        <Styled.LabelGroupWrapper>
          <LabelGroup
            text={name}
            Icon={Icon}
            tooltipTextDeleteButton={'Remove from the list'}
            tooltipTextAddButton={'Add a new property to item'}
            isExpanded={isOpen}
            onGroupClick={this.onClickItemGroup}
            removeGroup={onDeleteItem}
            addItemToGroup={this.openContextMenu}
            addButtonContextMenu={
              this.state.isOpenContextMenu
                ? <MenuProperties
                  closeContextMenu={this.closeContextMenu}
                  onClick={this.onAddProperty}
                  extraProperties={this.extraProperties}
                />
                : undefined
            }
            editItem={editItem && this.editItem}
            tooltipTextEditButton={'Edit Item'}
          />
        </Styled.LabelGroupWrapper>
        {isOpen && (
          <GroupPropertiesForm
            parentId={`${parentId}_${id}`}
            groups={itemForm.groupForm}
            visibilityMode={visibilityMode}
            collapsedGroups={collapsedGroups}
            toggleGroup={toggleGroup}
            onAddProperty={this.onAddProperty}
            extraProperties={this.extraProperties}
            hideDeleteButton={hideDeleteButton}
            disabled={disabled}
            getId={this.getId}
          />
        )}
      </Styled.ItemGroup>
    );
  }

  @autobind
  private getId(): string {
    if (this.props.getId) {
      return `${this.props.id}_${this.props.getId()}`;
    }
    return `${this.props.id}_`;
  }

  @autobind
  private editItem(): void {
    this.props.editItem(this.props.name);
  }

  @autobind
  private onClickItemGroup(): void {
    const { isOpen, parentId, id, toggleGroup } = this.props;
    toggleGroup(`${parentId}_${id}`, !isOpen);
  }

  @autobind
  private onAddProperty(): void {
    this.props.switchPanel(this.props.id);
  }

  @autobind
  private openContextMenu(): void {
    this.setState({ isOpenContextMenu: true });
  }

  @autobind
  private closeContextMenu(): void {
    this.setState({ isOpenContextMenu: false });
  }

  @autobind
  private onClickSelect(): void {
    this.props.onClickSelect(this.props.id, this.props.baseId);
  }
}
