import { ElementTooltip, IconButton, Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { Styled } from './styled';

interface Props {
  placeholder: string;
  isQuickSearchVisible: boolean;
  setQuickSearchValue: (value: string) => void;
  value?: string;
  tooltipText?: string;
  toggleQuickSearch?: () => void;
  passRef?: (ref: React.RefObject<HTMLInputElement>) => void;
}

interface State {
  showCross: boolean;
}

export class QuickSearchInput extends React.PureComponent<Props, State> {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  public constructor(props: Props) {
    super(props);

    this.state = {
      showCross: !!props.toggleQuickSearch || !!this.props.value,
    };
  }

  public render(): JSX.Element {
    if (!this.props.isQuickSearchVisible) {
      return null;
    }
    const showCross = this.state.showCross;
    return (
      <Styled.Container>
        <Styled.SearchIcon>
          <Icons.Search />
        </Styled.SearchIcon>
        <Styled.Input
          value={this.props.value}
          ref={this.inputRef}
          onInput={this.onInput}
          placeholder={this.props.placeholder}
        />
        {showCross && <Styled.Cross>
          <ElementTooltip
            text={this.props.tooltipText || 'Hide'}
            position='left'
            speed='s'
          >
            <IconButton
              Icon={Icons.CloseSmall}
              onClick={this.onClick}
              width={10}
              height={10}
              iconHeight={10}
              defaultColor={'red'}
            />
          </ElementTooltip>
        </Styled.Cross>}
      </Styled.Container>
    );
  }

  public componentDidMount(): void {
    if (this.props.passRef) {
      this.props.passRef(this.inputRef);
    }
  }

  @autobind
  private onClick(): void {
    const { toggleQuickSearch } = this.props;
    if (toggleQuickSearch) {
      toggleQuickSearch();
    }
    this.handleChangeSearchValue('');
  }

  @autobind
  private onInput(event: React.FormEvent<HTMLInputElement>): void {
    const value = event.currentTarget.value;
    this.handleChangeSearchValue(value);
  }

  @autobind
  private handleChangeSearchValue(value: string): void {
    this.props.setQuickSearchValue(value);
    if (!this.props.toggleQuickSearch) {
      this.setState({ showCross: !!value });
      this.inputRef.current.value = value;
    }
  }
}
