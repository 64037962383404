import * as Ag from 'ag-grid-community';

import { PropertyHelper } from '.';


export const QtoColumnPropertyHelper = {
  columnProperties: {
    header: 'header',
    width: 'width',
    aggregationStrategy: 'aggregationStrategy',
    pinned: 'pinned',
    isVisible: 'isVisible',
    unit: 'unit',
  },

  getColumnUnit: (column: Ag.Column) => {
    const colDef = column.getColDef();
    return colDef.cellRendererParams.unit && PropertyHelper.getActualValue<string>(colDef.cellRendererParams.unit);
  },
};
