import { Location } from 'history';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { ConstantFunctions } from 'common/constants/functions';
import { SubscriptionType } from 'common/constants/subscription';
import { KreoProduct } from 'common/enums/kreo-product';
import { PermissionState } from 'common/enums/permission-state';
import { EnvironmentConfigurationProps } from 'common/environment/environment-configuration-props';
import { SmartlookUtils } from 'common/environment/smartlook-utils';
import { withEnvironmentConfiguration } from 'common/environment/with-environment-configuration';
import { State } from 'common/interfaces/state';
import { CompanySubscriptionModel } from '../account/interfaces/company-subscription-model';
import { AnalyticsActions } from './actions/actions';
import { SegmentContextProps, withSegmentContext } from './components/segment-context';
import { TrackerProvider } from './components/utils/tracker-provider';

interface StateProps {
  userId: string | null;
  userHash: string | null;
  ownCompanyId: number | null;
  userFirstName: string | null;
  userLastName: string | null;
  userEmail: string | null;
  companyId: number | null;
  companyName: string | null;
  roleId: number | null;
  roleName: string | null;
  roleGroup: string | null;
  roleCode: string | null;
  location: Location | null;
  userRolePermissions: Record<string, PermissionState>;
  subscriptions: Record<SubscriptionType, CompanySubscriptionModel> | null;
}

interface DispatchProps {
  setChatOpen: (isOpen: boolean) => void;
}

interface ControllerState {
  isHubspotConsentAllowed: boolean;
}

interface ControllerProps extends
  StateProps,
  DispatchProps,
  SegmentContextProps,
  EnvironmentConfigurationProps,
  AbilityAwareProps {
}

class AnalyticsControllerComponent extends React.Component<ControllerProps, ControllerState> {
  private get segmentTracker(): SegmentTracker {
    return TrackerProvider.segmentTracker();
  }

  private get hubspotTracker(): HubspotTracker {
    return TrackerProvider.hubspotTracker();
  }

  private get intercomTracker(): IntercomTracker {
    return TrackerProvider.intercomTracker();
  }

  private isHubspotLoaded: boolean = false;
  private isIntercomLoaded: boolean = false;
  private isSmartlookIdentified: boolean = false;
  private isSmartLookEnable: boolean = true;

  constructor(props: ControllerProps) {
    super(props);
    this.state = {
      isHubspotConsentAllowed: false,
    };
  }

  public render(): React.ReactNode {
    return null;
  }

  public componentDidMount(): void {
    this.tryInitAnalytics(this.props);
  }

  public shouldComponentUpdate(nextProps: ControllerProps, nextState: ControllerState): boolean {
    const isSmartLookDisable = this.props.ability.can(Operation.Read, Subject.SmartLook);
    if (this.isSmartLookEnable && this.props.userId && isSmartLookDisable) {
      SmartlookUtils.disable();
      this.isSmartLookEnable = false;
    }

    if (!this.props.isSegmentReady && !nextProps.isSegmentReady && !nextProps.isSegmentLoaded) {
      return false;
    }

    if (nextProps.isSegmentReady && !this.props.isSegmentReady) {
      this.identifyInSegment(nextProps);
      this.groupInSegment(nextProps);
    }

    let needUpdateSmartlookInfo = false;

    if (nextState.isHubspotConsentAllowed && !nextState.isHubspotConsentAllowed && this.props.userId) {
      needUpdateSmartlookInfo = true;
    }

    if (nextProps.userId && nextProps.userId !== this.props.userId) {
      this.identifyInSegment(nextProps); // user logged in
      needUpdateSmartlookInfo = true;
    }

    if (this.props.userId && !nextProps.userId) {
      SmartlookUtils.anonymize();
      this.isSmartlookIdentified = false;
      this.resetIdentityInSegment(); // user logged out
    }

    if (nextProps.companyId && nextProps.companyId !== this.props.companyId
      || nextProps.subscriptions && !isEqual(nextProps.subscriptions, this.props.subscriptions)) {
      this.groupInSegment(nextProps);
      needUpdateSmartlookInfo = !!nextProps.userId;
      SmartlookUtils.anonymize();
      if (this.isSmartlookIdentified) {
        this.isSmartlookIdentified = false;
      }
    }

    if (nextProps.location && nextProps.location !== this.props.location) {
      this.pageInSegment(nextProps);
    }

    if (nextState.isHubspotConsentAllowed && needUpdateSmartlookInfo) {
      this.setSmartlookUserInfo(nextProps);
    }

    if (this.hubspotTracker && !this.isHubspotLoaded) {
      const consentCallback = (consent): void => this.setState({ isHubspotConsentAllowed: consent.allowed });
      this.hubspotTracker.push(['addPrivacyConsentListener', consentCallback]);
      this.isHubspotLoaded = true;
    }

    if (this.intercomTracker && !this.isIntercomLoaded) {
      this.intercomTracker('onShow', () => this.props.setChatOpen(true));
      this.intercomTracker('onHide', () => this.props.setChatOpen(false));
      this.isIntercomLoaded = true;
    }

    return false;
  }

  private setSmartlookUserInfo(props: ControllerProps): void {
    SmartlookUtils.setUserInfo({
      userRoleName: props.roleName,
      email: props.userEmail,
      companyId: props.companyId,
      firstName: props.userFirstName,
      lastName: props.userLastName,
      id: props.userId,
      companyName: props.companyName,
      name: `${props.userFirstName} ${props.userLastName}`,
    });
    this.isSmartlookIdentified = true;
  }

  private tryInitAnalytics(props: ControllerProps): void {
    if (props.isSegmentReady) {
      this.identifyInSegment(props);
      this.groupInSegment(props);
    }
  }

  private resetIdentityInSegment(): void {
    if (this.props.isSegmentReady) {
      this.segmentTracker.reset();
    }
  }

  private identifyInSegment(props: ControllerProps): void {
    const { userId, userEmail, userFirstName, userLastName, userHash, ownCompanyId, isSegmentReady } = props;
    if (isSegmentReady && userEmail) {
      this.segmentTracker.identify(
        userEmail,
        {
          email: userEmail,
          userId,
          name: `${userFirstName} ${userLastName}`,
          company: this.getGroupAttributes(props),
          ['Own Company Id']: ownCompanyId,
        },
        {
          integrations: {
            Intercom: {
              user_hash: userHash,
            },
          },
        },
        ConstantFunctions.doNothing,
      );
    }
  }

  private groupInSegment(props: ControllerProps): void {
    const { companyId, isSegmentReady } = props;
    if (isSegmentReady && companyId) {
      const attributes = this.getGroupAttributes(props);

      this.segmentTracker.group(companyId, attributes);
    }
  }

  private getGroupAttributes(props: ControllerProps): any {
    const { companyId, companyName, subscriptions } = props;
    if (!companyId) {
      return undefined;
    }

    const attributes = {
      id: companyId,
      name: companyName,
    };

    Object.entries(subscriptions).forEach(([type, subscription]) => {
      attributes[`${type} Plan`] = subscription.planId;
      attributes[`${type} Status`] = subscription.billingStatus;
      attributes[`${type} Create`] = subscription.createdAt;
      attributes[`${type} Next Billing`] = subscription.nextBillingAt;
      attributes[`${type} Trial End`] = subscription.trialEnd;
      attributes[`${type} License Count`] = subscription.employeesMaxCount;
      if (type === SubscriptionType.Takeoff2d) {
        attributes[`${type} AutoMeasure`] = subscription.products.includes(KreoProduct.Takeoff2dAutoMeasure);
        attributes[`${type} AutoMeasureApi`] = subscription.products.includes(KreoProduct.Takeoff2dAutoMeasureApi);
        attributes[`${type} Annotations`] = subscription.products.includes(KreoProduct.Takeoff2dAnnotations);
      }
    });

    return attributes;
  }

  private pageInSegment(props: ControllerProps): void {
    if (this.props.isSegmentReady) {
      const { location } = props;
      this.segmentTracker.page(location ? location.pathname : '');
    }
  }
}

const mapStateToProps = (state: State): StateProps => {
  const account = state.account;

  const userId = account && account.id ? account.id : null;
  const userEmail = account && account.email ? account.email : null;
  const userHash = account && account.intercomUserHash ? account.intercomUserHash : null;
  const userFirstName = account && account.firstName ? account.firstName : null;
  const userLastName = account && account.lastName ? account.lastName : null;

  const company = account && account.selectedCompany ? account.selectedCompany : null;
  const subscriptions = company && company.subscriptions || null;
  const ownCompanyId = account && account.ownedCompany && account.ownedCompany.id || null;
  const subscription = company && company.subscriptions[account.selectedSubscriptionType] || null;
  const companyId = company && company.id || null;
  const companyName = company && company.name || null;
  const roleId = subscription && subscription.userRoleId || null;
  const roleName = subscription ? subscription.userRoleName : null;
  const roleGroup = subscription ? subscription.userRoleGroup : null;
  const roleCode = subscription ? subscription.userRoleCode : null;
  const userRolePermissions = subscription ? subscription.userRolePermissions : null;

  return {
    userId,
    userEmail,
    userHash,
    userFirstName,
    userLastName,
    companyId,
    companyName,
    ownCompanyId,
    roleId,
    roleName,
    roleGroup,
    roleCode,
    location: state.router.location,
    userRolePermissions,
    subscriptions,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    setChatOpen: (isOpen) => dispatch(AnalyticsActions.setChatOpen(isOpen)),
  };
};

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
const AnalyticsControllerWithAbilityContext = withAbilityContext(AnalyticsControllerComponent);

export const AnalyticsController = withEnvironmentConfiguration(
  withSegmentContext(
    reduxConnector(AnalyticsControllerWithAbilityContext),
  ),
);
