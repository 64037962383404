import { Icons, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { ProjectsFolder } from '../../../projects/interfaces/projects-folder';
import { Styled } from './styled';

interface Props {
  folder: ProjectsFolder;
  onClick: (folder: ProjectsFolder) => void;
}

export class MoveToFolderItem extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <Styled.DialogFolder
        onClick={this.onClick}
      >
        <Icons.Group_2 height={20} width={20} />
        <Text fontSize={14} withEllipsis={true}>{this.props.folder.name}</Text>
        <Styled.DialogButton>
          <Icons.SmallArrow height={20} width={20}/>
        </Styled.DialogButton>
      </Styled.DialogFolder>
    );
  }

  @autobind
  private onClick(): void {
    this.props.onClick(this.props.folder);
  }
}
