import { WizzardStatus } from '../../enums/dropper-state';


export interface StreamProgress<ResultType> {
  status: WizzardStatus;
  value: ResultType;
}

export interface BaseStreamRunConfig<RequestType = any, ResultType = any> {
  request: RequestType;
  fileId: string;
  drawingId: string;
  onProgress: (value: StreamProgress<ResultType>) => void;
}

export type IStream<
  RequestType,
  ResultType,
  C extends BaseStreamRunConfig<RequestType, ResultType> = BaseStreamRunConfig,
> = new () => Stream<RequestType, ResultType, C>;


export class Stream<
  RequestType,
  ResultType,
  C extends BaseStreamRunConfig<RequestType, ResultType> = BaseStreamRunConfig> {
  protected _cancelled: boolean = false;
  protected _jobId: string;
  protected _config: C;

  public async run(config: C): Promise<void> {
    if (!this.runRequest || !this.streamRequest) {
      throw new Error('runRequest or streamRequest method is not implemented');
    }

    const id = await this.runRequest(config);
    this._jobId = id;
    if (this._cancelled) {
      if (this.sendCancelRequest) {
        return this.sendCancelRequest(config.fileId, config.drawingId);
      }
      return;
    }

    await this.streamRequest(config);
  }

  public cancel(): void {
    this._cancelled = true;
  }

  protected runRequest?(config: C): Promise<string>;
  protected sendCancelRequest?(fileId: string, drawingId: string): Promise<void>;

  protected async streamRequest?(
    config: C,
  ): Promise<any> {
    this._config = config;
  }
}
