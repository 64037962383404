import styled from 'styled-components';

import { CommentCountMarkStyled } from 'unit-2d-comments/comment-count-mark';

const ADD_REPORT_BUTTON_WIDTH = 36;
const NEW_WINDOW_BUTTON_WIDTH = 40;

const Container = styled.div<{ isNewWindowButton: boolean }>`
  position: relative;
  display: flex;
  max-width: calc(100% - ${p => (p.isNewWindowButton ? NEW_WINDOW_BUTTON_WIDTH : 0) + ADD_REPORT_BUTTON_WIDTH}px);
  flex-grow: 1;
  background-color: ${p => p.theme.color.backgroundRush};
`;

const TabsListContainer = styled.div`
  max-width: calc(100% - 40px);
  width: max-content;
`;

const CountMark = styled.div`
  position: relative;
  z-index: 1;
  cursor: pointer;

  ${CommentCountMarkStyled.Container} {
    position: initial;
    border-radius: 8px 8px 8px 2px;
  }
`;

export const Styled = {
  Container,
  TabsListContainer,
  CountMark,
};
