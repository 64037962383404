import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import './index.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RequestStatus } from 'common/enums/request-status';
import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { Location } from 'common/interfaces/common-state';
import { UserMap } from 'common/interfaces/people';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoButton, KreoDialogActions, KreoScrollbars } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { Common as CommonActions } from '../../../../actions';
import { ScenarioDialog, UPDATE_SCENARIO_DIALOG_NAME } from '../../../../components/dialog';
import { KreoConfirmationDialog } from '../../../../components/dialog/kreo-confirmation-dialog';
import { Header, Item } from '../../../../components/table/header';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { ProjectLayout } from '../../../project-dashbord';
import { ScenariosActions } from '../../actions';
import { ScenariosActionsType, ScenariosData } from '../../interfaces';
import { ScenarioItem } from '../scenario-item';

interface ReduxProps {
  scenariosStatus: RequestStatus;
  scenarios: ScenariosData.Scenario[];
  scenariosToCopy: number[];
  calculationsStatus: number[];
  isOwner: boolean;
  bidPricingCalculation: number | null;
  locations: Location[];
  people: UserMap | null;
  onSendRef?: (ref: HTMLDivElement) => void;
}

interface ReduxActions {
  getScenarios: (id: number) => void;
  changeScenarioName: (id: number, name: string) => void;
  changeCalculationName: (id: number, name: string, scenarioId: number) => void;
  deleteScenario: (id: number) => void;
  copyScenario: (id: number) => void;
  publishScenario: (id: number, value: boolean) => void;
  getCalculations: (id: number) => void;
  activateScenario: (scenarioId: number, calculationId: number) => void;
  deleteCalculation: (calculationId: number, scenarioId: number) => void;
  activateCalculation: (id: number, scenarioId: number) => void;
  setEditScenario: (scenario: ScenariosData.Scenario) => void;
  deleteAllScenariousExceptActive: (id: number) => void;
  updateScenario: (data: ScenariosActionsType.UpdateScenario) => void;
  getLocations: () => void;
  openDialog: (dialogName: string) => void;
  closeDialog: (dialogName: string) => void;
}

interface Props
  extends ReduxProps,
  ReduxActions,
  RouteComponentProps<PlanProjectRouteParams>,
  AbilityAwareProps,
  I18nAwareProps { }

interface State {
  showShortLevelling: boolean;
  updateScenario: ScenariosData.Scenario;
  scenarioToBeDeleted: ScenariosData.Scenario;
  fixedHeader: boolean;
}

const scenarioDeleteAllDialogName = 'delete-all-scenarios';
const scenarioDeleteDialogName = 'delete-scenario';

class ScenarioPageComponent extends React.Component<Props, State> {
  private scrollMasterContainer: HTMLDivElement;
  private tableContainer: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      showShortLevelling: false,
      updateScenario: null,
      scenarioToBeDeleted: null,
      fixedHeader: false,
    };
  }

  public UNSAFE_componentWillMount(): void {
    this.props.getScenarios(parseInt(this.props.match.params.projectId, 10));
    this.props.getLocations();
  }

  public componentDidMount(): void {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  public render(): JSX.Element {
    const {
      scenarios,
      changeScenarioName,
      changeCalculationName,
      copyScenario,
      publishScenario,
      scenariosToCopy,
      getCalculations,
      activateScenario,
      deleteCalculation,
      activateCalculation,
      calculationsStatus,
      locations,
      ability,
      i18n,
    } = this.props;
    const hasChangeScenarioStatus = scenarios.filter(x => x.isPublic).length > 1;

    return (
      <ProjectLayout projectId={this.props.match.params && this.props.match.params.projectId}>
        <KreoScrollbars onSendRef={this.saveScrollRef} onScroll={this.onScroll}>
          <KreoConfirmationDialog
            name={scenarioDeleteAllDialogName}
            onYes={this.deleteScenariousExceptActive}
            yesText='Delete'
            noText='Cancel'
            title='Delete Inactive Scenarios'
          >
            All scenarios except for the Active one will be permanently deleted for all the people
            in your team. This action can’t be undone.
          </KreoConfirmationDialog>
          <KreoConfirmationDialog
            name={scenarioDeleteDialogName}
            onYes={this.deleteScenario}
            yesText='Delete'
            noText='Cancel'
            title='Delete Scenario'
          >
            The "{this.state.scenarioToBeDeleted ? this.state.scenarioToBeDeleted.name : ''}"
            scenario" will be permanently deleted. This action can’t be undone.
          </KreoConfirmationDialog>
          <div className='scenario-page'>
            <div className='scenario-page__header'>
              <div className='scenario-page__header-container'>
                <div className='scenario-page__title'>Construction scenarios</div>
              </div>
              <div className='scenario-page__header-action'>
                {ability.can(Operation.Delete, Subject.InactiveScenarios) && (
                  <KreoButton
                    size='medium'
                    caption='Delete all inactive scenarios'
                    onClick={this.openDeleteAllScenarious}
                  />
                )}
              </div>
            </div>
            <div
              className={'scenario-page__table-container'}
              ref={this.saveContainerRef}
            >
              <Header fixed={this.state.fixedHeader}>
                <Item label='Scenario' className='scenario-item__scenario' />
                <div className='scenario-item__wrap'>
                  <Item label='Calculation' className='calculation-item__info-name' />
                  <Item
                    label='Labour'
                    secondLabel={i18n.currency.symbol}
                    className='calculation-item__info-labour'
                  />
                  <Item
                    label='Plant'
                    secondLabel={i18n.currency.symbol}
                    className='calculation-item__info-plant'
                  />
                  <Item
                    label='Material'
                    secondLabel={i18n.currency.symbol}
                    className='calculation-item__info-material'
                  />
                  <Item
                    label='O&amp;P'
                    secondLabel={i18n.currency.symbol}
                    className='calculation-item__info-overhead'
                  />
                  <Item
                    label='Total cost'
                    secondLabel={i18n.currency.symbol}
                    className='calculation-item__info-total'
                  />
                  <Item label='Resource levelling' className='calculation-item__info-levelling' />
                  <Item
                    label='Duration'
                    secondLabel='days'
                    className='calculation-item__info-duration'
                  />
                  <Item label='Action' className='calculation-item__info-actions' />
                </div>
              </Header>
              <div className='scenario-page__scenario-list'>
                {scenarios.map((x): JSX.Element => (
                  <ScenarioItem
                    key={x.id}
                    item={x}
                    changeScenarioName={changeScenarioName}
                    changeCalculationName={changeCalculationName}
                    deleteScenario={this.openDeleteScenarioDialog}
                    copyScenario={copyScenario}
                    publishScenario={publishScenario}
                    scenariosToCopy={scenariosToCopy}
                    getCalculations={getCalculations}
                    activateScenario={activateScenario}
                    deleteCalculation={deleteCalculation}
                    activateCalculation={activateCalculation}
                    onEditScenario={this.goToWorkpackages}
                    calculationsStatus={calculationsStatus}
                    showShortLevelling={this.state.showShortLevelling}
                    hasChangeScenarioStatus={hasChangeScenarioStatus}
                    bidPricingCalculation={this.props.bidPricingCalculation}
                    openUpdateScenarioDialog={this.openUpdateScenarioDialog}
                    locations={locations}
                    owner={this.props.people[x.profileId]}
                    ownerId={x.profileId}
                  />
                ))}
              </div>
            </div>
          </div>
          {this.state.updateScenario && (
            <ScenarioDialog
              scenario={this.state.updateScenario}
              close={this.closeScenarioDialog}
              onSubmit={this.updateScenario}
            />
          )}
        </KreoScrollbars>
      </ProjectLayout>
    );
  }

  @autobind
  private saveScrollRef(ref: HTMLDivElement): void {
    this.scrollMasterContainer = ref;
  }

  @autobind
  private saveContainerRef(ref: HTMLDivElement): void {
    this.tableContainer = ref;
  }

  @autobind
  private onScroll(): void {
    const shouldHaveFixedHeader = this.shouldHaveFixedHeader();
    if (this.state.fixedHeader !== shouldHaveFixedHeader) {
      this.setState({ fixedHeader: shouldHaveFixedHeader });
    }
  }

  @autobind
  private shouldHaveFixedHeader(): boolean {
    if (this.scrollMasterContainer && this.tableContainer) {
      return this.scrollMasterContainer.scrollTop >= this.tableContainer.offsetTop;
    }
  }

  @autobind
  private openUpdateScenarioDialog(scenario: ScenariosData.Scenario): void {
    this.setState({ updateScenario: scenario });
    this.props.openDialog(UPDATE_SCENARIO_DIALOG_NAME);
  }

  @autobind
  private closeScenarioDialog(): void {
    this.setState({ updateScenario: null });
    this.props.closeDialog(UPDATE_SCENARIO_DIALOG_NAME);
  }

  @autobind
  private updateScenario(data: ScenariosActionsType.UpdateScenario): void {
    if (this.state.updateScenario && data) {
      this.props.updateScenario({ ...data, scenarioId: this.state.updateScenario.id });
    }
    this.closeScenarioDialog();
  }

  @autobind
  private openDeleteAllScenarious(): void {
    this.props.openDialog(scenarioDeleteAllDialogName);
  }

  @autobind
  private deleteScenariousExceptActive(): void {
    this.props.deleteAllScenariousExceptActive(+this.props.match.params.projectId);
    this.props.closeDialog(scenarioDeleteAllDialogName);
  }

  @autobind
  private goToWorkpackages(scenario: ScenariosData.Scenario): void {
    this.props.setEditScenario(scenario);
    const workpackageUrl = AppUrls.plan.project.scenario.workpackages.url({
      projectId: this.props.match.params.projectId,
      scenarioId: scenario.id.toString(),
    });
    this.props.history.push(workpackageUrl);
  }

  @autobind
  private onResize(): void {
    const elements = document.getElementsByClassName('calculation-item__info-levelling');
    const showShortLevelling = Array.from(elements).some(
      x => x.getBoundingClientRect().width < x.children[0].getBoundingClientRect().width + 17,
    );
    if (this.state.showShortLevelling !== showShortLevelling) {
      this.setState({ showShortLevelling });
    }
  }

  @autobind
  private openDeleteScenarioDialog(scenario: ScenariosData.Scenario): void {
    this.setState({ scenarioToBeDeleted: scenario }, () =>
      this.props.openDialog(scenarioDeleteDialogName),
    );
  }

  @autobind
  private deleteScenario(): void {
    this.props.deleteScenario(this.state.scenarioToBeDeleted.id);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const currentProject = state.projects.currentProject;
  const people = currentProject ? currentProject.people : null;
  const userId = state.account.id;
  return {
    scenariosStatus: state.scenarios.statuses.scenarios,
    scenarios: state.scenarios.scenarios,
    scenariosToCopy: state.scenarios.scenariosToCopy,
    calculationsStatus: state.scenarios.statuses.calculations,
    isOwner: people && people.some(x => x.isAdmin && x.id === userId),
    bidPricingCalculation: state.projects.bidPricing.calculationId,
    locations: state.common.locations,
    people: state.people.usersmap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    openDialog: (dialogName): void => {
      dispatch(KreoDialogActions.openDialog(dialogName));
    },
    closeDialog: (dialogName): void => {
      dispatch(KreoDialogActions.closeDialog(dialogName));
    },
    getScenarios: (id: number) => {
      dispatch(ScenariosActions.getScenarios(id));
    },
    changeScenarioName: (id: number, name: string) => {
      dispatch(ScenariosActions.updateScenarioName({ id, name }));
    },
    changeCalculationName: (id: number, name: string, scenarioId: number) => {
      dispatch(ScenariosActions.updateCalculationName({ id, name }, scenarioId));
    },
    deleteScenario: (id: number) => {
      dispatch(ScenariosActions.deleteScenario(id));
    },
    copyScenario: (id: number) => {
      dispatch(ScenariosActions.copyScenario(id));
    },
    publishScenario: (id: number, value: boolean) => {
      dispatch(ScenariosActions.publishScenario({ id, value }));
    },
    getCalculations: (id: number) => {
      dispatch(ScenariosActions.getCalculations(id));
    },
    activateScenario: (scenarioId: number, calculationId: number) => {
      dispatch(
        ScenariosActions.setActiveScenarioRequest({
          scenarioId,
          calculationId,
        }),
      );
    },
    deleteCalculation: (calculationId, scenarioId) => {
      dispatch(ScenariosActions.removeCalculation({ calculationId, scenarioId }));
    },
    activateCalculation: (id: number, scenarioId: number) => {
      dispatch(ScenariosActions.setActiveCalculationRequest({ calculationId: id, scenarioId }));
    },
    setEditScenario: (scenario: ScenariosData.Scenario) => {
      dispatch(ScenariosActions.setEditScenario(scenario));
    },
    deleteAllScenariousExceptActive: (id: number) => {
      dispatch(ScenariosActions.deleteAllScenariosExceptActive(id));
    },
    updateScenario: (data: ScenariosActionsType.UpdateScenario) => {
      dispatch(ScenariosActions.updateScenario(data));
    },
    getLocations: () => {
      dispatch(CommonActions.getLocationsRequest());
    },
  };
};

export const ScenarioPage = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(withI18n(ScenarioPageComponent)),
);
