import { TreeTableValueGetterParams } from 'common/components/tree-table/interfaces';
import { QuantityTakeOffFormulaHelper } from './formula-helper';
import { PropertyHelper } from './property-helper';

export function getFunctionValue(columnId: string, params: TreeTableValueGetterParams): string {
  const { node: { data } } = params;
  try {
    const formula = PropertyHelper.getActualValue<string>(data.properties[columnId]);
    return QuantityTakeOffFormulaHelper.calculateFormula(
      formula.replace(QuantityTakeOffFormulaHelper.nonBreakingSpaceRegex, ''),
      params,
    );
  } catch (error) {
    return 'Invalid';
  }
}
