import { Icons, LinkComponent, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';

interface Props {
  selectedGroupName: string;
  onMoveTo: () => void;
}

const HeaderComponent: React.FC<Props> = ({
  selectedGroupName,
  onMoveTo,
}) => {
  return (
    <Styled.Container>
      <Styled.Title>
        <Icons.MoveToGroup_1/>
        <Text
          fontSize={14}
          withEllipsis={true}
        >
          {selectedGroupName || 'Move to'}
        </Text>
      </Styled.Title>
      <LinkComponent
        text="Move"
        onClick={onMoveTo}
        Icon={Icons.Right}
        mood={'secondary'}
        rightIcon={true}
      />
    </Styled.Container>
  );
};

export const Header = React.memo(HeaderComponent);
