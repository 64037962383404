import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconControlsSkipping: React.FC = () => {
  return (
    <svg
      width='32px'
      height='30px'
      viewBox='0 0 32 32'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='4D_controls_skipping' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='play' transform='translate(3.000000, 3.000000)' fillRule='nonzero'>
          <path
            // eslint-disable-next-line max-len
            d='M13,25 C19.627417,25 25,19.627417 25,13 C25,6.372583 19.627417,1 13,1 C6.372583,1 1,6.372583 1,13 C1,19.627417 6.372583,25 13,25 Z M13,26 C5.82029825,26 0,20.1797017 0,13 C0,5.82029825 5.82029825,0 13,0 C20.1797017,0 26,5.82029825 26,13 C26,20.1797017 20.1797017,26 13,26 Z'
            id='Oval'
            fill='#232836'
          />
          <g id='Group' transform='translate(8.000000, 9.000000)'>
            <path
              // eslint-disable-next-line max-len
              d='M10.7928932,4 L7.64644661,0.853553391 C7.45118446,0.658291245 7.45118446,0.341708755 7.64644661,0.146446609 C7.84170876,-0.0488155365 8.15829124,-0.0488155365 8.35355339,0.146446609 L11.8535534,3.64644661 C12.0488155,3.84170876 12.0488155,4.15829124 11.8535534,4.35355339 L8.35355339,7.85355339 C8.15829124,8.04881554 7.84170876,8.04881554 7.64644661,7.85355339 C7.45118446,7.65829124 7.45118446,7.34170876 7.64644661,7.14644661 L10.7928932,4 Z'
              id='Rectangle-7'
              fill={KreoColors.f4}
            />
            <path
              // eslint-disable-next-line max-len
              d='M6.79289322,4 L3.64644661,0.853553391 C3.45118446,0.658291245 3.45118446,0.341708755 3.64644661,0.146446609 C3.84170876,-0.0488155365 4.15829124,-0.0488155365 4.35355339,0.146446609 L7.85355339,3.64644661 C8.04881554,3.84170876 8.04881554,4.15829124 7.85355339,4.35355339 L4.35355339,7.85355339 C4.15829124,8.04881554 3.84170876,8.04881554 3.64644661,7.85355339 C3.45118446,7.65829124 3.45118446,7.34170876 3.64644661,7.14644661 L6.79289322,4 Z'
              id='Rectangle-7'
              fill={KreoColors.f3}
            />
            <path
              // eslint-disable-next-line max-len
              d='M2.79289322,4 L-0.353553391,0.853553391 C-0.548815536,0.658291245 -0.548815536,0.341708755 -0.353553391,0.146446609 C-0.158291245,-0.0488155365 0.158291245,-0.0488155365 0.353553391,0.146446609 L3.85355339,3.64644661 C4.04881554,3.84170876 4.04881554,4.15829124 3.85355339,4.35355339 L0.353553391,7.85355339 C0.158291245,8.04881554 -0.158291245,8.04881554 -0.353553391,7.85355339 C-0.548815536,7.65829124 -0.548815536,7.34170876 -0.353553391,7.14644661 L2.79289322,4 Z'
              id='Rectangle-7'
              fill={KreoColors.f2}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
