import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { CustomDialog } from '../custom-dialog';

export const CRASH_DIALOG = 'CRASH_DIALOG';

interface DispatchProps {
  closeDialog: () => void;
}

interface StateProps {
  message: string;
}

interface Props extends DispatchProps, StateProps {}

const title = 'Oops.. An unexpected error occurred';
const submitButtonText = 'Okay!';

const CrashDialogComponent = ({ message, closeDialog }: Props): JSX.Element => {
  useEffect(() => {
    return () => closeDialog();
  }, []);
  return (
    <DialogWrapper name={CRASH_DIALOG}>
      <CustomDialog
        mainText={title}
        onReject={closeDialog}
        secondaryText={message}
        rejectText={submitButtonText}
      />
    </DialogWrapper>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    message: state.dialog[CRASH_DIALOG],
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(CRASH_DIALOG)),
  };
};

export const CrashDialog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CrashDialogComponent);
