import autobind from 'autobind-decorator';
import * as React from 'react';

import './autocomplete.scss';

import { ConstantFunctions } from 'common/constants/functions';
import { MaterialInput } from 'common/UIKit';
import { MaterialComponentType } from 'common/UIKit/material/interfaces';
import { MultilevelSelectOptionData } from '../multi-level-drop-down-menu/interfaces/multi-level-select-option-data';
import { MultiLevelDropDown } from '../multi-level-drop-down-menu/multi-level-drop-down';
import { AutocompleteOption, AutocompleteOptionWithGroupName } from '.';

interface Props {
  value: string;
  options: AutocompleteOption[];
  onChange: (option: AutocompleteOption) => void;
  getCustomValuesFromName?: (inputValue: string) => AutocompleteOption[];
  getCustomValuesFromKey?: (key: string) => AutocompleteOption;
}

interface State {
  isOpen: boolean;
  tempValue: string;
  selectedItem: AutocompleteOptionWithGroupName;
  filteredOptions: AutocompleteOptionWithGroupName[];
  highlightedIndex: number;
  selectedIndex: number;
}

export class Autocomplete extends React.Component<Props, State> {
  private divRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (prevState.tempValue !== this.state.tempValue || prevProps.options !== this.props.options) {
      const filteredOptions = this.getFilteredOptions(this.state.tempValue);
      const highlightedOption = this.state.filteredOptions[this.state.highlightedIndex];
      const highlightedOptionIndex = filteredOptions.indexOf(highlightedOption);
      const highlightedIndex = highlightedOptionIndex !== -1
        ? highlightedOptionIndex
        : undefined;
      this.setState({ filteredOptions, highlightedIndex });
    }

    if (prevProps.value !== this.props.value) {
      this.setState(this.getInitialState(this.props));
    }
  }

  public render(): JSX.Element {
    const { filteredOptions, isOpen: isOpen, selectedItem } = this.state;
    const options: Array<MultilevelSelectOptionData<{}>> = filteredOptions;
    const value = selectedItem && selectedItem.value;

    return (
      <div
        className='autocomplete'
        onFocus={this.onFocus}
        ref={this.divRef}
        onKeyDown={this.onKeyDown}
      >
        <MaterialInput
          className='autocomplete__input'
          sizeSmall={true}
          displayedType={MaterialComponentType.Native}
          value={this.state.tempValue}
          onChange={this.onInputChange}
          onBlur={this.onBlur}
          onKeyPress={this.onKeyPress}
        />
        {
          (options && options.length && isOpen) ? (
            <MultiLevelDropDown
              options={options}
              value={value}
              parentRect={this.divRef.current && this.divRef.current.getBoundingClientRect()}
              displayModeSelect={true}
              onSelectClick={this.onSelectOption}
              onClose={ConstantFunctions.doNothing}
              onMouseDown={this.onMouseDown}
              highlightedIndex={this.state.highlightedIndex}
              optionContentRenderer={this.optionContentRenderer}
              getKey={this.getKey}
            />) : null
        }
      </div>
    );
  }


  private getInitialState(props: Props): State {
    const selectedItem = this.findOption(props.options, props.value)
      || props.getCustomValuesFromKey(props.value);
    const filteredOptions = this.getFilteredOptions(selectedItem && selectedItem.name);
    const selectedItemIndex = filteredOptions.indexOf(selectedItem);
    const selectedIndex = selectedItemIndex !== -1 ? selectedItemIndex : undefined;

    return {
      selectedItem,
      tempValue: selectedItem && selectedItem.name,
      filteredOptions,
      isOpen: false,
      highlightedIndex: selectedIndex,
      selectedIndex,
    };
  }

  @autobind
  private getKey(option: MultilevelSelectOptionData<{}>): string {
    return `${option.name}/${option.value}`;
  }

  private onMouseDown(event: React.MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
  }

  private optionContentRenderer(
    option: AutocompleteOptionWithGroupName,
  ): React.ReactNode {
    return (
      <div className='autocomplete__item'>
        {option.name}
        {option.groupName && (<span>{option.groupName}</span>)}
      </div>
    );
  }

  @autobind
  private onInputChange(_: React.ChangeEvent, newValue: string): void {
    this.setState({ tempValue: newValue });
  }

  @autobind
  private onBlur(_event: React.FocusEvent): void {
    const currentValue = this.state.selectedItem && this.state.selectedItem.name;
    this.setState({ isOpen: false, tempValue: currentValue });
  }

  @autobind
  private onKeyDown(event: React.KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        this.handelArrowKey('down');
        break;
      }
      case 'ArrowUp': {
        event.preventDefault();
        this.handelArrowKey('up');
        break;
      }
      default: {
        if (!this.state.isOpen) {
          this.setState({ isOpen: true });
        }
      }
    }
  }

  @autobind
  private onKeyPress(event: React.KeyboardEvent): void {
    switch (event.key) {
      case 'Enter': {
        const highlightedIndex = this.state.highlightedIndex || 0;
        const value = this.state.filteredOptions[highlightedIndex]
          ? this.state.filteredOptions[highlightedIndex].value
          : this.state.tempValue;
        this.onSelectOption(value, this.state.filteredOptions[highlightedIndex]);
        this.setState({ isOpen: false, highlightedIndex });
        break;
      }
      case 'Escape': {
        this.setState((s) => ({ isOpen: false, highlightedIndex: s.selectedIndex }));
        break;
      }
      default:
    }
  }

  private handelArrowKey(type: string): void {
    const nextIndex = this.getNextIndex(type);
    this.setState({ highlightedIndex: nextIndex });
  }

  private getNextIndex(type: string): number {
    const currentIndex = this.state.highlightedIndex;
    const filteredOptionsLastIndex = this.state.filteredOptions.length - 1;
    switch (type) {
      case 'down': {
        const startIndex = currentIndex === filteredOptionsLastIndex || currentIndex === undefined
          ? 0
          : currentIndex + 1;

        let index = startIndex;
        do {
          if (this.state.filteredOptions[index].isSelectable) {
            return index;
          }

          index = index === filteredOptionsLastIndex ? 0 : index + 1;
        } while (currentIndex !== index && (currentIndex || index));

        return undefined;
      }
      case 'up': {
        const startIndex = currentIndex === 0 || currentIndex === undefined
          ? filteredOptionsLastIndex
          : currentIndex - 1;

        let index = startIndex;
        do {
          if (this.state.filteredOptions[index].isSelectable) {
            return index;
          }

          index = index === 0 ? filteredOptionsLastIndex : index - 1;
        } while (startIndex !== index);

        return undefined;
      }
      default:
    }
  }

  @autobind
  private onFocus(): void {
    this.setState((s) => ({ isOpen: true, highlightedIndex: s.selectedIndex }));
  }

  @autobind
  private onSelectOption(_value: string, option: AutocompleteOption): void {
    this.setState({
      selectedItem: option,
      isOpen: false,
      tempValue: option && option.name,
    });
    this.props.onChange(option);
  }

  private getFilteredOptions(value: string): AutocompleteOptionWithGroupName[] {
    if (!value) {
      return this.props.options || [];
    }

    const filteredOptions = this.filterTreeOptionArray(this.props.options, value);

    if (!this.props.getCustomValuesFromName) {
      return filteredOptions;
    }

    const customOptions = this.props.getCustomValuesFromName(value);
    if (!filteredOptions || !filteredOptions.length) {
      return customOptions;
    }

    const filteredCustomOptions = customOptions.filter(x => !filteredOptions.find(option => option.name === x.name));

    return filteredCustomOptions.concat(filteredOptions);
  }

  private filterTreeOptionsComparer(option: AutocompleteOption, value: string): boolean {
    if (!value) {
      return false;
    }
    const lowerName = value.toLowerCase();
    return option.isSelectable && option.name && option.name.toLowerCase().includes(lowerName);
  }

  private getGroupName(prevGroup: string, group: string): string {
    return prevGroup
      ? `${prevGroup}/${group}`
      : group;
  }

  private filterTreeOptionArray(
    options: AutocompleteOption[], value: string, groupName?: string,
  ): AutocompleteOptionWithGroupName[] {
    if (!options) {
      return [];
    }

    return options
      .map(option => this.filterTreeOption(option, value, groupName))
      .reduce(
        (result, children) => {
          children.forEach(option => result.push(option));
          return result;
        },
        [],
      );
  }

  private filterTreeOption(
    option: AutocompleteOption, value: string, groupName?: string,
  ): AutocompleteOptionWithGroupName[] {
    const result = option && option.children
      ? this.filterTreeOptionArray(option.children, value, this.getGroupName(groupName, option.name))
      : [];

    if (this.filterTreeOptionsComparer(option, value)) {
      result.push({
        ...option,
        groupName,
      });
    }

    return result;
  }

  private findOption(options: AutocompleteOption[], value: string): AutocompleteOption {
    if (!options) {
      return null;
    }

    for (const option of options) {
      if (option.value === value) {
        return option;
      }

      if (option.children) {
        const result = this.findOption(option.children, value);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }
}
