import { LicenseManager } from 'ag-grid-enterprise';
import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { PdfHelper } from 'common/pdf/pdf-helper';
import { analyticEvent, MetricNames } from 'utils/posthog';
import { CommonApi } from '../../api/server/common-api';
import { store } from '../../store';
import { CrashPage } from '../../units/crash-page';
import { PageNotFound2D } from '../../units/page-not-found-2d';
import { CurrentCountryService } from './current-country-service';
import { DatadogLogger } from './datadog-logger';
import { EnvironmentConfiguration } from './environment-configuration-props';
import { ExceptionHandlingUtils } from './exception-handling-utils';

export const EnvironmentContext = React.createContext<EnvironmentConfiguration>(null);


interface StateProps {
  isProjectNotFound: boolean;
}

interface StateComponent {
  environmentConfiguration: EnvironmentConfiguration;
  isDevelopment: boolean;
  isError: boolean;
  isEnvConfigured: boolean;
}

interface Props extends StateProps {
  children?: React.ReactNode;
}

const developmentEnvironmentsNames = ['Development', 'Integration'];

class EnvironmentContextProviderComponent extends React.PureComponent<Props, StateComponent> {
  constructor(props: Props) {
    super(props);
    this.state = {
      environmentConfiguration: null,
      isDevelopment: false,
      isError: false,
      isEnvConfigured: false,
    };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo);
    if (this.state.environmentConfiguration && !this.state.isDevelopment) {
      ExceptionHandlingUtils.captureException(error, errorInfo);
    }
    analyticEvent(MetricNames.posthogPageDropped.crashPage, { ...this.tryGetUserInfo(), ...error });
    this.setState({ isError: true });
  }

  public componentDidMount(): void {
    this.fetchEnvironmentConfiguration();
  }

  public componentDidUpdate(_props: {}, prevState: StateComponent): void {
    const { environmentConfiguration, isDevelopment } = this.state;
    if (prevState.environmentConfiguration === null && environmentConfiguration !== null) {
      const {
        environmentName,
        sentryDsn,
        release,
        chargebeeSiteName,
        chargebeeApiKey,
        pdfTronLicenseKey,
        ipInfoLicenseKey,
        agGridLicenseKey,
      } = environmentConfiguration;
      if (!isDevelopment) {
        ExceptionHandlingUtils.initSentryAndOverrideConsoleError(
          sentryDsn,
          release,
          environmentName,
        );
      }
      if (chargebeeSiteName) {
        Chargebee.init({
          site: chargebeeSiteName,
          publishableKey: chargebeeApiKey,
          enableRedirectMode: false,
          iframeOnly: true,
        });
      }

      if (environmentName === 'Production') {
        LicenseManager.setLicenseKey(agGridLicenseKey);
      }
      CurrentCountryService.init(ipInfoLicenseKey);

      DatadogLogger.init({
        /**
         * XHR ошибки могут вызывать exception серелизации в JSON
         * а логер жрет XHR ошибки еще до наших обработчиков - если не отключить эту опцию
         */
        forwardErrorsToLogs: false,
        env: isDevelopment ? 'dev' : environmentConfiguration.environmentName,
        clientToken: environmentConfiguration.dataDogClientToken,
        service: 'front',
        version: environmentConfiguration.release,
      });
      PdfHelper.initializeClient(pdfTronLicenseKey);
      this.setState({ isEnvConfigured: true });
    }
  }

  public render(): React.ReactNode {
    const { isEnvConfigured, isError } = this.state;
    if (this.props.isProjectNotFound) {
      this.setState({ isError: false });
      return <PageNotFound2D />;
    }

    return (
      <EnvironmentContext.Provider value={this.state.environmentConfiguration}>
        {isError ? <CrashPage /> : isEnvConfigured ? this.props.children : null}
      </EnvironmentContext.Provider>
    );
  }

  private fetchEnvironmentConfiguration(): void {
    CommonApi.get<EnvironmentConfiguration>(
      '/common/environment-configuration',
      'Error while fetching environment configuration',
    ).then(environmentConfiguration => {
      const isDevelopment = developmentEnvironmentsNames.includes(environmentConfiguration.environmentName) ||
        process.env.NODE_ENV === 'development';
      this.setState({ environmentConfiguration, isDevelopment });
    });
  }

  private tryGetUserInfo(): { email: string, companyName: string } {
    const state = store.getState();
    return {
      email: state?.account?.email,
      companyName: state?.account?.selectedCompany?.name,
    };
  }
}


const mapStateToProps = (state: State): StateProps => {
  return {
    isProjectNotFound: state.projects?.isProjectNotFound,
  };
};

export const EnvironmentContextProvider = connect(mapStateToProps)(EnvironmentContextProviderComponent);
