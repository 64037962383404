import { SagaIterator } from 'redux-saga';
import { call, takeLatest } from 'redux-saga/effects';
import { ActionWith } from 'common/interfaces/action-with';
import { DumpProjectParams } from '../actions/payloads/projects';
import { AdminProjectsActionTypes } from '../actions/types/projects';
import { AdminProjectsApi } from '../api/projects';

function* download({ payload }: ActionWith<DumpProjectParams>): SagaIterator {
  try {
    yield call(AdminProjectsApi.dump2dProject, payload.projectId, payload.quality);
  } catch (error) {
    console.error('admin projects: dump project failed', payload.projectId, error);
  }
}

export function* adminProjectsSaga(): SagaIterator {
  yield takeLatest(AdminProjectsActionTypes.DOWNLOAD, download);
}
