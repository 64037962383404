import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconPeopleEmpty: React.FC = (_props) => {
  return (
    <svg width='60px' height='60px' viewBox='0 0 60 60' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='icon_people_empty' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <path
              d='M30.6259462,28.7194596 C27.448687,30.4880818 23.5513129,30.4880818 20.3740538,28.7194596
              L18,30.5 C12.9638831,34.2770876 10,40.2048539 10,46.5 C10,47.0522847 9.55228475,47.5 9,47.5
              C8.44771525,47.5 8,47.0522847 8,46.5 C8,39.5753393 11.2602715,33.0547964 16.8,28.9
              L18.6459246,27.5155566 C18.4395213,27.3386765 18.238333,27.1525232 18.0429064,26.9570965
              C13.9244672,22.8386566 13.9244672,16.161347 18.0429064,12.042907 C22.1613469,7.92446734
              28.8386553,7.92446796 32.9570936,12.042907 C34.0105355,13.0963491 34.81317,14.337415
              35.3362034,15.6896828 C38.3773055,14.4593806 41.9913142,15.0771212 44.4570966,17.542904
              C47.7471103,20.8329184 47.74711,26.1670847 44.4570954,29.4570999 C44.2573672,29.6568281
              44.0501055,29.8444313 43.8362249,30.0199095 C49.5258192,33.5534899 53,39.7821616 53,46.5
              C53,47.0522847 52.5522847,47.5 52,47.5 C51.4477153,47.5 51,47.0522847 51,46.5 C51,40.3461303
              47.7495718,34.6499227 42.4515421,31.5192688 L41.9166214,31.2031792 C40.4950995,31.8332962
              38.9349044,32.050642 37.4177176,31.8552165 C40.9797661,35.8485063 43,41.0490469 43,46.5
              C43,47.0522847 42.5522847,47.5 42,47.5 C41.4477153,47.5 41,47.0522847 41,46.5 C41,40.2048539
              38.0361169,34.2770876 33,30.5 L30.6259462,28.7194596 Z M33.0341865,26.8792135
              C33.2904571,27.2929333 33.5981007,27.6838688 33.9571171,28.0428854 C36.4660816,30.5518503
              40.5339171,30.5518507 43.0428819,28.0428863 C45.5518479,25.5339198 45.5518482,21.4660832
              43.0428829,18.9571174 C41.1061345,17.0203687 38.240489,16.5786568 35.8800359,17.631982
              C36.3699616,20.3654084 35.7693124,23.2032855 34.1704295,25.506238 C33.8330175,25.9922295
              33.4535211,26.4512361 33.0341865,26.8792135 Z M19.4571201,25.5428831 C22.7945099,28.8802735
              28.2054884,28.880274 31.5428787,25.5428844 C31.9085949,25.177168 32.2374311,24.7835203
              32.5275593,24.3656339 C33.872165,22.42893 34.3452907,20.0217397
              33.8610649,17.7267531C33.5222215,16.120806 32.7299162,14.6441569 31.5428799,13.4571205
              C28.2054901,10.11973 22.7945116,10.1197295 19.4571212,13.4571193 C16.1197293,16.7945118
              16.1197293,22.2054917 19.4571201,25.5428831 Z'
              id='Combined-Shape'
              fill={KreoColors.f4}
              fillRule='nonzero'
            />
        </g>
    </svg>
  );
};
