import * as React from 'react';

import { FeatureOptions } from '../../constants';
import { InfoBlock } from './info-block';
import { Styled } from './styled';


export const UpgradePlanDialogInfo: React.FC<{ isBillingPeriod: boolean }> = ({ isBillingPeriod }) => {
  return (
    <>
      <Styled.BorderLine />
      <Styled.Container isBillingPeriod={isBillingPeriod}>
        {
          FeatureOptions.map((el) => {
            return (
              <InfoBlock
                key={el.name}
                name={el.name}
                value={el.value}
              />
            );
          })
        }
      </Styled.Container>
    </>
  );
};
