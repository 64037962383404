import { Text, TabsContainerWithScroll } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { TwoDActions } from '../../actions/creators';
import { Styled } from './styled';
import { TraceLinkCell } from './trace-link-cell';


interface StateToProps {
  traceLink: string;
  pageIdToNameMap: Record<string, string>;
}

interface DispatchToProps {
  hideTraceLink: () => void;
}

interface OwnProps {
  getTraceLinks: (id: string) => string[];
  onCellClick: (sheetId: string, cellId: string) => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class TraceLinkComponent extends React.PureComponent<Props> {
  public componentDidMount(): void {
    window.addEventListener('click', this.hideTraceLink);
  }

  public UNSAFE_componentWillMount(): void {
    window.removeEventListener('click', this.hideTraceLink);
  }

  public componentWillUnmount(): void {
    window.onclick = null;
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.traceLink && !this.isTraceLinkVisible()) {
      this.props.hideTraceLink();
    }
  }

  public render(): JSX.Element {
    const { traceLink, getTraceLinks } = this.props;
    const cells = traceLink && getTraceLinks(traceLink);
    if (!this.isTraceLinkVisible() || cells.length === 0) {
      return null;
    }

    return (
      <Styled.Container
        onBlur={this.hideTraceLink}
        onClick={this.stopPropagation}
      >
        <Styled.Counter>{cells.length}</Styled.Counter>
        <Text fontSize={12}>Trace Links</Text>
        <Styled.LinksContainer>
          <TabsContainerWithScroll dragDirection='vertical'>
            {cells.sort((a, b) => arrayUtils.localCompareWithNumber(a, b)).map(this.renderChild)}
          </TabsContainerWithScroll>
        </Styled.LinksContainer>
      </Styled.Container>);
  }

  @autobind
  private hideTraceLink(): void {
    if (this.isTraceLinkVisible()) {
      this.props.hideTraceLink();
    }
  }

  @autobind
  private isTraceLinkVisible(): boolean {
    const { traceLink, getTraceLinks } = this.props;
    const cells = traceLink && getTraceLinks(traceLink);
    return !!(cells && cells.length);
  }

  @autobind
  private stopPropagation(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }

  @autobind
  private renderChild(cell: string): JSX.Element {
    const [, sheetId, cellId] = (/(.+)!(.+)/).exec(cell);
    return (
      <TraceLinkCell
        key={cell}
        onClick={this.props.onCellClick}
        sheetId={sheetId}
        sheetName={this.props.pageIdToNameMap[sheetId]}
        cellId={cellId}
        isSelected={false}
      />
    );
  }
}

function mapStateToProps(state: State): StateToProps {
  const twoD = state.twoD;
  const pageIdToNameMap = {};
  twoD.reportPages.forEach(p => {
    pageIdToNameMap[p.id] = p.name;
  });
  return {
    traceLink: twoD.traceLink,
    pageIdToNameMap,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchToProps {
  return {
    hideTraceLink: () => dispatch(TwoDActions.hideTraceLink()),
  };
}

export const TraceLink = connect(mapStateToProps, mapDispatchToProps)(TraceLinkComponent);
