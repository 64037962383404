export function _formatter(
  val: string,
  fix: number,
  prefix: string = '',
  postfix: string = '',
): string {
  // eslint-disable-next-line eqeqeq
  if (val == 'null' || val == 'undefined') {
    return '';
  }

  const num = Number.parseFloat(val);
  if (num) {
    const fixedNum = num.toFixed(fix);
    return `${prefix}${fixedNum}${postfix}`;
  }

  return val;
}

export function _dayRounding(val: string): string {
  const num = val.split('.')[0];
  return num;
}

export function _onlyObjects(item: any): any {
  return item.get('object') !== 0;
}

export function _onlySections(item: any): any {
  return item.get('section') !== 0 && _onlyObjects(item);
}

export function _onlySubGroups(item: any): any {
  return item.get('subGroup') !== 0 && item.get('section') === 0;
}

export function _onlyGroups(item: any): any {
  return item.get('subGroup') === 0;
}

export const containsAllWordsFromTerm = (text: string, term: string): boolean => {
  const words = term.toLowerCase().split(' ');
  const lowercasedText = text.toLowerCase();
  return words.every((word) => lowercasedText.includes(word));
};

export function containsTermInText(object: UniClassTreeItem, term: string, onlyApproved: boolean): boolean {
  const words = term.toLowerCase().split(' ');
  const lowerCasedCode = object.code ? object.code.toLowerCase() : null;
  const lowerCasedTitle = object.title ? object.title.toLowerCase() : null;
  return ((lowerCasedCode && words.every((word) => lowerCasedCode.includes(word))) ||
    (lowerCasedTitle && words.every((word) => lowerCasedTitle.includes(word)))) &&
    (!onlyApproved || object.isApproved);
}

interface UniClassTreeItem {
  title: string;
  code: string;
  isApproved: boolean;
  groupName: number;
  subGroup: number;
  section: number;
  object: number;
  id: number;
}

export function _filterUniclass(data: any[], term: string, onlyApproved: boolean): any {
  if (!term) {
    return data;
  }

  const jsData: UniClassTreeItem[] = data.map((item) => item.toJS());

  const leaves = jsData.filter((item) => item.object !== 0);
  const categories = jsData.filter((item) => item.object === 0);
  const filteredLeaves = leaves.filter((object) => containsTermInText(object, term, onlyApproved));
  const filteredCategories = categories.filter((category) => {
    const categoryCode = category.code;
    return !!filteredLeaves.find((leaf) =>
      leaf.code.startsWith(categoryCode)) || containsTermInText(category, term, onlyApproved);
  });

  return filteredLeaves.concat(filteredCategories);
}


export function _filterForChildren(data: any, children: any): any {
  const ret = data.filter((x: any): any =>
    children.find((a: any): any => a.code.includes(x.get('code'))) !== undefined);
  return ret;
}
