import { CheckboxButton, Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { Text } from 'common/components/text';
import { ElementTutorialTooltip } from 'common/components/tooltip';
import { Styled } from './styled';

export interface SettingItemTutorial {
  link: string;
  animation: string;
  description: string;
}

interface Props {
  label: string;
  checked: boolean;
  disabled: boolean;
  tutorial: SettingItemTutorial;
  onChange: (checked: boolean) => void;
}

const SettingItemComponent: React.FC<Props> = ({
  label,
  checked,
  disabled,
  onChange,
  tutorial: { link: tutorialLink, animation: tutorialAnimation, description },
}) => {
  const onChangeCallback = useCallback(() => {
    onChange(!checked);
  }, [onChange, checked]);
  return (
    <Styled.Container onClick={disabled ? undefined : onChangeCallback} isDisabled={disabled}>
      <CheckboxButton checked={checked} />
      <Text
        fontSize={14}
        fontWeight={500}
        lineHeight={16}
        letterSpacing={0}
      >
        {label}
      </Text>
      <Styled.Info>
        <ElementTutorialTooltip
          position='left'
          disabled={disabled}
          tutorialLink={tutorialLink}
          description={description}
          animation={tutorialAnimation}
        >
          <Icons.Questions2D />
        </ElementTutorialTooltip>
      </Styled.Info>
    </Styled.Container>
  );
};

export const SettingItem = React.memo(SettingItemComponent);
