import autobind from 'autobind-decorator';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { ProjectStatus } from 'common/enums/project-status';
import { RequestStatus } from 'common/enums/request-status';
import { State as ReduxState } from 'common/interfaces/state';
import { KreoDialogActions, KreoScrollbars } from 'common/UIKit';
import { MenuItem, TabNav } from '../../../../components/tab-nav';
import { PlanProjectRouteParams } from '../../../../routes/app-routes-params';
import { ProjectLayout } from '../../../project-dashbord';
import { BidPricingActions } from '../../actions/creators/bid-pricing';
import { GetPackageCost } from '../../actions/payloads/bid-pricing';
import { Invitations } from '../../components/bid-pricing/invitations/invitations';
import {
  ManagePeopleOnWorkPackageDialog,
  ManagePeopleOnWorkPackageDialogName,
} from '../../components/manage-people-on-workpackage-dialog';
import { BidPricingPageTab } from '../../enums/bid-pricing-page-tab';
import { BidPricingStatuses } from '../../interfaces/bid-pricing/bid-pricing-redux-state';
import { BidPricingUserPackage } from '../../interfaces/bid-pricing/bid-pricing-user-package';
import { BidPricingWorkPackage } from '../../interfaces/bid-pricing/bid-pricing-work-package';

interface ReduxProps {
  bidPricingPackages: BidPricingWorkPackage[];
  userPackages: BidPricingUserPackage[];
  calculationId?: number;
  isBidPricingActive: boolean;
  statuses: BidPricingStatuses;
  canReadInvitationTab: boolean;
  canReadWorkPackagesTab: boolean;
}

interface ReduxActions {
  getSubcontractorPackages: (id: number) => void;
  getBidPricingInfo: (id: number) => void;
  getPackageInfo: (data: GetPackageCost) => void;
  onWin: (userId: string, workPackageId: number, constructionCostId: number) => void;
  onCancelRequest: (email: string, workPackageId: number) => void;
  onDeleteSubcontractorBid: (constructionCostId: number, workPackageId: number) => void;
  openDialog: () => void;
  addNewBid: (workpackageId: number) => void;
}

interface Props
  extends ReduxActions,
    ReduxProps,
    RouteComponentProps<PlanProjectRouteParams>,
    AbilityAwareProps {}

interface ComponentState {
  page: BidPricingPageTab;
  workPackageToInvite: BidPricingWorkPackage;
  fixedHeader: boolean;
}

class BidPricingComponent extends Component<Props, ComponentState> {
  private scrollMasterContainer: HTMLDivElement;
  private tableContainer: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      page: props.canReadInvitationTab
        ? BidPricingPageTab.Invitation
        : BidPricingPageTab.WorkPackages,
      workPackageToInvite: null,
      fixedHeader: false,
    };
  }

  public componentDidMount(): void {
    if (this.props.calculationId) {
      this.loadBidPricingInfo();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.calculationId && prevProps.calculationId !== this.props.calculationId) {
      this.loadBidPricingInfo();
    }
  }

  public render(): React.ReactNode {
    const { match, calculationId, canReadInvitationTab, canReadWorkPackagesTab } = this.props;
    return (
      <ProjectLayout waitingForAdditionalInfo={!calculationId} projectId={match.params.projectId}>
        <KreoScrollbars onSendRef={this.saveScrollRef} onScroll={this.onScroll}>
          <ManagePeopleOnWorkPackageDialog workpackage={this.state.workPackageToInvite} />
          <TabNav onChangePage={this.onChangePage} page={this.state.page}>
            {canReadInvitationTab && (
              <MenuItem page={BidPricingPageTab.Invitation} action={this.getBidPricing}>
                Invitation
              </MenuItem>
            )}
            {canReadWorkPackagesTab && (
              <MenuItem
                page={BidPricingPageTab.WorkPackages}
                action={this.getSubcontractorPackages}
              >
                Work Packages
              </MenuItem>
            )}
          </TabNav>
          <div
            className='bid-pricing-invitations__wrap'
            ref={this.saveContainerRef}
          >
            {this.renderPageContent()}
          </div>
        </KreoScrollbars>
      </ProjectLayout>
    );
  }

  @autobind
  private onChangePage(page: BidPricingPageTab): void {
    if (this.state.page !== page) {
      this.setState({ page });
    }
  }

  @autobind
  private getSubcontractorPackages(): void {
    this.props.getSubcontractorPackages(this.props.calculationId);
  }

  @autobind
  private getBidPricing(): void {
    this.props.getBidPricingInfo(this.props.calculationId);
  }

  private loadBidPricingInfo(): void {
    if (this.props.canReadInvitationTab) {
      this.getBidPricing();
    } else if (this.props.canReadWorkPackagesTab) {
      this.getSubcontractorPackages();
    }
  }

  @autobind
  private saveScrollRef(ref: HTMLDivElement): void {
    this.scrollMasterContainer = ref;
  }

  @autobind
  private saveContainerRef(ref: HTMLDivElement): void {
    this.tableContainer = ref;
  }

  @autobind
  private onScroll(): void {
    const shouldHaveFixedHeader = this.shouldHaveFixedHeader();
    if (this.state.fixedHeader !== shouldHaveFixedHeader) {
      this.setState({ fixedHeader: shouldHaveFixedHeader });
    }
  }

  private shouldHaveFixedHeader(): boolean {
    if (this.scrollMasterContainer && this.tableContainer) {
      return this.scrollMasterContainer.scrollTop >= this.tableContainer.offsetTop;
    }
  }

  private renderPageContent(): React.ReactNode {
    const { statuses, isBidPricingActive, bidPricingPackages, userPackages } = this.props;

    switch (this.state.page) {
      case BidPricingPageTab.Invitation:
        if (statuses.packages === RequestStatus.Loaded) {
          return (
            <Invitations
              fixedHeader={this.state.fixedHeader}
              isBidPricingActive={isBidPricingActive}
              bidPricingPackages={bidPricingPackages}
              goToPackage={this.props.getPackageInfo}
              onWin={this.props.onWin}
              isSubcontractor={false}
              onCancelRequest={this.props.onCancelRequest}
              onDeleteSubcontractorBid={this.props.onDeleteSubcontractorBid}
              openWorkpackageDialog={this.openWorkpackageDialog}
            />
          );
        } else {
          return <SvgSpinner size='large' />;
        }
      case BidPricingPageTab.WorkPackages:
        return statuses.userPackages === RequestStatus.Loaded ? (
          <Invitations
            fixedHeader={this.state.fixedHeader}
            isBidPricingActive={isBidPricingActive}
            userPackages={userPackages}
            isSubcontractor={true}
            goToPackage={this.props.getPackageInfo}
            openWorkpackageDialog={this.openWorkpackageDialog}
            addNewBid={this.props.addNewBid}
          />
        ) : (
          <SvgSpinner size='large' />
        );
      default:
        return null;
    }
  }

  @autobind
  private openWorkpackageDialog(workPackage: BidPricingWorkPackage): void {
    if (this.props.isBidPricingActive) {
      this.setState({ workPackageToInvite: workPackage });
      this.props.openDialog();
    }
  }
}

function mapStateToProps(state: ReduxState, { ability }: Props): ReduxProps {
  const currentProjectStatus = state.projects.currentProject ? state.projects.currentProject.status : null;
  const canReadInvitationTab = ability.can(Operation.Read, Subject.BidPricingInvitation);
  const canReadWorkPackagesTab = ability.can(Operation.Read, Subject.BidPricingWorkPackages);

  return {
    isBidPricingActive: currentProjectStatus === ProjectStatus.OnBidPricing,
    calculationId: state.scenarios.active_calculation
      ? state.scenarios.active_calculation.id
      : null,
    userPackages: state.bidPricing.userPackages,
    bidPricingPackages: state.bidPricing.packages,
    statuses: state.bidPricing.statuses,
    canReadInvitationTab,
    canReadWorkPackagesTab,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): ReduxActions {
  return {
    getSubcontractorPackages: (calculationId: number) => {
      dispatch(BidPricingActions.getSubcontractorPackages(calculationId));
    },
    getBidPricingInfo: (id: number) => {
      dispatch(BidPricingActions.getBidPricingInfo(id));
    },
    getPackageInfo: (data: GetPackageCost) => {
      dispatch(BidPricingActions.setSubcontractorPackage(data));
    },
    onWin: (userId: string, workPackageId: number, constructionCostId: number) => {
      dispatch(BidPricingActions.setWinner(userId, workPackageId, constructionCostId));
    },
    onCancelRequest: (email: string, workPackageId: number) => {
      dispatch(BidPricingActions.cancelRequest({ email, workPackageId }));
    },
    onDeleteSubcontractorBid: (constructionCostId: number, workPackageId: number) => {
      dispatch(BidPricingActions.removeSubcontractorBid({ constructionCostId, workPackageId }));
    },
    openDialog: () => {
      dispatch(KreoDialogActions.openDialog(ManagePeopleOnWorkPackageDialogName));
    },
    addNewBid: (workpackageId: number) => {
      dispatch(BidPricingActions.createNewBid(workpackageId));
    },
  };
}

export const BidPricingPage = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(BidPricingComponent),
);
