import { Constants } from 'unit-2d-database/components/side-panel/components/property-panel/forms/tree-form/constants';
import { PiaBreakdownNode } from 'unit-2d-database/interfaces';

export const getCodeById = (nodes: PiaBreakdownNode[], id: string, code?: string): string => {
  if (!nodes) {
    return null;
  }
  let count = 0;
  for (const node of  nodes) {
    count = node.code
      ? node.code
      : count + 1;
    const currentCode = code
      ? `${code}${Constants.codeSeparator}${count}`
      : count.toString();

    if (node.id === id) {
      return currentCode;
    }
    const childCode = getCodeById(node.children, id, currentCode);
    if (childCode) {
      return childCode;
    }
  }

  return null;
};
