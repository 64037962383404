import { useContext } from 'react';
import { UndoRedoContext } from '../undo-redo-context';
import { UndoRedoFullApi } from '../undo-redo-context-props';
import { useUndoRedoState } from './use-undo-redo-state';

export function useUndoRedoFullApi(): UndoRedoFullApi {
  const state = useUndoRedoState();
  const api = useContext(UndoRedoContext);
  return { ...state, ...api };
}
