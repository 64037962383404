export enum PageFilters {
  Measurements = 'measurements',
}

export enum MeasurementsFilterValue {
  All = 'all',
  With = 'with',
  Without = 'without',
}

export const filtersSet = [
  {
    [PageFilters.Measurements]: {
      name: 'BY MEASUREMENTS',
      elements: [
        { name: 'All pages', type: MeasurementsFilterValue.All },
        { name: 'With measurements', type: MeasurementsFilterValue.With },
        { name: 'Without measurements', type: MeasurementsFilterValue.Without },
      ],
    },
  },
];
