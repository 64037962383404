import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { AnalyticsActionTypes } from '../actions/types';
import { AnalyticsState } from '../interfaces/redux-state';

export const INITIAL_STATE: AnalyticsState = {
  chatOpen: false,
};

export const analyticsReducerMethods: ReducerMethods<AnalyticsState> = {
  [AnalyticsActionTypes.SET_CHAT_OPEN]: (state, payload) => {
    const helper = new MonoliteHelper(state);
    return helper.set(_ => _.chatOpen, payload).get();
  },
};
