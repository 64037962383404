import { ICellEditorParams } from 'ag-grid-community';

export interface Editors<TDefaultCellEditor, TFormulaCellEditor> {
  defaultCellEditor: TDefaultCellEditor;
  formulaCellEditor: TFormulaCellEditor;
}

export abstract class TreeTableCellEditorCreator<
  TDefaultCellEditor,
  TFormulaCellEditor,
  > {
  public abstract getCellEditors(
    params: ICellEditorParams,
    eGui: HTMLDivElement,
  ): Editors<TDefaultCellEditor, TFormulaCellEditor>;
}
