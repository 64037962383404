import styled, { keyframes } from 'styled-components';

const ShakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-6px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(6px, 0, 0);
  }
`;

const Message = styled.div`
  position: absolute;
  z-index: 1000;
  right: 10px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  max-width: 176px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid ${p => p.theme.color.red};
  background-color: ${p => p.theme.color.backgroundRush};
  animation: ${ShakeAnimation} 0.75s 0s 3 ease both;

  > p {
    text-align: center;
  }

  button {
    width: 100%;
  }

  button:last-child {
    > p {
      color: ${p => p.theme.color.mainFont};
    }
  }
`;

export const Styled = {
  Message,
};
