import { Constants } from 'common/components/data-base-table/constants';

export interface Element {
  id: string;
  parentFolderId?: string;
}

export const calcPath = <T extends Element>(
  elements: T[],
  pathSelector: (element: T) => string,
): Record<string, string> => {
  const map = {};
  const dependenciesMap: Record<string, T[]> = {};

  const updateMap = (element: T): void => {
    const path = !element.parentFolderId
      ? `${pathSelector(element)}`
      : `${map[element.parentFolderId]}${Constants.DATA_PATH_SPLITER}${pathSelector(element)}`;
    map[element.id] = path;
    const dependencies = dependenciesMap[element.id];
    if (dependencies) {
      dependencies.forEach(d => updateMap(d));
      delete dependenciesMap[element.id];
    }
  };

  elements.forEach(element => {
    const canUpdateMap = !element.parentFolderId || !!map[element.parentFolderId];

    if (canUpdateMap) {
      updateMap(element);
    } else {
      if (dependenciesMap[element.parentFolderId]) {
        dependenciesMap[element.parentFolderId].push(element);
      } else {
        dependenciesMap[element.parentFolderId] = [element];
      }
    }
  });

  return map;
};
