import styled from 'styled-components';
import { PreviewItemStyled } from '../preview-item';


const Container = styled.div`
  display: flex;
  justify-content: center;
  ${PreviewItemStyled.Container} + ${PreviewItemStyled.Container} {
    margin-left: 20px;
  }
`;

export const Styled = {
  Container,
};
