import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import './database-resource-material.scss';

import { State as ReduxState } from 'common/interfaces/state';
import {
  MaterialInputField,
  MaterialInputProps,
  MaterialMenuItem,
  MaterialSelectField,
  MaterialSelectProps,
} from 'common/UIKit';
import { FieldRequired } from '../../../../components/dialog/validators';
import { UnitModel } from '../../interfaces/data';
import { MaterialModel } from '../../interfaces/resources-data';
import { MaterialUnitHelper } from './material-unit-helper';

const unitRequired = FieldRequired('Unit');

interface ReduxProps {
  units: UnitModel[];
  unitMap: Record<number, UnitModel>;
  measurementToUnits: Record<string, UnitModel[]>;
}

interface Props extends ReduxProps {
  readonly: boolean;
  resource: MaterialModel;
  onChange: (root: MaterialModel) => void;
}

class DatabaseResourceMaterialComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const materialUnitValidator = MaterialUnitHelper.getMaterialUnitInAvailableMeasurementValidator(this.props.unitMap);

    return (
      <div className='database-resource-material'>
        <div className='database-resource-material__name'>
          <Field<MaterialInputProps>
            name='name'
            component={MaterialInputField}
            label='Name'
            placeholder='Name'
            multiLine={true}
            value={this.props.resource.name}
            onChange={this.onNameChange}
            onBlur={this.onNameBlur}
            displayBottomInformation={true}
            disabled={this.props.readonly}
          />
        </div>
        <div className='database-resource-material__default-unit'>
          <Field<MaterialSelectProps>
            name='defaultUnitId'
            component={MaterialSelectField}
            value={this.props.resource.defaultUnitId}
            placeholder='Select Unit'
            label='Default Unit'
            onChange={this.onUnitChange}
            disabled={this.props.readonly}
            displayBottomInformation={true}
            validate={[unitRequired, materialUnitValidator]}
          >
            {this.getUnitVariants()}
          </Field>
        </div>
      </div>
    );
  }

  @autobind
  private onNameChange(_: React.ChangeEvent, name: string): void {
    this.props.onChange({
      ...this.props.resource,
      name,
    });
  }

  @autobind
  private onNameBlur(_: React.ChangeEvent, value: string): void {
    const name = value.trim();
    this.props.onChange({
      ...this.props.resource,
      name,
    });
  }

  @autobind
  private onUnitChange(_: React.SyntheticEvent, defaultUnitId: number): void {
    this.props.onChange({
      ...this.props.resource,
      defaultUnitId,
    });
  }

  private getUnitVariants(): JSX.Element[] {
    const { units, unitMap, measurementToUnits, resource } = this.props;
    const defaultUnit = unitMap[resource.defaultUnitId];
    const measuremntUnits = this.props.resource.variants.length
      ? measurementToUnits[defaultUnit.measurement]
      : units;

    return measuremntUnits
      .filter(MaterialUnitHelper.isMaterialUnitAvailable)
      .sort((a, b) => a.acronym.toLowerCase().localeCompare(b.acronym.toLowerCase()))
      .map(x => (<MaterialMenuItem key={x.id} value={x.id}>{x.acronym}</MaterialMenuItem>));
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    units: state.database.currentDatabase.units,
    unitMap: state.database.currentDatabase.unitMap,
    measurementToUnits: state.database.currentDatabase.measurementToUnits,
  };
};

const connector = connect(mapStateToProps);

export const DatabaseResourceMaterial = connector(DatabaseResourceMaterialComponent);
