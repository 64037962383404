import { Constants, DropdownInput, HotKeys, ModalWrapper, RectangleButton, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { UserAbility } from 'common/ability/user-ability';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SubscriptionType } from 'common/constants/subscription';
import { EnvironmentConfigurationProps } from 'common/environment/environment-configuration-props';
import { withEnvironmentConfiguration } from 'common/environment/with-environment-configuration';
import { State } from 'common/interfaces/state';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { Company } from '../../account/interfaces/company';
import { Styled } from './styled';

interface StateProps {
  ownCompanyId: number;
  companies: Company[];
  userId: string;
  userEmail: string;
  isAdmin: boolean;
}

interface Props extends StateProps, EnvironmentConfigurationProps, AbilityAwareProps {
  projectId: number;
  onDuplicateProject: (companyId: number) => void;
  onClose: () => void;
}

interface OwnState {
  selectedCompanyIndex: number;
  availableToDuplicateCompanies: Company[];
}

export const PROJECT_DUPLICATE_DIALOG = 'project-duplicate-dialog';

export class TwoDProjectDuplicateDialogComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedCompanyIndex: 0,
      availableToDuplicateCompanies: [],
    };
  }

  public componentDidMount(): void {
    this.getAvailableToDuplicateCompanies();
  }

  public render(): React.ReactNode {
    const availableCompanyNames = this.state.availableToDuplicateCompanies.map(company => company.name);

    return (
      <DialogWrapper name={PROJECT_DUPLICATE_DIALOG}>
        <ModalWrapper onExit={this.props.onClose}>
          <HotKeys
            keyMap={Constants.KeyMaps.ENTER_ESCAPE}
            handlers={{ escape: this.props.onClose, enter: this.onSubmit }}
            autofocus={true}
          >
            <Styled.Container>
              <Text>Duplicate project</Text>
              <DropdownInput
                header={'Company'}
                elements={availableCompanyNames}
                activeElementIndex={this.state.selectedCompanyIndex}
                onClick={this.onSelectCompany}
                closeAfterClick={true}
              />
              <RectangleButton
                mood='secondary'
                text='Duplicate'
                width={165}
                height={60}
                onClick={this.onSubmit}
              />
            </Styled.Container>
          </HotKeys>
        </ModalWrapper>
      </DialogWrapper>
    );
  }

  @autobind
  private onSelectCompany(index: number): void {
    this.setState({ selectedCompanyIndex: index });
  }

  @autobind
  private onSubmit(): void {
    const { availableToDuplicateCompanies, selectedCompanyIndex } = this.state;
    this.props.onDuplicateProject(availableToDuplicateCompanies[selectedCompanyIndex].id);
  }

  @autobind
  private getAvailableToDuplicateCompanies(): void {
    const availableToDuplicateCompanies = this.props.companies
      .filter(company => {
        const ability = this.getCompanyUserAbility(company);
        if (ability === null) {
          return false;
        }
        const canDuplicateProject = ability.can(Operation.Duplicate, Subject.Project);
        const canDuplicate2dProject = canDuplicateProject && ability.can(Operation.Read, Subject.QuantityTakeOff2d);
        return canDuplicate2dProject;
      });

    this.setState(
      { availableToDuplicateCompanies },
    );
  }

  @autobind
  private getCompanyUserAbility(company: Company): UserAbility {
    const subscription = company.subscriptions && company.subscriptions[SubscriptionType.Takeoff2d];

    return subscription
      ? new UserAbility(
        this.props.isAdmin,
        this.props.ownCompanyId === company.id,
        this.props.featureFlagService,
        subscription,
      )
      : null;
  }
}

function mapStateToProps(state: State): StateProps {
  const ownCompany = state.account.ownedCompany;
  return {
    ownCompanyId: ownCompany && ownCompany.id,
    companies: state.account.companies,
    userId: state.account.id,
    userEmail: state.account.email,
    isAdmin: state.account.isAdmin,
  };
}

const connector = connect(mapStateToProps);
export const TwoDProjectDuplicateDialog = withEnvironmentConfiguration(
  connector(withAbilityContext(TwoDProjectDuplicateDialogComponent)),
);
