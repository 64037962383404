import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { PdfGeometry } from '../../interfaces/api-responses/pdf-geometry-response';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';

export function useResults(): [PdfGeometry[], { width: number, height: number }] {
  const results = useSelector<State, PdfGeometry[]>((x) => x.drawings.wizzardTools.result);
  const similarity = useSelector<State, number>((x) => x.drawings.wizzardTools.finderSimilarity);

  return useMemo(() => {
    const filtered = results
      ? results.filter((x) => x.similarity >= similarity)
      : new Array<PdfGeometry>();
    if (!filtered.length) {
      return [null, null];
    }
    const firstItem = filtered[0];
    const bounds = DrawingsGeometryUtils.getPointsBounds(firstItem.points);
    return [filtered, bounds];
  }, [results, similarity]);
}
